import { customConfigs as defaultCustomConfigs } from '../../__tests__/mocks'
import { getColumnEditable } from '../index'

const getColumnEditableParams = () => ({
  ...defaultCustomConfigs,
  editing: { ...defaultCustomConfigs.editing, enabled: false }
})

describe('AgGrid:Helpers:Column:getColumnEditable', () => {
  let customConfigs = getColumnEditableParams()

  beforeEach(() => {
    customConfigs = getColumnEditableParams()
  })

  it('should return false if customConfigs.editing.enabled is false', () => {
    const gridEditableByDefault = true

    const result = getColumnEditable(customConfigs, gridEditableByDefault)
    expect(result).toBe(false)
  })

  it('should return false if gridEditableByDefault is false', () => {
    customConfigs.editing.enabled = true
    const gridEditableByDefault = false

    const result = getColumnEditable(customConfigs, gridEditableByDefault)
    expect(result).toBe(false)
  })

  it('should return true if customConfigs.editing.enabled is true and gridEditableByDefault is true', () => {
    customConfigs.editing.enabled = true
    const gridEditableByDefault = true

    const result = getColumnEditable(customConfigs, gridEditableByDefault)
    expect(result).toBe(true)
  })
})
