/* global Ext, tuiv2 */
import React, { Component } from 'react'

/* 3rd-party imports */
import * as d3 from 'd3'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import jwtDecode from 'jwt-decode'
import jquery from 'jquery'
import hash from 'object-hash'
import { v4 as uuidv4 } from 'uuid'
import { ExtContainer } from '@sencha/ext-react-classic'
import L from 'leaflet'
import 'leaflet-third-party-libraries'
import 'leaflet/dist/leaflet.css'

import { ExtRoot } from '@/components'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import GetDistinctColors from '@/helpers/DistinctColorGenerator'
import { options, leafletOptions } from '@/helpers/leafletOptions'
import { getExtContainerSize, getPluginSize } from '@/helpers'
import { API_URL } from '@/constants'

// EXT PACKAGE
require('./ext-package')

class TuiV2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      size: getPluginSize(props.size, props.isPreviewMode)
    }

    Ext.define('tuiv2.ExtGlobal', {
      singleton: true,
      config: {
        dragonApiUrl: `${API_URL}/data/${props.id}/invoke/`,
        authToken: props.token,
        pluginId: props.id
      },
      constructor: function (config) {
        var me = this
        me.initConfig(config)
      }
    })

    var that = this

    Ext.define(null, {
      override: 'Ext.grid.plugin.Exporter',

      saveDocumentAs: function (config) {
        const { userName = null, actualFilters = {}, createLog } = that.props

        config = {
          ...config,
          userName: userName,
          createLog: createLog,
          filters: {
            ...config.filters,
            ...actualFilters
          }
        }
        var cmp = this.cmp

        var deferred = new Ext.Deferred()

        var exporter = this.getExporter(config)

        cmp.fireEvent('beforedocumentsave', cmp, {
          config: config,
          exporter: exporter
        })

        this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])
        return deferred.promise
      }
    })

    /* HACK : 3rd-party imports */
    window.d3 = d3
    window.$ = jquery

    window.L = L
    // HACK : https://github.com/PaulLeCam/react-leaflet/issues/255
    delete L.Icon.Default.prototype._getIconUrl

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    })

    jquery.getScript('/resources/turf.min.js')

    Ext.require('Ext.button.*')
    Ext.require('Ext.calendar.*')
    Ext.require('Ext.exporter.*')
    Ext.require('Ext.form.*')
    Ext.require('Ext.grid.*')
    Ext.require('Ext.panel.*')
    Ext.require('Ext.ux.*')
    Ext.require('Ext.window.Toast')

    // Require Chart.ux
    Ext.require('Chart.ux.*')
    Ext.Date.shortMonthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    Ext.require('tuiv2.*')

    var defaultHeaders = Ext.Ajax.getDefaultHeaders() || {}

    if (!defaultHeaders['Authorization']) {
      defaultHeaders['environment'] = props.params.environment
      defaultHeaders['Authorization'] = 'Bearer ' + props.token
      Ext.Ajax.setDefaultHeaders(defaultHeaders)
    }

    Ext.Ajax.addListener('beforerequest', function (conn, options, eOpts) {
      options.url = options.url.replace(
        '###tuiv2_API_URL###',
        `${tuiv2.ExtGlobal.getDragonApiUrl()}`
      )

      options.url = options.url.replace('/tuiv2/', '/')
      options.url = options.url.replace('/localization/', '/')
      options.url = options.url.replace('/create', '_create')
      options.url = options.url.replace('/read', 'read')
      options.url = options.url.replace('/update', '_update')
      options.url = options.url.replace('/destroy', '_destroy')
      options.url = options.url.replace('/upload/logo', '/upload_logo')
    })

    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
    this.setFilters = this.setFilters.bind(this)
    this.setCheckedLoad = this.setCheckedLoad.bind(this)
    this.setCheckedLoads = this.setCheckedLoads.bind(this)
    this.tuiViewReady = this.tuiViewReady.bind(this)
    this.savePlan = this.savePlan.bind(this)
    this.scenarioSelected = this.scenarioSelected.bind(this)
    this.LoadRowSelected = this.LoadRowSelected.bind(this)
    this.LoadStopsSelected = this.LoadStopsSelected.bind(this)
    this.DemandRowSelected = this.DemandRowSelected.bind(this)
    this.LoadTreeLoaded = this.LoadTreeLoaded.bind(this)
    this.DemandTreeLoaded = this.DemandTreeLoaded.bind(this)
    this.ClickLoadEdit = this.ClickLoadEdit.bind(this)
    this.openLoadCard = this.openLoadCard.bind(this)
    this.openDemandCard = this.openDemandCard.bind(this)
    this.setTuiConnection = this.setTuiConnection.bind(this)
    this.createFooterButtons = this.createFooterButtons.bind(this)
    this.registerFooterButtons = this.registerFooterButtons.bind(this)
    this.DisabledFooterButtons = this.DisabledFooterButtons.bind(this)
    this.setFooterButtonsDisabled = this.setFooterButtonsDisabled.bind(this)
    this.setFooterButtonsEnabled = this.setFooterButtonsEnabled.bind(this)
    this.updateLoadTree = this.updateLoadTree.bind(this)
    this.updateDemandTree = this.updateDemandTree.bind(this)
  }

  UNSAFE_componentWillMount() {
    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition
      })
    }
    var that = this

    const {
      settings: {
        config: {
          settings: { wsdl = '' } = {},
          ServiceSetting: { ServiceAddress = '' } = {},
          LoadTree: { columns: LoadTreeAllColumns = [], loadFooterButtons = [] } = {},
          DemandTree: { columns: DemandTreeAllColumns = [], demandFooterButtons = [] } = {}
        } = {}
      } = {},
      getFieldType
    } = this.props

    if (
      (wsdl != null && wsdl.length > 0) ||
      (ServiceAddress != null && ServiceAddress.length > 0)
    ) {
      Ext.Ajax.request({
        method: 'POST',
        url: `${API_URL}/data/${that.props.id}/invoke/` + 'tuiv2/GetFilterAvailableColumns',
        params: {
          a: null
        },
        timeout: 600000,
        success: function (response) {
          var currentFields = Ext.decode(response.responseText, true)

          var mappedFilters = currentFields.map(function (currentField) {
            return {
              name: currentField.ComponentName + '_' + currentField.FieldName,
              type:
                // eslint-disable-next-line eqeqeq
                currentField.DataType == 'double'
                  ? PluginTypes['int']
                  : PluginTypes[currentField.DataType]
            }
          })

          that.props.registerMethod({
            key: 'setFilters',
            fn: that.setFilters.bind(that),
            args: mappedFilters
          })
        }
      })
    }

    this.props.registerMethod({
      key: 'setCheckedLoad',
      fn: this.setCheckedLoad.bind(this),
      args: [{ name: 'CheckedLoads', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setCheckedLoads',
      fn: this.setCheckedLoads.bind(this),
      args: [{ name: 'CheckedLoads', type: PluginTypes.arrayOf(PluginTypes.string) }]
    })

    this.props.registerMethod({
      key: 'openLoadCard',
      fn: this.openLoadCard.bind(this),
      args: [{ name: 'LoadNames', type: PluginTypes.arrayOf(PluginTypes.string) }]
    })

    this.props.registerMethod({
      key: 'openDemandCard',
      fn: this.openDemandCard.bind(this),
      args: [
        {
          name: 'FacilityNames',
          type: PluginTypes.arrayOf(PluginTypes.string)
        }
      ]
    })

    this.props.registerMethod({
      key: 'setTuiConnection',
      fn: this.setTuiConnection.bind(this),
      args: [{ name: 'DataConnectionId', type: PluginTypes.int }]
    })

    this.props.registerMethod({
      key: 'updateLoadTree',
      fn: this.updateLoadTree.bind(this),
      args: [{ name: 'refreshKey', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'updateDemandTree',
      fn: this.updateDemandTree.bind(this),
      args: [{ name: 'refreshKey', type: PluginTypes.string }]
    })

    this.tuiViewReady = this.props.registerEvent({
      key: 'tuiViewReady',
      fn: this.tuiViewReady,
      returnTypes: {
        CurrentScenarioID: PluginTypes.int,
        CurrentRunInfoID: PluginTypes.int
      }
    })

    this.savePlan = this.props.registerEvent({
      key: 'savePlan',
      fn: this.savePlan,
      returnTypes: {
        runInfoId: PluginTypes.int
      }
    })

    this.scenarioSelected = this.props.registerEvent({
      key: 'scenarioSelected',
      fn: this.scenarioSelected.bind(this),
      returnTypes: {
        ScenarioID: PluginTypes.string,
        ScenarioName: PluginTypes.string,
        ConnectionID: PluginTypes.int,
        RunID: PluginTypes.string,
        RunInfoID: PluginTypes.int
      }
    })

    var loadRowSelectedParams = {}

    var demandRowSelectedParams = {}

    var loadStopsSelectedParams = {}

    loadStopsSelectedParams['LoadName'] = PluginTypes.fromString('string')
    loadStopsSelectedParams['LoadStopName'] = PluginTypes.fromString('string')
    loadStopsSelectedParams['index'] = PluginTypes.fromString('string')

    LoadTreeAllColumns.forEach((item) => {
      loadRowSelectedParams[item.dataIndex] = PluginTypes.fromString(item.dataTypeName)
    })

    DemandTreeAllColumns.forEach((item) => {
      demandRowSelectedParams[item.dataIndex] = PluginTypes.fromString(item.dataTypeName)
    })

    this.LoadRowSelected = this.props.registerEvent({
      key: 'LoadRowSelected',
      fn: this.LoadRowSelected.bind(this),
      returnTypes: _.transform(
        loadRowSelectedParams,
        (result, value, key) => {
          result[key] = getFieldType(key, true)
        },
        {}
      )
    })

    this.LoadStopsSelected = this.props.registerEvent({
      key: 'LoadStopsSelected',
      fn: this.LoadStopsSelected.bind(this),
      returnTypes: _.transform(
        loadStopsSelectedParams,
        (result, value, key) => {
          result[key] = getFieldType(key, true)
        },
        {}
      )
    })

    this.ClickLoadEdit = this.props.registerEvent({
      key: 'ClickLoadEdit',
      fn: this.ClickLoadEdit.bind(this),
      returnTypes: {
        refreshKey: PluginTypes.string,
        LoadName: PluginTypes.string
      }
    })

    this.DemandRowSelected = this.props.registerEvent({
      key: 'DemandRowSelected',
      fn: this.DemandRowSelected.bind(this),
      returnTypes: _.transform(
        demandRowSelectedParams,
        (result, value, key) => {
          result[key] = getFieldType(key, true)
        },
        {}
      )
    })
    this.LoadTreeLoaded = this.props.registerEvent({
      key: 'LoadTreeLoaded',
      fn: this.LoadTreeLoaded,
      returnTypes: {
        refreshKey: PluginTypes.string
      }
    })
    this.DemandTreeLoaded = this.props.registerEvent({
      key: 'DemandTreeLoaded',
      fn: this.DemandTreeLoaded,
      returnTypes: {
        refreshKey: PluginTypes.string
      }
    })
    _.forEach(loadFooterButtons, (footerButton) => this.registerFooterButtons(footerButton, 'Load'))
    _.forEach(demandFooterButtons, (footerButton) =>
      this.registerFooterButtons(footerButton, 'Demand')
    )
    _.forEach(loadFooterButtons, (footerButton) => this.DisabledFooterButtons(footerButton, 'Load'))

    _.forEach(loadFooterButtons, (footerButton) => this.EnabledFooterButtons(footerButton, 'Load'))

    _.forEach(demandFooterButtons, (footerButton) =>
      this.DisabledFooterButtons(footerButton, 'Demand')
    )

    _.forEach(demandFooterButtons, (footerButton) =>
      this.EnabledFooterButtons(footerButton, 'Demand')
    )

    this.props.registerMethod({
      key: 'setDisablePolishize',
      fn: this.setFooterButtonsDisabled.bind(this, 'Polishize'),
      args: []
    })

    this.props.registerMethod({
      key: 'setEnablePolishize',
      fn: this.setFooterButtonsEnabled.bind(this, 'Polishize'),
      args: []
    })

    this.props.registerMethod({
      key: 'setDisableServe',
      fn: this.setFooterButtonsDisabled.bind(this, 'Serve'),
      args: []
    })

    this.props.registerMethod({
      key: 'setEnableServe',
      fn: this.setFooterButtonsEnabled.bind(this, 'Serve'),
      args: []
    })

    this.props.registerMethod({
      key: 'setDisableOrigin',
      fn: this.setFooterButtonsDisabled.bind(this, 'Origin'),
      args: []
    })

    this.props.registerMethod({
      key: 'setEnableOrigin',
      fn: this.setFooterButtonsEnabled.bind(this, 'Origin'),
      args: []
    })
  }

  registerFooterButtons(footerButton, prefix) {
    const { buttonText } = footerButton
    if (buttonText) {
      const modifiedButtonText = buttonText.replace(/\s/g, '_')

      // Register footer button clicked event
      this['handleFooterButtonClick_' + modifiedButtonText] = function () {
        return this.handleFooterButtonClick()
      }

      this['handleFooterButtonClick_' + modifiedButtonText] = this.props.registerEvent({
        key: prefix + 'FooterButtonClick_' + buttonText,
        fn: this['handleFooterButtonClick_' + modifiedButtonText].bind(this),
        returnTypes: { refreshKey: PluginTypes.string }
      })
    }
  }

  DisabledFooterButtons(footerButton, prefix) {
    const { buttonText } = footerButton
    if (buttonText) {
      const modifiedButtonText = buttonText.replace(/\s/g, '_')

      this.props.registerMethod({
        key: 'setDisabled' + prefix + '_' + buttonText,
        fn: this.setFooterButtonsDisabled.bind(this, modifiedButtonText),
        args: []
      })
    }
  }

  EnabledFooterButtons(footerButton, prefix) {
    const { buttonText } = footerButton
    if (buttonText) {
      const modifiedButtonText = buttonText.replace(/\s/g, '_')

      this.props.registerMethod({
        key: 'setEnabled' + prefix + '_' + buttonText,
        fn: this.setFooterButtonsEnabled.bind(this, modifiedButtonText),
        args: []
      })
    }
  }

  setFooterButtonsDisabled(buttonText) {
    const view = this.tuiv2MainView

    if (view) {
      view.fireEvent('setFooterButtonsView', buttonText, true)
    }
  }

  setFooterButtonsEnabled(buttonText) {
    const view = this.tuiv2MainView

    if (view) {
      view.fireEvent('setFooterButtonsView', buttonText, false)
    }
  }

  handleFooterButtonClick() {
    return { refreshKey: uuidv4() }
  }

  componentDidMount() {
    this.props.registerAuthorizations([
      'CanReset',
      'CanApproveLoad',
      'CanUpdateLocation',
      'CanUpdateDemand',
      'CanSavePlan',
      'CanRefix',
      'CanServe',
      'CanCreateSnapshot',
      'CanSetOrigin',
      'CanCancelTask',
      'CanSetService'
    ])

    this.props.reloadExtRoot(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If size of the grid changes do not render the grid
    const widthChanged = nextProps.size.width !== this.props.size.width
    const heightChanged = nextProps.size.height !== this.props.size.height

    if (widthChanged || heightChanged) {
      setTimeout(() => this.setState({ size: getExtContainerSize(this.props.id) }), 100)
    }
  }

  LoadRowSelected(records) {
    const {
      settings: { config: { LoadTree: { columns: LoadTreeAllColumns = [] } = {} } = {} } = {}
    } = this.props
    var loadRowSelectedParams = []
    LoadTreeAllColumns.forEach((item) => {
      loadRowSelectedParams.push(item)
    })

    return _.transform(
      loadRowSelectedParams,
      (result, field) => {
        const fieldValues = _.map(records, (selectedRow) => {
          return selectedRow[field.dataIndex]
        })
        result[field.dataIndex] = _.size(fieldValues) > 0 ? fieldValues : null
      },
      {}
    )
  }

  LoadStopsSelected(records) {
    const {
      settings: { config: { LoadTree: { columns: LoadTreeAllColumns = [] } = {} } = {} } = {}
    } = this.props
    var loadStopsSelectedParams = []
    LoadTreeAllColumns.forEach((item) => {
      if (!item.columnIsDisabled) {
        loadStopsSelectedParams.push(item)
      }
    })

    loadStopsSelectedParams.push({ dataIndex: 'LoadName' })
    loadStopsSelectedParams.push({ dataIndex: 'LoadStopName' })
    loadStopsSelectedParams.push({ dataIndex: 'index' })
    return _.transform(
      loadStopsSelectedParams,
      (result, field) => {
        const fieldValues = _.map(records, (selectedRow) => {
          return selectedRow[field.dataIndex]
        })
        result[field.dataIndex] = _.size(fieldValues) > 0 ? fieldValues : null
      },
      {}
    )
  }

  ClickLoadEdit(record) {
    return { refreshKey: uuidv4(), LoadName: record.LoadName }
  }

  DemandRowSelected(records) {
    const {
      settings: { config: { DemandTree: { columns: DemandTreeAllColumns = [] } = {} } = {} } = {}
    } = this.props
    var demandRowSelectedParams = []
    DemandTreeAllColumns.forEach((item) => {
      demandRowSelectedParams.push(item)
    })

    return _.transform(
      demandRowSelectedParams,
      (result, field) => {
        const fieldValues = _.map(records, (selectedRow) => {
          return selectedRow[field.dataIndex]
        })
        result[field.dataIndex] = _.size(fieldValues) > 0 ? fieldValues : null
      },
      {}
    )
  }

  LoadTreeLoaded(params) {
    return { refreshKey: uuidv4() }
  }

  DemandTreeLoaded(params) {
    return { refreshKey: uuidv4() }
  }

  savePlan(runInfoId) {
    return { runInfoId: runInfoId }
  }

  scenarioSelected(params) {
    return params
  }

  tuiViewReady(params) {
    return params
  }

  handleSynchronizeDataDefinition(fields, value, schemaHelper, a, context) {
    var newValue = _.cloneDeep(value)

    const currentPath = context.split('.')[1]

    Ext.Ajax.request({
      method: 'POST',
      async: false,
      url:
        `${API_URL}/data/${this.props.id}/invoke/` + 'tuiv2/Get' + currentPath + 'AvailableColumns',
      params: {
        a: null
      },
      timeout: 600000,
      success: function (response, opts) {
        fields = Ext.decode(response.responseText, true)
      },
      failure: function (response, opts) {
        alert(response)
      }
    })
    // Create an  empty template for grid column

    var emptyColumn = schemaHelper.getValueOfSchema(
      this.props.schema.properties[currentPath].properties.columns.items
    )

    _.remove(newValue[currentPath].columns, (column) => {
      return _.isEmpty(column.dataIndex)
    })

    _.forEach(fields, (field) => {
      // Check if the field is alraedy used as a column
      const existingColumn = _.find(newValue[currentPath].columns, (column) => {
        return column.dataIndex === field.FieldName
      })

      // Create a column if its field does not exist
      if (!existingColumn) {
        var newcolumn = _.cloneDeep(emptyColumn)

        if (_.isNil(newcolumn.dataIndex) || _.isEmpty(newcolumn.dataIndex)) {
          newcolumn.dataIndex = field.FieldName
          newcolumn.fieldName = field.FieldName
          newcolumn.text = field.FieldName
          newcolumn.dataTypeName = field.DataType
        }

        newValue[currentPath].columns.push(newcolumn)
      }
    })
    return newValue
  }

  setFilters(obj) {
    const view = this.tuiv2MainView

    if (view) {
      view.fireEvent('setFilters', obj)
    }
  }

  setCheckedLoads(obj) {
    const view = this.tuiv2MainView

    if (view) {
      view.fireEvent('setCheckedLoads', obj)
    }
  }

  setCheckedLoad(obj) {
    const view = this.tuiv2MainView

    if (view) {
      view.fireEvent('setCheckedLoads', obj)
    }
  }

  openLoadCard(params) {
    const view = this.tuiv2MainView

    if (view) {
      view.fireEvent('openLoadCard', params)
    }
  }

  setTuiConnection(params) {
    const view = this.tuiv2MainView
    if (view) {
      view.fireEvent('setTuiConnection', params)
    }
  }

  updateLoadTree() {
    const view = this.tuiv2MainView
    var controller = view.getController()

    this.props.setDataArguments(null, true)
    controller.reloadLoadTreeStore()
  }

  updateDemandTree() {
    const view = this.tuiv2MainView
    var controller = view.getController()

    this.props.setDataArguments(null, true)
    controller.reloadDemandTreeStore()
  }

  openDemandCard(params) {
    const view = this.tuiv2MainView

    if (view) {
      view.fireEvent('openDemandCard', params)
    }
  }

  addRenderer(item) {
    if (item.columnIsPercent) {
      item.renderer = function (rate, meta) {
        const { backColor = null, fontColor = null } = item || {}

        if (backColor) {
          meta.style += `background-color: ${backColor};`
        }
        if (fontColor) {
          meta.style += `color: ${fontColor};`
        }
        return Ext.util.Format.number(rate * 100, '0.##%')
      }
      // eslint-disable-next-line eqeqeq
    } else if (item.dataTypeName == 'datetime') {
      item.renderer = function (value, meta) {
        const { backColor = null, fontColor = null } = item || {}

        if (backColor) {
          meta.style += `background-color: ${backColor};`
        }
        if (fontColor) {
          meta.style += `color: ${fontColor};`
        }
        // eslint-disable-next-line no-redeclare
        var value = Ext.Date.parse(value, 'Y-m-dTH:i:s')

        if (!value) return '-'
        return Ext.util.Format.dateRenderer('d-m-Y H:i')(value)
      }
    }
    if (item.columnType === 'progress') {
      var properties = {
        dataIndex: item.dataIndex,
        dataTypeName: item.dataTypeName,
        isHierarchy: item.isHierarchy,
        text: item.text,
        aggregationFunction: item.aggregationFunction,
        tooltip: item.tooltip,
        width: item.width,
        locked: item.locked,
        summaryType: item.summaryType,
        summaryRenderer: function (val, summaryData, dataIndex) {
          var out = Ext.util.Format.percent(val, '0,0')
          return '<b>' + out + '</b>'
        }
      }

      item = Ext.create('Ext.grid.column.Widget', {
        ...properties,
        widget: {
          xtype: 'progress',
          textTpl: ['{percent:number("0")}%']
        }
      })
    }

    if (!item.renderer) {
      item.renderer = function (value, meta) {
        const { backColor = null, fontColor = null } = item || {}

        if (backColor) {
          meta.style += `background-color: ${backColor};`
        }
        if (fontColor) {
          meta.style += `color: ${fontColor};`
        }
        return value
      }
    }

    return item
  }

  createFooterButtons(footerButtons) {
    return footerButtons.map((footerButton) => {
      const {
        buttonIcon,
        buttonText,
        buttonTooltip,
        confirmation = false,
        confirmationText = 'Are you sure to continue?',
        confirmationTitle = 'Confirmation'
      } = footerButton
      const modifiedButtonText = buttonText.replace(/\s/g, '_')

      return {
        text: buttonText,
        tooltip: buttonTooltip,
        iconCls: buttonIcon,
        bind: {
          disabled: modifiedButtonText ? `{${modifiedButtonText}}` : false
        },
        handler: confirmation
          ? this.openFooterButtonConfirmationPopup(
              confirmationText,
              confirmationTitle,
              this['handleFooterButtonClick_' + modifiedButtonText]
            )
          : this['handleFooterButtonClick_' + modifiedButtonText]
      }
    })
  }

  openFooterButtonConfirmationPopup(confirmationText, confirmationTitle, handler) {
    return () => {
      confirmAlert({
        message: confirmationText,
        title: confirmationTitle,
        buttons: [
          {
            label: 'Cancel',
            onClick: () => false
          },
          {
            label: 'OK',
            onClick: () => handler()
          }
        ]
      })
    }
  }

  onInitializeRef(data) {
    this.tuiv2MainView = data.view
  }

  processTableColumns(columns, targetArray, rawColumnsArray) {
    columns.forEach((item) => {
      if (!item.columnIsDisabled) {
        item.align = 'stretch'
        item.flex = 1
        if (rawColumnsArray) {
          rawColumnsArray.push(item)
        }
        item = this.addRenderer(item)
        targetArray.push(item)
      }
    })
  }

  render() {
    let { tiles = [], theme = [] } = options || {}

    tiles = _.map(tiles, (item) => item.title)
    theme = _.map(theme, (item) => item.title)

    const [defaultTile = ''] = tiles
    const [defaultTheme = ''] = theme

    const {
      settings: {
        config: {
          settings: {
            errorMessage,
            asyncActionStartMessage,
            asyncActionSuccessMessage,
            asyncActionFailMessage,
            leftSideFilter,
            startingLastService = true,
            serviceSelectionPopUp,
            defaultViewTui,
            backgroundColor
          } = {},
          MapSettings: {
            tileSettings = defaultTile,
            mapColor = defaultTheme,
            latValue = 39.0405193724839,
            lngValue = 35.29724098742009,
            zoomFactor = 7
          } = {},
          LoadTree: {
            columns: LoadTreeAllColumns = [],
            Hierarchy: LoadTreeHierarchy = [],
            Filters: LoadTreeFilters,
            loadFooterButtons = []
          } = {},
          DemandTree: {
            columns: DemandTreeAllColumns = [],
            Hierarchy: DemandTreeHierarchy = [],
            Filters: DemandTreeFilters,
            demandFooterButtons = [],
            demandTotalFields = []
          } = {},
          Location: { locations: LocationColumns = [] } = {},
          LoadTable: {
            grouping: loadTableGrouping = false,
            columns: LoadTableAllColumns = []
          } = {},
          LoadStopTable: {
            header: loadStopTableHeader,
            grouping: loadStopTableGrouping = false,
            columns: LoadStopTableAllColumns = []
          } = {},
          LoadStopEntryTable: {
            header: loadStopEntryTableHeader,
            grouping: loadStopEntryTableGrouping = false,
            columns: LoadStopEntryTableAllColumns = []
          } = {},
          Units = {}
        } = {}
      } = {},
      isPreviewMode,
      token,
      id = 'id',
      isAllowed
    } = this.props

    if (isPreviewMode) {
      return <div> Preview mode. </div>
    }

    var loadHierarchyArray = LoadTreeHierarchy.map(function (item) {
      var column = LoadTreeAllColumns.find(function (column) {
        // eslint-disable-next-line eqeqeq
        return column.dataIndex == item.fieldName
      })
      return {
        text: column.text,
        dataIndex: column.dataIndex,
        isTreeDistinctColorField: column.isTreeDistinctColorField
      }
    })

    var demandHierarchyArray = DemandTreeHierarchy.map(function (item) {
      var column = DemandTreeAllColumns.find(function (column) {
        // eslint-disable-next-line eqeqeq
        return column.dataIndex == item.fieldName
      })
      return { text: column.text, dataIndex: column.dataIndex }
    })

    var demandTreeColumns = []

    var loadTreeColumns = []

    var loadTableColumns = []

    var loadTableRawColumns = []

    var loadStopTableColumns = []

    var loadStopEntryTableColumns = []

    var demandTreeHierarchyColumns = []

    var loadTreeHierarchyColumns = []

    var demandDetailDataColumns = []

    this.processTableColumns(LoadTableAllColumns, loadTableColumns, loadTableRawColumns)
    this.processTableColumns(LoadStopTableAllColumns, loadStopTableColumns)
    this.processTableColumns(LoadStopEntryTableAllColumns, loadStopEntryTableColumns)

    LoadTreeAllColumns.forEach((item) => {
      if (item.isHierarchy) {
        loadTreeHierarchyColumns.push(item)
      }
      if (!item.columnIsDisabled) {
        item = this.addRenderer(item)
        loadTreeColumns.push(item)
      }
    })

    loadHierarchyArray.forEach((item) => {
      let exist = false
      // eslint-disable-next-line array-callback-return
      loadTreeHierarchyColumns.forEach((obj) => {
        // eslint-disable-next-line eqeqeq
        if (obj.dataIndex == item.dataIndex) exist = true
      })
      if (!exist) {
        var column = LoadTreeAllColumns.find(function (column) {
          // eslint-disable-next-line eqeqeq
          return column.dataIndex == item.dataIndex
        })
        loadTreeHierarchyColumns.push({
          text: column.text,
          dataIndex: column.dataIndex,
          isTreeDistinctColorField: column.isTreeDistinctColorField
        })
      }
    })

    DemandTreeAllColumns.forEach((item) => {
      if (item.isHierarchy) {
        demandTreeHierarchyColumns.push(item)
      }
      if (!item.columnIsDisabled) {
        item = this.addRenderer(item)
        demandTreeColumns.push(item)
      }
    })

    demandHierarchyArray.forEach((item) => {
      let exist = false
      demandTreeHierarchyColumns.forEach((obj) => {
        // eslint-disable-next-line eqeqeq
        if (obj.dataIndex == item.dataIndex) exist = true
      })
      if (!exist) {
        var column = DemandTreeAllColumns.find(function (column) {
          // eslint-disable-next-line eqeqeq
          return column.dataIndex == item.dataIndex
        })
        demandTreeHierarchyColumns.push({
          text: column.text,
          dataIndex: column.dataIndex
        })
      }
    })

    const session = jwtDecode(token)
    const user = {
      id: session.sub * 1,
      username: session.preferred_username,
      culture: session.culture,
      email: session.email
    }

    const loadHierarchyHash = hash(LoadTreeHierarchy)
    const demandHierarchyHash = hash(DemandTreeHierarchy)

    const disabledMap = {
      latValue,
      lngValue,
      zoomFactor
    }

    const leafletOptionsProps = {
      tiles: leafletOptions,
      selectedTile: tileSettings,
      selectedTheme: mapColor
    }

    const packageProps = {
      xtype: 'tuiv2mainview',

      DemandTreeAllColumns,
      width: '100%',
      height: '100%',
      user,
      units: Units,
      disabledMap,
      errorMessage,
      leftSideFilter,
      loadTreeColumns,
      loadTableColumns,
      loadHierarchyHash,
      demandTreeColumns,
      GetDistinctColors,
      demandHierarchyHash,
      loadTableRawColumns,
      startingLastService,
      loadStopTableColumns,
      loadStopTableHeader,
      loadStopTableGrouping,
      loadTableGrouping,
      loadStopEntryTableHeader,
      loadStopEntryTableGrouping,
      serviceSelectionPopUp,
      defaultViewTui,
      backgroundColor,
      asyncActionFailMessage,
      demandDetailDataColumns,
      asyncActionStartMessage,
      loadTreeHierarchyColumns,
      loadStopEntryTableColumns,
      asyncActionSuccessMessage,
      demandTreeHierarchyColumns,
      demandTotalFields,
      locationColumns: LocationColumns,
      loadTreeFilters: LoadTreeFilters,
      loadFooterButtons: this.createFooterButtons(loadFooterButtons),
      demandFooterButtons: this.createFooterButtons(demandFooterButtons),
      leafletOptions: leafletOptionsProps,
      demandTreeFilters: DemandTreeFilters,
      loadTreeHierarchy: loadHierarchyArray,
      demandTreeHierarchy: demandHierarchyArray,
      CanReset: isAllowed('CanReset'),
      CanApproveLoad: isAllowed('CanApproveLoad'),
      CanRemoveLoadStop: isAllowed('CanRemoveLoadStop'),
      CanChangeLoadStopSequence: isAllowed('CanChangeLoadStopSequence'),
      CanUpdateLocation: isAllowed('CanUpdateLocation'),
      CanUpdateDemand: isAllowed('CanUpdateDemand'),
      CanSavePlan: isAllowed('CanSavePlan'),
      CanRefix: isAllowed('CanRefix'),
      CanServe: isAllowed('CanServe'),
      CanSetOrigin: isAllowed('CanSetOrigin'),
      CanCancelTask: isAllowed('CanCancelTask'),
      CanCreateSnapshot: isAllowed('CanCreateSnapshot'),
      CanSetService: isAllowed('CanSetService'),
      onScenarioSelected: this.scenarioSelected,
      onSavePlan: this.savePlan,
      onLoadTreeLoaded: this.LoadTreeLoaded,
      onLoadRowSelected: this.LoadRowSelected,
      onLoadStopsSelected: this.LoadStopsSelected,
      onClickLoadEdit: this.ClickLoadEdit,
      onDemandTreeLoaded: this.DemandTreeLoaded,
      onDemandRowSelected: this.DemandRowSelected,
      onInitializeRef: this.onInitializeRef.bind(this)
    }

    const extContainerProps = {
      ...getPluginSize(this.state.size, isPreviewMode),
      id: `slvyExtContainer-${id}`,
      cls: 'slvy-ext-container tui-wrp',
      layout: 'fit',
      autoSize: true,
      items: [packageProps]
    }

    return (
      <ExtRoot pluginId={id}>
        <ExtContainer {...extContainerProps} />
      </ExtRoot>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  setDataArguments: props.setDataArguments,
  registerAuthorizations: props.registerAuthorizations,
  settings: props.settings,
  size: props.size,
  isPreviewMode: props.isPreviewMode,
  id: props.id,
  userName: props.userName,
  actualFilters: props.actualFilters,
  createLog: props.createLog,
  token: props.token,
  params: props.params,
  onReady: props.onReady,
  reloadExtRoot: props.reloadExtRoot,
  schema: props.schema,
  isAllowed: props.isAllowed,
  getFieldType: props.getFieldType
})

export default createPlugin(TuiV2, selectConnectorProps)
