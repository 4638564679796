import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IState {
  containers: string[]
  plugins: string[]
}

export const initialState: IState = {
  containers: [],
  plugins: []
}

interface ActionType {
  id: string
  type: 'container' | 'plugin'
}

export const pluginlist = createSlice({
  name: 'pluginlist',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<ActionType>) => {
      const { type, id } = action.payload
      if (type === 'container') {
        state.containers.push(id)
      } else {
        state.plugins.push(id)
      }
    },
    remove: (state, action: PayloadAction<ActionType>) => {
      const { type, id } = action.payload
      if (type === 'container') {
        state.containers = state.containers.filter((containerId) => containerId !== id)
      } else {
        state.plugins = state.plugins.filter((pluginId) => pluginId !== id)
      }
    },
    clear: () => {
      return initialState
    }
  }
})

export const { add, remove, clear } = pluginlist.actions

export default pluginlist.reducer
