import CellRenderer, {
  ActionButtonCellRenderer,
  BooleanIconCellRenderer,
  ColorCellRenderer,
  DateCellRenderer,
  HighchartCellRenderer,
  HtmlCellRenderer,
  IconCellRenderer,
  SummaryCellRenderer,
  TemplateCellRenderer
} from '../../../components/CellRenderers/index'
import { getCellRendererSelector } from '../index'
import {
  cellDisplayRules as defaultCellDisplayRules,
  customConfigs as defaultCustomConfigs,
  fieldConfigs as defaultFieldConfigs,
  fieldLookupConfig as defaultFieldLookupConfig
} from '../../__tests__/mocks'
import { cellRendererSelectorParams as defaultCellRendererSelectorParams } from './mocks'

function getCellDisplayRules() {
  return defaultCellDisplayRules
}

function getCellRendererSelectorParams() {
  return defaultCellRendererSelectorParams
}

function getCustomConfigs() {
  return defaultCustomConfigs
}

function getFieldConfigs() {
  return defaultFieldConfigs[0]
}

function getFieldLookupConfig() {
  return defaultFieldLookupConfig
}

function getHandleActionBtnClick() {
  return () => {}
}

describe('AgGrid:Helpers:Cell:getCellRendererSelector', () => {
  let customConfigs = getCustomConfigs()
  let fieldConfig = getFieldConfigs()
  let cellDisplayRules = getCellDisplayRules()
  let handleActionBtnClick = getHandleActionBtnClick()
  let fieldLookupConfig = getFieldLookupConfig()
  let cellRendererSelectorParams = getCellRendererSelectorParams()

  beforeEach(() => {
    customConfigs = getCustomConfigs()
    fieldConfig = getFieldConfigs()
    cellDisplayRules = getCellDisplayRules()
    handleActionBtnClick = getHandleActionBtnClick()
    fieldLookupConfig = getFieldLookupConfig()
    cellRendererSelectorParams = getCellRendererSelectorParams()
  })

  it('should return SummaryCellRenderer as component and template by params when node is a footer', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = true
    customConfigs.summaryTemplate = 'tempTest'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellRendererSelectorResult = result.cellRendererSelector(cellRendererSelectorParams)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('params')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty('template')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params.template).toBe('tempTest')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.component).toEqual(SummaryCellRenderer)
  })

  it('should return TemplateCellRenderer as component and template by params when there is a template and no lookup and data type is not boolean', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = 'customTemp'
    fieldLookupConfig.substituteField = ''
    customConfigs.editing.lookupDataField = ''
    fieldConfig.dataType = 'int'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellRendererSelectorResult = result.cellRendererSelector(cellRendererSelectorParams)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('params')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty('template')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params.template).toBe('customTemp')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.component).toEqual(TemplateCellRenderer)
  })

  it('should return ActionButtonCellRenderer as component and actionButtonParams, icon, handleActionBtnClick by params when action button enabled and data type is not boolean', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = true
    fieldConfig.dataType = 'int'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellRendererSelectorResult = result.cellRendererSelector(cellRendererSelectorParams)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('params')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty(
      'actionButtonParams',
      customConfigs.actionButton
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty('icon')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty(
      'handleActionBtnClick',
      expect.any(Function)
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.component).toEqual(ActionButtonCellRenderer)
  })

  it('should return BooleanIconCellRenderer as component when data type is boolean and trueIcon and falseIcon are selected', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col10'
    fieldConfig.dataType = 'bool'
    customConfigs.boolean.trueIcon = 'fa slvy-ui-icon-pin-solid'
    customConfigs.boolean.falseIcon = 'fa slvy-ui-icon-pin'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams)).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams).component).toEqual(
      BooleanIconCellRenderer
    )
  })

  it(`should return 'agCheckboxCellRenderer' as component when data type is boolean and trueIcon and falseIcon are not selected`, () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col10'
    fieldConfig.dataType = 'bool'
    customConfigs.boolean.trueIcon = ''
    customConfigs.boolean.falseIcon = ''

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams)).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams).component).toBe(
      'agCheckboxCellRenderer'
    )
  })

  it('should return DateCellRenderer as component when data type is datetime', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col6'
    fieldConfig.dataType = 'datetime'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams)).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams).component).toEqual(
      DateCellRenderer
    )
  })

  it('should return ColorCellRenderer as component when column type is color', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    customConfigs.columnType = 'color'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams)).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams).component).toEqual(
      ColorCellRenderer
    )
  })

  it('should return IconCellRenderer as component when column type is icon', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    customConfigs.columnType = 'icon'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams)).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams).component).toEqual(
      IconCellRenderer
    )
  })

  it('should return HtmlCellRenderer as component when column type is html', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    customConfigs.columnType = 'html'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams)).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellRendererSelector(cellRendererSelectorParams).component).toEqual(
      HtmlCellRenderer
    )
  })

  it('should return HighchartCellRenderer as component and highchartOptions by params when column type is highchart', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    customConfigs.columnType = 'highchart'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellRendererSelectorResult = result.cellRendererSelector(cellRendererSelectorParams)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('params')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty(
      'highchartOptions',
      customConfigs.highchartOptions
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.component).toEqual(HighchartCellRenderer)
  })

  it('should return agSparklineCellRenderer as component and sparklineOptions by params when column type is progress', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    customConfigs.columnType = 'progress'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellRendererSelectorResult = result.cellRendererSelector(cellRendererSelectorParams)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('params')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty('sparklineOptions')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.component).toEqual('agSparklineCellRenderer')
  })

  it('should return agSparklineCellRenderer as component and sparklineOptions by params when column type is sparkline', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    customConfigs.columnType = 'sparkline'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellRendererSelectorResult = result.cellRendererSelector(cellRendererSelectorParams)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('params')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty('sparklineOptions')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.component).toEqual('agSparklineCellRenderer')
  })

  it('should return CellRenderer as component and icon by params when column type is simple and there is no lookup and data type is not datetime or boolean and node is not a footer node', () => {
    const result = getCellRendererSelector({
      customConfigs,
      fieldConfig,
      cellDisplayRules,
      handleActionBtnClick,
      fieldLookupConfig
    })
    cellRendererSelectorParams.node.footer = false
    customConfigs.template = ''
    customConfigs.actionButton.actionEnabled = false
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    customConfigs.columnType = 'simple'

    expect(result).toHaveProperty('cellRendererSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellRendererSelectorResult = result.cellRendererSelector(cellRendererSelectorParams)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult).toHaveProperty('params')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.params).toHaveProperty('icon')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(cellRendererSelectorResult.component).toEqual(CellRenderer)
  })
})
