import { isActionButtonDisabled } from '../cell'
import { IsActionButtonDisabledProps } from '../cell.types'
import { cellParamData, validCellParamsData } from './mocks'

const isActionButtonDisabledParams = (): IsActionButtonDisabledProps => ({
  record: cellParamData,
  enabledCondition: '',
  clickableOnDisabled: false
})

describe('AgGrid:Helpers:Cell:isActionButtonDisabled', () => {
  let params = isActionButtonDisabledParams()

  beforeEach(() => {
    params = isActionButtonDisabledParams()
  })

  it('should return false if clickableOnDisabled is true', () => {
    params.clickableOnDisabled = true
    const result = isActionButtonDisabled(params)
    expect(result).toBe(false)
  })

  it('should return false if clickableOnDisabled is false and disable condition exists in the record and its value is true', () => {
    params.record = validCellParamsData
    params.enabledCondition = 'col10'
    const result = isActionButtonDisabled(params)
    expect(result).toBe(false)
  })

  it('should return true if clickableOnDisabled is false and disable condition exists in the record and its value is false', () => {
    params.enabledCondition = 'col10'
    const result = isActionButtonDisabled(params)
    expect(result).toBe(true)
  })
})
