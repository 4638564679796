export default [
  {
    name: 'TotalRevenue',
    displayName: 'Forecasted Revenue',
    isVisible: true
  },
  {
    name: 'TotalCost',
    displayName: 'Forecasted Cost',
    isVisible: true
  },
  {
    name: 'Expenditure',
    displayName: 'Unit Cost',
    isVisible: true
  },
  {
    name: 'IMU',
    displayName: 'IMU',
    isVisible: true
  },
  {
    name: 'StartDate',
    displayName: 'Start Date',
    isVisible: true
  },
  {
    name: 'EndDate',
    displayName: 'End Date',
    isVisible: true
  },
  {
    name: 'TotalMinPresentation',
    displayName: 'Min Presentation',
    isVisible: true
  },
  {
    name: 'SizeRangeId',
    displayName: 'Size Range',
    isVisible: true
  },
  {
    name: 'Sellthrough',
    displayName: 'Sellthrough Target',
    isVisible: true
  },
  {
    name: 'NumberOfActiveStores',
    displayName: 'Number Of StoresUnit Sales',
    isVisible: true
  },
  {
    name: 'ROS',
    displayName: 'ROS',
    isVisible: true
  },
  {
    name: 'Markdown',
    displayName: 'Markdown',
    isVisible: true
  },
  {
    name: 'PrePackSize',
    displayName: 'Prepack per Option',
    isVisible: true
  },
  {
    name: 'TotalPrePackQuantity',
    displayName: 'Total PrePack Quantity',
    isVisible: true
  },
  {
    name: 'GlobalTotalStock',
    displayName: 'Global Total Stock',
    isVisible: true
  }
]
