import { CELL_CLASS_FOR_EXCEL_NUMBER_FORMAT } from '../../../constants'
import { getExcelNumberFormatId } from '../index'

describe('AgGrid:Helpers:Export:getExcelNumberFormatId', () => {
  it('should return the correct Excel number format id for a valid field', () => {
    const field = 'salesAmount'
    const result = getExcelNumberFormatId(field)
    expect(result).toBe('salesAmountExcelNumberFormatType')
  })

  it('should handle a field with special characters', () => {
    const field = 'price@per#unit'
    const result = getExcelNumberFormatId(field)
    expect(result).toBe('priceperunitExcelNumberFormatType')
  })

  it('should handle a field with no alphanumeric characters', () => {
    const field = '12345!@#$%^&*()'
    const result = getExcelNumberFormatId(field)
    expect(result).toBe('ExcelNumberFormatType') // Since cleanField becomes an empty string
  })

  it('should handle undefined field', () => {
    const result = getExcelNumberFormatId(undefined)
    expect(result).toBe('ExcelNumberFormatType')
  })

  it('should handle null field', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getExcelNumberFormatId(null)
    expect(result).toBe('ExcelNumberFormatType')
  })

  it('should handle an empty string field', () => {
    const result = getExcelNumberFormatId('')
    expect(result).toBe('ExcelNumberFormatType')
  })

  it('should handle a field with leading and trailing spaces', () => {
    const field = '  profitMargin  '
    const result = getExcelNumberFormatId(field)
    expect(result).toBe('profitMarginExcelNumberFormatType')
  })

  it('should have the correct value for CELL_CLASS_FOR_EXCEL_NUMBER_FORMAT', () => {
    expect(CELL_CLASS_FOR_EXCEL_NUMBER_FORMAT).toBe('ExcelNumberFormatType')
  })
})
