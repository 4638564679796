import React from 'react'
import _ from 'lodash'
import numeral from 'numeral'
import { Button } from 'react-bootstrap'
import { SidebarSegments } from './sidebar-segments'
import { SidebarFields } from './sidebar-fields'
import { SidebarSummary } from './sidebar-summary'
import { SidebarCellOptions } from './sidebar-cell-options'
import { SidebarFilter } from './sidebar-filter'
import { removeCurrencySymbol } from '../../utils'

export default class OptionSidebar extends React.Component {
  removeCurrencySymbol(value, format) {
    const { pluginSettings: { formatting: { currencySymbol = '$' } = {} } = {} } = this.props

    return removeCurrencySymbol(numeral(value).format(format), currencySymbol)
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    const {
      props: {
        onChangeCollapse = () => {},
        isCollapsed = false,
        IsPreviewMode = false,
        Segments = [],
        Options = [],
        pluginSettings: { formatting: { current = '0,0' } = {} } = {},
        CurrentSortBy: { value: activeSortBy = '' } = {},
        optionViewColumns
      }
    } = this

    const iconClass = !isCollapsed ? 'up' : 'down'

    return (
      <div className="left-top">
        <div className="option-img-ctn">
          <div className="option-img">
            {IsPreviewMode ? null : <SidebarFilter activeSortBy={activeSortBy} />}
            {IsPreviewMode ? null : (
              <SidebarSummary
                Options={Options}
                current={current}
                removeCurrencySymbol={this.removeCurrencySymbol.bind(this)}
              />
            )}
          </div>
          <SidebarCellOptions />
          {IsPreviewMode ? null : (
            <Button
              className="assortment-collapse-btn-"
              size="sm"
              variant="outline-dark"
              onClick={onChangeCollapse.bind(this)}
            >
              <i className={`slvy-ui-icon-angle-${iconClass}-extra-lt`} />
            </Button>
          )}
        </div>
        {isCollapsed ? null : (
          <SidebarFields isHiddenFirstRow={IsPreviewMode} optionViewColumns={optionViewColumns} />
        )}
        {IsPreviewMode ? null : (
          <SidebarSegments
            current={current}
            removeCurrencySymbol={this.removeCurrencySymbol.bind(this)}
            segments={Segments}
          />
        )}
      </div>
    )
  }
}
