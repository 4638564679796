Ext.define('MDM.src.view.model.DPISegment', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/RegionApp_DPISegment',
  fields: [
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'value',
      type: 'int'
    }
  ]
})
