import React, { Component } from 'react'
import _ from 'lodash'
import Item from './item'
import '../index.scss'

class ItemList extends Component {
  render() {
    const { items = [] } = this.props
    const sortedItems = _.sortBy(items, ['name'])

    return (
      <div className="stateList">
        {_.map(sortedItems, (item) => {
          return (
            <Item
              key={item.id}
              deleteState={this.props.deleteState}
              eventKey={item.name}
              item={item}
              loadState={this.props.loadState}
              updateState={this.props.updateState}
            />
          )
        })}
      </div>
    )
  }
}
export default ItemList
