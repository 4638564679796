Ext.define('Spui.view.common.productinfo.cmp.tabpanel.SalesChart.SalesChartGrid', {
  extend: 'Ext.grid.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-saleschartgrid',
  columns: [],

  initComponent: function () {
    var me = this
    ;(me.plugins = [
      {
        ptype: 'cornerIcon',

        extraButton: [
          '->',
          {
            iconCls: 'x-fa fa-cog',
            bind: {
              hidden: '{!isAdmin}'
            },
            handler: me.onConfigureGrid,
            scope: me
          }
        ]
      }
    ]),
      me.callParent()
  }
})
