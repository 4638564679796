Ext.define('Spui.view.common.similarity.tabpanel.SalesChart.Mixin', {
  extend: 'Ext.Mixin',
  config: {
    salesChartCfg: 'SpotSalesChart',
    salesChartStoreCfg: 'SpotSalesChartStore',
    salesChartStore: {},
    salesChart: {},
    similarSpotId: null,
    salesGridCfg: 'SpotSalesGrid',
    salesGrid: false,
    SpotId: null
  },

  applySpotId: function (c) {
    return c
  },

  onChangeSelectedSimilarSpot: function () {
    var store = this.getSalesChartStore()
    var tabpabel = this.up()
    if (!tabpabel) {
      return false
    }
    if (!tabpabel.collapsed && this.isVisible()) {
      var SpotId = this.getSpotId()
      store.getProxy().setExtraParam('SpotId', SpotId)
      store.load()
      store.forceToLoad = false
    } else {
      store.forceToLoad = true
    }
  },

  forcetoLoad: function () {
    var store = this.getSalesChartStore()
    var tabpabel = this.up()
    if (!tabpabel) {
      return false
    }
    if (!tabpabel.collapsed) {
      var SpotId = this.getSpotId()
      store.getProxy().setExtraParam('SpotId', SpotId)
      if (store.forceToLoad) {
        store.load()
        store.forceToLoad = false
      }
    }
  },

  applySalesChartCfg: function (c) {
    return window.ss(c)
  },

  applySalesChartStoreCfg: function (c) {
    return window.ss(c)
  },

  applySalesGridCfg: function (c) {
    return window.ss(c)
  },

  listeners: {
    activate: function (p) {}
  },

  applySimilarSpotId: function (c) {
    var store = this.getSalesChartStore()
    store.getProxy().setExtraParam('SimilarSpotId', c)
    var tabpabel = this.up()
    if (!tabpabel.collapsed && this.isVisible()) {
      store.load()
      store.forceToLoad = false
    } else {
      store.forceToLoad = true
    }
  },

  applySalesChartStore: function (b) {
    var me = this
    var setRec = this.getSalesChartStoreCfg()
    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        fields: [
          {
            name: 'QTY',
            type: 'number'
          },
          {
            name: 'Price',
            type: 'number'
          },
          {
            name: 'NumberOfStore',
            type: 'number'
          },
          {
            name: 'Envanter',
            type: 'number'
          },
          {
            name: 'DateKey',
            type: 'string'
          },
          {
            name: 'IsSimilarTemp',
            type: 'int',
            convert: function (v) {
              if (typeof v === 'boolean') {
                return v ? 1 : 0
              } else {
                return parseInt(v, 10)
              }
            }
          },
          {
            name: 'PriceNull',
            convert: function (value, record) {
              var v = record.get('Price')
              if (!v) return null
              return v
            },
            depends: ['Price']
          },
          {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
              var v = record.get('NumberOfStore')

              if (!v) return null
              return v
            },
            depends: ['NumberOfStore']
          }
        ],
        autoSync: true,
        listeners: {
          write: function (store) {}
        },
        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          api: {
            create: Spui.Global.getApiUrl() + 'spui/SmilarSpot/create',
            read: Spui.Global.getApiUrl() + 'spui/GetSpotSalesData',
            update: Spui.Global.getApiUrl() + 'spui/SmilarSpot/update',
            destroy: Spui.Global.getApiUrl() + 'spui/SmilarSpot/destroy'
          },
          data: [
            {
              a: 1
            }
          ],
          url: Spui.Global.getApiUrl() + 'spui/SmilarSpot'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)

      b.on('beforeload', me.beforeload, me)
      b.on('load', me.onload, me)
      b.loadData([
        {
          a: 1
        }
      ])
    }
    return b
  },

  beforeload: function () {
    this.setLoading(tt('Yükleniyor'))
  },

  onload: function (store) {
    this.setLoading(false)
    var data = store.getDatas()
    if (data.length === 0) {
      store.add({ SimilarSpotId: -1 })
    }
    store.forceToLoad = false
    var proxy = store.getProxy()
    if (!proxy.extraParams || !proxy.extraParams.SimilarSpotId) {
      proxy.setExtraParam('SimilarSpotId', this.getSimilarSpotId())
    }
  },

  applySalesChart: function (b) {
    var setRec = this.getSalesChartCfg(),
      me = this,
      code = setRec.get('code'),
      settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-saleschart',
        store: this.getSalesChartStore()
      })
      b = Ext.create(b)
    }

    b.on(
      'clickChart',
      function (record) {
        var recordData = record.data
        Ext.create('Spui.view.dd.saleschart.main', {
          recordData: recordData
        }).show()
      },
      me
    )
    return b
  },

  updateSalesChart: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  onConfigSalesChart: function () {
    var setRec = this.getSalesChartCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigSalesChartSave, this)
  },

  onConfigSalesChartSave: function (v, k) {
    var setRec = this.getSalesChartCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setSalesChart(res)
  },

  onConfigSalesChartStore: function () {
    var setRec = this.getSalesChartStoreCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigSalesChartStoreSave, this)
  },

  onConfigSalesChartStoreSave: function (v, k) {
    var setRec = this.getSalesChartStoreCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setSalesChartStore(res)
  },

  onMaximize: function () {
    var me = this,
      store = me.getSalesChartStore()

    var win = Ext.create('Ext.window.Window', {
      maximized: true,
      layout: 'fit'
    })
    win.show()
    win.setLoading(tt('Yükleniyor'))
    Ext.defer(function () {
      var item = Ext.create({
        xtype: 'spui-view-common-similarity-tabpanel-saleschart-tabpanel',
        salesChartStore: store,
        ownerCt: me.ownerCt.ownerCt,
        chart: {
          title: tt('Sales Chart'),

          plugins: [
            {
              ptype: 'cornerIcon',

              cfgButton: [
                '->',
                {
                  iconCls: 'x-fa fa-cog',
                  cls: 'config-btn',
                  handler: 'onConfigureChart'
                }
              ]
            }
          ]
        },
        salesGrid: {}
      })
      win.add(item)
      win.setLoading(false)
    }, 10)
  },

  applySalesGrid: function (b) {
    if (!b) return
    var setRec = this.getSalesGridCfg()
    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      var store = Ext.create('Ext.data.ChainedStore', {
        source: this.getSalesChartStore()
      })
      Ext.applyIf(b, {
        xtype: 'grid',
        title: tt('Sales Grid'),
        store: store,
        columns: [{ text: '11' }],
        plugins: [
          {
            ptype: 'cornerIcon',

            cfgaButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigureSalesGrid'
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    return b
  },

  updateSalesGrid: function (f, g) {
    if (!f) return
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  onConfigureSalesGrid: function () {
    var setRec = this.getSalesGridCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigureSalesGridSave, this)
  },

  onConfigureSalesGridSave: function (v, k) {
    var setRec = this.getSalesGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setSalesGrid(res)
  }
})
