Ext.define('Spui.view.common.productinfo.cmp.tabpanel.Info.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-tabpanel-info-main',
  mixins: ['Spui.view.common.productinfo.cmp.tabpanel.Info.Mixin'],
  defaultListenerScope: true,
  reference: 'tabpanel-info-mixin',
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  }
})
