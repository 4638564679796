import { Nav, Tab } from 'react-bootstrap'
import { getModifiedItem } from '../helpers'
import ContainersEvent from './ContainersEvent'
import PluginsEvent from './PluginsEvent'
import { IWaitEventProps } from './WaitEvent.types'
import { IContainerEventKey, IEventKey } from '../Jobs.types'

const WaitEvent = (props: IWaitEventProps) => {
  const {
    onSave,
    params: { eventKeys = [], containerEventKeys = [] } = {},
    params,
    plugins,
    containers
  } = props

  const handleAddItem = (listName: string, obj: IEventKey | IContainerEventKey) =>
    onSave(getModifiedItem(params, listName, obj))

  return (
    <div className="p-3" data-testid="wait-event">
      <Tab.Container defaultActiveKey="plugins">
        <>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="plugins" role="button">
                Plugins
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="containers" role="button">
                Containers
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="border-0 pt-3">
            <Tab.Pane mountOnEnter eventKey="plugins" transition={false}>
              <PluginsEvent eventKeys={eventKeys} handleAddItem={handleAddItem} plugins={plugins} />
            </Tab.Pane>
            <Tab.Pane mountOnEnter eventKey="containers" transition={false}>
              <ContainersEvent
                containerEventKeys={containerEventKeys}
                containers={containers}
                handleAddItem={handleAddItem}
              />
            </Tab.Pane>
          </Tab.Content>
        </>
      </Tab.Container>
    </div>
  )
}

export default WaitEvent
