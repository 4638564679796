import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import BaseModal from '../../BaseModal'
import { useReduxState } from '../../../hooks'
import { headerSlice } from '../../../store/slices'
import useSavePlan from '../../../hooks/useRegisterEvents/useSavePlan'
import { setSavedPlan } from '../../../store/api/endpoints'

function SavePlanModal() {
  const { savePlanRef } = useSavePlan()
  const [triggerSetSavedPlan] = setSavedPlan.useLazyQuery()

  const [planName, setPlanName] = useState('')
  const [planDescription, setPlanDescription] = useState('')
  const [reSequence, setReSequence] = useState(false)

  const [{ savePlanModal }, { setSavePlanModal }] = useReduxState(
    headerSlice.selectSlice,
    headerSlice.actions
  )

  function handleSavePlanCloseButton() {
    setSavePlanModal(false)
  }

  function handleSavePlanConfirmButton() {
    savePlanRef.current.handleSavePlan()
    triggerSetSavedPlan({ planName, planDescription, reSequence, userName: null })
    handleSavePlanCloseButton()
  }

  return (
    <BaseModal isOpen={savePlanModal} onClose={handleSavePlanCloseButton}>
      <BaseModal.Header>
        <BaseModal.Title>Save Plan</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className="fs-sm">
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              Plan Name:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              Plan Description:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                value={planDescription}
                onChange={(e) => setPlanDescription(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              Resequence:
            </Form.Label>
            <Col className="d-flex align-items-center justify-content-start fs-6" sm={8}>
              <Form.Check checked={reSequence} onChange={(e) => setReSequence(e.target.checked)} />
            </Col>
          </Form.Group>
        </Form>
      </BaseModal.Body>
      <BaseModal.Footer>
        <BaseModal.ConfirmButton
          className="text-white"
          variant="success"
          onClick={handleSavePlanConfirmButton}
        />
        <BaseModal.CancelButton className="text-white" onClick={handleSavePlanCloseButton} />
      </BaseModal.Footer>
    </BaseModal>
  )
}

export default SavePlanModal
