import { getIsLookupDataField } from '../index'
import { customInfo as defaultCustomInfo } from './mocks'

const getCustomInfo = () => {
  return defaultCustomInfo
}

describe('AgGrid:Helpers:Lookup:getIsLookupDataField', () => {
  let customInfo = getCustomInfo()
  beforeEach(() => {
    customInfo = getCustomInfo()
  })

  it('should return true if customInfo?.editing?.lookupDataField is a truthy value', () => {
    customInfo.editing.lookupDataField = 'lookupCol'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsLookupDataField(customInfo)
    expect(result).toBe(true)
  })
  it('should return false if customInfo?.editing?.lookupDataField is not a truthy value', () => {
    customInfo.editing.lookupDataField = ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsLookupDataField(customInfo)
    expect(result).toBe(false)
  })
  it('should return false if customInfo?.editing?.lookupDataField is not defined', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customInfo.editing = {}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsLookupDataField(customInfo)
    expect(result).toBe(false)
  })
})
