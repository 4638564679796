import * as actionTypes from './actionTypes'
import { makeRequest, Queue } from '../../utils'

export const updateMfpData = (action) => {
  const { payload: { body: { Guid = '' } = {} } = {} } = action // TODO

  return (dispatch) => {
    dispatch(startUpdateMfpDataStart())
    const _makeRequest = Queue.enqueue(() => makeRequest(action))

    return new Promise((resolve, reject) => {
      _makeRequest
        .then((response) => {
          response = {
            ...response.data,
            Guid
          }
          dispatch(
            saveUpdateMfpData({
              payload: response
            })
          )
          resolve(response)
        })
        .catch((error) => {
          error = {
            ...error.data,
            Guid
          }
          dispatch(saveUpdateMfpDataFailed({ payload: error }))
          reject(error)
        })
    })
  }
}

export const startUpdateMfpDataStart = () => {
  return {
    type: actionTypes.UPDATE_MFP_DATA_START
  }
}

export const saveUpdateMfpData = (options) => {
  return {
    type: actionTypes.STORE_UPDATE_MFP_DATA,
    payload: options.payload
  }
}

export const increasePendingUpdateCount = () => {
  return {
    type: actionTypes.STORE_INCREASE_PENDING_UPDATES_COUNT,
    payload: {}
  }
}

export const decreasePendingUpdateCount = () => {
  return {
    type: actionTypes.STORE_DECREASE_PENDING_UPDATES_COUNT,
    payload: {}
  }
}

export const savePendingUpdateCount = (options) => {
  return {
    type: actionTypes.STORE_PENDING_UPDATES_COUNT,
    payload: options.payload
  }
}

export const saveUpdateMfpDataFailed = (options) => {
  return {
    type: actionTypes.STORE_UPDATE_MFP_DATA_FAILED,
    payload: options.payload
  }
}
