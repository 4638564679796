Ext.define('tuiv2.cmp.ServiceDialog', {
  extend: 'Ext.window.Window',
  width: 1000,
  height: 400,
  resize: false,
  title: 'Select service',
  ui: 'tui-window',
  modal: true,
  closable: true,
  message: 'mesage',
  layout: 'fit',
  viewModel: {
    data: {
      selectedRecord: false
    }
  },

  initComponent: function () {
    var me = this

    me.buildStore()

    var grid = Ext.create('Ext.grid.Panel', {
      ui: 'tui-grid',
      cls: 'tui-custom-grid',
      border: true,
      store: this.store,
      scrollable: true,
      viewConfig: {
        enableTextSelection: true
      },

      columns: [
        {
          text: 'ScenarioName',
          dataIndex: 'ScenarioName',
          width: 200
        },
        {
          text: 'RunInfoID',
          dataIndex: 'RunInfoID',
          width: 100
        },
        {
          text: 'RunStartTime',
          dataIndex: 'RunStartTime',
          width: 150,
          xtype: 'datecolumn',
          format: 'Y-m-d H:i:s'
        },

        {
          text: 'RunID',
          dataIndex: 'RunID',
          flex: 1
        },
        {
          text: 'Source RunID',
          dataIndex: 'SourceId',
          flex: 1
        }
      ],
      height: 200,
      width: 400,
      renderTo: Ext.getBody(),
      selModel: {
        selType: 'checkboxmodel',
        mode: 'SINGLE'
      },
      listeners: {
        selectionchange: function (grid, selected, eOpts) {
          var me = this,
            window = me.up('window'),
            viewModel = window.getViewModel()
          viewModel.set('selectedRecord', selected)
        }
      }
    })
    me.items = [grid]
    me.bottomTb = new Ext.toolbar.Toolbar({
      ui: 'tui-footer',
      dock: 'bottom',
      focusableContainer: false,
      ariaRole: null,
      layout: {
        pack: 'center'
      },
      items: [
        {
          text: 'OK',
          ui: 'tui-button-success',
          width: 100,
          handler: me.onOKTap,
          bind: {
            disabled: '{!selectedRecord}'
          }
        }
      ]
    })
    me.dockedItems = [me.bottomTb]
    this.callParent()
  },

  onCancelTap: function () {
    this.close()
  },

  onOKTap: function () {
    var me = this,
      window = me.up('window'),
      viewModel = window.getViewModel(),
      data = viewModel.get('selectedRecord')[0].data

    Ext.Ajax.abortAll()

    window.fireEvent('select', data)
  },

  buildStore: function () {
    this.store = Ext.create('Ext.data.Store', {
      fields: [
        {
          name: 'RunStartTime',
          type: 'date',
          format: 'c'
        },
        {
          name: 'Siparis',
          type: 'number'
        },
        {
          name: 'Karsilanan',
          type: 'number'
        },
        {
          name: 'Ratio',
          type: 'number'
        }
      ],

      proxy: {
        type: 'ajax',
        actionMethods: {
          read: 'POST'
        },
        timeout: 600000,
        url: tuiv2.Global.getApiUrl() + 'tuiv2/GetServiceLists'
      },
      autoLoad: true
    })
  }
})
