import React from 'react'

export function getGridViewData(rootOptions, options, stores, segments) {
  let NumberOfUsedOptions = 0
  _.forEach(options, (option) => {
    if (_.filter(option.SegmentOptions, ['Enabled', true]).length !== 0) {
      NumberOfUsedOptions++
    }
  })

  let groupedStores = _.groupBy(stores, 'SegmentId')
  let availableSegmentIds = _.keys(groupedStores)
  let availableSegments = _.filter(segments, (segment) => {
    return _.indexOf(availableSegmentIds, segment.Id.toString()) > -1
  })

  const firstLineData = {
    assignedOptions: `${NumberOfUsedOptions} / ${options ? options.length : 0}`,
    availableSegments: availableSegments,
    firstLine: true,
    forecastedCost: _.sumBy(options, 'TotalCost'),
    forecastedQuantity: _.sumBy(options, 'TotalSales'),
    forecastedRevenue: _.sumBy(options, 'TotalRevenue'),
    numberOfStores: _.sumBy(options, 'NumberOfActiveStores')
  }

  const gridViewData = _.concat(firstLineData, options)
  return gridViewData
}
