import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  clusterTree: {
    currentScenario: {},
    scenarioName: '',
    isFolderSelected: false
  },
  tree: {
    currentScenario: {},
    scenarioName: '',
    isFolderSelected: true
  },
  saveTree: {
    currentScenario: {},
    scenarioName: '',
    isFolderSelected: true
  }
}

const treeSelectionSlice = createSlice({
  name: 'treeSelection',
  initialState,
  reducers: {
    resetScenarioSelection: (state, action) => {
      state[action.payload.dataKey] = { ...initialState[action.payload.dataKey] }
    },
    selectScenario: (state, action) => {
      state[action.payload.dataKey] = {
        ...state[action.payload.dataKey],
        currentScenario: {
          ...action.payload.currentScenario
        }
      }
    },
    selectScenarioName: (state, action) => {
      state[action.payload.dataKey] = {
        ...state[action.payload.dataKey],
        scenarioName: action.payload.scenarioName
      }
    },
    setCurrentScenario: (state, action) => {
      state[action.payload.dataKey] = {
        ...state[action.payload.dataKey],
        currentScenario: action.payload.currentScenario
      }
    },
    toggleScenarioSelection: (state, action) => {
      state[action.payload.dataKey] = {
        ...state[action.payload.dataKey],
        isFolderSelected: action.payload.isFolderSelected
      }
    }
  }
})

export const {
  resetScenarioSelection,
  selectScenario,
  selectScenarioName,
  setCurrentScenario,
  toggleScenarioSelection
} = treeSelectionSlice.actions

export const clusterTree = (state) => {
  state.clusterTree
}
export const tree = (state) => {
  state.tree
}
export const saveTree = (state) => {
  state.saveTree
}

export default treeSelectionSlice.reducer
