import { useMemo } from 'react'
import { SlvyTable } from '@/components'
import { getServices, Service } from '../../store/api/endpoints'
import { serviceModalSlice } from '../../store/slices'
import { useReduxState } from '../../hooks'
import styles from './ServiceTable.module.scss'

export default function ServiceTable() {
  const [, serviceModalDispatch] = useReduxState(undefined, serviceModalSlice.actions)
  const servicesResponse = getServices.useQueryState()

  const columns = useMemo(
    () => [
      {
        header: 'Scenario Name',
        accessorKey: 'ScenarioName'
      },
      {
        header: 'Run Info Id',
        accessorKey: 'RunInfoID',
        maxSize: 100
      },
      {
        header: 'Run Start Time',
        accessorKey: 'RunStartTime',
        maxSize: 160,
        meta: {
          format: 'YYYY-MM-DD, h:mm:ss',
          dataType: 'datetime'
        }
      },
      {
        header: 'Run Id',
        accessorKey: 'RunID',
        minSize: 280
      },
      {
        header: 'Source Run Id',
        accessorKey: 'SourceId',
        minSize: 280
      }
    ],
    []
  )

  function handleSelectRow({ original }: { original: Service }) {
    serviceModalDispatch.setSelectedService(original)
  }

  return (
    <SlvyTable
      className={styles.serviceTable}
      columns={columns}
      data={servicesResponse.data}
      rowSelection="single"
      theme="sencha-grid"
      onSelectRow={handleSelectRow}
    />
  )
}
