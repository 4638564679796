Ext.define('po.cmp.InventoryChart', {
  extend: 'Chart.ux.Highcharts',
  alias: 'widget.poInventoryChart',
  config: {
    store: null,
    i18n: null
  },
  setI18n: function (i18n) {
    var me = this
    if (i18n == null) return

    me.chartConfig.series[0].name = i18n.InventoryChart_series_name_Envanter
    me.chartConfig.series[1].name = i18n.InventoryChart_series_name_Acilan_Miktar
    me.chartConfig.series[2].name = i18n.InventoryChart_series_name_Kapanan_Miktar
    me.chartConfig.series[3].name = i18n.InventoryChart_series_name_NetEnvater
    me.chartConfig.yAxis.title.text = i18n.InventoryChart_yAxis_title_text
    me.chartConfig.lang.printChart = i18n.printChart
    me.chartConfig.lang.downloadJPEG = i18n.downloadJPEG
    me.chartConfig.lang.downloadPDF = i18n.downloadPDF
    me.chartConfig.lang.downloadPNG = i18n.downloadPNG
    me.chartConfig.lang.downloadSVG = i18n.downloadSVG
    me.chartConfig.lang.downloadXLS = i18n.downloadXLS
    me.refresh()
  },
  setStore: function (store) {
    var me = this
    if (store != null) {
      store.on('load', function () {
        me.draw()
      })
      this.bindStore(store, true)
    }

    return store
  },
  initComponent: function () {
    if (this.animation == false) {
      this.initAnim = false
      this.updateAnim = false
      this.initAnimAfterLoad = false
    }
    this.callParent(arguments)
  },
  xField: 'tarih',
  series: [
    {
      dataIndex: 'Envanter',
      name: 'Envanter',
      visible: true,
      color: '#FF8600'
    },
    {
      dataIndex: 'Acilan_Miktar',
      name: 'Açılan SAS',
      type: 'column',
      visible: true,
      color: 'blue'
    },
    {
      dataIndex: 'Kapanan_Miktar',
      name: 'Kapanan SAS',
      visible: true,
      type: 'column',
      color: 'red'
    },
    {
      dataIndex: 'NetEnvater',
      name: 'Net Envanter',
      visible: true,
      type: 'line',
      color: 'green'
    }
  ],
  chartConfig: {
    chart: {
      type: 'areaspline'
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },

    lang: {
      printChart: 'Grafiği yazdır',
      downloadJPEG: 'JPEG Dosyası olarak indir',
      downloadPDF: 'PDF Dosyası olarak indir',
      downloadPNG: 'PNG Dosyası olarak indir',
      downloadSVG: 'SVG vektör dosyası olarak indir',
      downloadXLS: 'XLS Dosyası olarak indir'
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.y:.2f}</b><br/>',
      shared: true,
      crosshairs: true
    },
    title: {
      text: '',
      style: {
        fontSize: '16px'
      }
    },
    yAxis: {
      title: {
        text: 'Envanter'
      },
      labels: {
        style: {
          fontSize: '13px'
        }
      }
    },
    xAxis: {
      plotBands: [
        {
          from: 0,
          to: 30,
          color: 'rgba(167, 203, 235, 0.5)',
          className: 'highchart-plotbands'
        }
      ],
      type: 'datetime',
      labels: {
        formatter: function () {
          var me = this,
            tarih = me.value
          if (tarih && typeof tarih === 'string') return tarih.substr(5)
          else return '-'
        }
      }
    },
    credits: {
      text: 'SOLVOYO © 2018',
      href: 'http://www.solvoyo.com',
      position: {
        align: 'right',
        y: -5
      },
      style: {
        fontSize: '7pt'
      }
    }
  }
})
