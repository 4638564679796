export default () => {
  return {
    title: 'D3 Heatmap',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },

      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },

      Settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          singleTitle: {
            type: 'boolean',
            title: 'Single Title',
            default: false,
            required: true
          },
          singleLegend: {
            type: 'boolean',
            title: 'Single Legend',
            default: false,
            required: true
          },
          showColorScale: {
            type: 'boolean',
            title: 'ShowColorScale ',
            default: true,
            required: true
          },
          scaleType: {
            type: 'string',
            title: 'Scale Type',
            default: 'linear',
            required: true,
            enum: ['linear', 'ordinal', 'threshold']
          },
          showSum: {
            type: 'boolean',
            title: 'Show Sum',
            default: false,
            required: true
          },
          x: {
            type: 'string',
            title: 'X',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          xOrder: {
            type: 'string',
            title: 'X (Sort Index)',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          y: {
            type: 'string',
            title: 'Y',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          yOrder: {
            type: 'string',
            title: 'Y (Sort Index)',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          autoRotate: {
            type: 'boolean',
            title: 'Auto Rotate',
            default: false,
            required: true
          },
          angle: {
            type: 'integer',
            title: 'Angle',
            format: 'range',
            minimum: 30,
            maximum: 90,
            default: 50
          },
          angleValue: {
            type: 'integer',
            title: ' ',
            template: '{{angleValue}}',
            watch: {
              angleValue: 'Settings.angle'
            }
          }
        }
      },

      columns: {
        type: 'array',
        title: 'Series',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: '{{self.serietitle}}',

          properties: {
            serietitle: {
              type: 'string',
              title: 'Serie Title'
            },
            z: {
              type: 'string',
              title: 'Z',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },

            tooltipType: {
              type: 'string',
              title: 'Tooltip Type',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            icon: {
              type: 'string',
              title: 'Icon',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            iconColor: {
              type: 'string',
              format: 'Color',
              title: 'Icon Color',
              default: '#ffffff'
            },
            colors: {
              type: 'array',
              // format: 'table',
              title: 'Color',
              default: [
                { color: '#ffffff', value: 0 },
                { color: '#FFEDA0', value: 0.05 },
                { color: '#FED976', value: 0.09 },
                { color: '#FEB24C', value: 0.1 },
                { color: '#FD8D3C', value: 0.3 },
                { color: '#FC4E2A', value: 0.5 },
                { color: '#E31A1C', value: 1 }
              ],
              items: {
                type: 'object',
                properties: {
                  color: {
                    type: 'string',
                    format: 'Color',
                    title: 'Color'
                  },
                  value: {
                    type: 'number',
                    title: 'Value'
                  }
                }
              }
            },
            tooltip: {
              type: 'array',
              title: 'Tooltip',
              items: {
                type: 'object',
                properties: {
                  title: {
                    type: 'string',
                    title: 'Title'
                  },
                  value: {
                    type: 'string',
                    title: 'Value',
                    watch: {
                      ds: 'datafields'
                    },
                    enumSource: 'ds'
                  },
                  aggregation: {
                    type: 'string',
                    title: 'Aggregation',
                    default: 'primary',
                    enum: ['sum', 'min', 'max', 'avg'],
                    required: true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
