import { compareValuesCaseInsensitive } from '../common'

describe('AgGrid:Helpers:Common:compareValuesCaseInsensitive', () => {
  it('should return -1 when the first string is less than the second string', () => {
    const result = compareValuesCaseInsensitive('apple', 'banana')
    expect(result).toBe(-1)
  })
  it('should return 1 when the first string is greater than the second string', () => {
    const result = compareValuesCaseInsensitive('banana', 'apple')
    expect(result).toBe(1)
  })

  it('should return 0 when both strings are equal', () => {
    const result = compareValuesCaseInsensitive('apple', 'apple')
    expect(result).toBe(0)
  })

  it('should handle case insensitivity', () => {
    const result = compareValuesCaseInsensitive('Apple', 'apple')
    expect(result).toBe(0)
  })

  it('should handle undefined values', () => {
    const result = compareValuesCaseInsensitive(undefined, 'apple')
    expect(result).toBe(-1)
  })

  it('should handle empty strings', () => {
    const result = compareValuesCaseInsensitive('', 'banana')
    expect(result).toBe(-1)
  })
})
