import { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { SketchPicker } from 'react-color'
import { Button, DropdownButton, Dropdown } from 'react-bootstrap'
import fontSizeOptions from './constants'

import styles from './index.module.scss'

export default class StyleEditor extends Component {
  constructor(props) {
    super(props)

    this.handleChangeColor = this.handleChangeColor.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleBoldButtonClick = this.handleBoldButtonClick.bind(this)
    this.handleItalicButtonClick = this.handleItalicButtonClick.bind(this)
    this.handleFontSizeSelect = this.handleFontSizeSelect.bind(this)

    this.state = {
      displayColorPicker: false
    }
  }

  UNSAFE_componentWillMount() {
    const { schema = {}, value } = this.props
    const val = !_.isEmpty(value) ? value : schema.default
    this.setState({ value: val })
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleChangeColor = (color) => {
    const colorCode = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`

    const newStyle = { ...this.state.value, color: colorCode }

    this.setState({ value: newStyle })
  }

  handleClear(e) {
    e.preventDefault()
    e.stopPropagation()

    const newStyle = { ...this.state.value, color: '' }

    this.setState({ value: newStyle })
    this.props.onChange(newStyle)
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
    this.props.onChange(this.state.value)
  }

  handleBoldButtonClick() {
    let newStyle = { ...this.state.value, fontWeight: 'bold' }
    if (this.state.value && this.state.value.fontWeight === 'bold') {
      newStyle = { ...this.state.value, fontWeight: 'normal' }
    }

    this.setState({ value: newStyle })
    this.props.onChange(newStyle)
  }

  handleItalicButtonClick() {
    let newStyle = { ...this.state.value, fontStyle: 'italic' }
    if (this.props.value && this.props.value.fontStyle === 'italic') {
      newStyle = { ...this.props.value, fontStyle: 'normal' }
    }

    this.setState({ value: newStyle })
    this.props.onChange(newStyle)
  }

  handleFontSizeSelect(eventKey, event) {
    const newStyle = { ...this.state.value, fontSize: eventKey }

    this.setState({ value: newStyle })
    this.props.onChange(newStyle)
  }

  render() {
    const style = this.state.value

    const color = {
      background: style && style.color
    }

    const isBold = style?.fontWeight === 'bold'
    const isItalic = style?.fontStyle === 'italic'
    const fontSize = style?.fontSize ?? ''

    return (
      <div className="d-flex flex-wrap gap-1">
        <Button
          active={isBold}
          className={cx('fw-bold', styles.ffTahoma)}
          size="sm"
          variant="outline-dark"
          onClick={this.handleBoldButtonClick}
        >
          B
        </Button>
        <Button
          active={isItalic}
          className={cx('fst-italic', styles.italicBtn, styles.ffTahoma)}
          size="sm"
          variant="outline-dark"
          onClick={this.handleItalicButtonClick}
        >
          I
        </Button>
        <DropdownButton
          className={styles.fontSizeDropdown}
          id="dropdown-size-small"
          size="sm"
          variant="outline-dark"
          title={fontSize || '    '}
          onSelect={this.handleFontSizeSelect}
        >
          <Dropdown.Item eventKey="" />
          {fontSizeOptions.map((fontSizeOption) => {
            return (
              <Dropdown.Item key={fontSizeOption} eventKey={fontSizeOption}>
                {fontSizeOption}
              </Dropdown.Item>
            )
          })}
        </DropdownButton>

        <div
          className={cx(
            'd-flex',
            'justify-content-between',
            'align-items-center',
            'rounded-1',
            'px-1',
            'cp',
            styles.colorBox
          )}
          onClick={this.handleClick}
        >
          <div className={styles.innerColorBox} style={color} />
          <button className="p-0 bg-transparent border-0" type="button">
            <i className="fa fa-times text-muted" onClick={this.handleClear} />
          </button>
          {this.state.displayColorPicker ? (
            <div
              className={cx('position-absolute', styles.popover)}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="position-fixed top-0 end-0 bottom-0 start-0"
                onClick={this.handleClose}
              />
              <SketchPicker color={style && style.color} onChange={this.handleChangeColor} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
