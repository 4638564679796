Ext.define('Spui.view.main.toolbar.ChangeLang', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-main-toolbar-changelang',

  bodyPadding: 10,
  width: 500,
  frame: false,
  border: true,
  defaults: {
    anchor: '100%'
  },
  viewModel: {},
  // The fields
  defaultType: 'textfield',
  initComponent: function () {
    var me = this,
      tpl = new Ext.create(
        'Ext.XTemplate',
        '<tpl for=".">',
        '<tpl for="Department" if="this.shouldShowHeader(Department)"><div class="group-header">{[this.showHeader(values.Department)]}</div></tpl>',
        '<div class="x-boundlist-item">{UserRoleCode} - {UserRoleDescriptions}</div>',
        '</tpl>',
        {
          shouldShowHeader: function (group) {
            return this.currentGroup !== group
          },
          showHeader: function (group) {
            this.currentGroup = group
            return group
          }
        }
      )

    me.items = [
      {
        // Fieldset in Column 1 - collapsible via toggle button
        xtype: 'fieldset',
        columnWidth: 0.5,
        title: 'Language Change',
        collapsible: true,
        border: true,
        defaults: {
          anchor: '100%'
        },
        layout: 'anchor',
        items: [
          {
            xtype: 'combo',
            fieldLabel: 'Select Language',
            forceSelection: true,
            editable: false,

            width: 400,

            labelAlign: 'right',
            translateData: true,
            store: [
              ['en', 'English'],
              ['cs', 'Czech'],
              ['de', 'German'],
              ['tr', 'Turkish']
            ],
            value: Ext.locale || 'en',
            listeners: {
              change: 'onChangeCultere',
              scope: this
            }
          }
        ]
      }
    ]
    me.callParent()
  },
  onChangeCultere: function (btn, d) {
    var me = this
    //me.getView().setLoading(tt('Changing user cultere.. please wait'));
    btn.up('menu').up('menu').hide()
    Spui.util.Mask.mask('Changing user cultere.. please wait')
    Ext.locale = d
    Ext.Ajax.request({
      url: Spui.Global.getApiUrl() + '/spui/SetUserCultere',
      method: 'POST',
      params: {
        cultere: d
      },

      success: function (response, opts) {
        Spui.util.Mask.unMask('Changing user cultere.. please wait')
        me.fireEvent('culterChange', this)
        //me.up('spui-view-main-main').getController().changeView();
      },

      failure: function (response, opts) {
        console.log('server-side failure with status code ' + response.status)
      }
    })
  }
})
