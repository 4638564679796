import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import * as d3 from 'd3'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import fontAwesomeUnicodeMap from '@/assets/scripts/font-awesome-unicode-map'
import './style.scss'

class D3Heatmap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      xCol: [],
      yCol: [],
      data: [],
      singleTitle: false,
      singleLegend: false,
      autoRotate: false,
      angleValue: 50
    }
    this.svg = undefined
  }

  componentDidMount() {
    const fields = _.get(this.props, 'settings.query.fields', [])
    this.handleItemClick = this.props.registerEvent({
      key: 'onClickItem',
      fn: this.handleItemClick.bind(this),
      returnTypes: _.transform(
        fields,
        (result, field) => {
          result[field.fieldName] = PluginTypes.fromString(field.dataType)
        },
        {}
      )
    })
  }

  componentDidUpdate() {
    if (!_.isEmpty(this.props.settings.config)) {
      this.execute()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.settings.config)) {
      const { columns, Settings: { singleTitle, singleLegend } = {} } = nextProps.settings.config
      this.setState({
        columns,
        singleTitle,
        singleLegend
      })
      if (this.props.isMaximized !== nextProps.isMaximized) {
        this.execute()
      }
    }
  }

  handleItemClick(value) {
    return value.data
  }

  execute() {
    const { columns } = this.props.settings.config
    const columnsLength = columns.length
    const containerWidth = ReactDOM.findDOMNode(this.container).parentNode.clientWidth
    columns.forEach((item, index) => this.design(item, index, containerWidth, columnsLength))
  }

  design(config, index, containerW, colLength) {
    const containerWidth = containerW / colLength
    const svgElement = `.slvyD3Heatmap #svg-container-${index}`

    let {
      pluginData: newData,
      settings: {
        config: {
          Settings: {
            x,
            y,
            singleTitle,
            singleLegend,
            showColorScale = true,
            scaleType = 'linear',
            showSum = false,
            xOrder,
            yOrder,
            autoRotate,
            angleValue
          } = {}
        } = {}
      } = {},
      getFormattedValue
    } = this.props

    const { z, icon, iconColors = '#ffffff', colors, tooltip, tooltipType = 0 } = config
    let xCol = []
    let yCol = []

    const sumElementsHeight = _.size(tooltip) * 12

    x = x || config.x
    y = y || config.y

    const data = _.map(newData, (o) => {
      yCol.push({ v: o[y], i: o[yOrder] })
      xCol.push({ v: o[x], i: o[xOrder] })
      return {
        data: o,
        x: o[x],
        y: o[y],
        z: parseFloat(o[z]),
        tooltipType: o[tooltipType] || 0,
        icon: o[icon],
        tooltip: _.map(tooltip, (t) => {
          return {
            title: t.title,
            value: o[t.value],
            col: t.value,
            aggregation: t.aggregation || 'sum'
          }
        })
      }
    })

    if (xCol.length > 0 && yCol.length > 0) {
      xCol = _.uniq(
        _.map(
          _.sortBy(xCol, (x) => x.i),
          (x) => x.v
        )
      )
      yCol = _.uniq(
        _.map(
          _.sortBy(yCol, (x) => x.i),
          (x) => x.v
        )
      )
      _.each(yCol, (yy) => {
        _.each(xCol, (xx) => {
          if (!_.find(data, (d) => d.x === xx && d.y === yy)) {
            data.push({
              data: {},
              x: xx,
              y: yy,
              z: 0,
              tooltipType: 1,
              icon: null,
              tooltip: []
            })
          }
        })
      })
      const margin = { top: 30, right: 0, bottom: 5, left: 50 }

      const width = containerWidth + margin.left - 40

      const height = ReactDOM.findDOMNode(this.container).parentNode.clientHeight

      const gridSizeH = Math.floor(height / yCol.length)

      const gridSizeW = Math.floor(width / xCol.length)

      let legendElementWidth = width / colors.length

      let legendElementHeight = 40

      let maxLength = 0

      for (let i = 0; i < xCol.length; i++) {
        maxLength = Math.max(maxLength, xCol[i] ? xCol[i].length : 0)
      }

      d3.selectAll(`${svgElement} > svg > g > *`).remove()
      d3.select(`.slvyD3Heatmap div.d3-tip#tooltip-${index}`).remove()
      d3.selectAll(`.slvyD3Heatmap div.cft footer > svg > g > *`).remove()

      const viewBoxRatio = containerWidth + margin.left * 2

      const viewBox = autoRotate
        ? `0 ${-angleValue * 0.8} ${showSum ? viewBoxRatio + 60 : viewBoxRatio} ${
            height +
            (margin.top + margin.bottom) +
            legendElementHeight * 2 +
            sumElementsHeight +
            angleValue
          }`
        : `0 -20 ${showSum ? viewBoxRatio + 60 : viewBoxRatio} ${
            height + (margin.top + margin.bottom) + legendElementHeight * 2 + sumElementsHeight
          }`

      this.svg = d3
        .select(svgElement)
        .style('background-color', '#fff')
        .select('.slvyD3Heatmap svg')
        .attr('width', '100%')
        .attr('viewBox', viewBox)

      if (maxLength > 6) {
        d3.select('.slvyD3Heatmap svg').style('padding', `${maxLength * 3}px`)
      }
      d3.select('.slvyD3Heatmap svg')
        .attr('height', height)
        .select('g')
        .attr('transform', `translate(50,20)`)

      if (index === 0) {
        const x = this.svg.selectAll('.dayLabel').data(yCol)
        x.enter()
          .append('text')
          .text((d) => d)
          .attr('x', 0)
          .attr('y', (d, i) => i * gridSizeH)
          .style('text-anchor', 'end')
          .attr('transform', `translate(-3,${gridSizeH / 2})`)
          .attr('class', 'dayLabel mono axis')
        x.exit().remove()
      }

      if (showSum) {
        const allSum = _.transform(
          tooltip,
          (r, t) => {
            r[t.value] = 0
          },
          {}
        )

        const xSum = _.map(xCol, (x) => {
          const rowData = _.filter(data, (d) => d.x === x)
          const size = _.size(rowData)
          return _.transform(
            rowData,
            (result, data) => {
              _.each(data.tooltip, (tp) => {
                switch (tp.aggregation) {
                  case 'sum':
                    result[tp.col] += parseFloat(tp.value)
                    allSum[tp.col] += parseFloat(tp.value)
                    break
                  case 'max':
                    result[tp.col] = _.max([
                      parseFloat(tp.value),
                      result[tp.col] || parseFloat(tp.value)
                    ])
                    allSum[tp.col] = _.max([
                      parseFloat(tp.value),
                      result[tp.col] || parseFloat(tp.value)
                    ])
                    break
                  case 'min':
                    result[tp.col] = _.min([
                      parseFloat(tp.value),
                      result[tp.col] || parseFloat(tp.value)
                    ])
                    allSum[tp.col] = _.min([
                      parseFloat(tp.value),
                      result[tp.col] || parseFloat(tp.value)
                    ])
                    break
                  case 'avg':
                    result[tp.col] += parseFloat(tp.value) / size
                    allSum[tp.col] += parseFloat(tp.value) / size
                    break
                  default:
                    break
                }
              })
            },
            _.transform(
              tooltip,
              (r, t) => {
                r[t.value] = 0
              },
              {}
            )
          )
        })
        const ySum = _.map(yCol, (y) => {
          const rowData = _.filter(data, (d) => d.y === y)
          const size = _.size(rowData)
          return _.transform(
            rowData,
            (result, data) => {
              _.each(data.tooltip, (tp) => {
                switch (tp.aggregation) {
                  case 'sum':
                    result[tp.col] += parseFloat(tp.value)
                    break
                  case 'max':
                    result[tp.col] = _.max([
                      parseFloat(tp.value),
                      result[tp.col] || parseFloat(tp.value)
                    ])
                    break
                  case 'min':
                    result[tp.col] = _.min([
                      parseFloat(tp.value),
                      result[tp.col] || parseFloat(tp.value)
                    ])
                    break
                  case 'avg':
                    result[tp.col] += parseFloat(tp.value) / size
                    break
                  default:
                    break
                }
              })
            },
            _.transform(
              tooltip,
              (r, t) => {
                r[t.value] = 0
              },
              {}
            )
          )
        })

        this.svg
          .selectAll('.sumlabel')
          .data([allSum])
          .enter()
          .append('foreignObject')
          .attr('height', gridSizeH)
          .attr('width', gridSizeW / 2)
          .html((d) => {
            return `<div class="foreign-bottom-right"><table>
          ${_.reduce(
            d,
            (r, t, f) => {
              return `${r}<tr>
                <td class="text-right">${getFormattedValue(f, t)}</td>
              </tr>`
            },
            ''
          )}
        </div></table>`
          })
          .attr('x', width + 20)
          .attr('y', height + 20)
          .attr('style', 'font-weight:800')

        this.svg
          .selectAll('.slvyD3Heatmap .tttimeLabel2')
          .data([tooltip])
          .enter()
          .append('foreignObject')
          .attr('height', gridSizeH)
          .attr('width', gridSizeW)
          .attr('transform', 'translate(-52,0)')
          .html((d) => {
            return `<div class="foreign-bottom-left"><table>
            ${_.reduce(
              d,
              (r, t) => {
                return `${r}<tr>
                  <td class="text-right">${t.title}</td>
                </tr>`
              },
              ''
            )}
          </div></table>`
          })
          .attr('x', 0)
          .attr('y', height + 20)

        this.svg
          .selectAll('.slvyD3Heatmap .timeLabel2')
          .data(xSum)
          .enter()
          .append('foreignObject')
          .attr('height', gridSizeH)
          .attr('width', gridSizeW)
          .html((d) => {
            return `<div><table style="width:100%">
              ${_.reduce(
                d,
                (r, t, f) => {
                  return `${r}<tr style="width:100%">
                    <td class="text-right">${getFormattedValue(f, t)}</td>
                  </tr>`
                },
                ''
              )}
            </div></table>`
          })
          .attr('x', (d, i) => (i === 0 ? 4 : i * gridSizeW))
          .attr('y', height + 20)

        this.svg
          .selectAll('.slvyD3Heatmap .timeLabel2')
          .data(ySum)
          .enter()
          .append('foreignObject')
          .attr('height', gridSizeH)
          .attr('width', gridSizeW / 2)
          .html((d) => {
            return `<div class="foreign-right"><table>
              ${_.reduce(
                d,
                (r, t, f) => {
                  return `${r}<tr>
                    <td class="text-right">${getFormattedValue(f, t)}</td>
                  </tr>`
                },
                ''
              )}
            </div></table>`
          })
          .attr('x', () => width + 20)
          .attr('y', (d, i) => i * gridSizeH)
      }

      this.svg.selectAll('.slvyD3Heatmap .timeLabel').remove('text')

      const y = this.svg.selectAll('.slvyD3Heatmap .timeLabel').data(xCol)

      const yText = y.enter().append('text')

      yText
        .text((d) => d)
        .attr('x', (d, i) => i * gridSizeW)
        .attr('y', 0)
        .attr('transform', `translate(${gridSizeW / 2}, -3)`)
        .attr('class', 'timeLabel mono axis')
        .attr('y', 0)
      if (autoRotate) {
        const dy = angleValue > 50 ? '0.9em' : '0.2em'
        yText
          .style('text-anchor', 'initial')
          .attr('dx', `${(0.8 * 30) / xCol.length}em`)
          .attr('dy', dy)
          .style('transform', `rotate(-${angleValue}deg)`)
          .style('transform-origin', (d, i) => `${i * gridSizeW}px 0px 0px`)
      } else {
        yText.style('text-anchor', 'middle')
      }

      y.exit().remove()

      const step = 0.001
      let counter = 0
      const numbers = []
      const ordColours = []
      const domain = _.map(colors, (c) => c.value)

      const range = _.map(colors, (c) => c.color)

      let colorScale = {}

      switch (scaleType) {
        case 'linear':
          domain.unshift(Number.MIN_SAFE_INTEGER)
          domain.push(Number.MAX_SAFE_INTEGER)

          range.unshift(colors[0].color)
          range.push(colors[counter].color)

          colorScale = d3.scaleLinear().domain(domain).range(range)
          break
        case 'ordinal':
          numbers.push(domain[0])
          ordColours.push(range[0])
          var i = domain[0]
          do {
            var c = parseFloat(i + step).toFixed(3)
            numbers.push(c)
            if (domain[counter + 1] !== undefined) {
              if (c < domain[counter + 1]) {
                ordColours.push(colors[counter].color)
              } else {
                counter++
                ordColours.push(colors[counter].color)
              }
            } else {
              ordColours.push(colors[counter].color)
            }
            i += 0.001
          } while (c <= domain[domain.length - 1])

          colorScale = d3.scaleQuantile().domain(numbers).range(ordColours)
          break
        case 'threshold':
          colorScale = d3.scaleThreshold().domain(domain).range(range)
          break

        default:
          break
      }

      const d3tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'd3-tip')
        .attr('id', `tooltip-${index}`)
        .style('position', 'fixed')
        .style('z-index', '99999999999')
        .style('visibility', 'hidden')

      const cards = this.svg.selectAll('.hour').data(data, (d) => {
        return `${d.x}:${d.y}:${d.z}`
      })

      cards.append('title')

      cards
        .enter()
        .append('rect')
        .attr('x', (d) => {
          return _.indexOf(xCol, d.x) * gridSizeW
        })
        .attr('y', (d) => {
          return _.indexOf(yCol, d.y) * gridSizeH
        })
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('class', 'hour bordered')
        .attr('width', gridSizeW)
        .attr('height', gridSizeH)
        .style('fill', (d) => colorScale(isNaN(d.z) ? 0 : d.z))
        .on('click', (d) => this.handleItemClick(d))
        .on('mouseover', (d) => {
          if (d.tooltipType === 0) {
            let html = '<table class="tipTable"><tbody>'
            _.each(d.tooltip, (t) => {
              let value = getFormattedValue(t.col, t.value)
              value = value || ''
              html += `<tr><td class="tipTableFirst">${t.title}</td><td  class="text-right">${value}</td></tr>`
            })
            html += `</tbody></table>`
            return d3tooltip.style('visibility', 'visible').html(html)
          }
        })
        .on('mousemove', (d) => {
          if (d.tooltipType === 0) {
            const pos = width - d3.event.offsetX
            const x =
              pos > d3tooltip.node().getBoundingClientRect().width
                ? d3.event.pageX + 10
                : d3.event.pageX - d3tooltip.node().getBoundingClientRect().width - 10
            return d3tooltip.style('top', `${d3.event.pageY - 10}px`).style('left', `${x}px`)
          }
        })
        .on('mouseout', (d) => {
          if (d.tooltipType === 0) {
            return d3tooltip.style('visibility', 'hidden')
          }
        })

      /// Foreign Object
      cards
        .enter()
        .append('foreignObject')
        .attr('class', (d, e) => {
          return `foreign-object-${index}-${e}`
        })

      cards
        .enter()
        .append('text')
        .attr('style', (d) => {
          if (d.tooltipType === 0) {
            return 'pointer-events : none;  text-shadow: 0 0 2px #000;'
          }
        })
        .attr('font-family', (d) => {
          if (d.tooltipType !== 0) {
            return 'Roboto'
          }
          return 'FontAwesome'
        })
        .attr('x', (d) => {
          return _.indexOf(xCol, d.x) * gridSizeW + gridSizeW / 2 - 6
        })
        .attr('y', (d) => {
          return _.indexOf(yCol, d.y) * gridSizeH + gridSizeH / 2 + 5
        })
        .style('fill', (d) => {
          if (d.tooltipType !== 0) {
            return '#747474'
          }
          return iconColors
        })
        .text((d, g) => {
          if (d.tooltipType !== 0) {
            const foreignObjectElement = d3.select(`.foreign-object-${index}-${g}`)

            foreignObjectElement
              .attr('x', (d) => {
                return _.indexOf(xCol, d.x) * gridSizeW
              })
              .attr('y', (d) => {
                return _.indexOf(yCol, d.y) * gridSizeH
              })
              .attr('rx', 4)
              .attr('ry', 4)
              .attr('width', gridSizeW)
              .attr('height', gridSizeH)
              .append('xhtml:div')
              .attr('class', 'foreign-tooltip')
              .append('table')
              .attr('class', `f-table-item ft-${index}-${g}`)
              .each((d, e) => {
                const tooltipElement = d3.select(`.ft-${index}-${g}`)
                const html = _.reduce(
                  d.tooltip,
                  (r, t) => {
                    const value = getFormattedValue(t.col, t.value) || ''

                    return `${r}<tr>
                        <td>${t.title}</td>
                        <td class="text-right">${value}</td>
                      </tr>`
                  },
                  ''
                )
                tooltipElement.html(html)
              })
            foreignObjectElement.on('click', (d) => {
              this.handleItemClick(d)
            })
          } else {
            return fontAwesomeUnicodeMap[d.icon]
          }
        })

      cards.select('title').text((d) => {
        return `${d.x}:${d.y}:${d.z}`
      })

      cards.exit().remove()

      if (showColorScale) {
        /// Legend Element
        legendElementHeight = 30

        const calculatedWidth = (width + 40) * colLength

        const singleWidth = (containerWidth - margin.left * 2) * colLength

        const legend = this.svg.append('g').attr('transform', 'translate(0,18)')
        if (autoRotate) {
          legend.attr('y', 600)
        }

        const renderLegedenHeader = (index) => {
          legend
            .selectAll(`.legend-${index}`)
            .data('H', (d) => d)
            .enter()
            .append('text')
            .attr('class', 'mono m-header')
            .attr('x', singleTitle ? calculatedWidth / 2 - config.serietitle.length * 4 : 0)
            .attr('y', height + 10)
            .text(() => config.serietitle)
        }

        const renderLegend = (index) => {
          legendElementWidth = singleLegend ? singleWidth / colors.length : legendElementWidth

          legend
            .selectAll(`.legend-${index}`)
            .data(
              _.map(colors, (c) => c.value),
              (d) => d
            )
            .enter()
            .append('rect')
            .attr('x', (d, i) => {
              return (
                (singleLegend ? (calculatedWidth - singleWidth) / 2 : 0) + legendElementWidth * i
              )
            })
            .attr('y', height + legendElementHeight + sumElementsHeight - 29)
            .attr('width', legendElementWidth)
            .attr('height', legendElementHeight - 5)
            .style('fill', (d, i) => {
              return _.map(colors, (c) => c.color)[i]
            })
            .style('stroke', '#e6e6e6')

          legend
            .selectAll(`.legend-${index}`)
            .data(
              _.map(colors, (c) => c.value),
              (d) => d
            )
            .enter()
            .append('text')
            .attr('class', 'mono')
            .text((d) => `≥ ${d.toFixed(2)}`)
            .attr('x', (d, i) => {
              return (
                (singleLegend ? (calculatedWidth - singleWidth) / 2 : 0) + legendElementWidth * i
              )
            })
            .attr(
              'y',
              height +
                legendElementHeight +
                25 +
                legendElementHeight / 2 +
                5 +
                sumElementsHeight -
                29
            )
        }

        d3.selectAll('#svg-container-0').classed('single-legend', singleLegend || singleTitle)

        if (singleLegend) {
          if (index === 0) {
            renderLegend(0)
          }
        } else {
          renderLegend(index)
        }

        if (singleTitle) {
          if (index === 0) {
            renderLegedenHeader(0)
          }
        } else {
          renderLegedenHeader(index)
        }

        legend.exit().remove()
      }
    }
  }

  render() {
    const { columns } = this.state
    return (
      <div ref={(container) => (this.container = container)}>
        <div className="slvyD3Heatmap" id="chart">
          <div className="cc-row">
            {_.map(columns, ({ item }, index) => {
              return (
                <div key={index.toString()} id={`svg-container-${index}`}>
                  <svg>
                    <g />
                  </svg>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  settings: props.settings,
  isMaximized: props.isMaximized,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(D3Heatmap, selectConnectorProps)
