import { fetchCollection, fetchOne, fetchRecord, updateOne, deleteRecord } from '../crudoptV3'

const model = 'user'
const model2 = 'usergroupuser'

export const getUsers = (catalogCustomerId) => {
  return fetchCollection(
    model,
    '/user',
    { params: { sort: 'name', catalogCustomerId } },
    { getRowKey: (row, index) => row.userId }
  )
}

export const getUser = (userId) => {
  return fetchOne(
    model,
    `/user/${userId}`,
    {},
    { method: 'get', key: userId, getRowKey: (row, index) => row.userId }
  )
}

export const createUser = (customerId, userName, name, surname, email, password, phoneNumber) => {
  return fetchRecord(
    model,
    `/user`,
    {
      data: {
        CustomerId: customerId,
        UserName: userName,
        Name: name,
        Surname: surname,
        Email: email,
        Password: password,
        PhoneNumber: phoneNumber
      }
    },
    {
      collectionKey: {
        path: `/user`,
        params: { sort: 'name', catalogCustomerId: customerId }
      },
      getRowKey: (row, index) => row.userId
    }
  )
}

export const updateUser = (userId, customerId, name, surname, email, phoneNumber) => {
  return updateOne(
    model,
    `/user`,
    {
      data: {
        UserId: userId,
        CustomerId: customerId,
        Name: name,
        Surname: surname,
        Email: email,
        PhoneNumber: phoneNumber
      }
    },
    {
      key: userId,
      collectionKey: { path: `/user`, params: { sort: 'name' } },
      getRowKey: (row, index) => row.userId
    }
  )
}

export const deleteUser = (userId, customerId) => {
  return deleteRecord(
    model,
    `/user/${userId}`,
    { data: { userId } },
    {
      key: userId,
      getRowKey: () => userId,
      collectionKey: {
        path: `/user`,
        params: { sort: 'name', catalogCustomerId: customerId }
      }
    }
  )
}

export const getUserGroupsUser = (userId) => {
  return fetchCollection(
    model2,
    `/user/${userId}/usergroup`,
    {
      params: { userId }
    },
    { collectionKey: { path: `/user/${userId}/usergroup` } }
  )
}

export const createUserGroup = (userId, userGroupId) => {
  return fetchRecord(
    model2,
    `/user/${userId}/usergroup`,
    {
      data: { userId, userGroupId }
    },
    {
      collectionKey: { path: `/user/${userId}/usergroup`, params: { userId } }
    }
  )
}

export const removeUserGroup = (userId, id) => {
  return deleteRecord(
    model2,
    `/user/${userId}/usergroup/${id}`,
    {
      data: { userId, id }
    },
    {
      key: id,
      getRowKey: () => id,
      collectionKey: { path: `/user/${userId}/usergroup`, params: { userId } }
    }
  )
}

export const updateUserProfile = (userId, name, surname, email, cultureId) => {
  return updateOne(
    model,
    `/user/${userId}`,
    {
      data: {
        Name: name,
        Surname: surname,
        Email: email,
        UserCulture: cultureId
      }
    },
    {
      getRowKey: (row, index) => row.userId
    }
  )
}

export const getAllCultures = () => {
  return fetchCollection('culture', '/culture')
}
