Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.Panel.Region', {
  extend: 'MDM.src.ux.GridPanel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-geogrid-region',
  title: 'Sub Region',
  iconCls: 'fa fa-map',
  border: true,
  listeners: {
    select: 'onSelectRegion'
  },
  tools: [
    {
      type: 'plus',
      tooltip: 'Add new Region',
      bind: {
        hidden: '{readOnlyMode}'
      },
      handler: 'onAddRegion'
    }
  ],
  viewConfig: {
    stripeRows: false,
    plugins: [
      {
        ptype: 'uxgridviewdragdrop',
        id: 'dd',
        containerScroll: true,
        dragGroup: 'dd-grid-to-grid-group1',
        dropGroup: 'dd-grid-to-grid-group2'
      }
    ],
    listeners: {
      beforedrop: 'onbeforeDropToRegion'
    }
  },
  rowLines: true,
  columnLines: true,
  features: [
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      text: 'Subregion',
      dataIndex: 'Region',
      flex: 1,
      summaryType: 'count',
      menuDisabled: true,
      summaryRenderer: function (value) {
        return Ext.String.format('<b>{0} Subregion{1}</b>', value, value > 1 ? 's' : '')
      }
    },
    {
      text: 'Country Cnt',
      tooltip: 'Country Count',
      dataIndex: 'CountryCount',
      align: 'right',
      flex: 1,
      summaryType: 'sum',
      menuDisabled: true,
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      text: 'Supply Source Cnt',
      tooltip: 'Supply Source Count',
      dataIndex: 'SupplysourceCount',
      align: 'right',
      menuDisabled: true,
      flex: 1,
      summaryType: 'sum',
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      bind: {
        hidden: '{readOnlyMode}'
      },
      align: 'center',
      items: [
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete Record',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          handler: 'onRegionDelete'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      bind: {
        hidden: '{readOnlyMode}'
      },
      align: 'center',
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          getClass: function (value, metadata, record) {
            if (record.get('isEditable')) {
              return 'fa fa-pencil-square-o'
            }
            return 'fa fa-eye '
          },
          tooltip: 'View/Edit',
          handler: 'onEditRegion'
        }
      ]
    }
  ]
})
