import _ from 'lodash'
import { ICustomerEnvironment } from './Navigation.types'

export const getFirstEnvironmentId = (customerEnvironments: ICustomerEnvironment[]) => {
  const firstEnvironment = _.minBy(customerEnvironments, (env) => {
    const { environmentOrder = 0 } = env || {}
    return environmentOrder
  })

  const { id: firstEnvironmentId = 0 } = firstEnvironment || {}
  return firstEnvironmentId
}

export const getSavedConnectionEnvironmentId = (lsKey: string) => {
  const id: string | number = localStorage.getItem(lsKey) ?? '0'
  return Number(id)
}
