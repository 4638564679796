import React, { Component } from 'react'
import _ from 'lodash'

class RenderEditable extends Component {
  constructor(props) {
    super(props)
    this.state = this.getState(props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.progress !== true) {
      this.setState(this.getState(nextProps))
    }
  }

  getState({ value, cellInfo, isDisabled }) {
    return { value, cellInfo, isDisabled }
  }

  onChange(event) {
    this.setState({ value: event.target.value })
  }

  render() {
    const { cellInfo, value, isDisabled } = this.state
    return (
      <input
        className="-react-table-input"
        disabled={isDisabled}
        type="text"
        value={_.isNil(value) ? '' : value}
        onBlur={(event) => this.props.handleRevisedQTYBlur(event, cellInfo)}
        onChange={this.onChange.bind(this)}
        onKeyPress={(event) => this.props.handleRevisedQTYKeyPress(event, cellInfo)}
      />
    )
  }
}
export default RenderEditable
