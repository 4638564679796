import * as actionTypes from './actionTypes'
import { makeRequest } from '../../utils'

export const fetchMfpDetailDataForSingleColumn = (action) => {
  return (dispatch) => {
    dispatch(startGetMfpDetailDataForSingleColumnStart())

    return new Promise((resolve, reject) => {
      const errorCb = (error) => {
        dispatch(
          saveGetMfpDetailDataForSingleColumnFailed({
            payload: error.data
          })
        )
        reject(error)
      }

      const successCb = (response) => {
        if (response.data?.HasErrors) {
          errorCb(response)
          return
        }

        dispatch(
          saveGetMfpDetailDataForSingleColumn({
            payload: response.data
          })
        )
        resolve(response)
      }

      makeRequest(action).then(successCb).catch(errorCb)
    })
  }
}

export const startGetMfpDetailDataForSingleColumnStart = () => {
  return {
    type: actionTypes.FETCH_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_START
  }
}

export const saveGetMfpDetailDataForSingleColumn = (options) => {
  return {
    type: actionTypes.STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN,
    payload: options.payload
  }
}

export const saveGetMfpDetailDataForSingleColumnFailed = (options) => {
  return {
    type: actionTypes.STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_FAILED,
    payload: options.payload
  }
}
