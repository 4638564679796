import { ChangeEvent, useEffect, useMemo } from 'react'
import { uniq } from 'lodash'
import { Col, Form } from 'react-bootstrap'
import { SlvyTable } from '@/components'
import { useReduxState } from '../../hooks'
import { getDemandTree, setAutoServe, setServeFrom } from '../../store/api/endpoints'
import { demandTreeTableSlice, tuiSlice, viewContainerSlice } from '../../store/slices'
import {
  collectNodes,
  getEnabledColumns,
  getTreeSubRows,
  getTreeQueryParams,
  calculateTotals
} from '../../utils'
import FooterButtons from '../FooterButtons'
import { DataIndexes, DemandTreeTableProps } from './DemandTreeTable.types'
import PrimaryButton from '../PrimaryButton'
import BaseModal from '../BaseModal'
import useDemandRowSelected from '../../hooks/useRegisterEvents/useDemandRowSelected/useDemandRowSelected'
import styles from './DemandTreeTable.module.scss'

export default function DemandTreeTable({ tableRef }: DemandTreeTableProps) {
  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)
  const [{ currentView }] = useReduxState(viewContainerSlice.selectSlice)
  const [
    {
      demandIndexes,
      disabledFooterButtons,
      facilityNames,
      isAutoServeModalOpen,
      isOriginModalOpen,
      selectedFacilityIndex,
      selectedRows
    },
    demandTreeTableDispatch
  ] = useReduxState(demandTreeTableSlice.selectSlice, demandTreeTableSlice.actions)
  const [triggerGetDemandTree, demandTreeResponse] = getDemandTree.useMutation({
    fixedCacheKey: 'demand-tree'
  })
  const [triggerAutoServe] = setAutoServe.useLazyQuery()
  const [triggerServeFrom] = setServeFrom.useLazyQuery()
  const { demandRowRef } = useDemandRowSelected({ demandTreeTableRef: tableRef })

  const data = [{ Text: 'Demands', ID: 'root', ...demandTreeResponse.data }]
  const initialState = { expanded: { 0: true } }
  const facilityNameOptions = config.location.locations.map((item) => item.name || item.displayName)
  const isTreeTableView = currentView === 'Tree Table'
  const { totalFields } = config.demandTree

  const dataIndexes: DataIndexes = totalFields?.reduce((acc, { fieldName }, index) => {
    const column = config.demandTree.columns.find((col) => col.text === fieldName)
    if (column) {
      acc[index] = {
        dataIndex: column.dataIndex,
        formatter: column.formatter?.startsWith('number') ? column.formatter.slice(8, -2) : ''
      }
    }
    return acc
  }, {})

  const { firstFormatTotal, secondFormatTotal } = useMemo(() => {
    if (totalFields?.length > 0 && demandTreeResponse.data) {
      return calculateTotals(selectedRows, dataIndexes)
    }
    return { firstFormatTotal: 0, secondFormatTotal: 0 }
  }, [dataIndexes, demandTreeResponse.data, selectedRows, totalFields?.length])

  const getTotalFieldsText = () => {
    if (!totalFields.length) {
      return null
    }

    if (totalFields.length === 1) {
      return `${totalFields[0].fieldName} Sum: ${firstFormatTotal}`
    }

    return `${totalFields[0].fieldName} Sum: ${firstFormatTotal} ${totalFields[1].fieldName} Sum: ${secondFormatTotal}`
  }

  const totalFieldsText = getTotalFieldsText()

  const dynamicColumns = isTreeTableView
    ? []
    : [
        {
          id: 'cardControls',
          cell: () => {
            return null
          },
          maxSize: 40
        }
      ]
  const columns = useMemo(
    () => [
      ...dynamicColumns,
      {
        header: 'Name',
        accessorFn: ({ Text }) => Text ?? 'Demands',
        id: 'Name',
        minSize: 600
      },
      ...getEnabledColumns(config.demandTree.columns)
    ],
    []
  )

  useEffect(() => {
    if (config.settings.isServicePersistent) {
      triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })
    }
  }, [])

  function getStickyColumnStyles(columnId, columns) {
    const defaultStyle = {
      position: 'sticky',
      zIndex: 20
    }

    switch (columnId) {
      case 'cardControls': {
        return { ...defaultStyle, left: 0 }
      }
      case 'name': {
        const [cardControls] = columns
        return { ...defaultStyle, left: cardControls.column.getSize() }
      }
      default:
        return {}
    }
  }

  function handleSelectRow(row, event: ChangeEvent<HTMLInputElement>) {
    const { original } = row
    const isSelected = event.target.checked

    const selectedRowAndChildRows = collectNodes(original, (node) => node)

    const { currentSelectedNodes, currentDemandIndexes, currentFacilityNames } =
      selectedRowAndChildRows.reduce<{
        currentSelectedNodes: Record<string, unknown>[]
        currentDemandIndexes: number[]
        currentFacilityNames: string[]
      }>(
        (accumulator, node) => {
          if (isSelected) {
            return {
              currentSelectedNodes: accumulator.currentSelectedNodes.concat(node),
              currentDemandIndexes: accumulator.currentDemandIndexes.concat(node.Index),
              currentFacilityNames: accumulator.currentFacilityNames.concat(node.FacilityName)
            }
          }

          return {
            currentSelectedNodes: accumulator.currentSelectedNodes.filter(
              (currentSelectedNode) =>
                !selectedRowAndChildRows.some(
                  (selectedNode) =>
                    selectedNode.Text === currentSelectedNode.Text &&
                    selectedNode.ID === currentSelectedNode.ID
                )
            ),
            currentDemandIndexes: accumulator.currentDemandIndexes.filter(
              (currentDemandIndex) => currentDemandIndex !== node.Index
            ),
            currentFacilityNames: accumulator.currentFacilityNames.filter(
              (currentFacilityName) => currentFacilityName !== node.FacilityName
            )
          }
        },
        {
          currentSelectedNodes: selectedRows,
          currentDemandIndexes: demandIndexes,
          currentFacilityNames: facilityNames
        }
      )

    const uniqFacilityName = uniq(currentFacilityNames)

    demandTreeTableDispatch.setDemandIndexes(uniq(currentDemandIndexes))
    demandTreeTableDispatch.setFacilityNames(uniqFacilityName)
    demandTreeTableDispatch.setSelectedRows(currentSelectedNodes)

    setTimeout(() => {
      demandRowRef.current.handleDemandRowSelected(currentSelectedNodes)
    })
  }

  function handleServeButtonClick() {
    if (!demandIndexes.length) {
      demandTreeTableDispatch.setIsAutoServeModalOpen(true)
    }
    triggerAutoServe({ demandIndexes: demandIndexes })
  }

  function handleServeCloseButton() {
    demandTreeTableDispatch.setIsAutoServeModalOpen(false)
  }

  function handleOriginButtonClick() {
    demandTreeTableDispatch.setIsOriginModalOpen(true)
  }

  function handleOriginCloseButton() {
    demandTreeTableDispatch.setIsOriginModalOpen(false)
  }

  function handleOriginConfirmButton() {
    const data = config.location.locations
    const facilityIndex = data[0].index || data[0].facilityIndex
    triggerServeFrom({
      demandIndexes: demandIndexes,
      serveFacilityIndex: selectedFacilityIndex ?? facilityIndex
    })
    handleOriginCloseButton()
  }

  const handleSelectChange = (event) => {
    const selectedFacility = event.target.value
    const data = config.location.locations
    const selectedItem = data.find(
      (item) => item.name === selectedFacility || item.displayName === selectedFacility
    )

    if (selectedItem) {
      demandTreeTableDispatch.setSelectedFacilityIndex(
        selectedItem.index || selectedItem.facilityIndex
      )
    }
  }

  function getRowId({ ID, Text }: { ID: string; Text: string }) {
    return `${ID}/${Text}`
  }

  return (
    <>
      <SlvyTable
        checkboxSelection
        multiRowSelection
        className={styles.demandTreeTable}
        columns={columns}
        data={data}
        getBodyClassName={styles.demandTreeTableBody}
        getCellStyle={({ column, row }) => getStickyColumnStyles(column.id, row.getAllCells())}
        getExpanderColumn="Name"
        getHeaderStyle={({ column, headers }) => getStickyColumnStyles(column.id, headers)}
        getRowId={getRowId}
        getSubRows={getTreeSubRows}
        initialState={initialState}
        isLoading={!demandTreeResponse.data}
        rowSelection="multiple"
        tableRef={tableRef}
        theme="sencha-grid"
        onSelectRow={handleSelectRow}
      />
      <div className={styles.tableSummary}>
        <div className={styles.tableButton}>
          <PrimaryButton
            disabled={disabledFooterButtons.Serve}
            tooltipText="Serve"
            onClick={handleServeButtonClick}
          >
            <i className="fa fa-fw fa fa-gavel" />
          </PrimaryButton>
          <PrimaryButton
            disabled={disabledFooterButtons.Origin}
            tooltipText="Origin"
            onClick={handleOriginButtonClick}
          >
            <i className="fa fa-fw fa fa-arrows" />
          </PrimaryButton>
          <FooterButtons
            activeTree="Demand"
            buttons={config.demandTree.footerButtons}
            disabled={disabledFooterButtons}
          />
        </div>
        {isTreeTableView ? (
          <span>
            <strong className={styles.summaryText}>{totalFieldsText}</strong>
          </span>
        ) : null}
      </div>

      <BaseModal isOpen={isAutoServeModalOpen} onClose={handleServeCloseButton}>
        <BaseModal.Header>
          <BaseModal.Title>Information</BaseModal.Title>
        </BaseModal.Header>
        <BaseModal.Body className="fs-sm">Please select at least one demand.</BaseModal.Body>
        <BaseModal.Footer className="d-flex align-items-center justify-content-center">
          <BaseModal.ConfirmButton
            className="text-white"
            variant="success"
            onClick={handleServeCloseButton}
          />
        </BaseModal.Footer>
      </BaseModal>

      <BaseModal isOpen={isOriginModalOpen} onClose={handleOriginCloseButton}>
        <BaseModal.Header>
          <BaseModal.Title>Select Facility</BaseModal.Title>
        </BaseModal.Header>
        <BaseModal.Body className="fs-sm">
          <Form className="d-flex">
            <Form.Label column sm={2}>
              Facility:
            </Form.Label>
            <Col className="d-flex align-items-center justify-content-start fs-6" sm={10}>
              <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
                {facilityNameOptions.map((facility, index) => (
                  <option key={index} value={facility}>
                    {facility}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form>
        </BaseModal.Body>
        <BaseModal.Footer>
          <BaseModal.ConfirmButton
            className="text-white"
            variant="success"
            onClick={handleOriginConfirmButton}
          />
          <BaseModal.CancelButton className="text-white" onClick={handleOriginCloseButton} />
        </BaseModal.Footer>
      </BaseModal>
    </>
  )
}
