export default `({
    series: [{
        dataIndex: 'Qty',
        type: 'column',
        name: tt('Number of Store'),
        color: 'rgba(236,17,92,1)',
        listeners: {
            pointclick: function (serie, point, record, event) {
               panel = serie.chart;
                panel.ownerCt.fireEvent('clickChart', record);
            }
        }
    }],
    chartConfig: {
        chart: {
            type: 'column',
            alignTicks: true,
            marginRight: 20,
            marginBottom: 100,
            zoomType: 'xy',
            dataLabels: {
                enabled: true
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            text: 'SOLVOYO © 2021',
            href: 'http://www.solvoyo.com',
            position: {
                align: 'right',
                y: -5 // position of credits
            },
            style: {
                fontSize: '11pt' // you can style it!
            }
        },
        tooltip: {
            formatter: function () {
                var s = '<b>' + this.x + '</b>';
                this.points.forEach(function (p) {
                    s += '<br/>' + p.series.name + ': ' +
                        Ext.util.Format.number(p.y, '0.##');
                });
                return s;
            },
            shared: true
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            },
            series: {
                point: {
                    events: {
                        drop: function () {
                            a = 1;
                        }
                    }
                },
                stickyTracking: false
            }
        },
        navigation: {
            buttonOptions: {
                align: 'left'
            }
        },
        title: {
            text: tt('Sales per Inventory Ratio Distribition'),
            style: {
                fontSize: "11px",
                fontWeight: 'bold'
            }
        },
        xAxis: {
            title: {
                 text: tt('Ratio Disribition'),
            },
            labels: {
                // formatter: function () {
                //     monthValue = this.value
                //     monthValue = monthValue.slice(-2);
                //     monthValue = parseInt(monthValue) - 1
                //     return Ext.Date.shortMonthNames[monthValue];
                // },
                style: {
                    fontSize: '13px'
                },
                rotation: 315,
                y: 35,
            },
        },
        yAxis: {
            allowDecimals: false,
            //gridLineColor: '#ccc',
            title: {
                text: tt('Number of Store')
            },
            labels: {
                formatter: function () {
                    // Use thousands separator for four-digit numbers too
                    //	return Ext.util.Format.number(this.value * 100, '0.##%');
                }
            }
        },
    },
})
`
