Ext.define('MDM.Btl.dialog.EditForm', {
  extend: 'Ext.window.Window',
  requires: [
    'Ext.layout.container.Fit',
    'Ext.layout.container.VBox',
    'Ext.layout.container.HBox',
    'Ext.form.Panel',
    'Ext.form.field.Text',
    'Ext.form.field.Date',
    'Ext.form.field.Time',
    'Ext.form.field.Checkbox',
    'Ext.calendar.form.CalendarPicker'
  ],
  resize: false,
  viewModel: {
    formulas: {
      isEditableFrom: {
        bind: {
          record: '{record}',

          deep: true
        },
        get: function (data) {
          let record
          let Resource
          record = data.record
          Resource = record.get('Resource')

          return Resource != 'Calculated'
        }
      }
    },
    data: {
      record: null,
      readOnly: false
    }
  },
  ui: 'window-inverse',
  closable: false,
  iconCls: 'fa fa-pencil-square-o',
  layout: 'border',
  bodyStyle: {
    backgroud: '#fff'
  },
  modal: true,
  defaultListenerScope: true,
  height: 450,
  width: 800,
  record: null,
  bodyPadding: 1,
  config: {
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     *
     */
    formType: 'MegaRegion',
    megaRegionField: {
      xtype: 'textfield',
      fieldLabel: 'Region',
      flex: 1,
      name: 'MegaRegionSelection',
      itemId: 'megaregionselection',
      disabled: true,
      bind: {
        value: '{record.MegaRegion}'
      }
    },
    regionField: {
      xtype: 'textfield',
      fieldLabel: 'Subregion',
      name: 'Region',
      itemId: 'Region',
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.Region}'
      }
    },
    supplySourceField: {
      xtype: 'textfield',
      fieldLabel: 'Plant',
      name: 'SupplySource',
      readOnly: true,
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.SupplySource}'
      }
    },
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     */

    fromRegionField: {
      xtype: 'combo',
      fieldLabel: 'From Subregion',
      name: 'FromRegion',
      itemId: 'fromregion',
      forceSelection: true,
      displayField: 'name',
      typeAhead: true,
      width: '50%',
      flex: 2,
      valueField: 'id',
      listeners: {
        select: 'onChangeFromRegionID'
      },
      bind: {
        value: '{record.FromRegionID}',
        disabled: '{isEditableFrom}',
        hidden: '{record.ToRegion}'
      }
    },
    toRegionField: {
      xtype: 'textfield',
      fieldLabel: 'To Subregion',
      name: 'toregion',
      itemId: 'toregion',
      disabled: true,
      flex: 2,
      valueField: 'id',
      allowBlank: false,
      bind: {
        value: '{record.ToRegion}',
        readOnly: '{readOnly}',
        hidden: '{record.FromRegion}'
      }
    },
    dpiFamilyField: {
      xtype: 'textfield',
      fieldLabel: 'DPI Family',
      name: 'DPIFamily',
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.DPIFamily}',
        readOnly: '{readOnly}'
      }
    },
    dpiTypeDesc: {
      xtype: 'textfield',
      fieldLabel: 'BTL Type',
      flex: 1,
      name: 'BTLType',
      disabled: true,
      bind: {
        value: '{record.BTLType}',
        readOnly: '{readOnly}'
      }
    },
    materialType: {
      xtype: 'textfield',
      fieldLabel: 'Material Type',
      flex: 1,
      name: 'MaterialType',
      disabled: true,
      bind: {
        value: '{record.MaterialType}',
        readOnly: '{record.IsApplied}'
      }
    },
    createdByField: {
      xtype: 'textfield',
      fieldLabel: 'Created By',
      flex: 1,
      name: 'MaterialType',
      disabled: true,
      bind: {
        value: '{record.CreatedBy}',
        readOnly: '{readOnly}'
      }
    },
    creteatedAtField: {
      xtype: 'datefield',
      fieldLabel: 'Created At',
      name: 'endDate',
      disabled: true,
      flex: 1,
      bind: {
        value: '{record.CreatedAt}',
        readOnly: '{readOnlyMode}'
      }
    },
    appliedAtField: {
      xtype: 'datefield',
      fieldLabel: 'Applied At',
      name: 'appliedAt',
      disabled: true,
      flex: 1,
      bind: {
        value: '{record.AppliedAt}',
        readOnly: '{readOnly}'
      }
    },
    materialCode: {
      xtype: 'mygridpicker',
      fieldLabel: 'Material Code',
      name: 'Material',
      bind: {
        value: '{record.MaterialCode}',
        readOnly: '{readOnly}',
        disabled: '{!isEditableFrom}',
        emptyTextGrid:
          'No Material found in<br><div>DPIFamily : {record.DPIFamily} </div><div>MaterialType : {record.MaterialType}</div>'
      },
      allowBlank: true,
      flex: 1,
      multiSelect: false,
      listeners: {
        select: 'onChangeMaterialCode'
      },
      columns: [
        {
          text: 'Code',
          dataIndex: 'id',
          width: 100
        },
        {
          text: 'Name',
          dataIndex: 'name',
          flex: 1
        }
      ],
      displayField: 'name',
      valueField: 'MaterialCode'
    },
    materialDescription: {
      xtype: 'textfield',
      fieldLabel: 'Description',
      name: 'MaterialDescription',
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.MaterialDescription}'
      }
    },
    commentField: {
      xtype: 'textarea',
      fieldLabel: 'Comment',
      name: 'Comment',
      height: 100,
      bind: {
        value: '{record.Comment}',
        readOnly: '{readOnly}'
      }
    },
    latLongContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    dpiReasonField: {
      xtype: 'combo',
      fieldLabel: 'Reason',
      cls: 'menuform',
      name: 'Reason',
      forceSelection: true,
      editable: true,
      queryMode: 'remote',
      displayField: 'name',
      typeAhead: true,
      flex: 1,
      valueField: 'id',
      allowBlank: false,
      defaultListConfig: {
        loadingHeight: 70,
        minWidth: 240,
        shadow: 'sides'
      },
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      listeners: {},
      bind: {
        value: '{record.Reason}',
        userCls: '{DPIFamilyLabel}',
        readOnly: '{readOnly}'
      }
    },
    dpiContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    geoContainer: {
      xtype: 'fieldcontainer',
      // padding:10,
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    crossContainer: {
      xtype: 'fieldcontainer',
      // padding:10,
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    userContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    reasonContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    matContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    showHideButton: {
      text: 'Show/Hide Data',
      handler: 'onShowHide'
    },
    /**
     * @cfg {Object} saveButton
     * The config for the save button.
     */
    saveButton: {
      text: 'OK',
      handler: 'onSaveTap',
      iconCls: 'fa fa-floppy-o',
      bind: {
        hidden: '{readOnly}',
        disabled: '{!record.Reason}'
      }
    },
    /**
     * @cfg {Object} cancelButton
     * The config for the cancel button.
     */
    cancelButton: {
      text: 'Close',
      handler: 'onCancelTap'
    }
  },
  initForm: function () {
    this.createItems()
  },
  initComponent: function () {
    var me = this,
      BTLType = me.record.get('BTLType'),
      BTLTypeTitles = {
        P: 'Production',
        D: 'Demand',
        I: 'Inventory',
        X: 'Cross Supply',
        R: 'Razor Draw',
        N: 'Non-revenue'
      },
      BTLTypeDesc = me.record.get('BTLTypeDesc')
    this.getViewModel().set('readOnly', this.readOnly)
    if (
      BTLType == 'P' ||
      BTLType == 'D' ||
      BTLType == 'I' ||
      BTLType == 'X' ||
      BTLType == 'R' ||
      BTLType == 'N'
    ) {
      this.title = BTLTypeTitles[BTLType] + ' - ' + me.record.get('Title')
      this.getViewModel().set('record', this.record)
      me.initForm()
      me.fbar = me.generateButtons()
      me.callParent()
      me.form = me.items.first()
      me.checkFields()
      me.applyValues()
    } else {
      me.height = 150
      me.width = 300
      me.title = 'Error ! <span style="color:yellow">' + BTLTypeDesc + '</span>'
      me.msg = new Ext.Component({
        cls: me.baseCls + '-text',
        html: BTLTypeDesc,
        margin: '5 5 20,5'
      })
      ;(me.iconCls = 'fa fa-warning'), (me.layout = 'center')
      me.items = [me.msg]
      me.fbar = [me.getCancelButton()]
      me.callParent()
    }
  },
  onChangeMaterialCode: function (combo, selectedrecord) {
    if (selectedrecord) {
      var record = this.record

      record.set('MaterialID', selectedrecord[0].get('MaterialID'))
      record.set('MaterialCode', selectedrecord[0].get('MaterialCode'))
      record.set('MaterialDescription', selectedrecord[0].get('MaterialDescription'))
    }
  },

  onChangeFromRegionID: function (combo, selectedrecord) {
    if (selectedrecord) {
      var record = this.record

      record.set('FromRegion', selectedrecord.get('name'))
      record.set('Title', 'Shipments from ' + selectedrecord.get('name'))
    }
  },
  generateButtons: function () {
    var buttons = [],
      showhide = this.getShowHideButton()
    if (showhide) {
    }
    buttons.push(
      {
        xtype: 'component',
        flex: 1
      },
      this.getSaveButton(),
      this.getCancelButton()
    )
    return buttons
  },
  applyValues: function () {},
  createItems: function () {
    var me = this,
      geoContainer = me.getGeoContainer(),
      dpiContainer = me.getDpiContainer(),
      userContainer = me.getUserContainer(),
      crossContainer = me.getCrossContainer(),
      matContainer = me.getMatContainer(),
      reasonContainer = me.getReasonContainer()
    if (!dpiContainer.items) {
      dpiContainer.items = [me.getDpiFamilyField(), me.getDpiTypeDesc(), me.getMaterialType()]
    }
    if (!geoContainer.items) {
      geoContainer.items = [me.getMegaRegionField(), me.getRegionField(), me.getSupplySourceField()]
    }
    if (!userContainer.items) {
      userContainer.items = [
        me.getCreatedByField(),
        me.getCreteatedAtField(),
        me.getAppliedAtField()
      ]
    }
    if (!crossContainer.items) {
      crossContainer.items = [me.getFromRegionField(), me.getToRegionField()]
    }
    if (!matContainer.items) {
      matContainer.items = [me.getMaterialCode(), me.getMaterialDescription()]
    }
    if (!reasonContainer.items) {
      reasonContainer.items = [me.getDpiReasonField()]
    }
    var items = [],
      BTLType = me.record.get('BTLType')
    if (BTLType === 'P') {
      items.push(dpiContainer)
      items.push(geoContainer)
      items.push(userContainer)
      items.push(matContainer)
      items.push(reasonContainer)
      items.push(me.getCommentField())
    } else if (BTLType == 'I') {
      items.push(dpiContainer)
      items.push(geoContainer)
      items.push(userContainer)
      items.push(matContainer)
      items.push(reasonContainer)
      items.push(me.getCommentField())
    } else if (BTLType == 'X') {
      items.push(dpiContainer)
      items.push(geoContainer)
      items.push(userContainer)
      items.push(crossContainer)
      items.push(matContainer)
      items.push(reasonContainer)
      items.push(me.getCommentField())
    } else if (BTLType == 'D' || BTLType == 'R' || BTLType == 'N') {
      items.push(dpiContainer)
      items.push(geoContainer)
      items.push(userContainer)
      items.push(matContainer)
      items.push(reasonContainer)
      items.push(me.getCommentField())
    } else if (me.getFormType() == 'Country') {
      items.push(me.getMegaRegionSelectionField())
      items.push(me.getRegionSelectionField())
      items.push(me.getCountryField())
    } else if (me.getFormType() == 'SupplySource') {
      items.push(me.getMegaRegionSelectionField())
      items.push(me.getRegionSelectionField())
      items.push(me.getSupplySourceField())
      items.push(me.getCommentField())
    }
    me.grid = Ext.create({
      xtype: 'btl-dialog-grid',
      region: 'center',
      reference: 'map',
      border: true,
      bind: {
        record: '{record}'
      },
      listeners: {
        dragend: 'onDragend'
      }
    })
    this.items = [
      {
        xtype: 'form',
        border: false,
        trackResetOnLoad: true,
        region: 'center',
        height: '120',
        layout: {
          type: 'vbox',
          align: 'stretch'
        },
        bodyPadding: 2,
        fieldDefaults: {
          labelAlign: 'right',
          labelWidth: 85,
          msgTarget: Ext.supports.Touch ? 'side' : 'qtip'
        },
        items: items
      },
      {
        xtype: 'panel',
        region: 'south',
        layout: 'fit',
        hidden: true,
        itemId: 'dataPanel',
        bodyPadding: 5,
        border: true,
        margin: '5 0 0 0',
        items: [me.grid]
      }
    ]
  },
  privates: {
    checkFields: function () {},
    onAllDayChange: function () {
      this.checkFields()
    },
    onCancelTap: function () {
      this.getViewModel().get('record').reject()
      this.close()
    },
    onDropTap: function () {
      this.fireDrop()
    },
    onSaveTap: function () {
      this.fireEvent('save', this, this.grid)
      this.close()
    },
    applyMegaRegionField: function (c) {
      if (!c.store) return c
    },
    applyDpiReasonField: function (c) {
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    applyRegionField: function (c) {
      if (!c.store) return c
    },
    applyMegaRegionSelectionField: function (c) {
      if (!c.store) return c
    },
    getMaterialCode: function () {
      var c = this.materialCode
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getFromRegionField: function () {
      var c = this.fromRegionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getStore: function (c) {
      var DPIFamilyID = this.record.get('DPIFamilyID')
      return Ext.create('Ext.data.Store', {
        extraParams: {
          fieldType: c
        },
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: MDM.Global.getApiUrl() + 'gillettev3/Mass_Import_ComboData',
          extraParams: {
            fieldType: c,
            DPIFamilyID: DPIFamilyID
          },
          reader: {
            type: 'json'
          }
        },
        autoLoad: true,
        listeners: {
          beforeload: 'onStoreBeforeLoad',
          scope: this
        }
      })
    }
  },
  onStoreBeforeLoad: function (store) {
    var record = this.record,
      data = record.data,
      dataJson = Ext.encode(data)
    store.getProxy().setExtraParam('dataJson', dataJson)
  },
  onShowHide: function () {
    var dataPanel = this.down('#dataPanel'),
      height = dataPanel.isVisible() ? 400 : 650
    this.setHeight(height)
    dataPanel.setVisible(!dataPanel.isVisible())
  }
})
