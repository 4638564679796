Ext.define('MDM.Btl.cmp.DhCombo', {
  extend: 'MDM.Btl.cmp.UxTreePicker',
  xtype: 'dhcombo',
  reference: 'dhcombo',
  margin: '0 2 0 0',
  bind: {
    store: '{dhStore}',
    defaultValue: '{dpidefaulValue}'
  },
  fieldLabel: '<b>DPI Hierarcy</b>',
  queryMode: 'local',
  typeAhead: true,
  triggerAction: 'all',
  width: 350,
  labelWidth: 95,
  displayField: 'text',
  labelAlign: 'left',
  emptyText: 'DPI Hierarcy',
  valueField: 'value',
  listeners: {
    select: 'onDhComboSelect'
  },
  setDefaultValue: function (c) {
    var me = this
    if (!c) return c
    if (me.store.loading) {
      Ext.defer(function () {
        me.setDefaultValue(c)
      }, 100)
      return c
    }
    me.changeRecord(c.fieldName, c.value)
    return c
  }
})
