import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import _ from 'lodash'

class GroupBy extends React.Component {
  constructor() {
    super()
    this.handleSubmitHierarchy = this.handleSubmitHierarchy.bind(this)
    this.handleDragStart = this.handleDragStart.bind(this)
    this.handleDragOver = this.handleDragOver.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
  }

  handleSubmitHierarchy() {
    const {
      id = '',
      pluginStates: { data: pluginStates = [] } = {},
      periodDates: { StartDate: startDate = '', EndDate: endDate = '' } = {},
      modificationCount = 0,
      initialProductFilters = {},
      settings: {
        resourceAndProductPlanSettings: {
          grandTotal: { isEnabled: isGrandTotalEnabled = true } = {}
        } = {}
      } = {},
      connectApi = () => {},
      updatePluginState = () => {},
      viewHandler = () => {},
      onHide = () => {},
      runInfo = {},
      isProductView
    } = this.props

    const stateName = isProductView ? 'ProductGroupByState' : 'GroupByState'

    const hierarchyNodes = this.hierarchiesRef.querySelectorAll('p')
    const selectedHierarchyNodes = this.selectedHierarchiesRef.querySelectorAll('p')
    const hierarchies = _.map(
      Array.from(hierarchyNodes),
      ({ dataset: { name, overrideName } }) => ({ Name: name, OverrideName: overrideName })
    )
    const selectedHierarchies = _.map(
      Array.from(selectedHierarchyNodes),
      ({ dataset: { name, overrideName } }) => ({ Name: name, OverrideName: overrideName })
    )

    let groupByState = _.filter(
      pluginStates,
      (plugin) =>
        plugin.name === `${stateName}-${runInfo.scenarioId}-${runInfo.runInfoId}-${runInfo.runId}`
    )
    groupByState = _.last(groupByState)

    const newGroupByState = {
      ...groupByState,
      config: {
        ...groupByState.config,
        state: {
          ...groupByState.config.state,
          hierarchies,
          selectedHierarchies
        }
      }
    }

    updatePluginState(`update_group_by_${id}`, groupByState.id, newGroupByState)
    this.props.stateHandler(
      {
        [_.camelCase(stateName)]: {
          hierarchies,
          selectedHierarchies
        }
      },
      false,
      true
    )
    onHide()

    const requestSelectedHierarchies = _.map(selectedHierarchies, ({ Name }) => Name)

    connectApi(
      isProductView ? 'GetRccpProductPeriodResponse' : 'GetRccpResourcePeriodHierarchyResponseWPF',
      {
        productId: -1,
        periodId: 0,
        productFilter: initialProductFilters.productFilter,
        hierProductFilter: initialProductFilters.productFilter,
        rccpConfig: {
          isGrandTotalEnabled,
          startDate,
          endDate,
          ModificationCount: modificationCount,
          OverrideGroupByHierarchy: requestSelectedHierarchies
        }
      }
    ).then(() => {
      viewHandler(isProductView ? 'productView' : 'newResourcePlanView', 'pushPage')
    })
  }

  handleDragStart(event) {
    event.dataTransfer.setData('drop-item', event.target.dataset.overrideName)
  }

  handleDragOver(event) {
    event.preventDefault()
  }

  handleDrop(event) {
    event.preventDefault()
    const dropItem = event.dataTransfer.getData('drop-item')
    if (event.target.tagName === 'SECTION')
      event.target.appendChild(document.querySelector(`[data-override-name='${dropItem}']`))
  }

  render() {
    const {
      pluginStates: { data: pluginStates = [] },
      settings: {
        resourceAndProductPlanSettings: { groupBy: { popupTitle = '' } = {} } = {},
        appSettings: { productionView: { gridView } = {} }
      } = {},
      show = () => {},
      onHide = () => {},
      runInfo = {},
      isProductView
    } = this.props
    const stateName = isProductView ? 'ProductGroupByState' : 'GroupByState'
    const groupByState = _.last(
      _.filter(
        pluginStates,
        (plugin) =>
          plugin.name === `${stateName}-${runInfo.scenarioId}-${runInfo.runInfoId}-${runInfo.runId}`
      )
    )
    const { config: { state: { hierarchies = [], selectedHierarchies = [] } = {} } = {} } =
      groupByState ?? {}

    return (
      <Modal
        {...{ show, onHide }}
        aria-labelledby="contained-modal-title-lg"
        className="groupby-modal"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">{popupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dynamic-group-by">
            <div className="dynamic-group-by__left">
              <main ref={(main) => (this.selectedHierarchiesRef = main)}>
                {!isProductView && (
                  <section className="not-draggable">
                    <span>Resource</span>
                  </section>
                )}
                {_.map([...selectedHierarchies, ...hierarchies], (item, i) =>
                  _.includes(selectedHierarchies, item) ? (
                    <section
                      key={item.OverrideName}
                      onDragOver={this.handleDragOver}
                      onDrop={this.handleDrop}
                    >
                      <p
                        draggable
                        data-name={item.Name}
                        data-override-name={item.OverrideName}
                        onDragStart={this.handleDragStart}
                      >
                        {item.OverrideName}
                      </p>
                    </section>
                  ) : (
                    <section
                      key={item.OverrideName}
                      onDragOver={this.handleDragOver}
                      onDrop={this.handleDrop}
                    />
                  )
                )}
                <section className="not-draggable">
                  <span>Product</span>
                </section>
                {isProductView && gridView === 'Product, Aggregation and Resource' ? (
                  <section className="not-draggable">
                    <span>Resource</span>
                  </section>
                ) : null}
              </main>
              <Button variant="success" onClick={this.handleSubmitHierarchy}>
                Submit
              </Button>
            </div>
            <div ref={(div) => (this.hierarchiesRef = div)} className="dynamic-group-by__right">
              {_.map([...hierarchies, ...selectedHierarchies], (item) =>
                _.includes(hierarchies, item) ? (
                  <section
                    key={item.OverrideName}
                    onDragOver={this.handleDragOver}
                    onDrop={this.handleDrop}
                  >
                    <p
                      draggable
                      data-name={item.Name}
                      data-override-name={item.OverrideName}
                      onDragStart={this.handleDragStart}
                    >
                      {item.OverrideName}
                    </p>
                  </section>
                ) : (
                  <section
                    key={item.OverrideName}
                    onDragOver={this.handleDragOver}
                    onDrop={this.handleDrop}
                  />
                )
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="border" variant="btn" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  runInfo: state.resourcePlan.runInfo
})

export default connect(mapStateToProps)(GroupBy)
