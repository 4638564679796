import { DropdownButton } from 'react-bootstrap'
import { btnStyle, categorizedJobs } from '../../constants'
import Options from './Options'
import Jobs from '../../jobs'
import { WorkSelectProps } from './WorkSelect.types'
import { omitJobs, categorizedJobsWithIds, jobsWithIds } from './constants'

const WorkSelect = ({ path, node, onChangeWorkSelect }: WorkSelectProps) => {
  let newJobs = jobsWithIds
  const isRootNode = path.length === 1
  return (
    <DropdownButton
      as="span"
      className="select-work-dropdown-btn"
      renderMenuOnMount={false}
      size="xs"
      style={btnStyle}
      title={<i className="fa fa-plus" title="Add Work" />}
      variant="success"
    >
      {categorizedJobsWithIds.map((catJob) => {
        const { cat } = categorizedJobs[catJob.key]

        if (isRootNode) {
          newJobs = newJobs
            .filter((job) => job.key !== 'PageReady')
            .filter((job) => omitJobs.some((item) => item === job.key))
            .filter((job) => {
              const { config } = Jobs[job.key]
              return !node.children.filter((item) => item.title === config.key).length
            })
        } else {
          newJobs = newJobs.filter((job) => omitJobs.every((item) => item !== job.key))
        }

        return (
          <Options
            key={catJob.id}
            cat={cat}
            displayDivider={!isRootNode}
            newJobs={newJobs}
            path={path}
            onChange={onChangeWorkSelect}
          />
        )
      })}
    </DropdownButton>
  )
}

export default WorkSelect
