import React, { Component } from 'react'
import _ from 'lodash'
import Image from './image'
import ImageFields from './imageFields'

class ImageBox extends Component {
  render() {
    const { item, imageWidth, imageHeight, imageFit, imageBackground } = this.props

    return (
      <div
        className="image-box"
        style={{
          width: `${imageWidth}%`,
          height: _.isEqual(imageHeight, 'Keep Aspect Ratio') ? '100%' : ''
        }}
      >
        <Image
          handleImageClick={this.props.handleImageClick}
          imageBackground={imageBackground}
          imageBoxId={item.imageBoxId}
          imageFit={imageFit}
          imageHeight={imageHeight}
          src={item.imageURL}
        />
        <ImageFields fieldsHeader={item.fieldsSectionHeader} imageFields={item.imageFields} />
      </div>
    )
  }
}
export default ImageBox
