import React, { Component } from 'react'
import echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'

import createPlugin, { PluginTypes } from '@/BasePlugin'
import { SlvySelect } from '@/components'

import LeafletCoordSys from './LeafletCoordSys'
import { getConfig } from './components/configuration.js'
import { getCenterPos } from './components/data.js'
import worldMap from './maps/world.json'

import './style.scss'

class EChartsMap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: [],
      loaded: false,
      selectedTooltipIndex: 0,
      zoom: _.get(props, 'settings.config.settings.defaultZoomLevel', 6)
    }

    this.onEvents = {
      click: this.onChartClick.bind(this),
      legendselectchanged: (params) => this.filterDataByLegendSelection(params.selected)
    }

    this.logChange = this.logChange.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.size.width > 0
  }

  componentDidMount() {
    const {
      settings: {
        config: { settings: { leafletLayer = false } = {} } = {},
        query: { fields = [] } = {}
      } = {}
    } = this.props

    if (!leafletLayer) {
      echarts.registerMap('world', worldMap)
    }

    const returnTypes = _.transform(
      fields,
      (result, field) => {
        result[field.fieldName] = PluginTypes.fromString(field.dataType)
      },
      {}
    )

    this.handleLineSelected = this.props.registerEvent({
      key: 'LineSelected',
      fn: this.handleLineSelected.bind(this),
      returnTypes
    })

    this.handleLocationSelected = this.props.registerEvent({
      key: 'LocationSelected',
      fn: this.handleLocationSelected.bind(this),
      returnTypes
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      settings: {
        config: {
          settings: { leafletLayer = false, defaultLatitude = 0, defaultLongitude = 0 } = {}
        } = {}
      } = {},
      pluginData,
      size,
      size: { width }
    } = this.props
    const { loaded, zoom } = this.state

    if (width > 0 && !loaded) {
      if (leafletLayer) {
        require('./LeafletModel')
        require('./LeafletView')

        echarts.registerCoordinateSystem('leaflet', LeafletCoordSys)
        echarts.registerAction(
          {
            type: 'leafletRoam',
            event: 'leafletRoam',
            update: 'updateLayout'
          },
          function (payload, ecModel) {
            ecModel.eachComponent('leaflet', function (leafletModel) {
              const leaflet = leafletModel.getLeaflet()
              const center = leaflet.getCenter()

              leafletModel.setCenterAndZoom(
                [defaultLongitude || center.lng, defaultLatitude || center.lat],
                leaflet.getZoom()
              )
            })
          }
        )
      } else {
        echarts.registerMap('world', worldMap)
      }

      this.setState({ loaded: true })
    }

    if (leafletLayer && pluginData.length > 0 && !_.isEqual(size, nextProps.size)) {
      const { echarts_react: { getEchartsInstance = () => {} } = {} } = this

      const center = getCenterPos(this).reverse()
      const echartsInstance = getEchartsInstance()

      if (echartsInstance && echartsInstance.setOption) {
        setTimeout(() => {
          echartsInstance.setOption({
            leaflet: {
              center,
              zoom
            }
          })
        }, 500)
      }
    }
  }

  componentWillUnmount() {
    const { echarts_react: { getEchartsInstance = () => {} } = {} } = this
    const { id = 0 } = getEchartsInstance() || {}

    if (id) echarts.dispose(id)
  }

  handleLineSelected(rowData) {
    return rowData
  }

  handleLocationSelected(rowData) {
    return rowData
  }

  getFilterList(type) {
    return _.uniqBy(
      _.map(this.props.pluginData, (item) => ({
        label: item[type],
        value: item[type]
      })),
      (x) => x.value
    )
  }

  onChartClick(params) {
    const { data: { rowData = {} } = {} } = params

    if (params.seriesType === 'lines') {
      this.handleLineSelected(rowData)
    } else {
      this.handleLocationSelected(rowData)
    }
  }

  logChange(type) {
    const that = this
    return (val) => {
      if (_.size(val) === 0) {
        const value = { ...that.state.value }
        delete value[type]
        this.setState({ value })
      } else {
        this.setState({
          value: {
            ...that.state.value,
            [type]: this.toEchartsMultivalue(val)
          }
        })
      }
    }
  }

  handleSelectTooltip(selectedTooltipIndex) {
    return () => {
      this.setState({ selectedTooltipIndex })
    }
  }

  classSelector(theme) {
    switch (theme) {
      case 'Dark':
        return 'dark-theme'
      case 'Light':
        return 'light-theme'
      case 'Light gray':
        return 'lightgray-theme'
      // no default
    }
  }

  getLastFromArray(array, n) {
    if (array == null) return void 0
    if (n == null) return array[array.length - 1]
    return array.slice(Math.max(array.length - n, 0))
  }

  filterDataByLegendSelection(selectedLegends) {
    if (Object.keys(selectedLegends).length === 0) return

    const list = []
    for (let i = 0; Object.keys(selectedLegends).length > i; i++) {
      if (selectedLegends[Object.keys(selectedLegends)[i]]) {
        list.push(Object.keys(selectedLegends)[i])
      }
    }

    const option = getConfig(this)
    const othersInSeries = option.series.slice(
      0,
      option.series.length - Object.keys(selectedLegends).length
    )
    const legendsInSeries = this.getLastFromArray(
      option.series,
      Object.keys(selectedLegends).length
    )

    const scatters = _.filter(othersInSeries, (serie) => serie.type === 'scatter')
    const notScatters = _.filter(othersInSeries, (serie) => serie.type !== 'scatter')

    const filteredScatters = _.map(scatters, (serie) => {
      return {
        ...serie,
        data: _.filter(serie.data, (item) => {
          if (item.types instanceof Array) return item.types.some((_type) => list.includes(_type))
          return true
        })
      }
    })

    let result = filteredScatters.concat(notScatters)
    result = result.concat(legendsInSeries)

    const { echarts_react: { getEchartsInstance = () => {} } = {} } = this
    const { setOption = () => {} } = getEchartsInstance()
    setOption({ series: result })
  }

  toSelectMultiValue(values) {
    return _.map(values, (value) => ({ label: value, value }))
  }

  toEchartsMultivalue(values) {
    return _.map(values, ({ value }) => value)
  }

  render() {
    const {
      settings: {
        config: { settings: { theme } = {}, tooltips: { rows = [] } = {}, filters = [] } = {}
      } = {},
      size: { width }
    } = this.props
    const { value, selectedTooltipIndex } = this.state

    const themeClass = this.classSelector(theme)
    const option = getConfig(this)
    if (!option && width === 0) return null

    const fullSize = { width: '100%', height: '100%' }
    const pluginStyle = fullSize

    const filterOptions = _.map(filters, (filter) => ({
      key: filter.filterValue,
      options: this.getFilterList(filter.filterValue)
    }))

    return (
      width > 0 && (
        <div className={`echarts-plugin ${themeClass}`} style={pluginStyle}>
          <div className="echarts-wrp">
            {_.map(filterOptions, ({ options, key }) => {
              const { [key]: keyValues = [] } = value
              return (
                <div key={key} className="select-column">
                  <SlvySelect
                    isClearable
                    isMulti
                    className="react-select-container"
                    isSearchable={false}
                    options={options}
                    placeholder={key}
                    value={this.toSelectMultiValue(keyValues)}
                    onChange={this.logChange(key)}
                  />
                </div>
              )
            })}
          </div>
          {_.size(rows) > 0 && (
            <div className="ec-box">
              {_.map(rows, ({ display, color }, index) => (
                <span
                  key={index}
                  className={index === selectedTooltipIndex ? 'active' : ''}
                  id={`ec-box-${index}`}
                  style={{ color }}
                  onClick={this.handleSelectTooltip(index)}
                >
                  <b>{display}</b>
                </span>
              ))}
            </div>
          )}
          <ReactEcharts
            ref={(event) => {
              this.echarts_react = event
            }}
            lazyUpdate
            notMerge
            option={option}
            style={fullSize}
            onEvents={this.onEvents}
          />
        </div>
      )
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  settings: props.settings,
  size: props.size,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(EChartsMap, selectConnectorProps)
