export const CRUDV2_FETCH_ONE_REQUEST = '/CRUDV3/FETCH/ONE/REQUEST'
export const CRUDV2_FETCH_ONE_SUCCESS = '/CRUDV3/FETCH/ONE/SUCCESS'
export const CRUDV2_FETCH_ONE_FAILURE = '/CRUDV3/FETCH/ONE/FAILURE'

export const CRUDV2_FETCH_REQUEST = '/CRUDV3/FETCH/REQUEST'
export const CRUDV2_FETCH_SUCCESS = '/CRUDV3/FETCH/SUCCESS'
export const CRUDV2_FETCH_FAILURE = '/CRUDV3/FETCH/FAILURE'

export const CRUDV2_CREATE_COLLECTION_REQUEST = '/CRUDV3/CREATE/COLLECTION/REQUEST'
export const CRUDV2_CREATE_COLLECTION_SUCCESS = '/CRUDV3/CREATE/COLLECTION/SUCCESS'
export const CRUDV2_CREATE_COLLECTION_FAILURE = '/CRUDV3/CREATE/COLLECTION/FAILURE'

export const CRUDV2_CREATE_REQUEST = '/CRUDV3/CREATE/REQUEST'
export const CRUDV2_CREATE_SUCCESS = '/CRUDV3/CREATE/SUCCESS'
export const CRUDV2_CREATE_FAILURE = '/CRUDV3/CREATE/FAILURE'

export const CRUDV2_UPDATE_REQUEST = '/CRUDV3/UPDATE/REQUEST'
export const CRUDV2_UPDATE_SUCCESS = '/CRUDV3/UPDATE/SUCCESS'
export const CRUDV2_UPDATE_FAILURE = '/CRUDV3/UPDATE/FAILURE'

export const CRUDV2_DELETE_REQUEST = '/CRUDV3/DELETE/REQUEST'
export const CRUDV2_DELETE_SUCCESS = '/CRUDV3/DELETE/SUCCESS'
export const CRUDV2_DELETE_FAILURE = '/CRUDV3/DELETE/FAILURE'

export const CRUDV2_CLEAR_ACTION_STATUS = '/CRUDV3/CLEAR/ACTION/STATUS'
export const CRUDV2_CLEAR_FETCH_ONE = '/CRUDV3/CLEAR/FETCH/ONE'

export const CRUDV2_CLEAR_ALL = '/CRUDV3/CLEAR/ALL'

export const CRUDV2_CLEAR_MODEL = '/CRUDV3/CLEAR/MODEL'
