import { Component } from 'react'
import { SlvyIconSelector } from '../../../index'

export default class IconEditor extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <SlvyIconSelector
        inputGroupClass={this.props?.inputGroupClass ?? ''}
        value={this.props.value}
        onChange={this.handleChange}
      />
    )
  }
}
