export default {
  title: 'Job Status',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'JobSchedulerStatus',
  description: 'to check the status of a JobScheduler job',
  iconClass: 'fa-tachometer',
  color: 'navy-bg',
  group: 'Views'
}
