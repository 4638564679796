import React, { Component } from 'react'
import _ from 'lodash'
import { Map, TileLayer } from 'react-leaflet'
import { confirmAlert } from 'react-confirm-alert'
import L, { divIcon } from 'leaflet'
import HeatmapOverlay from 'leaflet-heatmap'
import { v4 as uuidv4 } from 'uuid'
import jq from 'jquery'
import MapElement from './src/MapElement'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './style.scss'

class LeafletMap extends Component {
  constructor(props) {
    super(props)
    this.defaultLatLong = [40.73061, -73.935242] // USA
    this.map = null
    this.opacity = 1
    this.tempVisibleAfterZoomLevel = 4
    this.defaultMinSize = 10
    this.defaultMaxSize = 50

    this.state = {
      tooltipOpen: true,
      center: {
        lat: 51.505,
        lng: -0.09
      },
      zoom: 4,
      bounds: L.latLngBounds([
        [0, 0],
        [0, 0]
      ]),
      modifiedLocations: []
    }

    this.setMapRef = (event) => {
      if (event) {
        this.map = event
      }
    }

    this.setMapRef = this.setMapRef.bind(this)
    this.runUpdateQuery = this.runUpdateQuery.bind(this)
    this.prepareUpdatedData = this.prepareUpdatedData.bind(this)
    this.deleteLocation = this.deleteLocation.bind(this)
    this.runDeleteQuery = this.runDeleteQuery.bind(this)
    this.centerMapToMarker = this.centerMapToMarker.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.updateLocation = this.updateLocation.bind(this)
    this.addHeatmap = this.addHeatmap.bind(this)
    this.assignZoomLevel = this.assignZoomLevel.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    const { pluginData = [] } = nextProps
    return !_.isEmpty(pluginData)
  }

  componentDidUpdate(prevProps) {
    const {
      settings: {
        config: {
          settings: { locationSettings: { defaultZoom = 4 } = {} } = {},
          data: { sourceLatField, sourceLonField, layers = [], zoomLevelField } = {}
        } = {}
      } = {},
      pluginData = []
    } = this.props

    const { prevPluginData } = prevProps

    const matchedHeatmapLayer = _.find(layers, { layerType: 'Heatmap' })
    const matchedOsmLayer = _.find(layers, { layerType: 'OpenStreet' })
    if (
      !_.isEmpty(pluginData) &&
      matchedHeatmapLayer &&
      !_.isEmpty(sourceLatField) &&
      !_.isEmpty(sourceLonField) &&
      !_.isEqual(prevPluginData, pluginData)
    ) {
      this.addHeatmap()
    }
    if (matchedOsmLayer) {
      this.opacity = parseFloat(matchedOsmLayer.opacity)
      jq('.leaflet-marker-icon, .leaflet-marker-shadow').css('opacity', this.opacity)
    }

    const mapZoom = this.map.leafletElement.getZoom()
    if (pluginData && !_.isNil(zoomLevelField) && !_.isEmpty(zoomLevelField)) {
      const fieldZoom = pluginData[0][zoomLevelField]
      if (pluginData.length == 1 && mapZoom != this.state.zoom && fieldZoom != mapZoom) {
        this.map.leafletElement.setZoom(fieldZoom)
      }
    } else if (!_.isNil(defaultZoom) && defaultZoom != 0) {
      if (mapZoom != this.state.zoom) {
        const mapCenter = this.map.leafletElement.getCenter()
        if (pluginData.length > 1 && mapCenter != this.state.center) {
          this.map.leafletElement.setView(this.state.center, defaultZoom)
        } else {
          this.map.leafletElement.setZoom(defaultZoom)
        }
      }
    }
  }

  UNSAFE_componentWillMount() {
    this.props.registerMethod({
      key: 'focus',
      fn: this.focusToMarker.bind(this),
      args: [
        { name: 'lat', type: PluginTypes.int },
        { name: 'lng', type: PluginTypes.int }
      ]
    })

    this.props.registerMethod({
      key: 'saveChanges',
      fn: this.handleSaveChanges.bind(this),
      args: [{ name: 'refreshKey', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'resetChanges',
      fn: this.handleResetChanges.bind(this),
      args: [{ name: 'refreshKey', type: PluginTypes.string }]
    })

    // Register location updated event
    this.handleDataUpdated = this.props.registerEvent({
      key: 'DataUpdated',
      fn: this.handleDataUpdated.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    const locationSelectedParams = _.transform(
      this.getFieldConfigs(),
      (result, field = {}) => {
        const { dataType = '' } = field
        result[field.fieldName] = PluginTypes.fromString(dataType)
      },
      {}
    )

    this.handleLocationSelected = this.props.registerEvent({
      key: 'LocationSelected',
      fn: this.handleLocationSelected.bind(this),
      returnTypes: locationSelectedParams
    })
  }

  handleClose() {
    this.setState(() => ({
      tooltipOpen: false
    }))
  }

  centerMapToMarker = (event, mar) => {
    this.setState({ center: null })
    const zoomLevel = this.map.leafletElement._zoom
    this.setState({ center: event.latlng, zoom: zoomLevel, tooltipOpen: true })

    this.handleLocationSelected(mar)
  }

  handleDataUpdated() {
    return { refreshKey: uuidv4() }
  }

  handleSaveChanges() {
    this.runUpdateQuery(this.state.modifiedLocations)
  }

  handleResetChanges() {
    this.setState({ modifiedLocations: [] })
  }

  focusToMarker(params) {
    const {
      settings: {
        config: {
          data: { sourceLatField, sourceLonField, zoomLevelField } = {},
          settings: { locationSettings: { defaultZoom: zoom = 4 } = {} }
        } = {}
      } = {},
      pluginData = []
    } = this.props
    if (!_.isEmpty(params)) {
      let zoomLevel = zoom == 0 ? 4 : zoom
      if (!_.isNil(zoomLevelField) && !_.isEmpty(zoomLevelField)) {
        const obj = _.find(
          pluginData,
          (data) => data[sourceLatField] == params.lat && data[sourceLonField] == params.lng
        )
        if (obj) {
          zoomLevel = obj[zoomLevelField]
        }
      }

      this.setState({ center: params, zoom: zoomLevel, tooltipOpen: true })
    }
  }

  handleLocationSelected(mar) {
    return mar
  }

  getFieldConfigs() {
    const {
      data: { schema, schema: fieldConfigsSchema = [] } = {},
      settings: { query: { fields: fieldConfigsQuery = [] } = {} } = {}
    } = this.props || {}

    if (schema) {
      return fieldConfigsSchema
    }

    return fieldConfigsQuery
  }

  prepareUpdatedData(markers) {
    const {
      settings: { config: { data: { idField, sourceLatField, sourceLonField } = {} } = {} },
      pluginData = []
    } = this.props

    if (pluginData && pluginData.length !== 0) {
      const result = []
      _.forEach(markers, (marker) => {
        const currentMarker = _.find(pluginData, {
          [idField]: marker[idField]
        })
        let newItem = { ...currentMarker } // Not deep clone because no children
        newItem = currentMarker
        newItem[sourceLatField] = marker[sourceLatField]
        newItem[sourceLonField] = marker[sourceLonField]
        result.push({
          columnName: sourceLatField,
          config: newItem,
          oldValue: ''
        })
        result.push({
          columnName: sourceLonField,
          config: newItem,
          oldValue: ''
        })
      })

      return result
    }
  }

  updateValue(data) {
    const { id, client } = this.props

    client.post(`/data/plugin/${id}/update/`, { data }).then(() => {
      this.setState({ modifiedLocation: [] })
      this.handleDataUpdated()
      this.props.clearCaches()
      this.props.setDataArguments(null, true)
    })
  }

  runUpdateQuery(markers) {
    const { actualFilters = {} } = this.props
    const updateItems = this.prepareUpdatedData(markers)

    if (!_.isEmpty(updateItems)) {
      this.updateValue({
        filters: actualFilters,
        updateItems
      })
    }
  }

  updateLocation = (mar, idField, latField, lngField, askReplace, event) => {
    const {
      settings: {
        config: { settings: { locationSettings: { editingType = 'Direct' } = {} } = {} } = {}
      }
    } = this.props

    const that = this

    const latlng = event.target._latlng
    mar[latField] = latlng.lat
    mar[lngField] = latlng.lng
    const matchedIndex = _.findIndex(
      that.state.modifiedLocations,
      (item) => item[idField] === mar[idField]
    )

    if (askReplace) {
      confirmAlert({
        title: 'Changing Coordinates',
        message:
          "Changing this location's coordinates to the selected point's coordinates in the map. Continue?",
        buttons: [
          {
            label: 'Cancel',
            onClick: () => that.forceUpdate()
          },
          {
            label: 'Continue',
            onClick: () => {
              if (editingType === 'Direct') {
                this.runUpdateQuery([mar])
              } else {
                const modLocations = [...that.state.modifiedLocations]
                if (matchedIndex > -1) {
                  modLocations.splice(matchedIndex, 1)
                }
                modLocations.push(mar)
                that.setState({ modifiedLocations: modLocations })
              }
            }
          }
        ]
      })
    } else if (editingType === 'Direct') {
      this.runUpdateQuery([mar])
    } else {
      const modLocations = [...this.state.modifiedLocations]
      if (matchedIndex > -1) {
        modLocations.splice(matchedIndex, 1)
      }
      modLocations.push(mar)
      this.setState({ modifiedLocations: modLocations })
    }
  }

  deleteValue(eData) {
    const { id, client } = this.props
    client
      .post(`/data/plugin/${id}/edit/`, {
        data: eData
      })
      .then(() => {
        this.props.clearCaches()
        this.props.setDataArguments(null, true)
        this.handleClose()
      })
  }

  runDeleteQuery(element) {
    const { pluginData } = this.props
    if (pluginData && pluginData.length !== 0) {
      const currentMarker = _.find(pluginData, {
        Id: element.markerId
      })

      const editData = {
        type: 2, // 2 for deleting
        records: [currentMarker]
      }

      this.deleteValue(editData)
    }
  }

  deleteLocation = (mar, idField) => {
    confirmAlert({
      title: 'Deleting Location',
      message: 'Are you sure you wish to delete this location?',
      buttons: [
        {
          label: 'Cancel'
        },
        {
          label: 'Continue',
          onClick: () => {
            this.runDeleteQuery({
              markerId: mar[idField]
            })
          }
        }
      ]
    })
  }

  createHeatmap() {
    const {
      settings: {
        config: {
          settings: {
            heatmapSettings: { radius = this.defaultMinSize, maxOpacity = 0.8 } = {}
          } = {},
          data: { layers } = {}
        } = {}
      } = {}
    } = this.props

    const matchedHeatmapLayer = _.find(layers, { layerType: 'Heatmap' })
    const heatMapRadius = matchedHeatmapLayer ? matchedHeatmapLayer.heatmapRadius : 10

    const cfg = {
      nick: 'HeatLayer',
      // radius should be small ONLY if scaleRadius is true (or small radius is intended)
      radius: heatMapRadius > radius ? heatMapRadius : radius,
      maxOpacity: matchedHeatmapLayer ? matchedHeatmapLayer.opacity : maxOpacity,
      // scales the radius based on map zoom
      scaleRadius: false,
      // if set to false the heatmap uses the global maximum for colorization
      // if activated: uses the data maximum within the current map boundaries
      //   (there will always be a red spot with useLocalExtremas true)
      useLocalExtrema: true,
      // which field name in your data represents the latitude - default "lat"
      latField: 'lat',
      // which field name in your data represents the longitude - default "lng"
      lngField: 'lng',
      // which field name in your data represents the data value - default "value"
      valueField: 'amount'
    }

    this.heatmapLayer = new HeatmapOverlay(cfg)
  }

  generateIcon(name, size, color) {
    return divIcon({
      html: `<i class='${name}' style="color:${color}; font-size:${size}px;"></i>`,
      iconAnchor: [size / 2, size]
    })
  }

  calculateMarkerSize(elemValue, minSize, maxSize, minVal, maxVal) {
    return maxVal !== minVal
      ? minSize + (elemValue - minVal) * ((maxSize - minSize) / (maxVal - minVal))
      : minSize
  }

  addHeatmap() {
    const {
      settings: {
        config: { data: { sourceLatField, sourceLonField, heatmapField, layers } = {} } = {}
      },
      pluginData = []
    } = this.props

    const { zoom: mapZoom } = this.state
    const matchedHeatmapLayer = _.find(layers, { layerType: 'Heatmap' })
    const emptyData = { data: [] }
    const heatmapData = {
      data: _.map(pluginData, (elm) => {
        return {
          lat: elm[sourceLatField],
          lng: elm[sourceLonField],
          amount: _.isNil(elm[heatmapField]) ? 0 : parseFloat(elm[heatmapField], 10)
        }
      })
    }
    this.createHeatmap()
    if (this.heatmapLayer && mapZoom >= matchedHeatmapLayer.zoomLevel) {
      this.heatmapLayer.setData(heatmapData)
    } else {
      this.heatmapLayer.setData(emptyData)
    }

    this.map.leafletElement.eachLayer((layer) => {
      if (layer.cfg) {
        // means it is the heatmap layer of the map
        this.map.leafletElement.removeLayer(layer)
      }
    })

    this.map.leafletElement.addLayer(this.heatmapLayer)
  }

  assignZoomLevel(event) {
    this.setState({ zoom: event.target._zoom })
  }

  isValidLocation(lat, lon) {
    const regex_lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
    const regex_lon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/
    return lat && lon && regex_lat.test(lat) && regex_lon.test(lon)
  }

  getLatLong(defaultLat, defaultLong) {
    const $defaultLat = Number(defaultLat)
    const $defaultLong = Number(defaultLong)
    return this.isValidLocation($defaultLat, $defaultLong)
      ? [$defaultLat, $defaultLong]
      : this.defaultLatLong
  }

  render() {
    const {
      settings: {
        config: {
          general: { theme = 'Light' } = {},
          settings: {
            locationSettings: {
              removability = true,
              draggable = true,
              askReplace = true,
              defaultLat = '',
              defaultLong = ''
            } = {}
          } = {},
          data: {
            idField,
            sourceField,
            sourceLatField,
            sourceLonField,
            colorField,
            isEditableField,
            locationTypeField,
            heatmapOpacity,
            sizePropField = '',
            tooltip: {
              htmlTooltip = '',
              cssTooltip = '',
              backgroundColorTooltip = '#fff',
              tooltipElems = []
            } = {},
            locationTypes = [],
            layers = []
          } = {}
        } = {}
      } = {},
      pluginData = []
    } = this.props

    const $defaultLatLong = this.getLatLong(defaultLat, defaultLong)

    const clonedPluginData = _.cloneDeep(pluginData)

    let maxVal
    let minVal

    if (clonedPluginData && !_.isNil(clonedPluginData)) {
      if (clonedPluginData.length > 0 && sizePropField !== '') {
        maxVal = _.maxBy(clonedPluginData, (o) => {
          return o[sizePropField]
        })[sizePropField]

        minVal = _.minBy(clonedPluginData, (o) => {
          return o[sizePropField]
        })[sizePropField]
      }
    }

    const isHtmlTooltip = htmlTooltip.trim() !== ''
    const styleTooltip = `<style>.leaflet-tooltip{background-color: ${backgroundColorTooltip}; border-color: ${backgroundColorTooltip}} ${cssTooltip}</style>`

    const transFormedMapItems = _.transform(
      clonedPluginData,
      (res, row, index) => {
        if (!_.isNil(row[sourceLatField]) && !_.isNil(row[sourceLonField])) {
          let lat = row[sourceLatField]
          let lng = row[sourceLonField]
          const modifiedLocation = _.find(
            this.state.modifiedLocations,
            (item) => item[idField] === row[idField]
          )

          if (modifiedLocation) {
            lat = modifiedLocation[sourceLatField]
            lng = modifiedLocation[sourceLonField]
          }
          const currentLocationType = locationTypes.find((el) => {
            return el.name === row[locationTypeField]
          })

          const {
            icon = 'fa fa-map-marker',
            shape = 'Marker',
            minSize = this.defaultMinSize,
            maxSize = this.defaultMaxSize,
            name: locationTypeName = 'Default'
          } = currentLocationType || {}
          let maxIconSize
          let minIconSize

          minIconSize = _.isEmpty(minSize) ? this.defaultMinSize : parseInt(minSize, 10)
          maxIconSize = _.isEmpty(maxSize) ? this.defaultMaxSize : parseInt(maxSize, 10)

          const iconColor = row[colorField] === undefined ? '#000000' : row[colorField]
          let iconSize = minIconSize

          if (!_.isEmpty(sizePropField)) {
            iconSize = this.calculateMarkerSize(
              row[sizePropField],
              minIconSize,
              maxIconSize,
              minVal,
              maxVal
            )
          }

          const customMarkerIcon = this.generateIcon(
            icon,
            iconSize,
            iconColor,
            !_.isNil(this.opacity) ? this.opacity : 1
          )

          const tooltip = isHtmlTooltip
            ? styleTooltip + htmlTooltip.replace(/\{([^}]+)\}/g, (key, val) => row[val] || '')
            : tooltipElems.map((telem) => {
                let modifiedValue
                if (modifiedLocation) {
                  modifiedValue = modifiedLocation[telem.value]
                  modifiedValue =
                    sourceLatField === telem.value || sourceLonField === telem.value
                      ? modifiedValue.toFixed(4)
                      : modifiedValue
                }

                return {
                  name: telem.name,
                  value: modifiedLocation ? modifiedValue : row[telem.value],
                  isVisible: telem.isVisible
                }
              })

          const _opacity = !_.isNil(row[heatmapOpacity]) ? row[heatmapOpacity] : this.opacity

          res.mapElements.push(
            <MapElement
              key={`marker-${index}`}
              color={row[colorField]}
              delete={() => this.deleteLocation(row, idField)}
              dragend={(event) =>
                this.updateLocation(row, idField, sourceLatField, sourceLonField, askReplace, event)
              }
              draggable={draggable && row[isEditableField] !== 0}
              icon={customMarkerIcon}
              iconSize={iconSize}
              isHtmlTooltip={isHtmlTooltip}
              locationType={locationTypeName}
              name={row[sourceField]}
              opacity={_opacity}
              position={this.getLatLong(lat, lng)}
              removable={removability && row[isEditableField] !== 0}
              shape={shape}
              tooltip={tooltip}
              tooltipOpen={this.state.tooltipOpen}
              visibility={false}
              weight={_opacity}
              onClick={(event) => this.centerMapToMarker(event, row)}
            />
          )

          res.bounds.extend({ lat, lng })
        }
      },
      { mapElements: [], bounds: new L.LatLngBounds() }
    )

    const matchedOSMLayer = _.find(layers, { layerType: 'OpenStreet' })

    const zoomLevelToCompare =
      matchedOSMLayer === undefined ? this.tempVisibleAfterZoomLevel : matchedOSMLayer.zoomLevel

    const mapProps = {
      ...(_.size(transFormedMapItems.mapElements) > 0 && { bounds: transFormedMapItems.bounds }),
      center: clonedPluginData && clonedPluginData.length > 0 ? this.state.center : $defaultLatLong,
      zoom: this.state.zoom,
      ref: this.setMapRef
    }

    return (
      <div className={`leaflet-map-plugin ${theme.toLowerCase()}`}>
        <div className="lf-map-wrp">
          <Map {...mapProps} onzoomend={(event) => this.assignZoomLevel(event)}>
            {this.state.zoom >= zoomLevelToCompare && transFormedMapItems.mapElements}
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </Map>
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  data: props.data,
  id: props.id,
  client: props.client,
  clearCaches: props.clearCaches,
  setDataArguments: props.setDataArguments,
  actualFilters: props.actualFilters
})

export default createPlugin(LeafletMap, selectConnectorProps)
