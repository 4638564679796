import { Component } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'

/* Crud */
import { createSelectorCreator, lruMemoize } from 'reselect'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { select, selectCollection, selecOperationStatus, isSuccess } from '../../../crudoptV3'

import { getTemplate, restoreTemplate, deleteTemplate } from '../../../actions/template'
import { getDataConnections } from '../../../actions/dataConnection'
import { getCatalog } from '../../../actions/catalog'

import { parseTwitterDate } from '../../../helpers/datetime'
import { SlvySelect } from '../../../components/index'

class PluginList extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this)
    this.handleAddTemplate = this.handleAddTemplate.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.state = { currentTemplateId: null }
  }

  handleAddTemplate() {
    return () => {
      const { request: { templateId = 0, connectionMap = {} } = {} } = this.state
      const { catalogId, menuId, pageId } = this.props
      this.props.restoreTemplate(catalogId, menuId, pageId, templateId, connectionMap)
    }
  }

  handleDeleteTemplate(id) {
    return () => {
      confirmAlert({
        title: 'Deleting Template?',
        message: 'Are you sure you want to delete this?',
        buttons: [
          {
            label: 'Cancel'
          },
          {
            label: 'Confirm Delete',
            onClick: () => {
              this.props.deleteTemplate(id)
            }
          }
        ]
      })
    }
  }

  componentDidMount() {
    if (this.props.templates.needFetch) {
      this.props.dispatch(this.props.templates.fetch)
    }
    if (this.props.dataConnections.needFetch) {
      this.props.dispatch(this.props.dataConnections.fetch)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isSuccess(this.props.templateStatus, nextProps.templateStatus)) {
      this.props.addElement(nextProps.templateStatus.data.id, 'container')
    }
    if (nextProps.dataConnections.needFetch) {
      this.props.dispatch(nextProps.dataConnections.fetch)
    }
  }

  handleSetTemplate(id) {
    return () => {
      this.setState({
        request: { templateId: id, connectionMap: {} }
      })
    }
  }

  handleDataConnectionChange(id) {
    return (selectedItem) => {
      if (selectedItem) {
        const { request, request: { connectionMap = {} } = {} } = this.state
        this.setState({
          request: {
            ...request,
            connectionMap: { ...connectionMap, [id]: selectedItem.value }
          }
        })
      }
    }
  }

  handleCancel() {
    const { request = {} } = this.state
    this.setState({
      request: {
        ...request,
        templateId: 0
      }
    })
  }

  render() {
    const {
      props: {
        templates: { data: templateData = [] } = {},
        dataConnections: { data: dataConnectionData = [] } = {}
      } = {},
      state: { request: { templateId: currentTemplateId = 0, connectionMap = {} } = {} } = {}
    } = this

    const template = _.find(templateData, ({ id }) => id === currentTemplateId)

    if (template) {
      return (
        <table className="table table-hover issue-tracker">
          <tbody>
            {_.map(template.plugins, (plugin) => {
              const {
                id,
                type,
                config: { general: { name: pluginName = '' } = {} } = {},
                query: { dataConnectionId = 0 }
              } = plugin

              const { name: dataConnectionName = 'No Data Connection' } =
                _.find(dataConnectionData, ({ id }) => id === dataConnectionId) || {}

              const { [id]: selectedValue } = connectionMap
              const options = _.map(dataConnectionData, ({ id: value, name: label }) => ({
                value,
                label
              }))
              const selectValue =
                _.find(options, (item) => item.value === (selectedValue || dataConnectionId)) || {}

              return (
                <tr key={`template_plugin_${id}`}>
                  <td className="--w-3">
                    <strong>
                      {pluginName} @{type}
                    </strong>
                  </td>
                  <td className="--w-3">{dataConnectionName}</td>
                  <td className="--w-3">
                    <SlvySelect
                      componentClass="select"
                      options={options}
                      placeholder="select"
                      value={selectValue}
                      onChange={this.handleDataConnectionChange(id)}
                    />
                  </td>
                </tr>
              )
            })}
            <tr>
              <td className="--w-3" />
              <td className="--w-3 text-center">
                <Button
                  size="sm"
                  variant="success"
                  onClick={this.handleAddTemplate(currentTemplateId)}
                >
                  Add
                </Button>
                &nbsp;
                <Button size="sm" variant="danger" onClick={this.handleCancel}>
                  Close
                </Button>
              </td>
              <td className="--w-3" />
            </tr>
          </tbody>
        </table>
      )
    }
    return (
      <table className="table table-hover issue-tracker">
        <tbody>
          {_.map(templateData, ({ id, name, createdBy, createdOn }) => (
            <tr key={`template${id}`}>
              <td className="issue-info">
                <strong>{name}</strong>
                <small>{name}</small>
              </td>
              <td>
                <span>{createdBy}</span>
              </td>
              <td>
                <span>{createdOn ? parseTwitterDate(createdOn) : createdOn}</span>
              </td>
              <td>
                <Button size="sm" variant="success" onClick={this.handleSetTemplate(id)}>
                  Add
                </Button>
              </td>
              <td>
                <Button size="sm" variant="danger" onClick={this.handleDeleteTemplate(id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}
const getTemplatesState = (state) => {
  return selectCollection(getTemplate(), state.model3)
}

const templateOperationStatusState = (state, ownProps) => {
  return selecOperationStatus('template', 'create', state.model3)
}

const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)

const getTemplatesStateSelector = () => {
  return createDeepEqualSelector([getTemplatesState], (x) => x)
}

const templateOperationStatusSelector = () => {
  return createDeepEqualSelector([templateOperationStatusState], (x) => x)
}

const makeMapStateToProps = () => {
  const templateStatus = templateOperationStatusSelector()
  const templates = getTemplatesStateSelector()
  const mapStateToProps = (state, ownProps) => {
    const catalog = select(getCatalog(ownProps.catalogId), state.model3)
    const { data: { customerId = 1 } = {} } = catalog
    return {
      dataConnections: selectCollection(getDataConnections(customerId), state.model3),
      templateStatus: templateStatus(state, ownProps),
      templates: templates(state, ownProps)
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, (dispatch) => ({
  dispatch,
  deleteTemplate: bindActionCreators(deleteTemplate, dispatch),
  restoreTemplate: bindActionCreators(restoreTemplate, dispatch)
}))(PluginList)
