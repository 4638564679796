Ext.define('MDM.src.view.regionapp.Cmp.SearchBoxGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'regionapp-searckbox-grid',
  columnLines: true,
  rowLines: true,
  border: true,
  stateful: true,
  stateId: 'SearchBoxGrid',
  modal: true,
  listeners: {
    // this event notifies us when the document was saved
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady'
  },
  emptyText: '<h3> No Record Found <h3>',

  plugins: ['gridfilters', 'gridexporter'],
  lockedGridConfig: {
    header: false,
    width: 550,
    split: true,
    forceFit: false,
    stateful: true,
    stateId: 'SearchBoxGridlocked'
  },
  lockedViewConfig: {
    scroll: 'horizontal'
  },
  viewConfig: {
    enableTextSelection: true
  },
  layout: 'border',
  features: [
    {
      ftype: 'grouping',
      startCollapsed: true,
      groupHeaderTpl: '{name} ({rows.length} Material{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  columns: [
    {
      text: 'Material',
      locked: true,
      flex: 1,
      sortable: true,
      minWidth: 90,
      dataIndex: 'MaterialCode',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Description',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 200,
      locked: true,
      editor: {
        // defaults to textfield if no xtype is supplied
      },
      dataIndex: 'MaterialDescription',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Status',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 100,
      locked: true,
      editor: {
        // defaults to textfield if no xtype is supplied
      },
      dataIndex: 'statu',
      filter: 'list'
    },
    {
      text: 'Appears on extract',
      flex: 1,
      sortable: true,
      minWidth: 70,
      dataIndex: 'AppearOnExtractYesOrNo',
      filter: 'list',
      locked: true
    },
    {
      text: 'Appears on Subregion',
      flex: 1,
      sortable: true,
      minWidth: 70,
      dataIndex: 'AppearsOnSubregion',
      filter: 'list',
      locked: true
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'AppearOnExtractYesOrNo',
      menuDisabled: true,
      sortable: false,
      menuText: 'Extract Details',
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-external-link-square',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('AppearsOnExtract')
          },
          tooltip: 'Extraction details',
          handler: 'onExtractDetails'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'MaterialDescription',
      menuDisabled: true,
      sortable: false,
      enableCellDrag: true,
      width: 50,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          enableCellDrag: true,
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            if (record.get('IsDropped') == '1') return true
            return !record.get('isEditable')
          },
          getClass: function (value, metadata, record) {
            if (record.get('IsDropped') == '1') return ''
            if (record.get('isEditable')) {
              return 'fa fa-pencil-square-o red'
            }
          },
          tooltip: 'Edit',
          handler: 'onEditMaterial'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'MaterialDescription',
      menuDisabled: true,
      sortable: false,
      enableCellDrag: true,
      width: 50,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-trash-o',
          enableCellDrag: true,
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            if (record.get('IsDropped') == '1') return true
            return !record.get('isEditable')
          },
          getClass: function (value, metadata, record) {
            if (record.get('IsDropped') == '1') return ''
            if (record.get('isEditable')) {
              return 'fa fa-trash-o'
            }
          },
          tooltip: 'Suppress from all lists and make this material inactive ',
          handler: 'onDrop'
        }
      ]
    },
    {
      text: 'DPIFamily',
      minWidth: 100,
      sortable: true,
      dataIndex: 'DPIFamily',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      editor: {}
    },
    {
      text: 'BUOM',
      minWidth: 100,
      sortable: true,
      dataIndex: 'MaterialBUOM',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      editor: {}
    },

    {
      text: 'SU',
      width: 100,
      sortable: true,
      xtype: 'numbercolumn',
      format: '0.00000000',
      align: 'right',
      dataIndex: 'SU',
      filter: 'number'
    },

    {
      text: 'GU Assigned',
      width: 120,
      tdCls: 'red',
      align: 'right',
      sortable: true,
      format: '0',
      xtype: 'numbercolumn',
      dataIndex: 'GU'
    },
    {
      text: 'Material Type',
      width: 120,
      sortable: true,
      dataIndex: 'MaterialType',
      filter: 'list'
    },
    {
      text: 'Region',
      width: 120,
      sortable: true,

      dataIndex: 'MegaRegion',
      filter: 'list'
    },

    {
      text: 'Category',
      width: 120,
      sortable: true,

      dataIndex: 'Category',
      filter: 'list'
    },
    {
      text: 'Brand',
      width: 120,
      sortable: true,

      dataIndex: 'Brand',
      filter: 'list'
    },
    {
      text: 'Product Form Detail',
      width: 120,
      sortable: true,

      dataIndex: 'ProductFormDetail',
      filter: 'list'
    },
    {
      text: 'CSU Indicator',
      width: 120,
      sortable: true,

      dataIndex: 'CSUIndicator',
      filter: 'list'
    },
    {
      text: 'Suppressed By',
      width: 120,
      sortable: true,

      dataIndex: 'DroppedBy',
      filter: 'list'
    },
    {
      xtype: 'datecolumn',
      text: 'Suppressed At',
      dataIndex: 'DroppedAt',
      format: 'Y-m-d\\TH:i:s',
      filter: 'date',

      width: 200
    },
    {
      text: 'Modified At',
      width: 120,
      sortable: true,
      xtype: 'datecolumn',
      format: 'Y-m-d\\TH:i:s',
      dataIndex: 'ModifiedDate',
      filter: 'date'
    },
    {
      text: 'Supply Source',
      width: 120,
      sortable: true,

      dataIndex: 'SupplySourceDescription',
      filter: 'list'
    },

    {
      text: 'Data Source',
      width: 120,
      sortable: true,
      align: 'right',

      dataIndex: 'DataSource',
      filter: 'list'
    }
  ]
})
