import { useCallback } from 'react'
import { ApplyColumnStateParams } from 'ag-grid-community'
import { getColumnWidth, getUserColumnDefsAsFlat } from '../../helpers'
import { AG_GRID_ENUMS } from '../../enums'
import { ColumnRowGroupChangedProps } from './useColumnRowGroupChanged.types'
import { SlvyColDef, SlvyMasterColDef, Theme } from '../../types'
import { columnHeaderFontDifferences } from '../../constants'

const { AUTO_GROUP_COLUMN } = AG_GRID_ENUMS

const expandButtonWidthDifferences: Record<Theme, number> = {
  balham: 16,
  alpine: 16,
  quartz: 16
}

const expandButtonMarginRightDifferences: Record<Theme, number> = {
  balham: 10,
  alpine: 12,
  quartz: 12
}

// for two digit like (10) and it's better to make this as dynamic
const childRowCountWidthDifferences: Record<Theme, number> = {
  balham: 20,
  alpine: 23,
  quartz: 23
}

const useColumnRowGroupChanged = ({
  gridRef,
  grouping,
  groupDisplayType,
  theme,
  suppressCount
}: ColumnRowGroupChangedProps) => {
  const isGroupRows = groupDisplayType === 'groupRows'

  const onColumnRowGroupChanged = useCallback(() => {
    if (!grouping || isGroupRows) {
      return
    }

    // Note: %99 of the plugins use groupRows display type so checking columns defs
    // here is better for perf
    let columnDefs = (gridRef?.current?.api.getColumnDefs?.() ?? []) as SlvyMasterColDef[]
    columnDefs = getUserColumnDefsAsFlat(columnDefs)

    const hasColumnDefs = columnDefs?.length > 0
    if (!hasColumnDefs) {
      return
    }

    const state: ApplyColumnStateParams['state'] = gridRef?.current?.api?.getColumnState?.() ?? []
    const groupedColState = state.filter(({ rowGroup }) => rowGroup)

    let autoColumnWidth = 0

    groupedColState.forEach((col, colIndex) => {
      const { field } = (gridRef?.current?.api.getColumnDef?.(col?.colId) as SlvyColDef) ?? {}

      // Note: get width from the flat column defs to deal with groupHeaders (e.g., columnGroups)
      const { minWidth, customInfo } = (columnDefs.find((colDef) => colDef.field === field) ??
        {}) as SlvyColDef

      const withVar = customInfo?.width ?? minWidth ?? 90
      let newWidth = getColumnWidth({ width: withVar, flex: 0, theme }).width as number

      // add column padding for alpine and quartz theme for once
      if (colIndex < 0) {
        newWidth -= columnHeaderFontDifferences[theme]
      }

      autoColumnWidth += newWidth

      if (autoColumnWidth) {
        // extraWidth = expand button width + expand button margin-right + row count + row count margin-left
        // 0 for alpine and quartz because in those themes,
        // columns already have more padding and font differences
        let extraWidth = expandButtonWidthDifferences[theme] // expand button width
        extraWidth += expandButtonMarginRightDifferences[theme] // expand button margin-right
        extraWidth += suppressCount ? 0 : childRowCountWidthDifferences[theme] // child row count
        extraWidth += suppressCount ? 0 : 2 // child row count margin-left
        autoColumnWidth += extraWidth
      }
    })

    if (!autoColumnWidth) {
      return
    }

    gridRef?.current?.api?.applyColumnState?.({
      state: [{ colId: AUTO_GROUP_COLUMN, width: autoColumnWidth }]
    })
  }, [gridRef, isGroupRows, grouping, theme, suppressCount])

  return { onColumnRowGroupChanged }
}

export default useColumnRowGroupChanged
