import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import {
  CRUDV2_FETCH_REQUEST,
  CRUDV2_FETCH_SUCCESS,
  CRUDV2_FETCH_FAILURE,
  CRUDV2_FETCH_ONE_REQUEST,
  CRUDV2_FETCH_ONE_SUCCESS,
  CRUDV2_FETCH_ONE_FAILURE,
  CRUDV2_UPDATE_REQUEST,
  CRUDV2_UPDATE_SUCCESS,
  CRUDV2_UPDATE_FAILURE,
  CRUDV2_DELETE_REQUEST,
  CRUDV2_DELETE_SUCCESS,
  CRUDV2_DELETE_FAILURE,
  CRUDV2_CREATE_REQUEST,
  CRUDV2_CREATE_SUCCESS,
  CRUDV2_CREATE_FAILURE,
  CRUDV2_CLEAR_ALL,
  CRUDV2_CLEAR_MODEL
} from './actionTypes'

function generateKey(data) {
  return uuidv4()
}

const entityCollectionInitialState = {
  ids: [],
  needFetch: false,
  pending: false,
  isSuccess: false,
  startFetch: 0,
  fetchTime: 0
}

const collectionsInitialState = []

const initialState = {}

/**
 *
 * @param {*} state
 * @param {*} action
 */
export const CRUDOptV3 = (state = initialState, action) => {
  const { meta: { model = '', version = 1 } = {} } = action

  // Check model in state
  if (_.isEmpty(model) || version !== 3) return state

  const { [model]: { collections, byId, operations } = {} } = state

  switch (action.type) {
    case CRUDV2_CREATE_REQUEST:
    case CRUDV2_CREATE_SUCCESS:
    case CRUDV2_CREATE_FAILURE:
      return {
        ...state,
        [model]: {
          ...state[model],
          collections: collectionsReducer(collections, action),
          byId: byIdReducer(byId, action),
          operations: operationReducer(operations, action)
        }
      }
    case CRUDV2_FETCH_REQUEST:
    case CRUDV2_FETCH_SUCCESS:
    case CRUDV2_FETCH_FAILURE: {
      // Crud Operation for fetch queries
      return {
        ...state,
        [model]: {
          ...state[model],
          collections: collectionsReducer(collections, action),
          byId: byIdReducer(byId, action)
        }
      }
    }
    case CRUDV2_FETCH_ONE_REQUEST:
    case CRUDV2_FETCH_ONE_SUCCESS:
    case CRUDV2_FETCH_ONE_FAILURE: {
      // Crud Operation for fetch queries
      return {
        ...state,
        [model]: {
          ...state[model],
          byId: byIdReducer(byId, action)
        }
      }
    }
    case CRUDV2_UPDATE_REQUEST:
      // Crud Operation for fetch queries
      return {
        ...state,
        [model]: {
          ...state[model],
          operations: operationReducer(operations, action)
        }
      }

    case CRUDV2_UPDATE_SUCCESS:
    case CRUDV2_UPDATE_FAILURE:
      // Crud Operation for fetch queries
      return {
        ...state,
        [model]: {
          ...state[model],
          collections: collectionsReducer(collections, action),
          byId: byIdReducer(byId, action),
          operations: operationReducer(operations, action)
        }
      }
    case CRUDV2_DELETE_REQUEST:
    case CRUDV2_DELETE_SUCCESS:
    case CRUDV2_DELETE_FAILURE:
      // Crud Operation for fetch queries
      return {
        ...state,
        [model]: {
          ...state[model],
          collections: collectionsReducer(collections, action),
          byId: byIdReducer(byId, action),
          operations: operationReducer(operations, action)
        }
      }
    case CRUDV2_CLEAR_ALL:
      return {}
    case CRUDV2_CLEAR_MODEL:
      return {
        ...state,
        [model]: {}
      }
    default:
      return state
  }
}

/**
 *
 * @param {*} stateEntity
 * @param {*} action
 */
const collectionsReducer = (state = collectionsInitialState, action) => {
  switch (action.type) {
    case CRUDV2_FETCH_REQUEST:
    case CRUDV2_FETCH_SUCCESS:
    case CRUDV2_FETCH_FAILURE:
      if (_.isArray(action.meta.collectionKey)) {
        const newState = _.transform(
          action.meta.collectionKey,
          (result, key) => {
            const { [key]: collection } = state
            result[key] = collectionReducer(collection, action)
          },
          {}
        )
        return { ...state, ...newState }
      }
      const { [action.meta.collectionKey]: collection } = state
      return {
        ...state,
        [action.meta.collectionKey]: collectionReducer(collection, action)
      }

    case CRUDV2_CREATE_SUCCESS:
    case CRUDV2_DELETE_SUCCESS:
    case CRUDV2_UPDATE_SUCCESS:
      if (_.isArray(action.meta.collectionKey)) {
        const newState = _.transform(
          action.meta.collectionKey,
          (result, key) => {
            const { [key]: collection } = state
            if (collection) result[key] = collectionReducer(collection, action)
          },
          {}
        )
        return { ...state, ...newState }
      } else {
        const { [action.meta.collectionKey]: collection } = state
        return collection
          ? {
              ...state,
              [action.meta.collectionKey]: collectionReducer(collection, action)
            }
          : state
      }
    default:
      return state
  }
}

/**
 *
 * @param {*} stateEntity
 * @param {*} action
 */
const collectionReducer = (state = entityCollectionInitialState, action) => {
  const { meta: { startFetch, endFetch, fetchTime } = {} } = action
  switch (action.type) {
    case CRUDV2_CREATE_SUCCESS:
      return {
        ...state,
        ids: [action.meta.getRowKey(action.result, null), ...state.ids],
        fetchKey: generateKey()
      }
    case CRUDV2_UPDATE_SUCCESS:
      return {
        ...state,
        fetchKey: generateKey()
      }
    case CRUDV2_DELETE_SUCCESS:
      return {
        ...state,
        ids: _.filter(
          state.ids,
          (id) => !_.isEqual(id, action.meta.getRowKey(action.result, null))
        ),
        fetchKey: generateKey()
      }
    case CRUDV2_FETCH_REQUEST:
      return {
        ...state,
        ids: [],
        startFetch,
        endFetch,
        fetchTime,
        error: null,
        needFetch: false,
        isSuccess: false,
        isFailed: false,
        pending: true,
        fetchKey: generateKey()
      }
    case CRUDV2_FETCH_SUCCESS:
      const ids = _.map(action.meta.mapResult(action.result), (record, index) => {
        const id = action.meta.getRowKey(record, index)
        return id
      })
      return {
        ...state,
        ids,
        endFetch,
        fetchTime,
        isSuccess: true,
        pending: false,
        fetchKey: generateKey()
      }
    case CRUDV2_FETCH_FAILURE:
      return {
        ...state,
        data: action.error,
        endFetch,
        fetchTime,
        isFailed: true,
        pending: false,
        fetchKey: generateKey()
      }
    default:
      return state
  }
}

/**
 *
 * @param {*} stateEntity
 * @param {*} action
 */
const byIdReducer = (state = {}, action) => {
  const { meta: { key, startFetch, endFetch, fetchTime } = {} } = action

  switch (action.type) {
    case CRUDV2_CREATE_SUCCESS: {
      const id = action.meta.getRowKey(action.result, null)
      const data = action.meta.mapResult(action.result)
      return {
        ...state,
        [id]: {
          ...state[id],
          startFetch,
          endFetch,
          fetchTime,
          error: null,
          needFetch: false,
          data,
          isSuccess: true,
          isFailed: false,
          pending: false,
          fetchKey: generateKey(data)
        }
      }
    }
    case CRUDV2_FETCH_ONE_REQUEST:
      return {
        ...state,
        [key]: {
          ...state[key],
          startFetch,
          endFetch,
          fetchTime,
          error: null,
          needFetch: false,
          isSuccess: false,
          isFailed: false,
          pending: true,
          fetchKey: generateKey()
        }
      }
    case CRUDV2_FETCH_ONE_SUCCESS: {
      const data = action.meta.mapResult(action.result)
      return {
        ...state,
        [key]: {
          ...state[key],
          endFetch,
          fetchTime,
          data,
          isSuccess: true,
          pending: false,
          fetchKey: generateKey()
        }
      }
    }
    case CRUDV2_FETCH_ONE_FAILURE:
      return {
        ...state,
        [key]: {
          ...state[key],
          endFetch,
          fetchTime,
          error: action.error,
          data: action.result,
          isFailed: true,
          pending: false,
          fetchKey: generateKey()
        }
      }
    case CRUDV2_UPDATE_SUCCESS: {
      const { [key]: { data: data2 = {} } = {} } = state || {}
      const data = action.meta.mapResult(action.result, data2)
      return {
        ...state,
        [key]: {
          ...state[key],
          endFetch,
          fetchTime,
          data,
          isSuccess: true,
          pending: false,
          fetchKey: generateKey()
        }
      }
    }
    case CRUDV2_FETCH_SUCCESS:
      const result = _.transform(
        action.meta.mapResult(action.result),
        (result, record, index) => {
          const id = action.meta.getRowKey(record, index)
          result[id] = {
            ...result[id],
            startFetch,
            endFetch,
            fetchTime,
            error: null,
            data: record,
            isSuccess: true,
            pending: false,
            fetchKey: generateKey()
          }
        },
        {}
      )
      return {
        ...state,
        ...result
      }
    case CRUDV2_DELETE_SUCCESS:
      const stateClone = _.cloneDeep(state)
      const rowKey = action.meta.getRowKey(action.result)
      delete stateClone[rowKey]
      return { ...stateClone }
    default:
      return state
  }
}

const operationReducer = (state = {}, action) => {
  const {
    meta: { operationKey, startFetch, endFetch, fetchTime }
  } = action
  switch (action.type) {
    case CRUDV2_CREATE_REQUEST:
    case CRUDV2_DELETE_REQUEST:
    case CRUDV2_UPDATE_REQUEST:
      return {
        ...state,
        [operationKey]: {
          startFetch,
          endFetch,
          fetchTime,
          isSuccess: false,
          isFailed: false,
          pending: true,
          fetchKey: generateKey()
        }
      }
    case CRUDV2_CREATE_SUCCESS:
    case CRUDV2_CREATE_FAILURE:
    case CRUDV2_DELETE_SUCCESS:
    case CRUDV2_DELETE_FAILURE:
    case CRUDV2_UPDATE_SUCCESS:
    case CRUDV2_UPDATE_FAILURE:
      return {
        ...state,
        [operationKey]: {
          error: action.error,
          data: action.result,
          startFetch,
          endFetch,
          fetchTime,
          isSuccess: _.isNil(action.error),
          isFailed: !_.isNil(action.error),
          pending: false,
          fetchKey: generateKey()
        }
      }
    default:
      return state
  }
}
