export default `({
    viewConfig: {
        ui: 'spui-white-grid',
    },
    header: true,
    border: true,
    columnLines: true,
    syncRowHeight: true,
    multiColumnSort: true,
    onClickDeleteSpot: function (grid, rowIndex, colIndex, item, e, record) {
        var me = this;
        Ext.Msg.show({
            title: tt('Onay'),
            width: 500,
            msg: tt('Are you sure you want to cancel this record?'),
            icon: Ext.MessageBox.QUESTION,
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            buttons: Ext.Msg.YESNO,
            fn: function (response) {
                if (response === 'yes') {
                    me.doDeleteNode(record);
                }
            }
        });
    },
    doDeleteNode: function (record) {
        record.drop();
    },
    plugins: [
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [{
            xtype: 'rownumberer'
        },
        {
            text: 'id',
            hidden: true,
            dataIndex: 'SpotId',
            width: 50,
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'fa fa-pencil',
                tooltip: tt('Düzenle'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: 'onEditWizard'
            }]
        },
        {
            menuDisabled: true,
            sortable: true,
            xtype: 'actioncolumn',
            resizable: false,
            width: 30,
            align: 'center',
            items: [{
                tooltip: tt('Sil'),
                iconCls: 'cell-editing-delete-row',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: 'onClickDeleteSpot'
            }]
        }, {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            renderer: function (v) {
                return v * 1;
            },
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: '200',
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: '100',
            // format: '000.00',
            align: 'right',
            // format: '0.0,00/i',
            renderer: function (value) {
                return Ext.util.Format.number(value, "000.00").replace('.', ',')
            },
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('Start Date'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('End Date'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            filter: {
                type: 'date'
            },
            width: '90'
        },
        {
            text: tt('CreatedBy'),
            dataIndex: 'CreatedBy',
            filter: {
                type: 'string'
            },
            width: 140
        }
    ]
})
`
