import React from 'react'
import { isEmpty } from 'lodash'
import cx from 'classnames'
import styles from './index.module.scss'

export default function ExceptionView({ code, message, moveSummaries }) {
  const isWarning = code === 0
  const isError = code < 0 || code === undefined

  return (
    <div className={styles.exceptionView}>
      <p className={styles.exceptionTitle}>
        <i
          className={cx(
            styles.exceptionViewIcon,
            { 'fa fa-fw fa fa-exclamation-triangle': isWarning },
            { 'fa fa-fw fa fa-times-circle': isError }
          )}
        />
        <strong>{isError ? 'Error' : 'Warning'}</strong>
      </p>
      <span className={styles.exceptionDesc}>{message}</span>
      {!isEmpty(moveSummaries) ? (
        <div className={styles.exceptionSummary}>
          <span>
            <em>Summary:</em>
          </span>
          <ul className={styles.moveSummaries}>
            {moveSummaries.reduce((moveSummaries, moveSummary, index) => {
              const isWarning = moveSummary.Code === 0
              const isError = moveSummary.Code < 0 || moveSummary.Code === 2
              const isSuccess = moveSummary.Code === 1

              moveSummary = (
                <li className={styles.moveSummaryListItem} key={index.toString()}>
                  (
                  <i
                    className={cx(
                      styles.moveSummaryIcon,
                      { 'fa fa-fw fa fa-exclamation-triangle': isWarning },
                      { 'fa fa-fw fa fa-times-circle': isError },
                      { 'fa fa-fw fa fa-check-circle': isSuccess }
                    )}
                  />
                  ) {moveSummary.Message}
                </li>
              )

              return moveSummaries.concat(moveSummary)
            }, [])}
          </ul>
        </div>
      ) : null}
    </div>
  )
}
