import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PRODUCTfilterValues as filterValues } from '../../../../actions'
import Menu from './dropdownFilter'

class PeriodBox extends React.Component {
  constructor() {
    super()

    this.state = {
      showTrigger: false,
      showMenu: false,
      isTriggerHovered: false
    }

    this.periodBoxRef = null

    this.handleShowMenu = this.handleShowMenu.bind(this)
    this.handleHideMenu = this.handleHideMenu.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
  }

  handleToggleMenu() {}

  handleMouseEnter() {
    this.setState({ isTriggerHovered: true })
  }

  handleMouseLeave() {
    this.setState({ isTriggerHovered: false })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.periodBoxRef && !this.periodBoxRef.contains(event.target)) {
      this.setState({ showTrigger: false, showMenu: false })
    }
  }

  handleShowMenu() {
    this.setState({ showTrigger: true })
  }

  handleHideMenu() {
    if (this.state.showMenu) return
    this.setState({ showTrigger: false })
  }

  handleClick() {
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }))
  }

  render() {
    const {
      periodIndex,
      clickedProductInfo: { periodIndex: clickedPeriodIndex },
      dateArr,
      isGrandTotal,
      productLegendState: { currentProductInfoIndex = [] },
      filterValuesObj = {},
      weeklySort,
      connectApi
    } = this.props
    const { showTrigger } = this.state

    const date = _.map(dateArr, (date, index) => (
      <p key={`${date}-${index}`} className="period-box__date">
        {date}
        <span className="active-filter-container">
          {index === 0 && filterValuesObj.periodIndex === periodIndex && (
            <i className="fa-fw fa slvy-ui-icon-options active-filter-indicator" />
          )}
          {index === 0 && weeklySort.periodIndex === periodIndex && (
            <i
              className={`
              active-sort-indicator
              ${
                weeklySort.isAscending
                  ? 'fa-fw fa slvy-ui-icon-sort-ascending'
                  : 'fa-fw fa slvy-ui-icon-sort-descending'
              }`}
            />
          )}
        </span>
      </p>
    ))

    return (
      <div
        ref={(element) => {
          this.periodBoxRef = element
        }}
        className={`period-box period-bar__period-box ${
          periodIndex === clickedPeriodIndex || periodIndex + 1 === clickedPeriodIndex
            ? 'period-box_border-right'
            : ''
        } ${periodIndex === clickedPeriodIndex ? 'period-box_background' : ''} 
        ${
          showTrigger && !isGrandTotal && currentProductInfoIndex.length > 0
            ? 'period-box--hovered'
            : ''
        }`}
        onMouseEnter={this.handleShowMenu}
        onMouseLeave={this.handleHideMenu}
      >
        <div className="period-box period-box__date-container">{date}</div>
        {showTrigger && !isGrandTotal && currentProductInfoIndex.length > 0 && (
          <div
            className={`menu-trigger ${
              this.state.showMenu || this.state.isTriggerHovered ? 'menu-trigger--active' : ''
            }`}
            onClick={this.handleClick}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <i className="fa-fw fa slvy-ui-icon-caret-down" />
            {this.state.showMenu && <Menu connectApi={connectApi} periodIndex={periodIndex} />}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clickedProductInfo: state.PRODUCTclickedProductInfo.clickedProductInfo,
  productLegendState: state.PRODUCTlegend.productLegendState,
  filterValuesObj: state.PRODUCTresourcePlan.filterValues,
  weeklySort: state.PRODUCTresourcePlan.weeklySort
})

const mapDispatchToProps = {
  filterValues
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodBox)
