import { Component } from 'react'
import IconEditor from '../../SlvyIconSelector/index.tsx'

export default class IconSelector extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <div className="form-group">
        <label className="col-form-label">{this.props.title}</label>
        <IconEditor inputGroupClass="w-55" value={this.props.value} onChange={this.handleChange} />
      </div>
    )
  }
}
