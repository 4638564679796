import { useEffect, useState, ChangeEvent } from 'react'
import { FormLabel, Button, FormControl, FormGroup, Modal } from 'react-bootstrap'
import cx from 'classnames'
import moment from 'moment'
import { SlvyFormCheck } from '../..'
import { useUniqueId } from '../../../hooks'
import { IArchiveModalProps } from './ArchiveModal.types'

import styles from './index.module.scss'

const ArchiveModal = ({
  archiveDate = '',
  catalogName,
  onArchive,
  onHide,
  show
}: IArchiveModalProps) => {
  const [isArchiveBtnDisabled, setIsArchiveBtnDisabled] = useState(true)
  const [isDeleteCatalogChecked, setIsDeleteCatalogChecked] = useState(false)
  const [host, setHost] = useState('')

  const [uniqueId] = useUniqueId()
  const controlId = `controlId-${uniqueId}`

  useEffect(() => setHost(window.location.host), [])

  const handleChangeCatalogName = (event: ChangeEvent<HTMLInputElement>) => {
    const shouldArchiveBtnDisabled = event.target.value !== catalogName
    setIsArchiveBtnDisabled(shouldArchiveBtnDisabled)
  }

  const handleChangeDeleteCatalog = () => {
    setIsDeleteCatalogChecked((prevState: boolean) => !prevState)
  }

  const handleClickArchiveCatalog = () => onArchive(isDeleteCatalogChecked)

  return (
    <Modal
      aria-labelledby="catalog-archive-modal-title"
      data-testid="catalog-archive-modal"
      show={show}
      onHide={onHide}
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title className={styles.title} id="catalog-archive-modal-title">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <p className={styles.banner}>Unexpected bad things will happen if you don’t read this!</p>
          <div className="p-3">
            <p className="mb-3">
              This action cannot be undone. This will permanently archive{' '}
              <strong>{catalogName}</strong> catalog on <strong>{host}</strong>.
            </p>
            <form>
              <FormGroup className="mb-3" controlId={controlId}>
                <FormLabel>
                  <span className="d-inline-block fw-normal w-100">
                    <span className="d-flex justify-content-between w-100">
                      <span data-testid="catalog-archive-please-type-text">
                        Please type <strong>{catalogName}</strong> to confirm.
                      </span>
                      {archiveDate ? (
                        <span className="last-archived-on">
                          Last archived on:{' '}
                          <strong>{moment(archiveDate).format('DD/MM/YYYY')}</strong>
                        </span>
                      ) : null}
                    </span>
                  </span>
                </FormLabel>
                <FormControl
                  data-testid="catalog-archive-modal-input"
                  type="text"
                  onChange={handleChangeCatalogName}
                />
              </FormGroup>
            </form>
            <div className="mt-2 d-flex align-items-center justify-content-between">
              <SlvyFormCheck
                reverse
                checked={isDeleteCatalogChecked}
                formCheckClass="w-100 d-flex justify-content-between"
                label="Delete catalog"
                size="sm"
                type="switch"
                variant="success"
                onChange={handleChangeDeleteCatalog}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={cx(styles.button, 'w-100', 'pe-auto', 'fw-bold')}
            data-testid="catalog-archive-modal-button"
            disabled={isArchiveBtnDisabled}
            variant="light"
            onClick={handleClickArchiveCatalog}
          >
            <span>I understand the consequences, archive this catalog</span>
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default ArchiveModal
