import { Component } from 'react'
import JSONEditor from 'json-editor'
import _ from 'lodash'
import HiddenTable from './Editors/HiddenTable'
import SolvoyoObjectEditor from './Editors/SolvoyoObjectEditor'
import SolvoyoArrayEditor from './Editors/SolvoyoArrayEditor'
import SolvoyoSelect from './Editors/SolvoyoSelect'
import IconPicker from './Editors/IconPicker'
import StylePicker from './Editors/StylePicker'
import ColorPicker from './Editors/ColorPicker'
import SolvoyoMultiselectEditor from './Editors/SolvoyoMultiselectEditor'
import SolvoyoBooleanEditor from './Editors/SolvoyoBooleanEditor'
import SolvoyoNumberEditor from './Editors/SolvoyoNumberEditor'
import CodeEditor from './Editors/CodeEditor'
import SolvoyoMultipleEditor from './Editors/SolvoyoMultipleEditor'

import './PropertyEditor.scss'
import { JsonSchemaHelper } from '..'

class PropertyEditor extends Component {
  constructor(props) {
    super(props)

    window.JSONEditor = window.JSONEditor || JSONEditor

    const defaultOptions = {
      disable_edit_json: true,
      disable_properties: true,
      disable_array_delete_all_rows: true,
      disable_array_delete_last_row: true,
      theme: 'bootstrap3',
      iconlib: 'fontawesome4'
    }

    this.state = {
      defaultOptions,
      value: {},
      fields: [],
      showAdvanvedProperties: {}
    }
    this.onChange = this.onChange.bind(this)
    this.synchronizeDataDefinition = this.synchronizeDataDefinition.bind(this)
    this.showHideAdvancedProps = this.showHideAdvancedProps.bind(this)

    this.initialData = {}
  }

  getDifferenceObject(initial, modified) {
    for (const prop in initial) {
      if (!initial.hasOwnProperty(prop)) continue
      if (!modified.hasOwnProperty(prop)) continue
      if (_.isEqual(initial[prop], modified[prop])) {
        delete initial[prop]
        delete modified[prop]
      } else if (typeof initial[prop] === 'object') {
        this.getDifferenceObject(initial[prop], modified[prop])
      }
    }
  }

  getData() {
    return this.editor.getValue()
  }

  getInitialData() {
    return _.cloneDeep(this.initialData)
  }

  getChangedData(config) {
    const modifiedData = _.cloneDeep(config)
    const initialData = this.addDataFieldsToInitialConfig(this.props.fields)
    this.getDifferenceObject(initialData, modifiedData)

    return modifiedData
  }

  synchronizeDataDefinition(schemaPath) {
    const initialValue = this.addDataFieldsToInitialConfig(this.props.fields)

    const newValue = this.props.onSynchronizeDataDefinition(
      this.props.fields,
      this.props.value,
      this.schemaHelper,
      initialValue,
      schemaPath
    )

    const mergedValue = _.merge({}, initialValue, newValue)
    this.editor.setValue(mergedValue)

    this.onChange()
  }

  showHideAdvancedProps(showAdvancedProps, path) {
    this.setState({
      showAdvanvedProperties: {
        ...this.state.showAdvanvedProperties,
        [path]: showAdvancedProps
      }
    })
  }

  addCustomEditors() {
    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      // If the schema is a simple type
      if (schema.type === 'array' && !schema.format) {
        return SolvoyoArrayEditor.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (
        (schema.type === 'array' || schema.type === 'object') &&
        schema.format === HiddenTable.format
      ) {
        return HiddenTable.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      // If the schema is a simple type
      if (schema.type && schema.properties) {
        return SolvoyoObjectEditor.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.enumSource || schema.enum) {
        return SolvoyoSelect.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.type === 'array' && schema.format === 'select') {
        return SolvoyoMultiselectEditor.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.type === 'string' && schema.format === IconPicker.format) {
        return IconPicker.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.format === StylePicker.format) {
        return StylePicker.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (
        schema.type === 'string' &&
        (schema.format === ColorPicker.format || schema.format === 'NullColor')
      ) {
        return ColorPicker.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.type === SolvoyoNumberEditor.type) {
        return SolvoyoNumberEditor.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.type === SolvoyoBooleanEditor.type) {
        return SolvoyoBooleanEditor.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.format === CodeEditor.format) {
        return CodeEditor.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.oneOf || schema.anyOf) {
        return SolvoyoMultipleEditor.name
      }
    })

    window.JSONEditor.defaults.resolvers.unshift(function (schema) {
      if (schema.type === 'boolean' && !schema.enum) {
        // If explicitly set to 'checkbox', use that
        if (schema.format === 'checkbox' || (schema.options && schema.options.checkbox)) {
          return 'checkbox'
        }
        // Otherwise, default to select menu
        return SolvoyoBooleanEditor.name
      }
    })
  }

  createPropertyEditor(schema, options, validators) {
    const settings = this.state.defaultOptions

    settings.schema = schema

    for (const option in options) {
      if (options.hasOwnProperty(option)) {
        settings[option] = options[option]
      }
    }

    if (this.editor) {
      this.editor.destroy()
      this.editor.off('change', this.onChange)
    }
    this.editor = new window.JSONEditor(this.edHolder, settings)
    this.initialData = this.editor.getValue()

    this.editor.validator.options.custom_validators = []
    if (validators) {
      this.editor.validator.options.custom_validators =
        this.editor.validator.options.custom_validators.concat(validators)
    }

    this.editor.on('change', this.onChange)
  }

  onChange() {
    const value = this.editor.getValue()
    if (value) {
      let newValue = _.cloneDeep(value)
      _.forEach(this.props.changedHandlers, (handler) => {
        newValue = handler(newValue, this.initialData, this.cachedValue, this.schemaHelper)
      })

      // TODO: Is this clone necessary?
      this.cachedValue = _.cloneDeep(newValue)

      if (this.props.onChanged) {
        let data = null
        if (this.props.diffMode) {
          data = this.getChangedData(newValue)
        } else {
          data = newValue
        }

        if (!_.isEqual(this.props.value, data)) {
          this.props.onChanged(data)
        }
      }
    }
  }

  getObjectInObjectByKey(o, key) {
    for (const i in o) {
      if (o[i] !== null && typeof o[i] === 'object') {
        if (i === key) {
          return o[i]
        }
        const res = this.getObjectInObjectByKey(o[i], key)
        if (res) {
          return res
        }
      }
    }
  }

  addDataFieldsToInitialConfig(fields) {
    // update datafields in initial data
    const initialConfig = this.getInitialData()
    if (!_.isEmpty(initialConfig)) {
      const dataFields = this.getObjectInObjectByKey(initialConfig, 'datafields')
      if (dataFields) {
        dataFields.splice(0, dataFields.length)
        // Add an empty item to the list. The user resets the selection using this value.
        dataFields.push('')
        _.forEach(fields, function (value, key) {
          dataFields.push(value.fieldName)
        })
      }
    }
    return initialConfig
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.destroy()
      this.editor.off('change', this.onChange)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.schema && !_.isEqual(this.props.schema, nextProps.schema)) {
      this.createPropertyEditor(nextProps.schema)
    }
  }

  componentDidUpdate() {
    if (this.editor && !_.isEmpty(this.props.value)) {
      const initialValue = this.addDataFieldsToInitialConfig(this.props.fields)
      const mergedValue = _.merge({}, initialValue, this.props.value)

      this.editor.setValue(mergedValue)
    }
  }

  componentDidMount() {
    this.addCustomEditors()
    if (this.props.schema) {
      // Set synchronizeDataDefinition. It is used while automatically creating columns, series, levels...
      window.JSONEditor.defaults.synchronizeDataDefinition =
        window.JSONEditor.defaults.synchronizeDataDefinition || {}
      window.JSONEditor.defaults.synchronizeDataDefinition[this.props.schema.title] =
        this.synchronizeDataDefinition

      window.JSONEditor.defaults.showHideAdvancedProps = this.showHideAdvancedProps

      this.createPropertyEditor(this.props.schema, this.props.options, this.props.validators)

      // We have to run plugin specific functions here
      // Or else intial value is not empty on plugin specific parts e.g. chart series
      let newValue = _.cloneDeep(this.props.value)
      _.forEach(this.props.changedHandlers, (handler) => {
        newValue = handler(newValue, this.initialData, this.cachedValue, this.schemaHelper)
      })

      const initialValue = this.addDataFieldsToInitialConfig(this.props.fields)
      let mergedValue = _.merge({}, initialValue, this.props.value)
      if (this.props.initializer) {
        mergedValue = this.props.initializer(mergedValue)
      }

      this.editor.setValue(mergedValue)
    }
  }

  render() {
    let editorClass = ''
    if (this.props.hidden) {
      editorClass = 'hidden'
    }

    window.JSONEditor.defaults.showAdvanvedProperties = this.state.showAdvanvedProperties

    return (
      <div>
        <div
          ref={(input) => {
            this.edHolder = input
          }}
          className={editorClass}
        />
        <JsonSchemaHelper
          ref={(input) => {
            this.schemaHelper = input
          }}
          hidden
        />
      </div>
    )
  }
}

export default PropertyEditor
