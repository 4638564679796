import _ from 'lodash'

let totalSchema = {}

const pluginSchema = {
  title: 'Chart',
  type: 'object',
  format: 'objectTab',
  properties: {
    datafields: {
      type: 'array',
      format: 'HiddenTable',
      title: 'Data Fields',
      items: {
        type: 'string'
      }
    }
  }
}

const generalSchema = {
  general: {
    title: 'General',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      },
      header: {
        type: 'string',
        title: 'Header'
      },
      maximizable: {
        type: 'boolean',
        title: 'Maximizable',
        default: false,
        required: true
      },
      export: {
        type: 'boolean',
        title: 'Exportable',
        default: false,
        required: true
      },
      showNoDataToDisplay: {
        type: 'boolean',
        title: 'Show No Data To Display',
        default: false,
        required: true
      }
    }
  }
}

const chartSchema = {
  chart: {
    title: 'Chart',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      synchronized: {
        type: 'boolean',
        title: 'Synchronized Chart',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      alignTicks: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      alignYAxisZero: {
        title: 'Align Y-Axis zero levels',
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 8
      },
      animation: {
        type: 'boolean',
        required: true,
        default: true
      },
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: '#ffffff',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'Color',
        default: '#335cad',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      showCredits: {
        type: 'boolean',
        title: 'Show credits?',
        default: false,
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 9
      },
      hoverOpacity: {
        type: 'boolean',
        title: 'Change opacity on hover',
        default: true,
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 8
      },
      borderRadius: {
        type: 'number',
        default: 0
      },
      borderWidth: {
        type: 'number',
        default: 0
      },
      className: {
        type: 'string',
        default: ''
      },
      colorCount: {
        type: 'number',
        default: 10
      },
      description: {
        type: 'string',
        default: ''
      },
      height: {
        type: 'string',
        default: ''
      },
      ignoreHiddenSeries: {
        type: 'boolean',
        required: true,
        default: true
      },
      ignoreSeriesWithNullData: {
        title: 'IgnoreSeriesWithNullData',
        type: 'boolean',
        required: true,
        default: true
      },
      inverted: {
        type: 'boolean',
        required: true,
        default: false
      },
      panKey: {
        type: 'string',
        default: ''
      },
      panning: {
        type: 'boolean',
        required: true,
        default: false
      },
      pinchType: {
        type: 'string',
        default: ''
      },
      plotBackgroundColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      plotBackgroundImage: {
        type: 'string',
        default: ''
      },
      plotBorderColor: {
        type: 'string',
        format: 'Color',
        default: '#cccccc'
      },
      plotBorderWidth: {
        type: 'number',
        default: 0
      },
      plotShadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      polar: {
        type: 'boolean',
        required: true,
        default: false
      },
      reflow: {
        type: 'boolean',
        required: true,
        default: false
      },
      selectionMarkerFill: {
        type: 'string',
        format: 'Color',
        default: 'rgba(51,92,173,0.25)'
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      showAxes: {
        type: 'boolean',
        required: true,
        default: false
      },
      spacingBottom: {
        type: 'number',
        default: 15
      },
      spacingLeft: {
        type: 'number',
        default: 10
      },
      spacingRight: {
        type: 'number',
        default: 10
      },
      spacingTop: {
        type: 'number',
        default: 15
      },
      marginBottom: {
        type: 'NullableNumber',
        default: ''
      },
      marginLeft: {
        type: 'NullableNumber',
        default: ''
      },
      marginRight: {
        type: 'NullableNumber',
        default: ''
      },
      marginTop: {
        type: 'NullableNumber',
        default: ''
      },
      type: {
        type: 'string',
        enum: ['line', 'area', 'column', 'bar', 'spline', 'pie', 'scatter'],
        default: 'line',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      typeDescription: {
        type: 'string',
        default: ''
      },
      width: {
        type: 'string',
        default: '',
        description: 'In pixels'
      },
      zoomType: {
        options: {
          input_width: '500px',
          basic: true
        },
        type: 'string',
        enum: ['', 'x', 'y', 'xy'],
        default: '',
        required: true,
        propertyOrder: 2
      },
      emptyText: {
        type: 'string',
        title: 'No Data Text',
        default: 'No Data to Display',
        options: {
          basic: true
        },
        propertyOrder: 7
      },
      resetZoomButton: {
        type: 'object',
        title: 'Reset Zoom Button',
        properties: {
          theme: {
            type: 'object',
            title: 'Theme',
            properties: {
              zIndex: {
                type: 'number',
                title: 'z-index',
                default: 7
              }
            }
          }
        }
      },
      scrollbar: {
        type: 'object',
        title: 'Scrollbar',
        options: {
          collapsed: true
        },
        properties: {
          enabled: {
            type: 'boolean',
            required: true,
            default: true
          },
          margin: {
            type: 'number',
            default: 10
          },
          barBackgroundColor: {
            type: 'string',
            format: 'Color',
            default: '#cccccc'
          },
          barBorderColor: {
            type: 'string',
            format: 'Color',
            default: '#cccccc'
          },
          barBorderRadius: {
            type: 'number',
            default: 0
          },
          barBorderWidth: {
            type: 'number',
            default: 0
          },
          buttonArrowColor: {
            type: 'string',
            format: 'Color',
            default: '#333333'
          },
          buttonBackgroundColor: {
            type: 'string',
            format: 'Color',
            default: '#e6e6e6'
          },
          buttonBorderColor: {
            type: 'string',
            format: 'Color',
            default: '#cccccc'
          },
          buttonBorderRadius: {
            type: 'number',
            default: 0
          },
          buttonBorderWidth: {
            type: 'number',
            default: 1
          }
        }
      }
    }
  }
}

const plotOptionsSchema = {
  plotOptions: {
    title: 'PlotOptions',
    type: 'object',
    properties: {
      area: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: true
        },
        properties: {}
      },
      bar: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: true
        },
        properties: {}
      },
      bubble: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: true
        },
        properties: {}
      },
      column: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: true
        },
        properties: {}
      },
      line: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: true
        },
        properties: {}
      },
      pie: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: true
        },
        properties: {}
      },
      spline: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: true
        },
        properties: {}
      }
    }
  }
}

const xFieldSchema = {
  xField: {
    title: 'X Field',
    type: 'object',
    properties: {
      fieldName: {
        type: 'string',
        title: 'Data Field',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      },
      catGroupField: {
        type: 'string',
        title: 'Group Data Field',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds',
        description: 'Should include groups of xField data'
      },
      imageField: {
        type: 'string',
        title: 'Image Data Field',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds',
        description: 'Should include URL'
      }
    }
  }
}

const seriesSchema = {
  series: {
    type: 'array',
    title: 'Series',
    options: {
      syncButton: true
    },

    items: {
      type: 'object',
      title: 'Series',
      headerTemplate: '{{ self.fieldName }}',
      options: {
        collapsed: true
      },
      properties: {
        fieldName: {
          type: 'string',
          title: 'Data Field',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        },
        __Temp: {
          type: 'string',
          options: {
            hidden: true
          }
        },
        seriesProps: {
          title: 'Series Type',
          oneOf: [],
          options: {
            disable_collapse: true
          }
        }
      }
    }
  }
}

const titleSchema = {
  title: {
    title: 'Title',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      margin: {
        type: 'number',
        default: 15
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '18px' },
        options: {
          basic: true
        }
      },
      maxStyle: {
        type: 'object',
        title: 'Max Style',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '20px' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: 'Chart Title',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const subtitleSchema = {
  subtitle: {
    title: 'Subtitle',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(102,102,102,1)' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const tooltipSchema = {
  tooltip: {
    title: 'Tooltip',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      animation: {
        type: 'boolean',
        required: true,
        default: true
      },
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: 'rgba(247,247,247,0.85)',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      borderRadius: {
        type: 'number',
        default: 3
      },
      borderWidth: {
        type: 'number',
        default: 1,
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      followPointer: {
        type: 'boolean',
        required: true,
        default: false
      },
      followTouchMove: {
        type: 'NullableBoolean',
        default: null
      },
      footerFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      headerFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      hideDelay: {
        type: 'number',
        default: 500
      },
      padding: {
        type: 'number',
        default: 8
      },
      pointFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: true
      },
      shared: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      showInGrid: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      snap: {
        type: 'NullableNumber',
        default: ''
      },
      split: {
        type: 'boolean',
        required: true,
        default: false
      },
      style: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px'
        },
        options: {
          basic: true
        }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      valueDecimals: {
        type: 'NullableNumber',
        default: ''
      },
      valuePrefix: {
        type: 'string',
        default: ''
      },
      valueSuffix: {
        type: 'string',
        default: ''
      },
      xDateFormat: {
        type: 'string',
        default: ''
      }
    }
  }
}

const legendSchema = {
  legend: {
    title: 'Legend',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      backgroundColor: {
        type: 'string',
        format: 'NullColor'
      },
      borderColor: {
        type: 'string',
        format: 'Color',
        default: '#999999'
      },
      borderRadius: {
        type: 'number',
        default: 0
      },
      borderWidth: {
        type: 'number',
        default: 0
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      hideWhenNoData: {
        // Delete this property
        hidden: true,
        title: 'Hide when no data',
        type: 'boolean',
        required: true,
        default: true
      },
      itemDistance: {
        type: 'number',
        default: 20
      },
      itemHiddenStyle: {
        type: 'object',
        format: 'Style',
        default: { color: '#cccccc' }
      },
      itemHoverStyle: {
        type: 'object',
        format: 'Style',
        default: { color: '#000000' }
      },
      itemMarginBottom: {
        type: 'number',
        default: 0
      },
      itemMarginTop: {
        type: 'number',
        default: 0
      },
      itemStyle: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px',
          fontWeight: 'bold'
        },
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      itemWidth: {
        type: 'NullableNumber',
        default: ''
      },
      labelFormat: {
        type: 'string',
        default: ''
      },
      layout: {
        type: 'string',
        enum: ['horizontal', 'vertical'],
        default: 'horizontal',
        required: true
      },
      maxHeight: {
        type: 'NullableNumber',
        default: ''
      },
      padding: {
        type: 'number',
        default: 8
      },
      reversed: {
        type: 'boolean',
        required: true,
        default: false
      },
      rtl: {
        type: 'boolean',
        required: true,
        default: false
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      squareSymbol: {
        type: 'boolean',
        required: true,
        default: true
      },
      symbolPadding: {
        type: 'number',
        default: 5
      },
      symbolRadius: {
        type: 'NullableNumber',
        default: ''
      },
      symbolWidth: {
        type: 'NullableNumber',
        default: ''
      },
      title: {
        type: 'object',
        title: 'Title',
        options: {
          collapsed: true
        },
        propertyOrder: 1001,
        properties: {
          text: {
            type: 'string',
            default: ''
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { fontWeight: 'bold' }
          }
        }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['top', 'middle', 'bottom'],
        default: 'bottom',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      width: {
        type: 'NullableNumber',
        default: ''
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'number',
        default: 0
      }
    }
  }
}

const exportingSchema = {
  exporting: {
    title: 'Exporting',
    type: 'object',
    properties: {
      allowHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: false
      },
      fallbackToExportServer: {
        type: 'boolean',
        required: true,
        default: true
      },
      filename: {
        type: 'string',
        default: 'chart'
      },
      libURL: {
        type: 'string',
        default: 'https://code.highcharts.com/{version}/lib'
      },
      printMaxWidth: {
        type: 'number',
        default: 780
      },
      scale: {
        type: 'number',
        default: 2
      },
      sourceHeight: {
        type: 'NullableNumber',
        default: ''
      },
      sourceWidth: {
        type: 'NullableNumber',
        default: ''
      },
      url: {
        type: 'string',
        default: 'https://export.highcharts.com'
      },
      width: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const xAxisSchema = {
  xAxis: {
    title: 'X Axis',
    type: 'array',
    default: [{ id: '0' }],
    items: {
      type: 'object',
      title: 'xAxis',
      options: {
        collapsed: true,
        sortProperties: 'key',
        splitProperties: true
      },
      properties: {
        endOnTick: {
          type: 'boolean',
          required: true,
          default: false
        },
        crosshair: {
          type: 'boolean',
          required: true,
          default: false
        },
        gridLineWidth: {
          type: 'number',
          default: 0
        },
        labels: {
          type: 'object',
          title: 'Labels',
          propertyOrder: 1001,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['', 'left', 'center', 'right'],
              default: '',
              required: true
            },
            autoRotation: {
              type: 'array',
              format: 'table',
              items: {
                title: 'angle',
                type: 'number'
              },
              default: [-45],
              options: {
                collapsed: true
              },
              propertyOrder: 1000
            },
            autoRotationLimit: {
              type: 'number',
              default: 80
            },
            enabled: {
              type: 'boolean',
              required: true,
              default: true
            },
            format: {
              type: 'string'
            },
            padding: {
              type: 'number',
              default: 5
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            staggerLines: {
              type: 'NullableNumber',
              default: ''
            },
            step: {
              type: 'NullableNumber',
              default: ''
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
            },
            width: {
              type: 'NullableNumber',
              default: ''
            },
            useHTML: {
              type: 'boolean',
              required: true,
              default: false
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            },
            zIndex: {
              type: 'number',
              default: 7
            }
          }
        },
        lineWidth: {
          type: 'number',
          default: 1
        },
        maxPadding: {
          type: 'number',
          default: 0.01
        },
        minPadding: {
          type: 'number',
          default: 0.01
        },
        plotBands: {
          type: 'array',
          title: 'Plot Bands',
          propertyOrder: 1002,
          options: {
            collapsed: true
          },
          items: {
            type: 'object',
            title: 'Plot Band',
            properties: {
              bandField: {
                type: 'string',
                title: 'Band Field',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds'
              },
              bandFieldAsLabel: {
                title: 'Band Field as Label',
                type: 'boolean',
                required: true,
                default: false
              },
              borderColor: {
                type: 'string',
                format: 'NullColor'
              },
              borderWidth: {
                type: 'number',
                default: 0
              },
              color: {
                type: 'string',
                format: 'NullColor'
              },
              from: {
                type: 'NullableNumber',
                default: ''
              },
              id: {
                type: 'string',
                default: ''
              },
              label: {
                type: 'object',
                title: 'Label',
                propertyOrder: 1001,
                options: {
                  collapsed: true
                },
                properties: {
                  align: {
                    type: 'string',
                    enum: ['left', 'center', 'right'],
                    default: 'center',
                    required: true
                  },

                  rotation: {
                    type: 'number',
                    default: 0
                  },

                  style: {
                    type: 'object',
                    format: 'Style',
                    default: {}
                  },
                  text: {
                    type: 'string',
                    default: ''
                  },
                  textAlign: {
                    type: 'string',
                    enum: ['', 'left', 'center', 'right'],
                    default: '',
                    required: true
                  },
                  useHTML: {
                    type: 'boolean',
                    required: true,
                    default: false
                  },
                  verticalAlign: {
                    type: 'string',
                    enum: ['top', 'middle', 'bottom'],
                    default: 'top',
                    required: true
                  },
                  x: {
                    type: 'NullableNumber',
                    default: ''
                  },
                  y: {
                    type: 'NullableNumber',
                    default: ''
                  }
                }
              },
              to: {
                type: 'NullableNumber',
                default: ''
              },
              zIndex: {
                type: 'NullableNumber',
                default: ''
              }
            }
          }
        },
        plotLines: {
          type: 'array',
          title: 'Plot Lines',
          propertyOrder: 1003,
          options: {
            collapsed: true
          },
          items: {
            type: 'object',
            title: 'Plot Line',
            properties: {
              plotLineField: {
                type: 'string',
                title: 'Line Field',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds'
              },
              plotLineFieldAsLabel: {
                title: 'Line Field as Label',
                type: 'boolean',
                required: true,
                default: false
              },
              value: {
                title: 'Value',
                type: 'number',
                default: null
              },
              width: {
                title: 'Line Width',
                type: 'number',
                default: 1
              },
              color: {
                title: 'Line Color',
                type: 'string',
                format: 'Color',
                default: '#e6ebf5'
              },
              dashStyle: {
                title: 'Dash Style',
                type: 'string',
                enum: [
                  'Solid',
                  'ShortDash',
                  'ShortDot',
                  'ShortDashDot',
                  'ShortDashDotDot',
                  'Dot',
                  'Dash',
                  'LongDash',
                  'DashDot',
                  'LongDashDot',
                  'LongDashDotDot'
                ],
                default: 'Solid',
                required: true
              },
              label: {
                type: 'object',
                title: 'Label',
                propertyOrder: 1001,
                options: {
                  collapsed: true
                },
                properties: {
                  align: {
                    type: 'string',
                    enum: ['left', 'center', 'right'],
                    default: 'center',
                    required: true
                  },
                  rotation: {
                    type: 'number',
                    default: 90
                  },
                  style: {
                    type: 'object',
                    format: 'Style',
                    default: {}
                  },
                  text: {
                    type: 'string',
                    default: ''
                  },
                  textAlign: {
                    type: 'string',
                    enum: ['', 'left', 'center', 'right'],
                    default: '',
                    required: true
                  },
                  useHTML: {
                    type: 'boolean',
                    required: true,
                    default: false
                  },
                  verticalAlign: {
                    type: 'string',
                    enum: ['top', 'middle', 'bottom'],
                    default: 'top',
                    required: true
                  },
                  x: {
                    type: 'NullableNumber',
                    default: ''
                  },
                  y: {
                    type: 'NullableNumber',
                    default: ''
                  }
                }
              }
            }
          }
        },
        showLastLabel: {
          type: 'boolean',
          required: true,
          default: true
        },
        startOnTick: {
          type: 'boolean',
          required: true,
          default: false
        },
        tickWidth: {
          type: 'number',
          default: 1
        },
        enableGroupedTick: {
          type: 'boolean',
          default: false,
          title: 'Grouped Tick',
          description: 'Enable to use separator for grouped data field.'
        },
        title: {
          type: 'object',
          title: 'Title',
          propertyOrder: 1004,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['low', 'middle', 'high'],
              default: 'middle',
              required: true
            },
            margin: {
              type: 'NullableNumber',
              default: ''
            },
            offset: {
              type: 'NullableNumber',
              default: ''
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)' }
            },
            text: {
              type: 'string',
              default: '',
              description: 'Apply templates using {columnName}'
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            }
          }
        }
      }
    }
  }
}

const yAxisSchema = {
  yAxis: {
    title: 'Y Axis',
    type: 'array',
    default: [{ id: '0' }],
    items: {
      type: 'object',
      title: 'yAxis',
      options: {
        collapsed: true,
        sortProperties: 'key',
        splitProperties: true
      },
      properties: {
        angle: {
          type: 'number',
          default: 0
        },
        endOnTick: {
          type: 'boolean',
          required: true,
          default: true
        },
        gridLineInterpolation: {
          type: 'string',
          enum: ['', 'circle', 'polygon'],
          default: '',
          required: true
        },
        gridLineWidth: {
          type: 'number',
          default: 1
        },
        labels: {
          type: 'object',
          title: 'Labels',
          propertyOrder: 1001,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['left', 'center', 'right'],
              default: 'right',
              required: true
            },
            autoRotation: {
              type: 'array',
              format: 'table',
              items: {
                title: 'angle',
                type: 'number'
              },
              default: [-45],
              options: {
                collapsed: true
              },
              propertyOrder: 1000
            },
            autoRotationLimit: {
              type: 'number',
              default: 80
            },
            enabled: {
              type: 'boolean',
              required: true,
              default: true
            },
            format: {
              type: 'string'
            },
            padding: {
              type: 'number',
              default: 5
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            staggerLines: {
              type: 'NullableNumber',
              default: ''
            },
            step: {
              type: 'NullableNumber',
              default: ''
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
            },
            width: {
              type: 'NullableNumber',
              default: ''
            },
            useHTML: {
              type: 'boolean',
              required: true,
              default: false
            },
            x: {
              type: 'NullableNumber',
              default: ''
            },
            y: {
              type: 'number',
              default: 3
            },
            zIndex: {
              type: 'number',
              default: 7
            }
          }
        },
        lineWidth: {
          type: 'number',
          default: 0
        },
        maxColor: {
          type: 'string',
          format: 'Color',
          default: '#003399'
        },
        maxPadding: {
          type: 'number',
          default: 0.05
        },
        minColor: {
          type: 'string',
          format: 'Color',
          default: '#e6ebf5'
        },
        minPadding: {
          type: 'number',
          default: 0.05
        },
        reversedStacks: {
          type: 'boolean',
          required: true,
          default: true
        },
        showLastLabel: {
          type: 'NullableBoolean',
          default: null
        },
        startOnTick: {
          type: 'boolean',
          required: true,
          default: true
        },
        tickWidth: {
          type: 'number',
          default: 0
        },
        title: {
          type: 'object',
          title: 'Title',
          propertyOrder: 1002,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['low', 'middle', 'high'],
              default: 'middle',
              required: true
            },
            margin: {
              type: 'number',
              default: 40
            },
            offset: {
              type: 'NullableNumber',
              default: ''
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 270
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)' }
            },
            text: {
              type: 'string',
              default: 'Values',
              description: 'Apply templates using {columnName}'
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            }
          }
        },
        plotLines: {
          type: 'array',
          title: 'Plot Lines',
          propertyOrder: 1003,
          options: {
            collapsed: true
          },
          items: {
            type: 'object',
            title: 'Plot Line',
            properties: {
              plotLineField: {
                type: 'string',
                title: 'Line Field',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds'
              },
              value: {
                title: 'Value',
                type: 'number',
                default: null
              },
              width: {
                title: 'Line Width',
                type: 'number',
                default: 1
              },
              color: {
                title: 'Line Color',
                type: 'string',
                format: 'Color',
                default: '#e6ebf5'
              },
              dashStyle: {
                title: 'Dash Style',
                type: 'string',
                enum: [
                  'Solid',
                  'ShortDash',
                  'ShortDot',
                  'ShortDashDot',
                  'ShortDashDotDot',
                  'Dot',
                  'Dash',
                  'LongDash',
                  'DashDot',
                  'LongDashDot',
                  'LongDashDotDot'
                ],
                default: 'Solid',
                required: true
              },
              label: {
                type: 'object',
                title: 'Label',
                propertyOrder: 1001,
                options: {
                  collapsed: true
                },
                properties: {
                  align: {
                    type: 'string',
                    enum: ['left', 'center', 'right'],
                    default: 'center',
                    required: true
                  },
                  rotation: {
                    type: 'number',
                    default: 0
                  },
                  style: {
                    type: 'object',
                    format: 'Style',
                    default: {}
                  },
                  text: {
                    type: 'string',
                    default: ''
                  },
                  textAlign: {
                    type: 'string',
                    enum: ['', 'left', 'center', 'right'],
                    default: '',
                    required: true
                  },
                  useHTML: {
                    type: 'boolean',
                    required: true,
                    default: false
                  },
                  verticalAlign: {
                    type: 'string',
                    enum: ['top', 'middle', 'bottom'],
                    default: 'top',
                    required: true
                  },
                  x: {
                    type: 'NullableNumber',
                    default: ''
                  },
                  y: {
                    type: 'NullableNumber',
                    default: ''
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

const zAxisSchema = {
  zAxis: {
    title: 'Z Axis',
    type: 'array',
    items: {
      type: 'object',
      title: 'zAxis',
      options: {
        collapsed: true,
        sortProperties: 'key',
        splitProperties: true
      },
      properties: {
        endOnTick: {
          type: 'boolean',
          required: true,
          default: false
        },
        gridLineWidth: {
          type: 'number',
          default: 0
        },
        labels: {
          type: 'object',
          title: 'Labels',
          propertyOrder: 1001,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['', 'left', 'center', 'right'],
              default: '',
              required: true
            },
            autoRotation: {
              type: 'array',
              format: 'table',
              items: {
                title: 'angle',
                type: 'number'
              },
              default: [-45],
              options: {
                collapsed: true
              },
              propertyOrder: 1000
            },
            autoRotationLimit: {
              type: 'number',
              default: 80
            },
            enabled: {
              type: 'boolean',
              required: true,
              default: true
            },
            format: {
              type: 'string'
            },
            padding: {
              type: 'number',
              default: 5
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            staggerLines: {
              type: 'NullableNumber',
              default: ''
            },
            step: {
              type: 'NullableNumber',
              default: ''
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
            },
            useHTML: {
              type: 'boolean',
              required: true,
              default: false
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            },
            zIndex: {
              type: 'number',
              default: 7
            }
          }
        },
        lineWidth: {
          type: 'number',
          default: 1
        },
        maxPadding: {
          type: 'number',
          default: 0.01
        },
        minPadding: {
          type: 'number',
          default: 0.01
        },
        showLastLabel: {
          type: 'boolean',
          required: true,
          default: true
        },
        startOnTick: {
          type: 'boolean',
          required: true,
          default: false
        },
        tickWidth: {
          type: 'number',
          default: 1
        },
        title: {
          type: 'object',
          title: 'Title',
          propertyOrder: 1002,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['low', 'middle', 'high'],
              default: 'middle',
              required: true
            },
            margin: {
              type: 'NullableNumber',
              default: ''
            },
            offset: {
              type: 'NullableNumber',
              default: ''
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)' }
            },
            text: {
              type: 'string',
              default: ''
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            }
          }
        }
      }
    }
  }
}

const axisCommon = {
  allowDecimals: {
    type: 'boolean',
    required: true,
    default: true
  },
  alternateGridColor: {
    type: 'string',
    format: 'NullColor'
  },
  ceiling: {
    type: 'NullableNumber',
    default: ''
  },
  className: {
    type: 'string',
    default: ''
  },
  description: {
    type: 'string',
    default: ''
  },
  format: {
    type: 'string',
    default: '',
    options: {
      basic: true
    },
    propertyOrder: 7
  },
  floor: {
    type: 'NullableNumber',
    default: ''
  },
  gridLineColor: {
    type: 'string',
    format: 'Color',
    default: '#e6e6e6'
  },
  gridLineDashStyle: {
    type: 'string',
    enum: [
      'Solid',
      'ShortDash',
      'ShortDot',
      'ShortDashDot',
      'ShortDashDotDot',
      'Dot',
      'Dash',
      'LongDash',
      'DashDot',
      'LongDashDot',
      'LongDashDotDot'
    ],
    default: 'Solid',
    required: true
  },
  gridZIndex: {
    type: 'number',
    default: 1
  },
  id: {
    type: 'string',
    default: '',
    options: {
      basic: true
    },
    propertyOrder: 1
  },

  lineColor: {
    type: 'string',
    format: 'Color',
    default: '#ccd6eb'
  },
  linkedTo: {
    type: 'NullableNumber',
    default: ''
  },
  max: {
    type: 'NullableNumber',
    default: '',
    options: {
      basic: true
    },
    propertyOrder: 6,
    description: 'Obsolete, please do not use that property'
  },
  min: {
    type: 'NullableNumber',
    default: '',
    options: {
      basic: true
    },
    propertyOrder: 5,
    description: 'Obsolete, please do not use that property'
  },
  minRange: {
    type: 'NullableNumber',
    default: ''
  },
  minTickInterval: {
    type: 'NullableNumber',
    default: ''
  },
  minorGridLineColor: {
    type: 'string',
    format: 'Color',
    default: '#f2f2f2'
  },
  minorGridLineDashStyle: {
    type: 'string',
    enum: [
      'Solid',
      'ShortDash',
      'ShortDot',
      'ShortDashDot',
      'ShortDashDotDot',
      'Dot',
      'Dash',
      'LongDash',
      'DashDot',
      'LongDashDot',
      'LongDashDotDot'
    ],
    default: 'Solid',
    required: true
  },
  minorGridLineWidth: {
    type: 'number',
    default: 1
  },
  minorTickColor: {
    type: 'string',
    format: 'Color',
    default: '#999999'
  },
  minorTickInterval: {
    type: 'NullableNumber',
    default: ''
  },
  minorTickLength: {
    type: 'number',
    default: 2
  },
  minorTickPosition: {
    type: 'string',
    enum: ['inside', 'outside'],
    default: 'outside',
    required: true
  },
  minorTickWidth: {
    type: 'number',
    default: 0
  },
  offset: {
    type: 'number',
    default: 0
  },
  opposite: {
    type: 'boolean',
    required: true,
    default: false,
    options: {
      basic: true
    },
    propertyOrder: 2
  },
  reversed: {
    type: 'boolean',
    required: true,
    default: false
  },
  showEmpty: {
    type: 'boolean',
    required: true,
    default: true
  },
  showFirstLabel: {
    type: 'boolean',
    required: true,
    default: true
  },
  softMax: {
    type: 'NullableNumber',
    default: ''
  },
  softMin: {
    type: 'NullableNumber',
    default: ''
  },
  startOfWeek: {
    type: 'number',
    default: 1
  },
  tickAmount: {
    type: 'NullableNumber',
    default: ''
  },
  tickColor: {
    type: 'string',
    format: 'Color',
    default: '#ccd6eb'
  },
  tickInterval: {
    type: 'NullableNumber',
    default: '',
    options: {
      basic: true
    },
    propertyOrder: 4
  },
  tickLength: {
    type: 'number',
    default: 10
  },
  tickPixelInterval: {
    type: 'NullableNumber',
    default: ''
  },
  tickPosition: {
    type: 'string',
    enum: ['inside', 'outside'],
    default: 'outside',
    required: true
  },
  tickmarkPlacement: {
    type: 'string',
    enum: ['', 'between', 'on'],
    default: '',
    required: true
  },
  type: {
    type: 'string',
    enum: ['linear', 'logarithmic', 'datetime', 'category'],
    default: 'linear',
    required: true
  },
  uniqueNames: {
    type: 'boolean',
    required: true,
    default: true
  },
  visible: {
    type: 'boolean',
    required: true,
    default: true,
    options: {
      basic: true
    },
    propertyOrder: 3
  }
}

const commonSeries = {
  color: {
    type: 'string',
    format: 'NullColor',
    options: {
      basic: true
    },
    propertyOrder: 2
  },

  monochrome: {
    type: 'object',
    title: 'Monochrome',
    options: {
      collapsed: true
    },
    propertyOrder: 3,
    properties: {
      monochromeEnabled: {
        title: 'Monochrome',
        type: 'boolean',
        default: false
      },
      brightenParameter: {
        title: 'Brighten Parameter',
        type: 'number',
        default: 1,
        description: 'Enter number'
      }
    }
  },

  pattern: {
    type: 'object',
    title: 'Pattern Fill',
    options: {
      collapsed: true
    },
    propertyOrder: 4,
    default: {
      patternEnabled: false,
      patternIndex: 1,
      strokeWidth: 2,
      opacity: 1
    },
    properties: {
      patternEnabled: {
        title: 'Enable Pattern',
        type: 'boolean',
        description: 'Overwrites monochrome'
      },
      patternIndex: {
        title: 'Default Patterns',
        type: 'number',
        enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

        required: true
      },
      strokeWidth: {
        title: 'Pattern Width',
        type: 'number'
      },
      backgroundColor: {
        type: 'string',
        format: 'NullColor'
      },

      opacity: {
        type: 'number',
        description: 'Number between 0-1'
      }
    }
  },

  allowPointSelect: {
    type: 'boolean',
    required: true,
    default: false,
    options: {
      basic: true
    },
    propertyOrder: 20
  },
  animation: {
    type: 'boolean',
    required: true,
    default: true,
    options: {
      basic: true
    },
    propertyOrder: 22
  },
  animationLimit: {
    type: 'NullableNumber',
    default: ''
  },
  className: {
    type: 'string',
    default: ''
  },

  colorIndex: {
    type: 'NullableNumber',
    default: ''
  },
  cursor: {
    type: 'string',
    default: ''
  },
  dataLabels: {
    type: 'object',
    title: 'Data Labels',
    options: {
      collapsed: true
    },
    propertyOrder: 1001,
    additionalProperties: false,
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true
      },
      allowOverlap: {
        type: 'boolean',
        required: true,
        default: false
      },
      backgroundColor: {
        type: 'string',
        format: 'NullColor'
      },
      borderColor: {
        type: 'string',
        format: 'NullColor'
      },
      borderRadius: {
        type: 'number',
        default: 0
      },
      borderWidth: {
        type: 'number',
        default: 0
      },
      className: {
        type: 'string',
        default: ''
      },
      color: {
        type: 'string',
        format: 'NullColor'
      },
      crop: {
        type: 'boolean',
        required: true,
        default: true
      },
      defer: {
        type: 'boolean',
        required: true,
        default: true
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: false
      },
      format: {
        type: 'string',
        default: '{y}'
      },
      inside: {
        type: 'NullableBoolean',
        default: null
      },
      overflow: {
        type: 'string',
        enum: ['justify', 'none'],
        default: 'justify',
        required: true
      },
      padding: {
        type: 'number',
        default: 5
      },
      rotation: {
        type: 'number',
        default: 0
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      shape: {
        type: 'string',
        enum: ['square', 'callout', 'circle'],
        default: 'square',
        required: true
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { fontSize: '11px', fontWeight: 'bold' }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'number',
        default: -6
      },
      zIndex: {
        type: 'number',
        default: 6
      }
    }
  },
  description: {
    type: 'string',
    default: ''
  },
  enableMouseTracking: {
    type: 'boolean',
    required: true,
    default: 'true',
    options: {
      basic: true
    },
    propertyOrder: 21
  },
  findNearestPointBy: {
    type: 'string',
    default: 'x'
  },
  formatField: {
    type: 'string',
    title: 'Format Field',
    watch: {
      ds: 'datafields'
    },
    enumSource: 'ds'
  },
  getExtremesFromAll: {
    type: 'boolean',
    required: true,
    default: false
  },
  id: {
    type: 'string',
    default: ''
  },
  index: {
    type: 'NullableNumber',
    default: ''
  },
  legendIndex: {
    type: 'NullableNumber',
    default: ''
  },
  linkedTo: {
    type: 'string',
    default: ''
  },
  name: {
    type: 'string',
    default: '',
    options: {
      basic: true
    },
    propertyOrder: 1,
    description: 'Apply templates using {columnName}'
  },

  negativeColor: {
    type: 'string',
    format: 'NullColor'
  },
  pointInterval: {
    type: 'number',
    default: 1
  },
  pointStart: {
    type: 'number',
    default: 0
  },
  selected: {
    type: 'boolean',
    required: true,
    default: false
  },
  shadow: {
    type: 'boolean',
    required: true,
    default: false
  },
  showCheckbox: {
    type: 'boolean',
    required: true,
    default: false
  },
  showInLegend: {
    type: 'boolean',
    required: true,
    default: true,
    options: {
      basic: true
    },
    propertyOrder: 23
  },
  showOnlyInTooltip: {
    type: 'boolean',
    required: true,
    default: false,
    options: {
      basic: true
    },
    propertyOrder: 24
  },
  showOnlyInChart: {
    type: 'boolean',
    required: true,
    default: false,
    options: {
      basic: true
    },
    description: 'Not shows on tooltip',
    propertyOrder: 25
  },
  tooltip: {
    title: 'Tooltip',
    type: 'object',
    options: {
      collapsed: true
    },
    propertyOrder: 1002,
    additionalProperties: false,
    properties: {
      followPointer: {
        type: 'boolean',
        required: true,
        default: false
      },
      followTouchMove: {
        type: 'NullableBoolean',
        default: null
      },
      footerFormat: {
        type: 'string',
        default: ''
      },
      headerFormat: {
        type: 'string',
        default: ''
      },
      hideDelay: {
        type: 'number',
        default: 500
      },
      padding: {
        type: 'number',
        default: 8
      },
      pointFormat: {
        type: 'string',
        format: 'Code',
        default:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
      },
      split: {
        type: 'boolean',
        required: true,
        default: false
      },
      valueDecimals: {
        type: 'NullableNumber',
        default: ''
      },
      valuePrefix: {
        type: 'string',
        default: ''
      },
      valueSuffix: {
        type: 'string',
        default: ''
      },
      xDateFormat: {
        type: 'string',
        default: ''
      }
    }
  },
  turboThreshold: {
    type: 'number',
    default: 1000
  },
  visible: {
    type: 'boolean',
    required: true,
    default: true,
    options: {
      basic: true
    },
    propertyOrder: 12
  },
  xAxis: {
    type: 'number',
    default: 0,
    options: {
      basic: true
    },
    propertyOrder: 11
  },
  yAxis: {
    type: 'number',
    default: 0,
    options: {
      basic: true
    },
    propertyOrder: 10
  },
  zIndex: {
    type: 'NullableNumber',
    default: ''
  },
  zoneAxis: {
    type: 'string',
    enum: ['x', 'y'],
    default: 'y',
    required: true
  },
  zones: {
    type: 'array',
    title: 'Zones',
    propertyOrder: 1003,
    options: {
      collapsed: true
    },
    items: {
      type: 'object',
      title: 'Zone',
      properties: {
        className: {
          type: 'string',
          default: ''
        },
        color: {
          type: 'string',
          format: 'NullColor'
        },
        dashStyle: {
          type: 'string',
          enum: [
            'Solid',
            'ShortDash',
            'ShortDot',
            'ShortDashDot',
            'ShortDashDotDot',
            'Dot',
            'Dash',
            'LongDash',
            'DashDot',
            'LongDashDot',
            'LongDashDotDot'
          ],
          default: 'Solid',
          required: true
        },
        fillColor: {
          type: 'string',
          format: 'NullColor'
        },
        value: {
          type: 'NullableNumber',
          default: ''
        }
      }
    }
  }
}

const areaSeries = {
  title: 'Area',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['area'],
      options: {
        hidden: true
      }
    },
    connectEnds: {
      type: 'boolean',
      required: true,
      default: true
    },
    connectNulls: {
      type: 'boolean',
      required: true,
      default: false
    },
    cropThreshold: {
      type: 'number',
      default: 300
    },
    dashStyle: {
      type: 'string',
      enum: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
      ],
      default: 'Solid',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 5
    },
    colorField: {
      type: 'string',
      title: 'Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    fillColor: {
      type: 'string',
      format: 'NullColor',
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    fillOpacity: {
      type: 'number',
      default: 0.75,
      propertyOrder: 1
    },
    lineColor: {
      type: 'string',
      format: 'NullColor',
      options: {
        basic: true
      },
      propertyOrder: 4
    },
    lineWidth: {
      type: 'number',
      default: 2
    },
    negativeFillColor: {
      type: 'string',
      format: 'NullColor'
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: false
    },
    stack: {
      type: 'string',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 14
    },
    stacking: {
      type: 'string',
      enum: ['', 'normal', 'percent'],
      default: '',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 13
    },
    step: {
      type: 'string',
      enum: ['', 'false', 'left', 'center ', 'right'],
      default: '',
      required: true
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: true
    },
    threshold: {
      type: 'number',
      default: 0
    },
    trackByArea: {
      type: 'boolean',
      required: true,
      default: false
    }
  }
}

const lineSeries = {
  title: 'Line',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['line'],
      options: {
        hidden: true
      }
    },
    connectEnds: {
      type: 'boolean',
      required: true,
      default: true
    },
    connectNulls: {
      type: 'boolean',
      required: true,
      default: false
    },
    cropThreshold: {
      type: 'number',
      default: 300
    },
    dashStyle: {
      type: 'string',
      enum: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
      ],
      default: 'Solid',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    colorField: {
      type: 'string',
      title: 'Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    lineWidth: {
      type: 'number',
      default: 2,
      options: {
        basic: true
      },
      propertyOrder: 4
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: false
    },
    stack: {
      type: 'string',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 14
    },
    stacking: {
      type: 'string',
      enum: ['', 'normal', 'percent'],
      default: '',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 13
    },
    step: {
      type: 'string',
      enum: ['', 'false', 'left', 'center ', 'right'],
      default: '',
      required: true
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: true
    },
    threshold: {
      type: 'number',
      default: 0
    }
  }
}

const errorBarSeries = {
  title: 'Error Bar',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['errorbar'],
      options: {
        hidden: true
      }
    },
    boostThreshold: {
      type: 'number',
      default: 5000
    },
    colorByPoint: {
      type: 'boolean',
      required: true,
      default: false
    },
    connectEnds: {
      type: 'NullableBoolean',
      default: null
    },
    connectNulls: {
      type: 'boolean',
      required: true,
      default: false
    },
    cropThreshold: {
      type: 'number',
      default: 50
    },
    dashStyle: {
      type: 'string',
      enum: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
      ],
      default: 'Solid',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    colorField: {
      type: 'string',
      title: 'Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    lineWidth: {
      type: 'number',
      default: 1,
      options: {
        basic: true
      },
      propertyOrder: 4
    },
    maxField: {
      type: 'string',
      title: 'Max Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 2,
      description: 'Assign "Min Field" using the Data Field'
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: false
    },
    itemColor: {
      type: 'string',
      format: 'NullColor',
      options: {
        basic: true
      },
      propertyOrder: 2
    },
    itemDashStyle: {
      type: 'string',
      enum: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
      ],
      default: 'Solid',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 2
    },
    itemWidth: {
      type: 'NullableNumber',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 2
    },
    step: {
      type: 'string',
      enum: ['', 'false', 'left', 'center ', 'right'],
      default: '',
      required: true
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: false
    },
    threshold: {
      type: 'number',
      default: 0
    },
    whiskerColor: {
      type: 'string',
      format: 'NullColor',
      options: {
        basic: true
      },
      propertyOrder: 2
    },
    whiskerLength: {
      type: 'NullableNumber',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 2
    },
    whiskerWidth: {
      type: 'NullableNumber',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 2
    }
  }
}

const splineSeries = {
  title: 'Spline',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['spline'],
      options: {
        hidden: true
      }
    },
    connectEnds: {
      type: 'boolean',
      required: true,
      default: true
    },
    connectNulls: {
      type: 'boolean',
      required: true,
      default: false
    },
    cropThreshold: {
      type: 'number',
      default: 300
    },
    dashStyle: {
      type: 'string',
      enum: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
      ],
      default: 'Solid',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    colorField: {
      type: 'string',
      title: 'Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    lineWidth: {
      type: 'number',
      default: 2,
      options: {
        basic: true
      },
      propertyOrder: 4
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: false
    },
    stack: {
      type: 'string',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 14
    },
    stacking: {
      type: 'string',
      enum: ['', 'normal', 'percent'],
      default: '',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 13
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: true
    },
    threshold: {
      type: 'number',
      default: 0
    }
  }
}

const barSeries = {
  title: 'Bar',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['bar'],
      options: {
        hidden: true
      }
    },
    borderColor: {
      type: 'string',
      format: 'Color',
      default: '#ffffff',
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    borderRadius: {
      type: 'number',
      default: 0
    },
    borderWidth: {
      type: 'number',
      default: 1
    },
    colorByPoint: {
      type: 'boolean',
      required: true,
      default: false
    },
    colorField: {
      type: 'string',
      title: 'Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    crisp: {
      type: 'boolean',
      required: true,
      default: true
    },
    cropThreshold: {
      type: 'number',
      default: 50
    },
    depth: {
      type: 'number',
      default: 25
    },
    edgeColor: {
      type: 'string',
      format: 'NullColor'
    },
    edgeWidth: {
      type: 'number',
      default: 1
    },
    groupPadding: {
      type: 'number',
      default: 0.2
    },
    groupZPadding: {
      type: 'number',
      default: 1
    },
    grouping: {
      type: 'boolean',
      required: true,
      default: true
    },
    maxPointWidth: {
      type: 'NullableNumber',
      default: ''
    },
    minPointLength: {
      type: 'number',
      default: 0
    },
    pointPadding: {
      type: 'number',
      default: 0.1
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    pointRange: {
      type: 'NullableNumber',
      default: ''
    },
    pointWidth: {
      type: 'NullableNumber',
      default: ''
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: true
    },
    stack: {
      type: 'string',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 14
    },
    stacking: {
      type: 'string',
      enum: ['', 'normal', 'percent'],
      default: '',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 13
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: true
    },
    threshold: {
      type: 'number',
      default: 0
    }
  }
}

const bubbleSeries = {
  title: 'Bubble',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['bubble'],
      options: {
        hidden: true
      }
    },
    connectEnds: {
      type: 'boolean',
      required: true,
      default: true
    },
    connectNulls: {
      type: 'boolean',
      required: true,
      default: false
    },
    cropThreshold: {
      type: 'number',
      default: 300
    },
    dashStyle: {
      type: 'string',
      enum: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
      ],
      default: 'Solid',
      required: true
    },
    colorField: {
      type: 'string',
      title: 'Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    displayNegative: {
      type: 'boolean',
      required: true,
      default: true
    },
    jitter: {
      type: 'object',
      properties: {
        x: {
          type: 'number',
          default: 0
        },
        y: {
          type: 'number',
          default: 0
        }
      }
    },
    lineWidth: {
      type: 'number',
      default: 0,
      options: {
        basic: true
      },
      propertyOrder: 6
    },
    marker: {
      type: 'object',
      title: 'Marker',
      options: {
        collapsed: true
      },
      properties: {
        fillColor: {
          type: 'string',
          format: 'NullColor'
        },
        fillOpacity: {
          type: 'number',
          default: 0.5
        },
        lineColor: {
          type: 'string',
          format: 'NullColor',
          default: null
        },
        lineWidth: {
          type: 'number',
          default: 1
        },
        symbol: {
          type: 'string',
          enum: ['', 'circle', 'square', 'diamond', 'triangle', 'triangle-down'],
          default: 'circle',
          required: true
        }
      }
    },
    maxSize: {
      type: 'string',
      default: '20%',
      required: false,
      options: {
        basic: true
      },
      propertyOrder: 4
    },
    minSize: {
      type: 'string',
      default: '8',
      required: false,
      options: {
        basic: true
      },
      propertyOrder: 5
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    sizeBy: {
      type: 'string',
      enum: ['area', 'width', 'height'],
      default: 'area',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 7
    },
    sizeByAbsoluteValue: {
      type: 'boolean',
      required: true,
      default: false,
      options: {
        basic: true
      },
      propertyOrder: 8
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: false
    },
    stacking: {
      type: 'string',
      enum: ['', 'normal', 'percent'],
      default: '',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 13
    },
    step: {
      type: 'string',
      enum: ['', 'false', 'left', 'center ', 'right'],
      default: '',
      required: true
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: false
    },
    threshold: {
      type: 'number',
      default: 0
    },
    zDataField: {
      type: 'string',
      title: 'z Data Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    zMax: {
      type: 'number',
      default: null
    },
    zMin: {
      type: 'number',
      default: null
    },
    zThreshold: {
      type: 'number',
      default: 0
    }
  }
}

const columnSeries = {
  title: 'Column',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['column'],
      options: {
        hidden: true
      }
    },
    borderColor: {
      type: 'string',
      format: 'Color',
      default: '#ffffff',
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    borderRadius: {
      type: 'number',
      default: 0
    },
    borderWidth: {
      type: 'number',
      default: 1
    },
    colorByPoint: {
      type: 'boolean',
      required: true,
      default: false
    },
    colorField: {
      type: 'string',
      title: 'Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    crisp: {
      type: 'boolean',
      required: true,
      default: true
    },
    cropThreshold: {
      type: 'number',
      default: 50
    },
    depth: {
      type: 'number',
      default: 25
    },
    edgeColor: {
      type: 'string',
      format: 'NullColor'
    },
    edgeWidth: {
      type: 'number',
      default: 1
    },
    groupPadding: {
      type: 'number',
      default: 0.2
    },
    groupZPadding: {
      type: 'number',
      default: 1
    },
    grouping: {
      type: 'boolean',
      required: true,
      default: true
    },
    maxPointWidth: {
      type: 'NullableNumber',
      default: ''
    },
    minPointLength: {
      type: 'number',
      default: 0
    },
    pointPadding: {
      type: 'number',
      default: 0.1
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    pointRange: {
      type: 'NullableNumber',
      default: ''
    },
    pointWidth: {
      type: 'NullableNumber',
      default: ''
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: true
    },
    stack: {
      type: 'string',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 14
    },
    stacking: {
      type: 'string',
      enum: ['', 'normal', 'percent'],
      default: '',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 13
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: true
    },
    threshold: {
      type: 'number',
      default: 0
    }
  }
}

const scatterSeries = {
  title: 'Scatter',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['scatter'],
      options: {
        hidden: true
      }
    },
    cropThreshold: {
      type: 'number',
      default: 300
    },
    dashStyle: {
      type: 'string',
      enum: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
      ],
      default: 'Solid',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    lineWidth: {
      type: 'number',
      default: 0,
      options: {
        basic: true
      },
      propertyOrder: 4
    },
    pointColorField: {
      type: 'string',
      title: 'Point Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 5
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: false
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: false
    },
    threshold: {
      type: 'number',
      default: 0
    }
  }
}

const pieSeries = {
  title: 'Pie',
  type: 'object',
  required: ['type'],
  options: {
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['pie'],
      options: {
        hidden: true
      }
    },
    categoryField: {
      type: 'string',
      title: 'Category Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 1
    },
    allowPointSelect: {
      type: 'boolean',
      required: true,
      default: false,
      options: {
        basic: true
      },
      propertyOrder: 4
    },
    animation: {
      type: 'boolean',
      required: true,
      default: true,
      options: {
        basic: true
      },
      propertyOrder: 6
    },

    monochrome: {
      type: 'object',
      title: 'Monochrome',
      default: { brightenParameter: 1 },
      properties: {
        monochromeEnabled: {
          title: 'Monochrome',
          type: 'boolean',
          default: false
        },
        brightenParameter: {
          title: 'Brighten Parameter',
          type: 'number',
          description: 'Enter number'
        }
      }
    },
    color: {
      type: 'string',
      format: 'NullColor',
      description: 'Monochrome color'
    },

    pattern: {
      type: 'object',
      title: 'Pattern Fill Column',

      properties: {
        patternEnabled: {
          title: 'Enable Pattern',
          type: 'boolean',
          default: false,
          description: 'Overwrites monochrome'
        },
        patternField: {
          type: 'string',
          title: 'Color Field',
          description: 'Numeric column between 0 to 9',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        }
      }
    },

    animationLimit: {
      type: 'NullableNumber',
      default: ''
    },

    borderColor: {
      type: 'string',
      format: 'Color',
      default: '#ffffff'
    },
    borderWidth: {
      type: 'number',
      default: 1
    },
    className: {
      type: 'string',
      default: ''
    },
    colorIndex: {
      type: 'NullableNumber',
      default: ''
    },
    cursor: {
      type: 'string',
      default: ''
    },
    dataLabels: {
      type: 'object',
      title: 'Data Labels',
      options: {
        collapsed: true
      },
      additionalProperties: false,
      propertyOrder: 1001,
      properties: {
        backgroundColor: {
          type: 'string',
          format: 'NullColor'
        },
        borderColor: {
          type: 'string',
          format: 'NullColor'
        },
        borderRadius: {
          type: 'number',
          default: 0
        },
        borderWidth: {
          type: 'number',
          default: 0
        },
        className: {
          type: 'string',
          default: ''
        },
        color: {
          type: 'string',
          format: 'NullColor'
        },
        connectorColor: {
          type: 'string',
          format: 'NullColor'
        },
        connectorPadding: {
          type: 'number',
          default: 5
        },
        connectorWidth: {
          type: 'number',
          default: 1
        },
        crop: {
          type: 'boolean',
          required: true,
          default: true
        },
        defer: {
          type: 'boolean',
          required: true,
          default: true
        },
        distance: {
          type: 'number',
          default: 30
        },
        enabled: {
          type: 'boolean',
          required: true,
          default: false
        },
        format: {
          type: 'string',
          default: '{y}'
        },
        inside: {
          type: 'NullableBoolean',
          default: null
        },
        overflow: {
          type: 'string',
          enum: ['justify', 'none'],
          default: 'justify',
          required: true
        },
        padding: {
          type: 'number',
          default: 5
        },
        rotation: {
          type: 'number',
          default: 0
        },
        shadow: {
          type: 'boolean',
          required: true,
          default: false
        },
        shape: {
          type: 'string',
          enum: ['square', 'callout', 'circle'],
          default: 'square',
          required: true
        },
        softConnector: {
          type: 'boolean',
          required: true,
          default: false
        },
        style: {
          type: 'object',
          format: 'Style',
          default: { fontSize: '11px', fontWeight: 'bold' }
        },
        useHTML: {
          type: 'boolean',
          required: true,
          default: false
        },
        verticalAlign: {
          type: 'string',
          enum: ['', 'top', 'middle', 'bottom'],
          default: '',
          required: true
        },
        x: {
          type: 'number',
          default: 0
        },
        y: {
          type: 'number',
          default: -6
        },
        zIndex: {
          type: 'number',
          default: 6
        }
      }
    },
    depth: {
      type: 'number',
      default: 0
    },
    description: {
      type: 'string',
      default: ''
    },
    enableMouseTracking: {
      type: 'boolean',
      required: true,
      default: true,
      options: {
        basic: true
      },
      propertyOrder: 5
    },
    endAngle: {
      type: 'NullableNumber',
      default: ''
    },
    findNearestPointBy: {
      type: 'string',
      default: 'x'
    },
    formatField: {
      type: 'string',
      title: 'Format Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds'
    },
    getExtremesFromAll: {
      type: 'boolean',
      required: true,
      default: false
    },
    id: {
      type: 'string',
      default: ''
    },
    ignoreHiddenPoint: {
      type: 'boolean',
      required: true,
      default: true
    },
    index: {
      type: 'NullableNumber',
      default: ''
    },
    innerSize: {
      type: 'string',
      default: '',
      description: 'In percentage or pixels'
    },
    legendIndex: {
      type: 'NullableNumber',
      default: ''
    },
    linkedTo: {
      type: 'string',
      default: ''
    },
    minSize: {
      type: 'number',
      default: 80
    },
    name: {
      type: 'string',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 2,
      description: 'Apply templates using {columnName}'
    },
    selected: {
      type: 'boolean',
      required: true,
      default: false
    },
    shadow: {
      type: 'boolean',
      required: true,
      default: false
    },
    showInLegend: {
      type: 'boolean',
      required: true,
      default: true,
      options: {
        basic: true
      },
      propertyOrder: 7
    },
    size: {
      type: 'string',
      default: '',
      description: 'In percentage or pixels'
    },
    slicedOffset: {
      type: 'number',
      default: 10
    },
    startAngle: {
      type: 'number',
      default: 0
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: false
    },
    tooltip: {
      title: 'Tooltip',
      type: 'object',
      options: {
        collapsed: true
      },
      propertyOrder: 1002,
      additionalProperties: false,
      properties: {
        followPointer: {
          type: 'boolean',
          required: true,
          default: false
        },
        followTouchMove: {
          type: 'NullableBoolean',
          default: null
        },
        footerFormat: {
          type: 'string',
          default: ''
        },
        headerFormat: {
          type: 'string',
          default: ''
        },
        hideDelay: {
          type: 'number',
          default: 500
        },
        padding: {
          type: 'number',
          default: 8
        },
        pointFormat: {
          type: 'string',
          format: 'Code',
          default:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
        },
        split: {
          type: 'boolean',
          required: true,
          default: false
        },
        valueDecimals: {
          type: 'NullableNumber',
          default: ''
        },
        valuePrefix: {
          type: 'string',
          default: ''
        },
        valueSuffix: {
          type: 'string',
          default: ''
        },
        xDateFormat: {
          type: 'string',
          default: ''
        }
      }
    },
    visible: {
      type: 'boolean',
      required: true,
      default: true,
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    zIndex: {
      type: 'NullableNumber',
      default: ''
    },
    zoneAxis: {
      type: 'string',
      enum: ['x', 'y'],
      default: 'y',
      required: true
    },
    zones: {
      type: 'array',
      title: 'Zones',
      options: {
        collapsed: true
      },
      propertyOrder: 1003,
      items: {
        type: 'object',
        title: 'Zone',
        properties: {
          className: {
            type: 'string',
            default: ''
          },
          color: {
            type: 'string',
            format: 'NullColor'
          },
          dashStyle: {
            type: 'string',
            enum: [
              'Solid',
              'ShortDash',
              'ShortDot',
              'ShortDashDot',
              'ShortDashDotDot',
              'Dot',
              'Dash',
              'LongDash',
              'DashDot',
              'LongDashDot',
              'LongDashDotDot'
            ],
            default: 'Solid',
            required: true
          },
          fillColor: {
            type: 'string',
            format: 'NullColor'
          },
          value: {
            type: 'NullableNumber',
            default: ''
          }
        }
      }
    }
  }
}

const waterfallSeries = {
  title: 'Waterfall',
  type: 'object',
  required: ['type'],
  options: {
    sortProperties: 'key',
    splitProperties: true
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['waterfall'],
      options: {
        hidden: true
      }
    },
    borderColor: {
      type: 'string',
      format: 'Color',
      default: '#ffffff',
      options: {
        basic: true
      },
      propertyOrder: 3
    },
    borderRadius: {
      type: 'number',
      default: 0
    },
    borderWidth: {
      type: 'number',
      default: 1
    },
    colorByPoint: {
      type: 'boolean',
      required: true,
      default: false
    },
    crisp: {
      type: 'boolean',
      required: true,
      default: true
    },
    cropThreshold: {
      type: 'number',
      default: 50
    },
    depth: {
      type: 'number',
      default: 25
    },
    edgeColor: {
      type: 'string',
      format: 'NullColor'
    },
    edgeWidth: {
      type: 'number',
      default: 1
    },
    groupPadding: {
      type: 'number',
      default: 0.2
    },
    groupZPadding: {
      type: 'number',
      default: 1
    },
    grouping: {
      type: 'boolean',
      required: true,
      default: true
    },
    intermediateSumField: {
      type: 'string',
      title: 'Is Intermediate Sum Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 17
    },
    intermediateSumColor: {
      type: 'string',
      title: 'Intermediate Sum Color',
      format: 'Color',
      default: '#434348',
      options: {
        basic: true
      },
      propertyOrder: 19
    },
    maxPointWidth: {
      type: 'NullableNumber',
      default: ''
    },
    minField: {
      type: 'string',
      title: 'Min Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 20
    },
    minPointLength: {
      type: 'number',
      default: 0
    },
    pointColorField: {
      type: 'string',
      title: 'Point Color Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 20
    },
    pointPadding: {
      type: 'number',
      default: 0.1
    },
    pointPlacement: {
      type: 'string',
      enum: ['', 'on', 'between'],
      default: '',
      required: true
    },
    pointRange: {
      type: 'NullableNumber',
      default: ''
    },
    pointWidth: {
      type: 'NullableNumber',
      default: ''
    },
    softThreshold: {
      type: 'boolean',
      required: true,
      default: true
    },
    stack: {
      type: 'string',
      default: '',
      options: {
        basic: true
      },
      propertyOrder: 14
    },
    stacking: {
      type: 'string',
      enum: ['', 'normal', 'percent'],
      default: '',
      required: true,
      options: {
        basic: true
      },
      propertyOrder: 13
    },
    stickyTracking: {
      type: 'boolean',
      required: true,
      default: true
    },
    sumField: {
      type: 'string',
      title: 'Is Sum Field',
      watch: {
        ds: 'datafields'
      },
      enumSource: 'ds',
      options: {
        basic: true
      },
      propertyOrder: 16
    },
    sumColor: {
      type: 'string',
      title: 'Sum Color',
      format: 'Color',
      default: '#434348',
      options: {
        basic: true
      },
      propertyOrder: 18
    },
    threshold: {
      type: 'number',
      default: 0
    },
    upColor: {
      type: 'string',
      format: 'NullColor',
      options: {
        basic: true
      },
      propertyOrder: 15
    }
  }
}

const removeFromPlotOptionsNormalSeries = [
  'type',
  'id',
  'index',
  'legendIndex',
  'name',
  'stack',
  'xAxis',
  'yAxis',
  'zIndex'
]

const removeFromPlotOptionsBubbleSeries = [
  'type',
  'id',
  'index',
  'stack',
  'xAxis',
  'yAxis',
  'tooltip'
]

const removeFromPlotOptionsPieSeries = ['type', 'id', 'index', 'legendIndex', 'zIndex']

const markerSchema = {
  marker: {
    type: 'object',
    title: 'Marker',
    options: {
      collapsed: true
    },
    propertyOrder: 1001,
    properties: {
      enabled: {
        type: 'NullableBoolean',
        default: null
      },
      fillColor: {
        type: 'string',
        format: 'NullColor'
      },
      lineColor: {
        type: 'string',
        format: 'NullColor',
        default: '#ffffff'
      },
      lineWidth: {
        type: 'number',
        default: 0
      },
      radius: {
        type: 'number',
        default: 4
      },
      symbol: {
        type: 'string',
        enum: ['', 'circle', 'square', 'diamond', 'triangle', 'triangle-down'],
        default: '',
        required: true
      }
    }
  }
}

function createPlotOptionsForSeries(seriesSchema, seriesSpecificProperties) {
  const plotOptionsProps = _.cloneDeep(seriesSchema.properties)
  _.each(seriesSpecificProperties, (value) => {
    delete plotOptionsProps[value]
  })
  return plotOptionsProps
}

export default () => {
  if (_.isEmpty(totalSchema)) {
    _.assign(areaSeries.properties, _.cloneDeep(commonSeries))
    _.assign(lineSeries.properties, _.cloneDeep(commonSeries))
    _.assign(columnSeries.properties, _.cloneDeep(commonSeries))
    _.assign(barSeries.properties, _.cloneDeep(commonSeries))
    _.assign(bubbleSeries.properties, _.cloneDeep(commonSeries))
    _.assign(splineSeries.properties, _.cloneDeep(commonSeries))
    _.assign(scatterSeries.properties, _.cloneDeep(commonSeries))
    _.assign(errorBarSeries.properties, _.cloneDeep(commonSeries))
    _.assign(waterfallSeries.properties, _.cloneDeep(commonSeries))

    barSeries.properties.dataLabels.properties.align.default = 'left'
    bubbleSeries.properties.dataLabels.properties.inside.default = true
    bubbleSeries.properties.dataLabels.properties.verticalAlign.default = 'middle'
    bubbleSeries.properties.animationLimit.default = 250
    bubbleSeries.properties.zoneAxis.enum = ['x', 'y', 'z']
    bubbleSeries.properties.zoneAxis.default = 'z'
    bubbleSeries.properties.findNearestPointBy.default = 'xy'
    errorBarSeries.properties.linkedTo.default = 'previous'
    scatterSeries.properties.tooltip.properties.pointFormat.default =
      'x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>'
    errorBarSeries.properties.tooltip.properties.pointFormat.default =
      '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b><br/>'

    _.assign(areaSeries.properties, _.cloneDeep(markerSchema))
    _.assign(lineSeries.properties, _.cloneDeep(markerSchema))
    _.assign(splineSeries.properties, _.cloneDeep(markerSchema))
    _.assign(scatterSeries.properties, _.cloneDeep(markerSchema))

    _.assign(xAxisSchema.xAxis.items.properties, _.cloneDeep(axisCommon))
    _.assign(yAxisSchema.yAxis.items.properties, _.cloneDeep(axisCommon))
    _.assign(zAxisSchema.zAxis.items.properties, _.cloneDeep(axisCommon))

    seriesSchema.series.items.properties.seriesProps.oneOf.push(lineSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(areaSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(columnSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(barSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(bubbleSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(splineSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(pieSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(scatterSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(errorBarSeries)
    seriesSchema.series.items.properties.seriesProps.oneOf.push(waterfallSeries)

    let plotOptions = createPlotOptionsForSeries(areaSeries, removeFromPlotOptionsNormalSeries)

    _.assign(plotOptionsSchema.plotOptions.properties.area.properties, plotOptions)

    plotOptions = createPlotOptionsForSeries(barSeries, removeFromPlotOptionsNormalSeries)

    _.assign(plotOptionsSchema.plotOptions.properties.bar.properties, plotOptions)

    plotOptions = createPlotOptionsForSeries(bubbleSeries, removeFromPlotOptionsBubbleSeries)

    _.assign(plotOptionsSchema.plotOptions.properties.bubble.properties, plotOptions)

    plotOptions = createPlotOptionsForSeries(columnSeries, removeFromPlotOptionsNormalSeries)

    _.assign(plotOptionsSchema.plotOptions.properties.column.properties, plotOptions)

    plotOptions = createPlotOptionsForSeries(lineSeries, removeFromPlotOptionsNormalSeries)

    _.assign(plotOptionsSchema.plotOptions.properties.line.properties, plotOptions)

    plotOptions = createPlotOptionsForSeries(splineSeries, removeFromPlotOptionsNormalSeries)

    _.assign(plotOptionsSchema.plotOptions.properties.spline.properties, plotOptions)

    plotOptions = createPlotOptionsForSeries(pieSeries, removeFromPlotOptionsPieSeries)

    _.assign(plotOptionsSchema.plotOptions.properties.pie.properties, plotOptions)

    _.assign(pluginSchema.properties, generalSchema)
    _.assign(pluginSchema.properties, chartSchema)
    _.assign(pluginSchema.properties, titleSchema)
    _.assign(pluginSchema.properties, subtitleSchema)
    _.assign(pluginSchema.properties, xFieldSchema)
    _.assign(pluginSchema.properties, seriesSchema)
    _.assign(pluginSchema.properties, legendSchema)
    _.assign(pluginSchema.properties, plotOptionsSchema)
    _.assign(pluginSchema.properties, tooltipSchema)
    _.assign(pluginSchema.properties, exportingSchema)
    _.assign(pluginSchema.properties, xAxisSchema)
    _.assign(pluginSchema.properties, yAxisSchema)
    _.assign(pluginSchema.properties, zAxisSchema)

    totalSchema = pluginSchema
  }

  return totalSchema
}
