const style = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: '#f1f1f1'
}

const RootErr = ({ msg = '' }) => {
  return (
    <div style={style}>
      {msg}
      <button onClick={() => global.location.reload()}>Reload</button>
    </div>
  )
}

export default RootErr
