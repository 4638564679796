import { getDeleteIconClassNames } from '../column'
import { cellRendererParams, customConfigs } from '../../__tests__/mocks'
import { GetDeleteIconClassNamesProps } from '../column.types'
import { fieldConfig } from '../../filter/__tests__/mocks'

const getDeleteIconClassNamesParams = (): GetDeleteIconClassNamesProps => ({
  customInfo: {
    ...customConfigs,
    columnId: '1',
    columnType: 'simple',
    field: 'col1',
    fieldConfig,
    fieldLookupConfig: {
      lookupQueryList: [],
      isLookupQuery: false,
      substituteField: null
    },
    deletableCondition: ''
  },
  data: cellRendererParams.data,
  deleteIcon: 'fa-minus-circle'
})

describe('AgGrid:Helpers:Column:getDeleteIconClassNames', () => {
  let params = getDeleteIconClassNamesParams()

  beforeEach(() => {
    params = getDeleteIconClassNamesParams()
  })

  it('should return "fa fa-minus-circle text-muted" when deletableCondition and data are truthy but deletableCondition does not exist in data', () => {
    params.customInfo.deletableCondition = 'col8'
    const classNames = getDeleteIconClassNames(params)
    expect(classNames).toEqual('fa fa-minus-circle text-muted')
  })

  it('should return "fa fa-minus-circle text-danger cp" when deletableCondition and data are truthy and deletableCondition exists in data', () => {
    params.customInfo.deletableCondition = 'col1'
    const classNames = getDeleteIconClassNames(params)
    expect(classNames).toEqual('fa fa-minus-circle text-danger cp')
  })

  it('should return "fa fa-minus-circle text-danger cp" when data is not present in params', () => {
    const { data, ...rest } = params
    const newParams = rest
    const classNames = getDeleteIconClassNames(newParams)
    expect(classNames).toEqual('fa fa-minus-circle text-danger cp')
  })
})
