import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import * as d3 from 'd3'
import moment from 'moment'
import numeral from 'numeral'
import request from 'superagent'
import { Button as BootstrapButton, Modal, Table } from 'react-bootstrap'
import { setTimeout, clearTimeout } from 'timers'
import { slvyToast } from '@/components'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { statuses } from './statuses'
import './JobSchedulerStatus.scss'
import { API_URL } from '@/constants'

const portalTarget = document.body

class JobSchedulerStatus extends Component {
  constructor(props) {
    super(props)
    const enableRunButton = _.get(this.props, 'settings.config.fields.enableRunButton', true)

    this.refreshCycle = null

    this.state = {
      settings: { ...props },
      pending: true,
      pendingJobHistory: true,
      isSuccess: false,
      isSuccessJobHistory: false,
      status: null,
      colorArr: ['#23c6c8', '#1c84c6', '#1ab394'],
      showSteps: false,
      showJobHistory: false,
      enableSteps: false,
      statusJobHistory: [],
      isCurrentJobStarted: false /* It will be true when a job started, until it ends. */,
      disabled: !enableRunButton,
      jobvariableid: null,
      jobvariablevalue: null
    }

    this.getData = this.getData.bind(this)
    this.setEnabled = this.setEnabled.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
    this.startInterval = this.startInterval.bind(this)
    this.stopInterval = this.stopInterval.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.refreshStatus = this.refreshStatus.bind(this)
    this.setButtonState = this.setButtonState.bind(this)
    this.setRunButtonParameters = this.setRunButtonParameters.bind(this)
    this.getRequestHeader = this.getRequestHeader.bind(this)
    this.currentJobStarted = this.currentJobStarted.bind(this)
    this.currentJobFinished = this.currentJobFinished.bind(this)
    this.startButtonClicked = this.startButtonClicked.bind(this)
    this.createDurationChart = this.createDurationChart.bind(this)
    this.onShowModalJobHistory = this.onShowModalJobHistory.bind(this)
    this.playButtonClickHandle = this.playButtonClickHandle.bind(this)
    this.onShowModalFailedSteps = this.onShowModalFailedSteps.bind(this)
    this.cancelButtonClickHandle = this.cancelButtonClickHandle.bind(this)
  }

  componentDidMount() {
    const { registerMethod, registerEvent, registerAuthorizations } = this.props

    this.getData()
    registerMethod({
      key: 'setRunButtonState',
      fn: this.setButtonState,
      args: [{ name: 'disabled', type: PluginTypes.boolean }]
    })
    registerMethod({
      key: 'setRunButtonParameters',
      fn: this.setRunButtonParameters,
      args: [
        { name: 'jobvariableid', type: PluginTypes.int },
        { name: 'jobvariablevalue', type: PluginTypes.string }
      ]
    })
    registerMethod({
      key: 'setRunButtonEnabled',
      fn: this.setEnabled,
      args: []
    })
    registerMethod({
      key: 'setRunButtonDisabled',
      fn: this.setDisabled,
      args: []
    })
    registerMethod({
      key: 'startInterval',
      fn: this.startInterval,
      args: []
    })
    registerMethod({
      key: 'stopInterval',
      fn: this.stopInterval,
      args: []
    })
    registerMethod({
      key: 'refresh',
      fn: this.refreshData,
      args: []
    })
    registerMethod({
      key: 'startJob',
      fn: this.playButtonClickHandle,
      args: []
    })
    registerMethod({
      key: 'cancelJob',
      fn: this.cancelButtonClickHandle,
      args: []
    })

    this.currentJobStarted = registerEvent({
      key: 'currentJobStarted',
      fn: this.currentJobStarted,
      returnTypes: { refreshKey: PluginTypes.string }
    })
    this.currentJobFinished = registerEvent({
      key: 'currentJobFinished',
      fn: this.currentJobFinished,
      returnTypes: { refreshKey: PluginTypes.string }
    })
    this.refreshStatus = registerEvent({
      key: 'statesCheck',
      fn: this.refreshStatus,
      returnTypes: { refreshKey: PluginTypes.string }
    })
    this.startButtonClicked = registerEvent({
      key: 'startButtonClicked',
      fn: this.startButtonClicked,
      returnTypes: { refreshKey: PluginTypes.string }
    })
    registerAuthorizations(['EnabledRunButton'])
  }

  componentDidUpdate() {
    this.createDurationChart()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ settings: { ...nextProps } })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.isCurrentJobStarted && !this.state.isCurrentJobStarted) {
      this.currentJobStarted()
    }
  }

  componentWillUnmount() {
    this.stopInterval()
  }

  // TODO handle response
  requestHandler = () => {}

  getData(shouldRefresh = true) {
    const req = this.getRequestHeader()

    const {
      props: { settings: { config: { settings: { refreshingTime = 30 } = {} } = {} } = {} },
      state: { isCurrentJobStarted }
    } = this

    this.setState({ status: {}, pending: true, isSuccess: false })

    request
      .post(`${API_URL}/data/${req.id}/invoke/GetJobStatus`)
      .set(req.request)
      .then((res) => {
        this.setState({
          status: res.body,
          pending: false,
          isSuccess: res.body !== null
        })

        if (isCurrentJobStarted) {
          // if job is running
          if (this.state.status.IsCompleted && !this.state.status.IsFailed) {
            // and then job finishes currentJobFinished method should run once.
            this.setState({ isCurrentJobStarted: false })
            this.currentJobFinished()
          }
        } else if (
          !this.state.status.IsCompleted &&
          !this.state.status.IsCanceled &&
          this.state.status.IsStarted
        ) {
          // In the first time of job starts isCurrentJobStarted will be true until it finishes.
          this.setState({ isCurrentJobStarted: true })
        }

        if (shouldRefresh) {
          // refreshStatus() works in every settimeout by this way.
          this.refreshStatus()
          this.refreshCycle = setTimeout(this.getData, refreshingTime * 1000)
        }
      })
      .catch(() => {
        this.setState({ status: {}, pending: false, isSuccess: false })
        if (shouldRefresh) {
          this.refreshStatus()
          this.refreshCycle = setTimeout(this.getData, refreshingTime * 1000)
        }
      })
  }

  startInterval() {
    if (this.refreshCycle) {
      return
    }

    this.getData()
  }

  stopInterval() {
    clearTimeout(this.refreshCycle)
    this.refreshCycle = null
  }

  refreshData() {
    this.getData(false)
  }

  onResetState() {
    this.setState(this.state)
  }

  setEnabled() {
    this.setState({ disabled: false })
  }

  setDisabled() {
    this.setState({ disabled: true })
  }

  setButtonState({ disabled = true }) {
    this.setState({ disabled })
  }

  setRunButtonParameters(params) {
    const { jobvariableid, jobvariablevalue } = params
    if (!_.isNil(jobvariableid) && !_.isNil(jobvariablevalue)) {
      this.setState({ jobvariableid })
      this.setState({ jobvariablevalue })
    }
  }

  getRequestHeader() {
    const {
      id,
      params: { environment },
      token
    } = this.props

    return {
      request: {
        environment,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      id
    }
  }

  onCancelPopup() {
    this.setState({
      showSteps: false,
      showJobHistory: false
    })
  }

  onShowModalFailedSteps() {
    this.onResetState()
    this.setState({
      showSteps: true,
      showJobHistory: false
    })
  }

  // This is for opening and filling the 'Job Informations' pop-up screen.
  onShowModalJobHistory(jobInfoTaken, jobInfoSkip) {
    const req = this.getRequestHeader()

    this.onResetState()
    this.setState({
      pendingJobHistory: true,
      isSuccessJobHistory: false,
      statusJobHistory: [],
      showJobHistory: true
    })

    request
      .post(`${API_URL}/data/${req.id}/invoke/GetJobHistory`, {
        skip: jobInfoSkip,
        take: jobInfoTaken
      })
      .set(req.request)
      .then((res) => {
        this.setState({
          statusJobHistory: res.body,
          pendingJobHistory: false,
          isSuccessJobHistory: res.body !== null,
          showSteps: false
        })
      })
      .catch(() => {
        this.setState({
          statusJobHistory: [],
          pendingJobHistory: false,
          isSuccessJobHistory: false
        })
      })
  }

  // After clicked Start Job button on the right-up side of the plugin, job will be started.
  playButtonClickHandle() {
    const req = this.getRequestHeader()
    const {
      props: {
        settings: { config: { settings: { requestWithVariables = false } = {} } = {} } = {}
      },
      state: { colorArr = [] }
    } = this
    let clientUrl = `${API_URL}/data/${req.id}/invoke/StartJob`

    const { jobvariableid, jobvariablevalue } = this.state
    //StartJobWithChangeJobVariable
    if (requestWithVariables) {
      clientUrl = `${API_URL}/data/${req.id}/invoke/StartJobWithChangeJobVariable`
      let variableId = jobvariableid
      if (_.isNil(jobvariableid)) {
        variableId = 0
      }

      this.setState(
        {
          status: {},
          IsCompleted: false,
          IsCanceled: false,
          IsStarted: true
        },
        () => {
          request
            .post(clientUrl, { jobvariableid: variableId, jobvariablevalue: jobvariablevalue })
            .set(req.request)
            .then(this.requestHandler)
            .catch((error) => {
              const defaultErrMessage = { message: 'There has been an error!' }
              const { message } = JSON.parse(error?.message ?? JSON.stringify(defaultErrMessage))
              slvyToast.warning({ message, title: 'Start Job With Variable' })
            })
          this.startButtonClicked()
        }
      )
    } else {
      this.setState(
        {
          status: {},
          IsCompleted: false,
          IsCanceled: false,
          IsStarted: true
        },
        () => {
          request
            .post(clientUrl)
            .set(req.request)
            .then(this.requestHandler)
            .catch(this.requestHandler)
          this.startButtonClicked()
        }
      )
    }
  }

  // If job status is 'Running', Kill Job button will be appear instead of Start Job button.
  cancelButtonClickHandle() {
    const req = this.getRequestHeader()

    this.setState(
      {
        status: {},
        IsStarted: false,
        isRunning: false
      },
      () => {
        request
          .post(`${API_URL}/data/${req.id}/invoke/KillJob`)
          .set(req.request)
          .then(this.requestHandler)
          .catch(this.requestHandler)
      }
    )
  }

  createDurationChart() {
    const {
      props: {
        size: { height: sizeHeight = 200, width: sizeWidth },
        settings: {
          config: {
            fields: { enableRunStatus = true, enableStep = true, enableJobInfo = true } = {}
          } = {}
        } = {}
      },
      state: { colorArr = [] }
    } = this

    let status = { RunningMinutes: 0, AvgDurationInMinutes: 0, LastDurationInMinutes: 0 }

    if (!_.isEmpty(this.state.status)) {
      status = { ...status, ..._.cloneDeep(this.state.status) }
    }

    const { RunningMinutes = 0, AvgDurationInMinutes = 0, LastDurationInMinutes = 0 } = status

    const onlyDurationField = !(enableRunStatus || enableStep || enableJobInfo)

    const defaultChartSize = 170
    let calculatedHeight = parseInt((sizeHeight - defaultChartSize) / 2, 10)
    if (sizeHeight < 380) {
      calculatedHeight = parseInt((sizeHeight - defaultChartSize) / 2 + 20, 10)
    }

    const calculatedWidth = onlyDurationField
      ? sizeWidth >= 300
        ? 150
        : parseInt((sizeWidth - sizeWidth / 6) / 2, 10)
      : parseInt((sizeWidth - 100) / 8, 10)

    const { id } = this.props
    if (!_.isEmpty(this.state.status)) {
      const data = [
        { name: 'Average', value: AvgDurationInMinutes },
        { name: 'Last', value: LastDurationInMinutes },
        { name: 'Current', value: RunningMinutes }
      ]

      d3.selectAll(`#chart_${id} svg *, #tooltip_${id}`).remove()

      const height = 200

      let chartRadius = height / 2 - 10
      let arcMinRadius = 30
      let arcPadding = 5

      if (sizeHeight <= 380) {
        chartRadius = 150 / 2 - 10
        arcMinRadius = 20
        arcPadding = 4
      }

      const color = d3.scaleOrdinal(colorArr)

      const { PI } = Math

      const svg = d3
        .select(`#chart_${id} svg`)
        .append('g')
        .attr('transform', `translate(${calculatedWidth}, ${calculatedHeight})`)

      const tooltip = d3
        .select('body')
        .append('div')
        .attr('id', `tooltip_${id}`)
        .attr('class', 'duration-tooltip')

      const scale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.value) * 1.1])
        .range([0, 2 * PI])

      const keys = data.map((d) => d.name)

      const numArcs = keys.length

      const arcWidth = (chartRadius - arcMinRadius - numArcs * arcPadding) / numArcs
      const arc = d3
        .arc()
        .innerRadius((d, i) => getInnerRadius(i))
        .outerRadius((d, i) => getOuterRadius(i))
        .startAngle(0)
        .endAngle((d) => scale(d))

      const radialAxis = svg
        .append('g')
        .attr('class', 'r axis')
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')

      radialAxis.append('circle').attr('r', (d, i) => getOuterRadius(i) + arcPadding)

      const arcs = svg
        .append('g')
        .attr('class', 'data')
        .selectAll('path')
        .data(data)
        .enter()
        .append('path')
        .attr('class', 'arc')
        .style('fill', (d, i) => color(i))
      arcs
        .transition()
        .delay((d, i) => i * 200)
        .duration(1000)
        .attrTween('d', arcTween)

      arcs.on('mousemove', showTooltip)
      arcs.on('mouseout', hideTooltip)

      function arcTween(d, i) {
        const interpolate = d3.interpolate(0, d.value)

        return (t) => arc(interpolate(t), i)
      }

      function showTooltip(d) {
        tooltip
          .style('left', `${d3.event.screenX + 10}px`)
          .style('top', `${d3.event.screenY - 120}px`)
          .style('display', 'inline-block')
          .html(`${d.name}: ${d.value}`)
      }

      function hideTooltip() {
        tooltip.style('display', 'none')
      }

      function getInnerRadius(index) {
        return arcMinRadius + (numArcs - (index + 1)) * (arcWidth + arcPadding)
      }

      function getOuterRadius(index) {
        return getInnerRadius(index) + arcWidth
      }
    }
  }

  currentJobStarted() {
    return { refreshKey: uuidv4() }
  }

  // currentJobFinished runs once each time a job finishes.
  currentJobFinished() {
    return { refreshKey: uuidv4() }
  }

  refreshStatus() {
    return { refreshKey: uuidv4() }
  }

  startButtonClicked() {
    return { refreshKey: uuidv4() }
  }

  getDefaultStatus(stateStatus) {
    let defaultStatus = {
      RunningMinutes: 0,
      AvgDurationInMinutes: 0,
      LastDurationInMinutes: 0,
      JobCompletedDate: null,
      JobEffectiveStartDate: null,
      IsStarted: false,
      IsPaused: false,
      IsCompleted: false,
      IsCanceled: false,
      JobMachine: '',
      JobName: '',
      RunStatus: '',
      Gap: null,
      BestSolution: null,
      Bound: null,
      Steps: []
    }

    if (!_.isEmpty(stateStatus)) {
      defaultStatus = { ...defaultStatus, ..._.cloneDeep(stateStatus) }
    }

    return defaultStatus
  }

  render() {
    const {
      props: {
        id,
        settings: {
          config: {
            fields: {
              enableDurationChart = true,
              enableRunStatus = true,
              enableStep = true,
              enableJobInfo = true,
              jobInfoProp = {},
              enableRunButton = true
            } = {},
            settings: {
              showJobTitle = true,
              title: jobTitle = '',
              showMachineName = true,
              jobMachineName = '',
              backgroundcolor: backgroundColor = '#ffffff'
            } = {}
          } = {}
        } = {}
      },
      state: {
        settings: { size: { height, width } = {} } = {},
        pending,
        colorArr,
        statusJobHistory = [],
        disabled = false,
        showSteps,
        showJobHistory
      }
    } = this

    const {
      RunningMinutes = 0,
      AvgDurationInMinutes,
      LastDurationInMinutes,
      JobCompletedDate,
      JobEffectiveStartDate,
      IsStarted,
      IsPaused,
      IsCompleted,
      IsCanceled,
      JobMachine,
      JobName,
      RunStatus,
      Gap,
      BestSolution,
      Bound,
      Steps = []
    } = this.getDefaultStatus(this.state.status)

    const enabledFromAuthorization = this.props.isAllowed('EnabledRunButton')
    const isRunButtonEnabled = enabledFromAuthorization && !disabled

    const { jobInfoTaken = 10, jobInfoSkip = 0 } = jobInfoProp

    const stepsCompleted = _.filter(Steps, (step) => step.IsCompleted)

    const stepsFailed = _.filter(Steps, (step) => step.IsCompleted && !step.IsSuccessful)

    const failedStepsList = _.map(stepsFailed, (failedSteps, index) => {
      const {
        StepName = '',
        JobStepEffectiveStartDate = '',
        JobStepCompletedDate = '',
        RetryCount = 0
      } = failedSteps
      const StepStartTime = moment.utc(JobStepEffectiveStartDate).local().format('LLL')

      const StepCompletedTime = moment.utc(JobStepCompletedDate).local().format('LLL')

      return (
        <tr key={index}>
          <td> {StepName}</td>
          <td className="jss-started-date" title="Start Date">
            {StepStartTime}
          </td>
          <td className="jss-completed-date" title="Completed Date">
            {StepCompletedTime}
          </td>
          <td title="Retry count">{RetryCount}</td>
        </tr>
      )
    })

    if (showJobHistory) {
      this.state.jobHistoryInfo = _.map(statusJobHistory, (histJob, index) => {
        const {
          DurationInMinutes = 0,
          IsCanceled = false,
          IsCompleted = false,
          IsStarted = false,
          JobCompletedDate = '',
          JobEffectiveStartDate = ''
        } = histJob
        const JobCompletedTimePopup = moment.utc(JobCompletedDate).local().format('LLL')

        const JobStartedTimePopup = moment.utc(JobEffectiveStartDate).local().format('LLL')
        const IsFailed = IsStarted && IsCompleted && IsCanceled

        const IsJobSuccess = !IsFailed && IsCompleted
        return (
          <tr key={index}>
            <td className="jss-running">{JobStartedTimePopup}</td>
            <td title="minutes">{DurationInMinutes}</td>
            {JobCompletedDate != null ? (
              <td className="jss-completed-date">{JobCompletedTimePopup}</td>
            ) : (
              <td>-</td>
            )}
            {IsJobSuccess ? (
              <td className="jss-success"> Success </td>
            ) : IsFailed ? (
              <td className="jss-failed"> Failed </td>
            ) : (
              <td className="jss-running"> Running </td>
            )}
          </tr>
        )
      })
    }

    const stepsLeft = _.filter(
      Steps,
      (step) => (!step.IsStarted && !step.IsCompleted) || (step.IsStarted && !step.IsCompleted)
    )

    const currentStep = _.find(Steps, (step) => step.IsStarted && !step.IsCompleted)

    const isRunning = !IsCompleted && !IsCanceled && IsStarted

    const jobIsSuccess = IsCompleted && !IsCanceled && IsStarted

    const statusesTypes = [IsCompleted, IsCanceled, IsPaused, IsStarted, pending, true]

    const status = statuses[statusesTypes.findIndex((s) => s)]

    const badgeClass = `badge bg-${status.className}`
    const durationText = isRunning
      ? `Running ${RunningMinutes} min. Approx.${
          AvgDurationInMinutes > RunningMinutes
            ? `${AvgDurationInMinutes - RunningMinutes} min. to finish.`
            : ''
        }`
      : RunningMinutes < 2
      ? `Completed in ${RunningMinutes || 0} minute.`
      : `Completed in ${RunningMinutes || 0} minutes.`
    const totalStepsSize = Steps.length

    const percentageOfFinishedStep = isRunning ? (stepsCompleted.length / totalStepsSize) * 100 : 0

    const percentageOfCurrentStep = currentStep ? (1 / totalStepsSize) * 100 : 0

    const percentageOfLeftStep = (stepsLeft.length / totalStepsSize) * 100

    const RunTimeInMinutes = currentStep ? (currentStep.RunTimeInSeconds / 60).toFixed(1) : 0

    const JobCompletedTime = moment.utc(JobCompletedDate).local().format('LLL')

    const JobStartedTime = moment.utc(JobEffectiveStartDate).local().format('LLL')

    const boundFormatted = numeral(Bound).format('0,0')

    const gapFormatted = numeral(Gap).format('0,0')

    const gapFixed = ((Gap || 0) * 100).toFixed(2)

    const gapWidth = (Gap || 0) * 100 * 10 > 60 ? (Gap || 0) * 100 * 10 : 60

    const bestFormatted = numeral(BestSolution).format('0,0')

    const noFields = !(enableDurationChart || enableRunStatus || enableStep || enableJobInfo)

    return (
      <div className={`job-status ${width <= 580 ? 'small' : 'big'}-box-ctn`} style={{ height }}>
        {createPortal(
          <Modal
            className="job-hist-step-popup-portal"
            enforceFocus={false}
            show={showSteps}
            size="lg"
            onHide={this.onCancelPopup.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title" id="contained-modal-title-lg">
                Failed Steps
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="job-modal-body">
              <div className="job-modal">
                <Table className="job-history-step-popup-table">
                  <thead>
                    <tr>
                      <th>Step Name</th>
                      <th>Started Time</th>
                      <th>Completed Time</th>
                      <th>Retry Count</th>
                    </tr>
                  </thead>
                  <tbody>{failedStepsList}</tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal>,
          portalTarget
        )}
        {createPortal(
          <Modal
            className="job-hist-step-popup-portal"
            enforceFocus={false}
            show={showJobHistory}
            size="lg"
            onHide={this.onCancelPopup.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title" id="contained-modal-title-lg">
                Job History
              </Modal.Title>
            </Modal.Header>
            {showJobHistory && (
              <Modal.Body className="job-modal-body">
                <div className="job-modal">
                  <Table className="job-history-step-popup-table ">
                    <thead>
                      <tr>
                        <th>Started Time</th>
                        <th>Running Minutes</th>
                        <th>Completed Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{this.state.jobHistoryInfo}</tbody>
                  </Table>
                </div>
              </Modal.Body>
            )}
          </Modal>,
          portalTarget
        )}
        {pending ? (
          noFields ? (
            <div className="no-fields" />
          ) : (
            <div className="loading-box">Loading Data</div>
          )
        ) : noFields ? (
          <div className="header-ctn-no-fields">
            <header className="header-ctn-no-fields">
              {showJobTitle && (jobTitle === '' ? <h1>{JobName}</h1> : <h1>{jobTitle}</h1>)}
              {showMachineName &&
                (jobMachineName === '' ? (
                  <h1>
                    <span title="Job Machine Name">
                      <i className="fa fa-desktop" />
                      {JobMachine}
                    </span>
                  </h1>
                ) : (
                  <h1>
                    <span title="Job Machine Name">{jobMachineName}</span>
                  </h1>
                ))}
              {enableRunButton ? (
                <div className={`play-btn-ctn ${badgeClass} ${isRunning} ${status.display}`}>
                  <span>{status.display}</span>
                  <button
                    className={
                      status.display === 'Running'
                        ? 'fa fa-pause-circle-o'
                        : 'Start'
                        ? 'fa fa-play-circle'
                        : ''
                    }
                    disabled={!isRunButtonEnabled}
                    title={status.display === 'Running' ? 'Cancel Job' : 'Start' ? 'Start Job' : ''}
                    onClick={
                      status.display === 'Start'
                        ? this.playButtonClickHandle
                        : 'Running'
                        ? this.cancelButtonClickHandle
                        : ''
                    }
                  />
                </div>
              ) : null}
            </header>
          </div>
        ) : (
          <div>
            <header className="header-ctn">
              {showJobTitle && (jobTitle === '' ? <h1>{JobName}</h1> : <h1>{jobTitle}</h1>)}
              {showMachineName &&
                (jobMachineName === '' ? (
                  <h1>
                    <span title="Job Machine Name">
                      <i className="fa fa-desktop" />
                      {JobMachine}
                    </span>
                  </h1>
                ) : (
                  <h1>
                    <span title="Job Machine Name">{jobMachineName}</span>
                  </h1>
                ))}
              {enableRunButton ? (
                <div className={`play-btn-ctn ${badgeClass} ${isRunning} ${status.display}`}>
                  <span>{status.display}</span>
                  <button
                    className={
                      status.display === 'Running'
                        ? 'fa fa-pause-circle-o'
                        : 'Start'
                        ? 'fa fa-play-circle'
                        : ''
                    }
                    disabled={!isRunButtonEnabled}
                    title={status.display === 'Running' ? 'Cancel Job' : 'Start' ? 'Start Job' : ''}
                    onClick={
                      status.display === 'Start'
                        ? this.playButtonClickHandle
                        : 'Running'
                        ? this.cancelButtonClickHandle
                        : ''
                    }
                  />
                </div>
              ) : null}
            </header>
            <main style={{ height: height - 44 }}>
              {enableDurationChart && (
                <div className="common-box full-height duration-chart" style={{ backgroundColor }}>
                  <h2>{durationText}</h2>
                  {height >= 270 && (
                    <div className="chart" id={`chart_${id}`}>
                      <svg className="svg-chart" preserveAspectRatio="xMinYMin meet" />
                    </div>
                  )}
                  <ul className="duration-chart-info">
                    <li style={{ color: colorArr[0] }} title="Average of duration minute(s)">
                      <b>{AvgDurationInMinutes} min</b>
                      Average
                    </li>
                    <li style={{ color: colorArr[1] }} title="Last duration minute(s)">
                      <b>{LastDurationInMinutes} min</b>
                      Last
                    </li>
                    <li style={{ color: colorArr[2] }} title="Current running minute(s) of job">
                      <b>{RunningMinutes} min</b>
                      Current
                    </li>
                  </ul>
                </div>
              )}
              {(enableRunStatus || enableStep || enableJobInfo) && (
                <div
                  className={`common-box run-waiting ${
                    RunStatus === 'NotStarted' ? 'info-msg' : ''
                  }`}
                  data-msg="Waiting run parameters..."
                  style={{ backgroundColor }}
                >
                  {enableRunStatus && (
                    <div className="common-box run-status-ctn" style={{ backgroundColor }}>
                      <h2>Run Status</h2>
                      <nav className="run-timeline-ctn">
                        {RunStatus !== null || RunStatus !== 'NoRun' || RunStatus !== 'Unknown' ? (
                          <ol className={`run-timeline ${RunStatus}`}>
                            <li>
                              <i />
                              <b>Queue</b>
                            </li>
                            <li>
                              <i />
                              <b>Loading</b>
                            </li>
                            <li>
                              <i />
                              <b>Running</b>
                            </li>
                            <li>
                              <i />
                              <b>Writing</b>
                            </li>
                            <li>
                              <i />
                              <b>Comp.</b>
                            </li>
                          </ol>
                        ) : (
                          ''
                        )}
                      </nav>

                      <div className="run-chart">
                        <div className="bound">
                          <small>Bound</small>
                          <b>{boundFormatted}</b>
                        </div>
                        <div className="gap">
                          {Gap > 0 ? (
                            <div
                              className="percentage"
                              style={{
                                width: `${gapWidth}%`
                              }}
                            >
                              {gapFixed || 0}%{' '}
                            </div>
                          ) : (
                            <div />
                          )}
                          <small className="gap-text">Gap</small>
                          <b>{gapFormatted || 0}</b>
                        </div>
                        <div className="best-solution">
                          <small>Best</small>
                          <b>{bestFormatted}</b>
                        </div>
                      </div>

                      <ul className="run-legend">
                        <li>{boundFormatted}</li>
                        <li>{gapFormatted}</li>
                        <li>{bestFormatted}</li>
                      </ul>
                    </div>
                  )}
                  {enableStep && (
                    <nav className="common-box step-ctn" style={{ backgroundColor }}>
                      <h2>Step</h2>
                      <div className="chart-holder">
                        <ul className="c-progress-bar">
                          <li
                            className="finished"
                            style={{
                              width: `${percentageOfFinishedStep}%`
                            }}
                            title="Finished"
                          />

                          <li
                            className="current"
                            style={{ width: `${percentageOfCurrentStep}%` }}
                            title={`Running in ${RunTimeInMinutes}min`}
                          />

                          <li
                            className="left"
                            style={{ width: `${percentageOfLeftStep}%` }}
                            title="Not Started"
                          />
                        </ul>

                        <ul className="current-left-step">
                          <li title="Step Left">
                            {stepsLeft.length >= 0 ? (
                              stepsLeft.length > 1 ? (
                                <b>{stepsLeft.length} steps left.</b>
                              ) : (
                                <b>{stepsLeft.length} step left.</b>
                              )
                            ) : (
                              {}
                            )}
                          </li>
                          {currentStep && (
                            <li title="Current step">
                              <b> Running step : {currentStep.StepName}</b>
                            </li>
                          )}
                        </ul>
                      </div>
                    </nav>
                  )}
                  {enableJobInfo && (
                    <nav className="common-box job-info-ctn" style={{ backgroundColor }}>
                      <div className="chart-holder">
                        <ul className="job-info-list">
                          <div>
                            <BootstrapButton
                              type="button"
                              variant="warning"
                              onClick={() => {
                                this.onShowModalFailedSteps()
                              }}
                            >
                              Failed Steps: {failedStepsList.length}
                            </BootstrapButton>
                            &nbsp;
                            <BootstrapButton
                              type="button"
                              variant="success"
                              onClick={() => this.onShowModalJobHistory(jobInfoTaken, jobInfoSkip)}
                            >
                              Job Informations
                            </BootstrapButton>
                          </div>
                          {!IsCompleted && IsStarted ? (
                            <li>
                              <b className="jss-started-date" title="Current Job Started Date">
                                {JobStartedTime}
                              </b>
                            </li>
                          ) : (
                            <b />
                          )}
                          {JobCompletedDate && (
                            <li>
                              <b className="jss-started-date" title="Last Job Started Date">
                                {JobStartedTime}
                              </b>
                              <b> - </b>
                              <b className="jss-success" title="Last Job Completed Date">
                                {JobCompletedTime}
                              </b>
                              <b> - </b>
                              {IsCompleted && jobIsSuccess ? (
                                <b className="jss-success">Success</b>
                              ) : IsCompleted && IsCanceled ? (
                                <b className="jss-canceled">Canceled</b>
                              ) : IsCompleted && IsPaused ? (
                                <b className="jss-paused">Paused</b>
                              ) : (
                                !IsCompleted && <b className="jss-running">Running</b>
                              )}
                            </li>
                          )}
                        </ul>
                      </div>
                    </nav>
                  )}
                </div>
              )}
            </main>
          </div>
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  registerAuthorizations: props.registerAuthorizations,
  settings: props.settings,
  id: props.id,
  params: props.params,
  token: props.token,
  size: props.size,
  isAllowed: props.isAllowed
})

export default createPlugin(JobSchedulerStatus, selectConnectorProps)
