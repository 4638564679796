import { useCallback } from 'react'
import { CellEditingStartedEvent } from 'ag-grid-community'
import { AG_GRID_ENUMS } from '../../enums'
import { IRowData, TrowDataValue } from '../../types'

const { CUSTOM_ROW_INFO, ADDED_ROWS } = AG_GRID_ENUMS

function useCellEditingStarted() {
  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent<IRowData, TrowDataValue>) => {
      if (!event?.data) {
        return
      }

      const addedRows = event.data[CUSTOM_ROW_INFO][ADDED_ROWS]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isAddingEnabled = event.colDef.customInfo?.adding?.enabled

      if (addedRows.length && !isAddingEnabled) {
        event.api.stopEditing(true)
      }
    },
    []
  )

  return { onCellEditingStarted }
}

export default useCellEditingStarted
