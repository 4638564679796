export default `({
    xtype: 'grid',
    columnLines: true,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [
            {
                xtype: 'button',
                text: tt('Export to Excel'),
                docked: 'right',
                handler: function (btn) {
                    var grid = this.ownerCt.ownerCt
                    grid.saveDocumentAs({
                        type: 'xlsx',
                        title: tt('Old Campaign'),
                        fileName: tt('Old Campaign')+'.xlsx',
                    });
                }
        }],
    }],
    plugins: [{
        ptype: 'gridexporter'
    }],
    columns: [{
            text: tt('StartDate'),
            dataIndex: 'StartDate',
            width: 100,
            xtype: 'datecolumn',
            format: tt('d-m-Y')
        },
        {
            text: tt('EndDate'),
            dataIndex: 'EndDate',
            width: 100,
            xtype: 'datecolumn',
            format: tt('d-m-Y')
        },
        {
            text: tt('DurationInDays'),
            dataIndex: 'DurationInDays',
            width: 100,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('SalesPrice'),
            columns: [{
                text: tt('Etiket'),
                dataIndex: 'Price',
                width: 100,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('AgirlikliOrtalama'),
                dataIndex: 'WeightedAveragePrice',
                width: 100,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('yuzde20eniyi'),
                dataIndex: 'WeightedAveragePriceForMostSellerForFirst20PercentSales',
                width: 100,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('yuzde20enkotu'),
                dataIndex: 'WeightedAveragePriceForLeastSellerForFirst20PercentSales',
                width: 100,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn'
            }]
        },
        {
            text: tt('Tekıl Organizasyon'),
            columns: [{
                text: tt('Depo'),
                dataIndex: 'NumberOfDistinctDC',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Mağaza'),
                dataIndex: 'NumberOfDistinctStore',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn'
            }]
        },
        {
            text: tt('Performans'),
            columns: [{
                    text: tt('Quantity'),
                    dataIndex: 'TotalInvoicedQuantity',
                    width: 100,
                    format: '0,000',
                    align: 'right',
                    xtype: 'numbercolumn'
                }, {
                    text: tt('Average Sales Qty'),
                    width: 100,
                    format: '000',
                    align: 'right',
                    renderer: function (value, metaData, record) {
                        var data = record.data;
                        var numberOfDistinctStore = data.NumberOfDistinctStore;
                        var totalInvoicedQuantity = data.TotalInvoicedQuantity;
                        var averageSalesQuantity = numberOfDistinctStore !== 0 ? (totalInvoicedQuantity / numberOfDistinctStore) : 0;
                        return Ext.util.Format.number(averageSalesQuantity, '0,000.00');
                    }
                },
                {
                    text: tt('Revenue'),
                    dataIndex: 'TotalNetValueoftheSalesOrder',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        v = Ext.util.Format.number(v, '0,000');
                        if(t('₺')=='₺')
                        {
                            return v + ' ' + t('₺');
                        }
                        else{
                            return t('₺') +' '+ v;
                        }
                    }
                }
            ]
        },
        {
            text: tt('Day 1'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay1',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay1',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Day 2'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay2',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay2',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Day 4'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay4',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay4',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Day 7'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay7',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay7',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Pareto 80% '),
            columns: [{
                    text: tt('Gün'),
                    dataIndex: 'Pareto80Day',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('ParetoTotalInvoicedQuantity'),
                    dataIndex: 'ParetoTotalInvoicedQuantity',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('ParetoTotalInvoicedQuantityPercent'),
                    dataIndex: 'ParetoTotalInvoicedQuantityPercent',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        v = Ext.util.Format.number(v * 100, '0,000');
                        return v + ' ' + '%';
                    }
                }
            ]
        },
        // {
        //     text: tt('TotalInvoicedQuantity'),
        //     dataIndex: 'TotalInvoicedQuantity',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrder'),
        //     dataIndex: 'TotalNetValueoftheSalesOrder',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalInvoicedQuantityDay1'),
        //     dataIndex: 'TotalInvoicedQuantityDay1',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalInvoicedQuantityDay2'),
        //     dataIndex: 'TotalInvoicedQuantityDay2',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalInvoicedQuantityDay3'),
        //     dataIndex: 'TotalInvoicedQuantityDay3',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalInvoicedQuantityDay4'),
        //     dataIndex: 'TotalInvoicedQuantityDay4',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalInvoicedQuantityDay5',
        //     dataIndex: 'TotalInvoicedQuantityDay5',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalInvoicedQuantityDay6'),
        //     dataIndex: 'TotalInvoicedQuantityDay6',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalInvoicedQuantityDay7'),
        //     dataIndex: 'TotalInvoicedQuantityDay7',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay1',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay1',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay2'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay2',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay3'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay3',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay4'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay4',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay5',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay5',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay6'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay6',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay7'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay7',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('Pareto80Day'),
        //     dataIndex: 'Pareto80Day',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('ParetoNetValueoftheSalesOrder'),
        //     dataIndex: 'ParetoNetValueoftheSalesOrder',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('ParetoTotalInvoicedQuantity'),
        //     dataIndex: 'ParetoTotalInvoicedQuantity',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('ParetoTotalInvoicedQuantityPercent'),
        //     dataIndex: 'ParetoTotalInvoicedQuantityPercent',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // }
    ],
    setMaterialCode: function (c, d) {
        var store = this.getStore().load(),
            proxy = store.getProxy();
        store.setData([]);
        proxy.setExtraParam('MaterialCode', c);
        store.load();
    }
});
`
