import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'
import jwtDecode from 'jwt-decode'

import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import {
  GetRowIdFunc,
  GetRowIdParams,
  GridOptions,
  StateUpdatedEvent,
  ApplyColumnStateParams
} from 'ag-grid-community'
import { AG_GRID_LOCALE_TR, AG_GRID_LOCALE_EN } from '@ag-grid-community/locale'
import { AG_GRID_LOCALE_CUSTOM_TR, AG_GRID_LOCALE_CUSTOM_EN } from './locale'

import { SlvySpinner } from '@/components'

import { PluginProps } from '@/BasePlugin/components/PluginProps.types'

import {
  useApplyUpdate,
  useCellClick,
  useCellDoubleClick,
  useCellEditingStarted,
  useCellEditRequest,
  useCreateReportExportInfo,
  useCustomAggregations,
  useDataTypeDefinitions,
  useDefaultColDef,
  useDefaultExcelExportParams,
  useEnableAdvancedFilter,
  useExcelExportStyles,
  useFirstRowDataAfterFilterAndSort,
  useGetAddedRows,
  useGetColumnDefs,
  useGetContextMenuItems,
  useGetDeletedRows,
  useGetDirtyFields,
  useGetMainMenuItems,
  useGetRegisterEventsRef,
  useGetRowHeight,
  useGroupRowRendererParams,
  useHandleActionBtnClick,
  useHandleAddRecord,
  useHandleDeleteRow,
  useHandleFilter,
  useHandleReset,
  useHandleSave,
  useHandleSort,
  useHideOverlay,
  useInitialState,
  useIsGroupOpenByDefaultProps,
  useMassUpdateModal,
  useOverlayProps,
  usePaginationProps,
  usePostProcessPopup,
  usePreviewHandlers,
  useRegisterMethods,
  useResetDeletedRows,
  useResetCustomRowInfo,
  useRowSelection,
  useSetColumnEditable,
  useTriggerSave,
  useAutoGroupColumnDef,
  useColumnRowGroupChanged
} from './hooks'

import {
  constantGridProps,
  emptyArray,
  emptyObject,
  DEFAULT_CUSTOM_ROW_INFO,
  footerBarHeight,
  DEFAULT_CONTEXT,
  themeStyles
} from './constants'

import { DirtyStates } from './components/Debugging'
import { MassUpdateModal } from './components/MassUpdate'
import { Pagination } from './components/Pagination'
import Footer from './components/Footer'

import {
  getAggFuncs,
  getMappedRowData,
  getGridEditable,
  getBarsTotalHeight,
  getDisplayedRowCount as _getDisplayedRowCount,
  getColumnHidden,
  getColumnDefsWithHeaderGroups,
  mapWithInitialState,
  replaceTemplate,
  getColumnDefsAsFlat
} from './helpers/index'

import {
  AgGridProps,
  DeletedRow,
  DirtyCell,
  DoesDirectUpdate,
  IRowData,
  SlvyMasterColDef,
  TfieldConfigs,
  TStatus,
  TconfigColumn,
  SlvyContext,
  SlvyColDef,
  SlvyColGroupDef,
  Theme,
  LocaleText
} from './types'
import { PaginationPropsReturnTypesRequired } from './hooks/usePaginationProps/usePaginationProps.types'

import { AG_GRID_ENUMS } from './enums'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import headerStyles from './scss/_header.module.scss'
import rowStyles from './scss/_row.module.scss'
import { getLicenseKey } from '@/helpers'

const SLVY_AG_GRID_LICENSE_KEY: string = getLicenseKey('aggrid')

if (SLVY_AG_GRID_LICENSE_KEY) {
  LicenseManager.setLicenseKey(SLVY_AG_GRID_LICENSE_KEY)
} else {
  // eslint-disable-next-line no-console
  console.error('YOU MUST HAVE AG-GRID LICENSE!')
}

const { CUSTOM_ROW_INFO, ROW_ID } = AG_GRID_ENUMS

const popupParent = document.querySelector('body') as HTMLBodyElement

const AgGrid = (props: AgGridProps) => {
  const {
    actualFilters,
    additionalArgs,
    id,
    isMaximized,
    isPreviewMode,
    isPluginDataLoaded,
    isPluginDataLoading,
    pluginData,
    schema,
    schema: { properties: { columns: { items: schemaColumnItems = emptyObject } = {} } = {} } = {},
    data: { schema: dataSchema } = {},
    settings: {
      query: {
        dataEditing: { isUpdateQuery = false } = emptyObject,
        fields: fieldConfigsQuery = emptyArray,
        formattedFields = emptyArray,
        editableFields = emptyArray
      } = {},
      config: {
        general: { name, exportWithoutHeaders },
        cellDisplayRules,
        rowDisplayRules,
        columns: configColumns,
        grid: {
          customConfigs: {
            adding,
            dataDetails,
            deleting,
            editing,
            enableAdvancedFilter,
            getCellColorsFromData,
            editing: { enabled: editingEnabledByConfig, editingType, massUpdate },
            selection: { type: selectionType },
            emptyText,
            exportable,
            filtering,
            footerButtons,
            grouping,
            header,
            initialGroupingCollapse,
            listFilterCheckbox,
            lockPinned,
            pagination,
            suppressCount,
            updateParameters,
            noColorFooterButtons
          },
          ...restGridConfig
        }
      }
    },
    clearCaches,
    fetchPageData,
    formatValue,
    isAllowed,
    isPagedQuery,
    getFieldType,
    getFormattedValue,
    getPageSize,
    getTotalPage,
    getTotalRowCount,
    onReady,
    registerAuthorizations,
    registerEvent,
    registerMethod,
    reportExportInfo,
    setDataArguments,
    token
  } = props

  const { groupDisplayType, suppressRowGroupHidesColumns } = restGridConfig

  const { culture = 'en-US' } = jwtDecode<Record<'culture', string>>(token)

  const configColumnsArr = configColumns ?? (emptyArray as unknown as TconfigColumn[])

  const pluginDataArr =
    isPluginDataLoaded && pluginData
      ? pluginData
      : (emptyArray as unknown as PluginProps['pluginData'])

  // Force balham for preview mode to fix theme change crash
  const theme = isPreviewMode ? 'balham' : props?.settings?.config?.grid?.customConfigs?.theme

  const { rowSelection, enableCellTextSelection } = restGridConfig
  const doesDirectUpdate: DoesDirectUpdate = editingType === 'Direct'

  const AG_GRID_URL = `${process.env.API_URL}/data/plugin/${id}`

  const gridRef = useRef<AgGridReact<IRowData>>(null)

  const context = useRef<SlvyContext>(DEFAULT_CONTEXT)

  const [isGridReady, setIsGridReady] = useState(false)

  const [rowData, setRowData] = useState<undefined | IRowData[]>()
  const rowDataArr = rowData ?? (emptyArray as unknown as IRowData[])

  const [columnDefs, setColumnDefs] = useState<undefined | SlvyMasterColDef[]>()
  const columnDefsArr = columnDefs ?? (emptyArray as unknown as SlvyMasterColDef[])

  const [, setStatus] = useState<TStatus>('idle')

  const [currentPage, setCurrentPage] = useState(1)

  const [totalRowCount, setTotalRowCount] = useState(0)
  const [filteredRowCount, setFilteredRowCount] = useState(0)
  const [deletedRows, setDeletedRows] = useState<DeletedRow[]>([])
  const [editedFields, setEditedFields] = useState<DirtyCell[]>([])

  const [gridEditableByDefault, setGridEditableByDefault] = useState(false)
  const [gridEditableByState, setGridEditableByState] = useState(false)
  const [, setDefaultActionParams] = useState({
    payload: {
      pluginId: '',
      requestMethod: 'get',
      body: {}
    }
  })

  const memoizedFieldConfigs: TfieldConfigs = useMemo(() => {
    return dataSchema || fieldConfigsQuery
  }, [dataSchema, fieldConfigsQuery])

  const unMemoizedFirstData = rowDataArr?.[0]
  const firstRowData: undefined | IRowData = useMemo(
    () => unMemoizedFirstData ?? undefined,
    [unMemoizedFirstData]
  )
  const didColumnHiddenRunRef = useRef(false)

  const { massUpdateModal, onMassUpdateCompleted, onMassUpdateCancelled } = useMassUpdateModal(id)

  const {
    actionButtonsRef,
    conditionedRowsResultEventRef,
    dataUpdatedEventRef,
    dirtyStateChangedEventRef,
    footerButtonsRef,
    getModifiedRowsResultEventRef,
    multiRowSelectedEventRef,
    multiRowsSetEventRef,
    onCellDoubleClickedRef,
    rowAddedEventRef,
    rowSelectedEventRef,
    triggerMultiRowSelectedResultEventRef
  } = useGetRegisterEventsRef({
    columnConfigs: configColumnsArr,
    footerButtons,
    fieldConfigs: memoizedFieldConfigs,
    formattedFields,
    getFieldType,
    getFormattedValue,
    gridRef,
    registerEvent
  })

  const { handleActionBtnClick } = useHandleActionBtnClick({ actionButtonsRef })

  const { paginationProps, isPaginationEnabled } = usePaginationProps({
    pagination,
    isPagedQuery,
    pageSize: getPageSize() ?? 0,
    totalPage: getTotalPage() ?? 0,
    totalRowCount: getTotalRowCount() ?? 0
  })

  const { getColumnDefs } = useGetColumnDefs({
    gridRef,
    theme,
    exportable,
    isPaginationEnabled,
    getCellColorsFromData,
    cellDisplayRules,
    configColumns,
    deleting,
    editableFields,
    filtering,
    formatValue,
    getFormattedValue,
    gridEditableByDefault,
    grouping,
    groupDisplayType,
    header,
    handleActionBtnClick,
    listFilterCheckbox,
    lockPinned,
    massUpdate,
    selectionType,
    enableCellTextSelection,
    rowDisplayRules,
    fieldConfigs: memoizedFieldConfigs,
    formattedFields
  })

  const isMassUpdateAllowed = gridEditableByState && massUpdate
  const { getMainMenuItems } = useGetMainMenuItems({
    gridRef,
    grouping,
    lockPinned,
    massUpdate: isMassUpdateAllowed,
    suppressRowGroupHidesColumns
  })

  const getDisplayedRowCount = useCallback(() => _getDisplayedRowCount(gridRef), [])

  useEffect(() => {
    registerAuthorizations(['Editing'])
  }, [registerAuthorizations])

  useEffect(() => {
    setDefaultActionParams((prev) => {
      return {
        payload: {
          ...prev.payload,
          pluginId: id
        }
      }
    })
  }, [id])

  // JUST FOR PREVIEW MODE
  usePreviewHandlers({
    schema,
    onReady,
    dataSchema,
    schemaColumnItems,
    isPreviewMode,
    fieldConfigsQuery,
    memoizedFieldConfigs
  })

  const { getFirstRowDataAfterFilterAndSort } = useFirstRowDataAfterFilterAndSort({
    gridRef,
    checkIsSelected: false
  })

  const { initialState, saveGridState, getInstantGridInitialState } = useInitialState({
    initialGroupingCollapse,
    groupDisplayType,
    suppressRowGroupHidesColumns,
    configColumns: configColumnsArr,
    grouping,
    lockPinned,
    pluginId: id,
    isPreviewMode,
    theme
  })

  useEffect(() => {
    // Do not update columnDefs if getColumnDefs return undefined
    // when props.settings.config.columns is undefined
    const colDefs = getColumnDefs()
    if (!colDefs) return

    const columnState = gridRef?.current?.api?.getColumnState?.()

    const gridApiColumnDefs = gridRef?.current?.api?.getColumnDefs?.()

    const gridFirstRowData = getFirstRowDataAfterFilterAndSort()! || firstRowData

    const isInitialCreation = Boolean(
      !columnState?.length || !gridApiColumnDefs?.length // || !gridFirstRowData can be added but check if firstRowData is coming for the first time
    )

    const newColumnDefs = mapWithInitialState(
      colDefs,
      getColumnDefsAsFlat(gridApiColumnDefs as SlvyMasterColDef[]),
      getInstantGridInitialState(),
      isInitialCreation
    ).map((col) => {
      const optional: Partial<{ headerTooltip: SlvyColDef['headerTooltip'] }> = {}

      if (typeof col?.headerTooltip !== 'undefined') {
        optional.headerTooltip = replaceTemplate({
          text: col.headerTooltip,
          data: gridFirstRowData,
          getFormattedValue,
          formattedFields
        })
      }

      return {
        ...col,
        ...optional,
        headerName: replaceTemplate({
          text: col?.headerName ?? '',
          data: gridFirstRowData,
          getFormattedValue,
          formattedFields
        })
      }
    })

    const colDefsWithGroupHeaders = getColumnDefsWithHeaderGroups(
      newColumnDefs as Array<SlvyColDef>,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      gridFirstRowData,
      getFormattedValue,
      formattedFields
    )

    setColumnDefs(colDefsWithGroupHeaders as Array<SlvyMasterColDef>)
  }, [
    getColumnDefs,
    getFirstRowDataAfterFilterAndSort,
    getInstantGridInitialState,
    isPreviewMode,
    firstRowData,
    getFormattedValue,
    formattedFields
  ])

  useEffect(() => {
    // const colDefs = (getColumnDefs() ?? []) as SlvyColDef[]
    // columnDefs in state is mapped with initialState
    const colDefsWithGroupHeaders = columnDefs ?? []

    if (!gridRef.current || !colDefsWithGroupHeaders?.length || didColumnHiddenRunRef?.current) {
      return
    }

    const flatColDefs = getColumnDefsAsFlat(colDefsWithGroupHeaders)

    const doesAnyHiddenConditionExist = flatColDefs.some((col) => col?.customInfo?.hiddenCondition)
    const checkFirstRowData = doesAnyHiddenConditionExist
      ? doesAnyHiddenConditionExist && firstRowData
      : true

    if (!checkFirstRowData) {
      return
    }

    const state: ApplyColumnStateParams['state'] = []

    flatColDefs.forEach((col) => {
      const hide =
        col?.hide ??
        getColumnHidden({
          firstRowData,
          hideByColumnSettings: col?.customInfo?.hide,
          hiddenCondition: col?.customInfo?.hiddenCondition,
          isMaximized,
          showInMaxMode: col?.customInfo?.showInMaxMode,
          rowGroup: col?.rowGroup,
          suppressRowGroupHidesColumns
        })
      const colId = col?.colId ?? col.field ?? ''
      state.push({ colId, hide })
    })

    // if (!doesAnyHiddenConditionExist) {
    didColumnHiddenRunRef.current = true
    // }

    setTimeout(() => {
      if (state?.length) {
        gridRef?.current?.api?.applyColumnState?.({ state })
      }
    }, 0)
  }, [columnDefs, firstRowData, isMaximized, suppressRowGroupHidesColumns])

  const { customAgg } = useCustomAggregations({
    columnDefs: columnDefsArr,
    data: pluginDataArr,
    gridRef
  })

  useEffect(() => gridRef.current?.api?.addAggFuncs(customAgg), [customAgg])

  useEffect(() => {
    // Do not update rowData if pluginData is undefined or null
    if (!isPluginDataLoaded) {
      return
    }

    const rows: IRowData[] = getMappedRowData({
      pluginData: pluginDataArr,
      editableFields,
      fieldConfigs: memoizedFieldConfigs
    }).map((row) => {
      return {
        ...row,
        [CUSTOM_ROW_INFO]: {
          ...cloneDeep(DEFAULT_CUSTOM_ROW_INFO),
          [ROW_ID]: uuidv4()
        }
      }
    })
    setRowData(rows)
    setTotalRowCount(rows.length)
    setStatus('success')
  }, [isPluginDataLoaded, pluginDataArr, memoizedFieldConfigs, editableFields])

  const { onSortChanged, pluginSorters } = useHandleSort({
    gridRef,
    isPaginationEnabled,
    setCurrentPage
  })

  const { pluginFilters, onFilterChanged } = useHandleFilter({
    gridRef,
    configColumns: configColumnsArr,
    isPaginationEnabled,
    setFilteredRowCount,
    setCurrentPage,
    fieldConfigs: memoizedFieldConfigs
  })

  const { resetCustomRowInfo } = useResetCustomRowInfo({ gridRef, setEditedFields })
  const { resetDeletedRows } = useResetDeletedRows({ gridRef, setDeletedRows })

  const { handleReset } = useHandleReset({
    deletedRows,
    gridRef,
    setFilteredRowCount,
    setTotalRowCount,
    resetCustomRowInfo,
    resetDeletedRows
  })

  // TODO: This is added to prevent sending initial request
  // Passing `forceLoad: true` on initial request, makes the data request that we don't want in some rare cases
  // like, you have big data and you only want to load with filter
  // https://dragon.solvoyo.com/Configuration/catalog/5a3a0d9569822c0cc8e683a2/store/1/menu/5b485b0569822c0d405e3fa1/page/66040182693a591fee2c85ed
  // In pageLoad, we are passing forceLoad as false for 1 second
  const shouldPreventInitialForceLoad = useRef(true)

  useEffect(() => {
    const pageLoadTimeout = setTimeout(() => {
      shouldPreventInitialForceLoad.current = false
    }, 1000)
    return () => clearTimeout(pageLoadTimeout)
  }, [])

  const loadPageData = useCallback(
    (forceLoad = true) => {
      if (!isPaginationEnabled) {
        return
      }

      // Wait for grid onLoad
      if (gridRef?.current?.api) {
        handleReset()
      }

      fetchPageData({
        pageNumber: currentPage,
        pluginSorters,
        pluginFilters,
        forceLoad: shouldPreventInitialForceLoad?.current ? false : forceLoad
      })
    },
    [isPaginationEnabled, fetchPageData, currentPage, pluginSorters, pluginFilters, handleReset]
  )

  // PAGINATION: Calling fetchPageData stops auto pluginData load
  useEffect(() => loadPageData(), [loadPageData])

  const { createReportExportInfo } = useCreateReportExportInfo({
    configColumns: configColumnsArr,
    exportWithoutHeaders,
    formattedFields,
    reportExportInfo,
    columnDefs: columnDefs as SlvyColGroupDef[]
  })

  // Menu Widget Export
  useEffect(() => createReportExportInfo(), [pluginDataArr, createReportExportInfo])

  useEffect(() => {
    if (!isGridReady) {
      return
    }
    // Keep previous context if exist
    const prevContext = gridRef?.current?.api?.getGridOption('context') || {}

    context.current = {
      ...(context?.current || {}),
      ...prevContext,
      pluginId: id,
      additionalArgs,
      actualFilters,
      culture,
      groupDisplayType,
      theme
    }
    gridRef?.current?.api.setGridOption('context', context.current)
    gridRef?.current?.api.refreshCells()
    gridRef?.current?.api.refreshHeader()
  }, [isGridReady, theme, id, additionalArgs, actualFilters, culture, groupDisplayType])

  const { setColumnEditable } = useSetColumnEditable({
    gridRef,
    columnDefs: columnDefsArr,
    gridEditableByDefault,
    gridEditableByState
  })

  useEffect(() => {
    if (!isGridReady) {
      return
    }
    setColumnEditable()
  }, [isGridReady, setColumnEditable])

  useEffect(() => {
    const isEditable = getGridEditable({
      isAllowed,
      editingEnabledConfig: editingEnabledByConfig
    })
    setGridEditableByDefault(isEditable)
    setGridEditableByState(isEditable)
  }, [editingEnabledByConfig, isAllowed])

  // TODO: Workaround for: "Ag-Grid Cant load empty"
  useHideOverlay({ isPluginDataLoading, gridRef, rowData })

  const { getDirtyFields } = useGetDirtyFields(gridRef)

  const { getAddedRows } = useGetAddedRows(gridRef)

  const { getDeletedRows } = useGetDeletedRows(gridRef)

  const setDirtyFields = useCallback(() => {
    setEditedFields(getDirtyFields())
  }, [getDirtyFields])

  const isThereUnsavedChanges =
    getDirtyFields().length > 0 || getDeletedRows().length > 0 || getAddedRows().length > 0

  useEffect(() => {
    dirtyStateChangedEventRef?.current?.handleDirtyStateChanged?.(isThereUnsavedChanges)
  }, [dirtyStateChangedEventRef, isThereUnsavedChanges])

  // TODO: make callback func in useHandleSave as a second parameter for dataUpdatedEventRef
  const { handleSave } = useHandleSave({
    adding,
    additionalArgs,
    AG_GRID_URL,
    columnDefs: columnDefsArr,
    dataUpdatedEventRef,
    getDeletedRows,
    doesDirectUpdate,
    gridRef,
    isUpdateQuery,
    resetCustomRowInfo,
    resetDeletedRows
  })

  const refreshData = useCallback(() => {
    clearCaches()
    setDataArguments(null, true)
  }, [clearCaches, setDataArguments])

  const { triggerSave } = useTriggerSave({
    editing,
    isThereUnsavedChanges,
    gridRef,
    handleSave,
    refreshData
  })

  const { applyUpdate } = useApplyUpdate({ gridRef, setDirtyFields, doesDirectUpdate, triggerSave })

  const { handleAddRecord } = useHandleAddRecord({
    adding,
    rowAddedEventRef,
    columnDefs: columnDefsArr,
    gridRef,
    setTotalRowCount,
    totalRowCount
  })

  const { isGridReadOnly } = useRegisterMethods({
    conditionedRowsResultEventRef,
    fieldConfigs: memoizedFieldConfigs,
    getModifiedRowsResultEventRef,
    gridRef,
    selectionType,
    multiRowsSetEventRef,
    triggerMultiRowSelectedResultEventRef,
    updateParameters,
    applyUpdate,
    registerMethod,
    handleAddRecord,
    handleReset,
    triggerSave,
    setGridEditableByState
  })

  const { onCellEditingStarted } = useCellEditingStarted()

  const { handleDeleteRow } = useHandleDeleteRow({
    deleting,
    setDeletedRows,
    setTotalRowCount,
    setFilteredRowCount,
    doesDirectUpdate,
    triggerSave,
    gridRef
  })

  const { onCellClicked } = useCellClick({ gridEditableByState, handleDeleteRow })

  const { onCellDoubleClicked } = useCellDoubleClick({
    onCellDoubleClickedRef,
    selectionType
  })

  const onStateUpdated: GridOptions<IRowData>['onStateUpdated'] = useCallback(
    ({ state }: StateUpdatedEvent<IRowData>) => {
      if (isPreviewMode) {
        return
      }

      saveGridState(state)
    },
    [isPreviewMode, saveGridState]
  )

  const { onColumnRowGroupChanged } = useColumnRowGroupChanged({
    gridRef,
    grouping,
    groupDisplayType,
    theme,
    suppressCount
  })

  const onModelUpdated = useCallback(() => {
    setFilteredRowCount(getDisplayedRowCount())
  }, [getDisplayedRowCount])

  const onGridReady: GridOptions<IRowData>['onGridReady'] = useCallback(() => {
    setIsGridReady(true)
  }, [])

  const doesRowDisplayRulesExist = Boolean(rowDisplayRules.length)

  const { onCellEditRequest } = useCellEditRequest({
    gridRef,
    applyUpdate,
    doesRowDisplayRulesExist
  })

  const { getContextMenuItems } = useGetContextMenuItems(gridRef, exportable, name)

  const { onRowSelected, onSelectionChanged } = useRowSelection({
    multiRowSelectedEventRef,
    rowSelectedEventRef,
    rowSelection,
    selectionType
  })

  const { defaultExcelExportParams } = useDefaultExcelExportParams({
    grouping,
    groupDisplayType,
    gridRef,
    exportable,
    columnDefs: columnDefsArr
  })

  const { excelStyles } = useExcelExportStyles({
    gridRef,
    exportable,
    fieldConfigs: memoizedFieldConfigs,
    formattedFields
  })

  const aggFuncs: { aggFuncs: GridOptions<IRowData>['aggFuncs'] } = useMemo(() => getAggFuncs(), [])

  const { dataTypeDefinitions } = useDataTypeDefinitions({
    editableFields,
    formatValue,
    getFormattedValue,
    fieldConfigs: memoizedFieldConfigs,
    formattedFields
  })

  const { defaultColDef } = useDefaultColDef()

  const { getRowHeight, hasPieColumn, hasGraphColumn } = useGetRowHeight(columnDefsArr, theme)

  const { overlayProps } = useOverlayProps(emptyText)

  const getRowId = useMemo<GetRowIdFunc>(
    () => (params: GetRowIdParams) => params.data[CUSTOM_ROW_INFO][ROW_ID],
    []
  )

  const { groupRowRendererParams } = useGroupRowRendererParams({
    groupDisplayType,
    grouping,
    suppressCount,
    cellDisplayRules,
    configColumns: configColumnsArr,
    fieldConfigs: memoizedFieldConfigs,
    handleActionBtnClick,
    editableFields
  })

  const { postProcessPopup } = usePostProcessPopup()

  const { enableAdvancedFilterProps } = useEnableAdvancedFilter({
    filtering,
    enableAdvancedFilter,
    isPaginationEnabled
  })

  const isGroupOpenByDefaultProps = useIsGroupOpenByDefaultProps({ grouping, initialState })

  const getRowClass = useCallback((params) => {
    if (params.node.footer) {
      const themeKey = params.context.theme as Theme
      return themeStyles[themeKey]
    }
    return undefined
  }, [])

  const processUnpinnedColumns = useCallback(() => [], [])

  const localeTextProps = useMemo<{ localeText: LocaleText }>(() => {
    return {
      localeText:
        culture === 'tr-TR'
          ? { ...AG_GRID_LOCALE_TR, ...AG_GRID_LOCALE_CUSTOM_TR }
          : { ...AG_GRID_LOCALE_EN, ...AG_GRID_LOCALE_CUSTOM_EN }
    }
  }, [culture])

  const { autoGroupColumnDef } = useAutoGroupColumnDef({
    gridRef,
    fieldConfigs: memoizedFieldConfigs,
    suppressCount,
    configColumns: configColumnsArr,
    cellDisplayRules,
    handleActionBtnClick,
    editableFields,
    rowDisplayRules,
    grouping,
    groupDisplayType
  })

  const gridProps: GridOptions<IRowData> & {
    ref: typeof gridRef
  } = {
    ...aggFuncs,
    ...constantGridProps,
    ...defaultExcelExportParams,
    ...excelStyles,
    ...enableAdvancedFilterProps,
    ...groupRowRendererParams,
    ...getRowHeight,
    ...isGroupOpenByDefaultProps,
    ...overlayProps,
    ...restGridConfig,
    ...localeTextProps,
    autoGroupColumnDef,
    ref: gridRef,
    context: DEFAULT_CONTEXT,
    columnDefs,
    defaultColDef,
    dataTypeDefinitions,
    initialState,
    popupParent,
    rowData,
    getRowId,
    getRowClass,
    getMainMenuItems,
    getContextMenuItems,
    onFilterChanged,
    onCellClicked,
    onCellEditRequest,
    onCellEditingStarted,
    onCellDoubleClicked,
    processUnpinnedColumns,
    onColumnRowGroupChanged,
    onGridReady,
    onStateUpdated,
    onModelUpdated,
    onRowSelected,
    onSelectionChanged,
    onSortChanged,
    postProcessPopup
  }

  const isFooterBarEnabled = Boolean(
    (editingEnabledByConfig && editingType === 'SaveButton') ||
      (editingEnabledByConfig &&
        adding?.enabled &&
        (editingType === 'SaveButton' || adding?.addingType === 'Direct')) ||
      dataDetails?.enabled ||
      footerButtons?.length ||
      exportable
  )

  const barsTotalHeight = getBarsTotalHeight({ isFooterBarEnabled, isPaginationEnabled, theme })

  const agGridHeight = `calc(100% - ${barsTotalHeight})`

  const size = theme === 'balham' ? 'xs' : 'sm'

  return (
    <div
      className={cx(
        headerStyles.slvyGridHeader,
        { [rowStyles.slvyGroupIcon]: groupDisplayType === 'singleColumn' },
        'h-100'
      )}
    >
      <div
        className={cx('w-100', 'position-relative', `ag-theme-${theme}`, {
          'has-pie-column': hasPieColumn,
          'has-graph-column': hasGraphColumn
        })}
        style={{ height: agGridHeight }}
      >
        <AgGridReact<IRowData> {...gridProps} />
        {/*
         Below loading added, because below link did not display loading while data refresh by clicking item on the grid
         https://dragon.solvoyo.com/Configuration/catalog/5ac7077269822c0f18e93bf1/store/1/menu/5f1e8ba6f21cf000c06d1610/page/660177eeba1bea3545978719
        */}
        {isPluginDataLoading ? (
          <SlvySpinner containerClass="bg-opacity-10 bg-dark" size="sm" />
        ) : null}
      </div>
      {isPaginationEnabled ? (
        <Pagination
          currentPage={currentPage}
          doesDirectUpdate={doesDirectUpdate}
          gridRef={gridRef}
          refreshData={refreshData}
          setCurrentPage={setCurrentPage}
          theme={theme}
          {...(paginationProps as PaginationPropsReturnTypesRequired)}
        />
      ) : null}
      {isFooterBarEnabled ? (
        <Footer
          adding={adding}
          dataDetails={dataDetails}
          editing={editing}
          exportable={exportable}
          filteredRowCount={filteredRowCount}
          firstRowData={firstRowData}
          footerButtons={footerButtons}
          footerButtonsRef={footerButtonsRef}
          gridEditableByDefault={gridEditableByDefault}
          gridEditableByState={gridEditableByState}
          gridRef={gridRef}
          handleAddRecord={handleAddRecord}
          handleReset={handleReset}
          height={footerBarHeight[theme]}
          isThereUnsavedChanges={isThereUnsavedChanges}
          noColorFooterButtons={noColorFooterButtons}
          pluginName={name}
          theme={theme}
          totalRowCount={totalRowCount}
          triggerSave={triggerSave}
        />
      ) : null}
      {createPortal(<DirtyStates data={editedFields} />, popupParent)}
      {massUpdateModal?.isVisible ? (
        <MassUpdateModal
          ref={gridRef}
          activeField={massUpdateModal?.activeField}
          applyUpdate={applyUpdate}
          dataTypeDefinitions={dataTypeDefinitions}
          isVisible={massUpdateModal?.isVisible}
          mainGridContext={context?.current}
          mainGridFirstRowData={firstRowData}
          setEditedFields={setEditedFields}
          onCancel={onMassUpdateCancelled}
          onClose={onMassUpdateCompleted}
        />
      ) : null}
      {isGridReadOnly ? (
        <SlvySpinner asOverlay containerClass="bg-opacity-10 bg-dark" size={size} />
      ) : null}
    </div>
  )
}

export default AgGrid
