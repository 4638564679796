import React from 'react'
import { render, screen, fireEvent, waitFor } from '../../../utils/testUtils/dp-test-utils'

import CoefButtons from './CoefButtons'
import { ICoefButtonsProps } from './CoefButtons.types'

const getDefaultProps = (): ICoefButtonsProps => {
  return {
    coef: {
      buttons: [
        {
          spName: 'spName',
          icon: 'fa fa-sync',
          successMessage: 'success',
          explanation: 'explanation'
        }
      ],
      header: 'header',
      confirmationMessage: 'confirm',
      cancelButton: 'cancel',
      confirmButton: 'confirm'
    },
    leftKeys: ['Accessory'],
    actionCall: jest.fn()
  }
}

describe('CoefButtons', () => {
  let props = getDefaultProps()

  beforeEach(() => {
    props = getDefaultProps()
  })

  it('renders without crash', () => {
    render(<CoefButtons {...props} />)
    const element = screen.getByTestId('coef-buttons')
    expect(element).toBeInTheDocument()
  })

  it('tooltip should be visible on hovering icon', async () => {
    render(<CoefButtons {...props} />)

    const element = screen.getByTestId('coef-button-0')
    fireEvent.mouseEnter(element)

    await waitFor(() => {
      expect(screen.getByTestId('coef-tooltip-0')).toBeVisible()
    })
  })

  it('tooltip should be explanation if explanation is not empty', async () => {
    render(<CoefButtons {...props} />)

    const tooltip = props.coef.buttons[0].explanation
    const element = screen.getByTestId('coef-button-0')
    fireEvent.mouseEnter(element)

    await waitFor(() => {
      expect(screen.getByText(tooltip)).toBeInTheDocument()
    })
  })

  it('tooltip should be spName if spName is not empty and explanation is empty', async () => {
    props.coef.buttons[0].explanation = ''
    render(<CoefButtons {...props} />)

    const tooltip = props.coef.buttons[0].spName
    const element = screen.getByTestId('coef-button-0')
    fireEvent.mouseEnter(element)

    await waitFor(() => {
      expect(screen.getByText(tooltip)).toBeInTheDocument()
    })
  })

  it('tooltip should be Coef {index} if there is no explanation and spName', async () => {
    props.coef.buttons[0].explanation = ''
    props.coef.buttons[0].spName = ''
    render(<CoefButtons {...props} />)

    const tooltip = 'Coef 1'
    const element = screen.getByTestId('coef-button-0')
    fireEvent.mouseEnter(element)

    await waitFor(() => {
      expect(screen.getByText(tooltip)).toBeInTheDocument()
    })
  })

  // it('opens modal when clicks icon', () => {})
  // it('header should be modal title', () => {})
  // it('confirmationMessage should be modal body', () => {})
  // it('cancelButton and confirmButton should be on modal', () => {})
  // it('close modal when clicks cancel button', () => {})
})
