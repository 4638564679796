import { useCallback } from 'react'

import {
  HandleActionBtnClick,
  HandleActionBtnClickParams,
  HandleActionBtnClickProps
} from './useHandleActionBtnClick.types'

function useHandleActionBtnClick(props: HandleActionBtnClickProps) {
  const { actionButtonsRef } = props
  const handleActionBtnClick: HandleActionBtnClick = useCallback(
    ({ actionName, data, rowIndex }: HandleActionBtnClickParams) => {
      actionButtonsRef?.current?.[`handleActionClick_${actionName}`]?.(data, rowIndex)
    },
    [actionButtonsRef]
  )
  return { handleActionBtnClick }
}

export default useHandleActionBtnClick
