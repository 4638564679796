Ext.define('clustering.Model.StoreModel', {
  extend: 'Ext.data.Model',
  idProperty: 'Id',
  fields: [
    { name: 'ClusterID' },
    { name: 'Id' },
    { name: 'Latitude', type: 'float' },
    { name: 'Longitude', type: 'float' },
    { name: 'Name' },
    { name: 'SquareMeter', type: 'float' },
    { name: 'TotalRevenue', type: 'float' },
    { name: 'TotalSales', type: 'float' },
    { name: 'X', type: 'float' },
    { name: 'Y', type: 'float' },
    { name: 'Z', type: 'float' },
    { name: 'OpenDate' },
    { name: 'CloseDate' },
    { name: 'Selected', type: 'bool' },
    { name: 'City' },
    { name: 'State' },
    { name: 'Country' },
    { name: 'Type' },
    { name: 'Region' },
    { name: 'Status' }
  ],
  listeners: {
    beforeload: function (store) {
      var proxy = store.getProxy()
      proxy.setExtraParam('catalogId', clustering.Global.getCatalogId())
    }
  }
})
