import { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Dropdown, Overlay } from 'react-bootstrap'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import { useTheme } from '../../contexts/ThemeContext'
import ColumnSelect from './ColumnSelect'
import TextFilter from './TextFilter'
import NumberFilter from './NumberFilter'
import DateFilter from './DateFilter'
import ColumnMenuItem from './ColumnMenuItem'
import { ColumnMenuProps } from './ColumnMenu.types'

// Has ability to trigger dropdown on hover

const defaultColumnMenuItems = (columns) => [
  {
    name: 'Sort Ascending',
    icon: 'fa fa-fw fa fa-sort-alpha-up',
    disabled: false,
    onClick: () => {}
  },
  {
    name: 'Sort Descending',
    icon: 'fa fa-fw fa fa-sort-alpha-down',
    disabled: false,
    onClick: () => {}
  },
  'separator',
  {
    name: 'Columns',
    icon: 'fa fa-fw fa fa-columns',
    disabled: false,
    subMenu: [],
    onClick: () => {}
  },
  'separator',
  {
    name: 'Filters',
    icon: 'fa fa-fw fa fa-square',
    disabled: false,
    subMenu: []
  }
]

function EnhancedDropdown({ show, trigger = 'click', drop, children, ...props }) {
  const [internalShow, setInternalShow] = useState(false)
  const dropdownRef = useRef()

  const handleClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target.offsetParent)) {
      setInternalShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  const handlers =
    trigger === 'click'
      ? {
          onClick: (event) => {
            event.stopPropagation()
            setInternalShow(!internalShow)
          }
        }
      : {
          onMouseOver: () => setInternalShow(true),
          onMouseOut: () => setInternalShow(false)
        }

  return (
    <Dropdown
      drop={drop}
      show={internalShow}
      // onToggle={(show, meta) => {
      //   if (meta.source === 'rootClose' || (meta.source === 'click' && internalShow)) {
      //     setInternalShow(false)
      //   }
      // }}
      // autoClose={true}
      {...handlers}
      onKeyDown={() => {}}
      {...props}
    >
      {children(dropdownRef)}
    </Dropdown>
  )
}

function getFilterComponent(column, remotePaging, pagingProps) {
  if (
    column?.columnDef?.meta?.dataType === 'int' ||
    column?.columnDef?.meta?.dataType === 'double'
  ) {
    return [
      {
        render: () => (
          <NumberFilter
            key="number-filter"
            format={column?.columnDef?.meta?.format}
            getValue={
              remotePaging
                ? (operator) => pagingProps.getFilterValue(column, { type: 'number', operator })
                : column.getFilterValue
            }
            onChange={
              remotePaging
                ? ({ value, comparer }) => {
                    pagingProps.setFilterValue(column, value, comparer)
                  }
                : column.setFilterValue
            }
          />
        )
      }
    ]
  }

  const filterValue = column?.getFilterValue()
  if (column?.columnDef?.meta?.dataType === 'datetime') {
    return [
      {
        icon: column.getFilterValue()?.before
          ? 'fa fa-fw fa fa-check-square'
          : 'fa fa-fw fa fa-square',
        name: 'Before',
        subMenu: [
          {
            render: () => (
              <DateFilter
                key="date-filter-before"
                range="before"
                format={column?.columnDef?.meta?.format}
                getValue={() => {
                  const { on, ...rest } = column.getFilterValue() ?? {}
                  return rest
                }}
                onSelect={column.setFilterValue}
              />
            )
          }
        ]
      },
      {
        icon: column.getFilterValue()?.after
          ? 'fa fa-fw fa fa-check-square'
          : 'fa fa-fw fa fa-square',
        name: 'After',
        subMenu: [
          {
            render: () => (
              <DateFilter
                key="date-filter-after"
                range="after"
                format={column?.columnDef?.meta?.format}
                getValue={() => {
                  const { on, ...rest } = column.getFilterValue() ?? {}
                  return rest
                }}
                onSelect={column.setFilterValue}
              />
            )
          }
        ]
      },
      'separator',
      {
        icon: column.getFilterValue()?.on ? 'fa fa-fw fa fa-check-square' : 'fa fa-fw fa fa-square',
        name: 'On',
        subMenu: [
          {
            render: () => (
              <DateFilter
                key="date-filter-on"
                range="on"
                format={column?.columnDef?.meta?.format}
                getValue={() => {}}
                onSelect={column.setFilterValue}
              />
            )
          }
        ]
      }
    ]
  }

  return [
    {
      render: () => (
        <TextFilter
          key="text-filter"
          getValue={remotePaging ? () => pagingProps.getFilterValue(column) : column.getFilterValue}
          onChange={
            remotePaging
              ? (value) => pagingProps.setFilterValue(column, value, 'like')
              : column.setFilterValue
          }
        />
      )
    }
  ]
}

export default function ColumnMenu({
  isColumnHeader,
  drop = 'down',
  items,
  toggler,
  trigger,
  isSubMenu,
  columns,
  column,
  table,
  grouping,
  remotePaging,
  pagingProps,
  visibleColumnMenuOptions
}: ColumnMenuProps) {
  const [columnMenus, setColumnMenus] = useState([])
  const styles = useTheme()

  useEffect(() => {
    const generalSettings = [
      {
        name: 'Sort Ascending',
        icon: 'fa fa-fw fa fa-sort-alpha-up',
        disabled: false,
        onClick: () => {
          column.toggleSorting(false)
        }
      },
      {
        name: 'Sort Descending',
        icon: 'fa fa-fw fa fa-sort-alpha-down',
        disabled: false,
        onClick: () => column.toggleSorting(true)
      }
    ]
    const columnSettings = [
      {
        name: 'Columns',
        icon: 'fa fa-fw fa fa-columns',
        disabled: false,
        subMenu: [
          {
            render: (columns) => <ColumnSelect key="columns" columns={table.getAllLeafColumns()} />
          }
        ],
        onClick: () => {}
      }
    ]
    const filterSettings = [
      {
        name: 'Filters',
        icon: column?.getFilterValue() ? 'fa fa-fw fa fa-check-square' : 'fa fa-fw fa fa-square',
        disabled: false,
        subMenu: getFilterComponent(column, remotePaging, pagingProps),
        onClick: () => column.setFilterValue('')
      }
    ]
    const groupingSettings = grouping
      ? [
          {
            name: column.getIsGrouped() ? 'Ungroup' : 'Group by this field',
            icon: 'fa fa-object-group',
            onClick: () => {
              const groupedColumn = column.getIsGrouped() ? [] : [column.id]
              table.setGrouping(() => groupedColumn)
            }
          }
        ]
      : []

    if (isSubMenu) {
      setColumnMenus(items)
      return
    }

    if (!visibleColumnMenuOptions?.length) {
      setColumnMenus(
        [
          items,
          'separator',
          columnSettings,
          'separator',
          filterSettings,
          'separator',
          groupingSettings
        ].reduce(
          (accumulator, current) => (isEmpty(current) ? accumulator : accumulator.concat(current)),
          generalSettings
        )
      )

      return
    }

    const columnMenuOptions = [
      { name: 'generalSettings', options: generalSettings },
      { name: 'columnSettings', options: columnSettings },
      { name: 'filterSettings', options: filterSettings },
      { name: 'groupingSettings', options: groupingSettings }
    ]

    const selectedColumnMenus = visibleColumnMenuOptions.reduce((acc, curr) => {
      const selected = columnMenuOptions.find(({ name }) => curr === name)?.options
      if (!selected) {
        return acc
      }
      return acc.concat(selected, 'separator')
    }, items ?? [])

    setColumnMenus(selectedColumnMenus)
  }, [JSON.stringify(items), JSON.stringify(column?.getFilterValue()), column?.getIsGrouped()])

  const defaultToggler = (
    <i className={cx('fa fa-fw fa fa-caret-down', styles.columnMenuToggleIcon)} />
  )

  return (
    <EnhancedDropdown
      className={cx(styles.columnMenuContainer, { [styles.isSubMenu]: isSubMenu })}
      drop={drop}
      trigger={trigger}
    >
      {(dropdownRef) => (
        <>
          <Dropdown.Toggle className={styles.columnMenuToggle}>
            {toggler ? toggler : defaultToggler}
            {isSubMenu ? <i className="fa fa-fw fa fa-caret-right" /> : null}
          </Dropdown.Toggle>
          {isSubMenu ? (
            <Dropdown.Menu ref={dropdownRef} as="ul" className={styles.columnMenu}>
              {columnMenus?.map((item, index) =>
                item?.render ? (
                  item?.render(columns)
                ) : (
                  <ColumnMenuItem
                    columns={columns}
                    key={index}
                    name={item?.name}
                    icon={item?.icon}
                    subMenu={item?.subMenu}
                    isSeparator={item === 'separator'}
                    disabled={item?.disabled}
                    onClick={item?.onClick}
                  />
                )
              )}
            </Dropdown.Menu>
          ) : (
            createPortal(
              <Dropdown.Menu ref={dropdownRef} as="ul" className={styles.columnMenu}>
                {columnMenus?.map((item, index) =>
                  item?.render ? (
                    item?.render(columns)
                  ) : (
                    <ColumnMenuItem
                      columns={columns}
                      key={index}
                      name={item?.name}
                      icon={item?.icon}
                      subMenu={item?.subMenu}
                      isSeparator={item === 'separator'}
                      disabled={item?.disabled}
                      onClick={item?.onClick}
                    />
                  )
                )}
              </Dropdown.Menu>,
              document.body
            )
          )}
        </>
      )}
    </EnhancedDropdown>
  )
}
