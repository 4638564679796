import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import _ from 'lodash'
import { Row, Col, Nav, Navbar, Form, Container, OverlayTrigger } from 'react-bootstrap'
import { SlvyProgress, CatalogBox, Permission, SlvyLogo } from '../../components'
import Logout from '../../components/NavigationBar/Logout'
import { tooltip } from '@/components/NavigationBar/helpers'
import userManager from '../../utils/userManager'
import { getCatalogs } from '@/actions/catalog'
import { selectCollection } from '@/crudoptV3'
import { getEnvironments } from '@/helpers/Permission'
import './index.scss'

class Catalog extends Component {
  constructor(props) {
    super(props)
    this.state = { inputValue: '', filteredData: [], showLogout: false }

    this.handleLogout = this.handleLogout.bind(this)
    this.handleShowLogout = this.handleShowLogout.bind(this)
    this.handleHideLogout = this.handleHideLogout.bind(this)
  }

  handleShowLogout(event) {
    event.preventDefault()
    this.setState({ showLogout: true })
  }

  handleHideLogout() {
    this.setState({ showLogout: false })
  }

  handleLogout() {
    userManager.signoutRedirect({ id_token_hint: this.props.id_token })
  }

  inputHandle(event) {
    const {
      params: { environment },
      catalogs: { data = [] } = {}
    } = this.props

    const inputValue = event.currentTarget.value.toLowerCase()
    const filteredData = data.filter(
      ({ description, name, customerName }) =>
        description.toLowerCase().indexOf(inputValue) > -1 ||
        name.toLowerCase().indexOf(inputValue) > -1 ||
        customerName.toLowerCase().indexOf(inputValue) > -1
    )

    if (event.key === 'Enter' && filteredData.length) {
      this.props.navigate(`/${environment}/catalog/${filteredData[0].id}`)
    }
    this.setState({ inputValue, filteredData })
  }

  componentDidMount() {
    if (this.props.catalogs.needFetch) {
      this.props.dispatch(this.props.catalogs.fetch)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.catalogs.needFetch) {
      this.props.dispatch(nextProps.catalogs.fetch)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        catalogs: { data: currentCatalogData = [] }
      },
      state: { inputValue: value }
    } = this
    const {
      catalogs: { data: prevCatalogData = [] }
    } = prevProps

    if (!_.isEqual(currentCatalogData, prevCatalogData)) {
      this.inputHandle({
        currentTarget: {
          value
        },
        key: null
      })
    }
  }

  handleClick(environment) {
    this.props.navigate(`/${environment}`)
  }

  onSearchSubmit(event) {
    event.preventDefault()
  }

  render() {
    const {
      props: {
        catalogs: { pending, data = [] } = {},
        params: { environment = 'Live' },
        environments
      },
      state: { inputValue, filteredData, showLogout }
    } = this

    const orderedData = _.orderBy(data, (item) => item.name.toLowerCase())

    const orderFilteredData = _.orderBy(filteredData, (item) => item.name.toLowerCase())

    if (
      orderedData &&
      orderedData.length === 1 &&
      environments.length === 1 &&
      environment === 'Live'
    ) {
      const catalogInfo = orderedData[0]
      this.props.navigate(`/${environment}/catalog/${catalogInfo.id}`)
    }

    return (
      <>
        <Navbar collapseOnSelect className="p-0 bg-solvoyo" expand="lg" variant="dark">
          <Container fluid className="p-2 p-xl-0">
            <Navbar.Brand className="me-xl-5 ps-xl-3 pe-xl-3 pe-0 ps-0">
              <Link to="/">
                <SlvyLogo size="sm" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Form className="me-xl-3" onSubmit={this.onSearchSubmit.bind(this)}>
                <Form.Control
                  autoFocus
                  className="my-2 my-xl-0"
                  placeholder="Search"
                  size="sm"
                  type="text"
                  autoComplete="off"
                  onKeyUp={this.inputHandle.bind(this)}
                />
              </Form>
              <Nav className="me-auto" variant="pills">
                {environments.length > 1 &&
                  environments.map((env) => (
                    <Nav.Link
                      key={env}
                      className={cx('navbar-list-item', 'px-3', {
                        active: _.camelCase(env) === _.camelCase(environment)
                      })}
                      eventKey={env}
                      type="button"
                      onClick={this.handleClick.bind(this, env)}
                    >
                      {env}
                    </Nav.Link>
                  ))}
              </Nav>
              <Nav className="d-flex align-items-stretch align-items-xl-center">
                <OverlayTrigger overlay={tooltip('Log Out')} placement="bottom">
                  <Nav.Item
                    className="navbar-list-item px-3 py-2 cp"
                    onClick={this.handleShowLogout}
                  >
                    <Nav.Link as="span" className={cx('p-0', { active: showLogout })}>
                      <i className="fa fa-sign-out fa-lg mt-1" />
                      <span className="ms-2 d-xl-none">Log Out</span>
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="wrapper wrapper-content animated fadeInRight center">
          <SlvyProgress isLoading={pending}>
            <Row className="equal">
              <Permission has={['Catalog.Add']}>
                <Col lg={3} md={4} sm={6} xl={3} xxl={2}>
                  <CatalogBox environment={environment} />
                </Col>
              </Permission>
              {_.map(
                inputValue.length ? orderFilteredData : orderedData,
                ({
                  id,
                  name,
                  description,
                  customerName,
                  customerId,
                  customerLogoUri,
                  archiveDate
                }) => {
                  return (
                    <Col key={id} lg={3} md={4} sm={6} xl={3} xxl={2}>
                      <CatalogBox
                        archiveDate={archiveDate}
                        customer={customerName}
                        customerId={customerId}
                        customerLogoUri={customerLogoUri}
                        description={description}
                        environment={environment}
                        id={id}
                        name={name}
                      />
                    </Col>
                  )
                }
              )}
            </Row>
          </SlvyProgress>
        </div>
        <Logout show={showLogout} onHide={this.handleHideLogout} onLogout={this.handleLogout} />
      </>
    )
  }
}

export default connect((state) => {
  const user = state?.oidc?.user || ''

  return {
    id_token: user?.id_token || '',
    environments: getEnvironments(user),
    catalogs: selectCollection(getCatalogs(), state.model3)
  }
})(Catalog)
