/* global Ext */
Ext.override(Ext.exporter.file.ooxml.excel.Stylesheet, {
  getStyleParams: function (style) {
    var me = this,
      s = style && style.isStyle ? style : new Ext.exporter.file.Style(style),
      cfg = s.getConfig(),
      numFmtId = 0,
      fontId = 0,
      fillId = 0,
      borderId = 0,
      xfId = 0

    cfg.parentId = style ? style.parentId : null
    if (cfg.font) {
      fontId = me.addFont(cfg.font)
    }

    if (cfg.format) {
      numFmtId = me.getNumberFormatId(cfg.format)
    }

    if (cfg.interior) {
      fillId = me.addFill(cfg.interior)
    }

    if (cfg.borders) {
      borderId = me.getBorderId(cfg.borders)
    }

    if (cfg.parentId) {
      xfId = cfg.parentId
    }

    // ***************** Solvoyo Added Code *****************
    // Even if the number format is zero, it override the number format of the child style
    // Therefore ignore it when it is zero
    if (numFmtId === 0) {
      return {
        fontId: fontId,
        fillId: fillId,
        borderId: borderId,
        xfId: xfId,
        alignment: cfg.alignment || null
      }
    }
    // *******************************************************

    return {
      numFmtId: numFmtId,
      fontId: fontId,
      fillId: fillId,
      borderId: borderId,
      xfId: xfId,
      alignment: cfg.alignment || null
    }
  }
})
