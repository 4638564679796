import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { OverlayTrigger, Button } from 'react-bootstrap'
import AssortmentDropdown from '../Dropdown'
import dependencyFilters from '../../utils/dependencyFilters'
import { slvyToast } from '../../../../components'
import { getInvalidFields, makeRequest, mapOptions } from '../../utils'
import AssortmentPopover from '../Popover'
import { setLoaderReducer } from '../../store/slices/appSlice'

class AssortmentMainFilters extends React.Component {
  constructor(props) {
    super(props)

    const {
      props: { pluginId = '' }
    } = this

    this.filters = this.getInitialFilters(_.cloneDeep(dependencyFilters))

    this.state = {
      filters: this.getInitialState(),
      isTouched: false,
      isValid: false
    }

    this.SeasonCode = null
    this.SeasonGroup = null

    this.defaultActionParams = {
      payload: {
        pluginId,
        method: 'Filter',
        dropdownKey: '', // Brand
        requestMethod: 'post',
        body: {
          query: '',
          Name: '', // Brand
          page: 1,
          start: 0,
          limit: 25
        }
      }
    }
  }

  getInitialFilters(dependencyFilters) {
    const { isAllRequired = false, isTestScreen = false } = this.props
    return dependencyFilters.map((item) => {
      item.IsMultiple = isTestScreen && item.Key === 'SeasonCode' ? false : item.IsMultiple
      item.IsGrouped = isTestScreen && item.Key === 'SeasonCode' ? false : item.IsGrouped
      item.Info = isTestScreen && item.Key === 'SeasonCode' ? null : item.Info
      item.IsRequired = isAllRequired || item.IsRequired
      return item
    })
  }

  getInitialState() {
    const { isTestScreen = false } = this.props
    const { filters = [] } = this
    const stateFilters = {}

    filters.forEach(
      ({ Key, IsRequired, Index, IsMultiple, Info, IsGrouped, LimitedAt, IsDisabled }) => {
        stateFilters[Key] = {
          Index,
          Key,
          IsMultiple: isTestScreen && Key === 'SeasonCode' ? false : IsMultiple,
          IsGrouped: isTestScreen && Key === 'SeasonCode' ? false : IsGrouped,
          Info: isTestScreen && Key === 'SeasonCode' ? null : Info,
          LimitedAt,
          IsDisabled,
          IsRequired,
          options: [],
          groups: [],
          selected: null,
          selectedGroup: null,
          dependencyFilters: []
        }
      }
    )
    return stateFilters
  }

  componentDidMount() {
    const { onAnyFilterChanged = () => {}, firstAutoLoad = false } = this.props

    if (firstAutoLoad) {
      this.fetchDropdownByKey(this.filters[0].Key, null, false).then(
        onAnyFilterChanged(_.cloneDeep(this.state.filters))
      )
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { applyValidate = false, defaultSelectedFilters = {} } = this.props
    const {
      applyValidate: _applyValidate = false,
      defaultSelectedFilters: _defaultSelectedFilters = {}
    } = nextProps

    if (!_.isEqual(defaultSelectedFilters, _defaultSelectedFilters)) {
      this.resetFilters(() => {
        this.setDefaultSelectedFilters(_defaultSelectedFilters)
      })
    }

    if (applyValidate !== _applyValidate && _applyValidate) {
      this.onApplyFilter()
    }
  }

  resetFilters(callback = () => {}) {
    this.filters = this.getInitialFilters(_.cloneDeep(dependencyFilters))
    this.setState({ filters: this.getInitialState() }, callback)
  }

  setDefaultSelectedFilters(defaultSelectedFilters = {}) {
    const selectedFilterArr = []
    const clonedFilters = _.cloneDeep(this.state.filters)

    Object.keys(defaultSelectedFilters).forEach((item, index) => {
      const { IsDefault } = defaultSelectedFilters[item]
      const $defaultValue = defaultSelectedFilters[item].Value
      const nullCount = selectedFilterArr.length
        ? [...selectedFilterArr].filter((selectedFilter) => {
            return !this.hasSelected(selectedFilter.Value)
          }).length
        : 0

      // at least only one null needed because next fetch dropdown
      if (nullCount < 2 || item === 'SeasonCode') {
        selectedFilterArr.push({
          Key: item,
          Value: $defaultValue,
          IsDefault
        })
      }

      if (item === 'SeasonCode') {
        this.SeasonCode = $defaultValue
        this.DefaultSeasonCode = $defaultValue
      }

      if (IsDefault) {
        const $isDisabled = !(item === 'SeasonCode' && this.hasMultipleSelectedValue($defaultValue))
        this.filters = _.cloneDeep(this.filters).map((_filter) => {
          if (_filter.Key === item) {
            _filter.IsDisabled = $isDisabled
          }
          return _filter
        })

        if (Object.keys(defaultSelectedFilters).length === index + 1) {
          // is it last
          Object.keys(clonedFilters).forEach((keyItem) => {
            clonedFilters[keyItem].IsDisabled = $isDisabled
          })

          this.setState(
            {
              filters: { ...clonedFilters }
            },
            () => {
              this.runAutoLoad(selectedFilterArr)
            }
          )
        }
      }
    })
  }

  hasMultipleSelectedValue($val) {
    return _.isArray($val) && $val.length > 1
  }

  isMultipleHasValue($val) {
    return _.isArray($val) && $val.length > 0
  }

  runAutoLoad(selectedFilterArr) {
    const { onAnyFilterChanged = () => {} } = this.props
    selectedFilterArr
      .reduce((previousPromise, { Key, Value, IsDefault }) => {
        return previousPromise.then(() => {
          return this.fetchDropdownByKey(Key, Value, IsDefault)
        })
      }, Promise.resolve())
      .then(() => {
        onAnyFilterChanged(_.cloneDeep(this.state.filters))
      })
  }

  checkIsRequired($key, $isAllRequired) {
    return (
      _.cloneDeep(this.filters).filter(({ Key = '', IsRequired = true }) => {
        const check = $isAllRequired || IsRequired
        return Key === $key && check
      }).length > 0
    )
  }

  fetchDropdownByKey($key, $selected, $isDefault) {
    const { setLoader = () => {}, isAllRequired = false } = this.props

    setLoader({ isShown: true, messages: 'Loading Filters' })

    const updateState = (Values, callback) => {
      Values = _.isArray(Values) ? Values : [Values]

      if ($key === 'SeasonCode') {
        $selected = this.getSelectedSeasonCode(Values)
      }

      this.setState(
        {
          filters: {
            ...this.state.filters,
            [$key]: {
              ...this.state.filters[$key],
              IsRequired: this.checkIsRequired($key, isAllRequired),
              selected: $selected,
              options: mapOptions(Values)
            }
          }
        },
        () => {
          this.manageFilters($key, () => {
            this.updateDependenciesWithSeason(this.SeasonCode, () => {
              callback()
              setLoader({ isShown: false })
            })
          })
        }
      )
    }

    return new Promise((resolve, reject) => {
      // no need to ask for request if is default
      if ($isDefault) {
        const $values = $selected
        updateState($values, resolve)
      } else {
        makeRequest({
          payload: {
            ...this.defaultActionParams.payload,
            dropdownKey: $key,
            body: {
              ...this.defaultActionParams.payload.body,
              Name: $key,
              DependencyFilterStr: [..._.cloneDeep(this.state.filters[$key].dependencyFilters)]
            }
          }
        })
          .then((response) => {
            const { body: { Values = [] } = {} } = response
            updateState(Values, resolve)
          })
          .catch(() => {
            reject()
            setLoader({ isShown: false })
          })
      }
    })
  }

  getSeasonGroup(seasonCode, groups) {
    let seasonGroup = null
    if (seasonCode) {
      const group = _.find(groups, { options: [{ value: seasonCode[0] }] })
      if (group) {
        seasonGroup = group.label
      }
    }
    return seasonGroup
  }

  getSeasonGroups(Values) {
    const { seasonGroups = {} } = this.props
    const _seasonGroups = { ...seasonGroups }
    const mappedGroups = []
    Object.keys(_seasonGroups).forEach((item) => {
      mappedGroups.push({
        label: item,
        id: item,
        options: getGroupedOptions(_seasonGroups[item], item)
      })
    })

    function getGroupedOptions(currentGroupOptions, group) {
      const result = [...currentGroupOptions].filter((option) => {
        return [...Values].filter(({ key = '' }) => option === key).length > 0
      })
      if (result.length) {
        return result.map((val) => {
          return {
            key: val,
            value: val,
            groupId: group
          }
        })
      }
      return null
    }

    return mappedGroups.filter((elem) => _.isArray(elem.options) && elem.options.length)
  }

  onFilterChanged(dropdownKey, val, config) {
    const {
      props: { onAnyFilterChanged = () => {} },
      state: {
        filters: {
          SeasonCode: { options: seasonCodeOptions = [], IsGrouped: seasonCodeIsGrouped = false }
        }
      }
    } = this
    const { isMultiple = false } = config || {}
    let { key: selectedKey = '' } = val
    if (isMultiple) {
      selectedKey = val
    }

    if (dropdownKey === 'SeasonCode') {
      this.SeasonCode = selectedKey
      this.SeasonGroup = null
      if (seasonCodeIsGrouped && this.hasSelected(selectedKey)) {
        this.SeasonGroup = this.getSeasonGroup(selectedKey, this.getSeasonGroups(seasonCodeOptions))
      }
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [dropdownKey]: {
            ...this.state.filters[dropdownKey],
            selected: selectedKey,
            selectedGroup: dropdownKey === 'SeasonCode' ? this.SeasonGroup : null
          }
        }
      },
      () => {
        this.manageFilters(dropdownKey, () => {
          this.updateDependenciesWithSeason(this.SeasonCode, () => {
            if (dropdownKey !== 'SeasonCode') {
              if (dropdownKey === 'Range') {
                // get season every filter change
                this.manageSeason().then(() => {
                  // populate container
                  onAnyFilterChanged(_.cloneDeep(this.state.filters))
                })
              } else {
                this.fetchNextDropdown(dropdownKey)
                // get season every filter change
                this.manageSeason().then(() => {
                  // populate container
                  onAnyFilterChanged(_.cloneDeep(this.state.filters))
                })
              }
              // populate container
              onAnyFilterChanged(_.cloneDeep(this.state.filters))
            } else {
              // populate container
              onAnyFilterChanged(_.cloneDeep(this.state.filters))
            }
          })
        })
      }
    )
  }

  updateDependenciesWithSeason(selectedSeasonCode, callback = () => {}) {
    const { isTestScreen = false } = this.props
    const clonedFilters = _.cloneDeep(this.state.filters)
    Object.keys(clonedFilters).forEach((filterName) => {
      const dependencyFilters = [...clonedFilters[filterName].dependencyFilters]
      clonedFilters[filterName] = {
        ...clonedFilters[filterName],
        dependencyFilters: !isTestScreen
          ? dependencyFilters
          : dependencyFilters.map((dependency) => {
              if (dependency.Key === 'SeasonCode') {
                dependency.Value = selectedSeasonCode
              }
              return dependency
            })
      }
    })
    this.setState({ filters: clonedFilters }, callback)
  }

  getSelectedSeasonCode(Values) {
    const { isTestScreen = false } = this.props
    const defaultSeasonCode = this.DefaultSeasonCode
    const isExist = this.isExistingSeason(Values)
    const canMultiple = this.isMultipleHasValue(defaultSeasonCode)

    if (isTestScreen && isExist && canMultiple) {
      if (defaultSeasonCode.length === 1) {
        return defaultSeasonCode[0]
      }
    }

    return null
  }

  manageSeason() {
    const { setLoader = () => {}, isTestScreen = false } = this.props
    const { filters = {} } = this.state

    const _dependencyFilters = []
    Object.keys(filters).forEach((filterItem) => {
      const $currentSelected = filters[filterItem].selected
      if (this.hasSelected($currentSelected)) {
        _dependencyFilters.push({
          Key: filterItem,
          Value: filterItem === 'SeasonCode' ? null : [$currentSelected]
        })
      }
    })

    const seasonCodeIsGrouped = this.state.filters.SeasonCode.IsGrouped

    setLoader({ isShown: true, messages: 'Loading Filters' })

    return new Promise((resolve, reject) => {
      makeRequest({
        payload: {
          ...this.defaultActionParams.payload,
          dropdownKey: 'SeasonCode',
          body: {
            ...this.defaultActionParams.payload.body,
            Name: 'SeasonCode',
            DependencyFilterStr: _dependencyFilters
          }
        }
      })
        .then((response) => {
          let { body: { Values = [] } = {} } = response

          if (isTestScreen && this.hasMultipleSelectedValue(this.DefaultSeasonCode)) {
            const $selected = this.DefaultSeasonCode // TODO
            Values = _.intersectionWith(Values, $selected, _.isEqual)
          }

          const extraGroupedProps = {}
          if (seasonCodeIsGrouped) {
            const $seasonGroups = [...this.getSeasonGroups(mapOptions(Values))]
            extraGroupedProps.selectedGroup = this.getSeasonGroup(
              this.getSelectedSeasonCode(Values),
              $seasonGroups
            )
            extraGroupedProps.groups = $seasonGroups
          }

          this.setState(
            {
              filters: {
                ...this.state.filters,
                SeasonCode: {
                  ...this.state.filters.SeasonCode,
                  selected: this.getSelectedSeasonCode(Values),
                  options: mapOptions(Values),
                  selectedGroup: null,
                  groups: null,
                  ...extraGroupedProps
                }
              }
            },
            () => {
              setLoader({ isShown: false })
              resolve()
            }
          )
        })
        .catch(() => {
          setLoader({ isShown: false })
          reject()
        })
    })
  }

  isExistingSeason(seasonCodeOptions) {
    const { isTestScreen = false } = this.props
    if (isTestScreen) {
      const $code = _.isArray(this.SeasonCode) ? this.SeasonCode[0] : this.SeasonCode
      const $result = [...seasonCodeOptions].filter((item) => $code === item).length > 0
      if (!$result) {
        slvyToast.error({
          message: "Assortment Season's selection affected by your last selection!",
          title: 'Please revise your last selection!'
        })
      }
      return $result
    }
    return false
  }

  fetchNextDropdown(dropdownKey) {
    const { setLoader = () => {} } = this.props
    const filters = _.cloneDeep(this.filters)
    const activeIndex = filters.findIndex((item) => item.Key === dropdownKey)

    // is it last item? do not make a call
    if (activeIndex === filters.length - 1) {
      return
    }

    const nextKey = filters[activeIndex + 1].Key

    setLoader({ isShown: true, messages: 'Loading Filters' })

    return new Promise((resolve, reject) => {
      makeRequest({
        payload: {
          ...this.defaultActionParams.payload,
          dropdownKey: nextKey,
          body: {
            ...this.defaultActionParams.payload.body,
            Name: nextKey,
            DependencyFilterStr: [..._.cloneDeep(this.state.filters[nextKey].dependencyFilters)]
          }
        }
      })
        .then((response) => {
          const { body: { Values = [] } = {} } = response

          this.setState(
            {
              filters: {
                ...this.state.filters,
                [nextKey]: {
                  ...this.state.filters[nextKey],
                  selected: null,
                  options: mapOptions(Values),
                  selectedGroup: null,
                  groups: null
                }
              }
            },
            () => {
              setLoader({ isShown: false })
              resolve()
            }
          )
        })
        .catch(() => {
          setLoader({ isShown: false })
          reject()
        })
    })
  }

  manageFilters(dropdownKey, callback) {
    const filters = _.cloneDeep(this.filters)
    const clonedFilters = _.cloneDeep(this.state.filters)
    const activeIndex = filters.findIndex((item) => item.Key === dropdownKey)

    filters.forEach((filterItem, index) => {
      const { Key } = filterItem

      if (index > activeIndex) {
        // reset all next case
        clonedFilters[Key] = {
          ...filterItem,
          dependencyFilters: [],
          options: [],
          selected: null,
          selectedGroup: null,
          groups: []
        }
      }

      // set dependency filters
      clonedFilters[Key] = {
        ...clonedFilters[Key],
        dependencyFilters: [...this.getDependencyFilters(index, Key, clonedFilters)]
      }
    })

    this.setState({ filters: clonedFilters }, callback)
  }

  hasSelected($selected) {
    return (_.isArray($selected) && $selected.length > 0) || (!_.isArray($selected) && $selected)
  }

  getDependencyFilters(mainIndex, currentItem, clonedState) {
    const filters = _.cloneDeep(this.filters)
    const dependencyFilters = []

    filters.forEach(({ Key }, index) => {
      const isFollowingFilters = currentItem === Key || index > mainIndex
      const $currentSelected = clonedState[Key].selected
      if (!isFollowingFilters && this.hasSelected($currentSelected)) {
        dependencyFilters.push({
          Key,
          Value: _.isArray($currentSelected) ? $currentSelected : [$currentSelected]
        })
      }
    })
    return dependencyFilters
  }

  onApplyFilter() {
    const { onValidateCallback = () => {} } = this.props
    const clonedFilters = _.cloneDeep(this.state.filters)
    const invalidFields = getInvalidFields(clonedFilters)
    const isValid = invalidFields.length === 0

    this.setState({
      isTouched: true,
      isValid
    })

    if (!isValid) {
      const element = (
        <>
          {invalidFields.map((item) => (
            <React.Fragment key={item.Key}>
              {item.Key} is required!
              <br />
            </React.Fragment>
          ))}
        </>
      )

      slvyToast.error({ component: element, options: { containerId: 'ap', autoClose: 5000 } })
    }

    onValidateCallback(isValid)
  }

  render() {
    const {
      props: { isVisibleApplyButton = true, isTestScreen = false },
      state: { filters: stateFilters = {}, isTouched = false },
      filters: _filters = []
    } = this

    return (
      <div className="selectOptions simpleContainer">
        <form className="form-horizontal">
          {_filters.map((currentFilter, index) => {
            const { Key: currentFilterKey = '' } = currentFilter
            const stateCurrentFilter = stateFilters[currentFilterKey] || {}
            const {
              options = [],
              groups = [],
              selected = null,
              selectedGroup = null,
              LimitedAt = 0,
              IsDisabled = false,
              IsRequired = false,
              IsMultiple = false,
              IsGrouped = false,
              Info = {}
            } = stateCurrentFilter
            return (
              <div key={index} className="form-group row mb-3">
                <div className="col-md-6">
                  <label className="form-label">
                    {currentFilterKey}
                    {!isTestScreen && IsRequired ? <b>*</b> : ''}
                    {!_.isEmpty(Info) ? (
                      <OverlayTrigger
                        overlay={<AssortmentPopover content={Info.Body} title={Info.Header} />}
                      >
                        <span className="slvy-ui-icon-info" />
                      </OverlayTrigger>
                    ) : null}
                  </label>
                </div>
                <div className="col-md-6">
                  <AssortmentDropdown
                    dropdownKey={currentFilterKey}
                    groups={groups}
                    isDisabled={IsDisabled}
                    isGrouped={IsGrouped}
                    isMultiple={IsMultiple}
                    isRequired={IsRequired}
                    isTouched={isTouched}
                    limitedAt={LimitedAt}
                    options={options}
                    selected={selected}
                    selectedGroup={selectedGroup}
                    onFilterChanged={this.onFilterChanged.bind(this)}
                  />
                </div>
              </div>
            )
          })}
          {isVisibleApplyButton ? (
            <div className="col-md-12">
              <Button
                className="float-end"
                size="sm"
                type="button"
                variant="success"
                onClick={this.onApplyFilter.bind(this)}
              >
                Apply Filter
              </Button>
            </div>
          ) : null}
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    setLoader: bindActionCreators(setLoaderReducer, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(AssortmentMainFilters)
