export default {
  title: 'List',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'FilterList',
  description: 'Put filters into a list!',
  iconClass: 'fa-filter',
  color: 'navy-bg',
  group: 'Filters'
}
