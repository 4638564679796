import cx from 'classnames'
import styles from './SlvySpinner.module.scss'

interface ISlvySpinnerProps {
  asOverlay?: boolean
  containerClass?: string
  size?: string
  testId?: string
}

const SlvySpinner = ({ asOverlay, containerClass, size, testId = '' }: ISlvySpinnerProps) => {
  return (
    <div
      className={cx(
        'd-flex justify-content-center align-items-center',
        containerClass,
        styles.slvySpinner
      )}
      data-testid={testId}
    >
      {asOverlay
        ? null
        : [1, 2, 3].map((item) => {
            return (
              <div
                key={item}
                className={cx('spinner-grow text-success m-1', { [`spinner-grow-${size}`]: size })}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )
          })}
    </div>
  )
}

SlvySpinner.defaultProps = {
  asOverlay: false,
  containerClass: '',
  size: '',
  testId: ''
}

export default SlvySpinner
