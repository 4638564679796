import { useCallback, useMemo } from 'react'
import { useCheckboxSelectionColDef, useDeleteColDef, useGetSubstituteColumnDefs } from '../index'
import { CustomColumnsRowDisplayRules, SlvyMasterColDef } from '../../types'

import { customCheckboxColumnWidth, customDeleteColumnWidth } from '../../constants'
import { getMappedColumnDefs, getCustomColumnsRowDisplayRules } from '../../helpers'
import { GetColumnDefsProps } from './useGetColumnDefs.types'

function useGetColumnDefs(props: GetColumnDefsProps) {
  const {
    cellDisplayRules,
    configColumns,
    deleting,
    editableFields,
    enableCellTextSelection,
    exportable,
    fieldConfigs,
    filtering,
    formattedFields,
    formatValue,
    getCellColorsFromData,
    getFormattedValue,
    gridEditableByDefault,
    grouping,
    groupDisplayType,
    header,
    handleActionBtnClick,
    isPaginationEnabled,
    listFilterCheckbox,
    lockPinned,
    massUpdate,
    selectionType,
    rowDisplayRules,
    theme,
    gridRef
  } = props

  // Get only backColor (as style) for CHECKBOX_SELECTION and DELETE_COLUMN
  const customColumnsRowDisplayRules: CustomColumnsRowDisplayRules = useMemo(() => {
    return getCustomColumnsRowDisplayRules(rowDisplayRules)
  }, [rowDisplayRules])

  const checkboxColumnWidth = customCheckboxColumnWidth[theme]

  const { col: checkboxSelectionColumn } = useCheckboxSelectionColDef({
    width: checkboxColumnWidth,
    massUpdate,
    selectionType,
    rowDisplayRules: customColumnsRowDisplayRules
  })

  const deleteColumnWidth = customDeleteColumnWidth[theme]

  const { col: deleteColumn } = useDeleteColDef({
    gridRef,
    width: deleteColumnWidth,
    deleting,
    rowDisplayRules: customColumnsRowDisplayRules
  })

  const { getSubstituteColumnDefs } = useGetSubstituteColumnDefs({ fieldConfigs })

  const getColumnDefs = useCallback((): SlvyMasterColDef[] | undefined => {
    if (!configColumns) {
      return undefined
    }

    const columns = getMappedColumnDefs(configColumns, {
      cellDisplayRules,
      editableFields,
      enableCellTextSelection,
      exportable,
      fieldConfigs,
      filtering,
      formattedFields,
      formatValue,
      getCellColorsFromData,
      getFormattedValue,
      gridEditableByDefault,
      grouping,
      groupDisplayType,
      handleActionBtnClick,
      header,
      isPaginationEnabled,
      listFilterCheckbox,
      lockPinned,
      massUpdate,
      selectionType,
      rowDisplayRules,
      theme
    })

    const substituteColumns = getSubstituteColumnDefs(columns)

    let result: SlvyMasterColDef[] = []

    if (checkboxSelectionColumn !== null) {
      result = [checkboxSelectionColumn]
    }

    result = [...result, ...columns]

    if (deleteColumn !== null) {
      result = [...result, deleteColumn]
    }

    if (substituteColumns.length) {
      result = [...result, ...substituteColumns]
    }

    return result
  }, [
    configColumns,
    cellDisplayRules,
    editableFields,
    enableCellTextSelection,
    exportable,
    fieldConfigs,
    filtering,
    formattedFields,
    formatValue,
    getCellColorsFromData,
    getFormattedValue,
    gridEditableByDefault,
    grouping,
    groupDisplayType,
    handleActionBtnClick,
    header,
    isPaginationEnabled,
    listFilterCheckbox,
    lockPinned,
    massUpdate,
    selectionType,
    rowDisplayRules,
    theme,
    getSubstituteColumnDefs,
    checkboxSelectionColumn,
    deleteColumn
  ])

  return { getColumnDefs }
}

export default useGetColumnDefs
