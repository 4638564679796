import { memo, useMemo } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import sortByKey from '../../../../../../../utils/sortByKey'
import Events from '../../Events'
import ContainerHeader from '../../ContainerHeader'
import { IRegisterEventMethod, IRegisterEventMethodParams } from '../../../../Common.types'
import { IEventsContainerProps } from './EventsContainer.types'
import { emptyObject } from '../../../../../constants'
import { sortKeyInObject } from '../../../helpers'
import styles from '../../../index.module.scss'

const EventsContainer = ({
  id,
  name,
  type,
  events,
  eventsAssignees,
  handleReturnVariableChange,
  countInfo,
  toggleActives
}: IEventsContainerProps) => {
  let sortedEvents = sortByKey(events) as IRegisterEventMethod
  sortedEvents = sortKeyInObject(sortedEvents, 'params')

  const { active, total } = countInfo
  const title = useMemo(() => <h6>{`${name} (${type})`}</h6>, [name, type])

  return (
    <>
      <Accordion.Header className="mb-0 pb-0">
        <ContainerHeader active={active} title={title} total={total} type={type} />
      </Accordion.Header>
      <Accordion.Body className={cx('p-3', styles.relationAccordionBody)}>
        {_.map(sortedEvents, (event: IRegisterEventMethodParams, eventKey) => {
          const { [eventKey]: eventAssignees = emptyObject } = eventsAssignees
          return (
            <Events
              key={id + eventKey}
              assignees={eventAssignees}
              eventParams={event.params}
              id={id}
              parentKey={eventKey}
              title={eventKey}
              toggleActives={toggleActives}
              onChange={handleReturnVariableChange}
            />
          )
        })}
      </Accordion.Body>
    </>
  )
}

export default memo(EventsContainer)
