import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TuiProps } from '../../Tui.types'

type Auth = {
  accessToken: string
  apiUrl: string
  environment: string
  pluginId: string
}

type TuiState = {
  auth: Auth
  config: TuiProps['config']
  isInitialized: boolean
} & TuiProps

const initialState: TuiState = {
  actualFilters: {},
  auth: {
    accessToken: '',
    apiUrl: '',
    environment: '',
    pluginId: ''
  },
  config: {
    general: {
      name: '',
      header: '',
      maximizable: true
    },
    settings: {
      title: '',
      runParameters: '',
      wsdl: '',
      model: '',
      errorMessage: 'Service is not running. Please contact your system admins.',
      asyncActionStartMessage: 'Operation will be completed soon.',
      asyncActionSuccessMessage: 'Operation completed successfully.',
      asyncActionFailMessage: 'Operation has failed.',
      isServiceSelectionEnabled: true,
      defaultView: 'Map',
      backgroundColor: '#ffffff',
      treeRefreshInterval: 0,
      isTreeFilteringEnabled: true,
      isServicePersistent: true,
      disabledMap: {
        latValue: 39.0405193724839,
        lngValue: 35.29724098742009,
        zoomFactor: 7
      }
    },
    map: {
      tile: '',
      theme: '',
      latValue: 39.0405193724839,
      lngValue: 35.29724098742009,
      zoomFactor: 7
    },
    location: {
      locations: []
    },
    loadTree: {
      columns: [],
      hierarchy: [],
      filters: [],
      footerButtons: []
    },
    demandTree: {
      columns: [],
      hierarchy: [],
      filters: [],
      footerButtons: [],
      totalFields: []
    },
    loadTable: {
      isGroupingEnabled: false,
      columns: []
    },
    loadStopTable: {
      header: '',
      isGroupingEnabled: false,
      columns: []
    },
    loadStopEntryTable: {
      header: '',
      isGroupingEnabled: false,
      columns: []
    },
    units: {
      baseCurrency: '',
      baseDistance: '',
      baseTime: '',
      baseVolume: '',
      baseWeight: '',
      baseLademeter: ''
    }
  },
  createLog: () => undefined,
  getFieldType: () => ({ type: 'string', isMultiple: false }),
  id: '',
  isAllowed: () => false,
  isPreviewMode: false,
  params: {
    environment: 'Live',
    catalogId: '',
    menuId: '',
    pageId: '',
    storeIndex: '',
    designMode: false
  },
  registerAuthorizations: () => undefined,
  registerEvent: ({ fn }) => fn,
  registerMethod: () => undefined,
  schema: {},
  setDataArguments: () => undefined,
  size: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  token: '',
  userName: '',
  isInitialized: false
}

export const tuiSlice = createSlice({
  name: 'tui',
  initialState,
  reducers: {
    initializeTui(state, action: PayloadAction<Pick<TuiState, 'auth' | 'config'>>) {
      state.isInitialized = true
      Object.assign(state, action.payload)
    }
  }
})

export default tuiSlice
export type { TuiState }
