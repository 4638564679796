export default {
  title: 'Sencha Pivot',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'SenchaPivot',
  description: "it's sencha pivot",
  iconClass: 'fa-circle',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Data Manipulation',
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: false
  }
}
