Ext.define('tuiv2.gridview.loadStopTable', {
  extend: 'Ext.grid.Panel',
  xtype: 'tuiv2-loadStopTable',
  alias: 'widget.tuiv2-loadStopTable',
  reference: 'loadStopTable',
  columnLines: true,
  listeners: {
    select: 'onLoadStopTableRowSelect',
    viewready: function (grid) {
      var store = grid.getStore()

      store.on('load', function () {
        grid.getSelectionModel().select(0)
      })
    }
  },
  viewConfig: {
    enableTextSelection: true
  },
  columns: []
})
