export default `({
    fields: [{
            name: 'QTY',
            type: 'number'
        },
        {
            name: 'Price',
            type: 'number'
        },
        {
            name: 'NumberOfStore',
            type: 'number'
        },
        {
            name: 'Envanter',
            type: 'number'
        },
        {
            name: 'DateKey',
            type: 'string'
        },
        {
            name: 'IsSimilarTemp',
            type: 'int',
            convert: function (v) {
                if (typeof v === 'boolean') {
                    return v ? 1 : 0;
                } else {
                    return parseInt(v, 10);
                }
            }
        },
        {
            name: 'PriceNull',
            convert: function (value, record) {
                var v = record.get('Price');
                if (!v) return null
                return v
                // Math.floor(Math.random() * 1000) + 1;
            },
            depends: ['Price']
        },
        {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
                var v = record.get('NumberOfStore');
                if (!v) return null
                return v
            },
            depends: ['NumberOfStore']
        }
    ],
    listeners: {
        load: function (store, records, successful) {
            if (!successful) {
                store.clearData()
            }
                      var data = store.getDatas()
            if (data.length === 0) {
                store.add({
                    id: 1
                })
            }
            store.forceToLoad = false
        },
    }
})
`
