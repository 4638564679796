Ext.define('tuiv2.Units', {
  extend: 'Ext.Base',
  singleton: true,
  config: {
    planInfo: null
  },
  constructor: function (config) {
    var me = this
    me.initConfig(config)
  }
})
