/* global Ext, spui */
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'

/* 3rd-party imports */
import jwtDecode from 'jwt-decode'
import Highcharts from 'highcharts'
import { ExtContainer } from '@sencha/ext-react-classic'

import createPlugin, { PluginTypes } from '@/BasePlugin'
import MongoCollectionExportedData from './MongoConfiguration/index.js'
import { ExtRoot } from '@/components'
import { getExtContainerSize, getPluginSize } from '@/helpers'
import { API_URL } from '@/constants'

import './ext-package/sass/etc/all.scss'
/* INCLUDING SUBMODULE APPLICATIONS */
require('./ext-package')
require('./ext-package/utils')
require('./ext-package/requires/dd')
require('./ext-package/requires/common')
require('./ext-package/requires/wizard')
require('./ext-package/requires/tracking')
require('./ext-package/requires/statusdialogs')
require('./ext-package/requires/main')

class SpotUi extends Component {
  constructor(props) {
    super(props)
    this.state = {
      size: getPluginSize(props.size, props.isPreviewMode)
    }

    if (Ext.Ajax.hasListeners['beforerequest']) {
      Ext.Ajax.clearListeners()
    }

    Ext.define('spui.ExtGlobal', {
      singleton: true,
      config: {
        dragonApiUrl: `${API_URL}/data/${props.id}/invoke/`,
        authToken: props.token,
        pluginId: props.id
      },
      constructor: function (config) {
        const me = this
        me.initConfig(config)
      }
    })

    const that = this

    // TODO: Move this to the global helper file
    Ext.define(null, {
      override: 'Ext.grid.plugin.Exporter',
      saveDocumentAs: function (config) {
        const { userName = null, actualFilters = {}, createLog } = that.props

        config = {
          ...config,
          userName,
          createLog,
          filters: {
            ...config.filters,
            ...actualFilters
          }
        }
        const cmp = this.cmp
        const deferred = new Ext.Deferred()
        const exporter = this.getExporter(config)

        cmp.fireEvent('beforedocumentsave', cmp, { config, exporter })

        this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])

        this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])
        return deferred.promise
      }
    })

    window.Highcharts = Highcharts

    Ext.require('Ext.button.*')
    Ext.require('Ext.calendar.*')
    Ext.require('Ext.exporter.*')
    Ext.require('Ext.form.*')
    Ext.require('Ext.grid.*')
    Ext.require('Ext.panel.*')
    Ext.require('Ext.ux.*')
    Ext.require('Ext.window.Toast')

    Ext.require('Chart.ux.*')
    Ext.Date.shortMonthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    Ext.require('Spui.*')

    const defaultHeaders = Ext.Ajax.getDefaultHeaders() || {}

    if (!defaultHeaders['Authorization']) {
      defaultHeaders['environment'] = props.params.environment
      defaultHeaders['Authorization'] = 'Bearer ' + props.token
      Ext.Ajax.setDefaultHeaders(defaultHeaders)
    }

    Ext.Ajax.addListener('beforerequest', function (conn, options, eOpts) {
      if (options.url.includes('###SPUI_API_URL###')) {
        options.url = options.url.replace(
          '###SPUI_API_URL###',
          `${spui.ExtGlobal.getDragonApiUrl()}`
        )
        options.url = options.url.replace('/spui/', '/')
        options.url = options.url.replace('/localization/', '/')
        options.url = options.url.replace('/create', '_create')
        options.url = options.url.replace('/read', 'read')
        options.url = options.url.replace('/update', '_update')
        options.url = options.url.replace('/destroy', '_destroy')
        options.url = options.url.replace('/upload/logo', '/upload_logo')
      }
    })

    this.createContract = this.createContract.bind(this)
    this.distributionButtonClick = this.distributionButtonClick.bind(this)
  }

  componentDidMount() {
    this.props.reloadExtRoot(this.props.id, () => this.spotDidMount())
  }

  componentWillUnmount() {
    // Prevent duplication of id `slvyExtContainer-${this.props.id}`
    this.removeExtContainer()
  }

  removeExtContainer() {
    const container = document.getElementById(`slvyExtContainer-${this.props.id}`)
    if (container) {
      container.parentElement.removeChild(container)
    }
  }

  spotDidMount() {
    this.createContract = this.props.registerEvent({
      key: 'createContract',
      fn: this.createContract,
      returnTypes: { refreshKey: PluginTypes.string }
    })
    this.distributionButtonClick = this.props.registerEvent({
      key: 'distributionButtonClick',
      fn: this.distributionButtonClick,
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.props.registerAuthorizations(['CanUseSAP'])
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If size of the grid changes do not render the grid
    const widthChanged = nextProps.size.width !== this.props.size.width
    const heightChanged = nextProps.size.height !== this.props.size.height

    if (widthChanged || heightChanged) {
      setTimeout(() => this.setState({ size: getExtContainerSize(this.props.id) }), 100)
    }
  }

  createContract() {
    return { refreshKey: uuidv4() }
  }

  distributionButtonClick() {
    return { refreshKey: uuidv4() }
  }
  render() {
    const { id, token, isPreviewMode, isAllowed } = this.props

    const session = jwtDecode(token)
    const key = id

    const user = {
      id: session.sub * 1,
      username: session.preferred_username,
      culture: session.culture,
      email: session.email
    }

    if (isPreviewMode) {
      return <div> Preview mode. </div>
    }

    let packageProps = {
      xtype: 'spuimainview',
      ref: 'spuiMainView',
      width: '100%',
      height: '100%',
      key,

      user,
      menuId: '5b0b2d85f4141',
      MongoCollectionExportedData,
      CanUseSAP: isAllowed('CanUseSAP'),
      listeners: {
        createContract: () => this.createContract(),
        distributionButtonClick: () => this.distributionButtonClick()
      }
    }

    const extContainerProps = {
      ...getPluginSize(this.state.size, isPreviewMode),
      id: `slvyExtContainer-${id}`,
      cls: 'slvy-ext-container',
      layout: 'fit',
      autoSize: true,
      items: [packageProps]
    }

    return (
      <ExtRoot pluginId={id}>
        <ExtContainer {...extContainerProps} />
      </ExtRoot>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerAuthorizations: props.registerAuthorizations,
  size: props.size,
  isPreviewMode: props.isPreviewMode,
  id: props.id,
  token: props.token,
  userName: props.userName,
  actualFilters: props.actualFilters,
  createLog: props.createLog,
  params: props.params,
  reloadExtRoot: props.reloadExtRoot,
  isAllowed: props.isAllowed
})

export default createPlugin(SpotUi, selectConnectorProps)
