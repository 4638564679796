import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from '../appAPI'

const initialState = {
  columns: [],
  error: {},
  errorProducts: {},
  isForecast: true,
  isLoading: true,
  isProductLoading: false,
  isSelectAllChecked: false,
  isShown: false,
  products: [],
  similarOptions: {},
  similarProducts: [],
  similarProductsAdded: {},
  similarProductsError: {},
  similarProductsLoading: false
}

export const addSimilarProducts = createAsyncThunk('app/addSimilarProducts', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload
    return JSON.parse(response.text)
  } catch (err) {
    throw err
  }
})

export const getProducts = createAsyncThunk('app/getProducts', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload
    return JSON.parse(response.text)
  } catch (err) {
    throw err
  }
})

export const getSimilarOptions = createAsyncThunk('app/getSimilarOptions', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload
    return JSON.parse(response.text)
  } catch (err) {
    throw err
  }
})

const similarOptionsSlice = createSlice({
  name: 'similarOptions',
  initialState,
  reducers: {
    resetSimilarProductsAdded: (state, action) => {
      state.similarProductsAdded = {}
    },
    setColumns: (state, action) => {
      state.columns = action.payload
    },
    setIsSelectAllChecked: (state, action) => {
      state.isSelectAllChecked = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setSimilarProducts: (state, action) => {
      state.similarProducts = action.payload
    },
    toggleSimilarOptions: (state, action) => {
      state.isShown = action.payload // TODO be careful about payload obj
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSimilarProducts.pending, (state, action) => {
        state.similarProductsAdded = null
        state.similarProductsLoading = true
        state.similarProductsError = {}
      })
      .addCase(addSimilarProducts.fulfilled, (state, action) => {
        state.similarProductsAdded = action.payload
        state.similarProductsLoading = false
        state.similarProductsError = {}
      })
      .addCase(addSimilarProducts.rejected, (state, action) => {
        state.similarProductsAdded = {}
        state.similarProductsLoading = false
        state.similarProductsError = { ...action.payload }
      })
      .addCase(getProducts.pending, (state, action) => {
        state.products = []
        state.errorProducts = {}
        state.isProductLoading = true
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload
        state.errorProducts = {}
        state.isProductLoading = false
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.products = []
        state.errorProducts = { ...action.payload }
        state.isProductLoading = false
      })
      .addCase(getSimilarOptions.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getSimilarOptions.fulfilled, (state, action) => {
        state.error = {}
        state.similarOptions = action.payload
        state.isLoading = false
      })
      .addCase(getSimilarOptions.rejected, (state, action) => {
        // TODO initialState?
        state = {
          ...initialState,
          error: { ...action.payload },
          isLoading: false,
          isShown: true
        }
      })
  }
})

export const {
  resetSimilarProductsAdded,
  setColumns,
  setIsSelectAllChecked,
  setProducts,
  setSimilarProducts,
  toggleSimilarOptions
} = similarOptionsSlice.actions

export const columns = (state) => {
  state.columns
}
export const error = (state) => {
  state.error
}
export const errorProducts = (state) => {
  state.errorProducts
}
export const isForecast = (state) => {
  state.isForecast
}
export const isLoading = (state) => {
  state.isLoading
}
export const isProductLoading = (state) => {
  state.isProductLoading
}
export const isSelectAllChecked = (state) => {
  state.isSelectAllChecked
}
export const isShown = (state) => {
  state.isShown
}
export const products = (state) => {
  state.products
}
export const similarOptions = (state) => {
  state.similarOptions
}
export const similarProducts = (state) => {
  state.similarProducts
}
export const similarProductsAdded = (state) => {
  state.similarProductsAdded
}
export const similarProductsError = (state) => {
  state.similarProductsError
}
export const similarProductsLoading = (state) => {
  state.similarProductsLoading
}

export default similarOptionsSlice.reducer
