import { select, take } from 'redux-saga/effects'
import _ from 'lodash'
import { fromJS } from 'immutable'
import { SET_VARIABLES } from '../actionTypes'
import { difference } from '../../../helpers/lodash'

export default function* waitChangeVariables(props, result, action) {
  const {
    payload: {
      params: { storeIndex }
    }
  } = action
  const { relationReducers: oldrelationReducers } = yield select()
  const oldVariables = oldrelationReducers.getIn(
    ['stores', storeIndex, 'page', 'variables'],
    fromJS({})
  )
  while (true) {
    yield take(SET_VARIABLES)
    const { relationReducers } = yield select()
    const variables = relationReducers.getIn(
      ['stores', storeIndex, 'page', 'variables'],
      fromJS({})
    )
    if (!_.isEqual(oldVariables, variables)) {
      return difference(variables.toJS(), oldVariables.toJS())
    }
  }
}
