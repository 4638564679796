import cx from 'classnames'
import { ColumnState, MenuItemDef } from 'ag-grid-community'
import { EmptyObject } from '../../types'
import {
  FILTER_DISALLOWED_COLUMN_TYPES,
  agRightAlignedHeader,
  FIXED_WIDTH_CUSTOM_FIELDS
} from '../../constants'
import {
  GetHeaderClassProps,
  GetHeaderMenuItemsProps,
  GetMenuTabsProps,
  GetMenuTabsReturnType,
  HeaderClassReturnType
} from './headerMenu.type'
import styles from '../../scss/_header.module.scss'

// TODO: Missing test
const getHeaderMenuItems = ({
  params,
  gridRef,
  lockPinned,
  grouping,
  massUpdate: isMassUpdateAllowed,
  suppressRowGroupHidesColumns
}: GetHeaderMenuItemsProps) => {
  const {
    api,
    context,
    column,
    column: {
      colId,
      colDef: {
        customInfo: {
          columnType,
          editing: { enabled: columnEditable }
        },
        hasColumnGroup,
        filter,
        sortable,
        field
      },
      filterActive,
      rowGroupActive,
      sortIndex
    }
  } = params

  const {
    autosizeAllColumns: autosizeAllColumnsLocale,
    massUpdate: massUpdateLocale,
    sortAscending: sortAscendingLocale,
    sortDescending: sortDescendingLocale,
    sortUnSort: sortUnSortLocale,
    clearFilters: clearFiltersLocale,
    columnMenuSettings: columnMenuSettingsLocale,
    clearAllFilters: clearAllFiltersLocale,
    clearAllSorting: clearAllSortingLocale,
    clearAllGrouping: clearAllGroupingLocale
  } = params?.api?.getGridOption?.('localeText') || {}

  if (!gridRef?.current) {
    return []
  }

  const {
    current: { api: gridApi }
  } = gridRef

  const menuItems: (string | MenuItemDef)[] = []

  const isMassUpdateEnabled = isMassUpdateAllowed && columnEditable && columnType !== 'html'

  if (isMassUpdateEnabled) {
    menuItems.push({
      name: massUpdateLocale,
      disabled: api.getSelectedNodes().length < 2,
      action: () => {
        const pluginId = context?.pluginId
        if (!pluginId) {
          return
        }
        const eventName = `SLVY_AG_GRID_MASS_UPDATE_SHOW_MODAL_${pluginId}`
        dispatchEvent(new CustomEvent(eventName, { detail: { pluginId, activeField: field } }))
      }
    })

    menuItems.push('separator')
  }

  const columnState = gridApi.getColumnState()
  const isAnySortActive = columnState?.some((colState) => colState?.sort) // typeof sortIndex === 'number'
  const isAnyRowGroupingActive = columnState?.some((colState) => colState?.rowGroup) // typeof sortIndex === 'number'
  const isCurrentColumnSortActive = columnState?.some(
    (colState) => colState?.colId === colId && colState?.sort
  )

  if (sortable) {
    menuItems.push({
      name: sortAscendingLocale,
      // action: () => gridApi.sortController.setSortForColumn(column, 'asc')
      action: () =>
        gridApi.applyColumnState({ state: [{ colId, sort: 'asc' }], defaultState: { sort: null } })
    })

    menuItems.push({
      name: sortDescendingLocale,
      // action: () => gridApi.sortController.setSortForColumn(column, 'desc')
      action: () =>
        gridApi.applyColumnState({ state: [{ colId, sort: 'desc' }], defaultState: { sort: null } })
    })

    menuItems.push({
      name: sortUnSortLocale,
      disabled: !isCurrentColumnSortActive,
      // action: () => gridApi.sortController.setSortForColumn(column, null)
      action: () => gridApi.applyColumnState({ state: [{ colId, sort: null }] })
    })

    menuItems.push('separator')
  }

  if (!FILTER_DISALLOWED_COLUMN_TYPES.includes(columnType)) {
    if (filter) {
      menuItems.push({
        name: clearFiltersLocale,
        disabled: !filterActive,
        action: () => gridApi.destroyFilter(colId)
        // action: async () => {
        //   // Set the model to null
        //   await api.setColumnFilterModel(colId, null)
        //
        //   // Tell grid to run filter operation again
        //   api.onFilterChanged()
        // }
      })

      menuItems.push('separator')
    }

    const isAnyFilterPresent: boolean = api.isAnyFilterPresent()
    const isSortActive = isAnySortActive // api.sortController.isSortActive()
    const isSettingsDisabled = false

    const handleClearAllGrouping = () => {
      if (suppressRowGroupHidesColumns) {
        gridApi.applyColumnState({ defaultState: { rowGroup: null } })
        return
      }

      const columnStateWithoutGroup = columnState.reduce<ColumnState[]>((acc, state) => {
        if (!state.rowGroup) {
          return acc
        }

        return acc.concat({ colId: state.colId, rowGroup: null, hide: null })
      }, [])

      gridApi.applyColumnState({ state: columnStateWithoutGroup })
    }

    const settingsObj = {
      name: columnMenuSettingsLocale,
      disabled: isSettingsDisabled,
      subMenu: [
        {
          name: clearAllFiltersLocale,
          disabled: !isAnyFilterPresent,
          action: () => gridApi.setFilterModel(null)
        },
        {
          name: clearAllSortingLocale,
          disabled: !isSortActive,
          action: () => gridApi.applyColumnState({ defaultState: { sort: null } })
        },
        {
          name: clearAllGroupingLocale,
          disabled: !isAnyRowGroupingActive,
          action: handleClearAllGrouping
        },
        {
          name: autosizeAllColumnsLocale,
          action: () => {
            const allColumnIds: string[] = []
            gridApi.getColumns()?.forEach((column) => {
              const id = column.getId()
              if (!FIXED_WIDTH_CUSTOM_FIELDS?.includes(id)) {
                allColumnIds.push(id)
              }
            })
            gridApi.autoSizeColumns(allColumnIds)
          }
        }
      ]
    }
    menuItems.push(settingsObj)

    menuItems.push('separator')
  }

  if (grouping) {
    menuItems.push(rowGroupActive ? 'rowUnGroup' : 'rowGroup')
    menuItems.push('separator')
  }

  // if (!lockPinned && !hasColumnGroup) {
  menuItems.push('pinSubMenu')
  // }

  return menuItems
}

const getHeaderClass = ({ alignHeader }: GetHeaderClassProps): HeaderClassReturnType => {
  return {
    headerClass: cx({
      [styles.agCenterAlignedHeader]: alignHeader === 'center',
      [agRightAlignedHeader]: alignHeader === 'right'
    })
  }
}

const getMenuTabs = ({
  columnType,
  grouping
}: GetMenuTabsProps): GetMenuTabsReturnType | EmptyObject => {
  const doesDisallowedColumnExist = FILTER_DISALLOWED_COLUMN_TYPES.some(
    (filterDisallowedColumnType) => filterDisallowedColumnType === columnType
  )

  const isSparklineOrHighchart = columnType === 'sparkline' || columnType === 'highchart'

  if (isSparklineOrHighchart) {
    return { menuTabs: ['columnsMenuTab'] }
  }

  if (doesDisallowedColumnExist) {
    const menuTabs: GetMenuTabsReturnType['menuTabs'] = grouping
      ? ['generalMenuTab', 'columnsMenuTab']
      : ['columnsMenuTab']
    return { menuTabs }
  }

  return {}
}

export { getHeaderMenuItems, getHeaderClass, getMenuTabs }
