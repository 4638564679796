import { memo } from 'react'
import { SlvyFormInput } from '../../../../../../../components'
import RelationLabel from '../../RelationLabel'
import { IEventRowProps } from './EventRow.types'

const columns = { select: 6, label: 6 }

const EventRow = ({ paramType, paramKey, value, onChange, noRowMarginBottom }: IEventRowProps) => {
  const onInputChange = (variable: string) => onChange(variable, paramKey)

  return (
    <span data-testid="event-row">
      <SlvyFormInput
        shouldDebounce
        columns={columns}
        label={<RelationLabel sub={`{${paramType}}`} text={paramKey} />}
        labelClass="cp"
        noRowMarginBottom={noRowMarginBottom}
        size="sm"
        value={value}
        onChange={onInputChange}
        onLabelClick={() => onInputChange(paramKey)}
      />
    </span>
  )
}

export default memo(EventRow)
