module.exports = (appName) => {
  /**
   * Created by orhan.tornaci on 11/21/2016.
   * Bindto  property added;
   */
  Ext.define(`${appName}.overrides.ViewModel`, {
    override: 'Ext.app.ViewModel',
    privates: {
      createStore: function (key, cfg, listeners, binding) {
        var session = this.getSession(),
          store

        cfg = Ext.apply({}, cfg)

        if (cfg.session) {
          cfg.session = session
        }
        if (cfg.source) {
          cfg.type = cfg.type || 'chained'
        }

        // Restore the listeners from applyStores here
        cfg.listeners = listeners
        // Ensure events fired by ctor can find their target:
        cfg.resolveListenerScope = this.listenerScopeFn

        store = Ext.Factory.store(cfg)
        store.$binding = binding

        if (cfg.bindTo) {
          store.on(
            'load',
            function (store) {
              store.pCount++
              this.set(cfg.bindTo, store.pCount)
            },
            this
          )
          store.on(
            'update',
            function (store) {
              store.pCount++
              this.set(cfg.bindTo, store.pCount)
            },
            this
          )
          store.on(
            'add',
            function (store) {
              store.pCount++
              this.set(cfg.bindTo, store.pCount)
            },
            this
          )

          store.on(
            'remove',
            function (store) {
              store.pCount++
              this.set(cfg.bindTo, store.pCount)
            },
            this
          )
        }
        this.setupStore(store, key)
      }
    }
  })
}
