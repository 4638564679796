import { IAggFuncParams } from 'ag-grid-community'
import moment from 'moment'
import {
  customizedAggDateFuncs,
  customizedAggColumnTypeFuncs,
  customizedAggBooleanFuncs,
  DEFAULT_DATE_FORMAT
} from '../../constants'
import { IGetAggFuncProps } from './agg.types'

function getAggFunc({ dataType, columnType, aggFunc, customAggregation }: IGetAggFuncProps) {
  const isAggFuncDefined = typeof aggFunc !== 'undefined'

  if (customAggregation) {
    return {
      aggFunc: customAggregation
    }
  }

  if (aggFunc === '') {
    return {
      aggFunc: null
    }
  }

  if (!isAggFuncDefined) {
    return {
      aggFunc: null
    }
  }

  if (columnType && columnType !== 'simple' && aggFunc !== null) {
    if ((aggFunc as string) in customizedAggColumnTypeFuncs) {
      return {
        aggFunc: customizedAggColumnTypeFuncs[aggFunc as keyof typeof customizedAggColumnTypeFuncs]
      }
    }
  }

  if (dataType === 'datetime' && (aggFunc as string) in customizedAggDateFuncs) {
    return {
      aggFunc: customizedAggDateFuncs[aggFunc as keyof typeof customizedAggDateFuncs]
    }
  }

  if (dataType === 'bool' && (aggFunc as string) in customizedAggBooleanFuncs) {
    return {
      aggFunc: customizedAggBooleanFuncs[aggFunc as keyof typeof customizedAggBooleanFuncs]
    }
  }

  return { aggFunc }
}

const aggregateDate = (params: IAggFuncParams) => {
  const {
    colDef: { aggFunc }
  } = params
  const fn = aggFunc === 'date max' ? 'max' : 'min'
  // Filter zero from the array, to find a meaningful min/max date result instead of empty string
  const dateTimes = params.values.filter((val) => val).map((val) => new Date(val).getTime())
  return dateTimes.length ? moment(Math[fn](...dateTimes)).format(DEFAULT_DATE_FORMAT) : null
}

// const aggregateCount = (params: IAggFuncParams) => params.rowNode.allLeafChildren.length

const emptyFn = () => ''

function getAggFuncs() {
  return {
    aggFuncs: {
      // overwrite default count
      // count: aggregateCount,
      // custom fn
      'date max': aggregateDate,
      'date min': aggregateDate,
      'date avg': emptyFn,
      'date sum': emptyFn,
      'bool max': emptyFn,
      'bool min': emptyFn,
      'bool avg': emptyFn,
      'bool sum': emptyFn,
      'agg max': emptyFn,
      'agg min': emptyFn,
      'agg avg': emptyFn,
      'agg sum': emptyFn,
      'agg first': emptyFn,
      'agg last': emptyFn
    }
  }
}

export { getAggFunc, getAggFuncs, aggregateDate }
