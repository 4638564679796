import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPlugin } from '../containers/EventMethod/components/Common.types'

export interface IState {
  copy: IPlugin[]
}

export const initialState: IState = {
  copy: []
}

type ActionPayloadType = IPlugin | Pick<IPlugin, 'id'>

export const copy = createSlice({
  name: 'copy',
  initialState,
  reducers: {
    copyOrRemoveFromClipboard: (state, action: PayloadAction<ActionPayloadType>) => {
      const index = state.copy.findIndex((item) => item.id === action.payload.id)
      if (index === -1) {
        state.copy.push(action.payload)
      } else {
        state.copy = state.copy.filter((_item, itemIndex) => index !== itemIndex)
      }
    }
  }
})

export const { copyOrRemoveFromClipboard } = copy.actions

export default copy.reducer
