import React, { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import jwtDecode from 'jwt-decode'
import { v4 as uuidv4 } from 'uuid'
import { confirmAlert } from 'react-confirm-alert'
import { slvyToast, SlvySpinner, ErrorBoundary } from '@/components'
import { PluginTypes } from '@/BasePlugin'
import EventListenerManager from '@/utils/eventListenerManager'
import BoundedQueue from '@/utils/boundedQueue'
import { MESSAGES } from './messages'
import {
  AppInitialization,
  CommonPivotBuilder,
  FetchMfp,
  FetchPeriods,
  FetchPivotConfiguration,
  NodeActions,
  RegisterEventMethod
} from './facc'
import {
  CurrencyRates,
  GoToMainButton,
  Header,
  HierarchyTree,
  MainContainer,
  MetricSelection,
  OverlayTrigger,
  Pivot,
  PivotBuilder,
  PivotDetail,
  PivotSingleDetail,
  ScenarioComparison,
  ScenarioTree,
  Subset,
  Timeline
} from './components/index'
import {
  _errorHandler,
  _getBaseRequestParameters,
  _getSelectedParams,
  getActualScenarioIdsAsBoolean,
  getBaseUpdateRequestParameters,
  getCopiedBoundingClientRect,
  getFormattedDefaultDate,
  getRequestConfig,
  getScenarioParameters,
  makeRequest,
  setLoader,
  Queue,
  errorControl
} from './utils'
import {
  createNodes,
  getArgs,
  getDefaultDatesFromSettings,
  getHierarchy,
  getHierarchyTypes,
  isAnyColumnEqual,
  isExistTimelineSettings
} from './components/Util'
import 'slvy-ui-icons/style.css'
import './dp.scss'

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lastLeftHierarchy: false,
      stateHandler: {}
    }

    this.pipedFilterParams = {}
    this.errorContext = { pluginName: 'DemandPlanning' }
    this.demandPlanningRef = React.createRef()
    this.eventQueue = new BoundedQueue(5)

    this.stateHandler = this.stateHandler.bind(this)
  }

  componentDidMount() {
    const { isAutoLoadActive } = this.props

    this.dpErrorContextListeners = new EventListenerManager(
      this.demandPlanningRef.current,
      ['click'],
      this.setEventInfo
    )

    if (isAutoLoadActive) {
      this.dpErrorContextListeners.add()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOnline && !this.props.isOnline) {
      this.toggleLoader(false)

      this.removeClassFromAllSelector('-mini-loading')

      Queue.reset()

      this.props.savePendingUpdateCount({
        payload: { pendingCount: 0 }
      })

      this.onReloadViewClick()
    }
  }

  componentWillUnmount() {
    const { isAutoLoadActive } = this.props

    if (isAutoLoadActive) {
      this.dpErrorContextListeners.remove()
    }
  }

  removeClassFromAllSelector(className) {
    const elements = document.querySelectorAll(`.${className}`)
    // iterate over divs
    for (const element of elements) {
      // remove class from each div
      element.classList.remove(className)
    }
  }

  toggleLoader(isVisible = false, msg = MESSAGES.loading, Guid = undefined) {
    setLoader(this.props.setActionLoader, isVisible, msg, Guid)
  }

  engineUpdated = (request) => {
    const { configuration, configuration: { OldValue = 0, NewValue = 0, MetricField } = {} } =
      request

    return {
      ...this.getSelectedParams(configuration, false),
      NewValue,
      ChangedColumns: [MetricField],
      NewValues: [NewValue],
      OldValues: [OldValue]
    }
  }

  generateTreeFilter(params) {
    let nodes = []

    const omitDates = ($params, callback) => {
      const { StartDate = '', EndDate = '' } = $params
      if (StartDate && EndDate) {
        callback(_.omit($params, ['StartDate', 'EndDate']), { StartDate, EndDate })
      } else {
        callback($params, false)
      }
    }

    omitDates(params, (datesOmittedParams, dates) => {
      params = datesOmittedParams
      if (dates) {
        this.props.saveSetFilterRemote({
          payload: dates
        })
      }
    })

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key]
        nodes = nodes.concat(element)
      }
    }

    return nodes
  }

  _reloadPivotConfigurationAndView() {
    this._fetchPivotConfiguration().then(() => {
      const Fields = _.get(this.props, 'GetPivotConfiguration.Result.Fields', [])

      this.onGetPivotConfigurationLoaded(Fields, () => {
        this.commonPivotBuilderProps.executePivotMainConfiguration(($isOk) => {
          if ($isOk) {
            this.onReloadViewClick()
          } else {
            this.toggleLoader()
          }
        }, null)
      })
    })
  }

  _loadFromSubset() {
    this._fetchPivotConfiguration().then(() => {
      this.commonPivotBuilderProps.executePivotMainConfiguration(($isOk) => {
        if ($isOk) {
          this.fetchPeriodsProps._fetchPeriods().then(() => {
            const LeftAxisDraggable = _.get(
              this.props,
              'pivotMainConfiguration.LeftAxisDraggable',
              []
            )
            this.setupTimeline(() => this.getMfpDataCaller(LeftAxisDraggable))
          })
        } else {
          this.toggleLoader()
        }
      }, null)
    })
  }

  setFilterTree(params) {
    const { id = '' } = this.props
    const currentView = this.getCurrentView()
    const isVisibleScenarioComparison = currentView === 'ScenarioComparison'
    const isVisiblePivotDetailView = currentView === 'PivotDetailView'
    const isVisiblePivotSingleDetailView = currentView === 'PivotSingleDetailView'
    const isVisibleScenarioTree = currentView === 'ScenarioTree'

    const getMfpWithoutFilter = _.get(
      this.props,
      'settings.config.configuration.getMfpWithoutFilter',
      false
    )
    const multiColumnEnabled = _.get(
      this.props,
      'settings.config.gridDetail.multiColumnEnabled',
      false
    )

    this.errorContext = {
      ...this.errorContext,
      scenarios: this.getScenarioParameters().params,
      filters: params
    }

    if (_.isEmpty(params) && !getMfpWithoutFilter) {
      return
    }

    this.pipedFilterParams = params

    const nodes = this.generateTreeFilter(params)
    const $payload = createNodes(nodes)
    const $result = {
      raw: params,
      created: $payload
    }

    this.props
      .saveSetFilterTreeRemote({
        payload: $result
      })
      .then(() => {
        const resetSettings = {
          selectedLeftKeys: [],
          selectedBreadcrumbs: [],
          mergedLeftFieldsAndKeys: [],
          isVisible: false
        }

        dispatchEvent(new CustomEvent('filterApplied', { detail: $result }))

        if (
          isVisiblePivotDetailView ||
          isVisiblePivotSingleDetailView ||
          isVisibleScenarioComparison
        ) {
          // NOTE: When user came back to the Pivot Screen, left hierarchy should be sync with active filter.
          this._savePivotViewSettings({ selectedLeftKeys: [], mergedLeftFieldsAndKeys: [] })
        }

        if (isVisibleScenarioComparison) {
          this._saveScenarioComparisonSettings(resetSettings, () => this.onReloadViewClick())
          return
        }

        if (isVisiblePivotDetailView) {
          this._savePivotDetailViewSettings(resetSettings, () => this.reloadView())
          return
        }

        if (isVisiblePivotSingleDetailView) {
          let selectedMetricName = ''

          const defaultMetric = { DisplayName: '', Name: '', Format: '', IsUpdateAllowed: false }

          let selectedMetricFields = multiColumnEnabled
            ? JSON.parse(localStorage.getItem(`dp-SingleDetailMetricSelection-${id}`))
            : null

          selectedMetricName = selectedMetricFields
            ? {
                Name: selectedMetricFields[0].Name,
                DisplayName: selectedMetricFields[0].DisplayName
              }
            : selectedMetricName

          selectedMetricFields = selectedMetricFields || [
            defaultMetric,
            defaultMetric,
            defaultMetric
          ]

          const singleResetSettings = { selectedMetricName, selectedMetricFields, metricFields: [] }
          this._savePivotSingleDetailViewSettings(
            { ...resetSettings, ...singleResetSettings },
            () => this.reloadView()
          )
          return
        }

        if (!isVisibleScenarioTree) {
          this.onReloadViewClick()
          return
        }

        this.toggleLoader(true)

        // do not change sort of the fn call // call before pivot builder show
        this.commonPivotBuilderProps.executePivotMainConfiguration(({ isOk }) => {
          if (isOk) {
            this.fetchPeriodsProps._fetchPeriods().then(() => {
              const LeftAxisDraggable = _.get(
                this.props,
                'pivotMainConfiguration.LeftAxisDraggable',
                []
              )
              this.setupTimeline(() => this.getMfpDataCaller(LeftAxisDraggable))
            })
          } else {
            this.toggleLoader()
          }
        }, null)
      })
  }

  setupTimeline($callback) {
    const {
      GetPeriods: { Result = [] } = {},
      timelineSettings: { AggregationLevelId = '', AggregationPeriods = {} } = {}
    } = this.props

    const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods
    const { StartDate, EndDate } = AggCurrentPeriod
    const { Values = [] } = Result.find((item) => item.AggregationLevel === AggregationLevelId)

    let isNotFound = false
    if (Values.length) {
      const hasStartDate = Values.some((value) => value.StartDate === StartDate)
      const hasEndDate = Values.some((value) => value.EndDate === EndDate)
      isNotFound = hasStartDate === false || hasEndDate === false
    }

    if ((!StartDate && !EndDate) || isNotFound) {
      this.props
        .saveTimelineSettings({
          payload: { ...this.getTimelineInitialSettings() }
        })
        .then(() => $callback())
    } else {
      $callback()
    }
  }

  setGenerateReportTree(params) {
    if (_.isEmpty(params)) {
      return
    }

    const groupBy = _.get(this.props, 'settings.config.report.groupBy', [])

    const GroupBy = _.flatten(
      _.reduce(
        _.pickBy(params, (value, key) => _.findIndex(groupBy, (f) => f.name === key) > -1),
        (result, value) => {
          result.push(value)
          return result
        },
        []
      )
    )
    const nodes = this.generateTreeFilter(
      _.omitBy(params, (value, key) => _.findIndex(groupBy, (f) => f.name === key) > -1)
    )
    const $payload = createNodes(nodes)

    const $result = {
      raw: params,
      created: $payload,
      groupBy: GroupBy
    }

    this.props
      .saveSetGenerateReportTree({
        payload: $result
      })
      .then(() => {
        // do not change sort of the fn call // call before pivot builder show
        this.commonPivotBuilderProps.executePivotMainConfiguration(({ isOk }) => {
          if (isOk) {
            this.generateReportCallBack()
          } else {
            this.toggleLoader()
          }
        }, null)
      })
  }

  generateReportCallBack() {
    let {
      props: {
        defaultActionParams: { payload = {} } = {},
        GetSetGenerateReportTree: {
          created: FiltersMultiple = [],
          groupBy = [],
          raw: { StartDate = null, EndDate = null } = {}
        } = {},
        GetPivotConfiguration: { Result: { AggregationFunctions = ['SUM'] } = {} } = {}
      } = {}
    } = this

    this.toggleLoader(true)

    StartDate = getFormattedDefaultDate(StartDate, 'start')
    EndDate = getFormattedDefaultDate(EndDate, 'end')

    const body = this.getBaseRequestParameters()

    // get default pivot builder configuration
    const initialPivotMainCfg = this.commonPivotBuilderProps.getInitialPivotMainCfg()

    const { LeftFields, TopFields, MetricFields } = getRequestConfig(
      initialPivotMainCfg,
      AggregationFunctions
    )

    body.LeftFields = LeftFields
    body.TopFields = TopFields
    body.MetricFields = MetricFields

    body.FiltersMultiple = FiltersMultiple
    body.GroupByFields = groupBy
    body.StartDate = StartDate
    body.EndDate = EndDate

    const genereateReportPayload = {
      ...payload,
      method: 'GenerateReport',
      requestMethod: 'post',
      body: {
        ...body
      }
    }

    this.errorContext.lastRequest = {
      payload: genereateReportPayload
    }

    const promise = makeRequest({
      payload: genereateReportPayload
    })

    promise
      .then((response) => {
        const { data: { Errors = [] } = {} } = response || {}
        this.errorContext.lastRequest = {
          ...this.errorContext?.lastRequest,
          response
        }

        if (!Errors.length) {
          slvyToast.info({
            message: MESSAGES.your_report_will_be_create_soon,
            options: { containerId: 'dp' }
          })
        } else {
          slvyToast.warning({ message: _.first(Errors), options: { containerId: 'dp' } })
        }
        this.toggleLoader(false)
      })
      .catch(() => this.toggleLoader(false))

    return promise
  }

  getTimelineInitialSettings() {
    const shouldRememberLastTimelineSelection = _.get(
      this.props,
      'settings.config.timeline.shouldRememberLastTimelineSelection',
      false
    )

    const {
      GetPeriods: { Result = [] } = {},
      settings: { config: { timeline = {} } = {} } = {},
      timelineSettings: { AggregationLevelId = '', AggregationPeriods = {} } = {},
      id = ''
    } = this.props

    const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods

    const {
      StartDate,
      EndDate,
      StartDisplayName = '',
      EndDisplayName = '',
      PeriodName = '',
      currentSelection
    } = AggCurrentPeriod

    let _AggCurrentPeriod = {
      StartDate,
      EndDate,
      StartDisplayName,
      EndDisplayName,
      PeriodName,
      currentSelection
    }

    const _AggregationLevelId = timeline.defaultAggregationLevelId
    const defaultDates = getDefaultDatesFromSettings(Result, timeline)

    _AggCurrentPeriod = {
      ..._AggCurrentPeriod,
      ...defaultDates // StartDate, EndDate, StartDisplayName and EndDisplayName are optional
    }

    this.errorContext.period = _AggCurrentPeriod

    let _tml = {
      AggregationPeriods: {
        ...AggregationPeriods,
        [_AggregationLevelId]: {
          ..._AggCurrentPeriod
        }
      }
    }

    const storageTimelineSettings = JSON.parse(localStorage.getItem(`dp-TimelineSettings-${id}`))
    if (storageTimelineSettings) {
      if (shouldRememberLastTimelineSelection) {
        const tml = { ..._.omit(storageTimelineSettings, ['Collapse']) }
        const isExistTimeline = Result ? isExistTimelineSettings(Result, tml) : false
        if (isExistTimeline) {
          _tml = { ...tml }
        }
      } else {
        localStorage.removeItem(`dp-TimelineSettings-${id}`)
        // TODO: This is an async action so must not complete before calling saveTimeline below.
        this.props.resetTimelineSettings({
          payload: {}
        })
      }
    }

    return _tml
  }

  getMfpDataCaller(LeftAxisDraggable = []) {
    this.setState({
      lastLeftHierarchy: _.size(LeftAxisDraggable) === 1
    })

    this.fetchMfpProps._fetchMfpData().then(() => {
      this.toggleLoader()
      this.props.saveSubsetViewSettings({
        payload: {
          isVisible: false
        }
      })
      this.props.saveScenarioTreeViewSettings({
        payload: {
          isVisible: false
        }
      })
      this._savePivotViewSettings({ isVisible: true })
      this.onViewChangedCallback({ View: 'PivotView' })
    })
  }

  getBaseRequestParameters() {
    return _getBaseRequestParameters(this.props)
  }

  reloadMFP() {}

  getSelectedCurrency() {
    const Name = _.get(this.props, 'currencyRatesSettings.Name', null)
    return Name === 'TRY' ? null : Name
  }

  setEditable(response = {}, showMsg = true) {
    // dont add default value for this line
    const defaultIsScenarioEditable = _.get(this.props, 'mfpSettings.isScenarioEditable')
    const isScenarioEditable = _.get(response, 'Result.IsEditable', defaultIsScenarioEditable)
    const errors = _.get(response, 'Errors', [])

    this.props.saveMfpSettings({
      payload: {
        mfpSettings: {
          isScenarioEditable
        }
      }
    })

    if (showMsg && !isScenarioEditable) {
      slvyToast.warning({
        message: errors.length ? errors[0] : MESSAGES.this_scenario_is_not_editable,
        options: { containerId: 'dp' }
      })
    }
  }

  _handleUpdateMfpPromise = (callStatus, response, params) => {
    _errorHandler(response, 'UpdateMfpData')

    let $status = callStatus
    if (callStatus) {
      const { Errors = [] } = response || {}
      $status = Errors.length ? false : $status
    }

    const { Guid = null } = response || {}
    if (!$status && Guid) {
      const element = document.querySelector(`[data-id="${Guid}"] .-planned`)
      if (element) {
        element.classList.remove('-mini-loading')
      }
    }

    // set editable
    this.setEditable(response, false)

    this.cellUpdated({
      Status: $status,
      LeftHierarchy: params.LeftHierarchy,
      MfpId: Number(_.get(this.props, 'mfpSettings.mfpId', null))
    })
  }

  _updateMfpData($params = {}, isloading = false) {
    const { props: { defaultActionParams: { payload = {} } = {} } = {} } = this

    const body = this.getBaseRequestParameters()

    if (isloading) {
      this.toggleLoader(true, MESSAGES.loading)
    }

    const payloadBody = { ...body, ...$params }
    payloadBody.SelectedCurrency = this.getSelectedCurrency()

    const updateMfpDataPayload = {
      ...payload,
      method: 'UpdateMfpData',
      requestMethod: 'post',
      body: payloadBody
    }

    this.errorContext.lastUpdate = {
      payload: updateMfpDataPayload
    }

    const promise = this.props.callUpdateMfpData({
      payload: updateMfpDataPayload
    })

    promise
      .then((response) => {
        this.errorContext.lastUpdate = {
          ...this.errorContext?.lastUpdate,
          response
        }
        this._handleUpdateMfpPromise(true, this.getModifiedResult(response), payloadBody)
      })
      .catch((error) => {
        this.errorContext.lastUpdate = {
          ...this.errorContext?.lastUpdate,
          error
        }
        this._handleUpdateMfpPromise(false, this.getModifiedResult(error), payloadBody)
      })

    return promise
  }

  getRejectNegativeValuesMsg() {
    return (
      this.props?.settings?.config?.messages?.rejectNegativeValuesMessage ??
      MESSAGES.there_has_been_an_error
    )
  }

  getModifiedResult(response) {
    const { Errors = [] } = response || {}
    if (Errors.length) {
      if (Errors[0] === 'RejectNegativeValues') {
        Errors[0] = this.getRejectNegativeValuesMsg()
      }
    }
    return response
  }

  getArgs(fields, type) {
    return getArgs(fields, type, PluginTypes.string)
  }

  setHierarchy(params) {
    return getHierarchy(params, _.get(this.props, 'GetPivotConfiguration.Result.Fields', []))
  }

  onGetPivotConfigurationLoaded(Fields, $callback = () => {}) {
    const groupBy = this.props?.settings?.config?.report?.groupBy ?? []

    const $args = this.getArgs(Fields, PluginTypes.arrayOf(PluginTypes.string))

    this.props.registerMethod({
      key: 'setFilterTree',
      fn: this.setFilterTree.bind(this),
      args: $args
    })

    this.setHierarchy = this.props.registerEvent({
      key: 'setHierarchy',
      fn: this.setHierarchy.bind(this),
      returnTypes: this.getHierarchyTypes(Fields)
    })

    const args = [
      ...$args,
      ..._.map(groupBy, (item) => {
        const { name } = item
        return {
          name,
          type: PluginTypes.arrayOf(PluginTypes.string)
        }
      })
    ]

    this.props.registerMethod({
      key: 'setGenerateReportTree',
      fn: this.setGenerateReportTree.bind(this),
      args
    })

    $callback()
  }

  getHierarchyTypes(Fields) {
    return getHierarchyTypes(Fields, PluginTypes.string)
  }

  reloadAfterSave(showMsg, updatedWithState, $config, newLeftFields, callback = () => {}) {
    const currentView = this.getCurrentView()

    this.keepAliveView(newLeftFields, ({ goToMain = false }) => {
      this._savePivotBuilderViewSettings({ isVisible: false })

      this.commonPivotBuilderProps.savePivotAction({ ...$config }).then(() => {
        const reloadPage = () => {
          if (goToMain) {
            this.reloadView(() => callback({ isSuccess: true }))
          } else {
            this.onReloadViewClick()
            callback({ isSuccess: true })
          }
        }

        if (currentView === 'ScenarioComparison') {
          this.updateComparisonMetrics({ ...$config }, () => reloadPage())
        } else if (currentView === 'PivotSingleDetailView') {
          this.updateSingleDetailMetrics({ ...$config }, () => reloadPage())
        } else {
          reloadPage()
        }
      })
    })
  }

  mergeLeftFieldsAndKeys(LeftKeys) {
    const {
      pivotMainConfiguration = {},
      GetPivotConfiguration: { Result: { AggregationFunctions = ['SUM'] } = {} } = {}
    } = this.props

    let { LeftFields: ReqLeftFields } = getRequestConfig(
      pivotMainConfiguration,
      AggregationFunctions
    )

    const result = []
    ReqLeftFields = ReqLeftFields.slice(0, LeftKeys.length)
    ReqLeftFields.forEach((item, index) => result.push({ name: item, value: LeftKeys[index] }))

    return result
  }

  resetViews($callback = () => {}) {
    const defaultMetric = { DisplayName: '', Name: '', Format: '', IsUpdateAllowed: false }
    const resetSettings = {
      selectedLeftKeys: [],
      selectedBreadcrumbs: [],
      mergedLeftFieldsAndKeys: [],
      isVisible: false
    }
    const singleResetSettings = {
      selectedMetricName: '',
      selectedMetricFields: [defaultMetric, defaultMetric, defaultMetric],
      metricFields: []
    }
    this._savePivotViewSettings({ selectedLeftKeys: [], mergedLeftFieldsAndKeys: [] })
    this._savePivotDetailViewSettings(resetSettings)
    this._savePivotSingleDetailViewSettings({ ...resetSettings, ...singleResetSettings })
    this._saveScenarioComparisonSettings(resetSettings, () => $callback())
  }

  keepAliveView(newLeftFields, $callback) {
    const options = {
      PivotView: {
        key: 'pivotViewSettings',
        fn: this._savePivotViewSettings.bind(this)
      },
      PivotDetailView: {
        key: 'pivotDetailViewSettings',
        fn: this._savePivotDetailViewSettings.bind(this)
      },
      PivotSingleDetailView: {
        key: 'pivotSingleDetailViewSettings',
        fn: this._savePivotDetailViewSettings.bind(this)
      },
      ScenarioComparison: {
        key: 'scenarioComparisonSettings',
        fn: this._saveScenarioComparisonSettings.bind(this)
      }
    }

    const currentView = this.getCurrentView()
    const { key, fn } = options[currentView] || {}

    const { mergedLeftFieldsAndKeys = [] } = _.get(this.props, key, {})
    const settings = this.getSavedSettings(newLeftFields, [...mergedLeftFieldsAndKeys])
    const { mergedLeftFieldsAndKeys: _newMergedLeftFieldsAndKeys = [] } = settings

    if (_newMergedLeftFieldsAndKeys.length) {
      fn(settings, () => $callback({}))
    } else {
      this.resetViews(() => $callback({ goToMain: true }))
    }
  }

  getSavedSettings(newLeftFields, mergedLeftFieldsAndKeys) {
    const newSelectedKeys = []
    const newMergedLeftFieldsAndKeys = []
    for (let i = 0, len = newLeftFields.length; i < len; i++) {
      if (i >= mergedLeftFieldsAndKeys.length) {
        break
      }

      if (newLeftFields[i] !== mergedLeftFieldsAndKeys[i].name) {
        break
      }

      newMergedLeftFieldsAndKeys.push(mergedLeftFieldsAndKeys[i])
      newSelectedKeys.push(mergedLeftFieldsAndKeys[i].value)
    }

    return {
      selectedLeftKeys: newSelectedKeys,
      selectedBreadcrumbs: newSelectedKeys,
      mergedLeftFieldsAndKeys: newMergedLeftFieldsAndKeys
    }
  }

  getCurrentView() {
    const {
      pivotViewSettings: { isVisible: isVisiblePivotView },
      pivotDetailViewSettings: { isVisible: isVisiblePivotDetailView },
      pivotSingleDetailViewSettings: { isVisible: isVisiblePivotSingleDetailView },
      scenarioComparisonSettings: { isVisible: isVisibleScenarioComparison },
      scenarioTreeViewSettings: { isVisible: isVisibleScenarioTree }
    } = this.props

    if (isVisibleScenarioComparison) {
      return 'ScenarioComparison'
    }
    if (isVisiblePivotView) {
      return 'PivotView'
    }
    if (isVisiblePivotDetailView) {
      return 'PivotDetailView'
    }
    if (isVisiblePivotSingleDetailView) {
      return 'PivotSingleDetailView'
    }
    if (isVisibleScenarioTree) {
      return 'ScenarioTree'
    }
  }

  onReloadViewClick() {
    const currentView = this.getCurrentView()
    if (currentView === 'ScenarioComparison') {
      dispatchEvent(new CustomEvent('reloadComparison', { detail: {} }))
    } else if (currentView === 'PivotView') {
      this.fetchMfpProps._fetchMfpData().then(() => this.toggleLoader())
    } else if (currentView === 'PivotDetailView') {
      this.fetchMfpProps._fetchMfpDetailData().then(() => this.toggleLoader())
    } else if (currentView === 'PivotSingleDetailView') {
      this.fetchMfpProps._fetchMfpDetailDataForSingleColumn().then(() => this.toggleLoader())
    }
  }

  getDefaultMetric() {
    return { DisplayName: '', Name: '', Format: '', IsUpdateAllowed: false }
  }

  getFilledMetricSelection(Metrics, selectedMetricFields) {
    const clonedSelectedMetricFields = _.intersectionWith(Metrics, selectedMetricFields, _.isEqual)

    const emptyFieldsLength = 3 - clonedSelectedMetricFields.length
    for (let i = 0; i < emptyFieldsLength; i++)
      clonedSelectedMetricFields.push(this.getDefaultMetric())

    // nothing is equal
    if (emptyFieldsLength === 3) {
      // change first empty item with first metrics item
      clonedSelectedMetricFields[0] = Metrics[0]
    }

    return clonedSelectedMetricFields
  }

  getActiveMetrics($config) {
    return $config.ValuesDraggable.filter(({ Checked = false }) => Checked).map((item) => {
      const { DisplayName = '', Name = '', IsUpdateAllowed = false, Format = '' } = item
      return { DisplayName, Name, IsUpdateAllowed, Format }
    })
  }

  updateSingleDetailMetrics($config, $callback) {
    const {
      pivotSingleDetailViewSettings: {
        selectedMetricFields = [],
        selectedLeftKeys: LeftKeys = [],
        selectedMetricName: MetricName = ''
      } = {},
      settings: { config: { gridDetail: { multiColumnEnabled = false } = {} } = {} } = {}
    } = this.props

    const Metrics = this.getActiveMetrics($config)

    let singleDetailMetricParams = {}
    if (multiColumnEnabled) {
      const clonedSelectedMetricFields = this.getFilledMetricSelection(
        Metrics,
        selectedMetricFields
      )

      const CurrentMetric = clonedSelectedMetricFields.length
        ? clonedSelectedMetricFields[0]
        : Metrics.length
        ? Metrics[0]
        : this.getDefaultMetric()

      singleDetailMetricParams = {
        rawSelectedMetricFields: clonedSelectedMetricFields,
        CurrentMetric,
        Metrics
      }
    }

    // in case of selected metric that is selected before is not in the new metric list anymore, select first in the new metrics
    let newActiveItem = Metrics.find((item) => item.Name === MetricName.Name)
    newActiveItem = newActiveItem ? MetricName : Metrics[0]
    const _MetricName = { Name: newActiveItem.Name, DisplayName: newActiveItem.DisplayName }

    // no request
    this.pivotSingleDetailCallback(
      {
        LeftKeys,
        MetricName: _MetricName,
        ...(multiColumnEnabled ? { singleDetailMetricParams } : {})
      },
      { shouldNotRequest: true },
      () => $callback()
    )
  }

  updateComparisonMetrics($config, $callback) {
    const {
      scenarioComparisonSettings: {
        selectedMetricFields = [],
        selectedLeftKeys: LeftKeys = []
      } = {}
    } = this.props

    const Metrics = this.getActiveMetrics($config)

    const defaultMetric = this.getDefaultMetric()
    const clonedSelectedMetricFields = selectedMetricFields.map(() => defaultMetric)
    const CurrentMetric = Metrics.length ? Metrics[0] : defaultMetric

    this.onScenarioComparisonClick(
      {
        rawSelectedMetricFields: clonedSelectedMetricFields,
        CurrentMetric,
        LeftKeys,
        Metrics
      },
      () => $callback()
    )
  }

  _savePivotBuilderViewSettings($payload, $callback = () => {}) {
    const { pivotBuilderViewSettings = {} } = this.props
    this.props
      .savePivotBuilderViewSettings({
        payload: { ...pivotBuilderViewSettings, ...$payload }
      })
      .then(() => $callback())
  }

  _savePivotViewSettings($payload, $callback = () => {}) {
    const { pivotViewSettings = {} } = this.props
    this.props
      .savePivotViewSettings({
        payload: { ...pivotViewSettings, ...$payload }
      })
      .then(() => $callback())
  }

  _savePivotDetailViewSettings($payload, $callback = () => {}) {
    const { pivotDetailViewSettings = {} } = this.props
    this.props
      .savePivotDetailViewSettings({
        payload: { ...pivotDetailViewSettings, ...$payload }
      })
      .then(() => $callback())
  }

  _savePivotSingleDetailViewSettings($payload, $callback = () => {}) {
    const { pivotSingleDetailViewSettings = {} } = this.props
    this.props
      .savePivotSingleDetailViewSettings({
        payload: { ...pivotSingleDetailViewSettings, ...$payload }
      })
      .then(() => $callback())
  }

  _saveScenarioComparisonSettings($payload, $callback = () => {}) {
    const { scenarioComparisonSettings = {} } = this.props
    this.props
      .saveScenarioComparisonSettings({
        payload: { ...scenarioComparisonSettings, ...$payload }
      })
      .then(() => $callback())
  }

  reloadView($callback = () => {}) {
    const currentView = this.getCurrentView()
    if (currentView === 'ScenarioComparison') {
      this._saveScenarioComparisonSettings({ isVisible: false })
    }
    // Note: No else if because of z-index visibility
    if (currentView === 'PivotDetailView') {
      this._savePivotDetailViewSettings({ isVisible: false })
    } else if (currentView === 'PivotSingleDetailView') {
      this._savePivotSingleDetailViewSettings({ isVisible: false })
    }

    this.fetchMfpProps
      ._fetchMfpData()
      .then(() => {
        this._savePivotViewSettings({ isVisible: true })
        this.toggleLoader()
        $callback({ isSuccess: true })
      })
      .catch(() => $callback({ isSuccess: false }))
  }

  popConfirm($onConfirmCallback, $messages, $type) {
    const content = $type === 'sc' ? MESSAGES.the_scenario_comparison : MESSAGES.the_pivot_builder
    confirmAlert({
      title: MESSAGES.information,
      message: `Configuration of ${content} has been renewed,`,
      childrenElement: () => <ol className="dp-pop-confirm">{$messages}</ol>,
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: MESSAGES.confirm,
          onClick: $onConfirmCallback
        }
      ]
    })
  }

  getScenarioParameters() {
    const currentScenarios = _.get(this.props, 'selectedScenarioTree.currentScenarios', [])
    const mfpId = _.get(this.props, 'mfpSettings.mfpId')
    return getScenarioParameters(currentScenarios, mfpId)
  }

  pivotDetailCallback(param = {}) {
    const { LeftKeys = [] } = param
    this._savePivotDetailViewSettings(
      {
        selectedLeftKeys: LeftKeys,
        selectedBreadcrumbs: LeftKeys,
        mergedLeftFieldsAndKeys: this.mergeLeftFieldsAndKeys(LeftKeys)
      },
      () => {
        this.fetchMfpProps._fetchMfpDetailData().then(() => {
          const Errors = _.get(this.props, 'GetMfpDetailData.Errors', [])
          if (!Errors.length) {
            this._savePivotViewSettings({ isVisible: false })
            this._savePivotDetailViewSettings({ isVisible: true })
          }
        })
      }
    )
  }

  pivotSingleDetailCallback(param = {}, extraOpt = {}, $callback = () => {}) {
    const {
      id,
      pivotSingleDetailViewSettings: { selectedMetricFields = [] } = {},
      settings: { config: { gridDetail: { multiColumnEnabled = false } = {} } = {} } = {}
    } = this.props

    const { shouldNotRequest = false } = extraOpt

    const {
      LeftKeys = [],
      MetricName = '',
      singleDetailMetricParams: {
        rawSelectedMetricFields = [],
        CurrentMetric = {},
        Metrics = []
      } = {}
    } = param

    let metricsExtra = {}
    if (multiColumnEnabled && 'singleDetailMetricParams' in param) {
      let csmf = rawSelectedMetricFields.length
        ? _.cloneDeep(rawSelectedMetricFields)
        : _.cloneDeep(selectedMetricFields)

      if (this.getCurrentView() === 'PivotView') {
        const savedSelectedMetric = JSON.parse(
          localStorage.getItem(`dp-SingleDetailMetricSelection-${id}`)
        )
        if (savedSelectedMetric) {
          const _savedSelectedMetric = savedSelectedMetric.filter((item) => item)
          if (
            _savedSelectedMetric &&
            _savedSelectedMetric.length &&
            _savedSelectedMetric.filter((item) => item.Name).length
          ) {
            csmf = this.getFilledMetricSelection(csmf, _savedSelectedMetric)
          }
        }
      }

      if (csmf.some((item) => _.isEqual(item, CurrentMetric))) {
        csmf = csmf.filter((item) => !_.isEqual(item, CurrentMetric))
      } else if (csmf.some((item) => !item.Name)) {
        const emptyFirstItemIndex = csmf.findIndex((item) => !item.Name)
        csmf.splice(emptyFirstItemIndex, 1)
      } else {
        csmf.pop()
      }

      csmf.unshift(CurrentMetric)

      metricsExtra = {
        selectedMetricFields: csmf,
        metricFields: Metrics
      }

      // save
      localStorage.setItem(`dp-SingleDetailMetricSelection-${id}`, JSON.stringify(csmf))
    }

    this._savePivotSingleDetailViewSettings(
      {
        selectedLeftKeys: LeftKeys,
        selectedBreadcrumbs: LeftKeys,
        selectedMetricName: MetricName,
        mergedLeftFieldsAndKeys: this.mergeLeftFieldsAndKeys(LeftKeys),
        ...metricsExtra
      },
      () => {
        if (shouldNotRequest) {
          $callback()
          return
        }

        this.fetchMfpProps._fetchMfpDetailDataForSingleColumn().then(() => {
          const Errors = _.get(this.props, 'GetMfpDetailDataForSingleColumn.Errors', [])
          if (!Errors.length) {
            this._savePivotViewSettings({ isVisible: false })
            this._savePivotSingleDetailViewSettings({ isVisible: true })
          }
        })
      }
    )
  }

  getSelectedParams(params, onlyEvent = true) {
    return _getSelectedParams(this.props, params, onlyEvent)
  }

  isRowUpdate() {
    return _.get(this.props, 'headerSettings.updateMode', 0) === 1
  }

  updateCallback(params) {
    const { Guid = undefined } = params

    const checkParams = 'UpdateDetails' in params ? params.UpdateDetails : [params]

    if (isAnyColumnEqual(checkParams)) {
      slvyToast.info({
        message: MESSAGES.you_have_attempted_to_enter_the_same_value,
        options: { containerId: 'dp' }
      })
      this.toggleLoader(false, undefined, Guid)
      return
    }

    const ActualScenarioList = getActualScenarioIdsAsBoolean(
      this.props.selectedScenarioTree.actualScenarioList
    )
    const body = getBaseUpdateRequestParameters(this.props, params)
    body.ActualScenarioList = ActualScenarioList
    body.WithActual = ActualScenarioList[1]
    body.TopKey = null
    body.LeftHierarchy = params.LeftKeys

    this._updateMfpData(body, this.isRowUpdate()).then(() => {
      const { UpdateMfpData: { Errors = [], Guid = '' } = {} } = this.props

      if (!Errors.length) {
        this.fetchMfpProps
          ._fetchMfpData(this.isRowUpdate())
          .then(() => this.toggleLoader(false, undefined, Guid))
      } else {
        this.toggleLoader(false, undefined, Guid)
      }
    })

    document.body.click()
  }

  updateExternalCallback(params) {
    this.currentCell = params
    this.currentCell.UpdateType = 0
    this.externalUpdate(this.getSelectedParams(this.currentCell))
  }

  updateDetailCallback(params) {
    if (isAnyColumnEqual([params])) {
      slvyToast.info({
        message: MESSAGES.you_have_attempted_to_enter_the_same_value,
        options: { containerId: 'dp' }
      })
      return
    }

    const body = getBaseUpdateRequestParameters(this.props, params)
    const TopKey = _.get(params, 'TopKey', '')
    const currentView = this.getCurrentView()

    const ActualScenarioList = getActualScenarioIdsAsBoolean(
      this.props.selectedScenarioTree.actualScenarioList
    )

    body.ActualScenarioList = ActualScenarioList
    body.WithActual = ActualScenarioList[1]
    body.TopKey = TopKey

    this._updateMfpData(body).then(() => {
      const { UpdateMfpData: { Errors = [], Guid = '' } = {} } = this.props

      if (!Errors.length) {
        if (currentView === 'PivotDetailView') {
          this.fetchMfpProps
            ._fetchMfpDetailData(false)
            .then(() => this.toggleLoader(false, undefined, Guid))
        }
      } else {
        this.toggleLoader(false, undefined, Guid)
      }
    })

    document.body.click()
  }

  scenarioComparisonUpdateCallback(params, $callback = () => {}) {
    if (isAnyColumnEqual([params])) {
      slvyToast.info({
        message: MESSAGES.you_have_attempted_to_enter_the_same_value,
        options: { containerId: 'dp' }
      })
      return
    }

    const body = getBaseUpdateRequestParameters(this.props, params)
    body.TopKey = _.get(params, 'TopKey', '')
    body.BaselineScenarioId = _.get(params, 'BaselineScenarioId', null)
    body.MfpScenarioId = _.get(params, 'MfpScenarioId', null)
    body.ActualScenarioList = _.get(params, 'ActualScenarioList', [])
    body.WithActual = _.get(params, 'WithActual', false)

    this._updateMfpData(body, false).then(() => {
      const { UpdateMfpData = {} } = this.props
      $callback(UpdateMfpData)
    })

    document.body.click()
  }

  updateDetailExternalCallback(params) {
    this.currentCell = params
    this.currentCell.UpdateType = 0
    this.externalUpdate(this.getSelectedParams(this.currentCell))
  }

  updateSingleDetailCallback(params) {
    const ActualScenarioList = getActualScenarioIdsAsBoolean(
      this.props.selectedScenarioTree.actualScenarioList
    )

    const body = getBaseUpdateRequestParameters(this.props, params)
    body.TopKey = _.get(params, 'TopKey', '')
    body.LeftHierarchy = params.LeftKeys

    body.ActualScenarioList = ActualScenarioList
    body.WithActual = ActualScenarioList[1]

    this.props.increasePendingUpdateCount()

    const helper = (guid, hasError, errorClass) => {
      this.props.decreasePendingUpdateCount()
      this.toggleLoader(false, undefined, guid)
      errorControl({ guid, hasError, errorClass })
    }

    this._updateMfpData(body)
      .then(() => {
        const { UpdateMfpData: { Errors = [], Guid = '' } = {} } = this.props

        if (!Errors.length) {
          errorControl({ guid: Guid, hasError: false, errorClass: 'update-error' })
          this.fetchMfpProps
            ._fetchMfpDetailDataForSingleColumn(false)
            .then(({ data }) => helper(Guid, data?.hasErrors, 'detail-error'))
            .catch(() => helper(Guid, true, 'detail-error'))
        } else {
          helper(Guid, true, 'update-error')
        }
      })
      .catch(() => helper(params?.Guid, true, 'update-error'))

    document.body.click()
  }

  updateSingleDetailExternalCallback(params) {
    this.currentCell = params
    this.currentCell.UpdateType = 0
    this.externalUpdate(this.getSelectedParams(this.currentCell))
  }

  updateExternal(obj) {
    const currentView = this.getCurrentView()

    const { NewValue } = obj
    const TopKey = _.get(this.currentCell, 'TopKey', null)

    const body = getBaseUpdateRequestParameters(this.props, { ...this.currentCell, NewValue })
    body.TopKey = TopKey

    const callMfpData = (Guid) => {
      this.fetchMfpProps._fetchMfpData().then(() => this.toggleLoader(false, undefined, Guid))
    }

    this._updateMfpData({ ...body }, true).then(() => {
      const { UpdateMfpData: { Errors = [], Guid = '' } = {} } = this.props

      this.engineUpdated({ configuration: { ...body } })

      if (!Errors.length) {
        if (currentView === 'PivotDetailView') {
          this.fetchMfpProps._fetchMfpDetailData(false).then(() => callMfpData(Guid))
        } else if (currentView === 'PivotSingleDetailView') {
          this.fetchMfpProps._fetchMfpDetailDataForSingleColumn(false).then(() => callMfpData(Guid))
        } else {
          callMfpData(Guid)
        }
      } else {
        this.toggleLoader(false, undefined, Guid)
      }
    })
  }

  stateHandler(state, directlyInject = false) {
    return new Promise((resolve) => {
      if (directlyInject) {
        this.setState(state, () => resolve())
      } else {
        const stateHandler = { ...this.state.stateHandler, ...state }
        this.setState({ stateHandler }, () => resolve())
      }
    })
  }

  onPeriodUpdate() {
    this.onReloadViewClick()
    this.onPeriodUpdatedCallback()
  }

  onScenarioComparisonClick(params, $callback = () => {}) {
    const {
      scenarioComparisonSettings: { selectedMetricFields = [] }
    } = this.props

    const { rawSelectedMetricFields = [], CurrentMetric = {}, LeftKeys = [], Metrics = [] } = params

    const clonedSelectedMetricFields = rawSelectedMetricFields.length
      ? _.cloneDeep(rawSelectedMetricFields)
      : _.cloneDeep(selectedMetricFields)

    clonedSelectedMetricFields[0] = { ...CurrentMetric }

    this._saveScenarioComparisonSettings(
      {
        mergedLeftFieldsAndKeys: this.mergeLeftFieldsAndKeys(LeftKeys),
        selectedLeftKeys: LeftKeys,
        selectedBreadcrumbs: LeftKeys,
        selectedMetricFields: clonedSelectedMetricFields,
        metricFields: Metrics,
        isVisible: true
      },
      () => $callback()
    )
  }

  makeSubsetRequest(method = '', msg = '') {
    const { props: { defaultActionParams: { payload = {} } = {} } = {} } = this

    this.toggleLoader(true)

    let body = this.getBaseRequestParameters()
    body = _.pick(body, [
      'mfpId',
      'BaselineScenarioId',
      'Filters',
      'FiltersMultiple',
      'LeftFields',
      'MetricFields',
      'MfpScenarioId',
      'TopFields',
      'UserScenarioId',
      'ViewMode',
      'WorkInSubsetEnabled'
    ])

    const promise = makeRequest({
      payload: {
        ...payload,
        method,
        requestMethod: 'post',
        body: {
          ...body
        }
      }
    })

    promise
      .then((response) => {
        this.toggleLoader(false)
        const { data: { Errors = [] } = {} } = response
        if (!Errors.length) {
          slvyToast.info({ message: msg, options: { containerId: 'dp' } })
          this.resetPage()
        } else {
          _errorHandler({ Errors }, method)
        }
      })
      .catch(() => this.toggleLoader(false))

    return promise
  }

  onDiscardHandle() {
    this.makeSubsetRequest('DiscardChanges', MESSAGES.your_changes_have_been_discarded_successfully)
    const refreshKey = uuidv4()
    this.discardClicked({ refreshKey })
  }

  onApplyChangesHandle() {
    this.makeSubsetRequest('ApplyChanges', MESSAGES.your_changes_have_been_applied_successfully)
  }

  resetPage() {
    this.props.reload()
  }

  onMetricChanged(item, index) {
    const { pivotSingleDetailViewSettings: { selectedMetricFields = [] } = {}, id = '' } =
      this.props

    const clonedFields = _.cloneDeep(selectedMetricFields)
    const { DisplayName, Name, IsUpdateAllowed, Format = '' } = item
    clonedFields[index] = { DisplayName, Name, IsUpdateAllowed, Format }

    localStorage.setItem(`dp-SingleDetailMetricSelection-${id}`, JSON.stringify(clonedFields))

    this._savePivotSingleDetailViewSettings(
      {
        selectedMetricFields: clonedFields
      },
      () => this.onReloadViewClick()
    )
  }

  getConvertedFilters = () => {
    const Filters = { ...this.pipedFilterParams }
    Object.keys(Filters).forEach((key) => {
      const item = Filters[key]
      Filters[key] = _.isArray(item) ? item.join() : ''
    })

    return _.values(Filters).join('')
  }

  onRowClickedCallback(params) {
    let body = this.getBaseRequestParameters()

    body = _.pick(body, ['mfpId', 'LeftFields', 'MfpScenarioId', 'StartDate', 'EndDate'])

    body.MfpId = body.mfpId
    body = _.omit(body, ['mfpId'])

    let LeftFields = ''
    params.LeftHierarchy.forEach((leftHierarchy, index) => {
      LeftFields += `${leftHierarchy}||${body.LeftFields[index]}`
      if (index + 1 !== params.LeftHierarchy.length) {
        LeftFields += '|||'
      }
    })
    body.LeftFields = LeftFields

    const Filters = this.getConvertedFilters()

    body.PeriodAggregationLevel = this.props.timelineSettings.AggregationLevelId

    // this will overwrite with params on Comparison view
    body.IsActualScenario = this.props.selectedScenarioTree.actualScenarioList.some(
      (item) => item.scenarioType === 'Planned' && item.currentScenario.IsActualized
    )
      ? 1
      : 0

    // this will overwrite with params on Comparison view
    body.ScenarioTableName =
      this.props.selectedScenarioTree.currentScenarios.find(
        (item) => item.scenarioType === 'Planned'
      ).currentScenario?.ScenarioTableName || ''

    params = _.omit(params, ['LeftHierarchy'])

    const refreshKey = uuidv4()
    const result = { ...body, ...params, Filters, refreshKey }
    this.rowClicked(result)
  }

  onPeriodUpdatedCallback() {
    const {
      timelineSettings: { AggregationLevelId: PeriodAggregationLevel, AggregationPeriods = {} } = {}
    } = this.props

    this.errorContext.period = AggregationPeriods[PeriodAggregationLevel]
    this.periodUpdated({ PeriodAggregationLevel })
  }

  onViewChangedCallback(params) {
    this.errorContext.view = params.View
    this.viewChanged(params)
  }

  setEventInfo = (event) => {
    this.errorContext = {
      ...this.errorContext,
      events: this.eventQueue.enqueue({
        type: event.type,
        target: {
          className: event.target.className
        },
        text: event.target?.innerText
      }).items
    }
  }

  render() {
    const {
      props: {
        id = '',
        params: { catalogId = 0 },
        pluginStates: { data: $pluginStates = [] } = {},
        preferredUsername = '',
        settings,
        settings: {
          config: {
            general: { isRowClickedEventOn = false } = {},
            coef = {},
            localization: { isCurrencyModeActive = false } = {},
            messages = {},
            messages: { missingRowLockMessage = MESSAGES.this_row_cannot_be_unlocked } = {},
            updateModes: {
              isVisibleCellUpdate = true,
              isVisibleRowUpdate = true,
              isVisiblePopupUpdate = true
            } = {},
            timeline: timelineConfig = {},
            theme: { backgroundColor = '#FFF', color = '#000' } = {},
            configuration: {
              shouldShowConfirmApprovedCellUpdate = true,
              isWorkInSubsetEnabled = false,
              isApprove = true,
              isLock = true,
              pendingMessage = 'Auto-loading inactive.'
            } = {},
            grid: {
              isVisibleCopyHierarchyButton = false,
              isVisibleDetailView: detailView = true,
              isVisibleDetailViewSingleColumn: detailViewForSingleColumn = true,
              isVisibleDetailViewButtonAllLevels: detailViewButtonAllLevels = false,
              isHierarcyView,
              showAllDataInRows = false
            } = {},
            gridDetail: { multiColumnEnabled = false } = {},
            scenario: scenarioTreeSettings = {},
            pivotBuilder: pivotBuilderSettings = {},
            scenarioComparison: { isVisibleSCButton = true } = {},
            report: { groupBy = [] } = {}
          } = {}
        } = {},
        subsetViewSettings: { isVisible: isVisibleSubsetConfirmation },
        pivotBuilderViewSettings: { isVisible: isVisiblePivotBuilder },
        pivotViewSettings: { isVisible: isVisiblePivotView, selectedLeftKeys = [] },
        pivotDetailViewSettings: {
          isVisible: isVisiblePivotDetailView,
          selectedLeftKeys: detailLeftHierarchy = [],
          selectedBreadcrumbs: detailBreadcrumbs
        },
        pivotSingleDetailViewSettings: {
          isVisible: isVisiblePivotSingleDetailView,
          selectedLeftKeys: singleDetailLeftHierarchy = [],
          selectedBreadcrumbs: singleDetailBreadcrumbs,
          selectedMetricName: singleDetailPivotFieldMetricName,
          selectedMetricName: { DisplayName: singleDetailPivotFieldDisplayName = '' } = {},
          metricFields: singleDetailMetricFields = [],
          selectedMetricFields: singleDetailSelectedMetricFields = []
        },
        scenarioTreeViewSettings: { isVisible: isVisibleScenarioTree },
        scenarioComparisonSettings: {
          isVisible: isVisibleScenarioComparison,
          selectedBreadcrumbs: scenarioComparisonBreadcrumbs
        },
        GetPivotConfiguration = {},
        GetScenarioTree = {},
        GetScenarioTree: { Result: ScenarioTreeResult = [] },
        pivotMainConfiguration = {},
        pivotMainConfiguration: { ValuesDraggable = [] },
        GetPeriods: { Result: periods = [] },
        selectedScenarioTree: { currentScenarios = [] },
        headerSettings: {
          updateMode = 0,
          mode = 0,
          editMode: { format: buttonFormat }
        },
        GetMfpCurrencyRates: { Result: CurrencyRatesList = [] } = {},
        GetUserScenario = {},
        GetMfpDetailDataForSingleColumn = {},
        GetMfpDetailData = {},
        GetMfpData = {},
        isAllowed = () => {},
        saveHeaderSettings = () => {},
        token = '',
        Loader: {
          isAnimationStopped: isAnimationStoppedLoader = false,
          isVisible: isVisibleLoader = true,
          messages: loaderMsg = ''
        },
        mfpSettings: { isScenarioEditable = false, isSmallFontSize } = {},
        timelineSettings = {},
        timelineSettings: {
          Collapse: { isCollapsed = false }
        },
        UpdateMfpDataPendingCount = 0,
        isAutoLoadActive
      },
      state: { lastLeftHierarchy, stateHandler, TargetScenario = {} }
    } = this

    const size = getCopiedBoundingClientRect(this.props.size)

    const Fields = _.get(GetPivotConfiguration, 'Result.Fields', [])
    const AggregationFunctions = _.get(GetPivotConfiguration, 'Result.AggregationFunctions', [
      'SUM'
    ])

    const { culture = '', customername: customerName = '' } = jwtDecode(token)
    const userInfo = { userName: preferredUsername, customerName }

    const IsAllowed = isAllowed('CreateScenario')
    const IsApproveAllowed = isAllowed('ApproveMfpNode')
    const IsEditModeAllowed = isAllowed('Edit Mode')
    const IsMarkAsPublicAllowed = isAllowed('MarkAsPublic')
    const IsEdge = navigator.userAgent.indexOf('Edge') > -1 ? '-edge' : ''

    const filterIsUpdateAllowed = _.size(
      _.filter([...Fields], ({ IsUpdateAllowed = false } = {}) => IsUpdateAllowed)
    )
    const rowUpdateEnable = filterIsUpdateAllowed > 1
    const allowedToUpdate = filterIsUpdateAllowed > 0

    const externalUpdateEnable =
      _.size(_.filter([...ValuesDraggable], ({ ExternalEdit = false } = {}) => ExternalEdit)) > 0
    const pluginStyle = { backgroundColor, color }

    let Baseline = {}
    let Planned = {}
    if (currentScenarios.length === 2) {
      Baseline = currentScenarios.find((item) => item.scenarioType === 'Baseline').currentScenario
      Planned = currentScenarios.find((item) => item.scenarioType === 'Planned').currentScenario
    }

    const CurrentScenario = {
      Baseline,
      Planned
    }

    const configObj = {
      isRowClickedEventOn,
      isVisibleCopyHierarchyButton,
      isVisibleSCButton,
      shouldShowConfirmApprovedCellUpdate,
      isApprove,
      isLock,
      missingRowLockMessage,
      detailView,
      detailViewForSingleColumn,
      detailViewButtonAllLevels,
      isHierarcyView,
      showAllDataInRows,
      lastLeftHierarchy,
      coef,
      workInSubsetEnabled: isWorkInSubsetEnabled,
      DefaultAggregationLevel: _.get(
        this.props,
        'settings.config.timeline.defaultAggregationLevelId'
      ),
      ScenarioTreeResult,
      CurrentScenario,
      TargetScenario,
      stateHandler,
      updateModes: {
        isShownCellUpdate: isVisibleCellUpdate,
        isShownRowUpdate: isVisibleRowUpdate,
        isShownPopupUpdate: isVisiblePopupUpdate
      }
    }

    const StatePicker = {
      allowedToUpdate
    }

    const detailConfigObj = {
      size,
      isApprove,
      isLock,
      messages,
      isHierarcyView,
      isRowClickedEventOn,
      shouldShowConfirmApprovedCellUpdate,
      coef
    }

    const scenarioComparisonClass = isVisibleScenarioComparison ? 'scenario-comparison-active' : ''

    const { LeftFields: ReqLeftFields } = getRequestConfig(
      pivotMainConfiguration,
      AggregationFunctions
    )

    const pluginClasses = `dp-plugin ${isVisibleScenarioTree} ${scenarioComparisonClass} ${IsEdge}`
    const initProps = {
      ...this.props,
      pluginClasses,
      pluginStyle,
      id,
      isTimelineCollapsed: isCollapsed,
      setEditable: this.setEditable.bind(this),
      onViewChangedCallback: this.onViewChangedCallback.bind(this),
      _savePivotSingleDetailViewSettings: this._savePivotSingleDetailViewSettings.bind(this)
    }
    const nodeActionProps = {
      settings,
      stateHandler,
      cellApproved: this.cellApproved,
      getBaseRequestParameters: this.getBaseRequestParameters.bind(this),
      setEditable: this.setEditable.bind(this)
    }

    const commonPivotBuilderOpt = {
      ...this.props,
      reloadAfterSave: this.reloadAfterSave.bind(this),
      _savePivotBuilderViewSettings: this._savePivotBuilderViewSettings.bind(this),
      popConfirm: this.popConfirm.bind(this)
    }

    const _msg = `${MESSAGES.there_are_pending_updates} ${MESSAGES.please_wait_for_them_to_be_completed}`

    return (
      <ErrorBoundary errorContext={this.errorContext}>
        <RegisterEventMethod
          PluginTypes={PluginTypes}
          engineUpdated={this.engineUpdated.bind(this)}
          registerEvent={this.props.registerEvent.bind(this)}
          registerMethod={this.props.registerMethod.bind(this)}
          updateExternal={this.updateExternal.bind(this)}
          onReloadViewClick={this.onReloadViewClick.bind(this)}
          resetPage={this.resetPage.bind(this)}
        >
          {(registerProps) => {
            initProps.disableFilter = registerProps.disableFilter
            this.externalUpdate = registerProps.externalUpdate
            this.cellUpdated = registerProps.cellUpdated
            this.cellApproved = registerProps.cellApproved
            this.rowClicked = registerProps.rowClicked
            this.periodUpdated = registerProps.periodUpdated
            this.viewChanged = registerProps.viewChanged
            this.discardClicked = registerProps.discardClicked

            if (!isAutoLoadActive) {
              return (
                <div className="d-flex align-items-center justify-content-center h-100 bg-light fs-4">
                  {pendingMessage}
                </div>
              )
            }

            return (
              <AppInitialization {...initProps}>
                <CommonPivotBuilder {...commonPivotBuilderOpt}>
                  {(commonPivotBuilderProps) => {
                    this.commonPivotBuilderProps = commonPivotBuilderProps
                    return (
                      <FetchPivotConfiguration>
                        {({ _fetchPivotConfiguration = () => {} }) => {
                          this._fetchPivotConfiguration = _fetchPivotConfiguration
                          return (
                            <FetchPeriods settings={settings}>
                              {(fetchPeriodsProps) => {
                                this.fetchPeriodsProps = fetchPeriodsProps
                                return (
                                  <FetchMfp
                                    errorContext={this.errorContext}
                                    GetUserScenario={GetUserScenario}
                                    _savePivotSingleDetailViewSettings={this._savePivotSingleDetailViewSettings.bind(
                                      this
                                    )}
                                    getBaseRequestParameters={this.getBaseRequestParameters.bind(
                                      this
                                    )}
                                    getFilledMetricSelection={this.getFilledMetricSelection.bind(
                                      this
                                    )}
                                    getSelectedCurrency={this.getSelectedCurrency.bind(this)}
                                    pluginId={id}
                                    resetPivotLeftKeys={this._savePivotViewSettings.bind(this)}
                                    saveGetUserScenario={this.props.saveGetUserScenario.bind(this)}
                                    settings={settings}
                                  >
                                    {(fetchMfpProps) => {
                                      this.fetchMfpProps = fetchMfpProps
                                      return (
                                        <div
                                          ref={this.demandPlanningRef}
                                          className={`-dp-wrapper ${
                                            isSmallFontSize ? 'small-font-size' : ''
                                          }`}
                                          id={`dp-${id}`}
                                        >
                                          {isVisibleSubsetConfirmation ? (
                                            <Subset
                                              isScenarioEditable={isScenarioEditable}
                                              onApplyChanges={this._loadFromSubset.bind(this)}
                                              onDiscardHandle={this.onDiscardHandle.bind(this)}
                                            />
                                          ) : null}
                                          {isVisibleScenarioTree ? (
                                            <ScenarioTree
                                              IsAllowed={IsAllowed}
                                              ScenarioData={GetScenarioTree}
                                              _fetchPivotConfiguration={_fetchPivotConfiguration.bind(
                                                this
                                              )}
                                              _savePivotDetailViewSettings={this._savePivotDetailViewSettings.bind(
                                                this
                                              )}
                                              _savePivotSingleDetailViewSettings={this._savePivotSingleDetailViewSettings.bind(
                                                this
                                              )}
                                              _savePivotViewSettings={this._savePivotViewSettings.bind(
                                                this
                                              )}
                                              _saveScenarioComparisonSettings={this._saveScenarioComparisonSettings.bind(
                                                this
                                              )}
                                              currentView={this.getCurrentView()}
                                              generateReport={registerProps.generateReport.bind(
                                                this
                                              )}
                                              getScenarioParameters={this.getScenarioParameters.bind(
                                                this
                                              )}
                                              hasMfpData={!_.isEmpty(GetMfpData)}
                                              isVisibleGenerateReport={!_.isEmpty(groupBy)}
                                              pluginId={id}
                                              saveMfpSettings={this.props.saveMfpSettings.bind(
                                                this
                                              )}
                                              scenarioLoaded={registerProps.scenarioLoaded.bind(
                                                this
                                              )}
                                              setEditable={this.setEditable.bind(this)}
                                              settings={scenarioTreeSettings}
                                              onGetPivotConfigurationLoaded={this.onGetPivotConfigurationLoaded.bind(
                                                this
                                              )}
                                              onViewChangedCallback={this.onViewChangedCallback.bind(
                                                this
                                              )}
                                            />
                                          ) : null}
                                          {isVisiblePivotBuilder ? (
                                            <MainContainer>
                                              <PivotBuilder
                                                _savePivotBuilderViewSettings={this._savePivotBuilderViewSettings.bind(
                                                  this
                                                )}
                                                createDraftPivotBuilderState={this.commonPivotBuilderProps.createDraftPivotBuilderState.bind(
                                                  this
                                                )}
                                                createPivotBuilderState={this.commonPivotBuilderProps.createPivotBuilderState.bind(
                                                  this
                                                )}
                                                currentView={this.getCurrentView()}
                                                deletePivotBuilderState={this.commonPivotBuilderProps.deletePivotBuilderState.bind(
                                                  this
                                                )}
                                                executePivotMainConfiguration={this.commonPivotBuilderProps.executePivotMainConfiguration.bind(
                                                  this
                                                )}
                                                getSelectedPivotBuilderState={this.commonPivotBuilderProps.getSelectedPivotBuilderState.bind(
                                                  this
                                                )}
                                                isMarkAsPublicAllowed={IsMarkAsPublicAllowed}
                                                pivotBuilderSettings={pivotBuilderSettings}
                                                pivotData={GetPivotConfiguration}
                                                pivotState={{ ...pivotMainConfiguration }}
                                                pluginStates={$pluginStates}
                                                updatePivotBuilderState={this.commonPivotBuilderProps.updatePivotBuilderState.bind(
                                                  this
                                                )}
                                                userInfo={userInfo}
                                                onViewChangedCallback={this.onViewChangedCallback.bind(
                                                  this
                                                )}
                                              />
                                            </MainContainer>
                                          ) : null}
                                          {!isVisiblePivotBuilder &&
                                          !isVisibleScenarioTree &&
                                          !isVisibleSubsetConfirmation ? (
                                            <Header
                                              allowedToUpdate={allowedToUpdate}
                                              externalUpdateEnable={externalUpdateEnable}
                                              getBaseRequestParameters={this.getBaseRequestParameters.bind(
                                                this
                                              )}
                                              getPivotBuilderStates={this.commonPivotBuilderProps.getPivotBuilderStates.bind(
                                                this
                                              )}
                                              getSelectedPivotBuilderState={this.commonPivotBuilderProps.getSelectedPivotBuilderState.bind(
                                                this
                                              )}
                                              isEditModeAllowed={IsEditModeAllowed}
                                              isScenarioEditable={isScenarioEditable}
                                              isWorkInSubsetEnabled={isWorkInSubsetEnabled}
                                              pluginId={id}
                                              pluginStates={$pluginStates}
                                              preferredUsername={preferredUsername}
                                              rowUpdateEnable={rowUpdateEnable}
                                              settings={settings}
                                              updatePluginState={this.props.updatePluginState.bind(
                                                this
                                              )}
                                              onApplyChangesHandle={this.onApplyChangesHandle.bind(
                                                this
                                              )}
                                              onDiscardHandle={this.onDiscardHandle.bind(this)}
                                              onPivotStateChanged={this.commonPivotBuilderProps.onPivotStateChanged.bind(
                                                this
                                              )}
                                              onReloadViewClick={this._reloadPivotConfigurationAndView.bind(
                                                this
                                              )}
                                              onViewChangedCallback={this.onViewChangedCallback.bind(
                                                this
                                              )}
                                            />
                                          ) : null}
                                          {isVisiblePivotView ? (
                                            <header className="dp-header">
                                              <Timeline
                                                timePicker
                                                config={timelineConfig}
                                                periods={periods}
                                                pluginId={id}
                                                size={size}
                                                onPeriodUpdate={this.onPeriodUpdate.bind(this)}
                                              />
                                            </header>
                                          ) : null}
                                          {isVisiblePivotView ? (
                                            <CurrencyRates
                                              CurrencyRates={CurrencyRatesList}
                                              isVisible={isCurrencyModeActive}
                                              onReloadViewClick={this.onReloadViewClick.bind(this)}
                                            />
                                          ) : null}
                                          {isVisiblePivotView ? (
                                            <MainContainer>
                                              <NodeActions
                                                {...nodeActionProps}
                                                refresh={this.fetchMfpProps._fetchMfpData.bind(
                                                  this
                                                )}
                                              >
                                                {({ pivotNodeActionCallback = () => {} }) => {
                                                  return (
                                                    <Pivot
                                                      GetPivotConfiguration={GetPivotConfiguration}
                                                      IsApproveAllowed={IsApproveAllowed}
                                                      _fetchMfpData={this.fetchMfpProps._fetchMfpData.bind(
                                                        this
                                                      )}
                                                      _savePivotViewSettings={this._savePivotViewSettings.bind(
                                                        this
                                                      )}
                                                      buttonFormat={buttonFormat}
                                                      culture={culture}
                                                      isScenarioEditable={isScenarioEditable}
                                                      leftHierarchy={selectedLeftKeys}
                                                      mergeLeftFieldsAndKeys={this.mergeLeftFieldsAndKeys.bind(
                                                        this
                                                      )}
                                                      nodeActionCallback={pivotNodeActionCallback.bind(
                                                        this
                                                      )}
                                                      pivotData={GetMfpData}
                                                      pivotDetailCallback={this.pivotDetailCallback.bind(
                                                        this
                                                      )}
                                                      pivotSingleDetailCallback={this.pivotSingleDetailCallback.bind(
                                                        this
                                                      )}
                                                      pivotState={pivotMainConfiguration}
                                                      pluginId={id}
                                                      reqLeftFields={ReqLeftFields}
                                                      rowClicked={this.onRowClickedCallback.bind(
                                                        this
                                                      )}
                                                      setHierarchy={this.setHierarchy.bind(this)}
                                                      settings={configObj}
                                                      stateHandler={this.stateHandler}
                                                      statePicker={StatePicker}
                                                      toggleLoader={this.toggleLoader.bind(this)}
                                                      updateCallback={this.updateCallback.bind(
                                                        this
                                                      )}
                                                      updateExternalCallback={this.updateExternalCallback.bind(
                                                        this
                                                      )}
                                                      updateMode={updateMode}
                                                      viewMode={mode}
                                                      onScenarioComparisonClick={this.onScenarioComparisonClick.bind(
                                                        this
                                                      )}
                                                      onViewChangedCallback={this.onViewChangedCallback.bind(
                                                        this
                                                      )}
                                                    />
                                                  )
                                                }}
                                              </NodeActions>
                                            </MainContainer>
                                          ) : null}
                                          {isVisiblePivotDetailView ? (
                                            <MainContainer extraClasses="-overflow-none -detail-m">
                                              <div className="dp-pivot-modal">
                                                <div className="pm-wrp">
                                                  <div className="-pm-header">
                                                    <div className="-pm-nav">
                                                      <GoToMainButton
                                                        pluginId={id}
                                                        reloadView={this.reloadView.bind(this)}
                                                        onViewChangedCallback={this.onViewChangedCallback.bind(
                                                          this
                                                        )}
                                                      />
                                                      <HierarchyTree
                                                        canGoToLastLevel
                                                        getBaseRequestParameters={this.getBaseRequestParameters.bind(
                                                          this
                                                        )}
                                                        mergeLeftFieldsAndKeys={this.mergeLeftFieldsAndKeys.bind(
                                                          this
                                                        )}
                                                        saveHierarchyTreeSettings={this._savePivotDetailViewSettings.bind(
                                                          this
                                                        )}
                                                        selectedBreadcrumbs={detailBreadcrumbs}
                                                        onReloadViewClick={this.onReloadViewClick.bind(
                                                          this
                                                        )}
                                                      />
                                                    </div>
                                                    <Timeline
                                                      timePicker
                                                      config={timelineConfig}
                                                      periods={periods}
                                                      pluginId={id}
                                                      size={size}
                                                      onPeriodUpdate={this.onPeriodUpdate.bind(
                                                        this
                                                      )}
                                                    />
                                                    <CurrencyRates
                                                      CurrencyRates={CurrencyRatesList}
                                                      isVisible={isCurrencyModeActive}
                                                      onReloadViewClick={this.onReloadViewClick.bind(
                                                        this
                                                      )}
                                                    />
                                                  </div>
                                                  <NodeActions
                                                    {...nodeActionProps}
                                                    refresh={this.fetchMfpProps._fetchMfpDetailData.bind(
                                                      this
                                                    )}
                                                  >
                                                    {({
                                                      pivotDetailNodeActionCallback = () => {}
                                                    }) => {
                                                      return (
                                                        <PivotDetail
                                                          buttonFormat={buttonFormat}
                                                          culture={culture}
                                                          isScenarioEditable={isScenarioEditable}
                                                          leftHierarchy={detailLeftHierarchy}
                                                          nodeActionCallback={pivotDetailNodeActionCallback.bind(
                                                            this
                                                          )}
                                                          pivotCallback={this.pivotDetailCallback.bind(
                                                            this
                                                          )}
                                                          pivotData={GetMfpDetailData}
                                                          pivotState={pivotMainConfiguration}
                                                          setHierarchy={this.setHierarchy}
                                                          settings={detailConfigObj}
                                                          single={false}
                                                          stateHandler={this.stateHandler}
                                                          statePicker={StatePicker}
                                                          updateCallback={this.updateDetailCallback.bind(
                                                            this
                                                          )}
                                                          updateExternalCallback={this.updateDetailExternalCallback.bind(
                                                            this
                                                          )}
                                                          updateMode={updateMode}
                                                          viewMode={mode}
                                                        />
                                                      )
                                                    }}
                                                  </NodeActions>
                                                </div>
                                              </div>
                                            </MainContainer>
                                          ) : null}
                                          {isVisiblePivotSingleDetailView ? (
                                            <MainContainer extraClasses="-overflow-none">
                                              <div
                                                className={`pivot-single-detail-view dp-pivot-modal ${
                                                  multiColumnEnabled ? '-multi-column' : ''
                                                }`}
                                              >
                                                <div className="pm-wrp">
                                                  <div className="-pm-header -single-detail-header">
                                                    {multiColumnEnabled ? (
                                                      <MetricSelection
                                                        className="single-selection"
                                                        metricFields={singleDetailMetricFields}
                                                        selectedMetricFields={
                                                          singleDetailSelectedMetricFields
                                                        }
                                                        onMetricChanged={this.onMetricChanged.bind(
                                                          this
                                                        )}
                                                      />
                                                    ) : (
                                                      <strong className="fw-bold -display-metric">
                                                        {singleDetailPivotFieldDisplayName}
                                                      </strong>
                                                    )}
                                                    <div className="-pm-nav">
                                                      <OverlayTrigger
                                                        shouldWrap={Boolean(
                                                          UpdateMfpDataPendingCount
                                                        )}
                                                        tooltip={_msg}
                                                      >
                                                        <GoToMainButton
                                                          notAllowed={Boolean(
                                                            UpdateMfpDataPendingCount
                                                          )}
                                                          pluginId={id}
                                                          reloadView={this.reloadView.bind(this)}
                                                          onViewChangedCallback={this.onViewChangedCallback.bind(
                                                            this
                                                          )}
                                                        />
                                                      </OverlayTrigger>
                                                      <HierarchyTree
                                                        canGoToLastLevel={false}
                                                        getBaseRequestParameters={this.getBaseRequestParameters.bind(
                                                          this
                                                        )}
                                                        mergeLeftFieldsAndKeys={this.mergeLeftFieldsAndKeys.bind(
                                                          this
                                                        )}
                                                        pendingCount={UpdateMfpDataPendingCount}
                                                        saveHierarchyTreeSettings={this._savePivotSingleDetailViewSettings.bind(
                                                          this
                                                        )}
                                                        selectedBreadcrumbs={
                                                          singleDetailBreadcrumbs
                                                        }
                                                        onReloadViewClick={this.onReloadViewClick.bind(
                                                          this
                                                        )}
                                                      />
                                                    </div>
                                                    <Timeline
                                                      timePicker
                                                      config={timelineConfig}
                                                      periods={periods}
                                                      pluginId={id}
                                                      size={size}
                                                      onPeriodUpdate={this.onPeriodUpdate.bind(
                                                        this
                                                      )}
                                                    />
                                                    <CurrencyRates
                                                      CurrencyRates={CurrencyRatesList}
                                                      isVisible={isCurrencyModeActive}
                                                      onReloadViewClick={this.onReloadViewClick.bind(
                                                        this
                                                      )}
                                                    />
                                                  </div>
                                                  <NodeActions
                                                    {...nodeActionProps}
                                                    refresh={this.fetchMfpProps._fetchMfpDetailDataForSingleColumn.bind(
                                                      this
                                                    )}
                                                  >
                                                    {({
                                                      pivotSingleDetailNodeActionCallback = () => {}
                                                    }) => {
                                                      return (
                                                        <PivotSingleDetail
                                                          buttonFormat={buttonFormat}
                                                          culture={culture}
                                                          getSettings={this.getSettings}
                                                          isScenarioEditable={isScenarioEditable}
                                                          leftHierarchy={singleDetailLeftHierarchy}
                                                          multiColumnEnabled={multiColumnEnabled}
                                                          nodeActionCallback={pivotSingleDetailNodeActionCallback.bind(
                                                            this
                                                          )}
                                                          pendingCount={UpdateMfpDataPendingCount}
                                                          pivotCallback={this.pivotSingleDetailCallback.bind(
                                                            this
                                                          )}
                                                          pivotData={
                                                            GetMfpDetailDataForSingleColumn
                                                          }
                                                          pivotField={
                                                            singleDetailPivotFieldMetricName
                                                          }
                                                          pivotState={pivotMainConfiguration}
                                                          pluginId={id}
                                                          rowClicked={this.onRowClickedCallback.bind(
                                                            this
                                                          )}
                                                          saveHeaderSettings={saveHeaderSettings.bind(
                                                            this
                                                          )}
                                                          selectedMetricFields={
                                                            singleDetailSelectedMetricFields
                                                          }
                                                          setHierarchy={this.setHierarchy}
                                                          settings={detailConfigObj}
                                                          singleDetailBreadcrumbs={
                                                            singleDetailBreadcrumbs
                                                          }
                                                          stateHandler={this.stateHandler}
                                                          statePicker={StatePicker}
                                                          timelineSettings={timelineSettings}
                                                          updateCallback={this.updateSingleDetailCallback.bind(
                                                            this
                                                          )}
                                                          updateExternalCallback={this.updateSingleDetailExternalCallback.bind(
                                                            this
                                                          )}
                                                          updateMode={updateMode}
                                                          viewMode={mode}
                                                        />
                                                      )
                                                    }}
                                                  </NodeActions>
                                                </div>
                                              </div>
                                            </MainContainer>
                                          ) : null}
                                          {isVisibleScenarioComparison ? (
                                            <NodeActions {...nodeActionProps} refresh={null}>
                                              {({
                                                scenarioComparisonNodeActionCallback = () => {}
                                              }) => {
                                                return (
                                                  <div className="-scenario-comparison">
                                                    <div className="-pm-header">
                                                      <div className="-pm-nav">
                                                        <GoToMainButton
                                                          pluginId={id}
                                                          reloadView={this.reloadView.bind(this)}
                                                          onViewChangedCallback={this.onViewChangedCallback.bind(
                                                            this
                                                          )}
                                                        />
                                                        <HierarchyTree
                                                          canGoToLastLevel
                                                          getBaseRequestParameters={this.getBaseRequestParameters.bind(
                                                            this
                                                          )}
                                                          mergeLeftFieldsAndKeys={this.mergeLeftFieldsAndKeys.bind(
                                                            this
                                                          )}
                                                          saveHierarchyTreeSettings={this._saveScenarioComparisonSettings.bind(
                                                            this
                                                          )}
                                                          selectedBreadcrumbs={
                                                            scenarioComparisonBreadcrumbs
                                                          }
                                                          onReloadViewClick={this.onReloadViewClick.bind(
                                                            this
                                                          )}
                                                        />
                                                      </div>
                                                    </div>
                                                    <Timeline
                                                      timePicker
                                                      config={timelineConfig}
                                                      periods={periods}
                                                      pluginId={id}
                                                      size={size}
                                                      onPeriodUpdate={this.onPeriodUpdate.bind(
                                                        this
                                                      )}
                                                    />
                                                    <ScenarioComparison
                                                      buttonFormat={buttonFormat}
                                                      catalogId={catalogId}
                                                      createPluginState={this.props.createPluginState.bind(
                                                        this
                                                      )}
                                                      culture={culture}
                                                      deletePluginState={this.props.deletePluginState.bind(
                                                        this
                                                      )}
                                                      errorContext={this.errorContext}
                                                      getBaseRequestParameters={this.getBaseRequestParameters.bind(
                                                        this
                                                      )}
                                                      getSelectedCurrency={this.getSelectedCurrency.bind(
                                                        this
                                                      )}
                                                      handleNodeAction={scenarioComparisonNodeActionCallback.bind(
                                                        this
                                                      )}
                                                      handleUpdate={this.scenarioComparisonUpdateCallback.bind(
                                                        this
                                                      )}
                                                      id={id}
                                                      isRowClickedEventOn={isRowClickedEventOn}
                                                      isScenarioEditable={isScenarioEditable}
                                                      missingRowLockMessage={missingRowLockMessage}
                                                      pluginStates={$pluginStates}
                                                      popConfirm={this.popConfirm.bind(this)}
                                                      rowClicked={this.onRowClickedCallback.bind(
                                                        this
                                                      )}
                                                      shouldShowConfirmApprovedCellUpdate={
                                                        shouldShowConfirmApprovedCellUpdate
                                                      }
                                                      updatePluginState={this.props.updatePluginState.bind(
                                                        this
                                                      )}
                                                      viewMode={mode}
                                                    />
                                                  </div>
                                                )
                                              }}
                                            </NodeActions>
                                          ) : null}
                                          {isVisibleLoader ? (
                                            <div
                                              className={cx('dp-loading', {
                                                '-stop': isAnimationStoppedLoader
                                              })}
                                            >
                                              <i
                                                onClick={() =>
                                                  isAnimationStoppedLoader
                                                    ? global.location.reload()
                                                    : false
                                                }
                                              >
                                                <SlvySpinner />
                                              </i>
                                            </div>
                                          ) : null}
                                        </div>
                                      )
                                    }}
                                  </FetchMfp>
                                )
                              }}
                            </FetchPeriods>
                          )
                        }}
                      </FetchPivotConfiguration>
                    )
                  }}
                </CommonPivotBuilder>
              </AppInitialization>
            )
          }}
        </RegisterEventMethod>
      </ErrorBoundary>
    )
  }
}
