// OVERRIDES
require('./../../../extjs/overrides/Calendar/Event')('MDM')
require('./../../../extjs/overrides/data/store.mdm')
require('./../../../extjs/overrides/data/store/reader')
require('./../../../extjs/overrides/clipboard')
require('./../../../extjs/overrides/ViewModel')('MDM')

// HIGHCHART
require('./../../../extjs/src/Chart/ux/require.js')

require('./src/ux/VisComp/ComponentBase')
require('./src/ux/VisComp/Component')
require('./src/ux/FullScreen/Button')
require('./src/ux/FullScreen/Util')
require('./src/ux/VisComp/Echarts/Echarts')
require('./src/ux/VisComp/Leaflet/Map')

require('./src/ux/CellContext')
require('./src/ux/ComboWithClear')
require('./src/ux/DetailsBox')
require('./src/ux/DragDrop')
require('./src/ux/ErrorBox')
require('./src/ux/GridPanel')
require('./src/ux/GridPicker')
require('./src/ux/OnError')
require('./src/ux/Replicator')

require('./src/Global')

require('./src/CellDragDrop') //
require('./src/Task') //

require('./src/model/Base')
require('./src/model/BaseAll')
require('./src/model/BaseAutoSync')
require('./src/model/BaseForm')
require('./src/model/BaseTree')
require('./src/model/DPiProductGroup')
require('./src/model/DpiSegment')
