import React from 'react'
import { MESSAGES } from '../../messages'
import { ISubsetProps } from './Subset.types'

export function Subset({ isScenarioEditable, onDiscardHandle, onApplyChanges }: ISubsetProps) {
  return (
    <div className="scenario-tree is-active -default">
      <div className="scenario-lib-wrapper">
        <div className="a-wr">
          <nav className="-alert-action">
            <div className="-message">
              {isScenarioEditable ? (
                <>
                  <i className="slvy-ui-icon-plug" />
                  <h1>{MESSAGES.you_have_unsaved_changed_from_your_previous_session}</h1>
                  <h2>{MESSAGES.would_you_like_to_continue_working_on_them}</h2>
                  <h3>{MESSAGES.if_not_your_changes_will_be_discarded}</h3>
                </>
              ) : (
                <>
                  <i className="slvy-ui-icon-plug" />
                  <h1>{MESSAGES.your_scenario_is_not_editable}</h1>
                </>
              )}
            </div>
            <div className="sc-btn-group -center -clear">
              <div className="-scenario-action-group">
                <span className="cell-btn -delete" onClick={() => onDiscardHandle()}>
                  <i className="slvy-ui-icon-trash-can" /> {MESSAGES.discard}
                </span>
                {isScenarioEditable ? (
                  <span className="cell-btn -apply" onClick={() => onApplyChanges()}>
                    <i className="slvy-ui-icon-check-extra-lt" /> {MESSAGES.continue}
                  </span>
                ) : null}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
