import { useMemo, useState } from 'react'
import { setCustomAggregations } from '../../helpers'
import { LocaleText, UseCustomAggregationsParams } from '../../types'

const useCustomAggregations = ({ columnDefs, data, gridRef }: UseCustomAggregationsParams) => {
  const [isVisibleCustomAggPopup, setIsVisibleCustomAggPopup] = useState(true)
  const { customAggregationFailed: customAggregationFailedLocale } =
    (gridRef?.current?.api?.getGridOption?.('localeText') as LocaleText) || {}

  const customAgg = useMemo(() => {
    return setCustomAggregations(
      { columnDefs, data, customAggregationFailedLocale },
      isVisibleCustomAggPopup,
      setIsVisibleCustomAggPopup
    )
  }, [columnDefs, data, customAggregationFailedLocale, isVisibleCustomAggPopup])

  return { customAgg }
}

export default useCustomAggregations
