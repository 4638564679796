export default `({
    columnLines: true,
    columns: [{
            xtype: 'actioncolumn',
            width: 30,
            items: [{
                iconCls: 'x-fa fa-dot-circle-o',
                tooltip: 'Show Vendor Analysis',
                align: 'center',
                handler: function (grid, rowIndex, colIndex) {
                    var rec = grid.getStore().getAt(rowIndex);
                    grid.fireEvent('clickforDetail', rec);
                    Ext.create('Spui.view.drilldown.Vendor.Main').show();
                }
            }]
        },
        {
            text: 'DcCode',
            dataIndex: 'DcCode',
            width: 90
        },
        {
            text: 'DcName',
            dataIndex: 'DcName',
            width: 150,
            flex: 1
        },
        {
            text: 'Store',
            columns: [{
                    text: 'AcvtiveStore',
                    dataIndex: 'NumberOfOpenStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: 'HasInventory',
                    dataIndex: 'NumberofStoreHasInventory',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: 'TotaInventory',
                    dataIndex: 'TotalStoreInventory',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn'
                }
            ]
        },
        {
            text: 'DcInventory',
            dataIndex: 'DcInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: 'TotalInventory',
            dataIndex: 'TotalInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        }
    ],
})
`
