import { useMemo } from 'react'
import { PaginationProps, PaginationPropsReturnType } from './usePaginationProps.types'

function usePaginationProps({
  pagination,
  isPagedQuery,
  pageSize,
  totalRowCount,
  totalPage
}: PaginationProps) {
  const isPaginationEnabled = Boolean(pagination && isPagedQuery())

  const paginationProps = useMemo((): PaginationPropsReturnType => {
    if (!isPaginationEnabled) {
      return {}
    }

    return {
      pageSize,
      totalRowCount,
      totalPage
    }
  }, [isPaginationEnabled, pageSize, totalRowCount, totalPage])

  return { isPaginationEnabled, paginationProps, totalRowCount, totalPage }
}

export default usePaginationProps
