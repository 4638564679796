import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import cx from 'classnames'
import { isEmpty, isNil } from 'lodash'
import { connect } from 'react-redux'

import { toggleMoveMode, resetMoveMode } from '../../../../actions/moveMode'
import { useActions, useEvent } from '../../../../../../hooks'
import { moveExceptions } from '../../../../utils/constants'
import { patchDiff } from '../../../../utils'

import { SlvySelect, WizardModal } from '../../../../../../components'
import DestSelect from './DestSelect'
import ProductionAdjustment from './ProductionAdjustment'
import SuccessView from './SuccessView'
import ExceptionView from './ExceptionView'

import styles from './index.module.scss'

function getAvailableResources(resources, moveModeItems) {
  const products = moveModeItems.filter((item) => item.type === 'Product')
  return resources.reduce((resources, resource, index) => {
    const isResourceAvailable = products
      .filter((product) => product.isSelected)
      .every((product) => product.resourceIds.includes(resource.Id.toString()))

    if (isResourceAvailable) {
      resources = resources.concat({ name: resource.Name, id: resource.Id, index })
    }

    return resources
  }, [])
}

function getPeriods(periods) {
  return periods.reduce((periods, period, index) => {
    if (period.Name !== 'Grand Total') {
      periods = periods.concat({ name: period.Name, id: period.Id, index })
    }

    return periods
  }, [])
}

function getProducts(items, resourcePeriods, sourcePeriodIndex) {
  return items
    .filter((item) => item.type === 'Product')
    .map((product) => {
      const {
        [product.resourceIndex]: {
          [sourcePeriodIndex]: { ResourcePeriodProducts }
        }
      } = resourcePeriods
      const resourceProductPeriod =
        ResourcePeriodProducts.find(
          (resourcePeriodProduct) => resourcePeriodProduct?.ProductIndex === product.index
        ) ?? {}
      const { Production = 0 } = resourceProductPeriod

      return { ...product, production: Production }
    })
}

function getProductIdMoves(products) {
  return products.reduce((productIdMoves, product) => {
    if (product.isSelected) {
      productIdMoves[product.id] = product.production
    }

    return productIdMoves
  }, {})
}

function getIsNextBtnDisabled(destKey, destResource, destPeriod) {
  const otherDest = destKey === 'destResource' ? destPeriod : destResource
  return isEmpty(otherDest)
}

function getIsForceMoveView(forceMoveView, isForceMove) {
  return !isEmpty(forceMoveView) && isForceMove
}

function MoveMode({ selectedSession, connectApi, setAppState }) {
  const [views, setViews] = useState({})
  const [showWizardModal, setShowWizardModal] = useState(false)
  const [isForceMove, setIsForceMove] = useState(false)

  const [clickedCell, moveMode, supplyPlanRes] = useSelector(
    (state) => [
      state.clickedProductInfo.clickedProductInfo,
      state.moveMode,
      state.resourcePeriodHierarchy
    ],
    shallowEqual
  )
  const [onToggleMoveMode, onResetMoveMode] = useActions([toggleMoveMode, resetMoveMode])

  useEffect(() => {
    setViews(initialViews())
  }, [])

  const handleMountProductionAdjustment = useEvent(() => {
    if (isEmpty(views.productionAdjustment.products)) {
      const products = getProducts(
        Object.values(moveMode.items),
        supplyPlanRes.ResourcePeriods,
        views.destSelect.sourcePeriod.index
      )

      setViews((views) => ({
        ...views,
        productionAdjustment: {
          ...views.productionAdjustment,
          products
        }
      }))
    }

    if (!views.last.isLoading) {
      setViews((views) => ({
        ...views,
        last: {
          component: SuccessView,
          isLoading: true,
          wizardProps: {
            controls: {
              backBtn: {
                show: false
              },
              nextBtn: {
                disabled: true,
                text: 'Close',
                onClick: handleHideWizardModal
              }
            }
          },
          onMount: handleMountSuccessView
        },
        forceMove: {}
      }))
    }
  })

  const handleMountSuccessView = useEvent(async () => {
    try {
      const res = await moveProductions()

      const isSuccess = res.Code === 1
      const isWarning = res.Code === 0
      const isError = res.Code < 0

      if (isSuccess) {
        const diff = {
          left: supplyPlanRes,
          diffJson: JSON.parse(res.Json),
          isDiff: res.IsDiff,
          stateName: 'GetRccpResourcePeriodHierarchyResponseWPF',
          setState: setAppState
        }
        patchDiff(diff)

        const isForceMoveView = getIsForceMoveView(views.forceMove, isForceMove)
        const view = isForceMoveView ? 'forceMove' : 'last'
        setViews((views) => ({
          ...views,
          [view]: {
            ...views[view],
            isLoading: false,
            wizardProps: {
              controls: {
                ...views[view].wizardProps.controls,
                nextBtn: {
                  ...views[view].wizardProps.controls.nextBtn,
                  disabled: false
                }
              }
            }
          }
        }))
      }
      if (isWarning) {
        handleWarnings(res.MoveSummaries)
      }
      if (isError) {
        handleErrors(res.Code, res.MoveSummaries)
      }
    } catch (_) {
      handleErrors()
    }
  })

  function handleChangeDest(dest, destKey) {
    setViews((views) => {
      const isNextBtnDisabled = getIsNextBtnDisabled(
        destKey,
        views.destSelect.destResource,
        views.destSelect.destPeriod
      )
      const isSourcePeriod = destKey === 'sourcePeriod'
      const products = isSourcePeriod ? null : views.productionAdjustment.products

      return {
        ...views,
        destSelect: {
          ...views.destSelect,
          [destKey]: dest,
          wizardProps: {
            controls: {
              ...views.destSelect.wizardProps.controls,
              nextBtn: {
                ...views.destSelect.wizardProps.controls.nextBtn,
                disabled: isNextBtnDisabled
              }
            }
          }
        },
        productionAdjustment: {
          ...views.productionAdjustment,
          products
        }
      }
    })
  }

  function handleChangeProduction(production, productIndex) {
    setViews((views) => {
      const products = views.productionAdjustment.products.map((product) =>
        product.index === productIndex ? { ...product, production: production } : product
      )

      return {
        ...views,
        productionAdjustment: {
          ...views.productionAdjustment,
          products
        }
      }
    })
  }

  function handleClickAvailableValue(value, productIndex, availableValueIndex) {
    setViews((views) => {
      const products = views.productionAdjustment.products.map((product) =>
        product.index === productIndex
          ? { ...product, production: value, selectedAvailableValueIndex: availableValueIndex }
          : product
      )
      const isMoveBtnDisabled = !products
        .filter((product) => product.isSelected)
        .every((product) => !isNil(product.production))

      return {
        ...views,
        productionAdjustment: {
          ...views.productionAdjustment,
          products,
          wizardProps: {
            controls: {
              ...views.productionAdjustment.wizardProps.controls,
              nextBtn: {
                ...views.productionAdjustment.wizardProps.controls.nextBtn,
                disabled: isMoveBtnDisabled
              }
            }
          }
        }
      }
    })
  }

  function handleClickRemoveProductBtn(productIndex) {
    setViews((views) => {
      const products = views.productionAdjustment.products.filter(
        (product) => product.index !== productIndex
      )

      return {
        ...views,
        productionAdjustment: {
          ...views.productionAdjustment,
          products
        }
      }
    })
  }

  function handleClickMoveModeBtn() {
    if (moveMode.isOn) {
      onResetMoveMode()
    } else {
      onToggleMoveMode()
    }
  }

  function handleClickMoveBtn() {
    const resources = getAvailableResources(supplyPlanRes.Resources, Object.values(moveMode.items))
    const periods = getPeriods(supplyPlanRes.Periods)
    const firstPeriod = periods.at(0)
    const sourcePeriod = isNil(clickedCell.periodId)
      ? firstPeriod
      : {
          name: clickedCell.periodName,
          id: clickedCell.periodId,
          index: clickedCell.periodIndex
        }

    setViews((views) => ({
      ...views,
      destSelect: {
        ...views.destSelect,
        sourceResource: {
          name: clickedCell.resourceName,
          id: clickedCell.resourceId
        },
        sourcePeriod,
        resources,
        periods
      }
    }))
    setShowWizardModal(true)
  }

  function handleHideWizardModal() {
    setViews(initialViews())
    setShowWizardModal(false)
    setIsForceMove(false)
  }

  function handleWarnings(moveSummaries) {
    const { ProductId = '', Description = '' } =
      moveSummaries?.find((moveSummary) => moveSummary.Code == 0) ?? {}
    const message = getExceptionMessage({ Code: 0, ProductId, Description })
    const detailedMoveSummaries = moveSummaries.map((moveSummary) => ({
      ...moveSummary,
      Message: getExceptionMessage(moveSummary)
    }))
    const products = mapProductMoveSummaries(views.productionAdjustment.products, moveSummaries)

    setViews((views) => ({
      ...views,
      productionAdjustment: {
        ...views.productionAdjustment,
        products,
        wizardProps: {
          controls: {
            backBtn: {
              ...views.productionAdjustment.wizardProps.controls.backBtn,
              disabled: true
            },
            nextBtn: {
              ...views.productionAdjustment.wizardProps.controls.nextBtn,
              disabled: true
            }
          }
        }
      },
      last: {
        ...views.last,
        component: ExceptionView,
        isLoading: false,
        message,
        moveSummaries: detailedMoveSummaries,
        code: 0,
        wizardProps: {
          controls: {
            nextBtn: {
              text: 'Force Move',
              variant: 'success'
            }
          }
        }
      },
      forceMove: {
        component: SuccessView,
        isLoading: true,
        wizardProps: {
          controls: {
            backBtn: {
              show: false
            },
            nextBtn: {
              text: 'Close',
              disabled: true,
              onClick: handleHideWizardModal
            }
          }
        },
        onMount: handleMountSuccessView
      }
    }))
    setIsForceMove(true)
  }

  function getExceptionMessage({ Code, ProductId, Description }) {
    switch (Code) {
      case 2:
        return moveExceptions[Code].getMessage(Description)
      case 1:
        return moveExceptions[Code].getMessage(supplyPlanRes.Products, ProductId)
      case 0:
        return moveExceptions[Code].getMessage(Description)
      case -1:
        return moveExceptions[Code].getMessage(views.destSelect.sourceResource.name)
      case -2:
        return moveExceptions[Code].getMessage(views.destSelect.sourcePeriod.name)
      case -3:
        return moveExceptions[Code].getMessage(views.destSelect.destResource.name)
      case -4:
        return moveExceptions[Code].getMessage(views.destSelect.destPeriod.name)
      case -5:
        return moveExceptions[Code].getMessage(supplyPlanRes.Products, ProductId)
      case -6:
        return moveExceptions[Code].getMessage()
      case -10:
        return moveExceptions[Code].getMessage(
          views.destSelect.destResource.name,
          views.destSelect.destPeriod.name
        )
      case -101:
        return moveExceptions[Code].getMessage(supplyPlanRes.Products, ProductId)
      case -102:
        return moveExceptions[Code].getMessage(supplyPlanRes.Products, ProductId)
      case -103:
        return moveExceptions[Code].getMessage(supplyPlanRes.Products, ProductId)
      case -110:
        return moveExceptions[Code].getMessage(
          views.destSelect.sourceResource.name,
          views.destSelect.sourcePeriod.name
        )
      case -111:
        return moveExceptions[Code].getMessage(
          views.destSelect.destResource.name,
          views.destSelect.destPeriod.name
        )
      default:
        return 'Something went wrong. Please try again later.'
    }
  }

  function handleErrors(code, moveSummaries) {
    setViews((views) => {
      const isForceMoveView = getIsForceMoveView(views.forceMove, isForceMove)
      const view = isForceMoveView ? 'forceMove' : 'last'
      const { ProductId = '', Description } =
        moveSummaries?.find((moveSummary) => moveSummary.Code < 0) ?? {}
      const message = getExceptionMessage({ Code: code, ProductId, Description })
      const detailedMoveSummaries = moveSummaries?.map((moveSummary) => ({
        ...moveSummary,
        Message: getExceptionMessage(moveSummary)
      }))
      const products = mapProductMoveSummaries(views.productionAdjustment.products, moveSummaries)

      return {
        ...views,
        productionAdjustment: {
          ...views.productionAdjustment,
          products
        },
        [view]: {
          component: ExceptionView,
          message,
          moveSummaries: detailedMoveSummaries,
          code,
          isLoading: false,
          wizardProps: {
            controls: {
              nextBtn: {
                ...views[view].wizardProps.controls.nextBtn,
                disabled: false
              }
            }
          }
        }
      }
    })
  }

  function mapProductMoveSummaries(products, moveSummaries) {
    return products?.map((product) => {
      const moveSummary =
        moveSummaries?.find((moveSummary) => moveSummary.ProductId === product.id) ?? {}
      const isSuccess = moveSummary.Code === 1
      const isWarning = moveSummary.Code === 0
      const isError = moveSummary.Code < 0

      moveSummary.message = getExceptionMessage(moveSummary)
      if (isSuccess) {
        moveSummary.status = 'success'
      }
      if (isWarning) {
        moveSummary.status = 'warning'
      }
      if (isError) {
        moveSummary.status = 'error'
      }

      product = {
        ...product,
        moveSummary
      }

      return product
    })
  }

  async function moveProductions() {
    const productIdMoves = getProductIdMoves(views.productionAdjustment.products)
    const payload = {
      sourceResourceId: views.destSelect.sourceResource.id,
      sourcePeriodName: views.destSelect.sourcePeriod.name,
      destResourceId: views.destSelect.destResource.id,
      destPeriodName: views.destSelect.destPeriod.name,
      productIdMoves,
      hierProductId: -1,
      parameter: '',
      isQueue: true,
      rccpConfig: {
        ForceUpdate: isForceMove
      }
    }

    return await connectApi('MoveResourcePeriodAndReturnHierarchyDiff', payload)
  }

  function initialViews() {
    return {
      destSelect: {
        component: DestSelect,
        sourceResource: null,
        sourcePeriod: null,
        destResource: null,
        destPeriod: null,
        resources: null,
        periods: null,
        wizardProps: {
          controls: {
            nextBtn: {
              disabled: true
            }
          }
        },
        onChangeDest: handleChangeDest
      },
      productionAdjustment: {
        component: ProductionAdjustment,
        products: null,
        wizardProps: {
          controls: {
            nextBtn: {
              text: 'Move',
              variant: 'success'
            }
          }
        },
        onMount: handleMountProductionAdjustment,
        onChangeProduction: handleChangeProduction,
        onClickAvailableValue: handleClickAvailableValue,
        onClickRemoveProductBtn: handleClickRemoveProductBtn
      },
      last: {
        component: SuccessView,
        isLoading: true,
        wizardProps: {
          controls: {
            backBtn: {
              show: false
            },
            nextBtn: {
              text: 'Close',
              disabled: true,
              onClick: handleHideWizardModal
            }
          }
        },
        onMount: handleMountSuccessView
      }
    }
  }

  const isToggleBtnDisabled = selectedSession.IsDebug || isNil(clickedCell.resourceId)
  const isMoveBtnDisabled = !Object.values(moveMode.items)
    .filter((item) => item.type === 'Product')
    .some((product) => product.isSelected)
  const toggleBtnClassName = cx(
    styles.moveModeToggleBtn,
    { [styles.isActive]: moveMode.isOn },
    { [styles.isDisabled]: isToggleBtnDisabled }
  )
  const moveBtnClassName = cx(styles.moveModeMoveBtn, {
    [styles.isDisabled]: isMoveBtnDisabled
  })

  return (
    <>
      <button
        className={toggleBtnClassName}
        disabled={isToggleBtnDisabled}
        onClick={handleClickMoveModeBtn}
      >
        <i className={`fa fa-fw fa slvy-ui-icon-transfer ${styles.moveModeBtnIcon}`} />
        move mode
      </button>
      {moveMode.isOn && (
        <button
          className={moveBtnClassName}
          disabled={isMoveBtnDisabled}
          onClick={handleClickMoveBtn}
        >
          <i className={`fa fa-fw fa slvy-ui-icon-circle-check ${styles.moveModeBtnIcon}`} />
          move
        </button>
      )}
      <WizardModal
        className={styles.moveModeModal}
        views={views}
        show={showWizardModal}
        onHide={handleHideWizardModal}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedSession: state.resourcePlan.selectedSession
})

export default connect(mapStateToProps)(MoveMode)
