import ReactDOM from 'react-dom'
import store from './store/index'
import Root from './routes'
import RootErr from './routesErr'
import { setupExtJsComponents } from './extjs'
import { BUILD_ID } from './constants'

import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import '@fortawesome/fontawesome-free/scss/brands.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'
import '@fortawesome/fontawesome-free/scss/v4-shims.scss'
import './assets/scripts/fontawesome-kit'

import './assets/styles/default.scss'
import 'slvy-ui-icons/style.css'

import 'bootstrap/dist/js/bootstrap'
import 'toastr/toastr.scss'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-sortable-tree-patch-react-17/style.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

window.BUILD_ID = BUILD_ID

const isWithoutExtJs = process.argv.includes('--without-extjs')

if (isWithoutExtJs) {
  renderWithoutExtJs()
} else {
  renderWithExtJs()
}

function renderWithoutExtJs() {
  setTimeout(() => {
    ReactDOM.render(<Root store={store} />, document.getElementById('root'))
  }, 0)
}

function renderWithExtJs() {
  const initialize = global?.Ext?.onReady || ((fn) => fn())
  const reactDom = async () => {
    const { isReady, msg } = await setupExtJsComponents(Ext.isReady)

    ReactDOM.render(
      isReady ? <Root store={store} /> : <RootErr msg={msg} />,
      document.getElementById('root')
    )

    if (isReady) {
      if (!document.getElementById('ext-quicktips-tip')) {
        Ext.tip.QuickTipManager.init()
      }
    }
  }

  initialize(reactDom)
}
