export default () => {
  return {
    title: 'Filter',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Filter Settings',
        properties: {
          label: {
            type: 'string',
            title: 'Label'
          },
          placeholder: {
            type: 'string',
            default: 'Search',
            title: 'Placeholder'
          },
          multiple: {
            type: 'boolean',
            title: 'Is Multiple',
            required: true,
            default: false
          },
          defaultValue: {
            type: 'string',
            title: 'Default Value'
          },
          defaultItemFormula: {
            type: 'object',
            title: 'Default Item Formula',
            options: {
              collapsed: true
            },
            properties: {
              enabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              nthItem: {
                type: 'number',
                title: 'Nth Item',
                default: 1
              },
              location: {
                type: 'string',
                title: 'From',
                enum: ['Top', 'Bottom'],
                required: true
              }
            }
          },
          buttonFilter: {
            type: 'object',
            title: 'Button Filter',
            options: { collapsed: true },
            properties: {
              enabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              displayLabel: {
                type: 'boolean',
                title: 'Display Label',
                default: false,
                required: true
              },
              isStacked: {
                type: 'boolean',
                title: 'Stacked Mode',
                default: false,
                required: true
              },
              verticalMode: {
                type: 'boolean',
                title: 'Vertical Mode',
                default: false,
                required: true
              },
              isRadio: {
                type: 'boolean',
                title: 'Radio buttons',
                default: false,
                required: true
              },
              radioButtonStyle: {
                type: 'string',
                title: 'Radio button style',
                default: 'Default',
                enum: ['Default', 'Sleek'],
                required: true
              },
              style: {
                type: 'string',
                title: 'Style',
                default: 'success',
                enum: ['', 'primary', 'success', 'info', 'warning', 'danger', 'link', 'custom'],
                required: true
              },
              customColor: {
                type: 'string',
                format: 'Color',
                title: 'Custom Style Color',
                default: '#ffffff'
              },
              icon: {
                type: 'string',
                title: 'Icon',
                format: 'IconSelector'
              },
              iconPosition: {
                type: 'string',
                title: 'Icon Position',
                default: 'Left',
                enum: ['Left', 'Right'],
                required: true
              }
            }
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          display: {
            type: 'string',
            title: 'Display',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          tooltip: {
            type: 'string',
            title: 'Tooltip',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },

          isDefault: {
            type: 'string',
            title: 'Is Default',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          additionalFields: {
            title: 'Additional Fields',
            type: 'array',
            items: {
              type: 'object',
              title: 'Additional Field',
              headerTemplate: '{{ self.fieldName }}',
              options: {
                collapsed: true
              },
              properties: {
                fieldName: {
                  type: 'string',
                  title: 'Data Field',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds'
                }
              }
            }
          }
        }
      },
      items: {
        type: 'object',
        title: 'Items',
        properties: {
          items: {
            type: 'array',
            format: 'table',
            title: 'Items',
            items: {
              type: 'object',
              title: 'Item',
              properties: {
                value: {
                  type: 'string',
                  title: 'Value'
                },
                display: {
                  type: 'string',
                  title: 'Display'
                }
              }
            }
          }
        }
      }
    }
  }
}
