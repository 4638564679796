import React from 'react'
import _ from 'lodash'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { slvyToast } from '../../../../components'

export default class AssortmentDropdown extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  onFilterChanged(_options, event) {
    const { target: { value: selectedValue = '', selectedOptions = {} } = {} } = event

    const {
      isMultiple = false,
      limitedAt = 1,
      dropdownKey = '',
      onFilterChanged = () => {}
    } = this.props

    // TODO: parseFloat ===
    // eslint-disable-next-line
    let selectedItem = [..._options].filter((item) => item.key == selectedValue)[0]

    if (isMultiple) {
      const selectedTxt = Object.values(selectedOptions).map((item) => item.text)
      selectedItem = [..._options]
        .filter((item) => {
          return selectedTxt.filter((text) => text === item.key).length > 0
        })
        .map(({ key }) => key)

      if (limitedAt < selectedItem.length) {
        slvyToast.error({ message: `${dropdownKey} selection limited to ${limitedAt}!` })
        return
      }
    }

    onFilterChanged(dropdownKey, selectedItem, this.props)
  }

  getValidationClass() {
    const {
      props: { isTouched = false, isRequired = false, isMultiple = false, selected = null }
    } = this

    let validateClass = ''

    if (isTouched) {
      const $selected =
        isMultiple && _.isArray(selected)
          ? selected.filter((item) => item === '' || item === null).length === 0
          : selected
      if (!isRequired || (isRequired && $selected)) {
        validateClass = 'success'
      } else if (isRequired && !$selected) {
        validateClass = 'error'
      }
    }

    return validateClass
  }

  onResetField() {
    const {
      props,
      props: { dropdownKey = '', isMultiple = false, onFilterChanged = () => {} }
    } = this
    onFilterChanged(dropdownKey, isMultiple ? [] : null, props)
  }

  getOptionTemplate(options) {
    return options
      ? options.map((option, index) => (
          <option key={index} value={option.key}>
            {option.value}
          </option>
        ))
      : null
  }

  getTooltip(text, id = Math.random()) {
    return <Tooltip id={id}>{text}</Tooltip>
  }

  render() {
    const {
      props: {
        options = [],
        groups = [],
        selected = null,
        selectedGroup = null,
        canAddEmpty = true,
        isDisabled = false,
        isMultiple = false,
        isGrouped = false,
        noClass = false
      }
    } = this

    let isDisabledGroup = false

    const _options = [...options]
    if (canAddEmpty && !isMultiple) {
      _options.unshift({ key: '', value: '' })
    }

    // this is because null can not set dropdown
    let _selected = selected
    if (_selected === '' || _selected === null) {
      _selected = isMultiple ? [''] : ''
    } else {
      isDisabledGroup = true
      if (
        isMultiple &&
        _.isArray(_selected) &&
        _selected.filter((item) => item === '' || item === null).length > 0
      ) {
        _selected = ['']
      }
    }

    const hasSelectedGroup =
      (_.isArray(selectedGroup) && selectedGroup.length > 0) ||
      (!_.isArray(selectedGroup) && selectedGroup)

    let defaultClass = 'form-select form-select-sm select'
    defaultClass = noClass ? '' : defaultClass

    return (
      <div className="h-100">
        <select
          className={`${defaultClass} ${
            isMultiple ? 'multiple-select' : ''
          } ${this.getValidationClass()} h-100`}
          disabled={isDisabled}
          multiple={isMultiple}
          value={_selected}
          onChange={this.onFilterChanged.bind(this, _options)}
        >
          {isGrouped
            ? groups.map((group, groupIndex) => {
                return (
                  <optgroup
                    key={groupIndex}
                    disabled={isDisabledGroup && hasSelectedGroup && selectedGroup !== group.label}
                    label={group.label}
                  >
                    {this.getOptionTemplate(group.options)}
                  </optgroup>
                )
              })
            : this.getOptionTemplate(_options)}
        </select>
        {isGrouped && hasSelectedGroup ? (
          <OverlayTrigger overlay={this.getTooltip('Reset Selection')} placement="top">
            <Button
              className="select-reset-btn"
              size="sm"
              type="button"
              variant="danger"
              onClick={this.onResetField.bind(this)}
            >
              <span className="slvy-ui-icon-times-lt" />
            </Button>
          </OverlayTrigger>
        ) : null}
      </div>
    )
  }
}
