import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from '../appAPI'

const initialState = {
  error: {},
  isLoading: false,
  periodAggregations: {}
}

export const getPeriodAggregations = createAsyncThunk(
  'app/getPeriodAggregations',
  async (action) => {
    try {
      const response = await makeRequest(action)
      // The value we return becomes the `fulfilled` action payload
      return JSON.parse(response.text)
    } catch (err) {
      throw err
    }
  }
)

const periodAggregationsSlice = createSlice({
  name: 'periodAggregations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPeriodAggregations.pending, (state, action) => {
        state.isLoading = true
        state.error = {}
        state.periodAggregations = {}
      })
      .addCase(getPeriodAggregations.fulfilled, (state, action) => {
        state.periodAggregations = {
          ...action.payload
        }
        state.isLoading = false
        state.error = {}
      })
      .addCase(getPeriodAggregations.rejected, (state, action) => {
        state.periodAggregations = {}
        state.error = { ...action.payload }
        state.isLoading = false
      })
  }
})

export const error = (state) => {
  state.error
}
export const isLoading = (state) => {
  state.isLoading
}
export const periodAggregations = (state) => {
  state.periodAggregations
}

export default periodAggregationsSlice.reducer
