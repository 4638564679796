import React, { Component } from 'react'
import cx from 'classnames'

const getCheckboxValue = (value) => {
  if (value === true || value === 1) {
    return true
  }

  if (value === false || value === 0) {
    return false
  }

  return null
}

class Checkbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: getCheckboxValue(this.props.component.value)
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    const {
      component: {
        isReadonly,
        sectionKey,
        fieldName,
        element: { returnNumber = false }
      },
      onChange
    } = this.props
    const { value } = this.state

    if (!isReadonly) {
      const newValue = returnNumber ? Number(!value) : !value
      onChange(sectionKey, fieldName, newValue)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: getCheckboxValue(nextProps.component.value)
    })
  }

  render() {
    const {
      isInvalid,
      validationCheckState,
      component: { readonly: compReadonly, labelPlacement, justifyContent },
      readonly: formReadonly = false,
      controlId
    } = this.props
    const { value } = this.state
    const readonly = formReadonly || compReadonly

    let validationClass = ''
    if (validationCheckState) {
      validationClass = isInvalid ? 'is-invalid' : ''
    }

    return (
      <div
        className={cx('h-100 d-flex align-items-center', {
          [`justify-content-${justifyContent}`]: justifyContent
        })}
      >
        <input
          checked={value ? 'checked' : false}
          className={`chk-${labelPlacement} ${validationClass} form-check-input`}
          disabled={readonly ? 'disabled' : false}
          id={controlId}
          type="checkbox"
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
export default Checkbox
