import { Log } from 'oidc-client'
import { createUserManager } from 'redux-oidc'
import { AUTH_URL, NODE_ENV } from '@/constants'

const baseUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`

const userManagerConfig = {
  client_id: 'dragon_api_debug',
  redirect_uri: `${baseUrl}/callback`,
  response_type: 'id_token token',
  scope: 'openid profile email identityapi identityprofile',
  authority: AUTH_URL,
  silent_redirect_uri: `${baseUrl}/silent_renew`,
  post_logout_redirect_uri: `${baseUrl}`,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  clockSkew: 900
}

Log.logger = console
Log.level = Log.DEBUG
Log.info(NODE_ENV)

const userManager = createUserManager(userManagerConfig, () => true, false, '/callback')

export default userManager
