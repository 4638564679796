import _ from 'lodash'
import { number } from 'prop-types'

var totalSchema = {}

const pluginSchema = {
  title: 'Chart',
  type: 'object',
  format: 'objectTab',
  properties: {
    datafields: {
      type: 'array',
      format: 'HiddenTable',
      title: 'Data Fields',
      items: {
        type: 'string'
      }
    }
  }
}

const generalSchema = {
  general: {
    title: 'General',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      },
      header: {
        type: 'string',
        title: 'Header'
      },
      maximizable: {
        type: 'boolean',
        title: 'Maximizable',
        default: false,
        required: true
      },
      export: {
        type: 'boolean',
        title: 'Exportable',
        default: false,
        required: true
      },
      showNoDataToDisplay: {
        type: 'boolean',
        title: 'Show No Data To Display',
        default: false,
        required: true
      }
    }
  }
}

const chartSchema = {
  chart: {
    title: 'Chart',
    type: 'object',
    options: {
      basic: true
    },
    properties: {
      options3d: {
        type: 'object',
        title: '3d options ',
        properties: {
          alpha: {
            type: 'number',
            default: 10
          },
          beta: {
            type: 'number',
            default: 30
          },
          depth: {
            type: 'number',
            default: 250
          },
          viewDistance: {
            type: 'number',
            default: 5
          },
          fitToPlot: {
            type: 'boolean',
            default: true
          },
          frame: {
            type: 'object',
            title: 'Frame options ',
            properties: {
              back: {
                type: 'object',
                title: 'back',
                properties: {
                  visible: {
                    type: 'boolean',
                    default: true
                  },
                  size: {
                    type: 'number',
                    default: 1
                  },
                  color: {
                    type: 'string',
                    format: 'Color',
                    default: 'rgba(0,0,0,0.04)'
                  }
                }
              },
              bottom: {
                type: 'object',
                title: 'bottom',
                properties: {
                  visible: {
                    type: 'boolean',
                    default: true
                  },
                  size: {
                    type: 'number',
                    default: 1
                  },
                  color: {
                    type: 'string',
                    format: 'Color',
                    default: 'rgba(0,0,0,0.02)'
                  }
                }
              },
              front: {
                type: 'object',
                title: 'front',
                properties: {
                  visible: {
                    type: 'boolean',
                    default: false
                  },
                  size: {
                    type: 'number',
                    default: 1
                  },
                  color: {
                    type: 'string',
                    format: 'Color'
                  }
                }
              },
              top: {
                type: 'object',
                title: 'top',
                properties: {
                  visible: {
                    type: 'boolean',
                    default: false
                  },
                  size: {
                    type: 'number',
                    default: 1
                  },
                  color: {
                    type: 'string',
                    format: 'Color'
                  }
                }
              },
              left: {
                type: 'object',
                title: 'left',
                properties: {
                  visible: {
                    type: 'boolean',
                    default: true
                  },
                  size: {
                    type: 'number',
                    default: 1
                  },
                  color: {
                    type: 'string',
                    format: 'Color',
                    default: 'rgba(0,0,0,0.06)'
                  }
                }
              },
              right: {
                type: 'object',
                title: 'right',
                properties: {
                  visible: {
                    type: 'boolean',
                    default: false
                  },
                  size: {
                    type: 'number',
                    default: 1
                  },
                  color: {
                    type: 'string',
                    format: 'Color'
                  }
                }
              }
            }
          }
        }
      },

      borderColor: {
        type: 'string',
        format: 'Color',
        default: '#335cad',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      showCredits: {
        type: 'boolean',
        title: 'Show credits?',
        default: false,
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 9
      },

      borderRadius: {
        type: 'number',
        default: 0
      },
      borderWidth: {
        type: 'number',
        default: 0
      },

      plotBackgroundColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 6
      },

      plotBorderColor: {
        type: 'string',
        format: 'Color',
        default: '#cccccc'
      },
      plotBorderWidth: {
        type: 'number',
        default: 0
      },
      plotShadow: {
        type: 'boolean',
        required: true,
        default: false
      },

      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      showAxes: {
        type: 'boolean',
        required: true,
        default: false
      },
      spacingBottom: {
        type: 'number',
        default: 15
      },
      spacingLeft: {
        type: 'number',
        default: 10
      },
      spacingRight: {
        type: 'number',
        default: 10
      },
      spacingTop: {
        type: 'number',
        default: 15
      },

      zoomType: {
        options: {
          input_width: '500px',
          basic: true
        },
        type: 'string',
        enum: ['', 'x', 'y', 'xy'],
        default: '',
        required: true,
        propertyOrder: 2
      },

      resetZoomButton: {
        type: 'object',
        title: 'Reset Zoom Button',
        properties: {
          theme: {
            type: 'object',
            title: 'Theme',
            properties: {
              zIndex: {
                type: 'number',
                title: 'z-index',
                default: 7
              }
            }
          }
        }
      }
    }
  }
}

const seriesSchema = {
  series: {
    type: 'array',
    title: 'Series',
    options: {
      syncButton: true
    },

    items: {
      type: 'object',
      title: 'Series',
      headerTemplate: '{{ self.fieldName }}',
      options: {
        collapsed: true
      },
      properties: {
        name: {
          type: 'string'
        },
        xFieldName: {
          type: 'string',
          title: 'X Data Field',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        },
        yFieldName: {
          type: 'string',
          title: 'Y Data Field',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        },
        zFieldName: {
          type: 'string',
          title: 'Z Data Field',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        },
        color: {
          type: 'string',
          format: 'NullColor',
          options: {
            basic: true
          }
        },
        colorField: {
          type: 'string',
          title: 'Color Field',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        },
        turboThreshold: {
          type: 'number',
          default: 1000
        }
      }
    }
  }
}

const xAxis = {
  xAxis: {
    type: 'object',
    title: 'X Axis',

    properties: {
      min: {
        type: 'number'
      },
      max: {
        type: 'number'
      },
      gridLineWidth: {
        type: 'number',
        default: 1
      },

      tickInterval: {
        type: 'NullableNumber',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      opposite: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        }
      },
      visible: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        }
      },
      title: {
        type: 'object',
        title: 'Title',
        options: {
          collapsed: true
        },
        properties: {
          align: {
            type: 'string',
            enum: ['low', 'middle', 'high'],
            default: 'middle',
            required: true
          },
          margin: {
            type: 'NullableNumber',
            default: ''
          },
          offset: {
            type: 'NullableNumber',
            default: ''
          },
          reserveSpace: {
            type: 'boolean',
            required: true,
            default: true
          },
          rotation: {
            type: 'number',
            default: 0
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { color: 'rgba(102,102,102,1)', fontSize: '12px' },
            options: {
              basic: true
            }
          },
          text: {
            type: 'string',
            default: '',
            description: 'Apply templates using {columnName}'
          },
          x: {
            type: 'number',
            default: 0
          },
          y: {
            type: 'NullableNumber',
            default: ''
          }
        }
      },
      labels: {
        type: 'object',
        title: 'Labels',
        propertyOrder: 1001,
        options: {
          collapsed: true
        },
        properties: {
          align: {
            type: 'string',
            enum: ['', 'left', 'center', 'right'],
            default: '',
            required: true
          },

          enabled: {
            type: 'boolean',
            required: true,
            default: true
          },
          format: {
            type: 'string'
          },
          padding: {
            type: 'number',
            default: 5
          },
          reserveSpace: {
            type: 'boolean',
            required: true,
            default: true
          },
          rotation: {
            type: 'number',
            default: 0
          },
          staggerLines: {
            type: 'NullableNumber',
            default: ''
          },
          step: {
            type: 'NullableNumber',
            default: ''
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
          },
          useHTML: {
            type: 'boolean',
            required: true,
            default: false
          },
          x: {
            type: 'number',
            default: 0
          },
          y: {
            type: 'NullableNumber',
            default: 20
          },
          zIndex: {
            type: 'number',
            default: 7
          }
        }
      }
    }
  }
}

const yAxis = {
  yAxis: {
    type: 'object',
    title: 'Y Axis',

    properties: {
      min: {
        type: 'number'
      },
      max: {
        type: 'number'
      },
      gridLineWidth: {
        type: 'number',
        default: 1
      },

      tickInterval: {
        type: 'NullableNumber',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      opposite: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        }
      },
      visible: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        }
      },
      title: {
        type: 'object',
        title: 'Title',
        options: {
          collapsed: true
        },
        properties: {
          align: {
            type: 'string',
            enum: ['low', 'middle', 'high'],
            default: 'middle',
            required: true
          },
          margin: {
            type: 'NullableNumber',
            default: ''
          },
          offset: {
            type: 'NullableNumber',
            default: ''
          },
          reserveSpace: {
            type: 'boolean',
            required: true,
            default: true
          },
          rotation: {
            type: 'number',
            default: 0
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { color: 'rgba(102,102,102,1)', fontSize: '12px' },
            options: {
              basic: true
            }
          },
          text: {
            type: 'string',
            default: '',
            description: 'Apply templates using {columnName}'
          },
          x: {
            type: 'number',
            default: 0
          },
          y: {
            type: 'NullableNumber',
            default: ''
          }
        }
      },
      labels: {
        type: 'object',
        title: 'Labels',
        propertyOrder: 1001,
        options: {
          collapsed: true
        },
        properties: {
          align: {
            type: 'string',
            enum: ['', 'left', 'center', 'right'],
            default: '',
            required: true
          },

          enabled: {
            type: 'boolean',
            required: true,
            default: true
          },
          format: {
            type: 'string'
          },
          padding: {
            type: 'number',
            default: 5
          },
          reserveSpace: {
            type: 'boolean',
            required: true,
            default: true
          },
          rotation: {
            type: 'number',
            default: 0
          },
          staggerLines: {
            type: 'NullableNumber',
            default: ''
          },
          step: {
            type: 'NullableNumber',
            default: ''
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
          },
          useHTML: {
            type: 'boolean',
            required: true,
            default: false
          },
          x: {
            type: 'number',
            default: 0
          },
          y: {
            type: 'NullableNumber',
            default: ''
          },
          zIndex: {
            type: 'number',
            default: 7
          }
        }
      }
    }
  }
}

const zAxis = {
  zAxis: {
    type: 'object',
    title: 'Z Axis',

    properties: {
      min: {
        type: 'number'
      },
      max: {
        type: 'number'
      },
      gridLineWidth: {
        type: 'number',
        default: 1
      },

      tickInterval: {
        type: 'NullableNumber',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      opposite: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        }
      },
      visible: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        }
      },
      title: {
        type: 'object',
        title: 'Title',
        options: {
          collapsed: true
        },
        properties: {
          align: {
            type: 'string',
            enum: ['low', 'middle', 'high'],
            default: 'middle',
            required: true
          },
          margin: {
            type: 'NullableNumber',
            default: ''
          },
          offset: {
            type: 'NullableNumber',
            default: ''
          },
          reserveSpace: {
            type: 'boolean',
            required: true,
            default: true
          },
          rotation: {
            type: 'number',
            default: 0
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { color: 'rgba(102,102,102,1)', fontSize: '12px' },
            options: {
              basic: true
            }
          },
          text: {
            type: 'string',
            default: '',
            description: 'Apply templates using {columnName}'
          },
          x: {
            type: 'number',
            default: 0
          },
          y: {
            type: 'NullableNumber',
            default: ''
          }
        }
      },
      labels: {
        type: 'object',
        title: 'Labels',
        propertyOrder: 1001,
        options: {
          collapsed: true
        },
        properties: {
          align: {
            type: 'string',
            enum: ['', 'left', 'center', 'right'],
            default: '',
            required: true
          },

          enabled: {
            type: 'boolean',
            required: true,
            default: true
          },
          format: {
            type: 'string'
          },
          padding: {
            type: 'number',
            default: 5
          },
          reserveSpace: {
            type: 'boolean',
            required: true,
            default: true
          },
          rotation: {
            type: 'number',
            default: 0
          },
          staggerLines: {
            type: 'NullableNumber',
            default: ''
          },
          step: {
            type: 'NullableNumber',
            default: ''
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
          },
          useHTML: {
            type: 'boolean',
            required: true,
            default: false
          },
          x: {
            type: 'number',
            default: 0
          },
          y: {
            type: 'NullableNumber',
            default: ''
          },
          zIndex: {
            type: 'number',
            default: 7
          }
        }
      }
    }
  }
}

const titleSchema = {
  title: {
    title: 'Title',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      margin: {
        type: 'number',
        default: 15
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '18px' },
        options: {
          basic: true
        }
      },

      text: {
        type: 'string',
        default: 'Chart Title',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },

      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },

      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 15
      }
    }
  }
}

const subtitleSchema = {
  subtitle: {
    title: 'Subtitle',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(102,102,102,1)' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },

      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },

      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 35
      }
    }
  }
}

const tooltipSchema = {
  tooltip: {
    title: 'Tooltip',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      animation: {
        type: 'boolean',
        required: true,
        default: true
      },
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: 'rgba(247,247,247,0.85)',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      borderRadius: {
        type: 'number',
        default: 3
      },
      borderWidth: {
        type: 'number',
        default: 1,
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      followPointer: {
        type: 'boolean',
        required: true,
        default: false
      },
      followTouchMove: {
        type: 'NullableBoolean',
        default: null
      },
      footerFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      headerFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      hideDelay: {
        type: 'number',
        default: 500
      },
      padding: {
        type: 'number',
        default: 8
      },
      pointFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: true
      },

      style: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px'
        },
        options: {
          basic: true
        }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      }
    }
  }
}

const legendSchema = {
  legend: {
    title: 'Legend',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      backgroundColor: {
        type: 'string',
        format: 'NullColor'
      },
      borderColor: {
        type: 'string',
        format: 'Color',
        default: '#999999'
      },
      borderRadius: {
        type: 'number',
        default: 0
      },
      borderWidth: {
        type: 'number',
        default: 0
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 2
      },

      itemHiddenStyle: {
        type: 'object',
        format: 'Style',
        default: { color: '#cccccc' }
      },
      itemHoverStyle: {
        type: 'object',
        format: 'Style',
        default: { color: '#000000' }
      },
      itemMarginBottom: {
        type: 'number',
        default: 0
      },
      itemMarginTop: {
        type: 'number',
        default: 0
      },
      itemStyle: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px',
          fontWeight: 'bold'
        },
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      itemWidth: {
        type: 'NullableNumber',
        default: ''
      },
      labelFormat: {
        type: 'string',
        default: ''
      },
      layout: {
        type: 'string',
        enum: ['horizontal', 'vertical'],
        default: 'horizontal',
        required: true
      },
      maxHeight: {
        type: 'NullableNumber',
        default: ''
      },
      padding: {
        type: 'number',
        default: 8
      },
      reversed: {
        type: 'boolean',
        required: true,
        default: false
      },

      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },

      title: {
        type: 'object',
        title: 'Title',
        options: {
          collapsed: true
        },
        propertyOrder: 1001,
        properties: {
          text: {
            type: 'string',
            default: ''
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { fontWeight: 'bold' }
          }
        }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['top', 'middle', 'bottom'],
        default: 'bottom',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      width: {
        type: 'NullableNumber',
        default: ''
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'number',
        default: 0
      }
    }
  }
}

const exportingSchema = {
  exporting: {
    title: 'Exporting',
    type: 'object',
    properties: {
      allowHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: false
      },
      fallbackToExportServer: {
        type: 'boolean',
        required: false,
        default: true
      },
      filename: {
        type: 'string',
        default: 'chart'
      },
      libURL: {
        type: 'string',
        default: 'https://code.highcharts.com/{version}/lib'
      },
      printMaxWidth: {
        type: 'number',
        default: 780
      },
      scale: {
        type: 'number',
        default: 2
      },
      sourceHeight: {
        type: 'NullableNumber',
        default: ''
      },
      sourceWidth: {
        type: 'NullableNumber',
        default: ''
      },
      url: {
        type: 'string',
        default: 'https://export.highcharts.com'
      },
      width: {
        type: 'NullableNumber',
        default: ''
      },
      buttons: {
        title: 'Buttons',
        type: 'object',
        properties: {
          contextButton: {
            title: 'Context Button',
            type: 'object',
            options: {
              collapsed: true
            },
            properties: {
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'left',
                options: {
                  basic: true
                }
              }
            }
          }
        }
      }
    }
  }
}

export default () => {
  if (_.isEmpty(totalSchema)) {
    _.assign(pluginSchema.properties, generalSchema)
    _.assign(pluginSchema.properties, chartSchema)
    _.assign(pluginSchema.properties, titleSchema)
    _.assign(pluginSchema.properties, subtitleSchema)
    _.assign(pluginSchema.properties, seriesSchema)
    _.assign(pluginSchema.properties, legendSchema)
    _.assign(pluginSchema.properties, tooltipSchema)
    _.assign(pluginSchema.properties, exportingSchema)
    _.assign(pluginSchema.properties, xAxis)
    _.assign(pluginSchema.properties, yAxis)
    _.assign(pluginSchema.properties, zAxis)

    totalSchema = pluginSchema
  }

  return totalSchema
}
