import { isWarningThresholdExceeded } from '../index'

describe('AgGrid:Helpers:Common:isWarningThresholdExceeded', () => {
  it('should return false if given warningThreshold is not type of number', () => {
    const result = isWarningThresholdExceeded({ warningThreshold: '', oldValue: 1, newValue: 2 })
    expect(result).toBe(false)
  })
  it('should return false if given warningThreshold is equal or lower than 0', () => {
    const result = isWarningThresholdExceeded({ warningThreshold: 0, oldValue: 1, newValue: 2 })
    expect(result).toBe(false)
  })
  it('should return false if given newValue and oldValue is not type of number', () => {
    const result = isWarningThresholdExceeded({ warningThreshold: 0, oldValue: 1, newValue: 2 })
    expect(result).toBe(false)
  })
  it('should return true if newValue bigger than oldValue at given warningThreshold percent', () => {
    const result = isWarningThresholdExceeded({ warningThreshold: 50, oldValue: 10, newValue: 15 })
    expect(result).toBe(true)
  })
  it('should return false if newValue bigger than oldValue but not at given warningThreshold percent', () => {
    const result = isWarningThresholdExceeded({ warningThreshold: 50, oldValue: 10, newValue: 30 })
    expect(result).toBe(true)
  })
})
