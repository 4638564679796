import {
  ColumnOrderState,
  ColumnPinningState,
  ColumnSizingState,
  ColumnVisibilityState,
  FocusedCellState,
  GridState,
  RowGroupExpansionState,
  RowGroupState,
  SortModelItem
} from 'ag-grid-community'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { objectKeys } from '@/ts/utils'
import { AG_GRID_STATE, CUSTOM_FIELDS, emptyArray, emptyObject } from '../../constants'
import { defaultState, maxExpandedRowGroupLimit } from './constants'
import { AG_GRID_ENUMS } from '../../enums'
import { compareValuesCaseInsensitive } from '../../helpers'
import { FilterModel } from '../../types'
import { GridStateCustomInfo, InitialStateProps, RootGridState } from './useInitialState.types'

const { AUTO_GROUP_COLUMN } = AG_GRID_ENUMS

function useInitialState({
  initialGroupingCollapse,
  groupDisplayType,
  suppressRowGroupHidesColumns,
  pluginId,
  configColumns,
  grouping,
  lockPinned,
  isPreviewMode,
  theme
}: InitialStateProps) {
  const [initialState, setInitialState] = useState<GridState | undefined>()
  const isGroupRows = groupDisplayType === 'groupRows'

  const AG_GRID_STATE_KEY = `${AG_GRID_STATE}.${pluginId}`

  const getGridState = useCallback(() => {
    const lsState = (JSON.parse(localStorage.getItem(AG_GRID_STATE_KEY)!) ||
      defaultState) as RootGridState

    // prevent creating a new empty object (reference) if there is no filter
    const result = objectKeys(lsState?.gridState ?? {}).length ? lsState : defaultState

    // TODO: Remove below line after ag-grid migration complete
    if (!result?.IS_VERIFIED_COLUMN_ORDER) {
      result.gridState.columnOrder = undefined
    }
    if (!result?.IS_VERIFIED_COLUMN_VISIBILITY) {
      result.gridState.columnVisibility = undefined
    }

    // IMPORTANT NOTE
    // Change "IS_VERIFIED" with something else here and in saveGridState function.
    // Use this to reset initialState
    if (!result?.IS_VERIFIED) {
      result.gridState = {}
    }

    return result
  }, [AG_GRID_STATE_KEY])

  const getSortModel = useCallback(
    ($sortModel) => {
      const lsSortModel = $sortModel as SortModelItem[]

      return lsSortModel.filter(({ colId }) => {
        // Note: Clearing the AutoColumn is not problem
        // Clear columns in lsSortModel those no longer exist in configColumns and are not sortable
        return configColumns.some(({ field, sortable }) => field === colId && sortable)
      })
    },
    [configColumns]
  )

  const getFilterModel = useCallback(
    ($filterModel) => {
      const lsFilterModel = $filterModel as FilterModel

      // Clear columns in lsFilterModel those are no longer exist in configColumns
      const filteredFilterModel: FilterModel = {}
      objectKeys(lsFilterModel).forEach((filterKey) => {
        if (configColumns.some(({ field }) => field === filterKey)) {
          filteredFilterModel[filterKey] = lsFilterModel[filterKey]
        }
      })

      // prevent creating a new empty object (reference) if there is no filter
      return objectKeys(filteredFilterModel).length ? filteredFilterModel : emptyObject
    },
    [configColumns]
  )

  const getOrderedColIds = useCallback(
    ($orderedColIds, $lsConfigOrderedColIds) => {
      const lsOrderedColIds = $orderedColIds as ColumnOrderState['orderedColIds']

      // TODO: Find a way to keep empty data field order state on localStorage, without breaking other parts of the app.
      // "field" is used mostly and critical property for the column
      // if there is no "Data Field", there is no colId to save it on orderedColIds in localStorage
      // In this scenario; Ag cannot keep column order state of the grid
      // https://dragon-stage.solvoyo.com/Configuration/catalog/65f2e5799f6cc8d99500d46e/store/22/menu/65f2e5799f6cc8d99500d48f/page/65f2e5799f6cc8d99500e833
      const doesEmptyDataFieldExist = configColumns.some(({ field }) => {
        const isFieldUndefined = typeof field === 'undefined'
        const isFieldString = typeof field === 'string'
        return isFieldUndefined || !field || (isFieldString && !field.trim().length)
      })
      if (doesEmptyDataFieldExist) {
        return []
      }

      const configColumnIds = configColumns.map(({ field }) => field)
      // Reset column order if configuration column changes
      if (!isEqual(configColumnIds, $lsConfigOrderedColIds)) {
        return []
      }

      return lsOrderedColIds.filter((colId) => {
        const isFoundInConfig = configColumns.some(({ field }) => field === colId)
        const isAutoColumn = colId === AUTO_GROUP_COLUMN
        const isSpecialColumn =
          CUSTOM_FIELDS.some((customField) => customField === colId) && !isAutoColumn
        return isFoundInConfig || isSpecialColumn || (isAutoColumn && !isGroupRows)
      })
    },
    [configColumns, isGroupRows]
  )

  const getHiddenColIds = useCallback(
    ($hiddenColIds, $suppressRowGroupHidesColumns) => {
      const lsHiddenColIds = $hiddenColIds as ColumnVisibilityState['hiddenColIds']

      return lsHiddenColIds.filter((colId) => {
        const currentCol = configColumns.find(({ field }) => field === colId)

        if (!currentCol) {
          return false
        }

        // TODO: Getting group info from configColumns is not safe!
        const isGroupCol = configColumns.some(({ field, customConfigs }) => {
          return field === colId && customConfigs?.rowGroup
        })

        if (isGroupCol && suppressRowGroupHidesColumns !== $suppressRowGroupHidesColumns) {
          if (suppressRowGroupHidesColumns) {
            return false
          }
        }

        return true
      })
    },
    [configColumns, suppressRowGroupHidesColumns]
  )

  const getPinningColIds = useCallback(
    ($columnPinning) => {
      const { leftColIds: lsLeftColIds = emptyArray, rightColIds: lsRightColIds = emptyArray } =
        $columnPinning as ColumnPinningState

      const cleanLeftColIds = lsLeftColIds.filter((colId) => {
        const isAutoColumn = colId === AUTO_GROUP_COLUMN
        if (isAutoColumn) {
          return !isGroupRows
        }
        if (CUSTOM_FIELDS.some((customField) => customField === colId)) {
          return true
        }
        if (lockPinned) {
          return false
        }
        return configColumns.some(({ field }) => field === colId)
      })

      const cleanRightColIds = lsRightColIds.filter((colId) => {
        // Note: Custom fields cannot pin to right
        // if (CUSTOM_FIELDS.some((customField) => customField === colId)) {
        //   return true
        // }
        if (lockPinned) {
          return false
        }
        return configColumns.some(({ field }) => field === colId)
      })

      return { leftColIds: cleanLeftColIds, rightColIds: cleanRightColIds }
    },
    [configColumns, isGroupRows, lockPinned]
  )

  const getColumnSizingModel = useCallback(
    (
      $columnSizingModel,
      $lsConfigColumnSizingModel,
      hiddenColIds: ColumnVisibilityState['hiddenColIds']
    ) => {
      const lsColumnSizingModel = $columnSizingModel as ColumnSizingState['columnSizingModel']
      const lsConfigColumnSizingModel =
        $lsConfigColumnSizingModel as ColumnSizingState['columnSizingModel']

      const configColumnsSizeModel = configColumns
        .map(({ field, flex, customConfigs }) => {
          return { colId: field, flex, width: customConfigs.width }
        })
        .sort((prev, next) => compareValuesCaseInsensitive(prev?.colId, next?.colId))

      const $sortedLsConfigColumnSizingModel = lsConfigColumnSizingModel.sort((prev, next) =>
        compareValuesCaseInsensitive(prev?.colId, next?.colId)
      )

      if (!isEqual(configColumnsSizeModel, $sortedLsConfigColumnSizingModel)) {
        return []
      }

      const doesAutoColumnExistAndNotSingleColumn = lsColumnSizingModel.some((columnState) => {
        const isAutoGroupColumn = columnState?.colId === AUTO_GROUP_COLUMN
        return isAutoGroupColumn && isGroupRows
      })

      if (doesAutoColumnExistAndNotSingleColumn) {
        return []
      }

      return lsColumnSizingModel.filter((columnState) => {
        const isAutoGroupColumn = columnState?.colId === AUTO_GROUP_COLUMN
        if (isAutoGroupColumn && !isGroupRows) {
          return true
        }

        const currentCustomField = CUSTOM_FIELDS.some(
          (customField) => customField === columnState?.colId
        )

        const isHidden = hiddenColIds.some((colId) => colId === columnState?.colId)
        // TODO: Check Why did below line is need?
        // const isGrouped = groupColIds.some((colId) => colId === columnState?.colId)
        // TODO: Check Why did below line is need?
        // const isExpandedRowGroup = expandedRowGroupIds.some((colId) => colId === columnState?.colId)
        // if (currentCustomField || isHidden || isGrouped || isExpandedRowGroup) {
        if (currentCustomField || isHidden) {
          return false
        }
        return configColumns.some(({ field }) => field === columnState?.colId)
      })
    },
    [configColumns, isGroupRows]
  )

  const getGroupColIds = useCallback(
    ($groupColIds, hiddenColIds: ColumnVisibilityState['hiddenColIds']) => {
      const lsGroupColIds = $groupColIds as RowGroupState['groupColIds']

      return lsGroupColIds.filter((colId) => {
        const isAutoGroupColumn = colId === AUTO_GROUP_COLUMN
        if (isAutoGroupColumn) {
          return !isGroupRows
        }

        const doesExistInConfig = configColumns.some(({ field }) => field === colId)

        if (!doesExistInConfig) {
          return false
        }

        if (!suppressRowGroupHidesColumns) {
          return true
        }

        if (isGroupRows) {
          const isHidden = hiddenColIds.some((hiddenColId) => hiddenColId === colId)

          return !isHidden
        }

        return true
      })
    },
    [configColumns, isGroupRows, suppressRowGroupHidesColumns]
  )

  const getFocusedCell = useCallback(
    ($focusedCell) => {
      const lsFocusedCell = $focusedCell as FocusedCellState
      const isCellExist = configColumns.some(({ field }) => field === lsFocusedCell.colId)
      return isCellExist ? lsFocusedCell : undefined
    },
    [configColumns]
  )

  const getExpandedRowGroupIds = useCallback(
    (
      $expandedRowGroupIds,
      hiddenColIds: ColumnVisibilityState['hiddenColIds'],
      $lsConfigInitialGroupingCollapsed?: GridStateCustomInfo['initialGroupingCollapse']
    ) => {
      const lsExpandedRowGroupIds =
        $expandedRowGroupIds as RowGroupExpansionState['expandedRowGroupIds']

      if ($lsConfigInitialGroupingCollapsed !== initialGroupingCollapse) {
        return []
      }

      if (lsExpandedRowGroupIds.length > maxExpandedRowGroupLimit) {
        return []
      }

      return lsExpandedRowGroupIds.filter((rowGroupId) => {
        const colId = rowGroupId.split('-')[2]

        const doesExistInConfig = configColumns.some(({ field }) => field === colId)

        if (!doesExistInConfig) {
          return false
        }

        if (!suppressRowGroupHidesColumns) {
          return true
        }

        if (isGroupRows) {
          const isHidden = hiddenColIds.some((hiddenColId) => hiddenColId === colId)

          return !isHidden
        }

        return true
      })
    },
    [initialGroupingCollapse, configColumns, suppressRowGroupHidesColumns, isGroupRows]
  )

  const prepareGridState = useCallback(
    ($rootState: RootGridState) => {
      const gridKeys = Object.keys($rootState?.gridState)

      const result = gridKeys.length ? $rootState?.gridState : (emptyObject as GridState)
      const sortModel = getSortModel(result?.sort?.sortModel || emptyArray)
      const filterModel = getFilterModel(result?.filter?.filterModel || emptyObject)
      const orderedColIds = getOrderedColIds(
        result?.columnOrder?.orderedColIds || emptyArray,
        $rootState?.configurationState?.columnOrder?.orderedColIds || emptyArray
      )
      const hiddenColIds = getHiddenColIds(
        result?.columnVisibility?.hiddenColIds || emptyArray,
        $rootState?.configurationState?.customInfo?.suppressRowGroupHidesColumns
      )

      const groupColIds = getGroupColIds(result?.rowGroup?.groupColIds || emptyArray, hiddenColIds)

      const { leftColIds, rightColIds } = getPinningColIds(result?.columnPinning || emptyObject)

      const focusedCell = getFocusedCell(result?.focusedCell || emptyObject)
      const expandedRowGroupIds = getExpandedRowGroupIds(
        result?.rowGroupExpansion?.expandedRowGroupIds || emptyArray,
        hiddenColIds,
        $rootState?.configurationState?.customInfo?.initialGroupingCollapse
      )
      const columnSizingModel = getColumnSizingModel(
        result?.columnSizing?.columnSizingModel || emptyArray,
        $rootState?.configurationState?.columnSizing?.columnSizingModel || emptyArray,
        hiddenColIds
      )

      if (result?.sort) {
        result.sort = { sortModel }
      }

      if (result?.filter) {
        result.filter = { filterModel }
      }

      if (result?.columnOrder) {
        result.columnOrder = { orderedColIds }
      }

      if (result?.columnVisibility) {
        result.columnVisibility = { hiddenColIds }
      }

      if (result?.columnSizing) {
        // reset column sizing if stored theme changes
        result.columnSizing = $rootState?.theme === theme ? { columnSizingModel } : undefined
      }

      if (result?.columnPinning) {
        result.columnPinning = { leftColIds, rightColIds }
      }

      if (result?.rowGroup) {
        result.rowGroup = grouping ? { groupColIds } : undefined
      }

      if (result?.rowGroupExpansion) {
        result.rowGroupExpansion = grouping ? { expandedRowGroupIds } : undefined
      }

      if (result?.focusedCell) {
        result.focusedCell = focusedCell
      }

      // sideBar is not used on the grid.
      // aggregation cannot change on the grid, so keeping it useless
      // rowSelection works with row id, and we cannot provide the same id to the same row
      // pagination is not used on the grid.
      const { sideBar, aggregation, rowSelection, pagination, ...rest } = result

      return rest
    },
    [
      theme,
      getSortModel,
      getFilterModel,
      getOrderedColIds,
      getHiddenColIds,
      getPinningColIds,
      getGroupColIds,
      getFocusedCell,
      getExpandedRowGroupIds,
      getColumnSizingModel,
      grouping
    ]
  )

  const executeGridState = useCallback(
    ($newState: RootGridState) => {
      setInitialState((prev: GridState | undefined) => {
        return {
          ...(prev || {}),
          ...prepareGridState($newState)
        }
      })
    },
    [prepareGridState]
  )

  const restoreGridState = useCallback(() => {
    executeGridState(getGridState())
  }, [executeGridState, getGridState])

  const saveGridState = useCallback(
    ($gridState) => {
      const configurationState = {
        customInfo: {
          initialGroupingCollapse,
          suppressRowGroupHidesColumns
        },
        columnOrder: {
          orderedColIds: configColumns.map(({ field }) => field)
        },
        columnSizing: {
          columnSizingModel: configColumns.map(({ field, flex, customConfigs }) => ({
            colId: field,
            flex,
            width: customConfigs.width
          }))
        }
      } as RootGridState['configurationState']
      const newState = {
        // TODO: Remove below line after ag-grid migration complete
        IS_VERIFIED: true,
        IS_VERIFIED_COLUMN_ORDER: true,
        IS_VERIFIED_COLUMN_VISIBILITY: true,
        theme,
        configurationState,
        gridState: prepareGridState({ configurationState, gridState: $gridState, theme })
      }
      localStorage.setItem(AG_GRID_STATE_KEY, JSON.stringify(newState))
    },
    [
      AG_GRID_STATE_KEY,
      configColumns,
      initialGroupingCollapse,
      prepareGridState,
      suppressRowGroupHidesColumns,
      theme
    ]
  )

  const getInstantGridInitialState = useCallback(() => {
    return isPreviewMode ? null : prepareGridState(getGridState())
  }, [isPreviewMode, prepareGridState, getGridState])

  useEffect(() => {
    if (!initialState && !isPreviewMode) {
      restoreGridState()
    }
  }, [initialState, restoreGridState, isPreviewMode])

  return {
    initialState,
    setInitialState,
    saveGridState,
    getInstantGridInitialState
  }
}

export default useInitialState
