const $isplainobject = function (obj) {
  // Not plain objects:
  // - Any object or value whose internal [[Class]] property is not "[object Object]"
  // - DOM nodes
  // - window
  if (typeof obj !== 'object' || obj.nodeType || (obj !== null && obj === obj.window)) {
    return false
  }

  if (
    obj.constructor &&
    !Object.prototype.hasOwnProperty.call(obj.constructor.prototype, 'isPrototypeOf')
  ) {
    return false
  }

  // If the function hasn't returned already, we're confident that
  // |obj| is a plain object, created by {} or constructed with new Object
  return true
}

var $extend = function (destination) {
  let source
  let i
  let property
  for (i = 1; i < arguments.length; i++) {
    source = arguments[i]
    for (property in source) {
      if (!source.hasOwnProperty(property)) continue
      if (source[property] && $isplainobject(source[property])) {
        if (!destination.hasOwnProperty(property)) destination[property] = {}
        $extend(destination[property], source[property])
      } else {
        destination[property] = source[property]
      }
    }
  }
  return destination
}

window.JSONEditor.defaults.editors.SolvoyoSelect = window.JSONEditor.defaults.editors.select.extend(
  {
    setValue(value, initial) {
      value = this.typecast(value || '')

      // Sanitize value before setting it
      let sanitized = value
      if (this.enum_values.indexOf(sanitized) < 0) {
        sanitized = this.enum_values[0]
      }

      if (this.value === sanitized) {
        return
      }

      this.input.value = this.enum_options[this.enum_values.indexOf(sanitized)]
      if (this.select2) {
        this.select2.val(this.input.value).trigger('change')
      }
      this.value = sanitized
      this.onChange()
    },
    setupSelect2() {
      // If the Select2 library is loaded use it when we have lots of items
      if (
        window.jQuery &&
        window.jQuery.fn &&
        window.jQuery.fn.select2 &&
        (this.enum_options.length || this.enumSource)
      ) {
        let options = $extend({}, window.JSONEditor.plugins.select2)
        if (this.schema.options && this.schema.options.select2_options) {
          options = $extend(options, this.schema.options.select2_options)
        }
        this.select2 = window.jQuery(this.input).select2(options)
        const self = this
        this.select2.on('select2-blur', function () {
          self.input.value = self.select2.select2('val')
          self.onInputChange()
        })
        this.select2.on('select2:select select2:unselect', function () {
          self.input.value = self.select2.select2('val')
          self.onInputChange()
        })
      } else {
        this.select2 = null
      }
    }
  }
)

export default {
  name: 'SolvoyoSelect',
  format: 'SolvoyoSelect'
}
