import * as actionTypes from './actionTypes'
import { handleAsyncAwait, makeRequest } from '../../utils'

export const fetchMfpData = (action) => {
  return async (dispatch) => {
    dispatch(startGetMfpDataStart())

    const [response, error] = await handleAsyncAwait(makeRequest(action))
    if (error) {
      dispatch(
        saveGetMfpDataFailed({
          payload: error.data
        })
      )
      throw error
    }

    dispatch(
      saveGetMfpData({
        payload: response.data
      })
    )

    return response
  }
}

export const startGetMfpDataStart = () => {
  return {
    type: actionTypes.FETCH_MFP_DATA_START
  }
}

export const saveGetMfpData = (options) => {
  return {
    type: actionTypes.STORE_MFP_DATA,
    payload: options.payload
  }
}

export const saveGetMfpDataFailed = (options) => {
  return {
    type: actionTypes.STORE_MFP_DATA_FAILED,
    payload: options.payload
  }
}
