export default `({
    border: true,
    hidden: false,
    collapsed: false,
    header: false,
    bodyPadding: '20 2',
    height:150,
    bind: {
        data: {
            SalesPrice: '{spotRecord.SalesPrice}',
            PreStartDate: '{spotRecord.PreStartDate}',
            PreEndDate: '{spotRecord.PreEndDate}',
            UnitofMeasurement: '{spotRecord.UnitofMeasurement}',
            UnitsPerPackage: '{spotRecord.UnitsPerPackage}',
            MinQuantity: '{spotRecord.MinQuantity}',
            MaxQuantity: '{spotRecord.MaxQuantity}',
            MaterialDescription: '{spotRecord.MaterialDescription}',
        }
    },
    tpl: [
        '<div class="expander" style="text-align:center"> ' +
        '  <table class="table" style=" width: 98% ;min-width:100px:"> ' +
        '  <tr> ' +
        '<th colspan=4 style="text-align:center">{MaterialDescription} </td>',
        '  </tr>' +
        '    <tr> ' +
        '  <th class="r"> Sales Price </th> ' +
        '         <td class="r">{this.currencyFormat(SalesPrice)}</td> ' +
        '        <td class="r" colspan=2> </td>' +
        '     </tr> ' +
        '  </tr>' +
        '    <tr> ' +
        '          <th class="r">PreStartDate</th>' +
        '        <td class="r">{PreStartDate:date(tt("d-m-Y"))} </td>' +
        '         <th class="r">PreEndDate</th>' +
        '        <td class="r">{PreEndDate:date(tt("d-m-Y"))} </td>' +
        '     </tr> ' +
        '    <tr>' +
        '         <th class=r>UnitofMeasurement</th>' +
        '        <td class="r">{UnitofMeasurement}</td>' +
        '       <th class="r">UnitsPerPackage</th>' +
        '        <td class="r"> {UnitsPerPackage}</td>' +
        '     </tr>' +
        '  </tr>' +
        '    <tr>' +
        '        <th class="r"> MinQuantity </td>' +
        '        <td class="r">{MinQuantity} </td>' +
        '        <th class="r">MaxQuantity</td>' +
        '        <td class="r" >{MaxQuantity}</td>' +
        '     </tr>' +
        '  </table>' +
        '  </div>',
    ],
    itemss: [{
        xtype: 'fieldcontainer',
        labelWidth: 100,
        fieldDefaults: {
            msgTarget: 'under',
            labelAlign: 'top',
            width: 180,
        },
        defaults: {
            xtype: 'displayfield',
        },
        // The body area will contain three text fields, arranged
        // horizontally, separated by draggable splitters.
        layout: 'hbox',
        items: [{
            bind: {
                value: '{materialRecord.TotalShelfLife}'
            },
            fieldLabel: tt('TotalShelfLifedddddd'),
        }, {
            xtype: 'tbtext',
            text: ''
        }, {
            bind: {
                value: '{materialRecord.MinimumRemainingShelfLife}'
            },
            fieldLabel: 'RemainingShelfLife',
        }, {
            xtype: 'tbtext',
            text: ''
        }, {
            fieldLabel: 'ZZDIREKT',
            bind: {
                value: '{materialRecord.ZZDIREKT}'
            },
        }, {
            xtype: 'tbtext',
            text: ''
        }, {
            fieldLabel: 'ZZNITELIK',
            bind: {
                value: '{MaterialRecord.ZZNITELIK}'
            },
        }]
    }]
})
`
