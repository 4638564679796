import React from 'react'

export default function SwitchButton(props) {
  return (
    <div className="switch-shadow-container">
      {props.text && <span className="switch-shadow-text">{props.text}</span>}
      <input
        checked={props.checked}
        className="switch switch--shadow"
        id="switch-shadow"
        type="checkbox"
        onChange={props.handleOnChange}
      />
      <label htmlFor="switch-shadow" />
    </div>
  )
}
