import { Component } from 'react'
import { Button } from 'react-bootstrap'
import styles from './index.module.scss'

export default class Wizard extends Component {
  constructor() {
    super()

    this.state = {
      step: 0
    }

    this.handleMoveBack = this.handleMoveBack.bind(this)
    this.handleMoveNext = this.handleMoveNext.bind(this)
  }

  handleMoveBack(event) {
    event.target.blur()
    this.setState((prevState) => ({ step: prevState.step - 1 }))
  }

  handleMoveNext(event) {
    event.target.blur()
    this.setState((prevState) => ({ step: prevState.step + 1 }))
  }

  render() {
    const { show, views } = this.props
    const { step } = this.state

    const viewArray = Object.values(views)
    const {
      component: Component,
      wizardProps: {
        className = styles.wizard,
        content: { className: contentClassName = styles.wizardContent } = {},
        controls: {
          className: controlsClassName = styles.wizardControls,
          show: controlsShow = true,
          backBtn: {
            className: backBtnClassName = styles.backBtn,
            disabled: backBtnDisabled = false,
            show: backBtnShow = step !== 0,
            text: backBtnText = 'Back',
            variant: backBtnVariant = 'light',
            onClick: backBtnOnClick = this.handleMoveBack
          } = {},
          nextBtn: {
            className: nextBtnClassName = styles.nextBtn,
            disabled: nextBtnDisabled = false,
            show: nextBtnShow = true,
            text: nextBtnText = step === viewArray.length ? 'Submit' : 'Next',
            variant: nextBtnVariant = 'light',
            onClick: nextBtnOnClick = this.handleMoveNext
          } = {}
        } = {}
      } = {},
      ...props
    } = viewArray[step] ?? {}

    return show ? (
      <div className={className}>
        <div className={contentClassName}>
          {viewArray.length > 0 ? (
            <Component
              key={step.toString()}
              {...{
                ...props,
                wizardProps: {
                  step,
                  onMoveBack: this.handleMoveBack,
                  onMoveNext: this.handleMoveNext
                }
              }}
            />
          ) : null}
        </div>
        {controlsShow ? (
          <div className={controlsClassName}>
            {backBtnShow ? (
              <Button
                className={backBtnClassName}
                disabled={backBtnDisabled}
                type="button"
                variant={backBtnVariant}
                onClick={backBtnOnClick}
              >
                {backBtnText}
              </Button>
            ) : null}
            {nextBtnShow ? (
              <Button
                className={nextBtnClassName}
                disabled={nextBtnDisabled}
                type="button"
                variant={nextBtnVariant}
                onClick={nextBtnOnClick}
              >
                {nextBtnText}
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    ) : null
  }
}
