import { isEqual } from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelectorCreator, lruMemoize } from 'reselect'
import { add as addSchema } from '../schema'
import { Config } from '../ChronoTimeline.types'

const {
  enable,
  button: { properties: buttonSchema }
} = addSchema.properties

const selectRawAddConfig = (addConfig?: Config['add']) => addConfig

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

const selectAddConfigs = createDeepEqualSelector(selectRawAddConfig, (addConfig) => {
  const button = {
    icon: addConfig?.button?.icon ?? buttonSchema.icon.default,
    size: addConfig?.button?.size ?? buttonSchema.size.default,
    color: addConfig?.button?.color ?? buttonSchema.color.default
  }

  return {
    enable: addConfig?.enable ?? enable.default,
    button
  } satisfies Config['add']
})

export default selectAddConfigs
