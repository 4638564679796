import { useRef, useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import diacritics from '../utils/diacritics'
import { HeaderButton } from '../AssortmentPlanning.types'

interface EventRef {
  similarProductDrilldown?: () => void
  similarMultiProductDrilldown?: () => void
  forecastReport?: () => void
  stockCoverReport?: () => void
  [key: `similarProductsHeaderButton_${string}_Clicked`]: () => void
}

const useRegisterEventSimilarProducts = (registerEvent, headerButtons: HeaderButton[]) => {
  const similarProductsEventRef = useRef<EventRef>({})

  useEffect(() => {
    similarProductsEventRef.current.similarProductDrilldown = registerEvent({
      key: 'SimilarProductDrilldown',
      fn: (data) => data,
      returnTypes: {
        ProductId: PluginTypes.int,
        ClusterNodeId: PluginTypes.int
      }
    })
    similarProductsEventRef.current.similarMultiProductDrilldown = registerEvent({
      key: 'SimilarMultiProductDrilldown',
      fn: (data) => data,
      returnTypes: {
        ProductIds: PluginTypes.arrayOf(PluginTypes.int),
        ClusterNodeId: PluginTypes.int
      }
    })

    similarProductsEventRef.current.forecastReport = registerEvent({
      key: 'Forecast Report',
      fn: (data) => data,
      returnTypes: {
        AssortmentId: PluginTypes.int,
        ClusterNodeId: PluginTypes.int,
        OptionId: PluginTypes.int,
        SegmentId: PluginTypes.int
      }
    })
    similarProductsEventRef.current.stockCoverReport = registerEvent({
      key: 'Stock Cover Report',
      fn: (data) => data,
      returnTypes: {
        AssortmentId: PluginTypes.int,
        ClusterNodeId: PluginTypes.int,
        OptionId: PluginTypes.int,
        SegmentId: PluginTypes.int
      }
    })

    headerButtons.forEach(({ name }) => {
      const eventName = diacritics.removeAndLowerCase(name)
      const key = `similarProductsHeaderButton_${eventName}_Clicked`

      similarProductsEventRef.current[key] = registerEvent({
        key,
        fn: (data) => data,
        returnTypes: {
          AssortmentId: PluginTypes.int,
          ClusterNodeId: PluginTypes.int,
          OptionId: PluginTypes.int,
          SegmentId: PluginTypes.int
        }
      })
    })
  }, [registerEvent])

  return { similarProductsEventRef }
}

export default useRegisterEventSimilarProducts
