Ext.define('MDM.src.view.materialedit.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.materialedit-mainviewmodel',
  stores: {
    DPIRelationStore: {
      model: 'MDM.src.view.materialedit.model.Dpi',
      autoload: false,
      bindTo: 'DPIRelationStorebind',
      autoSync: false,
      listeners: {
        write: 'onWrite',
        datachanged: 'onDataChange'
      }
    },
    SuplySourceStore: {
      model: 'MDM.src.view.materialedit.model.SuplySourceModel',
      autoLoad: true
    },
    MaterialBUOMStore: {
      model: 'MDM.src.view.materialedit.model.MaterialBUOM',
      autoLoad: true
    },
    DPIListStore: {
      model: 'MDM.src.view.materialedit.model.DPIFamilyList',
      remoteSort: 'true', // For Remote Sorting
      autoLoad: true
    },
    CartridgeListStore: {
      source: '{DPIListStore}',
      filters: [
        {
          property: 'IsCartridge',
          value: '1',
          operator: '='
        }
      ]
    },
    ResourceListStore: {
      model: 'MDM.src.view.materialedit.model.ResourceList',
      autoLoad: true
    },
    ResourceofProductStore: {
      model: 'MDM.src.view.materialedit.model.ResourceofProduct',
      autoLoad: false,
      autoSync: false
    }
  },
  formulas: {
    selection: {
      bind: '{dpilist.selection}',
      get: function (selection) {
        return selection
      }
    },
    selectedTitle: {
      bind: {
        selection: '{dpilist.selection}',
        b: '{isChanged}'
      },
      get: function (data) {
        var selection = data.selection
        if (selection) {
          var isRazor = selection.get('IsRazor')
          if (isRazor === '1' || isRazor === 1) {
            return 'List of Cartridge (' + selection.get('DPIFamily') + ')'
          } else return 'Selected DPIFamily is not Razor '
        }
        return 'Select Razor DPIFamily to Activate'
      }
    },
    IsRazor: {
      bind: {
        selection: '{dpilist.selection}',
        b: '{isChanged}'
      },
      get: function (data) {
        var selection = data.selection
        if (selection) {
          var isRazor = selection.get('IsRazor')
          return isRazor === '1' || isRazor === 1
        }
        return false
      }
    },
    isStoreDirty: {
      bind: {
        bindTo: '{DPIRelationStorebind}',
        deep: true
      },
      get: function () {
        if (this.getStore('DPIRelationStore') && this.getStore('DPIRelationStore').isDirty) {
          return this.getStore('DPIRelationStore').isDirty()
        }
        return false
      }
    },
    isReadOnly: {
      bind: {
        bindTo: '{editType}',
        deep: true
      },
      get: function (c) {
        if (c == 'Sap') return true
        if (c != 'User') return false
        return false
      }
    },
    hasId: {
      bind: {
        bindTo: '{record}',
        deep: true
      },
      get: function (record) {
        return record.get('isNew')
      }
    }
  },
  data: {
    isNew: false,
    hasID: true,
    editType: 'Sap',
    isChanged: 0
  }
})
