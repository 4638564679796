import { getLineOptions } from '../sparkline'
import { SparklineOptions } from '../../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'
import { sparklineOptions as defaultSparklineOptions } from './mocks'

function getSparklineOptions() {
  return { ...defaultSparklineOptions }
}

describe('AgGrid:Helpers:Common:getLineOptions', () => {
  let sparklineOptions: SparklineOptions = getSparklineOptions()
  beforeEach(() => {
    sparklineOptions = getSparklineOptions()
  })

  it('should return an object includes line.stroke equals to lineColor', () => {
    sparklineOptions.lineColor = '#000'
    const result = getLineOptions(sparklineOptions)
    expect(result.line.stroke).toBe('#000')
    expect(result).toMatchObject({
      type: 'line',
      line: {
        stroke: '#000',
        strokeWidth: 1
      },
      padding: {
        top: 5,
        right: 1,
        bottom: 5,
        left: 1
      },
      marker: {
        strokeWidth: 1,
        formatter: expect.any(Function)
      }
    })
  })

  it('should return an object includes line.strokeWidth equals to lineWidth', () => {
    sparklineOptions.lineWidth = 4
    const result = getLineOptions(sparklineOptions)
    expect(result.line.strokeWidth).toEqual(4)
    expect(result).toMatchObject({
      type: 'line',
      line: {
        stroke: '#157fcc',
        strokeWidth: 4
      },
      padding: {
        top: 5,
        right: 1,
        bottom: 5,
        left: 1
      },
      marker: {
        strokeWidth: 1,
        formatter: expect.any(Function)
      }
    })
  })
})
