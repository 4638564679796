export default `({
    title:  tt('Campaign Picture') ,
    xtype: 'panel',
    border: true,
    header: false,
    //split: true,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b>' + tt('Campaign Picture') + '</b>'
        }]
    }],
    style: {
        "padding": "1px",
        background: '#fff',
    },
    padding: '0 0 0 0',
    viewModel: {},
    flex: 1,
    layout: 'center',
    items: [{
        xtype: 'image',
        autoEl: 'div',
        style: {
            padding: '10px',
            //   "background": "#e0e0e0"
        },
        listenerse: {
            render: function () {
                var me = this;
                me.getEl().on({
                    loads: function () {
                        var owner = me.ownerCt;
                                                if (owner) {
                            owner.updateLayout();
                            if (owner.isFloating()) {
                                owner.center();
                            }
                        }
                        if (me.isFloating()) {
                            me.center();
                        }
                    }
                });
            },
            el: {
                dblclick: function (p, e) {
                    // this.component.ownerCt.imageUploadWin()
                },
                scope: 'this'
            }
        },
        border: true,
        width: 778,
        height: 300,
        cls: 'center-image',
        bind: {
            src: '{spotRecord.ImgURL}'
        },
    }],
})
`
