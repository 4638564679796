import { updateOne, fetchOne } from '../crudoptV3'

const model = 'userSetting'

export const getUserSetting = (catalogId) => {
  return fetchOne(
    model,
    `/usersetting/${catalogId}`,
    {},
    { key: catalogId, getRowKey: (row, index) => row.catalogId }
  )
}

export const addFavorite = (catalogId, data) => {
  return updateOne(
    model,
    `/usersetting/${catalogId}`,
    { data },
    {
      key: catalogId,
      getRowKey: (row, index) => row.catalogId,
      collectionKey: {
        path: `/usersetting/${catalogId}`
      }
    }
  )
}
