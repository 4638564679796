export default {
  title: 'D3 Calendar Heatmap',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'HeatmapCalendar',
  description: '',
  iconClass: 'fa-dot-circle-o',
  color: 'yellow-bg',
  support: {
    maximizable: true
  },
  group: 'Charts'
}
