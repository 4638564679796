import { getIdFromLookupOptions } from '../index'
import { lookupOptions as defaultLookupOptions } from './mocks'

const getLookupOptions = () => {
  return defaultLookupOptions
}

describe('AgGrid:Helpers:Lookup:getIdFromLookupOptions', () => {
  let lookupOptions = getLookupOptions()
  beforeEach(() => {
    lookupOptions = getLookupOptions()
  })

  it(`should return id of matched object's by given value`, () => {
    const result = getIdFromLookupOptions({ lookupOptions, value: 'Fashion' })
    expect(result).toEqual(4)
  })

  it('should return undefined if given value cannot find in lookupOptions', () => {
    const result = getIdFromLookupOptions({ lookupOptions, value: 'Something' })
    expect(result).toEqual(undefined)
  })

  it('should return undefined if lookupOptions is an empty array', () => {
    lookupOptions = []
    const result = getIdFromLookupOptions({ lookupOptions, value: 'Fashion' })
    expect(result).toEqual(undefined)
  })
})
