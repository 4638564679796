import { useState } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import { useReduxState } from '../../hooks'
import { createLoadModalSlice, loadTreeTableSlice, tuiSlice } from '../../store/slices'
import { createLoad, getLoadTree, getDemandTree } from '../../store/api/endpoints'
import { getTreeQueryParams } from '../../utils'
import BaseModal from '../BaseModal'
import CommonModalFields from '../LoadTreeTable/CommonModalFields'
import { CreateLoadModalProps } from './CreateLoadModal.type'
import styles from './CreateLoadModal.module.scss'

export default function CreateLoadModal({
  loadStops,
  originLocations = [],
  show,
  transportationModes = [],
  onHide
}: CreateLoadModalProps) {
  const [selectedOriginLocation, setSelectedOriginLocation] = useState('')
  const [selectedTransportationMode, setSelectedTransportationMode] = useState('')
  const [createLoadModal, createLoadModalDispatch] = useReduxState(
    createLoadModalSlice.selectSlice,
    createLoadModalSlice.actions
  )
  const [{ config }] = useReduxState(tuiSlice.selectSlice)
  const [loadTreeTable] = useReduxState(loadTreeTableSlice.selectSlice)
  const [triggerCreateLoad, createLoadResponse] = createLoad.useMutation()
  const isConfirmDisabled =
    !selectedOriginLocation || !selectedTransportationMode || !loadTreeTable.selectedReasonCodeId
  const [triggerGetLoadTree] = getLoadTree.useMutation({ fixedCacheKey: 'load-tree' })
  const [triggerGetDemandTree] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })

  function handleSelectOriginLocation(event) {
    setSelectedOriginLocation(event.target.value)
  }

  function handleSelectTransportationMode(event) {
    setSelectedTransportationMode(event.target.value)
  }

  function handleHide() {
    setSelectedOriginLocation('')
    setSelectedTransportationMode('')
    createLoadModalDispatch.hide()
  }

  function handleCreateLoad() {
    const createNewLoadRequest = Object.values(loadStops).map((loadStop) => ({
      facilityKey: loadStop.Items.at(0).Id,
      itemGroup: '',
      sourceLoadName: loadStop.Id,
      quantity: 0.0
    }))

    triggerCreateLoad({
      createNewLoadRequest,
      originLocation: selectedOriginLocation,
      reasonCodeId: loadTreeTable.selectedReasonCodeId,
      transportationModeName: selectedTransportationMode
    }).then(() => {
      triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree') })
      triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree') })
    })
    createLoadModalDispatch.hide()
  }

  return (
    <BaseModal isOpen={show} onClose={onHide}>
      <BaseModal.Header>
        <BaseModal.Title>Confirm</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body>
        <CommonModalFields />
        <Row>
          <Col>
            <Form.Label htmlFor="originLocation">Origin Location</Form.Label>
          </Col>
          <Col>
            <Form.Select
              className={styles.formSelect}
              id="originLocation"
              onChange={handleSelectOriginLocation}
            >
              <option key="defaultOption" value="">
                Select origin location...
              </option>
              {originLocations.map((originLocation) => (
                <option key={originLocation} value={originLocation}>
                  {originLocation}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label htmlFor="transportation-mode">Transportation mode</Form.Label>
          </Col>
          <Col>
            <Form.Select
              className={styles.formSelect}
              id="transportation-mode"
              onChange={handleSelectTransportationMode}
            >
              <option key="defaultOption" value="">
                Select transportation mode...
              </option>
              {transportationModes.map((transportationMode) => (
                <option key={transportationMode} value={transportationMode}>
                  {transportationMode}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </BaseModal.Body>
      <BaseModal.Footer>
        <BaseModal.CancelButton onClick={handleHide}>Cancel</BaseModal.CancelButton>
        <BaseModal.ConfirmButton disabled={isConfirmDisabled} onClick={handleCreateLoad}>
          Confirm
        </BaseModal.ConfirmButton>
      </BaseModal.Footer>
    </BaseModal>
  )
}
