Ext.define('tuiv2.cmp.DiffOverallCmp', {
  extend: 'Ext.panel.Panel',
  xtype: 'tuiv2-diffoverall',
  data: [],
  tpl: new Ext.XTemplate(
    '<div class="card-group comparisonWindow">' +
      '<div class="comparisonCard">' +
      '   <ul class="tabs -top">' +
      '      <li class="tab -before">Before</li>' +
      '      <li class="tab -positive">After</li>' +
      '      <li class="tab -change">Change</li>' +
      '   </ul>' +
      '   <div class="contentContainer">' +
      '      <div class="content">' +
      '         <ul>' +
      '             <tpl for=".">',
    '                       <li>{FieldName}</li>' + '             </tpl>',
    '                     </ul>' + '                     <ul>' + '             <tpl for=".">',
    '                       <li>{[this.formatNumber(values.OldValue)]} {[this.getUnit(values)]} &nbsp</li>' +
      '             </tpl>',
    '                     </ul>' + '                     <ul>' + '             <tpl for=".">',
    '                       <li>{[this.formatNumber(values.NewValue)]} {[this.getUnit(values)]} &nbsp</li>' +
      '             </tpl>',
    '                     </ul>' + '                     <ul>' + '             <tpl for=".">',
    '                       <li><div class="indicator {[this.getClass(values.OldValue, values.NewValue)]}"></div><p>{[this.formatNumber(values.NewValue - values.OldValue)]} {[this.getUnit(values)]}</p></li>' +
      '             </tpl>',
    '         </ul>' +
      '      </div>' +
      '      <div class="tabs -bottom">' +
      '         <div class="tab -selected">Overall</div>' +
      '      </div>' +
      '   </div>' +
      '</div>',
    '</div>',
    {
      formatNumber: function (value) {
        if (value == Number.NaN || value == Number.MAX_VALUE || value == 'NaN') {
          value = 0
        }
        if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

        return Ext.util.Format.number(value, '0,000.00') == ''
          ? 0
          : Ext.util.Format.number(value, '0,000.00')
      },
      getClass: function (oldValue, newValue) {
        if (oldValue > newValue) return '-positive'
        if (newValue > oldValue) return '-negative'

        return ''
      },
      getUnit: function (values) {
        var planInfo = tuiv2.Units.getPlanInfo()
        return planInfo[values.UnitName] ? planInfo[values.UnitName] : values.Unit
      }
    }
  )
})
