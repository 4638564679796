import { Component } from 'react'

import { defaultTemplate } from '../helpers'

export default class TemplateRenderer extends Component {
  render() {
    const { schema, value } = this.props

    let val = ''

    if (schema.template) {
      const watch = {}

      if (schema.watch) {
        for (const key in schema.watch) {
          const element = schema.watch[key]
          watch[key] = this.props.getWatchedValue(element)
        }
      }

      const templateCompiled = defaultTemplate().compile(schema.template)
      val = templateCompiled({ self: value, ...watch })

      if (val === 'undefined') {
        console.log('TemplateRenderer -> render -> val, schema, value', val, schema, value)
        val = ''
      }
    }

    return <input disabled className="form-control" type="text" value={val} />
  }
}
