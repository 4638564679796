import React from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import {
  PRODUCTselectProduct as onProductSelect,
  PRODUCTsaveResourceIndex as saveResourceIndex
} from '../../../../actions'
import { getRowBgColor } from '../../../../utils'

class Box extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSelected: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(
    productIndex,
    periodIndex,
    testResourceId,
    testResourceName,
    periodName,
    periodId,
    productName,
    productId,
    locationNames,
    isGrandTotal,
    pegTags,
    hasProduction
  ) {
    const {
      type,
      isConsumedBy,
      onPeriodChange,
      testResourceIndex,
      isProductPeriod
      // onClickOwnProductAndPeriodIndex
    } = this.props
    if (isConsumedBy) {
      onPeriodChange(periodIndex)
      return
    }
    this.props.onProductSelect({
      productIndex,
      periodIndex,
      isResourceChart: false,
      resourceName: testResourceName,
      resourceId: testResourceId,
      periodName,
      periodId,
      productName,
      productId,
      locationNames,
      pegTags,
      hasProduction,
      isProductPeriod
    })
    this.props.saveResourceIndex(testResourceIndex)
    // onClickOwnProductAndPeriodIndex(productIndex, periodIndex)
  }

  render() {
    const {
      productIndex = undefined,
      periodIndex = undefined,
      clickedProductInfo: {
        productIndex: clickedProductIndex = undefined,
        periodIndex: clickedPeriodIndex = undefined,
        locationNames: clickedProductLocationNames = []
      } = {},
      year = undefined,
      type = undefined,
      paintOnClick,
      isIssue,
      testResourceIndex = 0,
      resourceIndex,
      isFrozenWeek = false,
      testResourceId,
      testResourceName,
      periodName,
      periodId,
      productName,
      productId,
      locationNames,
      children,
      isGrandTotal,
      pegTags,
      hasProduction,
      level
    } = this.props

    const isClicked =
      periodIndex === clickedPeriodIndex && isEqual(locationNames, clickedProductLocationNames)
    const boxStyles = {
      ...(isIssue && { alignItems: 'center' }),
      ...(productIndex === clickedProductIndex &&
        periodIndex === clickedPeriodIndex &&
        productIndex !== undefined &&
        type === 'Product' &&
        paintOnClick &&
        testResourceIndex === resourceIndex && {
          zIndex: 200
        }),
      ...(year === undefined && { backgroundColor: '#f8f8f8' }),
      ...((type !== 'Product' || isGrandTotal) && { cursor: 'default' }),
      background: getRowBgColor(0, level === 0 ? 'Resource' : 'Resource')
    }

    return (
      <div
        className={`resource-plan__period-box ${isClicked ? 'is-clicked' : ''}`}
        style={boxStyles}
        onMouseDown={this.handleClick.bind(
          this,
          productIndex,
          periodIndex,
          testResourceId,
          testResourceName,
          periodName,
          periodId,
          productName,
          productId,
          locationNames,
          isGrandTotal,
          pegTags,
          hasProduction
        )}
      >
        <div>{year}</div>
        {children}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clickedProductInfo: state.PRODUCTclickedProductInfo.clickedProductInfo,
  resourceIndex: state.PRODUCTclickedProductInfo.resourceIndex
})

const mapDispatchToProps = {
  onProductSelect,
  saveResourceIndex
}

export default connect(mapStateToProps, mapDispatchToProps)(Box)
