import { EmptyObject, TrowDataValue } from '../../types'
import { GetComparatorProps, GetComparatorReturnType } from './sort.types'

function getCustomComparator(params: GetComparatorProps): GetComparatorReturnType | EmptyObject {
  if (!params.sortable || params.dataType !== 'string') {
    return {}
  }

  return {
    // case insensitive sort comparator
    comparator: (
      prevValue: TrowDataValue | undefined,
      nextValue: TrowDataValue | undefined
    ): number => {
      if (prevValue == null) return -1
      if (nextValue == null) return 1
      if (typeof prevValue === 'string' && typeof nextValue === 'string') {
        return prevValue.toLowerCase().localeCompare(nextValue.toLowerCase())
      }
      return -1
    }
  }
}

export { getCustomComparator }
