import { useMemo } from 'react'
import { ValueFormatterParams, DataTypeDefinition } from 'ag-grid-community'
import { getFormattedValueGrid } from '../../helpers'
import { IRowData, TrowDataValue } from '../../types'
import { DataTypeDefinitionsProps } from './useDataTypeDefinitions.types'

function useDataTypeDefinitions(props: DataTypeDefinitionsProps) {
  const { fieldConfigs, getFormattedValue, formatValue, formattedFields, editableFields } = props
  const dataTypeDefinitions = useMemo((): {
    date: DataTypeDefinition<IRowData>
  } => {
    return {
      // override `date` to handle custom date format by formatted fields
      date: {
        baseDataType: 'date',
        extendsDataType: 'date',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        valueFormatter: (params: ValueFormatterParams<IRowData, Date>) => {
          const {
            data,
            value,
            colDef: {
              field,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              customInfo: { columnType, formatField }
            }
          } = params

          if (value === null) {
            return ''
          }

          const columnConfig = {
            fieldName: field,
            columnType,
            formatField
          }

          const record = { data: data as Exclude<IRowData, undefined> }

          return getFormattedValueGrid({
            value: value as TrowDataValue,
            columnConfig,
            record,
            fieldConfigs,
            getFormattedValue,
            formatValue,
            formattedFields,
            editableFields
          })
        }
      }
    }
  }, [fieldConfigs, getFormattedValue, formatValue, formattedFields, editableFields])

  return { dataTypeDefinitions }
}

export default useDataTypeDefinitions
