const SplitActions = (props) => {
  const { keyParam, onHandleSplit = () => {}, onHandleRemove = () => {} } = props
  return (
    <div className="-reflex-actions">
      <i
        className="slvy-ui-icon-dots-horizontal"
        title="Horizontal"
        onClick={onHandleSplit.bind(this, [...keyParam], 'horizontal')}
      />
      <i
        className="slvy-ui-icon-dots-vertical"
        title="Vertical"
        onClick={onHandleSplit.bind(this, [...keyParam], 'vertical')}
      />
      <i
        className="slvy-ui-icon-trash-can"
        title="Remove"
        onClick={onHandleRemove.bind(this, [...keyParam])}
      />
    </div>
  )
}

export default SplitActions
