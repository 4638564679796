import { FETCH_MFP_DATA_START, STORE_MFP_DATA, STORE_MFP_DATA_FAILED } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetMfpData: {},
  isLoading: true,
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MFP_DATA_START:
      return updateObject(state, {
        isLoading: true
      })
    case STORE_MFP_DATA:
      return updateObject(state, {
        GetMfpData: { ...action.payload }
      })
    case STORE_MFP_DATA_FAILED:
      return updateObject(state, {
        GetMfpData: {},
        error: { ...action.payload },
        isLoading: false
      })
    default:
      return state
  }
}

export default reducer
