import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type HeaderSliceState = {
  savePlanModal: boolean
  snapshotModal: boolean
}

const initialState: HeaderSliceState = {
  savePlanModal: false,
  snapshotModal: false
}

const headerSlice = createSlice({
  name: 'headerSlice',
  initialState,
  reducers: {
    setSavePlanModal: (state, action: PayloadAction<HeaderSliceState['savePlanModal']>) => {
      state.savePlanModal = action.payload
    },
    setSnapshotModal: (state, action: PayloadAction<HeaderSliceState['snapshotModal']>) => {
      state.snapshotModal = action.payload
    }
  }
})

export default headerSlice
export type { HeaderSliceState }
