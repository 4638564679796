export default [
  {
    section: 'solvoyo-ui-icons',
    icons: [
      {
        c: 'slvy-ui-icon-caret-left',
        l: 'slvy-caret-left'
      },
      {
        c: 'slvy-ui-icon-caret-right',
        l: 'slvy-caret-right'
      },
      {
        c: 'slvy-ui-icon-play',
        l: 'slvy-play'
      },
      {
        c: 'slvy-ui-icon-caret-top',
        l: 'slvy-caret-top'
      },
      {
        c: 'slvy-ui-icon-caret-down',
        l: 'slvy-caret-down'
      },
      {
        c: 'slvy-ui-icon-caret-outline-left',
        l: 'slvy-caret-outline-left'
      },
      {
        c: 'slvy-ui-icon-caret-outline-right',
        l: 'slvy-caret-outline-right'
      },
      {
        c: 'slvy-ui-icon-caret-outline-up',
        l: 'slvy-caret-outline-up'
      },
      {
        c: 'slvy-ui-icon-caret-outline-down',
        l: 'slvy-caret-outline-down'
      },
      {
        c: 'slvy-ui-icon-angle-left-extra-lt',
        l: 'slvy-angle-left-extra-lt'
      },
      {
        c: 'slvy-ui-icon-angle-left',
        l: 'slvy-angle-left'
      },
      {
        c: 'slvy-ui-icon-angle-right-extra-lt',
        l: 'slvy-angle-right-extra-lt'
      },
      {
        c: 'slvy-ui-icon-angle-right',
        l: 'slvy-angle-right'
      },
      {
        c: 'slvy-ui-icon-angle-up-extra-lt',
        l: 'slvy-angle-up-extra-lt'
      },
      {
        c: 'slvy-ui-icon-angle-up',
        l: 'slvy-angle-up'
      },
      {
        c: 'slvy-ui-icon-angle-down-extra-lt',
        l: 'slvy-angle-down-extra-lt'
      },
      {
        c: 'slvy-ui-icon-angle-down',
        l: 'slvy-angle-down'
      },
      {
        c: 'slvy-ui-icon-chevron-left',
        l: 'slvy-chevron-left'
      },
      {
        c: 'slvy-ui-icon-chevron-right',
        l: 'slvy-chevron-right'
      },
      {
        c: 'slvy-ui-icon-chevron-up',
        l: 'slvy-chevron-up'
      },
      {
        c: 'slvy-ui-icon-chevron-down',
        l: 'slvy-chevron-down'
      },
      {
        c: 'slvy-ui-icon-arrow-left-extra-lt',
        l: 'slvy-arrow-left-extra-lt'
      },
      {
        c: 'slvy-ui-icon-arrow-left',
        l: 'slvy-arrow-left'
      },
      {
        c: 'slvy-ui-icon-arrow-right-extra-lt',
        l: 'slvy-arrow-right-extra-lt'
      },
      {
        c: 'slvy-ui-icon-arrow-right',
        l: 'slvy-arrow-right'
      },
      {
        c: 'slvy-ui-icon-arrow-up-extra-lt',
        l: 'slvy-arrow-up-extra-lt'
      },
      {
        c: 'slvy-ui-icon-arrow-up',
        l: 'slvy-arrow-up'
      },
      {
        c: 'slvy-ui-icon-arrow-down-extra-lt',
        l: 'slvy-arrow-down-extra-lt'
      },
      {
        c: 'slvy-ui-icon-arrow-down',
        l: 'slvy-arrow-down'
      },
      {
        c: 'slvy-ui-icon-arrow-up-circle',
        l: 'slvy-arrow-up-circle'
      },
      {
        c: 'slvy-ui-icon-arrow-left-circle',
        l: 'slvy-arrow-left-circle'
      },
      {
        c: 'slvy-ui-icon-arrow-down-circle',
        l: 'slvy-arrow-down-circle'
      },
      {
        c: 'slvy-ui-icon-arrow-right-circle',
        l: 'slvy-arrow-right-circle'
      },
      {
        c: 'slvy-ui-icon-truck',
        l: 'slvy-truck'
      },
      {
        c: 'slvy-ui-icon-truck-outline',
        l: 'slvy-truck-outline'
      },
      {
        c: 'slvy-ui-icon-truck-out',
        l: 'slvy-truck-out'
      },
      {
        c: 'slvy-ui-icon-truck-out-outline',
        l: 'slvy-truck-out-outline'
      },
      {
        c: 'slvy-ui-icon-truck-in',
        l: 'slvy-truck-in'
      },
      {
        c: 'slvy-ui-icon-truck-in-outline',
        l: 'slvy-truck-in-outline'
      },
      {
        c: 'slvy-ui-icon-target',
        l: 'slvy-target'
      },
      {
        c: 'slvy-ui-icon-compass',
        l: 'slvy-compass'
      },
      {
        c: 'slvy-ui-icon-map-route',
        l: 'slvy-map-route'
      },
      {
        c: 'slvy-ui-icon-pin-solid',
        l: 'slvy-pin-solid'
      },
      {
        c: 'slvy-ui-icon-pin',
        l: 'slvy-pin'
      },
      {
        c: 'slvy-ui-icon-pin-plus',
        l: 'slvy-pin-plus'
      },
      {
        c: 'slvy-ui-icon-pin-times',
        l: 'slvy-pin-times'
      },
      {
        c: 'slvy-ui-icon-desktop',
        l: 'slvy-desktop'
      },
      {
        c: 'slvy-ui-icon-mobile',
        l: 'slvy-mobile'
      },
      {
        c: 'slvy-ui-icon-world',
        l: 'slvy-world'
      },
      {
        c: 'slvy-ui-icon-search',
        l: 'slvy-search'
      },
      {
        c: 'slvy-ui-icon-dot1',
        l: 'slvy-dot1'
      },
      {
        c: 'slvy-ui-icon-dots-vertical',
        l: 'slvy-dots-vertical'
      },
      {
        c: 'slvy-ui-icon-grid',
        l: 'slvy-grid'
      },
      {
        c: 'slvy-ui-icon-grid-outline',
        l: 'slvy-grid-outline'
      },
      {
        c: 'slvy-ui-icon-grid-inverted',
        l: 'slvy-grid-inverted'
      },
      {
        c: 'slvy-ui-icon-grid-selected',
        l: 'slvy-grid-selected'
      },
      {
        c: 'slvy-ui-icon-burger-lt',
        l: 'slvy-burger-lt'
      },
      {
        c: 'slvy-ui-icon-list-extra-lt',
        l: 'slvy-list-extra-lt'
      },
      {
        c: 'slvy-ui-icon-sort-down',
        l: 'slvy-sort-down'
      },
      {
        c: 'slvy-ui-icon-sort-up',
        l: 'slvy-sort-up'
      },
      {
        c: 'slvy-ui-icon-sort-solid',
        l: 'slvy-sort-solid'
      },
      {
        c: 'slvy-ui-icon-sort-outline',
        l: 'slvy-sort-outline'
      },
      {
        c: 'slvy-ui-icon-unsorted',
        l: 'slvy-unsorted'
      },
      {
        c: 'slvy-ui-icon-sorted',
        l: 'slvy-sorted'
      },
      {
        c: 'slvy-ui-icon-bolt',
        l: 'slvy-bolt'
      },
      {
        c: 'slvy-ui-icon-bookmark',
        l: 'slvy-bookmark'
      },
      {
        c: 'slvy-ui-icon-bookmark-star',
        l: 'slvy-bookmark-star'
      },
      {
        c: 'slvy-ui-icon-factory-outline',
        l: 'slvy-factory-outline'
      },
      {
        c: 'slvy-ui-icon-building',
        l: 'slvy-building'
      },
      {
        c: 'slvy-ui-icon-shop-2',
        l: 'slvy-shop-2'
      },
      {
        c: 'slvy-ui-icon-shop',
        l: 'slvy-shop'
      },
      {
        c: 'slvy-ui-icon-calendar',
        l: 'slvy-calendar'
      },
      {
        c: 'slvy-ui-icon-calendar-outline',
        l: 'slvy-calendar-outline'
      },
      {
        c: 'slvy-ui-icon-calendar-add',
        l: 'slvy-calendar-add'
      },
      {
        c: 'slvy-ui-icon-calendar-minus',
        l: 'slvy-calendar-minus'
      },
      {
        c: 'slvy-ui-icon-calendar-remove',
        l: 'slvy-calendar-remove'
      },
      {
        c: 'slvy-ui-icon-export-box',
        l: 'slvy-export-box'
      },
      {
        c: 'slvy-ui-icon-box-checked',
        l: 'slvy-box-checked'
      },
      {
        c: 'slvy-ui-icon-box_1',
        l: 'slvy-box_1'
      },
      {
        c: 'slvy-ui-icon-chart-area',
        l: 'slvy-chart-area'
      },
      {
        c: 'slvy-ui-icon-chart-bar-regular',
        l: 'slvy-chart-bar-regular'
      },
      {
        c: 'slvy-ui-icon-chart-down-regular',
        l: 'slvy-chart-down-regular'
      },
      {
        c: 'slvy-ui-icon-chart-up-regular',
        l: 'slvy-chart-up-regular'
      },
      {
        c: 'slvy-ui-icon-arrow-horizontal',
        l: 'slvy-arrow-horizontal'
      },
      {
        c: 'slvy-ui-icon-arrow-vertical',
        l: 'slvy-arrow-vertical'
      },
      {
        c: 'slvy-ui-icon-move',
        l: 'slvy-move'
      },
      {
        c: 'slvy-ui-icon-maximize',
        l: 'slvy-maximize'
      },
      {
        c: 'slvy-ui-icon-minimize',
        l: 'slvy-minimize'
      },
      {
        c: 'slvy-ui-icon-plus-lt',
        l: 'slvy-plus-lt'
      },
      {
        c: 'slvy-ui-icon-check-extra-lt',
        l: 'slvy-check-extra-lt'
      },
      {
        c: 'slvy-ui-icon-times-extra-lt',
        l: 'slvy-times-extra-lt'
      },
      {
        c: 'slvy-ui-icon-times-lt',
        l: 'slvy-times-lt'
      },
      {
        c: 'slvy-ui-icon-plus-circle-regular',
        l: 'slvy-plus-circle-regular'
      },
      {
        c: 'slvy-ui-icon-check-circle-regular',
        l: 'slvy-check-circle-regular'
      },
      {
        c: 'slvy-ui-icon-times-circle-regular',
        l: 'slvy-times-circle-regular'
      },
      {
        c: 'slvy-ui-icon-redo-extra-lt',
        l: 'slvy-redo-extra-lt'
      },
      {
        c: 'slvy-ui-icon-redo',
        l: 'slvy-redo'
      },
      {
        c: 'slvy-ui-icon-undo-extra-lt',
        l: 'slvy-undo-extra-lt'
      },
      {
        c: 'slvy-ui-icon-undo',
        l: 'slvy-undo'
      },
      {
        c: 'slvy-ui-icon-recycle',
        l: 'slvy-recycle'
      },
      {
        c: 'slvy-ui-icon-reset',
        l: 'slvy-reset'
      },
      {
        c: 'slvy-ui-icon-history',
        l: 'slvy-history'
      },
      {
        c: 'slvy-ui-icon-transfer',
        l: 'slvy-transfer'
      },
      {
        c: 'slvy-ui-icon-toggle-on',
        l: 'slvy-toggle-on'
      },
      {
        c: 'slvy-ui-icon-toggle-off',
        l: 'slvy-toggle-off'
      },
      {
        c: 'slvy-ui-icon-circle-fill',
        l: 'slvy-circle-fill'
      },
      {
        c: 'slvy-ui-icon-circle-extra-lt',
        l: 'slvy-circle-extra-lt'
      },
      {
        c: 'slvy-ui-icon-circle-outline',
        l: 'slvy-circle-outline'
      },
      {
        c: 'slvy-ui-icon-circle-out',
        l: 'slvy-circle-out'
      },
      {
        c: 'slvy-ui-icon-circle-check',
        l: 'slvy-circle-check'
      },
      {
        c: 'slvy-ui-icon-select-all',
        l: 'slvy-select-all'
      },
      {
        c: 'slvy-ui-icon-clock-regular',
        l: 'slvy-clock-regular'
      },
      {
        c: 'slvy-ui-icon-on-off',
        l: 'slvy-on-off'
      },
      {
        c: 'slvy-ui-icon-cog',
        l: 'slvy-cog'
      },
      {
        c: 'slvy-ui-icon-cog-outline',
        l: 'slvy-cog-outline'
      },
      {
        c: 'slvy-ui-icon-cog-simple-extra-lt',
        l: 'slvy-cog-simple-extra-lt'
      },
      {
        c: 'slvy-ui-icon-options',
        l: 'slvy-options'
      },
      {
        c: 'slvy-ui-icon-tools',
        l: 'slvy-tools'
      },
      {
        c: 'slvy-ui-icon-dollar-extra-lt',
        l: 'slvy-dollar-extra-lt'
      },
      {
        c: 'slvy-ui-icon-dollar-sign-circle-extra-lt',
        l: 'slvy-dollar-sign-circle-extra-lt'
      },
      {
        c: 'slvy-ui-icon-question-circle-solid',
        l: 'slvy-question-circle-solid'
      },
      {
        c: 'slvy-ui-icon-question-circle-outline',
        l: 'slvy-question-circle-outline'
      },
      {
        c: 'slvy-ui-icon-question',
        l: 'slvy-question'
      },
      {
        c: 'slvy-ui-icon-exclamation-triangle-solid',
        l: 'slvy-exclamation-triangle-solid'
      },
      {
        c: 'slvy-ui-icon-info',
        l: 'slvy-info'
      },
      {
        c: 'slvy-ui-icon-info-circle-solid',
        l: 'slvy-info-circle-solid'
      },
      {
        c: 'slvy-ui-icon-exclamation-circle-solid',
        l: 'slvy-exclamation-circle-solid'
      },
      {
        c: 'slvy-ui-icon-exclamation-circle-outline',
        l: 'slvy-exclamation-circle-outline'
      },
      {
        c: 'slvy-ui-icon-save',
        l: 'slvy-save'
      },
      {
        c: 'slvy-ui-icon-save-as',
        l: 'slvy-save-as'
      },
      {
        c: 'slvy-ui-icon-edit',
        l: 'slvy-edit'
      },
      {
        c: 'slvy-ui-icon-edit-extra-lt',
        l: 'slvy-edit-extra-lt'
      },
      {
        c: 'slvy-ui-icon-field',
        l: 'slvy-field'
      },
      {
        c: 'slvy-ui-icon-eraser',
        l: 'slvy-eraser'
      },
      {
        c: 'slvy-ui-icon-copy',
        l: 'slvy-copy'
      },
      {
        c: 'slvy-ui-icon-copy-extra-lt',
        l: 'slvy-copy-extra-lt'
      },
      {
        c: 'slvy-ui-icon-clipboard-remove',
        l: 'slvy-clipboard-remove'
      },
      {
        c: 'slvy-ui-icon-clipboard',
        l: 'slvy-clipboard'
      },
      {
        c: 'slvy-ui-icon-design',
        l: 'slvy-design'
      },
      {
        c: 'slvy-ui-icon-table',
        l: 'slvy-table'
      },
      {
        c: 'slvy-ui-icon-columns',
        l: 'slvy-columns'
      },
      {
        c: 'slvy-ui-icon-align-left',
        l: 'slvy-align-left'
      },
      {
        c: 'slvy-ui-icon-align-right',
        l: 'slvy-align-right'
      },
      {
        c: 'slvy-ui-icon-align-center',
        l: 'slvy-align-center'
      },
      {
        c: 'slvy-ui-icon-sort-ascending',
        l: 'slvy-sort-ascending'
      },
      {
        c: 'slvy-ui-icon-sort-descending',
        l: 'slvy-sort-descending'
      },
      {
        c: 'slvy-ui-icon-export',
        l: 'slvy-export'
      },
      {
        c: 'slvy-ui-icon-export-regular',
        l: 'slvy-export-regular'
      },
      {
        c: 'slvy-ui-icon-import',
        l: 'slvy-import'
      },
      {
        c: 'slvy-ui-icon-import-regular',
        l: 'slvy-import-regular'
      },
      {
        c: 'slvy-ui-icon-funnel',
        l: 'slvy-funnel'
      },
      {
        c: 'slvy-ui-icon-attachment',
        l: 'slvy-attachment'
      },
      {
        c: 'slvy-ui-icon-bell',
        l: 'slvy-bell'
      },
      {
        c: 'slvy-ui-icon-bell-silent',
        l: 'slvy-bell-silent'
      },
      {
        c: 'slvy-ui-icon-trash-can',
        l: 'slvy-trash-can'
      },
      {
        c: 'slvy-ui-icon-db',
        l: 'slvy-db'
      },
      {
        c: 'slvy-ui-icon-dashboard',
        l: 'slvy-dashboard'
      },
      {
        c: 'slvy-ui-icon-box',
        l: 'slvy-box'
      },
      {
        c: 'slvy-ui-icon-box-out',
        l: 'slvy-box-out'
      },
      {
        c: 'slvy-ui-icon-box-in',
        l: 'slvy-box-in'
      },
      {
        c: 'slvy-ui-icon-boxes',
        l: 'slvy-boxes'
      },
      {
        c: 'slvy-ui-icon-card',
        l: 'slvy-card'
      },
      {
        c: 'slvy-ui-icon-document',
        l: 'slvy-document'
      },
      {
        c: 'slvy-ui-icon-document-outline',
        l: 'slvy-document-outline'
      },
      {
        c: 'slvy-ui-icon-folder-close',
        l: 'slvy-folder-close'
      },
      {
        c: 'slvy-ui-icon-folder-close-outline',
        l: 'slvy-folder-close-outline'
      },
      {
        c: 'slvy-ui-icon-folder-open',
        l: 'slvy-folder-open'
      },
      {
        c: 'slvy-ui-icon-folder-open-outline',
        l: 'slvy-folder-open-outline'
      },
      {
        c: 'slvy-ui-icon-mail',
        l: 'slvy-mail'
      },
      {
        c: 'slvy-ui-icon-mail-open',
        l: 'slvy-mail-open'
      },
      {
        c: 'slvy-ui-icon-message',
        l: 'slvy-message'
      },
      {
        c: 'slvy-ui-icon-chat',
        l: 'slvy-chat'
      },
      {
        c: 'slvy-ui-icon-receipt-dollar-solid',
        l: 'slvy-receipt-dollar-solid'
      },
      {
        c: 'slvy-ui-icon-scenario-approve-regular',
        l: 'slvy-scenario-approve-regular'
      },
      {
        c: 'slvy-ui-icon-scenario-approve-extra-lt',
        l: 'slvy-scenario-approve-extra-lt'
      },
      {
        c: 'slvy-ui-icon-scenario-change-regular',
        l: 'slvy-scenario-change-regular'
      },
      {
        c: 'slvy-ui-icon-scenario-change-extra-lt',
        l: 'slvy-scenario-change-extra-lt'
      },
      {
        c: 'slvy-ui-icon-scenario-delete-regular',
        l: 'slvy-scenario-delete-regular'
      },
      {
        c: 'slvy-ui-icon-scenario-delete-extra-lt',
        l: 'slvy-scenario-delete-extra-lt'
      },
      {
        c: 'slvy-ui-icon-snapshot-regular',
        l: 'slvy-snapshot-regular'
      },
      {
        c: 'slvy-ui-icon-snapshot-approve-regular',
        l: 'slvy-snapshot-approve-regular'
      },
      {
        c: 'slvy-ui-icon-snapshot-delete-regular',
        l: 'slvy-snapshot-delete-regular'
      },
      {
        c: 'slvy-ui-icon-constraint',
        l: 'slvy-constraint'
      },
      {
        c: 'slvy-ui-icon-pivot-table-regular',
        l: 'slvy-pivot-table-regular'
      },
      {
        c: 'slvy-ui-icon-star-solid',
        l: 'slvy-star-solid'
      },
      {
        c: 'slvy-ui-icon-star-outline-regular',
        l: 'slvy-star-outline-regular'
      },
      {
        c: 'slvy-ui-icon-view-regular',
        l: 'slvy-view-regular'
      },
      {
        c: 'slvy-ui-icon-unview-regular',
        l: 'slvy-unview-regular'
      },
      {
        c: 'slvy-ui-icon-upload-regular',
        l: 'slvy-upload-regular'
      },
      {
        c: 'slvy-ui-icon-download-regular',
        l: 'slvy-download-regular'
      },
      {
        c: 'slvy-ui-icon-upload-cloud-regular',
        l: 'slvy-upload-cloud-regular'
      },
      {
        c: 'slvy-ui-icon-download-cloud-extra-lt',
        l: 'slvy-download-cloud-extra-lt'
      },
      {
        c: 'slvy-ui-icon-cloud-transfer-line_1',
        l: 'slvy-cloud-transfer-line_1'
      },
      {
        c: 'slvy-ui-icon-address-book',
        l: 'slvy-address-book'
      },
      {
        c: 'slvy-ui-icon-user-solid',
        l: 'slvy-user-solid'
      },
      {
        c: 'slvy-ui-icon-users-solid',
        l: 'slvy-users-solid'
      },
      {
        c: 'slvy-ui-icon-user-id-regular',
        l: 'slvy-user-id-regular'
      },
      {
        c: 'slvy-ui-icon-fingerprint',
        l: 'slvy-fingerprint'
      },
      {
        c: 'slvy-ui-icon-tag-extra-lt',
        l: 'slvy-tag-extra-lt'
      },
      {
        c: 'slvy-ui-icon-money-extra-lt',
        l: 'slvy-money-extra-lt'
      },
      {
        c: 'slvy-ui-icon-tree',
        l: 'slvy-tree'
      },
      {
        c: 'slvy-ui-icon-change',
        l: 'slvy-change'
      },
      {
        c: 'slvy-ui-icon-merge',
        l: 'slvy-merge'
      },
      {
        c: 'slvy-ui-icon-merge-different',
        l: 'slvy-merge-different'
      },
      {
        c: 'slvy-ui-icon-split',
        l: 'slvy-split'
      },
      {
        c: 'slvy-ui-icon-send-all',
        l: 'slvy-send-all'
      },
      {
        c: 'slvy-ui-icon-method',
        l: 'slvy-method'
      },
      {
        c: 'slvy-ui-icon-diff',
        l: 'slvy-diff'
      },
      {
        c: 'slvy-ui-icon-diff-all',
        l: 'slvy-diff-all'
      },
      {
        c: 'slvy-ui-icon-unlock',
        l: 'slvy-unlock'
      },
      {
        c: 'slvy-ui-icon-quantum-lock',
        l: 'slvy-quantum-lock'
      },
      {
        c: 'slvy-ui-icon-lock',
        l: 'slvy-lock'
      },
      {
        c: 'slvy-ui-icon-lock-check',
        l: 'slvy-lock-check'
      },
      {
        c: 'slvy-ui-icon-lock-cross',
        l: 'slvy-lock-cross'
      },
      {
        c: 'slvy-ui-icon-shield',
        l: 'slvy-shield'
      },
      {
        c: 'slvy-ui-icon-shield-check',
        l: 'slvy-shield-check'
      },
      {
        c: 'slvy-ui-icon-plus-outline',
        l: 'slvy-plus-outline'
      },
      {
        c: 'slvy-ui-icon-plus-fill',
        l: 'slvy-plus-fill'
      },
      {
        c: 'slvy-ui-icon-minus-outline',
        l: 'slvy-minus-outline'
      },
      {
        c: 'slvy-ui-icon-minus-fill',
        l: 'slvy-minus-fill'
      },
      {
        c: 'slvy-ui-icon-0',
        l: 'slvy-0'
      },
      {
        c: 'slvy-ui-icon-1',
        l: 'slvy-1'
      },
      {
        c: 'slvy-ui-icon-2',
        l: 'slvy-2'
      },
      {
        c: 'slvy-ui-icon-3',
        l: 'slvy-3'
      },
      {
        c: 'slvy-ui-icon-4',
        l: 'slvy-4'
      },
      {
        c: 'slvy-ui-icon-5',
        l: 'slvy-5'
      },
      {
        c: 'slvy-ui-icon-6',
        l: 'slvy-6'
      },
      {
        c: 'slvy-ui-icon-7',
        l: 'slvy-7'
      },
      {
        c: 'slvy-ui-icon-8',
        l: 'slvy-8'
      },
      {
        c: 'slvy-ui-icon-9',
        l: 'slvy-9'
      },
      {
        c: 'slvy-ui-icon-A',
        l: 'slvy-A'
      },
      {
        c: 'slvy-ui-icon-B',
        l: 'slvy-B'
      },
      {
        c: 'slvy-ui-icon-C',
        l: 'slvy-C'
      },
      {
        c: 'slvy-ui-icon-D',
        l: 'slvy-D'
      },
      {
        c: 'slvy-ui-icon-E',
        l: 'slvy-E'
      },
      {
        c: 'slvy-ui-icon-F',
        l: 'slvy-F'
      },
      {
        c: 'slvy-ui-icon-G',
        l: 'slvy-G'
      },
      {
        c: 'slvy-ui-icon-I',
        l: 'slvy-I'
      },
      {
        c: 'slvy-ui-icon-J',
        l: 'slvy-J'
      },
      {
        c: 'slvy-ui-icon-K',
        l: 'slvy-K'
      },
      {
        c: 'slvy-ui-icon-L',
        l: 'slvy-L'
      },
      {
        c: 'slvy-ui-icon-M',
        l: 'slvy-M'
      },
      {
        c: 'slvy-ui-icon-N',
        l: 'slvy-N'
      },
      {
        c: 'slvy-ui-icon-O',
        l: 'slvy-O'
      },
      {
        c: 'slvy-ui-icon-P',
        l: 'slvy-P'
      },
      {
        c: 'slvy-ui-icon-Q',
        l: 'slvy-Q'
      },
      {
        c: 'slvy-ui-icon-R',
        l: 'slvy-R'
      },
      {
        c: 'slvy-ui-icon-S',
        l: 'slvy-S'
      },
      {
        c: 'slvy-ui-icon-T',
        l: 'slvy-T'
      },
      {
        c: 'slvy-ui-icon-U',
        l: 'slvy-U'
      },
      {
        c: 'slvy-ui-icon-V',
        l: 'slvy-V'
      },
      {
        c: 'slvy-ui-icon-W',
        l: 'slvy-W'
      },
      {
        c: 'slvy-ui-icon-X',
        l: 'slvy-X'
      },
      {
        c: 'slvy-ui-icon-Y',
        l: 'slvy-Y'
      },
      {
        c: 'slvy-ui-icon-Z',
        l: 'slvy-Z'
      }
    ]
  },
  {
    section: 'font-awesome-5',
    icons: [
      {
        c: 'fa-glass-martini',
        l: 'glass-martini'
      },
      {
        c: 'fa-trash-alt',
        l: 'trash-alt'
      },
      {
        c: 'fa-clock',
        l: 'clock'
      },
      {
        c: 'fa-arrow-alt-circle-down',
        l: 'arrow-alt-circle-down'
      },
      {
        c: 'fa-arrow-alt-circle-up',
        l: 'arrow-alt-circle-up'
      },
      {
        c: 'fa-redo',
        l: 'redo'
      },
      {
        c: 'fa-sync',
        l: 'sync'
      },
      {
        c: 'fa-video',
        l: 'video'
      },
      {
        c: 'fa-pencil-alt',
        l: 'pencil-alt'
      },
      {
        c: 'fa-map-marker-alt',
        l: 'map-marker-alt'
      },
      {
        c: 'fa-calendar-alt',
        l: 'calendar-alt'
      },
      {
        c: 'fa-arrows-alt-v',
        l: 'arrows-alt-v'
      },
      {
        c: 'fa-arrows-alt-h',
        l: 'arrows-alt-h'
      },
      {
        c: 'fa-chart-bar',
        l: 'chart-bar'
      },
      {
        c: 'fa-sign-out-alt',
        l: 'sign-out-alt'
      },
      {
        c: 'fa-thumbtack',
        l: 'thumbtack'
      },
      {
        c: 'fa-external-link-alt',
        l: 'external-link-alt'
      },
      {
        c: 'fa-sign-in-alt',
        l: 'sign-in-alt'
      },
      {
        c: 'fa-lemon',
        l: 'lemon'
      },
      {
        c: 'fa-hdd',
        l: 'hdd'
      },
      {
        c: 'fa-hand-point-right',
        l: 'hand-point-right'
      },
      {
        c: 'fa-hand-point-left',
        l: 'hand-point-left'
      },
      {
        c: 'fa-hand-point-up',
        l: 'hand-point-up'
      },
      {
        c: 'fa-hand-point-down',
        l: 'hand-point-down'
      },
      {
        c: 'fa-expand-arrows-alt',
        l: 'expand-arrows-alt'
      },
      {
        c: 'fa-money-bill-alt',
        l: 'money-bill-alt'
      },
      {
        c: 'fa-tachometer-alt',
        l: 'tachometer-alt'
      },
      {
        c: 'fa-lightbulb',
        l: 'lightbulb'
      },
      {
        c: 'fa-exchange-alt',
        l: 'exchange-alt'
      },
      {
        c: 'fa-cloud-download-alt',
        l: 'cloud-download-alt'
      },
      {
        c: 'fa-cloud-upload-alt',
        l: 'cloud-upload-alt'
      },
      {
        c: 'fa-utensils',
        l: 'utensils'
      },
      {
        c: 'fa-file-alt',
        l: 'file-alt'
      },
      {
        c: 'fa-hospital',
        l: 'hospital'
      },
      {
        c: 'fa-tablet-alt',
        l: 'tablet-alt'
      },
      {
        c: 'fa-mobile-alt',
        l: 'mobile-alt'
      },
      {
        c: 'fa-smile',
        l: 'smile'
      },
      {
        c: 'fa-frown',
        l: 'frown'
      },
      {
        c: 'fa-meh',
        l: 'meh'
      },
      {
        c: 'fa-keyboard',
        l: 'keyboard'
      },
      {
        c: 'fa-code-branch',
        l: 'code-branch'
      },
      {
        c: 'fa-shield-alt',
        l: 'shield-alt'
      },
      {
        c: 'fa-ticket-alt',
        l: 'ticket-alt'
      },
      {
        c: 'fa-level-up-alt',
        l: 'level-up-alt'
      },
      {
        c: 'fa-level-down-alt',
        l: 'level-down-alt'
      },
      {
        c: 'fa-pen-square',
        l: 'pen-square'
      },
      {
        c: 'fa-external-link-square-alt',
        l: 'external-link-square-alt'
      },
      {
        c: 'fa-caret-square-down',
        l: 'caret-square-down'
      },
      {
        c: 'fa-caret-square-up',
        l: 'caret-square-up'
      },
      {
        c: 'fa-caret-square-right',
        l: 'caret-square-right'
      },
      {
        c: 'fa-euro-sign',
        l: 'euro-sign'
      },
      {
        c: 'fa-pound-sign',
        l: 'pound-sign'
      },
      {
        c: 'fa-dollar-sign',
        l: 'dollar-sign'
      },
      {
        c: 'fa-rupee-sign',
        l: 'rupee-sign'
      },
      {
        c: 'fa-yen-sign',
        l: 'yen-sign'
      },
      {
        c: 'fa-ruble-sign',
        l: 'ruble-sign'
      },
      {
        c: 'fa-won-sign',
        l: 'won-sign'
      },
      {
        c: 'fa-sort-alpha-down',
        l: 'sort-alpha-down'
      },
      {
        c: 'fa-sort-alpha-down-alt',
        l: 'sort-alpha-down-alt'
      },
      {
        c: 'fa-sort-amount-down',
        l: 'sort-amount-down'
      },
      {
        c: 'fa-sort-amount-down-alt',
        l: 'sort-amount-down-alt'
      },
      {
        c: 'fa-sort-numeric-down',
        l: 'sort-numeric-down'
      },
      {
        c: 'fa-sort-numeric-down-alt',
        l: 'sort-numeric-down-alt'
      },
      {
        c: 'fa-long-arrow-alt-down',
        l: 'long-arrow-alt-down'
      },
      {
        c: 'fa-long-arrow-alt-up',
        l: 'long-arrow-alt-up'
      },
      {
        c: 'fa-long-arrow-alt-left',
        l: 'long-arrow-alt-left'
      },
      {
        c: 'fa-long-arrow-alt-right',
        l: 'long-arrow-alt-right'
      },
      {
        c: 'fa-sun',
        l: 'sun'
      },
      {
        c: 'fa-moon',
        l: 'moon'
      },
      {
        c: 'fa-arrow-alt-circle-right',
        l: 'arrow-alt-circle-right'
      },
      {
        c: 'fa-arrow-alt-circle-left',
        l: 'arrow-alt-circle-left'
      },
      {
        c: 'fa-caret-square-left',
        l: 'caret-square-left'
      },
      {
        c: 'fa-dot-circle',
        l: 'dot-circle'
      },
      {
        c: 'fa-lira-sign',
        l: 'lira-sign'
      },
      {
        c: 'fa-utensil-spoon',
        l: 'utensil-spoon'
      },
      {
        c: 'fa-file-pdf',
        l: 'file-pdf'
      },
      {
        c: 'fa-file-word',
        l: 'file-word'
      },
      {
        c: 'fa-file-excel',
        l: 'file-excel'
      },
      {
        c: 'fa-file-powerpoint',
        l: 'file-powerpoint'
      },
      {
        c: 'fa-file-image',
        l: 'file-image'
      },
      {
        c: 'fa-file-archive',
        l: 'file-archive'
      },
      {
        c: 'fa-file-audio',
        l: 'file-audio'
      },
      {
        c: 'fa-file-video',
        l: 'file-video'
      },
      {
        c: 'fa-file-code',
        l: 'file-code'
      },
      {
        c: 'fa-circle-notch',
        l: 'circle-notch'
      },
      {
        c: 'fa-heading',
        l: 'heading'
      },
      {
        c: 'fa-sliders-h',
        l: 'sliders-h'
      },
      {
        c: 'fa-futbol',
        l: 'futbol'
      },
      {
        c: 'fa-newspaper',
        l: 'newspaper'
      },
      {
        c: 'fa-eye-dropper',
        l: 'eye-dropper'
      },
      {
        c: 'fa-chart-area',
        l: 'chart-area'
      },
      {
        c: 'fa-chart-pie',
        l: 'chart-pie'
      },
      {
        c: 'fa-chart-line',
        l: 'chart-line'
      },
      {
        c: 'fa-closed-captioning',
        l: 'closed-captioning'
      },
      {
        c: 'fa-shekel-sign',
        l: 'shekel-sign'
      },
      {
        c: 'fa-gem',
        l: 'gem'
      },
      {
        c: 'fa-hand-rock',
        l: 'hand-rock'
      },
      {
        c: 'fa-hand-paper',
        l: 'hand-paper'
      },
      {
        c: 'fa-hand-scissors',
        l: 'hand-scissors'
      },
      {
        c: 'fa-hand-lizard',
        l: 'hand-lizard'
      },
      {
        c: 'fa-hand-spock',
        l: 'hand-spock'
      },
      {
        c: 'fa-hand-pointer',
        l: 'hand-pointer'
      },
      {
        c: 'fa-hand-peace',
        l: 'hand-peace'
      },
      {
        c: 'fa-calendar-plus',
        l: 'calendar-plus'
      },
      {
        c: 'fa-calendar-minus',
        l: 'calendar-minus'
      },
      {
        c: 'fa-calendar-times',
        l: 'calendar-times'
      },
      {
        c: 'fa-calendar-check',
        l: 'calendar-check'
      },
      {
        c: 'fa-comment-dots',
        l: 'comment-dots'
      },
      {
        c: 'fa-phone-volume',
        l: 'phone-volume'
      },
      {
        c: 'fa-handshake',
        l: 'handshake'
      },
      {
        c: 'fa-snowflake',
        l: 'snowflake'
      }
    ]
  },
  {
    section: 'web-application',
    icons: [
      {
        c: 'fa-address-book',
        l: 'address-book'
      },
      {
        c: 'fa-address-book-o',
        l: 'address-book-o'
      },
      {
        c: 'fa-address-card',
        l: 'address-card'
      },
      {
        c: 'fa-address-card-o',
        l: 'address-card-o'
      },
      {
        c: 'fa-adjust',
        l: 'adjust'
      },
      {
        c: 'fa-american-sign-language-interpreting',
        l: 'american-sign-language-interpreting'
      },
      {
        c: 'fa-anchor',
        l: 'anchor'
      },
      {
        c: 'fa-archive',
        l: 'archive'
      },
      {
        c: 'fa-area-chart',
        l: 'area-chart'
      },
      {
        c: 'fa-arrows',
        l: 'arrows'
      },
      {
        c: 'fa-arrows-h',
        l: 'arrows-h'
      },
      {
        c: 'fa-arrows-v',
        l: 'arrows-v'
      },
      {
        c: 'fa-asl-interpreting',
        l: 'asl-interpreting (alias)'
      },
      {
        c: 'fa-assistive-listening-systems',
        l: 'assistive-listening-systems'
      },
      {
        c: 'fa-asterisk',
        l: 'asterisk'
      },
      {
        c: 'fa-at',
        l: 'at'
      },
      {
        c: 'fa-audio-description',
        l: 'audio-description'
      },
      {
        c: 'fa-automobile',
        l: 'automobile (alias)'
      },
      {
        c: 'fa-balance-scale',
        l: 'balance-scale'
      },
      {
        c: 'fa-ban',
        l: 'ban'
      },
      {
        c: 'fa-bank',
        l: 'bank (alias)'
      },
      {
        c: 'fa-bar-chart',
        l: 'bar-chart'
      },
      {
        c: 'fa-bar-chart-o',
        l: 'bar-chart-o (alias)'
      },
      {
        c: 'fa-barcode',
        l: 'barcode'
      },
      {
        c: 'fa-bars',
        l: 'bars'
      },
      {
        c: 'fa-bath',
        l: 'bath'
      },
      {
        c: 'fa-bathtub',
        l: 'bathtub (alias)'
      },
      {
        c: 'fa-battery',
        l: 'battery (alias)'
      },
      {
        c: 'fa-battery-0',
        l: 'battery-0 (alias)'
      },
      {
        c: 'fa-battery-1',
        l: 'battery-1 (alias)'
      },
      {
        c: 'fa-battery-2',
        l: 'battery-2 (alias)'
      },
      {
        c: 'fa-battery-3',
        l: 'battery-3 (alias)'
      },
      {
        c: 'fa-battery-4',
        l: 'battery-4 (alias)'
      },
      {
        c: 'fa-battery-empty',
        l: 'battery-empty'
      },
      {
        c: 'fa-battery-full',
        l: 'battery-full'
      },
      {
        c: 'fa-battery-half',
        l: 'battery-half'
      },
      {
        c: 'fa-battery-quarter',
        l: 'battery-quarter'
      },
      {
        c: 'fa-battery-three-quarters',
        l: 'battery-three-quarters'
      },
      {
        c: 'fa-bed',
        l: 'bed'
      },
      {
        c: 'fa-beer',
        l: 'beer'
      },
      {
        c: 'fa-bell',
        l: 'bell'
      },
      {
        c: 'fa-bell-o',
        l: 'bell-o'
      },
      {
        c: 'fa-bell-slash',
        l: 'bell-slash'
      },
      {
        c: 'fa-bell-slash-o',
        l: 'bell-slash-o'
      },
      {
        c: 'fa-bicycle',
        l: 'bicycle'
      },
      {
        c: 'fa-binoculars',
        l: 'binoculars'
      },
      {
        c: 'fa-birthday-cake',
        l: 'birthday-cake'
      },
      {
        c: 'fa-blind',
        l: 'blind'
      },
      {
        c: 'fa-bluetooth',
        l: 'bluetooth'
      },
      {
        c: 'fa-bluetooth-b',
        l: 'bluetooth-b'
      },
      {
        c: 'fa-bolt',
        l: 'bolt'
      },
      {
        c: 'fa-bomb',
        l: 'bomb'
      },
      {
        c: 'fa-book',
        l: 'book'
      },
      {
        c: 'fa-bookmark',
        l: 'bookmark'
      },
      {
        c: 'fa-bookmark-o',
        l: 'bookmark-o'
      },
      {
        c: 'fa-braille',
        l: 'braille'
      },
      {
        c: 'fa-briefcase',
        l: 'briefcase'
      },
      {
        c: 'fa-bug',
        l: 'bug'
      },
      {
        c: 'fa-building',
        l: 'building'
      },
      {
        c: 'fa-building-o',
        l: 'building-o'
      },
      {
        c: 'fa-bullhorn',
        l: 'bullhorn'
      },
      {
        c: 'fa-bullseye',
        l: 'bullseye'
      },
      {
        c: 'fa-bus',
        l: 'bus'
      },
      {
        c: 'fa-cab',
        l: 'cab (alias)'
      },
      {
        c: 'fa-calculator',
        l: 'calculator'
      },
      {
        c: 'fa-calendar',
        l: 'calendar'
      },
      {
        c: 'fa-calendar-check-o',
        l: 'calendar-check-o'
      },
      {
        c: 'fa-calendar-minus-o',
        l: 'calendar-minus-o'
      },
      {
        c: 'fa-calendar-o',
        l: 'calendar-o'
      },
      {
        c: 'fa-calendar-plus-o',
        l: 'calendar-plus-o'
      },
      {
        c: 'fa-calendar-times-o',
        l: 'calendar-times-o'
      },
      {
        c: 'fa-camera',
        l: 'camera'
      },
      {
        c: 'fa-camera-retro',
        l: 'camera-retro'
      },
      {
        c: 'fa-car',
        l: 'car'
      },
      {
        c: 'fa-caret-square-o-down',
        l: 'caret-square-o-down'
      },
      {
        c: 'fa-caret-square-o-left',
        l: 'caret-square-o-left'
      },
      {
        c: 'fa-caret-square-o-right',
        l: 'caret-square-o-right'
      },
      {
        c: 'fa-caret-square-o-up',
        l: 'caret-square-o-up'
      },
      {
        c: 'fa-cart-arrow-down',
        l: 'cart-arrow-down'
      },
      {
        c: 'fa-cart-plus',
        l: 'cart-plus'
      },
      {
        c: 'fa-cc',
        l: 'cc'
      },
      {
        c: 'fa-certificate',
        l: 'certificate'
      },
      {
        c: 'fa-check',
        l: 'check'
      },
      {
        c: 'fa-check-circle',
        l: 'check-circle'
      },
      {
        c: 'fa-check-circle-o',
        l: 'check-circle-o'
      },
      {
        c: 'fa-check-square',
        l: 'check-square'
      },
      {
        c: 'fa-check-square-o',
        l: 'check-square-o'
      },
      {
        c: 'fa-child',
        l: 'child'
      },
      {
        c: 'fa-circle',
        l: 'circle'
      },
      {
        c: 'fa-circle-o',
        l: 'circle-o'
      },
      {
        c: 'fa-circle-o-notch',
        l: 'circle-o-notch'
      },
      {
        c: 'fa-circle-thin',
        l: 'circle-thin'
      },
      {
        c: 'fa-clock-o',
        l: 'clock-o'
      },
      {
        c: 'fa-clone',
        l: 'clone'
      },
      {
        c: 'fa-close',
        l: 'close (alias)'
      },
      {
        c: 'fa-cloud',
        l: 'cloud'
      },
      {
        c: 'fa-cloud-download',
        l: 'cloud-download'
      },
      {
        c: 'fa-cloud-upload',
        l: 'cloud-upload'
      },
      {
        c: 'fa-code',
        l: 'code'
      },
      {
        c: 'fa-code-fork',
        l: 'code-fork'
      },
      {
        c: 'fa-coffee',
        l: 'coffee'
      },
      {
        c: 'fa-cog',
        l: 'cog'
      },
      {
        c: 'fa-cogs',
        l: 'cogs'
      },
      {
        c: 'fa-comment',
        l: 'comment'
      },
      {
        c: 'fa-comment-o',
        l: 'comment-o'
      },
      {
        c: 'fa-commenting',
        l: 'commenting'
      },
      {
        c: 'fa-commenting-o',
        l: 'commenting-o'
      },
      {
        c: 'fa-comments',
        l: 'comments'
      },
      {
        c: 'fa-comments-o',
        l: 'comments-o'
      },
      {
        c: 'fa-compass',
        l: 'compass'
      },
      {
        c: 'fa-copyright',
        l: 'copyright'
      },
      {
        c: 'fa-creative-commons',
        l: 'creative-commons'
      },
      {
        c: 'fa-credit-card',
        l: 'credit-card'
      },
      {
        c: 'fa-credit-card-alt',
        l: 'credit-card-alt'
      },
      {
        c: 'fa-crop',
        l: 'crop'
      },
      {
        c: 'fa-crosshairs',
        l: 'crosshairs'
      },
      {
        c: 'fa-cube',
        l: 'cube'
      },
      {
        c: 'fa-cubes',
        l: 'cubes'
      },
      {
        c: 'fa-cutlery',
        l: 'cutlery'
      },
      {
        c: 'fa-dashboard',
        l: 'dashboard (alias)'
      },
      {
        c: 'fa-database',
        l: 'database'
      },
      {
        c: 'fa-deaf',
        l: 'deaf'
      },
      {
        c: 'fa-deafness',
        l: 'deafness (alias)'
      },
      {
        c: 'fa-desktop',
        l: 'desktop'
      },
      {
        c: 'fa-diamond',
        l: 'diamond'
      },
      {
        c: 'fa-dot-circle-o',
        l: 'dot-circle-o'
      },
      {
        c: 'fa-download',
        l: 'download'
      },
      {
        c: 'fa-drivers-license',
        l: 'drivers-license (alias)'
      },
      {
        c: 'fa-drivers-license-o',
        l: 'drivers-license-o (alias)'
      },
      {
        c: 'fa-edit',
        l: 'edit (alias)'
      },
      {
        c: 'fa-ellipsis-h',
        l: 'ellipsis-h'
      },
      {
        c: 'fa-ellipsis-v',
        l: 'ellipsis-v'
      },
      {
        c: 'fa-envelope',
        l: 'envelope'
      },
      {
        c: 'fa-envelope-o',
        l: 'envelope-o'
      },
      {
        c: 'fa-envelope-open',
        l: 'envelope-open'
      },
      {
        c: 'fa-envelope-open-o',
        l: 'envelope-open-o'
      },
      {
        c: 'fa-envelope-square',
        l: 'envelope-square'
      },
      {
        c: 'fa-eraser',
        l: 'eraser'
      },
      {
        c: 'fa-exchange',
        l: 'exchange'
      },
      {
        c: 'fa-exclamation',
        l: 'exclamation'
      },
      {
        c: 'fa-exclamation-circle',
        l: 'exclamation-circle'
      },
      {
        c: 'fa-exclamation-triangle',
        l: 'exclamation-triangle'
      },
      {
        c: 'fa-external-link',
        l: 'external-link'
      },
      {
        c: 'fa-external-link-square',
        l: 'external-link-square'
      },
      {
        c: 'fa-eye',
        l: 'eye'
      },
      {
        c: 'fa-eye-slash',
        l: 'eye-slash'
      },
      {
        c: 'fa-eyedropper',
        l: 'eyedropper'
      },
      {
        c: 'fa-fax',
        l: 'fax'
      },
      {
        c: 'fa-feed',
        l: 'feed (alias)'
      },
      {
        c: 'fa-female',
        l: 'female'
      },
      {
        c: 'fa-fighter-jet',
        l: 'fighter-jet'
      },
      {
        c: 'fa-file-archive-o',
        l: 'file-archive-o'
      },
      {
        c: 'fa-file-audio-o',
        l: 'file-audio-o'
      },
      {
        c: 'fa-file-code-o',
        l: 'file-code-o'
      },
      {
        c: 'fa-file-excel-o',
        l: 'file-excel-o'
      },
      {
        c: 'fa-file-image-o',
        l: 'file-image-o'
      },
      {
        c: 'fa-file-movie-o',
        l: 'file-movie-o (alias)'
      },
      {
        c: 'fa-file-pdf-o',
        l: 'file-pdf-o'
      },
      {
        c: 'fa-file-photo-o',
        l: 'file-photo-o (alias)'
      },
      {
        c: 'fa-file-picture-o',
        l: 'file-picture-o (alias)'
      },
      {
        c: 'fa-file-powerpoint-o',
        l: 'file-powerpoint-o'
      },
      {
        c: 'fa-file-sound-o',
        l: 'file-sound-o (alias)'
      },
      {
        c: 'fa-file-video-o',
        l: 'file-video-o'
      },
      {
        c: 'fa-file-word-o',
        l: 'file-word-o'
      },
      {
        c: 'fa-file-zip-o',
        l: 'file-zip-o (alias)'
      },
      {
        c: 'fa-film',
        l: 'film'
      },
      {
        c: 'fa-filter',
        l: 'filter'
      },
      {
        c: 'fa-fire',
        l: 'fire'
      },
      {
        c: 'fa-fire-extinguisher',
        l: 'fire-extinguisher'
      },
      {
        c: 'fa-flag',
        l: 'flag'
      },
      {
        c: 'fa-flag-checkered',
        l: 'flag-checkered'
      },
      {
        c: 'fa-flag-o',
        l: 'flag-o'
      },
      {
        c: 'fa-flash',
        l: 'flash (alias)'
      },
      {
        c: 'fa-flask',
        l: 'flask'
      },
      {
        c: 'fa-folder',
        l: 'folder'
      },
      {
        c: 'fa-folder-o',
        l: 'folder-o'
      },
      {
        c: 'fa-folder-open',
        l: 'folder-open'
      },
      {
        c: 'fa-folder-open-o',
        l: 'folder-open-o'
      },
      {
        c: 'fa-frown-o',
        l: 'frown-o'
      },
      {
        c: 'fa-futbol-o',
        l: 'futbol-o'
      },
      {
        c: 'fa-gamepad',
        l: 'gamepad'
      },
      {
        c: 'fa-gavel',
        l: 'gavel'
      },
      {
        c: 'fa-gear',
        l: 'gear (alias)'
      },
      {
        c: 'fa-gears',
        l: 'gears (alias)'
      },
      {
        c: 'fa-gift',
        l: 'gift'
      },
      {
        c: 'fa-glass',
        l: 'glass'
      },
      {
        c: 'fa-globe',
        l: 'globe'
      },
      {
        c: 'fa-graduation-cap',
        l: 'graduation-cap'
      },
      {
        c: 'fa-group',
        l: 'group (alias)'
      },
      {
        c: 'fa-hand-grab-o',
        l: 'hand-grab-o (alias)'
      },
      {
        c: 'fa-hand-lizard-o',
        l: 'hand-lizard-o'
      },
      {
        c: 'fa-hand-paper-o',
        l: 'hand-paper-o'
      },
      {
        c: 'fa-hand-peace-o',
        l: 'hand-peace-o'
      },
      {
        c: 'fa-hand-pointer-o',
        l: 'hand-pointer-o'
      },
      {
        c: 'fa-hand-rock-o',
        l: 'hand-rock-o'
      },
      {
        c: 'fa-hand-scissors-o',
        l: 'hand-scissors-o'
      },
      {
        c: 'fa-hand-spock-o',
        l: 'hand-spock-o'
      },
      {
        c: 'fa-hand-stop-o',
        l: 'hand-stop-o (alias)'
      },
      {
        c: 'fa-handshake-o',
        l: 'handshake-o'
      },
      {
        c: 'fa-hard-of-hearing',
        l: 'hard-of-hearing (alias)'
      },
      {
        c: 'fa-hashtag',
        l: 'hashtag'
      },
      {
        c: 'fa-hdd-o',
        l: 'hdd-o'
      },
      {
        c: 'fa-headphones',
        l: 'headphones'
      },
      {
        c: 'fa-heart',
        l: 'heart'
      },
      {
        c: 'fa-heart-o',
        l: 'heart-o'
      },
      {
        c: 'fa-heartbeat',
        l: 'heartbeat'
      },
      {
        c: 'fa-history',
        l: 'history'
      },
      {
        c: 'fa-home',
        l: 'home'
      },
      {
        c: 'fa-hotel',
        l: 'hotel (alias)'
      },
      {
        c: 'fa-hourglass',
        l: 'hourglass'
      },
      {
        c: 'fa-hourglass-1',
        l: 'hourglass-1 (alias)'
      },
      {
        c: 'fa-hourglass-2',
        l: 'hourglass-2 (alias)'
      },
      {
        c: 'fa-hourglass-3',
        l: 'hourglass-3 (alias)'
      },
      {
        c: 'fa-hourglass-end',
        l: 'hourglass-end'
      },
      {
        c: 'fa-hourglass-half',
        l: 'hourglass-half'
      },
      {
        c: 'fa-hourglass-o',
        l: 'hourglass-o'
      },
      {
        c: 'fa-hourglass-start',
        l: 'hourglass-start'
      },
      {
        c: 'fa-i-cursor',
        l: 'i-cursor'
      },
      {
        c: 'fa-id-badge',
        l: 'id-badge'
      },
      {
        c: 'fa-id-card',
        l: 'id-card'
      },
      {
        c: 'fa-id-card-o',
        l: 'id-card-o'
      },
      {
        c: 'fa-image',
        l: 'image (alias)'
      },
      {
        c: 'fa-inbox',
        l: 'inbox'
      },
      {
        c: 'fa-industry',
        l: 'industry'
      },
      {
        c: 'fa-info',
        l: 'info'
      },
      {
        c: 'fa-info-circle',
        l: 'info-circle'
      },
      {
        c: 'fa-institution',
        l: 'institution (alias)'
      },
      {
        c: 'fa-key',
        l: 'key'
      },
      {
        c: 'fa-keyboard-o',
        l: 'keyboard-o'
      },
      {
        c: 'fa-language',
        l: 'language'
      },
      {
        c: 'fa-laptop',
        l: 'laptop'
      },
      {
        c: 'fa-leaf',
        l: 'leaf'
      },
      {
        c: 'fa-legal',
        l: 'legal (alias)'
      },
      {
        c: 'fa-lemon-o',
        l: 'lemon-o'
      },
      {
        c: 'fa-level-down',
        l: 'level-down'
      },
      {
        c: 'fa-level-up',
        l: 'level-up'
      },
      {
        c: 'fa-life-bouy',
        l: 'life-bouy (alias)'
      },
      {
        c: 'fa-life-buoy',
        l: 'life-buoy (alias)'
      },
      {
        c: 'fa-life-ring',
        l: 'life-ring'
      },
      {
        c: 'fa-life-saver',
        l: 'life-saver (alias)'
      },
      {
        c: 'fa-lightbulb-o',
        l: 'lightbulb-o'
      },
      {
        c: 'fa-line-chart',
        l: 'line-chart'
      },
      {
        c: 'fa-location-arrow',
        l: 'location-arrow'
      },
      {
        c: 'fa-lock',
        l: 'lock'
      },
      {
        c: 'fa-low-vision',
        l: 'low-vision'
      },
      {
        c: 'fa-magic',
        l: 'magic'
      },
      {
        c: 'fa-magnet',
        l: 'magnet'
      },
      {
        c: 'fa-mail-forward',
        l: 'mail-forward (alias)'
      },
      {
        c: 'fa-mail-reply',
        l: 'mail-reply (alias)'
      },
      {
        c: 'fa-mail-reply-all',
        l: 'mail-reply-all (alias)'
      },
      {
        c: 'fa-male',
        l: 'male'
      },
      {
        c: 'fa-map',
        l: 'map'
      },
      {
        c: 'fa-map-marker',
        l: 'map-marker'
      },
      {
        c: 'fa-map-o',
        l: 'map-o'
      },
      {
        c: 'fa-map-pin',
        l: 'map-pin'
      },
      {
        c: 'fa-map-signs',
        l: 'map-signs'
      },
      {
        c: 'fa-meh-o',
        l: 'meh-o'
      },
      {
        c: 'fa-microchip',
        l: 'microchip'
      },
      {
        c: 'fa-microphone',
        l: 'microphone'
      },
      {
        c: 'fa-microphone-slash',
        l: 'microphone-slash'
      },
      {
        c: 'fa-minus',
        l: 'minus'
      },
      {
        c: 'fa-minus-circle',
        l: 'minus-circle'
      },
      {
        c: 'fa-minus-square',
        l: 'minus-square'
      },
      {
        c: 'fa-minus-square-o',
        l: 'minus-square-o'
      },
      {
        c: 'fa-mobile',
        l: 'mobile'
      },
      {
        c: 'fa-mobile-phone',
        l: 'mobile-phone (alias)'
      },
      {
        c: 'fa-money',
        l: 'money'
      },
      {
        c: 'fa-moon-o',
        l: 'moon-o'
      },
      {
        c: 'fa-mortar-board',
        l: 'mortar-board (alias)'
      },
      {
        c: 'fa-motorcycle',
        l: 'motorcycle'
      },
      {
        c: 'fa-mouse-pointer',
        l: 'mouse-pointer'
      },
      {
        c: 'fa-music',
        l: 'music'
      },
      {
        c: 'fa-navicon',
        l: 'navicon (alias)'
      },
      {
        c: 'fa-newspaper-o',
        l: 'newspaper-o'
      },
      {
        c: 'fa-object-group',
        l: 'object-group'
      },
      {
        c: 'fa-object-ungroup',
        l: 'object-ungroup'
      },
      {
        c: 'fa-paint-brush',
        l: 'paint-brush'
      },
      {
        c: 'fa-paper-plane',
        l: 'paper-plane'
      },
      {
        c: 'fa-paper-plane-o',
        l: 'paper-plane-o'
      },
      {
        c: 'fa-paw',
        l: 'paw'
      },
      {
        c: 'fa-pencil',
        l: 'pencil'
      },
      {
        c: 'fa-pencil-square',
        l: 'pencil-square'
      },
      {
        c: 'fa-pencil-square-o',
        l: 'pencil-square-o'
      },
      {
        c: 'fa-percent',
        l: 'percent'
      },
      {
        c: 'fa-phone',
        l: 'phone'
      },
      {
        c: 'fa-phone-square',
        l: 'phone-square'
      },
      {
        c: 'fa-photo',
        l: 'photo (alias)'
      },
      {
        c: 'fa-picture-o',
        l: 'picture-o'
      },
      {
        c: 'fa-pie-chart',
        l: 'pie-chart'
      },
      {
        c: 'fa-plane',
        l: 'plane'
      },
      {
        c: 'fa-plug',
        l: 'plug'
      },
      {
        c: 'fa-plus',
        l: 'plus'
      },
      {
        c: 'fa-plus-circle',
        l: 'plus-circle'
      },
      {
        c: 'fa-plus-square',
        l: 'plus-square'
      },
      {
        c: 'fa-plus-square-o',
        l: 'plus-square-o'
      },
      {
        c: 'fa-podcast',
        l: 'podcast'
      },
      {
        c: 'fa-power-off',
        l: 'power-off'
      },
      {
        c: 'fa-print',
        l: 'print'
      },
      {
        c: 'fa-puzzle-piece',
        l: 'puzzle-piece'
      },
      {
        c: 'fa-qrcode',
        l: 'qrcode'
      },
      {
        c: 'fa-question',
        l: 'question'
      },
      {
        c: 'fa-question-circle',
        l: 'question-circle'
      },
      {
        c: 'fa-question-circle-o',
        l: 'question-circle-o'
      },
      {
        c: 'fa-quote-left',
        l: 'quote-left'
      },
      {
        c: 'fa-quote-right',
        l: 'quote-right'
      },
      {
        c: 'fa-random',
        l: 'random'
      },
      {
        c: 'fa-recycle',
        l: 'recycle'
      },
      {
        c: 'fa-refresh',
        l: 'refresh'
      },
      {
        c: 'fa-registered',
        l: 'registered'
      },
      {
        c: 'fa-remove',
        l: 'remove (alias)'
      },
      {
        c: 'fa-reorder',
        l: 'reorder (alias)'
      },
      {
        c: 'fa-reply',
        l: 'reply'
      },
      {
        c: 'fa-reply-all',
        l: 'reply-all'
      },
      {
        c: 'fa-retweet',
        l: 'retweet'
      },
      {
        c: 'fa-road',
        l: 'road'
      },
      {
        c: 'fa-rocket',
        l: 'rocket'
      },
      {
        c: 'fa-rss',
        l: 'rss'
      },
      {
        c: 'fa-rss-square',
        l: 'rss-square'
      },
      {
        c: 'fa-s15',
        l: 's15 (alias)'
      },
      {
        c: 'fa-search',
        l: 'search'
      },
      {
        c: 'fa-search-minus',
        l: 'search-minus'
      },
      {
        c: 'fa-search-plus',
        l: 'search-plus'
      },
      {
        c: 'fa-send',
        l: 'send (alias)'
      },
      {
        c: 'fa-send-o',
        l: 'send-o (alias)'
      },
      {
        c: 'fa-server',
        l: 'server'
      },
      {
        c: 'fa-share',
        l: 'share'
      },
      {
        c: 'fa-share-alt',
        l: 'share-alt'
      },
      {
        c: 'fa-share-alt-square',
        l: 'share-alt-square'
      },
      {
        c: 'fa-share-square',
        l: 'share-square'
      },
      {
        c: 'fa-share-square-o',
        l: 'share-square-o'
      },
      {
        c: 'fa-shield',
        l: 'shield'
      },
      {
        c: 'fa-ship',
        l: 'ship'
      },
      {
        c: 'fa-shopping-bag',
        l: 'shopping-bag'
      },
      {
        c: 'fa-shopping-basket',
        l: 'shopping-basket'
      },
      {
        c: 'fa-shopping-cart',
        l: 'shopping-cart'
      },
      {
        c: 'fa-shower',
        l: 'shower'
      },
      {
        c: 'fa-sign-in',
        l: 'sign-in'
      },
      {
        c: 'fa-sign-language',
        l: 'sign-language'
      },
      {
        c: 'fa-sign-out',
        l: 'sign-out'
      },
      {
        c: 'fa-signal',
        l: 'signal'
      },
      {
        c: 'fa-signing',
        l: 'signing (alias)'
      },
      {
        c: 'fa-sitemap',
        l: 'sitemap'
      },
      {
        c: 'fa-sliders',
        l: 'sliders'
      },
      {
        c: 'fa-smile-o',
        l: 'smile-o'
      },
      {
        c: 'fa-snowflake-o',
        l: 'snowflake-o'
      },
      {
        c: 'fa-soccer-ball-o',
        l: 'soccer-ball-o (alias)'
      },
      {
        c: 'fa-sort',
        l: 'sort'
      },
      {
        c: 'fa-sort-alpha-asc',
        l: 'sort-alpha-asc'
      },
      {
        c: 'fa-sort-alpha-desc',
        l: 'sort-alpha-desc'
      },
      {
        c: 'fa-sort-amount-asc',
        l: 'sort-amount-asc'
      },
      {
        c: 'fa-sort-amount-desc',
        l: 'sort-amount-desc'
      },
      {
        c: 'fa-sort-asc',
        l: 'sort-asc'
      },
      {
        c: 'fa-sort-desc',
        l: 'sort-desc'
      },
      {
        c: 'fa-sort-down',
        l: 'sort-down (alias)'
      },
      {
        c: 'fa-sort-numeric-asc',
        l: 'sort-numeric-asc'
      },
      {
        c: 'fa-sort-numeric-desc',
        l: 'sort-numeric-desc'
      },
      {
        c: 'fa-sort-up',
        l: 'sort-up (alias)'
      },
      {
        c: 'fa-space-shuttle',
        l: 'space-shuttle'
      },
      {
        c: 'fa-spinner',
        l: 'spinner'
      },
      {
        c: 'fa-spoon',
        l: 'spoon'
      },
      {
        c: 'fa-square',
        l: 'square'
      },
      {
        c: 'fa-square-o',
        l: 'square-o'
      },
      {
        c: 'fa-star',
        l: 'star'
      },
      {
        c: 'fa-star-half',
        l: 'star-half'
      },
      {
        c: 'fa-star-half-empty',
        l: 'star-half-empty (alias)'
      },
      {
        c: 'fa-star-half-full',
        l: 'star-half-full (alias)'
      },
      {
        c: 'fa-star-half-o',
        l: 'star-half-o'
      },
      {
        c: 'fa-star-o',
        l: 'star-o'
      },
      {
        c: 'fa-sticky-note',
        l: 'sticky-note'
      },
      {
        c: 'fa-sticky-note-o',
        l: 'sticky-note-o'
      },
      {
        c: 'fa-street-view',
        l: 'street-view'
      },
      {
        c: 'fa-suitcase',
        l: 'suitcase'
      },
      {
        c: 'fa-sun-o',
        l: 'sun-o'
      },
      {
        c: 'fa-support',
        l: 'support (alias)'
      },
      {
        c: 'fa-tablet',
        l: 'tablet'
      },
      {
        c: 'fa-tachometer',
        l: 'tachometer'
      },
      {
        c: 'fa-tag',
        l: 'tag'
      },
      {
        c: 'fa-tags',
        l: 'tags'
      },
      {
        c: 'fa-tasks',
        l: 'tasks'
      },
      {
        c: 'fa-taxi',
        l: 'taxi'
      },
      {
        c: 'fa-television',
        l: 'television'
      },
      {
        c: 'fa-terminal',
        l: 'terminal'
      },
      {
        c: 'fa-thermometer',
        l: 'thermometer (alias)'
      },
      {
        c: 'fa-thermometer-0',
        l: 'thermometer-0 (alias)'
      },
      {
        c: 'fa-thermometer-1',
        l: 'thermometer-1 (alias)'
      },
      {
        c: 'fa-thermometer-2',
        l: 'thermometer-2 (alias)'
      },
      {
        c: 'fa-thermometer-3',
        l: 'thermometer-3 (alias)'
      },
      {
        c: 'fa-thermometer-4',
        l: 'thermometer-4 (alias)'
      },
      {
        c: 'fa-thermometer-empty',
        l: 'thermometer-empty'
      },
      {
        c: 'fa-thermometer-full',
        l: 'thermometer-full'
      },
      {
        c: 'fa-thermometer-half',
        l: 'thermometer-half'
      },
      {
        c: 'fa-thermometer-quarter',
        l: 'thermometer-quarter'
      },
      {
        c: 'fa-thermometer-three-quarters',
        l: 'thermometer-three-quarters'
      },
      {
        c: 'fa-thumb-tack',
        l: 'thumb-tack'
      },
      {
        c: 'fa-thumbs-down',
        l: 'thumbs-down'
      },
      {
        c: 'fa-thumbs-o-down',
        l: 'thumbs-o-down'
      },
      {
        c: 'fa-thumbs-o-up',
        l: 'thumbs-o-up'
      },
      {
        c: 'fa-thumbs-up',
        l: 'thumbs-up'
      },
      {
        c: 'fa-ticket',
        l: 'ticket'
      },
      {
        c: 'fa-times',
        l: 'times'
      },
      {
        c: 'fa-times-circle',
        l: 'times-circle'
      },
      {
        c: 'fa-times-circle-o',
        l: 'times-circle-o'
      },
      {
        c: 'fa-times-rectangle',
        l: 'times-rectangle (alias)'
      },
      {
        c: 'fa-times-rectangle-o',
        l: 'times-rectangle-o (alias)'
      },
      {
        c: 'fa-tint',
        l: 'tint'
      },
      {
        c: 'fa-toggle-down',
        l: 'toggle-down (alias)'
      },
      {
        c: 'fa-toggle-left',
        l: 'toggle-left (alias)'
      },
      {
        c: 'fa-toggle-off',
        l: 'toggle-off'
      },
      {
        c: 'fa-toggle-on',
        l: 'toggle-on'
      },
      {
        c: 'fa-toggle-right',
        l: 'toggle-right (alias)'
      },
      {
        c: 'fa-toggle-up',
        l: 'toggle-up (alias)'
      },
      {
        c: 'fa-trademark',
        l: 'trademark'
      },
      {
        c: 'fa-trash',
        l: 'trash'
      },
      {
        c: 'fa-trash-o',
        l: 'trash-o'
      },
      {
        c: 'fa-tree',
        l: 'tree'
      },
      {
        c: 'fa-trophy',
        l: 'trophy'
      },
      {
        c: 'fa-truck',
        l: 'truck'
      },
      {
        c: 'fa-tty',
        l: 'tty'
      },
      {
        c: 'fa-tv',
        l: 'tv (alias)'
      },
      {
        c: 'fa-umbrella',
        l: 'umbrella'
      },
      {
        c: 'fa-universal-access',
        l: 'universal-access'
      },
      {
        c: 'fa-university',
        l: 'university'
      },
      {
        c: 'fa-unlock',
        l: 'unlock'
      },
      {
        c: 'fa-unlock-alt',
        l: 'unlock-alt'
      },
      {
        c: 'fa-unsorted',
        l: 'unsorted (alias)'
      },
      {
        c: 'fa-upload',
        l: 'upload'
      },
      {
        c: 'fa-user',
        l: 'user'
      },
      {
        c: 'fa-user-circle',
        l: 'user-circle'
      },
      {
        c: 'fa-user-circle-o',
        l: 'user-circle-o'
      },
      {
        c: 'fa-user-o',
        l: 'user-o'
      },
      {
        c: 'fa-user-plus',
        l: 'user-plus'
      },
      {
        c: 'fa-user-secret',
        l: 'user-secret'
      },
      {
        c: 'fa-user-times',
        l: 'user-times'
      },
      {
        c: 'fa-users',
        l: 'users'
      },
      {
        c: 'fa-vcard',
        l: 'vcard (alias)'
      },
      {
        c: 'fa-vcard-o',
        l: 'vcard-o (alias)'
      },
      {
        c: 'fa-video-camera',
        l: 'video-camera'
      },
      {
        c: 'fa-volume-control-phone',
        l: 'volume-control-phone'
      },
      {
        c: 'fa-volume-down',
        l: 'volume-down'
      },
      {
        c: 'fa-volume-off',
        l: 'volume-off'
      },
      {
        c: 'fa-volume-up',
        l: 'volume-up'
      },
      {
        c: 'fa-warning',
        l: 'warning (alias)'
      },
      {
        c: 'fa-wheelchair',
        l: 'wheelchair'
      },
      {
        c: 'fa-wheelchair-alt',
        l: 'wheelchair-alt'
      },
      {
        c: 'fa-wifi',
        l: 'wifi'
      },
      {
        c: 'fa-window-close',
        l: 'window-close'
      },
      {
        c: 'fa-window-close-o',
        l: 'window-close-o'
      },
      {
        c: 'fa-window-maximize',
        l: 'window-maximize'
      },
      {
        c: 'fa-window-minimize',
        l: 'window-minimize'
      },
      {
        c: 'fa-window-restore',
        l: 'window-restore'
      },
      {
        c: 'fa-wrench',
        l: 'wrench'
      }
    ]
  },
  {
    section: 'accessibility',
    icons: [
      {
        c: 'fa-american-sign-language-interpreting',
        l: 'american-sign-language-interpreting'
      },
      {
        c: 'fa-asl-interpreting',
        l: 'asl-interpreting (alias)'
      },
      {
        c: 'fa-assistive-listening-systems',
        l: 'assistive-listening-systems'
      },
      {
        c: 'fa-audio-description',
        l: 'audio-description'
      },
      {
        c: 'fa-blind',
        l: 'blind'
      },
      {
        c: 'fa-braille',
        l: 'braille'
      },
      {
        c: 'fa-cc',
        l: 'cc'
      },
      {
        c: 'fa-deaf',
        l: 'deaf'
      },
      {
        c: 'fa-deafness',
        l: 'deafness (alias)'
      },
      {
        c: 'fa-hard-of-hearing',
        l: 'hard-of-hearing (alias)'
      },
      {
        c: 'fa-low-vision',
        l: 'low-vision'
      },
      {
        c: 'fa-question-circle-o',
        l: 'question-circle-o'
      },
      {
        c: 'fa-sign-language',
        l: 'sign-language'
      },
      {
        c: 'fa-signing',
        l: 'signing (alias)'
      },
      {
        c: 'fa-tty',
        l: 'tty'
      },
      {
        c: 'fa-universal-access',
        l: 'universal-access'
      },
      {
        c: 'fa-volume-control-phone',
        l: 'volume-control-phone'
      },
      {
        c: 'fa-wheelchair',
        l: 'wheelchair'
      },
      {
        c: 'fa-wheelchair-alt',
        l: 'wheelchair-alt'
      }
    ]
  },
  {
    section: 'hand',
    icons: [
      {
        c: 'fa-hand-grab-o',
        l: 'hand-grab-o (alias)'
      },
      {
        c: 'fa-hand-lizard-o',
        l: 'hand-lizard-o'
      },
      {
        c: 'fa-hand-o-down',
        l: 'hand-o-down'
      },
      {
        c: 'fa-hand-o-left',
        l: 'hand-o-left'
      },
      {
        c: 'fa-hand-o-right',
        l: 'hand-o-right'
      },
      {
        c: 'fa-hand-o-up',
        l: 'hand-o-up'
      },
      {
        c: 'fa-hand-paper-o',
        l: 'hand-paper-o'
      },
      {
        c: 'fa-hand-peace-o',
        l: 'hand-peace-o'
      },
      {
        c: 'fa-hand-pointer-o',
        l: 'hand-pointer-o'
      },
      {
        c: 'fa-hand-rock-o',
        l: 'hand-rock-o'
      },
      {
        c: 'fa-hand-scissors-o',
        l: 'hand-scissors-o'
      },
      {
        c: 'fa-hand-spock-o',
        l: 'hand-spock-o'
      },
      {
        c: 'fa-hand-stop-o',
        l: 'hand-stop-o (alias)'
      },
      {
        c: 'fa-thumbs-down',
        l: 'thumbs-down'
      },
      {
        c: 'fa-thumbs-o-down',
        l: 'thumbs-o-down'
      },
      {
        c: 'fa-thumbs-o-up',
        l: 'thumbs-o-up'
      },
      {
        c: 'fa-thumbs-up',
        l: 'thumbs-up'
      }
    ]
  },
  {
    section: 'transportation',
    icons: [
      {
        c: 'fa-ambulance',
        l: 'ambulance'
      },
      {
        c: 'fa-automobile',
        l: 'automobile (alias)'
      },
      {
        c: 'fa-bicycle',
        l: 'bicycle'
      },
      {
        c: 'fa-bus',
        l: 'bus'
      },
      {
        c: 'fa-cab',
        l: 'cab (alias)'
      },
      {
        c: 'fa-car',
        l: 'car'
      },
      {
        c: 'fa-fighter-jet',
        l: 'fighter-jet'
      },
      {
        c: 'fa-motorcycle',
        l: 'motorcycle'
      },
      {
        c: 'fa-plane',
        l: 'plane'
      },
      {
        c: 'fa-rocket',
        l: 'rocket'
      },
      {
        c: 'fa-ship',
        l: 'ship'
      },
      {
        c: 'fa-space-shuttle',
        l: 'space-shuttle'
      },
      {
        c: 'fa-subway',
        l: 'subway'
      },
      {
        c: 'fa-taxi',
        l: 'taxi'
      },
      {
        c: 'fa-train',
        l: 'train'
      },
      {
        c: 'fa-truck',
        l: 'truck'
      },
      {
        c: 'fa-wheelchair',
        l: 'wheelchair'
      },
      {
        c: 'fa-wheelchair-alt',
        l: 'wheelchair-alt'
      }
    ]
  },
  {
    section: 'gender',
    icons: [
      {
        c: 'fa-genderless',
        l: 'genderless'
      },
      {
        c: 'fa-intersex',
        l: 'intersex (alias)'
      },
      {
        c: 'fa-mars',
        l: 'mars'
      },
      {
        c: 'fa-mars-double',
        l: 'mars-double'
      },
      {
        c: 'fa-mars-stroke',
        l: 'mars-stroke'
      },
      {
        c: 'fa-mars-stroke-h',
        l: 'mars-stroke-h'
      },
      {
        c: 'fa-mars-stroke-v',
        l: 'mars-stroke-v'
      },
      {
        c: 'fa-mercury',
        l: 'mercury'
      },
      {
        c: 'fa-neuter',
        l: 'neuter'
      },
      {
        c: 'fa-transgender',
        l: 'transgender'
      },
      {
        c: 'fa-transgender-alt',
        l: 'transgender-alt'
      },
      {
        c: 'fa-venus',
        l: 'venus'
      },
      {
        c: 'fa-venus-double',
        l: 'venus-double'
      },
      {
        c: 'fa-venus-mars',
        l: 'venus-mars'
      }
    ]
  },
  {
    section: 'file-type',
    icons: [
      {
        c: 'fa-file',
        l: 'file'
      },
      {
        c: 'fa-file-archive-o',
        l: 'file-archive-o'
      },
      {
        c: 'fa-file-audio-o',
        l: 'file-audio-o'
      },
      {
        c: 'fa-file-code-o',
        l: 'file-code-o'
      },
      {
        c: 'fa-file-excel-o',
        l: 'file-excel-o'
      },
      {
        c: 'fa-file-image-o',
        l: 'file-image-o'
      },
      {
        c: 'fa-file-movie-o',
        l: 'file-movie-o (alias)'
      },
      {
        c: 'fa-file-o',
        l: 'file-o'
      },
      {
        c: 'fa-file-pdf-o',
        l: 'file-pdf-o'
      },
      {
        c: 'fa-file-photo-o',
        l: 'file-photo-o (alias)'
      },
      {
        c: 'fa-file-picture-o',
        l: 'file-picture-o (alias)'
      },
      {
        c: 'fa-file-powerpoint-o',
        l: 'file-powerpoint-o'
      },
      {
        c: 'fa-file-sound-o',
        l: 'file-sound-o (alias)'
      },
      {
        c: 'fa-file-text',
        l: 'file-text'
      },
      {
        c: 'fa-file-text-o',
        l: 'file-text-o'
      },
      {
        c: 'fa-file-video-o',
        l: 'file-video-o'
      },
      {
        c: 'fa-file-word-o',
        l: 'file-word-o'
      },
      {
        c: 'fa-file-zip-o',
        l: 'file-zip-o (alias)'
      }
    ]
  },
  {
    section: 'spinner',
    icons: [
      {
        c: 'fa-circle-o-notch',
        l: 'circle-o-notch'
      },
      {
        c: 'fa-cog',
        l: 'cog'
      },
      {
        c: 'fa-gear',
        l: 'gear (alias)'
      },
      {
        c: 'fa-refresh',
        l: 'refresh'
      },
      {
        c: 'fa-spinner',
        l: 'spinner'
      }
    ]
  },
  {
    section: 'form-control',
    icons: [
      {
        c: 'fa-check-square',
        l: 'check-square'
      },
      {
        c: 'fa-check-square-o',
        l: 'check-square-o'
      },
      {
        c: 'fa-circle',
        l: 'circle'
      },
      {
        c: 'fa-circle-o',
        l: 'circle-o'
      },
      {
        c: 'fa-dot-circle-o',
        l: 'dot-circle-o'
      },
      {
        c: 'fa-minus-square',
        l: 'minus-square'
      },
      {
        c: 'fa-minus-square-o',
        l: 'minus-square-o'
      },
      {
        c: 'fa-plus-square',
        l: 'plus-square'
      },
      {
        c: 'fa-plus-square-o',
        l: 'plus-square-o'
      },
      {
        c: 'fa-square',
        l: 'square'
      },
      {
        c: 'fa-square-o',
        l: 'square-o'
      }
    ]
  },
  {
    section: 'payment',
    icons: [
      {
        c: 'fa-cc-amex',
        l: 'cc-amex'
      },
      {
        c: 'fa-cc-diners-club',
        l: 'cc-diners-club'
      },
      {
        c: 'fa-cc-discover',
        l: 'cc-discover'
      },
      {
        c: 'fa-cc-jcb',
        l: 'cc-jcb'
      },
      {
        c: 'fa-cc-mastercard',
        l: 'cc-mastercard'
      },
      {
        c: 'fa-cc-paypal',
        l: 'cc-paypal'
      },
      {
        c: 'fa-cc-stripe',
        l: 'cc-stripe'
      },
      {
        c: 'fa-cc-visa',
        l: 'cc-visa'
      },
      {
        c: 'fa-credit-card',
        l: 'credit-card'
      },
      {
        c: 'fa-credit-card-alt',
        l: 'credit-card-alt'
      },
      {
        c: 'fa-google-wallet',
        l: 'google-wallet'
      },
      {
        c: 'fa-paypal',
        l: 'paypal'
      }
    ]
  },
  {
    section: 'chart',
    icons: [
      {
        c: 'fa-area-chart',
        l: 'area-chart'
      },
      {
        c: 'fa-bar-chart',
        l: 'bar-chart'
      },
      {
        c: 'fa-bar-chart-o',
        l: 'bar-chart-o (alias)'
      },
      {
        c: 'fa-line-chart',
        l: 'line-chart'
      },
      {
        c: 'fa-pie-chart',
        l: 'pie-chart'
      }
    ]
  },
  {
    section: 'currency',
    icons: [
      {
        c: 'fa-bitcoin',
        l: 'bitcoin (alias)'
      },
      {
        c: 'fa-btc',
        l: 'btc'
      },
      {
        c: 'fa-cny',
        l: 'cny (alias)'
      },
      {
        c: 'fa-dollar',
        l: 'dollar (alias)'
      },
      {
        c: 'fa-eur',
        l: 'eur'
      },
      {
        c: 'fa-euro',
        l: 'euro (alias)'
      },
      {
        c: 'fa-gbp',
        l: 'gbp'
      },
      {
        c: 'fa-gg',
        l: 'gg'
      },
      {
        c: 'fa-gg-circle',
        l: 'gg-circle'
      },
      {
        c: 'fa-ils',
        l: 'ils'
      },
      {
        c: 'fa-inr',
        l: 'inr'
      },
      {
        c: 'fa-jpy',
        l: 'jpy'
      },
      {
        c: 'fa-krw',
        l: 'krw'
      },
      {
        c: 'fa-money',
        l: 'money'
      },
      {
        c: 'fa-rmb',
        l: 'rmb (alias)'
      },
      {
        c: 'fa-rouble',
        l: 'rouble (alias)'
      },
      {
        c: 'fa-rub',
        l: 'rub'
      },
      {
        c: 'fa-ruble',
        l: 'ruble (alias)'
      },
      {
        c: 'fa-rupee',
        l: 'rupee (alias)'
      },
      {
        c: 'fa-shekel',
        l: 'shekel (alias)'
      },
      {
        c: 'fa-sheqel',
        l: 'sheqel (alias)'
      },
      {
        c: 'fa-try',
        l: 'try'
      },
      {
        c: 'fa-turkish-lira',
        l: 'turkish-lira (alias)'
      },
      {
        c: 'fa-usd',
        l: 'usd'
      },
      {
        c: 'fa-won',
        l: 'won (alias)'
      },
      {
        c: 'fa-yen',
        l: 'yen (alias)'
      }
    ]
  },
  {
    section: 'text-editor',
    icons: [
      {
        c: 'fa-align-center',
        l: 'align-center'
      },
      {
        c: 'fa-align-justify',
        l: 'align-justify'
      },
      {
        c: 'fa-align-left',
        l: 'align-left'
      },
      {
        c: 'fa-align-right',
        l: 'align-right'
      },
      {
        c: 'fa-bold',
        l: 'bold'
      },
      {
        c: 'fa-chain',
        l: 'chain (alias)'
      },
      {
        c: 'fa-chain-broken',
        l: 'chain-broken'
      },
      {
        c: 'fa-clipboard',
        l: 'clipboard'
      },
      {
        c: 'fa-columns',
        l: 'columns'
      },
      {
        c: 'fa-copy',
        l: 'copy (alias)'
      },
      {
        c: 'fa-cut',
        l: 'cut (alias)'
      },
      {
        c: 'fa-dedent',
        l: 'dedent (alias)'
      },
      {
        c: 'fa-eraser',
        l: 'eraser'
      },
      {
        c: 'fa-file',
        l: 'file'
      },
      {
        c: 'fa-file-o',
        l: 'file-o'
      },
      {
        c: 'fa-file-text',
        l: 'file-text'
      },
      {
        c: 'fa-file-text-o',
        l: 'file-text-o'
      },
      {
        c: 'fa-files-o',
        l: 'files-o'
      },
      {
        c: 'fa-floppy-o',
        l: 'floppy-o'
      },
      {
        c: 'fa-font',
        l: 'font'
      },
      {
        c: 'fa-header',
        l: 'header'
      },
      {
        c: 'fa-indent',
        l: 'indent'
      },
      {
        c: 'fa-italic',
        l: 'italic'
      },
      {
        c: 'fa-link',
        l: 'link'
      },
      {
        c: 'fa-list',
        l: 'list'
      },
      {
        c: 'fa-list-alt',
        l: 'list-alt'
      },
      {
        c: 'fa-list-ol',
        l: 'list-ol'
      },
      {
        c: 'fa-list-ul',
        l: 'list-ul'
      },
      {
        c: 'fa-outdent',
        l: 'outdent'
      },
      {
        c: 'fa-paperclip',
        l: 'paperclip'
      },
      {
        c: 'fa-paragraph',
        l: 'paragraph'
      },
      {
        c: 'fa-paste',
        l: 'paste (alias)'
      },
      {
        c: 'fa-repeat',
        l: 'repeat'
      },
      {
        c: 'fa-rotate-left',
        l: 'rotate-left (alias)'
      },
      {
        c: 'fa-rotate-right',
        l: 'rotate-right (alias)'
      },
      {
        c: 'fa-save',
        l: 'save (alias)'
      },
      {
        c: 'fa-scissors',
        l: 'scissors'
      },
      {
        c: 'fa-strikethrough',
        l: 'strikethrough'
      },
      {
        c: 'fa-subscript',
        l: 'subscript'
      },
      {
        c: 'fa-superscript',
        l: 'superscript'
      },
      {
        c: 'fa-table',
        l: 'table'
      },
      {
        c: 'fa-text-height',
        l: 'text-height'
      },
      {
        c: 'fa-text-width',
        l: 'text-width'
      },
      {
        c: 'fa-th',
        l: 'th'
      },
      {
        c: 'fa-th-large',
        l: 'th-large'
      },
      {
        c: 'fa-th-list',
        l: 'th-list'
      },
      {
        c: 'fa-underline',
        l: 'underline'
      },
      {
        c: 'fa-undo',
        l: 'undo'
      },
      {
        c: 'fa-unlink',
        l: 'unlink (alias)'
      }
    ]
  },
  {
    section: 'directional',
    icons: [
      {
        c: 'fa-angle-double-down',
        l: 'angle-double-down'
      },
      {
        c: 'fa-angle-double-left',
        l: 'angle-double-left'
      },
      {
        c: 'fa-angle-double-right',
        l: 'angle-double-right'
      },
      {
        c: 'fa-angle-double-up',
        l: 'angle-double-up'
      },
      {
        c: 'fa-angle-down',
        l: 'angle-down'
      },
      {
        c: 'fa-angle-left',
        l: 'angle-left'
      },
      {
        c: 'fa-angle-right',
        l: 'angle-right'
      },
      {
        c: 'fa-angle-up',
        l: 'angle-up'
      },
      {
        c: 'fa-arrow-circle-down',
        l: 'arrow-circle-down'
      },
      {
        c: 'fa-arrow-circle-left',
        l: 'arrow-circle-left'
      },
      {
        c: 'fa-arrow-circle-o-down',
        l: 'arrow-circle-o-down'
      },
      {
        c: 'fa-arrow-circle-o-left',
        l: 'arrow-circle-o-left'
      },
      {
        c: 'fa-arrow-circle-o-right',
        l: 'arrow-circle-o-right'
      },
      {
        c: 'fa-arrow-circle-o-up',
        l: 'arrow-circle-o-up'
      },
      {
        c: 'fa-arrow-circle-right',
        l: 'arrow-circle-right'
      },
      {
        c: 'fa-arrow-circle-up',
        l: 'arrow-circle-up'
      },
      {
        c: 'fa-arrow-down',
        l: 'arrow-down'
      },
      {
        c: 'fa-arrow-left',
        l: 'arrow-left'
      },
      {
        c: 'fa-arrow-right',
        l: 'arrow-right'
      },
      {
        c: 'fa-arrow-up',
        l: 'arrow-up'
      },
      {
        c: 'fa-arrows',
        l: 'arrows'
      },
      {
        c: 'fa-arrows-alt',
        l: 'arrows-alt'
      },
      {
        c: 'fa-arrows-h',
        l: 'arrows-h'
      },
      {
        c: 'fa-arrows-v',
        l: 'arrows-v'
      },
      {
        c: 'fa-caret-down',
        l: 'caret-down'
      },
      {
        c: 'fa-caret-left',
        l: 'caret-left'
      },
      {
        c: 'fa-caret-right',
        l: 'caret-right'
      },
      {
        c: 'fa-caret-square-o-down',
        l: 'caret-square-o-down'
      },
      {
        c: 'fa-caret-square-o-left',
        l: 'caret-square-o-left'
      },
      {
        c: 'fa-caret-square-o-right',
        l: 'caret-square-o-right'
      },
      {
        c: 'fa-caret-square-o-up',
        l: 'caret-square-o-up'
      },
      {
        c: 'fa-caret-up',
        l: 'caret-up'
      },
      {
        c: 'fa-chevron-circle-down',
        l: 'chevron-circle-down'
      },
      {
        c: 'fa-chevron-circle-left',
        l: 'chevron-circle-left'
      },
      {
        c: 'fa-chevron-circle-right',
        l: 'chevron-circle-right'
      },
      {
        c: 'fa-chevron-circle-up',
        l: 'chevron-circle-up'
      },
      {
        c: 'fa-chevron-down',
        l: 'chevron-down'
      },
      {
        c: 'fa-chevron-left',
        l: 'chevron-left'
      },
      {
        c: 'fa-chevron-right',
        l: 'chevron-right'
      },
      {
        c: 'fa-chevron-up',
        l: 'chevron-up'
      },
      {
        c: 'fa-exchange',
        l: 'exchange'
      },
      {
        c: 'fa-hand-o-down',
        l: 'hand-o-down'
      },
      {
        c: 'fa-hand-o-left',
        l: 'hand-o-left'
      },
      {
        c: 'fa-hand-o-right',
        l: 'hand-o-right'
      },
      {
        c: 'fa-hand-o-up',
        l: 'hand-o-up'
      },
      {
        c: 'fa-long-arrow-down',
        l: 'long-arrow-down'
      },
      {
        c: 'fa-long-arrow-left',
        l: 'long-arrow-left'
      },
      {
        c: 'fa-long-arrow-right',
        l: 'long-arrow-right'
      },
      {
        c: 'fa-long-arrow-up',
        l: 'long-arrow-up'
      },
      {
        c: 'fa-toggle-down',
        l: 'toggle-down (alias)'
      },
      {
        c: 'fa-toggle-left',
        l: 'toggle-left (alias)'
      },
      {
        c: 'fa-toggle-right',
        l: 'toggle-right (alias)'
      },
      {
        c: 'fa-toggle-up',
        l: 'toggle-up (alias)'
      }
    ]
  },
  {
    section: 'video-player',
    icons: [
      {
        c: 'fa-arrows-alt',
        l: 'arrows-alt'
      },
      {
        c: 'fa-backward',
        l: 'backward'
      },
      {
        c: 'fa-compress',
        l: 'compress'
      },
      {
        c: 'fa-eject',
        l: 'eject'
      },
      {
        c: 'fa-expand',
        l: 'expand'
      },
      {
        c: 'fa-fast-backward',
        l: 'fast-backward'
      },
      {
        c: 'fa-fast-forward',
        l: 'fast-forward'
      },
      {
        c: 'fa-forward',
        l: 'forward'
      },
      {
        c: 'fa-pause',
        l: 'pause'
      },
      {
        c: 'fa-pause-circle',
        l: 'pause-circle'
      },
      {
        c: 'fa-pause-circle-o',
        l: 'pause-circle-o'
      },
      {
        c: 'fa-play',
        l: 'play'
      },
      {
        c: 'fa-play-circle',
        l: 'play-circle'
      },
      {
        c: 'fa-play-circle-o',
        l: 'play-circle-o'
      },
      {
        c: 'fa-random',
        l: 'random'
      },
      {
        c: 'fa-step-backward',
        l: 'step-backward'
      },
      {
        c: 'fa-step-forward',
        l: 'step-forward'
      },
      {
        c: 'fa-stop',
        l: 'stop'
      },
      {
        c: 'fa-stop-circle',
        l: 'stop-circle'
      },
      {
        c: 'fa-stop-circle-o',
        l: 'stop-circle-o'
      },
      {
        c: 'fa-youtube-play',
        l: 'youtube-play'
      }
    ]
  },
  {
    section: 'brand',
    icons: [
      {
        c: 'fa-500px',
        l: '500px'
      },
      {
        c: 'fa-adn',
        l: 'adn'
      },
      {
        c: 'fa-amazon',
        l: 'amazon'
      },
      {
        c: 'fa-android',
        l: 'android'
      },
      {
        c: 'fa-angellist',
        l: 'angellist'
      },
      {
        c: 'fa-apple',
        l: 'apple'
      },
      {
        c: 'fa-bandcamp',
        l: 'bandcamp'
      },
      {
        c: 'fa-behance',
        l: 'behance'
      },
      {
        c: 'fa-behance-square',
        l: 'behance-square'
      },
      {
        c: 'fa-bitbucket',
        l: 'bitbucket'
      },
      {
        c: 'fa-bitbucket-square',
        l: 'bitbucket-square'
      },
      {
        c: 'fa-bitcoin',
        l: 'bitcoin (alias)'
      },
      {
        c: 'fa-black-tie',
        l: 'black-tie'
      },
      {
        c: 'fa-bluetooth',
        l: 'bluetooth'
      },
      {
        c: 'fa-bluetooth-b',
        l: 'bluetooth-b'
      },
      {
        c: 'fa-btc',
        l: 'btc'
      },
      {
        c: 'fa-buysellads',
        l: 'buysellads'
      },
      {
        c: 'fa-cc-amex',
        l: 'cc-amex'
      },
      {
        c: 'fa-cc-diners-club',
        l: 'cc-diners-club'
      },
      {
        c: 'fa-cc-discover',
        l: 'cc-discover'
      },
      {
        c: 'fa-cc-jcb',
        l: 'cc-jcb'
      },
      {
        c: 'fa-cc-mastercard',
        l: 'cc-mastercard'
      },
      {
        c: 'fa-cc-paypal',
        l: 'cc-paypal'
      },
      {
        c: 'fa-cc-stripe',
        l: 'cc-stripe'
      },
      {
        c: 'fa-cc-visa',
        l: 'cc-visa'
      },
      {
        c: 'fa-chrome',
        l: 'chrome'
      },
      {
        c: 'fa-codepen',
        l: 'codepen'
      },
      {
        c: 'fa-codiepie',
        l: 'codiepie'
      },
      {
        c: 'fa-connectdevelop',
        l: 'connectdevelop'
      },
      {
        c: 'fa-contao',
        l: 'contao'
      },
      {
        c: 'fa-css3',
        l: 'css3'
      },
      {
        c: 'fa-dashcube',
        l: 'dashcube'
      },
      {
        c: 'fa-delicious',
        l: 'delicious'
      },
      {
        c: 'fa-deviantart',
        l: 'deviantart'
      },
      {
        c: 'fa-digg',
        l: 'digg'
      },
      {
        c: 'fa-dribbble',
        l: 'dribbble'
      },
      {
        c: 'fa-dropbox',
        l: 'dropbox'
      },
      {
        c: 'fa-drupal',
        l: 'drupal'
      },
      {
        c: 'fa-edge',
        l: 'edge'
      },
      {
        c: 'fa-eercast',
        l: 'eercast'
      },
      {
        c: 'fa-empire',
        l: 'empire'
      },
      {
        c: 'fa-envira',
        l: 'envira'
      },
      {
        c: 'fa-etsy',
        l: 'etsy'
      },
      {
        c: 'fa-expeditedssl',
        l: 'expeditedssl'
      },
      {
        c: 'fa-fa',
        l: 'fa (alias)'
      },
      {
        c: 'fa-facebook',
        l: 'facebook'
      },
      {
        c: 'fa-facebook-f',
        l: 'facebook-f (alias)'
      },
      {
        c: 'fa-facebook-official',
        l: 'facebook-official'
      },
      {
        c: 'fa-facebook-square',
        l: 'facebook-square'
      },
      {
        c: 'fa-firefox',
        l: 'firefox'
      },
      {
        c: 'fa-first-order',
        l: 'first-order'
      },
      {
        c: 'fa-flickr',
        l: 'flickr'
      },
      {
        c: 'fa-font-awesome',
        l: 'font-awesome'
      },
      {
        c: 'fa-fonticons',
        l: 'fonticons'
      },
      {
        c: 'fa-fort-awesome',
        l: 'fort-awesome'
      },
      {
        c: 'fa-forumbee',
        l: 'forumbee'
      },
      {
        c: 'fa-foursquare',
        l: 'foursquare'
      },
      {
        c: 'fa-free-code-camp',
        l: 'free-code-camp'
      },
      {
        c: 'fa-ge',
        l: 'ge (alias)'
      },
      {
        c: 'fa-get-pocket',
        l: 'get-pocket'
      },
      {
        c: 'fa-gg',
        l: 'gg'
      },
      {
        c: 'fa-gg-circle',
        l: 'gg-circle'
      },
      {
        c: 'fa-git',
        l: 'git'
      },
      {
        c: 'fa-git-square',
        l: 'git-square'
      },
      {
        c: 'fa-github',
        l: 'github'
      },
      {
        c: 'fa-github-alt',
        l: 'github-alt'
      },
      {
        c: 'fa-github-square',
        l: 'github-square'
      },
      {
        c: 'fa-gitlab',
        l: 'gitlab'
      },
      {
        c: 'fa-gittip',
        l: 'gittip (alias)'
      },
      {
        c: 'fa-glide',
        l: 'glide'
      },
      {
        c: 'fa-glide-g',
        l: 'glide-g'
      },
      {
        c: 'fa-google',
        l: 'google'
      },
      {
        c: 'fa-google-plus',
        l: 'google-plus'
      },
      {
        c: 'fa-google-plus-circle',
        l: 'google-plus-circle (alias)'
      },
      {
        c: 'fa-google-plus-official',
        l: 'google-plus-official'
      },
      {
        c: 'fa-google-plus-square',
        l: 'google-plus-square'
      },
      {
        c: 'fa-google-wallet',
        l: 'google-wallet'
      },
      {
        c: 'fa-gratipay',
        l: 'gratipay'
      },
      {
        c: 'fa-grav',
        l: 'grav'
      },
      {
        c: 'fa-hacker-news',
        l: 'hacker-news'
      },
      {
        c: 'fa-houzz',
        l: 'houzz'
      },
      {
        c: 'fa-html5',
        l: 'html5'
      },
      {
        c: 'fa-imdb',
        l: 'imdb'
      },
      {
        c: 'fa-instagram',
        l: 'instagram'
      },
      {
        c: 'fa-internet-explorer',
        l: 'internet-explorer'
      },
      {
        c: 'fa-ioxhost',
        l: 'ioxhost'
      },
      {
        c: 'fa-joomla',
        l: 'joomla'
      },
      {
        c: 'fa-jsfiddle',
        l: 'jsfiddle'
      },
      {
        c: 'fa-lastfm',
        l: 'lastfm'
      },
      {
        c: 'fa-lastfm-square',
        l: 'lastfm-square'
      },
      {
        c: 'fa-leanpub',
        l: 'leanpub'
      },
      {
        c: 'fa-linkedin',
        l: 'linkedin'
      },
      {
        c: 'fa-linkedin-square',
        l: 'linkedin-square'
      },
      {
        c: 'fa-linode',
        l: 'linode'
      },
      {
        c: 'fa-linux',
        l: 'linux'
      },
      {
        c: 'fa-maxcdn',
        l: 'maxcdn'
      },
      {
        c: 'fa-meanpath',
        l: 'meanpath'
      },
      {
        c: 'fa-medium',
        l: 'medium'
      },
      {
        c: 'fa-meetup',
        l: 'meetup'
      },
      {
        c: 'fa-mixcloud',
        l: 'mixcloud'
      },
      {
        c: 'fa-modx',
        l: 'modx'
      },
      {
        c: 'fa-odnoklassniki',
        l: 'odnoklassniki'
      },
      {
        c: 'fa-odnoklassniki-square',
        l: 'odnoklassniki-square'
      },
      {
        c: 'fa-opencart',
        l: 'opencart'
      },
      {
        c: 'fa-openid',
        l: 'openid'
      },
      {
        c: 'fa-opera',
        l: 'opera'
      },
      {
        c: 'fa-optin-monster',
        l: 'optin-monster'
      },
      {
        c: 'fa-pagelines',
        l: 'pagelines'
      },
      {
        c: 'fa-paypal',
        l: 'paypal'
      },
      {
        c: 'fa-pied-piper',
        l: 'pied-piper'
      },
      {
        c: 'fa-pied-piper-alt',
        l: 'pied-piper-alt'
      },
      {
        c: 'fa-pied-piper-pp',
        l: 'pied-piper-pp'
      },
      {
        c: 'fa-pinterest',
        l: 'pinterest'
      },
      {
        c: 'fa-pinterest-p',
        l: 'pinterest-p'
      },
      {
        c: 'fa-pinterest-square',
        l: 'pinterest-square'
      },
      {
        c: 'fa-product-hunt',
        l: 'product-hunt'
      },
      {
        c: 'fa-qq',
        l: 'qq'
      },
      {
        c: 'fa-quora',
        l: 'quora'
      },
      {
        c: 'fa-ra',
        l: 'ra (alias)'
      },
      {
        c: 'fa-ravelry',
        l: 'ravelry'
      },
      {
        c: 'fa-rebel',
        l: 'rebel'
      },
      {
        c: 'fa-reddit',
        l: 'reddit'
      },
      {
        c: 'fa-reddit-alien',
        l: 'reddit-alien'
      },
      {
        c: 'fa-reddit-square',
        l: 'reddit-square'
      },
      {
        c: 'fa-renren',
        l: 'renren'
      },
      {
        c: 'fa-resistance',
        l: 'resistance (alias)'
      },
      {
        c: 'fa-safari',
        l: 'safari'
      },
      {
        c: 'fa-scribd',
        l: 'scribd'
      },
      {
        c: 'fa-sellsy',
        l: 'sellsy'
      },
      {
        c: 'fa-share-alt',
        l: 'share-alt'
      },
      {
        c: 'fa-share-alt-square',
        l: 'share-alt-square'
      },
      {
        c: 'fa-shirtsinbulk',
        l: 'shirtsinbulk'
      },
      {
        c: 'fa-simplybuilt',
        l: 'simplybuilt'
      },
      {
        c: 'fa-skyatlas',
        l: 'skyatlas'
      },
      {
        c: 'fa-skype',
        l: 'skype'
      },
      {
        c: 'fa-slack',
        l: 'slack'
      },
      {
        c: 'fa-slideshare',
        l: 'slideshare'
      },
      {
        c: 'fa-snapchat',
        l: 'snapchat'
      },
      {
        c: 'fa-snapchat-ghost',
        l: 'snapchat-ghost'
      },
      {
        c: 'fa-snapchat-square',
        l: 'snapchat-square'
      },
      {
        c: 'fa-soundcloud',
        l: 'soundcloud'
      },
      {
        c: 'fa-spotify',
        l: 'spotify'
      },
      {
        c: 'fa-stack-exchange',
        l: 'stack-exchange'
      },
      {
        c: 'fa-stack-overflow',
        l: 'stack-overflow'
      },
      {
        c: 'fa-steam',
        l: 'steam'
      },
      {
        c: 'fa-steam-square',
        l: 'steam-square'
      },
      {
        c: 'fa-stumbleupon',
        l: 'stumbleupon'
      },
      {
        c: 'fa-stumbleupon-circle',
        l: 'stumbleupon-circle'
      },
      {
        c: 'fa-superpowers',
        l: 'superpowers'
      },
      {
        c: 'fa-telegram',
        l: 'telegram'
      },
      {
        c: 'fa-tencent-weibo',
        l: 'tencent-weibo'
      },
      {
        c: 'fa-themeisle',
        l: 'themeisle'
      },
      {
        c: 'fa-trello',
        l: 'trello'
      },
      {
        c: 'fa-tripadvisor',
        l: 'tripadvisor'
      },
      {
        c: 'fa-tumblr',
        l: 'tumblr'
      },
      {
        c: 'fa-tumblr-square',
        l: 'tumblr-square'
      },
      {
        c: 'fa-twitch',
        l: 'twitch'
      },
      {
        c: 'fa-twitter',
        l: 'twitter'
      },
      {
        c: 'fa-twitter-square',
        l: 'twitter-square'
      },
      {
        c: 'fa-usb',
        l: 'usb'
      },
      {
        c: 'fa-viacoin',
        l: 'viacoin'
      },
      {
        c: 'fa-viadeo',
        l: 'viadeo'
      },
      {
        c: 'fa-viadeo-square',
        l: 'viadeo-square'
      },
      {
        c: 'fa-vimeo',
        l: 'vimeo'
      },
      {
        c: 'fa-vimeo-square',
        l: 'vimeo-square'
      },
      {
        c: 'fa-vine',
        l: 'vine'
      },
      {
        c: 'fa-vk',
        l: 'vk'
      },
      {
        c: 'fa-wechat',
        l: 'wechat (alias)'
      },
      {
        c: 'fa-weibo',
        l: 'weibo'
      },
      {
        c: 'fa-weixin',
        l: 'weixin'
      },
      {
        c: 'fa-whatsapp',
        l: 'whatsapp'
      },
      {
        c: 'fa-wikipedia-w',
        l: 'wikipedia-w'
      },
      {
        c: 'fa-windows',
        l: 'windows'
      },
      {
        c: 'fa-wordpress',
        l: 'wordpress'
      },
      {
        c: 'fa-wpbeginner',
        l: 'wpbeginner'
      },
      {
        c: 'fa-wpexplorer',
        l: 'wpexplorer'
      },
      {
        c: 'fa-wpforms',
        l: 'wpforms'
      },
      {
        c: 'fa-xing',
        l: 'xing'
      },
      {
        c: 'fa-xing-square',
        l: 'xing-square'
      },
      {
        c: 'fa-y-combinator',
        l: 'y-combinator'
      },
      {
        c: 'fa-y-combinator-square',
        l: 'y-combinator-square (alias)'
      },
      {
        c: 'fa-yahoo',
        l: 'yahoo'
      },
      {
        c: 'fa-yc',
        l: 'yc (alias)'
      },
      {
        c: 'fa-yc-square',
        l: 'yc-square (alias)'
      },
      {
        c: 'fa-yelp',
        l: 'yelp'
      },
      {
        c: 'fa-yoast',
        l: 'yoast'
      },
      {
        c: 'fa-youtube',
        l: 'youtube'
      },
      {
        c: 'fa-youtube-play',
        l: 'youtube-play'
      },
      {
        c: 'fa-youtube-square',
        l: 'youtube-square'
      }
    ]
  },
  {
    section: 'medical',
    icons: [
      {
        c: 'fa-ambulance',
        l: 'ambulance'
      },
      {
        c: 'fa-h-square',
        l: 'h-square'
      },
      {
        c: 'fa-heart',
        l: 'heart'
      },
      {
        c: 'fa-heart-o',
        l: 'heart-o'
      },
      {
        c: 'fa-heartbeat',
        l: 'heartbeat'
      },
      {
        c: 'fa-hospital-o',
        l: 'hospital-o'
      },
      {
        c: 'fa-medkit',
        l: 'medkit'
      },
      {
        c: 'fa-plus-square',
        l: 'plus-square'
      },
      {
        c: 'fa-stethoscope',
        l: 'stethoscope'
      },
      {
        c: 'fa-user-md',
        l: 'user-md'
      },
      {
        c: 'fa-wheelchair',
        l: 'wheelchair'
      },
      {
        c: 'fa-wheelchair-alt',
        l: 'wheelchair-alt'
      }
    ]
  }
]
