import React from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { selectProduct as onProductSelect, saveResourceIndex } from '../../../../actions'
import { getRowBgColor } from '../../../../utils'

class Box extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSelected: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(
    productIndex,
    periodIndex,
    testResourceId,
    testResourceName,
    periodName,
    periodDisplayName,
    periodId,
    productName,
    productId,
    locationNames,
    isGrandTotal,
    pegTags,
    hasProduction,
    path
  ) {
    const { type, isConsumedBy, onPeriodChange, testResourceIndex, isProductPeriod } = this.props
    if (isConsumedBy) {
      onPeriodChange(periodIndex)
      return
    }
    this.props.onProductSelect({
      productIndex,
      periodIndex,
      isResourceChart: false,
      resourceName: testResourceName,
      resourceId: testResourceId,
      periodName,
      periodDisplayName,
      periodId,
      productName,
      productId,
      locationNames,
      pegTags,
      hasProduction,
      path,
      isProductPeriod
    })
    this.props.saveResourceIndex(testResourceIndex)
  }

  render() {
    const {
      productIndex = undefined,
      periodIndex = undefined,
      clickedProductInfo: {
        productIndex: clickedProductIndex = undefined,
        periodIndex: clickedPeriodIndex = undefined,
        locationNames: clickedCellLocationNames = []
      } = {},
      year = undefined,
      type = undefined,
      paintOnClick,
      isIssue,
      testResourceIndex = 0,
      resourceIndex,
      isFrozenWeek = false,
      testResourceId,
      testResourceName,
      periodName,
      periodDisplayName,
      periodId,
      productName,
      productId,
      locationNames,
      children,
      isGrandTotal,
      pegTags,
      hasProduction,
      path,
      isGrandTotalPinned,
      globalIndex
    } = this.props

    const isClicked =
      periodIndex === clickedPeriodIndex && isEqual(locationNames, clickedCellLocationNames)

    const boxStyles = {
      ...(isIssue && { alignItems: 'center' }),
      ...(productIndex === clickedProductIndex &&
        periodIndex === clickedPeriodIndex &&
        productIndex !== undefined &&
        type === 'Product' &&
        paintOnClick &&
        testResourceIndex === resourceIndex && {
          zIndex: 200
        }),
      ...(year === undefined && { backgroundColor: '#f8f8f8' }),
      ...((type !== 'Product' || isGrandTotal) && { cursor: 'default' }),
      background: getRowBgColor(globalIndex, type)
      // marginTop: type === 'Product' && paintOnClick ? 1 : ''
    }

    return (
      <div
        className={`resource-plan__period-box ${isClicked ? 'is-clicked' : ''}
        ${isGrandTotal ? 'sticky-grand-total' : ''}
        ${isGrandTotalPinned ? 'position-sticky end-0' : ''}
        `}
        style={boxStyles}
        onMouseDown={this.handleClick.bind(
          this,
          productIndex,
          periodIndex,
          testResourceId,
          testResourceName,
          periodName,
          periodDisplayName,
          periodId,
          productName,
          productId,
          locationNames,
          isGrandTotal,
          pegTags,
          hasProduction,
          path
        )}
      >
        {isFrozenWeek && <div className="is-frozen-week" />}
        {children}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
  resourceIndex: state.clickedProductInfo.resourceIndex,
  isGrandTotalPinned: state.resourcePlan.isGrandTotalPinned
})

const mapDispatchToProps = {
  onProductSelect,
  saveResourceIndex
}

const ConnectedBox = connect(mapStateToProps, mapDispatchToProps)(Box)
export default React.memo(ConnectedBox)
