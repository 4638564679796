import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Box from '../box'
import { slvyToast } from '../../../../../../components'
import { ChartCell } from '../../../../components'
import {
  val,
  format,
  getCoverKey,
  getCoverStyle,
  getAttachments,
  getMessage,
  editReason,
  parse,
  cellLoading,
  isStateCellTargetEmpty,
  patchDiff,
  displayOfflineErrorMsg,
  getCoverViolations,
  getIssueSize
} from '../../../../utils'
import Queue from '../../../../utils/queue'
import { coverColors } from '../../../../utils/constants'
import CustomConsole from '../../../../utils/custom-console'
import checkNumberInRange from '../../../../utils/check-number'

class ResourceRowItemInfo extends React.Component {
  constructor() {
    super()
    this.state = {
      currentSelection: '2',
      currentReason: {},
      updatedPeriodId: -1,
      attachments: [],
      isUserAllowedToChangeProductionQuantity: true,
      attachmentTableName: ''
    }

    this.cellTarget = null
    this.modificationCount = null

    this.getAggregationValue = this.getAggregationValue.bind(this)
    this.getResourceInfoList = this.getResourceInfoList.bind(this)
    this.getBoxValues = this.getBoxValues.bind(this)
  }

  componentDidMount() {
    const { isAllowed = () => {} } = this.props

    const isUserAllowedToChangeProductionQuantity = isAllowed(
      'Resource and Production/Resource Based Production Quantity'
    )

    this.setState({
      isUserAllowedToChangeProductionQuantity
    })
  }

  componentDidUpdate() {
    const isUndoRedo =
      this.props.lastRequest?.last?.request?.rccpConfig?.RequestType?.match(/Undo|Redo/)

    if (isUndoRedo) {
      this.modificationCount = null
    }
  }

  updateHandler(params, e, extras = {}) {
    return new Promise((resolveHandler) => {
      if (_.isEmpty(extras) && _.isEmpty(e)) {
        this.setState({
          currentReason: {},
          attachments: []
        })
      }

      const {
        initialFilters: { locationFilter = {}, resourceFilter = {}, productFilter = {} } = {},
        settings: {
          modificationCount = 0,
          updateReasons = {},
          resourceAndProductPlanSettings: {
            grandTotal: { isEnabled: isGrandTotalEnabled = true } = {}
          } = {}
        } = {},
        periodDates: { StartDate = '', EndDate = '' } = {},
        resourcePeriodHierarchy = {},
        connectApi = () => {},
        stateHandler = () => {}
      } = this.props
      let { cellTarget } = this

      let { keyCode, target, target: { value = '' } = {} } = e
      const { isUpdate, productId, periodId, resourceId, tableName: attachmentTableName } = params
      const { isForce = false, itemValue = value, clickUpdate = false } = extras

      value = parse(value)

      if ((keyCode === 13 && isUpdate) || (clickUpdate && isUpdate)) {
        const set = {
          isopen: true,
          node: target,
          reasondata: updateReasons
        }

        const update = () => {
          const { currentReason: { code: reasonCode, name: reasonName } = {} } = this.state

          const updateRequest = () => ({
            hierLocationFilter: locationFilter,
            hierResourceFilter: resourceFilter,
            hierProductFilter: productFilter,
            hierProductId: -1,
            fieldName: attachmentTableName,
            productId,
            periodId,
            resourceId,
            newValue: clickUpdate ? itemValue : value,
            rccpConfig: {
              isGrandTotalEnabled,
              ...(isForce ? { ForceUpdate: true } : {}),
              UpdateReasonCode: reasonCode,
              UpdateReasonDescription: reasonName,
              ModificationCount: this.modificationCount
                ? this.modificationCount
                : modificationCount,
              StartDate,
              EndDate
            }
          })

          // TODO: Refactor...
          if (target.parentNode.tagName === 'HEADER') cellTarget = target.parentNode
          cellLoading(target.parentNode, true)
          const updateApi = (req) => {
            return new Promise((resolve, reject) => {
              connectApi('UpdateRccpResourcePeriodProductAndReturnHierarchyDiffWPF', {
                ...req,
                isQueue: true
              })
                .then((res) => {
                  let {
                    Description = 'Error',
                    Code = -1,
                    Json,
                    IsDiff = false,
                    ModificationCount
                  } = res

                  this.modificationCount = ModificationCount
                  try {
                    Json = JSON.parse(Json)
                  } catch (error) {
                    slvyToast.error({ message: 'Parsing Failed!' })
                    CustomConsole.error(`Error: ${error}`)
                  }

                  let Attachments = []
                  if (Code < 1) {
                    const { Attachments: xAttachments = [] } = Json ?? {}
                    Attachments = xAttachments
                  } else {
                    const { Attachments: xAttachments = [] } = res
                    Attachments = xAttachments
                  }

                  const isAttachments = !_.isEmpty(Attachments)

                  if (isAttachments) {
                    this.setState({
                      attachments: getAttachments(Attachments, value),
                      updatedPeriodId: periodId,
                      attachmentTableName
                    })
                  }
                  getMessage(Code, Description).then((ret) => {
                    if (ret.isOk) {
                      if (clickUpdate) {
                        this.setState({
                          attachments: [],
                          updatedPeriodId: -1
                        })
                      }

                      if (Code !== 0) {
                        patchDiff({
                          left: resourcePeriodHierarchy,
                          diffJson: Json,
                          isDiff: IsDiff,
                          stateName: 'GetRccpResourcePeriodHierarchyResponseWPF',
                          setState: stateHandler
                        })
                      }

                      isStateCellTargetEmpty(cellTarget)
                        ? cellLoading(target.parentNode, false)
                        : cellLoading(cellTarget, false)
                      resolve()
                    } else {
                      isStateCellTargetEmpty(cellTarget)
                        ? cellLoading(target.parentNode, false)
                        : cellLoading(cellTarget, false)
                      reject()
                    }
                  })
                })
                .catch(() => {
                  displayOfflineErrorMsg()

                  isStateCellTargetEmpty(cellTarget)
                    ? cellLoading(target.parentNode, false)
                    : cellLoading(cellTarget, false)
                  reject()
                })
            })
          }

          Queue.enqueue(updateApi, updateRequest)
            .catch((error) => CustomConsole.error(error))
            .finally(resolveHandler)
        }

        if (_.isEmpty(this.state.attachments)) {
          editReason(set, {
            onConfirm: (e, isok, reason) => {
              this.setState({ currentReason: reason }, () => update())
            },
            onReject: resolveHandler
          })
        } else {
          update()
        }
      } else {
        resolveHandler()
      }
      cellLoading(cellTarget, false)
    })
  }

  clickHandler(pindex, periodId, e) {
    e.preventDefault()

    const { currentSelection } = this.state

    if (currentSelection !== pindex) {
      // chartHandler(round(pindex))
      this.setState({
        currentSelection: pindex,
        updatedPeriodId: periodId,
        attachments: [],
        currentReason: {}
      })
    }
  }

  getAggregationValue(hierarchyResourcePeriodProduct, issueIndex, index) {
    const {
      index: childrenIndex,
      productLegendData: { resourceInfoList: productInfoList = [] } = {},
      resourcePeriodHierarchy = {},
      settings: {
        stateHandler: { selectedParameter: { Index: selectedMassUnitIndex = 0 } = {} } = {}
      } = {}
    } = this.props
    const {
      HierarchyProductPeriods: {
        [childrenIndex]: { [index]: hierarchyProductPeriod = {} } = {}
      } = {}
    } = resourcePeriodHierarchy

    let {
      tableName = 'Production',
      matchFieldName = false,
      format: formatType = '0,0',
      isResourceBased = false
    } = productInfoList[issueIndex] || {}

    // TODO: Remove this when old services stop using 'ShiftTonage'
    if (tableName === 'ShiftTonage') {
      tableName = 'ShiftTonnage'
    }

    let aggregationValue = 0
    if (!isResourceBased && !_.isNil(hierarchyProductPeriod[tableName])) {
      const { Values = {} } = hierarchyProductPeriod || {}
      const value = val(Values, tableName, selectedMassUnitIndex)

      aggregationValue = matchFieldName && value ? value : hierarchyProductPeriod[tableName]
    } else if (!_.isEmpty(hierarchyResourcePeriodProduct)) {
      const { Values = {} } = hierarchyResourcePeriodProduct || {}
      const value = val(Values, tableName, selectedMassUnitIndex)

      aggregationValue = matchFieldName && value ? value : hierarchyResourcePeriodProduct[tableName]
    }

    const cellValue = format(aggregationValue, formatType)
    return (
      <span key={tableName}>
        <span>{cellValue}</span>
      </span>
    )
  }

  getResourceInfoList(resourceInfoList, item, index) {
    const { tableName = 'MajorSetupPercentage', format: formatType = '0,0' } =
      resourceInfoList[index] || {}

    const cellValue = format(item[tableName], formatType)
    return (
      <span key={index}>
        <span>{cellValue}</span>
      </span>
    )
  }

  getBoxValues(
    product,
    index,
    isUpdate,
    productId,
    periodId,
    pindex,
    resourceBasedProduct,
    isFrozenWeek
  ) {
    const {
      resourcePeriodHierarchy: { Periods, Resources } = {},
      paintOnClick,
      settings,
      settings: { coverSettings: { cover = '' } = {} },
      productLegendData: { resourceInfoList: productInfoList = [] } = {},
      settings: {
        stateHandler: { selectedParameter: { Index: selectedMassUnitIndex = 0 } = {} } = {},
        views: { newResourcePlanView }
      },
      resourceIndex = 0,
      selectedSession
    } = this.props

    const { Values: productValues } = product || {}
    let {
      [index]: {
        matchFieldName = false,
        tableName = 'Production',
        isEditable = false,
        format: formatType = '0,0',
        isResourceBased = false
      } = {}
    } = productInfoList
    const {
      attachments,
      updatedPeriodId,
      isUserAllowedToChangeProductionQuantity = true,
      attachmentTableName
    } = this.state

    const { IsGrandTotal = true } = Periods[pindex] || {}

    if (tableName === 'Cover') {
      tableName = cover
    }

    const { Values: ResourceBasedProductValues } = resourceBasedProduct || {}

    let boxValue
    if (isResourceBased) {
      boxValue = matchFieldName
        ? val(ResourceBasedProductValues, tableName, selectedMassUnitIndex)
        : resourceBasedProduct[tableName]
    } else if (product[tableName]) {
      boxValue = matchFieldName
        ? val(productValues, tableName, selectedMassUnitIndex)
        : product[tableName]
    } else {
      boxValue = matchFieldName
        ? val(ResourceBasedProductValues, tableName, selectedMassUnitIndex)
        : resourceBasedProduct[tableName]
    }

    // TODO: Remove this when old services stop using 'ShiftTonage'
    if (tableName === 'ShiftTonage' || tableName === 'ShiftTonnage') {
      boxValue = resourceBasedProduct?.ShiftTonnage ?? 0
    }

    if (isEditable) {
      const { Id = 0 } = Resources[resourceIndex] || {}
      return (
        <ChartCell
          key={tableName}
          attachments={{
            attachments: attachmentTableName === tableName ? attachments : [],
            isActive: updatedPeriodId === periodId
          }}
          disabled={
            (selectedSession.IsDebug && newResourcePlanView) ||
            !isUpdate ||
            !paintOnClick ||
            !isUserAllowedToChangeProductionQuantity ||
            IsGrandTotal
          }
          formatType={formatType}
          isFrozenWeek={isFrozenWeek}
          settings={settings}
          updateHandler={this.updateHandler.bind(this, {
            isUpdate,
            productId,
            periodId,
            resourceId: Id,
            tableName
          })}
          value={!paintOnClick && tableName === 'Production' ? '-' : format(boxValue, formatType)}
          onClick={this.clickHandler.bind(this, pindex, periodId)}
          onFocus={() => this.setState({ currentReason: {}, attachments: [] })}
        />
      )
    }
    return (
      <span className="production-info-cell-value" key={`box${index}`}>
        {' '}
        {format(boxValue, formatType)}
      </span>
    )
  }

  render() {
    let {
      index: childrenIndex = 0,
      type = '',
      path,

      ResourcePeriodsHierarchy: { ResourceProducts: resourceProducts = {} } = {},

      resourcePeriodHierarchy: {
        Products: products = {},
        Resources: resources = {},
        ResourcePeriods: resourcePeriods = {},
        ProductPeriods: productPeriods = {},
        Periods: periods = [],
        MaxRccpResourcePeriod: { IssueValues: MaxIssueValues },
        MaxRccpProductPeriod: { IssueValues: maxProductIssueValues },
        HierarchyResourcePeriodProducts: hierarchyResourcePeriodProducts = {},
        HierarchyProductPeriods = {}
      } = {},

      settings: {
        resourceIssueSettings = {},
        issueSettings: productIssueSettings = [],
        coverSettings: { cover = '', minCover = '' } = {},
        totalUtilization: { minUtilization, maxUtilization } = {},
        stateHandler: {
          selectedParameter: { Index: selectedMassUnitIndex = 0 } = {},
          selectedProductVariant = 'Production'
        } = {},
        resourceAndProductPlanSettings: {
          metricSettings: { isMetricOrderSorted = false } = {},
          aggregatedValues: { isEnabled: isAggregatedValuesEnabled = true } = {}
        } = {},
        productionVariants: { userOptions: { isEnabled: isUserOptionsEnabled = false } = {} } = {}
      } = {},

      productLegendData: {
        issueList: productIssueList = [],
        resourceInfoList: productInfoList = []
      } = {},

      productLegendState: {
        currentIssueIndex: currentProductIssueIndex = 0,
        currentProductInfoIndex: currentProductionInfoIndex = 0,
        isProductInfo: isProductProductInfo = false
      } = {},

      legendState: {
        currentIssueIndex = 0,
        currentProductInfoIndex = 0,
        isProductInfo = false
      } = {},

      legendData: { resourceInfoList = [] } = {},
      resourceIndex = 0,
      testResourceIndex = 0,

      coverViolationIndexes,
      paintOnClick,
      isConsumedBy,
      locationNames
    } = this.props

    if (!paintOnClick) {
      productPeriods = this.props.productPeriods
      periods = this.props.periods
    } else {
      productPeriods = productPeriods[childrenIndex]
    }

    const periodBarStyle = {
      width: _.size(periods) * 70,
      ...(type === 'Resource' && { backgroundColor: '#fff' })
    }

    const { [testResourceIndex]: { Id: resourceId, Name: resourceName } = [] } = resources

    return (
      <div className="resource-row-item-info" style={periodBarStyle}>
        {type === 'Resource' &&
          _.map(resourcePeriods[childrenIndex], (item, index) => {
            // if (isProductInfo) {
            //   return (
            //     <Box
            //       key={index}
            //       year={
            //         Math.round(
            //           item[props.legendData.resourceInfoList[currentProductInfoIndex].tableName]
            //         ) + '%'
            //       }
            //     />
            //   )
            // }
            const { color, tableName } = resourceIssueSettings[currentIssueIndex] || {}

            const issueValue = item.IssueValues[currentIssueIndex]
            const maxIssueValue = MaxIssueValues[currentIssueIndex] || 0
            const itemSizeIssue = getIssueSize(issueValue, maxIssueValue, tableName, 23)

            const { IsGrandTotal = true } = periods[index] || {}
            const {
              Id: ownResourceId,
              Index: ownResourceIndex,
              Name: ownResourceName
            } = resources[childrenIndex]
            const { Id: periodId, Name: periodName } = periods[index]

            const totalUtilizationInRange = checkNumberInRange(
              item?.TotalPercentage || 0,
              minUtilization,
              maxUtilization
            )

            return (
              <Box
                key={index}
                year
                globalIndex={this.props.globalIndex}
                locationNames={locationNames}
                type={type}
                isGrandTotal={IsGrandTotal}
                testResourceId={ownResourceId}
                testResourceName={ownResourceName}
                testResourceIndex={ownResourceIndex}
                periodName={periodName}
                periodId={periodId}
                periodIndex={Number(index)}
              >
                {!isProductInfo
                  ? itemSizeIssue > 0 && (
                      <i
                        style={{
                          height: itemSizeIssue,
                          width: itemSizeIssue,
                          backgroundColor: color
                        }}
                      />
                    )
                  : _.map(
                      isMetricOrderSorted
                        ? _.sortBy(currentProductInfoIndex)
                        : currentProductInfoIndex,
                      (productInfoItemIndex) =>
                        this.getResourceInfoList(resourceInfoList, item, productInfoItemIndex)
                    )}

                {totalUtilizationInRange === false ? (
                  <div
                    className="position-absolute bg-danger start-0 end-0 shadow"
                    style={{
                      top: 1,
                      height: 1
                    }}
                  />
                ) : null}
              </Box>
            )
          })}

        {type === 'Product' &&
          _.map(productPeriods, (product, index) => {
            const {
              [testResourceIndex]: { [index]: { ResourcePeriodProducts, ResourceIndex } = {} } = {}
            } = resourcePeriods
            const resourceBasedProduct =
              _.find(ResourcePeriodProducts, {
                ProductIndex: childrenIndex
              }) || {}
            const { PegTags = [], Production } = resourceBasedProduct
            const hasProduction = Production > 0
            const {
              [getCoverKey(cover, 'Cover')]: Cover = 0,
              [getCoverKey(minCover, 'MinCover')]: MinCover = 0
            } = product

            const {
              PeriodIndex: periodIndex,
              ProductIndex: productIndex,
              FrozenEndPeriodName: frozenEndPeriodName,
              IssueValues
            } = product
            const { Name: periodName } = periods[index] || {}

            const { [index]: { IsGrandTotal } = {} } = periods

            const isFrozenWeek = frozenEndPeriodName === periodName
            let currentEnabledProductIssueIndex = 0
            if (productIssueList.length && !isProductProductInfo) {
              const { issueIndex = 0 } = productIssueList[currentProductIssueIndex]
              currentEnabledProductIssueIndex = issueIndex || 0
            }

            const issueValue = product.IssueValues[currentEnabledProductIssueIndex]
            const maxIssueValue = maxProductIssueValues[currentEnabledProductIssueIndex] || 0
            const issueTableName = productIssueList[currentProductIssueIndex]?.tableName

            const itemSizeIssue = getIssueSize(issueValue, maxIssueValue, issueTableName, 23)
            const { color } = productIssueSettings[currentProductIssueIndex] || {}

            const coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
            const { backgroundColor: borderTopColor = 'transparent' } = getCoverStyle(
              Cover,
              MinCover,
              ...coverViolations
            )

            const cellCoverStyle = isProductProductInfo ? { borderTopColor } : {}
            const hasCoverViolation = borderTopColor !== coverColors.green
            const { Id: periodId, DisplayName: periodDisplayName } = periods[periodIndex] ?? {}
            const { Id: productId, Name: productName } = products[productIndex] ?? {}

            const shiftTonnage =
              resourceProducts?.[ResourceIndex]?.[productIndex]?.[0]?.ShiftTonnage
            resourceBasedProduct.ShiftTonnage = shiftTonnage

            return (
              <Box
                year
                key={index}
                path={path}
                globalIndex={this.props.globalIndex}
                hasProduction={hasProduction}
                isConsumedBy={isConsumedBy}
                isFrozenWeek={isFrozenWeek}
                isGrandTotal={IsGrandTotal}
                isIssue={!isProductProductInfo}
                locationNames={locationNames}
                paintOnClick={paintOnClick}
                isProductPeriod={true}
                pegTags={PegTags}
                periodId={periodId}
                periodIndex={periodIndex}
                periodName={periodName}
                periodDisplayName={periodDisplayName}
                productId={productId}
                productIndex={productIndex}
                productName={productName}
                testResourceId={resourceId}
                testResourceIndex={testResourceIndex}
                testResourceName={resourceName}
                type={type}
                onPeriodChange={this.props.onPeriodChange}
              >
                {!isProductProductInfo ? (
                  itemSizeIssue > 0 && (
                    <i
                      style={{
                        height: itemSizeIssue,
                        width: itemSizeIssue,
                        backgroundColor: color
                      }}
                    />
                  )
                ) : (
                  <>
                    {hasCoverViolation && (
                      <span key="badge" className="product-info-badge" style={cellCoverStyle} />
                    )}
                    <div className=""></div>
                    {_.map(
                      isMetricOrderSorted
                        ? _.sortBy(currentProductionInfoIndex)
                        : currentProductionInfoIndex,
                      (productionInfoIndex) =>
                        this.getBoxValues(
                          product,
                          productionInfoIndex,
                          true,
                          productId,
                          periodId,
                          periodIndex,
                          resourceBasedProduct,
                          isFrozenWeek
                        )
                    )}
                  </>
                )}
              </Box>
            )
          })}

        {type !== 'Resource' &&
          type !== 'Product' &&
          (!_.isEmpty(hierarchyResourcePeriodProducts) && isAggregatedValuesEnabled
            ? _.map(
                hierarchyResourcePeriodProducts[childrenIndex === -1 ? 0 : childrenIndex],
                (hierarchyResourcePeriodProduct, index) => {
                  currentProductionInfoIndex = isMetricOrderSorted
                    ? _.sortBy(currentProductionInfoIndex)
                    : currentProductionInfoIndex

                  const { [childrenIndex]: { [index]: hierarchyProductPeriod = {} } = {} } =
                    HierarchyProductPeriods
                  const {
                    [getCoverKey(cover, 'Cover')]: Cover = 0,
                    [getCoverKey(minCover, 'MinCover')]: MinCover = 0,
                    IssueValues
                  } = hierarchyProductPeriod
                  const coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
                  const { backgroundColor: borderTopColor = 'transparent' } = getCoverStyle(
                    Cover,
                    MinCover,
                    ...coverViolations
                  )
                  const cellCoverStyle = { borderTopColor }
                  const hasCoverViolation = borderTopColor !== coverColors.green
                  const { IsGrandTotal = true } = periods[index] || {}
                  const { ProductIndex } = hierarchyResourcePeriodProduct ?? {}
                  const { Id: periodId, Name: periodName } = periods[index]
                  return (
                    <Box
                      key={index}
                      year
                      globalIndex={this.props.globalIndex}
                      locationNames={locationNames}
                      isGrandTotal={IsGrandTotal}
                      productIndex={ProductIndex}
                      testResourceId={resourceId}
                      testResourceName={resourceName}
                      testResourceIndex={testResourceIndex}
                      periodName={periodName}
                      periodId={periodId}
                      periodIndex={Number(index)}
                    >
                      {[
                        hasCoverViolation && (
                          <span key="badge" className="product-info-badge" style={cellCoverStyle} />
                        ),
                        _.map(currentProductionInfoIndex, (issueIndex) =>
                          this.getAggregationValue(
                            hierarchyResourcePeriodProduct,
                            issueIndex,
                            index
                          )
                        )
                      ]}
                    </Box>
                  )
                }
              )
            : _.map(resourcePeriods[0], (_, index) => {
                return (
                  <Box
                    globalIndex={this.props.globalIndex}
                    key={index}
                    locationNames={locationNames}
                    testResourceId={resourceId}
                    testResourceName={resourceName}
                    testResourceIndex={testResourceIndex}
                    periodId={periodId}
                    periodName={periodName}
                    periodIndex={Number(index)}
                  />
                )
              }))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ResourcePeriodsHierarchy: state.resourcePeriodHierarchy,
  resourcePeriodHierarchy: state.resourcePeriodHierarchy,
  legendState: state.legend.resourceLegendState,
  legendData: state.legend.resourceLegendData,
  productLegendData: state.legend.productLegendData,
  productLegendState: state.legend.productLegendState,
  periodDates: state.resourcePeriodHierarchy.periodDates,
  resourceIndex: state.clickedProductInfo.resourceIndex,
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
  isProductionResourceBased: state.resourcePlan.isProductionResourceBased,
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes,
  selectedSession: state.resourcePlan.selectedSession
})

const ConnectedResourceRowItemInfo = connect(mapStateToProps)(ResourceRowItemInfo)
export default React.memo(ConnectedResourceRowItemInfo)
