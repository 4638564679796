import React from 'react'

export default function Horizontal(props) {
  const {
    settings: {
      flexDirection,
      alignItems,
      justifyContent,
      titleVal,
      titleSize,
      descriptinSize,
      textColor,
      progressBarColor,
      animationDuration,
      subTitleSize,
      subtitleVal,
      padding
    } = {},
    data: { percentage, description } = {},
    combined = false
  } = props

  const style = {
    flexDirection,
    alignItems,
    justifyContent
  }

  return (
    <div className="chart-wrapper -horizontal" style={style}>
      {titleVal && !combined && <h1 style={{ fontSize: `${titleSize}em` }}>{titleVal}</h1>}
      <div className="horizontal-progress" style={{ ...style, padding: `${padding}em` }}>
        {percentage && (
          <h2 style={{ fontSize: `${descriptinSize}em` }}>{description || `${percentage}%`}</h2>
        )}
        <div
          className="horizontal-ctn"
          style={{ margin: `${padding}em 0`, backgroundColor: textColor }}
        >
          <div style={{ width: `${percentage}%` }}>
            <span
              style={{
                animationDuration: `${animationDuration}ms`,
                backgroundColor: progressBarColor
              }}
            />
          </div>
        </div>
      </div>
      {subtitleVal && !combined && <p style={{ fontSize: `${subTitleSize}em` }}>{subtitleVal}</p>}
    </div>
  )
}
