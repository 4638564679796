/* global Ext, _ */

Ext.define('Assortment.cmp.SimilarOptionsChart', {
  extend: 'Chart.ux.Highcharts',
  alias: 'widget.SimilarOptionsChart',
  config: {
    store: null
  },
  series: [],
  setStore: function (store) {
    var me = this
    if (store != null) {
      var chartStore = new Ext.data.ArrayStore({})
      store.on('load', function () {
        var similarOptions = store.getDatas()

        if (similarOptions.length === 0) {
          return
        }
        var merchDates = similarOptions[0].merchDates

        // Get the planned sale period of the real product
        var startDate = store.realProduct.StartDate
        _.forEach(store.realProduct.SegmentOptions, (segmentOption) => {
          var segmentOptionStartDate = new Date(segmentOption.StartDate)
          if (
            segmentOption.Enabled &&
            segmentOption.NumberOfActiveStores > 0 &&
            startDate > segmentOptionStartDate
          ) {
            startDate = segmentOptionStartDate
          }
        })

        var shiftedStartDate = Ext.Date.subtract(startDate, Ext.Date.DAY, 0)
        var shiftedStartDateString = Ext.Date.format(shiftedStartDate, 'Y-m-d\\TH:i:s')
        var startDateMerchDate = merchDates[shiftedStartDateString]

        var endDate = store.realProduct.EndDate
        _.forEach(store.realProduct.SegmentOptions, (segmentOption) => {
          var segmentOptionEndDate = new Date(segmentOption.EndDate)
          if (
            segmentOption.Enabled &&
            segmentOption.NumberOfActiveStores > 0 &&
            endDate < segmentOptionEndDate
          ) {
            endDate = segmentOptionEndDate
          }
        })

        var shiftedEndDate = Ext.Date.subtract(endDate, Ext.Date.DAY, 0)
        var shiftedEndDateString = Ext.Date.format(shiftedEndDate, 'Y-m-d\\TH:i:s')

        var endDateMerchDate = merchDates[shiftedEndDateString]

        var started = false
        var finished = false
        var distinctMerchDates = []
        _.forEach(merchDates, (value, key) => {
          if (_.indexOf(distinctMerchDates, value) < 0) {
            distinctMerchDates.push(value)
          }
        })

        distinctMerchDates = _.sortBy(distinctMerchDates)

        // // Get the planned sale period of the real product as merch dates
        var productMerchDates = []
        _.forEach(distinctMerchDates, (productMerchDate) => {
          if (productMerchDate === startDateMerchDate) {
            started = true
          }
          if (started && !finished) {
            productMerchDates.push(productMerchDate)
          }
          if (productMerchDate === endDateMerchDate) {
            finished = true
          }
        })

        // Create series
        similarOptions = _.filter(similarOptions, { isEnabled: true })
        var series = _.map(similarOptions, (option) => {
          const series = {
            name: option.description || option.name,
            dataIndex: option.name,
            marker: {
              radius: 2
            }
          }
          if (option.similarityDesc === 'Forecast') {
            series.color = 'black'
            series.dashStyle = 'ShortDash'
            series.lineWidth = 3
          }
          return series
        })

        // Combine sales histories of options
        var data = []
        var combinedSalesData = {}

        _.transform(
          similarOptions,
          (res, item) => {
            _.forEach(item.weeklySales, (sale, salesDate) => {
              let findedMerchDates = merchDates[salesDate]
              if (!_.has(combinedSalesData, findedMerchDates)) {
                combinedSalesData[findedMerchDates] = {}
              }
              combinedSalesData[findedMerchDates][item.name] = sale
            })
          },
          {}
        )

        // If some merch dates do not exist in the sales data periods, add them
        _.forEach(productMerchDates, (merchDate) => {
          if (!_.has(combinedSalesData, merchDate)) {
            combinedSalesData[merchDate] = {}
          }
        })

        _.forEach(combinedSalesData, (value, key) => {
          var record = {
            salesDate: key,
            sortKey: key
          }
          _.forEach(similarOptions, (option) => {
            record[option.name] = _.has(value, option.name) ? value[option.name] : null
          })
          data.push(record)
        })

        data = _.sortBy(data, ['sortKey'])

        var startDateMerchDateIndex = _.findIndex(data, (row) => {
          return row['salesDate'] === startDateMerchDate
        })

        var endDateMerchDateIndex = _.findIndex(data, (row) => {
          return row['salesDate'] === endDateMerchDate
        })

        // Clear series before setting new data. This solves performance problems
        if (me.chart) {
          while (me.chart.series.length > 0) {
            me.chart.series[0].remove(false)
          }
        }

        me.addSeries(series)
        chartStore.loadData(data)
        me.draw()

        if (me.chart) {
          var xAxis = me.chart.xAxis[0]

          xAxis.addPlotBand({
            from: startDateMerchDateIndex,
            to: endDateMerchDateIndex,
            color: '#FCFFC5'
          })
        }
      })
      this.bindStore(chartStore, true)
    }

    return store
  },
  xField: 'salesDate',
  chartConfig: {
    chart: {
      type: 'spline',
      zoomType: 'xy'
    },
    colors: [
      '#7cb5ec',
      '#90ed7d',
      '#f7a35c',
      '#8085e9',
      '#f15c80',
      '#e4d354',
      '#2b908f',
      '#f45b5b',
      '#91e8e1',
      '#582425'
    ],
    title: {
      text: 'Sales History',
      style: { color: '#333333', fontSize: '15px' }
    },
    xAxis: {
      type: 'category',
      tickLength: 5,
      labels: {
        enabled: true,
        padding: 2
      }
    },
    yAxis: {
      title: {
        text: 'Avg. Wk. Sales per Store'
      }
    },
    tooltip: {
      valueDecimals: 2,
      shared: true
    },
    exporting: {
      buttons: {
        contextButton: {
          align: 'left'
        }
      }
    },
    legend: {
      itemStyle: {
        fontSize: 11
      },
      padding: 2
    },
    credits: {
      text: '',
      href: '',
      position: {
        align: 'right',
        y: -5
      },
      style: {
        fontSize: '7pt'
      }
    }
  }
})
