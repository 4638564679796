Ext.define('MDM.src.view.globalapp.Panel.Geo.Panel.Map', {
  extend: 'MDM.src.ux.Leaflet.Map',
  xtype: 'globalapp-geo-map',
  config: {
    marker: false,
    hasLatLong: false,
    record: false
  },
  applyRecord: function (record) {
    var me = this,
      map = this.getMap()
    if (!record || !record.isModel) {
      map.on(
        'click',
        Ext.bind(function (e) {
          //me.fireEvent('layerclick', me, e)
          me.addMarker(e)
        }, me)
      )
    } else {
      me.setHasLatLong(true)
      var marker = L.marker([record.get('Latitude'), record.get('Longitude')], {
        draggable: true,
        title: 'Resource location',
        alt: 'Resource Location',
        riseOnHover: true
      })
        .addTo(map)
        .bindPopup('You can drag marker...')
        .openPopup()
      marker.on('dragend', function (ev) {
        var chagedPos = ev.target.getLatLng()
        record.set('Latitude', chagedPos['lat'])
        record.set('Longitude', chagedPos['lng'])
        //  me.fireEvent('dragend', me, chagedPos)
      })
      Ext.defer(function () {
        map.setView([record.get('Latitude'), record.get('Longitude')], 5)
      }, 700)
    }
  },
  addMarker: function (e) {
    var me = this,
      map = this.getMap()
    if (!me.getHasLatLong()) {
      me.fireEvent('dragend', me, e.latlng)
      this.setMarker([e.latlng.lat, e.latlng.lng])
    }
  }
})
