import React, { useRef, DragEvent, CSSProperties } from 'react'
import Card from './Card'
import { IIndexProps } from './ScenarioMove.types'
import { isBefore, getElementIndex } from '../../Util'

const style: CSSProperties = { cursor: 'pointer', userSelect: 'none' }

const ScenarioMove = ({
  activeScenarioList,
  BaselineScenarioId,
  cellMetricLength,
  getActiveGroupClass,
  isWithBaseVisible,
  isWithGroupedVisible,
  onActualizeChange,
  onRemoveScenario,
  onScenarioClick,
  onMoveScenario
}: IIndexProps) => {
  const selected = useRef<HTMLDivElement | null>(null)

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    const eventTarget = event.target as HTMLDivElement

    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/plain', '')

    selected.current = eventTarget

    if (eventTarget.className.indexOf('slvy-ui-row-container') === -1) {
      selected.current = eventTarget.closest('.slvy-ui-row-container')
    }
  }

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    const eventTarget = event.target as HTMLDivElement

    let destination = eventTarget

    if (eventTarget.className.indexOf('slvy-ui-row-container') === -1) {
      destination = eventTarget.closest('.slvy-ui-row-container')!
    }

    const selector = '.scenarioEditor .slvy-ui-row-container'

    let targetIndex = getElementIndex(destination, selector)
    const selectedIndex = getElementIndex(selected.current, selector)

    const selectedItem = [...activeScenarioList].find((_item, index) => index === selectedIndex)
    if (typeof selectedItem === 'undefined') {
      return
    }

    const newItems = [...activeScenarioList].filter((_item, index) => index !== selectedIndex)

    if (isBefore(selected.current, destination) && targetIndex !== 0) {
      targetIndex -= 1
    }

    newItems.splice(targetIndex, 0, selectedItem)

    onMoveScenario(newItems)
  }

  const onDragEnd = () => {
    selected.current = null
  }

  return (
    <div>
      {activeScenarioList.map((item, index) => {
        const activeGroupClass = getActiveGroupClass(index)
        return (
          <div
            key={item.Id}
            className="slvy-ui-row-container"
            draggable="true"
            style={style}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDragStart={onDragStart}
          >
            <Card
              BaselineScenarioId={BaselineScenarioId}
              activeGroupClass={activeGroupClass}
              cellMetricLength={cellMetricLength}
              data={item}
              dataIndex={index}
              isWithBaseVisible={isWithBaseVisible}
              isWithGroupedVisible={isWithGroupedVisible}
              onActualizeChange={onActualizeChange}
              onRemoveScenario={onRemoveScenario}
              onScenarioClick={onScenarioClick}
            />
          </div>
        )
      })}
    </div>
  )
}

export default ScenarioMove
