export default `({
    controller: {
        doClose: function () {
            this.getView().ownerCt.close();
        },
        createCont: function (record, newStatus) {
                        var me = this;
            Ext.Msg.show({
                title: tt('Onay'),
                width: 500,
                msg: tt('Sözleşme oluşturmak istediğinizden emin misiniz? <br><br>'),
                buttonText: {
                    yes: tt('Evet'),
                    no: tt('Hayır')
                },
                buttons: Ext.Msg.YESNO,
                icon: Ext.MessageBox.QUESTION,
                fn: function (response) {
                    if (response === 'yes') {
                        me.setLoading(tt('Yükleniyor'));
                        me.setLoading(false);
                        me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                    }
                }
            })
        },
    },
    xtype: 'grid',
    reference: 'contractGrid',
    layout: 'fit',
    bind: {
        store: '{gridStore}'
    },
    listeners: {
        render: 'onRender'
    },
    features: [ {
        ftype: 'summary',
        dock: 'bottom'
    }],
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [
            {
                xtype: 'button',
                text: tt('Export to Excel'),
                docked: 'right',
                handler: function (btn) {
                    var grid = this.ownerCt.ownerCt
                    grid.saveDocumentAs({
                        type: 'xlsx',
                        title: tt('Sözleşme Oluştur'),
                        fileName: tt('Sözleşme Oluştur')+'.xlsx',
                    });
            }
        }],
    }],
    plugins: [{
        ptype: 'gridexporter'
    }],
    columns: [{
        text: tt('DOC_TYPE'),
        dataIndex: 'DOC_TYPE',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }, {
        text: tt('PUR_GROUP'),
        dataIndex: 'PUR_GROUP',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }, {
        text: tt('CRE_DATE'),
        dataIndex: 'CREATE_DATE',
        width: 90,
        align: 'right',
        locked: true,
        xtype: 'datecolumn',
        format: tt('d-m-Y')
    }, {
        text: tt('VPER_START'),
        dataIndex: 'VPER_START',
        width: 90,
        align: 'right',
        locked: true,
        xtype: 'datecolumn',
        format: tt('d-m-Y')
    }, {
        text: tt('VPER_END'),
        dataIndex: 'VPER_END',
        width: 90,
        align: 'right',
        locked: true,
        xtype: 'datecolumn',
        format: tt('d-m-Y')
    }, {
        text: tt('MATERIAL'),
        dataIndex: 'MATERIAL',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }, {
        text: tt('PLANT'),
        dataIndex: 'PLANT',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }, {
        text: tt('TARGET_QTY'),
        dataIndex: 'TARGET_QTY',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true,
        summaryType: function (records, property, root, begin, end) {
            var n = property.length,
                i, sum, v
            sum = 0;
            for (i = 0; i < n; ++i) {
                v = property[i]
                sum = sum + v;
            }
            return sum;
        },
    }, {
        text: tt('PO_UNIT'),
        dataIndex: 'PO_UNIT',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }, {
        text: tt('COLLECT_NO'),
        dataIndex: 'COLLECT_NO',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }, {
        text: tt('VENDOR'),
        dataIndex: 'VENDOR',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }, {
        text: tt('NET_PRICE'),
        dataIndex: 'NET_PRICE',
        width: 120,
        align: 'right',
        //xtype: 'numbercolumn',
        locked: true
    }],
})
`
