import { useState, useEffect, useMemo, ChangeEvent, FormEvent } from 'react'
import {
  FormControl,
  FormLabel,
  Form,
  Button,
  ButtonToolbar,
  DropdownButton,
  Dropdown
} from 'react-bootstrap'
import { ColorResult, SketchPicker } from 'react-color'
import { slvyToast } from '@/components'
import getAdminForm, { getBgColorCmpStyle } from '@/utils/admin-container.js'
import { defaultForm, defaultColor, defaultThemes } from './constants'
import { AdminContainerProps } from '@/elements/Container/Admin/Admin.types'
import { FormState, ThemeId } from './Admin.types'

const AdminContainer = (props: AdminContainerProps) => {
  const { propertyEditorFields, settings, onChangeSettings } = props

  const getInitialForm = useMemo(() => {
    return getAdminForm(propertyEditorFields, settings, defaultForm)
  }, [propertyEditorFields, settings])

  const [form, setForm] = useState<FormState>(getInitialForm)
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)

  const {
    Background = '',
    background,
    borderRadius,
    isBorderVisible,
    splitterWidth,
    theme = '0'
  } = form

  useEffect(() => {
    setForm(getInitialForm)
  }, [getInitialForm])

  const handleChangeSplitterWidth = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      currentTarget: { value }
    } = event

    const width = parseInt(value, 10)
    setForm((prevForm) => ({ ...prevForm, splitterWidth: width }))
  }

  const handleChangeBorderRadius = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      currentTarget: { value }
    } = event

    const radius = parseInt(value, 10)
    setForm((prevForm) => ({ ...prevForm, borderRadius: radius }))
  }

  const handleChangeBackground = (color: ColorResult) => {
    const { rgb } = color

    setForm((prevForm) => ({ ...prevForm, background: rgb }))
  }

  const handleResetBackground = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()

    setForm((prevForm) => ({ ...prevForm, background: defaultColor }))
  }

  const handleChangeTheme = (themeId: ThemeId) => {
    setForm((prevForm) => ({ ...prevForm, theme: themeId }))
  }

  const isValid = () => {
    const isSplitterWidthNaN = isNaN(splitterWidth)
    const isSplitterWidthLessThanZero = splitterWidth < 0

    return !isSplitterWidthNaN && !isSplitterWidthLessThanZero
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (isValid()) {
      onChangeSettings({
        ...settings,
        ...form
      })
      slvyToast.success({ message: 'Saved successfully' })
    }
  }

  const handleChangeBorderVisibility = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    setForm((prevForm) => ({ ...prevForm, isBorderVisible: checked }))
  }

  const themes = defaultThemes
  const { name: themeName = '' } = themes[theme] || themes[0]

  const color = background || Background || defaultColor
  const style = getBgColorCmpStyle(color)

  return (
    <Form noValidate className="containerForm" onSubmit={handleSubmit}>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Themes</FormLabel>
        <ButtonToolbar className="w-100">
          <DropdownButton
            align="end"
            className="w-100"
            id="dropdown-theme"
            size="sm"
            title={themeName}
            variant="outline-dark"
            onSelect={handleChangeTheme}
          >
            {themes.map(({ name, id }, key) => {
              const itemSettings = {
                key,
                eventKey: id,
                ...(id === theme ? { active: true } : {})
              }

              return (
                <Dropdown.Item className="w-100" {...itemSettings}>
                  {name}
                </Dropdown.Item>
              )
            })}
          </DropdownButton>
        </ButtonToolbar>
      </Form.Group>
      <Form.Group className="position-relative mb-2">
        <FormLabel className="fs-sm">Background Color</FormLabel>
        <br />
        {/* TODO: replace with SlvyColorPicker */}
        <div style={style.swatch} onClick={() => setDisplayColorPicker(true)}>
          <div style={style.color} />
          <div style={style.swatch.clear}>
            <i
              style={style.swatch.clear.icon}
              className="fa fa-times"
              onClick={handleResetBackground}
            />
          </div>
        </div>
        {displayColorPicker ? (
          <div style={style.popover}>
            <div style={style.cover} onClick={() => setDisplayColorPicker(false)} />
            <SketchPicker color={color} onChange={handleChangeBackground} />
          </div>
        ) : null}
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Margin</FormLabel>
        <FormControl
          isInvalid={!isValid()}
          size="sm"
          type="number"
          value={splitterWidth}
          onChange={handleChangeSplitterWidth}
        />
        <Form.Control.Feedback type="invalid">Margin cannot be less than 0</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm mb-0">
          <Form.Check
            checked={isBorderVisible}
            className="mh-auto mb-0 form-check-sm"
            id="borderVisible"
            label="Border Visible"
            type="checkbox"
            onChange={handleChangeBorderVisibility}
          />
        </FormLabel>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Border Radius</FormLabel>
        <FormControl
          placeholder="0"
          size="sm"
          type="number"
          value={borderRadius}
          onChange={handleChangeBorderRadius}
        />
      </Form.Group>
      <Button size="sm" type="submit" variant="success">
        Save
      </Button>
    </Form>
  )
}

export default AdminContainer
