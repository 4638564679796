import * as actionTypes from './actionTypes'

export const saveSetFilterRemote = (value) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storeSetFilterRemote(value))
      resolve()
    })
  }
}

const storeSetFilterRemote = (options) => {
  return {
    type: actionTypes.STORE_SET_FILTER_REMOTE,
    payload: options.payload
  }
}
