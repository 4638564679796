Ext.define('tuiv2.cmp.SnapShotWindow', {
  extend: 'Ext.window.Window',
  xtype: 'tuiv2-snapshotwindow',
  alias: 'widget.snapshotwindow',
  controller: 'tuiv2MainController',
  modal: true,
  layout: 'border',
  closeAction: 'hide',
  ui: 'window-inverse',
  height: 500,
  width: 800,
  title: 'Snapshot',
  referenceHolder: true,
  items: [
    {
      xtype: 'panel',
      region: 'center',
      layout: 'fit',
      margin: 10,
      overflowY: 'auto',
      items: [
        {
          xtype: 'tuiv2SnapShotGrid',
          reference: 'tuiv2SnapShotGrid'
        }
      ]
    }
  ]
})
