Ext.define('po.cmp.SapRecordsGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'saprecordsgrid',
  alias: 'widget.SapRecordsGrid',
  header: false,
  bodyStyle: {
    background: '#fff'
  },
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView(),
      grouping = normalView.getFeature('grouping')
    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    }
  ],
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    }
  ],
  columns: [
    {
      dataIndex: 'urunKodu',
      text: 'Ürün Kodu',
      bind: {
        text: '{i18n.MainGrid_Column_urunkodu}'
      },
      width: 115
    },
    {
      dataIndex: 'urunAciklamasi',
      text: 'Ürün',
      bind: {
        text: '{i18n.MainGrid_Column_urunAciklamasi}'
      },
      width: 230
    },
    {
      dataIndex: 'depoSiparisKoli',
      text: 'Öneri',
      bind: {
        text: '{i18n.MainGrid_Column_depoSiparisKoli}'
      },
      align: 'right',
      width: 80
    },
    {
      xtype: 'numbercolumn',
      sortable: true,
      text: 'Revize',
      dataIndex: 'degisenOneriKoli',
      bind: {
        text: '{i18n.MainGrid_Column_degisenOneriKoli}'
      },
      renderer: function (v) {
        return (
          "<span style='font-size:1em;color:#FF8600;font-weight:600'>" +
          Ext.util.Format.number(v, '0') +
          '</span>'
        )
      },
      width: 80,
      align: 'right'
    },
    {
      dataIndex: 'sebepKodu',
      text: 'sebepKodu',
      bind: {
        text: '{i18n.EditorView_Sebep}'
      },
      align: 'left',
      width: 150,
      renderer: function (v) {
        if (v == 0) return '-'
        var pomainView = this.up('pomainview')
        var viewModel = pomainView.getViewModel()
        var reasonDictionary = viewModel.get('reasonDictionary')

        return '<span style="font-weight:bold">' + reasonDictionary[v] + '</span>'
      }
    }
  ]
})
