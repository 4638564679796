import { memo, useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import SlvyAutosuggest, { SuggestionsContainer } from '../../../../../../components/SlvyAutosuggest'
import { ISearchCallbackParams } from '../../Assignees.types'
import { containerStyle, searchOption } from '../../constants'
import { convertDataForSearch, getFilteredVariables } from '../../helpers'
import { IVariables } from '../../../Common.types'
import { IVariablesProps, ISearchResult } from './Variables.types'
import NotFound from '../NotFound'
import Variable from './Variable'
import { objectKeys } from '@/ts'
import styles from './index.module.scss'

const emptyArray: any[] = []

const leadText = (
  <>
    If you are looking for a specific variable please enter the variable <strong>name</strong> e.g.
    refreshKey
  </>
)

function sortObjectByKeys<T extends object>(data: T): T {
  const sortedObject: Partial<T> = {}
  objectKeys(data)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .forEach((key) => {
      sortedObject[key] = data[key]
    })

  return sortedObject as T
}

const Variables = ({
  selectedEventVariables,
  selectedMethodVariables,
  onVariableRemove,
  onVariableAdd,
  variables
}: IVariablesProps) => {
  const [searchOptions, setSearchOptions] = useState({ ...searchOption })

  const handleSuggestionSelected = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: true
    })
  }, [])

  const handleSuggestionNotFound = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: true,
      isSelected: true
    })
  }, [])

  const handleQueryChange = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: false
    })
  }, [])

  const filteredVariables = useMemo(
    () => getFilteredVariables(variables, searchOptions),
    [variables, searchOptions]
  )

  const variableSearchData = useMemo(() => {
    const result: ISearchResult[] = []
    Object.keys(filteredVariables).forEach((key) => {
      const item = filteredVariables[key]
      Object.keys(item).forEach((itemKey) => {
        const config = {
          general: {
            name: itemKey
          }
        }
        result.push({ type: key, config })
      })
    })
    return convertDataForSearch(result)
  }, [filteredVariables])

  const isVariableNotFound = searchOptions.query && searchOptions.notFound

  const renderSuggestionsContainer = useCallback(
    (suggestionsContainerProps: any) => (
      <SuggestionsContainer {...suggestionsContainerProps} containerStyle={containerStyle} />
    ),
    []
  )

  return (
    <>
      <SlvyAutosuggest
        data={variableSearchData}
        field="variableOptions"
        placeholder="Search"
        renderSuggestionsContainer={renderSuggestionsContainer}
        size="sm"
        onQueryChange={handleQueryChange}
        onSuggestionNotFound={handleSuggestionNotFound}
        onSuggestionSelected={handleSuggestionSelected}
      />
      <div
        className="overflow-y-auto pe-1"
        data-testid="assignees-variables"
        style={{ height: 565 }}
      >
        {isVariableNotFound ? (
          <NotFound
            isVisibleIcon={false}
            leadText={leadText}
            query={searchOptions.query}
            tags={emptyArray}
          />
        ) : (
          <>
            {_.map(filteredVariables, (variableGroup: IVariables, variableGroupKey) => {
              let classNames: string[] = [styles.variablesList, 'mb-2']
              switch (variableGroupKey) {
                case 'menu':
                  classNames.push('menuVariableItem')
                  break
                case 'global':
                  classNames.push('globalVariableItem')
                  break
                case 'custom':
                  classNames.push('customVariableItem')
                  break
                default:
                  classNames = []
                  break
              }

              const hasVariableGroups = Object.keys(variableGroup ?? {}).length > 0

              if (!hasVariableGroups) {
                return <span key={variableGroupKey} />
              }

              const sortedVariableGroup = sortObjectByKeys(variableGroup)

              return (
                <ul key={variableGroupKey} className={classNames.join(' ')}>
                  {_.map(sortedVariableGroup, ({ count }, variable) => {
                    const isSelected =
                      selectedEventVariables.includes(variable) &&
                      selectedMethodVariables.includes(variable)

                    return (
                      <Variable
                        key={`${variable + count}`}
                        count={count}
                        isSelected={isSelected}
                        variable={variable}
                        onVariableAdd={onVariableAdd}
                        onVariableRemove={onVariableRemove}
                      />
                    )
                  })}
                </ul>
              )
            })}
          </>
        )}
      </div>
    </>
  )
}

export default memo(Variables)
