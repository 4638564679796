import Highcharts from 'highcharts'
import {
  BarFormatterParams,
  ColumnFormatterParams,
  LabelFormatterParams,
  MarkerFormatterParams
} from 'ag-grid-community'
import {
  SparklineTypeOptions,
  GetLineMarkerFormatterProps,
  GetSparklineOptionsProps,
  GetSparklineOptionsReturnType
} from './sparkline.types'
import { SparklineOptions } from '../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'
import { ProgressOptions } from '../../types'
import { PROGRESS_LABEL_PLACEMENT } from '../../constants'

const createMonochromeColors = (
  color: ProgressOptions['monochrome']['backColor'],
  brighten: ProgressOptions['monochrome']['brighten']
) => {
  if (!color || !color?.length) {
    return []
  }

  const colors = []

  for (let i = 0; i < 10; i += 1) {
    colors.push(new Highcharts.Color(color).brighten((i - brighten) / 9).get())
  }

  return colors
}

const getProgressColumnOptions = (progressOptions: ProgressOptions) => {
  const {
    textColor,
    monochrome: { backColor, isEnabled, brighten },
    cellStyle = {}
  } = progressOptions

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const placement = PROGRESS_LABEL_PLACEMENT[cellStyle?.textAlign ?? 'left']

  return {
    type: 'bar',
    label: {
      enabled: true,
      // #000 is --ag-data-color that comes from ag
      color: textColor,
      fontSize: 10,
      placement,
      formatter: ({ value }: LabelFormatterParams) => {
        const isValueString = typeof value === 'string'
        const newValue = isValueString ? parseFloat(value as string) : value
        if (!Number.isNaN(newValue)) {
          return `${Math.round(newValue as number)}%`
        }

        return ''
      }
    },
    paddingOuter: 0,
    padding: {
      top: 0,
      bottom: 0
    },
    valueAxisDomain: [0, 100],
    axis: {
      strokeWidth: 0
    },
    formatter: ({ yValue }: BarFormatterParams) => {
      if (!isEnabled) {
        let fillColor = '#195176' // 60-100
        if (yValue <= 20) {
          fillColor = '#4fa2d9' // 0-20
        } else if (yValue < 60) {
          fillColor = '#277cb5' // 20-60
        }
        return {
          fill: fillColor
        }
      }

      const colors = createMonochromeColors(backColor, brighten).reverse()
      const calculatedYValue = Math.round(yValue / 10)
      const colorIndex = calculatedYValue >= 10 ? 9 : calculatedYValue

      return {
        fill: colors[colorIndex]
      }
    }
  }
}

const getLineMarkerFormatter = ({ params, sparklineOptions }: GetLineMarkerFormatterProps) => {
  const { highlighted, last, min, max } = params
  const { maxSpotColor, minSpotColor, spotColor, highlightColor } = sparklineOptions
  let markerColor = ''
  let markerSize = 0

  if (highlighted) {
    markerColor = highlightColor
    markerSize = 5
  } else if (last) {
    markerColor = spotColor
    markerSize = 3
  } else if (max) {
    markerColor = maxSpotColor
    markerSize = 3
  } else if (min) {
    markerColor = minSpotColor
    markerSize = 3
  }

  return {
    size: markerSize,
    fill: markerColor,
    stroke: markerColor
  }
}

const getBarOptions = (sparklineOptions: SparklineOptions) => {
  const { columnColor, negBarColor, highlightColor, zeroColor } = sparklineOptions
  return {
    type: 'column',
    fill: columnColor,
    stroke: '#91cc75',
    highlightStyle: {
      fill: highlightColor
    },
    paddingInner: 0.1, // TODO: @v2 Add schema instead barSpacing and barWidth
    paddingOuter: 0.1,
    formatter: (params: ColumnFormatterParams) => {
      const { yValue, highlighted } = params
      let fillColor = columnColor
      if (highlighted) {
        fillColor = highlightColor
      } else if (yValue < 0) {
        fillColor = negBarColor
      } else if (yValue === 0) {
        fillColor = zeroColor
      }
      return { fill: fillColor }
    }
  }
}

const getDiscreteOptions = (sparklineOptions: SparklineOptions) => {
  const { highlightColor, lineColor, thresholdColor } = sparklineOptions

  return {
    type: 'line',
    line: {
      strokeWidth: 0
    },
    paddingInner: 0.5,
    padding: {
      top: 5,
      right: 1,
      bottom: 5,
      left: 1
    },
    marker: {
      strokeWidth: 0,
      shape: 'square',
      formatter: (params: MarkerFormatterParams) => {
        const { highlighted, yValue } = params
        let markerColor = lineColor
        if (highlighted) {
          markerColor = highlightColor
        } else if (yValue < 0) {
          markerColor = thresholdColor
        }
        return {
          size: highlighted ? 5 : 3,
          fill: markerColor
        }
      }
    }
  }
}

const getLineOptions = (sparklineOptions: SparklineOptions) => {
  const { lineWidth, lineColor } = sparklineOptions

  return {
    type: 'line',
    line: {
      stroke: lineColor,
      strokeWidth: lineWidth
    },
    padding: {
      top: 5,
      right: 1,
      bottom: 5,
      left: 1
    },
    marker: {
      strokeWidth: 1,
      formatter: (params: MarkerFormatterParams) =>
        getLineMarkerFormatter({ params, sparklineOptions })
    }
  }
}

const getSparklineOptions = ({
  columnType,
  sparklineOptions,
  progressOptions,
  cellStyle
}: GetSparklineOptionsProps): Partial<GetSparklineOptionsReturnType> => {
  const { type } = sparklineOptions
  const options: SparklineTypeOptions = {
    sparklineline: getLineOptions(sparklineOptions),
    sparklinebar: getBarOptions(sparklineOptions),
    sparklinediscrete: getDiscreteOptions(sparklineOptions),
    progress: getProgressColumnOptions({ ...progressOptions, cellStyle })
  }

  return columnType === 'progress' ? options.progress : options[type]
}

export {
  getBarOptions,
  getDiscreteOptions,
  getLineMarkerFormatter,
  getLineOptions,
  getProgressColumnOptions,
  getSparklineOptions
}
