import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type MapState = {
  isDemandCardOpen: boolean
  isLoadCardOpen: boolean
  loadCardLatLng: {
    lat: number
    lng: number
  }
  openLoadCardId: string
  openDemandCardId: string
}

const initialState: MapState = {
  isDemandCardOpen: false,
  isLoadCardOpen: false,
  loadCardLatLng: {
    lat: 0,
    lng: 0
  },
  openLoadCardId: '',
  openDemandCardId: ''
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setOpenLoadCardId(state, action: PayloadAction<MapState['openLoadCardId']>) {
      state.openLoadCardId = action.payload
    },
    setIsDemandCardOpen(state, action: PayloadAction<MapState['isDemandCardOpen']>) {
      state.isDemandCardOpen = action.payload
    },
    setLoadCardLatLng(state, action: any) {
      state.loadCardLatLng = { lat: action.payload.lat, lng: action.payload.lng }
    },
    setIsLoadCardOpen(state, action: PayloadAction<MapState['isLoadCardOpen']>) {
      state.isLoadCardOpen = action.payload
    },
    setOpenDemandCardId(state, action: PayloadAction<MapState['isDemandCardOpen']>) {
      state.isDemandCardOpen = action.payload
    }
  }
})

export default mapSlice
export type { MapState }
