import { fireEvent, screen, render } from '../../../utils/testUtils/test-utils.tsx'
import { SlvyToastContainer } from '../../SlvyToast'
import { placeholder } from './constants'
import QuickSearch from './index'
import { IQuickSearchProps, IListItem } from './QuickSearch.types'

export const getPageUrl = (
  catalogId: IQuickSearchProps['catalogId'],
  environment: IQuickSearchProps['environment'],
  id: IListItem['id'],
  parentMenuId: IListItem['parentMenuId']
) => {
  return `/${environment}/catalog/${catalogId}/store/0/menu/${parentMenuId}/page/${id}`
}

export const renderConnectedCmp = (props: IQuickSearchProps) => {
  return render(
    <>
      <SlvyToastContainer />
      <QuickSearch {...props} />
    </>
  )
}

export const renderTypedCmp = (props: IQuickSearchProps, query = 'demand') => {
  renderConnectedCmp(props)
  const input = screen.getByPlaceholderText(placeholder)! as HTMLInputElement
  input.focus()
  fireEvent.change(input, { target: { value: query } })
  return input
}
