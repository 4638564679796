import cx from 'classnames'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { IFavoriteMenusProps } from './FavoriteMenus.types'

const FavoriteMenus = ({ menus, environment, handleOnClick, storeIndex }: IFavoriteMenusProps) => {
  return (
    <ListGroupItem className="fav-list" data-testid="favorite-menus">
      <ListGroup>
        {_.map(menus, (o, index) => {
          return (
            <ListGroupItem
              key={o.item.id}
              className={cx({ 'text-decoration-line-through': !o.item.isVisible })}
              data-testid={`favorite-menus-list-group-item-${index}`}
            >
              <Link
                to={`/${environment}/catalog/${o.item.catalogId}/store/${storeIndex}/menu/${o.item.id}`}
                onClick={() => handleOnClick(o.item.id)}
              >
                <i className={o.item.iconClass} />
                <span className="nav-label">{o.item.name}</span>
              </Link>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </ListGroupItem>
  )
}

export default FavoriteMenus
