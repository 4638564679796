const similarProductsGridColumnsSchema = {
  similarProductsGridColumns: {
    type: 'array',
    title: 'Similar Products Grid Columns',
    options: {
      syncButton: true
    },

    items: {
      type: 'object',
      title: 'Columns',
      headerTemplate: '{{ self.name }}',
      properties: {
        name: {
          type: 'string',
          title: 'Column Name',
          description: 'Column Name is coming from the data, do not change this field.'
        },
        displayName: {
          type: 'string',
          title: 'Display Name'
        },
        isVisible: {
          type: 'boolean',
          title: 'Is Visible',
          default: true
        }
      }
    }
  }
}
const optionViewColumnsSchema = {
  optionViewColumns: {
    type: 'array',
    title: 'Option View Columns',
    options: {
      syncButton: true
    },

    items: {
      type: 'object',
      title: 'Columns',
      headerTemplate: '{{ self.name }}',
      properties: {
        name: {
          type: 'string',
          title: 'Column Name',
          description: 'Column Name is coming from the data, do not change this field.'
        },
        displayName: {
          type: 'string',
          title: 'Display Name'
        },
        isVisible: {
          type: 'boolean',
          title: 'Is Visible',
          default: true
        }
      }
    }
  }
}

export default () => {
  return {
    title: 'Assortment Planning',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          export: {
            type: 'boolean',
            title: 'Exportable',
            default: true,
            required: true
          }
        }
      },

      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },

      settings: {
        type: 'object',
        title: 'Plugin Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title'
          },
          subtitle: {
            type: 'string',
            title: 'Sub Title'
          },
          backgroundcolor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          fontcolor: {
            type: 'string',
            format: 'Color',
            title: 'Font Color',
            default: '#676a6c'
          },
          optionPriceEditable: {
            type: 'boolean',
            title: 'Option Price Editable',
            default: false,
            required: true
          },
          viewType: {
            type: 'string',
            title: 'View Type',
            enum: ['New View', 'Old View'],
            default: 'New View',
            required: true,
            description: 'Old View is the Assortment v1 view.It is recomended to use New View.'
          }
        }
      },

      timelineSettings: {
        type: 'array',
        title: 'Timeline Settings',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Aggregation Level {{self.aggregationLevelId}}',
          properties: {
            isDefault: {
              type: 'boolean',
              title: 'Default Aggregation',
              default: false,
              required: true
            },
            aggregationLevelId: {
              type: 'number',
              title: 'Aggregation Level Id'
            },
            map: {
              type: 'string',
              title: 'Map Key'
            },
            maxCount: {
              type: 'number',
              title: 'Timeline Max Count'
            }
          }
        }
      },

      formatting: {
        type: 'object',
        title: 'Formatting',
        properties: {
          currencySymbol: {
            type: 'string',
            title: 'Currency Symbol',
            default: '$',
            required: true
          },
          current: {
            type: 'string',
            title: 'Current',
            default: '0,0',
            required: true,
            description: 'You must use, dollar sign as a format currency symbol.'
          },
          decimal: {
            type: 'string',
            title: 'Decimal',
            default: '0,0.00',
            required: true,
            description: 'You must use, dollar sign as a format currency symbol.'
          },
          percentages: {
            type: 'string',
            title: 'Percentages',
            default: '0.00%',
            required: true
          }
        }
      },

      headerButtons: {
        type: 'array',
        title: 'Header Buttons',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Button {{self.name}}',
          properties: {
            name: {
              type: 'string',
              title: 'Name'
            },
            iconName: {
              type: 'string',
              title: 'Icon',
              format: 'IconSelector',
              default: 'fa-fw fa slvy-ui-icon-play'
            }
          }
        }
      },

      similarProductsHeaderButtons: {
        type: 'array',
        title: 'Similar Products Header Buttons',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Button {{self.name}}',
          properties: {
            name: {
              type: 'string',
              title: 'Name'
            },
            iconName: {
              type: 'string',
              title: 'Icon',
              format: 'IconSelector',
              default: 'fa-fw fa slvy-ui-icon-play'
            }
          }
        }
      },

      similarProductsGridColumns: similarProductsGridColumnsSchema.similarProductsGridColumns,

      optionViewGridSettings: {
        type: 'object',
        title: 'Option View Settings',
        properties: {
          optionFieldsEditable: {
            type: 'boolean',
            title: 'Fields Editable',
            default: true,
            required: true
          },
          optionViewColumns: optionViewColumnsSchema.optionViewColumns
        }
      }
    }
  }
}
