import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import _ from 'lodash'

class Textarea extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      value: _.isEmpty(props.component.value) ? '' : props.component.value
    }
  }

  handleChange(e) {
    const { component } = this.props
    this.props.onChange(component.sectionKey, component.fieldName, e.target.value)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: _.isEmpty(nextProps.component.value) ? '' : nextProps.component.value
    })
  }

  render() {
    const {
      isInvalid,
      validationCheckState,
      component: { readonly: compReadonly, placeholder = '' },
      readonly: formReadonly = false,
      compact = false,
      inputFontSize = 11
    } = this.props
    const { value } = this.state
    const readonly = formReadonly || compReadonly
    const compactStatus = compact ? 2 : 5

    const validationProps = {}
    if (validationCheckState) {
      validationProps.isInvalid = isInvalid
    }

    return (
      <FormControl
        {...validationProps}
        as="textarea"
        disabled={readonly ? 'disabled' : false}
        placeholder={placeholder}
        rows={compactStatus}
        style={{ fontSize: `${compact ? `${inputFontSize}px` : '14px'}` }}
        type="text"
        value={value}
        onChange={this.handleChange}
      />
    )
  }
}
export default Textarea
