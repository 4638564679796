export default `({
    //plain: true,
    // bind: {
    //     title: '{spotRecord.MaterialDescription}' + ' Urun Istatistikleri'
    // },
    header: false,
    tabPosition: 'top',
    //stateEvents: 'tabchange',
    activeTab: 0,
    //stateful: false,
    //stateId: 'tabpanel-demo',
    margin: '2 0 0 0',
    // tabPosition: 'right',
    // initComponent: function () {
    //     var me = this;
    //     me.callParent();
    // },
    items: [{
            title: tt('MaterialInformation'),
            xtype: 'spui-view-common-productinfo-cmp-tabpanel-info-main',
            bind: {
                materialCode: '{spotRecord.MaterialCode}',
            },
            updateMaterialCode: function (c, d) {
                if (c == d) return;
                if (!c) return;
                
                var vendorStore=this.getVendorStore()
                vendorStore.getProxy().setExtraParam('MaterialCode', c)
                var unitStore = this.getUnitStore()
                unitStore.getProxy().setExtraParam('MaterialCode', c);
                if (this.isVisible()) {
                    unitStore.load();
                    vendorStore.load()
                    this.forceToLoadStore = false;
                } else {
                    this.forceToLoadStore = true;
                }
            }
        }, {
            title: tt('Sales Chart'),
            xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-main',
            bind: {
                recordData: '{spotRecord}',
                materialCode: '{spotRecord.MaterialCode}',
            },
        },
        {
            title: tt('InventoryList'),
            xtype: 'spui-view-common-productinfo-cmp-tabpanel-inventory-main',
            inventoryCfg: Spui.Global.getUserRole().userOrg + '-InventoryList',
            bind: {
                materialCode: '{spotRecord.MaterialCode}',
            },
        },
        {
            title: tt('Old Campaign'),
            xtype: 'uxcontainer',
            uxItemCfg: 'eeeeeee',
            uxStoreCfg: 'oldSpotInformations',
            uxItem: {
                bind: {
                    materialCode: '{spotRecord.MaterialCode}',
                },
            }
        }
    ]
})
`
