import React, { Component } from 'react'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './ImageBox.scss'
import './Spinner.scss'

class ImageBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageurl: '',
      loading: false,
      loadError: false
    }

    this.handleImageLoadError = this.handleImageLoadError.bind(this)
    this.handleImageLoaded = this.handleImageLoaded.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'setImageUrl',
      fn: this.setImageUrl.bind(this),
      args: [{ name: 'imageurl', type: PluginTypes.string }]
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.getFinalImageUrl(this.props) !== this.getFinalImageUrl(nextProps)) {
      if (!this.state.loading) {
        this.setState({
          loading: true
        })
      }
      if (this.state.loadError) {
        this.setState({
          loadError: false
        })
      }
    }
  }

  handleImageLoadError() {
    if (!this.state.loadError && this.getFinalImageUrl(this.props) !== '') {
      this.setState({ loadError: true })
    }
    if (!this.state.loading) {
      this.setState({
        loading: true
      })
    }
  }

  handleImageLoaded() {
    this.setState({
      loading: false,
      loadError: false
    })
  }

  getData(currentProps) {
    let imageurlVal = ''
    if (currentProps.pluginData && currentProps.pluginData[0]) {
      const { imageurl } = this.props.settings.config.data
      imageurlVal = currentProps.pluginData[0][imageurl]
    }
    return {
      imageurl: imageurlVal
    }
  }

  setImageUrl({ imageurl }) {
    if (this.state.imageurl !== imageurl) {
      this.setState({ imageurl })
      this.handleImageLoaded()
    }
  }

  getFinalImageUrl(currentProps) {
    const data = this.getData(currentProps)
    const { imageurl: dataImageURL } = data || {}
    const { imageurl: stateImageURL } = this.state
    const { settings: { config: { settings: { imageurl: configImageURL } = {} } = {} } = {} } =
      currentProps

    const imageURL = stateImageURL || dataImageURL || configImageURL
    return imageURL
  }

  render() {
    const { loadError, loading } = this.state
    const {
      settings: {
        config: {
          settings: { backgroundcolor = '#FFFFFFF', keepratio = 'Keep-Ratio', paddingVal = 0 } = {}
        } = {}
      } = {}
    } = this.props

    const imageURL = this.getFinalImageUrl(this.props)
    const isImageURLEmpty = imageURL === ''

    return (
      <div
        className={`imageBoxPlugin ${keepratio}`}
        style={{ padding: paddingVal, backgroundColor: backgroundcolor }}
      >
        {isImageURLEmpty || loadError ? (
          <div className={`no-image ${isImageURLEmpty}`}>
            {isImageURLEmpty && !loadError && (
              <section>
                <i className="fa fa-picture-o" />
                <div>Empty image url</div>
              </section>
            )}
            {loadError && isImageURLEmpty && (
              <section>
                <i className="fa fa-chain-broken" />
                <div>Broken image url</div>
              </section>
            )}
          </div>
        ) : (
          ''
        )}
        <img
          alt=""
          className={`responsive img-fluid ${loading ? 'opacity-hidden' : ''}`}
          src={imageURL}
          onError={this.handleImageLoadError}
          onLoad={this.handleImageLoaded}
        />
        {!(isImageURLEmpty || loadError) && loading && (
          <div className="lds-css ng-scope">
            <div className="lds-ripple">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings
})

export default createPlugin(ImageBox, selectConnectorProps)
