import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { INotification } from '../components/NavigationBar/NotificationBox/NotificationBox.types'

export interface IState {
  notifications: INotification[]
}

export const initialState: IState = {
  notifications: []
}

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    storeNotifications: (state, action: PayloadAction<IState>) => {
      let newNotifications = action.payload.notifications
      const newResultLength = newNotifications.length
      if (newResultLength) {
        const clonedNotifications = _.cloneDeep(state.notifications)
        clonedNotifications.splice(0, newResultLength)
        newNotifications = [...action.payload.notifications, ...clonedNotifications]
      }
      state.notifications = [...newNotifications]
    },
    markAsRead: (state, action: PayloadAction<{ id: INotification['id'] }>) => {
      const { id } = action.payload
      const index = state.notifications.findIndex((notification) => notification.id === id)
      if (index !== -1) {
        state.notifications[index].read = true
      }
    }
  }
})

export const { storeNotifications, markAsRead } = notifications.actions
export default notifications.reducer
