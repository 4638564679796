import { useCallback, useEffect } from 'react'
import { uniq } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import { slvyToast } from '@/components'
import useReduxState from '../../useReduxState'
import { loadTreeTableSlice, tuiSlice } from '../../../store/slices'
import { expandAncestors } from '../../../utils'

function useCheckedLoads({ loadTreeTableRef }) {
  const [{ registerMethod }] = useReduxState(tuiSlice.selectSlice)
  const [, { setSelectedRows }] = useReduxState(undefined, loadTreeTableSlice.actions)

  const handleSetCheckedLoads = useCallback(
    (params) => {
      const { CheckedLoads } = params
      const { flatRows } = loadTreeTableRef.current.getRowModel()

      if (!CheckedLoads) {
        slvyToast.warning({
          title: 'TUI',
          message: 'setCheckedLoads parameter is empty.'
        })
        return
      }

      loadTreeTableRef.current.setRowSelection(true)

      const selectedRows = CheckedLoads.reduce((acc, checkedLoad) => {
        const matchingRows = flatRows.filter((row) => {
          return row?.originalSubRows?.[0]?.ID === checkedLoad
        })

        const selectedLoads = matchingRows.map((matchingRow) => {
          matchingRow.toggleSelected(true)
          expandAncestors(matchingRow)

          const originalSubRows = matchingRow.originalSubRows ?? []

          return [matchingRow.original, ...originalSubRows]
        })

        return acc.concat(...selectedLoads)
      }, [])

      setSelectedRows(uniq(selectedRows))
    },
    [loadTreeTableRef, setSelectedRows]
  )

  useEffect(() => {
    registerMethod({
      args: [{ name: 'CheckedLoads', type: PluginTypes.arrayOf(PluginTypes.string) }],
      key: 'setCheckedLoads',
      fn: handleSetCheckedLoads
    })
  }, [handleSetCheckedLoads, registerMethod])
}

export default useCheckedLoads
