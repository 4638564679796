import {
  FETCH_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_START,
  STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN,
  STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_FAILED
} from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetMfpDetailDataForSingleColumn: {},
  isLoading: true,
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_START:
      return updateObject(state, {
        isLoading: true
      })
    case STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN:
      return updateObject(state, {
        GetMfpDetailDataForSingleColumn: { ...action.payload }
      })
    case STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_FAILED:
      return updateObject(state, {
        error: { ...action.payload },
        isLoading: false
      })
    default:
      return state
  }
}

export default reducer
