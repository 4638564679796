import { useState, useEffect, KeyboardEvent } from 'react'
import { useParams } from 'react-router'
import { Button, FormControl, Offcanvas } from 'react-bootstrap'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from '@/hooks/store'
import { setLanguageModel } from '@/store/slices/pageConfig'
import ApiClient from '@/crudoptV3/libs/apiClient'
import { Chat, NewChat } from './components'
import { errorMessage } from './constants'
import { DisplayType, Message, Response } from './LanguageModel.types'
import styles from './index.module.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const client: any = new ApiClient()

const LanguageModel = () => {
  const [messages, setMessages] = useState<Message[]>([])
  const [isLarge, setIsLarge] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const showLanguageModel = useAppSelector((state) => state.pageConfig.showLanguageModel)
  const userName = useAppSelector((state) => state.oidc.user?.profile.name) ?? 'You'
  const { pageId } = useParams()

  useEffect(() => {
    setMessages([])
  }, [pageId])

  const sendMessage = (event: KeyboardEvent<HTMLInputElement>) => {
    const {
      key,
      currentTarget: { value: message }
    } = event

    const isLoading = messages[messages.length - 1]?.content?.displayType === DisplayType.Loading

    if (key !== 'Enter' || !message || isLoading) {
      return
    }

    setMessages((prev) => [
      ...prev,
      { author: userName, content: { displayType: DisplayType.Text, text: message } },
      { author: 'SolvoyoGPT', content: { displayType: DisplayType.Loading } }
    ])

    const data = { Text: message }
    client
      .post(`llmservice/${pageId}`, { data })
      .then((response: Response) => {
        setMessages((prev) => {
          const withoutLoading = prev.filter(
            (chat) => chat.content.displayType !== DisplayType.Loading
          )
          return [...withoutLoading, { author: 'SolvoyoGPT', content: response }]
        })
      })
      .catch(() => {
        setMessages((prev) => {
          const withoutLoading = prev.filter(
            (chat) => chat.content.displayType !== DisplayType.Loading
          )
          return [
            ...withoutLoading,
            {
              author: 'SolvoyoGPT',
              content: { displayType: DisplayType.Text, text: errorMessage }
            }
          ]
        })
      })

    // eslint-disable-next-line no-param-reassign
    event.currentTarget.value = ''
  }

  return (
    <Offcanvas
      backdrop={false}
      className={cx('theme-config-offcanvas', 'bg-dark', 'text-white', styles.canvas, {
        'w-50': isLarge
      })}
      enforceFocus={false}
      placement="end"
      show={showLanguageModel}
    >
      <Button
        className={cx(
          'position-absolute',
          'top-0',
          'bottom-0',
          'my-auto',
          'ps-1',
          'bg-transparent',
          'rounded',
          styles.resizer
        )}
        variant="base"
        onClick={() => setIsLarge((prev) => !prev)}
      >
        <div className="h-75 ps-1 bg-secondary rounded" />
      </Button>
      <Offcanvas.Header className="px-2">
        <Button
          className={cx('d-flex', 'text-muted', styles.headerButton)}
          variant="base"
          onClick={() => setMessages([])}
        >
          <i className="fa fa-fw fa fa-history" />
        </Button>
        <Button
          className={cx('d-flex', 'text-muted', 'px-2', styles.headerButton)}
          variant="base"
          onClick={() => dispatch(setLanguageModel(false))}
        >
          <i className="fa fa-fw fa-lg slvy-ui-icon-times-lt" />
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body className="pt-0">
        <div className="d-flex flex-column justify-content-between h-100 pb-3 gap-4">
          {messages.length ? <Chat messages={messages} /> : <NewChat />}
          <FormControl
            className="bg-dark text-white"
            placeholder="Message SolvoyoGPT..."
            onKeyDown={sendMessage}
          />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default LanguageModel
