Ext.define('clustering.cmp.Panel', {
  extend: 'Ext.panel.Panel',
  xtype: 'clustering-panel',
  config: {
    maximizeAllowed: false
  },
  border: false,
  toggleMaximize: function () {
    return this[this.maximized ? 'restore' : 'maximize']()
  },
  restore: function () {
    var me = this,
      tools = me.tools,
      header = me.header,
      parent = this.up(''),
      changed = false,
      c = me.lookupController(),
      viewModel = null

    if (c != null) viewModel = c.getViewModel()

    me.removeCls(me.winCls)
    me.addCls(me.oldCls)
    if (me.maximized) {
      me.hasSavedRestore = null
      // Manipulate visibility of header tools if there is a header
      if (header) {
        header.suspendLayouts()
        if (tools.maximize) {
          tools.maximize.setType('maximize')
        }
        if (me.collapseTool) {
          me.collapseTool.show()
          changed = true
        }
        me.resumeHeaderLayout(changed)
      }

      if (me.el && me.el.dom) me.el.dom.removeAttribute('style')

      me.oldParent.insert(me.oldIndex, me)
      me.setX(me.oldPosition[0])
      me.setY(me.oldPosition[1])
      me.maximized = false

      parent.close()
      if (viewModel) {
        viewModel.set('maximized', false)
      }
    }
  },
  maximize: function () {
    var me = this,
      header = me.header,
      tools = me.tools,
      changed = false,
      c = me.lookupController(),
      viewModel = c.getViewModel()

    me.oldCls = me.cls
    me.oldPosition = me.getPosition()
    me.oldParent = this.up('')
    me.oldViewModel = viewModel
    me.removeCls(me.oldCls)
    me.addCls(me.winCls)
    me.setPosition(0, 0)
    me.oldIndex = me.oldParent.items.indexOf(me)
    if (header) {
      header.suspendLayouts()
      if (tools.maximize) {
        tools.maximize.setType('restore')
      }
      if (me.collapseTool) {
        me.collapseTool.hide()
        changed = true
      }
      me.resumeHeaderLayout(changed)
    }

    var win = new Ext.window.Window({
      maximized: true,
      bodyPadding: 5,
      border: false,
      closeAction: 'hide',
      closable: true,
      cls: 'maximizedPanel',
      layout: 'fit',
      header: false,
      ui: 'window-inverse',
      bodyStyle: {
        background: '#D8DCE3',
        padding: '5px'
      },
      items: me,
      onEsc: function (k, e) {
        e.stopEvent()
      }
    })
    win.show()
    me.maximized = true
    if (viewModel) {
      viewModel.set('maximized', true)
    }
  },
  onBoxReady: function () {
    var me = this

    var btnid = Ext.id()
    me.createButton(btnid)
  },

  toggleMaximize: function () {
    return this[this.maximized ? 'restore' : 'maximize']()
  },

  createButton: function () {
    var me = this

    var buttons = []

    if (me.maximizeAllowed) {
      buttons.push({
        iconCls: 'fa fa-arrows-alt',
        handler: this.toggleMaximize,
        scope: me
      })
    }

    me.ButtonGroup = Ext.create('Ext.container.ButtonGroup', {
      renderTo: me.getEl(),
      y: 4,
      frame: false,
      cls: 'arh-x-btn-group',
      items: buttons
    })

    me.ButtonGroup.setHidden(true)

    this.mon(me.el, {
      scope: this,
      mouseover: this.onMouseover,
      delegate: '*'
    })
    this.mon(me.el, {
      scope: this,
      mouseleave: this.onMouseleave
    })
  },
  onMouseleave: function () {
    var me = this
    if (me.ButtonGroup) {
      me.ButtonGroup.setHidden(true)
    }
  },

  onMouseover: function () {
    var me = this
    if (me.ButtonGroup) {
      me.ButtonGroup.setHidden(false)
    }
  }
})
