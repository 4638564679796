Ext.define('MDM.btl.model.DPIModel', {
  extend: 'Ext.data.Model',
  fields: [
    {
      name: 'Demand',
      type: 'float'
    },
    {
      name: 'Import',
      type: 'float'
    },
    {
      name: 'Export',
      type: 'float'
    },
    {
      name: 'Inventory',
      type: 'float'
    },
    {
      name: 'Packaging',
      type: 'float'
    },
    {
      name: 'Production',
      type: 'float'
    },
    {
      name: 'DFC_Target',
      type: 'float'
    },
    {
      name: 'DFC_Calculated',
      type: 'float'
    },
    {
      name: 'RazorDraw',
      type: 'float'
    },
    {
      name: 'InventoryNull',
      calculate: function (data) {
        if (data.Inventory == 0) return null
        return data.Inventory
      }
    }
  ]
})
