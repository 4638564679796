export default `({
    fieldDefaults: {
        labelAlign: 'left',
        labelWidth: 130,
        msgTarget: 'side',
        labelStyle: 'font-weight:bold;',
        style: 'font-weight:bold; padding:1px',
    },
    cls: ' spot-new-campaign-parameters ',
    border: true,
    bodyPadding: 10,
    height: 315,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b>' + tt('Campaign parameters') + '</b>'
        }]
    }],
    items: [{
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            layout: 'hbox',
            defaultType: 'numberfield',
            items: [{
                    name: 'SalesPrice',
                    xtype: 'numberfield',
                    decimalSeparator: ',',
                    flex: 1,
                    minValue: 0,
                    afterLabelTextTpl: [
                        '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                    ],
                    bind: {
                        value: '{spotRecord.SalesPrice}'
                    },
                    fieldLabel: tt('SalesPrice'),
                    allowBlank: false,
                },
                {
                    xtype: 'combo',
                    fieldLabel: tt('İade Durumu'),
                    displayField: 'state',
                    valueField: 'value',
                    editable: false,
                    queryMode: 'local',
                    margin: '0 0 0 10',
                    flex: 1,
                    store: [{
                            state: tt('İadeli'),
                            value: 1
                        },
                        {
                            state: tt('İadesiz'),
                            value: 0
                        }
                    ],
                    // listeners: {
                    //     select: function (combo, record) {
                    //         var view = this.up('spui-view-wizard-wizardmain'),
                    //             viewModel = view.getViewModel(),
                    //             spotRecord = viewModel.get('spotRecord')
                    //         value = combo.getValue() ? 1 : 0;
                    //         spotRecord.set('Returnable', value)
                    //     }
                    // },
                    bind: {
                        value: '{spotRecord.Returnable}'
                    }
                }
                // {
                //     name: 'SalesPrice',
                //     maxValue: 2,
                //     minValue: 0.5,
                //     step: 0.1,
                //     margin: '0 0 0 10',
                //     xtype: 'numberfield',
                //     flex: 1,
                //     afterLabelTextTpl: [
                //         '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                //     ],
                //     bind: {
                //         value: '{spotRecord.expectedPerformanceIndex}'
                //     },
                //     fieldLabel: tt('Beklenti endeksi'),
                //     allowBlank: false,
                // },
            ]
        },
        {
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            layout: 'hbox',
            defaultType: 'numberfield',
            items: [{
                xtype: 'combobox',
                queryMode: 'local',
                displayField: 'name',
                valueField: 'abbr',
                editable: false,
                flex: 1,
                required: true,
                listeners: {
                    afterlayoutanimation: function (combo) {
                        combo.setValue('KOL')
                    }
                },
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                bind: {
                    value: '{spotRecord.UnitofMeasurement}'
                },
                fieldLabel: tt('Unit of Measurement'),
                store: [
                    {
                        abbr: 'KOL',
                        name: tt('Koli')
                    }
                ]
            }, {
                name: 'Units Per Package',
                margin: '0 0 0 10',
                xtype: 'numberfield',
                useNull: true,
                minValue: 0,
                flex: 1,
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                bind: {
                    value: '{spotRecord.UnitsPerPackage}'
                },
                fieldLabel: tt('Units Per Package'),
                allowBlank: false,
            }]
        },
        {
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            layout: 'hbox',
            defaultType: 'numberfield',
            items: [{
                flex: 1,
                name: 'middleInitial',
                fieldLabel: tt('Minimum Order Quantity'),
                tooltip: tt('Minimum Order Quantity Tip'),
                minValue: 0,
                step: 1,
                bind: {
                    value: '{spotRecord.MinQuantity}'
                },
            },
            {
                flex: 1,
                name: 'middleInitial',
                fieldLabel: tt('Minimum XL Order Quantity'),
                tooltip: tt('Minimum XL Order Quantity'),
                minValue: 0,
                step: 1,
                bind: {
                    value: '{spotRecord.MinXLQuantity}'
                },
            }]
        },
        {
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            layout: 'hbox',
            defaultType: 'textfield',
            items: [{
                flex: 1,
                name: 'middleInitial',
                fieldLabel: tt('Start Date'),
                editable: false,
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                xtype: 'datefield',
                format: tt('d-m-Y'),
                disabledDays: [0, 1, 2, 3, 5, 6, 7],
                minValue: new Date(),
                value: '{spotRecord.PreStartDate}',
                allowBlank: false,
                triggers: {
                    foo: {
                        cls: 'x-form-dd-trigger',
                        weight: -2, // negative to place before default triggers
                        handler: function (f) {
                            var win = Ext.create('Spui.uxWindow', {
                                height: '100%',
                                title: tt('Old Campaigns'),
                                uxItemCfg: 'card56',
                                viewModel: {
                                    data: {
                                        record: {}
                                    }
                                },
                                controller: {
                                    onSelectWeek: function () {
                                        alert(ok);
                                    },
                                    onExportExcel: function () {
                                        alert(ok);
                                    },
                                    listen: {
                                        component: {
                                            'uxchart': {
                                                clickChart: function (record) {
                                                    grid = this.lookup('haftalikgrid');
                                                    chart = this.lookup('chart2');
                                                    if (!grid) return;
                                                    var store = grid.store
                                                    this.getViewModel().set('record', record)
                                                    proxy = store.getProxy();
                                                    data = Ext.encode(record.data);
                                                    proxy.setExtraParam('data', data);
                                                    store.load();
                                                    var store = chart.store
                                                    proxy = store.getProxy();
                                                    proxy.setExtraParam('data', data);
                                                    store.load();
                                                }
                                            }
                                        }
                                    },
                                },
                                width: '100%',
                                layout: 'fit',
                                items: [{
                                    xtype: 'uxcontainer',
                                    uxItemCfg: 'card1',
                                }]
                            });
                            win.show();
                            win.on('select', function (record) {
                                this.setValue(record.get('StartDate'))
                                Spotrecord = this.up('spui-view-wizard-wizardmain').getViewModel().get('spotRecord')
                                var d = new Date();
                                d.setDate(d.getDate() + (4 + 7 - d.getDay()) % 7);
                                startDate = record.get('StartDate') !== null ? record.get('StartDate') : d
                                Spotrecord.set('PreStartDate', startDate);
                                PreEndDateValue = Ext.Date.add(startDate, Ext.Date.DAY, 21);
                                Spotrecord.set('PreEndDate', PreEndDateValue);
                            }, f)
                        }
                    },
                },
                listeners: {
                    select: function (field, value) {
                        var form = field.up('form'),
                            record = field.up('spui-view-wizard-wizardmain').getViewModel().get('spotRecord')
                        // var diffDate = Ext.Date.diff(Ext.Date.now(), value, Ext.Date.DAY)
                        // if (diffDate < 30) {
                        //     record.set('UseStock', 1)
                        //     var referenceHolder = this.lookupReferenceHolder(),
                        //         availableInventory = referenceHolder.lookupReference('availableInventory')
                        //     availableInventory.setValue(true)
                        // }
                        var PreEndDateField = field.nextNode('datefield(true)'),
                            PreStartDateValue = Ext.Date.add(value, Ext.Date.DAY, 21);
                        record.set('PreEndDate', PreStartDateValue);
                    },
                    change:function(me, newValue, oldValue)
                    {
                        var record = me.up('spui-view-wizard-wizardmain').getViewModel().get('spotRecord');
                        record.set('PreStartDate', newValue);
                    } 
                },
                bind: {
                    preStartDate: '{spotRecord.PreStartDate}'
                },
                setPreStartDate:function(data)
                {
                    if(data)
                    this.setValue(new Date(data.getTime() + 300*60000))
                }
            }, {
                flex: 1,
                name: 'PreEndDate',
                margin: '0 0 0 10',
                editable: false,
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                fieldLabel: tt('End Date'),
                format: tt('d-m-Y'),
                xtype: 'datefield',
                minValue: Ext.Date.add(new Date(Ext.Date.now()), Ext.Date.DAY, 7),
                disabledDays: [0, 1, 2, 3, 5, 6, 7],
                allowBlank: false,
                disabled:true,
                bind: {
                    value: '{spotRecord.PreEndDate}',
                    minValue: '{spotRecord.PreStartDate}',
                },
            }]
        },
        // {
        //     xtype: 'fieldcontainer',
        //     labelStyle: 'font-weight:bold;',
        //     layout: 'hbox',
        //     flex: 1,
        //     defaultType: 'textfield',
        //     items: [{
        //         xtype: 'combo',
        //         fieldLabel: tt('İade Durumu'),
        //         displayField: 'VALUE',
        //         value: false,
        //         valueField: 'state',
        //         queryMode: 'local',
        //         store: [{
        //                 state: true,
        //                 value: 'İadeli'
        //             },
        //             {
        //                 state: false,
        //                 value: 'İadesiz',
        //             }
        //         ],
        //         bind: {
        //             value: '{spotRecord.Returnable}'
        //         }
        //     }]
        // },
        {
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            layout: 'hbox',
            defaultType: 'checkboxfield',
            labelWidth: 100,
            items: [{
                flex: 1,
                name: 'AllowRoundingUp',
                inputValue: '1',
                labelAlign :'right',
                fieldLabel: tt('Round the Order Recommendations'),
                onChange: function (newValue) {
                    var wizardmain = this.up('spui-view-wizard-wizardmain');
                    var vm = wizardmain.getViewModel();
                    var spotRecord = vm.get('spotRecord');
                    spotRecord.set('AllowRoundingUp', newValue);
                    vm.set('spotRecord', spotRecord);
                    this.setValue(newValue);
                },
                bind: {
                    value: '{spotRecord.AllowRoundingUp}',
                },
            },    
            {
                flex: 1,
                name: 'PalletPlanning',
                labelAlign :'right',
                inputValue: '1',
                fieldLabel: tt('Palet Kati Planla'),
                onChange: function (newValue) {
                    var value = newValue ? 1 : 0;
                    var wizardmain = this.up('spui-view-wizard-wizardmain');
                    var vm = wizardmain.getViewModel();
                    var spotRecord = vm.get('spotRecord');
                    spotRecord.set('Palletize', value);
                    vm.set('spotRecord', spotRecord);
                    this.setValue(newValue);
                },
                bind: {
                    value: '{spotRecord.Palletize}',
                },
            }, {
                flex: 1,
                name: 'TrackPlanning',
                labelAlign :'right',
                inputValue: '1',
                fieldLabel: tt('KamyonKatiPlanla'),
                onChange: function (newValue) {
                    var value = newValue ? 1 : 0;
                    var wizardmain = this.up('spui-view-wizard-wizardmain');
                    var vm = wizardmain.getViewModel();
                    var spotRecord = vm.get('spotRecord');
                    spotRecord.set('FullTruck', value);
                    vm.set('spotRecord', spotRecord);
                    this.setValue(newValue);
                },
                bind: {
                    value: '{spotRecord.FullTruck}',
                },
            }, {
                flex: 1,
                reference: 'availableInventory',
                labelAlign :'right',
                name: 'AvailableInventory',
                onChange: function (newValue) {
                    var value = newValue ? 1 : 0;
                    var wizardmain = this.up('spui-view-wizard-wizardmain');
                    var vm = wizardmain.getViewModel();
                    var spotRecord = vm.get('spotRecord');
                    spotRecord.set('UseStock', value);
                    vm.set('spotRecord', spotRecord);
                    this.setValue(newValue);
                },
                inputValue: '1',
                fieldLabel: tt('Eldeki Envanteri Kullan'),
                bind: {
                    value: '{spotRecord.UseStock}',
                },
            },
            {
                labelAlign :'right',
                flex: 1,
                name: 'UseDCInventory',
                inputValue: '1',
                fieldLabel: tt('Use DC Inventory'),
                onChange: function (newValue) {
                    var wizardmain = this.up('spui-view-wizard-wizardmain');
                    var vm = wizardmain.getViewModel();
                    var spotRecord = vm.get('spotRecord');
                    spotRecord.set('UseDCInventory', newValue);
                    vm.set('spotRecord', spotRecord);
                    this.setValue(newValue);
                },
                bind: {
                    value: '{spotRecord.UseDCInventory}',
                },
            }
        ]
        },
        {
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            required: true,
            layout: 'hbox',
            items: [{
                xtype: 'combobox',
                queryMode: 'local',
                displayField: 'VendorNameWithCode',
                valueField: 'VendorCode',
                editable: false,
                flex: 1,
                allowBlank: false,
                required: true,
                bind: {
                    value: '{spotRecord.VendorCode}',
                    vendorData:'{vendorDatas}',
                    VendorCode:'{spotRecord.VendorCode}'
                },
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                setVendorData:function(data){
                    this.reset()
                    this.setStore(data)
                    this.setValue(this.VendorCode)
                },
                setVendorCode(data)
                {
                    this.VendorCode = data;
                },
                fieldLabel: tt('Vendor'),
            }]
        },
        
    ]
})
`
