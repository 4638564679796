import React, { Component } from 'react'
import _ from 'lodash'
import './index.scss'
import { confirmAlert } from 'react-confirm-alert'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import GridLayout from 'react-grid-layout'

export default class Timeline extends Component {
  constructor(props) {
    super(props)

    this.handleTaskChanged = this.handleTaskChanged.bind(this)
    this.handleTaskSelected = this.handleTaskSelected.bind(this)
    this.handleTaskInserted = this.handleTaskInserted.bind(this)
    this.calculateNewSummaryData = this.calculateNewSummaryData.bind(this)
    this.handleBtn = this.handleBtn.bind(this)
    this.state = {
      taskTree: [],
      summaryData: [],
      currentId: []
    }
    this.tasklist = []
  }

  tooltip(typeName) {
    return <Tooltip id="tooltip">{typeName}</Tooltip>
  }

  calculatePeriodLocations(periodData, periodsInHeader, basePeriodWidth) {
    const headerPeriodsData = _.transform(
      periodData,
      (res, value, key) => {
        if (_.indexOf(periodsInHeader, key) >= 0) {
          res[key] = value
        }
      },
      {}
    )

    const calcHeaderPeriodsData = _.transform(
      headerPeriodsData,
      (res, periodValues, key) => {
        let currWidth = 0
        const periodValuesWtLocations = _.map(periodValues, (periodValue, index) => {
          const left = currWidth
          const width = periodValue.count * basePeriodWidth
          currWidth = left + width
          return {
            ...periodValue,
            width,
            left
          }
        })
        res[key] = periodValuesWtLocations
      },
      {}
    )

    const maxWidth = _.reduce(
      calcHeaderPeriodsData,
      (result, periodValues, period) => {
        const lastPeriod = _.maxBy(periodValues, 'left')
        const right = lastPeriod.left + lastPeriod.width
        if (right > result) {
          result = right
        }
        return result
      },
      0
    )

    const firstHeaderPeriod = periodsInHeader[0]
    const secondHeaderPeriod = periodsInHeader[1]

    return {
      firstLevelPeriods: calcHeaderPeriodsData[firstHeaderPeriod],
      secondLevelPeriods: calcHeaderPeriodsData[secondHeaderPeriod],
      maxWidth
    }
  }

  traverseTreeForTasks(nodes, callBack) {
    _.forEach(nodes, (node) => {
      if (_.has(node, 'nodes')) {
        this.traverseTreeForTasks(node.nodes, callBack)
      } else if (_.has(node, 'tasks')) {
        _.forEach(node.tasks, (task) => {
          callBack(task)
        })
      }
    })
  }

  traverseTreeForNodes(nodes, callBack) {
    _.forEach(nodes, (node) => {
      if (_.has(node, 'nodes')) {
        this.traverseTreeForNodes(node.nodes, callBack)
      } else {
        callBack(node)
      }
    })
  }

  removeTaskFromTree(nodes, tsk, netWorkingCalculationTable) {
    const dif = tsk.end - tsk.start + 1
    const matchedWorkingDuration = _.find(netWorkingCalculationTable, (n) => {
      return n.gross_work_duration === dif
    })
    _.forEach(nodes, (node) => {
      if (_.has(node, 'nodes')) {
        this.removeTaskFromTree(node.nodes, tsk, netWorkingCalculationTable)
      } else if (_.has(node, 'tasks')) {
        if (node.id === tsk.rowId && node.currentWorkingHour) {
          node.currentWorkingHour -= parseFloat(matchedWorkingDuration.net_work_duration)
        }
        _.forEach(node.tasks, (t) => {
          node.tasks = _.filter(node.tasks, (task) => {
            return task.taskId !== tsk.taskId
          })
        })
      }
    })
  }

  calculateNewSummaryData(summaryData, taskTree, task) {
    this.taskList = []
    const parent = task.rawData !== undefined ? task.rawData.parent : task.rowId
    _.forEach(taskTree, (node) => {
      if (_.has(node, 'nodes')) {
        if (node.id === parent) {
          this.calculateNewSummaryData(summaryData, node.nodes, task)
        }
      } else if (_.has(node, 'tasks')) {
        this.taskList.push(...node.tasks)
      }
    })
    const newSum = []
    _.forEach(summaryData, (sum) => {
      if (sum.parent.RowId !== parent) {
        newSum.push(sum)
      } else {
        const sumElem = sum

        sumElem.quantities = _.map(sum.quantities, (quant, index) => {
          return _.size(_.filter(this.taskList, (tsk) => tsk.start <= index && tsk.end >= index))
        })
        newSum.push(sumElem)
      }
    })
    return newSum
  }

  handleTaskChanged(tasks, selectionType, resized) {
    return (layout, oldPosition, newPosition) => {
      const { netWorkingCalculationTable = [] } = this.props
      const activeTask = _.find(tasks, (task) => {
        return task.taskId.toString() === oldPosition.i
      })

      if (oldPosition.x !== newPosition.x || oldPosition.w !== newPosition.w) {
        const difference = newPosition.w - oldPosition.w

        const oldWorkingDuration = _.find(netWorkingCalculationTable, (n) => {
          return n.gross_work_duration === activeTask.end - activeTask.start + 1
        })

        const newWorkingDuration = _.find(netWorkingCalculationTable, (n) => {
          return n.gross_work_duration === activeTask.end - activeTask.start + 1 + difference
        })
        this.props.onTaskChanged(
          activeTask,
          newPosition.x,
          newPosition.x + newPosition.w - 1,
          oldPosition.x,
          oldPosition.x + oldPosition.w - 1
        )

        this.props.onTaskSelected(activeTask)

        const taskTree = [...this.state.taskTree]

        const summaryData = [...this.state.summaryData]

        let newSumData = []
        this.traverseTreeForTasks(taskTree, (task) => {
          if (task.taskId === activeTask.taskId) {
            task.start = newPosition.x
            task.end = newPosition.x + newPosition.w - 1
            task.selected = selectionType === 'Task Selection'

            newSumData = this.calculateNewSummaryData(summaryData, taskTree, task)
          } else {
            task.selected = false
          }
        })
        if (resized) {
          this.traverseTreeForNodes(taskTree, (node) => {
            if (node.id === activeTask.rowId && node.currentWorkingHour) {
              node.currentWorkingHour += parseFloat(newWorkingDuration.net_work_duration)
              node.currentWorkingHour -= parseFloat(oldWorkingDuration.net_work_duration)
            }
          })
        }

        this.setState({ taskTree, summaryData: newSumData })
      }
    }
  }

  handleTaskSelected(activeTask) {
    return () => {
      this.props.onTaskSelected(activeTask)

      const taskTree = [...this.state.taskTree]

      this.traverseTreeForTasks(taskTree, (task) => {
        if (task.taskId === activeTask.taskId) {
          task.selected = true
        } else {
          task.selected = false
        }
      })

      this.setState({ taskTree })
    }
  }

  handleBtn(task) {
    this.props.onBtnHandled(task)
  }

  handleTaskDeleted(activeTask, languageDictionary) {
    return (e) => {
      e.stopPropagation()

      const { netWorkingCalculationTable } = this.props
      const {
        removeTask: {
          title = '',
          text: message = '',
          ok: confirmLabel = '',
          cancel: cancelLabel = ''
        } = {}
      } = languageDictionary

      confirmAlert({
        title,
        message,
        buttons: [
          {
            label: cancelLabel
          },
          {
            label: confirmLabel,
            onClick: () => {
              this.props.onTaskDeleted(activeTask)

              const taskTree = [...this.state.taskTree]

              const summaryData = [...this.state.summaryData]

              this.removeTaskFromTree(taskTree, activeTask, netWorkingCalculationTable)
              const newSumData = this.calculateNewSummaryData(summaryData, taskTree, activeTask)

              this.setState({ taskTree, summaryData: [...newSumData] })
            }
          }
        ]
      })
    }
  }

  getUnionOfStartEndValues(tasks) {
    const allBetweenStartEnds = []
    _.forEach(tasks, (task) => {
      for (let index = task.start; index <= task.end; index++) {
        allBetweenStartEnds.push(index)
      }
    })
    return allBetweenStartEnds
  }

  findfirstAvailablePeriod(tasks, perSize) {
    const keys = []
    const unionOfStartEnds = this.getUnionOfStartEndValues(tasks)
    for (let index = 0; index < perSize; index++) {
      keys.push(index)
    }
    const foundKey = keys.find((key) => {
      return unionOfStartEnds.includes(key) === false
    })
    return { start: foundKey, end: foundKey }
  }

  handleTaskInserted(node) {
    return (e) => {
      e.stopPropagation()
      const { periods } = this.props

      const firstAvailablePeriodToInsert = this.findfirstAvailablePeriod(
        node.tasks,
        _.size(periods)
      )
      const task = { rowId: node.id, ...firstAvailablePeriodToInsert }
      const returnedTask = this.props.onTaskInserted(task)

      const taskTree = [...this.state.taskTree]

      const summaryData = [...this.state.summaryData]
      let newSumData = []
      this.traverseTreeForNodes(taskTree, (node) => {
        if (node.id === task.rowId) {
          node.currentWorkingHour += 1
          const temp = node.tasks
          temp.push(returnedTask)
          node.tasks = [...temp]
        }
      })

      this.traverseTreeForTasks(taskTree, (task) => {
        newSumData = this.calculateNewSummaryData(summaryData, taskTree, task)
      })

      this.setState({ taskTree, summaryData: [...newSumData] })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { taskTree = [], summaryData = {} } = nextProps
    this.setState({
      taskTree,
      summaryData,
      currentId: []
    })
  }

  render() {
    const {
      periodData = {},
      periodsInHeader = [],
      summaryVisible = true,
      basePeriodWidth = 20,
      basePeriods = [],
      selectionType = '',
      buttons = [],
      languageDictionary = {}
    } = this.props

    const { taskTree = [], summaryData = {}, currentId = [] } = this.state

    const {
      firstLevelPeriods,
      secondLevelPeriods,
      maxWidth: scrollWidth
    } = this.calculatePeriodLocations(periodData, periodsInHeader, basePeriodWidth)

    const {
      tooltip: { addTask = '', overtime = '', noOvertime = '' } = {},
      emp: { planned = '', need = '' } = {}
    } = languageDictionary

    return (
      <div className="general-timeline">
        <div className="timeline-wrp sc-timeline-table-gantt">
          <header className="rcc-header">
            <aside ref="tableHeader">
              <section className="-top" style={{ width: scrollWidth }}>
                {_.map(firstLevelPeriods, (period, index) => {
                  return (
                    <div key={index} style={{ width: period.width, left: period.left }}>
                      <b>{period.name}</b>
                    </div>
                  )
                })}
              </section>
              <section className="-bottom">
                <div>
                  {_.map(secondLevelPeriods, (period, index) => {
                    return (
                      <i key={index} style={{ width: period.width, left: period.left }}>
                        {period.name}
                      </i>
                    )
                  })}
                </div>
              </section>
            </aside>
          </header>
          <div className="sc-timeline-table">
            <div className="sc-timeline-left">
              <ul>
                {_.map(taskTree, (node, index) => {
                  const isActive = _.indexOf(currentId, index) >= 0
                  return (
                    <li key={index} className={`${isActive ? '-active' : ''}`}>
                      <header
                        onClick={(e) => {
                          const newCurrentId = [...this.state.currentId]
                          if (isActive) {
                            _.remove(newCurrentId, (item) => {
                              return item === index
                            })
                          } else {
                            newCurrentId.push(index)
                          }
                          this.setState({ currentId: newCurrentId })
                        }}
                      >
                        <i className="fa fa-caret-right" />
                        {node.label}
                      </header>
                      {isActive &&
                        _.size(node.nodes) > 0 &&
                        _.map(node.nodes, (node, index) => {
                          return (
                            <div key={`row-${index}`} className="row">
                              <div key={index} className="-level row">
                                <div className="col-md-1">
                                  <span>
                                    <i
                                      className={`fa fa-2x ${node.timeIcon}`}
                                      style={{ color: '#2f4050' }}
                                    />
                                  </span>
                                </div>
                                <div className="col-md-1">
                                  <span>
                                    <i
                                      className={
                                        node.currentWorkingHour > node.maxWorkingHour
                                          ? 'fa fa-2x fa-frown-o'
                                          : 'fa fa-2x fa-smile-o'
                                      }
                                      style={{
                                        color:
                                          node.currentWorkingHour > node.maxWorkingHour
                                            ? 'red'
                                            : '#228B22'
                                      }}
                                      title={
                                        node.currentWorkingHour > node.maxWorkingHour
                                          ? overtime
                                          : noOvertime
                                      }
                                    />
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <span
                                    style={{
                                      width: '100px',
                                      color: node.currentWorkingHour > node.maxWorkingHour && 'red',
                                      marginLeft: '-5px'
                                    }}
                                  >
                                    {node.currentWorkingHour
                                      ? `(${node.currentWorkingHour}) ${node.label}`
                                      : node.label}
                                  </span>
                                </div>

                                <div className="col-md-1">
                                  <span style={{ cursor: 'pointer' }}>
                                    <i
                                      className="fa fa-2x fa-plus-square"
                                      style={{ color: '#2f4050' }}
                                      title={addTask}
                                      onClick={this.handleTaskInserted(node)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div ref="tableBody" className="sc-timeline-body">
              {_.map(taskTree, (node, index) => {
                const isActive = _.indexOf(currentId, index) >= 0
                return (
                  <div
                    key={index}
                    className={`-row-ctn ${isActive ? '-active' : ''}`}
                    style={{ width: scrollWidth }}
                  >
                    <div key={index} className="-row row-header" style={{ width: scrollWidth }}>
                      {summaryVisible && (
                        <aside>
                          <section className="-bottom summary">
                            <div>
                              {_.map(summaryData[index].quantities, (quantity, ind) => {
                                return (
                                  <OverlayTrigger
                                    key={`tooltip-${ind}`}
                                    overlay={this.tooltip(
                                      `${planned}: ${quantity}, ${need}: ${summaryData[index].expecteds[ind]}`
                                    )}
                                    placement="top"
                                  >
                                    <i
                                      key={ind}
                                      style={{
                                        width: basePeriodWidth,
                                        left: secondLevelPeriods?.[ind]?.left,
                                        color:
                                          quantity === summaryData[index].expecteds[ind]
                                            ? 'black'
                                            : 'white',
                                        backgroundColor:
                                          quantity > summaryData[index].expecteds[ind]
                                            ? 'green'
                                            : quantity === summaryData[index].expecteds[ind]
                                            ? 'transparent'
                                            : '#bb2124',
                                        border: '1px solid',
                                        fontWeight: 'bold',
                                        borderColor: '#d8dce3'
                                      }}
                                    >
                                      {`${quantity}/${summaryData[index].expecteds[ind]}`}
                                    </i>
                                  </OverlayTrigger>
                                )
                              })}
                            </div>
                          </section>
                        </aside>
                      )}
                    </div>
                    {isActive &&
                      _.size(node.nodes) > 0 &&
                      _.map(node.nodes, (node, index) => {
                        return (
                          <div key={index} className="-level">
                            <GridLayout
                              autoSize={false}
                              className="inner-layout"
                              cols={_.size(basePeriods)}
                              compactType="vertical"
                              draggableCancel=".fa-trash"
                              margin={[0, 0]}
                              preventCollision={false}
                              rowHeight={27}
                              useCSSTransforms={false}
                              width={scrollWidth}
                              onDragStop={this.handleTaskChanged(node.tasks, selectionType, false)}
                              onResizeStop={this.handleTaskChanged(node.tasks, selectionType, true)}
                            >
                              {_.map(node.tasks, (task, index) => {
                                return (
                                  <div
                                    key={_.size(node.tasks) > 0 ? task.taskId : 0}
                                    className={
                                      task.selected
                                        ? 'timeline-taskContainer selected'
                                        : 'timeline-taskContainer'
                                    }
                                    data-grid={{
                                      x: _.size(node.tasks) >= 0 ? task.start : 0,
                                      y: 0,
                                      w: _.size(node.tasks) > 0 ? task.end - task.start + 1 : 0,
                                      h: 1,
                                      minH: 1,
                                      maxH: 1
                                    }}
                                  >
                                    {task.end !== -1 && (
                                      <div
                                        className={
                                          task.selected
                                            ? 'timeline-selectedTask timeline-taskContent'
                                            : 'timeline-taskContent'
                                        }
                                        onMouseDown={
                                          selectionType === 'Task Selection' &&
                                          this.handleTaskSelected(task, selectionType)
                                        }
                                      >
                                        {task.label}

                                        <span
                                          className="fa fa-trash-o"
                                          style={{
                                            float: 'left',
                                            fontSize: '11px',
                                            color: 'maroon',
                                            marginTop: '-2px'
                                          }}
                                          onClick={this.handleTaskDeleted(task, languageDictionary)}
                                          onMouseDown={(e) => e.stopPropagation()}
                                        />
                                        {task.end !== task.start &&
                                          _.map(buttons, (btn, idx) => {
                                            return (
                                              <span
                                                key={`btn-${idx}`}
                                                className={btn.icon}
                                                style={{
                                                  float: 'left',
                                                  fontSize: '11px',
                                                  color: btn.iconColor,
                                                  marginTop: '-2px',
                                                  marginLeft: '5px'
                                                }}
                                                onClick={() => this.handleBtn(task)}
                                              />
                                            )
                                          })}
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                            </GridLayout>
                          </div>
                        )
                      })}
                  </div>
                )
              })}
            </div>
          </div>
          <footer
            className="sc-timeline-gantt-footer"
            onScroll={(e) => {
              const { tableHeader, tableBody } = this.refs

              const {
                target: { scrollLeft = 0 }
              } = e

              tableHeader.scrollTo(scrollLeft, 0)
              tableBody.scrollTo(scrollLeft, 0)
            }}
          >
            <div style={{ width: scrollWidth }} />
          </footer>
        </div>
      </div>
    )
  }
}
