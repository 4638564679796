import { CSSProperties } from 'react'

const currentGrowthValueStyle: CSSProperties = { textAlign: 'left' }
const metricItemValueStyle: CSSProperties = { justifyContent: 'flex-start' }
const ratioLimitStyle: CSSProperties = { position: 'relative', left: '2px', width: '100%' }
const ratioLimitValueStyle: CSSProperties = {
  justifyContent: 'flex-start',
  maxWidth: 'calc(100% - 20px)',
  width: 'auto'
}

export { currentGrowthValueStyle, metricItemValueStyle, ratioLimitStyle, ratioLimitValueStyle }
