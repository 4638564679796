import cx from 'classnames'
import style from './CustomTooltipView.module.scss'

const CustomTooltipView = ({ tooltip }: { tooltip: string }) => {
  // https://dragon.solvoyo.com/Configuration/catalog/5a3a0d9569822c0cc8e683a2/store/18/menu/5b485b0569822c0d405e3fa1/page/657862bbd253c84a67a83cd3
  // light-tooltip used in above page only, but we cannot make `theme` optional

  return (
    <div
      className="tooltip show opacity-100 bs-tooltip-end overflow-hidden ag-grid-tooltip rounded-1"
      role="tooltip"
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: tooltip }}
        className={cx('ag-grid-tooltip-body', style.CustomTooltipInner)}
      />
    </div>
  )
}

export default CustomTooltipView
