import cx from 'classnames'
import Tooltip from '../Tooltip'
import { PrimaryButtonProps } from './PrimaryButton.types'
import styles from './PrimaryButton.module.scss'

export default function PrimaryButton({
  className,
  children,
  disabled,
  type = 'button',
  tooltipText,
  onClick
}: PrimaryButtonProps) {
  const button = (
    <button
      className={cx(styles.primaryButton, className)}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  )

  return tooltipText ? <Tooltip text={tooltipText}>{button}</Tooltip> : button
}
