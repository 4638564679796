import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from '../appAPI'

const initialState = {
  error: {},
  index: 0,
  isLoading: false,
  optionUpdateList: [],
  points: {
    x: 0,
    y: 0
  },
  rightClicked: null,
  rootOptions: {},
  scroll: {},
  selected: {},
  selectedSegmentId: null,
  selectType: 'Select',
  show: false,
  showActiveStores: false,
  target: {}
}

export const getOptions = createAsyncThunk('app/getOptions', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload
    return JSON.parse(response.text)
  } catch (err) {
    throw err
  }
})

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    resetOptions: (state, action) => {
      return initialState
    },
    selectOption: (state, action) => {
      state.selected = action.payload
    },
    setIndex: (state, action) => {
      state.index = action.payload
    },
    setOptionUpdateList: (state, action) => {
      state.optionUpdateList = action.payload
    },
    setPoints: (state, action) => {
      state.points = action.payload
    },
    setRightClicked: (state, action) => {
      state.rightClicked = action.payload
    },
    setScroll: (state, action) => {
      state.scroll = action.payload
    },
    setSelectedSegmentId: (state, action) => {
      state.selectedSegmentId = action.payload
    },
    setSelectType: (state, action) => {
      state.selectType = action.payload
    },
    setShow: (state, action) => {
      state.show = action.payload
    },
    setShowActiveStores: (state, action) => {
      state.showActiveStores = action.payload
    },
    setTarget: (state, action) => {
      state.target = action.payload
    },
    unSelectOption: (state, action) => {
      state.selected = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptions.pending, (state, action) => {
        state.isLoading = true
        state.error = {}
        state.selected = {}
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.rootOptions = { ...action.payload }
        state.error = {}
        state.isLoading = false
        state.selected = {}
      })
      .addCase(getOptions.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload.errorMessage
        } else {
          state.error = action.error.message
        }
        state.isLoading = false
        state.selected = {}
      })
  }
})

export const {
  resetOptions,
  selectOption,
  setIndex,
  setOptionUpdateList,
  setPoints,
  setRightClicked,
  setScroll,
  setSelectedSegmentId,
  setSelectType,
  setShow,
  setShowActiveStores,
  setTarget,
  unSelectOption
} = optionsSlice.actions

export const error = (state) => {
  state.error
}
export const index = (state) => {
  state.index
}
export const isLoading = (state) => {
  state.isLoading
}
export const optionUpdateList = (state) => {
  state.optionUpdateList
}
export const points = (state) => {
  state.points
}
export const rightClicked = (state) => {
  state.rightClicked
}
export const rootOptions = (state) => {
  state.rootOptions
}
export const scroll = (state) => {
  state.scroll
}
export const selected = (state) => {
  state.selected
}
export const selectedSegmentId = (state) => {
  state.selectedSegmentId
}
export const selectType = (state) => {
  state.selectType
}
export const show = (state) => {
  state.show
}
export const showActiveStores = (state) => {
  state.showActiveStores
}
export const target = (state) => {
  state.target
}

export default optionsSlice.reducer
