Ext.define('MDM.src.view.regionapp.Cmp.ExtractGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'regionapp-extract-grid',
  columnLines: true,
  rowLines: true,
  border: true,
  stateful: true,
  stateId: 'droppedgrid',
  modal: true,
  emptyText: '<h3> No Record Found <h3>',
  plugins: ['gridfilters', 'gridexporter'],
  viewConfig: {
    enableTextSelection: true
  },
  features: [
    {
      ftype: 'grouping',
      startCollapsed: true,
      groupHeaderTpl: '{name} ({rows.length} Material{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  columns: [
    {
      text: 'Material',

      flex: 1,
      sortable: true,
      minWidth: 90,
      dataIndex: 'MaterialCode',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Description',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 250,
      editor: {
        // defaults to textfield if no xtype is supplied
      },
      dataIndex: 'Material_Description',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Extraction_Type',
      flex: 1,
      dataIndex: 'Extraction_Type',
      sortable: true,
      minWidth: 120,
      editor: {
        // defaults to textfield if no xtype is supplied
      },
      filter: 'list'
    },
    {
      text: 'Country',
      flex: 1,
      sortable: true,
      minWidth: 100,
      dataIndex: 'Country'
    },
    {
      text: 'Plant',
      flex: 1,
      sortable: true,
      minWidth: 100,
      dataIndex: 'Plant'
    },
    {
      text: 'Region',
      minWidth: 70,
      sortable: true,
      dataIndex: 'Region',
      filter: 'list'
    },
    {
      text: 'MaxDate',
      width: 90,
      sortable: true,

      dataIndex: 'MaxDate',
      filter: 'list'
    },
    {
      text: 'UOM',
      width: 120,
      sortable: true,

      dataIndex: 'UOM',
      filter: 'list'
    },
    {
      text: 'Value',
      width: 100,
      sortable: true,
      xtype: 'numbercolumn',
      format: '0,000.00',
      align: 'right',
      dataIndex: 'Value',
      filter: 'number'
    }
  ]
})
