export default {
  title: 'IPO-Prepack',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'IPOPrepack',
  description: '',
  iconClass: 'fa-align-center',
  color: 'lazur-bg',
  support: {
    maximizable: true,
    needfulldataforpreview: false,
    editable: true
  },
  group: 'Application'
}
