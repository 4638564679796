Ext.define('Spui.view.common.spotParameters.tabpanel.Mixin', {
  extend: 'Ext.Mixin',
  // Uncomment to give this component an xtype
  config: {
    noteCfg: 'Note',
    note: {},
    imgCfg: 'Img',
    img: {}
  },
  forceToLoadStore: true,

  applyInputFormCfg: function (c) {
    return window.ss(c)
  },
  applyNoteCfg: function (c) {
    return window.ss(c)
  },
  applyImgCfg: function (c) {
    return window.ss(c)
  },
  applyNote: function (b) {
    var setRec = this.getNoteCfg()

    var code = setRec.get('code')
    settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'container',
        height: 100,
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',

                handler: 'onConfigNote'
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    return b
  },
  updateNote: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  onConfigNote: function () {
    var setRec = this.getNoteCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        snippetPanel: {
          record: setRec
        },
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigNoteSave, this)
  },
  onConfigNoteSave: function (v, k) {
    var setRec = this.getNoteCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setNote(res)
  },
  applyImg: function (b) {
    var setRec = this.getImgCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'container',
        height: 200,
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',

              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',

                handler: 'onConfigImg'
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    // b.getStore().load()

    // store.fireEvent('load',store)
    return b
  },

  updateImg: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(2, f)
        } else {
          d.splice(2, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  onConfigImg: function () {
    var setRec = this.getImgCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        snippetPanel: {
          record: setRec
        },
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigImgSave, this)
  },
  onConfigImgSave: function (v, k) {
    var setRec = this.getImgCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setImg(res)
  }

  // applySelectedChartPanel: function (b) {

  // 	if (b && !b.isComponent) {
  // 		Ext.applyIf(b, {
  // 			title: tt('Spot Sales chart'),
  // 			xtype: 'spui-view-common-spotParameters-tabpanel-saleschart-main',
  // 			salesChartCfg: 'SpotSelected',
  // 			salesChartStoreCfg: 'SpotSelectedStore',
  // 			salesChartStore: {},
  // 			salesChart: {},

  // 			salesGridCfg: 'SelectedSpotSalesGrid',
  // 			salesGrid: false,
  // 			bind: {
  // 				SpotId: '{spotRecord.SpotId}'
  // 			}
  // 		});
  // 		b = Ext.create(b)
  // 	}
  // 	//b.getStore().load();

  // 	//store.fireEvent('load',store);
  // 	return b
  // },
  // updateSelectedChartPanel: function (f, g) {
  // 	if (g) {
  // 		g.destroy()
  // 	}
  // 	if (f) {
  // 		var d = this.items;
  // 		if (d) {
  // 			if (d.isMixedCollection) {
  // 				this.insert(0, f)
  // 			} else {
  // 				d.splice(0, 0, f)
  // 			}
  // 		} else {
  // 			this.items = [f]
  // 		}
  // 	}
  // },
  // applySalesChartPanel: function (b) {
  // 	if (b && !b.isComponent) {
  // 		Ext.applyIf(b, {
  // 			title: tt('Spot Sales chartwww'),
  // 			xtype: 'spui-view-common-spotParameters-tabpanel-saleschart-main',
  // 			bind: {
  // 				SpotId: '{spotRecord.SpotId}'
  // 			}

  // 		});

  // 		b = Ext.create(b)
  // 	}
  // 	//b.getStore().load();

  // 	//store.fireEvent('load',store);
  // 	return b
  // },
  // updateSalesChartPanel: function (f, g) {

  // 	if (g) {
  // 		g.destroy()
  // 	}
  // 	if (f) {
  // 		var d = this.items;
  // 		if (d) {
  // 			if (d.isMixedCollection) {
  // 				this.insert(0, f)
  // 			} else {
  // 				d.splice(0, 0, f)

  // 			}
  // 		} else {
  // 			this.items = [f]
  // 		}
  // 	}
  // },
  // applyNomalizedChart: function (b) {
  // 	if (b && !b.isComponent) {

  // 		Ext.applyIf(b, {
  // 			title: tt('Normalized Chart'),
  // 			xtype: 'spui-view-common-spotParameters-tabpanel-nomalizedchart-main',
  // 			bind: {
  // 				SpotId: '{spotRecord.SpotId}'
  // 			}
  // 		});

  // 		b = Ext.create(b)
  // 	}
  // 	//b.getStore().load();

  // 	//store.fireEvent('load',store);
  // 	return b
  // },
  // updateNomalizedChart: function (f, g) {

  // 	if (g) {
  // 		g.destroy()
  // 	}
  // 	if (f) {
  // 		var d = this.items;
  // 		if (d) {
  // 			if (d.isMixedCollection) {
  // 				this.insert(1, f)
  // 			} else {
  // 				d.splice(1, 0, f)

  // 			}
  // 		} else {
  // 			this.items = [f]
  // 		}
  // 	}
  // },
  // listeners: {

  // 	// activate: function(p) {
  // 	// 	//if (p.forceToLoadStore)
  // 	//      p.getSimilarGridStore().load();
  // 	//      p.forceToLoadStore=false;
  // 	// }
  // },
  // applySimilarSpotId: function (c) {
  // 	this.getSalesChartPanel().setSimilarSpotId(c)
  // },
})
