Ext.define('MDM.Btl.cmp.HeaderBarMixin', {
  extend: 'Ext.Mixin',
  onLoadRpStore: function (store) {
    var me = this

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')
    if (ReportingPeriodID) return
    var rpcombo = me.lookup('rpcombo')
    var rec = store.findRecord('acPeriod', true)
    if (rec && rpcombo) {
      rpcombo.setValue(rec.get('value'))
    }
  },
  onChangeRpCombo: function (combo, v) {
    var me = this

    var dhStore = me.getViewModel().getStore('dhStore')

    var record = combo.selection
    this.getViewModel().set('isActivePeriod', record.get('acPeriod'))
    dhStore.getProxy().setExtraParam('ReportingPeriodID', v)
    var dhcombo = me.lookup('dhcombo')
    dhcombo.setValue(null)
    dhStore.load()
  },
  onMrComboChange: function (combo, v) {
    var me = this

    var rcombo = this.lookup('rcombo')

    var rStore = me.getViewModel().getStore('rStore')
    rStore.getProxy().setExtraParam('MegaRegionID', v)
    rStore.load()
  },
  onDhComboSelect: function (combo, record) {
    var me = this

    var title = ['Select DPI', 'DPI Segment', 'Product Group', 'DPI Family']
    var depth = record.get('depth')
    combo.setFieldLabel(title[depth])
    me.getViewModel().set('DPIHierarcy', record)
  },
  onSubmit: function () {
    var me = this

    var constarinView = me.getViewModel().get('constarinView')
    if (constarinView) {
      me.loadDataDisc()
    } else {
      this.loadData()
    }
  },
  onChangeMaterialType: function (btn, activeItem) {
    var me = this

    var viewModel = me.getViewModel()

    var store = this.getViewModel().getStore('mainStore')

    var discrepancyStore = this.getViewModel().getStore('discrepancyStore')

    var MaterialType = btn.itemId

    var cycleBtn = this.getViewModel().get('btncy')
    viewModel.set('MaterialType', MaterialType)
    store.clearFilter()
    discrepancyStore.clearFilter()
    if (MaterialType != 'All') {
      store.filter([
        {
          property: 'MaterialType',
          value: MaterialType
        }
      ])
      discrepancyStore.filter([
        {
          property: 'MaterialType',
          value: MaterialType
        }
      ])
    }
    if (cycleBtn == 'Active') {
      store.filter([
        {
          property: 'IsHide',
          value: false
        }
      ])
      discrepancyStore.filter([
        {
          property: 'IsHide',
          value: false
        }
      ])
    }
    me.refreshGrids()
  },
  onShowHideEditPanel: function (btn) {
    var isHidden = btn.isHidden

    var editpanel = this.lookup('edittabpanel')

    var iconcls = !isHidden ? 'fa fa-expand' : 'fa fa-compress'

    var tooltip = !isHidden ? 'Show edit panel' : 'Hide edit panel  '
    btn.setIconCls(iconcls)
    btn.setTooltip(tooltip)
    editpanel.setVisible(isHidden)
    btn.isHidden = !isHidden
  },
  onChangeView: function (btn, activeItem) {
    var me = this

    var viewModel = me.getViewModel()

    var MaterialType = viewModel.get('MaterialType')

    var discrepancyStore = this.getViewModel().getStore('discrepancyStore')

    var store = this.getViewModel().getStore('mainStore')

    var itemId = btn.itemId
    viewModel.set('cycleBtn', itemId)
    store.clearFilter()
    discrepancyStore.clearFilter()
    if (MaterialType != 'All') {
      store.filter([
        {
          property: 'MaterialType',
          value: MaterialType
        }
      ])
      discrepancyStore.filter([
        {
          property: 'MaterialType',
          value: MaterialType
        }
      ])
    }
    if (itemId == 'Active') {
      store.filter([
        {
          property: 'IsHide',
          value: false
        }
      ])
      discrepancyStore.filter([
        {
          property: 'IsHide',
          value: false
        }
      ])
    }
    me.refreshGrids()
  },
  onChangeSyncSc: function (btn, activeItem) {
    var me = this

    var viewModel = me.getViewModel()

    var itemId = btn.itemId
    viewModel.set('syncSc', itemId)
  },
  onChangeEditableView: function (btn, activeItem) {
    var me = this
    me.getViewModel().set('hideReadOnlyCol', btn.itemId)
  },
  refreshGrids: function () {
    var me = this

    var centergrid = me.lookup('centergrid')
    if (centergrid && centergrid.store && centergrid.store.count() > 0) {
      centergrid.reconfigure(centergrid.store, centergrid.column)
    }
    var discrepancy = me.lookup()
    if (discrepancy && discrepancy.store && discrepancy.store.count() > 0) {
      discrepancy.reconfigure(discrepancy.store, discrepancy.column)
    }
  },
  exportTo: function (btn) {
    var me = this
    var cfg = Ext.merge(
      {
        title: 'BTL List',
        fileName: 'GridExport' + '.' + (btn.ext || btn.type)
      },
      btn.cfg
    )

    var mainGrid = this.lookup('centergrid')
    mainGrid.saveDocumentAs(cfg)
  },
  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },
  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDPIChart: function () {
    var storeFWIP = this.getViewModel().getStore('DPIStoreFWIP')
    var storeFG = this.getViewModel().getStore('DPIStoreFG')
    var win = Ext.create('Ext.window.Window', {
      layout: 'fit',
      width: '80%',
      height: '80%',
      title: 'DPI Charts',
      modal: true,
      items: [
        {
          xtype: 'tabpanel',
          items: [
            {
              title: 'FWIP',
              iconCls: 'fa fa-chart-bar',
              xtype: 'btl-dpichart-dpichart',
              subtitle: 'FWIP',
              store: storeFWIP
            },
            {
              title: 'FG',
              iconCls: 'fa fa-chart-bar',
              xtype: 'btl-dpichart-dpichart',
              subtitle: 'FG',
              store: storeFG
            }
          ]
        }
      ]
    })
    var me = this

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')

    var mainGrid = me.lookup('centergrid')

    var MegaRegionID = me.getViewModel().get('MegaRegionID')

    var RegionID = me.getViewModel().get('RegionID')
    storeFWIP.getProxy().setExtraParam('DPISegmentID', DPIHierarcy.get('DPISegmentID'))
    storeFWIP.getProxy().setExtraParam('ProductGroupID', DPIHierarcy.get('ProductGroupID'))
    storeFWIP.getProxy().setExtraParam('DPIFamilyID', DPIHierarcy.get('DPIFamilyID'))
    storeFWIP.getProxy().setExtraParam('ReportingPeriodID', ReportingPeriodID)
    storeFWIP.getProxy().setExtraParam('MegaRegionID', MegaRegionID)
    storeFWIP.getProxy().setExtraParam('RegionID', RegionID)
    storeFWIP.getProxy().setExtraParam('Materialtype', 'FWIP')
    storeFG.getProxy().setExtraParam('DPISegmentID', DPIHierarcy.get('DPISegmentID'))
    storeFG.getProxy().setExtraParam('ProductGroupID', DPIHierarcy.get('ProductGroupID'))
    storeFG.getProxy().setExtraParam('DPIFamilyID', DPIHierarcy.get('DPIFamilyID'))
    storeFG.getProxy().setExtraParam('ReportingPeriodID', ReportingPeriodID)
    storeFG.getProxy().setExtraParam('MegaRegionID', MegaRegionID)
    storeFG.getProxy().setExtraParam('RegionID', RegionID)
    storeFG.getProxy().setExtraParam('Materialtype', 'FG')
    win.on('afterrender', function () {
      storeFWIP.load()
      storeFG.load()
    })
    win.show()
  },
  onDiscrepancyClick: function () {
    var win = Ext.create('MDM.Btl.Discrepancy.DiscrepancyDialog')
  },
  onDiscrepancyShow: function () {
    var me = this

    var win = Ext.create('MDM.Btl.dd.Discrepancy.Dialog')

    var store = win.store

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')

    var MegaRegionID = me.getViewModel().get('MegaRegionID')

    var RegionID = me.getViewModel().get('RegionID')

    var region = me.lookup('rcombo')

    var megaregion = me.lookup('mrcombo')
    var RegionName = region.selection.get('name')
    var MegaRegionName = megaregion.selection.get('name')
    win.setTitle(MegaRegionName + '/ ' + RegionName + ' Discrepancy View')
    win.show()

    store.getProxy().setExtraParam('ReportingPeriodID', ReportingPeriodID)
    store.getProxy().setExtraParam('MegaRegionID', MegaRegionID)
    store.getProxy().setExtraParam('RegionID', RegionID)
    store.load()
    win.on('select', 'onSelectDiscrepancy', this)
  },
  onNegativeInvShow: function () {
    var me = this

    var win = Ext.create('MDM.Btl.dd.NegativeInventory.Dialog')

    var store = win.store

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')

    var MegaRegionID = me.getViewModel().get('MegaRegionID')

    var RegionID = me.getViewModel().get('RegionID')

    var region = me.lookup('rcombo')

    var megaregion = me.lookup('mrcombo')
    var RegionName = region.selection.get('name')
    var MegaRegionName = megaregion.selection.get('name')
    win.setTitle(MegaRegionName + '/ ' + RegionName + ' Negative Inventory')
    win.show()

    store.getProxy().setExtraParam('ReportingPeriodID', ReportingPeriodID)
    store.getProxy().setExtraParam('MegaRegionID', MegaRegionID)
    store.getProxy().setExtraParam('RegionID', RegionID)
    store.load()
    win.on('select', 'onSelectDiscrepancy', this)
  },
  onMassAccept: function () {
    var me = this

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')

    var MegaRegionID = me.getViewModel().get('MegaRegionID')

    var RegionID = me.getViewModel().get('RegionID')

    var region = me.lookup('rcombo')

    var megaregion = me.lookup('mrcombo')
    var rpcombo = me.lookup('rpcombo')
    var win = Ext.create('MDM.Btl.dd.MassApply.Dialog', {
      isEditable: rpcombo.selection.get('acPeriod')
    })
    var MegaRegionName = megaregion.selection.get('name')
    var store = win.store
    win.show()
    store.getProxy().setExtraParam('ReportingPeriodID', ReportingPeriodID)
    store.getProxy().setExtraParam('MegaRegionID', MegaRegionID)
    store.getProxy().setExtraParam('RegionID', RegionID)
    store.load()
    win.on('select', 'onMassAccept', this)
  },
  onRuleBased: function () {
    var me = this

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')

    var MegaRegionID = me.getViewModel().get('MegaRegionID')

    var RegionID = me.getViewModel().get('RegionID')

    var region = me.lookup('rcombo')

    var megaregion = me.lookup('mrcombo')
    var rpcombo = me.lookup('rpcombo')
    var win = Ext.create('MDM.Btl.dd.RuleBased.Dialog', {
      isEditable: rpcombo.selection.get('acPeriod'),
      headerBarMixin: me
    })
    var MegaRegionName = megaregion.selection.get('name')
    var store = win.store
    win.show()
    store.getProxy().setExtraParam('ReportingPeriodID', ReportingPeriodID)
    store.getProxy().setExtraParam('MegaRegionID', MegaRegionID)
    store.getProxy().setExtraParam('RegionID', RegionID)
    store.load()
    win.on('select', 'onMassAccept', this)
  },
  onSelectDiscrepancy: function (a, rec) {
    var me = this
    var DPIHierarcy = this.getViewModel().get('DPIHierarcy')
    var dhcombo = me.lookup('dhcombo')
    dhcombo.changeRecord('DPIFamilyID', rec.get('DPIFamilyID'))
    me.loadData()
  },
  onChangeMainView: function (btn, acItem) {
    var me = this

    var viewModel = me.getViewModel()

    var uppertabPanel = me.lookup('uppertabPanel')

    var edittabpanel = me.lookup('edittabpanel')

    var store = viewModel.getStore('rpStore')
    viewModel.set('constarinView', acItem.viewId)
    if (acItem.viewId) {
      store.filter('hasConstrain', '1')
      edittabpanel.setActiveItem(0)
      uppertabPanel.setActiveItem(0)
      me.loadDataDisc()
    } else {
      store.clearFilter()
      me.loadData()
    }
  }
})
