export default {
  title: 'ECharts Map',
  subTitle: 'ECharts',
  version: '1.0',
  namespace: 'EChartsMap',
  description: '',
  iconClass: 'fa-map',
  color: 'lazur-bg',
  support: {
    maximizable: true,
    needfulldataforpreview: false
  },
  group: 'Maps'
}
