Ext.define('po.cmp.OpenSas', {
  extend: 'Ext.grid.Panel',
  xtype: 'opensas',
  alias: 'widget.opensas',
  height: 500,
  width: 650,
  enableLocking: true,
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView().normalView,
      grouping = normalView.getFeature('grouping')

    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText

    me.unlockText = i18n.GridConfig_unlockText
    me.lockText = i18n.GridConfig_lockText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'gridexporter',
      id: 'gridexport'
    }
  ],
  listeners: {
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady'
  },
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    },
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      dataIndex: 'EBELN',
      text: '&nbsp;',
      bind: {
        text: '{i18n.opensas_Column_OrderId}',
        kayit: '{i18n.GroupGrid_SummaryRow_text}'
      },
      width: 100,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      },
      summaryType: 'count',
      summaryRenderer: function (value, summaryData) {
        var text = this.kayit ? this.kayit : ''
        var sumObject = summaryData
        return Ext.String.format('{0} ' + text, value)
      },
      setKayit: function (value) {
        this.kayit = value
      }
    },
    {
      dataIndex: 'EBELP',
      bind: {
        text: '{i18n.opensas_Column_LineId}'
      },
      width: 100,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'NAME1',
      bind: {
        text: '{i18n.opensas_Column_Vendor}'
      },
      width: 115,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'BEDAT',
      bind: {
        text: '{i18n.opensas_Column_OrderDate}'
      },
      width: 100,
      align: 'center',
      renderer: function (value) {
        var date = Ext.Date.parse(value, 'Y-m-d')
        if (date == null) return '-'

        return Ext.util.Format.date(date, 'Y-m-d')
      },
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'TARIH',
      bind: {
        text: '{i18n.opensas_Column_DeliveryDate}'
      },
      width: 100,
      align: 'center',
      renderer: function (value) {
        var date = Ext.Date.parse(value, 'Y-m-d')
        if (date == null) return '-'

        return Ext.util.Format.date(date, 'Y-m-d')
      },
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'MENGE',
      bind: {
        text: '{i18n.opensas_Column_Quantity}'
      },
      width: 90,
      margin: '0 10 0 0',
      align: 'right',
      filter: {
        type: 'number'
      },
      summaryType: 'sum',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.0')
      },
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.0')
      }
    }
  ]
})
