import { useEffect, useState } from 'react'
import { Col, Dropdown, Form, Row } from 'react-bootstrap'
import cx from 'classnames'
import { useReduxState } from '../../../hooks'
import {
  headerSlice,
  serviceModalSlice,
  tuiSlice,
  viewContainerSlice,
  ViewContainerState
} from '../../../store/slices'
import {
  getDemandTree,
  getLoadTree,
  getRunInfo,
  getServiceStatus,
  getSnapshot,
  setResetPlan
} from '../../../store/api/endpoints'
import { getTreeQueryParams } from '../../../utils'
import styles from './AppControls.module.scss'

export default function AppControls() {
  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)
  const [{ currentView, currentViewIcon }, { setCurrentView, setCurrentViewIcon }] = useReduxState(
    viewContainerSlice.selectSlice,
    viewContainerSlice.actions
  )
  const [triggerSetResetPlan] = setResetPlan.useLazyQuery()
  const [triggerGetSnapshot] = getSnapshot.useLazyQuery()
  const [triggerGetDemandTree] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })
  const [triggerGetLoadTree] = getLoadTree.useMutation({
    fixedCacheKey: 'load-tree'
  })
  const [, { setSavePlanModal, setSnapshotModal }] = useReduxState(undefined, headerSlice.actions)
  const [serviceModal] = useReduxState(serviceModalSlice.selectSlice)

  const serviceStatus = getServiceStatus.useQueryState()
  const currentScenarioId = serviceStatus.data?.PlanInfo?.CurrentScenarioID
  const runInfoResponse = getRunInfo.useQueryState(currentScenarioId as number)

  const scenarioDefaultValue = `${serviceModal.selectedService?.ScenarioID}.${serviceModal.selectedService?.ScenarioName}`
  const runInfoDefaultId = runInfoResponse.data?.find((item) => item.Active === 1)?.RunInfoID
  const [selectedRunInfoId, setSelectedRunInfoId] = useState(runInfoDefaultId)

  useEffect(() => {
    if (currentView === undefined) {
      setCurrentView(config.settings.defaultView)
    }
    let icon
    switch (currentView) {
      case 'Map':
        icon = 'slvy-ui-icon-map-route'
        break
      case 'Table':
        icon = 'slvy-ui-icon-table'
        break
      case 'Tree Table':
        icon = 'slvy-ui-icon-minus-outline'
        break
      default:
        icon = 'slvy-ui-icon-map-route'
    }
    setCurrentViewIcon(icon)
  }, [config.settings.defaultView, currentView, setCurrentView, setCurrentViewIcon])

  function handleChangeView(view: ViewContainerState['currentView'], icon: string) {
    setCurrentView(view)
    setCurrentViewIcon(icon)
  }

  function handleSavePlanModal() {
    setSavePlanModal(true)
  }

  function handleReset() {
    const currentRunInfoId = serviceStatus.data?.PlanInfo?.CurrentRunInfoID
    triggerSetResetPlan(currentRunInfoId as number).then((response) => {
      if (response.isSuccess) {
        triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
        triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })
      }
    })
  }

  function handleSnapshot() {
    triggerGetSnapshot()
    setSnapshotModal(true)
  }

  function handleRunInfoChange(event) {
    const currentRunInfoId = event.target.value
    setSelectedRunInfoId(currentRunInfoId)
    const runInfoId = currentRunInfoId != null ? Number(currentRunInfoId) : 0

    if (currentRunInfoId !== 0 && currentRunInfoId !== selectedRunInfoId) {
      triggerSetResetPlan(runInfoId).then(() => {
        triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })
        triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
      })
    }
  }

  return (
    <div className={styles.appControls}>
      <Dropdown>
        <Dropdown.Toggle className={styles.appControlToggle}>
          <i className={cx(styles.appMapControlToggle, 'slvy-ui-icon-bolt')} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.appControlMenu}>
          <Dropdown.Item className={styles.appControlMenuItem} onClick={handleReset}>
            <i className="slvy-ui-icon-reset" />
            Reset
          </Dropdown.Item>
          <Dropdown.Item className={styles.appControlMenuItem} onClick={handleSavePlanModal}>
            <i className="slvy-ui-icon-save" />
            Save Plan
          </Dropdown.Item>
          <Dropdown.Item className={styles.appControlMenuItem} onClick={handleSnapshot}>
            <i className="slvy-ui-icon-snapshot-regular" />
            Snapshot
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle className={styles.appControlToggle}>
          <i className={cx(styles.appMapControlToggle, currentViewIcon)} />
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles.appControlMenu}>
          <Dropdown.Item
            className={styles.appControlMenuItem}
            onClick={() => handleChangeView('Map', 'slvy-ui-icon-map-route')}
          >
            <i className="slvy-ui-icon-map-route" />
            Map
          </Dropdown.Item>
          <Dropdown.Item
            className={styles.appControlMenuItem}
            onClick={() => handleChangeView('Table', 'slvy-ui-icon-table')}
          >
            <i className="slvy-ui-icon-table" />
            Table
          </Dropdown.Item>
          <Dropdown.Item
            className={styles.appControlMenuItem}
            onClick={() => handleChangeView('Tree Table', 'slvy-ui-icon-minus-outline')}
          >
            <i className="slvy-ui-icon-minus-outline" />
            Tree Table
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle className={styles.appControlToggle}>
          <i className={cx(styles.appMapControlToggle, 'slvy-ui-icon-snapshot-regular')} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={cx(styles.appControlMenu, styles.appDropdownMenu)}>
          <Row className={styles.appControlMenuRow}>
            <Col sm="3">
              <Form.Label className={styles.appControlMenuLabel}>Scenario:</Form.Label>
            </Col>
            <Col sm="9">
              <Form.Control readOnly defaultValue={scenarioDefaultValue} type="text" />
            </Col>
          </Row>

          <Row className={styles.appControlMenuRow}>
            <Col sm="3">
              <Form.Label className={styles.appControlMenuLabel}>RunInfo:</Form.Label>
            </Col>
            <Col sm="9">
              <Form.Select defaultValue={runInfoDefaultId} onChange={handleRunInfoChange}>
                {runInfoResponse.data?.map((item, index) => (
                  <option key={index} value={item.RunInfoID}>
                    {item.RunInfoName}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>

      {currentView === 'Map' && (
        <Dropdown>
          <Dropdown.Toggle className={styles.appControlToggle}>
            <i className={cx(styles.appMapControlToggle, 'slvy-ui-icon-tools')} />
          </Dropdown.Toggle>
          <Dropdown.Menu className={cx(styles.appControlMenu, styles.appDropdownMenu)}>
            <Row className={styles.appControlMenuRow}>
              <Col sm="4">
                <Form.Label>Smooth Factor</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Control defaultValue="2" min="0" type="number" />
              </Col>
            </Row>
            <Row className={styles.appControlMenuRow}>
              <Col sm="4">
                <Form.Label>Weight</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Control defaultValue="3" min="1" type="number" />
              </Col>
            </Row>
            <Row className={styles.appControlMenuRow}>
              <Col sm="4">
                <Form.Label>Opacity</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Control defaultValue="0.8" max="1" min="0" step="0.05" type="number" />
              </Col>
            </Row>
            <Row className={styles.appControlMenuRow}>
              <Col sm="4">
                <Form.Label>Show on Map</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Check checked />
              </Col>
            </Row>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}
