import { useEffect, useRef, ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { isNil, debounce } from 'lodash'
import { useTheme } from '../../contexts/ThemeContext'
import { PaginationProps } from './Pagination.types'

export default function Pagination({
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  pageCount,
  pageIndex,
  pageSize,
  gotoPage,
  setPageSize,
  pageSizeOptions,
  totalRows,
  firstPage,
  lastPage,
  showPageSizeOptions
}: PaginationProps) {
  const inputRef = useRef()
  const styles = useTheme()

  useEffect(() => {
    if (!isNil(inputRef.current)) {
      inputRef.current.value = pageIndex
    }
  }, [pageIndex])

  const handleMoveToPrevPage = () => {
    previousPage()
  }

  const handleMoveToNextPage = () => {
    nextPage()
  }

  const handleMoveToFirstPage = () => {
    firstPage()
  }

  const handleMoveToLastPage = () => {
    lastPage()
  }

  const handleChangePage = debounce((event) => {
    const page = Number(event.target.value)
    if (page > 0 && page < pageCount) {
      gotoPage(page)
    }
  }, 500)

  const handleChangePageSize = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(event.target.value))
  }

  return (
    <div className={styles.pagination}>
      <div className={styles.paginationLeft}>
        <button
          className={styles.paginationFirstPage}
          disabled={!canPreviousPage}
          type="button"
          onClick={handleMoveToFirstPage}
        >
          <i className="fa fa-fw fa fa-chevron-left" />
          <i className="fa fa-fw fa fa-chevron-left" />
        </button>
        <button
          className={styles.paginationPreviousPage}
          disabled={!canPreviousPage}
          type="button"
          onClick={handleMoveToPrevPage}
        >
          <i className="fa fa-fw fa fa-chevron-left" />
        </button>
        <div className={styles.separator} />
        <div className={styles.wrapper}>
          <span>Page</span>{' '}
          <Form.Control
            ref={inputRef}
            className={styles.paginationForm}
            defaultValue={pageIndex}
            max={pageCount}
            min={1}
            type="number"
            onChange={handleChangePage}
          />{' '}
          <span>of</span> <span>{pageCount}</span>
        </div>
        <div className={styles.separator} />
        <button
          className={styles.paginationNextPage}
          disabled={!canNextPage}
          type="button"
          onClick={handleMoveToNextPage}
        >
          <i className="fa fa-fw fa fa-chevron-right" />
        </button>
        <button
          className={styles.paginationLastPage}
          disabled={!canNextPage}
          type="button"
          onClick={handleMoveToLastPage}
        >
          <i className="fa fa-fw fa fa-chevron-right" />
          <i className="fa fa-fw fa fa-chevron-right" />
        </button>
        {showPageSizeOptions ? (
          <>
            <div className={styles.separator} />
            <div className={styles.paginationPageSizeOptions}>
              <span>Page Size</span>
              <Form.Select
                className={styles.paginationForm}
                value={pageSize}
                onChange={handleChangePageSize}
              >
                {pageSizeOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Form.Select>{' '}
            </div>
          </>
        ) : null}
      </div>
      <div className={styles.paginationRight}>
        Displaying{' '}
        <span>
          <span>{pageIndex * pageSize - pageSize + 1}</span>{' '}
        </span>{' '}
        <span>-</span>{' '}
        <span>
          <span>{pageIndex === pageCount ? totalRows : pageIndex * pageSize}</span>
        </span>{' '}
        of{' '}
        <span>
          <span>{totalRows}</span>
        </span>
      </div>
    </div>
  )
}
