/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '../store'
;(globalThis as any).rccplog = (globalThis as any).rccplog || process.env.NODE_ENV === 'development'

type LogLevel = 'log' | 'info' | 'warn' | 'error'

class CustomConsole {
  static logLevel: LogLevel = 'log'

  static log(message: any): void {
    if (CustomConsole.shouldLog('log')) {
      console.log(message)
    }
  }

  static info(message: any): void {
    if (CustomConsole.shouldLog('info')) {
      console.info(message)
    }
  }

  static warn(message: any): void {
    if (CustomConsole.shouldLog('warn')) {
      console.warn(message)
    }
  }

  static error(message: any): void {
    if (CustomConsole.shouldLog('error')) {
      console.group('%c ERROR DETAILS', 'color: red; font-size: 18px; font-weight: bold;')
      console.log(message)
      console.groupEnd()
    }
  }

  private static shouldLog(level: LogLevel): boolean {
    const levels: LogLevel[] = ['log', 'info', 'warn', 'error']
    const globalLog = (globalThis as any).rccplog
    const { isLoggingEnabled } = store.getState().logging

    return (
      level === 'error' ||
      (globalLog && levels.indexOf(level) >= levels.indexOf(CustomConsole.logLevel)) ||
      isLoggingEnabled
    )
  }
}

globalThis.CustomConsole = CustomConsole
export default CustomConsole
