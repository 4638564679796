import React, { Component } from 'react'
import _ from 'lodash'
import numeral from 'numeral'

export class GridSingleDetailSummary extends Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    const {
      detailDataList = [],
      isDetailData = true,
      metricsWithProps: _MetricsWithProps = []
    } = this.props

    return (
      <div className="slvy-ui-section -end">
        {isDetailData && detailDataList.length ? (
          <div className="-cell single-wrapper-cell">
            {detailDataList.map((detailDataItem, detailDataItemIndex) => {
              const moreThanOne = detailDataList.length > 1
              const isFirstMetric = detailDataItemIndex === 0
              const firstMetricClass = isFirstMetric ? ' is-first-metric ' : ''
              const lowerThanTwoClass = !moreThanOne ? ' full-width ' : ''
              const [baselineDetail, planDetail] = detailDataItem

              const valuesData = _MetricsWithProps[detailDataItemIndex] || {}
              const { Format } = valuesData

              return (
                <div
                  key={detailDataItemIndex}
                  className={`cell-metric-item -cell ${firstMetricClass} ${lowerThanTwoClass}`}
                >
                  <div className="-cellContent">
                    <div className="-baseline">
                      {detailDataItem.length > 0 ? (
                        numeral(isFirstMetric ? baselineDetail : planDetail).format(Format)
                      ) : (
                        <strong className="fw-bold">&nbsp;</strong>
                      )}
                    </div>
                    {isFirstMetric ? (
                      <div className="-planned">{numeral(planDetail).format(Format)}</div>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }
}
