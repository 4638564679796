import { transform } from 'lodash'
import { IRowNode } from 'ag-grid-community'
import { PluginTypes } from '@/BasePlugin'
import { getFieldConfigsValues } from '../index'
import { GetFieldConfigsValuesProps, GridRef, IRowData, TfieldConfigs } from '../../types'
import { GetMultiRowSelectedResultsReturnType, CellParams } from './selection.types'

const getSelectionCellParams = (fieldConfigs: TfieldConfigs): CellParams => {
  return transform(
    fieldConfigs,
    (result: CellParams, { fieldName, dataType }) => {
      if (fieldName) {
        // eslint-disable-next-line no-param-reassign
        result[fieldName] = PluginTypes.fromString(dataType)
      }
    },
    {}
  )
}

const getMultiRowSelectedResults = ({
  fieldConfigs,
  rows
}: Required<GetFieldConfigsValuesProps>): GetMultiRowSelectedResultsReturnType => {
  return {
    ...getFieldConfigsValues({ fieldConfigs, rows }),
    selectedRowCount: rows?.length ?? 0
  }
}

const getSelectedRowsAfterFilterAndSort = (gridRef: GridRef): IRowData[] => {
  const result: IRowData[] = []
  gridRef?.current?.api?.forEachNodeAfterFilterAndSort?.((node: IRowNode<IRowData>) => {
    if (node?.isSelected?.() && typeof node?.data !== 'undefined') {
      result.push(node.data)
    }
  })
  return result
}

export { getSelectionCellParams, getMultiRowSelectedResults, getSelectedRowsAfterFilterAndSort }
