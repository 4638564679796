Ext.define('MDM.src.view.globalapp.Panel.Time.model.TimeModel', {
  extend: 'MDM.model.BaseTree',
  entityName: 'gillettev3/Global_MDM_Time',
  remoteFilter: true,
  fields: [
    {
      name: 'Title',
      type: 'string'
    },
    {
      name: 'Latitude',
      type: 'float'
    },
    {
      name: 'Longitude',
      type: 'float'
    }
  ]
})
