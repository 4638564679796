Ext.define('Spui.view.tracking.Tracking', {
  extend: 'Ext.Container',
  // Uncomment to give this component an xtype
  xtype: 'spui-view-tracking-tracking',
  viewModel: 'spui-view-tracking-trackingviewmodel',
  controller: 'spui-view-tracking-trackingcontroller',
  layout: 'border',
  bodyBorder: true,
  border: true,
  defaults: {
    split: true
  },
  items: [
    {
      bind: {
        manualTitle: tt('Analysis & Approve of Campaign')
      },
      setManualTitle: function (valu) {
        this.setTitle(tt(valu))
      },
      region: 'center',
      margin: '5 0 0 0',
      flex: 1,
      border: true,
      xtype: 'spui-view-tracking-navigation-main'
    },
    {
      header: false,
      collapsible: false,
      region: 'east',
      flex: 1,
      bind: {
        title: '{spotRecord.MaterialDecription}',
        disabled: '{!spotRecord.SpotId}'
      },
      margin: '5 0 0 0',
      border: true,
      xtype: 'tabpanel',
      ui: 'spui',
      items: [
        {
          xtype: 'spui-view-common-productinfo-main',
          bind: {
            manualTitle: tt('MaterialInformation')
          },
          setManualTitle: function (valu) {
            this.setTitle(tt(valu))
          },
          searchBox: false
        },
        {
          xtype: 'spui-view-common-spotparameters-main',
          bind: {
            manualTitle: tt('Spot Bilgileri')
          },
          setManualTitle: function (valu) {
            this.setTitle(tt(valu))
          },
          inputFormCfg: 'SpotinputFormTracking',
          noteCfg: 'SpotinputNotTracking',
          imgCfg: 'ImgTracking'
        },
        {
          xtype: 'spui-view-common-similarity-main',
          similarGridCfg: 'SimilarGridTracking',
          itemId: 'SimilarGridTracking',
          bind: {
            manualTitle: tt('Benzer Ürünler'),
            spotId: '{spotRecord.SpotId}'
          },
          setManualTitle: function (valu) {
            this.setTitle(tt(valu))
          }
        },
        {
          xtype: 'spui-view-common-recom-main',
          itemId: 'untilDistributionTab',
          recomGridCfg: 'trackingrecom',
          bind: {
            manualTitle: tt('Bölgesel Dağıtım'),
            spotId: '{spotRecord.SpotId}',
            hidden: '{!(isDistributionTab || isContractTab)}'
          },
          setManualTitle: function (valu) {
            this.setTitle(tt(valu))
          },
          setHidden: function (data) {
            if (data) {
              this.up().child('#untilDistributionTab').tab.show()
              var tabpanel = this.up().child('#untilDistributionTab').up()
              if (tabpanel) {
                var activeTab = tabpanel.getActiveTab()
                if (
                  activeTab &&
                  (activeTab.itemId == 'DistributionTab' || activeTab.itemId == 'ContractTab')
                ) {
                  tabpanel.setActiveTab(3)
                }
              }
            } else {
              this.up().child('#untilDistributionTab').tab.hide()
            }
          }
        },
        {
          xtype: 'spui-view-common-recom-distribution-main',
          recomGridCfg: 'distributiontrackingrecom',
          itemId: 'DistributionTab',
          bind: {
            manualTitle: tt('Bölgesel Dağıtım'),
            spotId: '{spotRecord.SpotId}',
            hidden: '{isDistributionTab}'
          },
          setManualTitle: function (valu) {
            this.setTitle(tt(valu))
          },
          setHidden: function (data) {
            if (data) {
              this.up().child('#DistributionTab').tab.show()
              var tabpanel = this.up().child('#DistributionTab').up()
              if (tabpanel) {
                var activeTab = tabpanel.getActiveTab()
                if (
                  activeTab &&
                  (activeTab.itemId == 'untilDistributionTab' || activeTab.itemId == 'ContractTab')
                ) {
                  tabpanel.setActiveTab(4)
                }
              }
            } else {
              this.up().child('#DistributionTab').tab.hide()
            }
          }
        },
        {
          xtype: 'spui-view-common-recom-contract-main',
          setManualTitle: function (valu) {
            this.setTitle(tt(valu))
          },
          itemId: 'ContractTab',
          recomGridCfg: 'contracttrackingrecom',
          bind: {
            spotId: '{spotRecord.SpotId}',
            hidden: '{isContractTab}',
            manualTitle: tt('Bölgesel Dağıtım')
          },
          setHidden: function (data) {
            if (data) {
              this.up().child('#ContractTab').tab.show()
              var tabpanel = this.up().child('#ContractTab').up()
              if (tabpanel) {
                var activeTab = tabpanel.getActiveTab()
                if (
                  activeTab &&
                  (activeTab.itemId == 'DistributionTab' ||
                    activeTab.itemId == 'untilDistributionTab')
                ) {
                  tabpanel.setActiveTab(5)
                }
              }
            } else {
              this.up().child('#ContractTab').tab.hide()
            }
          }
        }
      ]
    }
  ]
})
