import { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import _ from 'lodash'
import Operations from './Operations'

export default class CalculatedFields extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.state = {
      text: props.value,
      value: {}
    }
    this.count = 0
    this.queryDic = {}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: this.reObj(this.repMap(nextProps.value)),
      text: nextProps.value
    })
  }

  handleChange(value) {
    const text = this.getFormulaString(value)
    this.setState({
      value: { formula: value.formula, args: value.args },
      text
    })
    if (this.props.onChange) {
      this.props.onChange(text)
    }
  }

  handleTextChange(event) {
    const { value } = event.target
    this.count = 0
    this.setState({ value: this.reObj(this.repMap(value)), text: value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  getFormulaString({ formula, args }) {
    let str = ''
    switch (formula) {
      case 'If':
        str += `${this.getFormulaString(args.first)}${
          args.comparison ? args.comparison : '='
        }${this.getFormulaString(args.second)}?${this.getFormulaString(
          args.isTrue
        )}:${this.getFormulaString(args.isFalse)}`

        break
      case 'SimpleValue':
        str += `'${args.value}','${args.type}'`
        break
      case 'Format':
        str += `'${args.column}','${args.format}'`
        break
      case 'ColumnValue':
        str += `'${args.name}'`
        break
      case 'Add':
      case 'Subtract':
      case 'Multiply':
      case 'Divide':
        str += `${this.getFormulaString(args.first)},${this.getFormulaString(args.second)}`
        break
      default:
        break
    }
    return `${formula}(${str})`
  }

  repMap(str) {
    const regex = /[\w]+\([^()]+\)/g
    let m
    let yyy = str
    const fillQueryDic = (match, groupIndex) => {
      this.count++
      yyy = yyy.replace(match, `x${this.count}`)
      this.queryDic[`x${this.count}`] = match
    }
    do {
      m = regex.exec(str)
      if (m) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        m.forEach(fillQueryDic)
      }
    } while (m)
    return yyy !== str ? this.repMap(yyy) : yyy
  }

  reObj(node) {
    const nodeValue = this.queryDic[node]
    const regex = /(^[\w]+)\((.*?)\)$/g
    const matches = regex.exec(nodeValue)
    if (matches) {
      const obj = { formula: matches[1] }
      switch (matches[1]) {
        case 'If':
          {
            const mat = this.getmatches(
              /([A-Za-z-0-9]+)(=|<=|>=|!=|<|>)([A-Za-z-0-9]+)[?]([A-Za-z-0-9]+)[:]([A-Za-z-0-9]+)/g,
              matches[2]
            )
            obj.args = {
              first: this.reObj(mat[1]),
              comparison: mat[2],
              second: this.reObj(mat[3]),
              isTrue: this.reObj(mat[4]),
              isFalse: this.reObj(mat[5])
            }
          }
          break
        case 'SimpleValue':
          {
            const mat = this.getmatches(/'([^']+|)','([^']+)'/g, matches[2])
            obj.args = {
              value: mat[1],
              type: mat[2]
            }
          }
          break
        case 'ColumnValue':
          {
            const mat = this.getmatches(/'([\S]+)'/g, matches[2]) // column names with spaces could not be selected
            obj.args = {
              name: mat[1]
            }
          }
          break
        case 'Format':
          {
            const mat = this.getmatches(/([\S]+),([\S]+)/g, matches[2])
            obj.args = {
              format: mat[1],
              column: mat[2]
            }
          }
          break
        case 'Add':
        case 'Subtract':
        case 'Multiply':
        case 'Divide':
          {
            const mat = this.getmatches(/([\S]+),([\S]+)/g, matches[2])
            obj.args = {
              first: this.reObj(mat[1]),
              second: this.reObj(mat[2])
            }
          }
          break
        default:
          break
      }
      return obj
    }
    return null
  }

  getmatches(regex, value) {
    return regex.exec(value)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(nextProps.value, this.props.value) ||
      !_.isEqual(nextProps.columns, this.props.columns) ||
      !_.isEqual(nextProps.dataTypes, this.props.dataTypes) ||
      !_.isEqual(this.state.value, nextState.value)
    ) {
      return true
    }
    return false
  }

  render() {
    const { value, text } = this.state
    return (
      <div>
        <FormControl
          as="textarea"
          className="w-100 mb-3"
          size="sm"
          value={text}
          onChange={this.handleTextChange}
        />
        <Operations
          columns={this.props.columns}
          dataTypes={this.props.dataTypes}
          value={value}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
