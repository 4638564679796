import { useRef, useLayoutEffect } from 'react'
import cx from 'classnames'
import Highlight from './Highlight/index.ts'
import { getPageUrl } from '../helpers.tsx'
import { IListProps } from './List.types'
import classes from './List.module.scss'

const List = ({ data, cursor, query, environment, catalogId, handleOnClick }: IListProps) => {
  const activeSearchOptionRef = useRef<HTMLLIElement | null>(null)

  useLayoutEffect(() => {
    activeSearchOptionRef?.current?.scrollIntoView?.({ block: 'nearest' })
  }, [cursor])

  const result = data.map(({ id, name, parentMenuId, breadCrumb, keywords }, index) => {
    const url = getPageUrl(catalogId, environment, id, parentMenuId)
    const isActive = cursor === index
    const extraAttr = isActive ? { ref: activeSearchOptionRef } : {}
    return (
      <li
        key={index}
        className={cx(classes.listItem, 'cp', { [classes.active]: isActive })}
        data-testid={`list-item-${index}`}
        {...extraAttr}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          className={cx(classes.highlightContainer, 'd-block', 'p-3')}
          data-testid={`list-redirect-btn-${index}`}
          onClick={() => handleOnClick(url)}
        >
          <Highlight data={[name, breadCrumb, keywords.toString()]} value={query} />
        </span>
      </li>
    )
  })

  return <>{result}</>
}

export default List
