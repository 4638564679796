import {
  SELECT_PRODUCT,
  TOGGLE_PRODUCTION_INFO,
  TOGGLE_RESOURCE_CHART,
  SAVE_RESOURCE_INDEX,
  ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE,
  PRODUCT_SELECT_PRODUCT,
  PRODUCT_TOGGLE_PRODUCTION_INFO,
  PRODUCT_TOGGLE_RESOURCE_CHART,
  PRODUCT_SAVE_RESOURCE_INDEX,
  PRODUCT_ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE
} from '../actions/actionTypes'
import { updateObject } from './utility'

const initialState = {
  clickedProductInfo: {
    periodIndex: undefined,
    productIndex: undefined,
    isSelectionOn: false
  },
  showProductionInfo: false,
  isResourceChartOpen: false,
  isProductionInfoOpen: false,
  // legendState: {
  //   currentIssueIndex: 0,
  //   currentProductInfoIndex: 0,
  //   isProductInfo: false,
  // },
  // legendData: {
  //   issueList: [],
  //   resourceInfoList: [],
  // },
  resourceIndex: 0,
  productSearchInputValue: ''
}

// TODO: Refactor...
const clickedProductInfo = (state = initialState, action) => {
  if (action.type === SELECT_PRODUCT) {
    return updateObject(state, {
      clickedProductInfo: {
        ...state.clickedProductInfo,
        ...action.productInfo,
        isSelectionOn: true
      }
    })
  }
  if (action.type === TOGGLE_PRODUCTION_INFO) {
    return updateObject(state, { ...state, isProductionInfoOpen: !state.isProductionInfoOpen })
  }
  if (action.type === SAVE_RESOURCE_INDEX)
    return updateObject(state, { ...state, resourceIndex: action.resourceIndex })
  if (action.type === TOGGLE_RESOURCE_CHART) {
    return updateObject(state, { ...state, isResourceChartOpen: !state.isResourceChartOpen })
  }

  // if (action.type === SELECT_LEGEND_OPTION) {
  //   return updateObject(state, {
  //     ...state,
  //     legendState: action.legendState,
  //     legendData: { ...action.legendData },
  //   })
  // }

  if (action.type === ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE) {
    return updateObject(state, {
      ...state,
      productSearchInputValue: action.productSearchInputValue
    })
  }

  return state
}

// const PRODUCTinitialState = {
//   PRODUCTclickedProductInfo: {
//     periodIndex: undefined,
//     productIndex: undefined,
//     isSelectionOn: false
//   },
//   PRODUCTshowProductionInfo: false,
//   PRODUCTisResourceChartOpen: false,
//   PRODUCTisProductionInfoOpen: false,
//   // legendState: {
//   //   currentIssueIndex: 0,
//   //   currentProductInfoIndex: 0,
//   //   isProductInfo: false,
//   // },
//   // legendData: {
//   //   issueList: [],
//   //   resourceInfoList: [],
//   // },
//   PRODUCTresourceIndex: 0,
//   PRODUCTproductSearchInputValue: ''
// }

// TODO: Refactor...
const PRODUCTclickedProductInfo = (state = initialState, action) => {
  if (action.type === PRODUCT_SELECT_PRODUCT) {
    return updateObject(state, {
      clickedProductInfo: {
        ...state.clickedProductInfo,
        ...action.productInfo,
        isSelectionOn: true
      }
    })
  }
  if (action.type === PRODUCT_TOGGLE_PRODUCTION_INFO) {
    return updateObject(state, {
      ...state,
      isProductionInfoOpen: !state.isProductionInfoOpen
    })
  }
  if (action.type === PRODUCT_SAVE_RESOURCE_INDEX)
    return updateObject(state, { ...state, resourceIndex: action.resourceIndex })
  if (action.type === PRODUCT_TOGGLE_RESOURCE_CHART) {
    return updateObject(state, { ...state, isResourceChartOpen: !state.isResourceChartOpen })
  }

  // if (action.type === SELECT_LEGEND_OPTION) {
  //   return updateObject(state, {
  //     ...state,
  //     legendState: action.legendState,
  //     legendData: { ...action.legendData },
  //   })
  // }

  if (action.type === PRODUCT_ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE) {
    return updateObject(state, {
      ...state,
      productSearchInputValue: action.productSearchInputValue
    })
  }

  return state
}

export { clickedProductInfo, PRODUCTclickedProductInfo }
