import { render, screen } from '@testing-library/react'
import NetworkInformation from './index'

interface IProps {
  show: boolean
}

function getDefaultProps(): IProps {
  return {
    show: false
  }
}

describe('DP - NetworkInformation', () => {
  let props: IProps = {}

  beforeEach(() => {
    props = getDefaultProps()
  })

  it('renders without crash', () => {
    props.show = true
    render(<NetworkInformation {...props} />)
    const networkInformationElement = screen.getByRole('alert')
    expect(networkInformationElement).toBeInTheDocument()
  })

  it('renders messages', () => {
    props.show = true
    const msg = 'Checking the network cables, modem, and router'
    render(<NetworkInformation {...props} />)
    const networkInformationElement = screen.getByText(msg)
    expect(networkInformationElement).toBeTruthy()
  })

  it('should not renders with show false', () => {
    props.show = false
    render(<NetworkInformation {...props} />)
    const networkInformationElement = screen.queryByRole('alert')
    expect(networkInformationElement).not.toBeTruthy()
  })
})
