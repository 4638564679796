import axios from 'axios'

const $state = window?.dashboardStore?.getState?.() || {}
const environment = $state?.settings?.environment ?? ''
const access_token = $state?.oidc?.user?.access_token ?? ''

const instance = axios.create({
  timeout: 90000,
  headers: {
    environment,
    Accept: 'application/json',
    Authorization: `Bearer ${access_token}`,
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response?.data?.errors && Object.keys(response.data.errors).length) {
      let message = ''
      Object.keys(response.data.errors).forEach((key) => {
        message += `${key}: ${response.data.errors[key]}\n`
      })
      response.data.Errors = [message]
      return Promise.reject(response)
    }

    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const message = error?.message ?? 'There has been an error!'
    return Promise.reject({ data: { Errors: [message] } })
  }
)

export default instance
