export default {
  title: 'Transportation UI',
  subTitle: 'Solvoyo',
  version: '3.0',
  namespace: 'tuiv3',
  description: 'Transportation User Interface (TUI) v3 - React',
  iconClass: 'fa-table',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Application'
}
