Ext.define('Spui.view.tracking.leftside.dataview.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-tracking-leftside-dataview-main',
  config: {
    dataViewRec: 'test',
    store: {},
    dataView: {}
  },

  // mixins: [
  //     'Ext.util.StoreHolder'
  // ],

  applyDataViewRec: function (c) {
    return window.ss(c)
  },
  applyStore: function (b) {
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        fields: [
          { name: 'StatusDescription', type: 'string' },
          { name: 'NumberOfRecord', type: 'int' },
          { name: 'ProcessLevel', type: 'int' },
          { name: 'StatusDescription', type: 'string' },
          { name: 'Type', type: 'string' },
          { name: 'IsDefault', type: 'int' },
          { name: 'StatusId', type: 'int' }
        ],
        proxy: {
          type: 'ajax',
          timeout: 120000,
          url: Spui.Global.getApiUrl() + '/spui/GetStatusReport'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)
    }

    return b
  },

  updateStore: function (f, g) {
    f.on('load', this.onLoadStore, this)
    f.on('beforeload', this.onBeforeLoadStore, this)
  },

  applyDataView: function (b) {
    var setRec = this.getDataViewRec()

    if (!setRec) {
      this.setDataView('test')
      setRec = this.getDataView()
    }
    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        cls: 'panel-primary',
        xtype: 'spui-view-tracking-leftside-dataview-statusreport',
        store: this.getStore(),
        title: '',
        html: 'test'
      })

      b = Ext.create(b)
    }
    b.getStore().load()
    return b
  },
  updateDataView: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.add(f)
        } else {
          d.push(f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onLoadStore: function () {},
  onBeforeLoadStore: function () {},

  onSave: function (v, k) {
    var setRec = this.getDataViewRec()
    v.close()
    setRec.set('code', k)
    setRec.save()

    var res = new Function('return ' + k)()

    this.setDataView(res)
  }
})
