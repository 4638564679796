import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import AssortmentDropdown from '../Dropdown'
import AssortmentInput from '../Input'
import sortingOptions from '../../utils/sorting-options'
import './style.scss'
import { setOptionsSettings } from '../../store/slices/optionSettings'

class OptionSettings extends React.Component {
  constructor(props) {
    super(props)
    this.sortingOptions = [...sortingOptions]
  }

  onHandleFieldChanged({ inputKey, checked }) {
    this.setOptionsSettings({ [inputKey]: checked })
  }

  onFilterChanged(dropdownKey, val) {
    const { key: sortBy = '' } = val
    this.setOptionsSettings({ sortBy })
  }

  setOptionsSettings(settings = {}) {
    const { onSetOptionsSettings = () => {} } = this.props
    onSetOptionsSettings({ ...settings })
  }

  onSearchClearRequested() {
    this.setOptionsSettings({ query: '' })
  }

  onHandleQueryChanged({ value }) {
    let query = value
    query = query
      .replace(/  +/g, ' ') //  eslint-disable-line no-regex-spaces
      .replace(/\.|\*|\\|\>|\<|\+|\%|\?|\/|\&|\:|\#/g, '') // eslint-disable-line

    this.setOptionsSettings({ query })
  }

  onHandleQueryKeyDown({ keyCode = '' }) {
    if (keyCode === 27) {
      this.onSearchClearRequested()
    }
  }

  render() {
    const {
      Settings: {
        displayConfirmedProducts = true,
        displayTestProducts = true,
        displayAssignedProducts = true,
        displayUnassignedProducts = true,
        sortBy = 'aZ_Ascending',
        query = ''
      } = {}
    } = this.props
    return (
      <div className="form-horizontal option-settings">
        <div className="col-sm-12">
          <div className="form-group mb-3">
            <div className="sc-quick-search">
              <AssortmentInput
                className="quick-search-input"
                inputKey="query"
                placeholder="Search..."
                type="text"
                value={query}
                onChanged={this.onHandleQueryChanged.bind(this)}
                onKeyDown={this.onHandleQueryKeyDown.bind(this)}
              />
              {!_.isEmpty(query) ? (
                <i
                  className="quick-search-reset-btn fa fa-times"
                  onClick={this.onSearchClearRequested.bind(this)}
                />
              ) : null}
            </div>
          </div>
          <div className="form-group mb-3">
            <AssortmentDropdown
              canAddEmpty={false}
              dropdownKey="sortBy"
              options={this.sortingOptions}
              selected={sortBy}
              onFilterChanged={this.onFilterChanged.bind(this)}
            />
          </div>
          <div className="form-group mb-3">
            <div className="checkbox">
              <AssortmentInput
                noDefaultClass
                checked={displayConfirmedProducts}
                id="displayConfirmedProducts"
                inputKey="displayConfirmedProducts"
                type="checkbox"
                onChanged={this.onHandleFieldChanged.bind(this)}
              />
              <label htmlFor="displayConfirmedProducts">Confirmed Products</label>
            </div>
          </div>
          <div className="form-group mb-3">
            <div className="checkbox">
              <AssortmentInput
                noDefaultClass
                checked={displayTestProducts}
                id="displayTestProducts"
                inputKey="displayTestProducts"
                type="checkbox"
                onChanged={this.onHandleFieldChanged.bind(this)}
              />
              <label htmlFor="displayTestProducts">Test Products</label>
            </div>
          </div>
          <hr />
          <div className="form-group mb-3">
            <div className="checkbox">
              <AssortmentInput
                noDefaultClass
                checked={displayAssignedProducts}
                id="displayAssignedProducts"
                inputKey="displayAssignedProducts"
                type="checkbox"
                onChanged={this.onHandleFieldChanged.bind(this)}
              />
              <label htmlFor="displayAssignedProducts">Assigned Products</label>
            </div>
          </div>
          <div className="form-group mb-3">
            <div className="checkbox">
              <AssortmentInput
                noDefaultClass
                checked={displayUnassignedProducts}
                id="displayUnassignedProducts"
                inputKey="displayUnassignedProducts"
                type="checkbox"
                onChanged={this.onHandleFieldChanged.bind(this)}
              />
              <label htmlFor="displayUnassignedProducts">Unassigned Products</label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    Settings: state.optionSettings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetOptionsSettings: bindActionCreators(setOptionsSettings, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionSettings)
