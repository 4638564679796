import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ISlvyBreadcrumbProps } from './SlvyBreadcrumb.types'

function SlvyBreadcrumb({ walk }: ISlvyBreadcrumbProps) {
  return (
    <div
      className="clearfix position-relative d-block p-1 bg-white mb-1 border-bottom"
      data-testid="slvy-breadcrumb"
    >
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Breadcrumb className="mb-0" listProps={{ className: 'mb-0 pt-2 pb-2' }}>
              <Breadcrumb.Item linkAs="span">
                <Link className="text-body" to="/">
                  Home
                </Link>
              </Breadcrumb.Item>
              {walk.map(({ to, title }, index) => {
                const item =
                  typeof to !== 'undefined' ? (
                    <Link className="text-body" to={to}>
                      {title}
                    </Link>
                  ) : (
                    <strong>{title}</strong>
                  )
                return (
                  <Breadcrumb.Item key={index} linkAs="span">
                    {item}
                  </Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SlvyBreadcrumb
