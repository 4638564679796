Ext.define('Spui.view.tracking.TrackingViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.spui-view-tracking-trackingviewmodel',
  stores: {
    SpotListStore: {
      model: 'Spui.data.model.SpotList',
      autoLoad: false,
      listeners: {}
    },
    statusStore: {
      fields: [
        { name: 'StatusDescription', type: 'string' },
        { name: 'NumberOfRecord', type: 'int' },
        { name: 'ProcessLevel', type: 'int' },
        { name: 'StatusDescription', type: 'string' },
        { name: 'Type', type: 'string' },
        { name: 'IsDefault', type: 'int' },
        { name: 'StatusId', type: 'int' },
        { name: 'isDistributionTab', type: 'boolean' },
        { name: 'isContractTab', type: 'boolean' }
      ],
      proxy: {
        type: 'ajax',
        timeout: 120000,
        url: Spui.Global.getApiUrl() + '/spui/GetStatusReport'
      },
      autoLoad: false
    }
  },
  data: {
    materialRecord: null,
    spotRecord: {}
    /* This object holds the arbitrary data that populates the ViewModel and is then available for binding. */
  }
})
