export default {
  title: 'Text Field',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'TextField',
  description: 'Simple text field',
  iconClass: 'fa-search',
  color: 'navy-bg',
  group: 'Filters'
}
