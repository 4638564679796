import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '@/constants'

const clusterTreeApi = createApi({
  reducerPath: 'clusterTreeApiReducer',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/data`,
    prepareHeaders: (headers) => {
      const {
        setting: { environment = '' },
        oidc: {
          user: { access_token = '' }
        }
      } = window.dashboardStore.getState()

      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')
      headers.set('environment', environment)
      headers.set('Authorization', `Bearer ${access_token}`)
      return headers
    }
  }),
  endpoints(builder) {
    return {
      fetchClusterTree: builder.mutation({
        invalidatesTags: ['fetchClusterTree'],
        query: (info) => {
          const { pluginId = '', method = '', requestMethod = 'get' } = info
          return {
            url: `/${pluginId}/invoke/${method}`,
            method: requestMethod // get
          }
        }
      })
    }
  }
})

export const { useFetchClusterTreeMutation } = clusterTreeApi
export default clusterTreeApi
