import { useReduxState } from '../../hooks'
import { tuiSlice } from '../../store/slices'
import Map from '../Map'

export default function MapView({ demandTreeTableRef, loadTreeTableRef }) {
  const [{ config }] = useReduxState(tuiSlice.selectSlice)

  return (
    <Map
      demandTreeTableRef={demandTreeTableRef}
      lat={config.map.latValue}
      lng={config.map.latValue}
      loadTreeTableRef={loadTreeTableRef}
      theme={config.map.theme}
      tile={config.map.tile}
      zoom={config.map.zoomFactor}
    />
  )
}
