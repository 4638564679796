Ext.define('MDM.Btl.cmp.RPCombo', {
  extend: 'Ext.form.ComboBox',
  xtype: 'rpcombo',
  reference: 'rpcombo',
  fieldLabel: '<b>Period</b>',
  labelWidth: 40,
  width: 135,
  margins: '0 4 0 0',
  tpl:
    '<tpl for=".">' +
    '<tpl if="acPeriod !== true">' +
    '<div class="x-boundlist-item">{name}</div>' +
    '<tpl else>' +
    '<div style="font-weight:bold" class="x-boundlist-item">{name}</div>' +
    '</tpl></tpl>',
  bind: {
    store: '{rpStore}',
    value: '{reportingPeriodID}'
  },
  listeners: {
    change: 'onChangeRpCombo'
  },
  queryMode: 'local',
  displayField: 'name',
  valueField: 'value'
})
