Ext.define('MDM.src.view.BomEdit.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.boomedit-maincontroller',
  /**
   * Called when the view is created
   */
  updated: false,
  init: function () {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      record = view.record,
      searchPanel = me.lookupReference('search'),
      bomStore = viewModel.getStore('BomStore')
    viewModel.set('MaterialID', record.get('MaterialID'))
    viewModel.set('record', record)
    bomStore.getProxy().setExtraParam('MaterialID', record.get('MaterialID'))
    bomStore.load()
    viewModel.getStore('searchStore').getProxy().setExtraParam('srckey', '')
    Ext.defer(function () {
      view.setTitle(record.get('MaterialDescription') + ' <b>BOM edit</b>')
      if (view.searchCollapsed) searchPanel.collapse('left', false)
    }, 5)
  },
  onWrite: function () {
    this.updated = true
  },
  onSearch: function (field, value) {
    var me = this,
      store = me.getViewModel().getStore('searchStore')
    if (value.length > 2) {
      store.getProxy().setExtraParam('srckey', value)
      store.loadPage(1)
    }
  },
  addToBom: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      viewModel = me.getViewModel(),
      bomStore = me.getViewModel().getStore('BomStore'),
      mainRecord = this.getViewModel().get('record'),
      newRec
    newRec = Ext.create('MDM.src.view.BomEdit.model.BomModel', {
      MaterialID: mainRecord.get('MaterialID'),
      MaterialCode: record.get('MaterialCode'),
      ComponentID: record.get('MaterialID'),
      MaterialDescription: record.get('MaterialDescription'),
      GU: record.get('GU')
    })
    bomStore.add(newRec)
    grid.store.remove(record)
  },
  onSaveBtnClick: function () {
    var me = this,
      bomStore = me.getViewModel().getStore('BomStore'),
      view = me.getView()
    bomStore.sync({
      success: function (batch, opts) {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        view.fireEvent('updated', view, bomStore)
      },
      failure: function (batch, opts) {}
    })
  },
  onbeforeClose: function () {
    var me = this,
      bomStore = me.getViewModel().getStore('BomStore'),
      view = me.getView()
    if (this.updated) view.fireEvent('updated', view, bomStore)
  },
  onBeforeDrop: function (node, data, overModel, dropPosition, dropHandlers) {
    var me = this,
      records = data.records,
      viewModel = me.getViewModel(),
      bomStore = viewModel.getStore('BomStore'),
      searchStore = viewModel.getStore('searchStore'),
      mainRecord = this.getViewModel().get('record')
    records.forEach(function (record) {
      var searchRec = bomStore.findRecord('ComponentID', record.get('MaterialID'))
      if (!searchRec) {
        var newRec = Ext.create('MDM.src.view.BomEdit.model.BomModel', {
          MaterialID: mainRecord.get('MaterialID'),
          MaterialCode: record.get('MaterialCode'),
          ComponentID: record.get('MaterialID'),
          MaterialDescription: record.get('MaterialDescription'),
          GU: record.get('GU')
        })
        searchStore.remove(record)
        bomStore.add(newRec)
      }
    }, this)
    dropHandlers.cancelDrop()
  },
  onEdit: function (editor, context) {
    var record = context.record
    record.save()
  }
})
