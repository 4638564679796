Ext.define('MDM.src.view.regionapp.Cmp.ExtractDialog', {
  extend: 'Ext.window.Window',
  width: 1100,
  height: '50%',
  autoShow: true,
  bodyPadding: 4,
  bodyStyle: {
    background: '#fff'
  },
  statuful: true,
  stateId: 'ExtractDialog',
  modal: true,
  closable: false,
  maximizable: false,
  layout: 'fit',
  userMegaRegion: '',
  defaultListenerScope: true,
  ui: 'window-inverse',
  iconCls: 'fa  fa-external-link-square',
  bbar: [
    {
      text: 'Export',
      iconCls: 'fa fa-download',
      ui: 'btn-success',
      handler: 'exportTo'
    },
    '->',
    {
      text: 'Close',
      ui: 'btn-success',
      handler: function (btn) {
        btn.up('window').close()
      }
    }
  ],
  initComponent: function () {
    var me = this
    me.title =
      'Extract data for <span style="color:yellow"> ' +
      me.record.get('MaterialCode') +
      ' ' +
      me.record.get('MaterialDescription') +
      '</span>'
    me.items = [
      (me.grid = {
        xtype: 'regionapp-extract-grid',
        border: true,
        store: me.getStore()
      })
    ]
    me.callParent()
  },
  getGrid: function () {
    return this.down('grid')
  },
  getStore: function (c) {
    var me = this,
      MaterialCode = me.record.get('MaterialCode')
    this.store = Ext.create('Ext.data.Store', {
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/RMDM_Material_Extract',
        extraParams: {
          MaterialCode: MaterialCode
        },
        reader: {
          type: 'json'
        }
      },
      autoLoad: true
    })
    return this.store
  },
  exportTo: function (btn) {
    var me = this
    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }

    var newcfg = Ext.merge(
      {
        title: 'Extract data for.... ',
        fileName: 'Export' + '.' + cfg.ext
      },
      btn.cfg
    )

    me.getGrid().saveDocumentAs(newcfg)
  },

  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },

  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },

  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  }
})
