Ext.define('MDM.src.view.regionapp.Dilaog.Grid', {
  extend: 'Ext.grid.Panel',
  xtype: 'prommptDialog',
  isDisabledBtn: false,
  columnLines: true,
  plugins: [
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],
  store: {
    autoLoad: true,
    fields: [],

    proxy: {
      type: 'memory',
      reader: {
        type: 'json',
        rootProperty: 'users'
      }
    }
  },
  columns: [
    {
      text: 'Material Code',
      dataIndex: 'MaterialCode',

      flex: 1,
      sortable: true
    },
    {
      text: 'Material Description',
      dataIndex: 'MaterialDescription',
      flex: 2,
      sortable: true
    },
    {
      text: 'GU',
      dataIndex: 'GU',
      width: 120,
      sortable: true,
      align: 'right',
      editor: {
        xtype: 'numberfield',
        allowBlank: false,
        minValue: 0,
        maxValue: 100000,
        step: 0.1
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Remove record',
          handler: function (grid, rowIndex, colIndex, item, e, record) {
            var store = grid.store
            store.remove(record)
          }
        }
      ]
    }
  ],
  initComponent: function () {
    var me = this
    ;(me.bbar = [
      '->',
      {
        text: 'Copy',
        iconCls: 'fa fa-clipboard',
        hidden: me.isDisabledBtn,
        handler: function (btn) {
          btn.up('grid').onSave('copy')
        }
      },
      '-',
      {
        text: 'Move',
        iconCls: 'fa fa-arrows-alt',
        hidden: me.isDisabledBtn,
        handler: function (btn) {
          btn.up('grid').onSave('move')
        }
      },
      {
        text: 'OK',
        hidden: !me.isDisabledBtn,
        handler: function (btn) {
          btn.up('grid').onSave('copy')
        }
      },
      '-',
      {
        text: 'Cancel',
        iconCls: 'fa fa-ban',

        handler: function (btn) {
          btn.up('window').destroy()
        }
      }
    ]),
      me.callParent()
  },

  onSave: function (action) {
    var store = this.store,
      me = this

    var data = Ext.pluck(store.data.items, 'data')
    var newData = []
    me.setLoading('Please wait...')
    data.forEach(function (item) {
      newData.push({
        MaterialID: item['MaterialID'],
        GU: item['GU'],
        DPIFamilyID: this.DPIFamilyID,
        RelationId: item['RelationId']
      })
    }, this)
    var jsonData = Ext.encode(newData)
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/DPIRelationUpdate',
      params: {
        jsonData: jsonData,
        action: action
      },
      success: function () {
        me.fireEvent('updated', this)
        me.up('window').destroy()
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
      },
      failure: function () {
        Ext.MessageBox.hide()
      }
    })
  }
})
