import { MESSAGES } from './messages'

export default [
  {
    value: '-1',
    name: MESSAGES.lock_settings
  },
  {
    value: '1',
    name: MESSAGES.locked
  },
  {
    value: '0',
    name: MESSAGES.not_locked
  }
]
