import React, { Component } from 'react'
import {
  Button,
  FormGroup,
  FormControl,
  Form,
  Container,
  Badge,
  Row,
  Col,
  Tabs,
  Tab
} from 'react-bootstrap'

import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
import _ from 'lodash'
import Select from 'react-select'
import Dropzone from 'react-dropzone'
import moment from 'moment'
import cx from 'classnames'
import Popup from '../Popup/index'
import { FieldGroup } from '../Add/index'
import './style.scss'

class TaskShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      show: false,
      assigneeValue: props.taskCard.AssigneeUniqueName,
      dueDateValue: moment(props.taskCard.DueDate).toISOString(),
      dueDateFormattedValue: props.taskCard.DueDate,
      subject: props.taskCard.Subject,
      files: [],
      categoryID: props.taskCard.CategoryID,
      priorityID: props.taskCard.PriorityID,
      subStatusId: props.taskCard.SubStatusId,
      description: props.taskCard.Description,
      isAssignee: props.taskCard.IsAssignee,
      isReporter: props.taskCard.IsReporter,
      isArchived: props.taskCard.IsArchived,
      comment: '',
      statusID: props.taskCard.StatusID,
      comments: props.taskComments,
      attachments: props.taskAttachments,
      watchers: this.mappingFromCrudListForUsers(props.taskWatchers),
      taskAvailableNavigations: props.taskAvailableNavigations,
      currentAvailableNavigations: [],
      imageFiles: [],
      saveClicked: false,
      priorities: this.mappingFromCrudList(this.props.priorities, 'PriorityID', 'Name'),
      categories: this.mappingFromCrudList(this.props.categories, 'CategoryID', 'Name'),
      subStatuses: this.mappingFromCrudList(this.props.subStatuses, 'Id', 'Name'),
      users: this.mappingFromCrudListForUsers(props.users),
      swimlaneUsers: this.mappingFromCrudListForUsers(props.swimlaneUsers),
      activityShow: false,
      showTransportationPopup: false,
      srcStatusID: 0,
      desStatusID: 0,
      areThereReasons: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.mappingFromCrudList = this.mappingFromCrudList.bind(this)
    this.handleAssigneeGroupChange = this.handleAssigneeGroupChange.bind(this)
    this.handleFilesCahnge = this.handleFilesCahnge.bind(this)
    this.handleCategorySelect = this.handleCategorySelect.bind(this)
    this.handlePrioritySelect = this.handlePrioritySelect.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleDueDateChange = this.handleDueDateChange.bind(this)
    this.handleSubjectChange = this.handleSubjectChange.bind(this)
    this.insertComment = this.insertComment.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.close = this.close.bind(this)
    this.handleSaveUpdates = this.handleSaveUpdates.bind(this)
    this.handleWathchersSelectChange = this.handleWathchersSelectChange.bind(this)
    this.mappingFromCrudListForUsers = this.mappingFromCrudListForUsers.bind(this)
    this.handleChangeStatus = this.handleChangeStatus.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.getValidationState = this.getValidationState.bind(this)
    this.checkFormValues = this.checkFormValues.bind(this)
    this.getHashCodeByHSL = this.getHashCodeByHSL.bind(this)
    this.getCurrentAvailableNavigations = this.getCurrentAvailableNavigations.bind(this)
    this.handleActivityShow = this.handleActivityShow.bind(this)
    this.changeArchiveStatus = this.changeArchiveStatus.bind(this)
    this.handleSubStatusSelectChange = this.handleSubStatusSelectChange.bind(this)
    this.popupOk = this.popupOk.bind(this)
    this.popupCancel = this.popupCancel.bind(this)
  }

  handleChangeStatus(toStatusId) {
    const { statusID, currentAvailableNavigations } = this.state
    const {
      taskCard: { Id: TaskId, TypeId, CategoryName },
      assigneeChangeabilityDuringCardTransportation
    } = this.props
    const currentNavigation = _.find(currentAvailableNavigations, {
      FromStatusId: statusID,
      ToStatusId: toStatusId,
      TypeId
    })
    if (assigneeChangeabilityDuringCardTransportation || currentNavigation.HasItReason) {
      this.props.getPopupData(
        statusID,
        toStatusId,
        assigneeChangeabilityDuringCardTransportation,
        currentNavigation.HasItReason,
        CategoryName
      )
      this.setState({
        srcStatusID: statusID,
        desStatusID: toStatusId,
        showTransportationPopup: true,
        areThereReasons: currentNavigation.HasItReason
      })
      this.props.localUpdateTaskStatus({
        taskID: TaskId,
        statusID: toStatusId
      })
    } else {
      this.props.localUpdateTaskStatus({
        taskID: TaskId,
        statusID: toStatusId
      })
      this.props.updateStatus({
        taskID: this.props.taskCard.Id,
        previousStatusID: statusID,
        statusID: toStatusId,
        reason: ''
      })
    }
  }

  handleActivityShow() {
    const { activityShow } = this.state
    if (activityShow) this.setState({ activityShow: false })
    else this.setState({ activityShow: true })
  }

  onDrop(files) {
    this.props.insertAttachment({
      taskID: this.props.taskCard.Id,
      commentID: null,
      files
    })
  }

  popupOk(assignee, reason) {
    const { srcStatusID, desStatusID } = this.state
    const {
      taskCard: { Id: TaskId }
    } = this.props
    if (assignee !== '') {
      this.props.updateTask({
        taskID: this.props.taskCard.Id,
        categoryID: this.props.taskCard.CategoryID,
        priorityID: this.props.taskCard.PriorityID,
        subStatusId: this.props.taskCard.SubStatusId,
        statusID: this.props.taskCard.StatusID,
        dueDate: this.props.taskCard.DueDate,
        assigneeName: assignee,
        subject: this.props.taskCard.Subject,
        description: this.props.taskCard.Description
      })
    }
    this.props.updateStatus({
      taskID: TaskId,
      previousStatusID: srcStatusID,
      statusID: desStatusID,
      reason
    })
    this.setState({
      showTransportationPopup: false,
      srcStatusID: 0,
      desStatusID: 0,
      areThereReasons: false
    })
    this.props.emptyPopupData()
  }

  popupCancel() {
    const { srcStatusID } = this.state
    const {
      taskCard: { Id: TaskId }
    } = this.props
    this.props.localUpdateTaskStatus({
      taskID: TaskId,
      statusID: srcStatusID
    })
    this.setState({
      showTransportationPopup: false,
      srcStatusID: 0,
      desStatusID: 0,
      areThereReasons: false
    })
    this.props.emptyPopupData()
  }

  getCurrentAvailableNavigations() {
    const { taskAvailableNavigations = [], statusID } = this.state
    const currentAvailableNavigations = _.reduce(
      taskAvailableNavigations,
      (result, item) => {
        if (item.FromStatusId === statusID) result.push(item)
        return result
      },
      []
    )
    this.setState({ currentAvailableNavigations })
  }

  mappingFromCrudListForUsers = (list) => {
    return _.map(list, function (item) {
      return {
        label: `${item.Name} ${item.Surname}`,
        value: item.UserName
      }
    })
  }

  handleSubjectChange(e) {
    this.setState({
      subject: e.target.value
    })
  }

  handleFilesCahnge(e) {
    const oldFiles = this.state.files
    oldFiles.push(_.first(e.target.files))
    this.setState({
      files: oldFiles
    })
    this.props.insertAttachment({
      taskID: this.props.taskCard.Id,
      commentID: null,
      files: e.target.files
    })
  }

  handleCategorySelect(e) {
    this.setState({ categoryID: e.value })
  }

  handlePrioritySelect(e) {
    this.setState({ priorityID: e.value })
  }

  handleDescriptionChange(e) {
    this.setState({
      description: e.target.value
    })
  }

  handleAssigneeGroupChange(newAssignee) {
    if (newAssignee) {
      this.setState({
        assigneeValue: newAssignee.value
      })
    } else {
      this.setState({
        assigneeValue: null
      })
    }
  }

  handleCommentChange(event) {
    this.setState({
      comment: event.target.value
    })
  }

  mappingFromCrudList = (list, key, value) => {
    return _.map(list, function (item) {
      return { label: item[value], value: item[key] }
    })
  }

  handleDueDateChange(value) {
    this.setState({
      dueDateValue: value && value.toISOString(),
      dueDateFormattedValue: value && value.format('DD-MM-YYYY')
    })
  }

  handleSubmit() {
    this.setState({ show: false })
    this.props.onChange(false)
    if (this.props.onSubmit) {
      this.props.onSubmit()
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleWathchersSelectChange(newWatchers) {
    this.setState({ watchers: newWatchers })
  }

  handleSubStatusSelectChange(newSubStatus) {
    this.setState({ subStatusId: newSubStatus.value })
  }

  handleSaveUpdates() {
    this.setState({ saveClicked: true })
    if (this.checkFormValues()) {
      this.props.updateTask({
        taskID: this.props.taskCard.Id,
        categoryID: this.state.categoryID,
        priorityID: this.state.priorityID,
        subStatusId: this.state.subStatusId,
        statusID: this.state.statusID,
        dueDate: this.state.dueDateValue,
        assigneeName: this.state.assigneeValue,
        subject: this.state.subject,
        description: this.state.description
      })
    }
    if (
      !_.isEqual(this.state.watchers, this.mappingFromCrudListForUsers(this.props.taskWatchers))
    ) {
      this.props.updateTaskWatchers({
        watchers: _.map(this.state.watchers, function (item) {
          return item.value
        }),
        taskID: this.props.taskCard.Id
      })
    }
  }

  deleteAttachment(attachmentId) {
    this.props.deleteAttachment({
      attachmentId,
      taskID: this.props.taskCard.Id
    })
  }

  insertComment() {
    this.props.insertComment({
      taskID: this.props.taskCard.Id,
      comment: this.state.comment
    })
    const newComment = this.state.comment
    const { comments } = this.state
    const userWords = _.words(this.props.username)
    comments.unshift({
      Comment: newComment,
      Date: new Date(),
      Name: userWords[0],
      Surname: userWords[1],
      UserName: this.props.uniqueUserName,
      CommentID: parseInt(Math.random() * 1000000, 10)
    })

    this.setState({
      comments,
      comment: ''
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.show !== nextProps.show) {
      this.setState({
        show: nextProps.show
      })
    } else if (!_.isEqual(nextProps, this.props)) {
      this.setState(
        {
          comments: nextProps.taskComments,
          watchers: this.mappingFromCrudListForUsers(nextProps.taskWatchers),
          taskAvailableNavigations: nextProps.taskAvailableNavigations,
          attachments: nextProps.taskAttachments,
          assigneeValue: nextProps.taskCard.AssigneeUniqueName,
          statusID: nextProps.taskCard.StatusID
        },
        () => {
          this.getCurrentAvailableNavigations()
        }
      )
    }
  }

  componentDidMount() {
    this.setState({ show: this.props.show })
    if (this.props.taskCard.UnseenCommentsCount > 0) {
      this.props.readComments({ taskID: this.props.taskCard.Id })
    }
    this.getCurrentAvailableNavigations()
  }

  close() {
    this.props.onClose()
    this.setState({ show: false })
  }

  getHashCodeByHSL(text) {
    text = text.split('').reverse().join('')
    let hash = 0
    let shortened = hash % 360
    if (text.length === 0) {
      return `hsl(${shortened},50%,75%)`
    }
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash)
      hash &= hash // Convert to 32bit integer
    }
    shortened = hash % 360
    return `hsl(${shortened},50%,75%)`
  }

  getValidationState(stateVariable) {
    if (this.state.saveClicked) {
      switch (stateVariable) {
        case 'subject': {
          const { length } = this.state.subject
          if (length > 0) return 'success'
          return 'error'
        }
        case 'priorityID': {
          if (this.state.priorityID !== '') return 'success'
          return 'error'
        }
        case 'categoryID': {
          if (this.state.categoryID !== '') return 'success'
          return 'error'
        }
        case 'dueDateValue': {
          if (this.state.dueDateValue !== '' && this.state.dueDateValue !== null) {
            return 'success'
          }
          return 'error'
        }
        case 'assigneeValue': {
          if (this.state.assigneeValue !== null) return 'success'
          return 'error'
        }
        default:
          return null
      }
    }
    return null
  }

  checkFormValues() {
    if (
      this.state.subject.length === 0 ||
      this.state.categoryID === null ||
      this.state.assigneeValue === null
    ) {
      return false
    }
    return true
  }

  changeArchiveStatus() {
    const { isArchived } = this.state
    if (isArchived) this.setState({ isArchived: false })
    else this.setState({ isArchived: true })
  }

  render() {
    const {
      show,
      comments,
      watchers,
      attachments,
      currentAvailableNavigations,
      priorities,
      categories,
      subStatuses,
      users,
      swimlaneUsers,
      activityShow,
      priorityID,
      showTransportationPopup,
      areThereReasons = false
    } = this.state
    const {
      taskActivities,
      localizationLanguage,
      taskAppearanceProperties: {
        categoryTitle = '',
        statusTitle = '',
        statusVisibility = true,
        priorityTitle = '',
        priorityVisibility = true,
        substatusTitle = '',
        substatusVisibility = true,
        attachmentsTabVisibility = true,
        duedateVisibility = true
      },
      taskCard: { CompletionDate },
      transportationPopupUsers,
      reasons,
      swimlaneBasedUsersEnabled,
      assigneeChangeabilityDuringCardTransportation,
      popupReasonsSuccess = false,
      popupUsersSuccess = false
    } = this.props
    const assigneeUserList = swimlaneBasedUsersEnabled ? swimlaneUsers : users
    const taskOrderNumber = _.find(this.props.statuses, {
      Id: this.state.statusID
    }).OrderNumber
    return (
      <div className={`task-edit-page ${show ? 'active' : ''}`}>
        {showTransportationPopup && (
          <Popup
            areThereReasons={areThereReasons}
            areThereUsers={assigneeChangeabilityDuringCardTransportation}
            popupReasonsSuccess={popupReasonsSuccess}
            popupUsersSuccess={popupUsersSuccess}
            reasons={reasons}
            title="Confirmation"
            users={transportationPopupUsers}
            onCancel={this.popupCancel}
            onOk={this.popupOk}
          />
        )}
        <div className="task-edit-row row">
          <div className={`left-side-row ${activityShow ? 'col-md-8' : 'col-md-12'} `}>
            <Row>
              <Col md={2} sm={2}>
                <h1>{localizationLanguage.TaskInfo} </h1>
              </Col>
              <Col md={6} sm={6} style={{ textAlign: 'center' }}>
                {(this.state.isAssignee || this.state.isReporter) &&
                  _.map(currentAvailableNavigations, (item, index) => {
                    return (
                      <Button
                        key={index}
                        className="buttonFilter"
                        size="sm"
                        variant="info"
                        onClick={() => this.handleChangeStatus(item.ToStatusId)}
                      >
                        {taskOrderNumber > item.OrderNumber && (
                          <i className="fa fa-chevron-left" style={{ paddingRight: 8 }} />
                        )}
                        {item.StatusName}
                        {taskOrderNumber < item.OrderNumber && (
                          <i className="fa fa-chevron-right" style={{ paddingLeft: 8 }} />
                        )}
                      </Button>
                    )
                  })}
              </Col>
              <Col md={4} sm={4} style={{ textAlign: 'right' }}>
                {!this.state.isArchived && this.state.isReporter && (
                  <Button
                    className="btnClose"
                    size="sm"
                    variant="info"
                    onClick={() => {
                      this.props.archiveTask({
                        taskID: this.props.taskCard.Id
                      })
                      this.changeArchiveStatus()
                    }}
                  >
                    {localizationLanguage.Archive}
                  </Button>
                )}
                {this.state.isArchived && this.state.isReporter && (
                  <Button
                    className="btnClose"
                    size="sm"
                    variant="info"
                    onClick={() => {
                      this.props.restoreTask({
                        taskID: this.props.taskCard.Id
                      })
                      this.changeArchiveStatus()
                    }}
                  >
                    {localizationLanguage.Restore}
                  </Button>
                )}
                <Button className="btnClose" size="sm" variant="info" onClick={this.close}>
                  {localizationLanguage.Close}
                </Button>
                <Button
                  className={`${activityShow ? 'opened' : 'closed'}`}
                  size="sm"
                  variant="outline-dark"
                  onClick={this.handleActivityShow}
                />
              </Col>
            </Row>
            <header>
              <Container fluid>
                <Row>
                  <Col md={8} sm={8}>
                    <Row>
                      <Col md={12} sm={12}>
                        <FieldGroup
                          disabled={!this.state.isReporter && !this.state.isAssignee}
                          inputClass={cx({
                            'is-invalid': this.getValidationState('subject') === 'error'
                          })}
                          label={localizationLanguage.TaskSubject}
                          placeholder={localizationLanguage.EnterTaskSubject}
                          type="text"
                          value={this.state.subject}
                          onChange={this.handleSubjectChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12}>
                        <FormGroup className="mb-3" controlId="Description">
                          <Form.Label className="fw-bold">
                            {localizationLanguage.TaskDescription}
                          </Form.Label>
                          <FormControl
                            as="textarea"
                            disabled={!this.state.isReporter && !this.state.isAssignee}
                            placeholder={localizationLanguage.TaskDescription}
                            value={this.state.description}
                            onChange={this.handleDescriptionChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {priorities.length > 1 && priorityID > 0 && priorityVisibility && (
                        <Col md={4} sm={4}>
                          <FormGroup className="mb-3" controlId="Priority">
                            <Form.Label className="fw-bold">{priorityTitle}</Form.Label>
                            <Select
                              clearable={false}
                              disabled={!this.state.isReporter && !this.state.isAssignee}
                              escapeClearsValue={false}
                              options={priorities}
                              value={priorityID}
                              onChange={this.handlePrioritySelect}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {duedateVisibility && (
                        <Col md={4} sm={4}>
                          <FormGroup className="mb-3" controlId="DueDate">
                            <Form.Label className="fw-bold">
                              {localizationLanguage.TaskDuedate}
                            </Form.Label>
                            <DatePicker
                              animation="slide-up"
                              calendar={<Calendar dateInputPlaceholder="" format="DD-MM-YYYY" />}
                              value={
                                this.state.dueDateValue ? moment(this.state.dueDateValue) : null
                              }
                              onChange={this.handleDueDateChange}
                            >
                              {({ value }) => {
                                return (
                                  <input
                                    readOnly
                                    className="input-date-picker form-control"
                                    disabled={!this.state.isReporter}
                                    placeholder="DD-MM-YYYY"
                                    tabIndex="-1"
                                    value={(value && value.format('DD-MM-YYYY')) || ''}
                                  />
                                )
                              }}
                            </DatePicker>
                          </FormGroup>
                        </Col>
                      )}
                      {
                        <Col md={4} sm={4}>
                          <FormGroup className="mb-3" controlId="ComletionDate">
                            <Form.Label className="fw-bold">
                              {localizationLanguage.CompletionDate}
                            </Form.Label>
                            <DatePicker
                              animation="slide-up"
                              calendar={<Calendar dateInputPlaceholder="" format="DD-MM-YYYY" />}
                              value={CompletionDate ? moment(CompletionDate) : null}
                              onChange={this.handleDueDateChange}
                            >
                              {({ value }) => {
                                return (
                                  <input
                                    disabled
                                    readOnly
                                    className="input-date-picker form-control"
                                    placeholder="DD-MM-YYYY"
                                    tabIndex="-1"
                                    value={(value && value.format('DD-MM-YYYY')) || ''}
                                  />
                                )
                              }}
                            </DatePicker>
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                  </Col>
                  <Col md={4} sm={4}>
                    <FormGroup
                      className={cx('mb-3', {
                        'is-invalid': this.getValidationState('assigneeValue') === 'error'
                      })}
                      controlId="Assignee"
                    >
                      <Form.Label className="fw-bold">
                        {localizationLanguage.TaskAssignee}
                      </Form.Label>
                      <Select
                        clearable={false}
                        disabled={!this.state.isReporter && !this.state.isAssignee}
                        escapeClearsValue={false}
                        options={assigneeUserList}
                        placeholder={localizationLanguage.SelectAssignee}
                        value={this.state.assigneeValue}
                        onChange={this.handleAssigneeGroupChange}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 wathers-list" controlId="Watchers">
                      <Form.Label className="fw-bold">
                        {localizationLanguage.TaskWatchers}
                      </Form.Label>
                      <Select
                        multi
                        disabled={!this.state.isReporter && !this.state.isAssignee}
                        escapeClearsValue={false}
                        options={users}
                        placeholder={localizationLanguage.AddTaskWatcher}
                        value={watchers}
                        onChange={this.handleWathchersSelectChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8} sm={8}>
                    <Row>
                      <Col md={4} sm={4}>
                        <FormGroup
                          className={cx('mb-3', {
                            'is-invalid': this.getValidationState('categoryID') === 'error'
                          })}
                          controlId="Category"
                        >
                          <Form.Label className="fw-bold">{categoryTitle}</Form.Label>
                          <Select
                            clearable={false}
                            disabled={!this.state.isReporter && !this.state.isAssignee}
                            escapeClearsValue={false}
                            options={categories}
                            value={this.state.categoryID}
                            onChange={this.handleCategorySelect}
                          />
                        </FormGroup>
                      </Col>
                      {substatusVisibility && (
                        <Col md={4} sm={4}>
                          <FormGroup className="mb-3" controlId="substatus">
                            <Form.Label className="fw-bold">{substatusTitle}</Form.Label>
                            <Select
                              clearable={false}
                              disabled={!this.state.isReporter && !this.state.isAssignee}
                              escapeClearsValue={false}
                              options={subStatuses}
                              value={this.state.subStatusId}
                              onChange={this.handleSubStatusSelectChange}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={4} sm={4}>
                        <FormGroup className="mb-3" controlId="ReportedBy">
                          <Form.Label className="fw-bold">
                            {localizationLanguage.ReportedBy}
                          </Form.Label>
                          <div>
                            <Badge className="label12">
                              {this.props.taskCard.ReporterName}{' '}
                              {this.props.taskCard.ReporterSurname}{' '}
                            </Badge>
                          </div>
                          <div>
                            <Badge className="label12">
                              {localizationLanguage.On}{' '}
                              {moment(this.props.taskCard.CreatedDate).format('DD-MM-YYYY')}
                            </Badge>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} sm={3}>
                    {statusVisibility && (
                      <FormGroup className="mb-3" controlId="TaskStatus">
                        <Form.Label className="fw-bold">{statusTitle}</Form.Label>
                        <div>
                          <Badge className="label12">
                            {
                              _.find(this.props.statuses, {
                                Id: this.state.statusID
                              }).Name
                            }
                          </Badge>
                        </div>
                      </FormGroup>
                    )}
                  </Col>
                  {(this.state.isReporter || this.state.isAssignee) && (
                    <Col md={1} sm={1}>
                      <Button
                        className="ms-auto d-flex btn-save-edit-form"
                        disabled={!this.state.isReporter}
                        variant="success"
                        onClick={this.handleSaveUpdates}
                      >
                        {localizationLanguage.Save}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Container>
            </header>
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Comments">
                <section>
                  <div className="comment-items">
                    {_.map(comments, (item, index) => {
                      return (
                        <div key={index} className="feed-activity-list">
                          <div className="feed-element">
                            <div
                              className="user-profile-box me-auto d-flex"
                              style={{
                                backgroundColor: this.getHashCodeByHSL(item.UserName)
                              }}
                            >
                              {_.capitalize(_.first(item.Name))}
                              {_.capitalize(_.first(item.Surname))}
                            </div>
                            <div>
                              <strong>
                                {' '}
                                {item.Name} {item.Surname}{' '}
                              </strong>
                              <div>{item.Comment}</div>
                              <small className="text-muted">
                                {moment(item.Date).format('DD-MM-YYYY')}
                              </small>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </section>
                <div>
                  <div className="comment-box-enter clearfix">
                    <Col md={10} sm={10}>
                      <FormControl
                        as="textarea"
                        placeholder={localizationLanguage.WriteComment}
                        value={this.state.comment}
                        onChange={this.handleCommentChange}
                      />
                    </Col>
                    <Col md={2} sm={2}>
                      <Button
                        className="btn-comment-add btn-success"
                        variant="success"
                        onClick={this.insertComment}
                      >
                        {localizationLanguage.Add}
                      </Button>
                    </Col>
                  </div>
                </div>
              </Tab>
              {attachmentsTabVisibility && (
                <Tab eventKey={2} title="Attachments">
                  <Dropzone
                    multiple
                    activeClassName="active-dropzone"
                    className="dropzone"
                    onDrop={this.onDrop}
                  >
                    {attachments.length > 0 ? (
                      <div className="dropzone-content">
                        <ul>
                          {attachments.map((file, index) => (
                            <li key={index}>
                              <div className="du-btn-group">
                                <a
                                  className="du-show"
                                  href={file.Link}
                                  rel="noreferrer"
                                  target="_blank"
                                  onClick={(that) => that.stopPropagation()}
                                >
                                  <i className="fa fa-file-text-o" />
                                </a>

                                <span
                                  className="du-delete"
                                  onClick={(that) => {
                                    that.stopPropagation()
                                    this.deleteAttachment(file.AttachmentId)
                                  }}
                                >
                                  <i className="fa fa-trash-o" />
                                </span>
                              </div>
                              <div className="doc-item">
                                <img alt="" src={file.Link} />
                              </div>
                              <div className="du-caption">{file.FileName}</div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div>{localizationLanguage.DragAndDrop}</div>
                    )}
                  </Dropzone>
                </Tab>
              )}
            </Tabs>
          </div>
          {activityShow && (
            <div className="right-side-row col-md-4">
              <h1>{localizationLanguage.Activity}</h1>
              <div className="inspinia-timeline">
                {_.map(taskActivities, (item, index) => {
                  return (
                    <div key={index} className="timeline-item">
                      <div className="row">
                        <div className="col-sm-3 date">
                          <i className="fa fa-bolt fa-fw" />
                          {index > 0
                            ? moment(item.ActionDate).format('DD-MM-YYYY') ===
                              moment(taskActivities[index - 1].ActionDate).format('DD-MM-YYYY')
                              ? ''
                              : moment(item.ActionDate).format('DD-MM-YYYY')
                            : moment(item.ActionDate).format('DD-MM-YYYY')}
                          <br />
                          <small className="text-navy">
                            {moment(item.ActionDate).format('HH.mm')}
                          </small>
                        </div>
                        <div className="col-sm-7 content no-top-border">
                          <p className="m-b-xs">
                            <strong> {item.PerformedBy}</strong>
                          </p>
                          <p>{item.Activity}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default TaskShow
