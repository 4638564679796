import { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { fetchPeriods } from '../store/actions'
import { _errorHandler, getFormattedDefaultDate, getScenarioParameters } from '../utils'

class FetchPeriods extends Component {
  _fetchPeriods() {
    const {
      props: {
        defaultActionParams: { payload = {} },
        selectedScenarioTree: { currentScenarios = [] },
        GetSetFilterTreeRemote: { raw: GetSetFilterTreeRemoteRaw = {} },
        mfpSettings: { mfpId } = {},
        settings: { config: { configuration: { isWorkInSubsetEnabled = false } = {} } = {} } = {}
      }
    } = this

    const UserScenario = _.get(this.props, 'GetUserScenario.Result', null)
    const UserScenarioId = _.get(this.props, 'GetUserScenario.Result.Id', null)
    const isActiveSubset =
      isWorkInSubsetEnabled && !_.isNil(UserScenario) && !_.isNil(UserScenarioId)

    const { StartDate = null, EndDate = null } = isActiveSubset
      ? UserScenario
      : GetSetFilterTreeRemoteRaw

    const body = {
      mfpId,
      StartDate: getFormattedDefaultDate(StartDate, 'start'),
      EndDate: getFormattedDefaultDate(EndDate, 'end'),
      mfpScenarioId: getScenarioParameters(currentScenarios, mfpId).params.PlannedId
    }

    const promise = this.props.fetchPeriods({
      payload: {
        ...payload,
        method: 'GetPeriods',
        requestMethod: 'post',
        body: {
          ...body
        }
      }
    })

    promise
      .then((periodsResponse) => _errorHandler(periodsResponse, 'GetPeriods'))
      .catch((periodsError) => _errorHandler(periodsError, 'GetPeriods'))

    return promise
  }

  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return this.props.children({
      _fetchPeriods: this._fetchPeriods.bind(this)
    })
  }
}

const mapStateToProps = (state) => {
  return {
    GetUserScenario: state.getUserScenario.GetUserScenario,
    GetPeriods: state.getPeriods.GetPeriods,
    GetSetFilterTreeRemote: state.setFilterTreeRemote.GetSetFilterTreeRemote,
    selectedScenarioTree: state.scenarioTreeSelection.scenarioTree,
    mfpSettings: state.mfpSettings.mfpSettings,
    defaultActionParams: state.defaultActionParams.defaultActionParams
  }
}

const mapDispatchToProps = {
  fetchPeriods
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchPeriods)
