import { Component } from 'react'
import styled from 'styled-components'
import { Button as BootstrapButton } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { confirmAlert } from 'react-confirm-alert'
import cx from 'classnames'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

const Wrp = styled.div`
  &:hover {
    color: ${({ iconHoverColor }) => iconHoverColor};
  }
`

class Button extends Component {
  constructor(props) {
    super(props)
    this.setButtonState = this.setButtonState.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.setEnabled = this.setEnabled.bind(this)
    this.setDisabled = this.setDisabled.bind(this)

    const { settings: { config: { settings: { disabledDefault = false } = {} } = {} } = {} } =
      props || {}

    this.state = { disabled: disabledDefault }
  }

  setEnabled() {
    this.setState({ disabled: false })
  }

  setDisabled() {
    this.setState({ disabled: true })
  }

  setButtonState({ disabled = false }) {
    this.setState({ disabled })
  }

  handleClick() {
    return { a: 1, refreshKey: uuidv4() }
  }

  openConfirmationPopup(confirmationText, confirmationTitle) {
    return (e) => {
      e.preventDefault()
      confirmAlert({
        title: confirmationTitle,
        message: confirmationText,
        buttons: [
          {
            label: 'Cancel',
            onClick: () => false
          },
          {
            label: 'OK',
            onClick: () => this.handleClick()
          }
        ]
      })
    }
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'setState',
      fn: this.setButtonState,
      args: [{ name: 'disabled', type: PluginTypes.boolean }]
    })
    this.props.registerMethod({
      key: 'setEnabled',
      fn: this.setEnabled,
      args: []
    })

    this.props.registerMethod({
      key: 'setDisabled',
      fn: this.setDisabled,
      args: []
    })
    this.handleClick = this.props.registerEvent({
      key: 'handleClick',
      fn: this.handleClick,
      returnTypes: { refreshKey: PluginTypes.string }
    })

    // Register for editibility
    this.props.registerAuthorizations(['Enabled'])
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      settings: { config: { data: { enabled: nextEnabled = false } = {} } = {} } = {},
      pluginData: nextPluginData = []
    } = nextProps || {}

    if (nextEnabled) {
      if (nextPluginData[0]) {
        this.setState({ disabled: !nextPluginData[0][nextEnabled] })
      }
    }
  }

  render() {
    const {
      settings: {
        config: {
          settings: {
            text = '',
            style = 'success',
            icon = 'fa-check',
            iconPosition = 'Left',
            iconHoverColor = 'black',
            showVisitedLink = true,
            confirmation = false,
            confirmationText = 'Are you sure to continue?',
            confirmationTitle = 'Confirmation'
          } = {},
          data: { text: textColumn = '' } = {}
        } = {}
      } = {}
    } = this.props
    const { disabled = false } = this.state
    const enabledFromAuthorization = this.props.isAllowed('Enabled')

    let isButtonEnabled = enabledFromAuthorization
    if (isButtonEnabled) {
      isButtonEnabled = !disabled
    }

    const shouldDisplayBiggerIcon = style === 'link' && !showVisitedLink && text === ''
    const iconClassName = cx('fa', icon, { 'fs-6': shouldDisplayBiggerIcon })

    let buttonText = text

    const iconHover = style !== 'link' ? 'inherit' : iconHoverColor

    if (textColumn && this.props.pluginData && this.props.pluginData[0]) {
      const row = this.props.pluginData[0]
      buttonText = row[textColumn]
    }

    return (
      <div className="buttonPlugin-wrapper">
        <div className="buttonPlugin p-1">
          <BootstrapButton
            className={cx('buttonFilter', { 'text-decoration-none': !showVisitedLink })}
            disabled={!isButtonEnabled}
            type="button"
            variant={style}
            onClick={
              confirmation
                ? this.openConfirmationPopup(confirmationText, confirmationTitle)
                : this.handleClick
            }
          >
            <Wrp className="btn-plugin-wrp" iconHoverColor={iconHover}>
              {iconPosition === 'Left' && icon ? <i className={iconClassName} /> : null}
              {` ${buttonText} `}
              {iconPosition === 'Right' && icon ? <i className={iconClassName} /> : null}
            </Wrp>
          </BootstrapButton>
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  settings: props.settings,
  registerMethod: props.registerMethod,
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  registerAuthorizations: props.registerAuthorizations,
  isAllowed: props.isAllowed
})

export default createPlugin(Button, selectConnectorProps)
