export default () => {
  return {
    title: 'Label',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          text: {
            type: 'string',
            title: 'Label Text'
          },
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color'
          },
          fontWeight: {
            type: 'string',
            title: 'Font Weight',
            default: 'Normal',
            enum: ['Normal', 'Bold'],
            required: true
          },
          fontStyle: {
            type: 'string',
            title: 'Font Style',
            default: 'Normal',
            enum: ['Normal', 'Italic'],
            required: true
          },
          fontColor: {
            type: 'string',
            format: 'Color',
            title: 'Font Color',
            default: '#676a6c'
          },
          fontSize: {
            type: 'integer',
            title: 'Font Size',
            default: '12'
          },
          textAlign: {
            type: 'string',
            title: 'Text Align',
            default: 'Center',
            enum: ['Center', 'Left', 'Right', 'Justify'],
            required: true
          },
          textDegree: {
            type: 'string',
            title: 'Text Degree',
            default: 'Normal',
            enum: ['Normal', '-90', '90', 'Vertical'],
            required: true
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          textColumn: {
            type: 'string',
            title: 'Text',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
