import React from 'react'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import numeral from 'numeral'
import { v3 as uuidv3 } from 'uuid'
import { MESSAGES } from '../../../../messages'
import Input from '../Input/index'
import { getApproveState, getLockState } from '../../../Util'
import MetricDiffs from '../MetricDiffs/index'
import ScenarioName from '../ScenarioName/index'
import Summaries from '../Summaries/index'
import { OverlayTrigger } from '../../../index'
import { slvyToast } from '../../../../../../components'
import { IProps } from './Row.types'
import { IMetricItem } from '../ScenarioTable.types'
import { defaultActiveMetricWithValue } from './constants'

export function Row(props: IProps) {
  const {
    activeChildrenFilter,
    BaselineScenarioId,
    Children,
    ComparedScenarioId,
    Fields,
    GroupedSelectedIndex,
    ScenarioId,
    activeScenarioList,
    areVisibleRatiosValues,
    buttonFormat,
    currentSummary,
    decimalScale,
    decimalSeparator,
    defaultMetricField,
    fixCurrentSummary,
    formats,
    getMetricFormat,
    getRatio,
    getScenarioById,
    getScenarioUpdateAllowedById,
    getSortedSummariesData,
    hasAnyUpdatableMetric,
    titleClass,
    isChildrenFiltered,
    isEditMode,
    isFirstRow,
    isHidden,
    isMillion,
    isNested,
    isPercentage,
    isRowClickedEventOn,
    isScenarioEditable,
    isVisibleRatioValue,
    isVisibleSplitValue,
    isWithBaseVisible,
    isWithGroupedVisible,
    onNodeAction,
    onRowClicked,
    onToggleScenariosWithChildren,
    onFilterScenariosChildren,
    onUpdate,
    rowIndex,
    scenario,
    selectedMetricFields,
    ScenariosWithChildren,
    summariesData,
    thousandSeparator,
    missingRowLockMessage
  } = props

  const cursorClass = !isNested ? 'cp' : ''

  const isFilterActive = Boolean(Object.keys(activeChildrenFilter).length)

  const inChildrenClass =
    isNested && isFilterActive && !isChildrenFiltered ? '-inChildren -isFiltered' : ''

  const rowClasses = cx('slvy-ui-row', {
    'd-none': isHidden,
    'multiple-cell': selectedMetricFields.length > 1,
    'show-ratio-value': isVisibleRatioValue,
    'show-ratios-values': areVisibleRatiosValues,
    'three-cell': isNested && areVisibleRatiosValues
  })

  return (
    <>
      <div key={`row-${rowIndex}`} className={rowClasses}>
        <ScenarioName
          extraClass={`${titleClass} ${cursorClass} ${inChildrenClass}`}
          isChildrenFiltered={isChildrenFiltered}
          isFilterActive={isFilterActive}
          isNested={isNested}
          isRowClickedEventOn={isRowClickedEventOn}
          isWithBaseIconVisible={
            isWithBaseVisible && BaselineScenarioId === ScenarioId && !isNested
          }
          isWithGroupedIconVisible={
            isWithGroupedVisible && GroupedSelectedIndex <= rowIndex && !isNested
          }
          name={scenario.Name}
          onFilterScenariosChildren={(shouldFilter: boolean) =>
            onFilterScenariosChildren({
              params: shouldFilter
                ? {
                    child: { LeftFields: Fields[0].LeftFields },
                    parent: { ScenarioId }
                  }
                : {},
              ComparedScenarioId,
              shouldFilter
            })
          }
          onRowClick={(event: any) =>
            onRowClicked(
              Fields.length ? Fields[0].LeftFields : [],
              scenario?.IsActualized ?? false,
              scenario.ScenarioTableName,
              event
            )
          }
          onToggleScenariosWithChildren={() =>
            onToggleScenariosWithChildren(ScenarioId, ComparedScenarioId)
          }
        />

        {Fields.map((fieldItem, fieldIndex) => {
          const { Metrics = [], LeftFields = [] } = fieldItem

          const activeMetric =
            Metrics.find((metricItem) => metricItem.Name === defaultMetricField.Name) ||
            defaultActiveMetricWithValue

          const {
            AbsoluteDiff,
            IsApproved,
            IsLocked,
            Name,
            PercentageDiff,
            ShowSplitValue,
            SplitValue,
            MissingRow
          } = activeMetric

          const $currentMetricFormat = getMetricFormat(Name, false)
          const splitValue = numeral(SplitValue).format($currentMetricFormat)

          const LockKey = getLockState(IsLocked)
          const ApproveKey = getApproveState(IsApproved)

          const isComparedScenario = ComparedScenarioId === null

          const getNodeParams = ($key: 'Approve' | 'Lock') => {
            return {
              fieldIndex,
              BaselineScenarioId: isComparedScenario ? ScenarioId : ComparedScenarioId,
              MfpScenarioId: ScenarioId,
              LeftFields,
              ...($key === 'Approve' ? ApproveKey : LockKey)
            }
          }

          const handleLockClick = () => {
            if (MissingRow) {
              slvyToast.warning({
                message: missingRowLockMessage,
                options: { containerId: 'dp' }
              })
              return
            }

            const params = getNodeParams('Lock')
            onNodeAction(params, isComparedScenario, ComparedScenarioId)
          }

          let growthValue = numeral(PercentageDiff).format(formats.staticFormat).replace(',', '.')
          if (!isPercentage) {
            growthValue = numeral(AbsoluteDiff).format(
              isMillion ? formats.millionFormat : formats.staticFormat
            )
          }

          return (
            <div key={`field-${fieldIndex}`} className="cell">
              <div className="cellContainer">
                <div className="split">
                  {isVisibleSplitValue && ShowSplitValue
                    ? [
                        <div
                          key="graphDown"
                          className="graph -down"
                          style={{ width: `${splitValue}%` }}
                        />,
                        <div key="splitValue" className="value">
                          {splitValue} %
                        </div>
                      ]
                    : null}
                </div>

                {scenario.ApproveAllowed && scenario.UpdateAllowed ? (
                  <div className="-comparison-actions">
                    <OverlayTrigger tooltip={ApproveKey.status}>
                      <i
                        className={ApproveKey.className}
                        onClick={() => {
                          onNodeAction(
                            getNodeParams('Approve'),
                            isComparedScenario,
                            ComparedScenarioId
                          )
                        }}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger tooltip={LockKey.name}>
                      <i className={LockKey.className} onClick={handleLockClick} />
                    </OverlayTrigger>
                  </div>
                ) : null}

                {!areVisibleRatiosValues && isVisibleRatioValue && !isFirstRow ? (
                  <div className="ratio- -limit-value">
                    <div className="value" title={growthValue.length > 6 ? growthValue : ''}>
                      <span>{growthValue}</span>
                      {isPercentage ? '%' : ''}
                    </div>
                    {isPercentage ? (
                      <div
                        className={`icon -${
                          PercentageDiff === 0 ? 'equal' : PercentageDiff < 0 ? 'down' : 'up'
                        }`}
                      >
                        <span
                          className={`slvy-ui-icon-caret-${
                            PercentageDiff === 0 ? 'equal' : PercentageDiff < 0 ? 'down' : 'top'
                          }`}
                        />
                      </div>
                    ) : (
                      <div
                        className={`icon -${
                          AbsoluteDiff === 0 ? 'equal' : AbsoluteDiff < 0 ? 'down' : 'up'
                        }`}
                      >
                        <span
                          className={`slvy-ui-icon-caret-${
                            AbsoluteDiff === 0 ? 'equal' : AbsoluteDiff < 0 ? 'down' : 'top'
                          }`}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
                {getScenarioUpdateAllowedById(ScenarioId) && isEditMode ? (
                  <div className="values-edit">
                    {Metrics.map((metricItem, metricIndex) => {
                      const defaultMetricItem = { Name: '', IsUpdateAllowed: false }
                      const $metricItem =
                        selectedMetricFields.find((item) => item.Name === metricItem.Name) ||
                        defaultMetricItem
                      const editableMetricFormat = getMetricFormat($metricItem.Name, false)
                      const { IsLocked = 0, IsApproved = 0 } = metricItem
                      const isPercentage = buttonFormat === 'percentage'
                      const isDisabled = IsLocked === 1 || (isFirstRow && isPercentage)
                      const placeholder =
                        !isFirstRow && isPercentage
                          ? '100%'
                          : numeral(metricItem.Value).format(
                              isDisabled && isMillion ? formats.millionFormat : editableMetricFormat
                            )
                      const isComparedScenario = ComparedScenarioId === null

                      const uniqueID = uuidv3(
                        `${scenario.Name}
                        ${metricItem.Name}
                        ${rowIndex}
                        ${fieldIndex}
                        ${metricIndex}
                        ${JSON.stringify(LeftFields)}`,
                        uuidv3.DNS
                      )

                      const isNotEditable = Boolean(
                        !isNested &&
                          Children.length &&
                          ScenariosWithChildren.find((item) => item === scenario.Id)
                      )

                      const disabled = MissingRow || isDisabled || isNotEditable
                      const numberFormatProps = {
                        className: isNotEditable ? 'drilldown-is-open' : '',
                        decimalScale,
                        decimalSeparator,
                        disabled,
                        fixedDecimalScale: true,
                        placeholder,
                        thousandSeparator,
                        value: '',
                        onBlur: (event: any) => {
                          event.target.value = ''
                        },
                        onKeyDown: (event: any) => {
                          onUpdate(
                            IsApproved,
                            isComparedScenario,
                            ComparedScenarioId,
                            {
                              fieldIndex,
                              Guid: uniqueID,
                              BaselineValue: metricItem.BaselineValue,
                              OldValue: metricItem.Value,
                              MetricField: metricItem.Name,
                              BaselineScenarioId: isComparedScenario
                                ? ScenarioId
                                : ComparedScenarioId,
                              MfpScenarioId: ScenarioId,
                              LeftFields,
                              ScenarioId
                            },
                            event
                          )
                        }
                      }

                      return (
                        <div
                          key={`metric-edit-${metricIndex}`}
                          className="value"
                          data-id={uniqueID}
                        >
                          <OverlayTrigger
                            shouldWrap={isNotEditable}
                            tooltip={
                              MESSAGES.parent_update_is_disabled_due_to_its_drilldown_being_open
                            }
                          >
                            <div className="-planned">
                              <Input
                                inputProps={{ placeholder }}
                                numberFormatProps={numberFormatProps}
                              />
                            </div>
                          </OverlayTrigger>
                        </div>
                      )
                    })}
                  </div>
                ) : null}

                <div className="values-container">
                  {areVisibleRatiosValues ? (
                    <MetricDiffs
                      getMetricFormat={getMetricFormat}
                      isFirstRow={isFirstRow}
                      isMillion={isMillion}
                      isPercentage={isPercentage}
                      isVisibleRatioValue={isVisibleRatioValue}
                      metrics={Metrics}
                      millionFormat={formats.millionFormat}
                      staticFormat={formats.staticFormat}
                      style={{}}
                    />
                  ) : null}

                  {isNested && areVisibleRatiosValues ? (
                    <div className="values ratio-to-parent common">
                      {Metrics.map((metricItem, metricIndex) => {
                        const RatioToParent =
                          metricItem.RatioToParent === 0
                            ? '-'
                            : `${numeral(metricItem.RatioToParent)
                                .format('0.00')
                                .replace(',', '.')}%`
                        return (
                          <div key={`metric-${metricIndex}`} className="value">
                            <span className="truncated-text" title={RatioToParent}>
                              {RatioToParent}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  ) : null}

                  <div className="values common">
                    {Metrics.map((metricItem, metricIndex) => {
                      const currentMetricFormat = getMetricFormat(metricItem.Name)
                      return (
                        <div key={`metric-${metricIndex}`} className="value">
                          <span
                            className="truncated-text"
                            title={numeral(metricItem.Value).format(currentMetricFormat)}
                          >
                            {numeral(metricItem.Value).format(currentMetricFormat)}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <div className="cell -stick-to-right -end">
          <div className="cellContainer">
            {!areVisibleRatiosValues && isVisibleRatioValue && !isFirstRow ? (
              <div className="ratio-">
                <div className="value">{currentSummary}</div>
                <div
                  className={`icon -${
                    fixCurrentSummary === 0 ? 'equal' : fixCurrentSummary < 0 ? 'down' : 'up'
                  }`}
                >
                  <span
                    className={`slvy-ui-icon-caret-${
                      fixCurrentSummary === 0 ? 'equal' : fixCurrentSummary < 0 ? 'down' : 'top'
                    }`}
                  />
                </div>
              </div>
            ) : null}

            <div className="values-container">
              {areVisibleRatiosValues ? (
                <MetricDiffs
                  getMetricFormat={getMetricFormat}
                  isFirstRow={isFirstRow}
                  isMillion={isMillion}
                  isPercentage={isPercentage}
                  isVisibleRatioValue={isVisibleRatioValue}
                  metrics={summariesData}
                  millionFormat={formats.millionFormat}
                  staticFormat={formats.staticFormat}
                  style={{ top: 0 }}
                />
              ) : null}

              {isNested && areVisibleRatiosValues ? (
                <div className="values ratio-to-parent common">
                  {summariesData.map((metricItem: IMetricItem, metricIndex: number) => {
                    const RatioToParent =
                      metricItem.RatioToParent === 0
                        ? '-'
                        : `${numeral(metricItem.RatioToParent).format('0.00').replace(',', '.')}%`
                    return (
                      <div key={`metric-${metricIndex}`} className="value">
                        <span className="truncated-text" title={RatioToParent}>
                          {RatioToParent}
                        </span>
                      </div>
                    )
                  })}
                </div>
              ) : null}

              <div className="values common">
                <Summaries data={summariesData} getMetricFormat={getMetricFormat} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {Children
        ? Children.map((childrenRow, childrenRowIndex) => {
            const {
              Fields,
              Summaries,
              Children = [],
              LeftFields: childLeftFields = []
            } = childrenRow
            const currentSummary = getRatio(
              Summaries,
              formats.staticFormat,
              isPercentage,
              defaultMetricField
            )
            const fixCurrentSummary = parseFloat(currentSummary)
            const scenarioName = childLeftFields.length ? (childLeftFields.at(-1) as string) : ''
            const childScenario = { ...scenario, Name: scenarioName, IsActualized: false }

            const inGroupClass = isWithGroupedVisible ? '-inGroup' : ''
            let nestedTitleClass = '-isNested'
            nestedTitleClass += ` ${inGroupClass} ${titleClass}`

            const summariesData = getSortedSummariesData(selectedMetricFields, Summaries)

            let isChildrenFiltered = false
            let isHidden = isFilterActive

            if (isFilterActive && isEqual(activeChildrenFilter.child.LeftFields, childLeftFields)) {
              if (activeChildrenFilter.parent.ScenarioId === ScenarioId) {
                isChildrenFiltered = true
              }
              isHidden = false
            }

            return (
              <Row
                key={childrenRowIndex}
                isNested
                BaselineScenarioId={BaselineScenarioId}
                Children={Children}
                ComparedScenarioId={ComparedScenarioId}
                Fields={Fields}
                GroupedSelectedIndex={GroupedSelectedIndex}
                ScenarioId={ScenarioId}
                ScenariosWithChildren={ScenariosWithChildren}
                activeChildrenFilter={activeChildrenFilter}
                activeScenarioList={activeScenarioList}
                areVisibleRatiosValues={areVisibleRatiosValues}
                buttonFormat={buttonFormat}
                currentSummary={currentSummary}
                decimalScale={decimalScale}
                decimalSeparator={decimalSeparator}
                defaultMetricField={defaultMetricField}
                fixCurrentSummary={fixCurrentSummary}
                formats={formats}
                getMetricFormat={getMetricFormat}
                getRatio={getRatio}
                getScenarioById={getScenarioById}
                getScenarioUpdateAllowedById={getScenarioUpdateAllowedById}
                getSortedSummariesData={getSortedSummariesData}
                hasAnyUpdatableMetric={hasAnyUpdatableMetric}
                isChildrenFiltered={isChildrenFiltered}
                isEditMode={isEditMode}
                isFirstRow={false}
                isHidden={isHidden}
                isMillion={isMillion}
                isPercentage={isPercentage}
                isRowClickedEventOn={isRowClickedEventOn}
                isScenarioEditable={isScenarioEditable}
                isVisibleRatioValue={isVisibleRatioValue}
                isVisibleSplitValue={isVisibleSplitValue}
                isWithBaseVisible={isWithBaseVisible}
                isWithGroupedVisible={isWithGroupedVisible}
                rowIndex={rowIndex}
                scenario={childScenario}
                selectedMetricFields={selectedMetricFields}
                summariesData={summariesData}
                thousandSeparator={thousandSeparator}
                titleClass={nestedTitleClass}
                missingRowLockMessage={missingRowLockMessage}
                onFilterScenariosChildren={onFilterScenariosChildren}
                onNodeAction={onNodeAction}
                onRowClicked={onRowClicked}
                onToggleScenariosWithChildren={onToggleScenariosWithChildren}
                onUpdate={onUpdate}
              />
            )
          })
        : null}
    </>
  )
}
