// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import TaskList from '../Primatives/task-list-item'
import { grid, colors } from '../constants'
import type { TaskCard } from '../types'
import type {
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd'

const Wrapper = styled.div`
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? colors.blue.lighter : colors.blue.light};
  display: flex;
  flex-direction: column;
  padding: ${grid}px;
  user-select: none;
  transition: background-color 0.1s ease;
  margin: ${grid}px 0;
`

const DropZone = styled.div`
  display: flex;
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  align-items: start;

  /* stop the list collapsing when empty */
  min-width: 600px;
`

const ScrollContainer = styled.div`
  overflow: auto;
`

const Container = styled.div`
  /* flex child */
  flex-grow: 1;

  /* flex parent */
  /* needed to allow width to grow greater than body */
  display: inline-flex;
`

type Props = {|
  taskCards: TaskCard[],
  listId: string,
  listType?: string,
  internalScroll?: boolean,
  autoFocusTaskCardId?: ?string
|}

export default class TaskListGroup extends Component<Props> {
  renderBoard = (dropProvided: DroppableProvided) => {
    const { listType, taskCards } = this.props

    return (
      <Container>
        <DropZone ref={dropProvided.innerRef}>
          {taskCards.map((taskCard: TaskCard, index: number) => (
            <Draggable key={taskCard.id} draggableId={taskCard.id} type={listType} index={index}>
              {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                <div>
                  <TaskList
                    taskList={taskCard.taskList}
                    provided={dragProvided}
                    snapshot={dragSnapshot}
                    autoFocus={this.props.autoFocusTaskCardId === taskCard.id}
                  />
                  {dragProvided.placeholder}
                </div>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </DropZone>
      </Container>
    )
  }

  render() {
    const { listId, listType, internalScroll } = this.props

    return (
      <Droppable droppableId={listId} type={listType} direction="horizontal">
        {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
          <Wrapper isDraggingOver={dropSnapshot.isDraggingOver}>
            {internalScroll ? (
              <ScrollContainer>{this.renderBoard(dropProvided)}</ScrollContainer>
            ) : (
              this.renderBoard(dropProvided)
            )}
          </Wrapper>
        )}
      </Droppable>
    )
  }
}
