Ext.define('po.cmp.PromotionsDetailsGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'PromotionsDetailsGrid',
  alias: 'widget.PromotionsDetailsGrid',
  height: 500,
  width: 480,
  enableLocking: true,
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView().normalView,
      grouping = normalView.getFeature('grouping')

    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText

    me.unlockText = i18n.GridConfig_unlockText
    me.lockText = i18n.GridConfig_lockText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'gridexporter',
      id: 'gridexport'
    }
  ],
  listeners: {
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady'
  },
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    }
  ],
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      dataIndex: 'PromosyonAciklama',
      text: '&nbsp;',
      bind: {
        text: '{i18n.Window_Promotions_Column_PromosyonAciklama}'
      },
      width: 175,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'BaslangicTarihi',
      bind: {
        text: '{i18n.Window_Promotions_Column_BaslangicTarihi}'
      },
      width: 130,
      align: 'center',
      renderer: function (value) {
        var date = new Date(value)
        var newDate = Ext.Date.format(date, 'Y-m-d')
        return newDate
      }
    },
    {
      dataIndex: 'BitisTarihi',
      text: '&nbsp;',
      bind: {
        text: '{i18n.Window_Promotions_Column_BitisTarihi}'
      },
      align: 'center',
      width: 130,
      renderer: function (value) {
        var date = new Date(value)
        var newDate = Ext.Date.format(date, 'Y-m-d')
        return newDate
      }
    }
  ]
})
