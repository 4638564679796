import { useState } from 'react'
import { Container, Nav, Tab } from 'react-bootstrap'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import cx from 'classnames'
import { tuiSlice } from '../../store/slices'
import { useReduxState } from '../../hooks'
import LoadTreeTable from '../LoadTreeTable'
import DemandTreeTable from '../DemandTreeTable'
import IconButton from '../IconButton'
import SettingsModal from '../SettingsModal'
import styles from './TreeTableView.module.scss'

export default function TreeTableView({ demandTreeTableRef, loadTreeTableRef }) {
  const [{ config }] = useReduxState(tuiSlice.selectSlice)
  const [activeSettings, setActiveSettings] = useState<'loadTree' | 'demandTree' | null>(null)

  return (
    <div className={styles.treeTableView}>
      <ReflexContainer orientation="vertical">
        <ReflexElement className="left-pane">
          <div className={styles.loadTreeTableSection}>
            <Tab.Container defaultActiveKey="load" transition={false}>
              <Container className={styles.tabBar}>
                <Nav className={styles.tabs}>
                  <Nav.Item
                    className={cx(styles.tab, styles.isSelected)}
                    // onClick={() => handleClickTab('load')}
                  >
                    <Nav.Link className={styles.tabLink} eventKey="load">
                      Load
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className={styles.tableControls}>
                  <IconButton
                    className={styles.tableControl}
                    onClick={() => setActiveSettings('loadTree')}
                  >
                    <i className={cx(styles.tableControlIcon, 'fa fa-fw fa slvy-ui-icon-cog')} />
                  </IconButton>
                </div>
              </Container>
              <Tab.Content className={styles.tabContent}>
                <Tab.Pane className={styles.tabPane} eventKey="load">
                  <LoadTreeTable tableRef={loadTreeTableRef} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </ReflexElement>
        <ReflexSplitter
          className={styles.treeTableViewReflexSplitter}
          style={{ backgroundColor: config.settings.backgroundColor }}
        />
        <ReflexElement className="right-pane">
          <div className={styles.demandTreeTableSection}>
            <Tab.Container defaultActiveKey="demand" transition={false}>
              <Container className={styles.tabBar}>
                <Nav className={styles.tabs}>
                  <Nav.Item
                    className={cx(styles.tab, styles.isSelected)}
                    // onClick={() => handleClickTab('demand')}
                  >
                    <Nav.Link className={styles.tabLink} eventKey="demand">
                      Demand
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className={styles.tableControls}>
                  <IconButton
                    className={styles.tableControl}
                    onClick={() => setActiveSettings('demandTree')}
                  >
                    <i className={cx(styles.tableControlIcon, 'fa fa-fw fa slvy-ui-icon-cog')} />
                  </IconButton>
                </div>
              </Container>
              <Tab.Content className={styles.tabContent}>
                <Tab.Pane className={styles.tabPane} eventKey="demand">
                  <DemandTreeTable tableRef={demandTreeTableRef} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </ReflexElement>
      </ReflexContainer>

      <SettingsModal
        isOpen={Boolean(activeSettings)}
        treeName={activeSettings}
        onClose={() => setActiveSettings(null)}
      />
    </div>
  )
}
