export enum AG_GRID_ENUMS {
  ROW_ID = '_SLVY_AG_GRID_ROW_ID_',
  CUSTOM_ROW_INFO = '_SLVY_AG_GRID_CUSTOM_ROW_INFO_',
  DELETE_COLUMN = '_SLVY_AG_GRID_DELETE_COLUMN_',
  CHECKBOX_SELECTION_COLUMN = '_SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_',
  ADDED_ROWS = '_SLVY_AG_GRID_ADDED_ROWS_',
  DIRTY_CELLS = '_SLVY_AG_GRID_DIRTY_CELLS_',
  LOOK_UP_FIELD_DATA = '_SLVY_FULL_LOOK_UP_FIELD_DATA_',
  AUTO_GROUP_COLUMN = 'ag-Grid-AutoColumn'
}
