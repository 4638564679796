import React from 'react'
import { render, screen } from '../../../utils/testUtils/dp-test-utils'

import HeaderButtonGroup from './HeaderButtonGroup'
import { IHeaderButtonGroupProps } from './HeaderButtonGroup.types'

const getDefaultProps = (): IHeaderButtonGroupProps => {
  return {
    actionLogsColumnName: 'actionLogsColumnName',
    isScenarioEditable: true,
    isVisibleActionsButton: true,
    isVisibleApplyChanges: true,
    isVisibleChangeScenarioButton: true,
    isVisibleDiscardChanges: true,
    isVisiblePivotBuilderButton: true,
    isWorkInSubsetEnabled: true,
    pluginId: '123',
    pluginStates: [],
    preferredUsername: 'John Doe',
    onApplySubsetChangesHandle: () => {},
    onDiscardHandle: () => {},
    onPivotStateChanged: () => {},
    onReloadViewClick: () => {},
    onViewChangedCallback: () => {}
  }
}

describe('CoefButtons', () => {
  let props = getDefaultProps()

  beforeEach(() => {
    props = getDefaultProps()
  })

  it('renders without crash', () => {
    render(<HeaderButtonGroup {...props} />)
    const element = screen.getByTestId('header-button-group')
    expect(element).toBeInTheDocument()
  })
})
