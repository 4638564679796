Ext.define('po.model.InventoryModel', {
  extend: 'po.model.Base',
  entityName: 'po/inventory',
  fields: [
    { name: 'matnr', type: 'string' },
    { name: 'depoKodu', type: 'string' },
    { name: 'tarih', type: 'string' },
    { name: 'PeriodID', type: 'int' },
    { name: 'urunkodu', type: 'string' },
    { name: 'labst', type: 'float' },
    { name: 'koli_ici_adet', type: 'float' },
    { name: 'Envanter', type: 'float' },
    { name: 'Acilan_Miktar', type: 'float' },
    { name: 'Kapanan_Miktar', type: 'float' },
    { name: 'Magaza_Talep', type: 'float' },
    { name: 'EnvanterSifir', type: 'int' },
    { name: 'TeminSuresi', type: 'float' },
    { name: 'BirSonrakiTeslim', type: 'int' },
    { name: 'NetEnvater', type: 'float' }
  ]
})
