import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Row, Col, Tab, Nav, Form, Offcanvas, FormLabel } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import _ from 'lodash'
import request from 'superagent'
import moment from 'moment'
import cx from 'classnames'

import {
  SlvyJsonTable,
  SlvyProgress,
  SlvyFormInput,
  SlvyFormIconSelector,
  SlvySelect,
  slvyToast,
  SlvyFormCheckbox
} from '@/components'
import Template from './Template'
import CopyToEnv from './CopyToEnv'

import { selectCollection, selecOperationStatus, select, isSuccess } from '@/crudoptV3'
import { parseErrorMsg, elipsisMiddle } from '@/helpers'
import { toReactSelectV2 } from '@/helpers/mapper'
import toastrTypes from '../../../../helpers/ToastrTypes'
import { getPage, updatePage, deletePage, clonePage } from '@/actions/page'
import { getUser } from '@/actions/user'
import { getUserGroups } from '@/actions/userGroup'
import { getCatalog } from '@/actions/catalog'
import { uploadHelpPdf, deleteHelpPdf } from '@/actions/pageTemplate'
import { togglePageConfig, setHelpPdfUrl } from '@/store/slices/pageConfig'
import permission, { PermTypes } from '@/helpers/Permission'
import { API_URL } from '@/constants'
import { isHiddenColumns, llmInputColumns, wipColumns } from './constants'

import './style.scss'

const formCheckProps = {
  reverse: true,
  type: 'switch',
  variant: 'success'
}

class PageConfiguration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      page: {},
      isMultiple: true,
      user: {},
      logs: [],
      isCloning: false,
      isPdfUploading: false,
      helpPdfName: ''
    }

    this.cloningStyles = {
      height: 33,
      width: 90,
      padding: 0
    }

    this.userLogRef = createRef()

    this.handleClick = this.handleClick.bind(this)
    this.handleClonePage = this.handleClonePage.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleTogglePageConfig = this.handleTogglePageConfig.bind(this)
    this.uploadPdfBtnContent = this.uploadPdfBtnContent.bind(this)
  }

  componentDidMount() {
    const { userId, getUser, page } = this.props

    getUser(userId).then((user) => {
      this.setState({ user })
    })

    this.setState({
      page: page.data,
      userLogs: []
    })
  }

  UNSAFE_componentWillMount() {
    if (this.props.catalog.needFetch) {
      this.props.dispatch(this.props.catalog.fetch)
    }
    if (this.props.page.needFetch) {
      this.props.dispatch(this.props.page.fetch)
    }
    if (this.props.userGroups.needFetch) {
      this.props.dispatch(this.props.userGroups.fetch)
    }
    if (this.props.page.isSuccess) {
      this.setState({
        page: {
          ...this.props.page.data
        }
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.catalog.needFetch) {
      this.props.dispatch(nextProps.catalog.fetch)
    }
    if (nextProps.page.needFetch && Object.keys(nextProps.page.fetch.meta.data).length) {
      this.props.dispatch(nextProps.page.fetch)
    }

    if (nextProps.userGroups.needFetch) {
      this.props.dispatch(nextProps.userGroups.fetch)
    }
    if (
      isSuccess(this.props.page, nextProps.page) ||
      !_.isEqual(this.props.page.data, nextProps.page.data)
    ) {
      this.setState({
        page: {
          ...nextProps.page.data
        }
      })
    }

    if (isSuccess(this.props.deleteStatus, nextProps.deleteStatus)) {
      slvyToast.success({
        message: `Page ${toastrTypes.true.deleteMsg}`,
        title: this.props.page.data.name
      })
    }
  }

  handleClick(ref) {
    return (event) => {
      event.preventDefault()
      this.setState({ show: false })
      if (this.props.onSelect) {
        this.props.onSelect(ref)
      }
      return false
    }
  }

  handleClonePage(event) {
    event.preventDefault()

    this.setState({ isCloning: true }, () => {
      const {
        params: { catalogId, menuId, pageId },
        togglePageConfig,
        clonePage
      } = this.props

      clonePage('clone', catalogId, menuId, pageId).then(() => {
        slvyToast.success({ message: 'Page copied!' })
        this.setState({ isCloning: false })
        togglePageConfig()
      })
    })
  }

  handleChange(label) {
    return (value) => {
      this.setState({
        page: {
          ...this.state.page,
          [label]: label === 'isHidden' ? !value : value
        }
      })
    }
  }

  handleOnChange(values) {
    const nonEmptyValue = values?.filter((elem) => elem.value.trim().length)
    const keywords = nonEmptyValue?.map((elem) => elem.value) || []
    this.setState({
      page: { ...this.state.page, keywords }
    })
  }

  getKeywordsWithOptions() {
    const { page: { keywords = [] } = {} } = this.state
    return _.map(keywords, (keyword) => {
      return {
        value: keyword,
        label: keyword,
        className: 'Select-create-option-placeholder'
      }
    })
  }

  handleDelete(event) {
    event.preventDefault()
    confirmAlert({
      title: 'Deleting Page?',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Cancel'
        },
        {
          label: 'Confirm Delete',
          onClick: () => {
            const {
              params: { catalogId, menuId, pageId }
            } = this.props
            this.props.deletePage(catalogId, menuId, pageId)
            this.props.togglePageConfig()
          }
        }
      ]
    })
  }

  handleSave(event) {
    event.preventDefault()
    const {
      params: { catalogId, menuId, pageId },
      updatePage,
      setHelpPdfUrl
    } = this.props
    let {
      page,
      page: { userGroups, helpUrl }
    } = this.state

    userGroups = _.map(userGroups, (userGroup) => (userGroup.value ? userGroup.value : userGroup))
    page = { ...page, userGroups }

    updatePage(catalogId, menuId, pageId, page)
      .then(() => {
        slvyToast.success({ message: 'Changes Saved!' })
        if (helpUrl) {
          this.setState((prevState) => ({ page: { ..._.omit(prevState.page, 'helpUrl') } }))
          setHelpPdfUrl(helpUrl)
        }
      })
      .catch((error) => {
        slvyToast.error({ title: 'Failed to save changes!', message: parseErrorMsg(error) })
      })
  }

  getUserLogs(userLogs) {
    if (!userLogs || userLogs.length === 0) {
      return <div ref={this.userLogRef} />
    }

    return (
      <div ref={this.userLogRef}>
        <SlvyJsonTable
          className="table table-bordered table-striped"
          columns={
            _.size(userLogs) > 0
              ? [
                  { key: 'ComponentName', label: 'Component' },
                  { key: 'Action', label: 'Action' },
                  {
                    key: 'Time',
                    label: 'Update Time',
                    cell(item) {
                      return moment(item.Time).format('DD/MM/YYYY HH:mm:ss')
                    }
                  }
                ]
              : []
          }
          rows={_.map(userLogs, (log) => {
            return {
              User: log.user,
              ComponentName: log.componentName,
              Action: log.action,
              Time: log.time
            }
          })}
        />
      </div>
    )
  }

  getChangeLogData() {
    const { params: { catalogId, pageId } = {} } = this.props
    const me = this
    me.setState({ logs: [], userLogs: [], isLoading: true, row: null })
    request
      .get(`${API_URL}/changelogs/catalog/${catalogId}/page/${pageId}`)
      .send()
      .then((response) => {
        me.setState({ logs: response.body, isLoading: false })
      })
      .catch(() => {
        me.setState({ userLogs: [], isLoading: false })
      })
  }

  onClickRow(e, item) {
    const { params: { catalogId, pageId } = {} } = this.props
    this.setState({ userLogLoading: true })
    const me = this
    request
      .post(`${API_URL}/changelogs/catalog/${catalogId}/page/${pageId}/getByDate`)
      .send({
        User: item.User,
        EndTime: item.EndTime,
        StartTime: item.StartTime
      })
      .then((response) => {
        me.setState({ userLogs: response.body, userLogLoading: false }, () => {
          me.userLogRef.current?.scrollIntoView?.({ block: 'start' })
        })
      })
      .catch(() => {})

    this.setState({ row: item.EndTime })
  }

  onFileChange(event) {
    const {
      params: { catalogId, menuId, pageId },
      uploadHelpPdf
    } = this.props
    const {
      target: { files }
    } = event

    const helpPdf = _.first(files) || {}
    const isPdfFile = helpPdf.type === 'application/pdf'
    if (isPdfFile) {
      this.setState({ isPdfUploading: true, helpPdfName: helpPdf.name })
      const formData = new FormData()
      formData.append('file', helpPdf, helpPdf.name)

      uploadHelpPdf(catalogId, menuId, pageId, formData)
        .then((helpUrl) => {
          this.setState((prevState) => ({
            page: { ...prevState.page, helpUrl },
            isPdfUploading: false
          }))
          slvyToast.success({ message: 'PDF uploaded successfully!' })
        })
        .catch((error) => {
          slvyToast.error({ message: parseErrorMsg(error), title: 'PDF upload filed!' })
        })
    } else {
      slvyToast.error({ message: 'File type is not pdf.', title: 'Upload Failed!' })
    }
  }

  handleTogglePageConfig() {
    const {
      params: { catalogId, menuId, pageId },
      togglePageConfig,
      deleteHelpPdf
    } = this.props
    const { helpPdfName, page: { helpUrl } = {} } = this.state

    if (helpPdfName && helpUrl) {
      deleteHelpPdf(catalogId, menuId, pageId, helpUrl).then(() => {
        this.setState((prevState) => ({
          page: { ..._.omit(prevState.page, 'helpUrl') },
          helpPdfName: ''
        }))
      })
      togglePageConfig()
    } else {
      togglePageConfig()
    }
  }

  uploadPdfBtnContent() {
    const { isPdfUploading, helpPdfName } = this.state

    if (!isPdfUploading && !helpPdfName) {
      return (
        <span>
          Choose a file...
          <i className="fa fa-upload" />
        </span>
      )
    }
    if (isPdfUploading) {
      return (
        <span>
          Uploading...
          <i className="fa fa-spinner spin" />
        </span>
      )
    }
    return <span>{elipsisMiddle(helpPdfName)}</span>
  }

  render() {
    const {
      show,
      userGroups,
      updateStatus,
      params,
      params: { environment, catalogId, menuId, pageId, storeIndex },
      checkPermission
    } = this.props
    const {
      page: {
        name,
        isHidden,
        wip,
        iconClass,
        userGroups: pageUserGroups,
        llmModel,
        llmServiceSqlConnectionId,
        llmServiceConnectionId
      } = {},
      isMultiple,
      isCloning,
      isPdfUploading
    } = this.state
    const { logs, userLogs, userLogLoading, isLoading = true } = this.state
    const keywordsWithOptions = this.getKeywordsWithOptions()
    const baseUrl = `/${environment}/catalog/${catalogId}/store/${storeIndex}/menu/${menuId}/page/${pageId}`
    const relationsUrl = `${baseUrl}/relations`

    return (
      <Offcanvas
        className="theme-config-offcanvas"
        enforceFocus={false}
        placement="end"
        show={show}
        onHide={this.handleTogglePageConfig}
      >
        <div className="theme-config">
          <div className="theme-config-box show">
            <div className="page-config skin-settings">
              <Tab.Container defaultActiveKey="PageSettings">
                <Nav className="mt-3 ps-3" variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="PageSettings">Page Settings</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ChangeLog" onClick={this.getChangeLogData.bind(this)}>
                      Change Log
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="trg-btn">
                  <Button
                    as={Link}
                    size="sm"
                    to={relationsUrl}
                    variant="success"
                    onClick={this.handleTogglePageConfig}
                  >
                    Triggers & Actions
                  </Button>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="PageSettings">
                    <SlvyProgress isLoading={false}>
                      <div className="panel-content p-3">
                        <Form className="form-horizontal plugin-settings">
                          <SlvyFormInput
                            error={updateStatus.error}
                            errorModelLabel="page.Name"
                            label="Name"
                            labelClass="justify-content-end"
                            value={name}
                            onChange={this.handleChange('name')}
                          />
                          <div className="hr-line-dashed" />
                          <Form.Group as={Row} className="form-group mb-3">
                            <Form.Label
                              column
                              className="d-flex align-items-center justify-content-end"
                              htmlFor="react-select-pageConfigurationKeywords-input"
                              sm={2}
                            >
                              Keywords
                            </Form.Label>
                            <Col sm="10">
                              <SlvySelect
                                isCreatable
                                className="no-selection"
                                instanceId="pageConfigurationKeywords"
                                isMulti={isMultiple}
                                placeholder=""
                                value={keywordsWithOptions}
                                onChange={this.handleOnChange.bind(this)}
                              />
                            </Col>
                          </Form.Group>
                          <div className="hr-line-dashed" />
                          <Form.Group as={Row} className="form-group">
                            <FormLabel
                              column
                              className="d-flex align-items-center justify-content-end cp fs-6 pe-0"
                              htmlFor="PageConfigurationIsVisibleInputId"
                              md={2}
                            >
                              Visible
                            </FormLabel>
                            <Col sm={10}>
                              <Row className="align-items-center justify-content-between">
                                <Col auto>
                                  <SlvyFormCheckbox
                                    noMarginBottom
                                    columns={isHiddenColumns}
                                    error={updateStatus.error}
                                    errorModelLabel="page.IsHidden"
                                    formCheckProps={formCheckProps}
                                    inputId="PageConfigurationIsVisibleInputId"
                                    isChecked={!isHidden}
                                    onChange={this.handleChange('isHidden')}
                                  />
                                </Col>
                                <Col auto>
                                  <SlvyFormCheckbox
                                    noMarginBottom
                                    columns={wipColumns}
                                    error={updateStatus.error}
                                    errorModelLabel="page.WIP"
                                    formCheckProps={formCheckProps}
                                    isChecked={wip}
                                    label="WIP"
                                    onChange={this.handleChange('wip')}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Form.Group>
                          <div className="hr-line-dashed" />
                          <SlvyFormIconSelector
                            error={updateStatus.error}
                            errorModelLabel="fa fa-bar-chart-o"
                            label="Icon"
                            value={iconClass}
                            onChange={this.handleChange('iconClass')}
                          />
                          <div className="hr-line-dashed" />
                          <Form.Group as={Row} className="form-group mb-3">
                            <Form.Label
                              column
                              className="d-flex align-items-center justify-content-end"
                              htmlFor="helpPdf"
                              sm={2}
                            >
                              PDF
                            </Form.Label>
                            <Col sm="10">
                              <Form.Label
                                className={`w-100 btn border m-0 ${cx({
                                  'opacity-50': isPdfUploading
                                })}`}
                              >
                                {this.uploadPdfBtnContent()}
                                <input
                                  accept="application/pdf"
                                  className="d-none"
                                  disabled={isPdfUploading}
                                  id="helpPdf"
                                  name="helpPdf"
                                  type="file"
                                  onChange={this.onFileChange}
                                />
                              </Form.Label>
                            </Col>
                          </Form.Group>
                          <div className="hr-line-dashed" />
                          <Form.Group as={Row} className="form-group mb-3">
                            <Form.Label
                              column
                              className="d-flex align-items-center justify-content-end"
                              htmlFor="react-select-pageConfigurationGroups-input"
                              sm={2}
                            >
                              Groups
                            </Form.Label>
                            <Col sm="10">
                              <SlvySelect
                                isMulti
                                className="no-selection"
                                closeMenuOnSelect={false}
                                instanceId="pageConfigurationGroups"
                                menuPlacement="auto"
                                options={_.map(userGroups.data, ({ id, name }) => ({
                                  value: id,
                                  label: name
                                }))}
                                placeholder=""
                                styles={{
                                  menu: (provided) => ({ ...provided, zIndex: 3 })
                                }}
                                value={toReactSelectV2(userGroups.data, pageUserGroups)}
                                onChange={this.handleChange('userGroups')}
                              />
                            </Col>
                          </Form.Group>
                          {checkPermission
                            .hasPermType(PermTypes.PageTemplateCreate)
                            .isEnv('Configuration').value && [
                            <div key="1" className="hr-line-dashed" />,
                            <Template key="2" params={params} />
                          ]}
                          <SlvyFormInput
                            columns={llmInputColumns}
                            error={updateStatus.error}
                            errorModelLabel="page.LlmModel"
                            label="LLM Model"
                            value={llmModel}
                            onChange={this.handleChange('llmModel')}
                          />
                          <SlvyFormInput
                            columns={llmInputColumns}
                            error={updateStatus.error}
                            errorModelLabel="page.LlmServiceSqlConnectionId"
                            label="Sql Connection Id"
                            value={llmServiceSqlConnectionId}
                            onChange={this.handleChange('llmServiceSqlConnectionId')}
                          />
                          <SlvyFormInput
                            columns={llmInputColumns}
                            error={updateStatus.error}
                            errorModelLabel="page.LlmServiceConnectionId"
                            label="Service Connection Id"
                            value={llmServiceConnectionId}
                            onChange={this.handleChange('llmServiceConnectionId')}
                          />
                        </Form>
                      </div>
                    </SlvyProgress>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ChangeLog">
                    <div className="panel-content p-3">
                      <Form.Group as={Row} className="form-group">
                        <SlvyProgress isLoading={isLoading}>
                          <SlvyJsonTable
                            className="table table-bordered table-striped"
                            columns={
                              _.size(logs) > 0 && [
                                { key: 'User', label: 'User' },
                                {
                                  key: 'EndTime',
                                  label: 'Update Time',
                                  cell(item) {
                                    return moment(item.EndTime).format('DD/MM/YYYY HH:mm:ss')
                                  }
                                }
                              ]
                            }
                            rows={_.map(logs, (log) => {
                              return {
                                User: log.user,
                                EndTime: log.endTime,
                                StartTime: log.startTime,
                                Id: log.id
                              }
                            })}
                            onClickRow={this.onClickRow.bind(this)}
                          />
                        </SlvyProgress>
                        <SlvyProgress isLoading={userLogLoading}>
                          {this.getUserLogs(userLogs)}
                        </SlvyProgress>
                      </Form.Group>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <div className="d-flex justify-content-between p-3 bg-white">
                  <Button className="w-25" variant="danger" onClick={this.handleDelete}>
                    Delete
                  </Button>
                  {checkPermission.hasPermType(PermTypes.CopyToEnv).isEnv('Configuration')
                    .value && <CopyToEnv container={this} params={params} />}
                  <Button
                    className="w-25 border"
                    disabled={isCloning}
                    style={isCloning ? this.cloningStyles : {}}
                    variant="btn"
                    onClick={this.handleClonePage}
                  >
                    {isCloning ? <i className="fa-fw fa fa-circle-o-notch spin" /> : 'Copy'}
                  </Button>
                </div>
                <div className="d-flex justify-content-end px-3 py-2">
                  <Button className="w-25" variant="success" onClick={this.handleSave}>
                    Save
                  </Button>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Offcanvas>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const catalog = select(getCatalog(ownProps.params.catalogId), state.model3)
  const { data: { customerId } = {} } = catalog
  const {
    profile: { sub: userId }
  } = state.oidc.user

  return {
    userId,
    show: state.pageConfig.show,
    page: select(
      getPage(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
      state.model3
    ),
    catalog,
    userGroups: customerId
      ? selectCollection(getUserGroups(customerId), state.model3)
      : { needFetch: false },
    updateStatus: selecOperationStatus('page', 'update', state.model3),
    deleteStatus: selecOperationStatus('page', 'delete', state.model3),
    checkPermission: permission(state.oidc.user, ownProps.params.environment)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getUser,
        updatePage,
        deletePage,
        clonePage,
        togglePageConfig,
        uploadHelpPdf,
        deleteHelpPdf,
        setHelpPdfUrl
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageConfiguration)
