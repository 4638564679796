import { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

export default class SimpleValue extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.state = {
      value: '',
      type: 'int',
      ...props.value
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.onChange && nextProps.value !== this.state) {
      this.setState(nextProps.value)
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleSelect(event) {
    this.setState({ type: event.target.value })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      this.props.onChange &&
      (nextState.value !== this.state.value || nextState.type !== this.state.type)
    ) {
      this.props.onChange(nextState)
    }
  }

  render() {
    return (
      <Row>
        <Col md={6}>
          <input
            className="w-100 form-control form-control-sm"
            type="text"
            value={this.state.value}
            onChange={this.handleChange}
          />
        </Col>
        <Col md={6}>
          <select
            className="w-100 form-select form-select-sm"
            value={this.state.type}
            onChange={this.handleSelect}
          >
            {this.props.dataTypes.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </select>
        </Col>
      </Row>
    )
  }
}
