/* global Ext */
import _ from 'lodash'
import moment from 'moment-timezone'
import numeral from '../locales/numeral'

const formats = {
  datetime: {
    'Date m/d/Y': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('MM/DD/Y')
      } catch (err) {}
    },
    'Date m/d/Y H:m:s': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('MM/DD/Y H:m:s')
      } catch (err) {}
    },
    'Date m/d/Y HH:mm:ss': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('MM/DD/Y HH:mm:ss')
      } catch (err) {}
    },
    'Date d/m/Y': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('DD/MM/Y')
      } catch (err) {}
    },
    'Date d/m/Y H:m:s': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('DD/MM/Y H:m:s')
      } catch (err) {}
    },
    'Date d/m/Y HH:mm:ss': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('DD/MM/Y HH:mm:ss')
      } catch (err) {}
    },
    'Date M d': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('MM DD')
      } catch (err) {}
    },
    'Date m-d-Y': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('MM-DD-Y')
      } catch (err) {}
    },
    'Date m-d-Y H:m:s': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('MM-DD-Y H:m:s')
      } catch (err) {}
    },
    'Date m-d-Y HH:mm:ss': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('MM-DD-Y HH:mm:ss')
      } catch (err) {}
    },
    'Date d-m-Y': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('DD-MM-Y')
      } catch (err) {}
    },
    'Date d-m-Y H:m:s': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('DD-MM-Y H:m:s')
      } catch (err) {}
    },
    'Date d-m-Y HH:mm:ss': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('DD-MM-Y HH:mm:ss')
      } catch (err) {}
    },
    'Date Y/m/d': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('Y/MM/DD')
      } catch (err) {}
    },
    'Date Y-m-d': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('Y-MM-DD')
      } catch (err) {}
    },
    'Date D. MMM': (value) => {
      try {
        return moment(value).tz(moment.tz.guess()).format('D. MMM')
      } catch (err) {}
    }
  },
  number: {
    'Currency $': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.usMoney(value)
    },
    'Currency $ 0,000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `$${Ext.util.Format.number(value, '0,000')}`
    },
    'Currency $ M': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `$${Ext.util.Format.number(value / 1000000, '0,000.##')}M`
    },
    'Currency $ K': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `$${Ext.util.Format.number(value / 1000, '0,000.##')}K`
    },
    'Currency ₺': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value, '0,000.00')
      cleanUp(result)
      return `${result}₺`
    },
    'Currency ₺ 0,000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value, '0,000')
      cleanUp(result)
      return `${result}₺`
    },
    'Currency ₺ M': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value / 1000000, '0,000.##')
      cleanUp(result)
      return `${result}M₺`
    },
    'Currency ₺ K': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value / 1000, '0,000.##')
      cleanUp(result)
      return `${result}K₺`
    },
    'Currency €': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.currency(value, '€')
    },
    'Currency € 0,000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `€${Ext.util.Format.number(value, '0,000')}`
    },
    'Currency € 0.000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      let result = `€${Ext.util.Format.number(value, '0,000')}`
      result = result.replace(/,/g, '.')
      return result
    },
    'Currency € M': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `€${Ext.util.Format.number(value / 1000000, '0,000.##')}M`
    },
    'Currency € K': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `€${Ext.util.Format.number(value / 1000, '0,000.##')}K`
    },
    Percent: (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.number(value * 100, '0.##%')
    },
    'Percent 0,000.0': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.number(value * 100, '0,000.0%')
    },
    'Percent 0,000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.number(value * 100, '0,000%')
    },
    'Number 0,000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.number(value, '0,000')
    },
    'Number 0,000.0': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.number(value, '0,000.0')
    },
    'Number 0,000.00': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.number(value, '0,000.00')
    },
    'Number 0,000.000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return Ext.util.Format.number(value, '0,000.000')
    },
    'Number 0.000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      let result = Ext.util.Format.number(value, '0,000')
      result = result.replace(/,/g, '.')
      return result
    },
    'Number 0.000,0': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value, '0,000.0')
      cleanUp(result)
      return result
    },
    'Number 0.000,00': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value, '0,000.00')
      cleanUp(result)
      return result
    },
    'Number 0.000,000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value, '0,000.000')
      cleanUp(result)
      return result
    },
    'Number K': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `${Ext.util.Format.number(value / 1000, '0,000.##')}K`
    },
    'Number M': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `${Ext.util.Format.number(value / 1000000, '0,000.##')}M`
    },
    'Number B': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      return `${Ext.util.Format.number(value / 1000000000, '0,000.##')}B`
    },
    'Number K 0.000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value / 1000, '0,000.##')
      cleanUp(result)
      return `${result}K`
    },
    'Number M 0.000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value / 1000000, '0,000.##')
      cleanUp(result)
      return `${result}M`
    },
    'Number B 0.000': (value) => {
      if (value === 0 || value === '') {
        return '-'
      }
      const result = Ext.util.Format.number(value / 1000000000, '0,000.##')
      cleanUp(result)
      return `${result}B`
    }
  }
}

function cleanUp(result) {
  result = result.replace(/,/g, '_')
  result = result.replace(/\./g, ',')
  result = result.replace(/_/g, '.')
  return result
}

export const getValidFormat = (dataType) => {
  switch (dataType) {
    case 'date':
    case 'datetime':
      return 'datetime'

    case 'int':
    case 'double':
    case 'decimal':
    case 'float':
    case 'real':
      return 'number'

    default:
      return false
  }
}

export const getFormats = (dataType) => {
  switch (dataType) {
    case 'date':
    case 'datetime':
      return formats.datetime

    case 'int':
    case 'double':
    case 'decimal':
    case 'float':
    case 'real':
      return formats.number

    default:
      return {}
  }
}

export const getFormatedValue = (format, value, row = {}) => {
  const queryFormatRegex = new RegExp(/\{([^}]+)\}/g)
  const groupFormatRegex = new RegExp('("(.*?)"|)([^"]+)("(.*?)"|)')

  const hasDataFormat = queryFormatRegex.test(format)

  if (hasDataFormat && !_.isEmpty(row)) {
    const formatColumn = format.replace(queryFormatRegex, (str, val) => val || str)
    format = row[formatColumn] || format
  }

  if (format && !_.isEmpty(format)) {
    let find = _.get(formats, ['datetime', format])
    if (find) {
      return find(value)
    }
    find = _.get(formats, ['number', format])

    if (find) {
      return find(value)
    }

    const groups = format.match(groupFormatRegex)

    const symbols = {
      before: groups[2],
      after: groups[5]
    }

    const output = numeral(value).format(groups[3])
    return _.join([symbols.before, output, symbols.after], '')
  }
  return value
}

export const getValueFilterFormats = (valueFilters, args) => {
  return valueFilters.reduce((valueFilterFormats, valueFilter) => {
    if (valueFilter.dataType !== 'string') {
      return valueFilterFormats
    }

    const key = valueFilter.parameterName
    const isKeyExistInArgs = args && key in args
    const format = isKeyExistInArgs ? args[key] : valueFilter.defaultValue
    return { ...valueFilterFormats, [key]: format }
  }, {})
}
