export default () => {
  return {
    title: 'Button',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          enableAutoLoad: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Enable Auto Load?'
          }
        }
      },
      variables: {
        type: 'array',
        title: 'Variables',
        items: {
          title: 'Variable',
          type: 'object',
          headerTemplate: '{{ self.name }}',
          properties: {
            name: {
              type: 'string',
              title: 'Name'
            },
            isMultiple: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Is Multiple'
            },
            type: {
              type: 'string',
              title: 'Type',
              default: 'string',
              enum: ['int', 'string', 'dynamic'],
              required: true
            }
          }
        }
      },
      submit: {
        type: 'object',
        title: 'Submit Settings',
        properties: {
          text: {
            type: 'string',
            title: 'Button Text'
          },
          style: {
            type: 'string',
            title: 'Style',
            default: 'primary',
            enum: ['success', 'primary', 'info', 'warning', 'danger'],
            required: true
          },
          icon: {
            type: 'string',
            title: 'Icon',
            default: 'fa-check',
            format: 'IconSelector'
          },
          iconPosition: {
            type: 'string',
            title: 'Icon Position',
            default: 'Left',
            enum: ['Left', 'Right'],
            required: true
          },
          sendFalsy: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Send Falsy'
          }
        }
      },
      reset: {
        type: 'object',
        title: 'Reset Settings',
        properties: {
          show: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Show'
          },
          text: {
            type: 'string',
            title: 'Text'
          }
        }
      },
      newState: {
        type: 'object',
        title: 'New State Settings',
        properties: {
          text: {
            type: 'string',
            title: 'Text'
          },
          placement: {
            type: 'string',
            title: 'Popover Placement',
            default: 'left',
            enum: ['left', 'right', 'top', 'bottom'],
            required: true
          }
        }
      }
    }
  }
}
