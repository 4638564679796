// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import { grid } from '../constants'
import { Draggable } from 'react-beautiful-dnd'
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import TaskCardList from '../Primatives/task-card-group'
import Title from '../Primatives/title'
import type { TaskCard } from '../types'
import './Board.scss'

const Small = styled.span`
  font-size: 75%;
  font-weight: 400;
  color: #777;
`
const Wrapper = styled.div``
const Container = styled.div`
  margin: ${grid}px;
`
const Header = styled.div`
  background-color: ${({ isDragging }) => (isDragging ? '#07253d' : '#fcfcfc')};
`

type Props = {|
  title: string,
  taskCards: TaskCard[],
  index: number,
  autoFocusTaskCardId: ?string
|}

export default class Column extends Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      taskCards: this.props.taskCards
    }
    this.sendTask = this.sendTask.bind(this)
  }
  sendTask(taskCard) {
    this.props.onShow(taskCard)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      taskCards: nextProps.taskCards
    })
  }
  render() {
    const title = this.props.title
    const taskCards = this.state.taskCards
    const index: number = this.props.index
    const { taskAppearanceProperties, localizationLanguage } = this.props
    return (
      <Draggable draggableId={title} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <Wrapper className="sc-box-wrapper">
            <Container
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="sc-box-container"
            >
              <Header className="sc-box-header" isDragging={snapshot.isDragging}>
                <Title
                  className="sc-box-title"
                  isDragging={snapshot.isDragging}
                  {...provided.dragHandleProps}
                >
                  <b>{title}</b>
                  <br />
                  <Small>
                    {taskCards.length} {localizationLanguage.Tasks}
                  </Small>
                </Title>
              </Header>
              <TaskCardList
                listId={title}
                className="sc-box-task-list"
                listType="TASKCARD"
                taskCards={taskCards}
                autoFocusTaskCardId={this.props.autoFocusTaskCardId}
                onShow={this.sendTask}
                taskAppearanceProperties={taskAppearanceProperties}
                uniqueUserName={this.props.uniqueUserName}
                tasksAvailableNavigations={this.props.tasksAvailableNavigations}
                statuses={this.props.statuses}
                getTask={this.props.getTask}
                format={this.props.format}
                openContent={this.props.openContent}
              />
            </Container>
            {provided.placeholder}
          </Wrapper>
        )}
      </Draggable>
    )
  }
}
