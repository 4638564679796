export default `({
    layout: 'fit',
    style: {
        background: '#fff',
    },
    items: [{
        xtype: 'panel',
        layout: 'fit',
        items: [{
            xtype: 'uxchart',
            reference: 'chart2',
            series: [{
                    dataIndex: 'planned',
                    type: 'column',
                    name: tt('Planlanan'),
                    pointPadding: 0.3,
                    // pointPlacement: -0.2,
                },
                {
                    dataIndex: 'planing',
                    type: 'column',
                    name: tt('Onay Surecinde'),
                    pointPadding: 0.4,
                    //  pointPlacement: -0.2
                },
                {
                    dataIndex: 'Revenue',
                    type: 'spline',
                    "lineWidth": 4,
                    "marker": {
                        "enabled": "true",
                        "states": {
                            "hover": {
                                "enabled": true
                            }
                        },
                        "radius": 5
                    },
                    "states": {
                        "hover": {
                            "lineWidthPlus": 0
                        }
                    },
                    name: tt('Revenue'),
                    yAxis: 1,
                    cursor: 'pointer',
                    // listeners: {
                    //     pointclick: function (serie, point, record, event) {
                    //         panel = serie.chart;
                    //         panel.fireEvent('clickChart', record);
                    //     }
                    // }
                },
                {
                    dataIndex: 'AvgPrice',
                    visible: false,
                    type: 'spline',
                    "lineWidth": 0,
                    "marker": {
                        "enabled": "true",
                        "states": {
                            "hover": {
                                "enabled": true
                            }
                        },
                        "radius": 5
                    },
                    "states": {
                        "hover": {
                            "lineWidthPlus": 0
                        }
                    },
                    name: tt('Ortalama Fiyat'),
                    yAxis: 2,
                    cursor: 'pointer',
                    // listeners: {
                    //     pointclick: function (serie, point, record, event) {
                    //         panel = serie.chart;
                    //         panel.fireEvent('clickChart', record);
                    //     }
                    // }
                },
            ],
            xField: 'H2N',
            chartConfig: {
                chart: {
                    type: 'column',
                    alignTicks: true,
                    // marginRight: 20,
                    // marginBottom: 160,
                    //  zoomType    : 'xy',
                    dataLabels: {
                        enabled: true
                    }
                },
                plotOptions: {
                    column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                    },
                    series: {
                        pointWidth: 25,
                    }
                },
                credits: {
                    text: 'SOLVOYO © 2021',
                    href: 'http://www.solvoyo.com',
                    position: {
                        align: 'right',
                        y: -5 // position of credits
                    },
                    style: {
                        fontSize: '11pt' // you can style it!
                    }
                },
                tooltip: {
                    shared: true
                },
                navigation: {
                    buttonOptions: {
                        align: 'left'
                    }
                },
                title: {
                    text: tt('Planlama'),
                    style: {
                        fontSize: "18px",
                        fontWeight: 'bold'
                    }
                },
                subtitle: {
                    text: tt(this.subtitle),
                    style: {
                        fontSize: "14px",
                        fontWeight: 'bold'
                    }
                },
                xAxis: {
                    labels: {
                        // formatter: function () {
                        //     monthValue = this.value
                        //     monthValue = monthValue.slice(-2);
                        //     monthValue = parseInt(monthValue) - 1
                        //     return Ext.Date.shortMonthNames[monthValue];
                        // },
                        style: {
                            fontSize: '13px'
                        },
                        rotation: 315,
                        y: 35
                    },
                    // plotBands: [{ // visualize the weekend
                    //     from: 13.5,
                    //     to: 36,
                    //     color: 'rgba(68, 170, 213, .2)',
                    //     id: me.plotBandId
                    // }]
                },
                yAxis: [{
                    min: 0,
                    title: {
                        text: tt('Urun Sayisi')
                    }
                }, {
                    title: {
                        text: tt('Ciro (mil)')
                    },
                    opposite: true
                }, {
                    title: {
                        text: tt('Price')
                    },
                    opposite: true
                }],
            },
            buildStore: function () {
                var me = this
                me.store = Ext.create('Ext.data.Store', {
                    fields: [{
                            name: 'planned',
                            type: 'number'
                        },
                        {
                            name: 'planing',
                            type: 'number'
                        },
                        {
                            name: 'Revenue',
                            type: 'number'
                        }, {
                            name: 'AvgPrice',
                            type: 'number'
                        },
                    ],
                    proxy: {
                        type: 'ajax',
                        url: Spui.Global.getApiUrl() + '/spui/SpotAnalizdetay2',
                        reader: {
                            type: 'json',
                        }
                    },
                    data: [{
                        x: 1
                    }]
                })
                me.store.load();
                return me.store
            }
        }]
    }],
})
`
