import React, { Component } from 'react'
import { connect, Provider } from 'react-redux'

import createPlugin from '@/BasePlugin'
import {
  createPluginState,
  deleteAllPluginState,
  deletePluginState,
  getPluginStates,
  updatePluginState
} from '@/actions/pluginstate'
import { selectCollection } from '@/crudoptV3'
import store from './store'

import App from './App'

class AppWrapper extends Component {
  constructor() {
    super()

    this.store = store
  }

  UNSAFE_componentWillMount() {
    const { pluginStates, dispatch } = this.props

    if (pluginStates.needFetch) {
      dispatch(pluginStates.fetch)
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props

    dispatch({ type: 'USER_EXIT' })
  }

  render() {
    return (
      <Provider store={this.store}>
        <App {...this.props} />
      </Provider>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  registerAuthorizations: props.registerAuthorizations,
  settings: props.settings,
  id: props.id,
  params: props.params,
  token: props.token,
  isAllowed: props.isAllowed,
  isMaximized: props.isMaximized,
  size: props.size
})

export default createPlugin(
  connect(
    (state, ownProps) => ({
      pluginStates: selectCollection(getPluginStates(ownProps.id), state.model3),
      user: state.oidc.user
    }),
    (dispatch) => ({
      createPluginState: (key, pluginId, data) => dispatch(createPluginState(key, pluginId, data)),
      deleteAllPluginState: (key, pluginId) => dispatch(deleteAllPluginState(key, pluginId)),
      deletePluginState: (key, pluginId, stateId) =>
        dispatch(deletePluginState(key, pluginId, stateId)),
      updatePluginState: (key, stateId, data) => dispatch(updatePluginState(key, stateId, data)),
      dispatch
    })
  )(AppWrapper),
  selectConnectorProps
)
