import { Component } from 'react'

export default class ScrollPosition extends Component {
  constructor() {
    super()
    this.state = {
      scrollTop: 0
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll(event) {
    const {
      target: { scrollTop }
    } = event

    this.setState({ scrollTop })
  }

  render() {
    const { children } = this.props
    const { scrollTop } = this.state

    return children(scrollTop, this.handleScroll)
  }
}
