import { CSSProperties } from 'react'
import cx from 'classnames'
import ColorCellRendererProps from './ColorCellRenderer.types'
import styles from './ColorCellRenderer.module.scss'

const ColorCellRenderer = (params: ColorCellRendererProps) => {
  const {
    api,
    value,
    customAttr = {},
    classNames = '',
    colDef: {
      customInfo: { isAutoColumn }
    },
    node: { group = false },
    context: { theme, groupDisplayType }
  } = params
  const style: CSSProperties = {}
  if (value) {
    style.backgroundColor = value
  }

  const colorStyle =
    group && theme === 'balham' ? styles.balhamColorCellRenderer : styles.colorCellRenderer

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    return <strong>{value?.value}</strong>
  }

  return (
    <div className={cx(colorStyle, 'd-inline-block', classNames)} style={style} {...customAttr} />
  )
}

export default ColorCellRenderer
