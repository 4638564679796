import * as actionTypes from './actionTypes'

export const saveScenarioComparisonSettings = (options) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storeScenarioComparisonSettings(options))
      resolve()
    })
  }
}

const storeScenarioComparisonSettings = (options) => {
  return {
    type: actionTypes.STORE_SCENARIO_COMPARISON_SETTINGS,
    payload: options.payload
  }
}

export const resetScenarioComparisonSettings = () => {
  return {
    type: actionTypes.RESET_SCENARIO_COMPARISON_SETTINGS
  }
}
