/* global Ext */
/* global DOC */
Ext.override(Ext, {
  // validIdRe: /^[a-z_][a-z0-9\-_]*$/i

  // ***************** Solvoyo Added Code *****************
  // Added the characters ı and I to the regex
  validIdRe: /^[a-z_ıI][a-z0-9\-_ıI]*$/i
  // *******************************************************
})

Ext.override(Ext.parse.Tokenizer, {
  privates: {
    // identFirstRe: /[a-z_$]/i,
    // identRe: /[0-9a-z_$]/i

    // ***************** Solvoyo Added Code *****************
    // Added the characters ı and I to the regex
    identFirstRe: /[a-zıI_$]/i,
    identRe: /[0-9a-zıI_$]/i
    // *******************************************************
  }
})

Ext.override(Ext.dom.Element, {
  constructor: function (dom) {
    this.validIdRe = Ext.validIdRe
    this.callParent(arguments)
  }
})

Ext.override(Ext.Component, {
  constructor: function (config) {
    this.validIdRe = Ext.validIdRe
    this.callParent(arguments)
  }
})
