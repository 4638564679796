Ext.define('tuiv2.model.SnapShotModel', {
  extend: 'tuiv2.model.Base',
  idProperty: 'ScenarioId',
  entityName: 'tuiv2/SnapShot',
  fields: [
    { name: 'ScenarioId', type: 'int' },
    { name: 'ScenarioName', type: 'string' },
    { name: 'ScenarioDescription', type: 'string' },
    { name: 'CreatedBy', type: 'string' },
    { name: 'CreatedAt', type: 'date', dateFormat: 'Y-m-d H:i:s.u' }
  ]
})
