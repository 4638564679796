export default {
  title: 'Big Calendar',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'Calendar',
  description: 'This is big calendar',
  iconClass: 'fa-calendar',
  color: 'navy-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Views',
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: true
  }
}
