export default `({
    headerPosition: 'top',
    // tabPosition: 'right',
    split: true,
    collapsible: true,
    collapsed: true,
    forceToLoadStore: true,
    forceToLoadStore: true,
    border: false,
    border: true,
    margin: 0,
    // tabBar: {
    //     items: [{
    //         xtype: 'tbfill'
    //     }, {
    //         xtype: 'button',
    //         style: {
    //             'background': '#2574AB'
    //         },
    //         text: 'Tum bolgeler'
    //     }]
    // },
    bodyStyle: {
        background: '#fff'
    },
    applySelectedChartPanel: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Selected Spot Sales Chart'),
                xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
                salesChartCfg: 'SpotSelected',
                salesChartStoreCfg: 'SpotSelectedStore',
                salesChartStore: {},
                salesChart: {},
                salesGridCfg: 'SelectedSpotSalesGrid',
                salesGrid: false,
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        //b.getStore().load();
        //store.fireEvent('load',store);
        b.on('activate', function (b) {
            b.forcetoLoad();
        })
        return b
    },
    updateSelectedChartPanel: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(0, f)
                } else {
                    d.splice(1, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    applySalesChartPanel: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Spot Sales chart'),
                xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        //b.getStore().load();
        //store.fireEvent('load',store);
        return b
    },
    updateSalesChartPanel: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(0, f)
                } else {
                    d.splice(2, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    applyNomalizedChart: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Normalized Chart'),
                xtype: 'spui-view-common-similarity-tabpanel-nomalizedchart-main',
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        b.getNormalizedChartStore().load()
        //b.getStore().load();
        //store.fireEvent('load',store);
        return b
    },
    updateNomalizedChart: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(1, f)
                } else {
                    d.splice(0, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    listeners: {
        // afterrender: function (c) {
        //     // alert('ok');
        //     c.getTabBar().insert(0, {
        //         xtype: 'component',
        //         flex: 1
        //     });
        // }
        // activate: function (p) {
                //     var store = p.getNormalizedChartStore(),
        //         SpotId = p.getSpotId();
        //     store.getProxy().setExtraParam('SpotId', SpotId);
        //     if (store.forcetoLoad) {
        //         store.load();
        //     }
        // }
    },
    applySimilarSpotId: function (c) {
        this.getSalesChartPanel().setSimilarSpotId(c)
    },
})
`
