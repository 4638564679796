import cx from 'classnames'
import { Button } from 'react-bootstrap'
import { iconPositions, iconCollapsedPositions } from './constants'
import { SplitterProps } from './Splitter.types'
import styles from '../index.module.scss'

const Splitter = ({
  isSizeChangeable,
  isHorizontal,
  title,
  panesize,
  defaultPane,
  initialPaneSize,
  isCollapsed,
  isLocked,
  onCollapse,
  onChangePaneSize
}: SplitterProps) => {
  const btnHiddenOptions = {
    top: {
      increaseBtn: isCollapsed,
      decreaseBtn: panesize <= initialPaneSize && !isCollapsed
    },
    right: {
      increaseBtn: panesize >= initialPaneSize && !isCollapsed,
      decreaseBtn: isCollapsed
    },
    bottom: {
      increaseBtn: panesize >= initialPaneSize && !isCollapsed,
      decreaseBtn: isCollapsed
    },
    left: {
      increaseBtn: isCollapsed,
      decreaseBtn: panesize <= initialPaneSize && !isCollapsed
    }
  }

  const isHiddenIncreaseBtn = btnHiddenOptions[defaultPane].increaseBtn
  const isHiddenDecreaseBtn = btnHiddenOptions[defaultPane].decreaseBtn

  const increaseIcon = isHorizontal ? 'down' : 'right'
  const decreaseIcon = isHorizontal ? 'up' : 'left'

  const defaultBtnClasses = 'text-center fs-sm bg-white cp fa fa-angle-double-'
  const increaseIconClass = defaultBtnClasses + increaseIcon
  const decreaseIconClass = defaultBtnClasses + decreaseIcon

  const position = isCollapsed ? iconCollapsedPositions[defaultPane] : iconPositions[defaultPane]
  const iconClass = `fa-caret-${position}`

  const noTitle = title.trim().length === 0

  return (
    <div
      className={cx(
        'position-relative',
        'd-flex',
        'justify-space-between',
        'align-content-between',
        {
          'no-title': noTitle,
          'justify-content-center': noTitle,
          [styles.lockSplit]: isLocked,
          'flex-column': !isHorizontal,
          'w-100': isHorizontal,
          'h-100': !isHorizontal
        },
        styles.splitContainerSplitter
      )}
      data-testid="splitter"
    >
      {noTitle ? null : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={cx('cp', 'flex-1', {
            'd-none': noTitle,
            'flex-column position-relative text-end': !isHorizontal,
            [styles.verticalSplitter]: !isHorizontal
          })}
          onClick={() => onCollapse()}
        >
          <span
            className={cx('fs-sm', 'fw-bold', 'text-nowrap', styles.splitContainerSplitterLabel)}
            data-testid="splitter-title"
          >
            {title}
          </span>
        </div>
      )}
      <div
        className={cx(
          'd-flex',
          'align-items-center',
          {
            'pe-none': isLocked,
            'opacity-50': isLocked,
            'flex-column': !isHorizontal
          },
          styles.splitContainerIconWrapper
        )}
      >
        {isSizeChangeable && !isHiddenIncreaseBtn ? (
          <Button
            aria-label="increase pane size"
            className={cx(styles.splitContainerFaIcon, increaseIconClass)}
            variant="light"
            onClick={() => onChangePaneSize('+')}
          />
        ) : null}
        {isSizeChangeable && !isHiddenDecreaseBtn ? (
          <Button
            aria-label="decrease pane size"
            className={cx(styles.splitContainerFaIcon, decreaseIconClass)}
            variant="light"
            onClick={() => onChangePaneSize('-')}
          />
        ) : null}
        <Button
          aria-label="collapse pane"
          className={cx('fs-sm', 'bg-white', 'fa', iconClass, styles.splitContainerFaIcon)}
          variant="light"
          onClick={() => onCollapse()}
        />
      </div>
    </div>
  )
}

export default Splitter
