import { ICellEditorParams } from 'ag-grid-community'
import { IRowData } from '../../../types'
import { GetNumberCellEditorParamsReturnType } from '../cell.types'
import { getCalculatedNumberCellEditorParams, getNumberCellEditorParams } from '../index'
import {
  cellParamData as defaultCellParamData,
  columnEditing as defaultColumnEditing
} from './mocks'

function getColumnEditing() {
  return { ...defaultColumnEditing }
}

function getCellData() {
  return defaultCellParamData as IRowData
}

describe('AgGrid:Helpers:Cell:getNumberCellEditorParams', () => {
  let columnEditing = getColumnEditing()
  let cellParamData = getCellData()
  const formatString = ''

  beforeEach(() => {
    columnEditing = getColumnEditing()
    cellParamData = getCellData()
  })

  it('should return empty object if given dataType does not exist in numberTypes', () => {
    const result = getNumberCellEditorParams({
      dataType: 'string',
      editing: columnEditing,
      formatString
    })
    expect(result).toEqual({})
  })

  it('should return an object that has cellEditorParams key and a function as value if given dataType is in numberTypes list', () => {
    const result = getNumberCellEditorParams({
      dataType: 'int',
      editing: columnEditing,
      formatString
    }) as GetNumberCellEditorParamsReturnType
    expect(result).toHaveProperty('cellEditorParams')
    expect(typeof result.cellEditorParams).toBe('function')
  })

  it('should cellEditorParams result must be equal with getCalculatedNumberCellEditorParams result', () => {
    const result = getNumberCellEditorParams({
      dataType: 'int',
      editing: columnEditing,
      formatString
    }) as GetNumberCellEditorParamsReturnType
    expect(result).toHaveProperty('cellEditorParams')
    const editorParams = result?.cellEditorParams?.({ data: cellParamData } as ICellEditorParams)
    const calcEditorParams = getCalculatedNumberCellEditorParams({
      editing: columnEditing,
      formatString,
      cellData: cellParamData
    })
    expect(editorParams).toEqual(calcEditorParams)
  })
})
