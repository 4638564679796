import { ChangeEvent, useRef, useEffect, HTMLAttributes } from 'react'
import cx from 'classnames'
import { FormGroup, Col, Row, Form, FormControl } from 'react-bootstrap'
import _ from 'lodash'
import { ISlvyFormInputProps } from './SlvyFormInput.types'
import { getFormErrorMsg } from '@/helpers'
import { useUniqueId } from '../../hooks'

export default function SlvyFormInput({
  type = 'text',
  placeholder,
  maxLength,
  minLength,
  pattern,
  label = '',
  labelClass = '',
  error,
  value,
  errorModelLabel,
  columns = { label: 2, input: 10 },
  size = '',
  disabled = false,
  autoFocus = false,
  required = false,
  shouldDebounce = false,
  onLabelClick,
  onChange,
  noRowMarginBottom = false
}: ISlvyFormInputProps) {
  const [uniqueId] = useUniqueId()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const controlId = `controlId-${uniqueId}`

  useEffect(() => {
    if (shouldDebounce && inputRef.current) {
      const val = value === null ? '' : value
      inputRef.current.value = val
      inputRef.current.placeholder = val
    }
  }, [shouldDebounce, value])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)

  const handleChanger = shouldDebounce ? _.debounce(handleChange, 500) : handleChange

  const errorMsg = getFormErrorMsg(error, errorModelLabel)

  const styles = { colLabel: columns.label, colInput: columns.input }

  const optionalAttributes: HTMLAttributes<HTMLInputElement> = {}
  if (shouldDebounce) {
    optionalAttributes.ref = inputRef
  } else {
    optionalAttributes.value = value
  }
  if (typeof placeholder !== 'undefined') {
    optionalAttributes.placeholder = placeholder
  }
  if (typeof maxLength !== 'undefined') {
    optionalAttributes.maxLength = maxLength
  }
  if (typeof minLength !== 'undefined') {
    optionalAttributes.minLength = minLength
  }
  if (typeof pattern !== 'undefined') {
    optionalAttributes.pattern = pattern
  }

  return label ? (
    <FormGroup
      as={Row}
      className={cx('form-group', {
        'mb-3': !noRowMarginBottom && size === '',
        'mb-2': !noRowMarginBottom && size === 'sm',
        'is-invalid': errorMsg
      })}
      controlId={controlId}
      data-testid="slvy-form-input"
    >
      <Form.Label
        className={cx('d-flex align-items-center', labelClass)}
        column={!size ? 'md' : size}
        sm={styles.colLabel}
        onClick={onLabelClick}
      >
        {label}
      </Form.Label>
      <Col sm={styles.colInput}>
        <input
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={autoFocus}
          className={cx('form-control', { [`form-control-${size}`]: size, 'is-invalid': errorMsg })}
          disabled={disabled}
          id={controlId}
          required={required}
          type={type}
          onChange={handleChanger}
          {...optionalAttributes}
        />
        <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>
      </Col>
    </FormGroup>
  ) : (
    <FormControl
      bsSize={size}
      className={cx({ 'is-invalid': errorMsg })}
      data-testid="slvy-form-input"
      disabled={disabled}
      required={required}
      type={type}
      onChange={handleChanger}
      {...optionalAttributes}
    />
  )
}
