export default () => {
  return {
    title: 'Timeline',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          basePeriodWidth: {
            type: 'number',
            title: 'Base Period Width',
            default: 30,
            description: 'In pixels'
          },
          selectionType: {
            type: 'string',
            title: 'Selection',
            enum: ['', 'Task Selection', 'Row Selection'],
            required: true,
            default: ''
          },
          isSummaryVisible: {
            type: 'boolean',
            title: 'Summary Visibility',
            default: true,
            required: true
          }
        }
      },
      taskData: {
        type: 'object',
        title: 'Task Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          taskRowIdField: {
            type: 'string',
            title: 'Row Id',
            watch: {
              ds: 'taskData.datafields'
            },
            enumSource: 'ds'
          },
          taskIdField: {
            type: 'string',
            title: 'Task Id Field',
            watch: {
              ds: 'taskData.datafields'
            },
            enumSource: 'ds'
          },

          taskLabelField: {
            type: 'string',
            title: 'Task Label Field',
            watch: {
              ds: 'taskData.datafields'
            },
            enumSource: 'ds'
          },
          taskStartField: {
            type: 'string',
            title: 'Start Field',
            watch: {
              ds: 'taskData.datafields'
            },
            enumSource: 'ds'
          },
          taskEndField: {
            type: 'string',
            title: 'End Field',
            watch: {
              ds: 'taskData.datafields'
            },
            enumSource: 'ds'
          },
          buttons: {
            type: 'array',
            title: 'Buttons',
            items: {
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  title: 'Title',
                  required: true
                },
                icon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector',
                  default: 'fa fa-home'
                },
                iconColor: {
                  type: 'string',
                  format: 'Color',
                  title: 'Icon Color',
                  default: '#000000'
                }
              }
            }
          }
        }
      },
      hierarchyData: {
        type: 'object',
        title: 'Hierarchy Data',
        properties: {
          rowIdField: {
            type: 'string',
            title: 'Row Id'
          },
          parentRowIdField: {
            type: 'string',
            title: 'Parent Row Id'
          },
          treeLabelField: {
            type: 'string',
            title: 'Tree Label'
          },
          hierarchyTimeIconField: {
            type: 'string',
            title: 'Working Time Icon'
          },
          maxWorkingHourField: {
            type: 'string',
            title: 'Max Working Hour Field'
          },
          currentWorkingHourField: {
            type: 'string',
            title: 'Current Working Hour Field'
          },
          workingTypeTextField: {
            type: 'string',
            title: 'Wortking Type Text'
          }
        }
      },
      periodData: {
        type: 'object',
        title: 'Period Data',
        properties: {
          periodIdField: {
            type: 'string',
            title: 'Period Id Field',
            required: true
          },
          periodFields: {
            type: 'array',
            title: 'Period Fields',
            items: {
              type: 'string',
              title: 'Field',
              required: true
            }
          },
          periodsInHeader: {
            type: 'array',
            title: 'Periods In Header',
            items: {
              type: 'string',
              title: 'Header',
              required: true
            }
          }
        }
      },
      periodHierarchyRelationData: {
        type: 'object',
        title: 'Period-Hierarchy Data',
        properties: {
          periodRelationIdField: {
            type: 'string',
            title: 'Period Id Field',
            required: true
          },
          hierarchyRelationIdField: {
            type: 'string',
            title: 'Hierarchy Id Field',
            required: true
          },
          expectedField: {
            type: 'string',
            title: 'Expected Field',
            required: true
          }
        }
      }
    }
  }
}
