import React, { Component } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import _ from 'lodash'
import Container from './container'

const map = {
  TopAxisDraggable: 1,
  LeftAxisDraggable: 2,
  ValuesDraggable: 3,
  DefaultAxisDraggable: 4
}

class App extends Component {
  render() {
    const {
      pivotConfiguration,
      getCurrentState,
      changeCurrentState,
      leftAxisLimit,
      pivotBuilderSettings = {}
    } = this.props
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="pivot-container">
          {!_.isEmpty(pivotConfiguration) &&
            _.map(pivotConfiguration, (item, key) => {
              const Id = map[key]
              return (
                <Container
                  key={Id}
                  axis={key}
                  changeCurrentState={changeCurrentState}
                  context={key}
                  getCurrentState={getCurrentState}
                  id={Id}
                  leftAxisLimit={leftAxisLimit}
                  list={item}
                  pivotBuilderSettings={pivotBuilderSettings}
                />
              )
            })}
        </div>
      </DndProvider>
    )
  }
}
export default App
