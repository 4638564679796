Ext.define('Spui.view.common.productinfo.cmp.ShortInfo', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-form-materialshortinfo',

  // plugins: ['cornerIcon'],
  title: 'Ü Bilgileri',
  cls: 'prodfuctInfo',
  style: {
    // padding: '0px 15px 5px 15px'
  },
  border: true,
  items: [
    {
      xtype: 'displayfield',
      readonly: true,
      fieldLabel: 'Ürün Kodu',
      width: 300,
      bind: {
        value: '{spotRecord.MaterialCode}'
      }
    },
    {
      xtype: 'displayfield',
      readonly: true,
      fieldLabel: 'Ürün Adi',
      width: '100%',
      bind: {
        value: '{spotRecord.MaterialDescription}'
      }
    },
    {
      xtype: 'displayfield',
      fieldLabel: 'Ürün Hiyerarsisi',
      width: '100%',
      bind: {
        value: '{spotRecord.MaterialGroupName}'
      }
    }
  ],
  plugins: [
    {
      ptype: 'cornerIcon',
      // offSet: {
      //     top: 0,
      //     right: 90
      // },
      cfgButton: [
        '->',
        {
          iconCls: 'x-fa fa-cog',
          cls: 'config-btn',

          handler: 'onConfigureShortInfo'
        }
      ]
    }
  ]
})
