import { getLookupValuesFromString } from '../index'
import { lookupValuesString as defaultLookupValuesString } from './mocks'

const getLookupValuesString = () => {
  return defaultLookupValuesString
}

describe('AgGrid:Helpers:Lookup:getLookupValuesFromString', () => {
  let lookupValuesString = getLookupValuesString()
  beforeEach(() => {
    lookupValuesString = getLookupValuesString()
  })
  it('should return an array with 2 object elements if there are 2 groups splitted by tilde', () => {
    const result = getLookupValuesFromString(lookupValuesString)
    expect(result?.length).toEqual(2)
  })
  it(`should return an array with one object element including id and value coming from lookupValuesString's first and second values (splitted by colon) respectively`, () => {
    lookupValuesString = '4:Red'
    const result = getLookupValuesFromString(lookupValuesString)
    expect(result).toEqual([{ id: '4', value: 'Red' }])
  })
  it('should return an array includes an object which has the same id and value if there is no values splitted by colon(:)', () => {
    lookupValuesString = 'White color~'
    const result = getLookupValuesFromString(lookupValuesString)
    expect(result).toEqual([{ id: 'White color', value: 'White color' }])
  })
  it('should return an array includes one object which has the same id and value if there is no tilde and colon(:) punctuation marks in lookupValuesString', () => {
    lookupValuesString = 'Black color'
    const result = getLookupValuesFromString(lookupValuesString)
    expect(result).toEqual([{ id: 'Black color', value: 'Black color' }])
  })
  it('should return undefined if there is no lookupValuesString or its undefined', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getLookupValuesFromString(undefined)
    expect(result).toBeUndefined()
  })
})
