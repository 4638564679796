Ext.define('Spui.model.Task', {
  extend: 'Ext.data.Model'
})

Ext.define('Spui.view.main.toolbar.ChangeUser', {
  extend: 'Ext.panel.Panel',
  defaultListenerScope: true,
  // Uncomment to give this component an xtype
  xtype: 'spui-view-main-toolbar-changeuser',

  bodyPadding: 1,
  width: 900,
  frame: true,
  border: true,
  defaults: {
    anchor: '100%'
  },
  layout: 'fit',
  viewModel: {},
  // The fields

  defaultType: 'textfield',

  initComponent: function () {
    var me = this
    var store = Ext.create('Ext.data.TreeStore', {
      root: {
        expanded: true
      },
      rootVisible: true,

      proxy: {
        type: 'ajax',
        timeout: 120000,
        url: Spui.Global.getApiUrl() + '/spui/getusertree',
        reader: {
          type: 'json',
          rootProperty: 'children',
          keepRawData: true,
          successProperty: 'success',
          messageProperty: 'errors'
        }
      }
    })
    var p = Ext.create('Ext.tree.Panel', {
      listeners: {
        select: 'onSelectUser'
      },

      reserveScrollbar: true,
      //   useArrows: true,
      rootVisible: false,
      // multiSelect: true,
      //  singleExpand: true,
      columnLines: true,

      store: store,

      columns: [
        {
          xtype: 'treecolumn', // this is so we know which column will show the tree
          text: 'OrgTitle',
          dataIndex: 'OrgTitle',

          flex: 2,
          sortable: true
        },
        {
          text: 'UserName',
          dataIndex: 'UserName',

          flex: 1,
          sortable: true
        },
        {
          text: 'OrgCode',
          dataIndex: 'OrgCode',

          flex: 1,
          sortable: true
        },
        {
          text: 'RoleName',
          dataIndex: 'RoleName',

          flex: 1,
          sortable: true
        },
        {
          text: 'DepoKodu',
          dataIndex: 'DepoKodu',

          flex: 1,
          sortable: true
        }

        // ,{
        //     xtype: 'widgetcolumn',
        //     flex: 1,
        //     widget: {
        //         xtype: 'button',
        //         bind: '{record.task}',
        //         scope: this,
        //         handler: 'onActionClick'
        //     },
        // }
      ]
    })
    var tpl = new Ext.create(
      'Ext.XTemplate',
      '<tpl for=".">',
      '<tpl for="Department" if="this.shouldShowHeader(Department)"><div class="group-header">{[this.showHeader(values.Department)]}</div></tpl>',
      '<div class="x-boundlist-item">{UserRoleCode} - {UserRoleDescriptions}</div>',
      '</tpl>',
      {
        shouldShowHeader: function (group) {
          return this.currentGroup !== group
        },
        showHeader: function (group) {
          this.currentGroup = group
          return group
        }
      }
    )
    this.userRoleCombo = Ext.create({
      fieldLabel: 'Chose User Role',
      xtype: 'combo',
      store: this.roleStore,
      reference: 'userrolecombo',
      forceSelection: true,
      editable: false,
      queryMode: 'local',
      width: 400,
      valueField: 'UserRoleCode',
      value: Spui.Global.getUserRoleName(),

      // Template for the dropdown menu.
      // Note the use of the "x-list-plain" and "x-boundlist-item" class,
      // this is required to make the items selectable.
      tpl: Ext.create(
        'Ext.XTemplate',
        '<ul class="x-list-plain"><tpl for=".">',
        '<li role="option" class="x-boundlist-item">{UserRoleCode} - {UserRoleDescriptions}</li>',
        '</tpl></ul>'
      ),
      listConfig: {
        cls: 'grouped-list'
      },
      //	tpl: tpl,
      // template for the content inside text field
      displayTpl: Ext.create(
        'Ext.XTemplate',
        '<tpl for=".">',
        '{UserRoleCode} - {UserRoleDescriptions}',
        '</tpl>'
      ),
      listeners: {
        change: 'onChangeUserRole',
        scope: this
      }
    })
    this.userCodeCombo = Ext.create({
      fieldLabel: 'Chose User Codeeeee',
      xtype: 'combo',
      reference: 'usercodecombo',
      store: this.codeStore,
      forceSelection: true,
      editable: false,
      queryMode: 'local',
      width: 400,
      valueField: 'OrgCode',
      value: Spui.Global.getUserCode(),

      // Template for the dropdown menu.
      // Note the use of the "x-list-plain" and "x-boundlist-item" class,
      // this is required to make the items selectable.
      tpl: Ext.create(
        'Ext.XTemplate',
        '<ul class="x-list-plain"><tpl for=".">',
        '<li role="option" class="x-boundlist-item">{OrgCode} - {OrgTitle}</li>',
        '</tpl></ul>'
      ),
      // listConfig: {
      //     cls: 'grouped-list'
      // },
      // tpl: tpl,
      // template for the content inside text field
      displayTpl: Ext.create('Ext.XTemplate', '<tpl for=".">', '{OrgCode} - {OrgTitle}', '</tpl>')
    })

    me.items = [
      p,

      {
        // Fieldset in Column 1 - collapsible via toggle button
        xtype: 'fieldset',
        hidden: true,
        columnWidth: 0.5,
        title: 'User Change',
        collapsible: false,
        defaults: {
          anchor: '100%'
        },
        border: true,
        layout: 'anchor',
        items: [
          this.userRoleCombo,
          this.userCodeCombo,
          {
            xtype: 'toolbar',
            style: {
              background: 'transparent'
            },
            items: [
              '->',
              {
                xtype: 'button',
                text: 'ApplyChange',
                handler: 'onApplyUserChange',
                scope: this
              }
            ]
          }
        ]
      }
    ]
    me.callParent()
  },
  onChangeCultere: function (f, d) {
    var e = Spui.Global.isDotNet ? this.up('spuimainview') : this.up('spui-view-main-main')
    if (e && e.cascadeLocale) {
      e.cascadeLocale(d)
      // e.down("panel header combo").setValue(d);
      Ext.locale = d
    }
  },

  onChangeUserRole: function (combo, value) {
    var me = this,
      record
    record = me.codeStore.first()
    if (record) {
      this.userCodeCombo.setValue(record.get('OrgCode'))
    }
  },
  onSelectUser: function (tree, record, index, eOpts) {
    var me = this

    me.setLoading(tt('Yükleniyor'))
    var data = record.data

    delete data['children']

    Ext.Ajax.request({
      url: Spui.Global.getApiUrl() + '/spui/SetAdminRole',
      method: 'POST',
      params: {
        data: Ext.encode(data)
      },

      success: function (response, opts) {
        me.setLoading(false)
        me.fireEvent('userChange', me)
        me.ownerCt.close()
      },

      failure: function (response, opts) {
        me.setLoading(false)
        console.log('server-side failure with status code ' + response.status)
      }
    })
    console.log(record)
  },
  onApplyUserChange: function (btn, e) {
    var me = this,
      viewModel = me.getViewModel(),
      role = me.userRoleCombo.getValue(),
      code = me.userCodeCombo.getValue(),
      obj = Spui.Global.getUserObj()
    btn.up('menu').up('menu').hide()

    if (this.userCodeCombo && this.userCodeCombo.getSelectedRecord) {
      var userData = this.userCodeCombo.getSelectedRecord().data
      delete userData['id']
      var userDataObj = Ext.encode(userData)
    } else {
      return
    }

    obj.userRole.code = code
    obj.userRole.name = role
    Spui.Global.setUserObj(obj)

    // item = view.items.items[0];
    // view.remove(item);
    // newView = Ext.create('Spui.main.Cnt.Main');
    // view.add(newView);
    // main = view.items.items
    // me.getView().setLoading(tt('Changing user.. please wait'));
    Spui.util.Mask.mask('Changing user.. please wait')
    Ext.Ajax.request({
      url: Spui.Global.getApiUrl() + '/spui/SetAdminRole',
      method: 'POST',
      params: {
        role: role,
        code: code,
        userDataObj: userDataObj
      },

      success: function (response, opts) {
        Spui.util.Mask.unMask()
        me.fireEvent('userChange', this)
        // me.up('spui-view-main-main').getController().changeView();
        // var obj = Ext.decode(response.responseText),
        //     userCultere = 'en';
        // Spui.Global.setUserObj(obj);
        // userCultere = Spui.Global.getCultere();
        // Ext.locale = userCultere;

        // me.loadUserSettings();
      },

      failure: function (response, opts) {
        console.log('server-side failure with status code ' + response.status)
      }
    })
  }
})
