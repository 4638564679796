export default {
  title: 'Chart',
  subTitle: 'Highcharts',
  version: '1.0',
  namespace: 'Highchart',
  description: 'Various chart types from Highcharts (www.highcharts.com)',
  iconClass: 'fa-line-chart',
  color: 'lazur-bg',
  support: {
    maximizable: true
  },
  group: 'Charts'
}
