import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  displayAssignedProducts: true,
  displayConfirmedProducts: true,
  displayTestProducts: true,
  displayUnassignedProducts: true,
  query: '',
  sortBy: 'aZ_Ascending'
}

const optionSettingsSlice = createSlice({
  name: 'optionSettings',
  initialState,
  reducers: {
    resetOptionsSettings(state, action) {
      return initialState
    },
    setOptionsSettings(state, action) {
      return { ...state, ...action.payload }
    }
  }
})

export const { resetOptionsSettings, setOptionsSettings } = optionSettingsSlice.actions

export const displayAssignedProducts = (state) => {
  state.displayAssignedProducts
}
export const displayConfirmedProducts = (state) => {
  state.displayConfirmedProducts
}
export const displayTestProducts = (state) => {
  state.displayTestProducts
}
export const displayUnassignedProducts = (state) => {
  state.displayUnassignedProducts
}
export const query = (state) => {
  state.query
}
export const sortBy = (state) => {
  state.sortBy
}

export default optionSettingsSlice.reducer
