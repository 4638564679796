import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash'
import styled from 'styled-components'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { Calendar, CalendarControls } from './packages/react-yearly-calendar/src'
import './style.scss'

let categoriesClass = {}

function styledCalendar(category, index) {
  let color = ''
  Object.keys(categoriesClass).forEach((elem, idx) => {
    if (idx === index) {
      color = elem
    }
  })

  return `
    .calendar .color_${index} {
        background-color:${color} !important;
    }`
}

const StyledCalendar = styled.div`
  display: inline;
  ${({ categories }) => categories.map((category, index) => styledCalendar(category, index))}
`

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

class HeatmapCalendar extends Component {
  constructor(props) {
    super(props)
    const today = moment()
    this.state = {
      selectedDay: null,
      currentDay: null,
      year: today.year()
    }

    this.datePicked = this.datePicked.bind(this)
    this.goToToday = this.goToToday.bind(this)
    this.onPrevYear = this.onPrevYear.bind(this)
    this.onNextYear = this.onNextYear.bind(this)
  }

  datePicked(date) {
    this.setState({
      selectedDay: date,
      currentDay: date.format('YYYY-MM-DD')
    })

    this.handleDateSelect(date)
  }

  onPrevYear() {
    this.setState((prevState) => ({
      year: prevState.year - 1
    }))
  }

  onNextYear() {
    this.setState((prevState) => ({
      year: prevState.year + 1
    }))
  }

  goToToday() {
    const today = moment()

    this.setState({
      selectedDay: today,
      year: today.year()
    })
  }

  componentDidMount() {
    this.handleDateSelect = this.props.registerEvent({
      key: 'handleDateSelect',
      fn: this.handleDateSelect.bind(this),
      returnTypes: { date: PluginTypes.string }
    })

    this.handleDoubleClick = this.props.registerEvent({
      key: 'handleDoubleClick',
      fn: this.handleDoubleClick.bind(this),
      returnTypes: { date: PluginTypes.string }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      pluginData: result = [],
      settings: { config: { data: { year = 'EventYear' } = {} } = {} } = {}
    } = this.props
    const { pluginData: nextResult = [] } = nextProps

    if (_.isEmpty(result) && !_.isEmpty(nextResult)) {
      const { [year]: dataYear = '' } = _.first(nextResult)
      if (!dataYear) return
      this.setState({ year: parseInt(dataYear, 10) })
    }
  }

  handleDateSelect(date) {
    return {
      date: date.format('YYYY-MM-DD')
    }
  }

  handleDoubleClick(date) {
    return {
      date: date.format('YYYY-MM-DD')
    }
  }

  getRange = (data, type = 'days') => {
    const {
      settings: {
        config: { data: { date: startDateColumn, endDate: endDateColumn } = {} } = {}
      } = {}
    } = this.props
    const range = []

    if (startDateColumn && endDateColumn) {
      const { [startDateColumn]: startDate, [endDateColumn]: endDate } = data

      const fromDate = moment(startDate)
      const toDate = moment(endDate)
      const diff = toDate.diff(fromDate, type)

      if (diff > 0) {
        for (let i = 0; i < diff + 1; i++) {
          const newDate = moment(startDate).add(i, type).format('YYYY-MM-DD')
          data = {
            ...data,
            [startDateColumn]: newDate
          }

          range.push(data)
        }
      } else {
        range.push(data)
      }
    } else {
      range.push(data)
    }

    return range
  }

  getPluginData = () =>
    _.flatMapDeep(_.map(this.props.pluginData || [], (item) => this.getRange(item)))

  handleColor(pluginData) {
    const {
      settings: { config: { data: { date: dateColumn = null, colorField } = {} } = {} } = {}
    } = this.props

    const groupedData = _.groupBy(pluginData, `${colorField}`)
    categoriesClass = groupedData

    const keys = Object.keys(groupedData)
    const values = Object.values(groupedData)

    const mappedValues = values.map((elem) => {
      return _.reduce(
        elem,
        (result, item) => {
          result.push(item[dateColumn])
          return result
        },
        []
      )
    })

    const newClass = {}
    keys.forEach((element, index) => {
      newClass[`color_${index}`] = mappedValues[index]
    })
    return newClass
  }

  mapData = (pluginData, data, tooltipFormat) => {
    // @todo check map
    // @check name

    if (pluginData) {
      return pluginData.map((item) => {
        return {
          date: item[data.date],
          value: item[data.value],
          colorField: item[data.colorField],
          description: item[data.description],
          tooltipHtml: tooltipFormat.replace(/{(.*?)}/g, (match, offset) => {
            return item[offset] ? item[offset] : ''
          })
        }
      })
    }

    return []
  }

  render() {
    const {
      settings: {
        config: {
          data = {},
          data: { date: dateColumn = null, value: valueColumn = null } = {},
          settings: {
            tooltipFormat = '',
            tooltipStyle = '',
            disableWeekends = false,
            weekendsColor = 'gray',
            firstDayOfWeek = 'Sunday'
          } = {}
        } = {}
      } = {}
    } = this.props

    const pluginData = this.getPluginData()
    // Get days with values
    const onDays =
      pluginData && pluginData.length > 0 && dateColumn && valueColumn
        ? _.reduce(
            pluginData,
            (res, row) => {
              if (row[valueColumn] > 0) {
                res.push(moment(row[dateColumn]).format('YYYY-MM-DD'))
                return res
              }
            },
            []
          )
        : []

    const { year, selectedDay, currentDay } = this.state

    const customClasses = {
      onDays,
      ...this.handleColor(pluginData),
      ...(currentDay && { currentDay: [currentDay] })
    }

    const events = this.mapData(pluginData, data, tooltipFormat)

    const calendarOptions = {
      year,
      ...(selectedDay && { selectedDay }),
      customClasses,
      events,
      onPickDate: (date) => this.datePicked(date),
      handleDateSelect: this.handleDoubleClick,
      disableWeekends,
      weekendsColor,
      firstDayOfWeek: _.indexOf(DAYS_OF_WEEK, firstDayOfWeek)
    }

    const categories = Object.keys(this.handleColor())
    return (
      <div className="year-calendar">
        <CalendarControls
          goToToday={() => this.goToToday()}
          year={year}
          onNextYear={() => this.onNextYear()}
          onPrevYear={() => this.onPrevYear()}
        />
        <StyledCalendar categories={categories}>
          <Calendar {...calendarOptions} />
        </StyledCalendar>
        <style>{tooltipStyle}</style>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  settings: props.settings
})

export default createPlugin(HeatmapCalendar, selectConnectorProps)
