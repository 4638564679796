Ext.define('tuiv2.cmp.DiffWindow', {
  extend: 'Ext.window.Window',
  xtype: 'tuiv2-diffwindow',
  title: 'Diff Screen',
  ui: 'window-inverse',
  referenceHolder: true,
  layout: 'fit',
  maximized: true,
  resize: false,
  closeAction: 'destroy',
  config: {
    diffData: null
  },
  initComponent: function () {
    var me = this,
      diffData = me.diffData

    var container = {
      xtype: 'tabpanel',
      activeTab: 0,
      items: [],
      listeners: {
        afterlayout: function () {
          var tabPanel = this,
            activeTab = tabPanel.activeTab,
            references = activeTab.getReferences(),
            beforeMainMap = references.beforeMap,
            afterMainMap = references.afterMap,
            beforeMap = beforeMainMap.getMap(),
            afterMap = afterMainMap.getMap()

          var fixZoom = function (map, mapContainer, level, initialValue) {
            if (initialValue == null) {
              try {
                initialValue = map.getBounds()
              } catch (error) {
                return
              }
            } else if (
              JSON.stringify(initialValue) != JSON.stringify(map.getBounds()) ||
              (JSON.stringify(initialValue) == JSON.stringify(map.getBounds()) && level > 0)
            ) {
              if (beforeMap.getZoom() > -1) {
                beforeMap.sync(afterMap)
                afterMap.sync(beforeMap)
              } else if (afterMap.getZoom() - 1) {
                afterMap.sync(beforeMap)
                beforeMap.sync(afterMap)
              }

              return
            }

            mapContainer.setZoom()

            level += 1

            setTimeout(() => {
              fixZoom(map, mapContainer, level, initialValue)
            }, 500)
          }

          setTimeout(() => {
            fixZoom(beforeMap, beforeMainMap, 0)
          }, 500)

          setTimeout(() => {
            fixZoom(afterMap, afterMainMap, 0)
          }, 500)
        }
      }
    }

    for (let index = 0; index < diffData.length; index++) {
      const data = diffData[index]

      container.items.push({
        title: data.ID,
        layout: 'border',
        xtype: 'panel',
        bodyPadding: 4,
        referenceHolder: true,
        items: [
          {
            xtype: 'container',
            layout: {
              type: 'hbox',
              pack: 'start',
              align: 'stretch'
            },
            bodyPadding: 4,
            region: 'center',
            flex: 1,
            border: true,
            items: [
              {
                xtype: 'mainmap',
                reference: 'beforeMap',
                mapData: [data.OldData],
                flex: 1
              },
              {
                xtype: 'mainmap',
                reference: 'afterMap',
                mapData: [data.NewData],
                flex: 1
              }
            ]
          },
          {
            region: 'south',
            xtype: 'tuiv2-difftemplate',
            height: 345,
            data: data.Summaries
          }
        ]
      })
    }

    me.items = [container]

    me.callParent(arguments)
  }
})
