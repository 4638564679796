import { useEffect } from 'react'
import _ from 'lodash'
import { useAppDispatch } from '@/hooks/store'
import { GetPlugin } from '@/helpers/Plugins'
import { add, remove } from '@/store/slices/pluginlist'
import {
  Container,
  SplitContainer,
  TabContainer,
  FlexboxContainer,
  PopupContainer,
  ReFlexContainer,
  WizardContainer,
  OverlayContainer
} from '..'
import { createComponent } from '@/helpers'
import { CommonProps, ContainerProps, PluginProps, ViewTypes } from './View.types'

const onReady = () => ({})
const onRemovePluginDefault = (_id?: string) => ({})

const View = (props: ViewTypes) => {
  const {
    id,
    type,
    draggableHandle = '',
    plugins,
    containers,
    params,
    first,
    canDelete,
    canMove,
    onMounted,
    onRemovePlugin = onRemovePluginDefault,
    showDeleteIcon = false
  } = props

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(add({ id, type }))
    return () => {
      dispatch(remove({ id, type }))
    }
  }, [])

  const commonProps: CommonProps = {
    params,
    onMounted,
    onRemoveClick: onRemovePlugin,
    draggableHandleClassName: draggableHandle
  }

  if (type !== 'plugin' && type !== 'container') {
    return null
  }

  if (type === 'plugin') {
    const plugin = _.find(plugins, (item) => item.id === id)
    if (!plugin) return null

    const pluginProps: PluginProps = {
      ...commonProps,
      showDeleteIcon,
      id: plugin.id,
      type: plugin.type,
      isPreviewMode: false,
      previewMenuProps: null,
      settings: {
        config: plugin.config,
        query: plugin.query
      },
      onReady
    }
    return createComponent(GetPlugin(plugin.type), pluginProps)
  }

  if (type === 'container') {
    const container = _.find(containers, (item) => item.id === id)

    if (!container) return null

    const { type: ContainerType = 'container' } = container
    const containerProps: ContainerProps = {
      ...commonProps,
      id,
      plugins,
      containers
    }

    if (ContainerType === 'container') {
      return <Container {...containerProps} canDelete={canDelete} canMove={canMove} first={first} />
    }
    if (ContainerType === 'splitcontainer') {
      return <SplitContainer {...containerProps} />
    }
    if (ContainerType === 'tabcontainer') {
      return <TabContainer {...containerProps} />
    }
    if (ContainerType === 'popupcontainer') {
      return <PopupContainer {...containerProps} />
    }
    if (ContainerType === 'flexcontainer') {
      return <FlexboxContainer {...containerProps} />
    }
    if (ContainerType === 'reflexcontainer') {
      return <ReFlexContainer {...containerProps} />
    }
    if (ContainerType === 'wizardcontainer') {
      return <WizardContainer {...containerProps} />
    }
    if (ContainerType === 'overlaycontainer') {
      return <OverlayContainer {...containerProps} />
    }
  }

  return null
}

export default View
