import { getCellEditorSelector } from '../index'
import { customConfigs as defaultCustomConfigs } from '../../__tests__/mocks'
import { BooleanIconCellEditor } from '../../../components/CellEditors'

function getBooleanIcons() {
  return defaultCustomConfigs.boolean
}

function getLookupDataField() {
  return defaultCustomConfigs.editing.lookupDataField
}

function getSelectProps() {
  return { menuIsOpen: true }
}

describe('AgGrid:Helpers:Cell:getCellEditorSelector', () => {
  let booleanIcons = getBooleanIcons()
  let lookupDataField = getLookupDataField()
  let selectProps = getSelectProps()

  beforeEach(() => {
    booleanIcons = getBooleanIcons()
    lookupDataField = getLookupDataField()
    selectProps = getSelectProps()
  })

  it('should return boolean icon cell editor when trueIcon and falseIcon is selected', () => {
    booleanIcons.trueIcon = 'fa slvy-ui-icon-pin-solid'
    booleanIcons.falseIcon = 'fa slvy-ui-icon-pin'
    const result = getCellEditorSelector({
      dataType: 'bool',
      isLookupQuery: false,
      lookupQueryList: undefined,
      booleanIcons,
      lookupDataField,
      selectProps
    })
    expect(result).toHaveProperty('cellEditorSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellEditorSelector()).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellEditorSelector().component).toEqual(BooleanIconCellEditor)
  })

  it('should return LookupFieldCellEditor when trueIcon or falseIcon is not selected and there is a substituteField or a lookupDataField', () => {
    booleanIcons.trueIcon = ''
    booleanIcons.falseIcon = ''
    lookupDataField = 'col9'
    const result = getCellEditorSelector({
      dataType: 'string',
      isLookupQuery: true,
      lookupQueryList: undefined,
      booleanIcons,
      lookupDataField,
      selectProps
    })
    expect(result).toHaveProperty('cellEditorSelector')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellEditorSelector()).toHaveProperty('component')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellEditorSelector()).toHaveProperty('popup', true)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.cellEditorSelector()).toHaveProperty('params')
  })

  // it(
  //   'should return agDateCelEditor when ' +
  //     'trueIcon or falseIcon is not selected and ' +
  //     'there is no substituteField or lookupDataField and ' +
  //     'dataType is datetime',
  //   () => {
  //     booleanIcons.trueIcon = ''
  //     booleanIcons.falseIcon = ''
  //     const result = getCellEditorSelector({
  //       isLookupQuery: false,
  //       lookupQueryList: undefined,
  //       booleanIcons,
  //       lookupDataField: '',
  //       selectProps,
  //       dataType: 'datetime'
  //     })
  //     expect(result).toHaveProperty('cellEditorSelector')
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     expect(result.cellEditorSelector()).toHaveProperty('component')
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     expect(result.cellEditorSelector().component).toEqual('agDateCellEditor')
  //   }
  // )

  it(
    'should return empty object when ' +
      'booleanIcons.trueIcon or booleanIcons.falseIcon is not selected and ' +
      'there is no substituteField or lookupDataField and' +
      'dataType is not datetime',
    () => {
      booleanIcons.trueIcon = ''
      booleanIcons.falseIcon = ''
      lookupDataField = ''

      // for not boolean
      let result = getCellEditorSelector({
        dataType: 'int',
        isLookupQuery: false,
        lookupQueryList: undefined,
        booleanIcons,
        lookupDataField,
        selectProps
      })
      expect(result).toEqual({})

      // for not look up
      result = getCellEditorSelector({
        dataType: 'int',
        isLookupQuery: false,
        lookupQueryList: undefined,
        booleanIcons,
        lookupDataField,
        selectProps
      })
      expect(result).toEqual({})

      // for not datetime
      result = getCellEditorSelector({
        dataType: 'int',
        isLookupQuery: false,
        lookupQueryList: undefined,
        booleanIcons,
        lookupDataField,
        selectProps
      })
      expect(result).toEqual({})
    }
  )
})
