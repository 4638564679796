import { Component } from 'react'

import { Modal, Button } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

/* Components */
import { SlvyCodeMirror } from '../../..'

/* Styles */
import './CodeEditor.scss'

export default class CodeEditor extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)

    this.dispatchChange = this.dispatchChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)

    this.styleId = uuidv4()

    this.state = {
      query: props.value
    }

    this.que = null
  }

  handleChange(newCode) {
    const { schema: { options: { base64 = false } = {} } = {} } = this.props

    const value = base64 ? btoa(newCode) : newCode

    this.setState({ query: value })

    clearTimeout(this.que)
    this.que = setTimeout(this.dispatchChange, 500)
  }

  dispatchChange() {
    this.props.onChange(this.state.query)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ query: nextProps.value })
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  render() {
    const {
      schema: {
        options: {
          mode = 'text/javascript',
          lineNumbers = true,
          readOnly = false,
          base64 = false,
          globalVars = true
        } = {}
      } = {}
    } = this.props

    // available modes: https://codemirror.net/mode/

    const { query = '' } = this.state

    const value = base64 ? query !== null && atob(query) : query

    const tooltip = value.length > 255 ? value.substring(0, 255) : value

    return (
      <div className="editCodeButton">
        <Button title={tooltip} variant="outline-dark" size="sm" onClick={this.handleShow}>
          Edit Code
        </Button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SlvyCodeMirror
              className="queryEditor"
              isThemeSelectable={false}
              options={{
                mode: {
                  name: mode,
                  globalVars
                },
                lineNumbers,
                readOnly
              }}
              value={value || ''}
              onChange={this.handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
