export const emptyObject: any = Object.freeze({})
export const emptyArray: any = Object.freeze([])

export const VARIABLE_TYPES = [
  { value: 'bool', label: 'bool' },
  { value: 'datetime', label: 'datetime' },
  { value: 'float', label: 'float' },
  { value: 'int', label: 'int' },
  { value: 'string', label: 'string' },
  { value: 'array{bool}', label: 'array{bool}' },
  { value: 'array{datetime}', label: 'array{datetime}' },
  { value: 'array{float}', label: 'array{float}' },
  { value: 'array{int}', label: 'array{int}' },
  { value: 'array{string}', label: 'array{string}' }
]
