Ext.define('MDM.src.view.massimport.Main', {
  extend: 'Ext.panel.Panel',
  xtype: 'massimportmain',
  viewModel: 'massimport-mainviewmodel',
  controller: 'massimport-maincontroller',
  header: false,
  border: true,
  bodyPadding: '2',
  layout: 'fit',
  dockedItems: [
    {
      xtype: 'toolbar',
      docked: 'top',
      items: [
        {
          xtype: 'tbtext',
          reference: 'title',
          bind: {
            html: 'BTL import ' + '{importTypeTitle}' + ''
          }
        },
        '->',
        {
          xtype: 'button',
          margin: '0 20,0,0',
          iconCls: 'fa fa-bolt',
          width: 200,
          text: 'Move To DPI',
          // ui: 'btn-inverse',

          handler: 'onClickApply',
          bind: {
            disabled: '{!grid.selection}'
          }
        },
        '-',
        {
          xtype: 'button',
          margin: '0 20,0,0',
          iconCls: 'fa fa-upload',
          text: 'Import',
          bind: {
            disabled: '{isReadOnly}'
          },
          handler: 'onAfterRender'
        }
      ]
    }
  ],
  listeners: {
    afterrender: 'onAfterRender'
  },
  items: [
    {
      xtype: 'massimport-grid',
      reference: 'grid'
    }
  ]
})
