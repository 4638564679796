import { useCallback } from 'react'
import { CustomInfo, SlvyMasterColDef } from '../../types'

import { filterSelectorConfig } from '../../constants'
import { getIsSubstitute } from '../../helpers'
import { GetSubstituteColumnDefsProps } from './useGetSubstituteColumnDefs.types'

function useGetSubstituteColumnDefs(props: GetSubstituteColumnDefsProps) {
  const { fieldConfigs } = props

  const getSubstituteColumnDefs = useCallback(
    (columns: SlvyMasterColDef[]): SlvyMasterColDef[] => {
      if (!columns) {
        return []
      }

      const substituteColumns: SlvyMasterColDef[] = columns.filter((col) => {
        return getIsSubstitute(col.customInfo as CustomInfo)
      })

      const newSubstituteColumns: SlvyMasterColDef[] = []

      substituteColumns.forEach((col) => {
        const { dataType = '' } =
          fieldConfigs.find(({ fieldName }) => fieldName === col.field) || {}

        newSubstituteColumns.push({
          ...col,
          ...(dataType ? filterSelectorConfig[dataType as keyof typeof filterSelectorConfig] : {}),
          flex: 0,
          width: 0,
          minWidth: 0,
          maxWidth: 0,
          hide: true,
          suppressColumnsToolPanel: true,
          field: `_SLVY_${col.field}_SUBSTITUTE_FIELD_`,
          colId: `_SLVY_${col.field}_SUBSTITUTE_FIELD_`,
          cellDataType: true,
          customInfo: {
            ...col.customInfo,
            isExportable: false
          }
        })
      })

      return newSubstituteColumns
    },
    [fieldConfigs]
  )

  return { getSubstituteColumnDefs }
}

export default useGetSubstituteColumnDefs
