import { Modal } from 'react-bootstrap'
import Wizard from '../Wizard'

export default function WizardModal({ show, views, ...props }) {
  return (
    <Modal show={show} {...props}>
      <Wizard show={show} views={views} />
    </Modal>
  )
}
