Ext.define('MDM.Btl.cmp.MenuPanel', {
  extend: 'Ext.panel.Panel',
  xtype: 'menupanel',
  bodyPadding: 20,
  layout: {
    type: 'vbox',
    align: 'left', // or 'right'
    // pack: 'center', // controls vertical align
    defaultMargins: 5
  },
  defaultType: 'fieldcontainer',
  items: [
    {
      fieldLabel: 'Material Type',
      items: [
        {
          xtype: 'segmentedbutton',
          items: [
            {
              text: 'FG',
              width: 70,
              ui: 'btn-default',
              itemId: 'FG',
              handler: 'onChangeMaterialType'
            },
            {
              text: 'FWIP',
              itemId: 'FWIP',
              width: 70,
              ui: 'btn-default',
              margin: '0 10 0 10  ',
              handler: 'onChangeMaterialType'
            },
            {
              text: 'All',
              width: 70,
              itemId: 'All',
              ui: 'btn-default',
              handler: 'onChangeMaterialType',
              pressed: true
            }
          ]
        }
      ]
    },
    {
      fieldLabel: 'Columns',
      items: [
        {
          xtype: 'segmentedbutton',
          items: [
            {
              text: 'Forecast',
              width: 70,
              margin: '0 2',
              itemId: 'Editable',
              ui: 'btn-default',
              handler: 'onChangeEditableView'
            },
            {
              text: 'All',
              margin: '0 2',
              width: 70,
              pressed: true,
              itemId: 'All',
              ui: 'btn-default',
              handler: 'onChangeEditableView'
            }
          ]
        }
      ]
    },
    {
      fieldLabel: 'Rows',
      items: [
        {
          xtype: 'segmentedbutton',
          items: [
            {
              text: 'Active',
              itemId: 'Active',
              ui: 'btn-default',
              pressed: true,
              handler: 'onChangeView',
              width: 70
            },
            {
              text: 'All',
              itemId: 'AllRows',

              ui: 'btn-default',
              handler: 'onChangeView',
              width: 70
            }
          ]
        }
      ]
    },
    {
      fieldLabel: 'Sync Scroll',
      items: [
        {
          xtype: 'segmentedbutton',
          items: [
            {
              text: 'Yes',
              itemId: 'True',
              pressed: true,
              ui: 'btn-default',
              handler: 'onChangeSyncSc',
              width: 70
            },
            {
              text: 'No',
              itemId: 'False',
              ui: 'btn-default',
              handler: 'onChangeSyncSc',
              width: 70
            }
          ]
        }
      ]
    }
    // {
    //     xtype: 'materialtype'
    // },
    // {
    //     margin: '0 5 0 5',
    //     xtype: 'cycle',
    //     width: 200,
    //     showText: true,
    //     showMenu: false,
    //     //ui: 'btn-inverse',
    //     arrowVisible: false,
    //     bind: {
    //         activeItem: '{hideReadOnlyCol}'
    //     },
    //     changeHandler: 'onChangeEditableView',
    //     menu: {
    //         items: [{
    //             text: 'Editable Column',
    //             itemId: 'Editable'
    //         }, {
    //             text: 'All Columns',
    //             itemId: 'All'
    //         }]
    //     }
    // },
    // {
    //     margin: '5 0 0 5',
    //     xtype: 'cycle',
    //     width: 150,
    //     showText: true,
    //     showMenu: false,
    //     //ui: 'btn-inverse',
    //     arrowVisible: false,
    //     bind: {
    //         activeItem: '{btncy}'
    //     },
    //     changeHandler: 'onChangeView',
    //     menu: {
    //         items: [{
    //             text: 'Active Rows',
    //             itemId: 'Active'
    //         }, {
    //             text: 'All Rows',
    //             itemId: 'All'
    //         }]
    //     }
    // },
  ]
})
