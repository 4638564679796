import { slvyToast } from '../../../components/SlvyToast'

window.JSONEditor.defaults.editors.SolvoyoArrayEditor =
  window.JSONEditor.defaults.editors.array.extend({
    build() {
      this._super()
      const subProperty = document.createElement('div')
      subProperty.classList.add('subProperty')
      while (this.container.firstChild) {
        const child = this.container.firstChild
        this.container.removeChild(this.container.firstChild)
        subProperty.appendChild(child)
      }
      this.container.appendChild(subProperty)
    },

    postBuild() {
      this._super()
      this.container.childNodes[0].childNodes[2].childNodes[1].classList.add(
        'generate-button-container'
      )
    },

    addControls() {
      this._super()
      const self = this

      const root = this.getSchemaRoot(self)

      if (self.options.syncButton) {
        const syncButton = this.getButton('Generate', 'edit', 'Auto Generate Items')

        syncButton.addEventListener('click', function (e) {
          e.preventDefault()
          e.stopPropagation()

          if (
            window.JSONEditor.defaults.synchronizeDataDefinition &&
            window.JSONEditor.defaults.synchronizeDataDefinition[root.schema.title]
          ) {
            window.JSONEditor.defaults.synchronizeDataDefinition[root.schema.title](self.path)
            slvyToast.success({
              message: 'Columns Are Successfully Generated.',
              title: `Operation Detail`
            })
          }
        })

        self.controls.appendChild(syncButton)
      }
    },

    getSchemaRoot(node) {
      if (node.parent) {
        return this.getSchemaRoot(node.parent)
      }
      return node
    },

    addRow(value, initial) {
      const self = this
      const i = this.rows.length

      self.rows[i] = this.getElementEditor(i)
      self.row_cache[i] = self.rows[i]

      if (self.tabs_holder) {
        self.rows[i].tab_text = document.createElement('span')
        self.rows[i].tab_text.textContent = self.rows[i].getHeaderText()
        self.rows[i].tab = self.theme.getTab(self.rows[i].tab_text)
        self.rows[i].tab.addEventListener('click', function (e) {
          self.active_tab = self.rows[i].tab
          self.refreshTabs()
          e.preventDefault()
          e.stopPropagation()
        })

        self.theme.addTab(self.tabs_holder, self.rows[i].tab)
      }

      const controls_holder = self.rows[i].title_controls || self.rows[i].array_controls

      // Buttons to delete row, move row up, and move row down
      if (!self.hide_delete_buttons) {
        self.rows[i].delete_button = this.getButton(
          self.getItemTitle(),
          'delete',
          this.translate('button_delete_row_title', [self.getItemTitle()])
        )
        self.rows[i].delete_button.className += ' delete'
        self.rows[i].delete_button.setAttribute('data-i', i)
        self.rows[i].delete_button.addEventListener('click', function (e) {
          e.preventDefault()
          e.stopPropagation()
          const i = this.getAttribute('data-i') * 1

          const value = self.getValue()

          const newval = []
          let new_active_tab = null
          value.forEach(function (row, j) {
            if (j === i) {
              // If the one we're deleting is the active tab
              if (self.rows[j].tab === self.active_tab) {
                // Make the next tab active if there is one
                // Note: the next tab is going to be the current tab after deletion
                if (self.rows[j + 1]) new_active_tab = self.rows[j].tab
                else if (j) {
                  // Otherwise, make the previous tab active if there is one
                  new_active_tab = self.rows[j - 1].tab
                }
              }

              return // If this is the one we're deleting
            }
            newval.push(row)
          })
          self.setValue(newval)
          if (new_active_tab) {
            self.active_tab = new_active_tab
            self.refreshTabs()
          }

          self.onChange(true)
        })

        if (controls_holder) {
          controls_holder.appendChild(self.rows[i].delete_button)
        }
      }
      // Button to copy an array element and add it as last element
      if (!self.show_copy_button && !this.options.disable_array_copy) {
        self.rows[i].copy_button = this.getButton('Copy', 'copy', `Copy ${self.getItemTitle()}`)
        self.rows[i].copy_button.className += ' copy'
        self.rows[i].copy_button.setAttribute('data-i', i)
        self.rows[i].copy_button.addEventListener('click', function (e) {
          const value = self.getValue()
          e.preventDefault()
          e.stopPropagation()
          const i = this.getAttribute('data-i') * 1
          value.forEach(function (row, j) {
            if (j === i) {
              value.push(row)
            }
          })
          slvyToast.success({ message: 'Item Successfully Copied.', title: `Operation Detail` })
          self.setValue(value)
          self.refreshValue(true)
          self.onChange(true)
        })

        controls_holder.appendChild(self.rows[i].copy_button)
      }

      if (i && !self.hide_move_buttons) {
        self.rows[i].moveup_button = this.getButton(
          '',
          'moveup',
          this.translate('button_move_up_title')
        )
        self.rows[i].moveup_button.className += ' moveup'
        self.rows[i].moveup_button.setAttribute('data-i', i)
        self.rows[i].moveup_button.addEventListener('click', function (e) {
          e.preventDefault()
          e.stopPropagation()
          const i = this.getAttribute('data-i') * 1

          if (i <= 0) return
          const rows = self.getValue()
          const tmp = rows[i - 1]
          rows[i - 1] = rows[i]
          rows[i] = tmp

          self.setValue(rows)
          self.active_tab = self.rows[i - 1].tab
          self.refreshTabs()

          self.onChange(true)
        })

        if (controls_holder) {
          controls_holder.appendChild(self.rows[i].moveup_button)
        }
      }

      if (!self.hide_move_buttons) {
        self.rows[i].movedown_button = this.getButton(
          '',
          'movedown',
          this.translate('button_move_down_title')
        )
        self.rows[i].movedown_button.className += ' movedown'
        self.rows[i].movedown_button.setAttribute('data-i', i)
        self.rows[i].movedown_button.addEventListener('click', function (e) {
          e.preventDefault()
          e.stopPropagation()
          const i = this.getAttribute('data-i') * 1

          const rows = self.getValue()
          if (i >= rows.length - 1) return
          const tmp = rows[i + 1]
          rows[i + 1] = rows[i]
          rows[i] = tmp

          self.setValue(rows)
          self.active_tab = self.rows[i + 1].tab
          self.refreshTabs()
          self.onChange(true)
        })

        if (controls_holder) {
          controls_holder.appendChild(self.rows[i].movedown_button)
        }
      }

      if (value) self.rows[i].setValue(value, initial)
      self.refreshTabs()
    }
  })

export default {
  name: 'SolvoyoArrayEditor',
  format: 'SolvoyoArrayEditor'
}

window.JSONEditor.defaults.iconlibs.fontawesome4 = window.JSONEditor.AbstractIconLib.extend({
  mapping: {
    collapse: 'caret-square-o-down',
    expand: 'caret-square-o-right',
    delete: 'times',
    edit: 'pencil',
    add: 'plus',
    cancel: 'ban',
    save: 'save',
    moveup: 'arrow-up',
    movedown: 'arrow-down',
    copy: 'files-o'
  },
  icon_prefix: 'fa fa-'
})
