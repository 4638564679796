import _ from 'lodash'

export const toDefaultSelectValue = (value) => {
  return _.isObject(value) || _.isNull(value) ? value : { value }
}

export const mapFromCrudList = (
  list,
  callback = (item) => ({
    text: item.name,
    id: item.id
  })
) => _.map(list, callback)

export function toReactSelectV2(options, selectedOptions) {
  return _.transform(options, (newSelectedOptions, option) => {
    _.some(selectedOptions, (selectedOption) => {
      if (selectedOption === option.id || selectedOption.value === option.id) {
        return newSelectedOptions.push({
          label: option.name,
          value: option.id
        })
      }
    })
  })
}

export const getPluginVariables = (plugins, assignees) => {
  const variables = {}
  _.each(plugins, ({ id: pluginKey, registers: { events } = {} }) => {
    _.each(events, ({ params }, eventKey) => {
      _.each(params, ({ type }, param) => {
        const { events: { [pluginKey]: { [eventKey]: { [param]: val } = {} } = {} } = {} } =
          assignees
        if (val && !_.isNil(val)) {
          variables[val] = { count: 0, type, group: 'page' }
        }
      })
    })
  })
  return variables
}

export const getMenuVariables = (menuTree = [], menuId) => {
  return _.reduce(
    menuTree,
    (result, { item: { id }, item, children }) => {
      if (result) return result
      if (_.isEqual(id, menuId)) {
        return _.transform(
          item.transferObject,
          (r, t, k) => {
            r[k] = { type: t, group: 'menu', count: 0 }
          },
          {}
        )
      }
      return this.getMenuVariables(children, menuId)
    },
    false
  )
}

export const getCatalogVariables = (transferObject) => {
  return _.transform(
    transferObject,
    (result, { type }, key) => {
      result[key] = { type, group: 'global', count: 0 }
    },
    {}
  )
}

export const getCustomVariables = (works, onlyValue) => {
  const pageReady = works[0].transitions[0]
  const defineVariables = pageReady.params?.defineVariables

  const customVariables = {}
  _.each(defineVariables, ({ type, value }, key) => {
    customVariables[key] = onlyValue ? value : { count: 0, type, group: 'custom' }
  })

  return customVariables
}
