export default `({
    bodyBorder: false,
    layout: 'border',
    bodyPadding: 6,
    // defaults: {
    //     split: true,
    //     bodyPadding: 10
    // },
    setSpotId: function (c) {
        alert(c)
    },
    items: [
        {
            collapsible: false,
            region: 'center',
            margin: '5 0 0 0',
            xtype: 'uxcontainer',
            uxItemCfg: 'editRecomgrid',
            uxStoreCfg: 'editRecomgridStore',
            name: 'dagitimgrid',
            uxStore: {
                xtype: 'store',
            },
            uxItem: {}
        }
    ]
})
    `
