import React from 'react'
import { OverlayTrigger as BsOverlayTrigger } from 'react-bootstrap'
import { Tooltip } from '../index'
import { IOverlayTriggerProps } from './OverlayTrigger.types'

const OverlayTrigger = React.forwardRef(
  (
    { children, shouldWrap = true, tooltip, placement = 'top', delay = null }: IOverlayTriggerProps,
    ref: any
  ) => {
    if (shouldWrap) {
      const extra: Partial<IOverlayTriggerProps> = {}
      if (delay) {
        extra.delay = delay
      }
      return (
        <BsOverlayTrigger
          ref={ref}
          overlay={(overlayProps) => <Tooltip {...overlayProps}>{tooltip}</Tooltip>}
          placement={placement}
          {...extra}
        >
          {children}
        </BsOverlayTrigger>
      )
    }

    return children
  }
)

export default OverlayTrigger
