import {
  RESET_PLUGIN_CONFIGURATION,
  SET_PLUGIN_CONFIGURATION,
  PRODUCT_RESET_PLUGIN_CONFIGURATION,
  PRODUCT_SET_PLUGIN_CONFIGURATION
} from '../actions/actionTypes'
import { updateObject } from './utility'

const initialState = {
  pluginConfiguration: {}
}

const pluginConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLUGIN_CONFIGURATION:
      return updateObject(state, {
        ...state,
        ...action.options
      })
    case RESET_PLUGIN_CONFIGURATION:
      return updateObject(state, {
        ...state,
        ...initialState
      })
    default:
      return state
  }
}

const PRODUCTpluginConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_SET_PLUGIN_CONFIGURATION:
      return updateObject(state, {
        ...state,
        ...action.options
      })
    case PRODUCT_RESET_PLUGIN_CONFIGURATION:
      return updateObject(state, {
        ...state,
        ...initialState
      })
    default:
      return state
  }
}

export { pluginConfiguration, PRODUCTpluginConfiguration }
