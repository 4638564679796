Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.model.SupplySource', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Global_MDM_Geo_SupplySource',
  idProperty: 'ID',
  fields: [
    {
      name: 'SupplySource',
      type: 'string',
      defaultValue: 'Enter Name'
    },
    {
      name: 'Latitude',
      type: 'float',
      defaultValue: 0
    },
    {
      name: 'Longitude',
      type: 'float',
      defaultValue: 0
    },
    {
      name: 'isEditable',
      type: 'boolean'
    },
    {
      name: 'IsActive',
      type: 'boolean',
      convert: function (value) {
        return value == 1 ? 1 : 0
      }
    },
    {
      name: 'IsShownOnTool',
      type: 'boolean',
      convert: function (value) {
        return value == 1 ? 1 : 0
      }
    }
  ],
  get: function (fieldName) {
    if (fieldName == 'Title') {
      return this.data['SupplySource']
    }
    return this.data[fieldName]
  }
})
