import * as actionTypes from './actionTypes'

export const selectProduct = (value) => {
  return {
    type: actionTypes.SELECT_PRODUCT,
    productInfo: value
  }
}

export const toggleProduct = () => {
  return {
    type: actionTypes.TOGGLE_PRODUCTION_INFO
  }
}

export const toggleResourceChart = () => {
  return {
    type: actionTypes.TOGGLE_RESOURCE_CHART
  }
}

export const selectLegendOption = (legendState, legendData) => {
  return {
    type: actionTypes.SELECT_LEGEND_OPTION,
    legendState: { ...legendState },
    legendData: {
      issueList: legendData.enabledResourceIssues,
      resourceInfoList: legendData.enabledResourceDetails
    }
  }
}

// TO-DO: Organize actions & reducers better...
export const saveResourceIndex = (resourceIndex) => {
  return {
    type: actionTypes.SAVE_RESOURCE_INDEX,
    resourceIndex
  }
}

export const onProductSearchInputValueChange = (value) => {
  return {
    type: actionTypes.ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE,
    productSearchInputValue: value
  }
}

// New production view...
export const PRODUCTselectProduct = (value) => {
  return {
    type: actionTypes.PRODUCT_SELECT_PRODUCT,
    productInfo: value
  }
}

export const PRODUCTtoggleProduct = () => {
  return {
    type: actionTypes.PRODUCT_TOGGLE_PRODUCTION_INFO
  }
}

export const PRODUCTtoggleResourceChart = () => {
  return {
    type: actionTypes.PRODUCT_TOGGLE_RESOURCE_CHART
  }
}

export const PRODUCTselectLegendOption = (legendState, legendData) => {
  return {
    type: actionTypes.PRODUCT_SELECT_LEGEND_OPTION,
    legendState: { ...legendState },
    legendData: {
      issueList: legendData.enabledResourceIssues,
      resourceInfoList: legendData.enabledResourceDetails
    }
  }
}

// TO-DO: Organize actions & reducers better...
export const PRODUCTsaveResourceIndex = (resourceIndex) => {
  return {
    type: actionTypes.PRODUCT_SAVE_RESOURCE_INDEX,
    resourceIndex
  }
}

export const PRODUCTonProductSearchInputValueChange = (value) => {
  return {
    type: actionTypes.PRODUCT_ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE,
    productSearchInputValue: value
  }
}
