import { createSlice } from '@reduxjs/toolkit'

type ModalData = {
  selectedApproveCell?: string[]
}

type ModalTypes = {
  showModal: boolean
  modalType: string | null
  modalData: ModalData
}

const initialState: ModalTypes = {
  showModal: false,
  modalType: null,
  modalData: {}
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShowModal: (state, action) => {
      state.showModal = action.payload
      state.modalType = action.payload.modalType
      state.modalData = action.payload.modalData || {}
    },
    closeModal: (state) => {
      state.showModal = false
      state.modalType = null
      state.modalData = {}
    }
  }
})

export default modalSlice
export const { setShowModal, closeModal } = modalSlice.actions
