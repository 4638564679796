Ext.define('po.cmp.StoreDetail', {
  extend: 'Ext.grid.Panel',
  xtype: 'postoredetail',
  alias: 'widget.postoredetail',
  enableLocking: true,
  height: 200,
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return
    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      view = me.getView().normalView
    var grouping = view.getFeature('grouping')

    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText

    me.unlockText = i18n.GridConfig_unlockText
    me.lockText = i18n.GridConfig_lockText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'gridexporter',
      id: 'gridexport'
    }
  ],
  listeners: {
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady'
  },
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    },
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      dataIndex: 'Magaza',
      width: 90,
      align: 'right',
      summaryType: 'count',
      bind: {
        text: '{i18n.StoreDetail_Column_Magaza}',
        tooltip: '{i18n.StoreDetail_Column_Magaza_tooltip}'
      },
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'EldekiStok',
      width: 110,
      align: 'right',
      summaryType: 'sum',
      bind: {
        text: '{i18n.StoreDetail_Column_EldekiStok}',
        tooltip: '{i18n.StoreDetail_Column_EldekiStok_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0')
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0')
      },
      filter: 'number'
    },
    {
      dataIndex: 'AcikSiparis',
      width: 115,
      align: 'right',
      summaryType: 'sum',
      bind: {
        text: '{i18n.StoreDetail_Column_AcikSiparis}',
        tooltip: '{i18n.StoreDetail_Column_AcikSiparis_tooltip}'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0')
      },
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'Review_period',
      width: 145,
      align: 'right',
      bind: {
        text: '{i18n.StoreDetail_Column_Review_period}',
        tooltip: '{i18n.StoreDetail_Column_Review_period_tooltip}'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0')
      },
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'GuvenlikStogu',
      width: 135,
      align: 'right',
      summaryType: 'average',
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      bind: {
        text: '{i18n.StoreDetail_Column_GuvenlikStogu}',
        tooltip: '{i18n.StoreDetail_Column_GuvenlikStogu_tooltip}'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0')
      },
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'BirSonrakiTeslimataKadarTahminiSatis',
      width: 165,
      align: 'right',
      summaryType: 'sum',
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0')
      },
      bind: {
        text: '{i18n.StoreDetail_Column_BirSonrakiTeslimataKadarTahminiSatis}',
        tooltip: '{i18n.StoreDetail_Column_BirSonrakiTeslimataKadarTahminiSatis_tooltip}'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'Gunluk_Ort_Tahmin',
      width: 160,
      align: 'right',
      summaryType: 'average',
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      bind: {
        text: '{i18n.StoreDetail_Column_Gunluk_Ort_Tahmin}',
        tooltip: '{i18n.StoreDetail_Column_Gunluk_Ort_Tahmin_tooltip}'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'Gunluk_Ort_Satis',
      width: 145,
      align: 'right',
      summaryType: 'average',
      bind: {
        text: '{i18n.StoreDetail_Column_Gunluk_Ort_Satis}',
        tooltip: '{i18n.StoreDetail_Column_Gunluk_Ort_Satis_tooltip}'
      },
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'SiparisOnerisi',
      width: 135,
      align: 'right',
      summaryType: 'sum',
      bind: {
        text: '{i18n.StoreDetail_Column_SiparisOnerisi}',
        tooltip: '{i18n.StoreDetail_Column_SiparisOnerisi_tooltip}'
      },
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'StokGun',
      width: 100,
      align: 'right',
      bind: {
        text: '{i18n.StoreDetail_Column_StokGun}',
        tooltip: '{i18n.StoreDetail_Column_StokGun_tooltip}'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.0')
      },
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.0')
      },
      summaryType: 'average',
      filter: 'number',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    }
  ]
})
