import { Button } from 'react-bootstrap'
import cx from 'classnames'
import { defaultIcon } from './constants'
import { TimelineIconProps } from './TimelineIcon.types'
import styles from './index.module.scss'

const TimelineIcon = ({ item, activeColor, color, fontSize, handleClick }: TimelineIconProps) => {
  const icon = item.icon ?? defaultIcon

  return (
    <Button
      className="d-flex fs-md border-0"
      size="sm"
      variant="base"
      onClick={() => handleClick(item)}
    >
      <i
        className={cx('rounded-circle', 'fa', icon, {
          'bg-white': item.icon,
          // The timeline active color is only being applied to the default icon.
          [styles.timelineActiveColor]: !item.icon
        })}
        style={{ backgroundColor: activeColor, color, fontSize }}
      />
    </Button>
  )
}

export default TimelineIcon
