export default `({
    controller: {
        doClose: function () {
            this.getView().ownerCt.close();
        },
    },
    xtype: 'grid',
    reference: 'RegionalDistributionDetailsGrid',
    layout: 'fit',
    bind: {
        store: '{gridStore}'
    },
    listeners: {
        render: 'onRender'
    },
    features: [ {
        ftype: 'summary',
        dock: 'bottom'
    }],
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [
            {
                xtype: 'button',
                text: tt('Export to Excel'),
                docked: 'right',
                handler: function (btn) {
                    var grid = this.ownerCt.ownerCt
                    grid.saveDocumentAs({
                        type: 'xlsx',
                        title: tt('Bölgesel Dağıtım Detayı'),
                        fileName: tt('Bölgesel Dağıtım Detayı')+'.xlsx',
                    });
            }
        }],
    }],
    plugins: [{
        ptype: 'gridexporter'
    }],
    columns: [{
        text: tt('SpotID'),
        dataIndex: 'SpotID',
        width: 90,
        align: 'right',
        hidden:true
    }, {
        text: tt('Code'),
        dataIndex: 'Code',
        width: 90,
        align: 'right'
    }, {
        text: tt('Name'),
        dataIndex: 'Name',
        width: 90,
        align: 'right',
    }, {
        text: tt('OrgCode'),
        dataIndex: 'OrgCode',
        width: 90,
        align: 'right',
        renderer: function (v) {
            return v * 1;
        }
    }, {
        text: tt('MaterialCode'),
        dataIndex: 'MaterialCode',
        width: 90,
        align: 'right',
        renderer: function (v) {
            return v * 1;
        }
    }, {
        text: tt('MaterialDescription'),
        dataIndex: 'MaterialDescription',
        width: 90,
        align: 'right',
        //xtype: 'numbercolumn',
    }, {
        text: tt('SalesPrice'),
        dataIndex: 'SalesPrice',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('LastModifiedAt'),
        dataIndex: 'LastModifiedAt',
        width: 90,
        align: 'right',
        xtype: 'datecolumn',
        format: tt('d-m-Y')
    },  {
        text: tt('LastModifiedBy'),
        dataIndex: 'LastModifiedBy',
        width: 90,
        align: 'right',
        // xtype: 'numbercolumn',
    }, {
        text: tt('StatusDescription'),
        dataIndex: 'StatusDescription',
        width: 90,
        align: 'right',
        // xtype: 'numbercolumn',
    }, {
        text: tt('UnitsPerPackage'),
        dataIndex: 'UnitsPerPackage',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('NumberofStore'),
        dataIndex: 'NumberofStore',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('NumberofRecommendedStore'),
        dataIndex: 'NumberofRecommendedStore',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('Inventory_Store'),
        dataIndex: 'OnHandInventoryStore',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('Inventory_DC'),
        dataIndex: 'OnHandInventoryDC',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('OpenOrderStore'),
        dataIndex: 'OpenOrderStore',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('OpenOrder'),
        dataIndex: 'OpenOrderDC',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('OrderRecommendationParent'),
        dataIndex: 'OrderRecommendationParent',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    },{
        text: tt('OrderRecommendationCurrent'),
        dataIndex: 'OrderRecommendationCurrent',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    }, {
        text: tt('OrderRecommendationChild'),
        dataIndex: 'OrderRecommendationChild',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    },{
        text: tt('OrderRecommendation_Pallet'),
        dataIndex: 'OrderRecommendation_Pallet',
        width: 90,
        align: 'right',
        xtype: 'numbercolumn',
    },
    {
        text: tt('Include'),
        dataIndex: 'IsInclude',
        align: 'center',
        width: 80
    },
    {
        text: tt('IsLocked'),
        dataIndex: 'IsLocked',
        align: 'center',
        width: 80
    },],
})
`
