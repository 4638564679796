export default () => {
  return {
    title: 'Key-Value List',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: false,
            required: true
          },
          description: {
            type: 'string',
            title: 'Description'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          title: {
            type: 'string',
            title: 'Title'
          },
          sectionLeftColumn: {
            type: 'string',
            title: 'Section Left Column Size'
          },
          sectionRightColumn: {
            type: 'string',
            title: 'Section Right Column Size'
          }
        }
      },
      sections: {
        type: 'array',
        title: 'Sections',
        items: {
          title: 'Section',
          type: 'object',
          headerTemplate: '{{ self.label }}',
          properties: {
            key: {
              type: 'string',
              title: 'Key'
            },
            label: {
              type: 'string',
              title: 'Label'
            },
            backgroundColor: {
              type: 'string',
              format: 'Color',
              title: 'Background Color',
              default: '#ffffff'
            }
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string'
            }
          },
          sectionKey: {
            type: 'string',
            title: 'Section Key',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          key: {
            type: 'string',
            title: 'Key',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          type: {
            type: 'string',
            title: 'Type',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          title: {
            type: 'string',
            title: 'Title',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          isReadonly: {
            type: 'string',
            title: 'Is Readonly',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          isRequired: {
            type: 'string',
            title: 'Is Required',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          validationMessage: {
            type: 'string',
            title: 'Validation Message',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          addButton: {
            type: 'string',
            title: 'Add Button',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
