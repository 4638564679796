const fontSizeOptions = [
  '5px',
  '6px',
  '7px',
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '13px',
  '14px',
  '15px',
  '16px',
  '17px',
  '18px',
  '19px',
  '20px',
  '25px',
  '30px',
  '40px'
] as const

export default fontSizeOptions
