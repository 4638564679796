Ext.define('MDM.src.view.regionapp.DroppedMaterial.Main', {
  extend: 'Ext.window.Window',
  width: '90%',
  height: '90%',
  autoShow: true,
  maximizable: true,
  bodyPadding: 4,
  stateful: true,
  bodyStyle: {
    background: '#fff'
  },
  stateId: 'DropDialog',
  modal: true,
  controller: 'droppedmaterial-maincontroller',
  viewModel: 'droppedmaterial-mainviewmodel',
  title: 'Suppressed Material List',
  closable: false,
  layout: 'fit',
  ui: 'window-inverse',
  iconCls: 'fa fa-trash-o',
  listeners: {
    beforeclose: 'onbeforeClose'
  },
  items: [
    {
      xtype: 'regionapp-droppedmaterial-grid',
      reference: 'dropgrid',
      stateful: true,
      border: true,
      stateId: 'dropgrid',
      bind: {
        store: '{DroppedMaterialStore}'
      }
    }
  ],
  bbar: [
    {
      text: 'Export',
      iconCls: 'fa fa-download',
      ui: 'btn-success',
      handler: 'exportTo'
    },
    '->',
    {
      text: 'Close',
      ui: 'btn-success',
      handler: function (btn) {
        btn.up('window').close()
      }
    }
  ]
})
