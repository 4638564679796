import {
  FETCH_QUICK_FILTER_START,
  STORE_QUICK_FILTER,
  STORE_QUICK_FILTER_FAILED
} from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetQuickFilter: {},
  isLoading: true,
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUICK_FILTER_START:
      return updateObject(state, {
        isLoading: true
      })
    case STORE_QUICK_FILTER:
      return updateObject(state, {
        GetQuickFilter: { ...action.payload }
      })
    case STORE_QUICK_FILTER_FAILED:
      return updateObject(state, {
        GetQuickFilter: {},
        error: { ...action.payload },
        isLoading: false
      })
    default:
      return state
  }
}

export default reducer
