Ext.define('Assortment.reader.SimilarOptionsReader', {
  extend: 'Ext.data.reader.Json',
  alias: 'reader.similarOptionsReader',
  getResponseData: function (response) {
    var result = response.responseJson
    var merchDates = result.MerchDates
    var options = _.map(result.Products, (product) => {
      const details = _.map(product.SimilarityDetails, (char) => {
        return _.toInteger(char)
      })
      return {
        name: product.Name,
        description:
          product.Description === product.Name
            ? product.Description.substring(0, product.Description.indexOf('_'))
            : product.Description,
        startTime: product.StartTime,
        endTime: product.EndTime,
        overlappingRatio: product.OverlappingRatio,
        similarityDesc:
          product.SimilarityHierarchy > 0 && !product.SimilarityDescription.includes('Flex')
            ? product.SimilarityDescription + ' ' + product.SimilarityHierarchy
            : product.SimilarityDescription,
        image: product.ImageURL,
        ruleID: product.SimilarityId,
        hierarchialOrder: product.SimilarityHierarchy,
        weeklySales: product.WeeklySales,
        basePrice: product.BasePrice,
        totalSales: product.TotalSales,
        isEnabled: !product.IsDisabled,
        id: product.Id,
        similarityDetails: details,
        stock: product.InitialInventory,
        storeCount: product.StoreCount,
        sizeRange: product.SizeRange,
        fit: product.Fit,
        merchDates: merchDates,
        overlappingTotalSales: product.OverlappingTotalSales,
        sizeRangeTypeID: product.SizeRangeTypeID
      }
    })

    return options
  }
})
