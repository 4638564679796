Ext.define('po.model.CheckedRecordsModel', {
  extend: 'po.model.Base',
  fields: [
    { name: 'urunKodu', type: 'string' },
    { name: 'urunAciklamasi', type: 'string' },
    { name: 'tedarikciKodu', type: 'string' },
    { name: 'tedarikciAdi', type: 'string' },
    { name: 'degisenOneriKoli', type: 'float' },
    { name: 'depoKodu', type: 'string' },
    { name: 'koliIciMiktar', type: 'float' },
    { name: 'minSiparisMiktari', type: 'float' },
    { name: 'paletKatsayisi', type: 'float' },
    { name: 'siparisKatsayisi', type: 'float' }
  ]
})
