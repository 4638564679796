import { FormGroup, Col, Row, Form } from 'react-bootstrap'
import cx from 'classnames'
import { SlvyIconSelector } from '..'
import { ISlvyFormIconSelectorProps } from './SlvyFormIconSelector.types'
import { getFormErrorMsg } from '@/helpers'
import { useUniqueId } from '../../hooks'

export default function SlvyFormIconSelector({
  onChange,
  label,
  value,
  error,
  errorModelLabel,
  inputClass = '',
  inputGroupClass = '',
  noRowMarginBottom = false
}: ISlvyFormIconSelectorProps) {
  const [uniqueId] = useUniqueId()
  const controlId = `controlId-${uniqueId}`

  const handleChange = <T,>(newValue: T) => onChange(newValue)

  const errorMsg = getFormErrorMsg(error, errorModelLabel)

  return (
    <FormGroup
      as={Row}
      className={cx('form-group', { 'mb-3': !noRowMarginBottom, 'is-invalid': errorMsg })}
      data-testid="slvy-form-icon-selector"
    >
      <Form.Label
        column
        className="d-flex align-items-center justify-content-end"
        htmlFor={controlId}
        sm={2}
      >
        {label}
      </Form.Label>
      <Col sm={10}>
        <SlvyIconSelector
          error={Boolean(errorMsg)}
          inputClass={inputClass}
          inputGroupClass={inputGroupClass}
          inputId={controlId}
          value={value}
          onChange={handleChange}
        />
        <Form.Control.Feedback className={cx({ 'd-block': errorMsg })} type="invalid">
          {errorMsg}
        </Form.Control.Feedback>
      </Col>
    </FormGroup>
  )
}
