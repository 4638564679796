Ext.define('MDM.src.view.globalapp.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.globalapp-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {
    var me = this,
      vm = this.getViewModel(),
      owner = vm.get('owner'),
      isReadOnly = MDM.Global.getPermission(owner),
      isGlobal = MDM.Global.isGlobal(),
      region = MDM.Global.getRegion()
    vm.set('readOnlyMode', isReadOnly)
    vm.set('readOnly', isReadOnly)
    vm.set('isGlobal', isGlobal)
    vm.set('userMegaRegion', region)

    console.log(vm)
    Ext.defer(function () {
      Ext.Ajax.request({
        method: 'POST',
        url: MDM.Global.getApiUrl() + 'gillettev3/getPermission',

        success: function (response, opts) {
          var data = Ext.JSON.decode(response.responseText)

          var view = me.getView()
          if (!view) return

          me.getViewModel().set('userMegaRegion', data.userMegaRegion)

          me.getView().setDisabled(false)

          me.getViewModel().getStore('calStore').load()
          me.getViewModel().getStore('EditCalStore').load()
        },
        failure: function (response) {
          var icon = Ext.MessageBox['WARNING']
          Ext.MessageBox.show({
            title: 'Warning',
            msg: "You dont have you don't have permission to access this page",
            buttons: Ext.MessageBox.OK,
            icon: icon,
            maskClickAction: 'hide'
          })
          me.getView().disable(true)
          console.log('server-side failure with status code ' + response.status)
        }
      })
    }, 50)
  },
  onRecordChange: function () {
    this.getViewModel().get('record').save()
  },
  isActionDisabled: function (view) {
    return this.getViewModel().get('readOnlyMode')
  },
  onPublish: function () {
    var record = this.getViewModel().get('record'),
      me = this,
      pageIndex = this.getViewModel().get('pageIndex')
    this.getViewModel().set('pageIndex', 4)

    record.set('IsPublished', 1)
    record.set('WizardPage5', 1)
    record.save({
      success: function () {
        me.getViewModel().set('pageIndex', pageIndex)
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
      }
    })
  },
  onBoxReady: function () {
    var me = this
    me.getView().setLoading('Loading data please wait....')
    var record = Ext.create('MDM.src.view.globalapp.model.ConfigModel')
    record.load({
      success: function (record) {
        var view = me.getView()
        if (!view) return

        me.getView().setLoading(false)
        var errorType = record.get('errorType')
        if (errorType) {
          me.showError(record)
        } else {
          me.setRecord(record)
        }
      }
    })
  },
  setRecord: function (record) {
    this.getViewModel().set('record', record)
  },
  showError: function (record) {
    var me = this,
      view = me.getView()

    Ext.Msg.show({
      title: record.get('errorType'),
      message: record.get('message'),
      buttons: Ext.Msg.OK,
      icon: Ext.Msg.QUESTION,
      fn: function () {
        view.setDisabled(true)
      }
    })
  },
  onCalendarWrite: function (store, record, operation) {
    if (operation == 'commit') {
      var calstore = this.getViewModel().getStore('calStore'),
        id = record.get('OrderNumber'),
        calRec = calstore.findRecord('id', id)
      if (calRec) {
        calRec.events().load()
      }
    }
  },
  onWriteEvent: function () {
    var store = this.getViewModel().getStore('EditCalStore')
    store.load()
  },
  onActiveteTree: function (tree) {
    tree.store.load()
  },
  OnLoad: function () {
    var store = this.getViewModel().getStore('calStore')
  },

  onRestart: function () {
    var me = this
    Ext.Msg.show({
      title: ' Reseting Changes',
      width: 500,
      closable: false,
      msg: 'This action will (re)copy last period master data to establish the default for this period. Any prior work on this period will be reset.<br><br> Are you sure you want to continue?<br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doRestart()
        }
      }
    })
  },

  onClickProgress: function (event, tile) {
    var index = Ext.get(tile).getAttribute('itemId'),
      c = Ext.get(tile).getAttribute('class')
    if (c == 'node complete') this.navigate(null, parseInt(index))
  },
  doRestart: function () {
    var view = this.view,
      me = this
    view.setLoading('Please wait....Copy in progress')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_ReStart',
      timeout: 360000,
      success: function (response, opts) {
        var data = Ext.JSON.decode(response.responseText)
        Ext.Msg.alert('Status', 'Reset operation was sucessful')
        me.restartLayout()
        view.setLoading(false)
      },
      failure: function (response) {
        Ext.MessageBox.hide()
        console.log('server-side failure with status code ' + response.status)
      }
    })
  },

  restartLayout: function () {
    var me = this
    me.getViewModel().set('pageIndex', 1)
    var record = Ext.create('MDM.src.view.globalapp.model.ConfigModel')
    record.load({
      success: function (record) {
        me.getViewModel().set('pageIndex', 0)
        var errorType = record.get('errorType')
        if (errorType) {
          me.showError(record)
        } else {
          me.setRecord(record)
        }
      }
    })
    me.getViewModel().getStore('calStore').load()
    me.getViewModel().getStore('EditCalStore').load()
    me.getViewModel().set('pageIndex', 77)
    me.navigate(null, 0)
  },

  onBeforeEdit: function (editor, context) {
    var field = context.field
    var record = context.record
    var editField = context.column.field
    var startDate = record.get('startDate')
    var endDate = record.get('endDate')

    if (field == 'startDate') {
      editField.setMaxValue(endDate)
    }
    if (field == 'endDate') {
      editField.setMinValue(startDate)
    }
  },

  navigate: function (direction, index) {
    var me = this,
      mainpanel = this.getView(),
      record = me.getViewModel().get('record')

    var panel = this.lookupReference('wizard')
    //	Ext.select('.indicator.active').replaceCls('active', 'visited');
    // This routine could contain business logic required to manage the navigation steps.
    // It would call setActiveItem as needed, manage navigation button state, handle any
    // branching logic that might be required, handle alternate actions like cancellation
    // or finalization, etc.  A complete wizard implementation could get pretty
    // sophisticated depending on the complexity required, and should probably be
    // done as a subclass of CardLayout in a real-world implementation.
    var layout = panel.getLayout()
    if (direction) {
      var i = panel.items.indexOf(panel.getLayout().activeItem) + 1
      index = direction === 'next' ? i + 1 : i - 1
      layout[direction]()
    } else if (index !== undefined) {
      layout.setActiveItem(index)
      index++
    }
    var readOnlyMode = this.getViewModel().get('readOnlyMode')
    if (!readOnlyMode) {
      var page = index - 1
      record.set('WizardPage' + page, 1)

      this.getViewModel().set('pageIndex', index)
      record.set('LastStepNumber', index)
      record.save()
    }
    if (readOnlyMode) {
      var page = index - 1
      record.set('WizardPage' + page, 1)

      this.getViewModel().set('pageIndex', index)
      record.set('LastStepNumber', index)
    }
    Ext.select('.indicator[index="' + index + '"]').addCls('active')
    mainpanel.down('#move-prev').setDisabled(!layout.getPrev())
    mainpanel.down('#move-next').setDisabled(!layout.getNext())
  },

  showPrevious: function () {
    this.navigate('prev')
  },
  showNext: function () {
    this.navigate('next')
  },
  onDPIActive: function () {
    this.getViewModel().getStore('DpiSegmentStore').load()
    this.getViewModel().getStore('DpiProductGrouptStore').load()
    this.getViewModel().getStore('DPIFamilyStore').load()
  },
  onEditCalendar: function (view, rowIndex, colIndex, item, e, record, row) {
    var store = this.getViewModel().getStore('calStore'),
      EditCalStore = this.getViewModel().getStore('EditCalStore'),
      readOnlyMode = this.getViewModel().get('readOnlyMode')

    var win = Ext.create('MDM.src.view.globalapp.Panel.setup.FormDialog', {
      height: 400,
      width: 600,
      viewModel: {
        data: {
          record: record,
          readOnlyMode: readOnlyMode,
          store: store
        }
      }
    }).show()
    win.on('save', function () {
      EditCalStore.sync()
    })
  },
  onExpandAllClick: function (btn) {
    var isCollapsed = btn.isCollapsed
    btn.isCollapsed = !isCollapsed
    if (!isCollapsed) {
      this.lookup(btn.target).collapseAll()
      this.lookup(btn.target).getRootNode().expand()
      btn.setText('Expand All')
    } else {
      var tree = this.lookup(btn.target).expandAll()
      btn.setText('Collapse All')
    }
  },
  onCollapseAllClick: function (btn) {
    this.lookup(btn.target).collapseAll()
    this.lookup(btn.target).getRootNode().expand()
  }
})
