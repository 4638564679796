Ext.define('clustering.Reader.CustomReader', {
  extend: 'Ext.data.reader.Json',
  alias: 'reader.customReader',
  keepRawData: true,
  getResponseData: function (response) {
    var me = this
    var result = response.responseJson
    setTimeout(function () {
      var component = me.clusterContext.down('#' + me.componentId)
      if (component) {
        component.setValue(result.SelectedIndices)
        component.setValuesToModel(result.Values)
      }
    }, 100)
    return result.Values
  }
})
