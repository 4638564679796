import { getDiscreteOptions } from '../sparkline'
import { SparklineOptions } from '../../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'
import { sparklineOptions as defaultSparklineOptions } from './mocks'

function getSparklineOptions() {
  return defaultSparklineOptions
}

describe('AgGrid:Helpers:Common:getDiscreteOptions', () => {
  let sparklineOptions: SparklineOptions = getSparklineOptions()
  beforeEach(() => {
    sparklineOptions = getSparklineOptions()
  })

  it('should return correct object including type, line, paddingInner, padding and marker', () => {
    const result = getDiscreteOptions(sparklineOptions)
    expect(result).toMatchObject({
      type: 'line',
      line: {
        strokeWidth: 0
      },
      paddingInner: 0.5,
      padding: {
        top: 5,
        right: 1,
        bottom: 5,
        left: 1
      },
      marker: {
        strokeWidth: 0,
        shape: 'square',
        formatter: expect.any(Function)
      }
    })
  })
})
