export default `({
    xtype: 'grid',
    columnLines: true,
    reference: 'haftalikgrid',
    // bind: {
    //     title: '{record.WeekNumber}'
    // },
    plugins: [{
        ptype: 'gridexporter'
    }],
    defaultListenerScope: false,
    features: [{
        ftype: 'grouping',
        startCollapsed: true,
        groupHeaderTpl: '{columnName}: {name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
    }],
    features: [{
            ftype: 'groupingsummary',
            groupHeaderTpl: '{name}',
            hideGroupedHeader: false,
            enableGroupingMenu: true
        },
        {
            ftype: 'summary',
            dock: 'bottom'
        }
    ],
    columns: [{
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            renderer: function (v) {
                return v * 1;
            }
        }, {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            summaryType: 'count',
            summaryRenderer: function (value, summaryData, dataIndex) {
                return ((value === 0 || value > 1) ? '(' + value + ' Items)' : '(1 Item)');
            }
        },
        {
            text: tt('H1N'),
            dataIndex: 'H1N',
            width: 200,
            summaryType: 'count',
            summaryRenderer: function (value, summaryData, dataIndex) {
                return ((value === 0 || value > 1) ? '(' + value + ' Items)' : '(1 Item)');
            }
        },
        {
            text: tt('H2N'),
            dataIndex: 'H2N',
            width: 200,
            summaryType: 'count',
            summaryRenderer: function (value, summaryData, dataIndex) {
                return ((value === 0 || value > 1) ? '(' + value + ' Items)' : '(1 Item)');
            }
        },
        {
            text: tt('H3N'),
            dataIndex: 'H3N',
            width: 200,
            summaryType: 'count',
            summaryRenderer: function (value, summaryData, dataIndex) {
                return ((value === 0 || value > 1) ? '(' + value + ' Items)' : '(1 Item)');
            }
        },
        {
            text: tt('Sales Price'),
            dataIndex: 'Price',
            width: 150,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'average',
            summaryRenderer: Ext.util.Format.numberRenderer('0,000.00')
        },
        {
            text: tt('TotalNetValueoftheSalesOrder'),
            dataIndex: 'TotalNetValueoftheSalesOrder',
            width: 150,
            format: '0,000',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'sum',
            summaryRenderer: Ext.util.Format.numberRenderer('0,000')
        },
        {
            text: tt('TotalInvoicedQuantity'),
            dataIndex: 'TotalInvoicedQuantity',
            width: 150,
            format: '0,000',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'sum',
            summaryRenderer: function (v) {
                v = Ext.util.Format.number(v, '0,000');
                if(t('₺')=='₺')
                {
                    return v + ' ' + t('₺');
                }
                else{
                    return t('₺') +' '+ v;                            
                }
            }
        },
        // {
        //     text: 'TotalInvoicedQuantityDay1',
        //     dataIndex: 'TotalInvoicedQuantityDay1',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalInvoicedQuantityDay2',
        //     dataIndex: 'TotalInvoicedQuantityDay2',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalInvoicedQuantityDay3',
        //     dataIndex: 'TotalInvoicedQuantityDay3',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalInvoicedQuantityDay4',
        //     dataIndex: 'TotalInvoicedQuantityDay4',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalInvoicedQuantityDay5',
        //     dataIndex: 'TotalInvoicedQuantityDay5',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalInvoicedQuantityDay6',
        //     dataIndex: 'TotalInvoicedQuantityDay6',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalInvoicedQuantityDay7',
        //     dataIndex: 'TotalInvoicedQuantityDay7',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay1'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay1',
        //     width: 150,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn',
        //     summaryType: 'sum',
        //     summaryRenderer: Ext.util.Format.numberRenderer('0,000 ₺')
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay2',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay2',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay3',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay3',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay4',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay4',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay5',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay5',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay6',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay6',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay7',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay7',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        {
            text: tt('NumberOfDistinctDC'),
            dataIndex: 'NumberOfDistinctDC',
            width: 150,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'average',
            summaryRenderer: Ext.util.Format.numberRenderer('0,000')
        },
        {
            text: tt('NumberOfDistinctStore'),
            dataIndex: 'NumberOfDistinctStore',
            width: 150,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'average',
            summaryRenderer: Ext.util.Format.numberRenderer('0,000')
        },
    ],
})
`
