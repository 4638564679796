import React from 'react'
import cx from 'classnames'
import _ from 'lodash'

export default class Dropdown extends React.Component {
  constructor() {
    super()

    this.dropdownRef = null

    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillunmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside(event) {
    const { onClickOutside, headerRef } = this.props

    const isClickOutside = this.dropdownRef && !headerRef.contains(event.target)
    if (isClickOutside) onClickOutside()
  }

  handleClick(item) {
    const { onClick } = this.props

    onClick(item.index)
  }

  render() {
    const { items, top } = this.props

    return (
      <ul
        ref={(element) => {
          this.dropdownRef = element
        }}
        className="consumed-by-dropdown"
        style={{ top }}
      >
        {items.length > 0 ? (
          _.map(items, (item) => {
            const isConsumedBy = _.includes(item.name, 'ConsumedBy')
            const isProducedBy = _.includes(item.name, 'ProducedBy')

            const displayName = isConsumedBy
              ? item.name.replace('ConsumedBy ', '')
              : item.name.replace('ProducedBy', '')

            let title = ''
            if (isConsumedBy) title = 'Consumed By'
            if (isProducedBy) title = 'Produced By'

            return (
              <li
                key={`${item.name}-${item.index}`}
                title={title}
                onClick={this.handleClick.bind(this, item)}
              >
                <i
                  className={cx(
                    { 'fa-fw fa slvy-ui-icon-box-in': isConsumedBy },
                    { 'fa-fw fa slvy-ui-icon-box-out': isProducedBy }
                  )}
                />
                {displayName}
              </li>
            )
          })
        ) : (
          <li>Not consumed by any product</li>
        )}
      </ul>
    )
  }
}
