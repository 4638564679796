import { useCallback, useEffect, useRef } from 'react'
import { useGetMultiRowSelectedParams, useGetSelectionCellParams } from '../../../index'
import { getMultiRowSelectedResults } from '../../../../helpers/selection/selection'
import {
  MultiRowSelectedEventRef,
  MultiRowSelectedProps,
  HandleMultiRowSelectedProps
} from './useRegisterEventMultiRowSelected.types'

function useRegisterEventMultiRowSelected({
  fieldConfigs,
  registerEvent,
  getFieldType
}: MultiRowSelectedProps) {
  const multiRowSelectedEventRef = useRef<MultiRowSelectedEventRef>({
    handleMultiRowSelected: (_args: never): never => ({} as never)
  })

  const handleMultiRowSelected = useCallback(
    ({ selectedRows }: HandleMultiRowSelectedProps) => {
      return getMultiRowSelectedResults({ fieldConfigs, rows: selectedRows })
    },
    [fieldConfigs]
  )

  const { cellParams } = useGetSelectionCellParams(fieldConfigs)

  const { multiRowSelectedParams } = useGetMultiRowSelectedParams({ cellParams, getFieldType })

  useEffect(() => {
    multiRowSelectedEventRef.current.handleMultiRowSelected = registerEvent({
      key: 'MultiRowSelected',
      fn: handleMultiRowSelected,
      returnTypes: multiRowSelectedParams
    })
  }, [handleMultiRowSelected, multiRowSelectedParams, registerEvent])

  return { multiRowSelectedEventRef }
}

export default useRegisterEventMultiRowSelected
