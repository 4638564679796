import { getMergedStyle } from '../index'
import { CommonStyleDisplayRule } from '../../../types'

describe('AgGrid:Helpers:Cell:getMergedStyle', () => {
  it('should return merged full style if italicText and boldText are truthy', () => {
    const rule: CommonStyleDisplayRule = {
      backColor: 'red',
      boldText: true,
      italicText: true,
      textColor: 'blue',
      textDecoration: 'underline'
    }
    const style = getMergedStyle({ rule, textAlign: 'left', cursor: 'text' })
    expect(style).toEqual({
      textAlign: 'left',
      backgroundColor: rule.backColor,
      fontWeight: 'bold',
      fontStyle: 'italic',
      color: rule.textColor,
      textDecoration: rule.textDecoration,
      cursor: 'text'
    })
  })
  it('should return merged style without fontStyle and fontWeight if italicText and boldText are falsy', () => {
    const rule: Partial<CommonStyleDisplayRule> = {
      backColor: 'red',
      textColor: 'blue',
      textDecoration: 'underline'
    }
    const style = getMergedStyle({ rule, textAlign: 'left', cursor: 'text' })
    expect(style).toEqual({
      textAlign: 'left',
      backgroundColor: rule.backColor,
      color: rule.textColor,
      textDecoration: rule.textDecoration,
      cursor: 'text'
    })
  })
  it('should return merged style without fontStyle if italicText is falsy', () => {
    const rule: Partial<CommonStyleDisplayRule> = {
      italicText: false,
      backColor: 'red',
      textColor: 'blue',
      textDecoration: 'underline'
    }
    const style = getMergedStyle({ rule, textAlign: 'left', cursor: 'text' })
    expect(style).toEqual({
      textAlign: 'left',
      backgroundColor: rule.backColor,
      color: rule.textColor,
      textDecoration: rule.textDecoration,
      cursor: 'text'
    })
  })
  it('should return merged style without fontWeight if boldText is falsy', () => {
    const rule: Partial<CommonStyleDisplayRule> = {
      boldText: false,
      backColor: 'red',
      textColor: 'blue',
      textDecoration: 'underline'
    }
    const style = getMergedStyle({ rule, textAlign: 'left', cursor: 'text' })
    expect(style).toEqual({
      textAlign: 'left',
      backgroundColor: rule.backColor,
      color: rule.textColor,
      textDecoration: rule.textDecoration,
      cursor: 'text'
    })
  })
  it('should not return merged style with backgroundColor prop when backColor is falsy', () => {
    const rule: Partial<CommonStyleDisplayRule> = {
      backColor: '',
      textColor: 'blue',
      textDecoration: 'underline'
    }
    const style = getMergedStyle({ rule, textAlign: 'left', cursor: 'text' })
    expect(style).toEqual({
      textAlign: 'left',
      color: rule.textColor,
      textDecoration: rule.textDecoration,
      cursor: 'text'
    })
  })
  it('should return merged merged style with textAlign: left when textAlign is provided', () => {
    const rule: CommonStyleDisplayRule = {
      backColor: 'red',
      boldText: true,
      italicText: true,
      textColor: 'blue',
      textDecoration: 'underline'
    }
    const textAlign = 'center'
    const style = getMergedStyle({ rule, textAlign, cursor: 'text' })
    expect(style.textAlign).toBe(textAlign)
  })
  it('should not return merged style with textAlign prop when textAlign is falsy', () => {
    const rule: CommonStyleDisplayRule = {
      backColor: 'red',
      boldText: true,
      italicText: true,
      textColor: 'blue',
      textDecoration: 'underline'
    }
    const style = getMergedStyle({ rule, textAlign: '', cursor: 'text' })
    expect(style).not.toHaveProperty('textAlign')
  })
  it('should not return merged style with cursor is empty', () => {
    const style = getMergedStyle({ rule: {}, textAlign: 'left', cursor: '' })
    expect(style).toEqual({
      textAlign: 'left'
    })
  })
})
