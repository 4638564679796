export default () => {
  return {
    title: 'Numeric Filter',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Filter Settings',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          label: {
            type: 'string',
            title: 'Label'
          },
          placeholder: {
            type: 'string',
            default: 'Enter a number',
            title: 'Placeholder'
          },
          step: {
            type: 'integer',
            title: 'Step',
            default: null
          },
          min: {
            type: 'integer',
            title: 'Minimum',
            default: null
          },
          max: {
            type: 'integer',
            title: 'Maximum',
            default: null
          },
          defaultValue: {
            type: 'integer',
            title: 'Default Value',
            default: null
          },
          defaultValueFromData: {
            type: 'string',
            title: 'Default Value from Data Field',
            watch: {
              ds: 'settings.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
