import { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { useTheme } from '../../contexts/ThemeContext'
import { IndeterminateCheckboxProps } from './IndeterminateCheckbox.types'

export default function IndeterminateCheckbox({
  checked,
  indeterminate,
  onChange
}: IndeterminateCheckboxProps) {
  const { indeterminateCheckbox } = useTheme()
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(updateIndeterminateState, [checked, indeterminate])

  function handleChange(event: Parameters<typeof onChange>[0]) {
    onChange(event)
  }

  function updateIndeterminateState() {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !checked && indeterminate
    }
  }

  return (
    <Form.Check
      ref={ref}
      checked={checked}
      className={indeterminateCheckbox}
      type="checkbox"
      onChange={handleChange}
    />
  )
}
