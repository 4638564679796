Ext.define('MDM.src.view.globalapp.Panel.setup.Form', {
  extend: 'Ext.form.Panel',

  xtype: 'globalapp-setup-form',

  items: [
    {
      xtype: 'fieldset',
      defaultType: 'textfield',
      layout: 'anchor',
      margin: 10,
      defaults: {
        anchor: '100%',
        labelWidth: 150
      },
      items: [
        {
          xtype: 'fieldcontainer',
          fieldLabel: '<b>Reporting Period</b>',
          layout: 'hbox',
          combineErrors: true,
          defaultType: 'textfield',
          defaults: {
            hideLabel: 'true'
          },
          items: [
            {
              xtype: 'textfield',
              flex: 1,
              emptyText: 'Period.',

              readOnly: true,
              disabled: true,

              bind: {
                value: '{record.ReportingPeriod}'
              }
            },
            {
              xtype: 'displayfield',
              value: '-'
            },
            {
              xtype: 'textfield',
              emptyText: 'DPI..',
              flex: 1,
              format: 'd-m-Y',
              disabled: true,
              name: 'PromotionEndDate',
              bind: {
                value: '{reportingPeriodsUFFIX}'
              },
              readOnly: true
            }
          ]
        },
        {
          xtype: 'fieldcontainer',
          fieldLabel: '<b>Year/Month</b>',
          layout: 'hbox',
          combineErrors: true,
          defaultType: 'textfield',
          defaults: {
            hideLabel: 'true'
          },
          items: [
            {
              xtype: 'combobox',
              fieldLabel: '<b>Year </b>',
              name: 'PromotionTypeId',
              flex: 1,
              bind: {
                value: '{record.Year}'
              },
              store: ['2016', '2017'],
              readOnly: true,
              allowBlank: false,
              disabled: true,
              emptyText: 'Year......',
              reference: 'PromotionTypeId', // component's name in the ViewModel
              publishes: 'value' // value is not published by default
            },
            {
              xtype: 'displayfield',
              value: '-'
            },
            {
              xtype: 'combobox',
              fieldLabel: '<b>Month </b>',

              readOnly: true,
              flex: 1,
              bind: {
                value: '{record.Month}'
              },
              allowBlank: false,
              disabled: true,
              store: ['01', '02', '04', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
              emptyText: 'Month Number...',

              publishes: 'value' // value is not published by default
            }
          ]
        },

        {
          xtype: 'fieldcontainer',
          fieldLabel: '<b>Reporting Dates</b>',
          layout: 'hbox',
          combineErrors: true,
          defaultType: 'textfield',
          defaults: {
            hideLabel: 'true'
          },
          items: [
            {
              xtype: 'datefield',
              flex: 1,
              emptyText: 'Start Date....',

              editable: false,
              bind: {
                value: '{record.BeginDate}',
                maxValue: '{record.EndDate}',
                disabled: '{readOnlyMode}'
              },
              format: 'd-m-Y',
              allowBlank: false
            },
            {
              xtype: 'displayfield',
              value: '-'
            },
            {
              xtype: 'datefield',
              emptyText: 'End Date....',
              flex: 1,

              allowBlank: false,
              format: 'd-m-Y',

              bind: {
                value: '{record.EndDate}',
                minValue: '{record.BeginDate}',
                disabled: '{readOnlyMode}'
              }
            }
          ]
        }
      ]
    }
  ]
})
