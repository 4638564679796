import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMenu } from '../components/Navigation/Navigation.types'

type IState = IMenu[]

export const initialState: IState = []

export const menus = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenus: (_state, action: PayloadAction<IState>) => {
      return action.payload
    }
  }
})

export const { setMenus } = menus.actions

export default menus.reducer
