/***
 * Serie class for spline series type
 *
 * See {@link Chart.ux.Highcharts.Serie} class for more info
 */
Ext.define('Chart.ux.Highcharts.SplineSerie', {
  extend: 'Chart.ux.Highcharts.Serie',
  alternateClassName: ['highcharts.spline'],
  type: 'spline'
})
