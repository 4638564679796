import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import ScenarioTreeList from '../../ScenarioTree/ScenarioTreeList'

const selectFromState = (state) => ({
  tree: state.tree.tree,
  selectedScenarioSaveTree: state.treeSelection.saveTree
})

function AssortmentSelection(props) {
  const { tree, selectedScenarioSaveTree } = useSelector(selectFromState)
  const { Root = {}, AvailableStatus = [] } = tree
  const { currentScenario = {} } = selectedScenarioSaveTree

  const expandedRows = {
    0: true,
    '0.0': true
  }

  return (
    <div className="assortment-scenario-tree -visible assortment-selection-container">
      <div className="scenario-lib-wrapper">
        <div className="a-wr">
          <nav>
            <ScenarioTreeList
              AvailableStatus={AvailableStatus}
              dataKey="saveTree"
              expandedRows={expandedRows}
              isStatusChangeable={false}
              root={Root}
              title={'Save As - Selection'}
            />
          </nav>
        </div>
      </div>
    </div>
  )
}

export default AssortmentSelection
