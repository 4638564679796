Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.Panel.MegaRegion', {
  extend: 'MDM.src.ux.GridPanel',
  // Uncomment to give this component an xtype
  // xtype : '-megaregion',
  xtype: 'globalapp-geogrid-megeregion',
  border: true,
  title: 'Regions',
  iconCls: 'fa fa-globe',
  columnLines: true,
  rowLines: true,
  listeners: {
    select: 'onSelectMegaRegion'
  },
  viewConfig: {
    stripeRows: false,
    plugins: [
      {
        ptype: 'uxgridviewdragdrop',
        id: 'dd',
        containerScroll: true,
        enableDrag: false,
        dropGroup: 'dd-grid-to-grid-group1',
        // The right hand drop zone gets special styling
        // when dragging over it.
        dropZone: {
          overClass: 'dd-over-gridview'
        }
      }
    ]
  },
  features: [
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      text: 'Region',
      dataIndex: 'MegaRegion',
      flex: 2,
      summaryType: 'count',
      menuDisabled: true,
      summaryRenderer: function (value) {
        return Ext.String.format('<b>{0} Region{1}</b>', value, value > 1 ? 's' : '')
      }
    },
    {
      text: 'Subregion Cnt',
      tooltip: 'Subregion Count',
      dataIndex: 'RegionCount',
      align: 'right',
      flex: 1,
      summaryType: 'sum',
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      text: 'Country Cnt',
      tooltip: 'Country Count',
      dataIndex: 'CountryCount',
      align: 'right',
      flex: 1,
      summaryType: 'sum',
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      text: 'Supply Source Cnt',
      tooltip: 'Supply Source Count',
      dataIndex: 'SupplysourceCount',
      align: 'right',
      flex: 1,
      summaryType: 'sum',
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      xtype: 'actioncolumn',
      width: 40,
      menuDisabled: true,
      hidden: true,
      align: 'center',
      items: [
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete Record',
          isActionDisabled: function () {
            return true
          },
          handler: 'onMegaRegionDelete'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      align: 'center',
      hidden: true,
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            //   return true;
            return !record.get('isEditable')
          },
          getClass: function (value, metadata, record) {
            if (record.get('isEditable')) {
              return 'fa fa-pencil-square-o'
            }
            return 'fa fa-eye '
          },
          tooltip: 'View/Edit',
          handler: 'onEditMegaRegion'
        }
      ]
    }
  ]
})
