import { createSlice } from '@reduxjs/toolkit'

type ReasonCodesTypes = {
  description: string
  id: number
}

const initialState: ReasonCodesTypes = {
  description: '',
  id: 0
}

const reasonCode = createSlice({
  name: 'reason',
  initialState,
  reducers: {
    setReasonCode: (state, action) => {
      state.description = action.payload.description
      state.id = action.payload.id
    }
  }
})

export default reasonCode
export const { setReasonCode } = reasonCode.actions
