export default {
  title: 'Calendar',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'Filter-Calendar',
  description: 'Filter with dates',
  iconClass: 'fa-filter',
  color: 'navy-bg',
  group: 'Filters'
}
