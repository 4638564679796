export default {
  title: 'ProgressBar',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'ProgressBar',
  description: 'ProgressBar plugin',
  iconClass: 'fa-tasks',
  color: 'navy-bg',
  group: 'Views'
}
