import { Options, TooltipOptions } from 'highcharts'

export const defaultClassNames = ' d-flex align-items-center justify-content-center m-0 ' as const

export const commonTooltipOptions: Pick<TooltipOptions, 'outside' | 'headerFormat'> = {
  outside: true,
  headerFormat: ''
}

export const defaultBoxOptions: Options = {
  chart: {
    backgroundColor: 'transparent',
    type: 'boxplot',
    inverted: true,
    height: 50
  },
  title: {
    text: '',
    floating: true
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  xAxis: {
    categories: ['0'],
    visible: false
  },
  tooltip: {
    ...commonTooltipOptions,
    shared: true,
    distance: 1,
    padding: 0,
    borderWidth: 0
  }
}

export const defaultBulletOptions: Options = {
  title: {
    text: '',
    floating: true
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  tooltip: {
    ...commonTooltipOptions,
    style: {
      fontSize: '11px'
    }
  },
  yAxis: {
    visible: false
  },
  xAxis: {
    visible: false
  }
}

export const defaultPieOptions: Options = {
  chart: {
    backgroundColor: 'transparent',
    plotBackgroundColor: 'transparent',
    plotBorderWidth: 0,
    plotShadow: false,
    type: 'pie',
    height: 45,
    width: 45
  },
  title: {
    text: ' ',
    align: 'left',
    floating: true
  },
  tooltip: {
    ...commonTooltipOptions,
    distance: 1,
    padding: 0,
    borderWidth: 0,
    pointFormat: '<b>{point.y}</b>'
  },
  plotOptions: {
    pie: {
      allowPointSelect: false,
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
      minSize: 30,
      size: 35
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  }
}
