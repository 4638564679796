import moment from 'moment'
import numeral from 'numeral'

export default [
  {
    name: 'TotalRevenue',
    format(value, formatOptions) {
      const { current } = formatOptions
      return numeral(value).format(current)
    }
  },
  {
    name: 'TotalCost',
    format(value, formatOptions) {
      const { current } = formatOptions
      return numeral(value).format(current)
    }
  },
  {
    name: 'Expenditure',
    format(value, formatOptions) {
      const { decimal } = formatOptions
      return numeral(value).format(decimal)
    }
  },
  {
    name: 'IMU',
    format(value, formatOptions) {
      const { removeCurrencySymbol } = formatOptions
      return isNaN(value) ? '' : removeCurrencySymbol(value, '0.0')
    }
  },
  {
    name: 'StartDate',
    format(value, formatOptions) {
      const date = moment(value)
      const { DateFormat } = formatOptions
      return date.format(DateFormat)
    }
  },
  {
    name: 'EndDate',
    format(value, formatOptions) {
      const date = moment(value)
      const { DateFormat } = formatOptions
      return date.format(DateFormat)
    }
  },
  {
    name: 'TotalMinPresentation',
    format(value, formatOptions) {
      const { removeCurrencySymbol, current } = formatOptions
      return removeCurrencySymbol(value, current)
    }
  },
  {
    name: 'SizeRangeId',
    // TODO remove unnecessary format funcs
    format(value) {
      return value
    }
  },
  {
    name: 'Sellthrough',
    format(value, formatOptions) {
      const { decimal, removeCurrencySymbol } = formatOptions
      return `${removeCurrencySymbol(value, decimal)} %`
    }
  },
  {
    name: 'NumberOfActiveStores',
    format(value) {
      return value
    }
  },
  {
    name: 'ROS',
    format(value, formatOptions) {
      const { removeCurrencySymbol } = formatOptions
      return isNaN(value) ? '' : removeCurrencySymbol(value, '0.00')
    }
  },
  {
    name: 'Markdown',
    format(value) {
      return `${value} %`
    }
  },
  {
    name: 'PrePackSize',
    format(value, formatOptions) {
      const { removeCurrencySymbol } = formatOptions
      return removeCurrencySymbol(value, '')
    }
  },
  {
    name: 'TotalPrePackQuantity',
    format(value) {
      return value
    }
  },
  {
    name: 'GlobalTotalStock',
    format(value) {
      return value
    }
  }
]
