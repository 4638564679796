Ext.define('Assortment.cmp.Grid', {
  extend: 'Ext.grid.Panel',
  xtype: 'assortment-grid',
  columnLines: true,
  rowLines: true,
  enableLocking: true,
  defaultValue: false,
  viewConfig: {
    enableTextSelection: true
  },
  listeners: {
    cellclick: 'onitemclick',
    cellContextMenu: 'onCellContextMenu',
    headermenucreate: function (grid, menu) {
      var me = this
      menu.add([
        {
          text: 'Sort By',
          xtype: 'menuitem',
          menu: [
            {
              text: 'Name',
              id: 'name',
              xtype: 'menucheckitem',
              checked: !me.defaultValue,
              handler: function () {
                var me = this
                me.up().up().up().sortIndex = 'name'
                me.up().items.items[0].setChecked(!me.defaultValue)
                me.up().items.items[1].setChecked(!!me.defaultValue)
                me.up().items.items[2].setChecked(!!me.defaultValue)
              }
            },
            {
              text: 'Price',
              id: 'price',
              xtype: 'menucheckitem',
              checked: !!me.defaultValue,
              handler: function () {
                var me = this
                me.up().up().up().sortIndex = 'price'
                me.up().items.items[1].setChecked(!me.defaultValue)
                me.up().items.items[0].setChecked(!!me.defaultValue)
                me.up().items.items[2].setChecked(!!me.defaultValue)
              }
            },
            {
              text: 'Approved Option',
              id: 'Editable',
              xtype: 'menucheckitem',
              checked: !!me.defaultValue,
              handler: function () {
                var me = this
                me.up().up().up().sortIndex = 'Editable'
                me.up().items.items[2].setChecked(!me.defaultValue)
                me.up().items.items[1].setChecked(!!me.defaultValue)
                me.up().items.items[0].setChecked(!!me.defaultValue)
              }
            }
          ]
        },
        '-',
        {
          text: 'Confirmed Products',
          id: 'confirmedProducts',
          xtype: 'menucheckitem',
          checked: !me.defaultValue,
          checkHandler: function (e, showConfirmed) {
            const showPlaceHolder = Ext.getCmp('placeHolderProducts').checked
            const showAssignedProducts = Ext.getCmp('assignedProducts').checked
            const showUnassignedProducts = Ext.getCmp('unassignedProducts').checked
            this.showOptions({
              showConfirmed: showConfirmed,
              showPlaceHolder: showPlaceHolder,
              showAssignedProducts: showAssignedProducts,
              showUnassignedProducts: showUnassignedProducts
            })
          }.bind(this)
        },
        {
          text: 'Test Products',
          id: 'placeHolderProducts',
          xtype: 'menucheckitem',
          checked: !me.defaultValue,
          checkHandler: function (e, showPlaceHolder) {
            const showConfirmed = Ext.getCmp('confirmedProducts').checked
            const showAssignedProducts = Ext.getCmp('assignedProducts').checked
            const showUnassignedProducts = Ext.getCmp('unassignedProducts').checked
            this.showOptions({
              showConfirmed: showConfirmed,
              showPlaceHolder: showPlaceHolder,
              showAssignedProducts: showAssignedProducts,
              showUnassignedProducts: showUnassignedProducts
            })
          }.bind(this)
        },
        '-',
        {
          text: 'Assigned Products',
          id: 'assignedProducts',
          xtype: 'menucheckitem',
          checked: !me.defaultValue,
          checkHandler: function (e, showAssignedProducts) {
            const showConfirmed = Ext.getCmp('confirmedProducts').checked
            const showPlaceHolder = Ext.getCmp('placeHolderProducts').checked
            const showUnassignedProducts = Ext.getCmp('unassignedProducts').checked
            this.showOptions({
              showConfirmed: showConfirmed,
              showPlaceHolder: showPlaceHolder,
              showAssignedProducts: showAssignedProducts,
              showUnassignedProducts: showUnassignedProducts
            })
          }.bind(this)
        },
        {
          text: 'Unassigned Products',
          id: 'unassignedProducts',
          xtype: 'menucheckitem',
          checked: !me.defaultValue,
          checkHandler: function (e, showUnassignedProducts) {
            const showConfirmed = Ext.getCmp('confirmedProducts').checked
            const showPlaceHolder = Ext.getCmp('placeHolderProducts').checked
            const showAssignedProducts = Ext.getCmp('assignedProducts').checked
            this.showOptions({
              showConfirmed: showConfirmed,
              showPlaceHolder: showPlaceHolder,
              showAssignedProducts: showAssignedProducts,
              showUnassignedProducts: showUnassignedProducts
            })
          }.bind(this)
        }
      ])

      menu.on({
        beforeshow: function (menu) {}
      })
    },
    sortchange: function (header, column, direction) {
      var me = this
      if (!header.menu) {
        return false
      }
      var isNameChecked = header.menu.items.items[5].menu.items.items[0].checked
      if (!isNameChecked) {
        var store = me.getStore()
        store.sort({
          property: 'BasePrice',
          direction: direction
        })
      }
    }
  },
  columns: [],
  enableColumnMove: false,
  plugins: {
    gridfilters: true
  },
  features: [{ ftype: 'summary', dock: 'top' }],
  cls: 'mainAssortmentGrid',
  showOptions: function ({
    showConfirmed,
    showPlaceHolder,
    showAssignedProducts,
    showUnassignedProducts
  }) {
    const me = this
    const store = me.getStore()

    if (me.productFilter) {
      store.clearFilter(me.productFilter)
    }

    me.productFilter = new Ext.util.Filter({
      id: 'productFilter',
      filterFn: function (record) {
        const {
          data: { IsTestProduct: isTestProduct = false, SegmentOptions: segmentOptions = [] }
        } = record
        const assignedSegments = _.find(segmentOptions, (segmentOption) => {
          if (_.has(segmentOption, 'OriginalEnabled')) {
            return segmentOption['OriginalEnabled']
          } else {
            const { Enabled: enabled = false } = segmentOption || {}
            return enabled
          }
        })

        var res = true
        if (!showConfirmed && !isTestProduct) {
          res = false
        } else if (!showPlaceHolder && isTestProduct) {
          res = false
        } else if (!showAssignedProducts && assignedSegments) {
          res = false
        } else if (!showUnassignedProducts && !assignedSegments) {
          res = false
        }
        return res
      }
    })

    store.addFilter(me.productFilter)
  },
  initComponent: function () {
    var me = this

    me.callParent()
  }
})
