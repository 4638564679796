Ext.define('Assortment.model.AssortmentTreeModel', {
  extend: 'Ext.data.TreeModel',
  fields: [
    {
      name: 'Name'
    },
    {
      name: 'leaf',
      mapping: 'IsLeaf'
    },
    'Id',
    'Status',
    'Description'
  ],
  schema: {
    id: 'assortmentBase',
    namespace: 'ARH.model',
    urlPrefix: Assortment.Global.getApiUrl(),
    proxy: {
      type: 'ajax',
      timeout: 600000,
      // type: 'myapp',
      method: 'POST',
      useDefaultXhrHeader: false,
      cors: true,
      actionMethods: {
        create: 'POST',
        read: 'POST',
        update: 'POST',
        destroy: 'POST'
      },
      api: {
        create: '{prefix}{entityName:lowercase}/create',
        read: '{prefix}{entityName:lowercase}/read',
        update: '{prefix}{entityName:lowercase}/update',
        destroy: '{prefix}{entityName:lowercase}/destroy'
      },
      reader: {
        type: 'json',
        rootProperty: 'Children',
        totalProperty: 'total'
      },
      writer: {
        type: 'json',
        rootProperty: 'data',
        allowSingle: false,
        allDataOptions: {
          associated: false
        },
        writeAllFields: false
      },
      extraParams: {
        mainAppId: null
      }
    }
  },
  listeners: {
    beforeload: function (store) {
      var proxy = store.getProxy()
      proxy.setExtraParam('catalogId', Assortment.Global.getCatalogId())
    }
  }
})
