export default `({
    xtype: 'grid',
    bodyStyle: {
        background: '#ccc',
        //     padding: '2px'
    },
    style: {
        background: 'rgb(209, 211, 214)'
    },
    title: tt('Oneri Listesi [Koli]'),
    margin: 2,
    columnLines: true,
    header: false,
    bind: {
        store: '{gridStore}'
    },
    columns: [{
            text: tt('Name'),
            dataIndex: 'Name',
            width: 120,
            align: 'left',
            locked: true,
            summaryType: 'count',
        },
        {
            text: tt('StatusDescription'),
            dataIndex: 'StatusDescription',
            locked: true,
            width: 120
        },
        {
            text: tt('Store Code'),
            dataIndex: 'StoreCode',
            width: 100,
            align: 'center',
            locked: true
        },
        {
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            hidden: true,
        },
        {
            text: tt('NumberOfstore'),
            columns: [{
                    text: tt('All'),
                    dataIndex: 'NumberofStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                },
                {
                    text: tt('Recommended'),
                    dataIndex: 'NumberofRecommendedStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v, meta, record) {
                        // meta.tdCls = 'sas drilldown';
                        return v;
                    },
                    summaryType: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                }
            ]
        },
        {
            text: tt('Depo Envanter'),
            columns: [{
                text: tt('OnHand'),
                dataIndex: 'OnHandInventoryDC',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryType: 'sum',
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    sumObject = summaryData;;
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }, {
                text: tt('OpenOrder'),
                dataIndex: 'OpenOrderDC',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                summaryType: 'sum',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }]
        },
        {
            text: tt('Inventory_Store'),
            columns: [{
                    text: tt('OnHandInventory'),
                    dataIndex: 'OnHandInventoryStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryType: 'sum',
                    summaryRenderer: function (value, summaryData, dataIndex, context) {
                        if (!value || value === 0) return '<b>-</b>';
                        return '<b>' + Ext.util.Format.number(value, '0,000.00') + '</b>';
                    }
                },
                {
                    text: tt('OpenOrder'),
                    dataIndex: 'OpenOrderStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryRenderer: function (v, summaryData, dataIndex, context) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                }
            ]
        },
        {
            text: tt('OrderRecommendationParent'),
            dataIndex: 'OrderRecommendationParent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            summaryType: 'sum',
            summaryType: function (records, property, root, begin, end) {
                var n = property.length,
                    i, sum, v, sum2
                sum = 0;
                sum2 = 0
                for (i = 0; i < n; ++i) {
                    v = property[i]
                    sum = sum + v;
                    if (records[i].get('IsInclude'))
                        sum2 = sum2 + v;
                }
                return Ext.util.Format.number(sum2, '0,000.00') + " / " + Ext.util.Format.number(sum, '0,000.00');
            },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('OrderRecommendationCurrent'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: 'sum',
            summaryType: function (records, property, root, begin, end) {
                var n = property.length,
                    i, sum, v, sum2
                sum = 0;
                sum2 = 0
                for (i = 0; i < n; ++i) {
                    v = property[i]
                    sum = sum + v;
                    if (records[i].get('IsInclude'))
                        sum2 = sum2 + v;
                }
                return Ext.util.Format.number(sum2, '0,000.00') + " / " + Ext.util.Format.number(sum, '0,000.00');
            },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('OrderRecommendationChild'),
            dataIndex: 'OrderRecommendationChild',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: 'sum',
            summaryType: function (records, property, root, begin, end) {
                var n = property.length,
                    i, sum, v, sum2
                sum = 0;
                sum2 = 0
                for (i = 0; i < n; ++i) {
                    v = property[i]
                    sum = sum + v;
                    if (records[i].get('IsInclude'))
                        sum2 = sum2 + v;
                }
                return Ext.util.Format.number(sum2, '0,000.00') + " / " + Ext.util.Format.number(sum, '0,000.00');
            },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
    ],
})
`
