export default {
  title: 'Iframe',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'Iframe',
  description: 'to open up a full web page in an iframe',
  iconClass: 'fa-crop-alt',
  color: 'navy-bg',
  group: 'Views'
}
