Ext.define('po.cmp.GroupGrid', {
  extend: 'Ext.grid.Panel',
  alias: 'widget.poGroupGrid',
  requires: ['Ext.grid.column.Action', 'Ext.grid.filters.Filters', 'Ext.grid.feature.Grouping'],
  columnLines: true,
  header: false,
  bodyStyle: {
    background: '#fff'
  },
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView(),
      grouping = normalView.getFeature('grouping')
    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    }
  ],
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    },
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      xtype: 'actioncolumn',
      width: 46,
      setTooltip1: function (value) {
        this.items[0].tooltip = value
      },
      setTooltip2: function (value) {
        this.items[1].tooltip = value
      },
      bind: {
        tooltip1: '{i18n.GroupGrid_Column_actionColumn_onFilterFromGroupGrid_tooltip}',
        tooltip2: '{i18n.GroupGrid_Column_actionColumn_onAcceptFromGroupGrid_tooltip}'
      },
      items: [
        {
          handler: 'onFilterFromGroupGrid',
          getClass: function (v, meta, record, rowIndex, columnIndex, store) {
            if (
              (store.store.isFiltered && store.store.isFiltered()) ||
              (store.isFiltered && store.isFiltered())
            ) {
              return 'x-fa fa-filter redIcon'
            } else {
              return 'x-fa fa-filter blueIcon'
            }
          }
        },
        {
          iconCls: 'x-fa fa-check-circle greenIcon',
          handler: 'onAcceptFromGroupGrid'
        }
      ]
    },
    {
      dataIndex: 'Name',
      align: 'left',
      summaryType: 'count',
      width: 150,
      summaryRenderer: function (value, summaryData) {
        var text = this.kayit ? this.kayit : ''
        var sumObject = summaryData
        return Ext.String.format('{0} ' + text, value)
      },
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      },
      setKayit: function (value) {
        this.kayit = value
      },
      bind: {
        text: '{i18n.GroupGrid_Column_Name}',
        kayit: '{i18n.GroupGrid_SummaryRow_text}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      bind: {
        text: '{i18n.GroupGrid_Column_Belge_header}'
      },
      columns: [
        {
          dataIndex: 'OnerilenKayit',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnerilenKayit}',
            tooltip: '{i18n.GroupGrid_Column_OnerilenKayit_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'OnaylananKayit',
          width: 105,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnaylananKayit}',
            tooltip: '{i18n.GroupGrid_Column_OnaylananKayit_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'SAPKayit',
          width: 80,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_SAPKayit}',
            tooltip: '{i18n.GroupGrid_Column_SAPKayit_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'ToplamKayit',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_ToplamKayit}',
            tooltip: '{i18n.GroupGrid_Column_ToplamKayit_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.GroupGrid_Column_Koli_header}'
      },
      columns: [
        {
          dataIndex: 'OnerilenKoli',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnerilenKoli}',
            tooltip: '{i18n.GroupGrid_Column_OnerilenKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'OnaylananKoli',
          width: 105,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnaylananKoli}',
            tooltip: '{i18n.GroupGrid_Column_OnaylananKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'SAPKoli',
          width: 80,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_SAPKoli}',
            tooltip: '{i18n.GroupGrid_Column_SAPKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'ToplamKoli',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_ToplamKoli}',
            tooltip: '{i18n.GroupGrid_Column_ToplamKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.GroupGrid_Column_Tutar_header}'
      },
      columns: [
        {
          dataIndex: 'OnerilenTutar',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnerilenTutar}',
            tooltip: '{i18n.GroupGrid_Column_OnerilenTutar_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'OnaylananTutar',
          width: 105,
          align: 'right',
          summaryType: 'sum',
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnaylananTutar}',
            tooltip: '{i18n.GroupGrid_Column_OnaylananTutar_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'SAPTutar',
          width: 80,
          align: 'right',
          summaryType: 'sum',
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_SAPTutar}',
            tooltip: '{i18n.GroupGrid_Column_SAPTutar_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'ToplamTutar',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000 ₺')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_ToplamTutar}',
            tooltip: '{i18n.GroupGrid_Column_ToplamTutar_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.GroupGrid_Column_Palet_header}'
      },
      columns: [
        {
          dataIndex: 'OnerilenPalet',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnerilenPalet}',
            tooltip: '{i18n.GroupGrid_Column_OnerilenPalet_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'OnaylananPalet',
          width: 105,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_OnaylananPalet}',
            tooltip: '{i18n.GroupGrid_Column_OnaylananPalet_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'SAPPalet',
          width: 80,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_SAPPalet}',
            tooltip: '{i18n.GroupGrid_Column_SAPPalet_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'ToplamPalet',
          width: 100,
          align: 'right',
          summaryType: 'sum',
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_ToplamPalet}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          dataIndex: 'ToplamPaletFiltresiz',
          width: 150,
          align: 'right',
          summaryType: 'sum',
          hidden: true,
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0,000')
          },
          filter: 'number',
          bind: {
            text: '{i18n.GroupGrid_Column_ToplamPaletFiltresiz}'
          }
        }
      ]
    }
  ],
  setData: function (c) {
    var store = this.getStore()
    store.loadData(c)
  }
})
