Ext.define('Spui.plugins.PanelTitleEdit', {
  extend: 'Ext.plugin.Abstract',

  alias: 'plugin.paneltitleedit',

  mixins: {
    observable: 'Ext.util.Observable'
  },

  //public
  constructor: function (config) {
    this.callParent([config])
    this.mixins.observable.constructor.call(this, config)
  },

  init: function (panel) {
    this.panel = panel

    this.mon(this.panel, {
      scope: this,
      afterlayout: this.onAfterLayout,
      single: true
    })
  },

  onAfterLayout: function () {
    this.textEl = this.panel.header.getTitle().textEl

    this.mon(this.textEl, {
      scope: this,
      dblclick: this.onDblClick
    })
  },

  onDblClick: function (event, target) {
    var me = this
    this.titleEditor = new Ext.Editor({
      // update the innerHTML of the bound element
      // when editing completes
      updateEl: true,
      alignment: 'l-l',
      editedItem: me.panel,
      autoSize: {
        width: 'boundEl'
      },
      field: {
        xtype: 'textfield'
      },
      listeners: {
        complete: 'onCompletEdit',
        scope: me
      }
    })
    this.titleEditor.startEdit(target)
  },

  onCompletEdit: function (el, value, startValue, opts) {
    var me = this,
      editedItem = el.editedItem

    if (value == startValue) return
    me.panel.setTitle(value)
    editedItem.updateLayout()
    me.saveChanges(value, startValue)
  },
  saveChanges: function (value, startValue) {
    var me = this,
      locale = me.panel.getLocale(),
      originalValue = me.panel._titleOriginalValue ? me.panel._titleOriginalValue : me.panel.title,
      store = Ext.getStore(me.panel.localeStore),
      localName = '_titleLocale',
      str,
      rec

    if (store && startValue && locale) {
      rec = store.findRecord(locale || 'default', startValue, 0, false, true, true)
      if (rec) {
        rec.set(locale, value)
        rec.save()
      }
    }
  }, // eo save Changes

  destroy: function () {
    var me = this
    if (me.titleEditor) {
      me.titleEditor.destroy()
      delete c.titleEditor
    }
    this.callParent()
  }
})
