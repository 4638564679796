import { memo } from 'react'
import { defaultOption } from '../../../constants'
import { Toption } from '../../../../Common.types'
import RelationLabel from '../../RelationLabel'
import { getSelectedValue } from '../../../helpers'
import { SlvyFormSelect } from '../../../../../../../components'
import { IMenuRowProps } from './MenuRow.types'

const MenuRow = ({
  options,
  paramType,
  paramKey,
  value,
  noRowMarginBottom,
  onChange
}: IMenuRowProps) => {
  const mappedOptions: Toption[] = [defaultOption]
  Object.keys(options).forEach((key) => mappedOptions.push({ value: key, label: key }))

  const result: Toption = getSelectedValue(value, mappedOptions)
  const onChanger = (selectedValue: string) => onChange(selectedValue, paramKey)

  const isNotSelected = result.label === defaultOption.label

  return (
    <span data-testid="menu-row">
      <SlvyFormSelect
        shouldSelectPortal
        columns={{ select: 6, label: 6 }}
        data={mappedOptions}
        label={<RelationLabel isHighlight={isNotSelected} sub={`{${paramType}}`} text={paramKey} />}
        menuPlacement="top"
        noRowMarginBottom={noRowMarginBottom}
        size="sm"
        value={result.value}
        onSelect={onChanger}
      />
    </span>
  )
}

export default memo(MenuRow)
