import {
  useRegisterEventActionButtons,
  useRegisterEventConditionedRowsResult,
  useRegisterEventDataUpdated,
  useRegisterEventDirtyStateChanged,
  useRegisterEventFooterButtons,
  useRegisterEventFormattedRowSelected,
  useRegisterEventGetModifiedRowsResult,
  useRegisterEventMultiRowSelected,
  useRegisterEventMultiRowsSet,
  useRegisterEventOnCellDoubleClicked,
  useRegisterEventRowAdded,
  useRegisterEventRowSelected,
  useRegisterEventTriggerMultiRowSelectedResult
} from '../index'
import { GetRegisterEventsRefProps } from './useGetRegisterEventsRef.types'

function useGetRegisterEventsRef(props: GetRegisterEventsRefProps) {
  const {
    columnConfigs,
    footerButtons,
    fieldConfigs,
    formattedFields,
    getFieldType,
    getFormattedValue,
    gridRef,
    registerEvent
  } = props

  const { actionButtonsRef } = useRegisterEventActionButtons({
    columnConfigs,
    fieldConfigs,
    registerEvent
  })
  const { conditionedRowsResultEventRef } = useRegisterEventConditionedRowsResult({
    fieldConfigs,
    gridRef,
    registerEvent,
    getFieldType
  })
  const { dataUpdatedEventRef } = useRegisterEventDataUpdated({ registerEvent })
  const { dirtyStateChangedEventRef } = useRegisterEventDirtyStateChanged({ registerEvent })
  const { footerButtonsRef } = useRegisterEventFooterButtons({ footerButtons, registerEvent })
  const { formattedRowSelectedEventRef } = useRegisterEventFormattedRowSelected({
    fieldConfigs,
    formattedFields,
    getFormattedValue,
    registerEvent
  })
  const { getModifiedRowsResultEventRef } = useRegisterEventGetModifiedRowsResult({
    fieldConfigs,
    gridRef,
    registerEvent,
    getFieldType
  })
  const { multiRowSelectedEventRef } = useRegisterEventMultiRowSelected({
    fieldConfigs,
    registerEvent,
    getFieldType
  })
  const { multiRowsSetEventRef } = useRegisterEventMultiRowsSet(registerEvent)
  // handleCellClicked for spreadsheet
  const { onCellDoubleClickedRef } = useRegisterEventOnCellDoubleClicked({
    columnConfigs,
    fieldConfigs,
    registerEvent
  })
  const { rowAddedEventRef } = useRegisterEventRowAdded(registerEvent)
  const { rowSelectedEventRef } = useRegisterEventRowSelected({
    fieldConfigs,
    registerEvent,
    formattedRowSelectedEventRef
  })
  const { triggerMultiRowSelectedResultEventRef } = useRegisterEventTriggerMultiRowSelectedResult({
    fieldConfigs,
    registerEvent,
    getFieldType,
    gridRef
  })

  return {
    actionButtonsRef,
    conditionedRowsResultEventRef,
    dataUpdatedEventRef,
    dirtyStateChangedEventRef,
    footerButtonsRef,
    formattedRowSelectedEventRef,
    getModifiedRowsResultEventRef,
    multiRowSelectedEventRef,
    multiRowsSetEventRef,
    onCellDoubleClickedRef,
    rowAddedEventRef,
    rowSelectedEventRef,
    triggerMultiRowSelectedResultEventRef
  }
}

export default useGetRegisterEventsRef
