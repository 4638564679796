import numeral from 'numeral'
import { isEmpty } from 'lodash'
import cx from 'classnames'
import { useTheme } from '../../contexts/ThemeContext'
import { AggregationRowProps } from './AggregationRow.types'

function getAggregation(type, pred, curr, index, length) {
  if (type === 'count') {
    pred = pred === undefined ? 0 : pred
    return pred + 1
  }

  if (type === 'sum') {
    pred = pred === undefined ? 0 : pred
    return pred + Number(curr)
  }

  if (type === 'min') {
    pred = pred === undefined ? Number.MAX_SAFE_INTEGER : pred

    return Math.min(pred, curr)
  }

  if (type === 'max') {
    pred = pred === undefined ? Number.MIN_SAFE_INTEGER : pred

    return Math.max(pred, curr)
  }

  if (type === 'avg') {
    pred = pred === undefined ? 0 : pred
    const value = index === length - 1 ? (pred + Number(curr)) / length : pred + Number(curr)
    return value
  }

  if (type === 'skip') {
    return pred
  }
}

function getAggregations(rows) {
  const aggregations = {}
  const rowLength = rows.length
  const expandableRowLength = rows.filter((row) => row.getCanExpand()).length
  for (let i = 0; i < rowLength; i += 1) {
    const isRowExpandable = rows[i].getCanExpand()
    const visibleCells = rows[i].getVisibleCells()
    const visibleCellsLength = visibleCells.length
    for (let j = 0; j < visibleCellsLength; j += 1) {
      const cell = visibleCells[j]
      const aggregation = cell.column.columnDef.meta?.aggregation ?? ''
      const aggregationLabel = cell.column.columnDef.meta?.aggregationLabel ?? ''
      const aggregationWithoutExpandableRows =
        cell.column.columnDef.meta?.aggregationWithoutExpandableRows ?? false

      const aggregationType =
        isRowExpandable && aggregationWithoutExpandableRows ? 'skip' : aggregation
      const aggregationPredValue = aggregations?.[cell.column.id]?.value
      const cellValue = cell.getValue()
      const index = aggregationWithoutExpandableRows ? i - expandableRowLength : i
      const length = aggregationWithoutExpandableRows ? rowLength - expandableRowLength : rowLength
      const value = getAggregation(aggregationType, aggregationPredValue, cellValue, index, length)

      const customAggregationFn = cell.column.columnDef.meta?.customAggregationFn
      const format = cell.column.columnDef.meta?.format
      const size = cell.column.getSize()
      const isPinned = cell.column.getIsPinned()

      aggregations[cell.column.id] = {
        type: aggregation,
        label: aggregationLabel,
        value,
        format,
        customAggregationFn,
        style: { width: size, minWidth: size },
        isPinned
      }
    }
  }

  return aggregations
}

export default function AggregationRow({ rows, columns }: AggregationRowProps) {
  const styles = useTheme()
  const aggregations = getAggregations(rows)

  return (
    <div className={styles.aggregationRow}>
      {Object.values(
        !isEmpty(aggregations)
          ? aggregations
          : columns?.reduce(
              (accumulator, current) =>
                Object.assign(accumulator, {
                  [current.accessorKey]: {
                    type: current.meta?.aggregation ?? '',
                    value: current.meta?.aggregation ? 0 : '',
                    label: current.meta?.aggregationLabel ?? '',
                    format: current.meta?.format ?? ''
                  }
                }),
              {}
            )
      ).map((aggregation, index) => {
        const { type, value, label, format, customAggregationFn, style, isPinned } = aggregation

        return (
          <div
            key={index}
            className={cx(styles.aggregationCell, { [styles.isPinned]: isPinned })}
            style={style}
          >
            {label ? <span className="text-muted">{label} </span> : null}
            {customAggregationFn ? (
              <span>{customAggregationFn(aggregations)}</span>
            ) : (
              <span>{format && type !== '' ? numeral(value).format(format) : value}</span>
            )}
          </div>
        )
      })}
    </div>
  )
}
