export default `({
    plugins: [{
        ptype: 'rowexpander',
        rowBodyTpl: [
            '<div class="expander">',
            '<table class="table">',
            '<tr>',
            '</tr>',
            '  <tr>',
            '       <th class=r>Solvoyo</th>',
            '      <td class="r">{OrderRecommendationParent}</td>',
            '       <th class=r>'+tt('Merkez')+'</th>',
            '      <td class="r">{OrderRecommendationCurrent}</td>',
            '      <th class="r">' + tt('Bölge') + '</th>',
            '      <td class="r">{OrderRecommendationChild}</td>',
            // '   </tr>',
            // '  <tr>',
            // '       <th class=r>NumberOf Org</th>',
            // '      <td class="r">{NumberOfRecommendedOrg}</td>',
            // '     <th class="r">Waiting Org</th>',
            // '      <td class="r">{NumberOfWaitingOrg}</td>',
            // '       <th class="r"> Delegated</th>',
            // '      <td class="r">{NumberOfDelegatedOrg}</td>',
            // '   </tr>',
            // '</tr>',
            // '  <tr>',
            // '      <th class="r"> Approved </td>',
            // '      <td class="r">{NumberOfApprovedOrg}</td>',
            // '      <th class="r">Dismissed</td>',
            // '      <td class="r" >{NumberOfDismissedOrg}</td>',
            // '   </tr>',
            '</table>',
            '</div>',
        ]
    },
    {
        ptype: 'gridfilters',
        id: 'gridfilters'
    }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    multiColumnSort: true,
    columnLines: true,
    columns: [{
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            renderer: function (v) {
                return v * 1;
            },
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('Start Date'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('End Date'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('Sözleşme Tarihi'),
            dataIndex: 'SozlesmeTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('VPER_START'),
            dataIndex: 'AfisBaslangicTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('VPER_END'),
            dataIndex: 'AfisBitisTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },
        {
            text: tt('Beklenen Ciro'),
            dataIndex: 'BeklenenCiro',
            width: 100,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('Toplam Adet'),
            dataIndex: 'ToplamAdet',
            width: 100,
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        }, {
            text: tt('VendorCode'),
            dataIndex: 'TedarikciKodu',
            width: 100,
            renderer: function (v) {
                return v * 1;
            },
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('Tedarikçi Adı'),
            dataIndex: 'VendorName',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('TeslimatTarihi'),
            dataIndex: 'TeslimatTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('Kategori Grubu'),
            dataIndex: 'CategoryGroup',
            
            filter: {
                type: 'string'
            },
            width: '90'
        }, {
            text: tt('Sözleşme Numarası'),
            dataIndex: 'ContractNo',
            renderer: function (v) {
                return v * 1;
            },
            width: '90',
            filter: {
                type: 'string'
            }
        }, {
            text: tt('Entegrasyon Statusü'),
            dataIndex: 'EntegrasyonStatusu',
            
            filter: {
                type: 'string'
            },
            width: '90',
            renderer: function (v) {
                if(v==0)
                {
                    return tt('Pending')
                }else if(v==2)
                {
                    return tt('Success')
                }else if(v==3)
                {
                    return tt('Error')
                }
                else if (v==4){
                    return tt('Completed')
                }
                else {
                    return v
                }
                
            },
        }, {
            text: tt('Hata Mesajı'),
            dataIndex: 'HataMesaji',
            filter: {
                type: 'string'
            },
            width: '90'
        },
        {
            text: tt('Açılan SAS %'),
            dataIndex: 'NumberOfOrgwithPO',
            filter: {
                type: 'number'
            },
            width: '90',
            renderer: function (value, metaData, record) {
                if (!value || value === 0) return '';
                value = Ext.util.Format.number(value, '0.00');
                return value +' %'
            }
        }, {
            text: tt('Teslimat Miktarı %'),
            dataIndex: 'NumberOfOrgDelivered',
            filter: {
                type: 'number'
            },
            width: '90',
            renderer: function (value, metaData, record) {
                if (!value || value === 0) return '';
                value = Ext.util.Format.number(value*100, '0');
                return value +' %'
            }
        }, {
            text: tt('Dağıtım Miktarı %'),
            dataIndex: 'NumberOfOrgDistributed',
            filter: {
                type: 'number'
            },
            width: '90',
            renderer: function (value, metaData, record) {
                if (!value || value === 0) return '';
                value = Ext.util.Format.number(value, '0.00');
                return value +' %'
            }
        }, {
            text: tt('Teslimat Miktarı'),
            dataIndex: 'TeslimatMiktari',
            filter: {
                type: 'number'
            },
            width: '90'
        },{
            text: tt('AcilanSas'),
            dataIndex: 'AcilanSas',
            filter: {
                type: 'number'
            },
            width: '90'
        },
        {
            text: tt('CreatedBy'),
            dataIndex: 'CreatedBy',
            filter: {
                type: 'string'
            },
            width: 140
        }
    ],
    onChangeStatus: function (record, newStatus, me) {
        var win = new Ext.Window({
            title: tt('Tarih Onayi'),
            modal: true,
            bscope: me,
            layout: 'fit',
            items: [{
                xtype: 'form',
                itemId: 'myForm',
                autoheight: true,
                width: 350,
                margin: 1,
                padding: 5,
                border: false,
                //unstyled: true,
                items: [{
                    xtype: 'label',
                    text: tt('Değerlendirmeye yollamak istediğinizden emin misiniz?'),
                    height: 20
                }, ],
            }],
            bbar: [
                '->',
                {
                    text: tt('Değerlendirmeye geri dön'),
                    handler: function (a, b, c) {
                        var w = a.up('window');
                        me = w.bscope;
                        w.close();
                        var data = Ext.encode(record.data);
                                             me.setLoading(tt('yükleniyor'));
                        Ext.Ajax.request({
                            url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                            method: 'POST',
                            params: {
                                data: data,
                                newStatus: newStatus
                            },
                            success: function (response, opts) {
                                me.setLoading(false);
                                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            },
                            failure: function (response, opts) {
                                console.log('server-side failure with status code ' + response.status);
                            }
                        });
                    }
                },
                {
                    text: tt('Cancel'),
                    handler: function (a, b, c) {
                        a.up('window').close();
                    }
                }
            ]
        });
        win.show();
    },
})
`
