export const coverColors = {
  red: '#FF0066',
  orange: '#FFA200',
  green: '#00ffa8',
  darkGreen: '#309875',
  purple: '#A020F0',
  gray: '#808080',
  white: '#fff'
}

export const rowColors = {
  white: '#fff',
  lightGray: '#f1f1f1'
}

export const dataMap = {
  productView: {
    constructor: 'TableProduct',
    getRequest: 'GetRccpProductPeriodResponse'
  },
  resourceView: {
    constructor: 'TableResource',
    getRequest: 'GetRccpResourcePeriodResponse'
  },
  scheduleResourceView: {
    constructor: 'ScheduleResource',
    getRequest: 'GetRccpResourceActivityResponseWPF'
  },
  scheduleLineView: {
    constructor: 'ScheduleLine',
    getRequest: 'GetRccpResourceLineResponse'
  },
  filteredProduct: {
    constructor: 'TableFilteredProduct',
    getRequest: 'GetRccpProductPeriodResponse'
  },
  newResourcePlanView: {
    constructor: 'NewResourcePlanView',
    getRequest: 'GetRccpResourcePeriodHierarchyResponseWPF'
  }
}

export const moveExceptions = {
  2: {
    code: 2,
    getMessage(description) {
      return description
    }
  },
  1: {
    code: 1,
    getMessage(products, productId) {
      const { SKU = '' } = products.find((product) => product.Id === productId) ?? {}
      return `Product (${SKU}) is ok!`
    }
  },
  0: {
    code: 0,
    getMessage(description) {
      return description
    }
  },
  '-1': {
    code: -1,
    getMessage(sourceResourceName) {
      return `Source resource (${sourceResourceName}) not found!`
    }
  },
  '-2': {
    code: -2,
    getMessage(sourcePeriodName) {
      return `Source period (${sourcePeriodName}) not found!`
    }
  },
  '-3': {
    code: -3,
    getMessage(destResourceName) {
      return `Destination resource (${destResourceName}) not found!`
    }
  },
  '-4': {
    code: -4,
    getMessage(destPeriodName) {
      return `Destination period (${destPeriodName}) not found!`
    }
  },
  '-5': {
    code: -5,
    getMessage(productId) {
      const { SKU = '' } = products.find((product) => product.Id === productId) ?? {}
      return `Product (${SKU}) with id ${productId} not found!)`
    }
  },
  '-6': {
    code: -6,
    getMessage() {
      return `Source and destination are the same!`
    }
  },
  '-10': {
    code: -10,
    getMessage(destResourceName, destPeriodName) {
      return `Not enough capacity at ${destResourceName} - ${destPeriodName}!`
    }
  },
  '-101': {
    code: -101,
    getMessage(products, productId) {
      const { SKU = '' } = products.find((product) => product.Id === productId) ?? {}
      return `Product (${SKU}) with id ${productId} not found!`
    }
  },
  '-102': {
    code: -102,
    getMessage(products, productId) {
      const { SKU = '' } = products.find((product) => product.Id === productId) ?? {}
      return `Product (${SKU}) Engine ItemGroup info not found!`
    }
  },
  '-103': {
    code: -103,
    getMessage(products, productId) {
      const { SKU = '' } = products.find((product) => product.Id === productId) ?? {}
      return `Product (${SKU}) has no production!`
    }
  },
  '-110': {
    code: -110,
    getMessage(sourceResourceName, sourcePeriodName) {
      return `Resource period (${sourceResourceName} - ${sourcePeriodName}) production BOR info not found!`
    }
  },
  '-111': {
    code: -111,
    getMessage(destResourceName, destPeriodName) {
      return `Resource period (${destResourceName} - ${destPeriodName}) production BOR info not found!`
    }
  }
}

export const shortcuts = {
  toggleLogging: 'ctrl+shift+l'
}
