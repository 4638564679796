import React from 'react'
import numeral from 'numeral'
import { confirmAlert } from 'react-confirm-alert'
import { MESSAGES } from '../../../messages'
import Row from './Row/index'
import {
  IFormats,
  IScenarioDataItem,
  IScenarioListItem,
  IScenarioTableProps,
  ISelectedMetricField,
  ISummaries
} from './ScenarioTable.types'

const formats: IFormats = {
  staticFormat: '0.00',
  millionFormat: '0.0a'
}

const defaultScenario: IScenarioListItem = {
  ApproveAllowed: false,
  Id: -99999,
  IsActualized: false,
  Name: '',
  ScenarioTableName: '',
  UpdateAllowed: false
}

export default function ScenarioTable(props: IScenarioTableProps) {
  const {
    activeChildrenFilter,
    ScenarioData,
    isMillion,
    onNodeAction,
    onToggleScenariosWithChildren,
    onFilterScenariosChildren,
    onUpdate,
    shouldShowConfirmApprovedCellUpdate
  } = props

  function _onUpdate(
    isApproved: number,
    isComparedScenario: boolean,
    ComparedScenarioId: null | number,
    params: object,
    event: any
  ) {
    const { target, target: { value = '' } = {}, keyCode } = event

    if (keyCode === 13 && value !== '') {
      const NewValue = numeral(value).value()

      if (isApproved === 1 && shouldShowConfirmApprovedCellUpdate) {
        confirmAlert({
          title: MESSAGES.approve_confirmation,
          message: MESSAGES.are_you_sure_you_want_to_edit_the_already_approved_row,
          buttons: [
            {
              label: MESSAGES.cancel,
              onClick: () => {}
            },
            {
              label: MESSAGES.confirm,
              onClick: () => {
                onUpdate({ ...params, NewValue }, isComparedScenario, ComparedScenarioId)
                addLoadingClass(target)
              }
            }
          ]
        })
      } else {
        onUpdate({ ...params, NewValue }, isComparedScenario, ComparedScenarioId)
        addLoadingClass(target)
      }
    }
  }

  function addLoadingClass(target: HTMLInputElement) {
    if (target) {
      const parent = target.parentElement as HTMLDivElement
      parent.classList.add('-mini-loading')
    }
  }

  function getRatio(
    Summaries: ISummaries,
    Format: string,
    isPercentage: boolean,
    defaultMetricField: ISelectedMetricField
  ): string {
    const activeMetric = Summaries[`${defaultMetricField.Name}_Summary`]
    const { PercentageDiff = 0, AbsoluteDiff = 0 } = activeMetric || {}
    const result = isPercentage
      ? `${numeral(PercentageDiff).format(Format)} %`
      : numeral(AbsoluteDiff).format(Format)
    const ratio = result.replace(',', '.')
    return ratio
  }

  function getScenarioById(
    ScenarioList: IScenarioListItem[],
    scenarioId: number
  ): IScenarioListItem {
    const foundScenario = ScenarioList.find((item) => item.Id === scenarioId)
    if (typeof foundScenario !== 'undefined') {
      return foundScenario
    }
    return defaultScenario
  }

  function _onNodeAction(
    params: object,
    isComparedScenario: boolean,
    ComparedScenarioId: null | number
  ) {
    onNodeAction(params, isComparedScenario, ComparedScenarioId)
  }

  function getSortedSummariesData(
    selectedMetricFields: ISelectedMetricField[],
    Summaries: ISummaries
  ) {
    const selectedMetricFieldsNames = selectedMetricFields.map(
      (selectedMetricField) => selectedMetricField.Name
    )
    return Object.keys(Summaries)
      .map((summaryItemKey) => {
        const Name = Summaries[summaryItemKey].Name.replace('_Summary', '')
        Summaries[summaryItemKey].Name = Name
        return Summaries[summaryItemKey]
      })
      .sort((prev, next) => {
        return (
          selectedMetricFieldsNames.indexOf(prev.Name) -
          selectedMetricFieldsNames.indexOf(next.Name)
        )
      })
  }

  const {
    BaselineScenarioId,
    GroupedSelectedIndex,
    ScenariosWithChildren,
    activeScenarioList,
    areVisibleRatiosValues = true,
    buttonFormat,
    isPercentage = true,
    isRowClickedEventOn,
    isScenarioEditable,
    isVisibleRatioValue = true,
    isVisibleSplitValue = true,
    onRowClicked,
    selectedMetricFields,
    viewMode,
    missingRowLockMessage,
    visibleScenarioTab = '',
    defaultMetricField = { Name: '' },
    currenciesOptions: { decimalScale, thousandSeparator, decimalSeparator }
  } = props

  const isEditMode = viewMode === 2

  const isWithBaseVisible = visibleScenarioTab === 'WithBase'
  const isWithGroupedVisible = visibleScenarioTab === 'WithGrouped'
  const isWithPreviousVisible = visibleScenarioTab === 'WithPrevious'

  const hasAnyUpdatableMetric =
    selectedMetricFields.filter((item) => item.IsUpdateAllowed).length > 0

  const getScenarioUpdateAllowedById = (scenarioId: number) => {
    return activeScenarioList.find((item) => item.Id === scenarioId)?.UpdateAllowed ?? false
  }

  const getMetricFormat = ($metricName: string, getMillion = true) => {
    if (getMillion && isMillion) {
      return formats.millionFormat
    }
    return selectedMetricFields.find((item) => item.Name === $metricName)?.Format ?? '0,0.00'
  }

  return (
    <div className="slvy-ui-table-section">
      {ScenarioData.map((row: IScenarioDataItem, rowIndex: number) => {
        const { ScenarioId, Fields, Summaries, ComparedScenarioId, Children = [] } = row
        const isFirstRow = rowIndex === 0
        const currentSummary = getRatio(
          Summaries,
          formats.staticFormat,
          isPercentage,
          defaultMetricField
        )
        // @ts-ignore
        const fixCurrentSummary = parseFloat(currentSummary, 10)

        const scenario = getScenarioById(activeScenarioList, ScenarioId)

        const inGroupClass =
          isWithGroupedVisible && GroupedSelectedIndex <= rowIndex ? '-inGroup' : ''
        const inBaselineClass = isWithBaseVisible ? '-inBaseline' : ''
        const inPreviousClass = isWithPreviousVisible ? '-inPrevious' : ''
        const titleClass = `${inGroupClass} ${inBaselineClass} ${inPreviousClass}`

        const summariesData = getSortedSummariesData(selectedMetricFields, Summaries)

        return (
          <Row
            key={rowIndex}
            BaselineScenarioId={BaselineScenarioId}
            Children={Children}
            ComparedScenarioId={ComparedScenarioId}
            Fields={Fields}
            GroupedSelectedIndex={GroupedSelectedIndex}
            ScenarioId={ScenarioId}
            ScenariosWithChildren={ScenariosWithChildren}
            activeChildrenFilter={activeChildrenFilter}
            activeScenarioList={activeScenarioList}
            areVisibleRatiosValues={areVisibleRatiosValues}
            buttonFormat={buttonFormat}
            currentSummary={currentSummary}
            decimalScale={decimalScale}
            decimalSeparator={decimalSeparator}
            defaultMetricField={defaultMetricField}
            fixCurrentSummary={fixCurrentSummary}
            formats={formats}
            getMetricFormat={getMetricFormat}
            getRatio={getRatio}
            getScenarioById={getScenarioById}
            getScenarioUpdateAllowedById={getScenarioUpdateAllowedById}
            getSortedSummariesData={getSortedSummariesData}
            hasAnyUpdatableMetric={hasAnyUpdatableMetric}
            isChildrenFiltered={false}
            isEditMode={isEditMode}
            isFirstRow={isFirstRow}
            isHidden={false}
            isMillion={isMillion}
            isNested={false}
            isPercentage={isPercentage}
            isRowClickedEventOn={isRowClickedEventOn}
            isScenarioEditable={isScenarioEditable}
            isVisibleRatioValue={isVisibleRatioValue}
            isVisibleSplitValue={isVisibleSplitValue}
            isWithBaseVisible={isWithBaseVisible}
            isWithGroupedVisible={isWithGroupedVisible}
            rowIndex={rowIndex}
            scenario={scenario}
            missingRowLockMessage={missingRowLockMessage}
            selectedMetricFields={selectedMetricFields}
            summariesData={summariesData}
            thousandSeparator={thousandSeparator}
            titleClass={titleClass}
            onFilterScenariosChildren={onFilterScenariosChildren}
            onNodeAction={_onNodeAction}
            onRowClicked={onRowClicked}
            onToggleScenariosWithChildren={onToggleScenariosWithChildren}
            onUpdate={_onUpdate}
          />
        )
      })}
    </div>
  )
}
