Ext.define('clustering.SegmentTree', {
  extend: 'Ext.tree.Panel',
  alias: 'widget.segmentTree',
  reference: 'segmentTree',
  rootVisible: false,
  width: 150,
  allowDeselect: true,
  droppedRecords: undefined,
  hideCollapseTool: true,
  cls: 'segmentTree clustering-details-grid',
  config: {
    store: null
  },
  bind: {
    store: '{segmentStore}'
  },
  viewConfig: {
    plugins: {
      ptype: 'treeviewdragdrop',
      dropGroup: 'SegmentDropGroup',
      enableDrag: false,
      appendOnly: true
    },
    listeners: {
      beforedrop: function (node, data, overModel, dropPos, opts) {
        this.droppedRecords = data.records
        data.records = []
        opts.cancelDrop()

        var clusterId = overModel.get('Id')
        var clusterName = overModel.get('Name')

        var clusterView = this.up().up().up().up()
        var references = clusterView.getReferences()
        if (references.scatter3d && references.scatter3d.grid3DViewControl) {
          references.scatter3d.grid3DViewControl = null
        }

        this.droppedRecords.forEach(function (record) {
          var store = record.store
          record.set('ClusterID', clusterId)
        }, this)

        this.droppedRecords = undefined
      }
    }
  },
  plugins: [
    {
      ptype: 'cellediting',
      clicksToEdit: 2
    }
  ],
  columns: [
    {
      xtype: 'treecolumn',
      dataIndex: 'Name',
      text: 'Segments',
      sortable: false,
      flex: 1,
      renderer: function (value, cell, record) {
        cell.style = 'color:' + record.data.Color + ';'
        return value
      },
      editor: {
        xtype: 'textfield',
        allowBlank: false,
        allowOnlyWhitespace: false,
        maxLength: 100
      }
    }
  ],
  listeners: {
    selectionChange: function (model, selected, opts) {
      var me = this
      var grid = me.getGrid()

      if (grid) {
        grid.filterStores(selected && selected[0] ? selected[0].get('Id') : -1)
      }

      this.ownerCt
        .lookupReferenceHolder()
        .getViewModel()
        .set('currentSegment', selected && selected[0] ? selected[0].get('Id') : null)
    },
    validateedit: function (editor, context) {
      return context.value != context.originalValue
    },
    edit: function (editor, context, eOpts) {
      var me = this

      var currentRow = context.record
      currentRow.set(context.field, context.value)

      var view = this.getView()
      setTimeout(function () {
        me.store.fireEvent('update')
        view.refresh()
      }, 100)
    }
  },
  getGrid: function () {
    var me = this
    return me.lookupReferenceHolder().getReferences()['storeGrid']
  }
})
