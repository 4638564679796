export default {
  title: '3D Scatter',
  subTitle: 'Highcharts',
  version: '1.0',
  namespace: '3D Scatter',
  description: '3D Scatter from Highcharts (www.highcharts.com)',
  iconClass: 'fa-cube',
  color: 'lazur-bg',
  support: {
    maximizable: true
  },
  group: 'Charts'
}
