import React, { memo } from 'react'
import isEqual from 'lodash/isEqual'
import numeral from 'numeral'
import { IProps } from './MetricDiffs.types'
import {
  currentGrowthValueStyle,
  metricItemValueStyle,
  ratioLimitStyle,
  ratioLimitValueStyle
} from './style'

function MetricDiffs({
  getMetricFormat,
  isFirstRow,
  isMillion,
  isPercentage,
  isVisibleRatioValue,
  metrics,
  millionFormat,
  staticFormat,
  style
}: IProps) {
  function getGrowthValue(
    metricPercentageDiff: number,
    metricAbsoluteDiff: number,
    Name: string | null
  ): string {
    if (isPercentage) {
      if (metricPercentageDiff === 0) {
        return '-'
      }
      return numeral(metricPercentageDiff).format(staticFormat).replace(',', '.')
    }

    const currentMetricFormat = getMetricFormat(Name)
    return numeral(metricAbsoluteDiff).format(isMillion ? millionFormat : currentMetricFormat)
  }

  return (
    <div className="values common diff" style={style}>
      {metrics.map((metricItem, metricIndex) => {
        const {
          PercentageDiff: metricPercentageDiff,
          AbsoluteDiff: metricAbsoluteDiff,
          Name = ''
        } = metricItem

        const currentGrowthValue = getGrowthValue(metricPercentageDiff, metricAbsoluteDiff, Name)

        return (
          <div key={`metric-${metricIndex}`} className="value" style={metricItemValueStyle}>
            {isVisibleRatioValue && !isFirstRow ? (
              <div className="ratio- -limit-value" style={ratioLimitStyle}>
                <div
                  className="value"
                  style={ratioLimitValueStyle}
                  title={currentGrowthValue.length > 6 ? currentGrowthValue : ''}
                >
                  <span style={currentGrowthValueStyle}>
                    {currentGrowthValue}
                    {isPercentage && currentGrowthValue !== '-' ? '%' : ''}
                  </span>
                </div>
                {isPercentage ? (
                  <div
                    className={`icon -${
                      metricPercentageDiff === 0
                        ? 'equal'
                        : metricPercentageDiff < 0
                        ? 'down'
                        : 'up'
                    }`}
                  >
                    <span
                      className={`slvy-ui-icon-caret-${
                        metricPercentageDiff === 0
                          ? 'equal'
                          : metricPercentageDiff < 0
                          ? 'down'
                          : 'top'
                      }`}
                    />
                  </div>
                ) : (
                  <div
                    className={`icon -${
                      metricAbsoluteDiff === 0 ? 'equal' : metricAbsoluteDiff < 0 ? 'down' : 'up'
                    }`}
                  >
                    <span
                      className={`slvy-ui-icon-caret-${
                        metricAbsoluteDiff === 0 ? 'equal' : metricAbsoluteDiff < 0 ? 'down' : 'top'
                      }`}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

export default memo(MetricDiffs, isEqual)
