export default {
  title: 'ColorPalettes',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'ColorPalettes',
  description: '',
  iconClass: 'fa-paint-brush',
  color: 'lazur-bg',
  group: 'Views'
}
