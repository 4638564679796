import React, { useState, useEffect } from 'react'

function useNetwork(onChange) {
  const [network, setNetwork] = useState({ isOnline: navigator.onLine })

  useEffect(() => {
    window.addEventListener('online', handleChange)
    window.addEventListener('offline', handleChange)
    return () => {
      window.removeEventListener('online', handleChange)
      window.removeEventListener('offline', handleChange)
    }
  }, [])

  function handleChange() {
    const params = { isOnline: navigator.onLine }
    if (typeof onChange === 'function') {
      onChange(params)
    }
    setNetwork(params)
  }

  return network
}

export default useNetwork
