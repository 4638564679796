import React, { Component } from 'react'
import _ from 'lodash'

const REASON_HEIGHT = 30

export default class EditReason extends Component {
  constructor() {
    super()
    this.state = {
      cursor: 0,
      reasons: [],
      reasonsLength: 0,
      selectedReason: ''
    }

    this.editReasonRef = null

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.handleEscape = this.handleEscape.bind(this)
    this.handleArrowUp = this.handleArrowUp.bind(this)
    this.handleArrowDown = this.handleArrowDown.bind(this)
  }

  componentDidMount() {
    const {
      data: { reasons } = {},
      position: { left = 0, width = 0, top = 0 } = {},
      isPortal
    } = this.props

    const reasonsLength = reasons.length
    const editReasonStyles = {
      left: isPortal ? left - 110 + width / 2 : left - 185,
      top: isPortal ? top + window.pageYOffset - 136 : top - 151
    }

    this.setState({ reasons, reasonsLength, editReasonStyles }, () => {
      window.addEventListener('keydown', this.handleKeyDown)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleEnter(event) {
    const { handler } = this.props
    const { cursor, reasons, selectedReason } = this.state

    selectedReason
      ? handler(event, true, selectedReason)
      : this.setState({ selectedReason: reasons[cursor] })
  }

  handleEscape(event) {
    const { handler } = this.props

    handler(event, false, null)
  }

  handleArrowUp() {
    const { cursor, reasons, reasonsLength } = this.state

    this.setState(
      (prevState) => ({
        cursor: prevState.cursor - 1,
        selectedReason: reasons[prevState.cursor - 1]
      }),
      () => {
        if (reasonsLength - cursor > 3) {
          this.editReasonRef.scrollTop = this.editReasonRef.scrollTop - REASON_HEIGHT
        }
      }
    )
  }

  handleArrowDown() {
    const { cursor, reasons } = this.state

    this.setState(
      (prevState) => ({
        cursor: prevState.cursor + 1,
        selectedReason: reasons[prevState.cursor + 1]
      }),
      () => {
        if (cursor > 2) {
          this.editReasonRef.scrollTop = this.editReasonRef.scrollTop + REASON_HEIGHT
        }
      }
    )
  }

  handleKeyDown(event) {
    const { keyCode } = event
    const { cursor, reasonsLength } = this.state

    const isEnter = keyCode === 13
    const isEscape = keyCode === 27
    const isArrowUp = keyCode === 38 && cursor > 0
    const isArrowDown = keyCode === 40 && cursor < reasonsLength - 1

    if (isEnter) {
      this.handleEnter(event)
    } else if (isEscape) {
      this.handleEscape(event)
    } else if (isArrowUp) {
      this.handleArrowUp(event)
    } else if (isArrowDown) {
      this.handleArrowDown(event)
    }
  }

  handleClick(flag, reason, event) {
    const { handler = () => {} } = this.props

    handler(event, flag, reason)
  }

  render() {
    const {
      data: { isReasonActive },
      isPortal,
      handler
    } = this.props
    const { cursor, reasons, editReasonStyles } = this.state

    if (!isReasonActive) {
      handler(null, null, false)
    }

    return (
      isReasonActive && (
        <div className={`edit-reason ${!isPortal ? 'is-not-portal' : ''}`}>
          <div className="reason-list-wrapper" style={editReasonStyles}>
            <ul ref={(element) => (this.editReasonRef = element)} className="reason-list">
              {_.map(reasons, (reason, index) => {
                const { name = '' } = reason

                reason = { ...reason, code: index }

                return (
                  <li
                    key={`${name}-${index}`}
                    className={`reason ${cursor === index ? 'is-cursor' : ''}`}
                    onClick={this.handleClick.bind(this, true, reason)}
                    onKeyDown={this.handleKeyDown}
                  >
                    <span>{name}</span>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="reason-overlay" onClick={this.handleClick.bind(this, false, null)} />
        </div>
      )
    )
  }
}
