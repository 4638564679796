/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */

import { Component, createRef } from 'react'
import { first, get, isArray, isEqual, isNil } from 'lodash'
import { Editor } from '@toast-ui/react-editor'
import { v4 as uuidv4 } from 'uuid'
import { slvyToast } from '@/components'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import ControlPanel from './components/ControlPanel'

import './index.scss'
import '@toast-ui/editor/dist/toastui-editor.css'

class MarkDownEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDisabled: false,
      visibility: {
        preview: true,
        previewHelp: false
      }
    }

    this.editorRef = createRef()
  }

  getDataWithColumn = () => {
    let pluginData = []
    if (isArray(this.props.pluginData)) {
      pluginData = this.props.pluginData
    }

    const textColumn = get(this.props, 'settings.config.data.textColumn', '')

    return {
      pluginData,
      textColumn
    }
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'saveChanges',
      fn: this.handleSaveChanges,
      args: [{ name: 'refreshKey', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setReadOnlyEnabled',
      fn: this.setReadOnlyEnabled.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setReadOnlyDisabled',
      fn: this.setReadOnlyDisabled.bind(this),
      args: []
    })

    this.handleDataUpdated = this.props.registerEvent({
      key: 'dataUpdated',
      fn: this.handleDataUpdated,
      returnTypes: { refreshKey: PluginTypes.string }
    })
  }

  componentDidUpdate(prevProps) {
    const { pluginData, textColumn } = this.getDataWithColumn()

    const editor = this.editorRef?.current?.getInstance()

    if (!pluginData.length) {
      editor.setMarkdown('')
      return
    }
    if (pluginData.length > 0 && !isEqual(prevProps.pluginData, pluginData)) {
      const { [textColumn]: text = '' } = first(pluginData) || {}
      const editorValue = isNil(text) ? '' : text.replace(/\\n/g, '\n')

      editor.setMarkdown(editorValue)
    }
  }

  handleSaveChanges = () => {
    const { pluginData, textColumn } = this.getDataWithColumn()
    const { props: { id, client } = {} } = this
    const editorValue = this.editorRef?.current?.getInstance()?.getMarkdown() || ''

    if (pluginData.length > 0) {
      const updateItems = [pluginData[0]].map((item) => ({
        columnName: textColumn,
        config: {
          ...item,
          [textColumn]: editorValue
        },
        oldValue: item[textColumn]
      }))

      const data = {
        filters: {},
        updateItems,
        updatehints: {}
      }

      client
        .post(`/data/plugin/${id}/update/`, { data })
        .then(() => {
          slvyToast.success({
            message: 'Record has been updated'
          })
          this.handleDataUpdated()
        })
        .catch((err) =>
          slvyToast.warning({
            message: err.toString()
          })
        )
    }
  }

  handleDataUpdated = () => ({ refreshKey: uuidv4() })

  handleVisibility = (sw) => {
    const { visibility, visibility: { preview } = {}, isDisabled } = this.state
    const { environment } = this.props.params
    const isConfig = environment.toLowerCase() === 'configuration'

    const isVisible = isConfig ? sw : true

    if (isDisabled) {
      return
    }

    this.setState({
      visibility: {
        ...visibility,
        preview: isVisible !== undefined ? isVisible : !preview
      }
    })
  }

  setReadOnlyDisabled() {
    this.setState({
      isDisabled: true
    })
  }

  setReadOnlyEnabled() {
    this.setState({
      isDisabled: false
    })
  }

  getToolBarSettings = () => {
    const toolbarSettings = this.props?.settings?.config?.settings?.toolbarSettings || 'default'
    if (toolbarSettings === 'default') {
      return {
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol'],
          ['table', 'link']
        ]
      }
    }
    return {}
  }

  render() {
    const {
      props: {
        settings: {
          config: {
            settings: { backgroundColor = 'white', saveButton = false, editorType = 'wysiwyg' } = {}
          } = {}
        } = {}
      } = {},
      state: { editorValue = '', isDisabled = false } = {}
    } = this

    return (
      <div
        className={`md-editor position-relative h-100 ${editorType}`}
        style={{ backgroundColor }}
      >
        {isDisabled ? (
          <div
            className="h-100 w-100 position-absolute"
            style={{ zIndex: 999999, backgroundColor: 'rgba(255,255,255, 0.1)' }}
          />
        ) : null}
        <ControlPanel saveButton={saveButton} onClick={this.handleSaveChanges} />
        <Editor
          initialValue={editorValue}
          previewStyle="vertical"
          height="100%"
          initialEditType={editorType === 'both' ? 'markdown' : editorType}
          useCommandShortcut
          usageStatistics={false}
          {...this.getToolBarSettings()}
          ref={this.editorRef}
        />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  id: props.id,
  client: props.client,
  params: props.params
})

export default createPlugin(MarkDownEditor, selectConnectorProps)
