import { Button, FormControl } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import cx from 'classnames'
import { EditableProps } from './Editable.types'
import styles from './index.module.scss'

const Editable = ({
  title,
  text,
  author,
  isNewCard,
  fontSizes,
  setTitle,
  setText,
  handleSave,
  handleDelete
}: EditableProps) => {
  const handleShowConfirmation = () => {
    confirmAlert({
      title: 'Deleting Card?',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Cancel',
          onClick: () => false
        },
        {
          label: 'Delete',
          onClick: handleDelete
        }
      ]
    })
  }

  return (
    <>
      {title || isNewCard ? (
        <FormControl
          className={cx('fs-lg', styles.title)}
          placeholder="Title"
          size="sm"
          style={{ fontSize: fontSizes.title }}
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
      ) : null}
      {text || isNewCard ? (
        <FormControl
          as="textarea"
          maxLength={1000}
          placeholder="Description"
          rows={3}
          size="sm"
          style={{ fontSize: fontSizes.text }}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      ) : null}
      <div className="d-flex align-items-center gap-1">
        {isNewCard ? (
          <Button
            disabled={!title || !text}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            size="xs"
            onClick={handleSave}
          >
            Add
          </Button>
        ) : null}
        <Button
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          size="xs"
          variant="danger"
          onClick={handleShowConfirmation}
        >
          <i className="fa slvy-ui-icon-trash-can" />
        </Button>
        {author ? (
          <p
            className="align-self-end text-muted m-0 ms-auto"
            style={{ fontSize: fontSizes.author }}
          >
            {author}
          </p>
        ) : null}
      </div>
    </>
  )
}

export default Editable
