import { useState, DragEvent } from 'react'
import cx from 'classnames'
import { ListGroup } from 'react-bootstrap'
import { IMenuItemProps } from './MenuItem.types'

const MenuItem = ({
  children,
  classes,
  environment,
  onDragOver,
  onDrop,
  skipItem
}: IMenuItemProps) => {
  const [isDragOver, setIsDragOver] = useState(false)

  const handleDragLeave = () => setIsDragOver(false)

  const handleDragOver = (event: DragEvent<HTMLLIElement>) => {
    if (environment !== 'Configuration' || skipItem) return
    setIsDragOver(true)
    onDragOver(event)
  }

  const handleDrop = (event: DragEvent<HTMLLIElement>) => {
    if (skipItem) return
    setIsDragOver(false)
    onDrop(event)
  }

  return (
    <ListGroup.Item
      className={cx(classes, { 'is-drag-overed': isDragOver })}
      data-testid="navigation-menu-item"
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {children}
    </ListGroup.Item>
  )
}

export default MenuItem
