import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ResourceRowItem from '../resourceRowItem'
import { calcTopValue } from '../../../../utils'

const ResourceRow = (props) => {
  const renderChildren = (
    children,
    isResourceChartOpen,
    ownResourceIndex,
    isExpanded,
    locationNames = [],
    isProductionChartOpen,
    productChildren,
    globalIndex
  ) => {
    return children.map((childrenItem, index) => {
      const {
        Name: childrenName = '',
        Children: grandchildren = [],
        Type: childrenType = '',
        Level: level = 0,
        Index: childrenIndex,
        isPinned = false
      } = childrenItem
      const {
        getRccpProductsResponse = {},
        initialFilters = {},
        lastRequest = {},
        settings = {},
        selectedResourceIssueCount = 0,
        maxSelectedIssues = 0,
        connectApi = () => {},
        isAllowed = () => {},
        historyHandler = () => {},
        stateHandler = () => {},
        viewHandler = () => {}
      } = props

      let periodStyle = {
        width: _.size(props.periods) * 70 + 314
      }
      if (isResourceChartOpen && level !== 0) periodStyle = {}

      const isLastProduct = childrenType === 'Product' && children.length - 1 === index
      const pinnedStyles = {
        position: 'sticky',
        zIndex: 99,
        top: calcTopValue(index, selectedResourceIssueCount, maxSelectedIssues)
      }
      const dynamicProps = _.assign(
        {},
        ..._.map(
          level === 0 ? [childrenName] : [...locationNames, childrenName],
          (levelName, i) => ({
            [`locationName${i}`]: levelName
          })
        )
      )
      const nextProductChildren =
        childrenType === 'Product'
          ? {
              ...childrenItem,
              Children: [],
              Level: childrenItem.Level + 2,
              isLastLevel: grandchildren?.length === 0
            }
          : productChildren

      return (
        <div
          key={`${childrenType}-${childrenName}-${index}-${
            isPinned ? 'pinned' : 'not-pinned'
          }-${level}`}
          style={{ ...(isPinned ? pinnedStyles : {}), backgroundColor: '#f9f9f9' }}
        >
          <div className="resource-row-item-outer-wrapper" style={periodStyle}>
            <ResourceRowItem
              aIsProductionChartOpen={isProductionChartOpen}
              aIsResourceChartOpen={isResourceChartOpen}
              connectApi={connectApi}
              children={grandchildren}
              globalIndex={globalIndex}
              productChildren={nextProductChildren}
              // locations={locations}
              getRccpProductsResponse={getRccpProductsResponse}
              historyHandler={historyHandler}
              index={childrenIndex}
              initialFilters={initialFilters}
              isAllowed={isAllowed}
              isExpanded={isExpanded}
              isLastProduct={isLastProduct}
              isPinned={isPinned}
              lastRequest={lastRequest}
              level={level}
              name={childrenName}
              ownResourceIndex={ownResourceIndex}
              renderChildren={renderChildren}
              settings={settings}
              stateHandler={stateHandler}
              type={childrenType}
              viewHandler={viewHandler}
              {...dynamicProps}
            />
          </div>
        </div>
      )
    })
  }

  const { root: { Children: children = [] } = {}, pinnedRows = [] } = props

  return (
    <div className="resource-row-wrapper resource-plan__resource-row-wrapper">
      {renderChildren([...pinnedRows, ...children])}
    </div>
  )
}

const mapStateToProps = (state) => ({
  pinnedRows: state.PRODUCTresourcePlan.pinnedRows,
  maxSelectedIssues: state.PRODUCTlegend.maxSelectedIssues,
  selectedResourceIssueCount: state.PRODUCTlegend.selectedResourceIssueCount,
  clickedProductInfo: state.PRODUCTclickedProductInfo.clickedProductInfo
})

export default connect(mapStateToProps)(ResourceRow)
