const AG_GRID_LOCALE_CUSTOM_TR = {
  groupBy: 'Gruplama Ölçütü:', // tr ozel // overwrite
  massUpdate: 'Toplu Güncelleme',
  refreshData: 'Verileri Yenile',
  clearFilters: 'Filtreyi Temizle',
  clearAllFilters: 'Filtreleri Temizle',
  clearAllSorting: 'Sıralamaları Temizle',
  clearAllGrouping: 'Gruplamaları Temizle',
  columnMenuSettings: 'Ayarlar',
  requiredFields: 'Zorunlu Alanlar',
  invalidRows: 'Geçersiz Satırlar',
  value: 'Değer',
  cancel: 'İptal',
  save: 'Kaydet',
  type: 'Tip',
  valueAreNotAllowedToBeBlank: 'Değer boş bırakılamaz!',
  overwrite: 'Üzerine Yaz',
  increment: 'Arttır',
  percentage: 'Yüzde',
  deleteAction: 'SİLME İŞLEMİ',
  youMustHaveChangesToSave: 'Kaydetmek için değişiklik yapmalısınız!',
  anErrorOccurred: 'Bir Hata Oluştu',
  thresholdExceeded: 'Sınır Aşıldı',
  invalidValue: 'Geçersiz değer',
  sheet: 'Sayfa',
  valueCannotBeGreaterThan: (params: string | number) =>
    `${params} değerinden daha büyük değer girilemez`,
  valueCannotBeLowerThan: (params: string | number) =>
    `${params} değerinden daha küçük değer girilemez`,
  customAggregationFailed: (params: string | number) =>
    `Custom Aggregationda bir hata oluştu! Hatalı kolonlar: ${params}`,
  onlyNumericValuesAllowed: (params: string | number) =>
    `${params} numerik değil. Sadece numerik değerler kullanılabilir`,
  valueChangedMoreThanThreshold: (params: string | number) =>
    `Değerin değişme yüzdesi belirlenen(${params}) yüzdeden daha fazla. Devam etmek istiyor musun?`,
  fieldIsRequired: (params: string | number) => `${params} girilmesi zorunludur!`
}

export type AG_GRID_LOCALE_CUSTOM_TR_TYPES = typeof AG_GRID_LOCALE_CUSTOM_TR

export default AG_GRID_LOCALE_CUSTOM_TR
