import React from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import _ from 'lodash'
import cx from 'classnames'
import {
  PRODUCTselectProduct as onProductSelect,
  PRODUCTsaveResourceIndex as saveResourceIndex,
  PRODUCTtoggleHierarchy as toggleHierarchy,
  PRODUCTexpandHierarchy as expandHierarchy,
  PRODUCTonPinRow as onPinRow
} from '../../../../actions'
import ResourceRowItemInfo from '../resourceRowItemInfo'
import ResourceChart from '../resourceChart'
import AddProduction from '../addProduction'
import ProductionChart from '../productionChart'
import UpdateFormProduct from '../productionChart/UpdateFormProduct'
import { Axis } from '../../../../components'
import { format, calcTopValue, parse, getMaxProductValue, getRowBgColor } from '../../../../utils'

const stringer = (productName) => {
  const words = productName.split(' - ')
  return (
    <span>
      {words[1]}
      <br />
      {words[0]}
    </span>
  )
}

const operators = {
  ' ': () => {},
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '=': (a, b) => a === b,
  '!=': (a, b) => a !== b,
  '<=': (a, b) => a <= b,
  '<': (a, b) => a < b
}

const getResourceRowState = ({
  level,
  name,
  thisResourceIndex,
  ownResourceIndex,
  toggledResourceRows,
  expandedResourceRows,
  allHierarchiesExpanded
}) => {
  const isRowToggled = _.some(toggledResourceRows, {
    level,
    name,
    resourceIndex: level === 0 ? thisResourceIndex : ownResourceIndex
  })
  const isRowExpanded = _.some(expandedResourceRows, {
    resourceIndex: level === 0 ? thisResourceIndex : ownResourceIndex
  })

  return (
    allHierarchiesExpanded || (isRowToggled && !isRowExpanded) || (isRowExpanded && !isRowToggled)
  ) // Exclusive OR
}

class ResourceRowItem extends React.Component {
  constructor(props) {
    super(props)
    const {
      index = 0,
      type = '',
      name = '',
      level = 0,
      ownResourceIndex = 0,
      toggledResourceRows = [],
      expandedResourceRows = [],
      allHierarchiesExpanded
    } = props
    this.resourceIndex = index
    this[`level${level}`] = name

    const isResourceRowItemOpen = getResourceRowState({
      level,
      name,
      thisResourceIndex: this.resourceIndex,
      ownResourceIndex,
      toggledResourceRows,
      expandedResourceRows,
      allHierarchiesExpanded
    })
    const isExpanded =
      _.some(expandedResourceRows, {
        resourceIndex: level === 0 ? this.resourceIndex : ownResourceIndex
      }) || allHierarchiesExpanded
    const isProductionChartOpen = toggledResourceRows.find(
      (row) => row.name === name && type === 'Product'
    )

    this.state = {
      isResourceRowItemOpen,
      isResourceChartOpen: false,
      isProductionChartOpen,
      isExpanded,
      addPopup: -30,
      isUserAllowedToAddProduction: true,
      productIndex: 0,
      // ownClickedProductIndex: undefined,
      ownClickedPeriodIndex: 0,
      ownTableIndex: 0
    }

    this.handleClickOwnProductAndPeriodIndex = this.handleClickOwnProductAndPeriodIndex.bind(this)
    this.handleChangeOwnTableIndex = this.handleChangeOwnTableIndex.bind(this)
  }

  componentDidMount() {
    const { isAllowed = () => {} } = this.props

    const isUserAllowedToAddProduction = isAllowed('Resource and Production/Add Production')
    this.locationNames = _.keys(this.props).reduce((locationNames, key) => {
      if (_.includes(key, 'locationName')) {
        return [...locationNames, this.props[key]]
      }
      return locationNames
    }, [])
    this.setState({ isUserAllowedToAddProduction })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isExpanded !== this.props.isExpanded && this.props.level !== 0) {
      this.setState({ isResourceRowItemOpen: nextProps.isExpanded })
    }

    if (nextProps.allHierarchiesExpanded !== this.props.allHierarchiesExpanded) {
      this.setState({
        isResourceRowItemOpen: nextProps.allHierarchiesExpanded,
        isExpanded: nextProps.allHierarchiesExpanded
      })
    }
  }

  handleClickOwnProductAndPeriodIndex(productIndex, periodIndex) {
    this.setState({ ownClickedProductIndex: productIndex, ownClickedPeriodIndex: periodIndex })
  }

  handleChangeOwnTableIndex(index) {
    this.setState({ ownTableIndex: index })
  }

  toggleResourceRowItem(event) {
    const {
      level,
      type,
      name,
      index,
      isPinned = false,
      ownResourceIndex,
      clickedProductInfo: { periodIndex: clickedPeriodIndex = 0 },
      resourcePeriodHierarchy: {
        Resources: resources = {},
        Periods: periods,
        Products: products
      } = {}
    } = this.props
    event.stopPropagation()

    if (isPinned) return

    this.setState(
      (prevState) => ({
        isResourceRowItemOpen: !prevState.isResourceRowItemOpen
      }),
      () => {
        this.props.toggleHierarchy({
          level,
          name,
          resourceIndex: level === 0 ? this.resourceIndex : ownResourceIndex
        })
      }
    )

    if (level === 0) {
      this.resourceIndex = index
      this.props.saveResourceIndex(index)
    }

    if (type === 'Product') {
      const { Id: periodId, DisplayName } = periods[clickedPeriodIndex] ?? {}
      const { Id: productId, Name: productName } = products[index] ?? {}
      const { [ownResourceIndex]: { Id, Name } = [] } = resources

      this.props.onProductSelect({
        periodIndex: clickedPeriodIndex,
        isResourceChart: false,

        resourceName: Name,
        resourceId: Id,

        periodName: DisplayName,
        periodId

        // productName,
        // productId,

        // locationNames: this.locationNames
      })

      this.props.saveResourceIndex(ownResourceIndex)
      this.setState((prevState) => ({
        isProductionChartOpen: !prevState.isProductionChartOpen,
        productIndex: index
      }))
    }
  }

  toggleResourceChart(event) {
    event.stopPropagation()

    this.setState((prevState) => ({
      isResourceChartOpen: !prevState.isResourceChartOpen
    }))
  }

  handleExpandCollapse(event) {
    event.stopPropagation()
    const {
      level,
      type = '',
      name = '',
      ownResourceIndex = 0,
      allHierarchiesExpanded = false
    } = this.props

    this.setState(
      (prevState) => ({ isExpanded: !prevState.isExpanded }),
      () => {
        const { isExpanded } = this.state

        this.props.expandHierarchy({
          resourceIndex: level === 0 ? this.resourceIndex : ownResourceIndex
        })

        if (!isExpanded || !allHierarchiesExpanded)
          this.props.toggleHierarchy({
            level,
            name,
            resourceIndex: level === 0 ? this.resourceIndex : ownResourceIndex
          })
      }
    )
  }

  popoverClickRootClose(data) {
    const { connectApi, lastRequest, settings, resourcePeriodHierarchy, coverViolationIndexes } =
      this.props
    return (
      <Popover id="popover-positioned-gantt-form-add">
        <AddProduction
          connectApi={connectApi}
          coverViolationIndexes={coverViolationIndexes}
          data={data}
          getData={null}
          getResourcePeriodHierarchy={resourcePeriodHierarchy}
          lastRequest={lastRequest}
          settings={settings}
        />
      </Popover>
    )
  }

  handleAddProduct(params, event) {
    event.stopPropagation()

    const { connectApi, period = params.week } = this.props

    params.week = period
    connectApi('GetRccpProductsResponse', params)
  }

  handlePinResource(event) {
    event.stopPropagation()
    const { name, type } = this.props

    this.props.onPinRow({
      Name: name,
      Type: type,
      Index: this.resourceIndex,
      Children: [],
      isPinned: true
    })
  }

  render() {
    const {
      isResourceRowItemOpen = false,
      isResourceChartOpen = false,
      isProductionChartOpen = false,
      isUserAllowedToAddProduction = true
    } = this.state
    const {
      name = '',
      children = [],
      type = '',
      level,
      index,
      isExpanded,
      isPinned = false,
      settings,
      settings: {
        modificationCount,
        stateHandler: {
          selectedParameter: { Index: selectedMassUnitIndex = 0 }
        },
        productPlanSettings = []
      },
      resourcePeriodHierarchy: {
        ResourcePeriods: resourcePeriods = {},
        ProductPeriods: productPeriods = {},
        Periods: periods = [],
        Resources: resources = {},
        Products: products = []
      } = {},
      clickedProductInfo: {
        periodIndex: clickedPeriodIndex = 0,
        productIndex: clickedProductIndex = 0,
        locationNames: clickedLocationNames = [],
        isProductPeriod,
        isSelectionOn
      },
      isLastProduct,
      maxSelectedIssues = 1,
      selectedResourceIssueCount = 1,
      resourceIndex,
      initialFilters = {},
      ownResourceIndex,
      configSettings: {
        resourceAndProductPlanSettings: { shiftCalendarButtonFormat = '0,0' } = {}
      } = {},
      getRccpProductsResponse = {},
      pinnedRows = [],
      locations = [],
      coverViolationIndexes,
      connectApi = () => {},
      isAllowed = () => {},
      renderChildren = () => {},
      stateHandler = () => {},
      filterValues = () => {},
      globalIndex,
      allHierarchiesExpanded
    } = this.props

    const isUpdateFormExpanded =
      (type === 'Product' && isProductionChartOpen) ||
      (type === 'Product' && allHierarchiesExpanded)

    const { tableName } = productPlanSettings[this.state.ownTableIndex]
    const maxProductPeriodTable = getMaxProductValue(
      tableName,
      productPeriods[index],
      selectedMassUnitIndex // this has to change later...
    )

    const { resourceIndex: thisResourceIndex } = this

    const leftPadding = !level ? 5 : 15 + level * 15

    const pinnedRowsLength = pinnedRows.length
    const currentResourceChartPeriod = resourcePeriods[index] /// To be dynamic
    const periodBarStyle = {
      position: level === 0 ? 'sticky' : 'relative',
      top:
        level === 0
          ? calcTopValue(pinnedRows.length, selectedResourceIssueCount, maxSelectedIssues)
          : 0,
      boxShadow:
        level === 0 && isResourceRowItemOpen && !isPinned ? '0px 8px 16px -8px #c1c1c1' : ''
    }

    const { Id: resourceId = 0 } = resources[this.resourceIndex] || {}
    const period = periods[clickedPeriodIndex] || []
    const { Name: periodName = '' } = period || {}

    const params = {
      week: periodName,
      period,
      resourceId,
      rccpConfig: {
        ModificationCount: modificationCount
      }
    }

    const resourcePeriod = resourcePeriods[index] || {}
    const { NumberOfEnabledShifts = 0, NumberOfAvailableShifts = 0 } =
      resourcePeriod[clickedPeriodIndex] || {}

    let foundProduct = {}
    if (type === 'Product' && filterValues.tableName) {
      if (filterValues.isResourceBased) {
        foundProduct = _.find(
          resourcePeriods[ownResourceIndex][filterValues.periodIndex || 0]?.ResourcePeriodProducts,
          { ProductIndex: index }
        )
      } else {
        foundProduct = productPeriods[index][filterValues.periodIndex]
      }
    }

    let { [filterValues.tableName]: Production = null } = foundProduct || {}
    Production = Production === null ? 0 : Production
    const locationsMatch = _.isEqual(this.locationNames, clickedLocationNames)

    return (
      <div
        className={`resource-row-item-container ${
          level === 0 &&
          isResourceChartOpen &&
          isResourceRowItemOpen &&
          'resource-row-item-container_resource-chart-open'
        }`}
        style={{
          height:
            this.props.aIsProductionChartOpen || isProductionChartOpen || allHierarchiesExpanded
              ? 384
              : 'auto'
        }}
      >
        <div
          ref={(itemWrapperRef) => {
            this.itemWrapperRef = itemWrapperRef
          }}
          className="add-production-container"
        />
        <ul
          className={`resource-row-item-wrapper ${
            type === 'Product' &&
            filterValues.tableName &&
            filterValues.tableName !== null &&
            !operators[filterValues.operator](
              parse(format(Production, filterValues.format)),
              parse(format(filterValues.value, filterValues.format))
            )
              ? 'resource-row-item-wrapper--hidden'
              : ''
          }
          `}
          style={periodBarStyle}
        >
          <li
            className={`resource-row-item resource-row__resource-row-item ${
              locationsMatch && 'resource-row-item_selected'
            }
              ${isSelectionOn && clickedPeriodIndex === 0 ? 'resource-row-item_border-right' : ''}
              ${locationsMatch && _.isNil(clickedPeriodIndex) ? 'is-selected' : ''}`}
            style={{
              paddingLeft: leftPadding,
              backgroundColor: getRowBgColor(globalIndex, type)
            }}
            onClick={this.toggleResourceRowItem.bind(this)}
          >
            {/* {type === 'Product' ? (
              <p className="resource-row-item__name">{stringer(name)}</p>
            ) : ( */}
            <div className="resource-row-item__name-wrapper">
              {level === 0 && (
                <div
                  className={cx(
                    'pinned-row',
                    {
                      'pinned-row--on': _.some(
                        this.props.pinnedRows,
                        (item) => item.Index === thisResourceIndex
                      )
                    },
                    {
                      'pinned-row--off': !_.some(
                        this.props.pinnedRows,
                        (item) => item.Index === thisResourceIndex
                      )
                    },
                    { 'pinned-row--expanded': isPinned }
                  )}
                >
                  <i
                    className="fa fa-fw fa fa-thumbtack"
                    onClick={this.handlePinResource.bind(this)}
                  />
                </div>
              )}
              {!isPinned && (
                <i
                  className={cx(
                    { 'slvy-ui-icon-caret-right_open': isResourceRowItemOpen },
                    'slvy-ui-icon-caret-right',
                    '-caretIndicator'
                  )}
                />
              )}
              <p className="resource-row-item__name">{name}</p>
            </div>
            {/* )} */}

            {level === 0 && isResourceRowItemOpen && !isPinned && (
              <div
                className={`resource-item-icon-container ${isResourceRowItemOpen ? 'is-open' : ''}`}
              >
                {isResourceChartOpen && (
                  <OverlayTrigger
                    rootClose
                    container={this.itemWrapperRef}
                    overlay={this.popoverClickRootClose(
                      {
                        ...getRccpProductsResponse,
                        ...params
                      },
                      false
                    )}
                    placement="right"
                    trigger="click"
                  >
                    <button
                      className={cx('-slvy-btn -neutral -xsmall', {
                        'opacity-50': !isProductPeriod
                      })}
                      disabled={!isUserAllowedToAddProduction}
                      onClick={this.handleAddProduct.bind(this, params)}
                    >
                      <i className="slvy-ui-icon-plus-lt" />
                      Production
                    </button>
                  </OverlayTrigger>
                )}
                {!isResourceChartOpen && (
                  <div>
                    {/* {isShiftCalendarViewEnabled && (
                      <button
                        className="-slvy-btn -neutral -xsmall"
                        title={shiftCalendarViewTitle}
                        key="shift-calendar"
                        onClick={(e) => {
                          e.stopPropagation()
                          let req = {
                            periodId: periods[clickedPeriodIndex].Id,
                            rccpConfig: {
                              ModificationCount: modificationCount
                            }
                          }
                          connectApi('GetRccpResourceLineResponse', req).then((res) => {
                            this.props.viewHandler('scheduleLineView', 'pushPage')
                          })
                        }}
                      >
                        <i className="slvy-ui-icon-export-box" />
                        {format(NumberOfEnabledShifts, shiftCalendarButtonFormat)} /{' '}
                        {format(NumberOfAvailableShifts, shiftCalendarButtonFormat)}
                      </button>
                    )} */}
                    {/* {isResourceScheduleViewEnabled && (
                      <button
                        type="button"
                        className="-slvy-btn -xsmall get-resource-line"
                        title={resourceScheduleViewTitle}
                        key="resource-schedule"
                        onClick={(event) => {
                          event.stopPropagation()
                          let req = {
                            productId: products[clickedProductIndex].Id,
                            periodId: periods[clickedPeriodIndex].Id,
                            rccpConfig: {
                              StartDate: periods[clickedPeriodIndex].StartTime,
                              EndDate: periods[clickedPeriodIndex].EndTime,
                              ModificationCount: modificationCount
                            }
                          }

                          connectApi('GetRccpResourceActivityResponseWPF', req).then((res) => {
                            this.props.viewHandler('scheduleResourceView', 'pushPage')
                          })
                        }}
                      >
                        <i className="slvy-ui-icon-sorted" />
                        RS
                      </button>
                    )} */}
                  </div>
                )}
                {/* <i
                  className={`slvy-ui-icon-chart-bar-regular resource-item__chart-button ${
                    isResourceChartOpen ? 'resource-item__chart-button--open' : ''
                  }`}
                  title="Resource Chart"
                  onClick={this.toggleResourceChart.bind(this)}
                /> */}
                <i
                  className={`${
                    this.state.isExpanded
                      ? 'fa-fw fa slvy-ui-icon-minus-outline'
                      : 'fa-fw fa slvy-ui-icon-plus-outline'
                  } resource-item__expand-collapse-button`}
                  title="Expand/Collapse"
                  onClick={this.handleExpandCollapse.bind(this)}
                />
              </div>
            )}
          </li>

          <ResourceRowItemInfo
            // onClickOwnProductAndPeriodIndex={this.handleClickOwnProductAndPeriodIndex}
            // ownClickedProductIndex={this.state.ownClickedProductIndex}
            // ownClickedPeriodIndex={this.state.ownClickedPeriodIndex}
            paintOnClick
            connectApi={connectApi}
            index={index}
            initialFilters={initialFilters}
            isAllowed={isAllowed}
            level={level}
            locationNames={this.locationNames}
            locations={locations}
            settings={settings}
            stateHandler={stateHandler}
            testResourceIndex={ownResourceIndex}
            type={type}
          />

          {this.props.aIsResourceChartOpen && <div />}
        </ul>

        {level === 0 && isResourceChartOpen && isResourceRowItemOpen && (
          <div className="rcc-wrp resource-row-item__resource-chart-wrapper">
            <div
              className="rccp-table resource-row-item__resource-chart-table"
              style={{
                top: calcTopValue(
                  pinnedRowsLength + 1,
                  selectedResourceIssueCount,
                  maxSelectedIssues
                )
              }}
            >
              <div className="resource-row-item__resource-chart" />
              <div ref="rccpbody" className="rccp-body" style={{ height: 347 }}>
                <div className="-row -active">
                  <div style={{ height: 347 }}>
                    <div style={{ height: '100%' }}>
                      <section className="-chart-box">
                        <ResourceChart
                          chartHandler={null}
                          connectApi={connectApi}
                          initialFilters={initialFilters}
                          isAllowed={isAllowed}
                          lastRequest={{}}
                          periods={periods}
                          productClickHandler={null}
                          productId={-1}
                          productPeriods={productPeriods}
                          products={products}
                          resourceId={resources[index]?.Id}
                          resourceIndex={index}
                          resourcePeriod={currentResourceChartPeriod}
                          settings={settings}
                          stateHandler={stateHandler}
                          viewHandler={null}
                        />
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isUpdateFormExpanded && (
          <div className="rcc-wrp resource-row-item__resource-chart-wrapper">
            <div
              className="rccp-table resource-row-item__resource-chart-table"
              style={{
                top: calcTopValue(
                  pinnedRowsLength + 1,
                  selectedResourceIssueCount,
                  maxSelectedIssues
                )
              }}
            >
              <div className="resource-row-item__resource-chart">
                <div className="-inner-box">
                  <UpdateFormProduct
                    connectApi={connectApi}
                    coverViolationIndexes={coverViolationIndexes}
                    data={productPeriods[index][this.state.ownClickedPeriodIndex ?? 0]}
                    historyHandler={this.props.historyHandler}
                    productId={products[index]?.Id}
                    productItem={products[index]}
                    productPlanHandler={this.handleChangeOwnTableIndex}
                    productPlanIndex={this.state.ownTableIndex}
                    settings={settings}
                    viewHandler={this.props.viewHandler}
                  />
                  <Axis axisData={maxProductPeriodTable} />
                </div>
              </div>
              <div ref="rccpbody" className="rccp-body" style={{ height: 347 }}>
                <div className="-row -active">
                  <div style={{ height: 347 }}>
                    <div style={{ height: '100%' }}>
                      <section className="-chart-box">
                        <ProductionChart
                          chartHandler={() => {}}
                          clickedProductIndex={clickedProductIndex}
                          // isConsumedBy={isConsumedBy}
                          connectApi={connectApi}
                          initialFilters={initialFilters}
                          isAllowed={isAllowed}
                          lastRequest={{}}
                          ownProductIndex={index}
                          periods={periods}
                          product={products[index]}
                          productId={products[index]?.Id}
                          productPeriod={productPeriods[index]}
                          selectedProductPeriod={clickedPeriodIndex}
                          settings={settings}
                          stateHandler={stateHandler}
                          tableName={tableName}
                          onPeriodChange={this.handleClickOwnProductAndPeriodIndex}
                        />
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isResourceRowItemOpen &&
          children.length >= 0 &&
          renderChildren(
            children,
            level === 0 ? isResourceChartOpen : this.props.aIsResourceChartOpen,
            level === 0 ? this.resourceIndex : ownResourceIndex,
            level === 0 ? this.state.isExpanded : isExpanded,
            this.locationNames,
            level === 0 ? isProductionChartOpen : this.props.aIsProductionChartOpen,
            globalIndex
          )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isResourceChartOpen: state.PRODUCTclickedProductInfo.isResourceChartOpen,
  clickedProductInfo: state.PRODUCTclickedProductInfo.clickedProductInfo,
  resourcePeriodHierarchy: state.PRODUCTresourcePeriodHierarchy,
  maxSelectedIssues: state.PRODUCTlegend.maxSelectedIssues,
  selectedResourceIssueCount: state.PRODUCTlegend.selectedResourceIssueCount,
  resourceIndex: state.PRODUCTclickedProductInfo.resourceIndex,
  toggledResourceRows: state.PRODUCTresourceRowItem.toggledHierarchies,
  expandedResourceRows: state.PRODUCTresourceRowItem.expandedHierarchies,
  configSettings: state.PRODUCTresourcePlan.configSettings,
  allHierarchiesExpanded: state.PRODUCTresourceRowItem.allHierarchiesExpanded,
  pinnedRows: state.PRODUCTresourcePlan.pinnedRows,
  filterValues: state.PRODUCTresourcePlan.filterValues,
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes
})

const mapDispatchToProps = {
  onProductSelect,
  saveResourceIndex,
  toggleHierarchy,
  expandHierarchy,
  onPinRow
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceRowItem)
