/* global Ext */
Ext.define(
  null,
  {
    override: 'Ext.util.Filter'
  },
  function () {
    var prototype = this.prototype
    prototype.operatorFns.notlike = function (candidate) {
      var v = this._filterValue
      return !(
        v &&
        this.getCandidateValue(candidate, v)
          .toLowerCase()
          .indexOf(v.toLowerCase()) > -1
      )
    }
  }
)
