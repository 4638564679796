export default {
  title: 'Heatmap',
  subTitle: 'Highcharts',
  version: '1.0',
  namespace: 'Heatmap',
  description: 'Heatmap from Highcharts (www.highcharts.com)',
  iconClass: 'fa-project-diagram',
  color: 'lazur-bg',
  support: {
    maximizable: true
  },
  group: 'Charts'
}
