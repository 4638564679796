Ext.define('Btl.UploadController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.btl-uploadcontroller',
  mixins: ['MDM.Btl.cmp.HeaderBarMixin', 'MDM.Btl.edit.EditMixins'],
  init: function () {
    var me = this
    Ext.defer(function () {
      // me.loadData();
    }, 5)
  },
  loadData: function () {
    var me = this,
      mainStore = me.getViewModel().getStore('mainStore'),
      DPIHierarcy = me.getViewModel().get('DPIHierarcy'),
      ReportingPeriodID = me.getViewModel().get('reportingPeriodID'),
      mainGrid = me.lookup('centergrid')
    var MegaRegionID = me.getViewModel().get('MegaRegionID')
    var RegionID = me.getViewModel().get('RegionID')
    me.getView().setLoading('Loading data please wait')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_data',
      timeout: 360000,
      params: {
        DPISegmentID: DPIHierarcy.get('DPISegmentID'),
        ProductGroupID: DPIHierarcy.get('ProductGroupID'),
        DPIFamilyID: DPIHierarcy.get('DPIFamilyID'),
        ReportingPeriodID: ReportingPeriodID,
        MegaRegionID: MegaRegionID,
        RegionID: RegionID
      },
      success: function (response, opts) {
        var data = Ext.JSON.decode(response.responseText)
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        if (data && data.MainGrid) mainGrid.setCfgData(data.MainGrid)
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        Ext.MessageBox.alert('Error', 'Server Error')
      }
    })
  },
  addToEditPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      store = me.getViewModel().getStore('editStore'),
      newrec = record.copy()
    var win = Ext.create('MDM.Btl.dialog.EditForm', {
      record: newrec
    }).show()
    win.on('save', function () {
      var data = newrec.data
      delete data['id']
      data.recType = 'Manuel'
      const rec = Ext.create('MDM.Btl.model.EditModel', data)
      for (var index = 1; index < 37; index++) {
        var key = index < 10 ? 'c0' + index : 'c' + index
        rec.set(key, 0)
      }
      rec.commit()
      store.add(rec)
      newrec.reject()
    })
  },
  showEditPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      store = me.getViewModel().getStore('editStore')
    var win = Ext.create('MDM.Btl.dialog.EditForm', {
      record: record
    }).show()
  },
  onUpLoadExcel: function () {
    var win = Ext.create('MDM.Btl.upload.Import', {
      filterParams: filterParams
    }).show()
  },
  onClickApply: function () {
    var me = this,
      grid = me.lookup('processedgrid'),
      records = grid.getSelection()
    me.getView().fireEvent('applyxls', records)
    me.getView().close()
  }
})
