export const categorizedJobs = {
  Others: {
    header: '- Others',
    cat: 'other',
    config: { key: '', title: '' }
  },
  Runs: {
    header: '- Run',
    cat: 'run',
    config: { key: '', title: '' }
  }
}

export const reactVirtualizedListProps = {
  width: 800,
  height: 630,
  rowHeight: 62,
  rowCount: 0
}

export const externalNodeType = 'yourNodeType'

export const navbarHeight = 48

export const btnStyle = {
  width: 24,
  height: 24
}

export const jobsUsingCustomVariables = ['updateVariables', 'condition', 'generateKey'] as const
export const jobsWithInitialValue = ['delay', 'runDrilldown', 'loading'] as const
