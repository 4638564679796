import { useCallback, useEffect, useMemo } from 'react'
import { size, transform } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import { getUpdateRowData, isRowDataChanged } from '../../../../helpers'
import { useGetColDefByField } from '../../../index'
import { IRowData } from '../../../../types'
import {
  ArgsType,
  SetRowValuesWithFilterProps
} from './useRegisterMethodSetRowValuesWithFilter.type'
import { RegisterMethodFunctionFieldConfigArg } from '../../registerEventsMethods.types'

function useRegisterMethodSetRowValuesWithFilter({
  applyUpdate,
  fieldConfigs,
  gridRef,
  registerMethod
}: SetRowValuesWithFilterProps) {
  const { getColDefByField } = useGetColDefByField(gridRef)

  const onSetRowValuesWithFilter = useCallback(
    (params: RegisterMethodFunctionFieldConfigArg) => {
      const dataToUpdate: IRowData[] = []
      const filterParams: RegisterMethodFunctionFieldConfigArg = {}
      const setParams: RegisterMethodFunctionFieldConfigArg = {}

      Object.keys(params).forEach((key) => {
        if (key.toString().startsWith('Filter_')) {
          filterParams[key.substring(7, size(key))] = params[key]
        } else if (key.toString().startsWith('Set_')) {
          setParams[key.substring(4, size(key))] = params[key]
        }
      })

      gridRef?.current?.api?.forEachNode?.(({ data }) => {
        if (data) {
          let updatedRow = { ...data }
          const rowMatch = isRowDataChanged(data, filterParams)

          if (rowMatch) {
            // TODO: Need improvement for lookup fields,
            // Because we do not have lookupOptions yet,
            // We need to make an api call to take lookupOptions for each rows.
            updatedRow = getUpdateRowData({
              fieldConfigs,
              getColDefByField,
              params: setParams,
              rowData: data
            })
          }

          dataToUpdate.push(updatedRow)
        }
      })

      if (dataToUpdate.length) {
        applyUpdate(dataToUpdate)
      }
    },
    [applyUpdate, fieldConfigs, getColDefByField, gridRef]
  )

  const args = useMemo(() => {
    return transform(
      fieldConfigs,
      (result: ArgsType, field) => {
        const { dataType, fieldName } = field
        result.push({
          name: `Filter_${fieldName}`,
          type: PluginTypes.fromString(dataType)
        })
        result.push({
          name: `Set_${fieldName}`,
          type: PluginTypes.fromString(dataType)
        })
      },
      []
    )
  }, [fieldConfigs])

  useEffect(() => {
    registerMethod({
      args,
      key: 'setRowValuesWithFilter',
      fn: onSetRowValuesWithFilter
    })
  }, [args, onSetRowValuesWithFilter, registerMethod])
}

export default useRegisterMethodSetRowValuesWithFilter
