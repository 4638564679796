import LoadTable from './LoadTable'
import LoadStopTable from './LoadStopTable'
import LoadStopEntryTable from './LoadStopEntryTable'
import styles from './TableView.module.scss'

export default function TableView() {
  return (
    <div className={styles.tableView}>
      <section className={styles.loadTableSection}>
        <LoadTable />
      </section>
      <div className={styles.loadStopAndEntryTableSection}>
        <LoadStopTable />
        <LoadStopEntryTable />
      </div>
    </div>
  )
}
