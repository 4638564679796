Ext.define('MDM.Btl.dialog.BtlRulesInfoTemplate', {
  extend: 'Ext.panel.Panel',
  xtype: 'btlRulesInfoTemplate',

  initComponent: function () {
    var me = this
    me.store.load({
      callback: function (store) {
        me.setData(store[0].data)
      }
    })
    me.callParent()
  },

  tpl: new Ext.XTemplate(
    '<div class="-btl-description -customScroll" style="width:500px;height:750px;margin:5px;">',
    '<h2>{RuleName}</h2>',
    '<table>',
    '<tbody>',
    '<tr>',
    '<td colspan="3">',
    '<h3>RULE BASE PROPERTIES</h3>',
    '</td>',
    '</tr>',
    '<tr>',
    '<td>Region</td>',
    '<td>{Region}</td>',
    '</tr>',
    '<tr>',
    '<td>Action</td>',
    '<td>{ActionTypeName}</td>',
    '</tr>',
    '<tr>',
    '<td>Level</td>',
    '<td>{LevelName}</td>',
    '</tr>',
    '<tr>',
    '<td>BTL Type Source</td>',
    '<td>{Source_BTLTypeDescription}</td>',
    '</tr>',
    '<tr>',
    '<td>BTL Type Target</td>',
    '<td>{Target_BTLTypeDescription}</td>',
    '</tr>',
    '<tr>',
    '<td>Material Type Source</td>',
    '<td>{Source_MaterialType}</td>',
    '</tr>',
    '<tr>',
    '<td>Material Type Target</td>',
    '<td>{Target_MaterialType}</td>',
    '</tr>',
    '<tr>',
    '<td>Reason</td>',
    '<td>{ReasonCode}</td>',
    '</tr>',
    '<tr>',
    '<td colspan="3">',
    '<h3>RULE DEFINITION</h3>',
    '</td>',
    '</tr>',
    '<tr>',
    '<td>Product Group</td>',
    '<td>{Source_ProductGroup}</td>',
    '</tr>',
    '<tr>',
    '<td>DPI Family</td>',
    '<td>{Source_DPIFamily}</td>',
    '</tr>',
    '<tr>',
    '<td>SKU</td>',
    '<td>{Source_Material}</td>',
    '</tr>',
    '<tr>',
    '<td>Supply Source</td>',
    '<td>{Source_SupplySource}</td>',
    '</tr>',
    '<tr>',
    '<td>From Region</td>',
    '<td>{Source_FromRegion}</td>',
    '</tr>',
    '<tr>',
    '<td>To Region</td>',
    '<td>{Source_ToRegion}</td>',
    '</tr>',
    '<tr>',
    '<td>Period Type</td>',
    '<td>{Source_PeriodType}</td>',
    '</tr>',
    '<tr>',
    '<td>Starting Period</td>',
    '<td>{Source_StartingPeriodID}</td>',
    '</tr>',
    '<tr>',
    '<td>Ending Period</td>',
    '<td>{Source_EndingPeriodID}</td>',
    '</tr>',
    '<tr>',
    '<td>Unit</td>',
    '<td>{UnitTypeName}</td>',
    '</tr>',
    '<tr>',
    '<td>Value</td>',
    '<td>{Value}</td>',
    '</tr>',
    '<tr>',
    '<td>Split</td>',
    '<td>{IsSplit}</td>',
    '</tr>',
    '<tr>',
    '<td colspan="3">',
    '<h3>TARGET DEFINITION</h3>',
    '</td>',
    '</tr>',
    '<tr>',
    '<td>Product Group</td>',
    '<td>{Target_ProductGroup}</td>',
    '</tr>',
    '<tr>',
    '<td>DPI Family</td>',
    '<td>{Target_DPIFamily}</td>',
    '</tr>',
    '<tr>',
    '<td>SKU</td>',
    '<td>{Target_Material}</td>',
    '</tr>',
    '<tr>',
    '<td>Supply Source</td>',
    '<td>{Target_SupplySource}</td>',
    '</tr>',
    '<tr>',
    '<td>From Region</td>',
    '<td>{Target_FromRegion}</td>',
    '</tr>',
    '<tr>',
    '<td>To Region</td>',
    '<td>{Target_ToRegion}</td>',
    '</tr>',
    '<tr>',
    '<td>Period Type</td>',
    '<td>{Target_PeriodType}</td>',
    '</tr>',
    '<tr>',
    '<td>Starting Period</td>',
    '<td>{Target_StartingPeriodID}</td>',
    '</tr>',
    '<tr>',
    '<td>Ending Period</td>',
    '<td>{Target_EndingPeriodID}</td>',
    '</tr>',
    '</tbody>',
    '</table>',
    '</div>'
  )
})
