export default `({
    height: 280,
    layout: 'fit',
    xtype: 'panel',
    title: tt('Notlar'),
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b>' + tt('Campaign Notes') + '</b>'
        }]
    }],
    style: {
        "padding": "1px",
        "background": "#fff"
    },
    padding: '0 0 0 0',
    items: [{
        xtype: 'htmleditor',
        labelAlign: 'top',
        anchor: '100%',
        bind: {
            value: '{spotRecord.Notes}'
        },
        listeners: {
            change: function ( me, newValue, oldValue, eOpts  ) {
                // debugger
                // if  (newValue.includes('<img '))
                // {
                //     me.setValue(oldValue)
                // }
            },
            initialize: function(editor){
                const doc = editor.getDoc();
                const docEl = Ext.get(doc);
                docEl.on({
                   paste: (e)=>{
                     if(e.event.clipboardData.files.length>0 && e.event.clipboardData.files[0].type.includes('image'))
                        {
                          e.preventDefault();
                        }
                   },
                   delegated:false,
                   scope: editor
                });
            }
        },
        margin: '0'
    }]
})
`
