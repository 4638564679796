Ext.define('Spui.view.common.productinfo.cmp.tabpanel.Info.Mixin', {
  extend: 'Ext.Mixin',
  config: {
    vendorCfg: 'vendorGrid',
    vendorStoreCfg: 'vendorStore',
    vendorStore: {},
    vendor: {},
    unitCfg: 'unitofMeasure',
    unitStore: {},
    unit: {},
    materialCode: false
  },
  applyOtherCfg: function (c) {
    return ss(c)
  },
  listeners: {
    activate: function (p) {
      if (p.forceToLoadStore) p.getVendorStore().load()
      p.getUnitStore().load()
    }
  },
  applyOther: function (b) {
    var setRec = this.getOtherCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        xtype: 'panel',
        height: 100,
        title: 'test',
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigureOtherInfo'
              }
            ]
          }
        ]
      })
      b = Ext.create(b)
    }
    return b
  },
  updateOther: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigureOtherInfo: function () {
    var setRec = this.getOtherCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.ononConfigureOtherInfoSave, this)
  },
  ononConfigureOtherInfoSave: function (v, k) {
    var setRec = this.getOtherCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setOther(res)
  },
  applyVendorCfg: function (c) {
    return ss(c)
  },
  applyVendorStoreCfg: function (c) {
    return ss(c)
  },
  applyVendorStore: function (b) {
    var me = this
    var setRec = this.getVendorStoreCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        fields: [
          {
            name: 'QTY',
            type: 'number'
          },
          {
            name: 'Price',
            type: 'number'
          },
          {
            name: 'NumberOfStore',
            type: 'number'
          },
          {
            name: 'Envanter',
            type: 'number'
          },
          {
            name: 'DateKey',
            type: 'string'
          },
          {
            name: 'PriceNull',
            convert: function (value, record) {
              var v = record.get('Price')
              if (!v) return null
              return v
            },
            depends: ['Price']
          },
          {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
              var v = record.get('NumberOfStore')
              if (!v) return null
              return v
            },
            depends: ['NumberOfStore']
          }
        ],
        listeners: {
          beforeload: function () {
            me.getVendor().setLoading(tt('Yükleniyor'))
          },
          load: function () {
            var vendor = me.getVendor()
            if (!vendor) return false
            vendor.setLoading(false)
            this.forceToLoadStore = false
            if (typeof viewModel != 'undefined' && !viewModel.destroyed) {
              var store = Ext.create('Ext.data.Store', {
                fields: [
                  {
                    name: 'VendorNameWithCode',
                    type: 'string',
                    convert: function (val, row) {
                      return row.data.VendorCode + ' - ' + row.data.VendorName
                    }
                  },
                  {
                    name: 'VendorCode',
                    type: 'string'
                  }
                ],
                autoLoad: false
              })
              store.loadData(this.getDatas())

              viewModel.set('vendorDatas', store)
            }
          }
        },
        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          data: [
            {
              DateKey: 0
            }
          ],
          url: Spui.Global.getApiUrl() + 'spui/GetVendorList'
        },
        autoLoad: false
      })
      b = Ext.create('Ext.data.Store', b)
    }
    return b
  },
  applyVendor: function (b) {
    var setRec = this.getVendorCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        xtype: 'grid',
        store: this.getVendorStore(),
        plugins: [
          {
            ptype: 'cornerIcon',
            extraButton: [
              {
                iconCls: 'x-fa fa-arrows-alt',
                handler: 'onMaximize'
              }
            ],
            cfgButton: [
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                menu: [
                  {
                    text: 'Chart Config',
                    handler: 'onConfigureVendor'
                  },
                  {
                    text: 'Store Config',
                    handler: 'onConfigureVendorStore'
                  }
                ]
              }
            ]
          }
        ],
        title: 'Vendor list',
        columns: [
          {
            text: 'title'
          }
        ]
      })
      b = Ext.create(b)
    }
    return b
  },
  updateVendor: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  updateMaterialCode: function (c, d) {
    if (c == d) return
    if (!c) return
    var vendorStore = this.getVendorStore(),
      unitStore = this.getUnitStore()
    vendorStore.getProxy().setExtraParam('MaterialCode', c)
    unitStore.getProxy().setExtraParam('MaterialCode', c)
    if (this.isVisible()) {
      vendorStore.load()
      unitStore.load()
      this.forceToLoadStore = false
    } else {
      this.forceToLoadStore = true
    }
  },
  applyUnitCfg: function (c) {
    return ss(c)
  },
  onConfigureVendor: function () {
    var setRec = this.getVendorCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigureVendorSave, this)
  },
  onConfigureVendorSave: function (v, k) {
    var setRec = this.getVendorCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setVendor(res)
  },
  onConfigureVendorStore: function () {
    var setRec = this.getVendorStoreCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigureVendorStoreSave, this)
  },
  onConfigureVendorStoreSave: function (v, k) {
    var setRec = this.getVendorStoreCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
  },
  applyUnitStore: function (b) {
    var me = this
    if (b && !b.isStore) {
      Ext.applyIf(b, {
        fields: [
          {
            name: 'QTY',
            type: 'number'
          },
          {
            name: 'Price',
            type: 'number'
          },
          {
            name: 'NumberOfStore',
            type: 'number'
          },
          {
            name: 'Envanter',
            type: 'number'
          },
          {
            name: 'DateKey',
            type: 'string'
          },
          {
            name: 'PriceNull',
            convert: function (value, record) {
              var v = record.get('Price')
              if (!v) return null
              return v
            },
            depends: ['Price']
          },
          {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
              var v = record.get('NumberOfStore')
              if (!v) return null
              return v
            },
            depends: ['NumberOfStore']
          }
        ],
        listeners: {
          beforeload: function () {
            me.getUnit().setLoading(tt('Yükleniyor'))
          },
          load: function () {
            var unit = me.getUnit()
            if (!unit) return false
            me.getUnit().setLoading(false)
            this.forceToLoadStore = false
          }
        },
        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          data: [
            {
              DateKey: 0
            }
          ],
          url: Spui.Global.getApiUrl() + 'spui/GetUnitofMeasurementList'
        },
        autoLoad: false
      })
      b = Ext.create('Ext.data.Store', b)
    }
    return b
  },
  applyUnit: function (b) {
    var setRec = this.getUnitCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        xtype: 'grid',
        store: this.getUnitStore(),
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigurUnit'
              }
            ]
          }
        ],
        columns: [
          {
            text: 'title'
          }
        ]
      })
      b = Ext.create(b)
    }
    return b
  },
  updateUnit: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(2, f)
        } else {
          d.splice(2, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigurUnit: function () {
    var setRec = this.getUnitCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigurUnitSave, this)
  },
  onConfigurUnitSave: function (v, k) {
    var setRec = this.getUnitCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setUnit(res)
  }
})
