Ext.define('MDM.src.view.globalapp.model.EditCal', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Global_MDM_Grid_Calendar',
  idProperty: 'id',
  fields: [
    {
      name: 'Title',
      type: 'string'
    },
    {
      name: 'startDate',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s',
      dateWriteFormat: 'Y-m-d\\TH:i:s'
    },
    {
      name: 'endDate',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s',
      dateWriteFormat: 'Y-m-d\\TH:i:s'
    },
    {
      name: 'ReportingPeriodId',
      type: 'string'
    },
    {
      name: 'isEditable',
      type: 'boolean'
    },
    {
      name: 'diff',
      convert: function (value, record) {
        return Ext.Date.diff(record.get('startDate'), record.get('endDate'), Ext.Date.DAY) + 1
      },
      depends: ['startDate', 'endDate']
    },
    {
      name: 'startDateTime',
      convert: function (value, record) {
        return Ext.Date.format(record.get('startDate'), 'H')
      },
      depends: ['startDate', 'endDate']
    },
    {
      name: 'endDateTime',
      convert: function (value, record) {
        return Ext.Date.format(record.get('endDate'), 'H')
      },
      depends: ['startDate', 'endDate']
    },
    {
      name: 'diff',
      convert: function (value, record) {
        return Ext.Date.diff(record.get('startDate'), record.get('endDate'), Ext.Date.DAY) + 1
      },
      depends: ['startDate', 'endDate']
    }
  ]
})
