import React from 'react'
import { OverlayTrigger } from '../../index'
import { IGridHeaderProps } from './GridHeader.types'

const GridHeader = (props: IGridHeaderProps) => {
  const {
    onLockActionHandle = () => {},
    onApproveActionHandle = () => {},
    actionButtonsVisibility: {
      isLock: isVisibleLock = false,
      isApprove: isVisibleApprove = false
    } = {},
    customField = '',
    hasActionButtons = false,
    hasSummary = false,
    headers,
    summaryText = ''
  } = props

  if (!headers.length) {
    return null
  }

  return (
    <div className="slvy-ui-row -header">
      <div className="slvy-ui-section -title">
        <i />
      </div>
      <div className="slvy-ui-section -values">
        {headers.map((header, headerIndex) => {
          let {
            TooltipText = '',
            Text,
            LockKey = {},
            ApproveKey = {},
            [customField]: _CustomField = ''
          } = header

          if (hasActionButtons) {
            LockKey = { ...LockKey, fieldName: Text }
            ApproveKey = { ...ApproveKey, fieldName: Text }
          }

          return (
            <div key={`grid-header-cell-${headerIndex}`} className="-cell" data-id={headerIndex}>
              <OverlayTrigger shouldWrap={Boolean(TooltipText)} tooltip={TooltipText}>
                {TooltipText ? <section>{Text}</section> : <span>{Text}</span>}
              </OverlayTrigger>
              {_CustomField}
              {hasActionButtons}
              {hasActionButtons ? (
                isVisibleLock || isVisibleApprove ? (
                  <span className="-cell-btn-group">
                    {isVisibleLock ? (
                      <OverlayTrigger tooltip={LockKey.name}>
                        <i
                          className={LockKey.className}
                          onClick={() => onLockActionHandle(LockKey)}
                        />
                      </OverlayTrigger>
                    ) : null}
                    {isVisibleApprove ? (
                      <OverlayTrigger tooltip={ApproveKey.status}>
                        <i
                          className={ApproveKey.className}
                          onClick={() => onApproveActionHandle(ApproveKey)}
                        />
                      </OverlayTrigger>
                    ) : null}
                  </span>
                ) : null
              ) : null}
            </div>
          )
        })}
      </div>
      <div className="slvy-ui-section -end d-flex align-items-center justify-content-center">
        {hasSummary ? <strong className="fw-bold">{summaryText}</strong> : null}
      </div>
    </div>
  )
}

export default GridHeader
