export default () => {
  return {
    title: 'ButtonGroup',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      buttonGroups: {
        type: 'array',
        title: 'Button Groups',
        items: {
          type: 'object',
          title: 'Button Group',
          properties: {
            name: {
              type: 'string',
              title: 'Name'
            },
            textAlign: {
              type: 'string',
              title: 'Title Text Align',
              default: 'center',
              enum: ['left', 'center', 'right'],
              required: true
            },
            groupButtonsTextAlign: {
              type: 'string',
              title: 'Group Buttons Text Align',
              default: 'center',
              enum: ['left', 'center', 'right'],
              required: true
            },
            groupOptions: {
              type: 'string',
              title: 'Type',
              default: 'none',
              enum: ['none', 'dropdown', 'split'],
              required: true
            },
            style: {
              type: 'string',
              title: 'Style',
              default: 'primary',
              enum: ['success', 'primary', 'info', 'warning', 'danger', 'link'],
              required: true
            },
            icon: {
              type: 'string',
              title: 'Icon',
              default: 'fa-check',
              format: 'IconSelector'
            },
            iconPosition: {
              type: 'string',
              title: 'Icon Position',
              default: 'Left',
              enum: ['Left', 'Right'],
              required: true
            }
          }
        }
      },

      buttons: {
        type: 'array',
        title: 'Buttons',
        items: {
          type: 'object',
          title: 'Button',
          headerTemplate: '{{ self.text }}',
          options: {
            collapsed: true
          },
          properties: {
            buttonGroup: {
              type: 'string',
              title: 'Button Group',
              watch: {
                ds: 'buttonGroups'
              },
              enumSource: [
                {
                  source: [
                    {
                      value: ''
                    }
                  ],
                  title: '{{item.value}}',
                  value: '{{item.value}}'
                },
                {
                  source: 'ds',
                  value: '{{item.name}}'
                }
              ],
              required: true
            },
            text: {
              type: 'string',
              title: 'Text'
            },
            style: {
              type: 'string',
              title: 'Style',
              default: 'primary',
              enum: ['success', 'primary', 'info', 'warning', 'danger', 'link'],
              required: true
            },
            icon: {
              type: 'string',
              title: 'Icon',
              default: 'fa-check',
              format: 'IconSelector'
            },
            iconPosition: {
              type: 'string',
              title: 'Icon Position',
              default: 'Left',
              enum: ['Left', 'Right'],
              required: true
            },
            seperator: {
              type: 'string',
              title: 'Seperator',
              default: 'none',
              enum: ['none', 'top', 'bottom', 'left', 'right'],
              required: true
            },
            confirmation: {
              type: 'boolean',
              title: 'Confirmation',
              required: true,
              default: false
            },
            confirmationText: {
              type: 'string',
              title: 'Confirmation Text',
              default: 'Are you sure to continue?'
            },
            confirmationTitle: {
              type: 'string',
              title: 'Confirmation Title',
              default: 'Confirmation'
            }
          }
        }
      }
    }
  }
}
