window.JSONEditor.defaults.editors.HiddenTable = window.JSONEditor.defaults.editors.array.extend({
  preBuild() {
    this._super()
    this.options.hidden = true
  }
})

export default {
  name: 'HiddenTable',
  format: 'HiddenTable'
}
