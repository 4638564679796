import React from 'react'
import _ from 'lodash'
import cx from 'classnames'

class Dropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      enabledMenus: [],
      isOpen: false
    }

    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    const {
      settings: {
        appSettings: {
          productionView: {
            isEnabled: isProductionViewEnabled = true,
            title: productionViewTitle = 'Production Plan'
          } = {},
          resourceView: {
            isEnabled: isResourceViewEnabled = true,
            title: resourceViewTitle = 'Resource Plan'
          } = {},
          resourceScheduleView: {
            isEnabled: isResourceScheduleViewEnabled = true,
            title: resourceScheduleViewTitle = 'Resource Schedule'
          } = {},
          shiftCalendarView: {
            isEnabled: isShiftCalendarViewEnabled = true,
            title: shiftCalendarViewTitle = 'Shift Calendar'
          } = {},
          resourceAndProductionView: {
            isEnabled: isResourceAndProductionPlanViewEnabled = true,
            title: resourceAndProductionViewTitle = 'Resource & Production Plan'
          } = {}
        } = {}
      }
    } = this.props

    const menus = [
      {
        isEnabled: isProductionViewEnabled,
        title: productionViewTitle
      },
      {
        isEnabled: isResourceViewEnabled,
        title: resourceViewTitle
      },
      {
        isEnabled: isResourceScheduleViewEnabled,
        title: resourceScheduleViewTitle
      },
      {
        isEnabled: isShiftCalendarViewEnabled,
        title: shiftCalendarViewTitle
      },
      {
        isEnabled: isResourceAndProductionPlanViewEnabled,
        title: resourceAndProductionViewTitle
      }
    ]

    const enabledMenus = _.reduce(
      menus,
      (enabledMenus, menu, index) =>
        menu.isEnabled
          ? [
              ...enabledMenus,
              <a
                key={`${index}-${menu.title}`}
                className="list-item"
                onClick={this.handleClickMenu.bind(this)}
              >
                <span className="list-item__text">{menu.title}</span>
              </a>
            ]
          : enabledMenus,
      []
    )

    this.setState({ enabledMenus })

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  toggleMenus() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleClickMenu(event) {
    const {
      target: { innerText: menu = '' }
    } = event

    this.setState({
      isOpen: !this.state.isOpen
    })
    this.props.onClickMenu(menu)
  }

  handleClickOutside(event) {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({
        isOpen: false
      })
    }
  }

  setDropdownRef(node) {
    this.dropdownRef = node
  }

  render() {
    return (
      <div ref={this.setDropdownRef.bind(this)}>
        <div
          className={cx('rccp-dropdown', { 'rccp-dropdown_open': this.state.isOpen })}
          onClick={this.toggleMenus.bind(this)}
        >
          <i className="rccp-dropdown__icon fa-fw fa slvy-ui-icon-dot1" />
        </div>
        {this.state.isOpen && <nav className="menu-list">{this.state.enabledMenus}</nav>}
      </div>
    )
  }
}

export default Dropdown
