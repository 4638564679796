import { useCallback } from 'react'
import { handleAsyncAwait } from '@/helpers'
import axiosWithDefaults from '../../axiosWithDefaults'
import { HandleAsyncAwaitReturnType } from '../../types'
import { EditRequestPayload, HandleEdit, HandleEditProps, ResultData } from './useHandleEdit.types'
import { shouldRefreshData, mapFilters } from '../../helpers/index'

function useHandleEdit(props: HandleEditProps) {
  const { additionalArgs, AG_GRID_URL, gridRef, resetCustomRowInfo, resetDeletedRows } = props

  const handleEdit = useCallback(
    async ({
      shouldIncludeUpdateRequest,
      doesDirectUpdate,
      payloadBodies,
      updateParameters
    }: HandleEdit): Promise<ResultData | undefined> => {
      if (shouldIncludeUpdateRequest && payloadBodies.length === 0) {
        return undefined
      }

      const context = gridRef?.current?.api?.getGridOption('context') || {}

      const payload: EditRequestPayload = {
        updatehints: {},
        filters: {
          ...mapFilters(gridRef),
          ...context?.actualFilters,
          ...updateParameters,
          ...additionalArgs
        }
      }

      const promises: Promise<HandleAsyncAwaitReturnType>[] = []

      payloadBodies.forEach((item) => {
        payload.type = item.type
        payload.records = item.records
        const request = axiosWithDefaults({
          url: `${AG_GRID_URL}/edit`,
          method: 'POST',
          data: payload
        })
        promises.push(handleAsyncAwait(request) as Promise<HandleAsyncAwaitReturnType>)
      })

      const responses = await Promise.all(promises)

      let resultData: ResultData = {}

      responses.forEach((result, index) => {
        const { name } = payloadBodies[index]
        const [data, error] = result

        const { shouldRefresh, shouldReset } = shouldRefreshData({
          name,
          doesDirectUpdate,
          error
        })

        resultData = {
          ...resultData,
          [name]: {
            data,
            error: error?.data?.result ?? [],
            shouldRefresh
          }
        }

        if (shouldReset) {
          // update means /edit request with type: 0
          if (name === 'edit' || name === 'add') {
            resetCustomRowInfo()
          } else if (name === 'delete') {
            resetDeletedRows()
          }
        }
      })
      return resultData
    },
    [gridRef, additionalArgs, AG_GRID_URL, resetCustomRowInfo, resetDeletedRows]
  )

  return { handleEdit }
}

export default useHandleEdit
