export default function resolveClassName(classGetter?: any, ...params: any[]) {
  if (typeof classGetter === 'string') {
    return classGetter
  }

  if (typeof classGetter === 'function') {
    return classGetter(...params)
  }

  return ''
}
