export default () => {
  return {
    title: 'ObjectViewer',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          type: {
            type: 'string',
            enum: ['application/pdf', 'application/json'],
            default: 'application/pdf',
            required: true,
            title: 'Type'
          },
          theme: {
            type: 'string',
            enum: ['light', 'dark'],
            default: 'light',
            required: true,
            title: 'Theme'
          },
          url: {
            type: 'string',
            format: 'textarea',
            title: 'Parameter'
          }
        }
      }
    }
  }
}
