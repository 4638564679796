import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import * as d3 from 'd3'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import fontAwesomeUnicodeMap from '@/assets/scripts/font-awesome-unicode-map'
import './FilterDonut.scss'

const colorConverter = (color, type) => {
  if (color !== undefined && type !== undefined) {
    const isDarken = type === 'dark'
    const amt = Math.round(2.55 * 10)
    let [r, g, b] = color
      .replace(/([^0-9,]{0,3})/gm, '')
      .split(',')
      .map((item) => {
        return parseInt(item, 10)
      })

    r = isDarken ? r - amt : r + amt
    g = isDarken ? g - amt : g + amt
    b = isDarken ? b - amt : b + amt

    return `rgb(${r},${g},${b})`
  }
  return ''
}

class FilterDonut extends Component {
  constructor(props) {
    super(props)
    this.state = {
      settings: { ...props.settings },
      guid: 0,
      attributes: [],
      level: [],
      total: []
    }

    this.getGuid = this.getGuid.bind(this)
    this.$ = this.$.bind(this)
    this.createChart = this.createChart.bind(this)
    this.calculatePercentage = this.calculatePercentage.bind(this)
    this.openClose = this.openClose.bind(this)
    this.drawFilter = this.drawFilter.bind(this)
    this.getData = this.getData.bind(this)
    this.createBreadCrumb = this.createBreadCrumb.bind(this)
    this.getFirstLevel = this.getFirstLevel.bind(this)
    this.backClickHandle = this.backClickHandle.bind(this)
    this.clickUpdate = this.clickUpdate.bind(this)
    this.clickHandle = this.clickHandle.bind(this)
    this.handleItemSelected = this.handleItemSelected.bind(this)
    this.colorfy = this.colorfy.bind(this)
    this.getMousePosition = this.getMousePosition.bind(this)
  }

  getMousePosition(evt) {
    let { pageX } = evt
    let { pageY } = evt
    if (pageX === undefined) {
      pageX = evt.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
      pageY = evt.clientY + document.body.scrollTop + document.documentElement.scrollTop
    }

    const rect = evt.target.getBoundingClientRect()
    const offsetX = evt.clientX - rect.left
    const offsetY = evt.clientY - rect.top

    return {
      client: { x: evt.clientX, y: evt.clientY },
      screen: { x: evt.screenX, y: evt.screenY },
      offset: { x: offsetX, y: offsetY },
      page: { x: pageX, y: pageY },
      rect
    }
  }

  getGuid(count) {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    let retval = ''

    count = count || 1

    for (let i = 0; i < count; i++) {
      retval += `${s4()}${i !== count - 1 ? '-' : ''}`
    }

    return retval
  }

  $(element) {
    const { guid } = this.state
    const guidContainer = `#attr-fc-${guid}`

    element = `${guidContainer} ${element}`

    return element
  }

  createChart() {
    const svgElement = this.$('svg')
    d3.selectAll(`${svgElement} g`).remove()

    const containerObj =
      this.container !== undefined
        ? ReactDOM.findDOMNode(this.container).parentNode
        : { clientWidth: 0, clientHeight: 0 }
    const width = containerObj.clientWidth
    const height = containerObj.clientHeight
    const radius = Math.min(width, height) / 2
    const settings = {
      duration: 350,
      strokeWidth: 1.2
    }

    d3.select('#donut-tooltip').remove()

    d3.select('body')
      .append('div')
      .attr('class', 'tooltip')
      .attr('id', 'donut-tooltip')
      .style('opacity', '0')
      .style('visibility', 'hidden')

    return {
      containerObj,
      width,
      height,
      radius,
      settings,
      g: d3
        .select(svgElement)
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`),
      partition: d3.partition().size([2 * Math.PI, radius])
    }
  }

  calculatePercentage(a, b) {
    return (a / b) * 100
  }

  openClose(target, sw) {
    const element = d3.select(target === 'tooltip' ? '#donut-tooltip' : this.$(`.${target}`))

    if (sw) {
      element.style('opacity', 1).style('visibility', 'visible')
    } else {
      element.style('opacity', 0).style('visibility', 'hidden')
    }
  }

  drawFilter(data) {
    this.openClose('donut-popup', false)

    const { g, partition, settings } = this.createChart()
    const basecolor = this.colorfy('basecolor')

    const stateLevel = this.state.level
    this.openClose('back', stateLevel.length > 0)

    const arcTweenPath = (a, i) => {
      const oi = d3.interpolate({ x0: a.x0s, x1: a.x1s }, a)

      function tween(t) {
        const b = oi(t)
        a.x0s = b.x0
        a.x1s = b.x1
        return arc(b)
      }

      return tween
    }
    const root = d3
      .hierarchy(data)
      .sum((d) => {
        return d.size
      })
      .sort((a, b) => {
        return b.value - a.value
      })

    partition(root)

    let arc = d3
      .arc()
      .startAngle((d) => {
        d.x0s = d.x0
        return d.x0
      })
      .endAngle((d) => {
        d.x1s = d.x1
        return d.x1
      })
      .innerRadius((d) => {
        return d.y0 === 0 ? 100 : d.y0
      })
      .outerRadius((d) => {
        return d.y1 - 2.5
      })
      .cornerRadius(4)
      .padAngle(0.01)

    const slice = g.selectAll('g.node').data(root.descendants(), (d) => {
      return d.data.name
    })
    const newSlice = slice.enter().append('g').attr('class', 'node').merge(slice)

    slice.exit().remove()
    slice.selectAll('path').remove()

    newSlice
      .append('path')
      .attr('display', (d) => {
        return d.depth ? null : 'none'
      })
      .attr('d', arc)
      // .style('stroke', basecolor.white)
      // .style('stroke-width', settings.strokeWidth)
      .style('fill', basecolor.white)
      .transition()
      .duration(settings.duration)
      .attrTween('d', arcTweenPath)
      .attr('data-base-color', (d) => {
        return d.data.color !== undefined
          ? d.data.color
          : this.colorfy(d.data.id !== 0 ? (d.depth > 3 ? 3 : d.depth) : 'base').background
      })
      .style('fill', (d) => {
        return d.data.color !== undefined
          ? d.data.color
          : this.colorfy(d.data.id !== 0 ? (d.depth > 3 ? 3 : d.depth) : 'base').background
      })

    /// Populate text items
    slice.selectAll('text').remove()
    newSlice
      .append('text')
      .attr('transform', (d) => {
        const arcarr = arc.centroid(d)
        let dataDeg = ((180 / Math.PI) * (arc.startAngle()(d) + arc.endAngle()(d))) / 2 - 90

        dataDeg = dataDeg > 90 ? dataDeg - 180 : dataDeg > 88 && dataDeg < 92 ? 0 : dataDeg

        return `translate(${arcarr})rotate(${Math.abs(dataDeg) > 10 ? dataDeg : 0}, 0, 0)`
      })
      .attr('text-anchor', 'middle')
      .style('fill', (d) => {
        return this.colorfy(d.depth > 3 ? 3 : d.depth).text
      })
    // .attr('dy', '.5em')

    newSlice
      .select('text')
      .append('tspan')
      .text((d) => {
        return d.parent ? (d.data.parent !== undefined ? d.data.parent : d.data.name) : ''
      })

    const col = this.props.settings.config.columns
    const levelLimit = stateLevel.length > 0 ? stateLevel[stateLevel.length - 1].id : 0
    let text = ''

    newSlice
      .select('text')
      .append('tspan')
      .text((d) => {
        if (d.parent !== null) {
          const selectedItem = col.filter((item) => {
            return item.column === d.parent.data.column
          })

          if (selectedItem.length > 0) {
            const { displayValue } = selectedItem[0]

            console.log(displayValue)

            if (displayValue === 'Percentage') {
              if (d.data.percentage && d.parent.data.percentage) {
                text = `${
                  d.depth < levelLimit
                    ? d.parent.data.percentage.toFixed(1)
                    : d.data.percentage.toFixed(1)
                } %`
              }
            } else if (displayValue === 'Total') {
              text = d.depth < levelLimit ? d.parent.data.selectedSize : d.data.size
            } else if (displayValue === 'Both') {
              if (d.data.percentage && d.parent.data.percentage) {
                text =
                  d.depth < levelLimit
                    ? d.parent.data.selectedSize
                    : `${d.data.size} - ${
                        d.depth < levelLimit
                          ? d.parent.data.percentage.toFixed(1)
                          : d.data.percentage.toFixed(1)
                      } %`
              }
            } else {
              text = ''
            }
          }
        }
        return text
      })
      .attr('x', '0')
      .attr('dy', '15')

    newSlice
      .on('click', (c, i) => {
        const collection = {
          root,
          newSlice
        }
        this.clickHandle(c, i, collection)
      })
      .select('path')
      .on('mouseover', this.mouseOverHandle)
      .on('mousemove', this.mouseMoveHandle)
      .on('mouseout', this.mouseOutHandle)

    this.openClose('tooltip', false)
  }

  getData(param, index) {
    const { attributes } = this.state
    const { level } = this.state
    const {
      pluginData = {},
      settings: { config: { settings: { totalcount = 0 } = {} } = {} } = {}
    } = this.props

    let arr = []
    let findedLevel = level.find((item) => {
      return item.column === param.column
    })

    if (findedLevel === undefined) {
      level.push({
        column: param.column,
        name: param.name,
        parent: param.parent,
        index,
        size: 0,
        color: param.color,
        id: index + 1,
        otherslimit: param.otherslimit
      })
    } else {
      param.filter = undefined
      findedLevel = Object.assign(findedLevel, param)
    }

    level.forEach((item) => {
      if (item.filter !== undefined) {
        arr = _.groupBy(arr === undefined || arr.length === 0 ? pluginData : arr, item.column)[
          item.filter
        ]
      } else {
        arr = _.groupBy(arr === undefined || arr.length === 0 ? pluginData : arr, item.column)
      }
    })

    var stateToObject = (array, parent, tree) => {
      array = array.map((item, index) => {
        item.id = index + 1
        item.index = index

        return { ...item }
      })

      tree = tree || []
      parent = parent || { id: 0 }

      const children = _.filter(array, (child) => {
        return child.index === parent.id
      })

      if (!_.isEmpty(children)) {
        if (parent.id === 0) {
          tree = children
        } else {
          parent.children = children
        }
        _.each(children, (child) => {
          stateToObject(array, child)
        })
      } else {
        let sumOftotalCount = 0
        for (const i in arr) {
          if (arr.hasOwnProperty(i)) {
            sumOftotalCount += _.sumBy(arr[i], totalcount)
          }
        }

        let sumOfArrLength = 0
        for (const i in arr) {
          sumOfArrLength += arr[i].length
        }

        parent.children = _.map(arr, (item, name) => {
          const totalCount = totalcount !== '' ? _.sumBy(item, totalcount) : item.length

          this.state.total.push({
            name,
            sum: totalCount
          })

          return {
            name,
            size: totalCount,
            attribute: attributes,
            percentage: parseFloat(
              this.calculatePercentage(
                totalCount,
                totalcount !== '' ? sumOftotalCount : sumOfArrLength
              ),
              10
            )
          }
        })

        // if(parent['children'].length > 10 ) {
        const tmpArr = []

        parent.children = _.reduce(
          parent.children,
          (result, item) => {
            if (
              item.size <
              (totalcount !== ''
                ? (sumOftotalCount / 100) * parent.otherslimit
                : (sumOfArrLength / 100) * parent.otherslimit)
            ) {
              tmpArr.push(item)
            } else {
              result.push(item)
            }
            return result
          },
          []
        )
        if (tmpArr.length > 0) {
          parent.children.push({
            name: 'Others',
            attribute: tmpArr,
            color: '#FF5722',
            percentage: parseFloat(
              _.reduce(
                tmpArr,
                (arr, item) => {
                  return (arr += parseFloat(item.percentage, 10))
                },
                0
              ).toFixed(1),
              10
            ),
            size: _.reduce(
              tmpArr,
              (arr, item) => {
                return (arr += item.size)
              },
              0
            ),
            id: 2
          })
        }
        console.log(parent.children)
        // }
      }

      return tree[0]
    }
    const topThree = level.length > 3 ? level.slice(level.length - 3, level.length) : level
    const dataLayer = stateToObject(topThree)

    this.openClose('dp-info', false)
    this.createBreadCrumb(level)
    console.log(level)
    return dataLayer
  }

  createBreadCrumb(level) {
    const breadCrumb = d3.select(this.$('.bread-crumb'))
    let html = ''
    if (level !== undefined) {
      html = level
        .map((item, index) => {
          const beforeIndex = level.length > 1 ? index - 1 : 0
          const selectedSize = level[beforeIndex] || ''
          const colorfy = this.colorfy(index > 3 ? 3 : index)
          return `<li style="background-color:${item.color || colorfy.background}; color:${
            colorfy.text
          };" class="item-${index}">
            <i style="border-left-color:${item.color || colorfy.background};"></i>
            <a>${item.parent || item.name} ${
            selectedSize.selectedSize !== undefined ? `(${selectedSize.selectedSize})` : ''
          }</a>
          </li>`
        })
        .join('')
    }

    breadCrumb.classed('dn', html.trim() === '').html(html)
  }

  getFirstLevel() {
    this.state.level = []

    let { columns } = this.props.settings.config
    this.handleItemSelected([])

    /// First Level
    if (columns !== undefined) {
      columns = columns.map((item, index) => {
        return {
          id: 0,
          name: item.title,
          column: item.column,
          size: 100 / columns.length,
          color: item.itemColor,
          otherslimit: item.otherslimit
        }
      })

      this.state.attributes = columns

      this.drawFilter({
        depth: 0,
        children: columns
      })

      this.createBreadCrumb()
    }
  }

  backClickHandle(e, i) {
    this.state.total = []
    let { level } = this.state

    if (level.length > 1) {
      const newLevel = level.slice(0, level.length - 1)
      this.state.level = newLevel
      level = newLevel

      const lastLevelObj = level[level.length - 1]

      this.drawFilter(this.getData(lastLevelObj, level.length))
      this.handleItemSelected(level)
    } else {
      this.getFirstLevel()
    }

    return false
  }

  clickUpdate(e, i, elements) {
    const self = elements[i]
    const data = self.dataset
    const depth = parseInt(data.depth, 10)
    const { level } = this.state

    if (!isNaN(depth)) {
      this.drawFilter(this.getData(data, level.length))
    }
  }

  clickHandle(c, i, collection) {
    const tooltip = d3.select('#donut-tooltip')
    const { attributes } = this.state
    const { level } = this.state
    const { depth } = c
    const { column } = c.parent.data
    const { event } = d3

    let selectedLevel

    if (column !== undefined) {
      selectedLevel = level.find((item) => {
        return item.column === column
      })

      if (selectedLevel !== undefined) {
        selectedLevel.filter = c.data.name
        selectedLevel.selectedSize = c.value
        selectedLevel.percentage = c.data.percentage
      }
    }

    attributes.forEach((item) => {
      item.active = Boolean(
        level.filter((fitem) => {
          return fitem.name === item.name
        }).length
      )
    })

    console.log('bool arr', attributes)

    if (c.data.id !== 0) {
      tooltip
        .html(
          attributes
            .map((element) => {
              return element.active
                ? `<a class="ac-${element.active}">${element.name}</a>`
                : `<a data-depth="${depth}" data-parent="${c.data.name}" data-column="${element.column}" data-name="${element.name}">${element.name}</a>`
            })
            .join('')
        )
        .selectAll('a')
        .on('click', this.clickUpdate)
    }

    if (c.data.id === 0) {
      this.drawFilter(this.getData(c.data, 0))
    } else if (c.data.id === 2) {
      const seedData = _.map(c.data.attribute, (item, index) => {
        return Object.assign(item, { width: c.data.attribute.length })
      })
      const donutPopup = d3.select(this.$('.donut-popup'))

      donutPopup.select('span').on('click', () => {
        this.openClose('donut-popup', false)
        this.openClose('dp-info', false)
        donutPopup.selectAll('svg g').remove()
      })

      donutPopup.select('h1 b').text(c.parent.data.name)

      this.openClose('donut-popup', true)

      if (selectedLevel !== undefined) {
        selectedLevel.filter = ''
      }

      this.handleItemSelected(level)
      this.openClose('tooltip', false)

      const containerObj =
        this.container !== undefined
          ? ReactDOM.findDOMNode(this.container).parentNode
          : { clientWidth: 0, clientHeight: 0 }
      const width = containerObj.clientWidth
      const height = containerObj.clientHeight
      const dpInfo = d3.select('.dp-info')

      const radius = Math.min(width, height) / 2
      const arc = d3
        .arc()
        .innerRadius(radius - 60)
        .outerRadius(radius)
        .cornerRadius(2)
        .padAngle(0.006)
      const pie = d3
        .pie()
        .value(function (d) {
          return d.size > 3 ? d.size : d.size * 4
        })
        .sort(null)
      const svg = donutPopup
        .select('svg')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2 - 9})`)
      // svg.append("circle").attr("cx", 0).attr("cy", 0).attr("r", 100).attr("fill", "#fff")

      const g = svg.selectAll('.arc').data(pie(seedData)).enter().append('g').attr('class', 'arc')

      g.append('path')
        .attr('d', arc)
        .attr('fill', '#ff5722')
        .on('click', (dc, di, dcollection) => {
          if (column !== undefined) {
            selectedLevel = level.find((item) => {
              return item.column === column
            })

            if (selectedLevel !== undefined) {
              selectedLevel.filter = dc.data.name
              selectedLevel.selectedSize = dc.value
              selectedLevel.percentage = dc.data.percentage
            }
          }

          const donutEvent = d3.event
          const tooltipBoxSize = tooltip.node().getBoundingClientRect()
          const tooltipCoordinate = this.getMousePosition(donutEvent)
          const tooltipLeft = tooltipCoordinate.page.x
          const tooltipTop = tooltipCoordinate.page.y

          tooltip
            .style('left', `${tooltipLeft}px`)
            .style('top', `${tooltipTop - tooltipBoxSize.height / 2}px`)

          d3.select(this.$('.bread-crumb .last-info')).remove()

          if (donutEvent.target.classList.contains('active')) {
            this.openClose('tooltip', false)
            donutEvent.target.classList.remove('active')
            selectedLevel.filter = ''
            this.handleItemSelected(level)
          } else {
            this.openClose('tooltip', true)
            this.openClose('dp-info', true)
            this.handleItemSelected(level)
            donutEvent.target.classList.add('active')
            if (dc.data.size !== 0) {
              const breadCrumb = d3.select(this.$('.bread-crumb'))
              breadCrumb
                .append('li')
                .attr('class', 'last-info')
                .html(`<i></i><a>${dc.data.name} (${dc.data.size})</a>`)
            }
          }

          console.log(dc, c, level, donutEvent)
        })
        .on('mouseover', (d) => {
          dpInfo.select('strong').text(d.data.name)

          dpInfo.select('.per').text(`Percentage: ${d.data.percentage}`)

          dpInfo.select('.sze').text(`Size: ${d.data.size}`)

          d3.event.target.style.fill = colorConverter('rgb(255, 87, 34)', 'dark')
          this.openClose('dp-info', true)
        })
        .on('mouseout', (d) => {
          d3.event.target.style.fill = 'rgb(255, 87, 34)'
        })

      console.log('sunburst popup', donutPopup, c.data.attribute)
    } else {
      const tooltipBoxSize = tooltip.node().getBoundingClientRect()
      const tooltipCoordinate = this.getMousePosition(event)
      const tooltipLeft = tooltipCoordinate.page.x
      const tooltipTop = tooltipCoordinate.page.y
      const lastInfoElement = d3.select(this.$('.bread-crumb .last-info'))

      lastInfoElement.remove()

      tooltip
        .style('left', `${tooltipLeft}px`)
        .style('top', `${tooltipTop - tooltipBoxSize.height / 2}px`)

      const clicked = c
      const rootPath = clicked.path(collection.root).reverse()
      const { newSlice } = collection

      rootPath.shift()
      newSlice.style('opacity', 0.78)
      newSlice
        .filter((d) => {
          if (d === clicked && d.prevClicked) {
            d.prevClicked = false
            newSlice.style('opacity', 1)
            this.openClose('tooltip', false)

            if (selectedLevel !== undefined) {
              selectedLevel.filter = ''
            }
            this.handleItemSelected(level)

            return true
          }
          if (d === clicked) {
            d.prevClicked = true
            this.openClose('tooltip', true)
            this.handleItemSelected(level)

            if (c.data.size !== 0) {
              const breadCrumb = d3.select(this.$('.bread-crumb'))
              breadCrumb
                .append('li')
                .attr('class', 'last-info')
                .html(`<i></i><a>${c.data.name} (${c.data.size})</a>`)
            }

            return true
          }
          d.prevClicked = false
          return rootPath.indexOf(d) >= 0
        })
        .style('opacity', 1)
    }
  }

  mouseOverHandle(d) {
    this.style.fill = colorConverter(this.dataset.baseColor, 'dark')
  }

  mouseOutHandle() {
    this.style.fill = this.dataset.baseColor
  }

  mouseMoveHandle() {}

  handleItemSelected(level) {
    const { columns } = this.props.settings.config
    return _.transform(
      columns,
      (result, item) => {
        const filteredLevel = level.find((fitem) => {
          return fitem.name === item.title
        })
        result[item.title] = _.isEmpty(filteredLevel) ? null : filteredLevel.filter
      },
      {}
    )
  }

  colorfy(param) {
    const colors = {
      get basecolor() {
        return {
          black: '#000000',
          white: '#ffffff'
        }
      },
      get inactive() {
        return {
          background: '#a1a1a1',
          hover: '',
          text: colors.basecolor.black
        }
      },
      get base() {
        return {
          background: '#0069bf',
          hover: '#0039b4',
          text: colors.basecolor.white
        }
      },
      get color() {
        return {
          0: {
            background: colors.basecolor.black,
            text: colors.basecolor.white
          },
          1: {
            background: '#c70039',
            hover: '#ac0031',
            text: colors.basecolor.white
          },
          2: {
            background: '#900c3f',
            hover: '#9f003d',
            text: colors.basecolor.white
          },
          3: {
            background: '#581845',
            hover: '#721e59',
            text: colors.basecolor.white
          }
        }
      }
    }

    if (param !== undefined) {
      if (typeof param === 'string') {
        return colors[param]
      }
      return colors.color[param]
    }
  }

  componentWillUnmount() {
    this.openClose('tooltip', false)
  }

  componentDidUpdate(prevProps, prevState) {
    const arePropsChanged = !_.isEqual(prevProps, this.props)
    const areStatesChanged = !_.isEqual(prevState, this.state)
    if (arePropsChanged || areStatesChanged) {
      this.getFirstLevel()
    }
  }

  componentDidMount() {
    // const level = this.state.level
    this.setState({ guid: this.getGuid(2) })

    this.props.registerMethod({
      key: 'setValue',
      fn: this.collectAttribute.bind(this),
      args: _.map(
        this.props.settings.config.columns,
        (item, index = {}) => {
          return { name: item.title, type: PluginTypes.string }
        },
        {}
      )
    })

    this.handleItemSelected = this.props.registerEvent({
      key: 'itemSelected',
      fn: this.handleItemSelected.bind(this),
      returnTypes: _.transform(
        this.props.settings.config.columns,
        (result, item = {}) => {
          result[item.title] = PluginTypes.string
        },
        {}
      )
    })
  }

  collectAttribute(values) {
    return {}
  }

  UNSAFE_componentWillMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ settings: nextProps.settings })
  }

  render() {
    const { settings, guid } = this.state

    return settings.config.settings !== undefined ? (
      <div
        ref={(input) => {
          this.container = input
        }}
        className="attr-filter-container"
        id={`attr-fc-${guid}`}
      >
        <ol className="bread-crumb breadcrumb dn">{settings.config.settings.title}</ol>
        <div className="attr-filter-wrapper">
          <svg className="attr-filter" />
          <a
            className="back"
            style={{ opacity: 0, visibility: 'hidden' }}
            onClick={this.backClickHandle}
          >
            {fontAwesomeUnicodeMap['arrow-left']}
          </a>
        </div>
        <div className="donut-popup" style={{ visibility: 'hidden', opacity: 0 }}>
          <h1>
            <strong>Details of</strong> <b />
          </h1>
          <span className="fa fa-close" />
          <svg />
          <div className="dp-info" style={{ visibility: 'hidden', opacity: 0 }}>
            <strong />
            <b className="per" />
            <b className="sze" />
          </div>
        </div>
      </div>
    ) : (
      <div>
        Filter Donut <br />
        No data to display
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings
})

export default createPlugin(FilterDonut, selectConnectorProps)
