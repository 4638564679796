import _ from 'lodash'
import React, { Component } from 'react'
import optionFieldsList from '../../utils/optionFieldsList'

export class SidebarFields extends Component {
  constructor(props) {
    super(props)
    this._optionViewColumns = this.getOptionViewColumns()
  }

  getOptionViewColumns() {
    const { props: { optionViewColumns = [] } = {} } = this
    const startEndDate = this.getStartEndDate(optionViewColumns)

    const _optionViewColumns = _.filter(
      optionViewColumns,
      (column) => column.name !== 'StartDate' && column.name !== 'EndDate'
    )
    if (startEndDate) {
      _optionViewColumns.splice(startEndDate.index, 0, startEndDate)
    }
    return _optionViewColumns
  }

  getStartEndDate(optionViewColumns) {
    const startDate = _.find(optionViewColumns, (column) => column.name === 'StartDate')
    const startDateIndex = _.findIndex(optionViewColumns, (column) => column.name === 'StartDate')

    const endDate = _.find(optionViewColumns, (column) => column.name === 'EndDate')
    const endDateIndex = _.findIndex(optionViewColumns, (column) => column.name === 'EndDate')

    let startEndDate = null
    if (
      (startDate && this.getDisplayName(startDate)) ||
      (endDate && this.getDisplayName(endDate))
    ) {
      const displayName = `${
        startDate && this.getDisplayName(startDate) ? this.getDisplayName(startDate) : ''
      } - ${endDate && this.getDisplayName(endDate) ? this.getDisplayName(endDate) : ''}`

      startEndDate = {
        displayName,
        isVisible: true,
        index: startDateIndex !== -1 ? startDateIndex : endDateIndex
      }
    }
    return startEndDate
  }

  getDisplayName(column) {
    if (column.isVisible) {
      return column.displayName
    }
    return null
  }

  getFields() {
    const displayNames = _.map(this._optionViewColumns, (column) => {
      const displayName = this.getDisplayName(column)

      if (!_.isNil(displayName)) {
        return <li key={displayName}>{displayName}</li>
      }
      return null
    })

    return _.isNil(displayNames) ? null : displayNames
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.isHiddenFirstRow !== nextProps.isHiddenFirstRow
  }

  render() {
    const { isHiddenFirstRow = false } = this.props

    return (
      <ul className="text-ctn">
        {isHiddenFirstRow ? null : <li> &nbsp; </li>}
        {this.getFields()}
        <li className="lastCell"> &nbsp; </li>
      </ul>
    )
  }
}
