import React, { Component } from 'react'
import { Popover, Row, FormControl, FormGroup, Col, Button, OverlayTrigger } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'

class Item extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stateName: props.item.name
    }

    this.updateStateName = this.updateStateName.bind(this)
    this.deleteState = this.deleteState.bind(this)
    this.onChangeStateName = this.onChangeStateName.bind(this)
  }

  onChangeStateName(e) {
    this.setState({ stateName: e.target.value })
  }

  popoverClickRootClose() {
    return (
      <Popover className="StateSaverDiv" id="popover-trigger-click-root-close">
        <Popover.Header as="h3" className="mb-0">
          Please write new name
        </Popover.Header>
        <Popover.Body>
          <div className="d-flex gap-2">
            <FormControl
              placeholder="Filter name"
              size="sm"
              type="text"
              value={this.state.stateName}
              onChange={this.onChangeStateName}
            />
            <Button size="sm" variant="success" onClick={this.updateStateName}>
              Save
            </Button>
          </div>
        </Popover.Body>
      </Popover>
    )
  }

  updateStateName(e) {
    document.body.click() // hide popover
    const { item = {} } = this.props || {}
    const { stateName = '' } = this.state
    const newPluginState = {
      ...item,
      name: stateName
    }
    this.props.updateState(newPluginState)
  }

  deleteState(e) {
    e.stopPropagation()
    confirmAlert({
      title: 'Deleting filter?',
      message: 'Are you sure to delete this filter?',
      buttons: [
        {
          label: 'Cancel'
        },
        {
          label: 'Confirm Delete',
          onClick: () => {
            const {
              item: { id }
            } = this.props || {}
            this.props.deleteState(id)
          }
        }
      ]
    })
  }

  render() {
    const { item: { name = 'no name', config = {} } = {} } = this.props

    return (
      <div className="item-ctn d-flex gap-2 p-2 align-items-center list-group-item-secondary">
        <span className="item-name" onClick={() => this.props.loadState(config)}>
          {name}
        </span>
        <OverlayTrigger
          rootClose
          overlay={this.popoverClickRootClose()}
          placement="bottom"
          trigger="click"
        >
          <i className="fa fa-pencil edit-btn ms-auto" />
        </OverlayTrigger>
        <i className="fa fa-trash-o delete-btn" onClick={this.deleteState} />
      </div>
    )
  }
}
export default Item
