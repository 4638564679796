import * as actionTypes from './actionTypes'

export const savePivotSingleDetailViewSettings = (options) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storePivotSingleDetailViewSettings(options))
      resolve()
    })
  }
}

const storePivotSingleDetailViewSettings = (options) => {
  return {
    type: actionTypes.STORE_PIVOT_SINGLE_DETAIL_VIEW_SETTINGS,
    payload: options.payload
  }
}
