import { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'
import { AgGridProps } from '../../../../types'
import { RowAddedEventRef } from './useRegisterEventRowAdded.types'

function useRegisterEventRowAdded(registerEvent: AgGridProps['registerEvent']) {
  const rowAddedEventRef = useRef<RowAddedEventRef>({
    handleRowAdded: (_args: never): never => ({} as never)
  })

  useEffect(() => {
    rowAddedEventRef.current.handleRowAdded = registerEvent({
      key: 'RowAdded',
      fn: () => ({ refreshKey: uuidv4() }),
      returnTypes: { refreshKey: PluginTypes.string }
    })
  }, [registerEvent])

  return { rowAddedEventRef }
}

export default useRegisterEventRowAdded
