import React, { Component } from 'react'
import styled from 'styled-components'
import { DayProps } from './types/Day.types'

const Div = styled.div`
  background: linear-gradient(
    180deg,
    ${(props) => props.color} 8.33%,
    transparent 8.33%,
    transparent 50%,
    ${(props) => props.color} 50%,
    ${(props) => props.color} 58.33%,
    transparent 58.33%,
    transparent 100%
  );
  top: 0;
  left: -32px;
  bottom: 0;
  right: -32px;
  position: absolute;
  background-size: 1px 10px;
  transform: rotate(-45deg);
`
const defaultProps = {
  classes: '',
  day: null,
  title: undefined
}

class Day extends Component<DayProps> {
  static defaultProps = defaultProps
  constructor(props) {
    super(props)

    this.timer = 0
    this.prevent = false

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
  }

  handleClick() {
    this.timer = setTimeout(() => {
      if (!this.prevent) {
        const { dayClicked, day } = this.props
        dayClicked(day)
      }
      this.prevent = false
    }, 250)
  }

  handleDoubleClick() {
    clearTimeout(this.timer)
    this.prevent = true
    const { handleDateSelect, day } = this.props
    handleDateSelect(day)
  }

  handleMouseEnter() {
    const { dayHovered, day } = this.props
    dayHovered(day)
  }

  getItem = (day) => {
    if (day) {
      const strDate = day.format('YYYY-MM-DD')
      const { events = [] } = this.props

      return (
        events.filter((item) => {
          return item.date === strDate
        }) || []
      )
    }

    return []
  }

  render() {
    let { classes, day, title, disableWeekends, weekendsColor } = this.props
    const dailyEvents = this.getItem(day) || []
    const isWeekend = day && (day.day() === 6 || day.day() === 0)

    return (
      <td
        className={classes}
        title={title}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
        onMouseEnter={this.handleMouseEnter}
      >
        <div className="day-container">
          {isWeekend && !disableWeekends && (
            <div className="is-weekend">
              <Div color={weekendsColor} />
            </div>
          )}
          <span className="day-number">{day === null ? '' : day.date()}</span>
          {dailyEvents.slice(0, 5).map(
            ({ tooltipHtml = '', colorField }, index) =>
              tooltipHtml && (
                <div style={{ backgroundColor: colorField }} className="event" key={index}>
                  <div className="tooltiptext" dangerouslySetInnerHTML={{ __html: tooltipHtml }} />
                </div>
              )
          )}
        </div>
      </td>
    )
  }
}

export default Day
