Ext.define('MDM.src.view.Grouping.panel.DPIFamilies', {
  extend: 'Ext.grid.Panel',
  xtype: 'grouping-dpifamily',
  iconCls: 'fa fa-list',
  columnLines: true,
  features: [
    {
      ftype: 'grouping',
      startCollapsed: true,
      groupHeaderTpl: '{name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  listeners: {
    edit: 'onEditDPI'
  },
  selModel: {
    type: 'cellmodel'
  },
  emptyText: '<h3> No Records Found <h3>',
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      text: 'DPI',
      dataIndex: 'DPIFamilyID',

      flex: 1,
      sortable: true,
      renderer: function (value, metaData, record) {
        if (record.get('DPIFamilyID')) {
          return record.get('DPIFamily')
        }
        return 'Not selected'
      },
      editor: {
        xtype: 'combo',
        bind: {
          store: '{DPIListStore}'
        },
        queryMode: 'local',
        typeAhead: true,
        triggerAction: 'all',
        displayField: 'DPIFamily',
        valueField: 'DPIFamilyID'
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'fa fa-chain-broken',
          tooltip: 'Remove',
          handler: 'onDPIRemoveClick'
        }
      ]
    }
  ]
})
