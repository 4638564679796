export default () => {
  return {
    title: 'ECharts Map',
    type: 'object',
    format: 'objectTab',

    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            default: '',
            required: true
          },
          subtitle: {
            type: 'string',
            title: 'Subtitle',
            default: '',
            required: true
          },
          leafletLayer: {
            type: 'boolean',
            title: 'Leaflet Layer',
            default: false,
            required: true
          },
          leafletTileStyle: {
            type: 'string',
            title: 'Leaflet Tile Style',
            default: 'OpenStreetMap',
            enum: ['OpenStreetMap', 'Topographic', 'Stadia', 'StadiaDark']
          },
          theme: {
            type: 'string',
            title: 'Theme',
            enum: ['Light', 'Dark', 'Light gray'],
            required: true
          },
          showAnimation: {
            type: 'boolean',
            title: 'Show Animation',
            default: true,
            required: true
          },
          showVisualMap: {
            type: 'boolean',
            title: 'Heatmap',
            default: false,
            required: true
          },
          // flowColor: {
          //   type: 'string',
          //   format: 'Color',
          //   title: 'Flow Color',
          //   default: '#ffffff',
          //   required: true
          // },
          flowWidth: {
            type: 'number',
            title: 'Flow Width',
            default: '2',
            required: true
          },
          flowCurve: {
            type: 'number',
            title: 'Flow Curve',
            default: '0.2',
            required: true
          },
          defaultLatitude: {
            type: 'number',
            title: 'Lat',
            default: '0',
            required: true
          },
          defaultLongitude: {
            type: 'number',
            title: 'Lon',
            default: '0',
            required: true
          },
          defaultZoomLevel: {
            type: 'number',
            title: 'Zoom',
            default: '6',
            required: true
          },
          autoSymbolSize: {
            type: 'boolean',
            title: 'Auto Symbol Size',
            default: true,
            required: true
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string'
            }
          },
          flowType: {
            type: 'string',
            title: 'Flow Type',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          flowColor: {
            type: 'string',
            title: 'Flow Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          flowWidth: {
            type: 'number',
            title: 'Flow Width',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          flowCurve: {
            type: 'number',
            title: 'Flow Curve',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          flowStyle: {
            type: 'string',
            title: 'Flow Style',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          source: {
            type: 'string',
            title: 'Source',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          sourceSymbol: {
            type: 'string',
            title: 'Source Symbol',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          sourceLat: {
            type: 'string',
            title: 'Source Latitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          sourceLon: {
            type: 'string',
            title: 'Source Longitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          flow: {
            type: 'string',
            title: 'Volume',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          showFlows: {
            type: 'boolean',
            title: 'Show Flows',
            default: true,
            required: true
          },
          showArrow: {
            type: 'string',
            title: 'Flow Arrows',
            default: 'None',
            enum: ['None', 'Start', 'End', 'Both'],
            required: true
          },
          destination: {
            type: 'string',
            title: 'Destination',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          destinationSymbol: {
            type: 'string',
            title: 'Destination Symbol',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          destinationLat: {
            type: 'string',
            title: 'Destination Latitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          destinationLon: {
            type: 'string',
            title: 'Destination Longitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          legendColor: {
            type: 'string',
            title: 'Legend Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          symbols: {
            type: 'array',
            title: 'Symbols',
            items: {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  title: 'Column'
                },
                value: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector'
                },
                color: {
                  type: 'string',
                  format: 'Color',
                  title: 'Color'
                },
                colorData: {
                  type: 'string',
                  format: 'Color',
                  title: 'Color',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds'
                },
                minSize: {
                  type: 'number',
                  title: 'Min Size',
                  default: 12
                },
                maxSize: {
                  type: 'number',
                  title: 'Max Size',
                  default: 22
                }
              }
            }
          }
        }
      },

      filters: {
        type: 'array',
        title: 'Filters',
        items: {
          type: 'object',
          title: 'Filter',
          headerTemplate: '{{ self.filterValue }}',
          properties: {
            filterValue: {
              type: 'string',
              title: 'Column',
              watch: {
                ds: 'data.datafields'
              },
              enumSource: 'ds'
            }
          }
        }
      },

      tooltips: {
        type: 'object',
        title: 'Series',
        properties: {
          rows: {
            type: 'array',
            title: 'Rows',
            items: {
              type: 'object',
              title: 'Row',
              properties: {
                datafields: {
                  type: 'array',
                  format: 'HiddenTable',
                  title: 'Data Fields',
                  items: {
                    type: 'string'
                  }
                },
                color: {
                  type: 'string',
                  format: 'Color',
                  title: 'Color'
                },
                display: {
                  type: 'string',
                  title: 'Display'
                },
                volume: {
                  type: 'string',
                  title: 'Volume',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds'
                },
                volumeDisplay: {
                  type: 'string',
                  title: 'Volume (Display)',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds'
                },
                columns: {
                  type: 'array',
                  title: 'Columns',
                  items: {
                    type: 'object',
                    title: 'Column',
                    properties: {
                      datafields: {
                        type: 'array',
                        format: 'HiddenTable',
                        title: 'Data Fields',
                        items: {
                          type: 'string'
                        }
                      },
                      column: {
                        type: 'string',
                        title: 'Column',
                        watch: {
                          ds: 'data.datafields'
                        },
                        enumSource: 'ds'
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
