import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'
import MovePopup from './components/MovePopup'
import SwitchButton from '../../components/SwitchButton'
import { ScheduleAddForm } from '../../components/index'
import { format, val, getEnabledDisplayNames, restoreScrollPos } from '../../utils'
import { setScrollPos } from '../../actions'

class ScheduleResource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      GetRccpResourceActivityResponseWPF: {},
      resourceDictionary: {},
      endX: 1300,
      currentProductId: -1,
      currentPeriodId: 0,

      selectedProductId: -1,
      isProduct: false,
      isMove: false,

      placement: 'right',
      isUserAllowedToMoveProduction: true,
      isUserAllowedToAddProduction: true
    }

    this.popover = false

    this.scheduleSettings = {
      startX: 0,
      minRange: 1000,
      maxRange: 5000,
      formatDateStr: 'YYYY-MM-DDTHH:mm:ss',
      formatShortDateStr: 'YYYY-MM-DD'
    }

    this.diff = this.diff.bind(this)
    this.calculateX = this.calculateX.bind(this)
    this.getData = this.getData.bind(this)
    this.popoverClickRootClose = this.popoverClickRootClose.bind(this)
    this.drawChartBars = this.drawChartBars.bind(this)
    this.popoverState = this.popoverState.bind(this)
  }

  componentDidMount() {
    const {
      lastRequest: {
        GetRccpResourceActivityResponseWPF: { Request: { productId = 0, periodId = 0 } = {} } = {}
      } = {},
      settings: {
        stateHandler: { resourceScheduleShowAllState = false, resourceSchedule } = {}
      } = {},
      stateHandler,
      isAllowed = () => {},
      scrollPositions
    } = this.props
    const { endX } = this.state

    if (!_.isNil(resourceSchedule.zoomLevel)) {
    }
    if (resourceScheduleShowAllState && productId !== -1) {
      stateHandler({ resourceScheduleShowAllState: false })
    }

    const isUserAllowedToMoveProduction = isAllowed('Resource Schedule/Move Production')
    const isUserAllowedToAddProduction = isAllowed('Resource Schedule/Add Production')

    this.setState(
      {
        currentProductId: productId,
        currentPeriodId: periodId,
        isUserAllowedToMoveProduction,
        isUserAllowedToAddProduction,
        endX: !_.isNil(resourceSchedule.zoomLevel) ? resourceSchedule.zoomLevel : endX
      },
      () => {
        this.getData()
        restoreScrollPos(this.resourceScheduleRef, scrollPositions.resourceSchedule.main)
      }
    )

    window.addEventListener('headerhandler', (e) => {
      const {
        detail: { response = {} }
      } = e

      if (response.Code > 0) {
        this.getData(response)
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { GetRccpResourceActivityResponseWPF: { lastRequest: { Request = {} } = {} } = {} } =
      this.props

    if (
      Request.periodId !==
      nextProps.lastRequest.GetRccpResourceActivityResponseWPF.Request.productId
    ) {
      this.getData()
    }
  }

  partHandler(id, e) {
    e?.stopPropagation()

    if (!_.isEqual(this.state.selectedProductId, id)) {
      const isProduct = id !== -1

      this.setState({
        isProduct,
        selectedProductId: id,
        isMove: true
      })

      if (id === -1) {
        this.setState({
          isMove: false
        })
      } else {
        this.setState({
          isProduct,
          selectedProductId: id,
          isMove: true
        })
      }
    }
  }

  moveHandler(e) {
    e.stopPropagation()

    this.setState({
      isMove: true
    })
  }

  popoverClickRootClose(data) {
    const {
      connectApi,
      lastRequest,
      settings,
      getRccpResourcePeriodResponse,
      resourcePeriodHierarchy,
      coverViolationIndexes
    } = this.props

    return (
      <Popover id="popover-positioned-gantt-form-add">
        <ScheduleAddForm
          connectApi={connectApi}
          coverViolationIndexes={coverViolationIndexes}
          data={data}
          getData={this.getData}
          getRccpResourcePeriodResponse={
            !_.isEmpty(getRccpResourcePeriodResponse)
              ? getRccpResourcePeriodResponse
              : resourcePeriodHierarchy
          }
          lastRequest={lastRequest}
          settings={settings}
        />
      </Popover>
    )
  }

  popoverState(target) {
    this.popover = _.indexOf(target.classList, 'in') > -1
  }

  closePopover() {
    if (this.popover) {
      document.body.click()
    }
  }

  range(start, end, settings = {}) {
    start = moment(start)
    end = moment(end)

    const acc = []
    const { type = 'days', step = 1 } = settings

    while (start <= end) {
      acc.push(start)
      start = _.cloneDeep(start).add(step, type)
    }

    return acc
  }

  diff(startTime, endTime) {
    startTime = moment(startTime)
    endTime = moment(endTime)
    return endTime.diff(startTime, 'seconds')
  }

  calculateX(startTime, endTime, calculatedTime) {
    const { startX } = this.scheduleSettings
    const { endX } = this.state
    const totalSecond = this.diff(startTime, endTime)
    const calculatedSecond = this.diff(startTime, calculatedTime)
    const x = (calculatedSecond * (endX - startX)) / totalSecond + startX

    return x
  }

  partMoveHandler(time, resourceId) {
    const { connectApi } = this.props
    const { currentPeriodId, selectedProductId } = this.state
    const {
      settings: { modificationCount },
      lastRequest: {
        GetRccpResourceActivityResponseWPF: { Request: ActivityRequest }
      }
    } = this.props
    const request = {
      fieldName: 'StartTime',
      newValue: moment(time).subtract('second', 1).format(this.scheduleSettings.formatDateStr),
      periodId: currentPeriodId,
      productId: selectedProductId,
      resourceId,
      rccpConfig: {
        ModificationCount: modificationCount
      }
    }

    ActivityRequest.rccpConfig.ModificationCount = 10000
    connectApi('UpdateRccpResourcePeriodProduct', request).then(() => {
      connectApi('GetRccpResourceActivityResponseWPF', ActivityRequest).then(() => {
        this.getData()
      })
    })
  }

  calculatePlacement(targetRef) {
    const { scrollLeft } = this.refs.rccpBody
    const {
      style: { left = 0, width = 0 }
    } = targetRef.current
    const [leftSize] = _.split(left, 'px')
    const [widthSize] = _.split(width, 'px')
    const boxDistance = parseFloat(leftSize) + 260 + parseFloat(widthSize) - scrollLeft
    const popoverRightSize = boxDistance + 300

    this.setState({
      placement: popoverRightSize > this.props.size.width ? 'left' : 'right'
    })
  }

  drawChartBars(cItem = {}, cIndex = 0, params = {}) {
    const {
      startX: currentStartX = 0,
      endX: currentEndX = 0,
      StartTime = '',
      EndTime = '',
      _OutputItems = [],
      _Parts = [],
      ProductPeriod = {},
      ResourcePeriodProduct = {},
      IsResourcePeriodProduct = !_.isNull(ResourcePeriodProduct),
      ResourceId,
      isOpen,
      Values = IsResourcePeriodProduct
        ? ResourcePeriodProduct.Values || {}
        : ProductPeriod.Values || {}
    } = cItem

    const {
      settings,
      settings: {
        stateHandler: { selectedParameter: { Index: SelectedParamIndex = 0 } = {} } = {}
      } = {},
      connectApi,
      lastRequest,
      coverViolationIndexes,
      isAllowed = () => {}
    } = this.props
    const { placement, isUserAllowedToMoveProduction } = this.state

    const { Id = 0, Description = '' } = _OutputItems[0] || {}
    const { currentProductId, selectedProductId, isProduct } = params

    const IsSelected = Id === currentProductId
    const IsActive = Id === selectedProductId && isProduct
    const IsFirst = currentStartX === 0
    const IsParts = _Parts.length > 1

    return (
      <div
        key={cIndex}
        className="-c-item"
        style={{
          left: currentStartX,
          width: currentEndX - currentStartX
        }}
      >
        <section
          className={`${IsSelected ? '-selected' : ''} ${IsActive ? '-active' : ''} ${
            IsFirst ? '-first-item' : ''
          }`}
        >
          <em title={Description}>{format(val(Values, 'Production', SelectedParamIndex))}</em>

          {!_.isEmpty(_Parts) && (
            <div className={`-sub-item-ctn ${IsParts ? '-parts' : ''}`}>
              {_.map(_Parts, (pItem, pIndex) => {
                const { pStartX = 0, pEndX = 0, status = 'white' } = pItem
                const targetRef = React.createRef()

                return isOpen ? (
                  <MovePopup
                    key={pIndex}
                    formProps={{
                      connectApi,
                      coverViolationIndexes,
                      data: cItem,
                      getData: this.getData,
                      isAllowed,
                      moveHandler: this.moveHandler.bind(this),
                      lastRequest,
                      settings,
                      isUserAllowedToMoveProduction
                    }}
                    placement={placement}
                    style={{
                      left: pStartX,
                      width: pEndX - pStartX,
                      backgroundColor: status
                    }}
                    onClick={this.partHandler.bind(this, Id)}
                    onEnter={() => this.calculatePlacement.bind(this, targetRef)}
                    onEntered={this.popoverState}
                    onExited={this.popoverState}
                  />
                ) : (
                  <div
                    key={pIndex}
                    className="-c-sub-item -cur-default"
                    style={{
                      left: pStartX,
                      width: pEndX - pStartX,
                      backgroundColor: status
                    }}
                  />
                )
              })}
            </div>
          )}

          <i
            className="-part-left slvy-ui-icon-plus-lt"
            onClick={this.partMoveHandler.bind(this, StartTime, ResourceId)}
          />
          <i
            className="-part-right slvy-ui-icon-plus-lt"
            onClick={this.partMoveHandler.bind(this, EndTime, ResourceId)}
          />
        </section>
      </div>
    )
  }

  getData() {
    const { formatDateStr, formatShortDateStr } = this.scheduleSettings
    const { endX, currentPeriodId } = this.state
    const {
      GetRccpResourceActivityResponseWPF: {
        Activities = [],
        Resources = [],
        ItemGroups = [],
        Periods = []
      }
    } = this.props
    const today = moment().format(formatShortDateStr)
    const { ResourcePeriods = [] } =
      _.first(
        _.filter(
          Resources,
          (item) =>
            !_.isEmpty(_.filter(item.ResourcePeriods, (sitem) => !_.isEmpty(sitem.ResourceDays)))
        )
      ) || {}
    const {
      StartTime: startTime = moment().format(formatDateStr),
      EndTime: endTime = moment().format(formatDateStr),
      ResourceDays = []
    } = _.first(ResourcePeriods) || {}
    const hoursData = this.range(startTime, endTime, { type: 'hours' })

    const Days = _.map(ResourceDays, (item) => {
      const x = this.calculateX(startTime, endTime, item.StartTime)
      const ex = this.calculateX(startTime, endTime, item.EndTime)
      const w = ex - x
      const d = moment(item.StartTime)
      const o = {
        fullDate: d.format(formatDateStr),
        dateFormat: d.format('DD MMM dddd'),
        x,
        isToday: d.format(formatShortDateStr) === today,
        dayItemWidth: w
      }

      item.Shifts = _.map(item.Shifts, (sitem) => {
        sitem.sx = this.calculateX(startTime, endTime, sitem.StartTime)
        sitem.sendx = this.calculateX(startTime, endTime, sitem.EndTime)

        return sitem
      })

      return {
        ...item,
        ...o
      }
    })

    const Hours = _.map(hoursData, (item) => {
      const formattedItem = item.format(formatDateStr)
      const x = this.calculateX(startTime, endTime, formattedItem)

      return {
        fullDate: item.format(formatDateStr),
        dateFormat: item.format('HH:mm'),
        x: !_.isNaN(x) ? x : endX,
        isDay: !_.isEmpty(_.find(Days, (i) => i.fullDate === formattedItem))
      }
    })
    const _NewResources = _.map(
      _.filter(Resources, (item) => item.Visible),
      (item) => {
        const { ResourcePeriods = [] } = item
        const { ResourceDays = [] } = _.first(ResourcePeriods)

        item._Shifts = _.map(ResourceDays, (si) => {
          return _.map(si.Shifts, (ms) => {
            const { StartTime, EndTime } = ms
            return {
              ...ms,
              startX: this.calculateX(startTime, endTime, StartTime),
              endX: this.calculateX(startTime, endTime, EndTime)
            }
          })
        })

        item.CurrentActivities = _.map(item.Activities, (aItem) => {
          const acItem = Activities[aItem] || {}

          acItem.startX = this.calculateX(startTime, endTime, acItem.StartTime)
          acItem.setupEndX = this.calculateX(startTime, endTime, acItem.SetupEndTime)
          acItem.endX = this.calculateX(startTime, endTime, acItem.EndTime)

          let partialPartEndX = -1

          acItem._Parts = _.map(acItem.Parts, (pItem) => {
            pItem.pStartX = this.calculateX(startTime, endTime, pItem.StartTime)
            pItem.pEndX = this.calculateX(startTime, endTime, pItem.EndTime)

            if (acItem.setupEndX > pItem.pEndX) {
              pItem.status = '#d2d2d2'
            } else if (acItem.setupEndX > pItem.pStartX) {
              partialPartEndX = pItem.pEndX

              pItem.pEndX = acItem.setupEndX
              pItem.status = '#d2d2d2'
            } else {
              pItem.status = '#e6e6e6'
            }

            return pItem
          })

          if (partialPartEndX > 0) {
            acItem._Parts.push({
              pStartX: acItem.setupEndX,
              pEndX: partialPartEndX,
              status: '#e6e6e6'
            })
          }

          acItem._OutputItems = _.map(acItem.OutputItems, (value, key) => {
            const oItem = ItemGroups[key]
            return oItem
          })

          return acItem
        })

        return item
      }
    )

    const firstPeriodId = currentPeriodId !== 0 ? currentPeriodId : Periods?.[0].Id ?? 0

    this.setState({
      GetRccpResourceActivityResponseWPF: {
        _NewResources,
        Days,
        Hours,
        Periods
      },
      currentPeriodId: firstPeriodId
    })
  }

  getPeriodIndex(Periods, currentPeriodId) {
    const periodIndex = _.findIndex(Periods, (item) => item.Id === currentPeriodId)
    return periodIndex === -1 ? 0 : periodIndex
  }

  changePeriod(direction) {
    const {
      GetRccpResourceActivityResponseWPF: { Periods = [] } = {},
      currentPeriodId,
      currentProductId
    } = this.state
    let currentPeriodIndex = this.getPeriodIndex(Periods, currentPeriodId)
    const targetPeriodIndex = direction ? ++currentPeriodIndex : --currentPeriodIndex
    const targetPeriod = Periods[targetPeriodIndex] || {}
    const { Id = currentPeriodId, StartTime = '', EndTime = '' } = targetPeriod
    const {
      connectApi,
      settings: { modificationCount },
      lastRequest: { GetRccpResourceActivityResponseWPF: { Request = {} } = {} } = {}
    } = this.props

    Request.rccpConfig = {
      ...Request.rccpConfig,
      ...{
        StartDate: StartTime,
        EndDate: EndTime,
        ModificationCount: modificationCount
      }
    }

    Request.periodId = Id
    Request.productId = currentProductId

    this.setState(
      {
        currentPeriodId: Id
      },
      () => {
        connectApi('GetRccpResourceActivityResponseWPF', Request).then((res) => {
          this.getData(res)
        })
      }
    )
  }

  addProductHandler(params, e) {
    e.stopPropagation()

    const { connectApi } = this.props

    connectApi('GetRccpProductsResponse', params)
  }

  showAll() {
    const {
      connectApi,
      lastRequest: {
        GetRccpResourceActivityResponseWPF: {
          Request = {},
          Request: { initialProductId = -1 }
        } = {}
      } = {},
      settings: { stateHandler: { resourceScheduleShowAllState = false } = {} } = {},
      stateHandler
    } = this.props

    if (!resourceScheduleShowAllState) {
      Request.productId = -1
      connectApi('GetRccpResourceActivityResponseWPF', Request)
      stateHandler({ resourceScheduleShowAllState: true })
    } else {
      Request.productId = initialProductId
      connectApi('GetRccpResourceActivityResponseWPF', Request)
      stateHandler({ resourceScheduleShowAllState: false })
    }
  }

  handleScroll = (scrollArea, event) => {
    const { setScrollPos } = this.props
    const {
      target: { scrollTop, scrollLeft }
    } = event

    setScrollPos({
      view: 'resourceSchedule',
      scrollArea,
      scrollPos: { top: scrollTop, left: scrollLeft }
    })
  }

  render() {
    const defaultRowSize = 52
    const {
      GetRccpResourceActivityResponseWPF: {
        _NewResources = [],
        Days = [],
        Hours = [],
        Periods = []
      } = {},
      resourceDictionary,
      endX,
      currentProductId,
      currentPeriodId,
      selectedProductId,
      isProduct,
      isMove,
      isUserAllowedToAddProduction = true
    } = this.state

    const { minRange, maxRange } = this.scheduleSettings

    let activeLines = _.reduce(
      resourceDictionary,
      (agg, value, key) => {
        const { CurrentActivities = [] } = _NewResources[key]
        agg += CurrentActivities.length * defaultRowSize
        return agg
      },
      0
    )

    activeLines += _NewResources.length * defaultRowSize

    const currentPeriodIndex = this.getPeriodIndex(Periods, currentPeriodId)
    const periodLength = Periods.length - 1
    const { Name: periodName = '', DisplayName: periodDisplayName } =
      Periods[currentPeriodIndex] || {}

    const {
      settings: {
        modificationCount,
        stateHandler: { resourceScheduleShowAllState = false } = {},
        periodSettings: {
          displayOptions: { isYearVisible = true, isWeekVisible = true, isMonthVisible = true } = {}
        } = {},
        history: {
          componentState: {
            pushState: { ScheduleResource: { resourceScheduleDictionary = {} } = {} } = {}
          } = {}
        } = {}
      } = {},
      getRccpProductsResponse = {},
      lastRequest: {
        GetRccpResourceActivityResponseWPF: { Request: { productId = 0 } = {} } = {}
      } = {},
      historyHandler,
      stateHandler
    } = this.props

    let switchButtonProps = false
    if (resourceScheduleShowAllState && productId === -1) {
      switchButtonProps = true
    }

    const enabledDisplayNames = _.reduce(
      getEnabledDisplayNames(
        [isYearVisible, isWeekVisible, isMonthVisible],
        periodDisplayName,
        periodName
      ),
      (enabledDisplayNames, enabledDisplayName, index) => [
        ...enabledDisplayNames,
        index ? '-' : '',
        enabledDisplayName
      ],
      []
    )

    return (
      <div
        className={`rcc-wrp rccp-table-gantt w-100 ${isMove ? '-move' : ''}`}
        onClick={this.partHandler.bind(this, -1)}
      >
        <div className="switch-button-wrapper">
          <SwitchButton
            checked={switchButtonProps}
            handleOnChange={this.showAll.bind(this)}
            text="Show All"
          />
        </div>
        <nav className="-period-nav">
          {currentPeriodIndex > 0 && (
            <i className="slvy-ui-icon-caret-left" onClick={this.changePeriod.bind(this, false)} />
          )}

          {enabledDisplayNames}

          {currentPeriodIndex < periodLength && (
            <i className="slvy-ui-icon-caret-right" onClick={this.changePeriod.bind(this, true)} />
          )}
        </nav>
        <div
          className="shift-calendar-container w-100 h-100"
          ref={(element) => {
            if (element) {
              this.resourceScheduleRef = element
            }
          }}
          onScroll={(event) => this.handleScroll('main', event)}
        >
          <header className="rcc-header">
            <div className="header-sticky-section">
              <input
                type="range"
                className="-slider-range"
                min={minRange}
                max={maxRange}
                value={endX}
                step={250}
                onChange={(e) => {
                  let {
                    target: { value = 0 }
                  } = e

                  value = parseInt(value, 10)

                  this.setState({ endX: value }, () => {
                    if (!_.isEqual(value, endX)) {
                      this.getData()
                    }
                  })
                  stateHandler({
                    resourceSchedule: {
                      zoomLevel: value
                    }
                  })

                  this.closePopover()
                }}
              />
            </div>

            <aside ref="rccpHeader">
              <section style={{ width: endX, transform: 'scale(1)' }}>
                {_.map(Days, (dItem, dIndex) => {
                  let { dateFormat = '', x = 0, isToday = false, dayItemWidth, Shifts = [] } = dItem

                  return (
                    <div
                      key={dIndex}
                      className={`scheduleTimeSpanContainer ${isToday ? '-today' : ''}`}
                      style={{
                        left: x,
                        width: dayItemWidth
                      }}
                    >
                      <div className="date">{dateFormat}</div>
                      <div className="shiftContainer">
                        {_.map(Shifts, (shitem, shindex) => {
                          let { Name: shName = '' } = shitem
                          return (
                            <div className="shift" key={`${dIndex}-${shindex}`}>
                              {shName}
                            </div>
                          )

                          // return <div className="shift" style={{
                          //     left: sx,
                          //     width: shw
                          // }} key={`${dIndex}-${shindex}`}>{shName}</div>
                        })}
                      </div>
                    </div>
                  )
                })}
              </section>
            </aside>
          </header>
          <div className="rccp-table">
            <div className="rccp-left">
              <ul>
                {_.map(_NewResources, (resourceItem, resourceIndex) => {
                  let {
                    Id: ResourceId,
                    Name,
                    Visible = false,
                    CurrentActivities = []
                  } = resourceItem
                  let { currentResourceIndex = null, isOpen = null } =
                    resourceDictionary[resourceIndex] || {}
                  let {
                    currentResourceIndex: scheduleCurrentResourceIndex = null,
                    isOpen: scheduleIsOpen = false
                  } = resourceScheduleDictionary[resourceIndex] || {}

                  currentResourceIndex = !_.isNil(currentResourceIndex)
                    ? currentResourceIndex
                    : scheduleCurrentResourceIndex
                  isOpen = !_.isNil(isOpen) ? isOpen : scheduleIsOpen
                  let params = {
                    week: periodName,
                    resourceId: ResourceId,
                    rccpConfig: {
                      ModificationCount: modificationCount
                    },
                    periodId: currentPeriodId
                  }

                  if (Visible) {
                    return (
                      <li
                        key={resourceIndex}
                        className={`${!_.isNil(currentResourceIndex) && isOpen ? '-active' : ''}`}
                      >
                        {
                          <header
                            className="rowHeader"
                            onClick={(e) => {
                              if (currentResourceIndex !== resourceIndex) {
                                resourceDictionary[resourceIndex] = {
                                  currentResourceIndex: resourceIndex,
                                  isOpen: true
                                }
                                historyHandler({
                                  key: 'ScheduleResource',
                                  state: {
                                    resourceScheduleDictionary: {
                                      ...resourceScheduleDictionary,
                                      [resourceIndex]: {
                                        currentResourceIndex: resourceIndex,
                                        isOpen: true
                                      }
                                    }
                                  }
                                })
                              } else {
                                delete resourceScheduleDictionary[resourceIndex]
                                historyHandler({
                                  key: 'ScheduleResource',
                                  state: {
                                    resourceScheduleDictionary
                                  }
                                })
                                delete this.state.resourceDictionary[resourceIndex]
                              }

                              let newState = {
                                ...{ resourceDictionary }
                              }

                              this.setState(newState)
                            }}
                          >
                            <i className="slvy-ui-icon-caret-right -caretIndicator" />
                            <div className="rowLabels">
                              <p>{Name}</p>
                            </div>
                            <div onClick={(e) => e.stopPropagation()}>
                              {isOpen && (
                                <OverlayTrigger
                                  rootClose
                                  rootCloseEvent="mousedown"
                                  trigger="click"
                                  placement="right"
                                  onEntered={this.popoverState}
                                  onExited={this.popoverState}
                                  overlay={this.popoverClickRootClose(
                                    { ...getRccpProductsResponse, ...params },
                                    false
                                  )}
                                >
                                  <button
                                    disabled={!isUserAllowedToAddProduction}
                                    className="-slvy-btn -approve -xsmall"
                                    onClick={this.addProductHandler.bind(this, params)}
                                  >
                                    <i className="slvy-ui-icon-plus-lt" />
                                    Production
                                  </button>
                                </OverlayTrigger>
                              )}
                            </div>
                          </header>
                        }

                        {isOpen && (
                          <div className="-active-list">
                            {_.map(CurrentActivities, (cItem, cIndex) => {
                              let { _OutputItems = [] } = cItem
                              return (
                                <div key={cIndex}>
                                  {_.map(_OutputItems, (oitem, oIndex) => {
                                    let { Id = 0, Description = '', Name = '' } = oitem

                                    return (
                                      <div
                                        key={cIndex}
                                        className={`${
                                          Id === currentProductId ? '-selected' : ''
                                        } -new-left-line`}
                                      >
                                        {/* <i className='slvy-ui-icon-circle-fill' /> */}
                                        <p>{Description}</p>
                                        <p>{Name}</p>
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </li>
                    )
                  }
                })}
              </ul>
            </div>

            <div className="rccp-body" ref="rccpBody" style={{ height: activeLines }}>
              <div className="rccp-gantt-grid" ref="rccpGanttGrid">
                <section style={{ width: endX }}>
                  {_.map(Hours, (hItem, hIndex) => {
                    let { x = 0, isDay = false } = hItem
                    return (
                      <div
                        key={hIndex}
                        className={`${isDay ? '-is-day' : ''}`}
                        style={{
                          left: x
                        }}
                      />
                    )
                  })}
                </section>
              </div>
              {_.map(_NewResources, (resourceItem, resourceIndex) => {
                let {
                  Id: ResourceId = 0,
                  Visible,
                  CurrentActivities = [],
                  _Shifts = []
                } = resourceItem
                let { currentResourceIndex = null, isOpen = null } =
                  resourceDictionary[resourceIndex] || {}
                let {
                  currentResourceIndex: scheduleCurrentResourceIndex = null,
                  isOpen: scheduleIsOpen = false
                } = resourceScheduleDictionary[resourceIndex] || {}

                currentResourceIndex = !_.isNil(currentResourceIndex)
                  ? currentResourceIndex
                  : scheduleCurrentResourceIndex
                isOpen = !_.isNil(isOpen) ? isOpen : scheduleIsOpen

                const drawChartParams = {
                  currentProductId,
                  selectedProductId,
                  isProduct
                }

                if (Visible) {
                  return (
                    <div
                      key={resourceIndex}
                      className={`-parent-row ${
                        !_.isNil(currentResourceIndex) && isOpen ? '-active' : ''
                      }`}
                      style={{ width: endX }}
                      onClick={() => this.setState({ isMove: false })}
                    >
                      <div className="-shift-indicator">
                        {_.map(_Shifts, (shitem) => {
                          return _.map(shitem, (sh) => {
                            let { Enabled, startX, endX, Status } = sh,
                              st = {
                                width: endX - startX,
                                left: startX
                              }
                            return (
                              <div
                                key={`${startX}-${endX}`}
                                className={`-status -st-${Status} ${Enabled}`}
                                style={st}
                              />
                            )
                          })
                        })}
                      </div>

                      <div className="-row -first-list">
                        {!isOpen &&
                          _.map(CurrentActivities, (cItem, cIndex) =>
                            this.drawChartBars(
                              { ...cItem, ResourceId, isOpen },
                              cIndex,
                              drawChartParams
                            )
                          )}
                      </div>

                      {isOpen &&
                        _.map(CurrentActivities, (cItem, cIndex) => {
                          return (
                            <div className="-row" key={cIndex}>
                              {this.drawChartBars(
                                { ...cItem, ResourceId, isOpen },
                                cIndex,
                                drawChartParams
                              )}
                            </div>
                          )
                        })}
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    resourcePeriodHierarchy: state.resourcePeriodHierarchy,
    coverViolationIndexes: state.resourcePlan.coverViolationIndexes,
    scrollPositions: state.scrollPositions
  }
}

const mapDispatchToProps = {
  setScrollPos
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleResource)
