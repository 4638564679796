Ext.define('MDM.src.view.materialedit.model.Material', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/RMDM_Material_Edit',
  storeName: 'eeeeeeeee',
  idProperty: 'ID',
  fields: [
    { name: 'ReportingPeriodID', type: 'int' },
    { name: 'SupplySourceID', type: 'int' },
    { name: 'MaterialID', type: 'int' },
    { name: 'ID', type: 'int' },
    { name: 'MaterialCode', type: 'string' },
    { name: 'MaterialDescription', type: 'string' },
    { name: 'MaterialBUOMID', type: 'int' },
    { name: 'MaterialTypeID', type: 'int' },
    { name: 'GU', type: 'float' },
    { name: 'OwnerID', type: 'int' },
    { name: 'SU', type: 'float', allowNull: true },
    { name: 'Category', type: 'string' },
    { name: 'Brand', type: 'string' },
    { name: 'ProductFormDetail', type: 'string' },
    { name: 'CSUIndicator', type: 'float' },
    { name: 'isActive', type: 'boolean' },
    { name: 'RazorDrawCoefficient', type: 'number' },
    {
      name: 'ExcludedFromProduction',
      type: 'boolean',
      convert: function (value) {
        return value == 1 ? 1 : 0
      }
    },
    {
      name: 'ExcludedFromRevenue',
      type: 'boolean',
      convert: function (value) {
        return value == 1 ? 1 : 0
      }
    }
  ]
})
