Ext.define('MDM.src.view.Grouping.Edit.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.grouping-edit-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {
    var me = this,
      record = this.getView().grpRecord,
      Id = record.get('id'),
      store = me.getViewModel().getStore('GroupDetailsStore')

    me.getViewModel().set('grpRecord', record)
    store.getProxy().setExtraParam('CusHieID', record.get('Id'))
    store.load()
  },
  onAddNode: function (grid, rowIndex, colIndex, item, e, record) {
    var targetStore = this.getViewModel().getStore('GroupDetailsStore'),
      CusHieID = this.getViewModel().get('grpRecord').get('Id')

    if (record.hasChildNodes()) {
      record.cascadeBy(function (node) {
        if (node.get('visible') && node.get('depth') === 3) {
          var parentNode0 = node.parentNode,
            parentNode1 = parentNode0.parentNode,
            ProductGroup = parentNode0.get('text'),
            DPISegment = parentNode1.get('text')

          var rec = targetStore.findRecord(
            'DPIFamilyID',
            node.get('DPIFamilyID'),
            0,
            false,
            true,
            true
          )
          if (!rec) {
            var newRec = Ext.create('MDM.src.view.Grouping.Edit.Model.GroupDetails', {
              DPIFamilyID: node.get('DPIFamilyID'),
              DPIFamily: node.get('text'),
              CusHieID: CusHieID,
              ProductGroup: ProductGroup,
              DPISegment: DPISegment
            })

            targetStore.add(newRec)
          }
        }
      })
    } else {
      var node = record
      if (node.get('visible') && node.get('depth') === 3) {
        var parentNode0 = node.parentNode,
          parentNode1 = parentNode0.parentNode,
          ProductGroup = parentNode0.get('text'),
          DPISegment = parentNode1.get('text')

        var rec = targetStore.findRecord(
          'DPIFamilyID',
          node.get('DPIFamilyID'),
          0,
          false,
          true,
          true
        )
        if (!rec) {
          var newRec = Ext.create('MDM.src.view.Grouping.Edit.Model.GroupDetails', {
            DPIFamilyID: node.get('DPIFamilyID'),
            DPIFamily: node.get('text'),
            CusHieID: CusHieID,
            ProductGroup: ProductGroup,
            DPISegment: DPISegment
          })

          targetStore.add(newRec)
        }
      }
    }
  },
  onDPIBrokeChain: function (grid, rowIndex, colIndex, item, e, record) {
    var targetStore = this.getViewModel().getStore('GroupDetailsStore')
    targetStore.remove(record)
  },
  onCancel: function (btn) {
    this.getViewModel().getStore('GroupDetailsStore').removeAll()
    this.getViewModel().getStore('GroupDetailsStore').load()
  },
  onClose: function () {
    this.getView().close()
  },

  onSearchTree: function (field) {
    var me = this,
      viewModel = me.getViewModel(),
      value = field.getValue(),
      lastFilterValue = viewModel.get('lastFilterValue')
    // Only filter if they actually changed the field value.
    // Otherwise the view refreshes and scrolls to top.
    if (value == '') {
      field.getTrigger('clear').hide()
      me.filterStore(value)
      viewModel.set('lastFilterValue', value)
    } else if (value && value !== lastFilterValue) {
      field.getTrigger('clear')[value.length > 0 ? 'show' : 'hide']()
      me.filterStore(value)
      viewModel.set('lastFilterValue', value)
      var tree = this.lookup('tree')
      tree.expandAll()
    }
  },
  onSearchTriggerClick: function (field) {
    this.filterStore(field.getValue())
  },
  onClearTriggerClick: function (field) {
    var store = this.getViewModel().getStore('DPITreeStore')

    field.setValue()
    store.clearFilter()
    field.getTrigger('clear').hide()
    var tree = this.lookup('tree')
    tree.collapseAll()
    tree.getRootNode().expand()
  },
  filterStore: function (value) {
    var me = this,
      store = this.getViewModel().getStore('DPITreeStore'),
      searchString = value.toLowerCase(),
      filterFn = function (node) {
        var children = node.childNodes,
          len = children && children.length,
          visible = v.test(node.get('text')),
          i

        // If the current node does NOT match the search condition
        // specified by the user...
        if (!visible) {
          // Check to see if any of the child nodes of this node
          // match the search condition.  If they do then we will
          // mark the current node as visible as well.
          for (i = 0; i < len; i++) {
            if (children[i].isLeaf()) {
              visible = children[i].get('visible')
            } else {
              visible = filterFn(children[i])
            }
            if (visible) {
              break
            }
          }
        } else {
          // Current node matches the search condition...

          // Force all of its child nodes to be visible as well so
          // that the user is able to select an example to display.
          for (i = 0; i < len; i++) {
            children[i].set('visible', true)
          }
        }

        return visible
      },
      v

    if (searchString.length < 1) {
      store.clearFilter()
    } else {
      v = new RegExp(searchString, 'i')
      store.getFilters().replaceAll({
        filterFn: filterFn
      })
    }
  },
  onSave: function () {
    var me = this,
      grpRecord = me.getViewModel().get('grpRecord')

    var targetStore = grpRecord.store
    var store = me.getViewModel().getStore('GroupDetailsStore')
    Ext.MessageBox.wait('Saving the grouping data...Please wait.... ', 'Save', {
      duration: 12000
    })
    store.sync({
      success: function () {
        targetStore.suspendAutoSync()

        grpRecord.set('ItemCount', store.count())
        grpRecord.set('myid', Ext.id())
        grpRecord.save()
        grpRecord.commit()
        targetStore.resumeAutoSync()
        Ext.MessageBox.close()
        me.getView().fireEvent('update', me)
        Ext.Msg.show({
          title: 'Information',
          message: '<br>Operation successfully completed<br>',
          buttons: Ext.Msg.OK,
          icon: Ext.Msg.INFO,
          fn: function (btn) {
            if (btn === 'ok') {
              me.getView().fireEvent('update', me)
              me.getView().close()
            }
          }
        })
      },
      failure: function (batch, opts) {}
    })
  }
})
