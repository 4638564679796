import React from 'react'
import numeral from 'numeral'
import { IProps } from './Summaries.types'

export default function Summaries({ data, getMetricFormat }: IProps) {
  return (
    <>
      {data.map(({ Name, Value, ...rest }) => {
        const format = getMetricFormat(Name)
        return (
          <div
            key={JSON.stringify({ Name, Value, ...rest })}
            className="value"
            title={numeral(Value).format(format)}
          >
            <span className="truncated-text">{numeral(Value).format(format)}</span>
          </div>
        )
      })}
    </>
  )
}
