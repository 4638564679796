Ext.define('clustering.cmp.ExcludedDate', {
  extend: 'Ext.grid.Panel',
  xtype: 'excludedDateDetail',
  alias: 'widget.excludedDateDetail',
  enableLocking: true,
  height: 300,
  width: 400,
  columnLines: true,
  bufferedRenderer: false,
  store: Ext.create('Ext.data.Store'),
  initComponent: function () {
    var me = this,
      viewModel = me.mainViewModel

    var currentConfigurationData = viewModel.get('currentConfigurationData')

    var excludedDates = currentConfigurationData.ExcludedDates

    if (!excludedDates) excludedDates = []

    var excludedDatesConverted = []

    for (let index = 0; index < excludedDates.length; index++) {
      const excludedDate = excludedDates[index]

      var newObj = {}
      for (var property in excludedDate) {
        if (excludedDate.hasOwnProperty(property)) {
          newObj[property] = new Date(excludedDate[property])
        }
      }
      excludedDatesConverted.push(newObj)
    }

    me.store.loadData(excludedDatesConverted)

    me.callParent(arguments)
  },
  columns: [
    {
      text: 'Start Date',
      dataIndex: 'StartDate',
      width: 150,
      dateFormat: 'd/m/Y',
      xtype: 'datecolumn'
    },
    {
      text: 'End Date',
      dataIndex: 'EndDate',
      width: 150,
      dateFormat: 'd/m/Y',
      xtype: 'datecolumn'
    },
    {
      xtype: 'actioncolumn',
      width: 40,
      tdCls: 'delete',
      items: [
        {
          iconCls: 'x-fa fa-trash-o',
          tooltip: 'Delete',
          handler: function (grid, rowIndex, colIndex) {
            var rec = grid.getStore().getAt(rowIndex)

            grid.getStore().remove(rec)
          }
        }
      ]
    }
  ],
  dockedItems: [
    {
      xtype: 'toolbar',
      dock: 'bottom',
      reference: 'toolbar',
      overflowHandler: 'scroller',
      items: [
        '->',
        {
          text: 'Add Excluded Date',
          handler: function (btn) {
            var me = this,
              grid = me.up('grid'),
              store = grid.store

            var exludedDateWindow = Ext.create('Ext.window.Window', {
              title: 'New Excluded Date',
              modal: true,
              resizable: false,
              maximizable: false,
              bodyPadding: 4,
              ui: 'window-inverse',
              items: [
                {
                  xtype: 'form',
                  labelWidth: 100,
                  defaults: {
                    margin: '10px'
                  },
                  items: [
                    {
                      xtype: 'datefield',
                      fieldLabel: 'Start Date',
                      name: 'StartDate',
                      labelWidth: 60,
                      allowBlank: false,
                      format: 'd/m/Y',
                      width: 175
                    },
                    {
                      xtype: 'datefield',
                      fieldLabel: 'End Date',
                      name: 'EndDate',
                      labelWidth: 60,
                      allowBlank: false,
                      format: 'd/m/Y',
                      width: 175
                    }
                  ],
                  dockedItems: [
                    {
                      xtype: 'toolbar',
                      dock: 'bottom',
                      reference: 'toolbar',
                      overflowHandler: 'scroller',
                      items: [
                        '->',
                        {
                          text: 'Add',
                          handler: function (btn) {
                            var window = btn.up('window'),
                              form = btn.up('form')

                            var values = form.getValues()

                            var formcontainer = form.getForm()

                            formcontainer.findField('StartDate').getValue()
                            formcontainer.findField('EndDate').getValue()

                            var obj = {
                              StartDate: formcontainer.findField('StartDate').getValue(),
                              EndDate: formcontainer.findField('EndDate').getValue()
                            }

                            store.add(obj)
                            exludedDateWindow.close()
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }).show()
          }
        },
        {
          text: 'Apply',
          handler: function (btn) {
            var me = this,
              grid = me.up('grid'),
              window = grid.up('window'),
              viewModel = grid.mainViewModel,
              store = grid.store,
              datas = store.getDatas()

            var results = []
            for (let index = 0; index < datas.length; index++) {
              const data = datas[index]

              results.push({
                StartDate: Ext.Date.format(data.StartDate, 'c'),
                EndDate: Ext.Date.format(data.EndDate, 'c')
              })
            }

            var currentConfigurationData = viewModel.get('currentConfigurationData')
            currentConfigurationData.ExcludedDates = results

            viewModel.set('currentConfigurationData', currentConfigurationData)

            window.close()
          }
        }
      ]
    }
  ]
})
