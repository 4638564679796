import { EmptyObject, TcustomConfigs } from '../../types'
import CustomTooltip from '../../components/CustomTooltip'
import {
  GetCellTooltipProps,
  GetHeaderTooltipProps,
  GetHeaderTooltipReturnType,
  GetTooltipComponentCommon,
  GetTooltipComponentReturnType
} from './tooltip.types'
import { getValidCellRule } from '../index'

function getHeaderTooltip({
  autoTooltip,
  headerName,
  configHeaderTooltip
}: GetHeaderTooltipProps): GetHeaderTooltipReturnType | EmptyObject {
  let headerTooltip = ''

  if (configHeaderTooltip && configHeaderTooltip.length > 0) {
    if (!configHeaderTooltip.includes('{')) {
      headerTooltip = configHeaderTooltip
    }
  } else if (autoTooltip) {
    headerTooltip = headerName
  }

  if (headerTooltip) {
    return {
      headerTooltip
    }
  }
  return {}
}

function getCellTooltip({ params, cellDisplayRules }: GetCellTooltipProps) {
  // cell rule
  const validCellRule = getValidCellRule({ params, cellDisplayRules })
  if (validCellRule) {
    // TODO: @v2 Refactor
    return validCellRule?.cellTooltip ?? ''
  }

  return ''
}

function getTooltipComponent(
  { cellTooltip }: Pick<TcustomConfigs, 'cellTooltip'>,
  tooltipField: GetTooltipComponentReturnType['tooltipField'],
  formatParams: GetTooltipComponentCommon['formatParams'],
  cellDisplayRules: GetTooltipComponentCommon['cellDisplayRules']
): GetTooltipComponentReturnType {
  return {
    tooltipField,
    tooltipComponent: CustomTooltip,
    tooltipComponentParams: {
      cellTooltip,
      formatParams,
      cellDisplayRules
    }
  }
}

export { getHeaderTooltip, getTooltipComponent, getCellTooltip }
