import React, { Component } from 'react'
import { OverlayTrigger as BsOverlayTrigger, Popover } from 'react-bootstrap'
import _ from 'lodash'
import numeral from 'numeral'
import { confirmAlert } from 'react-confirm-alert'
import ScenarioCopy from './ScenarioCopy'
import { slvyToast } from '../../../../components'
import { FetchScenarioTree } from '../../facc'
import { OverlayTrigger } from '..'
import { MESSAGES } from '../../messages'
import { CoefButtons } from '../GridCommon/index.ts'

export default class GridBtnSection extends Component {
  getPopover(params, actions, hocProps) {
    const { _fetchScenarioTree = () => {}, GetScenarioTree } = hocProps
    const { settings = {}, stateHandler = () => {}, onAction = () => {} } = this.props

    return (
      <Popover className="dp-popover-hierarchy" id="dpPopoverHierarchy">
        <Popover.Header as="h3" className="mb-0 pb-2">
          {MESSAGES.copy_hierarchy_from_scenario}
        </Popover.Header>
        <Popover.Body>
          <ScenarioCopy
            GetScenarioTree={GetScenarioTree}
            _fetchScenarioTree={_fetchScenarioTree}
            actions={actions}
            params={params}
            settings={settings}
            stateHandler={stateHandler}
            onAction={onAction.bind(this)}
          />
        </Popover.Body>
      </Popover>
    )
  }

  onApplyUpdate($params) {
    const { updateMultiple = () => {}, isEveryColumnInRangeValid = () => {} } = this.props

    const { valuesId, LeftKeys, buttonFormat, IsApproved, Metrics, metricsFields, values } = $params

    let isEveryColumnInRangeValidFlag = true
    const valuesArr = document.querySelectorAll(
      `.-values[data-id="${valuesId}"] .-row-update [name]`
    )

    const requests = _.transform(
      valuesArr,
      (arr, item) => {
        const subindex = item.name
        const [baselineValue, plannedValue] = Metrics[subindex]
        const metricsField = metricsFields[subindex]
        const {
          Format,
          Validation: { MinAcceptableValue, MaxAcceptableValue, MinMaxCheckType } = {}
        } = values[subindex] || {}
        const isPercent = !_.isNil(Format) ? Format.indexOf('%') > 0 : false
        const $newValue = numeral(item.value).value()
        const value = isPercent ? $newValue / 100 : $newValue

        if (!_.isEmpty(item.value)) {
          const $item = {
            MinMaxCheckType,
            MinAcceptableValue,
            MaxAcceptableValue,
            OldValue: plannedValue,
            BaselineValue: baselineValue,
            MetricField: metricsField.Name,
            UpdateType: buttonFormat === 'percentage' ? 1 : 0,
            NewValue: value === '' || value === 0 ? plannedValue : value
          }

          arr.push($item)

          if (isEveryColumnInRangeValidFlag && !isEveryColumnInRangeValid(item, $item, value)) {
            isEveryColumnInRangeValidFlag = false
          }
        }

        return arr
      },
      []
    )

    if (!isEveryColumnInRangeValidFlag) {
      return
    }

    if (requests.every((item) => !_.isNumber(item.NewValue))) {
      slvyToast.info({
        message: MESSAGES.you_must_have_changes_to_save,
        options: { containerId: 'dp' }
      })
      return
    }

    const requestObj = {
      LeftKeys,
      UpdateType: buttonFormat === 'percentage' ? 1 : 0,
      UpdateDetails: requests
    }

    if (IsApproved === 1) {
      confirmAlert({
        title: MESSAGES.approve_confirmation,
        message: MESSAGES.are_you_sure_you_want_to_edit_the_already_approved_row,
        buttons: [
          {
            label: MESSAGES.cancel
          },
          {
            label: MESSAGES.confirm,
            onClick: () => updateMultiple(requestObj)
          }
        ]
      })
    } else {
      updateMultiple(requestObj)
    }
  }

  handleLockClick = () => {
    const {
      actionCall,
      LeftKeys,
      LockKey,
      MissingRow,
      settings: { missingRowLockMessage }
    } = this.props

    if (MissingRow) {
      slvyToast.warning({
        message: missingRowLockMessage,
        options: { containerId: 'dp' }
      })
      return
    }

    actionCall(LeftKeys, LockKey)
  }

  onChartBtnClick(LeftKeys, resultReqLeftFields, event) {
    event.stopPropagation()
    const { setHierarchy = () => {} } = this.props
    setHierarchy({ List: _.zipObject(resultReqLeftFields, LeftKeys) })
  }

  // @TODO: OverlayTriggers doesn't close (itself) popover when you click an another OverlayTriggers
  hideElements() {
    const selector = '.dp-popover-hierarchy:not(:last-child)'
    ;[].forEach.call(document.querySelectorAll(selector), (element) => {
      element.style.display = 'none'
    })
  }

  getCoefButtonTooltip(SpName = '', explanation = '', index) {
    if (explanation.trim() !== '') {
      return explanation
    }

    if (SpName.trim() !== '') {
      return SpName
    }

    return `Coef ${index + 1}`
  }

  render() {
    const {
      isScenarioEditable,
      reqLeftFields = [],
      valuesId,
      LeftKeys,
      IsApproved,
      Metrics,
      metricsFields,
      values,
      isLast,
      ApproveKey,
      LockKey,
      updateMode = 0,
      viewMode = 0,
      buttonFormat,
      IsApproveAllowed,
      allowedToUpdate,
      settings: {
        isVisibleCopyHierarchyButton = true,
        isApprove: approveEnable = true,
        isLock: lockEnable = true,
        detailViewButtonAllLevels = false,
        detailView: detailViewEnable = true,
        isHierarcyView,
        coef,
        coef: { buttons: coefSettingsButtons = [] } = {}
      } = {},
      pivotClick = () => {},
      actionCall
    } = this.props

    const resultReqLeftFields = reqLeftFields.slice(0, LeftKeys.length)

    const detailEndClass = isLast ? 'detail-end' : ''
    const buttonWrpClass = updateMode === 1 ? 'button-wrp' : ''

    const hasLeftKeys = LeftKeys.length > 0

    const isVisibleEyeViewButton =
      (detailViewButtonAllLevels && hasLeftKeys && detailViewEnable) ||
      (isLast && detailViewEnable && hasLeftKeys)

    const isVisibleCoefSettingsButtons =
      isScenarioEditable && allowedToUpdate && coefSettingsButtons.length

    const isVisibleChartButton =
      isScenarioEditable && isHierarcyView && hasLeftKeys && allowedToUpdate

    const isVisibleApproveButton =
      isScenarioEditable && approveEnable && IsApproveAllowed && allowedToUpdate

    const isVisibleLockButton = isScenarioEditable && lockEnable && allowedToUpdate

    const isVisibleCopyHierarchyBtn =
      isScenarioEditable && isVisibleCopyHierarchyButton && allowedToUpdate

    return (
      <div className={`slvy-ui-section -end ${detailEndClass} ${buttonWrpClass}`}>
        {isScenarioEditable && updateMode === 1 && viewMode === 2 ? (
          <div className="-button-group-ctn">
            <button
              onClick={this.onApplyUpdate.bind(this, {
                valuesId,
                LeftKeys,
                buttonFormat,
                IsApproved,
                Metrics,
                metricsFields,
                values
              })}
            >
              Update
            </button>
          </div>
        ) : null}
        {
          <div className="selection-box">
            {isVisibleCoefSettingsButtons ? (
              <CoefButtons coef={coef} leftKeys={LeftKeys} actionCall={actionCall} />
            ) : null}
            {isVisibleEyeViewButton ? (
              <OverlayTrigger placement="left" tooltip="Detail">
                <i
                  className="slvy-ui-icon-view-regular"
                  onClick={pivotClick.bind(this, LeftKeys)}
                />
              </OverlayTrigger>
            ) : null}
            {isVisibleChartButton ? (
              <OverlayTrigger tooltip="Chart">
                <i
                  className="slvy-ui-icon-chart-bar-regular"
                  onClick={this.onChartBtnClick.bind(this, LeftKeys, resultReqLeftFields)}
                />
              </OverlayTrigger>
            ) : null}
            {isVisibleApproveButton ? (
              <OverlayTrigger tooltip={ApproveKey.status}>
                <i
                  className={ApproveKey.className}
                  onClick={actionCall.bind(this, LeftKeys, ApproveKey)}
                />
              </OverlayTrigger>
            ) : null}
            {isVisibleLockButton ? (
              <OverlayTrigger tooltip={LockKey.name}>
                <i className={LockKey.className} onClick={this.handleLockClick} />
              </OverlayTrigger>
            ) : null}
            {isVisibleCopyHierarchyBtn ? (
              <FetchScenarioTree>
                {(hocProps) => {
                  return (
                    <BsOverlayTrigger
                      // @TODO: when rootClose set true, without stopPropagation Popover doesn't open. It hides itself.
                      rootClose
                      overlay={this.getPopover(
                        LeftKeys,
                        {
                          name: 'Copy',
                          endPoint: 'CopyHierarchy'
                        },
                        hocProps
                      )}
                      placement="left"
                      trigger="click"
                      onClick={(event) => event.stopPropagation()}
                      onEnter={this.hideElements.bind(this)}
                    >
                      <span>
                        <OverlayTrigger placement="left" tooltip="Copy Hierarchy">
                          <i className="slvy-ui-icon-copy" />
                        </OverlayTrigger>
                      </span>
                    </BsOverlayTrigger>
                  )
                }}
              </FetchScenarioTree>
            ) : null}
          </div>
        }
      </div>
    )
  }
}
