import { Component, ComponentType } from 'react'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { lruMemoize, createSelectorCreator } from 'reselect'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import cx from 'classnames'
import { ErrorBoundary, slvyToast } from '@/components'
import Menu from '../Menu'
import PluginList from '../PluginList'
import { PluginTypes, Progress } from '../../../BasePlugin'
import { select, selectCollection, selecOperationStatus, isFailed } from '@/crudoptV3'
import {
  getContainer,
  updateContainer,
  registerContainer,
  getContainerStates,
  createContainerState
} from '@/actions/container'
import { selectAContainer } from '@/store/slices/containerRelation'
import { RootState } from '@/store'
import { RUN_METHOD, RUN_EVENT } from '@/libs/workflow'
import pubsub from '../../../subs'
import permission, { PermTypes } from '../../../helpers/Permission'
import { ContainerProps } from '@/elements/View/View.types'
import { ConnectorState, InjectedProps, WithConnectorProps } from './Connector.types'
import './style.scss'

const Params = {
  get isRequired() {
    throw new Error('missing parameter')
  }
}

const withConnector = (ChildComponent: ComponentType<WithConnectorProps>) => {
  type ConnectorProps = Omit<WithConnectorProps, keyof InjectedProps>
  class Connector extends Component<ConnectorProps, ConnectorState> {
    constructor(props: ConnectorProps) {
      super(props)
      this.state = {
        showPluginList: false
      }
      this.fn = undefined
      this.showAddPluginLayout = this.showAddPluginLayout.bind(this)
      this.hideAddPluginLayout = this.hideAddPluginLayout.bind(this)
      this.addElement = this.addElement.bind(this)
      this.removeContainer = this.removeContainer.bind(this)
      this.handlePluginMounted = this.handlePluginMounted.bind(this)
      this.handleSave = this.handleSave.bind(this)
      this.createState = this.createState.bind(this)
      this.handleClickContainer = this.handleClickContainer.bind(this)
      this.registerMethod = this.registerMethod.bind(this)
      this.registerEvent = this.registerEvent.bind(this)
      this.disableSelection = this.disableSelection.bind(this)
    }

    createState(key, value) {
      const { id, createContainerState: _createContainerState } = this.props
      _createContainerState('create', id, {
        Name: key,
        ContainerId: id,
        Config: value
      })
    }

    componentDidMount() {
      const { userStates, dispatch, container } = this.props
      if (userStates.needFetch) {
        dispatch(userStates.fetch)
      }
      if (container.needFetch) {
        dispatch(container.fetch)
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { updateStatus, dispatch } = this.props
      const {
        userStates: newUserStates,
        updateStatus: newUpdateStatus,
        dispatch: newDispatch,
        container: newContainer
      } = nextProps
      if (newUserStates.needFetch) {
        dispatch(newUserStates.fetch)
      }

      if (isFailed(updateStatus, newUpdateStatus)) {
        const {
          error: { message }
        } = newUpdateStatus
        slvyToast.error({ message: 'Cannot update container', title: `${message}` })
        newDispatch(newContainer.fetch)
      }
    }

    showAddPluginLayout(fn) {
      this.fn = _.isFunction(fn) ? fn : undefined
      this.setState({ showPluginList: true })
    }

    hideAddPluginLayout() {
      this.setState({ showPluginList: false })
    }

    addElement(id, type) {
      if (this.fn) {
        this.fn(id, type)
        this.fn = undefined
      }
    }

    removeContainer() {
      const { id, onRemoveClick = () => {} } = this.props
      confirmAlert({
        title: 'Deleting Container?',
        message: 'Are you sure you want to delete this?',
        buttons: [
          {
            label: 'Cancel',
            onClick: () => null
          },
          {
            label: 'Confirm Delete',
            onClick: () => {
              onRemoveClick(id)
            }
          }
        ]
      })
    }

    handlePluginMounted() {
      const { onMounted = () => {} } = this.props
      onMounted()
    }

    handleSave(settings, elementIds = []) {
      const {
        id,
        params: { catalogId, menuId, pageId },
        container: { data },
        update
      } = this.props

      update(`update_${id}`, catalogId, menuId, pageId, id, {
        ...data,
        settings: { ...settings },
        elementIds
      })
    }

    componentWillUnmount() {
      const { isSelected, id, selectAContainer: _selectAContainer } = this.props
      if (isSelected) {
        _selectAContainer(0)
      }

      pubsub.unsubscribeWithFilter({ id })
    }

    handleClickContainer(event) {
      const node = findDOMNode(this.ref)
      const { isSelected, id, selectAContainer: _selectAContainer } = this.props
      if (node.getBoundingClientRect) {
        const { x, y, bottom, right } = node.getBoundingClientRect()
        if (
          (x - 5 <= event.clientX && x + 5 >= event.clientX) ||
          (y - 5 <= event.clientY && y + 5 >= event.clientY) ||
          (right + 5 >= event.clientX && event.clientX >= right - 5) ||
          (bottom + 5 >= event.clientY && bottom - 5 <= event.clientY)
        ) {
          _selectAContainer(isSelected ? 0 : id)
          if (event.currentTarget.preventDefault) event.currentTarget.preventDefault()
        }
      }
    }

    disableSelection() {
      const { isSelected, selectAContainer: _selectAContainer } = this.props
      if (isSelected) {
        _selectAContainer(0)
      }
    }

    registerEvent({
      key = Params.isRequired,
      fn = Params.isRequired,
      returnTypes = Params.isRequired
    }) {
      const {
        id,
        container: { data },
        params: { catalogId, menuId, pageId },
        registerContainer: _registerContainer,
        dispatch
      } = this.props
      const { registers: { events: { [key]: { params } = {} } = {} } = {} } = data
      const mappedParams = _.transform(
        returnTypes,
        (ret, value, key) => {
          ret[key] = { type: PluginTypes.toString(value) }
        },
        {}
      )

      if (!_.isEqual(params, mappedParams)) {
        _registerContainer('update', catalogId, menuId, pageId, id, 'events', {
          [key]: { params: mappedParams }
        })
      }
      const newFunc = (...params) => {
        const result = fn(...params)
        dispatch({
          type: RUN_EVENT,
          payload: { result, pluginId: id, eventKey: key }
        })
        return result
      }
      pubsub.subscribe(key + id, newFunc, { type: 'event', id })
      return newFunc
    }

    registerMethod({ key = Params.isRequired, fn = Params.isRequired, args = Params.isRequired }) {
      const {
        id,
        container: { data },
        params: { catalogId, menuId, pageId },
        registerContainer: _registerContainer,
        dispatch
      } = this.props
      const mappedParams = _.transform(
        args,
        (result, value) => {
          result[value.name] = { type: PluginTypes.toString(value.type) }
        },
        {}
      )
      const { registers: { methods: { [key]: { params } = {} } = {} } = {} } = data
      if (!_.isEqual(params, mappedParams)) {
        _registerContainer('update', catalogId, menuId, pageId, id, 'methods', {
          [key]: { params: mappedParams }
        })
      }
      pubsub.subscribe(
        key + id,
        (...p) => {
          dispatch({
            type: RUN_METHOD,
            payload: { pluginId: id, methodKey: key }
          })

          return fn(...p)
        },
        { type: 'method', id }
      )
    }

    render() {
      const {
        state: { showPluginList },
        props: {
          canDelete = true,
          canMove = true,
          plugins,
          containers,
          id,
          params,
          container,
          container: { pending, data },
          draggableHandleClassName = '',
          userStates: { data: userStateData = [], isSuccess } = {},
          isSelected,
          checkPermission,
          setting: { environment, designMode },
          first
        }
      } = this

      const isConfiguration = environment === 'Configuration'

      const showMenu =
        checkPermission.isEnv('Configuration').hasPermType(PermTypes.PluginEdit).value &&
        isSelected &&
        designMode

      if (!isSuccess) {
        return null
      }

      return (
        <div
          ref={(element) => (this.ref = element)}
          className={cx('container-wrapper', { border: isConfiguration, select: isSelected })}
          id={`container_${id}`}
        >
          {isConfiguration && designMode ? (
            <div className="containerEditButton" onClick={this.handleClickContainer} />
          ) : null}
          {showMenu ? (
            <Menu
              canDelete={canDelete}
              canMove={canMove}
              draggableHandleClassName={draggableHandleClassName}
              id={id}
              onClickRemove={this.removeContainer}
            />
          ) : null}
          <Progress isLoading={pending}>
            <ErrorBoundary>
              <ChildComponent
                container={container}
                containers={containers}
                createState={this.createState}
                customState={userStateData}
                data={data}
                disableSelection={this.disableSelection}
                first={first}
                id={id}
                isSelected={isSelected}
                params={params}
                plugins={plugins}
                registerEvent={this.registerEvent}
                registerMethod={this.registerMethod}
                showAddPluginLayout={this.showAddPluginLayout}
                onMounted={this.handlePluginMounted}
                onSave={this.handleSave}
              />
            </ErrorBoundary>
          </Progress>
          {showPluginList ? (
            <PluginList
              addElement={this.addElement}
              containers={containers}
              id={id}
              params={params}
              plugins={plugins}
              show={showPluginList}
              onHide={this.hideAddPluginLayout}
            />
          ) : null}
        </div>
      )
    }
  }

  const getIsSelectedState = (state: RootState, ownProps: ContainerProps) => {
    return state.containerRelation.selectedContainer === ownProps.id
  }

  const getContainerUpdateStatus = (state: RootState, ownProps: ContainerProps) => {
    return selecOperationStatus('container', `update_${ownProps.id}`, state.model3)
  }

  const getContainerStatesState = (state: RootState, ownProps: ContainerProps) => {
    return selectCollection(getContainerStates(ownProps.id), state.model3)
  }
  const getContainerState = (state: RootState, ownProps: ContainerProps) => {
    return select(
      getContainer(
        ownProps.params.catalogId,
        ownProps.params.menuId,
        ownProps.params.pageId,
        ownProps.id
      ),
      state.model3
    )
  }

  const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)
  const getIsSelectedSelector = () => {
    return createDeepEqualSelector([getIsSelectedState], (x) => x)
  }
  const getContainerUpdateStatusSelector = () => {
    return createDeepEqualSelector([getContainerUpdateStatus], (x) => x)
  }

  const getContainerStatesSelector = () => {
    return createDeepEqualSelector([getContainerStatesState], (x) => x)
  }

  const getContainerSelector = () => {
    return createDeepEqualSelector([getContainerState], (x) => x)
  }

  const makeMapStateToProps = () => {
    const container = getContainerSelector()
    const states = getContainerStatesSelector()
    const isSelected = getIsSelectedSelector()
    const status = getContainerUpdateStatusSelector()
    const mapStateToProps = (state: RootState, ownProps: ContainerProps) => {
      return {
        updateStatus: status(state, ownProps),
        checkPermission: permission(state.oidc.user, ownProps.params.environment),
        isSelected: isSelected(state, ownProps),
        container: container(state, ownProps),
        userStates: states(state, ownProps),
        setting: state.setting
      }
    }

    return mapStateToProps
  }

  return connect(makeMapStateToProps, (dispatch) => ({
    dispatch,
    update: bindActionCreators(updateContainer, dispatch),
    createContainerState: bindActionCreators(createContainerState, dispatch),
    selectAContainer: bindActionCreators(selectAContainer, dispatch),
    registerContainer: bindActionCreators(registerContainer, dispatch)
  }))(Connector)
}

export default withConnector
