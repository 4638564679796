type EventType = 'click' | 'mousedown' | 'keydown'
type Target = Window | Document | HTMLElement

export default class EventListenerManager {
  private _target: Target

  private _eventTypes: EventType[]

  private _listener: EventListenerOrEventListenerObject

  private _options: AddEventListenerOptions | boolean

  constructor(
    target: Target,
    eventTypes: EventType[],
    listener: EventListenerOrEventListenerObject,
    options: AddEventListenerOptions | boolean
  ) {
    this._target = target
    this._eventTypes = eventTypes
    this._listener = listener
    this._options = options
  }

  add() {
    this._eventTypes.forEach((eventType) =>
      this._target.addEventListener(eventType, this._listener, this._options)
    )
  }

  remove() {
    this._eventTypes.forEach((eventType) =>
      this._target.removeEventListener(eventType, this._listener, this._options)
    )
  }
}
