import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IState {
  trigger: boolean
  params: {
    pluginId: string
    args: Record<string, unknown>
    _CONNECTIONID?: number
  }
}

export const initialState: IState = {
  trigger: false,
  params: {} as IState['params']
}

export const exportData = createSlice({
  name: 'exportData',
  initialState,
  reducers: {
    triggerExportData: (state, action: PayloadAction<IState['params']>) => {
      state.trigger = !state.trigger
      state.params = action.payload
    }
  }
})

export const { triggerExportData } = exportData.actions

export default exportData.reducer
