import React, { Component } from 'react'
import { FormGroup, Modal, Button, FormControl, Form, Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import cx from 'classnames'
import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
import { SlvySelect } from '../../../components'
import './style.scss'

export function FieldGroup({ id, label, inputClass = '', ...props }) {
  return (
    <FormGroup className="mb-3" controlId={id}>
      <Form.Label className="fw-bold">{label}</Form.Label>
      <FormControl {...props} className={inputClass} />
    </FormGroup>
  )
}
class TaskAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      dueDateValue: '',
      subject: '',
      AssigneeGroup: props.makeAssigneeSameReporterInCreating ? [this.props.uniqueUserName] : [],
      files: [],
      categoryID: '',
      priorityID: '',
      description: '',
      addClicked: false
    }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.handleDueDateChange = this.handleDueDateChange.bind(this)
    this.handleSubjectChange = this.handleSubjectChange.bind(this)
    this.getTaskCardData = this.getTaskCardData.bind(this)
    this.addAndClose = this.addAndClose.bind(this)
    this.handleAssigneeGroupChange = this.handleAssigneeGroupChange.bind(this)
    this.mappingFromCrudList = this.mappingFromCrudList.bind(this)
    this.handleFilesCahnge = this.handleFilesCahnge.bind(this)
    this.handleCategorySelect = this.handleCategorySelect.bind(this)
    this.handlePrioritySelect = this.handlePrioritySelect.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.mappingFromCrudListForUsers = this.mappingFromCrudListForUsers.bind(this)
    this.getValidationState = this.getValidationState.bind(this)
    this.checkFormValues = this.checkFormValues.bind(this)
  }

  showModal() {
    this.setState({ show: true })
    this.props.onClose()
  }

  hideModal() {
    this.setState({ show: false })
    this.props.onClose()
  }

  handleSubjectChange(e) {
    this.setState({
      subject: e.target.value
    })
  }

  handleFilesCahnge(e) {
    this.setState({
      files: e.target.files
    })
  }

  handleCategorySelect(category) {
    const categoryID = category?.value ?? ''
    this.setState({ categoryID })
  }

  handlePrioritySelect(priority) {
    const priorityID = priority?.value ?? ''
    this.setState({ priorityID })
  }

  handleDescriptionChange(e) {
    this.setState({
      description: e.target.value
    })
  }

  handleAssigneeGroupChange(assignees) {
    const AssigneeGroup = assignees?.map((assignee) => assignee.value) ?? []
    this.setState({
      AssigneeGroup
    })
  }

  mappingFromCrudList = (list, valueKey, labelKey) => {
    return list.reduce((options, option) => {
      const label = option[labelKey]
      const value = option[valueKey]
      if (label && value) options.push({ label: String(label), value: String(value) })
      return options
    }, [])
  }

  mappingFromCrudListForUsers = (list, key, name, surname) => {
    return list.reduce((users, user) => {
      const label = `${user[name]} ${user[surname]}`
      const value = user[key]
      if (label && value) users.push({ label, value })
      return users
    }, [])
  }

  handleDueDateChange(value) {
    this.setState({
      dueDateValue: value && value.toISOString()
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.show !== nextProps.show) {
      this.setState({ show: nextProps.show })
    }
  }

  componentDidMount() {
    const { show } = this.props
    this.setState({ show })
  }

  getTaskCardData() {
    const { subject, dueDateValue, categoryID, priorityID, description, files, AssigneeGroup } =
      this.state

    return {
      subject,
      dueDate: dueDateValue === null ? '' : dueDateValue,
      categoryID,
      priorityID,
      parentID: '',
      description,
      files,
      assignees: AssigneeGroup
    }
  }

  addAndClose() {
    this.setState({ addClicked: true })
    if (this.checkFormValues()) {
      this.props.addNewTask(this.getTaskCardData())
      this.props.onClose()
    }
  }

  getValidationState(stateVariable) {
    const { makeAssigneeSameReporterInCreating } = this.props
    const { addClicked, subject, priorityID, categoryID, dueDateValue, AssigneeGroup } = this.state

    if (!addClicked) return null

    switch (stateVariable) {
      case 'subject': {
        return subject.length > 0 ? 'success' : 'error'
      }
      case 'priorityID': {
        return priorityID !== '' ? 'success' : 'error'
      }
      case 'categoryID': {
        return categoryID !== '' ? 'success' : 'error'
      }
      case 'dueDateValue': {
        return dueDateValue !== '' && dueDateValue !== null ? 'success' : 'error'
      }
      case 'AssigneeGroup': {
        return makeAssigneeSameReporterInCreating || AssigneeGroup.length !== 0
          ? 'success'
          : 'error'
      }
      default:
        return null
    }
  }

  checkFormValues() {
    const { makeAssigneeSameReporterInCreating } = this.props
    if (
      this.state.subject.length === 0 ||
      this.state.categoryID === '' ||
      (!makeAssigneeSameReporterInCreating && this.state.AssigneeGroup.length === 0)
    ) {
      return false
    }
    return true
  }

  render() {
    const { show, priorityID, categoryID, AssigneeGroup, subject, description, dueDateValue } =
      this.state
    const {
      localizationLanguage,
      addingProperties: {
        categoryTitle = '',
        priorityTitle = '',
        priorityVisibility = true,
        attachmentVisibility = true,
        duedateVisibility = true
      },
      priorities,
      makeAssigneeSameReporterInCreating,
      users,
      categories
    } = this.props

    const priorityOptions = this.mappingFromCrudList(priorities, 'PriorityID', 'Name')
    const priority = priorityOptions.find((option) => option.value === priorityID) ?? null

    const userOptions = this.mappingFromCrudListForUsers(users, 'UserName', 'Name', 'Surname')
    const user = userOptions.filter((option) => AssigneeGroup.includes(option.value)) ?? null

    const categoryOptions = this.mappingFromCrudList(categories, 'CategoryID', 'Name')
    const category = categoryOptions.find((option) => option.value === categoryID) ?? null
    return (
      <Modal show={show} onHide={this.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{localizationLanguage.NewTask}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col md={12} sm={12}>
                <FieldGroup
                  inputClass={cx({
                    'is-invalid': this.getValidationState('subject') === 'error'
                  })}
                  label={`${localizationLanguage.TaskSubject} *`}
                  placeholder={localizationLanguage.EnterTaskSubject}
                  type="text"
                  value={subject}
                  onChange={this.handleSubjectChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <FormGroup className="mb-3">
                  <Form.Label className="fw-bold">
                    {localizationLanguage.TaskDescription}
                  </Form.Label>
                  <FormControl
                    as="textarea"
                    placeholder={localizationLanguage.TaskDescription}
                    value={description}
                    onChange={this.handleDescriptionChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {priorities.length > 1 && priorityVisibility && (
                <Col md={6} sm={6}>
                  <FormGroup className="mb-3">
                    <Form.Label className="fw-bold">{priorityTitle}</Form.Label>
                    <SlvySelect
                      options={priorityOptions}
                      value={priority}
                      onChange={this.handlePrioritySelect}
                    />
                  </FormGroup>
                </Col>
              )}
              {duedateVisibility && (
                <Col md={6} sm={6}>
                  <FormGroup className="mb-3">
                    <Form.Label className="fw-bold">{localizationLanguage.TaskDuedate}</Form.Label>
                    <DatePicker
                      animation="slide-up"
                      calendar={<Calendar dateInputPlaceholder="" format="DD-MM-YYYY" />}
                      value={dueDateValue ? moment(dueDateValue) : null}
                      onChange={this.handleDueDateChange}
                    >
                      {({ value }) => (
                        <input
                          readOnly
                          className="input-date-picker form-control"
                          placeholder="DD-MM-YYYY"
                          tabIndex="-1"
                          value={(value && value.format('DD-MM-YYYY')) || ''}
                        />
                      )}
                    </DatePicker>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <FormGroup
                  className={cx('mb-3', {
                    'is-invalid': this.getValidationState('AssigneeGroup') === 'error'
                  })}
                >
                  <Form.Label className="fw-bold">
                    {localizationLanguage.TaskAssignee}
                    {' *'}
                  </Form.Label>
                  <SlvySelect
                    isMulti
                    isDisabled={makeAssigneeSameReporterInCreating}
                    options={userOptions}
                    value={user}
                    onChange={this.handleAssigneeGroupChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <FormGroup
                  className={cx('mb-3', {
                    'is-invalid': this.getValidationState('categoryID') === 'error'
                  })}
                >
                  <Form.Label className="fw-bold">
                    {categoryTitle}
                    {' *'}
                  </Form.Label>
                  <SlvySelect
                    options={categoryOptions}
                    value={category}
                    onChange={this.handleCategorySelect}
                  />
                </FormGroup>
              </Col>
              {attachmentVisibility && (
                <Col md={6} sm={6}>
                  <FieldGroup
                    multiple
                    id="formControlsFile"
                    label={localizationLanguage.ChooseFiles}
                    type="file"
                    onChange={this.handleFilesCahnge}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" onClick={this.addAndClose}>
            {localizationLanguage.Ok}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default TaskAdd
