var SLVY_Helpers = {
  startIndex: 1,

  getSortedSegmentsByTotalRevenue: function (result) {
    result.Segments = _.map(_.cloneDeep(result.Segments), (segmentItem) => {
      segmentItem.SortIdFromEngine = segmentItem.SortId
      return segmentItem
    })

    result.Stores = _.map(_.cloneDeep(result.Stores), (storeItem) => {
      _.find(result.Segments, (segmentItem) => {
        if (segmentItem.Id === storeItem.ClusterID) {
          storeItem.GroupByName = segmentItem.GroupByName
        }
      })
      return storeItem
    })
    result.Stores = this.getSum(
      _.cloneDeep(result.Stores),
      'GroupByName',
      'TotalRevenue',
      'GroupSum'
    )
    result.Stores = _.orderBy(result.Stores, 'GroupSum', 'desc')

    const uniqueStores = _.uniqBy(_.cloneDeep(result.Stores), 'GroupByName')
    const orderedSegments = _.orderBy(_.cloneDeep(result.Segments), 'SortIdFromEngine')

    result.Segments = this.getSortedList(
      uniqueStores,
      orderedSegments,
      'GroupByName',
      'GroupByName',
      1
    )
    result.Stores = this.getSortedList(result.Segments, result.Stores, 'Id', 'ClusterID', -1)

    return { Stores: result.Stores, Segments: result.Segments }
  },
  getSum(store, groupByKey, sumByKey, sumField) {
    let groupedStores = _.groupBy(store, groupByKey)

    groupedStores = _.map(groupedStores, (groupedStore) => {
      let sum = 0
      sum = _.sumBy(groupedStore, sumByKey)
      groupedStore = _.map(groupedStore, (item) => {
        item[sumField] = sum
        return item
      })
      return groupedStore
    })

    let groupedStoresArr = _.transform(
      groupedStores,
      (result, value) => {
        ;(result || (result = [])).push(value)
      },
      []
    )

    return _.flattenDepth(groupedStoresArr, 1)
  },

  getSortedList(store, sortStore, storeKey, sortStoreKey, _startIndex) {
    this.startIndex = _startIndex
    let sortedArr = []
    // set SortId
    store.forEach((item) => {
      var filteredStore = _.filter(sortStore, (item2) => item[storeKey] === item2[sortStoreKey])
      if (filteredStore.length) {
        filteredStore = filteredStore.map((item3) => {
          item3.SortId = this.getSortId(this.startIndex, item.SortId)
          return item3
        })
        sortedArr = _.concat(sortedArr, filteredStore)
      }
    })
    return sortedArr
  },
  getSortId(startIndex, sortId) {
    let index = startIndex < 0 ? sortId : this.startIndex
    if (startIndex > 0) {
      this.startIndex++
    }
    return index
  }
}

Ext.define('clustering.clusterview', {
  extend: 'Ext.panel.Panel',
  xtype: 'clusteringmainview',
  requires: ['Ext.layout.container.Accordion', 'clustering.Plugin.ComboSelectAll'],
  viewModel: {
    type: 'cluster'
  },
  config: {
    excelReader: null
  },
  publishes: ['excelReader'],
  bind: {
    excelReader: '{excelReader}'
  },
  controller: 'cluster',
  layout: 'border',
  flex: 1,
  referenceHolder: true,
  reference: 'ClusterView',
  bodyStyle: 'padding:5px;',
  cls: 'clusterview-container',
  initComponent: function () {
    var me = this
    const viewModel = me.getViewModel()
    const excelReader = me.config.excelReader

    me.setStores()

    me.openClusterLibrary()

    me.callParent()
    viewModel.set({ excelReader: excelReader })
  },

  setStores: function () {
    var me = this
    var viewModel = me.getViewModel()

    var stores = viewModel.storeInfo

    var uniqueid = Ext.id()

    var clusterStore = Ext.create('clustering.store.ClusterStore', {
      storeId: uniqueid + 'Clusters',
      uniqueid: uniqueid,
      listeners: {
        update: function (store) {
          var testStoreCount = 0
          store.each(function (item) {
            if (item.data.IsTest) testStoreCount++
          })
          viewModel.set('numberOfTestStores', testStoreCount)
        },
        load: function (store) {
          var testStoreCount = 0
          store.each(function (item) {
            if (item.data.IsTest) testStoreCount++
          })
          viewModel.set('numberOfTestStores', testStoreCount)
        }
      }
    })

    stores['clusterStore'] = clusterStore

    var pivotClusterStore = Ext.create('clustering.store.ClusterStore', {
      storeId: uniqueid + 'PivotClusters',
      uniqueid: uniqueid
    })

    // need to clone store. does not calculate new version
    stores['pivotClusterStore'] = pivotClusterStore

    var clusters = Ext.create('clustering.store.Clusters', {
      storeId: uniqueid + 'ChainedClusters',
      source: uniqueid + 'Clusters',
      uniqueid: uniqueid
    })

    stores['clusters'] = clusters

    var segmentStore = Ext.create('clustering.store.SegmentStore', {
      storeId: uniqueid + 'Segments',
      uniqueid: uniqueid,
      listeners: {
        update: function () {
          if (clusterStore) {
            var datas = clusterStore.getDatas()
            clusterStore.loadData(datas)
            clusterStore.fireEvent('load', clusterStore)
          }
        }
      }
    })

    stores['segmentStore'] = segmentStore

    viewModel.setStores(stores)
  },

  openClusterLibrary: function () {
    var me = this

    Ext.create('clustering.ClusterLibrary.ClusterLibrary', {
      referencePanel: me,
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          layout: {
            type: 'hbox',
            pack: 'center'
          },
          defaults: {
            flex: 1
          },
          items: [
            {
              text: 'New Folder',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                if (!currentRow) return

                var clusterLibrary = this.lookupReferenceHolder()
                var references = clusterLibrary.getReferences()
                var clusterTree = references['clusterTree']

                if (!clusterTree) return

                me.getEl().mask('Loading...')

                var nodeId = currentRow.get('Id')

                Ext.Ajax.request({
                  url: clustering.Global.getApiUrl() + 'librarytree/CreateFolderNode',
                  params: {
                    MotherId: nodeId,
                    Name: 'New Folder',
                    Status: 'FOLDER',
                    ProjectId: 0
                  },
                  timeout: 600000,
                  success: function (response) {
                    var result = Ext.JSON.decode(response.responseText)

                    if (result.Code > -1) {
                      clustering.util.operationSuccessWithMessage(result.Description)
                      clusterTree.store.reload()
                    } else {
                      clustering.util.operationFailWithMessage(result.Description)
                    }

                    me.getEl().unmask()
                  },
                  failure: function () {
                    me.getEl().unmask()
                    alert('Create folder node has failed.')
                  }
                })
              },
              bind: {
                disabled: '{!isFolderSelected}'
              }
            },
            {
              text: 'Delete',
              iconCls: 'fa fa-folder-open-o',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                if (currentRow) {
                  var nodeId = currentRow.get('Id')

                  var clusterLibrary = this.lookupReferenceHolder()
                  var references = clusterLibrary.getReferences()
                  var clusterTree = references['clusterTree']

                  if (clusterTree) {
                    Ext.Msg.confirm(
                      'Confirm Delete',
                      'Are you sure you want to delete?',
                      function (result) {
                        if (result === 'yes') {
                          Ext.Ajax.request({
                            url:
                              clustering.Global.getApiUrl() +
                              'librarytree/DeleteNode' +
                              '?nodeId=' +
                              nodeId,
                            timeout: 600000,
                            success: function (response) {
                              var result = Ext.JSON.decode(response.responseText)

                              if (result.Code > -1) {
                                clustering.util.operationSuccessWithMessage(result.Description)
                                clusterTree.store.reload()
                              } else {
                                clustering.util.operationFailWithMessage(result.Description)
                              }
                            }
                          })
                        }
                      }
                    )
                  }
                }
              },
              bind: {
                disabled: '{!currentRow}'
              }
            },
            {
              text: 'Load',
              iconCls: 'fa fa-folder-open-o',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                me.getEl().mask('Loading...')

                if (!currentRow) return

                Ext.Ajax.request({
                  url:
                    clustering.Global.getApiUrl() +
                    'cluster/LoadNode' +
                    '?nodeId=' +
                    currentRow.get('Id'),
                  timeout: 600000,
                  success: function (response) {
                    var result = Ext.JSON.decode(response.responseText)

                    var viewModel = me.referencePanel.getViewModel()
                    viewModel.set('currentNode', currentRow.data)

                    me.referencePanel.initializeFilterSettings(
                      result.Configurationdata,
                      me.referencePanel
                    )
                    me.referencePanel.initializeClusterStore(
                      result.ClusterDetails,
                      me.referencePanel
                    )

                    me.getEl().unmask()
                    me.close()
                  },
                  failure: function () {
                    me.getEl().unmask()
                    alert('Cluster load node has failed.')
                  }
                })
              },
              bind: {
                disabled: '{!isLeafSelected}'
              }
            },
            {
              text: 'New Cluster',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                me.getEl().mask('Loading...')

                Ext.Ajax.request({
                  url: clustering.Global.getApiUrl() + 'cluster/GetClusteringMetaData',
                  timeout: 600000,
                  success: function (response) {
                    var result = Ext.JSON.decode(response.responseText)

                    var newStartDate = new Date()
                    newStartDate.setMonth(newStartDate.getMonth() - 12)
                    result.StartTime = newStartDate

                    me.referencePanel.initializeFilterSettings(result, me.referencePanel)
                    me.getEl().unmask()
                    me.close()
                  },
                  failure: function () {
                    me.getEl().unmask()
                    alert('Create new cluster has failed.')
                  }
                })
              }
            }
          ]
        }
      ]
    }).show()
  },

  initializeFilterSettings: function (result, me) {
    var viewModel = me.getViewModel()
    viewModel.set('currentConfigurationData', result)

    var references = me.getReferences()

    // set scopes
    var scopes = references.scopes
    if (scopes) {
      // filters

      scopes.removeAll()

      result.Scopes.forEach(function (scopeField, i) {
        var scopeContainer = {
          cls: 'filteringPanel',
          title: scopeField.Name,
          margin: '10px 10px 0px 10px',
          referenceHolder: true,
          xtype: 'panel',
          collapsible: true,
          items: []
        }

        scopeField.Levels.forEach(function (levelField, j) {
          var combo = me.getCombo(levelField, i, j)

          if (scopeField.IsHierarchical) {
            if (scopeContainer.items.length > 0) {
              var previousCombo = scopeContainer.items[scopeContainer.items.length - 1].items[0]

              previousCombo.childCombo = combo

              if (previousCombo.value.length > 0) {
                combo.hidden = false
              }

              combo.bind = {
                filters: {
                  property: previousCombo.reference,
                  value: '{' + previousCombo.reference + '.value}'
                }
              }
            }
          }
          if (levelField.Name === 'Store') {
            combo.cls = 'hiddenStoreCombo'
          }

          var comboContainer = {
            xtype: 'fieldcontainer',
            layout: 'hbox',
            bind: {
              hidden: '{' + combo.reference + '.hidden}'
            },
            items: [
              combo,
              {
                cls: 'only-icon-button-checkbox-clustering',
                xtype: 'checkbox',
                hidden: scopeField.Name !== 'Location' || levelField.Name === 'Store',
                tooltip: 'Group By',
                checked: result.GroupAttribute == levelField.Name,
                listeners: {
                  change: function () {
                    var me = this
                    var value = me.getValue()

                    var viewModel = me
                      .lookupReferenceHolder()
                      .lookupReferenceHolder()
                      .getViewModel()
                    var currentConfigurationData = viewModel.get('currentConfigurationData')

                    var checkboxes = me.up().up().query('checkbox')

                    for (let index = 0; index < checkboxes.length; index++) {
                      const checkbox = checkboxes[index]
                      if (checkbox != me) {
                        checkbox.suspendEvents()
                        checkbox.setValue(false)
                        checkbox.resumeEvents()
                      }
                    }

                    if (value) {
                      currentConfigurationData.GroupAttribute = levelField.Name
                    } else {
                      currentConfigurationData.GroupAttribute = 'NONE'
                    }

                    viewModel.set('currentConfigurationData', currentConfigurationData)
                  }
                }
              },
              {
                cls: 'only-icon-button',
                iconCls: 'fa fa-external-link',
                xtype: 'button',
                text: 'Select Store',
                hidden: levelField.Name !== 'Store',
                tooltip: 'Expand Store List',
                handler: 'openStoreList'
              },
              {
                cls: 'unselect-store-import-button',
                xtype: 'filefield',
                hidden: levelField.Name !== 'Store',
                buttonOnly: true,
                buttonText: 'Unselect...',
                anchor: '100%',
                accept:
                  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                listeners: {
                  change: 'openDataImport'
                }
              }
            ]
          }

          scopeContainer.items.push(comboContainer)
        })

        scopes.add(scopeContainer)
      }, this)
    }

    var metrics = references.metrics

    if (metrics) {
      metrics.removeAll()

      var clusteringAlgorithms = result.ClusteringAlgorithms
      if (!clusteringAlgorithms) {
        clusteringAlgorithms = []
      }

      var availableNormalizationAttributes = result.AvailableNormalizationAttributes
      if (!availableNormalizationAttributes) {
        availableNormalizationAttributes = ['None']
      }
      var displayFieldContainer = {
        xtype: 'fieldcontainer',
        layout: 'hbox',
        bind: {
          hidden: '{!isPercentSelected}'
        },
        items: [
          {
            xtype: 'displayfield',
            fieldLabel: 'Total Percentage',
            bind: {
              value: '{SumOfPercentages}%'
            }
          },
          {
            cls: 'only-icon-button',
            iconCls: 'fa fa-gavel',
            xtype: 'button',
            tooltip: 'Disribute percentage evenly',
            handler: function () {
              var me = this

              var viewModel = me.up('clusteringmainview').getViewModel()

              var currentConfigurationData = viewModel.get('currentConfigurationData')

              var value =
                Math.round((100 / currentConfigurationData.SplitPercentages.length) * 10) / 10

              var sum = 0
              for (
                let index = 0;
                index < currentConfigurationData.SplitPercentages.length;
                index++
              ) {
                if (index == currentConfigurationData.SplitPercentages.length - 1) {
                  currentConfigurationData.SplitPercentages[index] =
                    Math.round((100 - Math.round(sum * 10) / 10) * 10) / 10
                } else {
                  currentConfigurationData.SplitPercentages[index] = value
                }

                sum += value
              }

              viewModel.set('currentConfigurationData', currentConfigurationData)
            }
          }
        ]
      }

      var clusterSettingsPanel = {
        title: 'Cluster Settings',
        referenceHolder: true,
        header: false,
        xtype: 'panel',
        bodyStyle: 'background:#edf8ff;',
        items: [
          {
            xtype: 'checkbox',
            fieldLabel: 'Manual',
            bind: {
              value: '{currentConfigurationData.IsManual}'
            },
            listeners: {
              change: function () {
                var me = this

                var classificationCheckBox = me
                  .lookupReferenceHolder()
                  .lookupReference('Classification')

                if (classificationCheckBox.checked) {
                  classificationCheckBox.setValue(false)
                }

                var numberOfClusters = me.lookupReferenceHolder().getReferences().numberOfClusters

                if (!numberOfClusters) return

                var viewModel = this.up('clusteringmainview').getViewModel()

                var currentConfigurationData = viewModel.get('currentConfigurationData')

                if (this.value) {
                  currentConfigurationData.NumberOfClusters = 0
                } else {
                  currentConfigurationData.NumberOfClusters = numberOfClusters.value
                }

                viewModel.set('currentConfigurationData', currentConfigurationData)
              }
            }
          },
          {
            name: 'numberOfClusters',
            fieldLabel: 'Segment Count',
            allowBlank: false,
            xtype: 'numberfield',
            reference: 'numberOfClusters',
            minValue: 0,
            listeners: {
              change: function () {
                var me = this

                var mainView = me.up('clusteringmainview')

                var viewModel = mainView.getViewModel()

                var currentConfigurationData = viewModel.get('currentConfigurationData')

                if (this.value < 0) {
                  this.setValue(0)
                }

                currentConfigurationData.NumberOfClusters = me.value
                viewModel.set('currentConfigurationData', currentConfigurationData)

                var references = me.up().getReferences()

                var percentageContainer = references.percentageContainer

                var numberOfClusters = references.numberOfClusters.getValue()

                var value = clusteringAlgorithms[currentConfigurationData.ClusteringAlgorithmIndex]

                mainView.createPercentageInputs(percentageContainer, numberOfClusters, value)
              }
            },
            bind: {
              hidden: '{currentConfigurationData.IsManual}',
              value: '{currentConfigurationData.NumberOfClusters}'
            }
          },
          {
            xtype: 'combo',
            fieldLabel: 'Clustering Algorithm',
            reference: 'ClusteringAlgorithmIndex',
            forceSelection: true,
            store: clusteringAlgorithms,
            bind: {
              hidden: '{currentConfigurationData.IsManual}'
            },
            value:
              result.ClusteringAlgorithmIndex > -1
                ? clusteringAlgorithms[result.ClusteringAlgorithmIndex]
                : null,
            listeners: {
              change: function () {
                var me = this

                var mainView = this.up('clusteringmainview')

                var value = me.getValue()
                var index = clusteringAlgorithms.indexOf(value)

                var currentConfigurationData = viewModel.get('currentConfigurationData')

                currentConfigurationData.ClusteringAlgorithmIndex = index
                viewModel.set('currentConfigurationData', currentConfigurationData)

                var references = this.up().getReferences()

                var percentageContainer = references.percentageContainer

                var numberOfClusters = references.numberOfClusters.getValue()

                mainView.createPercentageInputs(percentageContainer, numberOfClusters, value)
              }
            }
          },
          {
            xtype: 'combo',
            fieldLabel: 'Normalize',
            forceSelection: true,
            store: availableNormalizationAttributes,
            bind: {
              hidden: '{currentConfigurationData.IsManual}'
            },
            value:availableNormalizationAttributes[0],
            listeners: {
              change: function () {
                var me = this
                var value = me.getValue()
                var currentConfigurationData = viewModel.get('currentConfigurationData')
                currentConfigurationData.SelectedNormalizationAttribute = value
              }
            }
          },
          {
            xtype: 'checkbox',
            fieldLabel: 'Classification',
            reference: 'Classification',
            bind: {
              hidden: '{currentConfigurationData.IsManual}',
              value: '{currentConfigurationData.ClassificationEnabled}'
            }
          },
          displayFieldContainer,
          {
            xtype: 'tbtext',
            bind: {
              text: '{SumOfPercentagesMessage}',
              hidden: '{!isPercentSelected}'
            },
            style: {
              color: 'red',
              padding: '0 0 10px 0'
            }
          },
          {
            reference: 'percentageContainer',
            header: false,
            xtype: 'panel',
            bodyStyle: 'background:#edf8ff;',
            items: [],
            bind: {
              hidden: '{!isPercentSelected}'
            }
          }
        ]
      }

      metrics.add({
        title: 'Cluster Settings',
        referenceHolder: true,
        xtype: 'panel',
        cls: 'filteringPanel',
        margin: '10px 10px 0px 10px',
        bodyStyle: 'background:#edf8ff;',
        collapsible: true,
        items: [clusterSettingsPanel]
      })

      var metricCapacityAvailable =
        result.Metrics.filter(function (metric) {
          return metric.Enabled
        }).length != 3

      result.Metrics.forEach(function (metric, metricIndex) {
        var metricContainer = {
          title: metric.Name,
          referenceHolder: true,
          header: false,
          xtype: 'panel',
          items: []
        }

        if (!metric.Enabled && !metricCapacityAvailable) {
          metricContainer.disabled = true
        }

        var panels = []

        var options = []
        metric.Algorithms.forEach(function (algorithmField, algorithmFieldIndex) {
          options.push(algorithmField.Name)

          var fields = []

          algorithmField.Parameters.forEach(function (parameterField, parameterFieldIndex) {
            var field = {
              xtype: 'textfield',
              allowBlank: false,
              fieldLabel: parameterField.Name,
              name: parameterField.Name,
              value: parameterField.Value,
              metricIndex: metricIndex,
              algorithmFieldIndex: algorithmFieldIndex,
              parameterFieldIndex: parameterFieldIndex,
              listeners: {
                change: function () {
                  var me = this

                  var viewModel = me
                    .lookupReferenceHolder()
                    .lookupReferenceHolder()
                    .lookupReferenceHolder()
                    .getViewModel()

                  // update currentConfigurationData
                  var currentConfigurationData = viewModel.get('currentConfigurationData')

                  if (me.xtype == 'datefield') {
                    currentConfigurationData.Metrics[this.metricIndex].Algorithms[
                      this.algorithmFieldIndex
                    ].Parameters[this.parameterFieldIndex].Value = Ext.Date.format(this.value, 'c')
                  } else {
                    currentConfigurationData.Metrics[this.metricIndex].Algorithms[
                      this.algorithmFieldIndex
                    ].Parameters[this.parameterFieldIndex].Value = this.value
                  }

                  viewModel.set('currentConfigurationData', currentConfigurationData)
                }
              }
            }

            switch (parameterField.Type) {
              case 'String':
                if (parameterField.AvailableValues.length > 0) {
                  field.xtype = 'combo'
                  field.store = parameterField.AvailableValues
                  field.forceSelection = true
                  field.selectOnFocus = true
                }

                break

              case 'Int32':
              case 'Double':
                break

              case 'DateTime':
                field.xtype = 'datefield'

                break

              default:
                alert('Not supported datatype' + parameterField.Type)
            }

            fields.push(field)
          }, this)

          var panel = {
            name: algorithmField.Name,
            xtype: 'fieldcontainer',
            items: fields,
            bind: {
              disabled: '{!' + (metric.Name + 'check').replace(/[^\w]/gi, '') + '.checked}'
            },
            hidden: metric.SelectedAlgorithmIndex != algorithmFieldIndex
          }

          panels.push(panel)
        }, this)

        // checkedmetriccount should be set for initial data.
        if (metric.Enabled) {
          var checkedMetricCount = viewModel.get('checkedMetricCount')
          if (!checkedMetricCount) checkedMetricCount = 0
          viewModel.set('checkedMetricCount', checkedMetricCount + 1)
        }

        var checkbox = {
          xtype: 'checkbox',
          boxLabel: 'Enabled',
          reference: (metric.Name + 'check').replace(/[^\w]/gi, ''),
          hideLabel: true,
          margin: '0 0 10 0',
          value: metric.Enabled,
          metricIndex: metricIndex,
          listeners: {
            change: function () {
              var me = this
              var viewModel = me
                .lookupReferenceHolder()
                .lookupReferenceHolder()
                .lookupReferenceHolder()
                .getViewModel()

              var checkedMetricCount = viewModel.get('checkedMetricCount')

              if (this.checked) {
                checkedMetricCount += 1
              } else {
                checkedMetricCount -= 1
              }

              viewModel.set('checkedMetricCount', checkedMetricCount)

              // update currentConfigurationData
              var currentConfigurationData = viewModel.get('currentConfigurationData')
              currentConfigurationData.Metrics[this.metricIndex].Enabled = this.value

              viewModel.set('currentConfigurationData', currentConfigurationData)

              var items = this.getRefOwner()
                .getRefOwner()
                .getRefOwner()
                .items.items.filter(function (item) {
                  return item.title != 'Cluster Settings'
                })

              if (checkedMetricCount == 3) {
                for (var index = 0; index < currentConfigurationData.Metrics.length; index++) {
                  var metric = currentConfigurationData.Metrics[index]

                  if (!metric.Enabled) {
                    items[index].items.items[0].setDisabled(true)
                  }
                }
              } else {
                for (var index = 0; index < currentConfigurationData.Metrics.length; index++) {
                  items[index].items.items[0].setDisabled(false)
                }
              }
            }
          }
        }

        var numberOfCluster = {
          name: 'numberOfClusters',
          fieldLabel: 'Segment Count',
          allowBlank: false,
          xtype: 'numberfield',
          reference: 'numberOfClusters',
          value: metric.NumberOfClusters,
          metricIndex: metricIndex,
          minValue: 0,
          listeners: {
            change: function () {
              var me = this

              var mainView = me.up('clusteringmainview')

              var viewModel = mainView.getViewModel()

              var currentConfigurationData = viewModel.get('currentConfigurationData')

              if (this.value < 0) {
                this.setValue(0)
              }

              currentConfigurationData.Metrics[this.metricIndex].NumberOfClusters = this.value
              viewModel.set('currentConfigurationData', currentConfigurationData)

              var references = me.up().getReferences()

              var percentageContainer = references.percentageContainer

              var numberOfClusters = references.numberOfClusters.getValue()

              var value =
                clusteringAlgorithms[
                  currentConfigurationData.Metrics[this.metricIndex].ClusteringAlgorithmIndex
                ]

              mainView.createPercentageInputsForMetrics(
                percentageContainer,
                numberOfClusters,
                value,
                this.metricIndex
              )
            }
          },
          bind: {
            disabled: '{!' + (metric.Name + 'check').replace(/[^\w]/gi, '') + '.checked}',
            hidden: '{!currentConfigurationData.IsManual}'
          }
        }

        var combo = {
          xtype: 'combo',
          fieldLabel: metric.Name + ' Method',
          reference: metric.Name.replace(/[^\w]/gi, ''),
          forceSelection: true,
          store: options,
          bind: {
            disabled: '{!' + (metric.Name + 'check').replace(/[^\w]/gi, '') + '.checked}'
          },
          value: metric.SelectedAlgorithmIndex > -1 ? options[metric.SelectedAlgorithmIndex] : null,
          metricIndex: metricIndex,
          listeners: {
            change: function () {
              var me = this

              var siblings = me.getRefOwner().items.items

              siblings.forEach(function (sibling) {
                if (sibling.xtype == 'fieldcontainer') {
                  if (sibling.name == this.value) {
                    sibling.show()
                  } else {
                    sibling.hide()
                  }
                }
              }, this)

              var value = me.getValue()
              var record = me.findRecord(me.Value || me.displayField, value)
              var index
              if (record != null) {
                index = me.store.indexOf(record)
              } else {
                index = -1
              }

              // update currentConfigurationData
              var currentConfigurationData = viewModel.get('currentConfigurationData')

              currentConfigurationData.Metrics[this.metricIndex].SelectedAlgorithmIndex = index
              viewModel.set('currentConfigurationData', currentConfigurationData)
            }
          }
        }

        var clusteringAlgorithmCombo = {
          xtype: 'combo',
          fieldLabel: 'Clustering Algorithm',
          reference: metric.Name.replace(/[^\w]/gi, '') + 'clusteringAlgorithm',
          forceSelection: true,
          store: clusteringAlgorithms,
          bind: {
            disabled: '{!' + (metric.Name + 'check').replace(/[^\w]/gi, '') + '.checked}',
            hidden: '{!currentConfigurationData.IsManual}'
          },
          value:
            metric.ClusteringAlgorithmIndex > -1 &&
            clusteringAlgorithms &&
            clusteringAlgorithms.length > 0
              ? clusteringAlgorithms[metric.ClusteringAlgorithmIndex]
              : null,
          metricIndex: metricIndex,
          listeners: {
            change: function () {
              var me = this

              var mainView = this.up('clusteringmainview')

              var value = me.getValue()
              var index = clusteringAlgorithms.indexOf(value)

              var currentConfigurationData = viewModel.get('currentConfigurationData')

              currentConfigurationData.Metrics[this.metricIndex].ClusteringAlgorithmIndex = index

              viewModel.set('currentConfigurationData', currentConfigurationData)

              var references = this.up().getReferences()

              var percentageContainer = references.percentageContainer

              var numberOfClusters = references.numberOfClusters.getValue()

              mainView.createPercentageInputsForMetrics(
                percentageContainer,
                numberOfClusters,
                value,
                this.metricIndex
              )
            }
          }
        }

        var percentageContainer = {
          reference: 'percentageContainer',
          header: false,
          xtype: 'panel',
          items: []
        }

        var displayFieldContainer = {
          xtype: 'fieldcontainer',
          layout: 'hbox',
          bind: {
            hidden: '{!isPercentSelected' + metricIndex + '}'
          },
          items: [
            {
              xtype: 'displayfield',
              fieldLabel: 'Total Percentage',
              bind: {
                value: '{SumOfPercentages' + metricIndex + '}%',
                disabled: '{!' + (metric.Name + 'check').replace(/[^\w]/gi, '') + '.checked}'
              }
            },
            {
              cls: 'only-icon-button',
              iconCls: 'fa fa-gavel',
              xtype: 'button',
              tooltip: 'Disribute percentage evenly',
              bind: {
                disabled: '{!' + (metric.Name + 'check').replace(/[^\w]/gi, '') + '.checked}'
              },
              handler: function () {
                var me = this

                var viewModel = me.up('clusteringmainview').getViewModel()

                var currentConfigurationData = viewModel.get('currentConfigurationData')

                var value =
                  Math.round(
                    (100 / currentConfigurationData.Metrics[metricIndex].SplitPercentages.length) *
                      10
                  ) / 10

                var sum = 0
                for (
                  let index = 0;
                  index < currentConfigurationData.Metrics[metricIndex].SplitPercentages.length;
                  index++
                ) {
                  if (
                    index ==
                    currentConfigurationData.Metrics[metricIndex].SplitPercentages.length - 1
                  ) {
                    currentConfigurationData.Metrics[metricIndex].SplitPercentages[index] =
                      Math.round((100 - Math.round(sum * 10) / 10) * 10) / 10
                  } else {
                    currentConfigurationData.Metrics[metricIndex].SplitPercentages[index] = value
                  }

                  sum += value
                }

                viewModel.set('currentConfigurationData', currentConfigurationData)
              }
            }
          ]
        }

        var warningContainer = {
          xtype: 'tbtext',
          bind: {
            text: '{SumOfPercentagesMessage' + metricIndex + '}',
            hidden: '{!isPercentSelected' + metricIndex + '}'
          },
          style: {
            color: 'red',
            padding: '0 0 10px 0'
          }
        }

        panels.unshift(percentageContainer)
        panels.unshift(displayFieldContainer)
        panels.unshift(warningContainer)
        panels.unshift(clusteringAlgorithmCombo)
        panels.unshift(numberOfCluster)
        panels.unshift(combo)
        panels.unshift(checkbox)

        metricContainer.items = panels

        metrics.add({
          title: metric.Name,
          referenceHolder: true,
          xtype: 'panel',
          cls: 'filteringPanel',
          margin: '10px 10px 0px 10px',
          collapsible: true,
          items: [metricContainer]
        })
      }, this)
    }

    var toolbar = references.toolbar

    if (toolbar) {
      toolbar.add({
        name: 'startdate',
        fieldLabel: 'Start Date',
        labelWidth: 60,
        allowBlank: false,
        xtype: 'datefield',
        format: 'd/m/Y',
        width: 175,
        value: new Date(result.StartTime),
        listeners: {
          change: function () {
            var me = this
            var viewModel = this.lookupReferenceHolder().getViewModel()
            var currentConfigurationData = viewModel.get('currentConfigurationData')
            currentConfigurationData.StartTime = Ext.Date.format(this.value, 'c')
            viewModel.set('currentConfigurationData', currentConfigurationData)
          }
        }
      })
      toolbar.add({
        name: 'enddate',
        fieldLabel: 'End Date',
        labelWidth: 60,
        allowBlank: false,
        xtype: 'datefield',
        format: 'd/m/Y',
        width: 175,
        value: new Date(result.EndTime),
        listeners: {
          change: function () {
            var me = this
            var viewModel = me.lookupReferenceHolder().getViewModel()
            var currentConfigurationData = viewModel.get('currentConfigurationData')
            currentConfigurationData.EndTime = Ext.Date.format(this.value, 'c')
            viewModel.set('currentConfigurationData', currentConfigurationData)
          }
        }
      })

      // result.ExcludedDates

      toolbar.add({
        xtype: 'button',
        text: 'Exclude Dates',
        iconCls: 'fa fa-bolt',
        handler: function () {
          Ext.create('Ext.window.Window', {
            title: 'Excluded Dates',
            modal: true,
            resizable: false,
            maximizable: false,
            bodyPadding: 4,
            ui: 'window-inverse',
            items: [
              {
                layout: 'fit',
                xtype: 'excludedDateDetail',
                border: true,
                mainViewModel: viewModel
              }
            ]
          }).show()
        }
      })

      toolbar.add({
        xtype: 'button',
        text: 'Run',
        iconCls: 'fa fa-bolt',
        handler: function () {
          var me = this

          var viewModel = me.lookupReferenceHolder().getViewModel()

          var currentConfigurationData = viewModel.get('currentConfigurationData')

          var checked = currentConfigurationData.IsManual

          var hasProblem = false

          if (checked) {
            currentConfigurationData.Metrics.forEach(function (metric, index) {
              if (hasProblem) return
              if (metric.Enabled) {
                var isPercentSelected = viewModel.get('isPercentSelected' + index)
                if (isPercentSelected) {
                  var sumOfPercentagesMessage = viewModel.get('SumOfPercentagesMessage' + index)
                  if (sumOfPercentagesMessage) {
                    clustering.util.operationFailWithMessagePersist(
                      'Please fix percentages. Percentage must be 100.'
                    )
                    hasProblem = true
                  }
                }
              }
            })
          } else {
            var isPercentSelected = viewModel.get('isPercentSelected')

            if (isPercentSelected) {
              var sumOfPercentagesMessage = viewModel.get('SumOfPercentagesMessage')
              if (sumOfPercentagesMessage) {
                clustering.util.operationFailWithMessagePersist(
                  'Please fix percentages. Percentage must be 100.'
                )
                hasProblem = true
                return
              }
            }
          }

          if (hasProblem) return

          var clone = $.extend({}, currentConfigurationData)

          var checkedMetricCount = viewModel.get('checkedMetricCount')

          if (checkedMetricCount == 0) {
            clustering.util.operationFailWithMessage('One metric must be enabled for clustering.')
            return
          }

          currentConfigurationData.IsManual = checked

          var view = me.lookupReferenceHolder().getView()
          view.getEl().mask('Loading...')

          view.getReferences().scatter3d.grid3DViewControl = null

          Ext.Ajax.request({
            url: clustering.Global.getApiUrl() + 'cluster/CalculateClusters',
            params: {
              configuration: JSON.stringify(currentConfigurationData)
            },
            timeout: 600000,
            success: function (response, opts) {
              const result = Ext.JSON.decode(response.responseText)

              if (result.Code < 0) {
                clustering.util.operationFailWithMessage(result.Description)
              } else {
                clustering.util.operationSuccessWithMessage(result.Description)

                if (result.Dimensions) {
                  viewModel.set('dimensions', result.Dimensions)

                  if (result.Dimensions.length > 0) {
                    viewModel.set('xDimension', result.Dimensions[0])
                  }
                  if (result.Dimensions.length > 1) {
                    viewModel.set('yDimension', result.Dimensions[1])
                  }
                  if (result.Dimensions.length > 2) {
                    viewModel.set('zDimension', result.Dimensions[2])
                  }
                }

                if (result.CategoryNames) {
                  viewModel.set('categoryNames', result.CategoryNames)
                }

                viewModel.set('currentStore', null)
                viewModel.set('currentSegment', null)

                _.forEach(result.Stores, (store) => {
                  var segment = _.find(result.Segments, (segment) => {
                    return segment.Id == store.ClusterID
                  })
                  if (segment) {
                    store.SortId = segment.SortId
                  }
                })

                const segmentStore = viewModel.getStore('segmentStore')
                result.Segments.forEach(function (item) {
                  item.uniqueid = segmentStore.uniqueid
                })
                segmentStore.loadData(result.Segments)

                const clusterStore = viewModel.getStore('clusterStore')
                result.Stores.forEach(function (item) {
                  item.uniqueid = clusterStore.uniqueid

                  if (_.size(item.Values) > 0) {
                    const xValues = item.Values[0]
                    if (_.size(xValues) > 1) {
                      item.XValues = xValues
                      _.forEach(xValues, (detailValue, i) => {
                        item['xValue' + i] = detailValue
                      })
                    }
                  }

                  if (_.size(item.Values) > 1) {
                    const yValues = item.Values[1]
                    if (_.size(yValues) > 1) {
                      item.YValues = yValues
                      _.forEach(yValues, (detailValue, i) => {
                        item['yValue' + i] = detailValue
                      })
                    }
                  }

                  if (_.size(item.Values) > 2) {
                    const zValues = item.Values[2]
                    if (_.size(zValues) > 1) {
                      item.ZValues = zValues
                      _.forEach(zValues, (detailValue, i) => {
                        item['zValue' + i] = detailValue
                      })
                    }
                  }
                })
                clusterStore.loadData(result.Stores)
                clusterStore.fireEvent('load', clusterStore)
              }

              view.getEl().unmask()
            },
            failure: function () {
              clustering.util.operationFailWithMessage(
                'Service cannot calculate clusters for current configuration.'
              )
              view.getEl().unmask()
            }
          })
        }
      })

      toolbar.add({
        xtype: 'button',
        text: 'Batch Run',
        iconCls: 'fa fa-list',
        handler: function () {
          var me = this
          var viewModel = me.lookupReferenceHolder().getViewModel()
          var currentConfigurationData = viewModel.get('currentConfigurationData')

          var checked = currentConfigurationData.IsManual

          var hasProblem = false

          if (checked) {
            currentConfigurationData.Metrics.forEach(function (metric, index) {
              if (hasProblem) return
              if (metric.Enabled) {
                var isPercentSelected = viewModel.get('isPercentSelected' + index)
                if (isPercentSelected) {
                  var sumOfPercentagesMessage = viewModel.get('SumOfPercentagesMessage' + index)
                  if (sumOfPercentagesMessage) {
                    clustering.util.operationFailWithMessagePersist(
                      'Please fix percentages. Percentage must be 100.'
                    )
                    hasProblem = true
                  }
                }
              }
            })
          } else {
            var isPercentSelected = viewModel.get('isPercentSelected')

            if (isPercentSelected) {
              var sumOfPercentagesMessage = viewModel.get('SumOfPercentagesMessage')
              if (sumOfPercentagesMessage) {
                clustering.util.operationFailWithMessagePersist(
                  'Please fix percentages. Percentage must be 100.'
                )
                hasProblem = true
                return
              }
            }
          }

          if (hasProblem) return

          var availableGroupAttributes = currentConfigurationData.AvailableGroupAttributes

          var batchRunWindow = Ext.create('Ext.window.Window', {
            title: 'Batch Run Settings',
            modal: true,
            resizable: false,
            maximizable: false,
            bodyPadding: 4,
            ui: 'window-inverse',
            items: [
              {
                xtype: 'form',
                labelWidth: 100,
                defaults: {
                  margin: '10px'
                },
                items: [
                  {
                    xtype: 'combo',
                    fieldLabel: 'Group Attribute',
                    forceSelection: true,
                    store: availableGroupAttributes,
                    name: 'GroupAttribute'
                  },
                  {
                    xtype: 'textfield',
                    fieldLabel: 'Batch Name Prefix',
                    allowBlank: true,
                    allowOnlyWhitespace: false,
                    maxLength: 200,
                    name: 'BatchNamePrefix'
                  },
                  {
                    xtype: 'clusterfield',
                    fieldLabel: 'Cluster Folder',
                    containerViewModel: viewModel,
                    name: 'SelectedFolder'
                  }
                ],
                dockedItems: [
                  {
                    xtype: 'toolbar',
                    dock: 'bottom',
                    reference: 'toolbar',
                    overflowHandler: 'scroller',
                    items: [
                      '->',
                      {
                        text: 'Apply',
                        handler: function (btn) {
                          var window = btn.up('window')

                          var form = btn.up('form')

                          var values = form.getValues()
                          values.nodeId = form.query('[name=SelectedFolder]')[0].nodeId

                          if (!(values['GroupAttribute'] && values['GroupAttribute'].length > 0)) {
                            alert('Please select group attribute')
                            return
                          }

                          if (
                            !(values['BatchNamePrefix'] && values['BatchNamePrefix'].length > 0)
                          ) {
                            alert('Please enter batch name')
                            return
                          }

                          if (values['nodeId'] == null) {
                            alert('Please select cluster folder')
                            return
                          }

                          var currentConfigurationData = viewModel.get('currentConfigurationData')
                          currentConfigurationData.GroupAttribute = values['GroupAttribute']

                          window.getEl().mask('Loading...')

                          currentConfigurationData.IsManual = checked

                          Ext.Ajax.request({
                            url: clustering.Global.getApiUrl() + 'cluster/CalculateBatchClusters',
                            params: {
                              configuration: JSON.stringify(currentConfigurationData),
                              folderNodeId: values['nodeId'],
                              batchName: values['BatchNamePrefix']
                            },
                            timeout: 600000,
                            success: function (response) {
                              var result = Ext.JSON.decode(response.responseText)

                              if (result.Code > -1) {
                                clustering.util.operationSuccessWithMessagePersist(
                                  result.Description
                                )
                              } else {
                                clustering.util.operationFailWithMessagePersist(result.Description)
                              }

                              window.getEl().unmask()

                              var clusterView = viewModel.getView()
                              clusterView.openClusterLibrary()

                              batchRunWindow.close()
                            },
                            failure: function () {
                              me.getEl().unmask()
                              alert('Batch run failed')
                            }
                          })
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }).show()
        }
      })

      toolbar.add({
        xtype: 'textfield',
        fieldLabel: 'Test Stores',
        labelWidth: 67,
        width: 127,
        bind: {
          disabled: '{isAssignTestStoresDisabled}',
          value: '{numberOfTestStores}'
        },
        listeners: {
          focus: 'onAssignClusters'
        }
      })

      toolbar.add('->')

      toolbar.add({
        xtype: 'button',
        text: 'Segments Export',
        iconCls: 'fa fa-download',
        handler: function () {
          var me = this
          var clusterContainer = me.lookupReferenceHolder()
          var clusterGrid = clusterContainer.getReferences().clusterGrid
          clusterGrid.up().setActiveTab(0)
          setTimeout(() => {
            clusterGrid.doExport()
          }, 250)
        }
      })

      toolbar.add({
        xtype: 'button',
        text: 'Details Export',
        iconCls: 'fa fa-download',
        handler: function () {
          var me = this
          var clusterContainer = me.lookupReferenceHolder()
          var details = clusterContainer.getReferences().details
          details.up().setActiveTab(1)
          var storeGrid = details.getReferences().storeGrid
          setTimeout(() => {
            storeGrid.doExport()
          }, 250)
        }
      })

      toolbar.add({
        xtype: 'button',
        text: viewModel.get('currentNode') ? 'Save As' : 'Save',
        iconCls: 'fa fa-floppy-o',
        handler: function () {
          var me = this
          var viewModel = me.lookupReferenceHolder().getViewModel()

          var configurationData = viewModel.get('currentConfigurationData')
          var clusterStore = viewModel.getStore('clusterStore')
          var segmentStore = viewModel.getStore('segmentStore')
          var dimensions = viewModel.get('dimensions')
          var categoryNames = viewModel.get('categoryNames')

          var clusterDetails = {
            Segments: segmentStore.getDatas(),
            Stores: clusterStore.getDatas(),
            Dimensions: dimensions,
            CategoryNames: categoryNames
          }

          var saveClusterObject = {
            configurationData: configurationData,
            clusterDetails: clusterDetails
          }

          Ext.create('clustering.ClusterLibrary.ClusterLibrary', {
            referencePanel: me,
            dockedItems: [
              {
                xtype: 'toolbar',
                dock: 'bottom',
                layout: {
                  type: 'hbox',
                  pack: 'center'
                },
                defaults: {
                  flex: 1
                },
                items: [
                  {
                    text: 'New Folder',
                    handler: function () {
                      var me = this.lookupReferenceHolder()
                      var currentRow = me.getViewModel().get('currentRow')

                      if (!currentRow) return

                      var clusterLibrary = this.lookupReferenceHolder()
                      var references = clusterLibrary.getReferences()
                      var clusterTree = references['clusterTree']

                      if (!clusterTree) return

                      me.getEl().mask('Loading...')

                      var nodeId = currentRow.get('Id')

                      Ext.Ajax.request({
                        url: clustering.Global.getApiUrl() + 'librarytree/CreateFolderNode',
                        params: {
                          MotherId: nodeId,
                          Name: 'New Folder',
                          Status: 'FOLDER',
                          ProjectId: 0
                        },
                        timeout: 600000,
                        success: function (response, opts) {
                          var result = Ext.JSON.decode(response.responseText)

                          if (result.Code > -1) {
                            clustering.util.operationSuccessWithMessage(result.Description)
                            clusterTree.store.reload()
                          } else {
                            clustering.util.operationFailWithMessage(result.Description)
                          }

                          me.getEl().unmask()
                        },
                        failure: function (response, opts) {
                          me.getEl().unmask()
                          alert('Create folder node has failed.')
                        }
                      })
                    },
                    bind: {
                      disabled: '{!isFolderSelected}'
                    }
                  },
                  {
                    text: 'Save',
                    iconCls: 'fa fa-floppy-o',
                    handler: function () {
                      var me = this.lookupReferenceHolder()
                      var currentRow = me.getViewModel().get('currentRow')

                      var form = new Ext.FormPanel({
                        labelWidth: 100,
                        defaults: {
                          margin: '10px'
                        },
                        items: [
                          {
                            xtype: 'textfield',
                            fieldLabel: 'Cluster Name',
                            name: 'text',
                            allowBlank: false
                          },
                          {
                            xtype: 'textfield',
                            fieldLabel: 'Description',
                            name: 'description',
                            allowBlank: false
                          }
                        ],
                        buttons: [
                          {
                            text: 'OK',
                            handler: function () {
                              var item = this.up('form').getValues()

                              var clusterName = item['text']

                              var description = item['description']

                              var node = {
                                MotherId: currentRow.get('Id'),
                                Name: clusterName,
                                Description: description
                              }

                              saveClusterObject.node = node

                              me.getEl().mask('Loading...')

                              Ext.Ajax.request({
                                url: clustering.Global.getApiUrl() + 'cluster/SaveClusterNode',
                                params: {
                                  configuration: JSON.stringify(saveClusterObject)
                                },
                                timeout: 600000,
                                success: function (response, opts) {
                                  var result = Ext.JSON.decode(response.responseText)

                                  if (result.Code > -1) {
                                    clustering.util.operationSuccessWithMessage(result.Description)
                                  } else {
                                    clustering.util.operationFailWithMessage(result.Description)
                                  }

                                  me.getEl().unmask()
                                  me.close()
                                },
                                failure: function (response, opts) {
                                  me.getEl().unmask()
                                  me.close()
                                  alert('Save cluster node has failed.')
                                }
                              })

                              loginDlg.close()
                            }
                          },
                          {
                            text: 'Cancel',
                            handler: function () {
                              loginDlg.close()
                            }
                          }
                        ]
                      })

                      var loginDlg = new Ext.Window({
                        closable: true,
                        closeAction: function () {
                          this.onNewRec('cancelled', '')
                        },
                        modal: true,
                        title: 'New Cluster',
                        layout: 'fit',
                        items: form
                      })

                      loginDlg.show()
                      // we need window here.
                      // node should be created here.
                    },
                    saveClusterObject: saveClusterObject,
                    bind: {
                      disabled: '{!isFolderSelected}'
                    }
                  }
                ]
              }
            ]
          }).show()
        }
      })

      toolbar.add({
        xtype: 'button',
        text: 'Update',
        iconCls: 'fa fa-floppy-o',
        handler: function () {
          var me = this
          var viewModel = me.lookupReferenceHolder().getViewModel()

          var node = viewModel.get('currentNode')
          delete node['Children']
          delete node['id']

          var configurationData = viewModel.get('currentConfigurationData')

          var clusterStore = viewModel.getStore('clusterStore')

          var segmentStore = viewModel.getStore('segmentStore')

          var dimensions = viewModel.get('dimensions')
          var categoryNames = viewModel.get('categoryNames')

          var clusterDetails = {
            Segments: segmentStore.getDatas(),
            Stores: clusterStore.getDatas(),
            Dimensions: dimensions
          }
          var saveClusterObject = {
            configurationData: configurationData,
            node: node,
            clusterDetails: clusterDetails,
            categoryNames: categoryNames
          }

          var clusterView = me.up().up()
          clusterView.getEl().mask('Loading...')

          Ext.Ajax.request({
            url: clustering.Global.getApiUrl() + 'cluster/SaveClusterNode',
            params: {
              configuration: JSON.stringify(saveClusterObject)
            },
            timeout: 600000,
            success: function (response) {
              var result = Ext.JSON.decode(response.responseText)

              if (result.Code > -1) {
                clustering.util.operationSuccessWithMessage(result.Description)
              } else {
                clustering.util.operationFailWithMessage(result.Description)
              }

              clusterView.getEl().unmask()
            },
            failure: function () {
              clusterView.getEl().unmask()
              alert('Save cluster has failed.')
            }
          })
        },
        hidden: viewModel.get('currentNode') == null
      })
    }
  },

  initializeClusterStore: function (result, me) {
    var viewModel = me.getViewModel()

    if (result.Dimensions) {
      viewModel.set('dimensions', result.Dimensions)

      if (result.Dimensions.length > 0) {
        viewModel.set('xDimension', result.Dimensions[0])
      }
      if (result.Dimensions.length > 1) {
        viewModel.set('yDimension', result.Dimensions[1])
      }
      if (result.Dimensions.length > 2) {
        viewModel.set('zDimension', result.Dimensions[2])
      }
    }

    if (result.CategoryNames) {
      viewModel.set('categoryNames', result.CategoryNames)
    }

    var segmentStore = viewModel.getStore('segmentStore')
    result.Segments.forEach(function (item) {
      item.uniqueid = segmentStore.uniqueid
    })
    result.Segments.sort(function (a, b) {
      return parseInt(a.SortId) > parseInt(b.SortId) ? 1 : -1
    })
    segmentStore.loadData(result.Segments)

    var clusterStore = viewModel.getStore('clusterStore')
    result.Stores.forEach(function (item) {
      item.uniqueid = clusterStore.uniqueid

      if (_.size(item.Values) > 0) {
        const xValues = item.Values[0]
        if (_.size(xValues) > 1) {
          item.XValues = xValues
          _.forEach(xValues, (detailValue, i) => {
            item['xValue' + i] = detailValue
          })
        }
      }

      if (_.size(item.Values) > 1) {
        const yValues = item.Values[1]
        if (_.size(yValues) > 1) {
          item.YValues = yValues
          _.forEach(yValues, (detailValue, i) => {
            item['yValue' + i] = detailValue
          })
        }
      }

      if (_.size(item.Values) > 2) {
        const zValues = item.Values[2]
        if (_.size(zValues) > 1) {
          item.ZValues = zValues
          _.forEach(zValues, (detailValue, i) => {
            item['zValue' + i] = detailValue
          })
        }
      }
    })

    result.Stores = SLVY_Helpers.getSortedList(
      result.Segments,
      result.Stores,
      'Id',
      'ClusterID',
      -1
    )

    clusterStore.loadData(result.Stores)
    clusterStore.fireEvent('load', clusterStore)
  },

  getCombo: function (levelField, scopeIndex, fieldIndex) {
    var componentId = 'combo_' + scopeIndex + '_' + fieldIndex
    return {
      itemId: componentId,
      id: componentId + '_combo',
      xtype: 'multicombobox',
      fieldLabel: levelField.Name,
      reference: levelField.Name.replace(/[^\w]/gi, ''),
      displayField: 'Value',
      valueField: 'Key',
      store: Ext.create('Ext.data.Store', {
        fields: ['Key', 'Value'],
        data: levelField.Values,
        proxy: {
          type: 'ajax',
          timeout: 600000,
          url: clustering.Global.getApiUrl() + 'cluster/GetFilterData',
          useDefaultXhrHeader: false,
          cors: true,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          reader: {
            type: 'customReader',
            clusterContext: this,
            componentId: componentId
          }
        },
        listeners: {
          load: function (store) {
            store.clearFilter()
          }
        }
      }),
      setSelectedCount: function () {
        var count = ''
        if (this.value && this.value.length > 0) {
          var count = this.value.length
        }
        var comboSelected = $('.' + this.itemId + 'selected')
        comboSelected.html(count)
      },
      afterLabelTpl:
        "<div class='" +
        componentId +
        "selected' style='width:30px;margin:0px 5px;color:#2574ab;'>" +
        (levelField.SelectedIndices && levelField.SelectedIndices.length > 0
          ? levelField.SelectedIndices.length
          : '') +
        '</div>',
      queryMode: levelField.Values && levelField.Values.length > 0 ? 'local' : 'remote',
      value: levelField.SelectedIndices,
      scopeIndex: scopeIndex,
      fieldIndex: fieldIndex,
      hidden:
        levelField.SelectedIndices && levelField.SelectedIndices.length == 0 && fieldIndex != 0,
      setHidden: function (hidden) {
        this.publishState('hidden', hidden)
        return this.setVisible(!hidden)
      },
      publishes: ['hidden'],
      listeners: {
        added: function () {
          var me = this
          me.setProxyParams()
        },
        beforeQuery: function () {
          var me = this

          me.setProxyParams()
          if (me.store.data.items.length == 0) {
            me.store.load()
          }
        },
        change: function () {
          this.setSelectedCount()

          var viewModel = this.lookupReferenceHolder().lookupReferenceHolder().getViewModel()

          var currentConfigurationData = viewModel.get('currentConfigurationData')

          currentConfigurationData.Scopes[this.scopeIndex].Levels[this.fieldIndex].SelectedIndices =
            this.value

          currentConfigurationData.Scopes[this.scopeIndex].CurrentLevelIndex = this.fieldIndex

          viewModel.set('currentConfigurationData', currentConfigurationData)

          var childCombo = this.getChildCombo()

          if (childCombo) {
            if (this.value && this.value.length > 0) {
              childCombo.setHidden(false)
            } else {
              childCombo.setHidden(true)
            }

            childCombo.resetStatus()
            childCombo.setValue([])
            childCombo.store.clearData()
          }
        }
      },
      getChildCombo: function () {
        if (!this.childCombo) return null

        return this.lookupReferenceHolder().getReferences()[this.childCombo.reference]
      },
      setProxyParams: function () {
        var me = this
        var proxy = me.store.getProxy()

        var viewModel = me.lookupReferenceHolder().lookupReferenceHolder().getViewModel()

        var currentConfigurationData = viewModel.get('currentConfigurationData')

        var clone = $.extend(true, {}, currentConfigurationData)

        clone.Scopes[this.scopeIndex].CurrentLevelIndex = this.fieldIndex - 1

        clone.ScopeFilterIndex = this.scopeIndex

        proxy.setExtraParam('configuration', JSON.stringify(clone))
      },
      setValuesToModel: function (values) {
        var me = this

        var viewModel = me.lookupReferenceHolder().lookupReferenceHolder().getViewModel()

        var currentConfigurationData = viewModel.get('currentConfigurationData')

        currentConfigurationData.Scopes[me.scopeIndex].Levels[me.fieldIndex].Values = values

        viewModel.set('currentConfigurationData', currentConfigurationData)
      },
      resetStatus: function () {
        this.setProxyParams()

        var childCombo = this.getChildCombo()

        if (childCombo) {
          childCombo.setHidden(true)
          childCombo.setValue([])
          childCombo.queryMode = 'remote'
          childCombo.store.clearData()
          childCombo.resetStatus()
        }
      }
    }
  },
  dockedItems: [
    {
      xtype: 'toolbar',
      dock: 'top',
      reference: 'toolbar',
      overflowHandler: 'scroller',
      items: []
    }
  ],

  defaults: {
    collapsible: true,
    split: true,
    floatable: true,
    layout: 'fit'
  },

  items: [
    {
      region: 'west',
      collapsible: false,
      collapseMode: 'mini',
      width: 390,
      items: [
        {
          xtype: 'tabpanel',
          items: [
            {
              xtype: 'panel',
              title: 'Cluster Filters',
              reference: 'scopes',
              collapsible: true,
              scrollable: true,
              scrollToTopOnRefresh: false,
              defaults: {
                bodyPadding: 5
              },
              items: []
            },
            {
              xtype: 'panel',
              title: 'Cluster Settings',
              reference: 'metrics',
              collapsible: true,
              scrollable: true,
              defaults: {
                bodyPadding: 5
              },
              items: [],
              dockedItems: [
                {
                  xtype: 'toolbar',
                  dock: 'top',
                  reference: 'toolbarMetrics',
                  overflowHandler: 'scroller',
                  items: []
                }
              ]
            }
          ]
        }
      ]
    },
    {
      xtype: 'clustering-panel',
      region: 'center',
      collapsible: false,
      maximizeAllowed: true,
      collapseMode: 'mini',
      flex: 1,
      items: [
        {
          xtype: 'scatter3d',
          reference: 'scatter3d'
        }
      ]
    },
    {
      xtype: 'clustering-panel',
      region: 'east',
      maximizeAllowed: true,
      collapsible: false,
      collapseMode: 'mini',
      flex: 1,
      items: [
        {
          xtype: 'clusterMap'
        }
      ]
    },
    {
      xtype: 'tabpanel',
      region: 'south',
      height: '35%',
      collapsible: false,
      collapseMode: 'mini',
      items: [
        {
          title: 'Segments',
          reference: 'clusterGrid',
          xtype: 'clusterGrid'
        },
        {
          title: 'Details',
          xtype: 'panel',
          referenceHolder: true,
          reference: 'details',
          layout: {
            type: 'hbox',
            align: 'stretch'
          },
          items: [
            {
              xtype: 'segmentTree',
              reference: 'segmentTree',
              margin: '5px 0px 0px 5px',
              width: 200
            },
            {
              xtype: 'splitter',
              collapseTarget: 'prev'
            },
            {
              xtype: 'storeGrid',
              reference: 'storeGrid',
              margin: 5
            }
          ]
        }
      ]
    }
  ],

  createPercentageInputs(percentageContainer, numberOfClusters, selectedAlgorithm) {
    var mainView = percentageContainer.up('clusteringmainview')

    var viewModel = mainView.getViewModel()

    var currentConfigurationData = viewModel.get('currentConfigurationData')

    percentageContainer.removeAll()

    if (selectedAlgorithm != 'Percentage') return

    if (!currentConfigurationData.SplitPercentages) {
      currentConfigurationData.SplitPercentages = []
      for (let index = 0; index < numberOfClusters; index++) {
        currentConfigurationData.SplitPercentages.push(0)
      }
    } else if (currentConfigurationData.SplitPercentages.length > numberOfClusters) {
      while (currentConfigurationData.SplitPercentages.length > numberOfClusters) {
        currentConfigurationData.SplitPercentages = currentConfigurationData.SplitPercentages.slice(
          0,
          -1
        )
      }
    } else if (currentConfigurationData.SplitPercentages.length < numberOfClusters) {
      while (currentConfigurationData.SplitPercentages.length < numberOfClusters) {
        currentConfigurationData.SplitPercentages.push(0)
      }
    }

    viewModel.set('currentConfigurationData', currentConfigurationData)

    for (let index = 0; index < numberOfClusters; index++) {
      percentageContainer.add({
        name: 'Segment' + (index + 1),
        fieldLabel: 'Segment ' + (index + 1) + ' Percentage',
        allowBlank: false,
        xtype: 'numberfield',
        currentIndex: index,
        minValue: 0,
        listeners: {
          change: function () {
            var me = this

            var mainView = me.up('clusteringmainview')

            var viewModel = mainView.getViewModel()

            if (this.value < 0) {
              this.setValue(0)
            }

            var currentConfigurationData = viewModel.get('currentConfigurationData')
            currentConfigurationData.SplitPercentages[me.currentIndex] = this.value

            viewModel.set('currentConfigurationData', currentConfigurationData)
          }
        },
        bind: {
          disabled: '{currentConfigurationData.IsManual}',
          value: {
            bindTo: '{currentConfigurationData.SplitPercentages.' + index + '}',
            twoWay: false
          }
        }
      })
    }
  },

  createPercentageInputsForMetrics(
    percentageContainer,
    numberOfClusters,
    selectedAlgorithm,
    metricIndex
  ) {
    var mainView = percentageContainer.up('clusteringmainview')

    var viewModel = mainView.getViewModel()

    var currentConfigurationData = viewModel.get('currentConfigurationData')

    percentageContainer.removeAll()

    if (selectedAlgorithm != 'Percentage') return

    if (!currentConfigurationData.Metrics[metricIndex].SplitPercentages) {
      currentConfigurationData.Metrics[metricIndex].SplitPercentages = []
      for (let index = 0; index < numberOfClusters; index++) {
        currentConfigurationData.Metrics[metricIndex].SplitPercentages.push(0)
      }
    } else if (
      currentConfigurationData.Metrics[metricIndex].SplitPercentages.length > numberOfClusters
    ) {
      while (currentConfigurationData.Metrics[metricIndex].length > numberOfClusters) {
        currentConfigurationData.Metrics[metricIndex].SplitPercentages =
          currentConfigurationData.Metrics[metricIndex].SplitPercentages.slice(0, -1)
      }
    } else if (
      currentConfigurationData.Metrics[metricIndex].SplitPercentages.length < numberOfClusters
    ) {
      while (
        currentConfigurationData.Metrics[metricIndex].SplitPercentages.length < numberOfClusters
      ) {
        currentConfigurationData.Metrics[metricIndex].SplitPercentages.push(0)
      }
    }

    viewModel.set('currentConfigurationData', currentConfigurationData)

    for (let index = 0; index < numberOfClusters; index++) {
      percentageContainer.add({
        name: 'Segment' + (index + 1),
        fieldLabel: 'Segment ' + (index + 1) + ' Percentage',
        allowBlank: false,
        xtype: 'numberfield',
        value:
          currentConfigurationData.Metrics[metricIndex].SplitPercentages &&
          currentConfigurationData.Metrics[metricIndex].SplitPercentages.length >= index
            ? currentConfigurationData.Metrics[metricIndex].SplitPercentages[index]
            : 0,
        currentIndex: index,
        minValue: 0,
        listeners: {
          change: function () {
            var me = this

            var mainView = me.up('clusteringmainview')

            var viewModel = mainView.getViewModel()

            if (this.value < 0) {
              this.setValue(0)
            }

            var currentConfigurationData = viewModel.get('currentConfigurationData')
            currentConfigurationData.Metrics[metricIndex].SplitPercentages[me.currentIndex] =
              this.value

            viewModel.set('currentConfigurationData', currentConfigurationData)
          }
        },

        bind: {
          hidden: '{!currentConfigurationData.IsManual}',
          value: {
            bindTo:
              '{currentConfigurationData.Metrics.' +
              metricIndex +
              '.SplitPercentages.' +
              index +
              '}',
            twoWay: false
          },
          disabled:
            '{!' +
            (currentConfigurationData.Metrics[metricIndex].Name + 'check').replace(/[^\w]/gi, '') +
            '.checked}'
        }
      })
    }
  }
})
