import React, { Component } from 'react'
import createPlugin, { PluginTypes } from '@/BasePlugin'

class IFrame extends Component {
  constructor(props) {
    super(props)
    this.state = {
      url: 'about:blank'
    }
  }

  setUrl({ url }) {
    this.setState({ url })
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'setUrl',
      fn: this.setUrl.bind(this),
      args: [{ name: 'url', type: PluginTypes.string }]
    })
  }

  componentWillReceiveProps(nextProps) {
    const { settings: { config: { general: { url } = {} } = {} } = {} } = nextProps
    if (url) {
      this.setUrl(url)
    }
  }

  render() {
    let { settings: { config: { general: { url } = {} } = {} } = {} } = this.props
    if (this.state.url) {
      url = this.state.url
    }

    return <iframe frameBorder="0" height="100%" src={url} title="iframe" width="100%" />
  }
}

const selectConnectorProps = (props) => ({
  registerMethod: props.registerMethod,
  settings: props.settings
})

export default createPlugin(IFrame, selectConnectorProps)
