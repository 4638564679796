import { ChangeEvent, FormEvent, useState } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { Form, Button, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap'
import { SlvySelect, SlvySelectStyles, slvyToast } from '../../../../../../components'
import { getPluginVariables } from '@/helpers/mapper'
import { useUniqueId } from '../../../../../../hooks'
import { IUpdateVariablesProps, IForm, VariableValues } from './UpdateVariables.types'
import { IVariables, Toption } from '../../../Common.types'
import { getSortedVariables } from '../helpers'

export const makeCamelCase = (str: string) =>
  str
    .split('')
    .map((e, i) => (!i ? e.charAt(0).toUpperCase() + e.slice(1).toLowerCase() : e.toLowerCase()))
    .join('')

export const prepareValue = (value: any, type: string) => {
  let newValue = value
  switch (type) {
    case 'int':
      newValue = parseInt(newValue, 10)
      break
    case 'array{int}':
      newValue = _.map(_.split(newValue, ','), (v) => parseInt(v, 10))
      break
    case 'array{string}':
      newValue = _.split(newValue, ',')
      break
    case 'bool':
      newValue = newValue === '1' || newValue === 'true'
      break
    case 'array{bool}':
      newValue = _.map(_.split(newValue, ','), (v) => v === '1' || v === 'true')
      break
    default:
      break
  }

  return newValue
}

const UpdateVariables = ({
  params: { variableValues = {} } = {},
  customVariables,
  onSave,
  plugins,
  assignees
}: IUpdateVariablesProps) => {
  const [uniqueId] = useUniqueId()
  const variableId = `variableId-${uniqueId}`
  const valueId = `valueId-${uniqueId}`
  const [form, setForm] = useState<IForm>({ variable: '', value: '' })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const variables = {
    ...getPluginVariables(plugins, assignees),
    ...customVariables
  } as IVariables

  const newVariables = [{ label: 'Select', value: '' }]
  Object.keys(variables).forEach((key) => {
    newVariables.push({ label: `${key} (${variables[key].type})`, value: key })
  })

  const sortedVariables = getSortedVariables(newVariables)

  const filteredVariableValues = Object.keys(variableValues).reduce<VariableValues>((acc, key) => {
    const isExist = sortedVariables.some(({ value }) => value === key)
    if (isExist) {
      acc[key] = variableValues[key]
    }
    return acc
  }, {})

  const handleAdd = () => {
    const { variable, value } = form

    const { type } = variables[variable]
    const newValue = prepareValue(value, type)

    onSave({
      variableValues: { ...filteredVariableValues, [variable]: newValue }
    })

    setForm({ variable: '', value: '' })

    setIsSubmitted(false)

    slvyToast.success({ message: 'Added successfully!' })
  }

  const isValid = (key: undefined | 'value' | 'variable' = undefined) => {
    if (!isSubmitted) {
      return []
    }

    if (typeof key !== 'undefined') {
      return [
        {
          isValid: form[key].trim().length > 0,
          key
        }
      ]
    }

    const variableValidation = { isValid: form.variable.trim().length > 0, key: 'variable' }
    const valueValidation = { isValid: form.value.trim().length > 0, key: 'value' }
    return [variableValidation, valueValidation]
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const validationOpts = isValid()
    const invalidOptions = validationOpts.filter((item) => !item.isValid)
    if (invalidOptions.length) {
      invalidOptions.forEach((opt) =>
        slvyToast.error({ message: `${makeCamelCase(opt.key)} is required!` })
      )
      return
    }

    handleAdd()
  }

  const handleChange = (newValue: string, key: 'variable' | 'value') => {
    setForm((prevState: IForm) => ({
      ...prevState,
      [key]: newValue
    }))
  }

  const handleDelete = (key: string) => {
    const newVariableValues = _.omit(filteredVariableValues, [key])
    onSave({ variableValues: newVariableValues })
  }

  const selectValue = newVariables.find((item) => item.value === form.variable) ?? null

  const [isVariableValidation = null] = isValid('variable')
  const [isValueValidation = null] = isValid('value')

  const isVariableInvalid = isVariableValidation && !isVariableValidation.isValid
  const isValueInvalid = isValueValidation && !isValueValidation.isValid

  return (
    <>
      <Form noValidate className="p-3" data-testid="update-variables-job" onSubmit={handleSubmit}>
        <Row className="mb-2">
          <Col>
            <Form.Label className="fs-sm" htmlFor={`react-select-${variableId}-input`}>
              Variable
            </Form.Label>
            <SlvySelect
              className={cx('form-slvy-select', { 'is-invalid': isVariableInvalid })}
              classNamePrefix="slvy-select"
              instanceId={variableId}
              options={sortedVariables}
              placeholder="select"
              styles={SlvySelectStyles.small}
              value={selectValue}
              onChange={(option: Toption) => handleChange(option?.value ?? null, 'variable')}
            />
          </Col>
          <Col>
            <Form.Label className="fs-sm" htmlFor={valueId}>
              Value
            </Form.Label>
            <Form.Control
              className={{ 'is-invalid': isValueInvalid }}
              id={valueId}
              size="sm"
              value={form.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.value, 'value')
              }
            />
          </Col>
          <Col sm="auto">
            <div className="d-flex align-items-end h-100">
              <Button
                data-testid="update-variables-submit-btn"
                size="sm"
                type="submit"
                variant="success"
                onClick={() => setIsSubmitted(true)}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="table-responsive">
        <table className="table table-striped border-top align-middle fs-sm">
          <thead>
            <tr>
              <th>Variable</th>
              <th className="text-center">Value</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody data-testid="update-variables-list">
            {_.map(filteredVariableValues, (val, variable) => {
              const finalVal = _.isArray(val)
                ? _.join(
                    val.map((v) => String(v)),
                    ','
                  )
                : String(val)
              return (
                <tr key={variable}>
                  <th>{variable}</th>
                  <td className="text-center">{finalVal}</td>
                  <td className="text-center">
                    <OverlayTrigger
                      rootClose
                      overlay={
                        <Popover id="removeVariable">
                          <Popover.Header as="h6" className="mb-0">
                            Remove Variable
                          </Popover.Header>
                          <Popover.Body className="m-0 py-2">
                            Are you sure you want to remove <strong>{variable}</strong>?
                            <div className="d-flex gap-2 mt-2 justify-content-end">
                              <Button
                                data-testid={`remove-variable-${variable}`}
                                size="sm"
                                variant="danger"
                                onClick={() => handleDelete(variable)}
                              >
                                Remove
                              </Button>
                              <Button
                                size="sm"
                                variant="outline-dark"
                                onClick={() => document.body.click()}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                      placement="top"
                      trigger="click"
                    >
                      <Button
                        className="text-nowrap"
                        size="xs"
                        title="Remove Variable"
                        variant="outline-danger"
                      >
                        <i className="fa fa-trash-o" />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UpdateVariables
