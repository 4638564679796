import _ from 'lodash'
import cx from 'classnames'
import { Col, FormLabel, Form, Row } from 'react-bootstrap'

import { SlvyProgress, SlvySelect, SlvySelectStyles } from '..'
import { getFormErrorMsg } from '@/helpers'
import { useUniqueId } from '../../hooks'
import './SlvyFormSelect.scss'
import {
  ISlvyFormSelectProps,
  IOption,
  IOptionLabelValue,
  IOptionTextId,
  IOptionAlmostAny
} from './SlvyFormSelect.types'
import getSelectStyle from './helpers'

const menuPortalTarget = document.body

export default function SlvyFormSelect({
  data,
  value,
  onSelect,
  label,
  name = '',
  labelClass = '',
  selectClass = '',
  multiple = false,
  creatable = false,
  isLoading = false,
  columns = { label: 2, select: 10 },
  colClass = '',
  error,
  errorModelLabel,
  disabled = false,
  placeholder = '',
  iconButton,
  formGroupStyle = {},
  iconPosition = 'left',
  size = '',
  shouldSelectPortal = false,
  menuPlacement = 'auto',
  noRowMarginBottom = false
}: ISlvyFormSelectProps) {
  const [uniqueId] = useUniqueId()
  const controlId = `controlId-${uniqueId}`

  const handleChange = (option: null | IOptionLabelValue[] | IOptionLabelValue) => {
    if (option === null) {
      onSelect(multiple ? [] : option)
      return
    }

    const newValue = multiple
      ? _.map(option as IOptionLabelValue[], (item) => item.value)
      : (option as IOptionLabelValue).value

    onSelect(newValue)
  }

  const mappedOptions = getMappedOptions(data)
  const selectValue = getValue(mappedOptions, value, multiple)

  const errorMessage = getFormErrorMsg(error, errorModelLabel)

  const styles = { colLabel: columns.label, colSelect: columns.select }
  const isLabelFull = columns.label === 12

  const selectStyle = getSelectStyle(size)

  const optionalSelectProps = shouldSelectPortal
    ? { styles: { ...selectStyle, ...SlvySelectStyles.portalMenuStyles }, menuPortalTarget }
    : { styles: selectStyle }

  return (
    <Form.Group
      as={Row}
      className={cx({
        'mb-3': !noRowMarginBottom && size === '',
        'mb-2': !noRowMarginBottom && size === 'sm',
        'has-error': errorMessage
      })}
      data-testid="slvy-form-select"
      style={formGroupStyle}
    >
      <SlvyProgress isLoading={isLoading} type="alpha">
        <Row>
          {label && (
            <FormLabel
              className={cx('d-flex align-items-center', labelClass, {
                'pb-2': isLabelFull,
                'text-start': isLabelFull
              })}
              column={!size ? 'md' : size}
              // react-select- and -input pre/suffix comes from react-select
              // and to be able to match that (instanceId) we need to provide them
              htmlFor={`react-select-${controlId}-input`}
              sm={styles.colLabel}
            >
              {label}
            </FormLabel>
          )}
          <Col
            className={cx(colClass, { 'has-icon-column': iconButton })}
            data-testid="slvy-form-select-column"
            sm={styles.colSelect}
          >
            {iconButton && iconPosition === 'left' ? iconButton : null}
            <SlvySelect
              className={cx('form-slvy-select', selectClass, { 'is-invalid': errorMessage })}
              classNamePrefix="slvy-select"
              instanceId={controlId}
              isCreatable={creatable}
              isDisabled={disabled}
              isMulti={multiple}
              menuPlacement={menuPlacement}
              name={name}
              options={mappedOptions}
              placeholder={placeholder}
              value={selectValue}
              onChange={handleChange}
              {...optionalSelectProps}
            />
            {iconButton && iconPosition === 'right' ? iconButton : null}
            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
          </Col>
        </Row>
      </SlvyProgress>
    </Form.Group>
  )
}

function getValue(options: IOptionLabelValue[], value: IOption | IOption[], multiple: boolean) {
  if (multiple) {
    return _.filter(options, (option) =>
      _.find(value as IOptionAlmostAny[], (val) => val === option.value)
    )
  }
  return _.first(_.filter(options, (option) => option.value === value)) ?? null
}

function getMappedOptions(data: IOption[]) {
  // Empty string is can be an option.
  const mappedOptions = _.filter(data, (option) => option || option === '')
  return _.map(mappedOptions, (option) => {
    if (_.isObject(option)) {
      const { text, id, label: _label, value: _value } = option as IOptionLabelValue & IOptionTextId
      if (!_.isUndefined(_label) && !_.isUndefined(_value)) {
        return option
      }
      if (!_.isUndefined(text) && !_.isUndefined(id)) {
        return { label: text, value: id }
      }
    }
    return { label: option, value: option }
  }) as IOptionLabelValue[]
}
