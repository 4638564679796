Ext.define('Spui.util.Settings', {
  singleton: true,
  getRecord: function (type) {
    var store = Spui.Global.getMongoCollectionExportedData(),
      rec
    if (store && type) {
      rec = store.findRecord('type', type, 0, false, true, true)
      if (!rec) {
        rec = new Spui.data.model.Settings({
          type: type,
          appId: Spui.Global.getMenuId(),
          settings: ''
        })
        store.add(rec)
      }
    }
    return rec
  }
})

window.ss = Spui.util.Settings.getRecord
