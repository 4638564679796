Ext.define('MDM.view.regionapp.MassEdit.model.MaterialNew', {
  extend: 'MDM.src.model.BaseAll',
  entityName: 'gillettev3/MassEdit',
  fields: [
    { name: 'ID', type: 'int' },
    { name: 'ReportingPeriodID', type: 'int' },
    { name: 'SupplySourceID', type: 'int' },
    { name: 'MaterialID', type: 'int' },
    { name: 'MaterialCode', type: 'string' },
    { name: 'MaterialDescription', type: 'string' },
    { name: 'MaterialBUOMID', type: 'int' },
    { name: 'MaterialTypeID', type: 'int' },
    { name: 'DPIFamilyID', type: 'int' },
    { name: 'GU', type: 'number' },
    { name: 'SU', type: 'number' },
    { name: 'OwnerID', type: 'int' },
    { name: 'Category', type: 'string' },
    { name: 'Brand', type: 'string' },
    { name: 'ProductFormDetail', type: 'string' },
    { name: 'CSUIndicator', type: 'string' },
    { name: 'LastModifiedBy', type: 'string' },
    { name: 'ModifiedDate', type: 'date', dateFormat: 'Y-m-d\\TH:i:s.u' },
    { name: 'AppearsOnExtract', type: 'int' },
    { name: 'AppearsOnSubregion', type: 'string' },
    { name: 'ProductionLine', type: 'string' },
    { name: 'RazorDrawCoefficient', type: 'number' },
    {
      name: 'ExcludedFromProduction',
      type: 'int',
      convert: function (v) {
        if (typeof v === 'boolean') {
          return v ? 1 : 0
        } else {
          return parseInt(v, 10)
        }
      }
    },
    { name: 'IsActive', type: 'boolean' },
    { name: 'IsDropped', type: 'int' },
    { name: 'IsNew', type: 'int' },
    { name: 'ReferenceID', type: 'int' },
    { name: 'DataSource', type: 'string' },
    { name: 'HasBOM', type: 'int' },
    {
      name: 'ExcludedFromRevenue',
      type: 'int',
      convert: function (v) {
        if (typeof v === 'boolean') {
          return v ? 1 : 0
        } else {
          return parseInt(v, 10)
        }
      }
    },

    { name: 'id', type: 'int' },

    {
      name: 'isValid',
      convert: function (value, record) {
        if (!record.get('DPIFamilyID')) return null
        if (!record.get('SU')) return null
        if (!record.get('GU')) return null
        return 1
      },

      depends: ['MaterialBUOMID', 'DPIFamilyID', 'SU', 'GU']
    }
  ],
  validations: [{ field: 'isValid', type: 'range', min: 0.01, max: 2 }],
  idProperty: 'ID'
})
