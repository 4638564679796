/* eslint-disable no-nested-ternary */
import { createElement } from 'react'
import _ from 'lodash'

export const validateEmail = (email) => {
  const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
  return emailRegex.test(String(email).toLowerCase())
}

export const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  return phoneNumberRegex.test(phoneNumber)
}

export const parseErrorMsg = (error) => {
  const { message, Message } = error

  return (message || Message).replace(/\n/g, '<br>')
}

export const getPluginSize = (size, previewMode) => {
  const defaultValue = '100%'
  let width = _.get(size, 'width', defaultValue)
  let height = _.get(size, 'height', defaultValue)

  // these are for 0 cases
  width = width || defaultValue
  height = height || defaultValue

  height = previewMode ? '500px' : height

  return {
    width,
    height
  }
}

export const getExtContainerSize = ($id) => {
  let width = '100%'
  let height = '100%'

  const container = document.getElementById(`slvyExtContainer-${$id}`)

  if (container) {
    const { parentElement } = container
    width = parseInt(parentElement.clientWidth, 10)
    height = parseInt(parentElement.clientHeight, 10)
  }

  return {
    width,
    height
  }
}

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export const elipsisMiddle = (str) => {
  if (str.length > 35) {
    return `${str.substr(0, 20)}...${str.substr(str.length - 10, str.length)}`
  }
  return str
}

export const hasClass = (element, className) => {
  return element.classList && [...element.classList].includes(className)
}

export const getPageOffset = () => {
  const supportPageOffset = window.pageXOffset !== undefined
  const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'

  const x = supportPageOffset
    ? window.pageXOffset
    : isCSS1Compat
    ? document.documentElement.scrollLeft
    : document.body.scrollLeft
  const y = supportPageOffset
    ? window.pageYOffset
    : isCSS1Compat
    ? document.documentElement.scrollTop
    : document.body.scrollTop

  return { x, y }
}

export const getFormErrorMsg = (errorObj, errorModelLabel) => {
  const { ModelState = {} } = errorObj ?? {}
  const error = Object.entries(ModelState).find(([key]) => key === errorModelLabel) ?? []
  const [, [errorMsg] = []] = error

  return errorMsg
}

export async function handleAsyncAwait(promise) {
  try {
    const data = await promise
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const createComponent = (pluginComponent, elementProps) => {
  if (_.isNil(pluginComponent)) return null
  try {
    return createElement(pluginComponent, elementProps)
  } catch (err) {
    return null
  }
}

export function getLicenseKey(product) {
  let licenseObject = {}

  try {
    licenseObject = JSON.parse(atob(process?.env?.PRODUCT_KEYS)) || {}
  } catch (error) {
    licenseObject = {}
  }

  return licenseObject[product] || ''
}
