Ext.define('MDM.src.view.globalapp.Panel.Publish.Panel.LogGrid', {
  extend: 'Ext.grid.Panel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-publish-loggrid',
  title: 'Change',
  columnLines: true,
  rowLines: true,
  listeners: {
    // cellclick: 'onCellclickDPI'
  },

  plugins: {
    rowexpander: {
      rowBodyTpl: Ext.create(
        'Ext.XTemplate',
        '<table border="1" cellpadding="10" cellspacing="0">',
        '<tr><td>#</td><td>Key</td><td>Old Value</td><td>New Value</td></tr>',
        '<tpl foreach="{data}">',
        '<tpl foreach=".">',
        '<tr>',
        '<td>{[xkey]}</td>',
        '<tpl foreach=".">',
        '<td>{.}</td>',
        '</tpl>',
        '</tr>',
        '</tpl>',
        '</table>',
        '</tpl>'
      )
    }
  },
  columns: [
    {
      text: 'Modified by',
      width: 200,
      dataIndex: 'UserName',
      format: '0'
    },
    {
      text: 'User Region',
      width: 200,

      dataIndex: 'MegaRegion',
      format: '0'
    },
    {
      xtype: 'datecolumn',
      text: 'Modified At',
      dataIndex: 'UpdateDateTime',
      format: 'Y-m-d\\TH:i:s',

      width: 200
    },
    {
      text: 'Action Type',
      flex: 1,
      width: 120,
      dataIndex: 'Action',
      format: '0'
    }
  ]
})
