import _ from 'lodash'

export const checkValidity = (options) => {
  const { type, value, required, readonly, min, max } = options
  const isFieldTypeNumber = type === 'number'

  if (readonly === 'true' || readonly === true) {
    return true
  }

  if (required && required !== 'false') {
    if (
      _.isNil(value) ||
      _.isEmpty(_.toString(value)) ||
      (_.isString(value) && !value.trim().length)
    ) {
      return false
    }
  }

  if (isFieldTypeNumber) {
    if (isNaN(value)) {
      return false
    }

    if (!_.isNil(min)) {
      if (value < min) {
        return false
      }
    }

    if (!_.isNil(max)) {
      if (value > max) {
        return false
      }
    }
  }

  return true
}
