export default {
  title: 'NxM Bubble Chart',
  subTitle: 'NxM View using Highcharts Bubble Chart',
  version: '1.0',
  namespace: 'NxMBubblechart',
  description: 'NxM View using Highcharts Bubble Chart',
  iconClass: 'fa-line-chart',
  color: 'lazur-bg',
  support: {
    maximizable: true
  },
  group: 'Charts'
}
