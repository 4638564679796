export default `({
    fields: [{
            name: 'QTY',
            type: 'number'
        },
        {
            name: 'Price',
            type: 'number'
        },
        {
            name: 'FirstOrderDate',
            type: 'date',
            dateFormat: Spui.Global.dateFormatLong
        },
        {
            name: 'LastOrderDate',
            type: 'date',
            dateFormat: Spui.Global.dateFormatLong
        },
        {
            name: 'NumberOfStore',
            type: 'number'
        },
        {
            name: 'Envanter',
            type: 'number'
        },
        {
            name: 'DateKey',
            type: 'string'
        },
        {
            name: 'PriceNull',
            convert: function (value, record) {
                var v = record.get('Price');
                if (!v) return null
                return v
            },
            depends: ['Price']
        },
        {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
                var v = record.get('NumberOfStore');
                if (!v) return null
                return v
            },
            depends: ['NumberOfStore']
        },
        {
            name: 'active',
            type: 'boolean'
        }
    ],
    sorters: [ {
        property: 'FirstOrderDate',
        direction: 'DESC'
    } ],
    proxy: {
        type: 'ajax',
        actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
        },
        data: [{
            DateKey: 0
        }],
        url: Spui.Global.getApiUrl() + 'spui/GetVendorList',
    },
})
`
