Ext.define('po.cmp.GroupChart', {
  extend: 'Chart.ux.Highcharts',
  alias: 'widget.poGroupChart',
  margin: '10,10,10,10',
  config: {
    i18n: null
  },
  setI18n: function (i18n) {
    var me = this
    if (i18n == null) return
    me.chartConfig.series[0].name = i18n.GroupChart_series_name_key
    me.chartConfig.lang.printChart = i18n.printChart
    me.chartConfig.lang.downloadJPEG = i18n.downloadJPEG
    me.chartConfig.lang.downloadPDF = i18n.downloadPDF
    me.chartConfig.lang.downloadPNG = i18n.downloadPNG
    me.chartConfig.lang.downloadSVG = i18n.downloadSVG
    me.chartConfig.lang.downloadXLS = i18n.downloadXLS
    me.refresh()
  },
  loadMask: true,
  initComponent: function () {
    this.buildDemo()

    if (this.animation == false) {
      this.initAnim = false
      this.updateAnim = false
      this.initAnimAfterLoad = false
    }

    this.callParent(arguments)
  },

  onClickPieChart: function (serie, point, record) {
    this.chart.fireEvent('clickChart', record)
  },

  setData: function (externalStore) {
    var me = this

    externalStore.on('load', function (currentStore) {
      var records = currentStore.getDatas()

      var mappedData = records.map(function (item) {
        return { key: item.Name, values: item.OnerilenTutar }
      })

      if (mappedData.length > 10) {
        mappedData.sort(function (a, b) {
          return parseFloat(a.values) < parseFloat(b.values)
        })

        var initialData = mappedData.slice(0, 10)
        var i18n = me.up('pomainview').getViewModel().get('i18n'),
          keyText = i18n.GroupChart_series_name_Other

        var others = mappedData.slice(10, mappedData.length)
        var sumValues = others.reduce((item1, item2) => item1 + item2.values, 0)

        initialData.push({
          key: keyText,
          values: sumValues
        })

        mappedData = initialData
      }

      me.store.removeAll()
      me.store.loadData(mappedData)
      me.refresh()
      me.setLoading(false)
    })
  },

  buildDemo: function () {
    var me = this

    me.store = me.buildStore()

    me.series = [
      {
        categorieField: 'key',
        name: 'Selected Key',
        dataField: 'values',
        visible: true,
        listeners: {
          pointclick: me.onClickPieChart
        }
      }
    ]

    me.chartConfig = {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        },
        animation: {
          duration: 500,
          easing: 'swing'
        }
      },

      lang: {
        printChart: 'Grafiği yazdır',
        downloadJPEG: 'JPEG Dosyası olarak indir',
        downloadPDF: 'PDF Dosyası olarak indir',
        downloadPNG: 'PNG Dosyası olarak indir',
        downloadSVG: 'SVG vektör dosyası olarak indir',
        downloadXLS: 'XLS Dosyası olarak indir'
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 15,
          dataLabels: {
            enabled: true,
            formatter: function () {
              var p = this.point.name
              return p + ':' + Ext.util.Format.number(this.percentage, '0,000') + '%'
            },
            formats: '{point.name} week ( {percentage:.1f}% )',
            style: {
              fontSize: '10px'
            }
          }
        }
      },

      credits: {
        text: 'SOLVOYO © 2018',
        href: 'http://www.solvoyo.com',
        position: {
          align: 'right',
          y: -5
        },
        style: {
          fontSize: '7pt'
        }
      },

      navigation: {
        buttonOptions: {
          align: 'left'
        }
      },

      title: {
        text: ''
      }
    }

    Ext.apply(me.chartConfig, {})
  },

  buildStore: function () {
    var me = this
    me.store = Ext.create('Ext.data.Store', {
      data: []
    })
    return me.store
  }
})
