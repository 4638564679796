import React from 'react'

export default function Box(props) {
  const {
    settings: {
      flexDirection,
      alignItems,
      justifyContent,
      titleVal,
      titleSize,
      animationDuration,
      subtitleVal,
      subTitleSize,
      iconVal,
      padding,
      progressBarColor,
      descriptinSize
    } = {},
    data: { percentage, description } = {}
  } = props

  const style = {
    flexDirection,
    alignItems,
    justifyContent
  }

  return (
    <div className="chart-wrapper box-progress" style={style}>
      <div className="box-icon-ctn" style={{ backgroundColor: progressBarColor }}>
        <i className={iconVal} style={{ animationDuration: `${animationDuration}ms` }} />
      </div>
      {titleVal || percentage || subtitleVal || description ? (
        <div style={{ padding: `${padding}em` }}>
          {titleVal && <h1 style={{ fontSize: `${titleSize}em` }}>{titleVal}</h1>}
          {percentage && <h2 style={{ fontSize: `${descriptinSize}em` }}>{percentage}%</h2>}
          {subtitleVal && (
            <p style={{ fontSize: `${subTitleSize}em` }}>
              {description} {subtitleVal}
            </p>
          )}
        </div>
      ) : null}
    </div>
  )
}
