import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import numeral from 'numeral'
import { v3 as uuidv3 } from 'uuid'
import { confirmAlert } from 'react-confirm-alert'
import { isEveryColumnInRange } from '../Util'
import { slvyToast } from '../../../../components'
import { MESSAGES } from '../../messages'
import { CURRENCIES_LOCALES } from '../../currencies-locales'
import GridCell from '../Grid/grid-cell'

export default class GridDetail extends Component {
  constructor(props) {
    super(props)
    this.currenciesOptions = CURRENCIES_LOCALES[props.culture ? props.culture : 'en-US']
  }

  componentDidUpdate() {
    const { updateMode = 0 } = this.props
    if (!_.isNil(this.elem)) {
      if (updateMode === 2) {
        this.elem.blur()
      } else {
        this.elem.focus()
      }
    }
  }

  getIconMap(UpColor, DownColor) {
    return {
      down: { style: { color: DownColor || '#ff0000' } },
      equal: { style: { color: 'inherit' } },
      up: { style: { color: UpColor || '#8BC34A' } }
    }
  }

  getIconCondition(calculatedValue) {
    return calculatedValue >= 0 ? (calculatedValue === 0 ? 'equal' : 'up') : 'down'
  }

  getCalculatedValue(isPercent, plannedValue, baselineValue) {
    return isPercent
      ? plannedValue - baselineValue
      : parseInt(baselineValue, 10) === 0
      ? 100
      : parseFloat(
          numeral(((plannedValue - baselineValue) / baselineValue) * 100)
            .format('0.0')
            .replace(',', '.'),
          10
        )
  }

  isEveryColumnInRangeValid(event, params, newValue) {
    event.classList.remove('alert')
    params.NewValue = newValue
    const updateDetails = 'UpdateDetails' in params ? params.UpdateDetails : [params]
    if (!isEveryColumnInRange(updateDetails, 'NewValue')) {
      slvyToast.warning({
        message: MESSAGES.please_enter_a_valid_value_between_min_and_max,
        options: { containerId: 'dp' }
      })
      event.classList.add('alert')
      return false
    }
    return true
  }

  getLeftKeys(currentLeftKey, ExternalEdit) {
    const { getLeftHierarchy, data: { IsParent = false } = {} } = this.props

    const _getLeftHierarchy = [...getLeftHierarchy]
    if ((!IsParent && !ExternalEdit) || ExternalEdit) {
      _getLeftHierarchy.push(currentLeftKey)
    }

    return _getLeftHierarchy
  }

  updateCall(params, currentLeftKey, isPercent, isApproved, event) {
    const { target, target: { value = '' } = {}, keyCode } = event

    const { updateMode, settings: { shouldShowConfirmApprovedCellUpdate = true } = {} } = this.props

    const { ExternalEdit = false } = params

    if (keyCode === 13 || ExternalEdit) {
      const newValue = numeral(value).value()

      if (!ExternalEdit) {
        if (!this.isEveryColumnInRangeValid(target, params, newValue)) {
          return
        }
        params.NewValue = isPercent ? newValue / 100 : newValue
      }

      const initUpdate = (_params) => {
        if (target && !_params.ExternalEdit) {
          const parent = event.target.parentElement
          parent.classList.add('-mini-loading')
        }

        this.props.onUpdate(_params)
        this.props.setSelectedRowId(0)
      }

      if (isApproved === 1) {
        if (shouldShowConfirmApprovedCellUpdate) {
          confirmAlert({
            title: MESSAGES.approve_confirmation,
            message: MESSAGES.are_you_sure_you_want_to_edit_the_already_approved_row,
            buttons: [
              {
                label: MESSAGES.cancel
              },
              {
                label: MESSAGES.confirm,
                onClick: () => initUpdate(params)
              }
            ]
          })
        } else {
          initUpdate(params)
        }
      } else {
        if (params.ExternalEdit && updateMode === 2) {
          params.LeftKeys = this.getLeftKeys(currentLeftKey, ExternalEdit)
        }
        initUpdate(params)
      }
    }
  }

  render() {
    let {
      props: {
        data: {
          Children = [],
          IsApproved = 0,
          IsParent = false,
          LeftKeys,
          LeftKeys: { IsUpdateAllowed = false } = {},
          Metrics = [],
          identifier = []
        } = {},
        allowedToUpdate,
        buttonFormat,
        className = '',
        click,
        culture,
        getLeftHierarchy = [],
        index = 0,
        isChildren = !_.isEmpty(Children),
        isScenarioEditable,
        leftHierarchy = [],
        onUpdate,
        selectedRowId,
        setHierarchy,
        settings = {},
        topFields = [],
        updateMode = 0,
        values = [],
        viewMode = 0
      },
      currenciesOptions: { decimalScale, thousandSeparator, decimalSeparator }
    } = this

    const isEditable = isScenarioEditable && IsUpdateAllowed && viewMode === 2
    const isEditableInputClass = isEditable ? ' -editable ' : ''
    const { cell = 0, row = 0 } = selectedRowId

    const valuesData = _.find(values, (item) => item.Name === LeftKeys.Name) || values[0]
    const {
      Format,
      ExternalEdit = false,
      Arrow: { DownColor = '', UpColor = '', Visible = true },
      DisplayName = '',
      Validation: { MinAcceptableValue, MaxAcceptableValue, MinMaxCheckType = 'absolute' } = {}
    } = valuesData

    const isPercent = !_.isNil(Format) ? Format.indexOf('%') > 0 : false

    const iconMap = this.getIconMap(UpColor, DownColor)

    const valuesId = uuidv3(`${LeftKeys.Name} ${Metrics.toString()}`, uuidv3.DNS)

    const detailCell = _.first(Metrics)
    const baselineDetail = detailCell[0]
    const planDetail = detailCell[1]
    Metrics = _.slice(Metrics, 1, Metrics.length)

    const isEditableHover = _.isEqual(row, valuesId) ? '-editable-hover' : ''

    return (
      <div>
        <div key={index} className={`slvy-ui-row ${className} ${isEditableHover}`}>
          <div className="slvy-ui-section -title">
            <div>
              <section>{DisplayName}</section>
            </div>
          </div>

          <div className="slvy-ui-section -values -detail-values">
            {_.map(Metrics, (subitem, subindex) => {
              const currentPeriod = topFields[subindex]
              const [baselineValue, plannedValue, subObject] = subitem
              const { IsLocked: lockIndicator = 0 } = subObject

              const uniqueID = uuidv3(
                `${currentPeriod}
                 ${LeftKeys.Name}
                 ${index}
                 ${subindex}`,
                uuidv3.DNS
              )

              const calculatedValue = this.getCalculatedValue(
                isPercent,
                plannedValue,
                baselineValue
              )

              const iconCondition = this.getIconCondition(calculatedValue)

              const isEditableActive = _.isEqual(uniqueID, cell) && isEditable && updateMode !== 2

              const isEditableClass = isEditableActive ? '-editable-active' : ''

              const UpdateType = buttonFormat === 'percentage' ? 1 : 0

              const $placeholder = UpdateType === 1 ? '100%' : numeral(plannedValue).format(Format)

              return (
                <div
                  key={`${index}-${subindex}`}
                  className={`-cell ${isEditableClass}`}
                  data-id={uniqueID}
                  onClick={
                    lockIndicator !== 1
                      ? isEditable && ExternalEdit && updateMode === 2 && viewMode === 2
                        ? this.updateCall.bind(
                            this,
                            {
                              BaselineValue: baselineValue,
                              ExternalEdit,
                              Guid: uniqueID,
                              Identifier: identifier,
                              IsParent,
                              LeftKeys: leftHierarchy,
                              MetricField: LeftKeys.Name,
                              OldValue: plannedValue,
                              SubIndex: subindex,
                              TopKey: topFields[subindex].Name,
                              UpdateType
                            },
                            LeftKeys.Name,
                            isPercent,
                            IsApproved
                          )
                        : () => {
                            if (_.isEqual(uniqueID, cell)) {
                              this.props.setSelectedRowId(0)
                            } else {
                              this.props.setSelectedRowId(uniqueID, valuesId)
                            }
                          }
                      : () => {}
                  }
                >
                  <div className="-cellContent">
                    {Visible && !_.isEqual(plannedValue, baselineValue) ? (
                      <div className="-corner" style={iconMap[iconCondition].style}>
                        <i className={`diff-icon fa fa-caret-${iconCondition}`} />
                        <div className="-percentage">
                          {isPercent
                            ? numeral(calculatedValue).format('0.00%')
                            : `${calculatedValue}%`}
                        </div>
                      </div>
                    ) : null}

                    <div key={`${index}-${subindex}-${0}`} className="-baseline">
                      {numeral(baselineValue).format(Format)}
                    </div>

                    {isEditable && lockIndicator !== 1 ? (
                      // eslint-disable-next-line no-mixed-operators
                      updateMode === 0 || (updateMode === 1 && viewMode === 2) ? (
                        <div
                          key={`${index}-${subindex}-${1}`}
                          className={`-planned ${isEditableInputClass}`}
                        >
                          {numeral(plannedValue).format(Format)}
                          <GridCell
                            ref={(inst) => {
                              if (uniqueID === cell) {
                                this.elem = ReactDOM.findDOMNode(inst)
                              }
                            }}
                            gridCellProps={{
                              name: subindex,
                              placeholder: $placeholder,
                              plannedValue
                            }}
                            numberFormatProps={{
                              decimalScale,
                              decimalSeparator,
                              fixedDecimalScale: true,
                              placeholder: $placeholder,
                              thousandSeparator,
                              value: '',
                              ref: (inst) => {
                                if (uniqueID === cell) {
                                  this.elem = ReactDOM.findDOMNode(inst)
                                }
                              },
                              onKeyDown: this.updateCall.bind(
                                this,
                                {
                                  MinMaxCheckType,
                                  MinAcceptableValue,
                                  MaxAcceptableValue,
                                  OldValue: plannedValue,
                                  LeftKeys: leftHierarchy,
                                  BaselineValue: baselineValue,
                                  MetricField: LeftKeys.Name,
                                  Guid: uniqueID,
                                  TopKey: topFields[subindex].Name,
                                  UpdateType,
                                  IsParent
                                },
                                LeftKeys.Name,
                                isPercent,
                                IsApproved
                              )
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          key={`${index}-${subindex}-${1}`}
                          className={`-planned ${isEditableInputClass}`}
                        >
                          {numeral(plannedValue).format(Format)}

                          <input
                            ref={(elem) => {
                              if (uniqueID === cell) {
                                this.elem = elem
                              }
                            }}
                            placeholder={$placeholder}
                            type="text"
                            onClick={this.updateCall.bind(
                              this,
                              {
                                BaselineValue: baselineValue,
                                ExternalEdit,
                                Guid: uniqueID,
                                LeftKeys: leftHierarchy,
                                MetricField: LeftKeys.Name,
                                OldValue: plannedValue,
                                TopKey: topFields[subindex].Name,
                                UpdateType
                              },
                              LeftKeys.Name,
                              isPercent,
                              IsApproved
                            )}
                          />
                        </div>
                      )
                    ) : (
                      <div key={`${index}-${subindex}-${1}`} className="-planned">
                        {numeral(plannedValue).format(Format)}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="slvy-ui-section -end">
            <div className="-cell">
              <div className="-cellContent">
                <div className="-baseline">{numeral(baselineDetail).format(Format)}</div>
                <div className="-planned">{numeral(planDetail).format(Format)}</div>
              </div>
            </div>
          </div>
        </div>
        {isChildren ? (
          <div className="slvy-ui-detail-container">
            {_.map(Children, (item, i) => {
              return (
                <GridDetail
                  key={i}
                  allowedToUpdate={allowedToUpdate}
                  buttonFormat={buttonFormat}
                  className="-opened"
                  click={click}
                  culture={culture}
                  data={item}
                  getLeftHierarchy={getLeftHierarchy}
                  index={i}
                  isScenarioEditable={isScenarioEditable}
                  leftHierarchy={leftHierarchy}
                  selectedRowId={selectedRowId}
                  setHierarchy={setHierarchy}
                  setSelectedRowId={this.props.setSelectedRowId}
                  settings={settings}
                  topFields={topFields}
                  updateMode={updateMode}
                  values={values}
                  viewMode={viewMode}
                  onUpdate={onUpdate}
                />
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }
}
