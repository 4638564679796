import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Button, Row } from 'react-bootstrap'
import numeral from 'numeral'
import NumberFormat from 'react-number-format'
import cx from 'classnames'
import { slvyToast, SlvyTable } from '@/components'
import AssortmentModal from '../Modal'
import Form from './Form'
import DatePicker from './components/DatePicker'
import { arrayToObject, objectToArray } from '../../utils'
import { CURRENCIES_LOCALES } from '../../utils/currencies'
import styles from './index.module.scss'

const textFields = ['Comment', 'StartDate', 'EndDate']
const dateFormat = 'YYYY-MM-DDT00:00:00'
const initialState = { columnPinning: { left: ['SegmentName'] } }

const selectFromState = (state) => ({
  rootOptions: state.options.rootOptions
})

function SegmentSettings(props) {
  const {
    assortmentId: AssortmentId = null,
    culture,
    data = {},
    isUpdateDisabled = false,
    handleCloseModal = () => {},
    onUpdateOption = () => {},
    SegmentOptions,
    show = false
  } = props
  const initialFormData = {
    BasePrice: data?.BasePrice ?? 0,
    Expenditure: data?.Expenditure ?? 0,
    Markdown: data?.Markdown ?? 0,
    Sellthrough: data?.Sellthrough ?? 0,
    PrePackSize: data?.PrePackSize ?? 0,
    TotalPrePackQuantity: data?.TotalPrePackQuantity ?? 0,
    TotalBuyQuantity: data?.TotalBuyQuantity ?? 0,
    SizeRangeId: data?.SizeRangeId ?? 0
  }
  const {
    rootOptions: { Segments = [] }
  } = useSelector(selectFromState)

  const initialSegmentOptions = objectToArray(SegmentOptions)
  const [segmentOptions, setSegmentOptions] = useState(initialSegmentOptions)
  const [formData, setFormData] = useState(initialFormData)

  const { [culture]: { decimalScale = 2, decimalSeparator = '.' } = {} } = CURRENCIES_LOCALES

  const getIsCellUpdated = (colId, original, updatedValue) => {
    const initialData = initialSegmentOptions.find(
      ({ SegmentId, SegmentName }) =>
        SegmentId === original.SegmentId && SegmentName === original.SegmentName
    )

    const isUpdated = initialData[colId] !== updatedValue

    return isUpdated
  }

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'SegmentName',
        size: 200,
        cell: (cell) => {
          const {
            row: { depth, getIsExpanded, getToggleExpandedHandler },
            getValue
          } = cell

          if (depth === 0) {
            const icon = getIsExpanded() ? 'minus' : 'plus'
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div className="d-flex align-items-center mt-1" onClick={getToggleExpandedHandler()}>
                <i className={`fa fa-${icon}-square me-2`} />
                <span>Group: {getValue()}</span>
              </div>
            )
          }

          return <span>{getValue()}</span>
        },
        enableSorting: false,
        meta: {
          visibleColumnMenuOptions: ['filterSettings']
        }
      },
      {
        header: ({ table }) => (
          <div className="d-flex flex-column align-items-center">
            <p>Start Date</p>
            <hr className="settings-modal-from-hr my-3" />
            <DatePicker
              inputClass={cx('p-1', { 'border-white': !isUpdateDisabled })}
              isDisabled={isUpdateDisabled}
              onSelect={(selectedDate) => {
                const formattedDate = selectedDate.format(dateFormat)
                handleBulkInputChange('StartDate', { target: { value: formattedDate } }, table)
              }}
            />
          </div>
        ),
        accessorKey: 'StartDate',
        cell: (cell) => {
          const {
            row: { depth, original },
            column: { id },
            getValue
          } = cell

          if (!depth) {
            return null
          }

          const value = getValue()
          const isUpdated = getIsCellUpdated(id, original, value)

          return (
            <DatePicker
              inputClass={cx('p-1', { 'border-danger': isUpdated })}
              isDisabled={isUpdateDisabled}
              value={getValue()}
              onSelect={(selectedDate) => {
                const formattedDate = selectedDate.format(dateFormat)
                handleInputChange(cell, 'StartDate', { target: { value: formattedDate } })
              }}
            />
          )
        },
        size: 100,
        enableSorting: false,
        meta: {
          hideColumnMenu: true
        }
      },
      {
        header: ({ table }) => (
          <div className="d-flex flex-column align-items-center">
            <p>End Date</p>
            <hr className="settings-modal-from-hr my-3" />
            <DatePicker
              inputClass={cx('p-1', { 'border-white': !isUpdateDisabled })}
              isDisabled={isUpdateDisabled}
              onSelect={(selectedDate) => {
                const formattedDate = selectedDate.format(dateFormat)
                handleBulkInputChange('EndDate', { target: { value: formattedDate } }, table)
              }}
            />
          </div>
        ),
        accessorKey: 'EndDate',
        cell: (cell) => {
          const {
            row: { depth, original },
            column: { id },
            getValue
          } = cell

          if (!depth) {
            return null
          }

          const value = getValue()
          const isUpdated = getIsCellUpdated(id, original, value)

          return (
            <DatePicker
              inputClass={cx('p-1', { 'border-danger': isUpdated })}
              isDisabled={isUpdateDisabled}
              value={value}
              onSelect={(selectedDate) => {
                const formattedDate = selectedDate.format(dateFormat)
                handleInputChange(cell, 'EndDate', { target: { value: formattedDate } })
              }}
            />
          )
        },
        size: 100,
        enableSorting: false,
        meta: {
          hideColumnMenu: true
        }
      },
      {
        header: () => (
          <div>
            Active <br /> Store <br /> Count
          </div>
        ),
        accessorKey: 'NumberOfActiveStores',
        meta: {
          aggregation: 'sum',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ NumberOfActiveStores }) =>
            numeral(NumberOfActiveStores?.value).format('0'),
          hideColumnMenu: true
        },
        size: 60,
        enableSorting: false
      },
      {
        header: () => (
          <div>
            Duration <br /> (Weeks)
          </div>
        ),
        accessorKey: 'AggPeriodCount',
        meta: {
          aggregation: 'avg',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ AggPeriodCount }) => numeral(AggPeriodCount?.value).format('0'),
          hideColumnMenu: true
        },
        size: 70,
        enableSorting: false
      },
      {
        header: ({ table }) => (
          <div className="d-flex flex-column align-items-center">
            <p>Depth</p>
            <hr className="settings-modal-from-hr my-3" />
            <NumberFormat
              fixedDecimalScale
              allowNegative={false}
              className={cx(
                'full-width',
                'assortment-react-table-input',
                'form-control',
                'form-control-sm',
                'fs-xs',
                styles.numberFormat
              )}
              decimalScale={decimalScale}
              decimalSeparator={decimalSeparator}
              defaultValue=""
              disabled={isUpdateDisabled}
              type="text"
              onBlur={(event) => handleBulkInputChange('Depth', event, table)}
              onKeyDown={(event) => {
                if (event.keyCode !== 13) {
                  return
                }

                handleBulkInputChange('Depth', event, table)
              }}
            />
          </div>
        ),
        accessorKey: 'Depth',
        meta: {
          aggregation: 'avg',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ Depth }) => numeral(Depth?.value).format('0.[0000]'),
          hideColumnMenu: true
        },
        cell: (cell) => {
          const {
            row: { depth, original },
            column: { id },
            getValue
          } = cell

          if (!depth) {
            return null
          }

          const value = getValue()
          const isUpdated = getIsCellUpdated(id, original, value)

          return (
            <NumberFormat
              fixedDecimalScale
              allowNegative={false}
              className={cx(
                'full-width',
                'assortment-react-table-input',
                'form-control',
                'form-control-sm',
                'fs-xs',
                {
                  'border-danger': isUpdated
                },
                styles.numberFormat
              )}
              decimalScale={decimalScale}
              decimalSeparator={decimalSeparator}
              defaultValue={numeral(value).value()}
              disabled={isUpdateDisabled}
              type="text"
              onBlur={(event) => handleInputChange(cell, 'Depth', event)}
              onKeyDown={(event) => {
                if (event.keyCode !== 13) {
                  return
                }

                handleInputChange(cell, 'Depth', event)
              }}
            />
          )
        },
        size: 70,
        enableSorting: false
      },
      {
        header: ({ table }) => (
          <div className="d-flex flex-column align-items-center">
            <p>Adjustment Factor</p>
            <hr className="settings-modal-from-hr my-3" />
            <NumberFormat
              fixedDecimalScale
              allowNegative={false}
              className={cx(
                'full-width',
                'assortment-react-table-input',
                'form-control',
                'form-control-sm',
                'fs-xs',
                styles.numberFormat
              )}
              decimalScale={decimalScale}
              decimalSeparator={decimalSeparator}
              defaultValue=""
              disabled={isUpdateDisabled}
              type="text"
              onBlur={(event) => handleBulkInputChange('PricingFactor', event, table)}
              onKeyDown={(event) => {
                if (event.keyCode !== 13) {
                  return
                }

                handleBulkInputChange('PricingFactor', event, table)
              }}
            />
          </div>
        ),
        accessorKey: 'PricingFactor',
        meta: {
          aggregation: 'avg',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ PricingFactor }) =>
            numeral(PricingFactor?.value).format('0.[0000]'),
          hideColumnMenu: true
        },
        cell: (cell) => {
          const {
            row: { depth, original },
            column: { id },
            getValue
          } = cell

          if (!depth) {
            return null
          }

          const value = getValue()
          const isUpdated = getIsCellUpdated(id, original, value)

          return (
            <NumberFormat
              fixedDecimalScale
              allowNegative={false}
              className={cx(
                'full-width',
                'assortment-react-table-input',
                'form-control',
                'form-control-sm',
                'fs-xs',
                {
                  'border-danger': isUpdated
                },
                styles.numberFormat
              )}
              decimalScale={decimalScale}
              decimalSeparator={decimalSeparator}
              defaultValue={numeral(value).format('0.00')}
              disabled={isUpdateDisabled}
              type="text"
              onBlur={(event) => handleInputChange(cell, 'PricingFactor', event)}
              onKeyDown={(event) => {
                if (event.keyCode !== 13) {
                  return
                }

                handleInputChange(cell, 'PricingFactor', event)
              }}
            />
          )
        },
        size: 130,
        enableSorting: false
      },
      {
        header: 'Forecast',
        accessorKey: 'TotalSales',
        meta: {
          aggregation: 'sum',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ TotalSales }) => numeral(TotalSales?.value).format('0'),
          hideColumnMenu: true
        },
        cell: (cell) => (cell.row.depth ? <span>{Math.round(cell.getValue())}</span> : null),
        size: 70,
        enableSorting: false
      },
      {
        header: () => (
          <div>
            Edited <br /> Forecast
          </div>
        ),
        accessorKey: 'EditedForecast',
        meta: {
          aggregation: 'sum',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ EditedForecast }) => numeral(EditedForecast?.value).format('0'),
          hideColumnMenu: true
        },
        size: 70,
        enableSorting: false
      },
      {
        header: () => (
          <div>
            Min <br /> Presentation
          </div>
        ),
        accessorKey: 'MinPresentation',
        meta: {
          aggregation: 'sum',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ MinPresentation }) => numeral(MinPresentation?.value).format('0'),
          hideColumnMenu: true
        },
        size: 95,
        enableSorting: false
      },
      {
        header: () => (
          <div>
            Buy <br /> Quantity
          </div>
        ),
        accessorKey: 'BuyQuantity',
        meta: {
          aggregation: 'sum',
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ BuyQuantity }) => numeral(BuyQuantity?.value).format('0'),
          hideColumnMenu: true
        },
        cell: (cell) => (cell.row.depth ? <span>{Math.round(cell.getValue())}</span> : null),
        size: 70,
        enableSorting: false
      },
      {
        header: 'ROS',
        accessorKey: 'ROS',
        meta: {
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ EditedForecast, AggPeriodCount, NumberOfActiveStores }) => {
            const totalEditedForecast = EditedForecast?.value ?? 0
            const avgDuration = AggPeriodCount?.value ?? 0
            const totalActiveStore = NumberOfActiveStores?.value ?? 0

            if (totalActiveStore > 0 && avgDuration > 0) {
              const result = totalEditedForecast / totalActiveStore / avgDuration
              return numeral(result).format('0')
            }

            return '0'
          },
          hideColumnMenu: true
        },
        size: 60,
        enableSorting: false
      },
      {
        header: () => (
          <div>
            Final <br /> ROS
          </div>
        ),
        accessorKey: 'FinalROS',
        meta: {
          aggregationWithoutExpandableRows: true,
          customAggregationFn: ({ BuyQuantity, AggPeriodCount, NumberOfActiveStores }) => {
            const TotalBuyQuantity = BuyQuantity?.value ?? 0
            const avgDuration = AggPeriodCount?.value ?? 0
            const totalActiveStore = NumberOfActiveStores?.value ?? 0

            if (totalActiveStore > 0 && avgDuration > 0) {
              const result = TotalBuyQuantity / totalActiveStore / avgDuration
              return numeral(result).format('0')
            }

            return '0'
          },
          hideColumnMenu: true
        },
        size: 60,
        enableSorting: false
      },
      {
        header: 'Comment',
        accessorKey: 'Comment',
        cell: (cell) => {
          const {
            row: { depth, original },
            column: { id },
            getValue
          } = cell

          if (!depth) {
            return null
          }

          const value = getValue()
          const isUpdated = getIsCellUpdated(id, original, value)

          return (
            <input
              className={cx(
                'full-width',
                'assortment-react-table-input',
                'form-control',
                'form-control-sm',
                'fs-xs',
                {
                  'border-danger': isUpdated
                },
                styles.numberFormat
              )}
              defaultValue={value}
              disabled={isUpdateDisabled}
              type="text"
              onBlur={(event) => handleInputChange(cell, 'Comment', event)}
              onKeyDown={(event) => {
                if (event.keyCode !== 13) {
                  return
                }

                handleInputChange(cell, 'Comment', event)
              }}
            />
          )
        },
        enableSorting: false,
        meta: {
          hideColumnMenu: true
        }
      }
    ],
    [segmentOptions]
  )

  const handleInputChange = ({ row }, field, event) => {
    const {
      original: { SegmentId }
    } = row

    const $val = event.target.value
    const $value = textFields.some((textField) => textField === field)
      ? $val
      : numeral($val).value()

    const clonedSegmentOptions = _.cloneDeep(segmentOptions)
    const index = clonedSegmentOptions.findIndex((option) => option.SegmentId === SegmentId)
    clonedSegmentOptions[index][field] = $value

    setSegmentOptions(clonedSegmentOptions)
  }

  const handleBulkInputChange = (field, event, table) => {
    const {
      target: { value }
    } = event

    if (value === '') {
      return
    }

    const clonedSegmentOptions = _.cloneDeep(segmentOptions)
    const isTextField = textFields.some((textField) => textField === field)
    const visibleRowNames = table
      .getRowModel()
      .flatRows.filter((row) => row.depth)
      .map((row) => row.original.SegmentName)

    clonedSegmentOptions.forEach((element) => {
      if (visibleRowNames?.length && !visibleRowNames.includes(element.SegmentName)) {
        return
      }

      const updatedValue = isTextField ? value : numeral(value).value()
      element[field] = updatedValue
    })
    setSegmentOptions(clonedSegmentOptions)
  }

  const handleOnSave = () => {
    if (isUpdateDisabled) {
      slvyToast.error({ message: 'Approved option can not update!', title: 'Update Option!' })
      return
    }

    const clonedSegmentOptions = arrayToObject(_.cloneDeep(segmentOptions))

    const OptionStr = {
      ...data,
      ...formData,
      SegmentOptions: clonedSegmentOptions
    }

    onUpdateOption({
      OptionStr,
      AssortmentId
      // cost: OptionStr.Expenditure,
      // price: OptionStr.BasePrice
    })
    handleCloseModal()
  }

  const tableData = segmentOptions.reduce((options, segmentOption) => {
    const matchingSegment = Segments.find((segment) => segment.Id === segmentOption.SegmentId)
    if (!matchingSegment) {
      return options
    }

    const groupName = matchingSegment.GroupByName
    const matchingGroup = options.find((option) => option.SegmentName === groupName)
    if (matchingGroup) {
      matchingGroup.children.push(segmentOption)
    } else {
      options.push({ SegmentName: matchingSegment.GroupByName, children: [segmentOption] })
    }

    return options
  }, [])

  return (
    <AssortmentModal
      isShowFooter
      backdrop="static"
      className={styles.SegmentSettingsModal}
      footerTemplate={
        <Button
          size="sm"
          variant={isUpdateDisabled ? 'outline-dark' : 'success'}
          onClick={handleOnSave}
        >
          <span className="slvy-ui-icon-check-circle-regular" />
        </Button>
      }
      handleCloseModal={handleCloseModal}
      show={show}
      size="slvy-modal-xl assortmentModal"
      title="Settings"
    >
      <Row className="react-table-element-wrapper p-2 gap-2">
        {segmentOptions.length > 0 ? (
          <SlvyTable
            aggregation
            customExpander
            groupAggregation
            className={cx('overflow-auto', styles.segmentSettingsTable)}
            columns={columns}
            data={tableData}
            getCellClassName={styles.cell}
            getHeaderClassName={cx('text-center', 'ps-2', 'pe-1', styles.cell)}
            initialState={initialState}
            theme="sencha-grid"
          />
        ) : null}
        <Form data={formData} isDisabled={isUpdateDisabled} setData={setFormData} />
      </Row>
    </AssortmentModal>
  )
}

export default SegmentSettings
