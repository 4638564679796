Ext.define('Spui.view.common.similarity.Mixin', {
  extend: 'Ext.Mixin',
  config: {
    spotId: '',
    similarGridCfg: 'SimilarGrid',
    similarGridStoreCfg: 'SimilarGridstore',
    similarGridStore: {},
    similarGrid: {},
    tapPanelCfg: 'similartab',
    tapPanel: {}
  },
  forceToLoadStore: true,
  updateSpotId: function (c) {
    if (!c) return
    if (this.hidden) return
    var mainTapPanel = this.up('tabpanel'),
      activeTap = mainTapPanel ? mainTapPanel.getActiveTab() : null
    var p = this,
      t = p.getTapPanel()
    var n = t.getNomalizedChart()
    t.setActiveTab(0)
    p.getSimilarGridStore().setData([])
    n.getNormalizedChartStore().setData([])
    if (activeTap && activeTap.itemId === 'SimilarGridTracking') {
      p.getSimilarGridStore().load()
    }
    if (n.up() && n.up().split) {
      n.getNormalizedChartStore().load()
    }
    return c
  },

  applySimilarGridCfg: function (c) {
    return window.ss(c)
  },

  applyTapPanelCfg: function (c) {
    return window.ss(c)
  },

  applySimilarGridStoreCfg: function (c) {
    return window.ss(c)
  },

  listeners: {
    activate: function (p) {
      setTimeout(() => {
        p.getSimilarGridStore().load()
        p.forceToLoadStore = false
      }, 1000);
      var t = p.getTapPanel(),
        n = t.getNomalizedChart()
      t.setActiveTab(0)
    }
  },

  applySimilarGridStore: function (b) {
    var me = this
    var setRec = this.getSimilarGridStoreCfg()

    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        fieldss: [
          {
            name: 'QTY',
            type: 'number'
          },
          {
            name: 'Price',
            type: 'number'
          },
          {
            name: 'NumberOfStore',
            type: 'number'
          },
          {
            name: 'Envanter',
            type: 'number'
          },
          {
            name: 'DateKey',
            type: 'string'
          },
          {
            name: 'IsSimilarTemp',
            type: 'int',

            convert: function (v) {
              if (typeof v === 'boolean') {
                return v ? 1 : 0
              } else {
                return parseInt(v, 10)
              }
            }
          },
          {
            name: 'PriceNull',
            convert: function (value, record) {
              var v = record.get('Price')

              if (!v) return null
              return v
            },

            depends: ['Price']
          },
          {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
              var v = record.get('NumberOfStore')

              if (!v) return null
              return v
            },

            depends: ['NumberOfStore']
          }
        ],
        autoSync: false,
        listeners: {
          beforeload: function (store) {
            store.getProxy().setExtraParam('spotId', me.getSpotId())
          },

          load: function (store) {
            me.forceToLoadStore = true
            if (this.getSimilarGrid) {
              var grid = this.getSimilarGrid()
              if (grid.storeLoad) {
                grid.storeLoad(store)
              }
            }
          },
          scope: me
        },
        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          api: {
            create: Spui.Global.getApiUrl() + 'spui/SmilarSpot/create',
            read: Spui.Global.getApiUrl() + 'spui/SmilarSpot/read',
            update: Spui.Global.getApiUrl() + 'spui/SmilarSpot/update',
            destroy: Spui.Global.getApiUrl() + 'spui/SmilarSpot/destroy'
          },
          writer: {
            rootProperty: 'data',
            allowSingle: true,
            successProperty: 'success',
            messageProperty: 'errors',
            allDataOptions: {
              associated: false
            },
            writeAllFields: true
          },
          url: Spui.Global.getApiUrl() + 'spui/SmilarSpot'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)
    }
    b.on('write', me.onWrite, me)
    return b
  },

  onWrite: function () {
    var me = this
    var tab = this.getTapPanel().getNomalizedChart().onChangeSelectedSimilarSpot()
    tab = this.getTapPanel().getSelectedChartPanel().onChangeSelectedSimilarSpot()
  },

  applySimilarGrid: function (b) {
    var me = this,
      setRec = this.getSimilarGridCfg(),
      code = setRec.get('code'),
      settings = code || '({})',
      res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        region: 'center',
        xtype: 'grid',
        store: this.getSimilarGridStore(),
        columns: [
          {
            text: 'deneme'
          }
        ],
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                menu: [
                  {
                    text: 'Grid Config',
                    handler: 'onConfigSimilarGrid'
                  },
                  {
                    text: 'store Config',
                    handler: 'onConfigSimilarGridStore'
                  }
                ]
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    return b
  },
  onSelectGrid: function () {},
  updateSimilarGrid: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  onConfigSimilarGrid: function () {
    var setRec = this.getSimilarGridCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigSimilarGridSave, this)
  },
  onConfigSimilarGridSave: function (v, k) {
    var setRec = this.getSimilarGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setSimilarGrid(res)
  },

  onConfigSimilarGridStore: function () {
    var setRec = this.getSimilarGridStoreCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigSimilarGridStoreSave, this)
  },

  onConfigSimilarGridStoreSave: function (v, k) {
    var setRec = this.getSimilarGridStoreCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setSimilarGridStore(res)
  },

  applyTapPanel: function (b) {
    var setRec = this.getTapPanelCfg()
    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        region: 'south',
        flex: 1,
        xtype: 'spui-view-common-similarity-tabpanel-main',
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigTapPanel'
              }
            ]
          }
        ]
      })
      b = Ext.create(b)
    }
    return b
  },

  updateTapPanel: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  onConfigTapPanel: function () {
    var setRec = this.getTapPanelCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigTapPanelSave, this)
  },

  onConfigTapPanelSave: function (v, k) {
    var setRec = this.getTapPanelCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setTapPanel(res)
  }
})
