Ext.define('MDM.src.view.globalapp.Panel.Dpi.Tree', {
  extend: 'Ext.tree.Panel',
  xtype: 'globalapp-dpi-tree',
  rootVisible: true,
  columnLines: true,
  rowLines: true,
  reference: 'dpitree',
  tools: [
    {
      type: 'plus',
      tooltip: 'Refresh form Data',
      handler: 'onAddDPI'
    },
    {
      type: 'help',
      tooltip: 'Get Help',
      callback: function (panel, tool, event) {}
    }
  ],
  bind: {
    store: '{DPITreeStore}'
  },
  columns: [
    {
      xtype: 'treecolumn',
      text: 'Title',
      dataIndex: 'text',
      flex: 2,
      width: 100,
      sortable: true,
      editor: {
        xtype: 'textfield',
        allowBlank: false,
        allowOnlyWhitespace: false,
        maxLength: 100
      }
    }
  ],
  viewConfig: {
    markDirty: false,
    listeners: {}
  },
  listeners: {
    activate: 'onActiveteTree'
  },
  tbar: {
    reference: 'tbar',
    items: [
      {
        text: 'Expand All',
        target: 'dpitree',
        ui: 'btn-success',
        isCollapsed: true,
        handler: 'onExpandAllClick'
      }
    ]
  }
})
