/* eslint-disable react/jsx-sort-props */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */

import { isValidElement, ReactNode } from 'react'
import { ToastContainer, toast, Slide } from 'react-toastify'
import { useAppSelector } from '@/hooks/store'

import 'react-toastify/dist/ReactToastify.css'
import styles from './index.module.scss'
import './react-toastify-overrides.scss'

const timeout = {
  fast: 4000,
  slow: 15000
}

export const Title = ({ children }: { children: ReactNode }) => (
  <div className={styles['toast-title']}>{children}</div>
)

function SlvyToastContainer() {
  const environment = useAppSelector((state) => state.setting.environment)
  const isDark: boolean = global.matchMedia('(prefers-color-scheme: dark)')?.matches || false

  return (
    <ToastContainer
      hideProgressBar={false}
      newestOnTop
      pauseOnFocusLoss
      closeOnClick
      pauseOnHover
      autoClose={environment === 'Configuration' ? timeout.fast : timeout.slow}
      theme={isDark ? 'dark' : 'light'}
      transition={Slide}
    />
  )
}

type ToastType = 'default' | 'info' | 'success' | 'warning' | 'error'

// type is NOT === JSX.Element | undefined | function => throw
// Should I throw instead of returning undefined, like ts?
const isValidElementOrFunction = (content: any): JSX.Element | undefined => {
  return isValidElement(content) ? content : typeof content === 'function' ? content : undefined
}

interface Proto {
  title?: string
  message?: string
  component?: JSX.Element
  options?: Options
}

type Content = string | JSX.Element

interface Options {
  containerId?: string | number
  type?: ToastType
  autoClose?: number
  [key: string]: any
}

const prepareArgs = ({
  title,
  message,
  component,
  options
}: Proto): [content?: Content, options?: Options] => {
  const processedOptions = prepareOptions(options)
  const validComponent = isValidElementOrFunction(component)
  if (typeof validComponent !== 'undefined') {
    return [validComponent, processedOptions]
  }

  if (typeof message === 'string') {
    if (typeof title !== 'string') {
      return [message, processedOptions]
    }
    const messageElement = <div>{message}</div>
    const titleElement = <div className={styles['toast-title']}>{title}</div>
    return [
      <>
        {titleElement}
        {messageElement}
      </>,
      processedOptions
    ]
  }
  throw Error
}

const prepareOptions = (options?: Options) => {
  if (typeof options === 'undefined') {
    return
  }
  if (typeof options.containerId !== 'undefined') {
    return { ...optionsObj[options.containerId], ...options }
  }
  return options
}

interface OptionsObj {
  [id: string]: Options
}
const optionsObj: OptionsObj = {}

const setOptions = (options: Options) => {
  if (typeof options.containerId !== 'undefined') {
    optionsObj[options.containerId] = options
  }
}

const makeToast = (obj: Proto) => {
  return toast(...prepareArgs(obj))
}

const makeToastType = (type: ToastType) => {
  return (obj: Proto) => {
    return makeToast(addType(obj, type))
  }
}

const addType = (obj: Proto, type: ToastType) => {
  const externalOptions: Options = type === 'success' ? { autoClose: timeout.fast } : {}

  return {
    ...obj,
    options: { type, ...obj.options, ...externalOptions }
  }
}

type mockId = string | number
type Toast = (obj: Proto) => mockId

interface SlvyToast {
  default: Toast
  info: Toast
  success: Toast
  warning: Toast
  error: Toast
  dismiss: (id: mockId) => void
  setOptions: (options: Options) => void
}

const slvyToast: SlvyToast = {
  default: makeToast,
  info: makeToastType('info'),
  success: makeToastType('success'),
  warning: makeToastType('warning'),
  error: makeToastType('error'),
  dismiss: toast.dismiss,
  setOptions
}

export { SlvyToastContainer, slvyToast }
