/* global Ext */
Ext.setKeyboardMode = function (keyboardMode) {
  Ext.keyboardMode = keyboardMode

  var keyboardModeCls = Ext.baseCSSPrefix + 'keyboard-mode'
  const body = Ext.getBody()
  if (body && body.dom && body.dom.classList) {
    body.dom.classList.toggle(keyboardModeCls, keyboardMode)
  }
}
