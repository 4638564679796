import { useEffect, useRef } from 'react'
import { debounce, isNil } from 'lodash'
import { Form } from 'react-bootstrap'
import { useTheme } from '../../../contexts/ThemeContext'
import { TextFilterProps } from './TextFilter.types'

export default function TextFilter({ getValue, onChange }: TextFilterProps) {
  const inputRef = useRef('')
  const styles = useTheme()

  useEffect(() => {
    if (!isNil(inputRef.current)) {
      inputRef.current.value = getValue() ?? ''
    }
  }, [getValue()])

  const handleChange = debounce((event) => {
    onChange(event.target.value)
  }, 500)

  return (
    <div className={styles.textFilter} onClick={(event) => event.stopPropagation()}>
      <i className="fa fa-fw fa fa-search" />
      <Form.Control
        ref={inputRef}
        className={styles.textFilterInput}
        placeholder="Search..."
        type="text"
        defaultValue={getValue() ?? ''}
        onChange={handleChange}
      />
    </div>
  )
}
