import React, { Component, createRef } from 'react'
import _ from 'lodash'
import { ResizableBox } from 'react-resizable'
import { connect } from 'react-redux'
import cx from 'classnames'
import { saveTimelineSettings } from '../../store/actions'
import { MESSAGES } from '../../messages'
import { OverlayTrigger } from '..'

class Timeline extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty: false
    }

    this.timeContainerRef = createRef()
    this.timelineListRef = createRef()

    this.scrollable = {
      down: false,
      scrollLeft: 0,
      x: 0
    }
  }

  componentDidMount() {
    const { timelineSettings: { AggregationLevelId, AggregationPeriods = {} } = {} } = this.props
    const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods
    const {
      EndDisplayName = '',
      PeriodName = '',
      StartDisplayName = '',
      scrollRange
    } = AggCurrentPeriod

    // This check for initial mount
    // Check app.js line 340 before update start&end dates
    if (!StartDisplayName && !EndDisplayName && !PeriodName) {
      const { payload: { AggregationLevelId: _AggregationLevelId, CurrentAgg = {} } = {} } =
        this.getCurrentPeriod()
      this.props.saveTimelineSettings({
        payload: {
          AggregationPeriods: {
            ...AggregationPeriods,
            [_AggregationLevelId]: { ...CurrentAgg }
          }
        }
      })
    } else {
      const { defaultUnitSize } = this.getTimeLineProperties(AggregationLevelId)
      const { current } = this.timeContainerRef

      if (current) {
        current.scrollLeft = scrollRange * defaultUnitSize

        this.moveScrollLeft(AggregationLevelId, scrollRange)
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      config: { shouldRememberLastTimelineSelection = false } = {},
      timelineSettings = {},
      pluginId = ''
    } = nextProps

    if (
      shouldRememberLastTimelineSelection &&
      !_.isEqual(this.props.timelineSettings, nextProps.timelineSettings)
    ) {
      localStorage.setItem(`dp-TimelineSettings-${pluginId}`, JSON.stringify(timelineSettings))
    }
  }

  eventDispatcher = (detail) => {
    dispatchEvent(
      new CustomEvent('timelineRetail', {
        detail
      })
    )
  }

  getCurrentPeriod = (aggregationLevel) => {
    const {
      aggregationLevelId: AggregationLevelId,
      max,
      min
    } = this.getTimeLineProperties(aggregationLevel)
    const { periods = [] } = this.props
    const { Name: PeriodName, Values = [] } =
      _.find(periods, (period) => period.AggregationLevel === AggregationLevelId) || {}

    const newValues = _.slice(Values, min, max)

    const { StartDate, DisplayName: StartDisplayName } = _.first(newValues)
    const { EndDate, DisplayName: EndDisplayName } = _.last(newValues)

    const payload = {
      AggregationLevelId,
      CurrentAgg: {
        EndDate,
        EndDisplayName,
        PeriodName,
        StartDate,
        StartDisplayName,
        currentSelection: max,
        scrollRange: min
      }
    }

    return {
      Values,
      payload
    }
  }

  getTimeLineProperties = (aggregationLevel) => {
    const {
      props: {
        periods = [],
        size: { width } = {},
        config: { defaultAggregationLevelId, settings = [] } = {},
        timelineSettings: { AggregationPeriods = {} } = {}
      } = {}
    } = this

    const { [aggregationLevel]: AggCurrentPeriod = {} } = AggregationPeriods
    let { currentSelection = 0 } = AggCurrentPeriod

    const { Values = [] } =
      _.find(
        periods,
        (period) => period.AggregationLevel === (aggregationLevel || defaultAggregationLevelId)
      ) || {}
    const foundSettings =
      _.find(
        settings,
        (setting) => setting.aggregationLevelId === (aggregationLevel || defaultAggregationLevelId)
      ) || {}

    const lengthOfCurrentPeriodAgg = Values.length

    let { max = 6, defaultUnitSize = 10 } = foundSettings

    if (lengthOfCurrentPeriodAgg) {
      max = lengthOfCurrentPeriodAgg < max ? lengthOfCurrentPeriodAgg : max
    }

    currentSelection = currentSelection === 0 ? max : currentSelection

    const timePickerWidth = defaultUnitSize * currentSelection
    const emptyGap = width - (150 + defaultUnitSize)

    return {
      ...foundSettings,
      emptyGap,
      max,
      sliderWith: defaultUnitSize * max,
      timePickerWidth
    }
  }

  getClosestFutureDate = (dates, key, targetDate) => {
    targetDate = new Date(targetDate)

    let closestDate = Infinity
    let closestIndex = null

    dates.forEach((item, index) => {
      const currentDate = new Date(item[key])

      if (targetDate >= currentDate) {
        closestDate = currentDate
        closestIndex = index
        if (targetDate > currentDate) {
          const isLast = index + 1 === dates.length
          closestIndex = isLast || key === 'StartDate' ? index : index + 1
          closestDate = new Date(dates[closestIndex][key])
        }
      } else {
        // Dates coming with own sort (ASC) and if first item is smaller than target? following items
        // can not be bigger, so select first as closest.
        if (index === 0) {
          closestDate = currentDate
          closestIndex = index
        }
      }
    })

    return {
      closestDate,
      closestIndex
    }
  }

  getClosestDate(CurrentRange, AggCurrentPeriod) {
    const { closestIndex: startClosestIndex } = this.getClosestFutureDate(
      CurrentRange,
      'StartDate',
      AggCurrentPeriod.StartDate
    )

    const { closestIndex: endClosestIndex } = this.getClosestFutureDate(
      CurrentRange,
      'EndDate',
      AggCurrentPeriod.EndDate
    )

    const { StartDate: closestStartDate, DisplayName: closestStartDisplayName } =
      CurrentRange[startClosestIndex]
    const { EndDate: closestEndDate, DisplayName: closestEndDisplayName } =
      CurrentRange[endClosestIndex]

    if (new Date(closestEndDate) < new Date(closestStartDate)) {
      console.warn(MESSAGES.the_start_date_cannot_bigger_than_the_end_date)
    }

    return {
      EndDate: closestEndDate,
      EndDisplayName: closestEndDisplayName,
      StartDate: closestStartDate,
      StartDisplayName: closestStartDisplayName,
      currentSelection: endClosestIndex - (startClosestIndex - 1),
      scrollRange: startClosestIndex
    }
  }

  onPeriodCallback(options) {
    const {
      props: {
        onPeriodUpdate = () => {},
        config: { buttonSelection = false } = {},
        timelineSettings: { AggregationLevelId: _AggregationLevelId, AggregationPeriods = {} } = {}
      }
    } = this

    const {
      AggregationLevel = _AggregationLevelId,
      extraState = {},
      isPeriodClick = false,
      selection
    } = options

    const { [_AggregationLevelId]: AggCurrentPeriod = {}, [AggregationLevel]: AggNextPeriod = {} } =
      AggregationPeriods

    const { Values = [], payload: { CurrentAgg: { PeriodName } } = {} } =
      this.getCurrentPeriod(AggregationLevel)
    const { w = 6, x = 0 } = selection
    const CurrentRange = _.slice(Values, x, w)

    if (!CurrentRange.length) {
      return
    }

    // This is default behaviour
    const { StartDate, DisplayName: StartDisplayName } = _.first(CurrentRange)
    const { EndDate, DisplayName: EndDisplayName } = _.last(CurrentRange)

    let AggNewPeriod = {
      EndDate,
      EndDisplayName,
      PeriodName,
      StartDate,
      StartDisplayName
    }

    let _scrollRange = 0

    if (isPeriodClick) {
      // Get Closest
      if (_.isEmpty(AggNextPeriod)) {
        const closestDates = this.getClosestDate(Values, AggCurrentPeriod)

        _scrollRange = closestDates.scrollRange
        AggNewPeriod = {
          ...AggNewPeriod,
          ...closestDates
        }
        // Get from saved state
      } else {
        _scrollRange = AggNextPeriod.scrollRange
        AggNewPeriod = AggNextPeriod
      }
    }

    this.props
      .saveTimelineSettings({
        payload: {
          AggregationLevelId: AggregationLevel,
          AggregationPeriods: {
            ...AggregationPeriods,
            [AggregationLevel]: {
              ...AggCurrentPeriod,
              ...extraState,
              ...AggNewPeriod
            }
          }
        }
      })
      .then(() => {
        if (!buttonSelection || isPeriodClick) {
          onPeriodUpdate()
        } else {
          this.setState({ isDirty: true })
        }
        if (isPeriodClick) {
          this.moveScrollLeft(AggregationLevel, _scrollRange)
        }
      })
  }

  moveScrollLeft(AggregationLevel, _scrollRange) {
    // @TODO: Move slider to the own selection range
    const { defaultUnitSize = 10 } = this.getTimeLineProperties(AggregationLevel)
    const timelineList = this.timelineListRef.current
    timelineList.scrollLeft = defaultUnitSize * _scrollRange
  }

  timeSliderHandler(type, timelineSettings, e) {
    e.preventDefault()

    const { clientX } = e
    const { defaultUnitSize = 10, max } = timelineSettings
    const timelineList = this.timelineListRef.current
    const timeContainer = this.timeContainerRef.current
    const { timelineSettings: { AggregationLevelId = '', AggregationPeriods = {} } = {} } =
      this.props

    const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods
    const { currentSelection = max } = AggCurrentPeriod

    switch (type) {
      case 'up':
        setTimeout(() => timeContainer.classList.remove('on-drag'), 200)
        this.scrollable.down = false

        const retUp = timelineList.scrollLeft % defaultUnitSize
        const newScroll =
          retUp >= defaultUnitSize / 2
            ? timelineList.scrollLeft + (defaultUnitSize - retUp)
            : timelineList.scrollLeft - retUp
        const scrollToRange = newScroll / defaultUnitSize

        timelineList.scrollLeft = newScroll

        this.props
          .saveTimelineSettings({
            payload: {
              AggregationPeriods: {
                ...AggregationPeriods,
                [AggregationLevelId]: {
                  ...AggCurrentPeriod,
                  scrollRange: scrollToRange
                }
              }
            }
          })
          .then(() =>
            this.onPeriodCallback({
              selection: { w: currentSelection + scrollToRange, x: scrollToRange }
            })
          )

        break

      case 'leave':
        this.scrollable.down = false
        timeContainer.classList.remove('on-drag')
        break

      case 'down':
        this.scrollable.down = true
        this.scrollable.scrollLeft = timelineList.scrollLeft
        this.scrollable.x = clientX
        timeContainer.classList.add('on-drag')
        break

      case 'move':
        if (this.scrollable.down) {
          timelineList.scrollLeft = this.scrollable.scrollLeft + this.scrollable.x - clientX
          timeContainer.classList.add('on-drag')
        }
        break
      default:
    }

    this.eventDispatcher({
      scroll: timelineList.scrollLeft,
      type
    })
  }

  onResizeStop(event, data, defaultUnitSize) {
    event.stopImmediatePropagation()
    const { timelineSettings: { AggregationLevelId = '', AggregationPeriods = {} } = {} } =
      this.props
    const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods
    const { scrollRange = 0 } = AggCurrentPeriod

    const {
      size: { width = 0 }
    } = data
    const selectedCount = Math.round(width / defaultUnitSize)

    this.props
      .saveTimelineSettings({
        payload: {
          AggregationPeriods: {
            ...AggregationPeriods,
            [AggregationLevelId]: {
              ...AggCurrentPeriod,
              currentSelection: selectedCount
            }
          }
        }
      })
      .then(() => {
        this.onPeriodCallback({
          selection: {
            w: selectedCount + scrollRange,
            x: scrollRange
          }
        })
      })
  }

  onReloadClick() {
    const {
      onPeriodUpdate = () => {},
      timelineSettings: {
        Collapse: { isPin } = {},
        AggregationLevelId = '',
        AggregationPeriods = {}
      } = {}
    } = this.props
    const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods

    this.props.saveTimelineSettings({
      payload: {
        AggregationPeriods: {
          // @TODO: Kill all other aggregation
          [AggregationLevelId]: AggCurrentPeriod
        }
      }
    })

    onPeriodUpdate()
    this.toggleCollapsed({ isCollapsed: true, isPin })

    this.setState({ isDirty: false })
  }

  onPeriodClick(AggregationLevel, max, timePicker) {
    const { timelineSettings: { Collapse: { isPin } = {} } = {} } = this.props

    const currentTimelineSelection = { w: max, x: 0 }
    this.onPeriodCallback({
      selection: currentTimelineSelection,
      AggregationLevel,
      isPeriodClick: true,
      extraState: {
        currentSelection: max,
        scrollRange: 0
      },
      timePicker
    })

    this.setState({ isDirty: false })
    this.toggleCollapsed({ isCollapsed: false, isPin })
  }

  toggleCollapsed = (Collapse) => {
    this.props.saveTimelineSettings({
      payload: {
        Collapse
      }
    })
  }

  render() {
    const {
      periods = [],
      timePicker = true,
      config: { buttonSelection } = {},
      timelineSettings,
      timelineSettings: {
        Collapse: { isCollapsed, isPin } = {},
        AggregationLevelId,
        AggregationPeriods = {}
      } = {}
    } = this.props

    const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods
    const { StartDisplayName = '', EndDisplayName = '', PeriodName = '' } = AggCurrentPeriod

    const { Values = [] } = this.getCurrentPeriod(AggregationLevelId)
    const { isDirty = false } = this.state
    const timeLineProperties = this.getTimeLineProperties(AggregationLevelId)

    const {
      defaultUnitSize = 10,
      emptyGap,
      isRotate = false,
      sliderWith,
      timePickerWidth
    } = timeLineProperties

    const getListItemStyle = (_emptyGap) => {
      return {
        marginRight: _emptyGap,
        visibility: 'hidden',
        width: 0
      }
    }

    const $timelineCollapsedClass = isCollapsed && !isPin ? '-collapsed' : ''
    const $pinClass = isPin ? '-pin' : ''

    return (
      <div className="time-wrapper">
        <div
          ref={this.timeContainerRef}
          className={`time-container ${$timelineCollapsedClass} ${$pinClass}`}
        >
          <header className="timeline-header">
            <ul className="period-list">
              {_.map(periods, (period, key) => {
                const { AggregationLevel, Name } = period
                const { max, isVisible = false } = this.getTimeLineProperties(AggregationLevel)

                return isVisible ? (
                  <li
                    key={key}
                    className={cx({ 'active-period': AggregationLevelId === AggregationLevel })}
                    onClick={this.onPeriodClick.bind(this, AggregationLevel, max, timePicker)}
                  >
                    {Name}
                  </li>
                ) : null
              })}
            </ul>
            {!_.isEmpty(timelineSettings)
              ? [
                  <div
                    key="summary"
                    className="summary"
                    onClick={() =>
                      !isPin ? this.toggleCollapsed({ isCollapsed: !isCollapsed, isPin }) : () => {}
                    }
                  >
                    <div className="summary-timespan">
                      {StartDisplayName} - {EndDisplayName} / {PeriodName}
                    </div>
                  </div>,
                  <button
                    key="pin"
                    className="-btn-pin"
                    type="button"
                    onClick={() => this.toggleCollapsed({ isCollapsed, isPin: !isPin })}
                  >
                    <OverlayTrigger tooltip={isPin ? 'Unpin' : 'Pin'}>
                      <i className="slvy-ui-icon-map-pin" />
                    </OverlayTrigger>
                  </button>
                ]
              : null}
          </header>

          {timePicker ? (
            <div className="timeline-- clearfix">
              <ResizableBox
                axis="x"
                className="timeSpan"
                draggableOpts={{ grid: [defaultUnitSize, defaultUnitSize] }}
                height={70}
                maxConstraints={[sliderWith, 70]}
                minConstraints={[defaultUnitSize, defaultUnitSize]}
                width={timePickerWidth}
                onResizeStop={(e, data) => this.onResizeStop(e, data, defaultUnitSize)}
              >
                {!_.isEmpty(timelineSettings) ? (
                  <div className="summary">
                    {buttonSelection && isDirty ? (
                      <span
                        className="-reload-timeline"
                        title={MESSAGES.reload_timeline}
                        onClick={this.onReloadClick.bind(this)}
                      >
                        <i className="slvy-ui-icon-recycle" />
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </ResizableBox>

              <ul
                ref={this.timelineListRef}
                className={cx('clearfix', { '-rotate': isRotate })}
                onMouseDown={this.timeSliderHandler.bind(this, 'down', timeLineProperties)}
                onMouseLeave={this.timeSliderHandler.bind(this, 'leave', timeLineProperties)}
                onMouseMove={this.timeSliderHandler.bind(this, 'move', timeLineProperties)}
                onMouseUp={this.timeSliderHandler.bind(this, 'up', timeLineProperties)}
              >
                {_.map(Values, (item, index) => {
                  const { DisplayName } = item
                  return (
                    <li key={index} style={{ width: defaultUnitSize }}>
                      <span>{DisplayName}</span>
                    </li>
                  )
                })}
                <li key="empty" style={getListItemStyle(emptyGap)}>
                  empty
                </li>
              </ul>
              <div className="timeline-list-bg" />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    timelineSettings: state.timelineSettings.timelineSettings
  }
}

const mapDispatchToProps = {
  saveTimelineSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)
