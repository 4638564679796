import { Component, createRef } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { Button } from 'react-bootstrap'

import EditorSelector from '../EditorSelector'
import { defaultTemplate, visitSchema } from '../helpers'
import './ArrayEditor.scss'

export default class ArrayEditor extends Component {
  constructor(props) {
    super(props)

    this.dragItem = createRef()
    this.dragOverItem = createRef()

    this.state = {
      value: [],
      visibleContent: {},
      activeColumnCount: 0
    }

    this.addItem = this.addItem.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.renderContent = this.renderContent.bind(this)
    this.renderCollapseButton = this.renderCollapseButton.bind(this)
    this.renderArrayItemButtons = this.renderArrayItemButtons.bind(this)
    this.renderMainButtons = this.renderMainButtons.bind(this)
    this.dragStart = this.dragStart.bind(this)
    this.dragEnter = this.dragEnter.bind(this)
    this.drop = this.drop.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { schema, value } = this.props
    // TODO : schema default
    const val = value || schema.default || []
    this.setState({ value: val })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      if (nextProps.schema?.isSupportMultipleColumn && !nextProps.value[0]?.width) {
        nextProps.value.forEach((val, idx) => {
          if (val.generalOptions?.header) {
            delete val.generalOptions.header
            const generalOptions = _.clone(val.generalOptions)
            delete val.generalOptions
            nextProps.value[idx] = { ...val, ...generalOptions }
          }
        })
      }
      this.setState({ value: nextProps.value })
    }
  }

  addItem() {
    const newItem = visitSchema(this.props.schema.items, {})

    this.setState(
      (prevState) => ({
        value: [...prevState.value, newItem]
      }),
      this.handleChange
    )
  }

  handleChange() {
    const {
      schema: { items: { properties = {} } = {} },
      onChange
    } = this.props
    const { value } = this.state

    if (_.isFunction(onChange)) {
      // NOTE: The IDs used for ordering columns were written into the database,
      // and we are deleting them by performing a 'hasId' check to clean them up
      // without affecting the IDs used in schema.js.
      const propertyKey = Object.keys(properties)
      const hasId = propertyKey.some((property) => property === 'id')

      const columns =
        hasId || !propertyKey.length
          ? value
          : value?.map((column) => {
              const { id, ...rest } = column
              return rest
            })
      onChange(columns)
    }
  }

  renderArrayItemButtons(index) {
    const {
      schema: { items = {} }
    } = this.props
    const {
      title = 'Item',
      options: {
        disable_array_delete = false,
        disable_array_copy = false,
        prompt_before_delete = false
      } = {}
    } = items
    //TODO : this.schema.options.disable_collapse

    return (
      <div className="d-flex align-items-center btn-group">
        {!disable_array_copy && (
          <button
            className="btn btn-default json-editor-btn-copy copy"
            title={`Copy ${title}`}
            type="button"
            onClick={() =>
              this.setState((prevState) => {
                const newValue = [...prevState.value]
                const item = newValue[index]
                newValue.push(item)
                return { value: newValue }
              }, this.handleChange)
            }
          >
            <i className="fa fa-files-o copyIcon" />
          </button>
        )}
        {!disable_array_delete && (
          <button
            className="btn btn-default json-editor-btn-delete delete"
            title={`Delete ${title}`}
            type="button"
            onClick={() => {
              if (prompt_before_delete) {
                if (window.confirm('Are you sure you want to remove this node?') === false) {
                  return
                }
              }

              this.setState(({ value, visibleContent }) => {
                const newValue = [...value]
                newValue.splice(index, 1)
                const updatedVisibleContent = Object.keys(visibleContent).reduce(
                  (acc, activeColumn) => {
                    const activeColumnIndex = Number(activeColumn)
                    if (activeColumnIndex === index) {
                      return acc
                    }

                    const currentIndex =
                      activeColumnIndex > index ? activeColumnIndex - 1 : activeColumnIndex

                    acc[currentIndex] = false
                    return acc
                  },
                  {}
                )

                return {
                  value: newValue,
                  visibleContent: updatedVisibleContent,
                  activeColumnCount: Object.keys(updatedVisibleContent).length
                }
              }, this.handleChange)
            }}
          >
            <i className="fa fa-times deleteIcon" />
          </button>
        )}
      </div>
    )
  }

  renderCollapseButton(index) {
    const {
      schema: { items = {} }
    } = this.props
    const { visibleContent } = this.state
    const { options: { collapsed = true } = {} } = items
    const { [index]: stateCollapsed = collapsed } = visibleContent

    const buttonSettings = stateCollapsed
      ? { title: 'Expand', icon: 'plus', class: '' }
      : { title: 'Collapse', icon: 'minus', class: 'active' }

    return (
      <button
        className={`d-flex justify-content-center align-items-center me-3 text-white border-0 collapseBtn ${buttonSettings.class}`}
        title={buttonSettings.title}
        type="button"
        onClick={() => this.handleCollapseBtn(index, stateCollapsed)}
      >
        <i className={`fa fa-${buttonSettings.icon}`} />
      </button>
    )
  }

  handleCollapseBtn(index, stateCollapsed) {
    if (stateCollapsed) {
      this.setState((prevState) => ({
        visibleContent: {
          ...prevState.visibleContent,
          [index]: !stateCollapsed
        },
        activeColumnCount: prevState.activeColumnCount + 1
      }))
      return
    }

    const visibleContent = { ...this.state.visibleContent }
    delete visibleContent[index]
    this.setState((prevState) => ({
      visibleContent: {
        ...visibleContent
      },
      activeColumnCount: prevState.activeColumnCount - 1
    }))
  }

  dragStart(position) {
    this.dragItem.current = position
  }

  dragEnter(position) {
    this.dragOverItem.current = position
  }

  drop() {
    const { value } = this.state
    const copyListItems = value
    const dragItemContent = copyListItems[this.dragItem.current]
    copyListItems.splice(this.dragItem.current, 1)
    copyListItems.splice(this.dragOverItem.current, 0, dragItemContent)
    this.dragItem.current = null
    this.dragOverItem.current = null
    this.setState({ value: copyListItems }, this.handleChange)
  }

  renderHeader(index, data) {
    const {
      schema: { items = {} }
    } = this.props
    const { title = 'Item', headerTemplate } = items

    let headerText

    if (!_.isNil(headerTemplate)) {
      const headerTemplateCompiled = defaultTemplate().compile(headerTemplate)
      headerText = headerTemplateCompiled({ self: data, i: index })
    }

    if (_.isNil(headerText) || _.isEmpty(headerText) || headerText === 'undefined') {
      headerText = `${title} ${index + 1}`
    }

    return (
      <div
        key={`${index}divHeader`}
        draggable
        className="position-relative overflow-hidden subProperty"
        onDragEnd={() => this.drop()}
        onDragEnter={() => this.dragEnter(index)}
        onDragStart={() => this.dragStart(index)}
      >
        <h3 className="d-flex headerColumn">
          {this.renderCollapseButton(index)}
          <span
            className="w-100 mw-100 cp"
            onClick={() =>
              this.setState({ visibleContent: { [index]: false }, activeColumnCount: 1 })
            }
          >
            {headerText}
          </span>
          {this.renderArrayItemButtons(index)}
        </h3>
      </div>
    )
  }

  renderContent(index, data) {
    const {
      schema: { items = {}, isSupportMultipleColumn = false },
      schemaPath,
      getWatchedValue
    } = this.props
    const { activeColumnCount, visibleContent } = this.state
    const isMultipleColumnActive = activeColumnCount > 1

    // Column properties moved under generalOptions for Sencha Grid
    if (isSupportMultipleColumn) {
      _.set(items, 'properties.generalOptions.options.collapsed', isMultipleColumnActive)
      if (!isMultipleColumnActive && data) {
        const { generalOptions, ...rest } = data
        data.generalOptions = rest
      }
    }

    const { options: { collapsed = true } = {} } = items
    const { [index]: stateCollapsed = collapsed } = visibleContent
    const value = isMultipleColumnActive ? {} : data

    return (
      <div key={`${index}divContent`} className="position-relative subProperty">
        <div className={cx('', { hidden: stateCollapsed })}>
          <div className={`form-group ${isMultipleColumnActive}`}>
            <EditorSelector
              key={index}
              getWatchedValue={getWatchedValue}
              hidden={stateCollapsed}
              isMultipleColumnActive={isMultipleColumnActive}
              schema={items}
              schemaPath={`${schemaPath}[${index}]`}
              value={value}
              onChange={(val) => {
                this.handleChangeProperty(index, val)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  handleChangeProperty(index, val) {
    const { activeColumnCount, visibleContent = {} } = this.state

    if (val.generalOptions) {
      const filteredGeneral = Object.entries(val.generalOptions).reduce(
        (filteredGeneral, [key, value]) => {
          if (typeof value !== 'object') {
            filteredGeneral[key] = value
          }
          return filteredGeneral
        },
        {}
      )
      delete val['generalOptions']
      val = { ...val, ...filteredGeneral }
    }

    if (activeColumnCount < 2) {
      this.setState((prevState) => {
        const newValue = [...prevState.value]
        newValue[index] = val
        return { value: newValue }
      }, this.handleChange)
      return
    }

    const activeColumnsIndexes = Object.keys(visibleContent)

    this.setState((prevState) => {
      const newValue = [...prevState.value]
      activeColumnsIndexes.forEach((idx) => {
        if (newValue[idx]?.generalOptions) delete newValue[idx].generalOptions
        this.updateValueForMultipleEdit(newValue[idx], val.multipleEdit)
        newValue[idx] = { ...newValue[idx], ...newValue[idx].generalOptions }
      })
      return { value: newValue }
    }, this.handleChange)
  }

  updateValueForMultipleEdit(val, multiple) {
    Object.entries(multiple).forEach(([key, value]) => {
      if (!val[key] || key === 'generalOptions') {
        val[key] = value
        return
      }
      if (typeof value === 'object') {
        this.updateValueForMultipleEdit(val[key], multiple[key])
        return
      }
      val[key] = value
    })
  }

  renderSyncButton() {
    return (
      <Button
        className="json-editor-btn-edit"
        size="xs"
        title="Auto Generate Items"
        type="button"
        variant="outline-dark"
        onClick={() => {
          if (_.isFunction(this.props.onSynchronizeDataDefinition)) {
            this.props.onSynchronizeDataDefinition(this.props.schemaPath)
          }
        }}
      >
        <i className="fa fa-pencil" /> Generate
      </Button>
    )
  }

  renderMainButtons() {
    const {
      schema: { items = {}, options: { syncButton = false } = {} }
    } = this.props
    const { title } = items

    // TODO : buttons ??
    /*
        this.hide_delete_buttons = this.options.disable_array_delete || this.jsoneditor.options.disable_array_delete
        this.hide_delete_all_rows_buttons = this.hide_delete_buttons || this.options.disable_array_delete_all_rows || this.jsoneditor.options.disable_array_delete_all_rows
        this.hide_delete_last_row_buttons = this.hide_delete_buttons || this.options.disable_array_delete_last_row || this.jsoneditor.options.disable_array_delete_last_row
        this.hide_move_buttons = this.options.disable_array_reorder || this.jsoneditor.options.disable_array_reorder
        this.hide_add_button = this.options.disable_array_add || this.jsoneditor.options.disable_array_add
        this.show_copy_button = this.options.enable_array_copy || this.jsoneditor.options.enable_array_copy
        this.array_controls_top = this.options.array_controls_top || this.jsoneditor.options.array_controls_top
    */

    return (
      <div className="btn-group generate-button-container my-2">
        <Button
          className="json-editor-btn-add"
          size="xs"
          title={`Add ${title}`}
          type="button"
          variant="outline-dark"
          onClick={this.addItem}
        >
          <i className="fa fa-plus" /> {title}
        </Button>
        {syncButton && this.renderSyncButton()}
      </div>
    )
  }

  render() {
    const { value, visibleContent, activeColumnCount } = this.state
    const activeColumnIndex = Object.keys(visibleContent)[0]
    const isMultipleColumnActive = activeColumnCount > 1

    // TODO : custom format for grid columns array
    // TODO : reorder items in popup

    // TODO : format:table

    return (
      <div className="d-flex well well-sm">
        <div className="position-sticky align-self-start pe-3 mb-2 columnSectionHeader">
          <div className="overflow-auto columnHeaders">
            {_.map(value, (item, index) => this.renderHeader(index, item))}
          </div>
          {this.renderMainButtons()}
        </div>
        <div className="w-75 columnSectionContainer">
          {isMultipleColumnActive && (
            <div className="position-sticky text-center fs-5 fw-bold p-2 rounded-1 multiple-edit-title">
              Multiple Selection
              <span className="d-block fw-normal multiple-edit-info">
                Only modified properties will be affected
              </span>
            </div>
          )}
          {this.renderContent(activeColumnIndex, value[activeColumnIndex])}
        </div>
      </div>
    )
  }
}
