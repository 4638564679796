import React, { Component } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import jquery from 'jquery'
import jwtDecode from 'jwt-decode'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { TimelineChart, Language } from './components/index'
import { isSuccess } from '@/crudoptV3'
import { __RowIndex } from '@/store/slices/localData'
import { slvyToast } from '@/components'
import { API_URL } from '@/constants'

class Timeline extends Component {
  constructor(props) {
    super(props)
    this.handleTaskChanged = this.handleTaskChanged.bind(this)
    this.handleTaskDeleted = this.handleTaskDeleted.bind(this)
    this.handleTaskInserted = this.handleTaskInserted.bind(this)
    this.handleAdded = this.handleAdded.bind(this)
    this.state = {
      cnt: 0,
      currentLanguage: {
        displayLanguage: 'en',
        localizationCulture: 'en'
      }
    }
    this.result = null
    this.getLanguage = this.getLanguage.bind(this)
  }

  getLanguage() {
    const session = jwtDecode(this.props.token)
    const [displayLanguage, localizationCulture] = session.culture.toLowerCase().split('-')

    this.setState({
      currentLanguage: {
        displayLanguage,
        localizationCulture
      }
    })
  }

  createPeriodNodes(periodDict, parentId = 0) {
    const nodes = []

    if (_.has(periodDict, parentId)) {
      nodes.push(...periodDict[parentId])
    }
    _.forEach(nodes, (node) => {
      node.nodes = this.createPeriodNodes(periodDict, node.id)
    })

    return nodes
  }

  preparePeriodData(pluginData, periodFields, basePeriodField, idField) {
    const nonBasePeriods = _.filter(periodFields, (periodName) => {
      return periodName !== basePeriodField
    })
    const periodRelationDict = _.transform(
      nonBasePeriods,
      (res, periodName) => {
        const periods = _.map(_.uniqBy(pluginData, periodName), (row) => {
          return row[periodName]
        })
        const periodRelations = _.transform(
          periods,
          (res, period) => {
            const basePeriodCount = _.reduce(
              pluginData,
              (res, row) => {
                if (row[periodName] === period) {
                  res++
                }
                return res
              },
              0
            )
            res.push({ name: period, count: basePeriodCount })
          },
          []
        )
        res[periodName] = periodRelations
      },
      {}
    )

    periodRelationDict[basePeriodField] = _.map(pluginData, (row) => {
      return { name: row[basePeriodField], count: 1 }
    })

    return periodRelationDict
  }

  getBasePeriods(periodData, basePeriod, idField) {
    return _.map(periodData, (row) => {
      return { id: row[idField], period: row[basePeriod] }
    })
  }

  prepareUpdateData(task, newStart, newEnd, oldStart, oldEnd) {
    const { settings: { config: { taskData: { taskStartField, taskEndField } = {} } = {} } = {} } =
      this.props
    const { rawData = {} } = task

    const updateData = {
      updateItems: []
    }

    if (newStart !== oldStart) {
      updateData.updateItems.push({
        columnName: taskStartField,
        config: {
          ...rawData,
          [taskStartField]: newStart,
          [taskEndField]: newEnd
        },
        oldValue: oldStart
      })
    }

    if (newEnd !== oldEnd) {
      updateData.updateItems.push({
        columnName: taskEndField,
        config: {
          ...rawData,
          [taskEndField]: newEnd,
          [taskStartField]: newStart
        },
        oldValue: oldEnd
      })
    }
    return updateData
  }

  handleTaskChanged(task, newStart, newEnd, oldStart, oldEnd) {
    const updateData = this.prepareUpdateData(task, newStart, newEnd, oldStart, oldEnd)
    const rowIndex = task.rawData[__RowIndex]
    const newTask = updateData.updateItems[0].config
    this.updateTask(updateData, rowIndex, newTask)
  }

  handleTaskDeleted(task) {
    const taskData = {
      type: 2, // 2 for deleting
      records: [task.rawData]
    }

    const rowIndex = task.rawData[__RowIndex]

    this.deleteTask(taskData, rowIndex)
  }

  handleTaskSelected(task) {
    const { settings: { query: { fields: fieldConfigs = [] } = {} } = {} } = this.props

    const { rawData = {} } = task

    const res = _.transform(
      fieldConfigs,
      (result, field) => {
        result[field.fieldName] = rawData[field.fieldName]
      },
      {
        refreshKey: uuidv4()
      }
    )
    return res
  }

  handleTaskInserted(raw) {
    const {
      settings: {
        config: {
          taskData: { taskRowIdField, taskLabelField, taskStartField, taskEndField } = {}
        } = {}
      } = {}
    } = this.props

    const taskData = {
      type: 1, // 1 for inserting
      records: [
        {
          [taskRowIdField]: raw.rowId,
          [taskLabelField]: '',
          [taskStartField]: raw.start,
          [taskEndField]: raw.end
        }
      ]
    }
    this.insertTask(taskData)

    return this.result
  }

  handleAdded(res) {
    const {
      settings: {
        config: {
          taskData: {
            taskRowIdField,
            taskIdField,
            taskLabelField,
            taskStartField,
            taskEndField
          } = {},
          hierarchyData: { rowIdField, parentRowIdField } = {}
        } = {}
      } = {},
      data: { additionaltables = [] } = {}
    } = this.props
    const treeData = additionaltables[0]

    const result = {
      taskId:
        res[_.find(Object.keys(res), (key) => key.toLowerCase() === taskIdField.toLowerCase())],
      taskLabel:
        res[_.find(Object.keys(res), (key) => key.toLowerCase() === taskLabelField.toLowerCase())],
      start:
        res[_.find(Object.keys(res), (key) => key.toLowerCase() === taskStartField.toLowerCase())],
      end: res[_.find(Object.keys(res), (key) => key.toLowerCase() === taskEndField.toLowerCase())],
      rowId:
        res[_.find(Object.keys(res), (key) => key.toLowerCase() === taskRowIdField.toLowerCase())]
    }
    const associatedNode = _.find(treeData, (tre) => tre[rowIdField] === result.rowId)

    const raw = {
      [taskIdField]: result.taskId,
      [taskRowIdField]: result.rowId,
      [taskStartField]: result.start,
      [taskEndField]: result.end,
      [taskLabelField]: result.taskLabel,
      parent: associatedNode[parentRowIdField]
    }

    result.rawData = raw

    this.result = result
  }

  insertTask(taskData) {
    const {
      actualFilters = {},
      params: { environment }
    } = this.props
    const that = this
    jquery.ajax({
      url: `${API_URL}/data/plugin/${this.props.id}/edit`,
      async: false,
      contentType: 'application/json',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        environment
      },
      data: JSON.stringify({
        filters: actualFilters,
        ...taskData
      }),
      success({ dataset: { table } = {}, result }) {
        const [res] = table || result
        that.handleAdded(res)
      },
      error() {
        slvyToast.warning({ message: 'Task cannot be inserted' })
      }
    })
  }

  updateTask(updateData, rowIndex, task) {
    const {
      actualFilters = {},
      params: { environment }
    } = this.props

    jquery.ajax({
      url: `${API_URL}/data/plugin/${this.props.id}/update`,
      contentType: 'application/json',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        environment
      },
      data: JSON.stringify({
        ...updateData,
        filters: actualFilters
      }),
      success: function () {
        this.props.updateRowInLocalData(rowIndex, task)
      }.bind(this),
      error() {
        slvyToast.warning({ message: 'Task cannot be updated' })
      }
    })
  }

  deleteTask(taskData, rowIndex) {
    const {
      actualFilters = {},
      params: { environment }
    } = this.props
    this.props.deleteRowInLocalData(rowIndex)
    jquery.ajax({
      url: `${API_URL}/data/plugin/${this.props.id}/edit`,
      contentType: 'application/json',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        environment
      },
      data: JSON.stringify({
        ...taskData,
        filters: actualFilters
      }),
      success: function () {
        this.props.deleteRowInLocalData(rowIndex)
      }.bind(this),
      error() {
        slvyToast.warning({ message: 'Task cannot be deleted' })
      }
    })
  }

  handleTaskMoved(task) {
    const {
      pluginData = [],
      settings: { query: { fields } = {}, config: { taskData: { taskIdField } } = {} }
    } = this.props
    if (fields && task !== undefined) {
      const data = _.find(pluginData, { [taskIdField]: task.taskId })
      return _.transform(
        fields,
        (result, field) => {
          result[field.fieldName] = data[field.fieldName]
        },
        {}
      )
    }
    return {}
  }

  componentDidMount() {
    this.getLanguage()
  }

  UNSAFE_componentWillMount() {
    const { settings: { query: { fields: fieldConfigs = [] } = {} } = {} } = this.props
    // Register task selection event
    const cellParams = _.transform(
      fieldConfigs,
      (result, field = {}) => {
        const { dataType = '' } = field
        result[field.fieldName] = PluginTypes.fromString(dataType)
      },
      {}
    )
    const rowSelectedParams = {
      ...cellParams,
      refreshKey: PluginTypes.string
    }
    this.handleTaskSelected = this.props.registerEvent({
      key: 'TaskSelected',
      fn: this.handleTaskSelected.bind(this),
      returnTypes: rowSelectedParams
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      settings: {
        query: { fields: fieldConfigs = [] } = {},
        config: { taskData: { buttons = [] } = {} } = {}
      } = {}
    } = nextProps
    // Register button events
    const cellParams = _.transform(
      fieldConfigs,
      (result, field = {}) => {
        const { dataType = '' } = field
        result[field.fieldName] = PluginTypes.fromString(dataType)
      },
      {}
    )
    const rowSelectedParams = {
      ...cellParams,
      refreshKey: PluginTypes.string
    }

    if (isSuccess(this.props.query, nextProps.query)) {
      // Add row index to grid, we need it while editing
      this.props.addRowIndexToLocalData()
    }
    _.forEach(buttons, (btn, index) => {
      this.handleTaskMoved = this.props.registerEvent({
        key: `btn_${btn.name}`,
        fn: this.handleTaskMoved.bind(this),
        returnTypes: rowSelectedParams
      })
    })
  }

  shouldComponentUpdate(nextProps) {
    const pluginDataReady =
      _.size(nextProps.pluginData) === 0 ||
      (_.size(nextProps.pluginData) > 0 && _.has(nextProps.pluginData[0], __RowIndex))
    return pluginDataReady
  }

  prepareHierarchyAndTaskData(
    treeData,
    taskData,
    workingTypeTextField,
    maxWorkingHourField,
    currentWorkingHourField,
    hierarchyTimeIconField,
    basePeriods,
    rowIdField,
    parentRowIdField,
    taskRowIdField,
    taskIdField,
    treeLabelField,
    taskLabelField,
    taskStartField,
    taskEndField
  ) {
    const translatedTreeData = _.map(treeData, (row) => {
      return {
        id: row[rowIdField],
        parentId: row[parentRowIdField],
        taskId: row[taskIdField],
        treeLabel: row[treeLabelField],
        timeIcon: row[hierarchyTimeIconField],
        maxWorkingHour: row[maxWorkingHourField],
        currentWorkingHour: row[currentWorkingHourField],
        workingType: row[workingTypeTextField],
        taskLabel: row[taskLabelField],
        start: row[taskStartField],
        end: row[taskEndField],
        rawData: row
      }
    })
    const translatedTaskData = _.map(taskData, (row) => {
      return {
        id: row[taskRowIdField],
        taskId: row[taskIdField],
        taskLabel: row[taskLabelField],
        start: row[taskStartField],
        end: row[taskEndField],
        rawData: row
      }
    })
    const treeDict = {}
    _.forEach(translatedTreeData, (row) => {
      if (!treeDict[row.parentId]) {
        treeDict[row.parentId] = []
      }
      treeDict[row.parentId].push(row)
    })

    const taskTree = this.createTaskTree(treeDict, 0)
    this.addTasksToTree(taskTree, translatedTaskData, basePeriods)
    return taskTree
  }

  createTaskTree(treeDict, parentId) {
    if (_.has(treeDict, parentId)) {
      const nodes = _.map(treeDict[parentId], (row) => {
        return {
          id: row.id,
          rowId: row.parentId,
          label: row.treeLabel,
          timeIcon: row.timeIcon,
          workingType: row.workingType,
          maxWorkingHour: row.maxWorkingHour,
          currentWorkingHour: row.currentWorkingHour
        }
      })
      _.forEach(nodes, (node) => {
        const children = this.createTaskTree(treeDict, node.id)
        if (_.size(children) > 0) {
          node.nodes = children
        }
      })
      return nodes
    }
  }

  addTasksToTree(taskTree, translatedTaskData, basePeriods) {
    basePeriods?.forEach((element, idx) => {
      element.order = idx
    })
    _.forEach(taskTree, (node) => {
      if (_.has(node, 'nodes')) {
        this.addTasksToTree(node.nodes, translatedTaskData, basePeriods)
      } else {
        const foundTasks = _.filter(translatedTaskData, (row) => {
          return row.id === node.id
        })
        const tasks = _.transform(
          foundTasks,
          (res, row) => {
            const startPoint = _.findIndex(basePeriods, { order: row.start })
            const endPoint = _.findIndex(basePeriods, { order: row.end })
            if (startPoint >= 0 && endPoint >= 0) {
              res.push({
                taskId: row.taskId,
                taskLabel: row.taskLabel,
                start: row.start,
                end: row.end,
                rowId: row.id,
                rawData: row.rawData
              })
            }
          },
          []
        )
        node.tasks = tasks
      }
    })
  }

  calculateCurrentQuantitiesByPeriod(perids, parent, pluginDataWithParents, rowIdField) {
    const currentArray = []
    if (perids !== undefined) {
      for (let index = 0; index < perids.length; index++) {
        currentArray.push(
          _.size(
            _.filter(
              pluginDataWithParents,
              (plug) =>
                plug.StartIndex <= index &&
                plug.EndIndex >= index &&
                plug.parent === parent[rowIdField]
            )
          )
        )
      }
    }

    return currentArray
  }

  calculateSummary(
    periods,
    periodHierRelation,
    periodRelationIdField,
    hierarchyRelationIdField,
    expectedField,
    basePeriodField,
    taskData,
    treeData,
    rowIdField,
    parentRowIdField
  ) {
    const parents = _.filter(treeData, (tree) => {
      return tree[parentRowIdField] === 0
    })
    const pluginDataWithParents = _.map(taskData, (task) => {
      const taskWithParent = task
      taskWithParent.parent = _.head(
        _.filter(treeData, (node) => {
          return node[rowIdField] === task[rowIdField]
        })
      )[parentRowIdField]
      return taskWithParent
    })

    const currentQuantities = []

    for (let index = 0; index < parents.length; index++) {
      currentQuantities.push({
        parent: parents[index],
        quantities: this.calculateCurrentQuantitiesByPeriod(
          periods,
          parents[index],
          pluginDataWithParents,
          rowIdField
        ),
        expecteds: _.filter(periodHierRelation, (rel) => {
          return rel[hierarchyRelationIdField] === parents[index][rowIdField]
        }).map((item) => {
          return item[expectedField]
        })
      })
    }
    return currentQuantities
  }

  render() {
    const {
      settings: {
        config: {
          taskData: {
            taskRowIdField,
            taskIdField,
            taskLabelField,
            taskStartField,
            taskEndField,
            buttons = []
          } = {},
          hierarchyData: {
            rowIdField,
            parentRowIdField,
            treeLabelField,
            hierarchyTimeIconField,
            maxWorkingHourField,
            currentWorkingHourField,
            workingTypeTextField
          } = {},
          settings: { basePeriodWidth = 30, selectionType = '', isSummaryVisible = true } = {},
          periodData: { periodIdField, periodFields = [], periodsInHeader = [] } = {},
          periodHierarchyRelationData: {
            periodRelationIdField,
            hierarchyRelationIdField,
            expectedField
          } = {}
        } = {}
      } = {},
      pluginData = [],
      data: { additionaltables = [] } = {}
    } = this.props

    const {
      currentLanguage: { displayLanguage }
    } = this.state

    const languageDictionary = Language[displayLanguage]

    const periodHierRelation = additionaltables?.[2]
      ? _.sortBy(additionaltables[2], periodRelationIdField)
      : []

    const taskData = pluginData
    const treeData = additionaltables?.[0] ?? []

    const netWorkingCalculationTable = additionaltables?.[3] ?? []

    const periodData = additionaltables?.[1] ?? []

    const basePeriodField = _.size(periodFields) > 0 ? _.last(periodFields) : null

    const periodDataPrepared =
      _.size(periodFields) > 0 && _.size(periodData) > 0
        ? this.preparePeriodData(periodData, periodFields, basePeriodField)
        : null

    const basePeriods =
      periodIdField !== '' && _.size(periodFields) > 0
        ? this.getBasePeriods(periodData, basePeriodField, periodIdField)
        : null
    const hierDataObj = { ...treeData }
    const taskDataRaw = {
      [taskLabelField]: '',
      [taskStartField]: 0,
      [taskEndField]: 0,
      [taskRowIdField]: 0
    }

    const taskTree = this.prepareHierarchyAndTaskData(
      hierDataObj,
      taskData,
      workingTypeTextField,
      maxWorkingHourField,
      currentWorkingHourField,
      hierarchyTimeIconField,
      basePeriods,
      rowIdField,
      parentRowIdField,
      taskRowIdField,
      taskIdField,
      treeLabelField,
      taskLabelField,
      taskStartField,
      taskEndField
    )
    const summaryData = !isSummaryVisible
      ? null
      : this.calculateSummary(
          periodData,
          periodHierRelation,
          periodRelationIdField,
          hierarchyRelationIdField,
          expectedField,
          basePeriodField,
          taskData,
          treeData,
          rowIdField,
          parentRowIdField
        )

    return (
      <TimelineChart
        basePeriodWidth={basePeriodWidth}
        basePeriods={basePeriods}
        buttons={buttons}
        languageDictionary={languageDictionary}
        netWorkingCalculationTable={netWorkingCalculationTable}
        periodData={periodDataPrepared}
        periods={periodData}
        periodsInHeader={periodsInHeader}
        selectionType={selectionType}
        summaryData={summaryData}
        summaryVisible={isSummaryVisible}
        taskDataRaw={taskDataRaw}
        taskTree={taskTree}
        treeData={treeData}
        onBtnHandled={this.handleTaskMoved}
        onTaskChanged={this.handleTaskChanged}
        onTaskDeleted={this.handleTaskDeleted}
        onTaskInserted={this.handleTaskInserted}
        onTaskSelected={this.handleTaskSelected}
      />
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  settings: props.settings,
  token: props.token,
  data: props.data,
  actualFilters: props.actualFilters,
  params: props.params,
  id: props.id,
  addRowIndexToLocalData: props.addRowIndexToLocalData,
  updateRowInLocalData: props.updateRowInLocalData,
  deleteRowInLocalData: props.deleteRowInLocalData,
  query: props.query
})

export default createPlugin(Timeline, selectConnectorProps)
