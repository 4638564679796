import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

import highchartsTreeMap from 'highcharts/modules/treemap'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsNodataDisplay from 'highcharts/modules/no-data-to-display'

highchartsTreeMap(Highcharts)
highchartsExporting(Highcharts)
highchartsNodataDisplay(Highcharts)

class Treemap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: null,
      subtitle: null
    }

    this.onPointClick = this.onPointClick.bind(this)
    this.handleLevelChanged = this.handleLevelChanged.bind(this)

    this.defaults = {
      credits: {
        enabled: false,
        text: 'Solvoyo © ' + new Date().getFullYear(),
        href: 'http://www.solvoyo.com',
        style: { fontSize: '12px' }
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      },
      pointClick: {
        events: {
          click: this.onPointClick
        }
      }
    }
  }

  handlePointClick(name, value, row) {
    return { ...row, ...{ name, value } }
  }

  setTitle({ title }) {
    if (this.state.title !== title) {
      this.setState({ title })
    }
  }

  setSubtitle({ setSubtitle }) {
    if (this.state.subtitle !== setSubtitle) {
      this.setState({ subtitle: setSubtitle })
    }
  }

  handleLevelChanged(value, initialData, previousValue, schemaHelper) {
    if (!this.levelValue) {
      this.levelValue = schemaHelper.getValueOfSchema(
        this.props.schema.properties.series.properties.levels.items
      )
    }

    if (initialData.series) {
      initialData.series.levels = []
    }
    if (value && value.series && value.series.levels) {
      _.forEach(value.series.levels, (level, index) => {
        if (!initialData.series.levels[index]) {
          initialData.series.levels[index] = {}
          _.assign(initialData.series.levels[index], this.levelValue)
          // New added series is different from its initial value
          level.__Temp = 'tmp'
        }
      })
    }
    return value
  }

  getFirstDataRow() {
    return this.props.pluginData && this.props.pluginData[0]
  }

  getData() {
    if (!_.isEmpty(this.props.pluginData)) {
      if (this.props.settings.config) {
        let series = {}
        if (this.props.settings.config.series) {
          series = _.cloneDeep(this.props.settings.config.series)
        }
        series.data = []

        const {
          config: {
            data: {
              id: idField,
              name: nameField,
              value: valueField,
              color: colorField,
              parent: parentField,
              level: levelField
            } = {},
            series: { name } = {}
          } = {}
        } = this.props.settings || {}

        _.forEach(this.props.pluginData, (row) => {
          const levelVal = row[levelField]
          let parentVal = row[parentField]
          if (levelVal === 0) {
            parentVal = null
          }
          series.data.push({
            id: row[idField] ? row[idField].toString() : row[idField],
            name: row[nameField],
            value: row[valueField],
            color: row[colorField],
            parent: parentVal ? parentVal.toString() : parentVal,
            row
          })
        })
        series.name = name ? this.replaceTemplate(name, this.getFirstDataRow()) : 'Series 1'
        return series
      }
    }
  }

  onPointClick(event) {
    this.handlePointClick(event.point.name, event.point.value, event.point.row)
  }

  componentDidMount() {
    const that = this
    const {
      settings: {
        query: { fields = [] } = {},
        config: { data: { id: idField, name: nameField, value: valueField } = {} } = {}
      } = {},
      id
    } = this.props || {}

    this.handlePointClick = this.props.registerEvent({
      key: 'handlePointClick',
      fn: this.handlePointClick.bind(this),
      returnTypes: {
        ..._.transform(
          fields,
          (result, field = {}) => {
            const { dataType = '' } = field
            result[field.fieldName] = PluginTypes.fromString(dataType)
          },
          {}
        ),
        ...{ name: PluginTypes.string, value: PluginTypes.int }
      }
    })

    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setSubtitle',
      fn: this.setSubtitle.bind(this),
      args: [{ name: 'setSubtitle', type: PluginTypes.string }]
    })

    // Workaround: Highcharts treemap does not have a drillup event
    // Therefore we register to the prototype event
    Highcharts.wrap(Highcharts.seriesTypes.treemap.prototype, 'drillUp', function (proceed) {
      const { chart: { options: { pluginId } = {} } = {} } = this || {}

      proceed.apply(this, [].slice.call(arguments, 1))

      // Since we use prototype event if there are multiple treemaps on the page
      // the drillup is triggered for all treemaps separately
      // To prevent this we check with the plugin id
      if (pluginId === id) {
        const relatedRow = _.find(that.props.pluginData, (row) => {
          return (
            _.has(row, idField) &&
            !_.isNil(row[idField]) &&
            !_.isNil(this.rootNode) &&
            row[idField].toString() === this.rootNode.toString()
          )
        })
        if (relatedRow && _.has(relatedRow, nameField) && _.has(relatedRow, valueField)) {
          that.handlePointClick(relatedRow[nameField], relatedRow[valueField], relatedRow)
        } else {
          // At the top level trigger event with empty parameters
          const emptyRow = _.transform(
            fields,
            (result, field = {}) => {
              result[field.fieldName] = null
            },
            {}
          )
          that.handlePointClick(null, null, emptyRow)
        }
      }
    })

    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [this.handleLevelChanged],
        diffMode: true
      })
    }
  }

  drillUp(proceed) {}
  unescapeUnicode(str) {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function (g, m1) {
      return String.fromCharCode(parseInt(m1, 16))
    })
  }

  replaceTemplate(text, data) {
    const regExp = /\{([^}]+)\}/g

    const matches = text.match(regExp)

    if (!matches) return text

    for (let i = 0; i < matches.length; i++) {
      const variableName = matches[i].substr(1, matches[i].length - 2)

      if (_.has(data, variableName)) {
        const value = data[variableName]

        if (value) {
          text = text.replace(matches[i], value)
        } else {
          text = text.replace(matches[i], '')
        }
      } else {
        text = text.replace(matches[i], '')
      }
    }

    return text
  }
  formatTooltip(tooltipFormat, row, formattedValue) {
    const { getFormattedValue } = this.props
    let tooltip = `<span style="font-size: 120%">${formattedValue}</span><br/>`

    tooltip = this.unescapeUnicode(tooltipFormat)
    if (row) {
      const formattedRow = _.transform(
        row,
        (res, value, key) => {
          res[key] = getFormattedValue(key, value, null, row)
        },
        {}
      )
      tooltip = this.replaceTemplate(tooltip, formattedRow)
    }

    return tooltip
  }

  tooltipFormatter(point) {
    const {
      settings: {
        config: {
          data: { value: valueField = null } = {},
          tooltip: { tooltipFormat = null } = {}
        } = {}
      } = {},
      getFormattedValue
    } = this.props
    const { point: { name: pointName = null, value: pointValue = null, row } = {} } = point
    const formattedValue = getFormattedValue(valueField, pointValue, null, row)
    if (tooltipFormat) {
      return this.formatTooltip(tooltipFormat, row, formattedValue)
    } else {
      return `<b>${pointName}</b>: ${formattedValue}<br/>`
    }
  }

  render() {
    let config = {}
    if (this.props.settings && this.props.settings.config) {
      config = _.cloneDeep(this.props.settings.config)

      const series = this.getData()
      if (series) {
        // set fixed type
        series.type = 'treemap'

        // add click handlers
        series.point = this.defaults.pointClick

        config.series = []
        config.series.push(series)
      }

      if (_.isEmpty(config.series?.levels)) {
        _.set(config, 'series.levels', undefined)
      }
    }
    const that = this
    if (_.isNil(config.tooltip)) {
      config.tooltip = {}
    }
    config.tooltip.formatter = function () {
      return that.tooltipFormatter(this)
    }

    // override title
    if (config?.title?.text) {
      const row = _.first(that.props?.pluginData) || {}

      if (!_.isEmpty(row)) {
        config.title.text = config.title.text.replace(
          /\{([^}]+)\}/g,
          (str, column) => row[column] || str
        )
      }
    }

    if (this.state.title) {
      if (!config.title) {
        config.title = {}
      }
      config.title.text = this.state.title
    }

    // override subtitle
    if (this.state.subtitle) {
      if (!config.subtitle) {
        config.subtitle = {}
      }
      config.subtitle.text = this.state.subtitle
    }

    // if the plugin is maximized apply max style for title
    if (this.props.isMaximized && config.title && config.title.maxStyle) {
      config.title.style = config.title.maxStyle
    }
    const {
      chart: { showCredits = false, emptyText = 'No Data to Display' } = {},
      exporting: { enabled: exportingEnabled = false } = {}
    } = config

    // disable credits
    config.credits = this.defaults.credits
    config.credits.enabled = showCredits

    // display no data
    config.noData = this.defaults.noData

    // set empty text
    config.lang = {
      noData: emptyText || ' '
    }

    // move export button to the top-left corner
    if (!config.exporting) {
      config.exporting = {}
    }
    config.exporting.enabled = exportingEnabled
    config.exporting.buttons = {
      contextButton: {
        align: 'left'
      }
    }

    config.pluginId = this.props.id

    if (config.series && config.series[0]) {
      // set turbo threshhold to infinite
      config.series[0].turboThreshold = 0

      if (!_.has(config.series[0].rootId)) {
        config.series[0].rootId = ''
      }
    }

    // remove non highchart properties
    delete config.data
    delete config.general

    config.series.length &&
      _.forEach(config.series, (item) => {
        if (_.isObject(item)) {
          item.levels = [
            {
              level: 1,
              dataLabels: {
                enabled: true
              },
              borderWidth: 1
            }
          ]
        }
      })

    return (
      <div className="highcharts-container">
        <HighchartsReact highcharts={Highcharts} options={config} allowChartUpdate immutable />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  schema: props.schema,
  onReady: props.onReady,
  isMaximized: props.isMaximized,
  id: props.id,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(Treemap, selectConnectorProps)
