import { useCallback, useEffect, useRef } from 'react'
import { transform, uniq } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { tuiSlice } from '../../../store/slices'
import { collectNodes } from '@/plugins/Tui/utils'

function useDemandRowSelected({ demandTreeTableRef }) {
  const demandRowRef = useRef<
    Record<string, (rowIds: Record<string, unknown>[]) => void | undefined>
  >({})
  const [{ registerEvent }] = useReduxState(tuiSlice.selectSlice)
  const [{ config, getFieldType }] = useReduxState(tuiSlice.selectSlice)

  const demandRowSelected = useCallback(
    (selectedRows) => {
      const { flatRows } = demandTreeTableRef.current.getRowModel()
      const leafSelectedRows = uniq(collectNodes({ Children: selectedRows }, (node) => node?.Leaf))

      return config.demandTree.columns.reduce((acc, field) => {
        const fieldValues = leafSelectedRows.reduce((fields, leafSelectedRow) => {
          const matchingRows = flatRows.filter((row) => {
            return row?.original.ID === leafSelectedRow.ID && row.original?.Leaf
          })
          matchingRows.forEach((matchingRow) => {
            if (matchingRow.getIsSelected()) {
              fields.push(leafSelectedRow[field.dataIndex])
            }
          })
          return fields
        }, [])
        // eslint-disable-next-line no-param-reassign
        acc[field.dataIndex] = fieldValues.length > 0 ? fieldValues : null
        return acc
      }, {})
    },
    [config.demandTree.columns, demandTreeTableRef]
  )

  useEffect(() => {
    const demandRowSelectedParams = {}

    config.demandTree.columns.forEach((item) => {
      demandRowSelectedParams[item.dataIndex] = PluginTypes.fromString(item.dataType)
    })

    const fn = 'handleDemandRowSelected'
    demandRowRef.current[fn] = registerEvent({
      key: 'DemandRowSelected',
      fn: demandRowSelected,
      returnTypes: transform(
        demandRowSelectedParams,
        (result, _value, key) => {
          // eslint-disable-next-line no-param-reassign
          result[key] = getFieldType(key, true)
        },
        {}
      )
    })
  }, [config.demandTree.columns, demandRowSelected, getFieldType, registerEvent])

  return { demandRowRef }
}

export default useDemandRowSelected
