import { useState, cloneElement, ReactElement } from 'react'
import { IStateToProps } from './StateToProps.types'

const StateToProps = (props: IStateToProps): null | ReactElement => {
  const { children, ...rest } = props
  const [state, setState] = useState({})

  if (children === null || typeof children === 'undefined') {
    return null
  }

  const setProps = (newState: object) =>
    setState((prevState: any) => ({ ...prevState, ...newState }))

  return cloneElement(children as ReactElement, {
    ...rest,
    ...state,
    setProps
  })
}

export default StateToProps
