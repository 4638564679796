import cx from 'classnames'

export const columns = [
  {
    header: '',
    accessorKey: 'FieldName',
    minSize: 250,
    meta: {
      style: {
        textWrap: 'wrap'
      }
    }
  },
  {
    header: 'Before',
    accessorKey: 'OldValue'
  },
  {
    header: 'After',
    accessorKey: 'NewValue'
  },
  {
    header: 'Change',
    accessorKey: 'Change',
    cell: ({ cell }) => {
      const value = cell.getValue()
      const isNegative = value[0] === '-'
      const isNeutral = value[0] === '0'

      return (
        <span>
          <i
            className={cx('slvy-ui-icon-circle-fill', {
              'text-success': isNegative && !isNeutral,
              'text-danger': !isNegative && !isNeutral,
              'text-secondary': isNeutral
            })}
          />{' '}
          {value}
        </span>
      )
    }
  }
]

export const valueFormat = '0,000.[00]'
