import { Row } from 'react-bootstrap'
import { slvyToast } from '@/components'
import BaseModal from '../../BaseModal'
import { useReduxState } from '../../../hooks'
import { loadTreeTableSlice, tuiSlice } from '../../../store/slices'
import { getDemandTree, getLoadTree, getRemoveLoadStop } from '../../../store/api/endpoints'
import { getTreeQueryParams } from '../../../utils'
import styles from './RemoveLoadStopModal.module.scss'
import CommonModalFields from '../CommonModalFields'

function RemoveLoadStopModal({ selectedLoadStopRow }) {
  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)
  const [{ isRemoveLoadStopModalOpen, selectedReasonCodeId }, { setIsRemoveLoadStopModalOpen }] =
    useReduxState(loadTreeTableSlice.selectSlice, loadTreeTableSlice.actions)
  const [triggerGetRemoveLoadStop] = getRemoveLoadStop.useLazyQuery()
  const [triggerGetDemandTree] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })
  const [triggerGetLoadTree] = getLoadTree.useMutation({
    fixedCacheKey: 'load-tree'
  })

  function handleClickCloseModal() {
    setIsRemoveLoadStopModalOpen(false)
  }

  function handleClickConfirmButton() {
    const loadStopIndex = Array.isArray(selectedLoadStopRow.index)
      ? selectedLoadStopRow.index
      : [selectedLoadStopRow.index]
    const loadId = Number(selectedLoadStopRow.original.ID)
    const reasonCodeId = Number(selectedReasonCodeId)

    triggerGetRemoveLoadStop({ loadStopIndex, loadId, reasonCodeId }).then((response) => {
      if (response?.data?.success) {
        triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
        triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })

        slvyToast.success({
          title: 'Information',
          message: 'Operation is completed successfully.'
        })
      } else if (!response?.data?.success) {
        slvyToast.warning({
          title: 'Error',
          message: 'Operation failed.'
        })
      }
    })
    handleClickCloseModal()
  }

  return (
    <BaseModal isOpen={isRemoveLoadStopModalOpen} onClose={handleClickCloseModal}>
      <BaseModal.Header>
        <BaseModal.Title>Confirm: Remove Load Stop</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className={styles.removeLoadStopModal}>
        <CommonModalFields />
        <Row>
          <p className={styles.removeLoadStopModalDescription}>
            Are you sure you want to unserve demand zone?
          </p>
        </Row>
      </BaseModal.Body>
      <BaseModal.Footer>
        <BaseModal.CancelButton onClick={handleClickCloseModal} />
        <BaseModal.ConfirmButton onClick={handleClickConfirmButton} />
      </BaseModal.Footer>
    </BaseModal>
  )
}

export default RemoveLoadStopModal
