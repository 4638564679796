// move to the global
require('./../../../extjs/overrides/Calendar/Event')('Assortment')
require('./../../../extjs/overrides/data/store.assortment')
require('./../../../extjs/overrides/data/store/reader')
require('./../../../extjs/overrides/clipboard')
require('./../../../extjs/overrides/ViewModel')('Assortment')
require('./overrides/Columns')
require('./overrides/Lockable')

// HIGHCHART
require('./../../../extjs/src/Chart/ux/require.js')

// already closed
// require('./src/Chart/ux/Highcharts/HeatmapSerie')

require('./src/Global')

require('./src/model/Base')
require('./src/model/BaseTree')
require('./src/model/CheckedRecordsModel')
require('./src/model/DcModel')
require('./src/model/FutureOrderModel')
require('./src/model/GroupByModel')
require('./src/model/InventoryModel')
require('./src/model/K1KModel')
require('./src/model/K2KModel')
require('./src/model/K3KModel')
require('./src/model/LocalizationModel')
require('./src/model/OpenOrderModel')
require('./src/model/OpenSasModel')
require('./src/model/OpenAllSasModel')
require('./src/model/OrderRecommendation')
require('./src/model/OrderRecommendationGroup')
require('./src/model/ProductCodeModel')
require('./src/model/PromotionDetailModel')
require('./src/model/ReasonModel')
require('./src/model/RoundUpModel')
require('./src/model/StoreDetailModel')
require('./src/model/SubstituteModel')
require('./src/model/ZeroInventoryModel')
require('./src/model/BlockagedStoreModel')
require('./src/MainViewModel')

require('./src/cmp/ComboWithClear')
require('./src/cmp/EditorView')
require('./src/cmp/FutureOrderGrid')
require('./src/cmp/GroupChart')
require('./src/cmp/GroupGrid')
require('./src/cmp/InventoryChart')
require('./src/cmp/InventoryGrid')
require('./src/cmp/MassUpdateGrid')
require('./src/cmp/OpenOrderStore')
require('./src/cmp/OpenSas')
require('./src/cmp/OpenAllSas')
require('./src/cmp/PromotionsDetailsGrid')
require('./src/cmp/RoundUp')
require('./src/cmp/RunButton')
require('./src/cmp/SapRecordsGrid')
require('./src/cmp/StoreDetail')
require('./src/cmp/SubstituteGrid')
require('./src/cmp/SubstituteTmp')
require('./src/cmp/SummaryTip')
require('./src/cmp/ZeroInventoryGrid')
require('./src/cmp/BlockagedStore')
require('./src/Toolbar')
require('./src/MainGrid')

require('./src/store/CheckedRecordsStore')
require('./src/store/DcStore')
require('./src/store/FutureOrderStore')
require('./src/store/GroupByStore')
require('./src/store/InventoryStore')
require('./src/store/K1kStore')
require('./src/store/K2kStore')
require('./src/store/K3kStore')
require('./src/store/LocalizationStore')
require('./src/store/OpenOrderStore')
require('./src/store/OpenSasStore')
require('./src/store/OpenAllSasStore')
require('./src/store/OrderRecommendationGroupStore')
require('./src/store/OrderRecommendationStore')
require('./src/store/ProcudtCodeStore')
require('./src/store/PromotionDetailStore')
require('./src/store/ReasonStore')
require('./src/store/RoundUpStore')
require('./src/store/StoreDetailStore')
require('./src/store/SubstituteStore')
require('./src/store/ZeroInventoryStore')
require('./src/store/BlockagedStore')

require('./src/Columns')

require('./src/MainController')

require('./src/MainView')
