import { getCustomColumnsRowDisplayRules } from '../index'
import { rowDisplayRules } from '../../__tests__/mocks'
import { TrowDisplayRules } from '../../../types'

describe('AgGrid:helpers:getCustomColumnsRowDisplayRules', () => {
  it('should returns an empty array when input is an empty array', () => {
    const result = getCustomColumnsRowDisplayRules([])

    expect(result).toHaveLength(0)
  })

  it('should filters and maps row display rules correctly', () => {
    const result = getCustomColumnsRowDisplayRules(rowDisplayRules)

    expect(result).toHaveLength(2)
    expect(result[0]).toEqual({
      compareToField: '',
      operator: 'equal',
      backColor: 'rgba(254,211,48,1)',
      ruleFieldName: 'col4',
      value: '1'
    })
    expect(result[1]).toEqual({
      compareToField: '',
      operator: 'equal',
      backColor: 'red',
      ruleFieldName: 'col3',
      value: '14'
    })
  })

  it('should ignores display rules without backColor property', () => {
    const newRowDisplayRules: TrowDisplayRules = [
      { operator: '===', ruleFieldName: 'status', value: 'Pending' },
      { backColor: '#00FF00', operator: '!==', ruleFieldName: 'priority', value: 'Low' }
    ]

    const result = getCustomColumnsRowDisplayRules(newRowDisplayRules)

    expect(result).toHaveLength(1)
    expect(result[0]).toEqual({
      compareToField: undefined,
      backColor: '#00FF00',
      operator: '!==',
      ruleFieldName: 'priority',
      value: 'Low'
    })
  })

  it('should handles undefined or null values in display rules', () => {
    const newRowDisplayRules: TrowDisplayRules = [
      { backColor: '#FF0000', operator: '===', ruleFieldName: 'status', value: undefined },
      { backColor: null, operator: '!==', ruleFieldName: 'priority', value: 'Low' }
    ]

    const result = getCustomColumnsRowDisplayRules(newRowDisplayRules)

    expect(result).toHaveLength(1)
    expect(result[0]).toEqual({
      compareToField: undefined,
      operator: '===',
      backColor: '#FF0000',
      ruleFieldName: 'status',
      value: undefined
    })
  })
})
