import { Component } from 'react'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

/* Crud */
import { AsyncConnect, select, selectCollection } from '../../../../crudoptV3'
import { getPlugins } from '../../../../actions/plugin'
import { getPage } from '../../../../actions/page'
import { getContainers } from '../../../../actions/container'
import { getCatalog } from '../../../../actions/catalog'
import { getMenu } from '../../../../actions/menu'
import { runRemoteAction } from '../../../../actions/remoteActions'
import { runLongRunningAction } from '../../../../actions/longRunningActions'
import { SET_STORE_RESET } from '@/libs/relation'

import pubsub from '../../../../subs'

import { View } from '../../../../elements'
import { clear } from '../../../../store/slices/pluginlist'
import { setHelpPdfUrl } from '../../../../store/slices/pageConfig'
import { PAGE_READY, REMOTE_ACTION_COMPLETED } from '../../../../libs/workflow'
import GAContainer from '../../../../helpers/GAContainer'

class PageContent extends Component {
  constructor(props) {
    super(props)
    this.showPropertyEditor = this.showPropertyEditor.bind(this)
    this.handlePluginMounted = this.handlePluginMounted.bind(this)
    this.handleResize = this.handleResize.bind(this)

    this.state = {
      containerId: 0,
      showPropertyEditor: false,
      activeTimeCount: 0,
      passiveTimeCount: 0
    }
    this.increaseActiveTime = 0
    this.increasePassiveTime = 0
    this.connection = null
  }

  handleResize() {
    this.forceUpdate()
  }

  showPropertyEditor() {
    this.setState({ showPropertyEditor: !this.state.showPropertyEditor })
  }

  UNSAFE_componentWillMount() {
    const {
      data: { name: pageName, helpUrl }
    } = this.props.page
    const { data: { item: { name: menuName } = {} } = {} } = this.props.menu
    const { data: { name: catalogName } = {} } = this.props.catalog
    const pageTitle = `${catalogName} |-> ${menuName} |-> ${pageName}`
    const { userName, setHelpPdfUrl } = this.props
    const {
      data: { relations, workflow }
    } = this.props.page
    const { data: { id: catalogId, transferObject = {}, remoteActions = [] } = {} } =
      this.props.catalog || {}

    setHelpPdfUrl(helpUrl)

    this.props.dispatch({
      type: 'START_WORKFLOW_SYNC',
      workflow,
      payload: {
        pageId: this.props.params.pageId,
        variables: _.keys(transferObject),
        params: this.props.params,
        relations,
        registers: _.transform(
          this.props.plugins.data,
          (result, { id, registers }) => {
            result[id] = registers
          },
          {}
        )
      }
    })

    _.each(remoteActions, (remoteAction) => {
      pubsub.subscribe(
        `runRemoteAction_${remoteAction.name}`,
        (params) => this.props.runRemoteAction(catalogId, remoteAction.name, params),
        { type: 'method' }
      )
      pubsub.subscribe(
        `runLongRunningAction_${remoteAction.name}`,
        (params) => this.props.runLongRunningAction(catalogId, remoteAction.name, params),
        { type: 'method' }
      )
    })

    this.controlEvent(false)
    this.controlEvent(true)
  }

  messagingReceiveMessage = ({ detail: res = {} }) => {
    const { page: { data: { id: pageId = '' } = {} } = {} } = this.props

    this.props.dispatch({
      type: REMOTE_ACTION_COMPLETED,
      payload: { pageId, res }
    })
  }

  controlEvent = (isAdd) =>
    window[isAdd ? 'addEventListener' : 'removeEventListener'](
      'MessagingReceiveMessage',
      this.messagingReceiveMessage
    )

  componentWillUnmount() {
    this.props.dispatch({
      type: 'START_WORKFLOW_SYNC_NOT',
      payload: { pageId: this.props.params.pageId }
    })
    this.props.dispatch({
      type: SET_STORE_RESET,
      storeIndex: Number(this.props.params?.storeIndex)
    })

    this.connection = null

    this.controlEvent(false)
  }

  findChildrenInLayout(containerId, layout = {}) {
    const { id, type, children = [] } = layout
    if (containerId === id && type === 'container') {
      return children
    }
    let pool = false
    _.each(children, (l) => {
      const find = this.findChildrenInLayout(containerId, l)
      if (find) {
        pool = find
      }
    })
    return pool
  }

  backwardCompatibility(containers, layout) {
    return _.map(containers, (container) => {
      const find = this.findChildrenInLayout(container.id, layout)
      if (find) {
        const y = _.map(find, ({ id, type, grid }) => ({
          i: id,
          type,
          ...grid
        }))
        return {
          ...container,
          settings: { ...container.settings, layout: y }
        }
      }
      return container
    })
  }

  handlePluginMounted() {
    setTimeout(() => {
      this.props.dispatch({
        type: PAGE_READY,
        payload: { pageId: this.props.params.pageId }
      })
    }, 0)
  }

  render() {
    const {
      containers: { data: containers },
      page: { data: { layout = {}, name: pageName } = {} },
      params,
      plugins: { data: plugins = [] },
      menu: {
        data: {
          item: { name: menuName }
        }
      },
      catalog: {
        data: { name: catalogName, customerName }
      }
    } = this.props

    const { environment = 'Live' } = params || {}

    const { id = '', type } = layout
    const pageTitle = `${catalogName} |-> ${menuName} |-> ${pageName}`

    const gAContainerProps = {
      MenuName: menuName,
      CustomerName: customerName,
      PageTitle: pageTitle,
      PageName: pageName,
      CatalogName: catalogName,
      Environment: environment
    }

    return (
      <div>
        <View
          first
          canDelete={false}
          canMove={false}
          containers={this.backwardCompatibility(containers, layout)}
          id={id}
          params={params}
          plugins={plugins}
          type={type}
          onMounted={this.handlePluginMounted}
        />
        <GAContainer {...gAContainerProps} />
      </div>
    )
  }
}

export default AsyncConnect(
  {
    catalog: (state, ownProps) => select(getCatalog(ownProps.params.catalogId), state.model3),
    menu: (state, ownProps) =>
      select(getMenu(ownProps.params.catalogId, ownProps.params.menuId), state.model3),
    containers: (state, ownProps) =>
      selectCollection(
        getContainers(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
        state.model3
      ),
    plugins: (state, ownProps) =>
      selectCollection(
        getPlugins(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
        state.model3
      ),
    page: (state, ownProps) =>
      select(
        getPage(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
        state.model3
      )
  },
  {},
  (dispatch) => ({
    dispatch,
    clear: bindActionCreators(clear, dispatch),
    runRemoteAction: bindActionCreators(runRemoteAction, dispatch),
    runLongRunningAction: bindActionCreators(runLongRunningAction, dispatch),
    setHelpPdfUrl: bindActionCreators(setHelpPdfUrl, dispatch)
  })
)(PageContent)
