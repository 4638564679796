Ext.define('tuiv2.cmp.LoadSummaryTmp', {
  extend: 'Ext.Component',
  xtype: 'tuiv2-loadSummaryTmp',
  draggable: true,
  listeners: {
    click: {
      fn: function (event, tile) {
        var me = this.component,
          view = me.rootView,
          viewModel = view.getViewModel(),
          controller = view.getController(),
          data = me.getData(),
          loadId = data.Id,
          demandIds = data.LoadStops.map(function (item) {
            return item.Id
          }),
          openedLoadCards = viewModel.get('openedLoadCards'),
          drawRoute = viewModel.get('drawRoute')

        if (controller.popups[me.popupId]) {
          controller.popups[me.popupId].bringToFront()
        }

        if (event.target.classList.contains('closeAllCard')) {
          var mainMap = me.rootView.down('mainmap'),
            map = mainMap.getMap()
          if (controller.popups[me.popupId]) {
            map.closePopup(controller.popups[me.popupId])
            delete controller.popups[me.popupId]
          } else {
            me.destroy()
          }

          if (me.loadToList) {
            controller.removeOptionCard(me.loadToList)
            me.loadToList.destroy()
          }
          return
        }

        if (event.target.classList.contains('changeView')) {
          data.viewIndex = data.viewIndex == 1 ? 0 : 1
          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('removeLoad')) {
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to remove load?',
            function (reasonCode) {
              controller.onRemoveLoad(reasonCode, loadId, function (result) {
                me.toastResult(result)
                if (result.IsSuccess) {
                  controller.reloadLoadTreeStore()
                  controller.reloadDemandTreeStore()
                  controller.refreshRelatedPopup(demandIds, [])
                }
              })
            },
            this
          )

          return
        }

        // transportation modes
        if (event.target.classList.contains('transportationModeOptions')) {
          controller.onGetAvailableTransportationModes(data.Id, function (result) {
            data.TransportationModeOptions = result

            if (data.TransportationModeOptions.length == 0) {
              Ext.toast({
                html: 'There is no option to change transportation mode.',
                title: 'Information',
                width: 300,
                align: 'tr',
                iconCls: 'fa fa-check',
                closable: true,
                timeout: 4000
              })
              return
            }

            data.viewIndex = 2
            me.dataSet(data)
            return
          })
        }

        if (
          event.target.classList.contains('setTransportationMode') ||
          event.target.parentNode.classList.contains('setTransportationMode') ||
          event.target.classList.contains('setTransportationModeIn')
        ) {
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to change transportation mode?',
            function (reasonCode) {
              var name =
                event.target.nodeName == 'P'
                  ? event.target.innerText
                  : event.target.children[0].innerText

              controller.onChangeTransportationMode(reasonCode, loadId, name, function (result) {
                me.toastResult(result)
                if (result.IsSuccess) {
                  controller.reloadLoadTreeStore()
                  controller.refreshRelatedPopup([], [loadId])
                }
              })
            },
            this
          )

          return
        }

        if (
          event.target.classList.contains('cancelTransportationModeOption') ||
          event.target.parentNode.classList.contains('cancelTransportationModeOption')
        ) {
          data.viewIndex = 1
          me.dataSet(data)
          return
        }

        // merge

        if (event.target.classList.contains('mergeOptions')) {
          var loadIds = []
          for (var property in controller.popups) {
            if (controller.popups.hasOwnProperty(property) && property.startsWith('load')) {
              var newLoadId = property.replace('load', '')

              if (loadId != newLoadId) loadIds.push(newLoadId)
            }
          }

          data.LoadOptions = loadIds.map(function (item) {
            return {
              Id: item
            }
          })

          if (data.LoadOptions.length == 0) {
            Ext.toast({
              html: 'There is no option to merge. Please open a load card.',
              title: 'Information',
              width: 300,
              align: 'tr',
              iconCls: 'fa fa-check',
              closable: true,
              timeout: 4000
            })
            return
          }
          data.DisplayLoadOptions = true
          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('mergeLoad')) {
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to merge loads?',
            function (reasonCode) {
              var loadId2 = event.target.getAttribute('loadId')

              controller.onMergeLoads(reasonCode, loadId, loadId2, function (result) {
                me.toastResult(result)
                if (result.IsSuccess) {
                  controller.reloadLoadTreeStore()
                  // TODO : what about load2 popups.
                  controller.refreshRelatedPopup(demandIds, [loadId, loadId2])
                }
              })
            },
            this
          )

          data.DisplayLoadOptions = false
          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('closeMergeOptions')) {
          data.DisplayLoadOptions = false
          me.dataSet(data)
          return
        }

        if (
          event.target.classList.contains('planFacility') ||
          event.target.classList.contains('planPallet')
        ) {
          var facilityKey = event.target.getAttribute('FacilityKey'),
            loadIndex = event.target.getAttribute('index'),
            loadId = data.Id,
            fromLoadId = event.target.getAttribute('FromLoadId'),
            palletId = event.target.getAttribute('palletId'),
            model = null,
            loadIds = []
          for (var property in controller.popups) {
            if (controller.popups.hasOwnProperty(property) && property.startsWith('load')) {
              loadIds.push(property.replace('load', ''))
            }
          }

          data.LoadIds = loadIds.map(function (item) {
            return item
          })

          data.loadIds = _.uniq(data.loadIds)

          if (event.target.classList.contains('planFacility')) {
            if (!me.selectedFacilityKeys) me.selectedFacilityKeys = []
            if (!me.selectedLoadIndexes) me.selectedLoadIndexes = []

            let index = me.selectedFacilityKeys.indexOf(facilityKey)
            if (index > -1) {
              event.target.classList.remove('-selected')
              me.selectedFacilityKeys.splice(index, 1)
            } else {
              me.selectedFacilityKeys.push(facilityKey)
              event.target.classList.add('-selected')
            }

            let lIndex = me.selectedLoadIndexes.indexOf(loadIndex)
            if (index > -1) {
              me.selectedLoadIndexes.splice(lIndex, 1)
            } else {
              me.selectedLoadIndexes.push(loadIndex)
            }
            var totalVolume = 0,
              totalWeight = 0,
              totalNumPallet = 0
            me.selectedFacilityKeys.forEach((key) => {
              var selectedLoad = data.LoadStops.find(function (load) {
                return load.Id == key
              })
              selectedLoad.Items.forEach((item) => {
                if (item.LoadId == loadId) {
                  totalVolume = totalVolume + item.Volume
                  totalWeight = totalWeight + item.Weight
                  totalNumPallet = totalNumPallet + item.NumPallet
                }
              })
            })
            me.selectedVolume = totalVolume
            me.selectedWeight = totalWeight
            me.selectedPallet = totalNumPallet

            model = {
              class: 'dot',
              Id: facilityKey,
              ltlLoadId: loadId,
              SelectedVolume: me.formatNumber(me.selectedVolume),
              SelectedWeight: me.formatNumber(me.selectedWeight),
              SelectedPallet: me.formatNumber(me.selectedPallet),
              Loads: data.LoadIds.map(function (id) {
                return {
                  Color: tuiv2.Colors.getColorByName(id),
                  Id: id
                }
              }),
              FromLoadId: fromLoadId,
              UnloadClass: 'unloadDemandZone',
              Title: loadId,
              ToLtlClass: 'loadFacilityToLTL',
              CreateLoadClass: 'createNewLoad',
              LoadClass: 'loadDemandZone',
              CloseClass: 'closeLoadToList'
            }

            if (me.selectedFacilityKeys.length > 0 && !me.loadToList) {
              me.loadToList = Ext.create({
                xtype: 'tuiv2-loadToListTmp',
                renderTo: event.target.parentNode.parentNode.parentNode.parentNode.children[0],
                data: model,
                rootView: me.rootView
              })

              openedLoadCards.push(me.loadToList)
              viewModel.set('openedLoadCards', openedLoadCards)
            } else if (me.selectedFacilityKeys.length == 0 && me.loadToList) {
              controller.removeOptionCard(me.loadToList)
              me.loadToList.destroy()
              me.loadToList = undefined
            }
            if (me.loadToList) {
              me.loadToList.setData(model)
            }
          }

          return
        }

        // demandzone

        if (event.target.classList.contains('unloadDemandZone')) {
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to unserve demand zone?',
            function (reasonCode) {
              var facilityKey = me.selectedFacilityKeys
              var indexes = me.selectedLoadIndexes
              if (indexes || indexes.length > 0) {
                controller.RemoveLoadStops(reasonCode, indexes, loadId, function (result) {
                  if (result.IsSuccess) {
                    var mergedDemandIs = $.extend([], demandIds)
                    mergedDemandIs.push(facilityKey)
                    me.toastResult(result)
                    controller.reloadLoadTreeStore()
                    controller.reloadDemandTreeStore()
                    controller.refreshRelatedPopup(mergedDemandIs, [loadId])
                  }
                })
              }
            },
            this
          )

          return
        }

        if (event.target.classList.contains('loadDemandZone')) {
          var targetLoadId = event.target.getAttribute('loadId')
          var facilityKeys = me.selectedFacilityKeys
          var loadIds = data.LoadIds.map(function (id) {
            return id
          })

          loadIds = _.uniq(loadIds.concat(targetLoadId))

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to serve demand zone from ' + targetLoadId + '?',
            function (reasonCode) {
              controller.onSwitchDemandZoneToLoad(
                reasonCode,
                facilityKeys,
                targetLoadId,
                data.Id,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()

                    var mergedDemandIs = $.extend([], demandIds)
                    mergedDemandIs.push(facilityKey)
                    controller.refreshRelatedPopup(mergedDemandIs, loadIds)
                  }
                }
              )
            },
            this
          )

          return
        }

        if (event.target.classList.contains('loadFacilityToLTL')) {
          var facilityKey = me.selectedFacilityKeys

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to convert LTL?',
            function (reasonCode) {
              controller.onLoadToLTL(
                reasonCode,
                facilityKey,
                loadId,
                null,
                null,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.reloadDemandTreeStore()
                    controller.refreshRelatedPopup([facilityKey], [loadId])
                    var loadStopIndex = parseInt(event.target.getAttribute('index')) - 1
                    data.LoadStops[loadStopIndex].visible = false

                    me.dataSet(data)
                  }
                }
              )
            },
            this
          )

          return
        }

        if (event.target.classList.contains('createNewLoad')) {
          var facilityKey = me.selectedFacilityKeys
          var serviceContractId = me.selectedServiceContractIds
          viewModel.set('facilityKey', facilityKey)
          viewModel.set('orderNumber', serviceContractId)
          controller.createLoadMessageBox(
            'Confirm',
            'Are you sure you want to create load?',
            function (originLocation, transportationModeName, reasonCode) {
              controller.onCreateNewLoad(
                facilityKey,
                loadId,
                serviceContractId,
                originLocation,
                transportationModeName,
                reasonCode,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('openDemandCard')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          if (controller.popups['demand' + facilityKey]) {
            if (drawRoute) {
              controller.onDemandlayerclick(null, { latlng: null }, facilityKey)
              return
            } else {
              var latlng = viewModel.get('disabledMapLatlng')
              controller.onDemandlayerclick(null, { latlng: latlng }, facilityKey)
              return
            }
          }
          viewModel.set('isOperationInProgress', true)
          controller.openDemandPopUp(facilityKey)
          return
        }

        // pallet
        if (event.target.classList.contains('closeLoadToList')) {
          controller.removeOptionCard(me.loadToList)
          me.loadToList.destroy()
          me.loadToList = undefined
          me.selectedFacilityKeys = []
          me.selectedLoadIndexes = []
          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('unloadPallet')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var fromLoadId = event.target.getAttribute('FromLoadId')

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to load pallet?',
            function (reasonCode) {
              controller.onRemovePalletsFromLoad(
                reasonCode,
                facilityKey,
                null,
                fromLoadId,
                1,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.reloadDemandTreeStore()
                    controller.refreshRelatedPopup([facilityKey], [fromLoadId])
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('loadPallet')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var fromLoadId = event.target.getAttribute('FromLoadId')

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to load pallet?',
            function (reasonCode) {
              controller.onServePalletOnLoad(
                reasonCode,
                facilityKey,
                null,
                1,
                loadId,
                fromLoadId,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], [loadId, fromLoadId])
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('loadPalletToLTL')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var fromLoadId = event.target.getAttribute('FromLoadId')

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to convert LTL?',
            function (reasonCode) {
              controller.onLoadToLTL(
                reasonCode,
                facilityKey,
                fromLoadId,
                null,
                1,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.reloadDemandTreeStore()
                    controller.refreshRelatedPopup([facilityKey], [loadId])

                    me.data.VisiblePalletOptionId = null
                    me.dataSet(data)
                  }
                }
              )
            },
            this
          )
          return
        }
        if (event.target.classList.contains('slvy-ui-icon-select-all')) {
          var list = event.currentTarget.getElementsByClassName('planFacility')
          if (!event.currentTarget.classList.contains('checked')) {
            for (let i = 0; i < list.length; i++) {
              const element = list[i]
              if (!element.classList.contains('-selected')) {
                element.click()
              }
            }
            event.currentTarget.classList.add('checked')
          } else {
            for (let i = 0; i < list.length; i++) {
              const element = list[i]
              if (element.classList.contains('-selected')) {
                element.click()
              }
            }
            event.currentTarget.classList.remove('checked')
          }

          return
        }
      },
      element: 'el'
    }
  },
  tpl: new Ext.XTemplate(
    '<div class="card {[this.getClass(values)]}">' +
      '<div class="card {[this.getClass(values)]} :: before" data-qtip="{[this.getCompliantTooltip(values)]}"></div>' +
      '<div class="loadToList"></div>',
    '   <div class="titleBar -demand">' +
      '       <p class="title">{Title}</p>' +
      '       <div class="card-close"><span class="slvy-ui-icon-times-lt closeAllCard"></span></div>' +
      '   </div>' +
      '   <div class="header -first" style="background:{[this.getColorCode(values.Id)]};">' +
      '      <div class="info">' +
      '         <div class="left">' +
      '            <div class="id">{SubTitle1}</div>' +
      '            <div class="name">{SubTitle2}</div>' +
      '            <div class="distance">{[this.formatNumber(values.LoadedWeight)]} {[this.getUnit("BaseWeightUOM")]}</div>' +
      '            <div class="cost">{[this.formatNumber(values.Cost)]} {[this.getUnit("BaseCurrencyUOM")]}</div>' +
      '         </div>' +
      '         <div class="right">' +
      '           <ul class="count-container">' +
      '               <li class="total-count">{[this.formatNumber(values.MaxPallet)]}P</li>' +
      '               <li class="palette-count" ><p class="card-tooltip"><span class="tooltiptext">{[this.getPalletSum(values)]}</span>{LoadedPallet}</p></li>' +
      '               <li class="empty-count"><p class="card-tooltip"><span class="tooltiptext">{[this.getPalletMinus(values)]}</span>{[values.MaxPallet - values.LoadedPallet]}</li>' +
      '           </ul>' +
      '         </div>' +
      '      </div>' +
      '   </div>' +
      '   <div class="header -second" style="background:{[this.getColorCode(values.Id)]};">' +
      '      <div class="action-container">' +
      '         <ul class="actions">' +
      '            <!-- <i class="slvy-ui-icon-arrow-left-extra-lt"></i> -->' +
      '            <li><i class="slvy-ui-icon-select-all fs-4" data-qtip="Select All"></i></li>' +
      '            <li><i class="slvy-ui-icon-info" data-qtip="Info"></i></li>' +
      '            <li><i class="slvy-ui-icon-merge mergeOptions" data-qtip="Merge Another Load"></i></li>' +
      '<tpl if="DisplayLoadOptions == true">' +
      '               <ul class="popup -sq" style="display:block;">' +
      '                   <li><span class="slvy-ui-icon-times-extra-lt closeMergeOptions"></span></li>' +
      '<tpl for="LoadOptions">' +
      '       <li  class="mergeLoad" loadId="{Id}">' +
      '                   <div class="dot" style="background:{[this.getColorCode(values.Id)]}"></div>{Id}' +
      '       </li>' +
      '</tpl>' +
      '               </ul>' +
      '</tpl>' +
      '            </li>' +
      '<tpl if="viewIndex != 2">' +
      '            <li><span class="slvy-ui-icon-scenario-change-extra-lt transportationModeOptions" data-qtip="Change TransportationMode"></span></li>' +
      '</tpl>' +
      '<tpl if="viewIndex == 2">' +
      '            <li><span class="fa fa-times cancelTransportationModeOption" data-qtip="Cancel TransportationMode"></span></li>' +
      '</tpl>' +
      '            <li><span class="slvy-ui-icon-scenario-delete-extra-lt removeLoad" data-qtip="Remove Load"></span></li>' +
      '<tpl if="viewIndex == 1">' +
      '            <li><i class="slvy-ui-icon-grid ekol-1-grid changeView" data-qtip="Pallet View"></i>' +
      '</tpl>' +
      '<tpl if="viewIndex == 0">' +
      '            <li><i class="slvy-ui-icon-list-extra-lt ekol-1-list changeView" data-qtip="Stop Sequence View"></i>' +
      '</tpl>' +
      '            </li>' +
      '         </ul>' +
      '      </div>' +
      '   </div>' +
      // load summary view
      '<tpl if="viewIndex == 0">' +
      '   <div class="grid-container-tui">' +
      '      <ul class="card-grid">' +
      '<tpl for="this.getPalletArrayForContainer(values.LoadStops,values.MaxPallet, values.Id)">' +
      '         <li  class="{class}"><div style="width:{palletWidth}%;background:{color};"></div></li>' +
      '</tpl>' +
      '      </ul>' +
      '   </div>' +
      '</tpl>' +
      // load list view
      '<tpl if="viewIndex == 1">' +
      '   <div class="grid-container-tui">' +
      '<tpl for="LoadStops">' +
      '      <div class="point">' +
      '         <ul class="sq">' +
      '            <li style="background:{[this.getDemandColor(values.Items)]}" class="planFacility" FacilityKey="{Id}" Id="{loadId}" index={#}></li>' +
      '         </ul>' +
      '         <div class="nameGroup">' +
      '           <p class="card-tooltip"><span class="tooltiptext"> Name: <b>{[this.isNull(values.SubTitle)]}</b> </br> Duedate: <b>{[this.isNull(values.Description)]}</b> </br> Priority: <b>{Priority}</b> </br> Cases: <b>{[this.getTooltip(values.Items,"Pieces")]}</b> </br> Volume: <b>{[this.getTooltip(values.Items,"Volume")]}</b> </br> Weight: <b>{[this.getTooltip(values.Items,"Weight")]}</b> </br> Lademeter: <b>{[this.getTooltip(values.Items,"Lademeter")]}</b></span>{Title}</p>' +
      '           <p class="loadSubTitle">{SubTitle}</p>' +
      '         </div>' +
      '         <i class="slvy-ui-icon-export-box openDemandCard" FacilityKey="{Id}"> </i>' +
      '      </div>' +
      '      <ul class="card-grid">' +
      '<tpl exec="values.parent = parent;"></tpl>' +
      '<tpl for="this.getPalletArray(values.Items, values.Id,values.parent)">' +
      '         <li style="background:{color};"><div class="planPallet {class}" palletId="{palletId}" FacilityKey="{facilityKey}" Id="{loadId}" style="width:{palletWidth}%"></div>' +
      '         </li>' +
      '</tpl>' +
      '      </ul>' +
      '</tpl>' +
      '   </div>' +
      '</tpl>' +
      // transportation mode options.
      '<tpl if="viewIndex == 2">' +
      '   <div class="grid-container-tui dropdown-menu-tui">' +
      '      <ul class="card-grid">' +
      '<tpl for="TransportationModeOptions">' +
      '<div class="point setTransportationMode">' +
      '        <div style="display:flex;">' +
      '           <p class="setTransportationModeIn" style="cursor: pointer;">{Name}</p>' +
      '           <p>{[this.isNull(values.SubTitle)]}</p>' +
      '        </div>' +
      '        <div class="count">' +
      '          <p class="palette-count">{[this.formatNumber(values.Utilization*100)]}% Util</p>' +
      '          <p class="empty-count">{[this.formatNumber(values.Cost)]} Cost</p>' +
      '        </div>' +
      '      </div>' +
      '</tpl>' +
      '      </ul>' +
      '   </div>' +
      '</tpl>' +
      '</div>',
    {
      getPalletArrayForContainer: function (demands, maxPallet, id) {
        // fix me
        var items = demands.reduce((a, b) => a.concat(b.Items), [])

        var loadedPallet = items.reduce(function (a, b) {
          if (b.IsPlanned && b.LoadId == id && b.LoadId.length > 0) return (a = a + b.NumPallet)
          return a
        }, 0)

        var array = []

        while (maxPallet > 0) {
          if (loadedPallet > 0) {
            array.push({
              palletWidth: 100,
              color: tuiv2.Colors.getColorByName(id),
              class: '-percent-bg'
            })

            loadedPallet--
          } else {
            array.push({
              palletWidth: 100,
              color: 'transparent',
              class: '-empty'
            })
          }

          maxPallet--
        }

        return array
      },
      getPalletArray: function (items, facilityKey, parent) {
        var array = []
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        items = items.sort(function (item) {
          return item.LoadId ? -1 : 1
        })
        var loopCount = 0
        for (let index = 0; index < items.length; index++) {
          const item = items[index]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned',
                palletId: loopCount + '-' + facilityKey,
                facilityKey: facilityKey,
                loadId: item.LoadId
              })
            } else if (item.LoadId != null && item.LoadId.length > 0) {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg',
                palletId: loopCount + '-' + facilityKey,
                facilityKey: facilityKey,
                loadId: item.LoadId
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9',
                palletId: loopCount + '-' + facilityKey,
                facilityKey: facilityKey,
                loadId: item.LoadId
              })
            }
            palletCount = palletCount - 1

            loopCount++
          }
        }
        return array
      },
      getColorCode: function (id) {
        return tuiv2.Colors.getColorByName(id)
      },
      formatNumber: function (value) {
        if (value == Number.NaN || value == Number.MAX_VALUE) value = 0
        if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

        return Ext.util.Format.number(value, '0,000.00')
      },
      getDemandColor(items) {
        var loadIds = items.map(function (item) {
          return item.LoadId
        })

        loadIds = loadIds.filter(function (item) {
          if (item) return true

          return false
        })

        if (loadIds.length == 1) {
          return tuiv2.Colors.getColorByName(loadIds[0])
        } else {
          return '#d9d9d9'
        }
      },
      isVisible: function (visible) {
        return visible ? 'block' : 'none'
      },
      isPalletVisible: function (palletId, visiblePalletId) {
        return palletId == visiblePalletId ? 'block' : 'none'
      },
      getTooltip: function (data, value) {
        var stringTooltip = '',
          numberTooltip = 0

        if (data.length == 1) {
          if (typeof data[0][value] == 'string') {
            stringTooltip += data[0][value] + ','
          } else {
            numberTooltip += data[0][value]
          }
        } else {
          data.forEach((item, index) => {
            if (typeof item[value] == 'string') {
              stringTooltip += item[value] + ','
            }
            if (value == 'Volume') {
              numberTooltip = index == 0 ? item[value] : numberTooltip
            } else {
              numberTooltip += item[value]
            }
          })
        }

        if (stringTooltip != '' && stringTooltip.includes(',')) {
          return stringTooltip.slice(0, stringTooltip.length - 1)
        } else {
          return this.formatNumber(numberTooltip)
        }
      },
      isNull: function (value) {
        if (!value) {
          return '-'
        } else {
          return value
        }
      },
      getClass: function (values) {
        var cardClass = tuiv2.Colors.getClassByColor(tuiv2.Colors.getColorByName(values.Id))
        if (values.Compliant == false) {
          cardClass = cardClass + ' -alert'
          cardClass = values.ComplianceWarningColor === 'red' ? cardClass + ' -red' : cardClass
        }
        return cardClass
      },
      getCompliantTooltip: function (values) {
        return values.ComplianceWarning
      },
      getUnit: function (value) {
        var planInfo = tuiv2.Units.getPlanInfo()
        if (planInfo) {
          return planInfo[value]
        }
      },
      getPalletSum: function (value) {
        var sum = 0
        var loadId = value.Id
        value.LoadStops.forEach((element) => {
          element.Items.forEach((item) => {
            if (item.LoadId == loadId) sum = sum + item.Volume
          })
        })
        return Ext.util.Format.number(sum, '0,000.00')
      },
      getPalletMinus: function (value) {
        var sum = 0
        var loadId = value.Id
        value.LoadStops.forEach((element) => {
          element.Items.forEach((item) => {
            sum = sum + item.Volume
          })
        })
        return Ext.util.Format.number(value.MaxVolume - sum, '0,000.00')
      }
    }
  ),

  toastResult: function (result) {
    if (result.IsSuccess) {
      Ext.toast({
        html: result.Message ? result.Message : 'Operation is completed successfully.',
        title: 'Information',
        width: 300,
        align: 'tr',
        iconCls: 'fa fa-check',
        closable: true,
        timeout: 4000
      })
    } else {
      Ext.toast({
        html: result.Message ? result.Message : 'Operation failed',
        title: 'Error',
        width: 300,
        align: 'tr',
        iconCls: 'fa fa-cross',
        closable: true,
        timeout: 4000
      })
    }
  },

  refresh: function () {
    var me = this,
      view = me.rootView,
      controller = view.getController(),
      data = me.getData(),
      loadId = data.Id

    controller.onRetrieveLoadPopupData(loadId, function (result) {
      data = result
      data.DisplayLoadOptions = false
      data.viewIndex = 1
      me.dataSet(data)
      return
    })
  },
  dataSet: function (data) {
    var me = this,
      view = me.rootView,
      controller = view.getController()
    if (me.loadToList) {
      controller.removeOptionCard(me.loadToList)
      me.loadToList.destroy()
      me.loadToList = undefined
    }

    me.setData(data)
    me.selectedFacilityKeys = []
    me.selectedLoadIndexes = []
  },
  formatNumber: function (value) {
    if (value == Number.NaN || value == Number.MAX_VALUE) value = 0
    if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

    return Ext.util.Format.number(value, '0,000.00')
  }
})
