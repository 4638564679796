import { take, put } from 'redux-saga/effects'
import _ from 'lodash'
import { RUN_EVENT } from '../actionTypes'
import { SET_PAGE_VARIABLES, SET_GLOBAL_VARIABLES } from '../../relation'

import { addMessage } from '../../../store/slices/logger'

function relationTransform(variables, eventRelations, result) {
  return _.transform(
    eventRelations,
    (eventRelationsResult, value, key) => {
      if (_.hasIn(result, key)) {
        if (_.indexOf(variables, value) !== -1) {
          eventRelationsResult.global[value] = result[key]
        } else {
          eventRelationsResult.page[value] = result[key]
        }
      }
    },
    { global: {}, page: {} }
  )
}
export default function* waitEvent(props = {}, result, action) {
  const { eventKeys = [], containerEventKeys = [] } = props
  const {
    payload: { relations, variables }
  } = action

  while (true) {
    const {
      payload: { result: takeResult, pluginId: takePluginId, eventKey: takeEventKey }
    } = yield take(RUN_EVENT)
    if (_.size(containerEventKeys) > 0) {
      const container = _.find(
        containerEventKeys,
        ({ id, eventKey }) => _.isEqual(id, takePluginId) && _.isEqual(eventKey, takeEventKey)
      )
      if (container) {
        return result
      }
    }
    if (relations) {
      const { events: { [takePluginId]: { [takeEventKey]: paramRelation } = {} } = {} } = relations
      let args = result

      if (_.size(eventKeys) <= 0 && _.size(containerEventKeys) <= 0) {
        args = relationTransform(variables, paramRelation, takeResult)

        if (window.ctrlshiftk) {
          const text = _.reduce(
            args,
            (r, v, k) => {
              return `${r}<br /><label>${k}</label>: ${JSON.stringify(v)}</i>`
            },
            ''
          )
          yield put(
            addMessage({ flag: 'Event', title: `${takeEventKey} - ${takePluginId}`, message: text })
          )
        }

        const {
          payload: {
            params: { storeIndex }
          }
        } = action
        yield put({
          type: SET_PAGE_VARIABLES,
          result: args.page,
          storeIndex
        })
        yield put({
          type: SET_GLOBAL_VARIABLES,
          result: args.global,
          storeIndex
        })
        return args
      }
      const event = _.find(
        eventKeys,
        ({ pluginId, eventKey }) =>
          _.isEqual(pluginId, takePluginId) && _.isEqual(eventKey, takeEventKey)
      )
      if (event) {
        args = relationTransform(variables, paramRelation, takeResult)

        if (window.ctrlshiftk) {
          const text = _.reduce(
            args,
            (r, v, k) => {
              return `${r}<br /><label>${k}</label>: ${JSON.stringify(v)}</i>`
            },
            ''
          )
          yield put(
            addMessage({ flag: 'Event', title: `${takeEventKey} - ${takePluginId}`, message: text })
          )
        }

        const {
          payload: {
            params: { storeIndex }
          }
        } = action
        yield put({
          type: SET_PAGE_VARIABLES,
          result: args.page,
          storeIndex
        })
        yield put({
          type: SET_GLOBAL_VARIABLES,
          result: args.global,
          storeIndex
        })
        return args
      }
    }
  }
}
