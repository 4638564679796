Ext.define('tuiv2.mapview.GeometryHelper', {
  extend: 'Ext.Base',
  singleton: true,
  config: {
    calculatedGeometries: {}
  },
  constructor: function (config) {
    var me = this
    me.initConfig(config)
  },
  getCoordinates: function (geo) {
    var geos = this.getCalculatedGeometries()
    if (!geos[geo]) {
      geos[geo] = L.PolylineUtil.decodeReverse(geo, 6)
    }
    return geos[geo]
  }
})
