const ControlPanel = ({ saveButton, onClick }) => {
  return (
    <div className="position-absolute" style={{ right: 3, top: 0, zIndex: 999, zoom: 0.8 }}>
      {saveButton ? (
        <button
          aria-label="Save"
          className=""
          style={{
            boxSizing: 'border-box',
            width: 32,
            height: 32,
            padding: 0,
            borderRadius: 3,
            margin: '7px 5px',
            border: '1px solid #f7f9fc',
            background: '#f7f9fc',
            fontSize: '1rem'
          }}
          type="button"
          onClick={onClick}
        >
          <i className="fa fa-fw fa fa-shekel" />
        </button>
      ) : null}
    </div>
  )
}

export default ControlPanel
