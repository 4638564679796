Ext.define('po.model.RoundUpModel', {
  extend: 'po.model.Base',
  entityName: 'po/roundup',
  fields: [
    { name: 'urunKodu', type: 'string' },
    { name: 'urunAciklamasi', type: 'string' },
    { name: 'tedarikciKodu', type: 'string' },
    { name: 'tedarikciAdi', type: 'string' },
    { name: 'depoSiparisKoli', type: 'float' },
    { name: 'DegisenOneriKoli', type: 'float' },
    { name: 'DegisenOneriKoli_PaletSayisi', type: 'float' }
  ]
})
