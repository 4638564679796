/* MODELS */
require('../src/view/regionapp/model/DPITree')
require('../src/view/regionapp/model/MaterialAssigned')
require('../src/view/regionapp/model/MaterialNew')
require('../src/view/regionapp/model/MaterialNoSupplySource')
require('../src/view/regionapp/model/MaterialUnAssigned')
require('../src/view/regionapp/model/Summary')
require('../src/view/regionapp/model/MaterialG11')

// DROPPED MATERIAL
require('../src/view/regionapp/DroppedMaterial/MainModel')
require('../src/view/regionapp/DroppedMaterial/MainViewModel')

// MASS EDIT
require('../src/view/regionapp/MassEdit/model/MaterialNew')
require('../src/view/regionapp/MassEdit/MainViewModel')

// GENERAL
require('../src/view/regionapp/MainViewModel')

// CMP
require('../src/view/regionapp/Cmp/SearchBoxGrid')
require('../src/view/regionapp/Cmp/SearchBox')
require('../src/view/regionapp/Cmp/ExtractDialog')
require('../src/view/regionapp/Cmp/ExtractGrid')
require('../src/view/regionapp/Cmp/NewMaterial')
require('../src/view/regionapp/Cmp/NoSupplysource')

// DIALOG
require('../src/view/regionapp/Dilaog/Grid')
require('../src/view/regionapp/Dilaog/OwnerList')

// DROPPED MATERIAL
require('../src/view/regionapp/DroppedMaterial/Grid')
require('../src/view/regionapp/DroppedMaterial/MainController')
require('../src/view/regionapp/DroppedMaterial/Main')

// GRID
require('../src/view/regionapp/Grid/MaterialAssigned')
require('../src/view/regionapp/Grid/MaterialNew')
require('../src/view/regionapp/Grid/MaterialNoSupplySource')
require('../src/view/regionapp/Grid/MaterialUnassigned')
require('../src/view/regionapp/Grid/MaterialG11')

// MASS EDIT
require('../src/view/regionapp/MassEdit/Grid')
require('../src/view/regionapp/MassEdit/MainController')
require('../src/view/regionapp/MassEdit/Main')

// PANEL
require('../src/view/regionapp/Panel/DropBox')
require('../src/view/regionapp/Panel/SilGrid')
require('../src/view/regionapp/Panel/silHeaderBar')
require('../src/view/regionapp/Panel/silMaterialEditForm')
require('../src/view/regionapp/Panel/silUnassignedGrid')
require('../src/view/regionapp/Panel/TabPanel')
require('../src/view/regionapp/Panel/Tree')

// GENERAL
require('../src/view/regionapp/MainController')

require('../src/view/regionapp/Main')
