import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Col, Row, FormGroup, Form } from 'react-bootstrap'
import _ from 'lodash'
import { slvyToast } from '@/components'
import CreatableSelect from './CreatableSelect'
import {
  getTemplates,
  getTemplateConnections,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  applyTemplate
} from '@/actions/pageTemplate'
import { mapFromCrudList } from '@/helpers/mapper'
import { parseErrorMsg } from '@/helpers'
import { isSuccess } from '@/crudoptV3'
import permission, { PermTypes } from '../../../../helpers/Permission'

class Template extends Component {
  constructor() {
    super()
    this.state = {
      templateName: '',
      templateId: '',
      pageTemplates: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    const {
      params: { pageId },
      pageTemplates,
      getTemplates
    } = this.props

    getTemplates(pageId)
    this.setState({ pageTemplates })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      isSuccess(this.props.pageTemplates, nextProps.pageTemplates) ||
      !_.isEqual(this.props.pageTemplates, nextProps.pageTemplates)
    ) {
      this.setState({
        pageTemplates: nextProps.pageTemplates
      })
    }
  }

  handleCreate(templateName) {
    const { params: { catalogId, pageId } = {}, createTemplate, getTemplates } = this.props

    if (!templateName) return

    const data = { catalogId, pageId, templateName }
    createTemplate(data)
      .then(() => {
        slvyToast.success({ message: 'Template created successfully!' })
        getTemplates(pageId)
      })
      .catch((error) => {
        slvyToast.error({ message: parseErrorMsg(error), title: 'Failed to create template!' })
      })
  }

  handleUpdate() {
    const {
      params: { pageId, catalogId },
      updateTemplate,
      getTemplates
    } = this.props
    const {
      templateId,
      pageTemplates: { data = [] }
    } = this.state

    if (!templateId) return
    const template = _.find(data, { id: templateId }) || {}
    const { name } = template

    updateTemplate(pageId, catalogId, templateId, name)
      .then(() => {
        slvyToast.success({ message: 'Template updated successfully!' })
        getTemplates(pageId)
      })
      .catch((error) => {
        slvyToast.error({ message: parseErrorMsg(error), title: 'Failed to update template!' })
      })
  }

  handleDelete(templateId) {
    const {
      params: { pageId },
      deleteTemplate,
      getTemplates
    } = this.props

    if (!templateId) return

    deleteTemplate(templateId)
      .then(() => {
        slvyToast.success({ message: 'Template deleted successfully!' })
        getTemplates(pageId)
      })
      .catch((error) => {
        slvyToast.error({ message: parseErrorMsg(error), title: 'Failed to delete template!' })
      })
  }

  handleChange(templateId) {
    this.setState({ templateId })
  }

  render() {
    const { checkPermission } = this.props
    const {
      pageTemplates: { data }
    } = this.state

    return (
      <FormGroup as={Row} className="form-group mb-3 page-template">
        <Form.Label column className="d-flex align-items-center justify-content-end" sm={2}>
          Template
        </Form.Label>
        <Col sm="10">
          <CreatableSelect
            placeholder="Select a template..."
            templateProps={{
              onChange: this.handleChange,
              onCreate: this.handleCreate,
              onDelete: this.handleDelete,
              options: mapFromCrudList(data, (item) => ({
                label: item.name,
                value: item.value,
                id: item.id
              })),
              checkPermission
            }}
          />
        </Col>
        <Col className="d-flex justify-content-end pt-2" sm="12">
          {checkPermission.hasPermType(PermTypes.PageTemplateEdit).isEnv('Configuration').value && (
            <Button variant="success" onClick={this.handleUpdate}>
              Update Template
            </Button>
          )}
        </Col>
      </FormGroup>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const {
    model3: {
      pagetemplate: {
        byId: { [ownProps.params.pageId]: pageTemplates = {} } = {},
        byId: { undefined: pageTemplateConnections } = {}
      } = {}
    } = {}
  } = state

  return {
    pageTemplates,
    pageTemplateConnections,
    checkPermission: permission(state.oidc.user, ownProps.params.environment)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTemplates: bindActionCreators(getTemplates, dispatch),
    getTemplateConnections: bindActionCreators(getTemplateConnections, dispatch),
    createTemplate: bindActionCreators(createTemplate, dispatch),
    updateTemplate: bindActionCreators(updateTemplate, dispatch),
    deleteTemplate: bindActionCreators(deleteTemplate, dispatch),
    applyTemplate: bindActionCreators(applyTemplate, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Template)
