Ext.define('MDM.src.view.globalapp.Panel.Publish.Main', {
  extend: 'Ext.panel.Panel',
  header: false,
  viewModel: 'globalapp-publish-mainviewmodel',
  controller: 'globalapp-publish-controller',
  bodyPadding: 10,
  xtype: 'globalapp-publish-main',
  layout: {
    type: 'hbox',
    pack: 'start',
    align: 'stretch'
  },
  listeners: {
    activate: 'onActive'
  },
  items: [
    {
      xtype: 'panel',
      flex: 3,
      margin: '0 5 0 0',
      layout: {
        type: 'vbox',
        pack: 'start',
        align: 'stretch'
      },
      items: [
        {
          xtype: 'globalapp-publish-maingrid',
          flex: 3,
          margin: '5 0 0 0',
          border: true,
          ui: 'panel-inverse',
          bind: {
            store: '{MainStore}'
          }
        },
        {
          xtype: 'globalapp-publish-loggrid',
          flex: 3,
          margin: '5 0 0 0',
          ui: 'panel-inverse',
          border: true,
          bind: {
            store: '{ChangeStore}'
          }
        }
      ]
    },
    {
      xtype: 'panel',
      margin: '5 5 0 0',
      flex: 2,
      layout: {
        type: 'fit',
        pack: 'start',
        align: 'stretch'
      },
      defaults: {
        collapsible: true,
        split: true,
        bodyPadding: 10
      },
      items: [
        {
          title: 'Reporting Period Information',
          border: true,
          region: 'north',
          height: 250,
          margin: '0 0 10 0',
          ui: 'panel-inverse',
          xtype: 'globalapp-setup-form'
        },
        {
          title: 'DPI Edit Calendar',
          iconCls: 'fa fa-calendar',
          flex: 1,
          region: 'center',
          bodyPadding: 0,
          border: true,
          xtype: 'globalapp-publish-callgrid',
          readOnlyMode: true,
          ui: 'panel-inverse',
          bind: {
            store: '{EditCalStore}'
          }
        }
      ]
    }
  ]
})
