import { CSSProperties } from 'react'
import { render, screen } from '@testing-library/react'
import _ from 'lodash'

import { Label } from './'

function getDefaultProps() {
  return {
    id: '',
    settings: {
      config: {
        settings: {
          textDegree: 'Normal'
        }
      }
    },
    registerMethod: () => {},
    registerEvent: () => {}
  }
}

describe('Label Plugin', () => {
  let props: any = {}

  beforeEach(() => {
    props = getDefaultProps()
  })

  it('renders without crash', () => {
    render(<Label {...props} />)
    const labelElement = screen.getByTestId('slvy-label')
    expect(labelElement).toBeInTheDocument()
  })

  it('renders with given text', () => {
    const text = 'John Doe'
    props.settings.config.settings.text = text
    render(<Label {...props} />)
    const labelElement = screen.getByText(text)
    expect(labelElement).toBeTruthy()
  })

  it('should not renders with wrong given text', () => {
    props.settings.config.settings.text = 'Kevin Spacey'
    render(<Label {...props} />)
    const labelElement = screen.queryByText('John Doe')
    expect(labelElement).not.toBeTruthy()
  })

  it('renders with given style', () => {
    const styleProps = {
      fontStyle: 'Normal',
      fontWeight: 'Normal',
      fontColor: 'blue',
      fontSize: 12,
      textAlign: 'Left'
    }

    props.settings.config.settings = {
      ...props.settings.config.settings,
      ...styleProps
    }

    render(<Label {...props} />)
    const labelElement = screen.getByTestId('slvy-label')

    const style: CSSProperties = _.omit(styleProps, ['fontColor'])
    style.color = styleProps.fontColor

    expect(labelElement).toHaveStyle(style)
  })

  it('renders vertically', () => {
    const text = 'Kevin Spacey'
    props.settings.config.settings.text = text
    props.settings.config.settings.textDegree = 'Vertical'
    render(<Label {...props} />)
    const letterElement = screen.getAllByTestId('slvy-label-letter')
    expect(letterElement).toHaveLength(text.length)
  })

  it('renders with given data column text', () => {
    const textColumn = 'City'
    const textValue = 'New York'
    props.pluginData = [{ [textColumn]: textValue }]
    props.settings.config.data = { textColumn }
    render(<Label {...props} />)

    const labelElement = screen.getByText(textValue)
    expect(labelElement).toHaveTextContent(textValue)
  })

  it('renders formatted version of given data column text', () => {
    const textColumn = 'Price'
    const textValue = '12.00'
    const textFormatted = '12.0000'
    const formatStr = '0,0.0000'

    props.getFormattedValue = () => textFormatted
    props.pluginData = [{ [textColumn]: textValue }]
    props.settings.config.data = { textColumn }
    props.settings.query = {
      formattedFields: [
        {
          baseColumn: textColumn,
          columnName: textColumn,
          excelFormatString: formatStr,
          formatString: formatStr
        }
      ]
    }

    render(<Label {...props} />)
    const labelElement = screen.getByText(textFormatted)
    expect(labelElement).toBeInTheDocument()
  })
})
