Ext.define('Spui.view.wizard.pages.Publish.Mixin', {
  extend: 'Ext.Mixin',
  config: {
    centerPanelCfg: 'Publisgh_Center_Panel',
    centerPanel: {}
  },
  applyCenterPanelCfg: function (c) {
    return window.ss(c)
  },
  listeners: {
    activate: function () {
      this.getCenterPanel().activated()
    }
  },
  applyCenterPanel: function (b) {
    var setRec = this.getCenterPanelCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        title: tt('Publish'),
        activated: Ext.emptyFn,
        bind: {
          spotRecord: '{spotRecord}'
        },
        xtype: 'panel',
        width: 600,
        height: 600,
        plugins: [
          {
            ptype: 'cornerIcon',
            extraButton: [
              {
                iconCls: 'x-fa fa-cog',

                menu: [
                  {
                    text: 'Chart Config',
                    handler: this.onConfigCenterPanel,
                    scope: this
                  }
                ]
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    return b
  },
  updateCenterPanel: function (f, g) {
    if (g) {
      this.remove(g)
      this.add(f)
      return
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigCenterPanel: function () {
    var setRec = this.getCenterPanelCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigCenterPanelSave, this)
  },
  onConfigCenterPanelSave: function (v, k) {
    var setRec = this.getCenterPanelCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setCenterPanel(res)
  }
})
