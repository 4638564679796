Ext.define('Spui.view.common.similarity.tabpanel.NomalizedChart.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-similarity-tabpanel-nomalizedchart-main',
  mixins: ['Spui.view.common.similarity.tabpanel.NomalizedChart.Mixin'],
  defaultListenerScope: true,
  layout: 'fit',
  initComponent: function () {
    var me = this
    me.plugins = [
      {
        ptype: 'cornerIcon',
        extraButton: [
          {
            iconCls: 'x-fa fa-arrows-alt',
            handler: me.onMaximize,

            scope: me
          }
        ],
        cfgButton: [
          {
            iconCls: 'x-fa fa-cog',
            cls: 'config-btn',
            menu: [
              {
                text: 'Chart Config',
                handler: me.onConfigNormalizedChart,
                scope: me
              },
              {
                text: 'Store Config',
                handler: me.onConfigNormalizedStore,
                scope: me
              }
            ]
          }
        ]
      }
    ]

    me.callParent()
  }
})
