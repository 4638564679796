import { defaultBooleanCellColor, defaultBooleanCellIcon } from '../../../constants'
import { TcustomConfigs } from '../../../types'
import { getCalculatedBooleanCellProps } from '../index'

const boolean: TcustomConfigs['boolean'] = {
  trueIcon: 'ag-icon ag-icon-checkbox-checked',
  falseIcon: 'ag-icon ag-icon-checkbox-unchecked',
  trueColor: 'green',
  falseColor: 'red'
}

describe('AgGrid:Helpers:Cell:getCalculatedBooleanCellProps', () => {
  it('should return the correct icon and color for true value', () => {
    const value = true

    const result = getCalculatedBooleanCellProps(value, boolean)
    expect(result).toEqual({ icon: boolean.trueIcon, color: boolean.trueColor })
  })

  it('should return the correct icon and color for "true" value', () => {
    const value = 'true'

    const result = getCalculatedBooleanCellProps(value, boolean)
    expect(result).toEqual({ icon: boolean.trueIcon, color: boolean.trueColor })
  })

  it('should return the correct icon and color for false value', () => {
    const value = false

    const result = getCalculatedBooleanCellProps(value, boolean)
    expect(result).toEqual({ icon: boolean.falseIcon, color: boolean.falseColor })
  })

  it('should return the correct icon and color for "false" value', () => {
    const value = 'false'

    const result = getCalculatedBooleanCellProps(value, boolean)
    expect(result).toEqual({ icon: boolean.falseIcon, color: boolean.falseColor })
  })

  it('should return the correct icon and color for null value', () => {
    const value = null

    const result = getCalculatedBooleanCellProps(value, boolean)
    expect(result).toEqual({ icon: defaultBooleanCellIcon, color: defaultBooleanCellColor })
  })
})
