Ext.define('MDM.src.view.globalapp.Panel.setup.Calendar', {
  extend: 'Ext.calendar.panel.Panel',
  xtype: 'globalapp-calendar',
  calendarList: {
    hidden: true
  },
  sideBar: {
    xtype: 'panel',
    hidden: true
  },
  views: {
    day: null,
    week: null,
    month: {
      xtype: 'calendar-month',
      titleTpl: '{start:date("F Y")}',
      draggable: false,
      label: 'Month',
      listeners: {
        beforeeventadd: function () {
          return false
        }
      },
      editForm: {
        xtype: 'calendar-form-add',
        title: 'DPI Process Calendar',
        calendarField: {
          xtype: 'calendar-calendar-picker',
          fieldLabel: 'Process Step',
          name: 'calendarId',
          forceSelection: true,
          editable: false,
          queryMode: 'local',
          displayField: 'title',
          valueField: 'id',
          readOnly: true
        },

        /**
         * @cfg {Object} titleField
         * The config for the title field.
         */
        titleField: {
          xtype: 'textfield',
          fieldLabel: 'Title',
          name: 'title',
          allowBlank: false,
          readOnly: true,
          hidden: true
        },

        /**
         * @cfg {Object} fromContainer
         * The config for the from container.
         */
        fromContainer: {
          xtype: 'fieldcontainer',
          fieldLabel: 'Start Date',
          layout: 'hbox',
          readOnly: true
        },

        /**
         * @cfg {Object} startDateField
         * The config for the start date field.
         */
        startDateField: {
          xtype: 'datefield',
          itemId: 'startDate',
          name: 'startDate',
          allowBlank: false,
          readOnly: true
        },

        /**
         * @cfg {Object} startTimeField
         * The config for the start time field.
         */
        startTimeField: {
          xtype: 'timefield',
          itemId: 'startTime',
          name: 'startTime',
          margin: '0 0 0 5',
          hidden: true,
          readOnly: true
        },

        /**
         * @cfg {Object} toContainer
         * The config for the to container.
         */
        toContainer: {
          xtype: 'fieldcontainer',
          fieldLabel: 'End Date',
          layout: 'hbox'
        },

        /**
         * @cfg {Object} endDateField
         * The config for the end date field.
         */
        endDateField: {
          xtype: 'datefield',
          itemId: 'endDate',
          name: 'endDate',
          allowBlank: false,
          readOnly: true
        },

        /**
         * @cfg {Object} endTimeField
         * The config for the end time field.
         */
        endTimeField: {
          xtype: 'timefield',
          itemId: 'endTime',
          name: 'endTime',
          hidden: true,
          margin: '0 0 0 5',
          readOnly: true
        },

        /**
         * @cfg {Object} allDayField
         * The config for the all day field.
         */
        allDayField: {
          xtype: 'checkbox',
          itemId: 'allDay',
          name: 'allDay',
          boxLabel: 'All Day',
          hidden: true,
          hideEmptyLabel: false,
          handler: 'onAllDayChange',
          readOnly: true
        },

        /**
         * @cfg {Object} descriptionField
         * The config for the description field.
         */
        descriptionField: {
          xtype: 'textarea',
          fieldLabel: 'Description',
          name: 'description',
          flex: 1,
          readOnly: true
        },

        /**
         * @cfg {Object} dropButton
         * The config for the drop button. `null` to not show this button.
         */
        dropButton: {
          text: 'Delete',
          handler: 'onDropTap',
          hidden: true
        },

        /**
         * @cfg {Object} saveButton
         * The config for the save button.
         */
        saveButton: {
          text: 'Save',
          handler: 'onSaveTap',
          hidden: true
        },

        /**
         * @cfg {Object} cancelButton
         * The config for the cancel button.
         */
        cancelButton: {
          text: 'Close',
          handler: 'onCancelTap'
        }
      },
      controlStoreRange: false,
      weight: 30
    }
  },
  timezoneOffset: 0
})
