import { useCallback, useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { GetConditionedRowsProps } from './useRegisterMethodGetConditionedRows.type'

function useRegisterMethodGetConditionedRows({
  conditionedRowsResultEventRef,
  registerMethod
}: GetConditionedRowsProps) {
  const handleGetConditionedRows = useCallback(
    ({ conditionDataField }: { conditionDataField: string }) => {
      conditionedRowsResultEventRef?.current?.handleConditionedRowsResult(conditionDataField)
    },
    [conditionedRowsResultEventRef]
  )

  useEffect(() => {
    registerMethod({
      args: [{ name: 'conditionDataField', type: PluginTypes.string }],
      key: 'GetConditionedRows',
      fn: handleGetConditionedRows
    })
  }, [handleGetConditionedRows, registerMethod])
}

export default useRegisterMethodGetConditionedRows
