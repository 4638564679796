Ext.define('MDM.src.view.DPIAdd.Main', {
  extend: 'Ext.window.Window',
  closeAction: 'destroy',
  height: 500,
  searchCollapsed: false,
  width: 1000,
  y: 200,
  header: false,
  modal: true,
  bodyStyle: {
    background: '#fff'
  },
  controller: 'dpiadd-maincontroller',
  viewModel: 'dpiadd-mainviewmodel',
  iconCls: 'fa fa-list',
  bodyBorder: false,
  layout: 'border',
  bodyPadding: 4,
  closable: false,
  defaults: {
    collapsible: true,
    split: true
  },
  items: [
    {
      title: 'Material List',
      region: 'west',
      xtype: 'DPIAdd-panels-search',
      floatable: false,
      reference: 'search',
      margin: '5 0 0 0',
      width: 440,
      iconCls: 'fa fa-search-plus',
      minWidth: 100,
      maxWidth: 600,
      ui: 'panel-inverse',
      border: true,
      bind: {
        store: '{searchStore}'
      }
    },
    {
      collapsible: false,
      border: true,
      ui: 'panel-inverse',
      xtype: 'DPIAdd-list',
      iconCls: 'fa fa-list',
      region: 'center',
      margin: '5 0 0 0',
      bind: {
        store: '{DPIMatStore}',
        title: 'Material List of ' + '{record.text}'
      }
    }
  ],
  bbar: {
    xtype: 'toolbar',
    reference: 'basic-statusbar',

    defaultText: 'Ready',

    text: 'Ready',
    iconCls: 'x-status-valid',

    items: [
      '->',
      '-',
      {
        xtype: 'button',
        text: 'Save',
        ui: 'btn-success',
        handler: 'onSaveBtnClick',
        iconCls: 'fa fa-floppy-o',
        bind: {
          visible: '{isStoreDirty}'
        }
      },
      {
        text: 'Close',
        iconCls: 'fa fa-ban',
        ui: 'btn-primary',
        handler: function (btn) {
          btn.up('window').destroy()
        }
      }
    ]
  }
})
