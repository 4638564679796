import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import defaultChartConfig from '../../utils/similarProductChartConfig'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)

export default class SimilarOptionsChart extends Component {
  constructor(props) {
    super(props)
    this.chartConfig = _.cloneDeep(defaultChartConfig)
  }

  componentDidMount() {
    this.chartConfig = this.getChartConfig(this.props)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!_.isEqual(nextProps, this.props)) {
      this.chartConfig = this.getChartConfig(nextProps)
    }
  }

  getChartConfig($props) {
    const { data, startDateMerchDateIndex, endDateMerchDateIndex } = this.getSeriesData($props)

    const result = this.getResponseData({
      Products: $props.products,
      MerchDates: $props.merchDates
    })

    const similarOptions = { ...result }

    // Create series
    const activeSimilarOptions = _.filter(similarOptions, { isEnabled: true })
    const series = _.map(activeSimilarOptions, (option) => {
      const series = {
        name: option.description || option.name,
        dataIndex: option.name,
        marker: {
          radius: 2
        },
        data: _.map(data, (row) => {
          return row[option.name]
        })
      }
      if (option.similarityDesc === 'Forecast') {
        series.color = 'black'
        series.dashStyle = 'ShortDash'
        series.lineWidth = 3
      }
      return series
    })

    const categories = _.map(data, (row) => {
      return row.salesDate
    })

    const chartConfig = _.cloneDeep(this.chartConfig)

    chartConfig.series = series
    chartConfig.xAxis.categories = categories
    chartConfig.xAxis.plotBands = [
      {
        from: startDateMerchDateIndex,
        to: endDateMerchDateIndex,
        color: '#FCFFC5'
      }
    ]

    return chartConfig
  }

  getSeriesData($props) {
    const result = this.getResponseData({
      Products: $props.products,
      MerchDates: $props.merchDates
    })
    const options = {
      realProduct: $props.selectedOption,
      numberOfHistoryBackPeriods: $props.numberOfHistoryBackPeriods
    }

    const similarOptions = { ...result }
    const { realProduct } = options

    if (similarOptions.length === 0) {
      return
    }

    const { merchDates } = similarOptions[0]

    const activeSimilarOptions = _.filter(similarOptions, { isEnabled: true })

    // Get the planned sale period of the real product
    let startDate = realProduct.StartDate
    _.forEach(realProduct.SegmentOptions, (segmentOption) => {
      const segmentOptionStartDate = new Date(segmentOption.StartDate)
      if (segmentOption.Enabled && startDate > segmentOptionStartDate) {
        startDate = segmentOptionStartDate
      }
    })

    let shiftedStartDate = moment(startDate).subtract(0, 'days').format()
    shiftedStartDate = moment(shiftedStartDate).toDate()

    let shiftedStartDateString = moment(shiftedStartDate).format('YYYY-MM-DDTHH:mm:ss')
    if (shiftedStartDateString) {
      let _shiftedStartDateString = shiftedStartDateString.split('T')
      if (_shiftedStartDateString.length > 0) {
        _shiftedStartDateString[1] = '00:00:00'
        _shiftedStartDateString = _shiftedStartDateString.join('T')
        shiftedStartDateString = _shiftedStartDateString
      }
    }

    const startDateMerchDate = merchDates[shiftedStartDateString]

    let endDate = realProduct.EndDate
    _.forEach(realProduct.SegmentOptions, (segmentOption) => {
      const segmentOptionEndDate = new Date(segmentOption.EndDate)
      if (segmentOption.Enabled && endDate < segmentOptionEndDate) {
        endDate = segmentOptionEndDate
      }
    })

    let shiftedEndDate = moment(endDate).subtract(0, 'days').format()
    shiftedEndDate = moment(shiftedEndDate).toDate()

    let shiftedEndDateString = moment(shiftedEndDate).format('YYYY-MM-DDTHH:mm:ss')
    if (shiftedEndDateString) {
      let _shiftedEndDateString = shiftedEndDateString.split('T')
      if (_shiftedEndDateString.length > 0) {
        _shiftedEndDateString[1] = '00:00:00'
        _shiftedEndDateString = _shiftedEndDateString.join('T')
        shiftedEndDateString = _shiftedEndDateString
      }
    }

    const endDateMerchDate = merchDates[shiftedEndDateString]

    let started = false
    let finished = false
    let distinctMerchDates = []
    _.forEach(merchDates, (value, key) => {
      if (_.indexOf(distinctMerchDates, value) < 0) {
        distinctMerchDates.push(value)
      }
    })

    distinctMerchDates = _.sortBy(distinctMerchDates)

    // Get the planned sale period of the real product as merch dates
    const productMerchDates = []
    _.forEach(distinctMerchDates, (productMerchDate) => {
      if (productMerchDate === startDateMerchDate) {
        started = true
      }
      if (started && !finished) {
        productMerchDates.push(productMerchDate)
      }
      if (productMerchDate === endDateMerchDate) {
        finished = true
      }
    })

    // Combine sales histories of options
    let data = []
    const combinedSalesData = {}

    _.transform(
      activeSimilarOptions,
      (res, item) => {
        _.forEach(item.weeklySales, (sale, salesDate) => {
          const findedMerchDates = merchDates[salesDate]
          if (!_.has(combinedSalesData, findedMerchDates)) {
            combinedSalesData[findedMerchDates] = {}
          }
          combinedSalesData[findedMerchDates][item.name] = sale
        })
      },
      {}
    )

    // If some merch dates do not exist in the sales data periods, add them
    _.forEach(productMerchDates, (merchDate) => {
      if (!_.has(combinedSalesData, merchDate)) {
        combinedSalesData[merchDate] = {}
      }
    })

    _.forEach(combinedSalesData, (value, key) => {
      const record = {
        salesDate: !_.isNil(key) && key !== 'undefined' ? key : '',
        sortKey: !_.isNil(key) && key !== 'undefined' ? key : ''
      }
      _.forEach(activeSimilarOptions, (option) => {
        record[option.name] = _.has(value, option.name) ? value[option.name] : null
      })
      data.push(record)
    })

    data = _.sortBy(data, ['sortKey'])

    const startDateMerchDateIndex = _.findIndex(data, (row) => {
      return row.salesDate === startDateMerchDate
    })

    const endDateMerchDateIndex = _.findIndex(data, (row) => {
      return row.salesDate === endDateMerchDate
    })

    return {
      data,
      startDateMerchDateIndex,
      endDateMerchDateIndex
    }
  }

  getResponseData(response) {
    const result = response
    const merchDates = result.MerchDates
    return _.map(result.Products, (product) => {
      const details = _.map(product.SimilarityDetails, (char) => Number(char))
      return {
        name: product.Name,
        description:
          product.Description === product.Name
            ? product.Description.substring(0, product.Description.indexOf('_'))
            : product.Description,
        startTime: product.StartTime,
        endTime: product.EndTime,
        overlappingRatio: product.OverlappingRatio,
        similarityDesc:
          product.SimilarityHierarchy > 0
            ? product.SimilarityHierarchy +
              (product.SimilarityId >= 0 ? ` ${product.SimilarityId}` : '')
            : product.SimilarityDescription,
        image: product.ImageURL,
        ruleID: product.SimilarityId,
        hierarchialOrder: product.SimilarityHierarchy,
        weeklySales: product.WeeklySales,
        basePrice: product.BasePrice,
        totalSales: product.TotalSales,
        isEnabled: !product.IsDisabled,
        id: product.Id,
        similarityDetails: details,
        stock: product.InitialInventory,
        storeCount: product.StoreCount,
        sizeRange: product.SizeRange,
        merchDates,
        overlappingTotalSales: product.OverlappingTotalSales,
        sizeRangeTypeID: product.SizeRangeTypeID
      }
    })
  }

  render() {
    const { chartConfig: config, chartConfig: { series = [] } = {} } = this
    return series.length > 0 ? <HighchartsReact highcharts={Highcharts} options={config} /> : null
  }
}
