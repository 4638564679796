import { ColumnFilterProps } from './ColumnFilter.types'

export default function ColumnFilter({ cell }: ColumnFilterProps) {
  // const count = preFilteredRows.length
  function handleChangeFilter(event) {
    cell.column.setFilterValue(event?.target.value)
  }

  return (
    <input
      className="w-100"
      placeholder={`${cell.column.getFacetedRowModel().flatRows.length} records...`}
      value={cell.column.getFilterValue()}
      onChange={handleChangeFilter}
    />
  )
}
