import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Col, FormControl, Row } from 'react-bootstrap'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { SlvySelect, SlvySelectStyles } from '@/components'
import './style.scss'

const menuPortalTarget = document.body

class FilterList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filterRows: [],
      disabled: false
    }

    this.handleNewRowAdded = this.handleNewRowAdded.bind(this)
    this.handleNewRowDeleted = this.handleNewRowDeleted.bind(this)
    this.handleFilterApplied = this.handleFilterApplied.bind(this)
    this.setSelectionEnabled = this.setSelectionEnabled.bind(this)
    this.setSelectionDisabled = this.setSelectionDisabled.bind(this)

    this.rowTemplate = {
      field: '',
      operator: '',
      value: '',
      dataType: ''
    }

    this.numberInput = []
  }

  handleFilterApplied({ filterRows = [] }) {
    // Remove rows with empty elements
    const validRows = _.transform(
      filterRows,
      (result, filterRow) => {
        if (filterRow.field && filterRow.operator && filterRow.value) {
          result.push(filterRow)
        }
        return result
      },
      []
    )

    return {
      value: _.map(validRows, (validRow) => JSON.stringify(validRow))
    }
  }

  handleNewRowAdded() {
    const newFilterRows = [...this.state.filterRows, { ...this.rowTemplate }]
    this.setState({
      filterRows: newFilterRows
    })
    this.handleFilterApplied({ filterRows: newFilterRows })
  }

  handleNewRowDeleted(index) {
    return () => {
      const filterRows = [...this.state.filterRows]
      _.remove(filterRows, (filterRow, n) => {
        return n === index
      })
      this.setState({
        filterRows
      })
      this.handleFilterApplied({ filterRows })
    }
  }

  setSelectionEnabled() {
    this.setState({ disabled: false })
    this.handleFilterApplied({ filterRows: this.state.filterRows })
  }

  setSelectionDisabled() {
    this.setState({ disabled: true })
    this.handleFilterApplied({ filterRows: this.state.filterRows })
  }

  handleFieldSelected(index) {
    return (option) => {
      if (option) {
        const { settings: { config: { fields = [] } = {} } = {} } = this.props || {}
        const fieldConfig = _.find(fields, { fieldName: option.value })

        if (fieldConfig) {
          const filterRows = [...this.state.filterRows]
          filterRows[index].field = option.value
          filterRows[index].dataType = fieldConfig.dataType

          this.setState({
            filterRows
          })
          this.handleFilterApplied({ filterRows })
        }
      }
    }
  }

  handleOperatorSelected(index) {
    return (option) => {
      if (option) {
        const filterRows = [...this.state.filterRows]
        filterRows[index].operator = option.value

        this.setState({
          filterRows
        })
        this.handleFilterApplied({ filterRows })
      }
    }
  }

  handleValueChanged(index) {
    return (event) => {
      const filterRows = [...this.state.filterRows]
      filterRows[index].value = event.target.value
      this.setState(
        {
          filterRows
        },
        () => {
          this.numberInput[index] && this.numberInput[index].focus()
          this.handleFilterApplied({ filterRows })
        }
      )
    }
  }

  setValue({ value }) {
    if (value && _.isArray(value)) {
      const filterRows = _.map(value, (row) => {
        return JSON.parse(row)
      })
      this.setState({
        filterRows
      })
      this.handleFilterApplied({ filterRows })
    }
  }

  clearValue() {
    this.setState({
      filterRows: []
    })
    this.handleFilterApplied({ filterRows: [] })
  }

  UNSAFE_componentWillMount() {
    const { settings: { config: { operators = [] } = {} } = {} } = this.props

    const defaultOperator = _.find(operators, (operator) => {
      return operator.default
    })

    if (defaultOperator) {
      this.rowTemplate.operator = defaultOperator.symbol
    }

    this.handleFilterApplied = this.props.registerEvent({
      key: 'filterChange',
      fn: this.handleFilterApplied,
      returnTypes: {
        value: PluginTypes.arrayOf(PluginTypes.dynamic)
      }
    })

    this.props.registerMethod({
      key: 'setValue',
      fn: this.setValue.bind(this),
      args: [{ name: 'value', type: PluginTypes.arrayOf(PluginTypes.dynamic) }]
    })

    // Register save method
    this.props.registerMethod({
      key: 'clearValue',
      fn: this.clearValue.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setSelectionEnabled',
      fn: this.setSelectionEnabled.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setSelectionDisabled',
      fn: this.setSelectionDisabled.bind(this),
      args: []
    })
  }

  render() {
    const {
      settings: {
        config: { fields = [], operators = [], settings: { operatorPosition = 'center' } = {} } = {}
      } = {}
    } = this.props
    const { filterRows = [], disabled = false } = this.state

    const availableOperators = _.map(operators, (operator) => {
      return { value: operator.symbol, label: operator.display || operator.symbol }
    })

    const availableFields = _.map(fields, (field) => {
      return { value: field.fieldName, label: field.displayName || field.fieldName }
    })

    return (
      <div className="list-filter">
        {_.map(filterRows, (filterRow, index) => {
          const selectedField =
            availableFields.find((option) => option.value === filterRow.field) ?? null
          const fieldSection = (
            <Col key={`field${index}`} className="list-filter-column" xs={5}>
              <SlvySelect
                isDisabled={disabled}
                menuPortalTarget={menuPortalTarget}
                options={availableFields}
                styles={SlvySelectStyles.auto}
                value={selectedField}
                onChange={this.handleFieldSelected(index)}
              />
            </Col>
          )

          const selectedOperator =
            availableOperators.find((option) => option.value === filterRow.operator) ?? null
          const operatorSection = (
            <Col key={`operator${index}`} className="list-filter-column" xs={2}>
              <SlvySelect
                isDisabled={disabled}
                menuPortalTarget={menuPortalTarget}
                options={availableOperators}
                styles={SlvySelectStyles.auto}
                value={selectedOperator}
                onChange={this.handleOperatorSelected(index)}
              />
            </Col>
          )

          const valueSection = (
            <Col key={`value${index}`} className="list-filter-column" xs={4}>
              <FormControl
                key={index}
                ref={(numberInput) => {
                  this.numberInput[index] = numberInput
                }}
                readOnly={disabled}
                type="number"
                value={filterRow.value}
                onChange={this.handleValueChanged(index)}
              />
            </Col>
          )

          let orderedSections = [fieldSection, operatorSection, valueSection]
          if (operatorPosition === 'left') {
            orderedSections = [operatorSection, fieldSection, valueSection]
          } else if (operatorPosition === 'right') {
            orderedSections = [fieldSection, valueSection, operatorSection]
          }

          return (
            <Row key={index} className="list-filter-row">
              {orderedSections}

              <Col className="list-filter-column" xs={1}>
                <Button
                  className="list-filter-action"
                  size="sm"
                  variant="outline-dark"
                  onClick={this.handleNewRowDeleted(index)}
                >
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>
          )
        })}
        <Row className="list-filter-row">
          <Col className="list-filter-column" xs={12}>
            <Button
              className="list-filter-action"
              size="sm"
              variant="outline-dark"
              onClick={this.handleNewRowAdded}
            >
              <i className="fa fa-plus" />
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  settings: props.settings
})

export default createPlugin(FilterList, selectConnectorProps)
