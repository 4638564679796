import { useEffect } from 'react'
import { objectKeys } from '@/ts/utils'
import {
  handleDataColumnChanged,
  // handleDeleteConfigChanged,
  handleSynchronizeDataDefinition
} from '../../helpers'
import { ColumnComparisonData } from '../../types'
import { PreviewHandlersProps } from './usePreviewHandlers.types'

function usePreviewHandlers(props: PreviewHandlersProps) {
  const {
    schema,
    onReady,
    dataSchema,
    schemaColumnItems,
    isPreviewMode,
    fieldConfigsQuery,
    memoizedFieldConfigs
  } = props
  useEffect(() => {
    if (!isPreviewMode || !objectKeys(schema).length) {
      return
    }

    onReady<ColumnComparisonData>({
      changedHandlers: [
        (value, _initialData, previousValue) => {
          return handleDataColumnChanged({
            _fieldConfigs: memoizedFieldConfigs,
            value,
            previousValue
          })
        }
        // (
        //   value: ColumnComparisonData,
        //   _initialData: ColumnComparisonData,
        //   previousValue: ColumnComparisonData | undefined,
        //   schemaHelper: any
        // ) => {
        //   return handleDeleteConfigChanged(schemaColumnItems, value, previousValue, schemaHelper)
        // }
      ],
      onSynchronizeDataDefinition: (fields, value, schemaHelper) => {
        return handleSynchronizeDataDefinition({ schemaColumnItems, fields, value, schemaHelper })
      }
    })
  }, [
    schema,
    onReady,
    schemaColumnItems,
    dataSchema,
    fieldConfigsQuery,
    memoizedFieldConfigs,
    isPreviewMode
  ])
}

export default usePreviewHandlers
