export default () => {
  return {
    title: 'Markdown Editor',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          text: {
            type: 'string',
            title: 'Label Text'
          },
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color'
          },
          saveButton: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Show Save Button'
          },
          editorType: {
            type: 'string',
            default: 'wysiwyg',
            title: 'Editor Type',
            enum: ['wysiwyg', 'both'],
            required: true
          },
          toolbarSettings: {
            type: 'string',
            default: 'default',
            title: 'Toolbar Settings',
            enum: ['default', 'advanced'],
            required: true
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          textColumn: {
            type: 'string',
            title: 'Text',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
