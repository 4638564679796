import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { slvyToast, Title } from '../../../components'
import { setLoader as setActionLoader } from '../store/actions'
import {
  setLoader,
  setDraftToState,
  getRequestConfig,
  hasSameColumnType,
  _mergeConfig,
  getAllPivotBuilderStates
} from '../utils'
import { getMergedPluginState, getModifiedConfig, getDefaultPluginState } from '../components/Util'
import { MESSAGES } from '../messages'

class CommonPivotBuilder extends Component {
  constructor(props) {
    super(props)

    this.pivotBuilderInfo = {
      info: MESSAGES.pivot_builder,
      create: {
        action: 'info',
        msg: MESSAGES.new_state_has_been_created
      },
      changes: {
        action: 'success',
        msg: MESSAGES.your_changes_have_been_saved_successfully
      },
      noChanges: {
        action: 'warning',
        msg: MESSAGES.you_must_have_changes_to_save
      }
    }
  }

  toggleLoader(isVisible = false, msg = 'Loading') {
    setLoader(this.props.setActionLoader, isVisible, msg)
  }

  getModifiedConfig($config) {
    return getModifiedConfig($config)
  }

  getDefaultPluginState($config) {
    return getDefaultPluginState(this.props, $config)
  }

  getDefaultPivotBuilderState() {
    const pivotBuilderPluginStates = this.getPivotBuilderStates()
    const _l = !_.isEmpty(pivotBuilderPluginStates)
    const defaultPivotState = pivotBuilderPluginStates.find(
      (item) => item.config.stateSettings && item.config.stateSettings.isDefault
    )

    return _l && defaultPivotState ? defaultPivotState : null
  }

  getSelectedPivotBuilderState() {
    const pivotBuilderPluginStates = this.getPivotBuilderStates()
    const _l = !_.isEmpty(pivotBuilderPluginStates)

    const selectedPivotOptions = JSON.parse(
      localStorage.getItem(`dp-PivotBuilderState-${this.props.id}`)
    )
    if (_l && selectedPivotOptions) {
      const { id = null } = selectedPivotOptions
      return pivotBuilderPluginStates.find((item) => item.name === id) || null
    }

    return null
  }

  getPivotBuilderStates(props = this.props, withDraft = true) {
    const { pluginStates: { data: pluginStates = [] } = {} } = props
    return withDraft
      ? setDraftToState(props, getAllPivotBuilderStates({ pluginStates }))
      : getAllPivotBuilderStates({ pluginStates })
  }

  deletePivotBuilderState($pluginStateId, $callback) {
    this.props
      .deletePluginState(`delete_pivot_builder_${this.props.id}`, this.props.id, $pluginStateId)
      .then(() => {
        const pivotMainConfiguration = this.getInitialPivotMainCfg()

        this.savePivotAction(pivotMainConfiguration).then(() => {
          // Create plugin state with default config
          this.syncDefault(true, true, () => $callback({ isDeleted: true }))
        })
      })
  }

  createDraftPivotBuilderState($config, $extra = {}) {
    const AggregationFunctions = _.get(
      this.props,
      'GetPivotConfiguration.Result.AggregationFunctions',
      ['SUM']
    )

    const { callback = () => {}, updatedWithState = null, shouldDraft = true } = $extra

    $config = this.getModifiedConfig(_.cloneDeep($config))

    updatedWithState.config.state = $config

    const { LeftFields: newLeftFields = [] } = getRequestConfig($config, AggregationFunctions)

    const draftCallback = () => {
      this.setPivotBuilderItem(updatedWithState)
      this.props.reloadAfterSave(false, updatedWithState, $config, newLeftFields, callback)
    }

    if (shouldDraft) {
      this.props._savePivotBuilderViewSettings({ draftState: _.cloneDeep(updatedWithState) }, () =>
        draftCallback()
      )
    } else {
      draftCallback()
    }
  }

  createPivotBuilderState($config, $extra = {}) {
    const { id = '' } = this.props

    const {
      callback = () => {},
      stateSettings = {
        name: 'Default',
        isDefault: true
      }
    } = $extra

    $config = this.getModifiedConfig(_.cloneDeep($config))

    const pluginState = this.getDefaultPluginState($config)

    // if there are no state, create state as default and selected
    pluginState.config.state = $config
    pluginState.config.stateSettings = stateSettings

    this.props.createPluginState(`create_pivot_builder_${id}`, id, pluginState).then(() => {
      const text = this.pivotBuilderInfo.create
      slvyToast[text.action]({
        message: text.msg,
        title: this.pivotBuilderInfo.info,
        options: { containerId: 'dp' }
      })
      callback({ newStateId: pluginState.config.stateId, isSuccess: true })
    })
  }

  updatePivotBuilderState($config, $extra = {}) {
    const { id = '' } = this.props
    const AggregationFunctions = _.get(
      this.props,
      'GetPivotConfiguration.Result.AggregationFunctions',
      ['SUM']
    )

    const {
      callback = () => {},
      shouldReload = true,
      showToastr = true,
      updatedWithState = null
    } = $extra

    $config = this.getModifiedConfig(_.cloneDeep($config))

    const currentInAll = this.getStateByStateId(updatedWithState.config.stateId, false)

    updatedWithState.config.state = $config

    const { LeftFields: newLeftFields = [] } = getRequestConfig($config, AggregationFunctions)

    const _onUpdateDone = (text, info) => {
      showToastr &&
        slvyToast[text.action]({ message: text.msg, title: info, options: { containerId: 'dp' } })
      if (shouldReload) {
        this.setPivotBuilderItem(updatedWithState)
      } else {
        callback({ isSuccess: true })
      }
    }

    const hasChanges = !_.isEqual(updatedWithState, currentInAll)
    if (hasChanges) {
      this.props
        .updatePluginState(`update_pivot_builder_${id}`, updatedWithState.id, updatedWithState)
        .then(() => _onUpdateDone(this.pivotBuilderInfo.changes, this.pivotBuilderInfo.info))
    } else {
      _onUpdateDone(this.pivotBuilderInfo.noChanges, this.pivotBuilderInfo.info)
    }

    if (shouldReload) {
      this.props.reloadAfterSave(true, updatedWithState, $config, newLeftFields, callback)
    }
  }

  getPluginPivotConfig() {
    const {
      settings: { config: { pivotBuilder: { configuration: $pluginConfig = [] } = {} } = {} } = {}
    } = this.props

    const _$pluginConfig = {}

    $pluginConfig
      .map((cfg) => {
        cfg.Name = cfg.Name === 'Main' ? 'DefaultAxisDraggable' : cfg.Name
        return cfg
      })
      .filter((cfg) => cfg.Name !== 'Main')
      .forEach((cfg) => (_$pluginConfig[cfg.Name] = cfg.List))

    return _$pluginConfig
  }

  getPluginStatePivotConfig($pluginState) {
    const pluginStateConfig = _.get($pluginState, 'config.state', {})
    return this.getModifiedConfig(_.cloneDeep(pluginStateConfig))
  }

  setPivotBuilderItem($data) {
    const { name: id = '', config: { stateSettings: { isDefault = false, name = '' } = {} } = {} } =
      $data || {}
    const result = { id, isDefault, name }
    localStorage.setItem(`dp-PivotBuilderState-${this.props.id}`, JSON.stringify(result))
  }

  getTargetState($hasPluginState, all, pluginState) {
    const { preferredUsername = '' } = this.props
    if (!$hasPluginState) {
      return pluginState
    }
    const existingState = all.filter((item) => item.user === preferredUsername)
    return existingState.length ? _.last(existingState) : _.first(all)
  }

  syncDefault($markAsDefault, $shouldSync, $callback) {
    if (!$shouldSync) {
      $callback({ isCreated: false })
      return
    }

    const { id } = this.props
    const all = this.getPivotBuilderStates()
    const hasPluginState = !_.isEmpty(all)

    const defaultState = this.getDefaultPivotBuilderState()
    const hasDefault = hasPluginState && !_.isEmpty(defaultState)

    const pivotMainCfg = this.getInitialPivotMainCfg()

    if (!hasDefault) {
      // CREATE DEFAULT
      const newState = this.getDefaultPluginState(pivotMainCfg)
      newState.config.stateSettings = { isDefault: true, name: 'Default' }

      this.props.createPluginState(`create_pivot_builder_${id}`, id, newState).then(() => {
        this.setPivotBuilderItem(this.getTargetState(hasPluginState, all, newState))
        $callback({ isCreated: true })
      })
    } else if (!_.isEmpty(pivotMainCfg) && !_.isEqual(defaultState.config.state, pivotMainCfg)) {
      // KEEP DEFAULT UPDATED
      defaultState.config.state = pivotMainCfg
      this.props
        .updatePluginState(`update_pivot_builder_${id}`, defaultState.id, defaultState)
        .then(() => {
          this.setPivotBuilderItem(defaultState)
          $callback({ isUpdated: true })
        })
    } else if ($markAsDefault) {
      this.setPivotBuilderItem(defaultState)
      $callback({ isUpdated: true })
    } else {
      $callback({ isUpdated: true })
    }
  }

  initialSet() {
    const all = this.getPivotBuilderStates()
    const savedState = JSON.parse(localStorage.getItem(`dp-PivotBuilderState-${this.props.id}`))
    if (all.length) {
      if (savedState) {
        const isExist = all.find((item) => item.name === savedState.id)
        // user can have a public state and public state can remove by anyone (who has auth)
        if (!isExist) {
          this.setPivotBuilderItem(_.first(all))
        }
      } else {
        this.setPivotBuilderItem(_.first(all))
      }
    }
  }

  getInitialPivotMainCfg() {
    const { GetPivotConfiguration: { Result: { Fields: $pivotConfig = [] } = {} } = {} } =
      this.props

    const $pluginConfig = this.getPluginPivotConfig()
    const $modifiedCfg = _.cloneDeep(_mergeConfig($pivotConfig, $pluginConfig)) || {}
    return this.getModifiedConfig($modifiedCfg)
  }

  getStateByStateId($stateId, $withDraft = true) {
    const pluginStates = _.cloneDeep(this.getPivotBuilderStates(this.props, $withDraft))
    return pluginStates.find((item) => item.config.stateId === $stateId)
  }

  onPivotStateChanged({ stateId = '' }) {
    this.toggleLoader(true)
    this.props._savePivotBuilderViewSettings({ draftState: {} }, () => {
      const selectedPluginState = this.getStateByStateId(stateId)
      this.executePivotMainConfiguration(
        ({ isOk }) => {
          if (isOk) {
            this.updatePivotBuilderState(
              selectedPluginState.config.state,
              {
                showToastr: false,
                shouldReload: true,
                updatedWithState: selectedPluginState
              },
              () => {}
            )
          }
          this.toggleLoader(false)
        },
        selectedPluginState,
        false
      )
    })
  }

  executePivotMainConfiguration($callback, $draftState = null, shouldSync = true) {
    const { GetPivotConfiguration: { Result: { Fields: $pivotConfig = [] } = {} } = {} } =
      this.props

    // KEEP USER STATE
    if (!$pivotConfig.length) {
      slvyToast.warning({
        message: MESSAGES.please_check_your_internet_connection,
        title: MESSAGES.no_pivot_configuration,
        options: { containerId: 'dp' }
      })
      setTimeout(() => window.location.reload(), 1000)
      return
    }
    // KEEP USER STATE
    this.initialSet()

    this.syncDefault(false, shouldSync, () => {
      const $pluginConfig = this.getPluginPivotConfig()
      const pivotMainCfg = this.getInitialPivotMainCfg()
      const all = this.getPivotBuilderStates()
      const $hasPluginState = !_.isEmpty(all)

      if ($hasPluginState) {
        const $pluginState = $draftState || this.getSelectedPivotBuilderState()
        const pluginStateConfig = this.getPluginStatePivotConfig($pluginState)

        const { columnsDeleted, columnsChanged, columnsChangedMessages, changedColumns } =
          hasSameColumnType(pivotMainCfg, pluginStateConfig)

        // Confirm and delete plugin state then load with default config
        if (columnsDeleted || columnsChanged) {
          const element = (
            <>
              <Title>{MESSAGES.missing_pivot_state_columns}</Title>
              {columnsChangedMessages.map((msg) => (
                <React.Fragment key={msg}>
                  {msg}
                  <br />
                </React.Fragment>
              ))}
            </>
          )

          slvyToast.info({
            component: element,
            options: { containerId: 'dp', autoClose: 5000 }
          })

          const reducedPluginState = {}
          Object.keys(pluginStateConfig).forEach((pscKey) => {
            reducedPluginState[pscKey] = [...pluginStateConfig[pscKey]].filter((pscItem) => {
              return changedColumns.find((changedColumn) => changedColumn.Name !== pscItem.Name)
            })
          })
          this.savePivotAction(getMergedPluginState($pluginConfig, reducedPluginState))
          $callback({ isOk: true })

          // Load with merged (user state and plugin config) result
        } else {
          this.savePivotAction(getMergedPluginState($pluginConfig, pluginStateConfig))
          $callback({ isOk: true })
        }
        // Load and create with default config
      } else {
        this.savePivotAction(pivotMainCfg)

        // Create plugin state with default config
        $callback({ isOk: true })
      }
    })
  }

  savePivotAction($pivotMainCfg) {
    return this.props.savePivotMainConfiguration({
      payload: {
        ...$pivotMainCfg
      }
    })
  }

  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return this.props.children({
      getSelectedPivotBuilderState: this.getSelectedPivotBuilderState.bind(this),
      getPivotBuilderStates: this.getPivotBuilderStates.bind(this),
      deletePivotBuilderState: this.deletePivotBuilderState.bind(this),
      savePivotAction: this.savePivotAction.bind(this),
      createDraftPivotBuilderState: this.createDraftPivotBuilderState.bind(this),
      executePivotMainConfiguration: this.executePivotMainConfiguration.bind(this),
      onPivotStateChanged: this.onPivotStateChanged.bind(this),
      updatePivotBuilderState: this.updatePivotBuilderState.bind(this),
      createPivotBuilderState: this.createPivotBuilderState.bind(this),
      getInitialPivotMainCfg: this.getInitialPivotMainCfg.bind(this)
    })
  }
}

const mapStateToProps = (state) => {
  return {
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    Loader: state.loader.loader
  }
}

const mapDispatchToProps = {
  setActionLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonPivotBuilder)
