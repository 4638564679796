import * as actionTypes from './actionTypes'

export const selectProductGraphValue = (graphName) => {
  return {
    type: actionTypes.SELECT_PRODUCT_GRAPH_VALUE,
    graphName
  }
}

// new production view
export const PRODUCTselectProductGraphValue = (graphName) => {
  return {
    type: actionTypes.PRODUCT_SELECT_PRODUCT_GRAPH_VALUE,
    graphName
  }
}
