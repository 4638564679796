Ext.define('MDM.src.view.regionapp.Panel.MaterialEditForm', {
  extend: 'Ext.form.Panel',
  xtype: 'form-checkout',
  bodyPadding: 10,
  // Uncomment to give this component an xtype
  // xtype : '-materialeditform',
  fieldDefaults: {
    labelAlign: 'right',
    labelWidth: 90,
    msgTarget: Ext.supports.Touch ? 'side' : 'qtip'
  },
  items: [
    {
      xtype: 'container',
      layout: {
        type: 'hbox'
      },
      items: [
        {
          xtype: 'fieldset',
          title: 'Material Info',
          defaultType: 'textfield',
          layout: 'anchor',
          flex: 1,
          defaults: {
            anchor: '100%'
          },
          items: [
            {
              fieldLabel: 'Code',
              xtype: 'textfield',
              name: 'Code',
              flex: 1,
              readOnly: true,
              disabled: true,
              bind: '{rec.MaterialCode}',
              emptyText: 'Material code'
            },
            {
              fieldLabel: 'Description',
              name: 'firstName',

              bind: '{rec.MaterialDescription}',
              flex: 2,
              xtype: 'textfield',
              readOnly: true,
              disabled: true
            },
            {
              fieldLabel: 'Category',
              bind: '{rec.Category}',
              readOnly: true,
              xtype: 'textfield',
              disabled: true,
              flex: 1
            },
            {
              fieldLabel: 'Brand',
              xtype: 'textfield',

              bind: '{rec.Brand}',
              readOnly: true,
              disabled: true,
              flex: 2
            }
          ]
        },
        {
          xtype: 'container',
          width: 150,
          height: 208,
          layout: 'center',
          border: true,
          margin: '20 5 20 5',
          style: 'border: 1px solid #bbb !important;',

          items: [
            {
              xtype: 'image',
              itemId: 'image',
              id: 'displayimage',
              shrinkWrap: true,
              minWidth: 114,
              minWeight: 120,
              maxWidth: 200,
              maxWeight: 220,

              height: 200,

              cls: 'photo-profile',
              src: 'https://cdn.voyager.pgsitecore.com/tr-tr/-/media/Gillette_TR/Images/Products/Fusion%20Proshield%20Razor%20with%20Flexball/gillette-fusion-proshield-razor-with-flexball-pl.jpg?h=280&la=tr-TR&w=114&v=1-201610061200'
            }
          ]
        }
      ],
      height: 250
    },
    {
      xtype: 'fieldset',
      title: '<b>Attributes</b>',
      reference: 'Attributes',

      defaultType: 'textfield',
      layout: 'anchor',
      defaults: {
        anchor: '100%'
      },

      items: [
        {
          xtype: 'textfield',
          fieldLabel: 'Form Detail',
          name: 'firstName',
          bind: '{rec.ProductFormDetail}',
          flex: 2,
          readOnly: true,
          disabled: true
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',

          items: [
            {
              xtype: 'textfield',
              fieldLabel: 'BUOM',
              name: 'firstName',
              bind: '{rec.BUOM}',
              flex: 1,
              readOnly: true,
              disabled: true
            },
            {
              xtype: 'textfield',
              fieldLabel: 'CSU Ind.',
              name: 'firstName',
              bind: '{rec.CSUIndicator}',
              readOnly: true,
              disabled: true,
              flex: 1
            }
          ]
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',

          items: [
            {
              xtype: 'textfield',
              fieldLabel: 'Modified By',
              name: 'mailingCity',
              reference: 'mailingCity',
              readOnly: true,
              disabled: true,
              flex: 1,
              allowBlank: false
            },
            {
              xtype: 'textfield',
              fieldLabel: 'Date',
              //labelWidth:120,
              name: 'mailingState',
              reference: 'mailingState',
              flex: 1,
              readOnly: true,
              disabled: true,
              forceSelection: true,
              enforceMaxLength: true,
              labelWidth: 50,
              valueField: 'abbr',
              displayField: 'abbr',
              typeAhead: true,
              queryMode: 'local',
              allowBlank: false,
              listConfig: {
                minWidth: null
              }
            }
          ]
        }
      ]
    },
    {
      xtype: 'fieldset',
      title: 'Editable Attributes',
      reference: 'Attributes',

      defaultType: 'textfield',
      layout: 'anchor',
      defaults: {
        anchor: '100%'
      },
      items: [
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',

          items: [
            {
              xtype: 'textfield',
              fieldLabel: 'SU',
              name: 'mailingCity',
              bind: '{rec.SU}',
              reference: 'mailingCity',
              flex: 1,
              allowBlank: false
            },
            {
              xtype: 'textfield',
              fieldLabel: 'GU´s',
              bind: '{rec.GU_Default}',
              name: 'mailingState',
              reference: 'mailingState',
              flex: 1,

              forceSelection: true,
              enforceMaxLength: true,
              labelWidth: 50,
              valueField: 'abbr',
              displayField: 'abbr',
              typeAhead: true,
              queryMode: 'local',
              allowBlank: false,
              listConfig: {
                minWidth: null
              }
            }
          ]
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',

          items: [
            {
              xtype: 'textfield',
              fieldLabel: 'Source Name',
              name: 'mailingCity',
              reference: 'mailingCity',
              bind: '{rec.SupplySourceName}',
              flex: 1,
              allowBlank: false
            },
            {
              xtype: 'textfield',
              fieldLabel: 'Material Type',
              name: 'mailingCity',
              reference: 'mailingCity',
              bind: '{rec.MaterialType}',
              flex: 1,
              allowBlank: false
            }
          ]
        },
        {
          fieldLabel: 'BUOM',
          xtype: 'textfield',
          name: 'BUOM',
          reference: 'BUOM',
          flex: 1,
          allowBlank: false
        }
      ]
    }
  ]
})
