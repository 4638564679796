export default `({
    hidden:true,
    items: [{
        title: tt('Spot Paremetreleri'),
        //cls:'prodfuctInfo',
        xtype: 'fieldset',
        border: true,
        style: {
            //  padding: '0px 15px 10px 15px'
        },
        fieldDefaults: {
            labelAlign: 'left',
            labelWidth: 130,
            msgTarget: 'side',
        },
        items: [{
                xtype: 'fieldcontainer',
                labelStyle: 'font-weight:bold;padding:0;',
                layout: 'hbox',
                defaultType: 'numberfield',
                items: [{
                        flex: 1,
                        name: 'middleInitial',
                        fieldLabel: tt('Units Per Package'),
                        tooltip: tt('Magaza minimum siparis birim'),
                        minValue: 0,
                        step: 1,
                        bind: {
                            value: '{sumRecord.OrderRecommendation_DC_UpdatedbyMO}',
                            // minValue: Ext.Date.add('{spotRecord.PreStartDate}', Ext.Date.DAY, 21),
                        },
                    }, {
                        flex: 1,
                        // afterLabelTextTpl: [
                        //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                        // ],
                        fieldLabel: 'Mag.Sevk Max.Miktar',
                        margin: '0 0 0 5',
                        minValue: 0,
                        step: 1,
                        bind: {
                            value: '{spotRecord.MaxQuantity}'
                        },
                    },
                    {
                        flex: 1,
                        // afterLabelTextTpl: [
                        //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                        // ],
                        fieldLabel: 'Mag.Sevk Max.Miktar',
                        margin: '0 0 0 5',
                        minValue: 0,
                        step: 1,
                        bind: {
                            value: '{spotRecord.MaxQuantity}'
                        },
                    },
                    {
                        flex: 1,
                        // afterLabelTextTpl: [
                        //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                        // ],
                        fieldLabel: 'Mag.Sevk Max.Miktar',
                        margin: '0 0 0 5',
                        minValue: 0,
                        step: 1,
                        bind: {
                            value: '{spotRecord.MaxQuantity}'
                        },
                    }
                ]
            },
            {
                xtype: 'fieldcontainer',
                labelStyle: 'font-weight:bold;padding:0;',
                layout: 'hbox',
                defaultType: 'textfield',
                items: [{
                    flex: 1,
                    name: 'middleInitial',
                    fieldLabel: tt('Start Date'),
                    afterLabelTextTpl: [
                        '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                    ],
                    xtype: 'datefield',
                    format: tt('d-m-Y'),
                    disabledDays: [0, 1, 2, 3, 5, 6, 7],
                    minValue: new Date(),
                    allowBlank: false,
                    listeners: {
                        changes: function (field, value) {
                            var form = field.up('form');
                            var PreEndDateField = field.nextNode('datefield(true)'),
                                PreStartDateValue = Ext.Date.add(value, Ext.Date.DAY, 21);
                            PreEndDateField.setValue(PreStartDateValue);
                        }
                    },
                    bind: {
                        value: '{spotRecord.PreStartDate}'
                    },
                }, {
                    flex: 1,
                    name: 'PreEndDate',
                    afterLabelTextTpl: [
                        '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                    ],
                    fieldLabel: tt('Bitis Tarihi'),
                    format: tt('d-m-Y'),
                    margin: '0 0 0 5',
                    // xtype: 'textfield',
                    disabledDays: [0, 1, 2, 3, 5, 6, 7],
                    allowBlank: false,
                    disabled:true,
                    bind: {
                        value: '{sumRecord.OrderRecommendation_DC_UpdatedbyMO}',
                        // minValue: Ext.Date.add('{spotRecord.PreStartDate}', Ext.Date.DAY, 21),
                    },
                }]
            },
            // {
            //     xtype: 'fieldcontainer',
            //     labelStyle: 'font-weight:bold;padding:0;',
            //     layout: 'hbox',
            //     defaultType: 'textfield',
            //     items: [{
            //         flex: 1,
            //         name: 'middleInitial',
            //         fieldLabel: 'CreatedBy',
            //         disabled: true
            //     }, {
            //         flex: 1,
            //         name: 'lastName',
            //         // afterLabelTextTpl: [
            //         //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
            //         // ],
            //         fieldLabel: 'CreatedAt',
            //         allowBlank: false,
            //         disabled: true,
            //         margin: '0 0 0 5'
            //     }]
            // }
        ]
    }]
})
`
