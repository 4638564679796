import { getIsSubstitute } from '../index'
import { customInfo as defaultCustomInfo } from './mocks'

const getCustomInfo = () => {
  return defaultCustomInfo
}

describe('AgGrid:Helpers:Lookup:getIsSubstitute', () => {
  let customInfo = getCustomInfo()
  beforeEach(() => {
    customInfo = getCustomInfo()
  })

  it('should return true if customInfo?.fieldLookupConfig?.substituteField is a truthy value', () => {
    customInfo.fieldLookupConfig.substituteField = 'textCol'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsSubstitute(customInfo)
    expect(result).toBe(true)
  })
  it('should return false if customInfo?.fieldLookupConfig?.substituteField not a truthy value', () => {
    customInfo.fieldLookupConfig.substituteField = ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsSubstitute(customInfo)
    expect(result).toBe(false)
  })
  it('should return false if customInfo?.fieldLookupConfig?.substituteField is not defined', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customInfo.fieldLookupConfig = { isLookupQuery: true }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsSubstitute(customInfo)
    expect(result).toBe(false)
  })
})
