export default {
  title: 'Treemap',
  subTitle: 'Highcharts - Treemap',
  version: '1.0',
  namespace: 'Treemap',
  description: 'Treemap from Highcharts (www.highcharts.com)',
  iconClass: 'fa-tree',
  color: 'lazur-bg',
  support: {
    maximizable: true
  },
  group: 'Charts'
}
