import { IRowNode } from 'ag-grid-community'
import { getValidRowRule } from '../index'
import { cellRendererParams, validRowRule } from '../../__tests__/mocks'

function getParams() {
  return cellRendererParams
}

describe('AgGrid:Helpers:Row:getValidRowRule', () => {
  let params = getParams()

  beforeEach(() => {
    params = getParams()
    params.value = validRowRule.value
    params.data.col1 = validRowRule.value
    params.node = {
      data: params.data,
      allLeafChildren: [{ data: params.data }],
      group: false
    } as IRowNode
  })

  it('should return null if given rowDisplayRules is empty', () => {
    const result = getValidRowRule({ params, rowDisplayRules: [] })
    expect(result).toEqual(null)
  })

  it('should return valid row rule if there is a valid one', () => {
    params.value = validRowRule.value
    params.data.col1 = validRowRule.value
    const result = getValidRowRule({ params, rowDisplayRules: [validRowRule] })
    expect(result).toEqual(validRowRule)
  })
})
