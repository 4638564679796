import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import HeaderButton from './HeaderButton'
import { getAllPivotBuilderStates } from '../../utils'
import { MESSAGES } from '../../messages'
import { savePivotBuilderViewSettings } from '../../store/actions'

interface IPivotBuilderDropdown {
  isVisiblePivotBuilderButton: boolean
  onPivotStateChanged(param: { stateId: any }): void
  onViewChangedCallback(param: { View: 'PivotBuilder' }): void
  pluginId: string
  pluginStates: any[]
}

function PivotBuilderDropdown(props: IPivotBuilderDropdown) {
  const {
    isVisiblePivotBuilderButton = false,
    onPivotStateChanged,
    onViewChangedCallback,
    pluginId
  } = props

  const dispatch = useDispatch()
  const allPluginStates = getAllPivotBuilderStates(props)

  function displayPivotBuilder(isVisible: boolean) {
    dispatch(
      savePivotBuilderViewSettings({
        payload: {
          isVisible
        }
      })
    )
    if (isVisible) {
      onViewChangedCallback({ View: 'PivotBuilder' })
    }
  }

  function onStateChanged(event: ChangeEvent<HTMLSelectElement>) {
    const { target: { value: stateId = '' } = {} } = event
    onPivotStateChanged({ stateId })
  }

  function getSelectedValue(pivotBuilderPluginStates: any[]) {
    const selectedPivotOptions = JSON.parse(
      localStorage.getItem(`dp-PivotBuilderState-${pluginId}`) || '{}'
    )
    let stateId = ''
    if (selectedPivotOptions) {
      const { id = null } = selectedPivotOptions
      stateId = pivotBuilderPluginStates.find((item) => item.name === id)?.config?.stateId ?? ''
    }

    return stateId
  }

  const selectedValue = getSelectedValue(allPluginStates)

  return (
    <div className="selectable-button">
      <HeaderButton
        extraClass="-change-pivot-builder"
        icon="slvy-ui-icon-dot1"
        isVisible={isVisiblePivotBuilderButton}
        label={MESSAGES.pivot_builder}
        onClickHandle={() => displayPivotBuilder(true)}
      />
      {allPluginStates.length ? (
        <div className="select-trigger">
          <i className="slvy-ui-icon-caret-down" />
          <select value={selectedValue} onChange={onStateChanged}>
            {allPluginStates.map((pbState: any, pbIndex: any) => {
              return (
                <option key={pbIndex} value={pbState.config.stateId}>
                  {pbState.config.stateSettings.name}
                </option>
              )
            })}
          </select>
        </div>
      ) : null}
    </div>
  )
}

export default PivotBuilderDropdown
