import { getHeaderTooltip } from '../index'
import { GetHeaderTooltipReturnType } from '../tooltip.types'

describe('AgGrid:Helpers:Tooltip', () => {
  it('should return empty object if configHeaderTooltip contains {', () => {
    const result = getHeaderTooltip({
      autoTooltip: true,
      headerName: 'Age',
      configHeaderTooltip: 'col1{'
    })
    expect(result).toEqual({})
  })
  it('should return configHeaderTooltip if it does not contains {', () => {
    const result = getHeaderTooltip({
      autoTooltip: true,
      headerName: 'Age',
      configHeaderTooltip: 'col1'
    }) as GetHeaderTooltipReturnType
    expect(result?.headerTooltip).toEqual('col1')
  })
  it('should return headerName if configHeaderTooltip is empty and autoTooltip true', () => {
    const result = getHeaderTooltip({
      autoTooltip: true,
      headerName: 'Age',
      configHeaderTooltip: ''
    }) as GetHeaderTooltipReturnType
    expect(result.headerTooltip).toEqual('Age')
  })
  it('should return null if configHeaderTooltip is empty and autoTooltip false', () => {
    const result = getHeaderTooltip({
      autoTooltip: false,
      headerName: 'Age',
      configHeaderTooltip: ''
    })
    expect(result).toEqual({})
  })
})
