/**
 * Serie class for area spline range series type
 *
 * See {@link Chart.ux.Highcharts.RangeSerie} class for more info
 *
 */
Ext.define('Chart.ux.Highcharts.AreaSplineRangeSerie', {
  extend: 'Chart.ux.Highcharts.RangeSerie',
  alternateClassName: ['highcharts.areasplinerange'],
  type: 'areasplinerange'
})
