Ext.define('Spui.view.wizard.Breadcrumbs.Breadcrumbs', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-wizard-breadcrumbs-breadcrumbs',
  style: {
    backgorund: '#0071a7',
    'border-bottom': '2px solid #0071a7'
  },
  height: 48,
  tpl: [
    '  <div class="spui-breadcrumbs">',

    '    <div class="inner">',
    '      <ul class="cf">',
    '       <tpl for=".">',
    '           <li>',
    '            <a class="{Status}">',
    '             <span>{PageIndex}</span>',
    '             <span>{Name}</span>',
    '             </a>',
    '            </li>',
    '       </tpl>',
    '      </ul>',
    '    </div>',
    '  </div>'
  ]
})
