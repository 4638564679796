import React from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: props.show
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  render() {
    const {
      props: { steps = [], onStepClicked = () => {} },
      state: { show = true }
    } = this

    const _steps = [...steps]
    const activeStep = _steps.filter((step) => step.isActive)[0]
    const activeStepId = activeStep.id

    return show ? (
      <div className="wizardHeader-steps">
        {_steps.map((item, index) => {
          const usedIndex = index + 1
          return (
            <Button
              key={usedIndex}
              disabled={usedIndex > activeStepId}
              size="sm"
              variant={activeStepId === usedIndex ? 'success' : 'outline-dark'}
              onClick={onStepClicked.bind(this, usedIndex)}
            >
              {usedIndex}
            </Button>
          )
        })}
      </div>
    ) : null
  }
}

export default Header
