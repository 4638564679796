Ext.define('MDM.src.view.massimport.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.massimport-mainviewmodel',
  stores: {
    /*
    A declaration of Ext.data.Store configurations that are first processed as binds to produce an effective
    store configuration. For example:
    users: {
        model: 'User',
        autoLoad: true
    }
    */
  },
  formulas: {
    importTypeTitle: {
      bind: {
        bindTo: '{importType}',
        deep: true
      },
      get: function (importType) {
        if (importType) return importType.rawValue
        return ''
      }
    }
  },
  data: {
    importType: null,
    isReadOnly: true,
    owner: 'Region'
  }
})
