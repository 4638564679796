import { Component } from 'react'

import { SlvySelect } from '../../../../../components'

export default class DestinationSelect extends Component {
  componentDidMount() {
    const { onMount } = this.props

    onMount()
  }

  render() {
    const { data, isLoading, mapData, selectValue, title, onSelect } = this.props

    return (
      <div className="destination-select">
        <span className="title">{title}</span>
        <section className="selection">
          <SlvySelect
            isLoading={isLoading}
            menuPlacement="top"
            options={mapData(data)}
            placeholder="Select..."
            value={selectValue}
            onChange={onSelect}
          />
        </section>
      </div>
    )
  }
}
