import * as actionTypes from './actionTypes'

export const saveQuickFilterSettings = (options) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storeQuickFilterSettings(options))
      resolve()
    })
  }
}

const storeQuickFilterSettings = (options) => {
  return {
    type: actionTypes.STORE_QUICK_FILTER_SETTINGS,
    payload: options.payload
  }
}

export const resetQuickFilterSettings = (options) => {
  return {
    type: actionTypes.RESET_QUICK_FILTER_SETTINGS,
    payload: options.payload
  }
}
