Ext.define('clustering.Model.FilterModel', {
  extend: 'Ext.data.Model',
  idProperty: 'Key',
  fields: [{ name: 'Key' }, { name: 'Value' }],
  listeners: {
    beforeload: function (store) {
      var proxy = store.getProxy()
      proxy.setExtraParam('catalogId', clustering.Global.getCatalogId())
    }
  }
})
