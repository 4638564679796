import { Component } from 'react'
import { SketchPicker } from 'react-color'
import './Picker.scss'

export default class Picker extends Component {
  constructor(props) {
    super(props)
    this.handleChangeColor = this.handleChangeColor.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.state = {
      displayColorPicker: false
    }
  }

  getValue() {
    return this.props.value
  }

  componentToHex(c) {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  rgbToHex(r, g, b, a) {
    return `#${this.componentToHex(r).toUpperCase()}${this.componentToHex(
      g
    ).toUpperCase()}${this.componentToHex(b).toUpperCase()} ${parseInt(a * 100, 10)}%`
  }

  rgb2hex(rgba) {
    let inputValue = rgba
    inputValue = inputValue.replace('rgba(', '')
    inputValue = inputValue.replace(')', '')
    const values = inputValue.split(',')

    return this.rgbToHex(
      parseInt(values[0], 10),
      parseInt(values[1], 10),
      parseInt(values[2], 10),
      parseFloat(values[3])
    )
  }

  getFormattedValue() {
    if (this.props.value) {
      if (this.props.value.startsWith('#')) {
        return `${this.props.value.toUpperCase()} ${100}%`
      }
      return this.rgb2hex(this.props.value)
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleChangeColor = (color) => {
    const colorCode = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    this.props.onChange(colorCode)
  }

  handleClear() {
    this.props.onChange('')
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '9'
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
    const color = {
      width: '100%',
      height: '19px',
      borderRadius: '2px',
      background: this.props.value
    }
    const swatch = {
      padding: '0',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      width: this.props.format === 'NullColor' ? '94%' : '100%'
    }

    return (
      <div className="form-group colorPicker">
        <label className=" col-form-label">{this.props.title}</label>
        <div className="picker">
          <div className="colorBox" style={swatch} onClick={this.handleClick}>
            <div style={color} />
          </div>
          {this.props.format === 'NullColor' ? (
            <button>
              <i className="fa fa-times" onClick={this.handleClear} />
            </button>
          ) : null}
          {this.state.displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={this.handleClose} />
              <SketchPicker color={this.getValue()} onChange={this.handleChangeColor} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
