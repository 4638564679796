import React, { ReactNode } from 'react'

const MainContainer = ({
  children = null,
  extraClasses = ''
}: {
  children: ReactNode
  extraClasses?: string
}) => {
  return (
    <main className={`dp-main ${extraClasses}`}>
      <div className="dp-plugin-container">
        <div className="dp-grid-holder">{children}</div>
      </div>
    </main>
  )
}

export default MainContainer
