import { put, take } from 'redux-saga/effects'
import { CHANGE_CONNECTION } from '../actionTypes'
import { setIsConnectionChangeActive } from '@/store/slices/setting'

export default function* changeConnection() {
  yield put(setIsConnectionChangeActive(true))
  while (true) {
    const result = yield take(CHANGE_CONNECTION)
    return result
  }
}
