export default {
  title: 'Bootstrap-Form',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'BootstrapForm',
  description: 'Extended form',
  iconClass: 'fa-align-center',
  color: 'lazur-bg',
  group: 'Data Manipulation',
  support: {
    maximizable: true,
    editable: true
  },
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: false
  }
}
