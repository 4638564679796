import { MESSAGES } from './messages'

export default [
  {
    value: '-1',
    name: MESSAGES.approve_settings
  },
  {
    value: '1',
    name: MESSAGES.approved
  },
  {
    value: '0',
    name: MESSAGES.not_approved
  }
]
