import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { filterValues as filterValuesFunc } from '../../../../../actions'
import MenuItem from './MenuItem'

class Menu extends React.Component {
  constructor() {
    super()
    this.state = {
      isToggleOn: false,
      isTriggerHovered: false,
      hoveredItemIndex: null,
      clickedSubItemIndex: null
    }

    this.handleToggleMenu = this.handleToggleMenu.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleItemMouseEnter = this.handleItemMouseEnter.bind(this)
    this.handleSubItemClick = this.handleSubItemClick.bind(this)
  }

  handleMouseEnter() {
    this.setState({ isTriggerHovered: true })
  }

  handleMouseLeave() {
    this.setState({ isTriggerHovered: false })
  }

  handleToggleMenu() {
    this.props.onClick()
  }

  handleItemMouseEnter(i) {
    this.setState({ hoveredItemIndex: i })
  }

  handleSubItemClick(filterName, isResourceBased, operator, value, format, isChange) {
    this.props.filterValuesFunc(
      this.props.periodIndex,
      filterName,
      isResourceBased,
      operator,
      value,
      format,
      isChange
    )
  }

  render() {
    const {
      legend: {
        productLegendData: { resourceInfoList = [] },
        productLegendState: { currentProductInfoIndex = [] }
      },
      filterValues,
      periodIndex,
      weeklySort,
      isOverflow,
      isGrandTotal,
      onToggleGrandTotalPin,
      isGrandTotalPinned
    } = this.props
    const { clickedSubItemIndex, hoveredItemIndex } = this.state

    const menuStyle = isOverflow ? { right: 0, left: 'unset' } : {}

    const list = _.reduce(
      currentProductInfoIndex,
      (acc, n, i) => {
        const {
          name = '',
          shortName = '',
          tableName = '',
          isResourceBased,
          format
        } = resourceInfoList[n] || {}

        const isLastItem = i === currentProductInfoIndex.length - 1
        if (isLastItem && isGrandTotal) {
          return acc.concat([
            <MenuItem
              key={i.toString()}
              clickedSubItemIndex={clickedSubItemIndex}
              connectApi={this.props.connectApi}
              filterValues={filterValues}
              format={format}
              hoveredIndex={hoveredItemIndex}
              i={i}
              issueIndex={n}
              isOverflow={isOverflow}
              isResourceBased={isResourceBased}
              issueList={resourceInfoList}
              name={shortName || name}
              periodIndex={periodIndex}
              tableName={tableName}
              weeklySort={weeklySort}
              onClick={this.handleSubItemClick}
              onMouseEnter={this.handleItemMouseEnter}
            />,
            <MenuItem
              key="pin-grand-total"
              clickedSubItemIndex={clickedSubItemIndex}
              connectApi={this.props.connectApi}
              format={''}
              filterValues={filterValues}
              hoveredIndex={hoveredItemIndex}
              i={i + 1}
              issueIndex={n}
              isOverflow={isOverflow}
              isResourceBased={false}
              issueList={resourceInfoList}
              name={`${isGrandTotalPinned ? 'Unpin' : 'Pin'} Grand Total`}
              tableName={''}
              weeklySort={weeklySort}
              onClick={this.handleSubItemClick}
              onMouseEnter={this.handleItemMouseEnter}
            />
          ])
        }

        return acc.concat(
          <MenuItem
            key={i.toString()}
            clickedSubItemIndex={clickedSubItemIndex}
            connectApi={this.props.connectApi}
            filterValues={filterValues}
            format={format}
            hoveredIndex={hoveredItemIndex}
            i={i}
            issueIndex={n}
            isOverflow={isOverflow}
            isResourceBased={isResourceBased}
            issueList={resourceInfoList}
            name={shortName || name}
            periodIndex={periodIndex}
            tableName={tableName}
            weeklySort={weeklySort}
            onClick={this.handleSubItemClick}
            onMouseEnter={this.handleItemMouseEnter}
          />
        )
      },
      []
    )

    return (
      <ul className="menu" style={menuStyle}>
        {list}
      </ul>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
    filterValues: state.resourcePlan.filterValues,
    weeklySort: state.resourcePlan.weeklySort,
    configSettings: state.resourcePlan.configSettings,
    legend: state.legend,
    isGrandTotalPinned: state.resourcePlan.isGrandTotalPinned
  }
}

const mapDispatchToProps = {
  filterValuesFunc
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
