Ext.define('Spui.view.main.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spuimainview',

  config: {
    menuId: '',
    user: null,
    CanUseSAP: null,
    mongoCollectionExportedData: null
  },
  bind: {
    menuId: '{menuId}',
    user: '{user}',
    CanUseSAP: '{CanUseSAP}',
    mongoCollectionExportedData: '{mongoCollectionExportedData}'
  },
  publishes: ['menuId', 'user', 'CanUseSAP', 'mongoCollectionExportedData'],
  initComponent: function () {
    var me = this,
      menuId = me.config.menuId,
      mongoCollectionExportedData = me.config.MongoCollectionExportedData,
      mongoCollectionRecord,
      viewModel = me.getViewModel()
    mongoCollectionRecord = new Spui.data.store.MongoSettings()
    mongoCollectionRecord.setData(mongoCollectionExportedData)
    console.log(menuId)
    viewModel.set({ CanUseSAP: me.config.CanUseSAP })
    viewModel.set({ mongoCollectionExportedData: mongoCollectionRecord })
    Spui.Global.setMenuId(menuId)
    Spui.Global.setMongoCollectionExportedData(mongoCollectionRecord)

    me.callParent()
  },
  viewModel: 'spui-view-main-mainviewmodel',
  controller: 'spuiMainController',
  layout: 'fit',
  cls: 'main-hide'
})
