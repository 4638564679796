/* eslint-disable jest/no-export */
import { IRowDataGrid, TfieldConfigs } from '../../../../types'

export const resultObj = {
  selectedRowCount: 2,
  LookupDataFieldCol: [
    '1:Bachelor~2:Collage~3:Doctor~4:High School or Below~5:Master',
    '1:Bachelor~2:Collage~3:Doctor~4:High School or Below~5:Master'
  ],
  Customer: ['AA35519', 'AA11235'],
  State: ['test', 'test3'],
  'Customer Lifetime Value': ['8002.308333', '2568.843396'],
  Coverage: ['Extended', 'Extended'],
  Education: ['College', 'Bachelor'],
  'Effective To Date': ['2022-05-11T21:00:00.000Z', '2022-05-11T21:00:00.000Z'],
  EmploymentStatus: ['Unemployed', 'Medical Leave'],
  Gender: ['Extended', 'Extended'],
  Income: [339, 33],
  'Location Code': ['Suburban', 'Suburban'],
  'Marital Status': ['Married', 'Married'],
  'Monthly Premium Auto': [107, 73],
  'Months Since Last Claim': [3, 0],
  'Months Since Policy Inception': [65, 99],
  'Number of Open Complaints': [0, 3],
  'Number of Policies': ['College', 'Bachelor'],
  'Policy Type': ['Personal Auto', 'Personal Auto'],
  Policy: ['Personal L2', 'Personal L3'],
  'Renew Offer Type': ['Offer1', 'Offer2'],
  'Sales Channel': ['Agent', 'Branch'],
  'Total Claim Amount': [513.6, 350.4],
  'Vehicle Class': ['SUV', 'Two-Door Car'],
  'Vehicle Size': ['Medsize', 'Medsize'],
  Response: [true, false]
}

export const resultObjNoSelectedRows = {
  selectedRowCount: 0,
  LookupDataFieldCol: null,
  Customer: null,
  State: null,
  'Customer Lifetime Value': null,
  Coverage: null,
  Education: null,
  'Effective To Date': null,
  EmploymentStatus: null,
  Gender: null,
  Income: null,
  'Location Code': null,
  'Marital Status': null,
  'Monthly Premium Auto': null,
  'Months Since Last Claim': null,
  'Months Since Policy Inception': null,
  'Number of Open Complaints': null,
  'Number of Policies': null,
  'Policy Type': null,
  Policy: null,
  'Renew Offer Type': null,
  'Sales Channel': null,
  'Total Claim Amount': null,
  'Vehicle Class': null,
  'Vehicle Size': null,
  Response: null
}

export const selectionFieldConfigs: TfieldConfigs = [
  {
    fieldName: 'LookupDataFieldCol',
    dataType: 'string'
  },
  {
    fieldName: 'Customer',
    dataType: 'string'
  },
  {
    fieldName: 'State',
    dataType: 'string'
  },
  {
    fieldName: 'Customer Lifetime Value',
    dataType: 'string'
  },
  {
    fieldName: 'Coverage',
    dataType: 'string'
  },
  {
    fieldName: 'Education',
    dataType: 'string'
  },
  {
    fieldName: 'Effective To Date',
    dataType: 'datetime'
  },
  {
    fieldName: 'EmploymentStatus',
    dataType: 'string'
  },
  {
    fieldName: 'Gender',
    dataType: 'string'
  },
  {
    fieldName: 'Income',
    dataType: 'int'
  },
  {
    fieldName: 'Location Code',
    dataType: 'string'
  },
  {
    fieldName: 'Marital Status',
    dataType: 'string'
  },
  {
    fieldName: 'Monthly Premium Auto',
    dataType: 'int'
  },
  {
    fieldName: 'Months Since Last Claim',
    dataType: 'int'
  },
  {
    fieldName: 'Months Since Policy Inception',
    dataType: 'int'
  },
  {
    fieldName: 'Number of Open Complaints',
    dataType: 'int'
  },
  {
    fieldName: 'Number of Policies',
    dataType: 'int'
  },
  {
    fieldName: 'Policy Type',
    dataType: 'string'
  },
  {
    fieldName: 'Policy',
    dataType: 'string'
  },
  {
    fieldName: 'Renew Offer Type',
    dataType: 'string'
  },
  {
    fieldName: 'Sales Channel',
    dataType: 'string'
  },
  {
    fieldName: 'Total Claim Amount',
    dataType: 'double'
  },
  {
    fieldName: 'Vehicle Class',
    dataType: 'string'
  },
  {
    fieldName: 'Vehicle Size',
    dataType: 'string'
  },
  {
    fieldName: 'Response',
    dataType: 'bool'
  }
]

export const selectedRows: IRowDataGrid[] = [
  {
    LookupDataFieldCol: '1:Bachelor~2:Collage~3:Doctor~4:High School or Below~5:Master',
    Customer: 'AA35519',
    State: 'test',
    'Customer Lifetime Value': '8002.308333',
    Coverage: 'Extended',
    Education: 'College',
    'Effective To Date': '2022-05-11T21:00:00.000Z',
    EmploymentStatus: 'Unemployed',
    Gender: 'Extended',
    Income: 339,
    'Location Code': 'Suburban',
    'Marital Status': 'Married',
    'Monthly Premium Auto': 107,
    'Months Since Last Claim': 3,
    'Months Since Policy Inception': 65,
    'Number of Open Complaints': 0,
    'Number of Policies': 'College',
    'Policy Type': 'Personal Auto',
    Policy: 'Personal L2',
    'Renew Offer Type': 'Offer1',
    'Sales Channel': 'Agent',
    'Total Claim Amount': 513.6,
    'Vehicle Class': 'SUV',
    'Vehicle Size': 'Medsize',
    Response: true
  },
  {
    LookupDataFieldCol: '1:Bachelor~2:Collage~3:Doctor~4:High School or Below~5:Master',
    Customer: 'AA11235',
    State: 'test3',
    'Customer Lifetime Value': '2568.843396',
    Coverage: 'Extended',
    Education: 'Bachelor',
    'Effective To Date': '2022-05-11T21:00:00.000Z',
    EmploymentStatus: 'Medical Leave',
    Gender: 'Extended',
    Income: 33,
    'Location Code': 'Suburban',
    'Marital Status': 'Married',
    'Monthly Premium Auto': 73,
    'Months Since Last Claim': 0,
    'Months Since Policy Inception': 99,
    'Number of Open Complaints': 3,
    'Number of Policies': 'Bachelor',
    'Policy Type': 'Personal Auto',
    Policy: 'Personal L3',
    'Renew Offer Type': 'Offer2',
    'Sales Channel': 'Branch',
    'Total Claim Amount': 350.4,
    'Vehicle Class': 'Two-Door Car',
    'Vehicle Size': 'Medsize',
    Response: false
  }
]

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
