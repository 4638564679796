Ext.define('tuiv2.treeview.Settings', {
  extend: 'Ext.window.Window',
  layout: {
    type: 'hbox',
    align: 'stretch',
    padding: 1
  },
  width: 600,
  height: 600,
  ui: 'tui-window',
  modal: true,
  title: 'Tree Structure',
  initComponent: function () {
    var me = this
    me.dockedItems = [
      {
        xtype: 'toolbar',
        dock: 'bottom',
        items: [
          // '->',
          {
            text: 'Load',
            itemId: 'save',
            ui: 'tui-button-success',
            iconCls: 'fa fa-floppy-o',
            handler: function () {
              var me = this,
                viewModel = me.mainViewModel,
                data = me.store.getDatas(),
                hierarchy = [],
                store = viewModel.getStore(me.tree + 'TreeStore')

              for (var i = 0; i < data.length; i++) {
                hierarchy.push(data[i])
              }

              this.fireEvent('changed', hierarchy)

              if (me.tree == 'load') {
                if (hierarchy.length == 0) {
                  tuiv2.Global.setLoadHierarchyIsEmpty(true)
                } else {
                  tuiv2.Global.setLoadHierarchyIsEmpty(false)
                }
                tuiv2.Global.setLoadTreeStructure(hierarchy)
              } else {
                if (hierarchy.length == 0) {
                  tuiv2.Global.setDemandHierarchyIsEmpty(true)
                } else {
                  tuiv2.Global.setDemandHierarchyIsEmpty(false)
                }
                tuiv2.Global.setDemandTreeStructure(hierarchy)
              }

              viewModel.set(me.tree + 'TreeHierarchy', hierarchy)
              store.load()
              me.close()
            },
            scope: this
          },
          {
            text: 'Cancel',
            itemId: 'cancel',
            ui: 'btn-danger',
            iconCls: 'fa fa fa-undo',
            margin: '0 10 0 5',
            handler: function () {
              this.close()
            },
            scope: this
          }
        ]
      }
    ]
    var me = this,
      columns = [
        {
          text: 'Name',
          flex: 1,
          sortable: true,
          width: '300px',
          dataIndex: 'text'
        }
      ],
      columns2 = [
        {
          text: 'Name',
          flex: 1,
          sortable: false,
          width: '150px',
          dataIndex: 'text',
          renderer: function (value, metaData, record, rowIndex, colIndex) {
            return '<span style=" margin-left:' + rowIndex * 10 + 'px">' + value + '</span>'
          }
        },
        {
          xtype: 'checkcolumn',
          text: 'Tree Distinct Color',
          width: '150px',
          dataIndex: 'isTreeDistinctColorField'
        }
      ]

    var rightSideData = me.getRightSideData()
    me.store = Ext.create('Ext.data.Store', {
      fields: ['text', 'dataIndex', 'isTreeDistinctColorField'],
      data: rightSideData
    })

    var leftSideData = me.getLeftSideData()

    leftSideData = leftSideData.filter((el) => !rightSideData.map((p) => p.text).includes(el.text))

    this.items = [
      {
        header: false,
        margin: '10',
        flex: 1,
        xtype: 'grid',
        border: true,
        multiSelect: true,
        viewConfig: {
          plugins: {
            ptype: 'gridviewdragdrop'
          }
        },
        store: new Ext.data.Store({
          fields: ['text', 'dataIndex', 'isTreeDistinctColorField'],
          data: leftSideData
        }),
        columns: columns,
        stripeRows: true
      },
      {
        header: false,
        border: true,
        margin: '10',
        flex: 1,
        xtype: 'grid',
        reference: 'treeStructureRightSideGrid',
        viewConfig: {
          plugins: {
            ptype: 'gridviewdragdrop'
          },
          listeners: {
            drop: function (node, data, overModel, dropPosition, eOpts) {
              this.query('grid')[1].getView().refresh()
            },
            scope: this
          }
        },
        store: me.store,
        columns: columns2,
        stripeRows: true,
        title: 'Second Grid'
      }
    ]

    this.callParent()
  },
  getLeftSideData: function () {
    var me = this,
      viewModel = me.mainViewModel,
      treeColumns = viewModel.get(me.tree + 'TreeHierarchyColumns'),
      columns = []

    treeColumns.forEach(function (s) {
      columns.push({
        text: s.text,
        dataIndex: s.dataIndex,
        isTreeDistinctColorField: s.isTreeDistinctColorField
      })
    })

    return columns
  },
  getRightSideData: function () {
    var me = this,
      viewModel = me.mainViewModel,
      hierarchy = viewModel.get(me.tree + 'TreeHierarchy'),
      result = [],
      stateTreeStructure,
      emptyState
    if (me.tree == 'load') {
      stateTreeStructure = tuiv2.Global.getLoadTreeStructure()
      emptyState = tuiv2.Global.getLoadHierarchyIsEmpty()
    } else {
      stateTreeStructure = tuiv2.Global.getDemandTreeStructure()
      emptyState = tuiv2.Global.getDemandHierarchyIsEmpty()
    }

    if (stateTreeStructure.length == 0) {
      if (!emptyState) {
        hierarchy.forEach(function (v) {
          result.push({
            text: v.text,
            dataIndex: v.dataIndex,
            isTreeDistinctColorField: v.isTreeDistinctColorField
          })
        })
      }
    } else {
      stateTreeStructure.forEach(function (v) {
        result.push({
          text: v.text,
          dataIndex: v.dataIndex,
          isTreeDistinctColorField: v.isTreeDistinctColorField
        })
      })
    }

    return result
  }
})
