Ext.define('MDM.Btl.cmp.Headerbar', {
  extend: 'Ext.toolbar.Toolbar',
  xtype: 'btl-header',
  border: true,
  enableOverflow: true,
  overflowHandler: 'scroller',
  style: {
    // background:'#505B72'
  },
  items: [
    {
      xtype: 'rpcombo'
    },
    {
      xtype: 'mrcombo'
    },
    {
      xtype: 'rcombo'
    },
    {
      xtype: 'dhcombo'
    },
    '-',
    {
      xtype: 'button',
      text: 'Submit',
      ui: 'btn-inverse',
      handler: 'onSubmit',
      bind: {
        disabled: '{!DPIHierarcy}'
      }
    },
    '->',
    {
      showText: true,
      prependText: 'DPI ',
      ui: 'btn-success',
      text: 'View ',
      xtype: 'cycle',
      setLabelbutton: function (v) {
        me.setText(me.getButtonText(item))
      },
      bind: {
        disabled: '{!RegionID}',
        text: 'DPI {v1}'
      },
      changeHandler: 'onChangeMainView',
      menu: {
        items: [
          {
            iconCls: 'far fa-circle',
            bind: {
              text: '{v1}'
            },
            viewId: 0
          },
          {
            iconCls: 'far fa-circle',
            bind: {
              disabled: '{!hasUnconstraint}',
              text: '{v2}'
            },
            viewId: 1
          }
        ]
      }
    },
    {
      xtype: 'button',
      text: 'Report',
      iconCls: 'fa fa-chart-bar',
      ui: 'btn-success',
      bind: {
        disabled: '{!RegionID}'
      },
      menu: [
        {
          iconCls: 'fa fa-chart-bar',
          text: 'DPI Chart',
          handler: 'onDPIChart'
        },
        {
          iconCls: 'fa fa-chart-bar',
          text: 'DPI Discrepancy',
          handler: 'onDiscrepancyShow'
        },
        {
          iconCls: 'fa fa-check-square-o',
          text: 'Apply BTLs ',
          handler: 'onMassAccept',
          bind: {
            disabled: '{isReadOnly}'
          }
        },
        {
          iconCls: 'fa fa-bars',
          text: 'DPI Negative Inventory',
          handler: 'onNegativeInvShow'
        },
        {
          iconCls: 'fa fa-file-text-o',
          text: 'Rule Based BTLs',
          handler: 'onRuleBased'
        }
      ]
    },
    {
      xtype: 'button',
      ui: 'btn-success',
      text: 'Export',
      iconCls: 'fas fa-download',
      handler: 'exportTo',
      type: 'excel07',
      ext: 'xlsx',
      bind: {
        disabled: '{!DPIHierarcy}'
      },
      includeGroups: true,
      includeSummary: true
    },
    {
      iconCls: 'fas fa-cog',
      ui: 'btn-success',
      menu: {
        items: [
          {
            xtype: 'menupanel'
          }
        ]
      }
    },
    {
      xtype: 'button',
      ui: 'btn-success',
      iconCls: 'fas fa-compress',
      tooltip: 'Show/Hide Edit Panel',
      margin: '0 10 0 0',
      bind: {
        visible: '{isEditable}'
      },
      handler: 'onShowHideEditPanel'
    }
  ]
})
