Ext.define('Spui.view.main.toolbar.Header', {
  extend: 'Ext.toolbar.Toolbar',
  // Uncomment to give this component an xtype
  xtype: 'spui-view-main-toolbar-header',
  ui: 'spui-white',
  config: {
    headerTitle: '',
    // yeni kampanya
    btnIconCls_NewCampaign: 'fa fa-calendar',
    btnHandler_NewCampaign: 'onClickNewCampaign',
    // MDM menu
    menuText_Mdm: 'WizardTabPanel1',
    menuIconCls_Mdm: 'fa fa-database'
  },
  initComponent: function () {
    var me = this
    var items = []
    var isAdmin = Spui.Global.isAdmin()
    var isMO = Spui.Global.isMO()
    var isDC = Spui.Global.isDC()
    var isSO = Spui.Global.isSO()

    if (isMO) {
      items = me.buildItemsForMO()
    }
    if (isDC) {
      items = me.buildItemsForDC()
    }
    if (isSO) {
      items = me.buildItemsForSO()
    }

    if (isAdmin) {
      items = items.concat(me.buildItemsForAdmin())
    }
    me.items = items
    me.callParent()
  },
  buildItemsForMO: function () {
    var me = this,
      items = []

    items.push({
      ui: 'spui-white',
      xtype: 'tbtext',
      text: me.headerTitle
    })
    items.push('->')

    items.push({
      iconCls: me.btnIconCls_NewCampaign,
      ui: 'spui-btn-header',
      xtype: 'cycle',
      reference: 'addnewspot',
      arrowVisible: false,
      showText: true,
      prependText: '',
      changeHandler: 'onChangeView',
      menu: {
        items: [
          {
            text: tt('New Campaign'),
            iconCls: 'fa fa-cart-plus',
            checked: true,
            itemId: 'main'
          },
          {
            text: tt('Anasayfa'),
            iconCls: 'fa fa-home',
            itemId: 'new'
          }
        ]
      }
    })

    return items
  },
  buildItemsForSO: function () {
    var me = this,
      items = []

    items.push(me.headerTitle)
    items.push('->')

    return items
  },
  buildItemsForDC: function () {
    var me = this,
      items = []

    items.push(me.headerTitle)
    items.push('->')

    return items
  },
  buildItemsForAdmin: function () {
    var me = this,
      items = [
        '-',
        {
          bind: { text: '{userObj.userRole.userName}' }
        },
        {
          bind: { text: '{userObj.userRole.name}' }
        },
        {
          bind: { text: '{userObj.userRole.code}' }
        },
        {
          bind: { text: '{userObj.userRole.DepoKodu}' }
        }
      ]
    items.push({
      iconCls: 'fa fa-cog',
      ui: 'spui-btn-header',
      menu: {
        style: {
          'border-style': 'none'
        },
        shadow: false,
        items: [
          {
            text: 'Set I18',
            iconCls: 'fa fa-cubes',

            handler: function () {
              Ext.create('Adm.I18.Main').show()
            }
          },
          '-',
          {
            text: 'Change User',
            iconCls: 'fa  fa-users',
            handler: function () {
              var me = Spui.Global.isDotNet
                  ? this.up('spuimainview')
                  : this.up('spui-view-main-main'),
                w = Ext.create('Ext.window.Window', {
                  ui: 'spui-white',
                  title: 'Change user',
                  layout: 'fit',
                  width: 800,
                  height: 800,
                  items: [
                    {
                      xtype: 'spui-view-main-toolbar-changeuser'
                    }
                  ]
                })
              me.add(w)
              w.show()
            }
          },
          {
            text: 'Change User Cultere',
            iconCls: 'fa fa-globe',
            menu: {
              items: [
                {
                  xtype: 'spui-view-main-toolbar-changelang',
                  width: 500,
                  minHeight: 100
                }
              ]
            }
          }
        ]
      }
    })
    return items
  }
})
