Ext.define('MDM.view.regionapp.MassEdit.Grid', {
  extend: 'Ext.grid.Panel',
  xtype: 'mdm-view-regionapp-massedit-grid',
  lockedGridConfig: {
    header: false,
    width: 400,
    split: true,
    forceFit: false
  },
  lockedViewConfig: {
    scroll: 'horizontal'
  },
  layout: 'border',
  columnLines: true,
  plugins: [
    {
      ptype: 'clipboard',
      system: 'raw',
      memory: true,
      putTextData: function (data, format) {
        return false
      }
    },
    {
      ptype: 'selectionreplicator',
      clipboard: true,
      selectionreplicator: true
    },
    {
      ptype: 'cellediting',
      clicksToEdit: 2
    }
  ],
  selModel: {
    type: 'spreadsheet',
    // Disables sorting by header click, though it will be still available via menu
    columnSelect: true,
    pruneRemoved: false,
    extensible: 'y',
    getNumbererColumnConfig: function () {
      var me = this

      return {
        xtype: 'rownumberer',
        width: 1,
        renderer: function () {
          return '&#160;'
        },
        editRenderer: '&#160;'
      }
    }
  },
  listeners: {
    beforeselectionextend: function (grid, selection, extension, eOpts) {
      for (var x = selection.startCell.colIdx; x <= selection.endCell.colIdx; x++) {
        if (!grid.columns[x].extensionAllowed) return false
      }
    }
  },
  viewConfig: {
    getRowClass: function (record) {
      if (!record.get('isValid')) return 'isnotValid'
      return ''
    },
    loadMask: false,
    stripeRows: true
  },
  columns: [
    {
      xtype: 'actioncolumn',
      width: 30,
      sortable: false,
      menuDisabled: true,
      locked: true,
      items: [
        {
          iconCls: 'fa fa-sign-out',
          tooltip: 'Remove From List',
          handler: function (view, recIndex, cellIndex, item, e, record) {
            view.store.remove(record)
          }
        }
      ]
    },

    {
      text: 'Material',
      dataIndex: 'MaterialCode',
      width: 100,
      locked: true
    },
    {
      text: 'Description',
      dataIndex: 'MaterialDescription',
      locked: true,
      flex: 1
    },

    {
      text: 'Material Type',
      dataIndex: 'MaterialTypeID',
      width: 80,
      extensionAllowed: true,
      renderer: function (val, metaData) {
        var store = Ext.getStore('MaterialType')
        if (!val) {
          metaData.tdCls = 'cell-yellow-warnig'
        }
        if (store && val) {
          var rec = store.findRecord('Id', val, 0, false, true, true)
          if (rec) return rec.get('Title')
        }
        return val
      },

      editor: {
        xtype: 'combo',
        typeAhead: true,
        triggerAction: 'all',
        displayField: 'Title',
        valueField: 'Id',
        queryMode: 'local',
        forceSelection: true,
        allowBlank: false,
        listeners: {
          select: function (a, b, c) {
            a.ownerCt.completeEdit()
          }
        },
        bind: {
          store: '{MaterialTypeStore}'
        }
      }
    },
    {
      text: 'Supply Source',
      dataIndex: 'SupplySourceID',
      width: 210,
      extensionAllowed: true,
      renderer: function (val, metaData) {
        var store = Ext.getStore('SupplySource')
        if (!val) {
          metaData.tdCls = 'cell-yellow-warnig'
          return ''
        }
        if (store && val) {
          var rec = store.findRecord('Id', val, 0, false, true, true)
          if (rec) return rec.get('Title')
        }

        return val
      },
      editor: {
        xtype: 'combo',
        typeAhead: true,
        triggerAction: 'all',
        displayField: 'Title',
        valueField: 'Id',
        forceSelection: true,
        allowBlank: false,
        queryMode: 'local',
        listeners: {
          select: function (a, b, c) {
            a.ownerCt.completeEdit()
          }
        },
        bind: {
          store: '{SupplySourceStore}'
        }
      }
    },
    {
      text: 'DPI Family-1',
      tdCls: 'blue',
      columns: [
        {
          text: 'DPI Family',

          dataIndex: 'DPIFamilyID',
          width: 170,
          extensionAllowed: true,
          renderer: function (val, metaData) {
            var store = Ext.getStore('DPIFamily')
            if (!val) {
              metaData.tdCls = 'cell-yellow-warnig'
              return ''
            }
            if (store && val) {
              var rec = store.findRecord('Id', val, 0, false, true, true)
              if (rec) return rec.get('Title')
            }

            return val
          },
          editor: {
            xtype: 'combo',
            typeAhead: true,
            triggerAction: 'all',
            displayField: 'Title',
            valueField: 'Id',
            queryMode: 'local',
            listeners: {
              select: function (a, b, c) {
                a.ownerCt.completeEdit()
              }
            },
            bind: {
              store: '{DPIFamilyStore}'
            }
          }
        },
        {
          text: 'GU',
          align: 'right',
          dataIndex: 'GU',
          width: 60,
          extensionAllowed: true,
          renderer: function (val, metaData, record, rowIndex) {
            if (!val) {
              metaData.tdCls = 'cell-yellow-warnig'
              return ''
            }

            return val
          },

          editor: {
            xtype: 'numberfield',
            hideTrigger: true,
            min: 0
          }
        },
        {
          text: 'Cardridge DPI',

          dataIndex: 'CartridgeDPIFamilyID',
          width: 170,
          extensionAllowed: true,
          renderer: function (val) {
            var store = Ext.getStore('DPIFamily')
            if (!val) {
              return ''
            }
            if (store && val) {
              var rec = store.findRecord('Id', val, 0, false, true, true)
              if (rec) return rec.get('Title')
            }

            return val
          },
          editor: {
            xtype: 'combo',
            typeAhead: true,
            triggerAction: 'all',
            displayField: 'Title',
            valueField: 'Id',
            queryMode: 'local',
            listeners: {
              select: function (a, b, c) {
                a.ownerCt.completeEdit()
              }
            },
            bind: {
              store: '{DPICartridgeStore}'
            }
          }
        },
        {
          text: 'RD Coefficient',
          align: 'right',
          tooltip: 'RD Coefficient',
          dataIndex: 'RazorDrawCoefficient',
          width: 60,
          extensionAllowed: true,
          renderer: function (val, metaData, record, rowIndex) {
            if (!val) {
              return ''
            }

            return val
          },

          editor: {
            xtype: 'numberfield',
            hideTrigger: true,
            min: 0
          }
        }
      ]
    },

    {
      text: 'SU',
      dataIndex: 'SU',
      width: 60,
      align: 'right',
      renderer: function (val, metaData, record, rowIndex) {
        if (!val) {
          metaData.tdCls = 'cell-yellow-warnig'
          return ''
        }

        return val
      },
      extensionAllowed: true,
      editor: {
        xtype: 'numberfield',
        hideTrigger: true,
        min: 0
      }
    },
    {
      text: 'BUOM',
      dataIndex: 'MaterialBUOMID',

      width: 110,
      extensionAllowed: true,
      renderer: function (val, metaData) {
        var store = Ext.getStore('MaterialBUOM')
        if (!val) {
          metaData.tdCls = 'cell-yellow-warnig'
          return ''
        }
        if (store && val) {
          var rec = store.findRecord('Id', val, 0, false, true, true)
          if (rec) return rec.get('Title')
        }
        return val
      },
      editor: {
        xtype: 'combo',
        typeAhead: true,
        triggerAction: 'all',
        displayField: 'Title',
        valueField: 'Id',
        queryMode: 'local',
        listeners: {
          select: function (a, b, c) {
            a.ownerCt.completeEdit()
          }
        },
        bind: {
          store: '{MaterialBUOMStore}'
        }
      }
    },
    {
      text: 'Excluded From',
      columns: [
        {
          xtype: 'checkcolumn',
          header: 'Production',
          dataIndex: 'ExcludedFromProduction',
          extensionAllowed: true,
          headerCheckbox: true,
          width: 90,
          stopSelection: false
        },
        {
          xtype: 'checkcolumn',
          header: 'Revenue',
          dataIndex: 'ExcludedFromRevenue',
          extensionAllowed: true,
          headerCheckbox: true,
          width: 90,
          stopSelection: false
        }
      ]
    },
    {
      text: 'CSU Indicator',
      dataIndex: 'CSUIndicator',
      width: 80,
      selectionreplicator: true,
      editor: {
        xtype: 'numberfield',
        hideTrigger: true,
        min: 0
      }
    },

    {
      text: 'Category',
      dataIndex: 'Category',
      width: 100,
      extensionAllowed: true,
      editor: {
        allowBlank: false
      }
    },
    {
      text: 'Brand',
      dataIndex: 'Brand',
      width: 100,
      extensionAllowed: true,
      editor: {
        allowBlank: false
      }
    },
    {
      text: 'Product Form Detail',
      dataIndex: 'ProductFormDetail',
      width: 100,
      extensionAllowed: true,
      editor: {
        allowBlank: false
      }
    },

    {
      text: 'DPI Family-2',
      tdCls: 'blue',
      columns: [
        {
          text: 'DPI Family',

          dataIndex: 'DPIFamilyID1',
          width: 170,
          extensionAllowed: true,
          renderer: function (val) {
            var store = Ext.getStore('DPIFamily')
            if (!val) {
              return ''
            }
            if (store && val) {
              var rec = store.findRecord('Id', val, 0, false, true, true)
              if (rec) return rec.get('Title')
            }

            return val
          },
          editor: {
            xtype: 'combo',
            typeAhead: true,
            triggerAction: 'all',
            displayField: 'Title',
            valueField: 'Id',
            queryMode: 'local',
            bind: {
              store: '{DPIFamilyStore}'
            }
          }
        },
        {
          text: 'GU',
          align: 'right',
          dataIndex: 'GU1',
          width: 60,
          extensionAllowed: true,
          renderer: function (val, metaData, record) {
            var dpifam = record.get('DPIFamilyID1')
            if (dpifam && !val) {
              metaData.tdCls = 'cell-yellow-warnig'
              return ''
            }
            if (!val) {
              return ''
            }

            return val
          },

          editor: {
            xtype: 'numberfield',
            hideTrigger: true,
            min: 0
          }
        }
      ]
    },
    {
      text: 'DPI Family-3',
      tdCls: 'blue',
      columns: [
        {
          text: 'DPI Family',

          dataIndex: 'DPIFamilyID2',
          width: 170,
          extensionAllowed: true,
          renderer: function (val) {
            var store = Ext.getStore('DPIFamily')
            if (!val) {
              return ''
            }
            if (store && val) {
              var rec = store.findRecord('Id', val, 0, false, true, true)
              if (rec) return rec.get('Title')
            }

            return val
          },
          editor: {
            xtype: 'combo',
            typeAhead: true,
            triggerAction: 'all',
            displayField: 'Title',
            valueField: 'Id',
            queryMode: 'local',
            bind: {
              store: '{DPIFamilyStore}'
            }
          }
        },
        {
          text: 'GU',
          align: 'right',
          dataIndex: 'GU2',
          width: 60,
          extensionAllowed: true,
          renderer: function (val, metaData, record) {
            var dpifam = record.get('DPIFamilyID2')
            if (dpifam && !val) {
              metaData.tdCls = 'cell-yellow-warnig'
              return ''
            }
            if (!val) {
              return ''
            }

            return val
          },

          editor: {
            xtype: 'numberfield',
            hideTrigger: true,
            min: 0
          }
        }
      ]
    }
  ]
})
