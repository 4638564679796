import _ from 'lodash'
import QueryBuilder, { Field, QueryBuilderProps, ValueSources } from 'react-querybuilder'
import { bootstrapControlClassnames, bootstrapControlElements } from '@react-querybuilder/bootstrap'
import { getPluginVariables } from '@/helpers/mapper'
import { IConditionProps } from './Condition.types'
import { getEmptyQuery } from './helpers'
import { availableOperators, translations } from './constants'
import { IVariables } from '../../../Common.types'
import 'react-querybuilder/dist/query-builder.scss'

const Condition = ({
  onSave,
  params: { query } = {},
  customVariables,
  plugins,
  assignees
}: IConditionProps) => {
  const variables = {
    ...getPluginVariables(plugins, assignees),
    ...customVariables
  } as IVariables

  const fields: Field[] = _.map(variables, (variable, key) => {
    return {
      name: key,
      label: key,
      comparator: 'variableType',
      variableType: variable.type,
      valueSources: ['value', 'field'] satisfies ValueSources
    }
  })?.sort(({ label: a }, { label: b }) => a.toLowerCase().localeCompare(b.toLowerCase()))

  const finalQuery: QueryBuilderProps['query'] = _.isEmpty(query) ? getEmptyQuery() : query

  const onQueryChange: any = (newQuery: QueryBuilderProps['query']) => {
    onSave({ query: newQuery })
  }

  return (
    <div className="p-3" data-testid="condition-job">
      <QueryBuilder
        autoSelectField={false}
        controlClassnames={bootstrapControlClassnames}
        controlElements={bootstrapControlElements}
        fields={fields}
        operators={availableOperators}
        query={finalQuery}
        translations={translations}
        onQueryChange={onQueryChange}
      />
    </div>
  )
}

export default Condition
