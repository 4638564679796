/* global Ext, Assortment, store, $, _ , uuid*/

Ext.define('Assortment.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.assortment-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {},

  beforeSeasonChange: function (combo, record) {
    var value = [...combo.value, record.data.name]

    var seasonGroups = this.getViewModel().get('SeasonGroup')

    if (_.size(value) > 1 && seasonGroups != null) {
      var firstSeason = _.first(value)
      var lastSeason = _.last(value)

      var firstSeasonGroup
      _.forEach(seasonGroups, (seasonGroupSeasons, seasonGroup) => {
        if (_.indexOf(seasonGroupSeasons, firstSeason) >= 0) {
          firstSeasonGroup = seasonGroup
        }
      })

      var lastSeasonGroup
      _.forEach(seasonGroups, (seasonGroupSeasons, seasonGroup) => {
        if (_.indexOf(seasonGroupSeasons, lastSeason) >= 0) {
          lastSeasonGroup = seasonGroup
        }
      })

      if (firstSeasonGroup !== lastSeasonGroup) {
        Assortment.util.operationWarningWithMessage(
          'Season does not belong to the same group as already selected season(s).'
        )
        return false
      }
    }
  },

  onBrandChange: function (combo, value) {
    if (this.getViewModel().get('BrandID') === value) {
      return
    }

    var me = this

    var division = me.lookupReference('divison')

    var season = me.lookupReference('season')
    var store = division.store

    division.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({ Key: 'Brand', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onDivisinChange: function (combo, value) {
    if (this.getViewModel().get('DivisionID') === value) {
      return
    }

    var me = this

    var subdivision = me.lookupReference('subdivision')

    var season = me.lookupReference('season')
    var store = subdivision.store

    subdivision.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({ Key: 'Division', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onSubDivisionChange: function (combo, value) {
    if (this.getViewModel().get('SubDivisionID') === value) {
      return
    }

    var me = this

    var age = me.lookupReference('age')

    var season = me.lookupReference('season')
    var store = age.store

    age.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: [this.getViewModel().get('DivisionID')]
    })
    dependencyFilters.push({ Key: 'SubDivision', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onAgeChange: function (combo, value) {
    if (this.getViewModel().get('AgeID') === value) {
      return
    }

    var me = this

    var lifestyle = me.lookupReference('lifestyle')

    var season = me.lookupReference('season')
    var store = lifestyle.store

    lifestyle.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: [this.getViewModel().get('DivisionID')]
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: [this.getViewModel().get('SubDivisionID')]
    })
    dependencyFilters.push({ Key: 'Age', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load
  },

  onLifeStyleChange: function (combo, value) {
    if (this.getViewModel().get('LifeStyleID') === value) {
      return
    }

    var me = this

    var collectiongroup = me.lookupReference('collectiongroup')

    var season = me.lookupReference('season')
    var store = collectiongroup.store

    collectiongroup.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: [this.getViewModel().get('DivisionID')]
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: [this.getViewModel().get('SubDivisionID')]
    })
    dependencyFilters.push({
      Key: 'Age',
      Value: [this.getViewModel().get('AgeID')]
    })
    dependencyFilters.push({ Key: 'LifeStyle', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onCollectionGroupChange: function (combo, value) {
    if (this.getViewModel().get('CollectionGroupID') === value) {
      return
    }

    var me = this

    var buyergroup = me.lookupReference('buyergroup')

    var season = me.lookupReference('season')
    var store = buyergroup.store

    buyergroup.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: [this.getViewModel().get('DivisionID')]
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: [this.getViewModel().get('SubDivisionID')]
    })
    dependencyFilters.push({
      Key: 'Age',
      Value: [this.getViewModel().get('AgeID')]
    })
    dependencyFilters.push({
      Key: 'LifeStyle',
      Value: [this.getViewModel().get('LifeStyleID')]
    })
    dependencyFilters.push({ Key: 'CollectionGroup', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onBuyerGroupChange: function (combo, value) {
    if (this.getViewModel().get('BuyerGroupID') === value) {
      return
    }

    var me = this

    var classx = me.lookupReference('class')

    var season = me.lookupReference('season')
    var store = classx.store

    classx.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: [this.getViewModel().get('DivisionID')]
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: [this.getViewModel().get('SubDivisionID')]
    })
    dependencyFilters.push({
      Key: 'Age',
      Value: [this.getViewModel().get('AgeID')]
    })
    dependencyFilters.push({
      Key: 'LifeStyle',
      Value: [this.getViewModel().get('LifeStyleID')]
    })
    dependencyFilters.push({
      Key: 'CollectionGroup',
      Value: [this.getViewModel().get('CollectionGroupID')]
    })
    dependencyFilters.push({ Key: 'BuyerGroup', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onClassChange: function (combo, value) {
    if (this.getViewModel().get('ClassID') === value) {
      return
    }

    var me = this

    var range = me.lookupReference('range')

    var season = me.lookupReference('season')
    let store = range.store

    range.setValue(null)
    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: [this.getViewModel().get('DivisionID')]
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: [this.getViewModel().get('SubDivisionID')]
    })
    dependencyFilters.push({
      Key: 'Age',
      Value: [this.getViewModel().get('AgeID')]
    })
    dependencyFilters.push({
      Key: 'LifeStyle',
      Value: [this.getViewModel().get('LifeStyleID')]
    })
    dependencyFilters.push({
      Key: 'CollectionGroup',
      Value: [this.getViewModel().get('CollectionGroupID')]
    })
    dependencyFilters.push({
      Key: 'BuyerGroup',
      Value: [this.getViewModel().get('BuyerGroupID')]
    })
    dependencyFilters.push({ Key: 'Class', Value: [value] })
    store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    store.load()
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onRangeChange: function (combo, value) {
    if (this.getViewModel().get('RangeID') === value) {
      return
    }

    var me = this
    var season = me.lookupReference('season')

    season.setValue(null)

    var dependencyFilters = []

    dependencyFilters.push({
      Key: 'Brand',
      Value: [this.getViewModel().get('BrandID')]
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: [this.getViewModel().get('DivisionID')]
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: [this.getViewModel().get('SubDivisionID')]
    })
    dependencyFilters.push({
      Key: 'Age',
      Value: [this.getViewModel().get('AgeID')]
    })
    dependencyFilters.push({
      Key: 'LifeStyle',
      Value: [this.getViewModel().get('LifeStyleID')]
    })
    dependencyFilters.push({
      Key: 'CollectionGroup',
      Value: [this.getViewModel().get('CollectionGroupID')]
    })
    dependencyFilters.push({
      Key: 'BuyerGroup',
      Value: [this.getViewModel().get('BuyerGroupID')]
    })
    dependencyFilters.push({
      Key: 'Class',
      Value: [this.getViewModel().get('ClassID')]
    })
    dependencyFilters.push({ Key: 'Range', Value: [value] })
    season.store.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
    season.store.load()
  },

  onFilterPopUp: function () {
    var me = this
    var filterWin = this.getView().filterPopupWin
    if (!filterWin) {
      filterWin = Ext.create('Ext.window.Window', {
        layout: 'fit',
        height: 330,
        width: 600,
        title: 'Select Filters',
        modal: true,
        scrollable: true,
        resizable: false,
        closeAction: 'hide',
        ui: 'window-inverse',
        id: 'filterWindow',

        items: [
          {
            xtype: 'container',
            layout: 'border',
            items: [
              {
                xtype: 'form',
                region: 'center',
                margin: '4px 4px 4px 4px',
                scrollable: true,
                style: 'border: 1px solid #bbb !important;',
                fieldDefaults: {
                  msgTarget: 'side'
                },
                defaults: {
                  border: false,
                  xtype: 'panel',
                  flex: 1,
                  layout: 'anchor'
                },

                layout: 'hbox',

                items: [
                  {
                    padding: '20px 15px 0px 25px',
                    items: [
                      {
                        xtype: 'combo',
                        fieldLabel: 'Brand',
                        reference: 'brand',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '-5',
                        queryMode: 'remote',
                        listeners: {
                          change: 'onBrandChange'
                        },
                        bind: {
                          store: '{BrandStore}',
                          value: '{BrandID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'Division',
                        reference: 'divison',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '-5',
                        queryMode: 'local',
                        listeners: {
                          change: 'onDivisinChange'
                        },
                        bind: {
                          store: '{DivisionStore}',
                          value: '{DivisionID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'SubDivision',
                        reference: 'subdivision',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '-5',
                        queryMode: 'local',
                        listeners: {
                          change: 'onSubDivisionChange'
                        },
                        bind: {
                          store: '{SubDivisionStore}',
                          value: '{SubDivisionID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'Age',
                        reference: 'age',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '-5',
                        queryMode: 'local',
                        listeners: {
                          change: 'onAgeChange'
                        },
                        bind: {
                          store: '{AgeStore}',
                          value: '{AgeID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'LifeStyle',
                        reference: 'lifestyle',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '-5',
                        queryMode: 'local',
                        listeners: {
                          change: 'onLifeStyleChange'
                        },
                        bind: {
                          store: '{LifeStyleStore}',
                          value: '{LifeStyleID}'
                        }
                      }
                    ]
                  },
                  {
                    padding: '20px 25px 0px 15px',
                    items: [
                      {
                        xtype: 'combo',
                        fieldLabel: 'CollectionGroup',
                        reference: 'collectiongroup',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '100%',
                        queryMode: 'local',
                        listeners: {
                          change: 'onCollectionGroupChange'
                        },
                        bind: {
                          store: '{CollectionGroupStore}',
                          value: '{CollectionGroupID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'BuyerGroup',
                        reference: 'buyergroup',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '100%',
                        queryMode: 'local',
                        listeners: {
                          change: 'onBuyerGroupChange'
                        },
                        bind: {
                          store: '{BuyerGroupStore}',
                          value: '{BuyerGroupID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'Class',
                        reference: 'class',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '100%',
                        queryMode: 'local',
                        listeners: {
                          change: 'onClassChange'
                        },
                        bind: {
                          store: '{ClassStore}',
                          value: '{ClassID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'Range',
                        reference: 'range',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '100%',
                        queryMode: 'local',
                        listeners: {
                          change: 'onRangeChange'
                        },
                        bind: {
                          store: '{RangeStore}',
                          value: '{RangeID}'
                        }
                      },
                      {
                        xtype: 'combo',
                        fieldLabel: 'Season',
                        reference: 'season',
                        valueField: 'name',
                        displayField: 'name',
                        anchor: '100%',
                        queryMode: 'local',
                        multiSelect: true,
                        listeners: {
                          beforeselect: 'beforeSeasonChange'
                        },
                        bind: {
                          store: '{SeasonStore}',
                          value: '{SeasonID}'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        dockedItems: [
          {
            xtype: 'toolbar',
            dock: 'bottom',
            items: [
              {
                text: 'Save Filters',
                handler: 'OnSaveFilter'
              },
              {
                text: 'Load Filters',
                handler: function () {
                  var savedFilterStatesStore = vm.get('SavedFilterStatesStore')
                  var pluginState = vm.get('pluginState')
                  if (pluginState && pluginState.filters) {
                    var filters = _.map(
                      _.filter(pluginState.filters, (filter) => {
                        return !filter.autoSave
                      }),
                      (filter) => {
                        return {
                          id: filter.id,
                          filterName: filter.name,
                          filterValue: filter.filterValues
                        }
                      }
                    )
                    savedFilterStatesStore.loadData(filters)
                  }

                  var loadFiltersWin = me.getView().loadFiltersWin
                  if (!loadFiltersWin) {
                    var loadFiltersGrid = Ext.create({
                      xtype: 'loadFiltersGrid',
                      flex: 1,
                      bind: {
                        store: '{SavedFilterStatesStore}'
                      },
                      style: 'border: 1px solid #bbb !important;',
                      margin: '5 5 5 5'
                    })

                    vm.set('loadFiltersGrid', loadFiltersGrid)

                    loadFiltersWin = Ext.create('Ext.window.Window', {
                      height: 330,
                      width: 600,
                      title: 'Load Filters',
                      modal: true,
                      closeAction: 'close',
                      scrollable: true,
                      resizable: false,
                      ui: 'window-inverse',
                      id: 'loadFiltersWin',
                      layout: {
                        type: 'hbox',
                        pack: 'start',
                        align: 'stretch'
                      },
                      viewModel: vm,
                      items: [loadFiltersGrid],
                      dockedItems: [
                        {
                          xtype: 'toolbar',
                          dock: 'bottom',
                          items: [
                            '->',
                            {
                              text: 'OK',
                              handler: function (btn) {
                                me.SelectFilter(vm)
                                btn.up('window').close()
                              }
                            },
                            {
                              text: 'Cancel',
                              handler: function (btn) {
                                btn.up('window').close()
                              }
                            }
                          ]
                        }
                      ]
                    })
                    me.getView().loadFiltersWin = loadFiltersWin
                  }
                  me.getView().add(loadFiltersWin)
                  loadFiltersWin.show()
                }
              },
              '->',
              {
                text: 'Apply',
                handler: function (btn) {
                  var vm = me.getViewModel()
                  var pluginState = vm.get('pluginState')
                  if (!pluginState) {
                    pluginState = {}
                  }
                  if (!pluginState.filters) {
                    pluginState.filters = []
                  }
                  var autoSaveFilter = _.find(pluginState.filters, (filter) => {
                    return filter.autoSave
                  })
                  if (!autoSaveFilter) {
                    autoSaveFilter = {
                      id: uuidv4(),
                      name: '',
                      autoSave: true,
                      description: '',
                      filterValues: {}
                    }
                    pluginState.filters.push(autoSaveFilter)
                  }
                  autoSaveFilter.filterValues.BrandID = vm.get('BrandID')
                  autoSaveFilter.filterValues.DivisionID = vm.get('DivisionID')
                  autoSaveFilter.filterValues.SubDivisionID = vm.get('SubDivisionID')
                  autoSaveFilter.filterValues.AgeID = vm.get('AgeID')
                  autoSaveFilter.filterValues.LifeStyleID = vm.get('LifeStyleID')
                  autoSaveFilter.filterValues.CollectionGroupID = vm.get('CollectionGroupID')
                  autoSaveFilter.filterValues.BuyerGroupID = vm.get('BuyerGroupID')
                  autoSaveFilter.filterValues.ClassID = vm.get('ClassID')
                  autoSaveFilter.filterValues.RangeID = vm.get('RangeID')
                  autoSaveFilter.filterValues.SeasonID = vm.get('SeasonID')

                  var savePluginstateFunc = vm.get('savePluginstate')
                  if (_.isEmpty(autoSaveFilter.filterValues.SeasonID)) {
                    var warning = Ext.Msg.alert(
                      'Warning',
                      'Please select a Season Filter to proceed.'
                    )
                    warning.alignTo('filterWindow', 'c-c')
                  } else {
                    savePluginstateFunc(pluginState)
                    btn.up('window').close()
                  }
                }
              }
            ]
          }
        ]
      })
      this.getView().filterPopupWin = filterWin
    }
    var vm = me.getViewModel()
    this.getView().add(filterWin)
    filterWin.show()
  },

  OnFilterSelected(table) {
    var me = this
    var vm = me.getViewModel()
    me.SelectFilter(vm)
    table.up('window').close()
  },

  SelectFilter(viewModel) {
    var loadFiltersGrid = viewModel.get('loadFiltersGrid')
    const selectedRows = loadFiltersGrid.getSelectionModel().getSelection()
    if (_.size(selectedRows) > 0) {
      viewModel.set('BrandID', selectedRows[0].data.filterValue['BrandID'])
      viewModel.set('DivisionID', selectedRows[0].data.filterValue['DivisionID'])
      viewModel.set('SubDivisionID', selectedRows[0].data.filterValue['SubDivisionID'])
      viewModel.set('AgeID', selectedRows[0].data.filterValue['AgeID'])
      viewModel.set('LifeStyleID', selectedRows[0].data.filterValue['LifeStyleID'])
      viewModel.set('CollectionGroupID', selectedRows[0].data.filterValue['CollectionGroupID'])
      viewModel.set('BuyerGroupID', selectedRows[0].data.filterValue['BuyerGroupID'])
      viewModel.set('ClassID', selectedRows[0].data.filterValue['ClassID'])
      viewModel.set('RangeID', selectedRows[0].data.filterValue['RangeID'])
      viewModel.set('SeasonID', selectedRows[0].data.filterValue['SeasonID'])
    }
  },

  OnSaveFilter() {
    var me = this
    Ext.MessageBox.prompt(
      'Save Filter',
      'Please name your filter:',
      function (btn, text, cfg) {
        if (btn === 'ok') {
          if (_.size(text) > 0) {
            var vm = me.getViewModel()
            var pluginState = vm.get('pluginState')
            if (!pluginState) {
              pluginState = {}
            }
            if (!pluginState.filters) {
              pluginState.filters = []
            }

            var newFilter = {
              id: uuidv4(),
              name: text,
              autoSave: false,
              description: '',
              filterValues: {
                BrandID: vm.get('BrandID'),
                DivisionID: vm.get('DivisionID'),
                SubDivisionID: vm.get('SubDivisionID'),
                AgeID: vm.get('AgeID'),
                LifeStyleID: vm.get('LifeStyleID'),
                CollectionGroupID: vm.get('CollectionGroupID'),
                BuyerGroupID: vm.get('BuyerGroupID'),
                ClassID: vm.get('ClassID'),
                RangeID: vm.get('RangeID'),
                SeasonID: vm.get('SeasonID')
              }
            }

            pluginState.filters.push(newFilter)

            var savePluginstateFunc = vm.get('savePluginstate')
            savePluginstateFunc(pluginState)
            vm.set('pluginState', pluginState)
            Assortment.util.operationSuccessWithMessage('Filter is successfully saved.')
          } else {
            Ext.Msg.show(Ext.apply({}, {}, cfg))
          }
        }
      },
      this
    )
  },

  OnDeleteFilter(grid, rowIndex) {
    var me = this
    var rec = grid.getStore().getAt(rowIndex)
    grid.getStore().remove(rec)

    var filterToDelete = rec.get('id')
    var vm = me.getViewModel()
    var pluginState = vm.get('pluginState')

    if (pluginState && pluginState.filters) {
      pluginState.filters = _.transform(
        pluginState.filters,
        (res, filter) => {
          if (filter.autoSave || filter.id !== filterToDelete) {
            res.push(filter)
          }
          return res
        },
        []
      )
    }
    var savePluginstateFunc = vm.get('savePluginstate')
    savePluginstateFunc(pluginState)
    vm.set('pluginState', pluginState)

    Assortment.util.operationSuccessWithMessage('Filter is successfully deleted.')
  },

  OnLoadFilterNameRender(value, metaData, record) {
    var vm = this.getViewModel()
    const tooltip = this.createFiltersTooltip(vm)
    metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
    return value
  },

  loadPluginStateFilters() {
    var me = this

    var vm = me.getViewModel()
    var pluginState = vm.get('pluginState')

    if (pluginState && pluginState.filters) {
      var autoSaveFilter = _.find(pluginState.filters, (filter) => {
        return filter.autoSave
      })

      if (autoSaveFilter) {
        var pluginStateBrandID = autoSaveFilter.filterValues.BrandID
        var pluginStateDivisionID = autoSaveFilter.filterValues.DivisionID
        var pluginStateSubDivisionID = autoSaveFilter.filterValues.SubDivisionID
        var pluginStateAgeID = autoSaveFilter.filterValues.AgeID
        var pluginStateLifeStyleID = autoSaveFilter.filterValues.LifeStyleID
        var pluginStateCollectionGroupID = autoSaveFilter.filterValues.CollectionGroupID
        var pluginStateBuyerGroupID = autoSaveFilter.filterValues.BuyerGroupID
        var pluginStateClassID = autoSaveFilter.filterValues.ClassID
        var pluginStateRangeID = autoSaveFilter.filterValues.RangeID
        var pluginStateSeasonID = autoSaveFilter.filterValues.SeasonID

        var brandStore = me.getViewModel().get('BrandStore')
        brandStore.load()

        /************************************/
        var dependencyFilters = []
        dependencyFilters.push({
          Key: 'Brand',
          Value: [pluginStateBrandID]
        })

        var divisionStore = me.getViewModel().get('DivisionStore')
        divisionStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        divisionStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'Division',
          Value: [pluginStateDivisionID]
        })
        var subdivisionStore = me.getViewModel().get('SubDivisionStore')
        subdivisionStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        subdivisionStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'SubDivision',
          Value: [pluginStateSubDivisionID]
        })
        var ageStore = me.getViewModel().get('AgeStore')
        ageStore.getProxy().setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        ageStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'Age',
          Value: [pluginStateAgeID]
        })
        var lifeStyleStore = me.getViewModel().get('LifeStyleStore')
        lifeStyleStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        lifeStyleStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'LifeStyle',
          Value: [pluginStateLifeStyleID]
        })
        var collectionGroupStore = me.getViewModel().get('CollectionGroupStore')
        collectionGroupStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        collectionGroupStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'CollectionGroup',
          Value: [pluginStateCollectionGroupID]
        })
        var buyerGroupStore = me.getViewModel().get('BuyerGroupStore')
        buyerGroupStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        buyerGroupStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'BuyerGroup',
          Value: [pluginStateBuyerGroupID]
        })
        var classStore = me.getViewModel().get('ClassStore')
        classStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        classStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'Class',
          Value: [pluginStateClassID]
        })
        var rangeStore = me.getViewModel().get('RangeStore')
        rangeStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        rangeStore.load()

        /************************************/
        dependencyFilters.push({
          Key: 'Range',
          Value: [pluginStateRangeID]
        })
        var seasonStore = me.getViewModel().get('SeasonStore')
        seasonStore
          .getProxy()
          .setExtraParam('DependencyFilterStr', JSON.stringify(dependencyFilters))
        seasonStore.load()

        me.getViewModel().set('BrandID', pluginStateBrandID)
        me.getViewModel().set('DivisionID', pluginStateDivisionID)
        me.getViewModel().set('SubDivisionID', pluginStateSubDivisionID)
        me.getViewModel().set('AgeID', pluginStateAgeID)
        me.getViewModel().set('LifeStyleID', pluginStateLifeStyleID)
        me.getViewModel().set('CollectionGroupID', pluginStateCollectionGroupID)
        me.getViewModel().set('BuyerGroupID', pluginStateBuyerGroupID)
        me.getViewModel().set('ClassID', pluginStateClassID)
        me.getViewModel().set('RangeID', pluginStateRangeID)
        me.getViewModel().set('SeasonID', pluginStateSeasonID)
      }
    }
  },

  onCreateTestProduct: function () {
    var me = this
    var vm = me.getViewModel()

    var seasonID = vm.get('SeasonID')
    var brandID = vm.get('BrandID')
    var divisionID = vm.get('DivisionID')
    var subDivisionID = vm.get('SubDivisionID')
    var ageID = vm.get('AgeID')
    var lifeStyleID = vm.get('LifeStyleID')
    var collectionGroupID = vm.get('CollectionGroupID')
    var buyerGroupID = vm.get('BuyerGroupID')
    var classID = vm.get('ClassID')
    var rangeID = vm.get('RangeID')
    vm.set('testOptionSeasonId', seasonID)
    var currency = vm.get('currency')

    var testAgeStore = new Ext.data.Store()
    var testLifeStyleStore = new Ext.data.Store()
    var testCollectionGroupStore = new Ext.data.Store()
    var testClassStore = new Ext.data.Store()
    var testRangeStore = new Ext.data.Store()
    var testColorStore = new Ext.data.Store()
    var testPriceLevelsStore = new Ext.data.Store()
    var testPriceStore = new Ext.data.Store()
    var testSizeRangeStore = new Ext.data.Store()
    var testTaxRateStore = new Ext.data.Store()
    var testCountOfProductStore = new Ext.data.Store()
    var testSeasonStore = new Ext.data.Store()
    var startDate = Ext.Date.add(new Date(), Ext.Date.DAY, 7)
    var endDate = Ext.Date.add(startDate, Ext.Date.DAY, 56)

    var testProductWin = Ext.create('Ext.window.Window', {
      layout: 'fit',
      height: 500,
      width: 800,
      title: 'Create a Test Product',
      modal: true,
      scrollable: true,
      resizable: false,
      ui: 'window-inverse',
      id: 'testProductWindow',
      items: [
        {
          xtype: 'container',
          layout: 'border',
          // scrollable: true,
          items: [
            {
              xtype: 'form',
              region: 'center',
              margin: '4px 4px 4px 4px',
              scrollable: true,
              defaultType: 'textfield',
              style: 'border: 1px solid #bbb !important;',
              fieldDefaults: {
                msgTarget: 'side'
              },
              defaults: {
                border: false,
                xtype: 'panel',
                flex: 1,
                layout: 'anchor'
              },

              layout: 'hbox',

              items: [
                {
                  padding: '20px 25px 0px 50px',
                  items: [
                    {
                      xtype: 'fieldcontainer',
                      layout: 'hbox',
                      items: [
                        {
                          id: 'testName',
                          xtype: 'textfield',
                          fieldLabel: 'Name',
                          anchor: '-5',
                          value: 'New Test Product'
                        },

                        {
                          cls: 'only-icon-button',
                          iconCls: 'fa fa-external-link',
                          xtype: 'button',
                          tooltip: 'Select Similar Product',
                          handler: 'onShowSimilarProducts',
                          margin: '0px 0px 0px 10px'
                        }
                      ]
                    },
                    {
                      id: 'testStartDate',
                      xtype: 'datefield',
                      fieldLabel: 'Start Date',
                      anchor: '-5',
                      value: startDate
                    },
                    {
                      id: 'testEndDate',
                      xtype: 'datefield',
                      fieldLabel: 'End Date',
                      anchor: '-5',
                      value: endDate
                    },
                    {
                      id: 'testPrice',
                      xtype: 'combo',
                      fieldLabel: 'Price (' + currency + ')',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testPriceStore,
                      editable: false,
                      anchor: '-5'
                    },
                    {
                      id: 'testCost',
                      xtype: 'numberfield',
                      fieldLabel: 'Unit Cost (' + currency + ')',
                      hideTrigger: true,
                      anchor: '-5'
                    },
                    {
                      id: 'testTaxRate',
                      xtype: 'combo',
                      fieldLabel: 'Tax Rate',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testTaxRateStore,
                      editable: false,
                      anchor: '-5'
                    },
                    {
                      id: 'testSizeRange',
                      xtype: 'combo',
                      fieldLabel: 'Size Range',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testSizeRangeStore,
                      editable: false,
                      anchor: '-5'
                    },
                    {
                      id: 'testPriceLevels',
                      xtype: 'combo',
                      fieldLabel: 'PriceLevels',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testPriceLevelsStore,
                      editable: false,
                      anchor: '-5'
                    },
                    {
                      id: 'testSeasonCombo',
                      xtype: 'combo',
                      fieldLabel: 'Season',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testSeasonStore,
                      editable: false,
                      anchor: '-5',
                      allowBlank: false
                    },
                    {
                      id: 'testCountOfProduct',
                      xtype: 'combo',
                      fieldLabel: 'Number of Options',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testCountOfProductStore,
                      editable: false,
                      anchor: '-5'
                    }
                  ]
                },
                {
                  padding: '20px 50px 0px 25px',
                  items: [
                    {
                      id: 'testBrand',
                      xtype: 'textfield',
                      fieldLabel: 'Brand',
                      anchor: '100%',
                      value: brandID,
                      readOnly: true
                    },
                    {
                      id: 'testDivision',
                      xtype: 'textfield',
                      fieldLabel: 'Division',
                      anchor: '100%',
                      value: divisionID,
                      readOnly: true
                    },
                    {
                      id: 'testSubDivision',
                      xtype: 'textfield',
                      fieldLabel: 'SubDivision',
                      anchor: '100%',
                      value: subDivisionID,
                      readOnly: true
                    },
                    {
                      id: 'testAge',
                      xtype: 'textfield',
                      fieldLabel: 'Age',
                      value: ageID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testLifeStyle',
                      xtype: 'textfield',
                      fieldLabel: 'LifeStyle',
                      value: lifeStyleID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testCollectionGroup',
                      xtype: 'textfield',
                      fieldLabel: 'CollectionGroup',
                      value: collectionGroupID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testBuyerGroup',
                      xtype: 'textfield',
                      fieldLabel: 'BuyerGroup',
                      anchor: '100%',
                      value: buyerGroupID,
                      readOnly: true
                    },
                    {
                      id: 'testClass',
                      xtype: 'textfield',
                      fieldLabel: 'Class',
                      value: classID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testRange',
                      xtype: 'textfield',
                      fieldLabel: 'Range',
                      value: rangeID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testColor',
                      xtype: 'combo',
                      fieldLabel: 'Color',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testColorStore,
                      editable: false,
                      anchor: '100%'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          items: [
            '->',
            {
              text: 'Cancel',
              handler: function (btn) {
                btn.up('window').close()
              }
            },
            {
              text: 'Create',
              handler: 'onCreateTestOption'
            }
          ]
        }
      ]
    })
    this.getView().add(testProductWin)
    testProductWin.show()
    testProductWin.getEl().mask('Loading...')

    var testOptionDataDetails = vm.get('testOptionDataDetails')
    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/GetTestOptionData',
      method: 'POST',
      jsonData: {
        seasonID: seasonID,
        brandID: brandID,
        divisionID: divisionID,
        subDivisionID: subDivisionID,
        ageID: ageID,
        lifeStyleID: lifeStyleID,
        collectionGroupID: collectionGroupID,
        buyerGroupID: buyerGroupID,
        classID: classID,
        rangeID: rangeID,
        fetchFilters: testOptionDataDetails === null
      },
      timeout: 600000,
      success: function (response, opts) {
        var result = JSON.parse(response.responseText)

        if (testOptionDataDetails === null) {
          var { AttributeDetails, AttributeNames, AvailablePrices, AvailableSizeRanges } = result
          testOptionDataDetails = {
            AttributeDetails,
            AttributeNames,
            AvailablePrices,
            AvailableSizeRanges
          }
          vm.set('testOptionDataDetails', testOptionDataDetails)
        } else {
          result.AttributeDetails = testOptionDataDetails.AttributeDetails
          result.AttributeNames = testOptionDataDetails.AttributeNames
          result.AvailablePrices = testOptionDataDetails.AvailablePrices
          result.AvailableSizeRanges = testOptionDataDetails.AvailableSizeRanges
        }

        vm.set('testOptionData', result)
        vm.set('filteredtestOptionData', result)

        var getPossibleValues = (property) => {
          return _.map(result.AttributeDetails[property], (value, key) => {
            return { id: key, name: value }
          })
        }

        testAgeStore.loadData(getPossibleValues('Age'))
        testLifeStyleStore.loadData(getPossibleValues('LifeStyle'))
        testCollectionGroupStore.loadData(getPossibleValues('CollectionGroup'))
        testClassStore.loadData(getPossibleValues('Class'))
        testRangeStore.loadData(getPossibleValues('Range'))

        // Price
        var prices = _.map(
          _.orderBy(
            _.uniq(
              _.map(result.Products, (product) => {
                return product.BasePrice
              })
            )
          ),
          (price) => {
            return { id: price, name: price }
          }
        )

        testPriceStore.loadData(prices)

        var initialPrice = prices && _.size(prices) > 0 && prices[_.ceil(prices.length / 2) - 1].id

        Ext.getCmp('testPrice').setValue(initialPrice)

        // Cost
        var initialCost = (initialPrice || 0) / 3
        Ext.getCmp('testCost').setValue(initialCost)

        // ColorCode
        if (result.AttributeDetails.ColorCode) {
          var colorCode = _.map(result.AttributeDetails.ColorCode, (color) => {
            return { id: color, name: color }
          })
          testColorStore.loadData(colorCode)
          var initialColorCode =
            result.AttributeDetails.ColorCode[_.first(_.keys(result.AttributeDetails.ColorCode))]
          Ext.getCmp('testColor').setValue(initialColorCode)
        }

        // PriceLevels
        if (result.AttributeDetails.PriceLevel) {
          var priceLevel = _.map(result.AttributeDetails.PriceLevel, (level) => {
            return { id: level, name: level }
          })
          testPriceLevelsStore.loadData(priceLevel)
          var initialPriceLevel =
            result.AttributeDetails.PriceLevel[_.first(_.keys(result.AttributeDetails.PriceLevel))]
          Ext.getCmp('testPriceLevels').setValue(initialPriceLevel)
        }

        // Size Range
        var sizeRanges = _.map(result.AvailableSizeRanges, (range) => {
          return { id: range, name: range }
        })
        testSizeRangeStore.loadData(sizeRanges)

        var initialSizeRange =
          result.AvailableSizeRanges[_.first(_.keys(result.AvailableSizeRanges))]
        Ext.getCmp('testSizeRange').setValue(initialSizeRange)

        // Tax Rate
        var taxRates = [
          { id: 0.08, name: '8 %' },
          { id: 0.18, name: '18 %' }
        ]
        testTaxRateStore.loadData(taxRates)

        var initialTaxRate = taxRates && taxRates[0] && taxRates[0].id
        Ext.getCmp('testTaxRate').setValue(initialTaxRate)

        // Count Of Test Product //
        var numberOfProduct = []
        for (let i = 1; i <= 10; i++) {
          numberOfProduct.push({ id: i, name: `${i}` })
        }
        testCountOfProductStore.loadData(numberOfProduct)

        // Season
        var seasonIDStoreData = _.map(seasonID, (seasonID) => {
          return { id: seasonID, name: seasonID }
        })

        testSeasonStore.loadData(seasonIDStoreData)
        if (_.size(seasonID) === 1) {
          var seasonCombo = Ext.getCmp('testSeasonCombo')
          seasonCombo.setValue(seasonID[0])
        }

        var initalCountOfProduct = numberOfProduct && numberOfProduct[0] && numberOfProduct[0].id
        Ext.getCmp('testCountOfProduct').setValue(initalCountOfProduct)

        testProductWin.getEl().unmask()
      },
      failure: function (response, opts) {
        Assortment.util.operationFailWithMessage('Retrieving test product info failed')
        testProductWin.getEl().unmask()
      }
    })
  },

  onShowSimilarProducts() {
    var me = this
    var vm = me.getViewModel()
    var testOptionSeasonId = vm.get('testOptionSeasonId')
    var testOptionStore = vm.getStore('testOptionStore')

    var testOptionGrid = Ext.create({
      id: 'testOptionGrid',
      xtype: 'testOptionGrid',
      region: 'center',
      store: testOptionStore,
      style: 'border: 1px solid #bbb !important;'
    })

    var similarProductsWin = Ext.create('Ext.window.Window', {
      layout: 'border',
      height: 500,
      width: 800,
      title: 'Select a Product',
      modal: true,
      resizable: true,
      bodyPadding: 4,
      ui: 'window-inverse',
      id: 'similarProductsWin',
      bodyStyle: { background: 'white' },

      items: [
        {
          xtype: 'container',
          layout: {
            type: 'vbox',
            pack: 'start',
            align: 'stretch'
          },
          region: 'east',
          width: 168,
          margin: '0px 0px 0px 5px',
          items: [
            {
              xtype: 'container',
              style: 'border: 1px solid #bbb !important;',
              items: [
                {
                  xtype: 'image',
                  region: 'north',
                  shrinkWrap: true,
                  width: '100%',
                  height: 252,
                  imgCls: 'similarOptionImage',
                  bind: {
                    src: '{testsimilarOptionImage}'
                  }
                }
              ]
            },
            {
              xtype: 'container',
              items: []
            }
          ]
        },
        testOptionGrid
      ],
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'top',
          overflowHandler: 'scroller',
          items: [
            {
              xtype: 'combo',
              fieldLabel: 'Season',
              reference: 'testOptionSeason',
              valueField: 'name',
              displayField: 'name',
              width: 200,
              labelWidth: 60,
              queryMode: 'remote',
              forceSelection: true,
              listeners: {
                change: 'ontestOptionSeasonChange'
              },
              bind: {
                store: '{SeasonStore}',
                value: testOptionSeasonId
              }
            }
          ]
        },
        {
          xtype: 'toolbar',
          dock: 'bottom',
          items: [
            '->',
            {
              text: 'Cancel',
              handler: function (btn) {
                btn.up('window').close()
              }
            },
            {
              text: 'OK',
              handler: 'onSimilarProductSelected'
            }
          ]
        }
      ]
    })

    this.getView().add(similarProductsWin)
    similarProductsWin.show()

    testOptionGrid.getSelectionModel().select(0)
  },

  ontestOptionSeasonChange: function (combo, value) {
    var me = this
    var vm = me.getViewModel()

    var similarProductsWin = Ext.getCmp('similarProductsWin')
    similarProductsWin.getEl().mask('Loading...')

    vm.set('testOptionSeasonId', value)

    var brandID = vm.get('BrandID')
    var divisionID = vm.get('DivisionID')
    var subDivisionID = vm.get('SubDivisionID')
    var ageID = vm.get('AgeID')
    var lifeStyleID = vm.get('LifeStyleID')
    var collectionGroupID = vm.get('CollectionGroupID')
    var buyerGroupID = vm.get('BuyerGroupID')
    var classID = vm.get('ClassID')
    var rangeID = vm.get('RangeID')

    var testOptionDataDetails = vm.get('testOptionDataDetails')
    var testOptionStore = vm.getStore('testOptionStore')
    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/GetTestOptionData',
      method: 'POST',
      jsonData: {
        seasonID: [value],
        brandID: brandID,
        divisionID: divisionID,
        subDivisionID: subDivisionID,
        ageID: ageID,
        lifeStyleID: lifeStyleID,
        collectionGroupID: collectionGroupID,
        buyerGroupID: buyerGroupID,
        classID: classID,
        rangeID: rangeID,
        fetchFilters: false
      },
      timeout: 600000,
      success: function (response, opts) {
        var result = JSON.parse(response.responseText)

        result.AttributeDetails = testOptionDataDetails.AttributeDetails
        result.AttributeNames = testOptionDataDetails.AttributeNames
        result.AvailablePrices = testOptionDataDetails.AvailablePrices
        result.AvailableSizeRanges = testOptionDataDetails.AvailableSizeRanges

        vm.set('filteredtestOptionData', result)
        var smallCurrencyFormat = vm.get('smallCurrencyFormat')

        // Fill products
        var products = _.map(result.Products, (product) => {
          var getAttributeValue = (option, property) => {
            var attributeIndex = _.indexOf(result.AttributeNames, property)
            if (attributeIndex >= 0) {
              return result.AttributeDetails[property][option.AttributeIds[attributeIndex]]
            }
          }
          return {
            id: product.Id,
            name: product.Name,
            price: Ext.util.Format.number(product.BasePrice, smallCurrencyFormat),
            cost: Ext.util.Format.number(product.Expenditure, smallCurrencyFormat),
            division: getAttributeValue(product, 'Division'),
            subdivision: getAttributeValue(product, 'SubDivision'),
            age: getAttributeValue(product, 'Age'),
            lifeStyle: getAttributeValue(product, 'LifeStyle'),
            collectionGroup: getAttributeValue(product, 'CollectionGroup'),
            buyerGroup: getAttributeValue(product, 'BuyerGroup'),
            class: getAttributeValue(product, 'Class'),
            range: getAttributeValue(product, 'Range'),
            season: getAttributeValue(product, 'SeasonCode'),
            color: getAttributeValue(product, 'ColorCode'),
            priceLevels: getAttributeValue(product, 'PriceLevel'),
            image: product.ImageURL
          }
        })

        testOptionStore.loadData(products)
        similarProductsWin.getEl().unmask()
      },
      failure: function (response, opts) {
        Assortment.util.operationFailWithMessage('Failed to load products.')
        similarProductsWin.getEl().unmask()
      }
    })
  },

  onSimilarProductSelected: function (button) {
    var testOptionGrid = Ext.getCmp('testOptionGrid')
    var selectedRecords = testOptionGrid.getSelectionModel().getSelection()

    if (selectedRecords.length > 0) {
      var record = selectedRecords[0]

      var me = this
      var vm = me.getViewModel()

      var testOptionData = vm.get('filteredtestOptionData')

      var optionId = record.get('id')
      var option = _.find(testOptionData.Products, { Id: optionId })

      var getAttributeValue = (option, property) => {
        var attributeIndex = _.indexOf(testOptionData.AttributeNames, property)
        if (attributeIndex >= 0) {
          return testOptionData.AttributeDetails[property][option.AttributeIds[attributeIndex]]
        }
      }

      Ext.getCmp('testAge').setValue(getAttributeValue(option, 'Age'))
      Ext.getCmp('testLifeStyle').setValue(getAttributeValue(option, 'LifeStyle'))
      Ext.getCmp('testCollectionGroup').setValue(getAttributeValue(option, 'CollectionGroup'))
      Ext.getCmp('testBuyerGroup').setValue(getAttributeValue(option, 'BuyerGroup'))
      Ext.getCmp('testClass').setValue(getAttributeValue(option, 'Class'))
      Ext.getCmp('testRange').setValue(getAttributeValue(option, 'Range'))
      Ext.getCmp('testColor').setValue(getAttributeValue(option, 'ColorCode'))
      Ext.getCmp('testPriceLevels').setValue(getAttributeValue(option, 'PriceLevel'))
      Ext.getCmp('testPrice').setValue(option.BasePrice)
      Ext.getCmp('testCost').setValue(option.Expenditure)
    }
    button.up('window').close()
  },

  onTestOptionSelected: function (grid, record) {
    var me = this
    var vm = me.getViewModel()

    vm.set('testsimilarOptionImage', record.get('image'))
  },

  onTestOptionDblClicked: function (grid, td, cellIndex, record, tr, rowIndex, e, eOpts) {
    var me = this
    var vm = me.getViewModel()

    vm.set('testsimilarOptionImage', record.get('image'))
  },

  onCreateTestOption(button) {
    var me = this
    var vm = me.getViewModel()

    var brandValue = vm.get('BrandID')
    var divisionValue = Ext.getCmp('testDivision').value || 0
    var subDivisionValue = Ext.getCmp('testSubDivision').value || 0
    var ageValue = Ext.getCmp('testAge').value || 0
    var lifeStyleValue = Ext.getCmp('testLifeStyle').value || 0
    var collectionGroupValue = Ext.getCmp('testCollectionGroup').value || 0
    var buyerGroupValue = Ext.getCmp('testBuyerGroup').value || 0
    var classValue = Ext.getCmp('testClass').value || 0
    // StyleCode
    var colorCodeValue = Ext.getCmp('testColor').value || 0

    var cost = Ext.getCmp('testCost').value || 0
    var Name = Ext.getCmp('testName').value || ''
    var BasePrice = Ext.getCmp('testPrice').value || 0
    var SizeRange = Ext.getCmp('testSizeRange').value || ''
    var similarProductId = vm.get('testOptionId') || 0
    var clusterNodeId = vm.get('NodeId') || 0
    var rangeValue = Ext.getCmp('testRange').value || 0
    var seasonValue = Ext.getCmp('testSeasonCombo').value || 0
    var priceLevelsValue = Ext.getCmp('testPriceLevels').value || 0
    var taxRateValue = Ext.getCmp('testTaxRate').value || 0
    var startDateValue = Ext.getCmp('testStartDate').value
    var endDateValue = Ext.getCmp('testEndDate').value
    var testOptionData = vm.get('testOptionData')
    var countOfProduct = Ext.getCmp('testCountOfProduct').value || 0

    if (seasonValue === 0) {
      Ext.Msg.alert('Test Product', 'Please select a season.')
      return
    }

    var brandId =
      _.findKey(testOptionData.AttributeDetails.Brand, (value) => {
        return value === brandValue
      }) || 0

    var divisionId =
      _.findKey(testOptionData.AttributeDetails.Division, (value) => {
        return value === divisionValue
      }) || 0

    var subDivisionId =
      _.findKey(testOptionData.AttributeDetails.SubDivision, (value) => {
        return value === subDivisionValue
      }) || 0

    var ageID =
      _.findKey(testOptionData.AttributeDetails.Age, (value) => {
        return value === ageValue
      }) || 0

    var lifeStyleId =
      _.findKey(testOptionData.AttributeDetails.LifeStyle, (value) => {
        return value === lifeStyleValue
      }) || 0

    var collectionGroupID =
      _.findKey(testOptionData.AttributeDetails.CollectionGroup, (value) => {
        return value === collectionGroupValue
      }) || 0

    var buyerGroupId =
      _.findKey(testOptionData.AttributeDetails.BuyerGroup, (value) => {
        return value === buyerGroupValue
      }) || 0

    var classID =
      _.findKey(testOptionData.AttributeDetails.Class, (value) => {
        return value === classValue
      }) || 0

    var rangeID =
      _.findKey(testOptionData.AttributeDetails.Range, (value) => {
        return value === rangeValue
      }) || 0

    // Style Code

    var colorCodeID =
      _.findKey(testOptionData.AttributeDetails.ColorCode, (value) => {
        return value === colorCodeValue
      }) || 0

    var seasonId =
      _.findKey(testOptionData.AttributeDetails.SeasonCode, (value) => {
        return value === seasonValue
      }) || 0

    var priceLevelID =
      _.findKey(testOptionData.AttributeDetails.PriceLevel, (value) => {
        return value === priceLevelsValue
      }) || 0

    var SizeRangeId =
      _.findKey(testOptionData.AvailableSizeRanges, (value) => {
        return value === SizeRange
      }) || 0
    var attributeIds = [
      brandId,
      divisionId,
      subDivisionId,
      ageID,
      lifeStyleId,
      collectionGroupID,
      buyerGroupId,
      classID,
      rangeID,
      0,
      colorCodeID,
      seasonId,
      0,
      0,
      0,
      0,
      priceLevelID,
      0,
      0
    ]
    var testProductWindow = Ext.getCmp('testProductWindow')
    var testProducts = []
    for (let i = 1; i <= countOfProduct; i++) {
      testProducts.push({
        SimilarProductId: similarProductId,
        ClusterNodeId: clusterNodeId,
        Name: Name + ' ' + i,
        BasePrice: BasePrice,
        SizeRange: SizeRange,
        SizeRangeId: SizeRangeId,
        SizeRangeTypeId: SizeRangeId,
        TaxRate: taxRateValue,
        Expenditure: cost,
        startDate: startDateValue,
        EndDate: endDateValue,
        AttributeIds: attributeIds
      })
    }

    var element = testProductWindow.getEl()
    element.mask('Creating Product...')
    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/CreateMultipleTestOptions',
      method: 'POST',
      params: {
        TestProduct: Ext.JSON.encode(testProducts)
      },
      timeout: 600000,
      success: function (response, opts) {
        var result = JSON.parse(response.responseText)

        var assortmentData = vm.get('AssortmentData')
        if (assortmentData) {
          Ext.Ajax.request({
            url: Assortment.Global.getApiUrl() + 'Assortment/MergeTestOption',
            method: 'POST',
            params: {
              SourceOptionId: result.AssortmentId,
              TargetOptionId: assortmentData.AssortmentId
            },
            timeout: 600000,
            success: function (response, opts) {
              var result = JSON.parse(response.responseText)

              me.initializeStores(result, me)
              element.unmask()
              button.up('window').close()
            },
            failure: function (conn, response, options, eOpts) {
              Assortment.util.operationFailWithMessage('Failed to merge product.')
              element.unmask()
            }
          })
        } else {
          me.initializeStores(result, me)

          element.unmask()
          button.up('window').close()
        }
      },
      failure: function (conn, response, options, eOpts) {
        Assortment.util.operationFailWithMessage('Failed to create product.')
        element.unmask()
      }
    })
  },

  onReloadProducts: function () {
    var me = this
    var vm = me.getViewModel()
    var element = me.getView().getEl()
    element.mask('Loading Assortment Data...')

    var SeasonID = vm.get('SeasonID')
    var brandID = vm.get('BrandID')
    var DivisionID = vm.get('DivisionID')
    var SubDivisionID = vm.get('SubDivisionID')
    var ageID = vm.get('AgeID')
    var lifeStyleID = vm.get('LifeStyleID')
    var collectionGroupID = vm.get('CollectionGroupID')
    var BuyerGroupID = vm.get('BuyerGroupID')

    var classID = vm.get('ClassID')
    var rangeID = vm.get('RangeID')

    var ClusterNodeId = vm.get('NodeId')
    var ClusterName = vm.get('ClusterName')

    var dependencyFilters = []
    dependencyFilters.push({
      Key: 'SeasonCode',
      Value: SeasonID || []
    })
    dependencyFilters.push({
      Key: 'Brand',
      Value: brandID ? [brandID] : []
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: DivisionID ? [DivisionID] : []
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: SubDivisionID ? [SubDivisionID] : []
    })
    dependencyFilters.push({
      Key: 'Age',
      Value: ageID ? [ageID] : []
    })
    dependencyFilters.push({
      Key: 'LifeStyle',
      Value: lifeStyleID ? [lifeStyleID] : []
    })
    dependencyFilters.push({
      Key: 'CollectionGroup',
      Value: collectionGroupID ? [collectionGroupID] : []
    })
    dependencyFilters.push({
      Key: 'BuyerGroup',
      Value: BuyerGroupID ? [BuyerGroupID] : []
    })
    dependencyFilters.push({
      Key: 'Class',
      Value: classID ? [classID] : []
    })
    dependencyFilters.push({
      Key: 'Range',
      Value: rangeID ? [rangeID] : []
    })

    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/Load',
      method: 'POST',
      params: {
        DependencyFilterStr: JSON.stringify(dependencyFilters),
        ClusterNodeId: ClusterNodeId,
        ClusterName: ClusterName
      },
      timeout: 600000,
      success: function (conn, response, options, eOpts) {
        var result = JSON.parse(conn.responseText)
        var assortmentData = vm.get('AssortmentData')

        if (result.Code > 0) {
          Ext.Ajax.request({
            url: Assortment.Global.getApiUrl() + 'Assortment/OverrideAssortmentData',
            method: 'POST',
            params: {
              SourceOptionId: result.AssortmentId,
              TargetOptionId: assortmentData.AssortmentId
            },
            timeout: 600000,
            success: function (conn, opts) {
              var result = JSON.parse(conn.responseText)
              me.initializeStores(result, me)
              element.unmask()
            },
            failure: function (conn, response, options, eOpts) {
              Assortment.util.operationFailWithMessage('Failed to merge product.')
              element.unmask()
            }
          })
        } else {
          Assortment.util.operationFailWithMessage('Product not found.')
        }

        element.unmask()
      },
      failure: function (conn, response, options, eOpts) {
        Assortment.util.operationFailWithMessage('Failed to retrieve data.')
        element.unmask()
      }
    })
  },

  onExecute: function () {
    var me = this
    var vm = me.getViewModel()
    var element = me.getView().getEl()
    element.mask('Loading Assortment Data...')

    var SeasonID = vm.get('SeasonID')
    var brandID = vm.get('BrandID')
    var DivisionID = vm.get('DivisionID')
    var SubDivisionID = vm.get('SubDivisionID')
    var ageID = vm.get('AgeID')
    var lifeStyleID = vm.get('LifeStyleID')
    var collectionGroupID = vm.get('CollectionGroupID')
    var BuyerGroupID = vm.get('BuyerGroupID')

    var classID = vm.get('ClassID')
    var rangeID = vm.get('RangeID')

    var ClusterNodeId = vm.get('NodeId')
    var ClusterName = vm.get('ClusterName')

    var dependencyFilters = []
    dependencyFilters.push({
      Key: 'SeasonCode',
      Value: SeasonID || []
    })
    dependencyFilters.push({
      Key: 'Brand',
      Value: brandID ? [brandID] : []
    })
    dependencyFilters.push({
      Key: 'Division',
      Value: DivisionID ? [DivisionID] : []
    })
    dependencyFilters.push({
      Key: 'SubDivision',
      Value: SubDivisionID ? [SubDivisionID] : []
    })
    dependencyFilters.push({
      Key: 'Age',
      Value: ageID ? [ageID] : []
    })
    dependencyFilters.push({
      Key: 'LifeStyle',
      Value: lifeStyleID ? [lifeStyleID] : []
    })
    dependencyFilters.push({
      Key: 'CollectionGroup',
      Value: collectionGroupID ? [collectionGroupID] : []
    })
    dependencyFilters.push({
      Key: 'BuyerGroup',
      Value: BuyerGroupID ? [BuyerGroupID] : []
    })
    dependencyFilters.push({
      Key: 'Class',
      Value: classID ? [classID] : []
    })
    dependencyFilters.push({
      Key: 'Range',
      Value: rangeID ? [rangeID] : []
    })

    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/Load',
      method: 'POST',
      params: {
        DependencyFilterStr: JSON.stringify(dependencyFilters),
        ClusterNodeId: ClusterNodeId,
        ClusterName: ClusterName
      },
      timeout: 600000,
      success: function (conn, response, options, eOpts) {
        var assortmentData = JSON.parse(conn.responseText)
        vm.set('isActualSeason', true)
        if (assortmentData.Code > 0) {
          me.initializeFilterSettings(assortmentData, me)
          me.initializeStores(assortmentData, me)
        } else {
          Assortment.util.operationFailWithMessage('Product not found.')
        }

        element.unmask()
      },
      failure: function (conn, response, options, eOpts) {
        Assortment.util.operationFailWithMessage('Failed to retrieve data.')
        element.unmask()
      }
    })
  },

  initializeFilterSettings: function (assortmentData, me) {
    if (!assortmentData) return

    var vm = me.getViewModel()

    assortmentData.Filters.forEach(function (filter) {
      var storeName = filter.Name + 'Store'
      if (storeName === 'SeasonCodeStore') {
        storeName = 'SeasonStore'
      }
      var filterStore = this.getViewModel().getStore(storeName)

      if (filterStore) {
        filterStore.loadData(
          filter.Values.map(function (value) {
            return { name: value }
          })
        )
      }
    }, this)

    assortmentData.Filters.forEach(function (filter) {
      var variableName = filter.Name + 'ID'
      var variableValue = filter.Values[filter.SelectedIndices[0]]

      if (variableName === 'SeasonCodeID') {
        variableName = 'SeasonID'

        variableValue = _.map(filter.SelectedIndices, (selectedIndex) => {
          return filter.Values[selectedIndex]
        })
      }
      if (variableValue !== 'undefined') {
        vm.set(variableName, variableValue)
      }
    }, this)

    vm.set('NodeId', assortmentData.ClusterNodeId)
    vm.set('ClusterName', assortmentData.ClusterName)
  },
  initializeStores: function (assortmentData, me, afterUpdate = false) {
    if (!assortmentData) return

    var vm = me.getViewModel()
    vm.set('AssortmentData', assortmentData)

    var groupedStores = _.groupBy(assortmentData.Stores, 'SegmentId')
    var availableSegmentIds = _.keys(groupedStores)

    var availableSegments = _.filter(assortmentData.Segments, (segment) => {
      return _.indexOf(availableSegmentIds, segment.Id.toString()) > -1
    })

    vm.set('AvailableSegments', availableSegments)

    var smallCurrencyFormat = vm.get('smallCurrencyFormat')
    var largeCurrencyFormat = vm.get('largeCurrencyFormat')

    // columns
    var columns = []

    columns.push({
      name: 'Product',
      text: '<b>Product</b>',
      xtype: 'imgcolumn',
      fields: ['Name', 'BasePrice', 'Editable', 'optionModified'],
      dataIndex: 'Name',
      locked: true,
      menuDisabled: false,
      sortable: true,
      hideable: false,
      lockable: false,
      filter: {
        type: 'string',
        dataIndex: 'Name'
      }
    })

    columns.push({
      text: 'Summary',
      dataIndex: 'TotalRevenue',
      locked: true,
      width: 350,
      minWidth: 350,
      menuDisabled: true,
      sortable: false,
      hideable: false,
      lockable: false,
      renderer: function (value, meta, record) {
        var totalRevenue = Ext.util.Format.numberRenderer(largeCurrencyFormat)(
          record.data.TotalRevenue
        )
        var totalSales = Ext.util.Format.numberRenderer('0,000')(record.data.TotalSales)
        var buyQuantity = Ext.util.Format.numberRenderer('0,000')(record.data.TotalBuyQuantity)
        var minPresentation = Ext.util.Format.numberRenderer('0,000')(
          record.data.TotalMinPresentation
        )

        var numberOfActiveStores = Ext.util.Format.numberRenderer('0,000')(
          record.data.NumberOfActiveStores
        )

        var totalCost = Ext.util.Format.numberRenderer(largeCurrencyFormat)(record.data.TotalCost)

        var unitCost = Ext.util.Format.numberRenderer(smallCurrencyFormat)(record.data.Expenditure)
        var IMU = Ext.util.Format.numberRenderer('0,000.00')(record.data.IMU)
        var endDate = Ext.util.Format.date(record.data.EndDate, 'd/m/Y')
        var startDate = Ext.util.Format.date(record.data.StartDate, 'd/m/Y')

        var rosFormatted = Ext.util.Format.numberRenderer('0,000.00')(record.data.ROS)
        var sizeRange = ''
        if (record.data.SizeRangeId > 0) {
          sizeRange = assortmentData.SizeRangeMapping[record.data.SizeRangeId]
        }

        const globalTotalStockVal = _.isNil(record.data.GlobalTotalStock)
          ? ``
          : `
          <tr>
          <td><b>Global Total Stock</b></td>
          <td>${record.data.GlobalTotalStock}</td>
        </tr>`

        return (
          `<table class="assortmentSummary">
          <tr>
            <td><b>Forecasted Revenue</b></td>
            <td> ${totalRevenue}</td>
          </tr>
          <tr>
            <td><b>Forecasted Quantity</b></td>
            <td> ${totalSales}</td>
          </tr>
          <tr>
            <td><b>Forecasted Cost</b></td>
            <td> ${totalCost}</td>
          </tr>
          <tr>
            <td><b>Unit Cost / IMU </b></td>
            <td> ${unitCost} / ${IMU}</td>
          </tr>
          <tr>
            <td><b>Start Date</b></td>
            <td> ${startDate}</td>
          </tr>
          <tr>
            <td><b>End Date</b></td>
            <td> ${endDate}</td>
          </tr>
          <tr>
            <td><b>Min Presentation</b></td>
            <td> ${minPresentation}</td>
          </tr>
          <tr>
            <td><b>Buy Quantity Final</b></td>
            <td> ${buyQuantity}</td>
          </tr>
          <tr>
            <td><b>Size Range Type</b></td>
            <td class="sizeRange">${sizeRange}</td>
          </tr>
          <tr>
            <td><b>Number Of Stores</b></td>
            <td> ${numberOfActiveStores}</td>
          </tr>
          <tr>
            <td><b>ROS</b></td>
            <td> ${rosFormatted}</td>
          </tr>` +
          globalTotalStockVal +
          `
       </table>`
        )
      },
      summaryType: function (records) {
        var summary = {
          TotalSales: 0,
          TotalRevenue: 0,
          NumberOfActiveStores: 0,
          TotalCost: 0,
          NumberOfOptions: 0,
          NumberOfUsedOptions: 0
        }

        if (records.length === 0) return summary
        var assortmentData = vm.get('AssortmentData')
        _.forEach(assortmentData.Options, (option) => {
          if (_.filter(option.SegmentOptions, ['Enabled', true]).length !== 0) {
            summary.NumberOfUsedOptions++
          }
        })
        records.forEach(function (record) {
          summary.TotalSales += record.get('TotalSales')
          summary.TotalRevenue += record.get('TotalRevenue')
          summary.TotalCost += record.get('TotalCost')
          summary.NumberOfActiveStores += record.get('NumberOfActiveStores')
          summary.NumberOfOptions += 1
        }, this)

        return summary
      },
      summaryRenderer: function (value) {
        return `<table class="assortmentSummary -as-top">
          <tr>
            <td><b>Forecasted Revenue</b></td>
            <td> ${Ext.util.Format.numberRenderer(largeCurrencyFormat)(value.TotalRevenue)}</td>
          </tr>
          <tr>
            <td><b>Forecasted Quantity</b></td>
            <td> ${Ext.util.Format.numberRenderer('0,000')(value.TotalSales)}</td>
          </tr>
          <tr>
            <td><b>Forecasted Cost</b></td>
            <td> ${Ext.util.Format.numberRenderer(largeCurrencyFormat)(value.TotalCost)}</td>
          </tr>
          <tr>
            <td><b>Number Of Stores</b></td>
            <td> ${Ext.util.Format.numberRenderer('0,000')(value.NumberOfActiveStores)}</td>
          </tr>
          <tr>
            <td><b>Assigned Options / Options</b></td>
            <td>${value.NumberOfUsedOptions} /${value.NumberOfOptions}</td>
          </tr>
          </table>`
      }
    })

    columns = columns.concat(
      availableSegments.map(function (segment) {
        return {
          text: segment.Name,
          name: 'Product',
          xtype: 'imgcolumn2',
          fields: ['optionModified'],
          dataIndex: segment.Name,
          segmentId: segment.Id,
          menuDisabled: true,
          sortable: false,
          width: 185,
          summaryType: function (records) {
            var summary = {
              TotalSales: 0,
              TotalRevenue: 0,
              NumberOfStores: 0,
              TotalCost: 0,
              NumberOfOptions: 0
            }
            var assortmentData = vm.get('AssortmentData')
            var currentSegment = assortmentData.Segments[segment.Id]

            summary.TotalSales = currentSegment.TotalSales
            summary.TotalRevenue = currentSegment.TotalRevenue
            summary.NumberOfStores = currentSegment.NumberOfStores
            summary.TotalCost = currentSegment.TotalCost
            summary.NumberOfOptions = currentSegment.NumberOfOptions

            return summary
          },
          summaryRenderer: function (value) {
            return `<table class="assortmentSummary -as-top-r">
              <tr>
                <td> ${Ext.util.Format.numberRenderer(largeCurrencyFormat)(value.TotalRevenue)}</td>
              </tr>
              <tr>
                <td> ${Ext.util.Format.numberRenderer('0,000')(value.TotalSales)}</td>
              </tr>
              <tr>
                <td> ${Ext.util.Format.numberRenderer(largeCurrencyFormat)(value.TotalCost)}</td>
              </tr>
              <tr>
              <td> ${Ext.util.Format.numberRenderer('0,000')(value.NumberOfStores)}</td>
              </tr>
              <tr>
              <td> ${Ext.util.Format.numberRenderer('0,000')(value.NumberOfOptions)}</td>
              </tr>
              </table>`
          }
        }
      })
    )

    var gridStore = this.getViewModel().getStore('gridStore')
    if (!afterUpdate) {
      var references = this.getView().getReferences()

      var grid = references.assortmentGrid

      grid.reconfigure(gridStore, columns)
    }
    const optionsData = _.map(assortmentData.Options, (option) => {
      option.BasePriceFormatted = Ext.util.Format.number(option.BasePrice, smallCurrencyFormat)
      return option
    })

    gridStore.loadData($.extend(true, [], optionsData))
  },

  onSaveAssortment: function () {
    var me = this
    var viewModel = me.getViewModel()
    var assortmentData = viewModel.get('AssortmentData')

    var assortment = {
      AssortmentId: assortmentData.AssortmentId,
      NodeStr: null
    }

    Ext.create('Assortment.assortmentLibrary.AssortmentLibrary', {
      referencePanel: me,
      items: [
        {
          xtype: 'panel',
          region: 'center',
          layout: 'fit',
          margin: 10,
          overflowY: 'auto',
          items: [
            {
              xtype: 'assortmentTree',
              referencePanel: me,
              mode: 'Save'
            }
          ]
        }
      ],
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          layout: {
            type: 'hbox',
            pack: 'center'
          },
          defaults: {
            flex: 1
          },
          items: [
            {
              text: 'New Folder',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                if (!currentRow) return

                var assortmentLibrary = this.lookupReferenceHolder()
                var references = assortmentLibrary.getReferences()
                var assortmentTree = references['assortmentTree']

                if (!assortmentTree) return

                me.getEl().mask('Loading...')

                var nodeId = currentRow.get('Id')

                Ext.Ajax.request({
                  url: Assortment.Global.getApiUrl() + 'LibraryTreeCreateFolderNode',
                  params: {
                    MotherId: nodeId,
                    Name: 'New Folder',
                    Status: 'FOLDER',
                    ProjectId: 1
                  },
                  timeout: 600000,
                  success: function (response) {
                    var result = Ext.JSON.decode(response.responseText)

                    if (result.Code > -1) {
                      Assortment.util.operationSuccessWithMessage(result.Description)
                      assortmentTree.store.reload()
                    } else {
                      Assortment.util.operationFailWithMessage(result.Description)
                    }

                    me.getEl().unmask()
                  },
                  failure: function (response, opts) {
                    me.getEl().unmask()
                    Assortment.util.operationFailWithMessage('Create folder has failed.')
                  }
                })
              },
              bind: {
                disabled: '{!isFolderSelected}'
              }
            },
            {
              text: 'Save',
              iconCls: 'fa fa-floppy-o',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                var form = new Ext.FormPanel({
                  labelWidth: 100,
                  defaults: {
                    margin: '10px'
                  },
                  items: [
                    {
                      xtype: 'textfield',
                      fieldLabel: 'Name',
                      name: 'text',
                      allowBlank: false
                    },
                    {
                      xtype: 'textfield',
                      fieldLabel: 'Description',
                      name: 'description',
                      allowBlank: false
                    }
                  ],
                  buttons: [
                    {
                      text: 'OK',
                      handler: function () {
                        var item = this.up('form').getValues()

                        var assortmentName = item['text']

                        var description = item['description']

                        var node = {
                          MotherId: currentRow.get('Id'),
                          Name: assortmentName,
                          Description: description,
                          ProjectId: 1
                        }

                        assortment.NodeStr = JSON.stringify(node)

                        var element = me.getEl()
                        element.mask('Loading...')

                        Ext.Ajax.request({
                          url: Assortment.Global.getApiUrl() + 'Assortment/Save',
                          params: assortment,
                          timeout: 600000,
                          success: function (response, opts) {
                            var result = Ext.JSON.decode(response.responseText)

                            if (result.Code > -1) {
                              Assortment.util.operationSuccessWithMessage(result.Description)

                              viewModel.set('selectedAssortmentName', assortmentName)

                              var pluginState = viewModel.get('pluginState')
                              if (_.indexOf(pluginState.recentAssortments, result.Code) < 0) {
                                var savePluginstateFunc = viewModel.get('savePluginstate')
                                pluginState.recentAssortments.push(result.Code)
                                if (_.size(pluginState.recentAssortments) > 3) {
                                  pluginState.recentAssortments.shift()
                                }
                                savePluginstateFunc(pluginState)
                                viewModel.set('pluginState', pluginState)
                              }

                              element.unmask()
                            } else {
                              Assortment.util.operationFailWithMessage(result.Description)
                              element.unmask()
                            }

                            me.close()
                          },
                          failure: function () {
                            element.unmask()
                            Assortment.util.operationSuccessWithMessage(
                              'Save assortment has failed.'
                            )

                            me.close()
                          }
                        })

                        loginDlg.close()
                      }
                    },
                    {
                      text: 'Cancel',
                      handler: function () {
                        loginDlg.close()
                      }
                    }
                  ]
                })

                var loginDlg = new Ext.Window({
                  closable: true,
                  closeAction: function () {
                    this.onNewRec('cancelled', '')
                  },
                  modal: true,
                  title: 'New Assortment',
                  layout: 'fit',
                  items: form
                })

                loginDlg.show()
                // we need window here.
                // node should be created here.
              },
              assortment: assortment,
              bind: {
                disabled: '{!isFolderSelected}'
              }
            }
          ]
        }
      ]
    }).show()
  },

  createFiltersTooltip(viewModel) {
    var tooltip = ''

    var SeasonID = viewModel.get('SeasonID')
    var BrandID = viewModel.get('BrandID')
    var DivisionID = viewModel.get('DivisionID')
    var SubDivisionID = viewModel.get('SubDivisionID')
    var AgeID = viewModel.get('AgeID')
    var LifeStyleID = viewModel.get('LifeStyleID')
    var CollectionGroupID = viewModel.get('CollectionGroupID')
    var BuyerGroupID = viewModel.get('BuyerGroupID')
    var ClassID = viewModel.get('ClassID')
    var RangeID = viewModel.get('RangeID')

    if (BrandID) {
      tooltip += '<li><b>Brand: </b>' + BrandID + '</li>'
    }
    if (DivisionID) {
      tooltip += '<li><b>Division: </b>' + DivisionID + '</li>'
    }
    if (SubDivisionID) {
      tooltip += '<li><b>SubDivision: </b>' + SubDivisionID + '</li>'
    }
    if (AgeID) {
      tooltip += '<li><b>Age: </b>' + AgeID + '</li>'
    }
    if (LifeStyleID) {
      tooltip += '<li><b>LifeStyle: </b>' + LifeStyleID + '</li>'
    }
    if (CollectionGroupID) {
      tooltip += '<li><b>CollectionGroup: </b>' + CollectionGroupID + '</li>'
    }
    if (BuyerGroupID) {
      tooltip += '<li><b>BuyerGroup: </b>' + BuyerGroupID + '</li>'
    }
    if (ClassID) {
      tooltip += '<li><b>Class: </b>' + ClassID + '</li>'
    }
    if (RangeID) {
      tooltip += '<li><b>Range: </b>' + RangeID + '</li>'
    }
    if (SeasonID) {
      tooltip += '<li><b>Season: </b>' + SeasonID + '</li>'
    }

    if (tooltip) {
      tooltip = '<ul style="list-style-type:none; padding-left:10px">' + tooltip
      tooltip = tooltip + '</ul>'
    }

    if (!tooltip) {
      tooltip = 'No filters selected'
    }
    return tooltip
  },

  afterRender: function () {
    var me = this
    Ext.create('Ext.tip.ToolTip', {
      target: 'SelectFiltersButton',
      html: 'Press this button to clear the form',
      title: 'Filters',
      anchor: 'top',
      listeners: {
        beforeshow: function updateTipBody(tip) {
          var viewModel = me.getViewModel()
          var tooltip = me.createFiltersTooltip(viewModel)
          tip.update(tooltip)
        }
      }
    })
    Ext.create('Ext.tip.ToolTip', {
      target: 'AssortmentStatus',
      title: 'Assortment',
      anchor: 'top',
      width: 150,
      listeners: {
        beforeshow: function updateTipBody(tip) {
          var viewModel = me.getViewModel()
          var status = viewModel.get('CurrentNode').Status
          var tooltip = ''
          if (status) {
            tooltip += '<b>Status: </b>' + status
          }
          tip.update(tooltip)
        }
      }
    })
  },

  onUpdateAssortment: function () {
    var me = this
    var viewModel = me.getViewModel()
    var view = me.getView()
    var element = view.getEl()
    element.mask('Saving...')

    var currentNode = viewModel.get('CurrentNode')
    var node = {
      Children: currentNode.Children,
      Id: currentNode.Id,
      IsLeaf: currentNode.IsLeaf,
      Name: currentNode.Name,
      ProjectId: currentNode.ProjectId,
      Status: 'EDITING',
      MotherId: currentNode.MotherId
    }

    var assortmentData = viewModel.get('AssortmentData')

    var assortment = {
      AssortmentId: assortmentData.AssortmentId,
      NodeStr: JSON.stringify(node)
    }

    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/Update',
      params: assortment,
      timeout: 600000,
      success: function (response, opts) {
        var result = Ext.JSON.decode(response.responseText)

        if (result.Code > -1) {
          Assortment.util.operationSuccessWithMessage(result.Description)

          if (result.Code > 1) assortmentData.AssortmentId = result.Code
          currentNode.Status = 'EDITING'
          viewModel.set('CurrentNode', currentNode)
          viewModel.set('AssortmentData', assortmentData)

          element.unmask()
        } else {
          Assortment.util.operationFailWithMessage(result.Description)
          element.unmask()
        }
      },
      failure: function (response, opts) {
        element.unmask()
        Assortment.util.operationSuccessWithMessage('Update assortment has failed.')
      }
    })
  },

  onApprove: function (editor, context, eOpts) {
    var me = this
    var vm = me.getViewModel()
    var currentNode = vm.get('CurrentNode')
    var view = me.getView()
    var element = view.getEl()
    element.mask('Loading...')
    currentNode.Status = 'APPROVED'
    vm.set('CurrentNode', currentNode)
    var node = $.extend(true, {}, currentNode)
    // removed nto needed arguments

    delete node['Children']
    delete node['Filters']
    delete node['id']
    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'LibraryTreeUpdateNode',
      params: node,
      timeout: 600000,
      success: function (response, opts) {
        var result = Ext.JSON.decode(response.responseText)

        if (result.Code > -1) {
          Assortment.util.operationSuccessWithMessage(result.Description)
        } else {
          Assortment.util.operationFailWithMessage(result.Description)
        }
        var assortmentData = vm.get('AssortmentData')
        var eventParams = {
          AssortmentId: node['Id'],
          ClusterNodeId: assortmentData.ClusterNodeId
        }
        view.onAssortmentApproved(eventParams)

        element.unmask()
      },
      failure: function (response, opts) {
        element.unmask()
        alert('Node update failed.')
      }
    })
  },

  onitemclick: function (view, cell, cellIndex, record, row, rowIndex, e) {
    var me = this
    var vm = me.getViewModel()
    var element = me.getView().getEl()
    var optionModified = 0
    var delayUpdates = vm.get('delayUpdates')
    var instantSaveRequest = !view.isLockedView

    if (view.isLockedView && cellIndex == 1) {
      // Return when summary column
      return
    }

    if (!view.isLockedView) {
      if (_.toInteger(record.data.SizeRangeId) === 0) {
        Ext.MessageBox.alert('Warning', 'Please enter a valid "Size Range Type".')
        return false
      }
      var isFutureOption = record.get('Editable')

      if (isFutureOption === false) {
        Ext.MessageBox.alert(
          'Warning',
          'This product is approved and it is locked to further changes.'
        )
        return false
      }

      // Assign option to the segment
      var assortmentDataPrev = vm.get('AssortmentData')
      var segmentOptions = record.get('SegmentOptions')

      var availableSegments = vm.get('AvailableSegments')

      var modifiedCellIndex = _.findKey(segmentOptions, (segmentOption, key) => {
        return segmentOption.SegmentId == availableSegments[cellIndex].Id
      })

      if (!_.has(segmentOptions[modifiedCellIndex], 'OriginalEnabled')) {
        segmentOptions[modifiedCellIndex].OriginalEnabled =
          segmentOptions[modifiedCellIndex].Enabled
      }
      segmentOptions[modifiedCellIndex].Enabled = !segmentOptions[modifiedCellIndex].Enabled
      record.set('SegmentOptions', segmentOptions)

      if (delayUpdates) {
        _.forEach(segmentOptions, (segmentOption) => {
          if (
            _.has(segmentOption, 'OriginalEnabled') &&
            segmentOption.OriginalEnabled != segmentOption.Enabled
          ) {
            optionModified = optionModified + 1
          }
        })
        record.set('optionModified', optionModified)

        var option = _.find(assortmentDataPrev.Options, (option) => {
          return option.Id == record.data.Id
        })
        option.optionModified = optionModified
        option.SegmentOptions = segmentOptions
      }
    }

    if (
      (instantSaveRequest && !delayUpdates) ||
      (!instantSaveRequest && delayUpdates && record.get('optionModified'))
    ) {
      var data = $.extend(true, {}, record.data)
      element.mask('Loading...')
      me.updateOption(
        data,
        record,
        function () {
          me.getView().getEl().unmask()
        },
        function () {
          me.getView().getEl().unmask()
        }
      )
    }
  },

  onSimilarOptionClicked: function (grid, record, index, eOpts) {
    var me = this
    var vm = me.getViewModel()

    var image = record.get('image')
    if (image && image[0]) {
      vm.set('SimilarOptionImage', image[0])
    }
  },

  onSimilarOptionActivityChanged: function (grid, rowIndex, checked, record, e, eOpts) {
    var me = this
    var vm = me.getViewModel()
    vm.set('similarOptionsDirty', true)

    var productId = vm.get('SelectedOption')
    var similarProductId = record.get('id')
    var isEnabled = checked

    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/SetSimilarProductStatus',
      method: 'POST',
      params: {
        ProductId: productId,
        SimilarProductId: similarProductId,
        IsEnabled: isEnabled
      },
      success: function (response, opts) {
        var similarOptionStore = vm.getStore('similarOptionStore')
        similarOptionStore.load()
      },
      timeout: 600000
    })

    record.commit()
  },

  beforeSimilarOptionActivityChanged(grid, rowIndex, checked, record, e, eOpts) {
    if (record.get('similarityDesc') === 'Forecast') {
      return false
    }
  },

  OnAddSimilarProductToCart: function (grid, rowIndex, colIndex) {
    var me = this
    var vm = me.getViewModel()

    var rec = grid.getStore().getAt(rowIndex)

    var addSimilarOptionGridCart = vm.get('addSimilarOptionGridCart')
    addSimilarOptionGridCart.getStore().add(rec)
  },

  OnDeleteSimilarProductToCart: function (grid, rowIndex, colIndex) {
    var rec = grid.getStore().getAt(rowIndex)
    grid.getStore().remove(rec)
  },

  beforeShowSimilarOptions: function (record, view, segmentId, isFutureOption) {
    var me = this
    var vm = me.getViewModel()
    var delayUpdates = vm.get('delayUpdates')
    var optionModified = record.get('optionModified')

    if (delayUpdates && optionModified) {
      var element = me.getView().getEl()

      Ext.Msg.show({
        title: 'Save Changes?',
        message:
          'There are unsaved changes on this option. You have to save them before continue. Do you want to proceed?',
        buttons: Ext.Msg.YESNO,
        icon: Ext.Msg.QUESTION,
        fn: function (btn) {
          if (btn === 'yes') {
            var data = $.extend(true, {}, record.data)
            element.mask('Loading...')
            me.updateOption(
              data,
              record,
              function () {
                element.unmask()
                me.showSimilarOptions(record, view, segmentId, isFutureOption)
              },
              function () {
                element.unmask()
              }
            )
          } else if (btn === 'no') {
          }
        }
      })
    } else {
      me.showSimilarOptions(record, view, segmentId, isFutureOption)
    }
  },

  showSimilarOptions: function (record, view, segmentId, isFutureOption) {
    var me = this
    var vm = me.getViewModel()

    vm.set('similarOptionsDirty', false)
    vm.set('IsFutureOption', isFutureOption)
    var optionId = record.get('Id')
    var sizeRangeTypeId = record.get('SizeRangeId')

    vm.set('SelectedOption', optionId)
    vm.set('SelectedOptionSizeRangeTypeId', sizeRangeTypeId)
    var assortmentData = vm.get('AssortmentData')
    var stores = assortmentData.Stores
    var assortmentId = assortmentData.AssortmentId
    var segmentStores = []

    segmentStores.push(_.find(stores, { SegmentId: segmentId }))
    var segmentStoreIds = []
    _.forEach(stores, (store) => {
      if (store.SegmentId === segmentId) {
        segmentStoreIds.push(store.Id)
      }
    })
    var similarOptionStore = vm.getStore('similarOptionStore')
    var numberOfHistoryBackPeriods = vm.get('NumberOfHistoryBackPeriods')

    similarOptionStore.getProxy().setExtraParam('optionId', optionId)
    similarOptionStore.getProxy().setExtraParam('stores', segmentStoreIds)
    similarOptionStore.getProxy().setExtraParam('assortmentId', assortmentId)
    similarOptionStore.getProxy().setExtraParam('includeForecast', true)
    similarOptionStore.realProduct = record.getData()
    similarOptionStore.numberOfHistoryBackPeriods = numberOfHistoryBackPeriods

    var commonProductStore = vm.getStore('CommonProductStore')
    commonProductStore.getProxy().setExtraParam('key', '')

    commonProductStore.on('load', function () {
      var similarOptions = similarOptionStore.getDatas()
      const { items: products = [] } = commonProductStore.getData() || {}

      // Remove rows
      const rowsToRemove = []
      _.forEach(products, (product) => {
        const matchingProduct = _.find(similarOptions, (similarOption) => {
          return product.get('id') === similarOption.id
        })
        if (matchingProduct) {
          rowsToRemove.push(product)
        }
      })
      if (_.size(rowsToRemove) > 0) {
        commonProductStore.remove(rowsToRemove)
      }
    })

    if (!view.similarOptionsWindow && !view.addSimilarProductWin) {
      var addSimilarOptionGrid = Ext.create({
        id: 'addSimilarOptionGrid',
        xtype: 'addSimilarOptionGrid',
        flex: 1,
        bind: {
          store: '{CommonProductStore}'
        },
        style: 'border: 1px solid #bbb !important;',
        title: 'Search Results',
        margin: '5 5 5 5'
      })

      var addSimilarOptionGridCartStore = Ext.create('Ext.data.Store', {})
      var addSimilarOptionGridCart = Ext.create({
        id: 'addSimilarOptionGridCart',
        xtype: 'addSimilarOptionGridCart',
        store: addSimilarOptionGridCartStore,
        style: 'border: 1px solid #bbb !important;',
        flex: 1,
        title: 'New Similar Products',
        margin: '5 5 5 5'
      })

      vm.set('addSimilarOptionGrid', addSimilarOptionGrid)
      vm.set('addSimilarOptionGridCart', addSimilarOptionGridCart)

      var addSimilarProductWin = Ext.create('Ext.window.Window', {
        height: 600,
        width: 800,
        title: 'Add Similar Product',
        modal: true,
        closeAction: 'hide',
        scrollable: true,
        resizable: false,
        ui: 'window-inverse',
        id: 'addSimilarProduct',
        layout: {
          type: 'hbox',
          pack: 'start',
          align: 'stretch'
        },
        listeners: {
          show: (window) => {
            var searchProductTextbox = Ext.getCmp('searchProductTextbox')
            if (searchProductTextbox) {
              searchProductTextbox.setValue('')
            }
            var commonProductStore = vm.getStore('CommonProductStore')
            if (commonProductStore) {
              commonProductStore.removeAll()
            }
            if (addSimilarOptionGridCartStore) {
              addSimilarOptionGridCartStore.removeAll()
            }
          }
        },
        tbar: [
          {
            xtype: 'textfield',
            name: 'searchProduct',
            id: 'searchProductTextbox',
            fieldLabel: 'Search Product',
            listeners: {
              change: function (field, newValue, oldValue) {
                var commonProductStore = vm.getStore('CommonProductStore')
                if (newValue != '') {
                  commonProductStore.getProxy().setExtraParam('key', newValue)
                  commonProductStore.load()
                } else {
                  commonProductStore.removeAll()
                }
              }
            }
          }
        ],
        items: [addSimilarOptionGrid, addSimilarOptionGridCart],
        dockedItems: [
          {
            xtype: 'toolbar',
            dock: 'bottom',
            items: [
              '->',
              {
                text: 'OK',
                handler: function (btn) {
                  var element = view.getEl()
                  element.mask('Saving...')

                  var newSimilarProducts = addSimilarOptionGridCartStore.getData()
                  var addedSimilarProductIds = _.map(
                    newSimilarProducts.items,
                    (newSimilarProduct) => {
                      return newSimilarProduct.id
                    }
                  )

                  Ext.Ajax.request({
                    url: Assortment.Global.getApiUrl() + 'Assortment/AddSimilarProducts',
                    jsonData: {
                      productId: vm.get('SelectedOption'),
                      similarProductIds: addedSimilarProductIds,
                      assortmentId: assortmentId
                    },
                    timeout: 600000,
                    success: function (response, opts) {
                      var result = Ext.JSON.decode(response.responseText)

                      if (result.Code > -1) {
                        similarOptionStore.load()

                        element.unmask()
                      } else {
                        Assortment.util.operationFailWithMessage(result.Description)
                        element.unmask()
                      }
                      addSimilarOptionGridCartStore.removeAll()
                      btn.up('window').close()
                      vm.set('AddedSimilarProductId', '')
                    },
                    failure: function (response, opts) {
                      element.unmask()
                      Assortment.util.operationSuccessWithMessage('Update assortment has failed.')
                      btn.up('window').close()
                      vm.set('AddedSimilarProductId', '')
                    }
                  })
                }
              },
              {
                text: 'Cancel',
                handler: function (btn) {
                  vm.set('AddedSimilarProductId', '')
                  btn.up('window').close()
                }
              }
            ]
          }
        ]
      })

      view.addSimilarProductWin = addSimilarProductWin
    }

    if (view.similarOptionsWindow) {
      const forecastButton = view.similarOptionsWindow.dockedItems.items[1].items.items[2]
      if (forecastButton.text === 'Hide Forecast') {
        forecastButton.toggle()
      }
    }

    if (!view.similarOptionsWindow) {
      var similarOptionsGrid = Ext.create({
        xtype: 'similarOptionsGrid',
        region: 'center',
        store: similarOptionStore,
        style: 'border: 1px solid #bbb !important;',
        margin: '4px, 2px, 2px, 4px',
        viewModel: vm
      })

      similarOptionStore.on('load', function () {
        similarOptionsGrid.getSelectionModel().select(0)
      })

      var similarProductButtons = vm.get('similarProductButtons')

      var similarProductButtonItems = _.map(similarProductButtons, (similarProductButton) => {
        const { buttonText } = similarProductButton
        return {
          text: buttonText,
          handler: 'similarProductGenericDrilldown'
        }
      })

      var win = Ext.create('Ext.window.Window', {
        layout: 'fit',
        height: 500,
        width: 1000,
        title: 'Similar Products',
        iconCls: 'fa fa-users',
        maximized: true,
        modal: true,
        closeAction: 'hide',
        ui: 'window-inverse',
        bodyPadding: 4,
        id: 'SimilarProductWindow',
        resizable: false,
        config: {
          record: null,
          IsFutureOption: isFutureOption
        },
        listeners: {
          close: function () {
            var data = $.extend(true, {}, record.data)
            if (vm.get('similarOptionsDirty')) {
              me.updateOption(data, record)
            }
          }
        },
        fitContainer: function (animate) {
          var me = this

          var parent = me.floatParent

          var container = false ? parent.getTargetEl() : me.container

          var newBox = container.getViewSize()

          var newPosition =
            parent || container.dom !== document.body
              ? // If we are a contained floater, or rendered to a div, maximized position is (0,0)
                [0, 0]
              : // If no parent and rendered to body, align with origin of container el.
                container.getXY()

          newBox.x = newPosition[0]
          newBox.y = newPosition[1]
          me.setBox(newBox, animate)
        },
        items: [
          {
            xtype: 'container',
            layout: 'border',
            items: [
              {
                xtype: 'container',
                layout: 'border',
                region: 'north',

                height: 200,
                items: [
                  similarOptionsGrid,
                  {
                    xtype: 'image',
                    region: 'east',
                    width: 133,
                    style: 'border: 1px solid #bbb !important;',
                    margin: '4px, 4px, 2px, 2px',
                    imgCls: 'similarOptionImage',
                    bind: {
                      src: '{SimilarOptionImage}'
                    }
                  }
                ]
              },
              {
                xtype: 'SimilarOptionsChart',
                region: 'center',
                bind: {
                  store: '{similarOptionStore}'
                },
                style: 'border: 1px solid #bbb !important;',
                margin: '2px, 4px, 2px, 4px'
              }
            ]
          }
        ],
        dockedItems: [
          {
            xtype: 'toolbar',
            dock: 'top',
            items: [
              {
                text: 'Add',
                tooltip: 'Add new similar product',
                iconCls: 'fa fa-plus',
                tooltipType: 'title',
                bind: {
                  disabled: '{!IsFutureOption}'
                },
                handler: function () {
                  view.addSimilarProductWin.show()
                }
              },
              {
                xtype: 'button',
                text: 'Recalculate',
                iconCls: 'fa fa-refresh',
                handler: function () {
                  var record = this.up('window').config.record
                  this.lookupReferenceHolder().updateOption(record.data, record, function () {
                    similarOptionStore.load()
                  })
                }
              },
              {
                text: 'Open Report',
                tooltip: 'Open Similar Product Report',
                iconCls: 'fa fa-flag-o',
                tooltipType: 'title',
                handler: 'similarMultiProductDrilldown'
              },
              ...similarProductButtonItems
            ]
          }
        ]
      })

      view.similarOptionsWindow = win
    }

    similarOptionStore.load()

    this.getView().add(view.similarOptionsWindow)
    this.getView().add(addSimilarProductWin)
    view.similarOptionsWindow.config.record = record
    view.similarOptionsWindow.show()
  },

  onSelectAllSegments: function (record) {
    var me = this
    var element = me.getView().getEl()
    var vm = me.getViewModel()

    if (_.toInteger(record.data.SizeRangeId) === 0) {
      Ext.MessageBox.alert('Warning', 'Please enter a valid "Size Range Type".')
      return false
    }

    var segmentOptions = record.get('SegmentOptions')
    var select = !_.isNil(
      _.find(segmentOptions, (segmentOption) => {
        return !segmentOption.Enabled
      })
    )

    _.forEach(segmentOptions, (segmentOption) => {
      if (!_.has(segmentOption, 'OriginalEnabled')) {
        segmentOption.OriginalEnabled = segmentOption.Enabled
      }
      segmentOption.Enabled = select
    })
    record.set('SegmentOptions', segmentOptions)
    var delayUpdates = vm.get('delayUpdates')
    if (delayUpdates) {
      var optionModified = 0
      _.forEach(segmentOptions, (segmentOption) => {
        if (
          _.has(segmentOption, 'OriginalEnabled') &&
          segmentOption.OriginalEnabled != segmentOption.Enabled
        ) {
          optionModified = optionModified + 1
        }
      })
      record.set('optionModified', optionModified)

      var assortmentDataPrev = vm.get('AssortmentData')
      var option = _.find(assortmentDataPrev.Options, (option) => {
        return option.Id == record.data.Id
      })
      option.optionModified = optionModified
      option.SegmentOptions = segmentOptions
    } else {
      var data = $.extend(true, {}, record.data)

      element.mask('Loading...')
      me.updateOption(
        data,
        record,
        function (result) {
          me.getView().getEl().unmask()
        },
        function (result) {
          me.getView().getEl().unmask()
        }
      )
    }
  },

  onEditTestProduct: function (record) {
    var me = this
    var vm = me.getViewModel()

    var name = record.get('Name')
    var startDate = record.get('StartDate')
    var endDate = record.get('EndDate')
    var basePrice = record.get('BasePrice')
    var unitCost = record.get('Expenditure')
    var taxRate = record.get('TaxRate')
    var priceLevelId = record.get('PriceLevelId')
    var sizeRangeId = record.get('SizeRangeId')
    var seasonID = vm.get('SeasonID')
    var brandID = vm.get('BrandID')
    var divisionID = vm.get('DivisionID')
    var subDivisionID = vm.get('SubDivisionID')
    var ageID = vm.get('AgeID')
    var lifeStyleID = vm.get('LifeStyleID')
    var collectionGroupID = vm.get('CollectionGroupID')
    var buyerGroupID = vm.get('BuyerGroupID')
    var classID = vm.get('ClassID')
    var colorCodeId = record.get('ColorCodeId')
    var rangeID = vm.get('RangeID')

    var testProductSeasonID = 0
    var testProductFilters = record.get('TestProductFilters')
    var seasonCodeFilter = _.find(testProductFilters, (filter) => {
      return filter.Name === 'SeasonCode'
    })
    if (seasonCodeFilter) {
      testProductSeasonID = seasonCodeFilter.Values[seasonCodeFilter.SelectedIndices[0]]
    }

    if (ageID == null) {
      var ageFilter = _.find(testProductFilters, (filter) => {
        return filter.Name === 'Age'
      })
      if (ageFilter) {
        ageID = ageFilter.Values[ageFilter.SelectedIndices[0]]
      }
    }

    if (lifeStyleID == null) {
      var lifeStyleFilter = _.find(testProductFilters, (filter) => {
        return filter.Name === 'LifeStyle'
      })
      if (lifeStyleFilter) {
        collectionGroupID = lifeStyleFilter.Values[lifeStyleFilter.SelectedIndices[0]]
      }
    }

    if (collectionGroupID == null) {
      var collectionGroupFilter = _.find(testProductFilters, (filter) => {
        return filter.Name === 'CollectionGroup'
      })
      if (collectionGroupFilter) {
        collectionGroupID = collectionGroupFilter.Values[collectionGroupFilter.SelectedIndices[0]]
      }
    }

    if (buyerGroupID == null) {
      var buyerGroupFilter = _.find(testProductFilters, (filter) => {
        return filter.Name === 'BuyerGroup'
      })
      if (buyerGroupFilter) {
        buyerGroupID = buyerGroupFilter.Values[buyerGroupFilter.SelectedIndices[0]]
      }
    }

    if (classID == null) {
      var classFilter = _.find(testProductFilters, (filter) => {
        return filter.Name === 'Class'
      })
      if (classFilter) {
        classID = classFilter.Values[classFilter.SelectedIndices[0]]
      }
    }

    if (rangeID == null) {
      var rangeFilter = _.find(testProductFilters, (filter) => {
        return filter.Name === 'Range'
      })
      if (rangeFilter) {
        rangeID = rangeFilter.Values[rangeFilter.SelectedIndices[0]]
      }
    }

    vm.set('testOptionSeasonId', testProductSeasonID)

    var currency = vm.get('currency')

    var testAgeStore = new Ext.data.Store()
    var testLifeStyleStore = new Ext.data.Store()
    var testCollectionGroupStore = new Ext.data.Store()
    var testClassStore = new Ext.data.Store()
    var testRangeStore = new Ext.data.Store()
    var testColorStore = new Ext.data.Store()
    var testPriceLevelsStore = new Ext.data.Store()
    var testPriceStore = new Ext.data.Store()
    var testSizeRangeStore = new Ext.data.Store()
    var testTaxRateStore = new Ext.data.Store()
    var testSeasonStore = new Ext.data.Store()

    var testProductWin = Ext.create('Ext.window.Window', {
      layout: 'fit',
      height: 500,
      width: 800,
      title: 'Edit Test Product',
      modal: true,
      scrollable: true,
      resizable: false,
      ui: 'window-inverse',
      id: 'testProductWindow',
      items: [
        {
          xtype: 'container',
          layout: 'border',
          // scrollable: true,
          items: [
            {
              xtype: 'form',
              region: 'center',
              margin: '4px 4px 4px 4px',
              scrollable: true,
              defaultType: 'textfield',
              style: 'border: 1px solid #bbb !important;',
              fieldDefaults: {
                // labelAlign: 'top',
                msgTarget: 'side'
              },
              defaults: {
                border: false,
                xtype: 'panel',
                flex: 1,
                layout: 'anchor'
              },

              layout: 'hbox',

              items: [
                {
                  padding: '20px 25px 0px 50px',
                  items: [
                    {
                      xtype: 'fieldcontainer',
                      layout: 'hbox',
                      items: [
                        {
                          id: 'testName',
                          xtype: 'textfield',
                          fieldLabel: 'Name',
                          anchor: '-5',
                          value: name
                        }
                      ]
                    },
                    {
                      id: 'testStartDate',
                      xtype: 'datefield',
                      fieldLabel: 'Start Date',
                      anchor: '-5',
                      value: startDate
                    },
                    {
                      id: 'testEndDate',
                      xtype: 'datefield',
                      fieldLabel: 'End Date',
                      anchor: '-5',
                      value: endDate
                    },
                    {
                      id: 'testPrice',
                      xtype: 'combo',
                      fieldLabel: 'Price (' + currency + ')',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      value: basePrice,
                      store: testPriceStore,
                      editable: false,
                      anchor: '-5'
                    },
                    {
                      id: 'testCost',
                      xtype: 'numberfield',
                      fieldLabel: 'Unit Cost (' + currency + ')',
                      value: unitCost,
                      hideTrigger: true,
                      anchor: '-5'
                    },
                    {
                      id: 'testTaxRate',
                      xtype: 'combo',
                      fieldLabel: 'Tax Rate',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testTaxRateStore,
                      editable: false,
                      anchor: '-5'
                    },
                    {
                      id: 'testSizeRange',
                      xtype: 'combo',
                      fieldLabel: 'Size Range',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testSizeRangeStore,
                      editable: false,
                      anchor: '-5'
                    },
                    {
                      id: 'testPriceLevels',
                      xtype: 'combo',
                      fieldLabel: 'PriceLevels',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testPriceLevelsStore,
                      editable: false,
                      bind: {
                        value: '{testPriceLevels}'
                      },
                      anchor: '-5'
                    },
                    {
                      id: 'testSeasonCombo',
                      xtype: 'combo',
                      fieldLabel: 'Season',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testSeasonStore,
                      editable: false,
                      anchor: '-5'
                    }
                  ]
                },
                {
                  padding: '20px 50px 0px 25px',
                  items: [
                    {
                      id: 'testBrand',
                      xtype: 'textfield',
                      fieldLabel: 'Brand',
                      anchor: '100%',
                      value: brandID,
                      readOnly: true
                    },
                    {
                      id: 'testDivision',
                      xtype: 'textfield',
                      fieldLabel: 'Division',
                      anchor: '100%',
                      value: divisionID,
                      readOnly: true
                    },
                    {
                      id: 'testSubDivision',
                      xtype: 'textfield',
                      fieldLabel: 'SubDivision',
                      anchor: '100%',
                      value: subDivisionID,
                      readOnly: true
                    },
                    {
                      id: 'testAge',
                      xtype: 'textfield',
                      fieldLabel: 'Age',
                      value: ageID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testLifeStyle',
                      xtype: 'textfield',
                      fieldLabel: 'LifeStyle',
                      value: lifeStyleID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testCollectionGroup',
                      xtype: 'textfield',
                      fieldLabel: 'CollectionGroup',
                      value: collectionGroupID,
                      readOnly: true,
                      anchor: '100%'
                    },
                    {
                      id: 'testBuyerGroup',
                      xtype: 'textfield',
                      fieldLabel: 'BuyerGroup',
                      anchor: '100%',
                      value: buyerGroupID,
                      readOnly: true
                    },
                    {
                      id: 'testClass',
                      xtype: 'textfield',
                      fieldLabel: 'Class',
                      anchor: '100%',
                      value: classID,
                      readOnly: true
                    },
                    {
                      id: 'testRange',
                      xtype: 'combo',
                      fieldLabel: 'Range',
                      anchor: '100%',
                      value: rangeID,
                      readOnly: true
                    },
                    {
                      id: 'testColor',
                      xtype: 'combo',
                      fieldLabel: 'Color',
                      valueField: 'id',
                      displayField: 'name',
                      queryMode: 'local',
                      store: testColorStore,
                      editable: false,
                      anchor: '100%'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          items: [
            '->',
            {
              text: 'Cancel',
              handler: function (btn) {
                btn.up('window').close()
              }
            },
            {
              text: 'Update',
              handler: function (btn) {
                me.onEditTestOption(record, btn)
              }
            }
          ]
        }
      ]
    })
    this.getView().add(testProductWin)
    testProductWin.show()
    testProductWin.getEl().mask('Loading...')

    var testOptionDataDetails = vm.get('testOptionDataDetails')
    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/GetTestOptionData',
      method: 'POST',
      jsonData: {
        seasonID: seasonID,
        brandID: brandID,
        divisionID: divisionID,
        subDivisionID: subDivisionID,
        ageID: ageID,
        lifeStyleID: lifeStyleID,
        collectionGroupID: collectionGroupID,
        buyerGroupID: buyerGroupID,
        classID: classID,
        rangeID: rangeID,
        fetchFilters: testOptionDataDetails === null
      },
      timeout: 600000,
      success: function (response, opts) {
        var result = JSON.parse(response.responseText)

        if (testOptionDataDetails === null) {
          var { AttributeDetails, AttributeNames, AvailablePrices, AvailableSizeRanges } = result
          testOptionDataDetails = {
            AttributeDetails,
            AttributeNames,
            AvailablePrices,
            AvailableSizeRanges
          }
          vm.set('testOptionDataDetails', testOptionDataDetails)
        } else {
          result.AttributeDetails = testOptionDataDetails.AttributeDetails
          result.AttributeNames = testOptionDataDetails.AttributeNames
          result.AvailablePrices = testOptionDataDetails.AvailablePrices
          result.AvailableSizeRanges = testOptionDataDetails.AvailableSizeRanges
        }

        vm.set('testOptionData', result)
        vm.set('filteredtestOptionData', result)

        var getPossibleValues = (property) => {
          return _.map(result.AttributeDetails[property], (value, key) => {
            return { id: key, name: value }
          })
        }

        testAgeStore.loadData(getPossibleValues('Age'))
        testLifeStyleStore.loadData(getPossibleValues('LifeStyle'))
        testCollectionGroupStore.loadData(getPossibleValues('CollectionGroup'))
        testClassStore.loadData(getPossibleValues('Class'))
        testRangeStore.loadData(getPossibleValues('Range'))
        testColorStore.loadData(getPossibleValues('ColorCode'))
        testPriceLevelsStore.loadData(getPossibleValues('PriceLevel'))
        // Price
        var prices = _.map(
          _.orderBy(
            _.uniq(
              _.map(result.Products, (product) => {
                return product.BasePrice
              })
            )
          ),
          (price) => {
            return { id: price, name: price }
          }
        )

        testPriceStore.loadData(prices)

        Ext.getCmp('testPrice').setValue(basePrice)
        // Size Range
        var sizeRanges = _.map(result.AvailableSizeRanges, (range) => {
          return { id: range, name: range }
        })
        testSizeRangeStore.loadData(sizeRanges)
        if (result.AvailableSizeRanges[sizeRangeId]) {
          var sizeRange = result.AvailableSizeRanges[sizeRangeId]
          Ext.getCmp('testSizeRange').setValue(sizeRange)
        }

        // ColorCode
        var colorCodes = _.map(result.AttributeDetails.ColorCode, (color) => {
          return { id: color, name: color }
        })
        testColorStore.loadData(colorCodes)
        if (result.AttributeDetails.ColorCode[colorCodeId]) {
          var colorCode = result.AttributeDetails.ColorCode[colorCodeId]
          Ext.getCmp('testColor').setValue(colorCode)
        }
        // Price Levels
        var priceLevels = _.map(result.AttributeDetails.PriceLevel, (level) => {
          return { id: level, name: level }
        })
        testPriceLevelsStore.loadData(priceLevels)
        if (result.AttributeDetails.PriceLevel[priceLevelId]) {
          var priceLevel = result.AttributeDetails.PriceLevel[priceLevelId]
          Ext.getCmp('testPriceLevels').setValue(priceLevel)
        }
        // Tax Rate
        var taxRates = [
          { id: 0.08, name: '8 %' },
          { id: 0.18, name: '18 %' }
        ]
        testTaxRateStore.loadData(taxRates)
        Ext.getCmp('testTaxRate').setValue(taxRate)

        // Season
        var seasonIDStoreData = _.map(seasonID, (seasonID) => {
          return { id: seasonID, name: seasonID }
        })

        testSeasonStore.loadData(seasonIDStoreData)
        var seasonCombo = Ext.getCmp('testSeasonCombo')
        seasonCombo.setValue(testProductSeasonID)

        testProductWin.getEl().unmask()
      },
      failure: function (response, opts) {
        Assortment.util.operationFailWithMessage('Retrieving test product info failed')
        testProductWin.getEl().unmask()
      }
    })
  },

  onEditTestOption: function (record, button) {
    var me = this
    var vm = me.getViewModel()
    var clusterNodeId = vm.get('NodeId') || 0
    var taxRateValue = Ext.getCmp('testTaxRate').value || 0
    var startDateValue = Ext.getCmp('testStartDate').value
    var endDateValue = Ext.getCmp('testEndDate').value
    var testOptionData = vm.get('testOptionData')
    var brandValue = vm.get('BrandID')
    var cost = Ext.getCmp('testCost').value || 0
    var Name = Ext.getCmp('testName').value || ''
    var BasePrice = Ext.getCmp('testPrice').value || 0
    var SizeRange = Ext.getCmp('testSizeRange').value || ''
    var prepackSize = record.get('PrePackSize')
    var divisionValue = Ext.getCmp('testDivision').value || 0
    var subDivisionValue = Ext.getCmp('testSubDivision').value || 0
    var ageValue = Ext.getCmp('testAge').value || 0
    var lifeStyleValue = Ext.getCmp('testLifeStyle').value || 0
    var collectionGroupValue = Ext.getCmp('testCollectionGroup').value || 0
    var buyerGroupValue = Ext.getCmp('testBuyerGroup').value || 0
    var classValue = Ext.getCmp('testClass').value || 0
    var rangeValue = Ext.getCmp('testRange').value || 0
    var seasonValue = Ext.getCmp('testSeasonCombo').value || 0
    var colorCodeValue = Ext.getCmp('testColor').value || 0
    var priceLevelsValue = Ext.getCmp('testPriceLevels').value || 0

    var brandId =
      _.findKey(testOptionData.AttributeDetails.Brand, (value) => {
        return value === brandValue
      }) || 0

    var seasonId =
      _.findKey(testOptionData.AttributeDetails.SeasonCode, (value) => {
        return value === seasonValue
      }) || 0

    var divisionId =
      _.findKey(testOptionData.AttributeDetails.Division, (value) => {
        return value === divisionValue
      }) || 0

    var subDivisionId =
      _.findKey(testOptionData.AttributeDetails.SubDivision, (value) => {
        return value === subDivisionValue
      }) || 0

    var buyerGroupId =
      _.findKey(testOptionData.AttributeDetails.BuyerGroup, (value) => {
        return value === buyerGroupValue
      }) || 0

    var ageID =
      _.findKey(testOptionData.AttributeDetails.Age, (value) => {
        return value === ageValue
      }) || 0

    var lifeStyleId =
      _.findKey(testOptionData.AttributeDetails.LifeStyle, (value) => {
        return value === lifeStyleValue
      }) || 0

    var collectionGroupID =
      _.findKey(testOptionData.AttributeDetails.CollectionGroup, (value) => {
        return value === collectionGroupValue
      }) || 0

    var classID =
      _.findKey(testOptionData.AttributeDetails.Class, (value) => {
        return value === classValue
      }) || 0

    var rangeID =
      _.findKey(testOptionData.AttributeDetails.Range, (value) => {
        return value === rangeValue
      }) || 0

    var colorCodeID =
      _.findKey(testOptionData.AttributeDetails.ColorCode, (value) => {
        return value === colorCodeValue
      }) || 0

    var priceLevelID =
      _.findKey(testOptionData.AttributeDetails.PriceLevel, (value) => {
        return value === priceLevelsValue
      }) || 0
    var SizeRangeId =
      _.findKey(testOptionData.AvailableSizeRanges, (value) => {
        return value === SizeRange
      }) || 0

    var attributeIds = [
      brandId,
      divisionId,
      subDivisionId,
      ageID,
      lifeStyleId,
      collectionGroupID,
      buyerGroupId,
      classID,
      rangeID,
      0,
      colorCodeID,
      seasonId,
      0,
      0,
      0,
      0,
      priceLevelID,
      0,
      0
    ]
    var data = _.cloneDeep(record.data)
    delete data['id']
    var testProduct = {
      AveragePrepackSize: prepackSize,
      ClusterNodeId: clusterNodeId,
      Name: Name,
      BasePrice: BasePrice,
      SizeRange: SizeRange,
      SizeRangeId: SizeRangeId,
      SizeRangeTypeId: SizeRangeId,
      TaxRate: taxRateValue,
      Expenditure: cost,
      startDate: startDateValue,
      EndDate: endDateValue,
      AttributeIds: attributeIds
    }
    var assortmentData = vm.get('AssortmentData')
    var assortmentId = assortmentData.AssortmentId
    var testOption = {
      OptionStr: Ext.JSON.encode(data),
      AssortmentId: assortmentId,
      TestProduct: Ext.JSON.encode(testProduct)
    }
    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/EditTestOption',
      params: testOption,
      timeout: 600000,
      success: function (response) {
        var result = Ext.JSON.decode(response.responseText)
        me.initializeStores(result, me)
        button.up('window').close()
      }
    })
  },

  onDeleteTestOption: function (record) {
    var me = this
    var vm = me.getViewModel()
    var data = _.cloneDeep(record.data)
    delete data['id']

    var assortmentData = vm.get('AssortmentData')
    var assortmentId = assortmentData.AssortmentId
    var testOption = {
      OptionStr: Ext.JSON.encode(data),
      AssortmentId: assortmentId
    }
    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/DeleteTestOption',
      params: testOption,
      timeout: 600000,
      success: function (response) {
        var result = Ext.JSON.decode(response.responseText)
        me.initializeStores(result, me)
      }
    })
  },

  changeSelectedStores: function (record, cellIndex, e, isFutureOption) {
    var me = this
    var vm = me.getViewModel()
    var stores = vm.get('AssortmentData').Stores // record.get('OptionStores')
    const consumedCapacities = record.get('ConsumedCapacities')
    var segmentOptions = record.get('SegmentOptions')
    var currentSegmentOption = segmentOptions[cellIndex]
    var StorePerformances = currentSegmentOption.StorePerformances || []

    var currentStores = $.extend(
      true,
      [],
      stores.filter(function (store) {
        if (store.SegmentId === currentSegmentOption.SegmentId) return store
      }, this)
    )

    _.forEach(currentStores, (store) => {
      if (
        currentSegmentOption.ExcludedStoreIds &&
        currentSegmentOption.ExcludedStoreIds.indexOf(store.Id) > -1
      ) {
        store.IsActive = false
      } else {
        store.IsActive = true
      }
      var performanceOfStore = _.find(StorePerformances, (storePerformance) => {
        var storeId = storePerformance.StoreId || 0
        return storeId == store.Id
      })
      store.performance = (performanceOfStore && performanceOfStore.Performance) || 'NA'

      const consumedCapacity = _.find(consumedCapacities, { StoreId: store.Id })
      store.ConsumedOptionCapacity = consumedCapacity ? consumedCapacity.ConsumedOptionCapacity : 0
    })

    var store = vm.getStore('clusterStore')
    store.loadData(currentStores || [])

    var win = Ext.create('Ext.window.Window', {
      height: 500,
      width: 700,
      iconCls: 'fa fa-university',
      title: 'Active Stores',
      reference: 'treeDialog',
      scrollable: 'y',
      modal: true,
      ui: 'window-inverse',
      items: [
        {
          xtype: 'store-grid',
          store: store
        }
      ],
      bbar: [
        '->',
        {
          text: 'Apply',
          disabled: !isFutureOption,
          handler: function (btn) {
            var window = btn.up('window')
            var element = window.getEl()

            var allStores = store.getDatas()

            var excludedStores = allStores.filter(function (store) {
              if (!store.IsActive) return store
            })

            var excludedStoreIds = excludedStores.map(function (store) {
              return store.Id
            })

            currentSegmentOption.ExcludedStoreIds = excludedStoreIds

            var data = $.extend(true, {}, record.data)
            data.SegmentOptions = segmentOptions

            element.mask('Loading...')
            me.updateOption(
              data,
              record,
              function () {
                element.unmask()
                window.close()
              },
              function () {
                element.unmask()
              }
            )
          }
        }
      ]
    })

    me.getView().add(win)

    win.show()

    e.preventDefault()
  },

  getAvaibleSegments(segmentOptions, segmentName) {
    var me = this
    var vm = me.getViewModel()
    var data = []
    var selectedIndex = -1

    const availableSegments = vm.get('AvailableSegments')
    for (var property in segmentOptions) {
      if (segmentOptions.hasOwnProperty(property)) {
        var segment = segmentOptions[property]

        const availableSegment = _.find(availableSegments, (availableSegment) => {
          return availableSegment.Id === segment.SegmentId
        })
        if (availableSegment) {
          segment.Group = availableSegment.GroupByName
          data.push(segment)

          if (segmentName && segment.SegmentName == segmentName) {
            selectedIndex = data.length - 1
          }
        }
      }
    }
    return data
  },

  changeClusterDate: function (record, segmentName, isFutureOption) {
    var me = this
    var vm = me.getViewModel()

    var currency = vm.get('currency')

    var selectedIndex = -1
    var segmentOptions = record.get('SegmentOptions')
    var data = me.getAvaibleSegments(segmentOptions, segmentName)

    const assortmentData = vm.get('AssortmentData')

    // Size Range
    var sizeRangeStore = new Ext.data.Store()

    var sizeRanges = _.map(assortmentData.SizeRangeMapping, (value, key) => {
      return { id: key, name: value }
    })

    sizeRangeStore.loadData(sizeRanges)

    var segmentStore = vm.getStore('segmentStore')
    segmentStore.loadData(data)

    var segmentGridFeatures = []
    var summaryFeature = {
      ftype: 'summary',
      dock: 'bottom'
    }
    var groupingSummaryFeature = { ftype: 'groupingsummary', startCollapsed: true }

    var segmentGroupingActive = _.find(data, 'Group')

    if (segmentGroupingActive) {
      segmentGridFeatures.push(groupingSummaryFeature)
    }
    // Summary feature has to come after groupingsummary or it is not displayed
    segmentGridFeatures.push(summaryFeature)

    var win = Ext.create('Ext.window.Window', {
      height: 550,
      width: 1000,
      title: 'Settings',
      maximizable: true,
      iconCls: 'fa fa-cog',
      modal: true,
      layout: {
        type: 'vbox',
        pack: 'start',
        align: 'stretch'
      },
      bodyPadding: 4,
      ui: 'window-inverse',
      items: [
        {
          xtype: 'segment-grid',
          store: segmentStore,
          features: segmentGridFeatures,
          bodyStyle: {
            background: 'white'
          },
          padding: 0,
          flex: 1,
          listeners: {
            render: function () {
              var me = this
              if (selectedIndex > -1) {
                setTimeout(function () {
                  me.getSelectionModel().select(selectedIndex)
                }, 250)
              }
            }
          }
        },
        {
          xtype: 'form',
          height: 170,
          bodyPadding: 4,
          margin: '4 0 0 0',
          layout: {
            type: 'hbox',
            pack: 'start',
            align: 'stretch'
          },
          border: true,
          items: [
            {
              flex: 1,
              xtype: 'container',
              defaults: {
                labelWidth: 180
              },
              items: [
                {
                  name: 'markdown',
                  fieldLabel: 'Markdown %',
                  allowBlank: false,
                  xtype: 'numberfield',
                  minValue: 0,
                  cls: 'input-symbol-percent',
                  hideTrigger: true,
                  bind: {
                    value: '{Markdown}',
                    disabled: true
                  }
                },
                {
                  name: 'cost',
                  fieldLabel: 'Unit Cost (' + currency + ')',
                  allowBlank: false,
                  xtype: 'numberfield',
                  minValue: 0,
                  hideTrigger: true,
                  disabled: !isFutureOption,
                  bind: {
                    value: '{Cost}'
                  }
                },
                {
                  id: 'sizeRangeUpdate',
                  name: 'sizeRangeUpdate',
                  fieldLabel: 'Size Range Type',
                  xtype: 'combo',
                  value: record.data.SizeRangeId,
                  valueField: 'id',
                  displayField: 'name',
                  queryMode: 'local',
                  store: sizeRangeStore,
                  disabled: !isFutureOption,
                  editable: false
                },
                {
                  fieldLabel: 'Total Buy Quantity',
                  name: 'totalbuyquantity',
                  allowBlank: false,
                  xtype: 'numberfield',
                  hideTrigger: true,
                  disabled: true,
                  bind: {
                    value: '{TotalBuyQuantity}'
                  }
                }
              ]
            },
            {
              xtype: 'container',
              flex: 1,
              defaults: {
                labelWidth: 180
              },
              items: [
                {
                  name: 'sellthrough',
                  fieldLabel: 'Sellthrough Target',
                  allowBlank: false,
                  xtype: 'numberfield',
                  minValue: 0,
                  cls: 'input-symbol-percent',
                  hideTrigger: true,
                  disabled: !isFutureOption,
                  bind: {
                    value: '{Sellthrough}'
                  }
                },
                {
                  name: 'prePackSize',
                  fieldLabel: 'Prepack per Option',
                  allowBlank: false,
                  xtype: 'numberfield',
                  minValue: 0,
                  disabled: !isFutureOption,
                  bind: {
                    value: '{PrePackSize}'
                  }
                },
                {
                  name: 'TotalPrePackQuantity',
                  xtype: 'textfield',
                  fieldLabel: 'Total PrePack Quantity',
                  bind: {
                    value: '{TotalPrePackQuantity}'
                  },
                  disabled: true
                },
                {
                  name: 'BasePrice',
                  xtype: 'numberfield',
                  fieldLabel: 'Price',
                  bind: {
                    value: '{BasePrice}',
                    hidden: '{!optionPriceEditable}'
                  }
                }
              ]
            }
          ]
        }
      ],
      viewModel: {
        data: {
          StartDate: record.get('StartDate'),
          EndDate: record.get('EndDate'),
          Markdown: record.get('Markdown'),
          Sellthrough: record.get('Sellthrough'),
          TotalPrePackQuantity: record.get('TotalPrePackQuantity'),
          Cost: record.get('Expenditure'),
          MarkdownDurationInWeeks: record.get('MarkdownDurationInDays') / 7,
          PrePackSize: record.get('PrePackSize'),
          TotalBuyQuantity: record.get('TotalBuyQuantity'),
          BasePrice: record.get('BasePrice'),
          Record: record,
          SegmentStore: segmentStore
        }
      },
      fitContainer: function (animate) {
        var me = this

        var parent = me.floatParent

        var container = false ? parent.getTargetEl() : me.container

        var newBox = container.getViewSize()

        var newPosition =
          parent || container.dom !== document.body
            ? // If we are a contained floater, or rendered to a div, maximized position is (0,0)
              [0, 0]
            : // If no parent and rendered to body, align with origin of container el.
              container.getXY()

        newBox.x = newPosition[0]
        newBox.y = newPosition[1]
        me.setBox(newBox, animate)
      },
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          items: [
            '->',
            {
              text: 'Apply',
              disabled: !isFutureOption,
              handler: function (btn) {
                var window = this.up('window')
                var vm = window.getViewModel()
                var element = window.getEl()

                var segmentOptionsList = segmentStore.getDatas()

                var segmentOptions = {}
                segmentOptionsList.forEach(function (segmentOption) {
                  segmentOptions[segmentOption.SegmentId] = segmentOption
                }, this)

                var data = $.extend(true, {}, record.data)

                var sizeRangeUpdateCombo = Ext.getCmp('sizeRangeUpdate')

                data.SizeRangeId = sizeRangeUpdateCombo.getValue()
                if (_.toInteger(data.SizeRangeId) === 0) {
                  Ext.MessageBox.alert('Warning', 'Please enter a valid "Size Range Type".')
                  return false
                }

                data.StartDate = vm.get('StartDate')
                data.EndDate = vm.get('EndDate')
                data.Markdown = vm.get('Markdown')
                data.Sellthrough = vm.get('Sellthrough')
                data.MarkdownDurationInDays = vm.get('MarkdownDurationInWeeks') * 7
                data.PrePackSize = vm.get('PrePackSize')

                data.SegmentOptions = segmentOptions

                var cost = vm.get('Cost')
                var price = vm.get('BasePrice')

                if (data.Expenditure !== cost) {
                  data.Expenditure = cost
                } else {
                  cost = null
                }

                if (data.BasePrice !== price) {
                  data.BasePrice = price
                } else {
                  price = null
                }

                element.mask('Loading...')

                this.lookupReferenceHolder().updateOption(
                  data,
                  record,
                  function (currentOption, record) {
                    var data = vm.getData()

                    var values = null
                    segmentStore = data.SegmentStore
                    if (currentOption.SegmentOptions) {
                      values = Object.values(currentOption.SegmentOptions)
                    }
                    if (values) segmentStore.loadData(values)

                    var segmentOptionData = me.getAvaibleSegments(record.data.SegmentOptions)

                    segmentStore.loadData(segmentOptionData)

                    vm.setData({
                      StartDate: record.get('StartDate'),
                      EndDate: record.get('EndDate'),
                      Markdown: record.get('Markdown'),
                      Sellthrough: record.get('Sellthrough'),
                      TotalPrePackQuantity: record.get('TotalPrePackQuantity'),
                      Cost: record.get('Expenditure'),
                      BasePrice: record.get('BasePrice'),
                      MarkdownDurationInWeeks: record.get('MarkdownDurationInDays') / 7,
                      PrePackSize: record.get('PrePackSize'),
                      TotalBuyQuantity: record.get('TotalBuyQuantity'),
                      Record: record,
                      SegmentStore: segmentStore
                    })
                    element.unmask()
                  },
                  function () {
                    element.unmask()
                  },
                  cost,
                  price
                )
              }
            }
          ]
        }
      ]
    })
    this.getView().add(win)
    win.show()
  },
  onBeforeDestroy: function () {
    if (this.copyPlanningWindow) {
      this.copyPlanningWindow.destroy()
    }
  },
  copyPlanningParameters: function (record, clickedOptionId) {
    var me = this
    var vm = me.getViewModel()
    var assortmentData = vm.get('AssortmentData')
    var optionList = assortmentData.Options

    var optionStore = vm.get('CopySourceOptionStore')
    vm.set('clickedOptionId', clickedOptionId)

    var options = _.map(optionList, (option) => {
      return { id: option.Id, name: option.Name }
    })
    optionStore.loadData(options)

    if (!me.copyPlanningWindow) {
      me.copyPlanningWindow = Ext.create('Ext.window.Window', {
        xtype: 'assortment-copyplanningparameters',
        layout: 'fit',
        height: 200,
        width: 400,
        title: 'Copy Planning Parameters From',
        modal: true,
        scrollable: true,
        resizable: false,
        closeAction: 'hide',
        ui: 'window-inverse',
        id: 'copyPlanningWindow',
        viewModel: vm,
        items: [
          {
            xtype: 'container',
            layout: 'border',
            // scrollable: true,
            items: [
              {
                xtype: 'form',
                region: 'center',
                margin: '4px 4px 4px 4px',
                scrollable: true,
                defaultType: 'textfield',
                style: 'border: 1px solid #bbb !important;',
                fieldDefaults: {
                  msgTarget: 'side'
                },
                defaults: {
                  border: false,
                  xtype: 'panel',
                  flex: 1,
                  layout: 'anchor'
                },
                layout: 'hbox',
                items: [
                  {
                    padding: '20px 0px 0px 10px',
                    items: [
                      {
                        xtype: 'combobox',
                        id: 'copyOption',
                        fieldLabel: 'Select Product',
                        displayField: 'name',
                        valueField: 'id',
                        anchor: '-15',
                        bind: {
                          store: '{CopySourceOptionStore}'
                        },
                        minChars: 2,
                        queryParam: 'key',
                        queryMode: 'local'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],

        dockedItems: [
          {
            xtype: 'toolbar',
            scope: me,
            dock: 'bottom',
            items: [
              '->',
              {
                text: 'Cancel',
                handler: function (btn) {
                  btn.up('window').close()
                }
              },
              {
                text: 'Update',
                handler: function (btn) {
                  var element = me.getView().getEl()
                  var clickedOptionId = vm.get('clickedOptionId')
                  var sourceOptionId = Ext.getCmp('copyOption').value
                  var targetOptionId = clickedOptionId
                  element.mask('Loading...')
                  me.copyPlanningParametersUpdate(
                    record,
                    sourceOptionId,
                    targetOptionId,
                    function (result) {
                      me.getView().getEl().unmask()
                    },
                    function (result) {
                      me.getView().getEl().unmask()
                    }
                  )
                  btn.up('window').close()
                }
              }
            ]
          }
        ]
      })
    }
    me.copyPlanningWindow.show()
  },

  similarProductDrilldown: function (a, b, c, d, e, record, g) {
    var me = this
    var view = me.getView()
    var vm = me.getViewModel()
    var assortmentData = vm.get('AssortmentData')
    var data = {
      ProductId: record.id,
      ClusterNodeId: assortmentData.ClusterNodeId
    }
    view.onSimilarProductDrilldown(data)
  },

  similarMultiProductDrilldown: function (record) {
    var me = this
    var view = me.getView()
    var vm = me.getViewModel()
    var assortmentData = vm.get('AssortmentData')
    var productIds = []
    var similarOptionStore = vm.getStore('similarOptionStore')
    similarOptionStore.getDatas().forEach((element) => {
      if (element.isEnabled) {
        productIds.push(element.id)
      }
    })
    var data = {
      ProductIds: productIds,
      ClusterNodeId: assortmentData.ClusterNodeId
    }
    view.onSimilarMultiProductDrilldown(data)
  },

  similarProductGenericDrilldown: function (button) {
    var me = this

    var view = me.getView()
    var vm = me.getViewModel()

    var optionId = vm.get('SelectedOption')

    var assortmentData = vm.get('AssortmentData')
    var data = {
      AssortmentId: assortmentData.AssortmentId,
      ClusterNodeId: assortmentData.ClusterNodeId,
      OptionId: optionId
    }
    view.onSimilarProductGenericDrilldown(data, button.text)
  },

  updateOption: function (data, record, callbackSuccess, callbackFail, cost = null, price = null) {
    var me = this
    var vm = me.getViewModel()
    delete data['id']
    var assortmentData = vm.get('AssortmentData')
    var assortmentId = assortmentData.AssortmentId

    var references = me.getView().getReferences()
    var assortmentGrid = references.assortmentGrid

    // Workaround
    _.forEach(data.SegmentOptions, (segmentOption) => {
      if (isNaN(segmentOption.ROS) || segmentOption.ROS === Infinity) {
        segmentOption.ROS = 0
      }
      if (isNaN(segmentOption.FinalROS) || segmentOption.FinalROS === Infinity) {
        segmentOption.FinalROS = 0
      }
    })

    var updateOption = {
      OptionStr: Ext.JSON.encode(data),
      AssortmentId: assortmentId,
      cost: cost,
      price: price
    }

    var currX = assortmentGrid.getScrollX()
    var currY = assortmentGrid.getScrollY()

    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/UpdateOption',
      params: updateOption,
      timeout: 600000,
      success: function (response, opts) {
        var assortmentDataNext = Ext.JSON.decode(response.responseText)

        if (assortmentDataNext.Code > -1) {
          Assortment.util.operationSuccessWithMessage(assortmentDataNext.Description)

          var assortmentDataPrev = vm.get('AssortmentData')
          var optionId = record.get('Id')

          _.forEach(assortmentDataPrev.Options, (optionPrev) => {
            if (optionPrev.optionModified && optionPrev.Id != optionId) {
              var optionNext = _.find(assortmentDataNext.Options, (optionNext) => {
                return optionPrev.Id == optionNext.Id
              })
              optionNext.SegmentOptions = optionPrev.SegmentOptions
              optionNext.optionModified = optionPrev.optionModified
            }
          })

          vm.set('AssortmentData', assortmentDataNext)

          var currentOption = assortmentDataNext.Options.filter(function (option) {
            return option.Id == optionId
          })[0]

          for (var property in currentOption) {
            if (currentOption.hasOwnProperty(property)) {
              var value = currentOption[property]
              record.set(property, value)
            }
          }

          if (callbackSuccess) {
            callbackSuccess(currentOption, record)
            me.initializeStores(assortmentDataNext, me, true)
            assortmentGrid.scrollTo(currX, currY)
          }
        } else {
          Assortment.util.operationFailWithMessage(assortmentDataNext.Description)
          if (callbackFail) {
            callbackFail()
          }
        }
      },
      failure: function (response, opts) {
        console.warn('failure', response)
        if (callbackFail) {
          callbackFail()
        }
        Assortment.util.operationFailWithMessage('Update option has failed.')
      }
    })
  },

  copyPlanningParametersUpdate: function (
    record,
    sourceProductId,
    targetProductId,
    callbackSuccess,
    callbackFail
  ) {
    var me = this
    var vm = me.getViewModel()
    var assortmentData = vm.get('AssortmentData')
    var assortmentId = assortmentData.AssortmentId

    var references = me.getView().getReferences()
    var assortmentGrid = references.assortmentGrid

    var inputParams = {
      SourceProductId: sourceProductId,
      TargetProductId: targetProductId,
      AssortmentId: assortmentId
    }

    var currX = assortmentGrid.getScrollX()
    var currY = assortmentGrid.getScrollY()

    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/CopyPlanningParameters',
      params: inputParams,
      timeout: 600000,
      success: function (response, opts) {
        var result = Ext.JSON.decode(response.responseText)

        if (result.Code > -1) {
          Assortment.util.operationSuccessWithMessage(result.Description)

          vm.set('AssortmentData', result)
          var optionId = record.get('Id')

          var currentOption = result.Options.filter(function (option) {
            return option.Id == optionId
          })[0]

          for (var property in currentOption) {
            if (currentOption.hasOwnProperty(property)) {
              var value = currentOption[property]
              record.set(property, value)
            }
          }

          if (callbackSuccess) {
            callbackSuccess(currentOption, record)
            me.initializeStores(result, me)

            assortmentGrid.scrollTo(currX, currY)
          }
        } else {
          Assortment.util.operationFailWithMessage(result.Description)
          if (callbackFail) {
            callbackFail()
          }
        }
      },
      failure: function (response, opts) {
        console.warn('failure', response)
        if (callbackFail) {
          callbackFail()
        }
        Assortment.util.operationFailWithMessage('Update option has failed.')
      }
    })
  },

  onSelectCluster: function () {
    var me = this
    var vm = me.getViewModel()

    Ext.create('Assortment.assortmentLibrary.AssortmentLibrary', {
      title: 'Select a Cluster',
      referencePanel: me,
      items: [
        {
          xtype: 'panel',
          region: 'center',
          layout: 'fit',
          margin: 10,
          overflowY: 'auto',
          items: [
            {
              xtype: 'assortmentClusterTree',
              listeners: {
                select: function (RowModel, record) {
                  if (record.isLeaf()) {
                    var window = this.lookupReferenceHolder()
                    var name = record.get('Name')
                    var Id = record.get('Id')

                    vm.set('NodeId', Id)
                    vm.set('ClusterName', name)

                    setTimeout(function () {
                      window.close()
                    }, 250)
                  }
                }
              }
            }
          ]
        }
      ]
    }).show()
  },

  onCellContextMenu: function (view, td, cellIndex, record, row, rowIndex, e) {
    var me = this
    var vm = me.getViewModel()

    var SegmentOptions = record.get('SegmentOptions')

    var availableSegments = vm.get('AvailableSegments')

    var modifiedCellIndex = _.findKey(SegmentOptions, (segmentOption, key) => {
      return segmentOption.SegmentId == availableSegments[cellIndex].Id
    })

    var clickedColumnName = view.panel.headerCt.getHeaderAtIndex(cellIndex).text
    var clickedOptionId = record.get('Id')
    var isFutureOption = record.get('Editable')
    var segmentId = SegmentOptions[modifiedCellIndex].SegmentId
    var isEnabled = SegmentOptions[modifiedCellIndex].Enabled
    var allSegmentsSelected = _.isNil(
      _.find(SegmentOptions, (segmentOption) => {
        return !segmentOption.Enabled
      })
    )

    vm.set('IsFutureOption', isFutureOption)
    // if (view.isLockedView) return
    var position = [e.getX(), e.getY()]
    e.stopEvent()

    var contextMenu = null

    if (!view.isLockedView) {
      contextMenu = Ext.create('Ext.menu.Menu', {
        cls: 'assortment-container',
        title: clickedColumnName,
        items: [
          {
            text: 'Settings',
            handler: function () {
              me.changeClusterDate(record, clickedColumnName, isFutureOption)
            },
            iconCls: 'fa fa-cog'
          },
          {
            text: 'Active Stores',
            handler: function () {
              me.changeSelectedStores(record, modifiedCellIndex, e, isFutureOption)
            },
            iconCls: 'fa fa-university'
          },
          {
            hidden: !isEnabled,
            text: 'Similar Products',
            handler: function () {
              me.beforeShowSimilarOptions(record, view, segmentId, isFutureOption)
            },
            iconCls: 'fa fa-users'
          }
        ]
      })
    } else {
      contextMenu = Ext.create('Ext.menu.Menu', {
        cls: 'assortment-container',
        items: [
          {
            text: 'Settings',
            handler: function () {
              me.changeClusterDate(record, null, isFutureOption)
            },
            iconCls: 'fa fa-cog'
          },
          {
            text: 'Copy Planning Parameters',
            handler: function () {
              me.copyPlanningParameters(record, clickedOptionId)
            },
            disabled: !isFutureOption,
            iconCls: 'fa fa-files-o'
          },
          {
            text: 'UnSelect All Segments',
            handler: function () {
              me.onSelectAllSegments(record)
            },
            hidden: !allSegmentsSelected,
            disabled: !isFutureOption,
            iconCls: 'fa fa-square'
          },
          {
            text: 'Select All Segments',
            handler: function () {
              me.onSelectAllSegments(record)
            },
            hidden: allSegmentsSelected,
            disabled: !isFutureOption,
            iconCls: 'fa fa-check-square'
          },
          {
            text: 'Edit Product',
            handler: function () {
              me.onEditTestProduct(record)
            },
            disabled: !record.get('IsTestProduct'),
            iconCls: 'fa fa-pencil'
          },
          {
            text: 'Delete Product',
            handler: function () {
              me.onDeleteTestOption(record)
            },
            disabled: !record.get('IsTestProduct'),
            iconCls: 'fa fa-trash'
          }
        ]
      })
    }
    contextMenu.showAt(position)
  }
})
