import React, { Component } from 'react'
import _ from 'lodash'
import { Row } from 'react-bootstrap'
import Section from './section'

class SectionList extends Component {
  render() {
    const {
      formObj,
      pluginId,
      actualFilters,
      settingsComponents,
      boundedDropdowns,
      clientWithProgress,
      changeMustBeUpdated,
      updateFieldLookUpData,
      getLookupDataEditableField,
      prepareRecorDatawithChanges,
      readonly = false,
      validationCheckState = false,
      compact = false,
      inputHeight = 25,
      inputFontSize = 11
    } = this.props
    return (
      <Row>
        {_.map(formObj, (section, index) => {
          return (
            <Section
              key={index}
              actualFilters={actualFilters}
              boundedDropdowns={boundedDropdowns}
              changeMustBeUpdated={changeMustBeUpdated}
              clientWithProgress={clientWithProgress}
              compact={compact}
              getFormatedValue={this.props.getFormatedValue}
              getLookupDataEditableField={getLookupDataEditableField}
              getUnformattedValue={this.props.getUnformattedValue}
              getValidationState={this.props.getValidationState}
              getValueWithDateFormat={this.props.getValueWithDateFormat}
              handleDataByIconClick={this.props.handleDataByIconClick}
              inputFontSize={inputFontSize}
              inputHeight={inputHeight}
              loadLookUpdataReadOnly={this.props.loadLookUpdataReadOnly}
              pluginId={pluginId}
              prepareRecorDatawithChanges={prepareRecorDatawithChanges}
              readonly={readonly}
              section={section}
              setRequiredState={this.props.setRequiredState}
              settingsComponents={settingsComponents}
              updateFieldLookUpData={updateFieldLookUpData}
              validationCheckState={validationCheckState}
              onChange={this.props.onChange}
            />
          )
        })}
      </Row>
    )
  }
}
export default SectionList
