import useClickLoadEdit from './useClickLoadEdit/useClickLoadEdit'
import useFooterButtonClick from './useFooterButtonClick'
import useSavePlan from './useSavePlan'
import useScenarioSelected from './useScenarioSelected'

function useRegisterEvents() {
  useFooterButtonClick()
  useScenarioSelected()
  useClickLoadEdit()
  useSavePlan()
}

export default useRegisterEvents
