import { confirmAlert } from 'react-confirm-alert'
import PrimaryButton from '../PrimaryButton'
import useFooterButtonClick from '../../hooks/useRegisterEvents/useFooterButtonClick'

function openFooterButtonConfirmationPopup(confirmationText, confirmationTitle, handler) {
  return () => {
    confirmAlert({
      message: confirmationText,
      title: confirmationTitle,
      buttons: [
        {
          label: 'Cancel',
          onClick: () => false
        },
        {
          label: 'OK',
          onClick: () => handler()
        }
      ]
    })
  }
}

export default function FooterButtons({ activeTree, buttons, disabled }) {
  const { footerButtonsRef } = useFooterButtonClick()

  return (
    <>
      {buttons.map((footerButton) => {
        const {
          icon,
          text,
          tooltip,
          isConfirmationRequired = false,
          confirmationText = 'Are you sure to continue?',
          confirmationTitle = 'Confirmation'
        } = footerButton
        const modifiedButtonText = text.replace(/\s/g, '_')

        const handleButtonClick =
          footerButtonsRef.current?.[`handle${activeTree}FooterButtonClick_${modifiedButtonText}`]

        const handleClick = isConfirmationRequired
          ? openFooterButtonConfirmationPopup(
              confirmationText,
              confirmationTitle,
              handleButtonClick
            )
          : handleButtonClick

        return (
          <PrimaryButton
            key={text}
            disabled={disabled[modifiedButtonText]}
            tooltipText={tooltip}
            onClick={handleClick}
          >
            {icon && <i className={icon} />}
            <span className="fs-xs">{text}</span>
          </PrimaryButton>
        )
      })}
    </>
  )
}
