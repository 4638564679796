import { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import Operations from '../Operations'

export default class CalculatedFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first: {},
      comparison: '',
      second: {},
      isTrue: {},
      isFalse: {},
      ...props.value
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.onChange && !_.isEqual(nextProps.value, this.state)) {
      this.setState(nextProps.value)
    }
  }

  handleChange(label) {
    return (value) => {
      if (label === 'comparison') {
        this.setState({ [label]: value.target.value })
      } else {
        this.setState({ [label]: value })
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.onChange && !_.isEqual(nextState, this.state)) {
      this.props.onChange(nextState)
    }
  }

  render() {
    const { first, comparison, second, isTrue, isFalse } = this.state
    return (
      <div>
        <Row>
          <Col className="text-end" md={2}>
            Condition:
          </Col>
          <Col md={10}>
            <div>
              <Operations
                columns={this.props.columns}
                dataTypes={this.props.dataTypes}
                value={first}
                onChange={this.handleChange('first')}
              />
            </div>
            <div className="mb-3">
              <select
                className="form-select form-select-sm"
                value={comparison}
                onChange={this.handleChange('comparison')}
              >
                {['=', '!=', '>', '<', '>=', '<='].map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Operations
                columns={this.props.columns}
                dataTypes={this.props.dataTypes}
                value={second}
                onChange={this.handleChange('second')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-end" md={2}>
            True:
          </Col>
          <Col md={10}>
            <Operations
              columns={this.props.columns}
              dataTypes={this.props.dataTypes}
              value={isTrue}
              onChange={this.handleChange('isTrue')}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-end" md={2}>
            False:
          </Col>
          <Col md={10}>
            <Operations
              columns={this.props.columns}
              dataTypes={this.props.dataTypes}
              value={isFalse}
              onChange={this.handleChange('isFalse')}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
