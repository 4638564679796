import { useCallback } from 'react'
import { slvyToast } from '@/components'
import RequireFields from '../../components/RequiredFields'
import { useConfirmationPopup } from '../index'
import {
  TriggerSave,
  TriggerSaveParams,
  TriggerSaveProps,
  OnHandleSaveParams
} from './useTriggerSave.types'
import { HandleSaveReturnType } from '../useHandleSave/useHandleSave.types'

function useTriggerSave(props: TriggerSaveProps) {
  const { isThereUnsavedChanges, editing, gridRef, handleSave, refreshData } = props
  const { confirmation, confirmationMessage, lockGrid } = editing

  const { youMustHaveChangesToSave: youMustHaveChangesToSaveLocale } =
    gridRef?.current?.api?.getGridOption?.('localeText') || {}

  const toastMessages = useCallback((messages: string[]) => {
    if (!messages.length) {
      return
    }

    slvyToast.warning({
      component: <RequireFields messages={messages} title="" />,
      options: { autoClose: 5000 }
    })
  }, [])

  const showWarningMessages = useCallback(
    (params: Omit<HandleSaveReturnType, 'shouldRefresh'>) => {
      const { addingMessages, errorMessages } = params
      toastMessages(addingMessages)
      toastMessages(errorMessages)
    },
    [toastMessages]
  )

  const onHandleSave = useCallback(
    async ({ updateParameters }: OnHandleSaveParams) => {
      if (lockGrid) {
        gridRef?.current?.api?.setGridOption?.('loading', true)
      }

      const { shouldRefresh, ...params } = await handleSave({ updateParameters })

      if (shouldRefresh) {
        refreshData()
      }

      gridRef?.current?.api?.setGridOption?.('loading', false)

      showWarningMessages(params)
    },
    [lockGrid, handleSave, gridRef, showWarningMessages, refreshData]
  )

  const { openConfirmationPopup } = useConfirmationPopup()

  const triggerSave: TriggerSave = useCallback(
    async ({ skipUnsavedChanges, skipConfirmation, updateParameters }: TriggerSaveParams) => {
      if (!skipUnsavedChanges && !isThereUnsavedChanges) {
        slvyToast.warning({ message: youMustHaveChangesToSaveLocale })
        return
      }

      if (confirmation && !skipConfirmation) {
        openConfirmationPopup('', confirmationMessage, () => {
          onHandleSave({ updateParameters })
        })
      } else {
        onHandleSave({ updateParameters })
      }
    },
    [
      isThereUnsavedChanges,
      confirmation,
      youMustHaveChangesToSaveLocale,
      openConfirmationPopup,
      confirmationMessage,
      onHandleSave
    ]
  )

  return { triggerSave }
}

export default useTriggerSave
