import React, { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { Button, ButtonGroup } from 'react-bootstrap'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { getColorStyle, defaultColorScheme } from './util'
import { SlvySelect } from '@/components'
import StyledSwitch from './components/StyledSwitch'
import { selectStyles, menuPortalTarget } from './constants'
import styles from './index.module.scss'

class ComboboxFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      label: '',
      uiFilter: null,
      disabled: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleSelectOption = this.handleSelectOption.bind(this)
    this.setSelectionEnabled = this.setSelectionEnabled.bind(this)
    this.setSelectionDisabled = this.setSelectionDisabled.bind(this)
  }

  componentDidMount() {
    const {
      settings: {
        query: { fields = [] } = {},
        config: {
          data: { display, value, additionalFields } = {},
          data,
          settings: { multiple = false } = {}
        } = {}
      } = {}
    } = this.props

    let displayFieldType = PluginTypes.string
    let valueFieldType = PluginTypes.string

    if (data) {
      const displayField = _.find(fields, (field) => {
        return field.fieldName === display
      })
      if (displayField) {
        displayFieldType = PluginTypes.fromString(displayField.dataType)
      }

      const valueField = _.find(fields, (field) => {
        return field.fieldName === value
      })
      if (valueField) {
        valueFieldType = PluginTypes.fromString(valueField.dataType)
      }
    }

    if (multiple) {
      displayFieldType = PluginTypes.arrayOf(displayFieldType)
      valueFieldType = PluginTypes.arrayOf(valueFieldType)
    }

    const additionalFieldParams = _.transform(
      additionalFields,
      (res, additionalField) => {
        const { fieldName } = additionalField

        const addtionalFieldInfo = _.find(fields, (field) => {
          return field.fieldName === fieldName
        })
        if (addtionalFieldInfo) {
          res[fieldName] = PluginTypes.fromString(addtionalFieldInfo.dataType)
        }
      },
      {}
    )

    this.handleItemSelect = this.props.registerEvent({
      key: 'handleItemSelect',
      fn: this.handleItemSelect.bind(this),
      returnTypes: {
        value: valueFieldType,
        display: displayFieldType,
        ...additionalFieldParams
      }
    })

    this.props.registerMethod({
      key: 'setValue',
      fn: this.setValue.bind(this),
      args: [{ name: 'value', type: valueFieldType }]
    })

    this.props.registerMethod({
      key: 'filterUIItems',
      fn: this.filterUIItems.bind(this),
      args: [{ name: 'filter', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'clearValue',
      fn: this.clearValue.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setSelectionEnabled',
      fn: this.setSelectionEnabled.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setSelectionDisabled',
      fn: this.setSelectionDisabled.bind(this),
      args: []
    })

    this.getData = this.getData.bind(this)

    this.setState(this.getData(this.props))
  }

  clearValue() {
    this.setState(this.getData(this.props))
  }

  setSelectionEnabled() {
    this.setState({ disabled: false })
  }

  setSelectionDisabled() {
    this.setState({ disabled: true })
  }

  handleChange(value) {
    return (e) => {
      this.setState({ defaultValue: value })
    }
  }

  handleButtonClick() {
    const { defaultValue, data } = this.state
    if (data.length > 0) {
      const nextValue = this.getNextDefaultValue(defaultValue, data)
      this.setState({ defaultValue: nextValue })
    }
  }

  handleItemSelect() {
    const { settings: { buttonFilter: { enabled: displayAsButton = false } = {} } = {} } =
      this.props.settings.config

    if (!displayAsButton) {
      return this.getSelectedOptions()
    }
    return this.getSelectedButtonValue()
  }

  handleSelectOption(option) {
    const options = Array.isArray(option ?? []) ? option : [option]

    this.setState({ options }, () => {
      const { value } = this.getSelectedOptions()
      if (!_.isEqual(this.state.defaultValue, value)) {
        this.setState({ defaultValue: value })
      }
    })
  }

  getSelectedOptions() {
    const {
      settings: {
        config: {
          data: { value: valueField, additionalFields = [] } = {},
          settings: { multiple: isMultiple = false } = {}
        } = {}
      } = {},
      pluginData = []
    } = this.props
    const { options, data } = this.state
    let selectOptions = options

    if (typeof options === 'undefined') {
      const selectData = data.filter((option) => option && 'text' in option && 'id' in option)
      if (isMultiple) {
        selectOptions = selectData.filter(this.isSelectedOption)
      } else {
        const val = selectData.find(this.isSelectedOption)
        selectOptions = typeof val === 'undefined' ? null : [val]
      }
    }

    const selectedValues = _.map(selectOptions, (option) => {
      return option.id
    })

    const selectedLabels = _.map(selectOptions, (option) => {
      return option.text
    })

    const selectedRows = _.filter(pluginData, (row) => {
      const rowValue = row[valueField]
      if (typeof rowValue === 'string') {
        return rowValue && _.indexOf(selectedValues, rowValue.toString()) >= 0
      } else {
        return rowValue && _.indexOf(selectedValues, rowValue) >= 0
      }
    })

    const additionalFieldsInfo = _.transform(
      additionalFields,
      (res, additionalField) => {
        const { fieldName } = additionalField
        if (_.size(selectedRows) > 0) {
          if (!isMultiple) {
            res[fieldName] = selectedRows[0][fieldName]
          } else {
            res[fieldName] = _.map(selectedRows, (row) => {
              return row[fieldName]
            })
          }
        }
      },
      {}
    )

    if (selectedValues.length === 0) {
      return {
        value: null,
        display: null,
        ...additionalFieldsInfo
      }
    }

    return {
      value: isMultiple ? selectedValues : selectedValues[0],
      display: isMultiple ? selectedLabels : selectedLabels[0],
      ...additionalFieldsInfo
    }
  }

  getSelectedButtonValue() {
    const {
      pluginData = [],
      settings: { config: { data: { additionalFields = [], value } = {} } = {} } = {}
    } = this.props
    const { defaultValue, data } = this.state
    const defaultValueText = this.getDefaultValueText(defaultValue, data)

    const additionalFieldsInfo = _.transform(
      additionalFields,
      (res, additionalField) => {
        const { fieldName } = additionalField
        if (defaultValue && value) {
          const { [fieldName]: additionalFieldValue } =
            pluginData.find((dataItem) => {
              return dataItem[value] === defaultValue
            }) || {}

          res[fieldName] = additionalFieldValue
        }
      },
      {}
    )

    return {
      value: defaultValue,
      display: defaultValueText,
      ...additionalFieldsInfo
    }
  }

  setValue({ value }) {
    if (this.state.defaultValue !== value) {
      this.setState({ defaultValue: value })
    }
  }

  setLabel({ label }) {
    if (this.state.label !== label) {
      this.setState({ label })
    }
  }

  filterUIItems({ filter }) {
    this.setState({ uiFilter: filter })
  }

  getDataFromDataSource(props) {
    if (props.pluginData) {
      if (props.settings.config && props.settings.config.data) {
        let displayField = props.settings.config.data.display
        let valueField = props.settings.config.data.value
        const tooltipField = props.settings.config.data.tooltip
        const isDefaultField = props.settings.config.data.isDefault
        const { multiple } = props.settings.config.settings

        if (!displayField && !valueField) {
          return
        }

        if (!displayField) {
          displayField = valueField
        }

        if (!valueField) {
          valueField = displayField
        }

        if (displayField) {
          const data = []
          let defaultValue
          if (multiple) defaultValue = []
          _.forEach(props.pluginData, (value) => {
            if (displayField in value) {
              const displayVal = value[displayField]
              const valueVal = value[valueField]
              const tooltipVal = value[tooltipField]
              if (valueVal !== null) {
                data.push({
                  text: props.getFormattedValue(displayField, displayVal),
                  id: valueVal,
                  tooltip: tooltipVal
                })

                if (!this.state.uiFilter) {
                  if (isDefaultField && isDefaultField in value) {
                    if (value[isDefaultField]) {
                      if (multiple) defaultValue.push(valueVal)
                      else defaultValue = valueVal
                    }
                  }
                }
              }
            }
          })
          if (_.isArray(defaultValue) && _.size(defaultValue) === 0) {
            defaultValue = undefined
          }
          return { data, defaultValue }
        }
      }
    }
  }

  getData(props) {
    const {
      config: {
        settings: {
          defaultValue = null,
          defaultItemFormula: { enabled = false, nthItem = 1, location = 'Top' } = {}
        } = {},
        items: { items = {} } = {}
      } = {}
    } = props.settings
    let res = this.getDataFromDataSource(props)
    if (!res) {
      res = {}
    }
    if (!res.data || res.data.length === 0) {
      res.data = _.map(items, (item) => {
        return { text: item.display, id: item.value }
      })
    }

    res.data = _.uniqBy(res.data, 'id')

    if (_.isNil(res.defaultValue) && !_.isNil(defaultValue)) {
      const item = _.find(res.data, function (item) {
        return item.id && item.id.toString() === defaultValue
      })
      if (item) {
        res.defaultValue = defaultValue
      }
    }

    if (enabled) {
      let nth = nthItem < 0 ? 0 : nthItem
      if (nth >= res.data.length) {
        nth = res.data.length
      }

      nth = location === 'Top' ? nth - 1 : nth * -1
      const found = _.nth(res.data, nth)
      if (found) {
        res.defaultValue = found.id
      }
    }
    return res
  }

  getDefaultValueText(defaultValue, data) {
    if (defaultValue) {
      const item = _.find(data, function (item) {
        const { id = null } = item || {}
        return id && id.toString() === defaultValue.toString()
      })
      if (item) {
        return item.text
      }
    }
  }

  getNextDefaultValue(defaultValue, data) {
    const index = _.findIndex(data, function (item) {
      const { id = null } = item || {}
      return id && item?.id?.toString() === defaultValue?.toString()
    })
    const nextIndex = (index + 1) % data.length

    return data[nextIndex].id.toString()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.defaultValue !== prevState.defaultValue) {
      setTimeout(this.handleItemSelect, 0)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.query.isSuccess && !nextProps.query.pending && nextProps.query.isSuccess) ||
      !_.isEqual(this.props.pluginData, nextProps.pluginData)
    ) {
      const data = this.getData(nextProps)
      this.setState(data)
    }
  }

  getVerticalModeStyle = (verticalMode) => {
    if (verticalMode) {
      const { size: { width: height = 0, height: width = 0 } = {} } = this.props

      return {
        width,
        height,
        marginLeft: -(width / 2),
        marginTop: -(height / 2)
      }
    }

    return {}
  }

  isSelectedOption = ({ id }) => {
    const { defaultValue } = this.state
    return Array.isArray(defaultValue) ? defaultValue.some((val) => val == id) : defaultValue == id
  }

  render() {
    const {
      id,
      settings: {
        config: {
          settings: {
            placeholder = '',
            label: propsLabel,
            multiple,
            buttonFilter: {
              enabled: buttonFilterEnabled = false,
              displayLabel: buttonFilterDisplayLabel = false,
              isRadio = false,
              radioButtonStyle = 'Default',
              style = '',
              customColor = '#ffffff',
              icon = '',
              iconPosition = '',
              isStacked = false,
              verticalMode = false
            } = {}
          } = {},
          general: { name } = {}
        } = {}
      } = {}
    } = this.props

    const isCustomStyle = style === 'custom'
    const customColorStyle = isCustomStyle
      ? getColorStyle(customColor)
      : defaultColorScheme[style] || {}

    const { label: stateLabel, disabled = false } = this.state

    const label = stateLabel || propsLabel

    const { defaultValue, data = [] } = this.state
    const verticalModeStyle = this.getVerticalModeStyle(verticalMode)

    const defaultValueText = this.getDefaultValueText(defaultValue, data)
    const displayLabel = !buttonFilterEnabled || (buttonFilterEnabled && buttonFilterDisplayLabel)

    const options = data.filter((option) => option && 'text' in option && 'id' in option)
    const value = multiple
      ? options.filter(this.isSelectedOption)
      : options.find(this.isSelectedOption) ?? null

    const isButtonGroupVisible =
      radioButtonStyle === 'Default' || radioButtonStyle === 'Vertical-default'

    return (
      <div
        className={cx('w-100', 'h-100', 'm-0', 'p-1', styles.filterComboBox, {
          // multiple: multiple,
          enabled: buttonFilterEnabled
        })}
        data-pluginname={name}
      >
        <div
          className={cx(
            styles.filterComboBoxContainer,
            'd-flex',
            'flex-row',
            'align-items-center',
            'justify-content-between',
            {
              'position-absolute top-50 start-50 p-1': verticalMode,
              [styles.verticalMode]: verticalMode
            }
          )}
          style={verticalModeStyle}
        >
          {displayLabel && label && (
            <label
              className={cx('d-flex', 'align-items-center', 'fw-bold', 'me-2', 'text-nowrap', {
                [styles.multipleLabel]: multiple
              })}
              htmlFor={id}
            >
              {label}:
            </label>
          )}

          {!buttonFilterEnabled ? (
            <SlvySelect
              className="w-100 h-100"
              isDisabled={disabled}
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.id}
              id={id}
              isClearable={!multiple}
              isMulti={multiple}
              menuPortalTarget={menuPortalTarget}
              options={options}
              value={value}
              placeholder={placeholder}
              styles={selectStyles}
              onChange={this.handleSelectOption}
            />
          ) : !isRadio ? (
            <Button
              className={cx('h-100', 'w-100', 'm-0', 'p-0', styles.buttonFilter)}
              disabled={disabled}
              type="button"
              variant={isCustomStyle ? 'outline-dark' : style}
              onClick={this.handleButtonClick}
            >
              {icon && iconPosition === 'Left' && (
                <i aria-hidden="true" className={`${icon} me-2`} />
              )}
              {defaultValueText}
              {icon && iconPosition === 'Right' && (
                <i aria-hidden="true" className={`${icon} ms-2`} />
              )}
            </Button>
          ) : (
            <div className="w-100 h-100">
              {isButtonGroupVisible ? (
                <ButtonGroup
                  className={cx(
                    { 'flex-column': radioButtonStyle === 'Vertical-default' || isStacked },
                    'd-flex',
                    'w-100',
                    'h-100'
                  )}
                >
                  {_.map(data, ({ id, text, tooltip }) => {
                    const isActive = id === defaultValue
                    const btnGroupStyle = isActive && isCustomStyle ? customColorStyle : {}

                    return (
                      <Button
                        key={id}
                        className="w-100 px-1 me-0"
                        size="sm"
                        active={isActive}
                        disabled={disabled}
                        style={btnGroupStyle}
                        title={tooltip}
                        value={id}
                        variant={
                          isActive ? (isCustomStyle ? 'outline-dark' : style) : 'outline-dark'
                        }
                        onClick={this.handleChange(id)}
                      >
                        {text}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              ) : (
                <StyledSwitch
                  data={data}
                  isStacked={isStacked}
                  defaultValue={defaultValue}
                  handleChange={disabled ? () => {} : this.handleChange}
                  id={id}
                  style={{ customColorStyle }}
                  theme={radioButtonStyle}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  size: props.size,
  id: props.id,
  query: props.query,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(ComboboxFilter, selectConnectorProps)
export const Self = ComboboxFilter
