export const getExternalUpdateTypes = (PluginTypes) => {
  return {
    StartPeriod: PluginTypes.string,
    EndPeriod: PluginTypes.string,
    PeriodName: PluginTypes.string,
    PeriodAggr: PluginTypes.int,
    OldValue: PluginTypes.int,
    Guid: PluginTypes.string,
    SelectedParams: PluginTypes.string,
    MetricField: PluginTypes.string,
    MetricFields: PluginTypes.object
  }
}
