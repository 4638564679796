import { useCallback } from 'react'
import { getColDefByField as _getColDefByField } from '../../helpers'
import { GridRef } from '../../types'

function useGetColDefByField(gridRef: GridRef) {
  const getColDefByField = useCallback(
    (activeField) => _getColDefByField(gridRef, activeField),
    [gridRef]
  )

  return { getColDefByField }
}

export default useGetColDefByField
