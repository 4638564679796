import React, { Component } from 'react'
import _ from 'lodash'
import { format } from '../utils'

export default class ChartCell extends Component {
  constructor(props) {
    super(props)
    const { value } = props

    this.state = {
      value,
      isOverlay: true,
      isUpdating: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, attachments } = this.props
    const { value: stateValue } = this.state

    const isValueChanged = value !== nextProps.value || stateValue !== nextProps.value

    if (isValueChanged) {
      this.setState({
        value: nextProps.value,
        isOverlay: true
      })
    }
  }

  clickHandler(extras, event) {
    const { isUpdating } = this.state
    if (isUpdating) return

    const { updateHandler = () => {} } = this.props
    extras = {
      ...extras,
      ...{ clickUpdate: true }
    }

    this.setState({ isOverlay: true, isUpdating: true }, () =>
      updateHandler(event, extras).finally(() => this.setState({ isUpdating: false }))
    )
  }

  handleChange(event) {
    const {
      target: { value = '' }
    } = event

    this.setState({ value })
  }

  handleKeyDown = (event) => {
    const { updateHandler } = this.props
    const { isUpdating } = this.state

    if (isUpdating) return

    this.setState({ isUpdating: true }, () =>
      updateHandler(event).finally(() => this.setState({ isUpdating: false }))
    )
  }

  render() {
    const { value, isOverlay } = this.state
    const {
      disabled,
      onClick = () => {},
      onFocus = () => {},
      updateHandler = () => {},
      attachments: { attachments = [], isActive = false },
      formatType
    } = this.props

    return (
      <header>
        {!_.isEmpty(attachments) && isActive && isOverlay ? (
          <div className="attachment-list">
            <ol>
              {_.map(attachments, (aitem, aindex) => {
                const isVisible = aitem === 0
                const isForce = aindex === 1

                return (
                  <li
                    key={aindex}
                    className={`${isForce ? '-force-update' : ''} ${isVisible ? '-visible' : ''}`}
                    title={isForce ? 'Force Update' : ''}
                  >
                    <span
                      className="attachment"
                      onClick={this.clickHandler.bind(this, { isForce: true, itemValue: aitem })}
                    >
                      {format(aitem, formatType)}
                      <i className="slvy-ui-icon-check-extra-lt" />
                    </span>
                  </li>
                )
              })}
            </ol>
            <div
              className="-overlay"
              onClick={() => {
                this.setState({ isOverlay: false })
                updateHandler({})
              }}
            />
          </div>
        ) : (
          <input
            className="update-input"
            disabled={disabled}
            type="text"
            value={value}
            onChange={this.handleChange}
            onClick={onClick}
            onFocus={onFocus}
            onKeyDown={this.handleKeyDown}
          />
        )}
      </header>
    )
  }
}
