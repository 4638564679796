import { Component, createElement } from 'react'
import { FormControl, FormLabel, Button, FormGroup, Col, Row } from 'react-bootstrap'
import _ from 'lodash'
import { slvyToast } from '@/components'

export default class PropertyEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      settings: this.getInitialSettingsState(props)
    }
    this.onChangeSettings = this.onChangeSettings.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClickSaveAsTemplate = this.handleClickSaveAsTemplate.bind(this)
    this.handleChangeTemplateTitle = this.handleChangeTemplateTitle.bind(this)
  }

  getInitialSettingsState($props = {}) {
    const { container: { settings } = {} } = $props
    return settings
  }

  onChangeSettings(settings) {
    const {
      container,
      container: { id }
    } = this.props
    this.setState({ settings }, () => {
      this.props.onSave(id, { ...container, settings: { ...settings } })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ settings: this.getInitialSettingsState(nextProps) })
  }

  timer = 0

  handleChange(e) {
    const {
      container: { type }
    } = this.props
    const { settings } = this.state || {}
    this.setState({ settings: { ...settings, name: e.currentTarget.value } })
    // timer is used in flex container
    if (type === 'flexcontainer') {
      clearInterval(this.timer)
      this.timer = setTimeout(() => {
        const {
          container,
          container: { id }
        } = this.props
        const { settings } = this.state || {}
        this.props.onSave(id, { ...container, settings: { ...settings } })
      }, 1000)
    }
  }

  getAdmin({ id, settings: cs, type = 'Container' } = {}, settings) {
    if (!id) return null

    let namespace = type
    switch (type) {
      case 'container':
        namespace = 'Container'
        break
      case 'splitcontainer':
        namespace = 'SplitContainer'
        break
      case 'flexcontainer':
        namespace = 'FlexboxContainer'
        break
      case 'popupcontainer':
        namespace = 'PopupContainer'
        break
      case 'tabcontainer':
        namespace = 'TabContainer'
        break
      case 'reflexcontainer':
        namespace = 'ReFlexContainer'
        break
      case 'wizardcontainer':
        namespace = 'WizardContainer'
        break
      case 'overlaycontainer':
        namespace = 'OverlayContainer'
        break
      default:
        namespace = type
        break
    }

    const requireContext = require.context(`./../../../../elements`, true, /\/Admin\/index.js$/i)

    const context = _.reduce(
      requireContext.keys(),
      (r, v) => {
        if (_.isEqual(v, `./${namespace}/Admin/index.js`)) {
          return requireContext(v).default
        }
        return r
      },
      <div />
    )

    const { templateTitle = '', settings: { name = '' } = {} } = this.state || {}

    return createElement(context, {
      settings: settings || cs,
      onChangeSettings: this.onChangeSettings,
      propertyEditorFields: {
        id,
        templateTitle,
        name
      }
    })
  }

  handleClickSaveAsTemplate() {
    const {
      container: { id }
    } = this.props
    const { templateTitle } = this.state
    if (templateTitle && templateTitle.length > 3) {
      this.props.onClickSaveAsTemplate(id, templateTitle)
      this.setState({ templateTitle: '' })
    } else {
      slvyToast.warning({
        message: 'Template name must be at least 4 characters',
        title: 'Warning'
      })
    }
  }

  handleChangeTemplateTitle(e) {
    this.setState({ templateTitle: e.target.value })
  }

  render() {
    const { container, container: { id = '', settings: { name: containerName = '' } = {} } = {} } =
      this.props

    if (!container) return null

    const {
      templateTitle = '',
      settings,
      settings: { name = containerName || '' } = {}
    } = this.state || {}

    return (
      <div className="property-editor-container">
        <div className="p-2">
          <FormGroup as={Row} className="mb-2" controlId="propertyEditorContainerId">
            <FormLabel column className="d-flex align-items-center fs-sm py-0" sm={3}>
              Id
            </FormLabel>
            <Col sm={9}>
              <FormControl disabled size="sm" type="text" value={id} />
            </Col>
          </FormGroup>
          <FormGroup as={Row} controlId="propertyEditorContainerName">
            <FormLabel column className="d-flex align-items-center fs-sm py-0" sm={3}>
              Name
            </FormLabel>
            <Col sm={9}>
              <FormControl size="sm" type="text" value={name} onChange={this.handleChange} />
            </Col>
          </FormGroup>
        </div>
        {this.getAdmin(container, settings)}
        <div className="d-grid gap-1 p-2 grid-auto-flow-column">
          <FormControl
            size="sm"
            type="text"
            value={templateTitle}
            onChange={this.handleChangeTemplateTitle}
          />
          <Button size="sm" variant="primary" onClick={this.handleClickSaveAsTemplate}>
            Save As Template
          </Button>
        </div>
      </div>
    )
  }
}
