Ext.define('MDM.Btl.model.model.Discrepancy', {
  extend: 'MDM.src.model.Base',
  // alias: 'model.pg-btl-models-MainModel',
  entityName: 'gillettev3/btl_edit_dpiddp',
  fields: [
    {
      name: 'IsHide',
      type: 'boolean'
    },
    {
      name: 'IsEditable',
      type: 'boolean'
    },
    {
      name: 'Region',
      type: 'string'
    },
    {
      name: 'TargetRegion',
      type: 'string'
    },
    {
      name: 'dataType',
      type: 'string',
      defaultValue: 'Edited'
    },
    {
      name: 'isEditable',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'Comment',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'ctype',
      type: 'string',
      defaultValue: 'Export'
    },
    {
      name: 'Title',
      calculate: function (data) {
        return data.ctype == 'Export'
          ? 'Shipment to ' + data.TargetRegion
          : 'Shipment from ' + data.TargetRegion
      }
    },
    {
      name: 'c01',
      type: 'number'
    },
    {
      name: 'c02',
      type: 'number'
    },
    {
      name: 'c03',
      type: 'number'
    },
    {
      name: 'c04',
      type: 'number'
    },
    {
      name: 'c05',
      type: 'number'
    },
    {
      name: 'c06',
      type: 'number'
    },
    {
      name: 'c07',
      type: 'number'
    },
    {
      name: 'c08',
      type: 'number'
    },
    {
      name: 'c09',
      type: 'number'
    },
    {
      name: 'c10',
      type: 'number'
    },
    {
      name: 'c11',
      type: 'number'
    },
    {
      name: 'c12',
      type: 'number'
    },
    {
      name: 't1',
      calculate: function (data) {
        if (data.SummaryType == 'sum') {
          return (
            data.c01 +
            data.c02 +
            data.c03 +
            data.c04 +
            data.c05 +
            data.c06 +
            data.c07 +
            data.c08 +
            data.c09 +
            data.c10 +
            data.c11 +
            data.c12
          )
        }
        if (data.SummaryType == 'LastPeriod') {
          return data.c12
        }
        if (data.SummaryType == 'NexPeriod') {
          return data.c13
        }
        if (data.SummaryType == 'Avg') {
          var i = 0
          for (var index = 1; index < 13; index++) {
            var key = index > 10 ? 'c' + index : 'c0' + index
            if (data[key] != 0) i = i + 1
          }
          return (
            (data.c01 +
              data.c02 +
              data.c03 +
              data.c04 +
              data.c05 +
              data.c06 +
              data.c07 +
              data.c08 +
              data.c09 +
              data.c10 +
              data.c11 +
              data.c12) /
            i
          )
        }
        if (data.SummaryType == 'none') {
          return 0
        }
        return (
          data.c01 +
          data.c02 +
          data.c03 +
          data.c04 +
          data.c05 +
          data.c06 +
          data.c07 +
          data.c08 +
          data.c09 +
          data.c10 +
          data.c11 +
          data.c12
        )
      }
    },
    {
      name: 'c13',
      type: 'number'
    },
    {
      name: 'c14',
      type: 'number'
    },
    {
      name: 'c15',
      type: 'number'
    },
    {
      name: 'c16',
      type: 'number'
    },
    {
      name: 'c17',
      type: 'number'
    },
    {
      name: 'c18',
      type: 'number'
    },
    {
      name: 'c19',
      type: 'number'
    },
    {
      name: 'c20',
      type: 'number'
    },
    {
      name: 'c21',
      type: 'number'
    },
    {
      name: 'c22',
      type: 'number'
    },
    {
      name: 'c23',
      type: 'number'
    },
    {
      name: 'c24',
      type: 'number'
    },
    {
      name: 't2',
      calculate: function (data) {
        if (data.SummaryType == 'sum') {
          return (
            data.c13 +
            data.c14 +
            data.c15 +
            data.c16 +
            data.c17 +
            data.c18 +
            data.c19 +
            data.c20 +
            data.c21 +
            data.c22 +
            data.c23 +
            data.c24
          )
        }
        if (data.SummaryType == 'LastPeriod') {
          return data.c24
        }
        if (data.SummaryType == 'NexPeriod') {
          return data.c25
        }
        if (data.SummaryType == 'Avg') {
          var i = 0
          for (var index = 13; index < 25; index++) {
            var key = 'c' + index
            if (data[key] != 0) i = i + 1
          }
          return (
            data.c13 +
            data.c14 +
            data.c15 +
            data.c16 +
            data.c17 +
            data.c18 +
            data.c19 +
            data.c20 +
            data.c21 +
            data.c22 +
            data.c23 +
            data.c24 / i
          )
        }
        if (data.SummaryType == 'none') {
          return 0
        }
        if (data.SummaryType == 'last') {
          return c22
        }
        return (
          data.c13 +
          data.c14 +
          data.c15 +
          data.c16 +
          data.c17 +
          data.c18 +
          data.c19 +
          data.c20 +
          data.c21 +
          data.c22 +
          data.c23 +
          data.c24
        )
      }
    },
    {
      name: 'c25',
      type: 'number'
    },
    {
      name: 'c26',
      type: 'number'
    },
    {
      name: 'c27',
      type: 'number'
    },
    {
      name: 'c28',
      type: 'number'
    },
    {
      name: 'c29',
      type: 'number'
    },
    {
      name: 'c30',
      type: 'number'
    },
    {
      name: 'c31',
      type: 'number'
    },
    {
      name: 'c32',
      type: 'number'
    },
    {
      name: 'c33',
      type: 'number'
    },
    {
      name: 'c34',
      type: 'number'
    },
    {
      name: 'c35',
      type: 'number'
    },
    {
      name: 'c36',
      type: 'number'
    },
    {
      name: 't3',
      calculate: function (data) {
        if (data.SummaryType == 'sum') {
          return (
            data.c25 +
            data.c26 +
            data.c27 +
            data.c28 +
            data.c29 +
            data.c30 +
            data.c31 +
            data.c32 +
            data.c33 +
            data.c34 +
            data.c35 +
            data.c36
          )
        }
        if (data.SummaryType == 'LastPeriod') {
          return data.c36
        }
        if (data.SummaryType == 'NexPeriod') {
          return 0
        }
        if (data.SummaryType == 'Avg') {
          var i = 0
          for (var index = 13; index < 25; index++) {
            var key = 'c' + index
            if (data[key] != 0) i = i + 1
          }
          return (
            data.c13 +
            data.c14 +
            data.c15 +
            data.c16 +
            data.c17 +
            data.c18 +
            data.c19 +
            data.c20 +
            data.c21 +
            data.c22 +
            data.c23 +
            data.c24 / i
          )
        }
        if (data.SummaryType == 'none') {
          return 0
        }
        return (
          data.c25 +
          data.c26 +
          data.c27 +
          data.c28 +
          data.c29 +
          data.c30 +
          data.c31 +
          data.c32 +
          data.c33 +
          data.c34 +
          data.c35 +
          data.c36
        )
      }
    },
    {
      name: 'BTLTypeDesc',
      calculate: function (data) {
        if (data.BTLType == 'D') return 'Demand'
        if (data.BTLType == 'P') return 'Production'
        if (data.BTLType == 'I') return 'Inventory'
        return data.BTLType
      }
    },
    {
      name: 'BTLType',
      type: 'string'
    },
    {
      name: 'SummaryType',
      type: 'string'
    },
    {
      name: 'Total',
      type: 'number'
    }
  ]
})
