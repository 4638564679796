Ext.define('MDM.src.view.regionapp.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.regionapp-maincontroller',
  /**
   * Called when the view is created
   */
  requires: [
    'Ext.exporter.text.CSV',
    'Ext.exporter.text.TSV',
    'Ext.exporter.text.Html',
    'Ext.exporter.excel.Xml',
    'Ext.exporter.excel.Xlsx'
  ],
  init: function () {
    const vm = this.getViewModel()
    const owner = vm.get('owner')
    const isReadOnly = MDM.Global.getPermission(owner)
    const isGlobal = MDM.Global.isGlobal()
    const region = MDM.Global.getRegion()
    vm.set('isReadOnly', isReadOnly)
    vm.set('isGlobal', isGlobal)
    vm.set('userMegaRegion', region)
    const summaryRec = Ext.create('MDM.src.view.regionapp.model.Summary')
    vm.set('summaryRec', summaryRec)
  },
  onBeforeLoadMatAssStore: function (store) {},
  onBeforeLoadMaterialUnassignedStore: function () {
    const store = this.getViewModel().getStore('matUnAssStore')
    const viewModel = this.getViewModel()
    store.getProxy().setExtraParam('viewAs', viewModel.get('viewAs'))
  },
  onActivate: function (grid) {
    const store = grid.getStore()
    store.load()
  },
  onDPITreeStoreLoad: function (store) {
    const me = this
    const tree = me.lookupReference('tree')
    const rawData = store.getProxy().getReader().rawData
    const node = store.findNode('id', 0)
    me.getViewModel().set('currentReportingPeriod', rawData.currentReportingPeriod)
    tree.getSelectionModel().select(node)
  },
  onTreeSelect: function (tree, record) {
    record.get('depth')
    var me = this,
      viewModel = me.getViewModel(),
      materialassigned = me.lookupReference('materialassigned'),
      store = materialassigned.getStore()
    store.getProxy().setExtraParam('DPISegmentID', record.get('DPISegmentID'))
    store.getProxy().setExtraParam('ProductGroupID', record.get('ProductGroupID'))
    store.getProxy().setExtraParam('DPIFamilyID', record.get('DPIFamilyID'))
    store.getProxy().setExtraParam('viewAs', viewModel.get('viewAs'))
    store.load()
    viewModel.set('currentNode', record)
    var summaryStore = viewModel.getStore('summaryStore')
    summaryStore.load()
  },
  onBeforeLoadSummaryStore: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      record = viewModel.get('currentNode')
    store.getProxy().setExtraParam('DPISegmentID', record.get('DPISegmentID'))
    store.getProxy().setExtraParam('ProductGroupID', record.get('ProductGroupID'))
    store.getProxy().setExtraParam('DPIFamilyID', record.get('DPIFamilyID'))
    store.getProxy().setExtraParam('viewAs', viewModel.get('viewAs'))
  },
  onLoadSummaryStore: function (store) {
    var me = this
    const viewModel = me.getViewModel()
    const record = store.first()
    viewModel.set('summaryRec', record)
  },
  onSelectionChangeMainGrid: function (grid, records) {
    const me = this
    const viewModel = me.getViewModel()
    viewModel.set('maingridSelected', records)
  },
  onChangeView: function (cycleBtn, activeItem) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('matAssStore')
    viewModel.set('viewAs', activeItem.itemId)
    store.getProxy().setExtraParam('viewAs', viewModel.get('viewAs'))
    me.storeLoad()
  },
  onEditMaterial: function (grid, rowIndex, colIndex, item, e, record) {
    const me = this
    const viewModel = me.getViewModel()
    viewModel.getStore('matAssStore')
    viewModel.set('rec', record)
    const v = me.getView()
    const materialnew = me.lookup('materialnew')
    const selctn = materialnew.getSelectionModel()
    const s = selctn.getSelection()
    const newview = Ext.create('MDM.src.view.materialedit.Main', {
      listeners: {
        updated: function () {
          me.storeLoad()
        }
      },
      modal: true,
      crec: record
    })
    newview.show()
  },
  onEditMaterialNew: function (grid, rowIndex, colIndex, item, e, record) {
    const me = this
    const viewModel = me.getViewModel()
    viewModel.getStore('matAssStore')
    viewModel.set('rec', record)
    const v = me.getView()
    const materialnew = me.lookup('materialnew')
    const selctn = materialnew.getSelectionModel()
    const s = selctn.getSelection()
    if (s.length <= 1) {
      var newview = Ext.create('MDM.src.view.materialedit.Main', {
        listeners: {
          updated: function () {
            me.storeLoad()
          }
        },
        modal: true,
        crec: record
      })
      newview.show()
    } else {
      const selectedMaterialIds = []
      Ext.each(s, function (item) {
        selectedMaterialIds.push(item.get('ID'))
      })
      const win = Ext.create('MDM.view.regionapp.MassEdit.Main', {
        selectedMaterialIds: selectedMaterialIds
      })
      win.on('update', 'onUpdateNewMaterial', this)
      win.show()
    }
  },
  onUpdateNewMaterial: function () {
    this.getViewModel().getStore('MaterialNewStore').load()
  },
  onAddMaterial: function () {
    const me = this
    var newview = Ext.create('MDM.src.view.materialedit.Main', {
      listeners: {
        updated: function () {
          me.storeLoad()
        }
      },
      modal: true,
      isNew: true
    })
    newview.show()
  },
  removeDPIRelation: function (grid, rowIndex, colIndex, item, e, record) {
    const me = this
    Ext.Msg.show({
      title: 'Remove material from a DPI Family',
      width: 500,
      closable: false,
      msg:
        'Are you sure you want to remove <span style="color:blue"> ' +
        record.get('MaterialDescription') +
        ' </span>from <span style="color:blue">' +
        record.get('DPIFamily') +
        ' </span?',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doRemoveDPIRelation(record)
        }
      }
    })
  },
  onDropMaterial: function (tree, records, DPIFamilyID, grid) {
    const newData = []
    const me = this
    const viewModel = me.getViewModel()
    tree = me.lookupReference('tree')
    const MaterialUnassignedStore = viewModel.getStore('MaterialUnassignedStore')
    viewModel.getStore('matAssStore')
    const DPITreeStore = viewModel.getStore('DPITreeStore')
    records.forEach(function (rec) {
      newData.push({
        MaterialID: rec.get('MaterialID'),
        MaterialCode: rec.get('MaterialCode'),
        MaterialDescription: rec.get('MaterialDescription'),
        GU: rec.get('GU_Default'),
        RelationId: rec.get('RelationId')
      })
    }, this)
    const isDisabledBtn = grid.xtype === 'regionapp-panel-unassignedGrid'
    grid = Ext.create({
      xtype: 'prommptDialog',
      DPIFamilyID: DPIFamilyID,
      isDisabledBtn: isDisabledBtn
    })
    grid.on('updated', function () {
      const node = DPITreeStore.findNode('DPIFamilyID', DPIFamilyID)
      tree.getSelectionModel().select(node)
      MaterialUnassignedStore.load()
    })
    const store = grid.store
    let title = 'Move or Copy to  <b>' + text + '</b>'
    if (isDisabledBtn) {
      title = 'Assign selected materials to  <b>' + text + '</b>'
    }
    store.loadData(newData)
    const win = Ext.create('Ext.window.Window', {
      layout: 'fit',
      height: 300,
      ui: 'window-inverse',
      closable: false,
      modal: true,
      iconCls: 'fa fa-pencil-square',
      closeable: false,
      width: 600,
      title: title,
      items: [grid]
    })
    win.on('updated', function () {
      me.storeLoad()
    })
    win.show()
  },
  doRemoveDPIRelation: function (record) {
    var me = this
    const mainGrid = this.lookupReference('mainGrid')
    mainGrid.setLoading('Removing...')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/DPIRelationRemove',
      params: {
        RelationId: record.get('RelationId')
      },
      success: function () {
        mainGrid.setLoading(false)
        me.storeLoad()
      },
      failure: function () {
        Ext.MessageBox.hide()
      }
    })
  },
  onShowDropList: function () {
    const me = this
    me.win = Ext.create('MDM.src.view.regionapp.DroppedMaterial.Main', {
      userMegaRegion: me.getViewModel().get('userMegaRegion'),
      isGlobal: me.getViewModel().get('isGlobal')
    })
    me.getView().add(me.win)
    me.win.on('updated', function () {
      me.storeLoad()
    })
  },
  onBomListClick: function (grid, rowIndex, colIndex, item, e, record) {
    const me = this
    const win = Ext.create('MDM.src.view.BomEdit.Main', {
      record: record,
      searchCollapsed: true,
      closeable: false
    })
    win
      .on('updated', function () {
        me.storeLoad()
      })
      .show()
  },
  storeLoad: function () {
    const me = this
    const viewModel = me.getViewModel()
    const tabpanel = me.lookupReference('tabpanel')
    const store = tabpanel.getActiveTab().getStore()
    store.load()
    const summaryStore = viewModel.getStore('summaryStore')
    summaryStore.load()
  },
  onAddNEwPage: function (view, rowIndex, cellIndex, item, e, record) {
    const win = Ext.create('MDM.src.view.DPIAdd.Main', {
      record: record,
      title: 'DPI Family add/remove Material'
    })
    win.show()
  },
  onSearchTree: function (field) {
    const me = this
    const viewModel = me.getViewModel()
    const value = field.getValue()
    const lastFilterValue = viewModel.get('lastFilterValue')
    if (value === '') {
      field.getTrigger('clear').hide()
      me.filterStore(value)
      viewModel.set('lastFilterValue', value)
    } else if (value && value !== lastFilterValue) {
      field.getTrigger('clear')[value.length > 0 ? 'show' : 'hide']()
      me.filterStore(value)
      viewModel.set('lastFilterValue', value)
      const tree = this.lookup('tree')
      tree.expandAll()
    }
  },
  onSearchTriggerClick: function (field) {
    this.filterStore(field.getValue())
  },
  filterStore: function (value) {
    let me = this,
      store = this.getViewModel().getStore('DPITreeStore'),
      searchString = value.toLowerCase(),
      filterFn = function (node) {
        var children = node.childNodes,
          len = children && children.length,
          visible = v.test(node.get('text')),
          i
        if (!visible) {
          for (i = 0; i < len; i++) {
            if (children[i].isLeaf()) {
              visible = children[i].get('visible')
            } else {
              visible = filterFn(children[i])
            }
            if (visible) {
              break
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            children[i].set('visible', true)
          }
        }
        return visible
      },
      v
    if (searchString.length < 1) {
      store.clearFilter()
    } else {
      v = new RegExp(searchString, 'i')
      store.getFilters().replaceAll({
        filterFn: filterFn
      })
    }
  },
  onMatAssStoreLoad: function (store) {
    const me = this,
      currentNode = me.getViewModel().get('currentNode')
    this.getView().setLoading(false)
    const viewAs = this.getViewModel().get('viewAs')
    if (currentNode.get('DPIFamilyID') && viewAs === 'owner') {
      var mainGrid = this.lookup('materialassigned'),
        groupingFeature = mainGrid.view.lockedView.findFeature('grouping')
      groupingFeature.expandAll()
    }
    me.getViewModel().set('assignedcount', store.count())
  },
  exportTo: function (btn) {
    const me = this
    const tabPanel = me.lookup('tabpanel')
    const activeTab = tabPanel.getActiveTab()
    if (activeTab.xtype === 'panel') return
    const cfg = Ext.merge(
      {
        title: 'Material list',
        tableHeaderStyle: {
          alignment: {
            horizontal: 'Center',
            vertical: 'Center'
          },
          borders: [
            {
              position: 'Bottom',
              lineStyle: 'Continuous',
              weight: 4,
              color: '#4F81BD'
            }
          ],
          font: {
            fontName: 'Arial',
            family: 'Swiss',
            size: 11,
            bold: true,
            color: '#1F497D'
          }
        },
        fileName: 'GridExport' + '.' + (btn.cfg.ext || btn.cfg.type)
      },
      btn.cfg
    )
    activeTab.saveDocumentAs(cfg)
  },
  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },
  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDrop: function (grid, rowIndex, colIndex, item, e, record) {
    const me = this,
      rec = record,
      title = rec.get('MaterialDescription')
    MDM.src.ux.MessageBox.show({
      title: 'Suppress material',
      width: 500,
      closable: false,
      msg:
        '<br> Are you sure you want to suppress ' +
        title +
        ' from all lists and make this material inactive ? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDrop(record, grid)
        }
      }
    })
  },
  doDrop: function (record, grid) {
    const me = this
    const view = this.getView()
    const store = grid.store
    Ext.MessageBox.prompt('Suppress Reason', 'Reason:', function (btn, text) {
      if (btn === 'ok') {
        view.mask('Please wait ...')
        Ext.Ajax.request({
          method: 'POST',
          actionName: 'Drop process',
          url: MDM.Global.getApiUrl() + 'gillettev3/RegionApp_MDM_Material_Drop',
          params: {
            MaterialCode: record.get('MaterialCode'),
            Reason: text
          },
          success: function () {
            store.suspendAutoSync()
            store.remove(record)
            store.suspendAutoSync()
            view.unmask()
            me.storeLoad()
          },
          failure: function () {
            Ext.MessageBox.hide()
            view.unmask()
          }
        })
      }
    })
  },
  onBoxReady: function () {
    const form = this.lookup('form')
    const body = form.body
    this.formPanelDropTarget = new Ext.dd.DropTarget(body, {
      ddGroup: 'grid-to-form',
      notifyEnter: function () {
        // Add some flare to invite drop.
        body.stopAnimation()
        body.highlight()
        return true
      },
      notifyDrop: function (ddSource, e, data) {
        var grid = data.view.ownerGrid
        grid.fireEvent('dropped', grid)
        return true
      }
    })
  },
  onBeforeDrag: function () {
    this.getViewModel().set('isDrag', true)
  },
  onEndDrag: function () {
    this.getViewModel().set('isDrag', false)
  },
  onDropped: function (grid, recIndex, cellIndex, item, e, record) {
    let me = this,
      mainGrid = grid,
      view = this.getView(),
      selectedRecords = grid.getSelectionModel().getSelection(),
      title = '',
      records = [],
      hasrec = false
    if (record) {
      var id = record.id
      selectedRecords.forEach(function (record) {
        records.push(record)
        if (record.id == id) hasrec = true
      }, this)
      if (!hasrec) {
        records.push(record)
      }
    } else {
      records = selectedRecords
    }
    records.forEach(function (record) {
      title = title + '<br>' + record.get('MaterialDescription')
    }, this)
    MDM.src.ux.MessageBox.show({
      title: 'Suppress material',
      width: 500,
      closable: false,
      msg:
        'Are you sure you want to suppress selected materials from all lists and make them inactive?<br><span style="color:#0622fe;">' +
        title +
        '</span><br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      defaultButton: 'no',
      fn: function (response) {
        if (response === 'yes') {
          me.doDrop2(records, grid)
        }
      }
    })
  },
  doDrop2: function (records, grid) {
    var me = this,
      mainGrid = grid,
      view = this.getView(),
      store = grid.store,
      MaterialCode = []
    records.forEach(function (record) {
      MaterialCode.push({
        MaterialCode: record.get('MaterialCode')
      })
    }, this)
    MaterialCode = Ext.encode(MaterialCode)
    Ext.MessageBox.prompt('Drop Reason', 'Reason:', function (btn, text) {
      if (btn === 'ok') {
        view.mask('Please wait ...')
        Ext.Ajax.request({
          method: 'POST',
          url: MDM.Global.getApiUrl() + 'gillettev3/RMDM_Material_Drop',
          params: {
            MaterialCode: MaterialCode,
            Reason: text
          },
          success: function () {
            store.suspendAutoSync()
            var t = Ext.toast({
              html: 'Operation successfully completed',
              title: 'Information',
              width: 300,
              align: 'tr',
              iconCls: 'fa fa-check',
              closable: true
            })
            view.unmask('Please wait ...')
            me.storeLoad()
          },
          failure: function (response, opts) {
            Ext.MessageBox.hide()
            view.unmask('Please wait ...')
            MDM.src.ux.OnError.ajaxFailure(response, opts, 'suppress')
          }
        })
      }
    })
  },
  onSearchMaterialList: function () {
    const me = this
    const view = me.getView()
    const win = Ext.create('MDM.src.view.regionapp.Cmp.SearchBox')
    view.add(win)
    win.show()
  },
  onSearchMaterialListNoSupply: function () {
    const me = this
    const win = Ext.create('MDM.src.view.regionapp.Cmp.NoSupplysource')
    win.show()
  },
  onNewMaterialList: function () {
    const me = this
    const win = Ext.create('MDM.src.view.regionapp.Cmp.NewMaterial')
    win.show()
    win.on('dropped', function () {
      me.storeLoad()
    })
  },
  onExtractDetails: function (grid, rowIndex, colIndex, item, e, record) {
    const win = Ext.create('MDM.src.view.regionapp.Cmp.ExtractDialog', {
      record: record
    })
    win.show()
  },
  onCloseList: function () {
    MDM.src.ux.MessageBox.show({
      title: 'Suppress material',
      width: 500,
      closable: false,
      msg:
        'Are you sure you want to suppress selected materials from all lists and make them inactive?<br><span style="color:#0622fe;">' +
        title +
        '</span><br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      defaultButton: 'no',
      fn: function (response) {
        if (response === 'yes') {
          me.doDrop2(records, grid)
        }
      }
    })
  },
  onClearTriggerClick: function (field) {
    var me = this,
      store = me.store
    store.removeAll()
    field.setValue()
    field.getTrigger('clear').hide()
  },
  applySearchFilter: function (field, e) {
    // e.HOME, e.END, e.PAGE_UP, e.PAGE_DOWN,
    // e.TAB, e.ESC, arrow keys: e.LEFT, e.RIGHT, e.UP, e.DOWN
    var me = this,
      store = me.store
    if (e.getKey() === e.ENTER) {
      store.getProxy().setExtraParam('queryString', field.getValue())
      store.load()
    }
    if (e.getKey() === e.TAB) {
      store.getProxy().setExtraParam('queryString', field.getValue())
      store.load()
    }
  },
  onExractDetailsNewMaterial: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var win = Ext.create('MDM.src.view.regionapp.Cmp.ListDialog', {
      materialCode: record.get('materialCode')
    })
    win.on('close', me.onCloseList)
    win.show()
  },
  onApplyG11: function () {
    var me = this,
      viewModel = me.getViewModel()
    var records = viewModel.get('maingridSelected')
    var ids = []
    records.forEach(function (record) {
      ids.push(record.get('MaterialID'))
    }, this)
    var g11Store = viewModel.getStore('MaterialG11Store')
    var newMaterialStore = viewModel.getStore('MaterialNewStore')
    if (records.length > 0) {
      me.getView().setLoading(true)
      Ext.Ajax.request({
        method: 'POST',
        timeout: 360000,
        url: MDM.Global.getApiUrl() + 'gillettev3/G11Apply',
        params: {
          MaterialIDs: ids.join()
        },
        success: function (response, opts) {
          me.getView().setLoading(false)
          if (response.status === 200) {
            if (g11Store.isLoaded()) {
              g11Store.load()
            }
            if (newMaterialStore.isLoaded()) {
              newMaterialStore.load()
            }
          } else {
            Ext.MessageBox.alert('Error', 'Error on apply.')
          }
        },
        failure: function (response, opts) {
          me.getView().setLoading(false)
          Ext.MessageBox.alert('Error', 'Error on apply.')
        }
      })
    }
  },
  onEnable: function (tabPanel, newCard) {
    const me = this
    const viewModel = me.getViewModel()
    let isHidden = true
    if (newCard.title === 'G11 Control' || newCard.title === 'New Materials') {
      isHidden = false
    }
    viewModel.set('g11IsHidden', isHidden)
  }
})

Ext.define('MDM.ZIndexManager', {
  override: 'Ext.ZIndexManager',
  privates: {
    showModalMask: function (comp) {
      var me = this,
        compEl = comp.el,
        // maskTarget = comp.floatParent ? comp.floatParent.getEl() : comp.container,
        maskTarget = comp.container,
        mask = me.mask
      if (!mask) {
        // Create the mask at zero size so that it does not affect upcoming target measurements.
        me.mask = mask = Ext.getBody().createChild({
          //<debug>
          // tell the spec runner to ignore this element when checking if the dom is clean
          'data-sticky': true,
          //</debug>
          role: 'presentation',
          cls: Ext.baseCSSPrefix + 'mask ' + Ext.baseCSSPrefix + 'border-box',
          style: 'height:0;width:0'
        })
        mask.setVisibilityMode(Ext.Element.DISPLAY)
        mask.on({
          mousedown: me.onMaskMousedown,
          click: me.onMaskClick,
          scope: me
        })
      }
      // If the mask is already shown, hide it before showing again
      // to ensure underlying elements' tabbability is restored
      else {
        me.hideModalMask()
      }
      mask.maskTarget = maskTarget
      // Since there is no fast and reliable way to find elements above or below
      // a given z-index, we just cheat and prevent tabbable elements within the
      // topmost component from being made untabbable.
      maskTarget.saveTabbableState({
        excludeRoot: compEl
      })
      // Size and zIndex stack the mask (and its shim)
      me.syncModalMask(comp)
    }
  }
})
