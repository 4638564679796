export default `({
    columnLines: true,
    columns: [{
            text: tt('DateKey'),
            dataIndex: 'DateKey',
            align: 'center'
        },
        {
            text: tt('Sales Qty'),
            dataIndex: 'QTY',
            align: 'right'
        },
        {
            text: tt('Sales Price'),
            dataIndex: 'PriceNull',
            align: 'right'
        },
        {
            text: tt('NumberOfStore'),
            dataIndex: 'NumberOfStore'
        }
    ]
})
`
