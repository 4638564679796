import React, { memo } from 'react'
import NumberFormat from 'react-number-format'
import _ from 'lodash'
import { IProps } from './Input.types'

const Input = (props: IProps) => {
  const {
    numberFormatProps,
    inputProps: { name = null }
  } = props

  const optionalProps = name !== null ? { name } : {}

  return <NumberFormat {...numberFormatProps} {...optionalProps} />
}

const MemoizedInput = memo(Input, (prevProps: IProps, nextProps: IProps) => {
  return _.isEqual(prevProps.inputProps, nextProps.inputProps)
})

export default MemoizedInput
