Ext.define('MDM.Btl.cmp.Region', {
  extend: 'Ext.form.ComboBox',
  xtype: 'rcombo',
  reference: 'rcombo',
  fieldLabel: '<b>Subregion</b>',
  labelWidth: 60,
  margins: '0 4 0 0',
  width: 150,
  bind: {
    store: '{rStore}',
    value: '{RegionID}'
  },
  queryMode: 'local',
  displayField: 'name',
  valueField: 'value'
})
