Ext.define('clustering.ClusterController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.cluster',
  openStoreList: function () {
    var me = this,
      viewModel = me.getViewModel()
    Ext.create('Ext.window.Window', {
      title: 'Store Details',
      modal: true,
      resizable: false,
      maximizable: false,
      bodyPadding: 4,
      ui: 'window-inverse',
      items: [
        {
          layout: 'fit',
          xtype: 'storedetail',
          border: true,
          mainViewModel: viewModel
        }
      ]
    }).show()
  },
  openDataImport: function (filefield, value) {
    const me = this
    const viewModel = me.getViewModel()
    const excelReader = viewModel.get('excelReader')
    const currentConfigurationData = viewModel.get('currentConfigurationData')
    const input = event.target
    const files = input.files

    if (!value) {
      return
    }

    if (!files || files.length === 0) return

    const reader = new FileReader()

    reader.onload = function () {
      excelReader(files[0]).then(
        function (datas) {
          const data = _.uniq(_.flatMapDeep(datas))
          const unselectNames = _.reject(data, (item) => _.isNil(item))
          const trimmedUnselectNames = _.map(unselectNames, (name) => _.trim(name))

          let lastLevel = 0
          const firstScope = _.cloneDeep(currentConfigurationData.Scopes[0])
          const isLastLevel =
            'Levels' in firstScope && _.isArray(firstScope.Levels) && firstScope.Levels.length > 0

          if (isLastLevel) {
            lastLevel = firstScope.Levels.length - 1
          }

          var currentStoresValues = firstScope.Levels[lastLevel].Values

          //match the names of stores to make them unselected
          const selectData = _.filter(currentStoresValues, (item) => {
            return !_.includes(trimmedUnselectNames, item.Value)
          })
          var selectedIndices = _.map(selectData, (item) => {
            return item.Key
          })
          if (lastLevel) {
            firstScope.Levels[lastLevel].SelectedIndices = selectedIndices
          }

          viewModel.set('currentConfigurationData', currentConfigurationData)

          const combo = Ext.getCmp('combo_0_' + lastLevel + '_combo')
          if (combo.store.data.items.length === 0) {
            combo.store.load(function () {
              setTimeout(() => {
                combo.setValue(selectedIndices)
              }, 150)
            })
          } else {
            combo.setValue(selectedIndices)
          }
        },
        function () {
          alert('Error while parsing Excel file. See console output for the error stack trace.')
        }
      )
    }

    reader.readAsBinaryString(files[0])
    // User can reselect the same file, therefore reset the filefield component
    filefield.reset()
  },
  onAssignClusters: function (textfield) {
    textfield.blur()
    var me = this,
      viewModel = me.getViewModel(),
      clusterStore = viewModel.getStore('clusterStore'),
      segmentStore = viewModel.getStore('segmentStore'),
      dimensions = viewModel.get('dimensions'),
      numberOfTestStores = viewModel.get('numberOfTestStores')

    var clusterDetails = {
      Segments: segmentStore.getDatas(),
      Stores: clusterStore.getDatas(),
      Dimensions: dimensions
    }

    var saveClusterObject = {
      clusterDetails: clusterDetails
    }
    Ext.create('Ext.window.Window', {
      title: 'Store Details',
      modal: true,
      resizable: false,
      maximizable: false,
      bodyPadding: 4,
      ui: 'window-inverse',
      items: [
        {
          xtype: 'form',
          labelWidth: 100,
          defaults: {
            margin: '10px'
          },
          items: [
            {
              xtype: 'numberfield',
              fieldLabel: 'Number Of Test Stores',
              name: 'numberOfTestStores',
              minValue: 0,
              value: numberOfTestStores
            }
          ],
          dockedItems: [
            {
              xtype: 'toolbar',
              dock: 'bottom',
              reference: 'toolbar',
              overflowHandler: 'scroller',
              items: [
                '->',
                {
                  text: 'Apply',
                  handler: function (btn) {
                    var window = btn.up('window'),
                      form = btn.up('form')

                    var values = form.getValues()
                    if (!values.numberOfTestStores) {
                      alert('Please enter number of test stores.')
                      return
                    }

                    saveClusterObject.numberOfTestStores = values.numberOfTestStores

                    viewModel.set('numberOfTestStores', values.numberOfTestStores)

                    Ext.Ajax.request({
                      url: clustering.Global.getApiUrl() + 'cluster/SelectTestStores',
                      params: {
                        configuration: JSON.stringify(saveClusterObject)
                      },
                      timeout: 600000,
                      success: function (response) {
                        var result = Ext.JSON.decode(response.responseText)

                        var testStores = []
                        if (result.Stores) {
                          testStores = result.Stores.filter(function (item) {
                            return item.IsTest && item.Id
                          }).map(function (item) {
                            if (item.IsTest && item.Id) {
                              return item.Id
                            }
                          })
                        }

                        clusterStore.suspendEvents()

                        clusterStore.each(function (record) {
                          record.set('IsTest', testStores.indexOf(record.data.Id) > -1)
                        })

                        clusterStore.resumeEvents()
                        window.close()
                        clusterStore.fireEvent('update', clusterStore)
                      },

                      failure: function () {
                        clusterView.getEl().unmask()
                        alert('Select test stores has failed.')
                      }
                    })
                  }
                }
              ]
            }
          ]
        }
      ]
    }).show()
  }
})
