Ext.define('tuiv2.cmp.DemandDetailSummary', {
  extend: 'Ext.Component',
  xtype: 'tuiv2-demandDetailSummaryTmp',
  draggable: true,
  listeners: {
    click: {
      fn: function (event, tile) {
        var me = this.component

        var view = me.rootView

        var controller = view.getController()

        var data = me.getData()

        if (event.target.classList.contains('slvy-ui-icon-times-lt')) {
          var mainMap = me.rootView.down('mainMap')

          var map = mainMap.getMap()
          if (controller.popups[me.popupId]) {
            map.closePopup(controller.popups[me.popupId])
            delete controller.popups[me.popupId]
          } else {
            me.destroy()
          }
          return
        }

        if (event.target.classList.contains('destroy2')) {
          me.destroy()
        }
      },
      element: 'el'
    }
  },
  tpl: new Ext.XTemplate(
    '<div class="card -demand" style="position: absolute !important;">' +
      '<div class="titleBar ">' +
      '<p class="title">{Title}</p>' +
      '<div class="card-close"><span class="slvy-ui-icon-times-lt"></span></div>' +
      '</div>' +
      '    <div class="header -first">' +
      '      <div class="info">' +
      '         <div class="bottom">' +
      '         <div class="field1">{SubTitle}</div>' +
      '         <div class="field2"></div>' +
      '         <div class="field3"></div>' +
      '         </div>' +
      '         <div class="left">' +
      '           <ul class="header-actions">' +
      '             <li class="icon"><span class="slvy-ui-icon-arrow-left-extra-lt destroy2"></span></li>' +
      '           </ul>' +
      '         </div>' +
      '      </div>' +
      '   </div>' +
      '   <div class="grid-container-tui-v2">' +
      '      <ul class="list">' +
      '<tpl for=".">' +
      '        <li>{Title}</li>' +
      '</tpl>' +
      '      </ul>' +
      '</div>',
    {
      getPalletArrayForContainer: function (demands, maxPallet, id) {
        var items = demands.reduce((a, b) => a.concat(b.Items), [])

        var loadedPallet = items.reduce(function (a, b) {
          if (b.IsPlanned && b.LoadId != null && b.LoadId.length > 0) {
            return (a = a + b.NumPallet)
          }
          return a
        }, 0)

        var array = []

        while (maxPallet > 0) {
          if (loadedPallet > 0) {
            array.push({
              palletWidth: 100,
              color: tuiv2.Colors.getColorByName(id),
              class: '-percent-bg'
            })

            loadedPallet--
          } else {
            array.push({
              palletWidth: 100,
              color: 'transparent',
              class: '-empty'
            })
          }

          maxPallet--
        }

        return array
      },
      getPalletArray: function (items) {
        var array = []
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        items = items.sort(function (item) {
          return item.LoadId ? -1 : 1
        })
        for (let index = 0; index < items.length; index++) {
          const item = items[index]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned'
              })
            } else if (item.LoadId != null && item.LoadId.length > 0) {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg'
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9' // class: '-'
              })
            }
            palletCount = palletCount - 1
          }
        }
        return array
      },
      getColorCode: function (id) {
        return tuiv2.Colors.getColorByName(id)
      },
      formatNumber: function (value) {
        if (value == Number.NaN || value == Number.MAX_VALUE) value = 0
        if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

        return Ext.util.Format.number(value, '0,000.00')
      }
    }
  )
})
