import { useCallback, useEffect, useRef } from 'react'
import { transform, uniq } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { tuiSlice } from '../../../store/slices'
import { collectNodes } from '@/plugins/Tui/utils'

function useLoadRowSelected({ loadTreeTableRef }) {
  const loadRowRef = useRef<
    Record<string, (rowIds: Record<string, unknown>[]) => void | undefined>
  >({})
  const [{ registerEvent }] = useReduxState(tuiSlice.selectSlice)
  const [{ config, getFieldType }] = useReduxState(tuiSlice.selectSlice)

  const loadRowSelected = useCallback(
    (selectedRows) => {
      const { flatRows } = loadTreeTableRef.current.getRowModel()

      const selectedLoadRows = uniq(
        collectNodes({ Children: selectedRows }, (node) => node?.Children?.[0]?.Leaf)
      )

      const selectedFieldsByColumn = config.loadTree.columns.reduce((acc, field) => {
        const fieldValues = selectedLoadRows.reduce((fields, selectedLoadRow) => {
          const matchingRow = flatRows.find((row) => row?.original.ID === selectedLoadRow.ID)

          if (matchingRow?.getIsAllSubRowsSelected()) {
            return fields.concat(selectedLoadRow.Children[0][field.dataIndex])
          }

          return fields
        }, [])

        acc[field.dataIndex] = fieldValues.length > 0 ? fieldValues : null
        return acc
      }, {})

      return selectedFieldsByColumn
    },
    [config.loadTree.columns, loadTreeTableRef]
  )

  useEffect(() => {
    const loadRowSelectedParams = {}

    config.loadTree.columns.forEach((item) => {
      loadRowSelectedParams[item.dataIndex] = PluginTypes.fromString(item.dataType)
    })

    const fn = 'handleLoadRowSelected'
    loadRowRef.current[fn] = registerEvent({
      key: 'LoadRowSelected',
      fn: loadRowSelected,
      returnTypes: transform(
        loadRowSelectedParams,
        (result, _value, key) => {
          // eslint-disable-next-line no-param-reassign
          result[key] = getFieldType(key, true)
        },
        {}
      )
    })
  }, [config.loadTree.columns, getFieldType, loadRowSelected, registerEvent])

  return { loadRowRef }
}

export default useLoadRowSelected
