import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LoadTreeTableState = {
  selectedRows: Record<string, unknown>[]
  loadStops: string[]
  loadIndexes: number[]
  isLoadApprovalModalOpen: boolean
  isRemoveLoadModalOpen: boolean
  isRemoveLoadStopModalOpen: boolean
  isChangeTransportationModalOpen: boolean
  selectedLoadApprovalRow: Record<string, unknown>[]
  selectedReasonCodeId: number | undefined
  disabledFooterButtons: Record<string, boolean>
  rowColors: Record<string, string>
}

const initialState: LoadTreeTableState = {
  selectedRows: [],
  loadStops: [],
  loadIndexes: [],
  isLoadApprovalModalOpen: false,
  isRemoveLoadModalOpen: false,
  isRemoveLoadStopModalOpen: false,
  isChangeTransportationModalOpen: false,
  selectedLoadApprovalRow: [],
  selectedReasonCodeId: undefined,
  disabledFooterButtons: {},
  rowColors: {}
}

const loadTreeTableSlice = createSlice({
  name: 'loadTreeTable',
  initialState,
  reducers: {
    setSelectedRows(state, action: PayloadAction<LoadTreeTableState['selectedRows']>) {
      state.selectedRows = action.payload
    },
    setLoadStops(state, action: PayloadAction<LoadTreeTableState['loadStops']>) {
      state.loadStops = action.payload
    },
    setLoadIndexes(state, action: PayloadAction<LoadTreeTableState['loadIndexes']>) {
      state.loadIndexes = action.payload
    },
    setIsLoadApprovalModalOpen(state, action: PayloadAction<boolean>) {
      state.isLoadApprovalModalOpen = action.payload
    },
    setIsRemoveLoadModalOpen(state, action: PayloadAction<boolean>) {
      state.isRemoveLoadModalOpen = action.payload
    },
    setIsRemoveLoadStopModalOpen(state, action: PayloadAction<boolean>) {
      state.isRemoveLoadStopModalOpen = action.payload
    },
    setIsChangeTransportationModalOpen(state, action: PayloadAction<boolean>) {
      state.isChangeTransportationModalOpen = action.payload
    },
    setSelectedLoadApprovalRow(
      state,
      action: PayloadAction<LoadTreeTableState['selectedLoadApprovalRow']>
    ) {
      state.selectedLoadApprovalRow = action.payload
    },
    setSelectedReasonCodeId(
      state,
      action: PayloadAction<LoadTreeTableState['selectedReasonCodeId']>
    ) {
      state.selectedReasonCodeId = action.payload
    },
    setDisabledFooterButtons(state, action: PayloadAction<{ text: string; value: boolean }>) {
      const { text, value } = action.payload
      state.disabledFooterButtons[text] = value
    },
    setRowColors(state, action: PayloadAction<LoadTreeTableState['rowColors']>) {
      state.rowColors = action.payload
    }
  }
})

export default loadTreeTableSlice
export type { LoadTreeTableState }
