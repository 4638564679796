import LogoSmall from '../../assets/images/solvoyo-logo-small.png'
import LogoLarge from '../../assets/images/solvoyo-logo-2x.v2.png'
import { ISlvyLogoProps } from './SlvyLogo.types'

const SlvyLogo = ({ size, attributes = {} }: ISlvyLogoProps) => {
  return (
    <img
      alt="SOLVOYO LOGO"
      className="slvy-logo"
      data-testid="slvy-logo"
      height={size === 'sm' ? 40 : 170}
      src={size === 'sm' ? LogoSmall : LogoLarge}
      width={size === 'sm' ? 140 : 170}
      {...attributes}
    />
  )
}

export default SlvyLogo
