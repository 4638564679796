import { checkActionButtonDisabled } from '../cell'
import { CheckDisabledConditionProps } from '../cell.types'
import { cellParamData, validCellParamsData } from './mocks'

const checkDisabledConditionParams = (): CheckDisabledConditionProps => ({
  record: cellParamData,
  disabledCondition: ''
})

describe('AgGrid:Helpers:Cell:checkActionButtonDisabled', () => {
  let params = checkDisabledConditionParams()

  beforeEach(() => {
    params = checkDisabledConditionParams()
  })

  it('should return true if disable condition exists in the record and its value is false', () => {
    params.disabledCondition = 'col10'

    const result = checkActionButtonDisabled(params)
    expect(result).toBe(true)
  })

  it('should return false if disable condition exists in the record and its value is true', () => {
    params.record = validCellParamsData
    params.disabledCondition = 'col10'

    const result = checkActionButtonDisabled(params)
    expect(result).toBe(false)
  })

  it('should return false if disabled condition is not defined', () => {
    params.disabledCondition = undefined

    const result = checkActionButtonDisabled(params)
    expect(result).toBe(false)
  })
})
