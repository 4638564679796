import React from 'react'
import _ from 'lodash'
import cx from 'classnames'
import './index.scss'
import { IMetricSelectionProps } from './MetricSelection.types'

const MetricSelection = ({
  metricFields,
  selectedMetricFields,
  className = 'dp-metric-selection',
  onMetricChanged
}: IMetricSelectionProps) => {
  const metricList = _.pullAllBy([...metricFields], [...selectedMetricFields], 'Name')

  return (
    <ul className={cx('metric-group', className)}>
      {selectedMetricFields.map((selectedMetricFieldItem, selectedMetricFieldIndex) => {
        const $first = selectedMetricFieldIndex === 0
        const isNotEmpty = selectedMetricFieldItem.Name && selectedMetricFieldItem.DisplayName
        const defaultMetric = { DisplayName: '', Name: '', Format: '', IsUpdateAllowed: false }
        return (
          <li
            key={`smf-${selectedMetricFieldIndex}`}
            className={cx('metric', { '-loaded': isNotEmpty })}
          >
            {!$first && isNotEmpty ? (
              <a
                className="remove"
                onClick={() => onMetricChanged(defaultMetric, selectedMetricFieldIndex)}
              >
                <span className="slvy-ui-icon-trash-can" />
              </a>
            ) : null}
            <div className="dot">
              <span className="slvy-ui-icon-view-regular" />
            </div>
            {isNotEmpty ? (
              <div className="lbl-name">{selectedMetricFieldItem.DisplayName}</div>
            ) : null}
            {metricList.length ? (
              <ul className="dropdown">
                {metricList.map((metricFieldItem, metricFieldIndex) => {
                  return (
                    <li
                      key={`mf-${metricFieldIndex}`}
                      className="option"
                      onClick={() => onMetricChanged(metricFieldItem, selectedMetricFieldIndex)}
                    >
                      {metricFieldItem.DisplayName}
                    </li>
                  )
                })}
              </ul>
            ) : null}
          </li>
        )
      })}
    </ul>
  )
}

export default MetricSelection
