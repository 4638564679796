export default `({
    title: tt('Spot Faaliyet Notları'),
    xtype: 'panel',
    border: true,
    header: false,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b>' + tt('Campaign Notes') + '</b>'
        }]
    }],
    height: 200,
    margin: 2,
    layout: 'fit',
    style: {
        "padding": "1px",
        "background": "#fff",
    },
    padding: '5 1 5 1',
    items: [{
        xtype: 'container',
        style: {
            "padding": "1px",
            padding: '10px',
            //"background": "#F6F6F6",
        },
        border: true,
        bind: {
            html: '{spotRecord.Notes}'
        },
        margin: '0',
        // fieldLabel:'Note',
        afterLabelTextTpl: [
            '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
        ],
        allowBlank: false
    }]
})
`
