import { Alert } from 'react-bootstrap'

const NoVariables = ({ text = 'No variables are found!' }) => {
  return (
    <Alert className="mb-0 d-flex align-items-center" variant="warning">
      <i aria-hidden="true" className="fa fa-info-circle me-2 fs-5" />
      <p className="fs-sm mb-0">{text}</p>
    </Alert>
  )
}

export default NoVariables
