export default () => {
  return {
    title: 'Gillette Packages',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          application: {
            type: 'string',
            title: 'Application',
            enum: [
              'BTLEdit',
              'Calendar',
              'Cost',
              'DFCN',
              'GlobalApp',
              'Grouping',
              'MassImport',
              'RDC',
              'RegionApp'
            ],
            default: 'RegionApp',
            required: true
          }
        }
      },
      BTLEdit: {
        type: 'object',
        title: 'BTLEdit',
        properties: {
          actions: {
            type: 'object',
            title: 'Actions',
            properties: {
              actionItems: {
                type: 'array',
                title: 'Action Items',
                items: {
                  type: 'object',
                  title: 'Item',
                  headerTemplate: '{{ self.name }}',
                  properties: {
                    name: {
                      type: 'string',
                      title: 'Name'
                    },
                    title: {
                      type: 'string',
                      title: 'Title'
                    },
                    icon: {
                      type: 'string',
                      title: 'Icon',
                      format: 'IconSelector'
                    }
                  }
                }
              }
            }
          }
        }
      },

      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      }
    }
  }
}
