import React from 'react'
import Circle from './Circle'
import Horizontal from './Horizontal'
import Combined from './Combined'
import Box from './Box'

export default function Charts(props) {
  const { settings: { chartType = '' } = {} } = props

  switch (chartType) {
    case 'horizontal':
      return <Horizontal {...props} />
    case 'combined':
      return <Combined {...props} />
    case 'boxProgress':
      return <Box {...props} />
    default:
      return <Circle {...props} />
  }
}
