Ext.define('MDM.src.view.DPIAdd.Panel.DPIMatList', {
  extend: 'Ext.grid.Panel',
  // Uncomment to give this component an xtype
  xtype: 'DPIAdd-list',
  plugins: [
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],
  listeners: {
    activate: function (grid) {
      grid.store.clearFilter()
    }
  },
  emptyText: 'norecord',
  viewConfig: {
    plugins: [
      {
        ptype: 'gridviewdragdrop',
        copy: true,
        containerScroll: true,
        dragGroup: 'dd-grid-to-grid-group1',
        dropGroup: 'dd-grid-to-grid-group2'
      }
    ],
    listeners: {
      beforedrop: 'onBeforeDrop'
    }
  },
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      text: 'MaterialCode',
      dataIndex: 'MaterialCode',
      flex: 1,
      sortable: true
    },
    {
      text: 'MaterialDescription',
      dataIndex: 'MaterialDescription',
      flex: 2,
      sortable: true
    },
    {
      text: 'GU',
      dataIndex: 'GU',
      width: 120,
      sortable: true,
      align: 'right',
      xtype: 'numbercolumn',
      format: '0.00',
      editor: {
        xtype: 'numberfield',
        allowBlank: false,
        minValue: 0,
        maxValue: 100000,
        step: 0.1
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'fa fa-chain-broken',
          tooltip: 'Remove from DPI',
          handler: function (grid, rowIndex, colIndex, item, e, record) {
            var store = grid.store
            store.remove(record)
          }
        }
      ]
    }
  ]
})
