import Highcharts from 'highcharts'

const getScrollSize = (isTrackpad, isHorizontalScroll) => {
  if (!isTrackpad) {
    return 10
  }

  if (isHorizontalScroll) {
    return 35
  }

  return 25
}

// This function adds custom mouse wheel scroll functionality
// because Highcharts scrollbar does not support it by default
const scroll = (H) => {
  Highcharts.Chart.prototype.callbacks.push((chart) => {
    H.addEvent(chart.container, 'wheel', (event) => {
      if (!chart?.resetZoomButton) {
        return
      }

      const { wheelDeltaX, wheelDeltaY } = event
      event.preventDefault()

      // https://stackoverflow.com/a/62415754
      const isTrackpad = Math.abs(wheelDeltaY) !== 120
      const isHorizontalScroll = isTrackpad && Math.abs(wheelDeltaX) >= Math.abs(wheelDeltaY)

      const xAxis = chart?.xAxis?.[0]
      const yAxis = chart?.yAxis?.[0]

      // For bar chart type, the axis position differs from other chart types.
      const isBarChart = chart.options.chart.type === 'bar'
      const horizontalAxis = isBarChart ? yAxis : xAxis
      const verticalAxis = isBarChart ? xAxis : yAxis
      const activeAxis = isHorizontalScroll ? horizontalAxis : verticalAxis

      if (!activeAxis?.scrollbar) {
        return
      }

      const { min, max, dataMin, dataMax } = activeAxis.getExtremes()

      const wheelDelta = isHorizontalScroll ? wheelDeltaX : wheelDeltaY
      const delta = wheelDelta < 0 ? 1 : -1
      const scrollSize = getScrollSize(isTrackpad, isHorizontalScroll)
      let step = ((max - min) / scrollSize) * delta
      step = isBarChart || isHorizontalScroll ? step : step * -1

      // Calculate new extremes
      const newMin =
        isHorizontalScroll || !isBarChart
          ? Math.max(min + step, activeAxis?.threshold ?? 0)
          : Math.max(min + step, dataMin)
      const newMax = isHorizontalScroll || !isBarChart ? max + step : Math.min(max + step, dataMax)

      const isExceedBelowLimit = activeAxis.scrollbar.from === 0 && delta === -1
      const isExceedUpperLimit = activeAxis.scrollbar.to === 1 && delta === 1
      const isScrollAllowed = !isExceedBelowLimit && !isExceedUpperLimit

      if (isScrollAllowed) {
        activeAxis.setExtremes(newMin, newMax, true, false)
      }
    })
  })
}

export default scroll
