Ext.define('MDM.src.view.regionapp.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.regionapp-mainviewmodel',
  stores: {
    DPITreeStore: {
      type: 'tree',
      model: 'MDM.src.view.regionapp.model.DPITree',
      autoLoad: true,
      root: {
        expanded: true,
        text: 'DPI Families',
        iconCls: 'fa fa-sitemap',
        id: '0',
        children: []
      },
      listeners: {
        load: 'onDPITreeStoreLoad'
      }
    },
    matAssStore: {
      model: 'MDM.src.view.regionapp.model.MaterialAssigned',
      pageName: 'Material List',
      listeners: {
        beforeload: function () {},
        load: function () {}
      }
    },
    summaryStore: {
      model: 'MDM.src.view.regionapp.model.Summary',
      pageName: 'Summary',
      listeners: {
        beforeload: 'onBeforeLoadSummaryStore',
        load: 'onLoadSummaryStore'
      }
    },
    matUnAssStore: {
      model: 'MDM.src.view.regionapp.model.MaterialUnAssigned',
      pageName: 'UnAssigned List',
      listeners: {
        beforeload: 'onBeforeLoadMaterialUnassignedStore'
      }
    },
    MaterialNewStore: {
      pageName: 'New List',
      model: 'MDM.src.view.regionapp.model.Material_New'
    },
    MaterialG11Store: {
      pageName: 'New List',
      model: 'MDM.src.view.regionapp.model.Material_G11'
    },
    matNoSupplySource: {
      pageName: 'Nosupplysource List',
      model: 'MDM.src.view.regionapp.model.MaterialNoSupplySource'
    },
    tempStore: {
      autoLoad: true,
      fields: [],
      proxy: {
        type: 'memory',
        reader: {
          type: 'json',
          rootProperty: 'users'
        }
      }
    }
  },
  formulas: {
    selectedNodeName: {
      bind: {
        bindTo: '{currentNode}',
        deep: true
      },
      get: function (rec) {
        return rec.get('text')
      }
    },
    ViewMode: {
      bind: {
        viewAs: '{viewAs}',
        userMegaRegion: '{userMegaRegion}'
      },
      get: function (d) {
        if (d.viewAs == 'owner') return d.userMegaRegion
        else {
          return 'All Region'
        }
      }
    },
    gridSelections: {
      bind: {
        bindTo: '{maingridSelected}',
        deep: true
      },
      get: function (records) {
        return records.length > 0
      }
    }
  },
  data: {
    viewAs: 'owner',
    maingridSelected: [],
    isGlobal: false,
    userMegaRegion: 'Own',
    isdragged: false,
    g11IsHidden: true,
    isDrag: false,
    currentPeriod: '',
    owner: 'Region',
    currentReportingPeriod: '',
    assignedcount: 0,
    unassignedcount: 0,
    NumberOfDPI: 0
  }
})
