import React from 'react'
import { Modal, Button, Container, Row, Col, Ratio, Card } from 'react-bootstrap'
import { SlvySpinner } from '../../../components'

export default class FileModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false
    }

    this.hideModal = this.hideModal.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.show !== nextProps.show) {
      this.setState({ show: nextProps.show })
    }
  }

  componentDidMount() {
    this.setState({ show: this.props.show })
  }

  hideModal() {
    this.setState({ show: false })
    this.props.onClose()
  }

  render() {
    const { isPdfReady } = this.props

    return (
      <Modal dialogClassName="pdf-modal" show={this.state.show} size="xl" onHide={this.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              {isPdfReady ? <SlvySpinner /> : null}
              <Container fluid>
                <Row>
                  <Col md={12} sm={12}>
                    <Ratio aspectRatio="16x9">
                      <iframe src={this.props.pdfUrl} title="PDF Export" />
                    </Ratio>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={this.hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
