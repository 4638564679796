export default [
  {
    name: 'assorty',
    displayName: 'Prepack',
    isVisible: true,
    isFixed: 'left',
    filterable: true,
    width: 200
  },
  {
    name: 'Type',
    displayName: 'Type',
    isVisible: true,
    isFixed: 'left',
    filterable: true,
    width: undefined
  },
  {
    name: 'Include',
    displayName: 'Include',
    isVisible: true,
    isFixed: 'left',
    filterable: false,
    width: undefined
  },
  {
    name: 'OptimalQuantity',
    displayName: 'Opt Qty',
    isVisible: true,
    isFixed: 'left',
    filterable: false,
    width: undefined
  },
  {
    name: 'RevisedQuantity',
    displayName: 'Rev Qty',
    isVisible: true,
    isFixed: 'left',
    filterable: false,
    width: undefined
  }
]
