Ext.define('MDM.src.view.globalapp.Panel.Geo.Main', {
  extend: 'Ext.panel.Panel',
  xtype: 'globalapp-geo-main',
  layout: 'border',
  viewModel: 'globalapp-geo-mainviewmodel',
  controller: 'globalapp-geo-maincontroller',
  listeners: {
    activate: 'onActivateGeo'
  },
  defaults: {
    collapsible: true,
    split: true
  },
  items: [
    {
      xtype: 'globalapp-Geo-panel-tree',
      region: 'west',
      margin: '0 2  0 0',
      width: 500,
      border: true,
      reference: 'tree',
      title: 'Georgraphy Hierarchy',
      bind: {
        store: '{GeoTreeStore}'
      }
    },
    {
      flex: 4,
      xtype: 'panel',
      region: 'center',
      bodyPadding: 10,
      layout: 'fit',
      margin: '0 2 0 0',
      title: 'Map Of',
      items: [
        {
          xtype: 'globalapp-EchartsMap',
          flex: 4,
          reference: 'echartmap',
          border: true
        }
      ]
    }
  ]
})
