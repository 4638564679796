import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'
import {
  saveScenarioTreeViewSettings,
  savePivotBuilderViewSettings,
  setLoader as setActionLoader
} from '../../store/actions'
import { slvyToast } from '../../../../components'
import App from './app'
import { getAllPivotBuilderStates, setLoader, copyClipBoard, setDraftToState } from '../../utils'
import { MESSAGES } from '../../messages'
import { OverlayTrigger } from '..'

class PivotBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisibleCreateState: false,
      previousStateId: '',
      selectedStateId: '',
      previousStateName: '',
      selectedStateName: '',
      newStateName: '',
      allPluginStates: [],
      pivotConfig: {
        LeftAxisDraggable: [],
        TopAxisDraggable: [],
        ValuesDraggable: [],
        DefaultAxisDraggable: []
      },
      publicSettings: {
        name: '',
        id: '',
        isPublic: false
      },
      buttonMenu: false
    }

    this.listButtonRef = createRef()

    this.getData = this.getData.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.isConfigEmpty = this.isConfigEmpty.bind(this)
  }

  componentDidMount() {
    const { props } = this
    this.getData(props, true)

    const {
      public: isPublic = false,
      config: { stateId = '', stateSettings: { name = '' } = {} } = {}
    } = this.props.getSelectedPivotBuilderState()

    const initialState = setDraftToState(props, _.cloneDeep(this.getAllPbStates()))

    this.setState({
      previousStateId: stateId,
      selectedStateId: stateId,
      previousStateName: name,
      selectedStateName: name,
      allPluginStates: initialState,
      publicSettings: {
        name,
        id: stateId,
        isPublic
      }
    })

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props, nextProps)) {
      this.setState({
        allPluginStates: setDraftToState(nextProps, this.getAllPbStates(nextProps))
      })
    }
  }

  handleClickOutside = ({ target }) => {
    if (this.listButtonRef.current && !this.listButtonRef.current.contains(target)) {
      this.setState({ buttonMenu: false })
    }
  }

  toggleLoader(isVisible = false, msg = MESSAGES.loading) {
    setLoader(this.props.setActionLoader, isVisible, msg)
  }

  isConfigEmpty(config) {
    return _.reduce(
      config,
      (sw, item) => {
        if (item.length > 0) {
          return false
        }
        return sw
      },
      true
    )
  }

  getCurrentState(params) {
    params = _.cloneDeep(params)
    const { context, state } = params || {}
    const pivotConfig = _.cloneDeep(this.state.pivotConfig)
    pivotConfig[context] = state

    const isContextLeftAxis = context === 'LeftAxisDraggable'
    const isContextDefaultAxis = context === 'DefaultAxisDraggable'
    if (isContextLeftAxis || isContextDefaultAxis) {
      pivotConfig[context] = pivotConfig[context].map((item) => {
        item.Checked = isContextLeftAxis
        return item
      })
    }
    this.setState({ pivotConfig })
  }

  changeCurrentState(params) {
    params = _.cloneDeep(params)
    const pivotConfig = _.cloneDeep(this.state.pivotConfig)
    const { card, value, context } = params

    const currentIndex = pivotConfig[context].findIndex((item) => item.Name === card.Name)
    pivotConfig[context][currentIndex].AggregationFunction = value

    if (_.isEmpty(value)) {
      pivotConfig[context][currentIndex].Checked = !card.Checked
    }

    this.setState({ pivotConfig })
  }

  getData(props) {
    const { pivotConfig } = this.state
    const { pivotState = pivotConfig } = props
    this.setState({ pivotConfig: pivotState })
  }

  onDragEnd() {}

  getAllPbStates(props = this.props) {
    return getAllPivotBuilderStates(props)
  }

  goDefault() {
    const {
      config: { stateId = '', stateSettings: { name = '' } = {}, state: pivotConfig = {} } = {}
    } =
      _.cloneDeep(this.state.allPluginStates).find((item) => item.config.stateSettings.isDefault) ||
      {}

    this.setState({
      previousStateId: stateId,
      selectedStateId: stateId,
      previousStateName: name,
      selectedStateName: name,
      pivotConfig
    })
  }

  onStateChanged(event) {
    const _selectedStateId = event.target.value
    const { previousStateName } = this.state
    const { previousStateId } = this.state

    const { config: { stateSettings: { name: _selectedStateName = '' } = {} } = {} } =
      this.getStateByStateId(_selectedStateId)

    this.toggleLoader(true)

    this.setState(
      {
        previousStateId,
        previousStateName,
        selectedStateName: _selectedStateName,
        selectedStateId: _selectedStateId
      },
      () => {
        // UPDATE SELECTED with: isSelected: true
        const selectedPluginState = this.getStateByStateId(_selectedStateId)
        selectedPluginState.config.stateSettings = {
          ...selectedPluginState.config.stateSettings,
          name: _selectedStateName
        }

        this.props.executePivotMainConfiguration(
          ({ isOk, isReturnDefault = false }) => {
            this.props._savePivotBuilderViewSettings({ draftState: {} }, () => {
              this.setState({ allPluginStates: this.getAllPbStates() }, () => {
                if (isReturnDefault) {
                  this.goDefault()
                } else if (isOk) {
                  this.getData({ pivotState: selectedPluginState.config.state }, true)
                }
                this.toggleLoader(false)
              })
            })
          },
          selectedPluginState,
          false
        )
      }
    )

    this.setState({ buttonMenu: false })
  }

  getStateByStateId($stateId) {
    const pluginStates = _.cloneDeep(this.state.allPluginStates)
    return pluginStates.find((item) => item.config.stateId === $stateId)
  }

  isValidConfig($config) {
    const $activeValuesDraggable = _.cloneDeep($config.ValuesDraggable).filter(
      (item) => item.Checked
    )
    const $activeLeftAxisDraggable = _.cloneDeep($config.LeftAxisDraggable).filter(
      (item) => item.Checked
    )
    if (!$activeValuesDraggable.length || !$activeLeftAxisDraggable.length) {
      return false
    }
    return true
  }

  onApply($config) {
    const {
      state: { selectedStateId = '', selectedStateName = '', publicSettings = {} }
    } = this

    if (!this.isValidConfig($config)) {
      slvyToast.warning({
        message: MESSAGES.please_select_at_least_one_values,
        options: { containerId: 'dp' }
      })
      return
    }

    this.toggleLoader(true)

    // FIND SELECTED STATE
    const selectedPluginState = this.getStateByStateId(selectedStateId)

    // GET LATEST PUBLIC CHOICE
    if (selectedStateId === publicSettings.id) {
      selectedPluginState.public = publicSettings.isPublic
    }

    selectedPluginState.config.stateSettings.name = selectedStateName

    // CREATE SELECTED CHANGES
    const latestParams = {
      shouldDraft: true,
      updatedWithState: selectedPluginState
    }

    // don't create draft state
    const final = {
      ...selectedPluginState,
      config: {
        ...selectedPluginState.config,
        state: $config
      }
    }
    if (_.isEqual(final, selectedPluginState)) {
      latestParams.shouldDraft = false
    }

    this.props.createDraftPivotBuilderState($config, latestParams)

    this.props.onViewChangedCallback({ View: this.props.currentView })
  }

  onUpdate($config) {
    const {
      state: { selectedStateId = '', selectedStateName = '', publicSettings = {} }
    } = this

    if (!this.isValidConfig($config)) {
      slvyToast.warning({
        message: MESSAGES.please_select_at_least_one_values,
        options: { containerId: 'dp' }
      })
      return
    }

    this.toggleLoader(true)

    // FIND SELECTED STATE
    const selectedPluginState = this.getStateByStateId(selectedStateId)

    // GET LATEST PUBLIC CHOICE
    if (selectedStateId === publicSettings.id) {
      selectedPluginState.public = publicSettings.isPublic
    }

    selectedPluginState.config.stateSettings.name = selectedStateName

    // CREATE SELECTED CHANGES
    const latestParams = {
      shouldReload: false,
      updatedWithState: selectedPluginState,
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          this.toggleLoader(false)
        }

        this.props._savePivotBuilderViewSettings({ draftState: {} }, () => {
          this.setState({ allPluginStates: this.getAllPbStates() })
        })
      }
    }

    this.props.updatePivotBuilderState($config, latestParams)
  }

  onNewStateNameChanged(event) {
    event.persist()
    this.setState({
      newStateName: event.target.value
    })
  }

  onCreate(event) {
    event.persist()
    event.preventDefault()
    let {
      state: {
        pivotConfig = {},
        allPluginStates = [],
        newStateName = '',
        selectedStateName = '',
        selectedStateId = ''
      }
    } = this

    newStateName = newStateName.trim()

    const pluginStates = _.cloneDeep(allPluginStates)

    if (!newStateName.length) {
      slvyToast.info({ message: MESSAGES.name_is_required, options: { containerId: 'dp' } })
      return
    }
    if (pluginStates.find((item) => item.config.stateSettings.name === newStateName)) {
      slvyToast.info({
        message: MESSAGES.this_name_was_taken_please_try_with_a_different_one,
        options: { containerId: 'dp' }
      })
      return
    }

    this.toggleLoader(true)

    // NEW STATE DEFAULT SETTINGS
    const stateSettings = {
      name: newStateName,
      isDefault: false
    }

    // CLONE SELECTED STATE TO CREATE NEW ONE
    const newPluginState = this.getStateByStateId(selectedStateId)

    newPluginState.public = false
    newPluginState.config.state = _.cloneDeep(pivotConfig)
    newPluginState.config.stateSettings = { ...stateSettings }

    // APPLY CHANGES TO THE LATEST
    this.props.executePivotMainConfiguration(() => {
      this.props.createPivotBuilderState(newPluginState.config.state, {
        callback: ({ newStateId }) => {
          const syncState = () => {
            this.setState({
              previousStateId: selectedStateId,
              previousStateName: selectedStateName,
              selectedStateName: newStateName,
              selectedStateId: newStateId,
              newStateName: '',
              buttonMenu: false
            })
            this.toggleLoader(false)
          }

          this.props._savePivotBuilderViewSettings({ draftState: {} }, () => {
            this.setState({ allPluginStates: this.getAllPbStates() }, () => syncState())
          })
        },
        stateSettings
      })
    }, newPluginState)
  }

  markAsPublic(isPublic = true, event) {
    event.persist()
    event.preventDefault()

    const {
      state: { selectedStateId = '', selectedStateName = '' }
    } = this

    const selectedPluginState = this.getStateByStateId(selectedStateId)
    selectedPluginState.public = isPublic

    this.setState({
      publicSettings: {
        name: selectedStateName,
        id: selectedStateId,
        isPublic
      }
    })

    const text = isPublic ? 'public' : 'unpublic'
    slvyToast.info({
      message: `${selectedStateName} marked as ${text}!`,
      options: { containerId: 'dp' }
    })
  }

  onDelete(event) {
    const confirmReturn = global.confirm(
      MESSAGES.are_you_sure_you_want_to_delete_the_pivot_builder_state
    )

    if (confirmReturn) {
      event.persist()
      event.preventDefault()
      const {
        state: { selectedStateId = '' }
      } = this

      const { id = '', config: { stateSettings: { name = '' } = {} } = {} } =
        this.getStateByStateId(selectedStateId)

      this.toggleLoader(true)

      this.props.deletePivotBuilderState(id, ({ isDeleted }) => {
        const deleteCallback = () => {
          if (isDeleted) {
            this.goDefault()
            slvyToast.info({ message: `${name} has been deleted!`, options: { containerId: 'dp' } })
          }
          this.toggleLoader(false)
        }

        this.props._savePivotBuilderViewSettings({ draftState: {} }, () => {
          this.setState({ allPluginStates: this.getAllPbStates() }, () => deleteCallback())
        })
      })
    }
  }

  onClose() {
    this.props._savePivotBuilderViewSettings({ isVisible: false })
    this.props.onViewChangedCallback({ View: this.props.currentView })
    this.toggleLoader(false)
  }

  hasPermission() {
    const { userInfo: { userName, customerName } = {} } = this.props

    const { user = '' } = this.getStateByStateId(this.state.selectedStateId) || { user: null }
    return user === userName || customerName === 'SOLVOYO' // It has to change, user-right has to organize
  }

  render() {
    const {
      props: {
        pivotBuilderCallBack,
        pivotData,
        pivotBuilderSettings = {},
        pivotBuilderSettings: { leftAxisLimit } = {},
        isMarkAsPublicAllowed = false,
        userInfo: { userName }
      },
      state: {
        selectedStateId,
        selectedStateName,
        newStateName,
        allPluginStates,
        pivotConfig,
        publicSettings: { id: lastPublicStateId, isPublic = false } = {},
        buttonMenu
      }
    } = this

    const msg = MESSAGES.you_cannot_change_this_pivot_builder_setting

    const pivotBuilderPluginStates = _.cloneDeep(allPluginStates)

    const hasPivotConfig = !this.isConfigEmpty(pivotConfig)
    const isVisibleApp = !_.isEmpty(pivotData) && hasPivotConfig
    const isDefaultSelected = selectedStateName === 'Default'
    const { public: isCurrentPublic, user } = this.getStateByStateId(selectedStateId) || {
      public: false
    }

    const isCrateorOfState = user === userName

    const checkPublic = lastPublicStateId === selectedStateId ? !isPublic : !isCurrentPublic

    const isUserRight = this.hasPermission()

    const noPermissionForAction = !isUserRight || isDefaultSelected

    return (
      <section className="slvy-ui-pivot-builder-ctn open">
        <div className="pivot-wrapper">
          {isVisibleApp ? (
            <App
              changeCurrentState={this.changeCurrentState.bind(this)}
              getCurrentState={this.getCurrentState.bind(this)}
              leftAxisLimit={leftAxisLimit}
              pivotBuilderCallBack={pivotBuilderCallBack}
              pivotBuilderSettings={pivotBuilderSettings}
              pivotConfiguration={pivotConfig}
            />
          ) : null}
          <div className="btn-group-row">
            <div ref={this.listButtonRef} className="-list-button">
              <button className="-trigger" type="text">
                <select value={selectedStateId} onChange={this.onStateChanged.bind(this)}>
                  {pivotBuilderPluginStates.map((pbState, pbIndex) => {
                    return (
                      <option key={pbIndex} value={pbState.config.stateId}>
                        {pbState.config.stateSettings.name}
                      </option>
                    )
                  })}
                </select>
              </button>

              {!isDefaultSelected && isUserRight && isCrateorOfState ? (
                <button className="-del" type="button" onClick={this.onDelete.bind(this)}>
                  <i className="slvy-ui-icon-trash-can" />
                </button>
              ) : null}

              {!isDefaultSelected && isUserRight && isMarkAsPublicAllowed ? (
                <button
                  className="-pub"
                  type="button"
                  onClick={this.markAsPublic.bind(this, checkPublic)}
                >
                  <i className={`slvy-ui-icon-${checkPublic ? 'unview' : 'view'}-regular`} />
                </button>
              ) : null}

              {!isDefaultSelected && isUserRight && isMarkAsPublicAllowed && selectedStateId ? (
                <button
                  className="-cpy"
                  type="button"
                  onClick={() => copyClipBoard(selectedStateId)}
                >
                  <i className="slvy-ui-icon-copy" />
                </button>
              ) : null}

              <OverlayTrigger tooltip={MESSAGES.save_as}>
                <button
                  className="-add"
                  type="text"
                  onClick={() => this.setState({ buttonMenu: !buttonMenu })}
                >
                  <i className="slvy-ui-icon-plus-lt" />
                </button>
              </OverlayTrigger>

              {buttonMenu ? (
                <div className="-list-box">
                  <input
                    placeholder={MESSAGES.enter_a_name}
                    type="text"
                    value={newStateName}
                    onChange={this.onNewStateNameChanged.bind(this)}
                  />
                  <button type="button" onClick={this.onCreate.bind(this)}>
                    {MESSAGES.save}
                  </button>
                </div>
              ) : null}
            </div>
            <button className="-close" type="button" onClick={this.onClose.bind(this)}>
              <i className="slvy-ui-icon-times-lt" />
            </button>
            {hasPivotConfig ? (
              <OverlayTrigger shouldWrap={noPermissionForAction} tooltip={msg}>
                <button
                  className={cx('-apply', { '-disabled': noPermissionForAction })}
                  type="button"
                  onClick={() => !noPermissionForAction && this.onUpdate(pivotConfig)}
                >
                  {' '}
                  {MESSAGES.save}{' '}
                </button>
              </OverlayTrigger>
            ) : null}
            {hasPivotConfig ? (
              <button
                className="-apply"
                type="button"
                onClick={this.onApply.bind(this, pivotConfig)}
              >
                {' '}
                {MESSAGES.apply}{' '}
              </button>
            ) : null}
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    pivotBuilderViewSettings: state.pivotBuilderViewSettings.pivotBuilderViewSettings,
    scenarioTreeViewSettings: state.scenarioTreeViewSettings.scenarioTreeViewSettings
  }
}

const mapDispatchToProps = {
  saveScenarioTreeViewSettings,
  savePivotBuilderViewSettings,
  setActionLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(PivotBuilder)
