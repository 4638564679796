import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cloneDeep, filter, find, isArray, isEmpty, min } from 'lodash'
import numeral from 'numeral'
import jwtDecode from 'jwt-decode'
import './index.scss'
import { objectToArray } from './utils'
import optionViewColumns from './utils/optionViewColumns'
import optionFieldsList from './utils/optionFieldsList'
import similarProductsGridColumns from './utils/similarProductsGridColumns'
import AcList from './components/AcList'
import CreateAssortment from './components/CreateAssortment'
import Header from './components/Header'
import OldView from './components/OldView'
import OptionView from './components/OptionView'
import SaveAsAssortment from './components/SaveAs'
import ScenarioTree from './components/ScenarioTree'
import TestProducts from './components/TestProducts'
import Timeline from './components/Timeline'
import AssortmentLoading from './components/Loading'
import {
  onCreateScenarioToggle,
  showSaveAsAssortment,
  showTestProducts,
  setScenarioView,
  setLoaderReducer,
  setViewType,
  userExit
} from './store/slices/appSlice'
import { collapseAll } from './store/slices/collapse'
import { getMinPresentationData } from './store/slices/minPresentationData'
import { setScroll } from './store/slices/options'
import { resetOptionsSettings } from './store/slices/optionSettings'
import { getPeriodAggregations } from './store/slices/periodAggregations'
import {
  setFormattingSettings,
  setOptionFieldsEditable,
  setOptionPriceEditable
} from './store/slices/plugin'
import { setCurrentPeriod } from './store/slices/timelineSettings'
import { getTree } from './store/slices/tree'
import { resetScenarioSelection } from './store/slices/treeSelection'
import {
  useRegisterEventSimilarProducts,
  useRegisterEventAssortmentApproved,
  useRegisterEventHeaderButtons
} from './hooks'

const selectFromState = (state) => ({
  currentPeriod: state.timelineSettings.currentPeriod,
  isShowCreateAssortment: state.app.isShowCreateAssortment,
  isShowSaveAsAssortment: state.app.isShowSaveAsAssortment,
  isShowTestProducts: state.app.isShowTestProducts,
  loader: state.app.loader,
  minDataError: state.minPresentationData.error,
  minDataLoading: state.minPresentationData.isLoading,
  minPresentationData: state.minPresentationData.minPresentationData,
  periodAggError: state.periodAggregations.error,
  periodAggLoading: state.periodAggregations.isLoading,
  periodAggregations: state.periodAggregations.periodAggregations,
  rootOptions: state.options.rootOptions,
  scenarioView: state.app.scenarioView,
  scroll: state.options.scroll,
  selectedScenarioTree: state.treeSelection.tree,
  tree: state.tree.tree,
  treeLoading: state.tree.treeLoading,
  treeError: state.tree.error,
  viewType: state.app.viewType
})

function App(props) {
  const dispatch = useDispatch()
  const {
    currentPeriod,
    isShowCreateAssortment,
    isShowSaveAsAssortment,
    isShowTestProducts,
    loader,
    minDataError,
    minDataLoading,
    minPresentationData,
    periodAggError,
    periodAggLoading,
    periodAggregations,
    rootOptions,
    scenarioView,
    scroll,
    selectedScenarioTree,
    tree,
    treeLoading,
    treeError,
    viewType
  } = useSelector(selectFromState)

  const [noConnection, setNoConnection] = useState(false)
  const [testProductsMode, setTestProductsMode] = useState('Create')

  const {
    fetchMinPresentationData = () => {},
    id: pluginId = '',
    isPreviewMode = false,
    params: { environment },
    registerEvent = () => {},
    settings: {
      config,
      config: {
        formatting,
        formatting: { currencySymbol = '$' } = {},
        headerButtons = [],
        optionViewGridSettings: {
          optionFieldsEditable = true,
          optionViewColumns: _optionViewColumns = []
        } = {},
        settings: {
          backgroundcolor: backgroundColor = '',
          fontcolor: color = '',
          optionPriceEditable = false,
          viewType: viewTypeSettings = 'New View'
        } = {},
        similarProductsGridColumns: _similarProductsGridColumns = [],
        similarProductsHeaderButtons = [],
        timelineSettings = []
      } = {},
      query: { hasQuery = false } = {}
    } = {},
    size = {},
    token
  } = props

  const {
    currentScenario: { Id: selectedScenarioTreeId = null } = {},
    scenarioName: selectedScenarioTreeName = '',
    isFolderSelected = true
  } = selectedScenarioTree

  const { isShown: isShownLoader = true, messages: loaderMsg = '' } = loader
  const { Options = [] } = rootOptions
  const treeIsEmpty = isEmpty(tree)
  const loadIsEmpty = isEmpty(rootOptions)
  const periodIsEmpty = isEmpty(periodAggregations)
  const isCreateScenarioEnabled = !!(
    selectedScenarioTreeId &&
    selectedScenarioTreeName &&
    isFolderSelected
  )

  const { culture = 'en-US' } = jwtDecode(token)

  const resultSimilarProductsGridColumns =
    _similarProductsGridColumns && _similarProductsGridColumns.length
      ? _similarProductsGridColumns
      : similarProductsGridColumns

  let resultOptionViewColumns =
    _optionViewColumns && _optionViewColumns.length ? _optionViewColumns : optionViewColumns

  resultOptionViewColumns = filter(resultOptionViewColumns, (column) => {
    return find(optionFieldsList, (item) => item.name === column.name)
  })

  const { similarProductsEventRef } = useRegisterEventSimilarProducts(
    registerEvent,
    similarProductsHeaderButtons
  )
  const { assortmentApprovedEventRef } = useRegisterEventAssortmentApproved(registerEvent)
  const { headerButtonsEventRef } = useRegisterEventHeaderButtons(registerEvent, headerButtons)

  useEffect(() => {}, [viewType])

  useEffect(() => {
    if (minDataLoading) {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Loading' }))
    } else if (!minDataLoading && !treeLoading && !periodAggLoading) {
      dispatch(setLoaderReducer({ isShown: false, messages: '' }))
    }
  }, [minDataLoading, treeLoading, periodAggLoading])

  useEffect(() => {
    if (viewTypeSettings === 'Old View') {
      dispatch(setViewType(viewTypeSettings))
    } else {
      dispatch(setViewType('optionView'))
    }
    if (!hasQuery) {
      setNoConnection(true)
      dispatch(userExit())
      return
    }

    dispatch(setFormattingSettings(formatting))
    dispatch(setOptionFieldsEditable(optionFieldsEditable))
    dispatch(setOptionPriceEditable(optionPriceEditable))

    window.DEFAULT_NUMERAL_CURRENCY_SYMBOL = numeral.localeData().currency.symbol
    numeral.localeData().currency.symbol = currencySymbol

    const payload = {
      pluginId,
      body: {},
      environment,
      token
    }

    dispatch(getMinPresentationData({ ...payload, method: 'GetMinPresentationData' }))
    dispatch(getTree({ ...payload, method: 'GetTree' }))
    dispatch(
      getPeriodAggregations({
        ...payload,
        method: 'GetPeriodAggregations',
        requestMethod: 'post'
      })
    )
    return () => {
      numeral.localeData().currency.symbol = window.DEFAULT_NUMERAL_CURRENCY_SYMBOL
      dispatch(userExit())
    }
  }, [])

  const handleSynchronizeDataDefinition = (fields, value) => {
    const newValue = cloneDeep(value)

    if (
      !newValue.similarProductsGridColumns ||
      (isArray(newValue.similarProductsGridColumns) && !newValue.similarProductsGridColumns.length)
    ) {
      newValue.similarProductsGridColumns = similarProductsGridColumns
    }
    if (
      !newValue.optionViewGridSettings.optionViewColumns ||
      (isArray(newValue.optionViewGridSettings.optionViewColumns) &&
        !newValue.optionViewGridSettings.optionViewColumns.length)
    ) {
      newValue.optionViewGridSettings.optionViewColumns = optionViewColumns
    }

    return newValue
  }

  const timelineHandler = (currentPeriod, callback = () => {}) => {
    dispatch(setCurrentPeriod(currentPeriod))
  }

  const scrollHandler = (scroll) => {
    // TODO check if its not necessary
    dispatch(setScroll(scroll))
  }

  const viewHandler = (event) => {
    if (event) {
      if (viewType === 'optionView') {
        dispatch(setViewType('segmentView'))
      } else if (viewType === 'segmentView') {
        dispatch(setViewType('optionView'))
      }
    }
    dispatch(resetOptionsSettings())
  }

  const onSaveAsScenarioToggle = (isShowSaveAsAssortment = false) => {
    dispatch(showSaveAsAssortment(isShowSaveAsAssortment))
    dispatch(resetScenarioSelection({ dataKey: 'saveTree' }))
    dispatch(collapseAll({ dataKey: 'saveTree' }))
  }

  const onTestProductsToggle = (isShowTestProducts = false, testProductsMode = 'Create') => {
    dispatch(showTestProducts(isShowTestProducts))
    setTestProductsMode(testProductsMode)
  }

  const onSaveAssortment = () => {
    changeScenarioHandler(false)
    createScenarioToggle(false)
  }

  const getMinimumStartDate = () => {
    let minimumStartDate = null
    let segmentOptions = []
    if (!isEmpty(rootOptions) && !isEmpty(Options)) {
      Options.forEach(
        (option) =>
          (segmentOptions = [
            ...segmentOptions,
            ...objectToArray(option.SegmentOptions).filter((item) => item.Enabled)
          ])
      )
      const dates = segmentOptions.map((segmentOption) => new Date(segmentOption.StartDate))
      minimumStartDate = min([...dates])
    }

    return minimumStartDate
  }

  const onResetScenarioSelection = () => {
    dispatch(resetScenarioSelection({ dataKey: 'tree' }))
    dispatch(resetScenarioSelection({ dataKey: 'clusterTree' }))
    dispatch(collapseAll({ dataKey: 'tree' }))
    dispatch(collapseAll({ dataKey: 'clusterTree' }))
  }

  const createScenarioToggle = (isShowCreateAssortment = false) => {
    dispatch(onCreateScenarioToggle(isShowCreateAssortment))
  }

  const changeScenarioHandler = (isShown) => {
    if (isShown) {
      onResetScenarioSelection()
    }
    dispatch(setScenarioView(isShown))
    dispatch(resetOptionsSettings())
  }

  return (
    <div className="assortment-plugin-container" data-loading-text="Assortment Library">
      <AssortmentLoading isShown={isShownLoader && !noConnection} messages={loaderMsg} />
      {noConnection || isPreviewMode ? (
        <div className="assortment-wrapper h-100" />
      ) : (
        <div className="assortment-wrapper h-100" style={{ backgroundColor, color }}>
          {isShowTestProducts ? (
            <TestProducts
              culture={culture}
              mode={testProductsMode}
              pluginId={pluginId}
              onTestProductsToggle={onTestProductsToggle}
            />
          ) : null}
          {isShowSaveAsAssortment ? (
            <SaveAsAssortment
              pluginId={pluginId}
              onSaveAsAssortment={() => {
                onSaveAsScenarioToggle(false)
              }}
              onSaveAsScenarioToggle={onSaveAsScenarioToggle}
            />
          ) : null}
          {isShowCreateAssortment && (
            <CreateAssortment
              pluginId={pluginId}
              size={size}
              onCreateScenarioToggle={createScenarioToggle}
              onSaveAssortment={() => {
                onSaveAsScenarioToggle(false)
              }}
            />
          )}
          {!isShowCreateAssortment && !isShowSaveAsAssortment && !isShowTestProducts
            ? scenarioView
              ? !treeIsEmpty && (
                  <ScenarioTree
                    changeScenario={changeScenarioHandler}
                    data={tree}
                    environment={environment}
                    isCreateScenarioEnabled={isCreateScenarioEnabled}
                    pluginId={pluginId}
                    onCreateScenarioToggle={createScenarioToggle}
                    token={token}
                  />
                )
              : !loadIsEmpty &&
                !periodIsEmpty && (
                  <div className="h-100 d-flex flex-column">
                    <Header
                      assortmentApprovedEventRef={assortmentApprovedEventRef}
                      changeScenario={changeScenarioHandler}
                      currentPeriod={currentPeriod}
                      headerButtons={headerButtons}
                      headerButtonsEventRef={headerButtonsEventRef}
                      pluginId={pluginId}
                      viewHandler={(e) => {
                        viewHandler(e, viewType)
                      }}
                      onSaveAsScenarioToggle={onSaveAsScenarioToggle}
                      onTestProductsToggle={onTestProductsToggle}
                    />
                    {viewType === 'segmentView' ? (
                      <div className="h-100">
                        <Timeline
                          data={periodAggregations}
                          minimumStartDate={getMinimumStartDate}
                          size={size}
                          timelineHandler={timelineHandler}
                          timelineSettings={timelineSettings}
                        />
                        <AcList
                          currentPeriod={currentPeriod}
                          pluginId={pluginId}
                          timeline={periodAggregations}
                          viewHandler={(e) => {
                            viewHandler(e, viewType)
                          }}
                        />
                      </div>
                    ) : viewType === 'optionView' ? (
                      <OptionView
                        culture={culture}
                        currentPeriod={currentPeriod}
                        onTestProductsToggle={onTestProductsToggle}
                        optionPriceEditable={optionPriceEditable}
                        optionViewColumns={resultOptionViewColumns}
                        pluginId={pluginId}
                        scroll={scroll}
                        scrollHandler={scrollHandler}
                        similarProductsColumns={resultSimilarProductsGridColumns}
                        similarProductsEventRef={similarProductsEventRef}
                        similarProductsHeaderButtons={similarProductsHeaderButtons}
                        size={size}
                      />
                    ) : (
                      <OldView
                        culture={culture}
                        currentPeriod={currentPeriod}
                        onTestProductsToggle={onTestProductsToggle}
                        optionPriceEditable={optionPriceEditable}
                        optionViewColumns={resultOptionViewColumns}
                        pluginId={pluginId}
                        scroll={scroll}
                        scrollHandler={scrollHandler}
                        similarProductsColumns={resultSimilarProductsGridColumns}
                        similarProductsEventRef={similarProductsEventRef}
                        similarProductsHeaderButtons={similarProductsHeaderButtons}
                        size={size}
                      />
                    )}
                  </div>
                )
            : null}
        </div>
      )}
    </div>
  )
}

export default App
