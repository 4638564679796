import { pullAt } from 'lodash'
import { Options } from 'highcharts'
import {
  commonTooltipOptions,
  defaultBoxOptions,
  defaultBulletOptions,
  defaultPieOptions
} from './constants'
import { BoxOptions, BulletOptions, CommonOptions } from './HighchartCellRenderer.types'

const getBoxOptions = (props: BoxOptions) => {
  const { field, valuesArray, highchartOptions, theme } = props
  let pulledOutliers: number[] = []
  let pieValues = valuesArray

  if (valuesArray.length > 5) {
    pieValues = [0].concat(valuesArray)
    pulledOutliers = pullAt(pieValues, [1, pieValues.length - 1])
  }
  const {
    boxFillColor,
    boxLineColor,
    medianColor,
    outlierFillColor,
    outlierLineColor,
    whiskerColor,
    lineColor
  } = highchartOptions

  const boxOptions: Options = {
    ...defaultBoxOptions,
    chart: {
      backgroundColor: 'transparent',
      type: 'boxplot',
      inverted: true,
      height: theme === 'balham' ? 35 : 45,
      spacing: [0, 0, 0, 0]
    },
    plotOptions: {
      boxplot: {
        color: boxLineColor,
        fillColor: boxFillColor,
        medianColor,
        medianWidth: 1,
        // TODO: check each highchart version upgrade
        // missing Highcharts type of PlotBoxplotOptions: stemColor
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        stemColor: lineColor,
        whiskerColor,
        whiskerWidth: 1
      }
    },
    series: [
      {
        flex: 1,
        animation: false,
        data: [pieValues],
        name: field,
        tooltip: {
          pointFormat:
            'Maximum: {point.high}<br/>Minimum: {point.low}<br/>Median: {point.median}<br/>Lower quartile: {point.q1}<br/>Upper quartile: {point.q3}<br/>'
        },
        type: 'boxplot'
      },
      {
        animation: false,
        data: pulledOutliers.length
          ? [
              // x, y positions where 0 is the first category
              [0, pulledOutliers[0]],
              [0, pulledOutliers[1]]
            ]
          : [],
        marker: {
          fillColor: outlierFillColor,
          lineWidth: 1,
          lineColor: outlierLineColor,
          radius: 2
        },
        name: field,
        tooltip: {
          pointFormat: '<br/>Outlier:{point.y}<br/>'
        },
        type: 'scatter'
      }
    ],
    tooltip: {
      ...commonTooltipOptions
    },
    yAxis: {
      plotLines: [
        {
          value: 162, // TODO: check theme support
          color: 'red',
          width: 1,
          label: {
            text: '',
            align: 'center',
            style: {
              color: 'gray'
            }
          }
        }
      ],
      visible: false,
      title: {
        text: ''
      }
    }
  }
  return boxOptions
}

const getBulletOptions = (props: BulletOptions) => {
  const { field, highchartOptions, valuesArray, theme } = props
  const bulletValues = [0].concat(valuesArray)

  const { performanceColor, targetColor } = highchartOptions

  const bulletOptions: Options = {
    ...defaultBulletOptions,
    chart: {
      backgroundColor: 'transparent',
      type: 'bullet',
      inverted: true,
      height: theme === 'balham' ? 35 : 45,
      spacing: [0, 0, 0, 0]
    },
    series: [
      {
        flex: 1,
        animation: false,
        color: performanceColor,
        data: [bulletValues],
        name: field,
        targetOptions: {
          color: targetColor
        },
        type: 'bullet'
      }
    ]
  }

  return bulletOptions
}

const getPieOptions = (props: CommonOptions) => {
  const { field, valuesArray } = props

  const pieOptions: Options = {
    ...defaultPieOptions,
    series: [
      {
        animation: false,
        name: field,
        data: valuesArray,
        type: 'pie'
      }
    ]
  }
  return pieOptions
}

export { getBoxOptions, getBulletOptions, getPieOptions }
