Ext.define('MDM.src.ux.GridPicker', {
  extend: 'Ext.form.field.Picker',
  xtype: 'mygridpicker',
  columns: false,
  store: false,
  gridConfig: {
    minHeight: 100,
    maxHeight: 300,
    height: 200
  },
  config: {
    emptyTextGrid: 'No Records in selected DPI Family'
  },
  editable: false,
  displayField: false,
  valueField: false,
  delimiter: ', ',
  minChars: 2,
  selectedRecords: [],

  initComponent: function () {
    this.callParent(arguments)
  },
  createPicker: function () {
    var me = this
    var config = Ext.applyIf(
      {
        maxHeight: this.maxHeight,
        hideHeaders: true,
        scrollable: true,
        pickerField: me,
        floating: true,
        cls: 'x-menu',
        hidden: true,
        store: this.getStore(),
        columns: this.getColumns(),
        emptyText: this.getEmptyTextGrid(),
        tbar: this.getSearchToolbar(),
        defaultStatusText: 'Nothing Found',
        matchCls: 'x-livesearch-match',
        minWidth: 400,
        // detects html tag
        tagsRe: /<[^>]*>/gm,
        // DEL ASCII code
        tagsProtect: '\x0f',
        width: 270,
        listeners: {
          selectionchange: {
            fn: function (grid, selectedRecords) {
              this.setRecords(selectedRecords)
              this.fireEvent('select', this, selectedRecords)
              this.onTabOut()
            },
            scope: this
          }
        }
      },
      this.gridConfig
    )
    var grid = Ext.create('Ext.grid.Panel', config)
    me.textField = Ext.ComponentQuery.query('textfield[itemId=searchField]')[0]
    return grid
  },
  onTabOut: function () {
    this.inputEl.focus()
    this.collapse()
  },
  setRecords: function (records) {
    if (records && !Ext.isArray(records)) {
      records = [records]
    }
    this.selectedRecords = records
    var rawValue = []
    Ext.Array.each(
      records,
      function (record) {
        rawValue.push(record.get(this.displayField))
      },
      this
    )

    this.setRawValue(rawValue.join(this.delimiter))
  },

  getRecords: function () {
    return this.selectedRecords
  },
  selectAll: function () {
    this.getPicker().getSelectionModel().selectAll()
  },
  deselectAll: function () {
    this.getPicker().getSelectionModel().deselectAll(true)
    //this.setRawValue('');
  },
  getSearchToolbar: function () {
    var me = this
    me.tbar = {
      layout: {
        type: 'fit'
      },
      items: [
        {
          xtype: 'textfield',
          hideLabel: true,
          name: 'searchField',
          itemId: 'searchField',
          enableKeyEvents: true,
          emptyText: 'enter search term',
          listeners: {
            change: {
              fn: me.applySearchFilter,
              scope: this,
              buffer: 500
            }
          }
        }
      ]
    }
    return me.tbar
  },
  getBottomBar: function () {
    var me = this
    me.bbar = {
      defaults: {
        enableToggle: true,
        pressed: true,
        hidden: true
      },
      items: [
        {
          text: 'All',

          listeners: {
            click: 'onAllClick',

            scope: me
          }
        },
        {
          text: 'Matching',
          itemId: 'matchingBtn',
          disabled: true,
          listeners: {
            click: 'onMatchingClick',
            scope: me
          }
        },
        {
          text: 'None',
          listeners: {
            click: 'onNoneClick',
            scope: me
          }
        }
      ]
    }
    return me.bbar
  },
  onAllClick: function () {
    this.resetFilter()
    this.selectAll()
  },
  onMatchingClick: function () {
    //this.deselectAll();
    if (!this.store.isFiltered) {
      this.applySearchFilter()
    }
    this.selectAll()
  },
  onNoneClick: function () {
    if (this.store.isFiltered) {
      this.resetFilter()
    }
    this.deselectAll()
    this.setRawValue('')
  },
  resetFilter: function () {
    var me = this
    me.store.suspendEvents()
    me.store.clearFilter()
    me.textField.setValue('')
    me.store.resumeEvents()
  },
  getSubmitValue: function () {
    var values = []
    Ext.Array.each(
      this.selectedRecords,
      function (record) {
        values.push(record.get(this.valueField))
      },
      this
    )

    return values
  },
  enableMatching: function () {
    var bbar = this.getBottomBar()
    var matchingButton = Ext.ComponentQuery.query('gridpanel > toolbar > button#matchingBtn')[0]
    //  matchingButton.setDisabled(false);
  },
  disableMatching: function () {
    var matchingButton = Ext.ComponentQuery.query('gridpanel > toolbar > button#matchingBtn')[0]
    //   matchingButton.setDisabled(true);
  },
  getValue: function () {
    return this.getSubmitValue()
  },

  beforeReset: function () {
    this.deselectAll()
    this.callParent(arguments)
  },
  collapse: function () {
    var me = this
    this.disableMatching()
    me.callParent()
  },
  expand: function () {
    var me = this

    me.callParent()
    me.getPicker().setEmptyText(me.getEmptyTextGrid())
  },
  getStore: function () {
    if (!this.store) {
      this.store = Ext.create('Ext.data.Store', {})
    }
    return this.store
  },

  getColumns: function () {
    if (!this.columns) {
      this.columns = []
    }
    return this.columns
  },
  applySearchFilter: function () {
    var me = this,
      store = me.getStore()
    var searchField = me.textField
    var queryString = searchField.getRawValue().trim().toLowerCase()

    me.queryFilter = null
    // Querying by a string...
    if (queryString != '') {
      var filter = (me.queryFilter = new Ext.util.Filter({
        id: me.id + '-filter',
        anyMatch: true,
        caseSensitive: me.caseSensitive,
        root: 'data',
        property: me.displayField,
        value: me.enableRegEx ? new RegExp(queryString) : queryString,
        filterFn: function (rec) {
          return me.containsText(rec.get(me.displayField), queryString)
        }
      }))

      store.addFilter(filter, true)
      me.enableMatching()
    } else {
      me.resetFilter()
      me.disableMatching()
    }

    me.highlightSearchText()
  },
  containsText: function (text, queryStr) {
    queryStr = Ext.String.escapeRegex(queryStr)
    var queryStringArr = queryStr.split(',')
    var searchStringArray = []
    Ext.each(queryStringArr, function (searchValue) {
      searchValue = searchValue.trim()
      if (!Ext.isEmpty(searchValue)) {
        searchStringArray.push(searchValue)
      }
    })

    var searchString = ''
    var searchStringLen = searchStringArray.length
    if (searchStringLen === 1) {
      searchString = searchStringArray[0]
    } else if (searchStringLen > 1) {
      searchString = '^' + searchStringArray.join('$|^') + '$'
    }
    var pattern = new RegExp(searchString, 'gi')
    return pattern.test(text)
  },
  getSearchValue: function () {
    var me = this,
      value = me.textField.getValue()

    if (value === '') {
      return null
    }
    if (!me.regExpMode) {
      value = Ext.String.escapeRegex(value)
    } else {
      try {
        new RegExp(value)
      } catch (error) {
        console.log(error.message)
        return null
      }
      if (value === '^' || value === '$') {
        return null
      }
    }

    return value
  },
  highlightSearchText: function () {
    var me = this.getPicker(),
      count = 0,
      view = me.view,
      cellSelector = view.cellSelector,
      innerSelector = view.innerSelector,
      columns = me.visibleColumnManager.getColumns()
    var plugins = me.plugins
    var bufferedPlugin =
      plugins &&
      plugins.filter(function (p) {
        return p instanceof Ext.grid.plugin.BufferedRenderer
      })[0]
    view.refresh()

    me.searchValue = this.getSearchValue()
    me.matches = []
    me.currentIndex = null

    if (me.searchValue !== null) {
      me.searchRegExp = new RegExp(this.getSearchValue(), 'g' + (me.caseSensitive ? '' : 'i'))

      me.store.each(function (record, idx) {
        var node = view.getNode(record)
        if (node) {
          Ext.Array.forEach(columns, function (column) {
            if (column.isCheckerHd) return false
            var cell = Ext.fly(node).down(column.getCellInnerSelector(), true),
              matches,
              cellHTML,
              seen

            if (cell) {
              matches = cell.innerHTML.match(me.tagsRe)
              cellHTML = cell.innerHTML.replace(me.tagsRe, me.tagsProtect)

              // populate indexes array, set currentIndex, and replace wrap matched string in a span
              cellHTML = cellHTML.replace(
                me.searchRegExp,
                function (m) {
                  ++count
                  if (!seen) {
                    me.matches.push({
                      record: record,
                      column: column
                    })
                    seen = true
                  }
                  return '<span class="' + me.matchCls + '">' + m + '</span>'
                },
                me
              )
              // restore protected tags
              Ext.each(me.matches, function (match) {
                cellHTML = cellHTML.replace(me.tagsProtect, match)
              })
              // update cell html
              cell.innerHTML = cellHTML
            }
          })
        }
      }, me)

      // results found
      if (count) {
        me.currentIndex = 0
      }
    }

    // no results found
    if (me.currentIndex === null) {
      this.textField.focus()
    }
  },
  gotoCurrent: function () {
    var picker = this.getPicker()
    var pos = picker.matches[picker.currentIndex]
    picker.getNavigationModel().setPosition(pos.record, pos.column)
    picker.getSelectionModel().select(pos.record)
  }
})
