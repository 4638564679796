import { useState, ChangeEvent } from 'react'
import { Modal, Button, Alert, FormControl } from 'react-bootstrap'
import { ISlvyConfirmationModalProps } from './SlvyConfirmationModal.types'

const SlvyConfirmationModal = ({
  show,
  correctValue,
  title,
  children,
  buttonName,
  onChange,
  onSubmit
}: ISlvyConfirmationModalProps) => {
  const [value, setValue] = useState('')

  const hideModal = () => {
    onChange(false)
    setValue('')
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)

  const handleSubmit = () => {
    hideModal()
    onSubmit()
  }

  const isValid = correctValue === value

  return (
    <Modal data-testid="slvy-confirmation-modal" show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <Alert variant="warning">
          <strong>Unexpected bad things will happen if you don’t read this!</strong>
        </Alert>
        {children}
        <div className="order-3 mt-3">
          <FormControl
            data-testid="slvy-confirmation-modal-input"
            isValid={isValid}
            type="text"
            onChange={handleChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="slvy-confirmation-modal-button"
          disabled={!isValid}
          variant="danger"
          onClick={handleSubmit}
        >
          {buttonName}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SlvyConfirmationModal
