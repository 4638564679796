export default () => {
  return {
    title: 'Import',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: false,
            required: true
          },
          description: {
            type: 'string',
            title: 'Description'
          },
          backgroundcolor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          hidden: {
            type: 'boolean',
            title: 'Hidden',
            default: false,
            required: true
          }
        }
      },
      columns: {
        type: 'array',
        title: 'Columns',
        items: {
          type: 'object',
          headerTemplate: '{{ self.column }}',
          properties: {
            column: {
              type: 'string',
              title: 'Column'
            },
            isRequired: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Required'
            },
            isNullable: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Nullable'
            },
            type: {
              type: 'string',
              title: 'Type',
              default: 'string',
              enum: ['int', 'string', 'bool', 'datetime', 'decimal'],
              required: true
            },
            length: {
              type: 'integer',
              title: 'Length'
            }
          }
        }
      },
      storedProcedure: {
        type: 'object',
        title: 'Stored Procedure',
        properties: {
          importSPName: {
            type: 'string',
            title: 'Import Stored Procedure Name'
          },
          sample: {
            type: 'string',
            format: 'Code',
            title: 'Sample Stored Procedure',
            default: `SET ANSI_NULLS ON
GO
SET QUOTED_IDENTIFIER ON
GO

CREATE PROCEDURE [dbo].[Dashboard_Import_Trial1]
	@tableName NVARCHAR(MAX) = null,
	@isvalidation BIT = 1,
	@modifiedBy NVARCHAR(MAX)
AS
BEGIN

	-- DashboardImportTest

	DECLARE @sql NVARCHAR(MAX)

	IF (@isvalidation = 1)
	BEGIN
		
		SET @sql = 'UPDATE ' + @tableName + ' SET IsImportable= 0, ErrorCode = ''Merhaba Dünya'' where Id=''1'';';
		SET @sql =  @sql + ' UPDATE ' + @tableName + ' SET IsImportable= 1, ErrorCode = ''Merhaba Dünya'' where IsDeleted=''0'';';
	END
	ELSE
	BEGIN
		SET @sql = 'INSERT INTO DashboardImportTest (Id,Name, Value, Date, IsDeleted) SELECT Id,Name, Value, Date, IsDeleted FROM  ' + @tableName + ' WHERE IsImportable = ''true''';
	END

	EXEC(@sql);

END`,
            options: {
              mode: 'javascript',
              lineNumbers: true,
              readOnly: true,
              base64: false
            }
          }
        }
      },
      parameterDefinitions: {
        type: 'array',
        title: 'Parameter Definitions',
        items: {
          type: 'object',
          headerTemplate: '{{ self.parameterName }}',
          properties: {
            parameterName: {
              type: 'string',
              title: 'Sql Parameter'
            },
            value: {
              type: 'string',
              title: 'Parameter Value'
            }
          }
        }
      },
      importButton: {
        type: 'object',
        title: 'Plugin Button',
        properties: {
          showButton: {
            type: 'boolean',
            default: true,
            title: 'Show Button'
          },
          buttonText: {
            type: 'string',
            title: 'Button Text'
          },
          style: {
            type: 'string',
            title: 'Style',
            default: 'primary',
            enum: ['success', 'primary', 'info', 'warning', 'danger'],
            required: true
          },
          icon: {
            type: 'string',
            title: 'Icon',
            default: 'fa-check',
            format: 'IconSelector'
          },
          iconPosition: {
            type: 'string',
            title: 'Icon Position',
            default: 'Left',
            enum: ['Left', 'Right'],
            required: true
          },
          buttonPosition: {
            type: 'string',
            title: 'Button Position',
            default: 'Right',
            enum: ['Left', 'Center', 'Right'],
            required: true
          }
        }
      },
      finalImportButton: {
        type: 'object',
        title: 'Final Import Button',
        properties: {
          buttonText: {
            type: 'string',
            title: 'Button Text'
          }
        }
      }
    }
  }
}
