Ext.define('Spui.view.main.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.spui-view-main-mainviewmodel',
  stores: {
    I18n: {
      type: 'I18n',
      autoLoad: false
    },
    Settings: {
      type: 'Settings',
      autoLoad: false
    }
  },
  formulas: {
    isPrevDisabled: {
      bind: {
        isAdmin: '{isAdmin}'
      },
      get: function (data) {
        var isAdmin = data.isAdmin
        if (isAdmin) return { display: '' }
        return { display: 'none' }
      }
    }
  },
  data: {
    isAdmin: false,
    searchDisabled:false
    /* This object holds the arbitrary data that populates the ViewModel and is then available for binding. */
  }
})
