import { useRef, useLayoutEffect } from 'react'
import { Alert, Col } from 'react-bootstrap'
import cx from 'classnames'
import { SlvyProgress, SlvyFormInput, SlvySelect } from '@/components'
import { maxMenuHeight, menuPlacement, selectPlaceholder } from './constants'
import { ListProps, SelectItem } from './List.types'
import styles from './index.module.scss'

const List = ({
  scrollTop,
  items,
  selectedItem,
  filterValue,
  withSelect,
  selectItems = {},
  onClickItem,
  onChange,
  onChangeConnection,
  onScroll
}: ListProps) => {
  const listRef = useRef<HTMLUListElement>(null)

  useLayoutEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = scrollTop
    }
  }, [])

  const noConnections = items[0]?.name === 'No connections'
  const isLoading = items.length === 0
  const connections = items.filter(({ name }) =>
    name.toLowerCase().includes(filterValue.toLowerCase())
  )

  if (noConnections) {
    return (
      <Alert className="d-flex align-items-center gap-2 mt-2 fs-sm" variant="info">
        <i aria-hidden="true" className="fas fa-exclamation-circle fs-6" />
        No connections to map
      </Alert>
    )
  }

  return (
    <div data-testid="template-list">
      <div className="py-3 border-bottom">
        <SlvyFormInput value={filterValue} onChange={onChange} />
      </div>
      <ul ref={listRef} className={cx('overflow-auto', styles.connectionList)} onScroll={onScroll}>
        <SlvyProgress isLoading={isLoading}>
          {connections.map((connection, index) => {
            const { options = {}, value } = selectItems[index] ?? {}
            const selectOptions = Object.values(options).map((option) => ({
              label: option.name,
              value: option.id
            }))

            const isSelected =
              !withSelect &&
              selectedItem.name === connection.name &&
              selectedItem.id === connection.id

            return (
              <li
                key={`${connection.name}-${index}`}
                className={cx('list-unstyled', 'ps-2', styles.connection, {
                  'bg-secondary bg-opacity-25': isSelected
                })}
                role="presentation"
                onClick={() => onClickItem(connection)}
              >
                <Col className="d-flex align-items-center h-100" sm={12}>
                  <Col sm={6}>{connection.name}</Col>
                  {withSelect ? (
                    <Col sm={4}>
                      <SlvySelect
                        maxMenuHeight={maxMenuHeight}
                        menuPlacement={menuPlacement}
                        options={selectOptions}
                        placeholder={selectPlaceholder}
                        value={value}
                        onChange={(newConnection: SelectItem['value']) =>
                          onChangeConnection(newConnection, index)
                        }
                      />
                    </Col>
                  ) : null}
                </Col>
              </li>
            )
          })}
        </SlvyProgress>
      </ul>
    </div>
  )
}

export default List
