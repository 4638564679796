import { useRef } from 'react'
import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import { CellProps } from './Cell.types'

export default function Cell({
  children,
  className,
  isSelected,
  textWrap,
  isEditable,
  isPinned,
  isProgressCell,
  style,
  onClick,
  onContextMenu
}: CellProps) {
  const styles = useTheme()
  const ref = useRef(null)

  return (
    <div
      ref={ref}
      className={cx(
        styles.cell,
        { [styles.isSelected]: true },
        { [styles.isPinned]: isPinned },
        { [styles.isEditable]: isEditable },
        className
      )}
      style={style(ref)}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      {isProgressCell || isEditable ? (
        children
      ) : (
        <span className={cx({ [styles?.cellText]: textWrap })}>{children}</span>
      )}
    </div>
  )
}
