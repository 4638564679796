Ext.define('tuiv2.treeview.DemandManagerTree', {
  extend: 'Ext.tree.Panel',
  xtype: 'demandManagerTree',
  displayField: 'Text',
  reference: 'demandManagerTree',
  columnLines: true,
  rowLines: true,
  checkPropagation: 'both',
  config: {},
  viewConfig: {
    enableTextSelection: true
  },
  columns: []
})
