import cx from 'classnames'
import { isEmpty } from 'lodash'
import Table from '../../core'
import { useTheme } from '../../contexts/ThemeContext'
import ColumnFilter from '../ColumnFilter'
import { FilterRowProps } from './FilterRow.types'

export default function FilterRow({
  row,
  getTableBodyRowProps,
  getTableBodyCellProps
}: FilterRowProps) {
  const styles = useTheme()

  return !isEmpty(row) ? (
    <Table.Row
      {...getTableBodyRowProps({ ...row, isFilterRow: true })}
      className={cx(getTableBodyRowProps(row).className, styles.filterRow)}
    >
      {row.getVisibleCells().map((cell, index) => {
        return (
          <Table.Cell {...getTableBodyCellProps(cell, index)}>
            {cell.column.columnDef.meta?.filterable ? <ColumnFilter cell={cell} /> : null}
          </Table.Cell>
        )
      })}
    </Table.Row>
  ) : null
}
