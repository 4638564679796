import { handleCustomAggregation } from '../custom-agg'
import { IHandleCustomAggregation } from '../custom-agg.types'

const getDefaultParams = (): IHandleCustomAggregation => ({
  customAggregation: window.btoa(`
    let sum = 0
    for (let i = 0; i < rowCount; i++) {
      sum += table(i, 'value')
    }
    return sum
  `),
  data: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }]
})

describe('AgGrid:Helpers:CustomAgg:handleCustomAggregation', () => {
  let params = getDefaultParams()

  beforeEach(() => {
    params = getDefaultParams()
  })

  it('should return a function that performs custom aggregation correctly', () => {
    const aggregationFn = handleCustomAggregation(params)

    expect(typeof aggregationFn).toBe('function')
    if (typeof aggregationFn !== 'function') {
      return
    }

    const result = aggregationFn()
    expect(result).toBe(15)
  })

  it('should return false if custom aggregation function throws an error', () => {
    const customAggregation = window.btoa("throw new Error('Test error')")
    params.customAggregation = customAggregation

    const aggregationFn = handleCustomAggregation(params)
    expect(aggregationFn).toBeFalsy()
  })

  it('should handle empty data', () => {
    params.data = []
    const aggregationFn = handleCustomAggregation(params)

    expect(typeof aggregationFn).toBe('function')
    if (typeof aggregationFn !== 'function') {
      return
    }

    expect(aggregationFn()).toBe(0)
  })
})
