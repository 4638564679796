import { memo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ISearchIconTooltipProps } from './SearchIconTooltip.types'

const tooltip = (msg: string, variable: string) => <Tooltip id={msg + variable}>{msg}</Tooltip>

const SearchIconTooltip = ({ icon, variable, onClickHandler }: ISearchIconTooltipProps) => {
  const tooltipText = icon === 'plus' ? 'Add to search as a filter.' : 'Remove from search filter.'

  return (
    <span data-testid="search-icon-tooltip">
      <OverlayTrigger overlay={tooltip(tooltipText, variable)} placement="top">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="variable-item-dark rounded-start text-white p-2 bg-solvoyo-widget-action fs-sm itemCount cp h-100"
          data-testid={`${variable}-${icon}-icon`}
          onClick={() => onClickHandler(variable)}
        >
          <i aria-hidden="true" className={`fa fa-search-${icon}`} />
        </div>
      </OverlayTrigger>
    </span>
  )
}

export default memo(SearchIconTooltip)
