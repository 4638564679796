Ext.define('tuiv2.mapView.MainMap', {
  extend: 'tuiv2.leaflet.map',
  xtype: 'mainmap',
  reference: 'mainMap',
  config: {
    mapData: [],
    oldCheckeds: [],
    smoothFactor: null,
    weight: null,
    opacity: null,
    zoomOnSelect: null,
    loadStopMarkerId: null,
    record: null,
    hasLatLong: false,
    maxDemandQuantity: null,
    planInfo: null,
    freeDrawMode: null,
    drawRoute: null,
    demoEnabled: null
  },
  loadStopDetails: {},
  demandMarkerDetails: {},
  setLoadStopMarkerId: function (loadStopMarkerId) {
    var me = this

    var map = me.getMap()

    var details = me.loadStopDetails[loadStopMarkerId]

    if (!details) return

    if (this.loadStopMarker) map.removeLayer(this.loadStopMarker)

    this.loadStopMarker = L.geoJSON(
      turf.point([details.latitude, details.longitude], details.feature.properties),
      {
        pointToLayer: function (feature, latlng) {
          feature.properties.text = feature.properties.data.StopSequence
          return new L.LabeledCircleMarker(latlng, feature, {
            interactive: true
          })
        },
        style: function (feature) {
          var color = tuiv2.Colors.getColorByName(feature.properties.data.ID)
          return {
            color: color,
            fill: true,
            fillOpacity: 1,
            radius: 20,
            className: 'ID' + feature.properties.data.ID
          }
        }
      }
    ).addTo(map)
    this.loadStopMarker.addEventListener(
      'contextmenu',
      Ext.bind(function (e) {
        me.fireEvent('markercontextmenu', me, e)
      }, me)
    )

    this.loadStopMarker.addEventListener(
      'click',
      Ext.bind(function (e) {
        me.fireEvent('layerclick', me, e)
      }, me)
    )
  },
  setMapData: function (data) {
    if ((!data || data == 1) && viewMode === 0) {
      this.getEl().unmask()
      return
    }

    var oldCheckeds = this.getOldCheckeds()
    var checkeds = data.map(function (item) {
      return item.ID
    })
    var me = this

    var map = me.getMap()

    var smoothFactor = me.getSmoothFactor()

    var weight = me.getWeight()

    var maxDemandQuantity = me.getMaxDemandQuantity()

    var view = this.up('tuiv2mainView')
    var viewModel = view.getViewModel()
    var viewMode = viewModel.get('viewMode')

    var planInfo = me.getPlanInfo()
    me.fireEvent('managePopUp', data)

    if (this.loadStopMarker) map.removeLayer(this.loadStopMarker)
    if (this.loadStopRouteGeoJson) map.removeLayer(this.loadStopRouteGeoJson)
    if (this.loadStopMarkerGeoJson) map.removeLayer(this.loadStopMarkerGeoJson)
    if (this.demandMarkerGeoJson) map.removeLayer(this.demandMarkerGeoJson)
    if (this.truckMarkers) map.removeLayer(this.truckMarkers)

    if (viewMode === 0 && data.length == 0) {
      this.getEl().unmask()
      return
    }

    this.loadStopDetails = {}
    this.demandMarkerDetails = {}

    var loadStopMarkers = []

    var loadStopRoutes = []

    var demandLabels = []

    var demandMarkers = []

    var truckMarkers = []

    data.forEach((item) => {
      if (item.Type === 0) {
        item.MapObjects.forEach((mapObject) => {
          var properties = {
            data: mapObject.Properties
          }

          loadStopMarkers.push(turf.point([mapObject.Longitude, mapObject.Latitude], properties))

          var geometries = tuiv2.mapview.GeometryHelper.getCoordinates(
            mapObject.NextGeometry == null ? '' : mapObject.NextGeometry
          )
          loadStopRoutes.push(turf.lineString(geometries, properties))

          if (me.getDemoEnabled()) {
            var truck = turf.point([item.Longitude_Executed, item.Latitude_Executed], {
              IsTruck: true,
              color: '#000',
              text: 'Vehicle',
              labelPosition: [item.Longitude_Executed, item.Latitude_Executed],
              markerOptions: {
                color: '#fff',
                fillOpacity: 1,
                radius: 30,
                fillColor: '#000',
                weight: 1,
                text: 'Vehicle',
                labelPosition: [item.Longitude_Executed, item.Latitude_Executed],
                textStyle: {
                  color: '#fff',
                  fontSize: 20,
                  fill: '#fff',
                  fontWeight: 'bold',
                  fontFamily: 'Helvetica, Arial, sans-serif'
                }
              }
            })
            truckMarkers.push(truck)
          }
        })
      } else {
        item.MapObjects.forEach((mapObject) => {
          var properties = {
            data: mapObject.Properties
          }

          demandMarkers.push(turf.point([mapObject.Longitude, mapObject.Latitude], properties))
        })
      }
    })

    var onEachFeatureLoad = function (feature, layer) {
      layer.feature = feature
      layer.on({
        mouseover: me.highlightFeature,
        mouseout: me.resetHighlight
      })
    }

    this.loadStopRouteGeoJson = L.geoJSON(loadStopRoutes, {
      style: function (feature) {
        var color = tuiv2.Colors.getColorByName(feature.properties.data.ID)

        return {
          color: color,
          weight: weight,
          smoothFactor: smoothFactor,
          className: 'ID' + feature.properties.data.ID
        }
      },
      onEachFeature: onEachFeatureLoad
    }).addTo(map)

    this.truckMarkers = L.geoJSON(truckMarkers, {
      pointToLayer: function (feature, latlng) {
        var truckMarker = L.AwesomeMarkers.icon({
          icon: 'truck',
          markerColor: 'red',
          prefix: 'fa'
        })

        return new L.marker(latlng, { icon: truckMarker })
      }
    }).addTo(map)

    this.loadStopMarkerGeoJson = L.geoJSON(loadStopMarkers, {
      pointToLayer: function (feature, latlng) {
        feature.properties.text = feature.properties.data.StopSequence
        return new L.LabeledCircleMarker(latlng, feature, { interactive: true })
      },
      style: function (feature) {
        var color = tuiv2.Colors.getColorByName(feature.properties.data.ID)

        me.loadStopDetails[
          feature.properties.data.ID + '-' + feature.properties.data.StopSequence
        ] = {
          latitude: feature.geometry.coordinates[0],
          longitude: feature.geometry.coordinates[1],
          color: color,
          feature: feature
        }

        return {
          color: color,
          fill: true,
          fillOpacity: 1,
          className: 'ID' + feature.properties.data.ID
        }
      },
      onEachFeature: onEachFeatureLoad
    }).addTo(map)

    this.loadStopMarkerGeoJson.addEventListener(
      'contextmenu',
      Ext.bind(function (e) {
        me.fireEvent('markercontextmenu', me, e)
      }, me)
    )

    this.demandMarkerGeoJson = L.geoJSON(demandMarkers, {
      pointToLayer: function (feature, latlng) {
        return new L.circleMarker(latlng, feature)
      },
      style: function (feature) {
        me.demandMarkerDetails[feature.properties.data.Key] = {
          latitude: feature.geometry.coordinates[0],
          longitude: feature.geometry.coordinates[1]
        }

        return {
          radius: me.getRadius(feature.properties.data.Quantity, maxDemandQuantity),
          color: 'red',
          fill: true,
          weight: 1,
          opacity: 0.1,
          fillColor: '#ff1c2a',
          fillOpacity: 0.1
        }
      }
    }).addTo(map)

    var exist = checkeds.every(function (item) {
      return oldCheckeds.indexOf(item) > -1
    })
    if (
      (!exist && oldCheckeds.length != checkeds.length) ||
      oldCheckeds.length != checkeds.length
    ) {
      me.setZoom()
    }

    // bind click
    this.loadStopRouteGeoJson.addEventListener(
      'click',
      Ext.bind(function (e) {
        me.fireEvent('layerclick', me, e)
      }, me)
    )

    this.loadStopMarkerGeoJson.addEventListener(
      'click',
      Ext.bind(function (e) {
        me.fireEvent('layerclick', me, e)
      }, me)
    )

    this.demandMarkerGeoJson.addEventListener(
      'click',
      Ext.bind(function (e) {
        me.fireEvent('demandlayerclick', me, e)
      }, me)
    )
    me.createDiffButton()
    this.setOldCheckeds(checkeds)
    if (viewMode === 0) {
      this.getEl().unmask()
    }
  },

  setZoom: function () {
    var me = this

    var map = me.getMap()

    var bounds = me.getBounds()

    if (bounds.isValid()) map.fitBounds(bounds)
  },

  getBounds: function () {
    var layers = [
      this.loadStopRouteGeoJson,
      this.loadStopMarkerGeoJson,
      // this.demandLabelsGeoJson,
      this.demandMarkerGeoJson
      //, this.demandHeatMapLayer
    ]

    var bounds = L.latLngBounds([])

    layers.forEach((item) => {
      if (!item || !item.getBounds) return
      var layerBounds = item.getBounds()
      bounds.extend(layerBounds)
    })

    if (this.freeDraw) {
      var items = this.freeDraw.all()

      items.forEach((item) => {
        var itemBounds = item.getBounds()
        bounds.extend(itemBounds)
      })
    }

    return bounds
  },

  getRadius: function (quantity, maxDemandQuantity) {
    return (quantity * 35) / maxDemandQuantity + 5
  },

  highlightFeature: function (e) {
    var layer = e.target

    var className = 'ID' + layer.feature.properties.data.ID

    d3.selectAll('path')
      .filter(function (d, i) {
        var classes = d3.select(this).attr('class')
        return !classes || (classes && classes.indexOf(className) == -1)
      })
      .transition()
      .duration(1000)
      .style('opacity', 0)
    d3.selectAll('text')
      .filter(function (d, i) {
        var classes = d3.select(this).attr('class')
        return !classes || (classes && classes.indexOf(className) == -1)
      })
      .transition()
      .duration(1000)
      .style('opacity', 0)
  },
  resetHighlight: function (e) {
    var layer = e.target

    var className = 'ID' + layer.feature.properties.data.ID

    d3.selectAll('path')
      .filter(function (d, i) {
        var classes = d3.select(this).attr('class')
        return !classes || (classes && classes.indexOf(className) == -1)
      })
      .transition()
      .duration(1000)
      .style('opacity', 1)

    d3.selectAll('text')
      .filter(function (d, i) {
        var classes = d3.select(this).attr('class')
        return !classes || (classes && classes.indexOf(className) == -1)
      })
      .transition()
      .duration(1000)
      .style('opacity', 1)
  },
  applyRecord: function (record) {
    var me = this

    var map = this.getMap()

    if (!record || !record.isModel) {
      map.on(
        'click',
        Ext.bind(function (e) {
          me.addMarker(e)
        }, me)
      )
    } else {
      me.setHasLatLong(true)

      var marker = L.marker([record.get('Latitude'), record.get('Longitude')], {
        draggable: true,
        title: 'Resource location',
        alt: 'Resource Location',
        riseOnHover: true
      })
        .addTo(map)
        .bindPopup('You can drag marker...')
        .openPopup()
      marker.on('dragend', function (ev) {
        var chagedPos = ev.target.getLatLng()

        record.set('Latitude', chagedPos['lat'])
        record.set('Longitude', chagedPos['lng'])
      })

      Ext.defer(function () {
        map.setView([record.get('Latitude'), record.get('Longitude')], 5)
      }, 700)
    }
  },
  addMarker: function (e) {
    var me = this

    var map = this.getMap()

    if (!me.getHasLatLong()) {
      me.fireEvent('dragend', me, e.latlng)
      this.setMarker([e.latlng.lat, e.latlng.lng])
    }
  },
  boundPolies: [],
  applyFreeDrawMode: function (n, o) {
    var me = this

    var map = this.getMap()

    if (me.freeDraw) {
      me.freeDraw.clear()
    }

    if (me.polys) {
      map.removeLayer(me.polys)
    }

    if (map) {
      if (!me.freeDraw) {
        me.freeDraw = new FreeDraw({ mode: n })

        me.freeDraw.on('markers', function (event) {
          me.polys = me.freeDraw.all()

          for (var i = 0; i < me.polys.length; i++) {
            var poly = me.polys[i]

            var polyKey = JSON.stringify(poly.getBounds())
            if (me.boundPolies.indexOf(polyKey) > -1) {
              continue
            } else me.boundPolies.push(polyKey)
            poly.on('click', function (layer) {
              var poly = layer.target

              var latlng = layer.latlng
              map.ownerCt.fireEvent('freeDrawMultiSelect', map, poly, latlng)
            })
          }
        })

        map.addLayer(me.freeDraw)
      } else {
        me.freeDraw.mode(n)
      }
    }
  },

  createDiffButton: function () {
    var me = this
  }
})
