export default `({
    header: false,
    bodyPadding: 2,
    height: 200,
    xtype: 'panel',
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b>' + tt('Product Info') + '</b>'
        }]
    }],
    bind: {
        data: {
            MaterialCode: '{spotRecord.MaterialCode}',
            MaterialDescription: '{spotRecord.MaterialDescription}',
            MaterialGroupName: '{spotRecord.MaterialGroupName}',
            UnitofMeasurement: '{spotRecord.UnitofMeasurement}',
            UnitsPerPackage: '{spotRecord.UnitsPerPackage}',
            MinQuantity: '{spotRecord.MinQuantity}',
            MaxQuantity: '{spotRecord.MaxQuantity}',
            MinimumRemainingShelfLife: '{materialRecord.MinimumRemainingShelfLife}',
            ShelfLife: '{spotRecord.TotalShelfLife}',
            ZZDIREKT: '{materialRecord.ZZDIREKT}',
            Nitelik: '{materialRecord.ZZNITELIK}'
        }
    },
    tpl: [
        '<div class="expander">',
        '<table class="table" style="width:98%; minWidth=200px;">',
        '<tr>',
        '</tr>',
        '  <tr>',
        '       <th class=l>' + tt('MaterialCode') + '</th>',
        '       <td class="l" colspan=3 >{MaterialCode:number("0")}- <strong>{MaterialDescription} </strong></th>',
        '   </tr>',
        '</tr>',
        '  <tr>',
        '       <th class=l>' + tt('Hierarchy') + '</th>',
        '       <td class="l" colspan=3 > {MaterialGroupName}  </th>',
        '   </tr>',
        '</tr>',
        '  <tr>',
        '       <th class=l>' + tt('ShelfLife') + '</th>',
        '       <td class="l"  colspan=3 > {ShelfLife}  </th>',
        '   </tr>',
        '</tr>',
        '</table>',
        '</div>'
    ]
})
`
