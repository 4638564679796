import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { bindActionCreators, ActionCreatorsMapObject } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from '../../store'
import { Selector } from './useReduxState.types'

const useAppDispatch: () => AppDispatch = useDispatch
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

function useReduxState<TState extends undefined, TActions extends ActionCreatorsMapObject>(
  selector: TState,
  actions: TActions
): [undefined, TActions]
function useReduxState<TState>(selector: Selector<RootState, TState>): [TState, undefined]
function useReduxState<TState, TActions extends ActionCreatorsMapObject>(
  selector: Selector<RootState, TState>,
  actions?: TActions
): [TState, TActions]
function useReduxState<TState, TActions extends ActionCreatorsMapObject>(
  selector: Selector<RootState, TState>,
  actions?: TActions
): [TState | undefined, TActions | undefined] {
  const state = useAppSelector(selector ?? (() => undefined))
  const dispatch = useAppDispatch()

  const boundActions = actions ? bindActionCreators(actions, dispatch) : undefined

  return [state, boundActions]
}

export default useReduxState
