Ext.define('clustering.ClusterLibrary.clusterTree', {
  extend: 'Ext.tree.Panel',
  alias: 'widget.clusterTree',
  requires: ['clustering.Model.ClusterTreeModel'],
  width: 600,
  height: 800,
  reference: 'clusterTree',
  rootVisible: false,
  columnLines: true,
  rowLines: true,
  publishes: ['currentRow'],
  config: {
    currentRow: null
  },
  features: [
    {
      ftype: 'treeGridFilter',
      reference: 'clusterTree'
    }
  ],
  bind: {
    currentRow: '{currentRow}'
  },

  columns: [
    {
      xtype: 'treecolumn',
      dataIndex: 'Name',
      text: 'Cluster Tree',
      sortable: false,
      flex: 1,
      rootVisible: true,
      filter: {
        width: 150
      },
      editor: {
        xtype: 'textfield',
        allowBlank: true,
        allowOnlyWhitespace: false,
        maxLength: 100
      }
    },
    {
      text: 'Status',
      dataIndex: 'Status',
      minWidth: '100px',
      renderer: function (value, cell, record) {
        if (record.get('Status') == 'FOLDER') return null

        return value
      },
      getEditor: function (record) {
        var me = this

        if (!record.get('IsLeaf')) return false

        var tree = me.up().up()
        var store = tree.store

        return Ext.create('Ext.grid.CellEditor', {
          field: {
            xtype: 'combo',
            forceSelection: true,
            store: store.AvailableStatuses,
            value: record.get('Status')
          }
        })
      }
    },
    {
      text: 'Description',
      dataIndex: 'Description',
      flex: 1,
      editor: {
        xtype: 'textfield',
        allowBlank: true,
        allowOnlyWhitespace: false,
        maxLength: 100
      }
    },
    {
      text: 'UserName',
      dataIndex: 'UserName',
      flex: 1
    },
    {
      text: 'CreateTime',
      dataIndex: 'CreateTime',
      flex: 1,
      renderer: function (value, cell, record) {
        if (_.startsWith(value, '000')) {
          return ''
        } else {
          var dt = new Date(value)
          return Ext.Date.format(dt, 'd/m/Y g:i A')
        }
      }
    }
  ],
  plugins: [
    {
      ptype: 'cellediting',
      clicksToEdit: 2
    }
  ],
  listeners: {
    validateedit: function (editor, context) {
      return context.value != context.originalValue
    },
    edit: function (editor, context, eOpts) {
      var me = this

      var currentRow = context.record
      currentRow.set(context.field, context.value)

      me.getEl().mask('Loading...')

      var node = $.extend(true, {}, currentRow.data)
      // removed nto needed arguments

      delete node['Children']
      delete node['id']

      Ext.Ajax.request({
        url: clustering.Global.getApiUrl() + 'librarytree/UpdateNode',
        params: node,
        timeout: 600000,
        success: function (response, opts) {
          var result = Ext.JSON.decode(response.responseText)

          if (result.Code > -1) {
            clustering.util.operationSuccessWithMessage(result.Description)
            me.store.reload()
          } else {
            clustering.util.operationFailWithMessage(result.Description)
          }

          me.getEl().unmask()
        },
        failure: function (response, opts) {
          me.getEl().unmask()
          alert('Update node has failed.')
        }
      })
    },
    select: function (RowModel, record, index, eOpts) {
      var me = this
      me.currentRow = record
      me.lookupReferenceHolder().getViewModel().set('currentRow', record)
    }
  },

  initComponent: function () {
    var me = this

    var store = Ext.create('Ext.data.TreeStore', {
      autoLoad: true,
      model: 'clustering.Model.ClusterTreeModel',
      proxy: {
        type: 'ajax',
        timeout: 600000,
        url: clustering.Global.getApiUrl() + 'cluster/GetTree',
        reader: {
          rootProperty: 'Root',
          metaProperty: 'AvailableStatus'
        }
      },

      root: {
        text: 'Root',
        id: '0',
        expanded: true,
        Children: []
      },
      listeners: {
        metachange: function (store, meta) {
          var availableStatuses = meta
          var removedStatuses = ['CLUSTER', 'FOLDER']

          availableStatuses = availableStatuses.filter(function (n) {
            return removedStatuses.indexOf(n) === -1
          })

          store.AvailableStatuses = availableStatuses
        },
        load: function () {
          var tree = me
          var clusterLibrary = me.up().up()
          var viewModel = clusterLibrary.getViewModel()

          viewModel.set('currentRow', null)

          if (tree.getRootNode() && tree.getRootNode().childNodes.length > 0) {
            tree.getRootNode().childNodes[0].expand()
          }
        }
      }
    })

    me.store = store

    me.callParent()
  }
})
