import React, { ReactNode } from 'react'
import { Tooltip as BsTooltip } from 'react-bootstrap'
import { useUniqueId } from '../../../../hooks/index'

const Tooltip = React.forwardRef(({ children, ...rest }: { children: ReactNode }, ref: any) => {
  const [id] = useUniqueId()
  return (
    <BsTooltip ref={ref} id={id} {...rest}>
      {children}
    </BsTooltip>
  )
})

export default Tooltip
