// OVERRIDES
require('./../../../extjs/overrides/Calendar/Event')('Assortment')
require('./../../../extjs/overrides/data/store.assortment')
require('./../../../extjs/overrides/data/store/reader')
require('./../../../extjs/overrides/clipboard')
require('./../../../extjs/overrides/ViewModel')('Assortment')

// HIGHCHART
require('./../../../extjs/src/Chart/ux/require.js')

require('./src/Assortment.util')
require('./src/Global')

require('./src/model/AssortmentTreeModel')

require('./src/MainViewModel')

require('./src/assortmentLibrary/AssortmentLibrary')
require('./src/assortmentLibrary/AssortmentTree')
require('./src/assortmentLibrary/ClusterTree')
require('./src/assortmentLibrary/MfpTree')

require('./src/cmp/AddSimilarOptionGrid')
require('./src/cmp/AddSimilarOptionGridCart')
require('./src/cmp/Form')
require('./src/cmp/Grid')
require('./src/cmp/Header')
require('./src/cmp/SegmentGrid')
require('./src/cmp/SimilarOptionsChart')
require('./src/cmp/SimilarOptionsGrid')
require('./src/cmp/StoreGrid')
require('./src/cmp/TemplateColumn')
require('./src/cmp/TemplateColumn2')
require('./src/cmp/TestOptionGrid')
require('./src/cmp/TreeFilter')
require('./src/cmp/LoadFiltersGrid')

require('./src/reader/AssortmentFilterReader')
require('./src/reader/ProductReader')
require('./src/reader/SimilarOptionsReader')

require('./src/MainController')

require('./src/Main')
