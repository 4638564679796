export const selectedRowsForLookupField = [
  {
    Key: 56,
    col2: 'Purple',
    col3: 34,
    col4: 'istanbul',
    col5: 'gray',
    Date: '2018-11-02T21:00:00.000Z',
    col7: null,
    col8: '10',
    col9: '1:Green~2:Blue~3:Red~4:Orange',
    Boolean: true,
    Color: 'yellow',
    Float: null,
    ColorNo: 1,
    ColorText: 'Purple',
    ColorProps: 'sdfsd',
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: '2d3d67ad-769d-4f1c-89a3-c27a220b52f8',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 15,
    col2: 'Blue',
    col3: 13,
    col4: 'istanbul',
    col5: '#ffffff',
    Date: '1997-04-19T21:00:00.000Z',
    col7: '0.9',
    col8: '3',
    col9: '1:Green~2:Blue~3:Red~4:Orange~5:Black~6:White',
    Boolean: true,
    Color: 'black',
    Float: null,
    ColorNo: 2,
    ColorText: 'Blue',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: 'a775cd55-cc74-4a94-bd35-c2300d41355d',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 20,
    col2: 'Blue',
    col3: 34,
    col4: '34',
    col5: '7',
    Date: '1984-02-21T21:00:00.000Z',
    col7: '41.7',
    col8: '54',
    col9: '1:Green~2:Blue~3:Red~4:Orange~5:Black~6:White',
    Boolean: false,
    Color: 'pink',
    Float: null,
    ColorNo: 2,
    ColorText: 'Blue',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: 'a308e11c-b1dd-4431-82e2-d9d610a989dd',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 45,
    col2: 'Blue',
    col3: 24,
    col4: 'pink',
    col5: 'istanbul',
    Date: '1900-06-12T22:03:04.000Z',
    col7: null,
    col8: '3',
    col9: '2:Blue~3:Red~4:Orange~5:Black~6:White',
    Boolean: false,
    Color: '#040498',
    Float: null,
    ColorNo: 2,
    ColorText: 'Blue',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: 'b56cb6b5-5dfb-4ad3-bfb6-f45ffd5e5bf5',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 6,
    col2: 'Red',
    col3: 11,
    col4: 'hakan.tugay',
    col5: 'pembe4',
    Date: '2017-02-09T21:00:00.000Z',
    col7: '10.4',
    col8: '2',
    col9: '1:Green~2:Blue~3:Red~4:Orange',
    Boolean: true,
    Color: 'blue',
    Float: 48.4242,
    ColorNo: 3,
    ColorText: 'Red',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: '8b92acf3-e4d5-4dab-b276-115c79ca904d',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 4,
    col2: 'Red',
    col3: 34,
    col4: '345hhh',
    col5: 'black tea',
    Date: null,
    col7: '25.4',
    col8: '2',
    col9: '1:Green~2:Blue~3:Red~4:Orange~5:Black~6:White',
    Boolean: true,
    Color: 'green',
    Float: null,
    ColorNo: 3,
    ColorText: 'Red',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: '1ea93c8c-5d39-44a7-9bd0-36fc449f4643',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 3,
    col2: 'Orange',
    col3: 2,
    col4: '34',
    col5: '1',
    Date: '1995-01-01T22:00:00.000Z',
    col7: null,
    col8: '15',
    col9: '1:Green~2:Blue~3:Red~4:Orange~5:Black~6:White~9:',
    Boolean: true,
    Color: 'red',
    Float: null,
    ColorNo: 4,
    ColorText: 'Orange',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: '7442850b-efc7-465e-b039-c0871d5514aa',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 2,
    col2: 'Orange',
    col3: 26,
    col4: '#fffff',
    col5: 'black tea',
    Date: '2017-10-20T21:00:00.000Z',
    col7: null,
    col8: '45',
    col9: '1:Green~2:Blue~3:Red~4:Orange~5:Black~6:White~9:',
    Boolean: true,
    Color: 'red',
    Float: null,
    ColorNo: 4,
    ColorText: 'Orange',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: '059c90e5-babf-402a-be10-729fea76161a',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 10,
    col2: 'Gray',
    col3: 2,
    col4: 'hakan.tugay',
    col5: null,
    Date: null,
    col7: '12.8',
    col8: '4',
    col9: '1:Green~2:Blue~3:Red~4:Orange~5:Black~6:White',
    Boolean: null,
    Color: 'green',
    Float: 45.1659,
    ColorNo: 8,
    ColorText: 'Gray',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: '9a24fc56-6c0f-45b3-90e4-7a65cb122d72',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  },
  {
    Key: 1,
    col2: 'Green',
    col3: 13,
    col4: 'istanbul',
    col5: 'black tea',
    Date: '2000-05-21T21:00:00.000Z',
    col7: '20.5',
    col8: '5',
    col9: '1:Green~2:Blue~4:Orange',
    Boolean: true,
    Color: 'white',
    Float: 30.156,
    ColorNo: 9,
    ColorText: 'Green',
    ColorProps: null,
    _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
      _SLVY_AG_GRID_ROW_ID_: '42eb666d-037e-4f1d-b2ce-f99a2cd35317',
      _SLVY_AG_GRID_ADDED_ROWS_: [],
      _SLVY_AG_GRID_CHECKBOX_SELECTION_COLUMN_: false,
      _SLVY_AG_GRID_DIRTY_CELLS_: [],
      _SLVY_AG_GRID_DELETE_COLUMN_: true,
      _SLVY_FULL_LOOK_UP_FIELD_DATA_: false
    }
  }
]

describe('Mocks:', () => {
  it('should skip test', () => {})
})
