export const COLLAPSE = 'COLLAPSE'
export const COLLAPSE_ALL = 'COLLAPSE_ALL'
export const UNCOLLAPSE = 'UNCOLLAPSE'
export const UNCOLLAPSE_BULK = 'UNCOLLAPSE_BULK'

export const SET_LOADER = 'SET_LOADER'

export const FETCH_SCENARIO_PARAMETERS_START = 'FETCH_SCENARIO_PARAMETERS_START'
export const FETCH_SCENARIO_PARAMETERS = 'FETCH_SCENARIO_PARAMETERS'
export const STORE_SCENARIO_PARAMETERS = 'STORE_SCENARIO_PARAMETERS'
export const STORE_SCENARIO_PARAMETERS_FAILED = 'FETCH_SCENARIO_PARAMETERS_FAILED'

export const FETCH_SCENARIO_TREE_START = 'FETCH_SCENARIO_TREE_START'
export const FETCH_SCENARIO_TREE = 'FETCH_SCENARIO_TREE'
export const STORE_SCENARIO_TREE = 'STORE_SCENARIO_TREE'
export const STORE_SCENARIO_TREE_FAILED = 'STORE_SCENARIO_TREE_FAILED'

export const STORE_SET_FILTER_REMOTE = 'STORE_SET_FILTER_REMOTE'

export const STORE_SET_FILTER_TREE_REMOTE = 'STORE_SET_FILTER_TREE_REMOTE'

export const SELECT_SCENARIO = 'SELECT_SCENARIO'
export const RESET_SCENARIO = 'RESET_SCENARIO'

export const SELECT_ACTUAL_SCENARIO = 'SELECT_ACTUAL_SCENARIO'
export const RESET_ACTUAL_SCENARIO = 'RESET_ACTUAL_SCENARIO'
export const RESET_ACTUAL_SCENARIO_BY_TYPE = 'RESET_ACTUAL_SCENARIO_BY_TYPE'

export const UPDATE_SCENARIO_TREE_STATUS = 'UPDATE_SCENARIO_TREE_STATUS'

export const DELETE_SCENARIO_TREE_NODE = 'DELETE_SCENARIO_TREE_NODE'

export const FETCH_PIVOT_CONFIGURATION = 'FETCH_PIVOT_CONFIGURATION'
export const FETCH_PIVOT_CONFIGURATION_START = 'FETCH_PIVOT_CONFIGURATION_START'
export const STORE_PIVOT_CONFIGURATION = 'STORE_PIVOT_CONFIGURATION'
export const STORE_PIVOT_CONFIGURATION_FAILED = 'STORE_PIVOT_CONFIGURATION_FAILED'

export const FETCH_COMPARISON_CONFIGURATION = 'FETCH_COMPARISON_CONFIGURATION'
export const FETCH_COMPARISON_CONFIGURATION_START = 'FETCH_COMPARISON_CONFIGURATION_START'
export const STORE_COMPARISON_CONFIGURATION = 'STORE_COMPARISON_CONFIGURATION'
export const STORE_COMPARISON_CONFIGURATION_FAILED = 'STORE_COMPARISON_CONFIGURATION_FAILED'

export const FETCH_COMPARISON_DATA = 'FETCH_COMPARISON_DATA'
export const FETCH_COMPARISON_DATA_START = 'FETCH_COMPARISON_DATA_START'
export const STORE_COMPARISON_DATA = 'STORE_COMPARISON_DATA'
export const STORE_COMPARISON_DATA_FAILED = 'STORE_COMPARISON_DATA_FAILED'

export const STORE_DEFAULT_ACTION_PARAMS = 'STORE_DEFAULT_ACTION_PARAMS'
export const STORE_PIVOT_BUILDER_VIEW_SETTINGS = 'STORE_PIVOT_BUILDER_VIEW_SETTINGS'
export const STORE_SCENARIO_TREE_VIEW_SETTINGS = 'STORE_SCENARIO_TREE_VIEW_SETTINGS'
export const STORE_PIVOT_VIEW_SETTINGS = 'STORE_PIVOT_VIEW_SETTINGS'

export const STORE_PIVOT_MAIN_CONFIGURATION = 'STORE_PIVOT_MAIN_CONFIGURATION'

export const STORE_SCENARIO_COMPARISON_MAIN_CONFIGURATION =
  'STORE_SCENARIO_COMPARISON_MAIN_CONFIGURATION'

export const STORE_HEADER_SETTINGS = 'STORE_HEADER_SETTINGS'

export const FETCH_PERIODS = 'FETCH_PERIODS'
export const FETCH_PERIODS_START = 'FETCH_PERIODS_START'
export const STORE_PERIODS = 'STORE_PERIODS'
export const STORE_PERIODS_FAILED = 'STORE_PERIODS_FAILED'
export const RESET_PERIODS = 'RESET_PERIODS'

export const STORE_TIMELINE_SETTINGS = 'STORE_TIMELINE_SETTINGS'
export const RESET_TIMELINE_SETTINGS = 'RESET_TIMELINE_SETTINGS'

export const STORE_SCENARIO_COMPARISON_SETTINGS = 'STORE_SCENARIO_COMPARISON_SETTINGS'
export const RESET_SCENARIO_COMPARISON_SETTINGS = 'RESET_SCENARIO_COMPARISON_SETTINGS'

export const FETCH_MFP_DATA = 'FETCH_MFP_DATA'
export const FETCH_MFP_DATA_START = 'FETCH_MFP_DATA_START'
export const STORE_MFP_DATA = 'STORE_MFP_DATA'
export const STORE_MFP_DATA_FAILED = 'STORE_MFP_DATA_FAILED'

export const UPDATE_MFP_DATA = 'UPDATE_MFP_DATA'
export const UPDATE_MFP_DATA_START = 'UPDATE_MFP_DATA_START'
export const STORE_INCREASE_PENDING_UPDATES_COUNT = 'STORE_INCREASE_PENDING_UPDATES_COUNT'
export const STORE_DECREASE_PENDING_UPDATES_COUNT = 'STORE_DECREASE_PENDING_UPDATES_COUNT'
export const STORE_PENDING_UPDATES_COUNT = 'STORE_PENDING_UPDATES_COUNT'
export const STORE_UPDATE_MFP_DATA = 'STORE_UPDATE_MFP_DATA'
export const STORE_UPDATE_MFP_DATA_FAILED = 'STORE_UPDATE_MFP_DATA_FAILED'

export const FETCH_MFP_DETAIL_DATA = 'FETCH_MFP_DETAIL_DATA'
export const FETCH_MFP_DETAIL_DATA_START = 'FETCH_MFP_DETAIL_DATA_START'
export const STORE_MFP_DETAIL_DATA = 'STORE_MFP_DETAIL_DATA'
export const STORE_MFP_DETAIL_DATA_FAILED = 'STORE_MFP_DETAIL_DATA_FAILED'

export const FETCH_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN = 'FETCH_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN'
export const FETCH_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_START =
  'FETCH_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_START'
export const STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN = 'STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN'
export const STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_FAILED =
  'STORE_MFP_DETAIL_DATA_FOR_SINGLE_COLUMN_FAILED'

export const STORE_PIVOT_DETAIL_VIEW_SETTINGS = 'STORE_PIVOT_DETAIL_VIEW_SETTINGS'
export const STORE_PIVOT_SINGLE_DETAIL_VIEW_SETTINGS = 'STORE_PIVOT_SINGLE_DETAIL_VIEW_SETTINGS'

export const STORE_MFP_SETTINGS = 'STORE_MFP_SETTINGS'

export const FETCH_CURRENCY_RATES = 'FETCH_CURRENCY_RATES'
export const FETCH_CURRENCY_RATES_START = 'FETCH_CURRENCY_RATES_START'
export const STORE_CURRENCY_RATES = 'STORE_CURRENCY_RATES'
export const STORE_CURRENCY_RATES_FAILED = 'STORE_CURRENCY_RATES_FAILED'

export const STORE_CURRENCY_RATES_SETTINGS = 'STORE_CURRENCY_RATES_SETTINGS'

export const STORE_SET_GENERATE_REPORT_TREE = 'STORE_SET_GENERATE_REPORT_TREE'

export const STORE_SUBSET_VIEW_SETTINGS = 'STORE_SUBSET_VIEW_SETTINGS'

export const FETCH_USER_SCENARIO = 'FETCH_USER_SCENARIO'
export const FETCH_USER_SCENARIO_START = 'FETCH_USER_SCENARIO_START'
export const STORE_USER_SCENARIO = 'STORE_USER_SCENARIO'
export const STORE_USER_SCENARIO_FAILED = 'STORE_USER_SCENARIO_FAILED'

export const FETCH_QUICK_FILTER = 'FETCH_QUICK_FILTER'
export const FETCH_QUICK_FILTER_START = 'FETCH_QUICK_FILTER_START'
export const STORE_QUICK_FILTER = 'STORE_QUICK_FILTER'
export const STORE_QUICK_FILTER_FAILED = 'STORE_QUICK_FILTER_FAILED'

export const STORE_QUICK_FILTER_SETTINGS = 'STORE_QUICK_FILTER_SETTINGS'
export const RESET_QUICK_FILTER_SETTINGS = 'RESET_QUICK_FILTER_SETTINGS'
