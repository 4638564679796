Ext.define('MDM.Btl.edit.TabPanel', {
  extend: 'Ext.tab.Panel',
  xtype: 'btledittabpanel',
  reference: 'edittabpanel',
  deferredRender: false,
  cls: 'rmdtabpanel',
  listeners: {
    tabchange: 'onTabchange',
    afterlayout: 'onAfterLayout'
  },
  tabBar: {
    items: [
      {
        xtype: 'tbfill'
      },
      {
        xtype: 'button',
        margin: '4 4,0,0',
        iconCls: 'fa fa-upload',
        text: 'Import',
        ui: 'btn-inverse',
        bind: {
          disabled: '{showhideimport}'
        },
        handler: 'onUpLoadExcel'
      }
    ]
  },
  items: [
    {
      xtype: 'btl-edit-grid-manuel',
      bind: {
        store: '{editStoreManuel}',
        hideReadOnlyCol: '{hideReadOnlyColfn}',
        readOnly: '{readOnly}',
        startCol: '{startCol}'
      }
    },
    {
      xtype: 'btl-edit-grid-imported',
      bind: {
        store: '{importStore}',
        hideReadOnlyCol: '{hideReadOnlyColfn}',
        disabled: '{constarinView}',
        readOnly: '{readOnly}',
        startCol: '{startCol}'
      },
      listeners: {
        activate: 'onActivateImported',
        deactivate: 'onDeactivateImported'
      }
    },
    {
      xtype: 'btl-edit-grid-lastperiod',
      bind: {
        store: '{lastPeriodStore}',
        hideReadOnlyCol: '{hideReadOnlyColfn}',
        readOnly: '{readOnly}',
        disabled: '{constarinView}',
        startCol: '{startCol}'
      }
    },
    {
      xtype: 'btl-edit-grid-other',
      iconCls: 'fa fa-map-signs',
      title: 'Other Regions',
      reference: 'otherEdit',
      bind: {
        store: '{otherStore}',
        hideReadOnlyCol: '{hideReadOnlyColfn}',
        readOnly: '{readOnly}',
        disabled: '{constarinView}'
      }
    },
    {
      xtype: 'btl-edit-grid-calculated',
      iconCls: 'fa fa-calculator',
      title: 'Calculated',
      reference: 'calculatedEdit',
      bind: {
        store: '{calculatedStore}',
        hideReadOnlyCol: '{hideReadOnlyColfn}',
        readOnly: '{readOnly}',
        disabled: '{constarinView}'
      }
    },
    {
      xtype: 'btl-edit-grid-rulebased',
      iconCls: 'fa fa-file-text-o',
      bind: {
        store: '{ruleBasedStore}',
        hideReadOnlyCol: '{hideReadOnlyColfn}',
        readOnly: '{readOnly}',
        disabled: '{constarinView}',
        startCol: '{startCol}'
      }
    }
  ]
})
