Ext.define('clustering.util', {
  singleton: true,
  fullscreen: false,

  operationFailWithMessage: function (message) {
    var t = Ext.toast({
      html: message,
      title: 'Error',
      width: 300,
      align: 'tr',
      iconCls: 'fa exclamation-circle',
      closable: true
    })
    t.show()
  },

  operationFailWithMessagePersist: function (message) {
    var t = Ext.toast({
      html: message,
      title: 'Error',
      width: 300,
      align: 'tr',
      iconCls: 'fa exclamation-circle',
      closable: true,
      autoClose: false
    })
    t.show()
  },

  operationSuccessWithMessage: function (message) {
    var t = Ext.toast({
      html: message,
      title: 'Success',
      width: 300,
      align: 'tr',
      iconCls: 'fa fa-check',
      closable: true
    })
    t.show()
  },

  operationSuccessWithMessagePersist: function (message) {
    var t = Ext.toast({
      html: message,
      title: 'Success',
      width: 300,
      align: 'tr',
      iconCls: 'fa fa-check',
      closable: true,
      autoClose: false
    })
    t.show()
  }
})
