export const getEngineUpdatedTypes = (PluginTypes) => {
  return {
    ChangedColumns: PluginTypes.arrayOf(PluginTypes.string),
    EndPeriod: PluginTypes.string,
    Guid: PluginTypes.string,
    NewValue: PluginTypes.int,
    NewValues: PluginTypes.arrayOf(PluginTypes.int),
    OldValue: PluginTypes.int,
    OldValues: PluginTypes.arrayOf(PluginTypes.int),
    PeriodAggr: PluginTypes.int,
    PeriodName: PluginTypes.string,
    SelectedParams: PluginTypes.string,
    StartPeriod: PluginTypes.string
  }
}
