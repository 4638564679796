import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FooterButtonProps } from '.'

const FooterButton = (props: FooterButtonProps) => {
  const { disabled, icon, triggerKey, text, tooltip, variant, handleBtnClick, size } = props
  return (
    <OverlayTrigger key={triggerKey} overlay={<Tooltip>{tooltip}</Tooltip>} placement="top-start">
      <Button disabled={disabled} size={size as 'sm'} variant={variant} onClick={handleBtnClick}>
        <i className={`fa ${icon}`} /> {text}
      </Button>
    </OverlayTrigger>
  )
}

export default FooterButton
