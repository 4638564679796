export default [
  '000000000014000421',
  '000000000014001458',
  '000000000026007801',
  'addNewSpot',
  'card',
  'card1',
  'card2',
  'card5',
  'card56',
  'centerApproveAllAtAnyTime',
  'centerApproveAllAtAnyTimeNegotiation',
  'centerApproveAllAtAnyTimeStore',
  'changedistribition',
  'changeoneri',
  'changeoneriwin',
  'chart11',
  'chart113',
  'chart113grid',
  'chart113grid2',
  'chart113grid23',
  'chart113gridstore',
  'chart113gridstore2',
  'contractCfg',
  'contracttrackingrecom',
  'dagitimCfg',
  'DC-InventoryList',
  'deneme',
  'deneme2',
  'distributionStore',
  'distributiontrackingrecom',
  'distributionView',
  'distributionViewStore',
  'editRecomgrid',
  'editRecomgridStore',
  'eeeeeee',
  'false-InventoryList',
  'false-InventoryList1',
  'false-InventoryList2',
  'Img',
  'ImgTracking',
  'ImgTracking1',
  'ImgTracking2',
  'inputForm',
  'MaterialInfoTabPanel',
  'MaterialInfoTabPanel1',
  'MaterialInfoTabPanel2',
  'MaterialShortInformation',
  'MaterialShortInformation1',
  'MaterialShortInformation2',
  'MO-InventoryList',
  'newmaterialedit',
  'NormalizedSalesChart',
  'NormalizedSalesGrid',
  'NormalizedSalesGrid1',
  'NormalizedSalesGrid2',
  'Note',
  'oldSpotInformations',
  'onAddNewProductFromSap',
  'otherCfg',
  'otherCfg1',
  'otherCfg2',
  'otherCfgInevtoryStore',
  'Publisgh_Center_Panel',
  'RatioChart',
  'RatioChartStore',
  'RatioGrid',
  'RatioGridStore',
  'RatioSalesChart',
  'RatiosalesChartStore',
  'recomFormCfg',
  'recomGridCfg',
  'recomStoreCfg',
  'SalesChart',
  'SalesChart1',
  'SalesChart2',
  'SalesChartGrid',
  'SalesChartStore',
  'SalesGrid',
  'SalesGrid1',
  'SalesGrid2',
  'searchCombo',
  'SelectedSpotSalesGrid',
  'SelectedSpotSalesGrid1',
  'SelectedSpotSalesGrid2',
  'SimilarGrid',
  'SimilarGridstore',
  'SimilarGridstore1',
  'SimilarGridstore2',
  'SimilarGridTracking',
  'similartab',
  'similartab1',
  'similartab2',
  'SO-InventoryList',
  'Spot-DC_APPROVED',
  'Spot-DC_Contract',
  'Spot-DC_DISMISSED',
  'Spot-DC_Distribution',
  'Spot-DC_IN_PROGRESS',
  'Spot-DC_INPROGRESS',
  'Spot-DC_NEGOTIATION',
  'Spot-DC_PARENTSTATUS',
  'Spot-DC_Published',
  'Spot-DC_SUBOFFERS',
  'Spot-DCDEL',
  'Spot-DCNOTOK',
  'Spot-DCOK',
  'Spot-DCWAIT',
  'SpotGrid',
  'SpotGrid1',
  'SpotGrid2',
  'SpotinputFormTracking',
  'SpotinputFormTracking1',
  'SpotinputFormTracking2',
  'SpotinputNotTracking',
  'SpotinputNotTracking1',
  'SpotinputNotTracking2',
  'Spot-MO_APPROVED',
  'Spot-MO_Contract',
  'Spot-MO_DISMISSED',
  'Spot-MO_INPROGRESS',
  'Spot-MO_NEGOTIATION',
  'Spot-MO_NOT_PUBLISHED',
  'Spot-MO_Published',
  'Spot-DC_REJECTED',
  'Spot-MO_SUBOFFERS',
  'Spot-MOPUBLISHED',
  'Spot-MOWAIT',
  'Spot-null',
  'SpotSalesChart',
  'SpotSalesChartStore',
  'SpotSalesGrid',
  'SpotSelected',
  'SpotSelected1',
  'SpotSelected2',
  'SpotSelectedStore',
  'Spot-SO_APPROVED',
  'Spot-SO_DISMISSED',
  'Spot-SO_INPROGRESS',
  'Spot-SO_PARENTSTATUS',
  'Spot-SO_Published',
  'Spot-SO_REJECTED',
  'statusReport',
  'statusReport1',
  'statusReport2',
  'storedistribituon',
  'StoretDetailsforWizard',
  'StoretDetailsforWizardi',
  'StoretDetailsforWizardStore',
  'test',
  'trackingrecom',
  'unitofMeasure',
  'unitofMeasure1',
  'unitofMeasure2',
  'vendorGrid',
  'vendorGrid1',
  'vendorGrid2',
  'vendorStore',
  'regionalDistributionDetailsGridCfg'
]
