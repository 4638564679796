/* eslint-disable jest/no-export */
import { CustomInfo, SlvyColDef, SlvyColGroupDef } from '../../../../types'

interface IGenerateFakeCustomInfoProps {
  groupHeader: string
  field: string
  columnId: string
}

const generateFakeCustomInfo = ({
  groupHeader,
  field,
  columnId
}: IGenerateFakeCustomInfoProps): CustomInfo => ({
  groupHeader,
  field,
  columnId,
  deletableCondition: '',
  boolean: {
    trueIcon: 'ag-icon ag-icon-checkbox-checked',
    trueColor: 'gray',
    falseIcon: 'ag-icon ag-icon-checkbox-unchecked',
    falseColor: 'gray'
  },
  fieldConfig: {
    fieldName: '',
    dataType: 'string'
  },
  fieldLookupConfig: {
    lookupQueryList: [],
    isLookupQuery: false,
    substituteField: ''
  },
  headerName: '',
  cellStyle: {
    textAlign: 'left',
    backgroundColor: '',
    color: ''
  },
  adding: {
    enabled: false
  },
  editing: {
    enabled: false,
    editableCondition: undefined,
    allowBlank: false,
    maxValue: null,
    minValue: null,
    step: null,
    maxValueField: '',
    minValueField: '',
    stepField: '',
    lookupDataField: '',
    warningThreshold: 0
  },
  cellDoubleClickEnabled: false,
  actionButton: {
    actionEnabled: false,
    actionName: '',
    actionEditableCondition: '',
    actionClickable: false
  },
  showInMaxMode: false,
  customAggregation: '',
  rowGroup: false,
  filtering: {
    showList: undefined,
    tagFilter: false
  },
  icon: {
    icon: undefined,
    displayOnlyIcon: false,
    iconPosition: 'left'
  },
  template: '',
  summaryTemplate: '',
  columnType: 'icon',
  cellTooltip: undefined,
  formatField: undefined,
  aggFunc: '',
  hiddenCondition: '',
  highchartOptions: {
    boxFillColor: '',
    boxLineColor: '',
    highlightColor: '',
    lineColor: '',
    medianColor: '',
    outlierFillColor: '',
    outlierLineColor: '',
    performanceColor: '',
    targetColor: '',
    type: 'sparklinepie',
    whiskerColor: ''
  },
  sparklineOptions: {
    columnColor: '',
    highlightColor: '',
    lineColor: '',
    lineWidth: 0,
    maxSpotColor: '',
    minSpotColor: '',
    negBarColor: '',
    spotColor: '',
    thresholdColor: '',
    type: 'sparklinebar',
    zeroColor: ''
  },
  width: 100,
  isExportable: false,
  hide: false,
  rawField: field,
  isRawFieldEmpty: false
})

export const getInputColumnDefs = ({ hasGroupHeader }: Record<string, boolean>): SlvyColDef[] => [
  {
    field: 'Column 1',
    hide: false,
    customInfo: generateFakeCustomInfo({
      groupHeader: hasGroupHeader ? 'Group 1 {Dynamic}' : '',
      field: 'Column 1',
      columnId: 'rowId1'
    })
  },
  {
    field: 'Column 2',
    hide: false,
    customInfo: generateFakeCustomInfo({
      groupHeader: hasGroupHeader ? 'Group 2' : '',
      field: 'Column 2',
      columnId: 'rowId2'
    })
  },
  {
    field: 'Column 3',
    hide: false,
    customInfo: generateFakeCustomInfo({
      groupHeader: hasGroupHeader ? 'Group 2' : '',
      field: 'Column 3',
      columnId: 'rowId3'
    })
  }
]

export const getExpectedColumnDefs = ({
  hasGroupHeader
}: Record<string, boolean>): SlvyColGroupDef[] => [
  {
    colId: 'Group 1 Empty',
    headerName: 'Group 1 Empty',
    headerClass: 'justify-content-center',
    hide: false,
    marryChildren: true,
    customInfo: {},
    children: [
      {
        field: 'Column 1',
        hide: false,
        customInfo: generateFakeCustomInfo({
          groupHeader: hasGroupHeader ? 'Group 1 Empty' : '',
          field: 'Column 1',
          columnId: 'rowId1'
        })
      }
    ] as SlvyColDef[]
  },
  {
    colId: 'Group 2',
    headerName: 'Group 2',
    headerClass: 'justify-content-center',
    hide: false,
    marryChildren: true,
    customInfo: {},
    children: [
      {
        field: 'Column 2',
        hide: false,
        pinned: false,
        customInfo: generateFakeCustomInfo({
          groupHeader: hasGroupHeader ? 'Group 2' : '',
          field: 'Column 2',
          columnId: 'rowId2'
        })
      },
      {
        field: 'Column 3',
        hide: false,
        pinned: false,
        customInfo: generateFakeCustomInfo({
          groupHeader: hasGroupHeader ? 'Group 2' : '',
          field: 'Column 3',
          columnId: 'rowId3'
        })
      } as SlvyColGroupDef
    ] as SlvyColDef[]
  }
]

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
