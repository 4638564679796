export default `({
    flex: 1,
    border: false,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        style: {
            'border-bottom': '1px solid rgba(193, 193, 193,0.5) !important;',
            'border-top': '1px solid rgba(193, 193, 193,0.5) !important;'
        },
        items: [{
            xtype: 'tbtext',
            iconCls: 'x-fa fa-home',
            bind: {
                html: '<b> ' + tt('Vendor List') + ' : {spotRecord.MaterialDescription}   </b>'
            }
        }]
    }],
    header: false,
    iconCls: 'x-fa fa-home',
    columns: [
        // {
        //     xtype: 'actioncolumn',
        //     locked: true,
        //     width: 30,
        //     items: [{
        //         iconCls: 'x-fa fa-dot-circle-o',
        //         tooltip: 'Show Vendor Analysis',
        //         align: 'center',
        //         handler: function (grid, rowIndex, colIndex) {
        //             var rec = grid.getStore().getAt(rowIndex);
        //             grid.fireEvent('clickforDetail', rec);
        //             Ext.create('Spui.view.drilldown.Vendor.Main').show();
        //         }
        //     }]
        // },
        // { 
        //     xtype: 'checkcolumn',
        //     dataIndex: 'active' ,
        //     locked: true,
        //     disabled:true
        // },
        {
            text: tt('VendorCode'),
            dataIndex: 'VendorCode',
            width: 100,
            locked: true,
            renderer: function (v) {
                return 1 * v;
            }
        },
        {
            text: tt('VendorName'),
            dataIndex: 'VendorName',
            width: 300,
            locked: true,
        },
        {
            text: tt('FirstOrderDate'),
            dataIndex: 'FirstOrderDate',
            width: '100',
            xtype: 'datecolumn',
            format: tt('d-m-Y')
        },
        // {
        //     text: tt('LastOrderDate'),
        //     dataIndex: 'LastOrderDte',
        //     width: '100',
        //     xtype: 'datecolumn',
        //     format: tt('d-m-Y')
        // },
        {
            text: tt('UnitOfMeasure'),
            dataIndex: 'UnitOfMeasure',
            width: '200'
        },
        {
            text: tt('NumberOfOrder'),
            dataIndex: 'NumberOfOrder',
            width: '100',
            align: 'right'
        },
        {
            text: tt('TotalOrder'),
            dataIndex: 'TotalOrder',
            width: '100',
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('AvgOrder'),
            dataIndex: 'AvgOrder',
            format: '0,000.00',
            width: '100',
            align: 'right',
            xtype: 'numbercolumn'
        }
    ],
})
`
