import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import VirtualList from 'react-tiny-virtual-list'
import _ from 'lodash'
import ChartProduct from './components/ChartProduct'
import UpdateFormProduct from './components/UpdateFormProduct'
import { Axis, IssueList, Sort } from '../../components'
import {
  getMaxProductValue,
  val,
  format,
  getCoverStyle,
  getCoverKey,
  getEnabledDisplayNames,
  dropGrandTotal,
  getCoverViolations,
  getIssueSize
} from '../../utils'
import { setScrollPos } from '../../actions'
import { coverColors } from '../../utils/constants'

class TableProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      productDictionary: {},
      legendData: {},
      legendState: {
        currentIssueIndex: 0,
        currentProductInfoIndex: 0,
        isProductInfo: false
      },
      productPlanIndex: 0,
      searchValue: '',
      searchResult: []
    }

    this.scrollTop = 0

    this.legendHandler = this.legendHandler.bind(this)
    this.popoverClickRootClose = this.popoverClickRootClose.bind(this)
    this.productPlanHandler = this.productPlanHandler.bind(this)
  }

  productPlanHandler(productIndex) {
    this.setState({ productPlanIndex: productIndex })
  }

  chartHandler(productId, productIndex, periodIndex) {
    const { historyHandler } = this.props
    const { productDictionary } = this.state

    productDictionary[`${productId}${productIndex}`].currentProductPeriod = periodIndex

    const newState = {
      ...{ productDictionary },
      scrollTop: this.scrollTop
    }

    this.setState(newState)

    historyHandler({
      key: 'TableProduct',
      state: newState
    })
  }

  updateProductDictionary() {
    const { productDictionary } = this.state
    const { getRccpProductPeriodResponse: { Products = [] } = {} } = this.props

    const newProductDictionary = _.reduce(
      productDictionary,
      (obj, value, key) => {
        key = parseInt(key, 10)

        const { Index } = _.find(Products, (p) => p.Id === key) || {}

        value.currentProductId = key /// Id
        value.currentProductIndex = Index

        obj[key] = value
        return obj
      },
      {}
    )

    this.setState({ productDictionary: newProductDictionary })
  }

  legendHandler(newState) {
    this.setState({
      legendState: { ...this.state.legendState, ...newState }
    })
  }

  componentDidMount() {
    const {
      getRccpProductPeriodResponse: { IssueNames = [], ModificationCount },
      connectApi,
      lastRequest: { GetRccpProductPeriodResponse: { Request: ProductRequest = {} } = {} },
      settings: {
        modificationCount,
        issueSettings = [],
        productInfoSettings = [],
        productPlanSettings = [],
        history: {
          componentState: {
            pushState: {
              TableProduct: { productDictionary = {} } = {},
              IssueList = {},
              UpdateFormProduct: {
                productPlanItem = !_.isEmpty(_.find(productPlanSettings, (p) => p.checked))
                  ? _.find(productPlanSettings, (p) => p.checked).tableName
                  : 'EndingInventory'
              } = {}
            } = {}
          } = {}
        } = {}
      }
    } = this.props

    const issueList = _.reduce(
      IssueNames,
      (arr, item, index) => {
        const foundElement = _.find(issueSettings, (i) => i.tableName === item) || {}
        const { enable = true } = foundElement

        if (enable && !_.isEmpty(foundElement)) {
          foundElement.issueIndex = index
          arr.push(foundElement)
        }

        return arr
      },
      []
    )

    const productInfoList = _.filter(productInfoSettings, (item) => item.enable)

    const newState = {
      legendData: {
        issueList,
        productInfoList
      },
      productDictionary,
      ...(!_.isEmpty(IssueList) && { legendState: IssueList }),
      productPlanIndex: _.findIndex(
        productPlanSettings,
        (item) => item.tableName === productPlanItem
      )
    }

    this.setState(newState)

    // if (!_.isEqual(ModificationCount, modificationCount)) {
    //   ProductRequest.rccpConfig.ModificationCount = 10000
    //   connectApi('GetRccpProductPeriodResponse', ProductRequest)
    // }

    const { virtualList: { rootNode = null } = {}, periodHeader = null } = this.refs
    if (rootNode && periodHeader) {
      rootNode.onscroll = ({ target: { scrollLeft = 0 } = {} }) => {
        periodHeader.scrollLeft = scrollLeft
      }
    }
  }

  popoverClickRootClose(Id, data, productItem) {
    const {
      connectApi,
      viewHandler,
      settings,
      historyHandler,
      coverViolationIndexes,
      scrollPositions
    } = this.props
    const { productPlanIndex } = this.state

    return (
      <Popover id="popover-positioned-right">
        <UpdateFormProduct
          connectApi={connectApi}
          coverViolationIndexes={coverViolationIndexes}
          data={data}
          historyHandler={historyHandler}
          productId={Id}
          productItem={productItem}
          productPlanHandler={this.productPlanHandler}
          productPlanIndex={productPlanIndex}
          settings={settings}
          scrollPos={scrollPositions.productionPlan.detailsTable}
          viewHandler={viewHandler}
        />
      </Popover>
    )
  }

  searchHandler(event) {
    const { target: { value = '' } = {} } = event
    const { getRccpProductPeriodResponse: { Products = [] } = {} } = this.props

    const foundProductList =
      value.length > 3
        ? _.map(
            _.filter(
              Products,
              (product) =>
                _.includes(product.Name.toLowerCase(), value.toLowerCase()) ||
                _.includes(product.SKU, value)
            ),
            (currentProduct) => {
              return {
                Id: currentProduct.Id,
                SKU: currentProduct.SKU,
                Index: currentProduct.Index
              }
            }
          )
        : []

    this.setState({
      searchValue: value,
      searchResult: foundProductList
    })
  }

  isAvailable(res, param) {
    return _.isEmpty(res) || !_.isEmpty(_.find(res, (a) => _.isMatch(a, param)))
  }

  scrollHandler(e) {
    const {
      target: { scrollTop }
    } = e
    this.scrollTop = scrollTop
  }

  calculateVirtualListItem(index) {
    const { productDictionary } = this.state
    const {
      getRccpProductPeriodResponse: { Products = [] }
    } = this.props

    if (this.state.searchResult.length) index = this.state.searchResult[index].Index

    const productItem = Products[index] || {}
    const { Id: productId } = productItem
    const dictionaryKey = `${productId}${index}`
    const { currentProductIndex = null, isOpen = false } = productDictionary[dictionaryKey] || {}

    return !_.isNil(currentProductIndex) && isOpen ? 400 : 52
  }

  renderVirtualListItem({ index, style, itemSize }) {
    const {
      productDictionary,
      legendData: { issueList, productInfoList = [] },
      legendState: { currentIssueIndex, currentProductInfoIndex, isProductInfo },
      productPlanIndex,
      searchResult
    } = this.state

    let {
      getRccpProductPeriodResponse: {
        MaxRccpProductPeriod = {},
        Periods = [],
        ProductPeriods = [],
        Products = []
      },
      connectApi,
      viewHandler,
      lastRequest,
      settings,
      settings: {
        coverSettings,
        coverSettings: { cover = '', minCover = '' } = {},
        issueSettings = [],
        productPlanSettings = [],
        stateHandler: {
          selectedParameter: { Index: SelectedParamIndex = 0 }
        },
        resourceAndProductPlanSettings: {
          grandTotal: { isEnabled: isGrandTotalEnabled } = {}
        } = {},
        appSettings: { productionView: { showGrandTotal = false } = {} } = {}
      },
      historyHandler,
      coverViolationIndexes,
      isAllowed = () => {},
      scrollPositions
    } = this.props

    const rowWidth = 270 + Periods.length * 52
    const { issueIndex = 0, tableName: issueTableName = '' } = !_.isEmpty(issueList)
      ? issueList[currentIssueIndex] || {}
      : {}
    const { tableName } = productPlanSettings[productPlanIndex] || {}

    if (this.state.searchResult.length) index = searchResult[index].Index

    const productItem = Products[index] || {}
    const { Id: productId, SKU, Index: productIndex, Name } = productItem
    const dictionaryKey = `${productId}${index}`
    const {
      currentProductId = null,
      currentProductPeriod = 0,
      currentProductIndex = null,
      isOpen = false
    } = productDictionary[dictionaryKey] || {}

    let productPeriodData = ProductPeriods[productIndex]
    const FormPeriodData = productPeriodData ? productPeriodData[currentProductPeriod] : {}
    const FormPeriodObj = Periods[currentProductPeriod]
    const maxProductPeriodTable = getMaxProductValue(
      tableName,
      productPeriodData,
      SelectedParamIndex
    )

    if (isGrandTotalEnabled && !showGrandTotal) {
      productPeriodData = dropGrandTotal(productPeriodData)
      Periods = dropGrandTotal(Periods)
    }

    return (
      this.isAvailable(searchResult, { Id: productId, SKU }) && (
        <div
          key={productIndex}
          className={`-row ${!_.isNil(currentProductIndex) && isOpen ? '-active' : ''}`}
          style={{ ...style, ...{ width: rowWidth } }}
        >
          <div style={{ display: 'flex' }}>
            <div
              key={productIndex}
              className={`rccp-left -sticky-left ${
                !_.isNil(currentProductId) && isOpen ? '-active' : ''
              }`}
            >
              <header
                className="rowHeader"
                onClick={(e) => {
                  const dictionaryKey = `${productId}${productIndex}`

                  if (currentProductId !== productId && currentProductIndex !== productIndex) {
                    productDictionary[dictionaryKey] = {
                      currentProductId: productId,
                      currentProductIndex: productIndex,
                      currentProductPeriod,
                      isOpen: true
                    }
                  } else {
                    delete this.state.productDictionary[dictionaryKey]
                  }

                  const newState = {
                    ...{ productDictionary },
                    scrollTop: this.scrollTop
                  }

                  this.setState(newState)

                  historyHandler({
                    key: 'TableProduct',
                    state: newState
                  })
                }}
              >
                <i className="slvy-ui-icon-caret-right -caretIndicator" />
                <div className="rowLabels">
                  <p>{Name}</p>
                  <p>{SKU}</p>
                </div>
              </header>

              {!_.isNil(currentProductId) && isOpen && (
                <div className="-inner-box">
                  <UpdateFormProduct
                    connectApi={connectApi}
                    coverSettings={coverSettings}
                    coverViolationIndexes={coverViolationIndexes}
                    data={FormPeriodData}
                    historyHandler={historyHandler}
                    period={FormPeriodObj}
                    productId={productId}
                    productItem={productItem}
                    productPlanHandler={this.productPlanHandler}
                    productPlanIndex={productPlanIndex}
                    settings={settings}
                    scrollPos={scrollPositions.productionPlan.detailsTable}
                    onScroll={(event) => this.handleScroll('detailsTable', event, productId)}
                    viewHandler={viewHandler}
                  />
                  <Axis axisData={maxProductPeriodTable} />
                </div>
              )}
            </div>
            <div>
              <ul>
                {_.map(Periods, (periodItem, periodIndex) => {
                  const { Visible = false } = periodItem
                  const ProductPeriodItem = productPeriodData ? productPeriodData[periodIndex] : {}
                  const {
                    IssueValues = [],
                    Values = [],
                    [getCoverKey(cover, 'Cover')]: Cover = 0,
                    [getCoverKey(minCover, 'MinCover')]: MinCover = 0
                  } = ProductPeriodItem

                  const issueValue = IssueValues[issueIndex]
                  const maxIssueValue = MaxRccpProductPeriod.IssueValues[issueIndex]
                  const itemSizeIssue = getIssueSize(issueValue, maxIssueValue, issueTableName)
                  const { color } = issueSettings[currentIssueIndex] || {}

                  const {
                    tableName = 'Cover',
                    matchFieldName = false,
                    format: formatType
                  } = productInfoList[currentProductInfoIndex] || {}
                  const tableValue = matchFieldName
                    ? val(Values, tableName, SelectedParamIndex)
                    : ProductPeriodItem[tableName]

                  const isInfinity = tableValue === 'Infinity'

                  const coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
                  const { backgroundColor: borderTopColor = 'transparent' } = getCoverStyle(
                    Cover,
                    MinCover,
                    ...coverViolations
                  )
                  const cellCoverStyle = isProductInfo ? { borderTopColor } : {}
                  const hasCoverViolation = borderTopColor !== coverColors.green

                  return (
                    Visible && (
                      <li
                        // ref={`cell_${productIndex}_${periodIndex}`}
                        key={`${productIndex}-${periodIndex}`}
                        ref={`cell_${productIndex}_${periodIndex}`}
                      >
                        <div className="-cell" style={hasCoverViolation ? cellCoverStyle : {}}>
                          {!isProductInfo ? (
                            itemSizeIssue > 0 && (
                              <OverlayTrigger
                                rootClose
                                overlay={this.popoverClickRootClose(
                                  productId,
                                  ProductPeriodItem,
                                  productItem
                                )}
                                placement="right"
                                trigger="click"
                              >
                                <i
                                  style={{
                                    height: itemSizeIssue,
                                    width: itemSizeIssue,
                                    backgroundColor: color
                                  }}
                                />
                              </OverlayTrigger>
                            )
                          ) : (
                            <b className={`${isInfinity ? '-infinity' : ''}`}>
                              {' '}
                              {isInfinity ? '∞' : format(tableValue, formatType)}{' '}
                            </b>
                          )}
                        </div>
                      </li>
                    )
                  )
                })}
                <li />
              </ul>
              {!_.isNil(currentProductIndex) && isOpen && (
                <section className="-chart-box">
                  <ChartProduct
                    chartHandler={this.chartHandler.bind(this, productId, productIndex)}
                    connectApi={connectApi}
                    isAllowed={isAllowed}
                    lastRequest={lastRequest}
                    periods={Periods}
                    product={productItem}
                    productId={productId}
                    productPeriod={productPeriodData}
                    settings={settings}
                    tableName={tableName}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      )
    )
  }

  handleScroll = (scrollArea, event, productId) => {
    const { setScrollPos } = this.props
    const {
      target: { scrollTop, scrollLeft }
    } = event

    if (scrollArea === 'detailsTable') {
      setScrollPos({
        view: 'productionPlan',
        scrollArea,
        scrollPos: {
          [productId]: { top: scrollTop, left: scrollLeft }
        }
      })
    } else {
      setScrollPos({
        view: 'productionPlan',
        scrollArea,
        scrollPos: { top: scrollTop, left: scrollLeft }
      })
    }
  }

  render() {
    const {
      legendData: { issueList, productInfoList = [] },
      legendState,
      legendState: { currentIssueIndex, currentProductInfoIndex, isProductInfo },
      searchValue,
      searchResult = []
    } = this.state

    let {
      getRccpProductPeriodResponse: { Periods = [], Products = [] },
      connectApi,
      lastRequest,
      settings,
      settings: {
        stateHandler: { currentMenuItem, currentMenuState },
        size: { height = 0 } = {},
        // history: {
        //   componentState: { pushState: { TableProduct: { scrollTop = 0 } = {} } = {} } = {}
        // } = {},
        periodSettings: {
          displayOptions: { isYearVisible = true, isWeekVisible = true, isMonthVisible = true } = {}
        } = {},
        resourceAndProductPlanSettings: {
          grandTotal: { isEnabled: isGrandTotalEnabled } = {}
        } = {},
        appSettings: { productionView: { showGrandTotal = false } = {} } = {}
      },
      scrollPositions,
      historyHandler,
      stateHandler
    } = this.props

    if (isGrandTotalEnabled && !showGrandTotal) {
      Periods = dropGrandTotal(Periods)
    }

    const periodWidth = _.filter(Periods, (p) => p.Visible).length * 52 + 52

    const allProducts = _.isEmpty(searchResult) ? Products : searchResult

    return (
      <div
        className={`rcc-wrp rccp-table-product ${
          currentMenuItem === 'issues' && currentMenuState ? '-issue-filter' : ''
        }`}
      >
        {productInfoList.length > 0 && (
          <IssueList
            issueList={issueList}
            infoList={productInfoList}
            legendState={legendState}
            legendHandler={this.legendHandler}
            settings={settings}
            historyHandler={historyHandler}
            scrollPos={scrollPositions.productionPlan.issueList}
            onScroll={(event) => this.handleScroll('issueList', event)}
          />
        )}

        <header className="rcc-header" ref="periodHeader">
          <div className="-header-left">
            <div className="searchContainer">
              <input
                placeholder="Product Search"
                value={searchValue}
                onChange={this.searchHandler.bind(this)}
              />
            </div>
          </div>
          <div ref="periodctn" className="-period-ctn">
            <ul style={{ width: periodWidth }}>
              {_.map(Periods, (periodItem, periodIndex) => {
                const {
                  Visible = false,
                  DisplayName: periodDisplayName,
                  Name: periodName
                } = periodItem

                const enabledDisplayNames = getEnabledDisplayNames(
                  [isYearVisible, isWeekVisible, isMonthVisible],
                  periodDisplayName,
                  periodName
                )

                return Visible && <li key={periodIndex}>{enabledDisplayNames}</li>
              })}
              <li className="-sort-box">
                <Sort
                  connectApi={connectApi}
                  currentIssueIndex={currentIssueIndex}
                  currentProductInfoIndex={currentProductInfoIndex}
                  historyHandler={historyHandler}
                  isProductInfo={isProductInfo}
                  issueList={issueList}
                  lastRequest={lastRequest}
                  period={Periods}
                  productInfoList={productInfoList}
                  settings={settings}
                  stateHandler={stateHandler}
                  updateProductDictionary={this.updateProductDictionary.bind(this)}
                />
              </li>
            </ul>
          </div>
        </header>
        <div ref="rccpTable" className="rccp-table" onScroll={this.scrollHandler.bind(this)}>
          <div className="rccp-body">
            <div ref="rccpbody" className="rccp-b-ctn">
              <VirtualList
                ref="virtualList"
                className="rccp-b-cover"
                width={'100%'}
                height={height - 178}
                itemCount={allProducts.length}
                itemSize={this.calculateVirtualListItem.bind(this)}
                renderItem={this.renderVirtualListItem.bind(this)}
                scrollOffset={scrollPositions.productionPlan.main.top}
                onScroll={(_, event) => this.handleScroll('main', event)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes,
  scrollPositions: state.scrollPositions
})

const mapDispatchToProps = {
  setScrollPos
}

export default connect(mapStateToProps, mapDispatchToProps)(TableProduct)
