export default `({
    tpl: new Ext.XTemplate(
        '<div class="grid-container">',
        '<tpl for=".">',
        '<div class="status-item" >',
        '<div class="toptext" style="font-size:18px"> {StatusDescription}</div>',
        '<div class="topNumber" style="font-size:28px "> {NumberOfRecord}</div>',
        '</div>',
        '</tpl>',
        '</div>'
    ),
})
`
