import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { find, forEach, isArray, isEmpty, isString } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import settings from '../../utils/settings'
import { SlvyTable, slvyToast } from '@/components'
import './_old-view.scss'
import { arrayToObject, objectToArray, getDateDiff } from '../../utils'
import { getGridViewData } from './utils'
import { getOptions, setOptionUpdateList } from '../../store/slices/options'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { defaultColumn, defaultFilterOptions, emptyData } from './constants'

const selectFromState = (state) => ({
  errorRootOptions: state.options.error,
  index: state.options.index,
  isLoading: state.options.isLoading,
  points: state.options.points,
  rightClicked: state.options.rightClicked,
  rootOptions: state.options.rootOptions,
  show: state.options.show,
  target: state.options.target,
  updateScroll: state.options.scroll,
  formattingSettings: state.plugin.formattingSettings,
  optionUpdateList: state.options.optionUpdateList
})

function getGridRowClass({ index }) {
  const isFirstRow = index === 0

  return isFirstRow ? 'old-view-grid-sticky-row' : ''
}

function SlvyGridComponent(props) {
  const dispatch = useDispatch()
  const { points, rightClicked, rootOptions, formattingSettings, optionUpdateList } =
    useSelector(selectFromState)

  const { Options = [], Segments, Stores } = rootOptions

  const [gridData, setGridData] = useState([rootOptions])
  const [segments, setSegments] = useState(Segments)
  const [selectedProductId, setSelectedProductId] = useState(null)
  const [stores, setStores] = useState(Stores)
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions)
  const { getContextMenuItems = () => {}, handleClickUpdateOption = () => {}, pluginId } = props
  const { decimal = '0,0.00' } = formattingSettings

  useEffect(() => {
    if (!isEmpty(rootOptions)) {
      setGridData(getGridViewData(rootOptions, Options, stores, segments))
      setStores(rootOptions.Stores)
      setSegments(rootOptions.Segments)
    }
  }, [rootOptions])

  useEffect(() => {}, [rightClicked, points])

  //   const handleTestProductsToggle = (isShowTestProducts) => {
  //     const { onTestProductsToggle = () => {} } = this.props
  //     onTestProductsToggle(isShowTestProducts, 'Edit')
  //   }

  const getGridColumnClass = (args, index) => {
    const { row } = args
    const isFirstTwoColumn = index < 2
    if (row) {
      const { original: { firstLine = false, Id } = {} } = row
      return isFirstTwoColumn
        ? selectedProductId === Id && !firstLine
          ? 'old-view-grid-sticky-column row-selected'
          : 'old-view-grid-sticky-column'
        : selectedProductId === Id && !firstLine
        ? 'row-selected'
        : ''
    } else {
      return isFirstTwoColumn ? 'old-view-grid-sticky-column overflow-visible' : ''
    }
  }

  const onUpdateOption = (params) => {
    const { DecimalFormat } = settings
    // const {
    //   props: {
    //     pluginId = '',
    //     formattingSettings: { decimal = '0,0.00' } = {},
    //     fetchOptions = () => {},
    //     setLoader = () => {},
    //     scrollHandler = () => {}
    //   },
    //   settings: { DecimalFormat }
    // } = this

    // TODO scroll ?
    // scrollHandler(this.state.scroll)

    dispatch(setLoaderReducer({ isShown: true, messages: 'Update Option' }))
    // setLoader({ isShown: true, messages: 'Update Option' })

    const {
      BasePrice = null,
      EndDate = null,
      StartDate = null,
      Expenditure = null,
      PrePackSize = null,
      Sellthrough = null
    } = params.OptionStr

    const { OptionStr } = params

    const BasePriceFormatted = `${numeral(BasePrice).format(decimal)}`
    const DaysDiff = getDateDiff(StartDate, EndDate, 'days')

    OptionStr.SegmentOptions = objectToArray(OptionStr.SegmentOptions).map((segmentOption) => {
      const { EndDate: sEndDate = null, StartDate: sStartDate = null } = segmentOption
      segmentOption.DaysDiff = getDateDiff(sStartDate, sEndDate, 'days')
      return segmentOption
    })

    OptionStr.SegmentOptions = arrayToObject(OptionStr.SegmentOptions)

    const payload = {
      pluginId,
      method: 'UpdateOption',
      requestMethod: 'post',
      body: {
        ...params,
        OptionStr: {
          ...OptionStr,
          BasePriceFormatted,
          DaysDiff,
          Expenditure: numeral(numeral(Expenditure).format(DecimalFormat)).value(),
          PrePackSize: numeral(numeral(PrePackSize).format(DecimalFormat)).value(),
          Sellthrough: numeral(numeral(Sellthrough).format(DecimalFormat)).value()
        }
      }
    }

    dispatch(getOptions(payload))
    // fetchOptions({ ...payload }).then(() => {
    //   const { rootOptions = {} } = this.props

    //   if (_.isEmpty(rootOptions)) {
    //     slvyToast.error({ message: 'There is an error!', title: 'Update Error!' })
    //   }
    //   setLoader({ isShown: false })

    //   $callback(rootOptions)
    // })

    dispatch(setLoaderReducer({ isShown: false, messages: 'Update Option' }))
  }

  const addProductToSegmentToggle = (OptionStr, Enabled, segmentEnabled, SegmentId, updateList) => {
    const { Editable: isFutureOption = false, Id: optionId } = OptionStr
    if (!isFutureOption) {
      slvyToast.error({
        message: 'Option can not be assigned. It is not a future option!',
        title: 'Assign Option'
      })
      return
    }
    const newOptionUpdateList = _.cloneDeep(updateList)
    const selectedIndex = _.findIndex(newOptionUpdateList, {
      optionId,
      SegmentId
    })

    if (selectedIndex === -1) {
      newOptionUpdateList.push({ optionId, SegmentId, Enabled: !segmentEnabled })
    } else if (Enabled === segmentEnabled) {
      // TODO we do not expect that this case will happen
      newOptionUpdateList[selectedIndex].Enabled = !segmentEnabled
    } else {
      _.pullAt(newOptionUpdateList, selectedIndex)
    }
    dispatch(setOptionUpdateList(newOptionUpdateList))
  }

  const getProductCell = (cell, updateList) => {
    const {
      row: {
        depth,
        index: cellIndex,
        original = {},
        original: { BasePrice, ColorName, Description = '', Id, ImageUrl = '', Name }
      }
    } = cell
    const src = isArray(ImageUrl) && ImageUrl.length ? ImageUrl[0] : ImageUrl
    const editedOption = _.find(updateList, { optionId: Id })

    return src && isString(src) ? (
      <div className="product-img-ctn">
        <img alt={Name} className="product-img" src={src} />
        {editedOption && (
          <i
            className="slvy-ui-icon-check-circle-regular opdate-opt-icon"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Update Option"
            onClick={(e) => {
              e.stopPropagation()
              handleClickUpdateOption(Id, updateList)
            }}
          />
        )}
        <div className="product-img-text">
          <div className="text-center "> {Name}</div>
          <div className="text-center "> {numeral(BasePrice).format(decimal)}</div>
        </div>
      </div>
    ) : null
  }

  const getSummaryCell = (cell) => {
    const {
      row: {
        depth,
        original: {
          firstLine = false,
          assignedOptions,
          forecastedCost,
          forecastedQuantity,
          forecastedRevenue,
          numberOfStores,
          EndDate,
          Expenditure,
          GlobalTotalStock,
          IMU,
          NumberOfActiveStores,
          ROS,
          SizeRangeId,
          StartDate,
          TotalBuyQuantity,
          TotalCost,
          TotalMinPresentation,
          TotalRevenue,
          TotalSales
        }
      }
    } = cell

    if (firstLine) {
      return (
        <div className="align-items-center" style={{ marginLeft: 15 * depth }}>
          <div className="d-flex justify-content-between">
            <span>Forecasted Revenue</span>
            <span>{numeral(forecastedRevenue).format(decimal)}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Forecasted Quantity</span>
            <span>{numeral(forecastedQuantity).format('0,000')}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Forecasted Cost</span> <span>{numeral(forecastedCost).format(decimal)}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Number Of Stores</span> <span>{numeral(numberOfStores).format('0,000')}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Assigned Options / Options</span> <span>{assignedOptions}</span>
          </div>
        </div>
      )
    }

    const sizeRangeType = SizeRangeId > 0 ? rootOptions.SizeRangeMapping[SizeRangeId] : ''
    return (
      <div>
        <div className="d-flex justify-content-between">
          <span>Forecasted Revenue</span> <span>{numeral(TotalRevenue).format(decimal)}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Forecasted Quantity</span> <span>{numeral(TotalSales).format('0,000')}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Forecasted Cost</span> <span>{numeral(TotalCost).format(decimal)}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Unit Cost / IMU</span>
          <span>
            {numeral(Expenditure).format(decimal)} /{numeral(IMU).format('0,000.00')}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Start Date</span> <span>{moment(StartDate).format('DD/MM/YYYY')}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>End Date</span> <span>{moment(EndDate).format('DD/MM/YYYY')}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Min Presentation</span>
          <span>{numeral(TotalMinPresentation).format('0,000')}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Buy Quantity Final</span> <span>{numeral(TotalBuyQuantity).format('0,000')}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Size Range Type</span> <span>{sizeRangeType}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Number Of Stores</span>
          <span>{numeral(NumberOfActiveStores).format('0,000')}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>ROS</span> <span>{numeral(ROS).format('0,000.00')}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Global Total Stock</span> <span>{GlobalTotalStock}</span>
        </div>
      </div>
    )
  }

  const getSegmentCell = (cell, updateList) => {
    const {
      row: {
        depth,
        index,
        original,
        original: { availableSegments, firstLine = false, Id, ImageUrl = '', Name, SegmentOptions }
      },
      column: { columnDef: { header } = {}, id } = {}
    } = cell

    const foundedOption = Options && Options[index - 1]
    let foundedSegmentRow

    if (firstLine) {
      foundedSegmentRow = find(availableSegments, (segmentOption) => {
        return segmentOption.Name === id
      })
      if (foundedSegmentRow) {
        const { TotalRevenue, TotalSales, TotalCost, NumberOfStores, NumberOfOptions } =
          foundedSegmentRow
        return (
          <div>
            <div className="text-end ml-2">{numeral(TotalRevenue).format(decimal)}</div>
            <div className="text-end ml-2">{numeral(TotalSales).format('0,000')}</div>
            <div className="text-end ml-2">{numeral(TotalCost).format(decimal)}</div>
            <div className="text-end ml-2">{numeral(NumberOfStores).format('0,000')}</div>
            <div className="text-end ml-2">{NumberOfOptions}</div>
          </div>
        )
      } else {
        return <div className="align-items-center"></div>
      }
    } else if (foundedOption) {
      foundedSegmentRow = find(foundedOption.SegmentOptions, (segmentOption) => {
        return segmentOption.SegmentName === header
      })
      if (foundedSegmentRow) {
        const {
          BuyQuantity,
          Enabled = false,
          EndDate,
          SegmentId,
          StartDate,
          ExcludedStoreIds
        } = foundedSegmentRow
        let segmentEnabled = Enabled
        const numberOfStores = Stores.filter((store) => store.SegmentId === SegmentId).length
        const numberOfExcludedStores = ExcludedStoreIds?.length ?? 0
        const numberOfActiveStores = numberOfStores - numberOfExcludedStores

        _.forEach(updateList, (item) => {
          if (item.optionId === Id && item.SegmentId === SegmentId) {
            segmentEnabled = item.Enabled
          }
        })
        if (segmentEnabled) {
          const src = isArray(ImageUrl) && ImageUrl.length ? ImageUrl[0] : ImageUrl

          return (
            <div
              className="segment-img-ctn"
              onClick={(e) => {
                e.stopPropagation()
                addProductToSegmentToggle(original, Enabled, segmentEnabled, SegmentId, updateList)
              }}
            >
              <img alt={Name} className="segment-img mw-100" src={src} />
              <div className="segment-img-text mw-100">
                <div className="text-center " style={{ fontSize: '12px' }}>
                  <b># Stores:</b> {numberOfActiveStores}/{numberOfStores}
                </div>
                <div className="text-center " style={{ fontSize: '10px' }}>
                  {moment(StartDate).format('DD/MM/YYYY')}-{moment(EndDate).format('DD/MM/YYYY')}
                </div>
                <div className="text-center " style={{ fontSize: '10px' }}>
                  Buy Qty: {numeral(BuyQuantity).format('0,000')}
                </div>
              </div>
            </div>
          )
        }
        return (
          <div
            className="segment-img-ctn"
            onClick={(e) => {
              e.stopPropagation()
              addProductToSegmentToggle(original, Enabled, segmentEnabled, SegmentId, updateList)
            }}
          >
            <div className="segment-add-product">Click to Add Product</div>
          </div>
        )
      }
    } else {
      return <div className="align-items-center"></div>
    }
  }

  const handleSelectTableRow = (row, event) => {
    setSelectedProductId(row.original.Id)
  }

  const getColumns = (rootOptions, updateList) => {
    const { Segments } = rootOptions
    let columns = [
      {
        accessorKey: 'Name',
        header: 'Product',
        sortable: true,
        enableResizing: false,
        minSize: 200,
        size: 200,
        maxSize: 200,
        cell: (cell) => getProductCell(cell, updateList),
        filterFn: 'includesString',
        meta: {
          hideColumnMenu: false
        }
      },
      {
        accessorKey: 'TotalSales',
        header: 'Summary',
        sortable: true,
        maxSize: 350,
        enableContextMenu: true,
        minSize: 350,
        size: 350,
        cell: getSummaryCell
      }
    ]

    forEach(Segments, (segment) => {
      columns.push({
        accessorKey: segment.Name,
        header: segment.Name,
        id: segment.Name,
        sortable: true,
        minSize: 200,
        size: 200,
        maxSize: 200,
        cell: (cell) => getSegmentCell(cell, updateList)
      })
    })

    return columns
  }

  const mainData = useMemo(() => {
    const filteredData = gridData.filter((row) => {
      const { IsTestProduct: isTestProduct = false, SegmentOptions = {}, firstLine = false } = row

      if (firstLine) {
        return true
      }

      const segmentOptionsArr = objectToArray(SegmentOptions)
      const assignedSegments = segmentOptionsArr.find((segmentOption) => {
        return segmentOption?.OriginalEnabled ?? segmentOption?.Enabled ?? false
      })

      if (!filterOptions.showConfirmedProducts && !isTestProduct) {
        return false
      }
      if (!filterOptions.showTestProducts && isTestProduct) {
        return false
      }
      if (!filterOptions.showAssignedProducts && assignedSegments) {
        return false
      }
      if (!filterOptions.showUnassignedProducts && !assignedSegments) {
        return false
      }
      return true
    })

    if (filteredData.length === 1) {
      return [
        {
          ...emptyData,
          availableSegments: filteredData[0]?.availableSegments
        }
      ]
    }

    return filteredData
  }, [
    filterOptions.showAssignedProducts,
    filterOptions.showConfirmedProducts,
    filterOptions.showTestProducts,
    filterOptions.showUnassignedProducts,
    gridData
  ])

  const getColumnMenuItems = () => {
    return [
      'separator',
      {
        name: 'Confirmed Products',
        icon: filterOptions.showConfirmedProducts ? 'fa fa-check-square' : 'fa fa-square',
        onClick: () =>
          setFilterOptions((prev) => ({
            ...prev,
            showConfirmedProducts: !prev.showConfirmedProducts
          }))
      },
      {
        name: 'Test Products',
        icon: filterOptions.showTestProducts ? 'fa fa-check-square' : 'fa fa-square',
        onClick: () =>
          setFilterOptions((prev) => ({
            ...prev,
            showTestProducts: !prev.showTestProducts
          }))
      },
      'separator',
      {
        name: 'Assigned Products',
        icon: filterOptions.showAssignedProducts ? 'fa fa-check-square' : 'fa fa-square',
        onClick: () =>
          setFilterOptions((prev) => ({
            ...prev,
            showAssignedProducts: !prev.showAssignedProducts
          }))
      },
      {
        name: 'Unassigned Products',
        icon: filterOptions.showUnassignedProducts ? 'fa fa-check-square' : 'fa fa-square',
        onClick: () =>
          setFilterOptions((prev) => ({
            ...prev,
            showUnassignedProducts: !prev.showUnassignedProducts
          }))
      }
    ]
  }

  const columns = getColumns(rootOptions, optionUpdateList)

  return !isEmpty(rootOptions) ? (
    <div>
      <div className="table-container">
        <SlvyTable
          rowSelection
          className="old-view-grid"
          columns={columns}
          data={mainData}
          defaultColumn={defaultColumn}
          getBodyClassName="old-view-grid-body"
          getCellClassName={getGridColumnClass}
          getColumnMenuItems={getColumnMenuItems}
          getContextMenuItems={getContextMenuItems}
          getHeadClassName="old-view-grid-head"
          getHeaderClassName={getGridColumnClass}
          getRowClassName={getGridRowClass}
          onSelectRow={handleSelectTableRow}
        />
      </div>
    </div>
  ) : (
    <div>loading</div>
  )
}

export default SlvyGridComponent
