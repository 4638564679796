import _ from 'lodash'
import path from 'path'

const isConfigFile = new RegExp(/config.(js|ts)/)
const isIndexFile = new RegExp(/^\.\/[^/]+\/index\.(js|jsx|ts|tsx)$/)

export const GetPluginSchema = (type) => {
  try {
    const requireContext = require.context('./../plugins/', true, /^\.\/.*\.(js|ts)$/)
    let config = null
    _.each(requireContext.keys(), (r) => {
      if (isConfigFile.test(r)) {
        const context = requireContext(r)
        if (context.default.namespace === type) {
          const extname = path.extname(r)
          const c = _.replace(r, `config${extname}`, `schema${extname}`)
          const pluginContext = requireContext(c)
          config = pluginContext.default
        }
      }
    })
    return config()
  } catch (ex) {
    return null
  }
}

export const GetPluginConfig = (type) => {
  const requireContext = require.context('./../plugins/', true, /^\.\/.*\.(js|ts)$/)
  let config = null
  _.each(requireContext.keys(), (r) => {
    if (isConfigFile.test(r)) {
      const context = requireContext(r)
      if (context.default.namespace === type) {
        config = context.default
      }
    }
  })
  return config
}

export const GetPlugins = () => {
  const requireContext = require.context('./../plugins/', true, /^\.\/.*\.(js|jsx|ts|tsx)$/)
  return requireContext
    .keys()
    .filter((file) => isConfigFile.test(file) || isIndexFile.test(file))
    .reduce(
      (accumulator, current, index, array) =>
        index % 2 === 0 ? accumulator.concat([[current, array.at(index + 1)]]) : accumulator,
      []
    )
    .map(([configFile, indexFile]) => ({
      pluginContext: requireContext(indexFile),
      ...requireContext(configFile).default
    }))
}

export function GetPlugin(pluginKey) {
  const g = _.find(GetPlugins(), (value, key) => {
    return pluginKey === value.namespace
  })
  return g && g.pluginContext && g.pluginContext.default
}
