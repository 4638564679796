import { Outlet, useParams } from 'react-router-dom'
import _ from 'lodash'

const LayoutWithoutNav = () => {
  const { environment = '' } = useParams()
  return (
    <div
      className={`slvy-ui-wrapper --${_.lowerCase(environment)}`}
      data-testid="layout-without-nav"
      id="wrapper"
    >
      <div className="gray-bg">
        <Outlet />
      </div>
    </div>
  )
}
export default LayoutWithoutNav
