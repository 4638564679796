Ext.define('MDM.Btl.dd.Discrepancy.Grid', {
  extend: 'Ext.grid.Panel',
  // Uncomment to give this component an xtype
  xtype: 'discrepancy-grid',
  emptyText: 'No DPI pairs with discrepancy',
  features: [
    {
      ftype: 'grouping',
      startCollapsed: false,
      groupHeaderTpl:
        '{columnName}: {name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  initComponent: function () {
    var me = this
    me.columns = me.buildColumns()
    me.callParent()
  },
  onSelect: function (grid, rowIndex, colIndex, item, e, record) {
    var win = this.up('window')
    win.fireEvent('select', this, record)
    win.close()
  },
  plugins: ['gridfilters', 'gridexporter'],
  tbar: [
    '->',
    {
      text: 'Export',
      iconCls: 'fa fa-download',
      ui: 'btn-success',
      handler: 'exportTo'
    }
  ],
  exportTo: function (btn) {
    var me = this
    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }
    var newcfg = Ext.merge(
      {
        title: 'Discrepancy',
        fileName: 'Discrepancy' + '.' + cfg.ext
      },
      btn.cfg
    )
    this.saveDocumentAs(newcfg)
  },
  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },
  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },
  defaultListenerScope: true,
  buildColumns: function () {
    var me = this

    var hideReadOnlyCol = false

    var readOnly = false
    return MDM.Btl.Column.build(
      [
        {
          menuDisabled: true,
          sortable: false,
          xtype: 'actioncolumn',
          locked: true,
          resizable: false,
          width: 50,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-pencil ',
              isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                return !rec.get('IsEditable')
              },
              getClass: function (v, meta, rec) {
                return 'fa    fa-arrow-circle-left"'
              },
              handler: function (grid, rowIndex, colIndex, item, e, record) {
                var win = grid.up('window')
                win.fireEvent('select', grid, record)
                win.close()
              },
              scope: me
            }
          ]
        },
        {
          text: 'DPI Family',
          tooltip: 'DPIFamily',
          dataIndex: 'DPIFamily',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'Type',
          tooltip: 'ctype',
          dataIndex: 'ctype',
          locked: true,
          width: 100,
          filter: 'list'
        },
        {
          text: 'Target Subregion',
          tooltip: 'Target Subregion',
          dataIndex: 'TargetRegion',
          locked: true,
          width: 80,
          filter: 'list'
        },
        {
          text: 'MaterialType',
          tooltip: 'MaterialType',
          dataIndex: 'MaterialType',
          locked: true,
          width: 80,
          filter: 'list'
        },
        {
          text: 'Actual',
          tooltip: 'Total sum of absolute actual value',
          xtype: 'btlnumber',
          dataIndex: 'Past',
          locked: true,
          tdCls: 'blue',
          width: 100,
          menuDisabled: false,
          sortable: true,
          filter: 'number'
        },
        {
          text: 'Forecast',
          tooltip: 'Total sum of absolute forecast value',
          xtype: 'btlnumber',
          dataIndex: 'Future',
          locked: true,
          width: 100,
          sortable: true,
          menuDisabled: false,
          filter: 'number'
        },
        {
          text: 'Total',
          tooltip: 'Total ABS',
          xtype: 'btlnumber',
          dataIndex: 'Toplam',
          locked: true,
          width: 100,
          sortable: true,
          menuDisabled: false,
          filter: 'number'
        }
      ],
      false
    )
  }
})
