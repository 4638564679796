import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ChartResource from './components/ChartResource'
import UpdateFormResource from './components/UpdateFormResource'
import { IssueList, SwitchButton } from '../../components'
import { format, getEnabledDisplayNames, getIssueSize, restoreScrollPos } from '../../utils'
import { setScrollPos } from '../../actions'

class TableResource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resourceDictionary: {},
      legendData: {
        issueList: [],
        resourceInfoList: []
      },
      legendState: {
        currentIssueIndex: 0,
        currentProductInfoIndex: 0,
        isProductInfo: false
      }
    }

    this.productClickHandler = this.productClickHandler.bind(this)
    this.getProductData = this.getProductData.bind(this)
    this.legendHandler = this.legendHandler.bind(this)
  }

  legendHandler(newState) {
    this.setState({
      legendState: { ...this.state.legendState, ...newState }
    })
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   let { resourceDictionary = {} } = nextState

  //   if (!_.isEmpty(resourceDictionary)) {
  //     _.transform(
  //       resourceDictionary,
  //       (obj, value, key) => {
  //         let {
  //           formProductData,
  //           formProductData: {
  //             Key: { currentResourceIndex, currentResourcePeriodIndex, currentProductIndex } = {}
  //           } = {}
  //         } = value

  //         obj[key] = value

  //         if (!_.isEmpty(formProductData)) {
  //           obj[key].formProductData = this.getProductData(
  //             currentResourceIndex,
  //             currentResourcePeriodIndex,
  //             currentProductIndex
  //           )
  //         }

  //         return obj
  //       },
  //       {}
  //     )
  //   }

  //   return true
  // }

  componentDidMount() {
    const {
      getRccpResourcePeriodResponse: { IssueNames = [], ModificationCount },
      connectApi,
      lastRequest: { GetRccpResourcePeriodResponse: { Request: ResourceRequest = {} } = {} },
      settings: {
        modificationCount,
        resourceIssueSettings = [],
        resourceInfoSettings = [],
        //resourcePlanSettings = [],
        stateHandler: { resourcePlanShowAllState = false } = {},
        history: {
          componentState: {
            pushState: { IssueList, TableResource: { resourceDictionary = {} } = {} } = {}
          } = {}
        } = {}
      } = {},
      stateHandler,
      scrollPositions
    } = this.props

    const issueList = _.reduce(
      IssueNames,
      (arr, item, index) => {
        let findedElement = _.find(resourceIssueSettings, (i) => i.tableName === item) || {},
          { enable = true } = findedElement

        if (enable && !_.isEmpty(findedElement)) {
          findedElement.issueIndex = index
          arr.push(findedElement)
        }

        return arr
      },
      []
    )
    const resourceInfoList = _.filter(resourceInfoSettings, (item) => item.enable)

    let newState = {
      legendData: {
        issueList,
        resourceInfoList
      },
      ...(!_.isEmpty(IssueList) && { legendState: IssueList }),
      ...(!_.isEmpty(resourceDictionary) && { resourceDictionary: resourceDictionary })
    }

    if (resourcePlanShowAllState && ResourceRequest.productId !== -1) {
      stateHandler({ resourcePlanShowAllState: false })
    }

    this.setState(newState, () =>
      restoreScrollPos(this.resourcePlanRef, scrollPositions.resourcePlan.main)
    )

    // if (!_.isEqual(ModificationCount, modificationCount)) {
    //   ResourceRequest.rccpConfig.ModificationCount = 10000
    //   connectApi('GetRccpResourcePeriodResponse', ResourceRequest)
    // }
  }

  chartHandler(resourceIndex, periodIndex) {
    const { historyHandler } = this.props
    const { resourceDictionary } = this.state

    resourceDictionary[resourceIndex].currentResourcePeriod = periodIndex

    const newState = {
      ...{ resourceDictionary }
    }

    this.setState(newState)

    historyHandler({
      key: 'TableResource',
      state: newState
    })
  }

  getProductData(
    currentResourceIndex = 0,
    currentResourcePeriodIndex = 0,
    currentProductIndex = 0
  ) {
    let retobject = {}

    try {
      const {
        getRccpResourcePeriodResponse: {
          ResourcePeriods = {},
          Products = [],
          ProductPeriods = {},
          Periods = [],
          Resources = {}
        }
      } = this.props

      const {
        ResourcePeriodProducts = [],
        NumberOfShifts = 0,
        NumberOfAvailableShifts = 0,
        NumberOfEnabledShifts = 0
      } = ResourcePeriods[currentResourceIndex][currentResourcePeriodIndex] || {}
      const { ProductIndex = 0, PeriodIndex = 0 } = !_.isEmpty(ResourcePeriodProducts)
        ? ResourcePeriodProducts[currentProductIndex]
        : {}

      retobject = {
        ...{ ProductPeriod: ProductPeriods[ProductIndex][PeriodIndex] },
        ...{
          ResourcePeriod: {
            NumberOfShifts,
            ResourcePeriodProducts,
            NumberOfAvailableShifts,
            NumberOfEnabledShifts,
            ResourcePeriodProductCount: ResourcePeriodProducts.length
          }
        },
        ...{ Product: Products[ProductIndex], Products },
        ...{ Period: Periods[PeriodIndex] },
        ...{ ResourcePeriodProduct: ResourcePeriodProducts[currentProductIndex] },
        ...{ Resource: Resources[currentResourceIndex] },
        ...{ Key: { currentResourceIndex, currentResourcePeriodIndex, currentProductIndex } }
      }
    } catch (error) {
      retobject = {}
    }

    return retobject
  }

  productClickHandler(obj = {}, resourceIndex = 0, period = {}) {
    const { resourceDictionary = {} } = this.state
    const { ResourceIndex = 0, PeriodIndex = 0, ResourcePeriodProductItemIndex = 0 } = obj
    const currentResourceDictionary =
      resourceDictionary[ResourceIndex] || _.find(resourceDictionary, (value) => value.isOpen)

    /// Object empty ise birinci ürünü getirmeye çalışma form data boş gitmeli

    resourceDictionary[resourceIndex].period = period

    if (!_.isEmpty(obj)) {
      currentResourceDictionary.formProductData = this.getProductData(
        ResourceIndex,
        PeriodIndex,
        ResourcePeriodProductItemIndex
      )
      resourceDictionary[resourceIndex].isLeftActive = true
    } else {
      resourceDictionary[resourceIndex].isLeftActive = false
    }

    this.setState({ resourceDictionary })
  }

  handleShowAll() {
    const {
      connectApi,
      lastRequest: {
        GetRccpResourcePeriodResponse: {
          Request = {},
          Request: { initialProductId = -1 } = {}
        } = {}
      } = {},
      settings: { stateHandler: { resourcePlanShowAllState = false } = {} } = {},
      stateHandler
    } = this.props

    if (!resourcePlanShowAllState) {
      Request.productId = -1
      Request.rccpConfig.ModificationCount = 10000
      connectApi('GetRccpResourcePeriodResponse', Request)
      stateHandler({ resourcePlanShowAllState: true })
    } else {
      Request.productId = initialProductId
      Request.rccpConfig.ModificationCount = 10000
      connectApi('GetRccpResourcePeriodResponse', Request)
      stateHandler({ resourcePlanShowAllState: false })
    }
  }

  handleScroll = (scrollArea, event, resourceId) => {
    const { setScrollPos } = this.props
    const {
      target: { scrollTop, scrollLeft }
    } = event

    if (scrollArea === 'detailsTable') {
      setScrollPos({
        view: 'resourcePlan',
        scrollArea,
        scrollPos: {
          [resourceId]: { top: scrollTop, left: scrollLeft }
        }
      })
    } else {
      setScrollPos({
        view: 'resourcePlan',
        scrollArea,
        scrollPos: { top: scrollTop, left: scrollLeft }
      })
    }
  }

  render() {
    const {
      resourceDictionary,
      legendData: { issueList, resourceInfoList },
      legendState,
      legendState: { currentIssueIndex, currentProductInfoIndex, isProductInfo }
    } = this.state
    const {
      getRccpProductsResponse = {},
      getRccpResourcePeriodResponse,
      getRccpResourcePeriodResponse: {
        MaxRccpResourcePeriod: { IssueValues: MaxIssueValues = [] } = {},
        // MaxRccpResourcePeriodProduct = {},
        Periods = [],
        Resources = [],
        ResourcePeriods = {},
        Products = [],
        ProductPeriods = {}
      },
      connectApi,
      viewHandler,
      settings,

      settings: {
        //issueFilter,
        resourceIssueSettings,
        stateHandler: {
          resourcePlanShowAllState = false,
          currentMenuItem,
          currentMenuState
          // selectedParameter: {
          //     Index: SelectedParamIndex = 0
          // }
        },
        periodSettings: {
          displayOptions: { isYearVisible = true, isWeekVisible = true, isMonthVisible = true } = {}
        } = {}
      },
      lastRequest,
      lastRequest: {
        GetRccpResourcePeriodResponse: {
          Request: { productId = 0 }
        }
      },
      historyHandler,
      isAllowed = () => {},
      scrollPositions
    } = this.props

    const periodWidth = _.filter(Periods, (p) => p.Visible).length * 52
    const prodHeight =
      Resources.length * 52 +
      _.reduce(
        _.filter(resourceDictionary, (value) => value.isOpen),
        (res) => (res += 350),
        0
      )
    const { issueIndex = 0 } = !_.isEmpty(issueList) ? issueList[currentIssueIndex] || {} : {}

    let switchButtonProps = false
    if (resourcePlanShowAllState && productId === -1) {
      switchButtonProps = true
    }

    return (
      <div
        className={`rcc-wrp rccp-table-resource ${
          currentMenuItem === 'issues' && currentMenuState ? '-issue-filter' : ''
        }`}
      >
        <IssueList
          issueList={issueList}
          infoList={resourceInfoList}
          legendState={legendState}
          legendHandler={this.legendHandler}
          settings={settings}
          historyHandler={historyHandler}
          scrollPos={scrollPositions.resourcePlan.issueList}
          onScroll={(event) => this.handleScroll('issueList', event)}
        />

        <div
          className="shift-calendar-container w-100 h-100"
          ref={(element) => {
            if (element) {
              this.resourcePlanRef = element
            }
          }}
          onScroll={(event) => this.handleScroll('main', event)}
        >
          <header className="rcc-header">
            <div className="-header-left">
              <SwitchButton
                text={'Show All'}
                checked={switchButtonProps}
                handleOnChange={this.handleShowAll.bind(this, productId)}
              />
            </div>
            <div className="-period-ctn" ref="periodctn">
              <ul style={{ width: periodWidth }}>
                {_.map(Periods, (periodItem, periodIndex) => {
                  let {
                    Visible = false,
                    DisplayName: periodDisplayName,
                    Name: periodName
                  } = periodItem

                  const enabledDisplayNames = getEnabledDisplayNames(
                    [isYearVisible, isWeekVisible, isMonthVisible],
                    periodDisplayName,
                    periodName
                  )

                  return (
                    Visible && (
                      <li key={periodIndex}>
                        <span>{enabledDisplayNames}</span>
                      </li>
                    )
                  )
                })}
              </ul>
            </div>
          </header>
          <div className="rccp-table">
            <div className="rccp-left" style={{ height: prodHeight }}>
              <ul>
                {_.map(Resources, (resourceItem, resourceIndex) => {
                  let { Name, HasProduction = false, Id } = resourceItem
                  let {
                    currentResourceIndex = null,
                    currentResourcePeriod = 0,
                    isOpen = false,
                    isLeftActive = true,
                    period = {},
                    formProductData = {}
                  } = resourceDictionary[resourceIndex] || {}
                  formProductData = _.isEmpty(formProductData) ? {} : formProductData

                  return (
                    <li
                      className={`${!_.isNil(currentResourceIndex) && isOpen ? '-active' : ''}`}
                      key={resourceIndex}
                    >
                      <header
                        className="rowHeader"
                        onClick={() => {
                          if (currentResourceIndex !== resourceIndex) {
                            resourceDictionary[resourceIndex] = {
                              currentResourceIndex: resourceIndex,
                              currentResourcePeriod: currentResourcePeriod,
                              isOpen: true
                            }
                          } else {
                            delete this.state.resourceDictionary[resourceIndex]
                          }

                          let newState = {
                            ...{ resourceDictionary }
                          }

                          this.setState(newState)

                          historyHandler({
                            key: 'TableResource',
                            state: newState
                          })
                        }}
                      >
                        <i
                          className="slvy-ui-icon-caret-right -caretIndicator"
                          style={{ color: HasProduction ? '#00ffa8' : '' }}
                        ></i>
                        <div className="rowLabels">
                          <p>{Name}</p>
                        </div>
                        {/* <span className="slvy-ui-icon-circle-fill"></span> */}
                      </header>

                      {!_.isNil(currentResourceIndex) && isOpen && (
                        <div className="-inner-box">
                          <UpdateFormResource
                            data={formProductData}
                            getRccpResourcePeriodResponse={getRccpResourcePeriodResponse}
                            getRccpProductsResponse={getRccpProductsResponse}
                            viewHandler={viewHandler}
                            connectApi={connectApi}
                            settings={settings}
                            lastRequest={lastRequest}
                            isActive={isLeftActive}
                            period={period}
                            resource={resourceItem}
                            isAllowed={isAllowed}
                            scrollPos={scrollPositions.resourcePlan.detailsTable}
                            onScroll={(event) => this.handleScroll('detailsTable', event, Id)}
                          />
                        </div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="rccp-body" ref="rccpbody" style={{ height: prodHeight }}>
              <div style={{ width: periodWidth, height: prodHeight }}>
                {_.map(Resources, (resourceItem, resourceIndex) => {
                  let { currentResourceIndex = null, isOpen = false } =
                      resourceDictionary[resourceIndex] || {},
                    ResourcePeriodItem = ResourcePeriods[resourceIndex],
                    { Id: resourceId } = resourceItem
                  return (
                    <div
                      key={resourceIndex}
                      className={`-row ${
                        !_.isNil(currentResourceIndex) && isOpen ? '-active' : ''
                      }`}
                      style={{ width: periodWidth }}
                    >
                      <ul>
                        {_.map(Periods, (periodItem, periodIndex) => {
                          let ResourcePeriodItemObj = ResourcePeriodItem[periodIndex] || {},
                            { IssueValues = [] } = ResourcePeriodItemObj,
                            { Visible = false } = periodItem,
                            { color, tableName: issueTableName } =
                              resourceIssueSettings[currentIssueIndex] || {},
                            { format: formatType, tableName = 'Cover' } =
                              resourceInfoList[currentProductInfoIndex] || {},
                            tableValue = ResourcePeriodItemObj[tableName]

                          const issueValue = IssueValues[issueIndex]
                          const maxIssueValue = MaxIssueValues[issueIndex] || 0
                          const itemSizeIssue = getIssueSize(
                            issueValue,
                            maxIssueValue,
                            issueTableName
                          )

                          return (
                            Visible && (
                              <li
                                ref={`cell_${resourceIndex}_${periodIndex}`}
                                key={`${resourceIndex}-${periodIndex}`}
                              >
                                <div className="-cell">
                                  {!isProductInfo ? (
                                    itemSizeIssue > 0 && (
                                      <i
                                        style={{
                                          height: itemSizeIssue,
                                          width: itemSizeIssue,
                                          backgroundColor: color
                                        }}
                                      />
                                    )
                                  ) : (
                                    <b>{format(tableValue, formatType)}</b>
                                  )}
                                </div>
                              </li>
                            )
                          )
                        })}
                      </ul>
                      {!_.isNil(currentResourceIndex) && isOpen && (
                        <section className="-chart-box">
                          <ChartResource
                            resourcePeriod={ResourcePeriodItem}
                            resourceId={resourceId}
                            products={Products}
                            periods={Periods}
                            productPeriods={ProductPeriods}
                            productId={productId}
                            productClickHandler={this.productClickHandler}
                            connectApi={connectApi}
                            lastRequest={lastRequest}
                            chartHandler={this.chartHandler.bind(this, resourceIndex)}
                            viewHandler={viewHandler}
                            settings={settings}
                            resourceIndex={resourceIndex}
                            isAllowed={isAllowed}
                          />
                        </section>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    scrollPositions: state.scrollPositions
  }
}

const mapDispatchToProps = {
  setScrollPos
}

export default connect(mapStateToProps, mapDispatchToProps)(TableResource)
