import cx from 'classnames'
import { Link } from 'react-router-dom'
import { Permission } from '../../'
import { ICatalogNameProps } from './CatalogName.types'

const CatalogName = ({ className = '', name, environment, catalogId }: ICatalogNameProps) => {
  return (
    <div className={cx('left-bar__catalog-name', className)} data-testid="catalog-name">
      <span className="subject">{name}</span>
      <Permission has={['Catalog.Edit']}>
        <Link className="edit" to={`/${environment}/catalog/${catalogId}/edit`}>
          <i className="fa fa-pencil" />
        </Link>
      </Permission>
    </div>
  )
}

export default CatalogName
