import { ITooltipParams } from 'ag-grid-community'
import { getCellTooltip } from '../index'
import {
  cellDisplayRules as defaultCellDisplayRules,
  cellRendererParams as defaultCellRendererParams,
  validCellRule as defaultValidCellRule
} from '../../__tests__/mocks'

function getCellDisplayRules() {
  return defaultCellDisplayRules
}

function getParams() {
  return defaultCellRendererParams as ITooltipParams
}

function getValidCellRuleData() {
  return defaultValidCellRule
}

describe('AgGrid:Helpers:Tooltip:getCellTooltip', () => {
  let cellDisplayRules = getCellDisplayRules()
  let validCellRule = getValidCellRuleData()
  let params = getParams()

  beforeEach(() => {
    cellDisplayRules = getCellDisplayRules()
    validCellRule = getValidCellRuleData()
    params = getParams()
  })

  it('should return empty string if there is no valid cell rule tooltip', () => {
    params.value = 15
    const result = getCellTooltip({ params, cellDisplayRules })
    expect(result).toEqual('')
  })

  it('should return empty string if there is a valid cell rule but has not a cellTooltip defined', () => {
    params.value = validCellRule.value
    params.data.col1 = validCellRule.value
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { cellTooltip = '', ...rest } = validCellRule
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getCellTooltip({ params, cellDisplayRules: [rest] })
    expect(result).toEqual('')
  })

  it('should return validCellRules cellTooltip if there is valid cell display rule', () => {
    params.value = validCellRule.value
    params.data.col1 = validCellRule.value
    validCellRule.cellTooltip = 'John Done'
    const result = getCellTooltip({ params, cellDisplayRules: [validCellRule] })
    expect(result).toEqual(validCellRule.cellTooltip)
  })
})
