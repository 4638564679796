import { useState, useEffect, useMemo, ChangeEvent, FormEvent } from 'react'
import { fromJS } from 'immutable'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import {
  Form,
  FormControl,
  Button,
  InputGroup,
  FormLabel,
  ButtonToolbar,
  DropdownButton,
  Dropdown
} from 'react-bootstrap'
import { ColorResult, SketchPicker } from 'react-color'
import { slvyToast } from '@/components'
import diacritics from '@/utils/diacritics'
import getAdminForm, { getBgColorCmpStyle } from '@/utils/admin-container'
import { themes } from '@/elements/themes'
import { defaultForm, defaultColor } from './constants'
import { AdminContainerProps } from '@/elements/Container/Admin/Admin.types'
import {
  FormState,
  HandleChangeBtnTextKey,
  Id,
  Step,
  ThemeId,
  ValidationParams
} from './Admin.types'

const AdminContainer = (props: AdminContainerProps) => {
  const { propertyEditorFields, settings, onChangeSettings } = props

  const getInitialForm = useMemo(() => {
    return getAdminForm(propertyEditorFields, settings, defaultForm)
  }, [propertyEditorFields, settings])

  const [form, setForm] = useState<FormState>(getInitialForm)
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)

  const {
    ids,
    steps,
    previousText,
    nextText,
    cancelText,
    finishText,
    isControlledWithWorkflow,
    backgroundColor,
    theme
  } = form

  useEffect(() => {
    setForm(getInitialForm)
  }, [getInitialForm])

  const setInput = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      currentTarget: { value }
    } = event

    const updatedSteps: Step[] = fromJS(steps)
      .update(index, (u: Map<string, string>) => u.set('name', value))
      .toJS()

    setForm((prevForm) => ({ ...prevForm, steps: updatedSteps }))
  }

  const removeInput = (index: number) => {
    const updatedSteps: Step[] = fromJS(steps).delete(index).toJS()
    const updatedIds: Id[] = fromJS(ids).delete(index).toJS()

    setForm((prevForm) => ({ ...prevForm, steps: updatedSteps, ids: updatedIds }))
  }

  const addInput = () => {
    const arrSteps = _.toArray(steps)
    const updatedSteps: Step[] = fromJS(_.size(arrSteps) <= 0 ? [] : arrSteps)
      .push(fromJS({ name: '' }))
      .toJS()

    // it gets 8 digit id
    const id = uuidv4().split('-')[0]
    const updatedIds = [...ids, id]

    setForm((prevForm) => ({ ...prevForm, steps: updatedSteps, ids: updatedIds }))
  }

  const handleChangeTheme = (themeId: ThemeId) => {
    setForm((prevForm) => ({ ...prevForm, theme: themeId }))
  }

  const handleChangeBtnText = (
    btnKey: HandleChangeBtnTextKey,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { value }
    } = event

    setForm((prevForm) => ({ ...prevForm, [btnKey]: value }))
  }

  const handleChangeIsControlledWithWorkflow = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked }
    } = event

    setForm((prevForm) => ({ ...prevForm, isControlledWithWorkflow: checked }))
  }

  const handleResetBackground = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    setForm((prevForm) => ({ ...prevForm, backgroundColor: defaultColor }))
  }

  const handleChangeBackground = (event: ColorResult) => {
    const { rgb } = event

    setForm((prevForm) => ({ ...prevForm, backgroundColor: rgb }))
  }

  const trimValues = (formValues: Partial<FormState>) => {
    Object.entries(formValues).forEach(([key, value]) => {
      if (_.isString(value)) {
        // eslint-disable-next-line no-param-reassign
        formValues[key] = value.trim()
      }
    })

    return formValues
  }

  const isValid = (params: ValidationParams = {}) => {
    const { index, fieldName, validateForm = false } = params

    const requiredButtons = ['previousText', 'nextText']
    let shouldCheckBtnTexts = validateForm ? requiredButtons : []
    shouldCheckBtnTexts = !_.isUndefined(fieldName) ? [fieldName] : shouldCheckBtnTexts

    if (shouldCheckBtnTexts.length) {
      const isBtnTextsInValid = shouldCheckBtnTexts.some(
        (btnText) => !form[btnText] || form[btnText].trim().length === 0
      )
      if (isBtnTextsInValid) {
        return false
      }
      return true
    }

    const shouldCheckStepArr = validateForm ? steps : [steps[index as number]]
    if (shouldCheckStepArr.length) {
      const isStepInvalid = shouldCheckStepArr.some(
        (step) => !step || step.name.trim().length === 0
      )
      if (isStepInvalid) {
        return false
      }
      return true
    }

    return true
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const checkList = [
      'steps',
      'previousText',
      'nextText',
      'cancelText',
      'finishText',
      'isControlledWithWorkflow',
      'backgroundColor',
      'theme'
    ]

    const propsValues = _.pick(settings, checkList)

    // This will save to the mongo be carefully while modify state
    let formValues = _.pick(form, checkList)
    formValues = trimValues(formValues)

    if (_.isEqual(propsValues, formValues)) {
      slvyToast.error({ message: 'You must have changes to save!' })
      return null
    }

    if (isValid({ validateForm: true })) {
      const updatedIds = ids.map((id, idIndex) => {
        // uuid generates 8 character id
        if (id.length === 8) {
          const text = diacritics.removeAndLowerCase(steps[idIndex].name)
          id = `${text}_${id}`
        }
        return id
      })

      setForm((prevForm) => ({ ...prevForm, ids: updatedIds }))

      onChangeSettings({
        ...propsValues,
        ...formValues,
        ids: updatedIds
      })
      slvyToast.success({ message: 'Your changes have been saved successfully!' })
    }
    return null
  }

  const style = getBgColorCmpStyle(backgroundColor)

  const { name: themeName = '' } = themes[theme] || themes[0]

  return (
    <Form className="containerForm" onSubmit={handleSubmit}>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm mb-0">
          <Form.Check
            checked={isControlledWithWorkflow}
            className="mh-auto mb-0 form-check-sm"
            id="isControlledWithWorkflow"
            label="Control with Workflow"
            type="checkbox"
            onChange={handleChangeIsControlledWithWorkflow}
          />
        </FormLabel>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block">Themes</FormLabel>
        <ButtonToolbar>
          <DropdownButton
            id="wizard-container-admin-dropdown-theme"
            size="sm"
            title={themeName}
            variant="outline-dark"
            onSelect={handleChangeTheme}
          >
            {themes.map(({ name, id }, key) => {
              const itemSettings = {
                key,
                eventKey: id,
                ...(id === theme && { active: true })
              }

              return <Dropdown.Item {...itemSettings}>{name}</Dropdown.Item>
            })}
          </DropdownButton>
        </ButtonToolbar>
      </Form.Group>
      <Form.Group className="position-relative mb-2">
        <FormLabel className="fs-sm d-block">Background Color</FormLabel>
        {/* TODO: replace with SlvyColorPicker */}
        <div style={style.swatch} onClick={() => setDisplayColorPicker(true)}>
          <div style={style.color} />
          <div style={style.swatch.clear}>
            <i
              style={style.swatch.clear.icon}
              className="fa fa-times"
              onClick={handleResetBackground}
            />
          </div>
        </div>
        {displayColorPicker ? (
          <div style={{ ...style.popover, zIndex: 9999 }}>
            <div style={style.cover} onClick={() => setDisplayColorPicker(false)} />
            <SketchPicker color={backgroundColor} onChange={handleChangeBackground} />
          </div>
        ) : null}
      </Form.Group>
      <FormLabel className="fs-sm d-block">Steps</FormLabel>
      {_.map(steps, (step, index) => {
        return (
          <div key={index} className="mb-2">
            <InputGroup>
              <FormControl
                required
                isInvalid={!isValid({ index })}
                maxLength={100}
                placeholder={`${index + 1}. Step name`}
                size="sm"
                type="text"
                value={step.name}
                onChange={(event) => setInput(index, event)}
              />
              <Button size="sm" variant="danger" onClick={() => removeInput(index)}>
                <i className="fa fa-minus" />
              </Button>
              <Form.Control.Feedback type="invalid">Step name is required!</Form.Control.Feedback>
            </InputGroup>
          </div>
        )
      })}
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block">Previous</FormLabel>
        <FormControl
          required
          isInvalid={!isValid({ fieldName: 'previousText' })}
          maxLength={100}
          size="sm"
          type="text"
          value={previousText}
          onChange={(event) => handleChangeBtnText('previousText', event)}
        />
        <Form.Control.Feedback type="invalid">
          Previous button text is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block">Next</FormLabel>
        <FormControl
          required
          isInvalid={!isValid({ fieldName: 'nextText' })}
          maxLength={100}
          size="sm"
          type="text"
          value={nextText}
          onChange={(event) => handleChangeBtnText('nextText', event)}
        />
        <Form.Control.Feedback type="invalid">Next button text is required!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block">Cancel</FormLabel>
        <FormControl
          maxLength={100}
          size="sm"
          type="text"
          value={cancelText}
          onChange={(event) => handleChangeBtnText('cancelText', event)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block">Finish</FormLabel>
        <FormControl
          maxLength={100}
          size="sm"
          type="text"
          value={finishText}
          onChange={(event) => handleChangeBtnText('finishText', event)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Button size="sm" variant="outline-success" onClick={addInput}>
          <i className="fa fa-plus" /> Add New
        </Button>
      </Form.Group>
      <Button size="sm" type="submit" variant="success">
        <i className="fa fa-save" /> Save
      </Button>
    </Form>
  )
}

export default AdminContainer
