Ext.define('Spui.util.ShowErrorBox', {
  extend: 'Ext.Base',
  singleton: true,
  record: function (operation) {
    var actionName, statusText, responseData

    if (operation.error && operation.error.response.timedout) {
      actionName = operation.error.response.request.action
      statusText =
        'We have problems performing ' +
        actionName +
        '. Can you please try again? If problem persist, please send the error detail to'
      var a = operation.error.getResponse().request
      responseData = {
        url: a.url,
        action: a.action,
        method: a.method
      }
    } else if (operation.error) {
      actionName = operation.error.response.request.action
      statusText = operation.error.response.statusText
      //responseData = Ext.decode(operation.error.response.responseText);
      responseData = operation.error.response.responseJson
    }

    Spui.ux.ErrorBox.show({
      title: '<b>' + actionName.capitalize() + '</b> action failed',
      message: responseData.message,
      buttons: Ext.Msg.YESNO,
      fn: this.showResult,
      width: 600,
      icon: Ext.Msg.INFO,
      copytext: JSON.stringify(responseData, undefined, 2),
      details: '<pre>' + JSON.stringify(responseData, undefined, 2) + '</pre>'
    })
  },

  requestexception: function (proxy, response, options) {
    if (response.aborted) {
      return
    }

    var actionName = response.request.action ? response.request.action : 'load',
      statusText = response.statusText,
      responseData = response.responseJson
    responseData = responseData ? responseData : response.responseText
    var pageName = proxy.pageName ? proxy.pageName : ''
    statusText = 'An error occurred! Please use the ticket ID to contact our support.'

    if (response.timedout) {
      statusText =
        'We have problems performing ' +
        actionName +
        '. Can you please try again? If problem persist, please send the error detail to'
      var a = response.request
      responseData = { url: a.url, action: a.action, method: a.method }
    }
    Spui.ux.ErrorBox.show({
      title: 'Action failed',
      message: responseData.message,
      buttons: Ext.Msg.YESNO,
      fn: this.showResult,
      width: 600,
      icon: Ext.Msg.INFO,
      copytext: JSON.stringify(responseData, undefined, 2),
      details: '<pre>' + JSON.stringify(responseData, undefined, 2) + '</pre>'
    })
  },
  ajaxFailure: function (response, opts, actionName) {
    if (response.aborted) {
      return
    }

    var statusText = response.statusText,
      responseData = response.responseJson
    responseData = responseData ? responseData : response.responseText
    statusText = 'An error occurred! Please use the ticket ID to contact our support.'
    var pageName = ''

    if (response.timedout) {
      statusText =
        'We have problems performing ' +
        actionName +
        '. Can you please try again? If problem persist, please send the error detail to'
      var a = response.request
      responseData = { url: a.url, action: a.action, method: a.method }
    }
    Spui.ux.ErrorBox.show({
      title: 'Action failed',
      message: responseData.message,
      buttons: Ext.Msg.YESNO,
      fn: this.showResult,
      width: 600,
      icon: Ext.Msg.INFO,
      copytext: JSON.stringify(responseData, undefined, 2),
      details: '<pre>' + JSON.stringify(responseData, undefined, 2) + '</pre>'
    })
  },
  showResult: function (btn, text, cmp) {
    console.log(cmp)
    copyTextToClipboard('\n' + cmp.message)
  }
})
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

function copyTextToClipboard(text) {
  var textArea = document.createElement('textarea')

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed'
  textArea.style.top = 0
  textArea.style.left = 0

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em'
  textArea.style.height = '2em'

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0

  // Clean up any borders.
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent'

  textArea.value = text

  document.body.appendChild(textArea)

  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log('Copying text command was ' + msg)
  } catch (err) {
    console.log('Oops, unable to copy')
  }

  document.body.removeChild(textArea)
}
