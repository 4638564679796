Ext.define('MDM.Btl.dd.MassApply.Dialog', {
  extend: 'Ext.window.Window',
  resize: false,
  modal: true,
  title: 'Apply BTLs',
  viewModel: {
    formulas: {
      isButtonDisabled: {
        bind: {
          selection: '{myGrid.selection}',
          gridSelModel: '{myGrid.selModel}'
        },
        get: function (obj) {
          return obj.gridSelModel.getCount() > 0
        }
      }
    },
    data: {
      record: null,
      readOnly: false,
      selection: false
    }
  },
  iconCls: 'fa fa-check-square-o',
  layout: 'fit',
  bodyStyle: {
    backgroud: '#fff'
  },
  bodyPadding: 2,
  defaultListenerScope: true,
  height: '90%',
  width: '90%',
  tbar: [
    '->',
    {
      text: 'Export',
      iconCls: 'fa fa-download',
      handler: 'exportTo'
    },
    '-',
    {
      iconCls: 'fa fa-check',
      bind: {
        disabled: '{!isButtonDisabled}'
      },
      text: 'Apply Selected BTLs',
      handler: 'onMassApply'
    }
  ],
  onMassApply: function () {
    var me = this
    var selectedRecord = this.grid.getSelection()
    selectedRecord.forEach(function (rec) {}, this)
    var parentId = []
    selectedRecord.forEach(function (record) {
      if (!record.get('IsApplied')) {
        parentId.push(record.get('ID'))
      }
    }, this)
    var parentIds = parentId.join()
    this.setLoading('Applying changes please wait')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_Apply',
      timeout: 360000,
      params: {
        parentId: parentIds
      },
      success: function (response) {
        var res = Ext.decode(response.responseText, true)
        if (!res || res.length < 0) {
          Ext.MessageBox.alert('Error', 'Server Error')
        } else if (res[0].Success === '1') {
          me.setLoading(false)
          me.grid.store.load()
        } else {
          Ext.MessageBox.alert('Error', res[0].ErrorMessage)
        }
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        Ext.MessageBox.alert('Error', 'Server Error')
      }
    })
  },
  exportTo: function (btn) {
    var me = this
    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }
    var newcfg = Ext.merge(
      {
        title: 'Material list',
        fileName: 'GridExport' + '.' + cfg.ext
      },
      btn.cfg
    )
    me.grid.saveDocumentAs(newcfg)
  },
  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },
  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },
  initComponent: function () {
    var me = this
    me.buildStore()
    var selModel = {
      selType: ''
    }
    if (me.isEditable) {
      selModel = {
        selType: 'checkboxmodel'
      }
    }

    me.grid = Ext.create({
      xtype: 'dd-btl-edit-grid-other',
      publishes: 'selModel',
      header: false,
      readOnly: false,
      isEditable: me.isEditable,
      reference: 'myGrid',
      selModel: selModel,
      hideReadOnlyCol: false,
      startCol: 0,
      store: this.store,
      border: true,
      listeners: {}
    })
    me.items = [me.grid]
    me.callParent()
  },
  onResizeLock: Ext.emptyFn,
  showEditPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var store = this.store
    this.store.suspendAutoSync()
    var isReadOnly = false
    var win = Ext.create('MDM.Btl.dialog.EditForm', {
      record: record,
      readOnly: isReadOnly
    }).show()
    win.on('save', function () {
      store.resumeAutoSync()
      store.sync()
    })
  },
  buildStore: function () {
    this.store = Ext.create('Ext.data.Store', {
      model: 'MDM.Btl.model.EditModel',
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/Mass_BTL'
      },
      autoLoad: false
    })
  }
})
