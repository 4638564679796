export default {
  title: 'Key-Value List',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'Key-Value-List',
  description: 'to display a dynamic list of key-value pairs',
  iconClass: 'fa-align-center',
  color: 'lazur-bg',
  group: 'Data Manipulation',
  support: {
    maximizable: true,
    editable: true
  },
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: false
  }
}
