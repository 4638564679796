Ext.define('Spui.view.tracking.leftside.Main', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-tracking-leftside-main',
  defaultListenerScope: true,
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },

  bodyPadding: 5,
  tools: [
    {
      type: 'refresh',
      tooltip: tt('refresh'),
      // hidden:true,
      handler: 'onRefrehTracking'
    }
  ],

  defaults: {
    frame: false
    //  bodyPadding: 10
  },
  onmaximize2: function () {},
  items: [
    {
      xtype: 'spui-view-tracking-leftside-dataview-main',
      header: false,
      border: true,
      height: 105,
      margin: '5 0 5 0',
      style: {
        background: '#fff'
        // 'border': '1px solid #ccc'
      }
    },

    {
      flex: 1,
      // maximizable: true,
      title: 'deneme',

      border: true,
      margin: '5 0 0 0',
      xtype: 'spui-view-tracking-leftside-grid-main',
      gridType: 'MO_NOT_PUBLISHED',
      reference: 'gridmain',
      bind: {
        store: '{SpotListStore}'
      }
    }
  ],
  onmaximize2: function () {
    var setRec = this.getDataViewRec(),
      code = setRec.get('code')
    var settings = code || '({ })'
    settings = Adm.util.Beautify['javascript'](code)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      value: code,
      height: 100,
      maximized: true,
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        // enableLiveAutocompletion: false,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onSave, this)
  }
})
