Ext.define('MDM.src.view.DPIAdd.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.dpiadd-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      record = view.record
    viewModel.set('record', record)
    var DPIMatStore = viewModel.getStore('DPIMatStore')
    DPIMatStore.getProxy().setExtraParam('DPIFamilyID', record.get('DPIFamilyID'))
    DPIMatStore.load()
  },
  onBeforeDrop: function (node, data, overModel, dropPosition, dropHandlers) {
    var me = this,
      records = data.records,
      viewModel = me.getViewModel()
    var DPIMatStore = viewModel.getStore('DPIMatStore')
    records.forEach(function (record) {
      var newRec = Ext.create('MDM.src.view.BomEdit.model.BomModel', {
        MaterialID: record.get('MaterialID'),
        MaterialCode: record.get('MaterialCode'),
        MaterialDescription: record.get('MaterialDescription'),
        DPIFamilyID: this.getViewModel().get('record').get('DPIFamilyID'),
        GU: record.get('GU')
      })
      DPIMatStore.add(newRec)
    }, this)
    dropHandlers.cancelDrop()
  },
  onSearch: function (field, value) {
    var me = this,
      store = me.getViewModel().getStore('searchStore')
    if (value.length > 2) {
      store.getProxy().setExtraParam('srckey', value)
      store.loadPage(1)
    }
  },
  addToBom: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      viewModel = me.getViewModel(),
      DPIMatStore = me.getViewModel().getStore('DPIMatStore'),
      newRec
    newRec = Ext.create('MDM.src.view.DPIAdd.Panel.DPIMatList', {
      MaterialID: record.get('MaterialID'),
      MaterialCode: record.get('MaterialCode'),
      ComponentID: record.get('MaterialID'),
      MaterialDescription: record.get('MaterialDescription'),
      DPIFamilyID: this.getViewModel().get('record').get('DPIFamilyID'),
      GU: record.get('GU')
    })
    DPIMatStore.add(newRec)
    grid.store.remove(record)
  },
  onSaveBtnClick: function () {
    var me = this
    var DPIMatStore = me.getViewModel().getStore('DPIMatStore')
    DPIMatStore.sync({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        me.getView().close()
      },
      failure: function (batch, opts) {}
    })
  }
})
