import React, { Component } from 'react'
import Handlebars from 'handlebars'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '../../BasePlugin'
import { StyledHtmlTemplate } from './htmlTemplate'
import './index.scss'

Handlebars.registerHelper('split', (str = '', ln) => {
  const { hash: { sep = '|' } = {} } = ln

  const arr = str.split(sep)

  if (ln && arr.length >= ln) {
    return arr[ln]
  }

  if (typeof ln.fn === 'function') {
    return arr.map((item) => ln.fn(item)).join('')
  }
})

Handlebars.registerHelper('eq', function (arg1, arg2, options) {
  return arg1 == arg2 ? options.fn(this) : options.inverse(this)
})

class HtmlTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      singleRecord: {}
    }

    this.generateHtmlFromConfig = this.generateHtmlFromConfig.bind(this)
    this.clickEvent = this.clickEvent.bind(this)
  }

  clickCallback(element) {
    const { dataset: { event: field = '', eventname, eventName } = {} } = element

    const ev = eventname || eventName

    const _f = this[`handleClick_${ev}`]

    if (ev && typeof _f === 'function') {
      _f({ field })
    }
  }

  getHtmlTemplate = (html, rows) => {
    try {
      return Handlebars.compile(html)({ rows })
    } catch (error) {
      return '<div>Please Check Your Template</div>'
    }
  }

  generateHtmlFromConfig() {
    const {
      settings: {
        config: {
          settings: { specificTemplate = '', customStyleTemplate = '', htmlTemplate = '' } = {}
        } = {}
      } = {},
      pluginData: result = [],
      getFormattedValue
    } = this.props

    const { singleRecord } = this.state

    let styleTemplate = ''

    switch (specificTemplate) {
      case 'primary':
        styleTemplate = ''
        break
      default:
        break
    }

    let newResult = _.map(
      result,
      (row) => {
        return _.transform(
          row,
          (r, col, colName) => {
            r[colName] = getFormattedValue(colName, col, null, row)
          },
          {}
        )
      },
      {}
    )

    if (!_.isEmpty(singleRecord)) {
      if (_.size(newResult) === 0) {
        newResult.push(singleRecord)
      } else {
        newResult = _.transform(
          newResult,
          (result, row) => {
            result.push({ ...row, ...singleRecord })
          },
          []
        )
      }
    }

    const template = this.getHtmlTemplate(htmlTemplate, newResult)
    return [`${template}`, `${styleTemplate} ${customStyleTemplate}`]
  }

  handleSetRecord(params) {
    this.setState({ singleRecord: { ...params } })
  }

  componentDidMount() {
    const { props } = this
    const {
      settings: {
        config: {
          setRecordMethodParams: { parameters: setRecordParams = [] } = {},
          setEventMethodParams: { parameters: setEventParams = [] } = {}
        } = {}
      } = {}
    } = props

    const methodParams = _.map(setRecordParams, (setRecordParam) => {
      return { name: setRecordParam, type: PluginTypes.string }
    })

    this.props.registerMethod({
      key: 'setRecord',
      fn: this.handleSetRecord.bind(this),
      args: methodParams
    })

    _.each(setEventParams, (item) => {
      this[`handleClick_${item}`] = this.props.registerEvent({
        key: `click_${item}`,
        fn: this.clickEvent,
        returnTypes: { field: PluginTypes.string }
      })
    })
  }

  clickEvent(obj) {
    return obj
  }

  componentDidUpdate() {
    const customEvents = document.querySelectorAll(`[data-id="${this.props.id}"] [data-event]`)

    if (!_.isEmpty(customEvents)) {
      _.each(customEvents, (item) => {
        const { eventBinded = 'false' } = item.dataset

        if (eventBinded === 'false') {
          item.addEventListener('click', this.clickCallback.bind(this, item, false))
          item.dataset.eventBinded = 'true'
        }
      })
    }
  }

  render() {
    const {
      id = '',
      size = {},
      settings: {
        config: {
          settings: {
            backgroundcolor = '',
            bindContainerHeight = false,
            styleAffectsWholePage = false
          } = {}
        } = {}
      } = {}
    } = this.props

    const [html, customStyle] = this.generateHtmlFromConfig()

    const wrapperStyle = { height: size.height, backgroundColor: backgroundcolor }
    const containerStyle = bindContainerHeight ? { height: 'inherit' } : {}

    return (
      <div className="html-template-container" data-id={id} style={wrapperStyle}>
        {styleAffectsWholePage ? (
          <div
            dangerouslySetInnerHTML={{ __html: `<style>${customStyle}</style> ${html}` }}
            style={containerStyle}
          />
        ) : (
          <StyledHtmlTemplate
            style={containerStyle}
            customStyle={customStyle}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  id: props.id,
  size: props.size,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(HtmlTemplate, selectConnectorProps)
