Ext.define('po.model.PromotionDetailModel', {
  extend: 'po.model.Base',
  entityName: 'po/promotion',
  fields: [
    { name: 'urunKodu', type: 'string' },
    { name: 'PromosyonAciklama', type: 'string' },
    { name: 'BaslangicTarihi', type: 'string' },
    { name: 'BitisTarihi', type: 'string' }
  ]
})
