import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type CreateLoadModalState = {
  isVisible: boolean
}

const initialState: CreateLoadModalState = {
  isVisible: false
}

const createLoadModalSlice = createSlice({
  name: 'createLoadModal',
  initialState,
  reducers: {
    show(state) {
      state.isVisible = true
    },
    hide(state) {
      state.isVisible = false
    }
  }
})

export default createLoadModalSlice
export type { CreateLoadModalState }
