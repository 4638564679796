export default {
  title: 'Ag-Pivot',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'AgPivot',
  description: 'new version of data pivot',
  iconClass: 'fa-circle',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Data Manipulation',
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: false
  }
}
