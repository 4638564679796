Ext.define('po.model.ZeroInventoryModel', {
  extend: 'po.model.Base',
  entityName: 'po/zeroinventory',
  fields: [
    { name: 'MagazaKodu', type: 'string' },
    { name: 'MagazaAdi', type: 'string' },
    { name: 'Envanter', type: 'float' },
    { name: 'Statu', type: 'string' }
  ]
})
