import { OverlayTrigger, Popover } from 'react-bootstrap'
import cx from 'classnames'
import Wizard from '../Wizard'
import styles from './index.module.scss'

function getOverlay({ className, placement, show, wizardViews }) {
  return (
    <Popover
      className={cx(styles.wizardPopover, className)}
      id={`popover-positioned-scrolling-${placement}`}
    >
      <Wizard show={show} views={wizardViews} />
    </Popover>
  )
}

export default function WizardPopover({
  children,
  className,
  placement,
  rootClose,
  show,
  trigger,
  wizardViews,
  onClose
}) {
  return (
    <OverlayTrigger
      overlay={getOverlay({ className, placement, show, wizardViews })}
      placement={placement}
      rootClose={rootClose}
      trigger={trigger}
      onExit={onClose}
    >
      {children}
    </OverlayTrigger>
  )
}
