import { TemplateCellRenderer } from '../index'
import SummaryCellRendererProps from './SummaryCellRenderer.types'

const overwrittenAggFuncs = ['bool count', 'date count', 'date min', 'date max']
// TODO: Create a ticket on ag-grid.zendesk platform
// When using custom agg funcs, props do not have 'valueFormatted', so we have to "format" value and
// pass value as valueFormatted

// Value is an object when using below cell renderer(built-in)
const builtInCellRenderers = ['agCheckboxCellRenderer']

const SummaryCellRenderer = (props: SummaryCellRendererProps) => {
  const {
    colDef: { aggFunc = '', cellRenderer = '' } = {},
    value,
    valueFormatted,
    template
  } = props

  const isFullObject = value !== null && typeof value === 'object' && 'value' in value

  const shouldGetFromObject =
    overwrittenAggFuncs.some((overwrittenAggFunc) => overwrittenAggFunc === aggFunc) ||
    builtInCellRenderers.some((builtInCellRenderer) => builtInCellRenderer === cellRenderer)

  let newValueFormatted = valueFormatted

  if (shouldGetFromObject) {
    newValueFormatted = isFullObject ? (value.value as string) : (value as string)
  }

  const isValueFormattedUndefined =
    typeof newValueFormatted === 'undefined' || newValueFormatted === 'undefined'

  if (!template) {
    if (!aggFunc || isValueFormattedUndefined) {
      return ''
    }
  }

  return (
    <>
      {template ? (
        <TemplateCellRenderer {...props} summaryTemplate={template} />
      ) : (
        <strong>{newValueFormatted}</strong>
      )}
    </>
  )
}

export default SummaryCellRenderer
