import React from 'react'
import ScenarioSubList from './scenario-sub-list'
import ScenarioHeader from './scenarioHeader'

const ScenarioSelect = (props) => {
  const onScenarioSelect = function ($selectedScenario) {
    const { selectedScenario: { Id } = {} } = props

    const result = Id === $selectedScenario.Id ? {} : $selectedScenario

    props.onScenarioSelect(result)
  }

  const { data = [], selectedScenario = {}, mode = '' } = props

  return (
    <div className="-scenario-tree-ctn">
      <ScenarioHeader />
      <div className="slvy-ui -grid-body al-list-single">
        <ScenarioSubList
          data={data}
          mode={mode}
          selectedScenario={selectedScenario}
          onScenarioSelect={onScenarioSelect.bind(this)}
        />
      </div>
    </div>
  )
}

export default ScenarioSelect
