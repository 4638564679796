/* global Ext */

export default (grid) => {
  const { props: { settings: { config: { grid: { dismissDelay = 5000 } = {} } = {} } = {} } = {} } =
    grid

  Ext.define(null, {
    override: 'Ext.grid.filters.filter.Date',
    alias: 'grid.filter.date',
    convertDateOnly: function (v) {
      // ***************** Solvoyo Added Code *****************
      if (!(v instanceof Date)) {
        v = new Date(v)
      }
      // *******************************************************

      var result = null
      if (v) {
        result = Ext.Date.clearTime(v, true).getTime()
      }
      return result
    }
  })

  Ext.define(null, {
    override: 'Ext.grid.column.Column',
    setMenuActive: function (menu) {
      // Called when the column menu is activated/deactivated.
      // Change the UI to indicate active/inactive menu
      this.activeMenu = menu
      /************************   Solvoyo Added Code   *********************/
      // this.titleEl can be null
      if (this.titleEl) {
        /************************   Solvoyo Added Code   *********************/
        this.titleEl[menu ? 'addCls' : 'removeCls'](this.headerOpenCls)
      }
    }
  })

  Ext.define(null, {
    override: 'Ext.grid.filters.filter.List',
    getOptionsFromStore: function (store) {
      let filterOption = 0
      const me = this
      // eslint-disable-next-line no-unused-vars
      let data = store.getData()
      const map = {}
      let ret = []
      const dataIndex = me.dataIndex
      const labelIndex = me.labelIndex
      let recData
      let idValue
      let labelValue

      if (store.isFiltered() && !store.remoteFilter) {
        data = data.getSource()
      }

      // ***************** Solvoyo Added Code *****************
      const columnConfig = _.find(grid.props.settings.config.columns, { fieldName: dataIndex })
      let showListMaximum = 20

      if (columnConfig) {
        showListMaximum = columnConfig.filtering.showListMaximum
      }

      const { data: { items: [storeItem = {}] = [] } = {} } = store
      const isStoreItem = !_.isEmpty(storeItem) && storeItem.get
      const strFilterList = isStoreItem ? storeItem.get(`${dataIndex}_FilterValue`) : ''
      const filterListField = !_.isEmpty(strFilterList) ? _.split(strFilterList, '||') : []

      if (filterListField.length > 0) {
        ret = _.map(filterListField, (item) => {
          item = _.isNaN(_.toNumber(item)) ? item : _.toNumber(item)
          return [item, item]
        })
        // ******************************************************
      } else {
        // Use store type agnostic each method.
        // TreeStore and Store implement this differently.
        // In a TreeStore, the items array only contains nodes
        // below *expanded* ancestors. Nodes below a collapsed ancestor
        // are removed from the collection. TreeStores walk the tree
        // to implement each.
        store.each(
          function (record) {
            recData = record.data
            idValue = recData[dataIndex]
            labelValue = recData[labelIndex]

            if (labelValue === undefined) {
              labelValue = idValue
            }

            // TODO: allow null?
            // if ((allowNull || !Ext.isEmpty(value)) && !map[strValue1]) {
            if (!map[idValue] && filterOption < showListMaximum) {
              map[idValue] = 1
              ret.push([idValue, labelValue])
              filterOption++
            }
          },
          null,
          {
            filtered: true, // Include filtered out nodes.
            collapsed: true // Include nodes below collapsed ancestors.
          }
        )
      }
      return _.sortBy(ret, ['1', '0'])
    },
    setValue: function () {
      let me = this,
        items = me.menu.items,
        value = [],
        i,
        len,
        checkItem

      const listFilterCheckbox = grid.props.settings.config.grid.listFilterCheckbox
      // The store filter will be updated, but we don't want to recreate the list store or the menu items in the
      // onDataChanged listener so we need to set this flag.
      // It will be reset in the onDatachanged listener when the store has filtered.
      me.preventDefault = true

      for (i = 0, len = items.length; i < len; i++) {
        checkItem = items.getAt(i)

        if (checkItem.checked) {
          value.push(checkItem.value)
        }
      }
      // Only update the store if the value has changed
      if (!Ext.Array.equals(value, me.filter.getValue())) {
        me.filter.setValue(value)
        len = value.length

        if (len && me.active) {
          me.updateStoreFilter()
        } else if (!listFilterCheckbox) {
          //   // ***************** Solvoyo Added Code *****************
          // TODO it can be specified by paging grids.
          //   /** listFilterCheckbox added to prevent applying filters immediately. */
          if (me) {
            me.setActive(!!len)
          }
          //   // *******************************************************
        }
      }
    }
  })

  // Do not show refresh data button in paging toolbar
  Ext.define(null, {
    override: 'Ext.toolbar.Paging',
    getPagingItems: function () {
      var me = this

      var inputListeners = {
        scope: me,
        blur: me.onPagingBlur
      }

      inputListeners[Ext.supports.SpecialKeyDownRepeat ? 'keydown' : 'keypress'] =
        me.onPagingKeyDown

      return [
        {
          itemId: 'first',
          tooltip: me.firstText,
          overflowText: me.firstText,
          iconCls: Ext.baseCSSPrefix + 'tbar-page-first',
          disabled: true,
          handler: me.moveFirst,
          scope: me
        },
        {
          itemId: 'prev',
          tooltip: me.prevText,
          overflowText: me.prevText,
          iconCls: Ext.baseCSSPrefix + 'tbar-page-prev',
          disabled: true,
          handler: me.movePrevious,
          scope: me
        },
        '-',
        me.beforePageText,
        {
          xtype: 'numberfield',
          itemId: 'inputItem',
          name: 'inputItem',
          cls: Ext.baseCSSPrefix + 'tbar-page-number',
          allowDecimals: false,
          minValue: 1,
          hideTrigger: true,
          enableKeyEvents: true,
          keyNavEnabled: false,
          selectOnFocus: true,
          submitValue: false,
          // mark it as not a field so the form will not catch it when getting fields
          isFormField: false,
          width: me.inputItemWidth,
          margin: '-1 2 3 2',
          listeners: inputListeners
        },
        {
          xtype: 'tbtext',
          itemId: 'afterTextItem',
          html: Ext.String.format(me.afterPageText, 1)
        },
        '-',
        {
          itemId: 'next',
          tooltip: me.nextText,
          overflowText: me.nextText,
          iconCls: Ext.baseCSSPrefix + 'tbar-page-next',
          disabled: true,
          handler: me.moveNext,
          scope: me
        },
        {
          itemId: 'last',
          tooltip: me.lastText,
          overflowText: me.lastText,
          iconCls: Ext.baseCSSPrefix + 'tbar-page-last',
          disabled: true,
          handler: me.moveLast,
          scope: me
        }
      ]
    }
  })

  Ext.define(null, {
    override: 'Ext.data.field.Integer',
    sortType: function (s) {
      // This function originally returns infinity for null values
      // But we want null values be treated as the smallest numbers
      // Therefore we changed this function to return minus infinity
      if (typeof s === 'number') {
        if (s == null || Number.isNaN(s)) {
          s = -Infinity
        }
      } else {
        if (s == null) {
          s = ''
        }
      }

      return s
    }
  })

  Ext.define(null, {
    override: 'Ext.tip.ToolTip',
    dismissDelay,

    getAlignRegion: function () {
      var me = this,
        anchorEl = me.anchorEl,
        align = me.getAnchorAlign(),
        overlap,
        alignSpec,
        target,
        mouseOffset = me.mouseOffset

      // ***************** Solvoyo Added Code *****************
      // classname is given to show tooltips in senchagrid when they are in popup container
      me.el.addCls('increased-zindex q-tip-nowrap')
      // *******************************************************

      if (!me.anchorSize) {
        anchorEl.addCls(Ext.baseCSSPrefix + 'tip-anchor-top')
        anchorEl.show()
        me.anchorSize = new Ext.util.Offset(
          anchorEl.getWidth(false, true),
          anchorEl.getHeight(false, true)
        )
        anchorEl.removeCls(Ext.baseCSSPrefix + 'tip-anchor-top')
        anchorEl.hide()
      }

      // Target region from the anchorTarget element unless trackMouse set
      if ((me.anchor || me.align) && me.anchorToTarget && !me.trackMouse) {
        target = me.currentTarget.getRegion()
      }

      // Here, we're either trackMouse: true, or we're not anchored to the target
      // element, so we should show offset from the mouse.
      // If we are being shown programatically, use 0, 0
      else {
        target = me.pointerEvent
          ? me.pointerEvent
              .getPoint()
              .adjust(
                -Math.abs(mouseOffset[1]),
                Math.abs(mouseOffset[0]),
                Math.abs(mouseOffset[1]),
                -Math.abs(mouseOffset[0])
              )
          : new Ext.util.Point()
        if (!me.anchor) {
          overlap = true
          if (mouseOffset[0] > 0) {
            if (mouseOffset[1] > 0) {
              align = 'tl-br'
            } else {
              align = 'bl-tr'
            }
          } else {
            if (mouseOffset[1] > 0) {
              align = 'tr-bl'
            } else {
              align = 'br-tl'
            }
          }
        }
      }
      alignSpec = {
        align: me.convertPositionSpec(align),
        axisLock: me.axisLock,
        target: target,
        overlap: overlap,
        offset: me.targetOffset,
        inside: me.constrainPosition
          ? me.constrainTo || Ext.getBody().getRegion().adjust(5, -5, -5, 5)
          : null
      }

      if (me.anchor) {
        alignSpec.anchorSize = me.anchorSize
      }

      return me.getRegion().alignTo(alignSpec)
    }
  })

  Ext.define(null, {
    override: 'Ext.grid.plugin.Exporter',

    saveDocumentAs: function (config) {
      // ***************** Solvoyo Added Code *****************
      const {
        cmp: {
          gridProps: {
            userName = null,
            actualFilters = {},
            createLog,
            settings: { config: { general: { name = '' } = {} } = {} } = {}
          } = {}
        } = {}
      } = this

      config = {
        ...config,
        userName: userName,
        pluginName: name,
        filters: actualFilters,
        createLog: createLog
      }
      // *******************************************************
      var cmp = this.cmp

      var deferred = new Ext.Deferred()

      var exporter = this.getExporter(config)

      cmp.fireEvent('beforedocumentsave', cmp, {
        config: config,
        exporter: exporter
      })

      this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])

      return deferred.promise
    }
  })

  // eslint-disable-next-line no-unused-expressions
  Ext.define(null, {
    override: 'Ext.menu.Manager',

    onGlobalScroll: function (scroller) {
      var allMenus = this.visible

      var len = allMenus.length

      var i

      var menu

      var scrollerEl = scroller.getElement()

      // Scrolling document should not hide menus.
      // The will move along with the document.
      if (len && scroller !== Ext.scroll.Scroller.viewport) {
        // Clone here, we may modify this collection while the loop is active
        allMenus = allMenus.slice()
        for (i = 0; i < len; ++i) {
          menu = allMenus[i]

          // Hide the menu if:
          //      The menu does not own scrolling element
          if (!menu.alignOnScroll && menu.hideOnScroll !== false && !menu.owns(scrollerEl)) {
            // ***************** Solvoyo Added Code *****************
            // hide() is removed in order to solve the problem that
            // header menu was closing involuntarily while filters were chosen
            // and lockable option is true on the grid.
            // menu.hide();
            // *******************************************************
          }
        }
      }
    }
    // eslint-disable-next-line no-sequences
  }),
    Ext.define(null, {
      override: 'Ext.grid.feature.Grouping',

      onGroupMenuItemClick: function (menuItem, e) {
        var me = this

        var menu = menuItem.parentMenu

        var hdr = menu.activeHeader

        var view = me.view

        var store = me.getGridStore()

        if (me.disabled) {
          me.lastGrouper = null
          me.block()
          me.enable()
          me.unblock()
        }
        view.isGrouping = true

        // ***************** Solvoyo Added Code *****************
        const {
          grid: {
            gridProps: {
              settings: { config: { grid: { initialGroupingCollapse } = {} } = {} } = {}
            } = {}
          } = {}
        } = me
        me.startCollapsed = initialGroupingCollapse

        // add initial collapsed column on click onGroupMenuItemClick
        // *******************************************************

        // First check if there is a grouper defined for the feature. This is necessary
        // when the value is a complex type.
        store.group(me.getGrouper(hdr.dataIndex) || hdr.dataIndex)
        me.pruneGroupedHeader()
      }
    }),
    Ext.define(null, {
      override: 'Ext.selection.CheckboxModel',

      getHeaderConfig: function () {
        var me = this

        var showCheck = me.showHeaderCheckbox !== false

        var htmlEncode = Ext.String.htmlEncode

        var config

        config = {
          xtype: 'checkcolumn',
          headerCheckbox: showCheck,
          isCheckerHd: showCheck, // historically used as a dicriminator property before isCheckColumn
          ignoreExport: true,
          text: me.headerText,
          width: me.headerWidth,
          sortable: false,
          draggable: false,
          resizable: false,
          hideable: false,
          menuDisabled: true,
          checkOnly: me.checkOnly,
          checkboxAriaRole: 'presentation',
          // Firefox needs pointer-events: none on the checkbox span to work around focusing issues
          tdCls: Ext.baseCSSPrefix + 'selmodel-checkbox ' + me.tdCls,
          cls: Ext.baseCSSPrefix + 'selmodel-column',
          editRenderer: me.editRenderer || me.renderEmpty,
          locked: me.hasLockedHeader(),
          processEvent: Ext.emptyFn,

          // It must not attempt to set anything in the records on toggle.
          // We handle that in onHeaderClick.
          toggleAll: Ext.emptyFn,

          // The selection model listens to the navigation model to select/deselect
          setRecordCheck: Ext.emptyFn,

          // It uses our isRowSelected to test whether a row is checked
          isRecordChecked: me.isRowSelected.bind(me)
        }

        if (!me.checkOnly) {
          config.tabIndex = undefined
          config.ariaRole = 'presentation'
          // ***************** Solvoyo Added Code *****************
          // When grid is multiselectable with checkboxes and lockable is enabled,
          // and we scroll down the grid, it displays empty blocks of rows.
          // Commenting out the line below fixes this problem
          // config.focusable = false;
          // *******************************************************
        } else {
          config.useAriaElements = true
          config.ariaLabel = htmlEncode(me.headerAriaLabel)
          config.headerSelectText = htmlEncode(me.headerSelectText)
          config.headerDeselectText = htmlEncode(me.headerDeselectText)
          config.rowSelectText = htmlEncode(me.rowSelectText)
          config.rowDeselectText = htmlEncode(me.rowDeselectText)
        }

        return config
      }
    })
}
