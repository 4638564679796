Ext.define('po.model.OpenSasModel', {
  extend: 'po.model.Base',
  entityName: 'po/opensas',
  fields: [
    { name: 'GSBER', type: 'string' },
    { name: 'BSART', type: 'string' },
    { name: 'BEDAT', type: 'string' },
    { name: 'EBELN', type: 'string' },
    { name: 'EBELP', type: 'string' },
    { name: 'LIFNR', type: 'string' },
    { name: 'NAME1', type: 'string' },
    { name: 'MATNR', type: 'string' },
    { name: 'MAKTX', type: 'string' },
    { name: 'MENGE', type: 'float' },
    { name: 'MEINS', type: 'string' },
    { name: 'BWART', type: 'string' },
    { name: 'ERFMG', type: 'float' },
    { name: 'aciksiparis', type: 'float' },
    { name: 'TARIH', type: 'string' }
  ]
})
