import { getSelectionCellParams } from '../index'
import {
  fieldConfigs as defaultFieldConfigs,
  transformedFieldConfigs as defaultTransformedFieldConfigs
} from '../../__tests__/mocks'

function getFieldConfigs() {
  return defaultFieldConfigs
}

describe('AgGrid:Helpers:Selection:getSelectionCellParams', () => {
  let fieldConfigs = getFieldConfigs()

  beforeEach(() => {
    fieldConfigs = getFieldConfigs()
  })

  it('should return transformed field configs object', () => {
    const result = getSelectionCellParams(fieldConfigs)
    expect(result).toEqual(defaultTransformedFieldConfigs)
  })

  it('should return transformed field configs object without empty fieldName objects', () => {
    fieldConfigs[0].fieldName = ''
    const result = getSelectionCellParams(fieldConfigs)
    expect(result).not.toHaveProperty('col1')
  })
})
