import { fetchCollection, fetchOne } from '../crudoptV3'

const model = 'dataConnection'

export const getDataConnectionTypes = (customerId) => {
  return fetchCollection(
    model,
    `customer/${customerId}/dataconnectiontypes`,
    {},
    { getRowKey: (result) => result }
  )
}

export const getDataConnectionsByType = (customerId, type) => {
  return fetchCollection(model, `customer/${customerId}/dataconnectionsbytype/${type}`)
}

export const getDataConnections = (customerId) => {
  return fetchCollection(model, `/customer/${customerId}/dataconnection`)
}

export const getMethods = (customerId, id) => {
  return fetchCollection(
    'methods',
    `/customer/${customerId}/dataconnection/${id}/method`,
    {},
    { key: `${customerId}${id}`, getRowKey: (result) => result }
  )
}

export const getMethodSchema = (customerId, id, method, methodParameters = {}) => {
  const params = JSON.stringify(methodParameters)
  return fetchOne(
    'methodschema',
    `/customer/${customerId}/dataconnection/${id}/method/${method}`,
    {
      data: {
        arguments: methodParameters
      }
    },
    { method: 'post', key: `${customerId}${id}${method}${params}` }
  )
}
