Ext.define('tuiv2.cmp.SessionGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'tuiv2-sessiongrid',
  columns: [
    {
      xtype: 'actioncolumn',
      width: 42,
      renderer: function (val, metadata, record) {},
      items: [
        {
          iconCls: 'slvy-ui-icon-diff',
          handler: 'getSessionDiff',
          tooltip: 'Open Diff'
        }
      ]
    },
    {
      dataIndex: 'Description',
      text: 'Description',
      width: 200
    },
    {
      dataIndex: 'Username',
      text: 'User Name',
      width: 100
    },
    {
      dataIndex: 'StartDate',
      text: 'Start Time',
      width: 100,
      filter: {
        type: 'list'
      },
      renderer: function (value) {
        return Ext.util.Format.date(value, 'Y-m-d')
      }
    },
    {
      dataIndex: 'Distance',
      text: 'Distance',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'Duration',
      text: 'Duration',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'NumStops',
      text: 'NumStops',
      width: 100
    },
    {
      dataIndex: 'PalletCumulative',
      text: 'Pallet (Cumulative)',
      tooltip: 'Pallet (Cumulative)',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'PalletRoundUp',
      text: 'Pallet (RoundUp)',
      tooltip: 'Pallet (RoundUp)',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'PlannedPercentage',
      text: 'Planned (Percentage)',
      tooltip: 'Planned (Percentage)',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      }
    },
    {
      dataIndex: 'PlannedTrucks',
      text: 'Planned Trucks',
      width: 100
    },
    {
      dataIndex: 'TotalCase',
      text: 'Total Case',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'TransportCost',
      text: 'Transport Cost',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'Weight',
      text: 'Weight',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'CostPerPalletCumulative',
      text: 'CostPerPallet (Cumulative)',
      tooltip: 'CostPerPallet (Cumulative)',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'CostPerPalletGross',
      text: 'CostPerPallet (Gross)',
      tooltip: 'CostPerPallet (Gross)',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    },
    {
      dataIndex: 'CostPerPalletRoundUp',
      text: 'CostPerPallet (RoundUp)',
      tooltip: 'CostPerPallet (RoundUp)',
      width: 100,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000.00')
      }
    }
  ]
})
