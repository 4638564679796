import { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

export default class SimpleValue extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.getSelection = this.getSelection.bind(this)

    this.state = {
      column: '',
      format: '',
      type: '',
      ...props.value
    }

    this.formats = {
      int: [
        {
          name: '00.000,00',
          format: '00.000,00'
        }
      ]
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.onChange && nextProps.value !== this.state) {
      this.setState(nextProps.value)
    }
  }

  handleChange(label) {
    const { columns } = this.props
    return (event) => {
      if (label === 'column') {
        const type = columns.find((o) => o.fieldName === event.target.value).dataType
        this.setState({ [label]: event.target.value, type })
      } else {
        this.setState({ [label]: event.target.value })
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      this.props.onChange &&
      (nextState.value !== this.state.value || nextState.format !== this.state.format)
    ) {
      this.props.onChange(nextState)
    }
  }

  getSelection() {
    const { type } = this.state
    if (this.formats[type]) {
      return this.formats[type]
    }
    return []
  }

  render() {
    return (
      <Row>
        <Col md={4}>
          <select
            className="w-100 form-select form-select-sm"
            value={this.state.column}
            onChange={this.handleChange('column')}
          >
            <option>Select...</option>
            {this.props.columns.map((item) => (
              <option key={item.fieldName} value={item.fieldName}>
                {item.fieldName}
              </option>
            ))}
          </select>
        </Col>
        <Col md={4}>
          <select className="w-100 form-select form-select-sm" onChange={this.handleSelect}>
            <option>Select...</option>
            {this.getSelection().map((item) => (
              <option key={item.name} value={item.format}>
                {item.name}
              </option>
            ))}
          </select>
        </Col>
        <Col md={4}>
          <input
            className="w-100 form-control form-control-sm"
            type="text"
            value={this.state.format}
            onChange={this.handleChange('format')}
          />
        </Col>
      </Row>
    )
  }
}
