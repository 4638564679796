Ext.define('MDM.src.view.DPIAdd.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.dpiadd-mainviewmodel',
  stores: {
    searchStore: {
      model: 'MDM.src.view.BomEdit.model.searchModel',
      remoteSort: 'true', //For Remote Sorting
      autoLoad: true
    },
    DPIMatStore: {
      model: 'MDM.src.view.DPIAdd.model.DPIMatList',
      remoteSort: 'true', //For Remote Sorting
      bindTo: 'bindToBomListStore',
      autoLoad: false,
      autoSync: false
    }
  },
  formulas: {
    title: {
      bind: {
        bindTo: '{record}',
        deep: true
      },
      get: function (record) {
        if (record) {
          return record.get('DPIFamily')
        }
        return ''
      }
    },
    isStoreDirty: {
      bind: {
        bindTo: '{bindToBomListStore}',
        deep: true
      },
      get: function () {
        if (this.getStore('DPIMatStore') && this.getStore('DPIMatStore').isDirty) {
          return this.getStore('DPIMatStore').isDirty()
        }
        return false
      }
    }
  },
  data: {
    record: false
  }
})
