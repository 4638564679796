import { useState, useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import withConnector from '@/BaseContainer'
import { useEvent } from '@/hooks'
import { View } from '..'
import { Permission } from '@/components'
import AddElement from '../components/AddElement'
import containerValidation from '@/utils/containerValidation'
import { defaultSettings, defaultModalStyle } from './constants'
import { ContainerRef, Element, NewSettings, ResolveCallback } from './PopupContainer.types'
import './style.scss'

const PopupContainer = withConnector((props) => {
  const {
    plugins,
    container: {
      data: {
        settings,
        settings: {
          name = defaultSettings.name,
          title = defaultSettings.title,
          sizeType = defaultSettings.sizeType,
          height = defaultSettings.height,
          sizeTypeWidth = defaultSettings.sizeTypeWidth,
          width = defaultSettings.width,
          fullPage = defaultSettings.fullPage,
          hideMode = defaultSettings.hideMode,
          confirmationMode = defaultSettings.confirmationMode,
          element
        }
      }
    },
    containers,
    params: { environment, designMode },
    params,
    showAddPluginLayout,
    registerMethod,
    registerEvent,
    onMounted,
    onSave
  } = props

  const [showModal, setShowModal] = useState(false)
  const containerRef = useRef<ContainerRef>({})
  const resolveCallback = useRef<ResolveCallback>(false)

  const onClose = () => {
    return {}
  }

  const handleClose = useEvent(() => {
    if (!showModal) {
      return
    }
    setShowModal(false)

    const onClosePopup = containerRef.current.registerEvent.onClose
    onClosePopup()
  })

  const handleShow = useEvent(() => {
    resolveCallback.current = false
    setShowModal(true)

    if (hideMode !== 'close') {
      return null
    }

    const promise = new Promise((resolve) => {
      resolveCallback.current = resolve
    })

    return promise
  })

  const handlePluginMounted = () => {
    onMounted?.()
  }

  useEffect(() => {
    // Added for hide mode to solve the scrollbar issue
    document.documentElement.classList.add('hidePopupModal')

    registerMethod({
      key: 'openModal',
      fn: handleShow,
      args: {}
    })
    registerMethod({
      key: 'closeModal',
      fn: handleClose,
      args: {}
    })

    containerRef.current.registerEvent = {
      onClose: registerEvent({
        key: 'onClose',
        fn: onClose,
        returnTypes: {}
      })
    }

    handlePluginMounted()
  }, [])

  const saveLayout = (newSettings: NewSettings) => {
    const updatedSettings = { ...settings, ...newSettings }
    const { element: { id = '' } = {} } = updatedSettings
    onSave(updatedSettings, id ? [id] : [])
  }

  const afterAddPlugin = (id: Element['id'], type: Element['type']) => {
    if (!containerValidation(containers, id)) {
      return
    }
    saveLayout({ element: { id, type } })
  }

  const handlePluginRemove = () => {
    saveLayout({ element: undefined })
  }

  const handleAddPlugin = () => {
    showAddPluginLayout(afterAddPlugin)
  }

  const handleEntered = () => {
    if (resolveCallback.current) {
      setTimeout(() => {
        resolveCallback.current()
      }, 500)
    }
  }

  let modalShowProperty = false
  if (hideMode === 'close') {
    modalShowProperty = showModal
  } else if (hideMode === 'hide') {
    modalShowProperty = true
  }

  const isConfiguration = environment === 'Configuration'
  const onEntered = hideMode === 'close' ? handleEntered : undefined
  const isPopupNotVisibleInHideMode = hideMode === 'hide' && !showModal
  const backdrop = confirmationMode ? 'static' : true
  const closeButton = isConfiguration ? true : !confirmationMode

  // These two css classes are used to control the visibility of the modals in hide mode
  const dialogClassName = isPopupNotVisibleInHideMode ? 'hideMode-dialog' : ''
  const backdropClassName = isPopupNotVisibleInHideMode ? 'hideMode-backdrop' : ''
  const fullPageClassName = fullPage ? 'fullPage' : ''

  const buttontext = name.trim() ? name : 'Show Modal'

  return (
    <div className="popup-container" data-testid="popup-container">
      {modalShowProperty && (
        <Modal
          backdrop={backdrop}
          backdropClassName={backdropClassName}
          className={`popupcontainer ${fullPageClassName} ${dialogClassName}`}
          enforceFocus={false}
          keyboard={!confirmationMode}
          show={modalShowProperty}
          size="lg"
          style={{
            height: height + sizeType,
            width: width + sizeTypeWidth,
            ...defaultModalStyle
          }}
          onEntered={onEntered}
          onHide={handleClose}
        >
          <Modal.Header closeButton={closeButton}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {element ? (
              <View
                containers={containers}
                id={element.id}
                params={params}
                plugins={plugins}
                type={element.type}
                onMounted={handlePluginMounted}
                onRemovePlugin={handlePluginRemove}
              />
            ) : (
              designMode && (
                <Permission has={['Plugin.Add']}>
                  <AddElement handleAddElement={handleAddPlugin} />
                </Permission>
              )
            )}
          </Modal.Body>
        </Modal>
      )}
      {isConfiguration && (
        <div className="popup-config">
          <Button size="sm" title={buttontext} variant="success" onClick={handleShow}>
            {buttontext}
          </Button>
        </div>
      )}
    </div>
  )
})

export default PopupContainer
