Ext.define('clustering.Global', {
  singleton: true,
  config: {
    apiUrl: '###CLUSTERING_API_URL###',
    startToken: 'Home',
    userInfo: null,
    customerInfo: null,
    catalogInfo: null,
    catalogId: null,
    catologConfig: {},
    isStateful: false,
    NetToken: null,
    isDevelopment: true
  },
  constructor: function (config) {
    var me = this
    me.initConfig(config)
  },
  getCatalogId: function () {
    var me = this,
      catalogInfo = me.getCatalogInfo(),
      catalogId = catalogInfo != null && catalogInfo.catalogId ? catalogInfo.catalogId : null
    return catalogId
  }
})
