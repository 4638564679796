import { useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { AddRecordProps } from './useRegisterMethodAddRecord.types'

function useRegisterMethodAddRecord({ registerMethod, handleAddRecord }: AddRecordProps) {
  useEffect(() => {
    registerMethod({
      args: [{ name: 'refreshKey', type: PluginTypes.string }],
      key: 'addRecord',
      fn: handleAddRecord
    })
  }, [registerMethod, handleAddRecord])
}

export default useRegisterMethodAddRecord
