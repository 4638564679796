import { objectKeys } from '@/ts/utils'
import { CustomInfo, EditableFields, FieldLookupConfig } from '../../types'
import { Option } from '../../components/CellEditors/LookupFieldCellEditor/LookupFieldCellEditor.types'
import {
  IdFromLookupOptionsProps,
  UpdateWithSubstituteNewValueProps,
  ValueFromLookupOptionsProps
} from './lookup.types'

const getFieldLookupConfig = (
  field: string | undefined,
  editableFields: EditableFields
): FieldLookupConfig => {
  const editableFieldDef = editableFields.find((editableField) => {
    return editableField.field === field
  })

  const {
    lookupQueryList = [],
    isLookupQuery = false,
    substitudeField = null
  } = editableFieldDef || {}

  // TODO: Rename substitudeField with substituteField from query
  return {
    lookupQueryList,
    isLookupQuery,
    substituteField: substitudeField
  }
}

const getIdFromLookupOptions = ({
  lookupOptions,
  value
}: IdFromLookupOptionsProps): Option['id'] | undefined => {
  return lookupOptions?.find((item: Option) => item.value === value)?.id
}

const getValueFromLookupOptions = ({
  lookupOptions,
  id
}: ValueFromLookupOptionsProps): Option['value'] | undefined => {
  return lookupOptions?.find((item: Option) => item.id === id)?.value
}

const getIsLookupDataField = (customInfo: CustomInfo): boolean => {
  return Boolean(customInfo?.editing?.lookupDataField)
}

const getIsLookupQuery = (customInfo: CustomInfo): boolean => {
  return Boolean(customInfo?.fieldLookupConfig?.isLookupQuery)
}

const getIsSubstitute = (customInfo: CustomInfo): boolean => {
  return Boolean(customInfo?.fieldLookupConfig?.substituteField)
}

const getLookupValuesFromString = (lookupValuesString: string) => {
  if (typeof lookupValuesString !== 'string') {
    return undefined
  }

  return lookupValuesString
    .split('~')
    .filter((item) => item.length)
    .map((item) => {
      const splitVal = item.split(':')
      if (splitVal.length === 2) {
        return { id: splitVal[0], value: splitVal[1] }
      }
      return { id: item, value: item }
    })
}

const updateWithSubstituteNewValue = ({ data, dirtyCells }: UpdateWithSubstituteNewValueProps) => {
  const newData = { ...data }

  // TODO:
  // Set default value of substitute column data even if they dont update
  // If we dont update, data will not be updated with id (always takes label)
  objectKeys(newData).forEach((fieldKey) => {
    const isSubstitute = isSubstituteFieldMatch(fieldKey)
    if (isSubstitute) {
      if (typeof fieldKey === 'string') {
        let originalFieldKey = fieldKey.replace('_SLVY_', '')
        originalFieldKey = originalFieldKey.replace('_SUBSTITUTE_FIELD_', '')

        if (originalFieldKey in newData) {
          newData[originalFieldKey] = newData[fieldKey]
        }
      }
    }
  })

  dirtyCells
    ?.filter((dirtyCell) => {
      const { isSubstitute } = dirtyCell
      return isSubstitute
    })
    ?.forEach((dirtyCell) => {
      const {
        field,
        newValueParams: { id: newValueParamsId }
      } = dirtyCell
      // eslint-disable-next-line no-prototype-builtins
      if (newData.hasOwnProperty(field)) {
        newData[field] = newValueParamsId
      }
    })
  return newData
}

const isSubstituteFieldMatch = (param: any) => {
  const pattern = /^_SLVY_.+_SUBSTITUTE_FIELD_$/
  return pattern.test(param)
}

export {
  isSubstituteFieldMatch,
  getFieldLookupConfig,
  getIdFromLookupOptions,
  getIsLookupDataField,
  getIsLookupQuery,
  getIsSubstitute,
  getLookupValuesFromString,
  getValueFromLookupOptions,
  updateWithSubstituteNewValue
}
