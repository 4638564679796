import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import _ from 'lodash'

export default class AssortmentModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: props.show
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.show !== this.props.show) {
      this.setState({ show: nextProps.show })
    }
  }

  handleClose() {
    this.setState({ show: false })
    this.props.handleCloseModal()
  }

  render() {
    const {
      props: {
        title = '',
        isShowFooter = true,
        children = '',
        size = '',
        footerTemplate = '',
        bsSize = 'xl',
        className = '',
        backdrop = true
      },
      state: { show = true }
    } = this

    return (
      <Modal
        backdrop={backdrop}
        className={className}
        dialogClassName={size}
        show={show}
        size={bsSize}
        onHide={this.handleClose.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {isShowFooter ? (
          <Modal.Footer>
            {footerTemplate}
            <Button size="sm" variant="outline-dark" onClick={this.handleClose.bind(this)}>
              <span className="slvy-ui-icon-times-extra-lt" />
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    )
  }
}
