import { isEqual } from 'lodash'
import { Config } from '../../Tui.types'
import { Hierarchy } from './SettingsModal.types'

export const getHierarchies = (
  config: Config,
  treeName: keyof Pick<Config, 'loadTree' | 'demandTree'> | null
) => {
  if (!treeName) {
    return { leftHierarchies: [], rightHierarchies: [] }
  }

  const hierarchies = config[treeName].hierarchy

  return config[treeName].columns.reduce<{
    leftHierarchies: Hierarchy[]
    rightHierarchies: Hierarchy[]
  }>(
    (acc, column) => {
      const { dataIndex, text, isHierarchy, isTreeDistinctColorField = false } = column

      const data = { text, dataIndex, isTreeDistinctColorField }
      const isExistInHiearchies = hierarchies.some(({ fieldName }) => fieldName === dataIndex)
      if (isExistInHiearchies) {
        return {
          leftHierarchies: acc.leftHierarchies,
          rightHierarchies: acc.rightHierarchies.concat(data)
        }
      }

      if (isHierarchy) {
        return {
          leftHierarchies: acc.leftHierarchies.concat(data),
          rightHierarchies: acc.rightHierarchies
        }
      }

      return acc
    },
    {
      leftHierarchies: [],
      rightHierarchies: []
    }
  )
}

export const getCellStyle = (cell) => {
  const isTextColumn = cell.column.id === 'text'
  if (!isTextColumn) {
    return {}
  }

  return { paddingLeft: 5 + cell.row.index * 8 }
}

export const toggleColorField = (row) => {
  row.original.isTreeDistinctColorField = !row.original.isTreeDistinctColorField
}

const getIsHierarchiesInLocalStorageValid = (
  rightHierarchiesFromConfig: Hierarchy[],
  rightHierarchiesFromLocalStorage: Hierarchy[]
) => {
  if (rightHierarchiesFromConfig.length !== rightHierarchiesFromLocalStorage?.length) {
    return false
  }

  const sortedRightHierarchiesFromConfig = rightHierarchiesFromConfig
    .map(({ dataIndex, text }) => ({ dataIndex, text }))
    .sort((prev, next) => prev.text.localeCompare(next.text))

  const sortedRightHierarchiesFromLocalStorage = rightHierarchiesFromLocalStorage
    .map(({ dataIndex, text }) => ({ dataIndex, text }))
    .sort((prev, next) => prev.text.localeCompare(next.text))

  if (!isEqual(sortedRightHierarchiesFromConfig, sortedRightHierarchiesFromLocalStorage)) {
    return false
  }

  return true
}

export const getInitialRightHierarchies = (
  key: string,
  rightHierarchiesFromConfig: Hierarchy[]
) => {
  const rightHierarchiesFromLocalStorage = JSON.parse(localStorage.getItem(key))
  const isHierarchiesInLocalStorageValid = getIsHierarchiesInLocalStorageValid(
    rightHierarchiesFromConfig,
    rightHierarchiesFromLocalStorage
  )

  if (isHierarchiesInLocalStorageValid) {
    return rightHierarchiesFromLocalStorage
  }

  return rightHierarchiesFromConfig
}
