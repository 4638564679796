export default {
  title: 'Gauge',
  subTitle: 'Highcharts - Treemap',
  version: '1.0',
  namespace: 'Gauge',
  description: 'Gauge from Highcharts (www.highcharts.com)',
  iconClass: 'fa-tachometer',
  color: 'lazur-bg',
  support: {
    maximizable: true
  },
  group: 'Charts'
}
