Ext.define('MDM.src.view.Grouping.Edit.TreePanel', {
  extend: 'Ext.tree.Panel',
  xtype: 'grouping-edit-treepanel',
  rootVisible: true,
  reference: 'tree',
  border: true,
  columnLines: true,
  rowLines: true,
  columns: [
    {
      xtype: 'treecolumn', //this is so we know which column will show the tree
      text: 'DPI Hierarchy',
      dataIndex: 'text',
      flex: 2,
      sortable: true
    },
    {
      xtype: 'gridcolumn', //this is so we know which column will show the tree
      text: 'DPI Family Cnt',
      tooltip: 'DPI Family Cnt',
      align: 'right',
      width: 80,
      sortable: true,
      renderer: function (value, metaData, record) {
        var v = 0
        if (record.get('depth') > 2) return ''

        if (record.hasChildNodes()) {
          record.cascadeBy(function (node) {
            if (node.get('visible')) {
              var c = node.get('depth') === 3 ? 1 : 0
              v = v + c
            }
          })
        }
        if (v == 0) return ''
        return v
      }
    },
    {
      menuDisabled: true,
      sortable: false,
      xtype: 'actioncolumn',
      locked: false,
      width: 40,
      align: 'center',
      items: [
        {
          iconCls: 'fas fa-plus-circle',
          tooltip: 'Add to list ',
          margin: 10,
          isDisabled: function (view, rowIndex, colIndex, item, rec) {
            return rec.get('depth') < 2
          },
          getClass: function (v, meta, rec) {
            if (rec.get('depth') != 0) {
              return 'fas fa-plus-circle'
            } else {
              return ''
            }
          },
          handler: 'onAddNode'
        }
      ]
    }
  ],
  dockedItems: [
    {
      xtype: 'toolbar',
      dock: 'top',
      items: [
        {
          flex: 1,
          xtype: 'textfield',
          reference: 'dpifamily',
          emptyText: 'Search',
          enableKeyEvents: true,
          triggers: {
            clear: {
              cls: 'x-form-clear-trigger',
              handler: 'onClearTriggerClick',
              hidden: true
            },
            search: {
              cls: 'x-form-search-trigger',
              weight: 1,
              handler: 'onSearchTriggerClick'
            }
          },
          listeners: {
            keyup: function (field, e) {
              var value = field.getValue()

              if (value.length >= 3 && e.browserEvent.keyCode !== 13) {
                field.fireEvent('dofilter', field, e)
              }
              if (value.length == 0 && e.browserEvent.keyCode !== 13) {
                field.fireEvent('clearfilter', field, e)
              }
            },
            dofilter: 'onSearchTree',
            clearfilter: 'onClearTriggerClick'
          }
        }
      ]
    }
  ]
})
