export default () => {
  return {
    title: 'File Upload',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          groupKey: {
            type: 'string',
            title: 'Group Key'
          },
          readOnly: {
            type: 'boolean',
            title: 'Read Only',
            default: false,
            required: true
          },
          multiple: {
            type: 'boolean',
            title: 'Multiple Upload',
            default: true,
            required: true
          },
          mode: {
            type: 'string',
            title: 'Mode',
            default: 'Gallery',
            enum: ['Gallery', 'Single Image'],
            required: true
          },
          showInfo: {
            type: 'boolean',
            title: 'Show Info',
            default: true,
            required: true
          },
          showBrowser: {
            type: 'boolean',
            title: 'Show Browser',
            default: true,
            required: true
          },
          containerBackground: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          detailViewBackground: {
            type: 'string',
            format: 'Color',
            title: 'Detail View Bg Color',
            default: '#000000'
          },
          listType: {
            type: 'string',
            title: 'List Type',
            default: 'Thumbnails',
            enum: ['Thumbnails', 'List', 'Masonry'],
            required: true
          },
          thumbnailsDirection: {
            type: 'string',
            title: 'Thumbnails Direction',
            default: 'right',
            enum: ['top', 'left', 'bottom', 'right'],
            required: true
          },
          ratioType: {
            type: 'string',
            title: 'Popup Image Ratio',
            default: 'cover',
            enum: ['fill', 'contain', 'cover'],
            required: true
          },
          columnSize: {
            type: 'integer',
            title: 'Column Size',
            format: 'range',
            minimum: 2,
            maximum: 8,
            default: 4
          }
        }
      },
      hooks: {
        type: 'object',
        title: 'Hooks',
        properties: {
          afterInsert: {
            type: 'string',
            title: 'After insert hook',
            description:
              'Should be a stored procedure with an int parameter (fileId). Will be called after a new image is uploaded.'
          },
          afterDelete: {
            type: 'string',
            title: 'After delete hook',
            description:
              'Should be a stored procedure with an int parameter (fileId). Will be called after an image is deleted.'
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          groupKey: {
            type: 'string',
            title: 'Group Key',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
