Ext.define('clustering.store.ClusterColors', {
  singleton: true,
  colors: [
    '#800055',
    '#cc00ff',
    '#00e6b8',
    '#ccff00',
    '#8c3800',
    '#1d2873',
    '#238c23',
    '#ff8c40',
    '#f23d3d',
    '#6666cc',
    '#b27159',
    '#acb4e6',
    '#e6d2ac',
    '#e600b8',
    '#140033',
    '#00331b',
    '#ccbe00',
    '#f23d6d',
    '#205380',
    '#61661a',
    '#401010',
    '#b35989',
    '#59adb3',
    '#85698c',
    '#86b386',
    '#403430',
    '#660080',
    '#002233',
    '#00ff00',
    '#f2a200',
    '#5039e6',
    '#40bfff',
    '#594316',
    '#7f2020',
    '#331a2b',
    '#336663',
    '#f2bfff',
    '#384030',
    '#e6acac',
    '#cd1b28'
  ]
})
