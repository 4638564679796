Ext.define('MDM.src.view.regionapp.Panel.DropBox', {
  extend: 'Ext.form.Panel',
  xtype: 'regionapp-dropbox',
  config: {
    hides: true
  },
  reference: 'form',
  width: 300,
  margin: '2 2 2 2',
  html: '  <span class="child">Drag selected records to suppressed list</span>',
  header: false,
  cls: 'dropbox',
  bind: {
    hides: '{isDrag}'
  },
  setHides: function (c) {
    if (!c) {
      this.setStyle({
        visibility: 'hidden'
      })
    }
    if (c) {
      this.setStyle({
        visibility: 'visible'
      })
    }
  }
})
