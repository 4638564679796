import React, { useState, useEffect, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import createPlugin, { PluginTypes } from './../../BasePlugin'
import './index.scss'
import highcharts3d from 'highcharts/highcharts-3d'
import { getFormatedValue } from './../../helpers/formats'

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)

highcharts3d(Highcharts)

const defaults = {
  credits: {
    enabled: false,
    text: 'Solvoyo © ' + new Date().getFullYear(),
    href: '#',
    style: { fontSize: '12px' }
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030'
    }
  }
}

let chartPosition = null
export const D3Scatter = (props) => {
  const [title, setTitle] = useState()
  const [subtitle, setSubtitle] = useState()
  const pluginRef = useRef({})

  function handleTitleChange(title) {
    if (title.text) {
      setTitle(title.text)
    }
  }

  function handleSubtitleChange(subtitle) {
    if (subtitle.text) {
      setSubtitle(subtitle.text)
    }
  }
  function handlePointClick(value) {
    return value
  }

  useEffect(() => {
    props.registerMethod({
      key: 'setTitle',
      fn: handleTitleChange,
      args: [{ name: 'text', type: PluginTypes.string }]
    })
    props.registerMethod({
      key: 'setSubtitle',
      fn: handleSubtitleChange,
      args: [{ name: 'text', type: PluginTypes.string }]
    })

    pluginRef.current.registerEvent = {
      handlePointClick: props.registerEvent({
        key: 'handlePointClick',
        fn: handlePointClick,
        returnTypes: _.transform(
          props.settings.query.fields,
          (result, field = {}) => {
            let { dataType = '' } = field
            result[field.fieldName] = PluginTypes.fromString(dataType)
          },
          {}
        )
      })
    }
  }, [])

  function getData(series = []) {
    const configSeries = []
    _.forEach(series, (series) => {
      let seriesArray = getSeries(series)
      if (seriesArray) {
        configSeries.push(seriesArray)
      }
    })

    return configSeries
  }

  function getSeries(series) {
    const {
      xFieldName: xField,
      yFieldName: yField,
      zFieldName: zField,
      colorByPoint = false,
      name = null,
      color = null,
      colorField = null,
      turboThreshold = 1000
    } = series || {}
    const fieldNullCheck = !_.isNil(xField) && !_.isNil(yField) && !_.isNil(zField)
    const fieldCheck = fieldNullCheck && xField.length > 0 && yField.length > 0 && zField.length > 0
    if (fieldCheck) {
      if (props.pluginData?.length != 0) {
        let series = {}

        series.data = []

        _.forEach(props.pluginData, (row) => {
          if (colorField && _.has(row, colorField) && row[colorField]) {
            series.data.push({
              x: row[xField],
              y: row[yField],
              z: row[zField],
              color: getColorAttributes(row[colorField]),
              row: row
            })
          } else {
            series.data.push({
              x: row[xField],
              y: row[yField],
              z: row[zField],
              row: row
            })
          }
        })

        series.type = 'scatter3d'
        if (name) {
          series.name = name
        }
        if (!colorField && color) {
          series.color = color
          const fillColor = {
            fillColor: getColorAttributes(color)
          }
          series.marker = fillColor
        }

        series.colorByPoint = colorByPoint
        series.visible = true
        series.showInLegend = true
        series.xFieldName = xField
        series.yFieldName = yField
        series.zFieldName = zField
        series.turboThreshold = turboThreshold

        return series
      }
    }
  }

  function onPointClick(event) {
    const onHandleClick = _.get(pluginRef, 'current.registerEvent.handlePointClick', null)
    onHandleClick(event.point.row)
  }

  function unescapeUnicode(str) {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function (g, m1) {
      return String.fromCharCode(parseInt(m1, 16))
    })
  }

  function formatPointValue({
    name,

    xValue,
    yValue,
    zValue,

    chartConfig,
    valueFormat,
    row = null,
    userOptions = {}
  }) {
    const xFieldName = userOptions?.xFieldName
    const yFieldName = userOptions?.yFieldName
    const zFieldName = userOptions?.zFieldName

    let formattedValue
    if (valueFormat) {
      formattedValue = getFormatedValue(valueFormat, yValue)
    } else {
      formattedValue = getFormattedSeriesValue(name, yValue, chartConfig)
    }

    const { tooltip: { pointFormat = {} } = {} } = chartConfig

    let tooltip = `${xFieldName} : <b>${xValue} </b> <br/> ${yFieldName} : <b>${formattedValue} </b><br/> ${zFieldName} : <b> ${zValue}</b><br/> `
    if (pointFormat) {
      tooltip = unescapeUnicode(pointFormat)
    }

    if (row) {
      const formattedRow = _.transform(
        row,
        (res, value, key) => {
          res[key] = getFormatedValue(key, value)
        },
        {}
      )

      tooltip = replaceTemplate(tooltip, formattedRow)
    }

    return tooltip
  }

  function getSeriesName(series) {
    const { userOptions: { fieldName: seriesFieldName = '' } = {} } = series
    return seriesFieldName || series.name
  }

  function replaceTemplate(text, data) {
    var regExp = /\{([^}]+)\}/g

    var matches = text.match(regExp)

    if (!matches) return text

    for (var i = 0; i < matches.length; i++) {
      var variableName = matches[i].substr(1, matches[i].length - 2)

      if (_.has(data, variableName)) {
        var value = data[variableName]

        if (value) {
          text = text.replace(matches[i], value)
        } else {
          text = text.replace(matches[i], '')
        }
      } else {
        text = text.replace(matches[i], '')
      }
    }

    return text
  }

  function getFormattedSeriesValue(name, value, chartConfig) {
    const series = _.find(chartConfig.series, (series) => series.fieldName === name)
    const { fieldName = null } = series || {}
    return fieldName ? getFormatedValue(series.fieldName, value) : value
  }

  function formatHeader(chartConfig, row, seriesName, color) {
    const { tooltip: { headerFormat } = {} } = chartConfig

    let tooltip = `<span style="color:${color}">\u25CF </span> <span style="font-size: 120%">${seriesName} </span><br/> `
    if (headerFormat) {
      tooltip = unescapeUnicode(headerFormat)

      if (row) {
        const formattedRow = _.transform(
          row,
          (res, value, key) => {
            res[key] = getFormatedValue(key, value)
          },
          {}
        )

        tooltip = replaceTemplate(tooltip, formattedRow)
      }
    }

    return tooltip
  }

  function tooltipFormatter() {
    const settings = props?.settings
    const chartConfig = settings?.config

    const { point: { valueFormat = null, row = {}, z } = {}, series = [], x, y, color } = this
    const userOptions = series?.userOptions
    const seriesName = getSeriesName(series)

    let tooltip = formatHeader(chartConfig, row, seriesName, color)
    tooltip += formatPointValue({
      name: seriesName,

      xValue: x,
      yValue: y,
      zValue: z,
      color: color,
      chartConfig: chartConfig,
      valueFormat: valueFormat,
      row: row,
      userOptions
    })

    return tooltip
  }

  function replaceTemplate(text, data) {
    const regExp = /\{([^}]+)\}/g

    let matches = text.match(regExp)

    if (!matches) return text

    for (var i = 0; i < matches.length; i++) {
      const variableName = matches[i].substr(1, matches[i].length - 2)

      if (_.has(data, variableName)) {
        const value = data[variableName]

        if (value) {
          text = text.replace(matches[i], value)
        } else {
          text = text.replace(matches[i], '')
        }
      } else {
        text = text.replace(matches[i], '')
      }
    }

    return text
  }

  function getFirstDataRow() {
    return props.pluginData && props.pluginData[0]
  }
  function applyTitleTemplates(config) {
    if (title) {
      if (!config.title) {
        config.title = {}
      }
      config.title.text = title
    }

    if (subtitle) {
      if (!config.subtitle) {
        config.subtitle = {}
      }
      config.subtitle.text = subtitle
    }

    if (config.title && config.title.text) {
      config.title.text = replaceTemplate(config.title.text, getFirstDataRow())
    }

    const { xAxis = [], yAxis = [], zAxis = [] } = config

    if (xAxis.title && xAxis.title.text) {
      xAxis.title.text = replaceTemplate(xAxis.title.text, getFirstDataRow())
    }

    if (yAxis.title && yAxis.title.text) {
      yAxis.title.text = replaceTemplate(yAxis.title.text, getFirstDataRow())
    }

    if (zAxis.title && zAxis.title.text) {
      zAxis.title.text = replaceTemplate(zAxis.title.text, getFirstDataRow())
    }
  }

  function applyRotation(chart) {
    if (chartPosition) {
      chart.update(chartPosition, undefined, undefined, false)
    }
  }

  function addEvents(chart) {
    const H = Highcharts
    function dragStart(eStart) {
      eStart = chart.pointer.normalize(eStart)

      var posX = eStart.chartX,
        posY = eStart.chartY,
        alpha = chart.options.chart.options3d.alpha,
        beta = chart.options.chart.options3d.beta,
        sensitivity = 5,
        handlers = []

      function drag(e) {
        e = chart.pointer.normalize(e)
        const position = {
          chart: {
            options3d: {
              alpha: alpha + (e.chartY - posY) / sensitivity,
              beta: beta + (posX - e.chartX) / sensitivity
            }
          }
        }
        chart.update(position, undefined, undefined, false)
      }

      function unbindAll() {
        handlers.forEach(function (unbind) {
          if (unbind) {
            unbind()
          }
        })
        handlers.length = 0
      }

      handlers.push(H.addEvent(document, 'mousemove', drag))
      handlers.push(H.addEvent(document, 'touchmove', drag))

      handlers.push(H.addEvent(document, 'mouseup', unbindAll))
      handlers.push(H.addEvent(document, 'touchend', unbindAll))
    }
    function drop(e) {
      const alpha = chart.options.chart.options3d.alpha
      const beta = chart.options.chart.options3d.beta

      chartPosition = {
        chart: {
          options3d: {
            alpha: alpha,
            beta: beta
          }
        }
      }
    }
    applyRotation(chart)
    H.addEvent(chart.container, 'mouseup', drop)
    H.addEvent(chart.container, 'mousedown', dragStart)
    H.addEvent(chart.container, 'touchstart', dragStart)
  }

  function getColorAttributes(color) {
    return {
      radialGradient: {
        cx: 0.4,
        cy: 0.3,
        r: 0.5
      },
      stops: [
        [0, color],
        [1, Highcharts.color(color).brighten(-0.2).get('rgb')]
      ]
    }
  }

  function getColor(Highcharts) {
    const colors = Highcharts.getOptions().colors.map(function (color) {
      return getColorAttributes(color)
    })

    return colors
  }

  function getConfig() {
    const settings = props?.settings
    const pluginConfig = settings?.config
    const config = _.cloneDeep(pluginConfig)
    if (settings && pluginConfig) {
      if (config?.chart?.options3d) {
        config.chart.options3d['enabled'] = true
      }

      config.series = getData(config.series)

      if (Highcharts?.chart) {
        config.colors = getColor(Highcharts)
      }
    }

    applyTitleTemplates(config)
    config.credits = defaults.credits
    config.noData = defaults.noData

    const showCredits = pluginConfig?.chart?.showCredits

    config.credits.enabled = showCredits !== undefined ? showCredits : false

    config.lang = {
      noData: config?.chart?.emptyText || 'No Data to Display'
    }

    if (!config.exporting) {
      config.exporting = {}
      config.exporting.enabled = false
    }

    const pointClick = {
      events: {
        click: onPointClick
      }
    }

    _.map(config.series, (series) => {
      series.point = pointClick
      return series
    })

    if (!config.tooltip) {
      config.tooltip = {}
    }

    config.tooltip.formatter = tooltipFormatter

    return config
  }

  return (
    <div className="highcharts-container">
      <HighchartsReact
        highcharts={Highcharts}
        options={getConfig()}
        callback={addEvents}
        allowChartUpdate
        immutable
      />
    </div>
  )
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings
})

export default createPlugin(D3Scatter, selectConnectorProps, true)
