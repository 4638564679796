import { useRef, useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import diacritics from '../utils/diacritics'
import { HeaderButton } from '../AssortmentPlanning.types'

interface EventRef {
  [key: `headerButton_${string}_Clicked`]: (data: object) => void
}

const useRegisterEventHeaderButtons = (registerEvent, headerButtons: HeaderButton[]) => {
  const headerButtonsEventRef = useRef<EventRef>({})

  useEffect(() => {
    headerButtons.forEach(({ name }) => {
      const eventName = diacritics.removeAndLowerCase(name)
      const key = `headerButton_${eventName}_Clicked`

      headerButtonsEventRef.current[key] = registerEvent({
        key,
        fn: (data) => data,
        returnTypes: {
          AssortmentId: PluginTypes.int
        }
      })
    })
  }, [registerEvent])

  return { headerButtonsEventRef }
}

export default useRegisterEventHeaderButtons
