import { useCallback, useEffect } from 'react'
import { TriggerMultiRowSelectedProps } from './useRegisterMethodTriggerMultiRowSelected.types'

function useRegisterMethodTriggerMultiRowSelected({
  registerMethod,
  triggerMultiRowSelectedResultEventRef
}: TriggerMultiRowSelectedProps) {
  const TriggerMultiRowSelected = useCallback(() => {
    triggerMultiRowSelectedResultEventRef?.current?.handleTriggerMultiRowSelectedResult()
  }, [triggerMultiRowSelectedResultEventRef])

  useEffect(() => {
    registerMethod({
      args: [],
      key: 'TriggerMultiRowSelected',
      fn: TriggerMultiRowSelected
    })
  }, [registerMethod, TriggerMultiRowSelected])
}

export default useRegisterMethodTriggerMultiRowSelected
