import { useState, useEffect, useRef, memo } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { RGBColor } from 'react-color'
import cx from 'classnames'
import { useEvent } from '@/hooks'
import withConnector from '@/BaseContainer'
import { PluginTypes } from '@/BasePlugin'
import AddElement from '../components/AddElement'
import { View } from '..'
import { themes } from '../themes'
import containerValidation from '@/utils/containerValidation'
import { defaultColor, defaultForm } from './Admin/constants'
import events from './constants'
import getBgColor from './helpers'
import { EventName, HiddenId, RegisterEventRef, SelectOptions, Step } from './WizardContainer.types'
import styles from './index.module.scss'

const WizardContainer = withConnector((props) => {
  const {
    id: containerId,
    plugins,
    containers,
    container: {
      data: {
        settings,
        settings: {
          ids = defaultForm.ids,
          steps = defaultForm.steps,
          isControlledWithWorkflow = defaultForm.isControlledWithWorkflow,
          previousText = defaultForm.previousText,
          nextText = defaultForm.nextText,
          cancelText = defaultForm.cancelText,
          finishText: initialFinishText = defaultForm.finishText,
          backgroundColor: initialBackgroundColor = defaultColor,
          theme = defaultForm.theme
        }
      }
    },
    params,
    params: { environment, designMode },
    registerEvent,
    registerMethod,
    showAddPluginLayout,
    onMounted,
    onSave
  } = props

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [previousDisabled, setPreviousDisabled] = useState<boolean>(false)
  const [nextDisabled, setNextDisabled] = useState<boolean>(false)
  const [hiddenIds, setHiddenIds] = useState<HiddenId[]>([])
  const registerEventRef = useRef<RegisterEventRef>({})

  useEffect(() => {
    events.forEach((event) => {
      registerEventRef.current[`handleWizard_${event}`] = registerEvent({
        key: `wizard_${event}`,
        fn: (obj) => obj,
        returnTypes: {
          current: PluginTypes.int,
          target: PluginTypes.int
        }
      })
    })

    const methods = [
      { key: 'setPreviousDisabled', fn: () => setPreviousDisabled(true) },
      { key: 'setPreviousEnabled', fn: () => setPreviousDisabled(false) },
      { key: 'setNextDisabled', fn: () => setNextDisabled(true) },
      { key: 'setNextEnabled', fn: () => setNextDisabled(false) }
    ]
    methods.forEach(({ key, fn }) => {
      registerMethod({ key, fn, args: [] })
    })
  }, [])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [currentStep])

  const handleStepVisibility = useEvent((options: HiddenId & Record<'isHidden', boolean>) => {
    const { isHidden, id, index } = options
    if (isHidden) {
      const newHiddenId: HiddenId = { id, index }
      setHiddenIds((prevHiddenIds) => [...new Set([...prevHiddenIds, newHiddenId])])
    } else {
      setHiddenIds((prevHiddenIds) => prevHiddenIds.filter((_item) => id !== _item.id))
    }
  })

  const callEvent = (options: SelectOptions, eventName: EventName, step: number) => {
    const { current, target } = options

    const handleWizardCallback = registerEventRef.current[`handleWizard_${eventName}`]
    if (_.isFunction(handleWizardCallback)) {
      handleWizardCallback({
        current: Number(current),
        target: Number(target)
      })
    }

    const currentTab = ids[step]

    const handleWizardTabCallback = registerEventRef.current[`handleWizardTab_${currentTab}`]
    if (_.isFunction(handleWizardTabCallback)) {
      handleWizardTabCallback()
    }
  }

  const handleSelect = useEvent((options: SelectOptions, eventName: EventName = '') => {
    const { target } = options

    const step = Number(target)
    setCurrentStep(step)

    if (eventName) {
      callEvent(options, eventName, step)
    }
  })

  useEffect(() => {
    setHiddenIds((prevHiddenIds) =>
      prevHiddenIds.filter((_item) => ids.find((id: string) => id === _item.id))
    )

    ids.forEach((id: string, index: number) => {
      const methods = [
        {
          key: `wizard_${id}`,
          fn: (event: Record<string, never>) =>
            handleSelect({ current: currentStep, target: index }, event)
        },
        {
          key: `set_${id}_Enabled`,
          fn: () => handleStepVisibility({ isHidden: false, id, index })
        },
        {
          key: `set_${id}_Disabled`,
          fn: () => handleStepVisibility({ isHidden: true, id, index })
        }
      ]

      methods.forEach(({ key, fn }) => {
        registerMethod({ key, fn, args: [] })
      })

      registerEventRef.current[`handleWizardTab_${id}`] = registerEvent({
        key: `wizardTab_${id}`,
        fn: () => {},
        returnTypes: {}
      })
    })
  }, [ids.length])

  const afterAddPlugin = (stepIndex: number) => {
    return (id: string) => {
      if (!containerValidation(containers, id)) {
        return
      }
      steps[stepIndex] = { ...steps[stepIndex], elementId: id }
      onSave(
        {
          ...settings,
          steps,
          children: _.map(steps, (l) => l.elementId)
        },
        _.map(steps, ({ elementId }) => elementId)
      )
    }
  }

  const handleAddPlugin = (stepIndex: number) => {
    showAddPluginLayout(afterAddPlugin(stepIndex))
  }

  const handlePluginRemove = (id?: string) => {
    const newPosition = _.map(steps, (p) => {
      if (p.elementId === id) {
        return { ...p, elementId: undefined }
      }
      return p
    })
    onSave(
      {
        ...settings,
        steps: newPosition,
        children: _.map(newPosition, (l) => l.elementId)
      },
      _.map(newPosition, ({ elementId }) => elementId)
    )
  }

  const findPlugin = (stepIndex: number) => {
    const { elementId = 0 } = steps[stepIndex] || {}
    let type
    const plugin = _.find(plugins, (p) => p.id === elementId)
    if (plugin) {
      type = 'plugin'
    } else {
      const container = _.find(containers, (p) => p.id === elementId)
      if (container) type = 'container'
    }

    if (type === 'plugin' || type === 'container') {
      return (
        <View
          key={elementId}
          containers={containers}
          draggableHandle={`move${containerId}`}
          id={elementId}
          params={params}
          plugins={plugins}
          type={type}
          onMounted={onMounted}
          onRemovePlugin={handlePluginRemove}
        />
      )
    }

    const isConfiguration = environment === 'Configuration'
    if (isConfiguration && designMode) {
      return (
        <AddElement containerClass="bg-white" handleAddElement={() => handleAddPlugin(stepIndex)} />
      )
    }

    return null
  }

  const handleSelectWithConfirmation = (options: SelectOptions, eventName: EventName = '') => {
    if (eventName) {
      callEvent(options, eventName, currentStep)
    }
  }

  const getSteps = () => {
    const newSteps: Step[] = []

    ids.forEach((id: string, index: number) => {
      const step = steps[index]
      step.index = index
      step.isHidden = hiddenIds.some((item) => item.id === id)
      newSteps.push(step)
    })

    return newSteps
  }

  const updatedSteps = getSteps()
  const stepSize = _.size(updatedSteps)
  const nextStep = currentStep + 1
  const prevStep = currentStep - 1
  const finishText = initialFinishText.trim().length ? initialFinishText : defaultForm.finishText
  const options = { current: currentStep }
  const onSelect = isControlledWithWorkflow ? handleSelectWithConfirmation : handleSelect

  const backgroundColor = _.isObject(initialBackgroundColor)
    ? getBgColor(initialBackgroundColor as RGBColor)
    : 'rgba(255,255,255,1)'

  const { slug: themeClass } = themes.find((_theme) => _theme.id === theme) || themes[0]
  const isSleekTheme = themeClass === 'sleek-theme'
  const buttonThemeClass = { 'fs-xs bg-secondary border-0 rounded-1': isSleekTheme }

  return (
    <div
      className="d-flex flex-column h-100"
      data-testid="wizard-container"
      style={{ backgroundColor }}
    >
      <header className={styles.stepHeader}>
        <ol
          className={cx(
            'position-relative',
            'd-flex',
            'overflow-hidden',
            'list-unstyled',
            'px-2',
            styles.stepList
          )}
        >
          {_.map(updatedSteps, ({ name = '', isHidden }, index) => {
            const isActive = index === currentStep
            const testId = isActive ? 'active-step' : null
            return isHidden ? null : (
              <li
                key={index}
                className={cx(
                  'position-relative',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'w-100',
                  'ms-1',
                  styles.stepListItem,
                  { [styles.stepListItemActive]: isActive }
                )}
              >
                <span
                  className={cx(
                    'text-secondary-emphasis',
                    'fs-sm',
                    'px-3',
                    styles.stepListItemTitle,
                    { 'text-white': isActive }
                  )}
                  data-testid={testId}
                >
                  {name}
                </span>
              </li>
            )
          })}
        </ol>
      </header>
      <div className="flex-fill">
        {_.map(updatedSteps, (step, stepIndex) => {
          const isHidden = step.isHidden || stepIndex !== currentStep
          return (
            <section key={stepIndex} className={cx('w-100', 'h-100', { 'd-none': isHidden })}>
              {findPlugin(stepIndex)}
            </section>
          )
        })}
      </div>
      <div className={cx('d-flex', 'justify-content-between p-2', styles.stepBtnGroup)}>
        <div className="w-100 text-start">
          {currentStep > 0 ? (
            <Button
              className={cx(styles.actionBtn, buttonThemeClass)}
              disabled={previousDisabled}
              size="sm"
              variant="primary"
              onClick={() => onSelect({ ...options, target: prevStep }, 'PreviousClicked')}
            >
              {previousText}
            </Button>
          ) : null}
        </div>
        <div className="w-100 text-center">
          {cancelText ? (
            <Button
              className={cx(styles.actionBtn, buttonThemeClass)}
              size="sm"
              variant="danger"
              onClick={() => onSelect({ ...options, target: 0 }, 'CancelClicked')}
            >
              {cancelText}
            </Button>
          ) : null}
        </div>
        <div className="w-100 text-end">
          {nextStep >= stepSize ? (
            <Button
              className={cx(styles.actionBtn, buttonThemeClass)}
              size="sm"
              variant="success"
              onClick={() => onSelect({ ...options, target: currentStep }, 'FinishClicked')}
            >
              {finishText}
            </Button>
          ) : (
            <Button
              className={cx(styles.actionBtn, buttonThemeClass)}
              disabled={nextDisabled}
              size="sm"
              variant="primary"
              onClick={() => onSelect({ ...options, target: nextStep }, 'NextClicked')}
            >
              {nextText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
})

const MemoizedWizardContainer = memo(WizardContainer, (prevProps, nextProps) => {
  const checkIsPropertyEqual = (
    path: string,
    prevDefaultValue: any = null,
    nextDefaultValue: any = []
  ) => {
    const oldProperty = _.get(prevProps, path, prevDefaultValue)
    const newProperty = _.get(nextProps, path, nextDefaultValue)
    return _.isEqual(oldProperty, newProperty)
  }

  const arePluginsEqual = checkIsPropertyEqual('plugins')
  const areContainersEqual = checkIsPropertyEqual('containers')
  const areElementIdsEqual = checkIsPropertyEqual('container.data.elementIds')
  const areSettingsEqual = checkIsPropertyEqual('container.data.settings', null, {})
  const isDesignModeEqual = checkIsPropertyEqual('params.designMode', false, true)

  const arePropsEqual =
    arePluginsEqual &&
    areContainersEqual &&
    areElementIdsEqual &&
    areSettingsEqual &&
    isDesignModeEqual

  return arePropsEqual
})

export default MemoizedWizardContainer
