import React, { Component } from 'react'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { slvyToast } from '@/components'
import Button from './components/button'
import FileModal from './components/fileModal'
import './index.scss'

class PdfExport extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.setButtonState = this.setButtonState.bind(this)
    this.setEnabled = this.setEnabled.bind(this)
    this.setDisabled = this.setDisabled.bind(this)

    const { settings: { config: { buttonSettings: { disabledDefault = false } = {} } = {} } = {} } =
      props || {}

    this.state = {
      disabled: disabledDefault,
      isPdfReady: true,
      pending: false
    }
  }

  UNSAFE_componentWillMount() {
    this.props.registerMethod({
      key: 'setState',
      fn: this.setButtonState,
      args: [{ name: 'disabled', type: PluginTypes.boolean }]
    })

    this.props.registerMethod({
      key: 'setEnabled',
      fn: this.setEnabled,
      args: []
    })

    this.props.registerMethod({
      key: 'setDisabled',
      fn: this.setDisabled,
      args: []
    })

    this.props.registerMethod({
      key: 'exportPdf',
      fn: this.handleClick,
      args: []
    })
  }

  setEnabled() {
    this.setState({ disabled: false })
  }

  setDisabled() {
    this.setState({ disabled: true })
  }

  setButtonState({ disabled = false }) {
    this.setState({ disabled })
  }

  handleClick() {
    const {
      id,
      actualFilters,
      settings: { config: { pdfSettings: { orientation, mode } = {} } = {} } = {},
      client
    } = this.props

    if (_.isEqual(mode, 'Show in modal')) {
      this.setState({ isPdfReady: true, pending: true, pdfUrl: '' })
    }

    client
      .post(`/data/${id}/invoke/GeneratePdf`, {
        data: {
          arguments: actualFilters,
          orientation
        }
      })
      .then(
        function (res) {
          const file = res.PdfUrl
          if (_.isEqual(mode, 'Show in modal')) {
            this.setState({ pdfUrl: file, isPdfReady: false, pending: true })
          } else {
            const a = document.createElement('A')
            a.href = file
            a.download = file.substr(file.lastIndexOf('/') + 1)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log(err)
        slvyToast.error({
          message: 'An unknown error occurred!',
          title: 'Error'
        })
      })
  }

  render() {
    const { isPreviewMode: previewMode } = this.props
    const { disabled = false, pending = false, pdfUrl = '', isPdfReady } = this.state

    const className = `buttonPlugin-wrapper${previewMode ? ' preview-mode' : ''}`

    return (
      <div className={className}>
        <Button {...this.props} disabled={disabled} onClick={this.handleClick} />
        <FileModal
          isPdfReady={isPdfReady}
          pdfUrl={pdfUrl}
          show={pending}
          onClose={() => this.setState({ pending: false })}
        />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerMethod: props.registerMethod,
  settings: props.settings,
  id: props.id,
  actualFilters: props.actualFilters,
  client: props.client,
  isPreviewMode: props.isPreviewMode,
  params: props.params
})

export default createPlugin(PdfExport, selectConnectorProps)
