import cx from 'classnames'
import styles from './SlvyProgress.module.scss'
import loadingIcon from './solvoyo-loading.gif'
import pluginLoadingIcon from './plugin-loading.gif'
import { ISlvyProgressProps } from './SlvyProgress.types'

const classes =
  'd-flex w-100 h-100 top-0 start-0 bottom-0 end-0 m-auto position-fixed' +
  ' justify-content-center align-items-center flex-direction-column flex-column'

export default function SlvyProgress({ isLoading, type, children }: ISlvyProgressProps) {
  const isAlpha = type === 'alpha'
  return (
    <div
      className={cx(styles.slvyProgress, { 'position-relative h-100 m-0': !isLoading })}
      data-testid="slvy-progress"
    >
      {isLoading ? (
        <div
          className={cx(styles.slvyProgressLoadingContainer, classes, {
            [`h-100 ${styles.slvyProgressLoadingContainerAlpha}`]: isAlpha
          })}
        >
          <h3 className="w-100 text-center">Loading...</h3>
          <div className="w-100 text-center">
            <img
              alt="Loading"
              className="d-block my-2 mx-auto"
              height={isAlpha ? 89 : 150}
              src={isAlpha ? pluginLoadingIcon : loadingIcon}
              width={isAlpha ? 89 : 150}
            />
          </div>
        </div>
      ) : null}
      <div key="childrenElement" className="children h-100">
        {!isLoading || isAlpha ? children : null}
      </div>
    </div>
  )
}
