Ext.define('MDM.src.view.globalapp.Panel.Publish.numberColumn', {
  extend: 'Ext.grid.column.Column',
  alias: ['widget.uxnumbercolumn'],
  requires: ['Ext.util.Format'],
  defaultFilterType: 'number',

  /**
   * @cfg {String} format
   * A formatting string as used by {@link Ext.util.Format#number} to format a numeric
   * value for this Column.
   * @locale
   */
  format: '0,000.00',

  /**
   * @cfg {Object} renderer
   * @hide
   */

  /**
   * @cfg {Object} scope
   * @hide
   */

  /**
   * @cfg {Boolean} producesHTML
   * @inheritdoc
   */
  producesHTML: false,

  defaultRenderer: function (value) {
    if (value == 0) return '-'
    return Ext.util.Format.number(value, this.format)
  },

  updater: function (cell, value) {
    Ext.fly(cell).down(this.getView().innerSelector, true).innerHTML =
      Ext.grid.column.Number.prototype.defaultRenderer.call(this, value)
  }
})
