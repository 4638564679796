import { Form } from 'react-bootstrap'
import PrimaryButton from '../../PrimaryButton'
import {
  getReasonCodes,
  getServiceStatus,
  getSessions,
  setSessionStart
} from '../../../store/api/endpoints'
import { useReduxState } from '../../../hooks'
import { reasonCode, sessionSlice } from '../../../store/slices'
import SessionListModal from './SessionListModal'
import styles from './SessionForm.module.scss'

export default function SessionForm() {
  const [triggerGetReasonCodes, reasonCodesResponse] = getReasonCodes.useLazyQuery()
  const [triggerSetSessionStart] = setSessionStart.useLazyQuery()
  const [triggerGetSessions] = getSessions.useLazyQuery()
  const serviceStatusResponse = getServiceStatus.useQueryState()
  const [reasonCodeState, reasonCodeDispatch] = useReduxState(
    reasonCode.selectSlice,
    reasonCode.actions
  )
  const [, sessionDispatch] = useReduxState(undefined, sessionSlice.actions)

  const sessionName = serviceStatusResponse.data?.Session?.Description

  const handleChooseReason = () => {
    triggerGetReasonCodes()
  }

  const handleReasonChange = (event) => {
    const selectedReason = event.target.value
    const selectedReasonId = reasonCodesResponse?.data?.find(
      (item) => item.Description === selectedReason
    )?.Id

    reasonCodeDispatch.setReasonCode({
      description: selectedReason,
      id: selectedReasonId
    })
  }

  const handleStartSession = () => {
    const reasonCodeId = reasonCodesResponse?.data?.find(
      (item) => item.Description === reasonCodeState.description
    )?.Id
    triggerSetSessionStart({ description: sessionName, reasonCode: reasonCodeId })
  }

  const handleSessionList = () => {
    triggerGetSessions()
    sessionDispatch.setIsSessionListModalOpen(true)
  }
  return (
    <div className={styles.sessionForm}>
      <Form.Label
        className={styles.sessionFormLabel}
        htmlFor="choose-reason"
        onClick={handleChooseReason}
      >
        <Form.Select
          className={styles.sessionFormInput}
          id="choose-reason"
          onChange={handleReasonChange}
        >
          <option className={styles.sessionFormDefaultValue}>Choose Reason</option>
          {reasonCodesResponse.data?.map((reason) => (
            <option key={reason.Id} value={reason.Description}>
              {reason.Description}
            </option>
          ))}
        </Form.Select>
      </Form.Label>
      <Form.Label className={styles.sessionFormLabel} htmlFor="sessionName">
        <Form.Control
          className={styles.sessionFormInput}
          defaultValue={sessionName}
          id="session-name"
          placeholder="Session Name"
          type="text"
        />
      </Form.Label>
      <PrimaryButton
        className={styles.sessionFormButton}
        tooltipText="Start Session"
        onClick={handleStartSession}
      >
        <i className="slvy-ui-icon-caret-right fw-bold fs-6" />
      </PrimaryButton>
      <PrimaryButton
        className={styles.sessionFormButton}
        tooltipText="Session List"
        onClick={handleSessionList}
      >
        <i className="slvy-ui-icon-history fs-6" />
      </PrimaryButton>

      <SessionListModal />
    </div>
  )
}
