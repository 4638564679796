import _ from 'lodash'

const pubsub = () => {
  const handlers = {}
  const queue = {}

  return {
    subscribe(text, listener, filters) {
      handlers[text] = { text, filters, listener }
      if (queue[text]) {
        // console.log('pubsub: found in queue', text, queue[text])
        listener(queue[text])
        delete queue[text]
      }
    },

    unsubscribe(text) {
      delete handlers[text]
    },

    unsubscribeWithFilter(filter) {
      const found = _.filter(handlers, { filters: { ...filter } })
      if (!_.isEmpty(found)) {
        _.each(found, (f) => this.unsubscribe(f.text))
      }
    },

    publish(text, args) {
      if (_.has(handlers, text) && _.isFunction(handlers[text].listener)) {
        return handlers[text].listener(args)
      }
      // console.log('pubsub: no subscribers found', text, args)
      queue[text] = args
    },

    publishWithFilter(filter, args) {
      const handler = _.find(handlers, (h) => {
        return _.isEqual(_.differenceWith(filter, h.filters), [])
      })
      if (handler && _.isFunction(handler.listener)) {
        handlers.listener(args)
      }
    }

    // customizer (a, b) {},
    // publishFilter (filters) {
    //   const handlers = _.find(this.handlers, h => {
    //     return _.isEqualWith(h.filters, filters, this.customizer)
    //   })
    // }
  }
}

export default pubsub()
