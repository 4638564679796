Ext.define('Spui.view.dd.saleschart.mixin', {
  extend: 'Ext.Mixin',
  config: {
    centerPanel: {},
    westPanel: {},
    ratioChartCfg: 'RatioChart',
    ratioChartStoreCfg: 'RatioChartStore',
    ratioChartStore: {},
    ratioChart: {},
    recordData: {},
    storeListStoreCfg: 'RatioGridStore',
    storeListStore: {},
    storeListCfg: 'RatioGrid',
    storeList: {},
    salesChartStoreCfg: 'RatiosalesChartStore',
    salesChartStore: {},
    salesChartCfg: 'RatioSalesChart',
    salesChart: {}
  },
  defaults: {
    collapsible: true,
    split: true,
    bodyPadding: 10
  },
  applyRatioChartCfg: function (c) {
    return window.ss(c)
  },
  applyRatioChartStoreCfg: function (c) {
    return window.ss(c)
  },
  applyStoreListCfg: function (c) {
    return window.ss(c)
  },
  applyStoreListStoreCfg: function (c) {
    return window.ss(c)
  },
  applySalesChartCfg: function (c) {
    return window.ss(c)
  },
  applySalesChartStoreCfg: function (c) {
    return window.ss(c)
  },
  applySalesChartStore: function (b) {
    var me = this
    var setRec = this.getRatioChartStoreCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        forcetoLoad: true,
        fields: [
          { name: 'QTY', type: 'number' },
          { name: 'QTY2', type: 'number' },
          { name: 'Price', type: 'number' },
          { name: 'NumberOfStore', type: 'number' },
          { name: 'DateKey', type: 'string' },
          {
            name: 'PriceNull',

            convert: function (value, record) {
              var v = record.get('Price')

              if (!v) return null
              return v
            },

            depends: ['Price']
          }
        ],

        proxy: {
          type: 'ajax',
          timeout: 60000,

          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: Spui.Global.getApiUrl() + 'spui/GetSalesChartDataByStore'
        },
        autoLoad: false
      })
      b = Ext.create('Ext.data.Store', b)
      b.loadData([
        {
          DateKey: 1
        }
      ])
      b.on(
        'beforeLoad',
        function () {
          this.getSalesChart().setLoading(tt('Yükleniyor'))
        },
        this
      )
      b.on(
        'load',
        function () {
          this.getSalesChart().setLoading(false)
        },
        this
      )
    }
    return b
  },

  applySalesChart: function (b) {
    var setRec = this.getSalesChartCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'spui-view-dd-saleschart-saleschart',
        store: this.getSalesChartStore()
      })
      var c = {
        xtype: 'panel',
        layout: 'fit',
        plugins: [
          {
            ptype: 'cornerIcon',
            extraButton: [
              {
                iconCls: 'x-fa fa-arrows-alt',
                handler: this.onMaximize,
                scope: this
              },
              {
                iconCls: 'x-fa fa-cog',

                menu: [
                  {
                    text: 'Chart Config',
                    handler: this.onConfigSalesChart,
                    scope: this
                  }
                ]
              }
            ]
          }
        ],
        items: [b]
      }
      b = Ext.create(c)
    }
    return b
  },
  onConfigSalesChart: function () {
    var setRec = this.getSalesChartCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          // enableLiveAutocompletion: false,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigSalesChartSave, this)
  },
  onConfigSalesChartSave: function (v, k) {
    var setRec = this.getSalesChartCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setSalesChart(res)
  },
  updateSalesChart: function (f, g) {
    var d = this.getCenterPanel()
    if (g) {
      d.remove(g)
      d.add(f)
      return
    }
    d.add(f)
    return

    if (f) {
      if (d) d = d.items
      d.add(f)
      if (d) {
        if (d.isMixedCollection) {
          d.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  applyCenterPanel: function (b) {
    if (b && !b.isComponent) {
      // Ext.applyIf(b, res);

      Ext.applyIf(b, {
        xtype: 'panel',
        region: 'center',
        layout: 'fit'
      })

      b = Ext.create(b)
    }
    //b.getStore().load();

    //store.fireEvent('load',store);
    return b
  },
  updateCenterPanel: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  applyWestPanel: function (b) {
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        xtype: 'panel',
        title: tt('West title1'),
        region: 'west',
        width: 600,
        minWidth: 250,
        maxWidth: 2000,
        layout: {
          type: 'vbox',
          pack: 'start',
          align: 'stretch'
        },
        bodyPadding: 10
      })
      b = Ext.create(b)
    }
    return b
  },
  updateWestPanel: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  applyRatioChartStore: function (b) {
    var me = this
    var setRec = this.getRatioChartStoreCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        forcetoLoad: true,
        fields: [
          { name: 'Ratio', type: 'int' },
          { name: 'Qty', type: 'number' },
          { name: 'RunningTotal', type: 'number' },
          { name: 'DaylyPercent', type: 'number' },
          { name: 'ParetoPercent', type: 'number' },
          { name: 'ParetoDayNumber', type: 'int' }
        ],

        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          extraParams: {
            recordData: Ext.encode(this.getRecordData())
          },
          url: Spui.Global.getApiUrl() + 'spui/GetSalesRatio'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)
      b.loadData([
        {
          a: 1
        }
      ])
    }
    return b
  },
  applyRatioChart: function (b) {
    var me = this
    var setRec = this.getRatioChartCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'spui-view-dd-saleschart-ratiochart',
        store: this.getRatioChartStore(),
        columns: [{ text: 'denemde' }],
        height: 400
      })
      var c = {
        xtype: 'container',
        height: 400,
        layout: 'fit',
        plugins: [
          {
            ptype: 'cornerIcon',
            extraButton: [
              {
                iconCls: 'x-fa fa-arrows-alt',
                handler: this.onMaximize,
                scope: this
              },
              {
                iconCls: 'x-fa fa-cog',

                menu: [
                  {
                    text: 'Chart Config',
                    handler: this.onConfigRatioChart,
                    scope: this
                  },
                  {
                    text: 'store Config'
                    //handler: 'onConfigNormalizedStore',
                  }
                ]
              }
            ]
          }
        ],

        items: [b]
      }
      b = Ext.create(c)
      b.on(
        'clickChart',
        function (record) {
          var recordData = record.data,
            store = this.getStoreListStore()
          store.getProxy().setExtraParam('Ratio', record.get('Ratio'))
          store.load()
        },
        me
      )
    }
    this.getRatioChartStore().load()

    return b
  },
  updateRatioChart: function (f, g) {
    var d = this.getWestPanel()
    if (g) {
      d.remove(g)
    }
    d.insert(0, f)
    return
    if (f) {
      if (d) d = d.items

      if (d) {
        if (d.isMixedCollection) {
          d.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        d.items = [f]
      }
    }
  },
  applyStoreListStore: function (b) {
    var me = this
    var setRec = this.getRatioChartStoreCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        forcetoLoad: true,
        fields: [
          { name: 'Ratio', type: 'int' },
          { name: 'Qty', type: 'number' },
          { name: 'Inventory', type: 'number' },
          { name: 'InvoicedQuantity', type: 'number' },
          { name: 'ParetoPercent', type: 'number' },
          { name: 'ParetoDayNumber', type: 'int' }
        ],
        groupField: 'DcName',
        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          extraParams: {
            recordData: Ext.encode(this.getRecordData()),
            Ratio: 0
          },
          url: Spui.Global.getApiUrl() + 'spui/GetSalesDcCodeByRatio'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)

      b.loadData([
        {
          a: 1
        }
      ])

      b.on('load', me.onLoadStoreListStore, me)
    }
    return b
  },
  onLoadStoreListStore: function (store) {
    var rec = store.first(),
      grid = this.getStoreList()
    if (rec) {
      grid.selModel.doSelect(rec)
    }
  },
  applyStoreList: function (b) {
    var setRec = this.getStoreListCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'grid',
        store: this.getStoreListStore(),
        columns: [
          {
            text: 'StoreCode'
          }
        ],
        flex: 1,
        plugins: [
          {
            ptype: 'cornerIcon',
            extraButton: [
              {
                iconCls: 'x-fa fa-arrows-alt',
                handler: this.onMaximize,
                scope: this
              },
              {
                iconCls: 'x-fa fa-cog',
                menu: [
                  {
                    text: 'Grid Config',
                    handler: this.onConfigStoreList,
                    scope: this
                  },
                  {
                    text: 'store Config'
                  }
                ]
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    b.getStore().load()
    return b
  },
  updateStoreList: function (f, g) {
    var d = this.getWestPanel()
    if (g) {
      d.remove(g)
    }
    d.insert(1, f)
    return
    if (f) {
      if (d) d = d.items
      if (d) {
        if (d.isMixedCollection) {
          d.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        d.items = [f]
      }
    }
  },
  onConfigRatioChart: function () {
    var setRec = this.getRatioChartCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigRatioChartSave, this)
  },
  onConfigRatioChartSave: function (v, k) {
    var setRec = this.getRatioChartCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRatioChart(res)
  },
  onConfigRatioChartStore: function () {
    var setRec = this.getRatioChartStoreCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigRatioChartStoreSave, this)
  },
  onConfigRatioChartStoreSave: function (v, k) {
    var setRec = this.getRatioChartStoreCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRatioChartStore(res)
  },
  onConfigStoreList: function () {
    var setRec = this.getStoreListCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigStoreListSave, this)
  },
  onConfigStoreListSave: function (v, k) {
    var setRec = this.getStoreListCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setStoreList(res)
  }
})
