import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, isNil } from 'lodash'
import dependencyFilters from '../../utils/dependencyFilters'
import { makeRequest, getIsActualSeason, getSelectedFilters } from '../../utils'
import diacritics from '../../utils/diacritics'
import UpdateButton from '../Actions/Update'
import ApproveButton from '../Actions/Approve'
import ReloadButton from '../Actions/Reload'
import { resetOptions } from '../../store/slices/options'
import { getFilter, setLoaderReducer } from '../../store/slices/appSlice'

const defaultDependencyFilters = [...dependencyFilters]

const selectFromState = (state) => ({
  rootOptions: state.options.rootOptions,
  isLoadingRootOptions: state.options.isLoading,
  errorRootOptions: state.options.error,
  scenarioName: state.treeSelection.tree.scenarioName,
  seasonValues: state.app.seasonValues,
  seasonValuesError: state.app.seasonValuesError,
  seasonValuesLoading: state.app.seasonValuesLoading,
  selectedScenarioTree: state.treeSelection.tree,
  viewType: state.app.viewType
})

function Header(props) {
  const dispatch = useDispatch()
  const {
    rootOptions,
    isLoadingRootOptions,
    errorRootOptions,
    scenarioName,
    seasonValues,
    seasonValuesError,
    seasonValuesLoading,
    selectedScenarioTree,
    viewType
  } = useSelector(selectFromState)
  const {
    changeScenario = () => {},
    headerButtons = [],
    onSaveAsScenarioToggle = () => {},
    onTestProductsToggle = () => {},
    pluginId = '',
    viewHandler = () => {},
    assortmentApprovedEventRef,
    headerButtonsEventRef
  } = props
  const [isActualSeason, setIsActualSeason] = useState(false)
  const { AssortmentId, ClusterName = '', Filters = [] } = rootOptions
  const { currentScenario: { Status: currentScenarioStatus = '' } = {} } = selectedScenarioTree

  const selectedFilters = getSelectedFilters(
    defaultDependencyFilters,
    !isNil(Filters) ? [...Filters] : []
  )
  const canApprove = currentScenarioStatus !== 'APPROVED'

  useEffect(() => {}, [Filters])
  useEffect(() => {
    checkIsActualSeason()
  }, [])
  useEffect(() => {
    if (seasonValuesLoading) {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Actual Season Checking' }))
    } else {
      if (!isEmpty(seasonValues)) {
        setIsActualSeason(getIsActualSeason(Filters, seasonValues))
      }
      dispatch(setLoaderReducer({ isShown: false, messages: 'Actual Season Checking' }))
    }
  }, [seasonValuesLoading, seasonValuesError])

  const checkIsActualSeason = () => {
    const payload = {
      pluginId,
      dropdownKey: 'SeasonCode',
      requestMethod: 'post',
      body: {
        Name: 'SeasonCode'
      }
    }

    dispatch(getFilter({ ...payload, method: 'Filter' }))
  }

  const handleChangeScenario = () => {
    changeScenario(true)
    dispatch(resetOptions())
  }

  const handleTestProductsToggle = (isActualSeason) => {
    isActualSeason && onTestProductsToggle(true, 'Create')
  }

  const onEventHandler = (eventName) => {
    const fn = headerButtonsEventRef.current?.[`headerButton_${eventName}_Clicked`]

    if (typeof fn === 'function') {
      fn({ AssortmentId })
    }
  }

  const onHeaderButtonClick = (name) => {
    const eventName = diacritics.removeAndLowerCase(name)
    onEventHandler(eventName)
  }

  return (
    <div className="top-wrp flex-grow-1">
      {viewType !== 'Old View' ? (
        <div className="option-name-ctn" onClick={viewHandler}>
          <span
            className={`${
              viewType === 'optionView'
                ? 'slvy-ui-icon-arrow-left-extra-lt'
                : 'slvy-ui-icon-burger-lt'
            }`}
          />
          <div className="option-segment">
            {viewType === 'optionView' ? 'Segment View' : 'Option View'}
          </div>
        </div>
      ) : (
        <div className="option-name-ctn" />
      )}
      <div className="top-nav">
        <div className="top-nav-left">
          <div className="option-btn selected-list-area">
            <div className="option-name">{scenarioName}</div>
            <div className="selected-list">
              <h5 className="cluster-title">
                <span>Cluster</span>
                <p>{ClusterName}</p>
              </h5>
              <ul className="selected-filters-list">
                {selectedFilters.map(({ Key = '', Value = '' }) => {
                  return (
                    <li key={Key}>
                      <strong>{Key}</strong>
                      <p>{isEmpty(Value) ? '-' : Value}</p>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="top-nav-options">
          {headerButtons.map((button, index) => {
            const { iconName = '', name = '' } = button
            return (
              <div
                key={index}
                className="option-btn"
                onClick={() => {
                  onHeaderButtonClick(name)
                }}
              >
                <i className={iconName} />
                <span>{name}</span>
              </div>
            )
          })}

          <div className="option-btn" onClick={handleChangeScenario}>
            <i className="slvy-ui-icon-scenario-change-regular" />
            <span>Change Assortment</span>
          </div>
          <ReloadButton isActualSeason={isActualSeason} pluginId={pluginId} />
          <ApproveButton
            assortmentApprovedEventRef={assortmentApprovedEventRef}
            canApprove={canApprove}
            pluginId={pluginId}
          />
          <UpdateButton isActualSeason={isActualSeason} pluginId={pluginId} />
          <div
            className="option-btn"
            onClick={() => {
              onSaveAsScenarioToggle(true)
            }}
          >
            <i className="slvy-ui-icon-save-as" />
            <span>Save As</span>
          </div>
          <div
            className={`option-btn ${isActualSeason ? '' : '-disabled'}`}
            onClick={() => {
              handleTestProductsToggle(isActualSeason)
            }}
          >
            <i className="slvy-ui-icon-box-checked" />
            <span>Create Test Products</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
