export default class BoundedQueue<TItem> {
  private _boundary: number

  private _items: TItem[]

  constructor(boundary: number) {
    this._boundary = boundary
    this._items = []
  }

  get items() {
    return this._items
  }

  get isEmpty() {
    return this._items.length === 0
  }

  get size() {
    return this._items.length
  }

  enqueue(item: TItem) {
    this._items.push(item)
    this._ensureBoundary()

    return this
  }

  dequeue() {
    this._items.shift()

    return this
  }

  peek() {
    return this._items.at(0)
  }

  peekBack() {
    return this._items.at(-1)
  }

  clear() {
    this._items = []

    return this
  }

  private _ensureBoundary() {
    if (this._items.length > this._boundary) {
      this.dequeue()
    }
  }
}
