export default `({
    autoLoad: true,
    proxy: {
        type: 'ajax',
        url: Spui.Global.getApiUrl() + '/spui/SpotAnalizDetay',
        reader: {
            type: 'json',
        }
    },
    fields:[
{name:'MaterialDescription',type:'string'},
{name:'H1C',type:'string'},
{name:'H1N',type:'string'},
{name:'H2C',type:'string'},
{name:'H2N',type:'string'},
{name:'H3C',type:'string'},
{name:'H3N',type:'string'},
{name:'WeekNumber',type:'string'},
{name:'StartDate',type:'date',dateFormat:tt('Y-m-d H:i:s.u')},
{name:'EndDate',type:'date',dateFormat:tt('Y-m-d H:i:s.u')},
{name:'DurationInWeek',type:'int'},
{name:'Price',type:'number'},
{name:'TotalInvoicedQuantityDay1',type:'number'},
{name:'TotalInvoicedQuantityDay2',type:'number'},
{name:'TotalInvoicedQuantityDay3',type:'number'},
{name:'TotalInvoicedQuantityDay4',type:'number'},
{name:'TotalInvoicedQuantityDay5',type:'number'},
{name:'TotalInvoicedQuantityDay6',type:'number'},
{name:'TotalInvoicedQuantityDay7',type:'number'},
{name:'TotalNetValueoftheSalesOrderDay1',type:'number'},
{name:'TotalNetValueoftheSalesOrderDay2',type:'number'},
{name:'TotalNetValueoftheSalesOrderDay3',type:'number'},
{name:'TotalNetValueoftheSalesOrderDay4',type:'number'},
{name:'TotalNetValueoftheSalesOrderDay5',type:'number'},
{name:'TotalNetValueoftheSalesOrderDay6',type:'number'},
{name:'TotalNetValueoftheSalesOrderDay7',type:'number'},
{name:'NumberOfDistinctDC',type:'int'},
{name:'NumberOfDistinctStore',type:'int'},
{name:'TotalNetValueoftheSalesOrder',type:'number'},
{name:'TotalInvoicedQuantity',type:'number'},
{name:'MaterialCode',type:'string'},
{name:'Status',type:'int'}],
})
`
