import _ from 'lodash'

const isKeyOfObject = <T extends object>(obj: T, key: string | number | symbol): key is keyof T =>
  _.has(obj, key)

const basicTypes = {
  string: { type: 'string', isMultiple: false },
  int: { type: 'int', isMultiple: false },
  object: { type: 'object', isMultiple: false },
  array: { type: 'array', isMultiple: true },
  boolean: { type: 'bool', isMultiple: false },
  datetime: { type: 'datetime', isMultiple: false },
  dynamic: { type: 'dynamic', isMultiple: false }
} as const
type BasicTypes = typeof basicTypes
type BasicTypeKey = keyof BasicTypes
export type BasicType = BasicTypes[BasicTypeKey] & { oneOf?: BasicType | BasicType[] }

const maps = {
  string: 'string',
  int: 'int',
  float: 'int',
  double: 'int',
  decimal: 'int',
  long: 'int',
  short: 'int',
  guid: 'string',
  bool: 'boolean',
  boolean: 'boolean',
  datetime: 'datetime'
} as const
export type MapKey = keyof typeof maps

type SystemType = BasicTypeKey | MapKey

const PluginTypes = {
  ...basicTypes,
  getFromSystemTypes: (typeLabel: SystemType): BasicTypeKey => {
    return isKeyOfObject(maps, typeLabel) ? maps[typeLabel] : typeLabel
  },
  oneOf: (...pluginTypes: BasicType[]): BasicType[] => {
    return pluginTypes
  },
  arrayOf: (pluginType: BasicType): BasicType => {
    return { ...PluginTypes.array, oneOf: pluginType }
  },
  objectOf: (pluginType: BasicType): BasicType => {
    return { ...PluginTypes.object, oneOf: pluginType }
  },
  toString: (pluginType: BasicType): string => {
    if (_.isNil(pluginType)) return '{string}'
    return (
      pluginType.type +
      (pluginType.oneOf !== undefined
        ? `{${
            Array.isArray(pluginType.oneOf)
              ? _.map(pluginType.oneOf, (o) => PluginTypes.toString(o))
              : PluginTypes.toString(pluginType.oneOf)
          }}`
        : '')
    )
  },
  fromString: (val: SystemType | `array{${SystemType}}`): BasicType => {
    const regex = /array{([a-z]+)}/g
    const match = regex.exec(val)
    if (match) {
      return PluginTypes.arrayOf(
        PluginTypes[PluginTypes.getFromSystemTypes(match[1] as SystemType)]
      )
    }
    return PluginTypes[PluginTypes.getFromSystemTypes(val as SystemType)]
  }
} as const

export default PluginTypes
