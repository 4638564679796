import { useEffect } from 'react'

export default function Success({ isLoading, onMount }) {
  useEffect(() => {
    onMount()
  }, [])

  return (
    <div className="success-view">
      {isLoading ? (
        <span className="loading-message">
          <i className="fa-fw fa fa-spinner" /> Copying...
        </span>
      ) : (
        <span className="success-message">
          <i className="fa-fw fa slvy-ui-icon-check-circle-regular" />
          Page copied!
        </span>
      )}
    </div>
  )
}
