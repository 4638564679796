import React from 'react'
import { SlvySelect } from '../../../../../../components'
import styles from './index.module.scss'

export default function DestSelect({
  sourceResource,
  sourcePeriod,
  destResource,
  destPeriod,
  resources,
  periods,
  onChangeDest
}) {
  return (
    <div className={styles.destSelect}>
      <div className={styles.destSelectHeader}>Select Source and Destination</div>
      <div className={styles.destSelectBody}>
        <div className={styles.destSelectRow}>
          <label className={styles.destSelectSourceResource}>
            Source Resource
            <SlvySelect
              className={styles.destSelectSelect}
              isDisabled={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={resources}
              value={sourceResource}
              onChange={(destResource) => onChangeDest(destResource, 'sourceResource')}
            />
          </label>
          <label className={styles.destSelectSourceResource}>
            Destination Resource
            <SlvySelect
              className={styles.destSelectSelect}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={resources}
              value={destResource}
              onChange={(destResource) => onChangeDest(destResource, 'destResource')}
            />
          </label>
        </div>
        <div className={styles.destSelectRow}>
          <label className={styles.destSelectSourcePeriod}>
            Source Period
            <SlvySelect
              className={styles.destSelectSelect}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={periods}
              value={sourcePeriod}
              onChange={(sourcePeriod) => onChangeDest(sourcePeriod, 'sourcePeriod')}
            />
          </label>
          <label className={styles.destSelectSourcePeriod}>
            Destination Period
            <SlvySelect
              className={styles.destSelectSelect}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={periods}
              value={destPeriod}
              onChange={(destPeriod) => onChangeDest(destPeriod, 'destPeriod')}
            />
          </label>
        </div>
      </div>
    </div>
  )
}
