import { Component } from 'react'
import { SketchPicker } from 'react-color'
import { Button, DropdownButton, Dropdown } from 'react-bootstrap'
import './StyleSelector.scss'

export default class StyleSelector extends Component {
  constructor(props) {
    super(props)
    this.handleChangeColor = this.handleChangeColor.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleBoldButtonClick = this.handleBoldButtonClick.bind(this)
    this.handleItalicButtonClick = this.handleItalicButtonClick.bind(this)
    this.handleFontSizeSelect = this.handleFontSizeSelect.bind(this)

    // this.handleClick = this.handleClick.bind(this)
    // this.handleChangeColor = this.handleChangeColor.bind(this)
    // this.handleClose = this.handleClose.bind(this)

    this.state = {
      displayColorPicker: false
    }
  }

  getValue() {
    return this.state.style.color
  }

  componentToHex(c) {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  rgbToHex(r, g, b, a) {
    return `#${this.componentToHex(r).toUpperCase()}${this.componentToHex(
      g
    ).toUpperCase()}${this.componentToHex(b).toUpperCase()} ${parseInt(a * 100, 10)}%`
  }

  rgb2hex(rgba) {
    let inputValue = rgba
    inputValue = inputValue.replace('rgba(', '')
    inputValue = inputValue.replace(')', '')
    const values = inputValue.split(',')

    return this.rgbToHex(
      parseInt(values[0], 10),
      parseInt(values[1], 10),
      parseInt(values[2], 10),
      parseFloat(values[3])
    )
  }

  getFormattedValue() {
    if (this.state.style.color) {
      if (this.state.style.color.startsWith('#')) {
        return `${this.state.style.color.toUpperCase()} ${100}%`
      }
      return this.rgb2hex(this.state.style.color)
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleChangeColor = (color) => {
    const colorCode = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`

    const newStyle = { ...this.props.value, color: colorCode }

    this.props.onChange(newStyle)
  }

  handleClear(e) {
    e.preventDefault()
    e.stopPropagation()

    const newStyle = { ...this.props.value, color: '' }

    this.props.onChange(newStyle)
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleBoldButtonClick() {
    let newStyle = { ...this.props.value, fontWeight: 'bold' }
    if (this.props.value && this.props.value.fontWeight === 'bold') {
      newStyle = { ...this.props.value, fontWeight: 'normal' }
    }

    this.props.onChange(newStyle)
  }

  handleItalicButtonClick() {
    let newStyle = { ...this.props.value, fontStyle: 'italic' }
    if (this.props.value && this.props.value.fontStyle === 'italic') {
      newStyle = { ...this.props.value, fontStyle: 'normal' }
    }

    this.props.onChange(newStyle)
  }

  handleFontSizeSelect(eventKey, event) {
    const newStyle = { ...this.props.value, fontSize: eventKey }

    this.props.onChange(newStyle)
  }

  render() {
    const style = this.props.value

    const popover = {
      position: 'absolute',
      zIndex: '2'
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
    const color = {
      background: style && style.color
    }

    let bold
    if (style && style.fontWeight === 'bold') {
      bold = true
    }

    let italic
    if (style && style.fontStyle === 'italic') {
      italic = true
    }

    let fontSize = ''
    if (style && style.fontSize) {
      fontSize = style.fontSize
    }

    return (
      <div className="form-group stylePicker">
        <label className=" col-form-label">{this.props.title}</label>
        <div className="styleContainer">
          <Button
            active={bold}
            className="boldButton"
            size="sm"
            variant="outline-dark"
            onClick={this.handleBoldButtonClick}
          >
            B
          </Button>
          <Button
            active={italic}
            className="italicButton"
            size="sm"
            variant="outline-dark"
            onClick={this.handleItalicButtonClick}
          >
            I
          </Button>
          <DropdownButton
            className="fontSizeDropdown float-start"
            id="dropdown-size-small"
            size="sm"
            title={fontSize || '    '}
            onSelect={this.handleFontSizeSelect}
          >
            <Dropdown.Item eventKey="" />
            <Dropdown.Item eventKey="5px">5px</Dropdown.Item>
            <Dropdown.Item eventKey="6px">6px</Dropdown.Item>
            <Dropdown.Item eventKey="7px">7px</Dropdown.Item>
            <Dropdown.Item eventKey="8px">8px</Dropdown.Item>
            <Dropdown.Item eventKey="9px">9px</Dropdown.Item>
            <Dropdown.Item eventKey="10px">10px</Dropdown.Item>
            <Dropdown.Item eventKey="11px">11px</Dropdown.Item>
            <Dropdown.Item eventKey="12px">12px</Dropdown.Item>
            <Dropdown.Item eventKey="13px">13px</Dropdown.Item>
            <Dropdown.Item eventKey="14px">14px</Dropdown.Item>
            <Dropdown.Item eventKey="15px">15px</Dropdown.Item>
            <Dropdown.Item eventKey="16px">16px</Dropdown.Item>
            <Dropdown.Item eventKey="17px">17px</Dropdown.Item>
            <Dropdown.Item eventKey="18px">18px</Dropdown.Item>
            <Dropdown.Item eventKey="19px">19px</Dropdown.Item>
            <Dropdown.Item eventKey="20px">20px</Dropdown.Item>
            <Dropdown.Item eventKey="25px">25px</Dropdown.Item>
            <Dropdown.Item eventKey="30px">30px</Dropdown.Item>
            <Dropdown.Item eventKey="40px">40px</Dropdown.Item>
          </DropdownButton>

          <div className="colorBox" onClick={this.handleClick}>
            <div className="innerColorBox" style={color} />
            <button className="clearButton">
              <i className="fa fa-times" onClick={this.handleClear} />
            </button>
          </div>

          {this.state.displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={this.handleClose} />
              <SketchPicker color={style && style.color} onChange={this.handleChangeColor} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
