import { useEffect } from 'react'
import { HideOverlayProps } from './useHideOverlay.types'

const useHideOverlay = ({ isPluginDataLoading, gridRef, rowData }: HideOverlayProps) => {
  useEffect(() => {
    if (isPluginDataLoading) {
      return
    }

    // TODO: This is a workaround to fix below problem
    // Ag-Grid Cant load empty
    // If a page starts without loading the data of ag-grid,
    // Ag-Grid stucks on loading state until its data is loaded.
    // Does not get the same error with blank filter
    // https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/15/menu/65cf4b23bc292b2c9af080e1/page/65d390417a64b58b0d18f1da
    const overlayTimeout = setTimeout(() => {
      if (Array.isArray(rowData) && !rowData.length) {
        return
      }
      gridRef?.current?.api?.setGridOption?.('loading', false)
    }, 3000)

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(overlayTimeout)
  }, [isPluginDataLoading, gridRef, rowData])
}

export default useHideOverlay
