import { useEffect, useMemo, useRef } from 'react'
import { SlvyTable } from '@/components'
import { useReduxState } from '../../../hooks'
import { getLoadTable } from '../../../store/api/endpoints'
import { tuiSlice, loadTableSlice, loadTreeTableSlice } from '../../../store/slices'
import { getEnabledColumns, getTableQueryParams } from '../../../utils'

export default function LoadTable() {
  const [{ config }] = useReduxState(tuiSlice.selectSlice)
  const [loadTreeTable] = useReduxState(loadTreeTableSlice.selectSlice)
  const [loadTableState, loadTableDispatch] = useReduxState(
    loadTableSlice.selectSlice,
    loadTableSlice.actions
  )
  const [triggerGetLoadTable, loadTableResponse] = getLoadTable.useLazyQuery()
  const tableRef = useRef(null)
  const columns = useMemo(
    () => [
      {
        id: 'map-button',
        maxSize: 80,
        cell: () => (
          <span>
            <i
              className="fa fa-fw fa fa-map"
              style={{ color: 'rgba(0, 0, 255, 0.5)', fontSize: 18, marginLeft: 0 }}
            />
          </span>
        )
      },
      ...getEnabledColumns(config.loadTable.columns)
    ],
    []
  )

  useEffect(() => {
    if (loadTreeTable.selectedRows.length) {
      triggerGetLoadTable({
        loadIds: loadTreeTable.loadStops,
        ...getTableQueryParams(config, 'loadTable')
      })
    }
  }, [JSON.stringify(loadTreeTable.loadStops)])

  useEffect(() => {
    if (loadTableResponse.isSuccess) {
      loadTableDispatch.setSelectedRow(loadTableResponse.data.at(0))
      // tableRef.current.setState((prevState) => ({
      //   ...prevState,
      //   rowSelection: { [loadTable.data.at(0).ID]: true }
      // }))
    }
  }, [loadTableResponse.isSuccess])

  function handleSelectRow({ original }) {
    loadTableDispatch.setSelectedRow(original.ID)
  }

  function getRowId({ ID }) {
    return ID
  }

  return (
    <SlvyTable
      rowSelection
      columns={columns}
      data={loadTableResponse.data}
      getRowId={getRowId}
      grouping={config.loadTable.isGroupingEnabled}
      isLoading={!loadTableResponse.data?.length}
      tableRef={tableRef}
      theme="sencha-grid"
      onSelectRow={handleSelectRow}
    />
  )
}
