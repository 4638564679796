import { put } from 'redux-saga/effects'
import _ from 'lodash'
import { setLoadingStatus } from '../../../store/slices/setting'

export default function* loadingJob(props, result) {
  const newProps = _.isEmpty(props)
    ? {
        action: true,
        text: 'Please Wait...'
      }
    : props

  yield put(setLoadingStatus(newProps))

  return result
}
