// @flow
export const colors = {
  blue: {
    deep: 'rgb(0, 121, 191)',
    light: 'lightblue',
    lighter: '#d9fcff'
  },
  black: '#4d4d4d',
  shadow: 'rgba(0,0,0,0.2)',
  grey: '#E2E4E6',
  green: 'rgb(185, 244, 188)',
  white: 'white',
  purple: 'rebeccapurple'
}

export const grid = 8

export const borderRadius = 2

export const filterOptionData = [
  {
    value: 'My tasks',
    key: 'MyTasks'
  },
  {
    value: 'Reported by me',
    key: 'ReportedByMe'
  },
  {
    value: 'All',
    key: 'All'
  }
]

export const sortOptionData = [
  {
    value: 'Created Date',
    key: 'CreatedDate'
  },
  {
    value: 'Due Date',
    key: 'TaskDuedate'
  },
  {
    value: 'Priority',
    key: 'TaskPriority'
  },
  {
    value: 'Category',
    key: 'TaskCategory'
  }
]
