import { OverlayTrigger, Popover } from 'react-bootstrap'
import { WORKFLOW_INFO } from './constants'
import { WorkflowInformationProps } from './WorkflowInformation.types'

const WorkflowInformation = ({ title }: WorkflowInformationProps) => {
  const information = WORKFLOW_INFO[title as keyof typeof WORKFLOW_INFO]

  const overlay = (
    <Popover id="WorkflowInformation">
      <Popover.Header as="h6" className="mb-0">
        {title}
      </Popover.Header>
      <Popover.Body className="m-0 py-2">{information}</Popover.Body>
    </Popover>
  )

  return information ? (
    <OverlayTrigger rootClose overlay={overlay} placement="top" trigger="click">
      <i
        className="fa fa-info-circle actions-information"
        data-testid="workflow-information"
        title="Info"
      />
    </OverlayTrigger>
  ) : null
}
export default WorkflowInformation
