import { Component } from 'react'
import { Row, Col, Modal, FormLabel, Button, OverlayTrigger, Card } from 'react-bootstrap'
/// @V2-TEST
import _ from 'lodash'
import { SlvyFormInput, SlvyFormSelect, slvyToast, SlvyJsonTable } from '..'
import { mapFromCrudList } from '../../helpers/mapper'
import toastrTypes from '../../helpers/ToastrTypes'
import './RemoteActions.scss'

import { isSuccess } from '../../crudoptV3'

const actionTypeSP = 'Stored Procedure'
const actionTypeJob = 'Job'

export default class RemoteActions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      remoteActions: [],
      show: false,
      selectedActionName: '',
      actionName: '',
      actionConnection: '',
      actionType: '',
      actionJob: '',
      actionSP: '',
      actionParameters: [],
      actionIsCloneJob: '',
      error: { ModelState: {} },
      relationalJobId: '',
      disabled: true
    }

    this.handleSubmit = this.handleSubmit.bind(this)

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.handleRemoteActionSave = this.handleRemoteActionSave.bind(this)
    this.handleRemoteActionChange = this.handleRemoteActionChange.bind(this)
    this.handleRowClick = this.handleRowClick.bind(this)
    this.handleActionParameterAdd = this.handleActionParameterAdd.bind(this)
    this.handleActionParameterChange = this.handleActionParameterChange.bind(this)
    this.handleActionJobIdChanged = this.handleActionJobIdChanged.bind(this)
    this.remoteActionInfo = this.remoteActionInfo.bind(this)
    this.handleRemoteActionDelete = this.handleRemoteActionDelete.bind(this)
    this.disableDeleteButton = this.disableDeleteButton.bind(this)
  }

  showModal(e) {
    this.setState({
      show: true,
      selectedActionName: '',
      actionName: '',
      actionConnection: '',
      actionType: actionTypeSP,
      actionJob: '',
      actionSP: '',
      actionParameters: [],
      actionIsCloneJob: '',
      error: { ModelState: {} },
      relationalJobId: ''
    })
    if (e.target.value === 'add-action-button') {
      this.setState({
        disabled: false
      })
    }
  }

  hideModal() {
    this.setState({
      show: false,
      selectedActionName: '',
      actionName: '',
      actionParameters: [],
      error: { ModelState: {} },
      relationalJobId: '',
      disabled: true
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    slvyToast.success({ message: `Remote Action ${toastrTypes.true.updateMsg}` })
    const { remoteActions } = this.state

    const newArr = remoteActions.map(({ used, ...rest }) => {
      return rest
    })

    this.props.onSubmit(newArr)

    return false
  }

  handleRowClick(event, rowData) {
    if (event.target.value == 'remote-detail' || event.target.value == 'remote-action-delete') {
      return
    }
    const row = _.find(this.state.remoteActions, (row) => row.name === rowData.name)

    this.setState({
      show: true,
      selectedActionName: row.name,
      actionName: row.name,
      actionConnection: row.connectionId,
      actionType: row.type,
      actionJob: row.jobId,
      actionSP: row.sp,
      actionParameters: row.parameters || [],
      actionIsCloneJob: row.isCloneJob,
      error: { ModelState: {} },
      relationalJobId: 'relationalJobId' in row ? row.relationalJobId : ''
    })

    if (row.type === actionTypeJob) {
      this.props.onJobConnectionChanged(row.connectionId)
    } else if (row.type === actionTypeSP) {
      this.props.onSPConnectionChanged(row.connectionId)
    }
  }

  handleRemoteActionSave(event) {
    const {
      actionName,
      selectedActionName,
      actionType,
      actionJob,
      actionSP,
      actionConnection,
      actionParameters,
      actionIsCloneJob,
      remoteActions,
      relationalJobId
    } = this.state
    const ModelState = {}

    if (_.isEmpty(actionName)) {
      ModelState.actionName = ['Action name is required.']
    }

    if (!actionConnection) {
      ModelState.actionConnection = ['Connection is required.']
    }
    if (actionType === actionTypeJob && actionJob <= 0) {
      ModelState.actionJob = ['Job is required.']
    }

    if (actionType === actionTypeSP && _.isEmpty(actionSP)) {
      ModelState.actionSP = ['SP is required.']
    }

    _.forEach(actionParameters, (param, n) => {
      if (_.isEmpty(param.name)) {
        ModelState[`paramName${n}`] = ['Parameter name is required.']
      }
    })

    if (!_.isEmpty(ModelState)) {
      this.setState({
        error: {
          ModelState
        }
      })
    } else {
      let index = _.findIndex(remoteActions, (o) => o.name === selectedActionName)
      if (index === -1) {
        index = remoteActions.length
      }

      if (!_.some(actionParameters, (item) => item.name === 'refreshKey')) {
        actionParameters.push({ name: 'refreshKey', type: 'string' })
      }

      remoteActions[index] = {
        name: actionName,
        type: actionType,
        jobId: actionJob,
        sp: actionSP,
        connectionId: actionConnection,
        parameters: actionParameters,
        relationalJobId,
        isCloneJob: actionIsCloneJob
      }
      const remoteActionsArr = this.remoteActionInfo(remoteActions, this.props.remoteActionsInfo)

      this.setState({ remoteActions: remoteActionsArr, show: false, disabled: true })

      this.handleSubmit(event)
    }
  }

  handleRemoteActionDelete(event, name) {
    const { selectedActionName, remoteActions } = this.state
    if (name) {
      const row = _.find(remoteActions, (row) => row.name === name)
      if (row.used === 0) {
        _.remove(remoteActions, (o) => o.name === name)
      }
    } else if (selectedActionName && selectedActionName != '') {
      _.remove(remoteActions, (o) => o.name === selectedActionName)
    }

    this.setState({ remoteActions, show: false })
    this.handleSubmit(event)
  }

  handleRemoteActionChange(label) {
    return (value) => {
      this.setState({
        [label]: value
      })

      if (label === 'actionConnection') {
        this.setState({
          actionJob: '',
          actionSP: '',
          actionParameters: []
        })
        if (this.state.actionType === actionTypeJob) {
          this.props.onJobConnectionChanged(value)
        } else if (this.state.actionType === actionTypeSP) {
          this.props.onSPConnectionChanged(value)
        }
      }

      if (label === 'actionType' && value === actionTypeSP) {
        this.setState({
          relationalJobId: ''
        })
      }

      if (label === 'actionType' && this.state.actionConnection) {
        this.setState({
          actionJob: '',
          actionSP: '',
          actionParameters: []
        })
        if (value === actionTypeJob) {
          this.props.onJobConnectionChanged(this.state.actionConnection)
        } else if (value === actionTypeSP) {
          this.props.onSPConnectionChanged(this.state.actionConnection)
        }
      }

      if (label === 'actionSP') {
        const { storedProcedures: { data: spList = [] } = {} } = this.props
        const sp = _.find(spList, { name: value })
        const { parameters = [] } = sp

        this.setState({
          actionParameters: _.map(parameters, (parameter) => {
            return { name: parameter.name, type: 'string' }
          })
        })
      }

      if (label === 'actionIsCloneJob') {
        this.setState({
          relationalJobId: ''
        })
      }
    }
  }

  handleActionParameterAdd() {
    const { actionParameters } = this.state

    actionParameters.push({ name: '', type: 'string' })

    this.setState({ actionParameters })
  }

  handleActionParameterChange(index, label) {
    return (value) => {
      const { actionParameters } = this.state

      actionParameters[index][label] = value

      this.setState({
        actionParameters
      })
    }
  }

  handleActionJobIdChanged(value) {
    this.setState({
      relationalJobId: value
    })
  }

  handleActionParameterRemove(index) {
    return (value) => {
      const { actionParameters } = this.state
      actionParameters.splice(index, 1)

      this.setState({
        actionParameters
      })
    }
  }

  remoteActionInfo(remoteActions, remoteActionsInfo) {
    const { isSuccess, data } = remoteActionsInfo
    const newArr = _.map(remoteActions, (action) => {
      if (isSuccess) {
        if (!_.isNil(data) && data[action.name]) {
          return {
            ...action,
            used: _.isArray(data[action.name]) ? data[action.name].length : '-'
          }
        }
        return { ...action, used: 0 }
      }
      return {
        ...action,
        used: '-'
      }
    })

    return newArr
  }

  UNSAFE_componentWillMount() {
    const remoteActions = this.remoteActionInfo(
      this.props.remoteActions,
      this.props.remoteActionsInfo
    )
    this.setState({
      remoteActions
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isSuccess(this.props.remoteActionsInfo, nextProps.remoteActionsInfo)) {
      const remoteActions = this.remoteActionInfo(
        this.props.remoteActions,
        nextProps.remoteActionsInfo
      )
      this.setState({
        remoteActions
      })
    }
  }

  disableDeleteButton() {
    const { selectedActionName, remoteActions } = this.state

    if (selectedActionName && selectedActionName != '') {
      const selectedObject = _.find(remoteActions, (connection) => {
        return connection.name === selectedActionName
      })

      if (selectedObject && selectedObject.used == 0) {
        return false
      }
    }
    return true
  }

  render() {
    const {
      remoteActions,
      actionName,
      actionType,
      actionJob,
      actionSP,
      actionConnection,
      actionParameters,
      actionIsCloneJob,
      error,
      relationalJobId,
      selectedActionName
    } = this.state

    const {
      dataConnections = [],
      jobs = [],
      storedProcedures = [],
      dataTypes,
      remoteActionsInfo: { data: remoteActionsInfo = [] }
    } = this.props
    const connections = mapFromCrudList(dataConnections)

    const availableJobs = _.map(jobs.data, (job) => {
      return { id: job.id, text: job.name }
    })

    const availableSPs = _.map(storedProcedures.data, (sp) => {
      return { id: sp.name, text: sp.name }
    })

    const isCloneData = [
      { id: true, text: 'True' },
      { id: false, text: 'False' }
    ]

    return (
      <>
        <Row>
          <Col lg={12}>
            <Card className="my-3">
              <Card.Header>
                <Card.Title as="h5" className="m-0">
                  Remote Actions
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <SlvyJsonTable
                  className="table table-bordered table-striped table-mouse-pointer"
                  columns={
                    _.size(remoteActions) > 0
                      ? [
                          { key: 'name', label: 'Name' },
                          { key: 'type', label: 'Type' },
                          { key: 'connection', label: 'Connection' },
                          {
                            key: 'used',
                            label: 'Usage',
                            cell: function (item) {
                              return (
                                <OverlayTrigger
                                  rootClose
                                  overlay={this.props.openPopover(item.name, remoteActionsInfo)}
                                  placement="bottom"
                                  trigger="click"
                                >
                                  <Button
                                    className="me-3"
                                    disabled={item.used === '-'}
                                    type="button"
                                    value="remote-detail"
                                    variant="outline-dark"
                                  >
                                    {item.used}
                                  </Button>
                                </OverlayTrigger>
                              )
                            }.bind(this)
                          },
                          {
                            key: 'remove',
                            label: 'Remove',
                            cell: (item) =>
                              item.used == 0 ? (
                                <Button
                                  className="me-3"
                                  type="button"
                                  value="remote-action-delete"
                                  variant="outline-danger"
                                  onClick={(e) => this.handleRemoteActionDelete(e, item.name)}
                                >
                                  -
                                </Button>
                              ) : null
                          }
                        ]
                      : []
                  }
                  rows={_.map(remoteActions, (action) => {
                    const connection = _.find(connections, (connection) => {
                      return connection.id.toString() === action.connectionId.toString()
                    })
                    return {
                      name: action.name,
                      type: action.type,
                      connection: connection && connection.text,
                      used: action.used
                    }
                  })}
                  onClickRow={this.handleRowClick}
                />
                <Button
                  className="btn-add-action"
                  value="add-action-button"
                  variant="success"
                  onClick={(e) => this.showModal(e)}
                >
                  Add Action
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal enforceFocus={false} show={this.state.show} size="xl" onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Remote Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <SlvyFormInput
                disabled={this.state.disabled}
                error={error}
                errorModelLabel="actionName"
                label="Name"
                labelClass="justify-content-end"
                value={actionName}
                onChange={this.handleRemoteActionChange('actionName')}
              />
              <SlvyFormSelect
                data={connections}
                error={error}
                errorModelLabel="actionConnection"
                label="Connection"
                labelClass="justify-content-end"
                value={actionConnection}
                onSelect={this.handleRemoteActionChange('actionConnection')}
              />
              <SlvyFormSelect
                data={[actionTypeSP, actionTypeJob]}
                label="Type"
                labelClass="justify-content-end"
                value={actionType}
                onSelect={this.handleRemoteActionChange('actionType')}
              />
              {actionType === actionTypeJob && (
                <SlvyFormSelect
                  data={isCloneData}
                  label="Is Clone"
                  labelClass="justify-content-end"
                  value={String(actionIsCloneJob)}
                  onSelect={this.handleRemoteActionChange('actionIsCloneJob')}
                />
              )}
              {actionType === actionTypeJob ? (
                <SlvyFormSelect
                  data={availableJobs}
                  error={error}
                  errorModelLabel="actionJob"
                  isLoading={jobs.pending}
                  label={actionTypeJob}
                  labelClass="justify-content-end"
                  value={actionJob}
                  onSelect={this.handleRemoteActionChange('actionJob')}
                />
              ) : null}
              {actionType === actionTypeSP ? (
                <SlvyFormSelect
                  data={availableSPs}
                  error={error}
                  errorModelLabel="actionSP"
                  isLoading={storedProcedures.pending}
                  label={actionTypeSP}
                  labelClass="justify-content-end"
                  placeholder={actionSP === '' ? '' : 'This SP is not in the available SP list.'}
                  value={actionSP}
                  onSelect={this.handleRemoteActionChange('actionSP')}
                />
              ) : null}
            </>
            {actionType === actionTypeSP ? (
              <Row>
                <FormLabel column className="d-flex align-items-start justify-content-end" sm={2}>
                  Parameters
                </FormLabel>
                <Col md={{ span: 10, offset: 2 }}>
                  {_.map(actionParameters, ({ name, type }, k) => {
                    return (
                      <Row key={k}>
                        <Col md={4}>
                          <SlvyFormInput
                            columns={{ label: 3, input: 9 }}
                            error={error}
                            errorModelLabel={`paramName${k}`}
                            label="Name"
                            labelClass="justify-content-end"
                            value={name}
                            onChange={this.handleActionParameterChange(k, 'name')}
                          />
                        </Col>
                        <Col md={4}>
                          <SlvyFormSelect
                            columns={{ label: 3, select: 9 }}
                            data={dataTypes.data}
                            label="Type"
                            labelClass="justify-content-end"
                            value={type}
                            onSelect={this.handleActionParameterChange(k, 'type')}
                          />
                        </Col>
                        <Col md={2}>
                          <Button
                            type="button"
                            variant="outline-danger"
                            onClick={this.handleActionParameterRemove(k)}
                          >
                            -
                          </Button>
                        </Col>
                      </Row>
                    )
                  })}
                  <Button
                    type="button"
                    variant="outline-success"
                    onClick={this.handleActionParameterAdd}
                  >
                    +
                  </Button>
                </Col>
              </Row>
            ) : (
              <>
                <SlvyFormInput
                  error={error}
                  label="Relational Id"
                  labelClass="justify-content-end"
                  value={relationalJobId}
                  onChange={this.handleActionJobIdChanged}
                />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.hideModal}>
              Close
            </Button>
            {this.disableDeleteButton() ? null : (
              <Button variant="danger" onClick={this.handleRemoteActionDelete}>
                Delete
              </Button>
            )}
            <Button variant="success" onClick={this.handleRemoteActionSave}>
              Add & Update
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
