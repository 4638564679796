export default `({
    plugins: [
        {
            ptype: 'rowexpander',
            rowBodyTpl: [
                '<div class="expander">',
                '<table class="table">',
                '<tr>',
                '</tr>',
                '  <tr>',
                '       <th class=r>'+tt('Merkez')+'</th>',
                '      <td class="r">{OrderRecommendationParent}</td>',
                '       <th class="r">'+tt('Bölge')+'</th>',
                '      <td class="r">{OrderRecommendationCurrent}</td>',
                '      <th class="r">'+tt('Satış Md.')+'</th>',
                '      <td class="r">{OrderRecommendationChild}</td>',
                '   </tr>',
                '</table>',
                '</div>',
            ]
        },{
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columnLines: true,
    multiColumnSort: true,
    columns: [{
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-thumbs-up blue',
                tooltip: tt('Dağıt'),
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    grid.mask()
                    var me = this;
                    me.DistributionCount = 0
                    me.SettingsId = 0
                    me.UseStoreStock = false
                    me.UseMiniStores = false
                    me.ReplenishmentState = false

                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/GetOrAddStoreDistributionSetting',
                        method: 'POST',
                        params: {
                            spotId: record.get('SpotId'),
                        },
                        success: function (response, opts) {
                            var obj = JSON.parse(response.responseText)
                            if(obj !=null)
                            {
                                me.DistributionCount = obj.DistributionCount
                                me.SettingsId = obj.Id
                                me.UseStoreStock = obj.UseStoreStock
                                me.UseMiniStores=obj.UseMiniStores
                                me.ReplenishmentState = obj.ReplenishmentState 
                            }
                            grid.unmask()
                        },
                        failure: function (response, opts) {
                            Ext.Msg.alert(
                                tt('Durum'),
                                tt('İşlem Gerçekleştirilirken bir hata oluştu')
                            )
                            console.log('server-side failure with status code ' + response.status);
                            grid.unmask() 
                        },
                        async: false
                    });

                    var mainView = this.up('spuimainview')
                    var distributionGridStore = Ext.create('Ext.data.Store', {
                        autoLoad: true,
                        autoSync: false,
                        proxy: {
                            type: 'ajax',
                            actionMethods: {
                                read: 'POST',
                            },
                            fields: [{
                                name: 'SpotID',
                                type: 'int'
                            }, {
                                name: 'Name',
                                type: 'string'
                            }],
                            sorters: [{
                                property: 'Name',
                                direction: 'ASC'
                            }],
                            api: {
                                read: Spui.Global.getApiUrl() + 'spui/StoreDistributionPreview',
                            },
                            writer: {
                                allowSingle: false,
                                successProperty: 'success',
                                messageProperty: 'errors',
                                allDataOptions: {
                                    associated: false
                                },
                                writeAllFields: true
                            },
                            extraParams: {
                                spotId: record.get('SpotId'),
                                useStoreStock:me.UseStoreStock
                            },
                            url: Spui.Global.getApiUrl() + 'spui/StoreDistributionPreview',
                        },
                    })

                    distributionGridStore.sort('Name', 'ASC')
                    var scope = this;
                    var canUseSAP = mainView != undefined ? mainView.getCanUseSAP() : null
                    var labelText= record.get('UseStoreStock') == true ? tt('İlgili spot için bölge depodaki stokların da dağıtılması gerekmektedir.') :'';

                    var win = Ext.create('Spui.uxWindow', {
                        height: '80%',
                        width: 1100,
                        ui: 'sui-window',
                        record: record,
                        scope: scope,
                        mainView: mainView,
                        title: tt('Dağıtım'),
                        uxItemCfg: 'dagitimCfg',
                        uxItem: {
                            spotRecord: record,
                            gridStore:distributionGridStore,
                            viewModel: {
                                stores: {
                                    gridStore: distributionGridStore
                                },
                                data: {
                                    SpotId: record.get('SpotId'),
                                    spotRecord: record,
                                    buttonsAreDisabled:me.ReplenishmentState,
                                    useStoreStock:me.UseStoreStock,
                                    labelText:labelText,
                                    useMiniStores:me.UseMiniStores,
                                    labelText:labelText,
                                    DistributionCount:me.DistributionCount,
                                    canUseSAP:canUseSAP,
                                    SettingsId:me.SettingsId
                                },
                            },
                        },
                        bodyPadding: 2,
                        modal: true,
                    });
                    distributionGridStore.win = win;
                    win.show();
                }
            }]
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('Spot Başlangıç Tarihi'),
            dataIndex: 'PreStartDate',
            filter: {
                type: 'date'
            },
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('Spot Bitiş Tarihi'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            hiden: true,
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '0,000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn',
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('Toplam Adet'),
            dataIndex: 'ToplamAdet',
            filter: {
                type: 'number'
            },
            width: 100,
            align: 'right',
            xtype: 'numbercolumn',
        }, 
        {
            text: tt('Beklenen Ciro'),
            dataIndex: 'BeklenenCiro',
            filter: {
                type: 'number'
            },
            width: 100,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
        },
        {
            text: tt('Sözleşme Numarası'),
            dataIndex: 'SozlesmeNo',
            filter: {
                type: 'string'
            },
            width: 100
        },
        {
            text: tt('Sözleşme Tarihi'),
            dataIndex: 'SozlesmeTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            filter: {
                type: 'date'
            },
            width: '90'
        },
        {
            text: tt('VPER_START'),
            dataIndex: 'AfisBaslangicTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            filter: {
                type: 'date'
            },
            width: '90'
        },
        {
            text: tt('VPER_END'),
            dataIndex: 'AfisBitisTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            filter: {
                type: 'date'
            },
            width: '90'
        },
        {
            text: tt('TeslimatTarihi'),
            dataIndex: 'TeslimatTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            filter: {
                type: 'date'
            },
            width: '90'
        },
        {
            text: tt('Planlanan Teslimat Tarihi'),
            dataIndex: 'PlanlananTeslimTarihi',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            filter: {
                type: 'date'
            },
            width: '90'
        },
        {
            text: tt('AcilanSas'),
            dataIndex: 'AcilanSasMiktar',
            format: '0,000.00',
            filter: {
                type: 'number'
            },
            width: 100
        },
        {
            text: tt('Dağıtım Miktarı'),
            dataIndex: 'RecommendationDistribution',
            format: '0,000.00',
            filter: {
                type: 'number'
            },
            renderer: function (v) {
                if(v==0)
                {
                    return null
                }
                
                return v
            },
            width: 100
        },
        {
            text: tt('Teslimat Miktarı'),
            filter: {
                type: 'number'
            },
            dataIndex: 'TeslimatMiktari',
            width: '90'
        },
        {
            text: tt('VendorCode'),
            dataIndex: 'TedarikciKodu',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('Tedarikçi Adı'),
            dataIndex: 'VendorName',
            
            filter: {
                type: 'string'
            },
            width: 200
        }, 
        {
            text: tt('Entegrasyon Statusü'),
            dataIndex: 'EntegrasyonStatusu',
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                if(v==0)
                {
                    return tt('Pending')
                }else if(v==2)
                {
                    return tt('Success')
                }else if(v==3)
                {
                    return tt('Error')
                }
                else if (v==4){
                    return tt('Completed')
                }
                else {
                    return v
                }
                
            },
            width: '90'
        },
        {
            text: tt('Hata Mesajı'),
            filter: {
                type: 'string'
            },
            dataIndex: 'HataMesaji',
            width: '90'
        },
        {
            text: tt("SAP'e Gönderilecek Miktar"),
            filter: {
                type: 'number'
            },
            dataIndex: 'SendingSAPRecommendation',
            width: '90',
            format: '0,000.00',
        },
        {
            text: tt("SAP'e Gönderilen Miktar"),
            filter: {
                type: 'number'
            },
            dataIndex: 'SentSAPRecommendation',
            format: '0,000.00',
            width: '90'
        }
    ],
})
`
