import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

import highchartsMore from 'highcharts/highcharts-more'
import highchartsHeatMap from 'highcharts/modules/heatmap'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsNodataDisplay from 'highcharts/modules/no-data-to-display'

highchartsMore(Highcharts)
highchartsHeatMap(Highcharts)
highchartsExporting(Highcharts)
highchartsNodataDisplay(Highcharts)

class Heatmap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      subTitle: null
    }

    this.dataLabelsFormatter = this.dataLabelsFormatter.bind(this)
    this.linksHover = this.linksHover.bind(this)
    this.onPointClick = this.onPointClick.bind(this)
    this.tooltipFormatter = this.tooltipFormatter.bind(this)
    this.getValueWithFormat = this.getValueWithFormat.bind(this)
  }

  dataLabelsFormatter(point) {
    if (point && point.point) {
      const { point: { value = 0, dataLabelValue = '' } = {} } = point

      return dataLabelValue || value
    }
    return null
  }

  handlePointClick(value) {
    return value
  }

  linksHover(point, state) {
    if (point.isNode) {
      point.linksFrom.forEach(function (l) {
        l.setState(state)
      })
    }
  }

  onPointClick(event) {
    const { props: { pluginData = [] } = {} } = this

    const { point: { index = 0 } = {} } = event

    const pointData = pluginData[index] || {}

    if (!_.isEmpty(pointData)) {
      this.handlePointClick(pointData)
    }
  }

  removeEmptyConfig(config) {
    for (const prop in config) {
      if (!config.hasOwnProperty(prop)) continue

      if (config[prop] === '') {
        delete config[prop]
      } else if (typeof config[prop] === 'object') {
        this.removeEmptyConfig(config[prop])
      }
    }
  }

  getValueWithFormat(fieldName, value) {
    const {
      props: { settings: { query: { formattedFields = [] } = {} } = {}, getFormattedValue } = {}
    } = this
    const format = _.find(formattedFields, { columnName: fieldName })

    if (!_.isNil(format) && !_.isEmpty(format)) {
      return getFormattedValue(fieldName, value, formattedFields)
    }
    return value
  }

  tooltipFormatter(item) {
    const {
      props: {
        settings: {
          config: {
            data: { yAxis: yColumn, xAxis: xColumn, value: vColumn } = {},
            tooltip: { headerFormat = '', footerFormat = '', pointFormat = '' } = {}
          } = {}
        } = {}
      } = {}
    } = this || {}

    const { point: { value, x, y, xValue = [] } = {} } = item

    const tooltipData = {
      _extendobj: { value, x, y },
      ...(xValue && xValue.length > 0 ? _.first(xValue) : {})
    }

    let tooltip =
      headerFormat ||
      `
        <b>${xColumn}:</b> ${this.getPointCategoryName(item.point, 'x')}<br>
        <b>${yColumn}:</b> ${this.getPointCategoryName(item.point, 'y')}<br>
        <b>${vColumn}:</b> ${this.getValueWithFormat(vColumn, item.point.value)}
      `
    tooltip += footerFormat

    tooltip = tooltip.replace(/{(.*?)}/g, (match, offset) => {
      return tooltipData[offset]
        ? tooltipData[offset]
        : tooltipData._extendobj[offset]
        ? tooltipData._extendobj[offset]
        : ''
    })
    return tooltip
  }

  componentDidMount() {
    this.handlePointClick = this.props.registerEvent({
      key: 'handlePointClick',
      fn: this.handlePointClick.bind(this),
      returnTypes: _.transform(
        this.props.settings.query.fields,
        (result, field = {}) => {
          const { dataType = '' } = field
          result[field.fieldName] = PluginTypes.fromString(dataType)
        },
        {}
      )
    })
  }

  getPointCategoryName = (point, dimension) => {
    const { series } = point
    const isY = dimension === 'y'
    const axis = series[isY ? 'yAxis' : 'xAxis']
    return axis.categories[point[isY ? 'y' : 'x']]
  }

  getSeriesConfig = (pluginData, pluginConfig) => {
    const { data: { name = '', yAxis, xAxis, labelValue, value: dataValue } = {} } = pluginConfig

    const config = {
      series: [
        {
          borderWidth: 1,
          type: 'heatmap',
          data: [],
          name,
          events: {},
          dataLabels: {
            enabled: true,
            color: '#000000'
          }
        }
      ],
      xAxis: {
        categories: []
      },
      yAxis: {
        categories: [],
        title: null,
        reversed: true
      }
    }

    if (yAxis && xAxis && dataValue && pluginData && pluginData.length > 0) {
      config.series[0].data = _.reduce(
        _.entries(_.groupBy(pluginData, yAxis)),
        (arr, [key, value], yIndex) => {
          _.each(_.entries(_.groupBy(value, xAxis)), ([xKey, xValue], xIndex) => {
            arr.push({
              x: xIndex,
              y: yIndex,
              value: xValue.reduce((total, item) => (total += parseFloat(item[dataValue], 10)), 0),
              xValue,
              dataLabelValue: (() => {
                if (labelValue) {
                  const { [labelValue]: dataLabelValue } = _.first(xValue)
                  return this.getValueWithFormat(labelValue, dataLabelValue)
                }
                return ''
              })()
            })
          })
          return arr
        },
        []
      )

      config.xAxis.categories = _.map(_.groupBy(pluginData, xAxis), (value, key) => key)
      config.yAxis.categories = _.map(_.groupBy(pluginData, yAxis), (value, key) => key)
    }

    return config
  }

  render() {
    const {
      props: {
        pluginData = [],
        settings: {
          config: pluginConfig = {},
          config: { chart: { emptyText = 'No Data to Display' } = {} } = {}
        } = {}
      } = {}
    } = this || {}
    const config = _.cloneDeep(pluginConfig)
    const defaults = {
      credits: {
        enabled: false,
        text: `Solvoyo © ${new Date().getFullYear()}`,
        href: '#',
        style: { fontSize: '12px' }
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      }
    }

    if (_.isEmpty(config.plotOptions?.heatmap?.dataLabels?.textPath)) {
      _.set(config, 'plotOptions.heatmap.dataLabels.textPath', undefined)
    }

    config.credits = defaults.credits
    const {
      chart: { showCredits = false } = {},
      exporting: { enabled: exportingEnabled = false } = {}
    } = pluginConfig
    config.credits.enabled = showCredits

    config.noData = defaults.noData
    config.lang = {
      noData: emptyText || ' '
    }

    if (!config.exporting) {
      config.exporting = {}
    }
    config.exporting.enabled = exportingEnabled
    config.exporting.buttons = {
      contextButton: {
        align: 'left'
      }
    }

    if (!config.tooltip) {
      config.tooltip = {}
    }

    if (!config.chart) {
      config.chart = {}
    }

    config.chart.type = 'heatmap'

    const that = this
    config.tooltip.formatter = function () {
      return that.tooltipFormatter(this)
    }
    config.tooltip.followPointer = true
    config.tooltip.crosshairs = true

    const { series, yAxis, xAxis } = this.getSeriesConfig(pluginData, config)

    config.series = series
    config.yAxis = yAxis
    config.xAxis = xAxis

    if (_.size(config.series[0].data) > 0) {
      config.noData = {
        style: {
          fontSize: '0px',
          color: 'rgba(255, 255, 255, 0)'
        }
      }
      config.lang = {
        noData: ''
      }
    }

    const pointClick = {
      events: {
        click: this.onPointClick
      }
    }

    config.series[0].point = pointClick

    if (!config.series[0].events) {
      config.series[0].events = {}
    }

    if (config.plotOptions && config.plotOptions.heatmap && config.plotOptions.heatmap.dataLabels) {
      config.plotOptions.heatmap.dataLabels.formatter = function () {
        return that.dataLabelsFormatter(this, config, pluginData)
      }
    }

    this.removeEmptyConfig(config)

    const configFilter = _.pick(config, [
      'chart',
      'title',
      'colorAxis',
      'legend',
      'series',
      'yAxis',
      'xAxis',
      'credits',
      'exporting',
      'legend',
      'noData',
      'plotOptions',
      'subtitle',
      'title',
      'tooltip'
    ])

    const heatmapConfig = {
      ...configFilter,

      accessibility: {
        point: {
          descriptionFormatter(point) {
            const ix = point.index + 1
            const xName = that.getPointCategoryName(point, 'x')
            const yName = that.getPointCategoryName(point, 'y')
            const val = point.value
            return `${ix}. ${xName} sales ${yName}, ${val}.`
          }
        }
      },

      tooltip: {
        ...config.tooltip,
        ...configFilter.tooltip
      }
    }

    return (
      <div className="highcharts-container -heatmap">
        <HighchartsReact highcharts={Highcharts} options={heatmapConfig} />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  settings: props.settings,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(Heatmap, selectConnectorProps)
