Ext.define('MDM.Global', {
  singleton: true,
  config: {
    /**
     * URL of the custom API to use for the reporting system
     */
    apiUrl: '###API_URL###',

    /*
     * Start token var.  This is updated when a major route is hit
     * */
    startToken: 'Home',
    /**
     * Global User info
     */
    userInfo: null,
    /**
     * Global Customer info
     */
    customerInfo: null,
    /**
     * Global Catolg info
     */
    catalogInfo: null,
    /**
     *
     */
    catalogId: null,

    catologConfig: {},
    isStateful: false,
    NetToken: null,

    userObj: {
      id: 0,
      username: '',
      isReadonly: true,
      isGlobal: false,
      isRegion: true,
      region: '',
      culture: 'en-US',
      email: ''
    }
  },
  constructor: function (config) {
    var me = this
    me.initConfig(config)
  },
  /**
   * return Catologid
   * @returns {type}
   */

  getPermission: function (type) {
    var userObj = this.getUserObj(),
      isGlobal = this.isGlobal(),
      isRegion = this.isRegion(),
      isReadonly = true

    if (userObj && 'isReadonly' in userObj) {
      isReadonly = userObj.isReadonly
    }
    if (isReadonly) {
      this.showToast()
      return true
    } else if (type == 'Region' && isRegion) {
      return false
    } else if (type == 'Global' && isGlobal) {
      return false
    } else if (type === null) {
      return false
    }
    this.showToast()
    return true
  },
  isGlobal: function () {
    var userObj = this.getUserObj(),
      isGlobal = !!(userObj && userObj.isGlobal)
    return isGlobal
  },
  isRegion: function () {
    var userObj = this.getUserObj(),
      isRegion = !!(userObj && userObj.isRegion)
    return isRegion
  },
  getRegion: function () {
    var userObj = this.getUserObj(),
      region = userObj && userObj.region ? userObj.region : null
    return region
  },

  showToast: function () {
    var t = Ext.toast({
      html: '<br>You only have view permissions for this page!<br>',
      title: 'Warning',
      ui: 'window-info',
      width: 300,
      align: 'tr',
      iconCls: 'fa fa-exclamation-circle',
      closable: true
    })
  },

  getCatalogId: function () {
    var me = this,
      catalogInfo = me.getCatalogInfo(),
      catalogId = catalogInfo != null && catalogInfo.catalogId ? catalogInfo.catalogId : null
    return catalogId
  },
  /**
   * convrt month number to month name
   * @param {type} m
   * @returns {type}
   */
  getMonthName: function (m) {
    var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    m = Math.abs(m)
    return month[m % 12]
  },
  /**
   * get Last Period
   * @returns {number}
   */
  getLastReportingPeriod: function () {
    var me = this
    return this.getCatologConfig().lastReportingPeriod
      ? this.getCatologConfig.lastReportingPeriod
      : '2016-08'
  },
  getCurrentFinancialYear: function () {
    var me = this
    return this.getCatologConfig().currentFinancialYear
      ? this.getCatologConfig.currentFinancialYear
      : '16-17'
  },
  isGV: function () {
    var user = this.getUserInfo()
    if (user && user.role) {
      return user.role == 'GV'
    }
    return true
  },
  isAdmin: function () {
    return this.getUserInfo() && this.getUserInfo().isAdmin
  }
})
