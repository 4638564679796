import cx from 'classnames'
import { IRelationLabelProps } from './RelationLabel.types'
import style from './RelationLabel.module.scss'

const RelationLabel = ({ text = '', sub = '', isHighlight = false }: IRelationLabelProps) => {
  return (
    <span className={cx({ [style.highlight]: isHighlight })}>
      <span className="text-truncate" title={text}>
        {text}
      </span>
      <strong className="text-nowrap ms-1">
        <small>{sub}</small>
      </strong>
    </span>
  )
}

export default RelationLabel
