import { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { tuiSlice } from '../../../store/slices'

function useFooterButtonClick() {
  const footerButtonsRef = useRef<Record<string, () => void | undefined>>({})
  const [{ registerEvent }] = useReduxState(tuiSlice.selectSlice)
  const [{ config }] = useReduxState(tuiSlice.selectSlice)

  useEffect(() => {
    config.loadTree.footerButtons.forEach((footerButton) => {
      const modifiedButtonText = footerButton.text.replace(/\s/g, '_')

      const fn = `handleLoadFooterButtonClick_${modifiedButtonText}`
      footerButtonsRef.current[fn] = registerEvent({
        key: `LoadFooterButtonClick_${modifiedButtonText}`,
        fn: () => ({ refreshKey: uuidv4() }),
        returnTypes: { refreshKey: PluginTypes.string }
      })
    })

    config.demandTree.footerButtons.forEach((footerButton) => {
      const modifiedButtonText = footerButton.text.replace(/\s/g, '_')

      const fn = `handleDemandFooterButtonClick_${modifiedButtonText}`
      footerButtonsRef.current[fn] = registerEvent({
        key: `DemandFooterButtonClick_${modifiedButtonText}`,
        fn: () => ({ refreshKey: uuidv4() }),
        returnTypes: { refreshKey: PluginTypes.string }
      })
    })
  }, [
    config.demandTree.footerButtons,
    config.loadTree.footerButtons,
    footerButtonsRef,
    registerEvent
  ])

  return { footerButtonsRef }
}

export default useFooterButtonClick
