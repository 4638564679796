export const alignments = {
  left: 'start',
  center: 'center',
  right: 'end'
}

export const operators = {
  eq: '=',
  gt: '>',
  lt: '<',
  like: 'like',
  notlike: 'not like',
  '==': '='
}

export const types = {
  int: 'number',
  string: 'string',
  datetime: 'date',
  bool: 'boolean',
  double: 'list'
}
