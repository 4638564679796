Ext.define('po.MainView', {
  extend: 'Ext.panel.Panel',
  alias: 'widget.mainView',
  xtype: 'pomainview',
  controller: 'mainController',
  viewModel: 'mainViewModel',
  layout: 'border',
  bodyBorder: false,
  cls: 'po-mainview',
  header: false,
  config: {
    applicationType: 'DC',
    selectedProductCode: null,
    groupByField: '',
    selectedDC: null,
    selectedK1K: null,
    selectedK2K: null,
    selectedK3K: null,
    selectedDate: null,
    endDate: null,
    selectedStore: null,
    viewMode: 'OneriVar',
    language: 'TR',
    reasonCodeEnabled: true,
    mainGridColumns: null,
    panelVisibility: null,
    zeroInventoryGrid: null,
    buttonsStatus: null,
    mainGridFilters: {},
    pluginInstanceId: null,
    catalogId: null,
    environment: null,
    hash: null,
    summaryEditorHidden: false,
    roundUpHidden: false,
    InventoryHidden: false,
    grupGridHidden: false,
    canEdit: false,
    canResetColumnSequence: false
  },
  bind: {
    applicationType: '{applicationType}',
    selectedProductCode: '{selectedProductCode}',
    selectedDC: '{selectedDC}',
    groupByField: '{groupByField}',
    selectedK1K: '{selectedK1K}',
    selectedK2K: '{selectedK2K}',
    selectedK3K: '{selectedK3K}',
    selectedDate: '{selectedDate}',
    endDate: '{endDate}',
    selectedStore: '{selectedStore}',
    viewMode: '{viewMode}',
    language: '{language}',
    reasonCodeEnabled: '{reasonCodeEnabled}',
    mainGridColumns: '{mainGridColumns}',
    panelVisibility: '{panelVisibility}',
    zeroInventoryGrid: '{zeroInventoryGrid}',
    buttonsStatus: '{buttonsStatus}',
    mainGridFilters: '{mainGridFilters}',
    pluginInstanceId: '{pluginInstanceId}',
    catalogId: '{catalogId}',
    environment: '{environment}',
    hash: '{hash}',
    summaryEditorHidden: '{summaryEditorHidden}',
    roundUpHidden: '{roundUpHidden}',
    InventoryHidden: '{InventoryHidden}',
    grupGridHidden: '{grupGridHidden}',
    canEdit: '{canEdit}',
    canResetColumnSequence: '{canResetColumnSequence}'
  },
  publishes: [
    'selectedProductCode',
    'applicationType',
    'groupByField',
    'selectedDC',
    'selectedK1K',
    'selectedK2K',
    'selectedK3K',
    'selectedDate',
    'endDate',
    'selectedStore',
    'viewMode',
    'language',
    'reasonCodeEnabled',
    'mainGridColumns',
    'panelVisibility',
    'zeroInventoryGrid',
    'buttonsStatus',
    'mainGridFilters',
    'pluginInstanceId',
    'catalogId',
    'environment',
    'hash',
    'summaryEditorHidden',
    'roundUpHidden',
    'InventoryHidden',
    'grupGridHidden',
    'canEdit',
    'canResetColumnSequence'
  ],
  listeners: {
    onCancelChanges: 'onCancelChanges',
    onRunReport: 'onRunReport',
    onSaveChanges: 'onSaveChanges',
    onSendToSAP: 'onSendToSAP',
    onExport: 'exportTo',
    onMassUpdateButtonClick: 'onMassUpdateButtonClick'
  },
  initComponent: function () {
    // where should be locate this
    // this.localStorageProvider = Ext.create('Ext.state.LocalStorageProvider')
    // Ext.state.Manager.setProvider(this.localStorageProvider)

    var me = this,
      viewModel = me.getViewModel(),
      panelVisibility = me.config.panelVisibility || {
        productDetailVisible: true,
        inventoryProfileVisible: true,
        footerToolbarVisible: true,
        footerToolbarERPGroupVisible: true
      },
      zeroInventoryGrid = me.config.zeroInventoryGrid,
      buttonsStatus = {
        massUpdateButtonEnable: false,
        exportButtonEnable: true,
        cancelButtonEnable: false,
        saveButtonEnable: false,
        sapButtonEnable: false
      },
      reasonCodeEnabled = me.config.hasOwnProperty('reasonCodeEnabled')
        ? me.config.reasonCodeEnabled
        : true

    var pluginInstanceId = me.config.pluginInstanceId
    var token = me.config.token
    var environment = me.config.environment
    var catalogId = me.config.catalogId

    me.setPanelVisibility(panelVisibility)
    me.setZeroInventoryGrid(zeroInventoryGrid)
    me.setButtonsStatus(buttonsStatus)
    viewModel.set('panelVisibility', panelVisibility)
    viewModel.set('zeroInventoryGrid', zeroInventoryGrid)
    viewModel.set('buttonsStatus', buttonsStatus)
    viewModel.set('reasonCodeEnabled', reasonCodeEnabled)
    viewModel.set('applicationType', me.config.applicationType)
    viewModel.set('selectedProductCode', me.config.selectedProductCode)
    viewModel.set('groupByField', me.config.groupByField)
    viewModel.set('pluginInstanceId', pluginInstanceId)
    viewModel.set('catalogId', catalogId)
    viewModel.set('environment', environment)
    viewModel.set('token', token)
    viewModel.set('selectedDC', me.config.selectedDC)
    viewModel.set('selectedK1K', me.config.selectedK1K)
    viewModel.set('selectedK2K', me.config.selectedK2K)
    viewModel.set('selectedK3K', me.config.selectedK3K)
    viewModel.set('selectedDate', me.config.selectedDate)
    viewModel.set('endDate', me.config.endDate)
    viewModel.set('selectedStore', me.config.selectedStore)
    viewModel.set('viewMode', me.config.viewMode)
    viewModel.set('hash', me.config.hash)
    viewModel.set('canEdit', me.config.canEdit)

    me.setLanguage(me.config.language)

    me.setPanelManagerVisibility(me.config.panelVisibility)
    var mainGridColumnsMerged = []

    var state = me.getPluginState()

    if (me.config.mainGridColumns && me.config.mainGridColumns.length > 0) {
      mainGridColumnsMerged = po.Global.mergeColumns(
        me.config.mainGridColumns,
        po.Columns.mainGridColumns
      )
      viewModel.set('mainGridColumns', me.config.mainGridColumns)
    } else {
      mainGridColumnsMerged = po.Columns.mainGridColumns
    }

    var columns = []
    if (state && state.columnStates.length > 0 && state.hash == viewModel.get('hash')) {
      mainGridColumnsMerged.map((element) => {
        if (element.columns) {
          element.columns.forEach((e) => {
            columns.push(e)
          })
        } else {
          columns.push(element)
        }
      })

      columns.forEach((column) => {
        state.columnStates.forEach((state) => {
          if (column && column.hasOwnProperty('columnId') && column.columnId == state.columnId) {
            column.width = state.width
            column.locked = state.locked
            column.hidden = state.hidden
            column.groupHeader = state.groupHeader
            column.columnOrder = state.columnOrder
          }
        })
      })
      let showedColumns = _.filter(columns, (column) => {
        return column && !_.isNil(column.columnOrder)
      })

      let sortedColumns = _.sortBy(showedColumns, 'columnOrder')
      let groupedColumns = []
      let orderedGroupColumns = []

      if (sortedColumns.length) {
        let groupedArr = _.groupBy(sortedColumns, 'groupHeader')
        let groupObj = {}

        _.forEach(groupedArr, (items, key) => {
          if (key === 'undefined') {
            _.forEach(items, (item) => {
              item.maxOrder = item.columnOrder
              groupedColumns.push(item)
            })
          } else {
            let groupObj = {
              columns: items,
              text: key,
              locked: items[0].locked,
              maxOrder: items[items.length - 1].columnOrder
            }
            groupedColumns.push(groupObj)
          }
        })
        orderedGroupColumns = _.sortBy(groupedColumns, 'maxOrder')
      }
      mainGridColumnsMerged = orderedGroupColumns
    }

    let asignedBind = {
      store: '{mainStore}',
      i18n: '{i18n}'
    }
    if (me.config.previewMode) {
      asignedBind = {
        store: '{mainStore}',
        i18n: '{i18n}',
        columns: '{mainGridColumnsMerged}'
      }
    }
    me.items = [
      {
        xtype: 'mainToolbar',
        region: 'north',
        header: false,
        hidden: !(po && po.Global && po.Global.config && po.Global.config.isDevelopment)
      },
      {
        title: 'Center Region',
        region: 'center',
        xtype: 'poMainGrid',
        flex: 1,
        columns: mainGridColumnsMerged,
        bind: asignedBind,
        lockedGridConfig: {
          width: 500,
          split: true,
          stateful: {
            columns: false,
            width: true,
            weight: true
          },
          stateId: pluginInstanceId + 'split',
          collapsible: false,
          applyState: function (state) {
            if (state) {
              this.setWidth(state.width)
            }
          }
        }
      },
      {
        xtype: 'panel',
        region: 'east',
        hidden: !panelVisibility.productDetailVisible,
        ui: 'panel-inverse',
        bind: {
          title: '{i18n.EditorView_title}' + ' : ' + '{selectedRecord.UrunAdi}',
          disabled: '{isEditorHidden}',
          hidden: '{!panelVisibility.productDetailVisible}'
        },
        margin: 3,
        reference: 'editorView',
        collapsible: true,
        animCollapse: false,
        titleCollapse: true,
        stateful: true,
        collapseMode: 'mini',
        stateId: 'editorViewState',
        collapseDirection: 'right',
        width: 400,
        layout: {
          type: 'vbox',
          align: 'stretch'
        },
        border: false,
        split: true,
        header: {
          height: 37,
          style: 'padding:8px 16px 12px 16px; !important;'
        },
        items: [
          {
            xtype: 'poEditorView',
            region: 'center',
            flex: 1,
            hidden: me.config.summaryEditorHidden,
            scrollable: true,
            margin: '2px 0px 0px 2px'
          },
          {
            xtype: 'panel',
            hidden: me.config.roundUpHidden,
            split: true,
            header: false,
            layout: 'fit',
            ui: 'panel-inverse',
            flex: 1,
            hideCollapseTool: true,
            collapsible: true,
            items: [
              {
                xtype: 'roundup',
                ui: 'panel-inverse',
                margin: '0 0 5 -3',
                header: {
                  height: 37,
                  style: 'padding:8px 16px 12px 16px; !important;'
                },
                bind: {
                  store: '{roundUpStore}',
                  title: '{selectedRecord.tedarikciKodu}' + ' - ' + '{selectedRecord.tedarikciAdi}'
                }
              }
            ]
          }
        ],
        listeners: {
          expand: function () {
            var me = this,
              poMainview = this.up(),
              viewModel = poMainview.getViewModel(),
              roundUpStore = viewModel.get('roundUpStore')

            roundUpStore.load()
          }
        }
      },
      {
        xtype: 'panel',
        reference: 'detailView',
        region: 'east',
        stateful: true,
        stateId: 'detailViewState',
        publishes: ['hidden'],
        hidden: !panelVisibility.inventoryProfileVisible,
        bind: {
          title: '{i18n.MainView_East_panel_title}',
          hidden: '{!panelVisibility.inventoryProfileVisible}'
        },
        layout: {
          type: 'vbox',
          align: 'stretch'
        },
        margin: 3,
        ui: 'panel-inverse',
        collapsible: true,
        animCollapse: false,
        collapseMode: 'mini',
        titleCollapse: true,
        split: true,
        width: 400,
        header: {
          height: 37,
          style: 'padding:8px 16px 12px 16px; !important;'
        },
        items: [
          {
            xtype: 'tabpanel',
            flex: 2,
            hidden: me.config.InventoryHidden,
            margin: '-2px 0px 0px -4px',
            split: true,
            items: [
              {
                xtype: 'panel',
                layout: 'fit',
                ui: 'panel-inverse',
                items: [
                  {
                    bind: {
                      store: '{inventoryStore}',
                      i18n: '{i18n}'
                    },
                    xtype: 'poInventoryChart',
                    margin: '10px 0px 0px 0px'
                  }
                ],
                bind: {
                  title: '{i18n.MainView_East_panel_poInventoryChart_title}'
                },
                iconCls: 'fa fa-bar-chart'
              },
              {
                iconCls: 'fa fa-table',
                xtype: 'poInventoryGrid',
                bind: {
                  store: '{inventoryStore}',
                  title: '{i18n.MainView_East_panel_poInventoryGrid_title}'
                }
              }
            ]
          },
          {
            split: true,
            collapsible: true,
            margin: '0 0 5 -3',
            flex: 1,
            hidden: me.config.grupGridHidden,
            xtype: 'panel',
            layout: 'card',
            activeItem: 0,
            ui: 'panel-inverse',
            bind: {
              activeItem: '{groupActiveItem}'
            },
            hideCollapseTool: true,
            header: {
              height: 37,
              style: 'padding:3px 16px 12px 16px; !important;',
              items: [
                {
                  xtype: 'button',
                  handler: 'onGroupClearFilter',
                  ui: 'btn-header',
                  iconCls: 'x-fa fa-filter',
                  cls: 'grupFilterButton',
                  bind: {
                    disabled: '{!isFiltered}',
                    tooltip: '{i18n.Button_RemoveFilter_tooltip}'
                  }
                },
                {
                  xtype: 'combo',
                  displayField: 'Title',
                  valueField: 'Field',
                  bind: {
                    store: '{groupbyStore}',
                    value: '{selectedGroupByField}'
                  }
                },
                {
                  xtype: 'button',
                  handler: 'onGroupViewChanged',
                  ui: 'btn-header',
                  bind: {
                    text: '{groupActiveItemButtonText}'
                  }
                }
              ]
            },
            items: [
              {
                height: 350,
                xtype: 'poGroupGrid',
                bind: {
                  store: '{groupStore}'
                }
              },
              {
                height: 350,
                xtype: 'poGroupChart',
                bind: {
                  data: '{groupStore}',
                  i18n: '{i18n}'
                }
              }
            ]
          }
        ],
        listeners: {
          expand: function () {
            var me = this,
              poMainview = this.up(),
              viewModel = poMainview.getViewModel(),
              groupStore = viewModel.get('groupStore'),
              inventoryStore = viewModel.get('inventoryStore')

            groupStore.load()
            inventoryStore.load()
          }
        }
      }
    ]
    me.bbar = {
      xtype: 'toolbar',
      overflowHandler: 'scroller',
      hidden: !panelVisibility.footerToolbarVisible,
      dock: 'top',
      bind: {
        hidden: '{!panelVisibility.footerToolbarVisible}'
      },
      items: [
        {
          xtype: 'button',
          hidden: !(panelVisibility.resetColumnSequence && me.config.canResetColumnSequence),
          iconCls: 'x-fa fa-arrows-h',
          bind: {
            text: '{i18n.ResetColumnSequence}',
            tooltip: '{i18n.ResetColumnSequenceTooltip}'
          },
          handler: 'resetColumnsState'
        },
        {
          xtype: 'tbtext',
          bind: {
            text: '{statusText}'
          }
        }
      ]
    }
    me.callParent()
  },

  setPanelManagerVisibility: function (panelVisibility) {
    var me = this,
      viewModel = me.getViewModel()
    if (panelVisibility) {
      viewModel.set(
        'panelManagerHidden',
        panelVisibility &&
          panelVisibility.productDetailVisible == false &&
          panelVisibility.inventoryProfileVisible == false
      )
      viewModel.set(
        'panelManagerProductCheckBoxVisible',
        panelVisibility && panelVisibility.productDetailVisible == true
      )
      viewModel.set(
        'panelManagerDetailCheckBoxVisible',
        panelVisibility && panelVisibility.inventoryProfileVisible == true
      )
    } else {
      const localPanelVisibility = viewModel.get('panelVisibility')
      viewModel.set(
        'panelManagerHidden',
        !(
          (localPanelVisibility && localPanelVisibility.productDetailVisible == true) ||
          (localPanelVisibility && localPanelVisibility.inventoryProfileVisible == true)
        )
      ),
        viewModel.set(
          'panelManagerProductCheckBoxVisible',
          localPanelVisibility && localPanelVisibility.productDetailVisible == true
        ),
        viewModel.set(
          'panelManagerDetailCheckBoxVisible',
          localPanelVisibility && localPanelVisibility.inventoryProfileVisible == true
        )
    }
  },
  stateful: true,
  stateId: 'mainViewState',
  getState: function () {
    var state = this.callParent()
    state.panelVisibility = this.panelVisibility
    return state
  },
  applyState: function (state) {
    var me = this,
      viewModel = me.getViewModel(),
      panelVisibility = viewModel.get('panelVisibility')
    if (
      panelVisibility &&
      panelVisibility.productDetailVisible == false &&
      panelVisibility.inventoryProfileVisible == false
    ) {
      return
    }
    if (panelVisibility && panelVisibility.productDetailVisible == false) {
      this.panelVisibility.inventoryProfileVisible = state.panelVisibility.inventoryProfileVisible
    }
    if (panelVisibility && panelVisibility.inventoryProfileVisible == false) {
      this.panelVisibility.productDetailVisible = state.panelVisibility.productDetailVisible
    }
  },
  defaults: {
    bodyPadding: 5
  },

  getPluginState() {
    var viewModel = this.getViewModel()
    var pluginInstanceId = viewModel.get('pluginInstanceId')
    var pluginstate = null
    Ext.Ajax.request({
      url: po.Global.getPluginApiUrl() + '/pluginstate/' + pluginInstanceId,
      method: 'GET',
      async: false,
      success: function (response) {
        var responseJson = Ext.decode(response.responseText)
        if (responseJson.length > 0) {
          if (responseJson[0] && responseJson[0].config && responseJson[0].config.state) {
            pluginstate = {
              columnStates: responseJson[0].config.state.columnStates,
              hash: responseJson[0].config.state.stateHash
                ? responseJson[0].config.state.stateHash
                : null
            }
          }
        }
      }
    })
    if (pluginstate != null) {
      viewModel.set('columnStates', pluginstate.columnStates)
      return pluginstate
    }
  },

  setLanguage: function (language) {
    var me = this,
      viewModel = me.getViewModel(),
      localizationStore = viewModel.getStore('localizationStore')

    var proxy = localizationStore.getProxy()
    proxy.setExtraParam('pluginTypeId', viewModel.get('pluginTypeId'))
    proxy.setExtraParam('pluginInstanceId', viewModel.get('pluginInstanceId'))
    proxy.setExtraParam('language', language)

    localizationStore.load()

    return language
  }
})
