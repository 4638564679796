Ext.define('MDM.src.view.globalapp.Panel.Geo.Dilaog.Region.Form', {
  extend: 'Ext.form.Panel',
  xtype: 'global-form-megargion',
  title: 'Login',
  frame: false,
  header: false,
  bodyPadding: 10,
  margin: '5 0 0 0',
  defaultType: 'textfield',
  items: [
    {
      xtype: 'fieldset',
      bind: {
        title: '{fieldLabel} Information'
      },
      defaultType: 'textfield',
      layout: 'anchor',
      flex: 1,
      defaults: {
        anchor: '100%',
        labelAlign: 'right',
        labelWidth: 100
      },
      items: [
        {
          allowBlank: false,
          fieldLabel: 'Mega Region',
          bind: {
            value: '{record.Title}',
            fieldLabel: '{fieldLabel}',
            visible: '{isTitleVisible}'
          },
          emptyText: 'Description',
          msgTarget: 'under'
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',
          items: [
            {
              xtype: 'textfield',
              fieldLabel: 'Latitude',
              name: 'firstName',
              labelAlign: 'right',
              reference: 'lat',
              bind: {
                value: '{record.Latitude}'
              },
              itemId: 'lat',
              labelWidth: 80,
              readOnly: true,
              flex: 1
            },
            {
              xtype: 'textfield',
              fieldLabel: 'Longitude',
              labelAlign: 'right',
              labelWidth: 80,
              name: 'firstName',
              bind: '{record.Longitude}',
              itemId: 'long',
              readOnly: true,
              flex: 1
            }
          ]
        }
      ]
    }
  ]
})
