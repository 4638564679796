Ext.define('Spui.view.common.productinfo.cmp.tabpanel.Main', {
  extend: 'Ext.tab.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-tabpanel-main',

  // Uncomment to give this component an xtype
  ui: 'spui',
  frame: false,
  border: false,
  getState: function () {
    var me = this

    var state = Ext.tab.Panel.prototype.getState()

    state = me.addPropertyToState(
      state,
      'activeTab',
      me.items.findIndex('id', me.getActiveTab().id)
    )
    return state
  },

  // stateFul applyState override
  applyState: function (state) {
    var me = this
    if (state) {
      if (state.activeTab) {
        me.setActiveTab(state.activeTab)
      }
      Ext.apply(me, state)
    }
  },

  plugins: [
    {
      ptype: 'cornerIcon',
      cfgButton: [
        '->',
        {
          iconCls: 'x-fa fa-cog',
          cls: 'config-btn',
          handler: 'onConfigureTabPanel'
        }
      ]
    }
  ],
  items: [
    {
      xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-main',
      bind: {
        recordData: '{spotRecord}',
        materialCode: '{spotRecord.MaterialCode}'
      }
    },
    {
      title: tt('MaterialInformation'),
      xtype: 'spui-view-common-productinfo-cmp-tabpanel-info-main',
      bind: {
        materialCode: '{spotRecord.MaterialCode}'
      }
    },
    {
      title: tt('InventoryList'),
      xtype: 'spui-view-common-productinfo-cmp-tabpanel-inventory-main',
      inventoryCfg: Spui.Global.getUserRole() + '-InventoryList',
      bind: {
        materialCode: '{spotRecord.MaterialCode}'
      }
    }
  ]
})
