/* global Ext */
Ext.override(Ext.exporter.excel.Xlsx, {
  solvoyoHeaderStyle: {
    borders: [
      {
        position: 'Top',
        lineStyle: 'Continuous',
        weight: 1,
        color: '#4F81BD'
      },
      {
        position: 'Bottom',
        lineStyle: 'Continuous',
        weight: 1,
        color: '#4F81BD'
      }
    ],
    font: {
      bold: true
    }
  },

  solvoyoFooterStyle: {
    borders: [
      {
        position: 'Top',
        lineStyle: 'Continuous',
        weight: 1,
        color: '#4F81BD'
      },
      {
        position: 'Bottom',
        lineStyle: 'Continuous',
        weight: 1,
        color: '#4F81BD'
      }
    ],
    font: {
      bold: true
    }
  },

  getContent: function () {
    var me = this

    var config = this.getConfig()

    var data = config.data

    var colMerge

    var ws

    me.excel = new Ext.exporter.file.ooxml.Excel({
      properties: {
        title: config.title,
        author: config.author
      }
    })

    me.worksheet = ws = me.excel.addWorksheet({
      name: config.title
    })

    me.tableHeaderStyleId = me.excel.addCellStyle(config.tableHeaderStyle)

    // ***************** Solvoyo Added Code *****************
    if (
      !me.onlyExpandedNodes &&
      (me.viewLayoutType === 'compact' || me.viewLayoutType === 'outline')
    ) {
      const solvoyoHeaderStyleId = this.excel.addCellStyle(
        me.solvoyoHeaderStyle
      )
      const solvoyoFooterStyleId = this.excel.addCellStyle(
        me.solvoyoFooterStyle
      )

      me.headerStyles = {}
      me.footerStyles = {}

      for (var i = 0; i < 10; i++) {
        me.headerStyles['l' + i] = solvoyoHeaderStyleId
        me.footerStyles['l' + i] = solvoyoFooterStyleId
      }
    }

    // *******************************************************

    colMerge = data ? data.getColumnCount() : 1

    ws.beginRowRendering()

    // Solvoyo removed the title
    // me.addTitle(config, colMerge)

    if (data) {
      ws.renderRows(me.buildHeader())
      ws.renderRows(me.buildRows(data, colMerge, -1))
    }

    ws.endRowRendering()

    // ***************** Solvoyo Added Code *****************
    me.rowGrandTotalsPosition = this.config.rowGrandTotalsPosition
    me.rowSubTotalsPosition = this.config.rowSubTotalsPosition
    me.colGrandTotalsPosition = this.config.colGrandTotalsPosition
    me.colGrandTotalsPosition = this.config.colGrandTotalsPosition
    me.viewLayoutType = this.config.viewLayoutType
    me.onlyExpandedNodes = this.config.onlyExpandedNodes

    if (!('userName' in this.config)) {
      console.warn('No user information is available in the export data.')
    }

    if (!('filters' in this.config)) {
      console.warn('No filters information is available in the export data.')
    }

    if (!('createLog' in this.config)) {
      console.warn('No createLog method is available in the export data.')
    }

    // Add Details worksheet
    const detailsWorksheet = me.excel.addWorksheet({
      name: 'Details'
    })

    // Write Content of details worksheet
    detailsWorksheet.beginRowRendering()
    detailsWorksheet.renderRows(
      me.buildDetailRows(this.config.userName, this.config.filters,this.config.pluginName)
    )
    detailsWorksheet.endRowRendering()

    // Log export operation
    if (this.config.createLog) {
      this.config.createLog('Export')
    }

    // *******************************************************

    me.columnStylesNormal = me.columnStylesNormalId = me.columnStylesFooter = me.columnStylesFooterId = null
    me.headerStyles = me.footerStyles = null

    return me.excel.render()
  },

  buildRows: function (group, colMerge, level) {
    var me = this

    var showSummary = me._showSummary

    var rows = []

    var groups

    var row

    var styleH

    var styleF

    var cells

    var i

    var j

    var k

    var gLen

    var sLen

    var cLen

    var oneLine

    var text

    var items

    var cell

    var temp

    var style

    if (!group) {
      return rows
    }

    // Use solvoyo exporter when
    // - Export all items
    // - Layout is compact or outline
    if (
      !me.onlyExpandedNodes &&
      (me.viewLayoutType === 'compact' || me.viewLayoutType === 'outline')
    ) {
      return this.buildRowsSolvoyo(group, colMerge, level)
    }
    else if(me.onlyExpandedNodes && me.viewLayoutType === 'tabular')
      {
        return this.buildTabularRows(group, colMerge, level)
      }

    groups = group._groups
    text = group._text

    // if the group has no subgroups and no rows then show only summaries
    oneLine = !groups && !group._rows

    if (showSummary !== false && !Ext.isEmpty(text) && !oneLine) {
      styleH = me.getGroupHeaderStyleByLevel(level)
      rows.push({
        styleId: styleH,
        cells: [
          {
            mergeAcross: colMerge - 1,
            value: text,
            styleId: styleH
          }
        ]
      })
    }

    if (groups) {
      gLen = groups.length
      for (i = 0; i < gLen; i++) {
        Ext.Array.insert(
          rows,
          rows.length,
          me.buildRows(groups.items[i], colMerge, level + 1)
        )
      }
    }
    if (group._rows) {
      items = group._rows.items
      sLen = items.length
      for (k = 0; k < sLen; k++) {
        temp = items[k]
        row = {
          id: temp._id,
          cells: []
        }
        cells = temp._cells
        cLen = cells.length
        for (j = 0; j < cLen; j++) {
          cell = cells.items[j]
          style = me.columnStylesNormalId[j]
          row.cells.push({
            id: cell._id,
            value: cell._value,
            styleId: me.getCellStyleId(cell._style, style)
          })
        }
        rows.push(row)
      }
    }

    items = group._summaries && group._summaries.items
    if (items && (showSummary || oneLine)) {
      styleF = me.getGroupFooterStyleByLevel(level)
      sLen = items.length
      for (k = 0; k < sLen; k++) {
        // that's the summary footer
        temp = items[k]
        row = {
          id: temp._id,
          cells: []
        }
        cells = temp._cells
        cLen = cells.length
        for (j = 0; j < cLen; j++) {
          cell = cells.items[j]
          style = oneLine
            ? me.columnStylesNormalId[j]
            : j === 0
              ? styleF
              : me.columnStylesFooterId[j]
          row.cells.push({
            id: cell._id,
            value: cell._value,
            styleId: me.getCellStyleId(cell._style, style)
          })
        }
        rows.push(row)
      }
    }
    group.destroy()

    return rows
  },

  buildRowsSolvoyo: function (group, colMerge, level) {
    var me = this

    var showSummary = me._showSummary

    var rows = []

    var groups

    var row

    var styleH

    var styleF

    var cells

    var i

    var j

    var k

    var gLen

    var sLen

    var cLen

    var oneLine

    var text

    var items

    var cell

    var temp

    var style

    if (!group) {
      return rows
    }

    groups = group._groups
    text = group._text

    // if the group has no subgroups and no rows then show only summaries
    oneLine = !groups && !group._rows

    // Header Rows
    items = group._summaries && group._summaries.items
    if (
      items &&
      (showSummary || oneLine) &&
      // Row subtotals in all cases
      (level >= 0 ||
        // Grand total as first row
        (me.rowGrandTotalsPosition === 'first' && level < 0))
    ) {
      styleF = me.getGroupHeaderStyleByLevel(level)
      sLen = items.length
      for (k = 0; k < sLen; k++) {
        // that's the summary footer
        temp = items[k]
        row = {
          id: temp._id,
          cells: []
        }
        cells = temp._cells
        cLen = cells.length

        for (j = 0; j < cLen; j++) {
          cell = cells.items[j]

          var rowHeaderValue = cell._value
          var rowHeaderStyle = oneLine
            ? me.columnStylesNormalId[j]
            : j === 0
              ? styleF
              : me.columnStylesFooterId[j]

          // Add HeaderValueStyle
          var rowHeaderValueStyle = Ext.clone(me.solvoyoHeaderStyle)
          var rowHeaderValueStyleId = me.excel.addCellStyle(
            rowHeaderValueStyle,
            me.columnStylesFooterId[j]
          )

          if (me.viewLayoutType === 'compact') {
            if (j === 0) {
              rowHeaderValue = text
            } else {
              if (me.rowSubTotalsPosition === 'first') {
                rowHeaderValue = cell._value
              } else {
                rowHeaderValue = ''
              }
            }

            if (j === 0) {
              // Add HeaderStyle
              var rowHeaderTitleStyle = Ext.clone(me.solvoyoHeaderStyle)
              rowHeaderTitleStyle.alignment = {
                Indent: level
              }
              var rowHeaderTitleStyleId = me.excel.addCellStyle(
                rowHeaderTitleStyle
              )

              rowHeaderStyle = oneLine
                ? me.columnStylesNormalId[j]
                : rowHeaderTitleStyleId
            } else {
              rowHeaderStyle = oneLine
                ? me.columnStylesNormalId[j]
                : rowHeaderValueStyleId
            }
          } else if (me.viewLayoutType === 'outline') {
            if (j > level) {
              if (me.rowSubTotalsPosition === 'first') {
                // Value Cells
                rowHeaderValue = cell._value
              } else {
                // Empty header values
                rowHeaderValue = ''
              }
            } else if (j === level) {
              // Header Cell
              rowHeaderValue = text
            } else {
              rowHeaderValue = ''
            }

            rowHeaderStyle = oneLine
              ? me.columnStylesNormalId[j]
              : j <= level
                ? styleF
                : rowHeaderValueStyleId
          }

          row.cells.push({
            id: cell._id,
            value: rowHeaderValue,
            styleId: me.getCellStyleId(cell._style, rowHeaderStyle)
          })
        }

        rows.push(row)
      }
    }

    // Content Rows
    if (groups) {
      gLen = groups.length
      for (i = 0; i < gLen; i++) {
        Ext.Array.insert(
          rows,
          rows.length,
          me.buildRows(groups.items[i], colMerge, level + 1)
        )
      }
    }
    if (group._rows) {
      items = group._rows.items
      sLen = items.length
      for (k = 0; k < sLen; k++) {
        temp = items[k]
        row = {
          id: temp._id,
          cells: []
        }
        cells = temp._cells
        cLen = cells.length
        for (j = 0; j < cLen; j++) {
          cell = cells.items[j]
          style = me.columnStylesNormalId[j]

          if (me.viewLayoutType === 'compact' && j === 0) {
            // Indent the first cell of the compact content
            var compactFirstRowStyle = {
              parentId: me.columnStylesFooterId[j],
              alignment: {
                Indent: level + 1
              }
            }
            style = me.excel.addCellStyle(compactFirstRowStyle)
          }

          row.cells.push({
            id: cell._id,
            value: cell._value,
            styleId: me.getCellStyleId(cell._style, style)
          })
        }
        rows.push(row)
      }
    }

    // Footer Rows
    items = group._summaries && group._summaries.items
    if (
      items &&
      (showSummary || oneLine) &&
      // Row subtotals as last rows
      ((me.rowGrandTotalsPosition === 'last' && level < 0) ||
        // Grand total as last row
        (me.rowSubTotalsPosition === 'last' && level >= 0))
    ) {
      styleF = me.getGroupFooterStyleByLevel(level)
      sLen = items.length
      for (k = 0; k < sLen; k++) {
        // that's the summary footer
        temp = items[k]
        row = {
          id: temp._id,
          cells: []
        }
        cells = temp._cells
        cLen = cells.length
        for (j = 0; j < cLen; j++) {
          cell = cells.items[j]
          var rowFooterValue = cell._value
          var rowFooterStyle = oneLine
            ? me.columnStylesNormalId[j]
            : j === 0
              ? styleF
              : me.columnStylesFooterId[j]

          // Add FooterValueStyle

          var rowFooterValueStyle = Ext.clone(me.solvoyoFooterStyle)
          var rowFooterValueStyleId = me.excel.addCellStyle(
            rowFooterValueStyle,
            me.columnStylesFooterId[j]
          )

          if (me.viewLayoutType === 'compact') {
            if (j === 0) {
              // Add HeaderStyle
              var rowFooterTitleStyle = Ext.clone(me.solvoyoFooterStyle)
              rowFooterTitleStyle.alignment = {
                Indent: level
              }

              var rowFooterTitleStyleId = me.excel.addCellStyle(
                rowFooterTitleStyle
              )

              rowFooterStyle = oneLine
                ? me.columnStylesNormalId[j]
                : rowFooterTitleStyleId
            } else {
              rowFooterStyle = oneLine
                ? me.columnStylesNormalId[j]
                : rowFooterValueStyleId
            }
          } else if (me.viewLayoutType === 'outline') {
            if (j < level) {
              // Pre levels are empty
              rowFooterValue = ''
            } else if (j === level) {
              // At the correct level show total (X)
              rowFooterValue = cells.items[0]._value
            }

            rowFooterStyle = oneLine
              ? me.columnStylesNormalId[j]
              : j <= level
                ? styleF
                : rowFooterValueStyleId
          }
          row.cells.push({
            id: cell._id,
            value: rowFooterValue,
            styleId: me.getCellStyleId(cell._style, rowFooterStyle)
          })
        }
        rows.push(row)
      }
    }
    group.destroy()

    return rows
  },

  buildDetailRows: function (userName, filters,pluginName) {
    const rows = []
    const userRow = {
      id: 1,
      cells: [
        { value: pluginName },
        { value: userName },
        { value: Ext.Date.format(new Date(), 'Y-m-d H:i:s') }
      ]
    }

    const filterCaptionRow = {
      id: 2,
      cells: [{ value: 'Filters' }]
    }

    rows.push(userRow)
    rows.push(filterCaptionRow)

    var i = 0
    for (var key in filters) {
      if (key.endsWith('_hidden')) {
        continue
      }
      var value = filters[key]
      if (filters[key] instanceof Array) {
        value = ''
        for (var k in filters[key]) {
          if (k > 0) {
            value += ', '
          }
          value += filters[key][k]
        }
      }

      var filterRow = {
        id: 2 + i,
        cells: [{ value: key }, { value: value }]
      }
      rows.push(filterRow)
      i++
    }

    return rows
  },
  
  buildTabularRows: function (group, colMerge, level,parentGroup) {
    var me = this

    var showSummary = me._showSummary

    var rows = []

    var groups

    var row

    var styleH

    var styleF

    var cells

    var i

    var j

    var k

    var gLen

    var sLen

    var cLen

    var oneLine

    var text

    var items

    var cell

    var temp

    var style


    if (!group) {
      return rows
    }
    if (!parentGroup) {
      parentGroup=[]
    }
    // Use solvoyo exporter when
    // - Export all items
    // - Layout is compact or outline

    groups = group._groups
    text = group._text

    // if the group has no subgroups and no rows then show only summaries
    oneLine = !groups && !group._rows
    // ***************** Solvoyo Added Code *****************
    // Removing merged unusefull row
    if (!me.onlyExpandedNodes || (!me.viewLayoutType || me.viewLayoutType != 'tabular'))
    //*******************************************************
    {
      if (showSummary !== false && !Ext.isEmpty(text) && !oneLine) {
        styleH = me.getGroupHeaderStyleByLevel(level)
        rows.push({
          styleId: styleH,
          cells: [
            {
              mergeAcross: colMerge - 1,
              value: text,
              styleId: styleH
            }
          ]
        })
      }
    }
    if (groups) {
      gLen = groups.length
      for (i = 0; i < gLen; i++) {
       var textArray=_.cloneDeep(parentGroup)
       if(text)
       {
         textArray.push(text)
       }
        Ext.Array.insert(
          rows,
          rows.length,
          me.buildTabularRows(groups.items[i], colMerge, level + 1,textArray)
        )
      }
    }
    if (group._rows) {
      items = group._rows.items
      sLen = items.length
      for (k = 0; k < sLen; k++) {
        temp = items[k]
        row = {
          id: temp._id,
          cells: []
        }
        cells = temp._cells
        cLen = cells.length
        for (j = 0; j < cLen; j++) {
          cell = cells.items[j]
          style = me.columnStylesNormalId[j]
          row.cells.push({
            id: cell._id,
            value: cell._value,
            styleId: me.getCellStyleId(cell._style, style)
          })
        }
        rows.push(row)
      }
    }

    items = group._summaries && group._summaries.items

    items.forEach(item => {
      var cellValue = ''
      for (let i = 0; i < item._cells.items.length; i++) {
        const cell = item._cells.items[i];
        if (cellValue == '') {
          cellValue = cell._value
        }
        else if (cellValue == cell._value) {
          if (parentGroup) {
            item._cells.items[i - 1]._value = parentGroup[0]
          }
        }
        else if (cell.value != undefined) {
          cellValue = cell._value
        }
      }
    });
    
    if (items && (showSummary || oneLine)) {
      styleF = me.getGroupFooterStyleByLevel(level)
      sLen = items.length
      for (k = 0; k < sLen; k++) {
        // that's the summary footer
        temp = items[k]
        // ***************** Solvoyo Added Code *****************
        // Added for removing rowsubtotals when rowSubTotalsPosition is 'none'
        if(me.rowSubTotalsPosition == 'none' && temp.config.cells[0].value.includes('Total'))
        {
          continue;
        }
        // ******************************************************
        row = {
          id: temp._id,
          cells: []
        }
        cells = temp._cells
        
        cLen = cells.length
        for (j = 0; j < cLen; j++) {
          cell = cells.items[j]
          style = oneLine
            ? me.columnStylesNormalId[j]
            : j === 0
              ? styleF
              : me.columnStylesFooterId[j]
          row.cells.push({
            id: cell._id,
            value: cell._value,
            styleId: me.getCellStyleId(cell._style, style)
          })
        }
        rows.push(row)
      }
    }
    group.destroy()
    if(parentGroup.length>0){
      rows.forEach(row => {
        for (let i = 0; i < row.cells.length; i++) {
          const cell = row.cells[i];
          if(i<level && !cell.value)
          {
            cell.value=parentGroup[i]
          }
        }
      });
  }
    return rows
  },
})
