import { STORE_CURRENCY_RATES_SETTINGS } from '../actions/actionTypes'

const initialState = {
  currencyRatesSettings: {
    Name: 'TRY',
    CurrencySymbol: null,
    CurrencyUnicode: null
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_CURRENCY_RATES_SETTINGS:
      return {
        currencyRatesSettings: {
          ...state.currencyRatesSettings,
          ...action.payload.currencyRatesSettings
        }
      }
    default:
      return state
  }
}

export default reducer
