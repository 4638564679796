import { fetchCollection, fetchOne, updateOne, fetchRecord, deleteRecord } from '../crudoptV3'

const model = 'container'

export const getContainers = (catalogId, menuId, pageId) => {
  return fetchCollection(model, `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container`)
}

export const getContainer = (catalogId, menuId, pageId, containerId) => {
  return fetchOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container/${containerId}`,
    {},
    {
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container`
      },
      getRowKey: () => containerId,
      key: containerId
    }
  )
}

export const createContainer = (operationKey, catalogId, menuId, pageId, data) => {
  return fetchRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container`,
    {
      data
    },
    {
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container`
      },
      operationKey
    }
  )
}

export const updateContainer = (operationKey, catalogId, menuId, pageId, containerId, data) => {
  return updateOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container/${containerId}`,
    {
      data
    },
    {
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container`
      },
      getRowKey: () => containerId,
      key: containerId,
      operationKey
    }
  )
}

export const removeContainer = (operationKey, catalogId, menuId, pageId, id) => {
  return deleteRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container/${id}`,
    {},
    {
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container`
      },
      key: id,
      getRowKey: () => id,
      operationKey
    }
  )
}

export const registerContainer = (
  operationKey,
  catalogId,
  menuId,
  pageId,
  containerId,
  registerType,
  data
) => {
  return updateOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/container/${containerId}/register/${registerType}`,
    {
      data,
      params: { catalogId, menuId, pageId }
    }
  )
}

const modelContainerState = 'containerstate'

export const getContainerStates = (containerId) => {
  return fetchCollection(modelContainerState, `/container/${containerId}/state`)
}

export const createContainerState = (operationKey, containerId, data) => {
  return fetchRecord(modelContainerState, `/container/${containerId}/state`, {
    data
  })
}
