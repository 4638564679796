import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { setScrollPos } from '../../actions'
import { restoreScrollPos } from '../../utils'

class ScheduleLine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLineId: -1,
      calculatedHeight: 0,
      getRccpResourceLineResponse: {},
      shiftIndex: 1,
      startWith: 0,
      collection: {},
      isUserAllowedToChangeShiftPattern: true
    }

    this.getData = this.getData.bind(this)
  }

  getData(getRccpResourceLineResponse = {}, startWith = 0, shiftIndex = 1) {
    const { ResourceLinePeriods = [], ResourceLines = [] } = getRccpResourceLineResponse
    const newResponse = {
      ResourceLinePeriods: _.slice(ResourceLinePeriods, startWith, shiftIndex) || [],
      ResourceLines: _.map(ResourceLines, (item) => {
        return {
          ...item,
          ...{
            Weeks: _.slice(item.Weeks, startWith, shiftIndex) || []
          }
        }
      })
    }

    this.setState({
      getRccpResourceLineResponse: { ...getRccpResourceLineResponse, ...newResponse }
    })
  }

  weekSwitcher(direction) {
    const {
      getRccpResourceLineResponse,
      getRccpResourceLineResponse: { ResourceLinePeriods = [] },
      settings: {
        stateHandler: { shiftCalendar }
      },
      stateHandler
    } = this.props

    if (direction && this.state.startWith < ResourceLinePeriods.length - 1) {
      this.state.startWith++
    } else if (!direction && this.state.startWith > 0) {
      this.state.startWith--
    }

    this.getData(getRccpResourceLineResponse, this.state.startWith, this.state.startWith + 1)
    stateHandler({
      shiftCalendar: {
        ...shiftCalendar,
        periodIndex: this.state.startWith
      }
    })
  }

  componentDidMount() {
    const {
      periodsLength = 0,
      getRccpResourceLineResponse = {},
      getRccpResourceLineResponse: { ResourceLinePeriods = [] } = {},
      settings: {
        history: {
          componentState: {
            pushState: { ScheduleLine: { shiftCalendarDictionary = {} } = {} } = {}
          } = {},
          state: {
            back: { pushState: { TableResource: { resourceDictionary = {} } = {} } = {} } = {}
          } = {}
        } = {},
        stateHandler: { shiftCalendar = {} } = {}
      } = {},
      lastRequest: { GetRccpResourceLineResponse: { Request: { periodId = 0 } = {} } = {} } = {},
      clickedProductInfo: { periodIndex: clickedPeriodIndex = 0 } = {},
      resourcePeriodHierarchy: { Resources: resources = [] } = {},
      resourceIndex = 0,
      isAllowed = () => {},
      scrollPositions
    } = this.props

    const { Name: clickedPeriodName = '' } =
      ResourceLinePeriods[
        !_.isNil(shiftCalendar.periodIndex) ? shiftCalendar.periodIndex : clickedPeriodIndex
      ] || []

    const {
      formProductData: { Period = {}, Period: { Name: PeriodName = '' } = '', Resource = {} } = {}
    } =
      _.find(resourceDictionary, (item) => {
        const { formProductData: { Period: { Id } = {} } = {} } = item

        return Id === periodId
      }) || {}
    const foundIndex =
      _.findIndex(
        getRccpResourceLineResponse.ResourceLinePeriods,
        (item) =>
          item.Name === PeriodName ||
          (item.Name === clickedPeriodName && shiftCalendar.showWeeklyView)
      ) || 0
    const isFound = foundIndex !== -1
    const sWith = isFound ? foundIndex : 0
    const sIndex = isFound ? foundIndex + 1 : periodsLength

    const isUserAllowedToChangeShiftPattern = isAllowed('Shift Calendar/Shift Pattern')

    this.setState(
      {
        ...shiftCalendarDictionary,
        startWith: sWith,
        shiftIndex: sIndex,
        collection: {
          Resource: !_.isEmpty(Resource) ? Resource : resources[resourceIndex],
          Period
        },
        isUserAllowedToChangeShiftPattern
      },
      () => {
        restoreScrollPos(this.shiftCalendarRef, scrollPositions.shiftCalendar.main)
      }
    )

    this.getData(getRccpResourceLineResponse, sWith, sIndex)
  }

  handleScroll = (scrollArea, event) => {
    const { setScrollPos } = this.props
    const {
      target: { scrollTop, scrollLeft }
    } = event

    setScrollPos({
      view: 'shiftCalendar',
      scrollArea,
      scrollPos: { top: scrollTop, left: scrollLeft }
    })
  }

  render() {
    const {
      props: {
        connectApi,
        lastRequest: {
          GetRccpResourcePeriodResponse: {
            Request: ResourceRequest = {
              productId: -1,
              rccpConfig: {
                ModificationCount: 10000
              }
            }
          } = {},
          GetRccpResourceLineResponse: { Request = {} } = {}
        } = {},
        getRccpResourceLineResponse: rccpLineResponseProps = {},
        stateHandler,
        settings: { stateHandler: { shiftCalendar = {} } = {} } = {}
      },
      state: {
        currentLineId,
        getRccpResourceLineResponse: { ResourceLinePeriods = [], ResourceLines = [] },
        startWith,
        calculatedHeight,
        collection: { Resource: { Id: ResourceId = 0 } = {} } = {},
        isUserAllowedToChangeShiftPattern = true
        // shiftIndex
      }
    } = this

    const { Weeks: tWeeks = [] } = ResourceLines[0] || {}
    const totalWidth = tWeeks.length * 21 * 26
    const totalHeight = (currentLineId > -1 ? calculatedHeight - 38 : 0) + ResourceLines.length * 38

    return (
      <div
        className="shift-calendar-container shift-calendar-container-helper w-100 h-100"
        ref={(element) => {
          if (element) {
            this.shiftCalendarRef = element
          }
        }}
        onScroll={(event) => this.handleScroll('main', event)}
      >
        <div className="rcc-wrp rccp-table-line-schedule">
          <header className="rcc-header">
            <div className="header-sticky-section"></div>
            <aside ref="rccpHeader">
              <section className="rccp-line-header" style={{ width: totalWidth }}>
                {_.map(ResourceLinePeriods, (rItem, rIndex) => {
                  let { Dates = '', Name = '' } = rItem

                  return (
                    <main key={rIndex} className="shift-calendar-main  d-flex flex-column">
                      <nav className="-period-nav ">
                        {shiftCalendar.showWeeklyView ? (
                          <span>
                            <i
                              className={`${
                                startWith === 0 ? '-op-zero' : ''
                              } slvy-ui-icon-caret-left`}
                              onClick={this.weekSwitcher.bind(this, false)}
                            />
                            Weekly Schedule / {Name}
                            <i
                              className={`${
                                startWith === rccpLineResponseProps.ResourceLinePeriods.length - 1
                                  ? '-op-zero'
                                  : ''
                              } slvy-ui-icon-caret-right`}
                              onClick={this.weekSwitcher.bind(this, true)}
                            />
                          </span>
                        ) : (
                          <span>{Name}</span>
                        )}
                      </nav>
                      <div className="d-flex">
                        {_.map(Dates, (dItem, dIndex) => {
                          const time = moment(dItem).format('DD.MM.YYYY')

                          return (
                            <section className="date-shift-info" key={dIndex}>
                              <span>{time}</span>
                              <br />
                              <span> S1 | S2 | S3 </span>
                            </section>
                          )
                        })}
                      </div>
                    </main>
                  )
                })}
              </section>
            </aside>
          </header>
          <div className="rccp-table">
            <div className="rccp-left">
              <ul>
                {_.map(ResourceLines, (resourceItem, resourceIndex) => {
                  let { Id, Name, AvailablePatterns = [], Weeks = [] } = resourceItem

                  AvailablePatterns = AvailablePatterns || []

                  let isActive = currentLineId === Id,
                    cHeight = AvailablePatterns.length * 26 + 38,
                    styleForActive = ResourceId === Id ? { color: 'rgb(0, 255, 168)' } : {}

                  return (
                    <li
                      key={resourceIndex}
                      onClick={(e) => {
                        this.setState({
                          currentLineId: isActive ? -1 : Id,
                          calculatedHeight: cHeight
                        })
                        this.props.historyHandler({
                          key: 'ScheduleLine',
                          state: {
                            shiftCalendarDictionary: {
                              currentLineId: isActive ? -1 : Id,
                              calculatedHeight: cHeight
                            }
                          }
                        })
                      }}
                      style={isActive ? { height: calculatedHeight } : {}}
                      className={`${isActive ? '-active' : ''}`}
                    >
                      <header>
                        <i
                          className="slvy-ui-icon-caret-right"
                          style={{ ...styleForActive, padding: '0 6px' }}
                        />
                        <p>{Name}</p>
                      </header>

                      {isActive && (
                        <section className="available-patterns -av-list">
                          {_.map(AvailablePatterns, (aItem, aIndex) => {
                            let { Name: ApName = '' } = aItem
                            const isSelected = Weeks[0].PatternIndex === aIndex

                            return (
                              <div key={aIndex}>
                                <i className={`${isSelected ? 'slvy-ui-icon-caret-right' : ''}`} />
                                <div
                                  className={`${isSelected ? 'is-selected' : ''}`}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    if (isUserAllowedToChangeShiftPattern) {
                                      let diff = shiftCalendar.showWeeklyView
                                        ? {
                                            request: 'UpdateRccpResourceLineWeek',
                                            param: { week: _.first(Weeks).Name }
                                          }
                                        : { request: 'UpdateRccpResourceLine', param: {} }

                                      connectApi(diff.request, {
                                        resourceId: Id,
                                        patternIndex: aIndex,
                                        ...diff.param
                                      }).then((res) => {
                                        if (res.Code > 0) {
                                          ResourceRequest.rccpConfig.ModificationCount = 10000
                                          Request.rccpConfig.ModificationCount = 10000

                                          connectApi(
                                            'GetRccpResourcePeriodResponse',
                                            ResourceRequest
                                          ).then(() => {
                                            connectApi('GetRccpResourceLineResponse', Request).then(
                                              (r) => {
                                                this.getData(r, startWith, startWith + 1)
                                              }
                                            )
                                          })
                                        }
                                      })
                                    }
                                  }}
                                >
                                  {ApName}
                                </div>
                              </div>
                            )
                          })}
                        </section>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="rccp-body" ref="rccpBody" style={{ height: totalHeight }}>
              <main>
                {_.map(ResourceLines, (resourceItem, resourceIndex) => {
                  let { Id: RId = -1, AvailablePatterns = [], Weeks = [] } = resourceItem,
                    isActive = currentLineId === RId,
                    resourceStyle = isActive
                      ? { width: totalWidth, height: calculatedHeight }
                      : { width: totalWidth }

                  return (
                    <div
                      key={resourceIndex}
                      className={`-row ${isActive ? '-active' : ''}`}
                      style={resourceStyle}
                    >
                      <div className="-inner-row">
                        {_.map(Weeks, (wItem, wIndex) => {
                          let { Points = [] } = wItem

                          return (
                            <div key={wIndex} className="-c-item">
                              {_.map(Points, (pItem, pIndex) => {
                                let { Date: Pdate = '', Shift = 0, Value = 0 } = pItem

                                return (
                                  <label key={pIndex}>
                                    <input type="checkbox" checked={Value > 0} readOnly />
                                    <em
                                      onClick={() => {
                                        connectApi('UpdateRccpResourceLinePoint', {
                                          resourceId: RId,
                                          date: Pdate,
                                          shift: Shift,
                                          status: 1 - Value
                                        }).then((res) => {
                                          if (res.Code > 0) {
                                            ResourceRequest.rccpConfig.ModificationCount = 10000
                                            Request.rccpConfig.ModificationCount = 10000

                                            connectApi(
                                              'GetRccpResourcePeriodResponse',
                                              ResourceRequest
                                            ).then(() => {
                                              connectApi(
                                                'GetRccpResourceLineResponse',
                                                Request
                                              ).then((r) => {
                                                this.getData(r, startWith, startWith + 1)
                                              })
                                            })
                                          }
                                        })
                                      }}
                                    />
                                  </label>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>

                      {isActive && (
                        <div className="d-flex">
                          {_.map(Weeks, (w, wIndex) => (
                            <section className="available-patterns -av-shift-list">
                              {_.map(AvailablePatterns, (aItem, aIndex) => {
                                let { Pattern = [] } = aItem

                                return (
                                  <div key={`${wIndex}-${aIndex}`}>
                                    {_.map(Pattern, (pItem, pIndex) => {
                                      return (
                                        <b
                                          key={pIndex}
                                          className={`${pItem > 0 ? '-ap-selected' : ''}`}
                                        />
                                      )
                                    })}
                                  </div>
                                )
                              })}
                            </section>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                })}
              </main>
              <button
                className={`-collapse-btn ${!shiftCalendar.showWeeklyView ? '-pos-fix' : ''}`}
                style={{ height: totalHeight }}
                onClick={(e) => {
                  let newShiftIndex = shiftCalendar.showWeeklyView
                    ? rccpLineResponseProps.ResourceLinePeriods.length
                    : 1
                  this.getData(rccpLineResponseProps, 0, newShiftIndex)
                  this.setState({
                    shiftIndex: newShiftIndex
                  })
                  stateHandler({
                    shiftCalendar: {
                      showWeeklyView: !shiftCalendar.showWeeklyView
                    }
                  })
                }}
              >
                <i
                  className={`slvy-ui-icon-caret-${
                    shiftCalendar.showWeeklyView ? 'right' : 'left'
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
  resourcePeriodHierarchy: state.resourcePeriodHierarchy,
  resourceIndex: state.clickedProductInfo.resourceIndex,
  scrollPositions: state.scrollPositions
})

const mapDispatchToProps = {
  setScrollPos
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleLine)
