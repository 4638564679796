import * as actionTypes from './actionTypes'

export const setPluginConfiguration = (value) => {
  return {
    type: actionTypes.SET_PLUGIN_CONFIGURATION,
    options: value.options
  }
}

export const resetPluginConfiguration = (value) => {
  return {
    type: actionTypes.RESET_PLUGIN_CONFIGURATION,
    options: value.options
  }
}

// new production view...

export const PRODUCTsetPluginConfiguration = (value) => {
  return {
    type: actionTypes.PRODUCT_SET_PLUGIN_CONFIGURATION,
    options: value.options
  }
}

export const PRODUCTresetPluginConfiguration = (value) => {
  return {
    type: actionTypes.PRODUCT_RESET_PLUGIN_CONFIGURATION,
    options: value.options
  }
}
