import { useCallback, useEffect, useRef } from 'react'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { serviceModalSlice, tuiSlice } from '../../../store/slices'

function useSavePlan() {
  const savePlanRef = useRef<Record<string, () => void | undefined>>({})
  const [{ registerEvent }] = useReduxState(tuiSlice.selectSlice)
  const [serviceModal] = useReduxState(serviceModalSlice.selectSlice)

  const savePlan = useCallback(() => {
    return {
      runInfoId: serviceModal.selectedService?.RunInfoID
    }
  }, [serviceModal.selectedService?.RunInfoID])

  useEffect(() => {
    const fn = 'handleSavePlan'
    savePlanRef.current[fn] = registerEvent({
      key: 'savePlan',
      fn: savePlan,
      returnTypes: {
        runInfoId: PluginTypes.int
      }
    })
  }, [registerEvent, savePlan])

  return { savePlanRef }
}

export default useSavePlan
