import { getIsLookupQuery } from '../index'
import { customInfo as defaultCustomInfo } from './mocks'

const getCustomInfo = () => {
  return defaultCustomInfo
}

describe('AgGrid:Helpers:Lookup:getIsLookupQuery', () => {
  let customInfo = getCustomInfo()
  beforeEach(() => {
    customInfo = getCustomInfo()
  })

  it('should return true if customInfo?.fieldLookupConfig?.isLookupQuery is true', () => {
    customInfo.fieldLookupConfig.isLookupQuery = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsLookupQuery(customInfo)
    expect(result).toBe(true)
  })
  it('should return false if customInfo?.fieldLookupConfig?.isLookupQuery is false', () => {
    customInfo.fieldLookupConfig.isLookupQuery = false
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsLookupQuery(customInfo)
    expect(result).toBe(false)
  })
  it('should return false if customInfo?.fieldLookupConfig?.isLookupQuery is not defined', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customInfo.fieldLookupConfig = { substituteField: 'testCol' }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getIsLookupQuery(customInfo)
    expect(result).toBe(false)
  })
})
