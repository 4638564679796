import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'react-bootstrap'
import _ from 'lodash'
import AssortmentModal from '../Modal'
import AssortmentDropdown from '../Dropdown'
import { getMultipleValues, getSelectedFilters, makeRequest, mapOptions } from '../../utils'
import dependencyFilters from '../../utils/dependencyFilters'
import AssortmentLoading from '../Loading'
import styles from './index.module.scss'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { getTestOptionData } from '../../store/slices/testOptions'
import { SlvyTable } from '@/components'

const defaultDependencyFilters = [...dependencyFilters]

class SelectProductModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: {},
      columns: [
        {
          header: 'Name',
          accessorKey: 'Name.Value'
        },
        {
          header: 'Price',
          accessorKey: 'BasePrice.Value'
        },
        {
          header: 'Cost',
          accessorKey: 'Expenditure.Value'
        },
        {
          header: 'Division',
          accessorKey: 'Division.Value'
        },
        {
          header: 'Sub Division',
          accessorKey: 'SubDivision.Value'
        },
        {
          header: 'Age',
          accessorKey: 'Age.Value'
        },
        {
          header: 'Life Style',
          accessorKey: 'LifeStyle.Value'
        },
        {
          header: 'Collection Group',
          accessorKey: 'CollectionGroup.Value'
        },
        {
          header: 'Buyer Group',
          accessorKey: 'BuyerGroup.Value'
        },
        {
          header: 'Class',
          accessorKey: 'Class.Value'
        },
        {
          header: 'Range',
          accessorKey: 'Range.Value'
        },
        {
          header: 'Season',
          accessorKey: 'SeasonCode.Value'
        },
        {
          header: 'Color',
          accessorKey: 'ColorCode.Value'
        },
        {
          header: 'Price Levels',
          accessorKey: 'PriceLevel.Value'
        }
      ],
      Products: [],
      show: props.show,
      filters: {
        SeasonCode: {
          options: [],
          selected: ''
        }
      },
      page: 0
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        show: nextProps.show
      })
      if (nextProps.show) {
        const {
          testOptionsDefault: {
            AttributeNames: _AttributeNames = [],
            AttributeDetails: _AttributeDetails = {},
            Products: _Products = []
          }
        } = nextProps
        this.getSeason()
        this.convertDataToTable(_AttributeNames, _AttributeDetails, _Products)
      }
    }
  }

  convertDataToTable(_AttributeNames = [], _AttributeDetails = {}, _Products = []) {
    const products = []
    const productFields = ['Name', 'Description', 'BasePrice', 'Expenditure', 'ImageURL']
    let product = {}

    _Products.forEach((productItem) => {
      product = {}

      productFields.forEach((field) => {
        product[field] = {
          Value: productItem[field],
          Index: null
        }
      })

      _AttributeNames.forEach((name, nameIndex) => {
        product[name] = {
          Value: _AttributeDetails[name][productItem.AttributeIds[nameIndex]],
          Index: nameIndex
        }
      })

      products.push(product)
    })
    this.setState({ Products: products, selectedIndex: null })
  }

  getSeason() {
    const { rootOptions: { Filters = [] } = {}, pluginId, setLoader = () => {} } = this.props
    let seasonCodes
    let seasonCodeFilter
    let defaultSelectedSeason = ''
    if (!_.isEmpty(Filters)) {
      seasonCodes = [...Filters].filter(({ Name = '' }) => Name === 'SeasonCode')
      if (!_.isEmpty(seasonCodes)) {
        seasonCodeFilter = seasonCodes[0]
      }
    }

    if (seasonCodeFilter) {
      defaultSelectedSeason = !_.isEmpty(getMultipleValues(seasonCodeFilter))
        ? getMultipleValues(seasonCodeFilter)[0]
        : ''
    }

    setLoader({ isShown: true, messages: 'Loading Season' })

    makeRequest({
      payload: {
        pluginId,
        method: 'Filter',
        dropdownKey: 'SeasonCode',
        requestMethod: 'post',
        body: {
          Name: 'SeasonCode'
        }
      }
    }).then((response) => {
      const { body: { Values = [] } = {} } = response

      this.setState({
        filters: {
          ...this.state.filters,
          SeasonCode: {
            ...this.state.SeasonCode,
            options: mapOptions(Values),
            selected: defaultSelectedSeason
          }
        }
      })

      setLoader({ isShown: false })
    })
  }

  handleOnSave() {
    const {
      props: { handleOnSave = () => {} },
      state: { selectedIndex = null, Products = [] }
    } = this

    if (selectedIndex !== null) {
      handleOnSave(Products[selectedIndex])
    }
  }

  getRowStyle = (row, selectedIndex) => {
    if (row) {
      if (selectedIndex === row.index) {
        return {
          backgroundColor: 'rgba(168, 168, 168, 0.3)'
        }
      }
    }
  }

  handleSelectTableRow = (row, event) => {
    this.setState({ selectedIndex: row.index })
  }

  onFilterChanged(dropdownKey, val, config) {
    const { isMultiple = false } = config || {}
    let { key: selectedKey = '' } = val
    if (isMultiple) {
      selectedKey = val
    }
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [dropdownKey]: {
            ...this.state.filters[dropdownKey],
            selected: selectedKey
          }
        },
        page: 0
      },
      this.getTestOptionData.bind(this, false)
    )
  }

  getRequestParams(Filters) {
    const {
      state: {
        filters: {
          SeasonCode: { selected: seasonCodeSelected = null }
        }
      }
    } = this

    let requestParams = {
      fetchFilters: false
    }

    let selectedFilters = getSelectedFilters(defaultDependencyFilters, [...Filters])
    selectedFilters = selectedFilters.map((item) => {
      if (item.Key === 'SeasonCode') {
        item.Value = [seasonCodeSelected]
      }
      return item
    })

    selectedFilters.forEach((filter) => {
      const { FieldId = '', Value = '' } = filter
      if (Value === null || (_.isArray(Value) && Value.length === 0)) {
        // skip
      } else {
        requestParams = {
          ...requestParams,
          [FieldId]: FieldId !== 'seasonID' ? Value[0] : Value
        }
      }
    })

    return requestParams
  }

  getTestOptionData() {
    const {
      props: {
        pluginId = '',
        rootOptions: { Filters = [] } = {},
        fetchGetTestOptionData = () => {},
        setLoader = () => {}
      }
    } = this

    const selectedFilters = this.getRequestParams(Filters)

    const payload = {
      pluginId,
      dataKey: 'selectProduct',
      method: 'GetTestOptionData',
      requestMethod: 'post',
      body: {
        ...selectedFilters
      }
    }

    setLoader({ isShown: true, messages: 'Loading Test Option Data' })

    fetchGetTestOptionData({ ...payload })
      .then(() => {
        const {
          testOptionsDefault: {
            AttributeNames: _AttributeNames = [],
            AttributeDetails: _AttributeDetails = {}
          },
          testOptionsSelect: { Products: _Products = [] }
        } = this.props

        if (_AttributeNames && _AttributeDetails && _Products) {
          this.convertDataToTable(_AttributeNames, _AttributeDetails, _Products)
        } else {
          this.setState({
            selectedIndex: null,
            Products: []
          })
        }
        setLoader({ isShown: false })
      })
      .catch(setLoader({ isShown: false }))
  }

  getExtraAttrs(Products, size) {
    const attrs = {
      showPagination: false,
      showPageSizeOptions: false,
      defaultPageSize: size,
      pageSize: size
    }
    if (Products.length > size) {
      attrs.showPagination = true
      attrs.showPageSizeOptions = false
    }
    return attrs
  }

  render() {
    const {
      props: {
        handleCloseModal = () => {},
        loader: { isShown: isShownLoader = true }
      },
      state: {
        show = false,
        Products = [],
        columns = [],
        selectedIndex = null,
        filters: {
          SeasonCode: { options: seasonCodeOptions = [], selected: seasonCodeSelected = '' } = {}
        },
        page = 0
      }
    } = this
    const isSelected = selectedIndex !== null

    const {
      ImageURL: { Value: selectedImageUrl = '' } = {},
      Name: { Value: selectedName = '' } = {}
    } = isSelected ? Products[selectedIndex] : {}

    const attrs = this.getExtraAttrs(Products, 1000)

    return (
      <AssortmentModal
        isShowFooter
        className={styles.SelectProduct}
        footerTemplate={
          <Button size="sm" variant="success" onClick={this.handleOnSave.bind(this)}>
            <span className="slvy-ui-icon-check-circle-regular" />
          </Button>
        }
        handleCloseModal={handleCloseModal.bind(this)}
        show={show}
        size="slvy-modal-xl assortmentModal"
        title="Select Product"
      >
        <AssortmentLoading isShown={isShownLoader} />
        <div className="select-product-modal">
          <div className="form-horizontal">
            <div className={`form-group ${styles.SelectProductFormGroup}`}>
              <div className="col-sm-1">
                <label className="control-label">Season</label>
              </div>
              <div className="col-sm-2">
                <AssortmentDropdown
                  canAddEmpty={false}
                  dropdownKey="SeasonCode"
                  options={seasonCodeOptions}
                  selected={seasonCodeSelected}
                  onFilterChanged={this.onFilterChanged.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {Products.length > 0 ? (
            <div className={`col-sm-${isSelected && selectedImageUrl ? '9' : '12'}`}>
              <SlvyTable
                rowSelection
                suppressColumnMenu
                className="assortment'@/components-table-element select-product-grid"
                columns={columns}
                data={Products}
                getBodyStyle={{ width: 'fit-content' }}
                getHeadStyle={{ width: 'fit-content' }}
                getRowStyle={(row) => this.getRowStyle(row, selectedIndex)}
                onSelectRow={this.handleSelectTableRow}
              />
            </div>
          ) : null}
          {isSelected && selectedImageUrl ? (
            <div className="col-sm-3">
              <img alt={selectedName} className="full-width" src={selectedImageUrl} />
            </div>
          ) : null}
        </div>
      </AssortmentModal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    testOptionsDefault: state.testOptions.testOptionsData._default,
    testOptionsSelect: state.testOptions.testOptionsData.selectProduct,
    loader: state.app.loader
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setLoader: setLoaderReducer,
        fetchGetTestOptionData: getTestOptionData
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProductModal)
