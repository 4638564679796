Ext.define('Spui.view.wizard.WizardMainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.spui-view-wizard-wizardmaincontroller',
  /**
   * Called when the view is created
   */
  init: function () {
    var me = this,
      view = me.getView(),
      spotRecord = view.spotRecord
    viewModel = me.getViewModel()
    viewModel.set('spotRecord', spotRecord)
  },
  listen: {
    component: {
      'spui-view-common-productinfo-cmp-search-searchcombo': {
        select: 'onMaterialChange'
      }
    }
  },
  onHome: function () {
    var me = this,
      view = me.getView()
    var pageIndex = this.getViewModel().get('pageIndex')
    var spuiMain = this.view.up('spuimainview')
    if (spuiMain && pageIndex !== 1) {
      var trackingView = spuiMain.down('spui-view-tracking-navigation-main')
      trackingView.onRefrehTracking()
    }
    view.fireEvent('gohome', view)
  },
  showNext: function () {
    var me = this,
      v = me.getView(),
      wizard = me.lookup('wizard'),
      form = me.lookup('inputForm'),
      panel = this.lookup('wiazarcardlayout'),
      vm = me.getViewModel(),
      pageIndex = this.getViewModel().get('pageIndex'),
      record = vm.get('spotRecord')
    if (record) {
      if (pageIndex == 1) {
        var materialCode = record.get('MaterialCode'),
          isValid = form.isValid()
        if (!materialCode) {
          Ext.Msg.alert(tt('Durum'), tt('İlerlemek için ürün seçmelisiniz.'))
          return
        }
        if (!isValid) {
          Ext.Msg.alert(
            tt('Durum'),
            tt('İlerlemek için kampanya zorunlu alanları doldurmalısınız.')
          )
        }
        if (record.get('MinQuantity')<0) {
          Ext.Msg.alert(tt('Durum'), tt('Minimum sipariş adetini 0`dan büyük bir sayı girmelisiniz.'))
          return
        }
        if (record.get('MaxQuantity')<0) {
          Ext.Msg.alert(tt('Durum'), tt('Maksimum sipariş adetini 0`dan büyük bir sayı girmelisiniz.'))
          return
        }
        
        if (record.get('MaxQuantity')>0 && record.get('MaxQuantity') < record.get('MinQuantity')) {
          Ext.Msg.alert(tt('Durum'), tt('Maksimum sipariş adetini minimum sipariş adetinden küçük giremezsiniz.'))
          return
        }
        if (!record.get('VendorCode')) {
          Ext.Msg.alert(tt('Durum'), tt('İlerlemek için bir tedarikçi seçmelisiniz.'))
          return
        }
        if(record.get('AllowRoundingUp') && record.get('MinQuantity')>0 && record.get('MinQuantity')%1 >0)
        {
          Ext.Msg.alert(tt('Durum'), tt('Sipariş önerilerini yuvarla seçeneği aktif olduğunda, Minimum sipariş adetini tam sayı girmelisiniz.'))
          return
        }
        if (form.isValid()) {
          wizard.setLoading(tt('Yükleniyor'))
          var actionType = 'Step0'
          record.set('PageIndex', pageIndex)
          record.save({
            params: {
              actionType: actionType
            },
            failure: function (record, operation) {
              wizard.setLoading(false)
            },
            success: function (record, operation) {
              wizard.setLoading(false)
              me.navigate('next')
            },
            callback: function (record, operation, success) {
              // do something whether the save succeeded or failed
            }
          })
        }
      } else if (pageIndex == 2) {
        var i = this.getViewModel().get('numberofselectedItems')

        if (!i) {
          Ext.Msg.alert(tt('Durum'), tt('Enaz bir adet benzer kampanya seçmeniz gerekir'))
          return
        }
        if (record.get('expectedPerformanceIndex') < 0.5) {
          Ext.Msg.alert(tt('Durum'), tt(`Beklenti endeksi 0.5'den küçük olamaz.`))
          return
        }
        if (i) {
          wizard.setLoading(tt('Yükleniyor'))
          record.save({
            params: {
              actionType: 'step0'
            },
            failure: function (record, operation) {
              wizard.setLoading(false)
            },
            success: function (record, operation) {
              Ext.Ajax.request({
                url: Spui.Global.getApiUrl() + '/spui/CalculateRecommendationForDC',
                method: 'POST',
                timeout: 600000,
                params: {
                  SpotId: record.get('SpotId')
                },
                success: function (response, opts) {
                  var obj = response.responseJson
                  wizard.setLoading(false)
                  me.navigate('next')
                },
                failure: function (response, opts) {
                  wizard.setLoading(false)
                  console.log('server-side failure with status code ' + response.status)
                }
              })
            },
            callback: function (record, operation, success) {
              // do something whether the save succeeded or failed
            }
          })
        }
      } else {
        //@TODO v2 CUI-1060
        var distributionMain = Ext.getCmp('spuiViewCommonRecomMain'), //v.down('spui-view-common-recom-distribution-main'),
          distributionMainView = distributionMain.getViewModel(),
          recomStore = null
        if (distributionMainView) {
          recomStore = distributionMainView.get('recomStore')
        }
        if (recomStore) {
          recomStore.each(function (record) {
            if (record.get('IsLocked')) {
              record.set('IsLocked', 0)
              console.log(record)
            }
          })

          recomStore.getProxy().setExtraParam('updateAction', 'UnlockAll')
          recomStore.sync()
        }

        this.navigate('next')
      }
    }
  },
  showPrevious: function () {
    this.navigate('prev')
  },
  navigate: function (direction, index) {
    var me = this,
      panel = this.lookup('wiazarcardlayout')
    // This routine could contain business logic required to manage the navigation steps.
    // It would call setActiveItem as needed, manage navigation button state, handle any
    // branching logic that might be required, handle alternate actions like cancellation
    // or finalization, etc.  A complete wizard implementation could get pretty
    // sophisticated depending on the complexity required, and should probably be
    // done as a subclass of CardLayout in a real-world implementation.
    var layout = panel.getLayout()
    if (direction) {
      var i = panel.items.indexOf(panel.getLayout().activeItem) + 1
      index = direction === 'next' ? i + 1 : i - 1
      layout[direction]()
    } else if (index !== undefined) {
      layout.setActiveItem(index)
      index++
    }
    this.getViewModel().set('pageIndex', index)
    Ext.select('.indicator[index="' + index + '"]').addCls('active')
  },
  onChangePreStartDate: function (el, value) {
    var record = this.getViewModel().get('spotRecord')
    var PreStartDate = Ext.Date.add(value, Ext.Date.DAY, 21)
    record.set('PreEndDate', PreStartDate)
  },
  onMaterialChange: function (combo, record) {
    var me = this,
      viewModel = me.getViewModel(),
      spotRecord = viewModel.get('spotRecord')
    if (record) {
      if (!spotRecord) {
        spotRecord = Ext.create('Spui.data.model.SpotBase', {
          MaterialCode: record.get('MaterialCode'),
          MaterialDescription: record.get('MaterialDescription'),
          MaterialGroupCode: record.get('MaterialGroupCode'),
          TotalShelfLife: record.get('TotalShelfLife'),
          MaterialGroupName: record.get('MaterialGroupName')
        })
        viewModel.set('spotRecord', spotRecord)
      } else {
        spotRecord.set('MaterialCode', record.get('MaterialCode'))
        spotRecord.set('MaterialDescription', record.get('MaterialDescription'))
        spotRecord.set('TotalShelfLife', record.get('TotalShelfLife'))
        spotRecord.set('MaterialGroupCode', record.get('MaterialGroupCode'))
        spotRecord.set('MaterialGroupName', record.get('MaterialGroupName'))
        spotRecord.set('VendorCode',record.get('vendorCode'))
      }
      this.doMaterialChange(record)
    }
  },
  doMaterialChange: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      spotRecord = viewModel.get('spotRecord')
      var tabpanelView =me.lookupReference('tabpanel-info-mixin');
      var vendorStore = tabpanelView.getVendorStore();
      vendorStore.getProxy().setExtraParam('MaterialCode', record.get('MaterialCode'));
      vendorStore.load();
  }
})
