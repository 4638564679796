import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import numeral from 'numeral'
import NumberFormat from 'react-number-format'
import dependencyFilters from '../../utils/dependencyFilters'
import settings from '../../utils/settings'
import { getDateDiff, getIsMultipleByKey, getSelectedFilters, mapOptions } from '../../utils'
import TestProductFilters from './testProductFilters'
import AssortmentDropdown from '../Dropdown'
import AssortmentMainFilters from '../AssortmentMainFilters'
import { slvyToast, Title } from '../../../../components'
import AssortmentInput from '../Input'
import { getOptions } from '../../store/slices/options'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { getTestOptionData, setFilters } from '../../store/slices/testOptions'

const defaultDependencyFilters = [...dependencyFilters]
const selectFromState = (state) => ({
  filters: state.testOptions.filters,
  rootOptions: state.options.rootOptions,
  rootOptionsError: state.options.error,
  rootOptionsLoading: state.options.isLoading,
  SelectedOption: state.options.selected,
  testOptionsDefault: state.testOptions.testOptionsData._default,
  testOptionsError: state.testOptions.error,
  testOptionsLoading: state.testOptions.isLoading,
  formattingSettings: state.plugin.formattingSettings
})

function TestProducts(props) {
  const dispatch = useDispatch()
  const isInitialCall = useRef(true)

  const {
    filters,
    rootOptions,
    rootOptionsError,
    rootOptionsLoading,
    SelectedOption,
    testOptionsDefault,
    testOptionsError,
    testOptionsLoading,
    formattingSettings
  } = useSelector(selectFromState)

  const { pluginId = '', culture, mode = '', onTestProductsToggle = () => {} } = props

  const { decimal = '0,0.00' } = formattingSettings

  const { AssortmentId = null, ClusterNodeId = null, Filters = [] } = rootOptions
  const {
    AvailableSizeRanges = [],
    AttributeDetails,
    AttributeDetails: { PriceLevel = {}, ColorCode = {} } = {},
    AttributeNames = [],
    Products = []
  } = testOptionsDefault

  const { TestProductFilters: _TestProductFilters = [] } = SelectedOption
  const { DateFormat = null, DecimalFormat, StateDateFormat = null } = settings

  const [colorCodes, setColorCodes] = useState([])
  const [defaultSelectedFilters, setDefaultSelectedFilters] = useState({})
  const [firstAssortmentId, setFirstAssortmentId] = useState(null)
  const [lastAssortmentId, setLastAssortmentId] = useState(null)
  const [priceLevels, setPriceLevels] = useState([])
  const [sizeRanges, setSizeRanges] = useState([])
  const [prices, setPrices] = useState([])
  const [numberOfOptions, setNumberOfOptions] = useState([])
  const [applyValidate, setApplyValidate] = useState(false)
  const [showSelectProductModal, setShowSelectProductModal] = useState(false)
  const [taxRates, setTaxRates] = useState([
    { id: 0.08, name: '8 %' },
    { id: 0.18, name: '18 %' }
  ])
  const [editProductLoading, setEditProductLoading] = useState(false)
  const [mergeOptionLoading, setMergeOptionLoading] = useState(false)
  const [multipleOptionsLoading, setMultipleOptionsLoading] = useState(false)

  // TODO check funcs using states ( send state values as params)
  let {
    Name,
    Description,
    startDate,
    EndDate,
    BasePrice,
    Expenditure,
    SizeRange,
    SizeRangeId,
    SizeRangeTypeId,
    TaxRate,
    NumberOfOptionsId,
    AttributeIds
  } = filters

  startDate = moment(startDate)
  EndDate = moment(EndDate)

  useEffect(() => {
    dispatch(
      setFilters({
        Name: '',
        Description: '',
        startDate: moment().add(7, 'days').format(StateDateFormat), // "2019-09-16T09:11:48",
        EndDate: moment().add(63, 'days').format(StateDateFormat), // "2019-11-11T09:11:48",
        BasePrice: 0,
        Expenditure: 0,
        SizeRange: '',
        SizeRangeId: '60',
        SizeRangeTypeId: '60',
        TaxRate: 0.08,
        NumberOfOptionsId: 1,
        AttributeIds: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      })
    )
    onGetTestOptionData(true)

    if (isCreateMode()) {
      setFirstAssortmentId(AssortmentId)
    }

    const newTaxRates = [...taxRates].map((taxRate) => ({ key: taxRate.id, value: taxRate.name }))
    setTaxRates(newTaxRates)

    const newNumberOfOptions = []
    for (let i = 0; i < 50; i++) {
      newNumberOfOptions.push({ key: i + 1, value: i + 1 })
    }
    setNumberOfOptions(newNumberOfOptions)
    if (isCreateMode()) {
      setNumberOfOptionsField(newNumberOfOptions[0] || [])
    }
  }, [])

  useEffect(() => {
    if (isCreateMode()) {
      setTaxRateField(taxRates[0], filters)
    }
  }, [taxRates])

  useEffect(() => {
    if (isInitialCall.current) {
      isInitialCall.current = false
      return
    }
    if (editProductLoading) {
      if (!rootOptionsLoading) {
        if (!_.isEmpty(rootOptions) && _.isEmpty(rootOptionsError)) {
          slvyToast.success({
            message: 'Edit Test Option completed successfully!',
            title: 'Edit Test Option'
          })
          goBack()
        } else {
          slvyToast.error({ message: 'Edit Test Option failed!', title: 'Edit Test Option' })
        }
        setEditProductLoading(false)
        dispatch(setLoaderReducer({ isShown: false, messages: 'Editing Test Option' }))
      }
    }
    if (mergeOptionLoading) {
      if (!rootOptionsLoading) {
        if (!_.isEmpty(rootOptions) && _.isEmpty(rootOptionsError)) {
          slvyToast.success({
            message: 'Test Product created succesfully!',
            title: 'Create Test Option'
          })
          onTestProductsToggle(false, 'Create')
        } else {
          slvyToast.error({ message: 'Test Product creation failed!', title: 'Create Test Option' })
        }
        setMergeOptionLoading(false)
        dispatch(setLoaderReducer({ isShown: false, messages: 'Creating Test Products' }))
      }
    }
    if (multipleOptionsLoading) {
      if (!rootOptionsLoading) {
        if (!_.isEmpty(rootOptions)) {
          setLastAssortmentId(AssortmentId)
          if (firstAssortmentId !== AssortmentId) {
            mergeTestOption()
          }
        }
        setMultipleOptionsLoading(false)
        dispatch(setLoaderReducer({ isShown: false, messages: 'Creating Multiple Test Option' }))
      }
    }
  }, [rootOptions])

  useEffect(() => {
    if (isInitialCall.current) {
      isInitialCall.current = false
      return
    }
    if (!testOptionsLoading && !_.isEmpty(testOptionsDefault) && _.isEmpty(testOptionsError)) {
      if (!_.isEmpty(AttributeDetails)) {
        if (!_.isEmpty(PriceLevel)) {
          onSetPriceLevels(PriceLevel)
        }
        if (!_.isEmpty(ColorCode)) {
          onSetColorCodes(ColorCode)
        }
      }

      if (!_.isEmpty(AvailableSizeRanges)) {
        onSetSizeRanges(AvailableSizeRanges)
      }

      if (!_.isEmpty(Products)) {
        onSetPrices(Products, filters)
        if (isCreateMode()) {
          onSetFilters(Filters)
        }
      }

      if (!isCreateMode()) {
        setForEditMode(filters)
      }
      dispatch(setLoaderReducer({ isShown: false, messages: 'Loading Test Option' }))
    }
  }, [testOptionsDefault])

  const createMultipleTestOptions = (params) => {
    const payload = {
      pluginId,
      method: 'CreateMultipleTestOptions',
      requestMethod: 'post',
      body: {
        TestProduct: duplicateNtimes(NumberOfOptionsId)
      }
    }

    dispatch(setLoaderReducer({ isShown: true, messages: 'Creating Multiple Test Option' }))
    setMultipleOptionsLoading(true)

    dispatch(getOptions({ ...payload }))
  }

  const duplicateNtimes = (times) => {
    let updatedFilters = {
      ...filters,
      ClusterNodeId,
      SimilarProductId: 0
    }

    const { Name = '', BasePrice = '' } = updatedFilters

    const TestProducts = []
    for (let i = 0; i < times; i++) {
      updatedFilters.BasePrice = numeral(numeral(BasePrice).format(DecimalFormat)).value()

      updatedFilters = _.omit(updatedFilters, 'NumberOfOptionsId')
      if (times > 1) {
        updatedFilters.Name = `${Name} ${i + 1}`
      }
      if (!isCreateMode()) {
        updatedFilters = _.omit(updatedFilters, 'SimilarProductId')
      }
      TestProducts.push(updatedFilters)
    }
    return TestProducts
  }

  const editTestOption = () => {
    // TODO: Add Validation
    const { BasePrice = null, EndDate = null, StartDate = null } = SelectedOption
    const BasePriceFormatted = `${numeral(BasePrice).format(decimal)}`
    const DaysDiff = getDateDiff(StartDate, EndDate, 'days')

    const newSelectedOption = {
      ...SelectedOption,
      BasePriceFormatted,
      DaysDiff
    }

    const payload = {
      pluginId,
      method: 'EditTestOption',
      requestMethod: 'post',
      body: {
        OptionStr: newSelectedOption,
        AssortmentId,
        TestProduct: duplicateNtimes(1)[0]
      }
    }

    dispatch(setLoaderReducer({ isShown: true, messages: 'Editing Test Option' }))
    setEditProductLoading(true)
    dispatch(getOptions({ ...payload }))
  }

  const executeValidate = () => {
    setApplyValidate(true)
  }

  const getRequestParams = (Filters, byFieldId, isFetchFilters) => {
    let requestParams = {
      fetchFilters: isFetchFilters
    }

    const selectedFilters = getSelectedFilters(defaultDependencyFilters, [...Filters])

    selectedFilters.forEach((filter) => {
      const { Key = '', FieldId = '', Value = null } = filter
      // eslint-disable-next-line
      if ((byFieldId && Value === null) || (_.isArray(Value) && Value.length === 0)) {
        // skip
      } else {
        const $key = byFieldId ? FieldId : Key
        const $val =
          byFieldId && FieldId !== 'seasonID' // for GetTestOptionData testOptionsDefault
            ? Value[0]
            : Value
        requestParams = {
          ...requestParams,
          [$key]: $val
        }
      }
    })
    return requestParams
  }

  const goBack = () => {
    onTestProductsToggle(false, 'Edit')
  }

  const isCreateMode = () => {
    return mode === 'Create'
  }

  const mapRootOptionFiltersWithDefault = (rootOptionFilters = {}) => {
    const newDefaultSelectedFilters = {}
    Object.keys(rootOptionFilters).forEach((filterName) => {
      const $isMultiple = getIsMultipleByKey(defaultDependencyFilters, filterName)
      const $defaultItem = rootOptionFilters[filterName]
      newDefaultSelectedFilters[filterName] = {
        Value: $isMultiple
          ? $defaultItem
          : $defaultItem && _.isArray($defaultItem) && $defaultItem.length
          ? $defaultItem[0]
          : null,
        IsDefault: Filters.filter(({ Name = '' }) => Name === filterName).length > 0
      }
    })
    setDefaultSelectedFilters(newDefaultSelectedFilters)
  }

  const mergeTestOption = () => {
    const payload = {
      pluginId,
      method: 'MergeTestOption',
      requestMethod: 'post',
      body: {
        SourceOptionId: lastAssortmentId,
        TargetOptionId: firstAssortmentId
      }
    }
    dispatch(setLoaderReducer({ isShown: true, messages: 'Creating Test Products' }))
    setMergeOptionLoading(true)
    dispatch(getOptions({ ...payload }))
  }

  const onAnyFilterChanged = (dropdownFilters, filters) => {
    // TODO check filters
    const { AttributeIds = [] } = filters

    const clonedDropdownFilters = _.cloneDeep(dropdownFilters)
    const clonedAttributeIds = _.cloneDeep(AttributeIds)
    _.each(clonedDropdownFilters, ({ selected, Index, Key }) => {
      const fieldIndexKey = _.findKey(AttributeDetails[Key], (value) => {
        return value === selected
      })
      clonedAttributeIds[Index] = _.isNil(fieldIndexKey) ? 0 : fieldIndexKey
    })
    dispatch(
      setFilters({
        ...filters,
        AttributeIds: [...clonedAttributeIds]
      })
    )
    setApplyValidate(false)
  }

  const onGetTestOptionData = (isFetchFilters) => {
    const selectedFilters = getRequestParams(Filters, true, isFetchFilters)
    const payload = {
      pluginId,
      dataKey: '_default',
      method: 'GetTestOptionData',
      requestMethod: 'post',
      body: {
        ...selectedFilters
      }
    }

    dispatch(setLoaderReducer({ isShown: true, messages: 'Loading Test Option' }))

    dispatch(getTestOptionData({ ...payload }))
  }

  const onSetColorCodes = (ColorCodesList) => {
    let newColorCodes = []
    Object.keys(ColorCodesList).forEach((item, index) => {
      newColorCodes.push({
        key: index,
        value: ColorCodesList[item]
      })
    })
    newColorCodes = _.sortBy(newColorCodes, 'value')
    setColorCodes(newColorCodes)
  }

  const onSetFilters = (Filters) => {
    let rootOptionFilters = getRequestParams(Filters, false, false)
    rootOptionFilters = _.omit(rootOptionFilters, 'fetchFilters')
    mapRootOptionFiltersWithDefault(rootOptionFilters)
  }

  const onSetPriceLevels = (PriceLevel) => {
    const newPriceLevels = []
    Object.keys(PriceLevel).forEach((item, index) => {
      newPriceLevels.push({
        key: index,
        value: PriceLevel[item]
      })
    })
    setPriceLevels(newPriceLevels)
  }

  const onSetPrices = (Products, filters) => {
    let basePrices = [...Products].map(({ BasePrice = 0 }) => {
      return numeral(BasePrice).format(decimal)
    })
    basePrices = _.uniqBy(basePrices)
    basePrices = _.sortBy(basePrices)
    basePrices.unshift('') // default selection will be '' automatically
    setPrices(mapOptions(basePrices))
    if (isCreateMode()) {
      setPriceField({ key: '' }, filters) // for default selection
    }
  }

  const onSetSizeRanges = (AvailableSizeRanges) => {
    const newAvailableSizeRanges = []
    Object.keys(AvailableSizeRanges).forEach((item) => {
      newAvailableSizeRanges.push({
        key: item,
        value: AvailableSizeRanges[item]
      })
    })
    const sizeRange = _.map(newAvailableSizeRanges, ({ key, value }) => ({ key, value }))
    setSizeRanges(sizeRange)
    if (isCreateMode()) {
      setSizeRangeField(sizeRange[0])
    }
  }

  const onValidateCallback = (isValid, filters) => {
    const { Name = '', BasePrice = '' } = filters

    const hasName = Name !== ''
    const basePriceIsSelected = BasePrice !== '' && BasePrice !== null

    const $isValid = isValid && basePriceIsSelected && hasName
    setApplyValidate($isValid)

    let messages = []
    if (!hasName) {
      messages.push('Name is required! ')
    }
    if (!basePriceIsSelected) {
      messages.push('Price is required! ')
    }
    if (!hasName || !basePriceIsSelected) {
      const element = (
        <>
          <Title>Form Validation</Title>
          {messages.map((item, key) => (
            <React.Fragment key={key}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </>
      )
      slvyToast.error({ component: element, options: { containerId: 'ap-val', autoClose: 5000 } })
      return
    }

    if ($isValid) {
      if (isCreateMode()) {
        createMultipleTestOptions()
      } else {
        editTestOption()
      }
    }
  }

  const setForEditMode = (filters) => {
    if (!_.isEmpty(SelectedOption)) {
      const currentTestProduct = [...Products].filter(
        (product) => product.Id === SelectedOption.Id
      )[0]

      if (!currentTestProduct) {
        slvyToast.error({ message: 'Edited Product can not found!', title: 'Edit Test Product!' })
        dispatch(setLoaderReducer({ isShown: true, messages: 'An error occurred!' }))
        return
      }

      const { AttributeIds: _AttributeIds = [] } = currentTestProduct

      const {
        Name = '',
        Description = '',
        StartDate: startDate = '',
        EndDate = '',
        BasePrice = '',
        Expenditure = '',
        TaxRate = '',
        SizeRangeId: SizeRangeIdKey = '',
        PriceLevelId = '' // AttributeIds'ten dolucak
        // NumberOfOptions = '', // ???
        // ColorCodeId = '' // AttributeIds'ten dolucak
      } = SelectedOption

      dispatch(
        setFilters({
          ...filters,
          Name,
          Description,
          startDate,
          EndDate,
          BasePrice,
          Expenditure,
          TaxRate,
          AttributeIds: _AttributeIds
        })
      )

      const currentSizeRangeItem = [...sizeRanges].filter(
        (sizeRange) => parseFloat(sizeRange.key) === SizeRangeIdKey
      )[0]

      setSizeRangeField(currentSizeRangeItem, filters)

      onSetFilters(_TestProductFilters)

      dispatch(setLoaderReducer({ isShown: false }))
    }
  }

  // filters should be a slice state
  const setNumberOfOptionsField = (item) => {
    const { key = '' } = item
    dispatch(
      setFilters({
        ...filters,
        NumberOfOptionsId: key
      })
    )
  }

  const setPriceField = (item = {}, filters) => {
    const { key = '' } = item
    dispatch(
      setFilters({
        ...filters,
        BasePrice: key // select first as a selected
      })
    )
  }

  const setSizeRangeField = (item = {}, filters) => {
    const { key = '', value = '' } = item
    dispatch(
      setFilters({
        ...filters,
        SizeRange: value,
        SizeRangeId: key,
        SizeRangeTypeId: key
      })
    )
  }

  const setTaxRateField = (item = {}, filters) => {
    const { key = '' } = item
    dispatch(
      setFilters({
        ...filters,
        TaxRate: key
      })
    )
  }

  return (
    <div>
      <div className="wizardHeader" style={{ float: 'none' }}>
        <Button className="-goBack" size="sm" type="button" variant="outline-dark" onClick={goBack}>
          <span className="slvy-ui-icon-times-lt" />
        </Button>
        <div className="pageTitle">{isCreateMode() ? 'Create' : 'Edit'} Test Product</div>
      </div>
      <form className="form-horizontal">
        <div className="row -frame-m50">
          <div className="col-sm-6">
            <TestProductFilters
              colorCodes={colorCodes}
              createMode={isCreateMode()}
              culture={culture}
              defaultSelectedFilters={defaultSelectedFilters}
              mapRootOptionFiltersWithDefault={mapRootOptionFiltersWithDefault}
              numberOfOptions={numberOfOptions}
              pluginId={pluginId}
              priceLevels={priceLevels}
              prices={prices}
              sizeRanges={sizeRanges}
              taxRates={taxRates}
            />
          </div>
          <div className="col-sm-6">
            <AssortmentMainFilters
              isAllRequired
              isTestScreen
              applyValidate={applyValidate}
              defaultSelectedFilters={defaultSelectedFilters}
              isVisibleApplyButton={false}
              pluginId={pluginId}
              onAnyFilterChanged={(e) => {
                onAnyFilterChanged(e, filters)
              }}
              onValidateCallback={(isValid) => {
                onValidateCallback(isValid, filters)
              }}
            />
          </div>
        </div>
      </form>
      <div className="wizardFooter">
        <Button size="sm" type="button" variant="success" onClick={executeValidate}>
          Apply
        </Button>
      </div>
    </div>
  )
}

export default TestProducts
