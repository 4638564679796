export default {
  title: 'Purchase Order',
  subTitle: 'Purchase Order Recommendation',
  version: '1.0',
  namespace: 'PurchaseOrder',
  description: '',
  iconClass: 'fa-circle',
  color: 'yellow-bg',
  support: {
    maximizable: true
  },
  group: 'Application'
}
