export default () => {
  return {
    title: 'Color Palettes',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          title: {
            type: 'string',
            title: 'Title'
          },
          designId: {
            type: 'string',
            title: 'Group Key'
          },
          readOnly: {
            type: 'boolean',
            title: 'Read Only',
            default: false,
            required: true
          },
          columnHeight: {
            type: 'integer',
            title: 'Column Height',
            format: 'range',
            minimum: 20,
            maximum: 100,
            default: 55
          },
          columnHeightValue: {
            type: 'integer',
            title: ' ',
            template: '{{columnHeightValue}}',
            watch: {
              columnHeightValue: 'settings.columnHeight'
            }
          }
        }
      },
      colorGroup: {
        type: 'array',
        title: 'Sections',
        items: {
          type: 'object',
          title: 'Section',
          headerTemplate: '{{ self.section }}',
          properties: {
            section: {
              type: 'string',
              title: 'Section Name'
            }
          }
        }
      },
      patternTypes: {
        type: 'array',
        title: 'Pattern Types',
        items: {
          type: 'object',
          title: 'Pattern Type',
          headerTemplate: '{{ self.name }}',
          properties: {
            name: {
              type: 'string',
              title: 'Pattern Type Name'
            },
            header: {
              type: 'string',
              title: 'Pattern Type Acronym'
            }
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          designId: {
            type: 'string',
            title: 'Design Id',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
