export default {
  title: 'DataTable',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'ReactDatatable',
  description: 'Simple and lightweight data table plugin',
  iconClass: 'fa-table',
  color: 'red-bg',
  group: 'Data Manipulation',
  support: {
    maximizable: true,
    editable: true,
    exportable: true
  }
}
