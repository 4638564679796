import { useState } from 'react'
import { DropdownButton } from 'react-bootstrap'
import { useTheme } from '../../contexts/ThemeContext'
import MenuList from './MenuList'
import { MenuItemDef } from './ContextMenu'

type SubmenuDropdownProps = {
  items: MenuItemDef[]
  toggleTitle: string
  onHide: () => void
}

const SubmenuDropdown = ({ items, toggleTitle, onHide }: SubmenuDropdownProps) => {
  const [showSubmenu, setShowSubmenu] = useState(false)
  const theme = useTheme()

  const handleShowSubmenu = () => setTimeout(() => setShowSubmenu(true), 500)
  const handleHideSubmenu = () => setShowSubmenu(false)

  return (
    <div className="flex-fill">
      <DropdownButton
        bsPrefix={theme.contextMenuDropdown}
        drop="end"
        show={showSubmenu}
        title={toggleTitle}
        variant="success"
        onMouseOut={handleHideSubmenu}
        onMouseOver={handleShowSubmenu}
      >
        {showSubmenu ? <MenuList items={items} onHide={onHide} /> : null}
      </DropdownButton>
    </div>
  )
}

export default SubmenuDropdown
