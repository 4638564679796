import React from 'react'
import { MESSAGES } from '../../messages'

const ScenarioHeader = () => {
  return (
    <div className="sc-header-single">
      <strong className="fw-bold">{MESSAGES.name}</strong>
      <strong className="fw-bold">{MESSAGES.description}</strong>
      <strong className="fw-bold">{MESSAGES.created_by}</strong>
      <strong className="fw-bold scenarioDate">{MESSAGES.revision}</strong>
      <strong className="fw-bold scenarioDate">{MESSAGES.create}</strong>
    </div>
  )
}

export default ScenarioHeader
