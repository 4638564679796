import React, { Component } from 'react'
import moment from 'moment'
import _, { isNumber } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'
import { Col, Form, Button, Row } from 'react-bootstrap'
import Checkbox from './checkbox'
import Text from './text'
import Dropdown from './dropdown'
import Number from './number'
import Textarea from './textArea'
import Datetime from './datetime'
import { checkValidity } from '../utils'

class Comp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validationState: null
    }
    this.components = {
      text: Text,
      dropdown: Dropdown,
      checkbox: Checkbox,
      number: Number,
      textarea: Textarea,
      datetime: Datetime
    }
    this.setValidationState = this.setValidationState.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.checkValidationState(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.component, nextProps.component)) {
      this.checkValidationState(nextProps)
    }
  }

  checkValidationState(props) {
    const { component } = props
    const { required, min, max, type, readonly } = component
    const value = type === 'datetime' ? moment(component.value).valueOf() : component.value
    const isValid = checkValidity({ type, value, required, readonly, min, max })
    const validationState = isValid ? null : 'error'

    this.setValidationState(validationState)
  }

  setValidationState(validationState) {
    this.setState({
      validationState
    })
  }

  getLabelColTpl(label, star, options) {
    const { justifyLabelContent, centerLabel, colSize, isInvalid, withIcon } = options
    return label || star ? (
      <Col
        className={cx({
          [`d-flex align-items-center px-2`]: centerLabel,
          [`justify-content-${justifyLabelContent}`]: centerLabel && justifyLabelContent
        })}
        md={colSize}
        sm={colSize}
      >
        <Form.Label
          className={cx(
            {
              'text-danger': this.props.validationCheckState && isInvalid
            },
            'pe-2'
          )}
          title={label}
        >
          {label}
          {withIcon ? <i className="bs-form-cmp-label-icon"> {star} </i> : star}
        </Form.Label>
      </Col>
    ) : null
  }

  render() {
    const {
      pluginId,
      component,
      actualFilters,
      settingsComponents,
      changeMustBeUpdated,
      updateFieldLookUpData,
      getLookupDataEditableField,
      prepareRecorDatawithChanges,
      readonly = false,
      compact = false,
      inputHeight = 25,
      inputFontSize = 11,
      validationCheckState = false,
      boundedComp = {},
      clientWithProgress
    } = this.props

    // Make sure before changing boundedComp
    const _boundedComp = boundedComp || undefined

    const { validationState } = this.state
    const SpecificComp = this.components[component.element.type]
    const label = _.isNil(component.label) ? '' : component.label
    const star = component.required ? ' *' : ''
    const isShownLabelCol = !_.isNil(label) || star

    const isInvalid = validationCheckState && validationState === 'error'
    const controlId = uuidv4()

    const colSize = isNumber(component.labelSize) ? component.labelSize : 3
    const remainingColSize = isNumber(component.labelSize) ? 12 - colSize : 9
    const justifyContent = component?.justifyContent

    return (
      <Col md={component.colSize} sm={component.colSize}>
        {component.labelPlacement === 'top' ? (
          <Form.Group as={Row} className="clearfix form-group" controlId={controlId}>
            {isShownLabelCol
              ? this.getLabelColTpl(label, star, {
                  colSize: 12,
                  withIcon: false,
                  isInvalid,
                  justifyLabelContent: component.justifyLabelContent
                })
              : null}
            {component.icon ? (
              <>
                <Col
                  className={cx({ [`justify-content-${justifyContent}`]: justifyContent })}
                  md={9}
                  sm={9}
                >
                  <SpecificComp
                    actualFilters={actualFilters}
                    boundedComp={_boundedComp}
                    changeMustBeUpdated={changeMustBeUpdated}
                    clientWithProgress={clientWithProgress}
                    compact={compact}
                    component={component}
                    controlId={controlId}
                    getLookupDataEditableField={getLookupDataEditableField}
                    inputFontSize={inputFontSize}
                    inputHeight={inputHeight}
                    isInvalid={isInvalid}
                    loadLookUpdataReadOnly={this.props.loadLookUpdataReadOnly}
                    pluginId={pluginId}
                    prepareRecorDatawithChanges={prepareRecorDatawithChanges}
                    readonly={readonly}
                    setRequiredState={this.props.setRequiredState}
                    setValidationState={this.setValidationState}
                    settingsComponents={settingsComponents}
                    updateFieldLookUpData={updateFieldLookUpData}
                    validationCheckState={validationCheckState}
                    onChange={this.props.onChange}
                  />
                </Col>
                <Col md={3} sm={3}>
                  <div className="d-grid">
                    <Button
                      className="btn-block"
                      size="sm"
                      style={{ height: `${compact ? `${inputHeight}px` : 'auto'}` }}
                      variant="success"
                      onClick={() => this.props.handleDataByIconClick(component.fieldName)}
                    >
                      <i className={`${component.icon} center-block`} />
                    </Button>
                  </div>
                </Col>
              </>
            ) : (
              <Col
                className={cx({ [`justify-content-${justifyContent}`]: justifyContent })}
                md={12}
                sm={12}
              >
                <SpecificComp
                  actualFilters={actualFilters}
                  boundedComp={_boundedComp}
                  changeMustBeUpdated={changeMustBeUpdated}
                  clientWithProgress={clientWithProgress}
                  compact={compact}
                  component={component}
                  controlId={controlId}
                  getFormatedValue={this.props.getFormatedValue}
                  getLookupDataEditableField={getLookupDataEditableField}
                  getUnformattedValue={this.props.getUnformattedValue}
                  getValueWithDateFormat={this.props.getValueWithDateFormat}
                  inputFontSize={inputFontSize}
                  inputHeight={inputHeight}
                  isInvalid={isInvalid}
                  loadLookUpdataReadOnly={this.props.loadLookUpdataReadOnly}
                  pluginId={pluginId}
                  prepareRecorDatawithChanges={prepareRecorDatawithChanges}
                  readonly={readonly}
                  setRequiredState={this.props.setRequiredState}
                  setValidationState={this.setValidationState}
                  settingsComponents={settingsComponents}
                  updateFieldLookUpData={updateFieldLookUpData}
                  validationCheckState={validationCheckState}
                  onChange={this.props.onChange}
                />
              </Col>
            )}
          </Form.Group>
        ) : null}
        {component.labelPlacement === 'left' ? (
          <>
            {component.icon ? (
              <Form.Group
                as={Row}
                className="clearfix form-group form-horizontal"
                controlId={controlId}
              >
                {isShownLabelCol
                  ? this.getLabelColTpl(label, star, {
                      colSize,
                      withIcon: true,
                      isInvalid,
                      centerLabel: true,
                      justifyLabelContent: component.justifyLabelContent
                    })
                  : null}
                <Col
                  md={isShownLabelCol ? remainingColSize : 9}
                  sm={isShownLabelCol ? remainingColSize : 9}
                >
                  {' '}
                  <SpecificComp
                    actualFilters={actualFilters}
                    boundedComp={_boundedComp}
                    changeMustBeUpdated={changeMustBeUpdated}
                    clientWithProgress={clientWithProgress}
                    compact={compact}
                    component={component}
                    controlId={controlId}
                    getFormatedValue={this.props.getFormatedValue}
                    getLookupDataEditableField={getLookupDataEditableField}
                    getUnformattedValue={this.props.getUnformattedValue}
                    getValueWithDateFormat={this.props.getValueWithDateFormat}
                    inputFontSize={inputFontSize}
                    inputHeight={inputHeight}
                    isInvalid={isInvalid}
                    loadLookUpdataReadOnly={this.props.loadLookUpdataReadOnly}
                    pluginId={pluginId}
                    prepareRecorDatawithChanges={prepareRecorDatawithChanges}
                    readonly={readonly}
                    setRequiredState={this.props.setRequiredState}
                    setValidationState={this.setValidationState}
                    settingsComponents={settingsComponents}
                    updateFieldLookUpData={updateFieldLookUpData}
                    validationCheckState={validationCheckState}
                    onChange={this.props.onChange}
                  />
                </Col>
                <Col md={3} sm={3}>
                  <div className="d-grid">
                    <Button
                      className="btn-block"
                      size="sm"
                      variant="success"
                      onClick={this.props.handleDataByIconClick}
                    >
                      <i className={`${component.icon} center-block`} />
                    </Button>
                  </div>
                </Col>
              </Form.Group>
            ) : (
              <Form.Group
                as={Row}
                className="clearfix form-group form-horizontal"
                controlId={controlId}
              >
                {isShownLabelCol
                  ? this.getLabelColTpl(label, star, {
                      colSize,
                      withIcon: true,
                      isInvalid,
                      centerLabel: true,
                      justifyLabelContent: component.justifyLabelContent
                    })
                  : null}

                <Col
                  md={isShownLabelCol ? remainingColSize : 12}
                  sm={isShownLabelCol ? remainingColSize : 12}
                >
                  <SpecificComp
                    actualFilters={actualFilters}
                    boundedComp={_boundedComp}
                    changeMustBeUpdated={changeMustBeUpdated}
                    clientWithProgress={clientWithProgress}
                    compact={compact}
                    component={component}
                    controlId={controlId}
                    getFormatedValue={this.props.getFormatedValue}
                    getLookupDataEditableField={getLookupDataEditableField}
                    getUnformattedValue={this.props.getUnformattedValue}
                    getValueWithDateFormat={this.props.getValueWithDateFormat}
                    inputFontSize={inputFontSize}
                    inputHeight={inputHeight}
                    isInvalid={isInvalid}
                    loadLookUpdataReadOnly={this.props.loadLookUpdataReadOnly}
                    pluginId={pluginId}
                    prepareRecorDatawithChanges={prepareRecorDatawithChanges}
                    readonly={readonly}
                    setRequiredState={this.props.setRequiredState}
                    setValidationState={this.setValidationState}
                    settingsComponents={settingsComponents}
                    updateFieldLookUpData={updateFieldLookUpData}
                    validationCheckState={validationCheckState}
                    onChange={this.props.onChange}
                  />
                </Col>
              </Form.Group>
            )}
          </>
        ) : null}
      </Col>
    )
  }
}

export default Comp
