Ext.define('Assortment.cmp.StoreGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'store-grid',
  columnLines: true,
  rowLines: true,
  enableLocking: true,
  viewConfig: {
    enableTextSelection: true
  },
  plugins: [
    {
      ptype: 'cellediting',
      id: 'cellEditing',
      clickstoedit: 1
    },
    {
      ptype: 'gridfilters',
      id: 'gridFilters'
    }
  ],
  columns: [
    {
      dataIndex: 'IsActive',
      text: 'Is Active',
      headerCheckbox: true,
      xtype: 'checkcolumn',
      bind: {
        disabled: '{!IsFutureOption}'
      },
      stopSelection: false
    },
    {
      text: 'Name',
      dataIndex: 'Name',
      flex: 1
    },
    {
      text: 'Country',
      dataIndex: 'Country',
      flex: 1,
      filter: {
        type: 'string'
      }
    },
    {
      text: 'Capacity',
      dataIndex: 'Capacity',
      flex: 1,
      renderer: function (value, meta, record) {
        return (
          Ext.util.Format.number(record.get('ConsumedOptionCapacity'), '0,000') +
          '/' +
          Ext.util.Format.number(record.get('TotalOptionCapacity'), '0,000')
        )
      }
    },
    {
      text: 'Capacity Percentage',
      xtype: 'widgetcolumn',
      flex: 1,
      dataIndex: 'CapacityPercent',
      widget: {
        xtype: 'progressbarwidget',
        textTpl: ['{percent:number("0")}%']
      }
    },
    {
      text: 'Performance',
      dataIndex: 'performance',
      flex: 1
    }
  ]
})
