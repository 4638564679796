/* eslint-disable jest/no-export */
import { TfieldConfigs } from '../../../../types'
import { TAG_FILTER_KEY } from '../../../../constants'

export const fieldConfig: TfieldConfigs[number] = {
  fieldName: 'col1',
  dataType: 'int'
}

export const rawFilterModel = {
  col3: {
    filterType: 'text',
    type: TAG_FILTER_KEY,
    filter: '1'
  },
  col1: {
    filterType: 'number',
    type: 'equals',
    filter: 14
  }
}

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
