import { useEffect, useRef, useState, MouseEvent } from 'react'
import cx from 'classnames'
import { useEvent } from '@/hooks'
import { ResizerProps } from './Resizer.types'
import styles from './Resizer.module.scss'

export default function Resizer({
  isResizing,
  onResizeStart,
  onResizeEnd,
  onResetSize
}: ResizerProps) {
  // const [isResizing, setIsResizing] = useState(false)
  const [deltaOffset, setPosition] = useState<number | null>(null)
  const resizerRef = useRef(null)
  const initialPosition = useRef(null)

  const handleResizeStart = useEvent((event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()

    onResizeStart(event)
  })

  const handleResizeEnd = useEvent(() => {
    onResizeEnd(deltaOffset)
  })

  const handleMouseMove = useEvent((event: MouseEvent<HTMLSpanElement>) => {
    if (!isResizing) return

    setPosition(event.clientX - initialPosition.current)
  })

  const handleResetSize = () => {
    // onResetSize()
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleResizeEnd)
    initialPosition.current = resizerRef.current?.getBoundingClientRect().left

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleResizeEnd)
    }
  }, [])

  const classNames = cx(styles.resizer, { [styles.isResizing]: isResizing })
  const style = {
    transform: `translateX(${deltaOffset}px)`
  }

  return (
    <span
      ref={resizerRef}
      aria-hidden="true"
      className={classNames}
      style={style}
      onDoubleClick={handleResetSize}
      onMouseDown={handleResizeStart}
    />
  )
}
