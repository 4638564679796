var mv = null
Ext.define('MDM.Btl.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.btl-maincontroller',
  win: [],
  /**
   * Called when the view is created
   */
  mixins: ['MDM.Btl.cmp.HeaderBarMixin', 'MDM.Btl.edit.EditMixins'],
  init: function () {
    Ext.Ajax.setTimeout(360000)
    var me = this

    var vm = this.getViewModel()

    var owner = vm.get('owner')

    var isReadOnly = MDM.Global.getPermission(owner)

    var isGlobal = MDM.Global.isGlobal()

    var region = MDM.Global.getRegion()
    vm.set('isReadOnly', isReadOnly)
    vm.set('readOnly', isReadOnly)
    vm.set('isGlobal', isGlobal)
    vm.set('userMegaRegion', region)
    me.getView().setLoading('Loading data please wait....')
    Ext.defer(function () {
      Ext.Ajax.request({
        method: 'POST',
        url: MDM.Global.getApiUrl() + 'gillettev3/getPermission',
        success: function (response, opts) {
          var data = Ext.JSON.decode(response.responseText)
          data = data ? data : response.responseText
          var view = me.getView()
          if (!view) return
          me.getViewModel().set('MegaRegionID', data.MegaRegionID)
          me.getView().setLoading(false)
        },
        failure: function (response, opts) {
          var icon = Ext.MessageBox['WARNING']
          Ext.MessageBox.show({
            title: 'Warning',
            msg: "You dont have you don't have permission to access this page",
            buttons: Ext.MessageBox.OK,
            icon: icon,
            maskClickAction: 'hide'
          })
          me.getView().disable(true)
        }
      })
    }, 5)
  },
  onDPITreeStoreLoad: function () {
    var me = this
    var c = me.getViewModel().get('dpidefaulValue')
    me.lookup('dhcombo').setDefaultValue(c)
    if (c) {
      Ext.defer(function () {
        me.loadData()
      }, 1200)
    }
  },
  onBeforesyncEditStoreManuel: function (o) {
    var me = this

    var view = me.lookup('manuelEdit')
    if (o.hasOwnProperty('update')) {
      view.setLoading('Saving ')
    }
  },
  onWriteEditStoreManuel: function () {
    var me = this

    var view = me.lookup('manuelEdit')
    view.setLoading(false)
  },
  onBeforesyncImportStore: function (o) {
    var me = this

    var view = me.lookup('importEdit')
    if (o.hasOwnProperty('update')) {
      view.setLoading('Saving ')
    }
  },
  onWriteImportStore: function (o) {
    var me = this

    var view = me.lookup('importEdit')
    view.setLoading(false)
  },
  onBeforesyncLastPeriodStore: function (o) {
    var me = this

    var view = me.lookup('lastPeriodEdit')
    if (o.hasOwnProperty('update')) {
      view.setLoading('Saving ')
    }
  },
  onWriteLastPeriodStore: function () {
    var me = this

    var view = me.lookup('lastPeriodEdit')
    view.setLoading(false)
  },
  onBeforesyncRuleBasedStore: function (o) {
    var me = this

    var view = me.lookup('ruleBasedEdit')
    if (o.hasOwnProperty('update')) {
      view.setLoading('Saving ')
    }
  },
  onWriteRuleBasedStore: function () {
    var me = this

    var view = me.lookup('ruleBasedEdit')
    view.setLoading(false)
  },
  onBeforesyncOtherStore: function (o) {
    var me = this

    var view = me.lookup('otherEdit')
    if (o.hasOwnProperty('update')) {
      view.setLoading('Saving ')
    }
  },
  onWriteOtherStore: function () {
    var me = this

    var view = me.lookup('otherEdit')
    view.setLoading(false)
  },
  loadData: function () {
    var me = this

    var mainStore = me.getViewModel().getStore('mainStore')

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var edittabpanel = me.lookup('edittabpanel')

    var discrepancy = me.lookup('discrepancy')
    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')
    var mainGrid = me.lookup('centergrid')
    var loadcount = me.getViewModel().get('loadcount')
    var importEdit = me.lookup('importEdit')
    var manuelEdit = me.lookup('manuelEdit')
    var lastPeriodEdit = me.lookup('lastPeriodEdit')
    var ruleBasedEdit = me.lookup('ruleBasedEdit')
    var otherEdit = me.lookup('otherEdit')
    var calculatedEdit = me.lookup('calculatedEdit')
    var discrepancyGrid = me.lookup('discrepancy')
    var MegaRegionID = me.getViewModel().get('MegaRegionID')
    var RegionID = me.getViewModel().get('RegionID')
    var constarinView = me.getViewModel().get('constarinView')
    me.getView().setLoading('Loading data please wait')
    if (DPIHierarcy.get('DPIFamilyID') && RegionID) {
      edittabpanel.setVisible(true)
      discrepancy.setDisabled(false)
    } else {
      edittabpanel.setVisible(false)
      edittabpanel.setActiveItem(0)
      discrepancy.setDisabled(true)
    }
    Ext.Ajax.request({
      method: 'POST',
      timeout: 360000,
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_data',
      params: {
        DPISegmentID: DPIHierarcy.get('DPISegmentID'),
        ProductGroupID: DPIHierarcy.get('ProductGroupID'),
        DPIFamilyID: DPIHierarcy.get('DPIFamilyID'),
        ReportingPeriodID: ReportingPeriodID,
        MegaRegionID: MegaRegionID,
        RegionID: RegionID,
        constarinView: constarinView
      },
      success: function (response) {
        var loadcount = loadcount + 1
        me.getViewModel().set('loadcount', Ext.id())
        var data = Ext.JSON.decode(response.responseText)
        data = data ? data : response.responseText
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        if (data && data.MainGrid) {
          MDM.Btl.Column.setStartCol(data.startCol)
          me.getViewModel().set('startCol', data.startCol)
          mainGrid.setCfgData(data.MainGrid)
          manuelEdit.setCfgData(data.Edit.Manuel)
          importEdit.setCfgData(data.Edit.Import)
          lastPeriodEdit.setCfgData(data.Edit.LastPeriod)
          ruleBasedEdit.setCfgData(data.Edit.RuleBased)
          otherEdit.setCfgData(data.Edit.Other)
          calculatedEdit.setCfgData(data.Edit.Calculated)
          discrepancyGrid.setCfgData(data.DiscrepancyGrid)
          if (data.MainGrid.FiscealYears) {
            MDM.Btl.Column.setColumnTitles(data.MainGrid.FiscealYears)
          }
        }
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        MDM.src.ux.OnError.ajaxFailure(response, opts)
      }
    })
  },
  addToEditPanelDisc: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var view = this.getView()

    var store = me.getViewModel().getStore('editStoreManuel')

    var newrec = record.copy()
    var isReadOnly = !!(this.getViewModel().get('readOnly') || record.get('IsApplied'))
    var data = newrec.data
    delete data['id']
    delete data['ID']
    data = Ext.JSON.decode(Ext.JSON.encode(data))
    data.recType = 'Manuel'
    var rec = Ext.create('MDM.Btl.model.EditModel', data)
    for (var index = 1; index < 37; index++) {
      var key = index < 10 ? 'c0' + index : 'c' + index
      rec.set(key, -1 * record.get(key))
    }
    view.setLoading('Creating BTL please wait....!')
    rec.save({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        if (view) view.setLoading(false)
        store.add(rec)
      },
      failure: function () {
        view.setLoading(false)
        console.log('server side error')
      }
    })
  },
  addToEditPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var view = this.getView()

    var store = me.getViewModel().getStore('editStoreManuel')

    var newrec = record.copy()
    var isReadOnly = !!(this.getViewModel().get('readOnly') || record.get('IsApplied'))
    var win = Ext.create('MDM.Btl.dialog.EditForm', {
      record: newrec,
      readOnly: isReadOnly
    }).show()
    win.on('save', function () {
      var data = newrec.data
      delete data['id']
      data.recType = 'Manuel'
      var rec = Ext.create('MDM.Btl.model.EditModel', data)
      for (var index = 1; index < 37; index++) {
        var key = index < 10 ? 'c0' + index : 'c' + index
        rec.set(key, 0)
      }
      view.setLoading('Creating BTL please wait....!')
      rec.save({
        success: function () {
          var t = Ext.toast({
            html: 'Operation successfully completed',
            title: 'Information',
            width: 300,
            align: 'tr',
            iconCls: 'fa fa-check',
            closable: true
          })
          if (view) view.setLoading(false)
          store.add(rec)
        },
        failure: function () {
          view.setLoading(false)
          console.log('server side error')
        }
      })
      newrec.reject()
    })
  },
  showEditPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var store = me.getViewModel().getStore('editStoreManuel')
    store.suspendAutoSync()
    const isReadOnly = !!(this.getViewModel().get('readOnly') || record.get('IsApplied'))
    var win = Ext.create('MDM.Btl.dialog.EditForm', {
      record: record,
      readOnly: isReadOnly
    }).show()
    win.on('save', function () {
      store.resumeAutoSync()
      store.sync()
    })
    win.on('close', function () {
      store.resumeAutoSync()
    })
  },
  showRuleBasedInfoPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var store = me.getViewModel().getStore('editStoreManuel')
    store.suspendAutoSync()
    var isReadOnly = !!(this.getViewModel().get('readOnly') || record.get('IsApplied'))
    var win = Ext.create('MDM.Btl.dialog.RuleBasedInfo', {
      record: record,
      readOnly: isReadOnly
    }).show()
    win.on('save', function () {
      store.resumeAutoSync()
      store.sync()
    })
    win.on('close', function () {
      store.resumeAutoSync()
    })
  },
  showBtlRuleInfo: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var store = me.getViewModel().getStore('btlRuleDetailsStore')
    store.suspendAutoSync()
    var isReadOnly = !!(this.getViewModel().get('readOnly') || record.get('IsApplied'))
    var win = Ext.create('MDM.Btl.dialog.BtlRulesInfo', {
      record: record
    }).show()
    win.on('save', function () {
      store.resumeAutoSync()
      store.sync()
    })
    win.on('close', function () {
      store.resumeAutoSync()
    })
  },
  onRuleBasedGridCellClick: function (view, td, cellIndex, record, tr, rowIndex, e) {
    var me = this,
      viewModel = me.getViewModel()
    var clickedDataIndex = view.panel.headerCt.getHeaderAtIndex(cellIndex).dataIndex

    if (clickedDataIndex == 'NoDependent' && record.get('NoDependent') > 0) {
      me.openDependWindow(record, viewModel, me)
    }
  },
  openDependWindow: function (record) {
    var me = this
    if (!me.dependentStore) {
      me.dependentStore = Ext.create('Ext.data.Store', {
        model: 'MDM.Btl.model.EditModel',
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: MDM.Global.getApiUrl() + 'gillettev3/RuleBasedDependentRead'
        },
        autoLoad: false
      })
    }

    if (!me.DependWindow) {
      me.DependWindow = Ext.create('Ext.window.Window', {
        modal: true,
        closeAction: 'hide',
        resizable: false,
        height: '60%',
        width: '70%',
        layout: 'fit',
        items: [
          {
            xtype: 'btl-edit-grid-RuleBasedDepent',
            border: true,
            columnLines: true,
            store: me.dependentStore,
            margin: 2
          }
        ]
      })
      me.getView().add(me.DependWindow)
      me.DependWindow.show()
    } else {
      me.DependWindow.show()
    }

    var BTLType = record.get('BTLType'),
      BTLTypeTitles = {
        P: 'Production',
        D: 'Demand',
        I: 'Inventory',
        X: 'Cross Supply',
        R: 'Razor Draw',
        N: 'Non-revenue'
      },
      title = BTLTypeTitles[BTLType] + ' - ' + record.get('Title')
    me.DependWindow.setTitle((title = BTLTypeTitles[BTLType] + ' - ' + record.get('Title')))
    var proxy = me.dependentStore.getProxy()
    proxy.setExtraParam('id', record.data.RuleID)
    me.dependentStore.load()
  },
  onUpLoadExcel: function () {
    var me = this

    var importStore = this.getViewModel().getStore('importStore')

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')

    var mainGrid = me.lookup('centergrid')

    var MegaRegionID = me.getViewModel().get('MegaRegionID')

    var RegionID = me.getViewModel().get('RegionID')

    var filterParams = {
      DPIFamilyID: DPIHierarcy.get('DPIFamilyID'),
      ReportingPeriodID: ReportingPeriodID,
      RegionID: RegionID
    }
    var win = Ext.create('MDM.Btl.upload.Import', {
      filterParams: filterParams
    }).show()
    win.on('applyxls', function (records) {
      importStore.suspendAutoSync()
      records.forEach(function (record) {
        var newrec = record.copy()
        var data = newrec.data
        var addRec = Ext.create('MDM.Btl.model.ImportModel', data)
        importStore.add(addRec)
      }, this)
      importStore.resumeAutoSync()
      importStore.sync()
    })
  },
  onTabchange: function (tabPanel, newCard) {
    this.getViewModel().set('actab', newCard)
  },
  onAfterLayout: function (a, b) {
    this.getViewModel().set('actab', b.activeItem)
  },
  onMassApply: function () {
    var me = this
    var view = me.getView()
    var records = me.getViewModel().get('actab').getSelection()
    var parentId = []
    records.forEach(function (record) {
      if (!record.get('IsApplied')) {
        parentId.push(record.get('ID'))
      }
    }, this)
    var parentIds = parentId.join()
    view.setLoading('Applying changes please wait')
    Ext.Ajax.request({
      method: 'POST',
      timeout: 360000,
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_Apply',
      params: {
        parentId: parentIds
      },
      success: function (response, opts) {
        var res = Ext.decode(response.responseText, true)
        res = res ? res : response.responseText
        if (!res || res.length < 0) {
          Ext.MessageBox.alert('Error', 'Server Error')
        } else if (res[0].Success === '1') {
          view.setLoading(false)
          me.loadData()
        } else {
          me.getView().setLoading(false)
          Ext.MessageBox.alert('Error', res[0].ErrorMessage)
        }
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        Ext.MessageBox.alert('Error', 'Server Error')
      }
    })
  },
  onEditDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var view = me.getView()
    var grid = me.getViewModel().get('actab')
    var records = me.getViewModel().get('actab').getSelection()
    records.push(record)
    grid.getSelectionModel().select(records)
    var records = me.getViewModel().get('actab').getSelection()

    var parentId = []
    records.forEach(function (record) {
      if (!record.get('IsApplied')) {
        parentId.push(record.get('ID'))
      }
    }, this)

    let cofrec = records.length

    var store = grid.store
    Ext.Msg.show({
      title: 'Deleting BTL Edit ',
      width: 500,
      msg: '<br> You are about to delete ' + cofrec + ' record(s). Continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doRemoveRec(records, store)
        }
      }
    })
  },
  onRuleBasedTabDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var view = me.getView()
    var grid = me.getViewModel().get('actab')
    var records = me.getViewModel().get('actab').getSelection()
    records.push(record)
    grid.getSelectionModel().select(records)
    var selectedRecords = me.getViewModel().get('actab').getSelection()

    var selectedRecordsForDelete = []
    selectedRecords.forEach(function (record) {
      if (record.get('IsApplied')) selectedRecordsForDelete.push(record)
    }, this)

    let cofrec = selectedRecordsForDelete.length

    var store = grid.store
    Ext.Msg.show({
      title: 'Deleting BTL Edit ',
      width: 500,
      msg: '<br> You are about to delete ' + cofrec + ' applied record(s). Continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doRemoveRec(selectedRecordsForDelete, store)
        }
      }
    })
  },
  onRuleBasedTabApply: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var view = me.getView()
    var grid = me.getViewModel().get('actab')
    var records = me.getViewModel().get('actab').getSelection()
    records.push(record)
    grid.getSelectionModel().select(records)
    records = me.getViewModel().get('actab').getSelection()

    var parentIds = []
    var errorValue = []
    records.forEach(function (record) {
      if (record.get('Resource')) {
        if (record.get('BTLType') == 'P' && !record.get('Reason')) {
          errorValue.push(record.get('Title') + ' <b> Reason </b>')
        }
        if (record.get('BTLType') == 'X' && !record.get('Reason')) {
          errorValue.push(record.get('Title') + ' <b>Reason</b>')
        }
        if (record.get('BTLType') == 'X' && !record.get('FromRegionID')) {
          errorValue.push(record.get('Title') + ' <b>From Region</b>')
        }
      }
      if (!record.get('IsApplied')) {
        parentIds.push(record.get('ID'))
      }
    }, this)
    if (errorValue.length > 0) {
      Ext.MessageBox.alert(
        'Validation',
        '<b>Please fill in the following mandotary fields.</b> <br><br><br>' +
          errorValue.join('<br>')
      )
      return
    }

    Ext.Msg.show({
      title: 'Deleting BTLs',
      // height: 160,
      width: 500,
      msg: '<br> You are about to Apply ' + parentIds.length + ' record(s). Continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          view.setLoading('Applying changes please wait')
          Ext.Ajax.request({
            method: 'POST',
            timeout: 360000,
            url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_Apply',
            params: {
              parentId: parentIds.join()
            },
            success: function (response, opts) {
              var res = Ext.decode(response.responseText, true)
              res = res ? res : response.responseText
              if (!res || res.length < 0) {
                Ext.MessageBox.alert('Error', 'Server Error')
              } else if (res[0].Success === '1') {
                view.setLoading(false)
                me.loadData()
              } else {
                me.getView().setLoading(false)
                Ext.MessageBox.alert('Error', res[0].ErrorMessage)
              }
            },
            failure: function (response, opts) {
              if (me.getView() && me.getView().setLoading) {
                me.getView().setLoading(false)
              }
              MDM.src.ux.OnError.ajaxFailure(response, opts)
            }
          })
        }
      }
    })
  },
  doRemoveRec: function (records, store) {
    var me = this
    var view = me.getView()
    view.setLoading('Deleting BTL... Please wait')
    store.suspendAutoSync()
    store.remove(records)
    store.sync({
      success: function () {
        me.getView().setLoading(false)
        me.loadData()
      },
      failure: function (response, opts) {
        me.getView().setLoading(false)
        store.rejectChanges()
      }
    })
    store.resumeAutoSync()
  },
  onEditPreCal: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var view = me.getView()
    grid = me.getViewModel().get('actab')
    var mainGrid = me.lookup('centergrid')
    var discrepancy = me.lookup('discrepancy')
    var store = grid.store
    var records = me.getViewModel().get('actab').getSelection()
    records.push(record)
    grid.getSelectionModel().select(records)
    records = me.getViewModel().get('actab').getSelection()

    grid = me.getViewModel().get('actab')

    store = grid.store

    var parentId = []

    var errorValue = []
    records.forEach(function (record) {
      if (record.get('Resource')) {
        if (record.get('BTLType') == 'X' && !record.get('FromRegionID')) {
          errorValue.push(record.get('Title') + ' <b>From Region</b>')
        }
      }
      if (!record.get('IsApplied')) {
        parentId.push(record.get('ID'))
      }
    }, this)
    if (errorValue.length > 0) {
      Ext.MessageBox.alert(
        'Validation',
        '<b>Please fill in the following mandotary fields.</b> <br><br><br>' +
          errorValue.join('<br>')
      )
      return
    }
    var parentIds = parentId.join()
    view.setLoading('Simulating BTL... Please wait')
    Ext.Ajax.request({
      method: 'POST',
      timeout: 360000,
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_Apply2',
      params: {
        parentId: parentIds
      },
      success: function (response, opts) {
        var res = Ext.decode(response.responseText, true)
        res = res ? res : response.responseText
        me.getView().setLoading(false)
        store.suspendAutoSync()
        if (res.success) {
          store.each(function (record) {
            record.set('IsSimulated', false)
            record.commit()
          })
          records.forEach(function (record) {
            record.set('IsSimulated', true)
            record.commit()
          }, this)
          store.resumeAutoSync()
          mainGrid.store.loadData(res.data)
          discrepancy.store.loadData(res.discrepancyData)
        } else {
          Ext.MessageBox.alert('Error', res[0].ErrorMessage)
        }
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
          record.set('', true)
        }
        MDM.src.ux.OnError.ajaxFailure(response, opts)
      }
    })
  },
  onApply: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var view = me.getView()
    grid = me.getViewModel().get('actab')
    var records = me.getViewModel().get('actab').getSelection()
    records.push(record)
    grid.getSelectionModel().select(records)
    records = me.getViewModel().get('actab').getSelection()

    var parentId = []

    var errorValue = []
    records.forEach(function (record) {
      if (record.get('Resource')) {
        if (record.get('BTLType') == 'P' && !record.get('Reason')) {
          errorValue.push(record.get('Title') + ' <b> Reason </b>')
        }
        if (record.get('BTLType') == 'X' && !record.get('Reason')) {
          errorValue.push(record.get('Title') + ' <b>Reason</b>')
        }
        if (record.get('BTLType') == 'X' && !record.get('FromRegionID')) {
          errorValue.push(record.get('Title') + ' <b>From Region</b>')
        }
      }
      if (!record.get('IsApplied')) {
        parentId.push(record.get('ID'))
      }
    }, this)
    if (errorValue.length > 0) {
      Ext.MessageBox.alert(
        'Validation',
        '<b>Please fill in the following mandotary fields.</b> <br><br><br>' +
          errorValue.join('<br>')
      )
      return
    }
    var parentIds = parentId.join()
    view.setLoading('Applying changes please wait')
    Ext.Ajax.request({
      method: 'POST',
      timeout: 360000,
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_Apply',
      params: {
        parentId: parentIds
      },
      success: function (response, opts) {
        var res = Ext.decode(response.responseText, true)
        res = res ? res : response.responseText
        if (!res || res.length < 0) {
          Ext.MessageBox.alert('Error', 'Server Error')
        } else if (res[0].Success === '1') {
          view.setLoading(false)
          me.loadData()
        } else {
          me.getView().setLoading(false)
          Ext.MessageBox.alert('Error', res[0].ErrorMessage)
        }
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        MDM.src.ux.OnError.ajaxFailure(response, opts)
      }
    })
  },
  lockToast: function () {
    var t = Ext.toast({
      html: 'This record is not editable.',
      title: 'Warning',
      width: 300,
      align: 'tr',
      iconCls: 'fa fa-check',
      closable: true
    })
  },
  onBeforeEdit: function (editor, context) {
    var me = this

    var field = context.field

    var record = context.record

    var editField = context.column.field

    var IsApplied = record.get('IsApplied')

    var isReadOnly = me.getViewModel().get('isReadOnly')
    if (isReadOnly) return false
    var dataindex = editField ? editField.dataIndex : context.column.dataIndex
    var index = parseInt(dataindex.replace('c', ''))
    var BTLType = record.get('BTLType')
    var startCol = me.getViewModel().get('startCol')
    if (IsApplied) {
      this.lockToast()
      return false
    }
    if (BTLType == 'X' || BTLType == 'D' || BTLType == 'P' || BTLType == 'N' || BTLType == 'R') {
      return true
    }
    var v = parseInt(startCol) + 1
    if (BTLType == 'I' && index <= v) {
      return true
    } else {
      this.lockToast()
      return false
    }
  },
  onSelectionChange: function () {
    this.getViewModel().set('onSelectionChange', Ext.id())
  },
  onAfterRender: function () {
    var me = this

    var mainGrid = me.lookup('centergrid')

    var importEdit = me.lookup('importEdit')

    var manuelEdit = me.lookup('manuelEdit')

    var lastPeriodEdit = me.lookup('lastPeriodEdit')

    var ruleBasedEdit = me.lookup('ruleBasedEdit')

    var otherEdit = me.lookup('otherEdit')

    var discrepancy = me.lookup('discrepancy')
    var calculatedEdit = me.lookup('calculatedEdit')
    var mv = this.getView()
    mainGrid.normalGrid.view.getEl().on('scroll', function (e, t) {
      if (me.getViewModel().get('syncSc') === 'True') {
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        importEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        lastPeriodEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        ruleBasedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        otherEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        discrepancy.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        calculatedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
    manuelEdit.normalGrid.view.getEl().on('scroll', function (e, t) {
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        importEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        lastPeriodEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        ruleBasedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        otherEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        discrepancy.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        calculatedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
    discrepancy.normalGrid.view.getEl().on('scroll', function (e, t) {
      // mainGrid.normalGrid.view.getEl().suspendEvent('scroll')
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        importEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        lastPeriodEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        ruleBasedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        otherEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        calculatedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
    importEdit.normalGrid.view.getEl().on('scroll', function (e, t) {
      // mainGrid.normalGrid.view.getEl().suspendEvent('scroll')
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        discrepancy.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        lastPeriodEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        ruleBasedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        otherEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        calculatedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
    lastPeriodEdit.normalGrid.view.getEl().on('scroll', function (e, t) {
      // mainGrid.normalGrid.view.getEl().suspendEvent('scroll')
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        discrepancy.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        importEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        otherEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        calculatedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
    ruleBasedEdit.normalGrid.view.getEl().on('scroll', function (e, t) {
      // mainGrid.normalGrid.view.getEl().suspendEvent('scroll')
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        discrepancy.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        importEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        otherEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        calculatedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
    otherEdit.normalGrid.view.getEl().on('scroll', function (e, t) {
      // mainGrid.normalGrid.view.getEl().suspendEvent('scroll')
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        discrepancy.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        importEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        lastPeriodEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        ruleBasedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        calculatedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
    calculatedEdit.normalGrid.view.getEl().on('scroll', function (e, t) {
      // mainGrid.normalGrid.view.getEl().suspendEvent('scroll')
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        discrepancy.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        importEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        lastPeriodEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        ruleBasedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        otherEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
  },
  onImportEditAfterRender: function () {
    var me = this

    var mainGrid = me.lookup('centergrid')

    var importEdit = me.lookup('importEdit')

    var manuelEdit = me.lookup('manuelEdit')

    var lastPeriodEdit = me.lookup('lastPeriodEdit')

    var ruleBasedEdit = me.lookup('ruleBasedEdit')

    var otherEdit = me.lookup('otherEdit')
    importEdit.normalGrid.view.getEl().on('scroll', function (e, t) {
      // mainGrid.normalGrid.view.getEl().suspendEvent('scroll')
      if (me.getViewModel().get('syncSc') === 'True') {
        mainGrid.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        manuelEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        lastPeriodEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
        ruleBasedEdit.normalGrid.view.getEl().dom.scrollLeft = t.scrollLeft
      }
    })
  },
  onResizeLock: function (grid, width, height, oldWidth, oldHeight) {
    var me = this

    var mainGrid = me.lookup('centergrid')

    var importEdit = me.lookup('importEdit')

    var manuelEdit = me.lookup('manuelEdit')

    var discrepancy = me.lookup('discrepancy')
    var lastPeriodEdit = me.lookup('lastPeriodEdit')
    var ruleBasedEdit = me.lookup('ruleBasedEdit')
    mainGrid.lockedGrid.setWidth(width)
    manuelEdit.lockedGrid.setWidth(width)
    lastPeriodEdit.lockedGrid.setWidth(width)
    ruleBasedEdit.lockedGrid.setWidth(width)
    importEdit.lockedGrid.setWidth(width)
    discrepancy.lockedGrid.setWidth(width)
  },
  loadDataDisc: function () {
    var me = this

    var mainStore = me.getViewModel().getStore('mainStore')

    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var edittabpanel = me.lookup('edittabpanel')

    var discrepancy = me.lookup('discrepancy')
    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')
    var mainGrid = me.lookup('centergrid')
    var loadcount = me.getViewModel().get('loadcount')
    var importEdit = me.lookup('importEdit')
    var manuelEdit = me.lookup('manuelEdit')
    var lastPeriodEdit = me.lookup('lastPeriodEdit')
    var ruleBasedEdit = me.lookup('ruleBasedEdit')
    var otherEdit = me.lookup('otherEdit')
    var discrepancyGrid = me.lookup('discrepancy')
    var MegaRegionID = me.getViewModel().get('MegaRegionID')
    var RegionID = me.getViewModel().get('RegionID')
    var constarinView = me.getViewModel().get('constarinView')
    me.getView().setLoading('Loading data please wait')
    if (DPIHierarcy.get('DPIFamilyID') && RegionID) {
      edittabpanel.setVisible(true)
      if (!constarinView) discrepancy.setDisabled(false)
    } else {
      edittabpanel.setVisible(false)
      edittabpanel.setActiveItem(0)
      discrepancy.setDisabled(true)
    }
    Ext.Ajax.request({
      method: 'POST',
      timeout: 360000,
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_dataDisc',
      params: {
        DPISegmentID: DPIHierarcy.get('DPISegmentID'),
        ProductGroupID: DPIHierarcy.get('ProductGroupID'),
        DPIFamilyID: DPIHierarcy.get('DPIFamilyID'),
        ReportingPeriodID: ReportingPeriodID,
        MegaRegionID: MegaRegionID,
        RegionID: RegionID,
        constarinView: constarinView
      },
      success: function (response) {
        var loadcount = loadcount + 1
        me.getViewModel().set('loadcount', Ext.id())
        var data = Ext.JSON.decode(response.responseText)
        data = data ? data : response.responseText
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        if (data && data.MainGrid) {
          MDM.Btl.Column.setStartCol(data.startCol)
          me.getViewModel().set('startCol', data.startCol)
          mainGrid.setCfgData(data.MainGrid)
          manuelEdit.setCfgData(data.Edit.Manuel)
        }
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        MDM.src.ux.OnError.ajaxFailure(response, opts)
      }
    })
  },
  onCelldblClick: function (grid, td, cellIndex, record) {
    if (!record.get('IsClickable')) return
    var me = this

    var column = grid.getColumnManager().columns[cellIndex]

    var dataIndex = column.dataIndex

    var title = column.ownerCt.text + ' ' + column.text

    var win = Ext.create('MDM.view.btlv2.dialog.MaterialDialog', {
      title: record.get('Title') + ' ' + record.get('MaterialType') + ' ' + title
    })

    var store = win.store
    this.getView().add(win)
    win.show()
    var DPIHierarcy = me.getViewModel().get('DPIHierarcy')

    var ReportingPeriodID = me.getViewModel().get('reportingPeriodID')

    var RegionID = me.getViewModel().get('RegionID')

    var MegaRegionID = me.getViewModel().get('MegaRegionID')

    var proxy = store.getProxy()
    proxy.setExtraParam('DPISegmentID', DPIHierarcy.get('DPISegmentID'))
    proxy.setExtraParam('ProductGroupID', DPIHierarcy.get('ProductGroupID'))
    proxy.setExtraParam('DPIFamilyID', DPIHierarcy.get('DPIFamilyID'))
    proxy.setExtraParam('ReportingPeriodID', ReportingPeriodID)
    proxy.setExtraParam('MegaRegionID', MegaRegionID)
    proxy.setExtraParam('RegionID', RegionID)
    proxy.setExtraParam('dataIndex', dataIndex)
    proxy.setExtraParam('text', title)
    proxy.setExtraParam('data', Ext.encode(record.data))
    win.grid.store.load()
  },
  onCloseAllWin: function () {
    var wins = Ext.ComponentQuery.query('materialdialog')
    wins.forEach(function (win) {
      win.close()
    }, this)
  },
  onCloneBTL: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var rec = record.copy(null)
    for (var i = 1; i < 36; i++) {
      var field = i < 10 ? 'c0' + i : 'c' + i
      rec.set(field, null)
    }
    rec.set('IsApplied', false)
    rec.set('CreatedBy', null)
    rec.set('CreatedAt', null)
    rec.set('AppliedAt', null)
    rec.set('AppliedBy', null)
    rec.set('LastModifiedAt', null)
    rec.set('LastModifiedBy', null)
    var store = this.getViewModel().getStore('editStoreManuel')
    var grid = me.lookup('manuelEdit')
    grid.setLoading('Saving ')
    rec.save({
      success: function (rec, op) {
        store.add(rec)
        grid.setLoading(false)
      },
      failure: function (rec, op) {
        Ext.Msg.alert('Warning', 'Record could not be saved  .. Please try later', Ext.emptyFn)
      }
    })
  },
  onEditCopyBtl: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var view = me.getView()
    grid = me.getViewModel().get('actab')
    var records = me.getViewModel().get('actab').getSelection()
    records.push(record)
    grid.getSelectionModel().select(records)
    records = me.getViewModel().get('actab').getSelection()

    var parentId = []
    records.forEach(function (record) {
      parentId.push(record.get('ID'))
    }, this)
    Ext.Msg.show({
      title: 'Moving BTL Edit ',
      // height: 160,
      width: 500,
      msg: '<br> You are about to move a record. Continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doCopyRec(parentId)
        }
      }
    })
  },
  doCopyRec: function (parentId) {
    var me = this
    var view = me.getView()
    view.setLoading('Copying BTL... Please wait')
    Ext.Ajax.request({
      method: 'POST',
      timeout: 360000,
      url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_copy',
      params: {
        parentId: parentId.join(',')
      },
      success: function (response) {
        var res = Ext.decode(response.responseText, true)
        res = res ? res : response.responseText
        if (!res || res.length < 0) {
          Ext.MessageBox.alert('Error', 'Server Error')
        } else if (res[0].Success === '1') {
          view.setLoading(false)
          me.loadData()
        } else {
          me.getView().setLoading(false)
        }
      },
      failure: function (response, opts) {
        if (me.getView() && me.getView().setLoading) {
          me.getView().setLoading(false)
        }
        MDM.src.ux.OnError.ajaxFailure(response, opts)
      }
    })
  },
  onLoadRegionStore: function (store, records) {
    var me = this
    var combo = me.lookup('rcombo')
    if (records && _.size(records) > 0) {
      combo.select(records[0])
    }
  }
})
