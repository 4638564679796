import React, { Component } from 'react'
import _ from 'lodash'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export class SidebarCell extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  getTooltip(text, id = Math.random()) {
    return <Tooltip id={id}>{text}</Tooltip>
  }

  render() {
    const { iconTpl = null, text = '', value = '', placement = 'top' } = this.props

    return (
      <div className="cell">
        {iconTpl}
        <OverlayTrigger overlay={this.getTooltip(`${text}: ${value}`)} placement={placement}>
          <p>{value}</p>
        </OverlayTrigger>
      </div>
    )
  }
}
