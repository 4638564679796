import { ValueFormatterParams } from 'ag-grid-community'
import { IRowData, TrowDataValue } from '../../types'
import { GetValueFormatterProps, GetValueFormatterPropsReturnType } from './valueFormatter.types'
import { getFormattedValueGrid } from '../index'

// TODO: Missing test
function getValueFormatter({
  fieldConfig,
  formatParams,
  groupDisplayType
}: GetValueFormatterProps): GetValueFormatterPropsReturnType {
  const { dataType } = fieldConfig
  const {
    columnConfig,
    fieldConfigs,
    getFormattedValue: _getFormattedValue,
    formatValue,
    formattedFields,
    editableFields
  } = formatParams

  if (dataType === 'bool' || dataType === 'datetime') {
    return {}
  }

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    valueFormatter: (params: ValueFormatterParams<IRowData, TrowDataValue>) => {
      const {
        data,
        node: { id, group = false }
      } = params
      const record: { data: IRowData } = { data: data as IRowData }
      let paramsValue: TrowDataValue = params.value as TrowDataValue

      // For grouped rows, ag-grid sends params.data as undefined and params.value as string
      // So here; we are providing 'data' and value as the raw version of them.
      // Https://ag-grid.zendesk.com/hc/en-us/requests/27909
      const isGroup = Boolean(group)
      if (isGroup) {
        const newRecord = params.api.getRowNode(id)
        if (newRecord) {
          if (groupDisplayType === 'groupRows') {
            // Note: groupRowInnerRenderer does not get valueFormatted, below fixes it
            record.data = newRecord.allLeafChildren[0].data
            paramsValue = record.data[columnConfig?.fieldName as string]
          }
        }

        if (paramsValue !== null && typeof paramsValue === 'object') {
          if (paramsValue?.value === null) {
            paramsValue = null
          } else {
            paramsValue = (('value' in paramsValue) as unknown as { value: TrowDataValue })
              ? paramsValue?.value
              : paramsValue
          }
        }
      }

      return getFormattedValueGrid({
        value: paramsValue,
        columnConfig,
        record,
        fieldConfigs,
        getFormattedValue: _getFormattedValue,
        formatValue,
        formattedFields,
        editableFields
      })
    }
  }
}

export { getValueFormatter }
