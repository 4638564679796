import { Component } from 'react'

import _ from 'lodash'

import SelectEditor from '../SelectEditor'
import ColorPicker from '../ColorPicker'
import IconEditor from '../IconEditor'
import CodeEditor from '../CodeEditor'

export default class StringEditor extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { schema = {}, value } = this.props
    const val = !_.isUndefined(value) ? value : schema.default
    this.setState({ value: val })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value })
    }
  }

  handleChange(e) {
    const val = e.target.value

    if (this.props.value !== val) {
      this.setState({ value: val })

      if (_.isFunction(this.props.onChange)) {
        this.props.onChange(val)
      }
    }
  }

  render() {
    const { schema } = this.props
    const { readOnly, format } = schema
    const { value = '' } = this.state
    const newValue = typeof value === 'object' ? '' : value

    // console.log('StringEditor:::', schema, value)

    if (schema.enumSource || schema.enum) {
      return <SelectEditor {...this.props} />
    }
    if (format === 'Color' || format === 'NullColor') {
      return <ColorPicker {...this.props} />
    }
    if (format === 'IconSelector') {
      return <IconEditor {...this.props} inputGroupClass="w-55" />
    }
    if (format === 'Code') {
      return <CodeEditor {...this.props} />
    }
    if (format === 'textarea') {
      return (
        <textarea
          className="form-control"
          readOnly={readOnly}
          rows={10}
          value={newValue}
          onBlur={this.handleChange}
          onChange={(e) => {
            this.setState({ value: e.target.value })
          }}
        />
      )
    }

    return (
      <input
        className="form-control"
        readOnly={readOnly}
        type="text"
        value={newValue}
        onBlur={this.handleChange}
        onChange={(e) => {
          this.setState({ value: e.target.value })
        }}
      />
    )
  }
}
