import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  PRODUCTsaveResponseData as saveResponseData,
  PRODUCTtoggleProduct as onToggleProductionInfo,
  PRODUCTtoggleResourceBasedProduction as toggleResourceBasedProduction,
  PRODUCTonSort as onSort,
  setScrollPos
} from '../../actions'
import PeriodBox from './components/periodBox'
import ResourceRow from './components/resourceRow'
import MultiSelectList from './components/multiSelectList'
import Search from './components/search'
import { filterChildren, getEnabledDisplayNames } from '../../utils'
import CustomConsole from '../../utils/custom-console'

class ProductionV2 extends React.Component {
  constructor(props) {
    super(props)
    const {
      GetRccpResourcePeriodHierarchyResponse = {},
      GetRccpResourcePeriodHierarchyResponse: { Root = {}, Root: { Children = [] } = {} },
      periodDates = {},
      productSearchInputValue
    } = props

    GetRccpResourcePeriodHierarchyResponse.periodDates = periodDates
    if (productSearchInputValue) {
      const filteredChildren = filterChildren(Children, productSearchInputValue)
      const filteredGetRccpResourcePeriodHierarchyResponse = {
        ...GetRccpResourcePeriodHierarchyResponse,
        FilteredRoot: { Children: filteredChildren }
      }
      this.props.saveResponseData(filteredGetRccpResourcePeriodHierarchyResponse)
    } else {
      this.props.saveResponseData({ ...GetRccpResourcePeriodHierarchyResponse, FilteredRoot: Root })
    }

    this.handleResourceBasedProduction = this.handleResourceBasedProduction.bind(this)
  }

  componentDidUpdate(prevProps) {
    const {
      GetRccpResourcePeriodHierarchyResponse = {},
      GetRccpResourcePeriodHierarchyResponse: { Root: { Children: children = [] } = {} } = {},
      periodDates = {},
      productSearchInputValue = '',
      settings: { filters = {} } = {}
    } = this.props

    GetRccpResourcePeriodHierarchyResponse.periodDates = periodDates
    const {
      GetRccpResourcePeriodHierarchyResponse: prevGetRccpResourcePeriodHierarchyResponse = {},
      productSearchInputValue: prevProductSearchInputValue,
      periodDates: prevPeriodDates = {},
      settings: { filters: prevFilters = {} } = {}
    } = prevProps

    if (!_.isEqual(filters, prevFilters) || !_.isEqual(periodDates, prevPeriodDates))
      this.props.onSort({})

    const isResponseEqual = _.isEqual(
      prevGetRccpResourcePeriodHierarchyResponse,
      GetRccpResourcePeriodHierarchyResponse
    )
    if (!isResponseEqual || !_.isEqual(productSearchInputValue, prevProductSearchInputValue)) {
      const filteredChildren = filterChildren(children, productSearchInputValue)
      const filteredGetRccpResourcePeriodHierarchyResponse = {
        ...GetRccpResourcePeriodHierarchyResponse,
        FilteredRoot: { Children: filteredChildren }
      }
      this.props.saveResponseData(filteredGetRccpResourcePeriodHierarchyResponse)
    }
  }

  scrollHandler = (event, sw) => {
    const resourcePlanLeft = document.querySelector('#resourcePlanLeft')
    const resourcePlanRight = document.querySelector('#resourcePlanRight')

    if (resourcePlanRight) {
      const target = sw ? resourcePlanLeft : resourcePlanRight
      const crossTarget = sw ? resourcePlanRight : resourcePlanLeft

      crossTarget.scrollLeft = target.scrollLeft
    }

    if (sw) {
      this.handleScroll('main', event)
    }
  }

  handleScroll = (scrollArea, event) => {
    const {
      target: { scrollTop, scrollLeft }
    } = event

    this.props.setScrollPos({
      view: 'productionPlanV2',
      scrollArea,
      scrollPos: { top: scrollTop, left: scrollLeft }
    })
  }

  handleToggleProductionInfo(event) {
    this.props.onToggleProductionInfo()
  }

  handleResourceBasedProduction() {
    this.props.toggleResourceBasedProduction()
  }

  render() {
    const {
      getRccpProductsResponse = {},
      initialFilters = {},
      lastRequest = {},
      resourcePeriodHierarchy,
      resourcePeriodHierarchy: { Periods: periods = [], FilteredRoot: root = {} } = {},
      settings = {},
      settings: {
        periodSettings: {
          displayOptions: { isYearVisible = true, isWeekVisible = true, isMonthVisible = true } = {}
        } = {},
        isMaximized
      } = {},
      clickedProductInfo: { periodIndex },
      connectApi = () => {},
      historyHandler = () => {},
      stateHandler = () => {},
      viewHandler = () => {},
      isAllowed = () => {}
    } = this.props

    const periodBarStyle = { width: _.size(periods) * 70 + 314 }

    CustomConsole.log({ data: resourcePeriodHierarchy })

    return (
      <article className="resource-plan-container">
        {/* <Header settings={settings} historyHandler={historyHandler} /> */}
        <main className="resource-plan">
          <section
            className={`resource-plan__side-left
              ${isMaximized ? 'resource-plan__side-left--is-maximized' : ''}`}
            id="resourcePlanLeft"
            onScroll={(event) => this.scrollHandler(event, true)}
          >
            <div className="periods-bar resource-plan__periods-bar-wrapper">
              <article className="periods-bar resource-plan__periods-bar" style={periodBarStyle}>
                <section
                  className={`${
                    periodIndex === 0 ? 'periods-bar_border-right' : ''
                  } periods-bar__side-left`}
                >
                  <Search />
                  <MultiSelectList
                    connectApi={connectApi}
                    historyHandler={historyHandler}
                    lastRequest={this.props.lastRequest}
                    settings={settings}
                  />
                </section>
                <section className="periods-bar__side-right">
                  {_.map(
                    periods,
                    ({ DisplayName: periodDisplayName = '', Name: periodName }, index) => {
                      const enabledDisplayNames = getEnabledDisplayNames(
                        [isYearVisible, isWeekVisible, isMonthVisible],
                        periodDisplayName,
                        periodName
                      )
                      const isGrandTotal = periodDisplayName === 'Grand Total'

                      return (
                        <PeriodBox
                          key={index}
                          connectApi={connectApi}
                          dateArr={enabledDisplayNames}
                          isGrandTotal={isGrandTotal}
                          periodIndex={index}
                        />
                      )
                    }
                  )}
                </section>
              </article>
            </div>
            <article className="resource-plan__resource-row">
              <ResourceRow
                connectApi={connectApi}
                getRccpProductsResponse={getRccpProductsResponse}
                historyHandler={historyHandler}
                initialFilters={initialFilters}
                isAllowed={isAllowed}
                lastRequest={lastRequest}
                periods={periods}
                root={root}
                settings={settings}
                stateHandler={stateHandler}
                viewHandler={viewHandler}
              />
            </article>
          </section>
          {/* <div className="production-panel-lock">
            <div onClick={this.handleToggleProductionInfo.bind(this)}>
              <i className="fa-fw fa fa-info-circle" />
              <span>PRODUCTION INFO</span>
            </div>
            <div className="product-variant-radio">
              <label className="product-variant-radio__label">
                <input
                  className="product-variant-radio__input"
                  checked={!isProductionResourceBased}
                  name="product-variant"
                  type="radio"
                  onChange={this.handleResourceBasedProduction}
                />
                <span className="product-variant-radio__text">Product Based</span>
              </label>
              <label className="product-variant-radio__label">
                <input
                  checked={isProductionResourceBased}
                  className="product-variant-radio__input"
                  name="product-variant"
                  type="radio"
                  onChange={this.handleResourceBasedProduction}
                />
                <span className="product-variant-radio__text">Resource Based</span>
              </label>
            </div>
          </div>
          <section
            className={`resource-plan__side-right ${
              maximizeState ? 'resource-plan__side-right--is-maximized' : ''
            }`}
            style={{ ...(isProductionInfoOpen && { minWidth: 'calc(44% - 22px)' }) }}
          >
            <ProductionInfo
              initialFilters={initialFilters}
              settings={settings}
              connectApi={connectApi}
              isAllowed={isAllowed}
              scrollHandler={this.scrollHandler}
              stateHandler={stateHandler}
            />
          </section> */}
        </main>
      </article>
    )
  }
}

const mapStateToProps = (state) => ({
  resourcePeriodHierarchy: state.PRODUCTresourcePeriodHierarchy,
  isProductionInfoOpen: state.PRODUCTclickedProductInfo.isProductionInfoOpen,
  isLocked: state.PRODUCTclickedProductInfo.isLocked,
  isProductionResourceBased: state.PRODUCTresourcePlan.isProductionResourceBased,
  productSearchInputValue: state.PRODUCTclickedProductInfo.productSearchInputValue,
  sortConfig: state.resourcePlan.PRODUCTsortConfig,
  clickedProductInfo: state.PRODUCTclickedProductInfo.clickedProductInfo
})

const mapDispatchToProps = {
  saveResponseData,
  onToggleProductionInfo,
  toggleResourceBasedProduction,
  onSort,
  setScrollPos
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionV2)
