import { memo, useMemo, useState, useCallback, useRef } from 'react'
import _ from 'lodash'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Accordion } from 'react-bootstrap'
import { bodyContentHeight, containerStyle1, searchOption } from '../../../constants'
import { getFilteredMethods, getActiveMethodsCount, convertDataForSearch } from '../../../helpers'
import { MethodsContainer } from '../../Containers'
import SlvyAutosuggest, { SuggestionsContainer } from '@/components/SlvyAutosuggest'
import sortByKey from '@/utils/sortByKey'
import {
  IContainer,
  IContainerSettings,
  IRegister,
  IRegisterEventMethod,
  TcountInfo
} from '../../../../Common.types'
import { IContainersSectionProps } from './ContainersSection.types'
import { ISearchCallbackParams } from '../../../Assignees.types'
import SelectedVariables from '../../SelectedVariables'
import NotFound from '../../NotFound'
import { emptyObject } from '@/containers/EventMethod/constants'

const leadText = (
  <>
    If you are looking for a specific container please enter the container <strong>name</strong>{' '}
    e.g. popupcontainer
  </>
)

const ContainersSection = ({
  containers,
  assignees,
  variables,
  selectedVariables,
  toggleActives,
  handleReturnVariableChange,
  handleRemoveSelectedVariable
}: IContainersSectionProps) => {
  const [searchOptions, setSearchOptions] = useState({ ...searchOption })

  const handleSuggestionSelected = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: true
    })
  }, [])

  const handleSuggestionNotFound = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: true,
      isSelected: true
    })
  }, [])

  const handleQueryChange = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: false
    })
  }, [])

  const filteredContainers = useMemo(() => {
    return getFilteredMethods(
      assignees,
      containers,
      searchOptions,
      selectedVariables,
      toggleActives
    ).filter((container) => _.size(container?.registers?.methods))
  }, [assignees, toggleActives, containers, searchOptions, selectedVariables])

  const methodSearchData = useMemo(
    () => convertDataForSearch(filteredContainers),
    [filteredContainers]
  )

  const parentRef = useRef(null)

  const virtualizer = useVirtualizer({
    count: _.size(filteredContainers),
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => {
      const filteredContainer = filteredContainers[index]
      const { id, registers: { methods = emptyObject } = emptyObject as IRegister } =
        filteredContainer
      const { [id]: methodsAssignees = emptyObject } = assignees

      const sortedMethods = sortByKey(methods) as IRegisterEventMethod
      const { active } = getActiveMethodsCount(sortedMethods, methodsAssignees)
      return active ? 500 : 100
    }
  })

  const isNotFound =
    (searchOptions.query && searchOptions.notFound) ||
    (selectedVariables.length && !filteredContainers.length)

  const renderSuggestionsContainer = useCallback(
    (suggestionsContainerProps: any) => (
      <SuggestionsContainer {...suggestionsContainerProps} containerStyle={containerStyle1} />
    ),
    []
  )

  const countInfos: TcountInfo[] = useMemo(() => {
    return filteredContainers.map((filteredContainer: IContainer) => {
      const { id, registers: { methods = emptyObject } = emptyObject as IRegister } =
        filteredContainer
      const { [id]: methodsAssignees = emptyObject } = assignees

      const sortedMethods = sortByKey(methods) as IRegisterEventMethod
      const { active, total } = getActiveMethodsCount(sortedMethods, methodsAssignees)
      return { active, total, id }
    })
  }, [filteredContainers, assignees])

  return (
    <div
      className="d-grid"
      data-testid="containers-section"
      style={{
        height: bodyContentHeight,
        gridTemplateRows: selectedVariables.length
          ? 'min-content min-content 1fr'
          : 'min-content 1fr'
      }}
    >
      <SlvyAutosuggest
        data={methodSearchData}
        field="methodOptions"
        placeholder="Search"
        renderSuggestionsContainer={renderSuggestionsContainer}
        size="sm"
        onQueryChange={handleQueryChange}
        onSuggestionNotFound={handleSuggestionNotFound}
        onSuggestionSelected={handleSuggestionSelected}
      />
      <SelectedVariables
        handleRemove={handleRemoveSelectedVariable}
        variables={selectedVariables}
      />
      {/* TODO: Display not found ui when variable search has no result. */}
      {isNotFound ? (
        <NotFound leadText={leadText} query={searchOptions.query} tags={selectedVariables} />
      ) : (
        <div ref={parentRef} className="w-100 overflow-y-auto h-100 pe-1">
          <Accordion
            alwaysOpen
            className="w-100 position-relative"
            defaultActiveKey={countInfos.filter((item) => item.active).map((item) => item.id)}
            style={{ height: virtualizer.getTotalSize() }}
          >
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const filteredContainer = filteredContainers[virtualRow.index]
              const {
                id,
                registers: { methods = emptyObject } = emptyObject as IRegister,
                settings: { name = '' } = emptyObject as IContainerSettings,
                type = ''
              } = filteredContainer
              const { [id]: methodsAssignees = emptyObject } = assignees
              const countInfo = countInfos.find((item) => item.id === id)

              return (
                <Accordion.Item
                  key={virtualRow.key}
                  ref={virtualizer.measureElement}
                  className="position-absolute top-0 left-0 w-100"
                  data-index={virtualRow.index}
                  eventKey={id}
                  style={{ transform: `translateY(${virtualRow.start}px)` }}
                >
                  <MethodsContainer
                    countInfo={countInfo}
                    handleReturnVariableChange={handleReturnVariableChange}
                    id={id}
                    methods={methods}
                    methodsAssignees={methodsAssignees}
                    name={name}
                    toggleActives={toggleActives}
                    type={type}
                    variables={variables}
                  />
                </Accordion.Item>
              )
            })}
          </Accordion>
        </div>
      )}
    </div>
  )
}

export default memo(ContainersSection)
