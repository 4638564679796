import { updateWithSubstituteNewValue } from '../index'
import { data as defaultData, dirtyCells as defaultDirtyCells } from './mocks'

const getData = () => {
  return defaultData
}
const getDirtyCells = () => {
  return defaultDirtyCells
}

describe('AgGrid:Helpers:Lookup:updateWithSubstituteNewValue', () => {
  let data = getData()
  let dirtyCells = getDirtyCells()

  beforeEach(() => {
    data = getData()
    dirtyCells = getDirtyCells()
  })

  it(`should return an object equals to data if isSubstitute is false of dirtyCells' objects`, () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = updateWithSubstituteNewValue({ data, dirtyCells })
    expect(result).toEqual(data)
  })
  it(`should return an object includes new value of the related field coming from dirtyCells' object newValueParams.id when isSubstitute is true`, () => {
    dirtyCells[0].isSubstitute = true
    dirtyCells[0].newValueParams.id = 6
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = updateWithSubstituteNewValue({ data, dirtyCells })
    expect(result).toEqual({
      col1: 6,
      col2: 77,
      col3: 10
    })
  })
  it(`should return an object equals to data when isSubstitute is not defined into dirtyCells array's objects`, () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dirtyCells[0] = {
      field: 'col1',
      newValueParams: {
        id: 'New York',
        value: 'New York'
      },
      oldValueParams: {
        id: 'Barcelona',
        value: 'Barcelona'
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dirtyCells[1] = {
      field: 'col2',
      newValueParams: {
        id: 77,
        value: 77
      },
      oldValueParams: {
        id: 222,
        value: 222
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = updateWithSubstituteNewValue({ data, dirtyCells })
    expect(result).toEqual(data)
  })
  it('should return an object equals to data if dirtyCells is an empty array', () => {
    dirtyCells = []
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = updateWithSubstituteNewValue({ data, dirtyCells })
    expect(result).toEqual(data)
  })
  it('should return an object equals to data if dirtyCells is undefined', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = updateWithSubstituteNewValue({ data })
    expect(result).toEqual(data)
  })
})
