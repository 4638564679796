Ext.define('MDM.src.view.regionapp.DroppedMaterial.Grid', {
  extend: 'Ext.grid.Panel',
  xtype: 'regionapp-droppedmaterial-grid',
  columnLines: true,
  rowLines: true,
  border: true,
  stateful: true,
  stateId: 'droppedgrid',
  modal: true,
  split: true,
  lockedGridConfig: {
    stateful: true,
    stateId: 'droppedgridlocked',
    header: false,
    width: 550,
    split: true,
    forceFit: false
  },
  lockedViewConfig: {
    scroll: 'horizontal'
  },
  listeners: {
    // this event notifies us when the document was saved
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady'
  },
  emptyText: '<h3> No Record Found <h3>',
  plugins: ['gridfilters', 'gridexporter'],
  viewConfig: {
    enableTextSelection: true
  },
  features: [
    {
      ftype: 'grouping',
      startCollapsed: true,
      groupHeaderTpl: '{name} ({rows.length} Material{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  layout: 'border',
  columns: [
    {
      text: 'Material',
      locked: true,
      flex: 1,
      sortable: true,
      minWidth: 90,
      dataIndex: 'MaterialCode',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Description',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 250,
      locked: true,
      editor: {},
      dataIndex: 'MaterialDescription',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Reason',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 120,
      locked: true,
      editor: {},
      dataIndex: 'Reason',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Appears on extract',
      flex: 1,
      sortable: true,
      minWidth: 100,
      dataIndex: 'AppearOnExtractYesOrNo',
      filter: 'list',
      locked: true
    },
    {
      text: 'Appears on Subregion',
      flex: 1,
      sortable: true,
      minWidth: 100,
      dataIndex: 'AppearsOnSubregion',
      filter: 'list',
      locked: true
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'AppearOnExtractYesOrNo',
      menuDisabled: true,
      sortable: false,
      menuText: 'Extract Details',
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-external-link-square',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('AppearsOnExtract')
          },
          tooltip: 'Extraction details',
          handler: 'onExtractDetails'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'MaterialDescription',
      menuDisabled: true,
      sortable: false,
      enableCellDrag: true,
      width: 30,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-history',
          enableCellDrag: true,
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          tooltip: 'Restore Material',
          handler: 'onRestore'
        }
      ]
    },
    {
      text: 'Demand',
      flex: 1,
      sortable: true,
      minWidth: 100,
      dataIndex: 'DemandYesorNo',
      align: 'center',
      filter: 'list',
      locked: true
    },
    {
      text: 'Production',
      flex: 1,
      sortable: true,
      minWidth: 100,
      align: 'center',
      dataIndex: 'ProductionYesorNo',
      filter: 'list',
      locked: true
    },
    {
      text: 'Inventory',
      flex: 1,
      sortable: true,
      align: 'center',
      minWidth: 100,
      dataIndex: 'InventoryYesorNo',
      filter: 'list',
      locked: true
    },
    {
      text: 'Xsupply',
      flex: 1,
      sortable: true,
      minWidth: 100,
      align: 'center',
      dataIndex: 'XsupplyYesorNo',
      filter: 'list',
      locked: true
    },
    {
      text: 'BUOM',
      minWidth: 100,
      sortable: true,
      dataIndex: 'MaterialBUOM',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      editor: {}
    },
    {
      text: 'SU',
      width: 100,
      sortable: true,
      xtype: 'numbercolumn',
      format: '0.000000',
      align: 'right',
      dataIndex: 'SU',
      filter: 'number'
    },
    {
      text: 'GU Assigned',
      width: 120,
      tdCls: 'red',
      align: 'right',
      sortable: true,
      format: '0',
      xtype: 'numbercolumn',
      dataIndex: 'GU',
      filter: 'number'
    },
    {
      text: 'Material Type',
      width: 120,
      sortable: true,
      dataIndex: 'MaterialType',
      filter: 'list'
    },
    {
      text: 'Category',
      width: 120,
      sortable: true,
      dataIndex: 'Category',
      filter: 'list'
    },
    {
      text: 'Brand',
      width: 120,
      sortable: true,
      dataIndex: 'Brand',
      filter: 'list'
    },
    {
      text: 'Product Form Detail',
      width: 120,
      sortable: true,
      dataIndex: 'ProductFormDetail',
      filter: 'list'
    },
    {
      text: 'CSU Indicator',
      width: 120,
      sortable: true,
      dataIndex: 'CSUIndicator',
      filter: 'list'
    },
    {
      text: 'Suppressed By',
      width: 120,
      sortable: true,
      dataIndex: 'DroppedBy',
      filter: 'list'
    },
    {
      xtype: 'datecolumn',
      text: 'Suppressed At',
      dataIndex: 'DroppedAt',
      format: 'Y-m-d\\TH:i:s',
      filter: 'date',
      width: 200
    },
    {
      text: 'Modified At',
      width: 120,
      sortable: true,
      xtype: 'datecolumn',
      format: 'Y-m-d\\TH:i:s',
      dataIndex: 'ModifiedDate',
      filter: 'date'
    },
    {
      text: 'Data Source',
      width: 120,
      sortable: true,
      align: 'right',
      dataIndex: 'DataSource',
      filter: 'list'
    }
  ]
})
