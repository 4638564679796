import {
  STORE_SCENARIO_COMPARISON_SETTINGS,
  RESET_SCENARIO_COMPARISON_SETTINGS
} from '../actions/actionTypes'

const initialState = {
  scenarioComparisonSettings: {
    isVisible: false,
    selectedLeftKeys: [],
    metricFields: [], // [{ Name: 'UnitSales', DisplayName: 'Unit Sales', AllowUpdate: false }}]
    selectedMetricFields: [{ Name: '' }, { Name: '' }, { Name: '' }], // [{ Name: 'Unit Sales', defaultSelected: true }],
    selectedBreadcrumbs: [],
    mergedLeftFieldsAndKeys: []
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SCENARIO_COMPARISON_SETTINGS:
      return {
        scenarioComparisonSettings: {
          ...state.scenarioComparisonSettings,
          ...action.payload
        }
      }
    case RESET_SCENARIO_COMPARISON_SETTINGS:
      return {
        scenarioComparisonSettings: {
          ...state.scenarioComparisonSettings,
          ...initialState.scenarioComparisonSettings
        }
      }
    default:
      return state
  }
}

export default reducer
