export default `({
    reference: 'contractGrid',
    plugins: [
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    multiColumnSort: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [{
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('Sözleşme Tarihi'),
            dataIndex: 'SozlesmeTarihi',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('VPER_START'),
            dataIndex: 'AfisBaslangicTarihi',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('VPER_END'),
            dataIndex: 'AfisBitisTarihi',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '0,000.00',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn',
        },
        {
            text: tt('Beklenen Ciro'),
            dataIndex: 'BeklenenCiro',
            filter: {
                type: 'number'
            },
            width: 100,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('Toplam Adet'),
            dataIndex: 'ToplamAdet',
            filter: {
                type: 'number'
            },
            width: 100,
            align: 'right',
            xtype: 'numbercolumn',
        }, {
            text: tt('VendorCode'),
            dataIndex: 'TedarikciKodu',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('Tedarikçi Adı'),
            dataIndex: 'VendorName',
            filter: {
                type: 'string'
            },
            width: 200
        },
        
        {
            text: tt('TeslimatTarihi'),
            dataIndex: 'TeslimatTarihi',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('Kategori Grubu'),
            filter: {
                type: 'string'
            },
            dataIndex: 'KategoriGrubu',
            width: '90'
        }, {
            text: tt('Sözleşme Numarası'),
            dataIndex: 'ContractNo',
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            },
            width: '90'
        }
    ],
})
`
