import { PluginProps } from '@/BasePlugin/components/PluginProps.types'
import { slvyToast } from '@/components'

export function tryParseSettings(settings: string) {
  try {
    const sanitizedSettings = settings.replace(/"([^"]*)""/g, `\\"$1\\""`)

    return JSON.parse(sanitizedSettings)
  } catch (error) {
    slvyToast.error({
      message: 'See the console for additional details.',
      title: (error as Error).message
    })
    console.log({ 'columnSettings error': error })

    return undefined
  }
}

export type FlatTree = Array<{
  ID: string
  ParentID: string
  Level: number
  LevelName: string
  [key: string]: any
}>

export function treeify(flatTree: FlatTree) {
  return flatTree
    .map((item) => (item.Level === 1 ? { ...item, Level: 0, ParentID: 'ALL' } : item))
    .map((item) => ({
      ...item,
      Children: flatTree.filter(({ ParentID }) => ParentID === item.LevelName)
    }))
    .filter(({ Level }) => Level === 0)
}

export function selectPluginProps({
  actualFilters,
  data,
  fetchPageData,
  getPageSize,
  getTotalRowCount,
  pluginData,
  registerEvent,
  registerMethod,
  settings
}: PluginProps) {
  return {
    actualFilters,
    config: settings.config,
    data,
    fetchPageData,
    getPageSize,
    getTotalRowCount,
    pluginData,
    registerEvent,
    registerMethod
  }
}
