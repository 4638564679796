export default `({
    plugins: [{
        ptype: 'rowexpander',
        rowBodyTpl: [
            '<div class="expander">',
            '<table class="table">',
            '<tr>',
            '</tr>',
            '  <tr>',
            '       <th class=r>Solvoyo</th>',
            '      <td class="r">{OrderRecommendationParent}</td>',
            '       <th class=r>'+tt('Merkez')+'</th>',
            '      <td class="r">{OrderRecommendationCurrent}</td>',
            '       <th class="r">'+tt('Bölge')+'</th>',
            '      <td class="r">{OrderRecommendationChild}</td>',
            '   </tr>',
            // '  <tr>',
            // '       <th class=r>NumberOf Org</th>',
            // '      <td class="r">{NumberOfRecommendedOrg}</td>',
            // '     <th class="r">Waiting Org</th>',
            // '      <td class="r">{NumberOfWaitingOrg}</td>',
            // '       <th class="r"> Delegated</th>',
            // '      <td class="r">{NumberOfDelegatedOrg}</td>',
            // '   </tr>',
            // '</tr>',
            // '  <tr>',
            // '      <th class="r"> Approved </td>',
            // '      <td class="r">{NumberOfApprovedOrg}</td>',
            // '      <th class="r">Dismissed</td>',
            // '      <td class="r" >{NumberOfDismissedOrg}</td>',
            // '   </tr>',
            '</table>',
            '</div>',
        ]
    },
    {
        ptype: 'gridfilters',
        id: 'gridfilters'
    }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [
        { 
            xtype: 'button',
            text: tt('Bölge Onaylarını Devral') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt,
                spotIds=[]

                grid.store.getDatas().forEach((x)=>{if(x.IsChecked) spotIds.push(x.SpotId)})
                    
                if(spotIds.length==0)
                    return

                Ext.Msg.show({
                    title: tt('Iptal'),
                    //height: 160,
                    width: 500,
                    msg: '<br>'+ tt('Seçilen kayıtları devralmak istediğinize emin misiniz?')+'</br>',
                    buttons: Ext.Msg.YESNO,
                    icon: Ext.MessageBox.QUESTION,
                    buttonText: {
                        yes: tt('Evet'),
                        no: tt('Hayır')
                    },
                    fn: function (response) {
                        if (response === 'yes') {
                            me.up('spui-view-tracking-tracking').setLoading(true);

                            Ext.Ajax.request({
                                url: Spui.Global.getApiUrl() + '/spui/MassChangeOverRecommendationAll',
                                method: 'POST',
                                params: {
                                    spotIds:JSON.stringify(spotIds)
                                },
                                success: function(response, opts) {
                                    
                                    me.up('spui-view-tracking-tracking').setLoading(false);
                                    me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                    
                                },
                                failure: function(response, opts) {
                                    
                                    me.up('spui-view-tracking-tracking').setLoading(false);
                                    Ext.Msg.alert(
                                        tt('Durum'),
                                        tt('İşlem Gerçekleştirilirken bir hata oluştu')
                                    )
                                }
                            });
                        }
                    }
                });
               
            }
        },
        { 
            xtype: 'button',
            text: tt('Toplu Afişe çık') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt,
                spotIds=[]
                grid.store.getDatas().forEach((x)=>{if(x.IsChecked) spotIds.push(x.SpotId)})
                    
                if(spotIds.length==0)
                    return

                Ext.Msg.show({
                    title: tt('Iptal'),
                    //height: 160,
                    width: 500,
                    msg: '<br>'+ tt('Seçilen kayıtları afişe çıkmak istediğinize emin misiniz?')+'</br>',
                    buttons: Ext.Msg.YESNO,
                    icon: Ext.MessageBox.QUESTION,
                    buttonText: {
                        yes: tt('Evet'),
                        no: tt('Hayır')
                    },
                    fn: function (response) {
                        if (response === 'yes') {
                            me.up('spui-view-tracking-tracking').setLoading('Please wait while we are processing your request...');
                            Ext.Ajax.request({
                                url: Spui.Global.getApiUrl() + '/spui/MassUpdateStatusFromNegotiationToPublished',
                                method: 'POST',
                                timeout: 60000,
                                jsonData:{
                                    spotids:spotIds,
                                    actionType:'publishFromNegotiation'
                                },
                                success: function (response, opts) {
                                    if(response.responseText=='false')
                                    (
                                        Ext.Msg.alert(
                                            tt('Durum'),
                                            tt('İşlem Gerçekleştirilemedi!'))
                                    )
                                    me.up('spui-view-tracking-tracking').setLoading(false);
                                    me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                },
                                failure: function (response, opts) {
                                    me.up('spui-view-tracking-tracking').setLoading(false);
                                    console.log('server-side failure with status code ' + response.status);
                                    Ext.Msg.alert(
                                        tt('Durum'),
                                        tt('İşlem Gerçekleştirilirken bir hata oluştu'))
                                }
                            });
                        }
                    }
                });
               
            }
        },
        { 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    multiColumnSort: true,
    columnLines: true,
    columns: [
        
        {
            xtype: 'checkcolumn',
            menuDisabled: true,
            headerCheckbox: true,
            width: 50,
            dataIndex:'IsChecked',
            // listeners: {
            //     checkchange: function ( me, rowIndex, checked, record, e, eOpts )  {
            //         var grid=me.up("spui-view-tracking-navigation-spotgrid")
            //         grid.onCheckChange(grid,record,checked)

            //       },
            // }
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            //locked: true,
            width: 30,
            align: 'center',
            items: [{
                tooltip: tt('Afise Cik'),
                iconCls: 'fa fa-newspaper-o blue',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = this
                    var win = Ext.create('Spui.uxWindow', {
                        height: '80%',
                        width: 950,
                        ui: 'sui-window',
                        maximized: true,
                        spotId: record.get('SpotId'),
                        spotRecord: record,
                        // title: tt('Spot Afis'),
                        // title: tt('Spot Afis'),
                        bscope: me,
                        uxItemCfg: 'centerApproveAllAtAnyTimeNegotiation',
                        //uxStoreCfg: 'centerApproveAllAtAnyTimeStore',
                        uxItem: {
                            spotRecord: record,
                            viewModel: {
                                stores: {
                                    gridStore: {
                                        autoLoad: true,
                                        autoSync: false,
                                        groupField: 'StatusDescription',
                                        listeners: {
                                            load: 'onLoadStore'
                                        },
                                        fields: [{
                                                name: 'SpotID',
                                                type: 'int'
                                            },
                                            {
                                                name: 'DCCode',
                                                type: 'string'
                                            },
                                            {
                                                name: 'DCName',
                                                type: 'string'
                                            },
                                            {
                                                name: 'MaterialCode',
                                                type: 'string'
                                            },
                                            {
                                                name: 'LastModifiedAt',
                                                type: 'date',
                                                dateFormat: tt('Y-m-d H:i:s.u')
                                            },
                                            {
                                                name: 'LastModifiedBy',
                                                type: 'string'
                                            },
                                            {
                                                name: 'StatusCode',
                                                type: 'string'
                                            },
                                            {
                                                name: 'UnitsPerPackage',
                                                type: 'number'
                                            },
                                            {
                                                name: 'NumberofStore',
                                                type: 'int'
                                            },
                                            {
                                                name: 'NumberofRecommendedStore',
                                                type: 'int'
                                            },
                                            {
                                                name: 'OnHandInventoryStore',
                                                type: 'number'
                                            },
                                            {
                                                name: 'OnHandInventoryDC',
                                                type: 'number'
                                            },
                                            {
                                                name: 'OpenOrderStore',
                                                type: 'number'
                                            },
                                            {
                                                name: 'OpenOrderDC',
                                                type: 'number'
                                            },
                                            {
                                                name: 'OrderRecommendationParent',
                                                type: 'number'
                                            },
                                            {
                                                name: 'OrderRecommendationCurrent',
                                                type: 'number'
                                            },
                                            {
                                                name: 'OrderRecommendationChild',
                                                type: 'number'
                                            },
                                            {
                                                name: 'IsInclude',
                                                type: 'int',
                                                convert: function (v) {
                                                    if (typeof v === 'boolean') {
                                                        return v ? 1 : 0;
                                                    } else {
                                                        return parseInt(v, 10);
                                                    }
                                                }
                                            },
                                            {
                                                name: 'IsLocked',
                                                type: 'int',
                                                convert: function (v) {
                                                    if (typeof v === 'boolean') {
                                                        return v ? 1 : 0;
                                                    } else {
                                                        return parseInt(v, 10);
                                                    }
                                                }
                                            }
                                        ],
                                        proxy: {
                                            type: 'ajax',
                                            actionMethods: {
                                                create: 'POST',
                                                read: 'POST',
                                                update: 'POST',
                                                destroy: 'POST'
                                            },
                                            timeout: 60000,
                                            api: {
                                                create: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/create',
                                                read: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/read',
                                                update: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/update',
                                                destroy: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/destroy',
                                            },
                                            writer: {
                                                rootProperty: 'data',
                                                allowSingle: true,
                                                successProperty: 'success',
                                                messageProperty: 'errors',
                                                allDataOptions: {
                                                    associated: false
                                                },
                                                writeAllFields: true
                                            },
                                            extraParams: {
                                                spotId: record.get('SpotId')
                                            },
                                            url: Spui.Global.getApiUrl() + 'spui/SmilarSpot',
                                        },
                                    }
                                },
                                data: {
                                    SpotId: record.get('SpotId'),
                                    spotRecord: record
                                }
                            },
                        },
                        bodyPadding: 2,
                        modal: true,
                    });
                    win.show();
                },
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            //   locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa  fa-ban red',
                tooltip: tt('İptal'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeStatus(record, 'Dismiss')
                },
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            //  locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'fa fa-stack-overflow',
                tooltip: tt('Bölge onaylarını devral'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeOverRecommendation(record, 'Sub Offers')
                },
            }]
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            renderer: function (v) {
                return v * 1;
            },
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('Start Date'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('End Date'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        // {
        //     text: 'SpotId',
        //     dataIndex: 'SpotId',
        //     width: 100,
        //     format: '000',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        {
            text: tt('Status'),
            columns: [{
                    text: tt('Total'),
                    dataIndex: 'NumberOfRecommendedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('Waiting'),
                    dataIndex: 'NumberOfWaitingOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfDelegatedOrg');
                    //     r = parseInt(t) + parseInt(value);
                    //     value = Ext.util.Format.number(r, '0');
                    //     return value
                    // }
                },
                {
                    text: tt('Delegated'),
                    dataIndex: 'NumberOfDelegatedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfRecommendedOrg');
                    //     per = t === 0 ? 0 : value / t;
                    //     per = Ext.util.Format.number(per * 100, '0%');
                    //     value = Ext.util.Format.number(value, '0');
                    //     return "<b>" + value + "</b> [" + per + "]"
                    // }
                },
                {
                    text: tt('Approved'),
                    dataIndex: 'NumberOfApprovedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfRecommendedOrg');
                    //     per = t === 0 ? 0 : value / t;
                    //     per = Ext.util.Format.number(per * 100, '0%');
                    //     value = Ext.util.Format.number(value, '0');
                    //     return "<b>" + value + "</b> [" + per + "]"
                    // }
                },
                {
                    text: tt('Dismissed'),
                    dataIndex: 'NumberOfDismissedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfRecommendedOrg');
                    //     per = t === 0 ? 0 : value / t;
                    //     per = Ext.util.Format.number(per * 100, '0%');
                    //     value = Ext.util.Format.number(value, '0');
                    //     return "<b>" + value + "</b> [" + per + "]"
                    // }
                },
            ]
        },
        {
            text: tt('CreatedBy'),
            dataIndex: 'CreatedBy',
            filter: {
                type: 'string'
            },
            width: 140
        }
    ],
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        var me = this;
        Ext.Msg.show({
            title: tt('Iptal'),
            //height: 160,
            width: 500,
            msg: '<br>'+ tt('Bu kaydı iptal etmek istediğinize emin misiniz?')+'</br>',
            buttons: Ext.Msg.YESNO,
            icon: Ext.MessageBox.QUESTION,
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            fn: function (response) {
                if (response === 'yes') {
                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                        //             ALTER PROCEDURE [www].[ChangeOverRecommendation]
                        //   @SpotID AS INT ,
                        //   -- @UserID AS INT = NULL,
                        //     @Orgcode AS NVARCHAR(18)=NULL
                        method: 'POST',
                        timeout: 60000,
                        params: {
                            data: data,
                            newStatus: newStatus
                        },
                        success: function (response, opts) {
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            //me.up('spui-view-main-main').getController().changeView();
                        },
                        failure: function (response, opts) {
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            console.log('server-side failure with status code ' + response.status);
                        }
                    });
                }
            }
        });
    },
    onChangeOverRecommendation: function (record, newStatus) {
        var data = Ext.encode(record.data);
                me = this;
        Ext.Msg.show({
            title: tt('Onay'),
            //height: 160,
            width: 500,
            msg: tt('<br>Bölge onaylarını devralmak istediğinizden emin misiniz?</br>'),
            buttons: Ext.Msg.YESNO,
            icon: Ext.MessageBox.QUESTION,
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            fn: function (response) {
                if (response === 'yes') {
                    me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));
                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/changeOverRecommendation',
                        method: 'POST',
                        params: {
                            data: data,
                            newStatus: newStatus
                        },
                        success: function (response, opts) {
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            //me.up('spui-view-main-main').getController().changeView();
                        },
                        failure: function (response, opts) {
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            console.log('server-side failure with status code ' + response.status);
                        }
                    });
                }
            }
        });
    }
})
`
