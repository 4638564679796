export const setupExtJsComponents = (isReady) =>
  new Promise((resolve, reject) => {
    const result = {
      msg: 'Ext JS is not ready',
      isReady
    }

    if (!isReady) {
      reject(result)
    }
    try {
      require('./settings')
      require('./overrides')

      result.msg = 'Ext JS successfully loaded, all overrides are successfully applied.'

      resolve(result)
    } catch (err) {
      result.msg = err.toString()
      reject(result)
    }
  })
