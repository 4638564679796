export default {
  title: 'Simple',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'FilterCombobox',
  description: 'Simple combobox filter',
  iconClass: 'fa-filter',
  color: 'navy-bg',
  group: 'Filters'
}
