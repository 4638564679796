import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import IssueList from '../IssueList'
import { selectLegendResourceIssue, selectLegendProductIssue } from '../../../../actions'

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      resourceLegendData: {
        enabledResourceIssues: [],
        enabledResourceDetails: []
      },
      productLegendData: {
        enabledProductIssues: [],
        enabledProductDetails: []
      },
      resourceLegendState: {
        currentIssueIndex: 0,
        currentProductInfoIndex: 0,
        isProductInfo: false
      },
      productLegendState: {
        currentIssueIndex: 0,
        currentProductInfoIndex: 0,
        isProductInfo: false
      }
    }
  }

  componentDidMount() {
    const {
      resourcePeriodHierarchy: {
        IssueNames: resourceIssueNames = [],
        ProductIssueNames: productIssueNames = [
          'SafetyStockViolation',
          'MinCoverViolation',
          'MaxCoverViolation',
          'UnfulfilledDemand',
          'StockOut',
          'Backordered',
          'MinCoverCwkViolation',
          'MaxCoverCwkViolation'
        ]
      } = {},
      settings: {
        resourceInfoSettings = {},
        resourceIssueSettings = [],
        productInfoSettings = [],
        issueSettings: productIssueSettings = []
      } = {}
    } = this.props

    const enabledResourceIssues = _.reduce(
      resourceIssueNames,
      (enabledResourceIssues, issueName, index) => {
        const foundElement =
          _.find(resourceIssueSettings, (issue) => issue.tableName === issueName) || {}
        const { enable = true } = foundElement

        if (enable && !_.isEmpty(foundElement)) {
          foundElement.issueIndex = index
          enabledResourceIssues.push(foundElement)
        }

        return enabledResourceIssues
      },
      []
    )

    const enabledProductIssues = _.reduce(
      productIssueNames,
      (enabledProductIssues, issueName, index) => {
        const foundElement =
          _.find(productIssueSettings, (issue) => issue.tableName === issueName) || {}
        const { enable = true } = foundElement

        if (enable && !_.isEmpty(foundElement)) {
          foundElement.issueIndex = index
          enabledProductIssues.push(foundElement)
        }

        return enabledProductIssues
      },
      []
    )

    const enabledResourceDetails = _.filter(
      resourceInfoSettings,
      (productInfo) => productInfo.enable
    )
    const enabledProductDetails = _.filter(productInfoSettings, (productInfo) => productInfo.enable)

    this.setState({
      resourceLegendData: {
        enabledResourceIssues,
        enabledResourceDetails
      },
      productLegendData: {
        enabledProductIssues,
        enabledProductDetails
      }
    })
  }

  legendHandler(newState) {
    this.setState({
      resourceLegendState: { ...this.state.resourceLegendState, ...newState }
    })
    this.props.selectLegendResourceIssue(newState, this.state.resourceLegendData)
  }

  tempLegendHandler2(newState) {
    this.setState({
      productLegendState: { ...this.state.productLegendState, ...newState }
    })
    this.props.selectLegendProductIssue(newState, this.state.productLegendData)
  }

  render() {
    const { settings = {}, historyHandler = () => {} } = this.props
    const {
      resourceLegendData: { enabledResourceIssues = {}, enabledResourceDetails = {} } = {},
      productLegendData: { enabledProductIssues = {}, enabledProductDetails = {} } = {},
      resourceLegendState = {},
      productLegendState = {}
    } = this.state

    return (
      <header className="header resource-plan__header">
        <div className="rcc-wrp rccp-table-product -issue-filter">
          <IssueList
            checkboxName="ResourceIssues"
            historyHandler={historyHandler}
            infoList={enabledResourceDetails}
            issueList={enabledResourceIssues}
            legendHandler={this.legendHandler.bind(this)}
            legendState={resourceLegendState}
            settings={settings}
          />
        </div>

        <div className="rcc-wrp rccp-table-product -issue-filter">
          <IssueList
            checkboxName="ProductIssues"
            historyHandler={historyHandler}
            infoList={enabledProductDetails}
            issueList={enabledProductIssues}
            legendHandler={this.tempLegendHandler2.bind(this)}
            legendState={productLegendState}
            settings={settings}
          />
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state) => ({
  resourcePeriodHierarchy: state.resourcePeriodHierarchy
})

const mapDispatchToProps = {
  selectLegendResourceIssue,
  selectLegendProductIssue
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
