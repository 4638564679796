Ext.define('Spui.view.wizard.WizardMain', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-wizard-wizardmain',

  viewModel: 'spui-view-wizard-wizardmainviewmodel',
  controller: 'spui-view-wizard-wizardmaincontroller',
  config: {
    spotRecord: null
  },
  publishes: {
    spotRecord: true
  },
  scrollable: true,
  style: {
    'background-image':
      'linear-gradient(0deg,#f4f4f4 1.1px, transparent 0), linear-gradient(90deg,#f4f4f4 1.1px, transparent 0);',
    'background-size': '20px 20px;'
  },
  layout: 'center',

  items: [
    {
      xtype: 'spui-view-wizard-items-main'
    }
  ],
  bind: {
    spotRecord: '{spotRecord}'
  }
})
