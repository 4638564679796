import { Component } from 'react'
import JSONEditor from 'json-editor'

export default class JsonSchemaHelper extends Component {
  constructor(props) {
    super(props)

    window.JSONEditor = window.JSONEditor || JSONEditor
  }

  getValueOfSchema(schema) {
    const settings = {}

    settings.schema = schema

    this.editor = new window.JSONEditor(this.edHolder, settings)
    const value = this.editor.getValue()
    this.editor.destroy()

    return value
  }

  render() {
    const editorClass = this.props?.hidden ? 'd-none' : ''

    return (
      <div>
        <div
          ref={(input) => {
            this.edHolder = input
          }}
          className={editorClass}
        />
      </div>
    )
  }
}
