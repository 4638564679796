import { useState, useMemo, useCallback } from 'react'
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap'
import Variables from './components/Variables/index.ts'
import { SlvyFormCheck } from '../../../../components'
import { getMappedVariables } from './helpers'
import { MenusContainer, RemoteActionsContainer } from './components/Containers'
import { EventsSection, PluginsSection, ContainersSection } from './components/Sections'
import { bodyContentHeight } from './constants' // Move to the related cmps
import { IAssignees, IOnChangeParams } from '../Common.types'
import { IAssigneesProps, Tvariable } from './Assignees.types'
import { emptyArray, emptyObject } from '../../constants'

const Assignees = ({
  assignees = emptyObject as IAssignees,
  onChange,
  plugins,
  containers,
  catalog,
  menuTree,
  menuId,
  works
}: IAssigneesProps) => {
  const [activeKey, setActiveKey] = useState<string>('plugins')
  const [eventVariables, setEventVariables] = useState<Tvariable[]>([])
  const [methodVariables, setMethodVariables] = useState<Tvariable[]>([])
  const [toggleActiveMethods, setToggleActiveMethods] = useState<boolean>(false)
  const [toggleActiveEvents, setToggleActiveEvents] = useState<boolean>(false)

  const handleReturnVariableChange = useCallback(
    (parameters: IOnChangeParams) => onChange(parameters),
    [onChange]
  )

  const handleVariableAdd = useCallback((variable: Tvariable) => {
    setEventVariables((prev: Tvariable[]) => [...new Set([...prev, variable])])
    setMethodVariables((prev: Tvariable[]) => [...new Set([...prev, variable])])
  }, [])

  const handleVariableRemove = useCallback((variable: Tvariable) => {
    const filterVariable = (item: Tvariable) => item !== variable
    setEventVariables((prev: Tvariable[]) => prev.filter(filterVariable))
    setMethodVariables((prev: Tvariable[]) => prev.filter(filterVariable))
  }, [])

  const variables = useMemo(() => {
    return getMappedVariables({
      assignees,
      plugins,
      catalog,
      menuId,
      menuTree,
      works
    })
  }, [assignees, plugins, catalog, menuId, menuTree, works])

  const handleRemoveEventVariable = useCallback((variable: Tvariable) => {
    setEventVariables((prev: Tvariable[]) => prev.filter((item) => item !== variable))
  }, [])

  const handleRemoveMethodVariable = useCallback((variable: Tvariable) => {
    setMethodVariables((prev: Tvariable[]) => prev.filter((item) => item !== variable))
  }, [])

  return (
    <div className="container-fluid p-0" data-testid="assignees">
      <Row>
        <Col md={5}>
          <Card className="assignees border-0">
            <Card.Header className="d-flex justify-content-between align-items-center bg-card-header text-white border-0 rounded-0">
              <Card.Title as="h6" className="mb-0">
                Triggers
              </Card.Title>
              <SlvyFormCheck
                reverse
                checked={toggleActiveEvents}
                formCheckClass="d-flex gap-2"
                label="Show Only Actives"
                size="sm"
                type="switch"
                variant="success"
                onChange={setToggleActiveEvents}
              />
            </Card.Header>
            <Card.Body className="border-solvoyo-widget">
              <EventsSection
                assignees={assignees.events}
                handleRemoveSelectedVariable={handleRemoveEventVariable}
                handleReturnVariableChange={handleReturnVariableChange}
                plugins={plugins} // TODO: { events, id, name, type }
                selectedVariables={eventVariables}
                toggleActives={toggleActiveEvents}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card className="assignees border-end-0 border-start-0 border-top-0">
            <Card.Header className="bg-card-header text-white border-0 rounded-0">
              <Card.Title as="h6" className="mb-0">
                Variables
              </Card.Title>
            </Card.Header>
            <Card.Body className="px-0 border-end-0 border-start-0 border-solvoyo-widget">
              <Variables
                selectedEventVariables={eventVariables}
                selectedMethodVariables={methodVariables}
                variables={variables}
                onVariableAdd={handleVariableAdd}
                onVariableRemove={handleVariableRemove}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Card className="assignees border-0">
            <Card.Header className="d-flex justify-content-between align-items-center bg-card-header text-white border-0 rounded-0">
              <Card.Title as="h6" className="mb-0">
                Actions
              </Card.Title>
              <SlvyFormCheck
                reverse
                checked={toggleActiveMethods}
                formCheckClass="d-flex gap-2"
                label="Show Only Actives"
                size="sm"
                type="switch"
                variant="success"
                onChange={setToggleActiveMethods}
              />
            </Card.Header>
            <Card.Body className="border-solvoyo-widget">
              <Tab.Container activeKey={activeKey} onSelect={setActiveKey}>
                <>
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="plugins" role="button">
                        Plugins
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="containers" role="button">
                        Containers
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="menus" role="button">
                        Menus
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="remoteActions" role="button">
                        Remote Actions
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="border-0 pt-3">
                    <Tab.Pane mountOnEnter eventKey="plugins" transition={false}>
                      <PluginsSection
                        assignees={assignees.methods}
                        handleRemoveSelectedVariable={handleRemoveMethodVariable}
                        handleReturnVariableChange={handleReturnVariableChange}
                        plugins={plugins} // TODO: { events, id, name, type }
                        selectedVariables={methodVariables}
                        toggleActives={toggleActiveMethods}
                        variables={variables}
                      />
                    </Tab.Pane>
                    <Tab.Pane mountOnEnter eventKey="containers" transition={false}>
                      <div className="d-grid" style={{ height: bodyContentHeight }}>
                        <ContainersSection
                          assignees={assignees.methods}
                          containers={containers} // TODO: { methods, id, name, type }
                          handleRemoveSelectedVariable={handleRemoveMethodVariable}
                          handleReturnVariableChange={handleReturnVariableChange}
                          selectedVariables={methodVariables}
                          toggleActives={toggleActiveMethods}
                          variables={variables}
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane mountOnEnter eventKey="menus" transition={false}>
                      <div className="overflow-y-auto pe-1" style={{ height: bodyContentHeight }}>
                        <MenusContainer
                          assignees={assignees.menus}
                          handleReturnVariableChange={handleReturnVariableChange}
                          menuTree={menuTree}
                          toggleActives={toggleActiveMethods}
                          variables={variables}
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane mountOnEnter eventKey="remoteActions" transition={false}>
                      <div className="overflow-y-auto pe-1" style={{ height: bodyContentHeight }}>
                        <RemoteActionsContainer
                          assignees={assignees.menus}
                          handleReturnVariableChange={handleReturnVariableChange}
                          remoteActions={catalog?.remoteActions ?? emptyArray}
                          toggleActives={toggleActiveMethods}
                          variables={variables}
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Assignees
