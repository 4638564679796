/**
 * Created by orhan.tornaci on 11/21/2016.
 */
Ext.define('MDM.overrides.data.store', {
  override: 'Ext.data.Store',

  pCount: 0,
  extraFilter: null,
  config: {
    isChanged: false
  },
  isDirty: function () {
    var b = this.getModifiedRecords().length
    b = b || this.getNewRecords().length
    b = b || this.getRemovedRecords().length
    return !!b
  },
  groupBy: function (keyName) {
    var res = {}
    this.each(function (record) {
      var k = record.get(keyName)
      if (k !== '') {
        var v = res[k]
        if (!v) v = res[k] = []
        v.push(record.get(keyName))
      }
    })
    return Ext.Object.getKeys(res)
  },
  onBeforeLoadMe: function () {
    var me = this,
      proxy = me.getProxy()
    if (me.extraFilter) {
      var params = proxy.getExtraParams()
      Ext.apply(params, { extraFilter: Ext.encode(me.extraFilter) })
      proxy.setExtraParams(params)
    }
  },
  getDatas: function () {
    //  console.log(Ext.Array.pluck(this.store.data.items, 'data'))
    return Ext.Array.pluck(this.data.items, 'data')
  },
  flushLoad: function () {
    var me = this,
      options = me.pendingLoadOptions,
      operation

    // If it gets called programatically before the timer fired, the listener will need cancelling.
    me.clearLoadTask()
    if (!options) {
      return
    }

    me.setLoadOptions(options)

    if (me.getRemoteSort() && options.sorters) {
      me.fireEvent('beforesort', me, options.sorters)
    }

    operation = Ext.apply(
      {
        internalScope: me,
        internalCallback: me.onProxyLoad,
        scope: me
      },
      options
    )
    me.lastOptions = operation

    operation = me.createOperation('read', operation)

    if (me.fireEvent('beforeload', me, operation) !== false) {
      me.onBeforeLoad(operation)
      me.onBeforeLoadMe(operation)
      me.loading = true
      operation.execute()
    }
  }
})
