import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IState {
  show: boolean
  showConfigButton: boolean
  showLanguageModel: boolean
  helpPdfUrl: string
}

export const initialState: IState = {
  show: false,
  showConfigButton: false,
  showLanguageModel: false,
  helpPdfUrl: ''
}

export const pageConfig = createSlice({
  name: 'pageConfig',
  initialState,
  reducers: {
    togglePageConfig: (state) => {
      state.show = !state.show
    },
    showConfigButton: (state, action: PayloadAction<IState['showConfigButton']>) => {
      state.showConfigButton = action.payload
    },
    setHelpPdfUrl: (state, action: PayloadAction<IState['helpPdfUrl']>) => {
      state.helpPdfUrl = action.payload
    },
    setLanguageModel: (state, action: PayloadAction<IState['showLanguageModel']>) => {
      state.showLanguageModel = action.payload
    }
  }
})

export const { togglePageConfig, showConfigButton, setHelpPdfUrl, setLanguageModel } =
  pageConfig.actions
export default pageConfig.reducer
