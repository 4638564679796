import { useRef, useState, useEffect } from 'react'
import { Button, Modal, Overlay, Popover } from 'react-bootstrap'
import cx from 'classnames'
import withConnector from '@/BaseContainer'
import { View } from '..'
import { Permission } from '@/components'
import AddElement from '../components/AddElement'
import containerValidation from '@/utils/containerValidation'
import { PluginTypes } from '@/BasePlugin'
import { getRgbAsString } from '@/components/SlvyColorPicker/helpers'
import { defaultModalStyle, defaultColor, commonModeClasses } from './constants'
import './style.scss'

const OverlayContainer = (props) => {
  const {
    plugins,
    onMounted,
    onSave,
    data: {
      settings,
      settings: {
        title = 'title',
        height = 300,
        width = 300,
        selectedMode = 'Label',
        selectedBtnStyle = 'primary',
        backgroundColor = defaultColor,
        icon = 'fa slvy-ui-icon-check-extra-lt',
        placement = 'bottom',
        rootClose = true,
        hideMode = false,
        textAlign = 'center',
        iconPosition = 'left',
        element,
        textColor = defaultColor,
        fontSize = '16',
        padding = 0
      }
    },
    params,
    params: { designMode },
    containers,
    showAddPluginLayout,
    registerMethod
  } = props

  const [showOverlay, setShowOverlay] = useState(false)
  const [dynamicTitle, setDynamictitle] = useState('')
  const contentRef = useRef(null)

  const handleShow = () => {
    setShowOverlay(true)
  }

  const handleClose = () => {
    setShowOverlay(false)
  }

  const setTitle = ({ title: _title }) => {
    if (dynamicTitle !== _title) {
      setDynamictitle(_title)
    }
  }

  useEffect(() => {
    registerMethod({
      key: 'showOverlay',
      fn: handleShow,
      args: {}
    })
    registerMethod({
      key: 'hideOverlay',
      fn: handleClose,
      args: {}
    })
    registerMethod({
      key: 'setTitle',
      fn: setTitle,
      args: [{ name: 'title', type: PluginTypes.string }]
    })
    onMounted?.()
  }, [onMounted, registerMethod])

  const handleToggleOverlay = () => {
    setShowOverlay((prevState) => !prevState)
  }

  const saveLayout = (newSettings) => {
    const finalSettings = { ...settings, ...newSettings }
    const { element: { id = '' } = {} } = finalSettings
    onSave(finalSettings, id ? [id] : [])
  }

  const afterAddPlugin = (id, type) => {
    if (!containerValidation(containers, id)) {
      return
    }
    saveLayout({ element: { id, type } })
  }

  const handlePluginMounted = () => {
    onMounted?.()
  }

  const handlePluginRemove = () => {
    saveLayout({ element: undefined })
  }

  const handleAddPlugin = () => {
    showAddPluginLayout(afterAddPlugin)
  }

  const getContentStyle = () => {
    const commonStyles = {
      fontSize: `${fontSize}px`,
      padding: `${padding}px`
    }

    if (selectedMode === 'Button') {
      return commonStyles
    }

    return {
      ...commonStyles,
      color: getRgbAsString(textColor)
    }
  }

  const background = getRgbAsString(backgroundColor)
  const contentStyle = getContentStyle()
  const contentTitle = dynamicTitle?.length ? dynamicTitle : title
  const splittedTitle = contentTitle?.split('#BR#') || []
  const isOverlayNotVisible = hideMode && !showOverlay

  const overlaySize = {
    height: `${height}px`,
    width: `${width}px`
  }

  const view = element ? (
    <View
      containers={containers}
      id={element.id}
      params={params}
      plugins={plugins}
      type={element.type}
      onMounted={handlePluginMounted}
      onRemovePlugin={handlePluginRemove}
    />
  ) : null

  const popoverClass = showOverlay ? 'd-block' : 'overlay-modal-hidden'
  const showPopoverOnHideMode = hideMode && popoverClass
  const popover = (
    <Popover className={cx('mw-100', 'overlay-container-popover', showPopoverOnHideMode)}>
      <Popover.Body className="p-0" style={overlaySize}>
        {view}
      </Popover.Body>
    </Popover>
  )

  const lines = (
    <div className="d-flex flex-column text-start lh-1">
      {splittedTitle.map((line, idx) => (
        <span key={idx}>{line}</span>
      ))}
    </div>
  )

  const textContent = (
    <>
      {iconPosition === 'right' ? (
        <>
          {lines}
          {icon ? <i className={cx(icon, 'fs-6')} /> : null}
        </>
      ) : (
        <>
          {icon ? <i className={cx(icon, 'fs-6')} /> : null}
          {lines}
        </>
      )}
    </>
  )

  const content = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div ref={contentRef} className="h-100" style={contentStyle} onClick={handleToggleOverlay}>
      {selectedMode === 'Label' ? (
        <div
          className={cx(commonModeClasses, 'h-100 content-label', `justify-content-${textAlign}`)}
          style={{ background }}
        >
          {textContent}
        </div>
      ) : null}
      {selectedMode === 'Button' ? (
        <Button
          className={cx(
            'w-100',
            'h-100',
            'content-btn',
            commonModeClasses,
            `justify-content-${textAlign}`
          )}
          variant={selectedBtnStyle}
        >
          {textContent}
        </Button>
      ) : null}
    </div>
  )

  const modalStyle = { ...overlaySize, ...defaultModalStyle }
  return (
    <>
      {content}
      {designMode ? (
        <>
          <Modal
            backdropClassName={cx({ 'hideMode-backdrop': isOverlayNotVisible })}
            className={cx('popupcontainer', 'p-0', 'm-auto', {
              'hideMode-dialog': isOverlayNotVisible
            })}
            enforceFocus={false}
            show={hideMode ? true : showOverlay}
            size="xl"
            style={modalStyle}
            onHide={handleClose}
          >
            <Modal.Body className="p-0 overlay-modal-body">
              {view || (
                <Permission has={['Plugin.Add']}>
                  <AddElement handleAddElement={handleAddPlugin} />
                </Permission>
              )}
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Overlay
            placement={placement}
            rootClose={hideMode ? false : rootClose}
            show={hideMode ? true : showOverlay}
            target={contentRef.current}
            onHide={handleClose}
          >
            {popover}
          </Overlay>
        </>
      )}
    </>
  )
}

export default withConnector(OverlayContainer)
