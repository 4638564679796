Ext.define('Spui.view.common.productinfo.cmp.search.SearchCombo', {
  extend: 'Ext.form.field.ComboBox',
  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-search-searchcombo',
  emptyText: tt('Search'),
  store: {
    type: 'material-search',
    pageSize: 10,
    autoLoad: true
  },
  width: '98%',
  queryMode: 'remote',
  style: {
    margin: 0
  },
  typeAhead: true,
  valueField: 'MaterialNumber',
  displayField: 'MaterialDescription',
  selectOnFocus: true,
  doRemoteQuery: function (queryPlan) {
    var me = this,
      loadCallback = function () {
        if (!me.destroyed) {
          me.afterQuery(queryPlan)
        }
      }
    // expand before loading so LoadMask can position itself correctly
    me.expand()
    // In queryMode: 'remote', we assume Store filters are added by the developer as remote filters,
    // and these are automatically passed as params with every load call, so we do *not* call clearFilter.
    if (me.pageSize) {
      // if we're paging, we've changed the query so start at page 1.
      me.loadPage(1, {
        rawQuery: queryPlan.rawQuery,
        callback: loadCallback
      })
    } else {
      me.store.load({
        params: me.getParams(queryPlan.query),
        rawQuery: queryPlan.rawQuery,
        callback: loadCallback
      })
    }
  },
  listConfig: {
    loadingText: 'Searching...',
    loadMask: true,
    emptyText: 'No matching posts found.',
    itemSelector: '.search-item',
    // Custom rendering template for each item
    itemTpl: [
      '<div class="search-item" >',
      '<h3><span>{MaterialCode}<br /> -</span>{MaterialDescription}</h3>',
      '{H1N}-{H2N}-{H3N}',
      '</div>'
    ]
  },
  autoSelect: false,
  forceSelection: false
})
