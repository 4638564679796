import { Component } from 'react'
import { connect } from 'react-redux'
import { fetchPivotConfiguration, setLoader as setActionLoader } from '../store/actions'
import { setLoader, _errorHandler } from '../utils'
import { MESSAGES } from '../messages'

class FetchPivotConfiguration extends Component {
  toggleLoader(isVisible = false, msg = MESSAGES.loading) {
    setLoader(this.props.setActionLoader, isVisible, msg)
  }

  _fetchPivotConfiguration() {
    const {
      props: {
        defaultActionParams: { payload = {} },
        selectedScenarioTree: { currentScenarios = [] },
        mfpSettings: { mfpId } = {}
      }
    } = this

    const plannedId = [...currentScenarios].filter((item) => item.scenarioType === 'Planned')[0]
      .currentScenario.Id

    this.toggleLoader(true, MESSAGES.loading)

    const promise = this.props.fetchPivotConfiguration({
      payload: {
        ...payload,
        method: 'GetPivotConfiguration',
        requestMethod: 'post',
        body: {
          mfpId,
          scenarioId: Number(plannedId)
        }
      }
    })

    promise
      .then((pivotConfigurationResponse) =>
        _errorHandler(pivotConfigurationResponse, 'GetPivotConfiguration')
      )
      .catch((pivotConfigurationError) =>
        _errorHandler(pivotConfigurationError, 'GetPivotConfiguration')
      )

    return promise
  }

  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return this.props.children({
      _fetchPivotConfiguration: this._fetchPivotConfiguration.bind(this)
    })
  }
}

const mapStateToProps = (state) => {
  return {
    GetPivotConfiguration: state.getPivotConfiguration.GetPivotConfiguration,
    selectedScenarioTree: state.scenarioTreeSelection.scenarioTree,
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    Loader: state.loader.loader,
    mfpSettings: state.mfpSettings.mfpSettings
  }
}

const mapDispatchToProps = {
  fetchPivotConfiguration,
  setActionLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchPivotConfiguration)
