import { useMemo } from 'react'
import { getExportableColumnDefs } from '../../helpers'

import { DEFAULT_EXCEL_COLUMN_WIDTH } from '../../constants'
import { EmptyObject, SlvyColGroupDef } from '../../types'
import {
  DefaultExcelExportProps,
  DefaultExcelExportParamsReturnType
} from './useDefaultExcelExportParams.types'
import { AG_GRID_ENUMS } from '../../enums'

const { AUTO_GROUP_COLUMN } = AG_GRID_ENUMS

function useDefaultExcelExportParams({
  gridRef,
  grouping,
  groupDisplayType,
  columnDefs,
  exportable
}: DefaultExcelExportProps) {
  const { sheet: sheetLocale } = gridRef?.current?.api?.getGridOption?.('localeText') || {}

  const defaultExcelExportParams = useMemo((): DefaultExcelExportParamsReturnType | EmptyObject => {
    if (!exportable) {
      return {}
    }

    const columnKeys = getExportableColumnDefs(columnDefs as SlvyColGroupDef[]).map(
      ({ field }) => field as string
    )

    if (grouping && groupDisplayType === 'singleColumn') {
      columnKeys.unshift(AUTO_GROUP_COLUMN)
    }

    return {
      defaultExcelExportParams: {
        sheetName: sheetLocale,
        columnKeys,
        columnWidth: ({ column }) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return column?.actualWidth || DEFAULT_EXCEL_COLUMN_WIDTH
        }
      }
    }
  }, [columnDefs, exportable, groupDisplayType, grouping, sheetLocale])

  return { defaultExcelExportParams }
}

export default useDefaultExcelExportParams
