Ext.define('Spui.view.common.productinfo.cmp.search.SearchBox', {
  extend: 'Ext.Container',
  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-search-searchbox',
  height: 40,
  defaultListenerScope: true,
  config: {
    searchComboCfg: 'searchCombo',
    searchCombo: {}
  },
  style: {
    padding: '1px',
    background: '#E6E6E6',
    border: '1px solid #ccc'
  },
  layout: 'fit',
  initComponent: function () {
    var me = this
    me.plugins = [
      {
        ptype: 'cornerIcon',
        offSet: {
          top: 0,
          right: 120
        },
        cfgButton: [
          '->',
          {
            iconCls: 'x-fa fa-cog',
            cls: 'config-btn',
            handler: this.onConfigureSearchCombo,
            scope: me
          }
        ]
      }
    ]
    me.callParent()
  },
  applySearchComboCfg: function (c) {
    return window.ss(c)
  },
  applySearchCombo: function (b) {
    var setRec = this.getSearchComboCfg()
    if (!setRec) {
      this.setDataView('test')
      setRec = this.getDataView()
    }
    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        xtype: 'spui-view-common-productinfo-cmp-search-searchcombo'
      })
      b = Ext.create(b)
    }
    return b
  },
  updateSearchCombo: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigureSearchCombo: function () {
    var setRec = this.getSearchComboCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onSearchComboCfgSave, this)
  },
  onSearchComboCfgSave: function (v, k) {
    var setRec = this.getSearchComboCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setSearchCombo(res)
  }
})
