import { MESSAGES } from './messages'

export default [
  {
    name: MESSAGES.view,
    value: 0
  },
  {
    name: MESSAGES.edit,
    value: 2
  }
]
