import { useEffect, useRef } from 'react'
import PluginUpdateQueue from '../helpers/PluginUpdateQueue'
import { useInterval } from './index'

const useUpdateInProgress = (callback: (val: boolean) => void, delay: number) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useInterval(() => {
    savedCallback.current(PluginUpdateQueue.updateInProgress())
  }, delay)
}

export default useUpdateInProgress
