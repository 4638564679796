Ext.define('MDM.src.view.regionapp.Cmp.SearchBox', {
  extend: 'Ext.window.Window',
  bodyPadding: 4,
  closable: false,
  height: '70%',
  iconCls: 'fa fa-trash-o',
  layout: 'fit',
  listeners: {},
  maximizable: true,
  modal: true,
  stateId: 'DropDialog',
  statuful: true,
  title: 'Search Material',
  ui: 'window-inverse',
  userMegaRegion: '',
  width: '70%',
  bbar: [
    '->',
    {
      text: 'Close',
      ui: 'btn-success',
      handler: function (btn) {
        btn.up('window').close()
      }
    }
  ],

  initComponent: function () {
    const me = this
    me.tbar = [
      {
        xtype: 'textfield',
        name: 'searchField',
        hideLabel: true,
        width: 500,
        emptyText: 'Enter search term',
        listeners: {
          specialkey: 'applySearchFilter',
          change: function (field, value) {
            field.getTrigger('clear')[value ? 'show' : 'hide']()
          },
          scope: me
        },
        triggers: {
          clear: {
            cls: 'x-form-clear-trigger',
            handler: 'onClearTriggerClick',
            hidden: true,
            scope: me
          },
          search: {
            cls: 'x-form-search-trigger',
            weight: 1,
            handler: 'onSearchTriggerClick',
            scope: me
          }
        }
      }
    ]
    me.items = [
      {
        xtype: 'regionapp-searckbox-grid',
        store: me.getStore()
      }
    ]
    me.callParent()
  },

  onClearTriggerClick: function (field) {
    const me = this
    const store = me.store
    store.removeAll()
    field.setValue()
    field.getTrigger('clear').hide()
  },

  applySearchFilter: function (field, event) {
    // e.HOME, e.END, e.PAGE_UP, e.PAGE_DOWN,
    // e.TAB, e.ESC, arrow keys: e.LEFT, e.RIGHT, e.UP, e.DOWN
    const me = this
    const store = me.store

    if (event.getKey() == event.ENTER) {
      store.getProxy().setExtraParam('queryString', field.getValue())
      store.load()
    }
    if (event.getKey() == event.TAB) {
      store.getProxy().setExtraParam('queryString', field.getValue())
      store.load()
    }
  },

  onSearchTriggerClick: function (field) {
    const me = this
    const store = me.store
    store.getProxy().setExtraParam('queryString', field.getValue())
    store.load()
  },

  onExtractDetails: function (grid, rowIndex, colIndex, item, e, record) {
    const win = Ext.create('MDM.src.view.regionapp.Cmp.ExtractDialog', {
      record: record
    })
    win.show()
  },

  getStore: function () {
    this.store = Ext.create('Ext.data.Store', {
      model: 'MDM.src.view.regionapp.model.MaterialAssigned',
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/RMD_Material_Search'
      },
      autoLoad: false
    })
    return this.store
  },

  onEditMaterialp: function (grid, rowIndex, colIndex, item, e, record) {
    const me = this
    const viewModel = me.getViewModel()
    viewModel.set('rec', record)
    const newview = Ext.create('MDM.src.view.materialedit.Main', {
      listeners: {
        updated: function () {}
      },
      modal: true,
      crec: record
    })
    newview.show()
  },

  onDrop: function (grid, rowIndex, colIndex, item, e, record) {
    const me = this
    const title = record.get('MaterialDescription')

    Ext.Msg.show({
      title: 'Suppress material',
      width: 500,
      msg:
        '<br> Are you sure you want to Suppress ' +
        title +
        ' from all lists and make it inactive ? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDrop(record, grid)
        }
      }
    })
  },

  doDrop: function (record) {
    const me = this
    me.setLoading('tests')

    Ext.MessageBox.prompt('Suppress Reason', 'Reason:', function (btn, text) {
      Ext.Ajax.request({
        method: 'POST',
        url: MDM.Global.getApiUrl() + 'gillettev3/RegionApp_MDM_Material_Drop',
        params: {
          MaterialCode: record.get('MaterialCode'),
          Reason: text
        },
        success: function () {
          me.setLoading(false)
          me.storeLoad()
        },
        failure: function () {
          me.setLoading(false)
          Ext.MessageBox.hide()
        }
      })
    })
  }
})
