import React, { memo } from 'react'
import { isArray } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Popover } from 'react-bootstrap'

function AssortmentPopover(props) {
  const { title = '', content = [] } = props

  return (
    <Popover {...props} id={`assortment-popover-${uuidv4()}-${title}`}>
      <Popover.Header as="h3" className="mb-0 pb-2">
        {title}
      </Popover.Header>
      {isArray(content) ? (
        <ul className="list-group border-0">
          {content.map((item, index) => (
            <li key={index} className="list-group-item">
              {item}
            </li>
          ))}
        </ul>
      ) : (
        content
      )}
    </Popover>
  )
}

export default memo(AssortmentPopover, (prevProps, nextProps) => !_.isEqual(nextProps, prevProps))
