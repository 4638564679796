/**
 * Created by orhan.tornaci on 11/21/2016.
 */
Ext.define('Assortment.overrides.data.store', {
  override: 'Ext.data.Store',
  pCount: 0,
  extraFilter: null,
  config: {
    isChanged: false
  },
  getDatas: function () {
    return Ext.Array.pluck(this.data.items, 'data')
  }
})
