import * as actionTypes from './actionTypes'

export const toggleHierarchy = (resourceRow) => ({
  type: actionTypes.TOGGLE_HIERARCHY,
  resourceRow
})

export const expandHierarchy = (resourceRow) => ({
  type: actionTypes.EXPAND_HIERARCHY,
  resourceRow
})

export const expandAllHierarchies = () => ({
  type: actionTypes.EXPAND_ALL_HIERARCHIES
})

export const resetHierarchy = () => ({
  type: actionTypes.RESET_HIERARCHY
})

// new product view
export const PRODUCTtoggleHierarchy = (resourceRow) => ({
  type: actionTypes.PRODUCT_TOGGLE_HIERARCHY,
  resourceRow
})

export const PRODUCTexpandHierarchy = (resourceRow) => ({
  type: actionTypes.PRODUCT_EXPAND_HIERARCHY,
  resourceRow
})

export const PRODUCTexpandAllHierarchies = () => ({
  type: actionTypes.PRODUCT_EXPAND_ALL_HIERARCHIES
})

export const PRODUCTresetHierarchy = () => ({
  type: actionTypes.PRODUCT_RESET_HIERARCHY
})
