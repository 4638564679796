Ext.define('Spui.view.dd.saleschart.RatioChart', {
  extend: 'Chart.ux.Highcharts',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-dd-saleschart-ratiochart',

  // Uncomment to give this component an xtype

  border: true,

  margin: '10,10,10,10',
  defaultListenerScope: true,
  loadMaskMsg: 'Chart data is looading.....',
  bodyStyle: {
    background: '#ffc',
    border: '2px #ccc solid'
  },
  config: {
    record: null
  },
  initComponent: function () {
    this.callParent()
  },
  series: [
    {
      dataIndex: 'Qty',
      type: 'column',
      name: tt('DaylyPercent'),
      color: 'rgba(236,17,92,1)',
      listeners: {
        pointclick: function (serie, point, record, event) {
          var panel = serie.chart
          panel.fireEvent('clickChart', record)
        }
      }
    }
  ],
  xField: 'Ratio',
  colorDelta: 0.07,
  chartConfig: {
    chart: {
      type: 'column',
      alignTicks: true,
      marginRight: 20,
      marginBottom: 100,
      zoomType: 'xy',

      dataLabels: {
        enabled: true
      }
    },
    credits: {
      text: 'SOLVOYO © 2021',
      href: 'http://www.solvoyo.com',
      position: {
        align: 'right',
        y: -5 // position of credits
      },
      style: {
        fontSize: '11pt' // you can style it!
      }
    },
    tooltip: {
      formatter: function () {
        var s = '<b>' + this.x + '</b>'

        this.points.forEach(function (p) {
          s += '<br/>' + p.series.name + ': ' + Ext.util.Format.number(p.y, '0.##')
        })

        return s
      },
      shared: true
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      },
      series: {
        point: {
          events: {
            drop: function () {}
          }
        },
        stickyTracking: false
      }
    },
    navigation: {
      buttonOptions: {
        align: 'left'
      }
    },
    title: {
      text: tt('Normalized Chart'),

      style: {
        fontSize: '18px',
        fontWeight: 'bold'
      }
    },

    xAxis: {
      labels: {
        style: {
          fontSize: '13px'
        },
        rotation: 315,
        y: 35
      }
    },
    yAxis: {
      allowDecimals: false,

      title: {
        text: tt('PercentofSales')
      },

      labels: {
        formatter: function () {
          // Use thousands separator for four-digit numbers too
          //	return Ext.util.Format.number(this.value * 100, '0.##%');
        }
      }
    }
  },
  setIsMax: function (c) {
    var me = this
    if (c) {
      me.chartConfig.title.style.fontSize = '24px'
      //   me.chartConfig.subtitle.style.fontSize = "20px";
      me.chartConfig.xAxis.labels.style.fontSize = '16px'
      me.chartConfig.yAxis[0].labels.style.fontSize = '16px'
      me.chartConfig.legend = {
        itemStyle: {
          fontSize: '16px'
        }
      }
    } else {
      me.chartConfig.title.style.fontSize = '14px'
      me.chartConfig.xAxis.labels.style.fontSize = '13px'
      me.chartConfig.yAxis[0].labels.style.fontSize = '13px'
      me.chartConfig.legend = {
        itemStyle: {
          fontSize: '13px'
        }
      }
    }
    me.draw()
  },
  setStore: function (s) {
    this.bindStore(s)
  },
  afterChartRendered: function (d) {
    this.fireEvent('chartRender', this, d)
  },
  buildStore: function () {
    if (this.store) return this.store
    this.store = Ext.create('Ext.data.Store', {
      fields: [
        { name: 'QTY', type: 'number' },
        { name: 'Price', type: 'number' },
        { name: 'NumberOfStore', type: 'number' },
        { name: 'Envanter', type: 'number' },
        { name: 'DateKey', type: 'string' },
        {
          name: 'PriceNull',
          convert: function (value, record) {
            var v = record.get('Price')

            if (!v) return null
            return v
          },

          depends: ['Price']
        },
        {
          name: 'NumberOfStoreNull',
          convert: function (value, record) {
            var v = record.get('NumberOfStore')

            if (!v) return null
            return v
          },

          depends: ['NumberOfStore']
        }
      ],

      proxy: {
        type: 'ajax',
        timeout: 120000,
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        extraParams: {
          MaterialCode: this.MaterialCode
        },
        url: Spui.Global.getApiUrl() + 'spui/GetSalesData'
      },
      autoLoad: true
    })
    return this.store
  },

  onClickPieChart: function (serie, point, record, event) {
    var store = Ext.getStore('SpotModel'),
      win = Ext.create('Spui.view.spotanaliz.pricedetails.PriceDetails', {
        DateKey: record.get('DateKey'),
        MaterialCode: record.get('MaterialCode'),
        record: record,
        title:
          this.chart.MaterialCode +
          ' -' +
          record.get('MaterialDescription') +
          ' ' +
          record.get('DateKey') +
          ' ' +
          record.get('DayNameTR') +
          ' Gunu Satışları'
      })
    win.show()

    this.chart.fireEvent('clickChart', record, event)
  }
})
