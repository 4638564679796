Ext.define('Assortment.cmp.TestOptionGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'testOptionGrid',
  columnLines: true,
  rowLines: true,
  listeners: {
    select: 'onTestOptionSelected',
    celldblclick: 'onSimilarProductSelected'
  },
  enableLocking: true,
  viewConfig: {
    enableTextSelection: true
  },
  columns: [
    {
      text: 'Id',
      dataIndex: 'id',
      width: 95,
      hidden: true,
      hideable: false
    },
    {
      text: 'Name',
      dataIndex: 'name',
      width: 95,
      locked: true
    },
    {
      text: 'Price',
      dataIndex: 'price',
      width: 95,
      align: 'right'
    },
    {
      text: 'Cost',
      dataIndex: 'cost',
      width: 95,
      align: 'right'
    },
    {
      text: 'Division',
      dataIndex: 'division',
      width: 95
    },
    {
      text: 'Subdivision',
      dataIndex: 'subdivision',
      width: 95
    },
    {
      text: 'Age',
      dataIndex: 'age',
      width: 95
    },
    {
      text: 'Life Style',
      dataIndex: 'lifeStyle',
      width: 95
    },
    {
      text: 'Collection Group',
      tooltip: 'Collection Group',
      dataIndex: 'collectionGroup',
      width: 95
    },
    {
      text: 'BuyerGroup',
      dataIndex: 'buyerGroup',
      width: 95
    },
    {
      text: 'Class',
      dataIndex: 'class',
      width: 95
    },
    {
      text: 'Range',
      dataIndex: 'range',
      width: 95
    },
    {
      text: 'Season',
      dataIndex: 'season',
      width: 95
    },
    {
      text: 'Color',
      dataIndex: 'color',
      width: 95
    },
    {
      text: 'Price Levels',
      dataIndex: 'priceLevels',
      width: 95
    }
  ]
})
