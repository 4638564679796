import React, { Component } from 'react'
import _ from 'lodash'
import numeral from 'numeral'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './style.scss'

class ExecutiveDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: [],
      selectedFlipIndex: [],
      title: ''
    }

    this.changeSelectedIndexState = this.changeSelectedIndexState.bind(this)
  }

  componentDidMount() {
    const eventList = [
      {
        key: 'TrendClicked',
        returnTypes: {
          VerticalIndex: PluginTypes.int,
          HorizontalIndex: PluginTypes.int,
          TargetValue: PluginTypes.int,
          ActualValue: PluginTypes.int,
          Trend: PluginTypes.int
        }
      },
      { key: 'SubTitleClicked' },
      { key: 'BarClicked' },
      { key: 'BarValueClicked' },
      { key: 'BarTargetValueClicked' }
    ]

    _.forEach(eventList, (event) => {
      const { key = '', returnTypes = { parameters: PluginTypes.object } } = event || {}
      const targetEvent = _.camelCase(key)
      this[targetEvent] = this.props.registerEvent({
        key,
        fn: this[targetEvent].bind(this),
        returnTypes
      })
    })

    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })
  }

  setTitle(params) {
    if (this.state.title !== params.title) {
      this.setState({ title: params.title })
    }
  }

  getColors(settings = [], index = 0) {
    const {
      firstColor = '#ed1d54',
      secondColor = '#67658b',
      thirdColor = '#00eba5'
    } = settings[index] || {}
    return {
      firstColor,
      secondColor,
      thirdColor
    }
  }

  sortColors({ firstColor, secondColor, thirdColor }, isAscending = 1) {
    return {
      firstColor: isAscending ? firstColor : thirdColor,
      secondColor,
      thirdColor: isAscending ? thirdColor : firstColor
    }
  }

  generateStyle(
    { firstColor, secondColor, thirdColor },
    { targetPercentage = 50, targetMarginLeft = 25, targetMarginRight = 25 },
    isAscending = 1,
    deg = '90deg'
  ) {
    const isRange = isAscending === 2

    return {
      background: `linear-gradient(
        ${deg},
        ${firstColor} 0%,
        ${isRange ? `${secondColor} ${targetMarginLeft}%,` : ''}
        ${isRange ? thirdColor : secondColor} ${targetPercentage}%,
        ${isRange ? `${secondColor} ${targetMarginRight}%,` : ''}
        ${isRange ? firstColor : thirdColor} 100%
      )`
    }
  }

  getTrendClass(value, isAscending = 1) {
    let className = ''
    const directionName = isAscending ? '-asc' : '-desc'

    switch (true) {
      case value === 0:
        className = '-flat'
        break
      case value > 0:
        className = `-up ${directionName}`
        break
      case value < 0:
        className = `-down ${directionName}`
        break
      default:
        className = ''
        break
    }

    return className
  }

  calculatePercentage = (min, max, input) =>
    input < min ? 0 : input > max ? 100 : ((input - min) * 100) / (max - min)

  valueFormatter = (value, format) => numeral(value).format(format)

  getSelectedIndex = (arr, index) => arr.find((item) => item === index)

  getValue = (dataSource = [], column = '', value = '') => dataSource[column] || value

  changeSelectedIndexState = (arr, index) => {
    const foundIndex = this.getSelectedIndex(arr, index)
    const { settings: { config: { dashboardSettings: { singleFocus = true } = {} } = {} } = {} } =
      this.props

    arr =
      foundIndex === index
        ? _.remove(arr, (item) => item !== index)
        : singleFocus
        ? [index]
        : _.concat(arr, index)

    return arr
  }

  formatFields(matchedField = {}) {
    const {
      dataTrendValue,
      dataTrendValueFormat,
      dataActualValue = 0,
      dataValueFormat,
      dataTargetValue = 0,
      dataStartPoint = 0,
      dataEndPoint = 1,
      dataDetailValue = 0,
      dataDetailValueFormat,
      dataTargetMarginLeft = 0,
      dataTargetMarginRight = 0
    } = matchedField

    return {
      ...matchedField,
      trendValue: this.valueFormatter(dataTrendValue, dataTrendValueFormat),
      value: this.valueFormatter(dataActualValue, dataValueFormat),
      targetValue: this.valueFormatter(dataTargetValue, dataValueFormat),
      actualPercentage: this.calculatePercentage(dataStartPoint, dataEndPoint, dataActualValue),
      targetPercentage: this.calculatePercentage(dataStartPoint, dataEndPoint, dataTargetValue),
      detailValue: this.valueFormatter(dataDetailValue, dataDetailValueFormat),

      targetMarginLeftValue: this.valueFormatter(dataTargetMarginLeft, dataValueFormat),
      targetMarginRightValue: this.valueFormatter(dataTargetMarginRight, dataValueFormat),
      targetMarginLeft: this.calculatePercentage(
        dataStartPoint,
        dataEndPoint,
        dataTargetMarginLeft
      ),
      targetMarginRight: this.calculatePercentage(
        dataStartPoint,
        dataEndPoint,
        dataTargetMarginRight
      )
    }
  }

  matchSettings(pluginData = [], { settings = {}, pluginDataSettings = {} } = {}) {
    return _.map(pluginData, (item) => {
      const matchedField = _.transform(
        pluginDataSettings,
        (obj, value, key) => {
          const fieldValue = this.getValue(item, value, settings[key])

          if (fieldValue) {
            obj[key] = fieldValue
          }

          return obj
        },
        {}
      )

      return {
        ...item,
        RenderItem: this.formatFields(matchedField)
      }
    })
  }

  getData(pluginData = [], matchSettings = {}) {
    pluginData = this.matchSettings(pluginData, matchSettings)

    const {
      pluginDataSettings: {
        dataTopRowIndex = 'VerticalIndex',
        dataTopColumnIndex = 'HorizontalIndex',
        dataDetailRowIndex = 'DetailsRowIndex',
        dataDetailColumnIndex = 'DetailsColumnIndex'
      } = {}
    } = matchSettings
    const groupByRowIndex = _.groupBy(pluginData, dataTopRowIndex)
    const groupByColumnIndex = _.map(groupByRowIndex, (item) => _.groupBy(item, dataTopColumnIndex))
    const retObj = _.map(groupByColumnIndex, (item) => {
      const [{ RenderItem: { dataTitle: Title = '' } } = {}] = item[0] || []
      const Data =
        _.map(
          item,
          (item) =>
            _.map(
              _.groupBy(
                _.sortBy(item, (sbitem) => sbitem[dataDetailColumnIndex]),
                dataDetailRowIndex
              )
            ),
          (subItem) => subItem
        ) || []

      return {
        Title,
        Data
      }
    })

    return retObj
  }

  containerClick(arr, index, e) {
    e.stopPropagation()
    this.setState({ selectedIndex: this.changeSelectedIndexState(arr, index) })
  }

  containerFlipClick(arr, index, e) {
    e.stopPropagation()
    this.setState({ selectedFlipIndex: this.changeSelectedIndexState(arr, index) })
  }

  clickHandler(params, functionName, e) {
    e.stopPropagation()
    const _f = this[_.camelCase(functionName)] || (() => {})

    _f(params)
  }

  trendClicked = (parameters) => parameters

  subTitleClicked = (parameters) => parameters

  barClicked = (parameters) => parameters

  barValueClicked = (parameters) => parameters

  barTargetValueClicked = (parameters) => parameters

  columnStyleGenerator = (columnSize) => {
    const calculatedColumWidth = 100 / columnSize

    return {
      gridTemplateColumns: _.map(new Array(columnSize), () => `${calculatedColumWidth}%`).join(' ')
    }
  }

  render() {
    const {
      state: { selectedIndex = [], selectedFlipIndex = [], title = '' } = {},
      props: {
        pluginData = [],

        settings: {
          config: {
            general: { title: generalTitle = '' } = {},
            colorPalette: { colorField = '', colors = [] } = {},
            data: pluginDataSettings = {},
            settings = {},
            dashboardSettings: {
              backgroundColor = 'white',
              isFlippable = true,
              columnSize = 4
            } = {}
          } = {}
        } = {}
      } = {}
    } = this

    let $title = title || generalTitle
    $title = _.isString($title) ? $title.trim() : ''

    const getData = this.getData(pluginData, { settings, pluginDataSettings })
    const columnStyle = this.columnStyleGenerator(columnSize)

    return (
      <div className={`executive-dashboard ${$title ? '-title' : ''}`} style={{ backgroundColor }}>
        {$title ? <h2 className="general-title">{$title}</h2> : null}
        <div className="container-holder" style={columnStyle}>
          {_.map(getData, ({ Title = '', Data = [] }, index) => {
            const foundIndex = this.getSelectedIndex(selectedIndex, index) === index
            const foundFlipIndex = this.getSelectedIndex(selectedFlipIndex, index) === index

            return (
              <div
                key={index}
                className={`ed-card-container ${foundIndex ? '-active' : ''} ${
                  foundFlipIndex ? '-flip-active' : ''
                }`}
                tabIndex={index}
                onClick={
                  !foundFlipIndex ? this.containerClick.bind(this, selectedIndex, index) : () => {}
                }
              >
                <div className="ed-card-flip">
                  <div className="face -front">
                    <div className="ed-card">
                      <h2 className="ed-cardTitle">
                        {Title}
                        {isFlippable && (
                          <div
                            className="ed-cardAction flipper-back"
                            onClick={this.containerFlipClick.bind(this, selectedFlipIndex, index)}
                          >
                            <i className="slvy-ui-icon-rotate-right" />
                          </div>
                        )}
                      </h2>

                      <div className="ed-card-wrp">
                        {_.map(Data, ([item = []], subIndex) => {
                          const [currentItem = {}] = item
                          const {
                            IsAscending = true,
                            RenderItem: {
                              dataSubTitle,
                              dataTrendValue,
                              trendValue,
                              value,
                              targetValue,
                              actualPercentage,
                              targetPercentage,
                              targetMarginLeftValue,
                              targetMarginRightValue,
                              targetMarginLeft,
                              targetMarginRight
                            } = {}
                          } = currentItem

                          const trendClass = this.getTrendClass(dataTrendValue, IsAscending)
                          const barStyle = this.generateStyle(
                            this.sortColors(
                              this.getColors(colors, currentItem[colorField]),
                              IsAscending
                            ),
                            { targetPercentage, targetMarginLeft, targetMarginRight },
                            IsAscending
                          )

                          const {
                            VerticalIndex = 0,
                            HorizontalIndex = 0,
                            TargetValue = 0,
                            ActualValue = 0,
                            Trend = 0
                          } = currentItem
                          const trendParams = {
                            VerticalIndex,
                            HorizontalIndex,
                            TargetValue,
                            ActualValue,
                            Trend
                          }

                          return (
                            <div key={subIndex} className="ed-cardRow">
                              <div className="trend">
                                <p
                                  className={trendClass}
                                  onClick={
                                    foundIndex
                                      ? this.clickHandler.bind(this, trendParams, 'TrendClicked')
                                      : () => {}
                                  }
                                >
                                  {trendValue}
                                </p>
                              </div>
                              <div className="rowTitle">{dataSubTitle}</div>

                              <div className="barContainer">
                                <div className="barWrp">
                                  <div className="current" style={{ left: `${actualPercentage}%` }}>
                                    <p>{value}</p>
                                    <div className="indicator -down" />
                                  </div>

                                  <div className="barGraph" style={barStyle} />

                                  {IsAscending === 2 ? (
                                    [
                                      <div
                                        key={`range-1-${index}`}
                                        className="target range-in"
                                        style={{ left: `${targetMarginLeft}%` }}
                                      >
                                        <div className="indicator -up" />
                                        <p>{targetMarginLeftValue}</p>
                                      </div>,
                                      <div
                                        key={`range-2-${index}`}
                                        className="target range-out"
                                        style={{ left: `${targetMarginRight}%` }}
                                      >
                                        <div className="indicator -up" />
                                        <p>{targetMarginRightValue}</p>
                                      </div>
                                    ]
                                  ) : (
                                    <div
                                      className="target"
                                      style={{ left: `${targetPercentage}%` }}
                                    >
                                      <div className="indicator -up" />
                                      <p>{targetValue}</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="rowSeparator" />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="face -back">
                    <div className="ed-card-back">
                      <h2 className="ed-cardTitle">
                        {Title}
                        <div
                          className="ed-cardAction flipper-front"
                          onClick={this.containerFlipClick.bind(this, selectedFlipIndex, index)}
                        >
                          <i className="slvy-ui-icon-times-lt" />
                        </div>
                      </h2>

                      <div className="ed-card-wrp">
                        {_.map(Data, (data, subIndex) => {
                          const [newData = []] = data || []
                          const [{ RenderItem: { dataSubTitle = '' } = {} } = {}] = newData

                          return (
                            <div key={subIndex} className="ed-cardRow">
                              <div className="rowTitle">{dataSubTitle}</div>
                              <div className="ed-cardTable">
                                <div className="ed-cardTable-row">
                                  <div className="title" />

                                  {_.map(Data, (sub, columnIndex) => {
                                    const { RenderItem: { dataDetailsRowHeader = '' } = {} } =
                                      _.first(data[columnIndex] || []) || {}
                                    return (
                                      <div key={columnIndex} className="title-column">
                                        {dataDetailsRowHeader}
                                      </div>
                                    )
                                  })}
                                </div>
                                <div>
                                  {_.map(
                                    newData,
                                    (
                                      { RenderItem: { dataDetailsColumnHeader = '' } = {} },
                                      rowIndex
                                    ) => (
                                      <div key={rowIndex} className="ed-cardTable-row">
                                        <div
                                          className="title -ellipsis"
                                          title={dataDetailsColumnHeader}
                                        >
                                          {dataDetailsColumnHeader}
                                        </div>

                                        {_.map(Data, (sub, columnIndex) => {
                                          const { RenderItem: { detailValue } = {} } =
                                            (data[columnIndex] || [])[rowIndex] || {}
                                          return (
                                            <div key={columnIndex} className="value">
                                              {detailValue}
                                            </div>
                                          )
                                        })}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="rowSeparator" />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings
})

export default createPlugin(ExecutiveDashboard, selectConnectorProps)
