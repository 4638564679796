export default [
  'Brand',
  'Division',
  'SubDivision',
  'Age',
  'LifeStyle',
  'CollectionGroup',
  'BuyerGroup',
  'Class',
  'Range',
  'StyleCode',
  'ColorCode',
  'SeasonCode',
  'SeasonGroup',
  'RangePart',
  'LayoutType',
  'Neck'
]
