import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  clusterTree: {
    unCollapsedScenarios: []
  },
  saveTree: {
    unCollapsedScenarios: []
  },
  tree: {
    unCollapsedScenarios: []
  }
}

const collapseSlice = createSlice({
  name: 'collapse',
  initialState,
  reducers: {
    collapse: (state, action) => {
      state[action.payload.dataKey] = {
        unCollapsedScenarios: state[action.payload.dataKey].unCollapsedScenarios.filter(
          ({ Id }) => Id !== action.payload.Id
        )
      }
    },
    collapseAll: (state, action) => {
      state[action.payload.dataKey] = {
        unCollapsedScenarios: []
      }
    },
    unCollapse: (state, action) => {
      state[action.payload.dataKey] = {
        unCollapsedScenarios: [
          ...state[action.payload.dataKey].unCollapsedScenarios,
          { Id: action.payload.Id }
        ]
      }
    },
    unCollapseBulk: (state, action) => {
      state[action.payload.dataKey] = {
        unCollapsedScenarios: [...action.payload.Ids]
      }
    }
  }
})

export const { collapse, collapseAll, unCollapse, unCollapseBulk } = collapseSlice.actions

export const clusterTree = (state) => {
  state.clusterTree
}
export const saveTree = (state) => {
  state.saveTree
}
export const tree = (state) => {
  state.tree
}

export default collapseSlice.reducer
