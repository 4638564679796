export default `({
    xtype: 'panel',
    layout: 'border',
    defaults: {
        collapsible: true,
        split: true,
        bodyPadding: 0
    },
    collapsible: false,
    init: function (panel) {},
    controller: {
        onCopyto: function (gridview, rowIndex, colIndex, item, e, record, row) {
            var mainGridStore = this.lookup('main').store,
                basketStore = this.lookup('basket').store;
            basketStore.add(record);
        },
        onCancel: function () {
            var view = this.getView()
            var vm = view.getViewModel()
            var data = vm.getData()
            this.getView().ownerCt.close()
        },
        onAccept: function () {
            var spotId = this.getView().spotId,
                me = this.getView().ownerCt
            store = this.lookup('basket').store,
                MaterialList = []
            i = 0
            store.each(function (record, id) {
                MaterialList.push(record.get('MaterialCode'))
                i = i + 1;
            });
            //if (i === 0) return;
            me.setLoading(tt('saving data'));
            Ext.Ajax.request({
                url: Spui.Global.getApiUrl() + '/spui/ListofAddedSpotListUpdate',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
                method: 'POST',
                timeout: 600000,
                params: {
                    spotId: spotId,
                    MaterialList: Ext.util.JSON.encode(MaterialList)
                },
                success: function (transport) {
                    me.fireEvent('changed');
                    me.close()
                },
                failure: function (transport) {
                    alert(tt("Error:NotSaved"));
                    // me.fireEvent('changed');
                    // me.close()
                    me.setLoading(false);
                }
            });
        }
    },
    tbar: [{
        width: 400,
        fieldLabel: 'Search',
        labelWidth: 50,
        xtype: 'searchfield',
        store: 'SpotMaster'
    }],
    bbar: [
        '->', {
            text: tt('Add Selected'),
            handler: 'onAccept'
        },
        {
            text: tt('Cancel'),
            handler: 'onCancel'
        }
    ],
    items: [{
            xtype: 'grid',
            scrollable: true,
            reference: 'main',
            title: tt('List of Spot Master'),
            region: 'center',
            flex: 1,
            columns: [{
                    menuDisabled: true,
                    sortable: false,
                    xtype: 'actioncolumn',
                    resizable: false,
                    width: 30,
                    align: 'center',
                    items: [{
                        iconCls: 'fa fa-arrow-circle-o-right',
                        tooltip: tt('Kopyala'),
                        handler: 'onCopyto'
                    }]
                },
                {
                    text: tt('MaterialCode'),
                    dataIndex: 'MaterialCode',
                    width: 100,
                    renderer: function (v) {
                        return v * 1;
                    }
                },
                {
                    text: tt('MaterialDescription'),
                    dataIndex: 'MaterialDescription',
                    width: 200
                },
                {
                    text: tt('H1N'),
                    dataIndex: 'H1N',
                    width: 200
                },
                {
                    text: tt('H2N'),
                    dataIndex: 'H2N',
                    width: 200
                },
                {
                    text: tt('H3N'),
                    dataIndex: 'H3N',
                    width: 200
                }
            ],
            flex: 1,
            store: 'SpotMaster',
            listeners: {
                onBefore: function () {},
            },
            margin: '2 0 0 0',
        },
        {
            xtype: 'grid',
            region: 'east',
            title: tt('List of Selected Spot'),
            floatable: false,
            margin: '2 0 0 0',
            flex: 1,
            reference: 'basket',
            store: {},
            bind: {
                store: '{basketStore}'
            },
            columns: [{
                    xtype: 'rownumberer'
                }, {
                    menuDisabled: true,
                    sortable: false,
                    xtype: 'actioncolumn',
                    resizable: false,
                    tdCls: 'red',
                    width: 30,
                    align: 'center',
                    items: [{
                        iconCls: 'fa fa-eraser',
                        tooltip: tt('Remove From List'),
                        handler: function (grid, rowIndex, colIndex, item, e, record, row) {
                            var store = grid.store
                            store.remove(record);
                        }
                    }]
                },
                {
                    text: tt('MaterialCode'),
                    dataIndex: 'MaterialCode',
                    width: 100,
                    renderer: function (v) {
                        return v * 1;
                    }
                },
                {
                    text: tt('MaterialDescription'),
                    dataIndex: 'MaterialDescription',
                    width: 200
                },
                {
                    text: tt('H1N'),
                    dataIndex: 'H1N',
                    width: 200
                },
                {
                    text: tt('H2N'),
                    dataIndex: 'H2N',
                    width: 200
                },
                {
                    text: tt('H3N'),
                    dataIndex: 'H3N',
                    width: 200
                }
            ],
            height: 600,
        }
    ]
})
`
