import * as actionTypes from './actionTypes'

export const saveDefaultActionParams = (options) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storeDefaultActionParams(options))
      resolve()
    })
  }
}

const storeDefaultActionParams = (options) => {
  return {
    type: actionTypes.STORE_DEFAULT_ACTION_PARAMS,
    payload: options.payload
  }
}
