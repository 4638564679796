import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import cx from 'classnames'
import settings from '../../utils/settings'
import { slvyToast, SlvyTable } from '@/components'
import { arrayToObject, getDateDiff, makeRequest, objectToArray } from '../../utils'
import AddModalForm from './add-modal-form'
import SimilarOptionsChart from './chart'
import similarProductsGridColumns from '../../utils/similarProductsGridColumns'
import './style.scss'
import { setLoaderReducer } from '../../store/slices/appSlice'
import {
  getSimilarOptions,
  setColumns,
  setSimilarProducts,
  setIsSelectAllChecked
} from '../../store/slices/similarOptions'
import { getOptions, selectOption } from '../../store/slices/options'
import diacritics from '../../utils/diacritics'

const defaultColumn = { meta: { style: { justifyContent: 'center' } } }

class SimilarProducts extends Component {
  constructor(props) {
    super(props)
    this.settings = { ...settings }
    this.optionalColumns = {
      CalculatedSimilarityRate: false,
      SimilarityRate: false
    }
    this.state = {
      isCollapsed: true,
      isShownAddModal: false
    }
  }

  componentDidMount() {
    const { isForecast = false, setColumns = () => {} } = this.props
    setColumns(this.getColumns())
    this.getSimilarOptions(isForecast)
  }

  forecastReport(data) {
    const { similarProductsEventRef } = this.props
    const { OptionId = 0, AssortmentId = 0, SegmentId = 0, ClusterNodeId } = data || {}

    const params = { OptionId, AssortmentId, SegmentId, ClusterNodeId }
    similarProductsEventRef.current?.forecastReport(params)
  }

  stockCoverReport(data) {
    const { similarProductsEventRef } = this.props
    const { OptionId = 0, AssortmentId = 0, SegmentId = 0, ClusterNodeId } = data || {}

    const params = { OptionId, AssortmentId, SegmentId, ClusterNodeId }
    similarProductsEventRef.current?.stockCoverReport(params)
  }

  similarProductDrilldown(data) {
    const { similarProductsEventRef } = this.props
    const { ProductId = 0, ClusterNodeId = 0 } = data || {}

    const params = { ProductId, ClusterNodeId }
    similarProductsEventRef.current?.similarProductDrilldown(params)
  }

  similarMultiProductDrilldown(data) {
    const { similarProductsEventRef } = this.props
    const { ProductIds = [], ClusterNodeId = 0 } = data || {}

    const params = { ProductIds, ClusterNodeId }
    similarProductsEventRef.current?.similarMultiProductDrilldown(params)
  }

  withValueCap = (inputObj) => {
    const MIN_VAL = 0
    const MAX_VAL = 100
    const { value } = inputObj
    return value >= MIN_VAL && value <= MAX_VAL
  }

  getColumns(params = {}) {
    const { CalculatedSimilarityRate = false, SimilarityRate = false } = params

    const {
      gridColumns,
      SelectedOption: { SizeRangeId = -1 } = {},
      minPresentationData: { ProductAttributeNames = [] } = {}
    } = this.props

    const optionalColumns = []
    if (CalculatedSimilarityRate) {
      optionalColumns.push({
        header: 'Calculated Similarity Rate',
        accessorKey: 'CalculatedSimilarityRate',
        sortable: false
      })
    }

    if (SimilarityRate) {
      optionalColumns.push({
        id: 'similarRate',
        header: ({ row }) => this.getSimilarityRateHeaderTpl(row, 'Similarity Rate'),
        accessorKey: 'SimilarityRate',
        sortable: false,
        cell: ({ row }) => (
          <NumberFormat
            className="full-width assortment-react-table-input"
            decimalScale={0}
            defaultValue={row.value}
            isAllowed={this.withValueCap}
            type="text"
            onKeyDown={this.handleSimilarityRateChange.bind(this, row, 'SimilarityRate')}
          />
        ),
        size: 70
      })
    }

    const customHeaders = [
      {
        name: 'SimilarityRate',
        getTpl: ({ row }, dName) => this.getSimilarityRateHeaderTpl(row, dName)
      },
      { name: 'IsDisabled', getTpl: ({ row }, dName) => this.getActiveHeader(row, dName) }
    ]

    const columnTempArr = [
      {
        header: 'imageURL',
        accessorKey: 'ImageURL',
        cell: this.getImageURLCell.bind(this),
        sortable: false,
        size: 70
      },
      {
        header: 'Name',
        accessorKey: 'Name',
        sortable: true,
        size: 140
      },
      {
        id: 'isDisabled',
        header: ({ row }) => this.getActiveHeader(row, 'Active'),
        accessorKey: 'IsDisabled',
        cell: ({ row }) => {
          const { Products = [], SelectedOption: { Editable: isFutureOption = false } = {} } =
            this.props
          const { index: rowIndex } = row

          return (
            <input
              className="cp"
              checked={!Products[rowIndex].IsDisabled}
              disabled={this.isForecastRow(rowIndex) || isFutureOption === false}
              type="checkbox"
              onChange={() => this.handleActiveChange(row)}
            />
          )
        },
        sortable: false,
        size: 45
      },
      ...optionalColumns,
      {
        header: 'Product Description',
        accessorKey: 'Description',
        size: 80
      },
      {
        header: 'Base Price',
        accessorKey: 'BasePrice',
        size: 60
      },
      {
        header: 'Overlapping Ratio',
        accessorKey: 'OverlappingRatio',
        size: 72
      },
      {
        header: 'Start Date',
        accessorKey: 'StartTime',
        sortingFn: () => -1,
        size: 72
      },
      {
        header: 'End Date',
        accessorKey: 'EndTime',
        sortingFn: () => -1,
        size: 72
      },
      {
        header: 'Unit Sales',
        accessorKey: 'TotalSales',
        size: 65
      },
      {
        header: 'Planned P. Sales',
        accessorKey: 'OverlappingTotalSales',
        size: 50
      },
      {
        header: 'Stock',
        accessorKey: 'InitialInventory',
        size: 40
      },
      {
        header: 'Store Count',
        accessorKey: 'StoreCount',
        size: 40
      },
      {
        header: 'Similarity Description',
        accessorKey: 'SimilarityDescription',
        size: 105
      },
      {
        header: 'Details',
        accessorKey: 'SimilarityDetails',
        cell: ({ row }) => {
          const { original: { SimilarityDetails = '' } = {} } = row
          const attributeNames = ProductAttributeNames.slice(0, SimilarityDetails.length)

          return SimilarityDetails ? (
            <div className="d-flex justify-content-center align-items-center cp details">
              {attributeNames.map((attributeName, index) => (
                <OverlayTrigger
                  key={attributeName}
                  overlay={this.tooltip(attributeName)}
                  placement="top"
                >
                  <div
                    className={cx('rounded-1', {
                      green: SimilarityDetails[index] === '1',
                      dot: SimilarityDetails[index] !== '1'
                    })}
                  />
                </OverlayTrigger>
              ))}
            </div>
          ) : null
        },
        meta: {
          style: { justifyContent: 'start', paddingLeft: 4 }
        }
      },
      {
        header: 'Fit',
        accessorKey: 'Fit',
        size: 90
      },
      {
        header: 'Size Range',
        accessorKey: 'SizeRange',
        cell: (cell) => {
          const {
            row: {
              original: { SizeRangeTypeID }
            },
            getValue
          } = cell

          const value = getValue()

          if (SizeRangeTypeID === SizeRangeId) {
            return value
          }

          return (
            <span
              className="d-flex align-items-center gap-1 text-warning"
              title="This similar product does not have the same Size Range Type as the selected product."
            >
              {value}
              <i className="fa fa-exclamation-triangle fs-6" aria-hidden="true"></i>
            </span>
          )
        },
        size: 165
      }
    ]

    const columns = []

    gridColumns.forEach((column) => {
      const { isVisible, name, displayName } = column
      if (isVisible) {
        const foundCustomHeader = customHeaders.find((header) => header.name === name)
        const foundColumnTpl = columnTempArr.find((columnTmp) => columnTmp.accessorKey === name)

        // special column
        if (name === 'DispatchEvent') {
          columns.push(this.getDispatchEventTpl(displayName))
        } else if (foundColumnTpl) {
          if (foundCustomHeader) {
            foundColumnTpl.header = (row) => {
              return foundCustomHeader.getTpl(row, displayName)
            }
          } else {
            foundColumnTpl.header = displayName
            foundColumnTpl.headerClassName = 'centerized-table-header-cell'
          }

          columns.push(foundColumnTpl)
        }
      }
    })

    return columns
  }

  getDispatchEventTpl(displayName) {
    return {
      header: displayName,
      id: 'displayName',
      accessorKey: null,
      cell: ({ row }) => (
        <span
          className="slvy-ui-icon-edit edit-event-cell"
          onClick={() => this.handleDispatchEvent(row)}
        />
      ),
      sortable: false,
      size: 35
    }
  }

  getSimilarityRateHeaderTpl(row, displayName) {
    return (
      <div>
        <span>{displayName}</span>
        <hr className="settings-modal-from-hr" />
        <br />
        <br />
        <NumberFormat
          className="full-width assortment-react-table-input"
          decimalScale={0}
          defaultValue=""
          isAllowed={this.withValueCap}
          type="text"
          onKeyDown={this.handleBulkSimilarityRateChange.bind(this, row, 'SimilarityRate')}
        />
      </div>
    )
  }

  handleSimilarityRateChange(row, field, event) {
    if (event.keyCode !== 13) {
      return false
    }

    const { original: { Id: SimilarProductId = null } = {}, index: rowIndex } = row

    const $val = event.target.value
    const $value = numeral($val).value()

    const {
      props: {
        pluginId = '',
        SelectedOption: { Id: ProductId = '' } = {},
        Products = [],
        isForecast = false,
        setLoader = () => {}
      }
    } = this

    const _Products = [...Products]

    const payload = {
      pluginId,
      method: 'SetSimilarProductWeight',
      requestMethod: 'post',
      body: {
        SimilarProductId,
        ProductId,
        Rate: $value
      }
    }

    _Products[rowIndex][field] = $val

    setLoader({ isShown: true, messages: 'Similarity Rate' })

    makeRequest({ payload: { ...payload } })
      .then((response) => {
        const {
          body: { Description = '' }
        } = response

        if (Description) {
          slvyToast.success({ message: Description, title: 'Similarity Rate' })
          this.props.setProducts(_Products)

          setLoader({ isShown: false })
          this.getSimilarOptions(isForecast)
        }
      })
      .catch(setLoader({ isShown: false }))
  }

  handleBulkSimilarityRateChange(row, field, event) {
    if (event.keyCode !== 13) {
      return false
    }

    const $val = event.target.value
    const $value = numeral($val).value()

    const {
      props: {
        pluginId = '',
        SelectedOption: { Id: ProductId = '' } = {},
        Products = [],
        isForecast = false,
        setLoader = () => {}
      }
    } = this

    const _Products = [...Products]
    const idList = []

    for (let i = 0, len = _Products.length; i < len; i++) {
      const element = _Products[i]
      if (!this.isForecastRow(i)) {
        _Products[i][field] = $val
        idList.push(element.Id)
      }
    }

    const payload = {
      pluginId,
      method: 'SetSimilarProductWeightMassUpdate',
      requestMethod: 'post',
      body: {
        SimilarProductId: idList,
        ProductId,
        Rate: $value
      }
    }

    setLoader({ isShown: true, messages: 'Bulk Update Similarity Rate' })

    makeRequest({ payload: { ...payload } })
      .then((response) => {
        const {
          body: { Description = '' }
        } = response

        if (Description) {
          slvyToast.success({ message: Description, title: 'Bulk Update Similarity Rate' })
          this.props.setProducts(_Products)

          setLoader({ isShown: false })
          this.getSimilarOptions(isForecast)
        }
      })
      .catch(setLoader({ isShown: false }))
  }

  getImageURLCell({ row }) {
    const {
      original: { Description = '', ImageURL = '' }
    } = row

    const src = _.isArray(ImageURL) && ImageURL.length ? ImageURL[0] : ImageURL

    return src && _.isString(src) ? (
      <img alt={Description} className="similar-option-img" src={src} />
    ) : null
  }

  getActiveHeader(row, displayName) {
    const {
      isSelectAllChecked = false,
      SelectedOption: { Editable: isFutureOption = false } = {}
    } = this.props

    return (
      <>
        <span>{displayName}</span>
        <input
          checked={isSelectAllChecked}
          className="h-auto mt-1 cp"
          disabled={isFutureOption === false}
          type="checkbox"
          onChange={(event) => this.handleBulkInputChange(row, 'IsDisabled', event)}
        />
      </>
    )
  }

  isForecastRow(index) {
    const { Products = [], isForecast = false } = this.props
    return Products.length > index && Products[index].isForecastItem && isForecast
  }

  handleDispatchEvent(row) {
    const {
      index: rowIndex,
      original: { Id: ProductId = null }
    } = row

    const {
      rootOptions: { ClusterNodeId = null } = {},
      SelectedOption: { Editable: isFutureOption = false } = {}
    } = this.props

    if (isFutureOption === false) {
      slvyToast.error({ message: 'Option can not update', title: 'Update Option' })
      return
    }

    if (!this.isForecastRow(rowIndex)) {
      this.similarProductDrilldown({ ProductId, ClusterNodeId })
    }
  }

  handleActiveChange(row) {
    const { original: { Id: SimilarProductId = null } = {}, index: rowIndex } = row

    const {
      props: {
        pluginId = '',
        SelectedOption: { Id: ProductId = '' } = {},
        Products = [],
        isForecast = false,
        setLoader = () => {}
      }
    } = this

    const _Products = Products.map((product, index) =>
      index === rowIndex ? { ...product, IsDisabled: !product.IsDisabled } : product
    )

    const payload = {
      pluginId,
      method: 'SetSimilarProductStatus',
      requestMethod: 'post',
      body: {
        SimilarProductId,
        ProductId,
        IsEnabled: !_Products[rowIndex].IsDisabled
      }
    }

    setLoader({ isShown: true, messages: 'Mark as' })

    makeRequest({ payload: { ...payload } })
      .then((response) => {
        const {
          body: { Description = '' }
        } = response

        if (Description) {
          slvyToast.success({ message: Description, title: 'Mark as' })

          this.props.setProducts(_Products)
          this.props.setIsSelectAllChecked(this.getIsSelectAllChecked(_Products))

          setLoader({ isShown: false })
          this.getSimilarOptions(isForecast)
        }
      })
      .catch(setLoader({ isShown: false }))
  }

  getIsSelectAllChecked(Products) {
    return (
      Products.filter((product) => !product.isForecastItem).filter((product) => product.IsDisabled)
        .length === 0
    )
  }

  handleBulkInputChange(row, field, event) {
    const { target: { checked = true } = {} } = event

    const {
      props: {
        pluginId = '',
        SelectedOption: { Id: ProductId = '' } = {},
        Products = [],
        isForecast = false,
        setLoader = () => {}
      }
    } = this

    const idList = []
    const _Products = Products.map((product, index) => {
      const isForecastRow = this.isForecastRow(index)
      if (isForecastRow) return product

      idList.push(product.Id)
      return { ...product, [field]: checked }
    })

    const payload = {
      pluginId,
      method: 'SetSimilarProductStatusMassUpdate',
      requestMethod: 'post',
      body: {
        SimilarProductId: idList,
        ProductId,
        IsEnabled: checked
      }
    }

    setLoader({ isShown: true, messages: 'Bulk Mark as' })

    makeRequest({ payload: { ...payload } })
      .then((response) => {
        const {
          body: { Description = '' }
        } = response

        if (Description) {
          slvyToast.success({ message: Description, title: 'Bulk Mark as' })

          this.props.setProducts(_Products)
          this.props.setIsSelectAllChecked(this.getIsSelectAllChecked(_Products))

          setLoader({ isShown: false })
          this.getSimilarOptions(isForecast)
        }
      })
      .catch(setLoader({ isShown: false }))
  }

  tooltip(typeName) {
    return <Tooltip id="tooltip">{typeName}</Tooltip>
  }

  getSimilarOptions(includeForecast = true, $callback = () => {}) {
    const {
      props: {
        pluginId = '',
        rootOptions: { Stores = [], AssortmentId: assortmentId = '' } = {},
        SelectedOption: {
          Id: optionId = '',
          SegmentOptions = [],
          SelectedSegmentIndex = null
        } = {},
        selectedSegmentId = null,
        fetchGetSimilarOptions = () => {},
        setLoader = () => {}
      }
    } = this

    if (SegmentOptions.length === 0) {
      return
    }

    const segmentStoreIds = []
    Stores.forEach((store) => {
      if (store.SegmentId === selectedSegmentId) {
        segmentStoreIds.push(store.Id)
      }
    })

    const payload = {
      pluginId,
      method: 'GetSimilarOptions',
      requestMethod: 'post',
      body: {
        optionId,
        stores: segmentStoreIds,
        assortmentId,
        includeForecast,
        page: 1,
        start: 0,
        limit: 25
      }
    }

    setLoader({ isShown: true, messages: 'Loading Similar Options' })

    fetchGetSimilarOptions({ ...payload }).then(() => {
      const { similarOptionsError = {}, similarOptions: { Products: InitialProducts = [] } = {} } =
        this.props

      if (!_.isEmpty(similarOptionsError)) {
        slvyToast.error({ message: 'There are no similar products.' })
        setLoader({ isShown: false })
      }

      if (InitialProducts && InitialProducts.length) {
        this.convertDataToTable(InitialProducts)

        this.handleCloseModal()
      }
      setLoader({ isShown: false })
      $callback()
    })
  }

  getSimilarityDescription(product) {
    const { SimilarityHierarchy = 0, SimilarityDescription = '' } = product || {}
    return SimilarityHierarchy > 0 && !SimilarityDescription.includes('Flex')
      ? `${SimilarityDescription} ${SimilarityHierarchy}`
      : SimilarityDescription
  }

  convertDataToTable(Products) {
    const _Products = _.cloneDeep(Products)
    const {
      settings: { DateFormat = null },
      props: {
        formattingSettings: { decimal = '0,0.00' } = {},
        setProducts = () => {},
        setIsSelectAllChecked = () => {},
        setColumns = () => {}
      }
    } = this

    _Products.map((productItem) => {
      const {
        OverlappingRatio = null,
        TotalSales = null,
        StartTime = null,
        EndTime = null,
        OverlappingTotalSales = null
      } = productItem
      productItem.OverlappingRatio = numeral(OverlappingRatio).format('0%')
      productItem.OverlappingTotalSales = Math.round(OverlappingTotalSales)
      productItem.TotalSales = numeral(TotalSales).format(decimal)
      productItem.StartTime = moment(StartTime).format(DateFormat)
      productItem.EndTime = moment(EndTime).format(DateFormat)
      productItem.SimilarityDescription = this.getSimilarityDescription(productItem)
      productItem.isForecastItem = this.getSimilarityDescription(productItem) === 'Forecast'

      if ('CalculatedSimilarityRate' in productItem) {
        productItem.CalculatedSimilarityRate = Math.round(productItem.CalculatedSimilarityRate)
      }

      if ('SimilarityRate' in productItem) {
        productItem.SimilarityRate = Math.round(productItem.SimilarityRate)
      }

      return productItem
    })

    if (Products.length) {
      const firstProduct = _Products[0]
      this.optionalColumns.CalculatedSimilarityRate = 'CalculatedSimilarityRate' in firstProduct
      this.optionalColumns.SimilarityRate = 'SimilarityRate' in firstProduct
    }
    setProducts(_Products)
    setIsSelectAllChecked(this.getIsSelectAllChecked(_Products))
    setColumns(this.getColumns(this.optionalColumns))
  }

  getProducts() {
    const { SelectedOption: { Editable: isFutureOption = false } = {} } = this.props

    if (!isFutureOption) {
      slvyToast.error({ message: 'Option can not update', title: 'Update Option' })
      return
    }

    this.setState({ isShownAddModal: true })
  }

  reCalculate(event) {
    event.stopPropagation()
    const {
      props: {
        isForecast = false,
        formattingSettings: { decimal = '0,0.00' } = {},
        rootOptions: { AssortmentId = null },
        pluginId = '',
        SelectedOption: _SelectedOption,
        SelectedOption: { Editable: isFutureOption = false },
        fetchOptions = () => {},
        setLoader = () => {},
        onSelectOption = () => {}
      },
      settings: { DecimalFormat }
    } = this

    if (!isFutureOption) {
      slvyToast.error({ message: 'Option can not recalculate!', title: 'Recalculate Option' })
      return
    }
    setLoader({ isShown: true, messages: 'Recalculating' })

    const executeReCalculate = () => {
      const {
        Id: OptionId = null,
        BasePrice = null,
        EndDate = null,
        StartDate = null,
        SelectedSegmentIndex: _SelectedSegmentIndex_ = null,
        Expenditure = null,
        PrePackSize = null,
        Sellthrough = null
      } = _SelectedOption

      let OptionStr = _.cloneDeep(_SelectedOption)

      const BasePriceFormatted = `${numeral(BasePrice).format(decimal)}`
      const DaysDiff = getDateDiff(StartDate, EndDate, 'days')

      OptionStr.SegmentOptions = objectToArray(OptionStr.SegmentOptions).map((segmentOption) => {
        const { EndDate: sEndDate = null, StartDate: sStartDate = null } = segmentOption
        segmentOption.DaysDiff = getDateDiff(sStartDate, sEndDate, 'days')
        return segmentOption
      })

      OptionStr.SegmentOptions = arrayToObject(OptionStr.SegmentOptions)

      OptionStr = {
        ...OptionStr,
        BasePriceFormatted,
        DaysDiff,
        Expenditure: numeral(numeral(Expenditure).format(DecimalFormat)).value(),
        PrePackSize: numeral(numeral(PrePackSize).format(DecimalFormat)).value(),
        Sellthrough: numeral(numeral(Sellthrough).format(DecimalFormat)).value()
      }

      const payload = {
        pluginId,
        method: 'UpdateOption',
        requestMethod: 'post',
        body: {
          AssortmentId,
          OptionStr
          // cost: null,
          // price: null
        }
      }

      fetchOptions({ ...payload }).then(() => {
        const {
          rootOptions,
          rootOptions: { Options = [] },
          isForecast = true
        } = this.props

        if (_.isEmpty(rootOptions)) {
          slvyToast.error({ message: 'There is an error!', title: 'Recalculate' })
        } else {
          const newSelectedProduct = _.cloneDeep(Options).filter(({ Id }) => Id === OptionId)

          if (newSelectedProduct.length > 0) {
            newSelectedProduct[0].SelectedSegmentIndex = _SelectedSegmentIndex_

            onSelectOption(newSelectedProduct[0])

            this.getSimilarOptions(isForecast)

            slvyToast.success({
              message: 'Recalculate action completed successfully!',
              title: 'Recalculate'
            })
          }
        }
        setLoader({ isShown: false })
      })
    }

    if (isForecast) {
      executeReCalculate()
    } else {
      this.getSimilarOptions(isForecast, executeReCalculate)
    }
  }

  openReport() {
    const { rootOptions: { ClusterNodeId = null } = {}, Products = [] } = this.props

    const activeProducts = [...Products].filter(({ IsDisabled }) => IsDisabled === false)

    if (activeProducts.length > 0) {
      const ProductIds = activeProducts.map(({ Id }) => Id)
      this.similarMultiProductDrilldown({ ProductIds, ClusterNodeId })
    } else {
      slvyToast.warning({ message: 'There is no enabled similar product!' })
    }
  }

  handleCloseModal() {
    this.setState({
      isShownAddModal: false
    })
  }

  getTableRowStyle = (rowInfo) => {
    const isForecastRow = this.isForecastRow(rowInfo.index)

    if (isForecastRow) {
      return {
        backgroundColor: 'rgba(168, 168, 168, 0.3)',
        cursor: 'not-allowed'
      }
    }

    return {}
  }

  toggleCollapsed(isCollapsed) {
    this.setState({ isCollapsed })
  }

  onHeaderButtonClick(name, params) {
    const { similarProductsEventRef } = this.props
    const eventName = diacritics.removeAndLowerCase(name)
    const fn = similarProductsEventRef.current?.[`similarProductsHeaderButton_${eventName}_Clicked`]

    if (typeof fn === 'function') {
      fn(params)
    }
  }

  render() {
    const {
      props: {
        similarProductsHeaderButtons = [],
        pluginId = '',
        similarViewHandler = () => {},
        SelectedOption = {},
        SelectedOption: {
          Id: SelectedOptionId = '',
          SegmentOptions = [{ SegmentId: null }],
          SelectedSegmentIndex = 0
        } = {},
        rootOptions: { AssortmentId = null, ClusterNodeId = null } = {},
        minPresentationData: { NumberOfHistoryBackPeriods = 0 } = {},
        similarOptions: { MerchDates = {}, Products: InitialProducts = [] } = {},
        selectedSegmentId = null,
        Products = [],
        columns = [],
        isForecast = false
      },
      state: { isShownAddModal = false, isCollapsed = true }
    } = this

    const hasMoreProducts = Products.length > 2
    const collapsedClass = isCollapsed && hasMoreProducts ? ' collapsed ' : ''

    const $reportParams = {
      AssortmentId,
      ClusterNodeId,
      OptionId: SelectedOptionId,
      SegmentId: selectedSegmentId
    }
    const $forecastReportParams = { ...$reportParams }
    const $stockCoverReportParams = { ...$reportParams }

    return (
      <div className="similar-products">
        <div className="similar-modal-header">
          {isShownAddModal ? (
            <AddModalForm
              SelectedOption={SelectedOption}
              addSimilarProductsCallback={this.getSimilarOptions.bind(this, isForecast)}
              assortmentId={AssortmentId}
              existingProducts={Products}
              handleCloseModal={this.handleCloseModal.bind(this)}
              pluginId={pluginId}
              show={isShownAddModal}
            />
          ) : null}
          <i
            className="slvy-ui-icon-times-lt activeIcon"
            onClick={similarViewHandler.bind(this, false)}
          />
          <span className="title">Similar Products</span>
          <span className="top-nav-options">
            {similarProductsHeaderButtons.map((button, index) => {
              const { iconName = '', name = '' } = button
              return (
                <button
                  key={index}
                  onClick={this.onHeaderButtonClick.bind(this, name, $reportParams)}
                >
                  <i className={iconName} />
                  <span>{name}</span>
                </button>
              )
            })}
            <button onClick={this.stockCoverReport.bind(this, $stockCoverReportParams)}>
              <i className=" slvy-ui-icon-pivot-table-regular" />
              <span>Stock Cover Report</span>
            </button>
            <button onClick={this.forecastReport.bind(this, $forecastReportParams)}>
              <i className="slvy-ui-icon-chart-bar-regular" />
              <span>Forecast Report</span>
            </button>
            {hasMoreProducts ? (
              <button onClick={this.toggleCollapsed.bind(this, !isCollapsed)}>
                <i className={`slvy-ui-icon-caret-${isCollapsed ? 'down' : 'top'}`} />
                <span>{`${isCollapsed ? 'Expand' : 'Collapse'}`}</span>
              </button>
            ) : null}
            <button onClick={this.getProducts.bind(this)}>
              <i className="slvy-ui-icon-plus-circle-regular" />
              <span>Add</span>
            </button>
            <button onClick={this.reCalculate.bind(this)}>
              <i className="slvy-ui-icon-reset" />
              <span>Recalculate</span>
            </button>
            <button onClick={this.openReport.bind(this)}>
              <i className="slvy-ui-icon-export-box" />
              <span>Open Report</span>
            </button>
          </span>
        </div>
        {_.size(InitialProducts) > 0 ? (
          <div key="similar-modal-wrp" className={`similar-modal-wrp ${collapsedClass}`}>
            <SlvyTable
              suppressColumnMenu
              className="align-items-start"
              columns={columns}
              data={Products}
              defaultColumn={defaultColumn}
              getBodyClassName="w-100 product-body"
              getCellClassName={(cell) =>
                cx('d-flex align-items-center text-wrap product-cell', {
                  'product-details': cell.column.id === 'SimilarityDetails'
                })
              }
              getHeadClassName="position-sticky top-0 w-100"
              getHeaderClassName={(cell) =>
                cx(
                  'd-flex justify-content-center align-items-center p-2 text-wrap product-row product-cell',
                  { 'product-details': cell.id === 'SimilarityDetails' }
                )
              }
              getRowClassName="w-100 product-row"
              getRowStyle={this.getTableRowStyle}
            />
          </div>
        ) : null}
        {_.size(InitialProducts) > 0 ? (
          <SimilarOptionsChart
            merchDates={MerchDates}
            numberOfHistoryBackPeriods={NumberOfHistoryBackPeriods}
            products={InitialProducts}
            selectedOption={SelectedOption}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    SelectedOption: state.options.selected,
    selectedSegmentId: state.options.selectedSegmentId,
    similarOptions: state.similarOptions.similarOptions,
    similarOptionsError: state.similarOptions.error,
    Products: state.similarOptions.similarProducts,
    columns: state.similarOptions.columns,
    isSelectAllChecked: state.similarOptions.isSelectAllChecked,
    isForecast: state.similarOptions.isForecast,
    minPresentationData: state.minPresentationData.minPresentationData,
    formattingSettings: state.plugin.formattingSettings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    setLoader: bindActionCreators(setLoaderReducer, dispatch),
    fetchGetSimilarOptions: bindActionCreators(getSimilarOptions, dispatch),
    fetchOptions: bindActionCreators(getOptions, dispatch),
    // onUnSelectOption: bindActionCreators(unSelectOption, dispatch),
    onSelectOption: bindActionCreators(selectOption, dispatch),
    setColumns: bindActionCreators(setColumns, dispatch),
    setProducts: bindActionCreators(setSimilarProducts, dispatch),
    setIsSelectAllChecked: bindActionCreators(setIsSelectAllChecked, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimilarProducts)
