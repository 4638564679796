import { useCallback } from 'react'
import hash from 'object-hash'
import { objectKeys } from '@/ts/utils'
import { GenerateLookupHashProps } from './useGenerateLookupHash.types'

function useGenerateLookupHash(props: GenerateLookupHashProps) {
  const { actualFilters, additionalArgs, record, lookupQueryList, field } = props
  const getLookupHash = useCallback(() => {
    const filters = { ...(actualFilters ?? {}), ...(additionalArgs ?? {}) }

    const keyRecords = []
    if (objectKeys(filters).length) {
      keyRecords.push({ filters })
    }

    if (record) {
      lookupQueryList.forEach((lookupQuery: string) => {
        keyRecords.push({
          [lookupQuery]: record[lookupQuery]
        })
      })
    }

    return hash({ keyRecords, field })
  }, [actualFilters, additionalArgs, record, lookupQueryList, field])

  return { getLookupHash }
}

export default useGenerateLookupHash
