Ext.define('MDM.src.view.regionapp.Main', {
  extend: 'Ext.panel.Panel',
  title: 'MDM',
  xtype: 'regionappmain',
  layout: 'border',
  autoShow: true,
  bind: {
    title: 'Regional MDM - <span style="color:yellow">' + '{selectedNodeName}' + '</span>'
  },
  margin: '2',
  iconCls: 'fa fa-pencil-square-o',
  bodyPadding: 2,
  header: false,
  viewModel: 'regionapp-mainviewmodel',
  controller: 'regionapp-maincontroller',
  stateful: true,
  stateId: 'rmdmmainview',
  bbar: {
    cls: 'rmdstbar',
    height: 44,
    items: [
      {
        xtype: 'tbtext',
        bind: {
          text: 'Selected Number of DPIs :<b> {summaryRec.NumberOfDPI}</b>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Current Region :<span class="stlabel"> {summaryRec.CurrentRegion}</span>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Assigned:<span class="stlabel"> {summaryRec.Assigned}</span>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Unassigned:<span class="stlabel"> {summaryRec.UnAssigned}</span>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'No Supply Source:<span class="stlabel"> {summaryRec.NoSupplysource}</span>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Total New:<span class="stlabel"> {summaryRec.TotalNew}</span>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Unprocessed New:<span class="stlabel">{summaryRec.UnprocessedNew}</span>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Suppressed:<span class="stlabel"> {summaryRec.Dropped}</span>'
        }
      }
    ]
  },
  items: [
    {
      xtype: 'regionapp-tabpanel',
      header: false,
      margin: '0 0 0 0',
      region: 'center',
      reference: 'tabpanel',
      cls: 'rmdtabpanel',
      items: [
        {
          xtype: 'regionapp-grid-materialassigned',
          reference: 'materialassigned',
          title: 'Material List',
          iconCls: 'fa fa-sitemap',
          bind: {
            store: '{matAssStore}'
          }
        },
        {
          xtype: 'regionapp-grid-materialunassigned',
          reference: 'materialunassigned',
          iconCls: 'fa fa-chain-broken',
          title: 'Unassigned',
          bind: {
            store: '{matUnAssStore}'
          }
        },
        {
          xtype: 'regionapp-grid-materialnosupplysource',
          reference: 'materialnosupplysource',
          iconCls: 'fa fa-share-alt',
          title: 'No Supply Source',
          bind: {
            store: '{matNoSupplySource}'
          }
        },
        {
          xtype: 'regionapp-grid-materialnew',
          reference: 'materialnew',
          iconCls: 'fas fa-plus-circle',
          title: 'New Materials',
          bind: {
            store: '{MaterialNewStore}'
          }
        },
        {
          xtype: 'regionapp-grid-materialg11',
          reference: 'materialg11',
          iconCls: 'fa fa-bars',
          title: 'G11 Control',
          bind: {
            store: '{MaterialG11Store}'
          }
        }
      ]
    },
    {
      xtype: 'panel',
      split: true,
      layout: 'fit',
      region: 'west',
      margin: '0 0 0 0',
      width: 350,
      minWidth: 50,
      maxWidth: 700,
      title: 'DPI Hierarchies',
      collapsible: true,
      ui: 'panel-inverse',
      items: [
        {
          xtype: 'regionapp-panel-tree',
          header: false,
          reference: 'tree',
          bind: {
            store: '{DPITreeStore}'
          }
        }
      ]
    }
  ]
})
Ext.state.Manager.setProvider(Ext.create('Ext.state.LocalStorageProvider'))

Ext.JSON._keyStr = ' BCDEFGHIJKLeNOPQRSbUVWXYZaTgdMkchijflmnopqrs0uvwxyzt123456789'
Ext.JSON.encoded = function (e) {
  var t = ''
  var n, r, i, s, o, u, a
  var f = 0
  e = Ext.JSON._utf8_encode(e)
  while (f < e.length) {
    n = e.charCodeAt(f++)
    r = e.charCodeAt(f++)
    i = e.charCodeAt(f++)
    s = n >> 2
    o = ((n & 3) << 4) | (r >> 4)
    u = ((r & 15) << 2) | (i >> 6)
    a = i & 63
    if (isNaN(r)) {
      u = a = 64
    } else if (isNaN(i)) {
      a = 64
    }
    t =
      t +
      Ext.JSON._keyStr.charAt(s) +
      this._keyStr.charAt(o) +
      this._keyStr.charAt(u) +
      this._keyStr.charAt(a)
  }
  return t
}
Ext.JSON.decoded = function (e) {
  var t = ''
  var n, r, i
  var s, o, u, a
  var f = 0
  e = e.replace(/[^A-Za-z0-9\+\/\=]/g, '')
  while (f < e.length) {
    s = this._keyStr.indexOf(e.charAt(f++))
    o = this._keyStr.indexOf(e.charAt(f++))
    u = this._keyStr.indexOf(e.charAt(f++))
    a = this._keyStr.indexOf(e.charAt(f++))
    n = (s << 2) | (o >> 4)
    r = ((o & 15) << 4) | (u >> 2)
    i = ((u & 3) << 6) | a
    t = t + String.fromCharCode(n)
    if (u != 64) {
      t = t + String.fromCharCode(r)
    }
    if (a != 64) {
      t = t + String.fromCharCode(i)
    }
  }
  t = Ext.JSON._utf8_decode(t)
  return t
}
Ext.JSON._utf8_encode = function (e) {
  e = e.replace(/\r\n/g, '\n')
  var t = ''
  for (var n = 0; n < e.length; n++) {
    var r = e.charCodeAt(n)
    if (r < 128) {
      t += String.fromCharCode(r)
    } else if (r > 127 && r < 2048) {
      t += String.fromCharCode((r >> 6) | 192)
      t += String.fromCharCode((r & 63) | 128)
    } else {
      t += String.fromCharCode((r >> 12) | 224)
      t += String.fromCharCode(((r >> 6) & 63) | 128)
      t += String.fromCharCode((r & 63) | 128)
    }
  }
  return t
}
Ext.JSON._utf8_decode = function (e) {
  var t = ''
  var n = 0
  var r = (c1 = c2 = 0)
  while (n < e.length) {
    r = e.charCodeAt(n)
    if (r < 128) {
      t += String.fromCharCode(r)
      n++
    } else if (r > 191 && r < 224) {
      c2 = e.charCodeAt(n + 1)
      t += String.fromCharCode(((r & 31) << 6) | (c2 & 63))
      n += 2
    } else {
      c2 = e.charCodeAt(n + 1)
      c3 = e.charCodeAt(n + 2)
      t += String.fromCharCode(((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
      n += 3
    }
  }
  return t
}
