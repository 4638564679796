import { cloneDeep } from 'lodash'
import { commonFilterParams } from '../../../constants'
import { getColumnFilter } from '../../filter/index'
import {
  CustomConfigsCommonFilter,
  CustomConfigsWithShowListFilter,
  CustomConfigsWithTagFilter
} from '../../filter/filter.types'
import { customConfigs as defaultCustomConfigs } from '../../__tests__/mocks'
import { fieldConfig as defaultFieldConfig } from './mocks'

function getCustomConfigs() {
  return cloneDeep(defaultCustomConfigs)
}

function getFieldConfig() {
  return cloneDeep(defaultFieldConfig)
}

describe('AgGrid:Helpers:Filter:getColumnFilter', () => {
  let customConfigs = getCustomConfigs()
  let fieldConfig = getFieldConfig()

  beforeEach(() => {
    customConfigs = getCustomConfigs()
    fieldConfig = getFieldConfig()
  })

  it('should return empty object if gridFiltering falsy', () => {
    const result = getColumnFilter(false, customConfigs, fieldConfig, false)
    expect(result).toEqual({})
  })

  it('should return an object that has filter and filterParams keys if gridFiltering truthy but tagFilter and showList falsy', () => {
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsCommonFilter,
      fieldConfig,
      false
    )
    expect(result).toHaveProperty('filter')
    expect(result).toHaveProperty('filterParams')
  })

  it('should return an object that has filterParams key and it should be equal with commonFilterParams', () => {
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsCommonFilter,
      fieldConfig,
      false
    )
    expect(result.filterParams).toEqual(commonFilterParams)
  })

  it('should return an object that has filter key and agTextColumnFilter as value if fieldConfig.dataType is string', () => {
    fieldConfig.dataType = 'string'
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsCommonFilter,
      fieldConfig,
      false
    )
    expect(result.filter).toBe('agTextColumnFilter')
  })

  it('should return an object that has filter key and agNumberColumnFilter as value if fieldConfig.dataType is int', () => {
    fieldConfig.dataType = 'int'
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsCommonFilter,
      fieldConfig,
      false
    )
    expect(result.filter).toBe('agNumberColumnFilter')
  })

  it('should return an object that has filter key and agNumberColumnFilter as value if fieldConfig.dataType is double', () => {
    fieldConfig.dataType = 'double'
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsCommonFilter,
      fieldConfig,
      false
    )
    expect(result.filter).toBe('agNumberColumnFilter')
  })

  it('should return an object that has filter key and agDateColumnFilter as value if fieldConfig.dataType is datetime', () => {
    fieldConfig.dataType = 'datetime'
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsCommonFilter,
      fieldConfig,
      false
    )
    expect(result.filter).toBe('agDateColumnFilter')
  })

  it('should return an object that has filter key and true as value if fieldConfig.dataType is bool', () => {
    fieldConfig.dataType = 'bool'
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsCommonFilter,
      fieldConfig,
      false
    )
    expect(result.filter).toBe(true)
  })

  it('should return an object that has filter and filterParams keys if tagFilter is true', () => {
    customConfigs.filtering.tagFilter = true
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsWithTagFilter,
      fieldConfig,
      false
    )
    expect(result).toHaveProperty('filter')
    expect(result).toHaveProperty('filterParams')
  })

  it('should return an object that has filter key and agTextColumnFilter as value if tagFilter is true', () => {
    customConfigs.filtering.tagFilter = true
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsWithTagFilter,
      fieldConfig,
      false
    )
    expect(result.filter).toBe('agTextColumnFilter')
  })

  it('should return an object that has filterParams and it should have maxNumConditions, filterOptions and commonFilterParams if tagFilter is true', () => {
    customConfigs.filtering.tagFilter = true
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsWithTagFilter,
      fieldConfig,
      false
    )
    Object.keys(commonFilterParams).forEach((p) => expect(result.filterParams).toHaveProperty(p))
    expect(result.filterParams).toHaveProperty('trimInput')
    expect(result.filterParams).toHaveProperty('maxNumConditions')
    expect(result.filterParams).toHaveProperty('filterOptions')
  })

  it('should return an object that has filterParams.filterOptions and it should have displayKey, displayName and predicate if tagFilter is true', () => {
    customConfigs.filtering.tagFilter = true
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsWithTagFilter,
      fieldConfig,
      false
    )
    const filterOption = result.filterParams?.filterOptions?.[0]
    expect(filterOption).toHaveProperty('displayKey')
    expect(filterOption).toHaveProperty('displayName')
    expect(filterOption).toHaveProperty('predicate')
  })

  it('should return an object that has filter and filterParams properties if showList is true', () => {
    customConfigs.filtering.showList = true
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsWithShowListFilter,
      fieldConfig,
      false
    )
    expect(result).toHaveProperty('filter')
    expect(result).toHaveProperty('filterParams')
  })

  it('should return an object that has filter key and value as agSetColumnFilter if showList is true', () => {
    customConfigs.filtering.showList = true
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsWithShowListFilter,
      fieldConfig,
      false
    )
    expect(result.filter).toBe('agSetColumnFilter')
  })

  it('should return an object that has filterParams and it should have buttons and closeOnApply if showList is true', () => {
    customConfigs.filtering.showList = true
    const result = getColumnFilter(
      true,
      customConfigs as CustomConfigsWithShowListFilter,
      fieldConfig,
      false
    )
    expect(result.filterParams).toHaveProperty('buttons')
    expect(result.filterParams).toHaveProperty('closeOnApply')
  })
})
