import cx from 'classnames'
import { StyledSwitchProps } from './StyledSwitch.types'
import styles from './index.module.scss'

const StyledSwitch = (props: StyledSwitchProps) => {
  const {
    data = [],
    defaultValue = '',
    theme,
    style: {
      customColorStyle: { backgroundColor = '#47c2a9' }
    },
    handleChange,
    id,
    isStacked
  } = props

  const isSleek = theme === 'Sleek'

  return (
    <div
      className={cx('w-100', 'h-100', theme, {
        'flex-column': isStacked,
        'd-flex align-items-center justify-content-center flex-nowrap rounded-3': isSleek
      })}
      style={{ backgroundColor }}
    >
      {data.map((elem, i) => {
        const isActive = defaultValue === elem.id && theme
        const labelStyle = isActive ? { color: backgroundColor } : {}
        const talk = `input_${id}_${i}`
        return (
          <div
            key={i}
            className={cx(styles.buttonItem, {
              'd-flex align-items-center justify-content-between h-100 w-100': isSleek
            })}
            role="presentation"
            onClick={handleChange(elem.id)}
          >
            <input className={cx({ 'd-none': isSleek })} id={talk} name="switch" type="radio" />
            <label
              className={cx('d-flex', 'align-items-center', 'text-nowrap', styles.sleekLabel, {
                'justify-content-center h-100 w-100 m-0 px-2 cp fw-semibold border border-2':
                  isSleek,
                'rounded-3': isActive,
                [styles.sleekActiveLabel]: isActive
              })}
              htmlFor={talk}
              style={labelStyle}
            >
              {elem.text}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default StyledSwitch
