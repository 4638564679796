export default () => {
  return {
    title: 'PDF Export',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      pdfSettings: {
        type: 'object',
        title: 'PDF Settings',
        properties: {
          mode: {
            type: 'string',
            title: 'Mode',
            default: 'Open in modal',
            enum: ['Download', 'Show in modal'],
            required: true
          },
          orientation: {
            type: 'string',
            title: 'Orientation',
            default: 'Portrait',
            enum: ['Portrait', 'Landscape'],
            required: true
          }
        }
      },
      templateSettings: {
        type: 'object',
        title: 'Template Settings',
        properties: {
          filenameFormat: {
            type: 'string',
            title: 'PDF Filename format',
            description: 'Sample: {DesignID}_{Season}'
          },
          htmlTemplate: {
            type: 'string',
            format: 'textarea',
            title: 'Template Html',
            default: '<div> </div>',
            options: {
              input_height: '150px'
            }
          },
          specificTemplate: {
            type: 'string',
            title: 'Style Templates',
            default: '',
            enum: ['', 'primary'],
            required: true
          },
          customStyleTemplate: {
            type: 'string',
            format: 'textarea',
            title: 'Template Style',
            options: {
              input_height: '150px'
            }
          },
          helpers: {
            type: 'string',
            format: 'textarea',
            title: 'Template Script',
            options: {
              input_height: '150px'
            }
          }
        }
      },
      buttonSettings: {
        type: 'object',
        title: 'Button Settings',
        properties: {
          show: {
            type: 'boolean',
            title: 'Show?',
            required: true,
            default: true
          },
          text: {
            type: 'string',
            title: 'Button Text',
            default: 'PDF'
          },
          style: {
            type: 'string',
            title: 'Style',
            default: 'primary',
            enum: ['success', 'primary', 'info', 'warning', 'danger', 'link'],
            required: true
          },
          icon: {
            type: 'string',
            title: 'Icon',
            default: 'fa-file-pdf-o',
            format: 'IconSelector'
          },
          iconPosition: {
            type: 'string',
            title: 'Icon Position',
            default: 'Left',
            enum: ['Left', 'Right'],
            required: true
          },
          iconHoverColor: {
            type: 'string',
            format: 'Color',
            default: 'black',
            title: 'Icon Hover Color'
          },
          disabledDefault: {
            type: 'boolean',
            title: 'Disabled by Default',
            required: true,
            default: false
          }
        }
      }
    }
  }
}
