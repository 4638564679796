import { Component } from 'react'
import CheckboxTree from 'react-checkbox-tree'
import _ from 'lodash'
import { filteredRootValue, multipleTexts, selectAllNode } from './constants'
import { slvyToast } from '@/components'
import { getCheckedNodes } from './helpers'
import './react-checkbox-tree.scss'

export default class TreeViewContent extends Component {
  constructor() {
    super()
    this.state = {
      searchText: '',
      expanded: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { initiallyExpandedNodes } = this.props
    const { initiallyExpandedNodes: prevInitiallyExpandedNodes } = prevProps

    if (!_.isEqual(initiallyExpandedNodes, prevInitiallyExpandedNodes)) {
      this.setState({ expanded: initiallyExpandedNodes })
    }
  }

  handleChange(event) {
    const searchText = _.toLower(event?.currentTarget?.value || '')

    this.setState({ searchText })
  }

  handleCheck(checked, nodeInfo, visibleNodes) {
    const { onCheck, maximumNumberOfSelectableItem, levels, checked: prevChecked } = this.props
    const { searchText } = this.state

    const checkLimit =
      typeof maximumNumberOfSelectableItem !== 'number'
        ? Number.MAX_SAFE_INTEGER
        : maximumNumberOfSelectableItem

    const levelNo = nodeInfo.value.split('~')[2]
    const enabled = levels?.[levelNo]?.enabled ?? true

    if (!enabled) {
      onCheck(prevChecked)
      return
    }

    const checkedNodes = getCheckedNodes(visibleNodes, checked, prevChecked[0])

    if (checkedNodes.length > checkLimit) {
      slvyToast.warning({
        message: `The maximum number of items that can be selected is ${checkLimit}.`
      })
      return
    }

    if (searchText.length && checkedNodes.length) {
      checkedNodes.push(filteredRootValue)
    }

    onCheck(checkedNodes)
  }

  handleClearSearch() {
    this.setState({ searchText: '' })
  }

  searchInNodes(nodes, searchText, expanded, showAllChildrenOnSearch = false) {
    if (_.isEmpty(searchText)) {
      return nodes
    }
    const children = _.transform(
      nodes,
      (result, item) => {
        const { label, value, icon, children, search } = item
        const index = search.search(searchText)
        let itemChildren = []
        if (index > -1) {
          itemChildren = this.searchInNodes(children, undefined, expanded, showAllChildrenOnSearch)
        } else {
          itemChildren = this.searchInNodes(children, searchText, expanded, showAllChildrenOnSearch)
        }
        if (index > -1 || itemChildren.length > 0) {
          expanded.push(value)
          result.push({
            label,
            value,
            icon,
            children: showAllChildrenOnSearch ? children : itemChildren
          })
        }
      },
      []
    )
    return children
  }

  buildLimitedTree(nodes, limitInfo, limitedNodes, level) {
    if (_.size(nodes) > 0) {
      _.forEach(nodes, (node) => {
        const { currLimit = 0, maxLimit = Number.MAX_SAFE_INTEGER } = limitInfo[level] || {}
        const treeList = _.split(node.value, '~') || []
        const isNull = _.some(treeList, (item) => item === 'null')

        if (currLimit < maxLimit && !_.isNil(node.value) && node.value !== 'null' && !isNull) {
          const newNode = { ...node, children: [] }
          limitedNodes.push(newNode)
          limitInfo[level] && (limitInfo[level].currLimit = limitInfo[level].currLimit + 1)
          const { children = [] } = node
          this.buildLimitedTree(children, limitInfo, newNode.children, level + 1)
        }
      })
      limitInfo[level] && (limitInfo[level].currLimit = 0)
    }
  }

  getExpandes(nodes, checked, parent = []) {
    const children = _.reduce(
      nodes,
      (result, item) => {
        const { value, children } = item
        const p = _.concat(parent, value)
        if (_.indexOf(checked, value) > -1) {
          return _.concat(result, p)
        }

        return _.concat(result, this.getExpandes(children, checked, p))
      },
      []
    )
    return children
  }

  getAllValuesOfTree(nodes, leafValues) {
    _.forEach(nodes, (node) => {
      const { children = [], value } = node || {}
      leafValues.push(value)
      if (_.size(children) > 0) {
        this.getAllValuesOfTree(children, leafValues)
      }
    })
  }

  filterExpanded(nodes, values) {
    const availableValues = []
    this.getAllValuesOfTree(nodes, availableValues)

    return _.transform(
      values,
      (result, value) => {
        if (_.indexOf(availableValues, value) >= 0) {
          result.push(value)
        }
        return result
      },
      []
    )
  }

  highlighter(searchText, label) {
    if (!_.isEmpty(searchText) && label) {
      const indexedOf = label.toLowerCase().indexOf(searchText)

      if (indexedOf === -1) {
        return label
      }
      const sizeSearchText = _.size(searchText)

      const subSearchText = label.substring(indexedOf, indexedOf + sizeSearchText)
      return (
        <span>
          {label.substring(0, indexedOf)}
          <span className="-highlight">{subSearchText}</span>
          {label.substring(indexedOf + sizeSearchText, label.length)}
        </span>
      )
    }
    return label
  }

  applyHighlighterChildrenNode(node, searchText) {
    _.forEach(node, (child) => {
      child.label = this.highlighter(searchText, child.label)
      if (child.children) {
        this.applyHighlighterChildrenNode(child.children, searchText)
      }
    })
  }

  applyHighlighter(limitedNodes, searchText) {
    _.forEach(limitedNodes, (node) => {
      node.label = this.highlighter(searchText, node.label)
    })
    _.forEach(limitedNodes, (node) => {
      if (node.children) {
        this.applyHighlighterChildrenNode(node.children, searchText)
      }
    })
  }

  render() {
    const {
      nodes,
      checked,
      multiple,
      showAllChildrenOnSearch,
      pluginName,
      levels = [],
      placeholder,
      isPreviewMode
    } = this.props
    const { expanded, searchText } = this.state
    let c = [...expanded]

    const newNodes = this.searchInNodes(nodes, searchText, c, showAllChildrenOnSearch)
    const limitInfo = _.transform(
      levels,
      (result, level) => {
        const { level: levelIndex = 0, limit } = level || {}
        result[levelIndex] = {
          maxLimit: limit === '' || _.isNil(limit) ? Number.MAX_SAFE_INTEGER : limit,
          currLimit: 0
        }
        return result
      },
      {}
    )

    const limitedNodes = []

    this.buildLimitedTree(newNodes, limitInfo, limitedNodes, 0)
    if (!_.isEmpty(searchText) && multiple === 'Multiple Values' && limitedNodes.length > 0) {
      limitedNodes.unshift({
        ...selectAllNode,
        label: 'Select all search results'
      })
    }

    c = this.filterExpanded(limitedNodes, c)
    this.applyHighlighter(limitedNodes, searchText)

    const isMultiple = multipleTexts.some((text) => text === multiple)
    const checkboxProps = {
      ref: (input) => {
        this.nameInput = input
      },
      noCascade: !isMultiple,
      nodes: limitedNodes,
      checked,
      expanded: c,
      onCheck: (newChecked, nodeInfo) => this.handleCheck(newChecked, nodeInfo, limitedNodes),
      onExpand: (expanded) => this.setState({ expanded })
    }

    return (
      <div className="filter-treeview-dropdown" data-plugin-name={pluginName}>
        <div className="filter-treeview-dropdown-search">
          <input
            ref={(input) => !isPreviewMode && input && input.focus()}
            placeholder={placeholder}
            value={searchText}
            onChange={this.handleChange}
          />
          {searchText.length > 0 && (
            <span className="clear-search-btn" onClick={this.handleClearSearch}>
              <i className="fa-fw fa slvy-ui-icon-times-extra-lt" />
            </span>
          )}
        </div>
        <div className="filter-treeview-dropdown-container h-100">
          <div className="filter-treeview-dropdown-treeview">
            <CheckboxTree {...checkboxProps} />
          </div>
        </div>
      </div>
    )
  }
}
