import React, { useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { slvyToast } from '../../../../../components'
import { MESSAGES } from '../../../messages'

const Hierarchies = (props) => {
  const [query, setQuery] = useState('')

  const onHierarchyClick = function () {
    const { noMoreChildHierarchy = false } = props
    if (noMoreChildHierarchy) {
      slvyToast.warning({
        message: MESSAGES.there_is_no_sub_level_to_go,
        options: { containerId: 'dp' }
      })
      return
    }
    props.onHierarchyChange(...arguments)
  }

  const {
    breadCrumbList = [],
    canGoToLastLevel = true,
    hierarchies = [],
    isLoading = false,
    noMoreChildHierarchy = false,
    style = {}
  } = props

  const bLen = breadCrumbList.length

  const onQueryChange = (event) => {
    setQuery(event.target.value)
  }

  const filterHierarchies = () => {
    const trimmedQuery = query.trim()

    if (trimmedQuery) {
      return hierarchies.filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    }
    if (trimmedQuery === '') {
      return hierarchies
    }
    return []
  }

  const filteredHierarchies = filterHierarchies()

  return (
    <div className="slvy-dp-popover" style={style}>
      <div className="slvy-dp-popover-arrow" />
      <div className="slvy-dp-popover-content">
        {isLoading ? (
          <div className="hierarchies-list-loading">
            <strong className="fw-bold">Loading ...</strong>
          </div>
        ) : (
          <>
            {hierarchies.length > 10 ? (
              <div className="form-group form-group-sm mb-2">
                <FormControl
                  placeholder={MESSAGES.search}
                  size="sm"
                  type="search"
                  value={query}
                  onChange={onQueryChange}
                />
              </div>
            ) : null}
            <ul className="hierarchies-list">
              {filteredHierarchies.length === 0 ? (
                <li className="not-found d-block">
                  <strong className="fw-bold">{query}</strong> is not found!
                </li>
              ) : (
                filteredHierarchies.map((hierarchy, hierarchyIndex) => {
                  return (
                    <li key={hierarchyIndex} className="hierarchies-list-item">
                      <span
                        className={`${
                          noMoreChildHierarchy ? 'not-allowed' : ''
                        } hierarchies-list-item-text`}
                        onClick={onHierarchyClick.bind(
                          this,
                          hierarchy,
                          hierarchyIndex,
                          false,
                          true,
                          false
                        )}
                      >
                        {hierarchy}
                      </span>
                      <button
                        className="btn btn-sm btn-success -btn-go"
                        onClick={
                          canGoToLastLevel
                            ? props.onHierarchyChange.bind(this, hierarchy, bLen, false, true, true)
                            : onHierarchyClick.bind(this, hierarchy, bLen, false, true, true)
                        }
                      >
                        <i className="slvy-ui-icon-angle-right" />
                      </button>
                    </li>
                  )
                })
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default Hierarchies
