import { DateCellRendererParams } from './DateCellRenderer.types'

const DateCellRenderer = (params: DateCellRendererParams) => {
  const {
    api,
    value,
    valueFormatted,
    colDef: {
      customInfo: { isAutoColumn }
    },
    node: { group },
    context: { groupDisplayType }
  } = params

  let shouldWrapWithStrong = false

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    shouldWrapWithStrong = true
  }

  const isObject = value !== null && typeof value === 'object' && 'value' in value

  let result: any = valueFormatted

  if (group && !('valueFormatted' in params) && value) {
    result = isObject ? value.value : value
  }
  return shouldWrapWithStrong ? <strong>{result}</strong> : <>{result}</>
}

export default DateCellRenderer
