export const defaultForm = {
  borderRadius: 0,
  isBorderVisible: true,
  splitterWidth: 2,
  theme: '0'
} as const

export const defaultColor = {
  r: 255,
  g: 255,
  b: 255,
  a: 0
} as const

export const defaultThemes = [
  { name: 'Default Theme', id: '0' },
  { name: 'Sleek Sencha', id: '1' }
] as const
