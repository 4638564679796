import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formattingSettings: {},
  optionFieldsEditable: true,
  optionPriceEditable: false
}

const pluginSlice = createSlice({
  name: 'plugin',
  initialState,
  reducers: {
    setFormattingSettings(state, action) {
      state.formattingSettings = action.payload
    },
    setOptionFieldsEditable(state, action) {
      state.optionFieldsEditable = action.payload
    },
    setOptionPriceEditable(state, action) {
      state.optionPriceEditable = action.payload
    }
    // TODO not using
    // resetPluginSettings(state, action) {
    //   state.Plugin.Settings = {}
    // }
  }
})

export const { setFormattingSettings, setOptionFieldsEditable, setOptionPriceEditable } =
  pluginSlice.actions

export const formattingSettings = (state) => {
  state.formattingSettings
}

export const optionFieldsEditable = (state) => {
  state.optionFieldsEditable
}

export const optionPriceEditable = (state) => {
  state.optionPriceEditable
}

export default pluginSlice.reducer
