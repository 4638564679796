import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import cx from 'classnames'
import { slvyToast } from '@/components'
import store from '../../store'
import L from 'leaflet'
import { camelCase } from 'lodash'
import traverseTree from '@/utils/traverseTree'
import { useReduxState } from '../../hooks'
import {
  getMapElements,
  getLoadDetailById,
  getDemandDetailById,
  getDemandTree
} from '../../store/api/endpoints'
import { demandTreeTableSlice, loadTreeTableSlice, mapSlice } from '../../store/slices'
import { LoadCard, DemandCard } from '../../components'
import mapOptions from '../../constants/mapOptions'
import { MapProps } from './Map.types'
import styles from './Map.module.scss'

function Loading() {
  return (
    <div
      className="text-muted fs-6 h-100 w-100 position-absolute  d-flex justify-content-center align-items-center"
      style={{ zIndex: 999, backgroundColor: '#00000061' }}
    >
      <div
        style={{
          height: 66,
          width: 66,
          backgroundColor: '#dddadacf',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 4
        }}
      >
        <p style={{ margin: 0, fontSize: 12 }}>Loading...</p>
      </div>
    </div>
  )
}

export default function Map({
  lat,
  lng,
  zoom,
  tile,
  theme,
  demandTreeTableRef,
  loadTreeTableRef
}: MapProps) {
  const [loadTreeTable] = useReduxState(loadTreeTableSlice.selectSlice, loadTreeTableSlice.actions)
  const [demandTreeTable] = useReduxState(
    demandTreeTableSlice.selectSlice,
    demandTreeTableSlice.actions
  )
  const [map, mapDispatch] = useReduxState(mapSlice.selectSlice, mapSlice.actions)
  const [triggerGetMapElements, mapElementsResponse] = getMapElements.useLazyQuery()
  const [triggerGetLoadDetailById, loadDetailByIdResponse] = getLoadDetailById.useMutation({
    fixedCacheKey: 'load-detail-by-id'
  })
  const [triggerGetDemandDetailById, demandDetailByIdResponse] = getDemandDetailById.useMutation({
    fixedCacheKey: 'demand-detail-by-id'
  })
  const [, demandTreeResponse] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })
  const [openLoadCardPopups, setOpenLoadCardPopups] = useState({})

  const mapRef = useRef<L.Map>(null)

  const loadStopGeoJson = useRef(null)
  const loadStopMarkerGeoJson = useRef(null)
  const demandMarkerGeoJson = useRef(null)
  const loadOverlayTargetRef = useRef(null)
  const demandOverlayTargetRef = useRef(null)
  const loadCardRef = useRef({})
  const demandCardRef = useRef({})

  useEffect(() => {
    if (mapRef.current) return
    initializeMap()
  }, [])

  useEffect(() => {
    triggerGetMapElements({
      loadIds: loadTreeTable.loadStops,
      demandIds: [],
      loadSummaries: []
    })
  }, [JSON.stringify(loadTreeTable.loadStops)])

  useEffect(() => {
    if (mapElementsResponse.status === 'fulfilled') {
      renderMapElements()
      setZoom()
    }
  }, [mapElementsResponse.status])

  useEffect(() => {
    renderMapElements()
  }, [JSON.stringify(loadTreeTable.rowColors)])

  useEffect(() => {
    if (map.openLoadCardId) {
      renderLoadCard()
    }
  }, [map.openLoadCardId])

  useEffect(() => {
    if (map.isDemandCardOpen) {
      renderDemandCard()
    }
  }, [map.isDemandCardOpen])

  function handleOpenDemandCard(id) {
    let rowSelection = { ...demandTreeTableRef.current.getState().rowSelection }
    let selectedDemandIds = []
    traverseTree(
      { Text: 'Demands', ID: 'root', ...demandTreeResponse.data },
      (node) => {
        if (node.Key === id) {
          rowSelection = {
            ...rowSelection,
            [`${node.ID}/${node.Text}`]: !rowSelection[`${node.ID}/${node.Text}`]
          }
          if (node.Leaf) {
            selectedDemandIds.push(node.ID)
          }
        }
      },
      { getChildren: (node) => node.Children, useImmer: false }
    )
    demandTreeTableRef.current.setRowSelection(rowSelection)
    triggerGetMapElements({
      loadIds: loadTreeTable.loadStops,
      demandIds: selectedDemandIds,
      loadSummaries: []
    }).then(() => {
      triggerGetDemandDetailById({ id }).then(({ data }) => {
        mapDispatch.setIsDemandCardOpen(true)
        mapDispatch.setOpenDemandCardId(data?.result.Title)
      })
    })
  }

  function handleClickDemandMarker(event) {
    demandOverlayTargetRef.current = event.originalEvent.target
    // dispatch(openDemandCard())
    triggerGetLoadDetailById({ id: loadTreeTable.loadStops[0] })
  }

  function handleClickLoadStopRoute(event) {
    loadOverlayTargetRef.current = event.originalEvent.target
    triggerGetLoadDetailById({ id: event.layer.feature.properties.data.ID }).then(() => {
      mapDispatch.setLoadCardLatLng(event.latlng)
      mapDispatch.setOpenLoadCardId(event.layer.feature.properties.data.ID)
      mapDispatch.setIsLoadCardOpen(true)
    })
  }

  function handleClickLoadStopMarker(event) {
    loadOverlayTargetRef.current = event.originalEvent.target
    triggerGetLoadDetailById({ id: event.layer.options.properties.data.ID }).then(() => {
      mapDispatch.setLoadCardLatLng(event.latlng)
      mapDispatch.setOpenLoadCardId(event.layer.options.properties.data.ID)
      mapDispatch.setIsLoadCardOpen(true)
    })
  }

  function handleCloseLoadCard(id: string) {
    mapRef.current?.closePopup(loadCardRef.current[id])
    mapDispatch.setOpenLoadCardId('')
  }

  function handleMergeLoad(id) {
    if (Object.values(loadCardRef.current).length > 1) {
      console.log(0)
    } else {
      slvyToast.info({
        title: 'Information',
        message: "There's no option to merge. Please open a load card."
      })
    }
  }

  function initializeMap() {
    const { tiles } = mapOptions
    const { [camelCase(tile.replace(' ', ''))]: tileLayer } = tiles
    mapRef.current = L.map('tui-map').setView([39, 32], 3)

    L.tileLayer(tileLayer.url, tileLayer.options).addTo(mapRef.current)
  }

  function renderMapElements() {
    if (loadStopGeoJson.current) {
      mapRef.current!.removeLayer(loadStopGeoJson.current)
    }
    if (loadStopMarkerGeoJson.current) {
      mapRef.current!.removeLayer(loadStopMarkerGeoJson.current)
    }

    const loadStopRoutes = []
    const truckMarkers = []
    const loadStopMarkers = []
    const demandMarkers = []
    let geometries = {}

    mapElementsResponse.data?.data?.forEach((item) => {
      if (item.Type === 0) {
        item.MapObjects.forEach((mapObject) => {
          const nextGeometry = mapObject.NextGeometry === null ? '' : mapObject.NextGeometry
          if (!geometries[nextGeometry]) {
            geometries[nextGeometry] = L.PolylineUtil.decodeReverse(nextGeometry, 6)
          }
          loadStopRoutes.push(
            turf.lineString(geometries[nextGeometry], { data: mapObject.Properties })
          )
          loadStopMarkers.push(
            turf.point([mapObject.Longitude, mapObject.Latitude], { data: mapObject.Properties })
          )
        })
      } else {
        item.MapObjects.forEach((mapObject) => {
          demandMarkers.push(
            turf.point([mapObject.Longitude, mapObject.Latitude], {
              data: mapObject.Properties
            })
          )
        })
      }
    })

    const onEachFeatureLoad = function (feature: any, layer: any) {
      layer.feature = feature
    }

    loadStopGeoJson.current = L.geoJSON(loadStopRoutes, {
      style: (feature) => {
        // var color = getColorByName(feature.properties.data.ID)

        return {
          color: [loadTreeTable.rowColors[feature?.properties.data.ID]],
          weight: 3,
          smoothFactor: 2,
          className: `ID${feature.properties.data.ID}`
        }
      },
      onEachFeature: onEachFeatureLoad
    }).addTo(mapRef.current!)

    loadStopGeoJson.current?.addEventListener('click', handleClickLoadStopRoute)

    loadStopMarkerGeoJson.current = L.geoJSON(loadStopMarkers, {
      pointToLayer: (feature, latlng) => {
        feature.properties.text = feature.properties.data.StopSequence
        return new L.LabeledCircleMarker(latlng, feature, { interactive: true })
      },
      style: (feature) => {
        // var color = getColorByName(feature.properties.data.ID)

        return {
          color: loadTreeTable.rowColors[feature?.properties.data.ID],
          fill: true,
          fillOpacity: 1,
          className: `ID${feature.properties.data.ID} load-stop-marker`
        }
      },
      onEachFeature: onEachFeatureLoad
    }).addTo(mapRef.current!)

    demandMarkerGeoJson.current = L.geoJSON(demandMarkers, {
      pointToLayer: (feature, latlng) => {
        return new L.circleMarker(latlng, feature)
      },
      style: (feature) => {
        return {
          radius: getRadius(
            feature.properties.data.Quantity,
            mapElementsResponse.data.demandSummary.maxDemandQuantity
          ),
          color: 'red',
          fill: true,
          weight: 1,
          opacity: 0.1,
          fillColor: '#ff1c2a',
          fillOpacity: 0.1,
          classNake: 'demand-stop-marker'
        }
      }
    }).addTo(mapRef.current!)

    loadStopMarkerGeoJson.current!.addEventListener('click', handleClickLoadStopMarker)
    demandMarkerGeoJson.current!.addEventListener('click', handleClickDemandMarker)
  }

  function renderLoadCard() {
    loadCardRef.current[map.openLoadCardId] = document.createElement('div')
    loadCardRef.current[map.openLoadCardId].classList.add(styles.cardContainer)

    const popup = L.popup({
      className: 'selectedAreaPopup card-group',
      closeButton: false,
      autoPan: true,
      autoClose: false,
      closeOnEscapeKey: false,
      closeOnClick: false
    })

    popup
      .setLatLng([map.loadCardLatLng.lat, map.loadCardLatLng.lng])
      .setContent(loadCardRef.current[map.openLoadCardId])
      .openOn(mapRef.current!)

    makeDraggable(popup)

    setOpenLoadCardPopups((openLoadCardPopups) => ({
      ...openLoadCardPopups,
      ...{ [map.openLoadCardId]: { popup, res: loadDetailByIdResponse } }
    }))
  }

  function renderDemandCard() {
    demandCardRef.current[map.openDemandCardId] = L.popup({
      className: 'selectedAreaPopup card-group',
      closeButton: false,
      autoPan: true,
      autoClose: false,
      closeOnEscapeKey: false,
      closeOnClick: false
    })

    const wrapperDiv = document.createElement('div')

    demandCardRef.current[map.openDemandCardId]
      .setLatLng([
        mapElementsResponse.data?.data?.[1]?.MapObjects[0]?.Latitude,
        mapElementsResponse.data?.data?.[1]?.MapObjects[0]?.Longitude
      ])
      .setContent(wrapperDiv)
      .openOn(mapRef.current!)

    makeDraggable(demandCardRef.current[map.openDemandCardId])

    ReactDOM.render(
      <Provider store={store}>
        <DemandCard
          data={demandDetailByIdResponse?.data?.result}
          // isOpen={treeState.demand.card.isOpen}
          target={demandOverlayTargetRef.current}
          onHide={(event) => {
            // dispatch(closeDemandCard())
          }}
        />
      </Provider>,
      wrapperDiv
    )
  }

  function makeDraggable(popup: any) {
    const pos = mapRef.current?.latLngToLayerPoint(popup.getLatLng())
    L.DomUtil.setPosition(popup._wrapper.parentNode, pos)
    const draggable = new L.Draggable(popup._container, popup._wrapper)
    draggable.enable()

    draggable.on('dragend', () => {
      const nextPos = mapRef.current.layerPointToLatLng(draggable._newPos)
      popup.setLatLng(nextPos)
    })
  }

  function getRadius(quantity, maxDemandQuantity) {
    return (quantity * 35) / maxDemandQuantity + 5
  }

  function setZoom() {
    const layers = [
      loadStopGeoJson.current,
      loadStopMarkerGeoJson.current,
      demandMarkerGeoJson.current
    ]
    const bounds = L.latLngBounds([])

    layers.forEach((layer) => {
      if (!layer || !layer.getBounds) return
      bounds.extend(layer.getBounds())
    })

    if (bounds.isValid()) {
      mapRef.current?.fitBounds(bounds)
    }
  }

  return (
    <>
      <div
        className={cx(styles.map, mapOptions.themes[theme]?.title)}
        id="tui-map"
        style={{ position: 'relative' }}
      >
        {(mapElementsResponse.isFetching ||
          loadDetailByIdResponse.isFetching ||
          demandDetailByIdResponse.isFetching) && <Loading />}
      </div>
      {Object.entries(openLoadCardPopups).map(([id, { res }]) =>
        ReactDOM.render(
          <Provider store={store}>
            <LoadCard
              id={id}
              data={res.data?.result}
              openLoadCardIds={Object.keys(openLoadCardPopups)}
              onOpenDemandCard={handleOpenDemandCard}
              onMergeLoad={handleMergeLoad}
              onClose={handleCloseLoadCard}
            />
          </Provider>,
          loadCardRef.current[id]
        )
      )}
    </>
  )
}
