import React, { Component } from 'react'
import numeral from 'numeral'
import _ from 'lodash'

export default class Axis extends Component {
  format(number) {
    return numeral(Math.round(number)).format('0,0')
  }

  render() {
    const { axisData = 0 } = this.props
    const count = this.format(axisData)
    const half = this.format(axisData / 2)
    const newArray = Array(21)

    return (
      <div className="-axis">
        {_.map(newArray, (item, index) => {
          const value =
            index === 0 ? 0 : index === 10 ? half : index === newArray.length - 1 ? count : null
          return (
            <div key={index}>
              <span className={(value !== null).toString()}>{value}</span>
            </div>
          )
        })}
      </div>
    )
  }
}
