import { useCallback } from 'react'
import { isEqual } from 'lodash'
import { slvyToast } from '@/components'
import { AG_GRID_ENUMS } from '../../enums'
import { LocaleText, TrowDataValue } from '../../types'
import {
  getEditingDirtyCells,
  getEditingParams,
  getIsProgressAndNotNumber,
  getIsSubstitute,
  isWarningThresholdExceeded,
  prepareUpdateData
} from '../../helpers/index'
import { useThresholdDidExceeded } from '../index'
import { CellEditRequestEventParams, ExecuteWarningThresholdParams } from '../../types/cell'
import { CellEditRequestProps } from './useCellEditRequest.types'

const { CUSTOM_ROW_INFO, DIRTY_CELLS } = AG_GRID_ENUMS

function useCellEditRequest(props: CellEditRequestProps) {
  const { gridRef, applyUpdate, doesRowDisplayRulesExist } = props
  const { thresholdDidExceeded } = useThresholdDidExceeded(gridRef)
  const {
    invalidValue: invalidValueLocale,
    onlyNumericValuesAllowed: onlyNumericValuesAllowedLocale
  } = (gridRef?.current?.api?.getGridOption?.('localeText') as LocaleText) || {}

  const executeEditRequest = useCallback(
    (params, node) => {
      const newData = prepareUpdateData(params)
      applyUpdate([newData], () => {
        // refresh current rows' cells to get/delete style if there is a row rule
        if (doesRowDisplayRulesExist) {
          gridRef.current?.api.refreshCells({
            force: true,
            rowNodes: [node] // only for the current RowNode
          })
        }
      })
    },
    [applyUpdate, doesRowDisplayRulesExist, gridRef]
  )

  const executeWarningThreshold = useCallback(
    (params: ExecuteWarningThresholdParams) => {
      const { warningThreshold, unTouchedValueParams, newValueId, callback } = params
      const isThresholdExceeded = isWarningThresholdExceeded({
        warningThreshold,
        oldValue: unTouchedValueParams.id,
        newValue: newValueId
      })

      if (isThresholdExceeded) {
        thresholdDidExceeded(warningThreshold, callback)
      } else {
        callback()
      }
    },
    [thresholdDidExceeded]
  )

  const onCellEditRequest = useCallback(
    (event: CellEditRequestEventParams) => {
      const {
        node,
        node: { id },
        data: {
          [CUSTOM_ROW_INFO]: { [DIRTY_CELLS]: dirtyCells = [] }
        },
        colDef: {
          field,
          customInfo,
          customInfo: { columnType }
        },
        newValue,
        oldValue
      } = event

      // for type safety
      if (typeof id === 'undefined' || typeof field === 'undefined') {
        return
      }
      const editingParams = getEditingParams({
        oldValue: oldValue as TrowDataValue,
        newValue: newValue as TrowDataValue,
        customInfo
      })
      const {
        newValueParams,
        newValueParams: { id: newValueId, value: newValueValue },
        oldValueParams,
        oldValueParams: { id: oldValueId, value: oldValueValue }
      } = editingParams

      const { allowBlank, warningThreshold = 0 } = customInfo.editing
      const isProgress = columnType === 'progress'
      const isValueEqual = isEqual(newValueValue, oldValueValue)

      // TODO
      // issue: While grid edit is on, if you click on a column of the progress column type and then click anywhere else, the data in that row is deleted.
      // The isProgress and isValueEqual control has been added temporarily until we receive feedback from the ag-grid.
      // Zendesk: https://ag-grid.zendesk.com/hc/en-us/requests/31274
      // Zendesk feedback: "We added this requirement to our backlog and we are tracking it with the following reference: AG-11164"
      // Plunker: https://plnkr.co/edit/GfXpbY2ZzAVJAxFe?open=index.tsx&preview

      // Empty value is null for each data type when it's cleaned.
      if ((!allowBlank && newValueId === null) || (isProgress && isValueEqual)) {
        gridRef?.current?.api.stopEditing()
        return
      }

      if (getIsProgressAndNotNumber({ columnType, value: newValue })) {
        slvyToast.warning({
          title: invalidValueLocale,
          message: onlyNumericValuesAllowedLocale(newValue)
        })
        event.api.stopEditing()
        return
      }

      const { currentDirtyCell, otherDirtyCells } = getEditingDirtyCells({
        dirtyCells,
        field,
        id
      })

      const unTouchedValueParams = currentDirtyCell
        ? currentDirtyCell.unTouchedValueParams
        : { id: oldValueId, value: oldValueValue }

      const executeEditRequestParams = {
        event,
        otherDirtyCells,
        isSubstitute: getIsSubstitute(customInfo),
        newValueParams,
        oldValueParams,
        unTouchedValueParams
      }

      executeWarningThreshold({
        warningThreshold,
        unTouchedValueParams,
        newValueId,
        callback: () => executeEditRequest(executeEditRequestParams, node)
      })
    },
    [
      executeWarningThreshold,
      gridRef,
      invalidValueLocale,
      onlyNumericValuesAllowedLocale,
      executeEditRequest
    ]
  )

  return { onCellEditRequest }
}

export default useCellEditRequest
