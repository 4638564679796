import { GridRef } from '../../../types'
import { getColDefByField } from '../common'

const mockColumnDefs = [{ field: 'Income' }, { field: 'Customer' }, { field: 'Coverage' }]

const mockGridApi = {
  getColumnDef: (activeField: string) =>
    mockColumnDefs.find((columnDef) => columnDef.field === activeField)
}

interface GetColDefByFieldProps {
  mockGridRef: GridRef
  activeField: string
}

const getColDefByFieldParams = (): GetColDefByFieldProps => ({
  mockGridRef: {
    current: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      api: mockGridApi
    }
  },
  activeField: 'Income'
})

describe('AgGrid:Helpers:Common:getColDefByField', () => {
  let params = getColDefByFieldParams()

  beforeEach(() => {
    params = getColDefByFieldParams()
  })

  it('should return correct column definition when field and activeField matches', () => {
    const result = getColDefByField(params.mockGridRef, params.activeField)

    expect(result).toEqual({ field: 'Income' })
  })

  it('should return undefined if field is not found', () => {
    params.activeField = 'name'
    const result = getColDefByField(params.mockGridRef, params.activeField)

    expect(result).toBeUndefined()
  })

  it('should return undefined if gridRef is null', () => {
    params.activeField = 'name'
    const nullGridRef: GridRef = { current: null }
    const result = getColDefByField(nullGridRef, params.activeField)

    expect(result).toBeUndefined()
  })

  it('should return undefined if getColumnDefs is undefined', () => {
    params.activeField = 'name'
    const undefinedGetColumnDefs: GridRef = {
      current: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api: { getColumnDef: () => undefined }
      }
    }
    const result = getColDefByField(undefinedGetColumnDefs, params.activeField)

    expect(result).toBeUndefined()
  })
})
