import _ from 'lodash'
import { STORE_SCENARIO_COMPARISON_MAIN_CONFIGURATION } from '../actions/actionTypes'

const initialState = {
  scenarioComparisonMainConfiguration: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SCENARIO_COMPARISON_MAIN_CONFIGURATION:
      return {
        scenarioComparisonMainConfiguration: {
          ...state.scenarioComparisonMainConfiguration,
          ..._.cloneDeep(action.payload)
        }
      }
    default:
      return state
  }
}

export default reducer
