import { useCallback } from 'react'
import { IRowNode } from 'ag-grid-community'
import { cloneDeep } from 'lodash'
import { IRowData } from '../../types'
import { AG_GRID_ENUMS } from '../../enums'
import { ResetCustomRowInfoProps } from './useResetCustomRowInfo.types'

const { CUSTOM_ROW_INFO, ROW_ID, DIRTY_CELLS, ADDED_ROWS } = AG_GRID_ENUMS

function useResetCustomRowInfo(props: ResetCustomRowInfoProps) {
  const { gridRef, setEditedFields } = props
  const resetCustomRowInfo = useCallback(() => {
    const cleanRowNodesData: IRowData[] = []

    gridRef?.current?.api?.forEachLeafNode?.(({ data }: IRowNode<IRowData>) => {
      if (data) {
        const {
          [ROW_ID]: rowId,
          [DIRTY_CELLS]: dirtyCells = [],
          [ADDED_ROWS]: addedRows = []
        } = data[CUSTOM_ROW_INFO]

        // This will only reset dirtyCells and addedRows if one of them exists
        if (dirtyCells?.length || addedRows?.length) {
          const newRowNodeData: IRowData = {
            ...data,
            [CUSTOM_ROW_INFO]: {
              ...cloneDeep(data[CUSTOM_ROW_INFO]),
              [ROW_ID]: rowId,
              [DIRTY_CELLS]: [],
              [ADDED_ROWS]: []
            }
          }
          cleanRowNodesData.push(newRowNodeData)
        }
      }
    })

    if (cleanRowNodesData.length) {
      // Use "applyTransactionAsync" if the grid frozen while removing dirty cell classes
      gridRef?.current?.api.applyTransaction({ update: cleanRowNodesData })
    }
    // Use "refreshCells" if the cellClass doesn't apply
    setEditedFields([])
  }, [gridRef, setEditedFields])
  return { resetCustomRowInfo }
}

export default useResetCustomRowInfo
