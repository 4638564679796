import React, { Component } from 'react'
import _ from 'lodash'
import Section from './section'

class SectionList extends Component {
  render() {
    const { schema } = this.props
    const { sections } = schema
    const sortedSections = _.orderBy(sections, (o) => o.order)
    return (
      <div
        className="sectionList"
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          width: '100%',
          backgroundColor: schema.backgroundColor
        }}
      >
        {!_.isEmpty(schema.title) ? <div className="widget-title">{schema.title}</div> : null}
        {_.map(sortedSections, (section, index) => {
          return (
            <div key={index}>
              <Section
                configData={this.props.configData}
                formBackgroundColor={schema.backgroundColor}
                getValidationState={this.props.getValidationState}
                queryFields={this.props.queryFields}
                section={section}
                sectionLeftColumn={schema.sectionLeftColumn}
                sectionRightColumn={schema.sectionRightColumn}
                updateFieldLookUpData={this.props.updateFieldLookUpData}
                onChange={this.props.onChange}
                onNewOptionAdd={this.props.onNewOptionAdd}
              />
            </div>
          )
        })}
      </div>
    )
  }
}
export default SectionList
