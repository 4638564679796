Ext.define('Assortment.assortmentLibrary.MfpTree', {
  extend: 'Ext.tree.Panel',
  alias: 'widget.assortmentMfpTree',
  width: 600,
  height: 800,
  reference: 'assortmentMfpTree',
  rootVisible: false,
  columnLines: true,
  rowLines: true,
  publishes: ['currentRow'],
  config: {
    currentRow: null
  },
  bind: {
    currentRow: '{currentRow}'
  },
  columns: [
    {
      xtype: 'treecolumn',
      dataIndex: 'Name',
      text: 'Cluster Tree',
      sortable: false,
      flex: 1,
      rootVisible: true
    },
    {
      text: 'Status',
      dataIndex: 'Status',
      minWidth: '100px',
      renderer: function (value, cell, record) {
        if (record.get('Status') == 'FOLDER') return null

        return value
      }
    },
    {
      text: 'Description',
      dataIndex: 'Description',
      flex: 1
    }
  ],

  initComponent: function () {
    var me = this

    var store = Ext.create('Ext.data.TreeStore', {
      autoLoad: true,
      model: 'Assortment.model.AssortmentTreeModel',
      proxy: {
        type: 'ajax',
        timeout: 600000,
        url: Assortment.Global.getApiUrl() + 'MfpGetTree',
        reader: {
          rootProperty: 'Root',
          metaProperty: 'AvailableStatus'
        }
      },

      root: {
        text: 'Root',
        id: '0',
        expanded: true,
        Children: []
      },
      listeners: {
        metachange: function (store, meta) {
          var availableStatuses = meta
          var removedStatuses = ['ASSORTMENT', 'FOLDER']

          availableStatuses = availableStatuses.filter(function (n) {
            return removedStatuses.indexOf(n) === -1
          })

          store.AvailableStatuses = availableStatuses
        },
        load: function () {
          var tree = me
          var assortmentLibrary = me.lookupReferenceHolder()
          var viewModel = assortmentLibrary.getViewModel()
          viewModel.set('currentRow', null)
          tree.expandAll()
        }
      }
    })

    me.store = store

    me.callParent()
  }
})
