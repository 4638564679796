Ext.define('tuiv2.cmp.SnapShotGrid', {
  extend: 'Ext.grid.Panel',
  alias: 'widget.tuiv2SnapShotGrid',
  store: {
    model: 'tuiv2.model.SnapShotModel',
    autoLoad: true,
    autoSync: true
  },
  viewConfig: {
    enableTextSelection: true
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],
  tbar: [
    {
      text: 'Create snapshot',
      handler: 'onCreateSnapShot'
    }
  ],
  columns: [
    {
      dataIndex: 'ScenarioName',
      text: 'Name',
      width: 190,
      filter: {
        type: 'list'
      },
      editor: {
        xtype: 'textfield',
        allowBlank: true,
        allowOnlyWhitespace: false,
        maxLength: 128
      }
    },
    {
      dataIndex: 'ScenarioDescription',
      text: 'Description',
      flex: 1,
      filter: {
        type: 'list'
      },
      editor: {
        xtype: 'textfield',
        allowBlank: true,
        allowOnlyWhitespace: false,
        maxLength: 500
      }
    },
    {
      dataIndex: 'CreatedBy',
      text: 'Created By',
      width: 100,
      filter: {
        type: 'list'
      }
    },
    {
      dataIndex: 'CreatedAt',
      text: 'Created At',
      width: 125,
      filter: {
        type: 'list'
      },
      renderer: function (value) {
        return Ext.util.Format.date(value, 'Y-m-d H:i')
      }
    },
    {
      xtype: 'actioncolumn',

      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete Plant',
          handler: 'onRemoveSnapShot'
        }
      ]
    }
  ]
})
