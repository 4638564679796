import { select, put } from 'redux-saga/effects'
import _ from 'lodash'

import { addMessage } from '../../../store/slices/logger'

function testRule(query, variables) {
  const { combinator = 'and', rules = [] } = query
  return _.reduce(
    rules,
    (result, rule) => {
      if (combinator === 'or' && result === true) return true
      if (combinator === 'and' && result === false) return false
      const { combinator: isCombinator, field, operator, value, valueSource = 'value' } = rule
      if (isCombinator) {
        const res = testRule(rule, variables)
        return res
      }
      let finalValue = value
      if (valueSource === 'field') {
        finalValue = variables[value]
      }
      switch (operator) {
        case 'null':
          return _.isNil(variables[field])
        case 'notNull':
          return !_.isNil(variables[field])
        case '=':
          // eslint-disable-next-line eqeqeq
          return variables[field] == finalValue
        case '!=':
          // eslint-disable-next-line eqeqeq
          return variables[field] != finalValue
        case '<':
          return variables[field] < finalValue
        case '>':
          return variables[field] > finalValue
        case '<=':
          return variables[field] <= finalValue
        case '>=':
          return variables[field] >= finalValue
        default:
          return true
      }
    },
    combinator === 'and'
  )
}
export default function* condition(props, result, action) {
  const { query } = props
  const {
    payload: {
      params: { storeIndex }
    }
  } = action

  const { relationReducers = {} } = yield select()

  const { stores = [] } = relationReducers
  const store = stores[storeIndex] || {}
  const {
    global: { variables: globalVariables = {} } = {},
    page: { variables: pageVariables = {} } = {},
    menu: { variables: menuVariables = {} } = {}
  } = store || {}

  const variables = {
    ...pageVariables,
    ...menuVariables,
    ...globalVariables
  }

  const res = testRule(query, variables)

  if (window.ctrlshiftk) {
    yield put(addMessage({ flag: 'Condition', title: JSON.stringify(query), message: `${result}` }))
  }

  result.cancel = !res

  return result
}
