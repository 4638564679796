import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'
import { expandAllHierarchies, PRODUCTexpandAllHierarchies } from '../actions'
import { Dropdown, GroupBy } from './'
import { dataMap } from '../utils/constants'
import { getMessage, relocateScroll } from '../utils'
import { resetMoveMode } from '../actions/moveMode'
import MoveMode from '../views/supply/components/MoveMode'
import Sessions from '../views/supply/components/sessions'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: '',
      showGroupBy: false,
      isPegtagActive: false,
      isProductEdited: false
    }

    this.mergeSelectedParameterNames = this.mergeSelectedParameterNames.bind(this)
    this.handleShowGroupBy = this.handleShowGroupBy.bind(this)
    this.handleClickPegTag = this.handleClickPegTag.bind(this)
  }

  eventDispatcher(obj) {
    dispatchEvent(
      new CustomEvent('headerhandler', {
        detail: obj
      })
    )
  }

  eventHandler(action) {
    let {
      eventHandler = () => {},
      settings: { runInfo: { runInfoId = 0 } = {} } = {},
      clickedProductInfo: {
        resourceName,
        resourceId,
        productName,
        productId,
        periodName,
        periodId,
        locationNames
      } = {}
    } = this.props
    const fn = eventHandler[`custom_${action}`]

    locationNames = _.dropRight(locationNames).join(' -> ')

    if (typeof fn === 'function') {
      if (action === 'eventBtn') {
        fn({
          resourceName,
          resourceId,
          productName,
          productId,
          periodName,
          periodId,
          locationNames
        })
      } else {
        fn({ runInfoId })
      }
    }
  }

  componentDidMount() {
    const {
      settings: { topMenu },
      stateHandler
    } = this.props
    const { name = '' } = _.find(topMenu, (item) => item.active) || {}

    stateHandler({
      currentMenuItem: name,
      currentMenuState: true,
      selectedParameter: _.first(this.mergeSelectedParameterNames())
    })
  }

  componentDidUpdate(prevProps) {
    const { lastRequest, modificationCount } = this.props
    const { isPegtagActive } = this.state
    const { modificationCount: prevModificationCount } = prevProps

    if (
      !_.isEqual(lastRequest.last.method, 'GetRccpResourcePeriodHierarchyResponseWPegtag') &&
      !_.includes(lastRequest.last.method, 'Update') &&
      isPegtagActive
    ) {
      this.setState({ isPegtagActive: false })
    }

    if (modificationCount !== prevModificationCount && prevModificationCount !== 0) {
      this.setState({ isProductEdited: true })
    }
  }

  buttonClickHandler(buttonObj, e) {
    const { name, action } = buttonObj
    const {
      stateHandler,
      settings: {
        stateHandler: { currentMenuItem, currentMenuState }
      }
    } = this.props

    const methodName = this[action]
    const isMethodAvailable = typeof methodName === 'function'

    if (action === 'issues') {
      stateHandler({
        currentMenuItem: name,
        currentMenuState: currentMenuItem === name ? !currentMenuState : true
      })
    }

    this.eventHandler(action)

    if (isMethodAvailable) {
      methodName.bind(this, e)()
    }
  }

  mergeSelectedParameterNames() {
    const {
      settings: { selectedParameterNames = [], metrics }
    } = this.props

    return _.reduce(
      selectedParameterNames,
      (arr, item) => {
        const { tableName } = item
        const findedItem = _.find(metrics, (m) => m.ProductionUpdateParameterName === tableName)

        if (findedItem) {
          arr.push({ ...item, ...findedItem })
        }

        return arr
      },
      []
    )
  }

  getCurrentRequest(type) {
    let {
      settings: { views = {}, sessionEnvironments: { productId: targetId } = {} },
      lastRequest,
      modificationCount = 0,
      maxModificationCount = 10000,
      connectApi
    } = this.props
    const selectedViewName = _.findKey(views, (value) => value)
    const { getRequest = 'GetRccpProductPeriodResponse' } = dataMap[selectedViewName] || {}
    let { Request } = lastRequest[getRequest]
    const params =
      selectedViewName !== 'filteredProduct'
        ? [getRequest, Request]
        : [getRequest, Request, 'RccpFilteredProduct']
    const actionType = type ? 'Redo' : 'Undo'

    Request.rccpConfig.ModificationCount = type
      ? modificationCount < maxModificationCount
        ? ++modificationCount
        : maxModificationCount
      : modificationCount > 0
      ? --modificationCount
      : modificationCount

    Request.rccpConfig.RequestType = actionType

    connectApi.apply(null, params).then((Response) => {
      this.eventDispatcher({
        method: getRequest,
        request: Request,
        response: Response,
        actionType
      })

      this.eventHandler()

      if (actionType === 'Undo' || actionType === 'Redo') {
        relocateScroll(targetId)
      }
    })
  }

  commonSave(promptName = '') {
    const {
      modificationCount = 0,
      connectApi,
      settings: { runInfo: { runInfoId = 0 } = {} } = {}
    } = this.props

    promptName = prompt('Enter Run Info Name')
    const runInfoName = `${promptName} - Created Under ${runInfoId}`

    const saveCanceled = _.isEmpty(promptName)
    if (saveCanceled) return

    const request = {
      runInfoName,
      parentRunInfoId: runInfoId,
      modificationCount
    }

    connectApi('SaveRccpData', request).then((res) => {
      const { Code, Description } = res

      getMessage(Code, Description).then((ret) => {
        if (ret.isOk) {
          this.eventHandler()
        }
      })
    })
  }

  undo() {
    this.getCurrentRequest.bind(this, false)()
  }

  redo() {
    this.getCurrentRequest.bind(this, true)()
  }

  saveas() {
    const promptName = prompt('Enter Run Info Name')

    if (!_.isEmpty(promptName)) {
      this.commonSave.bind(this, promptName)()
    }
  }

  save() {
    this.commonSave.bind(this, '')()
  }

  handleChange(event) {
    this.setState({
      view: event.target.value
    })
  }

  handleClickMenu(menu) {
    const {
      connectApi,
      firstPeriod: { Id: periodId = 0, StartTime, EndTime } = {},
      initialProductFilters = {},
      initialProductFilters: { productFilter = {} } = {},
      settings: {
        appSettings: {
          productionView: {
            title: productionViewTitle = 'Production Plan',
            gridView = 'Product'
          } = {},
          resourceView: { title: resourceViewTitle = 'Resource Plan' } = {},
          resourceScheduleView: { title: resourceScheduleViewTitle = 'Resource Schedule' } = {},
          shiftCalendarView: { title: shiftCalendarViewTitle = 'Shift Calendar' } = {},
          resourceAndProductionView: {
            title: resourceAndProductionViewTitle = 'Resource & Production Plan'
          } = {}
        } = {},
        productGroupByState: { selectedHierarchies = [] } = {},
        stateHandler: { shiftCalendar }
      } = {},
      viewHandler = () => {},
      clickedProductInfo: { resourceId, periodId: clickedPeriodId },
      stateHandler,
      onResetMoveMode
    } = this.props

    onResetMoveMode()

    switch (menu) {
      case productionViewTitle:
        connectApi('GetRccpProductPeriodResponse', {
          ...initialProductFilters,
          rccpConfig: {
            OverrideGroupByHierarchy: selectedHierarchies
              .map(({ Name }) => Name)
              .concat(gridView === 'Product, Aggregation and Resource' ? 'Resource' : '')
          }
        }).then(() => {
          viewHandler('productView', 'pushPage')
        })
        break
      case resourceViewTitle:
        connectApi('GetRccpResourcePeriodResponse', {
          productId: -1,
          periodId
        }).then(() => {
          viewHandler('resourceView', 'pushPage')
        })
        break
      case resourceScheduleViewTitle:
        connectApi('GetRccpResourceActivityResponseWPF', {
          productId: -1,
          ...initialProductFilters,
          periodId: clickedPeriodId,
          rccpConfig: {
            StartDate: StartTime,
            EndDate: EndTime
          }
        }).then(() => {
          viewHandler('scheduleResourceView', 'pushPage')
        })
        break
      case shiftCalendarViewTitle:
        {
          const { periodIndex, ...restOfShiftCalendar } = shiftCalendar
          stateHandler({
            shiftCalendar: restOfShiftCalendar
          })
          connectApi(
            'GetRccpResourceLineResponse',
            {
              rccpConfig: {
                StartDate: StartTime,
                EndDate: EndTime
              },
              resourceId,
              periodId: clickedPeriodId
            },
            null,
            true
          ).then(() => {
            viewHandler('scheduleLineView', 'pushPage')
          })
        }
        break
      case resourceAndProductionViewTitle:
        connectApi(
          'GetRccpResourcePeriodHierarchyResponseWPF',
          {
            productFilter,
            hierProductFilter: productFilter,
            productId: -1,
            periodId
          },
          '',
          true
        ).then(() => {
          viewHandler('newResourcePlanView', 'pushPage')
        })
        break
      // no default
    }
  }

  handleRefresh = (view) => {
    const {
      settings: {
        appSettings: {
          productionView: { title: productionViewTitle = 'Production Plan' } = {},
          resourceView: { title: resourceViewTitle = 'Resource Plan' } = {},
          resourceScheduleView: { title: resourceScheduleViewTitle = 'Resource Schedule' } = {},
          shiftCalendarView: { title: shiftCalendarViewTitle = 'Shift Calendar' } = {},
          resourceAndProductionView: {
            title: resourceAndProductionViewTitle = 'Resource & Production Plan'
          } = {}
        } = {}
      } = {}
    } = this.props

    this.setState({ isProductEdited: false })

    switch (view) {
      case 'productView':
        this.handleClickMenu(productionViewTitle)
        break
      case 'resourceView':
        this.handleClickMenu(resourceViewTitle)
        break
      case 'scheduleResourceView':
        this.handleClickMenu(resourceScheduleViewTitle)
        break
      case 'scheduleLineView':
        this.handleClickMenu(shiftCalendarViewTitle)
        break
      case 'newResourcePlanView':
        this.handleClickMenu(resourceAndProductionViewTitle)
        break
      // no default
    }
  }

  handleNavigate = (history, key) => {
    const { viewHandler, onResetMoveMode } = this.props
    const { isProductEdited } = this.state

    if (!_.isEmpty(history)) {
      const view = history.constructorKey
      onResetMoveMode()
      if (isProductEdited) {
        this.handleRefresh(view)
      }
      viewHandler(view, key)
    }
  }

  handleShowGroupBy() {
    this.setState((prevState) => ({ showGroupBy: !prevState.showGroupBy }))
  }

  handleClickPegTag() {
    const { connectApi, viewHandler, clickedProductInfo: { pegTags: pegtags } = {} } = this.props
    const { isPegtagActive } = this.state

    const req = {
      pegtags,
      rccpConfig: { pegtags }
    }

    connectApi(
      isPegtagActive
        ? 'GetRccpResourcePeriodHierarchyResponseWPF'
        : 'GetRccpResourcePeriodHierarchyResponseWPegtag',
      { ...(!isPegtagActive && req) },
      !isPegtagActive ? 'GetRccpResourcePeriodHierarchyResponseWPF' : ''
    ).then(() => {
      viewHandler('newResourcePlanView', 'pushPage')
    })
    this.setState((prevState) => ({ isPegtagActive: !prevState.isPegtagActive }))
  }

  handleExpandAll = () => {
    const {
      settings: { views },
      onExpandAllHierarchies,
      onExpandAllProductHierarchies
    } = this.props

    if (views.productView) {
      onExpandAllProductHierarchies()
    } else {
      onExpandAllHierarchies()
    }
  }

  getIsExpanded = () => {
    const {
      settings: { views },
      allHierarchiesExpanded,
      allProductHierarchiesExpanded
    } = this.props

    if (views.productView) {
      return allProductHierarchiesExpanded
    }

    return allHierarchiesExpanded
  }

  render() {
    let {
      settings = {},
      settings: {
        topMenu,
        history: {
          state: { back, next }
        },
        views: {
          productView,
          resourceView,
          scheduleResourceView,
          scheduleLineView,
          filteredProduct,
          newResourcePlanView
        },
        productionVariants: {
          defaultProductionVariant = 'Production',
          userOptions: {
            isEnabled: isUserOptionsEnabled = false,
            optionTitle = '',
            optionList = []
          } = {}
        } = {},
        appSettings: {
          productionView: {
            title: productionViewTitle = 'Production Plan',
            gridView = 'Product'
          } = {},
          resourceView: { title: resourceViewTitle = 'Resource Plan' } = {},
          resourceScheduleView: { title: resourceScheduleViewTitle = 'Resource Schedule' } = {},
          shiftCalendarView: { title: shiftCalendarViewTitle = 'Shift Calendar' } = {},
          resourceAndProductionView: {
            title: resourceAndProductionViewTitle = 'Resource & Production Plan'
          } = {}
        } = {},
        stateHandler: {
          currentMenuItem,
          currentMenuState,
          selectedParameter: { tableName = '' } = {},
          selectedProductVariant = ''
        },
        modificationCount,
        maxModificationCount,
        resourceAndProductPlanSettings: {
          groupBy: {
            isEnabled: isGroupByEnabled = false,
            buttonTitle: groupByButtonTitle = ''
          } = {},
          moveMode: { isEnabled: isMoveModeEnabled = false } = {},
          pegTagFilter: {
            isEnabled: isPegTagFilterEnabled = true,
            buttonTitle: pegTagFilterButtonTitle = 'pegtag filter',
            buttonIcon: pegTagFilterButtonIcon = 'fa-fw fa slvy-ui-icon-tag-extra-lt'
          } = {},
          sessions = {}
        } = {}
      },
      GetRccpGroupBySetting = {},
      viewHandler,
      lastRequest: {
        GetRccpProductPeriodResponse: {
          Request: {
            info: { PeriodName = '', Resource: { Name: ResourceName = '' } = {} } = {}
          } = {}
        } = {}
      } = {},
      moveMode,
      stateHandler,
      periodDates = {},
      allHierarchiesExpanded = false,
      isAllowed = () => {},
      connectApi = () => {},
      clickedProductInfo: {
        productIndex: clickedProductIndex,
        // pegTags = [],
        hasProduction = 0
      },
      selectedSession
    } = this.props
    const isHeaderActive =
      Math.max(
        productView,
        resourceView,
        scheduleResourceView,
        filteredProduct,
        scheduleLineView,
        newResourcePlanView
      ) === 1
    const mergeSelectedParameterNames = this.mergeSelectedParameterNames()

    selectedProductVariant = !selectedProductVariant
      ? defaultProductionVariant
      : selectedProductVariant

    const { showGroupBy, isPegtagActive } = this.state

    return (
      isHeaderActive && (
        <div>
          <section className="slvy-ui-header">
            <div style={{ display: 'flex' }}>
              <Dropdown settings={settings} onClickMenu={this.handleClickMenu.bind(this)} />
              <section className="-title-left">
                <i
                  className={`slvy-ui-icon-arrow-left-extra-lt ${
                    _.isEmpty(back) ? '-disabled' : ''
                  }`}
                  title="Back"
                  onClick={(e) => this.handleNavigate(back, 'back')}
                />

                <i
                  className={`slvy-ui-icon-arrow-right-extra-lt ${
                    _.isEmpty(next) ? '-disabled' : ''
                  }`}
                  title="Forward"
                  onClick={(e) => this.handleNavigate(next, 'next')}
                />
                <div className="pageTitle">
                  {productView && productionViewTitle}
                  {resourceView && resourceViewTitle}
                  {scheduleResourceView && resourceScheduleViewTitle}
                  {scheduleLineView && shiftCalendarViewTitle}
                  {filteredProduct && (
                    <span>{`Production Plan (${ResourceName} / ${PeriodName})`}</span>
                  )}
                  {newResourcePlanView && resourceAndProductionViewTitle}
                </div>
              </section>
            </div>
            <section className="-title-right">
              <span className="-button-container">
                {isMoveModeEnabled && newResourcePlanView && (
                  <MoveMode connectApi={connectApi} setAppState={stateHandler} />
                )}
                {isPegTagFilterEnabled && (
                  <button
                    className={cx({ '-active': isPegtagActive })}
                    disabled={!hasProduction && !isPegtagActive}
                    type="button"
                    onClick={this.handleClickPegTag}
                  >
                    <i
                      className={
                        isPegtagActive ? 'fa-fw fa slvy-ui-icon-times-lt' : pegTagFilterButtonIcon
                      }
                    />
                    <span>{pegTagFilterButtonTitle}</span>
                  </button>
                )}
                <Sessions
                  isSupplyPlan={newResourcePlanView}
                  settings={sessions}
                  onConnectApi={connectApi}
                />
                {(newResourcePlanView ||
                  ['Product and Aggregation', 'Product, Aggregation and Resource'].includes(
                    gridView
                  )) && (
                  <div className="expand-all" onClick={this.handleExpandAll}>
                    <i
                      className={
                        this.getIsExpanded()
                          ? 'fa-fw fa slvy-ui-icon-minus-outline'
                          : 'fa-fw fa slvy-ui-icon-plus-outline'
                      }
                    />
                    {this.getIsExpanded() ? 'collapse all' : 'expand all'}
                  </div>
                )}
                <div className="dynamic-group-by-trigger">
                  {(newResourcePlanView ||
                    ['Product and Aggregation', 'Product, Aggregation and Resource'].includes(
                      gridView
                    )) &&
                    isGroupByEnabled && (
                      <button
                        disabled={moveMode.isOn}
                        type="button"
                        onClick={() => {
                          this.setState({ showGroupBy: true })
                        }}
                      >
                        <i className="fa-fw fa slvy-ui-icon-options" />
                        <span>{groupByButtonTitle.toLowerCase()}</span>
                      </button>
                    )}
                  {showGroupBy && (
                    <GroupBy
                      {...this.props}
                      GetRccpGroupBySetting={GetRccpGroupBySetting}
                      connectApi={connectApi}
                      isProductView={productView}
                      modificationCount={modificationCount}
                      periodDates={periodDates}
                      show={showGroupBy}
                      stateHandler={stateHandler}
                      viewHandler={viewHandler}
                      onHide={() => this.setState({ showGroupBy: false })}
                    />
                  )}
                </div>

                <div className="button">
                  <i className="slvy-ui-icon-cog-outline" />
                  <span>settings</span>
                  <ul className="headerMenu" style={{ width: 160 }}>
                    <li className="sectionTitle">Unit</li>
                    {_.map(mergeSelectedParameterNames, (sp, spi) => {
                      const { name, tableName: spname } = sp

                      return (
                        <li key={spi}>
                          <input
                            defaultChecked={tableName === spname}
                            id={`btnType_${spi}`}
                            name="btnType"
                            type="radio"
                            onClick={() => {
                              stateHandler({
                                selectedParameter: sp
                              })
                            }}
                          />
                          <label htmlFor={`btnType_${spi}`}>{name}</label>
                        </li>
                      )
                    })}
                  </ul>
                  {isUserOptionsEnabled && !newResourcePlanView && (
                    <ul className="headerMenu" style={{ top: 140, width: 160 }}>
                      <li className="sectionTitle">{optionTitle}</li>
                      {_.map(optionList, ({ name, tableName }, index) => {
                        return (
                          <li key={`${name}-${index}`}>
                            <input
                              defaultChecked={selectedProductVariant === tableName}
                              id={`btnType-${name}-${index}`}
                              name="btnType-production-variant"
                              type="radio"
                              onClick={() => {
                                stateHandler({
                                  selectedProductVariant: tableName
                                })
                              }}
                            />
                            <label htmlFor={`btnType-${name}-${index}`}>{name}</label>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
                {/* <button>
                                <i className="slvy-ui-icon-sort-up" />
                                <span>Sort</span>
                            </button> */}
                {_.map(topMenu, (topMenuItem, topMenuIndex) => {
                  const {
                    // active,
                    enable,
                    name,
                    iconName,
                    action
                  } = topMenuItem
                  const isActive = currentMenuItem === name && currentMenuState

                  return (
                    enable && (
                      <button
                        key={topMenuIndex}
                        className={`${isActive ? '-active' : ''}`}
                        disabled={
                          !isAllowed('Top Menu Buttons') ||
                          (selectedSession.IsDebug && newResourcePlanView) ||
                          moveMode.isOn
                        }
                        title={
                          name === 'undo' || name === 'redo'
                            ? `${modificationCount} / ${maxModificationCount}`
                            : ''
                        }
                        type="button"
                        onClick={this.buttonClickHandler.bind(this, topMenuItem)}
                      >
                        <i className={iconName} />
                        <span>{name}</span>
                      </button>
                    )
                  )
                })}
              </span>
            </section>
          </section>
        </div>
      )
    )
  }
}

const mapStateToProps = (state) => ({
  allHierarchiesExpanded: state.resourceRowItem.allHierarchiesExpanded,
  allProductHierarchiesExpanded: state.PRODUCTresourceRowItem.allHierarchiesExpanded,
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
  selectedSession: state.resourcePlan.selectedSession,
  moveMode: state.moveMode
})

const mapDispatchToProps = {
  onExpandAllHierarchies: expandAllHierarchies,
  onExpandAllProductHierarchies: PRODUCTexpandAllHierarchies,
  onResetMoveMode: resetMoveMode
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
