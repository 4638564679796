import React, { Component } from 'react'
import _ from 'lodash'
import { slvyToast } from '../../../../../../components'

export default class MultiIssueList extends Component {
  constructor(props) {
    super(props)

    const {
      settings: {
        resourceAndProductPlanSettings: { metricSettings: { metricLimit = 4 } = {} } = {}
      } = {}
    } = props

    this.listLimit = metricLimit || 4
  }

  legendHandlerProxy = (newState) => {
    const { legendHandler, historyHandler } = this.props

    if (newState.currentProductInfoIndex.length <= this.listLimit) {
      legendHandler(newState)

      historyHandler({
        key: 'IssueList',
        state: newState
      })
    } else {
      slvyToast.warning({ message: `You can select maximum ${this.listLimit} items` })
    }
  }

  render() {
    const {
      issueList = [],
      infoList = [],
      checkboxName,
      legendState: { currentIssueIndex = 0, currentProductInfoIndex = [], isProductInfo } = {},
      height
    } = this.props

    return (
      <div>
        <div style={{ height }}>
          {_.map(issueList, (issueItem, issueIndex) => {
            const { name, color: backgroundColor } = issueItem

            const isActive = issueIndex === currentIssueIndex
            const activeStyle = isActive ? { backgroundColor } : {}

            const legendParams = {
              currentProductInfoIndex: [],
              currentIssueIndex: issueIndex,
              isProductInfo: false
            }

            return (
              <div key={issueIndex} className="msb-custom-radio" title={name}>
                <input
                  readOnly
                  checked={isActive}
                  id={`legend_${issueIndex}`}
                  name="radio_list"
                  type="radio"
                />
                <label
                  htmlFor={`legend_${issueIndex}`}
                  onClick={() => this.legendHandlerProxy(legendParams)}
                >
                  <div className="msb-cr">
                    <i style={activeStyle} />
                  </div>
                  <span>{name}</span>
                </label>
              </div>
            )
          })}
        </div>

        <div className="msb-custom-radio -no-border -no-pointer">
          <input
            readOnly
            checked={isProductInfo}
            id="legend_detail"
            name="radio_list"
            type="radio"
          />
          <label htmlFor="legend_detail">
            <div className="msb-cr" />
            <span className="legend-heading">Values</span>
          </label>
        </div>

        {_.map(infoList, (pListItem, pListIndex) => {
          const { name } = pListItem
          const checked = currentProductInfoIndex.indexOf(pListIndex) > -1

          return (
            <div key={pListIndex} className="msb-custom-radio -xs -no-border" title={name}>
              <input
                readOnly
                checked={checked}
                id={`sub_selection_${pListIndex}`}
                name={`${checkboxName}`}
                type="checkbox"
              />
              <label
                htmlFor={`sub_selection_${pListIndex}`}
                onClick={() => {
                  const arr = checked
                    ? _.remove(currentProductInfoIndex, (item) => item !== pListIndex)
                    : _.concat(currentProductInfoIndex, pListIndex)

                  const legendParams = {
                    currentProductInfoIndex: arr,
                    currentIssueIndex: -1,
                    isProductInfo: true
                  }

                  this.legendHandlerProxy(legendParams)
                }}
              >
                <div className="msb-cr">
                  <i />
                </div>
                <span>{name}</span>
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}
