import { connect } from 'react-redux'
import App from '../app'
import {
  saveSetFilterRemote,
  saveSetGenerateReportTree,
  saveSetFilterTreeRemote,
  saveDefaultActionParams,
  saveScenarioTreeViewSettings,
  saveSubsetViewSettings,
  savePivotBuilderViewSettings,
  savePivotViewSettings,
  savePivotDetailViewSettings,
  savePivotSingleDetailViewSettings,
  savePivotMainConfiguration,
  saveTimelineSettings,
  resetTimelineSettings,
  updateMfpData as callUpdateMfpData,
  setLoader as setActionLoader,
  saveScenarioComparisonSettings,
  saveHeaderSettings,
  selectScenario,
  unCollapse as onAddUnCollapsedScenarios,
  saveMfpSettings,
  fetchCurrencyRates,
  fetchUserScenario,
  saveGetUserScenario,
  saveCurrencyRatesSettings,
  resetScenarioSelection,
  increasePendingUpdateCount,
  decreasePendingUpdateCount,
  savePendingUpdateCount
} from '../store/actions'

const mapStateToProps = (state) => {
  return {
    unCollapsedScenarios: state.collapse.scenarioTree.unCollapsedScenarios,
    GetScenarioTree: state.scenarioTree.GetScenarioTree,
    GetScenarioTreeError: state.scenarioTree.error,
    GetSetGenerateReportTree: state.setGenerateReportTree.GetSetGenerateReportTree,
    GetSetFilterRemote: state.setFilterRemote.GetSetFilterRemote,
    GetSetFilterTreeRemote: state.setFilterTreeRemote.GetSetFilterTreeRemote,
    selectedScenarioTree: state.scenarioTreeSelection.scenarioTree,
    GetPivotConfiguration: state.getPivotConfiguration.GetPivotConfiguration,
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    scenarioTreeViewSettings: state.scenarioTreeViewSettings.scenarioTreeViewSettings,
    subsetViewSettings: state.subsetViewSettings.subsetViewSettings,
    pivotBuilderViewSettings: state.pivotBuilderViewSettings.pivotBuilderViewSettings,
    pivotViewSettings: state.pivotViewSettings.pivotViewSettings,
    pivotDetailViewSettings: state.pivotDetailViewSettings.pivotDetailViewSettings,
    pivotSingleDetailViewSettings:
      state.pivotSingleDetailViewSettings.pivotSingleDetailViewSettings,
    pivotMainConfiguration: state.pivotMainConfiguration.pivotMainConfiguration,
    scenarioComparisonMainConfiguration:
      state.scenarioComparisonMainConfiguration.scenarioComparisonMainConfiguration,
    GetPeriods: state.getPeriods.GetPeriods,
    GetMfpData: state.getMfpData.GetMfpData,
    GetMfpDetailData: state.getMfpDetailData.GetMfpDetailData,
    GetMfpDetailDataForSingleColumn:
      state.getMfpDetailDataForSingleColumn.GetMfpDetailDataForSingleColumn,
    GetUserScenario: state.getUserScenario.GetUserScenario,
    UpdateMfpData: state.updateMfpData.UpdateMfpData,
    UpdateMfpDataPendingCount: state.updateMfpData.pendingCount,
    headerSettings: state.headerSettings.headerSettings,
    timelineSettings: state.timelineSettings.timelineSettings,
    scenarioComparisonSettings: state.scenarioComparisonSettings.scenarioComparisonSettings,
    Loader: state.loader.loader,
    mfpSettings: state.mfpSettings.mfpSettings,
    GetMfpCurrencyRates: state.getMfpCurrencyRates.GetMfpCurrencyRates,
    currencyRatesSettings: state.currencyRatesSettings.currencyRatesSettings,
    quickFilterSettings: state.quickFilterSettings.quickFilterSettings
  }
}

const mapDispatchToProps = {
  saveSetFilterRemote,
  saveSetGenerateReportTree,
  saveSetFilterTreeRemote,
  saveDefaultActionParams,
  saveScenarioTreeViewSettings,
  saveSubsetViewSettings,
  savePivotBuilderViewSettings,
  savePivotViewSettings,
  savePivotDetailViewSettings,
  savePivotSingleDetailViewSettings,
  savePivotMainConfiguration,
  saveTimelineSettings,
  resetTimelineSettings,
  callUpdateMfpData,
  setActionLoader,
  saveScenarioComparisonSettings,
  saveHeaderSettings,
  selectScenario,
  onAddUnCollapsedScenarios,
  saveMfpSettings,
  fetchCurrencyRates,
  fetchUserScenario,
  saveGetUserScenario,
  saveCurrencyRatesSettings,
  resetScenarioSelection,
  increasePendingUpdateCount,
  decreasePendingUpdateCount,
  savePendingUpdateCount
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
