import { useCallback } from 'react'
import { IRowNode } from 'ag-grid-community'
import { IRowData } from '../../types'
import { ISelectedFirstRowDataAfterFilterAndSort } from './useFirstRowDataAfterFilterAndSort.types'

function useFirstRowDataAfterFilterAndSort(props: ISelectedFirstRowDataAfterFilterAndSort) {
  const { gridRef, checkIsSelected = false } = props

  const getFirstRowDataAfterFilterAndSort = useCallback(() => {
    let result: IRowData | null = null

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line consistent-return
    gridRef?.current?.api?.forEachNodeAfterFilterAndSort?.((node: IRowNode<IRowData>) => {
      if ((checkIsSelected ? node?.isSelected?.() : true) && node?.data && !result) {
        result = node.data
        return result
      }
    })

    return result
  }, [checkIsSelected, gridRef])

  return { getFirstRowDataAfterFilterAndSort }
}

export default useFirstRowDataAfterFilterAndSort
