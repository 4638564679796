export default `({
    plugins: [{
        ptype: 'rowexpander',
        rowBodyTpl: [
            '<div class="expander">',
            '<table class="table">',
            '<tr>',
            '</tr>',
            '  <tr>',
            '       <th class=r>Solvoyo</th>',
            '      <td class="r">{OrderRecommendationParent}</td>',
            '       <th class=r>'+tt('Merkez')+'</th>',
            '      <td class="r">{OrderRecommendationCurrent}</td>',
            '      <th class="r">'+tt('Bölge')+'</th>',
            '      <td class="r">{OrderRecommendationChild}</td>',
            '   </tr>',
            // '  <tr>',
            // '       <th class=r>NumberOf Org</th>',
            // '      <td class="r">{NumberOfRecommendedOrg}</td>',
            // '     <th class="r">Waiting Org</th>',
            // '      <td class="r">{NumberOfWaitingOrg}</td>',
            // '       <th class="r"> Delegated</th>',
            // '      <td class="r">{NumberOfDelegatedOrg}</td>',
            // '   </tr>',
            // '</tr>',
            // '  <tr>',
            // '      <th class="r"> Approved </td>',
            // '      <td class="r">{NumberOfApprovedOrg}</td>',
            // '      <th class="r">Dismissed</td>',
            // '      <td class="r" >{NumberOfDismissedOrg}</td>',
            // '   </tr>',
            '</table>',
            '</div>',
        ]
    },
    {
        ptype: 'gridfilters',
        id: 'gridfilters'
    }],
    multiColumnSort: true,
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    columnLines: true,
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        var me = this;
        var msg = tt('Are you sure you want to cancel this record?');
        if(newStatus=='NEGOTIATION')
        {
            msg= tt('Değerlendirmeye yollamak istediğinizden emin misiniz?');
        }
        Ext.Msg.show({
            title: tt('Onay'),
            //height: 160,
            width: 500,
            msg: msg,
            buttons: Ext.Msg.YESNO,
            icon: Ext.MessageBox.QUESTION,
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            fn: function (response) {
                if (response === 'yes') {
                    me.setLoading(tt('yükleniyor'));
                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                        //             ALTER PROCEDURE [www].[ChangeOverRecommendation]
                        //   @SpotID AS INT ,
                        //   -- @UserID AS INT = NULL,
                        //     @Orgcode AS NVARCHAR(18)=NULL
                        method: 'POST',
                        timeout: 60000,
                        params: {
                            data: data,
                            newStatus: newStatus
                        },
                        success: function (response, opts) {
                            me.setLoading(false)
                            me.ownerCt.onRefrehTracking()
                            //me.up('spui-view-main-main').getController().changeView();
                        },
                        failure: function (response, opts) {
                            me.setLoading(false)
                            me.ownerCt.onRefrehTracking()
                            console.log('server-side failure with status code ' + response.status);
                        }
                    });
                }
            }
        });
    },
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            //locked: true,
            width: 30,
            align: 'center',
            items: [{
                tooltip: tt('Afişe koy'),
                iconCls: 'fa fa-newspaper-o blue',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeStatus(record, 'NEGOTIATION')
                },
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            //   locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa  fa-ban red',
                tooltip: tt('İptal'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeStatus(record, 'Dismiss')
                },
            }]
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            renderer: function (v) {
                return v * 1;
            },
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('Start Date'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('End Date'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        // {
        //     text: tt('SalesPrice (₺)'),
        //     dataIndex: 'SalesPrice',
        //     width: 100,
        //     format: '0,000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn',
        // },
        // {
        //     text: 'DaysForApprove',
        //     dataIndex: 'DaysForApprove',
        //     locked: true,
        //     width: 100,
        //     format: '000',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        {
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('RemainingDaysForApprove'),
            dataIndex: 'RemainingDaysForApprove',
            width: 100,
            format: '000',
            align: 'right',
            filter: {
                type: 'number'
            },
            renderer: function (value, metaData, record) {
                var DaysForApprove = record.get('DaysForApprove');
                return value + " / " + DaysForApprove
            }
        },
        {
            text: tt('Progress'),
            xtype: 'widgetcolumn',
            dataIndex: 'CompletionRatio',
            width: 120,
            filter: {
                type: 'number'
            },
            sortable:true,
            widget: {
                bind: '{record.CompletionRatio}',
                xtype: 'progressbarwidget',
                textTpl: [
                    '{percent:number("0")}%'
                ]
            }
        },
        // {
        //     text: 'SpotId',
        //     dataIndex: 'SpotId',
        //     width: 100,
        //     format: '000',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        {
            text: tt('Status'),
            columns: [{
                    text: tt('Total'),
                    tooltip: tt('TotalTip'),
                    dataIndex: 'NumberOfRecommendedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('Waiting'),
                    tooltip: tt('WaitingTip'),
                    dataIndex: 'NumberOfWaitingOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfDelegatedOrg');
                    //     r = parseInt(t) + parseInt(value);
                    //     value = Ext.util.Format.number(r, '0');
                    //     return value
                    // }
                },
                {
                    text: tt('Delegated'),
                    dataIndex: 'NumberOfDelegatedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfRecommendedOrg');
                    //     per = t === 0 ? 0 : value / t;
                    //     per = Ext.util.Format.number(per * 100, '0%');
                    //     value = Ext.util.Format.number(value, '0');
                    //     return "<b>" + value + "</b> [" + per + "]"
                    // }
                },
                {
                    text: tt('Approved'),
                    dataIndex: 'NumberOfApprovedOrg',
                    width: 80,
                    format: '000',
                    filter: {
                        type: 'number'
                    },
                    align: 'right',
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfRecommendedOrg');
                    //     per = t === 0 ? 0 : value / t;
                    //     per = Ext.util.Format.number(per * 100, '0%');
                    //     value = Ext.util.Format.number(value, '0');
                    //     return "<b>" + value + "</b> [" + per + "]"
                    // }
                },
                {
                    text: tt('Dismissed'),
                    dataIndex: 'NumberOfDismissedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn',
                    // renderer: function (value, metaData, record) {
                    //     var t = record.get('NumberOfRecommendedOrg');
                    //     per = t === 0 ? 0 : value / t;
                    //     per = Ext.util.Format.number(per * 100, '0%');
                    //     value = Ext.util.Format.number(value, '0');
                    //     return "<b>" + value + "</b> [" + per + "]"
                    // }
                },
            ]
        },
        {
            text: tt('CreatedBy'),
            dataIndex: 'CreatedBy',
            filter: {
                type: 'string'
            },
            width: 140
        }
        // {
        //     text: "Recom",
        //     columns: [{
        //             text: 'Parent',
        //             dataIndex: 'OrderRecommendationParent',
        //             width: 100,
        //             format: '000.00',
        //             align: 'right',
        //             xtype: 'numbercolumn'
        //         },
        //         {
        //             text: 'Current',
        //             dataIndex: 'OrderRecommendationCurrent',
        //             width: 100,
        //             format: '000.00',
        //             align: 'right',
        //             xtype: 'numbercolumn'
        //         },
        //         {
        //             text: 'Child',
        //             dataIndex: 'OrderRecommendationChild',
        //             width: 100,
        //             format: '000.00',
        //             align: 'right',
        //             xtype: 'numbercolumn'
        //         },
        //     ]
        // },
        // {
        //     text: 'Hierarcy',
        //     dataIndex: 'MaterialGroupName',
        //     width: 200
        // },
        // {
        //     text: 'PreStartDate',
        //     dataIndex: 'PreStartDate',
        //     width: 100
        // },
        // {
        //     text: 'PreEndDate',
        //     dataIndex: 'PreEndDate',
        //     width: 100
        // },
        // {
        //     text: 'CreatedBy',
        //     dataIndex: 'CreatedBy',
        //     width: 200
        // },
        // {
        //     text: 'CreatedAt',
        //     dataIndex: 'CreatedAt',
        //     width: 100
        // },
        // {
        //     text: 'IsDeleted',
        //     dataIndex: 'IsDeleted',
        //     width: 100
        // },
        // {
        //     text: 'DeletedBy',
        //     dataIndex: 'DeletedBy',
        //     width: 200
        // },
        // {
        //     text: 'DeletedAt',
        //     dataIndex: 'DeletedAt',
        //     width: 100
        // },
        // {
        //     text: 'CategoryManagerCode',
        //     dataIndex: 'CategoryManagerCode',
        //     width: 200
        // },
        // {
        //     text: 'PuplishedBy',
        //     dataIndex: 'PuplishedBy',
        //     width: 200
        // },
        // {
        //     text: 'PublishDate',
        //     dataIndex: 'PublishDate',
        //     width: 100
        // },
        // {
        //     text: 'DaysForTargetSales',
        //     dataIndex: 'DaysForTargetSales',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'ImgURL',
        //     dataIndex: 'ImgURL',
        //     width: 200
        // },
        // {
        //     text: 'Notes',
        //     dataIndex: 'Notes',
        //     width: 200
        // },
        // {
        //     text: 'StatusCode',
        //     dataIndex: 'StatusCode',
        //     width: 200
        // },
        // {
        //     text: 'UpdatedRecommendation',
        //     dataIndex: 'UpdatedRecommendation',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'ApprovedRecommendation',
        //     dataIndex: 'ApprovedRecommendation',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'NumberOfDismissedOrg',
        //     dataIndex: 'NumberOfDismissedOrg',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'IsEditable',
        //     dataIndex: 'IsEditable',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // }
    ],
})
`
