export default () => {
  return {
    title: 'Task Tracking',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: false,
            required: true
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          assigneeChangeabilityDuringCardTransportation: {
            type: 'boolean',
            title: 'Assignee Changeability During Card Moving',
            default: false,
            required: true
          },
          swimlaneBasedUsersEnabled: {
            type: 'boolean',
            title: 'Swimlane-Based User List Authorization Enabled',
            default: false,
            required: true
          },
          makeAssigneeSameReporterInCreating: {
            type: 'boolean',
            title: 'Make Assignee Same Reporter In Creating',
            default: false,
            required: true
          },
          ShowSelfTasksToAnother: {
            type: 'boolean',
            title: 'Show Self Tasks To Another',
            default: false,
            required: true
          }
        }
      },
      componentsAppearance: {
        type: 'object',
        title: 'Components appearance',
        properties: {
          card: {
            type: 'object',
            title: 'Card components',
            options: {
              collapsed: true
            },
            properties: {
              assigneeName: {
                type: 'boolean',
                title: 'Assignee Name',
                default: true,
                required: true
              },
              reporterName: {
                type: 'boolean',
                title: 'Reporter Name',
                default: true,
                required: true
              },
              createdDate: {
                type: 'boolean',
                title: 'Created Date',
                default: true,
                required: true
              },
              dueDate: {
                type: 'boolean',
                title: 'Due Date',
                default: true,
                required: true
              },
              createdBefore: {
                type: 'boolean',
                title: 'Created Before',
                default: true,
                required: true
              },
              daysLeft: {
                type: 'boolean',
                title: 'Days Left',
                default: true,
                required: true
              },
              idAsPrefixWithSubjectVisibility: {
                type: 'boolean',
                title: 'Show task Id as prefix with subject',
                default: false
              }
            }
          },
          adding: {
            type: 'object',
            title: 'Adding components',
            options: {
              collapsed: true
            },
            properties: {
              categoryTitle: {
                type: 'string',
                title: 'Category Title',
                default: 'Category'
              },
              priorityTitle: {
                type: 'string',
                title: 'Priority Title',
                default: 'Priority'
              },
              priorityVisibility: {
                type: 'boolean',
                title: 'Priority Visibility',
                default: true
              },
              attachmentVisibility: {
                type: 'boolean',
                title: 'Attachment Visibility',
                default: true
              },
              duedateVisibility: {
                type: 'boolean',
                title: 'Duedate Visibility',
                default: true
              }
            }
          },
          editing: {
            type: 'object',
            title: 'Editing components',
            options: {
              collapsed: true
            },
            properties: {
              categoryTitle: {
                type: 'string',
                title: 'Category Title',
                default: 'Category'
              },
              statusTitle: {
                type: 'string',
                title: 'Status Title',
                default: 'Status'
              },
              statusVisibility: {
                type: 'boolean',
                title: 'Status Visibility',
                default: true
              },
              priorityTitle: {
                type: 'string',
                title: 'Priority Title',
                default: 'Priority'
              },
              priorityVisibility: {
                type: 'boolean',
                title: 'Priority Visibility',
                default: true
              },
              substatusTitle: {
                type: 'string',
                title: 'Substatus Title',
                default: 'Substatus'
              },
              substatusVisibility: {
                type: 'boolean',
                title: 'Substatus Visibility',
                default: true
              },
              attachmentsTabVisibility: {
                type: 'boolean',
                title: 'Attachments Tab Visibility',
                default: true
              },
              duedateVisibility: {
                type: 'boolean',
                title: 'Duedate Visibility',
                default: true
              }
            }
          }
        }
      },
      format: {
        type: 'object',
        title: 'Format',
        properties: {
          prefix: {
            type: 'string',
            title: 'Task Prefix'
          },
          idDigitNumber: {
            type: 'string',
            title: 'Id Digit Number'
          },
          postfix: {
            type: 'string',
            title: 'Task Postfix'
          }
        }
      }
    }
  }
}
