Ext.define('MDM.src.Task', {
  singleton: true,
  task: null,

  start: function () {
    if (this.task) {
      Ext.TaskManager.stop(this.task)
    }
    this.task = {
      run: function () {
        Ext.Ajax.request({
          url: MDM.Global.getApiUrl() + 'gillettev3/getTask',

          success: function (response) {
            var data = Ext.JSON.decode(response.responseText)

            var me = this
            if (data && data.show) {
              me.config = {
                title: 'Info',
                html: data.msg,
                align: 'br',
                width: 400,
                height: 200,
                closable: true
              }
              Ext.toast(me.config)
            }
          }
        })
      },
      interval: 30000
    }
  }
})
