import { Component, createElement } from 'react'
import { render as reactDOMRender } from 'react-dom'
import { Modal, Button } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

/* Components */
import { SlvyCodeMirror } from '../..'

/* Styles */
import './CodeEditor.scss'

window.JSONEditor.defaults.editors.CodeEditor = window.JSONEditor.AbstractEditor.extend({
  renderReact() {
    const self = this
    const { schema: { options = {} } = {} } = self
    const element = createElement(CodeBox, {
      value: this.value,
      onChange: (a) => {
        self.setValue(a)
        self.onChange(true)
      },
      title: this.schema.title ? this.schema.title : this.key,
      format: this.schema.format,
      options
    })
    reactDOMRender(element, this.container)
  },
  setValue(value, initial) {
    this.value = value
    this.renderReact()
    this.onChange()
  },
  build() {
    this.renderReact()
  }
})

class CodeBox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)

    this.dispatchChange = this.dispatchChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)

    this.styleId = uuidv4()

    this.state = {
      query: props.value
    }

    this.que = 0
  }

  handleChange(newCode) {
    const { options: { base64 = false } = {} } = this.props

    const value = base64 ? btoa(newCode) : newCode

    this.setState({ query: value })
    if (this.que !== 0) {
      clearTimeout(this.que)
    }

    this.que = setTimeout(this.dispatchChange, 2000)
  }

  dispatchChange() {
    this.props.onChange(this.state.query)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ query: nextProps.value })
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  render() {
    const {
      options: {
        mode = 'text/javascript',
        lineNumbers = true,
        readOnly = false,
        base64 = false,
        globalVars = true
      } = {}
    } = this.props

    const { query = '' } = this.state
    if (base64) {
    }
    const value = base64 ? query !== null && atob(query) : query

    return (
      <div className="form-group">
        <label className=" col-form-label">{this.props.title}</label>

        <Button size="sm" variant="outline-dark" onClick={this.handleShow}>
          Edit Code
        </Button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SlvyCodeMirror
              className="queryEditor"
              isThemeSelectable={false}
              options={{
                mode: {
                  name: mode,
                  globalVars
                },
                lineNumbers,
                readOnly
              }}
              value={value || ''}
              onChange={this.handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="outline-dark" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default {
  name: 'CodeEditor',
  format: 'Code'
}
