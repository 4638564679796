Ext.define('Assortment.eader.AssortmentFilterReader', {
  extend: 'Ext.data.reader.Json',
  alias: 'reader.assortmentFilterReader',

  getResponseData: function (response) {
    var result = response.responseJson
    return result.Values.map(function (value) {
      return { name: value }
    })
  }
})
