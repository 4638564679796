import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import axiosWithDefaults from './axiosWithDefaults'
import { slvyToast } from '../../../components'
import { MESSAGES } from '../messages'
import { API_URL } from '@/constants'

export async function handleAsyncAwait(promise) {
  try {
    const data = await promise
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const getActualScenarioIdsAsBoolean = (scenarios) => {
  return scenarios.map((scenario) => scenario.currentScenario.IsActualized)
}

export const getCopiedBoundingClientRect = (element) => {
  const { top, right, bottom, left, width, height, x, y } = element
  return { top, right, bottom, left, width, height, x, y }
}

export async function makeRequest(action) {
  const { payload: { pluginId = '', method = '', body = {}, requestMethod = 'get' } = {} } = action

  const url = `${API_URL}/data/${pluginId}/invoke/MfpRequest/${method}`

  const controller = new AbortController()
  window.__SOLVOYO__DP__REQUEST__POOL.push(controller)

  const request = axiosWithDefaults({
    url,
    method: requestMethod,
    signal: controller.signal,
    ...(requestMethod === 'post' ? { data: body } : {})
  })

  const [response, error] = await handleAsyncAwait(request)

  if (!navigator.onLine) {
    throw { data: { Errors: ['No internet connection'], Guid: null } }
  }

  if (error) {
    slvyToast.setOptions({ containerId: 'dp', autoClose: false })
    throw error
  }

  // success
  slvyToast.setOptions({ containerId: 'dp' })
  slvyToast.dismiss()

  return response
}

export const _mergeConfig = ($pivotConfig, $pluginConfig) => {
  $pivotConfig = _.cloneDeep($pivotConfig)
  $pluginConfig = _.cloneDeep($pluginConfig)

  const _$pluginConfig = {}

  Object.keys($pluginConfig).forEach((pluginDraggableName) => {
    let currentDraggableContainer = $pluginConfig[pluginDraggableName] || []

    currentDraggableContainer = currentDraggableContainer
      .filter((pcItem) => {
        return [...$pivotConfig].find((pivotItem) => pivotItem.Name === pcItem.Name)
      })
      .map((pcItem) => {
        const foundPivot = [...$pivotConfig].find((pivotItem) => pivotItem.Name === pcItem.Name)
        return { ...pcItem, ...foundPivot }
      })

    _$pluginConfig[pluginDraggableName] = currentDraggableContainer
  })

  return _$pluginConfig
}

export const pickSettings = ($settings) => {
  return _.pick($settings, ['Name', 'LeftAxisDraggable', 'TopAxisDraggable', 'ValuesDraggable'])
}

export const hasSameColumnType = ($configuration, $pluginState) => {
  let $configArr = []
  Object.keys($configuration).forEach(
    (item) => ($configArr = [...$configArr, ...$configuration[item]])
  )

  let $pluginStateArr = []
  Object.keys($pluginState).forEach(
    (item) => ($pluginStateArr = [...$pluginStateArr, ...$pluginState[item]])
  )

  const $configArrLength = $configArr.length
  const $pluginStateArrLength = $pluginStateArr.length

  const result = {
    columnsDeleted: false,
    columnsChanged: false,
    columnsChangedMessages: [],
    changedColumns: []
  }

  const isColumnDeleted = $configArrLength < $pluginStateArrLength
  const pluginStateWithoutDeletedColumns = isColumnDeleted
    ? $pluginStateArr.filter((pluginState) => {
        const isExistInConfig = $configArr.some(({ Id }) => Id === pluginState.Id)

        if (!isExistInConfig) {
          result.columnsDeleted = true
          result.changedColumns.push(pluginState)
          result.columnsChangedMessages.push(`${pluginState.Name}`)
        }

        return isExistInConfig
      })
    : $pluginStateArr

  ;[...$configArr].forEach(($configItem) => {
    const $item = pluginStateWithoutDeletedColumns.find(($pluginStateItem) => {
      return _.isEqual(pickSettings($configItem), pickSettings($pluginStateItem))
    })
    if (!$item) {
      result.columnsChanged = true
      result.changedColumns.push($configItem)
      result.columnsChangedMessages.push(`${$configItem.Name}`)
    }
  })

  return result
}

export const getRequestConfig = (pivotMainConfiguration = {}, AggregationFunctions = ['SUM']) => {
  const {
    TopAxisDraggable = [],
    LeftAxisDraggable = [],
    ValuesDraggable = []
  } = pivotMainConfiguration
  const TopFields = [...TopAxisDraggable].filter((item) => item.Checked).map((item) => item.Name)
  const LeftFields = [...LeftAxisDraggable].filter((item) => item.Checked).map((item) => item.Name)
  const MetricFields = [...ValuesDraggable]
    .filter((item) => item.Checked)
    .reduce((obj, item) => {
      obj[item.Name] = AggregationFunctions[0]
      return obj
    }, {})
  return {
    TopFields,
    LeftFields,
    MetricFields
  }
}

export const getFormattedDefaultDate = ($date, $manipulation = 'start') => {
  const $format = 'YYYY-MM-DDT00:00:00'
  const $fn = $manipulation === 'end' ? 'add' : 'subtract'
  return $date ? moment($date).format($format) : moment(new Date())[$fn](10, 'year').format($format)
}

export const getScenarioParameters = (currentScenarios = [], mfpId = null) => {
  const Planned = currentScenarios.find((item) => item.scenarioType === 'Planned').currentScenario
  const Baseline = currentScenarios.find((item) => item.scenarioType === 'Baseline').currentScenario

  return {
    CurrentScenario: currentScenarios,
    params: {
      mfpId,
      BaselineId: Baseline.Id,
      BaselineName: Baseline.Name,
      BaselineScenarioTableName: Baseline.ScenarioTableName,
      PlannedId: Planned.Id,
      PlannedName: Planned.Name,
      PlannedScenarioTableName: Planned.ScenarioTableName
    }
  }
}

export const loaderControl = (guid) => {
  const cellRef = document.querySelector(`[data-id="${guid}"]`)

  if (cellRef) {
    cellRef.querySelector('.-planned').classList.remove('-mini-loading')
  }
}

export const errorControl = ({ guid, hasError, errorClass }) => {
  const cellRef = document.querySelector(`[data-id="${guid}"]`)?.querySelector('.-planned')

  if (!cellRef) {
    return
  }

  if (hasError) {
    cellRef.classList.add(errorClass)
  } else {
    cellRef.classList.remove(errorClass)
  }
}

export function setLoaderWithDispatch(
  dispatch,
  setLoaderFn = () => {},
  isVisible = true,
  messages = 'Loading!',
  guid
) {
  dispatch(
    setLoaderFn({
      payload: {
        isVisible,
        messages
      }
    })
  )
  loaderControl(guid)
}

export function setLoader(setLoaderFn = () => {}, isVisible = true, messages = 'Loading!', guid) {
  setLoaderFn({
    payload: {
      isVisible,
      messages
    }
  })

  loaderControl(guid)
}

export class Queue {
  static queue = []

  static pendingPromise = false

  static stop = false

  static enqueue(promise) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        promise,
        resolve,
        reject
      })
      this.dequeue()
    })
  }

  static dequeue() {
    if (this.workingOnPromise) {
      return false
    }
    if (this.stop) {
      this.queue = []
      this.stop = false
      return
    }
    const item = this.queue.shift()
    if (!item) {
      return false
    }
    try {
      this.workingOnPromise = true
      item
        .promise()
        .then((value) => {
          this.workingOnPromise = false
          item.resolve(value)
          this.dequeue()
        })
        .catch((err) => {
          this.workingOnPromise = false
          item.reject(err)
          this.dequeue()
        })
    } catch (err) {
      this.workingOnPromise = false
      item.reject(err)
      this.dequeue()
    }
    return true
  }

  static reset() {
    this.queue = []
    this.pendingPromise = false
    this.stop = false
  }
}

export const getBaseUpdateRequestParameters = ($props, params) => {
  const RejectNegativeValues = _.get($props, 'settings.config.general.rejectNegativeValues', false)

  const {
    BaselineValue,
    Guid = '',
    LeftKeys = [],
    MetricField = null,
    NewValue = null,
    OldValue = null,
    UpdateDetails = [],
    UpdateType
  } = params

  let $updateDetails = [
    {
      BaselineValue,
      MetricField,
      NewValue,
      OldValue,
      UpdateType
    }
  ]

  if (UpdateDetails.length) {
    // Row Update
    $updateDetails = []
    UpdateDetails.forEach((item) => {
      $updateDetails.push({
        BaselineValue: item.BaselineValue,
        MetricField: item.MetricField,
        NewValue: item.NewValue,
        OldValue: item.OldValue,
        UpdateType: item.UpdateType
      })
    })
  }

  return {
    Guid,
    LeftKeys,
    RejectNegativeValues,
    UpdateDetails: $updateDetails
  }
}

export const _errorHandler = ($data = {}, $text = '') => {
  const { Errors = [] } = $data

  if (Errors.length) {
    slvyToast.warning({
      message: Errors[0],
      title: `${$text} Error`,
      options: { containerId: 'dp' }
    })
  }
}

export const findCurrency = (name, data) => _.find(data, (item) => item.Name === name)

export const _getBaseRequestParameters = ($props) => {
  const {
    selectedScenarioTree: { currentScenarios = [] } = {},
    GetSetFilterTreeRemote: { created: FiltersMultiple = [] } = {},
    timelineSettings: { AggregationLevelId, AggregationPeriods = {} } = {},
    pivotViewSettings: { selectedLeftKeys = [] } = {},
    GetPivotConfiguration: { Result: { AggregationFunctions = ['SUM'] } = {} } = {},
    pivotMainConfiguration = {},
    GetSetFilterRemote: { Locked = '-1', Approved = '-1' } = {},
    mfpSettings: { mfpId } = {},
    quickFilterSettings: { selected: { Id: QuickFilter } = {} } = {},
    settings: {
      config: {
        configuration: {
          isWorkInSubsetEnabled: WorkInSubsetEnabled,
          isQuickFilterEnabled = false
        } = {}
      } = {}
    } = {}
  } = $props

  const UserScenario = _.get($props, 'GetUserScenario.Result', null)
  const UserScenarioId = _.get($props, 'GetUserScenario.Result.Id', null)

  const isActiveSubset = WorkInSubsetEnabled && !_.isNil(UserScenario) && !_.isNil(UserScenarioId)
  const $optional = {}

  if (isActiveSubset) {
    $optional.UserScenarioId = UserScenarioId
  }

  if (isQuickFilterEnabled) {
    $optional.QuickFilter = QuickFilter
  }

  const { [AggregationLevelId]: AggCurrentPeriod = {} } = AggregationPeriods
  const { StartDate = null, EndDate = null } = AggCurrentPeriod

  const { LeftFields, TopFields, MetricFields } = getRequestConfig(
    pivotMainConfiguration,
    AggregationFunctions
  )

  const Filters = {}
  if (Locked !== '-1') {
    Filters.Locked = Locked
  }
  if (Approved !== '-1') {
    Filters.Approved = Approved
  }

  return {
    mfpId,
    Filters,
    EndDate,
    StartDate,
    TopFields,
    LeftFields,
    MetricFields,
    FiltersMultiple,
    TopHierarchy: null,
    WorkInSubsetEnabled,
    LeftHierarchy: selectedLeftKeys,
    TopHierarchyViewLevel: AggregationLevelId,
    MfpScenarioId: getScenarioParameters(currentScenarios, mfpId).params.PlannedId,
    BaselineScenarioId: getScenarioParameters(currentScenarios, mfpId).params.BaselineId,
    ...$optional
  }
}

export const _getBaseNodeActionParameters = ($props, body, params, method) => {
  const {
    defaultActionParams: { payload = {} }
  } = $props

  params = _.omit(params, ['endPoint', 'fieldName', 'name', 'className'])

  return {
    ...payload,
    requestMethod: 'post',
    method,
    body: {
      ...body,
      ...params
    }
  }
}

export const _getSelectedParams = ($props, params, onlyEvent = true) => {
  const {
    timelineSettings: { AggregationLevelId: AggregationLevel, AggregationPeriods = {} } = {},
    GetPivotConfiguration: {
      Result: { Fields: ConfigFields }
    },
    GetPivotConfiguration: { Result: { AggregationFunctions = ['SUM'] } = {} } = {},
    pivotMainConfiguration = {}
  } = $props

  const { [AggregationLevel]: AggCurrentPeriod = {} } = AggregationPeriods
  const { StartDisplayName = null, EndDisplayName = null, PeriodName: Name } = AggCurrentPeriod

  const { LeftFields: ConfigLeftFields, MetricFields } = getRequestConfig(
    pivotMainConfiguration,
    AggregationFunctions
  )

  const DefaultAggregationLevel = _.get(
    $props,
    'settings.config.timeline.defaultAggregationLevelId'
  )

  const { Guid, OldValue, TopKey, LeftKeys, MetricField } = params

  const IsSingle = 'TopKey' in params
  const StartPeriod = IsSingle ? TopKey : StartDisplayName
  const EndPeriod = IsSingle ? TopKey : EndDisplayName
  const PeriodName = Name
  const PeriodAggr = _.isNil(AggregationLevel) ? DefaultAggregationLevel : AggregationLevel

  const SelectedLeftFields = _.map(LeftKeys, (item, index) => {
    return {
      Value: item,
      ConfigLeftField: ConfigLeftFields[index]
    }
  })

  const FilteredConfigFields = _.reduce(
    ConfigFields,
    (arr, item) => {
      const foundItem = _.find(SelectedLeftFields, (s) => {
        return s.ConfigLeftField === item.Name
      })

      if (!_.isEmpty(foundItem)) {
        arr.push({
          ...item,
          ...foundItem
        })
      }
      return arr
    },
    []
  )

  const HandledFilters = _.reduce(
    FilteredConfigFields,
    (obj, item) => {
      obj[item.Aggregation] = item
      return obj
    },
    {}
  )

  const SelectedParams = _.map(HandledFilters, (value) => {
    const { Aggregation, AggregationId, Name, Value } = value
    return `${Aggregation}:${Name}:${AggregationId}:${Value}`
  }).join('#')

  return {
    StartPeriod,
    EndPeriod,
    PeriodName,
    PeriodAggr,
    OldValue,
    Guid,
    SelectedParams,
    ...(onlyEvent ? { MetricField, MetricFields } : {})
  }
}

export function copyClipBoard(str) {
  const textArea = document.createElement('textarea')
  textArea.value = str
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)

  slvyToast.success({ message: `${str} copied`, options: { containerId: 'dp' } })
}

export const displayToastrMessages = (endPoint, msg) => {
  switch (endPoint) {
    case 'ApproveMfpNode':
      slvyToast.info({
        message: MESSAGES.you_will_be_notified_once_the_approval_is_confirmed,
        options: { containerId: 'dp' }
      })
      break
    case 'CopyHierarchy':
      slvyToast.info({
        message: MESSAGES.hierarchy_will_be_copied_soon,
        options: { containerId: 'dp' }
      })
      break
    case 'LockMfpNode':
      slvyToast.info({
        message: MESSAGES.the_node_has_been_locked,
        options: { containerId: 'dp' }
      })
      break
    case 'UnlockMfpNode':
      slvyToast.info({
        message: MESSAGES.the_node_has_been_unlocked,
        options: { containerId: 'dp' }
      })
      break
    case 'ExecuteSpAction':
      slvyToast.info({ message: msg, options: { containerId: 'dp' } })
      break
    default:
      slvyToast.info({
        message: MESSAGES.the_process_has_been_completed,
        options: { containerId: 'dp' }
      })
  }
}

export const getAllPivotBuilderStates = (props = {}) => {
  const { pluginStates = [] } = props
  return pluginStates.filter((item) => item.name.indexOf('PivotBuilderState') === 0)
}

export const setDraftToState = (props, allPluginStates = []) => {
  allPluginStates = _.cloneDeep(allPluginStates)

  const { pivotBuilderViewSettings: { draftState = {} } = {} } = props

  if (!_.isEmpty(draftState)) {
    allPluginStates = allPluginStates.map((item) => {
      if (item.config.stateId === draftState.config.stateId) {
        item = _.cloneDeep(draftState)
      }
      return item
    })
  }

  return allPluginStates
}
