import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '../../BasePlugin'
import { defaultColors } from './util'

const defaultColor = '#444444'

const eventMethod = {
  events: [
    {
      key: 'DataUpdated',
      returnTypes: { refreshKey: PluginTypes.string },
      refreshKey: { refreshKey: uuidv4() }
    },
    {
      key: 'CentroidMoved',
      returnTypes: {
        longitude: PluginTypes.int,
        latitude: PluginTypes.int
      }
    },
    { key: 'LevelClicked', returnTypes: { max: PluginTypes.int } },
    {
      key: 'Moved',
      returnTypes: {
        lat: PluginTypes.int,
        lng: PluginTypes.int,
        zoom: PluginTypes.int,
        pitch: PluginTypes.int,
        bearing: PluginTypes.int
      }
    }
  ],
  methods: [
    {
      key: 'setMove',
      args: [
        { name: 'lat', type: PluginTypes.int },
        { name: 'lng', type: PluginTypes.int },
        { name: 'zoom', type: PluginTypes.int },
        { name: 'pitch', type: PluginTypes.int },
        { name: 'bearing', type: PluginTypes.int }
      ]
    },
    {
      key: 'setLevel',
      args: [{ name: 'levels', type: PluginTypes.arrayOf(PluginTypes.int) }]
    }
  ]
}

const mapStyles = {
  Streets: 'mapbox://styles/mapbox/streets-v11',
  OpenStreet: {
    version: 8,
    sources: {
      osm: {
        type: 'raster',
        tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
        attribution:
          'Map tiles by <a target="_top" rel="noopener" href="https://tile.openstreetmap.org/">OpenStreetMap tile servers</a>, under the <a target="_top" rel="noopener" href="https://operations.osmfoundation.org/policies/tiles/">tile usage policy</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>'
      }
    },
    layers: [
      {
        id: 'osm',
        type: 'raster',
        source: 'osm'
      }
    ]
  },
  Outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  Light: 'mapbox://styles/mapbox/light-v10',
  Dark: 'mapbox://styles/mapbox/dark-v10',
  Satellite: 'mapbox://styles/mapbox/satellite-streets-v11',
  NavigationDay: 'mapbox://styles/mapbox/navigation-day-v1',
  NavigationNight: 'mapbox://styles/mapbox/navigation-night-v1'
}

const motIconList = {
  car: 'fa fa-car',
  bus: 'fa fa-bus',
  walk: 'fa fa-street-view',
  cycle: 'fa fa-bicycle',
  plane: 'fa fa-plane',
  train: 'fa fa-train'
}

export { eventMethod, mapStyles, defaultColor, defaultColors, motIconList }
