import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import ContainerHeader from '../../../../Assignees/components/ContainerHeader'
import NoVariables from '../../../../Assignees/components/NoVariables'
import EventMethodButton from '../EventMethodButton'
import { IRemoteAction, IRemoteActionKey, TcountInfo } from '../../../../Common.types'
import { getClassName, getModifiedItem, hasInList } from '../../helpers'
import { bodyHeight } from '../../../../Assignees/constants'
import { RemoteActionProps } from './RemoteAction.types'

const accordionContainerStyle = { maxHeight: bodyHeight - 15 }

const getRemoteActionsCount = (
  remoteActionKeys: IRemoteActionKey[],
  remoteActions: IRemoteAction[],
  type: string
) => {
  const count = { active: 0, total: _.size(remoteActions) }
  remoteActions.forEach(({ name }) => {
    const isActive = hasInList(remoteActionKeys, { type, remoteActionKey: name })
    count.active += isActive ? 1 : 0
  })
  return count
}

const RemoteAction = ({
  onSave,
  params: { remoteActionKeys = [] } = {},
  params,
  remoteActions
}: RemoteActionProps) => {
  const handleAddItem = (listName: string, obj: IRemoteActionKey) =>
    onSave(getModifiedItem(params, listName, obj))

  const groupedRemoteActions = _.groupBy(remoteActions, 'type')

  const countInfos: TcountInfo[] = Object.keys(groupedRemoteActions).map((type) => {
    const { active, total } = getRemoteActionsCount(
      remoteActionKeys,
      groupedRemoteActions[type],
      type
    )
    return { active, total, id: type }
  })

  return (
    <div className="table-responsive p-3" data-testid="remote-action-job">
      <div className="overflow-y-auto pe-1" style={accordionContainerStyle}>
        <Accordion
          alwaysOpen
          data-testid="remote-action-acc"
          defaultActiveKey={countInfos.filter((item) => item.active).map((item) => item.id)}
        >
          {Object.keys(groupedRemoteActions).map((type) => {
            const { active, total } = countInfos.find((item) => item.id === type) || {}
            const title = <h6>{type}</h6>

            return (
              <Accordion.Item key={type} eventKey={type}>
                <Accordion.Header className="mb-0 pb-0">
                  <ContainerHeader active={active} title={title} total={total} type="" />
                </Accordion.Header>
                <Accordion.Body className="p-3">
                  {total ? null : <NoVariables text="No remote actions are found!" />}
                  <div className="d-flex flex-wrap gap-2">
                    {_.map(groupedRemoteActions[type], (remoteAction) => {
                      const { name } = remoteAction
                      const classParams = {
                        type,
                        remoteActionKey: name
                      }

                      const variant = getClassName(remoteActionKeys, classParams)
                      return (
                        <EventMethodButton
                          key={type + name}
                          text={name}
                          variant={variant}
                          onClick={() => handleAddItem('remoteActionKeys', classParams)}
                        />
                      )
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}

export default RemoteAction
