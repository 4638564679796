Ext.define('MDM.src.view.materialedit.panel.ResourceGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'ResourceGrid',
  columnLines: true,
  listeners: {
    edit: 'onSourceEdit'
  },
  viewConfig: {
    loadMask: false
  },
  title: '',
  tools: [
    {
      type: 'plus',
      tooltip: 'Add new Resource ',
      handler: 'onAddResource'
    }
  ],
  selModel: {
    type: 'cellmodel'
  },
  plugins: [
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],

  columns: [
    { xtype: 'rownumberer' },
    {
      text: 'Resource',
      dataIndex: 'ResourceID',
      flex: 1,
      sortable: true,
      renderer: function (value, metaData, record) {
        if (record.get('ResourceID')) {
          return record.get('Resource')
        }
        return 'Not selected'
      },
      editor: {
        xtype: 'combo',
        bind: {
          store: '{ResourceListStore}'
        },
        queryMode: 'local',
        typeAhead: true,
        editable: true,
        triggerAction: 'all',
        displayField: 'Resource',
        valueField: 'ResourceID',
        listeners: {
          beforequery: function (record) {
            record.query = new RegExp(record.query, 'i')
            record.forceAll = true
          }
        }
      }
    },
    {
      text: 'Resource %',
      dataIndex: 'ResourcePerc',
      width: 100,
      sortable: true,
      editor: {
        minValue: 0,
        maxValue: 100,
        step: 1,
        xtype: 'numberfield'
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'fa fa-chain-broken',
          tooltip: 'Remove the material from this DPI Family',
          handler: 'onRemoveResourceClick'
        }
      ]
    }
  ]
})
