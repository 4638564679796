export default [
  'LotName',
  'Include',
  'OptimalQuantity',
  'RevisedQuantity',
  'ProductId',
  'Type',
  'assorty',
  'AssortyId'
]
