import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import styles from './index.module.scss'

type IProps = {
  show: boolean
}

const NetworkInformation = (props: IProps) => {
  const [show, setShow] = useState<boolean>(props.show)

  useEffect(() => setShow(props.show), [props.show])

  if (show) {
    return (
      <Alert className={styles.NetworkInformation} variant="warning">
        <h3 className="mb-0">
          <strong className="fw-bold">Oh snap!</strong> It looks like you've no internet connection.
          Therefore, your latest action might not be completed.
        </h3>
        <h4>Try:</h4>
        <ul className="px-5">
          <li>Checking the network cables, modem, and router</li>
          <li>Reconnecting to Wi-Fi</li>
          <li>Checking VPN connection or settings</li>
        </ul>
      </Alert>
    )
  }

  return null
}

export default NetworkInformation
