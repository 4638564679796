/* global Ext, Assortment */
import React, { Component } from 'react'
import { ExtContainer } from '@sencha/ext-react-classic'
import Highcharts from 'highcharts'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import jquery from 'jquery'
import { API_URL } from '@/constants'

import createPlugin, { PluginTypes } from '@/BasePlugin'
import { ExtRoot } from '@/components'
import { getExtContainerSize, getPluginSize } from '@/helpers'

// EXT_PACKAGE
import './ext-package/sass/etc/all.scss'

require('./ext-package')

class AssortmentCmp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterArgs: {},
      attributeFilterArgs: [],
      size: getPluginSize(props.size, props.isPreviewMode)
    }

    Ext.define('Assortment.ExtGlobal', {
      singleton: true,
      config: {
        dragonApiUrl: `${API_URL}/data/${props.id}/invoke/`
      },
      constructor: function (config) {
        var me = this
        me.initConfig(config)
      }
    })

    /* HACK : 3rd-party imports */
    window.$ = jquery
    window._ = _
    window.Highcharts = Highcharts
    window.uuidv4 = uuidv4

    Ext.require('Ext.button.*')
    Ext.require('Ext.calendar.*')
    Ext.require('Ext.exporter.*')
    Ext.require('Ext.form.*')
    Ext.require('Ext.grid.*')
    Ext.require('Ext.panel.*')
    Ext.require('Ext.ux.*')
    Ext.require('Ext.window.Toast')

    // Require Chart.ux
    Ext.require('Chart.ux.*')
    Ext.Date.shortMonthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    Ext.require('Assortment.*')

    var defaultHeaders = Ext.Ajax.getDefaultHeaders() || {}

    if (!defaultHeaders['Authorization']) {
      defaultHeaders['environment'] = props.params.environment
      defaultHeaders['Authorization'] = 'Bearer ' + props.token
      Ext.Ajax.setDefaultHeaders(defaultHeaders)
    }

    Ext.Ajax.addListener('beforerequest', function (conn, options, eOpts) {
      options.url = options.url.replace(
        '###ASSORTMENT_API_URL###',
        `${Assortment.ExtGlobal.getDragonApiUrl()}`
      )
      options.url = options.url.replace('/Assortment/', '/')
      options.url = options.url.replace('/assortment/', '/')
    })
    this.getPluginstate = this.getPluginstate.bind(this)
    this.savePluginstate = this.savePluginstate.bind(this)
    this.similarProductGenericDrilldown = this.similarProductGenericDrilldown.bind(this)
    this.apiClient = this.apiClient.bind(this)
  }

  componentDidMount() {
    const { settings: { config: { drillDowns: { similarProductButtons = [] } = {} } = {} } = {} } =
      this.props

    this.similarProductDrilldown = this.props.registerEvent({
      key: 'SimilarProductDrilldown',
      fn: this.similarProductDrilldown,
      returnTypes: {
        ProductId: PluginTypes.int,
        ClusterNodeId: PluginTypes.int
      }
    })
    this.similarMultiProductDrilldown = this.props.registerEvent({
      key: 'SimilarMultiProductDrilldown',
      fn: this.similarMultiProductDrilldown,
      returnTypes: {
        ProductIds: PluginTypes.arrayOf(PluginTypes.int),
        ClusterNodeId: PluginTypes.int
      }
    })

    this.assortmentApproved = this.props.registerEvent({
      key: 'AssortmentApproved',
      fn: this.assortmentApproved,
      returnTypes: {
        AssortmentId: PluginTypes.int,
        ClusterNodeId: PluginTypes.int,
        refreshKey: PluginTypes.string
      }
    })

    _.forEach(similarProductButtons, (similarProductButton) => {
      const { buttonText } = similarProductButton

      this['similarProductGenericDrilldownFireEvent' + buttonText] = function (data) {
        const { AssortmentId = 0, OptionId = 0, SegmentId = 0, ClusterNodeId = 0 } = data || {}
        return { AssortmentId, OptionId, SegmentId, ClusterNodeId }
      }

      this['similarProductGenericDrilldownFireEvent' + buttonText] = this.props.registerEvent({
        key: buttonText,
        fn: this['similarProductGenericDrilldownFireEvent' + buttonText].bind(this),
        returnTypes: {
          AssortmentId: PluginTypes.int,
          ClusterNodeId: PluginTypes.int,
          OptionId: PluginTypes.int,
          SegmentId: PluginTypes.int
        }
      })
    })

    this.props.reloadExtRoot(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If size of the grid changes do not render the grid
    const widthChanged = nextProps.size.width !== this.props.size.width
    const heightChanged = nextProps.size.height !== this.props.size.height

    if (widthChanged || heightChanged) {
      setTimeout(() => this.setState({ size: getExtContainerSize(this.props.id) }), 100)
    }
  }

  componentWillUnmount() {
    dispatchEvent(
      new CustomEvent('hideExtRoot', { detail: { pluginId: this.props.id, callback: () => {} } })
    )
  }

  similarProductDrilldown(data) {
    const { ProductId = 0, ClusterNodeId = 0 } = data || {}
    return { ProductId, ClusterNodeId }
  }

  similarMultiProductDrilldown(data) {
    const { ProductIds = [], ClusterNodeId = 0 } = data || {}
    return { ProductIds, ClusterNodeId }
  }

  assortmentApproved(data) {
    const { AssortmentId = 0, ClusterNodeId = 0 } = data || {}
    return { AssortmentId, ClusterNodeId, refreshKey: uuidv4() }
  }

  similarProductGenericDrilldown(data, eventName) {
    this['similarProductGenericDrilldownFireEvent' + eventName](data)
  }

  getPluginstate(args) {
    const { id } = this.props || {}
    const { success: successCallback, complete: completeCallback } = args || {}

    this.apiClient({
      requestType: `pluginstate/${id}`,
      method: 'get',
      data: {},
      onSuccess: (data) => {
        if (successCallback) {
          successCallback(data)
        }
      },
      onComplete: () => {
        if (completeCallback) {
          completeCallback()
        }
      }
    })
  }

  savePluginstate(pluginState) {
    this.apiClient({
      requestType: 'pluginstateupsert',
      method: 'post',
      data: {
        name: 'AssortmentPlanning State ' + this.props.id,
        pluginId: this.props.id,
        catalogId: this.props.params.catalogId,
        config: {
          state: pluginState,
          stateId: 1
        }
      }
    })
  }

  apiClient({ requestType, method, data, onSuccess, onError, onComplete }) {
    const { client } = this.props
    const clientUrl = requestType

    client[method](clientUrl, { data }).then(onSuccess).catch(onError).finally(onComplete)
  }

  render() {
    const {
      settings: {
        config: {
          settings: {
            applicationType = 'DC',
            language = 'TR',
            smallCurrencyFormat = '$0.00',
            largeCurrencyFormat = '$0,000',
            currency = '$',
            similarItemExternalLink = false,
            optionPriceEditable = false,
            delayUpdates = false
          } = {},
          drillDowns: { similarProductButtons = [], segmentContextMenuItems = [] } = {}
        } = {}
      } = {},
      id = 'id',
      isPreviewMode
    } = this.props

    const packageProps = {
      xtype: 'assortmentmainview',
      isToolbarVisible: true,
      width: '100%',
      height: '100%',

      language,
      currency,
      delayUpdates,
      applicationType,
      smallCurrencyFormat,
      largeCurrencyFormat,
      optionPriceEditable,
      similarProductButtons,
      segmentContextMenuItems,
      similarItemExternalLink,
      pluginInstanceId: id,
      mainGridFilters: this.state.attributeFilterArgs,

      getPluginstate: this.getPluginstate,
      savePluginstate: this.savePluginstate,
      onSimilarProductDrilldown: this.similarProductDrilldown,
      onSimilarMultiProductDrilldown: this.similarMultiProductDrilldown,
      onAssortmentApproved: this.assortmentApproved,
      onSimilarProductGenericDrilldown: this.similarProductGenericDrilldown,

      ...this.state.filterArgs
    }

    const extContainerProps = {
      ...getPluginSize(this.state.size, isPreviewMode),
      id: `slvyExtContainer-${id}`,
      cls: 'slvy-ext-container',
      layout: 'fit',
      autoSize: true,
      items: [packageProps]
    }

    return (
      <ExtRoot pluginId={id}>
        <ExtContainer {...extContainerProps} />
      </ExtRoot>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  size: props.size,
  isPreviewMode: props.isPreviewMode,
  id: props.id,
  params: props.params,
  token: props.token,
  settings: props.settings,
  client: props.client,
  reloadExtRoot: props.reloadExtRoot
})

export default createPlugin(AssortmentCmp, selectConnectorProps)
