import { RESET_QUICK_FILTER_SETTINGS, STORE_QUICK_FILTER_SETTINGS } from '../actions/actionTypes'

const initialState = {
  quickFilterSettings: {
    selected: {
      Id: 0
    }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_QUICK_FILTER_SETTINGS:
      return {
        quickFilterSettings: {
          ...state.quickFilterSettings,
          ...action.payload
        }
      }
    case RESET_QUICK_FILTER_SETTINGS:
      return {
        quickFilterSettings: {
          ...state.quickFilterSettings,
          ...initialState.quickFilterSettings
        }
      }
    default:
      return state
  }
}

export default reducer
