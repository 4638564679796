import { useCallback, useState, useEffect } from 'react'
import { isCustomEvent } from '@/ts'
import { MassUpdateModalTypes } from '../../components/MassUpdate/MassUpdateModal/MassUpdateModal.types'

function useMassUpdateModal(id: string) {
  const eventName = `SLVY_AG_GRID_MASS_UPDATE_SHOW_MODAL_${id}`

  const [massUpdateModal, setMassUpdateModal] = useState<MassUpdateModalTypes | null>(null)

  const onMassUpdateShow = useCallback(
    (event: Event) => {
      if (!isCustomEvent(event)) {
        throw new Error('This is not a custom event!')
      }

      const {
        detail: { pluginId, activeField }
      } = event
      // Make sure id is same to prevent conflict with other ag-grids on the page
      if (pluginId !== id) {
        return
      }

      setMassUpdateModal({ isVisible: true, activeField })
    },
    [id]
  )

  const onMassUpdateCompleted = useCallback(() => setMassUpdateModal(null), [])

  const onMassUpdateCancelled = () => setMassUpdateModal(null)

  useEffect(() => {
    window.addEventListener(eventName, onMassUpdateShow)
    return () => window.removeEventListener(eventName, onMassUpdateShow)
  }, [eventName, onMassUpdateShow])

  return { massUpdateModal, onMassUpdateShow, onMassUpdateCompleted, onMassUpdateCancelled }
}

export default useMassUpdateModal
