Ext.define('MDM.src.view.Grouping.FormDialog', {
  extend: 'Ext.window.Window',
  xtype: 'form-grouping.formdialog',
  modal: true,
  title: 'Grouping add/update',
  width: 500,
  height: 300,
  minWidth: 300,
  minHeight: 220,
  bodyPadding: 20,
  layout: 'fit',
  plain: true,
  closable: false,
  closeAction: 'hide',
  collapsible: false,
  items: [
    {
      xtype: 'form',
      defaultType: 'textfield',
      fieldDefaults: {
        labelWidth: 100
      },
      layout: {
        type: 'vbox',
        align: 'stretch'
      },
      bodyPadding: 5,
      border: false,
      items: [
        {
          fieldLabel: 'Region',
          name: 'MegaRegion',
          disabled: true,
          bind: {
            value: '{editedRecord.MegaRegion}',
            visible: '{editedRecord.MegaRegion}'
          }
        },
        {
          fieldLabel: 'Group Name',
          name: 'GroupName',
          allowBlank: false,
          bind: {
            value: '{editedRecord.Descriptions}',
            disabled: '{!editedRecord.isEditable}'
          }
        },
        {
          xtype: 'checkbox',
          fieldLabel: 'Private',
          hideEmptyLabel: false,
          bind: {
            value: '{editedRecord.isPrivate}',
            disabled: '{!editedRecord.isEditable}'
          }
        },
        {
          fieldLabel: 'Notes',
          xtype: 'textarea',
          bind: {
            value: '{editedRecord.Notes}',
            disabled: '{!editedRecord.isEditable}'
          },
          flex: 1
        }
      ],
      buttons: [
        {
          text: 'Save',
          handler: 'onSave',
          formBind: true,
          bind: {
            visible: '{editedRecord.isEditable}'
          }
        },
        {
          text: 'Cancel',
          handler: 'onCancelEdit',
          bind: {
            visible: '{editedRecord.isEditable}'
          }
        },
        {
          text: 'Close',
          handler: 'onCancelEdit',
          bind: {
            visible: '{!editedRecord.isEditable}'
          }
        }
      ]
    }
  ]
})
