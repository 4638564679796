import React, { Component } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import _, { find } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import numeral from 'numeral'
import { slvyToast } from '@/components'
import SectionList from './components/sectionList'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { checkValidity } from './utils'
import DateFormats from './date-format'

import './index.scss'

class BootstrapForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formObj: {},
      readonly: false,
      enableSubmitButton: true,
      validationCheckState: false,
      requiredInfo: {}
    }

    this.prepareForm = this.prepareForm.bind(this)
    this.setReadonly = this.setReadonly.bind(this)
    this.onChangeValue = this.onChangeValue.bind(this)
    this.checkFieldsIfAreOk = this.checkFieldsIfAreOk.bind(this)
    this.getValueWithFormat = this.getValueWithFormat.bind(this)
    this.getValueWithoutFormat = this.getValueWithoutFormat.bind(this)
    this.getLookupDataEditableField = this.getLookupDataEditableField.bind(this)
    this.prepareRecorDatawithChanges = this.prepareRecorDatawithChanges.bind(this)
    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
    this.changeMustBeUpdatedByFieldName = this.changeMustBeUpdatedByFieldName.bind(this)

    this.FormIsDirty = false
    this.boundedDropdowns = {}
    this.eventHandler = {}
  }

  UNSAFE_componentWillMount() {
    // Call plugin on ready method
    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [(value) => value],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition
      })
    }
  }

  componentDidMount() {
    const { props: { settings: { config: { components = [] } = {} } = {} } = {} } = this

    const formFields = this.prepareFormFieldsAsEventReturnTypes()

    this.props.registerMethod({
      key: 'save',
      fn: this.runUpdateQuery.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'SetReadonly',
      fn: this.setReadonly.bind(this),
      args: [{ name: 'readonly', type: PluginTypes.boolean }]
    })

    this.props.registerMethod({
      key: 'GetFormData',
      fn: this.GetFormData.bind(this),
      args: []
    })

    this.FormDataResult = this.props.registerEvent({
      key: 'FormDataResult',
      fn: this.FormDataResult.bind(this),
      returnTypes: formFields
    })

    this.handleDataUpdated = this.props.registerEvent({
      key: 'DataUpdated',
      fn: this.handleDataUpdated.bind(this),
      returnTypes: formFields
    })

    this.dirtyStateChanged = this.props.registerEvent({
      key: 'DirtyStateChanged',
      fn: this.dirtyStateChanged.bind(this),
      returnTypes: {
        dirty: PluginTypes.boolean,
        clean: PluginTypes.boolean,
        ...formFields
      }
    })

    this.isValid = this.props.registerEvent({
      key: 'isValid',
      fn: this.isValid.bind(this),
      returnTypes: {
        isValid: PluginTypes.int
      }
    })

    _.each(components, ({ icon = null, fieldName }) => {
      if (icon) {
        const key = `DrillDown_${fieldName}`
        this[key] = this.props.registerEvent({
          key,
          fn: this.handleDataByIconClick.bind(this),
          returnTypes: formFields
        })

        this.eventHandler[key] = this[key]
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      pluginData: newPluginData = [],
      settings: { config: { sections = [], components = [] } = {} } = {}
    } = nextProps

    // to-do should component update
    const { props: { pluginData: oldPluginData } = {} } = this

    if (!_.isEqual(oldPluginData, newPluginData)) {
      if (this.FormIsDirty !== false) {
        this.FormIsDirty = false
        this.dirtyStateChanged(false)
      }

      if (_.size(newPluginData) !== 0) {
        const formData = this.prepareForm(sections, components, newPluginData)
        const { formObj, boundedDropdowns, requiredInfo } = formData
        this.boundedDropdowns = _.cloneDeep(boundedDropdowns)
        this.setState({ formObj, requiredInfo })
      } else {
        this.setState({ formObj: {} })
        this.FormIsDirty = false
      }
    }
    // if you will put any condition for rendering keep readonly in your mind
  }

  GetFormData() {
    this.FormDataResult()
  }

  FormDataResult() {
    const modifiedRecord = this.prepareRecorDatawithChanges()
    return { ...modifiedRecord, refreshKey: uuidv4() }
  }

  setReadonly(value) {
    const { readonly = null } = value || {}
    if (!_.isNil(readonly)) {
      this.setState({ readonly })
    }
  }

  dirtyStateChanged(dirty) {
    const modifiedRecord = this.prepareRecorDatawithChanges()
    return { dirty, clean: !dirty, ...modifiedRecord }
  }

  checkDirtyState(dirtyValue) {
    if (_.isNil(dirtyValue)) {
      const {
        props: { pluginData = [], settings: { config: { components = [] } = {} } = {} } = {}
      } = this

      if (_.size(pluginData) <= 0) {
        if (this.FormIsDirty !== false) {
          this.FormIsDirty = false
          this.dirtyStateChanged(false)
        }
      } else {
        const { formObj = {} } = this.state
        let isDirty = false

        _.forEach(components, (comp) => {
          if (!_.isNil(comp.fieldName)) {
            const { element: { type = 'text' } = {}, fieldName = null, sectionKey = null } = comp
            if (
              _.size(formObj[sectionKey]?.components) > 0 &&
              formObj[sectionKey]?.components[fieldName]
            ) {
              const compDataFromForm = _.isNil(formObj[sectionKey].components[fieldName].value)
                ? ''
                : formObj[sectionKey].components[fieldName].value

              const compDataFromPluginData = _.isNil(pluginData[0][fieldName])
                ? ''
                : pluginData[0][fieldName]

              const isDataChanged = compDataFromForm !== compDataFromPluginData

              if (type === 'datetime') {
                if (
                  (compDataFromForm === '' && isDataChanged) ||
                  (compDataFromPluginData === '' && isDataChanged) ||
                  moment(compDataFromForm).diff(compDataFromPluginData, 'days')
                ) {
                  isDirty = true
                  return false
                }
              } else if (isDataChanged) {
                isDirty = true
                return false
              }
            }
          }
        })
        if (this.FormIsDirty !== isDirty) {
          this.FormIsDirty = isDirty
          this.dirtyStateChanged(isDirty)
        }
      }
    } else if (this.FormIsDirty !== dirtyValue) {
      this.FormIsDirty = dirtyValue
      this.dirtyStateChanged(dirtyValue)
    }
  }

  handleDataByIconClick = (key) => {
    key = `DrillDown_${key}`
    const modifiedRecord = {
      ...this.prepareRecorDatawithChanges(),
      refreshKey: uuidv4()
    }
    const _fn = this.eventHandler[key] || (() => {})
    _fn()
    return modifiedRecord
  }

  isValid(obj) {
    return obj
  }

  handleDataUpdated() {
    const modifiedRecord = this.prepareRecorDatawithChanges()
    return { ...modifiedRecord, refreshKey: uuidv4() }
  }

  prepareFormFieldsAsEventReturnTypes() {
    const { props: { settings: { query: { fields = [] } = {} } = {} } = {} } = this
    const eventReturnTypes = _.transform(
      fields,
      (result, field) => {
        const fieldType = field.dataType
        const propName = field.fieldName

        if (!_.isEmpty(fieldType)) {
          switch (fieldType) {
            case 'datetime':
              result[propName] = PluginTypes.datetime
              break
            case 'string':
              result[propName] = PluginTypes.string
              break
            case 'int':
            case 'float':
            case 'double':
            case 'decimal':
              result[propName] = PluginTypes.int
              break
            case 'bool':
              result[propName] = PluginTypes.boolean
              break
            case 'dynamic':
              result[propName] = PluginTypes.dynamic
              break
            default: {
            }
          }
        }
      },
      {}
    )

    return { ...eventReturnTypes, refreshKey: PluginTypes.string }
  }

  handleSynchronizeDataDefinition(fields, value, schemaHelper) {
    const newValue = _.cloneDeep(value)

    // Create an  empty template for grid column
    const emptyComponent = schemaHelper.getValueOfSchema(
      this.props.schema.properties.components.items
    )

    _.remove(newValue.components, (component) => {
      return component.fieldName === ''
    })

    _.forEach(fields, (field) => {
      // Check if the field is already used as a column
      const existingComponent = _.find(
        newValue.components,
        (component) => component.fieldName === field.fieldName
      )

      // Create a column if its field does not exist
      if (!existingComponent) {
        const newComponent = _.cloneDeep(emptyComponent)
        newComponent.fieldName = field.fieldName
        newComponent.label = field.fieldName

        newValue.components.push(newComponent)
      }
    })

    return newValue
  }

  prepareForm(sections, components, pluginData) {
    const { props: { settings: { query: { editableFields = [] } = {} } = {} } = {} } = this

    const componentsClone = _.cloneDeep(components)
    const boundedDropdowns = {}
    const requiredInfo = {}
    // to-do incompatible types will not be taken
    const formObj = _.transform(
      sections,
      (result, section) => {
        const components = _.transform(
          componentsClone,
          (compsResult, component) => {
            const {
              element: { type = 'text', searchEnabled },
              fieldName,
              min,
              max,
              readonly,
              required,
              sectionKey,
              validationMessage
            } = component
            if (sectionKey === section.key && fieldName) {
              compsResult[fieldName] = component
              compsResult[fieldName].value =
                type === 'datetime'
                  ? moment(pluginData[0][fieldName]).isValid()
                    ? moment(pluginData[0][fieldName]).format('YYYY-MM-DD')
                    : null
                  : pluginData[0][fieldName]

              compsResult[fieldName].required = this.getPropertyValue(
                required,
                fieldName,
                pluginData[0],
                '_required'
              )

              compsResult[fieldName].readonly = this.getPropertyValue(
                readonly,
                fieldName,
                pluginData[0],
                '_readonly'
              )
              requiredInfo[fieldName] = compsResult[fieldName].required

              compsResult[fieldName].validationMessage = validationMessage
              compsResult[fieldName].type = type
              compsResult[fieldName].data =
                type === 'dropdown'
                  ? this.loadLookUpdata(pluginData[0], fieldName, searchEnabled)
                  : {}

              compsResult[fieldName].min = this.getMinMax(type, pluginData[0], min)
              compsResult[fieldName].max = this.getMinMax(type, pluginData[0], max)

              if (type === 'dropdown') {
                const lookup = _.find(editableFields, {
                  field: fieldName
                })
                let boundedFields
                if (lookup) {
                  const relatedFields = lookup.lookupQueryList
                  if (!_.isEmpty(relatedFields)) {
                    boundedFields = relatedFields
                    boundedDropdowns[fieldName] = {
                      sectionKey,
                      fieldName,
                      mustBeUpdated: false,
                      boundedFields
                    }
                  }
                }
              }
            }
          },
          {}
        )

        result[section.key] = {
          key: section.key,
          backgroundColor: section.backgroundColor,
          compact: section.compact,
          inputHeight: section.inputHeight,
          label: section.label,
          colSize: section.colSize,
          components
        }
      },
      {}
    )

    return { formObj, boundedDropdowns, requiredInfo }
  }

  getMinMax(compType, firstRow, valueField) {
    let result
    const value = valueField && firstRow[valueField]
    if (_.isNil(value) || _.isEmpty(_.toString(value))) {
      result = null
    } else {
      switch (compType) {
        case 'datetime':
          result = moment(value).valueOf()
          break
        case 'number':
          result = _.toNumber(value)
          break
        default: {
          result = null
        }
      }
    }

    return result
  }

  setRequiredState = (fieldName, required) => {
    this.setState({
      requiredInfo: {
        ...this.state.requiredInfo,
        ...{ [fieldName]: required }
      }
    })
  }

  getPropertyValue(property, fieldName, data, fieldPostFix) {
    if (property === 'true' || property === true) {
      return true
    }
    if (property === 'false' || property === false) {
      return false
    }
    if (data && !_.isNil(data[fieldName + fieldPostFix])) {
      return Boolean(data[fieldName + fieldPostFix])
    }
    return false
  }

  getMustBeUpdatedFields(affectingField) {
    const affectedFields = []

    function checkIfAnyFieldIsAffected(boundedDropdowns, affectingField) {
      _.forEach(boundedDropdowns, (item) => {
        if (_.find(item.boundedFields, (item) => item === affectingField)) {
          if (
            !_.find(affectedFields, (item) => item === item.fieldName) &&
            affectingField !== item.fieldName
          ) {
            affectedFields.push(item.fieldName)
            checkIfAnyFieldIsAffected(boundedDropdowns, item.fieldName)
          }
        }
      })
    }

    checkIfAnyFieldIsAffected(this.boundedDropdowns, affectingField)
    return affectedFields
  }

  changeMustBeUpdated(affectedFields) {
    this.boundedDropdowns = _.forEach(_.cloneDeep(this.boundedDropdowns), (item) => {
      if (_.includes(affectedFields, item.fieldName)) {
        item.mustBeUpdated = true
      }
    })
  }

  changeMustBeUpdatedByFieldName(fieldName, value) {
    this.boundedDropdowns[fieldName].mustBeUpdated = value
  }

  changeAffectedFieldsToNull(affectedFields, obj) {
    _.forEach(affectedFields, (item) => {
      obj[this.boundedDropdowns[item].sectionKey].components[item].value = null
    })
  }

  onChangeValue(sectionKey, key, value, callback = () => {}) {
    const { formObj = {} } = this.state

    const modifiedObj = {
      ...formObj,
      [sectionKey]: {
        ...formObj[sectionKey],
        components: {
          ...formObj[sectionKey].components,
          [key]: { ...formObj[sectionKey].components[key], value }
        }
      }
    }
    if (
      _.find(this.boundedDropdowns, (item) =>
        _.find(item.boundedFields, (bounded) => bounded === key)
      )
    ) {
      const affectedFields = this.getMustBeUpdatedFields(key)
      this.changeMustBeUpdated(affectedFields)
      this.changeAffectedFieldsToNull(affectedFields, modifiedObj)
    }

    this.setState({ formObj: modifiedObj }, () => this.checkDirtyState(), callback())
  }

  loadLookUpdataReadOnly = (_searchText) => {
    const that = this
    return new Promise((resolve, reject) => {
      const { id, actualFiters, clientWithProgress } = this.props
      const boundedDropdowns = _.filter(
        _.cloneDeep(this.boundedDropdowns),
        (item) => item.mustBeUpdated
      )
      const changes = this.prepareRecorDatawithChanges()

      const record = {
        ...changes,
        _searchText
      }

      if (boundedDropdowns.length > 0) {
        const { formObj = {} } = this.state
        const promiseAll = _.map(boundedDropdowns, (item) => {
          that.changeMustBeUpdatedByFieldName(item.fieldName, false)
          return clientWithProgress.post(`/data/plugin/${id}/lookup/${item.fieldName}`, {
            data: { record, actualFiters }
          })
        })

        Promise.all(promiseAll)
          .then((values) => resolve(values))
          .catch((err) => reject(err))

        boundedDropdowns.forEach((item, index) => {
          const { components = {} } = formObj[item.sectionKey] || {}
          const component = components[item.fieldName]
          component.data = promiseAll[index]
        })

        this.setState({ formObj })
      }
    })
  }

  checkFieldsIfAreOk(formData) {
    const {
      props: { settings: { config: { components = [] } = {} } = {}, pluginData = [] } = {},
      state: { requiredInfo = {} }
    } = this
    let isValid = true

    for (let i = 0, len = components.length; i < len; i++) {
      const comp = components[i]
      let {
        element: { type = 'text' } = {},
        fieldName,
        max,
        min,
        readonly,
        required: compRequied = 'false'
      } = comp

      const requiredVal = this.getPropertyValue(compRequied, fieldName, pluginData[0], '_required')

      const value = formData[fieldName]
      min = pluginData[0][min]
      max = pluginData[0][max]

      const required = _.isNil(requiredInfo[fieldName]) ? requiredVal : requiredInfo[fieldName]
      isValid = checkValidity({ type, value, required, readonly, min, max })

      if (!isValid) {
        break
      }
    }

    return isValid
  }

  runUpdateQuery() {
    const includeDirtyStateOnSubmit = _.get(
      this.props,
      'settings.config.submit.includeDirtyStateOnSubmit',
      true
    )

    if (this.FormIsDirty === true || !includeDirtyStateOnSubmit) {
      let isValid = 0

      const {
        props: {
          //data: { actualFilters = {} },
          settings: { query: { dataEditing: { isUpdateQuery = '' } = {} } = {} } = {}
        } = {}
      } = this

      // TODO: actualFilters comes from props.actualFilters, not from props.data.actualFilters
      // We don't get actualFilters from props.data because nobody questioned how it works with {} object
      const actualFilters = {}

      this.setState({
        validationCheckState: true
      })

      const preparedRecord = this.prepareRecorDatawithChanges()
      const formData = this.extractDataFromFormObj()

      if (this.checkFieldsIfAreOk(formData)) {
        if (_.size(preparedRecord) > 0) {
          let data = {}
          const records = []
          records.push(preparedRecord)
          if (isUpdateQuery) {
            data = {
              filters: actualFilters,
              records,
              updatehints: {},
              type: 0
            }
          } else {
            const updateItems = [
              {
                columnName: this.getFieldName(),
                config: preparedRecord,
                oldValue: -1
              }
            ]
            data = {
              filters: actualFilters,
              updateItems,
              updatehints: {}
            }
          }
          this.setState({ enableSubmitButton: false })
          this.updateValue(data)
          isValid = 1
        }
      }

      this.isValid({ isValid })
    }
  }

  updateValue(updateData) {
    const {
      props: {
        id,
        clientWithProgress,
        settings: {
          config: { submit: { refreshDataAfterSubmitting = true } = {} } = {},
          query: { dataEditing: { isUpdateQuery = '' } = {} } = {}
        } = {}
      } = {}
    } = this

    if (isUpdateQuery) {
      clientWithProgress
        .post(`/data/plugin/${id}/edit/`, { data: updateData })
        .then((res) => {
          if (res && 'result' in res) {
            const { result = [] } = res
            const errFiltered = _.filter(result, { isSuccess: false })
            const errGrouped = _.groupBy(errFiltered, 'errorCode')
            if (errFiltered.length > 0) {
              const errMessages = _.transform(
                errGrouped,
                (data, item, key) => {
                  if (key === '') {
                    data.push(`An error occured (${item.length})`)
                  } else {
                    data.push(`${key} (${item.length})`)
                  }
                  return data
                },
                []
              ).join('<br>')

              slvyToast.warning({
                message: errMessages
              })
            }
          }

          this.handleDataUpdated()
          this.checkDirtyState(false)

          if (refreshDataAfterSubmitting) {
            this.props.clearCaches()
            this.props.setDataArguments(null, true)
          }
        })
        .catch(({ message }) =>
          slvyToast.warning({
            message
          })
        )
        .finally(() => this.setState({ enableSubmitButton: true }))
    } else {
      clientWithProgress
        .post(`/data/plugin/${id}/update/`, { data: updateData })
        .then((res) => {
          if (res && 'result' in res) {
            const { result = [] } = res
            const errFiltered = _.filter(result, { isSuccess: false })
            const errGrouped = _.groupBy(errFiltered, 'errorCode')
            if (errFiltered.length > 0) {
              const errMessages = _.transform(
                errGrouped,
                (data, item, key) => {
                  if (key === '') {
                    data.push(`An error occurred (${item.length})`)
                  } else {
                    data.push(`${key} (${item.length})`)
                  }
                  return data
                },
                []
              ).join('<br>')

              slvyToast.warning({
                message: errMessages
              })
            }
          }
          this.handleDataUpdated()
          this.checkDirtyState(false)
          if (refreshDataAfterSubmitting) {
            this.props.clearCaches()
            this.props.setDataArguments(null, true)
          }
        })
        .catch(({ message }) =>
          slvyToast.warning({
            message
          })
        )
        .finally(() => this.setState({ enableSubmitButton: true }))
    }
  }

  prepareRecorDatawithChanges() {
    const { props: { pluginData } = {} } = this
    let modifiedRecord

    if (pluginData && pluginData.length !== 0) {
      const formData = this.extractDataFromFormObj()
      if (!_.isEmpty(formData)) {
        const pluginDataRow = pluginData[0]
        modifiedRecord = _.transform(
          pluginDataRow,
          (result, value, key) => {
            result[key] = formData.hasOwnProperty(key) ? formData[key] : value
          },
          {}
        )
      }
    }

    return modifiedRecord
  }

  loadLookUpdata(pluginDataRow, fieldName, searchEnabled) {
    const { actualFilters = {}, id, clientWithProgress } = this.props
    const editableField = this.getLookupDataEditableField(fieldName)
    const value = pluginDataRow[fieldName]
    let _searchText = ''

    if (searchEnabled && !_.isEmpty(value) && !_.isNil(value)) {
      _searchText = value
    }

    if (editableField) {
      const record = { ...pluginDataRow, _searchText }

      return clientWithProgress.post(`/data/plugin/${id}/lookup/${editableField.field}`, {
        data: { filters: actualFilters, record, updatehints: {} }
      })
    }
    return null
  }

  getLookupDataEditableField(fieldName) {
    const {
      props: { settings: { query: { editableFields = [] } = {} } = {}, pluginData = [] } = {}
    } = this

    /**
     * TODO: Check pluginData whether passed from props,
     * then check is it an array,
     * then check its length to make sure it really exists.
     * * */

    if (pluginData) {
      return _.find(editableFields, {
        field: fieldName
      })
    }
    return false
  }

  updateFieldLookUpData = (sectionKey, key, data) => {
    const { formObj = {} } = this.state

    const modifiedObj = {
      ...formObj,
      [sectionKey]: {
        ...formObj[sectionKey],
        components: {
          ...formObj[sectionKey].components,
          [key]: { ...formObj[sectionKey].components[key], data }
        }
      }
    }

    this.setState({ formObj: modifiedObj })
  }

  extractDataFromFormObj() {
    const { formObj } = this.state
    let formData
    _.forEach(formObj, (section) => {
      const sectionComps = _.transform(section.components, (result, comp) => {
        result[comp.fieldName] = comp.value
      })
      formData = { ...formData, ...sectionComps }
    })
    return formData
  }

  getFieldName() {
    const { props: { settings: { query: { editableFields = [] } = {} } = {} } = {} } = this
    const result = _.find(editableFields, (o) => o.isUpdateQuery)
    return result ? result.field : ''
  }

  onSubmit() {
    this.runUpdateQuery()
  }

  detectButtonPosition(buttonPosition) {
    switch (buttonPosition) {
      case 'Right':
        return 'float-end'
      case 'Left':
        return 'float-start'
      case 'Center':
        return 'd-block mx-auto'
      default:
        return 'float-end'
    }
  }

  getFormatedOptions = (fieldName) => {
    const {
      props: { settings: { query: { formattedFields = [] } = {} } = {}, getFormattedValue } = {}
    } = this
    const format = _.find(formattedFields, { columnName: fieldName })

    return {
      format,
      formattedFields,
      getFormattedValue
    }
  }

  getValueWithDateFormat = (fieldName, value) => {
    const { format: { formatString = '' } = {} } = this.getFormatedOptions(fieldName) || {}

    const { value: dateFormat = formatString } =
      find(DateFormats, ({ key }) => formatString === key) || {}

    const formattedDateValue = this.getValueWithFormat(fieldName, value)

    return {
      dateFormat,
      formattedDateValue
    }
  }

  getValueWithFormat(fieldName, value) {
    const { format, formattedFields, getFormattedValue } = this.getFormatedOptions(fieldName)

    if (!_.isNil(format) && !_.isEmpty(format)) {
      return getFormattedValue(fieldName, value, formattedFields)
    }
    return value
  }

  getValueWithoutFormat(fieldName, value) {
    const { format } = this.getFormatedOptions(fieldName)
    if (!_.isNil(value) && !_.isEmpty(value) && !_.isNil(format)) {
      return numeral(value)._value
    }

    return value
  }

  getSubmitButton() {
    const {
      props: {
        pluginData = [],
        settings: {
          config: {
            submit: {
              text = '',
              style = 'success',
              icon = 'fa fa-check',
              iconPosition = 'Left',
              buttonPosition,
              enabled = false
            } = {}
          } = {},
          inputHeight = 25,
          compact = false
        } = {}
      } = {}
    } = this

    const submitButtonPosition = this.detectButtonPosition(buttonPosition)

    const submitEnabled = enabled
      ? pluginData[0] && pluginData[0][enabled]
        ? this.state.enableSubmitButton
        : false
      : this.state.enableSubmitButton

    return (
      <Button
        className={`buttonFilter ${submitButtonPosition}`}
        disabled={!submitEnabled}
        size="sm"
        style={{ height: `${compact ? `${inputHeight}px` : 'auto'}` }}
        type="submit"
        variant={style}
        onClick={this.onSubmit.bind(this)}
      >
        {iconPosition === 'Left' ? <i className={icon} /> : null}
        {` ${text} `}
        {iconPosition === 'Right' ? <i className={icon} /> : null}
      </Button>
    )
  }

  render() {
    const {
      props: {
        clientWithProgress,
        actualFilters = {},
        id: pluginId,
        settings: {
          config: {
            submit: { show: showSubmitButton = true, tooltip = '' } = {},
            settings: {
              backgroundColor = '#ffffff',
              scrollable = true,
              compact = false,
              inputHeight = 25,
              inputFontSize = 11
            } = {},
            components: settingsComponents = []
          } = {}
        } = {}
      } = {},
      state: { formObj, readonly, validationCheckState },
      getValidationState,
      boundedDropdowns
    } = this

    const _tooltip = (
      <Tooltip className="bs-tooltip" id="bsTooltip">
        {tooltip}
      </Tooltip>
    )

    const bsFormStyle = { backgroundColor }

    const scrollableClass = scrollable ? 'bs-form-scrollable' : ''
    const compactStatus = compact ? 'compactModeOn' : 'compactModeOff'
    return (
      <div className={`bootstrap-form ${scrollableClass} ${compactStatus}`} style={bsFormStyle}>
        <SectionList
          actualFilters={actualFilters}
          boundedDropdowns={_.cloneDeep(boundedDropdowns)}
          changeMustBeUpdated={this.changeMustBeUpdatedByFieldName}
          clientWithProgress={clientWithProgress}
          compact={compact}
          formObj={formObj}
          getFormatedValue={this.getValueWithFormat}
          getLookupDataEditableField={this.getLookupDataEditableField}
          getUnformattedValue={this.getValueWithoutFormat}
          getValidationState={getValidationState}
          getValueWithDateFormat={this.getValueWithDateFormat}
          handleDataByIconClick={this.handleDataByIconClick}
          inputFontSize={inputFontSize}
          inputHeight={inputHeight}
          loadLookUpdataReadOnly={this.loadLookUpdataReadOnly}
          pluginId={pluginId}
          prepareRecorDatawithChanges={this.prepareRecorDatawithChanges}
          readonly={readonly}
          setRequiredState={this.setRequiredState}
          settingsComponents={settingsComponents}
          updateFieldLookUpData={this.updateFieldLookUpData}
          validationCheckState={validationCheckState}
          onChange={this.onChangeValue}
        />
        {showSubmitButton ? (
          <div className="bs-form-submit-btn-wrapper clearfix">
            {tooltip ? (
              <OverlayTrigger overlay={_tooltip} placement="bottom">
                {this.getSubmitButton()}
              </OverlayTrigger>
            ) : (
              this.getSubmitButton()
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  onReady: props.onReady,
  settings: props.settings,
  registerMethod: props.registerMethod,
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  id: props.id,
  actualFilters: props.actualFilters,
  clientWithProgress: props.clientWithProgress,
  clearCaches: props.clearCaches,
  setDataArguments: props.setDataArguments,
  schema: props.schema,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(BootstrapForm, selectConnectorProps)
