import _ from 'lodash'

let totalSchema = {}

const pluginSchema = {
  title: 'Sankey',
  type: 'object',
  format: 'objectTab',
  properties: {
    datafields: {
      type: 'array',
      format: 'HiddenTable',
      title: 'Data Fields',
      items: {
        type: 'string'
      }
    }
  }
}

const generalSchema = {
  general: {
    title: 'General',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      },
      header: {
        type: 'string',
        title: 'Header'
      },
      maximizable: {
        type: 'boolean',
        title: 'Maximizable',
        default: false,
        required: true
      },
      export: {
        type: 'boolean',
        title: 'Exportable',
        default: true,
        required: true
      },
      showNoDataToDisplay: {
        type: 'boolean',
        title: 'Show No Data To Display',
        default: true,
        required: true
      }
    }
  }
}

const titleSchema = {
  title: {
    title: 'Title',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      margin: {
        type: 'number',
        default: 15
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '18px' },
        options: {
          basic: true
        }
      },
      maxStyle: {
        type: 'object',
        title: 'Max Style',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '20px' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: 'Chart Title',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 25
      }
    }
  }
}

const subtitleSchema = {
  subtitle: {
    title: 'Subtitle',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(102,102,102,1)' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 50
      }
    }
  }
}

const exportingSchema = {
  exporting: {
    title: 'Exporting',
    type: 'object',
    properties: {
      allowHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: false
      },
      fallbackToExportServer: {
        type: 'boolean',
        required: true,
        default: true
      },
      filename: {
        type: 'string',
        default: 'chart'
      },
      libURL: {
        type: 'string',
        default: 'https://code.highcharts.com/{version}/lib'
      },
      printMaxWidth: {
        type: 'number',
        default: 780
      },
      scale: {
        type: 'number',
        default: 2
      },
      sourceHeight: {
        type: 'NullableNumber',
        default: ''
      },
      sourceWidth: {
        type: 'NullableNumber',
        default: ''
      },
      url: {
        type: 'string',
        default: 'https://export.highcharts.com'
      },
      width: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const dataSchema = {
  data: {
    title: 'Data',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      },
      dataFrom: {
        type: 'string',
        title: 'From',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      },
      dataTo: {
        type: 'string',
        title: 'To',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      },
      weight: {
        type: 'string',
        title: 'Weight',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      }
    }
  }
}

const tooltipSchema = {
  tooltip: {
    title: 'Tooltip',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: 'rgba(247,247,247,0.85)',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      borderRadius: {
        type: 'number',
        default: 3
      },
      borderWidth: {
        type: 'number',
        default: 1,
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      followPointer: {
        type: 'boolean',
        required: true,
        default: false
      },
      followTouchMove: {
        type: 'NullableBoolean',
        default: null
      },
      footerFormat: {
        type: 'string',
        default: ''
      },
      headerFormat: {
        type: 'string',
        default: ''
      },
      hideDelay: {
        type: 'number',
        default: 500
      },
      padding: {
        type: 'number',
        default: 8
      },
      pointFormat: {
        type: 'string',
        default: ''
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: true
      },
      style: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px'
        },
        options: {
          basic: true
        }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      }
    }
  }
}

const chartSchema = {
  chart: {
    title: 'Chart',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: '#ffffff',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      showCredits: {
        type: 'boolean',
        title: 'Show credits?',
        default: false,
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 9
      },
      plotBackgroundColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      plotBackgroundImage: {
        type: 'string',
        default: ''
      },
      plotShadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      emptyText: {
        type: 'string',
        title: 'No Data Text',
        default: 'No Data to Display',
        options: {
          basic: true
        },
        propertyOrder: 7
      },
      hoverOpacity: {
        type: 'boolean',
        title: 'Change opacity on hover',
        default: true,
        required: true,
        options:{
          basic: true
        },
        propertyOrder: 8
      }
    }
  }
}

const plotOptionsSchema = {
  plotOptions: {
    title: 'Plot Options',
    type: 'object',
    options: {
      splitProperties: false
    },
    properties: {
      sankey: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: false
        },
        properties: {
          dataLabels: {
            type: 'object',
            properties: {
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'center',
                required: true
              },
              animation: {
                type: 'object',
                properties: {
                  defer: {
                    type: 'number',
                    default: undefined
                  }
                }
              },
              backgroundColor: {
                type: 'string',
                format: 'NullColor'
              },
              borderColor: {
                type: 'string',
                format: 'NullColor'
              },
              borderRadius: {
                type: 'number',
                default: 0
              },
              borderWidth: {
                type: 'number',
                default: 0
              },
              className: {
                type: 'string',
                default: ''
              },
              color: {
                type: 'string',
                format: 'NullColor'
              },
              crop: {
                type: 'boolean',
                required: true,
                default: true
              },
              defer: {
                type: 'boolean',
                required: true,
                default: true
              },
              enabled: {
                type: 'boolean',
                required: true,
                default: true,
                options: {
                  basic: true
                },
                propertyOrder: 1
              },
              enabledBasedOn: {
                type: 'string',
                title: 'Enabled Based On',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds',
                options: {
                  basic: true
                },
                propertyOrder: 1
              },
              inside: {
                type: 'NullableBoolean',
                default: true
              },
              overflow: {
                type: 'string',
                enum: ['justify', 'allow'],
                default: 'justify',
                required: true,
                description:
                  'To display data labels outside the plot area, set crop to false and overflow to "allow"'
              },
              padding: {
                type: 'number',
                default: 5,
                description:
                  'When either the borderWidth or the backgroundColor is set, this is the padding within the box.'
              },
              position: {
                type: 'string',
                enum: ['center', 'right'],
                default: 'center'
              },
              rotation: {
                type: 'number',
                default: 0
              },
              shadow: {
                type: 'boolean',
                default: false,
                required: true
              },
              shape: {
                type: 'string',
                enum: ['square', 'callout', 'circle'],
                default: false
              },
              style: {
                type: 'string',
                default: undefined
              },
              textPath: {
                type: 'object',
                properties: {
                  attributes: {
                    type: 'string',
                    dafault: undefined
                  },
                  enabled: {
                    type: 'boolean',
                    dafault: undefined,
                    required: false
                  }
                }
              },
              useHTML: {
                type: 'boolean',
                required: true,
                default: false
              },
              verticalAlign: {
                type: 'string',
                enum: ['', 'top', 'middle', 'bottom'],
                default: '',
                required: true
              },
              x: {
                type: 'number',
                default: 0
              },
              y: {
                type: 'number',
                default: undefined
              },
              z: {
                type: 'number',
                default: 6
              }
            }
          }
        }
      }
    }
  }
}

export default () => {
  if (_.isEmpty(totalSchema)) {
    _.assign(pluginSchema.properties, generalSchema)
    _.assign(pluginSchema.properties, chartSchema)
    _.assign(pluginSchema.properties, titleSchema)
    _.assign(pluginSchema.properties, subtitleSchema)
    _.assign(pluginSchema.properties, dataSchema)
    _.assign(pluginSchema.properties, tooltipSchema)
    _.assign(pluginSchema.properties, exportingSchema)
    _.assign(pluginSchema.properties, plotOptionsSchema)

    totalSchema = pluginSchema
  }
  return totalSchema
}
