export enum DisplayType {
  Text = 'Sentence',
  Loading = 'Loading',
  Grid = 'Grid'
}

interface Grid {
  displayType: DisplayType.Grid
  table: Record<string, unknown>[]
  text?: string
}

interface Loading {
  displayType: DisplayType.Loading
}

interface Text {
  displayType: DisplayType.Text
  text: string
}

export type Response = Grid | Text
type Content = Response | Loading

export interface Message {
  author: string
  content: Content
}
