import cx from 'classnames'
import { getCalculatedBooleanCellProps } from '../../../helpers'
import { agIndeterminateIconClass, iconFontSizeClass, withTextIconSize } from '../../../constants'
import { Theme } from '../../../types'
import { BooleanIconCellRendererParams } from './BooleanIconCellRenderer.types'
import styles from './BooleanIconCellRenderer.module.scss'

const BooleanIconCellRenderer = (props: BooleanIconCellRendererParams) => {
  const {
    api,
    node: { group },
    colDef: {
      customInfo: { boolean, isAutoColumn }
    },
    value,
    context: { theme, groupDisplayType },
    withText = false,
    agGroupClass
  } = props

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    return <strong>{value?.value}</strong>
  }

  const { icon, color } = getCalculatedBooleanCellProps(value, boolean)

  const customAttr: Partial<{ 'data-slvy-element-selector': 'booleanIconCellRenderer' }> = {}
  if (!group) {
    customAttr['data-slvy-element-selector'] = 'booleanIconCellRenderer'
  }

  const isIndeterminateIcon = icon.includes(agIndeterminateIconClass)

  const defaultClass = withText ? withTextIconSize : iconFontSizeClass
  const classes = group ? iconFontSizeClass : defaultClass
  const className = cx(icon, classes[theme as Theme], 'slvy-i-element', {
    cp: !group,
    [agGroupClass]: agGroupClass,
    [styles.slvyIndeterminateIcon]: isIndeterminateIcon
  })

  return <i {...customAttr} className={className} style={{ color }} />
}

export default BooleanIconCellRenderer
