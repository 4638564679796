import { Dropdown } from 'react-bootstrap'
import Jobs from '../../../jobs'
import { OptionsProps } from './Options.types'

const Options = ({ newJobs, cat, path, displayDivider, onChange }: OptionsProps) => {
  return (
    <span data-testid="work-options">
      {newJobs.map((job) => {
        const { config, group } = Jobs[job.key]
        if (cat !== group) {
          return null
        }
        return (
          <Dropdown.Item
            key={job.id}
            as="small"
            className="cp"
            onClick={() => onChange(config.key, path)}
          >
            {config.title}
          </Dropdown.Item>
        )
      })}
      {cat === 'other' && displayDivider ? <Dropdown.Divider /> : null}
    </span>
  )
}

export default Options
