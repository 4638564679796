import jobs from '../../jobs'
import { categorizedJobs } from '../../constants'
import { getJobsWithIds } from '../../helpers'

export const omitJobs = [
  'WaitEvent',
  'RemoteActionCompleted',
  'PageReady',
  'ChangeConnection'
] as const
export const jobsWithIds = getJobsWithIds(jobs)
export const categorizedJobsWithIds = getJobsWithIds(categorizedJobs)
