Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
  extend: 'Ext.window.Window',
  requires: [
    'Ext.layout.container.Fit',
    'Ext.layout.container.VBox',
    'Ext.layout.container.HBox',
    'Ext.form.Panel',
    'Ext.form.field.Text',
    'Ext.form.field.Date',
    'Ext.form.field.Time',
    'Ext.form.field.Checkbox',
    'Ext.calendar.form.CalendarPicker'
  ],
  viewModel: {
    data: {
      record: null
    }
  },
  ui: 'window-inverse',
  resize: false,
  iconCls: 'fa fa-pencil-square-o',
  layout: 'border',
  modal: true,
  closable: false,
  defaultListenerScope: true,
  height: 600,
  width: 600,
  config: {
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     *
     */
    formType: 'MegaRegion',
    plantSpecField: {
      xtype: 'combo',
      fieldLabel: 'Plant Spec ID',
      name: 'PlantSpec',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      flex: 1,
      valueField: 'id',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],

      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var mainrecord = combo.up('window').getViewModel().get('record')
          if (mainrecord) mainrecord.set('MegaregionID', record.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.PlantSpecID}'
      }
    },

    plantTypeField: {
      xtype: 'combo',
      fieldLabel: 'Plant Type',
      name: 'PlantType',
      forceSelection: true,
      margin: '0 0 0 10',
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      bind: {
        store: '{store}',
        value: '{record.PlantTypeID}'
      }
    },

    megaRegionField: {
      xtype: 'combo',
      fieldLabel: 'Region',
      name: 'MegaRegion',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'name',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var mainrecord = combo.up('window').getViewModel().get('record')
          if (mainrecord) mainrecord.set('MegaregionID', record.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.MegaRegion}'
      }
    },
    megaRegionSelectionField: {
      xtype: 'combo',
      fieldLabel: 'Region',
      name: 'MegaRegionSelection',
      itemId: 'megaregionselection',
      forceSelection: true,
      editable: false,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var me = combo.up('window')

          var regionField = me.down('#RegionSelection')
          regionField.setValue('')
          var store = regionField.store
          store.clearFilter()
          store.filter('MegaRegionID', record.get('id'))
          var rec = store.first()
          regionField.setValue(rec.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.MegaRegionID}'
      }
    },
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     */
    regionField: {
      xtype: 'combo',
      fieldLabel: 'Subregion',
      name: 'Region',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'name',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var mainrecord = combo.up('window').getViewModel().get('record')
          if (mainrecord) mainrecord.set('RegionID', record.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.Region}'
      }
    },
    regionSelectionField: {
      xtype: 'combo',
      fieldLabel: 'Subregion',
      name: 'RegionSelection',
      itemId: 'RegionSelection',
      forceSelection: true,
      editable: false,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        beforequery: function (queryPlan, record) {
          var combo = queryPlan.combo

          var me = combo.up('window')

          var megaRegionField = me.down('#megaregionselection')
          var store = combo.store
          store.clearFilter()
          store.filter('MegaRegionID', megaRegionField.getValue())
        }
      },
      bind: {
        store: '{store}',
        value: '{record.RegionID}'
      }
    },
    countryField: {
      xtype: 'combo',
      fieldLabel: 'Country',
      name: 'Country',
      forceSelection: true,
      editable: false,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'name',
      allowBlank: false,
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      bind: {
        value: '{record.Country}'
      }
    },
    supplySourceField: {
      xtype: 'textfield',
      fieldLabel: 'Supply Source',
      name: 'SupplySource',
      bind: {
        value: '{record.SupplySource}'
      }
    },
    descriptionField: {
      xtype: 'textarea',
      fieldLabel: 'Description',
      name: 'description',
      flex: 1,
      bind: {
        value: '{record.Description}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} titleField
     * The config for the title field.
     */
    titleField: {
      xtype: 'textfield',
      fieldLabel: 'Title',
      name: 'title',
      allowBlank: false,
      bind: {
        value: '{record.Title}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} fromContainer
     * The config for the from container.
     */
    latLongContainer: {
      xtype: 'fieldcontainer',
      fieldLabel: 'Lat/Long',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    specContainer: {
      xtype: 'fieldcontainer',

      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    /**
     * @cfg {Object} startDateField
     * The config for the start date field.
     */
    startDateField: {
      xtype: 'combo',
      itemId: 'startDate',
      name: 'startDate',
      allowBlank: false,
      bind: {
        value: '{record.startDate}',
        maxValue: '{record.EndDate}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} startTimeField
     * The config for the start time field.
     */
    latitudeField: {
      xtype: 'textfield',
      itemId: 'latitude',
      name: 'startTime',
      margin: '0 0 0 0',
      flex: 1,
      readOnly: true,
      bind: {
        value: '{record.Latitude}'
      }
    },
    longitudeField: {
      xtype: 'textfield',
      itemId: 'longitude',
      name: 'startTime',
      margin: '0 0 0 5',
      flex: 1,
      readOnly: true,
      bind: {
        value: '{record.Longitude}'
      }
    },
    /**
     * @cfg {Object} toContainer
     * The config for the to container.
     */
    toContainer: {
      xtype: 'fieldcontainer',
      fieldLabel: 'Lat/Long',
      layout: 'hbox'
    },
    tocheckbox: {
      xtype: 'fieldcontainer',
      fieldLabel: 'Status',
      layout: 'hbox'
    },
    /**
     * @cfg {Object} endDateField
     * The config for the end date field.
     */
    endDateField: {
      xtype: 'datefield',
      itemId: 'endDate',
      name: 'endDate',
      allowBlank: false,
      bind: {
        value: '{record.endDate}',
        minValue: '{record.BeginDate}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} endTimeField
     * The config for the end time field.
     */
    endTimeField: {
      xtype: 'timefield',
      itemId: 'endTime',
      name: 'endTime',
      margin: '0 0 0 5',
      bind: {
        value: '{record.endDateTime}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} allDayField
     * The config for the all day field.
     */
    isActiveField: {
      xtype: 'checkbox',
      itemId: 'IsActive',
      name: 'IsActive',
      boxLabel: 'Active ?',
      hideEmptyLabel: false,
      bind: {
        value: '{record.IsActive}',
        readOnly: '{readOnlyMode}'
      }
    },
    IsShownOnTool: {
      xtype: 'checkbox',
      name: 'IsShownOnTool',
      boxLabel: 'Visible ?',
      hideEmptyLabel: false,
      bind: {
        value: '{record.IsShownOnTool}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} descriptionField
     * The config for the description field.
     */
    /**
     * @cfg {Object} dropButton
     * The config for the drop button. `null` to not show this button.
     */
    dropButton: {
      text: 'Delete',
      handler: 'onDropTap'
    },
    /**
     * @cfg {Object} saveButton
     * The config for the save button.
     */
    saveButton: {
      text: 'Save',
      handler: 'onSaveTap',
      iconCls: 'fa fa-floppy-o',
      bind: {
        hidden: '{readOnlyMode}',
        disabled: '{!record.dirty}'
      }
    },
    /**
     * @cfg {Object} cancelButton
     * The config for the cancel button.
     */
    cancelButton: {
      text: 'Close',
      handler: 'onCancelTap'
    }
  },
  initForm: function () {
    this.createItems()
  },
  initComponent: function () {
    var me = this
    this.getViewModel().set('record', this.record)
    me.initForm()
    me.fbar = me.generateButtons()
    me.callParent()
    me.form = me.items.first()
    me.checkFields()
    me.applyValues()
  },
  generateButtons: function () {
    var buttons = []

    var drop = this.getDropButton()
    if (drop) {
    }
    buttons.push(
      {
        xtype: 'component',
        flex: 1
      },
      this.getSaveButton(),
      this.getCancelButton()
    )
    return buttons
  },
  applyValues: function () {},
  createItems: function () {
    var me = this

    var latLongCt = me.getLatLongContainer()

    var tocheckbox = me.getTocheckbox()

    var toCt = me.getToContainer()

    var specContainer = me.getSpecContainer()
    var items = []
    if (!specContainer.items) {
      specContainer.items = [me.getPlantSpecField(), me.getPlantTypeField()]
    }

    if (me.getFormType() == 'MegaRegion') {
      items.push(me.getMegaRegionField())
    } else if (me.getFormType() == 'Region') {
      items.push(me.getMegaRegionSelectionField())
      items.push(me.getRegionField())
    } else if (me.getFormType() == 'Country') {
      items.push(me.getMegaRegionSelectionField())
      items.push(me.getRegionSelectionField())
      items.push(me.getCountryField())
    } else if (me.getFormType() == 'SupplySource') {
      items.push(me.getMegaRegionSelectionField())
      items.push(me.getRegionSelectionField())
      items.push(me.getSupplySourceField())
      items.push(me.getDescriptionField())
      tocheckbox.items = [me.getIsActiveField(), me.getIsShownOnTool()]
      items.push(tocheckbox)
      items.push(specContainer)
    }

    if (!latLongCt.items) {
      latLongCt.items = [me.getLatitudeField(), me.getLongitudeField()]
    }
    items.push(latLongCt)
    this.items = [
      {
        xtype: 'form',
        border: false,
        trackResetOnLoad: true,
        region: 'north',
        height: '100',
        layout: {
          type: 'vbox',
          align: 'stretch'
        },
        bodyPadding: 10,
        items: items
      },
      {
        xtype: 'panel',
        region: 'center',
        layout: 'fit',
        bodyPadding: 10,
        border: true,
        margin: '5 0 0 0',
        items: {
          xtype: 'globalapp-geo-map',
          region: 'center',
          reference: 'map',
          bind: {
            record: '{record}'
          },
          listeners: {
            dragend: 'onDragend'
          }
        }
      }
    ]
  },
  privates: {
    checkFields: function () {},
    onAllDayChange: function () {
      this.checkFields()
    },
    onCancelTap: function () {
      this.getViewModel().get('record').reject()
      this.close()
    },
    onDropTap: function () {
      this.fireDrop()
    },
    onSaveTap: function () {
      this.fireEvent('save', this)
      this.close()
    },
    getMegaRegionField: function (c) {
      var c = this.megaRegionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getRegionField: function (c) {
      var c = this.regionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getMegaRegionSelectionField: function (c) {
      var c = this.megaRegionSelectionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getRegionSelectionField: function (c) {
      var c = this.regionSelectionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getCountryField: function () {
      var c = this.countryField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getPlantSpecField: function () {
      var c = this.plantSpecField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getPlantTypeField: function () {
      var c = this.plantTypeField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getStore: function (c) {
      var data = this.getViewModel().get('record').data
      return Ext.create('Ext.data.Store', {
        field: [
          {
            name: 'name'
          },
          {
            name: 'id'
          }
        ],
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Geo_FormCombo',
          extraParams: {
            fieldType: c,
            data: Ext.encode(data)
          },
          reader: {
            type: 'json',
            rootProperty: 'users'
          }
        },
        autoLoad: true
      })
    }
  }
})
// [web].[SP_Global_MDM_Geo_FormCombo] copy from 4_c to 4

// [web].[SP_Global_MDM_Geo_SupplySource_Create] copy from 4_c to 4
