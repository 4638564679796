import _ from 'lodash'
import { updateObject } from '../utility'
import {
  RESET_ACTUAL_SCENARIO,
  RESET_ACTUAL_SCENARIO_BY_TYPE,
  RESET_SCENARIO,
  SELECT_ACTUAL_SCENARIO,
  SELECT_SCENARIO
} from '../actions/actionTypes'

const defaultBaselineScenarioForActual = {
  dataKey: 'scenarioTree',
  index: 0,
  currentScenario: {
    Id: 0,
    IsActualized: false
  },
  scenarioType: 'Baseline'
}

const defaultPlannedScenarioForActual = {
  dataKey: 'scenarioTree',
  index: 0,
  currentScenario: {
    Id: 0,
    IsActualized: false
  },
  scenarioType: 'Planned'
}

const initialState = {
  scenarioTree: {
    currentScenarios: [],
    actualScenarioList: [defaultBaselineScenarioForActual, defaultPlannedScenarioForActual]
  }
}

const sortByType = ($scenarios, $type) => {
  const $target = $scenarios.find((scenario) => scenario.scenarioType === $type)
  const $filteredScenarios = [...$scenarios].filter((scenario) => scenario.scenarioType !== $type)
  return $target ? [$target, ...$filteredScenarios] : $scenarios
}

const getToggledScenarios = (scenarios, newItem) => {
  const itemIndex = scenarios.findIndex(
    (item) => item.index === newItem.index && item.scenarioType === newItem.scenarioType
  )
  if (itemIndex === -1) {
    // add last
    scenarios.push(newItem)
  } else {
    // remove if exist
    if (
      scenarios[itemIndex].currentScenario.Id === newItem.currentScenario.Id &&
      scenarios[itemIndex].scenarioType === newItem.scenarioType
    ) {
      const others = scenarios.filter(({ scenarioType }) => scenarioType !== newItem.scenarioType)
      const current = scenarios.filter(
        (item) => item.index !== newItem.index && item.scenarioType === newItem.scenarioType
      )
      scenarios = [...others, ...current]
    } else {
      // update with new one
      scenarios[itemIndex] = newItem
    }
  }
  return scenarios
}

export const getToggledScenariosForActual = (scenarios, newItem) => {
  const itemIndex = scenarios.findIndex((item) => item.scenarioType === newItem.scenarioType)
  // important to cloneDeep
  const clonedNewScenarios = _.cloneDeep(scenarios)
  // update with new one
  clonedNewScenarios[itemIndex] = _.cloneDeep(newItem)

  return clonedNewScenarios
}

const reducer = (state = initialState, action) => {
  const mainType = 'Baseline'
  switch (action.type) {
    case SELECT_SCENARIO:
      const _currentScenarios = sortByType(
        getToggledScenarios([...state[action.payload.dataKey].currentScenarios], {
          ...action.payload
        }),
        mainType
      )
      return updateObject(state, {
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          currentScenarios: [..._currentScenarios]
        }
      })
    case RESET_SCENARIO:
      return updateObject(state, {
        [action.payload.dataKey]: {
          ...initialState[action.payload.dataKey]
        }
      })
    case SELECT_ACTUAL_SCENARIO:
      const actualScenarioList = getToggledScenariosForActual(
        [...state[action.payload.dataKey].actualScenarioList],
        {
          ...action.payload
        }
      )
      return updateObject(state, {
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          actualScenarioList: [...actualScenarioList]
        }
      })
    case RESET_ACTUAL_SCENARIO:
      return updateObject(state, {
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          actualScenarioList: [...initialState[action.payload.dataKey].actualScenarioList]
        }
      })
    case RESET_ACTUAL_SCENARIO_BY_TYPE:
      return updateObject(state, {
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          actualScenarioList: [...state[action.payload.dataKey].actualScenarioList].filter(
            (item) => {
              return item.scenarioType !== action.payload.scenarioType
            }
          )
        }
      })

    default:
      return state
  }
}

export default reducer
