import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ResourceRowItem from '../resourceRowItem'
import { calcTopValue } from '../../../../utils'

const ResourceRow = (props) => {
  const renderChildren = (
    children,
    isResourceChartOpen,
    ownResourceIndex,
    isExpanded,
    locationNames = [],
    globalIndex = 0
  ) => {
    return children.map((childrenItem, index) => {
      const {
        Name: childrenName = '',
        Children: grandchildren = [],
        Type: childrenType = '',
        Level: level = 0,
        Index: childrenIndex,
        isPinned = false
      } = childrenItem
      const {
        getRccpProductsResponse = {},
        initialFilters = {},
        lastRequest = {},
        settings = {},
        selectedResourceIssueCount = 0,
        maxSelectedIssues = 0,
        connectApi = () => {},
        isAllowed = () => {},
        stateHandler = () => {},
        viewHandler = () => {}
      } = props

      let periodStyle = {
        width: _.size(props.periods) * 70 + 314
      }
      if (isResourceChartOpen && childrenType !== 'Resource') periodStyle = {}

      const isLastProduct = !!(childrenType === 'Product' && children.length - 1 === index)

      const pinnedStyles = {
        position: 'sticky',
        zIndex: 99,
        top: calcTopValue(index, selectedResourceIssueCount, maxSelectedIssues)
      }

      const location = { name: childrenName, index: childrenIndex }
      const dynamicProps = _.assign(
        {},
        ..._.map(
          childrenType === 'Resource' ? [location] : [...locationNames, location],
          (location, i) => ({
            [`locationName${i}`]: location
          })
        )
      )

      const path = [...locationNames, location].map(({ name }) => name).join('/')

      return (
        <div
          key={`${childrenType}-${childrenName}-${index}-${isPinned ? 'pinned' : 'not-pinned'}`}
          style={{ ...(isPinned ? pinnedStyles : {}), backgroundColor: '#f9f9f9' }}
        >
          <div className="resource-row-item-outer-wrapper" style={periodStyle}>
            <ResourceRowItem
              children={grandchildren}
              aIsResourceChartOpen={isResourceChartOpen}
              connectApi={connectApi}
              path={path}
              // locations={locations}
              getRccpProductsResponse={getRccpProductsResponse}
              indentLevel={level}
              globalIndex={index}
              index={childrenIndex}
              initialFilters={initialFilters}
              isAllowed={isAllowed}
              isExpanded={isExpanded}
              isLastProduct={isLastProduct}
              isPinned={isPinned}
              lastRequest={lastRequest}
              name={childrenName}
              ownResourceIndex={ownResourceIndex}
              renderChildren={renderChildren}
              settings={settings}
              stateHandler={stateHandler}
              type={childrenType}
              viewHandler={viewHandler}
              {...dynamicProps}
            />
          </div>
        </div>
      )
    })
  }

  const { root: { Children: children = [] } = {}, pinnedRows = [] } = props

  return (
    <div className="resource-row-wrapper resource-plan__resource-row-wrapper">
      {renderChildren([...pinnedRows, ...children])}
    </div>
  )
}

const mapStateToProps = (state) => ({
  pinnedRows: state.resourcePlan.pinnedRows,
  maxSelectedIssues: state.legend.maxSelectedIssues,
  selectedResourceIssueCount: state.legend.selectedResourceIssueCount,
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo
})

export default connect(mapStateToProps)(ResourceRow)
