import { useState, memo, useCallback } from 'react'
import { Card } from 'react-bootstrap'
import _ from 'lodash'
import { useUniqueId } from '../../../../../../hooks'
import FormHeading from '../FormHeading'
import { SlvyFormCheck } from '../../../../../../components'
import NoVariables from '../NoVariables'
import EventRow from './EventRow'
import { IEventsProps } from './Events.types'
import { emptyObject } from '../../../../constants'
import WorkflowInformation from '../WorkflowInformation'

const Events = ({
  assignees = emptyObject,
  id,
  title,
  parentKey,
  eventParams,
  toggleActives,
  onChange
}: IEventsProps) => {
  const [uniqueId] = useUniqueId()
  const checkboxId = `checkbox-${uniqueId}`

  const [isActive, setIsActive] = useState(() => !_.isEqual(assignees, emptyObject))

  const handleReturnVariableChange = useCallback(
    (newValue: string, key: string) => {
      onChange({ id, newValue, key, parentKey, type: 'events' })
    },
    [id, onChange, parentKey]
  )

  const handleChangeStatus = () => {
    setIsActive((prev: boolean) => !prev)
    onChange({
      id,
      newValue: '',
      key: '',
      parentKey,
      type: 'events',
      shouldRemoveAll: true
    })
  }

  const hasEventParams = Object.keys(eventParams ?? {}).length > 0

  if (toggleActives && !isActive) {
    return null
  }

  return (
    <Card className="mb-2" data-testid="event-card">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <SlvyFormCheck
          reverse
          checked={isActive}
          formCheckClass="w-100 d-flex justify-content-between"
          inputId={checkboxId}
          label={title}
          size="sm"
          type="switch"
          variant="success"
          onChange={handleChangeStatus}
        />

        <WorkflowInformation title={title} />
      </Card.Header>
      {isActive ? (
        <Card.Body>
          <div className="form-horizontal">
            {hasEventParams ? (
              <>
                <FormHeading />
                {_.map(eventParams, (param, paramKey) => {
                  const { [paramKey]: value = '' } = assignees
                  return (
                    <EventRow
                      key={id + paramKey}
                      noRowMarginBottom={Object.keys(eventParams ?? {}).pop() === paramKey}
                      paramKey={paramKey}
                      paramType={param.type}
                      value={value}
                      onChange={handleReturnVariableChange}
                    />
                  )
                })}
              </>
            ) : (
              <NoVariables />
            )}
          </div>
        </Card.Body>
      ) : null}
    </Card>
  )
}

export default memo(Events)
