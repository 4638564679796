Ext.define('tuiv2.cmp.Settings', {
  extend: 'Ext.button.Button',
  alias: 'widget.tuiv2-settings',
  config: {
    menuPanel: [
      {
        xtype: 'numberfield',
        fieldLabel: 'SmoothFactor',
        minValue: 0,
        bind: {
          value: '{smoothFactor}'
        }
      },
      {
        xtype: 'numberfield',
        fieldLabel: 'Weight',
        minValue: 1,
        bind: {
          value: '{weight}'
        }
      },
      {
        xtype: 'numberfield',
        fieldLabel: 'Opacity',
        minValue: 0,
        maxValue: 1,
        step: 0.05,
        bind: {
          value: '{opacity}'
        }
      },
      {
        xtype: 'fieldset',
        title: 'Demo Mode',
        collapsible: true,
        bind: {
          hidden: '{!demoMode}'
        },
        items: [
          {
            xtype: 'checkboxfield',
            labelWidth: 180,
            fieldLabel: 'Demo Enabled',
            bind: '{demoEnabled}'
          },
          {
            xtype: 'tbtext',
            padding: '10 10 0 0',
            bind: {
              hidden: '{!demoEnabled}',
              text: 'Simulation Date :<b>' + '{simulationDateStr}' + '</b>'
            }
          },
          {
            xtype: 'slider',
            width: 200,
            minValue: 0,
            clickToChange: false,
            bind: {
              hidden: '{!demoEnabled}',
              maxValue: '{simulationSliderMaxValue}',
              value: '{simulationSliderValue}'
            },
            tipText: function (tip) {
              var panel = tip.ownerCt.up('Tuiv2MainView')
              var viewModel = panel.getViewModel()
              viewModel.set('simulationSliderValue', tip.value)
              return viewModel.get('simulationDateStr')
            },
            listeners: {
              beforechanged: function (slider, newValue, oldValue) {
                var panel = slider.ownerCt.up('Tuiv2MainView')
                var viewModel = panel.getViewModel()
                var planInfo = viewModel.get('PlanInfo')

                var currentDateTime = Ext.Date.parse(planInfo.CurrentDateTime, 'Y-m-d\\TH:i:s')
                var startDate = Ext.Date.parse(PlanInfo.StartDateTime, 'Y-m-d\\TH:i:s')
                if (newValue < currentDateTime.getTime() - startDate.getTime()) {
                  return false
                }
                return true
              },
              dragend: function (slider, e, eOpts) {
                var me = this
                var viewModel = me.up('Tuiv2MainView').getViewModel()

                viewModel.set('isOperationInProgress', true)

                var simulationDate = viewModel.get('simulationDate')
                tui.util.Mask.apply('Loading data')

                Ext.Ajax.request({
                  url: tuiv2.Global.getApiUrl() + 'tuiv2/SetActiveTime',
                  method: 'POST',
                  timeout: 600000,
                  params: {
                    activeTime: simulationDate
                  },
                  success: function (response) {
                    var jsonData = Ext.decode(response.responseText)
                    tui.util.Mask.destroy('Loading data')
                    viewModel.set('isOperationInProgress', false)
                  },
                  // If not logged in redirect to the login route
                  failure: function (response) {
                    tui.util.Mask.destroy('Loading data')
                    viewModel.set('isOperationInProgress', false)
                  }
                })
              }
            }
          }
        ]
      },
      {
        xtype: 'checkbox',
        fieldLabel: 'Show On Map',
        tooltip: 'Draw route when open a card.',
        bind: {
          value: '{drawRoute}'
        }
      }
    ]
  },
  setUi: function (c) {
    this.setUI(c)
  },
  applyMenuPanel: function (items) {
    if (items) {
      this.menu = {
        xtype: 'menu',
        deltaY: 8,
        deltaX: -90,
        plain: true,
        shadow: true,
        bodyPadding: 3,
        closeAction: 'hide',
        items: [
          {
            xtype: 'panel',
            border: false,
            minWidth: 300,
            header: false,
            bodyPadding: 10,
            defaults: {
              anchor: '100%'
            },
            items: items
          }
        ]
      }
    }
  }
})
