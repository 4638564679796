import { GetFieldConfigsValuesProps } from '../../../types'
import { fieldConfigs } from '../../__tests__/mocks'
import { getFieldConfigsValues } from '../common'
import { rows, expectedResultForFieldConfigValues, expectedNullValuesForAllColumns } from './mocks'

const getFieldConfigsValuesParams = (): GetFieldConfigsValuesProps => ({
  fieldConfigs,
  rows
})

describe('AgGrid:Helpers:Common:getFieldConfigsValues', () => {
  let params = getFieldConfigsValuesParams()

  beforeEach(() => {
    params = getFieldConfigsValuesParams()
  })
  it('should return correct field values for each field', () => {
    const result = getFieldConfigsValues(params)

    expect(result).toEqual(expectedResultForFieldConfigValues)
  })

  it('should return null for all fields in empty rows', () => {
    params.rows = []

    const result = getFieldConfigsValues(params)

    expect(result).toEqual(expectedNullValuesForAllColumns)
  })

  it('should return an empty object if fieldConfigs is empty array', () => {
    params.fieldConfigs = []

    const result = getFieldConfigsValues(params)

    expect(result).toEqual({})
  })
})
