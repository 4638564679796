import { Component } from 'react'
import _ from 'lodash'

import ObjectEditor from '.'
import { SlvySelect } from '../../../index'

export default class OneOfEditor extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.renderProperties = this.renderProperties.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { schema, value } = this.props

    const val = value || (schema && schema.default) || {}

    const typeProp = schema.oneOf[0] && schema.oneOf[0].required && schema.oneOf[0].required[0]

    if (_.isNil(val[typeProp])) {
      val[typeProp] = this.getOneOfValue(schema.oneOf[0], typeProp)
      this.props.onChange(val)
    }

    this.setState({ value: val, typeProp })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value ?? {} })
    }
  }

  handleTypeChange(newTypeValue) {
    const { value, typeProp } = this.state
    const type = newTypeValue && newTypeValue.value

    if (value[typeProp] !== type) {
      // TODO : keep value ???

      const newValue = value
      newValue[typeProp] = type
      // const newValue = { [typeProp]: type }

      this.setState({ value: newValue }, this.props.onChange(newValue))
    }
  }

  handleChange(value) {
    if (!_.isEqual(this.state.value, value)) {
      this.setState({ value }, this.props.onChange(value))
    }
  }

  getOneOfValue(oneOfOption, typeProp) {
    if (oneOfOption.properties[typeProp] && oneOfOption.properties[typeProp].enum) {
      return oneOfOption.properties[typeProp].enum[0]
    }

    return undefined
  }

  hasVisibleSubProperty(subSchema) {
    if (subSchema && subSchema.properties) {
      for (const key in subSchema.properties) {
        const element = subSchema.properties[key]

        if (!(element.options && element.options.hidden === true)) {
          return true
        }
      }
    }

    return false
  }

  renderProperties() {
    const { schema, onSynchronizeDataDefinition, getWatchedValue, isMultipleColumnActive } =
      this.props
    const { value, typeProp } = this.state
    const { [typeProp]: type } = value

    let subSchema
    schema.oneOf.forEach((option, i) => {
      const optionValue = this.getOneOfValue(option, typeProp)
      if (optionValue === type) {
        subSchema = option
      }
    })

    if (!this.hasVisibleSubProperty(subSchema)) {
      return null
    }

    return (
      <div className="subProperty">
        <h3>
          <span>{subSchema && subSchema.title}</span>
          <div className="btn-group" />
        </h3>
        <ObjectEditor
          isMultipleColumnActive={isMultipleColumnActive}
          getWatchedValue={getWatchedValue}
          schema={subSchema}
          value={value}
          onChange={this.handleChange}
          onSynchronizeDataDefinition={onSynchronizeDataDefinition}
        />
      </div>
    )
  }

  render() {
    const { schema } = this.props
    const { value, typeProp } = this.state
    const { [typeProp]: type } = value

    const options = []
    schema.oneOf.forEach((option, i) => {
      options[i] = {
        value: this.getOneOfValue(option, typeProp),
        label: option.title
      }
    })

    // TODO:
    // options.unshift({ value: undefined, label: ' ' })

    // TODO : default option ???

    const selectValue = _.find(options, (item) => item.value === type) || {}

    return (
      <div className="well well-sm">
        <div>
          <div className="row clear-both">
            <div className="col-md-12">
              <div className="form-group px-0">
                <label className="col-form-label">{schema && schema.title}</label>
                <SlvySelect
                  className="col-md-6 w-100 px-0"
                  isClearable={false}
                  options={options}
                  placeholder="select"
                  value={selectValue}
                  onChange={this.handleTypeChange}
                />
              </div>
            </div>
          </div>
          {this.renderProperties()}
        </div>
      </div>
    )
  }
}
