import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import Body from './Body'
import Cell from './Cell'
import Footer from './Footer'
import FooterHeader from './FooterHeader'
import Head from './Head'
import Header from './Header'
import Row from './Row'
import DraggableRow from './DraggableRow'
import { CommonThemeOpts, BootstrapThemeProps, TableProps } from './Table.types'

function getThemeOpts(
  themeOpts: CommonThemeOpts | BootstrapThemeProps['themeOpts'],
  themeStyles: { [key: string]: string }
) {
  const commonThemeOpts = [
    { [themeStyles.tableHover]: themeOpts?.hover ?? true },
    { [themeStyles.tableStriped]: themeOpts?.striped ?? true }
  ]
  const isBootstrapTheme = themeOpts && 'bordered' in themeOpts

  if (isBootstrapTheme) {
    return commonThemeOpts.concat(
      { [themeStyles.tableBordered]: themeOpts.bordered },
      { [themeStyles.tableBorderless]: themeOpts.borderless }
    )
  }

  return commonThemeOpts
}

function Table({
  children,
  className,
  theme = 'react-table',
  themeOpts = {},
  density,
  ...props
}: TableProps) {
  const themeStyles = useTheme()
  const classNames = cx(
    themeStyles.table,
    ...getThemeOpts(themeOpts, themeStyles),
    themeStyles[`${density}Density`],
    className
  )

  return (
    <div className={classNames} {...props}>
      {/* <ThemeContext.Provider value={themeStyles}>{children}</ThemeContext.Provider> */}
      {children}
    </div>
  )
}

Table.Body = Body
Table.Cell = Cell
Table.Footer = Footer
Table.FooterHeader = FooterHeader
Table.Head = Head
Table.Header = Header
Table.Row = Row
Table.DraggableRow = DraggableRow

export default Table
