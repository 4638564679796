Ext.define('po.cmp.SubstituteTmp', {
  extend: 'Ext.panel.Panel',
  xtype: 'poSubstituteTmp',
  alias: 'widget.poSubstituteTmp',

  tpl: new Ext.XTemplate(
    '<div style="display:grid; font-size:14px;">',
    '<div style="display:inline-flex;">',
    '<div style="padding:3px;margin:5px;">',
    '<span>{Substitute_Grid_UrunKodu} </span>: <b>{urunKodu}</b> |',
    '<span>{Substitute_Grid_Urun} </span>: <b>{urunAciklamasi}</b>',
    '</div>',
    '</div>',
    '<div style="display:inline-flex;">',
    '<div style="padding:3px;margin:5px;;">',
    '<span>{Substitute_Grid_Tedarikci} </span>: <b>{tedarikciAdi}</b> | ',
    '<span>{Substitute_Grid_SiparisSayisi} </span>: <b>{degisenOneriKoli}</b> ',
    '</div>',
    '</div>',
    '</div>'
  )
})
