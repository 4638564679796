Ext.define('po.model.SubstituteModel', {
  extend: 'po.model.Base',
  entityName: 'po/substitute',
  fields: [
    [
      { name: 'urunKodu', type: 'string' },
      { name: 'urunAciklamasi', type: 'string' },
      { name: 'tedarikciKodu', type: 'string' },
      { name: 'tedarikciAdi', type: 'string' },
      { name: 'degisenOneriKoli', type: 'int' },
      { name: 'depoKodu', type: 'string' },
      { name: 'koliIciMiktar', type: 'float' },
      { name: 'minSiparisMiktari', type: 'float' },
      { name: 'paletKatsayisi', type: 'float' },
      { name: 'siparisKatsayisi', type: 'float' }
    ]
  ]
})
