import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  saveMfpSettings,
  saveSubsetViewSettings,
  resetQuickFilterSettings
} from '../../store/actions'
import ViewModes from './ViewModes'
import HeaderButtonGroup from './HeaderButtonGroup/index.ts'
import HeaderPageTitle from './HeaderPageTitle'
import HeaderSmallFilterGroup from './HeaderSmallFilterGroup'
import QuickFilter from '../QuickFilter'
import HeaderButton from './HeaderButton'
import { MESSAGES } from '../../messages'

class Header extends Component {
  constructor() {
    super()
    this._resetQuickFilterSettings = this._resetQuickFilterSettings.bind(this)
  }

  _resetQuickFilterSettings(needReload = false) {
    const { onReloadViewClick = () => {} } = this.props
    this.props.resetQuickFilterSettings({
      payload: {}
    })

    if (needReload) {
      onReloadViewClick()
    }
  }

  onChangeFontSize() {
    this.props.saveMfpSettings({
      payload: {
        mfpSettings: {
          isSmallFontSize: !this.props.mfpSettings.isSmallFontSize
        }
      }
    })
  }

  render() {
    const {
      props: {
        settings: {
          config: {
            updateModes,
            actions: {
              isVisiblePivotBuilderButton = true,
              isVisibleChangeScenarioButton = true,
              isVisibleActionsButton = true,
              actionLogsColumnName
            } = {},
            configuration: { isQuickFilterEnabled = false } = {}
          } = {}
        } = {},
        selectedScenarioTree: { currentScenarios = [] },
        pivotViewSettings: { isVisible: isVisiblePivotView },
        pivotBuilderViewSettings: { draftState: draftPivotState = {} },
        scenarioComparisonSettings: { isVisible: isVisibleScenarioComparison },
        quickFilterSettings: { selected: { Key: selectedQuickFilterKey = '' } = {} } = {},
        headerSettings: { updateMode = 0, mode = 0, editMode: { format: buttonFormat } = {} },
        mfpSettings: { isSmallFontSize },
        isScenarioEditable,
        pluginId = '',
        pluginStates = [],
        isWorkInSubsetEnabled,
        isEditModeAllowed,
        rowUpdateEnable,
        externalUpdateEnable,
        preferredUsername,
        onReloadViewClick = () => {},
        onApplyChangesHandle = () => {},
        onDiscardHandle = () => {},
        onPivotStateChanged = () => {},
        getSelectedPivotBuilderState = () => {},
        getPivotBuilderStates = () => {},
        getBaseRequestParameters = () => {},
        onViewChangedCallback = () => {}
      }
    } = this

    const isVisibleApplyChanges = _.get(this.props, 'GetUserScenario.Result.Updated', false)
    const isVisibleDiscardChanges = _.get(this.props, 'GetUserScenario.Result', null)
    const displayPivotState = getPivotBuilderStates().length > 1
    const selectedPivotState = _.get(
      getSelectedPivotBuilderState(),
      'config.stateSettings.name',
      ''
    )

    return (
      <div className="slvy-ui-header-light">
        <HeaderButtonGroup
          actionLogsColumnName={actionLogsColumnName}
          isScenarioEditable={isScenarioEditable}
          isVisibleActionsButton={isVisibleActionsButton}
          isVisibleApplyChanges={isVisibleApplyChanges}
          isVisibleChangeScenarioButton={isVisibleChangeScenarioButton && !isWorkInSubsetEnabled}
          isVisibleDiscardChanges={isVisibleDiscardChanges}
          isVisiblePivotBuilderButton={isVisiblePivotBuilderButton}
          isVisibleScenarioComparison={isVisibleScenarioComparison}
          isWorkInSubsetEnabled={isWorkInSubsetEnabled}
          pluginId={pluginId}
          pluginStates={pluginStates}
          preferredUsername={preferredUsername}
          onApplySubsetChangesHandle={onApplyChangesHandle.bind(this)}
          onDiscardHandle={onDiscardHandle.bind(this)}
          onPivotStateChanged={onPivotStateChanged.bind(this)}
          onReloadViewClick={onReloadViewClick.bind(this)}
          onViewChangedCallback={onViewChangedCallback.bind(this)}
        />
        <HeaderPageTitle
          _resetQuickFilterSettings={this._resetQuickFilterSettings}
          currentScenarios={[...currentScenarios]}
          displayPivotState={displayPivotState}
          draftPivotState={draftPivotState}
          isVisibleScenarioComparison={isVisibleScenarioComparison}
          selectedPivotState={selectedPivotState}
          selectedQuickFilterKey={selectedQuickFilterKey}
        />
        <div className="dp-btn-group">
          <HeaderButton
            extraClass="adjust-font-size"
            isVisible={isVisibleScenarioComparison}
            label={MESSAGES.adjust_font_size}
            onClickHandle={this.onChangeFontSize.bind(this)}
          >
            {isSmallFontSize ? (
              <span>
                <strong className="fw-bold">a</strong>
                <span>A</span>
              </span>
            ) : (
              <span>
                <span>a</span>
                <strong className="fw-bold">A</strong>
              </span>
            )}
          </HeaderButton>

          {isQuickFilterEnabled ? (
            <QuickFilter
              _resetQuickFilterSettings={this._resetQuickFilterSettings}
              getBaseRequestParameters={getBaseRequestParameters.bind(this)}
              getPivotBuilderStates={getPivotBuilderStates.bind(this)}
              pluginId={pluginId}
              onPivotStateChanged={onPivotStateChanged.bind(this)}
            />
          ) : null}
          <HeaderSmallFilterGroup onReloadViewClick={onReloadViewClick.bind(this)} />
          <ViewModes
            buttonFormat={buttonFormat}
            externalUpdateEnable={externalUpdateEnable}
            isEditModeAllowed={isEditModeAllowed}
            isVisiblePivotView={isVisiblePivotView}
            isWorkInSubsetEnabled={isWorkInSubsetEnabled}
            mode={mode}
            rowUpdateEnable={rowUpdateEnable}
            updateMode={updateMode}
            updateModes={updateModes}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mfpSettings: state.mfpSettings.mfpSettings,
    headerSettings: state.headerSettings.headerSettings,
    GetUserScenario: state.getUserScenario.GetUserScenario,
    pivotViewSettings: state.pivotViewSettings.pivotViewSettings,
    selectedScenarioTree: state.scenarioTreeSelection.scenarioTree,
    quickFilterSettings: state.quickFilterSettings.quickFilterSettings,
    scenarioComparisonSettings: state.scenarioComparisonSettings.scenarioComparisonSettings,
    pivotBuilderViewSettings: state.pivotBuilderViewSettings.pivotBuilderViewSettings
  }
}

const mapDispatchToProps = {
  saveMfpSettings,
  saveSubsetViewSettings,
  resetQuickFilterSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
