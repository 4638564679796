import React, { Component } from 'react'
import _ from 'lodash'
import numeral from 'numeral'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import VirtualList from 'react-tiny-virtual-list'
import OptionFields from '../../OptionView/option-fields'
import { slvyToast } from '../../../../../components'
import settings from '../../../utils/settings'
import { filterOptions, removeCurrencySymbol, sortByAdvanced } from '../../../utils'
import AssortmentLoading from '../../Loading'
import OptionImage from '../../OptionImage'
import OptionSidebar from '../../OptionView/option-sidebar'
import { setLoaderReducer } from '../../../store/slices/appSlice'
import { getOptions } from '../../../store/slices/options'

class AssortmentOptions extends Component {
  constructor(props) {
    super(props)
    this.settings = { ...settings }
    this.state = {
      show: false
    }
  }

  componentDidMount() {
    const {
      pluginId,
      selectedScenarioClusterTree: {
        currentScenario: { Id: ClusterNodeId = null } = {},
        scenarioName: ClusterName = ''
      } = {},
      dependencyFilters: DependencyFilterStr = [],
      setLoader = () => {},
      onSaveAssortmentId = () => {},
      fetchOptions = () => {}
    } = this.props

    const payload = {
      pluginId,
      method: 'Load',
      requestMethod: 'post',
      body: {
        DependencyFilterStr,
        ClusterNodeId,
        ClusterName
      }
    }

    setLoader({ isShown: true, messages: 'Loading Options' })

    fetchOptions({ ...payload }).then(() => {
      const {
        rootOptions,
        rootOptions: { AssortmentId = null } = {},
        errorRootOptions: { message: errorMessage = '' } = {}
      } = this.props

      setLoader({ isShown: false, messages: 'Loading Options' })

      if (_.isEmpty(rootOptions)) {
        slvyToast.error({ message: errorMessage, title: 'Load Options' })
      } else {
        onSaveAssortmentId(AssortmentId)
      }
    })
  }

  getTooltip(Description = '', Id = 0) {
    return <Tooltip id={Id + Description}>{Description}</Tooltip>
  }

  getDescriptionTemplate(Description, Id, Limit = 20) {
    Description = Description ? Description.trim() : null
    if (!Description) {
      return <div>-</div>
    }
    if (Description.length <= Limit) {
      return <div>{Description}</div>
    }
    const croppedDescription = `${Description.substring(0, Limit)}...`
    return (
      <OverlayTrigger overlay={this.getTooltip(Description, Id)} placement="top">
        <div>{croppedDescription}</div>
      </OverlayTrigger>
    )
  }

  removeCurrencySymbol(value, format) {
    const { formattingSettings: { currencySymbol = '$' } = {} } = this.props

    return removeCurrencySymbol(numeral(value).format(format), currencySymbol)
  }

  render() {
    let {
      props: {
        isLoading = true,
        size: { width = 0 } = {},
        Settings,
        Settings: { sortBy = 'aZ_Ascending' } = {},
        formattingSettings,
        formattingSettings: { current = '0,0', decimal = '0,0.00' } = {},
        rootOptions: { Options = [], SizeRangeMapping = {} } = {}
      }
    } = this

    Options = filterOptions(_.cloneDeep(Options), Settings)
    Options = sortByAdvanced(Options, sortBy)

    return (
      <div className="option-view h-100">
        {!_.isEmpty(Options) && (
          <div className="main-wrp h-100">
            <div ref="leftSideCtn" className="left-side-ctn">
              <OptionSidebar IsPreviewMode />
            </div>
            <VirtualList
              ref="virtualList"
              className="options-ctn"
              height="100%"
              itemCount={Options.length}
              itemSize={154}
              renderItem={({ index: optionIndex, style }) => {
                const optionItem = Options[optionIndex]
                const {
                  IsTestProduct = false,
                  ImageUrl = '',
                  Name = '',
                  Description = '',
                  Id = -1,
                  BasePrice,
                  TotalSales = 0,
                  TotalBuyQuantity = 0
                } = optionItem

                const imgClasses = `${IsTestProduct ? ' test-product ' : ''}`

                return (
                  <div key={optionIndex} className="option-w-segments" style={style}>
                    <div className="option-card">
                      <div className="option-img-ctn">
                        <OptionImage alt={Description} extraClass={imgClasses} source={ImageUrl} />
                        <div className="spinner" />
                        {this.getDescriptionTemplate(Description, Id)}
                        <div>{Name}</div>
                        <div>{numeral(BasePrice).format(decimal)}</div>
                        <div>{this.removeCurrencySymbol(TotalSales, current)}</div>
                        <div>{this.removeCurrencySymbol(TotalBuyQuantity, decimal)}</div>
                      </div>
                      <OptionFields
                        IsPreviewMode
                        SizeRangeMapping={SizeRangeMapping}
                        data={optionItem}
                        formattingSettings={formattingSettings}
                        removeCurrencySymbol={this.removeCurrencySymbol.bind(this)}
                      />
                    </div>
                  </div>
                )
              }}
              scrollDirection="horizontal"
              width={width - 154}
            />
          </div>
        )}
        <AssortmentLoading isShown={isLoading} messages="Loading Options" />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    isLoading: state.options.isLoading,
    errorRootOptions: state.options.error,
    selectedScenarioClusterTree: state.treeSelection.clusterTree,
    formattingSettings: state.plugin.formattingSettings,
    Settings: state.optionSettings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    fetchOptions: bindActionCreators(getOptions, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssortmentOptions)
