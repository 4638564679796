Ext.define('MDM.src.view.Grouping.Edit.Main', {
  extend: 'Ext.window.Window',
  grpRecord: null,
  // Uncomment to give this component an xtype
  xtype: 'grouping-edit-main',
  controller: 'grouping-edit-maincontroller',
  viewModel: 'grouping-edit-mainviewmodel',
  modal: true,
  layout: 'fit',
  width: 1000,
  height: 600,
  maximizable: false,
  border: false,
  frame: false,
  shadow: false,
  closable: false,
  bodyBorder: false,
  bodyPadding: 2,
  defaults: {
    collapsible: true,
    split: true
  },
  bind: {
    title: 'Group Name : <span style="color:yellow">{GroupDescription}<span>'
  },
  items: [
    {
      title: 'DPI Hierarchy',
      region: 'west',
      floatable: true,
      margin: '5 0 5 5',
      width: 500,
      minWidth: 200,
      maxWidth: 450,
      xtype: 'grouping-edit-treepanel',
      bind: {
        store: '{DPITreeStore}'
      }
    },
    {
      collapsible: false,
      region: 'center',
      margin: '5 5 5 0',
      xtype: 'grouping-edit-gridpanel',
      bind: {
        store: '{GroupDetailsStore}',
        title: 'List of DPI Families : <span style="color:yellow"> {TotalRecCount}  </span>'
      }
    }
  ],
  dockedItems: [
    {
      xtype: 'toolbar',
      dock: 'bottom',
      items: [
        '->',
        {
          text: 'Save',
          handler: 'onSave',
          iconCls: 'fa fa-floppy-o',
          bind: {
            disabled: '{!isStoreDirty}'
          }
        },
        {
          text: 'Cancel',
          iconCls: 'fa fa-ban',
          handler: 'onClose'
        }
      ]
    }
  ]
})
