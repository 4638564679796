import {
  FETCH_MFP_DETAIL_DATA_START,
  STORE_MFP_DETAIL_DATA,
  STORE_MFP_DETAIL_DATA_FAILED
} from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetMfpDetailData: {},
  isLoading: true,
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MFP_DETAIL_DATA_START:
      return updateObject(state, {
        isLoading: true
      })
    case STORE_MFP_DETAIL_DATA:
      return updateObject(state, {
        GetMfpDetailData: { ...action.payload }
      })
    case STORE_MFP_DETAIL_DATA_FAILED:
      return updateObject(state, {
        GetMfpDetailData: {},
        error: { ...action.payload },
        isLoading: false
      })
    default:
      return state
  }
}

export default reducer
