import { GridState } from 'ag-grid-community'
import cx from 'classnames'
import {
  columnHeaderFontDifferences,
  columnHeaderMenuWidthObj,
  columnHeaderPaddingDifferences,
  defaultFieldConfig,
  emptyArray
} from '../../constants'

import { SlvyColDef, SlvyMasterColDef, TconfigColumn, TcustomConfigs } from '../../types'
import {
  getAggFunc,
  getCellClass,
  getCellClassRules,
  getCellEditable,
  getCellEditorSelector,
  getCellRendererSelector,
  getCellStyle,
  getColumnFilter,
  getCustomComparator,
  getEnableInfo,
  getExcelNumberFormatId,
  getFieldLookupConfig,
  getGroupProps,
  getHeaderClass,
  getHeaderTooltip,
  getMenuTabs,
  getNumberCellEditorParams,
  getTooltipComponent,
  getValueFormatter,
  getValueGetter
} from '../index'
import {
  ColumnDefsConfigs,
  FormatParams,
  GetActionButtonClassNamesProps,
  GetColumnHidden,
  GetColumnWithParams,
  GetDeleteIconClassNamesProps
} from './column.types'

const getColumnEditable = (customConfigs: TcustomConfigs, gridEditableByDefault: boolean) => {
  const { editing: { enabled } = {} } = customConfigs
  if (!enabled || !gridEditableByDefault) {
    return false
  }
  return true
}

function getActionButtonClassNames({
  selectedIcon,
  isDisabled,
  isClickable
}: GetActionButtonClassNamesProps) {
  // TODO
  return cx('fa', selectedIcon, {
    'opacity-25': isDisabled,
    cp: isClickable
  })
}

function getDeleteIconClassNames({ customInfo, data, deleteIcon }: GetDeleteIconClassNamesProps) {
  const isDeletableActive =
    customInfo?.deletableCondition && data
      ? getEnableInfo(data, customInfo?.deletableCondition)
      : true

  return cx('fa', deleteIcon, {
    'text-danger': isDeletableActive,
    'text-muted': !isDeletableActive,
    cp: isDeletableActive
  })
}

const isGraphColumn = (column: SlvyColDef): boolean => {
  return (
    column.customInfo.columnType === 'sparkline' || column.customInfo.columnType === 'highchart'
  )
}

const getColumnHidden = ({
  firstRowData,
  hideByColumnSettings,
  hiddenCondition,
  isMaximized,
  showInMaxMode,
  rowGroup,
  suppressRowGroupHidesColumns
}: GetColumnHidden) => {
  let hidden = hideByColumnSettings
  if (hiddenCondition && firstRowData) {
    hidden = Boolean(firstRowData[hiddenCondition])
  }
  if (!hidden) {
    hidden = showInMaxMode && !isMaximized
  }
  if (rowGroup && !suppressRowGroupHidesColumns) {
    hidden = true
  }
  return hidden
}

const getColumnWidth = ({ flex, width, theme }: GetColumnWithParams) => {
  /**
   * The flex config does not work with a width config in the same column.
   * If you need to provide a minimum width for a column, you should use flex and the minWidth config.
   * Flex will also take maxWidth into account.
   * */
  const conditionalProps: {
    maxWidth?: SlvyColDef['maxWidth']
    minWidth?: SlvyColDef['minWidth']
    width?: SlvyColDef['width']
  } = {}

  const menuWidth = columnHeaderMenuWidthObj[theme]
  const paddingDifference = columnHeaderPaddingDifferences[theme]
  const fontDifference = columnHeaderFontDifferences[theme]

  const calculatedWidth = width ? width + paddingDifference + menuWidth + fontDifference : 0

  if (flex) {
    conditionalProps.minWidth = calculatedWidth
  } else {
    conditionalProps.width = calculatedWidth

    // TODO: Check below is needed to make column invisible with, setting width: 0
    if (!calculatedWidth) {
      conditionalProps.minWidth = calculatedWidth
      conditionalProps.maxWidth = calculatedWidth
    }
  }

  return conditionalProps
}

function getMappedColumnDefs(
  configColumns: TconfigColumn[],
  configs: ColumnDefsConfigs
): SlvyColDef[] {
  const {
    theme,
    exportable,
    isPaginationEnabled,
    getCellColorsFromData,
    cellDisplayRules,
    editableFields,
    fieldConfigs,
    filtering,
    formattedFields,
    gridEditableByDefault,
    grouping,
    groupDisplayType,
    header,
    listFilterCheckbox,
    lockPinned,
    rowDisplayRules,
    getFormattedValue,
    handleActionBtnClick,
    formatValue,
    enableCellTextSelection
  } = configs

  const { align: headerAlign, alignment: headerAlignment, autoTooltip, multiLineHeader } = header

  return configColumns.map((column) => {
    const { customConfigs, field, ...restColumn } = column
    const { headerName, headerTooltip: configHeaderTooltip, flex } = restColumn
    const fieldConfig =
      fieldConfigs.find(({ fieldName }) => fieldName === column.field) ?? defaultFieldConfig
    const alignHeader = headerAlignment ? headerAlign : customConfigs.cellStyle.textAlign

    const formatParams: FormatParams = {
      columnConfig: {
        fieldName: column.field,
        columnType: customConfigs.columnType,
        formatField: customConfigs.formatField
      },
      fieldConfigs,
      getFormattedValue,
      formatValue,
      formattedFields,
      editableFields
    }

    const formatString =
      formattedFields.find(({ columnName }) => columnName === field)?.formatString ?? ''

    const cannotGroup =
      customConfigs.columnType === 'sparkline' || customConfigs.columnType === 'highchart'

    const groupParams = {
      gridGrouping: grouping,
      rowGroup: customConfigs.rowGroup,
      cannotGroup
    }

    const fieldLookupConfig = getFieldLookupConfig(field, editableFields)

    const { lookupDataField } = customConfigs.editing

    const autoHeight = customConfigs.columnType === 'html'

    return {
      autoHeaderHeight: multiLineHeader,
      autoHeight,
      // TODO: Read useHandleSort and getCellRendererSelector todos
      // Passing date as cellDataType fix the problem instead of commented out two solution
      cellDataType: fieldConfig?.dataType === 'datetime' ? 'date' : true, // TODO: Default is true, but ag-grid make it false when using getColumnDefs.
      customInfo: {
        // Random string, uuid or Math.random() does not work for export excelStyle!
        // If we have same column name(field) for more than one column, export excelStyle crashes!
        // Format that comes from BasePlugin can only set once to a field, so there is no problem
        // with having the same field name for more than one column. SKIP
        columnId: getExcelNumberFormatId(field),
        deletableCondition: '',
        isExportable: true,
        field,
        fieldConfig,
        ...customConfigs,
        fieldLookupConfig,
        groupHeader: customConfigs.groupHeader ?? '',
        formatString
      },
      field,
      lockPinned,
      wrapHeaderText: multiLineHeader,
      wrapText: autoHeight,
      ...restColumn,
      ...getMenuTabs({ columnType: customConfigs?.columnType, grouping }),
      ...getColumnWidth({ flex, width: customConfigs.width, theme }),
      ...getCellEditable({ customConfigs, gridEditableByDefault, fieldConfig }),
      ...getHeaderClass({ alignHeader }),
      ...getGroupProps(groupParams),
      ...getColumnFilter(
        filtering,
        customConfigs,
        fieldConfig,
        isPaginationEnabled,
        listFilterCheckbox
      ),
      ...getCellEditorSelector({
        booleanIcons: customConfigs.boolean,
        isLookupQuery: fieldLookupConfig.isLookupQuery,
        lookupQueryList: fieldLookupConfig.lookupQueryList,
        lookupDataField,
        dataType: fieldConfig.dataType
      }),
      ...getValueGetter({ columnType: customConfigs.columnType, fieldConfig }),
      ...getCellStyle({
        customConfigs,
        cellDisplayRules,
        rowDisplayRules,
        getCellColorsFromData,
        enableCellTextSelection
      }),
      ...getCellClass(exportable),
      ...getCellClassRules(),
      ...getValueFormatter({ fieldConfig, formatParams, groupDisplayType }),
      ...getNumberCellEditorParams({
        dataType: fieldConfig.dataType,
        editing: customConfigs.editing,
        formatString
      }),
      ...getHeaderTooltip({ autoTooltip, headerName, configHeaderTooltip }),
      ...getAggFunc({
        dataType: fieldConfig.dataType,
        columnType: customConfigs.columnType,
        aggFunc: customConfigs.aggFunc,
        customAggregation: customConfigs.customAggregation
      }),
      ...getCellRendererSelector({
        customConfigs,
        fieldConfig,
        cellDisplayRules,
        handleActionBtnClick,
        fieldLookupConfig
      }),
      ...getTooltipComponent(
        customConfigs,
        column.field,
        { getFormattedValue, formattedFields },
        cellDisplayRules
      ),
      ...getCustomComparator({ sortable: restColumn.sortable, dataType: fieldConfig.dataType }),
      headerName: customConfigs.headerName ?? headerName
    }
  })
}

const mapWithInitialState = (
  colDefs: SlvyMasterColDef[],
  gridApiColDefs: SlvyMasterColDef[],
  initialState: GridState | null,
  isInitialCreation: boolean
): SlvyMasterColDef[] => {
  if (!colDefs || !initialState) {
    return colDefs
  }

  let mappedColumns = colDefs

  const hiddenColIds = initialState?.columnVisibility?.hiddenColIds || []
  const groupColIds = initialState?.rowGroup?.groupColIds || []
  const leftColIds = initialState?.columnPinning?.leftColIds || emptyArray
  const rightColIds = initialState?.columnPinning?.rightColIds || emptyArray
  const columnSizingModel = initialState?.columnSizing?.columnSizingModel || []

  if (!isInitialCreation) {
    mappedColumns = colDefs.map((colDef) => {
      // get updated customInfo from state otherwise groupHeader does not get dynamic value
      // check below link Customer: group column
      // https://dragon.solvoyo.com/Configuration/catalog/64a7bf816b6afd3cd177cba0/store/15/menu/6596785e7560cf4bbc7df65d/page/66b1e64f162495c988c2beb1
      const { valueFormatter, rowGroup, ...restColDef } = colDef

      const gridApiColDef = gridApiColDefs?.find?.((col) => col.field === colDef.field) || {}

      // If we don't omit "row" and "rowGroupIndex", when we group a column that has `agSetFilterColumn`
      // then filter that column, then ungroup column, then Clear Filter for that column
      // In this scenario, column grouped automatically. So, below fixes it.
      // https://dragon-dev.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/1/menu/65c353047837bb86ff9e74f6/page/65c3531b7837bb86ff9e74f7
      return {
        ...restColDef,
        valueFormatter: gridApiColDef?.valueFormatter ?? valueFormatter
      }
    })
  }

  mappedColumns = mappedColumns.map((colDef) => {
    const newColDef = { ...colDef }

    let overrides: Partial<SlvyMasterColDef> = {}
    if (hiddenColIds.some((col) => col === newColDef.field)) {
      overrides.hide = true
    } else {
      // Scenario: column is hidden in configuration and the user makes it visible on the grid header
      if (newColDef.customInfo.hide && hiddenColIds.length) {
        overrides.hide = false
      }
    }

    if (groupColIds.some((col) => col === newColDef.field)) {
      overrides.rowGroup = true
    }

    if (leftColIds.some((col) => col === newColDef.field)) {
      overrides.pinned = 'left'
    }

    if (rightColIds.some((col) => col === newColDef.field)) {
      overrides.pinned = 'right'
    }

    const currentColumnSizingModel = columnSizingModel.find((col) => col.colId === newColDef.field)
    if (currentColumnSizingModel) {
      // ColumnSizeState has { colId, width?, flex? }
      overrides = { ...overrides, ...currentColumnSizingModel }
      if (typeof currentColumnSizingModel?.width === 'undefined') {
        delete newColDef?.width
      }
      // Delete flex to keep providing same width
      if (typeof currentColumnSizingModel?.flex === 'undefined') {
        delete newColDef?.flex
      }
    }

    return {
      ...newColDef,
      ...overrides
    }
  })

  return mappedColumns
}

export {
  isGraphColumn,
  getActionButtonClassNames,
  getColumnEditable,
  getColumnHidden,
  getColumnWidth,
  getDeleteIconClassNames,
  getMappedColumnDefs,
  mapWithInitialState
}
