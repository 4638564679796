import { PluginProps } from '@/BasePlugin/components/PluginProps.types'
import {
  selectDataConfigs,
  selectStylesConfigs,
  selectLayoutConfigs,
  selectAddConfigs
} from './schema-mappings'
import { IField } from '@/containers/EventMethod/components/Common.types'

const defaultFieldConfigs: IField[] = []

const selectPluginProps = (props: PluginProps) => {
  const settings = {
    ...props.settings,
    config: {
      ...props.settings.config,
      layout: selectLayoutConfigs(props.settings.config?.layout),
      styles: selectStylesConfigs(props.settings.config?.styles),
      data: selectDataConfigs(props.settings.config?.data),
      add: selectAddConfigs(props.settings.config?.add)
    }
  }

  const fieldConfigs = props.data.schema ?? props.settings.query.fields ?? defaultFieldConfigs
  const user = props?.userName ?? props?.preferredUsername ?? ''

  return {
    settings,
    fieldConfigs,
    user,
    actualFilters: props.actualFilters,
    id: props.id,
    clientWithProgress: props.clientWithProgress,
    pluginData: props.pluginData,
    getFormattedValue: props.getFormattedValue,
    registerEvent: props.registerEvent
  }
}

export default selectPluginProps
