export default `({
    height: 280,
    layout: 'fit',
    xtype: 'panel',
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b> '+ tt('Campaign Notes')+' </b>'
        }]
    }],
    style: {
        "padding": "1px",
        "background": "#fff"
    },
    padding: '0 0 0 0',
    items: [{
        xtype: 'htmleditor',
        labelAlign: 'top',
        anchor: '100%',
        bind: {
            value: '{spotRecord.Notes}'
        },
        margin: '0',
        // fieldLabel:'Note',
        afterLabelTextTpl: [
            '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
        ],
        allowBlank: false
    }]
})`
