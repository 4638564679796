/* eslint-disable jest/no-export */

export const dirtyCells = [
  {
    field: 'Customer',
    isSubstitute: false,
    newValueParams: { id: 'yyy', value: 'yyy' },
    oldValueParams: { id: 'AA35519', value: 'AA35519' },
    rowId: '111',
    unTouchedValueParams: { id: 'AA35519', value: 'AA35519' }
  },
  {
    field: 'Income',
    isSubstitute: false,
    newValueParams: { id: 9, value: 9 },
    oldValueParams: { id: null, value: null },
    rowId: '222',
    unTouchedValueParams: { id: null, value: null }
  }
]

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
