import * as _actions from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetUserScenario: {},
  isLoading: true,
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _actions.FETCH_USER_SCENARIO_START:
      return updateObject(state, {
        isLoading: true
      })
    case _actions.STORE_USER_SCENARIO:
      return updateObject(state, {
        GetUserScenario: { ...action.payload }
      })
    case _actions.STORE_USER_SCENARIO_FAILED:
      return updateObject(state, {
        GetUserScenario: {},
        error: { ...action.payload },
        isLoading: false
      })
    default:
      return state
  }
}

export default reducer
