import { isNil } from 'lodash'
import { TrowDisplayRules } from '../../types'
import { ValidateRuleProps } from './rule.types'

function validateRule({ conditionValue, value, operator }: ValidateRuleProps): boolean {
  let isValid = false
  if (isNil(value) || isNil(conditionValue)) {
    return isValid
  }
  switch (operator) {
    case 'equal':
      if (conditionValue.toString().toUpperCase() === value.toString().toUpperCase()) {
        isValid = true
      }
      break
    case 'not equal':
      if (conditionValue.toString().toUpperCase() !== value.toString().toUpperCase()) {
        isValid = true
      }
      break
    case 'larger':
      // Due to backward compatability cast value as string
      if (parseFloat(conditionValue) < parseFloat(value as string)) {
        isValid = true
      }
      break
    case 'smaller':
      // Due to backward compatability cast value as string
      if (parseFloat(conditionValue) > parseFloat(value as string)) {
        isValid = true
      }
      break
    default:
      break
  }

  return isValid
}

const getCustomColumnsRowDisplayRules = (rowDisplayRules: TrowDisplayRules) => {
  return rowDisplayRules
    .filter((displayRule: TrowDisplayRules[number]) => displayRule?.backColor)
    .map((displayRule: TrowDisplayRules[number]) => {
      const { compareToField, backColor, operator, ruleFieldName, value } = displayRule
      return {
        compareToField,
        backColor,
        operator,
        ruleFieldName,
        value
      }
    })
}

export { validateRule, getCustomColumnsRowDisplayRules }
