import React, { Component } from 'react'
import _ from 'lodash'
import { restoreScrollPos } from '../utils'

export default class IssueList extends Component {
  constructor(props) {
    super(props)

    this.legendHandlerProxy = this.legendHandlerProxy.bind(this)
  }

  componentDidMount() {
    const { scrollPos } = this.props

    restoreScrollPos(this.issueListRef, scrollPos)
  }

  componentDidUpdate(prevProps) {
    const { issueList, scrollPos } = this.props

    const isIssueListAvailable = !_.isEmpty(issueList) && _.isEmpty(prevProps.issueList)
    if (isIssueListAvailable) {
      restoreScrollPos(this.issueListRef, scrollPos)
    }
  }

  legendHandlerProxy(newState) {
    const { legendHandler, historyHandler } = this.props

    legendHandler(newState)

    historyHandler({
      key: 'IssueList',
      state: newState
    })
  }

  render() {
    const {
      issueList,
      infoList,
      legendState: { currentIssueIndex, currentProductInfoIndex, isProductInfo } = {},
      onScroll
    } = this.props
    const productStyle = isProductInfo ? { backgroundColor: 'grey' } : {}

    return (
      <div className="rccp-filter-legend">
        <div className="rccp-legend">
          <ul
            className="top-legend"
            ref={(element) => {
              if (element) this.issueListRef = element
            }}
            onScroll={onScroll}
          >
            {_.map(issueList, (issueItem, issueIndex) => {
              const { name, color } = issueItem
              const isActive = issueIndex === currentIssueIndex
              const activeStyle = isActive ? { backgroundColor: color } : {}

              return (
                <li key={issueIndex}>
                  <input
                    readOnly
                    checked={isActive}
                    className="search-by"
                    id={`legend_${issueIndex}`}
                    name="radio_list"
                    type="radio"
                  />
                  <label
                    htmlFor={`legend_${issueIndex}`}
                    onClick={(e) => {
                      this.legendHandlerProxy({
                        currentIssueIndex: issueIndex,
                        isProductInfo: false,
                        currentProductInfoIndex: 0
                      })
                    }}
                  >
                    <i className="-base">
                      <i className="-round" style={activeStyle} />
                    </i>
                    <span>{name}</span>
                  </label>
                </li>
              )
            })}

            <li>
              <input
                readOnly
                checked={isProductInfo}
                className="search-by"
                id="legend_detail"
                name="radio_list"
                type="radio"
              />
              <label
                htmlFor="legend_detail"
                onClick={(e) => {
                  this.legendHandlerProxy({
                    currentProductInfoIndex: 0,
                    currentIssueIndex: -1,
                    isProductInfo: !isProductInfo
                  })
                }}
              >
                <i className="-base">
                  <i className="-round" style={productStyle} />
                </i>
                <span>Values</span>
              </label>
            </li>
            {isProductInfo && (
              <li className="prod-i-wrp">
                <ul>
                  {_.map(infoList, (pListItem, pListIndex) => {
                    const { name } = pListItem
                    return (
                      <li key={pListIndex}>
                        <input
                          readOnly
                          checked={currentProductInfoIndex === pListIndex}
                          className="select-by"
                          id={`sub_selection_${pListIndex}`}
                          name="info_list"
                          type="radio"
                        />
                        <label
                          htmlFor={`sub_selection_${pListIndex}`}
                          onClick={(e) => {
                            this.legendHandlerProxy({
                              currentProductInfoIndex: pListIndex,
                              currentIssueIndex: -1,
                              isProductInfo: true
                            })
                          }}
                        >
                          <i />
                          {name}
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}
