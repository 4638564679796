Ext.define('MDM.src.view.globalapp.Panel.Dpi.Form.Main', {
  extend: 'Ext.window.Window',
  resizable: false,
  requires: [
    'Ext.layout.container.Fit',
    'Ext.layout.container.VBox',
    'Ext.layout.container.HBox',
    'Ext.form.Panel',
    'Ext.form.field.Text',
    'Ext.form.field.Date',
    'Ext.form.field.Time',
    'Ext.form.field.Checkbox',
    'Ext.calendar.form.CalendarPicker'
  ],
  viewModel: {
    data: {
      record: null,
      hideTrigger: true
    }
  },
  ui: 'window-inverse',
  iconCls: 'fa fa-pencil-square-o',
  layout: 'fit',
  modal: true,
  resize: false,
  closable: false,
  defaultListenerScope: true,
  height: 700,
  width: 800,
  bodyPadding: 4,
  config: {
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     *
     */
    formType: 'DpiSegment',
    formAction: 'upadete',
    dpiSegmentField: {
      xtype: 'combo',
      fieldLabel: 'DPI Segment',
      name: 'DPISegment',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'name',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var mainrecord = combo.up('window').getViewModel().get('record')
          if (mainrecord) mainrecord.set('DPISegmentID', record.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.DPISegment}',
        hideTrigger: '{hideTrigger}'
      }
    },
    productGroupField: {
      xtype: 'combo',
      fieldLabel: 'Product Group',
      name: 'ProductGroup',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'name',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var mainrecord = combo.up('window').getViewModel().get('record')
          if (mainrecord) mainrecord.set('ProductGroupID', record.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.ProductGroup}',
        hideTrigger: '{hideTrigger}'
      }
    },
    dpiSegmentSelectionField: {
      xtype: 'combo',
      fieldLabel: 'DPI Segment',
      name: 'DPISegmentSelection',
      itemId: 'DPISegmentSelection',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var me = combo.up('window'),
            field = me.down('#ProductGroupSelection')
          field.setValue('')
          var store = field.store
          store.clearFilter()
          store.filter('DPISegmentID', record.get('id'))
          var rec = store.first()
          field.setValue(rec.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.DPISegmentID}'
      }
    },
    productGroupSelectionField: {
      xtype: 'combo',
      fieldLabel: 'Product Group',
      itemId: 'ProductGroupSelection',
      name: 'ProductGroupSelection',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        beforequery: function (queryPlan, record) {
          var combo = queryPlan.combo,
            me = combo.up('window'),
            field = me.down('#DPISegmentSelection')
          var store = combo.store
          store.clearFilter()
          store.filter('DPISegmentID', field.getValue())
        }
      },
      bind: {
        store: '{store}',
        value: '{record.ProductGroupID}'
      }
    },
    dpiFamilyField: {
      xtype: 'combo',
      fieldLabel: 'DPI Family',
      name: 'DPIFamily',
      forceSelection: false,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'name',
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      allowBlank: false,
      listeners: {
        select: function (combo, record) {
          var mainrecord = combo.up('window').getViewModel().get('record')
          if (mainrecord) mainrecord.set('DPIFamilyID', record.get('id'))
        }
      },
      bind: {
        store: '{store}',
        value: '{record.DPIFamily}',
        hideTrigger: '{hideTrigger}'
      }
    },
    /**
     * @cfg {Object} titleField
     * The config for the title field.
     */
    suField: {
      xtype: 'numberfield',
      fieldLabel: 'SU',
      width: 200,
      name: 'SU',
      allowBlank: false,
      step: 0.1,
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      bind: {
        value: '{record.SU}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} fromContainer
     * The config for the from container.
     */
    latLongContainer: {
      xtype: 'fieldcontainer',
      fieldLabel: 'Lat/Long',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    /**
     * @cfg {Object} startDateField
     * The config for the start date field.
     */
    startDateField: {
      xtype: 'combo',
      itemId: 'startDate',
      name: 'startDate',
      allowBlank: false,
      bind: {
        value: '{record.startDate}',
        maxValue: '{record.EndDate}',
        readOnly: '{readOnlyMode}'
      }
    },
    /**
     * @cfg {Object} startTimeField
     * The config for the start time field.
     */
    /**
     * @cfg {Object} toContainer
     * The config for the to container.
     */
    /**
     * @cfg {Object} endDateField
     * The config for the end date field.
     */
    /**
     * @cfg {Object} endTimeField
     * The config for the end time field.
     */
    /**
     * @cfg {Object} allDayField
     * The config for the all day field.
     */
    /**
     * @cfg {Object} descriptionField
     * The config for the description field.
     */
    /**
     * @cfg {Object} dropButton
     * The config for the drop button. `null` to not show this button.
     */
    dropButton: {
      text: 'Delete',
      handler: 'onDropTap'
    },
    /**
     * @cfg {Object} saveButton
     * The config for the save button.
     */
    saveButton: {
      text: 'Save',
      handler: 'onSaveTap',
      iconCls: 'fa fa-floppy-o',
      bind: {
        hidden: '{readOnlyMode}',
        disabled: '{!record.dirty}'
      }
    },
    /**
     * @cfg {Object} cancelButton
     * The config for the cancel button.
     */
    cancelButton: {
      text: 'Close',
      handler: 'onCancelTap'
    }
  },
  initForm: function () {
    this.createItems()
  },
  initComponent: function () {
    var me = this,
      action = this.getFormAction()
    if (action == 'create') {
      me.getViewModel().set('hideTrigger', false)
    }
    this.getViewModel().set('record', this.record)
    me.initForm()
    me.fbar = me.generateButtons()
    me.callParent()
    me.form = me.items.first()
    me.checkFields()
    me.applyValues()
  },
  generateButtons: function () {
    var buttons = [],
      drop = this.getDropButton()
    if (drop) {
    }
    buttons.push(
      {
        xtype: 'component',
        flex: 1
      },
      this.getSaveButton(),
      this.getCancelButton()
    )
    return buttons
  },
  applyValues: function () {},
  createItems: function () {
    var me = this,
      items = []
    if (me.getFormType() == 'DpiSegment') {
      items.push(me.getDpiSegmentField())
    } else if (me.getFormType() == 'ProductGroup') {
      items.push(me.getDpiSegmentSelectionField())
      items.push(me.getProductGroupField())
    } else if (me.getFormType() == 'DPIFamily') {
      items.push(me.getDpiSegmentSelectionField())
      items.push(me.getProductGroupSelectionField())
      items.push(me.getDpiFamilyField())
      items.push(me.getSuField())
    }
    this.items = [
      {
        xtype: 'form',
        border: false,
        trackResetOnLoad: true,
        layout: {
          type: 'vbox',
          align: 'stretch'
        },
        bodyPadding: 10,
        items: items
      }
    ]
  },
  privates: {
    checkFields: function () {},
    onAllDayChange: function () {
      this.checkFields()
    },
    onCancelTap: function () {
      this.getViewModel().get('record').reject()
      this.close()
    },
    onDropTap: function () {
      this.fireDrop()
    },
    onSaveTap: function () {
      this.fireEvent('save', this)
      this.close()
    },
    getDpiSegmentField: function () {
      var c = this.dpiSegmentField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getDpiSegmentSelectionField: function () {
      var c = this.dpiSegmentSelectionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getProductGroupField: function () {
      var c = this.productGroupField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getProductGroupSelectionField: function () {
      var c = this.productGroupSelectionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getDpiFamilyField: function () {
      var c = this.dpiFamilyField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getStore: function (c) {
      return Ext.create('Ext.data.Store', {
        field: [
          {
            name: 'name'
          },
          {
            name: 'id'
          }
        ],
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_DPI_FromCombo',
          extraParams: {
            fieldType: c
          },
          reader: {
            type: 'json',
            rootProperty: 'users'
          }
        },
        autoLoad: true
      })
    }
  }
})
