export default {
  title: 'Clustering',
  subTitle: 'Clustering Planning',
  version: '1.0',
  namespace: 'Clustering',
  description: '',
  iconClass: 'fa-briefcase ',
  color: 'yellow-bg',
  support: {
    maximizable: true
  },
  group: 'Application'
}
