import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import _ from 'lodash'
import { ScheduleAddForm, CoverBox } from '../../../components'
import { round, format, increase, val, restoreScrollPos } from '../../../utils'

class UpdateFormResource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUserAllowedToAddProduction: true
    }

    this.popoverClickRootClose = this.popoverClickRootClose.bind(this)
  }

  componentDidMount() {
    const { isAllowed = () => {}, scrollPos, resource } = this.props

    const isUserAllowedToAddProduction = isAllowed('Resource/Add Production')
    this.setState({ isUserAllowedToAddProduction }, () => {
      if (this.detailsTableRef && !_.isEmpty(scrollPos[resource.Id])) {
        restoreScrollPos(this.detailsTableRef, scrollPos[resource.Id])
      }
    })
  }

  createNoAggregationRequestItem(Values = []) {
    return {
      Name: 'No Aggregation',
      Operator: '=',
      Values
    }
  }

  popoverClickRootClose(data) {
    const {
      connectApi,
      lastRequest,
      settings,
      getRccpResourcePeriodResponse,
      coverViolationIndexes
    } = this.props

    return (
      <Popover id="popover-positioned-gantt-form-add">
        <ScheduleAddForm
          connectApi={connectApi}
          coverViolationIndexes={coverViolationIndexes}
          data={data}
          getData={null}
          getRccpResourcePeriodResponse={getRccpResourcePeriodResponse}
          lastRequest={lastRequest}
          settings={settings}
        />
      </Popover>
    )
  }

  addProductHandler(params, e) {
    e.stopPropagation()

    const { connectApi, period = params.week } = this.props

    params.week = period.Name
    connectApi('GetRccpProductsResponse', params)
  }

  render() {
    let {
      data,
      data: {
        Period: {
          Id: PeriodId = 0,
          Name: PeriodName = '',
          DisplayName: periodDisplayName = '',
          StartTime: PeriodStartTime = null,
          EndTime: PeriodEndTime = null
        } = {},
        Product: { Id: ProductId = 0, Name: ProductName = '', SKU = 0 } = {},
        Products = [],
        ProductPeriod,
        ProductPeriod: { NetRequirement = 0, Production: Planned = 0, Values = {} } = {},
        ResourcePeriodProduct = {},
        ResourcePeriod: {
          ResourcePeriodProducts = [],
          ResourcePeriodProductCount = 0,
          NumberOfAvailableShifts,
          NumberOfEnabledShifts
        } = {},
        Resource = {}
      } = {},
      getRccpProductsResponse = {},
      viewHandler,
      connectApi,
      settings: {
        coverSettings = {},
        appSettings: {
          resourceScheduleView: { isEnabled: isResourceScheduleViewEnabled = true } = {},
          shiftCalendarView: { isEnabled: isShiftCalendarViewEnabled = true } = {}
        } = {},
        modificationCount,
        stateHandler: {
          selectedParameter: { Index: SelectedParamIndex = 0, name: Abbreviation = '' }
        },
        resourcePlanSettings = [],
        productInfoSettings = {}
      } = {},
      lastRequest: {
        GetRccpProductPeriodResponse: { Request = {} } = {},
        GetRccpResourcePeriodHierarchyResponseWPF: { Request: hierarchyRequest } = {}
      } = {},
      isActive = true,
      period,
      resource: { Id: ResourceId },
      configSettings: {
        resourceAndProductPlanSettings: { shiftCalendarButtonFormat = '0,0' } = {}
      } = {},
      coverViolationIndexes,
      onScroll
    } = this.props
    const { isUserAllowedToAddProduction = true } = this.state

    Request = _.isEmpty(Request) ? hierarchyRequest : Request

    const productInfoCoverSettings = _.find(productInfoSettings, ({ tableName }) =>
      _.includes(tableName, 'Cover')
    )
    const { format: formatType = '0,0' } = productInfoCoverSettings || {}
    coverSettings = { ...coverSettings, formatType }

    NetRequirement = val(Values, 'NetRequirement', SelectedParamIndex)
    Planned = val(Values, 'Production', SelectedParamIndex)

    const max = round(Math.max(NetRequirement, Planned))
    const calcPlannedValue = Planned > 0 && max > 0 ? round((Planned * 100) / max) : 0
    const calcNetRequirement =
      NetRequirement > 0 && max > 0 ? round((NetRequirement * 100) / max) : 0
    const params = {
      week: PeriodName,
      period,
      resourceId: Resource.Id || ResourceId,
      rccpConfig: {
        ModificationCount: modificationCount
      }
    }
    const AddButton = () => {
      return (
        <OverlayTrigger
          rootClose
          overlay={this.popoverClickRootClose({ ...getRccpProductsResponse, ...params }, false)}
          placement="right"
          // onEntered={this.popoverState}
          // onExited={this.popoverState}
          trigger="click"
        >
          <button
            className="-slvy-btn -approve -xsmall"
            disabled={!isUserAllowedToAddProduction}
            onClick={this.addProductHandler.bind(this, params)}
          >
            <i className="slvy-ui-icon-plus-lt" />
            Production
          </button>
        </OverlayTrigger>
      )
    }

    return !_.isEmpty(data) ? (
      <div className="-box">
        <div className="-box-row">
          <h3>{periodDisplayName || PeriodName}</h3>

          {isShiftCalendarViewEnabled && (
            <b
              className="-slvy-btn -neutral -xsmall"
              title="Shift Calendar"
              onClick={(e) => {
                connectApi('GetRccpResourceLineResponse', {
                  periodId: PeriodId,
                  resourceId: ResourceId,
                  rccpConfig: {
                    ModificationCount: modificationCount
                  }
                  // Todo Weekly
                }).then((res) => {
                  viewHandler('scheduleLineView', 'pushPage')
                })
              }}
            >
              <i className="slvy-ui-icon-export-box" />
              {format(NumberOfEnabledShifts, shiftCalendarButtonFormat)} /{' '}
              {format(NumberOfAvailableShifts, shiftCalendarButtonFormat)}
            </b>
          )}
        </div>

        <div className="-box-row -r-row">
          <label>Num. of products</label>

          <b
            className="-slvy-btn -neutral -xsmall"
            onClick={() => {
              Request.productFilter = this.createNoAggregationRequestItem(
                _.map(ResourcePeriodProducts, (item) =>
                  (Products[item.ProductIndex].Id || 0).toString()
                )
              )
              Request.resourceFilter = this.createNoAggregationRequestItem([Resource.Id.toString()])

              Request.info = {
                PeriodName,
                Resource
              }

              connectApi('GetRccpProductPeriodResponse', Request, 'RccpFilteredProduct').then(
                (res) => {
                  viewHandler('filteredProduct', 'pushPage')
                }
              )
            }}
          >
            <i className="slvy-ui-icon-export-box" />
            {ResourcePeriodProductCount}
          </b>
        </div>

        <div className="-box-row">
          <h3 style={{ minHeight: 55 }}>
            {ProductName}
            <small>{SKU}</small>
          </h3>
        </div>

        <div className="-box-row -b-chart">
          <label>Planned vs Requirement</label>
          <ul className="-common-list">
            <li style={{ width: `${increase(calcPlannedValue)}%` }}>{format(Planned)}</li>
            <li style={{ width: `${increase(calcNetRequirement)}%` }}>{format(NetRequirement)}</li>
          </ul>
        </div>

        <div
          className="-box-row-ctn"
          ref={(element) => {
            if (element) {
              this.detailsTableRef = element
            }
          }}
          onScroll={onScroll}
        >
          {_.map(
            resourcePlanSettings,
            ({ name, tableName, matchFieldName, format: formatType }, index) => {
              const { Values: { [tableName]: resourcePeriodProductArrayValue = [] } = {} } =
                ResourcePeriodProduct
              const { Values: { [tableName]: productPeriodArrayValue = [] } = {} } = ProductPeriod

              const resourcePeriodProductValue = matchFieldName
                ? resourcePeriodProductArrayValue[SelectedParamIndex]
                : ResourcePeriodProduct[tableName]
              const productPeriodValue = matchFieldName
                ? productPeriodArrayValue[SelectedParamIndex]
                : ProductPeriod[tableName]

              return ResourcePeriodProduct[tableName] ? (
                <div key={`${tableName}-${index}`} className="-box-row">
                  <label>
                    <span>{`${name} ${matchFieldName ? `[${Abbreviation}]` : ''}`}</span>
                  </label>
                  <b>
                    {typeof resourcePeriodProductValue === 'number'
                      ? format(resourcePeriodProductValue, formatType)
                      : resourcePeriodProductValue}
                  </b>
                </div>
              ) : (
                <div key={`${tableName}-${index + 1}`} className="-box-row">
                  <label>
                    <span>{`${name} ${matchFieldName ? `[${Abbreviation}]` : ''}`}</span>
                  </label>
                  <b>
                    {typeof productPeriodValue === 'number'
                      ? format(productPeriodValue, formatType)
                      : productPeriodValue}
                  </b>
                </div>
              )
            }
          )}
        </div>

        <div className="-box-row -b-chart">
          <CoverBox
            coverSettings={coverSettings}
            coverViolationIndexes={coverViolationIndexes}
            data={ProductPeriod}
          />
        </div>

        <div className="-btn-group">
          {AddButton()}

          {isResourceScheduleViewEnabled && (
            <button
              className="-slvy-btn -xsmall get-resource-line"
              type="button"
              onClick={() => {
                const req = {
                  productId: ProductId,
                  periodId: PeriodId,
                  rccpConfig: {
                    StartDate: PeriodStartTime,
                    EndDate: PeriodEndTime,
                    ModificationCount: modificationCount
                  }
                }

                connectApi('GetRccpResourceActivityResponseWPF', req).then((res) => {
                  viewHandler('scheduleResourceView', 'pushPage')
                })
              }}
            >
              <i className="slvy-ui-icon-sorted" />
              Resource Schedule
            </button>
          )}
        </div>
      </div>
    ) : (
      <div className="-box-empty">
        <div>
          <b>Select a product</b>
          <i className="slvy-ui-icon-angle-right-lt" />
        </div>
        <div>{AddButton()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  configSettings: state.resourcePlan.configSettings,
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes
})

export default connect(mapStateToProps)(UpdateFormResource)
