import React from 'react'
import { IProps } from './Title.types'

const Title = ({
  name,
  isWithGroupedIconVisible,
  isWithBaseIconVisible,
  children,
  slotForChildren
}: IProps) => {
  return (
    <div className="-title">
      {slotForChildren === 'left' ? children : null}
      <div className="rowTitle">{name}</div>
      {isWithGroupedIconVisible ? (
        <span className="slvy-ui-icon-group-down -icon -toGroup" />
      ) : null}
      {isWithBaseIconVisible ? <div className="baselineIndicator">B</div> : null}
      {slotForChildren === 'right' ? children : null}
    </div>
  )
}

export default Title
