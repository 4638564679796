import { useCallback } from 'react'
import { handleAsyncAwait } from '@/helpers'
import { shouldRefreshData, mapFilters } from '../../helpers/index'
import axiosWithDefaults from '../../axiosWithDefaults'
import { HandleAsyncAwaitReturnType } from '../../types'
import {
  HandleUpdate,
  HandleUpdateProps,
  HandleUpdateReturnType,
  UpdateRequestPayload
} from './useHandleUpdate.types'

function useHandleUpdate(props: HandleUpdateProps) {
  const { additionalArgs, AG_GRID_URL, gridRef, resetCustomRowInfo } = props

  const handleUpdate = useCallback(
    async ({
      dirtyColumns,
      doesDirectUpdate,
      updateParameters
    }: HandleUpdate): Promise<HandleUpdateReturnType | undefined> => {
      if (!dirtyColumns.length) {
        return undefined
      }

      const context = gridRef?.current?.api?.getGridOption('context') || {}

      const requestPayload: UpdateRequestPayload = {
        updateItems: dirtyColumns,
        updatehints: {},
        filters: {
          ...mapFilters(gridRef),
          ...context?.actualFilters,
          ...updateParameters,
          ...additionalArgs
        }
      }

      const request = axiosWithDefaults({
        url: `${AG_GRID_URL}/update`,
        method: 'POST',
        data: requestPayload
      })

      const [data, error] = (await handleAsyncAwait(request)) as HandleAsyncAwaitReturnType

      const { shouldRefresh, shouldReset } = shouldRefreshData({
        name: 'update',
        doesDirectUpdate,
        error
      })

      if (shouldReset) {
        resetCustomRowInfo()
      }

      // P.S.: This type should be given by src/helpers
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return { update: { data, error: error?.data?.result ?? [], shouldRefresh } }
    },
    [AG_GRID_URL, additionalArgs, gridRef, resetCustomRowInfo]
  )

  return { handleUpdate }
}

export default useHandleUpdate
