Ext.define('tuiv2.cmp.TreeCombo', {
  extend: 'tuiv2.cmp.UxTreePicker',
  xtype: 'tuiv2-treeCombo',
  setDefaultValue: function (c) {
    var me = this
    if (!c) return c
    if (me.store.loading) {
      Ext.defer(function () {
        me.setDefaultValue(c)
      }, 100)
      return c
    }
    me.changeRecord(c.fieldName, c.value)
    return c
  }
})
