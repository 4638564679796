import cx from 'classnames'
import { iconFontSizeClass } from '../../../constants'
import { getDeleteIconClassNames } from '../../../helpers/column/column'
import DeleteColumnCellRendererProps from './DeleteColumnCellRenderer.types'

const DeleteColumnCellRenderer = (props: DeleteColumnCellRendererProps) => {
  const {
    colDef: { customInfo },
    data,
    icon: { icon: deleteIcon = '' },
    node: { group },
    context
  } = props

  if (group) {
    return null
  }

  const classNames = getDeleteIconClassNames({ customInfo, data, deleteIcon })
  return <i className={cx('slvy-i-element', classNames, iconFontSizeClass[context.theme])} />
}

export default DeleteColumnCellRenderer
