import { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { isEmpty, isEqual } from 'lodash'

import {
  getEnvironments,
  getCatalogs,
  getMenus,
  getCatalogConnections,
  getPageConnections,
  copyPageToEnvironment
} from '@/actions/page'
import { mapFromCrudList } from '@/helpers/mapper'

import { WizardPopover } from '../../../../../components'
import DestinationSelect from './DestinationSelect'
import ConnectionMapping from './ConnectionMapping'
import ErrorView from './ErrorView'
import SuccessView from './SuccessView'

import './index.scss'

class CopyToEnv extends Component {
  constructor() {
    super()

    this.state = {
      showPopover: false,
      views: {}
    }

    this.mapCallback1 = ({ name }) => ({ label: name, value: name })
    this.mapCallback2 = ({ name, id }) => ({ label: name, value: id })
    this.mapCallback3 = ([key, value]) => ({ label: key, value })

    this.handleTogglePopover = this.handleTogglePopover.bind(this)
    this.handleClosePopover = this.handleClosePopover.bind(this)
    this.fetchEnvironments = this.fetchEnvironments.bind(this)
    this.handleSelectEnvironment = this.handleSelectEnvironment.bind(this)
    this.fetchCatalogs = this.fetchCatalogs.bind(this)
    this.handleSelectCatalog = this.handleSelectCatalog.bind(this)
    this.fetchMenus = this.fetchMenus.bind(this)
    this.handleSelectMenu = this.handleSelectMenu.bind(this)
    this.fetchConnections = this.fetchConnections.bind(this)
    this.handleSelectConnections = this.handleSelectConnections.bind(this)
    this.handleCopyToEnv = this.handleCopyToEnv.bind(this)
  }

  componentDidUpdate(_, prevState) {
    const { showPopover } = this.state
    const { showPopover: prevShowPopover } = prevState

    const popoverOpened = showPopover && !prevShowPopover
    if (popoverOpened) {
      this.setState((prevState) => ({
        ...prevState,
        views: {
          environment: {
            component: DestinationSelect,
            data: [],
            isLoading: true,
            mapData: (data) => mapFromCrudList(data, this.mapCallback1),
            selectValue: null,
            title: 'Select environment',
            wizardProps: {
              controls: {
                nextBtn: {
                  disabled: true
                }
              }
            },
            onMount: this.fetchEnvironments,
            onSelect: this.handleSelectEnvironment
          },
          catalog: {
            component: DestinationSelect,
            data: [],
            isLoading: true,
            mapData: (data) => mapFromCrudList(data, this.mapCallback2),
            selectValue: null,
            title: 'Select catalog',
            wizardProps: {
              controls: {
                nextBtn: {
                  disabled: true
                }
              }
            },
            onMount: this.fetchCatalogs,
            onSelect: this.handleSelectCatalog
          },
          menu: {
            component: DestinationSelect,
            data: [],
            isLoading: true,
            mapData: (data) => mapFromCrudList(data, this.mapCallback2),
            selectValue: null,
            title: 'Select menu',
            wizardProps: {
              controls: {
                nextBtn: {
                  disabled: true
                }
              }
            },
            onMount: this.fetchMenus,
            onSelect: this.handleSelectMenu
          },
          mapping: {
            component: ConnectionMapping,
            data: {
              catalogConnections: [],
              pageConnections: []
            },
            isLoading: {
              catalogConnections: true,
              pageConnections: true
            },
            mapData: (data) => mapFromCrudList(Object.entries(data), this.mapCallback3),
            selectValue: [],
            title: 'Map connections',
            wizardProps: {
              controls: {
                nextBtn: {
                  disabled: true,
                  text: 'copy',
                  variant: 'success'
                }
              }
            },
            onMount: this.fetchConnections,
            onSelect: this.handleSelectConnections
          },
          success: {
            component: SuccessView,
            isLoading: true,
            wizardProps: {
              controls: {
                backBtn: {
                  show: false
                },
                nextBtn: {
                  show: false
                }
              }
            },
            onMount: this.handleCopyToEnv
          }
        }
      }))
    }

    const popoverClosed = !showPopover && prevShowPopover
    if (popoverClosed) {
      this.setState({ views: {} })
    }
  }

  handleTogglePopover() {
    this.setState((prevState) => ({ showPopover: !prevState.showPopover }))
  }

  handleClosePopover() {
    this.setState({ showPopover: false })
  }

  fetchEnvironments() {
    const { getEnvironments } = this.props
    const {
      views: { environment }
    } = this.state

    if (!isEmpty(environment.data)) return

    getEnvironments()
      .then((environments) => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            environment: {
              ...prevState.views.environment,
              data: environments,
              isLoading: false
            }
          }
        }))
      })
      .catch(() => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            environment: {
              ...prevState.views.environment,
              component: ErrorView,
              data: null,
              isLoading: false,
              message: 'Failed to fetch environments!'
            }
          }
        }))
      })
  }

  handleSelectEnvironment(value) {
    this.setState((prevState) => {
      const {
        views,
        views: { environment, catalog, menu, mapping }
      } = prevState

      const destinationChanged =
        !isEmpty(environment.selectValue) && !isEqual(environment.selectValue, value)

      return {
        views: {
          ...views,
          ...(destinationChanged && {
            catalog: {
              ...catalog,
              component: DestinationSelect,
              data: [],
              isLoading: true,
              selectValue: null,
              wizardProps: {
                ...catalog.wizardProps,
                controls: {
                  nextBtn: { disabled: true }
                }
              }
            },
            menu: {
              ...menu,
              component: DestinationSelect,
              data: [],
              isLoading: true,
              selectValue: null,
              wizardProps: {
                ...menu.wizardProps,
                controls: {
                  nextBtn: { disabled: true }
                }
              }
            },
            mapping: {
              ...mapping,
              component: ConnectionMapping,
              data: {
                catalogConnections: [],
                pageConnections: []
              },
              isLoading: {
                catalogConnections: {},
                pageConnections: {}
              },
              selectValue: this.resetMappingSelect(mapping.selectValue),
              wizardProps: {
                ...mapping.wizardProps,
                controls: {
                  nextBtn: { ...mapping.wizardProps.controls.nextBtn, disabled: true }
                }
              }
            }
          }),
          environment: {
            ...environment,
            selectValue: value,
            wizardProps: {
              ...environment.wizardProps,
              controls: {
                nextBtn: { disabled: false }
              }
            }
          }
        }
      }
    })
  }

  fetchCatalogs() {
    const { getCatalogs } = this.props
    const {
      views: {
        environment: { selectValue },
        catalog
      }
    } = this.state

    if (!isEmpty(catalog.data)) return

    getCatalogs(selectValue.value)
      .then((environments) => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            catalog: {
              ...prevState.views.catalog,
              data: environments,
              isLoading: false
            }
          }
        }))
      })
      .catch(() => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            catalog: {
              ...prevState.views.catalog,
              component: ErrorView,
              data: null,
              isLoading: false,
              message: 'Failed to fetch catalogs!'
            }
          }
        }))
      })
  }

  handleSelectCatalog(value) {
    this.setState((prevState) => {
      const {
        views,
        views: { catalog, menu, mapping }
      } = prevState

      const destinationChanged =
        !isEmpty(catalog.selectValue) && !isEqual(catalog.selectValue, value)

      return {
        views: {
          ...views,
          ...(destinationChanged && {
            menu: {
              ...menu,
              component: DestinationSelect,
              data: [],
              isLoading: true,
              selectValue: null,
              wizardProps: {
                ...menu.wizardProps,
                controls: {
                  nextBtn: { disabled: true }
                }
              }
            },
            mapping: {
              ...mapping,
              component: ConnectionMapping,
              data: {
                catalogConnections: [],
                pageConnections: []
              },
              isLoading: {
                catalogConnections: {},
                pageConnections: {}
              },
              selectValue: this.resetMappingSelect(mapping.selectValue),
              wizardProps: {
                ...mapping.wizardProps,
                controls: {
                  nextBtn: { ...mapping.wizardProps.controls.nextBtn, disabled: true }
                }
              }
            }
          }),
          catalog: {
            ...catalog,
            selectValue: value,
            wizardProps: {
              ...catalog.wizardProps,
              controls: {
                nextBtn: { disabled: false }
              }
            }
          }
        }
      }
    })
  }

  fetchMenus() {
    const { getMenus } = this.props
    const {
      views: { environment, catalog, menu }
    } = this.state

    if (!isEmpty(menu.data)) return

    getMenus(environment.selectValue.value, catalog.selectValue.value)
      .then((menu) => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            menu: {
              ...prevState.views.menu,
              data: menu,
              isLoading: false
            }
          }
        }))
      })
      .catch(() => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            menu: {
              ...prevState.views.menu,
              component: ErrorView,
              data: null,
              isLoading: false,
              message: 'Failed to fetch menus!'
            }
          }
        }))
      })
  }

  handleSelectMenu(value) {
    this.setState((prevState) => {
      const {
        views,
        views: { menu, mapping }
      } = prevState

      const destinationChanged = !isEmpty(menu.selectValue) && !isEqual(menu.selectValue, value)

      return {
        views: {
          ...views,
          ...(destinationChanged && {
            mapping: {
              ...mapping,
              component: ConnectionMapping,
              data: {
                catalogConnections: [],
                pageConnections: []
              },
              isLoading: {
                catalogConnections: {},
                pageConnections: {}
              },
              selectValue: this.resetMappingSelect(mapping.selectValue),
              wizardProps: {
                ...mapping.wizardProps,
                controls: {
                  nextBtn: { ...mapping.wizardProps.controls.nextBtn, disabled: true }
                }
              }
            }
          }),
          menu: {
            ...menu,
            selectValue: value,
            wizardProps: {
              ...menu.wizardProps,
              controls: {
                nextBtn: { disabled: false }
              }
            }
          }
        }
      }
    })
  }

  fetchConnections() {
    const {
      params: { catalogId, pageId },
      getCatalogConnections,
      getPageConnections
    } = this.props
    const {
      views: { environment, catalog, mapping }
    } = this.state

    if (!isEmpty(mapping.data.catalogConnections) && !isEmpty(mapping.data.pageConnections)) return

    Promise.all([
      getCatalogConnections(environment.selectValue.value, catalog.selectValue.value),
      getPageConnections(catalogId, pageId)
    ])
      .then(([catalogConnections, pageConnections]) => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            mapping: {
              ...prevState.views.mapping,
              data: { catalogConnections, pageConnections },
              isLoading: { catalogConnections: false, pageConnections: false },
              selectValue: Array(Object.keys(pageConnections).length).fill(null)
            }
          }
        }))
      })
      .catch(() => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            mapping: {
              ...prevState.views.mapping,
              component: ErrorView,
              data: {
                catalogConnections: [],
                pageConnections: []
              },
              isLoading: { catalogConnections: false, pageConnections: false },
              message: 'Failed to fetch connections!'
            }
          }
        }))
      })
  }

  handleSelectConnections(value, index) {
    this.setState((prevState) => {
      let {
        views: {
          mapping,
          mapping: { selectValue }
        }
      } = prevState

      selectValue = selectValue.map((prevValue, i) => (i === index ? value : prevValue))
      const disabled = selectValue.includes(null)

      return {
        views: {
          ...prevState.views,
          mapping: {
            ...mapping,
            selectValue,
            wizardProps: {
              ...mapping.wizardProps,
              controls: {
                nextBtn: {
                  ...mapping.wizardProps.controls.nextBtn,
                  disabled
                }
              }
            }
          }
        }
      }
    })
  }

  handleCopyToEnv() {
    const {
      params: { pageId },
      copyPageToEnvironment
    } = this.props
    const {
      views: { environment, catalog, menu, mapping }
    } = this.state

    const connectionMapping = Object.entries(mapping.data.pageConnections).reduce(
      (acc, [_, value], i) => ({
        ...acc,
        [value]: mapping.selectValue[i].value
      }),
      {}
    )

    copyPageToEnvironment(
      environment.selectValue.value,
      pageId,
      catalog.selectValue.value,
      menu.selectValue.value,
      connectionMapping
    )
      .then(() => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            success: {
              ...prevState.views.success,
              isLoading: false
            }
          }
        }))
      })
      .catch(() => {
        this.setState((prevState) => ({
          views: {
            ...prevState.views,
            success: {
              ...prevState.views.success,
              component: ErrorView,
              isLoading: false,
              message: 'Failed to copy page!'
            }
          }
        }))
      })
  }

  resetMappingSelect(selectValue) {
    return selectValue.map(() => null)
  }

  render() {
    const { container } = this.props
    const { showPopover, views } = this.state

    return (
      <WizardPopover
        rootClose
        className="copy-to-env"
        placement="top"
        show={showPopover}
        trigger="click"
        wizardViews={views}
        onClose={this.handleClosePopover}
      >
        <Button className="w-25 border" variant="btn" onClick={this.handleTogglePopover}>
          Copy To
        </Button>
      </WizardPopover>
    )
  }
}

export default connect(null, {
  getEnvironments,
  getCatalogs,
  getMenus,
  getCatalogConnections,
  getPageConnections,
  copyPageToEnvironment
})(CopyToEnv)
