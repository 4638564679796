import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Config } from '../../Tui.types'

type ViewContainerState = {
  currentView: Config['settings']['defaultView'] | undefined
  currentViewIcon: string | undefined
}

const initialState: ViewContainerState = {
  currentView: undefined,
  currentViewIcon: undefined
}

const viewContainerSlice = createSlice({
  name: 'viewContainer',
  initialState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<ViewContainerState['currentView']>) => {
      state.currentView = action.payload
    },
    setCurrentViewIcon: (state, action: PayloadAction<ViewContainerState['currentViewIcon']>) => {
      state.currentViewIcon = action.payload
    }
  }
})

export default viewContainerSlice
export type { ViewContainerState }
