import { Component, MouseEvent } from 'react'
import { connect } from 'react-redux'
import { Nav, Tab, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { slvyToast } from '@/components'

import Assignees from './components/Assignees'
import WorkFlow from './components/Workflow'
import toastrTypes from '../../helpers/ToastrTypes'
import {
  DoFetchParams,
  EventMethodProps,
  EventMethodState,
  HandleAssigneesChangeParams
} from './EventMethod.types'

import { select, selectCollection, isSuccess, selecOperationStatus } from '@/crudoptV3'
import { getMenus } from '@/actions/menu'
import { getCatalog } from '@/actions/catalog'
import { getPage, updatePage } from '@/actions/page'
import { getPlugins } from '@/actions/plugin'
import { getContainers } from '@/actions/container'
import { emptyArray, emptyObject } from './constants'

import './EventMethod.scss'

const catalogData = { transferObject: emptyObject, remoteActions: emptyArray }

class EventMethod extends Component<EventMethodProps, EventMethodState> {
  constructor(props: EventMethodProps) {
    super(props)
    this.state = {
      workflow: [],
      relations: {}
    }

    this.handleAssigneesChange = this.handleAssigneesChange.bind(this)
    this.handleWorkflowChange = this.handleWorkflowChange.bind(this)
    this.save = this.save.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { page } = this.props

    this.doFetch(this.props)

    if (page.isSuccess) {
      const {
        data: { relations = emptyObject, workflow = emptyArray }
      } = page
      this.setState({
        relations,
        workflow
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: EventMethodProps) {
    const { page, pageUpdateStatus, dispatch } = this.props

    this.doFetch(nextProps)

    if (isSuccess(page, nextProps.page)) {
      const {
        data: { relations = emptyObject, workflow = emptyArray }
      } = nextProps.page
      this.setState({
        relations,
        workflow
      })
    }

    if (isSuccess(pageUpdateStatus, nextProps.pageUpdateStatus)) {
      dispatch(nextProps.page.fetch)
      slvyToast.success({
        message: `Event Method ${toastrTypes.true.updateMsg}`,
        title: nextProps.page.data.name
      })
    }
  }

  handleWorkflowChange(workflow: EventMethodState['workflow']) {
    this.setState({ workflow })
  }

  handleAssigneesChange(parameters: HandleAssigneesChangeParams) {
    const { id, newValue, shouldRemoveAll = false, type, key, parentKey } = parameters
    const { relations } = this.state
    this.setState({
      relations: {
        ...relations,
        [type]: {
          ...(relations?.[type] ?? {}),
          [id]: {
            ...(relations?.[type]?.[id] ?? {}),
            [parentKey]: shouldRemoveAll
              ? {}
              : {
                  ...(relations?.[type]?.[id]?.[parentKey] ?? {}),
                  [key]: newValue
                }
          }
        }
      }
    })
  }

  doFetch({ dispatch, containers, plugins, page }: DoFetchParams) {
    if (containers.needFetch) {
      dispatch(containers.fetch)
    }
    if (plugins.needFetch) {
      dispatch(plugins.fetch)
    }
    if (page.needFetch) {
      dispatch(page.fetch)
    }
  }

  save(event: MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault()
    const {
      params: { catalogId, menuId, pageId },
      page: { data },
      updatePage: _updatePage,
      onSaveAndClose
    } = this.props
    const { workflow, relations } = this.state
    _updatePage(catalogId, menuId, pageId, {
      ...data,
      workflow,
      relations
    })
    onSaveAndClose()
    return false
  }

  render() {
    const { relations, workflow } = this.state
    const {
      plugins,
      containers,
      catalog,
      catalog: {
        data: { transferObject = emptyObject, remoteActions = emptyArray } = emptyObject
      } = emptyObject,
      page,
      menus: { data: menuTree } = emptyObject,
      params: { environment, catalogId, menuId, pageId, storeIndex },
      params,
      navigate,
      tab,
      baseUrl
    } = this.props

    if (!containers.isSuccess || !catalog.isSuccess || !plugins.isSuccess || !page.isSuccess) {
      return null
    }

    // TODO: Check if this breaks the memoization
    catalogData.transferObject = transferObject
    catalogData.remoteActions = remoteActions

    const workflowUrl = `${baseUrl}/${pageId}/workflow`
    const relationsUrl = `${baseUrl}/${pageId}/relations`

    return (
      <div className="eventMethod p-3 position-relative">
        <Row>
          <Col xs={12}>
            <Tab.Container activeKey={tab} id="eventmethod-tab">
              <>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link as={Link} eventKey="relations" role="button" to={relationsUrl}>
                      Relations
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={Link} eventKey="workflow" role="button" to={workflowUrl}>
                      Workflow
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="eventMethodTabContent border-0">
                  <Tab.Pane mountOnEnter eventKey="relations" transition={false}>
                    <Assignees
                      assignees={relations}
                      catalog={catalogData}
                      containers={containers.data}
                      menuId={menuId}
                      menuTree={menuTree}
                      plugins={plugins.data}
                      works={workflow}
                      onChange={this.handleAssigneesChange}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter eventKey="workflow" transition={false}>
                    <WorkFlow
                      assignees={relations}
                      containers={containers.data}
                      menuTree={menuTree}
                      params={params}
                      plugins={plugins.data}
                      remoteActions={remoteActions}
                      works={workflow}
                      onChange={this.handleWorkflowChange}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </>
            </Tab.Container>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex mt-3" xs={12}>
            <Button
              className="ms-auto me-2"
              size="sm"
              variant="outline-dark"
              onClick={() => {
                navigate(
                  `/${environment}/catalog/${catalogId}/store/${storeIndex}/menu/${menuId}/page/${pageId}`
                )
              }}
            >
              Close
            </Button>
            <Button size="sm" variant="success" onClick={this.save}>
              Save
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => {
    return {
      catalog: select(getCatalog(ownProps.params.catalogId), state.model3),
      menus: selectCollection(getMenus(ownProps.params.catalogId), state.model3),
      containers: selectCollection(
        getContainers(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
        state.model3
      ),
      plugins: selectCollection(
        getPlugins(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
        state.model3
      ),
      page: select(
        getPage(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
        state.model3
      ),
      pageUpdateStatus: selecOperationStatus('page', 'update', state.model3)
    }
  },
  (dispatch) => ({
    dispatch,

    updatePage: (...p) => {
      dispatch(updatePage(...p))
    }
  })
)(EventMethod)
