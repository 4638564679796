import { DefaultOperator } from 'react-querybuilder'
import { IJobConfig } from '../Jobs.types'

export const config: IJobConfig = {
  key: 'condition',
  title: 'Condition',
  detail_title: 'Condition'
}

export const availableOperators: DefaultOperator[] = [
  { name: 'null', label: 'Is Null' },
  { name: 'notNull', label: 'Is Not Null' },
  { name: '=', label: '=' },
  { name: '!=', label: '!=' },
  { name: '<', label: '<' },
  { name: '>', label: '>' },
  { name: '<=', label: '<=' },
  { name: '>=', label: '>=' }
]

export const translations = { fields: { placeholderLabel: 'Select' } } as const
