Ext.define('Assortment.assortmentLibrary.AssortmentLibrary', {
  extend: 'Ext.window.Window',
  alias: 'widget.assortmentLibrary',
  modal: true,
  layout: 'border',
  height: 500,
  width: 800,
  referenceHolder: true,
  viewModel: {
    data: {
      currentRow: null
    },
    formulas: {
      isFolderSelected: function (get) {
        var currentRow = get('currentRow')
        if (currentRow) {
          return !currentRow.get('IsLeaf')
        }
        return false
      },
      isLeafSelected: function (get) {
        var currentRow = get('currentRow')
        if (currentRow) {
          return currentRow.get('IsLeaf')
        }
        return false
      },
      IsRecentAssortmentsSelected: function (get) {
        var currentRow = get('currentRow')
        if (currentRow) {
          return currentRow.get('IsRecentAssortments')
        }
        return false
      },
      IsReadonly: function (get) {
        var currentRow = get('currentRow')
        if (currentRow) {
          return currentRow.get('IsReadonly')
        }
        return false
      }
    }
  }
})
