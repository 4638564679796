import { getBarsTotalHeight } from '../common'
import { GetBarsTotalHeightProps } from '../common.types'

const getBarsTotalHeightParams = (): GetBarsTotalHeightProps => ({
  isFooterBarEnabled: true,
  isPaginationEnabled: true,
  theme: 'balham'
})

describe('AgGrid:Helpers:Common:getBarsTotalHeight', () => {
  let params = getBarsTotalHeightParams()

  beforeEach(() => {
    params = getBarsTotalHeightParams()
  })

  it('should return 0px if isFooterBarEnabled false and isPaginationEnabled is false', () => {
    params.isFooterBarEnabled = false
    params.isPaginationEnabled = false
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('0px')
  })

  it('should return 40px if isFooterBarEnabled true and isPaginationEnabled is false and theme is balham', () => {
    params.isPaginationEnabled = false
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('40px')
  })
  it('should return 47px if isFooterBarEnabled true and isPaginationEnabled is false and theme is alpine', () => {
    params.isPaginationEnabled = false
    params.theme = 'alpine'
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('47px')
  })
  it('should return 47px if isFooterBarEnabled true and isPaginationEnabled is false and theme is quartz', () => {
    params.isPaginationEnabled = false
    params.theme = 'quartz'
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('47px')
  })

  it('should return 32px if isFooterBarEnabled false and isPaginationEnabled is true and theme is balham', () => {
    params.isFooterBarEnabled = false
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('32px')
  })
  it('should return 49px if isFooterBarEnabled false and isPaginationEnabled is true and theme is alpine', () => {
    params.isFooterBarEnabled = false
    params.theme = 'alpine'
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('49px')
  })
  it('should return 49px if isFooterBarEnabled false and isPaginationEnabled is true and theme is quartz', () => {
    params.isFooterBarEnabled = false
    params.theme = 'quartz'
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('49px')
  })

  it('should return 72px if isFooterBarEnabled true and isPaginationEnabled is true and theme is balham', () => {
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('72px')
  })
  it('should return 96px if isFooterBarEnabled true and isPaginationEnabled is true and theme is alpine', () => {
    params.theme = 'alpine'
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('96px')
  })
  it('should return 96px if isFooterBarEnabled true and isPaginationEnabled is true and theme is quartz', () => {
    params.theme = 'quartz'
    const result = getBarsTotalHeight(params)
    expect(result).toEqual('96px')
  })
})
