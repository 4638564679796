import _ from 'lodash'
import { IRegisterEventMethod } from '../../Common.types'
import { Obj, Params, TreeIndex } from './helpers.types'
import { IContainerEventKey, IContainerMethodKey, IEventKey, IMethodKey } from './Jobs.types'

const hasInList = <T>(list: T[], obj: T) => {
  return !_.isNil(_.find(list, (val) => _.isEqual(val, obj)))
}

const getModifiedItem = (params = {}, listName: string, obj: Obj) => {
  const { [listName]: list = [] }: Params = params

  const modifiedList = hasInList(list, obj)
    ? _.filter(list, (val) => !_.isEqual(val, obj))
    : [...list, obj]

  return {
    ...params,
    [listName]: modifiedList
  }
}

const getClassName = <T>(list: T[], obj: T) => {
  return hasInList(list, obj) ? 'success' : 'outline-dark'
}

const getNodeKey = ({ treeIndex }: TreeIndex) => treeIndex

const getEventsCount = (
  eventKeys: IEventKey[] | IContainerEventKey[],
  sortedEvents: IRegisterEventMethod,
  id: string,
  type: 'plugin' | 'container'
) => {
  const count = { active: 0, total: _.size(sortedEvents) }
  const idKey = type === 'plugin' ? 'pluginId' : 'id'
  Object.keys(sortedEvents).forEach((key) => {
    const isActive = hasInList(eventKeys, { [idKey]: id, eventKey: key })
    count.active += isActive ? 1 : 0
  })
  return count
}

const getMethodsCount = (
  eventMethods: IMethodKey[] | IContainerMethodKey[],
  sortedMethods: IRegisterEventMethod,
  id: string,
  type: 'plugin' | 'container'
) => {
  const count = { active: 0, total: _.size(sortedMethods) }
  const idKey = type === 'plugin' ? 'pluginId' : 'id'
  Object.keys(sortedMethods).forEach((key) => {
    const isActive = hasInList(eventMethods, { [idKey]: id, methodKey: key })
    count.active += isActive ? 1 : 0
  })
  return count
}

const getSortedVariables = (variables: Record<'label' | 'value', string>[]) =>
  variables.sort(({ label: a }, { label: b }) => {
    if (a === 'Select') {
      return -1
    }

    if (b === 'Select') {
      return 1
    }

    return a.toLowerCase().localeCompare(b.toLowerCase())
  })

export {
  getModifiedItem,
  hasInList,
  getClassName,
  getNodeKey,
  getEventsCount,
  getMethodsCount,
  getSortedVariables
}
