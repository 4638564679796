import React from 'react'
import ReactDOM from 'react-dom'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import numeral from 'numeral'
import * as fastJsonPatch from 'fast-json-patch/index'
import { slvyToast } from '../../../components'
import { EditReason } from '../components'
import { coverColors, rowColors } from './constants'
import CustomConsole from './custom-console'

const portalTarget = document.body

export const message = (obj = {}) => {
  const { title = 'Status', msg = 'Ok!', type = 'success' } = obj

  slvyToast[_.isFunction(slvyToast[type]) ? type : 'success']({ message: msg, title })
}

export const getCoverStyle = (
  Cover = 0,
  MinCover = 0,
  minCoverViolation,
  maxCoverViolation,
  unfulfilledCoverViolation
) => {
  const colorStyle = {
    backgroundColor: '',
    color: '',
    set(background, color) {
      this.backgroundColor = background
      this.color = color
    }
  }

  switch (true) {
    case unfulfilledCoverViolation:
      colorStyle.set(coverColors.purple, coverColors.white)
      break
    case minCoverViolation && Cover > MinCover / 2:
      colorStyle.set(coverColors.orange, coverColors.white)
      break
    case minCoverViolation:
      colorStyle.set(coverColors.red, coverColors.white)
      break
    case maxCoverViolation:
      colorStyle.set(coverColors.darkGreen, coverColors.white)
      break
    default:
      colorStyle.set(coverColors.green, coverColors.gray)
  }

  return colorStyle
}

export const getMaxProductValue = (tableName, productPeriod, selectedParamIndex) => {
  return _.max(_.map(productPeriod, (item) => val(item.Values, tableName, selectedParamIndex)))
}

export const round = (number) => {
  // return Math.round(number * 100) / 100
  return Math.round(number)
}

export const format = (number, formatType = '0,0') => {
  return numeral(number).format(formatType)
}

export const parse = (number) => parseFloat(number.replace(/[^\d.-]/g, ''))

export const increase = (number) => {
  return number < 10 ? number + 10 : number
}

export const resourcePlanIncrease = (number) => {
  return number < 4.5 ? number + 4.5 : number
}

export const onlyNumber = (value) => {
  return value.replace(/\D/g, '')
}

export const editReason = (settings = {}, callback = {}) => {
  const { node, isopen = false, reasondata, isportal = true } = settings
  const { onConfirm = () => {}, onReject = () => {} } = callback
  const handler = (e, b, o) => {
    if (b) {
      onConfirm(e, b, o)
    } else {
      onReject(e, b, o)
    }

    ReactDOM.unmountComponentAtNode(node)
  }

  if (isopen) {
    ReactDOM.render(
      isportal ? (
        createPortal(
          <EditReason
            data={reasondata}
            handler={handler}
            isPortal={isportal}
            position={node.getBoundingClientRect()}
          />,
          portalTarget
        )
      ) : (
        <EditReason
          data={reasondata}
          handler={handler}
          isPortal={isportal}
          position={node.getBoundingClientRect()}
        />
      ),
      node
    )
  }
}

export const getMessage = (code, description) => {
  const ret = {
    type: 'success',
    isOk: false,
    status: '',
    set(type, isOk, status) {
      this.type = type
      this.isOk = isOk
      this.status = status
    }
  }

  if (code < 0) {
    ret.set('error', false, 'Update not completed!')
  } else if (code > 0) {
    if (code === 1) {
      ret.set('success', true, 'Successful')
    }
  } else {
    ret.set('warning', false, 'Warning')
  }

  message({
    title: ret.status,
    msg: description,
    type: ret.type
  })

  return new Promise((resolve, reject) => {
    resolve(ret)
  })
}

export const getAttachments = (attachments, value) => {
  if (!_.isEmpty(attachments)) {
    const min = _.first(attachments)
    const max = _.last(attachments)

    attachments = [max, value, min]
  }

  return attachments
}

export const val = (Values, Fieldname, Index) => {
  if (!_.isEmpty(Values) && !_.isEmpty(Fieldname)) {
    const item = Values[Fieldname] || [0, 0]
    const retItem = item[Index] || 0

    return retItem
  }
  return 0
}

// Input: [{a: 1, b:2, c: 3}, {a: 4, b: 5, c: 6}]
// Output: {a: [1,4], b: [2,5], c: [3, 6]}
export const combineItems = (items) => {
  let result = {}

  items.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      const curr = result?.[key]
      const values = curr ? [...curr, value] : [value]

      result = { ...result, [key]: values }
    })
  })

  return result
}

export const relocateScroll = (productId) => {
  const findedElement = document.querySelector(`[data-scroll-id="product-${productId}"]`)
  const rccpTable = document.querySelector('.rccp-table')

  if (!_.isNil(productId) && findedElement && rccpTable) {
    const currentOffset = findedElement.offsetTop
    CustomConsole.log(currentOffset)
    rccpTable.scrollTo(0, currentOffset)
  }
}

export const restoreScrollPos = (element, scrollPos) => {
  element.scrollTop = scrollPos.top
  element.scrollLeft = scrollPos.left
}

export const getProductPeriod = (
  getRccpResourcePeriodResponse = {},
  data = {},
  selectedProduct = {}
) => {
  const { Periods = [], Products = [], ProductPeriods = {} } = getRccpResourcePeriodResponse
  const { week: periodWeekName = '' } = data

  const periodIndex = _.findIndex(Periods, (period) => _.isEqual(period.Name, periodWeekName))
  const productIndex = _.findIndex(Products, (product) =>
    _.isEqual(product.Name, selectedProduct.Name)
  )
  const productPeriod = productIndex !== -1 ? ProductPeriods[productIndex][periodIndex] : {}

  return productPeriod
}

export const getCoverKey = (setting, type) => {
  switch (type) {
    case 'Cover':
      return setting || 'Cover'
    case 'MinCover':
      return setting || 'MinCover'
    case 'MaxCover':
      return setting || 'MaxCover'
    // no default
  }
}

export const findUpdateValue = (unit, productionVariant) => {
  if (unit === 'Production') {
    return `${productionVariant}`
  }

  if (unit === 'ProductionKG') {
    return `${productionVariant}KG`
  }
}

export const copy = (obj) => ({ ...obj })

export const filterChildren = (children, searchValue) => {
  return children.map(copy).filter(function filterChild(children) {
    return (
      children.Name.includes(searchValue.toUpperCase()) ||
      (children.Children &&
        (children.Children = children.Children.map(copy).filter(filterChild)).length)
    )
  })
}

export const cellLoading = (target, state) => {
  if (!_.isEmpty(target)) target.classList[state ? 'add' : 'remove']('-small-loading')
}

export const isStateCellTargetEmpty = (cellTarget) =>
  _.isEmpty(cellTarget) || cellTarget.className === ''

export const logErrorDetails = ({ left, diffJson, stateName, setState, errCount }) => {
  let error = {}
  const validator = (operation, index, tree, existingPath) => {
    index = _.findIndex(diffJson, operation)
    error = { operation, index, tree, existingPath }
  }

  try {
    fastJsonPatch.applyPatch(left, diffJson, validator)
  } catch (err) {
    CustomConsole.error({ ...error, index: error.index + errCount })
    diffJson = _.filter(diffJson, (diff, index) => index != error.index)
    errCount++
    patchDiff({ left, diffJson, isDiff: true, stateName, setState, errCount })
  }
}

export const patchDiff = ({ left, diffJson, isDiff, stateName, setState, errCount = 0 }) => {
  let right = left

  try {
    right = isDiff ? fastJsonPatch.applyPatch(left, diffJson).newDocument : diffJson
  } catch (_) {
    slvyToast.error({ message: 'Please retrieve the most recent data.', title: 'Patching Failed!' })
    logErrorDetails({ left, diffJson, stateName, setState, errCount })
  }

  setState({ [stateName]: right }, false, true)
}

export const getEnabledDisplayNames = (displayOptions, periodDisplayName, periodName) => {
  const displayNameArr = !_.isNil(periodDisplayName)
    ? periodDisplayName.split('-')
    : periodName.split('-')

  const enabledDisplayNames = _.reduce(
    displayOptions,
    (enabledDisplayNames, periodOption, index) =>
      periodOption
        ? [
            ...enabledDisplayNames,
            <span key={`${displayNameArr[index]}-${index}`}>{displayNameArr[index]}</span>
          ]
        : enabledDisplayNames,
    []
  )

  return enabledDisplayNames
}

export const calcTopValue = (items, selectedResourceIssueCount, maxSelectedIssues) => {
  const defaultCellHeight = 29 // -1px negative margin. It's actually 30px
  const defaultPeriodsBarHeight = 48

  const rowHeight =
    defaultCellHeight +
    (selectedResourceIssueCount < 3
      ? (selectedResourceIssueCount - 1) * 4
      : (selectedResourceIssueCount - 1) * 14 - 10)
  const periodsBarHeight = maxSelectedIssues > 3 ? maxSelectedIssues * 16 : defaultPeriodsBarHeight

  return items * rowHeight + periodsBarHeight
}

export const displayOfflineErrorMsg = () => {
  slvyToast.error({
    message: 'Something went wrong. Please try again later.',
    title: 'Update not completed!'
  })
}

export const dropGrandTotal = (collection) => {
  if (_.isObject(collection)) {
    return _.reject(collection, (item, i) => _.parseInt(i) === _.size(collection) - 1)
  }

  return _.dropRight(collection, 1)
}

export const isMaxNumber = (n) => n >= Number.MAX_VALUE

export const getCoverViolations = (issueValues, coverViolationIndexes = []) => {
  const [minCoverViolationIndex, maxCoverViolationIndex, unfulfilledCoverViolationIndex] =
    coverViolationIndexes
  const minCoverViolation = issueValues[minCoverViolationIndex]
  const maxCoverViolation = issueValues[maxCoverViolationIndex]
  const unfulfilledCoverViolation = issueValues[unfulfilledCoverViolationIndex]

  return [!!minCoverViolation, !!maxCoverViolation, unfulfilledCoverViolation < 0]
}

export const getIssueSize = (value, maxValue, name, baseSize = 32) => {
  let size = (value * baseSize) / maxValue

  const isUnfulfilledCoverViolation = name === 'UnfulfilledCoverViolation'
  if (isUnfulfilledCoverViolation) {
    size = value >= 0 ? 0 : Math.min(value * -1, baseSize)
  }

  return Math.ceil(size > 0 ? Math.max(3, size) : 0)
}

export const getRowBgColor = (index, type) => {
  if (index % 2 === 0 && type === 'Product') {
    return rowColors.lightGray
  }
  return rowColors.white
}

export const getProductName = (productName) => {
  const [number, name] = productName.split(' - ')
  return (
    <span>
      <span>{name}</span>
      <br />
      <span>{number}</span>
    </span>
  )
}
