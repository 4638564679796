import * as actionTypes from './actionTypes'
import { makeRequest } from '../../utils'

export const fetchScenarioTree = (action) => {
  return (dispatch) => {
    dispatch(startFetchScenarioTree())

    return new Promise((resolve, reject) => {
      makeRequest(action)
        .then((response) => {
          dispatch(
            saveScenarioTree({
              payload: response.data
            })
          )
          resolve(response.data)
        })
        .catch((error) => {
          dispatch(
            saveScenarioTreeFailed({
              payload: error.data
            })
          )
          reject(error.data)
        })
    })
  }
}

export const startFetchScenarioTree = () => {
  return {
    type: actionTypes.FETCH_SCENARIO_TREE_START
  }
}

export const saveScenarioTree = (options) => {
  return {
    type: actionTypes.STORE_SCENARIO_TREE,
    payload: options.payload
  }
}

export const saveScenarioTreeFailed = (options) => {
  return {
    type: actionTypes.STORE_SCENARIO_TREE_FAILED,
    payload: options.payload
  }
}

export const updateScenarioTreeStatus = (options) => {
  return {
    type: actionTypes.UPDATE_SCENARIO_TREE_STATUS,
    payload: options.payload
  }
}

export const deleteScenarioTreeNode = (options) => {
  return {
    type: actionTypes.DELETE_SCENARIO_TREE_NODE,
    payload: options.payload
  }
}
