import React from 'react'
import moment from 'moment'
import { MESSAGES } from '../../messages'
import { IAction, IActionProps } from './Actions.types'
import './actions.scss'

const Actions = ({ actions = [], userName, actionLogsColumnName, onUndoAction }: IActionProps) => {
  if (!actions.length) {
    return null
  }

  return (
    <div className="-action-list-container">
      <ul className="-action-list">
        {actions.map((action: IAction, actionIndex) => {
          const { Id, CreatedOn, MfpActionLogs } = action
          const [{ Username = '' } = {}] = MfpActionLogs

          return (
            <li key={actionIndex}>
              <div className="-ac-line">
                <span className="-tt-c">{Username}</span>
                <span>{moment(CreatedOn).format('DD.MM.YYYY')}</span>
              </div>
              {MfpActionLogs.length ? (
                <ul>
                  {MfpActionLogs.map((log, logIndex) => {
                    const { OldValue, NewValue } = log
                    const ColumnName =
                      actionLogsColumnName in log ? log[actionLogsColumnName] : log.ColumnName
                    return (
                      <li key={logIndex} className="-ac-line -ac-row-item">
                        <span className="-ac-col-item">{ColumnName}:</span>
                        <span className="-ac-col-item">{OldValue.toFixed(2)}</span>
                        <span className="-ac-col-item"> to </span>
                        <b className="-ac-col-item">{NewValue.toFixed(2)}</b>
                      </li>
                    )
                  })}
                </ul>
              ) : null}
              {userName === Username && actionIndex === 0 ? (
                <span className="cell-btn -undo" onClick={() => onUndoAction(Id)}>
                  <i className="slvy-ui-icon-undo slvy-icon" />
                  {MESSAGES.undo}
                </span>
              ) : null}
            </li>
          )
        })}
      </ul>
      <div className="-action-overlay" />
    </div>
  )
}

export default Actions
