Ext.define('MDM.Btl.Main', {
  extend: 'Ext.panel.Panel',
  controller: 'btl-maincontroller',
  viewModel: 'btl-mainviewmodel',
  requires: ['MDM.Btl.edit.TabPanel'],
  bodyPadding: 2,
  maximized: true,
  autoShow: true,
  ui: 'window-inverse',
  xtype: 'btleditmain',
  config: {
    cfgData: null,
    extraParams: null
  },
  bodyStyle: {
    'background-color': '#fafafa'
  },
  layout: 'border',
  dockedItems: [
    {
      xtype: 'btl-header',
      docked: 'top'
    }
  ],
  listeners: {
    afterrender: 'onAfterRender',
    delay: 1000
  },
  items: [
    {
      xtype: 'tabpanel',
      region: 'center',
      cls: 'rmdtabpanel',
      reference: 'uppertabPanel',
      deferredRender: false,
      items: [
        {
          margin: '0',
          xtype: 'btl-center-grid',
          border: true,
          region: 'center',
          collapsible: false,
          title: 'DPI View',
          reference: 'centergrid',
          split: false,
          bind: {
            store: '{mainStore}',
            readOnly: '{readOnly}',
            hideReadOnlyCol: '{hideReadOnlyColfn}'
          }
        },
        {
          xtype: 'Discrepancy-maingrid',
          title: 'Discrepancy View',
          reference: 'discrepancy',
          border: true,
          collapsible: false,
          bind: {
            store: '{discrepancyStore}',
            disabled: '{constarinView}',
            readOnly: '{readOnly}',
            hideReadOnlyCol: '{hideReadOnlyColfn}'
          }
        }
      ]
    },
    {
      margin: '2 0 6 0',
      xtype: 'btledittabpanel',
      region: 'south',
      height: 400,
      header: false,
      hidden: true,
      collapsible: false,
      split: true
    }
  ],
  setExtraParams: function (c) {
    var me = this,
      viewModel = me.getViewModel()
    if (!Ext.isObject(c)) {
      console.log('Arguments is not Object')
      return
    }
    if (c.hasOwnProperty('ReportingPeriodID')) {
      v = c.ReportingPeriodID
      viewModel.set('ReportingPeriodID', v)
    }
    if (c.hasOwnProperty('MegaRegionID')) {
      v = c.ReportingPeriodID
      viewModel.set('MegaRegionID', v)
    }
    if (c.hasOwnProperty('RegionID')) {
      v = c.RegionID
      viewModel.set('RegionID', v)
    }
    if (c.hasOwnProperty('DPIFamilyID')) {
      var v = c.DPIFamilyID
      viewModel.set('dpidefaulValue', {
        fieldName: 'DPIFamilyID',
        value: v
      })
    } else if (c.hasOwnProperty('ProductGroupID')) {
      var v = c.ProductGroupID
      viewModel.set('dpidefaulValue', {
        fieldName: 'ProductGroupID',
        value: v
      })
    } else if (c.hasOwnProperty('DPISegmentID')) {
      var v = c.DPISegmentID
      viewModel.set('dpidefaulValue', {
        fieldName: 'DPISegmentID',
        value: v
      })
    }
    return
  }
})
