type EnvTypes = 'Configuration' | 'Test' | 'Live'

function definePermissions<T extends Record<string, EnvTypes[]>>(template: T) {
  return template
}

export const environmentPermissions = definePermissions({
  'Catalog.Add': ['Configuration'],
  'Catalog.Edit': ['Configuration', 'Test'],
  'Menu.Add': ['Configuration'],
  'Menu.Edit': ['Configuration'],
  'Page.Add': ['Configuration'],
  'Page.CreateTemplate': ['Configuration'],
  'Page.Edit': ['Configuration'],
  'Plugin.Add': ['Configuration'],
  'Plugin.Edit': ['Configuration'],
  'UserGroupUserManagement.Create': ['Configuration', 'Test', 'Live'],
  'UserGroupUserManagement.Delete': ['Configuration', 'Test', 'Live'],
  'UserGroupUserManagement.Read': ['Configuration', 'Test', 'Live'],
  'UserGroupUserManagement.Update': ['Configuration', 'Test', 'Live'],
  'UserManagement.Create': ['Configuration', 'Test', 'Live'],
  'UserManagement.Delete': ['Configuration', 'Test', 'Live'],
  'UserManagement.Read': ['Configuration', 'Test', 'Live'],
  'UserManagement.Update': ['Configuration', 'Test', 'Live'],
  'Version.Publish': ['Configuration', 'Test'],
  'Version.ReturnFromSnapshot': ['Configuration'],
  'Version.Snapshot': ['Configuration', 'Test']
})
