Ext.define('tuiv2.treeview.LoadTree', {
  extend: 'Ext.tree.Panel',
  xtype: 'loadtree',
  displayField: 'Text',
  reference: 'loadTree',
  columnLines: true,
  cls: 'loadTree',
  rowLines: true,
  syncRowHeight: true,
  checkPropagation: 'both',
  lockedViewConfig: {
    syncRowHeight: true
  },
  lockedGridConfig: {
    syncRowHeight: true
  },
  config: {
    loadKeys: [],
    userManipulation: false
  },
  viewConfig: {
    enableTextSelection: true
  },
  columns: [],
  stateful: true,
  getState: function () {
    var me = this,
      state = me.callParent(),
      storeState = me.store.getState()

    state = me.addPropertyToState(state, 'loadKeys', me.getLoadKeys())
    state = me.addPropertyToState(state, 'userManipulation', me.getUserManipulation())
    if (storeState) {
      state.storeState = storeState
    }
    return state
  },
  getStateId: function () {
    var me = this
    var view = me.up('tuiv2mainview')
    var viewModel = view.getViewModel()
    return 'loadHieararchyState-' + viewModel.data.loadHierarchyHash
  },
  applyState: function (state) {
    var me = this,
      loadKeys = state.loadKeys,
      userManipulation = state.userManipulation
    if (loadKeys && loadKeys.length > 0) {
      me.setLoadKeys(loadKeys)
    }
    me.setUserManipulation(userManipulation)
  },
  listeners: {
    itemclick: function (self, record, item, index, eventObj, eOpts) {
      var checkedLoads = [],
        checkedLoadStops = [],
        checkedLoadsForEvent = [],
        checkedLoadStopsForEvent = [],
        view = this.up('tuiv2mainView'),
        viewModel = view.getViewModel(),
        controller = view.getController(),
        map = view.lookupReference('mainMap'),
        node

      if (eventObj.getTarget('.x-tree-checkbox', 1, true)) {
        map.getEl().mask('Processing')

        var nodes
        if (self.lockedGrid) {
          nodes = self.lockedGrid.getChecked()
        } else {
          nodes = self.getChecked()
        }

        nodes.forEach((element) => {
          var currentNode = element.parentNode

          while (currentNode && currentNode.parentNode) {
            currentNode.set('checked', element.get('checked'))

            currentNode = currentNode.parentNode
          }
        })

        var loads = controller.getCheckedLoads(nodes)
        loads.forEach((element) => {
          checkedLoads.push(element.id)
        })

        var data = controller.getCheckedLoadsForEvent(nodes)
        data.forEach((element) => {
          checkedLoadsForEvent.push(element)
        })

        viewModel.set('checkedLoads', checkedLoads)
        view.fireEvent('LoadRowSelected', checkedLoadsForEvent)
        view.onLoadRowSelected(checkedLoadsForEvent)

        var loadStops = controller.getCheckedLoadStops(nodes)
        loadStops.forEach((element) => {
          checkedLoadStops.push(element.id)
        })

        var dataForEvent = controller.getCheckedLoadStopsForEvent(nodes)
        dataForEvent.forEach((element) => {
          checkedLoadStopsForEvent.push(element)
        })

        viewModel.set('checkedLoadStops', checkedLoadStops)
        view.fireEvent('LoadStopsSelected', checkedLoadStopsForEvent)
        view.onLoadStopsSelected(checkedLoadStopsForEvent)
      }
    },
    staterestore: function () {
      tuiv2.Global.setLoadTreeStructure(this.getLoadKeys())
      tuiv2.Global.setLoadHierarchyIsEmpty(this.getUserManipulation())
    }
  }
})
