export const defaultColor = {
  r: 255,
  g: 255,
  b: 255,
  a: 1
} as const

export const defaultForm = {
  steps: [],
  ids: [],
  isControlledWithWorkflow: false,
  previousText: 'Previous',
  nextText: 'Next',
  cancelText: '',
  finishText: 'Finish',
  backgroundColor: defaultColor,
  theme: '0'
} as const
