import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { createSelectorCreator, lruMemoize } from 'reselect'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import { Button } from 'react-bootstrap'
import Mousetrap from 'mousetrap'
import { SlvySelect, SlvySelectStyles, slvyToast } from '../../../../components'

/* Crud */
import { selectCollection } from '../../../../crudoptV3'
import { getContainers, updateContainer } from '../../../../actions/container'
import { createTemplate } from '../../../../actions/template'
import { clear } from '../../../../store/slices/pluginlist'
import { selectAContainer } from '../../../../store/slices/containerRelation'
import { setIsConnectionChangeActive } from '@/store/slices/setting'

import PropertyEditor from './PropertyEditor'
import PageDetail from '../PageDetail'
import pubsub from '../../../../subs'

import './style.scss'
import 'react-reflex/styles.css'

const menuPortalTarget = document.body

class PageContent extends Component {
  constructor(props) {
    super(props)
    this.showPropertyEditor = this.showPropertyEditor.bind(this)
    this.handleChangeCurrentContainer = this.handleChangeCurrentContainer.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleHide = this.handleHide.bind(this)
    this.handleCreateTemplate = this.handleCreateTemplate.bind(this)
    this.handleClickContainerEdit = this.handleClickContainerEdit.bind(this)
    this.handleContainerEdit = this.handleContainerEdit.bind(this)
    this.closePropertyEditor = this.closePropertyEditor.bind(this)
    this.state = { popupme: [], containerId: 0, showPropertyEditor: false }
    this.usedContainers = []
  }

  componentWillUnmount() {
    const {
      params: { environment }
    } = this.props
    if (environment === 'Configuration' && Mousetrap.unbind) {
      Mousetrap.unbind(['ctrl+up', 'ctrl+shift+x'])
    }
  }

  UNSAFE_componentWillMount() {
    const {
      params: { environment }
    } = this.props
    if (environment === 'Configuration') {
      Mousetrap.bind(['ctrl+up', 'ctrl+shift+x'], this.showPropertyEditor)
    }

    if (this.props.containers.needFetch) {
      this.props.dispatch(this.props.containers.fetch)
    }

    this.props.setIsConnectionChangeActive(false)

    pubsub.subscribe(
      'popup_drilldown',
      (value) => {
        const { popupme = [] } = this.state
        this.setState({ popupme: [...popupme, value] })
      },
      { type: 'method' }
    )
  }

  componentDidMount() {
    this.handleClickContainerEdit()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.containers.needFetch) {
      this.props.dispatch(nextProps.containers.fetch)
    }

    if (nextProps.params.pageId !== this.props.params.pageId) {
      this.setState({ popupme: [] })
      this.props.setIsConnectionChangeActive(false)
    }
  }

  handleClickContainerEdit() {
    window.removeEventListener('editContainer', this.handleContainerEdit)
    window.addEventListener('editContainer', this.handleContainerEdit)
  }

  handleContainerEdit(event) {
    const { detail } = event
    this.setState({ showPropertyEditor: true })
    this.handleChangeCurrentContainer(detail)
  }

  closePropertyEditor() {
    this.setState({ showPropertyEditor: false })
  }

  handleChangeCurrentContainer(newContainerId) {
    this.setState({ containerId: newContainerId })
    this.props.selectAContainer(newContainerId)
  }

  handleSave(id, data) {
    const {
      params: { catalogId, menuId, pageId }
    } = this.props
    this.props.updateContainer('update', catalogId, menuId, pageId, id, {
      ...data
    })
  }

  handleHide(pageId) {
    return () => {
      this.setState({
        popupme: _.filter(this.state.popupme, (p) => !_.isEqual(p.pageId, pageId))
      })
    }
  }

  handleCreateTemplate(containerId, title) {
    this.props.createTemplate(containerId, title)
    slvyToast.success({ message: 'Save As Template', title: 'Success' })
  }

  showPropertyEditor() {
    this.setState({ showPropertyEditor: !this.state.showPropertyEditor })
  }

  mapContainers(containers) {
    const newContainers = [{ label: 'Select', value: 0 }]
    _.each(
      _.sortBy(containers, (c) => `${c.type}_${c.settings.name}`),
      ({ id, type, settings: { name } = {} }) => {
        newContainers.push({ label: `${name || id} (${type || 'main'})`, value: id })
      }
    )
    return newContainers
  }

  render() {
    const {
      containers: { data: containers },
      params,
      userName
    } = this.props
    const { showPropertyEditor, containerId, popupme } = this.state

    const newContainers = this.mapContainers(containers)
    const [firstContainerOption] = newContainers

    const selectValue =
      newContainers.find((item) => item.value === containerId) ?? firstContainerOption

    return (
      <div key={params.pageId} className="pageDetail">
        {showPropertyEditor ? (
          <ReflexContainer orientation="vertical">
            <ReflexElement>
              <PageDetail params={params} userName={userName} />
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement maxSize={800} minSize={300} size={300}>
              <div className="adminButtonWrapper">
                <Button
                  className="adminButton"
                  size="sm"
                  variant="danger"
                  onClick={this.closePropertyEditor}
                >
                  <i className="slvy-ui-icon-times-lt adminCloseButtonIcon" />
                </Button>
              </div>
              <div className="p-2 border-1">
                <SlvySelect
                  className="form-slvy-select"
                  classNamePrefix="slvy-select"
                  menuPortalTarget={menuPortalTarget}
                  options={newContainers}
                  placeholder="select"
                  styles={SlvySelectStyles.small}
                  value={selectValue}
                  onChange={(option) => {
                    this.handleChangeCurrentContainer(option?.value ?? firstContainerOption.value)
                  }}
                />
              </div>
              <PropertyEditor
                container={_.find(containers, ({ id }) => id === containerId)}
                onClickSaveAsTemplate={this.handleCreateTemplate}
                onSave={this.handleSave}
              />
            </ReflexElement>
          </ReflexContainer>
        ) : (
          <PageDetail params={params} userName={userName} />
        )}

        {_.map(popupme, (popup, key) => (
          <PageDetail key={key} params={{ ...params, ...popup }} userName={userName} />
        ))}
      </div>
    )
  }
}

const getContainersState = (state, ownProps) => {
  return selectCollection(
    getContainers(ownProps.params.catalogId, ownProps.params.menuId, ownProps.params.pageId),
    state.model3
  )
}

const createDeepEqualSelector = createSelectorCreator(lruMemoize, (a, b) => {
  return _.isEqual(a.fetchKey, b.fetchKey)
})

const getContainersSelector = () => {
  return createDeepEqualSelector([getContainersState], (x) => x)
}

const makeMapStateToProps = () => {
  const containers = getContainersSelector()
  const mapStateToProps = (state, ownProps) => {
    return {
      containers: containers(state, ownProps)
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, (dispatch) => ({
  dispatch,
  clear: bindActionCreators(clear, dispatch),
  createTemplate: bindActionCreators(createTemplate, dispatch),
  selectAContainer: bindActionCreators(selectAContainer, dispatch),
  updateContainer: bindActionCreators(updateContainer, dispatch),
  setIsConnectionChangeActive: bindActionCreators(setIsConnectionChangeActive, dispatch)
}))(PageContent)
