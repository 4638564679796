import React from 'react'
import { MESSAGES } from '../../messages'

const ScenarioHeader = () => {
  return (
    <div className="sc-header">
      <table>
        <tbody>
          <tr>
            <td>{MESSAGES.name}</td>
            <td>{MESSAGES.description}</td>
            <td>{MESSAGES.created_by}</td>
            <td className="text-end" title="Actual / Revision / Create">
              Actual &nbsp; R &nbsp; / &nbsp; C
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ScenarioHeader
