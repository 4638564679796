export default `({
    layout: 'border',
    title: 'Spot Analysis',
    bodyBorder: true,
    defaults: {
        collapsible: true,
        split: true,
        bodyPadding: 10,
    },
    items: [{
            region: 'center',
            header: false,
            flex: 1,
            xtype: 'uxPanel',
            collapsible: true,
            uxStoreCfg: 'chart113gridstore',
            uxItemCfg: 'chart113grid',
        },
        {
            collapsible: true,
            header: false,
            region: 'north',
            margin: '5 0 0 0',
            xtype: 'uxPanel',
            uxItemCfg: 'chart113',
            flex: 1
        }
    ]
})
`
