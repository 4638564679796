Ext.define('MDM.src.model.BaseAutoSync', {
  extend: 'Ext.data.Model',

  schema: {
    namespace: 'Mdm.model.config',
    id: 'MainMDMmodelautocync',
    urlPrefix: MDM.Global.getApiUrl(),
    proxy: {
      type: 'ajax',
      method: 'POST',
      timeout: 360000,
      useDefaultXhrHeader: false,
      cors: true,
      autoSync: true,
      actionMethods: {
        create: 'POST',
        read: 'POST',
        update: 'POST',
        destroy: 'POST'
      },
      api: {
        create: '{prefix}{entityName:lowercase}/create',
        read: '{prefix}{entityName:lowercase}/read',
        update: '{prefix}{entityName:lowercase}/update',
        destroy: '{prefix}{entityName:lowercase}/destroy'
      },
      reader: {
        type: 'json',
        rootProperty: 'data',
        totalProperty: 'totalCount',
        keepRawData: true,
        successProperty: 'success',
        messageProperty: 'errors'
      },
      writer: {
        rootProperty: 'data',
        allowSingle: false,
        successProperty: 'success',
        messageProperty: 'errors',
        allDataOptions: {
          associated: false
        },
        writeAllFields: false
      },
      extraParams: {
        mainAppId: null
      }
    }
  },
  listeners: {
    beforeload: function (store) {
      if (store.isLoading()) return false
      var proxy = store.getProxy(),
        catalofInfo = MDM.Global.getCatalogInfo(),
        catalogId = catalofInfo != null && catalofInfo.catalogId ? catalofInfo.catalogId : null
      proxy.setExtraParam('catalogId', catalogId)
    }
  }
})
