import { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { createSelectorCreator, lruMemoize } from '@reduxjs/toolkit'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { runPluginQuery } from '@/actions/query'
import { select } from '@/crudoptV3'
import { slvyToast } from '@/components'
import { copyOrRemoveFromClipboard } from '@/store/slices/copy'
import { max, min } from '@/store/slices/maximize'
import permission, { PermTypes } from '@/helpers/Permission'
import ApiClient from '@/crudoptV3/libs/apiClient'

const client = new ApiClient()
const queryFormatRegex = new RegExp(/\{([^}]+)\}/g)

class Menu extends Component {
  constructor() {
    super()
    this.handleCopy = this.handleCopy.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.maximizeHandle = this.maximizeHandle.bind(this)
    this.minimizeHandle = this.minimizeHandle.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.state = {
      maximize: false
    }
  }

  handleCopy(event) {
    event.preventDefault()
    const { id, type } = this.props.item
    this.props.copyOrRemoveFromClipboard({ id, type })
  }

  handleRemove(event) {
    const {
      item: { id },
      onRemoveClick
    } = this.props

    event.preventDefault()
    confirmAlert({
      title: 'Deleting Plugin?',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Cancel'
        },
        {
          label: 'Confirm Delete',
          onClick: () => onRemoveClick(id)
        }
      ]
    })
  }

  handleExport(event) {
    if (!_.isEmpty(event)) event.preventDefault()

    const {
      args,
      fields: initialFields,
      formattedFields: initialFormattedFields,
      exportedColumn: initialExportedColumn = [],
      item: {
        query: { editableFields = [] } = {},
        config: {
          general: { exportAllColumns = false, exportWithoutHeaders = false } = {},
          columns: configColumns = [],
          leftAxis = []
        } = {},
        type = null,
        id
      } = {},
      exportData: { params: { args: exportArgs = {}, _CONNECTIONID } = {} } = {},
      query: { data: { schema: queryFields } = {} } = {}
    } = this.props

    const fields = queryFields ?? initialFields

    const formattedFields =
      initialFormattedFields?.map((formattedField) => {
        const { formatString, excelFormatString } = formattedField
        const formatKey = formatString?.replace?.(queryFormatRegex, (str, val) => val || str)
        const excelFormatKey = excelFormatString?.replace?.(
          queryFormatRegex,
          (str, val) => val || str
        )

        const isFormatVariable = queryFormatRegex.test(formatString)
        const shouldUpdateFormatString = isFormatVariable && args && formatKey in args
        const updatedFormatString = shouldUpdateFormatString ? args[formatKey] : formatString

        const isExcelFormatVariable = queryFormatRegex.test(excelFormatString)
        const shouldUpdateExcelFormatString =
          isExcelFormatVariable && args && excelFormatKey in args
        const updatedExcelFormatString = shouldUpdateExcelFormatString
          ? args[excelFormatKey]
          : excelFormatString

        return {
          ...formattedField,
          formatString: updatedFormatString,
          excelFormatString: updatedExcelFormatString
        }
      }) ?? []

    const exportedColumn = initialExportedColumn.map((column) => {
      const { FormatString } = column

      const isFormatVariable = queryFormatRegex.test(FormatString)
      const key = FormatString?.replace?.(queryFormatRegex, (str, val) => val || str)

      const shouldUpdateFormat = isFormatVariable && args && key in args
      const updatedFormatString = shouldUpdateFormat ? args[key] : FormatString

      return {
        ...column,
        FormatString: updatedFormatString
      }
    })

    const selectedArgs = !_.isEmpty(exportArgs) ? exportArgs : args

    const exportObj = {
      getDataInput: {
        arguments: { ...selectedArgs, _CONNECTIONID }
      },
      columns: [],
      exportAllColumns,
      exportWithoutHeaders
    }

    const me = this

    if (exportAllColumns) {
      _.map(fields, (field) => {
        const formattedField = _.find(formattedFields, {
          columnName: field.fieldName
        })
        let displayName = field.fieldName
        if (!exportWithoutHeaders) {
          if (configColumns.length > 0) {
            // eslint-disable-next-line eqeqeq
            const configColumn = configColumns.find((p) => p.fieldName == field.fieldName)
            if (configColumn) {
              displayName = configColumn.header
            }
          }
        }
        const substituteColumn = me.findSubstituteColumn(editableFields, field.fieldName, fields)

        exportObj.columns.push({
          columnName: field.fieldName,
          displayName,
          substituteColumn,
          ...(formattedField && (formattedField.formatString || formattedField.excelFormatString)
            ? {
                formatString: formattedField.formatString,
                excelFormatString: formattedField.excelFormatString
              }
            : null)
        })
      })
    }
    // eslint-disable-next-line eqeqeq
    else if ((type === 'SenchaGrid' || type === 'AgGrid') && exportWithoutHeaders) {
      _.map(configColumns, (column) => {
        const formattedField = _.find(formattedFields, {
          columnName: column.fieldName
        })

        const substituteColumn = me.findSubstituteColumn(editableFields, column.fieldName, fields)

        exportObj.columns.push({
          columnName: column.fieldName,
          displayName: column.fieldName,
          substituteColumn,
          ...(formattedField && (formattedField.formatString || formattedField.excelFormatString)
            ? {
                formatString: formattedField.formatString,
                excelFormatString: formattedField.excelFormatString
              }
            : null)
        })
      })
    } else if (!_.isEmpty(exportedColumn)) {
      const uniqExportedColumn = _.uniqBy(exportedColumn, 'ColumnName')

      uniqExportedColumn.forEach(function (column) {
        const substituteColumn = me.findSubstituteColumn(editableFields, column.ColumnName, fields)
        column.substituteColumn = substituteColumn
      })

      exportObj.columns = uniqExportedColumn
    }
    // eslint-disable-next-line eqeqeq
    else if ((type === 'SenchaGrid' || type === 'AgGrid') && configColumns.length > 0) {
      _.map(configColumns, (column) => {
        const formattedField = _.find(formattedFields, {
          columnName: column.fieldName
        })

        const substituteColumn = me.findSubstituteColumn(editableFields, column.fieldName, fields)

        exportObj.columns.push({
          columnName: column.fieldName,
          displayName: column.header,
          substituteColumn,
          ...(formattedField && (formattedField.formatString || formattedField.excelFormatString)
            ? {
                formatString: formattedField.formatString,
                excelFormatString: formattedField.excelFormatString
              }
            : null)
        })
      })
    }
    // eslint-disable-next-line eqeqeq
    else if ((type === 'SenchaPivot' || type === 'AgPivot') && !exportWithoutHeaders) {
      _.map(fields, (field) => {
        const formattedField = _.find(formattedFields, {
          columnName: field.fieldName
        })

        let displayName = field.fieldName
        if (leftAxis.length > 0) {
          // eslint-disable-next-line eqeqeq
          const configColumn = leftAxis.find((p) => p.dataIndex == field.fieldName)
          if (configColumn) {
            displayName = configColumn.header
          }
        }

        const substituteColumn = me.findSubstituteColumn(editableFields, field.fieldName, fields)

        exportObj.columns.push({
          columnName: field.fieldName,
          displayName,
          substituteColumn,
          ...(formattedField && (formattedField.formatString || formattedField.excelFormatString)
            ? {
                formatString: formattedField.formatString,
                excelFormatString: formattedField.excelFormatString
              }
            : null)
        })
      })
    } else {
      _.map(fields, (field) => {
        const formattedField = _.find(formattedFields, {
          columnName: field.fieldName
        })

        const substituteColumn = me.findSubstituteColumn(editableFields, field.fieldName, fields)

        exportObj.columns.push({
          columnName: field.fieldName,
          displayName: field.fieldName,
          substituteColumn,
          ...(formattedField && (formattedField.formatString || formattedField.excelFormatString)
            ? {
                formatString: formattedField.formatString,
                excelFormatString: formattedField.excelFormatString
              }
            : null)
        })
      })
    }

    // if (this.props.settings.query.query.indexOf('{TAKE}') > 0) {
    exportObj.getDataInput.arguments = {
      ...exportObj.getDataInput.arguments,
      SKIP: 0,
      TAKE: 100000000
    }
    // }

    if (_.isEmpty(exportObj.columns)) return

    client.post(`/data/${id}/export`, { data: exportObj }).then((result) => {
      const { url: file_path, exportByEmail } = result || {}
      if (exportByEmail) {
        slvyToast.info({
          message: 'Export started. You will receive a download link in your email shortly.',
          title: 'Export by E-mail'
        })
      } else if (file_path) {
        const a = document.createElement('A')
        a.href = file_path
        a.download = file_path.substr(file_path.lastIndexOf('/') + 1)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
  }

  findSubstituteColumn(editableFields, columnName, fields) {
    // eslint-disable-next-line eqeqeq
    const substituteField = editableFields.find((p) => p.field == columnName)
    let substituteColumn = substituteField ? substituteField.substitudeField : null
    if (substituteColumn != null) {
      // eslint-disable-next-line eqeqeq
      const field = fields.find((p) => p.fieldName == substituteColumn)
      // eslint-disable-next-line eqeqeq
      if (field == null || field == undefined) {
        substituteColumn = null
      }
    }
    return substituteColumn
  }

  maximizeHandle(event) {
    event.preventDefault()
    const { id } = this.props.item
    this.props.max(id)
    this.setState({ maximize: true }, this.dispatchResize)
  }

  minimizeHandle(event) {
    event.preventDefault()
    this.props.min()
    this.setState({ maximize: false }, this.dispatchResize)
  }

  componentWillUnmount() {
    this.props.min()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.maximize !== prevState.maximize) {
      this.dispatchResize(1000)
    }

    const {
      item: { id },
      exportData: { trigger = false, params: { pluginId = '' } = {} } = {}
    } = this.props
    const { exportData: { trigger: prevTrigger = false } = {} } = prevProps

    if (id === pluginId && trigger !== prevTrigger) {
      this.handleExport()
    }
  }

  dispatchResize(time = 250) {
    setTimeout(() => window.dispatchEvent(new Event('resize')), time)
  }

  render() {
    const {
      params: { catalogId, menuId, pageId, environment },
      isCopy = false,
      exportable = false,
      maximizable = false,
      showDeleteIcon = false,
      checkPermission,
      designMode = false,
      item: { id, type: pluginType = '', config: { general: { name = '' } = {} } = {} } = {},
      isPreviewMode
    } = this.props

    const pluginIdSliced = id.substr((id.length || 5) - 5)
    const pluginName = name === '' ? pluginType : name
    const isConfiguration = environment === 'Configuration'

    const { maximize } = this.state
    const noDisplay =
      !isPreviewMode &&
      ((isConfiguration && designMode) ||
        (!maximize && isConfiguration && designMode) ||
        exportable ||
        maximizable)
    if (!noDisplay) {
      return null
    }
    if (checkPermission.isEnv('Configuration').value && designMode === true) {
      return (
        <div className="widget-menu-wrapper">
          <div
            className={`widget-menu ${environment.toLowerCase()}-environment ${
              maximize ? 'maximize' : ''
            }`}
          >
            {!maximize && checkPermission.hasPermType(PermTypes.PluginEdit).value && (
              <div className="widget-menu-row">
                <a className="widget-menu-link" href="" onClick={this.handleRemove}>
                  <i className="fas fa-trash" />
                </a>
                <div className="-plugin-name">
                  {pluginName} - {pluginIdSliced}
                </div>
              </div>
            )}

            <div className="widget-menu-row">
              {!maximize && checkPermission.hasPermType(PermTypes.PluginEdit).value && (
                <a className="widget-menu-link" href="" onClick={this.handleCopy}>
                  {isCopy ? 'Remove from clipboard' : 'Copy'}
                </a>
              )}
              {!maximize && checkPermission.hasPermType(PermTypes.PluginEdit).value && (
                <Link
                  className="widget-menu-link text-center flex-grow-1"
                  to={`/${environment}/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin/${id}`}
                >
                  Settings
                </Link>
              )}
              {exportable && (
                <a className="widget-menu-link" href="" onClick={this.handleExport}>
                  Export
                </a>
              )}
              {maximizable && (
                <a
                  className="widget-menu-link"
                  href=""
                  onClick={maximize ? this.minimizeHandle : this.maximizeHandle}
                >
                  {maximize ? 'Restore' : 'Maximize'}
                </a>
              )}
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="widget-menu-wrapper">
        <div
          className={`widget-menu ${environment.toLowerCase()}-environment ${
            maximize ? 'maximize' : ''
          }`}
        >
          <div className="widget-menu-row">
            {!maximize && showDeleteIcon && isConfiguration && (
              <a className="widget-menu-link" href="" onClick={this.handleRemove}>
                <i className="fas fa-trash" />
              </a>
            )}
            {exportable && (
              <a className="widget-menu-link" href="" onClick={this.handleExport}>
                Export
              </a>
            )}
            {maximizable && (
              <a
                className="widget-menu-link"
                href=""
                onClick={maximize ? this.minimizeHandle : this.maximizeHandle}
              >
                {maximize ? 'Restore' : 'Maximize'}
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const getQueryState = (state, ownProps) => {
  return select(
    runPluginQuery(ownProps.item.id, {
      data: {
        id: ownProps.item.id,
        arguments: ownProps.args
      }
    }),
    state.model3
  )
}

const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)

const getQuerySelector = () => {
  return createDeepEqualSelector([getQueryState], (plugins) => plugins)
}

const makeMapStateToProps = () => {
  const query = getQuerySelector()

  const mapStateToProps = (state, ownProps) => {
    // Export is using the initial columns, even when the columns have been changed with setDataArguments.
    // To fix this, the query columns will be used to retrieve the latest columns.
    // TODO: Apply this fix to other plugins if no issues are encountered.
    const isReactDatatable = ownProps.item.type === 'ReactDatatable'

    const commonProps = {
      designMode: state.setting.designMode,
      checkPermission: permission(state.oidc.user, ownProps.params.environment),
      isCopy: _.findIndex(state.copy.copy, (o) => _.isEqual(o.id, ownProps.item.id)) !== -1,
      exportData: state.exportData
    }

    if (isReactDatatable) {
      return {
        ...commonProps,
        query: query(state, ownProps)
      }
    }

    return commonProps
  }

  return mapStateToProps
}

const mapDispatchToProps = {
  min,
  max,
  copyOrRemoveFromClipboard
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Menu)
