import { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { connect } from 'react-redux'
import { Row, Col, Modal, Button, Tab, Nav, Alert, InputGroup, FormControl } from 'react-bootstrap'

/* Helpers */
import { createSelectorCreator, lruMemoize } from 'reselect'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { GetPlugins, GetPluginConfig } from '../../../helpers/Plugins'

/* Crud */
import { selecOperationStatus, isSuccess } from '../../../crudoptV3'
import { createPlugin, removePlugin, clonePlugin, getPlugins } from '../../../actions/plugin'
import { createContainer, removeContainer, getContainers } from '../../../actions/container'
import { getUser } from '../../../actions/user'

import { copyOrRemoveFromClipboard } from '../../../store/slices/copy'
import { Permission } from '../../../components'

import permission, { PermTypes } from '../../../helpers/Permission'
import Template from './Template'
import SearchPlugins from './components/SearchPlugins'

import './index.scss'

class PluginList extends Component {
  constructor(props) {
    super(props)
    this.handleSelectPlugin = this.handleSelectPlugin.bind(this)
    this.handleSelectContainer = this.handleSelectContainer.bind(this)
    this.handleCreateElement = this.handleCreateElement.bind(this)
    this.handleRemoveElement = this.handleRemoveElement.bind(this)
    this.handlePasteElement = this.handlePasteElement.bind(this)
    this.handleAddElement = this.handleAddElement.bind(this)
    this.resetContainerName = this.resetContainerName.bind(this)

    this.containersList = [
      { name: 'popupcontainer', display: 'Popup' },
      { name: 'reflexcontainer', display: 'ReFlex' },
      { name: 'container', display: 'Simple' },
      { name: 'splitcontainer', display: 'Split' },
      { name: 'tabcontainer', display: 'Tab' },
      { name: 'wizardcontainer', display: 'Wizard' },
      { name: 'overlaycontainer', display: 'Overlay' }
    ]

    this.state = {
      containerId: 0,
      pluginList: GetPlugins(),
      containerName: '',
      user: {},
      ...this.getContainerState()
    }
  }

  getContainerState(current, status) {
    const result = {}

    this.containersList.forEach(({ name = '' }) => {
      result[`display_${name}`] = false
    })

    if (current) {
      result[`display_${current}`] = status
    }

    return result
  }

  handlePasteElement(id) {
    return () => {
      const {
        params: { catalogId, menuId, pageId }
      } = this.props
      this.props.clonePlugin('clone', catalogId, menuId, pageId, {
        pluginIds: [id]
      })
      this.props.copyOrRemoveFromClipboard({ id })
    }
  }

  handleToggle(namespace, status) {
    const result = this.getContainerState(namespace, status)
    let cloned = _.cloneDeep(this.state)
    cloned = {
      ...cloned,
      ...result
    }
    this.setState({
      ...cloned
    })
    this.resetContainerName()
  }

  resetContainerName() {
    this.setState({ containerName: '' })
  }

  onChangeContainerName(event) {
    this.setState({ containerName: event.target.value })
  }

  eventHandler(event) {
    event.stopPropagation()
  }

  handleCreateElement(namespace, version) {
    const {
      id,
      params: { catalogId, menuId, pageId }
    } = this.props
    const { containerName = '' } = this.state

    if (
      namespace === 'container' ||
      namespace === 'splitcontainer' ||
      namespace === 'tabcontainer' ||
      namespace === 'popupcontainer' ||
      namespace === 'reflexcontainer' ||
      namespace === 'wizardcontainer' ||
      namespace === 'overlaycontainer'
    ) {
      this.props.createContainer(`create${id}`, catalogId, menuId, pageId, {
        type: namespace,
        catalogId,
        menuId,
        pageId,
        version: '2.0',
        settings: {
          name: containerName
        }
      })
    } else {
      this.props.createPlugin(`create${id}`, catalogId, menuId, pageId, {
        catalogId,
        menuId,
        pageId,
        query: {},
        type: namespace,
        version,
        layout: {
          x: 0,
          y: Infinity,
          w: 3,
          h: 3
        }
      })
    }
  }

  handleSelectElement(id, type) {
    return () => {
      this.handleAddElement(id, type)
    }
  }

  executeCreateContainer(namespace, event) {
    event.preventDefault()
    this.handleCreateElement(namespace)
  }

  handleAddElement(id, type) {
    this.props.addElement(id, type)
    setTimeout(() => {
      this.props.onHide()

      const {
        params: { catalogId, menuId, pageId }
      } = this.props
      this.props.dispatch(getContainers(catalogId, menuId, pageId))
      this.props.dispatch(getPlugins(catalogId, menuId, pageId))
    }, 1000)
  }

  handleRemoveElement(id, type) {
    return () => {
      if (type === 'plugin') {
        confirmAlert({
          title: 'Deleting Plugin?',
          message: 'Are you sure you want to delete this?',
          buttons: [
            {
              label: 'Cancel'
            },
            {
              label: 'Confirm Delete',
              onClick: () => {
                const {
                  params: { catalogId, menuId, pageId }
                } = this.props
                this.props.removePlugin('remove', catalogId, menuId, pageId, id)
              }
            }
          ]
        })
      } else if (type === 'container') {
        confirmAlert({
          title: 'Deleting Container?',
          message: 'Are you sure you want to delete this?',
          buttons: [
            {
              label: 'Cancel'
            },
            {
              label: 'Confirm Delete',
              onClick: () => {
                const {
                  params: { catalogId, menuId, pageId }
                } = this.props
                this.props.removeContainer('delete', catalogId, menuId, pageId, id)
              }
            }
          ]
        })
      }
    }
  }

  handleSelectPlugin(namespace, version) {
    return (e) => {
      this.handleCreateElement(namespace, version)
    }
  }

  handleSelectContainer(namespace, version) {
    return () => {
      this.handleCreateElement(namespace, version)
    }
  }

  getPluginsByGroup(group) {
    const { pluginList } = this.state
    return _.filter(pluginList, ({ group: pluginGroup = 'general' }) =>
      _.isEqual(pluginGroup, group)
    )
  }

  componentDidMount() {
    const { userId, getUser } = this.props

    getUser(userId).then((user) => {
      this.setState({ user })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isSuccess(this.props.updatePageStatus, nextProps.updatePageStatus)) {
      this.props.addElement(nextProps.updatePageStatus.data.id, 'plugin')
      this.props.onHide()
    }
    if (isSuccess(this.props.cloneStatus, nextProps.cloneStatus)) {
      const { data } = nextProps.cloneStatus
      _.each(data, ({ id }) => {
        this.props.addElement(id, 'plugin')
      })

      this.props.onHide()
    }
    if (isSuccess(this.props.createContainerStatus, nextProps.createContainerStatus)) {
      this.props.addElement(nextProps.createContainerStatus.data.id, 'container')
      this.props.onHide()
    }
  }

  findUsedPlugins(containers, usedContainers) {
    let used = [...usedContainers]

    _.find(containers, (container) => {
      used = _.concat(used, container.elementIds)
    })

    return used
  }

  render() {
    const {
      show,
      plugins,
      containers,
      copy,
      inuse: { containers: inUsedContainers } = {},
      params
    } = this.props

    const { pluginList, containerName = '' } = this.state

    const containersList = [...this.containersList]

    // obsolete is checked for plugins that we do not want to add them to dashboard anymore.
    const filteredPluginList = _.filter(pluginList, (a) => !a.obsolete)
    const pluginListGroupByGroup = _.groupBy(
      _.sortBy(filteredPluginList, 'group'),
      ({ group = 'general' }) => _.capitalize(group)
    )

    const elementIds = this.findUsedPlugins(containers, inUsedContainers)

    const filteredContainers = _.filter(containers, (c) => !elementIds.includes(c.id))

    const filteredPlugins = _.filter(plugins, (c) => !elementIds.includes(c.id))
    const containersSize = _.size(filteredContainers)
    const pluginsSize = _.size(filteredPlugins)

    return (
      show && (
        <Modal
          className="inmodal add-plugin-popup"
          show={show}
          size="lg"
          onHide={this.props.onHide}
        >
          <Modal.Header closeButton>
            <div className="flex-column w-100">
              <i className="fa fa-puzzle-piece modal-icon" />
              <Modal.Title>Plugin Selection</Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            <SearchPlugins
              containersList={containersList}
              handleSelectedPlugin={this.handleCreateElement}
              pluginListGroupByGroup={pluginListGroupByGroup}
            />
            <Tab.Container
              defaultActiveKey={pluginsSize + containersSize <= 0 ? 'newone' : 'elements'}
              id="tabs-with-dropdown"
            >
              <Row className="clearfix">
                <Col sm={12}>
                  <Nav variant="tabs">
                    {this.props.checkPermission
                      .hasPermType(PermTypes.PluginAdd)
                      .isEnv('Configuration').value && (
                      <Nav.Item>
                        <Nav.Link eventKey="newone">New Element</Nav.Link>
                      </Nav.Item>
                    )}

                    {pluginsSize + containersSize > 0 && (
                      <Nav.Item>
                        <Nav.Link eventKey="elements">
                          Available Items ({pluginsSize + containersSize})
                        </Nav.Link>
                      </Nav.Item>
                    )}

                    {this.props.checkPermission
                      .hasPermType(PermTypes.PluginAdd)
                      .isEnv('Configuration').value && (
                      <Nav.Item>
                        <Nav.Link eventKey="clipboard">Clipboard Elements</Nav.Link>
                      </Nav.Item>
                    )}
                    {this.props.checkPermission
                      .hasPermType(PermTypes.PluginAdd)
                      .isEnv('Configuration').value && (
                      <Nav.Item>
                        <Nav.Link eventKey="template">Templates</Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    {this.props.checkPermission
                      .hasPermType(PermTypes.PluginAdd)
                      .isEnv('Configuration').value && (
                      <Tab.Pane className="pluginSelection" eventKey="newone">
                        <Tab.Container className="tabs-container" defaultActiveKey="Container">
                          <Row className="clearfix">
                            <Col className="tabs-left d-flex" sm={12}>
                              <Nav className="flex-column" variant="tabs">
                                <Nav.Item key="Container" className="nav-tab-item">
                                  <Nav.Link className="text-black-50" eventKey="Container">
                                    Container ({containersList.length})
                                  </Nav.Link>
                                </Nav.Item>
                                {_.map(pluginListGroupByGroup, (group, key) => (
                                  <Nav.Item key={key} className="nav-tab-item">
                                    <Nav.Link className="text-black-50" eventKey={key}>
                                      {key} ({_.size(group)})
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                              <Tab.Content className="flex-grow-1">
                                <Tab.Pane key="Container" eventKey="Container">
                                  <div className="card-body p-3">
                                    <div className="table-responsive">
                                      <table className="table table-hover issue-tracker">
                                        <tbody>
                                          {_.map(containersList, (container, key) => (
                                            <tr key={`container${key}`}>
                                              <td>
                                                <span className="fa fa-list fa-2x" />
                                              </td>
                                              <td className="issue-info alert-wrapper position-relative">
                                                <strong>{container.display}</strong>
                                                <small />
                                                {this.state[`display_${container.name}`] ? (
                                                  <Alert className="container-name-alert p-1 position-absolute d-flex end-0">
                                                    <form
                                                      className="w-100"
                                                      onSubmit={this.executeCreateContainer.bind(
                                                        this,
                                                        container.name
                                                      )}
                                                    >
                                                      <div className="d-flex">
                                                        <InputGroup>
                                                          <FormControl
                                                            className="alert-form-control"
                                                            placeholder="Enter a name"
                                                            size="sm"
                                                            type="text"
                                                            value={containerName}
                                                            onChange={this.onChangeContainerName.bind(
                                                              this
                                                            )}
                                                          />
                                                          <Button
                                                            size="sm"
                                                            type="submit"
                                                            variant="outline-dark"
                                                            onClick={this.eventHandler}
                                                          >
                                                            <span className="slvy-ui-icon-check-circle-regular text-success" />
                                                          </Button>
                                                          <Button
                                                            size="sm"
                                                            variant="outline-dark"
                                                            onClick={this.handleToggle.bind(
                                                              this,
                                                              container.name,
                                                              false
                                                            )}
                                                          >
                                                            <span className="slvy-ui-icon-times-circle-regular text-danger" />
                                                          </Button>
                                                        </InputGroup>
                                                      </div>
                                                    </form>
                                                  </Alert>
                                                ) : null}
                                              </td>
                                              <td>
                                                <Button
                                                  size="sm"
                                                  variant="success"
                                                  onClick={this.handleToggle.bind(
                                                    this,
                                                    container.name,
                                                    true
                                                  )}
                                                >
                                                  Create
                                                </Button>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>
                                {_.map(pluginListGroupByGroup, (plugins, key) => (
                                  <Tab.Pane key={key} eventKey={key}>
                                    <div className="card-body p-3">
                                      <div className="table-responsive">
                                        <table className="table table-hover issue-tracker">
                                          <tbody>
                                            {_.map(
                                              _.sortBy(plugins, 'title'),
                                              ({
                                                title,
                                                namespace,
                                                version,
                                                iconClass,
                                                description
                                              }) => (
                                                <tr
                                                  key={`plugins${namespace}`}
                                                  className={cx({
                                                    'bg-warning opacity-75 d-none':
                                                      namespace === 'SenchaGrid'
                                                  })}
                                                >
                                                  <td>
                                                    <span className={`fa ${iconClass} fa-2x`} />
                                                  </td>
                                                  <td className="issue-info">
                                                    <strong>{title}</strong>
                                                    <small>{description}</small>
                                                  </td>
                                                  <td>
                                                    <Button
                                                      size="sm"
                                                      variant="success"
                                                      onClick={this.handleSelectPlugin(
                                                        namespace,
                                                        version
                                                      )}
                                                    >
                                                      Add
                                                    </Button>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                ))}
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </Tab.Pane>
                    )}

                    <Tab.Pane eventKey="elements">
                      <table className="table table-hover issue-tracker">
                        <tbody>
                          {_.map(
                            filteredPlugins,
                            ({ id, type, config: { general: { name = '' } = {} } = {} }) => {
                              const config = GetPluginConfig(type)
                              const slicedId = id.slice(-5)
                              if (config) {
                                const { title, iconClass, description } = config
                                return (
                                  <tr key={`plugins${id}`}>
                                    <td>
                                      <span className={`fa ${iconClass} fa-2x`} />
                                    </td>
                                    <td className="issue-info">
                                      <strong>
                                        {name} @{title}
                                      </strong>
                                      <small>{description}</small>
                                    </td>
                                    <td>
                                      <span
                                        className="bg-secondary rounded-pill p-2 py-1 text-white"
                                        title={id}
                                      >
                                        ...{slicedId}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="success"
                                        onClick={this.handleSelectElement(id, 'plugin')}
                                      >
                                        Select
                                      </Button>
                                    </td>
                                    <td>
                                      <Permission has={['Plugin.Edit']}>
                                        <Button
                                          size="sm"
                                          variant="danger"
                                          onClick={this.handleRemoveElement(id, 'plugin')}
                                        >
                                          Remove
                                        </Button>
                                      </Permission>
                                    </td>
                                  </tr>
                                )
                              }
                            }
                          )}
                          {_.map(
                            filteredContainers,
                            ({ id, type = 'container', settings: { name = '' } = {} } = {}) => {
                              if (!id) return null
                              const slicedId = id.slice(-5)
                              return (
                                <tr key={`containers${id}`}>
                                  <td>
                                    <span className="fa fa-archive fa-2x" />
                                  </td>
                                  <td className="issue-info">
                                    <strong>
                                      {name} @Container ({type})
                                    </strong>
                                    <small />
                                  </td>
                                  <td>
                                    <span
                                      className="bg-secondary rounded-pill p-2 py-1 text-white"
                                      title={id}
                                    >
                                      ...{slicedId}
                                    </span>
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      variant="success"
                                      onClick={this.handleSelectElement(id, 'container')}
                                    >
                                      Select
                                    </Button>
                                  </td>
                                  <td>
                                    <Permission has={['Plugin.Edit']}>
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={this.handleRemoveElement(id, 'container')}
                                      >
                                        Remove
                                      </Button>
                                    </Permission>
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    </Tab.Pane>

                    {this.props.checkPermission
                      .hasPermType(PermTypes.PluginAdd)
                      .isEnv('Configuration').value && (
                      <Tab.Pane eventKey="clipboard">
                        <table className="table table-hover issue-tracker">
                          <tbody>
                            {_.map(copy, ({ id, type }) => {
                              const config = GetPluginConfig(type)
                              if (config) {
                                const { title, iconClass, description } = config
                                return (
                                  <tr key={`plugins${id}`}>
                                    <td>
                                      <span className={`fa ${iconClass} fa-2x`} />
                                    </td>
                                    <td className="issue-info">
                                      <strong>{title}</strong>
                                      <small>{description}</small>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="success"
                                        onClick={this.handlePasteElement(id)}
                                      >
                                        Paste
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                          </tbody>
                        </table>
                      </Tab.Pane>
                    )}
                    {this.props.checkPermission
                      .hasPermType(PermTypes.PluginAdd)
                      .isEnv('Configuration').value && (
                      <Tab.Pane eventKey="template">
                        <Template
                          addElement={this.handleAddElement}
                          catalogId={params.catalogId}
                          menuId={params.menuId}
                          pageId={params.pageId}
                        />
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      )
    )
  }
}

const cloneOperationStatusState = (state) => {
  return selecOperationStatus('plugin', 'clone', state.model3)
}
const layoutOperationStatusState = (state, ownProps) => {
  return selecOperationStatus('plugin', `create${ownProps.id}`, state.model3)
}
const containerOperationStatusState = (state, ownProps) => {
  return selecOperationStatus('container', `create${ownProps.id}`, state.model3)
}

const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)

const layoutOperationStatusSelector = () => {
  return createDeepEqualSelector([layoutOperationStatusState], (x) => x)
}
const cloneOperationStatusSelector = () => {
  return createDeepEqualSelector([cloneOperationStatusState], (x) => x)
}
const containerOperationStatusSelector = () => {
  return createDeepEqualSelector([containerOperationStatusState], (x) => x)
}

const makeMapStateToProps = () => {
  const cloneOperationStatus = cloneOperationStatusSelector()
  const updateLayoutStatus = layoutOperationStatusSelector()
  const containerOperationStatus = containerOperationStatusSelector()
  const mapStateToProps = (state, ownProps) => {
    const {
      profile: { sub: userId }
    } = state.oidc.user

    return {
      userId,
      checkPermission: permission(state.oidc.user, ownProps.params.environment),
      inuse: state.pluginlist,
      copy: state.copy.copy,
      cloneStatus: cloneOperationStatus(state, ownProps),
      createContainerStatus: containerOperationStatus(state, ownProps),
      updatePageStatus: updateLayoutStatus(state, ownProps)
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, (dispatch) => ({
  dispatch,
  getUser: bindActionCreators(getUser, dispatch),
  createPlugin: bindActionCreators(createPlugin, dispatch),
  removePlugin: bindActionCreators(removePlugin, dispatch),
  createContainer: bindActionCreators(createContainer, dispatch),
  removeContainer: bindActionCreators(removeContainer, dispatch),
  clonePlugin: bindActionCreators(clonePlugin, dispatch),
  copyOrRemoveFromClipboard: bindActionCreators(copyOrRemoveFromClipboard, dispatch)
}))(PluginList)
