export default `({
    plugins: [{
        ptype: 'cornerIcon',
        extraButton: [
            '->',
            {
                iconCls: 'x-fa fa-arrows-alt',
                handler: this.onmaximize,
                scope: this
            }
        ],
        cfgButton: [{
            iconCls: 'x-fa fa-cog',
            cls: 'config-btn',
            handler: 'onConfigureSpotGrid',
        }]
    },
    {
        ptype: 'cellediting',
        clicksToEdit: 1,
        listeners: {
            beforeedit: function (editor, context) {
                rec = context.record;
                return rec.get('IsEditable');
            },
            edit: function (editor, context, eOpts) {
                if (context.originalValue == context.value)
                    return;
                var record = context.record,
                    grid = context.grid.ownerCt
                //    grid.setLoading('Saving');
                record.save({
                    params: {
                        updateAction: 'DCValueChange'
                    },
                    failure: function (record, operation) { },
                    success: function (record, operation) {
                        grid.setLoading(false)
                        //  grid.store.load()
                    },
                    callback: function (record, operation, success) {
                        // do something whether the save succeeded or failed
                    }
                });
            },
            scope: 'this'
        }
    },
    {
        ptype: 'rowexpander',
        rowBodyTpl: [
            '<div class="expander">',
            '<table class="table">',
            '<tr>',
            '</tr>',
            '  <tr>',
            '       <th class=r>'+tt('Merkez')+'</th>',
            '      <td class="r">{OrderRecommendationParent}</td>',
            '       <th class="r">'+tt('Bölge')+'</th>',
            '      <td class="r">{OrderRecommendationCurrent}</td>',
            '      <th class="r">'+tt('Satış Md.')+'</th>',
            '      <td class="r">{OrderRecommendationChild}</td>',
            '   </tr>',
            '</table>',
            '</div>',
        ]
    },{
          ptype: 'gridfilters',
          id: 'gridfilters'
    }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    multiColumnSort: true,
    columns: [{
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        width: 30,
        align: 'center',
        items: [{
            iconCls: 'x-fa fa-outdent',
            tooltip: tt('Dağıtım Öneri'),
            handler: function (grid, rowIndex, colIndex, item, e, record) {
                var me = this;
                var tracking = me.up('spui-view-tracking-navigation-main')
                var win = Ext.create('Spui.uxWindow', {
                    height: '25%',
                    spotId: record.get('SpotId'),
                    title: tt('Öneri Dağıtım'),
                    close: function(){
                        tracking.onRefrehTracking()
                        this.doClose()
                    },
                    uxItemCfg: 'changeoneriwin',
                    bodyPadding: 2,
                    modal: true,
                    onClickdata: function (item) {
                        var me = this,
                            vieModel = me.getViewModel(),
                            newValue = item.sendValue,
                            calculateType = item.calculateType;
                        me.onClickdataRequest(newValue, calculateType);
                    },
                    onClickdataRequest: function (newValue, calculateType) {
                        var spotId = this.spotId;
                        var me = this;
                        me.setLoading(tt('yükleniyor'));
                        Ext.Ajax.request({
                            url: Spui.Global.getApiUrl() + '/spui/recalculateoneri',
                            method: 'POST',
                            timeout: 600000,
                            params: {
                                SpotId: spotId,
                                newValue: newValue,
                                calculateType: calculateType
                            },
                            success: function (response, opts) {
                                var obj = Ext.decode(response.responseText);
                                me.setLoading(false);
                                var grid = me.query('grid')[2]
                                store = grid.getStore();
                                store.load();
                                //me.reDrawChart(obj);
                                // me.loadUserSettings();
                            },
                            failure: function (response, opts) {
                                me.setLoading(false);
                                console.log('server-side failure with status code ' + response.status);
                            }
                        });
                    },
                    onReCalcuulate: function (field, f, e, g) {
                        var value = field.getValue();
                        var menu = Ext.create('Ext.menu.Menu', {
                            margin: '0 0 10 0',
                            items: [{
                                text: tt('Satış Profili'),
                                handler: 'onClickdata',
                                scope: this,
                                calculateType: 1,
                                sendValue: value
                            }, {
                                text: tt('Min-Max'),
                                handler: 'onClickdata',
                                scope: this,
                                calculateType: 2,
                                sendValue: value
                            },
                            {
                                text: tt('Fixed Quantity'),
                                handler: 'onClickdata',
                                scope: this,
                                calculateType: 3,
                                sendValue: value
                            }
                            ]
                        });
                        menu.showAt(e.getXY());
                    },
                    viewModel: {
                        data: {
                            record: record,
                        }
                    },
                    controller: {
                        onSelectWeek: function () {
                            alert(ok);
                        },
                        onExportExcel: function () {
                            alert(ok);
                        },
                        listen: {
                            component: {
                                'uxchart': {
                                    clickChart: function (record) {
                                        grid = this.lookup('haftalikgrid');
                                        chart = this.lookup('chart2');
                                        if (!grid) return;
                                        var store = grid.store
                                        this.getViewModel().set('record', record)
                                        proxy = store.getProxy();
                                        data = Ext.encode(record.data);
                                        proxy.setExtraParam('data', data);
                                        store.load();
                                        var store = chart.store
                                        proxy = store.getProxy();
                                        proxy.setExtraParam('data', data);
                                        store.load();
                                    }
                                }
                            }
                        },
                    },
                    width: 1380,
                    layout: 'fit'
                });
                win.show();
            },
        }]
    },
    {
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        // locked: true,
        width: 30,
        align: 'center',
        items: [{
            iconCls: 'x-fa fa-thumbs-up blue',
            isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                return !rec.get('IsEditable');
            },
            handler: function (grid, rowIndex, colIndex, item, e, record) {
                var me = grid.ownerCt;
                Ext.Msg.show({
                    title: tt('Onay'),
                    width: 500,
                    msg: tt('Are you sure you want to approve this record? <br><br>'),
                    buttonText: {
                        yes: 'Devral ve Onayla',
                        no: 'Onayla',
                        cancel: 'Hayır'
                    },
                    buttons: Ext.Msg.YESNOCANCEL,
                    icon: Ext.MessageBox.QUESTION,
                    fn: function (response) {
                        if (response === 'yes') {
                            me.onChangeStatus(record, 'Approve')
                        }
                        if (response === 'no') {
                            me.onChangeStatus(record, 'Approve')
                        }
                    }
                })
            },
        }]
    },
    {
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        width: 30,
        align: 'center',
        items: [{
            iconCls: 'x-fa  fa-thumbs-down red',
            isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                return !rec.get('IsEditable');
            },
            handler: function (grid, rowIndex, colIndex, item, e, record) {
                var me = grid.ownerCt;
                Ext.Msg.show({
                    title: tt('Onay'),
                    width: 500,
                    msg: tt('Are you sure you want to cancel this record?'),
                    buttonText: {
                        yes: tt('Evet'),
                        no: tt('Hayır')
                    },
                    buttons: Ext.Msg.YESNO,
                    icon: Ext.MessageBox.QUESTION,
                    fn: function (response) {
                        if (response === 'yes') {
                            me.onChangeStatus(record, 'Dismiss')
                        }
                    }
                })
            },
        }]
    },
    {
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        // locked: true,
        width: 30,
        align: 'center',
        items: [{
            iconCls: 'fa fa-stack-overflow',
            isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                return !rec.get('IsEditable');
            },
            handler: function (grid, rowIndex, colIndex, item, e, record) {
                var me = grid.ownerCt;
                Ext.Msg.show({
                    title: tt('Onay'),
                    width: 500,
                    msg: tt('Are you sure you want to take over this record?'),
                    buttonText: {
                        yes: tt('Evet'),
                        no: tt('Hayır')
                    },
                    buttons: Ext.Msg.YESNO,
                    icon: Ext.MessageBox.QUESTION,
                    fn: function (response) {
                        if (response === 'yes') {
                            me.onChangeOverRecommendation(record, 'Sub Offers')
                        }
                    }
                })
            },
        }]
    },
    {
        text: tt('MaterialCode'),
        dataIndex: 'MaterialCode',
        width: 100,
        filter: {
            type: 'string'
        }
    },
    {
        text: tt('MaterialDescription'),
        filter: {
            type: 'string'
        },
        dataIndex: 'MaterialDescription',
        width: 200
    },
    {
        text: tt('Start Date'),
        dataIndex: 'PreStartDate',
        filter: {
            type: 'date'
        },
        xtype: 'datecolumn',
        format: tt('d-m-Y'),
        width: '90'
    },
    {
        text: tt('End Date'),
        dataIndex: 'PreEndDate',
        filter: {
            type: 'date'
        },
        xtype: 'datecolumn',
        format: tt('d-m-Y'),
        width: '90'
    },
    {
        text: tt('RemainingDaysForApprove'),
        dataIndex: 'RemainingDaysForApprove',
        width: 100,
        format: '000',
        align: 'right',
        filter: {
            type: 'number'
        },
        renderer: function (value, metaData, record) {
            var DaysForApprove = record.get('DaysForApprove');
            return value + " / " + DaysForApprove
        }
    },{
        text: tt('UnitsPerPackage'),
        dataIndex: 'UnitsPerPackage',
        width: 100,
        format: '000.00',
        align: 'right',
        filter: {
            type: 'number'
        },
        xtype: 'numbercolumn'
    },
    {
        text: tt('Status'),
        columns: [{
            text: tt('Total'),
            tooltip: tt('TotalTip'),
            dataIndex: 'NumberOfRecommendedOrg',
            width: 80,
            format: '000',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('Waiting'),
            tooltip: tt('WaitingTip'),
            dataIndex: 'NumberOfWaitingOrg',
            width: 80,
            filter: {
                type: 'number'
            },
            format: '000',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('Delegated'),
            dataIndex: 'NumberOfDelegatedOrg',
            width: 80,
            format: '000',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('Approved'),
            dataIndex: 'NumberOfApprovedOrg',
            width: 80,
            format: '000',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('Dismissed'),
            dataIndex: 'NumberOfDismissedOrg',
            width: 80,
            filter: {
                type: 'number'
            },
            format: '000',
            align: 'right',
            xtype: 'numbercolumn',
        },
        ]
    }
    ],
    onAccept: function () { },
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        me = this;
        var msg = tt('Are you sure you want to dismiss this record? <br><br>')
        if (newStatus === 'Approve') {
            msg = tt('Are you sure you want to approve this record? <br><br>')
        }
        Ext.Msg.show({
            title: tt('Onay'),
            //height: 160,
            width: 500,
            msg: msg,
            icon: Ext.MessageBox.QUESTION,
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            buttons: Ext.Msg.YESNO,
            fn: function (response) {
                if (response === 'yes') {
                    me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));
                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                        //             ALTER PROCEDURE [www].[ChangeOverRecommendation]
                        //   @SpotID AS INT ,
                        //   -- @UserID AS INT = NULL,
                        //     @Orgcode AS NVARCHAR(18)=NULL
                        method: 'POST',
                        timeout: 60000,
                        params: {
                            data: data,
                            newStatus: newStatus
                        },
                        success: function (response, opts) {
                            //me.up('spui-view-main-main').getController().changeView();
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                        },
                        failure: function (response, opts) {
                            console.log('server-side failure with status code ' + response.status);
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                        }
                    });
                }
            }
        });
    },
    onChangeOverRecommendation: function (record, newStatus) {
        var data = Ext.encode(record.data);
        me = this;
        me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));
        Ext.Ajax.request({
            url: Spui.Global.getApiUrl() + '/spui/changeOverRecommendation',
            method: 'POST',
            params: {
                data: data,
                newStatus: newStatus
            },
            success: function (response, opts) {

                var mainView = null,
                    recommView = null,
                    viewModel = null,
                    store = null
                mainview = me.up('spuimainview')
                if (mainview) {
                    recommView = mainview.down('spui-view-common-recom-distribution-main')
                    if (recommView) {
                        viewModel = recommView.getViewModel()
                        if (viewModel) {
                            store = viewModel.get('recomStore')
                        }
                    }
                    else
                    {
                        recommView = mainview.down('spui-view-common-recom-main')
                        if (recommView) {
                            store = recommView.getRecomStore()
                        }
                    }
                    if (store) {
                        store.load()
                    }
                }
                me.up('spui-view-tracking-tracking').setLoading(false);
                //me.up('spui-view-main-main').getController().changeView();
            },
            failure: function (response, opts) {
                console.log('server-side failure with status code ' + response.status);
            }
        });
    },
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        me = this;
        me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));
        Ext.Ajax.request({
            url: Spui.Global.getApiUrl() + '/spui/changeStatus',
            method: 'POST',
            timeout: 60000,
            params: {
                data: data,
                newStatus: newStatus
            },
            success: function (response, opts) {
                me.up('spui-view-tracking-tracking').setLoading(false);
                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
            },
            failure: function (response, opts) {
                console.log('server-side failure with status code ' + response.status);
                me.up('spui-view-tracking-tracking').setLoading(false);
                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
            }
        });
    }
})
    `
