import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import viewModeOptions from '../../viewModeOptions'
import HeaderUpdateModes from './HeaderUpdateModes'
import UpdateForm from '../UpdateForm/index'
import { saveHeaderSettings } from '../../store/actions'

interface IViewModeProps {
  buttonFormat: 'absolute' | 'percentage'
  externalUpdateEnable: boolean
  isEditModeAllowed: boolean
  isVisiblePivotView: boolean
  isWorkInSubsetEnabled: boolean
  mode: number
  rowUpdateEnable: boolean
  updateMode: number
  updateModes: {
    isVisibleCellUpdate: boolean
    isVisiblePopupUpdate: boolean
    isVisibleRowUpdate: boolean
  }
}

function ViewModes(props: IViewModeProps) {
  const dispatch = useDispatch()

  const {
    buttonFormat,
    externalUpdateEnable,
    isEditModeAllowed,
    isVisiblePivotView,
    isWorkInSubsetEnabled,
    mode,
    rowUpdateEnable,
    updateMode,
    updateModes: { isVisibleCellUpdate, isVisiblePopupUpdate, isVisibleRowUpdate } = {}
  } = props

  useEffect(() => {
    isWorkInSubsetEnabled && viewModeHandler(viewModeOptions.at(-1)!.value)
  }, [])

  function viewModeHandler(mode: string | number) {
    dispatch(
      saveHeaderSettings({
        payload: { mode: Number(mode) }
      })
    )
  }

  const modeButtons = [
    {
      modeId: 0,
      name: 'Cell',
      isVisible: Boolean(isVisibleCellUpdate)
    },
    {
      modeId: 1,
      name: 'Row',
      isVisible: Boolean(isVisibleRowUpdate && rowUpdateEnable && isVisiblePivotView)
    },
    {
      modeId: 2,
      name: 'Popup',
      isVisible: Boolean(isVisiblePopupUpdate && externalUpdateEnable)
    }
  ]

  return (
    <ul className="-view-modes d-flex">
      {isEditModeAllowed
        ? viewModeOptions.map(({ value, name }, index) => {
            const isEdit = index === 1
            const isCurrentModeEdit = mode === 2
            const isEditMode = isEdit && isCurrentModeEdit
            return [
              <li key={index} className="action-toggleMimic">
                <input
                  checked={value === mode}
                  id={`rdb_${index}`}
                  name="viewmode"
                  type="radio"
                  onChange={() => {}} // do not remove for usage of check attr
                  onClick={() => viewModeHandler(value)}
                />
                <label className={isEdit ? 'off' : 'on'} htmlFor={`rdb_${index}`}>
                  {name}
                </label>
              </li>,
              isEditMode ? (
                <li key={`${index}_update`} className="-edit-menu">
                  <i className="slvy-ui-icon-dots-vertical" />
                  <div className="-edit-menu-box">
                    <UpdateForm buttonFormat={buttonFormat} />
                    <HeaderUpdateModes modeButtons={modeButtons} updateMode={updateMode} />
                  </div>
                </li>
              ) : null
            ]
          })
        : null}
    </ul>
  )
}

export default ViewModes
