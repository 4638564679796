const defaultActiveMetricWithValue = {
  AbsoluteDiff: 0,
  IsApproved: 0,
  IsLocked: 0,
  Name: '',
  PercentageDiff: 0,
  ShowSplitValue: true,
  SplitValue: 0,
  MissingRow: false
}

export { defaultActiveMetricWithValue }
