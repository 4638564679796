import { useState } from 'react'
import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { SuggestionsContainer } from '../../../../../../../components'
import SlvyAutosuggest from '../../../../../../../components/SlvyAutosuggest'
import sortByKey from '../../../../../../../utils/sortByKey'
import { ISearchCallbackParams } from '../../../../Assignees/Assignees.types'
import ContainerHeader from '../../../../Assignees/components/ContainerHeader'
import NotFound from '../../../../Assignees/components/NotFound'
import NoVariables from '../../../../Assignees/components/NoVariables'
import EventMethodButton from '../../components/EventMethodButton'
import { bodyContentHeight, searchOption } from '../../../../Assignees/constants'
import { convertDataForSearch, getFilteredWaitEvents } from '../../../../Assignees/helpers'
import { IContainer, IRegisterEventMethod, TcountInfo } from '../../../../Common.types'
import { getClassName, getMethodsCount } from '../../helpers'
import { IContainersMethodProps } from './ContainersMethod.types'

const leadText = (
  <>
    If you are looking for a specific container please enter the container <strong>name</strong>{' '}
    e.g. popupcontainer
  </>
)

const ContainersMethod = ({
  containers,
  handleAddItem,
  containerMethodKeys
}: IContainersMethodProps) => {
  const [searchOptions, setSearchOptions] = useState({ ...searchOption })

  const handleSuggestionSelected = ({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: true
    })
  }

  const handleSuggestionNotFound = ({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: true,
      isSelected: true
    })
  }

  const handleQueryChange = ({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: false
    })
  }

  const mContainers: IContainer[] = containers.filter((container) => container?.registers?.methods)

  const filteredContainers: IContainer[] = getFilteredWaitEvents(mContainers, searchOptions)

  const searchData = convertDataForSearch(filteredContainers)

  const isNotFound = searchOptions.query && searchOptions.notFound

  const renderSuggestionsContainer = (suggestionsContainerProps: any) => (
    <SuggestionsContainer
      {...suggestionsContainerProps}
      containerStyle={{ overflowY: 'auto', maxHeight: bodyContentHeight - 50 }}
    />
  )

  const countInfos: TcountInfo[] = filteredContainers.map((container) => {
    const { id, registers: { methods = {} } = {} } = container
    const sortedMethods = sortByKey(methods) as IRegisterEventMethod
    const { active, total } = getMethodsCount(containerMethodKeys, sortedMethods, id, 'container')
    return { active, total, id }
  })

  return (
    <>
      <SlvyAutosuggest
        data={searchData}
        placeholder="Search"
        renderSuggestionsContainer={renderSuggestionsContainer}
        size="sm"
        onQueryChange={handleQueryChange}
        onSuggestionNotFound={handleSuggestionNotFound}
        onSuggestionSelected={handleSuggestionSelected}
      />
      {isNotFound ? (
        <NotFound leadText={leadText} query={searchOptions.query} tags={[]} />
      ) : (
        <div className="overflow-y-auto pe-1" style={{ height: bodyContentHeight - 65 }}>
          <Accordion
            alwaysOpen
            data-testid="containers-method"
            defaultActiveKey={countInfos.filter((item) => item.active).map((item) => item.id)}
          >
            {_.map(filteredContainers, (container) => {
              const {
                id,
                type,
                settings: { name = '' } = {},
                registers: { methods } = {}
              } = container

              const sortedMethods = sortByKey(methods)
              const { active, total } = countInfos.find((item) => item.id === id) || {}
              const title = <h6>{`${name}@${type}`}</h6>

              return (
                <Accordion.Item key={uuidv4()} eventKey={id}>
                  <Accordion.Header className="mb-0 pb-0">
                    <ContainerHeader active={active} title={title} total={total} type="" />
                  </Accordion.Header>
                  <Accordion.Body className="p-3">
                    {total ? null : <NoVariables text="No methods are found!" />}
                    <div className="d-flex flex-wrap gap-2">
                      {_.map(sortedMethods, (_method, key) => {
                        const classParams = {
                          id,
                          methodKey: key
                        }
                        const variant = getClassName(containerMethodKeys, classParams)
                        return (
                          <EventMethodButton
                            key={id + key}
                            text={key}
                            variant={variant}
                            onClick={() => handleAddItem('containerMethodKeys', classParams)}
                          />
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        </div>
      )}
    </>
  )
}

export default ContainersMethod
