import cx from 'classnames'
import { useReduxState } from '../../hooks'
import { sidebarSlice, viewContainerSlice } from '../../store/slices'
import AppControls from './AppControls'
import IconButton from '../IconButton'
import ServiceTasks from './ServiceTasks'
import SessionForm from './SessionForm'
import UserInfo from './UserInfo'
import SavePlanModal from './SavePlanModal'
import styles from './Header.module.scss'
import SnapshotModal from './SnapshotModal'

export default function Header() {
  const [sidebar, dispatchSidebar] = useReduxState(sidebarSlice.selectSlice, sidebarSlice.actions)
  const [viewContainer] = useReduxState(viewContainerSlice.selectSlice)

  const isTreeTableView = viewContainer.currentView === 'Tree Table'

  function handleClickCloseSidebar() {
    dispatchSidebar.toggleIsOpen()
  }

  return (
    <div className={styles.header}>
      <section
        className={cx(styles.headerLeftSection, {
          [styles.isHidden]: isTreeTableView
        })}
      >
        {sidebar.isOpen ? <span className={styles.appTitle}>Plan Analysis Report</span> : null}
        {!isTreeTableView ? (
          <IconButton className={styles.closeSidebarBtn} onClick={handleClickCloseSidebar}>
            <i className="fa fa-fw fa fa-navicon" />
          </IconButton>
        ) : null}
      </section>
      <section className={styles.headerRightSection}>
        <div className={styles.userInfo}>
          <ServiceTasks />
          <UserInfo />
        </div>
        <SessionForm />
        <div className={styles.formControls}>
          <AppControls />
          <SavePlanModal />
          <SnapshotModal />
        </div>
      </section>
    </div>
  )
}
