/* global Ext, _, tuiv2 */

Ext.define('tuiv2.tuiv2MainController', {
  extend: 'tuiv2.tuiv2MainControllerAjax',
  alias: 'controller.tuiv2MainController',
  control: {
    mainmap: {
      layerclick: 'onLayerClick',
      markercontextmenu: 'onMarkerContextMenu',
      managePopUp: 'onManagePopUp',
      freeDrawMultiSelect: 'onFreeDrawMultiSelect',
      demandlayerclick: 'onDemandlayerclick'
    }
  },

  popups: {},

  init: function () {
    this.view.onInitializeRef(this)
    var mainView = this.getView('tuiv2mainview')
    var defaultViewTui = mainView.defaultViewTui
    const isGrid = defaultViewTui === 'Grid'
    const isNone = defaultViewTui === 'Tree Grid'

    if (isGrid || isNone) {
      const viewId = isGrid ? 1 : 2
      this.onChangeView(null, { viewId })
    }
  },

  onLoadStoreBeforeLoad: function (store) {
    var me = this

    var viewModel = me.getViewModel()

    var loadTreeColumns = viewModel.get('loadTreeColumns')

    var loadTreeHierarchy = viewModel.get('loadTreeHierarchy')

    var loadTreeFilters = viewModel.get('loadTreeFilters')

    var proxy = store.getProxy()

    if (tuiv2.Global.getLoadHierarchyIsEmpty()) {
      proxy.setExtraParam('Hierarchy', Ext.emptyArray)
    } else {
      if (tuiv2.Global.getLoadTreeStructure().length > 0) {
        proxy.setExtraParam('Hierarchy', JSON.stringify(tuiv2.Global.getLoadTreeStructure()))
      } else {
        proxy.setExtraParam('Hierarchy', JSON.stringify(loadTreeHierarchy))
      }
    }
    proxy.setExtraParam('Columns', JSON.stringify(loadTreeColumns))
    proxy.setExtraParam('Filters', JSON.stringify(loadTreeFilters))
    proxy.setExtraParam('TreeType', 0)

    return true
  },

  getLeafParentNodeOnSelectedNode: function (node) {
    var leafParentNodeCount = []
    var recursiveFindLeafParentNode = function (node) {
      if (node && node.firstChild) {
        if (node.firstChild && node.firstChild.isLeaf()) {
          leafParentNodeCount.push(node)
        } else {
          _.forEach(node.childNodes, (childNode) => {
            recursiveFindLeafParentNode(childNode)
          })
        }
      }
    }
    recursiveFindLeafParentNode(node)

    return leafParentNodeCount
  },

  getNodesByTreeDepth: function (rootNode, depth) {
    var resultArray = []
    var recursiveFunc = function (node, depth) {
      if (node) {
        if (node.getDepth() == depth) {
          resultArray.push(node)
        } else if (node.childNodes) {
          _.forEach(node.childNodes, (childNode) => {
            recursiveFunc(childNode, depth)
          })
        }
      }
    }
    recursiveFunc(rootNode, depth)

    return resultArray
  },

  setDistinctColorsForTreeLeafParentNodes: function (tree, treeStructure) {
    var mainView = this.getView('tuiv2mainview')
    if (tree) {
      var definedColors = tuiv2.Colors.getDefinedColors()
      var treeRootNode = tree.getRootNode()
      var selectedDistinctColorFields = _.filter(treeStructure, ['isTreeDistinctColorField', true])
      var treeDistinColorDepth =
        selectedDistinctColorFields < 0
          ? 0
          : _.indexOf(treeStructure, _.last(selectedDistinctColorFields)) + 1
      var treeDistinctColorNodes = this.getNodesByTreeDepth(treeRootNode, treeDistinColorDepth)
      var treeDistinctColorNodesWithLeafParentNodes = []

      _.forEach(treeDistinctColorNodes, (treeDistinctColorNode) => {
        var leafParenNode = this.getLeafParentNodeOnSelectedNode(treeDistinctColorNode)
        treeDistinctColorNodesWithLeafParentNodes.push(leafParenNode)
      })

      _.forEach(treeDistinctColorNodesWithLeafParentNodes, (distinctColorParentNode) => {
        var distinctColorArray = mainView.GetDistinctColors(distinctColorParentNode.length)
        _.forEach(distinctColorParentNode, (leafNode, key) => {
          leafNode.cascadeBy(function (rec, idx) {
            var nodeId = rec.get('ID')
            definedColors[nodeId] = distinctColorArray[key]
          })
        })
      })

      //Refresh popups color
      _.forEach(this.popups, (popup) => {
        popup.tpl.refresh()
      })

      tuiv2.Colors.setDefinedColors(definedColors)
    }
  },

  setMapTile: function () {
    var mainView = this.getView('tuiv2mainview')
    var leafletOptions = mainView.leafletOptions
    tuiv2.leaflet.map.SetTiles(leafletOptions)
  },

  onLoadTreeStoreLoad: function (store, records, successful, operation) {
    var me = this
    var viewModel = me.getViewModel()
    var loadTree = me.lookupReference('loadTree')
    var loadTreeStructure = tuiv2.Global.getLoadTreeStructure()
    var view = this.getView('tuiv2mainview')
    //var response = operation.getResponse()
    //var result = response ? Ext.decode(response.responseText, true) : null
    // @TODO v2 get response in a different way

    me.setDistinctColorsForTreeLeafParentNodes(loadTree, loadTreeStructure)

    if (!successful) {
      viewModel.set('treeStoreError', true)
      if (viewModel.get('IsServiceRunning')) {
        return false
      }
    } else {
      viewModel.set('isOperationInProgress', false)
      viewModel.set('treeStoreError', false)
      view.fireEvent('LoadTreeLoaded')
      var result = operation._response.responseJson
      if (result) {
        viewModel.set({
          loadTreeLoadCount: result.LoadCount,
          loadTreeLoadStopCount: result.LoadStopCount
        })
      }
      me.setLoadTreeChecked()
    }
  },

  setLoadTreeChecked: function (checkedLoads, setViewModel = true, expand = true) {
    var me = this

    var references = me.getReferences()

    var tree = references['loadTree']

    var viewModel = me.getViewModel()

    checkedLoads = checkedLoads || viewModel.get('checkedLoads')
    var rootNode = tree.getRootNode()
    var checkedLoadList = []

    if (checkedLoads && rootNode) {
      var treeView = tree.getView()

      treeView.lockedView.setChecked(rootNode, false)

      rootNode.cascade(function (node) {
        var id = node.get('ID')
        if (checkedLoads.indexOf(id) > -1) {
          if (checkedLoadList.indexOf(id) < 0) {
            checkedLoadList.push(id)
          }

          treeView.lockedView.setChecked(node.parentNode, true)
          node.parentNode.set('checked', true)
          if (expand) {
            if (node.parentNode && node.parentNode.parentNode) {
              node.parentNode.parentNode.expand()
            }
          }
        }
      })
    }
    if (setViewModel) {
      viewModel.set('checkedLoads', checkedLoadList)
    }

    if (tree && checkedLoadList.length == 0) {
      tree.getRootNode().expand()
    }
  },

  onDemandTreeStoreBeforeLoad: function (store) {
    var me = this

    var viewModel = me.getViewModel()

    var demandTreeColumns = viewModel.get('demandTreeColumns')

    var demandTreeHierarchy = viewModel.get('demandTreeHierarchy')

    var demandTreeFilters = viewModel.get('demandTreeFilters')

    var proxy = store.getProxy()

    if (tuiv2.Global.getDemandHierarchyIsEmpty()) {
      proxy.setExtraParam('Hierarchy', Ext.emptyArray)
    } else {
      if (tuiv2.Global.getDemandTreeStructure().length > 0) {
        proxy.setExtraParam('Hierarchy', JSON.stringify(tuiv2.Global.getDemandTreeStructure()))
      } else {
        proxy.setExtraParam('Hierarchy', JSON.stringify(demandTreeHierarchy))
      }
    }
    proxy.setExtraParam('Columns', JSON.stringify(demandTreeColumns))
    proxy.setExtraParam('Filters', JSON.stringify(demandTreeFilters))
    proxy.setExtraParam('TreeType', 1)
    return true
  },

  onDemandTreeStoreLoad: function (store, records, successful, operation, eOpts) {
    var me = this
    var viewModel = me.getViewModel()
    var view = me.getView('tuiv2mainview')
    var demandTree = me.lookupReference('demandTree')
    var demandTreeStructure = tuiv2.Global.getDemandTreeStructure()

    if (!successful) {
      viewModel.set('treeStoreError', true)
      if (viewModel.get('IsServiceRunning')) {
        return false
      }
    } else {
      viewModel.set('isOperationInProgress', false)
      viewModel.set('treeStoreError', false)
      view.fireEvent('DemandTreeLoaded')
      me.setDistinctColorsForTreeLeafParentNodes(demandTree, demandTreeStructure)
      me.setDemandTreeChecked()
    }
  },

  onTransportationModeStoreBeforeLoad: function (store) {
    var me = this
    var viewModel = me.getViewModel()
    var facilityKeys = viewModel.get('facilityKey')
    var orderNumbers = viewModel.get('orderNumber')
    var proxy = store.getProxy()

    proxy.setExtraParam('facilityKey', JSON.stringify(facilityKeys))
    proxy.setExtraParam('orderNumber', JSON.stringify(orderNumbers))
    proxy.setExtraParam('productCode', Ext.emptyArray)
  },

  setDemandTreeChecked: function (checkedDemands, setViewModel = true, expand = true) {
    var me = this

    var references = me.getReferences()

    var tree = references['demandTree']

    var viewModel = me.getViewModel()

    var checkedDemands = checkedDemands || viewModel.get('checkedDemands')

    var rootNode = tree.getRootNode()
    var checkedDemandList = []
    if (checkedDemands && rootNode) {
      var treeView = tree.getView()

      treeView.lockedView.setChecked(rootNode, false)
      rootNode.cascade(function (node) {
        var id = node.get('ID')
        if (checkedDemands.indexOf(id) > -1) {
          checkedDemandList.push(id)

          treeView.lockedView.setChecked(node, true)
          node.set('checked', true)
        }
      })
    }

    if (setViewModel) {
      if (JSON.stringify(viewModel.get('checkedDemands')) != JSON.stringify(checkedDemandList)) {
        viewModel.set('checkedDemands', checkedDemandList)
      }
    }
    if (tree && checkedDemandList.length == 0) {
      tree.getRootNode().expand()
    }
  },

  onDemandDetailDataStoreBeforeLoad: function (store) {
    var me = this

    var viewModel = me.getViewModel()

    var demandTreeColumns = viewModel.get('demandDetailDataColumns')

    var demandTreeHierarchy = viewModel.get('demandDetailDataHierarchy')

    var checkedDemands = viewModel.get('checkedDemands')

    var proxy = store.getProxy()
    proxy.setExtraParam('Columns', JSON.stringify(demandTreeColumns))
    proxy.setExtraParam('Hierarchy', JSON.stringify(demandTreeHierarchy))
    proxy.setExtraParam('DemandIds', JSON.stringify(checkedDemands))

    return true
  },

  onShowSettingsLoad: function () {
    var me = this

    var viewModel = me.getViewModel()
    me.showSettingWindow(viewModel, 'load')
  },

  onShowSettings: function () {
    var me = this

    var view = me.getView()

    var viewModel = me.getViewModel()

    var treeView = view.lookupReference('treeView')

    var activeTab = treeView.getActiveTab()

    var reference = activeTab.reference

    me.showSettingWindow(viewModel, reference.replace('Tree', ''))
  },

  onShowSettingsDemand: function () {
    var me = this

    var viewModel = me.getViewModel()
    me.showSettingWindow(viewModel, 'demand')
  },

  showSettingWindow: function (viewModel, treetype) {
    var me = this

    var win = Ext.create('tuiv2.treeview.Settings', {
      mainViewModel: viewModel,
      tree: treetype
    })

    win.show()

    win.on('changed', function (data) {
      var tree = me.lookupReference(treetype + 'Tree')
      if (treetype == 'demand') {
        tree.setDemandKeys(data)
        if (data.length == 0) {
          tree.setUserManipulation(true)
        } else {
          tree.setUserManipulation(false)
        }
      } else {
        tree.setLoadKeys(data)
        if (data.length == 0) {
          tree.setUserManipulation(true)
        } else {
          tree.setUserManipulation(false)
        }
      }

      tree.saveState()
    })
  },

  onToggleTreeView: function () {
    var me = this

    var treeView = me.lookupReference('treeView')

    var header = me.lookupReference('headerBarTitle')
    if (!header.getHidden() && !treeView.getHidden()) {
      treeView.hide()
      header.hide()
    } else {
      treeView.show()
      header.show()
    }
  },

  onChangeColor: function (grid, rowIndex, colIndex, item, e, record) {
    this.selectedNode = record
    var position = e.getXY()
    var me = this

    var rec = grid.getStore().getAt(rowIndex)

    if (rec.isLeaf()) return

    if (!this.colorMenu) {
      this.colorMenu = Ext.create({
        xtype: 'colormenu',
        handler: 'updateColors',
        buttons: [
          {
            text: 'random',
            handler: 'onRandomColor',
            scope: me
          }
        ],
        scope: me
      })
    }

    if (this.colorMenu) this.colorMenu.showAt(position)
  },

  updateColors: function (colormenuitem, rgb) {
    var me = this

    var vm = me.getViewModel()

    var tree = me.lookupReference('loadTree')

    var map = me.lookupReference('mainMap')

    var mapData = vm.get('mapData')

    tree.store.suspendEvents()
    this.selectedNode.cascadeBy(function (rec, idx) {
      var key = rec.get('ID')
      rec.set('color', '#' + rgb)
      if (key) tuiv2.Colors.setColorByName(key, '#' + rgb, me)
    })

    this.selectedNode.set('color', '#' + rgb)

    tree.store.resumeEvents()
    tree.view.refresh()
    map.setMapData(mapData)
  },

  onRandomColor: function (btn) {
    var me = this
    var vm = me.getViewModel()

    btn.up('menu').hide()
    var tree = me.lookupReference('loadTree')

    tree.store.suspendEvents()

    this.selectedNode.cascadeBy(function (rec, idx) {
      var key = rec.get('ID')
      if (key && key != '') {
        tuiv2.Colors.setColorByName(key, 0, me)
      }
    })
    this.selectedNode.set('color', '#ccc')

    tree.store.resumeEvents()
    tree.view.refresh()
  },

  onApproveLoad: function (grid, rowIndex) {
    var me = this

    var viewModel = me.getViewModel()

    var store = grid.getStore()

    var currentNode = store.getAt(rowIndex)

    var Ids = []

    var loadTree = me.lookupReference('loadTree')
    var records = loadTree.lockedGrid.getChecked()

    if (rowIndex === 0) {
      if (records) {
        records.forEach(function (record) {
          if (
            record.childNodes.length > 0 &&
            record.get('Approved') === 'Unapproved' &&
            record.childNodes &&
            record.childNodes.length > 0 &&
            record.childNodes[0].isLeaf()
          ) {
            Ids.push(record.data.LoadName)
          }
        })
      }
    }

    if (rowIndex !== 0) {
      currentNode.cascade(function (node) {
        var id = node.get('ID')
        if (node.get('leaf') && Ids.indexOf(id) == -1) {
          Ids.push(id)
        }
      })
    }

    var approveText = ''
    if (rowIndex === 0 && currentNode.get('Approved') === undefined) {
      if (records.length > 0) {
        approveText = 'Loads are approved'
      } else {
        approveText = 'Loads selection was not made.'
      }
    }

    if (
      Ids.length == 0 &&
      currentNode.get('Approved') !== 'Approved' &&
      currentNode.get('Approved') !== 'Unapproved'
    ) {
      Ext.create('Ext.window.Window', {
        width: 230,
        minHeight: 100,
        maxHeight: 400,
        modal: true,
        autoShow: true,
        ui: 'tui-window',
        cls: 'tuiv2-confirmationWindow',
        title: 'Information',
        scrollable: 'y',
        items: [
          {
            xtype: 'label',
            text: approveText
          }
        ],
        bbar: [
          '->',
          {
            xtype: 'button',
            text: 'OK',
            handler: function () {
              var button = this

              var window = button.up('window')
              window.destroy()
            },
            ui: 'tui-button-success'
          },
          '->'
        ],
        bodyStyle: 'padding:20px;word-wrap: break-word'
      }).show()

      return
    }

    if (currentNode.get('Approved') === 'Approved') {
      me.createMessageBox(
        'Unapprove',
        'Selected loads will be unapproved. Continue? Loads: ' + Ids.join(','),
        function () {
          viewModel.set('isOperationInProgress', true)

          Ext.Ajax.request({
            url: tuiv2.Global.getApiUrl() + 'tuiv2/ApproveLoad',
            method: 'POST',
            timeout: 600000,
            params: {
              Ids: JSON.stringify(Ids)
            },
            success: function (response) {
              var data = Ext.decode(response.responseText, true)

              if (!data.success) {
                Ext.MessageBox.show({
                  title: 'Action Failed',
                  message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
                  buttons: Ext.Msg.OK,
                  width: 600,
                  icon: Ext.Msg.INFO,
                  details: Ext.JSON.encodeValue(data, '\n')
                })
              }

              viewModel.set('isOperationInProgress', false)
              store.load()
            },
            failure: function (response) {
              var data = Ext.decode(response.responseText, true)

              if (data && data.message) {
                Ext.Function.defer(function () {
                  Ext.MessageBox.show({
                    title: 'Action Failed',
                    message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
                    buttons: Ext.Msg.OK,
                    width: 600,
                    icon: Ext.Msg.INFO,
                    details: Ext.JSON.encodeValue(data, '\n')
                  })
                }, 50)
              }
              viewModel.set('isOperationInProgress', false)
            }
          })
        }
      )
    }

    if (currentNode.get('Approved') === 'Unapproved' || currentNode.get('Approved') === undefined) {
      me.createMessageBox(
        'Approve',
        'Selected loads will be approved. Continue? Loads: ' + Ids.join(','),
        function () {
          viewModel.set('isOperationInProgress', true)

          Ext.Ajax.request({
            url: tuiv2.Global.getApiUrl() + 'tuiv2/ApproveLoad',
            method: 'POST',
            timeout: 600000,
            params: {
              Ids: JSON.stringify(Ids)
            },
            success: function (response) {
              var data = Ext.decode(response.responseText, true)

              if (!data.success) {
                Ext.MessageBox.show({
                  title: 'Action Failed',
                  message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
                  buttons: Ext.Msg.OK,
                  width: 600,
                  icon: Ext.Msg.INFO,
                  details: Ext.JSON.encodeValue(data, '\n')
                })
              }

              viewModel.set('isOperationInProgress', false)
              store.load()
            },
            failure: function (response) {
              var data = Ext.decode(response.responseText, true)

              if (data && data.message) {
                Ext.Function.defer(function () {
                  Ext.MessageBox.show({
                    title: 'Action Failed',
                    message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
                    buttons: Ext.Msg.OK,
                    width: 600,
                    icon: Ext.Msg.INFO,
                    details: Ext.JSON.encodeValue(data, '\n')
                  })
                }, 50)
              }
              viewModel.set('isOperationInProgress', false)
            }
          })
        }
      )
    }
  },
  onErase: function () {
    var me = this

    var viewModel = me.getViewModel()

    var loadTree = me.lookupReference('loadTree')

    var demandTree = me.lookupReference('demandTree')

    var view = me.getView()

    var mainmap = view.down('mainmap')

    var map = mainmap.getMap()

    var records = loadTree.lockedGrid.getChecked()
    for (var i = 0; i < records.length; i++) {
      records[i].set('checked', false)
    }

    records = demandTree.lockedGrid.getChecked()
    for (var i = 0; i < records.length; i++) {
      records[i].set('checked', false)
    }

    viewModel.set({
      freeDrawMode: 0,
      checkedLoads: [],
      checkedDemands: [],
      openedLoadCards: []
    })

    me.closeAllPopUps()
  },

  closeAllPopUps: function () {
    var me = this

    var view = me.getView()

    var mainmap = view.down('mainmap')

    var map = mainmap.getMap()

    for (var property in me.popups) {
      if (me.popups.hasOwnProperty(property)) {
        map.closePopup(me.popups[property])

        delete me.popups[property]
      }
    }
  },

  onManagePopUp: function (data) {
    if (Object.keys(this.popups).length === 0) return

    var me = this

    var view = me.getView()

    var viewModel = view.getViewModel()

    var mainmap = view.down('mainmap')

    var map = mainmap.getMap()

    var checkedLoads = viewModel.get('checkedLoads')

    var checkedDemands = viewModel.get('checkedDemands')

    var checkedDemandIds = _.map(checkedDemands, (checkedDemand) => {
      return checkedDemand.substring(_.lastIndexOf(checkedDemand, '|') + 1)
    })

    for (var property in me.popups) {
      if (me.popups.hasOwnProperty(property)) {
        var popup = me.popups[property]
        var exist = false

        if (property.startsWith('load')) {
          var loadId = property.replace('load', '')
          exist = checkedLoads.indexOf(loadId) > -1
        } else if (property.startsWith('demand')) {
          var demandPopupTitle = me.popups[property].tpl.data.Title

          exist = _.find(checkedDemandIds, (checkedDemandId) => {
            return checkedDemandId === demandPopupTitle
          })
        } else {
          // TODO : implement me if required.
          var selectedAreaDemandIds = _.map(
            me.popups[property].tpl.data.DemandZones,
            (demandZone) => {
              return demandZone.Title
            }
          )

          selectedAreaDemandIds = _.uniq(selectedAreaDemandIds)

          var selectedAreaLoadIds = _.map(me.popups[property].tpl.data.Loads, (load) => {
            return load.Id
          })

          selectedAreaLoadIds = _.uniq(selectedAreaLoadIds)

          exist =
            selectedAreaDemandIds.every(function (demandId) {
              return checkedDemandIds.indexOf(demandId) > -1
            }) &&
            selectedAreaLoadIds.every(function (loadId) {
              return checkedLoads.indexOf(loadId) > -1
            })
        }

        if (!exist) {
          map.closePopup(popup)
          delete me.popups[property]
        }
      }
    }
  },

  onChangeView: function (btn, activeItem) {
    var me = this

    var viewId = activeItem.viewId

    var viewModel = me.getViewModel()

    viewModel.set('viewMode', viewId)

    var treeView = me.lookupReference('treeView')

    var header = me.lookupReference('headerBarTitle')

    treeView.show()
    header.show()

    me.closeAllPopUps()
  },

  openDemandPopUp: function (facilityKey) {
    var me = this

    var view = me.getView()

    var viewModel = me.getViewModel()

    var mainmap = view.down('mainmap')

    var map = mainmap.getMap()

    var demandStore = viewModel.get('demandTreeStore')

    var references = me.getReferences()

    var tree = references['demandTree']

    var treeView = tree.getView()

    var drawRoute = viewModel.get('drawRoute')

    var node = demandStore.findNode('Key', facilityKey)

    treeView.lockedView.setChecked(node, true)
    // fires reset of demands.
    treeView.lockedView.ownerCt.ownerCt.fireEvent('itemclick', treeView, null, null, null, {
      getTarget: function () {
        return true
      }
    })

    if (drawRoute) {
      me.asyncCaller(
        function () {
          if (mainmap.demandMarkerDetails[facilityKey]) {
            return true
          } else {
            return false
          }
        },
        function () {
          var latlng = {
            lat: mainmap.demandMarkerDetails[facilityKey].longitude,
            lng: mainmap.demandMarkerDetails[facilityKey].latitude
          }

          me.onDemandlayerclick(map, { latlng: latlng }, facilityKey)
        },
        400
      )
    } else {
      var latlng = viewModel.get('disabledMapLatlng')
      me.onDemandlayerclick(map, { latlng: latlng }, facilityKey)
    }
  },

  onDemandlayerclick: function (map, e, key) {
    var me = this

    var latlng = e.latlng

    var view = me.getView()

    var key = key || e.layer.feature.properties.data.Key

    var viewModel = me.getViewModel()

    var mainmap = view.down('mainmap')

    var map = mainmap.getMap()

    me.onRetrieveDemandPopupData(key, function (data) {
      $('#' + divId)
        .closest('.selectedAreaPopup')
        .remove()

      var divId = 'tmpDemandDiv' + key

      data.viewIndex = 0
      data.viewType = 'order'

      if (me.popups['demand' + key]) {
        me.popups['demand' + key].tpl.dataSet(data)
        return
      }

      var sortedData = _.orderBy(
        data.OrderDetails,
        [
          function (item) {
            return item.Items[0].LoadId
          },
          function (item) {
            return item.Items[0].Volume
          }
        ],
        ['asc', 'desc']
      )

      data.OrderDetails = sortedData

      var popup = L.popup({
        className: 'selectedAreaPopup card-group',
        closeButton: false,
        autoClose: false,
        closeOnEscapeKey: false,
        closeOnClick: false
      })

      popup
        .setLatLng(latlng)
        .setContent("<div id='" + divId + "'></div>")
        .openOn(map)

      me.makeDraggable(popup, map)

      me.popups['demand' + key] = popup

      popup.tpl = Ext.create({
        xtype: 'tuiv2-DemandSummaryTmp',
        renderTo: Ext.getElementById(divId),
        data: data,
        rootView: view,
        popupId: 'demand' + key
      }).show()
    })
  },

  makeDraggable: function (popup, map) {
    var pos = map.latLngToLayerPoint(popup.getLatLng())
    L.DomUtil.setPosition(popup._wrapper.parentNode, pos)
    var draggable = new L.Draggable(popup._container, popup._wrapper)
    draggable.enable()

    draggable.on('dragend', function () {
      var pos = map.layerPointToLatLng(this._newPos)
      popup.setLatLng(pos)
    })
  },

  onLayerClick: function (map, e) {
    var me = this

    var view = me.getView()

    var plansView = view.lookupReference('plansView')

    var viewModel = me.getViewModel()

    var treeView = me.lookupReference('treeView')

    var header = me.lookupReference('headerBarTitle')

    var latlng = e.latlng

    var data = null
    if (
      e.layer &&
      e.layer.feature &&
      e.layer.feature.properties &&
      e.layer.feature.properties.data
    ) {
      data = e.layer.feature.properties.data
    }

    if (!data) {
      if (
        e.layer &&
        e.layer.options &&
        e.layer.options.properties &&
        e.layer.options.properties.data
      ) {
        data = e.layer.options.properties.data
      }
    }

    if (data) {
      if (plansView && plansView.hidden) {
        plansView.setHidden(false)
        treeView.hide()
        header.hide()
      }

      var id = data.ID

      var loadStopSequence = data.StopSequence

      viewModel.set({
        selectedLoadId: id,
        loadStopSequence: loadStopSequence,
        selectedLoadIndex: data.LoadIndex
      })
      me.openLoadPopup(latlng, id)
    }
  },

  asyncCaller: function (testFunc, successFunc2, timeout) {
    var me = this
    if (!testFunc()) {
      setTimeout(function () {
        me.asyncCaller(testFunc, successFunc2, timeout)
      }, timeout)
    } else {
      successFunc2()
    }
  },

  openLoadPopup: function (latlng, id) {
    var me = this
    var view = me.getView()
    var viewModel = me.getViewModel()
    var mainmap = view.down('mainmap')
    var map = mainmap.getMap()
    var openedLoadCards = viewModel.get('openedLoadCards')

    openedLoadCards.forEach((card) => {
      let ids = []
      var data = card.data
      data.Loads.forEach((element) => {
        ids.push(element.Id)
      })
      ids.push(id)
      ids = _.uniq(ids)
      data.Loads = ids.map(function (id) {
        return {
          Color: tuiv2.Colors.getColorByName(id),
          Id: id
        }
      })

      card.setData(data)
    })

    if (latlng == null) {
      latlng = {
        lat: mainmap.loadStopDetails[id + '-0'].longitude,
        lng: mainmap.loadStopDetails[id + '-0'].latitude
      }
    }
    me.onRetrieveLoadPopupData(id, function (data) {
      data.viewIndex = 1
      data.LoadOptions = []
      data.DisplayLoadOptions = false
      data.VisiblePalletOptionId = null

      var divId = 'tmpLoadDiv' + id
      if (me.popups['load' + id]) {
        me.popups['load' + id].tpl.dataSet(data)
        return
      }

      $('#' + divId)
        .closest('.selectedAreaPopup')
        .remove()

      var popup = L.popup({
        className: 'selectedAreaPopup card-group',
        closeButton: false,
        autoPan: true,
        autoClose: false,
        closeOnEscapeKey: false,
        closeOnClick: false
      })

      popup
        .setLatLng(latlng)
        .setContent("<div id='" + divId + "'></div>")
        .openOn(map)

      me.makeDraggable(popup, map)

      me.popups['load' + id] = popup

      popup.tpl = Ext.create({
        xtype: 'tuiv2-loadSummaryTmp',
        renderTo: Ext.getElementById(divId),
        data: data,
        rootView: view,
        popupId: 'load' + id
      }).show()
    })
  },

  onLoadTableRowSelect: function (grid, record, index) {
    var me = this

    var viewModel = me.getViewModel()

    var store = viewModel.getStore('loadStopTableStore')

    var columns = viewModel.get('loadStopTableColumns')

    viewModel.set('selectedLoadId', record.get('LoadStopName'))

    var proxy = store.getProxy()
    proxy.setExtraParam('ID', record.get('ID'))
    proxy.setExtraParam('Columns', JSON.stringify(columns))

    store.load()
  },

  onLoadStopTableRowSelect: function (grid, record, index) {
    var me = this

    var viewModel = me.getViewModel()

    var store = viewModel.getStore('loadStopEntryTableStore')

    var columns = viewModel.get('loadStopEntryTableColumns')

    viewModel.set('selectedLoadId', record.get('LoadStopName'))
    viewModel.set('loadStopSequence', record.get('StopSequence'))

    viewModel.set('selectedLoadStopIndex', record.get('LoadStopIndex'))

    var proxy = store.getProxy()
    proxy.setExtraParam('LoadId', record.get('LoadStopName'))
    proxy.setExtraParam('LoadStopSequence', record.get('loadStopSequence'))
    proxy.setExtraParam('Columns', JSON.stringify(columns))

    store.load()
  },

  onLoadTableShowMap: function (gridView, rowIndex, colIndex, item, e, record) {
    var me = this

    var view = me.getView()

    var viewModel = view.getViewModel()

    var id = record.get('ID')

    var drawRoute = viewModel.get('drawRoute')

    var mainmap = view.down('mainmap')

    var checkedLoads = viewModel.get('checkedLoads')

    checkedLoads = checkedLoads.filter(function (value) {
      return value != id
    })
    checkedLoads.push(id)

    viewModel.set('checkedLoads', checkedLoads)
    viewModel.set('viewMode', 0)

    if (drawRoute) {
      me.asyncCaller(
        function () {
          if (mainmap.loadStopDetails[id + '-0']) {
            return true
          } else {
            return false
          }
        },
        function () {
          me.openLoadPopup(null, id)
        },
        400
      )
    } else {
      var latlng = viewModel.get('disabledMapLatlng')
      me.openLoadPopup(latlng, id)
    }
  },

  ajaxCallForMapData: function (loadIds, demandIds, mapLoadSummary) {
    var result
    var view = this.getView()

    var viewmodel = view.getViewModel()

    var mapRequest = Ext.Ajax.request({
      method: 'POST',
      url: tuiv2.Global.getApiUrl() + 'tuiv2/LoadMapObjects',
      params: {
        LoadIds: JSON.stringify(loadIds),
        DemandIds: JSON.stringify(demandIds),
        LoadSummaryItems: JSON.stringify(mapLoadSummary)
      },
      timeout: 600000,
      success: function (response, opts) {
        result = Ext.decode(response.responseText, true)
        if (result) {
          viewmodel.set({
            mapData: result.data,
            summaryData: result.loadSummary,
            maxDemandQuantity: result.demandSummary.maxDemandQuantity
          })
        }
      },
      failure: function (response, opts) {
        viewmodel.set({
          summaryData: [],
          mapData: []
        })
      }
    })
    viewmodel.set('mapRequest', mapRequest)
  },

  onSimulate: function () {
    var me = this

    var viewModel = me.getViewModel()

    var tree = me.lookupReference('demandManagerTree')

    var checked = tree.lockedGrid.getChecked()

    var demandIndexes = []

    checked.forEach(function (node) {
      if (node.isLeaf()) demandIndexes.push(node.get('Index'))
    })

    Ext.Ajax.request({
      method: 'POST',
      url: tuiv2.Global.getApiUrl() + 'tuiv2/Simulate',
      params: {
        DemandIndexes: JSON.stringify(demandIndexes)
      },
      timeout: 600000,
      success: function (response, opts) {},
      failure: function (response, opts) {}
    })
  },
  onServe: function () {
    var me = this

    var viewModel = me.getViewModel()

    var tree = me.lookupReference('demandTree')

    var checked = tree.lockedGrid.getChecked()

    var demandIndexes = []

    checked.forEach(function (node) {
      if (node.isLeaf()) demandIndexes.push(node.get('Index'))
    })

    if (demandIndexes.length == 0) {
      Ext.create('Ext.window.Window', {
        width: 230,
        minHeight: 100,
        maxHeight: 400,
        modal: true,
        autoShow: true,
        ui: 'tui-window',
        cls: 'tuiv2-confirmationWindow',
        title: 'Information',
        scrollable: 'y',
        items: [
          {
            xtype: 'label',
            text: 'Please select at least one demand.'
          }
        ],
        bbar: [
          '->',
          {
            xtype: 'button',
            text: 'OK',
            handler: function () {
              var button = this

              var window = button.up('window')
              window.destroy()
            },
            ui: 'tui-button-success'
          },
          '->'
        ],
        bodyStyle: 'padding:20px;word-wrap: break-word'
      }).show()

      return
    }

    if (me.manageWindow) me.manageWindow.close()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      method: 'POST',
      url: tuiv2.Global.getApiUrl() + 'tuiv2/AutoServe',
      params: {
        DemandIndexes: JSON.stringify(demandIndexes)
      },
      timeout: 600000,
      success: function (response, opts) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action Failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          var loadStore = viewModel.getStore('loadTreeStore')
          loadStore.load()

          var demandStore = viewModel.getStore('demandTreeStore')
          demandStore.load()
        }

        viewModel.set('isOperationInProgress', false)
      },
      failure: function (response, opts) {
        var data = Ext.decode(response.responseText, true)

        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onSetOrigin: function () {
    var me = this

    var viewModel = me.getViewModel()

    var tree = me.lookupReference('demandTree')

    var checkedRecordNodes = tree.lockedGrid.getChecked()

    var demandIndexes = []
    checkedRecordNodes.forEach(function (node) {
      if (node.isLeaf()) demandIndexes.push(node.get('Index'))
    }, this)

    var locationColums = viewModel.get('locationColumns')

    var locationStore = Ext.create('Ext.data.Store', {
      fields: ['index', 'name', 'facility'],
      data: locationColums
    })

    var selectFacility = new Ext.Window({
      closable: true,
      modal: true,
      title: 'Select Facility',
      layout: 'fit',
      items: [
        {
          xtype: 'form',
          labelWidth: 100,
          defaults: {
            margin: '10px'
          },
          items: [
            {
              xtype: 'combo',
              store: locationStore,
              fieldLabel: 'facility',
              name: 'facility',
              displayField: 'name',
              valueField: 'index',
              allowBlank: false
            }
          ],
          buttons: [
            {
              text: 'OK',
              handler: function () {
                var record = this.up('form').getValues()
                Ext.Ajax.request({
                  method: 'POST',
                  url: tuiv2.Global.getApiUrl() + 'tuiv2/SetServeFrom',
                  params: {
                    DemandIndexes: JSON.stringify(demandIndexes),
                    serveFacilityIndex: JSON.stringify(record['facility'])
                  },
                  timeout: 600000,
                  success: function (response, opts) {
                    var data = Ext.decode(response.responseText, true)

                    if (!data.success) {
                      Ext.MessageBox.show({
                        title: 'Action Failed',
                        message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
                        buttons: Ext.Msg.OK,
                        width: 600,
                        icon: Ext.Msg.INFO,
                        details: Ext.JSON.encodeValue(data, '\n')
                      })
                    } else {
                      var loadStore = viewModel.getStore('loadTreeStore')
                      loadStore.load()

                      var demandStore = viewModel.getStore('demandTreeStore')
                      demandStore.load()
                    }

                    viewModel.set('isOperationInProgress', false)
                  },
                  failure: function (response, opts) {
                    var data = Ext.decode(response.responseText, true)

                    Ext.MessageBox.show({
                      title: 'Action Failed',
                      message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
                      buttons: Ext.Msg.OK,
                      width: 600,
                      icon: Ext.Msg.INFO,
                      details: Ext.JSON.encodeValue(data, '\n')
                    })

                    viewModel.set('isOperationInProgress', false)
                  }
                })
                selectFacility.close()
              }
            },
            {
              text: 'Cancel',
              handler: function () {
                selectFacility.close()
              }
            }
          ]
        }
      ]
    }).show()
  },

  onSaveChanges: function () {
    var me = this

    var viewModel = me.getViewModel()

    var references = me.getReferences()
    var loadentrygrid = references.loadStopEntry
    if (loadentrygrid) {
      var store = loadentrygrid.store

      var loadEntries = []

      store.getRoot().cascadeBy(function (node) {
        if (node.get('leaf') && node.get('isLoaded')) {
          loadEntries.push(node.data)
        }
      })
      if (loadEntries.length > 0) {
        var loadStopIndex = loadEntries[0].LoadStopIndex

        var demandIndexes = loadEntries.map(function (item) {
          return parseInt(item.Index)
        })

        var quantities = loadEntries.map(function (item) {
          return item.ShipQuantity
        })

        var request = {
          loadStopIndex: loadStopIndex,
          demandIndexes: JSON.stringify(demandIndexes),
          quantities: JSON.stringify(quantities)
        }

        viewModel.set('isOperationInProgress', true)

        Ext.Ajax.request({
          url: tuiv2.Global.getApiUrl() + 'tuiv2/LoadStopEntriesUpdate',
          method: 'POST',
          timeout: 600000,
          params: request,
          success: function (response, opts) {
            var data = Ext.decode(response.responseText, true)

            if (!data.success) {
              Ext.MessageBox.show({
                title: 'Action Failed',
                message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
                buttons: Ext.Msg.OK,
                width: 600,
                icon: Ext.Msg.INFO,
                details: Ext.JSON.encodeValue(data, '\n')
              })
            } else {
              var loadStore = viewModel.getStore('loadTreeStore')
              loadStore.load()

              var demandStore = viewModel.getStore('demandTreeStore')
              demandStore.load()
              var loadEntryStore = viewModel.getStore('loadEntryStore')
              loadEntryStore.load()
            }

            viewModel.set('isOperationInProgress', false)
          },
          failure: function (response, opts) {
            var data = Ext.decode(response.responseText, true)

            Ext.MessageBox.show({
              title: 'Action Failed',
              message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
              buttons: Ext.Msg.OK,
              width: 600,
              icon: Ext.Msg.INFO,
              details: Ext.JSON.encodeValue(data, '\n')
            })

            viewModel.set('isOperationInProgress', false)
          }
        })
      }
    }
  },

  onResetTable: function () {
    var me = this

    var viewModel = me.getViewModel()

    var runinfoId = viewModel.get('PlanInfo.CurrentRunInfoID')

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/reset',
      method: 'GET',
      timeout: 600000,
      params: {
        runinfoId: runinfoId != null ? runinfoId : 0
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Reset action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          var loadStore = viewModel.getStore('loadTreeStore')
          loadStore.load()

          var demandStore = viewModel.getStore('demandTreeStore')
          demandStore.load()
        }

        viewModel.set('isOperationInProgress', false)
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)

        Ext.MessageBox.show({
          title: 'Reset action failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onPolishize: function () {
    var me = this

    var viewModel = me.getViewModel()

    var loadTree = me.lookupReference('loadTree')

    var checkedLoads = me.getCheckedLoads(loadTree.lockedGrid.getChecked())

    var loadIndexes = []

    var loadNames = []

    checkedLoads.forEach(function (el) {
      loadIndexes.push(el.loadnIndex)
      loadNames.push(el.id)
    })

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/Refix',
      method: 'POST',
      timeout: 600000,
      params: {
        LoadIndexes: JSON.stringify(loadIndexes),
        LoadNames: JSON.stringify(loadNames)
      },

      success: function (response, opts) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action Failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          var loadStore = viewModel.getStore('loadTreeStore')
          loadStore.load()

          var demandStore = viewModel.getStore('demandTreeStore')
          demandStore.load()
        }

        viewModel.set('isOperationInProgress', false)
      },
      failure: function (response, opts) {
        var data = Ext.decode(response.responseText, true)

        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  setFooterButtonsView: function (buttonText, isDisabled) {
    var me = this
    var view = me.getView()
    var viewModel = view.getViewModel()
    viewModel.set(buttonText, isDisabled)
  },

  onOpenSavePlan: function () {
    var me = this

    var view = me.getView()

    if (this.savePlan) this.savePlan.show()

    this.savePlan = view.add({ xtype: 'tuiv2-savePlan' }).show()
  },

  onSavePlan: function (btn) {
    var me = this
    var view = me.getView()
    var viewModel = me.getViewModel()
    var form = btn.up('form')
    var record = form.getValues()
    var savePlanWindow = btn.up('window')
    var asyncActions = viewModel.get('asyncActions')
    var asyncActionStartMessage = viewModel.get('asyncActionStartMessage')

    var plan = {
      planName: record['planName'],
      planDescription: record['planDescription'],
      reSequence: record['reSequence'],
      userName: null
    }

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/SavePlan',
      method: 'POST',
      timeout: 600000,
      params: plan,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action Failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          var action = {
            actionId: data.result.Message,
            type: 'savePlan'
          }

          asyncActions.push(action)

          viewModel.set('asyncActions', asyncActions)
        }

        Ext.toast({
          html: asyncActionStartMessage,
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })

        viewModel.set({
          isOperationInProgress: false
        })
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.Function.defer(function () {
          Ext.MessageBox.show({
            title: 'Action Failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        }, 50)

        viewModel.set('isOperationInProgress', false)
      }
    })

    savePlanWindow.close()
  },

  onCancelSavePlan: function (btn) {
    var savePlanWindow = btn.up('window')

    savePlanWindow.close()
  },

  onAfterRender: function () {
    var me = this
    tuiv2.Colors.reset()
    me.checkServiceStatus()
  },

  checkServiceStatus: function () {
    var me = this

    var viewModel = me.getViewModel()
    if (!viewModel) return

    var references = me.getReferences()

    var serviceIsNotReadyPanel = null

    var asyncActions = viewModel.get('asyncActions')

    var startingLastService = viewModel.get('startingLastService')

    var isServiceSelected = viewModel.get('isServiceSelected')
    if (references && references.ServiceIsNotReadyPanel) {
      serviceIsNotReadyPanel = references['ServiceIsNotReadyPanel']
    }

    var isOperationInProgress = false
    if (viewModel) {
      isOperationInProgress = viewModel.get('isOperationInProgress')
    }
    if (!isServiceSelected && !startingLastService) {
      return
    }

    if (isOperationInProgress) {
      setTimeout(function () {
        me.checkServiceStatus()
      }, 500)
      return
    }

    Ext.Ajax.request({
      method: 'POST',
      async: true,
      url: tuiv2.Global.getApiUrl() + 'tuiv2/IsServiceRunning',
      timeout: 600000,

      success: function (response, opts) {
        var result = Ext.decode(response.responseText, true)
        if (result) {
          if (viewModel) {
            if (result.Tasks) {
              asyncActions.forEach(function (action) {
                var task = result.Tasks.find(function (task) {
                  return task.ActionId == action.actionId
                })
                if (!task) {
                  if (action.type == 'savePlan') {
                    me.savePlanActionResult(action.actionId)
                  }
                }
              })

              var user = viewModel.get('user')
              var inProgress = result.Tasks.find(function (task) {
                return (
                  task.Name.includes('Save Plan') &&
                  task.InProgress == 1 &&
                  task.UserName == user.username
                )
              })
              if (inProgress) {
                viewModel.set('savingHidden', false)
              } else {
                viewModel.set('savingHidden', true)
              }
              var taskStore = viewModel.get('taskStore')
              taskStore.getSorters().add('StartTime')
              taskStore.loadData(result.Tasks)
            }

            var planInfo = result['PlanInfo']

            var session = result['Session']

            var sessionName = ''

            if (session) {
              sessionName = session.Description
            }

            var currentState = viewModel.get('PlanInfo.CurrentState')
            if (currentState != null && currentState != planInfo.CurrentState) {
              me.reloadLoadTreeStore()

              me.reloadDemandTreeStore()
            }

            var currentDateTime = Ext.Date.parse(planInfo.CurrentDateTime, 'Y-m-d\\TH:i:s')

            var startDate = Ext.Date.parse(planInfo.StartDateTime, 'Y-m-d\\TH:i:s')

            viewModel.set({
              IsServiceRunning: result['IsServiceRunning'],
              ServiceMessage: result['Description'],
              PlanInfo: planInfo,
              Users: result['Users'],
              Session: result['Session'],
              sessionName: sessionName,
              simulationSliderValue: currentDateTime.getTime() - startDate.getTime()
            })

            var message = result['Description']
              ? result['Description']
              : viewModel.get('errorMessage')
          }

          if (serviceIsNotReadyPanel) {
            serviceIsNotReadyPanel.mask(message)
          }
        }

        if (serviceIsNotReadyPanel) {
          setTimeout(function () {
            me.checkServiceStatus()
          }, 5000)
        }
      },
      failure: function () {
        if (serviceIsNotReadyPanel) {
          setTimeout(function () {
            me.checkServiceStatus()
          }, 5000)
        }
      }
    })
  },

  savePlanActionResult: function (actionId) {
    var me = this

    var viewModel = me.getViewModel()
    var asyncActionSuccessMessage = viewModel.get('asyncActionSuccessMessage')
    var asyncActionFailMessage = viewModel.get('asyncActionFailMessage')
    var asyncActions = viewModel.get('asyncActions')

    me.getActionResult(actionId, function (data) {
      var result = Ext.decode(data.result, true)

      if (result.IsSuccess) {
        viewModel.set('ReloadRunInfo', new Date().getUTCMilliseconds())
        view.fireEvent('savePlan', result.Message)

        asyncActions = asyncActions.filter(function (action) {
          return action.actionId != actionId
        })

        viewModel.set('asyncActions', asyncActions)
        Ext.toast({
          html: asyncActionSuccessMessage,
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })
      } else {
        Ext.toast({
          html: asyncActionFailMessage,
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })
      }
    })
  },

  onMarkerContextMenu: function (map, e) {
    var me = this

    var vm = me.getViewModel()

    var canUpdateLocation = vm.get('CanUpdateLocation')

    var feature = e.layer && e.layer.options && e.layer.options ? e.layer.options : null

    if (
      canUpdateLocation &&
      feature &&
      feature.properties &&
      feature.properties.data &&
      feature.properties.data.StopSequence === 0
    ) {
      Ext.create('Ext.menu.Menu', {
        items: [
          {
            text: 'Update Location',
            iconCls: 'x-fa fa-pencil-square-o',
            handler: function () {
              var loadData = feature.properties.data

              var record = Ext.create('Ext.data.Model', {
                FacilityIndex: loadData['FacilityIndex'],
                Latitude: feature.geometry.coordinates[1],
                Longitude: feature.geometry.coordinates[0],
                LoadStopDesc: loadData['FacilityDescription'],
                id: loadData['ID']
              })

              Ext.create('tuiv2.cmp.FacilityForm', {
                record: record,
                vm: vm
              }).show()
            }
          }
        ]
      }).showAt(e.originalEvent.x, e.originalEvent.y)
    }
  },

  getCheckedLoads: function (nodes) {
    var controlNodes = []

    var checkedLoads = []
    var recursive = function (node) {
      if (controlNodes.indexOf(node) > -1) return
      controlNodes.push(node)

      if (node.childNodes && node.childNodes.length > 0 && !node.childNodes[0].isLeaf()) {
        recursive(node.childNodes[0])
      } else {
        checkedLoads.push({
          id: node.childNodes[0].get('ID'),
          loadnIndex: node.childNodes[0].get('LoadIndex')
        })
      }
    }

    nodes.forEach((element) => {
      if (controlNodes.indexOf(element) > -1) return
      controlNodes.push(element)

      if (element.isLeaf()) return

      if (element.childNodes && element.childNodes.length > 0 && !element.childNodes[0].isLeaf()) {
        recursive(element.childNodes[0])
      } else {
        checkedLoads.push({
          id: element.childNodes[0].get('ID'),
          loadnIndex: element.childNodes[0].get('LoadIndex')
        })
      }
    })
    return checkedLoads
  },

  getCheckedLoadsForEvent: function (nodes) {
    var controlNodes = []

    var checkedLoads = []
    var recursive = function (node) {
      if (controlNodes.indexOf(node) > -1) return
      controlNodes.push(node)

      if (node.childNodes && node.childNodes.length > 0 && !node.childNodes[0].isLeaf()) {
        recursive(node.childNodes[0])
      } else {
        checkedLoads.push(node.childNodes[0].getData())
      }
    }

    nodes.forEach((element) => {
      if (controlNodes.indexOf(element) > -1) return
      controlNodes.push(element)

      if (element.isLeaf()) return

      if (element.childNodes && element.childNodes.length > 0 && !element.childNodes[0].isLeaf()) {
        recursive(element.childNodes[0])
      } else {
        checkedLoads.push(element.childNodes[0].getData())
      }
    })
    return checkedLoads
  },

  getCheckedLoadStops: function (nodes) {
    var controlNodes = []

    var checkedLoadStops = []
    var recursive = function (node) {
      if (controlNodes.indexOf(node) > -1) return
      controlNodes.push(node)

      if (node && !node.isLeaf()) {
        recursive(node.childNodes[0])
      } else {
        checkedLoadStops.push({
          id: node.get('ID'),
          loadnIndex: node.get('LoadIndex')
        })
      }
    }

    nodes.forEach((element) => {
      if (controlNodes.indexOf(element) > -1) return
      controlNodes.push(element)

      if (element.isLeaf()) return

      if (element && !element.isLeaf()) {
        recursive(element.childNodes[0])
      } else {
        checkedLoadStops.push({
          id: element.get('ID'),
          loadnIndex: element.get('LoadIndex')
        })
      }
    })
    return checkedLoadStops
  },

  getCheckedLoadStopsForEvent: function (nodes) {
    var controlNodes = []

    var checkedLoadStops = []
    var recursive = function (node) {
      if (controlNodes.indexOf(node) > -1) return
      controlNodes.push(node)

      if (node && !node.isLeaf()) {
        recursive(node.childNodes[0])
      } else {
        checkedLoadStops.push(node.getData())
      }
    }

    nodes.forEach((element) => {
      if (controlNodes.indexOf(element) > -1) return
      controlNodes.push(element)

      if (element && !element.isLeaf()) {
        recursive(element.childNodes[0])
      } else {
        checkedLoadStops.push(element.getData())
      }
    })
    return checkedLoadStops
  },

  onSetFilters: function (filter) {
    var me = this

    var viewModel = me.getViewModel()

    var oldFilters = viewModel.get('externalFilters')

    if (JSON.stringify(oldFilters) == JSON.stringify(filter)) {
      return
    } else {
      for (var key in oldFilters) {
        if (oldFilters.hasOwnProperty(key)) {
          if (!filter.hasOwnProperty(key)) {
            filter[key] = null
          }
        }
      }
    }

    me.onErase()

    viewModel.set('externalFilters', filter)

    var componentNames = {
      LoadTree: {
        variableName: 'loadTreeFilters',
        store: 'loadTreeStore'
      },
      DemandTree: {
        variableName: 'demandTreeFilters',
        store: 'demandTreeStore'
      }
    }

    var storeNames = []

    for (var key in filter) {
      if (filter.hasOwnProperty(key)) {
        var componentName = key.split('_')[0]
        var variableName = key.split('_')[1]

        var currentComponent = componentNames[componentName]
        if (currentComponent) {
          var filterContainer = viewModel.get(currentComponent.variableName)

          if (storeNames.indexOf(currentComponent.store)) {
            storeNames.push(currentComponent.store)
          }

          var isFilterFound = false
          var removedFilters = []
          filterContainer.forEach((currentFilter) => {
            if (currentFilter.fieldName == variableName) {
              if (!filter[key]) {
                removedFilters.push(currentFilter)
                return
              }

              isFilterFound = true

              currentFilter.operator = '='
              currentFilter.value = filter[key]
            }
          })

          removedFilters.forEach((removedFilter) => {
            var index = filterContainer.indexOf(removedFilter)
            if (index > -1) {
              filterContainer.splice(index, 1)
            }
          })

          if (!isFilterFound) {
            filterContainer.push({
              fieldName: variableName,
              operator: '=',
              value: filter[key]
            })
          }

          viewModel.set(currentComponent.variableName, filterContainer)
        }
      }
    }

    storeNames.forEach((storeName) => {
      var store = viewModel.get(storeName)
      store.load()
    })
  },

  onSaveCurrentState: function () {
    var me = this

    var view = me.getView()

    if (this.snapShotWindow) {
      this.snapShotWindow.show()
    } else {
      this.snapShotWindow = view.add({ xtype: 'tuiv2-snapshotwindow' }).show()
    }
  },

  onSetCheckedLoads: function (obj) {
    var me = this

    if (obj.CheckedLoads) {
      if (Array.isArray(obj.CheckedLoads)) {
        me.setLoadTreeChecked(obj.CheckedLoads)
      } else {
        me.setLoadTreeChecked([obj.CheckedLoads])
      }
    }
  },

  onSetCheckedDemands: function (obj) {
    var me = this
    if (obj.CheckedLoads) {
      if (Array.isArray(obj.CheckedLoads)) {
        me.setDemandTreeChecked(obj.CheckedLoads)
      } else {
        me.setDemandTreeChecked([obj.CheckedLoads])
      }
    }
  },

  onOpenLoadCardExternal: function (params) {
    const { LoadNames: loadNames = [] } = params

    const loadNamesToOpen = _.isArray(loadNames) ? loadNames : [loadNames]

    this.openLoadCard(loadNamesToOpen)
  },

  openDemandCardExternal: function (params) {
    var me = this

    const { FacilityNames: facilityNames = [] } = params

    const facilityNamesToOpen = _.isArray(facilityNames) ? facilityNames : [facilityNames]

    var viewModel = me.getViewModel()
    var demandTreeStore = viewModel.get('demandTreeStore')
    var demandData = demandTreeStore.getData()
    var demandRows = demandData && demandData.items

    var facilityKeys = []
    var facilityIds = []

    _.forEach(facilityNamesToOpen, (facilityName) => {
      var facilityRow = _.find(demandRows, (row) => {
        return row.get('Text') == facilityName
      })
      if (facilityRow) {
        var children = facilityRow.get('Children')
        var facilityKey = facilityRow.get('Key')
        var facilityId = _.size(children) > 0 ? children[0]['ID'] : facilityRow.get('ID')
        facilityKeys.push(facilityKey)
        facilityIds.push(facilityId)
      }
    })

    if (_.size(facilityIds)) {
      this.openDemandCard(facilityIds, facilityKeys)
    }
  },

  onCreateSnapShot: function () {
    var me = this

    var view = me.getView()

    var viewModel = me.getViewModel()

    var references = view.getReferences()

    var snapShotGrid = references.tuiv2SnapShotGrid

    var snapShotStore = snapShotGrid.store

    if (!snapShotStore) return

    var snapShotName =
      viewModel.get('PlanInfo.CurrentScenarioID') +
      '-' +
      viewModel.get('PlanInfo.CurrentRunInfoID') +
      '-' +
      Ext.util.Format.date(new Date(), 'Y/m/d H:i:s')

    var snapShotDescription =
      'Scenario : ' +
      viewModel.get('PlanInfo.CurrentScenarioID') +
      ' - RunInfo : ' +
      viewModel.get('PlanInfo.CurrentRunInfoID') +
      ' - Date : ' +
      Ext.util.Format.date(new Date(), 'Y/m/d H:i:s')

    var snapShotData = {
      ScenarioName: snapShotName,
      ScenarioDescription: snapShotDescription
    }
    var record = new tuiv2.model.SnapShotModel(snapShotData)

    snapShotStore.insert(0, record)
  },

  onRemoveSnapShot: function (view, recIndex, cellIndex, item, e, record) {
    record.drop()
  },

  serviceIconClick: function () {
    var me = this

    var view = me.getView()

    if (this.taskWindow) {
      this.taskWindow.show()
    } else {
      this.taskWindow = view.add({ xtype: 'tuiv2-taskwindow' }).show()
    }
  },

  onCancelTask: function () {},

  openDiffWindow: function () {
    var me = this

    me.getActionDiff(function (data) {
      if (data.result && data.result.DiffData && data.result.DiffData.length > 0) {
        Ext.create('tuiv2.cmp.DiffWindow', {
          diffData: data.result.DiffData
        }).show()
      }
    })
  },

  onMapEdit: function (btn) {
    var me = this

    var viewModel = me.getViewModel()

    var freeDrawMode = viewModel.get('freeDrawMode')

    var references = me.getReferences()

    var tree = references['demandTree']

    if (!tree.getRootNode().isExpanded()) {
      tree.store.load()
    }

    freeDrawMode = freeDrawMode == FreeDraw.ALL ? 0 : FreeDraw.ALL
    viewModel.set('freeDrawMode', freeDrawMode)
  },

  onFreeDrawMultiSelect: function (map, polygon, latlng) {
    var me = this
    me.getSelectedAreaDetails(polygon, latlng)
  },

  getSelectedAreaDetails(polygon, latlng) {
    var me = this

    var viewModel = me.getViewModel()

    var poly = polygon.toGeoJSON()

    var view = me.getView()

    var mainmap = view.down('mainmap')

    var map = mainmap.getMap()

    var facilityIds = []

    var loadIds = []

    var facilityTreeIds = []

    var demandStore = viewModel.get('demandTreeStore')

    var checkedLoads = viewModel.get('checkedLoads')

    var checkedDemandsViewModel = viewModel.get('checkedDemands')

    var coordinates = poly.geometry.coordinates[0]
    me.onRetrieveSelectedAreaDetails(coordinates, function (data) {
      if (data.DemandZones.length == 0) {
        Ext.toast({
          html: 'There is no demand in the selected area.',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })
        return
      }

      var controlNodes = []

      var checkedDemands = []
      var recursive = function (node) {
        if (node && node.childNodes && node.childNodes.length > 0) {
          recursive(node.childNodes[0])
        } else {
          checkedDemands.push(node.get('ID'))
        }
      }

      data.DemandZones.forEach(function (item) {
        var node = demandStore.findNode('Key', item.Id)

        if (node && node.isLeaf()) {
          checkedDemands.push(node.get('ID'))
        }

        if (node && node.childNodes && node.childNodes.length > 0) {
          recursive(node.childNodes[0])
        }
        facilityIds.push(item.Id)
      })

      data.Loads.forEach(function (item) {
        loadIds.push(item.Id)
      })

      if (checkedLoads && checkedLoads.length > 0) {
        checkedLoads = _.uniq(checkedLoads.concat(loadIds))
      } else {
        checkedLoads = loadIds
      }
      if (checkedDemandsViewModel && checkedDemandsViewModel.length > 0) {
        checkedDemands = _.uniq(checkedDemandsViewModel.concat(checkedDemands))
      }

      viewModel.set({
        checkedLoads: checkedLoads,
        checkedDemands: checkedDemands
      })
      me.setLoadTreeChecked(checkedLoads, false)
      me.setDemandTreeChecked(checkedDemands, false)

      var id = facilityIds.join('-') + '-' + loadIds.join('-')

      var divId = 'tmpLoadDiv' + id

      $('#' + divId)
        .closest('.selectedAreaPopup')
        .remove()

      var popup = L.popup({
        className: 'selectedAreaPopup card-group',
        closeButton: false,
        autoClose: false,
        closeOnEscapeKey: true,
        closeOnClick: false
      })

      popup
        .setLatLng(latlng)
        .setContent("<div id='" + divId + "'></div>")
        .openOn(map)

      me.makeDraggable(popup, map)

      var popUpId = '~' + facilityIds.join('~') + '~' + loadIds.join('~') + '~'

      me.popups['selectedarea' + popUpId] = popup
      popup.tpl = Ext.create({
        xtype: 'tuiv2-selectedAreaSummaryTmp',
        renderTo: Ext.getElementById(divId),
        data: data,
        rootView: view,
        popupId: 'selectedarea' + popUpId,
        coordinates: coordinates
      })
    })
  },
  setTuiConnection: function (params) {
    var me = this

    const { DataConnectionId } = params || {}

    var data = {
      DataConnectionId: DataConnectionId
    }
    me.onErase()
    me.onSelectServiceWithConnectionId(data)
  },

  onSelectServiceWithConnectionId: function (data) {
    var me = this

    var viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    me.setServiceWithConnectionId(data, function (data) {
      if (!data) return

      if (data.IsServiceRunning) {
        me.doRefresh()
        viewModel.set({
          IsServiceRunning: data['IsServiceRunning'],
          isOperationInProgress: false
        })
      } else {
        viewModel.set({
          IsServiceRunning: data['IsServiceRunning'],
          ServiceMessage: data['Description'],
          isOperationInProgress: false
        })
      }
    })
  },

  onSelectService: function (data) {
    var me = this

    var viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    me.getServiceList(data, function (data) {
      if (!data) return

      if (data.IsServiceRunning) {
        me.doRefresh()
        viewModel.set({
          IsServiceRunning: data['IsServiceRunning'],
          isOperationInProgress: false
        })
      } else {
        viewModel.set({
          IsServiceRunning: data['IsServiceRunning'],
          ServiceMessage: data['Description'],
          isOperationInProgress: false
        })
      }
    })
  },

  doRefresh: function () {
    var me = this

    me.reloadLoadTreeStore()
    me.reloadDemandTreeStore()
  },

  reloadLoadTreeStore: function () {
    var me = this

    var viewModel = me.getViewModel()
    var viewMode = viewModel.get('viewMode')

    var loadStore = viewModel.getStore('loadTreeStore')
    if (viewMode === 2) {
      viewModel.set({
        checkedLoads: []
      })
    }

    loadStore.load()
  },

  reloadDemandTreeStore: function () {
    var me = this

    var viewModel = me.getViewModel()
    var viewMode = viewModel.get('viewMode')

    var demandStore = viewModel.getStore('demandTreeStore')
    if (viewMode === 2) {
      viewModel.set({
        checkedDemands: [],
        firstSum: 0,
        secondSum: 0
      })
    }
    demandStore.load()
  },

  refreshRelatedPopup: function (demandIds, loadIds) {
    var me = this

    var popupIds = Object.keys(me.popups)

    var popupIdsToRefresh = []

    if (demandIds) {
      for (let index = 0; index < demandIds.length; index++) {
        const demandId = demandIds[index]

        if (me.popups.hasOwnProperty('demand' + demandId)) {
          var popup = me.popups['demand' + demandId]
          popupIdsToRefresh.push(popup.tpl.popupId)
        }

        var filteredPopUpIds = popupIds.filter(function (item) {
          return item.indexOf('~' + demandId + '~') > -1
        })

        filteredPopUpIds.forEach((popupIp) => {
          var popup = me.popups[popupIp]
          popupIdsToRefresh.push(popup.tpl.popupId)
        })
      }
    }

    if (loadIds) {
      for (let index = 0; index < loadIds.length; index++) {
        const loadId = loadIds[index]

        if (me.popups.hasOwnProperty('load' + loadId)) {
          var popup = me.popups['load' + loadId]
          popupIdsToRefresh.push(popup.tpl.popupId)
        }

        var filteredPopUpIds = popupIds.filter(function (item) {
          return item.indexOf('~' + loadId + '~') > -1
        })

        filteredPopUpIds.forEach((popupIp) => {
          var popup = me.popups[popupIp]
          popupIdsToRefresh.push(popup.tpl.popupId)
        })
      }

      var popupIds = popupIdsToRefresh.filter((v, i, a) => a.indexOf(v) === i)

      popupIds.forEach((popupId) => {
        me.popups[popupId].tpl.refresh()
      })
    }
  },

  openAllDiffWindow: function () {
    var me = this
    var view = me.getView()
    var viewModel = view.getViewModel()

    viewModel.set('isOperationInProgress', true)
    me.getAllDiff(function (data) {
      if (data.result && data.result.AllSummary) {
        Ext.create('Ext.window.Window', {
          title: 'Difference',
          ui: 'tui-window',
          closeAction: 'hide',
          width: 800,
          height: 475,
          bodyPadding: 4,
          layout: 'fit',
          items: [
            {
              xtype: 'tuiv2-diffoverall',
              data: data.result.AllSummary
            }
          ]
        }).show()
      }
    })
  },

  onEditLoadCard: function (grid, rowIndex) {
    var store = grid.store

    var currentNode = store.getAt(rowIndex)

    var view = this.getView('tuiv2mainview')

    view.fireEvent('ClickLoadEdit')
    view.onClickLoadEdit(currentNode.data)
  },

  onChangeTransportationCard: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var view = me.getView()
    var position = e.getXY()
    var controller = view.getController()

    function formatNumber(value) {
      if (value == Number.NaN || value == Number.MAX_VALUE) value = 0
      if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

      return Ext.util.Format.number(value, '0,000.00')
    }

    function isNull(value) {
      if (!value) {
        return '-'
      } else {
        return value
      }
    }

    function toastResult(result) {
      if (result.IsSuccess) {
        Ext.toast({
          html: result.Message ? result.Message : 'Operation is completed successfully.',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })
      } else {
        Ext.toast({
          html: result.Message ? result.Message : 'Operation failed',
          title: 'Error',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-cross',
          closable: true,
          timeout: 4000
        })
      }
    }

    function changeTransportation(event) {
      me.createMessageBox(
        'Confirm',
        'Are you sure you want to change transportation mode?',
        function (reasonCode) {
          var name =
            event.target.nodeName == 'P'
              ? event.target.innerHTML
              : event.target.children[0].innerHTML

          controller.onChangeTransportationMode(
            reasonCode,
            record.data.LoadName,
            name,
            function (result) {
              toastResult(result)
              if (result.IsSuccess) {
                controller.reloadLoadTreeStore()
              }
            }
          )
        },
        this
      )
    }

    controller.onGetAvailableTransportationModes(record.data.LoadName, function (result) {
      if (result.length == 0) {
        Ext.toast({
          html: 'There is no option to change transportation mode.',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })
        return
      }

      const html = result.reduce(
        (acc, element, index) =>
          `${acc}
          <div class="grid-container-tui dropdown-menu-tui">
            <ul class="card-grid"> 
              <tpl for="TransportationModeOptions"> 
                <div class="point setTransportationMode ${
                  index === result.length - 1 ? 'border-0' : ''
                }"> 
                  <div class="d-flex p-1 pb-0" style="height:25px;">
                    <p class="cp m-0 setTransportationName">${element.Name}</p>  
                    <p class="m-0">${[isNull(element.SubTitle)]}</p> 
                  </div>
                  <div class="count d-flex p-1"> 
                    <p class="m-0">${[formatNumber(element.Utilization * 100)]}% Util</p> 
                    <p class="m-0">${[formatNumber(element.Cost)]} Cost</p> 
                  </div> 
                </div> 
              </tpl> 
            </ul>
          </div>`,
        ''
      )
      if (!this.changeTransportationMenu) {
        this.changeTransportationMenu = Ext.create('Ext.window.Window', {
          scope: me,
          width: 250,
          maxHeight: 400,
          scrollable: 'y',
          modal: false,
          header: false,
          resizable: false,
          style: 'border-color: #ccc;',
          closeAction: 'hide',
          bodyCls: 'none-mode-transportation',
          bodyPadding: 5,
          html,
          listeners: {
            click: {
              element: 'body',
              fn: function (event) {
                if (event.target.className.includes('setTransportationName')) {
                  changeTransportation(event)
                }
              }
            },
            el: {
              blur: function (event) {
                this.hide()
                Ext.getCmp(this.id).setVisible(false)
              }
            }
          }
        })
      }

      if (this.changeTransportationMenu) this.changeTransportationMenu.showAt(position)
    })
  },

  onRemoveLoadsHandler: function (grid, rowIndex) {
    var me = this
    var store = grid.getStore()
    var view = this.getView('tuiv2mainview')

    var controller = view.getController()

    var currentNode = store.getAt(rowIndex)

    var Ids = []

    var loadTree = me.lookupReference('loadTree')
    var records = loadTree.lockedGrid.getChecked()

    if (rowIndex === 0) {
      if (records) {
        records.forEach(function (record) {
          if (
            record.childNodes.length > 0 &&
            record.childNodes &&
            record.childNodes.length > 0 &&
            record.childNodes[0].isLeaf()
          ) {
            Ids.push(record.data.LoadName)
          }
        })
      }
    }

    if (rowIndex !== 0) {
      currentNode.cascade(function (node) {
        var id = node.get('ID')
        if (node.get('leaf') && Ids.indexOf(id) == -1) {
          Ids.push(id)
        }
      })
    }

    function toastResult(result) {
      if (result && result.IsSuccess) {
        Ext.toast({
          html: result.Message ? result.Message : 'Operation is completed successfully.',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })
      } else {
        Ext.toast({
          html: result.Message ? result.Message : 'Operation failed',
          title: 'Error',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-cross',
          closable: true,
          timeout: 4000
        })
      }
    }

    controller.createMessageBox(
      'Confirm: Remove Load',
      'Are you sure you want to remove load? ' + Ids.join(','),
      function (reasonCode) {
        controller.onRemoveLoads(reasonCode, Ids, function (result) {
          toastResult(result)
          if (result.IsSuccess) {
            controller.reloadLoadTreeStore()
            controller.reloadDemandTreeStore()
          }
        })
      },
      this
    )
  },

  onRemoveLoadStop: function (grid, rowIndex) {
    var store = grid.store
    var view = this.getView('tuiv2mainview')

    var controller = view.getController()

    var currentNode = store.getAt(rowIndex)

    function toastResult(result) {
      if (result.IsSuccess) {
        Ext.toast({
          html: result.Message ? result.Message : 'Operation is completed successfully.',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true,
          timeout: 4000
        })
      } else {
        Ext.toast({
          html: result.Message ? result.Message : 'Operation failed',
          title: 'Error',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-cross',
          closable: true,
          timeout: 4000
        })
      }
    }

    controller.createMessageBox(
      'Confirm',
      'Are you sure you want to unserve demand zone?',
      function (reasonCode) {
        controller.RemoveLoadStops(
          reasonCode,
          [currentNode.data.index],
          currentNode.data.LoadName,
          function (result) {
            if (result.IsSuccess) {
              toastResult(result)
              controller.reloadLoadTreeStore()
              controller.reloadDemandTreeStore()
            }
          }
        )
      },
      this
    )
  },

  onOpenLoadCard: function (grid, rowIndex) {
    var store = grid.store

    var currentNode = store.getAt(rowIndex)
    var id = currentNode.childNodes[0].get('ID')

    this.openLoadCard([id])
  },

  openLoadCard: function (loadNames) {
    var me = this
    var view = me.getView()
    var viewModel = view.getViewModel()
    var drawRoute = viewModel.get('drawRoute')
    var mainmap = view.down('mainmap')
    var checkedLoads = viewModel.get('checkedLoads')

    viewModel.set('isOperationInProgress', true)
    viewModel.set('viewMode', 0)
    checkedLoads = checkedLoads.filter(function (value) {
      return _.indexOf(loadNames, value) < 0
    })

    checkedLoads.push(...loadNames)
    viewModel.set('checkedLoads', checkedLoads)

    if (drawRoute) {
      _.forEach(loadNames, (loadId) => {
        me.asyncCaller(
          function () {
            if (mainmap.loadStopDetails[loadId + '-0']) {
              return true
            } else {
              return false
            }
          },
          function () {
            me.openLoadPopup(null, loadId)
          },
          400
        )
      })
    } else {
      var latlng = viewModel.get('disabledMapLatlng')
      _.forEach(loadNames, (loadId) => {
        me.openLoadPopup(latlng, loadId)
      })
    }
    me.setLoadTreeChecked(checkedLoads, true, false)
  },

  onOpenDemandCard: function (grid, rowIndex) {
    var store = grid.store

    var currentNode = store.getAt(rowIndex)
    var id = currentNode.childNodes[0].get('ID')
    var key = currentNode.childNodes[0].get('Key')

    if (
      currentNode.childNodes[0].childNodes &&
      currentNode.childNodes[0].childNodes.length > 0 &&
      currentNode.childNodes[0].childNodes[0]
    ) {
      id = currentNode.childNodes[0].childNodes[0].get('ID')
    }
    this.openDemandCard([id], [key])
  },

  openDemandCard: function (facilityNames, facilityKeys) {
    var me = this

    var view = me.getView()
    var viewModel = view.getViewModel()
    var mainmap = view.down('mainmap')
    var checkedDemands = viewModel.get('checkedDemands')
    checkedDemands = checkedDemands.filter(function (value) {
      return _.indexOf(facilityNames, value) < 0
    })
    checkedDemands.push(...facilityNames)

    viewModel.set('isOperationInProgress', true)
    viewModel.set('checkedDemands', checkedDemands)
    viewModel.set('viewMode', 0)

    _.forEach(facilityKeys, (facilityKey) => {
      setTimeout(function () {
        me.openDemandPopUp(facilityKey)
      }, 0)
    })

    me.setDemandTreeChecked(checkedDemands, true, false)
  },

  removeOptionCard: function (card) {
    var me = this

    var viewModel = me.getViewModel()

    var openedLoadCards = viewModel.get('openedLoadCards')

    openedLoadCards.forEach(function (openedCard) {
      if (openedCard.id == card.id) {
        openedLoadCards.splice(openedLoadCards.indexOf(openedCard), 1)
      }
    })
    viewModel.set('openedLoadCards', openedLoadCards)
  },

  createMessageBox: function (title, message, func) {
    var me = this

    var viewModel = me.getViewModel()

    var reasonCodeStore = viewModel.getStore('reasonCodeStore')

    var isApprove = title === 'Approve'

    var isActionConfirm = isApprove || title === 'Confirm: Remove Load'

    var sessionName = viewModel.get('sessionName'),
      reasonCode = viewModel.get('reasonCode')
    Ext.create('Ext.window.Window', {
      width: !isActionConfirm ? 320 : null,
      minWidth: isActionConfirm ? 400 : null,
      maxWidth: isActionConfirm ? 700 : null,
      minHeight: 100,
      maxHeight: 400,
      modal: true,
      autoShow: true,
      ui: 'tui-window',
      cls: 'tuiv2-confirmationWindow',
      title: title,
      scrollable: 'y',
      items: [
        {
          cls: 'tuiv2-inputArea',
          xtype: 'textfield',
          fieldLabel: 'Session Name',
          displayField: 'Description',
          reference: 'msgSessionNameTextField',
          value: sessionName,
          hidden: isApprove
        },
        {
          cls: 'tuiv2-comboArea',
          xtype: 'combo',
          fieldLabel: 'Choose Reason',
          store: reasonCodeStore,
          editable: false,
          displayField: 'Description',
          valueField: 'Id',
          value: reasonCode,
          hidden: isApprove,
          listeners: {
            afterrender: function (me, eOpts) {
              var selectedDefaultReason = viewModel.get('selectedDefaultReason')
              me.setValue(selectedDefaultReason)
            }
          }
        },
        {
          xtype: 'label',
          text: message,
          maxWidth: isActionConfirm ? 600 : null
        }
      ],
      bbar: [
        '->',
        {
          xtype: 'button',
          text: 'Cancel',
          handler: function () {
            var me = this

            var window = me.up('window')

            window.destroy()
          }
        },
        {
          xtype: 'button',
          text: 'OK',
          handler: function () {
            var button = this

            var window = button.up('window')

            var reasonCombo = window.items.items[1]

            var sessionNameTextBox = window.items.items[0]

            var sessionNameText = sessionNameTextBox.getValue()

            var reasonCode = reasonCombo.getValue()

            window.destroy()
            if (sessionName != sessionNameText) {
              me.onSetSessionStart(sessionNameText, reasonCode, function () {
                func(reasonCode)
                viewModel.set('sessionName', sessionNameText)
                viewModel.set('sessionNameText', sessionNameText)
              })
            } else {
              func(reasonCode)
            }
            viewModel.set('reasonCode', reasonCode)
          }
        }
      ],
      bodyStyle: 'padding:20px;word-wrap: break-word'
    }).show()
  },

  createLoadMessageBox: function (title, message, func) {
    var me = this,
      viewModel = me.getViewModel(),
      reasonCodeStore = viewModel.getStore('reasonCodeStore'),
      transportationModeStore = viewModel.getStore('transportationModeStore'),
      sessionName = viewModel.get('sessionName'),
      reasonCode = viewModel.get('reasonCode'),
      locationColums = viewModel.get('locationColumns'),
      locationStore = Ext.create('Ext.data.Store', {
        fields: ['index', 'name', 'facility'],
        data: locationColums
      })

    Ext.create('Ext.window.Window', {
      width: 320,
      minHeight: 100,
      maxHeight: 400,
      modal: true,
      autoShow: true,
      ui: 'tui-window',
      cls: 'tuiv2-confirmationWindow',
      title: title,
      scrollable: 'y',
      items: [
        {
          cls: 'tuiv2-inputArea',
          xtype: 'textfield',
          fieldLabel: 'Session Name',
          displayField: 'Description',
          reference: 'msgSessionNameTextField',
          value: sessionName
        },
        {
          cls: 'tuiv2-comboArea',
          xtype: 'combo',
          fieldLabel: 'Choose Reason',
          store: reasonCodeStore,
          editable: false,
          displayField: 'Description',
          valueField: 'Id',
          value: reasonCode,
          listeners: {
            afterrender: function (me, eOpts) {
              var selectedDefaultReason = viewModel.get('selectedDefaultReason')
              me.setValue(selectedDefaultReason)
            }
          }
        },
        {
          cls: 'tuiv2-comboArea',
          xtype: 'combo',
          fieldLabel: 'Select Origin Location',
          editable: false,
          displayField: 'name',
          valueField: 'facility',
          store: locationStore
        },
        {
          cls: 'tuiv2-comboArea',
          xtype: 'combo',
          fieldLabel: 'Select Transportation Mode',
          editable: false,
          displayField: 'DisplayName',
          valueField: 'Name',
          store: transportationModeStore,
          listeners: {
            beforerender: function (me, eOpts) {
              me.store.clearData()
            }
          }
        },
        {
          xtype: 'label',
          text: message
        }
      ],
      bbar: [
        '->',
        {
          xtype: 'button',
          text: 'Cancel',
          handler: function () {
            var me = this

            var window = me.up('window')

            window.destroy()
          }
        },
        {
          xtype: 'button',
          text: 'OK',
          handler: function () {
            var button = this
            var window = button.up('window')

            var sessionNameTextBox = window.items.items[0]
            var reasonCombo = window.items.items[1]
            var originLocationCombo = window.items.items[2]
            var transportationModeCombo = window.items.items[3]

            var sessionNameText = sessionNameTextBox.getValue()
            var reasonCode = reasonCombo.getValue()
            var originLocation = originLocationCombo.getValue()
            var transportationModeName = transportationModeCombo.getValue()

            if (!originLocation || !transportationModeName) {
              Ext.Msg.alert('Error', 'Please select both Origin Location and Transportation Mode.')
              return
            }

            window.destroy()

            if (sessionName != sessionNameText) {
              me.onSetSessionStart(sessionNameText, reasonCode, function () {
                func(originLocation, transportationModeName, reasonCode)
                viewModel.set('sessionName', sessionNameText)
                viewModel.set('sessionNameText', sessionNameText)
              })
            } else {
              func(originLocation, transportationModeName, reasonCode)
            }
          }
        }
      ],
      bodyStyle: 'padding:20px;word-wrap: break-word'
    }).show()
  },

  openSessionListWindow: function () {
    var me = this

    var view = me.getView()

    var viewModel = me.getViewModel()

    var store = viewModel.getStore('sessionListStore')

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/getSessions',
      method: 'POST',
      timeout: 600000,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)
        store.loadData(data)

        if (this.sessionlistwindow) {
          this.sessionlistwindow.show()
        } else {
          this.sessionlistwindow = view.add({ xtype: 'tuiv2-sessionlistwindow' }).show()
        }
      }
    })
  },

  getSessionDiff: function (view, rowIndex, colIndex, item, e, record, row) {
    var me = this

    var id = record.get('Id')
    me.getSnapshotDiff(id, function (data) {
      if (data.result && data.result.DiffData && data.result.DiffData.length > 0) {
        Ext.create('tuiv2.cmp.DiffWindow', {
          diffData: data.result.DiffData
        }).show()
      }
    })
  }
})
