import { put, select } from 'redux-saga/effects'
import _ from 'lodash'
import pubsub from '../../../subs'
import { addMessage } from '../../../store/slices/logger'

export default function* runRemoteAction(props = {}, result, action) {
  const { remoteActionKeys = [] } = props
  const {
    payload: {
      params: { storeIndex },
      relations = {}
    }
  } = action

  const { relationReducers = {} } = yield select()

  const { stores = [] } = relationReducers
  const store = stores[storeIndex] || {}
  const {
    global: { variables: globalVariables = {} } = {},
    page: { variables: pageVariables = {} } = {},
    menu: { variables: menuVariables = {} } = {}
  } = store || {}

  const variables = {
    ...pageVariables,
    ...menuVariables,
    ...globalVariables,
    ...result
  }

  const msg = []
  _.each(remoteActionKeys, ({ remoteActionKey }) => {
    const { menus: { [remoteActionKey]: { drilldown } = {} } = {} } = relations
    const args = _.transform(
      drilldown,
      (r, v, k) => {
        if (!_.isNil(variables[v])) {
          r[k] = variables[v]
        }
      },
      {}
    )

    msg.push({
      title: `${remoteActionKey}`,
      text: _.reduce(
        args,
        (r, v, k) => {
          return `${r}<br /><label>${k}</label>: ${v}</i>`
        },
        ''
      )
    })

    try {
      pubsub.publish(`runRemoteAction_${remoteActionKey}`, args)
    } catch (err) {
      console.error(err)
    }
  })

  for (let j = 0; j < msg.length; j++) {
    yield put(addMessage({ flag: 'Remote Action', title: msg[j].title, message: msg[j].text }))
  }

  return result
}
