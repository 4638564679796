export default () => {
  return {
    title: 'TextField',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          text: {
            type: 'string',
            title: 'Text'
          },
          placeHolder: {
            type: 'string',
            title: 'Placeholder',
            default: ''
          },
          autocompleteLength: {
            type: 'number',
            title: 'Autocomplete Length',
            default: 3
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          id: {
            type: 'string',
            title: 'ID',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          textColumn: {
            type: 'string',
            title: 'Text Field',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          autocompleteColumn: {
            type: 'string',
            title: 'Autocomplete Field',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          argumentName: {
            type: 'string',
            title: 'Argument Name'
          }
        }
      }
    }
  }
}
