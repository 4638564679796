import { Component } from 'react'
import _ from 'lodash'
import { defaultTemplate } from '../helpers'
import { SlvySelect } from '../../../index'

export default class SelectEditor extends Component {
  constructor() {
    super()
    this.state = {
      value: {}
    }

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    let {
      schema: { default: schemaDefault },
      value
    } = this.props

    value = this.setValue(!_.isUndefined(value) ? value : schemaDefault)
    this.setState({ value })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: this.setValue(nextProps.value) })
    }
  }

  handleChange(nextValue) {
    const {
      value: { value }
    } = this.state

    const selectedValue = Array.isArray(nextValue)
      ? nextValue.map((v) => v.value)
      : nextValue?.value

    if (!_.isEqual(nextValue, value)) {
      this.setState({ value: nextValue }, this.props.onChange(selectedValue))
    }
  }

  setValue(value) {
    const {
      schema: { isMulti }
    } = this.props

    if (isMulti && _.isArray(value)) {
      return value.map((v) => ({
        label: v,
        value: v
      }))
    }

    return { label: value, value }
  }

  typecast(schema, value) {
    if (schema.type === 'boolean')
      return value === 'undefined' || value === undefined ? undefined : !!value
    if (schema.type === 'number') return 1 * value || 0
    if (schema.type === 'integer') return Math.floor(value * 1 || 0)
    return `${value}`
  }

  getEnumSourceValue(src) {
    let source = []
    const options = []

    if (_.isArray(src.source)) {
      source = src.source
    } else if (_.isString(src.source)) {
      source = this.props.getWatchedValue(this.props.schema.watch[src.source])
    }

    if (!_.isNil(source)) {
      const valueTemplate = src.value ? defaultTemplate().compile(src.value) : null
      const labelTemplate = src.title ? defaultTemplate().compile(src.title) : null

      source.forEach((item, i) => {
        const value = valueTemplate ? valueTemplate({ i, item }) : item
        const label = labelTemplate ? labelTemplate({ i, item }) : value

        options[i] = {
          value,
          label
        }
      })
    }

    return options
  }

  render() {
    const { schema } = this.props
    const { value } = this.state

    let val = !_.isNil(value) ? value : schema.default
    // <SlvySelect /> doesn't accept { label: {}, value: {} } as no selection value
    val = _.isEqual(val, { label: {}, value: {} }) ? null : val

    if (!_.isArray(val) && !val?.value && schema.isMulti) {
      val = null
    }

    let options = []
    let isSearchable = true

    if (schema.enum) {
      const display = (schema.options && schema.options.enum_titles) || []

      schema.enum.forEach((option, i) => {
        options[i] = {
          value: this.typecast(schema, option),
          label: `${display[i] || option}`
        }
      })

      if (!schema.required) {
        options.unshift({ value: undefined, label: ' ' })
      }
    } else if (schema.type === 'boolean') {
      const labels = (schema.options && schema.options.enum_titles) || ['true', 'false']

      options[0] = { value: true, label: labels[0] }
      options[1] = { value: false, label: labels[1] }

      isSearchable = false

      if (!schema.required) {
        options.unshift({ value: undefined, label: ' ' })
      }
    } else if (schema.enumSource) {
      if (_.isString(schema.enumSource)) {
        const arr = this.getEnumSourceValue({
          source: schema.enumSource,
          value: schema.enumValue,
          title: schema.enumTitle
        })
        options = options.concat(arr)
      } else if (_.isArray(schema.enumSource)) {
        for (let i = 0; i < schema.enumSource.length; i++) {
          const src = schema.enumSource[i]
          const arr = this.getEnumSourceValue(src)
          options = options.concat(arr)
        }
      }
    }

    // TODO : fix: empty string ui render problem

    return (
      <div className="w-55 selectEditorWidth float-start px-0">
        <SlvySelect
          className="col-md-6 w-100 px-0"
          isClearable={false}
          isMulti={schema.isMulti}
          isSearchable={isSearchable}
          options={options}
          placeholder="select"
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 3
            })
          }}
          value={val}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
