import React from 'react'
import {
  FormControl,
  Button,
  OverlayTrigger as BSOverlayTrigger,
  Popover,
  FormGroup,
  Col,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import '../index.scss'

export default class OverlayTrigger extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.onChangeStateName = this.onChangeStateName.bind(this)
    this.saveState = this.saveState.bind(this)

    this.state = {
      stateName: ''
    }
  }

  onChangeStateName(e) {
    this.setState({ stateName: e.target.value })
  }

  saveState() {
    document.body.click() // popover hidding
    const { stateName } = this.state
    this.props.saveState(stateName)
  }

  popoverClickRootClose() {
    return (
      <Popover className="StateSaverDiv" id="popover-trigger-click-root-close">
        <Popover.Header as="h3" className="mb-0">
          Please name your filter
        </Popover.Header>
        <Popover.Body>
          <div className="d-flex gap-2">
            <FormControl
              placeholder="Filter name"
              size="sm"
              type="text"
              value={this.state.stateName}
              onChange={this.onChangeStateName}
            />
            <Button size="sm" variant="success" onClick={this.saveState}>
              Save
            </Button>
          </div>
        </Popover.Body>
      </Popover>
    )
  }

  render() {
    const { resetShow = true, newStateText = 'New state', newStatePlacement = 'left' } = this.props

    return (
      <BSOverlayTrigger
        rootClose
        overlay={this.popoverClickRootClose()}
        placement={newStatePlacement}
        trigger="click"
      >
        <Button className={`btn-min-w w-100 ${resetShow}`} size="sm" variant="primary">
          {' '}
          {_.isEmpty(newStateText) ? 'Save Filter' : newStateText}
        </Button>
      </BSOverlayTrigger>
    )
  }
}
