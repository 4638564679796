import api from '../../api'
import { toURLSearchParams, getTreeQueryParams } from '../../../utils'
import {
  AllActionsDiff,
  ApprovedLoad,
  AutoServe,
  ChangeTransportationMode,
  CreateLoad,
  DemandDetail,
  DemandTree,
  GetAvailableTransportationModes,
  GetDemandTransportationModes,
  LastActionDiff,
  LoadDetail,
  LoadStopEntryTable,
  LoadStopTable,
  LoadTable,
  LoadTree,
  MapElements,
  ReasonCode,
  Refix,
  RemoveLoads,
  RemoveLoadStop,
  ResetPlan,
  RunInfo,
  SavedPlan,
  ServeForm,
  Service,
  ServiceStatus,
  Session,
  SetServiceWithConnectionId,
  SnaphotSnapshotWithStringIdReturnType,
  Snapshot,
  SnapshotDiff,
  SnapshotWithStringId
} from './endpoint.types'

const enhancedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllActionsDiff: builder.query<AllActionsDiff, void>({
      query: () => 'getAllDiff'
    }),
    getDemandDetailById: builder.mutation<DemandDetail, { id: string }>({
      query: ({ id }) => ({
        url: 'GetDemandDetailById',
        data: new URLSearchParams(toURLSearchParams({ id: Number(id) })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getDemandTree: builder.mutation<
      DemandTree,
      {
        columns: ReturnType<typeof getTreeQueryParams>['columns']
        filters: ReturnType<typeof getTreeQueryParams>['filters']
        hierarchy: ReturnType<typeof getTreeQueryParams>['hierarchy']
      }
    >({
      query: ({ columns, filters, hierarchy }) => ({
        url: 'gettreeread',
        method: 'POST',
        data: new URLSearchParams(
          toURLSearchParams({ columns, filters, hierarchy, treeType: 1, node: 'root' })
        ),
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        },
        params: {
          _dc: Date.now()
        }
      })
    }),
    getLastActionDiff: builder.query<LastActionDiff, void>({
      query: () => 'getActionDiff'
    }),
    getLoadDetailById: builder.mutation<LoadDetail, { id: string }>({
      query: ({ id }) => ({
        url: 'GetLoadDetailById',
        data: new URLSearchParams(toURLSearchParams({ id: Number(id) })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getLoadStopEntryTable: builder.query<
      LoadStopEntryTable,
      { id: string; loadStopSequence: string; columns: any }
    >({
      query: ({ id, loadStopSequence, columns }) => ({
        url: 'getloadstopentrytableread',
        data: new URLSearchParams(toURLSearchParams({ loadId: id, loadStopSequence, columns })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getLoadStopTable: builder.query<LoadStopTable, { id: string; columns: any }>({
      query: ({ id, columns }) => ({
        url: 'getloadstoptableread',
        data: new URLSearchParams(toURLSearchParams({ id: Number(id), columns })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getLoadTable: builder.query<LoadTable, { loadIds: string[]; columns: any }>({
      query: ({ loadIds, columns }) => ({
        url: 'getloadtableread',
        data: new URLSearchParams(toURLSearchParams({ loadIds, columns })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getLoadTree: builder.mutation<
      LoadTree,
      {
        columns: ReturnType<typeof getTreeQueryParams>['columns']
        filters: ReturnType<typeof getTreeQueryParams>['filters']
        hierarchy: ReturnType<typeof getTreeQueryParams>['hierarchy']
      }
    >({
      query: ({ columns, filters, hierarchy }) => ({
        url: 'gettreeread',
        method: 'POST',
        data: new URLSearchParams(
          toURLSearchParams({ columns, filters, hierarchy, treeType: 0, node: 'root' })
        ),
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        },
        params: {
          _dc: Date.now()
        }
      })
    }),
    getMapElements: builder.query<
      MapElements,
      { loadIds: string[]; demandIds: string[]; loadSummaries: string[] }
    >({
      query: ({ loadIds, demandIds, loadSummaries }) => ({
        url: 'LoadMapObjects',
        data: new URLSearchParams(toURLSearchParams({ loadIds, demandIds, loadSummaries })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getRunInfo: builder.query<RunInfo[], number>({
      query: (currentScenarioId) => ({
        url: 'getruninforead',
        data: new URLSearchParams(toURLSearchParams({ currentScenarioId })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        },
        params: {
          _dc: Date.now()
        }
      })
    }),
    getSnapshot: builder.query<Snapshot, void>({
      query: () => ({
        url: 'snapshotread',
        params: {
          _dc: Date.now()
        }
      })
    }),
    getSnapshotDestroy: builder.query<SnaphotSnapshotWithStringIdReturnType, SnapshotWithStringId>({
      query: ({ CreatedAt, CreatedBy, Description, Id, Name }) => ({
        url: 'snapshot_destroy',
        data: {
          data: [{ CreatedAt, CreatedBy, scenarioDescription: Description, scenarioId: Id, Name }]
        },
        method: 'POST',
        params: {
          _dc: Date.now()
        }
      })
    }),
    getSnapshotCreate: builder.query<SnaphotSnapshotWithStringIdReturnType, SnapshotWithStringId>({
      query: ({ CreatedAt, CreatedBy, Description, Id, Name }) => ({
        url: 'snapshot_create',
        data: {
          data: [{ CreatedAt, CreatedBy, scenarioDescription: Description, scenarioId: Id, Name }]
        },
        method: 'POST',
        params: {
          _dc: Date.now()
        }
      })
    }),
    getServices: builder.query<Service[], void>({
      query: () => ({
        url: 'GetServiceLists',
        method: 'POST',
        params: { _dc: Date.now() }
      })
    }),
    getServiceStatus: builder.query<ServiceStatus, void>({
      query: () => 'isServiceRunning'
    }),
    getSessions: builder.query<Session[], void>({
      query: () => 'getSessions'
    }),
    getSnapshotDiff: builder.query<SnapshotDiff, { id: string }>({
      query: ({ id }) => ({
        url: 'GetSnapshotDiff',
        data: new URLSearchParams({ id }),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getReasonCodes: builder.query<ReasonCode[], void>({
      query: () => 'getreasoncodesread'
    }),
    removeLoad: builder.query<RemoveLoads, { loadId: number; reasonCodeId: number }>({
      query: ({ loadId, reasonCodeId }) => ({
        url: 'RemoveLoad',
        data: new URLSearchParams(toURLSearchParams({ loadId, reasonCodeId })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    removeLoads: builder.query<RemoveLoads, { loadNames: string[]; reasonCodeId: number }>({
      query: ({ loadNames, reasonCodeId }) => ({
        url: 'RemoveLoads',
        data: new URLSearchParams(toURLSearchParams({ loadNames, reasonCodeId })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getRemoveLoadStop: builder.query<
      RemoveLoadStop,
      { loadStopIndex: number[]; loadId: number; reasonCodeId: number }
    >({
      query: ({ loadStopIndex, loadId, reasonCodeId }) => ({
        url: 'RemoveLoadStops',
        data: new URLSearchParams(toURLSearchParams({ loadStopIndex, loadId, reasonCodeId })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getAvailableTransportationModes: builder.mutation<
      GetAvailableTransportationModes,
      { id: number }
    >({
      query: ({ id }) => ({
        url: 'GetAvailableTransportationModes',
        data: new URLSearchParams(toURLSearchParams({ id })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    getDemandTransportationModes: builder.query<
      GetDemandTransportationModes,
      { facilityKey: string[]; orderNumber: number; query: string }
    >({
      query: ({ facilityKey, orderNumber, query }) => ({
        url: 'GetAvailableTransportationModesForDemands',
        data: new URLSearchParams(toURLSearchParams({ facilityKey, orderNumber, query })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    createLoad: builder.mutation<
      CreateLoad,
      {
        createNewLoadRequest: Array<{
          facilityKey: string
          itemGroup: string
          sourceLoadName: string
          quantity: 0
        }>
        originLocation: string
        transportationModeName: string
        reasonCodeId: number
      }
    >({
      query: ({ ...args }) => ({
        url: 'CreateNewLoad',
        data: new URLSearchParams(toURLSearchParams({ ...args })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setChangeTransportationMode: builder.query<
      ChangeTransportationMode,
      { id: number; transportationModeName: number; reasonCodeId: number }
    >({
      query: ({ id, transportationModeName, reasonCodeId }) => ({
        url: 'ChangeTransportationMode',
        data: new URLSearchParams(toURLSearchParams({ id, transportationModeName, reasonCodeId })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setApprovedLoads: builder.mutation<ApprovedLoad, string[]>({
      query: (ids) => ({
        url: 'ApproveLoad',
        data: new URLSearchParams(toURLSearchParams({ ids })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setResetPlan: builder.query<ResetPlan, number>({
      query: (runInfoId) => ({
        url: 'reset',
        data: new URLSearchParams(toURLSearchParams({ runInfoId })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        },
        params: {
          _dc: Date.now()
        }
      })
    }),
    setSavedPlan: builder.query<
      SavedPlan,
      { planName: string; planDescription: string; reSequence: boolean; userName: null }
    >({
      query: ({ planName, planDescription, reSequence, userName }) => ({
        url: 'SavePlan',
        data: new URLSearchParams(
          toURLSearchParams({ planName, planDescription, reSequence, userName })
        ),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setService: builder.mutation<Service, { runParameter: string; serviceAddress: string }>({
      query: ({ runParameter, serviceAddress }) => ({
        url: `SetService`,
        data: new URLSearchParams({ runParameter, serviceAddress }),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
    }),
    setSessionStart: builder.query<
      RunInfo[],
      { description: string | undefined; reasonCode: number | undefined }
    >({
      query: ({ description, reasonCode }) => ({
        url: 'SetSessionStart',
        data: new URLSearchParams(toURLSearchParams({ description, reasonCode })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setRefix: builder.query<Refix[], { loadIndexes: number[]; loadNames: string[] }>({
      query: ({ loadIndexes, loadNames }) => ({
        url: 'Refix',
        data: new URLSearchParams(toURLSearchParams({ loadIndexes, loadNames })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setAutoServe: builder.query<AutoServe[], { demandIndexes: number[] }>({
      query: ({ demandIndexes }) => ({
        url: 'AutoServe',
        data: new URLSearchParams(toURLSearchParams({ demandIndexes })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setServeFrom: builder.query<
      ServeForm[],
      { demandIndexes: number[]; serveFacilityIndex: string }
    >({
      query: ({ demandIndexes, serveFacilityIndex }) => ({
        url: 'SetServeFrom',
        data: new URLSearchParams(toURLSearchParams({ demandIndexes, serveFacilityIndex })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    }),
    setServiceWithConnectionId: builder.mutation<
      SetServiceWithConnectionId,
      { dataConnectionId: number }
    >({
      query: ({ dataConnectionId }) => ({
        url: 'SetServiceWithConnectionId',
        data: new URLSearchParams(toURLSearchParams({ dataConnectionId })),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
    })
  })
})

export default enhancedApi
