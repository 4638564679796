import { v5 } from 'uuid'
import {
  CRUDV2_FETCH_REQUEST,
  CRUDV2_FETCH_SUCCESS,
  CRUDV2_FETCH_FAILURE,
  CRUDV2_FETCH_ONE_REQUEST,
  CRUDV2_FETCH_ONE_SUCCESS,
  CRUDV2_FETCH_ONE_FAILURE,
  CRUDV2_UPDATE_REQUEST,
  CRUDV2_UPDATE_SUCCESS,
  CRUDV2_UPDATE_FAILURE,
  CRUDV2_DELETE_REQUEST,
  CRUDV2_DELETE_SUCCESS,
  CRUDV2_DELETE_FAILURE,
  CRUDV2_CREATE_REQUEST,
  CRUDV2_CREATE_SUCCESS,
  CRUDV2_CREATE_FAILURE,
  CRUDV2_CLEAR_ALL,
  CRUDV2_CLEAR_MODEL
} from './actionTypes'

const CRUD_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341'

const initialMeta = {
  version: 3,
  method: 'get',
  getRowKey: (row, index) => row.id,
  mapResult: (result, currentResult) => result
}

export const fetchCollection = (model, path, { params = {}, data = {} } = {}, opts = {}) => {
  const newOpts = { ...initialMeta, ...opts }

  return {
    type: CRUDV2_FETCH_REQUEST,
    meta: {
      ...newOpts,
      success: CRUDV2_FETCH_SUCCESS,
      failure: CRUDV2_FETCH_FAILURE,
      model,
      path,
      params,
      data,
      collectionKey: v5(JSON.stringify({ path, params, data }), CRUD_NAMESPACE)
    },
    payload: {}
  }
}

export const fetchOne = (model, path, { params = {}, data = {} } = {}, opts = {}) => {
  const newOpts = { ...initialMeta, ...opts }
  return {
    type: CRUDV2_FETCH_ONE_REQUEST,
    meta: {
      ...newOpts,
      success: CRUDV2_FETCH_ONE_SUCCESS,
      failure: CRUDV2_FETCH_ONE_FAILURE,
      model,
      path,
      params,
      data
    },
    payload: {}
  }
}

export const fetchRecord = (model, path, { data = {}, params = {} }, opts = {}) => {
  const newOpts = {
    ...initialMeta,
    method: 'post',
    operationKey: 'create',
    ...opts
  }

  return {
    type: CRUDV2_CREATE_REQUEST,
    meta: {
      ...newOpts,
      success: CRUDV2_CREATE_SUCCESS,
      failure: CRUDV2_CREATE_FAILURE,
      model,
      path,
      data,
      collectionKey: v5(
        JSON.stringify({
          path: '',
          params: {},
          data: {},
          ...opts.collectionKey
        }),
        CRUD_NAMESPACE
      )
    },
    payload: {
      data
    }
  }
}

export const updateOne = (model, path, { data = {}, params = {} }, opts = {}) => {
  const newOpts = {
    ...initialMeta,
    ...{
      method: 'put',
      operationKey: 'update'
    },
    ...opts
  }
  return {
    type: CRUDV2_UPDATE_REQUEST,
    meta: {
      ...newOpts,
      version: 3,
      success: CRUDV2_UPDATE_SUCCESS,
      failure: CRUDV2_UPDATE_FAILURE,
      model,
      path,
      data,
      collectionKey: v5(
        JSON.stringify({
          path: '',
          params: {},
          data: {},
          ...opts.collectionKey
        }),
        CRUD_NAMESPACE
      )
    },
    payload: {
      data
    }
  }
}

export const deleteRecord = (model, path, { data = {}, params = {} } = {}, opts = {}) => {
  const newOpts = {
    ...initialMeta,
    ...{
      method: 'del',
      operationKey: 'delete'
    },
    ...opts
  }
  return {
    type: CRUDV2_DELETE_REQUEST,
    meta: {
      ...newOpts,
      success: CRUDV2_DELETE_SUCCESS,
      failure: CRUDV2_DELETE_FAILURE,
      model,
      params,
      path,
      data,
      collectionKey: v5(
        JSON.stringify({
          path: '',
          params: {},
          data: {},
          ...opts.collectionKey
        }),
        CRUD_NAMESPACE
      )
    },
    payload: {
      data
    }
  }
}

export const clearAll = () => {
  return {
    type: CRUDV2_CLEAR_ALL,
    meta: {
      version: 3,
      model: '1'
    }
  }
}

export const clearModel = (model) => {
  return {
    type: CRUDV2_CLEAR_MODEL,
    meta: {
      version: 3,
      model
    }
  }
}
