import { getColumnDefsWithHeaderGroups } from '../index'
import { getExpectedColumnDefs, getInputColumnDefs } from './mocks'
import { pluginData } from '../../__tests__/mocks'

const firstAgGridRowData = pluginData[0]

describe('AgGrid:Helpers:GroupHeader:getColumnDefsWithHeaderGroups', () => {
  let params = { hasGroupHeader: true }
  let inputColumnDefs = getInputColumnDefs(params)

  beforeEach(() => {
    params = { hasGroupHeader: true }
    inputColumnDefs = getInputColumnDefs(params)
  })

  it('should not return the same column definitions', () => {
    const result = getColumnDefsWithHeaderGroups(inputColumnDefs, firstAgGridRowData)
    expect(result).not.toEqual(inputColumnDefs)
  })

  it('should not group columns if no group header is provided', () => {
    params.hasGroupHeader = false
    const inputColumnDefsWithoutGroupHeader = getInputColumnDefs(params)
    const result = getColumnDefsWithHeaderGroups(
      inputColumnDefsWithoutGroupHeader,
      firstAgGridRowData
    )

    expect(result).toEqual(inputColumnDefsWithoutGroupHeader)
  })

  it('should group columns based on the group header information', () => {
    const expectedColumnDefs = getExpectedColumnDefs(params)
    const result = getColumnDefsWithHeaderGroups(inputColumnDefs, firstAgGridRowData)
    expect(result).toEqual(expectedColumnDefs)
  })
})
