/* eslint-disable max-classes-per-file */
import { MESSAGING_URL } from '@/constants'
import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr'
import _ from 'lodash'

const settings = {
  huburl: MESSAGING_URL,
  connectionStatus: {
    0: 'connecting',
    1: 'connected',
    2: 'reconnecting',
    4: 'disconnected'
  },
  logLevelStatus: {
    0: 'Trace',
    1: 'Debug',
    2: 'Information',
    3: 'Warning',
    4: 'Error',
    5: 'Critical',
    6: 'None'
  },
  retryTimeout: 15000,
  retryLimit: 3
}

let retryCount = 0
let forceLongPolling = false
let signalrClient = {}
let signalrClientLog = []

const logErrorMessages = () => {}

const getConnectionState = () => {
  const { connection: { state = 0 } = {} } = signalrClient
  return settings.connectionStatus[state]
}

const pushLogMessage = (params = {}) => {
  let {
    logLevelState = 'Status',
    message = params,
    connectionState = getConnectionState(),
    currentState = ''
  } = params
  currentState = _.isEmpty(currentState) ? connectionState : currentState

  signalrClientLog.push({
    logLevelState,
    message,
    connectionState,
    currentState
  })
}

const getConnection = (accessToken) => {
  const transport = forceLongPolling
    ? HttpTransportType.LongPolling
    : HttpTransportType.WebSockets |
      HttpTransportType.LongPolling |
      HttpTransportType.ServerSentEvents

  return new HubConnectionBuilder()
    .withUrl(settings.huburl, { accessTokenFactory: () => accessToken, transport })
    .configureLogging(new MyLogger())
    .build()
}

export class MyLogger {
  log(logLevel, message) {
    if (logLevel === 4) {
      logErrorMessages(message)
      if (message.indexOf('handshake') > -1) {
        forceLongPolling = true
      }
    }

    const logLevelState = settings.logLevelStatus[logLevel]

    dispatchEvent(new CustomEvent('MessagingLogLevel', { detail: signalrClientLog }))

    if (logLevelState !== 'Trace') {
      pushLogMessage({
        logLevelState,
        message
      })
    }

    if (window.debugmessaging) {
      console.log(`${logLevel}: ${message}`)
    }
  }
}
class SignalrClient {
  constructor(props) {
    const { access_token: accessToken } = props
    let connection = getConnection(accessToken)

    const status = () => signalrClientLog
    const start = () => {
      pushLogMessage('Trying to connect')

      connection
        .start()
        .then((res) => {
          retryCount = 0
          pushLogMessage({ message: 'Connection ok', currentState: 'connected' })
          connection
            .invoke('GetAccumulatedMessages')
            .then(() =>
              pushLogMessage({ message: 'Get accumulated messages', currentState: 'connected' })
            )
        })
        .catch((err) => {
          connection = null
          signalrClientLog = []

          pushLogMessage(`Catch error: ${err}`)

          if (retryCount < settings.retryLimit) {
            setTimeout(() => {
              connection = getConnection(accessToken)
              start()
              retryCount++
            }, settings.retryTimeout)
          } else {
            connection = null
            retryCount = 0
            forceLongPolling = true
            connection = getConnection(accessToken)
            start()
          }
        })
    }

    connection.onclose(async () => {
      pushLogMessage('Connection closed')
      start()
    })

    start()

    this.isConnectionAlive = () => 'connection' in connection

    connection.off('ReceiveMessage')
    connection.on('ReceiveMessage', (messages) => {
      const groupMessage = _.groupBy(messages, 'messageType')
      dispatchEvent(new CustomEvent('MessagingReceiveMessage', { detail: groupMessage }))
    })

    this.sendMessage = ({ receiverUserId, message }) =>
      new Promise((resolve, reject) => {
        connection
          .invoke('SendObject', { receiverUserId, message })
          .catch((err) => reject({ err, ...{ isok: false } }))

        resolve({ isok: true })
      })

    this.connection = connection
    this.status = status

    return this
  }
}

export default {
  init: (params) => {
    signalrClient = new SignalrClient(params)
  },
  get: () => ('connection' in signalrClient ? signalrClient : null)
}
