import { useMemo } from 'react'
import { pick } from 'lodash'

import { CreateColumnDefsProps, CreateColumnDefsReturnType } from './useCreateColumnDefs.types'
import { SlvyColDef } from '../../../../../types'
import { constantColDefs, commonColDefKeys, conditionalColDefKeys } from './constants'

const useCreateColumnDefs = ({
  mainGridFirstColDef
}: CreateColumnDefsProps): CreateColumnDefsReturnType => {
  // Pick only the necessary fields from main grid column defs
  // Checks each main grid column defs update
  // IMPORTANT: lodash's pick excludes "undefined" values!
  const commonColDefs = useMemo(
    () => pick(mainGridFirstColDef, commonColDefKeys),
    [mainGridFirstColDef]
  )

  // IMPORTANT: lodash's pick excludes "undefined" values!
  const conditionalColDefs = useMemo(
    () => pick(mainGridFirstColDef, conditionalColDefKeys),
    [mainGridFirstColDef]
  )

  // Extend "cellEditorSelector" if its exist!
  const editorSelector = useMemo(() => {
    if (typeof conditionalColDefs?.cellEditorSelector === 'undefined') {
      return {}
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cellEditorSelectorResult = conditionalColDefs?.cellEditorSelector()

    const newCellEditorParams: Partial<{
      shouldAutoStopEditing: boolean
      selectProps: object
      isMassUpdateModalVisible: boolean
    }> = {
      isMassUpdateModalVisible: true
    }

    if (cellEditorSelectorResult?.params) {
      const { editorName } = cellEditorSelectorResult.params as any
      if (editorName === 'lookup' || editorName === 'booleanIcon') {
        newCellEditorParams.shouldAutoStopEditing = false
      }
      if (editorName === 'lookup') {
        newCellEditorParams.selectProps = {}
      }
    }

    return {
      cellEditorSelector: () => {
        return {
          ...cellEditorSelectorResult,
          params: {
            ...cellEditorSelectorResult?.params,
            ...newCellEditorParams
          }
        }
      }
    }
  }, [conditionalColDefs])

  const columnDefs = useMemo((): Partial<SlvyColDef>[] => {
    return [{ ...constantColDefs, ...commonColDefs, ...editorSelector }]
  }, [commonColDefs, editorSelector])

  return {
    columnDefs
  }
}

export default useCreateColumnDefs
