import { STORE_TIMELINE_SETTINGS, RESET_TIMELINE_SETTINGS } from '../actions/actionTypes'

const initialState = {
  timelineSettings: {
    Collapse: {
      isCollapsed: false,
      isPin: false
    },
    AggregationLevelId: null,
    // StartDate: '',
    // EndDate: '',
    // StartDisplayName: '',
    // EndDisplayName: '',
    // PeriodName: '',
    AggregationPeriods: {}
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_TIMELINE_SETTINGS:
      return {
        timelineSettings: {
          ...state.timelineSettings,
          ...action.payload
        }
      }
    case RESET_TIMELINE_SETTINGS:
      return {
        timelineSettings: {
          ...state.timelineSettings,
          ...initialState
        }
      }
    default:
      return state
  }
}

export default reducer
