Ext.define('MDM.src.view.Grouping.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.grouping-maincontroller',
  /**
   * Called when the view is created
   */
  init: function (view) {
    var me = this,
      vm = this.getViewModel(),
      owner = vm.get('owner'),
      isReadOnly = MDM.Global.getPermission(owner),
      isGlobal = MDM.Global.isGlobal(),
      region = MDM.Global.getRegion()
    vm.set('readOnlyMode', isReadOnly)
    vm.set('isReadOnly', isReadOnly)
    vm.set('isGlobal', isGlobal)
    vm.set('userMegaRegion', region)
  },
  onCusHieStoreLoad: function (store) {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      store = viewModel.getStore('CusHieStore'),
      grid = me.lookup('cushie')
    grid.getSelectionModel().select(0)
    var grpgrid = this.lookup('cushie'),
      groupingFeature = grpgrid.view.lockedView.findFeature('grouping')
    groupingFeature.expandAll()
  },
  CusHieRelStoreLoad: function (store) {
    var me = this,
      famgrid = me.lookup('famgrid'),
      famgridFeature = famgrid.view.findFeature('grouping')
    famgridFeature.expandAll()
  },
  onCusHieSelect: function (grid, record) {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      store = viewModel.getStore('CusHieRelStore'),
      rec = Ext.create('MDM.src.view.Grouping.model.CusHie'),
      grid = me.lookup('cushie')
    this.getViewModel().set('curCusHieRec', record)
    store.getProxy().setExtraParam('CusHieID', record.get('Id'))
    store.load()
  },
  onShowTree: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      store = this.getViewModel().getStore('DPITreeStore'),
      cushie = me.lookup('cushie')
    cushie.getSelectionModel().select(record)
    var CusHieRelStore = this.getViewModel().getStore('CusHieRelStore')
    store.getProxy().setExtraParam('Id', record.get('Id'))
    me.getViewModel().set('actionRecord', record)
    var grid = me.lookup('cushie')
    grid.getSelectionModel().select(record)
    var win = Ext.create('MDM.src.view.Grouping.Edit.Main', {
      grpRecord: record
    }).show()
    win.on('update', function () {
      CusHieRelStore.load()
    })
    return

    if (!me.win) {
      me.win = Ext.create('Ext.window.Window', {
        layout: 'fit',
        closeAction: 'hide',
        bodyStyle: {
          background: '#d8dce3'
        },
        ui: 'window-inverse',
        title: 'Name of selected group :' + record.get('Descriptions'),
        modal: true,
        bodyPadding: 10,
        width: 500,
        height: 800,
        bbar: [
          '->',
          {
            text: 'Apply',
            iconCls: 'fa fa-bolt',
            handler: 'onApply',
            ui: 'btn-primary'
          }
        ],
        items: [
          {
            xtype: 'grouping-tree'
          }
        ]
      })

      me.getView().add(me.win)
    }
    me.win.setTitle('Group Name : ' + record.get('Descriptions'))
    me.win.show()
  },
  onApply: function () {
    var me = this,
      records = me.lookup('tree').getChecked(),
      actionRecord = me.getViewModel().get('actionRecord'),
      names = [],
      DPIFamilyIDS = []

    Ext.Array.each(records, function (rec) {
      if (rec.isLeaf()) {
        names.push(rec.get('text'))
        DPIFamilyIDS.push({
          CusHieID: actionRecord.get('Id'),
          DPIFamilyID: rec.get('DPIFamilyID')
        })
      }
    })

    me.postResults(DPIFamilyIDS)
  },
  postResults: function (data) {
    var me = this,
      store = this.getViewModel().getStore('CusHieRelStore')
    me.win.setLoading('Saving changes. Please wait!')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/CusHieMass_Update',
      timeout: 360000,
      params: {
        data: Ext.JSON.encode(data)
      },
      success: function (response, opts) {
        me.win.setLoading(false)
        store.load()
        me.win.hide()
      },
      failure: function (response, opts) {
        Ext.MessageBox.hide()
        console.log('server-side failure with status code ' + response.status)
      }
    })
  },
  onAddNew: function () {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      store = viewModel.getStore('CusHieStore'),
      rec = Ext.create('MDM.src.view.Grouping.model.CusHie', {
        isEditable: true
      }),
      grid = me.lookup('cushie')
    me.getViewModel().set('editedRecord', rec)
    if (!this.win) {
      this.win = Ext.create({
        title: 'Grouping',
        iconCls: 'fa fa-pencil',
        xtype: 'form-grouping.formdialog'
      })
      this.getView().add(this.win)
    }
    this.win.show()
  },
  onAddNewDPI: function () {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      store = viewModel.getStore('CusHieRelStore'),
      curCusHieRec = viewModel.get('curCusHieRec'),
      rec = Ext.create('MDM.src.view.Grouping.model.Relations', {
        CusHieID: curCusHieRec.get('Id')
      }),
      grid = me.lookup('famgrid')
    store.add(rec)
    grid.findPlugin('cellediting').startEdit(rec, 0)
  },

  onDeleteCusHie: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete  ',
      width: 500,
      closable: false,
      msg: 'Are you sure you want to delete this record? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDeleteCusHie(record)
        }
      }
    })
  },
  doDeleteCusHie: function (record) {
    var me = this,
      store = me.getViewModel().getStore('CusHieStore')
    store.remove(record)
    store.sync({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
      },
      failure: function () {
        console.log('doDeleteCusHie server side error')
      }
    })
  },

  onDPIRemoveClick: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      text = record.get('DPIFamily')
    Ext.Msg.show({
      title: 'Remove  ',
      width: 500,
      closable: false,
      msg: 'Are you sure you want to remove ' + text + '? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doRemoveFamilyDelete(record)
        }
      }
    })
  },
  doRemoveFamilyDelete: function (record) {
    var me = this
    var CusHieRelStore = me.getViewModel().getStore('CusHieRelStore')
    CusHieRelStore.remove(record)
    curCusHieRec = this.getViewModel().get('curCusHieRec')
    var curCusHieRec = this.getViewModel().get('curCusHieRec'),
      store = this.getViewModel().getStore('CusHieStore')
    store.suspendAutoSync()
    var ItemCount = curCusHieRec.get('ItemCount')
    curCusHieRec.set('ItemCount', ItemCount - 1)
    curCusHieRec.commit()
    store.resumeAutoSync()
    store.remove(record)
    CusHieRelStore.sync({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        curCusHieRec.set('myid', Ext.id())
        curCusHieRec.save()
      },
      failure: function (batch, opts) {}
    })
  },

  onDPITreeStoreLoad: function () {},

  onEditDPI: function (editor, e) {
    var v = e.value,
      record = e.record,
      field = e.field,
      dpiStore = this.getViewModel().getStore('DPIListStore')

    var index = dpiStore.findExact('DPIFamilyID', v)
    var dpiRec = dpiStore.getAt(index)
    record.set('DPIFamily', dpiRec.get('DPIFamily'))
  },
  onCancelEdit: function () {
    var me = this,
      store = this.getViewModel().getStore('DPIListStore')
    const rec = this.getViewModel().get('editedRecord')
    rec.reject()
    this.win.hide()
    if (!rec.store) rec.drop()
  },
  onEdit: function (grid, rowIndex, colIndex, item, e, record) {
    this.getViewModel().set('editedRecord', record)

    if (!this.win) {
      this.win = Ext.create({
        title: 'Grouping',
        iconCls: 'fa fa-pencil',
        xtype: 'form-grouping.formdialog',
        collapsible: false
      })
      this.getView().add(this.win)
    }
    this.win.show()
  },

  onSave: function () {
    var me = this,
      store = this.getViewModel().getStore('CusHieStore')
    var rec = this.getViewModel().get('editedRecord')
    if (!rec.store) store.add(rec)
    store.sync()
    this.win.hide()
  }
})
