Ext.define('MDM.src.view.globalapp.Panel.Publish.model.Change', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Global_MDM_Publish_Changes',
  fields: [
    {
      name: 'UpdateDateTime',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s.u',
      dateWriteFormat: 'Y-m-d\\TH:i:s.u'
    },
    {
      name: 'Old',
      type: 'string'
    },
    {
      name: 'New',
      type: 'string'
    },
    {
      name: 'Action',
      type: 'string'
    },

    {
      name: 'data',

      convert: function (value, record) {
        var newData = Ext.decode(record.get('New'), true),
          newRec = newData && newData.length > 0 ? newData[0] : {},
          oldData = Ext.decode(record.get('Old'), true),
          oldRec = oldData && oldData.length > 0 ? oldData[0] : {},
          action = record.get('Action')
        var keys = Object.keys(oldRec)
        if (action == 'Update' || action == 'Create') {
          keys = Object.keys(newRec)
        }
        var result = []
        keys.forEach(function (key) {
          result.push({
            key: key,
            oldData: oldRec[key],
            newData: newRec[key]
          })
        }, this)

        return result
      },
      depends: ['Old', 'New', 'Action']
    }
  ]
})
