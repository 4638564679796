import { loadTreeTableSlice } from '../../../store/slices'
import { useReduxState } from '../../../hooks'
import IconButton from '../../IconButton'
import { LoadApprovalCellProps } from './LoadApprovalCell.types'

export default function LoadApprovalCell({ cell }: LoadApprovalCellProps) {
  const [, loadTreeDispatch] = useReduxState(undefined, loadTreeTableSlice.actions)
  function handleClickApprove() {
    loadTreeDispatch.setSelectedLoadApprovalRow(cell.row)
    loadTreeDispatch.setIsLoadApprovalModalOpen(true)
  }

  const isApproved = cell.row.original.Approved === 'Approved'
  const tooltipText = isApproved ? 'Unapprove' : 'Approve'
  const approvalColor = isApproved ? '#45aaf2' : '#818181'
  const approvalIcon = isApproved
    ? 'slvy-ui-icon-circle-check fs-6'
    : 'slvy-ui-icon-circle-extra-lt fs-6'

  return (
    <IconButton tooltipText={tooltipText} onClick={handleClickApprove}>
      <i className={approvalIcon} style={{ color: approvalColor }} />
    </IconButton>
  )
}
