import { ICellRendererParams } from 'ag-grid-community'
import { TcellDisplayRules } from '../../../types'
import { getCellIcon } from '../index'
import {
  cellDisplayRules as defaultCellDisplayRules,
  customConfigs as defaultCustomConfigs,
  cellRendererParams,
  validCellRule
} from '../../__tests__/mocks'
import { validCellParamsData } from './mocks'

function getCellDisplayRules() {
  return defaultCellDisplayRules as TcellDisplayRules
}

function getCustomConfigs() {
  return defaultCustomConfigs
}

function getParams() {
  return cellRendererParams as ICellRendererParams
}

describe('AgGrid:Helpers:Cell:getCellIcon', () => {
  let cellDisplayRules = getCellDisplayRules()
  let customConfigs = getCustomConfigs()
  let params = getParams()

  beforeEach(() => {
    cellDisplayRules = getCellDisplayRules()
    customConfigs = getCustomConfigs()
    params = getParams()
  })

  it('should return customConfigs icon if there is no valid cell rule', () => {
    const result = getCellIcon({ params, cellDisplayRules, customConfigs })
    expect(result).toEqual(customConfigs.icon)
  })

  it('should return cell rule icon if there is a valid one', () => {
    params.data = validCellParamsData
    params.value = 2
    const result = getCellIcon({ params, cellDisplayRules: [validCellRule], customConfigs })
    expect(result).toEqual(validCellRule)
  })
})
