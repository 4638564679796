Ext.define('MDM.Btl.dd.RuleBased.Dialog', {
  extend: 'Ext.window.Window',
  resize: false,
  title: 'Rule Based BTLs',
  viewModel: {
    stores: {
      btlRuleDetailsStore: {
        model: 'MDM.Btl.model.EditModel'
      }
    },
    formulas: {
      isButtonDisabled: {
        bind: {
          selection: '{myGrid.selection}',
          gridSelModel: '{myGrid.selModel}'
        },
        get: function (obj) {
          return obj.gridSelModel.getCount() > 0
        }
      }
    },
    data: {
      record: null,
      readOnly: false,
      selection: false
    }
  },
  iconCls: 'fa  fa-file-text-o',
  layout: 'fit',
  bodyStyle: {
    backgroud: '#fff'
  },
  bodyPadding: 2,
  modal: true,
  defaultListenerScope: true,
  height: '90%',
  width: '90%',
  tbar: [
    '->',
    {
      text: 'Export',
      iconCls: 'fa fa-download',
      handler: 'exportTo'
    },
    '-',
    {
      iconCls: 'fa fa-times',
      bind: {
        disabled: '{!isButtonDisabled}'
      },
      text: 'Delete Selected Applied BTLs',
      handler: 'onMassDelete'
    },
    {
      iconCls: 'fa fa-check',
      bind: {
        disabled: '{!isButtonDisabled}'
      },
      text: 'Apply Selected BTLs',
      handler: 'onMassApply'
    }
  ],
  onMassApply: function () {
    var me = this
    var selectedRecord = this.grid.getSelection()
    var parentIds = []
    selectedRecord.forEach(function (record) {
      if (!record.get('IsApplied')) {
        parentIds.push(record.get('ID'))
      }
    }, this)

    Ext.Msg.show({
      title: 'Deleting BTLs',
      width: 500,
      msg: '<br> You are about to Apply ' + parentIds.length + ' record(s). Continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.setLoading('Applying changes please wait')
          Ext.Ajax.request({
            method: 'POST',
            url: MDM.Global.getApiUrl() + 'gillettev3/btl_all_Apply',
            timeout: 360000,
            params: {
              parentId: parentIds.join()
            },
            success: function (response) {
              var res = Ext.decode(response.responseText, true)
              res = res ? res : response.responseText
              if (_.isEmpty(res)) {
                Ext.MessageBox.alert('Error', 'Server Error')
              } else if (res[0].Success === '1') {
                me.setLoading(false)
                me.grid.store.load()
              } else {
                Ext.MessageBox.alert('Error', res[0].ErrorMessage)
              }
            },
            failure: function () {
              if (me && 'getView' in me && me.getView().setLoading) {
                me.getView().setLoading(false)
              } else {
                me.setLoading(false)
              }
              Ext.MessageBox.alert('Error', 'Server Error')
            }
          })
        }
      }
    })
  },
  onMassDelete: function () {
    var me = this
    var selectedRecord = this.grid.getSelection()
    var data = []
    selectedRecord.forEach(function (record) {
      if (record.get('IsApplied')) {
        data.push({ ID: record.get('ID') })
      }
    }, this)
    let cofrec = data.length
    Ext.Msg.show({
      title: 'Deleting BTLs',
      width: 500,
      msg: '<br> You are about to delete ' + cofrec + ' applied record(s). Continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.setLoading('Deleting BTL... Please wait')
          console.log(data)
          Ext.Ajax.request({
            method: 'POST',
            url: MDM.Global.getApiUrl() + 'gillettev3/btl_edit_dpi_data_destroy',
            timeout: 360000,
            params: {
              data: Ext.encode(data)
            },
            success: function (response) {
              var res = Ext.decode(response.responseText, true)
              res = res ? res : response.responseText
              if (_.isEmpty(res)) {
                Ext.MessageBox.alert('Error', 'Server Error')
              } else if (res[0].Success == 1 || res[0].MESSAGE === 'deleted') {
                me.grid.store.load()
                if (me.headerBarMixin) {
                  me.headerBarMixin.loadData()
                }
              } else {
                Ext.MessageBox.alert('Error', res[0].ErrorMessage)
              }
              me.setLoading(false)
            },
            failure: function () {
              me.setLoading(false)
              Ext.MessageBox.alert('Error', 'Server Error')
            }
          })
        }
      }
    })
  },
  exportTo: function (btn) {
    var me = this
    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }
    var newcfg = Ext.merge(
      {
        title: 'Material list',
        fileName: 'GridExport' + '.' + cfg.ext
      },
      btn.cfg
    )
    me.grid.saveDocumentAs(newcfg)
  },
  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },
  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },
  initComponent: function () {
    var me = this
    me.buildStore()
    var selModel = {
      selType: ''
    }
    if (me.isEditable) {
      selModel = {
        selType: 'checkboxmodel'
      }
    }

    me.grid = Ext.create({
      xtype: 'dd-btl-rule-edit-grid-other',
      publishes: 'selModel',
      header: false,
      readOnly: false,
      isEditable: me.isEditable,
      reference: 'myGrid',
      selModel: selModel,
      hideReadOnlyCol: false,
      startCol: 0,
      store: this.store,
      border: true,
      listeners: {}
    })
    me.items = [me.grid]
    me.callParent()
  },
  onResizeLock: Ext.emptyFn,
  showEditPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var store = this.store
    this.store.suspendAutoSync()
    var isReadOnly = false
    var win = Ext.create('MDM.Btl.dialog.RuleBasedInfo', {
      record: record,
      readOnly: isReadOnly
    }).show()
    win.on('save', function () {
      store.resumeAutoSync()
      store.sync()
    })
    win.on('close', function () {})
  },
  buildStore: function () {
    this.store = Ext.create('Ext.data.Store', {
      model: 'MDM.Btl.model.EditModel',
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/RuleBased_BTL'
      },
      autoSync: true,
      autoLoad: false
    })
  },
  showRuleBasedInfoPanel: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var store = this.store

    store.suspendAutoSync()
    var isReadOnly = !!(this.getViewModel().get('readOnly') || record.get('IsApplied'))
    var win = Ext.create('MDM.Btl.dialog.RuleBasedInfo', {
      record: record,
      readOnly: isReadOnly
    }).show()
    win.on('save', function () {
      store.resumeAutoSync()
      store.sync()
    })
    win.on('close', function () {
      store.resumeAutoSync()
    })
  },
  showBtlRuleInfo: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var store = me.getViewModel().getStore('btlRuleDetailsStore')
    store.suspendAutoSync()
    var isReadOnly = !!(this.getViewModel().get('readOnly') || record.get('IsApplied'))
    var win = Ext.create('MDM.Btl.dialog.BtlRulesInfo', {
      record: record
    }).show()
    win.on('save', function () {
      store.resumeAutoSync()
      store.sync()
    })
    win.on('close', function () {
      store.resumeAutoSync()
    })
  },
  onRuleBasedGridCellClick: function (view, td, cellIndex, record) {
    var me = this,
      viewModel = me.getViewModel()
    var clickedDataIndex = view.panel.headerCt.getHeaderAtIndex(cellIndex).dataIndex

    if (clickedDataIndex == 'NoDependent' && record.get('NoDependent') > 0) {
      me.openDependWindow(record)
    }
  },
  openDependWindow: function (record) {
    var me = this
    var view = this.getViewModel().getView()
    if (!me.dependentStore) {
      me.dependentStore = Ext.create('Ext.data.Store', {
        model: 'MDM.Btl.model.EditModel',
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: MDM.Global.getApiUrl() + 'gillettev3/RuleBasedDependentRead'
        },
        autoLoad: false
      })
    }

    if (!me.DependWindow) {
      me.DependWindow = Ext.create('Ext.window.Window', {
        modal: false,
        closeAction: 'hide',
        resizable: false,
        height: '60%',
        width: '70%',
        layout: 'fit',
        items: [
          {
            xtype: 'btl-edit-grid-RuleBasedDepent',
            border: true,
            columnLines: true,
            store: me.dependentStore,
            margin: 2
          }
        ]
      })
      view.add(me.DependWindow)
      me.DependWindow.show()
    } else {
      me.DependWindow.show()
    }

    var BTLType = record.get('BTLType'),
      BTLTypeTitles = {
        P: 'Production',
        D: 'Demand',
        I: 'Inventory',
        X: 'Cross Supply',
        R: 'Razor Draw',
        N: 'Non-revenue'
      },
      title = BTLTypeTitles[BTLType] + ' - ' + record.get('Title')
    me.DependWindow.setTitle((title = BTLTypeTitles[BTLType] + ' - ' + record.get('Title')))
    var proxy = me.dependentStore.getProxy()
    proxy.setExtraParam('id', record.data.RuleID)
    me.dependentStore.load()
  }
})
