/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { isNil } from 'lodash'
import { makeRequest, MakeRequestAction, RequestError } from '../appAPI'
import { Option } from '../../components/CellEditors/LookupFieldCellEditor/LookupFieldCellEditor.types'

interface IResponseListItem {
  Id: Option['id']
  Value: Option['value']
}

interface ILookupFieldItemState {
  error?: RequestError['data']['result']
  isLoading: boolean
  isRequested: boolean
  lookupOptions: Option[]
}

export const initialLookupFieldItemState: ILookupFieldItemState = {
  error: [],
  isLoading: false,
  isRequested: false,
  lookupOptions: []
}

type ApiData = Record<string, ILookupFieldItemState>

interface ILookupFieldState {
  apiData: ApiData
  fieldData: Record<string, ApiData>
}

const initialState: ILookupFieldState = {
  apiData: {},
  fieldData: {}
}

export interface MakeRequestActionForLookup extends MakeRequestAction {
  hash: string
}

export const getLookupField = createAsyncThunk(
  'app/getLookupField',
  async (action: MakeRequestActionForLookup) => {
    try {
      const response = await makeRequest(action)
      // The value we return becomes the `fulfilled` action payload
      return response.data.data.result.map(({ Id, Value }: IResponseListItem): Option => {
        return { id: isNil(Id) ? Value : Id, value: Value }
      }) as Option[]
    } catch (err) {
      return err as RequestError
    }
  }
)

export interface SetLookupFieldListAction {
  fieldData: Option[]
  field: string
  rowId: string
}

const isLookupFieldPayloadError = (payload: Option[] | RequestError): payload is RequestError =>
  'data' in payload && 'Errors' in payload.data

const lookupFieldSlice = createSlice({
  name: 'lookupField',
  initialState,
  reducers: {
    setLookupFieldList: (state, action: PayloadAction<SetLookupFieldListAction>) => {
      const { field, rowId, fieldData } = action.payload
      state.fieldData[field] = {
        ...(state.fieldData?.[field] ?? {}),
        [rowId]: {
          ...initialLookupFieldItemState,
          isRequested: true,
          lookupOptions: fieldData
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLookupField.pending, (state, action) => {
        state.apiData[action.meta.arg.hash] = {
          ...initialLookupFieldItemState,
          isRequested: true,
          isLoading: true
        }
      })
      .addCase(getLookupField.fulfilled, (state, action) => {
        const lookupField = isLookupFieldPayloadError(action.payload)
          ? {
              error: action.payload.data.result
            }
          : {
              lookupOptions: action.payload
            }

        state.apiData[action.meta.arg.hash] = {
          ...initialLookupFieldItemState,
          ...lookupField,
          isRequested: true,
          isLoading: false
        }
      })
      .addCase(getLookupField.rejected, (state, action) => {
        state.apiData[action.meta.arg.hash] = {
          ...initialLookupFieldItemState,
          isRequested: true,
          isLoading: false,
          error: [{ errorCode: 'An error occurred', isSuccess: false, successCode: -1 }],
          lookupOptions: []
        }
      })
  }
})

export default lookupFieldSlice.reducer
export const { setLookupFieldList } = lookupFieldSlice.actions
