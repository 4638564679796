import { useEffect, useRef } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { UpdateParameter } from '../../../../types'
import { SaveChangesProps } from './useRegisterMethodSaveChanges.type'

function useRegisterMethodSaveChanges({
  updateParameters,
  registerMethod,
  triggerSave,
  gridRef
}: SaveChangesProps) {
  const isPageLoaded = useRef(false)

  // TODO: This is added to prevent displaying toastr in pageLoad
  // If save changes in relations tab is filled with refresh key, upon opening the page user gets 'you must have changes to save' orange popup
  // In pageLoad registerMethods run automatically by the system, so if there is no changes cancel it.
  useEffect(() => {
    const pageLoadTimeout = setTimeout(() => {
      isPageLoaded.current = true
    }, 1000)
    return () => clearTimeout(pageLoadTimeout)
  }, [])

  useEffect(() => {
    const filteredUpdateParameters = updateParameters.filter(({ name }) => name !== '')

    const restArgs = filteredUpdateParameters.map(({ name, type }) => {
      return { name, type: PluginTypes.fromString(type) }
    })

    const args = [{ name: 'refreshKey', type: PluginTypes.string }, ...restArgs]

    registerMethod({
      args,
      key: 'saveChanges',
      fn: (saveChangesArgs: UpdateParameter[]) => {
        if (!isPageLoaded.current) {
          return
        }

        triggerSave({
          skipUnsavedChanges: true,
          skipConfirmation: true,
          updateParameters: saveChangesArgs
        })
      }
    })
  }, [updateParameters, registerMethod, triggerSave, gridRef])
}

export default useRegisterMethodSaveChanges
