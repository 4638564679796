import { Dropdown, Form } from 'react-bootstrap'
import { useTheme } from '../../../contexts/ThemeContext'

export default function ColumnSelect({ columns }) {
  const styles = useTheme()

  return (
    <div
      className={styles.columnSelect}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      {columns?.map((column, index) => (
        <Dropdown.Item
          key={index}
          as="li"
          className={styles.columnSelectItem}
          onClick={() => column.toggleVisibility(!column.getIsVisible())}
        >
          <Form.Check
            readOnly
            checked={column.getIsVisible()}
            className={styles.columnSelectCheck}
          />
          {column.columnDef.header}
        </Dropdown.Item>
      ))}
    </div>
  )
}
