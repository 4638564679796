import {
  SELECT_PRODUCT_GRAPH_VALUE,
  PRODUCT_SELECT_PRODUCT_GRAPH_VALUE
} from '../actions/actionTypes.js'
import { updateObject } from './utility.js'

const initialState = {
  selectedProductGraphValue: ''
}

function productGraphValues(state = initialState, action) {
  switch (action.type) {
    case SELECT_PRODUCT_GRAPH_VALUE:
      return updateObject(state, {
        ...state
      })
    default:
      return state
  }
}

function PRODUCTproductGraphValues(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_SELECT_PRODUCT_GRAPH_VALUE:
      return updateObject(state, {
        ...state
      })
    default:
      return state
  }
}

export { productGraphValues, PRODUCTproductGraphValues }
