import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Box from '../box'
import { ChartCell } from '../../../../components'
import {
  val,
  format,
  getCoverKey,
  getCoverStyle,
  getAttachments,
  getMessage,
  editReason,
  parse,
  cellLoading,
  isStateCellTargetEmpty,
  patchDiff,
  displayOfflineErrorMsg,
  getCoverViolations,
  findUpdateValue,
  getIssueSize
} from '../../../../utils'
import { coverColors } from '../../../../utils/constants'

class ResourceRowItemInfo extends React.Component {
  constructor() {
    super()
    this.state = {
      currentSelection: '2',
      currentReason: {},
      updatedPeriodId: -1,
      attachments: [],
      isUserAllowedToChangeProductionQuantity: true,
      attachmentTableName: ''
    }

    this.cellTarget = null
    this.modificationCount = null

    this.getAggregationValue = this.getAggregationValue.bind(this)
    this.getResourceInfoList = this.getResourceInfoList.bind(this)
    this.getBoxValues = this.getBoxValues.bind(this)
  }

  componentDidMount() {
    const { isAllowed = () => {} } = this.props

    const isUserAllowedToChangeProductionQuantity = isAllowed(
      'Resource and Production/Resource Based Production Quantity'
    )

    this.setState({
      isUserAllowedToChangeProductionQuantity
    })
  }

  updateHandler(params, e, extras = {}) {
    return new Promise((resolveHandler) => {
      if (_.isEmpty(extras) && _.isEmpty(e)) {
        this.setState({
          currentReason: {},
          attachments: []
        })
      }

      let {
        connectApi,
        // lastRequest: {
        //   GetRccpProductPeriodResponse: { Request }
        // },
        settings: {
          modificationCount = 0,
          stateHandler: {
            selectedParameter: { tableName = 'Production' },
            selectedProductVariant = ''
          },
          productionVariants: {
            defaultProductionVariant = 'Production',
            userOptions: { isEnabled: isUserOptionsEnabled = false } = {}
          } = {},
          isFiltered = false,
          updateReasons = {},
          productGroupByState
        },
        productChildren = {},
        resourcePeriodHierarchy: { Resources = {} } = {},
        resourceIndex
      } = this.props

      let { keyCode, target, target: { value = '' } = {} } = e
      const { isUpdate, productId, periodId, tableName: attachmentTableName } = params
      const { isForce = false, itemValue = value, clickUpdate = false } = extras
      const { Id } = Resources[resourceIndex] ?? {}
      value = parse(value)

      if ((keyCode === 13 && isUpdate) || (clickUpdate && isUpdate)) {
        const set = {
          isopen: true,
          node: target,
          reasondata: updateReasons
        }

        const update = () => {
          const { currentReason: { code: reasonCode, name: reasonName } = {} } = this.state

          selectedProductVariant = !selectedProductVariant
            ? defaultProductionVariant
            : selectedProductVariant
          const productionVariant = isUserOptionsEnabled
            ? selectedProductVariant
            : defaultProductionVariant
          const requestFieldName = findUpdateValue(tableName, productionVariant)

          const req = {
            fieldName: requestFieldName,
            productId,
            periodId,
            ...(productChildren?.isLastLevel ? { resourceId: Id } : {}),
            newValue: clickUpdate ? itemValue : value,
            rccpConfig: {
              ...(isForce ? { ForceUpdate: true } : {}),
              UpdateReasonCode: reasonCode,
              UpdateReasonDescription: reasonName,
              ModificationCount: modificationCount,
              OverrideGroupByHierarchy: productGroupByState.selectedHierarchies.map(
                ({ Name }) => Name
              )
            }
          }

          connectApi(
            productChildren?.isLastLevel
              ? 'UpdateRccpResourcePeriodProduct'
              : 'UpdateRccpProductPeriod',
            req
          )
            .then((res) => {
              const { Description = 'Error', Code = -1, Attachments = [] } = res

              const isAttachments = !_.isEmpty(Attachments)

              if (isAttachments) {
                this.setState({
                  attachments: getAttachments(Attachments, value),
                  updatedPeriodId: periodId,
                  attachmentTableName
                })
              }
              getMessage(Code, Description).then((ret) => {
                if (ret.isOk) {
                  req.rccpConfig.ModificationCount = 10000

                  connectApi('GetRccpProductPeriodResponse', req).then(() => {
                    target.blur()
                    // relocateScroll(productId)
                  })

                  if (clickUpdate) {
                    this.setState({
                      attachments: [],
                      updatedPeriodId: -1,
                      attachmentTableName: ''
                    })
                  }
                }
              })
            })
            .catch(() => {
              displayOfflineErrorMsg()
            })
            .finally(resolveHandler)
        }

        if (_.isEmpty(this.state.attachments)) {
          editReason(set, {
            onConfirm: (e, isok, reason) => {
              this.setState({ currentReason: reason }, () => update())
            },
            onReject: resolveHandler
          })
        } else {
          update()
        }
      } else {
        resolveHandler()
      }
    })
  }

  clickHandler(pindex, periodId, e) {
    e.preventDefault()

    const { currentSelection } = this.state

    if (currentSelection !== pindex) {
      // chartHandler(round(pindex))
      this.setState({
        currentSelection: pindex,
        updatedPeriodId: periodId,
        attachments: [],
        currentReason: {}
      })
    }
  }

  getAggregationValue(hierarchyResourcePeriodProduct, issueIndex, index) {
    const {
      index: childrenIndex,
      productLegendData: { resourceInfoList: productInfoList = [] } = {},
      resourcePeriodHierarchy = {},
      settings: {
        stateHandler: { selectedParameter: { Index: selectedMassUnitIndex = 0 } = {} } = {}
      } = {}
    } = this.props
    const {
      HierarchyProductPeriods: {
        [childrenIndex]: { [index]: hierarchyProductPeriod = {} } = {}
      } = {}
    } = resourcePeriodHierarchy

    const {
      tableName = 'Production',
      matchFieldName = false,
      format: formatType = '0,0',
      isResourceBased = false
    } = productInfoList[issueIndex] || {}

    let aggregationValue = 0
    if (!isResourceBased && !_.isNil(hierarchyProductPeriod[tableName])) {
      const { Values = {} } = hierarchyProductPeriod || {}
      const value = val(Values, tableName, selectedMassUnitIndex)

      aggregationValue = matchFieldName && value ? value : hierarchyProductPeriod[tableName]
    } else if (!_.isEmpty(hierarchyResourcePeriodProduct)) {
      const { Values = {} } = hierarchyResourcePeriodProduct || {}
      const value = val(Values, tableName, selectedMassUnitIndex)

      aggregationValue = matchFieldName && value ? value : hierarchyResourcePeriodProduct[tableName]
    }

    const cellValue = format(aggregationValue, formatType)
    return (
      <span key={tableName}>
        <span>{cellValue}</span>
      </span>
    )
  }

  getResourceInfoList(resourceInfoList, item, index) {
    const { tableName = 'MajorSetupPercentage', format: formatType = '0,0' } =
      resourceInfoList[index] || {}

    const cellValue = format(item[tableName], formatType)
    return (
      <span key={index}>
        <span>{cellValue}</span>
      </span>
    )
  }

  getBoxValues(
    product,
    index,
    isUpdate,
    productId,
    periodId,
    pindex,
    resourceBasedProduct,
    isFrozenWeek,
    isLastLevel
  ) {
    const {
      resourcePeriodHierarchy: { Periods, Resources } = {},
      isProductionResourceBased,
      paintOnClick,
      settings,
      settings: { coverSettings: { cover = '' } = {} },
      productLegendData: { resourceInfoList: productInfoList = [] } = {},
      settings: {
        productionVariants: { userOptions: { isEnabled: isUserOptionsEnabled = false } = {} } = {},
        stateHandler: {
          selectedParameter: { Index: selectedMassUnitIndex = 0 } = {},
          selectedProductVariant = 'Production'
        } = {},
        views: { newResourcePlanView }
      },
      resourceIndex = 0,
      selectedSession,
      children,
      level,
      type
    } = this.props
    const { Values: productValues } = product || {}
    let {
      [index]: {
        matchFieldName = false,
        tableName = 'Production',
        isEditable = false,
        format: formatType = '0,0',
        isResourceBased = false
      } = {}
    } = productInfoList
    const {
      attachments,
      updatedPeriodId,
      isUserAllowedToChangeProductionQuantity = true,
      attachmentTableName
    } = this.state

    const { IsGrandTotal = true } = Periods[pindex] || {}

    if (tableName === 'Cover') {
      tableName = cover
    }

    const { Values: ResourceBasedProductValues } = resourceBasedProduct || {}

    let boxValue

    if (tableName === 'Production') {
      if (isLastLevel) {
        boxValue = matchFieldName
          ? val(ResourceBasedProductValues, tableName, selectedMassUnitIndex)
          : resourceBasedProduct[tableName]
      } else {
        boxValue = matchFieldName
          ? val(productValues, tableName, selectedMassUnitIndex)
          : product[tableName]
      }
    } else if (isResourceBased && !_.isNil(resourceBasedProduct[tableName])) {
      boxValue = matchFieldName
        ? val(ResourceBasedProductValues, tableName, selectedMassUnitIndex)
        : resourceBasedProduct[tableName]
    } else {
      boxValue = matchFieldName
        ? val(productValues, tableName, selectedMassUnitIndex)
        : product[tableName]
    }

    // TODO: Remove this when old services stop using 'ShiftTonage'
    if (tableName === 'ShiftTonage' || tableName === 'ShiftTonnage') {
      boxValue = resourceBasedProduct?.ShiftTonnage ?? 0
    }

    if (isEditable) {
      const { Id = 0 } = Resources[resourceIndex] || {}
      return (
        <ChartCell
          key={tableName}
          attachments={{
            attachments: attachmentTableName === tableName ? attachments : [],
            isActive: updatedPeriodId === periodId
          }}
          disabled={
            !isUpdate || !paintOnClick || !isUserAllowedToChangeProductionQuantity || IsGrandTotal
          }
          formatType={formatType}
          isFrozenWeek={isFrozenWeek}
          settings={settings}
          updateHandler={this.updateHandler.bind(this, {
            isUpdate,
            productId,
            periodId,
            resourceId: Id,
            tableName
          })}
          value={!paintOnClick && tableName === 'Production' ? '-' : format(boxValue, formatType)}
          onClick={this.clickHandler.bind(this, pindex, periodId)}
          onFocus={() => this.setState({ currentReason: {}, attachments: [] })}
        />
      )
    }
    return (
      <span className="production-info-cell-value" key={`box${index}`}>
        {' '}
        {format(boxValue, formatType)}
      </span>
    )
  }

  render() {
    let {
      index: childrenIndex = 0,
      type = '',

      resourcePeriodHierarchy: {
        Products: products = {},
        Resources: resources = {},
        ResourcePeriods: resourcePeriods = {},
        ProductPeriods: productPeriods = {},
        Periods: periods = [],
        MaxRccpResourcePeriod: { IssueValues: MaxIssueValues },
        MaxRccpProductPeriod: { IssueValues: maxProductIssueValues },
        HierarchyResourcePeriodProducts: hierarchyResourcePeriodProducts = {},
        HierarchyProductPeriods = {}
      } = {},

      settings: {
        resourceIssueSettings = {},
        issueSettings: productIssueSettings = [],
        coverSettings: { cover = '', minCover = '' } = {},
        stateHandler: {
          selectedParameter: { Index: selectedMassUnitIndex = 0 } = {},
          selectedProductVariant = 'Production'
        } = {},
        resourceAndProductPlanSettings: {
          metricSettings: { isMetricOrderSorted = false } = {},
          aggregatedValues: { isEnabled: isAggregatedValuesEnabled = true } = {}
        } = {},
        productionVariants: { userOptions: { isEnabled: isUserOptionsEnabled = false } = {} } = {}
      } = {},

      productLegendData: {
        issueList: productIssueList = [],
        resourceInfoList: productInfoList = []
      } = {},

      productLegendState: {
        currentIssueIndex: currentProductIssueIndex = 0,
        currentProductInfoIndex: currentProductionInfoIndex = 0,
        isProductInfo: isProductProductInfo = false
      } = {},

      legendState: {
        currentIssueIndex = 0,
        currentProductInfoIndex = 0,
        isProductInfo = false
      } = {},

      legendData: { resourceInfoList = [] } = {},
      resourceIndex = 0,
      testResourceIndex = 0,

      coverViolationIndexes,
      paintOnClick,
      isConsumedBy,
      // ownClickedProductIndex,
      // ownClickedPeriodIndex,
      // onClickOwnProductAndPeriodIndex
      level,
      isLastLevel,
      locationNames,
      globalIndex
    } = this.props

    if (!paintOnClick) {
      productPeriods = this.props.productPeriods
      periods = this.props.periods
    } else {
      productPeriods = productPeriods[childrenIndex]
    }

    const periodBarStyle = {
      width: _.size(periods) * 70,
      ...(level === 0 && { backgroundColor: '#fff' })
    }

    return (
      <div className="resource-row-item-info" style={periodBarStyle}>
        {type === 'Resource' &&
          _.map(resourcePeriods[childrenIndex], (item, index) => {
            // if (isProductInfo) {
            //   return (
            //     <Box
            //       key={index}
            //       year={
            //         Math.round(
            //           item[props.legendData.resourceInfoList[currentProductInfoIndex].tableName]
            //PRODUCT //         ) + '%'
            //       }
            //     />
            //   )
            // }
            let itemSize =
              (item.IssueValues[currentIssueIndex] * 32) / MaxIssueValues[currentIssueIndex] || 0
            let itemSizeIssue = Math.ceil(itemSize > 0 ? Math.max(3, itemSize) : 0)
            let { color } = resourceIssueSettings[currentIssueIndex] || {}

            return (
              <Box
                type={type}
                key={index}
                globalIndex={globalIndex}
                locationNames={locationNames}
                year={true}
                periodIndex={parseInt(index, 10)}
                level={level}
              >
                {!isProductInfo
                  ? itemSize > 0 && (
                      <i
                        style={{
                          height: itemSizeIssue,
                          width: itemSizeIssue,
                          backgroundColor: color
                        }}
                      />
                    )
                  : _.map(
                      isMetricOrderSorted
                        ? _.sortBy(currentProductInfoIndex)
                        : currentProductInfoIndex,
                      (productInfoItemIndex) =>
                        this.getResourceInfoList(resourceInfoList, item, productInfoItemIndex)
                    )}
              </Box>
            )
          })}

        {type === 'Product' &&
          _.map(productPeriods, (product, index) => {
            const { [testResourceIndex]: { [index]: { ResourcePeriodProducts } = {} } = {} } =
              resourcePeriods
            const resourceBasedProduct =
              _.find(ResourcePeriodProducts, {
                ProductIndex: childrenIndex
              }) || {}
            const { PegTags = [], Production } = resourceBasedProduct
            const hasProduction = Production > 0
            const {
              [getCoverKey(cover, 'Cover')]: Cover = 0,
              [getCoverKey(minCover, 'MinCover')]: MinCover = 0
            } = product

            const {
              PeriodIndex: periodIndex,
              ProductIndex: productIndex,
              FrozenEndPeriodName: frozenEndPeriodName,
              IssueValues
            } = product
            const { Name: periodName } = periods[index] || {}

            const { [index]: { IsGrandTotal } = {} } = periods

            const isFrozenWeek = frozenEndPeriodName === periodName
            let currentEnabledProductIssueIndex = 0
            if (productIssueList.length && !isProductProductInfo) {
              const { issueIndex = 0 } = productIssueList[currentProductIssueIndex]
              currentEnabledProductIssueIndex = issueIndex || 0
            }

            const issueValue = product.IssueValues[currentEnabledProductIssueIndex]
            const maxIssueValue = maxProductIssueValues[currentEnabledProductIssueIndex] || 0
            const issueTableName = productIssueList[currentProductIssueIndex]?.tableName

            const itemSizeIssue = getIssueSize(issueValue, maxIssueValue, issueTableName)
            const { color } = productIssueSettings[currentProductIssueIndex] || {}

            const coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
            const { backgroundColor: borderTopColor = 'transparent' } = getCoverStyle(
              Cover,
              MinCover,
              ...coverViolations
            )
            const hasCoverViolation = borderTopColor !== coverColors.green

            const cellCoverStyle = isProductProductInfo ? { borderTopColor } : {}
            const { Id: periodId, DisplayName } = periods[periodIndex] ?? {}
            const { Id: productId, Name: productName } = products[productIndex] ?? {}
            const { [testResourceIndex]: { Id, Name } = [] } = resources

            return (
              <Box
                // ownClickedProductIndex={ownClickedProductIndex}
                // ownClickedPeriodIndex={ownClickedPeriodIndex}
                // onClickOwnProductAndPeriodIndex={onClickOwnProductAndPeriodIndex}
                key={index}
                year
                hasProduction={hasProduction}
                globalIndex={globalIndex}
                isProductPeriod={true}
                isConsumedBy={isConsumedBy}
                isFrozenWeek={isFrozenWeek}
                isGrandTotal={IsGrandTotal}
                isIssue={!isProductProductInfo}
                level={level}
                locationNames={locationNames}
                paintOnClick={paintOnClick}
                pegTags={PegTags}
                periodId={periodId}
                periodIndex={periodIndex}
                periodName={DisplayName}
                productId={productId}
                productIndex={childrenIndex}
                productName={productName}
                testResourceId={Id}
                testResourceIndex={testResourceIndex}
                testResourceName={Name}
                type={type}
                onPeriodChange={this.props.onPeriodChange}
              >
                {!isProductProductInfo
                  ? itemSizeIssue > 0 && (
                      <i
                        style={{
                          height: itemSizeIssue,
                          width: itemSizeIssue,
                          backgroundColor: color
                        }}
                      />
                    )
                  : [
                      hasCoverViolation && (
                        <span key="badge" className="product-info-badge" style={cellCoverStyle} />
                      ),
                      // Todo: Refactor parameters

                      _.map(
                        isMetricOrderSorted
                          ? _.sortBy(currentProductionInfoIndex)
                          : currentProductionInfoIndex,
                        (productionInfoIndex) =>
                          this.getBoxValues(
                            product,
                            productionInfoIndex,
                            true,
                            productId,
                            periodId,
                            periodIndex,
                            resourceBasedProduct,
                            isFrozenWeek,
                            isLastLevel
                          )
                      )
                    ]}
              </Box>
            )
          })}

        {type === 'Aggregation' &&
          (!_.isEmpty(HierarchyProductPeriods) && isAggregatedValuesEnabled
            ? _.map(
                HierarchyProductPeriods[childrenIndex === -1 ? 0 : childrenIndex],
                (hierarchyProductPeriod, index) => {
                  currentProductionInfoIndex = isMetricOrderSorted
                    ? _.sortBy(currentProductionInfoIndex)
                    : currentProductionInfoIndex

                  const {
                    [getCoverKey(cover, 'Cover')]: Cover = 0,
                    [getCoverKey(minCover, 'MinCover')]: MinCover = 0,
                    IssueValues
                  } = hierarchyProductPeriod
                  const coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
                  const { backgroundColor: borderTopColor = 'transparent' } = getCoverStyle(
                    Cover,
                    MinCover,
                    ...coverViolations
                  )
                  const cellCoverStyle = { borderTopColor }
                  const hasCoverViolation = borderTopColor !== coverColors.green
                  const { ProductIndex } = hierarchyResourcePeriodProducts ?? {}

                  return (
                    <Box
                      key={index}
                      year
                      level={level}
                      periodIndex={parseInt(index, 10)}
                      locationNames={locationNames}
                      productIndex={ProductIndex}
                    >
                      {[
                        hasCoverViolation && (
                          <span key="badge" className="product-info-badge" style={cellCoverStyle} />
                        ),
                        _.map(currentProductionInfoIndex, (issueIndex) =>
                          this.getAggregationValue(hierarchyProductPeriod, issueIndex, index)
                        )
                      ]}
                    </Box>
                  )
                }
              )
            : _.map(resourcePeriods[0], (_, index) => {
                return (
                  <Box
                    productIndex={childrenIndex}
                    locationNames={locationNames}
                    key={index}
                    level={level}
                  />
                )
              }))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  resourcePeriodHierarchy: state.PRODUCTresourcePeriodHierarchy,
  legendState: state.legend.resourceLegendState,
  legendData: state.legend.resourceLegendData,
  productLegendData: state.legend.productLegendData,
  productLegendState: state.legend.productLegendState,
  periodDates: state.PRODUCTresourcePeriodHierarchy.periodDates,
  resourceIndex: state.PRODUCTclickedProductInfo.resourceIndex,
  clickedProductInfo: state.PRODUCTclickedProductInfo.clickedProductInfo,
  isProductionResourceBased: state.PRODUCTresourcePlan.isProductionResourceBased,
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes,
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo
})

export default connect(mapStateToProps)(ResourceRowItemInfo)
