Ext.define('MDM.Btl.cmp.MrCombo', {
  extend: 'Ext.form.ComboBox',
  xtype: 'mrcombo',
  reference: 'mrcombo',
  fieldLabel: '<b>Region</b>',
  labelWidth: 40,
  margins: '0 4 0 0',
  width: 130,
  bind: {
    store: '{mrStore}',
    value: '{MegaRegionID}'
  },
  queryMode: 'local',
  displayField: 'name',
  valueField: 'value',
  listeners: {
    change: 'onMrComboChange'
  }
})
