import { IRowDataGrid } from '../../../types'
import { formatDate } from '../common'
import { expectedFormatDateData, formatDateData, formatWithoutDateData } from './mocks'

const formatDateParams = (): IRowDataGrid => formatDateData
const formatWithoutDateParams = (): IRowDataGrid => formatWithoutDateData

describe('AgGrid:Helpers:Common:formatDate', () => {
  let paramsWithDate = formatDateParams()
  let paramsWithoutDate = formatWithoutDateParams()

  beforeEach(() => {
    paramsWithDate = formatDateParams()
    paramsWithoutDate = formatWithoutDateParams()
  })
  it('should return formatted date values in the data object with DEFAULT_DATE_FORMAT like YYYY-MM-DD HH:mm:ss', () => {
    const result = formatDate(paramsWithDate)

    expect(result).toEqual(expectedFormatDateData)
  })
  it('should return not format non-date values in the data object', () => {
    const result = formatDate(paramsWithoutDate)

    expect(result).toEqual(formatWithoutDateData)
  })
  it('should return an empty object for an empty input object', () => {
    const result = formatDate({})

    expect(result).toEqual({})
  })
})
