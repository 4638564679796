import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ProductionChart from '../productionChart'
import ResourceRowItemInfo from '../resourceRowItemInfo'
import { CoverBox, Axis } from '../../../../components'
import Dropdown from './Dropdown'
import {
  resourcePlanIncrease,
  format,
  round,
  val,
  getMaxProductValue,
  dropGrandTotal,
  isMaxNumber,
  restoreScrollPos,
  combineItems
} from '../../../../utils'

function extractName(fullName) {
  const [, name] = fullName.split(' - ')
  return name
}

function getProductIndexByName(products, name) {
  const product = _.find(products, { Name: name }) || {}
  return product.Index
}

class ProductionInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGraphValueTableName: 'IndependentDemand',
      isDropdownOpen: false,
      consumedByProductIndex: null,
      chartPeriodIndex: null,
      headerOffsetBottom: 0
    }

    this.headerRef = null

    this.handleToggleDropdown = this.handleToggleDropdown.bind(this)
    this.handleCloseDropdown = this.handleCloseDropdown.bind(this)
    this.handleClickChart = this.handleClickChart.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { consumedByProductIndex } = this.state
    const {
      clickedProductInfo: { productIndex: nextProductIndex }
    } = nextProps

    if (nextProductIndex !== consumedByProductIndex)
      this.setState({ consumedByProductIndex: null, chartPeriodIndex: null })
    if (nextProductIndex === consumedByProductIndex) this.setState({ chartPeriodIndex: null })
  }

  componentDidMount() {
    const {
      settings: {
        productPlanSettings = {},
        stateHandler: { supplyPlan }
      },
      scrollPos
    } = this.props

    const defaultCheckedGraphValue = _.find(productPlanSettings, ['checked', true])
    const { tableName = 'Production' } = defaultCheckedGraphValue
    this.setState(
      {
        selectedGraphValueTableName: supplyPlan.selectedGraphValue
          ? supplyPlan.selectedGraphValue
          : tableName
      },
      () => {
        if (!_.isEmpty(scrollPos) && this.detailsTableRef) {
          restoreScrollPos(this.detailsTableRef, scrollPos)
        }
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.headerRef && !prevState.headerOffsetBottom) {
      const { offsetTop } = this.headerRef
      this.setState({ headerOffsetBottom: offsetTop + 16 })
    }
  }

  handleToggleDropdown() {
    this.setState((prevState) => ({ isDropdownOpen: !prevState.isDropdownOpen }))
  }

  handleCloseDropdown() {
    this.setState({ isDropdownOpen: false })
  }

  handleSelectConsumedBy(index) {
    this.setState({ consumedByProductIndex: index, isDropdownOpen: false })
  }

  handleClickChart(periodIndex) {
    this.setState({ chartPeriodIndex: periodIndex })
  }

  render() {
    let {
      ResourcePeriodsHierarchy: {
        Products: products = [],
        Periods: periods = [],
        ProductPeriods: productPeriods = {},
        ResourcePeriods: resourcePeriods = {},
        ResourceProducts: resourceProducts = {}
      } = {},
      clickedProductInfo: {
        periodIndex = 0,
        productIndex = 0,
        isSelectionOn,
        locationNames = []
      } = {},
      settings = {},
      settings: {
        coverSettings = {},
        productPlanSettings = [],
        customMOQSettings: { overrideDefaults = false, customMOQValues = [] } = {},
        stateHandler: {
          selectedParameter: { Index: selectedMassUnitIndex = 0, name: unitAbbreviation } = {}
        } = {},
        resourceAndProductPlanSettings: {
          grandTotal: { isEnabled: isGrandTotalEnabled } = {}
        } = {},
        productInfoSettings = [],
        appSettings: { resourceAndProductionView: { showGrandTotal = false } = {} } = {}
      } = {},
      selectedProductIssueCount = 1,
      initialFilters = {},
      resourceIndex = 0,
      connectApi = () => {},
      isAllowed = () => {},
      scrollHandler = () => {},
      stateHandler = () => {},
      coverViolationIndexes,
      onScroll
    } = this.props
    let { chartPeriodIndex, consumedByProductIndex, isDropdownOpen, headerOffsetBottom } =
      this.state

    const isConsumedBy = !_.isNil(consumedByProductIndex)

    chartPeriodIndex = !_.isNil(chartPeriodIndex) ? chartPeriodIndex : periodIndex
    periodIndex = isConsumedBy ? chartPeriodIndex : periodIndex
    productIndex = isConsumedBy ? consumedByProductIndex : productIndex

    let productPeriod = productPeriods[productIndex] || {}
    if (isGrandTotalEnabled && !showGrandTotal) {
      productPeriod = dropGrandTotal(productPeriod)
      periods = dropGrandTotal(periods)
    }

    const enabledProductPlanSettings = _.reject(productPlanSettings, ['enable', false])

    let {
      Values: productValues = [],
      Name: productName,
      SKU: productSKU,
      Id: productId,
      ConsumedBys
    } = products[productIndex] || {}
    ConsumedBys = _.map(ConsumedBys, (consumedBy) => {
      const name = extractName(consumedBy)
      const index = getProductIndexByName(products, name)

      return { name: consumedBy, index }
    })

    const { selectedGraphValueTableName } = this.state
    const { Values = {} } = productPeriod[periodIndex] || {}
    const NetRequirement = val(Values, 'NetRequirement', 1)
    const Planned = val(Values, 'Production', 1)
    const max = round(Math.max(NetRequirement, Planned))
    const calcPlannedValue = Planned > 0 && max > 0 ? round((Planned * 100) / max) : 0
    const calcNetRequirement =
      NetRequirement > 0 && max > 0 ? round((NetRequirement * 100) / max) : 0
    const maxProductValue = getMaxProductValue(selectedGraphValueTableName, productPeriod, 1)

    const resourcePeriod = resourcePeriods[resourceIndex] || {}
    const { ResourcePeriodProducts: resourcePeriodProducts = [] } =
      resourcePeriod[periodIndex] || {}
    const resourcePeriodProduct = _.find(resourcePeriodProducts, ['ProductIndex', productIndex])
    const { Values: resourcePeriodProductValues = [] } = resourcePeriodProduct || {}

    const resourceProduct = resourceProducts?.[resourceIndex]?.[productIndex] || []
    const resourceProductValues = combineItems(resourceProduct)

    // Fix typo
    resourceProductValues.ShiftTonage =
      resourceProductValues?.ShiftTonage || resourceProductValues?.ShiftTonnage

    productValues.ShiftTonage = productValues?.ShiftTonage || productValues?.ShiftTonnage

    const periodBarStyle = { width: _.size(periods) * 70 + 52 }
    const { Name: periodName = '', DisplayName: periodDisplayName = '' } =
      periods[periodIndex] || {}

    const productionInfoCoverSettings = _.find(productInfoSettings, ({ tableName }) =>
      _.includes(tableName, 'Cover')
    )
    const { format: formatType = '0,0' } = productionInfoCoverSettings || {}
    coverSettings = { ...coverSettings, formatType }
    const { [productIndex]: { [periodIndex]: product = {} } = {} } = productPeriods || {}

    return (
      this.props.izProductionInfoOpen && (
        <div style={{ height: '100%' }}>
          <div
            id="resourcePlanRight"
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
              position: 'relative',
              height: '100%',
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'column'
            }}
            onScroll={(e) => scrollHandler(false)}
          >
            <section className="production-info">
              {/* TODO: Refactor... */}
              <header
                ref={(elem) => {
                  if (elem) {
                    this.headerRef = elem
                  }
                }}
              >
                <div className="production-info__header">
                  <h1 className="production-info__title" onClick={this.handleToggleDropdown}>
                    {productName}
                    <i
                      className={`
                        -icon-trigger
                        consumed-by-dropdown-trigger
                        ${isDropdownOpen ? 'slvy-ui-icon-caret-up' : 'slvy-ui-icon-caret-down'}`}
                    />
                  </h1>
                  <h1 className="production-info__title">{periodDisplayName || periodName}</h1>
                </div>
                <p className="production-info__SKU">{productSKU}</p>
              </header>
              {isDropdownOpen && (
                <Dropdown
                  headerRef={this.headerRef}
                  items={ConsumedBys}
                  top={headerOffsetBottom}
                  onClick={this.handleSelectConsumedBy.bind(this)}
                  onClickOutside={this.handleCloseDropdown}
                />
              )}

              <div className="production-info__col" style={{ width: '100%' }}>
                <div className="-box">
                  {overrideDefaults
                    ? _.map(customMOQValues, (customMOQValue, index) => {
                        const { enable, name, tableName } = customMOQValue
                        const {
                          [resourceIndex]: { [productIndex]: resourceAndProduct = [] } = {}
                        } = resourceProducts ?? {}
                        const [firstFormula = {}] = resourceAndProduct
                        const { EffectiveShiftTonnage = 'N/A' } =
                          firstFormula.ResourceProductPeriods?.[periodIndex] ?? {}
                        if (_.has(productValues, tableName)) {
                          const { [tableName]: tableNameValues = '' } =
                            resourcePeriodProductValues || {}
                          let MOQValue = val(
                            tableNameValues ? resourcePeriodProductValues : resourceProductValues,
                            tableName,
                            selectedMassUnitIndex
                          )
                          MOQValue = isMaxNumber(MOQValue) ? 'N/A' : format(MOQValue)

                          return (
                            enable && (
                              <div key={`${tableName}-${index}`} className="-box-row">
                                <label>
                                  {name} [{unitAbbreviation}]
                                </label>
                                <b>{typeof MOQValue === 'number' ? format(MOQValue) : MOQValue}</b>
                              </div>
                            )
                          )
                        }
                        const { [tableName]: tableNameValue = '' } = resourcePeriodProduct || {}
                        const { product = {} } = productPeriod[periodIndex] || {}
                        let MOQValue = ''
                        if (tableName === 'EffectiveShiftTonnage') {
                          MOQValue = EffectiveShiftTonnage
                        } else {
                          MOQValue = tableNameValue || product[tableName]
                        }
                        MOQValue = _.isNumber(MOQValue) ? format(MOQValue) : MOQValue
                        MOQValue = isMaxNumber(MOQValue) ? 'N/A' : MOQValue

                        return (
                          enable && (
                            <div key={`${tableName}-${index + 1}`} className="-box-row">
                              <label>{name}</label>
                              <b>{MOQValue}</b>
                            </div>
                          )
                        )
                      })
                    : [
                        <div key="MinProduction" className="-box-row">
                          <label>MOQ</label>
                          <b>
                            {format(
                              val(
                                resourcePeriodProductValues.MinProduction
                                  ? resourcePeriodProductValues
                                  : resourceProductValues,
                                'MinProduction',
                                selectedMassUnitIndex
                              )
                            )}
                          </b>
                        </div>,
                        <div key="LotSize" className="-box-row">
                          <label>Lot Size</label>
                          <b>
                            {format(
                              val(
                                resourcePeriodProductValues.LotSize
                                  ? resourcePeriodProductValues
                                  : resourceProductValues,
                                'LotSize',
                                selectedMassUnitIndex
                              )
                            )}
                          </b>
                        </div>
                      ]}

                  <div className="-box-row -b-chart">
                    <label>Planned vs Requirement</label>
                    <ul className="-common-list">
                      <li style={{ width: `${resourcePlanIncrease(calcPlannedValue)}%` }}>
                        <span className="production-info__planned-vs-required">
                          {format(Planned)}
                        </span>
                      </li>
                      <li style={{ width: `${resourcePlanIncrease(calcNetRequirement)}%` }}>
                        <span className="production-info__planned-vs-required">
                          {format(NetRequirement)}
                        </span>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="-box-row-ctn">
                  {_.map(productPlanSettings, (productPlanItem, index) => {
                    let { enable, name, tableName } = productPlanItem,
                      currentSelectedValue = val(Values, tableName, 1)
                    if (index < 3)
                      return (
                        enable && (
                          <div
                            key={index}
                            className={`-box-row ${this.state.selectedGraphValueTableName === tableName ? '-active' : ''}`}
                            onClick={e => {

                              this.setState({ selectedGraphValueTableName: tableName})
                              // if (!_.isEmpty(period)) {
                              //     productPlanHandler(index)
                              //     historyHandler({
                              //         key: 'UpdateFormProduct',
                              //         state: { productPlanItem: tableName }
                              //     })
                              // }
                            }}
                          >
                            <label>{name} [KG]</label>
                            <b>{format(currentSelectedValue)}</b>
                          </div>
                        )
                      )
                  })}
                </div> */}
                </div>
              </div>

              <div className="d-flex  flex-column">
                <div className="production-info__col">
                  <div className="-box">
                    <div
                      className="-box-row-ctn"
                      ref={(element) => {
                        if (element) {
                          this.detailsTableRef = element
                        }
                      }}
                      onScroll={onScroll}
                    >
                      {_.map(enabledProductPlanSettings, (productPlanItem, index) => {
                        const { enable, name, tableName } = productPlanItem
                        const currentSelectedValue = val(Values, tableName, selectedMassUnitIndex)

                        return (
                          enable && (
                            <div
                              key={index}
                              className={`-box-row ${
                                this.state.selectedGraphValueTableName === tableName
                                  ? '-active'
                                  : ''
                              }`}
                              onClick={(e) => {
                                this.setState({
                                  selectedGraphValueTableName: tableName
                                })
                                stateHandler({
                                  supplyPlan: {
                                    selectedGraphValue: tableName
                                  }
                                })
                                // if (!_.isEmpty(period)) {
                                //     productPlanHandler(index)
                                //     historyHandler({
                                //         key: 'UpdateFormProduct',
                                //         state: { productPlanItem: tableName }
                                //     })
                                // }
                              }}
                            >
                              <label>
                                {name} [{unitAbbreviation}]
                              </label>
                              <b>{format(currentSelectedValue)}</b>
                            </div>
                          )
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/* 
                <div className="production-info__col">
                  <div className="-box"> */}
                {/* <div className="-box-row">
                  <label>MOQ</label>
                  <b>{format(val(Values, 'MinProduction', 1))}</b>
                </div>

                <div className="-box-row" style={{ marginBottom: 12 }}>
                  <label>Lot Size</label>
                  <b>{format(val(Values, 'LotSize', 1))}</b>
                </div> */}
                {/* 
                    <div className="-box-row-ctn">
                      {_.map(enabledProductPlanSettings, (productPlanItem, index) => {
                        const { enable, name, tableName } = productPlanItem
                        const currentSelectedValue = val(Values, tableName, selectedMassUnitIndex)
                        if (index > 2 && index < 6)
                          return (
                            enable && (
                              <div
                                key={index}
                                className={`-box-row ${
                                  this.state.selectedGraphValueTableName === tableName
                                    ? '-active'
                                    : ''
                                }`}
                                onClick={(e) => {
                                  this.setState({
                                    selectedGraphValueTableName: tableName
                                  })
                                  // if (!_.isEmpty(period)) {
                                  //     productPlanHandler(index)
                                  //     historyHandler({
                                  //         key: 'UpdateFormProduct',
                                  //         state: { productPlanItem: tableName }
                                  //     })
                                  // }
                                }}
                              >
                                <label>
                                  {name} [{unitAbbreviation}]
                                </label>
                                <b>{format(currentSelectedValue)}</b>
                              </div>
                            )
                          )
                      })}
                    </div>
                  </div>
                </div> */}

                <div className="production-info__col">
                  <div className="-box">
                    {/* <div className="-box-row-ctn">
                      {_.map(enabledProductPlanSettings, (productPlanItem, index) => {
                        const { enable, name, tableName } = productPlanItem
                        const currentSelectedValue = val(Values, tableName, selectedMassUnitIndex)
                        if (index >= 6 && index < 8)
                          return (
                            enable && (
                              <div
                                key={index}
                                className={`-box-row ${
                                  this.state.selectedGraphValueTableName === tableName
                                    ? '-active'
                                    : ''
                                }`}
                                onClick={(e) => {
                                  this.setState({
                                    selectedGraphValueTableName: tableName
                                  })
                                  // if (!_.isEmpty(period)) {
                                  //     productPlanHandler(index)
                                  //     historyHandler({
                                  //         key: 'UpdateFormProduct',
                                  //         state: { productPlanItem: tableName }
                                  //     })
                                  // }
                                }}
                              >
                                <label>
                                  {name} [{unitAbbreviation}]
                                </label>
                                <b>{format(currentSelectedValue)}</b>
                              </div>
                            )
                          )
                      })}
                    </div>  */}
                    <div className="-box-row -b-chart">
                      <CoverBox
                        coverSettings={coverSettings}
                        coverViolationIndexes={coverViolationIndexes}
                        data={product}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div style={{ height: '65%' }}>
              <div className="periods-bar resource-plan__periods-bar-wrapper">
                <section className="periods-bar resource-plan__periods-bar" style={periodBarStyle}>
                  <div
                    className="periods-bar__side-left production-info__periods-bar__side-left"
                    style={{ minWidth: 52, boxShadow: 'none', borderRight: '1px solid #e5e5e5' }}
                  />
                  <div className="periods-bar__side-right">
                    {_.map(periods, (period, index) => {
                      let { DisplayName: name = '' } = period || {}
                      name = name.split('-')
                      return (
                        <div
                          key={`${name[1]}-${index}`}
                          className={`periods-bar__period-item ${
                            isSelectionOn && index === periodIndex ? 'period-box_background' : ''
                          } ${
                            isSelectionOn && index + 1 === periodIndex
                              ? 'period-box_border-right'
                              : ''
                          }`}
                        >
                          <p>{name[0]}</p>
                          <p>{name[1]}</p>
                          <p>{name[2]}</p>
                        </div>
                      )
                    })}
                  </div>
                </section>
              </div>
              {/** Refactor below (and above really...) */}
              <div
                className="production-info__chart-wrapper d-flex flex-column"
                style={{
                  width: _.size(periods) * 70 + 52,
                  height: '90%'
                }}
              >
                <div style={{ display: 'inline-flex' }}>
                  <div
                    style={{
                      minHeight: 28,
                      width: 52,
                      /* display: inline-block; */
                      position: 'sticky',
                      left: 0,
                      /* background: #f00; */
                      zIndex: 100,
                      borderTop: '1px solid #e5e5e5',
                      /* border-top-left-radius: 16px; */
                      backgroundColor: '#fff'
                    }}
                  >
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        // borderTopLeftRadius: 16,
                        borderRight: '1px solid #e5e5e5',
                        // boxShadow: '0px -2px 8px #c1c1c1',
                        background: 'white'
                      }}
                    />
                  </div>
                  <ResourceRowItemInfo
                    connectApi={connectApi}
                    index={productIndex}
                    isConsumedBy={isConsumedBy}
                    locationNames={locationNames}
                    paintOnClick={false}
                    periods={periods}
                    productPeriods={productPeriod}
                    settings={settings}
                    type="Product"
                    onPeriodChange={this.handleClickChart}
                  />
                </div>
                <section className="production-info__chart">
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      position: 'sticky',
                      left: 0,
                      zIndex: 10,
                      borderRight: '1px solid #e5e5e5'
                    }}
                  >
                    <div className="production-info__axis-container">
                      <Axis axisData={maxProductValue} />
                    </div>
                  </div>

                  <div className="rccp-body" style={{ height: '100%' }}>
                    <div className="-row -active">
                      <div style={{ display: 'flex', height: '100%' }}>
                        <div className="rcc-wrp" style={{ height: '100%' }}>
                          <ul style={{ height: '100%' }}>
                            <section className="-chart-box">
                              <ProductionChart
                                chartHandler={() => {}}
                                connectApi={connectApi}
                                initialFilters={initialFilters}
                                isAllowed={isAllowed}
                                isConsumedBy={isConsumedBy}
                                lastRequest={{}}
                                periods={periods}
                                product={product}
                                productId={productId}
                                productPeriod={productPeriod}
                                selectedProductPeriod={periodIndex}
                                settings={settings}
                                stateHandler={stateHandler}
                                tableName={this.state.selectedGraphValueTableName}
                                onPeriodChange={this.handleClickChart}
                              />
                            </section>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }
}

const mapStateToProps = (state) => ({
  ResourcePeriodsHierarchy: state.resourcePeriodHierarchy,
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
  productGraphValues: state.productGraphValues.selectedProductGraphValue,
  izProductionInfoOpen: state.clickedProductInfo.isProductionInfoOpen,
  selectedProductIssueCount: state.legend.selectedProductIssueCount,
  resourceIndex: state.clickedProductInfo.resourceIndex,
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes
})

export default connect(mapStateToProps, null)(ProductionInfo)
