/* global Ext, MDM */
import React, { Component } from 'react'
import L from 'leaflet'
import Highcharts from 'highcharts'
import 'leaflet/dist/leaflet.css'
import echarts from 'echarts'
import worldMap from './maps/world.json'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { ExtContainer } from '@sencha/ext-react-classic'

import createPlugin from '@/BasePlugin'

import { ExtRoot } from '@/components'
import { getExtContainerSize, getPluginSize } from '@/helpers'
import { API_URL } from '@/constants'

/* INCLUDING SUBMODULE APPLICATIONS STYLES */
//import './ext-package/sass/var/all.scss'
//import './ext-package/sass/etc/all.scss'
//import './ext-package/sass/src/Main.scss'
//import './ext-package/src/view/btlv2/Main.scss'
//import './ext-package/src/view/regionapp/MassEdit/Grid.scss'
//import './ext-package/src/view/regionapp/Panel/TabPanel.scss'
//import './ext-package/src/view/regionapp/Main.scss'
/* Global app's style files imported from ext-solvoyo theme */

/* INCLUDING SUBMODULE APPLICATIONS */
require('./ext-package')
require('./ext-package/requires/materialeditmain')
require('./ext-package/requires/regionappmain')
require('./ext-package/requires/globalappmain')
require('./ext-package/requires/btleditmain')
require('./ext-package/requires/groupingmain')
require('./ext-package/requires/massimportmain')
require('./ext-package/requires/calendarmain')

class GilletteMDM extends Component {
  constructor(props) {
    super(props)
    this.state = {
      size: getPluginSize(props.size, props.isPreviewMode)
    }

    Ext.define('MDM.ExtGlobal', {
      singleton: true,
      config: {
        dragonApiUrl: `${API_URL}/data/${props.id}/invoke/`,
        authToken: props.token,
        pluginId: props.id
      },
      constructor: function (config) {
        var me = this
        me.initConfig(config)
      }
    })

    this.defineCustomExporters()

    window.Highcharts = Highcharts

    window.L = L
    // HACK : https://github.com/PaulLeCam/react-leaflet/issues/255
    delete L.Icon.Default.prototype._getIconUrl

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    })

    echarts.registerMap('world', worldMap)
    window.echarts = echarts

    Ext.define('MDM.Btl.Requires', {})
    // Require Chart.ux
    Ext.require('Chart.ux.*')
    Ext.Date.shortMonthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    Ext.require('Ext.button.*')
    Ext.require('Ext.calendar.*')
    Ext.require('Ext.data.*')
    Ext.require('Ext.exporter.*')
    Ext.require('Ext.form.*')
    Ext.require('Ext.grid.*')
    Ext.require('Ext.panel.*')
    Ext.require('Ext.ux.*')
    Ext.require('Ext.window.Toast')

    Ext.require('MDM.*')

    var defaultHeaders = Ext.Ajax.getDefaultHeaders() || {}

    if (!defaultHeaders['Authorization']) {
      defaultHeaders['environment'] = props.params.environment
      defaultHeaders['Authorization'] = 'Bearer ' + props.token
      Ext.Ajax.setDefaultHeaders(defaultHeaders)
    }

    Ext.Ajax.addListener('beforerequest', function (conn, options, eOpts) {
      options.url = options.url.replace('###API_URL###', `${MDM.ExtGlobal.getDragonApiUrl()}`)

      options.url = options.url.toLowerCase().replace('gillettev3/', '')

      options.url = options.url.toLowerCase().replace('/create', '_create')
      options.url = options.url.toLowerCase().replace('/read', '_read')
      options.url = options.url.toLowerCase().replace('/update', '_update')
      options.url = options.url.toLowerCase().replace('/destroy', '_destroy')
    })

    //PS: this.handleTreeSelect = this.handleTreeSelect.bind(this)
  }

  componentDidMount() {
    /**
     *  TODO@ PS: handleTreeSelect is not used from anywhere but it was registered to our workflow.
     *    We have to be sure if something will affect or not be able to remove these code blocks.
     */
    // const {
    //   settings: {
    //     config: { settings: { application = 'RegionApp' } = {} }
    //   }
    // } = this.props
    //
    // switch (application) {
    //   case 'BTLEdit':
    //     this.handleTreeSelect = this.props.registerEvent({
    //       key: 'handleTreeSelect',
    //       fn: this.handleTreeSelect,
    //       returnTypes: {
    //         value: PluginTypes.fromString('string'),
    //         display: PluginTypes.fromString('string')
    //       }
    //     })
    //     break
    //   default:
    //     break
    // }

    this.props.reloadExtRoot(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If size of the grid changes do not render the grid
    const widthChanged = nextProps.size.width !== this.props.size.width
    const heightChanged = nextProps.size.height !== this.props.size.height

    if (widthChanged || heightChanged) {
      setTimeout(() => this.setState({ size: getExtContainerSize(this.props.id) }), 100)
    }
  }

  // PS:
  // handleTreeSelect (record) {
  //   //console.log('handleTreeSelect', record)
  // }

  defineCustomExporters() {
    this.defineCustomExporter('Ext.grid.plugin.Exporter')
    this.defineCustomExporter('Ext.pivot.plugin.Exporter')
  }

  defineCustomExporter(typeToOverride) {
    const that = this

    Ext.define(null, {
      override: typeToOverride,
      saveDocumentAs: function (config) {
        const { userName = null, actualFilters = {}, createLog } = that.props

        config = {
          ...config,
          userName: userName,
          createLog: createLog,
          filters: {
            ...config.filters,
            ...actualFilters
          }
        }
        var cmp = this.cmp

        var deferred = new Ext.Deferred()

        var exporter = this.getExporter(config)

        cmp.fireEvent('beforedocumentsave', cmp, {
          config: config,
          exporter: exporter
        })

        this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])
        return deferred.promise
      }
    })
  }

  getApplication() {
    const application = _.get(this.props, 'settings.config.settings.application', 'RegionApp')

    const id = application + '_' + this.props.id

    const session = jwtDecode(this.props.token)

    const region = session.MegaRegion
      ? _.isArray(session.MegaRegion) && session.MegaRegion.length > 0
        ? session.MegaRegion[0]
        : session.MegaRegion
      : ''

    const dataAccessLevel = session.PGDataAccess ? _.toLower(session.PGDataAccess) : 'read'

    const isReadonly = session.PGDataAccess ? _.toLower(session.PGDataAccess) === 'read' : true

    const user = {
      id: session.sub * 1,
      username: session.preferred_username,
      isReadonly: isReadonly,
      isGlobal: _.toLower(region) === 'global',
      isRegion: _.toLower(region) !== 'global' || dataAccessLevel === 'super',
      region: region,
      culture: session.culture,
      email: session.email
    }

    if (MDM.Global.setUserObj) {
      MDM.Global.setUserObj(user)
    }

    let packageProps = {
      id,
      width: '100%',
      height: '100%'
    }

    switch (application) {
      case 'BTLEdit':
        //PS: packageProps.onTreeSelect = this.handleTreeSelect
        packageProps.xtype = 'btleditmain'
        return packageProps

      case 'Calendar':
        packageProps.xtype = 'calendarmain'
        return packageProps

      case 'GlobalApp':
        packageProps.xtype = 'globalappmain'
        return packageProps

      case 'Grouping':
        packageProps.xtype = 'groupingmain'
        return packageProps

      case 'MassImport':
        packageProps.xtype = 'massimportmain'
        return packageProps

      case 'RegionApp':
        packageProps.xtype = 'regionappmain'
        return packageProps
      default:
        return null
    }
  }

  render() {
    const { id = 'id', isPreviewMode } = this.props
    const key = 'Gillette-MDM-' + id
    let packageProps = this.getApplication()

    const extContainerProps = {
      key,
      ...getPluginSize(this.state.size, isPreviewMode),
      id: `slvyExtContainer-${id}`,
      cls: 'slvy-ext-container',
      layout: 'fit',
      autoSize: true,
      items: [packageProps]
    }

    return (
      <ExtRoot pluginId={id}>
        {packageProps === null ? (
          <div id={`slvyExtContainer-${id}`}>Please select an application from configuration.</div>
        ) : (
          <ExtContainer {...extContainerProps} />
        )}
      </ExtRoot>
    )
  }
}

const selectConnectorProps = (props) => ({
  settings: props.settings,
  size: props.size,
  isPreviewMode: props.isPreviewMode,
  id: props.id,
  token: props.token,
  params: props.params,
  reloadExtRoot: props.reloadExtRoot,
  userName: props.userName,
  actualFilters: props.actualFilters,
  createLog: props.createLog
})

export default createPlugin(GilletteMDM, selectConnectorProps)
