Ext.define('Assortment.cmp.Header', {
  extend: 'Ext.Toolbar',
  xtype: 'assortment-toolbar',
  items: [
    {
      xtype: 'tbtext',
      id: 'AssortmentStatus',
      bind: {
        html: '<b>' + '{selectedAssortmentName}' + '</b>'
      }
    },
    '-',
    {
      id: 'SelectFiltersButton',
      text: 'Select Filters',
      handler: 'onFilterPopUp',
      disabledCls: 'pointer-events-default',
      bind: {
        disabled: '{CurrentNode}'
      }
    },
    '-',
    {
      text: 'Select Cluster',
      bind: {
        text: '{getButtonText}',
        disabled: '{CurrentNode}'
      },
      handler: 'onSelectCluster'
    },
    {
      xtype: 'tbtext',
      bind: {
        html: '<b>' + '{ClusterName}' + '</b>'
      }
    },
    '-',
    {
      text: 'Get Products',
      iconCls: 'fa fa-bolt',
      handler: 'onExecute',
      bind: {
        hidden: '{CurrentNode}',
        disabled: '{ClusterName === "Please Select Cluster"}'
      }
    },
    {
      text: 'Reload Products',
      iconCls: 'fa fa-refresh',
      handler: 'onReloadProducts',
      bind: {
        hidden: '{!CurrentNode}',
        disabled: '{ !isActualSeason}'
      }
    },
    {
      text: 'Create Test Product',
      handler: 'onCreateTestProduct',
      bind: {
        disabled:
          '{!isActualSeason || !AssortmentData || !SeasonID || !DivisionID || !SubDivisionID || !BuyerGroupID || ClusterName === "Please Select Cluster" }'
      }
    },
    '->',
    {
      text: 'Approve',
      handler: 'onApprove',
      bind: {
        hidden: '{!CurrentNode}',
        disabled: '{CurrentNode.Status === "APPROVED"}'
      }
    },
    {
      text: 'Update',
      iconCls: 'fa fa-floppy-o',
      handler: 'onUpdateAssortment',
      bind: {
        // TODO: beycan should return current node when saved.
        hidden: '{!CurrentNode}'
      }
    },
    {
      iconCls: 'fa fa-floppy-o',
      handler: 'onSaveAssortment',
      bind: {
        text: '{getSaveText}',
        hidden: '{!AssortmentData}'
      }
    }
  ]
})
