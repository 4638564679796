export default `({
    columns: [{
            text: tt('DayofSpot'),
            dataIndex: 'DayofSpot',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('TotalSalesQty'),
            dataIndex: 'TotalSalesQty',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('RunningTotal'),
            dataIndex: 'RunningTotal',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('DaylyPercent'),
            dataIndex: 'DaylyPercent',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('ParetoPercent'),
            dataIndex: 'ParetoPercent',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('ParetoDayNumber'),
            dataIndex: 'ParetoDayNumber',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        }
    ],
})
`
