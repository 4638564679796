Ext.define('po.cmp.ZeroInventoryGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'zeroInventory',
  alias: 'widget.zeroInventory',
  height: 500,
  width: 555,
  enableLocking: true,
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView().normalView,
      grouping = normalView.getFeature('grouping')

    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText

    me.unlockText = i18n.GridConfig_unlockText
    me.lockText = i18n.GridConfig_lockText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'gridexporter',
      id: 'gridexport'
    }
  ],
  listeners: {
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady'
  },
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    },
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      dataIndex: 'MagazaKodu',
      bind: {
        text: '{i18n.ZeroInventory_Column_MagazaKodu}',
        kayit: '{i18n.GroupGrid_SummaryRow_text}',
        hidden: '{!zeroInventoryGrid.magzaKoduVisible}'
      },
      width: 100,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      },
      summaryType: 'count',
      summaryRenderer: function (value, summaryData, dataIndex) {
        var text = this.kayit ? this.kayit : ''
        let sumObject = summaryData
        return Ext.String.format('{0} ' + text, value)
      },
      setKayit: function (value) {
        this.kayit = value
      }
    },
    {
      dataIndex: 'MagazaAdi',
      bind: {
        text: '{i18n.ZeroInventory_Column_MagazaAdi}',
        hidden: '{!zeroInventoryGrid.magzaAdiVisible}'
      },
      width: 200,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'Envanter',
      bind: {
        text: '{i18n.ZeroInventory_Column_Envanter}',
        hidden: '{!zeroInventoryGrid.envanterVisible}'
      },
      width: 100,
      summaryType: 'sum',
      filter: {
        type: 'number'
      },
      align: 'right'
    },
    {
      dataIndex: 'Statu',
      bind: {
        text: '{i18n.ZeroInventory_Column_Statu}',
        hidden: '{!zeroInventoryGrid.statuVisible}'
      },
      width: 100,
      filter: {
        type: 'list'
      }
    }
  ]
})
