import { useParams, useNavigate, useLocation } from 'react-router-dom'

import { WithRouterDomProps, CommonParams } from './WithRouterDom.types'

const WithRouterDom = ({ as: Component, child = null }: WithRouterDomProps) => {
  const commonParams: CommonParams = {
    routerLocation: useLocation(),
    params: useParams(),
    navigate: useNavigate()
  }

  // Set only once
  if (!('SLVY_useNavigate' in window)) {
    window.SLVY_useNavigate = commonParams.navigate
  }

  return <Component {...commonParams}>{child}</Component>
}

export default WithRouterDom
