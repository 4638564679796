export default `({
    proxy: {
        type: 'ajax',
        actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
        },
        api: {
            // create: Spui.Global.getApiUrl() + 'spui/SmilarSpotSelectedSalesChartData/create',
            read: Spui.Global.getApiUrl() + 'spui/SmilarSpotSelectedSalesChartData',
            update: Spui.Global.getApiUrl() + 'spui/SmilarSpotSelectedSalesChartData/update',
            destroy: Spui.Global.getApiUrl() + 'spui/SmilarSpotSelectedSalesChartData/destroy',
        },
        // data: [{
        //     a: 1
        // }],
        fields:[{
            name: 'DateKey',
            type: 'string'
        },{
            name: 'Envanter',
            type: 'number'
        },{
            name: 'QTY',
            type: 'number'
        },{
            name: 'Price',
            type: 'number'
        }],
        url: Spui.Global.getApiUrl() + 'spui/SmilarSpotSelectedSalesChartData',
    },
})
`
