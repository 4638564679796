const gridColumnsSchema = {
  gridColumns: {
    type: 'array',
    title: 'Grid Columns',
    options: {
      syncButton: true
    },

    items: {
      type: 'object',
      title: 'Columns',
      headerTemplate: '{{ self.name }}',
      properties: {
        name: {
          type: 'string',
          title: 'Column Name',
          description: 'Column Name is coming from the data, do not change this field.'
        },
        displayName: {
          type: 'string',
          title: 'Display Name'
        },
        isVisible: {
          type: 'boolean',
          title: 'Is Visible',
          default: true
        },
        isFixed: {
          type: 'string',
          title: 'Is Fixed',
          description: 'It must be left or right.'
        },
        isFilterable: {
          type: 'boolean',
          title: 'Is Filterable',
          default: false
        },
        width: {
          type: 'integer',
          title: 'Width'
        }
      }
    }
  }
}

export default () => {
  return {
    title: 'Label',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          isApproveShown: {
            type: 'boolean',
            title: 'Show Approve',
            default: false,
            required: true
          },
          isApproveExternal: {
            type: 'boolean',
            title: 'Approve External',
            default: false,
            required: true,
            description: 'Approve button will not approve when Approve External is true'
          },
          approveButtonText: {
            type: 'string',
            title: 'Approve Button Text',
            default: 'Approve',
            description: 'This will be active when Approve External is true'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          text: {
            type: 'string',
            title: 'Label Text'
          },
          title: {
            type: 'string',
            title: 'Title',
            watch: {
              ds: 'settings.datafields'
            },
            enumSource: 'ds'
          },
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color'
          },
          paginationActivation: {
            type: 'boolean',
            title: 'Pagination Activation',
            default: true,
            required: true
          },
          testMode: {
            type: 'boolean',
            title: 'Test Mode',
            default: false,
            required: true
          },
          getPrepackAsEvent: {
            type: 'boolean',
            title: 'GetPrepack Functionality As Event',
            default: false,
            required: true
          },
          popupUpdate: {
            type: 'boolean',
            title: 'Popup Update',
            default: false,
            required: true
          },
          sizeBreakdownTotal: {
            type: 'boolean',
            title: 'Size Breakdown Total',
            default: false,
            required: true
          },
          IPOPrepackSizesSPName: {
            type: 'string',
            title: 'IPO-Prepack Sizes Stored Procedure Name',
            required: true
          }
        }
      },
      prepackData: {
        type: 'object',
        title: 'Prepack Data',
        properties: {
          productId: {
            type: 'integer',
            title: 'Product Id'
          },
          assortmentId: {
            type: 'integer',
            title: 'Assortment Id'
          },
          scenarioId: {
            type: 'integer',
            title: 'Scenario Id'
          },
          runInfoId: {
            type: 'integer',
            title: 'RunInfo Id'
          },
          clusterId: {
            type: 'string',
            title: 'Cluster Id'
          }
        }
      },
      gridColumns: gridColumnsSchema.gridColumns
    }
  }
}
