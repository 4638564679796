Ext.define('MDM.src.view.regionapp.Panel.Tree', {
  extend: 'Ext.tree.Panel',
  xtype: 'regionapp-panel-tree',
  rootVisible: true,
  columnLines: true,
  rowLines: true,
  listeners: {
    select: 'onTreeSelect',
    droped: 'onDropMaterial',
    boxready: {
      fn: function (p) {
        var me = this,
          view = me.getView()

        me.dropZone = new Ext.dd.DropZone(view.el, {
          view: view,
          ddGroup: 'grid-to-form',

          // Obtain correct styles for drop allowed/not allowed from
          // the dropzon prototype.

          getAllowed: function (allowed) {
            var proto = Ext.dd.DropZone.prototype
            return allowed ? proto.dropAllowed : proto.dropNotAllowed
          },

          // Logic here to specify return of correct styling
          // for dragged item when over parts of the drop zone

          notifyOver: function (source, e, data) {
            var rec = this.getNodeRecord(e)
            if (!data.view.grid.ownerGrid.canDragToTree) return false
            if (e.getTarget(view.rowSelector) === null) {
              return false
            }
            if (!rec) {
              return false
            }
            var depth = rec.get('depth')

            return this.getAllowed(depth == 3)
          },

          // Called when dragged element is over a drop zone. Used
          // when tasks are required when mouse is over the drop zop

          notifyEnter: function (source, e, data) {},

          // Called when dragged element leaves drop zone. Used
          // to perform any required tasks upon exit of drop zone

          notifyOut: function (source, e, data) {},

          getNodeRecord: function (e) {
            if (e.getTarget(view.rowSelector) === null) return false
            if (view.getRecord === null) return false
            if (!view.getRecord) return false
            return view.getRecord(e.getTarget(view.rowSelector))
          },

          onContainerDrop: function (source, e, data) {
            if (!data.view.grid.ownerGrid.canDragToTree) return false

            var rec = this.getNodeRecord(e)
            if (!rec) return false
            var depth = rec.get('depth')
            var text = rec.get('text')
            if (depth == 3) {
              // Perform desired tasks to accomplish
              // drop into tree and possibly remove record
              // from source grid. Return drop as successfull
              if (data.records.length == 0) return true
              me.fireEvent('droped', me, data.records, rec.get('DPIFamilyID'), data.view.ownerGrid)
              var records = data.records

              return true
            }
            // Drop not permitted. Let framework repair the drop
            return false
          }
        })
      }
    }
  },

  columns: [
    {
      xtype: 'treecolumn', // this is so we know which column will show the tree
      text: '',
      dataIndex: 'text',
      flex: 2,
      width: 300,
      sortable: true
    }
  ],
  dockedItems: [
    {
      xtype: 'toolbar',
      dock: 'top',
      items: [
        {
          flex: 1,
          xtype: 'textfield',
          reference: 'dpifamily',
          emptyText: 'Search',
          enableKeyEvents: true,

          triggers: {
            clear: {
              cls: 'x-form-clear-trigger',
              handler: 'onClearTriggerClick',
              hidden: true
            },
            search: {
              cls: 'x-form-search-trigger',
              weight: 1,
              handler: 'onSearchTriggerClick'
            }
          },

          listeners: {
            keyup: function (field, e) {
              var value = field.getValue()

              if (value.length >= 3 && e.browserEvent.keyCode !== 13) {
                field.fireEvent('dofilter', field, e)
              }
              if (value.length == 0 && e.browserEvent.keyCode !== 13) {
                field.fireEvent('clearfilter', field, e)
              }
            },
            dofilter: 'onSearchTree',
            clearfilter: 'onClearTriggerClick'
          }
        }
      ]
    }
  ]
})
