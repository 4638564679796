import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import _ from 'lodash'
import cx from 'classnames'
import { slvyToast } from '../../../components'
import { MESSAGES } from '../messages'

const Pantone = (props) => {
  const {
    pluginId,
    designId,
    columnHeightValue,
    readOnly,
    selectedMainColorId,
    isForAddColor = false,
    isMaterialList,
    materialTypeID,
    showModal = () => {},
    afterDelete = () => {},
    client = () => {},
    onEdit = () => {},
    onColorClick = () => {}
  } = props

  const [pending, setPending] = useState(false)
  const IsMainColor = props.data ? !props.data.AttributeId || props.data.AttributeId === 0 : false

  function removeMainColor() {
    const { data: { PantoneCode: pantoneCode, MainColorId: mainColorId } = {}, data = {} } = props
    client
      .post(`/data/${pluginId}/Invoke/DeleteMainColor`, {
        data: { designId, pantoneCode, mainColorId }
      })
      .then(() => {
        afterDelete(data, true)
        setPending(false)
      })
      .catch(() => {
        slvyToast.error({ message: MESSAGES.an_unknown_error_occurred, title: MESSAGES.error })
        setPending(false)
      })
  }

  function removeOptionColor() {
    const {
      data: {
        AttributeId: attributeId,
        MainColorId: mainColorId,
        MaterialTypeID: materialTypeID
      } = {}
    } = props
    client
      .post(`/data/${pluginId}/Invoke/DeleteColorFromOptionAttribute`, {
        data: { attributeId, mainColorId, materialTypeID }
      })
      .then((result) => {
        afterDelete(result, false)
        setPending(false)
      })
      .catch(() => {
        slvyToast.error({ message: MESSAGES.an_unknown_error_occurred, title: MESSAGES.error })
        setPending(false)
      })
  }

  function handleRemove(event) {
    const { data: { MaterialName } = {} } = props
    event.stopPropagation()

    confirmAlert({
      title: IsMainColor ? MESSAGES.delete_pantone_color : `Delete ${MaterialName} Color?`,
      message: MESSAGES.are_you_sure_you_want_to_delete_this,
      buttons: [
        {
          label: MESSAGES.cancel
        },
        {
          label: MESSAGES.confirm_delete,
          onClick: () => {
            setPending(true)
            IsMainColor ? removeMainColor() : removeOptionColor()
          }
        }
      ]
    })
  }

  function handleEdit(event) {
    event.stopPropagation()
    const { data = {} } = props
    onEdit(data)
  }

  function handlePantoneClick() {
    const { data: { MainColorId } = {} } = props
    if (IsMainColor) onColorClick(MainColorId)
  }

  const {
    data: {
      MasterCode,
      MainColorId = 0,
      PantoneCode,
      Name,
      NameTR = '',
      Red,
      Green,
      Blue,
      IsCustomColor,
      Notes
    } = {}
  } = props

  const isSelected = IsMainColor && selectedMainColorId === MainColorId
  const isNoColor = !!(_.isNull(PantoneCode) || _.isNull(Red) || _.isNull(Green) || _.isNull(Blue))

  return (
    <li className={cx('col-md-6', { active: isSelected })} onClick={handlePantoneClick}>
      <div className="cb-ctn overflow-hidden position-relative rounded-2 m-1" title={Notes}>
        {pending && (
          <div className="box-loading text-center position-absolute top-0 bottom-0 start-0 end-0 bg-white">
            {MESSAGES.deleting}
          </div>
        )}
        {!isForAddColor && (
          <div>
            {IsCustomColor && <span className="header position-absolute start-50 fw-bold">CC</span>}
            <div
              className="color-box w-100 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: isNoColor ? '#fff' : `rgb(${Red},${Green},${Blue})`,
                height: columnHeightValue
              }}
            >
              {isNoColor ? (
                <span className="no-color-info fw-semibold">{MESSAGES.no_color_info}</span>
              ) : (
                ''
              )}
            </div>
            <div className="p-1">
              <b className="text-truncate">{PantoneCode || '-'}</b>
              <span className="text-truncate">
                {MasterCode} - {Name}
                {NameTR ? `/${NameTR}` : ''}
              </span>
            </div>
            {!readOnly && (
              <i
                className="btn-edit d-flex justify-content-center position-absolute p-1 bg-white rounded-1 cp fa fa-pencil"
                onClick={handleEdit}
              />
            )}
            {!readOnly && (
              <i
                className="btn-trash d-flex justify-content-center position-absolute p-1 bg-white rounded-1 cp text-danger fa fa-trash"
                onClick={handleRemove}
              />
            )}
          </div>
        )}
        {isForAddColor && (
          <div
            className="color-box w-100 d-flex align-items-center justify-content-center color-add-icon"
            onClick={() => showModal(!isMaterialList, 0, materialTypeID)}
          >
            <i aria-hidden="true" className="fa fa-plus-circle" />
          </div>
        )}
      </div>
    </li>
  )
}

export default Pantone
