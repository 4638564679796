import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PRODUCTfilterValues as filterValuesFunc } from '../../../../../actions'
import MenuItem from './MenuItem'

class Menu extends React.Component {
  constructor() {
    super()
    this.state = {
      isToggleOn: false,
      isTriggerHovered: false,
      hoveredItemIndex: null,
      clickedSubItemIndex: null
    }

    this.handleToggleMenu = this.handleToggleMenu.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleItemMouseEnter = this.handleItemMouseEnter.bind(this)
    this.handleSubItemClick = this.handleSubItemClick.bind(this)
  }

  handleMouseEnter() {
    this.setState({ isTriggerHovered: true })
  }

  handleMouseLeave() {
    this.setState({ isTriggerHovered: false })
  }

  handleToggleMenu() {
    this.props.onClick()
  }

  handleItemMouseEnter(i) {
    this.setState({ hoveredItemIndex: i })
  }

  handleSubItemClick(filterName, isResourceBased, operator, value, format, isChange) {
    this.props.filterValuesFunc(
      this.props.periodIndex,
      filterName,
      isResourceBased,
      operator,
      value,
      format,
      isChange
    )
  }

  render() {
    const {
      legend: {
        productLegendData: { resourceInfoList = [] },
        productLegendState: { currentProductInfoIndex = [] }
      },
      filterValues,
      periodIndex,
      weeklySort
    } = this.props
    const { clickedSubItemIndex, hoveredItemIndex } = this.state

    const list = _.map(currentProductInfoIndex, (n) => {
      const {
        name = '',
        shortName = '',
        tableName = '',
        isResourceBased,
        format
      } = resourceInfoList[n] || {}

      return (
        <MenuItem
          key={n.toString()}
          clickedSubItemIndex={clickedSubItemIndex}
          connectApi={this.props.connectApi}
          filterValues={filterValues}
          format={format}
          hoveredIndex={hoveredItemIndex}
          i={n}
          isResourceBased={isResourceBased}
          issueList={resourceInfoList}
          name={shortName || name}
          periodIndex={periodIndex}
          tableName={tableName}
          weeklySort={weeklySort}
          onClick={this.handleSubItemClick}
          onMouseEnter={this.handleItemMouseEnter}
        />
      )
    })

    return <ul className="menu">{list}</ul>
  }
}

const mapStateToProps = (state) => {
  return {
    clickedProductInfo: state.PRODUCTclickedProductInfo.clickedProductInfo,
    filterValues: state.PRODUCTresourcePlan.filterValues,
    weeklySort: state.resourcePlan.weeklySort,
    configSettings: state.resourcePlan.configSettings,
    legend: state.legend
  }
}

const mapDispatchToProps = {
  filterValuesFunc
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
