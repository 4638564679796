import { useState, useEffect, ChangeEvent } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { FormControl, InputGroup, Button, Modal } from 'react-bootstrap'
import IconSource from '../PropertyEditor/Editors/IconSource'
import { ISlvyIconSelectorProps } from './SlvyIconSelector.types'
import './SlvyIconSelector.scss'

export default function SlvyIconSelector(props: ISlvyIconSelectorProps) {
  const {
    value,
    onChange,
    onlyAddon = false,
    inputId,
    inputClass = '',
    inputGroupClass = '',
    error = false
  } = props

  const [query, setQuery] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [selectedIconName, setSelectedIconName] = useState('')
  const [selectedIconClass, setSelectedIconClass] = useState('')

  useEffect(() => {
    setSelectedIcon(props)
  }, [])

  useEffect(() => {
    setSelectedIcon(props)
  }, [value])

  function setSelectedIcon($props: ISlvyIconSelectorProps) {
    if ($props.value) {
      setSelectedIconName(getIconDescription($props.value))
      setSelectedIconClass($props.value)
    }
  }

  const close = () => setIsVisible(false)

  const open = () => setIsVisible(true)

  function onIconClick(_selectedIconName: string, _selectedIconClass: string) {
    setSelectedIconName(_selectedIconName)
    setSelectedIconClass(_selectedIconClass)
    close()
    onChange(_selectedIconClass)
  }

  const onClearSelected = () => {
    setQuery('')
    setSelectedIconName('')
    setSelectedIconClass('')
    close()
    onChange('')
  }

  const onFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  function getIconDescription(iconClass: string): string {
    const modifiedIconClass = iconClass.replace('fa ', '')
    let description = ''
    IconSource.some((sectionInfo) => {
      sectionInfo.icons.some((iconInfo) => {
        if (modifiedIconClass === iconInfo.c) {
          description = iconInfo.l
          return true
        }
        return false
      })
      return false
    })
    return description
  }

  function getFilteredIcons(newFilter: string) {
    // Remove 'Brand' section
    let sections = _.filter(IconSource, (sectionInfo) => {
      return sectionInfo.section !== 'brand'
    })

    const lowerCaseFilter = newFilter.toLowerCase()

    // Filter Icons
    sections = sections.map((sectionInfo) => {
      return {
        section: sectionInfo.section,
        icons: _.filter(sectionInfo.icons, (iconInfo) => {
          return iconInfo.l.toLowerCase().indexOf(lowerCaseFilter) >= 0
        })
      }
    })

    sections = sections.map((sectionInfo) => {
      return {
        section: sectionInfo.section,
        icons: _.map(sectionInfo.icons, (iconInfo) => {
          return {
            c: `fa ${iconInfo.c}`,
            l: iconInfo.l
          }
        })
      }
    })

    // Remove empty sections
    sections = _.filter(sections, (sectionInfo) => {
      return sectionInfo.icons.length > 0
    })

    return sections
  }

  const iconData = getFilteredIcons(query)
  const sections = iconData.map((sectionInfo) => {
    return (
      <div key={sectionInfo.section}>
        <h4 className="fw-bold my-2">{sectionInfo.section}</h4>
        {sectionInfo.icons.map((iconInfo) => {
          return (
            <Button
              key={iconInfo.c}
              className="m-1"
              data-testid="slvy-icon-selector-icon-button"
              title={iconInfo.l}
              variant="outline-dark"
              onClick={() => onIconClick(iconInfo.l, iconInfo.c)}
            >
              <i aria-hidden="true" className={`fa fa-fw ${iconInfo.c}`} />
            </Button>
          )
        })}
      </div>
    )
  })

  const optionalProps = inputId ? { id: inputId } : {}

  return (
    <>
      <InputGroup
        className={cx(
          'd-flex flex-nowrap justify-content-center w-100 icon-selector',
          inputGroupClass,
          {
            'is-invalid': error
          }
        )}
        data-testid="slvy-icon-selector"
        onClick={open}
      >
        <InputGroup.Text>
          <i aria-hidden="true" className={cx('fa', 'fa-fw', selectedIconClass)} />
        </InputGroup.Text>
        {onlyAddon ? null : (
          <FormControl
            readOnly
            className={cx('bg-light', 'icon-selector-input', inputClass, { 'is-invalid': error })}
            data-testid="slvy-icon-selector-input"
            type="text"
            value={selectedIconName}
            onClick={open}
            {...optionalProps}
          />
        )}
        {onlyAddon ? null : (
          <Button
            className="px-1 select-icon-btn"
            data-testid="slvy-icon-selector-select-button"
            variant="dark"
            onClick={open}
          >
            Select Icon
          </Button>
        )}
      </InputGroup>
      <Modal data-testid="slvy-icon-selector-modal" show={isVisible} size="lg" onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Select an Icon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            className="mb-2"
            data-testid="slvy-icon-selector-search-input"
            placeholder="Search"
            type="text"
            value={query}
            onChange={onFilterChanged}
          />
          <div className="overflow-auto slvyIconContainer">{sections}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-testid="slvy-icon-selector-clear-button"
            variant="outline-danger"
            onClick={onClearSelected}
          >
            Clear Selected
          </Button>
          <Button variant="outline-dark" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
