import defaults from 'superagent-defaults'

// Create a defaults context
const superagent = defaults()

const {
  setting: { environment = '' },
  oidc: {
    user: { access_token = '' }
  }
} = window.dashboardStore.getState()

// Setup some defaults
superagent.set({
  environment,
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${access_token}`
})

export default superagent
