import { TQuery } from './QuickSearch.types'
import classes from './NotFound.module.scss'

const NotFound = ({ value }: { value: TQuery }) => {
  return (
    <li className={`${classes.notFound} p-3`} data-testid="quick-search-not-found">
      <i aria-hidden="true" className="fa fa-info-circle me-3" />
      Sorry, <b>{value}</b> is not found.
    </li>
  )
}

export default NotFound
