import React, { Component } from 'react'
import { Modal, Button, Row, Col, Form, FormGroup, Card } from 'react-bootstrap'
import Select from 'react-select'
import _ from 'lodash'
import { SlvySpinner } from '../../../components'

class Popup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reasonValue: '',
      assigneeValue: '',
      progressPercent: 100
    }
    this.handleAssigneeChange = this.handleAssigneeChange.bind(this)
    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.checkConfirmationEnabling = this.checkConfirmationEnabling.bind(this)
    this.ok = this.ok.bind(this)
  }

  mappingFromCrudListForUsers = (list) => {
    return _.map(list, function (item) {
      return {
        label: `${item.Name} ${item.Surname}`,
        value: item.UserName
      }
    })
  }

  mappingFromCrudList = (list, key, value) => {
    return _.map(list, function (item) {
      return { label: item[value], value: item[key] }
    })
  }

  handleAssigneeChange(newAssignee) {
    if (newAssignee) {
      this.setState({
        assigneeValue: newAssignee.value
      })
    } else {
      this.setState({
        assigneeValue: null
      })
    }
  }

  handleReasonChange(reason) {
    if (reason) {
      this.setState({
        reasonValue: reason.value
      })
    } else {
      this.setState({
        reasonValue: null
      })
    }
  }

  checkConfirmationEnabling() {
    const {
      areThereUsers = false,
      areThereReasons = false,
      popupReasonsSuccess = false,
      popupUsersSuccess = false
    } = this.props
    if (areThereUsers && areThereReasons) {
      return popupUsersSuccess && popupReasonsSuccess
    }
    if (areThereUsers) return popupUsersSuccess
    if (areThereReasons) return popupReasonsSuccess
    return false
  }

  ok() {
    const { reasons } = this.props
    const { assigneeValue, reasonValue } = this.state
    const reasonDescription = _.find(reasons, { ReasonId: reasonValue })
    const Description = reasonDescription ? reasonDescription.Description : ''
    this.props.onOk(assigneeValue, Description)
  }

  render() {
    const {
      title = '',
      reasons = [],
      users = [],
      popupReasonsSuccess = false,
      popupUsersSuccess = false
    } = this.props
    const { reasonValue = '', assigneeValue = '' } = this.state
    const enableConfirmation = this.checkConfirmationEnabling()
    return (
      <div className="static-modal">
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Card>
              <Card.Body className={`${!enableConfirmation ? 'slvy-spinner-loading' : ''}`}>
                {popupUsersSuccess && (
                  <Row>
                    <Col md={12} sm={12}>
                      <FormGroup className="mb-3" controlId="Assignee">
                        <Form.Label className="fw-bold">New Assignee</Form.Label>
                        <Select
                          clearable={false}
                          escapeClearsValue={false}
                          options={this.mappingFromCrudListForUsers(users)}
                          placeholder="Please choose new assignee"
                          value={assigneeValue}
                          onChange={this.handleAssigneeChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {popupReasonsSuccess && (
                  <Row>
                    <Col md={12} sm={12}>
                      <FormGroup className="mb-3" controlId="Watchers">
                        <Form.Label className="fw-bold">Reason</Form.Label>
                        <Select
                          clearable={false}
                          escapeClearsValue={false}
                          options={this.mappingFromCrudList(reasons, 'ReasonId', 'Description')}
                          placeholder="Please choose reason if necessary"
                          value={reasonValue}
                          onChange={this.handleReasonChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {users.length === 0 && reasons.length === 0 && <SlvySpinner />}
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!enableConfirmation}
              size="sm"
              variant="dark"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !enableConfirmation ||
                (popupUsersSuccess && assigneeValue === '') ||
                (popupReasonsSuccess && reasonValue === '')
              }
              size="sm"
              variant="success"
              onClick={this.ok}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    )
  }
}
export default Popup
