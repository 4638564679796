export default `({
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b> ' + tt('Campaign Info') + '</b>'
        }]
    }],
    border: true,
    // header: false,
    bind:{
        title:tt('Spot Bilgilerii'),
    },
    margin: 2,
    defaults: {
        labelStyle: 'font-weight:bold;',
    },
    xtype: 'panel',
    header: false,
    bind: {
        data: {
            SalesPrice: '{spotRecord.SalesPrice}',
            PreStartDate: '{spotRecord.PreStartDate}',
            PreEndDate: '{spotRecord.PreEndDate}',
            UnitofMeasurement: '{spotRecord.UnitofMeasurement}',
            UnitsPerPackage: '{spotRecord.UnitsPerPackage}',
            MinQuantity: '{spotRecord.MinQuantity}',
            MinXLQuantity: '{spotRecord.MinXLQuantity}',
            MaterialDescription: '{spotRecord.MaterialDescription}',
            //SasNubmer: '{spotRecord.SasNubmer}',
            ContractNumber: '{spotRecord.ContractNumber}',
            UseStock: '{spotRecord.UseStock}',
            Palletize: '{spotRecord.Palletize}',
            FullTruck: '{spotRecord.FullTruck}',
            Returnable: '{spotRecord.Returnable}',
            AllowRoundingUp: '{spotRecord.AllowRoundingUp}',
            UseDCInventory: '{spotRecord.UseDCInventory}',
        }
    },
    tpl: [
        '<div class="expander" style="text-align:center"> ' +
        '  <table class="table" style=" width: 98% ;min-width:100px:"> ' +
        '  <tr> ' +
        '<th colspan=4 style="text-align:center">{MaterialDescription} </td>',
        '  </tr>' +
        '    <tr> ' +
        '  <th class="r">' + tt('SalesPrice') + ' </th> ' +
        '         <td class="r">{[this.currencyFormat(values.SalesPrice)]}</td> ' +
        '        <th class="r"> ' + tt('Contract Number') + '</td>' +
        '        <td class="r">{ContractNumber} </td>' +
        '     </tr> ' +
        '  </tr>' +
        '    <tr> ' +
        '          <th class="r">' + tt('PreStartDate') + '</th>' +
        '        <td class="r">{PreStartDate:date(tt("d-m-Y"))} </td>' +
        '         <th class="r">' + tt('PreEndDate') + '</th>' +
        '        <td class="r">{PreEndDate:date(tt("d-m-Y"))} </td>' +
        '     </tr> ' +
        '    <tr>' +
        '         <th class=r>' + tt('UnitofMeasurement') + '</th>' +
        '        <td class="r">{UnitofMeasurement}</td>' +
        '       <th class="r">' + tt('UnitsPerPackage') + '</th>' +
        '        <td class="r"> {UnitsPerPackage}</td>' +
        '     </tr>' +
        '  </tr>' +
        '    <tr>' +
        '        <th class="r"> ' + tt('MinQuantity') + '</td>' +
        '        <td class="r">{MinQuantity} </td>' +
        '        <th class="r">' + tt('Minimum XL Order Quantity') + '</td>' +
        '        <td class="r" >{MinXLQuantity}</td>' +
        '     </tr>' +
        '    <tr>' +
    '        <th class="r"> ' + tt('Round the Order Recommendations') + '</td>' +
        '        <tpl if="AllowRoundingUp == true">',
        '         <td><i class="fa fa-check"/> </td>',
        '         <tpl else>',
        '         <td><i class="fa fa-times"/> </td>',
        '         </tpl>' +
        '    <th class="r"> ' + tt('Use DC Inventory') + '</td>' +
        '        <tpl if="UseDCInventory == true">',
        '         <td><i class="fa fa-check"/> </td>',
        '         <tpl else>',
        '         <td><i class="fa fa-times"/> </td>',
        '         </tpl>' +
        '    <tr>' +
        '        <th class="r"> ' + tt('Palet Kati Planla') + '</td>' +
        '<tpl if="Palletize == true">',
        '<td><i class="fa fa-check"/> </td>',
        '<tpl else>',
        '<td><i class="fa fa-times"/> </td>',
        '</tpl>' +
        '        <th class="r">' + tt('KamyonKatiPlanla') + '</td>' +
        '<tpl if="FullTruck == true">',
        '<td><i class="fa fa-check"/> </td>',
        '<tpl else>',
        '<td><i class="fa fa-times"/> </td>',
        '</tpl>' +
        '     </tr>' +
        '    <tr>' +
        '        <th class="r"> ' + tt('Iade Durumu') + '</td>' +
        '<tpl if="Returnable==true">',
        '<td><i class="fa fa-check"/> </td>',
        '<tpl else>',
        '<td><i class="fa fa-times"/> </td>',
        '</tpl>' +
        '        <th class="r">' + tt('Eldeki Envanteri Kullan') + '</td>' +
        '<tpl if="UseStock == true">',
        '<td><i class="fa fa-check"/> </td>',
        '<tpl else>',
        '<td><i class="fa fa-times"/> </td>',
        '</tpl>' +
        '     </tr>' +
        '  </table>' +
        '  </div>',
        {
            // XTemplate configuration:
            // disableFormats: true,
            // member functions:
            numberFormat: function (value) {
                return Ext.util.Format.number(value, "000.00").replace('.', ',')
            },
            currencyFormat: function (value) {
                if(t('₺')=='₺')
                {
                    return Ext.util.Format.number(value, "000.00").replace('.', ',') + ' ' + t('₺');
                }
                else{
                    return t('₺') +' '+ Ext.util.Format.number(value, "000.00").replace('.', ',');                            
                }
            }
        }
    ],
})
`
