import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type TreeState = {
  selectedLoadTreeRows: {
    id: string
    loadStops: string[]
  }
  selectedDemandTreeRows: string[]
  load: {
    card: {
      isOpen: boolean
    }
  }
  demand: {
    card: {
      isOpen: boolean
    }
  }
}

const initialState: TreeState = {
  selectedLoadTreeRows: {
    id: '',
    loadStops: []
  },
  selectedDemandTreeRows: [],
  load: {
    card: {
      isOpen: false
    }
  },
  demand: {
    card: {
      isOpen: false
    }
  }
}

const sidebarSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    setSelectedLoadTreeRow(state, action: PayloadAction<TreeState>) {
      // return {
      //   ...state,
      //   selectedLoadTreeRows:
      //     state.selectedLoadTreeRows.length === 0
      //       ? action.payload.selectedLoadTreeRows
      //       : state.selectedLoadTreeRows.reduce((accumulator, current) => {
      //           return action.payload.selectedLoadTreeRows.includes(current)
      //             ? accumulator
      //             : accumulator.concat(action.payload.selectedLoadTreeRows[0])
      //         }, [])
      // }

      state.selectedLoadTreeRows =
        action.payload.selectedLoadTreeRows.id === state.selectedLoadTreeRows.id
          ? { id: '', loadStops: [] }
          : action.payload.selectedLoadTreeRows
    },
    openLoadCard(state) {
      state.load.card.isOpen = true
    },
    closeLoadCard(state) {
      state.load.card.isOpen = false
    },
    openDemandCard(state) {
      state.demand.card.isOpen = true
    },
    closeDemandCard(state) {
      state.demand.card.isOpen = false
    }
  }
})

export default sidebarSlice
export const {
  setSelectedLoadTreeRow,
  openLoadCard,
  closeLoadCard,
  openDemandCard,
  closeDemandCard
} = sidebarSlice.actions
