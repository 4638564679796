import { CSSProperties } from 'react'
import { IJobConfig } from '../Jobs.types'
import { IButtonList } from './RemoteActionCompleted.types'

export const config: IJobConfig = {
  key: 'remoteActionCompleted',
  title: 'Wait Remote Action',
  detail_title: 'Run Remote Action'
}

export const style: CSSProperties = {
  margin: 0
}

export const buttonList: IButtonList[] = [
  { name: 'Success', action: 'Succeeded' },
  { name: 'Fail', action: 'Failed' }
]
