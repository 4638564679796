Ext.define('MDM.src.view.calendar.Main', {
  extend: 'Ext.panel.Panel',
  xtype: 'calendarmain',
  layout: 'fit',
  bodyPadding: 10,
  viewModel: 'calendar-mainviewmodel',
  items: [
    {
      xtype: 'calendar-calendar',
      readOnlyMode: true,
      bind: {
        store: '{calStore}'
      }
    }
  ]
})
