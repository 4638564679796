import superagent from 'superagent'
import _ from 'lodash'
import { API_URL, NODE_ENV } from '@/constants'

const methods = ['get', 'post', 'put', 'patch', 'del']

function formatUrl(path) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path
  // Prepend host and port of the API server to the path.
  return API_URL + adjustedPath
}

XMLHttpRequest.prototype.wrappedOpen = XMLHttpRequest.prototype.open

XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
  this.wrappedOpen.apply(this, arguments)

  if (url.indexOf(API_URL) >= 0) {
    const {
      setting: { environment, connectionEnvironment = 0 },
      oidc: {
        user: { access_token }
      }
    } = window.dashboardStore.getState()

    this.setRequestHeader('Authorization', `Bearer ${access_token}`)
    this.setRequestHeader('Environment', environment)
    this.setRequestHeader('ConnectionEnvironment', connectionEnvironment)
  }
}

// TODO : we don't need the setRequestHeader wrapper, when it is removed from all plugins
XMLHttpRequest.prototype.wrappedSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader

XMLHttpRequest.prototype.setRequestHeader = function (header, value) {
  if (!this.headers) {
    this.headers = {}
  }

  const headerSafe = header.toLowerCase()

  if (
    this.headers[headerSafe] &&
    (headerSafe === 'authorization' ||
      headerSafe === 'environment' ||
      headerSafe === 'connectionenvironment')
  ) {
    if (NODE_ENV === 'development') {
      // console.log(`Header: ${header} is already set.`)
    }
  } else {
    this.wrappedSetRequestHeader(header, value)
    this.headers[headerSafe] = value
  }
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(
      (method) =>
        (this[method] = (path, { params, data, attach } = {}, requestHeader = {}) => {
          return new Promise((resolve, reject) => {
            const request = superagent[method](formatUrl(path))

            if (params) {
              request.query(params)
            }

            if (attach) {
              _.each(attach.files, (file) => {
                request.attach(...file)
              })

              _.each(attach.fields, (field) => {
                request.field(field.name, field.value)
              })
            }

            _.each(requestHeader, (value, key) => {
              request.set(key, value)
            })

            if (data) {
              request.send(data)
            }

            request.end((err, { body } = {}) => (err ? reject(body || err) : resolve(body)))
          })
        })
    )
  }

  empty() {}
}
