export default {
  chart: {
    type: 'spline',
    height: 450,
    zoomType: 'xy'
  },
  colors: [
    '#7cb5ec',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
    '#582425'
  ],
  title: {
    text: 'Sales History',
    style: { color: '#333333', fontSize: '15px' }
  },
  xAxis: {
    type: 'category',
    tickLength: 5,
    labels: {
      enabled: true,
      padding: 2
    },
    categories: []
  },
  yAxis: {
    title: {
      text: 'Avg. Wk. Sales per Store'
    }
  },
  tooltip: {
    valueDecimals: 2,
    shared: true
  },
  exporting: {
    buttons: {
      contextButton: {
        align: 'left'
      }
    }
  },
  series: [],
  legend: {
    itemStyle: {
      fontSize: 11
    },
    padding: 2
  },
  credits: {
    text: '',
    href: '',
    position: {
      align: 'right',
      y: -5
    },
    style: {
      fontSize: '7pt'
    }
  }
}
