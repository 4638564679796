import React, { useState, ChangeEvent, KeyboardEvent } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { MESSAGES } from '../../messages'
import { IScenarioSearch } from './ScenarioSearch.types'
import styles from './index.module.scss'

const ScenarioSearch = (props: IScenarioSearch) => {
  const [, setIsNotFound] = useState(false)
  const [query, setQuery] = useState('')

  const settings = {
    minChars: props.minChars
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { target: { value = '' } = {} } = event
    value = value
      .replace(/  +/g, ' ') //  eslint-disable-line no-regex-spaces
      .replace(/\.|\*|\\|\>|\<|\+|\%|\?|\/|\&|\:|\#/g, '') // eslint-disable-line

    setQuery(value)
    checkQuery(value)

    props.onChange({ query: value })
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event
    if (keyCode === 27) {
      onClear()
    }
  }

  const checkQuery = (value: string) => {
    if (!_.isEmpty(value)) {
      if (!(value.length < settings.minChars + 1)) {
        searchTree(value)
      }
    } else {
      onClear()
    }
  }

  const onClear = () => {
    setIsNotFound(false)
    setQuery('')
    props.onClear()
  }

  const searchTree = (val: string) => {
    const { rawData = [], onSearchStart = () => {}, onSearchEnd = () => {} } = props

    let _tree = _.cloneDeep(rawData)
    let _isNotFound = false
    const items: any[] = []

    if (val.length >= settings.minChars) {
      onSearchStart(val)

      const response = _.cloneDeep(_tree).filter(function filterFn(item: {
        Item: { Name: string; IsHighlight: boolean }
        Children: any[]
      }): any {
        if (item.Item.Name.toLowerCase().includes(val.toLowerCase())) {
          item.Item.IsHighlight = true
          return true
        }
        if (item.Children) {
          return (item.Children = item.Children.filter(filterFn)).length
        }
        return null
      })

      _.cloneDeep(response).filter(function filterFnc(item: {
        Item: { IsScenario: boolean }
        Children: any[]
      }): any {
        if (item.Item.IsScenario === false) {
          items.push({ ...item.Item })
        }
        if (item.Children) {
          return (item.Children = item.Children.filter(filterFnc)).length
        }
        return null
      })

      const noData = _.isEmpty(response)
      _isNotFound = noData
      _tree = noData ? [] : response
    }

    setIsNotFound(_isNotFound)

    onSearchEnd(items, _tree, _isNotFound)
  }

  return (
    <div className={styles.scenarioSearch}>
      <input
        className={styles.scenarioSearchInput}
        placeholder={MESSAGES.search}
        type="text"
        value={query}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {!_.isEmpty(query) ? (
        <i className={cx(styles.scenarioSearchResetBtn, 'fa', 'fa-times')} onClick={onClear} />
      ) : null}
    </div>
  )
}

export default ScenarioSearch
