import _ from 'lodash'

let totalSchema = {}

const pluginSchema = {
  title: 'Word Cloud',
  type: 'object',
  format: 'objectTab',
  properties: {}
}

const generalSchema = {
  general: {
    title: 'General',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      },
      header: {
        type: 'string',
        title: 'Header'
      },
      maximizable: {
        type: 'boolean',
        title: 'Maximizable',
        default: false,
        required: true
      },
      export: {
        type: 'boolean',
        title: 'Exportable',
        default: true,
        required: true
      },
      showNoDataToDisplay: {
        type: 'boolean',
        title: 'Show No Data To Display',
        default: true,
        required: true
      }
    }
  }
}

const titleSchema = {
  title: {
    title: 'Title',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      margin: {
        type: 'number',
        default: 15
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '18px' },
        options: {
          basic: true
        }
      },
      maxStyle: {
        type: 'object',
        title: 'Max Style',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '20px' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: 'Chart Title',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 10
      }
    }
  }
}

const subtitleSchema = {
  subtitle: {
    title: 'Subtitle',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(102,102,102,1)' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 30
      }
    }
  }
}

const exportingSchema = {
  exporting: {
    title: 'Exporting',
    type: 'object',
    properties: {
      allowHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true
      },
      fallbackToExportServer: {
        type: 'boolean',
        required: true,
        default: true
      },
      filename: {
        type: 'string',
        default: 'chart'
      },
      libURL: {
        type: 'string',
        default: 'https://code.highcharts.com/{version}/lib'
      },
      printMaxWidth: {
        type: 'number',
        default: 780
      },
      scale: {
        type: 'number',
        default: 2
      },
      sourceHeight: {
        type: 'NullableNumber',
        default: ''
      },
      sourceWidth: {
        type: 'NullableNumber',
        default: ''
      },
      url: {
        type: 'string',
        default: 'https://export.highcharts.com'
      },
      width: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const dataSchema = {
  data: {
    title: 'Data',
    type: 'object',
    properties: {
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string',
          title: 'Field'
        }
      },
      fieldName: {
        type: 'string',
        title: 'Name',
        watch: {
          ds: 'data.datafields'
        },
        enumSource: 'ds'
      },
      fieldWeight: {
        type: 'string',
        title: 'Weight',
        watch: {
          ds: 'data.datafields'
        },
        enumSource: 'ds'
      }
    }
  }
}

const tooltipSchema = {
  tooltip: {
    title: 'Tooltip',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: 'rgba(247,247,247,0.85)',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      borderRadius: {
        type: 'number',
        default: 3
      },
      borderWidth: {
        type: 'number',
        default: 1,
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      followPointer: {
        type: 'boolean',
        required: true,
        default: true
      },
      hideDelay: {
        type: 'number',
        default: 500
      },
      padding: {
        type: 'number',
        default: 8
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: true
      },
      style: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px'
        },
        options: {
          basic: true
        }
      }
    }
  }
}

const chartSchema = {
  chart: {
    title: 'Chart',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: '#ffffff',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      showCredits: {
        type: 'boolean',
        title: 'Show credits?',
        default: true,
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 9
      },
      plotBackgroundColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      plotBackgroundImage: {
        type: 'string',
        default: '',
        propertyOrder: 1
      },
      plotShadow: {
        type: 'boolean',
        required: true,
        default: false,
        propertyOrder: 2
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: false,
        propertyOrder: 3
      },
      spiral: {
        type: 'string',
        enum: ['archimedean', 'rectangular', 'square'],
        default: 'rectangular',
        propertyOrder: 4
      },
      placementStrategy: {
        type: 'string',
        enum: ['random', 'center'],
        default: 'center',
        propertyOrder: 5
      },
      emptyText: {
        type: 'string',
        title: 'No Data Text',
        default: 'No Data to Display',
        options: {
          basic: true
        },
        propertyOrder: 7
      },
      colors: {
        type: 'array',
        title: 'Colors',
        default: [], // ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"]
        propertyOrder: 9,
        items: {
          type: 'object',
          title: 'Add Color',
          headerTemplate: '{{ self.fieldColor }}',
          options: {
            collapsed: true
          },
          properties: {
            fieldColor: {
              type: 'string',
              format: 'Color',
              default: 'rgba(0,0,0,1)'
            }
          }
        }
      }
    }
  }
}

export default () => {
  if (_.isEmpty(totalSchema)) {
    _.assign(pluginSchema.properties, generalSchema)
    _.assign(pluginSchema.properties, chartSchema)
    _.assign(pluginSchema.properties, titleSchema)
    _.assign(pluginSchema.properties, subtitleSchema)
    _.assign(pluginSchema.properties, dataSchema)
    _.assign(pluginSchema.properties, tooltipSchema)
    _.assign(pluginSchema.properties, exportingSchema)

    totalSchema = pluginSchema
  }
  return totalSchema
}
