import { StyleHTMLAttributes } from 'react'
import style from './index.module.scss'

interface ISuggestionsContainer {
  containerProps: any
  children: any
  query: string | number
  // eslint-disable-next-line react/require-default-props
  containerStyle?: StyleHTMLAttributes<any>
}

const SuggestionsContainer = ({
  containerProps,
  children,
  query,
  containerStyle = {}
}: ISuggestionsContainer) => {
  return (
    <div {...containerProps} style={{ ...containerProps.style, ...containerStyle }}>
      {children}
      <div className={style.footer}>
        Press select to search <strong>{query}</strong>
      </div>
    </div>
  )
}

export default SuggestionsContainer
