import { CSSProperties } from 'react'
import cx from 'classnames'
import { ColorCellRenderer } from '../index'
import { getActionButtonClassNames } from '../../../helpers'
import { iconFontSizeClass, withTextIconSize } from '../../../constants'
import ActionButtonCellRendererProps from './ActionButtonCellRenderer.types'
import { checkActionButtonDisabled, isActionButtonDisabled } from '../../../helpers/cell'

const ActionButtonCellRenderer = (props: ActionButtonCellRendererProps) => {
  const {
    api,
    value,
    columnType,
    node: { group, rowIndex },
    icon: { icon: selectedIcon = '' },
    actionButtonParams: { actionEditableCondition, actionClickable, actionName },
    data,
    handleActionBtnClick,
    context: { theme, groupDisplayType },
    colDef,
    colDef: {
      customInfo: { isAutoColumn }
    },
    withText = false
  } = props

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    return <strong>{value?.value}</strong>
  }

  const isColorColumn = columnType === 'color'

  // if it is a color column, do not display an icon like SenchaGrid
  const newSelectedIcon = isColorColumn ? '' : selectedIcon

  const isDisabled =
    group ||
    isActionButtonDisabled({
      record: data,
      enabledCondition: actionEditableCondition,
      clickableOnDisabled: actionClickable
    })
  const isIconDisabled = checkActionButtonDisabled({
    record: data,
    disabledCondition: actionEditableCondition
  })

  const classNames = getActionButtonClassNames({
    selectedIcon: newSelectedIcon,
    isDisabled: isIconDisabled,
    isClickable: !isDisabled
  })

  if (!newSelectedIcon && !isColorColumn) {
    return null
  }

  // TODO: Check ColorCellRenderer after each change on classNames and customAttr
  const customAttr: Partial<{
    onClick: () => void
    style: { color: CSSProperties['color']; backgroundColor: CSSProperties['backgroundColor'] }
  }> = {}
  if (!isDisabled) {
    customAttr.onClick = () => handleActionBtnClick({ actionName, data, rowIndex })
  }

  if (!isColorColumn) {
    const cellStyle = colDef?.customInfo?.cellStyle
    if (group && cellStyle) {
      const { color, backgroundColor } = cellStyle
      customAttr.style = { color, backgroundColor }
    }
    const defaultClass = withText ? withTextIconSize[theme] : iconFontSizeClass[theme]
    const agGroupClass = group ? iconFontSizeClass[theme] : defaultClass
    return <i {...customAttr} className={cx(classNames, agGroupClass, 'slvy-i-element')} />
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ColorCellRenderer
      {...props}
      classNames={classNames}
      customAttr={customAttr}
      value={value as string}
    />
  )
}

export default ActionButtonCellRenderer
