Ext.define('MDM.src.view.regionapp.DroppedMaterial.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.droppedmaterial-maincontroller',
  /**
   * Called when the view is created
   */
  updated: false,
  init: function () {
    this.getViewModel().set('userMegaRegion', this.getView().userMegaRegion)
    this.getViewModel().set('isGlobal', this.getView().isGlobal)
  },
  onRestore: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      mainGrid = this.lookupReference('mainGrid'),
      rec = record,
      view = this.getView(),
      title = rec.get('MaterialDescription')
    MDM.src.ux.MessageBox.show({
      title: title + ' Restore ',
      width: 500,
      closable: false,
      msg:
        '<br> You are about to restore ' +
        title +
        '  and remove from the suppresed list.  <br><br> Are you sure ?',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doReStore(record)
        }
      }
    })
  },
  onBeforeLoadDroppedMaterialStore: function (store) {
    var store = this.getViewModel().getStore('DroppedMaterialStore')
    var viewModel = this.getViewModel()
    store.getProxy().setExtraParam('viewAs', viewModel.get('viewAs'))
    return true
  },
  doReStore: function (record) {
    var me = this,
      mainGrid = this.lookupReference('mainGrid'),
      rec = record,
      view = this.getView(),
      DroppedMaterialStore = me.getViewModel().getStore('DroppedMaterialStore')
    view.mask('Please wait ...')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/RMDM_Dropped_Material_Restore',
      params: {
        MaterialCode: record.get('MaterialCode')
      },
      success: function (response, opts) {
        me.updated = true
        view.unmask()
        DroppedMaterialStore.remove(record)
      },
      failure: function (response, opts) {
        Ext.MessageBox.hide()
      }
    })
  },
  onbeforeClose: function () {
    var me = this,
      view = me.getView()
    if (this.updated) view.fireEvent('updated', view)
  },
  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },
  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },
  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },
  exportTo: function () {
    var me = this
    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }
    cfg = Ext.merge(
      {
        title: 'List of suppressed materials',
        fileName: 'GridExport' + '.' + (cfg.ext || cfg.type)
      },
      cfg
    )
    var dropgrid = this.lookup('dropgrid')
    dropgrid.saveDocumentAs(cfg)
  },
  onChangeView: function (cycleBtn, activeItem) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('DroppedMaterialStore')
    viewModel.set('viewAs', activeItem.itemId)
    store.getProxy().setExtraParam('viewAs', viewModel.get('viewAs'))
    store.load()
  },
  onExtractDetails: function (grid, rowIndex, colIndex, item, e, record) {
    var win = Ext.create('MDM.src.view.regionapp.Cmp.ExtractDialog', {
      record: record
    })
    win.show()
  },
  applySearchFilter: function (searchField) {
    var me = this,
      store = me.getViewModel().getStore('DroppedMaterialStore')
    var queryString = searchField.getRawValue().trim().toLowerCase()
    me.queryFilter = null
    if (queryString != '') {
      var filter = (me.queryFilter = new Ext.util.Filter({
        // id: me.id + '-filter',
        anyMatch: true,
        caseSensitive: false,
        root: 'data',
        property: 'MaterialDescription',
        value: me.enableRegEx ? new RegExp(queryString) : queryString
      }))
      store.addFilter(filter, true)
    } else {
      me.resetFilter()
    }
  },
  resetFilter: function () {
    var me = this,
      store = me.getViewModel().getStore('DroppedMaterialStore')
    store.clearFilter()
  },
  containsText: function (text, queryStr) {
    queryStr = Ext.String.escapeRegex(queryStr)
    var queryStringArr = queryStr.split(',')
    var searchStringArray = []
    Ext.each(queryStringArr, function (searchValue) {
      searchValue = searchValue.trim()
      if (!Ext.isEmpty(searchValue)) {
        searchStringArray.push(searchValue)
      }
    })
    var searchString = ''
    var searchStringLen = searchStringArray.length
    if (searchStringLen === 1) {
      searchString = searchStringArray[0]
    } else if (searchStringLen > 1) {
      searchString = '^' + searchStringArray.join('$|^') + '$'
    }
    var pattern = new RegExp(searchString, 'gi')
    return pattern.test(text)
  }
})
