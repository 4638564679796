import { getAggFuncs } from '../index'
import { dateMaxParams, dateMinParams } from './mocks'

describe('AgGrid:Helpers:Agg:getAggFuncs', () => {
  it('should return the aggregateDate function for "date max"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['date max'](dateMaxParams)
    expect(result).toBe('2022-03-15 03:00:00')
  })

  it('should return the aggregateDate function for "date min"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['date min'](dateMinParams)
    expect(result).toBe('2022-01-01 03:00:00')
  })

  it('should return an empty string for "date avg"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['date avg']()
    expect(result).toBe('')
  })

  it('should return an empty string for "date sum"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['date sum']()
    expect(result).toBe('')
  })

  it('should return an empty string for "bool max"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['bool max']()
    expect(result).toBe('')
  })

  it('should return an empty string for "bool min"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['bool min']()
    expect(result).toBe('')
  })

  it('should return an empty string for "bool avg"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['bool avg']()
    expect(result).toBe('')
  })

  it('should return an empty string for "bool sum"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['bool sum']()
    expect(result).toBe('')
  })

  it('should return an empty string for "agg max"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['agg max']()
    expect(result).toBe('')
  })

  it('should return an empty string for "agg min"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['agg min']()
    expect(result).toBe('')
  })

  it('should return an empty string for "agg avg"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['agg avg']()
    expect(result).toBe('')
  })

  it('should return an empty string for "agg sum"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['agg sum']()
    expect(result).toBe('')
  })

  it('should return an empty string for "agg first"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['agg first']()
    expect(result).toBe('')
  })

  it('should return an empty string for "agg last"', () => {
    const { aggFuncs } = getAggFuncs()
    const result = aggFuncs['agg last']()
    expect(result).toBe('')
  })
})
