Ext.define('po.cmp.RoundUp', {
  extend: 'Ext.grid.Panel',
  xtype: 'roundup',
  alias: 'widget.roundup',
  enableLocking: true,
  hideCollapseTool: true,
  columnLines: true,
  bind: {
    i18n: '{i18n}',
    applicationType: '{applicationType}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView().normalView,
      grouping = normalView.getFeature('grouping')

    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText

    me.unlockText = i18n.GridConfig_unlockText
    me.lockText = i18n.GridConfig_lockText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters',
      menuFilterText: 'Filtrele'
    }
  ],
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    },
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      text: '&nbsp;',
      dataIndex: 'urunKodu',
      bind: {
        text: '{i18n.roundUp_Column_urunKodu}',
        tooltip: '{i18n.roundUp_Column_urunKodu_tooltip}',
        kayit: '{i18n.GroupGrid_SummaryRow_text}'
      },
      setKayit: function (value) {
        this.kayit = value
      },
      summaryType: 'count',
      summaryRenderer: function (value, summaryData) {
        var text = this.kayit ? this.kayit : ''
        var sumObject = summaryData
        return Ext.String.format('{0} ' + text, value)
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      width: 100,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      text: '&nbsp;',
      dataIndex: 'MagazaKodu',
      bind: {
        text: '{i18n.roundUp_Column_MagazaKodu}',
        tooltip: '{i18n.roundUp_Column_MagazaKodu_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      width: 100,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'urunAciklamasi',
      width: 200,
      bind: {
        text: '{i18n.roundUp_Column_urunAciklamasi}',
        tooltip: '{i18n.roundUp_Column_urunAciklamasi_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'depoSiparisKoli',
      width: 140,
      bind: {
        text: '{i18n.roundUp_Column_depoSiparisKoli}',
        tooltip: '{i18n.roundUp_Column_depoSiparisKoli_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      align: 'right',
      filter: {
        type: 'number'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      summaryType: 'sum'
    },
    {
      dataIndex: 'DegisenOneriKoli',
      width: 150,
      bind: {
        text: '{i18n.roundUp_Column_DegisenOneriKoli}',
        tooltip: '{i18n.roundUp_Column_DegisenOneriKoli_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      align: 'right',
      filter: {
        type: 'number'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      summaryType: 'sum'
    },
    {
      dataIndex: 'DegisenOneriKoli_PaletSayisi',
      width: 135,
      bind: {
        text: '{i18n.roundUp_Column_DegisenOneriKoli_PaletSayisi}',
        tooltip: '{i18n.roundUp_Column_DegisenOneriKoli_PaletSayisi_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      align: 'right',
      filter: {
        type: 'number'
      },
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      summaryRenderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0.00')
      },
      summaryType: 'sum'
    }
  ],
  setApplicationType: function (type) {
    var me = this
    var columns = []
    if (type == 'Store') {
      columns = [
        {
          text: '&nbsp;',
          dataIndex: 'urunKodu',
          bind: {
            text: '{i18n.roundUp_Column_urunKodu}',
            tooltip: '{i18n.roundUp_Column_urunKodu_tooltip}',
            kayit: '{i18n.GroupGrid_SummaryRow_text}'
          },
          setKayit: function (value) {
            this.kayit = value
          },
          summaryType: 'count',
          summaryRenderer: function (value, summaryData, dataIndex) {
            var text = this.kayit ? this.kayit : ''
            let sumObject = summaryData
            return Ext.String.format('{0} ' + text, value)
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          width: 100,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          }
        },
        {
          text: '&nbsp;',
          dataIndex: 'MagazaKodu',
          bind: {
            text: '{i18n.roundUp_Column_MagazaKodu}',
            tooltip: '{i18n.roundUp_Column_MagazaKodu_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          width: 100,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          }
        },
        {
          dataIndex: 'urunAciklamasi',
          width: 200,
          bind: {
            text: '{i18n.roundUp_Column_urunAciklamasi}',
            tooltip: '{i18n.roundUp_Column_urunAciklamasi_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          }
        },
        {
          dataIndex: 'depoSiparisKoli',
          width: 140,
          bind: {
            text: '{i18n.roundUp_Column_depoSiparisKoli}',
            tooltip: '{i18n.roundUp_Column_depoSiparisKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryType: 'sum'
        },
        {
          dataIndex: 'DegisenOneriKoli',
          width: 150,
          bind: {
            text: '{i18n.roundUp_Column_DegisenOneriKoli}',
            tooltip: '{i18n.roundUp_Column_DegisenOneriKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryType: 'sum'
        },
        {
          dataIndex: 'DegisenOneriKoli_PaletSayisi',
          width: 135,
          bind: {
            text: '{i18n.roundUp_Column_DegisenOneriKoli_PaletSayisi}',
            tooltip: '{i18n.roundUp_Column_DegisenOneriKoli_PaletSayisi_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryType: 'sum'
        }
      ]
    } else {
      columns = [
        {
          text: '&nbsp;',
          dataIndex: 'urunKodu',
          bind: {
            text: '{i18n.roundUp_Column_urunKodu}',
            tooltip: '{i18n.roundUp_Column_urunKodu_tooltip}',
            kayit: '{i18n.GroupGrid_SummaryRow_text}'
          },
          setKayit: function (value) {
            this.kayit = value
          },
          summaryType: 'count',
          summaryRenderer: function (value, summaryData, dataIndex) {
            var text = this.kayit ? this.kayit : ''
            let sumObject = summaryData
            return Ext.String.format('{0} ' + text, value)
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          width: 100,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          }
        },
        {
          dataIndex: 'urunAciklamasi',
          width: 200,
          bind: {
            text: '{i18n.roundUp_Column_urunAciklamasi}',
            tooltip: '{i18n.roundUp_Column_urunAciklamasi_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          }
        },
        {
          dataIndex: 'depoSiparisKoli',
          width: 140,
          bind: {
            text: '{i18n.roundUp_Column_depoSiparisKoli}',
            tooltip: '{i18n.roundUp_Column_depoSiparisKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryType: 'sum'
        },
        {
          dataIndex: 'DegisenOneriKoli',
          width: 150,
          bind: {
            text: '{i18n.roundUp_Column_DegisenOneriKoli}',
            tooltip: '{i18n.roundUp_Column_DegisenOneriKoli_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryType: 'sum'
        },
        {
          dataIndex: 'DegisenOneriKoli_PaletSayisi',
          width: 135,
          bind: {
            text: '{i18n.roundUp_Column_DegisenOneriKoli_PaletSayisi}',
            tooltip: '{i18n.roundUp_Column_DegisenOneriKoli_PaletSayisi_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return Ext.util.Format.number(value, '0.00')
          },
          summaryType: 'sum'
        }
      ]
    }
    me.reconfigure(me.store, columns)
  }
})
