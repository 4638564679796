export default {
  title: 'Button',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'Button',
  description: '',
  iconClass: 'fa-align-center',
  color: 'lazur-bg',
  group: 'Control'
}
