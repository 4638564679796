import React from 'react'
import { connect } from 'react-redux'
import { FormControl, ButtonToolbar, FormGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import { weeklySort as weeklySortFn } from '../../../../../actions'
import Sort from '../../sort'

class SubMenuItem extends React.Component {
  constructor() {
    super()
    this.state = {
      operator: ' ',
      showOperators: false,
      value: 0
    }

    this.operators = ['>', '>=', '=', '!=', '<=', '<']
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { filterValues, periodIndex, tableName } = this.props

    if (filterValues.periodIndex !== periodIndex || filterValues.tableName !== tableName) return

    this.setState({ operator: filterValues.operator, value: filterValues.value })
  }

  handleChange(event) {
    let {
      target: { value }
    } = event
    const isNotDigit = /\D/.test(value)
    value = value === '' ? 0 : isNotDigit ? this.state.value : parseFloat(value)
    this.setState({ value })

    if (this.state.operator === ' ') return

    const { tableName, isResourceBased, format, onClick } = this.props
    const { operator } = this.state

    onClick(tableName, isResourceBased, operator, value, format, true)
  }

  handleSelectOperator(operator) {
    const { tableName, isResourceBased, format, onClick } = this.props
    const { value } = this.state

    onClick(tableName, isResourceBased, operator, value, format)
    this.setState((prevState) => ({
      operator: prevState.operator === operator ? ' ' : operator
    }))
  }

  handleDeselectOperator() {
    this.setState({
      operator: ' '
    })
  }

  handleShowOperators() {
    this.setState((prevState) => ({ showOperators: !prevState.showOperators }))
  }

  handleApply() {
    const { tableName, isResourceBased, format, onClick } = this.props
    const { operator, value } = this.state

    onClick(tableName, isResourceBased, operator, value, format)
  }

  handleSort(params) {
    const { periodIndex, tableName, weeklySort } = this.props

    const isSortResetted =
      weeklySort.periodIndex === periodIndex &&
      weeklySort.issueName === tableName &&
      weeklySort.isAscending === params.IsAscending
    this.props.weeklySortFn(
      isSortResetted ? '' : periodIndex,
      isSortResetted ? '' : tableName,
      isSortResetted ? -1 : params.IsAscending
    )
  }

  render() {
    const { i, issueList, periodIndex, connectApi, weeklySort, tableName } = this.props
    return (
      <div className="submenu-item" title="">
        <div className="submenu-item__sort">
          <span>SORT</span>
          <Sort
            ascIcons={['fa-fw fa slvy-ui-icon-caret-outline-up', 'fa-fw fa slvy-ui-icon-caret-top']}
            bsClass="bsClassTest"
            connectApi={connectApi}
            currentIssueIndex={i}
            descIcons={[
              'fa-fw fa slvy-ui-icon-caret-outline-down',
              'fa-fw fa slvy-ui-icon-caret-down'
            ]}
            isAscending={
              weeklySort.periodIndex === periodIndex && weeklySort.issueName === tableName
                ? weeklySort.isAscending
                : null
            }
            issueList={issueList}
            periodIndex={[periodIndex]}
            onClick={this.handleSort.bind(this)}
          />
        </div>
        <div className="submenu-item__filter">
          <div>
            <ButtonToolbar>
              <DropdownButton
                noCaret
                id="dropdown-no-caret"
                title={this.state.operator}
                variant="light"
                onClick={this.handleShowOperators.bind(this)}
                onDoubleClick={this.handleDeselectOperator.bind(this)}
              >
                {_.map(this.operators, (operator, i) => (
                  <Dropdown.Item
                    key={operator}
                    eventKey={i}
                    onClick={this.handleSelectOperator.bind(this, operator)}
                  >
                    <div className={this.state.operator === operator ? 'selected-operator' : ''}>
                      {operator}
                    </div>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </ButtonToolbar>
            <FormGroup controlId="formBasicText">
              <FormControl
                type="text"
                value={this.state.value}
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  filterValues: state.PRODUCTresourcePlan.filterValues,
  weeklySort: state.resourcePlan.weeklySort
})

const mapDispatchToProps = {
  weeklySortFn
}

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuItem)
