import { memo, useCallback, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Accordion } from 'react-bootstrap'
import SlvyAutosuggest, { SuggestionsContainer } from '@/components/SlvyAutosuggest'
import { ISearchCallbackParams } from '../../../Assignees.types'
import { bodyHeight, containerStyle, searchOption } from '../../../constants'
import {
  convertDataForSearch,
  getFilteredEventPlugins,
  getActiveEventsCount
} from '../../../helpers'
import { EventsContainer } from '../../Containers'
import NotFound from '../../NotFound'
import SelectedVariables from '../../SelectedVariables'
import { IRegister, IConfig, IPlugin, TcountInfo } from '../../../../Common.types'
import { IEventsSectionProps } from './EventsSection.types'
import { emptyObject } from '@/containers/EventMethod/constants'

const EventsSection = ({
  plugins,
  assignees,
  handleReturnVariableChange,
  selectedVariables,
  toggleActives,
  handleRemoveSelectedVariable
}: IEventsSectionProps) => {
  const [searchOptions, setSearchOptions] = useState({ ...searchOption })

  const handleSuggestionSelected = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: true
    })
  }, [])

  const handleSuggestionNotFound = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: true,
      isSelected: true
    })
  }, [])

  const handleQueryChange = useCallback(({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: false
    })
  }, [])

  const filteredPlugins = useMemo(() => {
    return getFilteredEventPlugins(
      assignees,
      plugins,
      searchOptions,
      selectedVariables,
      toggleActives
    ).filter((plugin) => _.size(plugin?.registers?.events))
  }, [assignees, toggleActives, plugins, searchOptions, selectedVariables])

  const eventSearchData = useMemo(() => convertDataForSearch(filteredPlugins), [filteredPlugins])

  const parentRef = useRef(null)

  const virtualizer = useVirtualizer({
    count: _.size(filteredPlugins),
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => {
      const filteredPlugin = filteredPlugins[index]
      const { id, registers: { events = emptyObject } = emptyObject as IRegister } = filteredPlugin
      const { [id]: eventsAssignees = emptyObject } = assignees
      const { active } = getActiveEventsCount(events, eventsAssignees)
      return active ? 500 : 100
    }
  })

  const isNotFound =
    (searchOptions.query && searchOptions.notFound) ||
    (selectedVariables.length && !filteredPlugins.length)

  const renderSuggestionsContainer = useCallback(
    (suggestionsContainerProps: any) => (
      <SuggestionsContainer {...suggestionsContainerProps} containerStyle={containerStyle} />
    ),
    []
  )

  const countInfos: TcountInfo[] = useMemo(() => {
    return filteredPlugins.map((filteredPlugin: IPlugin) => {
      const { id, registers: { events = emptyObject } = emptyObject as IRegister } = filteredPlugin
      const { [id]: eventsAssignees = emptyObject } = assignees
      const { active, total } = getActiveEventsCount(events, eventsAssignees)
      return { active, total, id }
    })
  }, [filteredPlugins, assignees])

  return (
    <>
      <div
        className="d-grid"
        data-testid="events-section"
        style={{
          height: bodyHeight,
          gridTemplateRows: selectedVariables.length
            ? 'min-content min-content 1fr'
            : 'min-content 1fr'
        }}
      >
        <SlvyAutosuggest
          data={eventSearchData}
          field="eventOptions"
          placeholder="Search"
          renderSuggestionsContainer={renderSuggestionsContainer}
          size="sm"
          onQueryChange={handleQueryChange}
          onSuggestionNotFound={handleSuggestionNotFound}
          onSuggestionSelected={handleSuggestionSelected}
        />
        <SelectedVariables
          handleRemove={handleRemoveSelectedVariable}
          variables={selectedVariables}
        />
        {isNotFound ? (
          <NotFound query={searchOptions.query} tags={selectedVariables} />
        ) : (
          <div ref={parentRef} className="w-100 overflow-y-auto h-100 pe-1">
            <Accordion
              alwaysOpen
              className="w-100 position-relative"
              defaultActiveKey={countInfos.filter((item) => item.active).map((item) => item.id)}
              style={{ height: virtualizer.getTotalSize() }}
            >
              {virtualizer.getVirtualItems().map((virtualRow) => {
                const filteredPlugin = filteredPlugins[virtualRow.index]
                const {
                  id,
                  registers: { events = emptyObject } = emptyObject as IRegister,
                  config: { general: { name = '' } = emptyObject } = emptyObject as IConfig,
                  type = ''
                } = filteredPlugin
                const { [id]: eventsAssignees = emptyObject } = assignees
                const countInfo = countInfos.find((item) => item.id === id)

                return (
                  <Accordion.Item
                    key={virtualRow.key}
                    ref={virtualizer.measureElement}
                    className="position-absolute top-0 left-0 w-100"
                    data-index={virtualRow.index}
                    eventKey={id}
                    style={{ transform: `translateY(${virtualRow.start}px)` }}
                  >
                    <EventsContainer
                      countInfo={countInfo}
                      events={events}
                      eventsAssignees={eventsAssignees}
                      handleReturnVariableChange={handleReturnVariableChange}
                      id={id}
                      name={name}
                      toggleActives={toggleActives}
                      type={type}
                    />
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        )}
      </div>
    </>
  )
}

export default memo(EventsSection)
