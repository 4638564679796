import { useCallback } from 'react'
import {
  GetContextMenuItemsParams,
  ExcelExportParams,
  MenuItemDef,
  IMenuActionParams
} from 'ag-grid-community'
import { generateExportFileName, getExportableColumnDefs } from '@/plugins/Ag-Grid/helpers'
import { CUSTOM_FIELDS } from '../../constants'
import { GridRef, IRowData, LocaleText, SlvyColGroupDef } from '../../types'

function useGetContextMenuItems(gridRef: GridRef, exportable: boolean, pluginName: string) {
  const { export: exportLocale } =
    (gridRef?.current?.api?.getGridOption?.('localeText') as LocaleText) || {}

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<IRowData>): (string | MenuItemDef)[] => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isSpecialColumn = CUSTOM_FIELDS.some(
        (customField) => customField === params?.column?.colId
      )
      if (isSpecialColumn) {
        return []
      }

      const newMenuItems: (string | MenuItemDef)[] =
        params?.defaultItems?.filter?.((item) => item !== 'export') ?? []
      if (exportable) {
        newMenuItems.push({
          name: exportLocale,
          action: (menuItemParams: IMenuActionParams) => {
            const columns = (menuItemParams?.api?.getColumnDefs?.() ?? []) as SlvyColGroupDef[]
            const columnKeys = getExportableColumnDefs(columns).map(({ field }) => field as string)

            const exportParams: ExcelExportParams = {
              columnKeys,
              fileName: generateExportFileName(pluginName, exportLocale)
            }
            menuItemParams?.api?.exportDataAsExcel(exportParams)
          }
        })
      }

      return newMenuItems ?? []
    },
    [exportLocale, exportable, pluginName]
  )

  return { getContextMenuItems }
}

export default useGetContextMenuItems
