import { ChangeEvent, FormEvent, useState } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { Button, Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { SlvySelect, SlvySelectStyles, slvyToast } from '@/components'
import { useUniqueId } from '@/hooks'
import { Toption } from '../../../Common.types'
import { makeCamelCase, prepareValue } from '../UpdateVariables'
import { IPageReadyProps, IForm } from './PageReady.types'
import { VARIABLE_TYPES } from '@/containers/EventMethod/constants'

const PageReady = ({ params: { defineVariables } = {}, onSave }: IPageReadyProps) => {
  const [uniqueId] = useUniqueId()
  const typeId = `typeId-${uniqueId}`
  const variableId = `variableId-${uniqueId}`
  const valueId = `valueId-${uniqueId}`
  const [form, setForm] = useState<IForm>({ type: '', variable: '', value: '' })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleAdd = () => {
    const { type, variable, value } = form

    const newValue = prepareValue(value, type)

    onSave({
      defineVariables: {
        ...defineVariables,
        [variable]: {
          value: newValue,
          type
        }
      }
    })

    setForm({ type: '', variable: '', value: '' })

    setIsSubmitted(false)

    slvyToast.success({ message: 'Added successfully!' })
  }

  const isValid = (key?: 'type' | 'variable' | 'value') => {
    if (!isSubmitted) {
      return []
    }

    if (typeof key !== 'undefined') {
      return [
        {
          isValid: form[key].trim().length > 0,
          key
        }
      ]
    }

    const typeValidation = { isValid: form.type.trim().length > 0, key: 'type' }
    const variableValidation = { isValid: form.variable.trim().length > 0, key: 'variable' }
    const valueValidation = { isValid: form.value.trim().length > 0, key: 'value' }
    return [typeValidation, variableValidation, valueValidation]
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const validationOpts = isValid()
    const invalidOptions = validationOpts.filter((item) => !item.isValid)
    if (invalidOptions.length) {
      invalidOptions.forEach((opt) =>
        slvyToast.error({ message: `${makeCamelCase(opt.key)} is required!` })
      )
      return
    }

    handleAdd()
  }

  const handleChange = (newValue: string, key: 'type' | 'variable' | 'value') => {
    setForm((prevState: IForm) => ({
      ...prevState,
      [key]: newValue
    }))
  }

  const handleDelete = (key: string) => {
    const newVariableValues = _.omit(defineVariables, [key])
    onSave({ defineVariables: newVariableValues })
  }

  const newVariables = [{ label: 'Select', value: '' }, ...VARIABLE_TYPES]

  const selectValue = newVariables.find((item) => item.value === form.type) ?? null
  const [isTypeValidation = null] = isValid('type')
  const [isVariableValidation = null] = isValid('variable')
  const [isValueValidation = null] = isValid('value')

  const isTypeInvalid = isTypeValidation && !isTypeValidation.isValid
  const isVariableInvalid = isVariableValidation && !isVariableValidation.isValid
  const isValueInvalid = isValueValidation && !isValueValidation.isValid

  return (
    <>
      <Form
        noValidate
        className="p-3"
        data-testid="page-ready-variables-job"
        onSubmit={handleSubmit}
      >
        <Row className="mb-2">
          <Col>
            <Form.Label className="fs-sm" htmlFor={`react-select-${typeId}-input`}>
              Type
            </Form.Label>
            <SlvySelect
              className={cx('form-slvy-select', { 'is-invalid': isTypeInvalid })}
              classNamePrefix="slvy-select"
              instanceId={typeId}
              options={newVariables}
              placeholder="select"
              styles={SlvySelectStyles.small}
              value={selectValue}
              onChange={(option: Toption) => handleChange(option?.value ?? null, 'type')}
            />
          </Col>
          <Col>
            <Form.Label className="fs-sm" htmlFor={variableId}>
              Variable
            </Form.Label>
            <Form.Control
              className={{ 'is-invalid': isVariableInvalid }}
              id={variableId}
              size="sm"
              value={form.variable}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.value, 'variable')
              }
            />
          </Col>
          <Col>
            <Form.Label className="fs-sm" htmlFor={valueId}>
              Value
            </Form.Label>
            <Form.Control
              className={{ 'is-invalid': isValueInvalid }}
              id={valueId}
              size="sm"
              value={form.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.value, 'value')
              }
            />
          </Col>
          <Col sm="auto">
            <div className="d-flex align-items-end h-100">
              <Button
                data-testid="update-variables-submit-btn"
                size="sm"
                type="submit"
                variant="success"
                onClick={() => setIsSubmitted(true)}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="table-responsive">
        <table className="table table-striped border-top align-middle fs-sm">
          <thead>
            <tr>
              <th>Type</th>
              <th className="text-center">Variable</th>
              <th className="text-center">Value</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody data-testid="update-variables-list">
            {_.map(defineVariables, (val, variable) => {
              return (
                <tr key={variable}>
                  <th>{val.type}</th>
                  <th className="text-center">{variable}</th>
                  <td className="text-center">
                    {_.isArray(val.value) ? _.join(val.value, ',') : String(val.value)}
                  </td>
                  <td className="text-center">
                    <OverlayTrigger
                      rootClose
                      overlay={
                        <Popover id="removeVariable">
                          <Popover.Header as="h6" className="mb-0">
                            Remove Variable
                          </Popover.Header>
                          <Popover.Body className="m-0 py-2">
                            Are you sure you want to remove <strong>{variable}</strong>?
                            <div className="d-flex gap-2 mt-2 justify-content-end">
                              <Button
                                data-testid={`remove-variable-${variable}`}
                                size="sm"
                                variant="danger"
                                onClick={() => handleDelete(variable)}
                              >
                                Remove
                              </Button>
                              <Button
                                size="sm"
                                variant="outline-dark"
                                onClick={() => document.body.click()}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                      placement="top"
                      trigger="click"
                    >
                      <Button
                        className="text-nowrap"
                        size="xs"
                        title="Remove Variable"
                        variant="outline-danger"
                      >
                        <i className="fa fa-trash-o" />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default PageReady
