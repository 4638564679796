Ext.define('MDM.src.view.Grouping.Edit.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.grouping-edit-mainviewmodel',
  stores: {
    DPITreeStore: {
      type: 'tree',
      model: 'MDM.src.view.Grouping.Edit.Model.DPITree',
      autoLoad: true,
      root: {
        expanded: true,
        text: 'DPI Hierarcy',
        iconCls: 'fa fa-sitemap',
        children: []
      }
    },
    GroupDetailsStore: {
      model: 'MDM.src.view.Grouping.Edit.Model.GroupDetails',
      autoLoad: false,
      autoSync: false,
      groupField: 'HierarchyText',
      bindTo: 'GroupDetailsCount',
      listeners: {}
    }
  },
  formulas: {
    GroupDescription: {
      bind: {
        bindTo: '{grpRecord}',
        deep: true
      },
      get: function (rec) {
        if (rec) return rec.get('Descriptions')
        return ''
      }
    },
    isStoreDirty: {
      bind: {
        bindTo: '{GroupDetailsCount}',
        deep: true
      },
      get: function (d) {
        if (d === 0) return false
        if (this.getStore('GroupDetailsStore').loadCount == 0) return false
        if (this.getStore('GroupDetailsStore') && this.getStore('GroupDetailsStore').isDirty) {
          return this.getStore('GroupDetailsStore').isDirty()
        }
        return false
      }
    },
    TotalRecCount: {
      bind: {
        bindTo: '{GroupDetailsCount}',
        deep: true
      },
      get: function () {
        var c = this.getStore('GroupDetailsStore').count()
        return c < 1 ? c + ' item' : c + ' items'
      }
    }
  },
  data: {
    grpRecord: null,
    GroupDetailsCount: 0
  }
})
