import { configureStore } from '@reduxjs/toolkit'
import api from './api'
import {
  createLoadModalSlice,
  demandTreeTableSlice,
  headerSlice,
  loadStopTableSlice,
  loadTableSlice,
  loadTreeTableSlice,
  mapSlice,
  modalSlice,
  reasonCode,
  serviceModalSlice,
  sessionSlice,
  sidebarSlice,
  treeSlice,
  tuiSlice,
  viewContainerSlice
} from './slices'

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    createLoadModal: createLoadModalSlice.reducer,
    demandTreeTable: demandTreeTableSlice.reducer,
    headerSlice: headerSlice.reducer,
    loadStopTable: loadStopTableSlice.reducer,
    loadTable: loadTableSlice.reducer,
    loadTreeTable: loadTreeTableSlice.reducer,
    map: mapSlice.reducer,
    modal: modalSlice.reducer,
    reason: reasonCode.reducer,
    serviceModal: serviceModalSlice.reducer,
    session: sessionSlice.reducer,
    sidebar: sidebarSlice.reducer,
    tree: treeSlice.reducer,
    tui: tuiSlice.reducer,
    viewContainer: viewContainerSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})

export default store
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
