import { SlvySpinner, SlvyTable } from '@/components'
import BaseModal from '../BaseModal'
import { getAllActionsDiff, getServiceStatus } from '../../store/api/endpoints'
import { useReduxState } from '../../hooks'
import { tuiSlice } from '../../store/slices'
import { ViewAllDifferenceModalProps } from './ViewAllDifferenceModal.types'
import { DifferenceSummary, ServicePlanInfo } from '../../store/api/endpoints/endpoint.types'
import { columns, valueFormat } from '../../constants/differenceModal'
import { calculateDifferences } from '../../utils'

const ViewAllDifferenceModal = ({ isOpen, onClose }: ViewAllDifferenceModalProps) => {
  const [{ config }] = useReduxState(tuiSlice.selectSlice)

  const allSummary: DifferenceSummary[] =
    getAllActionsDiff.useQueryState()?.currentData?.result?.AllSummary ?? []
  const planInfo: ServicePlanInfo =
    getServiceStatus.useQueryState()?.currentData?.PlanInfo ?? ({} as ServicePlanInfo)

  const allDifferences = calculateDifferences(allSummary, planInfo, config, valueFormat)

  return (
    <BaseModal isOpen={isOpen} size="lg" onClose={onClose}>
      <BaseModal.Header>
        <BaseModal.Title>Difference</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body>
        {allDifferences.length ? (
          <SlvyTable suppressColumnMenu className="fs-sm" columns={columns} data={allDifferences} />
        ) : (
          <SlvySpinner size="sm" />
        )}
      </BaseModal.Body>
    </BaseModal>
  )
}

export default ViewAllDifferenceModal
