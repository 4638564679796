import { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'
import _ from 'lodash'

import { SlvySelect } from '../../../../components'
import { PermTypes } from '@/helpers/Permission'

class CustomOption extends Component {
  constructor() {
    super()

    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleCreate() {
    const {
      data: { value },
      selectProps: { onCreate }
    } = this.props

    onCreate(value)
  }

  handleDelete(event) {
    event.stopPropagation()
    const {
      data: { id },
      selectProps: { onDelete }
    } = this.props

    confirmAlert({
      title: 'Deleting Template?',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Cancel'
        },
        {
          label: 'Confirm Delete',
          onClick: () => {
            onDelete(id)
          }
        }
      ]
    })
  }

  render() {
    const {
      data: { __isNew__ },
      getStyles,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
      children,
      selectProps: { checkPermission }
    } = this.props

    return (
      <div
        {...innerProps}
        ref={innerRef}
        className={`
          option
          creatable-option
          ${isDisabled ? 'option--is-disabled' : ''}
          ${isFocused ? 'option--is-focused' : ''}
          ${isSelected ? 'option--is-selected' : ''}}
        `}
        style={getStyles('option', this.props)}
      >
        {children}
        {__isNew__ && <Button onClick={this.handleCreate}>Add</Button>}
        {checkPermission.hasPermType(PermTypes.PageTemplateDelete).isEnv('Configuration').value &&
          !__isNew__ && (
            <i
              className="delete-template fa-fw fa slvy-ui-icon-trash-can"
              onClick={this.handleDelete}
            />
          )}
      </div>
    )
  }
}

class CreatableSelect extends Component {
  constructor() {
    super()
    this.state = {
      inputValue: '',
      value: null,
      options: []
    }

    this.handleCreate = this.handleCreate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      templateProps: { options }
    } = this.props
    const {
      templateProps: { options: nextOptions }
    } = nextProps

    if (!_.isEqual(options, nextOptions)) {
      this.setState({ options: nextOptions })
    }
  }

  handleCreate(value) {
    const {
      templateProps: { onCreate }
    } = this.props

    onCreate(value)
  }

  handleChange(value) {
    const {
      templateProps: { onChange }
    } = this.props

    if (value.__isNew__) return

    this.setState({ value })
    onChange(value.id)
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue })
  }

  handleDelete(id) {
    const {
      templateProps: { onDelete }
    } = this.props
    let { options, value } = this.state

    options = options.filter((option) => option.id !== id)
    const newOrSame = !_.isEmpty(value) && (value.__isNew__ || value.id === id)
    value = newOrSame ? null : value

    this.setState({ options, value }, () => {
      onDelete(id)
    })
  }

  render() {
    const {
      templateProps: { checkPermission }
    } = this.props
    const { inputValue, value, options } = this.state

    return (
      <SlvySelect
        isCreatable
        checkPermission={checkPermission}
        components={{ Option: CustomOption }}
        formatCreateLabel={(str) => str}
        inputValue={inputValue}
        // TODO: After SlvySelect is complete solve z-index
        // issues in its index.scss file
        menuPlacement="auto"
        options={options}
        placeholder={this.props.placeholder}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 3 }),
          menuList: (provided) => ({ ...provided, maxHeight: '220px' })
        }}
        value={value}
        onChange={this.handleChange}
        onCreate={this.handleCreate}
        onDelete={this.handleDelete}
        onInputChange={this.handleInputChange}
      />
    )
  }
}

export default CreatableSelect
