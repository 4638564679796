import { aggregateDate } from '../agg'
import { dateMaxParams, dateMinParams, dateMaxEmptyValueParams, zeroValueParams } from './mocks'

describe('AgGrid:Helpers:Agg:aggregateDate', () => {
  it('should return the maximum date when aggFunc is "date max"', () => {
    const result = aggregateDate(dateMaxParams)
    expect(result).toBe('2022-03-15 03:00:00')
  })

  it('should return the minimum date when aggFunc is "date min"', () => {
    const result = aggregateDate(dateMinParams)
    expect(result).toBe('2022-01-01 03:00:00')
  })

  it('should handle empty values and return null', () => {
    const result = aggregateDate(dateMaxEmptyValueParams)
    expect(result).toBeNull()
  })

  it('should filter zero values from the array when calculating min/max date', () => {
    const result = aggregateDate(zeroValueParams)
    expect(result).toBe('2022-04-20 03:00:00')
  })
})
