Ext.define('tuiv2.tuiv2MainControllerAjax', {
  extend: 'Ext.app.ViewController',

  // actions
  onRemoveLoad: function (reasonCode, loadId, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      timeout: 600000,
      method: 'POST',
      url: tuiv2.Global.getApiUrl() + 'tuiv2/RemoveLoad',
      params: {
        loadId: loadId,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })
        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)

        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRemoveLoads: function (reasonCode, loadNames, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      timeout: 600000,
      method: 'POST',
      url: tuiv2.Global.getApiUrl() + 'tuiv2/RemoveLoads',
      params: {
        loadNames: JSON.stringify(loadNames),
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })
        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)

        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onChangeTransportationMode: function (reasonCode, loadId, name, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/ChangeTransportationMode',
      timeout: 600000,
      method: 'POST',
      params: {
        Id: loadId,
        transportationModeName: name,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onMergeLoads: function (reasonCode, loadId1, loadId2, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/MergeLoads',
      timeout: 600000,
      method: 'POST',
      params: {
        loadId1: loadId1,
        loadId2: loadId2,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRemoveDemandZoneFromAllLoads: function (reasonCode, facilityKeys, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/RemoveDemandZoneFromAllLoads',
      timeout: 600000,
      method: 'POST',
      params: {
        facilityKeys: JSON.stringify(facilityKeys),
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  RemoveLoadStops: function (reasonCode, loadStopIndex, loadId, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/RemoveLoadStops',
      timeout: 600000,
      method: 'POST',
      params: {
        loadStopIndex: JSON.stringify(loadStopIndex),
        loadId: loadId,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onSwitchDemandZoneToLoad: function (reasonCode, facilityKey, targetLoadId, sourceLoadId, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/SwitchDemandZoneToLoad',
      timeout: 600000,
      method: 'POST',
      params: {
        facilityKeys: JSON.stringify(facilityKey),
        targetLoadId: targetLoadId,
        SourceLoadId: sourceLoadId,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onLoadToLTL: function (reasonCode, facilityKeys, loadId, serviceContractId, palletCount, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    var convertToLtlRequest = []
    facilityKeys.forEach((key) => {
      convertToLtlRequest.push({ FacilityKey: key, ServiceContractIds: serviceContractId })
    })

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/ConvertToLTL',
      timeout: 600000,
      method: 'POST',
      params: {
        convertToLtlRequest: JSON.stringify(convertToLtlRequest),
        loadId: loadId,
        palletCount: palletCount,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onCreateNewLoad: function (
    facilityKeys,
    loadId,
    serviceContractIds,
    originLocation,
    transportationModeName,
    reasonCode,
    func
  ) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    var createNewLoadRequest = []
    if (Array.isArray(facilityKeys)) {
      facilityKeys.forEach((facilityKey) => {
        createNewLoadRequest.push({
          FacilityKey: facilityKey,
          ServiceContractId: serviceContractIds,
          ItemGroup: '',
          SourceLoadName: loadId,
          Quantity: 0.0
        })
      })
    } else {
      serviceContractIds.forEach((id) => {
        createNewLoadRequest.push({
          FacilityKey: facilityKeys,
          ServiceContractId: id,
          ItemGroup: '',
          SourceLoadName: loadId[id],
          Quantity: 0.0
        })
      })
    }

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/CreateNewLoad',
      timeout: 600000,
      method: 'POST',
      params: {
        createNewLoadRequest: JSON.stringify(createNewLoadRequest),
        originLocation: originLocation,
        transportationModeName: transportationModeName,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var result = Ext.decode(response.responseText)
        if (!result.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + result.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(result, '\n')
          })
        } else {
          if (func) {
            func(result.data)
          }
        }
        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onSwitchServiceContractToLoad: function (
    reasonCode,
    facilityKey,
    serviceContractId,
    loadId,
    func
  ) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/SwitchServiceContractToLoad',
      timeout: 600000,
      method: 'POST',
      params: {
        Id: loadId,
        serviceContractIds: JSON.stringify(serviceContractId),
        facilityKey: facilityKey,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRemoveServiceContractFromAllLoads: function (reasonCode, serviceContractId, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/RemoveServiceContractFromAllLoads',
      timeout: 600000,
      method: 'POST',
      params: {
        serviceContractIds: JSON.stringify(serviceContractId),
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRemovePalletsFromLoad: function (
    reasonCode,
    facilityKey,
    serviceContractId,
    loadId,
    palletCount,
    func
  ) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/RemovePalletsFromLoad',
      timeout: 600000,
      method: 'POST',
      params: {
        facilityKey: facilityKey,
        serviceContractId: serviceContractId,
        palletCount: palletCount,
        loadId: loadId,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onServePalletOnLoad: function (
    reasonCode,
    facilityKey,
    serviceContractId,
    palletCount,
    loadId,
    fromLoadId,
    func
  ) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/ServePalletOnLoad',
      timeout: 600000,
      method: 'POST',
      params: {
        facilityKey: facilityKey,
        serviceContractId: serviceContractId,
        palletCount: palletCount,
        loadId: loadId,
        fromLoadId: fromLoadId,
        reasonCodeId: reasonCode
      },
      success: function (response) {
        var data = Ext.decode(response.responseText)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        var planInfo = viewModel.get('PlanInfo')
        planInfo.CurrentState = data.result.CurrentState

        viewModel.set({
          isOperationInProgress: false,
          PlanInfo: planInfo
        })

        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  // methods

  onGetAvailableTransportationModes: function (loadId, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      timeout: 600000,
      method: 'POST',
      url: tuiv2.Global.getApiUrl() + 'tuiv2/GetAvailableTransportationModes',
      params: {
        id: loadId
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },
      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRetrieveLoadPopupData: function (loadId, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/GetLoadDetailById',
      timeout: 600000,
      method: 'POST',
      params: {
        Id: loadId
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRetrieveOrderPopupData: function (id, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/GetOrderDetails',
      timeout: 600000,
      method: 'POST',
      params: {
        Id: id
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRetrieveDemandPopupData: function (key, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/GetDemandDetailById',
      timeout: 600000,
      method: 'POST',
      params: {
        Id: key
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onRetrieveSelectedAreaDetails: function (coordinates, func) {
    var me = this,
      viewModel = me.getViewModel()

    viewModel.set('isOperationInProgress', true)
    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/GetSelectedAreaDetails',
      method: 'POST',
      timeout: 600000,
      params: {
        Coordinates: JSON.stringify(coordinates)
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data.result)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  setServiceWithConnectionId: function (data, func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/SetServiceWithConnectionId',
      method: 'POST',
      timeout: 600000,
      params: {
        DataConnectionId: data.DataConnectionId
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data)
          }
        }
        viewModel.set('isServiceSelected', true)
        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  getServiceList: function (data, func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/SetService',
      method: 'POST',
      timeout: 600000,
      params: {
        ServiceAddress:
          'http://' +
          data.ServiceAddress +
          ':' +
          data.ServiceHttpPort +
          '/LoadPlannerServiceMeta?wsdl',
        RunParameter: data.RunID
      },
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data)
          }
        }
        viewModel.set('isServiceSelected', true)
        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  getAllDiff: function (func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/GetAllDiff',
      method: 'POST',
      timeout: 600000,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },
  getActionResult: function (actionId, func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/getActionResult',
      method: 'POST',
      params: {
        actionId: actionId
      },
      timeout: 600000,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  getActionDiff: function (func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/getActionDiff',
      method: 'POST',
      timeout: 600000,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onSetSessionStart: function (sessionName, reasonCode, func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/SetSessionStart',
      method: 'POST',
      params: {
        description: sessionName,
        reasonCode: reasonCode
      },
      timeout: 600000,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          Ext.toast({
            html: 'New session is started.',
            title: 'Information',
            width: 300,
            align: 'tr',
            iconCls: 'fa fa-check',
            closable: true,
            timeout: 4000
          })

          if (func) {
            func(data)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  onSetSessionEnd: function (reasonCode, func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/SetSessionEnd',
      method: 'POST',
      params: {
        reasonCode: reasonCode
      },
      timeout: 600000,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  },

  getSnapshotDiff: function (id, func) {
    var me = this,
      viewModel = me.getViewModel()

    Ext.Ajax.request({
      url: tuiv2.Global.getApiUrl() + 'tuiv2/GetSnapshotDiff',
      method: 'POST',
      params: {
        id: id
      },
      timeout: 600000,
      success: function (response) {
        var data = Ext.decode(response.responseText, true)

        if (!data.success) {
          Ext.MessageBox.show({
            title: 'Action failed',
            message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
            buttons: Ext.Msg.OK,
            width: 600,
            icon: Ext.Msg.INFO,
            details: Ext.JSON.encodeValue(data, '\n')
          })
        } else {
          if (func) {
            func(data)
          }
        }

        viewModel.set('isOperationInProgress', false)
        return
      },

      failure: function (response) {
        var data = Ext.decode(response.responseText, true)
        Ext.MessageBox.show({
          title: 'Action Failed',
          message: ' Has some issues:<br><br><b> ' + data.message + '</b>',
          buttons: Ext.Msg.OK,
          width: 600,
          icon: Ext.Msg.INFO,
          details: Ext.JSON.encodeValue(data, '\n')
        })

        viewModel.set('isOperationInProgress', false)
      }
    })
  }
})
