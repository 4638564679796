export default `({
    layout: 'fit',
    scrollable: true,
    floatable: true,
    items: [{
        xtype: 'grid',
        scrollable: true,
        columns: [{
                menuDisabled: true,
                sortable: false,
                xtype: 'actioncolumn',
                resizable: false,
                width: 30,
                align: 'center',
                items: [{
                    iconCls: 'x-fa   fa-clone',
                    tooltip: tt('Kopyala'),
                    // isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
                    //     return !rec.get('IsEditable');
                    // },
                    handler: function (grid, rowIndex, colIndex, item, e, record) {
                                               c = this.up('window').getUxItem().getController()
                        c.onCloneProduct(grid, rowIndex, colIndex, item, e, record)
                        this.up('menu').hide()
                    }
                }]
            },
            {
                text: tt('MaterialCode'),
                dataIndex: 'MaterialCode',
                width: 100
            },
            {
                text: tt('MaterialDescription'),
                dataIndex: 'MaterialDescription',
                width: 200
            },
            {
                text: tt('H1N'),
                dataIndex: 'H1N',
                width: 200
            },
            {
                text: tt('H2N'),
                dataIndex: 'H2N',
                width: 200
            },
            {
                text: tt('H3N'),
                dataIndex: 'H3N',
                width: 200
            }
        ],
        height: 600,
        store: 'ProductStore',
        tbar: [{
            width: 400,
            fieldLabel: 'Search',
            labelWidth: 50,
            xtype: 'searchfield',
            store: 'ProductStore'
        }]
    }],
})
`
