export default `({
    plugins: [{
        ptype: 'rowexpander',
        rowBodyTpl: [
            '<div class="expander">',
            '<table class="table">',
            '<tr>',
            '</tr>',
            '  <tr>',
            '       <th class=r>'+tt('Merkez')+'</th>',
            '      <td class="r">{OrderRecommendationParent}</td>',
            '       <th class="r">'+tt('Bölge')+'</th>',
            '      <td class="r">{OrderRecommendationCurrent}</td>',
            '      <th class="r">'+tt('Satış Md.')+'</th>',
            '      <td class="r">{OrderRecommendationChild}</td>',
            '   </tr>',
            '</tr>',
            '</table>',
            '</div>',
        ]
    },
    {
        ptype: 'gridfilters',
        id: 'gridfilters'
    }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    columnLines: true,
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        me = this;
        var msg = tt('Are you sure you want to dismiss this record? <br><br>');
        if (newStatus === 'Approve') {
            msg = tt('Are you sure you want to approve this record? <br><br>');
        }
        Ext.Msg.show({
            title: tt('Onay'),
            //height: 160,
            width: 500,
            msg: msg,
            icon: Ext.MessageBox.QUESTION,
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            buttons: Ext.Msg.YESNO,
            fn: function (response) {
                if (response === 'yes') {
                    me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));
                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                        method: 'POST',
                        timeout: 60000,
                        params: {
                            data: data,
                            newStatus: newStatus
                        },
                        success: function (response, opts) {
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                        },
                        failure: function (response, opts) {
                            console.log('server-side failure with status code ' + response.status);
                            me.up('spui-view-tracking-tracking').setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                        }
                    });
                }
            }
        });
    },
    multiColumnSort: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [{
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-thumbs-up blue',
                tooltip: tt('Accept'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeStatus(record, 'Approve')
                }
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa  fa-thumbs-down red',
                tooltip: tt('Reject'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeStatus(record, 'Dismiss')
                },
            }]
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('Start Date'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('End Date'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('RemainingDaysForApprove'),
            dataIndex: 'RemainingDaysForApprove',
            width: 100,
            format: '000',
            align: 'right',
            filter: {
                type: 'number'
            },
            renderer: function (value, metaData, record) {
                var DaysForApprove = record.get('DaysForApprove');
                return value + " / " + DaysForApprove
            }
        },
        {
            text: tt('Progress'),
            xtype: 'widgetcolumn',
            dataIndex: 'CompletionRatio',
            width: 120,
            filter: {
                type: 'number'
            },
            sortable:true,
            widget: {
                bind: '{record.CompletionRatio}',
                xtype: 'progressbarwidget',
                textTpl: [
                    '{percent:number("0")}%'
                ]
            }
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('Status'),
            columns: [{
                    text: tt('Total'),
                    tooltip: tt('TotalTip'),
                    dataIndex: 'NumberOfRecommendedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    filter: {
                        type: 'number'
                    },
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('Waiting'),
                    tooltip: tt('WaitingTip'),
                    dataIndex: 'NumberOfWaitingOrg',
                    filter: {
                        type: 'number'
                    },
                    width: 80,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
                {
                    text: tt('Delegated'),
                    dataIndex: 'NumberOfDelegatedOrg',
                    filter: {
                        type: 'number'
                    },
                    width: 80,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
                {
                    text: tt('Approved'),
                    filter: {
                        type: 'number'
                    },
                    dataIndex: 'NumberOfApprovedOrg',
                    width: 80,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
                {
                    text: tt('Dismissed'),
                    dataIndex: 'NumberOfDismissedOrg',
                    filter: {
                        type: 'number'
                    },
                    width: 80,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
            ]
        },
    ],
})
`
