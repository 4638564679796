export default {
  title: 'Task Tracking',
  subTitle: 'Solvoyo',
  version: '2.0',
  namespace: 'TaskTracking',
  description: 'Task Tracking application, with kanban board',
  iconClass: 'fa-align-center',
  color: 'lazur-bg',
  group: 'Application',
  support: {
    maximizable: true
  }
}
