import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { uniq } from 'lodash'
import { SlvyTable } from '@/components'
import { useEvent } from '@/hooks'
import { useReduxState } from '../../hooks'
import { getAvailableTransportationModes, getLoadTree, setRefix } from '../../store/api/endpoints'
import { tuiSlice, loadTreeTableSlice, viewContainerSlice } from '../../store/slices'
import {
  getEnabledColumns,
  getTreeSubRows,
  getTreeQueryParams,
  collectNodes,
  getDistinctColors,
  formatNumber
} from '../../utils'
import CardControlsCell from './CardControlsCell'
import LoadApprovalCell from './LoadApprovalCell'
import LoadApprovalModal from './LoadApprovalModal'
import IconButton from '../IconButton'
import PrimaryButton from '../PrimaryButton'
import { LoadTreeTableProps } from './LoadTreeTableProps'
import FooterButtons from '../FooterButtons'
import useLoadRowSelected from '../../hooks/useRegisterEvents/useLoadRowSelected'
import useClickLoadEdit from '../../hooks/useRegisterEvents/useClickLoadEdit/useClickLoadEdit'
import useLoadStopsSelected from '../../hooks/useRegisterEvents/useLoadStopsSelected/useLoadStopsSelected'
import RemoveLoadStopModal from './RemoveLoadStopModal/RemoveLoadStopModal'
import RemoveLoadModal from './RemoveLoadModal/RemoveLoadModal'
import { Hierarchy } from '../SettingsModal/SettingsModal.types'
import styles from './LoadTreeTable.module.scss'
import ChangeTransportationModal from './ChangeTransportationModal/ChangeTransportationModal'

export default function LoadTreeTable({ tableRef }: LoadTreeTableProps) {
  const [selectedLoadRow, setSelectedLoadRow] = useState(null)
  const [selectedLoadStopRow, setSelectedLoadStopRow] = useState(null)
  const [changeTransportationLoadName, setChangeTransportationLoadName] = useState(null)
  const [availableTransportationModeName, setAvailableTransportationModeName] = useState(null)

  const [{ auth, config, isAllowed }] = useReduxState(tuiSlice.selectSlice)
  const [loadTreeTable, loadTreeDispatch] = useReduxState(
    loadTreeTableSlice.selectSlice,
    loadTreeTableSlice.actions
  )
  const [viewContainer] = useReduxState(viewContainerSlice.selectSlice)
  const [triggerGetLoadTree, loadTreeResponse] = getLoadTree.useMutation({
    fixedCacheKey: 'load-tree'
  })
  const [triggerGetAvailableTransportationModes, availableTransportationModesResponse] =
    getAvailableTransportationModes.useMutation({ fixedCacheKey: 'available-transportation-modes' })

  const [triggerSetRefix] = setRefix.useLazyQuery()
  const { loadRowRef } = useLoadRowSelected({ loadTreeTableRef: tableRef })
  const { editButtonRef } = useClickLoadEdit()
  const { loadStopRef } = useLoadStopsSelected({ loadTreeTableRef: tableRef })
  const data = [{ Text: 'Loads', ID: 'root', ...loadTreeResponse.data }]
  const { LoadCount, LoadStopCount } = data.at(0)
  const initialState = { expanded: { root: true } }
  const disabledPolishizeButton =
    !isAllowed('canRefix') || loadTreeTable.disabledFooterButtons.Polishize

  function handleRemoveLoad(row) {
    setSelectedLoadRow(row)
    loadTreeDispatch.setIsRemoveLoadModalOpen(true)
  }

  function handleRemoveLoadStop(row) {
    setSelectedLoadStopRow(row)
    loadTreeDispatch.setIsRemoveLoadStopModalOpen(true)
  }

  function handleChangeTransportationPopover(loadName) {
    const numericLoadName = Number(loadName)
    setChangeTransportationLoadName(numericLoadName)
    triggerGetAvailableTransportationModes({ id: numericLoadName })
  }

  function handleChangeTransportationModal(Name) {
    setAvailableTransportationModeName(Name)
    loadTreeDispatch.setIsChangeTransportationModalOpen(true)
  }

  const popover = useEvent(() => {
    const result = availableTransportationModesResponse?.data?.result ?? []

    const popoverBody = !availableTransportationModesResponse?.data ? (
      <p>Loading...</p>
    ) : (
      result.map(({ Name, SubTitle, Utilization, Cost }, index) => (
        <div key={index}>
          <Row className="cp" onClick={() => handleChangeTransportationModal(Name)}>
            <Col sm="6">
              <button className={styles.popoverButton} type="button">
                {Name}
              </button>
              <p className="fs-xs">{SubTitle}</p>
            </Col>
            <Col sm="6">
              <p className="fs-xs">{formatNumber(Utilization * 100)}% Util</p>
              <p className="fs-xs">{formatNumber(Cost)} Cost</p>
            </Col>
          </Row>
          {index !== result.length - 1 && <hr className="m-1" />}
        </div>
      ))
    )

    return (
      <Popover className={styles.transportationModePopup}>
        <Popover.Body className={styles.transportationModePopupBody}>{popoverBody}</Popover.Body>
      </Popover>
    )
  })

  const dynamicColumns = useMemo(
    () =>
      viewContainer.currentView === 'Tree Table'
        ? [
            {
              id: 'transportationControls',
              cell: (params) => (
                <div className={styles.transportationControls}>
                  {params.row.original.Text === params.row.original.LoadName && (
                    <>
                      <IconButton
                        tooltipText="Edit"
                        onClick={() =>
                          editButtonRef.current?.handleEditButtonClick(params.row.original.LoadName)
                        }
                      >
                        <i className="slvy-ui-icon-edit fs-6" />
                      </IconButton>
                      <OverlayTrigger
                        rootClose
                        overlay={popover()}
                        placement="bottom"
                        trigger="click"
                      >
                        <IconButton
                          tooltipText="Change Transportation Mode"
                          onClick={() =>
                            handleChangeTransportationPopover(params.row.original.LoadName)
                          }
                        >
                          <i className="slvy-ui-icon-scenario-change-extra-lt fs-6" />
                        </IconButton>
                      </OverlayTrigger>
                    </>
                  )}
                  {!params.row.original.Leaf ? (
                    <IconButton
                      tooltipText="Remove Load"
                      onClick={() => handleRemoveLoad(params.row)}
                    >
                      <i className="slvy-ui-icon-scenario-delete-extra-lt fs-6" />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        tooltipText="Remove Load Stop"
                        onClick={() => handleRemoveLoadStop(params.row)}
                      >
                        <i className="slvy-ui-icon-scenario-delete-extra-lt fs-6" />
                      </IconButton>
                    </>
                  )}
                </div>
              ),
              maxSize: 70,
              meta: {
                style: {
                  display: 'flex',
                  justifyContent: 'end'
                }
              }
            }
          ]
        : [
            {
              id: 'cardControls',
              cell: (cell) => <CardControlsCell {...cell} />,
              maxSize: 60
            }
          ],
    [triggerGetAvailableTransportationModes]
  )

  const columns = useMemo(
    () => [
      ...dynamicColumns,
      {
        id: 'loadApproval',
        cell: (cell) => <LoadApprovalCell {...cell} />,
        maxSize: 37
      },
      {
        id: 'Name',
        header: 'Name',
        accessorKey: 'Text',
        minSize: 300
      },
      ...getEnabledColumns(config.loadTree.columns)
    ],
    [config.loadTree.columns, dynamicColumns]
  )

  useEffect(() => {
    if (config.settings.isServicePersistent) {
      triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
    }
  }, [])

  useEffect(() => {
    const { hierarchy } = getTreeQueryParams(config, 'loadTree', auth.pluginId)

    const distinctColorIndex = hierarchy.findLastIndex(
      (currentHierarchy: Hierarchy) => currentHierarchy.isTreeDistinctColorField
    )
    const depth = distinctColorIndex < 0 ? 0 : distinctColorIndex + 1
    const nodesByDepth = collectNodes(
      loadTreeResponse.data,
      (_node, context) => context.cursor.depth === depth
    )

    const loadNameNodesParents = nodesByDepth.map((currentNode) =>
      collectNodes(currentNode, (node) => node?.Children?.[0]?.Leaf)
    )

    const rowColors = loadNameNodesParents.reduce<Record<string, string>>(
      (acc, loadNameNodesParent) => {
        const distinctColors = getDistinctColors(loadNameNodesParent.length)
        loadNameNodesParent.forEach(({ ID, LoadName }, index) => {
          acc[ID] = distinctColors[index]
          acc[LoadName] = distinctColors[index]
        })
        return acc
      },
      {}
    )

    loadTreeDispatch.setRowColors(rowColors)
  }, [auth.pluginId, config, loadTreeResponse.data])

  function handleSelectParentRow(row, isSelected, selectedRows) {
    const parentRow = row.getParentRow()
    if (!parentRow) {
      return selectedRows
    }

    // When all child rows are selected, the parent row is not selected.
    // The code below selects the parent row.
    const isAllSubRowsSelected = parentRow.getIsAllSubRowsSelected()
    if (isSelected && isAllSubRowsSelected) {
      const updatedSelectedRows = selectedRows.concat(parentRow.original)
      loadTreeDispatch.setSelectedRows(updatedSelectedRows)
      return updatedSelectedRows
    }

    // When a child row is unselected, the code below unselects both the child row and the parent row.
    // if (!isSelected && !isAllSubRowsSelected) {
    //   const updatedSelectedRows = selectedRows.filter(
    //     (selectedRow) =>
    //       selectedRow.ID !== parentRow.original.ID && selectedRow.Text !== row.original.Text
    //   )
    //   loadTreeDispatch.setSelectedRows(updatedSelectedRows)
    //   return updatedSelectedRows
    // }

    return selectedRows
  }

  function handleSelectRow(row, event: ChangeEvent<HTMLInputElement>) {
    const { original } = row
    const isSelected = event.target.checked

    const selectedRowAndChildRows = collectNodes(original, (node) => node)

    const { currentSelectedRows, currentLoadStops, currentLoadIndexes } =
      selectedRowAndChildRows.reduce<{
        currentSelectedRows: Record<string, unknown>[]
        currentLoadStops: string[]
        currentLoadIndexes: number[]
      }>(
        (accumulator, node) => {
          if (isSelected) {
            return {
              currentSelectedRows: accumulator.currentSelectedRows.concat(node),
              currentLoadStops: accumulator.currentLoadStops.concat(node.LoadName),
              currentLoadIndexes: accumulator.currentLoadIndexes.concat(node.LoadIndex)
            }
          }

          return {
            currentSelectedRows: accumulator.currentSelectedRows.filter(
              (currentSelectedNode) =>
                !selectedRowAndChildRows.some(
                  (selectedNode) =>
                    selectedNode.Text === currentSelectedNode.Text &&
                    selectedNode.ID === currentSelectedNode.ID
                )
            ),
            currentLoadStops: accumulator.currentLoadStops.filter(
              (currentLoadStop) => currentLoadStop !== node.LoadName
            ),
            currentLoadIndexes: accumulator.currentLoadIndexes.filter(
              (currentLoadIndex) => currentLoadIndex !== node.LoadIndex
            )
          }
        },
        {
          currentSelectedRows: loadTreeTable.selectedRows,
          currentLoadStops: loadTreeTable.loadStops,
          currentLoadIndexes: loadTreeTable.loadIndexes
        }
      )

    const uniqLoadStops = uniq(currentLoadStops)

    loadTreeDispatch.setSelectedRows(currentSelectedRows)
    loadTreeDispatch.setLoadStops(uniqLoadStops)
    loadTreeDispatch.setLoadIndexes(uniq(currentLoadIndexes))

    // States are not updating until 'handleSelectRow' is completed,
    // so 'row.getIsAllSubRowsSelected()' does not return the correct result without using setTimeout
    setTimeout(() => {
      const updatedSelectedRows = handleSelectParentRow(row, isSelected, currentSelectedRows)
      loadRowRef.current.handleLoadRowSelected(updatedSelectedRows)
      loadStopRef.current.handleLoadStopsSelected(updatedSelectedRows)
    })
  }

  function handlePolishizeButtonClick() {
    triggerSetRefix({ loadIndexes: loadTreeTable.loadIndexes, loadNames: loadTreeTable.loadStops })
  }

  function getStickyColumnStyles(columnId, columns, isCell) {
    const defaultStyle = {
      position: 'sticky',
      top: 0,
      zIndex: isCell ? 10 : 20
    }

    switch (columnId) {
      case 'cardControls':
      case 'transportationControls': {
        return { ...defaultStyle, left: 0, padding: isCell ? 2 : undefined }
      }
      case 'loadApproval': {
        const [card] = columns
        return { ...defaultStyle, left: card.column.getSize() }
      }
      case 'Name': {
        const [card, approve] = columns
        return {
          ...defaultStyle,
          borderRightColor: '#c9c9c9',
          left: card.column.getSize() + approve.column.getSize()
        }
      }
      default:
        return undefined
    }
  }

  function getRowId({ ID, Text }: { ID: string; Text: string }) {
    return `${ID}/${Text}`
  }

  return (
    <>
      <SlvyTable
        checkboxSelection
        multiRowSelection
        className={styles.loadTreeTable}
        columns={columns}
        data={data}
        getBodyClassName={styles.loadTreeTableBody}
        getCellStyle={({ column, row }) =>
          getStickyColumnStyles(column.id, row.getAllCells(), true)
        }
        getExpanderColumn="Name"
        getHeaderStyle={({ column, headers }) => getStickyColumnStyles(column.id, headers)}
        getRowId={getRowId}
        getSubRows={getTreeSubRows}
        initialState={initialState}
        isLoading={!data}
        rowSelection="multiple"
        tableRef={tableRef}
        theme="sencha-grid"
        onSelectRow={handleSelectRow}
      />
      <div className={styles.tableSummary}>
        <div className={styles.tableButton}>
          <PrimaryButton
            disabled={disabledPolishizeButton}
            tooltipText="Polishize"
            onClick={handlePolishizeButtonClick}
          >
            <i className="fa fa-fw fa slvy-ui-icon-method" />
          </PrimaryButton>
          <FooterButtons
            activeTree="Load"
            buttons={config.loadTree.footerButtons}
            disabled={loadTreeTable.disabledFooterButtons}
          />
        </div>
        <span>
          <strong className={styles.summaryText}>
            Load Count: {LoadCount} | Load Stop Count: {LoadStopCount}
          </strong>
        </span>
      </div>
      <LoadApprovalModal />
      <RemoveLoadModal selectedLoadRow={selectedLoadRow} />
      <RemoveLoadStopModal selectedLoadStopRow={selectedLoadStopRow} />
      <ChangeTransportationModal
        availableTransportationModeName={availableTransportationModeName}
        changeTransportationLoadName={changeTransportationLoadName}
      />
    </>
  )
}
