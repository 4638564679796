import React, { Component } from 'react'
import _ from 'lodash'
import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
import TimePickerPanel from 'rc-time-picker/lib/Panel'
import 'rc-calendar/dist/rc-calendar.css'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import { isDisabledDate } from '@/helpers/datetime'

function isEqualToCurrentTime(value) {
  if (value === null) return false
  return moment().format('HH:mm') === moment(value).format('HH:mm')
}
class Datetime extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.disabledDate = this.disabledDate.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.state = {
      value: _.isEmpty(props.component.value)
        ? ''
        : moment(props.component.value).format('YYYY-MM-DD HH:mm')
    }
  }

  handleChange(value) {
    const { component } = this.props
    this.props.onChange(
      component.sectionKey,
      component.fieldName,
      value ? value.format('YYYY-MM-DD HH:mm') : null
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: _.isEmpty(nextProps.component.value)
        ? null
        : moment(nextProps.component.value).format('YYYY-MM-DD HH:mm')
    })
  }

  componentWillUnmount() {
    document.removeEventListener('wheel', this.onScroll)
  }

  componentDidMount() {
    document.addEventListener('wheel', this.onScroll)
  }

  onScroll() {
    this.pickerRef.onVisibleChange(false)
  }

  disabledDate(current) {
    const { min, max, disableDays } = this.props.component

    return isDisabledDate(current, {
      min,
      max,
      disableDays
    })
  }

  render() {
    const {
      isInvalid,
      validationCheckState,
      component: {
        fieldName = '',
        readonly: compReadonly = false,
        element: { selectTime = false } = {}
      } = {},
      readonly: formReadonly = false,
      compact = false,
      inputHeight = 25,
      inputFontSize = 11,
      getValueWithDateFormat
    } = this.props

    const { value } = this.state
    const dateFormat = getValueWithDateFormat(fieldName, value)?.dateFormat || 'YYYY-MM-DD'

    const displaydateformat = `${dateFormat} ${selectTime ? 'HH:mm' : ''}`
    const format = isEqualToCurrentTime(value)
      ? `${dateFormat} ${selectTime ? '00:00' : ''}`
      : displaydateformat
    const readonly = formReadonly || compReadonly
    const valueIsInvalid = !moment(value).isValid()

    const calendar = (
      <Calendar
        showClear
        defaultValue={valueIsInvalid ? null : moment(value)}
        disabledDate={this.disabledDate}
        format={format}
        style={{ zIndex: 1000 }}
        timePicker={selectTime ? <TimePickerPanel showSecond={false} /> : null}
        onSelect={(that) => {
          this.handleChange(that)
        }}
      />
    )

    let validationClass = ''
    if (validationCheckState) {
      validationClass = isInvalid ? 'is-invalid' : ''
    }

    return (
      <DatePicker
        ref={(instance) => {
          this.pickerRef = instance
        }}
        animation="slide-up"
        calendar={calendar}
        disabled={readonly}
        value={valueIsInvalid ? null : moment(value)}
      >
        {({ value }) => {
          return (
            <span tabIndex="0">
              <input
                className={`${validationClass} form-control`}
                disabled={readonly ? 'disabled' : false}
                style={{
                  height: `${compact ? `${inputHeight}px` : 'auto'}`,
                  fontSize: `${compact ? `${inputFontSize}px` : '14px'}`
                }}
                tabIndex="-1"
                value={valueIsInvalid ? '' : moment(value).format(format)}
                onChange={() => {}}
              />
            </span>
          )
        }}
      </DatePicker>
    )
  }
}
export default Datetime
