Ext.define('tuiv2.cmp.SelectedAreaListTmp', {
  extend: 'Ext.Component',
  xtype: 'tuiv2-selectedAreaListTmp',
  draggable: true,
  tpl: new Ext.XTemplate(
    '   <ul class="popup" style="display:block;">' +
      '      <div class="titleBar">' +
      '          <p class="title">{Title}</p>' +
      '          <div class="card-close"><span class="slvy-ui-icon-times-extra-lt {CloseClass}"  index={#}></span></div>' +
      '      </div>' +
      '       <ul>' +
      '      <tpl for="Loads">' +
      '         <li  class="{parent.LoadClass}" loadId="{Id}" palletId="{palletId}" FromLoadId="{Id}" FacilityKey="{parent.Id}">' +
      '           <div class="loadRow">' +
      '             <div class="{parent.class} {parent.LoadClass}" loadId="{Id}" palletId="{palletId}" FromLoadId="{Id}" FacilityKey="{parent.Id}" style="background:{Color}"></div>' +
      '             <p class="{parent.LoadClass}" loadId="{Id}" palletId="{palletId}" FromLoadId="{Id}" FacilityKey="{parent.Id}">{Id}</p>' +
      '           </div>' +
      '         </li>' +
      '      </tpl>' +
      '       </ul>' +
      '      <div class="popupActions">' +
      '          <div class="popupAction {UnloadClass}" FacilityKey="{Id}" FromLoadId="{ltlLoadId}"><span class="slvy-ui-icon-trash-can {UnloadClass}" FacilityKey="{Id}" FromLoadId="{ltlLoadId}" index={#}></span><p class="{UnloadClass}" FacilityKey="{Id}" FromLoadId="{ltlLoadId}" index={#}>Remove</p></div>' +
      '      </div>' +
      '   </ul>'
  )
})
