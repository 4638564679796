import { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, TabContent, TabPane } from 'react-bootstrap'
import _ from 'lodash'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { createSelectorCreator, lruMemoize } from 'reselect'
import { bindActionCreators } from 'redux'
import { PageContent, Tab, PageConfiguration, LanguageModel } from './components'
import EventMethod from '../EventMethod'
import PageAdd from './components/Add'
import { Permission } from '@/components'

/* Crud */
import { AsyncConnect, selectCollection } from '@/crudoptV3'

import { setDesignMode, setLoadingStatus } from '@/store/slices/setting'
import { selectAContainer } from '@/store/slices/containerRelation'
import { showConfigButton, setLanguageModel } from '@/store/slices/pageConfig'

import { getMenus } from '@/actions/menu'
import { clearPageModel, getPages, movePage } from '@/actions/page'
import getMenu from '@/utils/getMenu'
import './tab.scss'

class Page extends Component {
  constructor(props) {
    super(props)
    this.handleOpenAdd = this.handleOpenAdd.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleOpenPageDetail = this.handleOpenPageDetail.bind(this)
    this.handleDesignMode = this.handleDesignMode.bind(this)
    this.setPageTitle = this.setPageTitle.bind(this)
    this.state = {
      pageRef: 'detail'
    }
  }

  setPageTitle = () => {
    const {
      menus: { data: menusData = [] } = {},
      pages: { data: pagesData = [] } = {},
      params: { menuId = '', pageId = '' } = {}
    } = this.props

    const menu = getMenu(menusData, menuId)
    const tab = _.find(pagesData, ['id', pageId])
    const { item: { menuName } = {} } = menu || {}
    const { name: tabName } = tab || {}

    if (menuName && tabName) {
      document.title = `${menuName} / ${tabName}`
    }
  }

  componentDidMount() {
    if (this.props.pages.needFetch) {
      this.props.dispatch(this.props.pages.fetch)
    }

    const { pages: { data: pagesData = [] } = {} } = this.props
    if (pagesData.length) {
      this.setPageTitle()
    }

    this.props.showConfigButton(true)
  }

  componentDidUpdate(prevProps) {
    const { pages: { data: pagesData = [] } = {} } = this.props
    if (pagesData.length || prevProps.params.menuId !== this.props.params.menuId) {
      this.setPageTitle()
    }
  }

  componentWillUnmount() {
    document.title = 'Solvoyo | Supply Chain Planning and Optimization, Cloud Based, SaaS'
    this.props.showConfigButton(false)
    this.props.setLanguageModel(false)

    if (this.props.loadingAction) {
      this.props.setLoadingStatus({ action: false })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.params, nextProps.params)) {
      this.setState({ pageRef: 'detail' })
      this.props.setLanguageModel(false)

      if (!_.isEqual(this.props.params.comp, nextProps.params.comp) && this.props.loadingAction) {
        this.props.setLoadingStatus({ action: false })
      }
    }
    if (nextProps.pages.needFetch) {
      this.props.dispatch(nextProps.pages.fetch)
    }
    if (!_.isEqual(this.props.pages.data, nextProps.pages.data)) {
      const has = _.find(nextProps.pages.data, (data) => data.id === nextProps.params.pageId)
      if (!has) {
        const {
          params: { environment, catalogId, menuId, storeIndex }
        } = nextProps
        const firstPage = _.first(nextProps.pages.data)
        if (firstPage) {
          const $url = `/${environment}/catalog/${catalogId}/store/${storeIndex}/menu/${menuId}/page/${firstPage.id}`
          // @TODO:
          this.props.navigate($url, { replace: true })
          // window.location.replace($url)
        }
      }
    }
  }

  handleOpenAdd(event) {
    event.preventDefault()
    this.setState({ pageRef: 'add' })
    return false
  }

  handleOpen(ref) {
    this.setState({ pageRef: ref })
  }

  handleOpenPageDetail() {
    this.handleOpen('detail')
  }

  handleDesignMode() {
    const { designMode } = this.props
    localStorage.setItem('designMode', String(!designMode))
    this.props.setDesignMode(!designMode)
  }

  disableSelection() {
    this.props.selectAContainer(0)
  }

  handleDragStart(id, menuId, catalogId, event) {
    event.dataTransfer.setData('catalogId', catalogId)
    event.dataTransfer.setData('menuId', menuId)
    event.dataTransfer.setData('pageId', id)
  }

  handleDrop(index, id, event) {
    const catalogId = event.dataTransfer.getData('catalogId')
    const menuId = event.dataTransfer.getData('menuId')
    const pageId = event.dataTransfer.getData('pageId')

    const isItself = id === pageId
    if (isItself) return

    const { movePage, clearPageModel } = this.props

    movePage(catalogId, menuId, pageId, { sortOrder: index })
    // TODO: Find a better way to clear the page model
    setTimeout(() => clearPageModel(), 1000)
  }

  render() {
    const {
      pages: { data = [] },
      params: { catalogId, menuId, storeIndex, pageId, comp, environment },
      params,
      designMode = false,
      user
    } = this.props
    const { pageRef } = this.state
    const pageParams = { ...params, designMode }
    const sortedData = _.sortBy(data, 'sortOrder')
    const isConfig = environment === 'Configuration'
    const baseUrl = `/${environment}/catalog/${catalogId}/store/${storeIndex}/menu/${menuId}/page`

    const containerClass = `wrapper-content tabs-container ${cx({
      designMode,
      previewMode: !designMode
    })}`

    return (
      <div className={containerClass}>
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <Nav variant="tabs" onClick={this.disableSelection.bind(this)}>
          {_.map(sortedData, (item, index) => {
            const { id, name, iconClass, isHidden } = item
            const activeClass = cx({ active: pageId === id })
            const hiddenClass = cx({ tabLineThrough: isHidden })

            if (!isConfig && isHidden) return null

            // WARNING: DO NOT CHANGE "slvy-js-nav-item"
            // className used for calculating pageHeight in src/elements/container/index.js
            return (
              <Nav.Item key={id} className="slvy-js-nav-item">
                <Tab
                  className={hiddenClass}
                  environment={environment}
                  moveData={{ id, menuId, catalogId, index }}
                  onDragStart={this.handleDragStart}
                  onDrop={this.handleDrop.bind(this)}
                >
                  <Nav.Link as={Link} className={activeClass} to={`${baseUrl}/${id}`}>
                    <i className={`pageIcon me-2 ${iconClass}`} />
                    {name}
                  </Nav.Link>
                </Tab>
              </Nav.Item>
            )
          })}

          <Permission has={['Page.Add']}>
            <Nav.Item>
              <Nav.Link
                as={Link}
                className={cx({ active: comp === 'add' })}
                to={`${baseUrl}/${pageId}/add`}
              >
                <i className="pageIcon me-2 fa fa-plus" />
                Add
              </Nav.Link>
            </Nav.Item>
          </Permission>

          {isConfig ? (
            <Nav.Item className="ms-auto">
              <button className="border rounded bg-white" onClick={this.handleDesignMode}>
                {designMode ? 'Design Mode' : 'Preview Mode'}
              </button>
            </Nav.Item>
          ) : null}
        </Nav>

        <TabContent>
          <TabPane className="active" transition={false}>
            {comp === 'relations' || comp === 'workflow' ? (
              <EventMethod
                key={pageRef}
                baseUrl={baseUrl}
                navigate={this.props.navigate}
                params={params}
                tab={comp}
                onSaveAndClose={this.handleOpenPageDetail}
              />
            ) : comp === 'add' ? (
              <PageAdd
                key={pageRef}
                navigate={this.props.navigate}
                params={params}
                onSaveAndClose={this.handleOpenPageDetail}
              />
            ) : (
              <PageContent key={pageRef} params={pageParams} userName={user} />
            )}
          </TabPane>
        </TabContent>

        <Permission has={['Page.Edit']}>
          <PageConfiguration params={params} onSelect={this.handleOpen} />
        </Permission>
        <LanguageModel />
      </div>
    )
  }
}

const getPagesState = (state, ownProps) => {
  return selectCollection(getPages(ownProps.params.catalogId, ownProps.params.menuId), state.model3)
}

const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)

const getPagesSelector = () => {
  return createDeepEqualSelector([getPagesState], (plugins) => plugins)
}

const getIsSelectedState = (state, ownProps) => {
  return state.containerRelation.selectedContainer === ownProps.id
}

const getIsSelectedSelector = () => {
  return createDeepEqualSelector([getIsSelectedState], (x) => x)
}

const makeMapStateToProps = () => {
  const pages = getPagesSelector()
  const isSelected = getIsSelectedSelector()
  const mapStateToProps = (state, ownProps) => {
    return {
      isSelected: isSelected(state, ownProps),
      designMode: state.setting.designMode,
      pages: pages(state, ownProps),
      user: state.oidc.user.profile.preferred_username,
      loadingAction: state.setting.loading.action
    }
  }
  return mapStateToProps
}

export default AsyncConnect(
  {
    menus: (state, ownProps) => selectCollection(getMenus(ownProps.params.catalogId), state.model3)
  },
  null
)(
  connect(makeMapStateToProps, (dispatch) => ({
    dispatch,
    setDesignMode: bindActionCreators(setDesignMode, dispatch),
    selectAContainer: bindActionCreators(selectAContainer, dispatch),
    movePage: (...p) => {
      dispatch(movePage(...p))
    },
    clearPageModel: bindActionCreators(clearPageModel, dispatch),
    showConfigButton: bindActionCreators(showConfigButton, dispatch),
    setLoadingStatus: bindActionCreators(setLoadingStatus, dispatch),
    setLanguageModel: bindActionCreators(setLanguageModel, dispatch)
  }))(Page)
)
