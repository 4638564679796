import React, { Component } from 'react'
import _ from 'lodash'
import { ChartCell } from '../../../components'
import {
  round,
  getMessage,
  getAttachments,
  val,
  editReason,
  findUpdateValue,
  format,
  parse,
  displayOfflineErrorMsg
} from '../../../utils'

export default class ChartResource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelection: '0',
      productPeriod: {},
      attachments: [],
      updatedPeriodId: -1,
      currentReason: {},
      isUserAllowedToChangeProductionQuantity: true
    }
  }

  updateHandler(params, e, extras = {}) {
    return new Promise((resolveHandler) => {
      if (_.isEmpty(extras) && _.isEmpty(e)) {
        this.setState({
          currentReason: {},
          attachments: []
        })
      }

      let {
        connectApi,
        lastRequest: {
          GetRccpResourcePeriodResponse: { Request = {} }
        },
        settings: {
          modificationCount = 0,
          productionVariants: {
            defaultProductionVariant = 'Production',
            userOptions: { isEnabled: isUserOptionsEnabled = false } = {}
          } = {},
          stateHandler: {
            selectedParameter: { tableName = 'Production' },
            selectedProductVariant = ''
          },
          updateReasons = {}
        }
      } = this.props
      let {
        keyCode,
        target,
        target: { value = '' }
      } = e
      const { isForce = false, itemValue = value, clickUpdate = false } = extras

      value = parse(value)

      selectedProductVariant = !selectedProductVariant
        ? defaultProductionVariant
        : selectedProductVariant
      const productionVariant = isUserOptionsEnabled
        ? selectedProductVariant
        : defaultProductionVariant
      const requestFieldName = findUpdateValue(tableName, productionVariant)

      if (keyCode === 13 || clickUpdate) {
        const set = {
          isopen: true,
          node: target,
          reasondata: updateReasons
        }
        const update = () => {
          const {
            currentReason: { code: reasonCode, name: reasonName }
          } = this.state

          connectApi('UpdateRccpResourcePeriodProduct', {
            fieldName: requestFieldName,
            ...params,
            newValue: clickUpdate ? itemValue : value,
            rccpConfig: {
              ...(isForce ? { ForceUpdate: true } : {}),
              UpdateReasonCode: reasonCode,
              UpdateReasonDescription: reasonName,
              ModificationCount: modificationCount
            }
          })
            .then((res) => {
              const { Description = 'Error', Code = -1, Attachments = [] } = res

              if (!_.isEmpty(Attachments)) {
                this.setState({
                  attachments: getAttachments(Attachments, value),
                  updatedPeriodId: params.periodId
                })
              }

              getMessage(Code, Description).then((ret) => {
                if (ret.isOk) {
                  Request.rccpConfig.ModificationCount = 10000

                  connectApi('GetRccpResourcePeriodResponse', Request).then(() => {
                    target.blur()
                  })

                  if (clickUpdate) {
                    this.setState({
                      attachments: [],
                      updatedPeriodId: -1
                    })
                  }
                }
              })
            })
            .catch(() => {
              displayOfflineErrorMsg()
            })
            .finally(resolveHandler)
        }

        if (_.isEmpty(this.state.attachments)) {
          editReason(set, {
            onConfirm: (e, isok, reason) => {
              this.setState({ currentReason: reason }, () => update())
            },
            onReject: resolveHandler
          })
        } else {
          update()
        }
      } else {
        resolveHandler()
      }
    })
  }

  clickHandler(pindex, periodId, period, e) {
    e.preventDefault()

    const { chartHandler, productClickHandler, resourceIndex } = this.props
    const { currentSelection } = this.state

    productClickHandler({}, resourceIndex, period)

    if (currentSelection !== pindex) {
      chartHandler(pindex)
      this.setState({
        currentSelection: pindex,
        updatedPeriodId: periodId,
        attachments: []
      })
    }
  }

  getData(resourcePeriod, products) {
    return _.reduce(
      _.cloneDeep(resourcePeriod),
      (obj, value, key) => {
        value.ResourcePeriodProducts = _.map(value.ResourcePeriodProducts, (_rpp, _rppindex) => {
          const { ProductIndex: newProductIndex = 0 } = _rpp

          return {
            ..._rpp,
            ...{ Product: products[newProductIndex] },
            ...{ ResourcePeriodProductItemIndex: _rppindex }
          }
        })

        obj[key] = value
        return obj
      },
      {}
    )
  }

  componentDidMount() {
    const {
      settings: {
        history: {
          componentState: { pushState: { TableResource: { resourceDictionary } = {} } = {} },
          state: {
            back: { pushState: { TableProduct: { productDictionary = {} } = {} } = {} } = {}
          } = {}
          // componentState: {
          //     pushState: {
          //         TableResource: {
          //             resourceDictionary = {}
          //         } = {}
          //     } = {}
          // } = {}
        } = {}
      } = {},
      productPeriods,
      resourcePeriod,
      products,
      productId = 0,
      resourceIndex = 0,
      productClickHandler,
      periods = [],
      isAllowed = () => {}
    } = this.props

    const newResourcePeriod = this.getData(resourcePeriod, products)
    const { currentProductPeriod = 0 } =
      _.find(productDictionary, (_pd) => _pd.currentProductId === productId) || {}
    const { ResourcePeriodProducts: newResourcePeriodProducts = [] } =
      newResourcePeriod[currentProductPeriod]
    const resourcePeriodProductItem =
      _.find(newResourcePeriodProducts, (_rp) => _rp.Product.Id === productId) || {}
    const { ProductIndex = 0 } = resourcePeriodProductItem
    const productPeriod = productPeriods[ProductIndex][0]

    const resource =
      _.find(resourceDictionary, (_, index) => index === resourceIndex.toString()) ?? {}
    const periodIndex = _.findIndex(periods, { Id: resource?.period?.Id }) ?? 0
    const { formProductData: { ProductPeriod = {} } = {} } = resource ?? {}

    productClickHandler(
      { ...resourcePeriodProductItem },
      resourceIndex,
      periods[currentProductPeriod ?? 0]
    )

    const isUserAllowedToChangeProductionQuantity = isAllowed('Resource/Product Quantity')

    let currentSelection = {}
    if (currentProductPeriod) {
      currentSelection = {
        productPeriod,
        currentSelection: currentProductPeriod.toString()
      }
    } else {
      currentSelection = {
        productPeriod: ProductPeriod,
        currentSelection: ProductPeriod?.PeriodIndex?.toString()
      }
    }

    this.setState({
      ...currentSelection,
      isUserAllowedToChangeProductionQuantity
    })
  }

  render() {
    let {
      products,
      productPeriods,
      productClickHandler,
      productId,
      resourceId,
      resourcePeriod: newResourcePeriod,
      periods,
      settings,
      settings: {
        productionVariants: {
          defaultProductionVariant = 'Production',
          userOptions: { isEnabled: isUserOptionsEnabled = false } = {}
        } = {},
        stateHandler: {
          selectedParameter: { Index: SelectedParamIndex = 0 },
          selectedProductVariant = ''
        },
        productInfoSettings = []
      }
    } = this.props
    const {
      productPeriod: { ProductIndex: stateProductIndex = 0 },
      currentSelection,
      attachments,
      updatedPeriodId,
      isUserAllowedToChangeProductionQuantity = true
    } = this.state

    const productionObj = _.find(productInfoSettings, { tableName: 'Production' }) || {}
    const { format: formatType } = productionObj || {}

    const resourcePeriod = this.getData(newResourcePeriod, products)
    // color =
    //     d3
    //         .scaleLinear()
    //         .domain([0, 400])
    //         .interpolate(d3.interpolateHcl)
    //         .range([d3.rgb('#dcdcdc'), d3.rgb('#999')])

    return (
      <aside className="product-resource-chart -chart">
        {_.map(resourcePeriod, (pitem, pindex) => {
          let {
            PeriodIndex = 0,
            MinorSetupPercentage = 0,
            MajorSetupPercentage = 0,
            ResourcePeriodProducts = []
          } = pitem
          const currentPeriod = periods[PeriodIndex]
          /// , Name: PeriodName
          const { Id: periodId = 0, Visible = false } = currentPeriod
          const selectedProduction =
            _.find(ResourcePeriodProducts, (rpItem) => rpItem.ProductIndex === stateProductIndex) ||
            {}
          const { Values = {} } = selectedProduction

          MinorSetupPercentage = 100 * MinorSetupPercentage
          MajorSetupPercentage = 100 * MajorSetupPercentage

          const calculatedMinorSetup = MinorSetupPercentage
          const calculatedMajorSetup = MajorSetupPercentage
          const totalSetup = round(calculatedMinorSetup + calculatedMajorSetup)
          const chartStyle = {
            height: `calc(100% - ${24}px)`
          }

          selectedProductVariant = !selectedProductVariant
            ? defaultProductionVariant
            : selectedProductVariant
          const productionVariant = isUserOptionsEnabled
            ? selectedProductVariant
            : defaultProductionVariant
          const selectedProductionValue = val(Values, productionVariant, SelectedParamIndex)

          return (
            Visible && (
              <figure key={pindex} className={pindex === currentSelection ? '-active' : ''}>
                <section>
                  <ChartCell
                    attachments={{ attachments, isActive: updatedPeriodId === periodId }}
                    disabled={!isUserAllowedToChangeProductionQuantity}
                    formatType={formatType}
                    settings={settings}
                    updateHandler={this.updateHandler.bind(this, {
                      resourceId,
                      productId: products[stateProductIndex].Id,
                      periodId
                    })}
                    value={format(selectedProductionValue, formatType)}
                  />
                </section>

                <main
                  className="-chart-wrp"
                  style={chartStyle}
                  onClick={this.clickHandler.bind(this, pindex, periodId, currentPeriod)}
                >
                  <footer style={{ height: `${totalSetup}%` }}>
                    <div
                      className="-setup"
                      style={{
                        height: `${(100 * calculatedMinorSetup) / totalSetup}%`,
                        backgroundColor: 'gray'
                      }}
                    />
                    <div
                      className="-setup"
                      style={{
                        height: `${(100 * calculatedMajorSetup) / totalSetup}%`,
                        backgroundColor: 'black'
                      }}
                    />
                  </footer>

                  {_.map(
                    ResourcePeriodProducts,
                    (resourcePeriodProductItem, resourcePeriodProductIndex) => {
                      const {
                        CapacityPercentage = 0,
                        ProductIndex = 0,
                        ResourceIndex = 0,
                        Product = {}
                      } = resourcePeriodProductItem
                      const productPeriod = productPeriods[ProductIndex][pindex]
                      const isSelected = productPeriod.ProductIndex === stateProductIndex
                      const { Id: pID = 0, Name = '' } = Product
                      const isProduction = productId === pID
                      const productItemHeight = CapacityPercentage * 100

                      return (
                        <div
                          key={resourcePeriodProductIndex}
                          className={`-cbar ${isSelected ? '-active-product' : ''} ${
                            isProduction ? '-green' : ''
                          } ${productItemHeight < 3.2 ? '-zoom-hover' : ''}`}
                          style={{ height: `${productItemHeight}%` }}
                          title={Name}
                          // style={{ height: `${productItemHeight}%`, backgroundColor: color((400 / ResourcePeriodProducts.length) * resourcePeriodProductIndex) }}
                          onClick={(e) => {
                            e.stopPropagation()
                            productClickHandler(
                              { ...resourcePeriodProductItem },
                              ResourceIndex,
                              currentPeriod
                            )

                            this.setState({
                              productPeriod,
                              currentSelection: pindex,
                              attachments: []
                            })
                          }}
                        />
                      )
                    }
                  )}
                </main>
              </figure>
            )
          )
        })}
      </aside>
    )
  }
}
