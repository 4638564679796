Ext.define('Spui.view.common.similarity.tabpanel.NomalizedChart.Mixin', {
  extend: 'Ext.Mixin',
  config: {
    normalizedChartCfg: 'NormalizedSalesChart',
    normalizedChartStoreCfg: 'NormalizedSalesChartStore',
    normalizedChartStore: {},
    normalizedChart: {},
    normalizedGridCfg: 'NormalizedSalesGrid',
    normalizedGrid: false,
    SpotId: null
  },
  onChangeSelectedSimilarSpot: function () {
    var store = this.getNormalizedChartStore()
    var tabpabel = this.up()
    if (!tabpabel) {
      return false
    }
    if (!tabpabel.collapsed && this.isVisible()) {
      var SpotId = this.getSpotId()
      store.getProxy().setExtraParam('SpotId', SpotId)
      store.load()
      store.forceToLoad = false
    } else {
      store.forceToLoad = true
    }
  },
  forcetoLoad: function () {
    var store = this.getNormalizedChartStore()
    var tabpabel = this.up()
    if (!tabpabel) {
      return false
    }
    if (!tabpabel.collapsed) {
      var SpotId = this.getSpotId()
      store.getProxy().setExtraParam('SpotId', SpotId)
      if (store.forceToLoad) {
        store.load()
        store.forceToLoad = false
      }
    }
  },

  applyNormalizedChartCfg: function (c) {
    return window.ss(c)
  },
  applyNormalizedGridCfg: function (c) {
    return window.ss(c)
  },
  applyNormalizedChartStoreCfg: function (c) {
    return window.ss(c)
  },
  updateSpotId: function (c) {
    var store = this.getNormalizedChartStore()
    store.getProxy().setExtraParam('SpotId', c)
  },
  listeners: {
    activate: function (p) {
      var store = p.getNormalizedChartStore(),
        SpotId = p.getSpotId()
      store.getProxy().setExtraParam('SpotId', SpotId)
      if (store.forceToLoad) {
        store.load()
        store.forceToLoad = false
      }
    }
  },
  applyNormalizedChartStore: function (b) {
    var me = this
    var setRec = this.getNormalizedChartStoreCfg()

    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        forceToLoad: true,
        fields: [
          { name: 'DayofSpot', type: 'int' },
          { name: 'TotalSalesQty', type: 'number' },
          { name: 'RunningTotal', type: 'number' },
          { name: 'DaylyPercent', type: 'number' },
          { name: 'ParetoPercent', type: 'number' },
          { name: 'ParetoDayNumber', type: 'int' }
        ],

        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          extraParams: {
            MaterialCode: this.MaterialCode
          },
          url: Spui.Global.getApiUrl() + 'spui/GetNormalizedData'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)

      b.on('beforeload', me.onBeforeLoadStore, me)
      b.on('load', me.onload, me)
      b.loadData([
        {
          a: 1
        }
      ])
    }
    return b
  },
  onload: function (store) {
    var p = this.getNormalizedChart()
    var data = store.getDatas()
    if (data.length === 0) {
      p.draw()
    }
    p.setLoading(false)
    if (p.onloadStore) p.onloadStore(store)
  },
  onBeforeLoadStore: function (store) {
    if (this.hidden) return false
    if (!this.ownerCt.ownerCt.rendered) return false
    if (this.ownerCt.ownerCt.hidden) return false
    var p = this.getNormalizedChart()
    p.setLoading(tt('Yükleniyor'))
  },
  applyNormalizedChart: function (b) {
    var setRec = this.getNormalizedChartCfg()

    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'spui-view-common-similarity-tabpanel-nomalizedchart-chart',
        store: this.getNormalizedChartStore()
      })

      b = Ext.create(b)
    }
    return b
  },
  updateNormalizedChart: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onMaximize: function () {
    var me = this,
      store = me.getNormalizedChartStore(),
      win = Ext.create('Ext.window.Window', {
        maximized: true,
        layout: 'fit'
      })
    win.show()
    win.setLoading(tt('Yükleniyor'))
    Ext.defer(function () {
      var item = Ext.create({
        xtype: 'spui-view-common-similarity-tabpanel-nomalizedchart-tabpabel',

        normalizedChartStore: store,
        ownerCt: me.ownerCt.ownerCt,
        normalizedChart: {
          title: tt('Sales Chart'),

          plugins: [
            {
              ptype: 'cornerIcon',

              cfgButton: [
                '->',
                {
                  iconCls: 'x-fa fa-cog',
                  cls: 'config-btn',
                  handler: 'onConfigureChart'
                }
              ]
            }
          ]
        },

        normalizedGrid: { titleo: 3 }
      })
      win.add(item)
      win.setLoading(false)
    }, 10)
  },
  applyNormalizedGrid: function (b) {
    if (!b) return
    var setRec = this.getNormalizedGridCfg()

    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      var store = Ext.create('Ext.data.ChainedStore', {
        source: this.getNormalizedChartStore()
      })

      Ext.applyIf(b, {
        xtype: 'grid',
        title: tt('Sales Grid'),
        store: store,
        columns: [{ text: '11' }],
        plugins: [
          {
            ptype: 'cornerIcon',

            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigNormalizedGrid'
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    return b
  },
  updateNormalizedGrid: function (f, g) {
    if (!f) return
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigNormalizedGrid: function () {
    var setRec = this.getNormalizedGridCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigNormalizedGridSave, this)
  },
  onConfigNormalizedGridSave: function (v, k) {
    var setRec = this.getNormalizedGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setNormalizedGrid(res)
  },
  onConfigNormalizedChart: function () {
    var setRec = this.getNormalizedChartCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigNormalizedChartSave, this)
  },
  onConfigNormalizedChartSave: function (v, k) {
    var setRec = this.getNormalizedChartCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setNormalizedChart(res)
  }
})
