import React, { Component } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { slvyToast, SlvyTable } from '@/components'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import RenderEditable from './renderEditable'
import notSizeColumns from './not-size-columns'
import gridColumns from './gridColumns'
import './index.scss'

const mixedTypeFilter = (row, columnId, filterValue) => {
  const value = row.getValue(columnId)
  const isNum = !Number.isNaN(Number(value))

  if (isNum) {
    return Number(value) === Number(filterValue)
  }

  return value.toLowerCase().includes(filterValue.toLowerCase())
}

class IPOPrepack extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allAssortiesAreIncluded: false,
      assortmentId: 0,
      assortyName: '',
      assortyTypeId: undefined,
      clusterId: '',
      columns: [],
      data: [],
      getPrepackButtonActivationState: true,
      isApproveDisable: true,
      isApproveShown: false,
      isApproved: false,
      isLockRevision: false,
      optimizeState: false,
      productId: 0,
      progress: false,
      runInfoId: 0,
      scenarioId: 0,
      sizeBreakdownTotal: {},
      title: '',
      defaultSorted: [
        {
          id: 'OptimalQuantity',
          desc: true
        }
      ]
    }

    this.bindMethods()
  }

  bindMethods() {
    this.GetPrepackClickedEvent = this.GetPrepackClickedEvent.bind(this)
    this.calculateIncludedAssorties = this.calculateIncludedAssorties.bind(this)
    this.createNewAssortyEvent = this.createNewAssortyEvent.bind(this)
    this.disableGetPrepackButton = this.disableGetPrepackButton.bind(this)
    this.enableGetPrepackButton = this.enableGetPrepackButton.bind(this)
    this.excludeAll = this.excludeAll.bind(this)
    this.executeApprove = this.executeApprove.bind(this)
    this.handleAssortName = this.handleAssortName.bind(this)
    this.handleRevisedQTYBlur = this.handleRevisedQTYBlur.bind(this)
    this.handleRevisedQTYKeyPress = this.handleRevisedQTYKeyPress.bind(this)
    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
    this.includeAll = this.includeAll.bind(this)
    this.mappingFromCrudList = this.mappingFromCrudList.bind(this)
    this.onAssortyTypeIdChange = this.onAssortyTypeIdChange.bind(this)
    this.prepackOptimizationEvent = this.prepackOptimizationEvent.bind(this)
    this.renderEditable = this.renderEditable.bind(this)
    this.renderEditablePopup = this.renderEditablePopup.bind(this)
    this.setPrepackData = this.setPrepackData.bind(this)
    this.setPrepackDataOnClick = this.setPrepackDataOnClick.bind(this)
    this.setPrepackParameters = this.setPrepackParameters.bind(this)
    this.setUpdatedPopupValue = this.setUpdatedPopupValue.bind(this)
    this.triggerApproveEvent = this.triggerApproveEvent.bind(this)
    this.triggerConstraintsEvent = this.triggerConstraintsEvent.bind(this)
    this.triggerPopupUpdateEvent = this.triggerPopupUpdateEvent.bind(this)
    this.updateProductInclude = this.updateProductInclude.bind(this)
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'setPrepackData',
      fn: this.setPrepackData.bind(this),
      args: [
        {
          name: 'productId',
          type: PluginTypes.int
        },
        {
          name: 'assortmentId',
          type: PluginTypes.int
        },
        {
          name: 'scenarioId',
          type: PluginTypes.int
        },
        {
          name: 'runInfoId',
          type: PluginTypes.int
        },
        {
          name: 'clusterId',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'SetPrepackParameters',
      fn: this.setPrepackParameters.bind(this),
      args: [
        {
          name: 'productId',
          type: PluginTypes.int
        },
        {
          name: 'assortmentId',
          type: PluginTypes.int
        },
        {
          name: 'clusterId',
          type: PluginTypes.string
        },
        {
          name: 'title',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'DisplayLoading',
      fn: this.DisplayLoading.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'HideLoading',
      fn: this.HideLoading.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'DisableGetPrepackButton',
      fn: this.disableGetPrepackButton.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'EnableGetPrepackButton',
      fn: this.enableGetPrepackButton.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'ExecuteApprove',
      fn: this.executeApprove.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'SetUpdatedPopupValue',
      fn: this.setUpdatedPopupValue.bind(this),
      args: [
        {
          name: 'rowIndex',
          type: PluginTypes.int
        },
        {
          name: 'columnName',
          type: PluginTypes.string
        },
        {
          name: 'popupValue',
          type: PluginTypes.int
        }
      ]
    })

    this.props.registerMethod({
      key: 'ChangeOptimizeState',
      fn: this.changeOptimizeState.bind(this),
      args: []
    })

    this.triggerConstraintsEvent = this.props.registerEvent({
      key: 'ConstraintsClicked',
      fn: this.triggerConstraintsEvent.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.triggerApproveEvent = this.props.registerEvent({
      key: 'ApproveClicked',
      fn: this.triggerApproveEvent.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.triggerPopupUpdateEvent = this.props.registerEvent({
      key: 'PopupUpdateClicked',
      fn: this.triggerPopupUpdateEvent.bind(this),
      returnTypes: {
        refreshKey: PluginTypes.string,
        assorty: PluginTypes.string,
        assortyId: PluginTypes.int,
        columnName: PluginTypes.string,
        lotName: PluginTypes.string,
        productId: PluginTypes.int,
        rowIndex: PluginTypes.int,
        type: PluginTypes.string
      }
    })

    this.createNewAssortyEvent = this.props.registerEvent({
      key: 'NewPrepackClicked',
      fn: this.createNewAssortyEvent.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.prepackOptimizationEvent = this.props.registerEvent({
      key: 'OptimizationClicked ',
      fn: this.prepackOptimizationEvent.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.GetPrepackClickedEvent = this.props.registerEvent({
      key: 'GetPrepackClicked',
      fn: this.GetPrepackClickedEvent.bind(this),
      returnTypes: {
        refreshKey: PluginTypes.string,
        assortmentId: PluginTypes.int,
        productId: PluginTypes.int
      }
    })
    this.PrepacksPopulated = this.props.registerEvent({
      key: 'PrepacksPopulated',
      fn: this.PrepacksPopulated.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    const isApproveShown = _.get(this.props, 'settings.config.general.isApproveShown', false)

    this.setState({
      isApproveShown
    })

    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition
      })
    }
  }

  handleSynchronizeDataDefinition(fields, value) {
    const newValue = _.cloneDeep(value)

    if (newValue?.gridColumns?.length === 0) {
      newValue.gridColumns = gridColumns
    }

    return newValue
  }

  DisplayLoading() {
    this.setState({ progress: true })
  }

  HideLoading() {
    this.setState({ progress: false })
  }

  disableGetPrepackButton() {
    this.setState({ getPrepackButtonActivationState: false })
  }

  enableGetPrepackButton() {
    this.setState({ getPrepackButtonActivationState: true })
  }

  createNewAssortyEvent() {
    const { productId } = this.state
    if (!_.isNil(productId) && productId !== 0) {
      return { refreshKey: uuidv4() }
    }
  }

  triggerConstraintsEvent() {
    const { productId } = this.state
    if (!_.isNil(productId) && productId !== 0) {
      return { refreshKey: uuidv4() }
    }
  }

  triggerApproveEvent() {
    const { productId } = this.state
    if (!_.isNil(productId) && productId !== 0) {
      return { refreshKey: uuidv4() }
    }
  }

  triggerPopupUpdateEvent({
    Assorty: assorty,
    AssortyId: assortyId,
    ColumnName: columnName,
    LotName: lotName,
    ProductId: productId,
    RowIndex: rowIndex,
    Type: type
  }) {
    const { productId: productIdState } = this.state

    if (!_.isNil(productIdState) && productIdState !== 0) {
      return {
        refreshKey: uuidv4(),
        assorty,
        assortyId,
        columnName,
        lotName,
        productId,
        rowIndex,
        type
      }
    }
  }

  PrepacksPopulated() {
    const { productId } = this.state
    if (!_.isNil(productId) && productId !== 0) {
      return { refreshKey: uuidv4() }
    }
  }

  GetPrepackClickedEvent() {
    const { productId, assortmentId } = this.state
    if (!_.isNil(productId) && productId !== 0) {
      return { refreshKey: uuidv4(), productId, assortmentId }
    }
  }

  prepackOptimizationEvent() {
    const { productId } = this.state
    this.setState({ optimizeState: true })
    if (!_.isNil(productId) && productId !== 0) {
      return { refreshKey: uuidv4() }
    }
  }

  setUpdatedPopupValue({ rowIndex, columnName, popupValue }) {
    const { data = [] } = this.state
    if (!_.isEmpty(data)) {
      data[rowIndex][columnName] = popupValue
      this.setState({ data })
    }
  }

  changeOptimizeState() {
    this.setState({ optimizeState: false })
  }

  setPrepackParameters(data) {
    const { productId, assortmentId, clusterId, title = '' } = data

    this.setState({
      title: title || '',
      productId,
      assortmentId,
      clusterId
    })
  }

  setPrepackData(data) {
    const { settings: { config: { prepackData = {} } = {} } = {} } = this.props
    const {
      productId = 0,
      assortmentId = 0,
      scenarioId = 0,
      runInfoId = 0,
      clusterId = 0
    } = { ...prepackData, ...data }

    const initPlugin = () => {
      if (this.state.productId) {
        this.initializePlugin()
      }
    }

    if (productId) {
      this.setState(
        {
          productId,
          assortmentId,
          scenarioId,
          runInfoId,
          clusterId
        },
        () => {
          initPlugin()
        }
      )
    } else {
      // if parameters set by setPrepackParameters
      initPlugin()
    }
  }

  setPrepackDataOnClick() {
    const {
      settings: {
        config: {
          prepackData: { productId: cfgProductId = 0, assortmentId: cfgAssortmentId } = {}
        } = {}
      } = {}
    } = this.props
    const { productId, assortmentId } = this.state
    if ((_.isNil(productId) || productId === 0) && (_.isNil(assortmentId) || assortmentId === 0)) {
      this.setState({ productId: cfgProductId, assortmentId: cfgAssortmentId }, () => {
        if (!_.isNil(this.state.productId) && this.state.productId !== 0) {
          if (!_.isNil(this.state.assortmentId) && this.state.assortmentId !== 0) {
            this.initializePlugin()
          }
        }
      })
    } else {
      this.initializePlugin()
    }
  }

  getApproveStatus($callback) {
    const { productId } = this.state
    const {
      id,
      clientWithProgress,
      settings: { config: { general: { isApproveExternal = false } = {} } = {} } = {}
    } = this.props

    clientWithProgress
      .post(`/data/${id}/invoke/IsOptionLockedNew`, { data: { productId } })
      .then((response) => {
        const { LockOption = false, LockRevision = false, StatusName = '' } = response
        let optObj = {}
        if (StatusName === 'UNOPTIMIZED') {
          optObj = { optimizeState: true }
        } else if (StatusName === 'OPTIMIZED') {
          optObj = { optimizeState: false }
        }
        if (isApproveExternal) {
          this.setState({
            isApproveDisable: false,
            isApproved: LockOption,
            isLockRevision: LockRevision
          })
        } else {
          this.setState({ isApproved: LockOption, isLockRevision: LockRevision, ...optObj })
        }
        $callback()
      })
      .catch(() => {
        this.setState({ isApproved: false, isLockRevision: false })
        $callback()
      })
  }

  mappingFromCrudList = (list) => {
    return _.map(list, ({ AssortyType, AssortyTypeID }) => {
      return {
        label: AssortyType,
        value: AssortyTypeID
      }
    })
  }

  initializePlugin() {
    const {
      id,
      clientWithProgress,
      settings: { config: { settings: { sizeBreakdownTotal = false } = {} } = {} } = {}
    } = this.props

    const { productId, assortmentId, isApproveShown } = this.state

    this.setState({ progress: true })

    const getData = () => {
      clientWithProgress
        .post(`/data/${id}/invoke/InitializeIPOPrepack`, {
          data: { productId, assortmentId }
        })
        .then((response) => {
          this.setState({ data: response, progress: false }, () => {
            this.createReactTable()
            this.PrepacksPopulated()
          })
        })
    }

    if (isApproveShown) {
      this.getApproveStatus(() => {
        if (sizeBreakdownTotal) {
          this.getSizeBreakdownTotal(() => {
            getData()
          })
        } else {
          getData()
        }
      })
    } else if (sizeBreakdownTotal) {
      this.getSizeBreakdownTotal(() => {
        getData()
      })
    } else {
      getData()
    }
  }

  getPrapackOptimization() {
    this.setState({ progress: true })

    const { id, clientWithProgress } = this.props
    const { productId, scenarioId, runInfoId, clusterId } = this.state
    clientWithProgress
      .post(`/data/${id}/invoke/GtPrapackOptimization`, {
        data: { productId, scenarioId, runInfoId, clusterId }
      })
      .then((response) => {
        this.setState({ data: response, progress: false }, () => this.createReactTable())
      })
  }

  handleIncludeChange({ row }) {
    this.setState({ progress: true })

    const { id, clientWithProgress } = this.props || {}
    const { productId, data } = this.state
    const { original: { AssortyId } = {}, index: assortyIndex } = row

    const modifiedAssorty = { ...data[assortyIndex] }
    modifiedAssorty.Include = !modifiedAssorty.Include

    clientWithProgress
      .post(`/data/${id}/invoke/UpdateAssortyInclude`, {
        data: {
          include: modifiedAssorty.Include,
          productId,
          assortyId: AssortyId
        }
      })
      .then(() => {
        const modifiedData = [
          ...data.slice(0, assortyIndex),
          modifiedAssorty,
          ...data.slice(1 + assortyIndex)
        ]
        this.setState({ data: modifiedData, progress: false }, () => this.createReactTable())
      })
  }

  includeAll() {
    this.updateProductInclude(true)
  }

  excludeAll() {
    this.updateProductInclude(false)
  }

  updateProductInclude(value) {
    this.setState({ progress: true })

    const { id, clientWithProgress } = this.props || {}
    const { productId, data } = this.state
    const dataClone = _.cloneDeep(data)

    clientWithProgress
      .post(`/data/${id}/invoke/UpdateAllAssortiesInclude`, {
        data: {
          include: value,
          productId
        }
      })
      .then(() => {
        const modifiedData = _.forEach(dataClone, (item) => {
          item.Include = value
        })
        this.setState(
          {
            data: modifiedData,
            progress: false,
            allAssortiesAreIncluded: value
          },
          () => {
            this.createReactTable()
          }
        )
      })
  }

  onAssortyTypeIdChange(event) {
    this.setState({ assortyTypeId: event.value })
  }

  handleAssortName(event) {
    this.setState({ assortyName: event.target.value })
  }

  updateRevisedQTY(value, assortyObj) {
    if (!_.isNil(value) && _.isNumber(value) && value >= 0) {
      this.setState({ progress: true })

      const { id, clientWithProgress } = this.props || {}
      const { productId, data } = this.state
      const {
        row: {
          index: assortyIndex,
          original: { AssortyId }
        }
      } = assortyObj

      const modifiedAssorty = { ...data[assortyIndex] }
      modifiedAssorty.RevisedQuantity = value

      clientWithProgress
        .post(`/data/${id}/invoke/UpdateAssortyRevisedQTY`, {
          data: {
            revisedQTY: modifiedAssorty.RevisedQuantity,
            productId,
            assortyId: AssortyId
          }
        })
        .then(() => {
          const modifiedData = [
            ...data.slice(0, assortyIndex),
            modifiedAssorty,
            ...data.slice(1 + assortyIndex)
          ]
          this.setState({ data: modifiedData, progress: false }, () => this.createReactTable())
        })
    } else {
      slvyToast.warning({
        message: 'The revision quantity must be positive number',
        title: 'Invalid Value'
      })
    }
  }

  handleRevisedQTYBlur(event, cellInfo) {
    const value = _.toNumber(event.target.value)
    if (
      !_.isNil(event.target.value) &&
      value !== this.state.data[cellInfo.row.index][cellInfo.column.id]
    ) {
      this.updateRevisedQTY(_.toNumber(event.target.value), cellInfo)
    }
  }

  handleRevisedQTYKeyPress(event, cellInfo) {
    if (event.key === 'Enter') {
      const value = _.toNumber(event.target.value)
      if (
        !_.isNil(event.target.value) &&
        value !== this.state.data[cellInfo.row.index][cellInfo.column.id]
      ) {
        this.updateRevisedQTY(value, cellInfo)
      }
    }
  }

  isDisabled(isApproveShown, isApproved) {
    return isApproveShown ? isApproved : false
  }

  renderEditablePopup({
    column: { id: ColumnName },
    row: {
      index: RowIndex,
      original: { assorty: Assorty, AssortyId, LotName, ProductId, Type }
    }
  }) {
    const { isApproved, isApproveShown, data = [], isLockRevision = false } = this.state
    const rowData = data[RowIndex] || {}
    const value = rowData[ColumnName]

    const updateData = {
      Assorty,
      AssortyId,
      ColumnName,
      LotName,
      ProductId,
      RowIndex,
      Type
    }

    return (
      <div className="-editable-popup-cell">
        {value}{' '}
        {!isLockRevision && !this.isDisabled(isApproved, isApproveShown) && (
          <i
            className="-popup-trigger -cur-p slvy-ui-icon-edit"
            onClick={() => this.triggerPopupUpdateEvent(updateData)}
          />
        )}
      </div>
    )
  }

  renderEditable(cell) {
    const { isApproved, isApproveShown } = this.state
    const {
      row: { index },
      column: { id }
    } = cell
    const value = this.state.data[index][id]
    return (
      <RenderEditable
        cellInfo={cell}
        handleRevisedQTYBlur={this.handleRevisedQTYBlur}
        handleRevisedQTYKeyPress={this.handleRevisedQTYKeyPress}
        isDisabled={this.isDisabled(isApproved, isApproveShown)}
        progress={this.state.progress}
        value={value}
      />
    )
  }

  calculateAssortySizeTotal(index) {
    const { data = [] } = this.state
    let total = 0

    _.forEach(data[index], (value, propName) => {
      if (!_.includes(notSizeColumns, propName)) total += value
    })

    return total
  }

  calculateOneSizeOPTTotals(accessor) {
    const { data = [] } = this.state
    let total = 0

    _.forEach(data, (item) => {
      if (item.Include) {
        total += item.OptimalQuantity * item[accessor]
      }
    })

    return total
  }

  getSizeBreakdownTotal($callback) {
    const { id, clientWithProgress } = this.props
    const { productId, clusterId } = this.state

    this.setState({ progress: true })

    clientWithProgress
      .post(`/data/${id}/invoke/GetFinalBuyQuantitySummary`, {
        data: { productId, clusterId }
      })
      .then((response) => {
        if (!_.isEmpty(response)) {
          this.setState({ sizeBreakdownTotal: response, progress: false }, () => {
            $callback()
          })
        } else {
          $callback()
        }
      })
  }

  getSizeBreakdownTotalByName(accessor) {
    const { sizeBreakdownTotal } = this.state
    if (sizeBreakdownTotal.hasOwnProperty(accessor)) {
      return Math.round(sizeBreakdownTotal[accessor])
    }
    return accessor
  }

  getSizeBreakdownAllTotal() {
    const { sizeBreakdownTotal } = this.state
    let sum = 0
    Object.keys(sizeBreakdownTotal).forEach((key) => (sum += Math.round(sizeBreakdownTotal[key])))
    return sum
  }

  calculateOneSizeRevisedTotals(accessor) {
    const { data = [] } = this.state
    let total = 0

    _.forEach(data, (item) => {
      if (item.Include) {
        total += item.RevisedQuantity * item[accessor]
      }
    })

    return total
  }

  calculateAllSizesTotals(OPTOrRiv) {
    const { data = [] } = this.state

    let total = 0

    _.forEach(data, (item) => {
      let sizeTotal = 0
      _.forEach(item, (value, propName) => {
        if (!_.includes(notSizeColumns, propName) && item.Include) {
          sizeTotal += value
        }
      })
      total += item[OPTOrRiv] * sizeTotal
    })

    return total
  }

  calculateIncludedAssorties() {
    const { data = [] } = this.state

    const dataClone = _.cloneDeep(data)
    return _.filter(dataClone, (o) => {
      if (o.Include === true) return o
    }).length
  }

  applyConfig(columns) {
    const { settings: { config: { gridColumns = [] } = {} } = {} } = this.props

    return columns.reduce((acc, column) => {
      const current = gridColumns.find((gColumn) => gColumn.name === column.accessor)

      if (typeof current === 'undefined') {
        acc.push(column)
      } else {
        const { isVisible } = current
        const extraOptions = {}

        if (isVisible) {
          if (typeof current.displayName !== 'undefined') {
            extraOptions.Header = current.displayName
          }
          if (typeof current.isVisible !== 'undefined') {
            extraOptions.show = current.isVisible
          }
          if (typeof current.isFixed !== 'undefined') {
            const isProperDirection = ['left', 'right'].some(
              (direction) => direction === current.isFixed
            )
            if (isProperDirection) {
              extraOptions.fixed = current.isFixed
            }
          }
          if (typeof current.isFilterable !== 'undefined') {
            extraOptions.filterable = current.isFilterable
          }
          if (typeof current.width !== 'undefined') {
            extraOptions.width = current.width
          }

          acc.push({ ...column, ...extraOptions })
        }
      }
      return acc
    }, [])
  }

  createReactTable() {
    const { data, isApproved, isApproveShown } = this.state
    const {
      settings: {
        config: { settings: { popupUpdate = false, sizeBreakdownTotal = false } = {} } = {}
      } = {}
    } = this.props

    const includedAssorties = this.calculateIncludedAssorties()

    const style = { textAlign: 'center' }

    let permanentColumns = [
      {
        header: 'Prepack',
        accessorKey: 'assorty',
        filterFn: 'includesString',
        meta: {
          filterable: true
        },
        minSize: 220,
        left: 0,
        footer: (
          <div>
            <div>
              <strong>Optimization Totals</strong>
            </div>
            <div>
              <strong>Revised Totals</strong>
            </div>
            {sizeBreakdownTotal ? (
              <div>
                <strong>Size Breakdown Totals</strong>
              </div>
            ) : null}
          </div>
        )
      },
      {
        header: 'Type',
        minSize: 100,
        left: 220,
        accessorKey: 'Type',
        filterFn: mixedTypeFilter,
        meta: {
          filterable: true
        }
      },
      {
        header: 'Include',
        accessorKey: 'Include',
        minSize: 100,
        left: 320,
        sortType: 'basic',
        cell: (cell) => {
          return (
            <label className="-chk-include">
              <input
                checked={cell.getValue()}
                disabled={isApproveShown ? isApproved : false}
                style={{ margin: 'auto', display: 'block' }}
                type="checkbox"
                onChange={() => this.handleIncludeChange(cell)}
              />

              <i className="slvy-ui-icon-box_1" />
            </label>
          )
        },
        footer: (
          <div title={` ${includedAssorties} Included`}>
            <strong>{` ${includedAssorties} Included`}</strong>
          </div>
        )
      },
      {
        header: 'Opt Qty',
        minSize: 100,
        accessorKey: 'OptimalQuantity',
        left: 420
      },
      {
        header: 'Rev Qty',
        minSize: 100,
        left: 520,
        accessorKey: 'RevisedQuantity',
        style: {
          ...style,
          position: 'sticky',
          left: 496,
          top: 0,
          zIndex: 11,
          borderRight: '1px solid #ccc'
        },
        cell: popupUpdate ? this.renderEditablePopup : this.renderEditable
      }
    ]

    permanentColumns = this.applyConfig(permanentColumns)

    const tableColumns = [
      ...permanentColumns,
      ..._.transform(
        data[0],
        (result, item, index) => {
          if (!_.includes(notSizeColumns, index)) {
            result.push({
              header: index,
              accessorKey: index,
              filterFn: 'equalsString',
              meta: {
                filterable: true
              },
              minSize: 75,
              size: 75,
              style,
              footer: (
                <div>
                  <div>{this.calculateOneSizeOPTTotals(index)}</div>
                  <div>{this.calculateOneSizeRevisedTotals(index)}</div>
                  {sizeBreakdownTotal ? <div>{this.getSizeBreakdownTotalByName(index)}</div> : null}
                </div>
              )
            })
          }
        },
        []
      ),
      {
        header: 'Total',
        id: 'total',
        minSize: 100,
        filterFn: (row, _id, filterValue) =>
          String(this.calculateAssortySizeTotal(row.index)) === filterValue,
        meta: {
          filterable: true
        },
        style: { ...style, position: 'sticky', right: 0 },
        cell: (cellInfo) => (
          <input
            readOnly
            className="-react-table-input"
            type="text"
            value={this.calculateAssortySizeTotal(cellInfo.row.index)}
          />
        ),
        footer: () => (
          <div>
            <div>{this.calculateAllSizesTotals('OptimalQuantity')}</div>
            <div>{this.calculateAllSizesTotals('RevisedQuantity')}</div>
            {sizeBreakdownTotal ? <div>{this.getSizeBreakdownAllTotal()}</div> : null}
          </div>
        )
      }
    ]

    this.setState({ columns: tableColumns })
  }

  executeApprove() {
    const { settings: { config: { general: { isApproveExternal = false } = {} } = {} } = {} } =
      this.props
    if (this.state.isApproveShown) {
      if (!isApproveExternal) {
        this.setState({ isApproved: true }, () => {
          this.createReactTable()
        })
      }
    }
  }

  getStickyColumnStyle(left, header, index) {
    if (index < 5) {
      if (header === 'Rev Qty') return { borderRightColor: '#ccc', left }
      return { left }
    }
    if (header === 'Total') return { right: 0 }
  }

  getStickyColumnClass(header, index) {
    if (index > 4 && header !== 'Total')
      return 'd-flex justify-content-center align-items-center ipo-grid-cell'
    return `ipo-grid-sticky-column ipo-grid-column ipo-grid-cell`
  }

  render() {
    const {
      settings: {
        config: {
          general: { isApproveExternal = false, approveButtonText = 'Approve' } = {},
          settings: {
            getPrepackAsEvent = false,
            paginationActivation = true,
            testMode = false
          } = {}
        } = {}
      } = {}
    } = this.props

    const {
      allAssortiesAreIncluded = false,
      columns,
      data,
      defaultSorted,
      getPrepackButtonActivationState,
      isApproveDisable: approveDisable,
      isApproveShown,
      isApproved,
      optimizeState,
      progress,
      title
    } = this.state

    const isDataEmpty = _.isEmpty(data)
    const isDisable = isDataEmpty || isApproved
    const isApproveDisable = isApproveExternal ? approveDisable : isDisable || optimizeState

    return (
      <div className={`ipo-prepack-plugin ${isDataEmpty ? '-empty-data' : ''}`}>
        <section className="slvy-ui-header">
          <section className="-title-left">
            <div className="pageTitle">{title}</div>
          </section>
          <section className="-title-right">
            <span className="-button-container">
              <button
                className="btns_button"
                disabled={!getPrepackButtonActivationState}
                type="button"
                onClick={
                  testMode
                    ? this.setPrepackDataOnClick
                    : getPrepackAsEvent
                    ? this.GetPrepackClickedEvent
                    : this.setPrepackData
                }
              >
                <i className="slvy-ui-icon-boxes" />
                <span>Get Prepack</span>
              </button>
              <button
                className="btns_button"
                disabled={isDisable}
                onClick={this.triggerConstraintsEvent}
              >
                <i className="slvy-ui-icon-constraint" />
                <span>Constraints</span>
              </button>

              {!allAssortiesAreIncluded && (
                <button className="btns_button" disabled={isDisable} onClick={this.includeAll}>
                  <i className="slvy-ui-icon-import" />
                  <span>Include All</span>
                </button>
              )}

              {allAssortiesAreIncluded && (
                <button className="btns_button" disabled={isDisable} onClick={this.excludeAll}>
                  <i className="slvy-ui-icon-export-regular" />
                  <span>Exclude All</span>
                </button>
              )}

              <button
                className="btns_button"
                disabled={isDisable}
                onClick={this.prepackOptimizationEvent}
              >
                <i className="slvy-ui-icon-cog" />
                <span>Optimize</span>
              </button>

              <button
                className="btns_button"
                disabled={isDisable}
                onClick={this.createNewAssortyEvent}
              >
                <i className="slvy-ui-icon-plus-outline" />
                <span>Create</span>
              </button>

              {isApproveShown ? (
                <button
                  className="btns_button"
                  disabled={isApproveDisable}
                  onClick={this.triggerApproveEvent.bind(this)}
                >
                  <i className="slvy-ui-icon-snapshot-approve-regular" />
                  <span>{isApproveExternal ? approveButtonText : 'Approve'}</span>
                </button>
              ) : null}
            </span>
          </section>
        </section>

        <div className="-table-wrapper">
          <SlvyTable
            footer
            suppressColumnMenu
            className="react-table-element"
            columns={columns}
            data={data}
            defaultColumn={{ sortable: true }}
            getBodyClassName="ipo-grid-body"
            getCellClassName={(
              {
                column: {
                  columnDef: { header }
                }
              },
              index
            ) => this.getStickyColumnClass(header, index)}
            getCellStyle={(
              {
                column: {
                  columnDef: { left, header }
                }
              },
              index
            ) => this.getStickyColumnStyle(left, header, index)}
            getFooterClassName="ipo-grid-foot"
            getFooterHeaderClassName={(
              {
                column: {
                  columnDef: { header }
                }
              },
              index
            ) => {
              if (index > 4 && header !== 'Total') return
              return 'ipo-grid-sticky-column'
            }}
            getHeadClassName="ipo-grid-head"
            getHeaderClassName={(
              {
                column: {
                  columnDef: { header }
                }
              },
              index
            ) => {
              if (index > 4 && header !== 'Total') return
              return 'ipo-grid-sticky-column'
            }}
            getHeaderStyle={(
              {
                column: {
                  columnDef: { left, header }
                }
              },
              index
            ) => this.getStickyColumnStyle(left, header, index)}
            getFooterHeaderStyle={(
              {
                column: {
                  columnDef: { left, header }
                }
              },
              index
            ) => this.getStickyColumnStyle(left, header, index)}
            getRowClassName={(row) =>
              `ipo-grid-row ${row.index % 2 === 0 ? 'ipo-grid-odd-row' : ''}`
            }
            pagination={paginationActivation}
            paginationOpts={{
              showPageSizeOptions: true
            }}
            themeOpts={{
              striped: false
            }}
          />
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  settings: props.settings,
  onReady: props.onReady,
  id: props.id,
  clientWithProgress: props.clientWithProgress
})

export default createPlugin(IPOPrepack, selectConnectorProps)
