export default `({
    series: [{
        dataIndex: 'QTY',
        type: 'column',
        name: tt('Satis Adedi'),
        color: 'rgba(236,17,92,1)',
        listeners: {
            //  pointclick: me.onClickPieChart
        }
    }, ]
})
`
