Ext.define('po.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.mainController',

  init: function () {
    this.view.onInitializeRef(this)
  },

  // button methods
  onRunReport: function (btn) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore')
    if (btn && btn.itemId) viewModel.set('viewMode', btn.itemId)
    mainStore.load()
  },

  onCancelChanges: function () {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      i18n = viewModel.get('i18n'),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      data = mainStore.getDatas(),
      selectedRecords = data.filter(function (item) {
        if (item.selected) return item
      })

    var title = i18n.toolbar_btnIptal_msg_title,
      msg = i18n.toolbar_btnIptal_msg_text,
      yes = i18n.msg_buttonYes_text,
      no = i18n.msg_buttonNo_text

    if (mainStore.isDirty()) {
      Ext.Msg.show({
        title: title,
        msg: msg,
        buttons: Ext.Msg.YESNO,
        buttonText: {
          yes: yes,
          no: no
        },
        fn: function (btn) {
          if (btn === 'yes') {
            mainStore.suspendEvents()
            var modifiedRecords = mainStore.getModifiedRecords()
            selectedRecords.forEach((element) => {
              element.selected = false
            })
            mainStore.rejectChanges()
            mainStore.resumeEvents()
            modifiedRecords.forEach(function (record) {
              gridView.refreshNode(record)
            })
            viewModel.set('selectedRowCount', 0)
            viewModel.set('checkedSAPCount', 0)
            viewModel.set('storeChanged', Ext.id())
          }
        }
      })
    }
  },

  onSaveChanges: function () {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      i18n = viewModel.get('i18n'),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      data = mainStore.getDatas(),
      selectedRecords = data.filter(function (item) {
        if (item.selected) return item
      })

    var title = i18n.toolbar_btnOnay_msg_title,
      msg = i18n.toolbar_btnKaydet_msg_text,
      yes = i18n.msg_buttonYes_text,
      no = i18n.msg_buttonNo_text,
      eventText = i18n.toolbar_btnSAPaGonder_event_text

    if (mainStore.isDirty()) {
      Ext.Msg.show({
        title,
        msg,
        buttonText: {
          yes,
          no
        },
        buttons: Ext.Msg.YESNO,
        fn: function (btn) {
          if (btn === 'yes') {
            if (mainStore.isDirty()) {
              mainStore.suspendEvents()
              Ext.getBody().mask(eventText, 'splashscreen')
              var modifiedRecords = mainStore.getModifiedRecords()
              selectedRecords.forEach((element) => {
                element.selected = false
              })
              var mainStoreParams = mainStore.getProxy().getExtraParams()
              mainStore.getProxy().setExtraParams()

              mainStore.sync({
                callback: function () {
                  mainStore.resumeEvents()
                  modifiedRecords.forEach(function (record) {
                    gridView.refreshNode(record)
                  })
                  mainStore.commitChanges()
                  mainStore.fireEvent('write')
                  mainStore.getProxy().setExtraParams(mainStoreParams)
                }
              })
            }
          }
        }
      })
    }
  },

  onSendToSAP: function () {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      i18n = viewModel.get('i18n'),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView()

    var title = i18n.toolbar_btnSAPaGonder_msg_title,
      msg = i18n.toolbar_btnSAPaGonder_msg_text,
      yes = i18n.msg_buttonYes_text,
      no = i18n.msg_buttonNo_text,
      eventText = i18n.toolbar_btnSAPaGonder_event_text

    var checkedSAPCount = viewModel.get('checkedSAPCount')

    if (checkedSAPCount == 0) return

    Ext.Msg.show({
      title: title,
      msg: msg,
      buttons: Ext.Msg.YESNO,
      buttonText: {
        yes: yes,
        no: no
      },
      fn: function (btn) {
        if (btn === 'yes') {
          mainStore.suspendEvents()

          var modifiedRecords = mainStore.getModifiedRecords()
          mainStore.each(function (record) {
            if (record.get('isSendToSap')) {
              record.set('SAPYollandi', 1)
            }
          })
          Ext.getBody().mask(eventText, 'splashscreen')

          var mainStoreParams = mainStore.getProxy().getExtraParams()
          mainStore.getProxy().setExtraParams()

          mainStore.sync({
            success: function () {
              viewModel.set('checkedSAPCount', 0)
            },
            callback: function () {
              mainStore.resumeEvents()
              modifiedRecords.forEach(function (record) {
                gridView.refreshNode(record)
              })
              mainStore.commitChanges()
              mainStore.fireEvent('write')
              mainStore.getProxy().setExtraParams(mainStoreParams)
            }
          })
        }
      }
    })
  },

  // group events

  onFilterFromGroupGrid: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      store = grid.store

    var isFiltered = viewModel.get('isFiltered')

    if (isFiltered) {
      store.clearFilter()

      mainStore.clearFilter()
      viewModel.set('isFiltered', false)
    } else {
      var filterColumn = viewModel.get('selectedGroupByField')
      var filterValue = record.get('Name')

      if (filterValue == '' || filterValue == null) return

      mainStore.clearFilter()
      mainStore.filter(filterColumn, filterValue)

      store.clearFilter()
      store.filter('Name', filterValue)

      viewModel.set('isFiltered', true)
    }

    store.clearData()
    viewModel.set('storeChanged', Ext.id())
  },

  onAcceptFromGroupGrid: function (grid, rowIndex) {
    var me = this,
      viewModel = me.getViewModel(),
      record = grid.getStore().getAt(rowIndex),
      mainStore = viewModel.getStore('mainStore'),
      i18n = viewModel.get('i18n'),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    var title = i18n.toolbar_btnOnay_msg_title,
      msg = i18n.toolbar_btnOnay_msg_text,
      yes = i18n.msg_buttonYes_text,
      no = i18n.msg_buttonNo_text

    var filterColumn = viewModel.get('selectedGroupByField')
    var filterValue = record.get('Name')

    Ext.Msg.show({
      title: title,
      msg: msg,
      buttons: Ext.Msg.YESNO,
      buttonText: {
        yes: yes,
        no: no
      },
      fn: function (btn) {
        if (btn === 'yes') {
          mainStore.suspendEvents()
          mainStore.each(function (record) {
            if (record.get(filterColumn) == filterValue) {
              record.set('onayFlag', 1)
            }
          }, this)
          mainStore.resumeEvents()
          gridView.refresh()
          viewModel.set('storeChanged', Ext.id())
        }
      }
    })
  },

  onGroupClearFilter: function () {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      groupStore = viewModel.getStore('groupStore')

    mainStore.clearFilter()
    groupStore.clearFilter()
    viewModel.set('isFiltered', false)
    viewModel.set('storeChanged', Ext.id())
  },

  onGroupViewChanged: function () {
    var me = this,
      viewModel = me.getViewModel()

    var groupActiveItem = viewModel.get('groupActiveItem')

    if (groupActiveItem == 1) viewModel.set('groupActiveItem', 0)
    else {
      viewModel.set('groupActiveItem', 1)
    }
  },

  // main grid actions
  onMainGridApproveChange: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    mainStore.suspendEvents()

    var value = !record.get('onayFlag')
    record.set('onayFlag', value)

    if (value == 0) {
      record.set('SAPYollandi', value)

      if (record.get('isSendToSap')) {
        const checkedSAPCount = viewModel.get('checkedSAPCount')
        viewModel.set('checkedSAPCount', checkedSAPCount - 1)

        record.set('isSendToSap', false)
      }
    }

    mainStore.resumeEvents()
    grid.refreshNode(record)
    viewModel.set('storeChanged', Ext.id())
  },

  onMainSAPVendorApprovalChange: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    mainStore.suspendEvents()

    var value = !record.get('SAPVendorApproval')
    record.set('SAPVendorApproval', value)

    mainStore.resumeEvents()
    grid.refreshNode(record)
    viewModel.set('storeChanged', Ext.id())
  },

  onMainGridSAPChange: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      checkedSAPCount = viewModel.get('checkedSAPCount'),
      buttonsStatus = viewModel.get('buttonsStatus'),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    var value = !record.get('isSendToSap')
    if (value) {
      checkedSAPCount++
    } else {
      checkedSAPCount--
    }
    if (checkedSAPCount > 0) {
      if (buttonsStatus.sapButtonEnable != true) {
        buttonsStatus.sapButtonEnable = true
        view.fireEvent('buttonsStatusChange', buttonsStatus)
        viewModel.set('buttonsStatus', buttonsStatus)
      }
    } else {
      buttonsStatus.sapButtonEnable = false
      view.fireEvent('buttonsStatusChange', buttonsStatus)
      viewModel.set('buttonsStatus', buttonsStatus)
    }

    viewModel.set('checkedSAPCount', checkedSAPCount)
    mainStore.suspendEvents()
    if (record.get('selected')) record.set('selected', false)
    record.set('isSendToSap', value)
    mainStore.resumeEvents()
    grid.refreshNode(record)

    viewModel.set('storeChanged', Ext.id())
  },

  onMainSelectChange: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      viewModel = me.getViewModel(),
      view = me.getView(),
      mainStore = viewModel.getStore('mainStore'),
      records = mainStore.getDatas(),
      selectedRowCount = viewModel.get('selectedRowCount'),
      buttonsStatus = viewModel.get('buttonsStatus'),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    var value = !record.get('selected')
    if (value) {
      selectedRowCount++
    } else {
      selectedRowCount--
    }
    if (selectedRowCount > 0) {
      if (buttonsStatus.massUpdateButtonEnable !== true) {
        buttonsStatus.massUpdateButtonEnable = true
        view.fireEvent('buttonsStatusChange', buttonsStatus)
        viewModel.set('buttonsStatus', buttonsStatus)
      }
    } else {
      buttonsStatus.massUpdateButtonEnable = false
      view.fireEvent('buttonsStatusChange', buttonsStatus)
      viewModel.set('buttonsStatus', buttonsStatus)
    }

    viewModel.set('selectedRowCount', selectedRowCount)
    viewModel.set('selectChange', Ext.id())
    mainStore.suspendEvents()
    records.forEach((element) => {
      if (element.ID == record.get('ID')) {
        element.selected = value
      }
    })
    mainStore.resumeEvents()
    grid.refreshNode(record)
  },
  onMainGridRowSelectSet: null,
  onMainGridRowSelect: function (grid, record) {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      degisenOneriKoli = record.get('degisenOneriKoli'),
      sebepKodu = record.get('sebepKodu')

    clearTimeout(this.onMainGridRowSelectSet)

    this.onMainGridRowSelectSet = setTimeout(() => {
      viewModel.set('selectedRecord', record)
      viewModel.set('editorDegisenOneriKoli', degisenOneriKoli)
      viewModel.set('editorSebepKodu', sebepKodu)

      view.fireEvent('MainGridRowSelected', record)

      me.reloadInventoryStore()
    }, 400)
  },

  onMainGridBeforeRowSelect: function () {
    // secili olanı bul reason kod yoksa uyarı cıkar ve return false değilse true
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      selectedRecord = viewModel.get('selectedRecord'),
      reasonCodeEnabled = viewModel.get('reasonCodeEnabled'),
      i18n = viewModel.get('i18n'),
      alertText = null,
      alertTitle = null

    if (i18n) {
      alertText = i18n.RowSelect_SebepKodu_Warning_Text
      alertTitle = i18n.RowSelect_SebepKodu_Warning_Title
    }

    if (selectedRecord == null) return true

    if (
      reasonCodeEnabled &&
      selectedRecord.isDirty() &&
      selectedRecord.modified &&
      selectedRecord.modified.degisenOneriKoli &&
      selectedRecord.get('isReasonCodeRequiredOnChange') &&
      selectedRecord.get('sebepKodu') == 0
    ) {
      Ext.MessageBox.alert(alertTitle, alertText)
      return false
    }
    return true
  },

  onMainGridAfterLayout: function (grid) {
    var me = this,
      view = grid.getView(),
      viewModel = me.getViewModel()

    viewModel.set('rendered', true)
  },

  onMainGridCellClick: function (view, td, cellIndex, record) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore')
    var clickedDataIndex = view.panel.headerCt.getHeaderAtIndex(cellIndex).dataIndex
    if (clickedDataIndex === 'depoAcikSiparis' && record.get('depoAcikSiparis') > 0) {
      me.openSasWindow(record, viewModel, me)
    } else if (
      clickedDataIndex === 'depoTumAcikSiparisler' &&
      record.get('depoTumAcikSiparisler') > 0
    ) {
      me.openAllSasWindow(record, viewModel, me)
    } else if (clickedDataIndex === 'magazaAcikSiparis' && record.get('magazaAcikSiparis') > 0) {
      me.openOrderStoreWindow(record, viewModel, me)
    } else if (
      clickedDataIndex === 'SifirStokluMagazaSayisi' &&
      record.get('SifirStokluMagazaSayisi') > 0
    ) {
      me.openZeroInventoryWindow(record, viewModel, me)
    } else if (
      clickedDataIndex === 'BlokajliMagazaSayisi' &&
      record.get('BlokajliMagazaSayisi') > 0
    ) {
      me.openBlockagedStoreWindow(record, viewModel, me)
    } else if (
      clickedDataIndex === 'birSonrakiSipariseKadarkiMagazaTalebi' &&
      record.get('birSonrakiSipariseKadarkiMagazaTalebi') > 0
    ) {
      me.futureOrderStoreWindow(record, viewModel, me)
    } else if (clickedDataIndex === 'PromosyonDurumu' && record.get('PromosyonDurumu') == 'VAR') {
      me.promotionsDetailWindow(record, viewModel, me)
    }
  },

  clickToDrillDown: function (clickedDataIndex) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      record = viewModel.get('selectedRecord')

    if (clickedDataIndex === 'depoAcikSiparis' && record.get('depoAcikSiparis') > 0) {
      me.openSasWindow(record, viewModel, me)
    } else if (
      clickedDataIndex === 'birSonrakiSipariseKadarkiMagazaTalebi' &&
      record.get('birSonrakiSipariseKadarkiMagazaTalebi') > 0
    ) {
      me.futureOrderStoreWindow(record, viewModel, me)
    } else if (clickedDataIndex === 'magazaAcikSiparis' && record.get('magazaAcikSiparis') > 0) {
      me.openOrderStoreWindow(record, viewModel, me)
    }
  },

  onMainGridDrillDown: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      view = me.getView()

    view.fireEvent('drillDown', record)
  },

  onMainGridCalendarEvent: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      view = me.getView()

    view.fireEvent('calendarEvent', record)
  },

  exportGrid: function (btn, tool, header) {
    var me = this,
      window = header.up(),
      grid = window.items.items[0]
    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }
    var title = window.title
    title = title.replace('<span style="color:yellow;">', '')
    title = title.replace('</span>', '')
    title = title.replace(':', '')

    var newcfg = Ext.merge(
      {
        title: title,
        fileName: title + '.' + cfg.ext
      },
      btn.cfg
    )
    grid.saveDocumentAs(newcfg)
  },

  onMainGridDrilldownStoreDetail: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('storeDetailStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null
    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        i18n.Window_Store_Detail_Title +
        ' : ' +
        record.get('urunKodu') +
        '-' +
        record.get('urunAciklamasi')
    }

    grid.getSelectionModel().select(rowIndex)
    viewModel.set('selectedRecord', record)

    if (!me.drilldownStoreDetailCmp) {
      me.drilldownStoreDetailCmp = Ext.create('Ext.window.Window', {
        title: record.get('urunKodu') + '-' + record.get('urunAciklamasi'),
        modal: true,
        closeAction: 'hide',
        closeToolText: closeToolText,
        resizable: false,
        ui: 'window-inverse',
        maximizable: false,
        maximized: true,
        bodyPadding: 4,
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        layout: 'fit',
        items: [
          {
            xtype: 'postoredetail',
            border: true,
            store: store
          }
        ],
        fitContainer: function (animate) {
          var me = this,
            parent = me.floatParent,
            container = false ? parent.getTargetEl() : me.container,
            newBox = container.getViewSize(),
            newPosition =
              parent || container.dom !== document.body
                ? // If we are a contained floater, or rendered to a div, maximized position is (0,0)
                  [0, 0]
                : // If no parent and rendered to body, align with origin of container el.
                  container.getXY()

          newBox.x = newPosition[0]
          newBox.y = newPosition[1]
          me.setBox(newBox, animate)
        }
      })
      me.getView().add(me.drilldownStoreDetailCmp)
      me.drilldownStoreDetailCmp.show()
    } else {
      me.drilldownStoreDetailCmp.show()
    }

    me.drilldownStoreDetailCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))
    store.load()
  },

  openSasWindow: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('openSasStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null

    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        i18n.Window_Open_Sas_Title +
        ' : ' +
        '<span style="color:yellow;">' +
        record.get('urunKodu') +
        '-' +
        record.get('urunAciklamasi') +
        '</span>'
    }

    if (!me.openSasWindowCmp) {
      me.openSasWindowCmp = Ext.create('Ext.window.Window', {
        closeToolText: closeToolText,
        modal: true,
        closeAction: 'hide',
        ui: 'window-inverse',
        resizable: false,
        bodyPadding: 4,
        layout: 'fit',
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        items: [
          {
            xtype: 'opensas',
            border: true,
            columnLines: true,
            store: store
          }
        ]
      })
      me.getView().add(me.openSasWindowCmp)
      me.openSasWindowCmp.show()
    } else {
      me.openSasWindowCmp.show()
    }

    me.openSasWindowCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))

    store.load()
  },

  openAllSasWindow: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('openAllSasStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null

    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        'DC Open All Orders : ' +
        '<span style="color:yellow;">' +
        record.get('urunKodu') +
        '-' +
        record.get('urunAciklamasi') +
        '</span>'
    }

    if (!me.openAllSasWindowCmp) {
      me.openAllSasWindowCmp = Ext.create('Ext.window.Window', {
        closeToolText: closeToolText,
        modal: true,
        closeAction: 'hide',
        ui: 'window-inverse',
        resizable: false,
        bodyPadding: 4,
        layout: 'fit',
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        items: [
          {
            xtype: 'depotumaciksiparis',
            border: true,
            columnLines: true,
            store: store
          }
        ]
      })
      me.getView().add(me.openAllSasWindowCmp)
      me.openAllSasWindowCmp.show()
    } else {
      me.openAllSasWindowCmp.show()
    }

    me.openAllSasWindowCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))

    store.load()
  },

  openOrderStoreWindow: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('openOrderStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null

    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        i18n.Window_Open_Order_Store_Title +
        ' : ' +
        '<span style="color:yellow;">' +
        record.get('urunKodu') +
        '-' +
        record.get('urunAciklamasi') +
        '</span>'
    }

    if (!me.openOrderStoreWindowCmp) {
      me.openOrderStoreWindowCmp = Ext.create('Ext.window.Window', {
        closeToolText: closeToolText,
        modal: true,
        closeAction: 'hide',
        resizable: false,
        ui: 'window-inverse',
        width: 680,
        layout: 'fit',
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        items: [
          {
            xtype: 'openorderstore',
            border: true,
            columnLines: true,
            store: store
          }
        ]
      })
      me.getView().add(me.openOrderStoreWindowCmp)
      me.openOrderStoreWindowCmp.show()
    } else {
      me.openOrderStoreWindowCmp.show()
    }

    me.openOrderStoreWindowCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))
    store.load()
  },

  openZeroInventoryWindow: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('zeroInventoryStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null

    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        i18n.Window_ZeroInventory_Title +
        ' : ' +
        '<span style="color:yellow;">' +
        record.get('urunKodu') +
        ' - ' +
        record.get('urunAciklamasi') +
        '</span>'
    }

    if (!me.zeroInventoryWindowCmp) {
      me.zeroInventoryWindowCmp = Ext.create('Ext.window.Window', {
        closeToolText: closeToolText,
        modal: true,
        closeAction: 'hide',
        resizable: false,
        ui: 'window-inverse',
        bodyPadding: 4,
        layout: 'fit',
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        items: [
          {
            xtype: 'zeroInventory',
            border: true,
            columnLines: true,
            store: store
          }
        ]
      })
      me.getView().add(me.zeroInventoryWindowCmp)
      me.zeroInventoryWindowCmp.show()
    } else {
      me.zeroInventoryWindowCmp.show()
    }

    me.zeroInventoryWindowCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))
    store.load()
  },

  openBlockagedStoreWindow: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('blockagedStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null

    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        'Blockaged Store : ' +
        '<span style="color:yellow;">' +
        record.get('urunKodu') +
        ' - ' +
        record.get('urunAciklamasi') +
        '</span>'
    }

    if (!me.blockagedWindowCmp) {
      me.blockagedWindowCmp = Ext.create('Ext.window.Window', {
        closeToolText: closeToolText,
        modal: true,
        closeAction: 'hide',
        resizable: false,
        ui: 'window-inverse',
        bodyPadding: 4,
        layout: 'fit',
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        items: [
          {
            xtype: 'blockagedstores',
            border: true,
            columnLines: true,
            store: store
          }
        ]
      })
      me.getView().add(me.blockagedWindowCmp)
      me.blockagedWindowCmp.show()
    } else {
      me.blockagedWindowCmp.show()
    }

    me.blockagedWindowCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))
    store.load()
  },

  futureOrderStoreWindow: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('futureOrderStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null

    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        i18n.Window_FutureOrder_Title + ' (' + record.get('RevPeriod') + ' ' + i18n.Gunluk + ')'
    }

    if (!me.FutureOrderWindowCmp) {
      me.FutureOrderWindowCmp = Ext.create('Ext.window.Window', {
        closeToolText: closeToolText,
        modal: true,
        closeAction: 'hide',
        resizable: false,
        ui: 'window-inverse',
        layout: 'fit',
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        items: [
          {
            xtype: 'futureorder',
            border: true,
            columnLines: true,
            store: store
          }
        ]
      })
      me.getView().add(me.FutureOrderWindowCmp)
      me.FutureOrderWindowCmp.show()
    } else {
      me.FutureOrderWindowCmp.show()
    }

    me.FutureOrderWindowCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))
    if (record.get('RevPeriod')) {
      proxy.setExtraParam('revPeriod', record.get('RevPeriod'))
    }
    store.load()
  },

  promotionsDetailWindow: function (record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('promotionStore'),
      i18n = viewModel.get('i18n'),
      closeToolText = null,
      windowTitle = null

    if (i18n) {
      closeToolText = i18n.dialog_CloseToolText
      windowTitle =
        i18n.Window_Promotions_Title +
        ' : ' +
        '<span style="color:yellow;">' +
        record.get('urunKodu') +
        '-' +
        record.get('urunAciklamasi') +
        '</span>'
    }

    if (!me.promotionsDetailWindowCmp) {
      me.promotionsDetailWindowCmp = Ext.create('Ext.window.Window', {
        closeToolText: closeToolText,
        modal: true,
        closeAction: 'hide',
        resizable: false,
        ui: 'window-inverse',
        layout: 'fit',
        tools: [
          {
            type: 'save',
            handler: 'exportGrid'
          }
        ],
        items: [
          {
            xtype: 'PromotionsDetailsGrid',
            border: true,
            columnLines: true,
            store: store
          }
        ]
      })
      me.getView().add(me.promotionsDetailWindowCmp)
      me.promotionsDetailWindowCmp.show()
    } else {
      me.promotionsDetailWindowCmp.show()
    }

    me.promotionsDetailWindowCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))
    store.load()
  },

  onHandleColumnChanged() {
    var grid = this.lookupReference('MainGrid')
    if (grid) {
      var groupingColumn = grid.store.getGroupField()

      var columns = grid.getColumns()

      var state = grid.getState()
      if (state.storeState && state.storeState.sorters) {
        var sorters = grid.getState().storeState.sorters
      }

      var filters = this.getActualFilters(grid)

      if (columns) {
        this.saveColumnState(columns, groupingColumn, sorters, filters, state, grid)
      }
    }
  },

  onHandleUnlockColumn() {
    var grid = this.lookupReference('MainGrid'),
      gridView = grid.getView()
    if (grid && gridView) {
      // WorkAround:
      // Problem: User unlocks a column, sparkline column go blank
      // Solution: Refresh the grid view after unlocking
      gridView.refresh()
    }
    // Save plugin state
    this.onHandleColumnChanged()
  },

  saveColumnState(columnsSettings, groupingColumn, sorters, filters, gridState, grid) {
    var viewModel = this.getViewModel(),
      columnStates = viewModel.get('columnStates')
    if (viewModel.get('rendered')) {
      var columnConfigs = grid.columnManager.columns
      // Prepare actual column states
      var columnStates = columnConfigs.map((columnConfig, index) => {
        var columnSettings =
          columnsSettings.find(function (o) {
            if (o.columnId == columnConfig.columnId) {
              o.locked = o.isLocked()
              return o
            }
          }) || {}

        var columnOrder = columnsSettings.findIndex(function (s) {
          if (s.columnId == columnConfig.columnId) return index
        })

        var { text, width, hidden, hiddenAncestor, locked, columnId } = columnSettings

        var id = columnSettings.headerId

        var groupHeader = gridState.columns.find((groupColumn) => {
          if (groupColumn.columns) {
            var group = groupColumn.columns.find(function (subColumn) {
              if (subColumn.id == id) return groupColumn
            })
            if (group) return group
          }
        })

        var columnState = {
          name: text,
          configIndex: index,
          columnOrder: columnOrder == -1 ? 0 : columnOrder,
          columnId: columnId,
          locked: locked
        }

        if (columnConfig) {
          if (width != null) {
            columnState.width = width
          }

          if (hidden || hiddenAncestor) {
            columnState.hidden = true
          } else if (columnConfig.hidden) {
            // Save the hidden = false state only if the column is is hidden in the config
            columnState.hidden = false
          }

          if (groupHeader) {
            if (groupHeader.hasOwnProperty('locked')) {
              columnState.locked = groupHeader.locked
            } else {
              columnState.locked = locked
            }
          }

          if (columnSettings.isSubHeader && columnSettings.ownerCt) {
            columnState.groupHeader = columnSettings.ownerCt.text
          }
        }
        return columnState
      })

      var pluginInstanceId = viewModel.get('pluginInstanceId'),
        hash = viewModel.get('hash')
      var pluginState = {
        columnStates: columnStates,
        stateHash: hash,
        groupingColumn: groupingColumn
      }

      localStorage.removeItem('ColumnsState' + pluginInstanceId)
      localStorage.setItem('ColumnsState' + pluginInstanceId, JSON.stringify(pluginState))

      viewModel.set('pluginState', pluginState)
      this.savePluginstate(pluginState)
    }
  },
  resetColumnsState() {
    this.savePluginstate('')
  },
  savePluginstate(pluginState) {
    var viewModel = this.getViewModel()
    var pluginInstanceId = viewModel.get('pluginInstanceId'),
      catalogId = viewModel.get('catalogId')

    if (!viewModel.get('upsertInProgress')) {
      viewModel.set('upsertInProgress', true)

      Ext.Ajax.request({
        url: po.Global.getPluginApiUrl() + '/pluginstateupsert',
        contentType: 'application/json',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        params: JSON.stringify({
          name: 'po state ' + pluginInstanceId,
          pluginId: pluginInstanceId,
          catalogId: catalogId,
          config: {
            state: pluginState,
            stateId: 1
          }
        }),
        success: function (data) {
          viewModel.set('upsertInProgress', false)
        },
        failure: function () {
          viewModel.set('upsertInProgress', false)
        }
      })
    }
  },

  getActualFilters(grid) {
    if (grid) {
      var filters = grid.store.getFilters()
      var conditions = filters.items.forEach((filter) => {
        return {
          fieldName: filter._property,
          condition: {
            [filter._operator]: filter._value
          }
        }
      })
      return conditions
    }
  },

  // inventory
  reloadInventoryStore: function () {
    var me = this,
      viewModel = me.getViewModel(),
      inventoryStore = viewModel.getStore('inventoryStore')

    inventoryStore.removeAll()
    inventoryStore.load()

    // TODO : fix me please salih
    var roundUpStore = viewModel.getStore('roundUpStore')
    roundUpStore.load()
  },

  // main store events
  onMainStoreUpdate: function (store, record, operation, modifiedFieldNames) {
    var me = this,
      viewModel = me.getViewModel()

    if (modifiedFieldNames != null) {
      if (
        modifiedFieldNames.includes('Aciklamalar') ||
        modifiedFieldNames.includes('sebepKodu') ||
        modifiedFieldNames.includes('devliveryDate')
      ) {
        viewModel.set('recordChanged', Ext.id())
        return
      }
    }
    viewModel.set('storeChanged', Ext.id())

    if (modifiedFieldNames && modifiedFieldNames.indexOf('degisenOneriKoli') > -1) {
      var selectedRecord = viewModel.get('selectedRecord')
      if (record.get('ID') == selectedRecord.get('ID')) {
        me.reloadInventoryStore()
      }
    }
  },

  onMainStoreLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      mainGrid = me.lookupReference('MainGrid'),
      i18n = viewModel.get('i18n'),
      indices = null,
      indicesArray = []

    if (store.data.autoSource) {
      indices = store.data.autoSource.getIndices()
      indicesArray = Object.keys(indices)
    }

    if (indicesArray.length > 0) {
      if (store.getDatas().length == 0) {
        if (mainGrid.view.lockedGrid.view) {
          mainGrid.view.lockedGrid.view.emptyText = i18n.MainGrid_EmptyText
        }

        if (mainGrid.view.normalView) {
          mainGrid.view.normalView.emptyText = i18n.MainGrid_EmptyText
        }
        mainGrid.view.refresh()
      }
    }
    // clear filters on group and main store.
    var groupStore = viewModel.getStore('groupStore')
    mainGrid.getSelectionModel().select(0)
    viewModel.set('storeChanged', Ext.id())
  },

  onMainStoreBeforeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      i18n = viewModel.get('i18n'),
      title = null,
      alertText = null

    store.setGroupField(viewModel.get('groupByField'))

    if (i18n) {
      alertText = i18n.onMainStoreBeforeLoad_AlertText
      title = i18n.onMainStoreBeforeLoad_AlertTitle
    }

    if (store.isDirty()) {
      Ext.MessageBox.alert(title, alertText)
      return false
    }

    var proxy = store.getProxy()
    proxy.setExtraParam('applicationType', viewModel.get('applicationType'))
    proxy.setExtraParam('selectedProductCode[]', viewModel.get('selectedProductCode'))
    proxy.setExtraParam('selectedDC[]', viewModel.get('selectedDC'))
    proxy.setExtraParam('selectedK1K[]', viewModel.get('selectedK1K'))
    proxy.setExtraParam('selectedK2K[]', viewModel.get('selectedK2K'))
    proxy.setExtraParam('selectedK3K[]', viewModel.get('selectedK3K'))
    proxy.setExtraParam('selectedDate', viewModel.get('selectedDate'))
    proxy.setExtraParam('endDate', viewModel.get('endDate'))
    proxy.setExtraParam('selectedStore', viewModel.get('selectedStore'))
    proxy.setExtraParam('viewMode', viewModel.get('viewMode'))

    return true
  },

  onMainStoreWrite: function () {
    var me = this,
      viewModel = me.getViewModel(),
      grid = me.lookupReference('MainGrid')

    viewModel.set('storeChanged', Ext.id())
    grid.getSelectionModel().deselectAll()
    Ext.getBody().unmask(false)
  },

  // inventoryStore Events
  onInventoryStoreBeforeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      view = me.getView()
    var detailView = view.lookupReference('detailView')

    var panelVisibility = viewModel.get('panelVisibility')
    if (!panelVisibility.inventoryProfileVisible || detailView.collapsed) {
      return false
    }

    store.getProxy().abort()

    var selectedRecord = viewModel.get('selectedRecord')

    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', selectedRecord.get('depoKodu'))
    proxy.setExtraParam('urunKodu', selectedRecord.get('urunKodu'))
    proxy.setExtraParam('depoSiparisKoli', parseFloat(selectedRecord.get('degisenOneriKoli')))

    return true
  },

  // groupStore events
  onGroupStoreBeforeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      view = me.getView()
    var detailView = view.lookupReference('detailView')

    var panelVisibility = viewModel.get('panelVisibility')
    if (!panelVisibility.inventoryProfileVisible || detailView.collapsed) {
      return false
    }

    var modifiedRecords = mainStore.getModifiedRecords()
    var dirtyRecords = ''

    Ext.each(modifiedRecords, function (item) {
      dirtyRecords +=
        '|' +
        item.get('ID') +
        ';' +
        (item.get('onayFlag') ? 1 : 0) +
        ';' +
        item.get('degisenOneriKoli')
    })

    dirtyRecords = dirtyRecords.substr(1)

    var proxy = store.getProxy()
    proxy.setExtraParam('selectedDC[]', viewModel.get('selectedDC'))
    proxy.setExtraParam('selectedK1K[]', viewModel.get('selectedK1K'))
    proxy.setExtraParam('selectedK2K[]', viewModel.get('selectedK2K'))
    proxy.setExtraParam('selectedK3K[]', viewModel.get('selectedK3K'))
    proxy.setExtraParam('viewMode', viewModel.get('viewMode'))
    proxy.setExtraParam('dirtyRecords', dirtyRecords)
    proxy.setExtraParam('groupByField', viewModel.get('selectedGroupByField'))

    return true
  },

  // LocalizationStore events
  onLocalizationStoreLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      data = store.getDatas(),
      mainGrid = me.lookupReference('MainGrid'),
      colmuns = mainGrid.getColumns()

    var localizationDictionary = {}
    data.map(function (obj) {
      localizationDictionary[obj.TextKey] = obj.Text
    })

    viewModel.setData({
      i18n: localizationDictionary
    })
    var i18n = viewModel.get('i18n')
    colmuns.forEach((element) => {
      if (element.text == null || element.text == ' ') {
        element.setText(i18n['MainGrid_Column_' + element.columnId])
        if (element.setTooltip != undefined) {
          element.setTooltip(i18n['MainGrid_Column_' + element.columnId + '_tooltip'])
        }
      }
    })
  },

  // reasonStore events

  onReasonStoreLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      data = store.getDatas()
    var reasonDictionary = {}

    data.map(function (obj) {
      reasonDictionary[obj.ReasonId] = obj.Reason
    })

    viewModel.setData({
      reasonDictionary: reasonDictionary
    })
  },

  onReasonStoreBeforeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel()
    return viewModel.get('reasonCodeEnabled')
  },

  // RoundUp
  onRoundUpStoreBeforeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      view = me.getView()
    var editorView = view.lookupReference('editorView')

    var panelVisibility = viewModel.get('panelVisibility')
    if (!panelVisibility.productDetailVisible || editorView.collapsed) {
      return false
    }

    var selectedRecord = viewModel.get('selectedRecord')

    var modifiedRecords = mainStore.getModifiedRecords()
    var updatedData = ''

    Ext.each(modifiedRecords, function (item) {
      updatedData +=
        '|' +
        item.get('ID') +
        ';' +
        (item.get('onayFlag') ? 1 : 0) +
        ';' +
        item.get('degisenOneriKoli')
    })

    updatedData = updatedData.substr(1)

    if (selectedRecord == null) return

    var proxy = store.getProxy()
    proxy.setExtraParam('updatedData', updatedData)
    proxy.setExtraParam('depoKodu', selectedRecord.get('depoKodu'))
    proxy.setExtraParam('tedarikciKodu', selectedRecord.get('tedarikciKodu'))
    proxy.setExtraParam('urunKodu', selectedRecord.get('urunKodu'))

    return true
  },

  onFilterFromRoundUp: function () {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      groupGridStore = viewModel.getStore('groupStore'),
      selectedRecord = viewModel.get('selectedRecord'),
      selectedGroupByField = viewModel.get('selectedGroupByField')

    var isFiltered = viewModel.get('isFiltered')

    if (isFiltered) {
      if (groupGridStore && selectedGroupByField == 'tedarikciAdi') {
        groupGridStore.clearFilter()
      }

      mainStore.clearFilter()
      viewModel.set('isFiltered', false)
    } else {
      var filterColumn = 'tedarikciAdi'

      var filterValue = selectedRecord.get('tedarikciAdi')

      if (filterValue == '' || filterValue == null) return

      mainStore.clearFilter()
      mainStore.filter(filterColumn, filterValue)

      if (groupGridStore && selectedGroupByField == 'tedarikciAdi') {
        groupGridStore.clearFilter()
      }
      if (groupGridStore && selectedGroupByField == 'tedarikciAdi') {
        groupGridStore.filter('Name', filterValue)
      }

      viewModel.set('isFiltered', true)
    }

    groupGridStore.clearData()
    viewModel.set('storeChanged', Ext.id())
  },

  // ProductCodeStore
  onProductCodeStoreBeforeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel()

    var proxy = store.getProxy()
    proxy.setExtraParam('selectedDC[]', viewModel.get('selectedDC'))
    return po && po.Global && po.Global.config && po.Global.config.isDevelopment
  },

  // DcStore
  onDcStoreBeforeLoad: function () {
    var me = this,
      viewModel = me.getViewModel()
    return po && po.Global && po.Global.config && po.Global.config.isDevelopment
  },

  // K1kStore
  onK1kStoreBeforeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel()

    var proxy = store.getProxy()
    proxy.setExtraParam('selectedDC[]', viewModel.get('selectedDC'))
    return po && po.Global && po.Global.config && po.Global.config.isDevelopment
  },

  // GroupByStore
  onGroupByStoreBeforeLoad: function () {
    var me = this,
      viewModel = me.getViewModel()
    var panelVisibility = viewModel.get('panelVisibility')
    return panelVisibility.inventoryProfileVisible
  },
  exportTo: function (btn) {
    var me = this,
      mainGrid = me.lookupReference('MainGrid')

    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }
    var newcfg = Ext.merge(
      {
        title: 'PO lists',
        fileName: 'PO' + '.' + cfg.ext
      },
      btn.cfg
    )

    mainGrid.saveDocumentAs(newcfg)
  },
  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },

  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },

  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },

  // Substitute Events

  onSubstituteStoreUpdate: function (store, record) {
    var me = this,
      viewModel = me.getViewModel(),
      oldValue = record.get('degisenOneriKoli'),
      newValue = record.getModified('degisenOneriKoli'),
      incrementValue = oldValue - newValue

    if (record.isDirty() && incrementValue != 0) {
      record.set('IncrementValue', incrementValue)
    }
  },

  onSubstituteClick: function (grid, rowIndex, colIndex, action, event, record) {
    var me = this,
      viewModel = me.getViewModel(),
      store = viewModel.getStore('substituteStore')

    grid.getSelectionModel().select(rowIndex)
    viewModel.set('selectedRecord', record)
    if (!me.subStituteCmp) {
      me.subStituteCmp = Ext.create('Ext.window.Window', {
        modal: true,
        closeAction: 'hide',
        closeToolText: '',
        ui: 'window-inverse',
        resizable: true,
        maximizable: false,
        bodyPadding: 4,
        layout: 'vbox',
        items: [
          {
            xtype: 'container',
            layout: 'fit',
            items: [
              {
                xtype: 'poSubstituteTmp',
                bind: { data: '{selectedRecordWithi18n}' }
              }
            ]
          },
          {
            xtype: 'container',
            layout: 'fit',
            flex: 2,
            items: [
              {
                xtype: 'substitutegrid',
                store: store,
                height: 500,
                width: 700
              }
            ]
          }
        ],
        dockedItems: [
          {
            xtype: 'toolbar',
            dock: 'bottom',
            items: [
              {
                xtype: 'button',
                bind: {
                  text: '{i18n.toolbar_btnIptalText}'
                },
                handler: 'onSubstituteCancelButton'
              },
              '->',
              {
                xtype: 'button',
                text: 'Apply',
                bind: {
                  text: '{i18n.Window_ApplyButton_text}'
                },
                handler: 'onSubstituteApplyButton'
              }
            ]
          }
        ]
      })

      me.getView().add(me.subStituteCmp)
      me.subStituteCmp.show()
    } else {
      me.subStituteCmp.show()
    }
    var windowTitle = record.get('urunKodu') + '-' + record.get('urunAciklamasi')
    me.subStituteCmp.setTitle(windowTitle)
    var proxy = store.getProxy()
    proxy.setExtraParam('depoKodu', record.get('depoKodu'))
    proxy.setExtraParam('urunKodu', record.get('urunKodu'))
    store.load()
  },

  onSubstituteCancelButton: function () {
    var me = this,
      store = me.getStore('substituteStore'),
      viewModel = me.getViewModel(),
      i18n = viewModel.get('i18n')

    var title = i18n.toolbar_btnIptal_msg_title,
      msg = i18n.toolbar_btnIptal_msg_text,
      yes = i18n.msg_buttonYes_text,
      no = i18n.msg_buttonNo_text

    if (store.isDirty()) {
      Ext.Msg.show({
        title: title,
        msg: msg,
        buttons: Ext.Msg.YESNO,
        buttonText: {
          yes: yes,
          no: no
        },
        fn: function (btn) {
          if (btn === 'yes') {
            store.rejectChanges()
            me.subStituteCmp.close()
          }
        }
      })
    } else {
      me.subStituteCmp.close()
    }
  },

  onSubstituteApplyButton: function () {
    var me = this,
      store = me.getStore('substituteStore'),
      mainStore = me.getStore('mainStore'),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      viewModel = me.getViewModel(),
      data = [],
      i18n = viewModel.get('i18n')

    var title = i18n.toolbar_btnOnay_msg_title,
      msg = i18n.Substitute_SaveMessage,
      yes = i18n.msg_buttonYes_text,
      no = i18n.msg_buttonNo_text,
      eventText = i18n.toolbar_btnSAPaGonder_event_text

    if (store.isDirty()) {
      Ext.Msg.show({
        title: title,
        msg: msg,
        buttonText: {
          yes: yes,
          no: no
        },
        buttons: Ext.Msg.YESNO,
        fn: function (btn) {
          if (btn === 'yes') {
            var modifiedRecords = store.getModifiedRecords()
            modifiedRecords.forEach((element) => {
              data.push(element.data)
            })

            Ext.Ajax.request({
              method: 'POST',
              url: po.Global.getApiUrl() + 'po/substituteUpdate',
              params: {
                data: Ext.JSON.encode(data)
              },
              timeout: 600000,
              success: function (response) {
                var result = Ext.JSON.decode(response.responseText)
                if ((result.Type = 1)) {
                  result.forEach((element) => {
                    var record = mainStore.findRecord('urunKodu', element.urunKodu)
                    var selectedRecord = viewModel.get('selectedRecord')
                    selectedRecord.set('degisenOneriKoli', 0)
                    record.set(element)
                    record.commit(true)
                    selectedRecord.commit()
                    gridView.refreshNode(record)
                    gridView.refreshNode(selectedRecord)
                  })
                }
              },
              failure: function (response) {
                alert(response)
              }
            })

            me.subStituteCmp.hide()
            viewModel.set('storeChanged', Ext.id())
          }
        }
      })
    }
  },

  // massupdate events

  onMassUpdateButtonClick: function () {
    var me = this,
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      checkedRecordsStore = viewModel.getStore('checkedRecordsStore'),
      data = mainStore.getDatas(),
      checkedRecords = data.filter(function (item) {
        if (item.selected) return item
      }),
      i18n = viewModel.get('i18n'),
      add = i18n.MassUpdate_ComboBox_AddText,
      equal = i18n.MassUpdate_ComboBox_EqualText,
      percentage = i18n.MassUpdate_ComboBox_PercentageText,
      comboboxFieldLabel = i18n.MassUpdate_ComboBox_FieldLabelText,
      numberFieldLabel = i18n.MassUpdate_NumberField_FieldLabelText,
      title = i18n.Massupdate_Window_Title,
      newData = []

    checkedRecords.forEach((element) => {
      var isRevisedCountZero = element.degisenOneriKoli == 0
      if (!isRevisedCountZero) {
        newData.push({
          ID: element.ID,
          urunKodu: element.urunKodu,
          urunAciklamasi: element.urunAciklamasi,
          tedarikciKodu: element.tedarikciKodu,
          tedarikciAdi: element.tedarikciAdi,
          degisenOneriKoli: element.degisenOneriKoli,
          depoKodu: element.depoKodu,
          koliIciMiktar: element.koliIciMiktar,
          minSiparisMiktari: element.minSiparisMiktari,
          paletKatsayisi: element.paletKatsayisi,
          siparisKatsayisi: element.siparisKatsayisi,
          DepoSiparisliStokGun: element.DepoSiparisliStokGun,
          DepoForecasteGoreStokGun: element.DepoForecasteGoreStokGun
        })
      }
    })
    checkedRecordsStore.loadData(newData)

    var comboStore = Ext.create('Ext.data.Store', {
      fields: ['type', 'name'],
      data: [
        { type: '+', name: add },
        { type: '=', name: equal },
        { type: '%', name: percentage }
      ]
    })

    if (!me.massUpdateCmp) {
      me.massUpdateCmp = Ext.create('Ext.window.Window', {
        title: title,
        modal: true,
        closeAction: 'hide',
        closeToolText: '',
        ui: 'window-inverse',
        closable: false,
        resizable: true,
        maximizable: false,
        height: 400,
        bodyPadding: 4,
        layout: 'vbox',
        items: [
          {
            xtype: 'form',
            bodyPadding: 4,
            reference: 'massUpdateForm',
            width: 680,
            items: [
              {
                xtype: 'combobox',
                fieldLabel: comboboxFieldLabel,
                store: comboStore,
                name: 'combo',
                allowBlank: false,
                editable: false,
                value: 'Add',
                valueField: 'type',
                displayField: 'name',
                listeners: {
                  afterrender: function (combo) {
                    var recordSelected = combo.getStore().getAt(0)
                    combo.setValue(recordSelected.get('type'))
                  }
                }
              },
              {
                xtype: 'numberfield',
                allowBlank: false,
                fieldLabel: numberFieldLabel,
                name: 'value'
              }
            ]
          },
          {
            xtype: 'container',
            layout: 'fit',
            width: 680,
            flex: 1,
            bodyPadding: 4,
            items: [
              {
                xtype: 'massupdategrid',
                bind: {
                  store: '{checkedRecordsStore}'
                }
              }
            ]
          }
        ],
        dockedItems: [
          {
            xtype: 'toolbar',
            dock: 'bottom',
            items: [
              {
                xtype: 'button',
                text: 'Close',
                bind: {
                  text: '{i18n.Window_CLoseButton_text}'
                },
                handler: function () {
                  var me = this,
                    window = this.up('window'),
                    form = window.down('form'),
                    numberfield = form.items.items[1]

                  numberfield.reset()
                  window.close()
                }
              },
              '->',
              {
                xtype: 'button',
                bind: {
                  text: '{i18n.Window_ResetButton_text}'
                },
                handler: 'onMassUpdateResetButtonClick'
              },
              {
                xtype: 'button',
                bind: {
                  text: '{i18n.Window_ApplyButton_text}'
                },
                handler: 'onMassUpdateApplyButtonClick'
              }
            ]
          }
        ]
      })
      me.getView().add(me.massUpdateCmp)
      me.massUpdateCmp.show()
    } else {
      me.massUpdateCmp.show()
    }
  },

  onMassUpdateResetButtonClick: function (button) {
    var me = this,
      view = me.getView(),
      form = me.lookupReference('massUpdateForm'),
      window = button.up('window'),
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      data = mainStore.getDatas(),
      checkedRecords = viewModel.get('checkedRecords'),
      productIds = [],
      checkedRecordsStore = viewModel.getStore('checkedRecordsStore'),
      selectedRecords = data.filter(function (item) {
        if (item.selected) return item
      })

    if (checkedRecordsStore.isChanged) {
      window.setLoading('calculating')
      mainStore.suspendEvents()
      selectedRecords.forEach((element) => {
        productIds.push(element.ID)
      })

      Ext.Ajax.request({
        method: 'POST',
        url: po.Global.getApiUrl() + 'po/massUpdate',
        params: {
          value: 0,
          type: '+',
          id: productIds.toString(),
          depoKodu: viewModel.get('selectedDC')
        },
        timeout: 600000,
        success: function (response) {
          var result = Ext.JSON.decode(response.responseText)
          result.forEach((element) => {
            var record = mainStore.findRecord('ID', element.id)
            checkedRecords = checkedRecordsStore.findRecord('ID', element.id)
            checkedRecords.set('degisenOneriKoli', element.UpdatedDegisenKoliOneri)
            checkedRecords.commit()
            record.set('degisenOneriKoli', element.UpdatedDegisenKoliOneri)
            gridView.refreshNode(record)
            checkedRecordsStore.setIsChanged(false)
          })
          mainStore.resumeEvents()
          viewModel.set('storeChanged', Ext.id())
          window.setLoading(false)
        },
        failure: function (response) {
          alert(response)
          window.setLoading(false)
        }
      })
    }
  },

  onMassUpdateApplyButtonClick: function (button) {
    var me = this,
      view = me.getView(),
      form = me.lookupReference('massUpdateForm'),
      window = button.up('window'),
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      data = mainStore.getDatas(),
      checkedRecords = viewModel.get('checkedRecords'),
      productIds = [],
      checkedRecordsStore = viewModel.getStore('checkedRecordsStore'),
      selectedRecord = viewModel.get('selectedRecord'),
      selectedRecords = data.filter(function (item) {
        if (item.selected) return item
      })

    if (form.isValid()) {
      window.setLoading('calculating')
      mainStore.suspendEvents()
      selectedRecords.forEach((element) => {
        productIds.push(element.ID)
      })
      var formValues = form.getValues()

      Ext.Ajax.request({
        method: 'POST',
        url: po.Global.getApiUrl() + 'po/massUpdate',
        params: {
          value: formValues.value,
          type: formValues.combo,
          id: productIds.toString(),
          depoKodu: viewModel.get('selectedDC')
        },
        timeout: 600000,
        success: function (response) {
          var result = Ext.JSON.decode(response.responseText)
          result.forEach((element) => {
            var record = mainStore.findRecord('ID', element.id)
            checkedRecords = checkedRecordsStore.findRecord('ID', element.id)
            checkedRecords.set('degisenOneriKoli', element.UpdatedDegisenKoliOneri)
            record.set('degisenOneriKoli', element.UpdatedDegisenKoliOneri)
            gridView.refreshNode(record)
            checkedRecordsStore.setIsChanged(true)
          })
          mainStore.resumeEvents()
          viewModel.set('storeChanged', Ext.id())
          window.setLoading(false)
        },
        failure: function (response) {
          //@PS related to https://jira.solvoyo.com/browse/CUI-1355
          const { message = 'An error occurred!' } = Ext.JSON.decode(response.responseText) || {}
          alert(message)
          mainStore.resumeEvents()
          window.setLoading(false)
        }
      })
    } else return null
  },

  onErpActionHeaderClick: function (ct, column) {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      buttonsStatus = viewModel.get('buttonsStatus'),
      mainStoreData = mainStore.getDatas(),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      state = !viewModel.get('checkedAll'),
      records = mainStoreData.filter(function (item) {
        if (item.onayFlag && item.SAPYollandi == false) return item
      }),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    var checkedSAPCount = viewModel.get('checkedSAPCount')
    if (records.length > 0) {
      mainStore.suspendEvents()
      records.forEach((element) => {
        var record = mainStore.findRecord('ID', element.ID, 0, false, true, true)
        record.set('isSendToSap', state)
        gridView.refreshNode(record)
      })
      mainStore.resumeEvents()

      //@PS related to https://jira.solvoyo.com/browse/CUI-1347
      column.removeCls('x-grid-checkcolumn-checked')

      if (state) {
        checkedSAPCount = records.length
        //@PS related to https://jira.solvoyo.com/browse/CUI-1347
        column.addCls('x-grid-checkcolumn-checked')
        // column.setText(
        //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn-checked" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
        // )
      } else if (!state) {
        //@PS related to https://jira.solvoyo.com/browse/CUI-1347
        // column.setText(
        //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
        // )
        checkedSAPCount = 0
      }

      buttonsStatus.sapButtonEnable = state
      viewModel.set('buttonsStatus', buttonsStatus)
      view.fireEvent('buttonsStatusChange', buttonsStatus)
      viewModel.set('checkedSAPCount', checkedSAPCount)
      viewModel.set('checkedAll', state)
      viewModel.set('storeChanged', Ext.id())
    }
  },

  onOnayActionHeaderClick: function (ct, column) {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      buttonsStatus = viewModel.get('buttonsStatus'),
      mainStoreData = mainStore.getDatas(),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      state = !viewModel.get('checkedAllOnay'),
      records = mainStoreData.filter(function (item) {
        if (item.degisenOneriKoli > 0 && item.IsEditable) {
          return item
        }
      }),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    if (records.length > 0) {
      mainStore.suspendEvents()
      records.forEach((element) => {
        var record = mainStore.findRecord('ID', element.ID, 0, false, true, true)
        record.set('onayFlag', state)
        gridView.refreshNode(record)
      })
      mainStore.resumeEvents()

      column.removeCls('array-grid-wait-col array-grid-accept-col')

      if (state) {
        column.addCls('array-grid-wait-col')
      } else if (!state) {
        column.addCls('array-grid-accept-col')
      }

      buttonsStatus.saveButtonEnable = state

      viewModel.set('buttonsStatus', buttonsStatus)
      view.fireEvent('buttonsStatusChange', buttonsStatus)
      viewModel.set('checkedAllOnay', state)
      viewModel.set('storeChanged', Ext.id())
    }
  },

  onselectCheckboxColumnHeaderClick: function () {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel(),
      mainStore = viewModel.getStore('mainStore'),
      buttonsStatus = viewModel.get('buttonsStatus'),
      records = mainStore.getDatas(),
      grid = me.lookupReference('MainGrid'),
      gridView = grid.getView(),
      state = !viewModel.get('checkedAll'),
      canEdit = viewModel.get('canEdit')

    if (!canEdit) return

    var selectedRowCount = viewModel.get('selectedRowCount')
    if (records.length > 0) {
      mainStore.suspendEvents()

      records.forEach((element) => {
        const isRevisedCountZero = element.degisenOneriKoli === 0

        if (element.isSendToSap || element.SAPYollandi) return
        element.selected = state
        if (isRevisedCountZero) {
          element.selected = false
        }
      })

      if (state) {
        selectedRowCount = records.length
      } else if (!state) {
        selectedRowCount = 0
      }

      mainStore.resumeEvents()
      gridView.refresh()
      buttonsStatus.massUpdateButtonEnable = state
      viewModel.set('buttonsStatus', buttonsStatus)
      view.fireEvent('buttonsStatusChange', buttonsStatus)
      viewModel.set('selectedRowCount', selectedRowCount)
      viewModel.set('selectChange', Ext.id())
      viewModel.set('checkedAll', state)
    }
  },
  onMainStoreFilterChange: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      datas = store.getDatas(),
      count = 0

    datas.forEach((element) => {
      if (element.selected) count++
    })

    viewModel.set('selectChange', Ext.id())
    viewModel.set('selectedRowCount', count)
  }
})
