Ext.define('MDM.src.view.regionapp.Panel.TabPanel', {
  extend: 'Ext.tab.Panel',
  // Uncomment to give this component an xtype
  xtype: 'regionapp-tabpanel',
  cls: 'rmdtabpanel',
  frame: true,
  listeners: {
    boxready: 'onBoxReady',
    tabchange: 'onEnable'
  },
  tabBar: {
    items: [
      {
        xtype: 'tbseparator'
      },
      {
        xtype: 'component',
        flex: 2,
        margin: '0 0 0 10'
      },
      {
        xtype: 'regionapp-dropbox'
      },
      {
        xtype: 'component',
        width: 120,
        margin: '0 0 0 10',
        html: '<div class="headerText"> Read Only</div>',
        bind: {
          visible: '{isGlobal}'
        }
      },
      {
        xtype: 'component',
        width: 160,
        margin: '0 10 0 10',
        bind: {
          visible: '{!isGlobal}',
          html: '<div class="headerText2">' + '{ViewMode}' + '</div>'
        }
      },
      {
        xtype: 'cycle',
        ui: 'btn-defaults',
        bind: {
          visible: '{!isGlobal}'
        },
        showText: false,
        arrowVisible: false,
        menushow: true,
        changeHandler: 'onChangeView',
        menu: {
          items: [
            {
              text: '',
              iconCls: 'fa fa fa-user darkblue',
              checked: true,
              itemId: 'owner'
            },
            {
              text: 'All Region',
              iconCls: 'fa fa fa-users darkblue',
              itemId: 'all'
            }
          ]
        }
      }
    ]
  },
  tbar: [
    {
      xtype: 'tbtext',
      bind: {
        html: '<h3>Reporting Period : <b>{currentReportingPeriod}</b></h3>'
      }
    },
    '->',
    '-',
    {
      iconCls: 'fa fa-check',
      xtype: 'button',
      text: 'Apply G11',
      //   tooltip: 'Show dropped material records',
      ui: 'btn-success',
      handler: 'onApplyG11',
      bind: {
        hidden: '{g11IsHidden}'
      }
    },
    {
      iconCls: 'fa fa-search',
      xtype: 'button',
      text: 'Search Materials ',
      //   tooltip: 'Show dropped material records',
      ui: 'btn-success',
      handler: 'onSearchMaterialList'
    },
    '-',
    {
      text: 'Add New Material',
      bind: {
        disabled: '{isReadOnly}'
      },
      ui: 'btn-success',
      iconCls: 'fa fa-cubes',
      handler: 'onAddMaterial'
    },
    '-',
    {
      iconCls: 'fa fa-trash-o',
      text: 'Suppressed List',
      ui: 'btn-primary',
      handler: 'onShowDropList'
    },
    {
      margin: '0 2 0 10',
      // ui: 'btn-inverse',
      xtype: 'button',
      text: 'Export',
      iconCls: 'fa fa-download',
      ui: 'btn-primary',
      menu: {
        defaults: {
          handler: 'exportTo'
        },
        items: [
          {
            text: 'Excel xlsx',
            cfg: {
              type: 'excel07',
              ext: 'xlsx'
            }
          },
          {
            text: 'Excel xlsx (include groups)',
            cfg: {
              type: 'excel07',
              ext: 'xlsx',
              includeGroups: true,
              includeSummary: true
            }
          }
        ]
      }
    }
  ]
})
