Ext.define('Spui.view.main.CardLayout', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-main-cardlayour',

  layout: 'card',
  reference: 'maincardlayout',
  dockedItems: [
    {
      xtype: 'spui-view-main-toolbar-header',
      dock: 'top'
    }
  ],

  style: {
    border: '1 solid #ccc'
  },
  items: [{ xtype: 'spui-view-tracking-tracking' }]
})
