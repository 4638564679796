Ext.define('Spui.view.wizard.pages.Publish.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-wizard-pages-publish-main',
  mixins: ['Spui.view.wizard.pages.Publish.Mixin'],
  layout: 'center',

  itemso: [
    {
      width: 600,
      height: 200,
      xtype: 'form',
      border: true,
      bodyPadding: 10,
      items: [
        {
          // Fieldset in Column 1 - collapsible via toggle button
          xtype: 'fieldset',

          title: 'Onay bekleme suresi giriniz',
          collapsible: false,
          defaultType: 'textfield',
          defaults: {
            anchor: '100%'
          },
          layout: 'anchor',
          items: [
            {
              xtype: 'numberfield',
              name: 'name',
              width: 300,
              fieldLabel: 'Sure',
              allowBlank: false, // requires a non-empty value
              bind: {
                value: '{spotRecord.DaysForApprove}'
              }
            }
          ]
        }
      ],
      buttons: [
        {
          text: window.tt('Yayinla'),
          handler: 'onPublishSpot'
        }
      ]
    }
  ]
})
