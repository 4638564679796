export default `({
    plugins: [{
            ptype: 'cornerIcon',
            extraButton: [
                '->',
                {
                    iconCls: 'x-fa fa-arrows-alt',
                    handler: this.onmaximize,
                    scope: this
                }
            ],
            cfgButton: [{
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigureSpotGrid',
            }]
        },
        {
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
                beforeedit: function (editor, context) {
                    rec = context.record;
                    return rec.get('IsEditable');
                },
                edit: function (editor, context, eOpts) {
                    var me = this;
                    var record = context.record;
                    if (context.originalValue * 1 == context.value * 1) {
                        return;
                    }
                    grid = context.grid.ownerCt
                    grid.setLoading('Saving');
                    record.save({
                        params: {
                            updateAction: 'DCValueChange'
                        },
                        failure: function (record, operation) {},
                        success: function (record, operation) {
                            grid.setLoading(false)
                            var c = grid.ownerCt.ownerCt.getController(),
                                targetGrid = c.lookup('listofChildGrid'),
                                store = grid.getStore();
                            store.load();
                            grid.setLoading(false);
                                                    },
                        callback: function (record, operation, success) {
                            // do something whether the save succeeded or failed
                        }
                    });
                },
                scope: 'this'
            }
        },{
          ptype: 'gridfilters',
          id: 'gridfilters'
    }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    multiColumnSort: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [{
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-outdent',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                                        var win = Ext.create('Spui.uxWindow', {
                        height: '80%',
                        spotId: record.get('SpotId'),
                        title: tt('Spot Kampanyalari'),
                        uxItemCfg: 'changeoneriwin',
                        bodyPadding: 2,
                        modal: true,
                        onClickdata: function (item) {
                            var me = this,
                                vieModel = me.getViewModel(),
                                newValue = item.sendValue,
                                calculateType = item.calculateType;
                            me.onClickdataRequest(newValue, calculateType);
                        },
                        onClickdataRequest: function (newValue, calculateType) {
                            var spotId = this.spotId;
                            var me = this;
                            me.setLoading(tt('Oneri Hesaplaniyor Lutfen bekleyiniz.'));
                            Ext.Ajax.request({
                                url: Spui.Global.getApiUrl() + '/spui/recalculateoneri',
                                method: 'POST',
                                timeout: 600000,
                                params: {
                                    SpotId: spotId,
                                    newValue: newValue,
                                    calculateType: calculateType
                                },
                                success: function (response, opts) {
                                    var obj = Ext.decode(response.responseText);
                                    me.setLoading(false);
                                    var grid = me.query('grid')[2]
                                    store = grid.getStore();
                                    store.load();
                                    //me.reDrawChart(obj);
                                    // me.loadUserSettings();
                                },
                                failure: function (response, opts) {
                                    me.setLoading(false);
                                    console.log('server-side failure with status code ' + response.status);
                                }
                            });
                        },
                        onReCalcuulate: function (field, f, e, g) {
                            var value = field.getValue();
                            var menu = Ext.create('Ext.menu.Menu', {
                                margin: '0 0 10 0',
                                items: [{
                                        text: tt('Satış Profili'),
                                        handler: 'onClickdata',
                                        scope: this,
                                        calculateType: 1,
                                        sendValue: value
                                    }, {
                                        text: tt('Min-Max'),
                                        handler: 'onClickdata',
                                        scope: this,
                                        calculateType: 2,
                                        sendValue: value
                                    },
                                    // {
                                    //     text: tt('Fixed Quantity'),
                                    //     handler: 'onClickdata',
                                    //     scope: this,
                                    //     calculateType: 3,
                                    //     sendValue: value
                                    // }
                                ]
                            });
                            menu.showAt(e.getXY());
                        },
                        viewModel: {
                            data: {
                                record: record,
                            }
                        },
                        controller: {
                            onSelectWeek: function () {
                                alert(ok);
                            },
                            onExportExcel: function () {
                                alert(ok);
                            },
                            listen: {
                                component: {
                                    'uxchart': {
                                        clickChart: function (record) {
                                            grid = this.lookup('haftalikgrid');
                                            chart = this.lookup('chart2');
                                                                                    if (!grid) return;
                                            var store = grid.store
                                            this.getViewModel().set('record', record)
                                            proxy = store.getProxy();
                                            data = Ext.encode(record.data);
                                            proxy.setExtraParam('data', data);
                                            store.load();
                                            var store = chart.store
                                            proxy = store.getProxy();
                                            proxy.setExtraParam('data', data);
                                            store.load();
                                        }
                                    }
                                }
                            },
                        },
                        width: '80%',
                        layout: 'fit',
                        items: [{
                            xtype: 'uxcontainer',
                            uxItemCfg: 'changeoneri',
                        }]
                    });
                                        win.show();
                    // win.on('select', function (record) {
                    //     this.setValue(record.get('StartDate'))
                    // }, f)
                    // Ext.create('Spui.view.common.Details.Main', {
                    //     maximized: true,
                    //     title: "  Onay Ekrani",
                    //     ui: 'spui-white',
                    //     recomGridCfg: 'recomGridCfg',
                    //     recomStoreCfg: 'recomStoreCfg',
                    // }).show();
                },
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-thumbs-up blue',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt.ownerCt;
                    me.onChangeStatus(record, 'Approve')
                },
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa  fa-thumbs-down red',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt.ownerCt;
                    me.onChangeStatus(record, 'Dismiss')
                },
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-share-alt ',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt.ownerCt;
                    me.onChangeStatus(record, 'Sub Offers')
                },
            }]
        },
        {
            text: tt('MaterialCode'),
            filter: {
                type: 'string'
            },
            dataIndex: 'MaterialCode',
            width: 168,
            locked: true,
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            filter: {
                type: 'string'
            },
            width: 200,
            locked: true,
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            filter: {
                type: 'number'
            },
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('UnitofMeasurement'),
            dataIndex: 'UnitofMeasurement',
            filter: {
                type: 'number'
            },
            width: 168
        },
        {
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('MinQuantity'),
            dataIndex: 'MinQuantity',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('MaxQuantity'),
            dataIndex: 'MaxQuantity',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('PreStartDate'),
            dataIndex: 'PreStartDate',
            filter: {
                type: 'date'
            },
            width: 100
        },
        {
            text: tt('PreEndDate'),
            dataIndex: 'PreEndDate',
            filter: {
                type: 'date'
            },
            width: 100
        },
        {
            text: tt('DaysForApprove'),
            dataIndex: 'DaysForApprove',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            },
        },
        {
            text: tt('DaysForTargetSales'),
            dataIndex: 'DaysForTargetSales',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            },
        },
        {
            text: tt('StatusCode'),
            dataIndex: 'StatusCode',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('NumberOfRecommendedOrg'),
            dataIndex: 'NumberOfRecommendedOrg',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('NumberOfWaitingOrg'),
            dataIndex: 'NumberOfWaitingOrg',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('NumberOfDelegatedOrg'),
            dataIndex: 'NumberOfDelegatedOrg',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('NumberOfApprovedOrg'),
            dataIndex: 'NumberOfApprovedOrg',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('Parent'),
            dataIndex: 'OrderRecommendationParent',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            },
            locked: true,
        },
        {
            text: tt('Current'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            },
            locked: true,
            tdCls: 'blue',
            editor: {
                // completeOnEnter: false,
                // If the editor config contains a field property, then
                // the editor config is used to create the Ext.grid.CellEditor
                // and the field property is used to create the editing input field.
                field: {
                    xtype: 'numberfield',
                    allowBlank: false
                }
            },
        },
        {
            text: tt('OrderRecommendationChild '),
            dataIndex: 'OrderRecommendationChild',
            width: 100,
            locked: true,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            },
        },
        // {
        //     text: 'Child',
        //     dataIndex: 'ApprovedRecommendation',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        {
            text: tt('IsEditable'),
            dataIndex: 'IsEditable',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        }
    ],
    onAccept: function () {
            },
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        me = this;
        Ext.Ajax.request({
            url: Spui.Global.getApiUrl() + '/spui/changeStatus',
            method: 'POST',
            params: {
                data: data,
                newStatus: newStatus
            },
            success: function (response, opts) {
                //me.up('spui-view-main-main').getController().changeView();
            },
            failure: function (response, opts) {
                console.log('server-side failure with status code ' + response.status);
            }
        });
    }
})
`
