Ext.define('MDM.src.view.globalapp.Panel.Geo.Panel.MegaRegion', {
  extend: 'Ext.panel.Panel',
  tools: [
    {
      type: 'pin',
      tooltip: 'Add new DPIFamily ',
      // hidden:true,
      handler: 'onAddDPIFamily'
    },
    {
      type: 'plus',
      tooltip: 'Add new DPIFamily ',
      // hidden:true,
      handler: 'onAddDPIFamily'
    },
    {
      type: 'help',
      tooltip: 'Get Help',
      callback: function (panel, tool, event) {
        // show help here
      }
    }
  ],
  xtype: 'globalapp-Geo-panel-megaregion'
})
