import { put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { SET_PAGE_VARIABLES } from '../../relation'

export default function* generateKey(props = {}, result, action) {
  const { variable } = props
  const variableValues = { [variable]: uuidv4() }
  const {
    payload: {
      params: { storeIndex }
    }
  } = action
  yield put({
    type: SET_PAGE_VARIABLES,
    result: variableValues,
    storeIndex
  })
  return variableValues
}
