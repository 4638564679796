import { put, take } from 'redux-saga/effects'
import { PAGE_READY } from '../actionTypes'
import { SET_PAGE_VARIABLES } from '@/libs/relation'
import { getCustomVariables } from '@/helpers/mapper'

export default function* pageReady(props, result, action) {
  const {
    payload: { pageId }
  } = action
  while (true) {
    const result = yield take(PAGE_READY)

    const {
      payload: { pageId: resultPageId }
    } = result

    const {
      payload: {
        params: { storeIndex }
      },
      workflow
    } = action

    if (pageId === resultPageId) {
      const customVariables = getCustomVariables(workflow, true)
      yield put({
        type: SET_PAGE_VARIABLES,
        result: customVariables,
        storeIndex
      })

      return result
    }
  }
}
