Ext.define('MDM.src.view.materialedit.Main', {
  extend: 'Ext.window.Window',
  xtype: 'materialeditmain',
  isNew: false,
  crec: null,
  width: 1020,
  height: 680,
  layout: 'border',
  ui: 'window-inverse',
  iconCls: 'fa fa-pencil-square',
  title: 'Material Edit Dialog',
  requires: ['MDM.src.view.materialedit.MainControllor', 'MDM.src.view.materialedit.MainViewModel'],
  viewModel: 'materialedit-mainviewmodel',
  controller: 'materialedit-maincontrollor',
  bodyBorder: false,
  bodyPadding: 5,
  closable: false,
  listeners: {
    beforedestroy: 'onBeforeDestroy',
    afterrender: 'onAfterRender'
  },
  defaults: {
    collapsible: false,
    split: true
  },
  bbar: {
    xtype: 'toolbar',
    reference: 'basic-statusbar',
    defaultText: 'Ready',
    text: 'Ready',
    iconCls: 'x-status-valid',
    items: [
      '->',
      {
        text: 'Save',
        // disabled: true,
        iconCls: 'fa fa-floppy-o',
        reference: 'saveButton',
        formBind: true,
        handler: 'onSaveForm'
      },
      {
        text: 'Close',
        iconCls: 'fa fa-ban',
        handler: function (btn) {
          btn.up('window').destroy()
        }
      }
    ]
  },
  items: [
    {
      xtype: 'MaterialEditForm',
      region: 'center',
      border: true,
      margin: '4 0 4 4',
      collapsible: false,
      title: 'Material Edit Form',
      ui: 'panel-inverse'
    },
    {
      xtype: 'container',
      region: 'east',
      collapsible: true,
      collapsed: false,
      margin: '4 4 4 0',
      border: true,
      layout: {
        type: 'vbox',
        pack: 'start',
        align: 'stretch'
      },
      width: 340,
      maxwidth: 550,
      items: [
        {
          title: 'List of DPI Families',
          region: 'east',
          xtype: 'DPIGrid',
          reference: 'dpilist',
          flex: 1,
          bind: {
            store: '{DPIRelationStore}',
            visible: '{!hasId}'
          }
        },
        {
          region: 'east',
          xtype: 'DPICarttRidge',
          reference: 'Cartridgelist',
          flex: 1,
          bind: {
            store: '{dpilist.selection.cartridges}',
            disabled: '{!IsRazor}',
            title: '{selectedTitle}'
          }
        },
        {
          title: 'List of Production Line',
          region: 'east',
          xtype: 'ResourceGrid',
          reference: 'list',
          flex: 1,
          bind: {
            store: '{ResourceofProductStore}',
            visible: '{!hasId}'
          }
        }
      ]
    }
  ]
})
