import React, { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { Row, Col, Form, FormGroup, Button } from 'react-bootstrap'

class Checkbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.item.value === 'true' ? true : props.item.value === 'false' ? false : undefined
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(newValue) {
    const { item } = this.props
    if (!item.isReadonly) {
      this.props.onChange(item.sectionKey, item.key, _.toString(newValue))
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value:
        nextProps.item.value === 'true'
          ? true
          : nextProps.item.value === 'false'
          ? false
          : undefined
    })
  }

  render() {
    const { item, sectionLeftColumn, sectionRightColumn, backgroundColor, getValidationState } =
      this.props
    const { value } = this.state
    const validationState = getValidationState(item, value)
    const lightColor = backgroundColor.replace(/[^,]+(?=\))/, '0.3')
    const darkColor = backgroundColor.replace(/[^,]+(?=\))/, '0.1')
    return (
      <Row>
        <Col
          className="data-row-left"
          md={_.toNumber(sectionLeftColumn)}
          sm={_.toNumber(sectionLeftColumn)}
          style={{
            backgroundColor: lightColor
          }}
        >
          <h3>{item.title}</h3>
        </Col>
        <Col
          className="data-row-right"
          md={_.toNumber(sectionRightColumn)}
          sm={_.toNumber(sectionRightColumn)}
          style={{
            backgroundColor: darkColor
          }}
        >
          <FormGroup
            className="mb-3 form-group"
            // validationState={validationState === 'changed' ? null : validationState}
          >
            <div
              className={cx(`btn-group${validationState}`, 'd-flex', 'gap-2', {
                'is-invalid': validationState === 'error'
              })}
            >
              <Button
                className={cx('true-btn', { 'border-end-0': value !== undefined })}
                disabled={item.isReadonly}
                size="sm"
                type="button"
                variant={`${value === true ? 'success' : 'secondary'} `}
                onClick={() => this.handleChange(true)}
              >
                True
              </Button>
              <Button
                className="false-btn"
                disabled={item.isReadonly}
                size="sm"
                type="button"
                variant={`${value === false ? 'success' : 'secondary'} `}
                onClick={() => this.handleChange(false)}
              >
                False
              </Button>
            </div>
            <Form.Control.Feedback type="invalid">{item.validationMessage}</Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>
    )
  }
}
export default Checkbox
