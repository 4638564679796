import React from 'react'
import { Button, Dropdown, ButtonToolbar, ButtonGroup } from 'react-bootstrap'

export default class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()

    this.props.onClick(e, this.refs.customTrigger)
  }

  render() {
    return (
      <div ref="customTrigger">
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              className="custom-trigger-btn"
              disabled={this.props.disabled}
              variant={this.props.style}
              onClick={this.props.handleButtonClick}
            >
              {this.props.children}
            </Button>
            <Dropdown.Toggle
              className="custom-trigger-tgl"
              disabled={this.props.disabled}
              variant={this.props.style}
              onClick={this.handleClick}
            />
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }
}
