import React, { Component } from 'react'
import _ from 'lodash'
import '../index.scss'

class ImageFields extends Component {
  render() {
    const { imageFields, fieldsHeader } = this.props
    return (
      <div className="imageFields">
        <h4 style={{ textAlign: 'center', backgroundColor: '#e6e6e6' }}>{fieldsHeader}</h4>
        <div className="imageFields-rows-div">
          {_.map(imageFields, (item, key) => (
            <div key={key} className="imageFields-rows" style={{ display: 'flex' }}>
              <div
                className="ellipsis"
                style={{
                  width: '50%',
                  fontWeight: '500',
                  paddingLeft: '4px'
                }}
                title={item.label}
              >
                {item.label}
              </div>

              <div className="ellipsis" style={{ width: '50%' }} title={item.label}>
                {item.value}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
export default ImageFields
