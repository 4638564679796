import HtmlCellRendererProps from './HtmlCellRenderer.types'

const HtmlCellRenderer = (props: HtmlCellRendererProps) => {
  const {
    api,
    value,
    valueFormatted,
    colDef: {
      customInfo: { isAutoColumn }
    },
    node: { group },
    context: { groupDisplayType }
  } = props

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    return <strong>{value?.value}</strong>
  }

  if (!valueFormatted) {
    return null
  }

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: valueFormatted }} />
}

export default HtmlCellRenderer
