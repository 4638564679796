export default `({
    fields: [{
            name: 'SpotID',
            type: 'int'
        },
        {
            name: 'DCCode',
            type: 'string'
        }, {
            name: 'Name',
            type: 'string'
        },
        {
            name: 'DCName',
            type: 'string'
        },
        {
            name: 'MaterialCode',
            type: 'string'
        },
        {
            name: 'LastModifiedAt',
            type: 'date',
            dateFormat: tt('Y-m-d H:i:s.u')
        },
        {
            name: 'LastModifiedBy',
            type: 'string'
        },
        {
            name: 'StatusCode',
            type: 'string'
        },
        {
            name: 'UnitsPerPackage',
            type: 'number'
        },
        {
            name: 'NumberofStore',
            type: 'int'
        },
        {
            name: 'NumberofRecommendedStore',
            type: 'int'
        },
        {
            name: 'OnHandInventoryStore',
            type: 'number'
        },
        {
            name: 'OnHandInventoryDC',
            type: 'number'
        },
        {
            name: 'OpenOrderStore',
            type: 'number'
        },
        {
            name: 'OpenOrderDC',
            type: 'number'
        },
        {
            name: 'OrderRecommendationParent',
            type: 'number'
        },
        {
            name: 'OrderRecommendationCurrent',
            type: 'number'
        },
        {
            name: 'OrderRecommendationChild',
            type: 'number'
        },
        {
            name: 'IsInclude',
            type: 'int',
            convert: function (v) {
                if (typeof v === 'boolean') {
                    return v ? 1 : 0;
                } else {
                    return parseInt(v, 10);
                }
            }
        },
        {
            name: 'IsLocked',
            type: 'int',
            convert: function (v) {
                if (typeof v === 'boolean') {
                    return v ? 1 : 0;
                } else {
                    return parseInt(v, 10);
                }
            }
        }
    ],
})
`
