import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { getFormatedValue } from '@/helpers/formats'
import './index.scss'

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)
require('highcharts/modules/funnel')(Highcharts)

class Pyramid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      subTitle: null
    }

    this.onPyramidSectionClick = this.onPyramidSectionClick.bind(this)
    this.tooltipFormatter = this.tooltipFormatter.bind(this)
    this.gridTooltipFormatter = this.gridTooltipFormatter.bind(this)
  }

  onPyramidSectionClick(event) {
    let selected = null
    const { fieldName } = this.props.settings.config.data
    _.forEach(this.props.pluginData, (row) => {
      if (row[fieldName] === event.point.name) {
        selected = row
        return false
      }
    })

    this.pyramidSectionClick(selected || null)
  }

  componentDidMount() {
    this.pyramidSectionClick = this.props.registerEvent({
      key: 'pyramidSectionClick',
      fn: this.pyramidSectionClick.bind(this),
      returnTypes: _.transform(
        this.props.settings.query.fields,
        (result, field = {}) => {
          const { dataType = '' } = field
          result[field.fieldName] = PluginTypes.fromString(dataType)
        },
        {}
      )
    })

    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setSubTitle',
      fn: this.setSubTitle.bind(this),
      args: [{ name: 'subTitle', type: PluginTypes.string }]
    })
  }

  setTitle({ title }) {
    if (this.state.title !== title) {
      this.setState({ title })
    }
  }

  setSubTitle({ subTitle }) {
    if (this.state.subTitle !== subTitle) {
      this.setState({ subTitle })
    }
  }

  pyramidSectionClick(value) {
    return value
  }

  getSeriesName(series) {
    // Find series config through fieldName and not through series name
    // Since series name can be a template.
    const { userOptions: { fieldName: seriesFieldName = '' } = {} } = series
    return seriesFieldName || series.name
  }

  gridTooltipFormatter(e) {
    const { settings: { config: chartConfig = {} } = {}, getFormattedValue } = this.props
    const { series: { fieldName } = [] } = chartConfig
    const xFieldName = chartConfig.xField && fieldName
    let xValue = e.x
    if (xFieldName) {
      xValue = getFormattedValue(xFieldName, e.x)
    }

    let tooltip = `<table class="tg"><tr><th class="tg-yw4l" colspan="2">${xValue}</th></tr>`

    let pointIndex = 0
    if (e.points) {
      // destructuring for e.points[0]
      const {
        points: [
          {
            series: { name: referenceSeriesName, chart: { series: chartSeries = [] } = {} } = {},
            point: { clientX } = {}
          } = {}
        ] = []
      } = e

      // Find the refrence series which the clientX belong to
      const referenceSeries = _.find(chartSeries, {
        name: referenceSeriesName
      })

      if (referenceSeries) {
        _.each(referenceSeries.points, (point, index) => {
          const { clientX: pointClientX } = point
          if (pointClientX === clientX) {
            pointIndex = index
            return false
          }
        })
      }
      _.each(chartSeries, (series) => {
        if (series.visible || series.options.showOnlyInTooltip) {
          const { points: seriesPoints } = series
          _.each(seriesPoints, (point, index) => {
            if (index === pointIndex) {
              const seriesName = this.getSeriesName(series)
              const { valueFormat = null, y } = point
              let yValue
              if (valueFormat) {
                yValue = getFormatedValue(valueFormat, y)
              } else {
                yValue = this.getFormattedSeriesValue(seriesName, y, chartConfig)
              }

              tooltip += `<tr><td class="tg-yw4l" style="min-width:50px">${series.name}: </td><td class="tg-yw42">${yValue}</td></tr>`
              return true
            }
          })
        }
      })
    }
    // Single point
    else {
      const {
        point: {
          clientX,
          series: { name: refSeriesName, chart: { series: chartSeries = [] } = {} } = {}
        } = {}
      } = e
      const refSeries = _.find(chartSeries, {
        name: refSeriesName
      })

      if (refSeries) {
        const { points: refPoints = [] } = refSeries

        _.each(refPoints, (point, index) => {
          const { clientX: pointClientX } = point
          if (pointClientX === clientX) {
            pointIndex = index
            return false
          }
        })
      }

      _.each(chartSeries, (series) => {
        const { options: { showOnlyInTooltip } = {}, points = [], visible, name } = series

        if (visible || showOnlyInTooltip) {
          _.each(points, (point, index) => {
            if (index === pointIndex) {
              const seriesName = this.getSeriesName(series)
              const { valueFormat = null, y } = point
              let yValue
              if (valueFormat) {
                yValue = getFormatedValue(valueFormat, y)
              } else {
                yValue = this.getFormattedSeriesValue(seriesName, y, chartConfig)
              }

              tooltip += `<tr><td class="tg-yw4l" style="min-width:50px">${name}: </td><td class="tg-yw42">${yValue}</td></tr>`
              return true
            }
          })
        }
      })
    }

    tooltip += '</table>'

    return tooltip
  }

  tooltipFormatter(point) {
    const { settings: { config: chartConfig = {} } = {}, getFormattedValue } = this.props
    const { xField: { fieldName: xFieldName = null } = {} } = chartConfig
    let category = point.key || point.x
    if (xFieldName) {
      category = getFormattedValue(xFieldName, category)
    }

    let tooltip = `<span style="font-size: 120%">${category}</span><br/>`
    if (point.points) {
      // shared tooltip
      let pointIndex = 0
      // destructuring for point.points[0]
      const {
        points: [
          {
            series: { name: referenceSeriesName, chart: { series: chartSeries = [] } = {} } = {},
            point: { clientX } = {}
          } = {}
        ] = []
      } = point

      // Find the refrence series which the clientX belog to
      const referenceSeries = _.find(chartSeries, {
        name: referenceSeriesName
      })

      if (referenceSeries) {
        _.each(referenceSeries.points, (point, index) => {
          if (point.clientX === clientX) {
            pointIndex = index
            return false
          }
        })
      }

      let selectedRow
      _.each(chartSeries, (series) => {
        if (series.visible || series.options.showOnlyInTooltip) {
          _.each(series.points, (p, index) => {
            if (index === pointIndex) {
              selectedRow = p.row
              return false
            }
          })
        }
      })

      if (selectedRow) {
        tooltip = this.formatHeader(chartConfig, selectedRow, category)
      }

      _.each(chartSeries, (series) => {
        if (series.visible || series.options.showOnlyInTooltip) {
          _.each(series.points, (p, index) => {
            if (index === pointIndex) {
              const seriesName = this.getSeriesName(series)
              const seriesDescription = series.name

              if (_.has(p, 'low') && _.has(p, 'high')) {
                const { low, high, color } = p

                tooltip += this.formatErrorRangeText(seriesName, low, high, color, chartConfig)
              } else {
                const { point: { valueFormat = null } = {}, y, color, row = {} } = p

                tooltip += this.formatPointValue(
                  seriesName,
                  seriesDescription,
                  y,
                  color,
                  chartConfig,
                  valueFormat,
                  row
                )
              }
            }
          })
        }
      })
    } else {
      // Single point
      const { point: { valueFormat = null, row = {} } = {}, series = [], y, color } = point
      const seriesName = this.getSeriesName(series)
      const seriesDescription = series.name
      tooltip = this.formatHeader(chartConfig, row, category)
      tooltip += this.formatPointValue(
        seriesName,
        seriesDescription,
        y,
        color,
        chartConfig,
        valueFormat,
        row
      )
    }

    return tooltip
  }

  formatHeader(chartConfig, row, xValue) {
    const { getFormattedValue } = this.props
    const { tooltip: { headerFormat } = {} } = chartConfig

    let tooltip = `<span style="font-size: 120%">${xValue}</span><br/>`
    if (headerFormat) {
      tooltip = this.unescapeUnicode(headerFormat)

      if (row) {
        const formattedRow = _.transform(
          row,
          (res, value, key) => {
            res[key] = getFormattedValue(key, value)
          },
          {}
        )

        tooltip = this.replaceTemplate(tooltip, formattedRow)
      }
    }

    return tooltip
  }

  formatPointValue(name, description, value, color, chartConfig, valueFormat, row = null) {
    const { getFormattedValue } = this.props

    let formattedValue
    if (valueFormat) {
      formattedValue = getFormatedValue(valueFormat, value)
    } else {
      formattedValue = this.getFormattedSeriesValue(name, value, chartConfig)
    }

    // const series = _.find(
    //   chartConfig.series,
    //   series => series.fieldName === name
    // )
    // const { seriesProps: { tooltip: { pointFormat = null } = {} } = {} } =
    // series || {}

    const { tooltip: { pointFormat = {} } = {} } = chartConfig

    let tooltip = `<span style="color:${color}">\u25CF</span> ${description}: <b>${formattedValue}</b><br/> `
    if (pointFormat) {
      tooltip = this.unescapeUnicode(pointFormat)
    }
    tooltip = _.replace(tooltip, '{point.color}', color)
    tooltip = _.replace(tooltip, '{series.color}', color)
    tooltip = _.replace(tooltip, '{series.name}', description)

    tooltip = _.replace(tooltip, '{point.y}', formattedValue)

    if (row) {
      const formattedRow = _.transform(
        row,
        (res, value, key) => {
          res[key] = getFormattedValue(key, value)
        },
        {}
      )

      tooltip = this.replaceTemplate(tooltip, formattedRow)
    }

    return tooltip
  }

  unescapeUnicode(str) {
    const a = str.replace(/\\u([a-fA-F0-9]{4})/g, function (g, m1) {
      return String.fromCharCode(parseInt(m1, 16))
    })
    return a
  }

  formatErrorRangeText(name, lowValue, highValue, color, chartConfig) {
    const lowFormattedValue = this.getFormattedSeriesValue(name, lowValue, chartConfig)
    const highFormattedValue = this.getFormattedSeriesValue(name, highValue, chartConfig)

    // const series = _.find(
    //   chartConfig.series,
    //   series => series.fieldName === name
    // )

    // const { seriesProps: { tooltip: { pointFormat = null } = {} } = {} } =
    // series || {}

    const { tooltip: { pointFormat = {} } = {} } = chartConfig
    let tooltip = `<span style="color:{point.color}">●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b><br/>`

    if (pointFormat) {
      tooltip = this.unescapeUnicode(pointFormat)
    }

    tooltip = _.replace(tooltip, '{point.low}', lowFormattedValue)
    tooltip = _.replace(tooltip, '{point.high}', highFormattedValue)
    tooltip = _.replace(tooltip, '{point.color}', color)
    tooltip = _.replace(tooltip, '{series.name}', name)

    return tooltip
  }

  getFormattedSeriesValue(name, value, chartConfig) {
    const { getFormattedValue } = this.props
    // Get field name from series name
    const series = _.find(chartConfig.series, (series) => series.fieldName === name)

    const { fieldName = null } = series || {}

    return fieldName ? getFormattedValue(series.fieldName, value) : value
  }

  replaceTemplate(text, data) {
    const regExp = /\{([^}]+)\}/g

    const matches = text.match(regExp)

    if (!matches) return text

    for (let i = 0; i < matches.length; i++) {
      const variableName = matches[i].substr(1, matches[i].length - 2)

      if (_.has(data, variableName)) {
        const value = data[variableName]

        if (value) {
          text = text.replace(matches[i], value)
        } else {
          text = text.replace(matches[i], '')
        }
      } else {
        text = text.replace(matches[i], '')
      }
    }

    return text
  }

  removeEmptyConfig(config) {
    for (const prop in config) {
      if (!config.hasOwnProperty(prop)) continue

      if (config[prop] === '') {
        delete config[prop]
      } else if (typeof config[prop] === 'object') {
        this.removeEmptyConfig(config[prop])
      }
    }
  }

  render() {
    const { config: pluginConfig = {} } = this.props.settings || {}

    const config = _.cloneDeep(pluginConfig)

    const defaults = {
      credits: {
        enabled: false,
        text: 'Solvoyo © ' + new Date().getFullYear(),
        href: '#',
        style: { fontSize: '12px' }
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      }
    }

    // disable credits
    config.credits = defaults.credits
    const {
      chart: { showCredits = false } = {},
      exporting: { enabled: exportingEnabled = false } = {}
    } = pluginConfig
    config.credits.enabled = showCredits

    // display no data
    config.noData = defaults.noData

    // set empty text
    const { chart: { emptyText = 'No Data to Display' } = {} } = config
    config.lang = {
      noData: emptyText || ' '
    }

    // move export button to the top-left corner
    if (!config.exporting) {
      config.exporting = {}
    }
    config.exporting.enabled = exportingEnabled
    config.exporting.buttons = {
      contextButton: {
        align: 'left'
      }
    }

    // Display tooltip in a grid
    if (!config.tooltip) {
      config.tooltip = {}
    }

    const that = this
    if (config.tooltip.showInGrid) {
      config.tooltip.formatter = function () {
        return that.gridTooltipFormatter(this)
      }
      config.tooltip.shared = true
      config.tooltip.useHTML = true
      config.tooltip.crosshairs = true
    } else {
      config.tooltip.formatter = function () {
        return that.tooltipFormatter(this)
      }
    }

    const {
      settings: {
        config: {
          data: { fieldName: nameField, fieldWeight: weightField } = {},
          chart: { hoverOpacity = true } = {}
        } = {}
      } = {},
      pluginData = []
    } = this.props

    config.series = [
      {
        type: 'pyramid',
        data: [],
        name: weightField, // '__Weight',
        events: {}
      }
    ]

    if (!hoverOpacity) _.set(config, 'plotOptions.series.states.inactive.opacity', 1)

    _.forEach(pluginData, (row) => {
      if (weightField && _.has(row, weightField)) {
        if (row[weightField] && row[nameField] && row[weightField] > 0) {
          config.series[0].data.push({ name: row[nameField], y: row[weightField], row: { ...row } })
        }
      }
    })

    config.series[0].point = {
      events: {
        click: this.onPyramidSectionClick
      }
    }

    // Delete empty ('') properties
    this.removeEmptyConfig(config)

    return (
      <div className="highcharts-container">
        <HighchartsReact highcharts={Highcharts} options={config} />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(Pyramid, selectConnectorProps)
