export default () => {
  return {
    title: 'Leaflet Map',
    type: 'object',
    format: 'objectTab',

    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          },
          theme: {
            type: 'string',
            title: 'Theme',
            default: 'Light',
            enum: ['Light', 'Dark', 'Light gray'],
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            default: '',
            required: true
          },
          showHeatmap: {
            type: 'boolean',
            title: 'Show Heatmap',
            default: false,
            required: true,
            options: {
              hidden: true
            }
          },
          heatmapSettings: {
            type: 'object',
            title: 'Heatmap',
            options: {
              collapsed: true,
              hidden: true
            },
            properties: {
              heatMapEnabled: {
                type: 'boolean',
                title: '',
                options: {
                  hidden: true
                }
              },
              showHeatmap: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              radius: {
                type: 'integer',
                title: 'Radius',
                format: 'range',
                minimum: 0,
                maximum: 100,
                default: 10
              },
              radiusValue: {
                type: 'integer',
                title: ' ',
                template: '{{radiusValue}}',
                watch: {
                  radiusValue: 'settings.heatmapSettings.radius'
                }
              },

              maxOpacity: {
                type: 'number',
                title: 'Max Opacity',
                enum: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                default: 0.8,
                required: true
              }
            }
          },
          locationSettings: {
            type: 'object',
            title: 'Locations',
            options: {
              collapsed: true
            },
            properties: {
              removability: {
                type: 'boolean',
                title: 'Removable',
                default: false,
                required: true
              },
              draggable: {
                type: 'boolean',
                title: 'Draggable',
                default: true,
                required: true
              },
              editingType: {
                type: 'string',
                title: 'Editing Type',
                enum: ['Direct', 'Trigger'],
                default: 'Direct',
                required: true
              },
              askReplace: {
                type: 'boolean',
                title: 'Ask on Replacement',
                default: true,
                required: true
              },
              showCoordinates: {
                type: 'boolean',
                title: 'Show Coordinates',
                default: true,
                required: true,
                options: {
                  hidden: true
                }
              },
              defaultLat: {
                type: 'string',
                title: 'Default Latitude',
                required: false
              },
              defaultLong: {
                type: 'string',
                title: 'Default Longitude',
                required: false
              },
              defaultZoom: {
                type: 'number',
                title: 'Default Zoom Level',
                required: false
              }
            }
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string'
            }
          },
          idField: {
            type: 'string',
            title: 'Id',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },

          sourceField: {
            type: 'string',
            title: 'Source Country',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },

          sourceLatField: {
            type: 'string',
            title: 'Source Latitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          sourceLonField: {
            type: 'string',
            title: 'Source Longitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          zoomLevelField: {
            type: 'number',
            title: 'Zoom Level',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds',
            description: 'Overwrites default zoom'
          },
          colorField: {
            type: 'string',
            title: 'Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },

          isEditableField: {
            type: 'string',
            title: 'IsEditable',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          sizePropField: {
            type: 'string',
            title: 'Icon Size By',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          locationTypeField: {
            type: 'string',
            title: 'Location Type',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          heatmapField: {
            type: 'string',
            title: 'Heatmap field',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          heatmapOpacity: {
            type: 'number',
            title: 'Location Opacity',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },

          source: {
            type: 'string',
            title: 'Source Country',
            options: {
              hidden: true
            }
          },

          sourceLat: {
            type: 'string',
            title: 'Source Latitude',
            options: {
              hidden: true
            }
          },
          sourceLon: {
            type: 'string',
            title: 'Source Longitude',
            options: {
              hidden: true
            }
          },

          dest: {
            type: 'string',
            title: 'Destination',
            options: {
              hidden: true
            }
          },

          destLat: {
            type: 'string',
            title: 'Destination Latitude',
            options: {
              hidden: true
            }
          },

          destLon: {
            type: 'string',
            title: 'Destination Longitude',
            options: {
              hidden: true
            }
          },

          sizeProp: {
            type: 'string',
            title: 'Icon Size By',
            options: {
              hidden: true
            }
          },
          locationType: {
            type: 'string',
            title: 'Location Type',
            options: {
              hidden: true
            }
          },
          isEditable: {
            type: 'string',
            title: 'IsEditable',
            options: {
              hidden: true
            }
          },

          tooltip: {
            type: 'object',
            title: 'Tooltip',
            properties: {
              htmlTooltip: {
                type: 'string',
                format: 'Code',
                title: 'HTML Tooltip',
                options: {
                  mode: 'htmlmixed',
                  lineNumbers: true,
                  readOnly: false
                }
              },
              cssTooltip: {
                type: 'string',
                format: 'Code',
                title: 'Style Tooltip',
                options: {
                  mode: 'text/css'
                }
              },
              backgroundColorTooltip: {
                type: 'string',
                format: 'Color',
                title: 'Background Color Tooltip',
                default: '#ffffff'
              },
              tooltipElems: {
                type: 'array',
                title: 'Tooltip Fields',
                items: {
                  type: 'object',
                  properties: {
                    name: {
                      type: 'string',
                      title: 'Title',
                      required: true
                    },
                    value: {
                      type: 'string',
                      title: 'Value',
                      watch: {
                        ds: 'data.datafields'
                      },
                      enumSource: 'ds'
                    },
                    isVisible: {
                      type: 'boolean',
                      title: 'IsVisible',
                      default: true,
                      required: true
                    }
                  }
                }
              },
              toolTitle: {
                type: 'string',
                title: 'Title',
                options: {
                  hidden: true
                }
              },
              toolDesc: {
                type: 'string',
                title: 'Description',
                options: {
                  hidden: true
                }
              },
              toolValue: {
                type: 'string',
                title: 'Value',
                options: {
                  hidden: true
                }
              }
            }
          },
          locationTypes: {
            type: 'array',
            title: 'Location Types',
            items: {
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  title: 'Title',
                  required: true
                },
                shape: {
                  type: 'string',
                  title: 'Shape',
                  enum: ['Marker', 'Circle'],
                  default: 'Marker',
                  required: true
                },
                icon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector',
                  default: 'fa fa-home'
                },
                iconColor: {
                  type: 'string',
                  format: 'Color',
                  title: 'Icon Color',
                  default: '#000000',
                  options: {
                    hidden: true
                  }
                },
                minSize: {
                  type: 'string',
                  title: 'Min. Icon Size',
                  default: '10',
                  required: true
                },
                maxSize: {
                  type: 'string',
                  title: 'Max. Icon Size',
                  default: '50',
                  required: true
                }
              }
            }
          },
          layers: {
            type: 'array',
            title: 'Layers',
            items: {
              type: 'object',
              id: 'arr_item[i]',
              properties: {
                layerType: {
                  type: 'string',
                  title: 'Layer Type',
                  enum: ['OpenStreet', 'Heatmap'],
                  default: 'OpenStreet',
                  required: true
                },
                opacity: {
                  type: 'number',
                  title: 'Opacity',
                  enum: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                  default: 0.8,
                  required: true
                },
                heatmapRadius: {
                  type: 'integer',
                  title: 'Heatmap Radius',
                  format: 'range',
                  minimum: 0,
                  maximum: 100,
                  default: 10
                },
                heatMapradiusValue: {
                  type: 'integer',
                  title: ' ',
                  template: '{{heatMapradiusValue}}',
                  watch: {
                    heatMapradiusValue: 'arr_item[i].heatmapRadius'
                  }
                },
                zoomLevel: {
                  type: 'integer',
                  title: 'Visible After Zoom Level',
                  format: 'range',
                  minimum: 0,
                  maximum: 18,
                  default: 1
                },
                zoomLevelValue: {
                  type: 'integer',
                  title: ' ',
                  template: '{{zoomLevelValue}}',
                  watch: {
                    zoomLevelValue: 'arr_item[i].zoomLevel'
                  }
                }
              }
            }
          },
          toolTitle: {
            type: 'string',
            title: 'Title',
            options: {
              hidden: true
            }
          },
          toolDesc: {
            type: 'string',
            title: 'Description',
            options: {
              hidden: true
            }
          },
          toolValue: {
            type: 'string',
            title: 'Value',
            options: {
              hidden: true
            }
          }
        }
      }
    }
  }
}
