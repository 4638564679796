import { useState, useCallback, useMemo, useEffect } from 'react'
import { Chrono as ReactChrono } from 'react-chrono'
import cx from 'classnames'
import { slvyToast } from '@/components'
import { AddCard, Card, NoDataToDisplay, TimelineIcon } from './components'
import { useRegisterEventIconClick } from './hooks'
import getInitialItems from './helpers'
import {
  addCardItem,
  deleteFailed,
  deleteSuccess,
  queryType,
  saveFailed,
  saveSuccess
} from './constants'
import { ChronoTimelineProps, Item, Row } from './ChronoTimeline.types'
import styles from './index.module.scss'

const ChronoTimeline = (props: ChronoTimelineProps) => {
  const {
    id,
    user,
    settings: {
      config: {
        layout: { cardLess, mode, flipLayout },
        styles: {
          fontSize: {
            timeline: { title: timelineTitleFontSize, icon: iconFontSize },
            card: cardFontSizes
          },
          color: {
            timeline: {
              title: timelineTitleColor,
              activeTitle: timelineActiveTitleColor,
              background: timelineTitleBackgroundColor,
              line: timelineTitleLineColor,
              icon: timelineTitleIconColor
            }
          }
        },
        data: dataConfig,
        add: addConfig,
        add: { enable: isAddCardEnabled }
      }
    },
    actualFilters,
    pluginData,
    fieldConfigs,
    clientWithProgress,
    getFormattedValue,
    registerEvent
  } = props

  const [items, setItems] = useState(getInitialItems(pluginData, dataConfig))
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  useEffect(() => {
    setItems(getInitialItems(pluginData, dataConfig))
  }, [dataConfig, pluginData])

  const handleIconClick = useRegisterEventIconClick({ registerEvent, dataConfig, fieldConfigs })

  const onSave = useCallback(
    (card: Row, updatedCardIndex: number, isNewCard: boolean) => {
      const type = isNewCard ? queryType.add : queryType.update
      const data = {
        records: [card],
        filters: actualFilters,
        type
      }

      setActiveItemIndex(updatedCardIndex)

      clientWithProgress
        .post(`/data/plugin/${id}/edit/`, { data })
        .then((response: any) => {
          const errorCode = response.result?.[0]?.errorCode
          if (errorCode) {
            slvyToast.error({ title: saveFailed.title, message: errorCode })
            return
          }

          const updatedItem = {
            cardTitle: card[dataConfig.card.title],
            cardDetailedText: card[dataConfig.card.text],
            isNewCard: false
          } as Partial<Item>

          setItems((prev) =>
            prev.map((item, index) =>
              index === updatedCardIndex ? { ...item, ...updatedItem } : item
            )
          )
          slvyToast.success({ title: saveSuccess.title, message: saveSuccess.message })
        })
        .catch(() => slvyToast.error({ title: saveFailed.title, message: saveFailed.message }))
    },
    [actualFilters, clientWithProgress, dataConfig.card.text, dataConfig.card.title, id]
  )

  const onDelete = useCallback(
    (card: Row, deletedCardIndex: number, isNewCard: boolean) => {
      if (isNewCard) {
        setItems((prev) => prev.filter((_item, index) => index !== deletedCardIndex))
        return
      }

      const data = {
        records: [card],
        filters: actualFilters,
        type: queryType.delete
      }

      const activeIndex = Math.max(0, deletedCardIndex - 1)
      setActiveItemIndex(activeIndex)

      clientWithProgress
        .post(`/data/plugin/${id}/edit/`, { data })
        .then((response: any) => {
          const errorCode = response.result?.[0]?.errorCode
          if (errorCode) {
            slvyToast.error({ title: deleteFailed.title, message: errorCode })
            return
          }

          setItems((prev) => prev.filter((_item, index) => index !== deletedCardIndex))
          slvyToast.success({ title: deleteSuccess.title, message: deleteSuccess.message })
        })
        .catch(() => slvyToast.error({ title: deleteFailed.title, message: deleteFailed.message }))
    },
    [actualFilters, clientWithProgress, id]
  )

  // ReactChrono does not re-render its children when the 'items' prop is not updated.
  // To fix this, '_cardTitle' and '_cardDetailedText' were added,
  // so it re-renders after edits are made.
  const timelineTitles = useMemo(() => {
    const formattedItems = items.map(({ title, cardTitle, cardDetailedText }) => ({
      title: getFormattedValue(dataConfig.timeline.title, title) ?? '-',
      _cardTitle: cardTitle,
      _cardDetailedText: cardDetailedText
    }))

    if (isAddCardEnabled) {
      return formattedItems.concat(addCardItem)
    }

    return formattedItems
  }, [dataConfig.timeline.title, getFormattedValue, isAddCardEnabled, items])

  const cardHeight = cardLess ? 0 : '100%'
  const isHorizontal = mode === 'HORIZONTAL'
  const cardPositionHorizontal = isHorizontal && flipLayout ? 'TOP' : 'BOTTOM'
  const shouldFlipLayout = isHorizontal ? false : flipLayout

  if (!timelineTitles.length) {
    return <NoDataToDisplay />
  }

  return (
    <div className={cx(styles.timeline, { [styles.onlyTimeline]: cardLess })}>
      <ReactChrono
        allowDynamicUpdate
        hideControls
        activeItemIndex={activeItemIndex}
        cardHeight={cardHeight}
        cardLess={cardLess}
        cardPositionHorizontal={cardPositionHorizontal}
        flipLayout={shouldFlipLayout}
        fontSizes={{ title: `${timelineTitleFontSize}px` }}
        items={timelineTitles}
        mode={mode}
        theme={{
          primary: timelineTitleLineColor,
          secondary: timelineTitleBackgroundColor,
          titleColor: timelineTitleColor,
          titleColorActive: timelineActiveTitleColor
        }}
      >
        {/* 
          Icons should be rendered inside a wrapper with "chrono-icons" class
          https://react-chrono.prabhumurthy.com/features/icons.html 
        */}
        <div className="chrono-icons">
          {items.map((item, index) => (
            <TimelineIcon
              key={index}
              activeColor={timelineTitleBackgroundColor}
              color={timelineTitleIconColor}
              fontSize={iconFontSize}
              handleClick={handleIconClick}
              item={item}
            />
          ))}
        </div>

        {cardLess
          ? null
          : items.map((item, index) => {
              return (
                <Card
                  key={index}
                  dataConfig={dataConfig}
                  fontSizes={cardFontSizes}
                  index={index}
                  item={item}
                  onDelete={onDelete}
                  onSave={onSave}
                />
              )
            })}

        {isAddCardEnabled ? <AddCard config={addConfig} setItems={setItems} user={user} /> : null}
      </ReactChrono>
    </div>
  )
}

export default ChronoTimeline
