import {
  ConstantColDefs,
  CommonColDefKeys,
  ConditionalColDefKeys
} from './useCreateColumnDefs.types'

export const constantColDefs: ConstantColDefs = {
  flex: 1
}

export const commonColDefKeys: CommonColDefKeys = [
  'cellDataType',
  'cellEditor',
  'cellEditorParams',
  'cellRendererSelector',
  'colId',
  'customInfo',
  'editable',
  'field',
  'valueFormatter',
  'valueParser'
]

export const conditionalColDefKeys: ConditionalColDefKeys = ['cellEditorSelector']
