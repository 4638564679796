export default () => {
  return {
    title: 'Demand Planning',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          rejectNegativeValues: {
            type: 'boolean',
            title: 'Reject Negative Values',
            default: false,
            required: true
          },
          isRowClickedEventOn: {
            type: 'boolean',
            title: 'Is Row Clicked Event',
            default: false,
            required: true
          }
        }
      },

      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },

      theme: {
        type: 'object',
        title: 'Theme',
        properties: {
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          fontColor: {
            type: 'string',
            format: 'Color',
            title: 'Font Color',
            default: '#676a6c'
          },
          isHeaderSticky: {
            type: 'boolean',
            title: 'Is Header Sticky',
            default: false,
            required: true
          }
        }
      },

      localization: {
        type: 'object',
        title: 'Localization',
        properties: {
          currency: {
            type: 'string',
            title: 'Currency',
            default: ''
          },
          isCurrencyModeActive: {
            type: 'boolean',
            title: 'Currency Mode',
            default: false,
            required: true
          }
        }
      },

      pivotBuilder: {
        type: 'object',
        title: 'Pivot Builder',
        properties: {
          leftAxisLimit: {
            type: 'number',
            title: 'Left Axis Limit',
            default: 5
          },
          isMoveEnabledLeftAxisDraggable: {
            type: 'boolean',
            title: 'Is Move Enabled for Left Axis Draggable?',
            default: true,
            required: true
          },
          isCheckEnabledValuesDraggable: {
            type: 'boolean',
            title: 'Is Check Enabled for Values Draggable?',
            default: true,
            required: true
          },
          isMoveEnabledValuesDraggable: {
            type: 'boolean',
            title: 'Is Move Enabled for Values Draggable?',
            default: true,
            required: true
          },
          configuration: {
            type: 'array',
            title: 'Configuration',
            items: {
              type: 'object',
              title: 'Serie',
              headerTemplate: '{{self.Name}}',
              properties: {
                Name: {
                  type: 'string',
                  title: 'Name'
                },
                Checked: {
                  type: 'boolean',
                  title: 'Enable',
                  default: true,
                  required: true
                },
                List: {
                  type: 'array',
                  title: 'List',
                  items: {
                    type: 'object',
                    properties: {
                      Name: {
                        type: 'string',
                        title: 'Name'
                      },
                      Checked: {
                        type: 'boolean',
                        title: 'Checked',
                        default: true,
                        required: true
                      },
                      ExternalEdit: {
                        type: 'boolean',
                        title: 'External Edit',
                        default: false,
                        required: true
                      },
                      Arrow: {
                        type: 'object',
                        properties: {
                          IsVisible: {
                            type: 'boolean',
                            title: 'Is Visible',
                            default: true,
                            required: true
                          },
                          UpColor: {
                            type: 'string',
                            format: 'Color',
                            title: 'Up Color',
                            default: '#8bc34a'
                          },
                          DownColor: {
                            type: 'string',
                            format: 'Color',
                            title: 'Down Color',
                            default: '#ff0000'
                          }
                        }
                      },
                      Validation: {
                        type: 'object',
                        properties: {
                          MinMaxCheckType: {
                            title: 'Min - Max Acceptable Value',
                            type: 'string',
                            enum: ['absolute', 'percentage'],
                            default: 'absolute',
                            required: true
                          },
                          MinAcceptableValue: {
                            type: 'string',
                            title: 'Min Acceptable Value'
                          },
                          MaxAcceptableValue: {
                            type: 'string',
                            title: 'Max Acceptable Value'
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },

      timeline: {
        type: 'object',
        title: 'Timeline',
        properties: {
          defaultAggregationLevelId: {
            type: 'number',
            title: 'Default Aggregation Level Id',
            default: 2
          },
          buttonSelection: {
            type: 'boolean',
            title: 'Button Selection',
            default: false,
            required: true
          },
          shouldRememberLastTimelineSelection: {
            type: 'boolean',
            title: 'Remember Last Timeline Selection',
            default: false,
            required: true
          },
          settings: {
            type: 'array',
            title: 'Settings',
            items: {
              type: 'object',
              title: 'Serie',
              headerTemplate: 'Aggregation Level {{self.aggregationLevelId}}',
              properties: {
                aggregationLevelId: {
                  type: 'number',
                  title: 'Aggregation Level Id'
                },
                defaultUnitSize: {
                  type: 'number',
                  title: 'Unit Size'
                },
                min: {
                  type: 'number',
                  title: 'Min'
                },
                max: {
                  type: 'number',
                  title: 'Max'
                },
                isVisible: {
                  type: 'boolean',
                  title: 'Is Visible?',
                  default: true,
                  required: true
                },
                isRotate: {
                  type: 'boolean',
                  title: 'Is Rotate?',
                  default: false,
                  required: true
                }
              }
            }
          }
        }
      },

      grid: {
        type: 'object',
        title: 'Grid',
        properties: {
          isHierarcyView: {
            type: 'boolean',
            title: 'Is Hierarchy View',
            default: true,
            required: true
          },
          isVisibleDetailView: {
            type: 'boolean',
            title: 'Is Visible Detail View',
            default: true,
            required: true
          },
          isVisibleDetailViewSingleColumn: {
            type: 'boolean',
            title: 'Is Visible Detail View Single Column',
            default: true,
            required: true
          },
          isVisibleDetailViewButtonAllLevels: {
            type: 'boolean',
            title: 'Is Visible Detail View Button for All Levels',
            default: false,
            required: true
          },
          isVisibleCopyHierarchyButton: {
            type: 'boolean',
            title: 'Is Visible Copy Hierarchy Button',
            default: true,
            required: true
          },
          showAllDataInRows: {
            // @TODO
            type: 'boolean',
            title: 'Show All Data in Rows',
            default: false,
            required: true
          }
        }
      },

      gridDetail: {
        type: 'object',
        title: 'Grid Detail',
        properties: {
          drilldownCount: {
            type: 'number',
            title: 'Drilldown Count',
            default: 2,
            required: true
          },
          multiColumnEnabled: {
            type: 'boolean',
            title: 'Multi Column Enabled',
            default: false,
            required: true
          }
        }
      },

      configuration: {
        type: 'object',
        title: 'General Configuration',
        properties: {
          mfpId: {
            type: 'number',
            title: 'Mfp Id'
          },
          isApprove: {
            type: 'boolean',
            title: 'Is Approve',
            default: true,
            required: true
          },
          isLock: {
            type: 'boolean',
            title: 'Is Lock',
            default: true,
            required: true
          },
          moveToApprovedScenario: {
            type: 'boolean',
            title: 'Move To Approved Scenario',
            default: false,
            required: true
          },
          getMfpWithoutFilter: {
            type: 'boolean',
            title: 'Get Mfp Without Filter',
            default: false,
            required: true
          },
          shouldShowConfirmApprovedCellUpdate: {
            type: 'boolean',
            title: 'Show Confirm Approved Cell Update',
            default: true,
            required: true
          },
          isWorkInSubsetEnabled: {
            type: 'boolean',
            title: 'Work in Subset Enabled',
            default: false,
            required: true
          },
          isQuickFilterEnabled: {
            type: 'boolean',
            title: 'Is Quick Filter Enabled',
            default: false,
            required: true
          },
          isAutoLoadActive: {
            type: 'boolean',
            title: 'Auto Load',
            default: true,
            required: true
          },
          pendingMessage: {
            type: 'string',
            title: 'Pending Message',
            description: 'message to be displayed when auto-load is disabled',
            default: 'Auto-loading inactive.',
            required: true
          }
        }
      },

      snapshot: {
        type: 'object',
        title: 'Snapshot',
        properties: {
          errorMessage: {
            type: 'string',
            title: 'Error Message',
            default: 'Are you sure to overwrite your previous snapshot?'
          }
        }
      },

      scenario: {
        type: 'object',
        title: 'Scenario',
        properties: {
          successMessage: {
            type: 'string',
            title: 'Success Message',
            default: 'Scenario will be created soon.'
          },
          errorMessage: {
            type: 'string',
            title: 'Error Message',
            default: 'Can not create scenario.'
          },
          shouldRememberLastScenarioSelection: {
            type: 'boolean',
            title: 'Remember Last Scenario Selection',
            default: false,
            required: true
          },
          includeColumnStoreIndex: {
            type: 'boolean',
            title: 'Create Scenario with Column store Index',
            default: false,
            required: true
          }
        }
      },

      report: {
        type: 'object',
        title: 'Report',
        properties: {
          groupBy: {
            type: 'array',
            title: 'Group By',
            items: {
              type: 'object',
              title: 'Button',
              properties: {
                name: {
                  type: 'string',
                  title: 'Group By Field'
                }
              }
            }
          }
        }
      },

      coef: {
        type: 'object',
        title: 'COEF',
        properties: {
          header: {
            type: 'string',
            title: 'Header',
            default: ''
          },
          confirmationMessage: {
            type: 'string',
            title: 'Confirmation Message',
            default: ''
          },
          cancelButton: {
            type: 'string',
            title: 'Cancel Button',
            default: ''
          },
          confirmButton: {
            type: 'string',
            title: 'Confirm Button',
            default: ''
          },
          buttons: {
            type: 'array',
            title: 'Buttons',
            items: {
              type: 'object',
              title: 'Button',
              properties: {
                spName: {
                  type: 'string',
                  title: 'SP Name',
                  default: ''
                },
                explanation: {
                  type: 'string',
                  title: 'Explanation',
                  default: ''
                },
                successMessage: {
                  type: 'string',
                  title: 'Success Message',
                  default: ''
                },
                icon: {
                  type: 'string',
                  title: 'Icon Name',
                  default: ''
                }
              }
            }
          }
        }
      },

      updateModes: {
        type: 'object',
        title: 'Update Button',
        properties: {
          isVisibleCellUpdate: {
            type: 'boolean',
            title: 'Visible Cell Update Button',
            default: true,
            required: true
          },
          isVisibleRowUpdate: {
            type: 'boolean',
            title: 'Visible Row Update Button',
            default: true,
            required: true
          },
          isVisiblePopupUpdate: {
            type: 'boolean',
            title: 'Visible Popup Update Button',
            default: true,
            required: true
          }
        }
      },

      scenarioComparison: {
        type: 'object',
        title: 'Scenario Comparison',
        properties: {
          isVisibleSCButton: {
            type: 'boolean',
            title: 'Is Visible Scenario Comparison Button',
            default: true,
            required: true
          }
        }
      },

      actions: {
        type: 'object',
        title: 'Actions',
        properties: {
          isVisiblePivotBuilderButton: {
            type: 'boolean',
            title: 'Is Visible Pivot Builder Button',
            default: true,
            required: true
          },
          isVisibleChangeScenarioButton: {
            type: 'boolean',
            title: 'Visible Change Scenario Button',
            default: true,
            required: true
          },
          isVisibleSnapshotButton: {
            type: 'boolean',
            title: 'Visible Snapshot Button',
            default: true,
            required: true
          },
          isVisibleActionsButton: {
            type: 'boolean',
            title: 'Visible Actions Button',
            default: true,
            required: true
          },
          actionLogsColumnName: {
            title: 'Action Logs Column Name',
            type: 'string',
            enum: ['ColumnName', 'ColumnDisplayName'],
            default: 'ColumnName',
            required: true
          }
        }
      },

      messages: {
        type: 'object',
        title: 'Common Messages',
        properties: {
          summary_text: {
            type: 'string',
            title: 'Detail Summary Text',
            default: 'Summary'
          },
          rejectNegativeValuesMessage: {
            type: 'string',
            title: 'Reject Negative Values Message',
            default: 'It is disallowed to update with negative values.'
          },
          missingRowLockMessage: {
            type: 'string',
            title: 'Missing Row Lock Message',
            default: 'This row cannot be unlocked.'
          }
        }
      },

      rccp: {
        type: 'object',
        title: 'RCCP Settings',
        properties: {
          serviceAddress: {
            type: 'string',
            title: 'Service Address (ip:port)',
            default: '',
            required: false
          },
          serviceIntegration: {
            type: 'boolean',
            title: 'Service Integration',
            default: false,
            required: false
          },
          runInfoID: {
            type: 'number',
            title: 'Run Info ID',
            default: 0,
            required: false
          },
          scenarioID: {
            type: 'number',
            title: 'Scenario ID',
            default: 0,
            required: false
          }
        }
      }
    }
  }
}
