Ext.define('tuiv2.model.LoadTreeModel', {
  extend: 'tuiv2.model.BaseTree',
  entityName: 'tuiv2/GetTree',
  fields: [
    {
      name: 'leaf',
      mapping: 'Leaf'
    },
    {
      name: 'color',
      type: 'string',
      defaultValue: '#ffffff'
    },
    {
      name: 'Text',
      type: 'string'
    },
    {
      name: 'checked',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'iconCls',
      calculate: function (data) {
        return null
      }
    }
  ]
})
