Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.globalapp-geogrid-mainviewmodel',
  stores: {
    MegaRegionStore: {
      model: 'MDM.src.view.globalapp.Panel.GeoGrid.model.MegaRegion',
      autoLoad: false,
      autoSync: false,
      bindTo: 'bindTodfcStore',
      listeners: {
        load: 'onMegaRegionStoreLoad'
      }
    },
    RegionStore: {
      model: 'MDM.src.view.globalapp.Panel.GeoGrid.model.Region',
      autoLoad: false,
      autoSync: false,

      filters: [
        {
          property: 'MegaRegionID',
          value: '{MegaRegionID}'
        }
      ],
      listeners: {
        filterchange: 'onRegionStoreFilterChange',
        load: 'onRegionStoreFilterChange'
      }
    },
    CountryStore: {
      model: 'MDM.src.view.globalapp.Panel.GeoGrid.model.Country',
      autoLoad: false,
      autoSync: false,

      filters: [
        {
          property: 'MegaRegionID',
          value: '{MegaRegionID}'
        },
        {
          property: 'RegionID',
          value: '{RegionID}'
        }
      ],
      listeners: {
        filterchange: 'onCountryStoreFilterChange',
        load: 'onCountryStoreFilterChange'
      }
    },
    SupplySourceStore: {
      model: 'MDM.src.view.globalapp.Panel.GeoGrid.model.SupplySource',
      autoLoad: false,
      autoSync: false,

      filters: [
        {
          property: 'MegaRegionID',
          value: '{MegaRegionID}'
        },
        {
          property: 'RegionID',
          value: '{RegionID}'
        }
      ],
      listeners: {}
    }
  },
  formulas: {
    MegaRegionID: {
      bind: {
        bindTo: '{curMegaRegionRec}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('MegaRegionID') : 1
      }
    },
    MegaRegion: {
      bind: {
        bindTo: '{curMegaRegionRec}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('MegaRegion') : ''
      }
    },
    RegionID: {
      bind: {
        bindTo: '{curRegionRec}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('RegionID') : 1
      }
    },
    Region: {
      bind: {
        bindTo: '{curRegionRec}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('Region') : ''
      }
    },
    CountryID: {
      bind: {
        bindTo: '{curCountryRec}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('CountryID') : 1
      }
    },
    Country: {
      bind: {
        bindTo: '{curCountryRec}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('Country') : ''
      }
    }
  },
  data: {
    curMegaRegionRec: false,
    curRegionRec: false,
    curCountryRec: false
  }
})
