import { useState, useEffect, ChangeEvent, FormEventHandler, useMemo } from 'react'
import { FormLabel, FormCheck, Form, Button } from 'react-bootstrap'
import _ from 'lodash'
import { SketchPicker } from 'react-color'
import { slvyToast } from '@/components'
import getAdminForm, { getBgColorCmpStyle } from '../../../utils/admin-container.js'
import { defaultForm, defaultColor, containerSettings } from './constants'
import { AdminContainerProps, FormState } from './Admin.types'

const AdminContainer = (props: AdminContainerProps) => {
  const { propertyEditorFields, settings, onChangeSettings } = props

  const getInitialForm = useMemo(() => {
    return getAdminForm({ ...propertyEditorFields, ...containerSettings }, settings, defaultForm)
  }, [propertyEditorFields, settings])

  const [form, setForm] = useState<FormState>(getInitialForm)
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)

  useEffect(() => {
    setForm(getInitialForm)
  }, [getInitialForm])

  const handleClick = () => {
    setDisplayColorPicker((prevState: boolean) => !prevState)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChangeUseCssTransforms = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    setForm((prevForm: FormState) => ({ ...prevForm, useCSSTransforms: value === 'true' }))
  }

  const handleChangeContainerPadding = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    setForm((prevForm: FormState) => ({ ...prevForm, withContainerPadding: value === '1' }))
  }

  const handleResetBackground = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()

    setForm((prevForm: FormState) => ({ ...prevForm, background: defaultColor }))
  }

  const handleChangeBackground = ({ rgb }: { rgb: FormState['background'] }) => {
    setForm((prevForm: FormState) => ({ ...prevForm, background: rgb }))
  }

  const handleChangeFit = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked = true }
    } = event

    setForm((prevForm: FormState) => ({ ...prevForm, fit: checked }))
  }

  const getValidationState = () => {
    // TODO: add validation case
    return null
  }

  const handleSubmit = (event: FormEventHandler<HTMLInputElement>) => {
    event.preventDefault()
    if (!getValidationState()) {
      onChangeSettings({
        ...settings,
        ...form
      })
      slvyToast.success({ message: 'Saved successfully' })
    }
  }

  const {
    fit,
    background,
    withContainerPadding,
    useCSSTransforms,
    Background,
    WithContainerPadding
  } = form

  const color = background || Background || defaultColor
  const style = getBgColorCmpStyle(color)

  // eslint-disable-next-line no-underscore-dangle
  const _withContainerPadding = !_.isNil(withContainerPadding)
    ? withContainerPadding
    : WithContainerPadding

  const containerPaddingStatus = _withContainerPadding ? '1' : '0'

  // eslint-disable-next-line no-underscore-dangle
  const _useCSSTransforms = !_.isNil(useCSSTransforms) ? useCSSTransforms : useCSSTransforms

  return (
    <Form noValidate className="containerForm" onSubmit={handleSubmit}>
      <Form.Group className="position-relative mb-2">
        <FormLabel className="fs-sm d-block">Background Color</FormLabel>
        <div style={style.swatch} onClick={handleClick}>
          <div style={style.color} />
          <div style={style.swatch.clear}>
            <i
              className="fa fa-times"
              style={style.swatch.clear.icon}
              onClick={handleResetBackground}
            />
          </div>
        </div>
        {displayColorPicker ? (
          <div style={style.popover}>
            <div style={style.cover} onClick={handleClose} />
            <SketchPicker color={color} onChange={handleChangeBackground} />
          </div>
        ) : null}
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm mb-0">
          <FormCheck
            inline
            checked={fit}
            className="mh-auto mb-0 form-check-sm"
            id="fit"
            label="Fit"
            type="checkbox"
            onChange={handleChangeFit}
          />
        </FormLabel>
      </Form.Group>
      <Form.Group className="mb-2">
        <div>
          <FormLabel className="fs-sm d-block">Open Container Padding</FormLabel>
          <FormCheck
            inline
            checked={containerPaddingStatus === '1'}
            className="mh-auto mb-0 form-check-sm"
            label="On"
            name="paddinggroup"
            type="radio"
            value="1"
            onChange={handleChangeContainerPadding}
          />
          <FormCheck
            inline
            checked={containerPaddingStatus === '0'}
            className="mh-auto mb-0 form-check-sm"
            label="Off"
            name="paddinggroup"
            type="radio"
            value="0"
            onChange={handleChangeContainerPadding}
          />
        </div>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block">Use CSS Transforms</FormLabel>
        <FormCheck
          inline
          checked={_useCSSTransforms}
          className="mh-auto mb-0 form-check-sm"
          label="On"
          name="usecsstransforms"
          type="radio"
          value="true"
          onChange={handleChangeUseCssTransforms}
        />
        <FormCheck
          inline
          checked={!_useCSSTransforms}
          className="mh-auto mb-0 form-check-sm"
          label="Off"
          name="usecsstransforms"
          type="radio"
          value="false"
          onChange={handleChangeUseCssTransforms}
        />
      </Form.Group>
      <Button size="sm" type="submit" variant="success">
        Save
      </Button>
    </Form>
  )
}

export default AdminContainer
