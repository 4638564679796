Ext.define('MDM.view.regionapp.MassEdit.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.mdm-view-regionapp-massedit-mainviewmodel',
  stores: {
    MaterialStore: {
      model: 'MDM.view.regionapp.MassEdit.model.MaterialNew',
      autoLoad: false,
      listeners: {
        beforeload: 'onBeforeLoadMaterialStore',
        load: 'onLoadMaterialStore'
      }
    },
    DPIFamilyStore: {
      type: 'store',
      storeId: 'DPIFamily',
      proxy: {
        type: 'memory',
        reader: {
          type: 'json'
        }
      }
    },
    DPICartridgeStore: {
      source: '{DPIFamilyStore}',
      filters: [
        {
          property: 'IsCartridge',
          value: '1',
          operator: '='
        }
      ]
    },
    MaterialTypeStore: {
      type: 'store',
      storeId: 'MaterialType',
      proxy: {
        type: 'memory',
        reader: {
          type: 'json'
        }
      }
    },
    MaterialBUOMStore: {
      type: 'store',
      storeId: 'MaterialBUOM',
      proxy: {
        type: 'memory',
        reader: {
          type: 'json'
        }
      }
    },
    SupplySourceStore: {
      type: 'store',
      storeId: 'SupplySource',
      proxy: {
        type: 'memory',
        reader: {
          type: 'json'
        }
      }
    }
  },
  data: {
    /* This object holds the arbitrary data that populates the ViewModel and is then available for binding. */
  }
})
