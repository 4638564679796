import { forwardRef } from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import _ from 'lodash'

import { omitList, SlvySelectStyles } from './constants'
import { SlvySelectProps } from './SlvySelect.types'

const { defaultStyles } = SlvySelectStyles

const SlvySelect = forwardRef((props: SlvySelectProps, ref) => {
  const { isCreatable = false } = props
  const selectProps = _.omit(props, omitList)

  return isCreatable ? (
    <Creatable styles={defaultStyles} {...selectProps} ref={ref} />
  ) : (
    <Select styles={defaultStyles} {...selectProps} ref={ref} />
  )
})

export default SlvySelect
