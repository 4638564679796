Ext.define('tuiv2.cmp.TaskGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'tuiv2-taskgrid',
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],
  viewConfig: {
    enableTextSelection: true
  },
  columns: [
    {
      dataIndex: 'Name',
      text: 'Name',
      width: 100,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'Description',
      text: 'Description',
      width: 100,
      filter: {
        type: 'list'
      }
    },
    {
      dataIndex: 'UserName',
      text: 'User Name',
      width: 100,
      filter: {
        type: 'list'
      }
    },
    {
      dataIndex: 'StartTime',
      text: 'Start Time',
      width: 150,
      renderer: function (value) {
        return Ext.util.Format.date(value, 'Y-m-d h:m:s')
      }
    },
    {
      dataIndex: 'EndTime',
      text: 'End Time',
      width: 150,
      renderer: function (value) {
        return Ext.util.Format.date(value, 'Y-m-d h:m:s')
      }
    },
    {
      dataIndex: 'InProgress',
      text: 'In Progress',
      flex: 1
    },
    {
      text: 'Progress',
      xtype: 'widgetcolumn',
      dataIndex: 'Progress',
      width: 120,
      widget: {
        xtype: 'progressbarwidget',
        textTpl: ['{percent:number("0")}% done']
      }
    }
  ]
})
