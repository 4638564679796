Ext.define('Spui.view.main.page.ErrorPage', {
  extend: 'Ext.panel.Panel',
  xtype: 'spui-pages-errorpage',
  flex: 1,
  cls: 'spui-errorpage-main',
  bodyStyle: {
    'background-image':
      'linear-gradient(0deg,#f4f4f4 1.1px, transparent 0), linear-gradient(90deg,#f4f4f4 1.1px, transparent 0);',
    'background-size': '20px 20px;'
  },
  layout: {
    type: 'vbox',
    align: 'center',
    pack: 'center'
  },
  defaults: {
    xtype: 'panel',
    margin: 10,
    bodyPadding: 5,
    overflowY: 'auto'
  },
  items: [
    {
      header: false,
      border: true,
      width: 500,
      height: 300,
      xtype: 'panel',
      margin: '5px',
      cls: 'spui-errorpage-center',
      html: '  <div class="spui-errorpage-container"><h1>401!</h1><h3> Bu sayfayı görüntüleme yetkiniz yok </h3><h4>Yetkisiz: erişim geçersiz kimlik bilgileri nedeniyle engellendi.</h4></div> '
    }
  ]
})
