export const version = {}

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState)
      }

      const { meta: { version = 0 } = {}, meta, type, ...rest } = action // eslint-disable-line no-redeclare
      if (version !== 3) {
        return next(action)
      }

      const { success, failure, method = 'get', path, params, requestHeader, data = {} } = meta
      const startFetch = Date.now()
      const newMeta = { ...meta, startFetch, endFetch: 0, fetchTime: 0 }
      next({
        meta: newMeta,
        ...rest,
        type
      })
      if (path) {
        const actionPromise = client[method](
          path,
          {
            params,
            data
          },
          requestHeader
        )
        actionPromise
          .then(
            (result) => {
              const endFetch = Date.now()
              const fetchTime = endFetch - startFetch
              return next({
                ...rest,
                meta: { ...newMeta, endFetch, fetchTime },
                result,
                type: success
              })
            },
            (error) => {
              const endFetch = Date.now()
              const fetchTime = endFetch - startFetch
              return next({
                ...rest,
                meta: { ...newMeta, endFetch, fetchTime },
                error,
                type: failure
              })
            }
          )
          .catch((error) => {
            console.error('MIDDLEWARE ERROR:', error)

            const endFetch = Date.now()
            const fetchTime = endFetch - startFetch
            next({
              ...rest,
              meta: { ...newMeta, endFetch, fetchTime },
              error,
              type: failure
            })
          })

        return actionPromise
      }
    }
  }
}
