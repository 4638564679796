import { useEffect, useState, useRef } from 'react'
import { isNil, isEmpty } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import SlvyTable, { SlvyTableProps } from '@/components/SlvyTable'
import { alignments, operators, types } from './constants'
import { tryParseSettings, treeify } from './utils'
import { collectNodes } from '../Tui/utils'
import { ReactDatatableProps } from './ReactDatatable.types'
import styles from './ReactDatatable.module.scss'

export default function ReactDatatable({
  actualFilters,
  config,
  data,
  fetchPageData,
  getPageSize,
  getTotalRowCount,
  pluginData,
  registerEvent,
  registerMethod
}: ReactDatatableProps) {
  const [columnSettings, setColumnSettings] = useState({})
  const [tableSettings, setTableSettings] = useState({})
  const [columnDefSettings, setColumnDefSettings] = useState({})
  const [remotePageIndex, setRemotePageIndex] = useState(1)
  const [avgColumnWidth, setAvgColumnWidth] = useState(0)
  const remoteSortedColumns = useRef([])
  const remoteFilteredColumns = useRef([])
  const initalLoadComplete = useRef(undefined)
  const remoteTotalRowCount = useRef(getTotalRowCount())
  const remotePaging = !isEmpty(actualFilters) && 'SKIP' in actualFilters
  const remotePageSize = useRef(getPageSize())
  const actionButtonHandlersRef = useRef<Record<string, any>>({})
  const rowClickHandlerRef = useRef<() => void>(() => ({}))
  const cellEditHandlerRef = useRef<() => void>(() => ({}))
  const dataTableRef = useRef(null)

  useEffect(registerMethods, [])
  useEffect(registerEvents, [data.schema, JSON.stringify(pluginData)])

  function handleClickActionButton(
    actionButton: {
      name: string
      isEnabled: boolean
      returnParams: Array<{
        name: string
        type: 'boolean' | 'datetime' | 'dynamic' | 'int' | 'string'
      }>
    },
    rowIndex: number
  ) {
    return actionButton.returnParams.reduce(
      (accumulator, { name }) =>
        Object.assign(accumulator, {
          [name]: pluginData[rowIndex][name]
        }),
      {}
    )
  }

  function handleSetColumnSettings({
    columnSettings: colSettings = '{}'
  }: {
    columnSettings: string
  }) {
    setColumnSettings(tryParseSettings(colSettings))
  }

  function handleSetColumnDef({ columnDef = '{}' }: { columnDef: string }) {
    setColumnDefSettings(tryParseSettings(columnDef))
  }

  function handleSetTableSettings({
    tableSettings: tblSettings = '{}'
  }: {
    tableSettings: string
  }) {
    setTableSettings(tryParseSettings(tblSettings))
  }

  function handleClearAllFilters() {}

  function handleSelectRow({ index }) {
    rowClickHandlerRef.current(index)
  }

  function handleEditCell(cell, value) {
    cellEditHandlerRef.current(cell, value)
  }

  function getIsColumnRemoteSorted(columnId) {
    return remoteSortedColumns.current.some((column) => Object.keys(column).at(0) === columnId)
  }

  function clearColumnRemoteSorting(columnId) {
    remoteSortedColumns.current = remoteSortedColumns.current.reduce(
      (accumulator, current) =>
        Object.keys(current).at(0) === columnId ? accumulator : accumulator.concat(current),
      []
    )

    fetchPageData({
      pageNumber: remotePageIndex,
      pluginSorters: remoteSortedColumns.current,
      pluginFilters: remoteFilteredColumns.current,
      forceLoad: true
    })
  }

  function getIsColumnRemoteFiltered(columnId) {
    return remoteFilteredColumns.current.some((column) => column.field === columnId)
  }

  function clearColumnRemoteFilter(columnId) {
    remoteFilteredColumns.current = remoteFilteredColumns.current.reduce(
      (accumulator, current) =>
        current.field === columnId ? accumulator : accumulator.concat(current),
      []
    )

    fetchPageData({
      pageNumber: remotePageIndex,
      pluginSorters: remoteSortedColumns.current,
      pluginFilters: remoteFilteredColumns.current,
      forceLoad: true
    })
  }

  function resetAllRemoteSorting() {
    remoteSortedColumns.current = []

    fetchPageData({
      pageNumber: remotePageIndex,
      pluginSorters: remoteSortedColumns.current,
      pluginFilters: remoteFilteredColumns.current,
      forceLoad: true
    })
  }

  function resetAllRemoteFilters() {
    remoteFilteredColumns.current = []

    fetchPageData({
      pageNumber: remotePageIndex,
      pluginSorters: remoteSortedColumns.current,
      pluginFilters: remoteFilteredColumns.current,
      forceLoad: true
    })
  }

  function getColumnMenuItems(params) {
    return [
      {
        name: 'Clear Sorting',
        icon: 'fa fa-fw fa fa-minus',
        disabled: remotePaging
          ? !getIsColumnRemoteSorted(params.column.id)
          : !params.column.getIsSorted(),
        onClick: () =>
          remotePaging ? clearColumnRemoteSorting(params.column.id) : params.column.clearSorting()
      },
      {
        name: 'Clear Filters',
        icon: 'fa fa-fw fa fa-minus',
        disabled: remotePaging
          ? !getIsColumnRemoteFiltered(params.column.id)
          : !params.column.getIsFiltered(),
        onClick: () =>
          remotePaging
            ? clearColumnRemoteFilter(params.column.id)
            : params.column.setFilterValue(undefined)
      },
      {
        name: 'Settings',
        icon: 'fa fa-fw fa fa-cog',
        subMenu: [
          {
            name: 'Clear All Sorting',
            icon: 'fa fa-fw fa fa-minus',
            onClick: () =>
              remotePaging ? resetAllRemoteSorting() : params.table.resetSorting(true)
          },
          {
            name: 'Clear All Filters',
            icon: 'fa fa-fw fa fa-minus',
            onClick: () =>
              remotePaging ? resetAllRemoteFilters() : params.table.resetColumnFilters(true)
          }
        ]
      }
    ]
  }

  function resolvePageSize() {
    const currentPageSize = getPageSize()

    if (isNil(currentPageSize)) {
      return remotePageSize.current
    }

    return currentPageSize
  }

  function resolveTotalRowCount() {
    const currentTotalRowCount = getTotalRowCount()

    if (isNil(currentTotalRowCount)) {
      if (!isNil(actualFilters.IssueCount) && isNil(data.rowcount)) {
        return actualFilters.IssueCount
      }

      return remoteTotalRowCount.current
    }

    return currentTotalRowCount
  }

  function composeColumns(schema: any = [], columnSettings = {}) {
    const actionButtonColumns =
      config.actionButtons?.reduce(
        (accumulator, { name, isEnabled }) =>
          isEnabled
            ? accumulator.concat({
                id: name,
                tooltipText: columnSettings[name]?.tooltipText,
                header: '',
                cell: (cell: any) => {
                  const { color, iconName, label } = columnSettings[name] ?? {}

                  return (
                    <button
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation()
                        actionButtonHandlersRef.current[name](cell.row.index)
                      }}
                      style={{ all: 'unset' }}
                    >
                      <i className={iconName} style={{ color, fontSize: 16, cursor: 'pointer' }} />{' '}
                      {label ? <span>{label}</span> : null}
                    </button>
                  )
                }
              })
            : accumulator,
        []
      ) ?? []
    const columns = schema?.map(
      ({ fieldName, dataType }: { fieldName: string; dataType: 'string' }) => {
        const { defaultColumn = {} } = columnSettings ?? {}
        const {
          aggregation = defaultColumn.aggregation,
          align = defaultColumn.align,
          alignHeader = defaultColumn.alignHeader,
          type = defaultColumn.type,
          format = defaultColumn.format,
          pin = defaultColumn.pin,
          aggregationLabel = defaultColumn.aggregationLabel,
          width = defaultColumn.width,
          editable
        } = columnSettings[fieldName] ?? {}

        return {
          header: fieldName,
          accessorKey: fieldName,
          ...(dataType === 'datetime' ? { filterFn: 'datetime' } : {}),
          ...(dataType === 'int' || dataType === 'double' ? { filterFn: 'number' } : {}),
          ...(dataType === 'string' ? { filterFn: 'includesString' } : {}),
          meta: {
            editable,
            pin,
            type,
            alignHeader: alignments[alignHeader],
            aggregation,
            aggregationLabel,
            dataType,
            format,
            style: {
              justifyContent: alignments[align]
            }
          }
        }
      }
    )

    return actionButtonColumns.concat(columns)
  }

  function getActionButtonReturnTypes(actionButton: {
    name: string
    isEnabled: boolean
    returnParams: Array<{
      name: string
      type: 'boolean' | 'datetime' | 'dynamic' | 'int' | 'string'
    }>
  }) {
    return actionButton.returnParams.reduce(
      (accumulator, { name, type }) =>
        Object.assign(accumulator, {
          [name]: PluginTypes[type]
        }),
      {}
    )
  }

  function composeColumnDefColumns(columnDefs, schema, columnSettings = {}) {
    return columnDefs.map((column) => {
      const { defaultColumn = {} } = columnSettings ?? {}
      const {
        aggregation = isNil(column.align) ? defaultColumn.aggregation : column.align,
        align = isNil(column.align) ? defaultColumn.align : column.align,
        alignHeader = isNil(column.alignHeader) ? defaultColumn.alignHeader : column.alignHeader,
        type = isNil(column.type) ? defaultColumn.type : column.type,
        format = isNil(column.format) ? defaultColumn.format : column.format,
        pin = isNil(column.pin) ? defaultColumn.pin : column.pin,
        aggregationLabel = isNil(column.aggregationLabel)
          ? defaultColumn.aggregationLabel
          : column.aggregationLabel,
        width = isNil(column.width) ? defaultColumn.width : column.width
      } = columnSettings[column.accessor] ?? {}
      const { dataType } = schema.find(({ fieldName }) => fieldName === column.accessor) ?? {}
      const isActionButton = !isEmpty(column.iconName)

      return {
        id: column.id,
        size: isNil(width) ? avgColumnWidth : width,
        header: column.header,
        tooltipText: column.tooltipText,
        accessorKey: column.accessor,
        ...(dataType === 'datetime' ? { filterFn: 'datetime' } : {}),
        ...(dataType === 'int' || dataType === 'double' ? { filterFn: 'number' } : {}),
        ...(dataType === 'string' ? { filterFn: 'includesString' } : {}),
        ...(isActionButton
          ? {
              cell: (cell: any) => {
                return (
                  <button
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation()
                      actionButtonHandlersRef.current[column.id](cell.row.index)
                    }}
                    style={{ all: 'unset' }}
                  >
                    <i
                      className={column.iconName}
                      style={{ color: column.color, fontSize: 16, cursor: 'pointer' }}
                    />{' '}
                    {column.label ? <span>{column.label}</span> : null}
                  </button>
                )
              }
            }
          : {}),
        columns:
          column.columns?.length > 0
            ? composeColumnDefColumns(column.columns, schema, columnSettings)
            : [],
        meta: {
          pin,
          type,
          size: width,
          aggregation,
          aggregationLabel,
          dataType,
          format,
          alignHeader: alignments[alignHeader],
          style: {
            justifyContent: alignments[align]
          }
        }
      }
    })
  }

  function registerMethods() {
    registerMethod({
      key: 'setColumnSettings',
      fn: handleSetColumnSettings,
      args: [{ name: 'columnSettings', type: PluginTypes.string }]
    })
    registerMethod({
      key: 'setTableSettings',
      fn: handleSetTableSettings,
      args: [{ name: 'tableSettings', type: PluginTypes.string }]
    })
    registerMethod({
      key: 'setColumnDef',
      fn: handleSetColumnDef,
      args: [{ name: 'columnDef', type: PluginTypes.string }]
    })
    registerMethod({
      key: 'clearAllFilters',
      fn: handleClearAllFilters,
      args: []
    })
  }

  function registerEvents() {
    if (isEmpty(data.schema)) return

    config.actionButtons?.forEach(
      (actionButton: {
        name: string
        isEnabled: boolean
        returnParams: Array<{
          name: string
          type: 'boolean' | 'datetime' | 'dynamic' | 'int' | 'string'
        }>
      }) => {
        if (actionButton.isEnabled) {
          actionButtonHandlersRef.current[actionButton.name] = registerEvent({
            key: actionButton.name,
            fn: (rowIndex: number) => handleClickActionButton(actionButton, rowIndex),
            returnTypes: getActionButtonReturnTypes(actionButton)
          })
        }
      }
    )
    rowClickHandlerRef.current = registerEvent({
      key: 'rowClick',
      fn: (rowIndex) => {
        return config.events?.rowClick?.returnParams?.reduce(
          (accumulator, { name }) =>
            Object.assign(accumulator, {
              [name]: pluginData[rowIndex][name]
            }),
          {}
        )
      },
      returnTypes:
        config.events?.rowClick?.returnParams?.reduce(
          (accumulator, current) =>
            Object.assign(accumulator, { [current.name]: PluginTypes[current.type] }),
          {}
        ) ?? {}
    })
    cellEditHandlerRef.current = registerEvent({
      key: 'cellEdit',
      fn: (cell, value) => {
        return {
          value,
          ...config.events?.cellEdit?.returnParams?.reduce(
            (accumulator, { name }) =>
              Object.assign(accumulator, {
                [name]: pluginData[cell.row.index][name]
              }),
            {}
          )
        }
      },
      returnTypes: {
        value: (() => {
          const editableColumn = !isEmpty(columnDefSettings)
            ? columnDefSettings.find((column) => column.editable)
            : Object.entries(columnSettings).find(([, value]) => value.editable)

          const { dataType = 'string' } =
            data.schema.find((item) => item.fieldName === editableColumn?.at(0)) ?? {}

          return PluginTypes[dataType]
        })(),
        ...config.events?.cellEdit?.returnParams?.reduce(
          (accumulator, current) =>
            Object.assign(accumulator, { [current.name]: PluginTypes[current.type] }),
          {}
        )
      }
    })
    setAvgColumnWidth(calcAvgColumnWidth(columnDefSettings))
  }

  function getCellStyle({ row }) {
    if (isNil(row.original.rowBgColor)) return

    return { backgroundColor: row.original.rowBgColor }
  }

  function getTableProps(): SlvyTableProps<any, any> {
    const isReady =
      initalLoadComplete.current === undefined ? pluginData?.length > 0 : initalLoadComplete.current
    if (!isReady) {
      return {
        isLoading: !isReady,
        noDataMessage: config.settings?.noDataMessage
      }
    }

    initalLoadComplete.current = true
    const columns = !isEmpty(columnDefSettings)
      ? composeColumnDefColumns(columnDefSettings, data.schema, columnSettings)
      : composeColumns(data.schema, columnSettings)
    const initialState = {
      columnPinning: {
        left: columns
          ?.filter((column: Record<string, any>) => column.meta?.pin)
          ?.map((column: Record<string, any>) => column.accessorKey)
      }
    }

    remoteTotalRowCount.current = resolveTotalRowCount()
    remotePageSize.current = resolvePageSize()

    return {
      columns,
      data: tableSettings?.flatTreeData ? treeify(pluginData) : pluginData,
      initialState,
      pagination: tableSettings?.pagination ?? true,
      aggregation:
        tableSettings?.aggregation ??
        Object.values(columnSettings ?? {})?.some((column) => column?.aggregation),
      density: tableSettings?.density,
      theme: 'sencha-grid',
      getSubRows: (row) => row.Children,
      getColumnMenuItems,
      textWrap: tableSettings?.textWrap,
      ...(isEmpty(tableSettings?.paginationSettings)
        ? {}
        : {
            paginationOpts: tableSettings?.paginationSettings
          }),
      rowSelection: true,
      remotePaging,
      getBodyStyle: { marginBottom: 0 },
      onEditCell: handleEditCell,
      getCellStyle,
      pagingProps: {
        totalRowCount: remoteTotalRowCount.current,
        pageSize: remotePageSize.current,
        nextPage: () => {
          fetchPageData({
            pageNumber: remotePageIndex + 1,
            pluginSorters: [],
            pluginFilters: [],
            forceLoad: true
          })
          setRemotePageIndex(remotePageIndex + 1)
        },
        previousPage: () => {
          fetchPageData({
            pageNumber: remotePageIndex - 1,
            pluginSorters: [],
            pluginFilters: remoteFilteredColumns.current,
            forceLoad: true
          })
          setRemotePageIndex(remotePageIndex - 1)
        },
        gotoPage: (index: number) => {
          fetchPageData({
            pageNumber: index,
            pluginSorters: [],
            pluginFilters: remoteFilteredColumns.current,
            forceLoad: true
          })
          setRemotePageIndex(index)
        },
        firstPage: () => {
          fetchPageData({
            pageNumber: 1,
            pluginSorters: [],
            pluginFilters: remoteFilteredColumns.current,
            forceLoad: true
          })
          setRemotePageIndex(1)
        },
        lastPage: () => {
          const nextRemotePageIndex = Math.ceil(
            remoteTotalRowCount.current / remotePageSize.current
          )
          fetchPageData({
            pageNumber: nextRemotePageIndex,
            pluginSorters: [],
            pluginFilters: remoteFilteredColumns.current,
            forceLoad: true
          })
          setRemotePageIndex(nextRemotePageIndex)
        },
        pageIndex: remotePageIndex,
        pageCount: Math.ceil(remoteTotalRowCount.current / remotePageSize.current),
        canNextPage:
          remotePageIndex + 1 <= Math.ceil(remoteTotalRowCount.current / remotePageSize.current),
        canPreviousPage: remotePageIndex - 1 >= 1,
        sort: (column) => {
          if (
            isEmpty(remoteSortedColumns.current) ||
            !Object.hasOwn(remoteSortedColumns.current[0], column)
          ) {
            remoteSortedColumns.current = [{ [column]: 'DESC' }]
          } else if (
            !isEmpty(remoteSortedColumns.current) &&
            Object.hasOwn(remoteSortedColumns.current[0], column) &&
            Object.values(remoteSortedColumns.current[0])[0] === 'DESC'
          ) {
            remoteSortedColumns.current = [{ [column]: 'ASC' }]
          } else if (
            !isEmpty(remoteSortedColumns.current) &&
            Object.hasOwn(remoteSortedColumns.current[0], column) &&
            Object.values(remoteSortedColumns.current[0])[0] === 'ASC'
          ) {
            remoteSortedColumns.current = []
          }
          fetchPageData({
            pageNumber: remotePageIndex,
            pluginSorters: remoteSortedColumns.current,
            pluginFilters: remoteFilteredColumns.current,
            forceLoad: true
          })
        },
        getIsColumnSorted: (column) => {
          const value = Object.hasOwn(remoteSortedColumns.current[0] ?? {}, column)
            ? Object.values(remoteSortedColumns.current[0])[0].toLowerCase()
            : false
          return value
        },
        getIsColumnFiltered: getIsColumnRemoteFiltered,
        setFilterValue: (column, value, operator) => {
          const { dataType } =
            data.schema.find((item) => item.fieldName === column.columnDef.accessorKey) ?? {}
          if (isEmpty(remoteFilteredColumns.current)) {
            remoteFilteredColumns.current.push({
              field: column.columnDef.accessorKey,
              dataType,
              operator: operators[operator],
              value
            })

            fetchPageData({
              pageNumber: remotePageIndex,
              pluginSorters: remoteSortedColumns.current,
              pluginFilters: remoteFilteredColumns.current,
              forceLoad: true
            })
          } else {
            const exist = remoteFilteredColumns.current.some(
              (item) =>
                item.field === column.columnDef.accessorKey && item.operator === operators[operator]
            )

            if (exist) {
              if (value === undefined) {
                remoteFilteredColumns.current = remoteFilteredColumns.current.reduce(
                  (accumulator, current) =>
                    current.field === column.id && current.operator === operators[operator]
                      ? accumulator
                      : accumulator.concat(current),
                  []
                )
              } else {
                remoteFilteredColumns.current = remoteFilteredColumns.current.map((item) =>
                  item.field === column.columnDef.accessorKey ? { ...item, value } : item
                )
              }
            } else {
              remoteFilteredColumns.current.push({
                field: column.columnDef.accessorKey,
                dataType,
                operator: operators[operator],
                value
              })
            }

            fetchPageData({
              pageNumber: remotePageIndex.current,
              pluginSorters: remoteSortedColumns.current,
              pluginFilters: remoteFilteredColumns.current,
              forceLoad: true
            })
          }
        },
        getFilterValue: (column, params) => {
          if (params?.type === 'number') {
            const { value } =
              remoteFilteredColumns.current.find(
                (item) =>
                  item.field === column.columnDef.accessorKey &&
                  item.operator === operators[params.operator]
              ) ?? {}
            return value
          }

          if (params?.type === 'date') {
            console.log('date')
          }

          const { value } =
            remoteFilteredColumns.current.find(
              (item) => item.field === column.columnDef.accessorKey
            ) ?? {}

          return value
        }
      },
      onSelectRow: handleSelectRow
    }
  }

  function calcAvgColumnWidth(columns) {
    const leafColumns = collectNodes(
      { columns } ?? {},
      (node) => {
        if (!node.columns?.length) return true

        return false
      },
      {
        getChildren: (node) => node.columns
      }
    )
    const leafColumnsWithWidth = leafColumns.filter((column) => !isNil(column.width))
    const leafColumnsWithoutWidth = leafColumns.filter((column) => isNil(column.width))
    const leafColumnWidthSum = leafColumnsWithWidth.reduce(
      (accumulator, current) => accumulator + current.width,
      0
    )
    const { width } = dataTableRef.current.getBoundingClientRect()
    const bordersWidthSum = leafColumnsWithWidth.length
    const avgColumnWidth =
      (width - (leafColumnWidthSum + bordersWidthSum)) /
      (leafColumns.length - leafColumnsWithWidth.length)
    const regularColumnWidth = 150
    return leafColumnWidthSum + leafColumnsWithoutWidth.length * regularColumnWidth < width
      ? avgColumnWidth
      : null
  }

  return (
    <div
      ref={dataTableRef}
      className={tableSettings?.rowCountFooter ? styles.isRowCountFooterVisible : ''}
    >
      <SlvyTable {...getTableProps()} />
      {tableSettings?.rowCountFooter && pluginData.length ? (
        <div className={styles.rowCountFooter}>Row Count: {pluginData.length}</div>
      ) : null}
    </div>
  )
}
