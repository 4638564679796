Ext.define('po.cmp.SubstituteGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'substitutegrid',
  alias: 'widget.substitutegrid',
  header: false,
  bodyStyle: {
    background: '#fff'
  },
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView(),
      grouping = normalView.getFeature('grouping')
    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    }
  ],
  columns: [
    {
      dataIndex: 'urunKodu',
      text: 'Ürün Kodu',
      bind: {
        text: '{i18n.SubstituteGrid_Column_urunKodu}'
      },
      width: 100
    },
    {
      dataIndex: 'urunAciklamasi',
      text: 'Ürün',
      flex: 1,
      bind: {
        text: '{i18n.SubstituteGrid_Column_urunAciklamasi}'
      },
      width: 164,
      align: 'left'
    },
    {
      dataIndex: 'tedarikciKodu',
      text: 'Tedarikçi Kodu',
      bind: {
        text: '{i18n.SubstituteGrid_Column_tedarikciKodu}'
      },
      width: 100
    },
    {
      dataIndex: 'tedarikciAdi',
      text: 'Tedarikçi Adı',
      bind: {
        text: '{i18n.SubstituteGrid_Column_tedarikciAdi}'
      },
      width: 117,
      align: 'left'
    },
    {
      dataIndex: 'koliIciMiktar',
      text: 'koliIciMiktar',
      bind: {
        text: '{i18n.Substitute_Column_koliIciMiktar}',
        tooltip: '{i18n.Substitute_Column_koliIciMiktar_tooltip}'
      },
      xtype: 'numbercolumn',
      format: '0',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      align: 'right',
      width: 62
    },
    {
      dataIndex: 'paletKatsayisi',
      text: 'paletKatsayisi',
      xtype: 'numbercolumn',
      format: '0',
      bind: {
        text: '{i18n.Substitute_Column_paletKatsayisi}',
        tooltip: '{i18n.Substitute_Column_paletKatsayisi_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      width: 60,
      align: 'right'
    },
    {
      sortable: true,
      dataIndex: 'degisenOneriKoli',
      bind: {
        text: '{i18n.SubstituteGrid_Column_degisenOneriKoli}'
      },
      width: 120,
      align: 'right',
      renderer: function (v) {
        return (
          "<span style='font-size:1em;color:#FF8600;font-weight:600'>" +
          Ext.util.Format.number(v, '0') +
          '</span>'
        )
      },
      getEditor: function (record) {
        if (record.get('IsEditable') == 0) {
          return null
        }
        return Ext.create('Ext.form.field.Number', {
          editable: false,
          value: record.get('degisenOneriKoli'),
          minValue: record.get('minSiparisMiktari'),
          step: parseInt(record.get('siparisKatsayisi'))
        })
      }
    }
  ]
})
