Ext.define(
  'MDM.src.ux.ErrorBox',
  {
    extend: 'Ext.window.MessageBox',
    alwaysOnTop: true,
    scrollable: true,
    buttonText: {
      yes: 'Close',
      no: 'Copy to clipborad'
    },

    buttonUI: {
      ok: 'btn-success',
      yes: 'btn-success',
      no: 'btn-success',
      cancel: 'btn-success'
    },
    ui: 'window-inverse',
    makeButton: function (btnIdx) {
      var btnId = this.buttonIds[btnIdx]
      return new Ext.button.Button({
        handler: this.btnCallback,
        itemId: btnId,

        scope: this,
        text: this.buttonText[btnId],
        ui: this.buttonUI[btnId],
        minWidth: 75
      })
    },

    show: function (cfg) {
      var me = this,
        baseId = me.id,
        icon = cfg.icon,
        detailsHtml = '<pre><code>' + cfg.details + '</code></pre>',
        detailsFieldSet = me.down('#' + baseId + '-fieldset'),
        title,
        color
      //cfg.buttons=

      if (detailsFieldSet) {
        detailsFieldSet.destroy()
      }

      if (cfg.details) {
        if (icon.indexOf('info') != -1) {
          title = 'Info'
          color = '#00529B'
        } else if (icon.indexOf('warning') != -1) {
          title = 'Warning'
          color = '#9F6000'
        } else if (icon.indexOf('error') != -1) {
          title = 'Error'
          color = '#D8000C'
        }

        title += ' Details'

        // me.add({
        //     xtype: 'fieldset',
        //     id: baseId + '-fieldset',
        //     collapsible: true,
        //     scrollable: true,
        //     collapsed: true,

        //     maxHeight: 320,
        //     title: title,
        //     style: {
        //         color: color

        //     },
        //     html: detailsHtml,
        //     listeners: {
        //         expand: function () {
        //             me.center();
        //         },
        //         collapse: function () {
        //             me.center();
        //         }
        //     }
        // });
      }

      me = me.callParent(arguments)
      return me
    }
  },

  function (ErrorBox) {
    /**
     * @class Ext.DetailsBox
     * @alternateClassName Ext.Details
     * @extends Ext.window.DetailsBox
     * @singleton
     * @inheritdoc Ext.window.DetailsBox
     */
    MDM.src.ux.ErrorBox = MDM.src.ux.ErrorBox = new ErrorBox({ scrollable: false })
  }
)
