import React, { Component } from 'react'
import { Popover } from 'react-bootstrap'
import _ from 'lodash'
import { CoverBox } from '../../../../components'
import { round, format, increase, val, isMaxNumber } from '../../../../utils'

export default class UpdateFormProduct extends Component {
  constructor(props) {
    super(props)

    this.popoverInfo = this.popoverInfo.bind(this)
  }

  popoverInfo() {
    const productionData = [
      {
        ProductLine: 'Sakir 1',
        ProductionPercent: '87%',
        Productions: '17/21'
      },
      {
        ProductLine: 'Cafer 2',
        ProductionPercent: '103%',
        Productions: '21/21'
      },
      {
        ProductLine: 'Nurilla 3',
        ProductionPercent: '50%',
        Productions: '6/21'
      }
    ]

    return (
      <Popover id="popover-info">
        <div className="lineUtilizationVisibility" style={{ display: ' none' }}>
          {_.map(productionData, (item, index) => {
            const { ProductLine, ProductionPercent, Productions } = item

            return (
              <ul key={index}>
                <li className="lineIndicator">
                  <span className="slvy-ui-icon-circle-fill" />
                </li>
                <li className="productLineName">{ProductLine}</li>
                <li className="ProductionPercent">{ProductionPercent}</li>
                <li className="Productions">{Productions}</li>
              </ul>
            )
          })}
        </div>
      </Popover>
    )
  }

  render() {
    let {
      data,
      data: {
        NumberOfLines = 0,
        NumberOfUsedLines = 0,
        NetRequirement = 0,
        Production: Planned = 0,
        Values = {}
      } = {},
      period = {},
      connectApi,
      productId = 0,
      viewHandler,
      settings: {
        coverSettings = {},
        appSettings: { resourceView: { isEnabled: isResourceViewEnabled = true } = {} } = {},
        productPlanSettings = [],
        customMOQSettings: { overrideDefaults = false, customMOQValues = [] } = {},
        stateHandler: {
          selectedParameter: { Index: SelectedParamIndex = 0, name: Abbreviation = '' }
        },
        productInfoSettings = {}
      },
      productPlanHandler,
      productPlanIndex,
      historyHandler,
      productItem = {},
      productItem: { Values: ProductValues = {} },
      coverViolationIndexes
    } = this.props
    const { Name: PeriodName = '', DisplayName: periodDisplayName = '' } = period

    const IsPopover = _.isEmpty(period)

    NetRequirement = val(Values, 'NetRequirement', SelectedParamIndex)
    Planned = val(Values, 'Production', SelectedParamIndex)

    const max = round(Math.max(NetRequirement, Planned))
    const calcPlannedValue = Planned > 0 && max > 0 ? round((Planned * 100) / max) : 0
    const calcNetRequirement =
      NetRequirement > 0 && max > 0 ? round((NetRequirement * 100) / max) : 0

    const productInfoCoverSettings = _.find(productInfoSettings, ({ tableName }) =>
      _.includes(tableName, 'Cover')
    )
    const { format: formatType = '0,0' } = productInfoCoverSettings || {}
    coverSettings = { ...coverSettings, formatType }

    return (
      <form
        className="-box rccp-production-view-popup"
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        {isResourceViewEnabled && (
          <div className="-box-row -r-row">
            <label>Num. of lines</label>
            <b
              className="-group-btn"
              onClick={
                !IsPopover
                  ? () => {
                      connectApi('GetRccpResourcePeriodResponse', { productId }).then(() => {
                        viewHandler('resourceView', 'pushPage')
                      })
                    }
                  : () => {}
              }
            >
              <i className="btn-get-resource slvy-ui-icon-export-box" />
              {NumberOfUsedLines}/{NumberOfLines}
            </b>
          </div>
        )}

        {/* <OverlayTrigger
                        trigger="click"
                        placement="right"
                        rootClose
                        overlay={this.popoverInfo()}
                    >
                        <span className="slvy-ui-icon-info" />
                    </OverlayTrigger> */}

        {!_.isEmpty(periodDisplayName || PeriodName) && (
          <div className="-box-row">
            <h3>{periodDisplayName || PeriodName}</h3>
          </div>
        )}

        {overrideDefaults
          ? _.map(customMOQValues, (customMOQValue, index) => {
              const { enable, name, tableName } = customMOQValue

              if (_.has(ProductValues, tableName)) {
                let MOQValue = val(ProductValues, tableName, SelectedParamIndex)
                MOQValue = isMaxNumber(MOQValue) ? 'NA' : format(MOQValue)
                return (
                  enable && (
                    <div key={`${tableName}-${index}`} className="-box-row">
                      <label>
                        {name} [{Abbreviation}]
                      </label>
                      <b>{MOQValue}</b>
                    </div>
                  )
                )
              }
              let MOQValue = productItem[tableName]
              MOQValue = _.isNumber(MOQValue) ? format(MOQValue) : MOQValue
              MOQValue = isMaxNumber(MOQValue) ? 'NA' : MOQValue
              return (
                enable && (
                  <div key={`${tableName}-${index + 1}`} className="-box-row">
                    <label>{name}</label>
                    <b>{MOQValue}</b>
                  </div>
                )
              )
            })
          : [
              <div key="MinProduction" className="-box-row">
                <label>MOQ</label>
                <b>{format(val(ProductValues, 'MinProduction', SelectedParamIndex))}</b>
              </div>,

              <div key="LotSize" className="-box-row">
                <label>Lot Size</label>
                <b>{format(val(ProductValues, 'LotSize', SelectedParamIndex))}</b>
              </div>
            ]}

        <div className="-box-row -b-chart">
          <label>Planned vs Requirement</label>
          <ul className="-common-list">
            <li style={{ width: `${increase(calcPlannedValue)}%` }}>{format(Planned)}</li>
            <li style={{ width: `${increase(calcNetRequirement)}%` }}>{format(NetRequirement)}</li>
          </ul>
        </div>

        <div className="-box-row-ctn">
          {_.map(productPlanSettings, (productPlanItem, index) => {
            const { enable, name, tableName } = productPlanItem
            const currentSelectedValue = val(Values, tableName, SelectedParamIndex)

            return (
              enable && (
                <div
                  key={index}
                  className={`-box-row ${productPlanIndex === index ? '-active' : ''}`}
                  onClick={(e) => {
                    productPlanHandler(index)
                    if (!_.isEmpty(period)) {
                      historyHandler({
                        key: 'UpdateFormProduct',
                        state: { productPlanItem: tableName }
                      })
                    }
                  }}
                >
                  <label>
                    {name} [{Abbreviation}]
                  </label>
                  <b>{format(currentSelectedValue)}</b>
                </div>
              )
            )
          })}
        </div>

        <div className="-box-row -b-chart">
          <CoverBox
            coverSettings={coverSettings}
            coverViolationIndexes={coverViolationIndexes}
            data={data}
          />
        </div>
      </form>
    )
  }
}
