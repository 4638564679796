import { IAggFuncParams } from 'ag-grid-community'

const dateMaxParams = {
  colDef: { aggFunc: 'date max' },
  values: ['2022-01-01', '2022-03-15', '2022-02-10', '']
} as IAggFuncParams

const dateMinParams = {
  colDef: { aggFunc: 'date min' },
  values: ['2022-01-01', '2022-03-15', '2022-02-10', '']
} as IAggFuncParams

const dateMaxEmptyValueParams = {
  colDef: { aggFunc: 'date max' },
  values: ['']
} as IAggFuncParams

const zeroValueParams = {
  colDef: { aggFunc: 'date max' },
  values: ['2022-01-01', '2022-03-15', '2022-02-10', '', 0, '2022-04-20']
} as IAggFuncParams

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})

// eslint-disable-next-line jest/no-export
export { dateMaxParams, dateMinParams, dateMaxEmptyValueParams, zeroValueParams }
