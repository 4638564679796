import { useCallback } from 'react'
import { GridOptions } from 'ag-grid-community'
import { useHandleBooleanIcon } from '../index'
import { getEnableInfo } from '../../helpers/index'
import { AG_GRID_ENUMS } from '../../enums'
import { CellClickedEventParams, IRowData } from '../../types'
import { UseCellClickProps } from './useCellClick.types'

const { DELETE_COLUMN } = AG_GRID_ENUMS

function useCellClick(props: UseCellClickProps) {
  const { gridEditableByState, handleDeleteRow } = props
  const { handleBooleanIcon } = useHandleBooleanIcon()
  const onCellClicked: GridOptions<IRowData>['onCellClicked'] = useCallback(
    (event: CellClickedEventParams) => {
      const {
        colDef: {
          customInfo: {
            deletableCondition,
            boolean: { trueIcon, falseIcon }
          },
          field,
          editable
        },
        data
      } = event

      // Delete Column
      if (field === DELETE_COLUMN) {
        let isDeleteEnabled = true
        if (deletableCondition) {
          isDeleteEnabled = getEnableInfo(data, deletableCondition)
        }
        if (gridEditableByState && isDeleteEnabled) {
          handleDeleteRow(event)
        }
      }

      // BooleanIcon Column
      if (typeof editable === 'function' && editable(event) && trueIcon && falseIcon) {
        handleBooleanIcon(event)
      }
    },
    [gridEditableByState, handleDeleteRow, handleBooleanIcon]
  )
  return { onCellClicked }
}

export default useCellClick
