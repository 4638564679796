export default [
  {
    name: 'DispatchEvent',
    displayName: '',
    isVisible: true
  },
  {
    name: 'ImageURL',
    displayName: '',
    isVisible: true
  },
  {
    name: 'IsDisabled',
    displayName: 'Active',
    isVisible: true
  },
  {
    name: 'Name',
    displayName: 'Name',
    isVisible: true
  },
  {
    name: 'CalculatedSimilarityRate',
    displayName: 'Calculated Similarity Rate',
    isVisible: true
  },
  {
    name: 'SimilarityRate',
    displayName: 'Similarity Rate',
    isVisible: true
  },
  {
    name: 'Description',
    displayName: 'Product Description',
    isVisible: true
  },
  {
    name: 'BasePrice',
    displayName: 'Base Price',
    isVisible: true
  },
  {
    name: 'OverlappingRatio',
    displayName: 'Overlapping Ratio',
    isVisible: true
  },
  {
    name: 'StartTime',
    displayName: 'Start Date',
    isVisible: true
  },
  {
    name: 'EndTime',
    displayName: 'End Date',
    isVisible: true
  },
  {
    name: 'TotalSales',
    displayName: 'Total Sales',
    isVisible: true
  },
  {
    name: 'OverlappingTotalSales',
    displayName: 'Planned P. Sales',
    isVisible: true
  },
  {
    name: 'InitialInventory',
    displayName: 'Stock',
    isVisible: true
  },
  {
    name: 'StoreCount',
    displayName: 'Store Count',
    isVisible: true
  },
  {
    name: 'SimilarityDescription',
    displayName: 'Similarity Description',
    isVisible: true
  },
  {
    name: 'SimilarityDetails',
    displayName: 'Details',
    isVisible: true
  },
  {
    name: 'Fit',
    displayName: 'Fit',
    isVisible: true
  },
  {
    name: 'SizeRange',
    displayName: 'Size Range',
    isVisible: true
  }
]
