Ext.define('Ext.data.summary.Sum', {
  extend: 'Ext.data.summary.Base',

  alias: 'data.summary.sum',

  calculate: function (records, property, root, begin, end) {
    var n = end - begin,
      i,
      sum,
      v

    for (i = 0; i < n; ++i) {
      v = this.extractValue(records[begin + i], property, root)
      sum = i ? sum + v : v
    }

    return sum
  }
})

Ext.define('Spui.view.common.recom.Mixin', {
  extend: 'Ext.Mixin',

  config: {
    SpotId: null,
    recomGridCfg: 'recomGridCfg',
    recomStoreCfg: 'recomStoreCfg',
    recomFormCfg: 'recomFormCfg',
    recomForm: {},
    recomStore: {},
    recomGrid: {}
  },
  applyRecomGridCfg: function (c) {
    return window.ss(c)
  },
  applyRecomStoreCfg: function (c) {
    return window.ss(c)
  },
  applyRecomFormCfg: function (c) {
    return window.ss(c)
  },

  listeners: {
    activate: function (p) {
      var store = p.getRecomStore(),
        spotId = p.getSpotId()
      store.getProxy().setExtraParam('spotId', spotId)
      store.load()
    }
  },

  applyRecomForm: function (b) {
    var setRec = this.getRecomFormCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        xtype: 'panel',
        region: 'north',
        plugins: [
          {
            ptype: 'cornerIcon',
            extraButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                bind: {
                  hidden: '{!admin}'
                },
                menu: [
                  {
                    text: 'Grid Config',
                    handler: 'onConfigRecomFrom'
                  }
                ]
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    return b
  },
  onConfigRecomFrom: function () {
    var setRec = this.getRecomFormCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigRecomFormSave, this)
  },
  onConfigRecomFormSave: function (v, k) {
    var setRec = this.getRecomFormCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRecomForm(res)
  },
  updateRecomForm: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  applyRecomStore: function (b) {
    var me = this
    var setRec = this.getRecomStoreCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        fieldss: [
          {
            name: 'QTY',
            type: 'number'
          },
          {
            name: 'Price',
            type: 'number'
          },
          {
            name: 'NumberOfStore',
            type: 'number'
          },
          {
            name: 'Envanter',
            type: 'number'
          },
          {
            name: 'DateKey',
            type: 'string'
          },
          {
            name: 'IsSimilarTemp',
            type: 'int',

            convert: function (v) {
              if (typeof v === 'boolean') {
                return v ? 1 : 0
              } else {
                return parseInt(v, 10)
              }
            }
          },
          {
            name: 'PriceNull',
            convert: function (value, record) {
              var v = record.get('Price')

              if (!v) return null
              return v
            },

            depends: ['Price']
          },
          {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
              var v = record.get('NumberOfStore')

              if (!v) return null
              return v
            },

            depends: ['NumberOfStore']
          }
        ],
        autoSync: true,

        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          api: {
            create: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/create',
            read: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/read',
            update: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/update',
            destroy: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/destroy'
          },
          writer: {
            rootProperty: 'data',
            allowSingle: true,
            successProperty: 'success',
            messageProperty: 'errors',
            allDataOptions: {
              associated: false
            },
            writeAllFields: true
          },
          url: Spui.Global.getApiUrl() + 'spui/SmilarSpot'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)
    }
    return b
  },
  applyRecomGrid: function (b) {
    var setRec = this.getRecomGridCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        region: 'center',
        xtype: 'grid',
        plugins: [
          {
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
              edit: function (editor, context, eOpts) {
                if (context.originalValue == context.value) return
                var record = context.record,
                  grid = context.grid.ownerCt
                grid.setLoading(tt('Yükleniyor'))
                record.save({
                  failure: function (record, operation) {},
                  success: function (record, operation) {
                    grid.setLoading(false)
                    grid.store.load()
                  },
                  callback: function (record, operation, success) {
                    // do something whether the save succeeded or failed
                  }
                })
              },
              scope: 'this'
            }
          },
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',

                menu: [
                  {
                    text: 'Grid Config',
                    handler: 'onConfigRecomGrid'
                  },
                  {
                    text: 'store Config',
                    handler: 'onConfigRecomStore'
                  }
                ]
              }
            ]
          }
        ],
        flex: 1,
        store: this.getRecomStore(),
        columns: [
          {
            text: 'ID',
            dataIndex: 'ID',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'SpotId',
            dataIndex: 'SpotId',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          { text: 'MaterialCode', dataIndex: 'MaterialCode', width: 140 },
          {
            text: 'DCCode',
            dataIndex: 'DCCode',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'OnHandInventory_DC',
            dataIndex: 'OnHandInventory_DC',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'OnHandInventory_Store',
            dataIndex: 'OnHandInventory_Store',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'OpenOrder_DC',
            dataIndex: 'OpenOrder_DC',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'OpenOrder_Store',
            dataIndex: 'OpenOrder_Store',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'log_OrjinalOneri',
            dataIndex: 'log_OrjinalOneri',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'log_OrtalamaSonrasiOneri',
            dataIndex: 'log_OrtalamaSonrasiOneri',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'log_DepoKirilimiSonrasiOneri',
            dataIndex: 'log_DepoKirilimiSonrasiOneri',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'log_MagazaKisitSonrasiOneri',
            dataIndex: 'log_MagazaKisitSonrasiOneri',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'log_MagazaEnvanterSonrasiOneri',
            dataIndex: 'log_MagazaEnvanterSonrasiOneri',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'log_DepoEnvanterSonrasiOneri',
            dataIndex: 'log_DepoEnvanterSonrasiOneri',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'OrderRecommendation_DC',
            dataIndex: 'OrderRecommendation_DC',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'OrderRecommendation_DC_UpdatedbyMO',
            dataIndex: 'OrderRecommendation_DC_UpdatedbyMO',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'OrderRecommendation_DC_UpdatedbyDC',
            dataIndex: 'OrderRecommendation_DC_UpdatedbyDC',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'NumberofStores',
            dataIndex: 'NumberofStores',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          {
            text: 'NumberofRecommendedStores',
            dataIndex: 'NumberofRecommendedStores',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          { text: 'Comments', dataIndex: 'Comments', width: 100 },
          { text: 'LastModifiedBy', dataIndex: 'LastModifiedBy', width: 200 },
          { text: 'LastModifiedAt', dataIndex: 'LastModifiedAt', width: 100 },
          { text: 'CreatedAt', dataIndex: 'CreatedAt', width: 100 },
          { text: 'IsInclude', dataIndex: 'IsInclude', width: 100 },
          {
            text: 'StatusId',
            dataIndex: 'StatusId',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
          },
          { text: 'StatusChangedAt', dataIndex: 'StatusChangedAt', width: 100 },
          { text: 'StatusChangedBy', dataIndex: 'StatusChangedBy', width: 200 }
        ],
        flex: 1
      })

      b = Ext.create(b)
    }
    return b
  },
  onConfigRecomStore: function () {
    var setRec = this.getRecomStoreCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        snippetPanel: {
          record: setRec
        },
        value: settings,
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          // enableLiveAutocompletion: false,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigRecomStoreSave, this)
  },
  onConfigRecomStoreSave: function (v, k) {
    var setRec = this.getRecomStoreCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRecomStore(res)
  },
  onConfigRecomGrid: function () {
    var setRec = this.getRecomGridCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigRecomGridSave, this)
  },
  onConfigRecomGridSave: function (v, k) {
    var setRec = this.getRecomGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRecomGrid(res)
  },
  updateRecomGrid: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigRecomGrid: function () {
    var setRec = this.getRecomGridCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        snippetPanel: {
          record: setRec
        },
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigRecomGridSave, this)
  },

  onConfigRecomGridSave: function (v, k) {
    var setRec = this.getRecomGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRecomGrid(res)
  }
})
