export default {
  title: 'Map',
  subTitle: 'Map',
  version: '1.0',
  namespace: 'Map',
  description: '',
  iconClass: 'fa-map',
  color: 'lazur-bg',
  support: {
    maximizable: true,
    needfulldataforpreview: false,
    editable: true
  },
  group: 'Maps',
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: false
  }
}
