export const defaultFilterOptions = {
  showConfirmedProducts: true,
  showTestProducts: true,
  showAssignedProducts: true,
  showUnassignedProducts: true
}

export const defaultColumn = {
  enableResizing: false,
  meta: {
    hideColumnMenu: true
  }
}

export const emptyData = {
  assignedOptions: '0 / 0',
  firstLine: true,
  forecastedCost: 0,
  forecastedQuantity: 0,
  forecastedRevenue: 0,
  numberOfStores: 0
}
