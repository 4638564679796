import { ColorResult } from 'react-color'
import { SlvyColorPicker } from '@/components'
import traverseTree from '@/utils/traverseTree'
import { useReduxState } from '../../../hooks'
import { getLoadDetailById, getMapElements } from '../../../store/api/endpoints'
import { mapSlice, loadTreeTableSlice } from '../../../store/slices'
import IconButton from '../../IconButton'
import { CardControlsCellProps } from './CardControlsCell.types'
import { defaultCardColor, defaultRowColor } from './constants'

export default function CardControlsCell({ cell }: CardControlsCellProps) {
  const [map, mapDispatch] = useReduxState(mapSlice.selectSlice, mapSlice.actions)
  const [loadTreeTable, loadTreeDispatch] = useReduxState(
    loadTreeTableSlice.selectSlice,
    loadTreeTableSlice.actions
  )
  const [triggerGetLoadDetailById, loadDetailById] = getLoadDetailById.useMutation({
    fixedCacheKey: 'load-detail-by-id'
  })
  const mapElements = getMapElements.useQueryState({
    loadIds: loadTreeTable.loadStops,
    demandIds: [],
    loadSummaries: []
  })

  function handleOpenCard() {
    triggerGetLoadDetailById({ id: cell.row.original.LoadName }).then(() => {
      mapDispatch.setLoadCardLatLng({
        lat: mapElements.data?.data[0].MapObjects[0].Latitude,
        lng: mapElements.data?.data[0].MapObjects[0].Longitude
      })
      mapDispatch.setOpenLoadCardId(cell.row.original.LoadName)
      mapDispatch.setIsLoadCardOpen(true)
    })
  }

  function getColor() {
    const currentRowID = cell.row.original.ID
    const currentRowColor = loadTreeTable.rowColors[currentRowID]

    const parentRowID = cell.row.getParentRow()?.original?.ID
    const parentRowColor = loadTreeTable.rowColors[parentRowID]

    return currentRowColor ?? parentRowColor ?? defaultRowColor
  }

  function onColorChange(color: ColorResult) {
    const updatedRowColors = {}
    traverseTree(
      cell.row.original,
      (node) => {
        updatedRowColors[node.ID] = color.hex
        updatedRowColors[node.LoadName] = color.hex
      },
      {
        getChildren: (node) => node.Children
      }
    )

    const rowColors = { ...loadTreeTable.rowColors, ...updatedRowColors }
    loadTreeDispatch.setRowColors(rowColors)
  }

  const isLeaf = cell.row.original?.Leaf
  const triggerEvent = isLeaf ? 'none' : 'click'

  return (
    <>
      <IconButton className="px-1" onClick={() => {}}>
        <SlvyColorPicker
          picker="compact"
          trigger={<i className="fa fa-fw fa fa-circle fs-6" style={{ color: getColor() }} />}
          triggerEvent={triggerEvent}
          onChangeComplete={onColorChange}
        />
      </IconButton>
      {cell.row.original.Text === cell.row.original.LoadName ? (
        <IconButton tooltipText="Open Card" onClick={handleOpenCard}>
          <i className="slvy-ui-icon-card fs-6 p-1" style={{ color: defaultCardColor }} />
        </IconButton>
      ) : null}
    </>
  )
}
