export default ` ({
     title: 'Grid',
     columnLines: true,
     columns: [{
             text: 'DateKey',
             dataIndex: 'DateKey'
         },
         {
             text: 'QTY',
             dataIndex: 'QTY'
         },
         {
             text: 'Price',
             dataIndex: 'Price'
         }
     ],
 })
`
