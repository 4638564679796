Ext.define('clustering.cmp.StoreDetail', {
  extend: 'Ext.grid.Panel',
  xtype: 'storedetail',
  alias: 'widget.storedetail',
  enableLocking: true,
  height: 400,
  width: 750,
  columnLines: true,
  viewModel: {
    data: {
      //text: '', //@PS related to https://jira.solvoyo.com/browse/CUI-1118
      State: false
    }
  },
  bufferedRenderer: false,
  store: Ext.create('Ext.data.Store', {
    model: 'clustering.Model.StoreModel',
    autoLoad: false,
    proxy: {
      type: 'ajax',
      actionMethods: {
        read: 'POST'
      },
      reader: {
        rootProperty: 'Stores'
      },
      url: clustering.Global.getApiUrl() + 'cluster/GetStoreData'
    }
  }),
  initComponent: function () {
    var me = this

    var viewModel = me.mainViewModel

    var currentConfigurationData = viewModel.get('currentConfigurationData')

    var storeScope = currentConfigurationData.Scopes[0]

    var selectedStores = storeScope.Levels[storeScope.Levels.length - 1].SelectedIndices

    var isInitial = selectedStores.length === 0

    var counter = 0

    var selectedCount = 0
    var othersSelected = selectedStores.indexOf(-1) > -1

    me.store.on(
      'load',
      function (store) {
        store.suspendEvents()
        store.each(function (record) {
          if (othersSelected) {
            if (counter < 500) {
              if (selectedStores.indexOf(record.get('Id')) > -1) {
                record.set('Selected', true)
                selectedCount++
              }
            } else {
              record.set('Selected', true)
              selectedCount++
            }
          } else if (isInitial) {
            record.set('Selected', true)
            selectedCount++
          } else if (selectedStores.indexOf(record.get('Id')) > -1) {
            record.set('Selected', true)
            selectedCount++
          }
          counter++
        })

        if (store.getDatas() && store.getDatas().length === selectedCount) {
          me.viewModel.set('State', true)
          //@PS related to https://jira.solvoyo.com/browse/CUI-1118
          // me.viewModel.set(
          //   'text',
          //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn-checked" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
          // )
        }
        me.viewModel.set('selectedRowCount', selectedCount)

        store.resumeEvents()
        me.getView().refresh()
      },
      me
    )

    me.store.on(
      'beforeLoad',
      function (store) {
        var currentConfigurationData = viewModel.get('currentConfigurationData')

        var clone = $.extend(true, {}, currentConfigurationData)

        var proxy = store.getProxy()

        proxy.setExtraParam('configuration', JSON.stringify(clone))
      },
      me
    )

    me.store.load()
    me.callParent(arguments)
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    }
  ],
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    }
  ],
  // type, opendate, closedata, store name, square meter, indices
  columns: [
    {
      xtype: 'checkcolumn',
      //@PS related to https://jira.solvoyo.com/browse/CUI-1118
      // bind: {
      //   text: '{text}'
      // },
      //text: '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0 x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>',
      dataIndex: 'Selected',
      width: 90,
      sortable: false,
      locked: true,
      headerCheckbox: true,
      listeners: {
        headerClick: function (ct, column) {
          var grid = this.up().up()

          var gridView = grid.getView()

          var viewModel = grid.ownerCt.viewModel
          var store = grid.store

          var selectedRowCount = viewModel.get('selectedRowCount')

          var state = !viewModel.get('State')

          store.suspendEvents()
          store.each((element) => {
            element.set('Selected', state)
          })

          store.resumeEvents()
          store.each((element) => {
            gridView.refreshNode(element)
          })

          //@PS related to https://jira.solvoyo.com/browse/CUI-1118
          // if (state) {
          // column.setText(
          //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn-checked" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
          // )
          // } else {
          // column.setText(
          //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div'
          // )
          // }

          if (state) {
            selectedRowCount = store.getDatas().length
          } else if (!state) {
            selectedRowCount = 0
          }

          viewModel.set('State', state)
          viewModel.set('selectedRowCount', selectedRowCount)
        },
        beforecheckchange: function () {
          var grid = this.up().up()
          var store = grid.store

          store.suspendEvents()
        },
        checkchange: function (column, rowIndex, checked, record, e, eOpts) {
          var grid = this.up().up()
          var store = grid.store

          var viewModel = grid.ownerCt.viewModel

          var selectedRowCount = viewModel.get('selectedRowCount')
          store.resumeEvents()
          var gridView = grid.getView()
          gridView.refreshNode(record)

          var value = record.get('Selected')
          if (value) {
            selectedRowCount++
          } else {
            selectedRowCount--
          }

          var datas = store.getDatas()

          var length = datas.length

          if (length === 0) {
            //@PS related to https://jira.solvoyo.com/browse/CUI-1118
            // column.setText(
            //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
            // )
          } else if (selectedRowCount === length) {
            viewModel.set('State', true)
            //@PS related to https://jira.solvoyo.com/browse/CUI-1118
            // column.setText(
            //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn-checked" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
            // )
          } else {
            //@PS related to https://jira.solvoyo.com/browse/CUI-1118
            viewModel.set('State', false)
            // column.setText(
            //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
            // )
          }
          viewModel.set('selectedRowCount', selectedRowCount)
        }
      }
    },
    {
      text: 'Name',
      dataIndex: 'Name',
      locked: true,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      },
      width: 150
    },
    {
      text: 'Square Meter',
      dataIndex: 'SquareMeter',
      filter: {
        type: 'number'
      },
      align: 'right',
      width: 120
    },
    {
      text: 'Open Date',
      dataIndex: 'OpenDate',
      filter: {
        type: 'date'
      },
      renderer: function (value) {
        var date = Ext.Date.parse(value, 'Y-m-d\\TH:i:s.u')
        if (date == null) {
          date = Ext.Date.parse(value, 'Y-m-d\\TH:i:s')
          if (date == null) return '-'
        }

        if (date < new Date('01.01.2000')) return '-'

        return Ext.util.Format.date(date, 'Y-m-d')
      }
    },
    {
      text: 'Close Date',
      dataIndex: 'CloseDate',
      filter: {
        type: 'date'
      },
      renderer: function (value) {
        var date = Ext.Date.parse(value, 'Y-m-d\\TH:i:s.u')
        if (date == null) {
          date = Ext.Date.parse(value, 'Y-m-d\\TH:i:s')
          if (date == null) return '-'
        }

        if (date < new Date('01.01.2000')) return '-'

        return Ext.util.Format.date(date, 'Y-m-d')
      }
    },
    {
      dataIndex: 'Country',
      text: 'Country',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'Region',
      text: 'Region',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'State',
      text: 'State',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'City',
      text: 'City',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'Type',
      text: 'Type',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    },
    {
      dataIndex: 'Status',
      text: 'Status',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      }
    }
  ],
  dockedItems: [
    {
      xtype: 'toolbar',
      dock: 'bottom',
      reference: 'toolbar',
      overflowHandler: 'scroller',
      items: [
        '->',
        {
          text: 'Apply',
          handler: function (btn) {
            var grid = btn.up('grid')

            var store = grid.store

            var window = grid.up('window')

            var data = store.getDatas()
            var selectedIndices = data.filter(function (record) {
              if (record.Selected && record.Id) return record.Id
            })

            selectedIndices = selectedIndices.map(function (record) {
              return record.Id
            })

            // set to viewmodel

            var currentConfigurationData = grid.mainViewModel.get('currentConfigurationData')

            var lastLevel = currentConfigurationData.Scopes[0].Levels.length - 1

            currentConfigurationData.Scopes[0].Levels[lastLevel].SelectedIndices = selectedIndices

            grid.mainViewModel.set('currentConfigurationData', currentConfigurationData)

            var combo = Ext.getCmp('combo_0_' + lastLevel + '_combo')
            if (combo.store.data.items.length === 0) {
              combo.store.load(function () {
                setTimeout(() => {
                  combo.setValue(selectedIndices)
                }, 150)
              })
            } else {
              combo.setValue(selectedIndices)
            }

            store.removeAll()

            window.close()
          }
        }
      ]
    }
  ]
})
