Ext.define('MDM.src.view.globalapp.Panel.Publish.CalGrid', {
  extend: 'MDM.src.ux.GridPanel',
  xtype: 'globalapp-publish-callgrid',
  bodyPadding: 0,
  columns: [
    {
      text: 'Process Steps',
      xtype: 'templatecolumn',
      flex: 1,
      tpl: '<div class="foo" style="background-color: {Color};"></div>{Title}'
    },
    {
      xtype: 'datecolumn',
      text: 'Start Date',
      dataIndex: 'startDate',
      format: 'M d, Y',

      width: 150
    },
    {
      xtype: 'datecolumn',
      text: 'End Date',
      dataIndex: 'endDate',
      format: 'M d, Y',

      width: 150
    },
    {
      text: 'Duration',
      dataIndex: 'diff',
      widt: 70,
      xtype: 'templatecolumn',
      tpl: '{diff} days'
    }
  ]
})
