import { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { Button } from 'react-bootstrap'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import { fromJS } from 'immutable'
import withConnector from '../../BaseContainer'
import { View } from '..'
import SplitActions from './SplitActions'
import 'react-reflex/styles.css'
import './style.scss'
import containerValidation from '../../utils/containerValidation'

class FlexboxContainer extends Component {
  constructor(props) {
    super(props)
    this.intt = 0
    this.count = 0
    this.defaultColor = {
      r: '255',
      g: '255',
      b: '255',
      a: '0'
    }
    this.defaultElements = [
      { type: 'element', elements: [], config: {} },
      { type: 'element', elements: [], config: {} }
    ]

    const { data: { settings = {} } = {} } = this.props
    this.state = {
      settings,
      isDemo: false
    }

    this.bindMethods()
  }

  bindMethods() {
    this.afterAddPlugin = this.afterAddPlugin.bind(this)
    this.findPlugin = this.findPlugin.bind(this)
    this.handleAddPlugin = this.handleAddPlugin.bind(this)
    this.handlePluginMounted = this.handlePluginMounted.bind(this)
    this.handleRemoveView = this.handleRemoveView.bind(this)
    this.saveLayout = this.saveLayout.bind(this)
  }

  componentDidMount() {
    const {
      data: { settings: { name = '', theme = '0' } = {} } = {},
      params: { environment }
    } = this.props

    const isDemo = _.lowerCase(_.last(name.split('_'))) === 'demo' || theme === '1'
    const hasClassDemo = document.documentElement.className.match(/-doc-demo\b/)

    if (hasClassDemo === null && isDemo) {
      document.documentElement.classList.add('-doc-demo')
    }

    this.isConfiguration = environment === 'Configuration'

    this.setState({ isDemo })

    window.addEventListener('tabSelected', this.dispatchResize)
    window.addEventListener('tabPaneExited', this.dispatchResize)

    setTimeout(this.dispatchResize, 100)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data: { settings = {} } = {} } = nextProps
    if (!_.isEqual(settings, this.state.settings)) {
      this.setState({ settings })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('tabSelected', this.dispatchResize)
    window.removeEventListener('tabPaneExited', this.dispatchResize)
  }

  dispatchResize() {
    window.dispatchEvent(new Event('resize'))
  }

  generateRealPath(path) {
    const size = _.size(path)
    return _.transform(
      _.slice(path, 1),
      (r, foot, index) => {
        r.realPath.push('elements')
        r.realPath.push(foot)
        if (index < size - 2) {
          r.parentRealPath.push('elements')
          r.parentRealPath.push(foot)
        }
      },
      { realPath: [], parentRealPath: [] }
    )
  }

  getElementIds(item) {
    const { pluginId, elements = [] } = item
    const elementIds = []
    if (pluginId) elementIds.push(pluginId)
    return _.reduce(
      elements,
      (result, element) => _.concat(result, this.getElementIds(element)),
      elementIds
    )
  }

  saveLayout(key, value) {
    const settings = {
      ..._.cloneDeep(_.get(this.state, 'settings', {})),
      [key]: value
    }

    this.setState({ settings })

    clearInterval(this.intt)

    this.intt = setTimeout(() => {
      this.props.onSave(settings, this.getElementIds(value))
    }, 1000)
  }

  afterAddPlugin(path) {
    return (id) => {
      const { realPath } = this.generateRealPath(path)
      const { settings: { map = {} } = {} } = this.state
      const immutableJS = fromJS(map).updateIn(realPath, (element) => {
        return element.set('pluginId', id)
      })
      if (!containerValidation(this.props.containers, id)) {
        return
      }
      this.saveLayout('map', immutableJS.toJS())
    }
  }

  mapCount({ pluginId, elements = [] }) {
    return _.reduce(
      elements,
      (r, e) => {
        return r + this.mapCount(e)
      },
      pluginId ? 1 : 0
    )
  }

  handlePluginMounted() {
    this.count++
    const {
      settings: { map = {} }
    } = this.state
    if (this.mapCount(map) === this.count && this.props.onMounted) {
      this.props.onMounted()
    }
  }

  handleRemoveView(path) {
    return () => {
      const { realPath } = this.generateRealPath(path)
      const {
        settings: { map = {} }
      } = this.state
      let immutableJS = fromJS(map)
      immutableJS = immutableJS.deleteIn([...realPath, 'pluginId'])
      this.saveLayout('map', immutableJS.toJS())
    }
  }

  handleAddPlugin(path) {
    return () => {
      this.props.showAddPluginLayout(this.afterAddPlugin(path))
    }
  }

  findPlugin(path) {
    const { realPath } = this.generateRealPath(path)
    const {
      props: {
        containers,
        params,
        plugins,
        data: { id: containerId },
        params: { designMode }
      },
      state: { settings: { map = {} } = {} }
    } = this

    const immutableJS = fromJS(map)
    const pluginId = immutableJS.getIn([...realPath, 'pluginId'])

    if (!pluginId) {
      return (
        <div key={uuidv4()} className="-text-center">
          {this.isConfiguration && designMode ? (
            <Button size="sm" variant="success" onClick={this.handleAddPlugin(path)}>
              Add Element
            </Button>
          ) : null}
        </div>
      )
    }

    let type
    const plugin = _.find(plugins, (p) => p.id === pluginId)
    if (plugin) {
      type = 'plugin'
    } else {
      const container = _.find(containers, (p) => p.id === pluginId)
      if (container) {
        type = 'container'
      }
    }

    if (type) {
      return (
        <div key={`${type}-${pluginId}`} className={`${type}-under-reflex w-100 h-100`}>
          <View
            key={pluginId}
            containers={containers}
            draggableHandle={`move${containerId}`}
            id={pluginId}
            params={params}
            plugins={plugins}
            type={type}
            onMounted={this.handlePluginMounted}
            onRemovePlugin={this.handleRemoveView(path)}
          />
        </div>
      )
    }
  }

  handleRemove(path) {
    const { realPath, parentRealPath } = this.generateRealPath(path)
    const { settings: { map = {} } = {} } = this.state
    let immutableJS = fromJS(map)
    const elementsSize = immutableJS.getIn([...parentRealPath, 'elements']).size
    if (elementsSize > 2) {
      immutableJS = immutableJS
        .updateIn([...parentRealPath, 'elements'], (e) =>
          e.map((t) => t.deleteIn(['config', 'flex']))
        )
        .deleteIn(realPath)
    } else {
      const elementIndex = _.last(path)
      immutableJS = immutableJS.updateIn(parentRealPath, (element) =>
        element.getIn(['elements', Math.abs(elementIndex - 1)])
      )
    }
    if (immutableJS) {
      this.saveLayout('map', immutableJS.toJS())
    }
  }

  handleSplit(path, orientation = 'vertical') {
    const { settings: { map = {} } = {} } = this.state
    const { realPath, parentRealPath } = this.generateRealPath(path)

    let immutableJS = fromJS(map)

    const parentOrientation = immutableJS.getIn([...parentRealPath, 'orientation'])
    if (parentOrientation === orientation) {
      immutableJS = immutableJS.updateIn([...parentRealPath, 'elements'], (element) => {
        return element.splice(
          parseInt(realPath[3] !== undefined ? realPath[3] + 1 : realPath[1] + 1, 10),
          0,
          fromJS({
            type: 'element',
            elements: []
          })
        )
      })
    } else {
      immutableJS = immutableJS.setIn(
        realPath,
        fromJS({
          type: 'container',
          orientation,
          elements: [
            immutableJS.getIn(realPath, fromJS(this.defaultElements[0])),
            this.defaultElements[0]
          ]
        })
      )
    }

    this.saveLayout('map', immutableJS.toJS())
  }

  handleStopResize(path, previousFlex, event) {
    const { realPath } = this.generateRealPath(path)
    const { settings: { map = {} } = {} } = this.state
    const { component: { props: { flex = 0.5 } = {} } = {}, domElement = {} } = event

    let $flex = flex

    if ($flex === Infinity) {
      $flex = previousFlex

      if (domElement) {
        const { width: parentWidth = 0 } = domElement.parentElement.getClientRects()[0]

        const { width: childWidth = 0 } = domElement.getClientRects()[0]

        const childPercentWidth = childWidth / parentWidth

        $flex = childPercentWidth
      }
    }

    if (this.isConfiguration) {
      const immutableJS = fromJS(map).updateIn([...realPath, 'config'], () => {
        return fromJS({ flex: $flex })
      })
      this.saveLayout('map', immutableJS.toJS())
    }
    // Fire this event to resize the child plugins inside the container
    this.dispatchResize()
  }

  getSplitterStyle(borderSize) {
    const { settings: { isBorderVisible = true } = {} } = this.state
    let borderVisibleStyle = {}
    if (!isBorderVisible) {
      borderVisibleStyle = {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      }
    }

    const splitterStyle = {
      borderWidth: borderSize,
      ...borderVisibleStyle
    }

    return { ...splitterStyle }
  }

  getSplitter(keyText, index, borderSize) {
    const { settings: { isBorderVisible = true } = {} } = this.state

    const splitterStyle = this.getSplitterStyle(borderSize)

    return (
      <ReflexSplitter
        key={Math.random()}
        className={`reflex-splitter ${!isBorderVisible ? '-pointer-disable' : ''}`}
        style={splitterStyle}
      />
    )
  }

  renderContainer(parameters = {}, key = [0]) {
    let {
      type = 'element',
      orientation = 'horizontal',
      elements = this.defaultElements
    } = parameters

    if (type === null) {
      return null
    }

    const keyText = _.join(key, '_')

    switch (type) {
      case 'container':
        const size = _.size(elements)
        if (size <= 1) {
          elements = this.defaultElements
        }

        const { settings: { splitterWidth = 2 } = {} } = this.state
        const borderSize = splitterWidth / 2
        const containerStyle = {
          border: borderSize
        }

        return (
          <ReflexContainer windowResizeAware orientation={orientation} style={containerStyle}>
            {_.transform(
              elements,
              (result, element, index) => {
                if (element === null) {
                  element = {}
                }

                const opts = {}
                let currentFlex = 0.5 // 0.5 is the default value to use onStopResize fn as a previous value
                if ('flex' in _.get(element, 'config', {})) {
                  currentFlex = element.config.flex
                  opts.flex = currentFlex
                }

                const {
                  settings: { borderRadius = 0 }
                } = this.state

                result.push(
                  <ReflexElement
                    {...opts}
                    key={`${keyText}_${index}`}
                    style={{ borderRadius }}
                    onStopResize={this.handleStopResize.bind(this, [...key, index], currentFlex)}
                  >
                    {this.renderContainer(element, [...key, index])}
                  </ReflexElement>
                )
                if (index !== size - 1) {
                  result.push(this.getSplitter(keyText, index, borderSize))
                }
              },
              []
            )}
          </ReflexContainer>
        )
      default:
        return [
          this.props.isSelected ? (
            <SplitActions
              key={`${keyText}_split`}
              keyParam={key}
              onHandleRemove={this.handleRemove.bind(this)}
              onHandleSplit={this.handleSplit.bind(this)}
            />
          ) : null,
          this.findPlugin(key)
        ]
    }
  }

  getBackgroundColor() {
    const { data: { settings: { background, Background = null } = {} } = {} } = this.props

    const color = background || Background || this.defaultColor

    return _.isObject(color) ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : color
  }

  render() {
    const { settings: { map, isBorderVisible = true, splitterWidth } = {}, isDemo = false } =
      this.state

    const reflexContainerStyle = {
      backgroundColor: this.getBackgroundColor()
    }

    if (!isBorderVisible) {
      reflexContainerStyle.border = 0
    }

    if (splitterWidth) {
      reflexContainerStyle.padding = splitterWidth / 2
    }

    return (
      <div
        className={cx('flex-container', { '-sencha-reflex': isDemo })}
        style={reflexContainerStyle}
      >
        {this.renderContainer(map)}
      </div>
    )
  }
}

export default withConnector(FlexboxContainer)
