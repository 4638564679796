Ext.define('Spui.view.tracking.leftside.dataview.StatusReport', {
  extend: 'Ext.view.View',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-tracking-leftside-dataview-statusreport',

  selectedRecord: null,
  header: false,
  border: true,
  height: 105,
  margin: '5 0 5 0',
  style: {
    background: '#fff'
    //'border': '1px solid #ccc'
  },
  width: 'auto',

  refresh: function () {
    var me = this
    me.callParent()
    me.fireEvent('refreshbtn', me)
  },
  initComponent: function () {
    // this.store.on('load', function(v) {
    // 	v.fireEvent('viewready', v)
    // }, this);

    this.plugins = [
      {
        ptype: 'cornerIcon',
        cfgButton: [
          '->',
          {
            iconCls: 'x-fa fa-cog',
            cls: 'config-btn',
            handler: 'onConfigureStatusReport'
          }
        ]
      }
    ]
    this.callParent()
  },
  reference: 'statusreport',
  itemSelector: 'div.status-item',
  tpl: new Ext.XTemplate(
    '<div class="grid-container">',
    '<tpl for=".">',
    '<div class="status-item">',
    '<div class="toptext"> {StatusDescription} </div>',
    '<div class="topNumber"> {NumberOfRecord} </div>',
    '</div>',
    '</tpl>',
    '</div>'
  ),
  overItemCls: 'status-item-over',
  selectedItemCls: 'status-item-selected',
  listeners: {
    selectionchange: 'onSelectStatusReport'
  },

  onSelect: function (dataview, selected, eOpts) {
    //  this.selectedRecord=selected[0]
  },
  onLoadStore: function (store) {
    var aa = store
    var defaultRec = store.findRecord('IsDefault', 1),
      selModel = this.selModel
    var selection = selModel.getSelected()
    var reSelectRecord = this.selectedRecord.items.length ? this.selectedRecord : defaultRec
    selModel.select(reSelectRecord)
    this.fireEvent('loadStatusReport', this)
  },
  onBeforeLoadStore: function (store) {
    var selModel = this.selModel
    this.selectedRecord = selModel.getSelected()
  }
})
