Ext.define('Spui.view.dd.saleschart.main', {
  extend: 'Ext.window.Window',
  xtype: 'spui-view-dd-saleschart-main',
  maximized: true,
  mixins: ['Spui.view.dd.saleschart.mixin'],
  layout: 'border',
  defaultListenerScope: true,
  constructor: function (config) {
    if (!config) config = {}
    this.callParent([config])
  }
})
