import { Dispatch, SetStateAction } from 'react'
import { IAggFunc } from 'ag-grid-community'
import { slvyToast } from '@/components'
import { getUserColumnDefsAsFlat } from '../index'
import { IRowData, ISetCustomAggregations, TrowDataValue } from '../../types'
import { IHandleCustomAggregation } from './custom-agg.types'

export const handleCustomAggregation = ({ customAggregation, data }: IHandleCustomAggregation) => {
  const aggFunc = `function table(i, col){return records[i][col]};` // define table function for custom aggregation

  const funcBody = window.atob(customAggregation)
  // eslint-disable-next-line no-new-func
  const customAggregationFunction = new Function('records', 'rowCount', aggFunc + funcBody)

  try {
    customAggregationFunction(data, data.length)
  } catch (err) {
    return false
  }

  return () => customAggregationFunction(data, data.length)
}

export const setCustomAggregations = (
  { columnDefs, data, customAggregationFailedLocale }: ISetCustomAggregations,
  state: boolean,
  setState: Dispatch<SetStateAction<boolean>>
) => {
  const funcs: { [key: string]: IAggFunc<IRowData, TrowDataValue> } = {}
  const colNames: (string | undefined)[] = []

  const userColumnDefsAsFlat = getUserColumnDefsAsFlat(columnDefs)

  userColumnDefsAsFlat.forEach((colDef) => {
    const {
      field,
      customInfo: { customAggregation = '' }
    } = colDef
    if (customAggregation) {
      const tryCustomAggregation = handleCustomAggregation({
        customAggregation,
        data
      })

      if (tryCustomAggregation !== false && field) {
        funcs[`${customAggregation}`] = tryCustomAggregation
      } else {
        colNames.push(field)
      }
    }
  })

  if (colNames.length > 0 && state && customAggregationFailedLocale) {
    slvyToast.warning({ message: customAggregationFailedLocale(colNames.join(', ')) })
    setState(false)
  }

  return funcs
}

export default { handleCustomAggregation, setCustomAggregations }
