import { useCallback } from 'react'
import { CellDoubleClickedEvent, GridOptions } from 'ag-grid-community'
import { IRowData, TrowDataValue } from '../../types'
import { CellDoubleClickProps } from './useCellDoubleClick.types'

function useCellDoubleClick(props: CellDoubleClickProps) {
  const { onCellDoubleClickedRef, selectionType } = props
  const onCellDoubleClicked: GridOptions<IRowData>['onCellDoubleClicked'] = useCallback(
    (event: CellDoubleClickedEvent<IRowData, TrowDataValue>) => {
      if (selectionType !== 'Spreadsheet') {
        return
      }

      const {
        data,
        colDef: { customInfo },
        node: { rowIndex }
      } = event
      const { rawField, isRawFieldEmpty } = customInfo

      if (isRawFieldEmpty) {
        return
      }

      onCellDoubleClickedRef?.current?.[`handleCellClick_${rawField}`]?.(data, rowIndex)
    },
    [onCellDoubleClickedRef, selectionType]
  )
  return { onCellDoubleClicked }
}

export default useCellDoubleClick
