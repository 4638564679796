import { useEffect, useRef, useCallback, useMemo } from 'react'
import { transform } from 'lodash'
import { BasicType } from '@/BasePlugin/PluginTypes'
import { useGetSelectionCellParams } from '../../../index'
import {
  getGridRowData,
  getFieldConfigsValues,
  omitCustomFields,
  updateWithSubstituteNewValue
} from '../../../../helpers'
import { IRowDataGrid } from '../../../../types'
import { GetFieldConfigsValuesResult } from '../../registerEventsMethods.types'
import {
  ConditionedRowsResultEventRef,
  ConditionedRowsResultProps
} from './useRegisterEventConditionedRowsResult.types'

function useRegisterEventConditionedRowsResult({
  fieldConfigs,
  gridRef,
  registerEvent,
  getFieldType
}: ConditionedRowsResultProps) {
  const conditionedRowsResultEventRef = useRef<ConditionedRowsResultEventRef>({
    handleConditionedRowsResult: (_args: never): never => ({} as never)
  })
  const { cellParams } = useGetSelectionCellParams(fieldConfigs)

  const getConditionedRowsResult = useCallback(
    (conditionDataField: string) => {
      const rowsResult: GetFieldConfigsValuesResult = {}

      const foundField = conditionDataField
        ? fieldConfigs.find(({ fieldName }) => {
            return fieldName === conditionDataField
          })
        : null

      if (!foundField) {
        return rowsResult
      }

      const currentRowData = getGridRowData(gridRef?.current?.api)
      const conditionalRows = currentRowData.reduce((result: IRowDataGrid[], row) => {
        if (row[conditionDataField]) {
          const item = updateWithSubstituteNewValue({ data: row, dirtyCells: [] })
          result.push(omitCustomFields(item))
        }
        return result
      }, [])

      return getFieldConfigsValues({ fieldConfigs, rows: conditionalRows })
    },
    [fieldConfigs, gridRef]
  )

  const conditionedRowsParams = useMemo(() => {
    return transform(
      cellParams,
      (result: Record<string, BasicType>, _value, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] = getFieldType(key, true)
      },
      {}
    )
  }, [cellParams, getFieldType])

  useEffect(() => {
    const event = registerEvent({
      key: 'ConditionedRowsResult',
      fn: getConditionedRowsResult,
      returnTypes: conditionedRowsParams
    })
    conditionedRowsResultEventRef.current.handleConditionedRowsResult = event
  }, [conditionedRowsParams, getConditionedRowsResult, registerEvent])

  return { conditionedRowsResultEventRef }
}

export default useRegisterEventConditionedRowsResult
