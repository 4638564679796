import React, { Component } from 'react'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import { connect } from 'react-redux'
import cx from 'classnames'
import { slvyToast } from '../../../../components'
import {
  resetScenarioSelection,
  resetActualScenarioSelection,
  fetchScenarioParameters,
  savePivotBuilderViewSettings,
  saveScenarioTreeViewSettings,
  unCollapse as onAddUnCollapsedScenarios,
  selectScenario,
  selectActualScenario,
  collapseAll,
  setLoader as setActionLoader
} from '../../store/actions/index'
import { makeRequest, setLoader } from '../../utils'
import ScenarioHeader from './scenario-header'
import ScenarioTreeList from '../ScenarioTreeList'
import CreateScenarioForm from './create-scenario-form'
import DeleteScenarioForm from './delete-scenario-form'
import scenarioConfig from '../../scenarioConfig'
import FetchScenarioTree from '../../facc/FetchScenarioTree'
import { MESSAGES } from '../../messages'
import { findById } from '../Util'
import { getToggledScenariosForActual } from '../../store/reducers/scenarioTreeSelection'

class ScenarioTree extends Component {
  constructor(props) {
    super(props)
    this.scenarioConfig = [...scenarioConfig]
    this.state = {
      isVisibleCreateForm: false,
      isVisibleDeleteForm: false,
      selectedScenarioTree: null,
      unCollapsedScenarios: null
    }
  }

  componentDidMount() {
    this.autoSelectLastScenario(() => {
      const { selectedScenarioTree, unCollapsedScenarios } = this.props
      this.setState({
        selectedScenarioTree: _.cloneDeep(selectedScenarioTree),
        unCollapsedScenarios: unCollapsedScenarios
      })
    })
  }

  autoSelectLastScenario(callback) {
    const {
      ScenarioData: { Result: ScenarioTreeResult = [] } = {},
      settings: { shouldRememberLastScenarioSelection = false } = {},
      pluginId = '',
      selectedScenarioTree: { currentScenarios: rCurrentScenarios = [] } = {},
      unCollapsedScenarios: rUnCollapsedScenarios = []
    } = this.props

    const isNotSelectedBefore = rCurrentScenarios.length === 0 && rUnCollapsedScenarios.length === 0
    if (shouldRememberLastScenarioSelection && isNotSelectedBefore) {
      const collapseStorage = localStorage.getItem(`dp-Collapse-${pluginId}`)
      if (collapseStorage) {
        const { collapse: { unCollapsedScenarios = [] } = {} } = JSON.parse(collapseStorage)
        unCollapsedScenarios.forEach((value) => {
          if (!_.isEmpty(findById(ScenarioTreeResult, value.Id))) {
            this.props.onAddUnCollapsedScenarios({ payload: value })
          }
        })
      }

      const selectedScenarioTreeStorage = localStorage.getItem(
        `dp-SelectedScenarioTree-${pluginId}`
      )
      if (selectedScenarioTreeStorage) {
        const { currentScenarios = [] } = JSON.parse(selectedScenarioTreeStorage)
        currentScenarios.forEach((value) => {
          value.currentScenario = findById(ScenarioTreeResult, value.currentScenario.Id)
          if (!_.isEmpty(value.currentScenario)) {
            this.props.selectScenario({ payload: value })
          }
        })
      }
    }

    setTimeout(() => {
      callback?.()
    }, 500)
  }

  toggleLoader(isVisible = false, msg = MESSAGES.loading) {
    setLoader(this.props.setActionLoader, isVisible, msg)
  }

  onCreateClick() {
    const {
      props: {
        defaultActionParams: { payload = {} },
        mfpSettings: { mfpId }
      }
    } = this

    this.props
      .fetchScenarioParameters({
        payload: {
          ...payload,
          method: 'GetScenarioParameters',
          requestMethod: 'post',
          body: {
            mfpId
          }
        }
      })
      .then(() => this.onCreateFormToggle(true))
  }

  onRefreshClick() {
    this._fetchScenarioTree()
      .then(() => {
        this.props.collapseAll({
          payload: { unCollapsedScenarios: [], dataKey: 'scenarioTree' }
        })
        this.props.resetScenarioSelection({
          payload: { dataKey: 'scenarioTree' }
        })

        this.autoSelectLastScenario()
        this.saveScenarioOnLocalStorage()

        slvyToast.success({
          message: MESSAGES.the_scenario_tree_has_been_reloaded_successfully,
          title: MESSAGES.reload_scenario,
          options: { containerId: 'dp' }
        })

        this.toggleLoader()
      })
      .catch(() => {
        slvyToast.warning({
          message: MESSAGES.the_scenario_tree_could_not_be_reloaded,
          title: MESSAGES.reload_scenario,
          options: { containerId: 'dp' }
        })
        this.toggleLoader()
      })
  }

  onDeleteClick() {
    this.onDeleteFormToggle(true)
  }

  displayPivotBuilder($state = true) {
    this.props.savePivotBuilderViewSettings({
      payload: {
        isVisible: $state
      }
    })
  }

  displayScenarioTree($state = true) {
    this.props.saveScenarioTreeViewSettings({
      payload: {
        isVisible: $state
      }
    })
  }

  saveScenarioOnLocalStorage() {
    const {
      pluginId = '',
      selectedScenarioTree = {},
      unCollapsedScenarios = [],
      settings: { shouldRememberLastScenarioSelection = false } = {}
    } = this.props

    if (shouldRememberLastScenarioSelection) {
      localStorage.setItem(
        `dp-SelectedScenarioTree-${pluginId}`,
        JSON.stringify(selectedScenarioTree)
      )
      localStorage.setItem(
        `dp-Collapse-${pluginId}`,
        JSON.stringify({ collapse: { unCollapsedScenarios } })
      )
    }
  }

  saveEditable() {
    const {
      selectedScenarioTree: { currentScenarios = [] }
    } = this.props

    const plannedScenario =
      [...currentScenarios].find((item) => item.scenarioType === 'Planned') || {}

    // dont add default value for this line
    const defaultIsScenarioEditable = _.get(this.props, 'mfpSettings.isScenarioEditable')
    const IsEditable = _.get(
      plannedScenario,
      'currentScenario.IsEditable',
      defaultIsScenarioEditable
    )
    const ScenarioName = _.get(plannedScenario, 'currentScenario.Name', 'This scenario')

    const errMsg = `${ScenarioName} is not editable!`

    this.props.setEditable({ Result: { IsEditable }, Errors: [errMsg] })
  }

  onLoadScenarioClick() {
    const { params = {} } = this.props.getScenarioParameters()
    this.saveScenarioOnLocalStorage()
    this.fetchPivotConfiguration(() => {
      this.props.scenarioLoaded(params)
      // important to call
      this.saveEditable()
    })
  }

  onGenerateReportClick() {
    const {
      mfpSettings: { mfpId }
    } = this.props
    this.fetchPivotConfiguration(() => {
      this.props.generateReport({ mfpId: String(mfpId) })
    })
  }

  fetchPivotConfiguration($callback) {
    this.props._savePivotViewSettings({ isVisible: false })
    this.props._savePivotDetailViewSettings({ isVisible: false })
    this.props._savePivotSingleDetailViewSettings({ isVisible: false })
    this.props._saveScenarioComparisonSettings({ isVisible: false })
    this.displayPivotBuilder(false)

    this.props
      ._fetchPivotConfiguration()
      .then(() => {
        const {
          props: { GetPivotConfiguration: { Result: { Fields = [] } = {} } = {} }
        } = this
        this.toggleLoader()
        this.props.onGetPivotConfigurationLoaded(Fields, () => {
          $callback()
        })
      })
      .catch(() => this.toggleLoader())
  }

  onCreateFormToggle($status) {
    this.setState({
      isVisibleCreateForm: $status
    })
  }

  onDeleteFormToggle($status) {
    this.setState({
      isVisibleDeleteForm: $status
    })
  }

  onCreateFormSubmit($params) {
    const {
      defaultActionParams: { payload = {} }
    } = this.props

    this.toggleLoader(true, MESSAGES.loading)

    makeRequest({
      payload: {
        ...payload,
        requestMethod: 'post',
        method: 'CreateScenario',
        body: {
          ...$params
        }
      }
    })
      .then(() => {
        slvyToast.success({
          message: MESSAGES.the_scenario_will_be_created_soon,
          title: MESSAGES.create_scenario,
          options: { containerId: 'dp' }
        })
        this.setState({
          selectedScenario: {},
          isVisibleCreateForm: false
        })
        this.toggleLoader()
      })
      .catch(() => {
        slvyToast.warning({
          message: MESSAGES.the_scenario_tree_could_not_be_created,
          title: MESSAGES.create_scenario,
          options: { containerId: 'dp' }
        })
        this.toggleLoader()
      })
  }

  onDeleteFormSubmit($params) {
    const {
      props: {
        defaultActionParams: { payload = {} }
      }
    } = this

    confirmAlert({
      title: MESSAGES.delete_scenario,
      message: MESSAGES.are_you_sure_you_want_to_delete_this_scenario,
      buttons: [
        {
          label: MESSAGES.cancel
        },
        {
          label: MESSAGES.delete,
          onClick: () => {
            this.toggleLoader(true, MESSAGES.loading)

            makeRequest({
              payload: {
                ...payload,
                requestMethod: 'post',
                method: 'DeleteScenario',
                body: $params
              }
            })
              .then(() => {
                slvyToast.success({
                  message: MESSAGES.the_scenario_has_been_deleted_successfully,
                  title: MESSAGES.delete_scenario,
                  options: { containerId: 'dp' }
                })

                this._fetchScenarioTree().then(() => this.toggleLoader())

                this.setState({
                  selectedScenario: {},
                  isVisibleDeleteForm: false
                })
              })
              .catch(() => {
                slvyToast.warning({
                  message: MESSAGES.the_scenario_could_not_be_deleted,
                  title: MESSAGES.delete_scenario,
                  options: { containerId: 'dp' }
                })
                this.toggleLoader()
              })
          }
        }
      ]
    })
  }

  getAction() {
    const { isVisibleCreateForm, isVisibleDeleteForm } = this.state
    if (isVisibleCreateForm) {
      return '-create'
    }
    if (isVisibleDeleteForm) {
      return '-delete'
    }
    return '-default'
  }

  selectDefaultItems() {
    const {
      state: {
        unCollapsedScenarios = [],
        selectedScenarioTree: { currentScenarios = [], actualScenarioList = [] } = {}
      }
    } = this

    this.props.resetScenarioSelection({ payload: { dataKey: 'scenarioTree' } })
    this.props.resetActualScenarioSelection({ payload: { dataKey: 'scenarioTree' } })
    this.props.collapseAll({ payload: { unCollapsedScenarios: [], dataKey: 'scenarioTree' } })

    setTimeout(() => {
      currentScenarios.forEach((value) => this.props.selectScenario({ payload: value }))

      actualScenarioList.forEach((value) => this.props.selectActualScenario({ payload: value }))

      unCollapsedScenarios.forEach((value) =>
        this.props.onAddUnCollapsedScenarios({ payload: value })
      )
    }, 200)
  }

  render() {
    const {
      props: {
        settings: { includeColumnStoreIndex = false } = {},
        IsAllowed = false,
        hasMfpData = false,
        isVisibleGenerateReport = false,
        ScenarioData: { Result = [] } = {},
        GetScenarioParameters: { Result: { ScenarioParameters = [] } = {} } = {},
        selectedScenarioTree,
        selectedScenarioTree: { currentScenarios = [] },
        mfpSettings: { mfpId }
      },
      state: { isVisibleDeleteForm, isVisibleCreateForm },
      scenarioConfig
    } = this

    const _scenarioConfig = _.cloneDeep(scenarioConfig)
    const IsSelected = _scenarioConfig.length === currentScenarios.length

    return (
      <FetchScenarioTree>
        {({ _fetchScenarioTree = () => {} }) => {
          this._fetchScenarioTree = _fetchScenarioTree
          return (
            <div
              className={cx('scenario-tree', this.getAction(), { 'is-active': !_.isEmpty(Result) })}
            >
              {hasMfpData ? (
                <button
                  className="btn btn-sm sc-right"
                  onClick={() => {
                    this.selectDefaultItems()
                    this.displayScenarioTree(false)
                    this.props.onViewChangedCallback({ View: this.props.currentView })
                  }}
                >
                  {MESSAGES.close}
                </button>
              ) : null}
              <div className="scenario-lib-wrapper">
                <div className="a-wr">
                  <header>
                    <h1>{MESSAGES.scenario_selection}</h1>
                  </header>
                  <nav>
                    {!isVisibleCreateForm && !isVisibleDeleteForm && !_.isEmpty(Result) ? (
                      <div className="scenario-container">
                        {_scenarioConfig.map((scenarioItem, scenarioItemIndex) => {
                          return (
                            <div key={scenarioItemIndex} className="sc-wrapper -w50">
                              <h1>{scenarioItem.name}</h1>
                              <div className="slvy-ui -grid-body al-list d-flex">
                                <ScenarioTreeList
                                  currentScenario={[...currentScenarios].find(
                                    (item) => item.scenarioType === scenarioItem.name
                                  )}
                                  currentScenarios={currentScenarios}
                                  dataKey="scenarioTree"
                                  headerTpl={<ScenarioHeader />}
                                  listIndex={scenarioItemIndex}
                                  root={Result}
                                  scenarioType={scenarioItem.name}
                                  selectActualScenario={(param) => {
                                    if (!hasMfpData) {
                                      const newActualScenarioList = getToggledScenariosForActual(
                                        [...selectedScenarioTree.actualScenarioList],
                                        { ...param.payload }
                                      )
                                      this.setState({
                                        selectedScenarioTree: {
                                          ...selectedScenarioTree,
                                          actualScenarioList: _.cloneDeep(newActualScenarioList)
                                        }
                                      })
                                    }
                                    this.props.selectActualScenario(param)
                                  }}
                                  selectScenario={this.props.selectScenario}
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    ) : null}
                    {isVisibleCreateForm ? (
                      <CreateScenarioForm
                        ScenarioParameters={ScenarioParameters}
                        data={Result}
                        includeColumnStoreIndex={includeColumnStoreIndex}
                        mfpId={mfpId}
                        onClose={this.onCreateFormToggle.bind(this)}
                        onCreateFormSubmit={this.onCreateFormSubmit.bind(this)}
                      />
                    ) : null}
                    {isVisibleDeleteForm ? (
                      <DeleteScenarioForm
                        ScenarioParameters={ScenarioParameters}
                        data={Result}
                        mfpId={mfpId}
                        onClose={this.onDeleteFormToggle.bind(this)}
                        onDeleteFormSubmit={this.onDeleteFormSubmit.bind(this)}
                      />
                    ) : null}
                    {!isVisibleCreateForm && !isVisibleDeleteForm ? (
                      <div className="sc-btn-group">
                        <span
                          className="cell-btn -refresh -apply -light"
                          onClick={this.onRefreshClick.bind(this)}
                        >
                          <i className="slvy-ui-icon-recycle" />
                          Reload
                        </span>
                        {IsAllowed ? (
                          <span
                            key="onCreateClick"
                            className="cell-btn -create"
                            onClick={this.onCreateClick.bind(this)}
                          >
                            <i className="slvy-ui-icon-plus-circle-regular" /> Create
                          </span>
                        ) : null}
                        {IsAllowed ? (
                          <span
                            key="onDeleteClick"
                            className="cell-btn -delete"
                            onClick={this.onDeleteClick.bind(this)}
                          >
                            <i className="slvy-ui-icon-trash-can" /> Delete
                          </span>
                        ) : null}
                        {isVisibleGenerateReport && IsSelected ? (
                          <span
                            className="cell-btn -apply -light"
                            onClick={this.onGenerateReportClick.bind(this)}
                          >
                            <i className="slvy-ui-icon-bolt" /> Generate Report
                          </span>
                        ) : null}
                        {IsSelected ? (
                          <span
                            className="cell-btn -apply"
                            onClick={this.onLoadScenarioClick.bind(this)}
                          >
                            <i className="slvy-ui-icon-server-download" /> Load
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                  </nav>
                </div>
              </div>
            </div>
          )
        }}
      </FetchScenarioTree>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    unCollapsedScenarios: state.collapse.scenarioTree.unCollapsedScenarios,
    selectedScenarioTree: state.scenarioTreeSelection.scenarioTree,
    GetScenarioParameters: state.scenarioParameters.GetScenarioParameters,
    GetPivotConfiguration: state.getPivotConfiguration.GetPivotConfiguration,
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    mfpSettings: state.mfpSettings.mfpSettings
  }
}

const mapDispatchToProps = {
  resetScenarioSelection,
  resetActualScenarioSelection,
  fetchScenarioParameters,
  savePivotBuilderViewSettings,
  saveScenarioTreeViewSettings,
  onAddUnCollapsedScenarios,
  selectScenario,
  selectActualScenario,
  collapseAll,
  setActionLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioTree)
