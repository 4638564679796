import { useCallback } from 'react'
import { PostProcessPopupParams } from 'ag-grid-community'
import styles from '../../scss/_filter.module.scss'
import { TAG_FILTER_KEY } from '../../constants'
import { IRowData } from '../../types'

function usePostProcessPopup() {
  const postProcessPopup = useCallback((params: PostProcessPopupParams<IRowData>) => {
    // TODO: colDef is private
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [{ displayKey = '' } = {}] = params.column?.colDef?.filterParams?.filterOptions || []
    // TODO: AG-8659 [Column Filters] Add an option to hide the filter option select in the filter popup
    if (displayKey === TAG_FILTER_KEY) {
      params.ePopup.classList.add(styles.slvyTagFilterExist)
    }
  }, [])

  return { postProcessPopup }
}

export default usePostProcessPopup
