/* eslint-disable jest/no-export */
import { CellClassParams } from 'ag-grid-community'
import { DEFAULT_CUSTOM_ROW_INFO } from '../../../constants'
import { AG_GRID_ENUMS } from '../../../enums'
import {
  EditableFields,
  FieldLookupConfig,
  TcellDisplayRules,
  TconfigColumn,
  TcustomConfigs,
  TfieldConfigs,
  TrowDisplayRules
} from '../../../types'
import { CellParams } from '../../selection/selection.types'
import { HighchartOptions } from '../../../components/CellRenderers/HighchartCellRenderer/HighchartCellRenderer.types'

const { CUSTOM_ROW_INFO } = AG_GRID_ENUMS

export const cellRendererParams = {
  value: '1',
  colDef: {
    field: 'col1'
  },
  data: {
    col1: '14',
    col2: 7,
    col3: 14,
    col4: '1',
    col5: 'vito',
    col6: '2020-04-09T21:00:00.000Z',
    col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
    col8: null,
    col9: '-100,NULL,0,100,100',
    col10: false,
    col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1,
    [CUSTOM_ROW_INFO]: DEFAULT_CUSTOM_ROW_INFO
  }
} as CellClassParams

export const customConfigs: TcustomConfigs = {
  cellStyle: {
    backgroundColor: '#eee',
    color: '#aaa',
    textAlign: 'left'
  },
  adding: {
    enabled: true
  },
  headerName: '',
  groupHeader: '',
  hiddenCondition: '',
  width: 0,
  editing: {
    enabled: false,
    editableCondition: '',
    allowBlank: false,
    maxValue: null,
    minValue: null,
    step: null,
    maxValueField: '',
    minValueField: '',
    stepField: '',
    lookupDataField: '',
    warningThreshold: 0
  },
  cellDoubleClickEnabled: false,
  actionButton: {
    actionEnabled: false,
    actionName: '',
    actionEditableCondition: '',
    actionClickable: false
  },
  customAggregation: '',
  rowGroup: false,
  filtering: {
    showList: false,
    tagFilter: false
  },
  icon: {
    displayOnlyIcon: false,
    iconPosition: 'right',
    icon: 'fa slvy-ui-icon-calendar'
  },
  template: '',
  summaryTemplate: '',
  columnType: 'simple',
  cellTooltip: '',
  formatField: '',
  aggFunc: '',
  highchartOptions: null as unknown as HighchartOptions,
  sparklineOptions: {
    columnColor: '',
    highlightColor: '',
    lineColor: '',
    lineWidth: 2,
    maxSpotColor: '',
    minSpotColor: '',
    negBarColor: '',
    spotColor: '',
    thresholdColor: '',
    type: 'sparklineline',
    zeroColor: ''
  },
  boolean: {
    trueIcon: '',
    falseIcon: '',
    trueColor: '',
    falseColor: ''
  },
  showInMaxMode: false,
  hiddenCondition: '',
  width: 0
}

export const configColumns: TconfigColumn[] = [
  {
    customConfigs,
    field: 'col10',
    flex: 1,
    headerName: 'col10',
    headerTooltip: '',
    hide: false,
    pinned: false,
    sortable: false,
    suppressHeaderMenuButton: false
  }
]

export const cellDisplayRules = [
  {
    applyRuleSummary: false,
    operator: 'equal',
    textColor: 'rgba(248,248,248,1)',
    boldText: false,
    italicText: false,
    textDecoration: 'underline',
    displayOnlyIcon: false,
    iconPosition: 'right',
    ruleFieldName: 'col10',
    value: 'false',
    backColor: 'rgba(74,144,226,1)',
    icon: '',
    referenceFieldName: '',
    cellTooltip: '{col1} testing tooltip Rule 1'
  },
  {
    applyRuleSummary: false,
    operator: 'smaller',
    textColor: '#ddd',
    boldText: false,
    italicText: false,
    textDecoration: 'none',
    displayOnlyIcon: false,
    iconPosition: 'right',
    ruleFieldName: 'col3',
    referenceFieldName: 'col4',
    backColor: 'rgba(43,203,186,1)'
  }
] as TcellDisplayRules

export const fieldLookupConfig: FieldLookupConfig = {
  lookupQueryList: [],
  isLookupQuery: false,
  substituteField: null
}

export const validCellRule: TcellDisplayRules[number] = {
  cellTooltip: 'ss',
  operator: 'equal',
  backColor: '',
  textColor: '#ddd',
  boldText: false,
  italicText: false,
  textDecoration: 'none',
  applyRuleSummary: false,
  displayOnlyIcon: false,
  iconPosition: 'left',
  ruleFieldName: 'col1',
  referenceFieldName: '',
  value: '2',
  icon: 'fa slvy-ui-icon-compass'
}

export const rowDisplayRules: TrowDisplayRules = [
  {
    compareToField: '',
    operator: 'equal',
    backColor: 'rgba(254,211,48,1)',
    textColor: '#ffffff',
    boldText: false,
    italicText: false,
    textDecoration: 'none',
    ruleFieldName: 'col4',
    value: '1'
  },
  {
    compareToField: '',
    operator: 'equal',
    backColor: 'red',
    textColor: '#ffffff',
    boldText: false,
    italicText: false,
    textDecoration: 'none',
    ruleFieldName: 'col3',
    value: '14'
  }
]

export const validRowRule: TrowDisplayRules[number] = {
  compareToField: '',
  operator: 'equal',
  backColor: 'red',
  textColor: '#ffffff',
  boldText: false,
  italicText: false,
  textDecoration: 'none',
  ruleFieldName: 'col1',
  value: '2'
}

export const pluginData: any = [
  {
    col1: 10,
    col2: 7,
    col3: 14,
    col4: '1',
    col5: 'vito',
    col6: '2020-04-09T00:00:00',
    col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
    col8: null,
    col9: '-100,NULL,0,100,100',
    col10: false,
    col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 20,
    col4: '99',
    col5: 'vito',
    col6: '2021-03-29T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: '0,0,0,NULL',
    col10: true,
    col11: '1,-1,1,-1,1,-1',
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sonny',
    col6: '2021-12-13T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: '-100,-100,0,0,100,-100',
    col10: false,
    col11: '1,2,4,5,7,3,3,6,10,2,4',
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 55,
    col2: 5,
    col3: 2,
    col4: '33',
    col5: 'vitoo',
    col6: '2021-12-13T00:00:00',
    col7: 'vito',
    col8: null,
    col9: '10,-200,50,100,500',
    col10: true,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 55,
    col2: 5,
    col3: 2,
    col4: '33',
    col5: 'vitoo',
    col6: '2021-12-13T00:00:00',
    col7: 'vito',
    col8: null,
    col9: null,
    col10: true,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sony',
    col6: '2023-06-02T00:00:00',
    col7: 'as',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 55,
    col2: 5,
    col3: 2,
    col4: '33',
    col5: 'vitoo',
    col6: '2021-08-08T00:00:00',
    col7: 'vito',
    col8: null,
    col9: null,
    col10: true,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sonny',
    col6: '2020-01-28T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sonny',
    col6: '2020-01-27T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: 'sonny',
    col6: '2020-06-17T00:00:00',
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 32,
    col2: 10,
    col3: 20,
    col4: '1',
    col5: 'sonny',
    col6: '2020-06-02T00:00:00',
    col7: null,
    col8: null,
    col9: null,
    col10: null,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: 'sonny',
    col6: '2020-06-09T00:00:00',
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  }
]

export const fieldConfigs: TfieldConfigs = [
  {
    fieldName: 'col1',
    dataType: 'int'
  },
  {
    fieldName: 'col2',
    dataType: 'int'
  },
  {
    fieldName: 'col3',
    dataType: 'int'
  },
  {
    fieldName: 'col4',
    dataType: 'string'
  },
  {
    fieldName: 'col5',
    dataType: 'string'
  },
  {
    fieldName: 'col6',
    dataType: 'datetime'
  },
  {
    fieldName: 'col7',
    dataType: 'string'
  },
  {
    fieldName: 'col8',
    dataType: 'string'
  },
  {
    fieldName: 'col9',
    dataType: 'string'
  },
  {
    fieldName: 'col10',
    dataType: 'bool'
  },
  {
    fieldName: 'col11',
    dataType: 'string'
  },
  {
    fieldName: 'col12',
    dataType: 'double'
  },
  {
    fieldName: 'col3_readonly',
    dataType: 'int'
  },
  {
    fieldName: 'col4_readonly',
    dataType: 'int'
  },
  {
    fieldName: 'col5_readonly',
    dataType: 'int'
  }
]

export const transformedFieldConfigs: CellParams = {
  col1: {
    type: 'int',
    isMultiple: false
  },
  col2: {
    type: 'int',
    isMultiple: false
  },
  col3: {
    type: 'int',
    isMultiple: false
  },
  col4: {
    type: 'string',
    isMultiple: false
  },
  col5: {
    type: 'string',
    isMultiple: false
  },
  col6: {
    type: 'datetime',
    isMultiple: false
  },
  col7: {
    type: 'string',
    isMultiple: false
  },
  col8: {
    type: 'string',
    isMultiple: false
  },
  col9: {
    type: 'string',
    isMultiple: false
  },
  col10: {
    type: 'bool',
    isMultiple: false
  },
  col11: {
    type: 'string',
    isMultiple: false
  },
  col12: {
    type: 'int',
    isMultiple: false
  },
  col3_readonly: {
    type: 'int',
    isMultiple: false
  },
  col4_readonly: {
    type: 'int',
    isMultiple: false
  },
  col5_readonly: {
    type: 'int',
    isMultiple: false
  }
}

export const editableFields: EditableFields = [
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    field: 'field1',
    lookupQueryList: ['query1', 'query2'],
    isLookupQuery: true,
    substitudeField: 'subField1'
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    field: 'field2',
    lookupQueryList: ['query3', 'query4'],
    isLookupQuery: false,
    substitudeField: 'subField2'
  }
]

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
