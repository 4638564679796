import { useCallback, useEffect, useRef } from 'react'
import { useGetSelectionCellParams } from '../../../index'
import {
  getDirtyRows,
  getRegisterEventFieldConfigsParams,
  getFieldConfigsValues
} from '../../../../helpers'
import { IRowDataGrid } from '../../../../types'
import {
  GetModifiedRowsResultEventRef,
  GetModifiedRowsResultProps
} from './useRegisterEventGetModifiedRowsResult.types'

function useRegisterEventGetModifiedRowsResult({
  fieldConfigs,
  gridRef,
  registerEvent,
  getFieldType
}: GetModifiedRowsResultProps) {
  const getModifiedRowsResultEventRef = useRef<GetModifiedRowsResultEventRef>({
    handleGetModifiedRowsResult: (): never => ({} as never)
  })

  const { cellParams } = useGetSelectionCellParams(fieldConfigs)
  const getModifiedRowsResultParams = getRegisterEventFieldConfigsParams({
    cellParams,
    getFieldType
  })

  const getModifiedRowsResult = useCallback(() => {
    const modifiedRows: IRowDataGrid[] = getDirtyRows({ gridRef, shouldOmit: true })
    return getFieldConfigsValues({ fieldConfigs, rows: modifiedRows })
  }, [fieldConfigs, gridRef])

  useEffect(() => {
    const event = registerEvent({
      key: 'GetModifiedRowsResult',
      fn: getModifiedRowsResult,
      returnTypes: getModifiedRowsResultParams
    })
    getModifiedRowsResultEventRef.current.handleGetModifiedRowsResult = event
  }, [getModifiedRowsResult, getModifiedRowsResultParams, registerEvent])

  return { getModifiedRowsResultEventRef }
}

export default useRegisterEventGetModifiedRowsResult
