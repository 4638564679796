import { cloneDeep, find, forEach, isNil, remove } from 'lodash'
import { ColumnComparisonData } from '../../types'
import { HandleDataColumnChangedProps, HandleSynchronizeDataDefinitionProps } from './preview.types'

function handleDataColumnChanged({
  _fieldConfigs,
  value,
  previousValue
}: HandleDataColumnChangedProps): ColumnComparisonData {
  if (!previousValue || (value.columns && value.columns.length !== previousValue.columns.length)) {
    return value
  }

  // eslint-disable-next-line no-param-reassign
  value.columns = value.columns?.map?.((column, index) => {
    if (!column || typeof column !== 'object') {
      return column
    }

    const newColumn: ColumnComparisonData['columns'][number] = { ...column }

    // We changed column alignment options from start, center, end to
    // left, center, right
    if (newColumn.align === 'start') {
      newColumn.align = 'left'
    }

    if (newColumn.align === 'end') {
      newColumn.align = 'right'
    }

    // Data field of the column changed
    if (newColumn.fieldName && previousValue.columns[index].fieldName !== newColumn.fieldName) {
      // Set Header
      if (newColumn.header === previousValue.columns[index].fieldName) {
        newColumn.header = newColumn.fieldName
      }

      if (
        newColumn?.actionButton?.name !== undefined &&
        newColumn?.actionButton?.name === previousValue.columns[index].fieldName
      ) {
        newColumn.actionButton.name = newColumn.fieldName
      }

      const field = find(_fieldConfigs, ({ fieldName }) => fieldName === newColumn.fieldName)

      // Set column alignment
      if (field) {
        if (
          field.dataType === 'int' ||
          field.dataType === 'double' ||
          field.dataType === 'float' ||
          field.dataType === 'decimal' ||
          field.dataType === 'long' ||
          field.dataType === 'short' ||
          field.dataType === 'datetime'
        ) {
          newColumn.align = 'right'
        } else if (field.dataType === 'string') {
          newColumn.align = 'left'
        } else if (field.dataType === 'bool') {
          newColumn.align = 'center'
        }
      }
    }

    return newColumn
  })

  return value
}

const handleDeleteConfigChanged = () => {}
// function handleDeleteConfigChanged(
//   schemaColumnItems: SchemaColumnItems | undefined,
//   value: ColumnComparisonData,
//   previousValue: ColumnComparisonData | undefined,
//   schemaHelper: any
// ): ColumnComparisonData {
//   const { grid: { deleting: { enabled = false } = {} } = {}, columnActions = [] } = value || {}
//   const { grid: { deleting: { enabled: enabledPrev = false } = {} } = {} } = previousValue || {}
//
//   // Runs only if the Deleting config changed
//   if (enabled !== enabledPrev) {
//     if (enabled && indexOf(columnActions, 'Delete Button') < 0) {
//       // Deleting is enabled
//
//       // Add Delete Button to column actions
//       value.columnActions.push('Delete Button')
//
//       // Create a delete column
//       const deleteColumn = schemaHelper.getValueOfSchema(schemaColumnItems)
//       deleteColumn.adding.enabled = false
//       deleteColumn.align = 'center'
//       deleteColumn.columnAction = 'Delete Button'
//       deleteColumn.flex = 0
//       deleteColumn.fontColor = '#dd6550'
//       deleteColumn.sortable = false
//       deleteColumn.tooltip = 'Delete'
//       deleteColumn.width = 40
//
//       value.columns.push(deleteColumn)
//     } else if (!enabled && indexOf(columnActions, 'Delete Button') >= 0) {
//       // Deleting is enabled
//
//       // Remove delete button from grid
//       remove(value.columns, (column) => {
//         return column.columnAction === 'Delete Button'
//       })
//
//       // Remove Delete Button from column actions
//       remove(columnActions, (columnAction) => {
//         return columnAction === 'Delete Button'
//       })
//     }
//   }
//
//   return value
// }

function handleSynchronizeDataDefinition({
  schemaColumnItems,
  fields,
  value,
  schemaHelper
}: HandleSynchronizeDataDefinitionProps): ColumnComparisonData {
  const newValue = cloneDeep(value)

  // Create an  empty template for grid column
  const emptyColumn = schemaHelper.getValueOfSchema(schemaColumnItems)

  if (isNil(newValue.columns)) {
    newValue.columns = []
  }

  remove(newValue.columns, (column) => {
    return column.fieldName === ''
  })

  forEach(fields, (field) => {
    // Check if the field is already used as a column
    const existingColumn = find(newValue.columns, (column) => {
      return column.fieldName === field.fieldName
    })

    // Create a column if its field does not exist
    if (!existingColumn) {
      const newcolumn = cloneDeep(emptyColumn)
      newcolumn.fieldName = field.fieldName
      newcolumn.header = field.fieldName

      if (
        field.dataType === 'datetime' ||
        field.dataType === 'decimal' ||
        field.dataType === 'double' ||
        field.dataType === 'float' ||
        field.dataType === 'int' ||
        field.dataType === 'long' ||
        field.dataType === 'short'
      ) {
        newcolumn.align = 'right'
      } else if (field.dataType === 'string') {
        newcolumn.align = 'left'
      } else if (field.dataType === 'bool') {
        newcolumn.align = 'center'
      }

      newValue.columns.push(newcolumn)
    }
  })

  return newValue
}

export { handleDataColumnChanged, handleDeleteConfigChanged, handleSynchronizeDataDefinition }
