import * as actionTypes from './actionTypes'

export const saveSetGenerateReportTree = (value) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storeSetGenerateReportTree(value))
      resolve()
    })
  }
}

const storeSetGenerateReportTree = (options) => {
  return {
    type: actionTypes.STORE_SET_GENERATE_REPORT_TREE,
    payload: options.payload
  }
}
