import { updateObject } from './utility'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  resourcePeriodHierarchy: {}
}

const resourcePeriodHierarchy = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_RESPONSE_DATA:
      return updateObject(state, { ...action.responseData })
    default:
      return state
  }
}

const PRODUCTresourcePeriodHierarchy = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_SAVE_RESPONSE_DATA:
      return updateObject(state, { ...action.responseData })
    default:
      return state
  }
}

export { resourcePeriodHierarchy, PRODUCTresourcePeriodHierarchy }
