import numeral from 'numeral'
import crawl from 'tree-crawl'
import { isNaN, uniq } from 'lodash'
import { PluginProps } from '@/BasePlugin/components/PluginProps.types'
import { distinctColors, keyMaps } from '../constants'
import { Config, CommonColumn, AllTableColumns } from '../Tui.types'
import { DemandTreeTableState } from '../store/slices'
import { DataIndexes } from '../components/DemandTreeTable/DemandTreeTable.types'

export function toURLSearchParams<TParams extends object>(params: TParams) {
  return Object.entries(params).reduce(
    (urlSearchParams, [key, value]) =>
      Object.assign(urlSearchParams, { [key]: JSON.stringify(value) }),
    {}
  )
}

export function getTreeQueryParams(
  config: Config,
  treeName: keyof Pick<Config, 'loadTree' | 'demandTree'>,
  pluginId?: string
) {
  const TUI_HIERARCHY_STATE_KEY = `TUI_HIERARCHY_STATE_${treeName}_${pluginId}`
  const hierarchyFromLocalStorage = JSON.parse(localStorage.getItem(TUI_HIERARCHY_STATE_KEY))

  const columns = config[treeName].columns
    .filter(({ isDisabled }) => !isDisabled)
    .map((column) => renameColumnKeys(column, keyMaps.column))
  const hierarchy =
    hierarchyFromLocalStorage ??
    config[treeName].hierarchy.map(({ fieldName }) => ({
      text: fieldName,
      dataIndex: fieldName
    }))
  const { filters } = config[treeName]

  return { columns, hierarchy, filters }
}

export function getTableQueryParams(
  config: Config,
  treeName: keyof Pick<Config, 'loadTable' | 'loadStopTable' | 'loadStopEntryTable'>
) {
  const columns = config[treeName].columns
    .filter(({ isDisabled }) => !isDisabled)
    .map((column) => renameColumnKeys(column, keyMaps.column))

  return { columns }
}

export function renameColumnKeys(column: AllTableColumns, keyMap: Record<string, string>) {
  return Object.entries(column).reduce((renamedColumn, [key, value]) => {
    const renamedKey = keyMap[key] ?? key

    return Object.assign(renamedColumn, { [renamedKey]: value })
  }, {})
}

export function collectNodes(
  tree: Config['loadTree'],
  callback,
  options = { getChildren: (node) => node.Children }
) {
  const nodes = []

  crawl(
    tree,
    (node, context) => {
      if (callback(node, context)) {
        nodes.push(node)
      }
    },
    options
  )

  return nodes
}

export function getEnabledColumns(columns: CommonColumn[]) {
  return columns
    .filter((column) => !column.isDisabled)
    .map((column) => ({
      header: column.text,
      accessorKey: column.dataIndex
    }))
}

export function getLoadStops(tree) {
  return uniq(collectNodes(tree, (node) => node).map(({ LoadName }) => LoadName))
}

export function getStickyColumnStyles(columnId, columns) {
  const defaultStyle = {
    position: 'sticky',
    zIndex: 20
  }

  switch (columnId) {
    case 'demandTree/card':
    case 'loadTree/card': {
      return { ...defaultStyle, left: 0 }
    }
    case 'demandTree/Name':
    case 'loadTree/approve': {
      const [card] = columns
      return { ...defaultStyle, left: card.column.getSize() }
    }
    case 'loadTree/Name': {
      const [card, approve] = columns
      return { ...defaultStyle, left: card.column.getSize() + approve.column.getSize() }
    }
    default:
      return undefined
  }
}

export function getTreeSubRows(row) {
  return row.Children
}

export function getIsLoadApproved({ Approved }: { Approved: string } = { Approved: '' }) {
  return Approved === 'Approved'
}

export function selectPluginProps({
  actualFilters,
  createLog,
  getFieldType,
  id,
  isAllowed,
  isPreviewMode,
  params,
  registerAuthorizations,
  registerEvent,
  registerMethod,
  schema,
  setDataArguments,
  settings,
  size,
  token,
  userName
}: PluginProps) {
  return {
    actualFilters,
    createLog,
    config: settings.config,
    getFieldType,
    id,
    isAllowed,
    isPreviewMode,
    params,
    registerAuthorizations,
    registerEvent,
    registerMethod,
    schema,
    setDataArguments,
    size,
    token,
    userName
  }
}

const numberOfDistinctColor = 100
export function getDistinctColors(numberOfColors: number) {
  const result = []

  for (let i = numberOfDistinctColor; i <= numberOfColors; i += numberOfDistinctColor) {
    result.push(...distinctColors[numberOfDistinctColor])
  }

  const remainder = numberOfColors % numberOfDistinctColor
  if (remainder !== 0) {
    result.push(...distinctColors[remainder])
  }

  return result
}

export const expandAncestors = (row) => {
  row.toggleExpanded(true)

  const parent = row.getParentRow()
  if (!parent) return

  expandAncestors(parent)
}

export const calculateDifferences = (summaries, planInfo, config, valueFormat) => {
  return (
    summaries?.map(({ FieldName, NewValue, OldValue, Unit, UnitName }) => {
      const unit = config?.units?.[UnitName] ?? planInfo[UnitName] ?? Unit ?? ''
      const oldValue = numeral(OldValue).format(valueFormat)
      const newValue = numeral(NewValue).format(valueFormat)
      const change = numeral(NewValue - OldValue).format(valueFormat)

      return {
        FieldName,
        OldValue: `${oldValue} ${unit}`,
        NewValue: `${newValue} ${unit}`,
        Change: `${change} ${unit}`
      }
    }) ?? []
  )
}

export const calculateTotals = (
  nodes: DemandTreeTableState['selectedRows'],
  dataIndexes: DataIndexes
) => {
  let firstSum = 0
  let secondSum = 0

  uniq(nodes)?.forEach((element) => {
    if (element.Leaf === true) {
      firstSum += dataIndexes[0] ? (element[dataIndexes[0].dataIndex] as number) : 0
      secondSum += dataIndexes[1] ? (element[dataIndexes[1].dataIndex] as number) : 0
    }
  })

  const customRound = (value: number, precision: number) => {
    const factor = 10 ** precision
    if (value > 0 && value < 1) {
      return Math.ceil(value * factor) / factor
    }
    return Math.floor(value * factor) / factor
  }

  const firstFormatTotal =
    dataIndexes[0] && dataIndexes[0].formatter
      ? numeral(customRound(firstSum, 2)).format(dataIndexes[0].formatter)
      : firstSum.toFixed(1)

  const secondFormatTotal =
    dataIndexes[1] && dataIndexes[1].formatter
      ? numeral(customRound(secondSum, 2)).format(dataIndexes[1].formatter)
      : secondSum.toFixed(1)

  return { firstFormatTotal, secondFormatTotal }
}

export const getLoadNames = (currentRow, _selectedRows) => {
  function traverseSubRows(subRows) {
    return subRows.flatMap((item) => {
      if (!item.leaf) {
        const childrenLoadNames = item.Children ? traverseSubRows(Object.values(item.Children)) : []
        return [item.LoadName, ...childrenLoadNames]
      }
      return [currentRow.original.LoadName]
    })
  }

  if (currentRow?.index === 0 && currentRow?.original.ID === 'root') {
    return uniq(_selectedRows.map((row) => row.LoadName))
  }

  const loadNames = traverseSubRows(
    currentRow?.originalSubRows ? Object.values(currentRow.originalSubRows) : []
  )

  return uniq(loadNames)
}

export const formatNumber = (value) => {
  if (isNaN(value) || value === Number.MAX_VALUE) value = 0

  if (value % 1 === 0) {
    return numeral(value).format('0,0')
  }

  return numeral(value).format('0,0.00')
}
