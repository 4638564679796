import axios from 'axios'

const $state: any = window?.dashboardStore?.getState?.() || {}
const environment: string = $state?.settings?.environment ?? ''
// eslint-disable-next-line camelcase
const access_token: string = $state?.oidc?.user?.access_token ?? ''

const instance = axios.create({
  timeout: 90000,
  headers: {
    environment,
    Accept: 'application/json',
    // eslint-disable-next-line camelcase
    Authorization: `Bearer ${access_token}`,
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  (response) => {
    const errFiltered = response?.data?.result?.filter((item: any) => !item.isSuccess) ?? []
    if (errFiltered.length) {
      return Promise.reject(response)
    }

    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const message = error?.message ?? 'There has been an error!'
    return Promise.reject({ data: { result: [{ errorCode: message, isSuccess: false }] } })
  }
)

export default instance
