import { StylesConfig } from 'react-select'

export const omitList = ['isCreatable'] as const

const defaultStyles: StylesConfig = {
  menu: (provided) => ({
    ...provided,
    position: 'absolute',
    zIndex: 99999999999
  })
}

const small: StylesConfig = {
  ...defaultStyles,
  control: (provided) => ({
    ...provided,
    minHeight: '30px',
    height: '30px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: '0 6px'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '30px'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px'
  })
}

const auto: StylesConfig = {
  ...defaultStyles,
  control: (provided) => ({
    ...provided,
    minHeight: 'auto',
    height: '100%',
    width: '100%'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 6px'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginRight: '3px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 999999999999
  })
}

const portalMenuStyles: StylesConfig = {
  menuPortal: (base) => ({ ...base, zIndex: 99999999999 })
}

const smallWithPortal: StylesConfig = {
  ...small,
  ...portalMenuStyles
}

export const SlvySelectStyles = {
  defaultStyles,
  small,
  auto,
  portalMenuStyles,
  smallWithPortal
}
