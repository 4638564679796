import { useCallback, useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { slvyToast } from '@/components'
import useReduxState from '../../useReduxState'
import { loadTreeTableSlice, tuiSlice } from '../../../store/slices'
import { expandAncestors } from '../../../utils'

function useCheckedLoad({ loadTreeTableRef }) {
  const [{ registerMethod }] = useReduxState(tuiSlice.selectSlice)
  const [, { setSelectedRows }] = useReduxState(undefined, loadTreeTableSlice.actions)

  const handleSetCheckedLoad = useCallback(
    (params) => {
      if (!params.CheckedLoads) {
        slvyToast.warning({
          title: 'TUI',
          message: 'setCheckedLoad parameter is empty.'
        })
        return
      }

      const checkedRow = loadTreeTableRef.current.getRowModel().flatRows.find((row) => {
        return row?.originalSubRows?.[0]?.ID === params.CheckedLoads
      })

      if (!checkedRow) {
        return
      }

      loadTreeTableRef.current.setRowSelection(true)

      checkedRow.toggleSelected(true)
      expandAncestors(checkedRow)

      const originalSubRows = checkedRow.originalSubRows ?? []
      setSelectedRows([checkedRow.original, ...originalSubRows])
    },
    [loadTreeTableRef, setSelectedRows]
  )

  useEffect(() => {
    registerMethod({
      args: [{ name: 'CheckedLoads', type: PluginTypes.string }],
      key: 'setCheckedLoad',
      fn: handleSetCheckedLoad
    })
  }, [handleSetCheckedLoad, registerMethod])
}

export default useCheckedLoad
