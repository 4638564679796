import { memo, useMemo } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import Menus from '../../Menus/index'
import ContainerHeader from '../../ContainerHeader'
import {
  IAssigneeMenu,
  IAssigneeMenuDrilldown,
  IRegisterEventMethodParams,
  IRemoteAction
} from '../../../../Common.types'
import { IRemoteActionsContainerProps } from './RemoteActionsContainer.types'
import { emptyObject } from '../../../../../constants'
import styles from '../../../index.module.scss'

export const getActiveRemoteActionMethodsCount = (
  remoteActions: IRemoteAction[],
  assignees: IAssigneeMenu
) => {
  const count = {
    active: 0,
    total: 0
  }
  _.each(remoteActions, (remoteAction) => {
    const params = _.transform(
      remoteAction.parameters,
      (result: IRegisterEventMethodParams, parameter) => {
        // eslint-disable-next-line no-param-reassign
        result[parameter.name] = { type: parameter.type }
      },
      {}
    )

    const drilldown = assignees?.[remoteAction?.name]?.drilldown ?? {}
    const selectedValues = Object.values(drilldown).filter((val) => val)

    const isParamsExist = Object.keys(params).length
    count.active += isParamsExist && selectedValues.length ? 1 : 0
    count.total += isParamsExist ? 1 : 0
  })
  return count
}

const RemoteActionsContainer = ({
  assignees,
  variables,
  handleReturnVariableChange,
  remoteActions,
  toggleActives
}: IRemoteActionsContainerProps) => {
  const sortedRemoteActions = remoteActions.sort((action, nextAction) =>
    action.name.localeCompare(nextAction.name)
  )

  const { active, total } = getActiveRemoteActionMethodsCount(sortedRemoteActions, assignees)
  const title = useMemo(() => <h6 className="p-0 m-0">Remote Action Methods (ALL)</h6>, [])

  if (toggleActives && !active) {
    return null
  }

  return (
    <Accordion defaultActiveKey={active ? '0' : ''}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="mb-0 pb-0">
          <ContainerHeader active={active} title={title} total={total} />
        </Accordion.Header>
        <Accordion.Body className={cx('p-3', styles.relationAccordionBody)}>
          {_.map(sortedRemoteActions, (remoteAction, index) => {
            const {
              [remoteAction.name]: {
                drilldown: menuAssignees = emptyObject
              } = emptyObject as IAssigneeMenuDrilldown
            } = assignees

            const params = _.transform(
              remoteAction.parameters,
              (result: IRegisterEventMethodParams, parameter) => {
                // eslint-disable-next-line no-param-reassign
                result[parameter.name] = { type: parameter.type }
              },
              {}
            )

            if (_.isEqual(params, {})) {
              return null
            }

            return (
              <Menus
                key={index}
                assignees={menuAssignees}
                id={remoteAction.name}
                menuParams={params}
                parentKey="drilldown"
                title={remoteAction.name}
                toggleActives={toggleActives}
                variables={variables}
                onChange={handleReturnVariableChange}
              />
            )
          })}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default memo(RemoteActionsContainer)
