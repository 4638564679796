import { useCallback, useEffect, useRef } from 'react'
import { uniqBy } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { tuiSlice } from '../../../store/slices'
import { collectNodes } from '../../../utils'

function useLoadStopsSelected({ loadTreeTableRef }) {
  const loadStopRef = useRef<
    Record<string, (rowIds: Record<string, unknown>[]) => void | undefined>
  >({})

  const [{ registerEvent }] = useReduxState(tuiSlice.selectSlice)
  const [{ getFieldType }] = useReduxState(tuiSlice.selectSlice)

  const loadStopsSelected = useCallback(
    (selectedRows) => {
      const { flatRows } = loadTreeTableRef.current.getRowModel()

      const leafSelectedRows = uniqBy(
        collectNodes({ Children: selectedRows }, (node) => node?.Leaf),
        'ID'
      )

      const selectedLoadStopRows = leafSelectedRows.reduce<{
        index: number[]
        LoadName: string[]
        LoadStopName: string[]
      }>(
        (acc, leafSelectedRow) => {
          const matchingRows = flatRows.filter((row) => {
            return row?.original.ID === leafSelectedRow.LoadName && row.original?.Leaf
          })

          matchingRows.forEach((matchingRow, currentIndex) => {
            if (!matchingRow.getIsSelected()) {
              return
            }
            acc.index.push(currentIndex)
            acc.LoadName.push(matchingRow.original.LoadName)
            acc.LoadStopName.push(matchingRow.original.LoadStopName)
          })

          return acc
        },
        { index: [], LoadName: [], LoadStopName: [] }
      )

      return selectedLoadStopRows
    },
    [loadTreeTableRef]
  )

  useEffect(() => {
    const fn = 'handleLoadStopsSelected'
    loadStopRef.current[fn] = registerEvent({
      key: 'LoadStopsSelected',
      fn: loadStopsSelected,
      returnTypes: {
        index: PluginTypes.arrayOf(PluginTypes.string),
        LoadName: PluginTypes.arrayOf(PluginTypes.string),
        LoadStopName: PluginTypes.arrayOf(PluginTypes.string)
      }
    })
  }, [getFieldType, loadStopsSelected, registerEvent])

  return { loadStopRef }
}

export default useLoadStopsSelected
