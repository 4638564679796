import { useRef, useState, useEffect, useCallback, useMemo, memo, MouseEvent } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import cx from 'classnames'
import { lruMemoize, createSelectorCreator } from 'reselect'
import { connect } from 'react-redux'
import { Wrapper, StatesToProps } from '../..'
import Menu from '../Menu'
import { GetPluginSchema } from '../../helpers'
import Progress from '../Progress'
import { ErrorBoundary } from '@/components'
import { select } from '@/crudoptV3'
import { getPlugin } from '@/actions/plugin'
import { RootState } from '@/store'
import {
  ConnectorProvidedProps,
  ConnectorProps,
  MenuProps,
  CreateConnectorParams
} from './Connector.types'
import BasePluginComponent from '@/BasePlugin/components/BasePluginComponent/index'
import { PluginProps } from '@/BasePlugin/components/PluginProps.types'
import { StatesToPropsExtra } from '@/BasePlugin/components/StatesToProps/StatesToProps.types'

const emptyObject = {}

const portalContainer = document.getElementsByTagName('body')[0]

const getPluginState = (state: RootState, ownProps: ConnectorProps) => {
  return select(
    getPlugin(
      ownProps.params.catalogId,
      ownProps.params.menuId,
      ownProps.params.pageId,
      ownProps.id
    ),
    state.model3
  )
}

const getStateVariablesInJSON = (state: RootState, ownProps: ConnectorProps) => {
  const {
    params: { storeIndex },
    settings: { config = {} } = {}
  } = ownProps

  const { stores = [] } = state.relationReducers || {}
  const store = stores[storeIndex] || {}
  const {
    global: { variables: globalVariables = {} } = {},
    page: { variables: pageVariables = {} } = {},
    menu: { variables: menuVariables = {} } = {}
  } = store || {}

  const allVariables = {
    ...pageVariables,
    ...menuVariables,
    ...globalVariables
  }

  const str = JSON.stringify(config)
  const variables: string[] = []
  let m

  const regex = /{([^"]+)}/g
  // eslint-disable-next-line no-cond-assign
  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex += 1
    }
    m.forEach((match, groupIndex) => {
      if (groupIndex === 1) {
        variables.push(match)
      }
    })
  }

  return _.transform(
    allVariables,
    (result, v, k) => {
      if (_.indexOf(variables, k) !== -1) result[k] = v
    },
    {}
  )
}

const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)

const pluginSelector = () => {
  return createDeepEqualSelector([getPluginState], (plugin) => plugin)
}
const variableSelector = () => {
  return createDeepEqualSelector([getStateVariablesInJSON], (plugin) => plugin)
}

const makeMapStateToProps = () => {
  const ps = pluginSelector()
  const vs = variableSelector()
  const mapStateToProps = (state: RootState, props: ConnectorProps) => {
    return {
      max: state.maximize.max,
      variables: vs(state, props),
      plugin: ps(state, props),
      token: state.oidc.user?.access_token,
      userName: state.oidc.user?.profile.name,
      preferredUsername: state.oidc.user?.profile.preferred_username
    }
  }
  return mapStateToProps
}

const propsAreEqual = (prevProps: ConnectorProps, nextProps: ConnectorProps) => {
  // TODO: Backward compatability
  const { settings, variables, max } = prevProps
  return (
    max === nextProps.max &&
    _.isEqual(settings, nextProps.settings) &&
    _.isEqual(variables, nextProps.variables)
  )
}

const initialSize = { top: 0, bottom: 0, left: 0, right: 0, height: 0, width: 0, x: 0, y: 0 }

const pluginDefault = {} as ConnectorProps['plugin']
const pluginDataDefault = {} as ConnectorProps['plugin']['data']
const pluginDataConfigDefault = {} as ConnectorProps['plugin']['data']['config']
const pluginDataQueryDefault = {} as ConnectorProps['plugin']['data']['query']

export default (
  ...[ChildComponent, selectConnectorProps = null, isHook]: CreateConnectorParams
) => {
  if (typeof selectConnectorProps !== 'function') {
    throw new Error('You must provide a function to get "Connector" props')
  }

  // function getObjectDiff(obj1, obj2) {
  //   const diff = Object.keys(obj1).reduce((result, key) => {
  //     if (!obj2.hasOwnProperty(key)) {
  //       result.push(key)
  //     } else if (_.isEqual(obj1[key], obj2[key])) {
  //       const resultKeyIndex = result.indexOf(key)
  //       result.splice(resultKeyIndex, 1)
  //     }
  //     return result
  //   }, Object.keys(obj2))
  //
  //   return diff
  // }

  // Memoize ChildComponent if it is a hook based component.
  const Plugin = isHook ? memo(ChildComponent) : ChildComponent

  const Connector = (props: ConnectorProps) => {
    const {
      // PROPS FROM REDUX
      dispatch,
      max,
      plugin,
      plugin: {
        needFetch,
        fetch,
        isSuccess: isSuccessVal,
        data,
        data: {
          config = pluginDataConfigDefault,
          query = pluginDataQueryDefault
        } = pluginDataDefault
      } = pluginDefault,
      preferredUsername,
      token,
      userName,
      variables,
      // PROPS FROM PARENT COMPONENT (VIEW)
      params,
      params: { environment },
      onMounted,
      onRemoveClick,
      draggableHandleClassName,
      showDeleteIcon,
      id,
      type,
      isPreviewMode,
      previewMenuProps,
      settings: {
        config: settingsConfig,
        query: settingsQuery,
        // TODO: data and status.data are the same object
        data: settingsData, // for plugin preview
        status: settingsStatus // for plugin preview
      },
      onReady
    } = props

    const [exportedColumn, setExportedColumn] = useState([])
    const [schema, setSchema] = useState({})
    const [args, setArgs] = useState({})
    const [size, setSize] = useState(initialSize)

    const basePluginContainerRef = useRef<null | HTMLDivElement>(null)
    const menuWidgetRef = useRef<null | HTMLDivElement>(null)
    const widgetContentRef = useRef<null | HTMLDivElement>(null)
    const timeoutRef = useRef<null | NodeJS.Timeout>(null)
    const isInitialRenderRef = useRef(true)

    const handleResize = () => {
      if (!widgetContentRef.current) {
        return
      }
      const newSize = widgetContentRef.current.getBoundingClientRect()
      // TODO: Backward compatability
      if (!_.isEqual(size, newSize)) {
        setSize(newSize)
      }
    }

    const handleTabSelected = () => handleResize()

    useEffect(() => {
      window.addEventListener('resize', handleResize)
      window.addEventListener('tabSelected', handleTabSelected)
      return () => {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('tabSelected', handleTabSelected)
      }
    }, [])

    const hideMenu = (menuWidget: HTMLDivElement) => {
      if (timeoutRef.current) {
        return
      }

      timeoutRef.current = setTimeout(() => {
        menuWidget.classList.remove('-hover')
        clearTimeout(timeoutRef.current as NodeJS.Timeout)
        timeoutRef.current = null
      }, 5000)
    }

    const mouseHandler = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (!menuWidgetRef.current || !basePluginContainerRef.current) {
        return
      }

      const relatedTarget = basePluginContainerRef.current
      const { offsetWidth, offsetHeight } = menuWidgetRef.current
      const { type: eventType } = event
      const { left = 0, top = 0, width } = relatedTarget.getBoundingClientRect()

      const newLeft = left + (width - offsetWidth) / 2
      const isHover = eventType === 'mouseenter'

      menuWidgetRef.current.style.left = `${newLeft > 0 ? newLeft : left}px`
      // TODO: +5 position menu over the element and this prevents triggering mouseleave and
      // So the menu doesn't disappear unintentionally.
      menuWidgetRef.current.style.top = `${top + 5}px`
      menuWidgetRef.current.style.marginTop = `${-offsetHeight}px`
      menuWidgetRef.current.classList[isHover ? 'add' : 'remove']('-hover')

      if (isHover) hideMenu(menuWidgetRef.current)
    }

    const mouseMove = () => {
      if (!menuWidgetRef.current) {
        return
      }

      const isOver = menuWidgetRef.current.classList.contains('-hover')
      if (isOver) {
        hideMenu(menuWidgetRef.current)
      } else {
        menuWidgetRef.current.classList.add('-hover')
      }
    }

    const reportExportInfo = useCallback(
      (newExportedColumn) => {
        // TODO: Backward compatability
        if (!_.isEqual(exportedColumn, newExportedColumn)) {
          setExportedColumn(newExportedColumn)
        }
      },
      [exportedColumn]
    )

    const stateChange = useCallback(
      (newState) => {
        // TODO: Backward compatability
        if (!_.isEqual(newState.args, args)) {
          setArgs(newState.args)
        }
      },
      [args]
    )

    useEffect(() => {
      // TODO: needFetch is undefined and never changes. Refactor.
      if (needFetch) {
        dispatch(fetch)
      }
    }, [])

    useEffect(() => handleResize(), [])

    // TODO: Is it required to watch plugin.data.type? It will be same everytime.
    useEffect(() => {
      setSchema(GetPluginSchema(plugin.data.type))
    }, [plugin?.data?.type])

    useEffect(() => {
      if (isInitialRenderRef.current) {
        isInitialRenderRef.current = false
        return
      }

      setTimeout(handleResize, 800)
    }, [max, id])

    const memoizedNewConfig = useMemo(() => {
      let strconf = JSON.stringify(settingsConfig || config)
      _.each(variables, (v, k) => {
        strconf = _.replace(strconf, `{${k}}`, v)
      })
      return JSON.parse(strconf)
    }, [settingsConfig, config, variables])

    const isMaximized = max === id

    const memoizedSettings = useMemo(() => {
      return {
        // object - should memo
        config: memoizedNewConfig,
        query: settingsQuery || query,
        data: settingsData,
        status: settingsStatus
      }
    }, [memoizedNewConfig, settingsQuery, query, settingsData, settingsStatus])

    const connectorProvidedProps: ConnectorProvidedProps = {
      id,
      params,
      preferredUsername,
      isPreviewMode,
      isMaximized,
      schema,
      size,
      token,
      userName,
      settings: memoizedSettings,
      reportExportInfo
    }

    const {
      settings: {
        query: { fields = [], formattedFields = [] },
        config: { general: { export: exportable = false, maximizable = false, header } = {} } = {}
      }
    } = connectorProvidedProps

    const item: MenuProps =
      isPreviewMode && previewMenuProps
        ? previewMenuProps
        : { id, type, config: settingsConfig, query: settingsQuery }

    const isConfiguration = environment === 'Configuration'

    const $menu = createPortal(
      <div
        ref={menuWidgetRef}
        className="widget-fixed"
        onMouseEnter={mouseHandler}
        onMouseLeave={mouseHandler}
      >
        <Menu
          args={args}
          exportable={exportable}
          exportedColumn={exportedColumn}
          fields={fields}
          formattedFields={formattedFields}
          isPreviewMode={isPreviewMode}
          item={item}
          maximizable={maximizable}
          params={params}
          showDeleteIcon={showDeleteIcon}
          onRemoveClick={onRemoveClick}
        />
      </div>,
      portalContainer
    )

    return (
      <div
        ref={basePluginContainerRef}
        className={cx('widget-wrapper', item?.type.toLowerCase(), { maximize: isMaximized })}
        id={`plugin_${id}`}
        onMouseEnter={mouseHandler}
        onMouseLeave={mouseHandler}
        onMouseMove={mouseMove}
      >
        {isConfiguration ? (
          <span className={cx('plugin-move-handler', draggableHandleClassName)}>
            <i aria-hidden="true" className="fa fa-arrows" />
          </span>
        ) : null}
        {$menu}
        {!_.isEmpty(header) ? <div className="widget-title">{header}</div> : null}
        <div
          ref={widgetContentRef}
          className={cx('widget-content', { withTitle: !_.isEmpty(header) })}
        >
          {isSuccessVal ? (
            <StatesToProps onChange={stateChange}>
              {(statesToProps: StatesToPropsExtra) => {
                return (
                  <Wrapper
                    {...connectorProvidedProps}
                    statesToPropsData={statesToProps}
                    wrapperData={data}
                    onMounted={onMounted}
                  >
                    {({ wrapperProps, isLoading, displayNoData, name }) => {
                      if (displayNoData) {
                        return (
                          <div className="no-data">
                            <section>
                              <h1>{name}</h1>
                              <i className="fa fa-exclamation-triangle" />
                              <div>No data to display</div>
                            </section>
                          </div>
                        )
                      }

                      return (
                        <Progress isLoading={isLoading} type={type}>
                          <ErrorBoundary>
                            <BasePluginComponent
                              args={args}
                              data={wrapperProps.data}
                              settings={connectorProvidedProps.settings}
                            >
                              {(basePluginProps) => {
                                const pluginProps: PluginProps = {
                                  onReady,
                                  ...connectorProvidedProps,
                                  ...wrapperProps,
                                  ...basePluginProps
                                }
                                const selectedProps =
                                  typeof selectConnectorProps === 'function'
                                    ? selectConnectorProps(pluginProps)
                                    : emptyObject
                                return <Plugin {...selectedProps} />
                              }}
                            </BasePluginComponent>
                          </ErrorBoundary>
                        </Progress>
                      )
                    }}
                  </Wrapper>
                )
              }}
            </StatesToProps>
          ) : null}
        </div>
      </div>
    )
  }

  return connect(makeMapStateToProps)(memo(Connector, propsAreEqual))
}
