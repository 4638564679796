Ext.define('MDM.src.view.Grouping.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.grouping-mainviewmodel',
  stores: {
    CusHieStore: {
      model: 'MDM.src.view.Grouping.model.CusHie',
      autoLoad: true,
      autoSync: false,
      groupField: 'MegaRegion',
      listeners: {
        load: 'onCusHieStoreLoad'
      }
    },
    CusHieRelStore: {
      model: 'MDM.src.view.Grouping.model.Relations',
      autoLoad: false,
      autoSync: false,
      groupField: 'HierarchyText',
      listeners: {
        load: 'CusHieRelStoreLoad'
      }
    },
    DPIListStore: {
      model: 'MDM.src.view.materialedit.model.DPIFamilyList',
      remoteSort: 'true', //For Remote Sorting
      autoLoad: true
    },
    DPITreeStore: {
      type: 'tree',
      model: 'MDM.src.view.Grouping.model.DPITreeModel',
      autoLoad: false,
      root: {
        expanded: true,
        text: 'DPI Families',
        iconCls: 'fa fa-sitemap',
        children: []
      },
      listeners: {
        load: 'onDPITreeStoreLoad'
      }
    }
  },
  formulas: {
    descriptions: {
      bind: {
        bindTo: '{curCusHieRec}',
        deep: true
      },
      get: function (rec) {
        if (rec) return rec.get('Descriptions')
        return ''
      }
    }
  },
  data: {
    curCusHieRec: false,
    isReadOnly: false
  }
})
