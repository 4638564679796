import { getExportableColumnDefs } from '..'
import { SlvyColDef, SlvyColGroupDef } from '../../../types'
import { AG_GRID_ENUMS } from '../../../enums'

const { DELETE_COLUMN, CUSTOM_ROW_INFO } = AG_GRID_ENUMS

const emptyColumnDefs = [
  {
    field: 'col1',
    hide: true,
    customInfo: {
      isExportable: false
    }
  },
  {
    field: DELETE_COLUMN,
    hide: false,
    customInfo: {
      isExportable: false
    }
  },
  {
    field: CUSTOM_ROW_INFO,
    hide: false,
    customInfo: {
      isExportable: false
    }
  },
  {
    hide: false,
    customInfo: {
      isExportable: false
    }
  }
] as SlvyColGroupDef[]

const filteredColumnDef = { field: 'col2', hide: false, customInfo: { isExportable: true } }
const filteredColumnDefs = [...emptyColumnDefs, filteredColumnDef] as SlvyColDef[]

const getColumnDefs = (param = ''): SlvyColGroupDef[] => {
  let columns: SlvyColGroupDef[]
  if (param === 'isEmpty') {
    columns = emptyColumnDefs
  }

  if (param === 'isFiltered') {
    columns = filteredColumnDefs as SlvyColGroupDef[]
  }

  return columns
}

describe('AgGrid:Helpers:Column:getExportableColumnDefs', () => {
  let params = getColumnDefs()

  beforeEach(() => {
    params = getColumnDefs()
  })

  it('should filter column that has hide property as true or colDef.customInfo.isExportable is false', () => {
    params = getColumnDefs('isEmpty')
    const result = getExportableColumnDefs(params)
    expect(result).toEqual([])
  })

  it('should filter columns and return one single column which is not hide', () => {
    params = getColumnDefs('isFiltered')
    const result = getExportableColumnDefs(params)
    expect(result).toEqual([filteredColumnDef])
  })
})
