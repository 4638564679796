Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.Panel.SupplySource', {
  extend: 'MDM.src.ux.GridPanel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-geogrid-supplysource',
  title: 'Country',
  iconCls: 'fas fa-industry',
  border: true,
  listeners: {
    celldblclick: 'onSupplySourceCellClick'
  },
  tools: [
    {
      type: 'plus',
      tooltip: 'Add new Supply Source',
      bind: {
        hidden: '{readOnlyMode}'
      },
      handler: 'onAddSupplySource'
    }
  ],
  plugins: [
    {
      ptype: 'uxcellediting',
      clicksToEdit: 2
    }
  ],
  multiSelect: true,
  viewConfig: {
    stripeRows: false,
    plugins: [
      {
        ptype: 'uxgridviewdragdrop',
        id: 'dd',
        containerScroll: true,
        enableDrop: false,
        dragGroup: 'dd-grid-to-grid-group2',
        dropZone: {
          overClass: 'dd-over-gridview'
        }
      }
    ],
    listeners: {
      beforedrop: function (node, data, overModel, dropPosition, dropHandlers) {
        // Defer the handling
        dropHandlers.wait = true
        // here you have the record from the treeview and can do anything you like with it.
        var record = data.records[0]
        Ext.MessageBox.confirm(
          'Move',
          'Your are about to move <span style="color:red">' +
            record.get('ProductGroup') +
            '</span>, Are you sure?',
          function (btn) {
            if (btn === 'yes') {
              dropHandlers.processDrop()
            } else {
              // IMPORTANT - In this case, we want to cancel the drop as the records aren't compatible
              dropHandlers.cancelDrop()
            }
          }
        )
      }
    }
  },
  features: [
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      text: 'SupplySource',
      dataIndex: 'SupplySource',
      flex: 1,
      summaryType: 'count',
      menuDisabled: true,
      summaryRenderer: function (value) {
        return Ext.String.format('<b>{0} SupplySource{1}</b>', value, value > 1 ? 's' : '')
      }
    },
    {
      text: 'Description',
      dataIndex: 'Description',
      menuDisabled: true,
      flex: 1
    },
    {
      dataIndex: 'IsActive',
      menuDisabled: true,
      text: 'Active',
      align: 'center',
      renderer: function (value, metaData, record) {
        if (record.get('IsActive') == 1) return '<i class="fa fa-check" aria-hidden="true"></i>'
        return ''
      }
    },
    {
      dataIndex: 'IsShownOnTool',
      menuDisabled: true,
      text: 'Visible',
      align: 'center',
      renderer: function (value, metaData, record) {
        if (record.get('IsShownOnTool') == 1)
          return '<i class="fa fa-check" aria-hidden="true"></i>'
        return ''
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      bind: {
        hidden: '{readOnlyMode}'
      },
      align: 'center',
      items: [
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete Record',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          handler: 'onSupplySourceDelete'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      align: 'center',
      bind: {
        hidden: '{readOnlyMode}'
      },
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          getClass: function (value, metadata, record) {
            if (record.get('isEditable')) {
              return 'fa fa-pencil-square-o'
            }
            return 'fa fa-eye '
          },
          tooltip: 'View/Edit',
          handler: 'onEditSupplySource'
        }
      ]
    }
  ]
})
