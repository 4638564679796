/* global Ext, po */
import React, { Component } from 'react'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import moment from 'moment-timezone'
import hash from 'object-hash'

/* 3rd-party imports */
import alasql from 'alasql'
import Highcharts from 'highcharts'
import { ExtContainer } from '@sencha/ext-react-classic'

import createPlugin, { PluginTypes } from '@/BasePlugin'
import { ExtRoot } from '@/components'
import { getExtContainerSize } from '@/helpers'
import { API_URL } from '@/constants'

// EXT_PACKAGE
import './ext-package/sass/etc/all.scss'
require('./ext-package')

class PurchaseOrder extends Component {
  constructor(props) {
    super(props)

    this.resizeObserver = null

    this.state = {
      columnStates: null,
      attributeFilterArgs: [],
      filterArgs: {
        selectedDC: null,
        selectedProductCode: null,
        selectedK1K: null,
        selectedK2K: null,
        selectedK3K: null,
        selectedDate: null,
        endDate: null,
        selectedStore: null,
        viewMode: 'OneriVar'
      },
      buttonStatus: {
        cancelButtonEnable: true,
        exportButtonEnable: true,
        massUpdateButtonEnable: true,
        sapButtonEnable: false,
        saveButtonEnable: true
      }
    }

    this.setupExtJS(props)
    this.bindMethods()
  }

  setupExtJS(props) {
    if (Ext.Ajax.hasListeners['beforerequest']) {
      Ext.Ajax.clearListeners()
    }

    Ext.define('po.ExtGlobal', {
      singleton: true,
      config: {
        dragonApiUrl: `${API_URL}/data/${props.id}/invoke/`,
        authToken: props.token,
        pluginId: props.id
      },
      constructor: function (config) {
        const me = this
        me.initConfig(config)
      }
    })

    const that = this

    function saveDocumentAs(config) {
      const { userName = null, actualFilters = {}, createLog } = that.props

      config = {
        ...config,
        userName,
        createLog,
        filters: {
          ...config.filters,
          ...actualFilters
        }
      }
      const cmp = this.cmp,
        deferred = new Ext.Deferred(),
        exporter = this.getExporter(config)

      cmp.fireEvent('beforedocumentsave', cmp, { config, exporter })

      this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])

      return deferred.promise
    }

    Ext.define(null, {
      override: 'Ext.grid.plugin.Exporter',
      saveDocumentAs
    })

    Ext.define(null, {
      override: 'Ext.pivot.plugin.Exporter',
      saveDocumentAs
    })

    /* HACK : 3rd-party imports */
    window.alasql = alasql
    window.Highcharts = Highcharts

    Ext.require('Ext.button.*')
    Ext.require('Ext.calendar.*')
    Ext.require('Ext.exporter.*')
    Ext.require('Ext.form.*')
    Ext.require('Ext.grid.*')
    Ext.require('Ext.panel.*')
    Ext.require('Ext.ux.*')
    Ext.require('Ext.window.Toast')

    Ext.require('po.*')

    const defaultHeaders = Ext.Ajax.getDefaultHeaders() || {}

    if (!defaultHeaders['Authorization']) {
      defaultHeaders['environment'] = props.params.environment
      defaultHeaders['Authorization'] = 'Bearer ' + props.token
      Ext.Ajax.setDefaultHeaders(defaultHeaders)
    }

    Ext.Ajax.addListener('beforerequest', function (conn, options) {
      if (options.url.includes('###PO_PLUGIN_URL###')) {
        options.url = options.url.replace('###PO_PLUGIN_URL###', `${API_URL}`)
      } else {
        options.url = options.url.replace('###PO_API_URL###', `${po.ExtGlobal.getDragonApiUrl()}`)

        options.url = options.url.replace('/po/', '/')
        options.url = options.url.replace('/localization/', '/')
        options.url = options.url.replace('/create', '_create')
        options.url = options.url.replace('/read', '_read')
        options.url = options.url.replace('/update', '_update')
        options.url = options.url.replace('/destroy', '_destroy')
      }
    })
  }

  bindMethods() {
    this.setFilter = this.setFilter.bind(this)
    this.mainGridDrilldown = this.mainGridDrilldown.bind(this)
    this.setAttributeFilter = this.setAttributeFilter.bind(this)
    this.mainGridRowSelected = this.mainGridRowSelected.bind(this)
    this.buttonsStatusChange = this.buttonsStatusChange.bind(this)
    this.sapButtonStateChanged = this.sapButtonStateChanged.bind(this)
    this.mainGridCalendarEvent = this.mainGridCalendarEvent.bind(this)
    this.saveButtonStateChanged = this.saveButtonStateChanged.bind(this)
    this.getPanelVisibilityConfig = this.getPanelVisibilityConfig.bind(this)
    this.cancelButtonStateChanged = this.cancelButtonStateChanged.bind(this)
    this.exportButtonStateChanged = this.exportButtonStateChanged.bind(this)
    this.getMainGridColumnDefinition = this.getMainGridColumnDefinition.bind(this)
    this.massUpdateButtonStateChanged = this.massUpdateButtonStateChanged.bind(this)
    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
  }

  componentDidMount() {
    const pluginId = this.props.id
    this.props.reloadExtRoot(pluginId, () => {
      this.poDidMount()
      this.observeResize(pluginId)
    })
  }

  poDidMount() {
    const items = _.get(this.props, 'settings.config.attributeFilters.items', {})

    const commonReturnTypes = {
      ID: PluginTypes.int,
      UrunAdi: PluginTypes.string,
      urunKodu: PluginTypes.string,
      urunAciklamasi: PluginTypes.string,
      tedarikciKodu: PluginTypes.string,
      tedarikciAdi: PluginTypes.string,
      depoKodu: PluginTypes.string,
      depoAdi: PluginTypes.string
    }

    this.mainGridRowSelected = this.props.registerEvent({
      key: 'mainGridRowSelected',
      fn: this.mainGridRowSelected,
      returnTypes: commonReturnTypes
    })

    this.mainGridCalendarEvent = this.props.registerEvent({
      key: 'mainGridCalendarEvent',
      fn: this.mainGridCalendarEvent,
      returnTypes: commonReturnTypes
    })

    this.cancelButtonStateChanged = this.props.registerEvent({
      key: 'cancelButtonStateChanged',
      fn: this.cancelButtonStateChanged,
      returnTypes: {
        enabled: PluginTypes.boolean
      }
    })

    this.exportButtonStateChanged = this.props.registerEvent({
      key: 'exportButtonStateChanged',
      fn: this.exportButtonStateChanged,
      returnTypes: {
        enabled: PluginTypes.boolean
      }
    })

    this.massUpdateButtonStateChanged = this.props.registerEvent({
      key: 'massUpdateButtonStateChanged',
      fn: this.massUpdateButtonStateChanged,
      returnTypes: {
        enabled: PluginTypes.boolean
      }
    })

    this.sapButtonStateChanged = this.props.registerEvent({
      key: 'sapButtonStateChanged',
      fn: this.sapButtonStateChanged,
      returnTypes: {
        enabled: PluginTypes.boolean
      }
    })

    this.saveButtonStateChanged = this.props.registerEvent({
      key: 'saveButtonStateChanged',
      fn: this.saveButtonStateChanged,
      returnTypes: {
        enabled: PluginTypes.boolean
      }
    })

    this.mainGridDrilldown = this.props.registerEvent({
      key: 'mainGridDrilldown',
      fn: this.mainGridDrilldown,
      returnTypes: commonReturnTypes
    })

    this.props.registerMethod({
      key: 'setFilter',
      fn: this.setFilter.bind(this),
      args: [
        {
          name: 'selectedDC',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'selectedProductCode',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'selectedK1K',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'selectedK2K',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'selectedK3K',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'selectedDate',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'endDate',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'selectedStore',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'viewMode',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'setAttributeFilter',
      fn: this.setAttributeFilter.bind(this),
      args: _.map(items, (filter) => {
        return { name: filter.column, type: PluginTypes.string }
      })
    })

    this.props.registerMethod({
      key: 'massUpdateButtonClick',
      fn: this.massUpdateButtonClick.bind(this),
      args: [
        {
          name: 'refreshKey',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'saveChanges',
      fn: this.saveChanges.bind(this),
      args: [
        {
          name: 'refreshKey',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'cancelChanges',
      fn: this.cancelChanges.bind(this),
      args: [
        {
          name: 'refreshKey',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'massUpdate',
      fn: this.massUpdate.bind(this),
      args: [
        {
          name: 'refreshKey',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'sendToSAP',
      fn: this.sendToSAP.bind(this),
      args: [
        {
          name: 'refreshKey',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerMethod({
      key: 'exportToExcel',
      fn: this.exportToExcel.bind(this),
      args: [
        {
          name: 'refreshKey',
          type: PluginTypes.string
        }
      ]
    })

    this.props.registerAuthorizations(['CanEdit', 'CanResetColumnSequence'])
  }

  UNSAFE_componentWillMount() {
    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition
      })
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  observeResize(pluginId) {
    const container = document.getElementById(`slvyExtContainer-${pluginId}`)
    if (_.isEmpty(container)) {
      return
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      const size = _.isEmpty(entries) ? getExtContainerSize(pluginId) : entries[0].contentRect
      this.setSize(size)
    })

    this.resizeObserver.observe(container)
  }

  setSize({ width, height }) {
    // If size of the grid changes do not render the grid
    if (this.poMainViewRef) {
      this.poMainViewRef.setWidth(width)
      this.poMainViewRef.setHeight(height)
    }
  }

  findColumn(mainGridColumns, col) {
    return _.find(mainGridColumns, (o) => o.columnId === col.columnId)
  }

  handleSynchronizeDataDefinition(fields, value) {
    const mainGridColumnDefault = _.get(this.props, 'schema.properties.mainGridColumns.default', [])

    const newValue = _.cloneDeep(value)

    let mainGridColumns = newValue.mainGridColumns

    // add new columns
    for (let i = 0, len = mainGridColumnDefault.length; i < len; i++) {
      var col = mainGridColumnDefault[i]

      const found = this.findColumn(mainGridColumns, col)

      if (!found) {
        mainGridColumns.splice(i, 0, col)
      }
    }

    // properties deleted
    _.forEach(mainGridColumns, (column) => {
      delete column.hideable
      delete column.filter
    })

    // duplicate column & exist in defaults check
    const duplicateCheck = {}
    newValue.mainGridColumns = _.reduce(
      mainGridColumns,
      (result, value) => {
        const foundInDefaults = this.findColumn(mainGridColumnDefault, value)

        if (!duplicateCheck[value.columnId] && foundInDefaults) {
          duplicateCheck[value.columnId] = true
          result.push(value)
        }

        return result
      },
      []
    )

    return newValue
  }

  setFilter(args) {
    const view = this.poMainViewRef

    this.setState({ args })

    if (view) {
      view.setSelectedDC(args.selectedDC)
      view.setSelectedProductCode(args.selectedProductCode)
      view.setSelectedK1K(args.selectedK1K)
      view.setSelectedK2K(args.selectedK2K)
      view.setSelectedK3K(args.selectedK3K)

      const date = _.isEmpty(args.selectedDate)
        ? null
        : moment(args.selectedDate).format('YYYY-MM-DD')
      view.setSelectedDate(date)
      const endDate = _.isEmpty(args.endDate) ? null : moment(args.endDate).format('YYYY-MM-DD')
      view.setEndDate(endDate)

      view.setSelectedStore(args.selectedStore)
      view.setViewMode(args.viewMode)

      view.fireEvent('onRunReport')
    }
  }

  setAttributeFilter(args) {
    const view = this.poMainViewRef

    this.setState({ attributeFilterArgs: args })

    view.setMainGridFilters(args)
  }

  massUpdateButtonClick(args = {}) {
    if (args.refreshKey) {
      this.poMainViewRef.fireEvent('onMassUpdateButtonClick')
    }
  }

  saveChanges(args = {}) {
    if (args.refreshKey) {
      this.poMainViewRef.fireEvent('onSaveChanges')
    }
  }

  cancelChanges(args = {}) {
    if (args.refreshKey) {
      this.poMainViewRef.fireEvent('onCancelChanges')
    }
  }

  massUpdate(args = {}) {
    if (args.refreshKey) {
      this.poMainViewRef.fireEvent('onMassUpdate')
    }
  }

  sendToSAP(args = {}) {
    if (args.refreshKey) {
      this.poMainViewRef.fireEvent('onSendToSAP')
    }
  }

  exportToExcel(args = {}) {
    if (args.refreshKey) {
      this.poMainViewRef.fireEvent('onExport')
    }
  }

  getMainGridColumnDefinition() {
    const { settings: { config: { mainGridHeaders = [], mainGridColumns = [] } = {} } = {} } =
      this.props

    const config = { columns: [] }
    const headersAdded = []

    _.forEach(mainGridColumns, (column) => {
      if (column.hidden) {
        return
      }

      if (_.isEmpty(column.text)) {
        delete column['text']
      } else {
        column.bind = {}
      }

      if (_.isEmpty(column.tooltip)) {
        delete column['tooltip']
      }

      if (column.groupHeader === '-') {
        config.columns.push({
          ...column
        })
      } else {
        const headerIndex = column.groupHeader * 1
        if (!headersAdded[headerIndex]) {
          const groupHeader = {
            ...mainGridHeaders[headerIndex],
            columns: []
          }
          headersAdded[headerIndex] = groupHeader

          config.columns.push({
            ...groupHeader
          })
        }

        const header = headersAdded[headerIndex]
        header.columns.push({
          ...column
        })
      }
    })

    return config.columns
  }

  getDataFromRow(record) {
    const {
      data: {
        ID,
        UrunAdi,
        urunKodu,
        urunAciklamasi,
        tedarikciKodu,
        tedarikciAdi,
        depoKodu,
        depoAdi
      } = {}
    } = record

    return {
      ID,
      UrunAdi,
      urunKodu,
      urunAciklamasi,
      tedarikciKodu,
      tedarikciAdi,
      depoKodu,
      depoAdi
    }
  }

  mainGridRowSelected(record) {
    return this.getDataFromRow(record)
  }

  mainGridDrilldown(record) {
    return this.getDataFromRow(record)
  }

  mainGridCalendarEvent(record) {
    return this.getDataFromRow(record)
  }

  buttonsStatusChange(status) {
    const buttonStatus = this.state.buttonStatus
    if (buttonStatus.cancelButtonEnable !== status.cancelButtonEnable) {
      this.cancelButtonStateChanged(status.cancelButtonEnable)
    }

    if (buttonStatus.exportButtonEnable !== status.exportButtonEnable) {
      this.exportButtonStateChanged(status.exportButtonEnable)
    }

    if (buttonStatus.massUpdateButtonEnable !== status.massUpdateButtonEnable) {
      this.massUpdateButtonStateChanged(status.massUpdateButtonEnable)
    }

    if (buttonStatus.sapButtonEnable !== status.sapButtonEnable) {
      this.sapButtonStateChanged(status.sapButtonEnable)
    }

    if (buttonStatus.saveButtonEnable !== status.saveButtonEnable) {
      this.saveButtonStateChanged(status.saveButtonEnable)
    }

    this.setState({ buttonStatus: { ...status } })
  }

  cancelButtonStateChanged(enabled) {
    return { enabled }
  }

  exportButtonStateChanged(enabled) {
    return { enabled }
  }

  massUpdateButtonStateChanged(enabled) {
    return { enabled: !enabled }
  }

  sapButtonStateChanged(enabled) {
    return { enabled }
  }

  saveButtonStateChanged(enabled) {
    return { enabled }
  }

  onInitializeRef(data) {
    this.poMainViewRef = data.view
  }

  getPanelVisibilityConfig() {
    const {
      settings: {
        config: {
          panelVisibility: {
            productDetailVisible = true,
            inventoryProfileVisible = true,
            footerToolbarVisible = true,
            footerToolbarERPGroupVisible = true,
            resetColumnSequence = false
          } = {}
        } = {}
      } = {}
    } = this.props

    return {
      productDetailVisible,
      inventoryProfileVisible,
      footerToolbarVisible,
      footerToolbarERPGroupVisible,
      resetColumnSequence
    }
  }

  getZeroInventoryConfig() {
    const {
      settings: {
        config: {
          zeroInventoryGrid: {
            magzaKoduVisible = true,
            magzaAdiVisible = true,
            envanterVisible = true,
            statuVisible = true
          } = {}
        } = {}
      } = {}
    } = this.props

    return {
      magzaKoduVisible,
      magzaAdiVisible,
      envanterVisible,
      statuVisible
    }
  }

  render() {
    const {
      settings: {
        config,
        config: {
          settings: {
            applicationType = 'DC',
            isReasonCodeRequiredOnChange = false,
            groupByField = ''
          } = {},
          panelVisibility: {
            summaryEditorHidden,
            roundUpHidden,
            InventoryHidden,
            grupGridHidden
          } = {}
        } = {}
      } = {},
      params: { environment, catalogId } = {},
      id = 'id',
      token,
      isAllowed
    } = this.props

    const mainGridColumns = this.getMainGridColumnDefinition()
    const panelVisibilityConfig = this.getPanelVisibilityConfig()
    const zeroInventoryGridConfig = this.getZeroInventoryConfig()
    const hashCode = hash(config)
    const session = jwtDecode(token)
    const language = session.culture === 'tr-TR' ? 'TR' : 'EN'
    const key = id

    const packageProps = {
      xtype: 'pomainview',

      isToolbarVisible: false,
      key,
      width: '100%',
      height: '100%',

      token,
      language,
      catalogId,
      environment,
      groupByField,
      roundUpHidden,
      grupGridHidden,
      hash: hashCode,
      mainGridColumns,
      InventoryHidden,
      applicationType,
      summaryEditorHidden,
      pluginInstanceId: id,
      panelVisibility: panelVisibilityConfig,
      zeroInventoryGrid: zeroInventoryGridConfig,
      mainGridFilters: this.state.attributeFilterArgs,
      reasonCodeEnabled: isReasonCodeRequiredOnChange,
      canEdit: isAllowed('CanEdit'),
      onDrilldown: this.mainGridDrilldown,
      onCalendarEvent: this.mainGridCalendarEvent,
      onMainGridRowSelected: this.mainGridRowSelected,
      onButtonsStatusChange: this.buttonsStatusChange,
      canResetColumnSequence: isAllowed('CanResetColumnSequence'),
      onInitializeRef: this.onInitializeRef.bind(this),
      ...this.state.filterArgs
    }

    const extContainerProps = {
      width: '100%',
      height: '100%',
      cls: 'slvy-ext-container w-100 h-100',
      layout: 'fit',
      autoSize: true,
      items: [packageProps]
    }

    // needs a pure html element wrapper to observe
    const containerProps = {
      id: `slvyExtContainer-${id}`,
      className: extContainerProps.cls
    }

    return mainGridColumns.length ? (
      <ExtRoot pluginId={id}>
        <div {...containerProps}>
          <ExtContainer {...extContainerProps} />
        </div>
      </ExtRoot>
    ) : (
      <div {...containerProps}>
        <strong>[ Purchase Order ]</strong>
        <p>Please configure plugin</p>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  registerAuthorizations: props.registerAuthorizations,
  settings: props.settings,
  id: props.id,
  token: props.token,
  userName: props.userName,
  actualFilters: props.actualFilters,
  createLog: props.createLog,
  params: props.params,
  reloadExtRoot: props.reloadExtRoot,
  onReady: props.onReady,
  schema: props.schema,
  isAllowed: props.isAllowed
})

export default createPlugin(PurchaseOrder, selectConnectorProps)
