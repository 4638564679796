import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from '../appAPI'

const initialState = {
  clusterTree: {},
  errorSave: {},
  errorUpdate: {},
  isLoadingSave: false,
  isLoadingUpdate: false,
  SaveAssortment: {},
  UpdateAssortment: {}
}

export const saveAssortmentRequest = createAsyncThunk(
  'app/saveAssortmentRequest',
  async (action) => {
    try {
      const response = await makeRequest(action)
      // The value we return becomes the `fulfilled` action payload
      return JSON.parse(response.text)
    } catch (err) {
      throw err
    }
  }
)

export const updateAssortment = createAsyncThunk('app/updateAssortment', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload
    return JSON.parse(response.text)
  } catch (err) {
    throw err
  }
})

const saveAssortmentSlice = createSlice({
  name: 'assortment',
  initialState,
  reducers: {
    setClusterTree: (state, action) => {
      state.clusterTree = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAssortmentRequest.pending, (state, action) => {
        state.isLoadingSave = true
        state.errorSave = {}
      })
      .addCase(saveAssortmentRequest.fulfilled, (state, action) => {
        state.SaveAssortment = { ...action.payload }
        state.isLoadingSave = false
        state.errorSave = {}
      })
      .addCase(saveAssortmentRequest.rejected, (state, action) => {
        state.SaveAssortment = {}
        state.errorSave = { ...action.payload }
        state.isLoadingSave = false
      })
      .addCase(updateAssortment.pending, (state, action) => {
        state.isLoadingUpdate = true
        state.errorUpdate = {}
      })
      .addCase(updateAssortment.fulfilled, (state, action) => {
        state.UpdateAssortment = { ...action.payload }
        state.errorUpdate = {}
        state.isLoadingUpdate = false
      })
      .addCase(updateAssortment.rejected, (state, action) => {
        state.UpdateAssortment = {}
        state.errorUpdate = { ...action.payload }
        state.isLoadingUpdate = false
      })
  }
})

export const { setClusterTree } = saveAssortmentSlice.actions

export const clusterTree = (state) => {
  state.clusterTree
}
export const errorSave = (state) => {
  state.errorSave
}
export const errorUpdate = (state) => {
  state.errorUpdate
}
export const isLoadingSave = (state) => {
  state.isLoadingSave
}
export const isLoadingUpdate = (state) => {
  state.isLoadingUpdate
}
export const SaveAssortment = (state) => {
  state.SaveAssortment
}
export const UpdateAssortment = (state) => {
  state.UpdateAssortment
}

export default saveAssortmentSlice.reducer
