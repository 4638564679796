Ext.define('po.model.OrderRecommendation', {
  extend: 'po.model.Base',
  entityName: 'po/OrderRecommendation',
  idProperty: 'ID',
  fields: [
    { name: 'UrunAdi', type: 'string' },
    { name: 'K1K', type: 'string' },
    { name: 'K1N', type: 'string' },
    { name: 'K2K', type: 'string' },
    { name: 'K2N', type: 'string' },
    { name: 'K3K', type: 'string' },
    { name: 'K3N', type: 'string' },
    { name: 'ID', type: 'int' },
    { name: 'tarih', type: 'date', dateFormat: 'Y-m-d H:i:s.u' },
    { name: 'depoKodu', type: 'string' },
    { name: 'depoAdi', type: 'string' },
    { name: 'tesGun', type: 'int' },
    { name: 'urunKodu', type: 'string' },
    { name: 'urunAciklamasi', type: 'string' },
    { name: 'malzemeAile', type: 'string' },
    { name: 'malzemeAileKodu', type: 'string' },
    { name: 'malzemeAltGrup', type: 'string' },
    { name: 'malzemeAltGrupKodu', type: 'string' },
    { name: 'malzemeAnaGrup', type: 'string' },
    { name: 'malzemeAnaGrupKodu', type: 'string' },
    { name: 'gunBasiEldekiStok', type: 'float' },
    { name: 'gunSonuEldekiStok', type: 'float' },
    { name: 'depoAcikSiparis', type: 'float' },
    { name: 'depoTumAcikSiparisler', type: 'float' },
    { name: 'guvenlikStogu', type: 'float' },
    { name: 'magazaAcikSiparis', type: 'float' },
    { name: 'magazaBugunkuReplMiktari', type: 'float' },
    { name: 'RevPeriod', type: 'float' },
    { name: 'birSonrakiSipariseKadarkiMagazaTalebi', type: 'float' },
    { name: 'YuvarlanmamisDepoSiparisKoli', type: 'float' },
    { name: 'EnvanterSifiraDusuyorMu', type: 'int' },
    { name: 'YuvarlanmamisDepoSiparisKoli', type: 'float' },
    { name: 'depoSiparisKoli', type: 'float' },
    { name: 'degisenOneriKoli', type: 'float' },
    { name: 'depoSiparisAdet', type: 'float' },
    { name: 'PaletStd', type: 'float' },
    { name: 'koliIciMiktar', type: 'float' },
    { name: 'siparisGunu', type: 'string' },
    { name: 'magazaSayisi', type: 'int' },
    { name: 'magazaBasiKoli', type: 'float' },
    { name: 'birStokGunKoli', type: 'float' },
    { name: 'magazaGunlukOrtSatisKoli', type: 'float' },
    { name: 'birim', type: 'string' },
    { name: 'rafOmru', type: 'float' },
    { name: 'reviewPeriod', type: 'string' },
    { name: 'leadTime', type: 'float' },
    { name: 'minSiparisMiktari', type: 'float' },
    { name: 'siparisKatsayisi', type: 'float' },
    { name: 'paletKatsayisi', type: 'float' },
    { name: 'tedarikciKodu', type: 'string' },
    { name: 'tedarikciAdi', type: 'string' },
    { name: 'ortalamaTedarikSuresi', type: 'int' },
    { name: 'sonTedarikSuresi', type: 'int' },
    { name: 'onayFlag', type: 'boolean' },
    { name: 'SAPYollandi', type: 'boolean' },
    { name: 'sebepKodu', type: 'int', defaultValue: 0 },
    { name: 'KoliSatisFiyati', type: 'float' },
    { name: 'Son30GunSparkline', type: 'string' },
    { name: 'Son30GunDepoCikisSparkline', type: 'string' },
    { name: 'warningThreshold', type: 'float' },
    { name: 'toplamSatis_Son30Gun', type: 'float' },
    { name: 'toplamCiro_Son30Gun', type: 'float' },
    { name: 'toplamSatis_Son90Gun', type: 'float' },
    { name: 'toplamCiro_Son90Gun', type: 'float' },
    { name: 'toplamSatis_Son180Gun', type: 'float' },
    { name: 'toplamCiro_Son180Gun', type: 'float' },
    { name: 'onceliklendirme', type: 'int' },
    { name: 'warningPercentage', type: 'float' },
    { name: 'IsEditable', type: 'int', defaultValue: 1 },
    { name: 'IsSubstitute', type: 'int' },
    { name: 'SifirStokluMagazaSayisi', type: 'float' },
    { name: 'BlokajliMagazaSayisi', type: 'float' },
    { name: 'Kayip_Ciro', type: 'float' },
    { name: 'Son7GunSatisKoli', type: 'float' },
    { name: 'Son7GunMagazaSiparis', type: 'float' },
    { name: 'Son7GunDepoCikis', type: 'float' },
    { name: 'DepoStokGun', type: 'float' },
    { name: 'MagazaStokGun', type: 'float' },
    { name: 'MagazaStokKoli', type: 'float' },
    { name: 'CiroPayi', type: 'float' },
    { name: 'Son7GunSatisKoliArindirilmis', type: 'float' },
    { name: 'DepoBakiyeliStokGun', type: 'float' },
    { name: 'DepoSiparisliStokGun', type: 'float' },
    { name: 'DepoForecasteGoreStokGun', type: 'float' },
    { name: 'OrtalamaMagazaBasiGorselStok', type: 'float' },
    { name: 'PromosyonDurumu', type: 'string' },
    { name: 'Aciklamalar', type: 'string' },
    { name: 'SiparisKalemNo', type: 'string' },
    { name: 'SiparisNo', type: 'string' },
    { name: 'ErrorMessage', type: 'string' },
    { name: 'StokGunPayda', type: 'float' },
    { name: 'IsRowColored', type: 'boolean' },
    { name: 'IsRowColoredRed', type: 'boolean' },
    { name: 'IsStarred', type: 'boolean' },
    { name: 'SAPVendorApproval', type: 'boolean' },

    {
      name: 'tedarikci_onay_saati_bitis',
      type: 'date',
      dateFormat: 'H:i:s'
    },
    {
      name: 'HesaplananPalet',
      type: 'float',
      calculate: function (data) {
        return data.degisenOneriKoli / data.siparisKatsayisi
      }
    },
    {
      name: 'MonthlySalesRevLast10',
      calculate: function (data) {
        if (data.MonthlySalesRev < 10) return data.MonthlySalesRev
        return data.MonthlySalesRev.splice(
          data.MonthlySalesRev.length - 10,
          data.MonthlySalesRev.length
        )
      },
      depends: ['MonthlySalesRev']
    },
    {
      name: 'MonthlySalesRev',
      calculate: function (data) {
        if (!data.Son30GunSparkline) return []

        return data.Son30GunSparkline.split(',')
      },
      depends: ['Son30GunSparkline']
    },
    {
      name: 'Son30GunDepoCikisSparklineLast10',
      calculate: function (data) {
        if (data.Son30GunDepoCikisSparklineObj.length < 10) {
          var missing = 10 - data.Son30GunDepoCikisSparklineObj.length
          for (var i = 0; i < missing; i++) {
            data.Son30GunDepoCikisSparklineObj.push(0)
          }
          return data.Son30GunDepoCikisSparklineObj
        }

        return data.Son30GunDepoCikisSparklineObj.splice(
          data.Son30GunDepoCikisSparklineObj.length - 10,
          data.Son30GunDepoCikisSparklineObj.length
        )
      },
      depends: ['Son30GunDepoCikisSparklineObj']
    },
    {
      name: 'Son30GunDepoCikisSparklineObj',
      calculate: function (data) {
        if (!data.Son30GunDepoCikisSparkline) return []

        return data.Son30GunDepoCikisSparkline.split(',')
      },
      depends: ['Son30GunDepoCikisSparkline']
    },

    { name: 'isSendToSap', type: 'boolean' },
    {
      name: 'devliveryDate',
      type: 'date',
      dateWriteFormat: 'Y-m-d',
      dateFormat: 'Y-m-d'
    },
    {
      name: 'netDepoGiris',
      calculate: function (data) {
        return data.gunBasiEldekiStok + data.depoAcikSiparis
      },
      depends: ['gunBasiEldekiStok', 'depoAcikSiparis']
    },
    {
      name: 'editorMiktarDisabled',
      calculate: function (data) {
        if (data.IsEditable == 0 || data.isSendToSap || data.SAPYollandi) {
          return true
        }

        return false
      },
      depends: ['IsEditable', 'isSendToSap', 'SAPYollandi']
    },
    {
      name: 'netDepoCikis',
      calculate: function (data) {
        return data.magazaBugunkuReplMiktari + data.magazaAcikSiparis
      },
      depends: ['magazaBugunkuReplMiktari', 'magazaAcikSiparis']
    },
    {
      name: 'netStok',
      calculate: function (data) {
        return data.netDepoGiris - data.netDepoCikis
      },
      depends: ['netDepoGiris', 'netDepoCikis']
    },
    {
      name: 'yeniTalep',
      calculate: function (data) {
        return data.birSonrakiSipariseKadarkiMagazaTalebi + data.guvenlikStogu
      },
      depends: ['birSonrakiSipariseKadarkiMagazaTalebi', 'guvenlikStogu']
    },
    {
      name: 'siparisIhtiyaci',
      calculate: function (data) {
        return data.yeniTalep - data.netStok
      },
      depends: ['netStok', 'yeniTalep']
    },
    {
      name: 'isReasonCodeRequiredOnChange',
      calculate: function (data) {
        var degisenOneriKoli = data.degisenOneriKoli
        var depoSiparisKoli = data.depoSiparisKoli

        if (degisenOneriKoli == depoSiparisKoli) return false

        if (data.warningThreshold && data.warningThreshold > 0) {
          return Math.abs(degisenOneriKoli - depoSiparisKoli) > data.warningThreshold
        } else if (data.warningPercentage && data.warningPercentage > 0) {
          var decreasePercentage = ((depoSiparisKoli - degisenOneriKoli) / depoSiparisKoli) * 100
          return Math.abs(decreasePercentage) > data.warningPercentage * 100
        } else return false
      },
      depends: ['warningThreshold', 'warningPercentage', 'degisenOneriKoli', 'depoSiparisKoli']
    },
    { name: 'selected', type: 'boolean' },
    {
      name: 'DepoSiparisliStokGunUpdated',
      calculate: function (data) {
        return data.Son7GunDepoCikis + data.Son7GunSatisKoli == 0
          ? 0
          : (data.gunBasiEldekiStok + data.depoAcikSiparis + data.degisenOneriKoli) /
              data.StokGunPayda
      },
      depends: [
        'DepoSiparisliStokGun',
        'Son7GunDepoCikis',
        'Son7GunSatisKoli',
        'gunBasiEldekiStok',
        'depoAcikSiparis',
        'degisenOneriKoli',
        'StokGunPayda'
      ]
    }
    // {
    //   name: 'isValid',
    //   calculate: function (data) {
    //     if (data.sebepKodu == 0) return null
    //     else return 1
    //   },

    //   depends: ['sebepKodu']
    // }
  ]

  // validations: [{ field: 'isValid', type: 'range', min: 0.01, max: 2 }]
})
