Ext.define('MDM.src.view.globalapp.Panel.Time.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.globalapp-time-mainviewmodel',
  stores: {
    TimeStore: {
      type: 'tree',
      model: 'MDM.src.view.globalapp.Panel.Time.model.TimeModel',

      autoLoad: false,
      listeners: {},
      root: {
        expanded: true,
        Title: 'Time Hierarchy',
        iconCls: 'fa  fa-calendar-o',
        id: '0',
        children: []
      }
    }
  },
  data: {
    /* This object holds the arbitrary data that populates the ViewModel and is then available for binding. */
  }
})
