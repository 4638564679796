import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import _ from 'lodash'

export default class GridCell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props.gridCellProps, nextProps.gridCellProps) ||
      this.state.value !== nextState.value
    )
  }

  render() {
    const {
      numberFormatProps,
      gridCellProps: { name = null }
    } = this.props

    const optionalProps = name !== null ? { name } : {}

    return <NumberFormat {...numberFormatProps} {...optionalProps} />
  }
}
