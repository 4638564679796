Ext.define('po.Toolbar', {
  extend: 'Ext.toolbar.Toolbar',
  xtype: 'mainToolbar',
  overflowHandler: 'scroller',
  cls: 'po-toolbar',
  items: [
    {
      xtype: 'poComboWithClear',
      displayField: 'urunAciklamasi',
      valueField: 'urunKodu',
      multiSelect: true,
      bind: {
        store: '{productCodeStore}',
        value: '{selectedProductCode}',
        disabled: '{isProductCodeDisabled}',
        emptyText: '{i18n.toolbar_PcCombo_emptyText}'
      },
      margin: '0 10 0 0'
    },
    {
      xtype: 'poComboWithClear',
      displayField: 'DepoAdi',
      valueField: 'DepoKodu',
      // multiSelect: true,
      queryMode: 'local',
      editable: false,
      bind: {
        store: '{dcStore}',
        value: '{selectedDC}',
        emptyText: '{i18n.toolbar_Dccombo_emptyText}'
      },
      margin: '0 10 0 0'
    },
    {
      emptyText: 'Kademe 1',
      xtype: 'poComboWithClear',
      displayField: 'K1N',
      valueField: 'K1K',
      multiSelect: true,
      bind: {
        store: '{k1kStore}',
        value: '{selectedK1K}',
        disabled: '{isK1KDisabled}',
        emptyText: '{i18n.toolbar_K1NCombo_emptyText}'
      },
      margin: '0 10 0 0'
    },
    {
      emptyText: 'Kademe 2',
      xtype: 'poComboWithClear',
      displayField: 'K2N',
      valueField: 'K2K',
      multiSelect: true,
      bind: {
        store: '{k2kStore}',
        value: '{selectedK2K}',
        disabled: '{isK2KDisabled}',
        emptyText: '{i18n.toolbar_K2NCombo_emptyText}'
      },
      margin: '0 10 0 0'
    },
    {
      emptyText: 'Kademe 3',
      xtype: 'poComboWithClear',
      displayField: 'K3N',
      valueField: 'K3K',
      multiSelect: true,
      bind: {
        store: '{k3kStore}',
        value: '{selectedK3K}',
        disabled: '{isK3KDisabled}',
        emptyText: '{i18n.toolbar_K3NCombo_emptyText}'
      },
      margin: '0 10 0 0'
    },
    {
      xtype: 'poRunButton',
      handler: 'onRunReport',
      style: {
        'background-color': '#2673ab'
      },
      bind: {
        text: '{i18n.toolbar_RunButtonText}'
      }
    },
    '->',
    {
      bind: {
        disabled: '{!buttonsStatus.massUpdateButtonEnable}',
        tooltip: '{i18n.Toolbar_MassUpdateButton_Tooltip}'
      },
      iconCls: 'x-fa fa-pencil',
      ui: 'btn-success',
      handler: 'onMassUpdateButtonClick'
    },
    {
      style: {
        'background-color': '#5fa2dd'
      },
      bind: {
        hidden: '{panelManagerHidden}',
        text: '{i18n.toolbar_PanelManagerButton_Text}'
      },
      menu: [
        {
          xtype: 'checkbox',
          cls: 'panel-manager',
          bind: {
            value: '{panelVisibility.productDetailVisible}',
            hidden: '{!panelManagerProductCheckBoxVisible}',
            boxLabel: '{i18n.toolbar_PanelManager_ProductDetailCheckBox_Text}'
          },
          listeners: {
            change: function () {
              var me = this
              setTimeout(() => {
                me.up('pomainview').saveState()
              }, 100)
            }
          }
        },
        {
          xtype: 'checkbox',
          cls: 'panel-manager',
          bind: {
            value: '{panelVisibility.inventoryProfileVisible}',
            hidden: '{!panelManagerDetailCheckBoxVisible}',
            boxLabel: '{i18n.toolbar_PanelManager_DetailCheckBox_Text}'
          },
          listeners: {
            change: function () {
              var me = this,
                view = me.up('pomainview'),
                viewModel = view.getViewModel(),
                inventoryStore = viewModel.getStore('inventoryStore'),
                groupByStore = viewModel.getStore('groupbyStore'),
                groupStore = viewModel.getStore('groupStore')

              setTimeout(() => {
                me.up('pomainview').saveState()
              }, 100)
              if (me.value == true) {
                inventoryStore.load()
                groupByStore.load()
                groupStore.load()
              }
            }
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.toolbar_btnexportText}'
      },
      iconCls: 'x-fa fa-download',
      ui: 'btn-success',
      handler: 'exportTo'
    },
    {
      xtype: 'button',
      style: {
        'background-color': 'red'
      },
      handler: 'onCancelChanges',
      margin: '0 10 0 0',
      bind: {
        text: '{i18n.toolbar_btnIptalText}',
        disabled: '{!buttonsStatus.cancelButtonEnable}'
      }
    },
    {
      xtype: 'button',
      style: {
        'background-color': 'green'
      },
      handler: 'onSaveChanges',
      margin: '0 10 0 0',
      bind: {
        text: '{i18n.toolbar_btnKaydet_Text}',
        disabled: '{!buttonsStatus.saveButtonEnable}'
      }
    },
    {
      xtype: 'button',
      style: {
        'background-color': '#2673ab'
      },
      handler: 'onSendToSAP',
      margin: '0 10 0 0',
      bind: {
        text: '{i18n.toolbar_btnSAPaGonderText}',
        disabled: '{!buttonsStatus.sapButtonEnable}'
      }
    }
  ]
})
