import { useMemo } from 'react'
import { isNil } from 'lodash'
import { ExcelStyle } from 'ag-grid-enterprise'
import { formatToExcelConvention, getExportableColumnDefs } from '../../helpers'
import { numberTypes } from '../../constants'
import { EmptyObject, SlvyColGroupDef } from '../../types'
import { ExcelExportStylesProps, ExcelExportStylesReturnType } from './useExcelExportStyles.types'

const stringType: ExcelStyle = { id: 'stringType', dataType: 'String' }

function useExcelExportStyles({
  fieldConfigs,
  formattedFields,
  exportable,
  gridRef
}: ExcelExportStylesProps) {
  const excelStyles = useMemo((): ExcelExportStylesReturnType | EmptyObject => {
    if (!exportable) {
      return {}
    }

    const styles: ExcelStyle[] = [stringType]

    const columnDefs = gridRef?.current?.api?.getColumnDefs?.()
    // TODO:
    // Note: 'ag-Grid-AutoColumn' works as expected
    // if there is a format problem occurs check here
    getExportableColumnDefs(columnDefs as SlvyColGroupDef[]).forEach((exportableColumnDef) => {
      if ('field' in exportableColumnDef) {
        const formatString = formattedFields.find(
          ({ columnName }) => columnName === exportableColumnDef.field
        )?.formatString

        if (formatString) {
          const dataType = fieldConfigs.find(
            ({ fieldName }) => fieldName === exportableColumnDef.field
          )?.dataType

          const isNumberType = numberTypes.some((numberType) => numberType === dataType)

          if (isNumberType) {
            const excelFormat = formatToExcelConvention(formatString)

            if (!isNil(excelFormat)) {
              styles.push({
                id: exportableColumnDef.customInfo.columnId!,
                numberFormat: { format: `${excelFormat}` }
              })
            }
          }
        }
      }
    })

    return {
      excelStyles: styles
    }
  }, [exportable, fieldConfigs, formattedFields, gridRef])

  return { excelStyles }
}

export default useExcelExportStyles
