import { Component } from 'react'
import _ from 'lodash'
import { lruMemoize, createSelectorCreator } from 'reselect'
import { connect } from 'react-redux'

export default (
  modelActions = {},
  mapStateToProps = (state, ownProps) => ({}),
  mapDispatchToProps = (dispatch) => ({ dispatch })
) => {
  const keys = _.keys(modelActions)

  const createDeepEqualSelector = createSelectorCreator(lruMemoize, (a, b) =>
    _.isEqual(a.fetchKey, b.fetchKey)
  )

  const makeMapStateToProps = () => {
    const selectorStates = _.transform(
      modelActions,
      (result, action, key) => {
        result[key] = createDeepEqualSelector([action], (a) => a)
      },
      {}
    )
    const combinedMapStateToProps = (state, ownProps) => {
      const m = _.isFunction(mapStateToProps) ? mapStateToProps(state, ownProps) : mapStateToProps
      const selectorStateResults = _.transform(
        selectorStates,
        (result, s, key) => {
          if (!result[key]) result[key] = s(state, ownProps)
        },
        { ...m }
      )
      return selectorStateResults
    }
    return combinedMapStateToProps
  }

  return (ChildComponent) => {
    class AsyncConnect extends Component {
      UNSAFE_componentWillMount() {
        _.each(keys, (key) => {
          if (this.props[key].needFetch) {
            this.props.dispatch(this.props[key].fetch)
          }
        })
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        _.each(keys, (key) => {
          if (nextProps[key].needFetch) {
            nextProps.dispatch(nextProps[key].fetch)
          }
        })
      }

      render() {
        const allSuccess = _.reduce(
          keys,
          (result, key, k) => {
            const {
              [key]: { isSuccess }
            } = this.props
            return isSuccess ? result : false
          },
          true
        )

        if (!allSuccess) return null
        return <ChildComponent {...this.props} />
      }
    }

    return connect(makeMapStateToProps, mapDispatchToProps)(AsyncConnect)
  }
}
