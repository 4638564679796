import { useState, useRef } from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import cx from 'classnames'
import numeral from 'numeral'
import { isEmpty, isNil, omit } from 'lodash'
import { slvyToast } from '@/components'
import { useReduxState } from '../../hooks'
import {
  getAvailableTransportationModes,
  getDemandTransportationModes
} from '../../store/api/endpoints'
import { loadTreeTableSlice, createLoadModalSlice } from '../../store/slices'
import { useLazyGetDemandDetailByIdQuery } from '../../store/api/apiSlice'
import IconButton from '../IconButton'
import Tooltip from '../Tooltip'
import RemoveModal from '../LoadTreeTable/RemoveLoadModal'
import RemoveLoadStopModal from '../LoadTreeTable/RemoveLoadStopModal'
import ChangeTransportationModal from '../LoadTreeTable/ChangeTransportationModal'
import CreateLoadModal from '../CreateLoadModal'
import { LoadCardProps } from './LoadCard.types'
import styles from './LoadCard.module.scss'

function ActionPopup({
  loadIdColor,
  loadId,
  numPallet,
  volume,
  weight,
  onRemoveLoadStop,
  onCreateLoad
}: any) {
  return (
    <div className={styles.actionPopup}>
      <header className={styles.actionPopupHeader}>
        <span>{loadId}</span>
        <IconButton onClick={() => ({})}>
          <i className="fa fa-fw fa slvy-ui-icon-times-extra-lt" />
        </IconButton>
      </header>
      <div className={styles.actionPopupLoadInfo}>
        <div className={styles.actionPopupLoadInfoLeft}>
          <div className={styles.actionPopupLoadInfoItem}>{numeral(volume).format('0,0.00')}m3</div>
          <div className={styles.actionPopupLoadInfoItem}>{numeral(weight).format('0,0.00')}kg</div>
        </div>
        <div className={styles.actionPopupLoadInfoRight}>{numPallet}P</div>
      </div>
      <div className={styles.actionPopupControls}>
        <div className={styles.actionPopupLoadStops}>
          <i className="fa fa-fw fa slvy-ui-icon-circle-fill fs-6" style={{ color: loadIdColor }} />{' '}
          {loadId}
        </div>
        <div className={styles.actionPopupBtnGroup}>
          <Button
            bsPrefix="action-btn"
            className={styles.actionPopupBtn}
            onClick={onRemoveLoadStop}
          >
            <i className="slvy-ui-icon-trash-can fs-6 pe-1 fw-normal" /> Remove
          </Button>
          <Button bsPrefix="action-btn" className={styles.actionPopupBtn} onClick={onCreateLoad}>
            <i className="slvy-ui-icon-truck-outline fs-6 pe-1 fw-normal" />
            Create New Load
          </Button>
        </div>
      </div>
    </div>
  )
}

export default function LoadCard({
  data,
  id,
  onClose,
  onOpenDemandCard,
  onMergeLoad,
  openLoadCardIds
}: LoadCardProps) {
  const [selectedLoadStops, setSelectedLoadStops] = useState({})
  const [isAllLoadStopsSelected, setIsAllLoadStopsSelected] = useState(false)
  const [isPalletView, setIsPalletView] = useState(false)
  const [isTransportationModeView, setIsTransportationModeView] = useState(false)
  const [selectTransportationMode, setSelectedTransportationMode] = useState('')
  const [showMergeLoadPopup, setShowMergeLoadPopup] = useState(false)
  const [loadTreeTable, loadTreeTableDispatch] = useReduxState(
    loadTreeTableSlice.selectSlice,
    loadTreeTableSlice.actions
  )
  const [createLoadModal, createLoadModalDispatch] = useReduxState(
    createLoadModalSlice.selectSlice,
    createLoadModalSlice.actions
  )
  const [triggerGetAvailableTransportationModes, getAvailableTransportationModesResponse] =
    getAvailableTransportationModes.useMutation()
  const [triggerGetDemandTransportationModes, getDemandTransportationModesResponse] =
    getDemandTransportationModes.useLazyQuery()
  const loadDetailsBackground = loadTreeTable.rowColors[data.Id]
  const loadControlsRef = useRef(null)

  function handleCloseLoadCard(id) {
    onClose(id)
  }

  function handleOpenDemandCard(id) {
    onOpenDemandCard(id)
  }

  function handleSetSelectedLoadStops(loadStop: any, index: number) {
    setSelectedLoadStops((prevState) =>
      isEmpty(prevState[loadStop.Id])
        ? { ...prevState, [loadStop.Id]: { ...loadStop, index } }
        : omit(prevState, loadStop.Id)
    )
  }

  function handleSelectAllLoadStops() {
    setIsAllLoadStopsSelected((prevState) => !prevState)
    const nextSelectedLoadStops = data?.LoadStops.reduce(
      (accumulator, current) =>
        isAllLoadStopsSelected
          ? Object.assign(accumulator, { [current.Id]: current })
          : accumulator,
      {}
    )
    setSelectedLoadStops(nextSelectedLoadStops)
  }

  function handleTogglePalletView() {
    setIsPalletView((isPalletView) => !isPalletView)
  }

  function handleRemoveLoad() {
    loadTreeTableDispatch.setIsRemoveLoadStopModalOpen(true)
  }

  function handleToggleTransportationModeView() {
    setIsTransportationModeView((isTransportationModeView) => !isTransportationModeView)
    triggerGetAvailableTransportationModes({ id: Number(data?.Id) })
  }

  function handleMergeAnotherLoad() {
    onMergeLoad(id)
    setShowMergeLoadPopup(true)
  }

  function handleChangeTransportationMode(transportationMode) {
    setSelectedTransportationMode(transportationMode)
    loadTreeTableDispatch.setIsChangeTransportationModalOpen(true)
  }

  function handleCreateLoad() {
    const facilityKey = Object.values(selectedLoadStops).map(({ Id }) => Id)
    triggerGetDemandTransportationModes({ facilityKey, orderNumber: null, query: '' })
    createLoadModalDispatch.show()
  }

  function handleHideCreateLoadModal() {
    createLoadModalDispatch.hide()
  }

  function handleCloseMergeLoadPopup() {
    setShowMergeLoadPopup(false)
  }

  function renderContent() {
    if (isPalletView) {
      return (
        <div className="p-1">
          {Array(data?.LoadedPallet).fill(
            <span
              className="d-inline-block "
              style={{
                backgroundColor: loadTreeTable.rowColors[data?.Id],
                width: 18,
                height: 18,
                marginRight: 2
              }}
            />
          )}
        </div>
      )
    }

    if (isTransportationModeView) {
      return (
        <ul className={styles.availableTransportationModes}>
          {getAvailableTransportationModesResponse.data?.result?.map((transportationMode) => (
            <li
              className={styles.availableTransportationModeItem}
              onClick={() => handleChangeTransportationMode(transportationMode.Name)}
            >
              <div className={styles.availableTransportationModeItemDesc}>
                <span>{transportationMode.Name}</span>
                <span>{transportationMode.SubTitle}</span>
              </div>
              <div className={styles.availableTransportationModeItemDetails}>
                <span>{numeral(transportationMode.Utilization).format('0%')}% Util</span>
                <span>{numeral(transportationMode.Cost).format('0,0.00')} Cost</span>
              </div>
            </li>
          ))}
        </ul>
      )
    }

    return data?.LoadStops?.map((loadStop, index, array) => {
      const isSelected = !isEmpty(selectedLoadStops[loadStop.Id])
      return (
        <div key={loadStop.Id} className={cx({ 'border-bottom': index < array.length - 1 }, 'p-1')}>
          <div className="d-flex justify-content-between">
            <span className="d-flex">
              <IconButton
                className="d-flex pt-1"
                onClick={() => handleSetSelectedLoadStops(loadStop, index)}
              >
                <i
                  className={cx(
                    isSelected
                      ? 'fa fa-fw fa slvy-ui-icon-check-circle-regular'
                      : 'fa fa-fw fa slvy-ui-icon-circle-fill',
                    'd-inline-block me-1 fs-5',
                    isSelected ? 'text-dark' : ''
                  )}
                  style={{
                    color:
                      loadStop.Items.length > 1
                        ? '#d9d9d9'
                        : loadTreeTable.rowColors[loadStop.Items.at(0).LoadId] ?? '#d9d9d9',
                    width: 18,
                    height: 18
                  }}
                />
              </IconButton>
              <span className="align-self-start">
                <Tooltip
                  text={`Name: ${loadStop.Items.at(0).Title}
                Duatedate: ${loadStop.Items.at(0).Description}
                Priority: ${loadStop.Items.at(0).Priority}
                Cases: ${1}
                Volume: ${loadStop.Items.at(0).Volume}
                Weight: ${numeral(loadStop.Items.at(0).Weight).format('0,0.00')}
                Lademeter: ${loadStop.Items.at(0).Lademeter}`}
                >
                  <div className="py-1">{loadStop.Title}</div>
                </Tooltip>
                <div className="pb-1">{loadStop.SubTitle}</div>
              </span>
            </span>
            <IconButton className="d-flex pt-1" onClick={() => handleOpenDemandCard(loadStop.Id)}>
              <i className="slvy-ui-icon-export-box fs-5" />
            </IconButton>
          </div>
          <div className="lh-1">
            {loadStop.Items.map(({ LoadId, NumPallet }) =>
              Array(NumPallet).fill(
                <span
                  className="d-inline-block "
                  style={{
                    backgroundColor: loadTreeTable.rowColors[LoadId],
                    width: 18,
                    height: 18,
                    marginRight: 2
                  }}
                />
              )
            )}
          </div>
        </div>
      )
    })
  }

  function isDarkColor(color: string) {
    const rgbValues = color?.match(/\d+/g)
    const [r = 0, g = 0, b = 0] = rgbValues?.map(Number) ?? []
    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness < 128
  }

  return (
    <div className={cx(styles.loadCard)}>
      <div className={cx(styles.loadCardHeader)}>
        <span>{data.Title}</span>
        <IconButton onClick={() => handleCloseLoadCard(id)}>
          <i className="fa fa-fw fa fa-close" />
        </IconButton>
      </div>
      <div style={{ backgroundColor: loadDetailsBackground }}>
        <div className={styles.loadCardLoadDetails}>
          <div
            className={styles.loadDetailsLeft}
            style={{ color: !isDarkColor(loadDetailsBackground) ? 'white' : 'black' }}
          >
            <span>{data?.SubTitle1}</span>
            <span>{data?.SubTitle2}</span>
            <span>{numeral(data?.LoadedWeight).format('0,0.00')} kg</span>
            <span>{numeral(data?.Cost).format('0,0.00')} ₺</span>
          </div>
          <div className={cx('')}>
            <span
              className={cx(
                'w-100 h-50 d-flex justify-content-center align-items-center',
                styles.maxPallet
              )}
              style={{ alignSelf: 'stretch' }}
            >
              {data?.MaxPallet}P
            </span>
            <div className={cx('w-100 d-flex h-50')}>
              <span
                className={cx(
                  'w-100 d-flex align-items-center justify-content-center bg-black text-white fs-6'
                )}
              >
                {data?.LoadedPallet}
              </span>
              <span
                className={cx('w-100 d-flex justify-content-center align-items-center fs-6')}
                style={{ color: !isDarkColor(loadDetailsBackground) ? 'white' : 'black' }}
              >
                {data?.MaxPallet - data?.LoadedPallet}
              </span>
            </div>
          </div>
        </div>
        <section
          ref={loadControlsRef}
          className={cx(
            'd-flex justify-content-between align-items-center p-1 px-2 fs-6',
            styles.loadControls
          )}
          style={{ color: !isDarkColor(loadDetailsBackground) ? 'white' : 'black' }}
        >
          <IconButton tooltipText="Select All" onClick={handleSelectAllLoadStops}>
            <i className="slvy-ui-icon-select-all fs-4" />
          </IconButton>
          <IconButton tooltipText="Info" onClick={() => ({})}>
            <i className="slvy-ui-icon-info" />
          </IconButton>
          {openLoadCardIds.length > 1 ? (
            <OverlayTrigger
              container={loadControlsRef}
              overlay={
                <Popover className={styles.mergeLoadsPopup}>
                  <Popover.Body>
                    <IconButton
                      className={styles.closeMergeLoadPopup}
                      onClick={handleCloseMergeLoadPopup}
                    >
                      <i className="fa fa-fw fa fa-close" />
                    </IconButton>

                    {openLoadCardIds
                      .filter((loadCardId) => loadCardId !== id)
                      .map((loadCard) => (
                        <IconButton onClick={() => ({})}>
                          <i
                            className="fa fa-fw fa slvy-ui-icon-circle-fill"
                            style={{ color: loadTreeTable.rowColors[loadCard] }}
                          />
                          {loadCard}
                        </IconButton>
                      ))}
                  </Popover.Body>
                </Popover>
              }
              placement="bottom"
              show={showMergeLoadPopup}
              trigger="click"
            >
              <IconButton tooltipText="Merge Another Load" onClick={handleMergeAnotherLoad}>
                <i className="slvy-ui-icon-merge" />
              </IconButton>
            </OverlayTrigger>
          ) : (
            <IconButton tooltipText="Merge Another Load" onClick={handleMergeAnotherLoad}>
              <i className="slvy-ui-icon-merge" />
            </IconButton>
          )}
          <IconButton
            tooltipText="Change Transporation Mode"
            onClick={handleToggleTransportationModeView}
          >
            <i
              className={
                isTransportationModeView
                  ? 'fa fa-fw fa slvy-ui-icon-times-extra-lt'
                  : 'slvy-ui-icon-scenario-change-extra-lt'
              }
            />
          </IconButton>
          <IconButton tooltipText="Remove Load" onClick={handleRemoveLoad}>
            <i className="slvy-ui-icon-scenario-delete-extra-lt" />
          </IconButton>
          <IconButton
            tooltipText={isPalletView ? 'Stop Sequence View' : 'Pallet View'}
            onClick={handleTogglePalletView}
          >
            <i className="slvy-ui-icon-grid" />
          </IconButton>
        </section>
      </div>
      <section className={cx(styles.loadItems)}>{renderContent()}</section>
      <RemoveModal selectedLoadRow={data?.Id} endpoint={'load'} />
      <ChangeTransportationModal
        availableTransportationModeName={selectTransportationMode}
        changeTransportationLoadName={Number(data?.Id)}
      />
      <RemoveLoadStopModal
        selectedLoadStopRow={{
          index: Object.values(selectedLoadStops).map((loadStop) => loadStop.index),
          original: { ID: id }
        }}
      />
      <CreateLoadModal
        loadStops={selectedLoadStops}
        transportationModes={getDemandTransportationModesResponse.data?.data.map(
          ({ Name, SubTitle }) => `${SubTitle}-${Name}`
        )}
        originLocations={['3060']}
        show={createLoadModal.isVisible}
        onHide={handleHideCreateLoadModal}
      />
      {Object.values(selectedLoadStops).filter((loadStop) => !isNil(loadStop)).length ? (
        <ActionPopup
          {...{
            onCreateLoad: handleCreateLoad,
            onRemoveLoadStop: handleRemoveLoad,
            loadIdColor: loadTreeTable.rowColors[id],
            loadId: id,
            ...Object.values(selectedLoadStops).reduce(
              (accumulator, current) =>
                Object.assign(accumulator, {
                  numPallet: accumulator.numPallet + current.Items.at(0).NumPallet,
                  volume: accumulator.volume + current.Items.at(0).Volume,
                  weight: accumulator.weight + current.Items.at(0).Weight
                }),
              { numPallet: 0, volume: 0, weight: 0 }
            )
          }}
        />
      ) : null}
    </div>
  )
}
