Ext.define('MDM.Btl.dialog.BtlRulesInfo', {
  extend: 'Ext.window.Window',
  resize: false,
  ui: 'window-inverse',
  iconCls: 'fa fa-pencil-square-o',
  layout: 'border',
  bodyStyle: {
    backgroud: '#fff'
  },
  modal: true,
  defaultListenerScope: true,
  height: 800,
  width: 508,
  record: null,
  bodyPadding: 1,
  onStoreBeforeLoad: function (store) {
    var record = this.record,
      data = record.data,
      dataJson = Ext.encode(data)
    store.getProxy().setExtraParam('dataJson', dataJson)
  },
  onShowHide: function () {
    var dataPanel = this.down('#dataPanel'),
      height = dataPanel.isVisible() ? 400 : 750
    this.setHeight(height)
    dataPanel.setVisible(!dataPanel.isVisible())
  },
  initComponent: function () {
    var me = this,
      BTLType = me.record.get('BTLType'),
      BTLTypeTitles = {
        P: 'Production',
        D: 'Demand',
        I: 'Inventory',
        X: 'Cross Supply',
        R: 'Razor Draw',
        N: 'Non-revenue'
      }
    this.title = BTLTypeTitles[BTLType] + ' - ' + me.record.get('Title')
    me.buildStore()
    me.items = [
      {
        xtype: 'btlRulesInfoTemplate',
        data: this.store.data,
        store: this.store
      }
    ]
    me.callParent()
  },
  buildStore: function () {
    var me = this
    this.store = Ext.create('Ext.data.Store', {
      model: 'MDM.Btl.model.EditModel',
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/BTLRules_Form_Read',
        extraParams: {
          id: me.record.data.RuleID
        }
      },
      autoLoad: false
    })
  }
})
