import { useMemo } from 'react'
import { isGraphColumn } from '../../helpers'
import { EmptyObject, SlvyColDef, SlvyMasterColDef, Theme } from '../../types'
import { CUSTOM_FIELDS } from '../../constants'

function useGetRowHeight(columnDefs: SlvyMasterColDef[], theme: Theme) {
  const isThereGraphColumn = useMemo(() => {
    const filteredColumnDefs = columnDefs.filter((column): column is SlvyColDef => {
      const isSpecialColumn = CUSTOM_FIELDS.some((customField) => customField === column.field)
      return !isSpecialColumn
    })
    return filteredColumnDefs.some(isGraphColumn)
  }, [columnDefs])

  const isPieColumn = columnDefs.some(
    (column) =>
      column.customInfo?.columnType === 'highchart' &&
      column.customInfo?.highchartOptions?.type === 'sparklinepie'
  )

  const rowHeight = !isPieColumn && theme === 'balham' ? 35 : 45

  const getRowHeight = useMemo((): EmptyObject | { getRowHeight: () => typeof rowHeight } => {
    if (!isThereGraphColumn) {
      return {}
    }

    return { getRowHeight: () => rowHeight }
  }, [isThereGraphColumn, rowHeight])

  return { getRowHeight, hasPieColumn: isPieColumn, hasGraphColumn: isThereGraphColumn }
}

export default useGetRowHeight
