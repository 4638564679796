import React, { Component } from 'react'
import numeral from 'numeral'
import _ from 'lodash'
import { SidebarCell } from './sidebar-cell'

export class SidebarSummary extends Component {
  // @TODO: No need to check should component update because parent cmp is checking same [Options]

  getTotalByField($listName, $currentFieldName) {
    const $list = this.props[$listName] || []
    let total = 0
    $list.forEach((item) => (total += item[$currentFieldName]))
    return total
  }

  getEnabledSegments() {
    const { Options = [] } = this.props
    let total = 0
    Options.forEach((option) => {
      if (_.filter(option.SegmentOptions, ['Enabled', true]).length > 0) {
        total++
      }
    })
    return total
  }

  render() {
    const { removeCurrencySymbol = (a, b) => {}, current = '', Options = [] } = this.props

    const $TotalRevenue = numeral(this.getTotalByField('Options', 'TotalRevenue')).format(current)
    const $TotalSales = removeCurrencySymbol(this.getTotalByField('Options', 'TotalSales'), current)
    const $TotalCost = numeral(this.getTotalByField('Options', 'TotalCost')).format(current)
    const $NumberOfActiveStores = removeCurrencySymbol(
      this.getTotalByField('Options', 'NumberOfActiveStores'),
      current
    )
    const $EnabledSegments = this.getEnabledSegments()
    const $OptionsLength = Options.length

    return (
      <div className="currentSummary">
        <div className="cell-row -spaceBetween2">
          <SidebarCell text="Forecasted Revenue" value={$TotalRevenue} />
          <SidebarCell text="Forecasted Cost" value={$TotalCost} />
        </div>
        <div className="cell-row -spaceBetween3">
          <SidebarCell
            iconTpl={<span className="slvy-ui-icon-box" />}
            text="Forecasted Quantity"
            value={$TotalSales}
          />
          <SidebarCell
            iconTpl={<span className="slvy-ui-icon-shopping-bag-outline" />}
            text="Number Of Stores"
            value={$NumberOfActiveStores}
          />
          <SidebarCell
            text="Assigned Options / Options"
            value={`${$EnabledSegments} / ${$OptionsLength}`}
          />
        </div>
      </div>
    )
  }
}
