import { useCallback, useEffect, Dispatch, SetStateAction } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { compareOppositeVariables } from '../../../../helpers'
import { RegisterMethod } from '../../registerEventsMethods.types'
import { SetEditableStateParams } from './useRegisterMethodSetEditableState.type'

function useRegisterMethodSetEditableState(
  registerMethod: RegisterMethod,
  setGridEditableByState: Dispatch<SetStateAction<boolean>>
) {
  const onSetEditableState = useCallback(
    ({ notEditable, editable }: SetEditableStateParams) => {
      const isEditable = compareOppositeVariables({ enabled: editable, notEnabled: notEditable })
      if (isEditable !== null) {
        setGridEditableByState(isEditable)
      }
    },
    [setGridEditableByState]
  )

  useEffect(() => {
    registerMethod({
      args: [
        { name: 'editable', type: PluginTypes.boolean },
        { name: 'notEditable', type: PluginTypes.boolean }
      ],
      key: 'setEditableState',
      fn: onSetEditableState
    })
  }, [onSetEditableState, registerMethod])
}

export default useRegisterMethodSetEditableState
