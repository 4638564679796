import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import _ from 'lodash'
import AssortmentModal from '../Modal'
import { slvyToast, SlvyTable } from '@/components'

export default class ActiveStores extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          id: 'isActive',
          header: this.getActiveHeader.bind(this),
          cell: this.getActiveCell.bind(this),
          sortable: false
        },
        {
          header: 'Name',
          accessorKey: 'Name'
        },
        {
          header: 'Country',
          accessorKey: 'Country'
        },
        {
          header: 'Capacity',
          cell: ({ row }) => {
            const {
              original: { ConsumedOptionCapacity = 0, TotalOptionCapacity = 0 }
            } = row
            return (
              <span>
                {`${Math.round(ConsumedOptionCapacity)}/${Math.round(TotalOptionCapacity)}`}
              </span>
            )
          }
        },
        {
          header: 'Capacity Percentage',
          cell: ({ row }) => {
            const {
              original: { ConsumedOptionCapacity = 0, TotalOptionCapacity = 0 }
            } = row
            let result = '-'
            let hasResult = false
            let calculatedResult = 0
            if (TotalOptionCapacity > 0) {
              hasResult = true
              calculatedResult = Math.round((ConsumedOptionCapacity / TotalOptionCapacity) * 100)
              result = `${calculatedResult}%`
            }
            return (
              <div>
                {result}
                <div
                  className="react-table-cell-progress-holder"
                  style={hasResult && calculatedResult ? {} : { background: 'none' }}
                >
                  <span
                    className="react-table-cell-progress"
                    style={{ width: hasResult ? result : 0 }}
                  />
                </div>
              </div>
            )
          }
        },
        {
          header: 'Store Performance',
          cell: ({ row }) => {
            const {
              original: { Performance = '-' }
            } = row
            return <span>{Performance}</span>
          }
        }
      ],
      isSelectAllChecked: false,
      Stores: [],
      show: props.show
    }
  }

  componentDidMount() {
    const { option = {}, stores = [], segmentIndex = 1, show } = this.props
    if (show) {
      this.convertDataToTable(option, stores, segmentIndex)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        show: nextProps.show
      })
      const { option = {}, stores = [], segmentIndex = 1 } = nextProps
      this.convertDataToTable(option, stores, segmentIndex)
    }
  }

  getActiveHeader({ row }) {
    const { isSelectAllChecked = false } = this.state
    const { option: { Editable: isFutureOption = false } = {} } = this.props
    return (
      <div>
        <span>Active</span>
        <hr className="settings-modal-from-hr" />
        <br />
        <br />
        <input
          checked={isSelectAllChecked}
          disabled={!isFutureOption}
          type="checkbox"
          onChange={(event) => this.handleBulkInputChange(row, 'IsActive', event)}
        />
      </div>
    )
  }

  handleActiveChange(row) {
    const { index: rowIndex } = row

    const {
      state: { Stores = [] }
    } = this

    const _Stores = Stores.map((store, index) =>
      index === rowIndex ? { ...store, IsActive: !store.IsActive } : store
    )

    this.setState({
      Stores: _Stores,
      isSelectAllChecked: this.getIsSelectAllChecked(_Stores)
    })
  }

  getActiveCell({ row }) {
    const { index: rowIndex } = row
    const { Stores = [] } = this.state
    const { option: { Editable: isFutureOption = false } = {} } = this.props

    return (
      <input
        checked={Stores[rowIndex].IsActive}
        disabled={!isFutureOption}
        type="checkbox"
        onChange={() => this.handleActiveChange(row)}
      />
    )
  }

  getIsSelectAllChecked(Stores) {
    return Stores.length === [...Stores].filter(({ IsActive }) => IsActive).length
  }

  handleBulkInputChange(row, field, event) {
    const { target: { checked = true } = {} } = event

    const {
      state: { Stores = [] }
    } = this

    const _Stores = [...Stores].map((element) => ({ ...element, [field]: checked }))

    this.setState({
      Stores: _Stores,
      isSelectAllChecked: this.getIsSelectAllChecked(_Stores)
    })
  }

  convertDataToTable(option, stores, segmentIndex) {
    const segmentOptions = option.SegmentOptions
    const currentSegmentOption = segmentOptions[segmentIndex]
    const { ExcludedStoreIds = [], StorePerformances = [] } = currentSegmentOption

    let currentStores = stores.filter(({ SegmentId = null }) => SegmentId === Number(segmentIndex))
    currentStores = currentStores
      .map((store) => {
        store = { ...store, IsActive: true }
        if (
          _.isArray(ExcludedStoreIds) &&
          ExcludedStoreIds.filter((id) => id === store.Id).length
        ) {
          store = { ...store, IsActive: false }
        }
        return store
      })
      .map((store) => {
        const { Id = null } = store
        if (_.isArray(StorePerformances)) {
          const currentItem = [...StorePerformances].filter(({ StoreId = null }) => StoreId === Id)
          if (currentItem.length) {
            store = {
              ...store,
              ...currentItem[0]
            }
          }
        }
        return store
      })

    this.setState({
      Stores: currentStores,
      isSelectAllChecked: this.getIsSelectAllChecked(currentStores)
    })
  }

  handleOnSave($isDisabled) {
    if ($isDisabled) {
      slvyToast.error({ message: 'This is not future option!', title: 'Update Disabled!' })
      return
    }

    const {
      props: { handleOnSave = () => {}, segmentIndex, option = {} },
      state: { Stores = [] }
    } = this
    const clonedOption = _.cloneDeep(option)
    const allStores = [...Stores]
    const excludedStores = allStores.filter((store) => !store.IsActive)

    clonedOption.SegmentOptions[segmentIndex].ExcludedStoreIds = excludedStores.map(
      (store) => store.Id
    )

    handleOnSave(clonedOption)
  }

  render() {
    const {
      props: { handleCloseModal = () => {}, option: { Editable: isFutureOption = false } = {} },
      state: { show = false, Stores = [], columns = [] }
    } = this

    const isUpdateDisabled = !isFutureOption

    return (
      <AssortmentModal
        isShowFooter
        footerTemplate={
          <Button
            size="sm"
            variant={isUpdateDisabled ? 'outline-dark' : 'success'}
            onClick={this.handleOnSave.bind(this, isUpdateDisabled)}
          >
            <span className="slvy-ui-icon-check-circle-regular" />
          </Button>
        }
        handleCloseModal={handleCloseModal.bind(this)}
        show={show}
        size="slvy-modal-xl assortmentModal"
        title="Active Stores"
      >
        <div className="row react-table-element-wrapper">
          {Stores.length > 0 ? (
            <div className="col-sm-12">
              <SlvyTable
                suppressColumnMenu
                className="assortment-react-table-element"
                columns={columns}
                data={Stores}
                getBodyClassName="ap-active-stores-body"
                getCellClassName="ap-active-stores-cell"
                getHeadClassName="ap-active-stores-head"
                getHeaderClassName="ap-active-stores-header"
              />
            </div>
          ) : null}
        </div>
      </AssortmentModal>
    )
  }
}
