import { validateRule } from '../rule'

describe('AgGrid:helpers:validateRule', () => {
  it('should compare values whether equal', () => {
    const isValid = validateRule({ conditionValue: '1', value: 1, operator: 'equal' })
    expect(isValid).toBe(true)
    const shouldValid = validateRule({ conditionValue: '3', value: 5, operator: 'equal' })
    expect(shouldValid).toBe(false)
  })
  it('should compare values whether not equal', () => {
    const isValid = validateRule({ conditionValue: '1', value: 0, operator: 'not equal' })
    expect(isValid).toBe(true)
    const shouldValid = validateRule({ conditionValue: '3', value: 3, operator: 'not equal' })
    expect(shouldValid).toBe(false)
  })
  it('should compare values whether larger', () => {
    const isValid = validateRule({ conditionValue: '3', value: 5, operator: 'larger' })
    expect(isValid).toBe(true)
    const shouldValid = validateRule({ conditionValue: '5', value: 3, operator: 'larger' })
    expect(shouldValid).toBe(false)
  })
  it('should compare values whether smaller', () => {
    const isValid = validateRule({ conditionValue: '5', value: 3, operator: 'smaller' })
    expect(isValid).toBe(true)
    const shouldValid = validateRule({ conditionValue: '3', value: 5, operator: 'smaller' })
    expect(shouldValid).toBe(false)
  })
  it('should compare values return false when given value or conditionValue parameter is nil', () => {
    const isValid = validateRule({ conditionValue: '', value: null, operator: 'equal' })
    expect(isValid).toBe(false)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const shouldValid = validateRule({ conditionValue: undefined, value: 1, operator: 'equal' })
    expect(shouldValid).toBe(false)
  })
})
