export default () => {
  return {
    title: 'Assortment Planning',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          currency: {
            type: 'string',
            default: '$',
            title: 'Currency'
          },
          smallCurrencyFormat: {
            type: 'string',
            default: '$0.00',
            title: 'Small Currency Format'
          },
          largeCurrencyFormat: {
            type: 'string',
            default: '$0,000',
            title: 'Large Currency Format'
          },
          similarItemExternalLink: {
            type: 'boolean',
            title: `Similar Item's External Link`,
            default: false,
            required: true
          },
          optionPriceEditable: {
            type: 'boolean',
            title: `Option Price Editable`,
            default: false,
            required: true
          },
          delayUpdates: {
            type: 'boolean',
            title: 'Delay Updates',
            default: false,
            required: true
          }
        }
      },
      drillDowns: {
        type: 'object',
        title: 'Drilldowns',
        properties: {
          similarProductButtons: {
            type: 'array',
            title: 'Similar Product Buttons',
            items: {
              type: 'object',
              title: 'Similar Product Button',
              headerTemplate: '{{ self.buttonText}}',
              options: {
                collapsed: true
              },
              properties: {
                buttonText: {
                  type: 'string',
                  title: 'Button Text'
                }
              }
            }
          },
          segmentContextMenuItems: {
            type: 'array',
            title: 'Segment Context Menu Items',
            items: {
              type: 'object',
              title: 'Segment Context Menu Item',
              headerTemplate: '{{ self.menuText}}',
              options: {
                collapsed: true
              },
              properties: {
                menuText: {
                  type: 'string',
                  title: 'Menu Text'
                }
              }
            }
          }
        }
      }
    }
  }
}
