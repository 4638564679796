import * as actionTypes from './actionTypes'

export const saveCurrencyRatesSettings = (options) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storeCurrencyRatesSettings(options))
      resolve()
    })
  }
}

const storeCurrencyRatesSettings = (options) => {
  return {
    type: actionTypes.STORE_CURRENCY_RATES_SETTINGS,
    payload: options.payload
  }
}
