import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import _ from 'lodash'
import cx from 'classnames'

import { clearAll } from '@/crudoptV3'
import signalr from '@/helpers/SignalrClient'
import userManager from '@/utils/userManager'
import { setEnvironment } from '@/store/slices/setting'
import { useUserLocale } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/hooks/store'
import { EnvironmentTypes } from '@/ts'
import SessionModal from '@/components/SessionModal/SessionModal'

const App = () => {
  const dispatch = useAppDispatch()
  const environment = useAppSelector(({ setting }) => _.lowerCase(setting.environment))
  const isMaximize = useAppSelector(({ maximize }) => maximize.max !== '')
  const { user } = useUserLocale()
  const { environment: envParam } = useParams<{ environment: EnvironmentTypes }>()

  useEffect(() => {
    if (!user || user.expired) {
      setTimeout(() => {
        userManager.signinRedirect({
          data: { redirectUrl: window.location.href }
        })
      }, 1000)
    } else {
      signalr.init(user)
    }
  }, [user])

  useEffect(() => {
    if (envParam) {
      dispatch(clearAll())
      dispatch(setEnvironment(envParam))
    } else {
      dispatch(setEnvironment('Live'))
    }
  }, [dispatch, envParam])

  useEffect(() => {
    const htmlDoc = document.documentElement
    htmlDoc.classList.remove('--test', '--live', '--configuration')
    htmlDoc.classList.add(`--${environment}`)
    htmlDoc.classList[isMaximize ? 'add' : 'remove']('maximize-container')
  }, [isMaximize, environment])

  const handleExtendSession = () => {
    userManager.signinSilent()
  }

  if (!user || user.expired || !environment) return null

  return (
    <div
      className={cx('slvy-ui-wrapper', `--${environment}`, {
        'maximize-wrapper': isMaximize
      })}
      id="wrapper"
    >
      <SessionModal expiresIn={user.expires_in} onExtendSessionClick={handleExtendSession} />
      <Outlet />
    </div>
  )
}

export default App
