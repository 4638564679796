Ext.define('MDM.Btl.model.EditModel', {
  extend: 'MDM.src.model.Base',
  // alias: 'model.pg-btl-models-MainModel',
  entityName: 'gillettev3/btl_edit_dpi_data',
  idProperty: 'ID',
  fields: [
    {
      name: 'IsHide',
      type: 'boolean'
    },
    {
      name: 'IsEditable',
      type: 'boolean'
    },
    {
      name: 'EditType',
      type: 'string',
      defaultValue: 'Manuel'
    },
    {
      name: 'IsApplied',
      type: 'boolean'
    },
    {
      name: 'Title',
      type: 'string'
    },
    {
      name: 'RegionId',
      type: 'int'
    },
    {
      name: 'Region',
      type: 'string'
    },
    {
      name: 'dataType',
      type: 'string',
      defaultValue: 'Edited'
    },
    {
      name: 'isEditable',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'Comment',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'c01',
      type: 'number'
    },
    {
      name: 'c02',
      type: 'number'
    },
    {
      name: 'c03',
      type: 'number'
    },
    {
      name: 'c04',
      type: 'number'
    },
    {
      name: 'c05',
      type: 'number'
    },
    {
      name: 'c06',
      type: 'number'
    },
    {
      name: 'c07',
      type: 'number'
    },
    {
      name: 'c08',
      type: 'number'
    },
    {
      name: 'c09',
      type: 'number'
    },
    {
      name: 'c10',
      type: 'number'
    },
    {
      name: 'c11',
      type: 'number'
    },
    {
      name: 'c12',
      type: 'number'
    },
    {
      name: 't1',
      calculate: function (data) {
        return (
          data.c01 +
          data.c02 +
          data.c03 +
          data.c04 +
          data.c05 +
          data.c06 +
          data.c07 +
          data.c08 +
          data.c09 +
          data.c10 +
          data.c11 +
          data.c12
        )
      }
    },
    {
      name: 'c13',
      type: 'number'
    },
    {
      name: 'c14',
      type: 'number'
    },
    {
      name: 'c15',
      type: 'number'
    },
    {
      name: 'c16',
      type: 'number'
    },
    {
      name: 'c17',
      type: 'number'
    },
    {
      name: 'c18',
      type: 'number'
    },
    {
      name: 'c19',
      type: 'number'
    },
    {
      name: 'c20',
      type: 'number'
    },
    {
      name: 'c21',
      type: 'number'
    },
    {
      name: 'c22',
      type: 'number'
    },
    {
      name: 'c23',
      type: 'number'
    },
    {
      name: 'c24',
      type: 'number'
    },
    {
      name: 't2',
      calculate: function (data) {
        return (
          data.c13 +
          data.c14 +
          data.c15 +
          data.c16 +
          data.c17 +
          data.c18 +
          data.c19 +
          data.c20 +
          data.c21 +
          data.c22 +
          data.c23 +
          data.c24
        )
      }
    },
    {
      name: 'c25',
      type: 'number'
    },
    {
      name: 'c26',
      type: 'number'
    },
    {
      name: 'c27',
      type: 'number'
    },
    {
      name: 'c28',
      type: 'number'
    },
    {
      name: 'c29',
      type: 'number'
    },
    {
      name: 'c30',
      type: 'number'
    },
    {
      name: 'c31',
      type: 'number'
    },
    {
      name: 'c32',
      type: 'number'
    },
    {
      name: 'c33',
      type: 'number'
    },
    {
      name: 'c34',
      type: 'number'
    },
    {
      name: 'c35',
      type: 'number'
    },
    {
      name: 'c36',
      type: 'number'
    },
    {
      name: 'Future',
      type: 'number'
    },
    {
      name: 'Past',
      type: 'number'
    },
    {
      name: 't3',
      calculate: function (data) {
        return (
          data.c25 +
          data.c26 +
          data.c27 +
          data.c28 +
          data.c29 +
          data.c30 +
          data.c31 +
          data.c32 +
          data.c33 +
          data.c34 +
          data.c35 +
          data.c36
        )
      }
    },
    {
      name: 'BTLType',
      type: 'string'
    },
    {
      name: 'Title2',
      convert: function (value, record) {
        if (record.get('BTLType') == 'D') {
          return record.get('MaterialType') + ' / ' + record.get('MaterialCode')
        } else if (record.get('BTLType') == 'P') {
          return (
            record.get('MaterialType') +
            ' / ' +
            record.get('SupplySource') +
            ' / ' +
            record.get('Material')
          )
        } else if (record.get('BTLType') == 'I') {
          return record.get('MaterialType') + ' / ' + record.get('MaterialCode')
        } else if (record.get('BTLType') == 'X') {
          return record.get('MaterialType') + ' / ' + record.get('MaterialCode')
        }
        return 'DPI type not defined'
      }
    },
    {
      name: 'BTLTypeDesc',
      calculate: function (data) {
        if (data.BTLType == 'D') return 'Demand'
        if (data.BTLType == 'P') return 'Production'
        if (data.BTLType == 'I') return 'Inventory'
        if (data.BTLType == 'X') return 'Cross Supply'
        return data.BTLType
      }
    },
    {
      name: 'DPIType',
      type: 'string'
    },
    {
      name: 'Plant',
      type: 'string'
    },
    {
      name: 'Material',
      type: 'string'
    },
    {
      name: 'MaterialCode',
      type: 'string',
      value: ''
    },
    {
      name: 'CreatedAt',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s.u'
    },
    {
      name: 'AppliedAt',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s.u'
    },
    {
      name: 'hasValue',
      calculate: function (data) {
        var a =
          Math.abs(data.c01) +
          Math.abs(data.c02) +
          Math.abs(data.c03) +
          Math.abs(data.c04) +
          Math.abs(data.c05) +
          Math.abs(data.c06) +
          Math.abs(data.c07) +
          Math.abs(data.c08) +
          Math.abs(data.c09) +
          Math.abs(data.c10) +
          Math.abs(data.c11) +
          Math.abs(data.c12) +
          Math.abs(data.c13) +
          Math.abs(data.c14) +
          Math.abs(data.c15) +
          Math.abs(data.c16) +
          Math.abs(data.c17) +
          Math.abs(data.c18) +
          Math.abs(data.c19) +
          Math.abs(data.c20) +
          Math.abs(data.c21) +
          Math.abs(data.c22) +
          Math.abs(data.c23) +
          Math.abs(data.c24) +
          Math.abs(data.c25) +
          Math.abs(data.c26) +
          Math.abs(data.c27) +
          Math.abs(data.c28) +
          Math.abs(data.c29) +
          Math.abs(data.c30) +
          Math.abs(data.c31) +
          Math.abs(data.c32) +
          Math.abs(data.c33) +
          Math.abs(data.c34) +
          Math.abs(data.c35) +
          Math.abs(data.c36)
        return a
      }
    },
    {
      name: 'Toplam',
      type: 'number'
    },
    {
      name: 'NoDependent',
      type: 'string'
    }
  ]
})
