import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { API_URL } from '@/constants'
import { RootState } from '../index'

function axiosBaseQuery(
  { baseUrl, baseHeaders }: { baseUrl: string; baseHeaders?: any } = {
    baseUrl: '',
    baseHeaders: {}
  }
): BaseQueryFn<
  {
    url: string
    method?: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
    headers?: AxiosRequestConfig['headers']
  },
  unknown,
  unknown
> {
  return async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: baseHeaders()
      })

      return { data: result.data }
    } catch (axiosError) {
      const error = axiosError as AxiosError

      return {
        error: {
          status: error.response?.status,
          data: error.response?.data || error.message
        }
      }
    }
  }
}

const rawBaseQuery = axiosBaseQuery({
  baseUrl: `${API_URL}/data/`,
  baseHeaders: () => {}
})

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { tui } = api.getState() as RootState
  const { auth } = tui
  const urlEnd = typeof args === 'string' ? args : args.url
  const adjustedUrl = `${auth.pluginId}/invoke/${urlEnd}`
  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${auth.accessToken}`,
    'content-type': 'application/json',
    environment: auth.environment,
    ...args.headers
  }
  const adjustedArgs =
    typeof args === 'string'
      ? { url: adjustedUrl, headers }
      : { ...args, url: adjustedUrl, headers }

  return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export default dynamicBaseQuery
