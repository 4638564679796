export default () => {
  return {
    title: 'Box',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          },
          hidden: {
            type: 'boolean',
            title: 'Hidden',
            default: false,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Box Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title'
          },
          subtitle: {
            type: 'string',
            title: 'Sub Title'
          },
          backgroundcolor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          fontcolor: {
            type: 'string',
            format: 'Color',
            title: 'Text Color',
            default: '#676a6c'
          },
          textAlign: {
            type: 'string',
            title: 'Text Align',
            default: 'Center',
            enum: ['Center', 'Left', 'Right', 'Justify'],
            required: true
          },
          itemsDistribution: {
            type: 'string',
            title: 'Items Distribution',
            default: 'Distributed',
            enum: ['Distributed', 'Centered'],
            required: true
          },
          paddingTarget: {
            type: 'string',
            title: 'Padding Target',
            default: 'Top Bottom',
            enum: [
              'All',
              'Text Only',
              'Top Bottom',
              'Left Right',
              'Top',
              'Right',
              'Bottom',
              'Left'
            ],
            required: true
          },
          paddingValue: {
            type: 'integer',
            title: 'Padding',
            format: 'range',
            minimum: 0,
            maximum: 100,
            default: 0
          },
          doubleClickEnabled: {
            type: 'boolean',
            title: 'DoubleClick Event Enabled',
            default: true,
            required: true
          },
          cursorClickPointer: {
            type: 'boolean',
            title: 'Cursor Hand Pointer',
            default: false,
            required: true
          },
          iconStyle: {
            type: 'object',
            title: 'Icon Style',
            properties: {
              isIconEmbedded: {
                type: 'boolean',
                title: 'Icon Embedded',
                default: false,
                required: true
              },
              iconSize: {
                type: 'number',
                title: 'Icon Size',
                default: 1.4,
                minimum: 0
              },
              embeddedIconSize: {
                type: 'number',
                title: 'Embedded Icon Size',
                default: 50,
                minimum: 0
              },
              embeddedIconPosition: {
                type: 'string',
                title: 'Embedded Icon Position',
                default: 'Left',
                enum: ['Left', 'Right'],
                required: true
              }
            }
          },
          textStyle: {
            type: 'object',
            title: 'Text Style',
            properties: {
              titleTextSize: {
                type: 'number',
                title: 'Title Text Size',
                default: 2
              },
              valueTextSize: {
                type: 'number',
                title: 'Value Text Size',
                default: 3.2
              },
              valueTextFontWeight: {
                type: 'string',
                title: 'Value Text Font-Weight',
                enum: ['Bold', 'Normal'],
                default: 'Bold',
                required: true
              },
              subTitleTextSize: {
                type: 'number',
                title: 'Sub Title Text Size',
                default: 1.4
              },
              subValueTextSize: {
                type: 'number',
                title: 'Sub Value Text Size',
                default: 1.4
              }
            }
          },
          backgroundGradientColors: {
            type: 'object',
            title: 'Gradient Colors',
            properties: {
              firstColor: {
                type: 'string',
                format: 'Color',
                title: 'First Color',
                default: 'transparent'
              },
              secondColor: {
                type: 'string',
                format: 'Color',
                title: 'Second Color',
                default: 'transparent'
              }
            }
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          title: {
            type: 'string',
            title: 'Title',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          subtitle: {
            type: 'string',
            title: 'Sub Title',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          valueFormatField: {
            type: 'string',
            title: 'Value Format Field',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          subvalue: {
            type: 'string',
            title: 'Sub Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          subValueFormatField: {
            type: 'string',
            title: 'Sub Value Format Field',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          icon: {
            type: 'string',
            title: 'Icon',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          backgroundcolor: {
            type: 'string',
            title: 'Background Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          fontcolor: {
            type: 'string',
            title: 'Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
