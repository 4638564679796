import React, { Component } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { getItemByKey } from '../Util'
import { MESSAGES } from '../../messages'
import { slvyToast, SlvySpinner } from '../../../../components'

class ScenarioCopy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scenarioData: {}
    }
  }

  componentDidMount() {
    const { _fetchScenarioTree = () => {} } = this.props
    _fetchScenarioTree({ isLoadingActive: false }).then(() => {
      const { GetScenarioTree } = this.props
      this.setState({ scenarioData: GetScenarioTree })
    })
  }

  onApply(PlannedId, TargetId) {
    let { params, actions, onAction = () => {} } = this.props

    if (PlannedId === TargetId) {
      slvyToast.warning({
        message: MESSAGES.you_cannot_copy_the_same_scenario,
        options: { containerId: 'dp' }
      })
    } else {
      actions = { ...actions, PlannedId, TargetId }
      onAction(params, actions)
    }
  }

  onReverseHandler() {
    const { settings: { stateHandler: { isReverse = false } } = {}, stateHandler = () => {} } =
      this.props

    stateHandler({ isReverse: !isReverse })
  }

  onChangeScenario(ScenarioTreeResult, scenarioList, event) {
    const { stateHandler = () => {} } = this.props
    const { value } = event.target
    const Id = parseInt(value, 10)

    const TargetScenario =
      _.find(scenarioList, (o) => o.Id === Id) || getItemByKey(ScenarioTreeResult, 'Type', 2) || {}

    stateHandler({ TargetScenario }, true)
  }

  render() {
    const {
      state: { scenarioData: { Result: ScenarioTreeResult = [] } = {} },
      props: {
        settings: {
          stateHandler: { isReverse = false },
          CurrentScenario: { Baseline = {}, Planned: { Id: PlannedId, Name: PlannedName } } = {},
          TargetScenario = {}
        } = {}
      }
    } = this

    const { Id: TargetId, Name } = _.isEmpty(TargetScenario) ? Baseline : TargetScenario

    const scenarioList = _.map(
      _.flatMapDeep(ScenarioTreeResult, (item) => item.Children),
      (item) => item.Item
    ).filter((scenario) => scenario.Type !== 1 && scenario.Type !== 2)

    return !_.isEmpty(ScenarioTreeResult) ? (
      <>
        <div className="d-flex mb-2 gap-2 align-items-center">
          <div>
            Copy from{' '}
            <strong className="fw-bold text-capitalize">{isReverse ? PlannedName : Name}</strong> to{' '}
            <strong className="fw-bold text-capitalize">{isReverse ? Name : PlannedName}</strong>
          </div>
          <button className="btn btn-sm" onClick={this.onReverseHandler.bind(this)}>
            <i className="fa fa-exchange" />
          </button>
        </div>
        <div className="mb-2">
          <select
            className="form-select form-select-sm"
            onChange={this.onChangeScenario.bind(this, ScenarioTreeResult, scenarioList)}
          >
            {_.map(scenarioList, ({ Name, Id }, index) => (
              <option key={index} value={Id}>
                {Name}
              </option>
            ))}
          </select>
        </div>
        <Button
          className="ms-auto d-flex"
          size="sm"
          type="button"
          variant="primary"
          onClick={this.onApply.bind(this, PlannedId, TargetId)}
        >
          Apply
        </Button>
      </>
    ) : (
      <div className="position-relative p-5">
        <SlvySpinner />
      </div>
    )
  }
}

export default ScenarioCopy
