import { BasicType } from '@/BasePlugin/PluginTypes'
import { GetRegisterEventFieldConfigsParams } from '../../../types'
import { CellParams } from '../../selection/selection.types'
import { getRegisterEventFieldConfigsParams } from '../index'

const mockGetFieldType = jest.fn(
  (_type, isMultiple): BasicType => ({
    type: 'array',
    isMultiple
  })
)

const mockCellParams: CellParams = {
  col1: { type: 'array', isMultiple: true },
  col2: { type: 'string', isMultiple: false },
  col3: { type: 'bool', isMultiple: false }
}

const mockGetRegisterEventFieldConfigsParams: GetRegisterEventFieldConfigsParams = {
  cellParams: mockCellParams,
  getFieldType: mockGetFieldType
}

describe('AgGrid:Helpers:Common:getRegisterEventFieldConfigsParams', () => {
  it('should call getFieldType for each key in cellParams', () => {
    getRegisterEventFieldConfigsParams(mockGetRegisterEventFieldConfigsParams)
    expect(mockGetFieldType).toHaveBeenCalledTimes(Object.keys(mockCellParams).length)
  })

  it('should call getFieldType with the correct parameters', () => {
    getRegisterEventFieldConfigsParams(mockGetRegisterEventFieldConfigsParams)
    Object.keys(mockCellParams).forEach((key) => {
      expect(mockGetFieldType).toHaveBeenCalledWith(key, true)
    })
  })

  it('should return an object with the correct keys and values', () => {
    const result = getRegisterEventFieldConfigsParams({
      cellParams: mockCellParams,
      // TODO: Make getFieldType work dynamic
      getFieldType: () => ({ type: 'array', isMultiple: true })
    })
    Object.keys(mockCellParams).forEach((key) => {
      expect(result[key]).toEqual({ type: 'array', isMultiple: true })
    })
  })

  it('should return an empty object when cellParams is empty', () => {
    const result = getRegisterEventFieldConfigsParams({
      cellParams: {},
      getFieldType: mockGetFieldType
    })
    expect(result).toEqual({})
  })

  it('should return an empty object when cellParams is undefined', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getRegisterEventFieldConfigsParams({ getFieldType: mockGetFieldType })
    expect(result).toEqual({})
  })
})
