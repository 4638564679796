import { useEffect } from 'react'
import { isArray } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { tuiSlice } from '../../../store/slices'

function useOpenDemandCard() {
  const [{ registerMethod }] = useReduxState(tuiSlice.selectSlice)

  const handleOpenDemandCard = (params) => {
    const { FacilityNames: facilityNames = [] } = params
    const facilityNamesToOpen = isArray(facilityNames) ? facilityNames : [facilityNames]

    const facilityKeys = []
    const facilityIds = []

    // TODO: add openDemandCard function
    // The openDemandCard function allows us to open cards on the map according to the selected facilityNames.
    // openDemandCard(facilityIds, facilityKeys)
  }

  useEffect(() => {
    registerMethod({
      args: [{ name: 'FacilityNames', type: PluginTypes.arrayOf(PluginTypes.string) }],
      key: 'openDemandCard',
      fn: handleOpenDemandCard
    })
  }, [registerMethod])
}

export default useOpenDemandCard
