import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import AssortmentModal from '../Modal'
import AssortmentInput from '../Input'
import { slvyToast, SlvyTable } from '@/components'
import AssortmentLoading from '../Loading'
import { setLoaderReducer } from '../../store/slices/appSlice'
import {
  addSimilarProducts,
  getProducts,
  resetSimilarProductsAdded
} from '../../store/slices/similarOptions'

const columns = [
  { header: 'Name', accessorKey: 'name' },
  { header: 'Description', accessorKey: 'description' }
]

function AddModalForm(props) {
  const {
    show = false,
    handleCloseModal = () => {},
    pluginId = '',
    existingProducts = [],
    assortmentId = null,
    SelectedOption: { Id: ProductId = '' } = {},
    addSimilarProductsCallback = () => {}
  } = props

  const dispatch = useDispatch()

  const [query, setQuery] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState([])

  const onGetProducts = ($key = null) => {
    const payload = {
      pluginId,
      method: 'GetProducts',
      requestMethod: 'post',
      body: {
        key: $key
      }
    }

    setIsLoading(true)

    dispatch(getProducts(payload))
      .then((res) => {
        const unusedProducts = res.payload.filter((product) =>
          existingProducts.every((exProduct) => exProduct.Id !== product.id)
        )
        setFilteredProducts(unusedProducts)
      })
      .catch(() => setFilteredProducts([]))
      .finally(() => setIsLoading(false))
  }

  const handleOnAdd = () => {
    if (!selectedRows.length) {
      slvyToast.error({ message: 'To be able to add product, select product/s' })
      return
    }

    const similarProductIds = []
    filteredProducts.forEach((element) => {
      const selectedProductRow = selectedRows.filter(
        ({ original: { id = null } = {} }) => id === element.id
      )
      if (selectedProductRow.length) {
        similarProductIds.push(selectedProductRow[0].original.id)
      }
    })

    const payload = {
      pluginId,
      method: 'AddSimilarProducts',
      requestMethod: 'post',
      body: {
        similarProductIds,
        productId: ProductId,
        assortmentId
      }
    }

    dispatch(setLoaderReducer({ isShown: true, messages: 'Add Similar Products' }))
    dispatch(addSimilarProducts(payload))
      .then(({ Code, Description }) => {
        if (Code > -1) {
          slvyToast.success({ message: Description, title: 'Add Similar Products' })
          addSimilarProductsCallback()
        }
        resetSimilarProductsAdded()
      })
      .catch(() => {
        slvyToast.error({ message: 'Similar products could not be added' })
      })
      .finally(() => dispatch(setLoaderReducer({ isShown: false })))

    handleCloseModal()
  }

  const onHandleFieldChanged = ({ value = '' }) => {
    setQuery(value)
    onGetProducts(value.trim())
  }

  const handleSelectTableRow = (row, event) => {
    let _selectedRows = [...selectedRows]
    const hasInSelected =
      _selectedRows.filter(({ original: { id = null } = {} }) => id === row.original.id).length > 0

    if (hasInSelected) {
      _selectedRows = _selectedRows.filter(
        ({ original: { id = null } = {} }) => id !== row.original.id
      )
    } else {
      _selectedRows.push(row)
    }
    setSelectedRows(_selectedRows)
  }

  const getCellClass = (cell) => {
    const {
      row: { index }
    } = cell

    const isSelected = selectedRows.find((selectedRow) => selectedRow.index === index)
    if (isSelected) {
      return 'row-selected'
    }
    return ''
  }

  const removeSelectedProducts = ($id) => {
    setSelectedRows([...selectedRows].filter(({ original: { id = null } = {} }) => id !== $id))
  }

  return (
    <AssortmentModal
      isShowFooter
      className="add-modal-form"
      footerTemplate={
        <Button disabled={!selectedRows.length} size="sm" variant="success" onClick={handleOnAdd}>
          Add
        </Button>
      }
      handleCloseModal={handleCloseModal}
      show={show}
      size="slvy-modal-xl assortmentModal"
      title="Add Similar Product"
    >
      <div className="form-horizontal add-modal-form-search">
        <div className="form-group row py-1">
          <div className="col-sm-2">
            <label className="form-label mb-0" htmlFor="search-product">
              Search in Product
            </label>
          </div>
          <div className="col-sm-2">
            <AssortmentInput
              isRequired
              id="search-product"
              inputKey="query"
              value={query}
              onChanged={onHandleFieldChanged}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {selectedRows.length > 0 ? (
          <div className="d-flex flex-wrap gap-2 p-3 border-bottom">
            {selectedRows.map((selected) => {
              const { original: { id: productId = null, name: productName = '' } = {} } =
                selected || {}
              return (
                <Button
                  key={productId}
                  className="d-flex align-items-center gap-1"
                  size="sm"
                  type="button"
                  variant="outline-dark"
                  onClick={() => removeSelectedProducts(productId)}
                >
                  {productName}
                  <span className="slvy-ui-icon-times-lt fs-6" />
                </Button>
              )
            })}
          </div>
        ) : null}
        <div className="col-sm-12 position-relative">
          <SlvyTable
            rowSelection
            suppressColumnMenu
            className="add-modal-form-grid assortment-react-table-element"
            columns={columns}
            data={filteredProducts}
            getCellClassName={getCellClass}
            onSelectRow={handleSelectTableRow}
          />
          <AssortmentLoading isShown={isLoading} />
        </div>
      </div>
    </AssortmentModal>
  )
}

export default AddModalForm
