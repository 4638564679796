export default `({
    title: 'Spot oneri icin secilen benzer urun lstesi',
    border: true,
    dockedItems: [{
        xtype: 'toolbar',
        style: {
            'border-bottom': '1px solid rgb(193, 193, 193) !important;'
        },
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: tt('<b>Similar Product List</b>'),
        }]
    }],
    columnLines: true,
    margin: 0,
    header: false,
    flex: 1,
    defaultListenerScope: true,
    viewModel: {
        data: {
            numberofselectedItems: '0',
        }
    },
    tbar: [{
            iconCls: 'x-fa fa-filter',
            bind: {
                text: tt('Secili Urunler') + ' : ' + '{numberofselectedItems}'
            },
            handler: function () {
                if (this.ownerCt && this.ownerCt.ownerCt) {
                    var grid = this.ownerCt.ownerCt
                    if (!grid.store.isFiltered()) {
                        grid.store.filter('IsSimilarTemp', 1)
                    } else {
                        grid.store.clearFilter();
                    }
                }
            },
            scope: 'this'
        }, '->',
        // {
        //     xtype: 'button',
        //     text: 'Tum Bolgeler',
        // }
        {
            xtype: 'button',
            text: tt('Export to Excel'),
            handler: function (btn) {
                var grid = this.ownerCt.ownerCt
                grid.saveDocumentAs({
                    type: 'xlsx',
                    title: tt('Benzer Ürünler'),
                    fileName: tt('Benzer Ürünler')+'.xlsx',
                });
            }
        }
    ],
    listeners: {
        render: function (grid) {
            grid.store.on('load', grid.onCheckchange, grid)
        },
        select: function (grid, record) {
            var me = this,
                t = me.ownerCt.getTapPanel();
            t.setSimilarSpotId(record.get('SimilarityID'));
            // spotsaleschart = me.lookup('spotsaleschart');
                        //  spotsaleschart.store.getProxy().setExtraParam('Id', record.get('Id'));
            // spotsaleschart.store.load();
        },
    },
    features: [{
        ftype: 'grouping',
        startCollapsed: false,
        groupHeaderTpl: 'Ürün Açıklaması: {name} ({rows.length} {[values.rows.length > 1 ? "" : ""]})'
    }],
    plugins: [{
        ptype: 'gridexporter'
    }],
    viewConfig: {
        emptyText: 'No record found',
        getRowClass: function (record, rowIndex, rowParams, store) {
            if (record.get('IsSame')) {
                //  rowParams.tstyle += "background-color:" + 'red' + ';';
                return 'row-red';
            }
            return ''
        }
    },
    columns: [{
            xtype: 'checkcolumn',
            header: tt('IsSmilar?'),
            dataIndex: 'IsSimilarTemp',
            headerCheckbox: false,
            width: 90,
            stopSelection: true,
            disabled: true,
            listeners: {
                checkchange: 'onCheckchange'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            // xtype: 'textcolumn',
        },
        {
            text: tt('StartDate'),
            dataIndex: 'StartDate',
            width: 100,
            xtype: 'datecolumn',
            format: tt('d-m-Y')
        },
        {
            text: tt('EndDate'),
            dataIndex: 'EndDate',
            width: 100,
            xtype: 'datecolumn',
            format: tt('d-m-Y')
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('Price'),
            dataIndex: 'Price',
            width: 100,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
            renderer: function (v) {
                v = Ext.util.Format.number(v, '0,000');
                if(t('₺')=='₺')
                {
                    return v + ' ' + t('₺');
                }
                else{
                    return t('₺') +' '+ v;
                }
            }
        },
        {
            text: tt('CurrentInventory'),
            dataIndex: 'currentInventory',
            width: 100,
            format: '0,000.00',
            align: 'right',
            xtype: 'numbercolumn',
            renderer: function (v) {
                v = Ext.util.Format.number(v, '0,000.00');
                return v
            }
        },
        {
            text: tt('DurationInWeek'),
            dataIndex: 'DurationInWeek',
            width: 100,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('DurationInDays'),
            dataIndex: 'DurationInDays',
            width: 100,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('Sales Quantity'),
            columns: [{
                    text: tt('Total'),
                    dataIndex: 'TotalInvoicedQuantity',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('NumberOfDistinctStore'),
                    dataIndex: 'NumberOfDistinctStore',
                    width: 100,
                    format: '0,000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        v = Ext.util.Format.number(v, '0,000.00');
                        return v
                    }
                },
                {
                    text: tt('Day 1'),
                    dataIndex: 'TotalInvoicedQuantityDay1',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('Day 4'),
                    dataIndex: 'TotalInvoicedQuantityDay4',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('Day 7'),
                    dataIndex: 'TotalInvoicedQuantityDay7',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
            ]
        },
        //	{ text: 'StartDateKey', dataIndex: 'StartDateKey', width: 100 },
        //	{ text: 'EndDateKey', dataIndex: 'EndDateKey', width: 100 },
        //	{ text: 'BackDateKey', dataIndex: 'BackDateKey', width: 100 },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay2'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay2',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay3'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay3',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'TotalNetValueoftheSalesOrderDay5',
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay5',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('TotalNetValueoftheSalesOrderDay6'),
        //     dataIndex: 'TotalNetValueoftheSalesOrderDay6',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        {
            text: tt('Pareto 80%'),
            columns: [{
                    text: tt('Day'),
                    dataIndex: 'Pareto80Day',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn'
                }, {
                    text: tt('ParetoNetValueoftheSalesOrder'),
                    dataIndex: 'ParetoNetValueoftheSalesOrder',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('ParetoTotalInvoicedQuantity'),
                    dataIndex: 'ParetoTotalInvoicedQuantity',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn'
                }, {
                    text: tt('ParetoTotalInvoicedQuantityPercent'),
                    dataIndex: 'ParetoTotalInvoicedQuantityPercent',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        return v * 100 + ' %';
                    }
                }
            ]
        },
        //	{ text: 'IsSimilar', dataIndex: 'IsSimilar', width: 100, format: '000.00', align: 'right', xtype: 'numbercolumn' },
        //	{ text: 'IsSimilarTemp', dataIndex: 'IsSimilarTemp', width: 100, format: '000.00', align: 'right', xtype: 'numbercolumn' },
        // {
        //     text: tt('MinQuantity'),
        //     dataIndex: 'MinQuantity',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('MaxQuantity'),
        //     dataIndex: 'MaxQuantity',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
    ],
    storeLoad: function (store) {
        var rec = store.first();
        if (rec) {
            this.selModel.doSelect(rec);
        } else {
            rec = Ext.create('Ext.data.Store', {
                id: -1
            })
            this.selModel.doSelect(rec)
        }
    },
    onCheckchange: function (p, rowIndex, checked, record, e, eOpts) {
        var store = this.store,
            viewModel = this.getViewModel();
        ownerCt = this.ownerCt;
        // ownerCt.setLoading(tt('Saving data......'));
        store.sync({
            success: function () {
                ownerCt.setLoading(false);
            },
            failure: function () {
                ownerCt.setLoading(false);
                Ext.Msg.alert(tt('Status'), tt('Changes can not saved ....'));
            }
        })
        var allRecords = store.snapshot || store.data;
        var i = 0
        allRecords.each(function (record) {
            i = record.get('IsSimilarTemp') ? i + 1 : i;
        });
        viewModel.set('numberofselectedItems', i);
        store.filter('IsSimilarTemp', 1)
    }
})
`
