import { Component } from 'react'

import _ from 'lodash'
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import cx from 'classnames'
import styles from './index.module.scss'

export default class BooleanEditor extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { schema = {}, value } = this.props
    const val = !_.isNil(value) ? value : schema.default
    this.setState({ value: val })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value })
    }
  }

  handleChange(val) {
    if (this.props.value !== val) {
      this.setState({ value: val })

      if (_.isFunction(this.props.onChange)) {
        this.props.onChange(val)
      }
    }
  }

  render() {
    const { type, title } = this.props.schema
    const { value } = this.state

    return (
      <ButtonToolbar aria-label={title}>
        <ButtonGroup size="xs" className={styles.buttonGroup}>
          <Button
            className={cx('m-0', styles.buttonGroupBtn)}
            variant={value === true ? 'success' : 'outline-dark'}
            onClick={() => this.handleChange(true)}
          >
            On
          </Button>
          <Button
            className={cx('m-0', styles.buttonGroupBtn)}
            variant={value === false ? 'success' : 'outline-dark'}
            onClick={() => this.handleChange(false)}
          >
            Off
          </Button>
          {type === 'NullableBoolean' && (
            <Button
              className={cx('m-0', styles.buttonGroupBtn)}
              variant={_.isNil(value) ? 'success' : 'outline-dark'}
              onClick={() => this.handleChange(null)}
            >
              x
            </Button>
          )}
        </ButtonGroup>
      </ButtonToolbar>
    )
  }
}
