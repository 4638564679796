import React, { Component } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { confirmAlert } from 'react-confirm-alert'
import { Dropdown, Button } from 'react-bootstrap'
import CustomToggle from './components/customToggle'
import CustomMenu from './components/customMenu'
import ItemList from './components/itemList'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { slvyToast } from '@/components'
import './index.scss'

class StateSaver extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      autoLoad: false,
      targetRect: {},
      disabled: false
    }

    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.saveState = this.saveState.bind(this)
    this.loadState = this.loadState.bind(this)
    this.saveNewState = this.saveNewState.bind(this)
    this.deleteState = this.deleteState.bind(this)
    this.updateState = this.updateState.bind(this)
    this.bodyClick = this.bodyClick.bind(this)
    this.resizeWindow = this.resizeWindow.bind(this)
    this.deleteAllStates = this.deleteAllStates.bind(this)
    this.getFiltersObjProps = this.getFiltersObjProps.bind(this)
    this.loadLastState = this.loadLastState.bind(this)
    this.saveLastUsedState = this.saveLastUsedState.bind(this)
    this.autoLoadValueChanged = this.autoLoadValueChanged.bind(this)
    this.setEnabled = this.setEnabled.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
  }

  newPluginState = {}

  mounted = false

  loadLastStateOnMount = false

  LAST_USED = '[LAST USED]'

  AUTO_LOAD = '[AUTO LOAD]'

  UNSAFE_componentWillMount() {
    this.props.loadPluginStates()
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'saveState',
      fn: this.saveState,
      args: this.prepareSaveStateMethodArgs()
    })
    this.props.registerMethod({
      key: 'loadLastState',
      fn: this.loadLastState,
      args: []
    })

    this.props.registerMethod({
      key: 'setEnabled',
      fn: this.setEnabled,
      args: []
    })

    this.props.registerMethod({
      key: 'setDisabled',
      fn: this.setDisabled,
      args: []
    })
    const obj = this.prepareStateLoadedEventReturnTypes()
    obj.refreshKey = PluginTypes.string

    this.loadState = this.props.registerEvent({
      key: 'stateLoaded',
      fn: this.loadState,
      returnTypes: obj
    })
    this.handleButtonClick = this.props.registerEvent({
      key: 'handleButtonClick',
      fn: this.handleButtonClick,
      returnTypes: obj
    })
    this.handleResetClick = this.props.registerEvent({
      key: 'handleResetClick',
      fn: this.handleResetClick,
      returnTypes: obj
    })

    window.addEventListener('click', this.bodyClick)
    window.addEventListener('resize', this.resizeWindow)
  }

  setEnabled() {
    this.setState({ disabled: false })
  }

  setDisabled() {
    this.setState({ disabled: true })
  }

  componentDidUpdate(prevProps) {
    const { states: { result: prevResult = [] } = {} } = prevProps
    const { states: { result: currentResult = [] } = {} } = this.props

    if (prevResult.length === 0 && currentResult.length > 0) {
      this.mounted = true

      if (this.loadLastStateOnMount) {
        this.loadLastState()
        this.loadLastStateOnMount = false
      }
    }
  }

  resizeWindow(e) {
    this.setState({ isOpen: false })
  }

  bodyClick(event) {
    const { isOpen = false } = this.state
    if (isOpen) {
      const isDescendant = this.isDomDescendant('StateSaverDiv', event.target)
      if (!isDescendant) {
        this.setState({ isOpen: false })
      }
    }
  }

  isDomDescendant(StateSaverDiv, child) {
    if (_.includes(child.className, StateSaverDiv)) {
      return true
    }

    let node = child.parentNode

    while (node != null) {
      if (!_.isEmpty(node.className)) {
        if (_.includes(node.className, StateSaverDiv)) {
          return true
        }
      }
      node = node.parentNode
    }
    return false
  }

  prepareSaveStateMethodArgs() {
    const { settings: { config: { variables = [] } = {} } = {} } = this.props || {}

    const methodArgs = _.transform(
      variables,
      (result, item, key) => {
        if (!_.isEmpty(item.name)) {
          switch (item.type) {
            case 'int':
              if (item.isMultiple) {
                result.push({
                  name: item.name,
                  type: PluginTypes.arrayOf(PluginTypes.int)
                })
              } else {
                result.push({ name: item.name, type: PluginTypes.int })
              }
              break
            case 'string':
              if (item.isMultiple) {
                result.push({
                  name: item.name,
                  type: PluginTypes.arrayOf(PluginTypes.string)
                })
              } else {
                result.push({ name: item.name, type: PluginTypes.string })
              }
              break
            case 'dynamic':
              if (item.isMultiple) {
                result.push({
                  name: item.name,
                  type: PluginTypes.arrayOf(PluginTypes.dynamic)
                })
              } else {
                result.push({ name: item.name, type: PluginTypes.dynamic })
              }
              break
            default: {
            }
          }
        }
      },
      []
    )
    return methodArgs
  }

  prepareStateLoadedEventReturnTypes() {
    const { settings: { config: { variables = [] } = {} } = {} } = this.props || {}
    const eventReturnTypes = _.transform(
      variables,
      (result, item, key) => {
        if (!_.isEmpty(item.name)) {
          switch (item.type) {
            case 'int':
              if (item.isMultiple) {
                result[item.name] = PluginTypes.arrayOf(PluginTypes.int)
              } else {
                result[item.name] = PluginTypes.int
              }
              break
            case 'string':
              if (item.isMultiple) {
                result[item.name] = PluginTypes.arrayOf(PluginTypes.string)
              } else {
                result[item.name] = PluginTypes.string
              }
              break
            case 'dynamic':
              if (item.isMultiple) {
                result[item.name] = PluginTypes.arrayOf(PluginTypes.dynamic)
              } else {
                result[item.name] = PluginTypes.dynamic
              }
              break
            default: {
            }
          }
        }
      },
      {}
    )
    return eventReturnTypes
  }

  getFiltersObjProps(filters) {
    const {
      settings: { config: { variables = [], submit: { sendFalsy = false } = {} } = {} } = {}
    } = this.props || {}
    const state = _.transform(
      variables,
      (result, filter) => {
        const currentFilter = filters[filter.name]
        const isFilterExist = sendFalsy ? currentFilter !== undefined : currentFilter
        result[filter.name] = isFilterExist ? currentFilter : null
      },
      {}
    )
    return state
  }

  loadState(state, dontSaveAsLastUsed) {
    const incomingState = this.getFiltersObjProps(state)
    this.saveState(incomingState)
    if (!dontSaveAsLastUsed) {
      this.saveLastUsedState(incomingState)
    }
    return incomingState
  }

  saveNewState(name, ignoreToastr) {
    const { states: { result = [] } = {} } = this.props || {}
    if (
      _.size(result) !== 0 &&
      _.find(result, (item) => {
        return item.name === name
      })
    ) {
      if (!ignoreToastr) {
        slvyToast.error({ message: 'State with this name already exist!', title: 'Failure' })
      }
    } else {
      this.props
        .createPluginState(name, this.newPluginState)
        .then(() => {
          if (!ignoreToastr) {
            slvyToast.success({ message: 'Filter has been created successfully', title: 'Success' })
          }
        })
        .catch(() => {
          if (!ignoreToastr) slvyToast.error({ message: 'Cannot create filter', title: 'Failure' })
        })
    }
  }

  saveState(newState) {
    if (_.isEmpty(newState)) {
      this.newPluginState = {}
    } else {
      this.newPluginState = newState
    }
  }

  loadLastState() {
    if (!this.mounted) {
      this.loadLastStateOnMount = true
      return
    }

    const {
      states: { result = [] } = {},
      settings: { config: { general: { enableAutoLoad = false } = {} } = {} } = {}
    } = this.props

    const autoLoadState = _.find(result, (state) => state.name === this.AUTO_LOAD)
    const { config: { autoLoad = enableAutoLoad } = {} } = autoLoadState || {}

    if (this.state.autoLoad !== autoLoad) {
      this.setState({ autoLoad })
    }

    if (!autoLoad) {
      return
    }

    const lastState = _.find(result, (state) => state.name === this.LAST_USED)
    if (lastState) {
      this.loadState(lastState.config, true)
    }
  }

  updateState(pluginState, ignoreToastr) {
    const { states: { result = [] } = {} } = this.props || {}

    if (
      _.size(result) !== 0 &&
      _.find(result, (item) => {
        return item.name === pluginState.name && item.id !== pluginState.id
      })
    ) {
      if (!ignoreToastr) {
        slvyToast.error({ message: 'State with this name already exist!', title: 'Failure' })
      }
    } else {
      this.props
        .updatePluginState(pluginState)
        .then(() => {
          if (!ignoreToastr) {
            slvyToast.success({
              message: 'Success',
              title: 'Filter name has been updated successfully'
            })
          }
        })
        .catch(() => {
          if (!ignoreToastr) slvyToast.error({ message: 'Cannot update filter', title: 'Failure' })
        })
    }
  }

  deleteState(stateId) {
    this.props
      .deletePluginState(stateId)
      .then(() =>
        slvyToast.success({ message: 'Filter has been deleted successfully', title: 'Success' })
      )
      .catch(() => slvyToast.error({ message: 'Cannot delete filter', title: 'Failure' }))
  }

  deleteAllStates() {
    confirmAlert({
      title: "Deleting all filters' states !!??",
      message: `Are you sure to delete all filters' states?`,
      buttons: [
        {
          label: 'Cancel'
        },
        {
          label: 'Confirm Delete',
          onClick: () => {
            this.props
              .deleteAllPluginState()
              .then(() =>
                slvyToast.success({
                  message: 'Success',
                  title: `Filter's all states has been deleted successfully`
                })
              )
              .catch(() => slvyToast.error({ message: 'Cannot delete filter', title: 'Failure' }))
          }
        }
      ]
    })
  }

  saveLastUsedState(stateConfig) {
    if (this.state.autoLoad) {
      const { states: { result = [] } = {} } = this.props
      const lastState = _.find(result, (st) => st.name === this.LAST_USED)
      if (lastState) {
        lastState.config = stateConfig
        this.updateState(lastState, true)
      } else {
        this.saveNewState(this.LAST_USED, true)
      }
    }
  }

  handleButtonClick(event) {
    event.preventDefault()
    this.setState({ isOpen: false })

    let obj = {}
    obj.refreshKey = uuidv4()
    const objProps = this.getFiltersObjProps(this.newPluginState)
    obj = { ...obj, ...objProps }

    this.saveLastUsedState(this.newPluginState)

    return obj
  }

  handleResetClick() {
    this.newPluginState = {}
    let obj = { ...this.newPluginState }
    obj.refreshKey = uuidv4()
    const objProps = this.getFiltersObjProps({})
    obj = { ...obj, ...objProps }
    return obj
  }

  autoLoadValueChanged(e) {
    this.setState({ autoLoad: e.target.checked })

    const { states: { result = [] } = {} } = this.props
    const autoLoadState = _.find(result, (st) => st.name === this.AUTO_LOAD)
    if (autoLoadState) {
      autoLoadState.config = { autoLoad: e.target.checked }
      this.updateState(autoLoadState, true)
    } else {
      this.props.createPluginState(this.AUTO_LOAD, {
        autoLoad: e.target.checked
      })
    }
  }

  render() {
    const { isOpen, targetRect, disabled = false } = this.state
    const {
      states: { result = [] } = {},
      settings: {
        config: {
          general: { enableAutoLoad = false } = {},
          submit: { text = '', style = 'success', icon = 'fa-check', iconPosition = 'Left' } = {},
          reset = {},
          newState = {}
        } = {}
      } = {},
      isPreviewMode
    } = this.props
    const iconClassName = `fa ${icon}`

    const states = _.filter(result, (s) => s.name !== this.LAST_USED && s.name !== this.AUTO_LOAD)

    return (
      <div className="StateSaverDiv state-saver-plugin p-1">
        <Dropdown
          className="state-saver-btn-group"
          defaultShow={isOpen}
          disabled={disabled}
          id="dropdown-custom-menu"
        >
          <CustomToggle
            bsRole="toggle"
            disabled={disabled}
            handleButtonClick={this.handleButtonClick}
            style={style}
            onClick={(e, targetElement) => {
              this.setState({
                isOpen: !isOpen,
                targetRect: targetElement.getBoundingClientRect()
              })
            }}
          >
            {iconPosition === 'Left' ? <i className={iconClassName} /> : null}
            {` ${text} `}
            {iconPosition === 'Right' ? <i className={iconClassName} /> : null}
          </CustomToggle>

          <CustomMenu
            autoLoad={this.state.autoLoad}
            autoLoadValueChanged={this.autoLoadValueChanged}
            bsRole="menu"
            className="StateSaverDiv"
            enableAutoLoad={enableAutoLoad}
            handleResetClick={this.handleResetClick}
            isOpen={isOpen}
            newState={newState}
            reset={reset}
            rootCloseEvent="click"
            saveState={this.saveNewState}
            targetRect={targetRect}
          >
            {states && states.length > 0 && (
              <ItemList
                deleteState={this.deleteState}
                items={states}
                loadState={this.loadState}
                updateState={this.updateState}
              />
            )}
            {states.length === 0 && (
              <div className="empty-state text-center fw-normal">Please save new filter...</div>
            )}
          </CustomMenu>
        </Dropdown>
        {isPreviewMode && (
          <Button variant="danger" onClick={() => this.deleteAllStates()}>
            Delete all states
          </Button>
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  settings: props.settings,
  loadPluginStates: props.loadPluginStates,
  states: props.states,
  createPluginState: props.createPluginState,
  updatePluginState: props.updatePluginState,
  deletePluginState: props.deletePluginState,
  deleteAllPluginState: props.deleteAllPluginState,
  isPreviewMode: props.isPreviewMode
})

export default createPlugin(StateSaver, selectConnectorProps)
