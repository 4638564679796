import { getValueFromLookupOptions } from '../index'
import { lookupOptions as defaultLookupOptions } from './mocks'

const getLookupOptions = () => {
  return defaultLookupOptions
}

describe('AgGrid:Helpers:Lookup:getValueFromLookupOptions', () => {
  let lookupOptions = getLookupOptions()
  beforeEach(() => {
    lookupOptions = getLookupOptions()
  })

  it(`should return value of matched object's by given id`, () => {
    const result = getValueFromLookupOptions({ lookupOptions, id: 4 })
    expect(result).toEqual('Fashion')
  })

  it('should return undefined if given id cannot find in lookupOptions', () => {
    const result = getValueFromLookupOptions({ lookupOptions, id: 99 })
    expect(result).toEqual(undefined)
  })

  it('should return undefined if lookupOptions is an empty array', () => {
    lookupOptions = []
    const result = getValueFromLookupOptions({ lookupOptions, id: 4 })
    expect(result).toEqual(undefined)
  })
})
