export const leftHiearchyColumns = [
  {
    header: 'Name',
    accessorKey: 'text'
  }
]

export const rightHierarchyColumns = [
  ...leftHiearchyColumns,
  {
    header: 'Tree Distinct Color',
    accessorKey: 'isTreeDistinctColorField',
    maxSize: 150,
    cell: ({ row }) => (
      <input defaultChecked checked={row.original.isTreeDistinctColorField} type="checkbox" />
    ),
    meta: {
      style: {
        display: 'flex',
        justifyContent: 'center'
      }
    }
  }
]
