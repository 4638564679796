import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { SlvySelect, SlvyTable } from '@/components'
import {
  selectScenario,
  selectScenarioName,
  toggleScenarioSelection
} from '../../../store/slices/treeSelection'

function ScenarioTreeList(props) {
  const dispatch = useDispatch()

  const selectedScenarioTree = useSelector((state) => state.treeSelection.tree)
  const selectedClusterTree = useSelector((state) => state.treeSelection.clusterTree)
  const selectedSaveTree = useSelector((state) => state.treeSelection.saveTree)

  const { currentScenario = {}, isFolderSelected = false } = selectedScenarioTree

  const {
    currentScenario: currentClusterTreeScenario = {},
    isFolderSelected: isFolderSelectedClusterTree = false
  } = selectedClusterTree

  const {
    currentScenario: currentSaveAsScenario = {},
    isFolderSelected: isFolderSelectedSaveAs = false
  } = selectedSaveTree

  const {
    availableStatus = [],
    dataKey = 'tree',
    expandedRows = {},
    isStatusChangeable = true,
    recentAssortments = [],
    root = {},
    title
  } = props

  const selectedCurrentScenario =
    dataKey === 'tree'
      ? currentScenario
      : dataKey === 'clusterTree'
      ? currentClusterTreeScenario
      : currentSaveAsScenario

  const getCell = (cell) => {
    const {
      row: {
        depth,
        original: { IsLeaf = false },
        getToggleExpandedHandler
      },
      column: { id: columnId }
    } = cell
    const cellValue = cell.getValue()
    if (columnId === 'Name') {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="d-flex align-items-center"
          style={{ marginLeft: 15 * depth }}
          onClick={getToggleExpandedHandler()}
        >
          {!IsLeaf && <span>{getExpanderCell(cell)}</span>}
          <span style={{ marginLeft: '2px' }} title={cellValue}>
            {' '}
            {cellValue}
          </span>
        </div>
      )
    } else if (columnId === 'CreateTime') {
      return (
        <div className="d-flex align-items-center">
          <span style={{ marginLeft: '2px' }}>
            {' '}
            {cellValue ? moment(cellValue).format('DD.MM.YYYY') : ''}
          </span>
        </div>
      )
    } else {
      return (
        <div className="d-flex align-items-center" title={cellValue}>
          <span style={{ marginLeft: '2px' }}> {cellValue}</span>
        </div>
      )
    }
  }

  const getCellClass = (cell, scenario) => {
    const { row, column: { header } = {} } = cell
    if (row) {
      const { original: { Id } = {} } = row
      return scenario.Id === Id ? 'row-selected' : ''
    }
  }

  const getExpanderCell = (cell) => {
    const { row } = cell
    const { getIsExpanded = () => {} } = row
    const $icon = getIsExpanded() ? 'minus' : 'plus'
    return (
      <div className="expander-cell me-1">
        <i className={`fa fa-${$icon}-square`} />
      </div>
    )
  }

  const getStatusCell = (cell) => {
    const {
      row: {
        original = {},
        original: { MotherId }
      }
    } = cell
    const cellValue = cell.getValue()

    if (cellValue !== 'FOLDER') {
      const filteredStatus = availableStatus.filter(
        (option) => option !== 'FOLDER' && option !== 'ASSORTMENT'
      )
      const options = _.map(filteredStatus, (item) => {
        return { value: item, label: item }
      })
      const value = cellValue === 'ASSORTMENT' ? 'EDITING' : cellValue
      const selectedValue = { value, label: value }
      if (isStatusChangeable) {
        return (
          <SlvySelect
            className="status-select w-100"
            classNamePrefix="status-select"
            menuPlacement="bottom"
            menuPortalTarget={document.body}
            options={options}
            placeholder="select"
            value={selectedValue}
            onChange={(e) => {
              props.onLibraryTreeUpdateNodeHandler(e.value, original, MotherId)
            }}
          />
        )
      }
    }
    return <div className="d-flex align-items-center" />
  }

  const handleSelectTableRow = (row, event) => {
    // TODO _valuesCache check
    dispatch(
      selectScenario({
        dataKey: dataKey,
        currentScenario: {
          ...row.original,
          isSelected: true,
          Status: row._valuesCache.Status
        }
      })
    )

    dispatch(selectScenarioName({ dataKey: dataKey, scenarioName: row.original.Name }))

    dispatch(
      toggleScenarioSelection({
        dataKey: dataKey,
        scenarioName: row.original.Name,
        currentScenario: {
          ...row.original,
          Status: row._valuesCache.Status
        },
        isFolderSelected: row._valuesCache.Status === 'FOLDER'
      })
    )
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'Name',
        header: 'Scenario Name',
        sortable: true,
        minSize: 500,
        cell: getCell
      },
      {
        accessorKey: 'Status',
        header: 'Status',
        sortable: true,
        maxSize: 130,
        cell: getStatusCell
      },
      {
        accessorKey: 'Description',
        header: 'Description',
        sortable: true,
        minSize: 100,
        cell: getCell
      },
      {
        accessorKey: 'UserName',
        header: 'User Name',
        sortable: true,
        cell: getCell
      },
      {
        accessorKey: 'CreateTime',
        header: 'Create Time',
        sortable: true,
        maxSize: 100,
        cell: getCell
      }
    ],
    []
  )

  const mainNode = useMemo(
    () => [
      {
        Name: 'All Assortments',
        Description: '',
        UserName: '',
        Status: 'FOLDER',
        Id: 0,
        CreateTime: null,
        Children: _.isNil(recentAssortments) ? [root] : _.concat(recentAssortments, root)
      }
    ],
    [root]
  )

  return (
    <div className="scenario-container">
      <div className="sc-wrapper">
        <h1>{title}</h1>
        <div className="al-list">
          <SlvyTable
            customExpander
            enableGlobalFilter
            rowSelection
            suppressColumnMenu
            className="tableHover"
            columns={columns}
            data={mainNode}
            getCellClassName={(cell) => getCellClass(cell, selectedCurrentScenario)}
            getSubRows={(row) => row.Children}
            globalFilterStyle={{
              borderWidth: '0px',
              fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
              fontSize: '12px',
              padding: '3px 7px',
              width: '100%'
            }}
            initialState={{
              expanded: expandedRows
            }}
            onSelectRow={handleSelectTableRow}
          />
        </div>
      </div>
    </div>
  )
}

export default ScenarioTreeList
