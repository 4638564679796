export default `({
    plugins: [{
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
                beforeedit: function (editor, context) {
                    rec = context.record;
                    return rec.get('RemainingDaysForApprove')>0;
                },
                edit: function (editor, context, eOpts) {
                    if (context.originalValue == context.value)
                        return;
                    var record = context.record,
                        grid = context.grid.ownerCt
                    //    grid.setLoading(tt('yükleniyor'));
                    record.save({
                        params: {
                            updateAction: 'DCValueChange'
                        },
                        failure: function (record, operation) {},
                        success: function (record, operation) {
                            grid.setLoading(false)
                            //  grid.store.load()
                        },
                        callback: function (record, operation, success) {
                            // do something whether the save succeeded or failed
                        }
                    });
                },
                scope: 'this'
            }
        },
        {
            ptype: 'rowexpander',
            rowBodyTpl: [
                '<div class="expander">',
                '<table class="table">',
                '<tr>',
                '</tr>',
                '  <tr>',
                '       <th class=r>'+tt('Merkez')+'</th>',
                '      <td class="r">{OrderRecommendationParent}</td>',
                '       <th class="r">'+tt('Bölge')+'</th>',
                '      <td class="r">{OrderRecommendationCurrent}</td>',
                '      <th class="r">'+tt('Satış Md.')+'</th>',
                '      <td class="r">{OrderRecommendationChild}</td>',
                '   </tr>',
                '</table>',
                '</div>',
            ]
        },
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    columnLines: true,
    multiColumnSort: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [{
        text: tt('MaterialCode'),
        dataIndex: 'MaterialCode',
        width: 100,
        filter: {
            type: 'string'
        },
        renderer: function (v) {
            return v * 1;
        }
    },{
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('Spot Başlangıç Tarihi'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('Spot Bitiş Tarihi'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            filter: {
                type: 'date'
            },
            hiden: true,
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            filter: {
                type: 'number'
            },
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('UpdatedRecommendation'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 150,
            format: '000.00',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn',
            tdCls: 'blue',
            editor: {
                // completeOnEnter: false,
                // If the editor config contains a field property, then
                // the editor config is used to create the Ext.grid.CellEditor
                // and the field property is used to create the editing input field.
                field: {
                    xtype: 'numberfield',
                    allowBlank: false
                }
            },
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
    ],
})
`
