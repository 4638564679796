import { useCallback, useState, useMemo } from 'react'
import { FilterChangedEvent, GridOptions } from 'ag-grid-community'
import { preparePluginFilters } from '../../helpers/index'
import { emptyArray, emptyObject } from '../../constants'
import { HandleFilterProps } from './useHandleFilter.types'
import { IRowData } from '../../types'

function useHandleFilter(props: HandleFilterProps) {
  const {
    gridRef,
    configColumns,
    isPaginationEnabled,
    setFilteredRowCount,
    setCurrentPage,
    fieldConfigs
  } = props

  const [filterModel, setFilterModel] = useState({})

  const onFilterChanged: GridOptions<IRowData>['onFilterChanged'] = useCallback(
    (event: FilterChangedEvent) => {
      const $filterModel = event.api.getFilterModel() || emptyObject

      if (isPaginationEnabled) {
        setCurrentPage(1)
        setFilterModel($filterModel)
      }

      const rowCount = gridRef?.current?.api?.getDisplayedRowCount()
      if (typeof rowCount === 'number') {
        setFilteredRowCount(rowCount)
      }
    },
    [isPaginationEnabled, gridRef, setCurrentPage, setFilteredRowCount]
  )

  const pluginFilters = useMemo(() => {
    if (!isPaginationEnabled) {
      return emptyArray
    }
    const pluginFiltersResult = preparePluginFilters({
      configColumns,
      fieldConfigs,
      filterModel,
      gridRef
    })

    // prevent creating a new empty array (reference) if there is no filter
    return pluginFiltersResult.length ? pluginFiltersResult : emptyArray
  }, [configColumns, fieldConfigs, filterModel, gridRef, isPaginationEnabled])

  return { onFilterChanged, filterModel, pluginFilters }
}

export default useHandleFilter
