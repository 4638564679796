import { isEqual } from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelectorCreator, lruMemoize } from 'reselect'
import { styles as stylesSchema } from '../schema'
import { Config } from '../ChronoTimeline.types'

const {
  fontSize: {
    properties: {
      timeline: {
        properties: {
          title: { default: defaultTimelineTitleFontSize },
          icon: { default: defaultTimelineIconFontSize }
        }
      },
      card: {
        properties: {
          title: { default: defaultCardTitleFontSize },
          text: { default: defaultCardTextFontSize },
          author: { default: defaultCardAuthorFontSize }
        }
      }
    }
  },
  color: {
    properties: {
      timeline: {
        properties: {
          title: { default: defaultTimelineTitleColor },
          activeTitle: { default: defaultTimelineActiveTitleColor },
          background: { default: defaultTimelineBackgroundColor },
          line: { default: defaultTimelineLineColor },
          icon: { default: defaultTimelineIconColor }
        }
      }
    }
  }
} = stylesSchema.properties

const selectRawStylesConfig = (stylesConfig?: Config['styles']) => stylesConfig

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

const selectStylesConfigs = createDeepEqualSelector(selectRawStylesConfig, (stylesConfig) => {
  const fontSize = {
    timeline: {
      title: stylesConfig?.fontSize?.timeline?.title ?? defaultTimelineTitleFontSize,
      icon: stylesConfig?.fontSize?.timeline?.icon ?? defaultTimelineIconFontSize
    },
    card: {
      title: stylesConfig?.fontSize?.card?.title ?? defaultCardTitleFontSize,
      text: stylesConfig?.fontSize?.card?.text ?? defaultCardTextFontSize,
      author: stylesConfig?.fontSize?.card?.author ?? defaultCardAuthorFontSize
    }
  }

  const color = {
    timeline: {
      title: stylesConfig?.color?.timeline?.title ?? defaultTimelineTitleColor,
      activeTitle: stylesConfig?.color?.timeline?.activeTitle ?? defaultTimelineActiveTitleColor,
      background: stylesConfig?.color?.timeline?.background ?? defaultTimelineBackgroundColor,
      line: stylesConfig?.color?.timeline?.line ?? defaultTimelineLineColor,
      icon: stylesConfig?.color?.timeline?.icon ?? defaultTimelineIconColor
    }
  }

  return {
    fontSize,
    color
  } satisfies Config['styles']
})

export default selectStylesConfigs
