Ext.define('Assortment.cmp.TemplateColumn2', {
  extend: 'Ext.grid.column.Template',

  xtype: 'imgcolumn2',
  width: 200,
  align: 'center',
  text: 'Style List',

  defaultRenderer: function (value, meta, record, a, b, c, grid) {
    var vm = grid.lookupReferenceHolder().getViewModel()
    var assortmentData = vm.get('AssortmentData')
    var stores = assortmentData.Stores

    var currentStores = stores.filter(function (store) {
      if (store.SegmentId == this.segmentId) return store
    }, this)

    var data = Ext.apply({}, record.data, record.getAssociatedData())

    var currentSegmentOption = data.SegmentOptions[this.segmentId]
    var buyQuantityFormatted = Ext.util.Format.number(currentSegmentOption.BuyQuantity, '0,000')

    Ext.apply(data, {
      numberOfStores: currentStores.length,
      activeNumberOfStores:
        currentStores.length -
        (currentSegmentOption.ExcludedStoreIds ? currentSegmentOption.ExcludedStoreIds.length : 0),
      currentSegmentOption: currentSegmentOption,
      buyQuantityFormatted: buyQuantityFormatted
    })

    return this.tpl.apply(data)
  },

  tpl: new Ext.XTemplate(
    '<tpl for=".">',
    '<div class="optcontainer">',
    '<tpl if="currentSegmentOption.Enabled">',
    '<div class="text"><b># Stores</b>: {activeNumberOfStores}/{numberOfStores}<br>',
    '<span class="dates">{currentSegmentOption.StartDate:date("d/m/Y")}-{currentSegmentOption.EndDate:date("d/m/Y")}</span><br>',
    '<span class="dates"><b>Buy Qty</b>: {buyQuantityFormatted}</span>',
    '</div>',
    '<div class="bottom"><img src="{ImageUrl}" /></div>',
    '<tpl else>',
    '<div class="click-to-add">Click to Add Product</div>',
    '</tpl>',
    '</div>',
    '</tpl>',
    {
      formatNumber: function (value) {
        return Ext.util.Format.number(value, '0.00')
      }
    }
  )
})
