import { useEffect, useState } from 'react'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { User } from 'oidc-client'
import { lruMemoize, createSelectorCreator } from 'reselect'
import numeral from '../locales/numeral'

import 'moment/min/locales'
import 'numeral/locales'

import { useAppSelector } from './store'

const DEFAULT_LOCALE = 'en'

interface Locales {
  language?: string
  culture?: string
  momentLocale?: string
  numeralLocale?: string
  defaultLocale: string
}

interface UserLocale {
  user?: User
  locales: Locales
}

// TODO
const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)
const selectOidcUser = (state: any) => state.oidc.user
const userSelector = createDeepEqualSelector([selectOidcUser], (user) => user)

const useUserLocale = (): UserLocale => {
  const user = useAppSelector(userSelector)
  const [locales, setLocales] = useState<Locales>({
    defaultLocale: DEFAULT_LOCALE
  })

  useEffect(() => {
    if (!user) {
      return
    }

    const { access_token: accessToken } = user
    const session: any = jwtDecode(accessToken)
    const language = _.first(_.split(session.culture, '-', 1))?.toLowerCase()
    const culture = session.culture?.toLowerCase()

    const numeralLocales = _.keys(numeral.locales)
    const numeralLocale =
      numeralLocales.find((l) => l.toLowerCase() === culture) ||
      numeralLocales.find((l) => l.toLowerCase() === language) ||
      DEFAULT_LOCALE
    numeral.locale(numeralLocale)

    const momentLocales = moment.locales()
    const momentLocale =
      momentLocales.find((l) => l.toLowerCase() === culture) ||
      momentLocales.find((l) => l.toLowerCase() === language) ||
      DEFAULT_LOCALE
    moment.locale(momentLocale)

    setLocales({
      language,
      culture,
      momentLocale,
      numeralLocale,
      defaultLocale: DEFAULT_LOCALE
    })
  }, [user])

  return { user, locales }
}

export default useUserLocale
