import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import { HeaderProps } from './Header.types'

export default function Header({
  ref,
  children,
  className,
  style,
  isSorted,
  isPinned,
  theme,
  onClick
}: HeaderProps) {
  const styles = useTheme()

  return (
    <div
      ref={ref}
      className={cx(
        styles.header,
        { [styles.sortAsc]: isSorted === 'asc' },
        { [styles.sortDesc]: isSorted === 'desc' },
        { [styles.isPinned]: isPinned },
        className
      )}
      style={style}
      onClick={onClick}
    >
      {children}
      {theme === 'sencha-grid' ? (
        <i
          className={cx(
            styles.headerSortIcon,
            { 'fa fa-fw fa fa-long-arrow-up': isSorted === 'asc' },
            { 'fa fa-fw fa fa-long-arrow-down': isSorted === 'desc' }
          )}
        />
      ) : null}
    </div>
  )
}
