import { getCalculatedNewValue } from '../../index'

describe('AgGrid:MassUpdate:Helpers:Common:getCalculatedNewValue', () => {
  it('should increment value with the given number if numberTypeOptionValue is increment', () => {
    const calculatedNewValue = getCalculatedNewValue({
      numberTypeOptionValue: 'increment',
      oldValue: 3,
      newValue: 5
    })
    expect(calculatedNewValue).toBe(8)
  })
  it('should overwrite value with the given number if numberTypeOptionValue is overwrite', () => {
    const calculatedNewValue = getCalculatedNewValue({
      numberTypeOptionValue: 'overwrite',
      oldValue: 3,
      newValue: 5
    })
    expect(calculatedNewValue).toBe(5)
  })
  it('should return 0.15 if the given number is 5 numberTypeOptionValue is percentage', () => {
    const calculatedNewValue = getCalculatedNewValue({
      numberTypeOptionValue: 'percentage',
      oldValue: 3,
      newValue: 5
    })
    expect(calculatedNewValue).toBe(0.15)
  })
})
