import React from 'react'
import { Marker, Tooltip, CircleMarker } from 'react-leaflet'
import _ from 'lodash'

class MapElement extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      props: {
        shape,
        color,
        position,
        iconSize,
        weight,
        opacity,
        onClick,
        tooltipOpen,
        isHtmlTooltip,
        tooltip,
        locationType,
        draggable,
        icon,
        dragstart,
        dragend
      },
      markerRef
    } = this

    if (shape !== 'Marker') {
      return (
        <CircleMarker
          ref={markerRef}
          center={position}
          color={color}
          fillOpacity={opacity}
          radius={iconSize}
          weight={weight}
          onClick={onClick}
        >
          {tooltipOpen && tooltip.length > 0 && (
            <Tooltip direction="left" minWidth={70} offset={[0, 0]} opacity={1}>
              <div>
                {isHtmlTooltip ? (
                  <div dangerouslySetInnerHTML={{ __html: tooltip }} />
                ) : (
                  _.map(tooltip, (tool, index) => {
                    return (
                      <div
                        key={index}
                        className="row"
                        style={{ display: tool.isVisible ? 'block' : 'none' }}
                      >
                        <div className="col-md-12" style={{ color: 'black' }}>
                          {' '}
                          <b style={{ color: 'orange' }}> {tool.name}:</b>{' '}
                          {tool.value !== undefined ? tool.value : ''}
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </Tooltip>
          )}
        </CircleMarker>
      )
    }
    return (
      <Marker
        ref={markerRef}
        draggable={draggable}
        icon={icon}
        locationType={locationType}
        position={position}
        onClick={onClick}
        onDragEnd={dragend}
        onDragStart={dragstart}
      >
        {tooltipOpen && tooltip.length > 0 && (
          <Tooltip direction="left" minWidth={70} offset={[0, 0]} opacity={1}>
            <div>
              {isHtmlTooltip ? (
                <div dangerouslySetInnerHTML={{ __html: tooltip }} />
              ) : (
                _.map(this.props.tooltip, (tool, index) => {
                  return (
                    <div
                      key={index}
                      className="row"
                      style={{ display: tool.isVisible ? 'block' : 'none' }}
                    >
                      <div className="col-md-12" style={{ color: 'black' }}>
                        {' '}
                        <b style={{ color: 'orange' }}> {tool.name}:</b>{' '}
                        {tool.value !== undefined ? tool.value : ''}
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </Tooltip>
        )}
      </Marker>
    )
  }
}

export default MapElement
