import { useRef, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'

interface EventRef {
  assortmentApproved?: () => void
}

const useRegisterEventAssortmentApproved = (registerEvent) => {
  const assortmentApprovedEventRef = useRef<EventRef>({})

  useEffect(() => {
    assortmentApprovedEventRef.current.assortmentApproved = registerEvent({
      key: 'AssortmentApproved',
      fn: (data) => {
        const { AssortmentId = 0, ClusterNodeId = 0 } = data || {}
        return { AssortmentId, ClusterNodeId, refreshKey: uuidv4() }
      },
      returnTypes: {
        AssortmentId: PluginTypes.int,
        ClusterNodeId: PluginTypes.int,
        refreshKey: PluginTypes.string
      }
    })
  }, [registerEvent])

  return { assortmentApprovedEventRef }
}

export default useRegisterEventAssortmentApproved
