import { intersectionWith, isEqual } from 'lodash'
import { IRowDataGrid } from '../../../../types'
import { Option } from '../../../CellEditors/LookupFieldCellEditor/LookupFieldCellEditor.types'
import { getLookupValuesFromString } from '../../../../helpers'

const getFullLookupFieldData = (
  selectedRows: IRowDataGrid[],
  lookupDataField: string
): Option[] => {
  let fullLookupFieldData: Option[] | undefined = []

  selectedRows.forEach((item) => {
    const typeofValue = typeof item[lookupDataField]
    const isString = typeofValue === 'string'
    const isNumeric = typeofValue === 'number' || typeofValue === 'bigint'
    let currentValue = item[lookupDataField] as string

    if (isString || isNumeric) {
      if (isNumeric) {
        currentValue = `${item?.[lookupDataField]}`
      }

      const fieldData = getLookupValuesFromString(currentValue)
      if (!fullLookupFieldData?.length) {
        fullLookupFieldData = fieldData
      }

      fullLookupFieldData = intersectionWith(fullLookupFieldData, fieldData ?? [], isEqual)
    }
  })

  return fullLookupFieldData
}

const getCalculatedNewValue = ({
  numberTypeOptionValue,
  oldValue,
  newValue
}: {
  numberTypeOptionValue: string
  oldValue: number
  newValue: number
}): number => {
  let calculatedNewValue

  switch (numberTypeOptionValue) {
    case 'percentage':
      calculatedNewValue = (oldValue * newValue) / 100
      break
    case 'increment':
      calculatedNewValue = oldValue + newValue
      break
    default:
      calculatedNewValue = newValue
  }

  return calculatedNewValue
}

export { getFullLookupFieldData, getCalculatedNewValue }
