import { getCalculatedNumberCellEditorParams, getNoOfDecimalPlaces } from '../index'
import {
  cellParamData as defaultCellParamData,
  columnEditing as defaultColumnEditing
} from './mocks'

function getColumnEditing() {
  return { ...defaultColumnEditing }
}

function getCellData() {
  return defaultCellParamData
}

describe('AgGrid:Helpers:Cell:getCalculatedNumberCellEditorParams', () => {
  let columnEditing = getColumnEditing()
  let cellParamData = getCellData()
  let formatString = ''

  beforeEach(() => {
    columnEditing = getColumnEditing()
    cellParamData = getCellData()
  })

  it('should return an empty object if nothing is set', () => {
    const result = getCalculatedNumberCellEditorParams({
      editing: columnEditing,
      formatString,
      cellData: cellParamData
    })
    expect(result).toEqual({})
  })

  it('should return an object with min and max if minValue and maxValue is set and convertable to number', () => {
    columnEditing.minValue = 1
    columnEditing.maxValue = 100
    const result = getCalculatedNumberCellEditorParams({
      editing: columnEditing,
      formatString,
      cellData: cellParamData
    })
    expect(result).toEqual({ min: 1, max: 100 })
  })

  it('should return min max as provided minValueField, maxValueField is set and exist in cellData and convertable to number', () => {
    columnEditing.minValueField = 'col2'
    columnEditing.maxValueField = 'col1'
    const result = getCalculatedNumberCellEditorParams({
      editing: columnEditing,
      formatString,
      cellData: cellParamData
    })
    expect(result).toEqual({
      min: Number(cellParamData.col2),
      max: Number(cellParamData.col1)
    })
  })

  it('should check if provided min whether bigger than max and convertable to number and do not add min max to the return object', () => {
    columnEditing.minValueField = 'col1'
    columnEditing.maxValueField = 'col2'
    const result = getCalculatedNumberCellEditorParams({
      editing: columnEditing,
      formatString,
      cellData: cellParamData
    })
    expect(result).toEqual({})
  })

  it('should check if step is provided and convertable to number then add step to the return object', () => {
    columnEditing.step = 10
    const result = getCalculatedNumberCellEditorParams({
      editing: columnEditing,
      formatString,
      cellData: cellParamData
    })
    expect(result).toEqual({ step: 10 })
  })

  it('should get provided stepField if it is set and exist in cellData and convertable to number', () => {
    columnEditing.stepField = 'col3'
    const result = getCalculatedNumberCellEditorParams({
      editing: columnEditing,
      formatString,
      cellData: cellParamData
    })
    expect(result).toEqual({ step: Number(cellParamData.col3) })
  })

  // NOTE: Raw value will be displayed in the cell editor so precision is removed
  // it('should add precision if formatString is truthy with its formatted version', () => {
  //   formatString = '0.00'
  //   const result = getCalculatedNumberCellEditorParams({
  //     editing: columnEditing,
  //     formatString,
  //     cellData: cellParamData
  //   })
  //   expect(result).toEqual({ precision: getNoOfDecimalPlaces('0.00') })
  // })
})
