import cx from 'classnames'
import styles from './SlvyColorPicker.module.scss'

export default function DefaultTrigger() {
  return (
    <>
      <button aria-label="colorbox container" className="w-100 p-2 border-0" type="button">
        <div className={cx('rounded-1', styles.colorBox)} data-testid="color-box" />
      </button>
      <button className={cx('p-0', 'border-0', 'border-start', 'bg-transparent')} type="button">
        <i className="fa fa-times p-1" />
      </button>
    </>
  )
}
