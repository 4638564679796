Ext.define('MDM.src.ux.GridPanel', {
  extend: 'Ext.grid.Panel',
  xtype: 'uxgrid',
  config: {
    readOnlyMode: false
  },
  applyReadOnlyMode: function (c) {
    if (!this.view) return c

    var view = this.view
    if (view.getPlugin) var dd = view.getPlugin('dd')
    if (!dd) return c

    if (c) dd.disable()
    else dd.enable()
    return c
  }
})
