import React, { Component } from 'react'
import cx from 'classnames'
import { FormGroup, Col, Row, Form } from 'react-bootstrap'
import _ from 'lodash'
import { SlvySelect, SlvySelectStyles, slvyToast } from '../../../components'

const menuPortalTarget = document.body
const defaultSelectStyles = {
  ...SlvySelectStyles.auto,
  indicatorSeparator: () => ({
    display: 'none'
  })
}

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.item.value,
      data: []
    }

    this.handleAddNewOption = this.handleAddNewOption.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.resolveComboPromise = this.resolveComboPromise.bind(this)
  }

  UNSAFE_componentWillMount() {
    const {
      item: { data }
    } = this.props
    this.resolveComboPromise(data)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.item.value
    })
  }

  resolveComboPromise(dataPromise, successCallback = () => {}) {
    if (dataPromise instanceof Promise || !_.isEmpty(dataPromise)) {
      dataPromise
        .then(({ data }) => {
          const comboData = data.result.reduce((acc, row) => {
            if (_.isNil(row.Value)) return acc
            const option = _.isNil(row.Id)
              ? { id: row.Value, value: row.Value }
              : { id: row.Id, value: row.Value }
            acc.push(option)
            return acc
          }, [])
          this.setState({ data: comboData }, () => {
            successCallback(comboData)
          })
        })
        .catch((err) => {})
    }
  }

  handleChange(option) {
    const { item, onChange } = this.props
    const label = option ? option.label : null
    onChange(item.sectionKey, item.key, label)
  }

  handleAddNewOption(newValue) {
    const newOption = { id: newValue, value: newValue }
    this.setState(
      (prevState) => ({ data: [...prevState.data, newOption] }),
      () => {
        const { onNewOptionAdd = () => {}, item, configData = {}, queryFields = [] } = this.props

        const clonedItem = _.cloneDeep(item)
        if ('data' in clonedItem) {
          delete clonedItem.data
        }

        const _queryFields = queryFields
        const _configData = configData

        const _transformedItem = _.transform(
          _queryFields,
          (result, item) => {
            result[item.fieldName] =
              clonedItem[_.findKey(_configData, (configItem) => configItem === item.fieldName)]
          },
          {}
        )

        // dispatch new option event
        onNewOptionAdd({
          ..._transformedItem,
          [configData.value]: newValue
        })

        // start update
        this.props.updateFieldLookUpData(item, (dataPromise) => {
          // listen new option promise
          this.resolveComboPromise(dataPromise, (comboData) => {
            const updatedField = comboData.find((data) => data.value === newValue)

            let { id = '' } = updatedField || {}
            id = id !== null ? id.toString() : ''

            if (id) {
              this.handleChange({
                label: id
              })
            } else {
              slvyToast.error({ message: 'New option can not added.', title: 'There is an error.' })
            }
          })
        })
      }
    )
  }

  render() {
    const {
      item,
      item: { addButton = 1 },
      sectionLeftColumn,
      sectionRightColumn,
      backgroundColor,
      getValidationState
    } = this.props

    const lightColor = backgroundColor.replace(/[^,]+(?=\))/, '0.3')
    const darkColor = backgroundColor.replace(/[^,]+(?=\))/, '0.1')
    const { data } = this.state
    let { value } = this.state
    if (item.type === 'Dropdown-Num') value = _.toNumber(value)
    const validationState = getValidationState(item, value)
    const isInvalid = validationState === 'error'

    const options = data.map((option) => ({ label: option.value, value: option.id }))
    const selectedOption = options.find((option) => option.value == item.value)
    const noOptionsCb = () => `No result found.${addButton ? 'Type to create a new option.' : ''}`
    const selectStyles = {
      ...defaultSelectStyles,
      control: (provided) => ({
        ...provided,
        minHeight: 'auto',
        height: '100%',
        width: '100%',
        borderColor: isInvalid ? '#dc3545' : '#ccc'
      })
    }

    return (
      <Row>
        <Col
          className="data-row-left"
          md={_.toNumber(sectionLeftColumn)}
          sm={_.toNumber(sectionLeftColumn)}
          style={{
            backgroundColor: lightColor
          }}
        >
          <h3>{item.title}</h3>
        </Col>
        <Col
          className="data-row-right"
          md={_.toNumber(sectionRightColumn)}
          sm={_.toNumber(sectionRightColumn)}
          style={{
            backgroundColor: darkColor
          }}
        >
          <FormGroup className="mb-3 form-group">
            <div className={cx(validationState, { 'is-invalid': isInvalid })}>
              <SlvySelect
                isClearable
                className={cx(validationState, { 'is-invalid': isInvalid })}
                isCreatable={addButton}
                isDisabled={item.isReadonly}
                menuPortalTarget={menuPortalTarget}
                noOptionsMessage={noOptionsCb}
                options={options}
                placeholder=""
                styles={selectStyles}
                value={selectedOption}
                onChange={this.handleChange}
                onCreateOption={this.handleAddNewOption}
              />
            </div>
            <Form.Control.Feedback type="invalid">{item.validationMessage}</Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>
    )
  }
}
export default Dropdown
