const $isplainobject = function (obj) {
  // Not plain objects:
  // - Any object or value whose internal [[Class]] property is not "[object Object]"
  // - DOM nodes
  // - window
  if (typeof obj !== 'object' || obj.nodeType || (obj !== null && obj === obj.window)) {
    return false
  }

  if (
    obj.constructor &&
    !Object.prototype.hasOwnProperty.call(obj.constructor.prototype, 'isPrototypeOf')
  ) {
    return false
  }

  // If the function hasn't returned already, we're confident that
  // |obj| is a plain object, created by {} or constructed with new Object
  return true
}

var $extend = function (destination) {
  let source
  let i
  let property
  for (i = 1; i < arguments.length; i++) {
    source = arguments[i]
    for (property in source) {
      if (!source.hasOwnProperty(property)) continue
      if (source[property] && $isplainobject(source[property])) {
        if (!destination.hasOwnProperty(property)) destination[property] = {}
        $extend(destination[property], source[property])
      } else {
        destination[property] = source[property]
      }
    }
  }
  return destination
}

window.JSONEditor.defaults.editors.SolvoyoMultiselectEditor =
  window.JSONEditor.defaults.editors.multiselect.extend({
    setupSelect2() {
      if (window.jQuery && window.jQuery.fn && window.jQuery.fn.select2) {
        let options = window.jQuery.extend({}, window.JSONEditor.plugins.select2)
        if (this.schema.options && this.schema.options.select2_options) {
          options = $extend(options, this.schema.options.select2_options)
        }
        this.select2 = window.jQuery(this.input).select2(options)
        const self = this
        this.select2.on('select2-blur', function () {
          const val = self.select2.select2('val')
          self.value = val
          self.onChange(true)
        })
        this.select2.on('select2:select select2:unselect', function () {
          const val = self.select2.select2('val')
          self.value = val
          self.onChange(true)
        })
      } else {
        this.select2 = null
      }
    },

    updateValue(value) {
      let changed = false
      const new_value = []
      for (let i = 0; i < value.length; i++) {
        if (!this.select_options[`${value[i]}`]) {
          changed = true
          continue
        }
        const sanitized = this.sanitize(this.select_values[value[i]])
        new_value.push(sanitized)
        if (sanitized !== value[i]) changed = true
      }
      this.value = new_value
      if (this.select2) this.select2.val(this.value).trigger('change')
      return changed
    }
  })

export default {
  name: 'SolvoyoMultiselectEditor',
  format: 'SolvoyoMultiselectEditor'
}
