import { useLocation } from 'react-router-dom'

interface IUrlLastSegment {
  urlLastSegment: string
}

function useUrlLastSegment(): IUrlLastSegment {
  const { pathname } = useLocation()
  return { urlLastSegment: pathname.split('/').pop() ?? '' }
}

export default useUrlLastSegment
