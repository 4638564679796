Ext.define('MDM.view.regionapp.MassEdit.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.mdm-view-regionapp-massedit-maincontroller',
  onBoxReady: function () {
    var selected = this.getView().selectedMaterialIds
    var s = Ext.encode(selected)
    var MaterialStore = this.getViewModel().getStore('MaterialStore'),
      DPIFamilyStore = this.getViewModel().getStore('DPIFamilyStore'),
      MaterialTypeStore = this.getViewModel().getStore('MaterialTypeStore'),
      MaterialBUOMStore = this.getViewModel().getStore('MaterialBUOMStore')
    var SupplySourceStore = this.getViewModel().getStore('SupplySourceStore')

    MaterialStore.getProxy().setExtraParam('selectedItems', s)
    this.getView().setLoading('Loading......')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/ComboData/Read',

      success: function (response) {
        MaterialStore.load()

        var res = Ext.decode(response.responseText, true)
        DPIFamilyStore.loadData(res.DPI)
        MaterialTypeStore.loadData(res.MaterialType)
        MaterialBUOMStore.loadData(res.MaterialBUOM)
        SupplySourceStore.loadData(res.SupplySource)
      },
      failure: function () {
        this.getView().setLoading(false)
      }
    })
  },
  onClose: function () {
    this.getView().close()
  },
  onBeforeLoadMaterialStore: function () {
    this.getView().setLoading('Loading......')
  },
  onLoadMaterialStore: function () {
    this.getView().setLoading(false)
  },

  onReload: function () {
    var store = this.getViewModel().getStore('MaterialStore').load()
  },
  onSave: function () {
    var me = this
    var store = this.getViewModel().getStore('MaterialStore')
    var recs = store.getModifiedRecords()
    var string = ''
    var i = 0
    recs.forEach((rec) => {
      string = string + rec.get('MaterialDescription') + '<br>'
      i++
    })
    if (i > 0) {
      MDM.src.ux.MessageBox.show({
        title: ' Save ',
        width: 500,
        closable: false,
        msg: '<br> You are about to save ' + i + ' materials   <br><br>' + string,
        buttons: Ext.Msg.YESNO,
        icon: Ext.MessageBox.QUESTION,
        fn: function (response) {
          if (response === 'yes') {
            me.dosave()
          }
        }
      })
    }
  },
  dosave: function () {
    var store = this.getViewModel().getStore('MaterialStore')
    var me = this
    me.getView().setLoading('saving data please wait.....')
    store.sync({
      failure: function () {
        me.getView().setLoading(false)
      },
      success: function () {
        me.getView().fireEvent('update', store)
        me.getView().setLoading(false)
      }
    })
  },
  onReLoad: function () {
    var store = this.getViewModel().getStore('MaterialStore')
    store.load()
  }
})
