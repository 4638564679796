import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from '../appAPI'

const initialState = {
  currentId: null,
  error: {},
  selectedStatus: '',
  tree: [],
  treeLoading: false
}

export const getTree = createAsyncThunk('app/getTree', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload
    return JSON.parse(response.text)
  } catch (err) {
    throw err
  }
})

function deleteTreeNodeBySpecificId(state, action) {
  const { currentId = null } = action.payload
  const result = {
    ...state.tree,
    Root: {
      ...state.tree.Root
    }
  }

  result.Root.Children = removeById(state.tree.Root.Children, currentId)
  return result
}

function findById(data, Id, selectedStatus) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].Id === Id) {
      data[i].Status = selectedStatus
      break
    } else if (data[i].Children && data[i].Children.length) {
      findById(data[i].Children, Id, selectedStatus)
    }
  }
  return [...data]
}

function removeById(data, Id) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].Id === Id) {
      data.splice(i, 1)
      break
    } else if (data[i].Children && data[i].Children.length) {
      removeById(data[i].Children, Id)
    }
  }
  return [...data]
}

function updateStatusBySpecificId(state, action) {
  const { currentId = null, selectedStatus = '' } = action.payload
  const isInRoot = state.tree.Root.Id === currentId
  const result = {
    ...state.tree,
    Root: {
      ...state.tree.Root
    }
  }

  if (isInRoot) {
    result.Root.Status = selectedStatus
  } else {
    result.Root.Children = findById(state.tree.Root.Children, currentId, selectedStatus)
  }
  return result
}

const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    deleteTreeNode: (state, action) => {
      state.tree = deleteTreeNodeBySpecificId({ ...state }, action)
    },
    setCurrentId: (state, action) => {
      state.currentId = action.payload
    },
    setSelectedStatus: (state, action) => {
      state.selectedStatus = action.payload
    },
    updateTreeStatus: (state, action) => {
      state.tree = updateStatusBySpecificId({ ...state }, action)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTree.pending, (state, action) => {
        state.tree = []
        state.error = {}
        state.treeLoading = true
      })
      .addCase(getTree.fulfilled, (state, action) => {
        state.tree = action.payload
        state.error = {}
        state.treeLoading = false
      })
      .addCase(getTree.rejected, (state, action) => {
        state.tree = []
        state.error = { ...action.payload }
        state.treeLoading = false
      })
  }
})

export const { deleteTreeNode, setCurrentId, setSelectedStatus, updateTreeStatus } =
  treeSlice.actions

export const currentId = (state) => {
  state.currentId
}
export const error = (state) => {
  state.error
}
export const selectedStatus = (state) => {
  state.selectedStatus
}
export const tree = (state) => {
  state.tree
}
export const treeLoading = (state) => {
  state.treeLoading
}

export default treeSlice.reducer
