import { getGroupRowRendererParams } from '..'
import { GroupRowInnerRenderer } from '../../../components/CellRenderers'
import {
  configColumns,
  fieldConfigs,
  cellDisplayRules,
  editableFields
} from '../../__tests__/mocks'
import { GetGroupRowRendererParams } from '../group.types'

const getGroupRowRenderer = (): GetGroupRowRendererParams => ({
  grouping: false,
  groupDisplayType: 'groupRows',
  suppressCount: true,
  cellDisplayRules,
  configColumns,
  fieldConfigs,
  editableFields,
  handleActionBtnClick: jest.fn()
})

describe('AgGrid:Helpers:Group:getGroupRowRendererParams', () => {
  let params = getGroupRowRenderer()

  beforeEach(() => {
    params = getGroupRowRenderer()
  })

  it('should return an empty object if grouping is false', () => {
    const result = getGroupRowRendererParams(params)

    expect(result).toEqual({})
  })

  it('should return an empty object if groupDisplayType is not groupRows', () => {
    params.groupDisplayType = 'singleColumn'
    const result = getGroupRowRendererParams(params)

    expect(result).toEqual({})
  })

  it('should return groupRowRendererParams object if grouping is true', () => {
    params.grouping = true

    const result = getGroupRowRendererParams(params)

    const expectedValue = {
      groupRowRendererParams: {
        suppressCount: params.suppressCount,
        innerRendererParams: {
          cellDisplayRules: params.cellDisplayRules,
          configColumns: params.configColumns,
          fieldConfigs: params.fieldConfigs,
          handleActionBtnClick: params.handleActionBtnClick,
          editableFields: params.editableFields
        },
        innerRenderer: GroupRowInnerRenderer
      }
    }

    expect(result).toEqual(expectedValue)
  })
})
