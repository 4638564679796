import { MarkerFormatterParams } from 'ag-grid-community'
import { getLineMarkerFormatter } from '../sparkline'
import { SparklineOptions } from '../../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'
import {
  sparklineOptions as defaultSparklineOptions,
  sparklineParams as defaultSparklineParams
} from './mocks'

function getSparklineParams() {
  return defaultSparklineParams
}

function getSparklineOptions() {
  return defaultSparklineOptions
}

describe('AgGrid:Helpers:Common:getLineMarkerFormatter', () => {
  let params: MarkerFormatterParams = getSparklineParams()
  let sparklineOptions: SparklineOptions = getSparklineOptions()

  beforeEach(() => {
    params = getSparklineParams()
    sparklineOptions = getSparklineOptions()
  })

  it('should return an object includes 3 parameters: fill and stroke which are equals to sparklineOptions.highlightColor and size is equals to 5 if params.highlighted is true', () => {
    params.highlighted = true
    sparklineOptions.highlightColor = '#f80'
    const result = getLineMarkerFormatter({ params, sparklineOptions })
    const expectation = {
      size: 5,
      fill: '#f80',
      stroke: '#f80'
    }
    expect(result).toEqual(expectation)
  })

  it('should return an object includes 3 parameters: fill and stroke which are equals to sparklineOptions.spotColor and size is equals to 3 if params.last is true', () => {
    params.highlighted = false
    params.last = true
    sparklineOptions.spotColor = '#fff'
    const result = getLineMarkerFormatter({ params, sparklineOptions })
    const expectation = {
      size: 3,
      fill: '#fff',
      stroke: '#fff'
    }
    expect(result).toEqual(expectation)
  })

  it('should return an object includes 3 parameters: fill and stroke which are equals to sparklineOptions.maxSpotColor and size is equals to 3 if params.max is true', () => {
    params.highlighted = false
    params.last = false
    params.max = true
    sparklineOptions.maxSpotColor = '#000'
    const result = getLineMarkerFormatter({ params, sparklineOptions })
    const expectation = {
      size: 3,
      fill: '#000',
      stroke: '#000'
    }
    expect(result).toEqual(expectation)
  })

  it('should return an object includes 3 parameters: fill and stroke which are equals to sparklineOptions.minSpotColor and size is equals to 3 if params.min is true', () => {
    params.highlighted = false
    params.last = false
    params.max = false
    params.min = true
    sparklineOptions.minSpotColor = '#ddd'
    const result = getLineMarkerFormatter({ params, sparklineOptions })
    const expectation = {
      size: 3,
      fill: '#ddd',
      stroke: '#ddd'
    }
    expect(result).toEqual(expectation)
  })

  it('should return an object includes 3 parameters: fill and stroke which are equals to empty string and size is equals to 0 if params parameters highlighted, last, min and max are false.', () => {
    params.highlighted = false
    params.last = false
    params.max = false
    params.min = false
    const result = getLineMarkerFormatter({ params, sparklineOptions })
    const expectation = {
      size: 0,
      fill: '',
      stroke: ''
    }
    expect(result).toEqual(expectation)
  })
})
