import { PluginGroups, SearchPluginsProps, SearchSuggestion } from './SearchPlugins.types'

const convertPluginDataForSearch = (pluginList: SearchPluginsProps['pluginListGroupByGroup']) => {
  const suggestions = []
  const keys = Object.keys(pluginList) as PluginGroups[]

  for (let i = 0, len = keys.length; i < len; i += 1) {
    const item: { title: string; suggestions: SearchSuggestion[] } = {
      title: keys[i],
      suggestions: []
    }
    const groupPlugin = pluginList[keys[i]]

    for (let k = 0, klen = groupPlugin.length; k < klen; k += 1) {
      const {
        title: pluginName,
        version: pluginVersion,
        group: pluginGroupName,
        namespace
      } = groupPlugin[k]

      // name shows up on the dashboard and value is the real plugin name to search.
      item.suggestions.push({
        type: pluginGroupName,
        name: pluginName,
        value: namespace,
        version: pluginVersion
      })
    }
    suggestions.push(item)
  }

  return suggestions
}

const convertContainerDataForSearch = (containersList: SearchPluginsProps['containersList']) => {
  const suggestions = []
  const item: { title: string; suggestions: SearchSuggestion[] } = {
    title: 'Container',
    suggestions: []
  }

  for (let j = 0, jlen = containersList.length; j < jlen; j += 1) {
    const { display: containerName = '', name = '' } = containersList[j]

    // name shows up on the dashboard and value is the real plugin name to search.
    item.suggestions.push({
      type: 'Container',
      name: containerName,
      value: name,
      version: '2.0'
    })
  }
  suggestions.push(item)

  return suggestions
}

const convertDataForSearch = (
  pluginList: SearchPluginsProps['pluginListGroupByGroup'],
  containersList: SearchPluginsProps['containersList']
) => {
  const pluginSuggestions = convertPluginDataForSearch(pluginList)
  const containerSuggestions = convertContainerDataForSearch(containersList)

  return pluginSuggestions.concat(containerSuggestions)
}

export default convertDataForSearch
