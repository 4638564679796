import { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormControl } from 'react-bootstrap'
import cx from 'classnames'
import _ from 'lodash'
import ApiClient from '../../../crudoptV3/libs/apiClient'
import { SlvySpinner, slvyToast } from '../../index'
import NotFound from './NotFound/index.ts'
import List from './List/index.ts'
import { IQuickSearchProps, IListItem, TStatus } from './QuickSearch.types'
import { settings, placeholder } from './constants.ts'
import { getPageUrl } from './helpers.tsx'
import classes from './QuickSearch.module.scss'

const client: any = new ApiClient()
const { minChars, debounceDelay, throttleDelay } = settings

const QuickSearch = ({ catalogId, environment }: IQuickSearchProps) => {
  const navigate = useNavigate()
  const [cursor, setCursor] = useState(0)
  const [data, setData] = useState<IListItem[]>([])
  const [isNotFound, setIsNotFound] = useState(false)
  const [isShow, setIsShow] = useState(true)
  const [query, setQuery] = useState('')
  const [status, setStatus] = useState<TStatus>('idle')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isCancelled = useRef(false)

  // Prevent updating state when component is no longer alive
  useEffect(() => {
    return () => {
      isCancelled.current = true
    }
  }, [])

  const toastWithDebounce = _.debounce(() => {
    slvyToast.error({
      message: 'An unknown error occurred when getting search results!',
      title: 'Quick Search'
    })
  }, 1000)

  const autocomplete = (newQuery: string) => {
    if (newQuery.length < minChars) {
      return
    }

    client
      .get(`/catalog/${catalogId}/search/${newQuery}`)
      .then((result: IListItem[]) => {
        if (isCancelled.current) {
          return
        }

        setStatus('success')

        const newResult = !_.isEmpty(result) ? result : []
        setIsNotFound(!newResult.length)
        setData(newResult)

        setIsShow(false)
      })
      .catch(() => {
        if (isCancelled.current) {
          return
        }

        setStatus('error')

        toastWithDebounce()
      })
  }

  const autocompleteWithDebounce = _.debounce(autocomplete, debounceDelay)
  const autocompleteWithThrottle = _.throttle(autocomplete, throttleDelay)

  const toggleOverlay = (show: boolean) => setIsShow(show)

  const onClear = () => {
    setCursor(0)
    setData([])
    setIsNotFound(false)
    setIsShow(true)
    setQuery('')
  }

  const checkQuery = (newQuery: string) => {
    if (_.isEmpty(newQuery)) {
      toggleOverlay(true)
      return
    }

    setStatus('pending')

    if (newQuery.length < minChars + 1) {
      autocompleteWithThrottle(newQuery)
    } else {
      autocompleteWithDebounce(newQuery)
    }
  }

  const handleRedirect = (url: string) => {
    inputRef.current.blur()
    onClear()
    navigate(url, { replace: true })
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value
      .replace(/ +/g, ' ')
      .replace(/\.|\*|\\|>|<|\+|%|\?|\/|&|:|#/g, '')
    setQuery(newQuery)
    checkQuery(newQuery)
  }

  const onFocus = () => setIsShow(false)

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event

    if ((data.length < 1 && keyCode !== 27) || query.length < 3) {
      setCursor(0)
      setData([])
      setIsNotFound(false)
      setStatus('idle')
      return
    }

    switch (keyCode) {
      case 38:
        if (cursor > 0) {
          setCursor((prevState: number) => prevState - 1)
        }
        break
      case 40:
        if (cursor < data.length - 1) {
          setCursor((prevState: number) => prevState + 1)
        }
        break
      case 13:
        handleRedirect(
          getPageUrl(catalogId, environment, data[cursor].id, data[cursor].parentMenuId)
        )
        break
      case 27:
        toggleOverlay(true)
        onClear()
        break
      default:
        break
    }
  }

  const onExit = () => {
    toggleOverlay(true)
    if (!data.length) {
      setCursor(0)
      setStatus('idle')
    }
  }

  const queryLength = query.length

  const isOpen = !isShow && queryLength > minChars - 1 && status !== 'error'

  const isQueryExist = Boolean(queryLength)

  return (
    <div
      className={cx(classes.quickSearch, 'position-relative', 'quick-search')}
      data-testid="quick-search"
    >
      <FormControl
        ref={inputRef}
        className={cx(classes.searchInput, 'position-relative', 'quick-search-input')}
        placeholder={placeholder}
        size="sm"
        type="text"
        value={query}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      {isQueryExist ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <i
          className={cx(classes.resetButton, 'cp', 'position-absolute', 'top-50', 'fa', 'fa-times')}
          data-testid="quick-search-reset-button"
          onClick={onClear}
        />
      ) : null}
      {isOpen ? (
        <div
          className={cx(
            classes.searchResults,
            'bg-white',
            'd-block',
            'overflow-hidden',
            'position-absolute',
            'w-100'
          )}
        >
          <ul
            className={cx(
              classes.list,
              'm-0',
              'ps-0',
              'default-scrollbar',
              'default-scrollbar-colored'
            )}
          >
            {isNotFound ? (
              <NotFound value={query} />
            ) : (
              <List
                catalogId={catalogId}
                cursor={cursor}
                data={data}
                environment={environment}
                handleOnClick={(url) => handleRedirect(url)}
                query={query}
              />
            )}

            {status === 'pending' ? (
              <SlvySpinner
                containerClass="opacity-75 bg-white"
                size="sm"
                testId="quick-search-loading"
              />
            ) : null}
          </ul>
        </div>
      ) : null}
      {!isShow && isQueryExist ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={cx(classes.overlay, 'position-fixed', 'bottom-0', 'start-0', 'end-0', 'top-0')}
          data-testid="quick-search-overlay"
          onClick={onExit}
        />
      ) : null}
    </div>
  )
}

export default QuickSearch
