export default `({
    xtype: 'panel',
    layout: 'border',
    bodyBorder: false,
    title: 'deneme',
    defaults: {
        collapsible: true,
        split: true,
        bodyPadding: 10
    },
    items: [{
            title: 'Footer',
            region: 'south',
            height: 500,
            html: '<p>Footer content</p>',
        },
        {
            title: 'Main Content',
            collapsible: false,
            region: 'center',
            margin: 140,
            margin: '5 0 0 0',
            xtype: 'uxcontainer',
            uxItemCfg: 'card5',
        }
    ]
})
`
