Ext.define('po.model.StoreDetailModel', {
  extend: 'po.model.Base',
  entityName: 'po/storedetail',
  fields: [
    { name: 'UrunKodu', type: 'string' },
    { name: 'Urun_Aciklama', type: 'string' },
    { name: 'Magaza', type: 'string' },
    { name: 'EldekiStok', type: 'float' },
    { name: 'AcikSiparis', type: 'float' },
    { name: 'Review_period', type: 'int' },
    { name: 'GuvenlikStogu', type: 'float' },
    { name: 'BirSonrakiTeslimataKadarTahminiSatis', type: 'float' },
    { name: 'Gunluk_Ort_Tahmin', type: 'float' },
    { name: 'Gunluk_Ort_Satis', type: 'float' },
    { name: 'SiparisOnerisi', type: 'float' },
    { name: 'StokGun', type: 'float' }
  ]
})
