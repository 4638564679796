import React, { Component, createRef } from 'react'
import _ from 'lodash'
import ScenarioSelect from './scenarioSelect'
import { MESSAGES } from '../../messages'

export default class CreateScenarioForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedScenario: {}
    }
    this.nameRef = createRef()
    this.descriptionRef = createRef()
    this.scenarioIdRef = createRef()
  }

  scenarioCheck(event) {
    event.target.setCustomValidity(
      _.isEmpty(event.target.value) ? MESSAGES.please_select_a_scenario : ''
    )
  }

  onFormClick(event) {
    event.stopPropagation()
  }

  onFormSubmit(event) {
    event.preventDefault()

    const {
      state: { selectedScenario },
      props: {
        mfpId,
        ScenarioParameters,
        includeColumnStoreIndex: IncludeColumnStoreIndex,
        onCreateFormSubmit = () => {}
      },
      refs = {}
    } = this

    const name = this.nameRef.current
    const description = this.descriptionRef.current

    const scenarioParams = !_.isEmpty(ScenarioParameters)
      ? _.map(ScenarioParameters, (item) => {
          const value = parseFloat(refs[item.FieldName].value, 10)
          const propValue = refs[`${item.FieldName}_prop`].value
          const calculatedValue = parseFloat(
            (propValue === 'inc' ? value : -1 * value) / 100 + 1,
            10
          )
          item.Value = calculatedValue === 0 ? 1 : calculatedValue
          return item
        })
      : null

    const _scenario = {
      ParentId: selectedScenario.ParentId,
      MfpId: selectedScenario.MfpId,
      Name: name.value,
      Description: description.value,
      IsScenario: true,
      ScenarioStatus: 0,
      ScenarioParameters: scenarioParams
    }

    const params = {
      mfpId,
      scenario: { ..._scenario },
      fromScenarioId: selectedScenario.Id,
      IncludeColumnStoreIndex
    }

    onCreateFormSubmit(params)
  }

  onCloseClick() {
    const { onClose = () => {} } = this.props
    onClose(false)
  }

  onScenarioSelect(selectedScenario) {
    const { Id = '' } = selectedScenario

    this.setState({
      selectedScenario
    })

    this.scenarioIdRef.current.value = Id
  }

  render() {
    const {
      props: { data = [], ScenarioParameters },
      state: { selectedScenario = {} }
    } = this

    const nameColumnStyle = { width: 145 }

    return (
      <div className="scenario-container">
        <div className="sc-wrapper -w100">
          <h1>Create Scenario</h1>
          <form
            className="scenario-create-form"
            onClick={this.onFormClick.bind(this)}
            onSubmit={this.onFormSubmit.bind(this)}
          >
            <table>
              <tbody>
                <tr>
                  <td style={nameColumnStyle}>
                    <label>{MESSAGES.name}</label>
                  </td>
                  <td>
                    <input ref={this.nameRef} required type="text" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{MESSAGES.description}</label>
                  </td>
                  <td>
                    <textarea ref={this.descriptionRef} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{MESSAGES.create_scenario_from}</label>
                  </td>
                  <td>
                    <div className="sc-wrapper">
                      <ScenarioSelect
                        data={data}
                        mode="create"
                        selectedScenario={selectedScenario}
                        onScenarioSelect={this.onScenarioSelect.bind(this)}
                      />
                      <input
                        ref={this.scenarioIdRef}
                        required
                        className="scenarioId"
                        type="number"
                        onInvalid={this.scenarioCheck}
                      />
                    </div>
                  </td>
                </tr>
                {!_.isEmpty(ScenarioParameters) && (
                  <tr>
                    <td>
                      <label>{MESSAGES.parameters_ratio}</label>
                    </td>
                    <td>
                      {_.map(ScenarioParameters, (spItem, spIndex) => {
                        return (
                          <div key={spIndex} className="f-element-group">
                            <div className="feg-cell">{spItem.DisplayName}</div>
                            <select ref={`${spItem.FieldName}_prop`} className="feg-type">
                              <option value="inc">Increase</option>
                              <option value="dec">Decrease</option>
                            </select>
                            <input
                              ref={spItem.FieldName}
                              required
                              className="feg-ratio"
                              defaultValue="0"
                              min="0"
                              step="0.01"
                              type="number"
                            />
                          </div>
                        )
                      })}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="sc-btn-group">
              <span className="cell-btn -close" onClick={this.onCloseClick.bind(this)}>
                <i className="slvy-ui-icon-angle-left" /> {MESSAGES.back}
              </span>
              <div className="push-sep" />
              <button className="cell-btn -create" type="submit">
                <i className="slvy-ui-icon-plus-circle-regular" /> {MESSAGES.create}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
