import { Alert } from 'react-bootstrap'
import _ from 'lodash'
import { ISlvyJsonTableProps } from './SlvyJsonTable.types'

const SlvyJsonTable = (props: ISlvyJsonTableProps) => {
  const { onClickRow, columns, rows, className } = props

  return (
    <div data-testid="slvy-json-table">
      {rows.length > 0 && columns.length > 0 ? (
        <table className={className}>
          <thead>
            <tr>
              {columns.map(({ label }, columnIndex) => (
                <th key={columnIndex}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody className="border-top-0">
            {rows.map((row, rowIndex) => {
              return (
                <tr
                  key={rowIndex}
                  onClick={(event) => _.isFunction(onClickRow) && onClickRow(event, row)}
                >
                  {columns.map(({ cell, key }) => {
                    return <td key={key}>{_.isFunction(cell) ? cell(row, key) : row[key]}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <Alert className="mb-0 p-2" variant="secondary">
          No items
        </Alert>
      )}
    </div>
  )
}

export default SlvyJsonTable
