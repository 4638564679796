import { useState, memo } from 'react'
import Autosuggest from 'react-autosuggest'
import {
  SuggestionSelectedEventData,
  ChangeEvent,
  SuggestionsFetchRequestedParams
} from '@types/react-autosuggest'

import { useUniqueId } from '../../hooks'

import { ISectionSuggestion, ISlvyAutosuggestProps, ISuggestion } from './SlvyAutosuggest.types'
import theme from './SlvyAutosuggest.module.scss'

const emptyObject = Object.freeze({})

const SlvyAutosuggest = ({
  data,
  field,
  placeholder,
  size = '',
  onQueryChange,
  onSuggestionNotFound,
  onSuggestionSelected: _onSuggestionSelected,
  renderSuggestionsContainer = null
}: ISlvyAutosuggestProps<ISuggestion>) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState<ISectionSuggestion<ISuggestion>[]>([])

  const [uniqueId] = useUniqueId()
  const id = `SlvyAutosuggestId-${uniqueId}`

  const getSuggestions = (text: string) => {
    if (text.trim() === '') {
      return []
    }

    return data
      .map((section) => {
        return {
          title: section.title,
          suggestions: section.suggestions.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase())
          )
        }
      })
      .filter((section) => section.suggestions.length > 0)
  }

  const getSuggestionValue = ({ name = '', prefix = '' }: ISuggestion) =>
    prefix ? `${name} ${prefix}` : name

  const renderSuggestion = ({ name = '', prefix = '' }: ISuggestion) => (
    <span>
      {name}
      {prefix && <strong className="fw-bold"> {prefix}</strong>}
    </span>
  )

  const renderSectionTitle = ({ title }: ISectionSuggestion<ISuggestion>) => (
    <strong>{title}</strong>
  )

  const getSectionSuggestions = (section: ISectionSuggestion<ISuggestion>) => section.suggestions

  const onChange = (_event: any, { newValue }: ChangeEvent) => {
    setValue(newValue)
    handleQueryChanged(newValue)
  }

  const onSuggestionsFetchRequested = ({ value: newValue }: SuggestionsFetchRequestedParams) => {
    const newSuggestions = getSuggestions(newValue)
    setSuggestions(newSuggestions)

    if (newSuggestions.length === 0) {
      handleSuggestionNotFound(newValue)
    }
  }

  const onSuggestionsClearRequested = () => setSuggestions([])

  const onSuggestionSelected = (
    _event: any,
    { suggestion, suggestionValue }: SuggestionSelectedEventData<ISuggestion>
  ) => {
    handleSuggestionSelected(suggestionValue, suggestion)
  }

  function handleQueryChanged(query: string) {
    onQueryChange({
      fieldName: field,
      options: {
        query,
        selectedSuggestion: {}
      }
    })
  }

  function handleSuggestionNotFound(query: string) {
    onSuggestionNotFound({
      fieldName: field,
      options: {
        query,
        selectedSuggestion: {}
      }
    })
  }

  function handleSuggestionSelected(query: string, suggestion: ISuggestion) {
    _onSuggestionSelected({
      fieldName: field,
      options: {
        query,
        selectedSuggestion: suggestion
      }
    })
  }

  let inputClassName = 'form-control'
  inputClassName += size ? ` form-control-${size}` : ''

  const inputProps = {
    type: 'search',
    placeholder,
    value,
    onChange,
    className: inputClassName
  }

  const optionalProps = renderSuggestionsContainer ? { renderSuggestionsContainer } : emptyObject

  return (
    <Autosuggest
      focusInputOnSuggestionClick
      multiSection
      getSectionSuggestions={getSectionSuggestions}
      getSuggestionValue={getSuggestionValue}
      id={id}
      inputProps={inputProps}
      renderSectionTitle={renderSectionTitle}
      renderSuggestion={renderSuggestion}
      suggestions={suggestions}
      theme={theme}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      {...optionalProps}
    />
  )
}

export default memo(SlvyAutosuggest)
