import {
  useRegisterMethodAddRecord,
  useRegisterMethodClearAllFilters,
  useRegisterMethodFilterClientData,
  useRegisterMethodGetConditionedRows,
  useRegisterMethodGetModifiedRows,
  useRegisterMethodResetChanges,
  useRegisterMethodSaveChanges,
  useRegisterMethodSetEditableState,
  useRegisterMethodSetEnabledState,
  useRegisterMethodSetRowValues,
  useRegisterMethodSetRowValuesWithFilter,
  useRegisterMethodSetMultiSelectRowValues,
  useRegisterMethodTriggerMultiRowSelected
} from '../index'
import { UseRegisterMethodsProps } from './useRegisterMethods.types'

function useRegisterMethods(props: UseRegisterMethodsProps) {
  const {
    conditionedRowsResultEventRef,
    fieldConfigs,
    selectionType,
    getModifiedRowsResultEventRef,
    gridRef,
    multiRowsSetEventRef,
    triggerMultiRowSelectedResultEventRef,
    updateParameters,
    applyUpdate,
    registerMethod,
    handleAddRecord,
    handleReset,
    triggerSave,
    setGridEditableByState
  } = props
  useRegisterMethodAddRecord({ registerMethod, handleAddRecord })
  useRegisterMethodClearAllFilters({ gridRef, registerMethod })
  useRegisterMethodFilterClientData({ fieldConfigs, gridRef, registerMethod })
  useRegisterMethodGetConditionedRows({ registerMethod, conditionedRowsResultEventRef })
  useRegisterMethodGetModifiedRows({ getModifiedRowsResultEventRef, registerMethod })
  useRegisterMethodResetChanges({ registerMethod, handleReset })
  useRegisterMethodSaveChanges({ updateParameters, registerMethod, triggerSave, gridRef })
  useRegisterMethodSetEditableState(registerMethod, setGridEditableByState)
  useRegisterMethodSetMultiSelectRowValues({
    applyUpdate,
    gridRef,
    fieldConfigs,
    selectionType,
    multiRowsSetEventRef,
    registerMethod
  })
  useRegisterMethodSetRowValues({
    applyUpdate,
    gridRef,
    registerMethod,
    fieldConfigs
  })
  useRegisterMethodSetRowValuesWithFilter({
    applyUpdate,
    fieldConfigs,
    gridRef,
    registerMethod
  })
  useRegisterMethodTriggerMultiRowSelected({
    registerMethod,
    triggerMultiRowSelectedResultEventRef
  })

  const { isGridReadOnly } = useRegisterMethodSetEnabledState(registerMethod)
  return { isGridReadOnly }
}

export default useRegisterMethods
