Ext.define('MDM.src.view.globalapp.Panel.Progress', {
  extend: 'Ext.Component',
  xtype: 'globalapp-progress',
  listeners: {
    click: {
      fn: 'onClickProgress',
      element: 'el',
      delegate: 'div.node'
    }
  },
  height: 80,
  frame: false,
  title: 'Light UI',
  collapsible: true,
  cls: 'wizard-main',
  bind: {
    data: '{processLine}'
  },
  tpl: [
    '<div class="wizard-progress">',
    '<tpl for=".">',
    '  <div class="step {status}"  >',
    '       {name}',
    '  <div class="node {status}" itemId="{Index}"></div>',
    '</div>',
    '</tpl>',
    '</div>'
  ],
  style: {
    background: 'background: rgba(0 ,0,0,0.08);'
  },
  header: false,
  border: false,
  margin: '0 0 2 0'
})
