export default () => {
  return {
    title: 'Pivot',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          export: {
            type: 'boolean',
            title: 'Exportable',
            default: true,
            required: true
          },
          exportWithoutHeaders: {
            type: 'boolean',
            title: 'Export Without Headers',
            default: true,
            required: true
          },
          remoteMatrix: {
            type: 'boolean',
            title: 'Remote Matrix',
            default: false,
            required: true
          }
        }
      },
      pivot: {
        type: 'object',
        title: 'Pivot',
        properties: {
          startRowGroupsCollapsed: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Start row groups collapsed'
          },
          startColGroupsCollapsed: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Start column groups collapsed'
          },
          collapseButtonEnabled: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Collapse button enabled'
          },
          subtotalsButtonEnabled: {
            options: {
              hidden: true
            },
            type: 'boolean',
            default: true,
            required: true,
            title: 'Subtotals button enabled'
          },
          totalsButtonEnabled: {
            options: {
              hidden: true
            },
            type: 'boolean',
            default: true,
            required: true,
            title: 'Totals button enabled'
          },
          stateButtonEnabled: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'State button enabled'
          },
          autoRememberState: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Remember last state'
          },
          enableLocking: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Locking'
          },
          emptyText: {
            type: 'string',
            title: 'No Data Text',
            default: 'No Data to Display'
          },
          autoTooltip: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Headers as Tooltips'
          },
          textSelection: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Text Selection',
            options: {
              hidden: true // TODO: I moved this property, therefore it is hidden
            }
          },
          publicState: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Public States'
          },
          exportState: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Exporting States'
          },
          selection: {
            type: 'object',
            title: 'Selection',
            options: {
              collapsed: true
            },
            properties: {
              type: {
                type: 'string',
                title: 'Cell Selection',
                enum: ['Row', 'Spreadsheet'],
                default: 'Row',
                required: true
              },
              textSelection: {
                type: 'boolean',
                default: true,
                required: true,
                title: 'Text Selection'
              }
            }
          },
          editing: {
            type: 'object',
            title: 'Editing',
            options: {
              collapsed: true
            },
            properties: {
              enabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              editingType: {
                type: 'string',
                title: 'Editing Type',
                enum: ['SaveButton', 'Direct', 'Trigger', 'Queue'],
                default: 'SaveButton',
                required: true
              },
              clicksToEdit: {
                type: 'number',
                title: 'Clicks to Edit',
                default: 1,
                enum: [1, 2],
                required: true
              },
              editingMethod: {
                type: 'string',
                title: 'Editing Method',
                enum: ['CellEditing', 'RangeEditor'],
                default: 'CellEditing',
                required: true
              },
              defaultUpdater: {
                type: 'string',
                title: 'Default Updater',
                enum: ['percentage', 'increment', 'overwrite', 'uniform', 'ratio', 'ratioInteger'],
                default: 'overwrite',
                required: true
              },
              lockGrid: {
                type: 'boolean',
                title: 'Lock Grid While Saving',
                default: true,
                required: true
              },
              lockGridDisaggregation: {
                type: 'boolean',
                title: 'Lock Grid While Calculation',
                default: true,
                required: true
              },
              saveButtonSettings: {
                type: 'object',
                title: 'Save Button',
                options: {
                  collapsed: true
                },
                properties: {
                  saveButtonText: {
                    type: 'string',
                    title: 'Save Button Text',
                    default: 'Save'
                  },
                  saveButtonTooltip: {
                    type: 'string',
                    title: 'Save Button Tooltip',
                    default: 'Save Changes'
                  },
                  saveButtonIcon: {
                    type: 'string',
                    title: 'Save Button Icon',
                    format: 'IconSelector',
                    default: 'fa fa-floppy-o'
                  },
                  resetButton: {
                    type: 'boolean',
                    title: 'Reset Button',
                    default: true,
                    required: true
                  },
                  resetButtonText: {
                    type: 'string',
                    title: 'Reset Button Text',
                    default: 'Reset Changes'
                  },
                  resetButtonTooltip: {
                    type: 'string',
                    title: 'Reset Button Tooltip',
                    default: 'Reset Changes'
                  },
                  resetButtonIcon: {
                    type: 'string',
                    title: 'Reset Button Icon',
                    format: 'IconSelector'
                  }
                }
              }
            }
          },
          updateParameters: {
            type: 'array',
            title: 'Update Parameters',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Parameter',
              properties: {
                name: {
                  type: 'string',
                  title: 'Name',
                  required: true
                },
                type: {
                  type: 'string',
                  title: 'Type',
                  enum: ['string', 'int', 'boolean', 'datetime'],
                  default: 'string',
                  required: true
                }
              }
            }
          },
          footerButtons: {
            type: 'array',
            title: 'Footer Buttons',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Button',
              headerTemplate: 'Button {{ self.buttonText }}',
              options: {
                collapsed: true
              },
              properties: {
                buttonText: {
                  type: 'string',
                  title: 'Text',
                  default: ''
                },
                buttonTooltip: {
                  type: 'string',
                  title: 'Tooltip',
                  default: ''
                },
                buttonIcon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector',
                  default: 'fa fa-pie-chart'
                },
                footerButtonCondition: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds'
                },
                confirmation: {
                  type: 'boolean',
                  title: 'Confirmation',
                  required: true,
                  default: false
                },
                confirmationText: {
                  type: 'string',
                  title: 'Confirmation Text',
                  default: 'Are you sure to continue?'
                },
                confirmationTitle: {
                  type: 'string',
                  title: 'Confirmation Title',
                  default: 'Confirmation'
                }
              }
            }
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },
      matrix: {
        type: 'object',
        title: 'Matrix',
        properties: {
          viewLayoutType: {
            title: 'View layout type',
            options: {
              disable_collapse: true
            },
            oneOf: [
              {
                title: 'Outline',
                type: 'object',
                properties: {
                  viewLayoutType: {
                    type: 'string',
                    enum: ['outline'],
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['viewLayoutType'],
                additionalProperties: false
              },
              {
                title: 'Compact',
                type: 'object',
                properties: {
                  viewLayoutType: {
                    type: 'string',
                    enum: ['compact'],
                    options: {
                      hidden: true
                    }
                  },
                  compactViewColumnWidth: {
                    title: 'Compact view column width',
                    type: 'number',
                    default: 100
                  }
                },
                required: ['viewLayoutType']
              },
              {
                title: 'Tabular',
                type: 'object',
                properties: {
                  viewLayoutType: {
                    type: 'string',
                    enum: ['tabular'],
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['viewLayoutType'],
                additionalProperties: false
              }
            ]
          },
          textRowLabels: {
            type: 'string',
            title: 'Text row labels'
          },
          textGrandTotalTpl: {
            type: 'string',
            title: 'Grand total text',
            default: 'Grand total'
          },
          rowSubTotalsPosition: {
            type: 'string',
            title: 'Row subtotal position',
            enum: ['first', 'none', 'last'],
            default: 'first',
            required: true
          },
          colSubTotalsPosition: {
            type: 'string',
            title: 'Column subtotal position',
            enum: ['first', 'none', 'last'],
            default: 'first',
            required: true
          },
          rowGrandTotalsPosition: {
            type: 'string',
            title: 'Row total position',
            enum: ['first', 'none', 'last'],
            default: 'first',
            required: true
          },
          colGrandTotalsPosition: {
            type: 'string',
            title: 'Column total position',
            enum: ['first', 'none', 'last'],
            default: 'first',
            required: true
          },
          collapsibleColumns: {
            type: 'boolean',
            title: 'Collapsible Columns',
            default: true,
            required: true
          },
          collapsibleRows: {
            type: 'boolean',
            title: 'Collapsible Rows',
            default: true,
            required: true
          }
        }
      },
      dynamicAggregators: {
        type: 'array',
        title: 'Custom Aggregators',
        items: {
          type: 'object',
          title: 'Custom Aggregator',
          headerTemplate: '{{ self.name }}',
          options: {
            collapsed: true
          },
          properties: {
            name: {
              type: 'string',
              title: 'Name',
              required: true
            },
            dynamicAggregatorFunc: {
              type: 'string',
              format: 'Code',
              title: 'Aggregator Function',
              options: {
                mode: 'javascript',
                lineNumbers: true,
                readOnly: false,
                base64: true
              }
            }
          }
        }
      },
      aggregate: {
        type: 'array',
        title: 'Aggregates',
        items: {
          type: 'object',
          title: 'Aggregate column',
          headerTemplate: '{{ self.dataIndex }}',
          options: {
            collapsed: true
          },
          properties: {
            dataIndex: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            header: {
              type: 'string',
              title: 'Header'
            },
            tooltip: {
              type: 'string',
              title: 'Tooltip'
            },
            aggregator: {
              type: 'string',
              title: 'Aggregation function',
              enum: [
                'avg',
                'max',
                'min',
                'sum',
                'stdDev',
                'stdDevP',
                'variance',
                'varianceP',
                'groupSumPercentage'
              ],
              default: 'sum',
              required: true
            },
            customAggregator: {
              type: 'string',
              title: 'Custom aggregation function',
              enumSource: [
                {
                  source: [
                    { name: '' },
                    { name: 'Yorsan_PRODUCT_COSTSUM_Plus_PRODUCT_COSTAVG' },
                    { name: 'Yorsan_PRODUCT_COSTSUM_Times_Two' },
                    { name: 'AlwaysFive' },
                    { name: 'AlwaysTen' },
                    { name: 'UniqueLeftCategories' },
                    { name: 'Gillette_DemandByRegion_ChangeInDemand' },
                    { name: 'Gillette_performanceComparison' },
                    { name: 'Gillette_productionComparison' },
                    { name: 'Renault_WaitingTime' },
                    { name: 'Renault_TransportLeadTime' },
                    { name: 'Renault_Totaltime' },
                    { name: 'Yorsan_Bulunabilirlik' },
                    { name: 'Yorsan_StokGun_GERCEKLESENEGORE' },
                    { name: 'Yorsan_StokGun_SON30GUNEGORE' },
                    { name: 'Yorsan_StokGun_GELECEK1HAFTAYAGORE' },
                    { name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_TONAJ' },
                    { name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_MALIYET' },
                    { name: 'DogtasKelebek_SapmaOrani' },
                    { name: 'DogtasKelebek_SapmaOrani_Takimi' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi' },
                    { name: 'DogtasKelebek_ButceKarsilastirma' },
                    { name: 'DogtasKelebek_TahminFark' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi_2' },
                    { name: 'Defacto_Difference' },
                    { name: 'Defacto_DIFFERENCEpercent' },
                    { name: 'DogtasKelebek_Unfull_Percent' },
                    { name: 'DogtasKelebek_Start_StockDay' },
                    { name: 'DogtasKelebek_End_StockDay' },
                    { name: 'Penti_Store_Stock_Cover' },
                    { name: 'Penti_WH_Stock_Cover' },
                    { name: 'Penti_Availability' },
                    { name: 'Penti_Rep_Sum_StoreCover' },
                    { name: 'Penti_Rep_Sum_WHCover' },
                    { name: 'DogtasKelebek_Unfull_Percent_Sol' },
                    { name: 'DogtasKelebek_End_StockDay_Sol' },
                    { name: 'DogtasKelebek_UYSapmaOrani_Takim_2' },
                    { name: 'DogtasKelebek_SatisSapmaOrani_Takim_2' },
                    { name: 'DogtasKelebek_SolvoyoSapmaOrani_Takim_2' },
                    { name: 'Penti_TotalStoreStock_Change' },
                    { name: 'Penti_Kiriklik_Option_Per' },
                    { name: 'Penti_Capacity_Usage' },
                    { name: 'Gillette_InventorybyRegion_Change' },
                    { name: 'HB_DefinedAttrPer' },
                    { name: 'HB_AvgSalesPrice' },
                    { name: 'HB_BudgetPerform' },
                    { name: 'HB_StockDay' },
                    { name: 'HB_FreeCashDay' },
                    { name: 'HB_AggTerm' },
                    { name: 'HB_AggTermActual' },
                    { name: 'HB_FreeCashDayActual' },
                    { name: 'Penti_Availability_Kisitsiz' },
                    { name: 'Defacto_IPO' },
                    { name: 'Unilever_Supply_CoverKG' },
                    { name: 'Unilever_Supply_CoverValue' },
                    { name: 'Unilever_Supply_MinCoverKG' },
                    { name: 'Unilever_Supply_MinCoverValue' },
                    { name: 'Unilever_Supply_MaxCoverKG' },
                    { name: 'Unilever_Supply_MaxCoverValue' },
                    { name: 'Unilever_Supply_MinCover2KG' },
                    { name: 'Unilever_Supply_MinCover2Value' },
                    { name: 'Unilever_Planners_CoverKG' },
                    { name: 'Unilever_Planners_CoverValue' },
                    { name: 'Unilever_Supply_MinCoverKG_Diff' },
                    { name: 'Unilever_Supply_MinCover2KG_Diff' },
                    { name: 'Unilever_Supply_MaxCoverKG_Diff' },
                    { name: 'Unilever_Supply_MinCoverValue_Diff' },
                    { name: 'Unilever_Supply_MinCover2Value_Diff' },
                    { name: 'Unilever_Supply_MaxCoverValue_Diff' },
                    { name: 'Unilever_Planners_MinCoverKG_Diff' },
                    { name: 'Unilever_Planners_MinCover2KG_Diff' },
                    { name: 'Unilever_Planners_MaxCoverKG_Diff' },
                    { name: 'Unilever_Planners_MinCoverValue_Diff' },
                    { name: 'Unilever_Planners_MinCover2Value_Diff' },
                    { name: 'Unilever_Planners_MaxCoverValue_Diff' },
                    { name: 'yatsan_devirhizi_teshiregoreciro' },
                    { name: 'yatsan_devirhizi_toplamagoreciro' },
                    { name: 'yatsan_devirhizi_teshiregoreadet' },
                    { name: 'yatsan_devirhizi_toplamagoreadet' },
                    { name: 'MD_DegisimStok' },
                    { name: 'MD_DegisimSatisAdet' },
                    { name: 'MD_DegisimSatisTutar' },
                    { name: 'MD_IndirimOncesiCover1' },
                    { name: 'MD_IndirimSonrasiCover1' },
                    { name: 'MD_DegisimGrossProfit' },
                    { name: 'MD_DegisimCOGS' },
                    { name: 'MD_DegisimCover' },
                    { name: 'IndirimOncesiGMROI1' },
                    { name: 'IndirimSonrasiGMROI1' },
                    { name: 'DegisimGMROI' },
                    { name: 'IndirimOncesiCOGSPer' },
                    { name: 'IndirimSonrasiCOGSPer' },
                    { name: 'COSGPerDegisim' },
                    { name: 'IndirimOncesiGrossMarginPer' },
                    { name: 'IndirimSonrasiGrossMarginPer' },
                    { name: 'DegisimGrossMarginPer' },
                    { name: 'IndirimOncesiAvgPrice' },
                    { name: 'IndirimSonrasiAvgPrice' },
                    { name: 'DegisimPricePer' },
                    { name: 'IndirimOncesiAvgCost' },
                    { name: 'IndirimSonrasiAvgCost' },
                    { name: 'IndirimOncesiAvgMargin' },
                    { name: 'IndirimSonrasiAvgMargin' },
                    { name: 'DegisimMarginPer' },
                    { name: 'SLVY_Dogruluk' },
                    { name: '1.SLVY_Dogruluk' },
                    { name: '2.SLVY_Dogruluk' },
                    { name: '3.SLVY_Dogruluk' },
                    { name: '4.SLVY_Dogruluk' },
                    { name: '5.SLVY_Dogruluk' },
                    { name: 'Stok/Satis' },
                    { name: 'Penti_Store_Stock_Cover_Prev' },
                    { name: 'Penti_WH_Stock_Cover_Prev' },
                    { name: 'Gillette_UnconsVsCons' },
                    { name: 'Penti_Store_Stock_Cover_Change' },
                    { name: 'Penti_WH_Stock_Cover_Change' },
                    { name: 'Gillette_Scenarios' },
                    { name: 'GrandTotal_Ratio' }
                  ],
                  title: '{{item.name}}',
                  value: '{{item.name}}'
                },
                {
                  source: 'aggregators',
                  value: '{{item.name}}'
                }
              ],
              watch: {
                aggregators: 'dynamicAggregators'
              },
              default: '',
              required: true
            },
            width: {
              type: 'number',
              title: 'Width',
              default: 70
            },
            flex: {
              type: 'number',
              title: 'Flex',
              default: 0
            },
            showZeroAsBlank: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Show zero as blank'
            },
            aggregators: {
              title: 'Available aggregation functions',
              type: 'array',
              format: 'select',
              uniqueItems: true,
              required: true,
              items: {
                type: 'string',
                enum: ['avg', 'max', 'min', 'sum', 'stdDev', 'stdDevP', 'variance', 'varianceP']
              }
            },
            visibleByDefault: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Visible by default'
            },
            fixed: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Fixed'
            },
            formatField: {
              type: 'string',
              title: 'Format Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            editing: {
              type: 'object',
              title: 'Editing',
              options: {
                collapsed: true
              },
              properties: {
                enabled: {
                  type: 'boolean',
                  title: 'Enabled',
                  default: false,
                  required: true
                },
                defaultUpdater: {
                  type: 'string',
                  title: 'Default Updater',
                  enum: [
                    'percentage',
                    'increment',
                    'overwrite',
                    'uniform',
                    'ratio',
                    'ratioInteger',
                    'increasePercentage',
                    'decreasePercentage'
                  ],
                  default: 'overwrite',
                  required: true
                },
                editingStep: {
                  type: 'number',
                  title: 'Step',
                  default: 1
                },
                disaggregation: {
                  type: 'array',
                  default: [
                    'percentage',
                    'increment',
                    'overwrite',
                    'uniform',
                    'ratio',
                    'ratioInteger',
                    'increasePercentage',
                    'decreasePercentage'
                  ],
                  title: 'Available Disaggregations',
                  items: {
                    type: 'string',
                    enum: [
                      'percentage',
                      'increment',
                      'overwrite',
                      'uniform',
                      'ratio',
                      'ratioInteger',
                      'increasePercentage',
                      'decreasePercentage'
                    ],
                    default: 'overwrite'
                  },
                  required: true
                },
                maxValue: {
                  type: 'NullableNumber',
                  title: 'Maximum Value',
                  default: null
                },
                minValue: {
                  type: 'NullableNumber',
                  title: 'Minimum Value',
                  default: null
                },
                editableCondition: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  required: true
                },
                lockingCondition: {
                  type: 'string',
                  title: 'Locking based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  required: true
                },
                checkboxField: {
                  type: 'string',
                  title: 'Checkbox based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  required: true
                },
                checkboxFieldIconOn: {
                  type: 'string',
                  title: 'Checkbox Icon On',
                  format: 'IconSelector',
                  default: 'fa fa-check-square-o'
                },
                checkboxFieldIconOff: {
                  type: 'string',
                  title: 'Checkbox Icon Off',
                  format: 'IconSelector',
                  default: 'fa-square-o'
                }
              }
            }
          }
        }
      },
      leftAxis: {
        type: 'array',
        title: 'Left Axis',
        items: {
          type: 'object',
          title: 'Left axis column',
          headerTemplate: '{{ self.dataIndex }}',
          options: {
            collapsed: true
          },
          properties: {
            dataIndex: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            header: {
              type: 'string',
              title: 'Header'
            },
            tooltip: {
              type: 'string',
              title: 'Tooltip'
            },
            width: {
              type: 'number',
              title: 'Width',
              default: 120
            },
            flex: {
              type: 'number',
              title: 'Flex',
              default: 0
            },
            visibleByDefault: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Visible by default'
            },
            fixed: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Fixed'
            },
            sortIndex: {
              type: 'string',
              title: 'Sort Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            visibleBy: {
              type: 'string',
              title: 'Visible based on',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            }
          }
        }
      },
      topAxis: {
        type: 'array',
        title: 'Top Axis',
        items: {
          type: 'object',
          title: 'Top axis column',
          headerTemplate: '{{ self.dataIndex }}',
          options: {
            collapsed: true
          },
          properties: {
            dataIndex: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            header: {
              type: 'string',
              title: 'Header'
            },
            visibleByDefault: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Visible by default'
            },
            fixed: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Fixed'
            },
            sortIndex: {
              type: 'string',
              title: 'Sort Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            }
          }
        }
      },
      keys: {
        type: 'array',
        title: 'Keys',
        items: {
          type: 'object',
          title: 'Key',
          headerTemplate: '{{ self.fieldName}}',
          options: {
            collapsed: true
          },
          properties: {
            fieldName: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            }
          }
        }
      },
      pivotconfigurator: {
        title: 'Pivot Configurator',
        type: 'object',
        properties: {
          plugin: {
            title: 'Pivot configurator',
            options: {
              disable_collapse: true
            },
            oneOf: [
              {
                title: 'Disabled',
                type: 'object',
                properties: {
                  enabled: {
                    type: 'boolean',
                    enum: [false],
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['enabled'],
                additionalProperties: false
              },
              {
                title: 'Enabled',
                type: 'object',
                properties: {
                  enabled: {
                    type: 'boolean',
                    enum: [true],
                    options: {
                      hidden: true
                    }
                  },
                  ptype: {
                    type: 'string',
                    enum: ['pivotconfigurator'],
                    options: {
                      hidden: true
                    }
                  },
                  collapsible: {
                    options: {
                      hidden: true
                      // TODO: Remove this fields
                    },
                    type: 'boolean',
                    default: true,
                    required: true,
                    title: 'Collapsible'
                  },
                  collapsed: {
                    options: {
                      hidden: true
                      // TODO: Remove this fields
                    },
                    type: 'boolean',
                    default: true,
                    required: true,
                    title: 'Collapsed'
                  },
                  dock: {
                    type: 'string',
                    title: 'Docking position',
                    enum: ['right'],
                    // , 'bottom', 'left','top'
                    default: '',
                    required: true,
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['enabled', 'ptype']
              }
            ]
          }
        }
      },
      pivotdrilldown: {
        title: 'Pivot Drill Down',
        type: 'object',
        options: {
          hidden: true
        },
        properties: {
          plugin: {
            title: 'Pivot drill down',
            options: {
              disable_collapse: true
            },
            oneOf: [
              {
                title: 'Disabled',
                type: 'object',
                properties: {
                  enabled: {
                    type: 'boolean',
                    enum: [false],
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['enabled'],
                additionalProperties: false
              },
              {
                title: 'Enabled',
                type: 'object',
                properties: {
                  enabled: {
                    type: 'boolean',
                    enum: [true],
                    options: {
                      hidden: true
                    }
                  },
                  ptype: {
                    type: 'string',
                    enum: ['pivotdrilldown'],
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['enabled', 'ptype']
              }
            ]
          }
        }
      },
      pivotexporter: {
        title: 'Pivot Exporter',
        type: 'object',
        properties: {
          plugin: {
            title: 'Pivot exporter',
            options: {
              disable_collapse: true
            },
            oneOf: [
              {
                title: 'Disabled',
                type: 'object',
                properties: {
                  enabled: {
                    type: 'boolean',
                    enum: [false],
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['enabled'],
                additionalProperties: false
              },
              {
                title: 'Enabled',
                type: 'object',
                properties: {
                  enabled: {
                    type: 'boolean',
                    enum: [true],
                    options: {
                      hidden: true
                    }
                  },
                  ptype: {
                    type: 'string',
                    enum: ['pivotexporter'],
                    options: {
                      hidden: true
                    }
                  }
                },
                required: ['enabled', 'ptype']
              }
            ]
          }
        }
      },
      cellDisplayRules: {
        type: 'array',
        title: 'Cell Display Rules',
        items: {
          type: 'object',
          title: 'Rule',
          properties: {
            ruleFieldName: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },

            aggregator: {
              type: 'string',
              title: 'Aggregation function',
              enum: [
                '',
                'avg',
                'max',
                'min',
                'sum',
                'stdDev',
                'stdDevP',
                'variance',
                'varianceP',
                'groupSumPercentage'
              ],
              default: '',
              required: true
            },
            customAggregator: {
              type: 'string',
              title: 'Custom aggregation function',
              enumSource: [
                {
                  source: [
                    { name: '' },
                    { name: 'Yorsan_PRODUCT_COSTSUM_Plus_PRODUCT_COSTAVG' },
                    { name: 'Yorsan_PRODUCT_COSTSUM_Times_Two' },
                    { name: 'AlwaysFive' },
                    { name: 'AlwaysTen' },
                    { name: 'UniqueLeftCategories' },
                    { name: 'Gillette_DemandByRegion_ChangeInDemand' },
                    { name: 'Gillette_performanceComparison' },
                    { name: 'Gillette_productionComparison' },
                    { name: 'Renault_WaitingTime' },
                    { name: 'Renault_TransportLeadTime' },
                    { name: 'Renault_Totaltime' },
                    { name: 'Yorsan_Bulunabilirlik' },
                    { name: 'Yorsan_StokGun_GERCEKLESENEGORE' },
                    { name: 'Yorsan_StokGun_SON30GUNEGORE' },
                    { name: 'Yorsan_StokGun_GELECEK1HAFTAYAGORE' },
                    { name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_TONAJ' },
                    { name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_MALIYET' },
                    { name: 'DogtasKelebek_SapmaOrani' },
                    { name: 'DogtasKelebek_SapmaOrani_Takimi' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi' },
                    { name: 'DogtasKelebek_ButceKarsilastirma' },
                    { name: 'DogtasKelebek_TahminFark' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi_2' },
                    { name: 'Defacto_Difference' },
                    { name: 'Defacto_DIFFERENCEpercent' },
                    { name: 'DogtasKelebek_Unfull_Percent' },
                    { name: 'DogtasKelebek_Start_StockDay' },
                    { name: 'DogtasKelebek_End_StockDay' },
                    { name: 'Penti_Store_Stock_Cover' },
                    { name: 'Penti_WH_Stock_Cover' },
                    { name: 'Penti_Availability' },
                    { name: 'Penti_Rep_Sum_StoreCover' },
                    { name: 'Penti_Rep_Sum_WHCover' },
                    { name: 'DogtasKelebek_Unfull_Percent_Sol' },
                    { name: 'DogtasKelebek_End_StockDay_Sol' },
                    { name: 'DogtasKelebek_UYSapmaOrani_Takim_2' },
                    { name: 'DogtasKelebek_SatisSapmaOrani_Takim_2' },
                    { name: 'DogtasKelebek_SolvoyoSapmaOrani_Takim_2' },
                    { name: 'Penti_TotalStoreStock_Change' },
                    { name: 'Penti_Kiriklik_Option_Per' },
                    { name: 'Penti_Capacity_Usage' },
                    { name: 'Gillette_InventorybyRegion_Change' },
                    { name: 'HB_DefinedAttrPer' },
                    { name: 'HB_AvgSalesPrice' },
                    { name: 'HB_BudgetPerform' },
                    { name: 'HB_StockDay' },
                    { name: 'HB_FreeCashDay' },
                    { name: 'HB_AggTerm' },
                    { name: 'HB_AggTermActual' },
                    { name: 'HB_FreeCashDayActual' },
                    { name: 'Penti_Availability_Kisitsiz' },
                    { name: 'Defacto_IPO' },
                    { name: 'Unilever_Supply_CoverKG' },
                    { name: 'Unilever_Supply_CoverValue' },
                    { name: 'Unilever_Supply_MinCoverKG' },
                    { name: 'Unilever_Supply_MinCoverValue' },
                    { name: 'Unilever_Supply_MaxCoverKG' },
                    { name: 'Unilever_Supply_MaxCoverValue' },
                    { name: 'Unilever_Supply_MinCover2KG' },
                    { name: 'Unilever_Supply_MinCover2Value' },
                    { name: 'Unilever_Planners_CoverKG' },
                    { name: 'Unilever_Planners_CoverValue' },
                    { name: 'Unilever_Supply_MinCoverKG_Diff' },
                    { name: 'Unilever_Supply_MinCover2KG_Diff' },
                    { name: 'Unilever_Supply_MaxCoverKG_Diff' },
                    { name: 'Unilever_Supply_MinCoverValue_Diff' },
                    { name: 'Unilever_Supply_MinCover2Value_Diff' },
                    { name: 'Unilever_Supply_MaxCoverValue_Diff' },
                    { name: 'Unilever_Planners_MinCoverKG_Diff' },
                    { name: 'Unilever_Planners_MinCover2KG_Diff' },
                    { name: 'Unilever_Planners_MaxCoverKG_Diff' },
                    { name: 'Unilever_Planners_MinCoverValue_Diff' },
                    { name: 'Unilever_Planners_MinCover2Value_Diff' },
                    { name: 'Unilever_Planners_MaxCoverValue_Diff' },
                    { name: 'yatsan_devirhizi_teshiregoreciro' },
                    { name: 'yatsan_devirhizi_toplamagoreciro' },
                    { name: 'yatsan_devirhizi_teshiregoreadet' },
                    { name: 'yatsan_devirhizi_toplamagoreadet' },
                    { name: 'MD_DegisimStok' },
                    { name: 'MD_DegisimSatisAdet' },
                    { name: 'MD_DegisimSatisTutar' },
                    { name: 'MD_IndirimOncesiCover1' },
                    { name: 'MD_IndirimSonrasiCover1' },
                    { name: 'MD_DegisimGrossProfit' },
                    { name: 'MD_DegisimCOGS' },
                    { name: 'MD_DegisimCover' },
                    { name: 'IndirimOncesiGMROI1' },
                    { name: 'IndirimSonrasiGMROI1' },
                    { name: 'DegisimGMROI' },
                    { name: 'IndirimOncesiCOGSPer' },
                    { name: 'IndirimSonrasiCOGSPer' },
                    { name: 'COSGPerDegisim' },
                    { name: 'IndirimOncesiGrossMarginPer' },
                    { name: 'IndirimSonrasiGrossMarginPer' },
                    { name: 'DegisimGrossMarginPer' },
                    { name: 'IndirimOncesiAvgPrice' },
                    { name: 'IndirimSonrasiAvgPrice' },
                    { name: 'DegisimPricePer' },
                    { name: 'IndirimOncesiAvgCost' },
                    { name: 'IndirimSonrasiAvgCost' },
                    { name: 'IndirimOncesiAvgMargin' },
                    { name: 'IndirimSonrasiAvgMargin' },
                    { name: 'DegisimMarginPer' },
                    { name: 'SLVY_Dogruluk' },
                    { name: '1.SLVY_Dogruluk' },
                    { name: '2.SLVY_Dogruluk' },
                    { name: '3.SLVY_Dogruluk' },
                    { name: '4.SLVY_Dogruluk' },
                    { name: '5.SLVY_Dogruluk' },
                    { name: 'Stok/Satis' },
                    { name: 'Penti_Store_Stock_Cover_Prev' },
                    { name: 'Penti_WH_Stock_Cover_Prev' },
                    { name: 'Gillette_UnconsVsCons' },
                    { name: 'Penti_Store_Stock_Cover_Change' },
                    { name: 'Penti_WH_Stock_Cover_Change' },
                    { name: 'Gillette_Scenarios' }
                  ],
                  title: '{{item.name}}',
                  value: '{{item.name}}'
                },
                {
                  source: 'aggregators',
                  value: '{{item.name}}'
                }
              ],
              watch: {
                aggregators: 'dynamicAggregators'
              },
              default: '',
              required: true
            },
            referenceFieldName: {
              type: 'string',
              title: 'Reference Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },
            referenceAggregator: {
              type: 'string',
              title: 'Reference Aggregation function',
              enum: ['', 'avg', 'max', 'min', 'sum', 'stdDev', 'stdDevP', 'variance', 'varianceP'],
              default: '',
              required: true
            },
            referenceCustomAggregator: {
              type: 'string',
              title: 'Reference Custom aggregation function',
              enumSource: [
                {
                  source: [
                    { name: '' },
                    { name: 'Yorsan_PRODUCT_COSTSUM_Plus_PRODUCT_COSTAVG' },
                    { name: 'Yorsan_PRODUCT_COSTSUM_Times_Two' },
                    { name: 'AlwaysFive' },
                    { name: 'AlwaysTen' },
                    { name: 'UniqueLeftCategories' },
                    { name: 'Gillette_DemandByRegion_ChangeInDemand' },
                    { name: 'Gillette_performanceComparison' },
                    { name: 'Gillette_productionComparison' },
                    { name: 'Renault_WaitingTime' },
                    { name: 'Renault_TransportLeadTime' },
                    { name: 'Renault_Totaltime' },
                    { name: 'Yorsan_Bulunabilirlik' },
                    { name: 'Yorsan_StokGun_GERCEKLESENEGORE' },
                    { name: 'Yorsan_StokGun_SON30GUNEGORE' },
                    { name: 'Yorsan_StokGun_GELECEK1HAFTAYAGORE' },
                    { name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_TONAJ' },
                    { name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_MALIYET' },
                    { name: 'DogtasKelebek_SapmaOrani' },
                    { name: 'DogtasKelebek_SapmaOrani_Takimi' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi' },
                    { name: 'DogtasKelebek_ButceKarsilastirma' },
                    { name: 'DogtasKelebek_TahminFark' },
                    { name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi_2' },
                    { name: 'Defacto_Difference' },
                    { name: 'Defacto_DIFFERENCEpercent' },
                    { name: 'DogtasKelebek_Unfull_Percent' },
                    { name: 'DogtasKelebek_Start_StockDay' },
                    { name: 'DogtasKelebek_End_StockDay' },
                    { name: 'Penti_Store_Stock_Cover' },
                    { name: 'Penti_WH_Stock_Cover' },
                    { name: 'Penti_Availability' },
                    { name: 'Penti_Rep_Sum_StoreCover' },
                    { name: 'Penti_Rep_Sum_WHCover' },
                    { name: 'DogtasKelebek_Unfull_Percent_Sol' },
                    { name: 'DogtasKelebek_End_StockDay_Sol' },
                    { name: 'DogtasKelebek_UYSapmaOrani_Takim_2' },
                    { name: 'DogtasKelebek_SatisSapmaOrani_Takim_2' },
                    { name: 'DogtasKelebek_SolvoyoSapmaOrani_Takim_2' },
                    { name: 'Penti_TotalStoreStock_Change' },
                    { name: 'Penti_Kiriklik_Option_Per' },
                    { name: 'Penti_Capacity_Usage' },
                    { name: 'Gillette_InventorybyRegion_Change' },
                    { name: 'HB_DefinedAttrPer' },
                    { name: 'HB_AvgSalesPrice' },
                    { name: 'HB_BudgetPerform' },
                    { name: 'HB_StockDay' },
                    { name: 'HB_FreeCashDay' },
                    { name: 'HB_AggTerm' },
                    { name: 'HB_AggTermActual' },
                    { name: 'HB_FreeCashDayActual' },
                    { name: 'Penti_Availability_Kisitsiz' },
                    { name: 'Defacto_IPO' },
                    { name: 'Unilever_Supply_CoverKG' },
                    { name: 'Unilever_Supply_CoverValue' },
                    { name: 'Unilever_Supply_MinCoverKG' },
                    { name: 'Unilever_Supply_MinCoverValue' },
                    { name: 'Unilever_Supply_MaxCoverKG' },
                    { name: 'Unilever_Supply_MaxCoverValue' },
                    { name: 'Unilever_Supply_MinCover2KG' },
                    { name: 'Unilever_Supply_MinCover2Value' },
                    { name: 'Unilever_Planners_CoverKG' },
                    { name: 'Unilever_Planners_CoverValue' },
                    { name: 'Unilever_Supply_MinCoverKG_Diff' },
                    { name: 'Unilever_Supply_MinCover2KG_Diff' },
                    { name: 'Unilever_Supply_MaxCoverKG_Diff' },
                    { name: 'Unilever_Supply_MinCoverValue_Diff' },
                    { name: 'Unilever_Supply_MinCover2Value_Diff' },
                    { name: 'Unilever_Supply_MaxCoverValue_Diff' },
                    { name: 'Unilever_Planners_MinCoverKG_Diff' },
                    { name: 'Unilever_Planners_MinCover2KG_Diff' },
                    { name: 'Unilever_Planners_MaxCoverKG_Diff' },
                    { name: 'Unilever_Planners_MinCoverValue_Diff' },
                    { name: 'Unilever_Planners_MinCover2Value_Diff' },
                    { name: 'Unilever_Planners_MaxCoverValue_Diff' },
                    { name: 'yatsan_devirhizi_teshiregoreciro' },
                    { name: 'yatsan_devirhizi_toplamagoreciro' },
                    { name: 'yatsan_devirhizi_teshiregoreadet' },
                    { name: 'yatsan_devirhizi_toplamagoreadet' },
                    { name: 'MD_DegisimStok' },
                    { name: 'MD_DegisimSatisAdet' },
                    { name: 'MD_DegisimSatisTutar' },
                    { name: 'MD_IndirimOncesiCover1' },
                    { name: 'MD_IndirimSonrasiCover1' },
                    { name: 'MD_DegisimGrossProfit' },
                    { name: 'MD_DegisimCOGS' },
                    { name: 'MD_DegisimCover' },
                    { name: 'IndirimOncesiGMROI1' },
                    { name: 'IndirimSonrasiGMROI1' },
                    { name: 'DegisimGMROI' },
                    { name: 'IndirimOncesiCOGSPer' },
                    { name: 'IndirimSonrasiCOGSPer' },
                    { name: 'COSGPerDegisim' },
                    { name: 'IndirimOncesiGrossMarginPer' },
                    { name: 'IndirimSonrasiGrossMarginPer' },
                    { name: 'DegisimGrossMarginPer' },
                    { name: 'IndirimOncesiAvgPrice' },
                    { name: 'IndirimSonrasiAvgPrice' },
                    { name: 'DegisimPricePer' },
                    { name: 'IndirimOncesiAvgCost' },
                    { name: 'IndirimSonrasiAvgCost' },
                    { name: 'IndirimOncesiAvgMargin' },
                    { name: 'IndirimSonrasiAvgMargin' },
                    { name: 'DegisimMarginPer' },
                    { name: 'SLVY_Dogruluk' },
                    { name: '1.SLVY_Dogruluk' },
                    { name: '2.SLVY_Dogruluk' },
                    { name: '3.SLVY_Dogruluk' },
                    { name: '4.SLVY_Dogruluk' },
                    { name: '5.SLVY_Dogruluk' },
                    { name: 'Stok/Satis' },
                    { name: 'Penti_Store_Stock_Cover_Prev' },
                    { name: 'Penti_WH_Stock_Cover_Prev' },
                    { name: 'Gillette_UnconsVsCons' },
                    { name: 'Penti_Store_Stock_Cover_Change' },
                    { name: 'Penti_WH_Stock_Cover_Change' },
                    { name: 'Gillette_Scenarios' }
                  ],
                  title: '{{item.name}}',
                  value: '{{item.name}}'
                },
                {
                  source: 'aggregators',
                  value: '{{item.name}}'
                }
              ],
              watch: {
                aggregators: 'dynamicAggregators'
              },
              default: '',
              required: true
            },
            operator: {
              type: 'string',
              title: 'Operator',
              enum: ['equal', 'not equal', 'smaller', 'larger'],
              required: true,
              default: 'equal'
            },
            value: {
              type: 'string',
              title: 'Value'
            },
            backColor: {
              type: 'string',
              title: 'Back Color',
              format: 'Color',
              default: 'red'
            },
            textColor: {
              type: 'string',
              title: 'Text Color',
              format: 'Color',
              default: '#ffffff'
            },
            icon: {
              type: 'string',
              title: 'Icon',
              format: 'IconSelector'
            },
            displayOnlyIcon: {
              type: 'boolean',
              title: 'Display Only Icon',
              required: true,
              default: true
            },
            iconPosition: {
              type: 'string',
              title: 'Icon Position',
              enum: ['left', 'right'],
              required: true,
              default: 'right'
            },
            group: {
              type: 'string',
              title: 'Group'
            }
          }
        }
      }
    }
  }
}
