import * as actionTypes from './actionTypes'
import { makeRequest } from '../../utils'

export const fetchCurrencyRates = (action) => {
  return (dispatch) => {
    dispatch(startGetCurrencyRatesStart())

    return new Promise((resolve, reject) => {
      makeRequest(action)
        .then((response) => {
          dispatch(saveGetCurrencyRates({ payload: response.data }))
          resolve(response.data)
        })
        .catch((error) => {
          dispatch(saveGetCurrencyRatesFailed({ payload: error.data }))
          reject(error.data)
        })
    })
  }
}

export const startGetCurrencyRatesStart = () => {
  return {
    type: actionTypes.FETCH_CURRENCY_RATES_START
  }
}

export const saveGetCurrencyRates = (options) => {
  return {
    type: actionTypes.STORE_CURRENCY_RATES,
    payload: options.payload
  }
}

export const saveGetCurrencyRatesFailed = (options) => {
  return {
    type: actionTypes.STORE_CURRENCY_RATES_FAILED,
    payload: options.payload
  }
}
