import React from 'react'
import numeral from 'numeral'

function Revenue(props) {
  const numeralFormat = {
    ts: '0,0'
  }
  const { totalRevenue, totalSales, numberOfStores, minPresentation, buyQuantity, optionCount } =
    props

  return (
    <div className="segment-summary">
      <section>
        <i className="slvy-ui-icon-revenue-extra-lt" />
        <div>
          <small>Total Revenue</small>
          <strong>{numeral(totalRevenue).format(numeralFormat.ts)}</strong>
        </div>
      </section>

      <section>
        <i className="slvy-ui-icon-chart-up-extra-lt" />
        <div>
          <small>Total Sales</small>
          <strong>{numeral(totalSales).format(numeralFormat.ts)}</strong>
        </div>
      </section>

      <section>
        <i className="slvy-ui-icon-shop" />
        <div>
          <small>Number of Stores</small>
          <strong>{numeral(numberOfStores).format(numeralFormat.ts)}</strong>
        </div>
      </section>

      <section>
        <i className="slvy-ui-icon-grid" />
        <div>
          <small>Min Presentation</small>
          <strong>{numeral(minPresentation).format(numeralFormat.ts)}</strong>
        </div>
      </section>

      <section>
        <i className="slvy-ui-icon-grid-selected" />
        <div>
          <small>Buy Quantity</small>
          <strong>{numeral(buyQuantity).format(numeralFormat.ts)}</strong>
        </div>
      </section>

      <section>
        <i className="slvy-ui-icon-tag-extra-lt" />
        <div>
          <small>Option Count</small>
          <strong>{optionCount}</strong>
        </div>
      </section>
    </div>
  )
}

export default Revenue
