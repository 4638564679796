import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import TUICalendar from '@toast-ui/react-calendar'
import { slvyToast } from '../../components'
import createPlugin, { PluginTypes } from '../../BasePlugin'
import Header from './components/Header'
import 'tui-calendar/dist/tui-calendar.css'
import './style.scss'

const dayTitles = {
  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  tr: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi']
}

const dayClickedParams = {
  startDate: PluginTypes.datetime,
  endDate: PluginTypes.datetime,
  // for support old version
  daySelected: PluginTypes.boolean,
  eventSelected: PluginTypes.boolean,
  refreshKey: PluginTypes.string
}

const eventClickedParams = {
  id: PluginTypes.string,
  eventName: PluginTypes.string,
  startDate: PluginTypes.datetime,
  endDate: PluginTypes.datetime,
  description: PluginTypes.string,
  // for support old version
  daySelected: PluginTypes.boolean,
  eventSelected: PluginTypes.boolean,
  refreshKey: PluginTypes.string
}

export const ReactBigCalendar = (props) => {
  const calendarRef = useRef(null)
  const [currentView, setCurrentView] = useState('month')
  const [lastAddedScheduleDate, setLastAddedScheduleDate] = useState()

  useEffect(() => {
    props.registerMethod({
      key: 'RefreshCalendar',
      fn: refreshCalendar,
      args: [{ name: 'refreshKey', type: PluginTypes.string }]
    })
    calendarRef.current.registerEvent = {
      handleDayClicked: props.registerEvent({
        key: 'DayClicked',
        fn: handleDayClicked,
        returnTypes: dayClickedParams
      }),
      handleEventClicked: props.registerEvent({
        key: 'EventClicked',
        fn: handleEventClicked,
        returnTypes: eventClickedParams
      }),
      handleDataUpdated: props.registerEvent({
        key: 'DataUpdated',
        fn: handleDataUpdated,
        returnTypes: { refreshKey: PluginTypes.string }
      })
    }
    props.registerAuthorizations(['Editing'])
  }, [])

  function handleDataUpdated() {
    return { refreshKey: uuidv4() }
  }

  function refreshCalendar() {
    props.setDataArguments(null, true)
  }

  function handleDayClicked(event) {
    return createPopupFormData(event, true)
  }

  function handleEventClicked(event) {
    console.log(event)
    return createPopupFormData(event, false)
  }

  function createPopupFormData(event, isNewEvent) {
    setLastAddedScheduleDate(event.start)
    const today = new Date()

    const popupFormData = {
      startDate: formatScheduleDate(event.start.getTime()) || today,
      endDate: formatScheduleDate(event.end.getTime()) || today,
      // for support old version
      daySelected: isNewEvent,
      eventSelected: isNewEvent ? false : !!event.id,
      refreshKey: uuidv4()
    }

    if (!isNewEvent) {
      return {
        id: event.id,
        eventName: event.title,
        description: event.body,
        ...popupFormData
      }
    }
    return popupFormData
  }

  function onBeforeUpdateSchedule(event) {
    if (
      !Object.hasOwn(dataEditing, 'updateQuery') ||
      dataEditing.updateQuery.trim() === '' ||
      dataEditing.updateQuery.slice(0, 1) === '\n'
    ) {
      sendNotifications(false, 'Update query is not found.')
      return
    }

    const { schedule, changes } = event

    // Clicked event's edit button
    if (changes === undefined) {
      calendarRef.current.registerEvent.handleEventClicked(schedule)
      return
    }

    calendarRef.current.calendarInst.updateSchedule(schedule.id, schedule.calendarId, changes)

    const startDate = formatScheduleDate(schedule.start.getTime())
    const endDate = formatScheduleDate(schedule.end.getTime())

    const formData = {
      [events.id]: schedule.id,
      [events.startDate]: startDate,
      [events.endDate]: endDate
    }

    postData(formData, 0)
  }

  function onBeforeDeleteSchedule(event) {
    if (
      !Object.hasOwn(dataEditing, 'deleteQuery') ||
      dataEditing.deleteQuery.trim() === '' ||
      dataEditing.deleteQuery.slice(0, 1) === '\n'
    ) {
      sendNotifications(false, 'Delete query is not found.')
      return
    }

    const { schedule } = event
    calendarRef.current.calendarInst.deleteSchedule(schedule.id, schedule.calendarId)

    const formData = {
      [events.id]: schedule.id
    }

    postData(formData, 2)
  }

  function postData(formData, type) {
    const data = {
      filters: {},
      records: [formData],
      // type: 0 -> update, type: 2 -> delete
      type,
      updatehints: {}
    }

    clientWithProgress
      .post(`/data/plugin/${id}/edit/`, { data })
      .then((res) => {
        if (res.result.length) {
          sendNotifications(false, res.result[0].errorCode)
        } else {
          sendNotifications(true, 'Calendar updated successfully')
          calendarRef.current.registerEvent.handleDataUpdated()
        }
      })
      .catch(() => sendNotifications(false, 'Error'))
  }

  function onHandleDayClicked(event) {
    calendarRef.current.registerEvent.handleDayClicked(event)
  }

  function sendNotifications(isSuccess, message) {
    if (isSuccess) slvyToast.success({ message, title: 'Success' })
    else slvyToast.warning({ message })
  }

  function formatScheduleDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(date).format(format)
  }

  function handleViewChange(event) {
    setCurrentView(event.target.value)
  }

  function getDisplayOptions(settings = {}) {
    const { localization, weekStartsMonday = false, workweek = false } = settings

    const displayOptions = {
      daynames: dayTitles.en,
      workweek,
      isAlways6Week: false
    }

    if (localization === 'tr') {
      displayOptions.daynames = dayTitles.tr
    }
    if (weekStartsMonday || workweek) {
      displayOptions.startDayOfWeek = 1
    }

    return displayOptions
  }

  function getToday(columnName, firstSchedule = false) {
    if (!columnName || !firstSchedule) {
      return new Date()
    }

    const startDate = firstSchedule[columnName]
    return new Date(startDate)
  }

  function getSchedules(columnNames, pluginData = []) {
    if (columnNames === undefined || pluginData === null || pluginData.length === 0) {
      return
    }

    const schedules = pluginData.reduce((schedules, data) => {
      const newSchedule = {
        id: data[columnNames.id],
        title: data[columnNames.eventName],
        body: data[columnNames.description],
        start: data[columnNames.startDate],
        // Ex: 2022-05-05T00:00:00 does not show on calendar
        // because of time so added 1 second
        end: moment(data[columnNames.endDate], 'YYYY-MM-DD HH:mm').add(1, 's'),
        bgColor: data[columnNames.color],
        dragBgColor: data[columnNames.color],
        category: 'time'
      }
      schedules.push(newSchedule)
      return schedules
    }, [])

    return schedules
  }

  const {
    id,
    clientWithProgress,
    size: { width = 0 },
    settings: {
      config: {
        settings = {},
        settings: { isReadOnly = false } = {},
        events,
        days: { today = null } = {}
      } = {},
      query: { dataEditing = {} } = {}
    } = {},
    pluginData
  } = props

  const displayOptions = getDisplayOptions(settings)
  const selectedToday = getToday(today, pluginData?.[0])
  const schedules = getSchedules(events, pluginData)

  return (
    <div
      className={cx('slvyCalendar', {
        'xs-calendar': width < 500,
        'overflow-hidden': currentView !== 'month'
      })}
    >
      <Header
        calendarRef={calendarRef}
        currentView={currentView}
        defaultDate={lastAddedScheduleDate || selectedToday}
        handleViewChange={handleViewChange}
        settings={settings}
      />
      <TUICalendar
        ref={calendarRef}
        useDetailPopup
        height={'calc(100% - 89px)'}
        view={currentView}
        month={displayOptions}
        week={displayOptions}
        taskView={false}
        isReadOnly={isReadOnly}
        schedules={schedules}
        usageStatistics={false}
        onBeforeCreateSchedule={onHandleDayClicked}
        onBeforeDeleteSchedule={onBeforeDeleteSchedule}
        onBeforeUpdateSchedule={onBeforeUpdateSchedule}
      />
    </div>
  )
}

const selectConnectorProps = (props) => ({
  registerMethod: props.registerMethod,
  registerEvent: props.registerEvent,
  registerAuthorizations: props.registerAuthorizations,
  setDataArguments: props.setDataArguments,
  clientWithProgress: props.clientWithProgress,
  size: props.size,
  id: props.id,
  settings: props.settings,
  pluginData: props.pluginData
})

export default createPlugin(ReactBigCalendar, selectConnectorProps, true)
