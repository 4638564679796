Ext.define('tuiv2.cmp.TaskWindow', {
  extend: 'Ext.window.Window',
  xtype: 'tuiv2-taskwindow',
  modal: true,
  layout: 'border',
  height: 500,
  width: 800,
  title: 'Service Tasks',
  closeAction: 'hide',
  ui: 'tui-window',
  referenceHolder: true,
  items: [
    {
      xtype: 'panel',
      region: 'center',
      layout: 'fit',
      margin: 10,
      overflowY: 'auto',
      items: [
        {
          xtype: 'tuiv2-taskgrid',
          reference: 'taskGrid',
          bind: {
            store: '{taskStore}'
          }
        }
      ]
    }
  ]
})
