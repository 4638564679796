const selectConnectorProps = (props) => ({
  client: props.client,
  settings: props.settings,
  pluginData: props.pluginData,
  data: props.data,
  onReady: props.onReady,
  id: props.id,
  reloadExtRoot: props.reloadExtRoot,
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  getQueryArguments: props.getQueryArguments,
  registerAuthorizations: props.registerAuthorizations,
  addRowIndexToLocalData: props.addRowIndexToLocalData,
  query: props.query,
  size: props.size,
  hideExtRoot: props.hideExtRoot,
  isAllowed: props.isAllowed,
  actualFilters: props.actualFilters,
  params: props.params,
  updateRowInLocalData: props.updateRowInLocalData,
  clearCaches: props.clearCaches,
  setDataArguments: props.setDataArguments,
  dequeueUpdateRequest: props.dequeueUpdateRequest,
  enqueueUpdateRequest: props.enqueueUpdateRequest,
  afterDataLoad: props.afterDataLoad,
  preferredUsername: props.preferredUsername,
  isPreviewMode: props.isPreviewMode,
  getFormattedValue: props.getFormattedValue
})

export default selectConnectorProps
