Ext.define('MDM.Btl.Discrepancy.DiscrepancyDialog', {
  extend: 'Ext.window.Window',
  height: '80%',
  width: '80%',
  modal: true,
  autoShow: true,
  title: 'Discrepancy Report',
  // Uncomment to give this component an xtype
  xtype: 'x-discrepancydialog',
  tbar: [
    {
      text: 'Filter'
    }
  ],
  layout: 'fit',
  items: [
    {
      xtype: 'Discrepancy-maingrid'
    }
  ]
})
