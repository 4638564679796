Ext.define('po.MainGrid', {
  extend: 'Ext.grid.Panel',
  alias: 'widget.poMainGrid',
  columnLines: true,
  header: false,
  bodyStyle: {
    background: '#fff'
  },
  reference: 'MainGrid',
  multiColumnSort: true,
  syncRowHeight: true,
  enableLocking: true,
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this

    var gridfilters = me.getPlugin('gridfilters')

    var normalView = me.getView().normalView

    var grouping = normalView.getFeature('grouping')
    grouping.lockingPartner.expandTip = i18n.GridConfig_features_expandTip
    grouping.lockingPartner.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText

    me.unlockText = i18n.GridConfig_unlockText
    me.lockText = i18n.GridConfig_lockText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    },
    {
      ptype: 'gridexporter',
      id: 'gridexport'
    },
    {
      ptype: 'cellediting',
      clicksToEdit: 1,
      listeners: {
        beforeedit: function (editor, context) {
          var record = context.record
          var mainView = context.grid.view.up('pomainview')
          var viewModel = mainView.getViewModel()
          var canEdit = viewModel ? viewModel.get('canEdit') : true
          return (
            record.get('IsEditable') &&
            !record.get('SAPYollandi') &&
            !record.get('isSendToSap') &&
            canEdit
          )
        },
        validateedit: function (editor, context) {
          if (context.value == context.originalValue) {
            context.cancel = true
            return false
          }

          var mainStore = context.store.store
          mainStore.suspendEvents()
        },
        canceledit: function (editor, context) {
          var mainStore = context.store.store
          mainStore.resumeEvents()
          context.grid.view.refreshNode(context.record)
        },
        edit: function (editor, context) {
          var mainStore = context.store.store
          mainStore.resumeEvents()
          context.grid.view.refreshNode(context.record)
        }
      }
    }
  ],
  features: {
    ftype: 'grouping',
    id: 'grouping'
  },
  viewConfig: {
    getRowClass: function (record) {
      if (record.get('IsRowColoredRed')) {
        if (record.get('onayFlag') && !record.get('SAPYollandi')) {
          return 'has-checkbox coloredRed'
        }

        return 'no-checkbox coloredRed'
      }
      if (record.get('IsRowColored')) {
        if (record.get('onayFlag') && !record.get('SAPYollandi')) {
          return 'has-checkbox colored'
        }

        return 'no-checkbox colored'
      }
      if (record.get('onayFlag') && !record.get('SAPYollandi')) {
        return 'has-checkbox'
      }

      return 'no-checkbox'
    },
    enableTextSelection: true
  },
  layout: 'border',
  collapsible: false,
  split: true,
  selModel: {
    rowSelect: true
  },
  bufferedRenderer: true,
  listeners: {
    columnresize: 'onHandleColumnChanged',
    columnhide: 'onHandleColumnChanged',
    columnshow: 'onHandleColumnChanged',
    columnmove: 'onHandleColumnChanged',
    lockcolumn: 'onHandleColumnChanged',
    unlockcolumn: 'onHandleUnlockColumn',
    groupchange: 'onHandleColumnChanged',
    select: 'onMainGridRowSelect',
    afterlayout: 'onMainGridAfterLayout',
    cellclick: 'onMainGridCellClick',
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady'
  },
  columns: []
})
