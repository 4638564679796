import { SlvyLogo } from '@/components'
import logoStyles from './constants'

const NewChat = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-fill gap-3">
      <SlvyLogo attributes={logoStyles} size="lg" />
      <h4>How can I help you today?</h4>
    </div>
  )
}

export default NewChat
