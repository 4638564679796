export default () => {
  return {
    title: 'Filter Donut',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: false,
            required: false
          }
        }
      },

      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string',
          title: 'Field'
        }
      },

      settings: {
        type: 'object',
        title: 'Filter Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title'
          },
          subtitle: {
            type: 'string',
            title: 'Sub Title'
          },
          totalcount: {
            type: 'string',
            title: 'Total Count',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          }
        }
      },
      columns: {
        type: 'array',
        title: 'Series',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: '{{self.title}}',

          properties: {
            title: {
              type: 'string',
              title: 'Title'
            },
            itemColor: {
              type: 'string',
              format: 'Color',
              title: 'Item Color',
              default: '#f1f1f1'
            },
            column: {
              type: 'string',
              title: 'Column',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            },
            displayValue: {
              title: 'Show as',
              type: 'string',
              enum: ['Both', 'Percentage', 'Total'],
              default: 'Percentage',
              required: true
            },
            otherslimit: {
              type: 'integer',
              title: 'Slice Lowest Limit (%)',
              default: null
            }
          }
        }
      }
    }
  }
}
