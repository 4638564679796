Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.Main', {
  extend: 'Ext.panel.Panel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-geogrid-main',
  controller: 'globalapp-geogrid-maincontroller',
  viewModel: 'globalapp-geogrid-mainviewmodel',
  header: false,
  layout: {
    type: 'hbox',
    pack: 'start',
    align: 'stretch'
  },
  listeners: {
    activate: 'onDPIActive',
    boxready: 'onBoxReady'
  },
  items: [
    {
      xtype: 'globalapp-geogrid-megeregion',
      flex: 6,
      margin: '0 2 0 0',
      reference: 'megeregion',
      ui: 'panel-inverse',
      bodyStyle: {
        background: '#fcfcfc'
      },
      bind: {
        store: '{MegaRegionStore}',
        readOnlyMode: '{readOnlyMode}'
      }
    },
    {
      xtype: 'globalapp-geogrid-region',
      flex: 5,
      margin: '0 2 0 0',
      reference: 'region',
      bodyStyle: {
        background: '#fcfcfc'
      },
      ui: 'panel-inverse',
      bind: {
        store: '{RegionStore}',
        title: 'Subregions of  <span style="color:yellow">' + '{MegaRegion}' + '</span>',
        readOnlyMode: '{readOnlyMode}'
      }
    },
    {
      xtype: 'container',
      flex: 4,
      margin: '0 2 0 0',
      bodyStyle: {
        background: '#fcfcfc'
      },
      layout: 'border',
      defaults: {
        collapsible: true,
        split: true
      },
      items: [
        {
          xtype: 'globalapp-geogrid-country',
          flex: 1,
          margin: '0 0 2 0',
          reference: 'country',
          ui: 'panel-inverse',
          bodyStyle: {
            background: '#fcfcfc'
          },
          region: 'center',
          bind: {
            store: '{CountryStore}',
            title: 'Countries of <span style="color:yellow">' + '{Region}' + '</span>',
            readOnlyMode: '{readOnlyMode}'
          }
        },
        {
          xtype: 'globalapp-geogrid-supplysource',
          flex: 1,
          margin: '2 0 0 0',
          reference: 'supplysource',
          ui: 'panel-inverse',
          region: 'south',
          bodyStyle: {
            background: '#fcfcfc'
          },
          bind: {
            store: '{SupplySourceStore}',
            title: 'Supply Sources of <span style="color:yellow">' + '{Region}' + '</span>',
            readOnlyMode: '{readOnlyMode}'
          }
        }
      ]
    }
  ]
})
