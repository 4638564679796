import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'
import { FormControl } from 'react-bootstrap'
import { slvyToast } from '../../../../components'
import {
  saveScenarioComparisonSettings,
  resetScenarioComparisonSettings,
  saveScenarioComparisonMainConfiguration,
  setLoader as setActionLoader,
  fetchComparisonData,
  saveGetComparisonData,
  fetchComparisonConfiguration,
  saveGetComparisonConfiguration
} from '../../store/actions'
import { _errorHandler, makeRequest, setLoader } from '../../utils'
import MetricSelection from '../MetricSelection/index'
import SoftTimeline from './SoftTimeline/index'
import ViewOptions from './ViewOptions/index'
import ScenarioTable from './ScenarioTable/index'
import ScenarioMove from './ScenarioMove/index'
import { CURRENCIES_LOCALES } from '../../currencies-locales'
import { MESSAGES } from '../../messages'
import { OverlayTrigger } from '../index'
import './style.scss'

class ScenarioComparison extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ComparisonConfigurationData: {},
      ScenariosWithChildren: [],
      activeChildrenFilter: {},
      activeScenarioFullList: [],
      areVisibleRatiosValues: false,
      backupVisibleScenarioTab: 'WithGrouped',
      childData: this.getInitialChildData(),
      isMillion: false,
      isVisibleActiveScenarioFullList: false,
      isVisibleRatioValue: true,
      isVisibleScenarios: false,
      isVisibleSplitValue: true,
      query: '',
      view: 'percentage', // props.buttonFormat, // for initial mode
      visibleScenarioTab: 'WithGrouped'
    }
    this.scenariolistRef = createRef()
    this.currenciesOptions = CURRENCIES_LOCALES[props.culture ? props.culture : 'en-US']

    this.rowClicked = this.rowClicked.bind(this)
  }

  getInitialChildData() {
    return {
      WithBase: {},
      WithGrouped: {},
      WithPrevious: {}
    }
  }

  componentDidMount() {
    this.init()
    document.addEventListener('mousedown', this.handleClickOutside)
    window.addEventListener('reloadComparison', this.reload)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    window.removeEventListener('reloadComparison', this.reload)

    this.props.saveGetComparisonData({
      payload: {}
    })

    this.props.saveGetComparisonConfiguration({
      payload: {}
    })

    this.props.saveScenarioComparisonMainConfiguration({
      payload: {}
    })

    this.props.resetScenarioComparisonSettings({})
  }

  handleClickOutside = ({ target }) => {
    if (this.scenariolistRef.current && !this.scenariolistRef.current.contains(target)) {
      this.setState({ isVisibleActiveScenarioFullList: false, query: '' })
    }
  }

  reload = () => this.init(true)

  handleResponse() {
    _errorHandler(this.getMergedComparisonData(), 'GetComparisonData')
    this.toggleLoader()
    // 123
  }

  onRefreshScenariosWithChildren(
    newScenario,
    ComparedScenarioId,
    loadingData = { fullscreenLoading: true, Guid: undefined }
  ) {
    const {
      state: { visibleScenarioTab, childData }
    } = this

    const _childData = _.cloneDeep(childData)
    this._fetchComparisonDataWithChild(newScenario, ComparedScenarioId, loadingData)
      .then((_response) => {
        const { data = {} } = _response

        this.toggleLoader(false, undefined, loadingData?.Guid)

        _errorHandler(data, 'GetComparisonDataWithChild')

        if (!data.Errors.length) {
          _childData[visibleScenarioTab][newScenario] = { ...data, IsHidden: false }
          this.setState({ childData: _childData })
        }
      })
      .catch((_response) => {
        const { data = {} } = _response
        this.toggleLoader()
        _errorHandler(data, 'GetComparisonDataWithChild')
      })
  }

  onToggleScenariosWithChildren(newScenario, ComparedScenarioId) {
    const {
      state: { visibleScenarioTab, childData, ScenariosWithChildren }
    } = this

    if (typeof newScenario !== 'number') {
      return
    }
    let shouldHide = false
    let newScenariosWithChildren = [...ScenariosWithChildren]
    if (newScenariosWithChildren.find((item) => item === newScenario)) {
      shouldHide = true
      newScenariosWithChildren = newScenariosWithChildren.filter((item) => item !== newScenario)
    } else {
      newScenariosWithChildren.push(newScenario)
    }

    const _childData = _.cloneDeep(childData)
    if (_childData[visibleScenarioTab] && newScenario in _childData[visibleScenarioTab]) {
      _childData[visibleScenarioTab][newScenario].IsHidden = shouldHide
      this.setState({ childData: _childData, ScenariosWithChildren: newScenariosWithChildren })
      return
    }

    this.setState({ ScenariosWithChildren: newScenariosWithChildren }, () => {
      this.onRefreshScenariosWithChildren(newScenario, ComparedScenarioId)
    })
  }

  init(fromReload = false) {
    this._fetchComparisonConfiguration().then(() => {
      const { GetComparisonConfiguration: { Errors = [], Result = {} } = {} } = this.props

      if (!Errors.length) {
        const { isChanged = false, config = {} } = this.getMainConfiguration(Result, fromReload)

        if (isChanged) {
          this.deletePluginState(() => this.updateConfigAndGetComparisonData(_.cloneDeep(config)))
        } else {
          this.updateConfigAndGetComparisonData(_.cloneDeep(config))
        }
      } else {
        slvyToast.warning({
          message: 'GetComparisonConfiguration',
          title: Errors[0],
          options: { containerId: 'dp' }
        })
        this.toggleLoader()
      }
    })
  }

  updateConfigAndGetComparisonData(config) {
    this.updateRedux(config).then(() => {
      this.setState(
        {
          ComparisonConfigurationData: _.cloneDeep(this.props.scenarioComparisonMainConfiguration)
        },
        () => {
          this._fetchComparisonData().then(() => this.handleResponse())
        }
      )
    })
  }

  toggleLoader(isVisible = false, msg = MESSAGES.loading, Guid = undefined) {
    setLoader(this.props.setActionLoader, isVisible, msg, Guid)
  }

  deletePluginState($callback = () => {}) {
    const { id, pluginStates = [] } = this.props

    const scenarioComparisonStates = pluginStates.filter(
      (item) => item.name === 'ScenarioComparisonState'
    )
    const { id: $pluginStateId } = _.last(scenarioComparisonStates)

    this.props.popConfirm(
      () => {
        this.props.deletePluginState(`delete_scenario_comparison_${id}`, id, $pluginStateId)
        $callback()
      },
      MESSAGES.the_scenario_list_has_been_changed,
      'sc'
    )
  }

  getComparisonTypeByName(visibleScenarioTab) {
    return this.state.ComparisonConfigurationData[visibleScenarioTab].ComparisonType
  }

  getMainConfiguration(defaultConfig, fromReload = false) {
    if (
      'ScenarioList' in defaultConfig &&
      'WithBase' in defaultConfig &&
      'WithGrouped' in defaultConfig &&
      'WithPrevious' in defaultConfig
    ) {
      defaultConfig.ScenarioList = defaultConfig.ScenarioList.map(setActualized)
      defaultConfig.WithBase.Scenarios = defaultConfig.WithBase.Scenarios.map(setActualized)
      defaultConfig.WithGrouped.Scenarios = defaultConfig.WithGrouped.Scenarios.map(setActualized)
      defaultConfig.WithPrevious.Scenarios = defaultConfig.WithPrevious.Scenarios.map(setActualized)
    }

    const { pluginStates = [] } = this.props

    const scenarioComparisonStates = pluginStates.filter(
      (item) => item.name === 'ScenarioComparisonState'
    )

    const $hasPluginState = !_.isEmpty(scenarioComparisonStates)

    const result = { isChanged: false, config: defaultConfig }

    if ($hasPluginState) {
      const $pluginState = _.last(scenarioComparisonStates)

      const { config: { state: $$pluginState = {} } = {} } = $pluginState

      const { config: pluginStateConfig = {}, defaultConfig: pluginStateDefaultConfig = {} } =
        $$pluginState

      const { ScenarioList: _pluginStateDefaultScenarioList = [] } = pluginStateDefaultConfig

      if (
        'ScenarioList' in pluginStateDefaultConfig &&
        'WithBase' in pluginStateDefaultConfig &&
        'WithGrouped' in pluginStateDefaultConfig &&
        'WithPrevious' in pluginStateDefaultConfig
      ) {
        pluginStateDefaultConfig.ScenarioList =
          pluginStateDefaultConfig.ScenarioList.map(setActualized)
        pluginStateDefaultConfig.WithBase.Scenarios =
          pluginStateDefaultConfig.WithBase.Scenarios.map(setActualized)
        pluginStateDefaultConfig.WithGrouped.Scenarios =
          pluginStateDefaultConfig.WithGrouped.Scenarios.map(setActualized)
        pluginStateDefaultConfig.WithPrevious.Scenarios =
          pluginStateDefaultConfig.WithPrevious.Scenarios.map(setActualized)
      }

      const _defaultConfig = _.pick(defaultConfig, ['WithBase', 'WithGrouped', 'WithPrevious'])
      const _defaultScenarioList = _.cloneDeep(defaultConfig.ScenarioList)

      const _pluginStateDefaultConfig = _.pick(pluginStateDefaultConfig, [
        'WithBase',
        'WithGrouped',
        'WithPrevious'
      ])

      const scenarioDiff = _pluginStateDefaultScenarioList.filter((scenario) => {
        return _defaultScenarioList.map(($scenario) => $scenario.Id).indexOf(scenario.Id) === -1
      })

      if (_.isEqual(_defaultConfig, _pluginStateDefaultConfig) && !scenarioDiff.length) {
        result.config = pluginStateConfig

        // reset scenario list with default no matter what's happened!
        result.config.ScenarioList = defaultConfig.ScenarioList
      } else {
        result.isChanged = true
      }
    }

    function setActualized(scenario) {
      if (!('IsActualized' in scenario)) {
        scenario.IsActualized = false
      }
      return scenario
    }

    result.config.ScenarioList = result.config.ScenarioList.map(setActualized)
    result.config.WithBase.Scenarios = result.config.WithBase.Scenarios.map(setActualized)
    result.config.WithGrouped.Scenarios = result.config.WithGrouped.Scenarios.map(setActualized)
    result.config.WithPrevious.Scenarios = result.config.WithPrevious.Scenarios.map(setActualized)

    // reset config first
    if (!fromReload) {
      result.config.ComparisonType = result.config.WithGrouped.ComparisonType
    } else {
      result.config.ComparisonType = this.getComparisonTypeByName(this.state.visibleScenarioTab)
    }

    return result
  }

  getComparisonDataCommonRequestParameters() {
    const {
      scenarioComparisonSettings: { selectedMetricFields = [], selectedLeftKeys = [] }
    } = this.props

    const shouldOmitList = [
      'BaselineScenarioId',
      'MfpScenarioId',
      'MetricFields',
      'WorkInSubsetEnabled'
    ]

    const params = _.omit(this.props.getBaseRequestParameters(), shouldOmitList)

    return {
      ...params,
      Fields: _.cloneDeep(selectedMetricFields)
        .filter((item) => item.Name)
        .map((item) => item.Name),
      ActualScenarioList: this.getActualizedScenarioListAsBoolean(),
      LeftHierarchy: _.cloneDeep(selectedLeftKeys),
      SelectedCurrency: this.props.getSelectedCurrency()
    }
  }

  _fetchComparisonData(loadingData = { fullscreenLoading: true, Guid: undefined }) {
    const {
      scenarioComparisonMainConfiguration = {},
      defaultActionParams: { payload = {} },
      errorContext
    } = this.props

    if (loadingData?.fullscreenLoading) {
      this.toggleLoader(true, 'Loading')
    }

    const comparisonDataPayload = {
      ...payload,
      method: 'GetComparisonData',
      requestMethod: 'post',
      body: {
        ...this.getComparisonDataCommonRequestParameters(),
        ComparisonConfiguration: scenarioComparisonMainConfiguration,
        ScenariosWithChildren: [...this.state.ScenariosWithChildren]
      }
    }

    errorContext.lastRequest = {
      payload: comparisonDataPayload
    }

    const promise = this.props.fetchComparisonData({
      payload: comparisonDataPayload
    })

    promise
      .then((response) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          response
        }
        this.updateChildData(response)
        this.toggleLoader(false, undefined, loadingData?.Guid)
      })
      .catch((error) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          error
        }
        this.toggleLoader(false, undefined, loadingData?.Guid)
      })

    return promise
  }

  updateChildData(response) {
    const { visibleScenarioTab, childData, ScenariosWithChildren } = this.state
    const initialChildData = this.getInitialChildData()
    const newScenariosWithChildren = []
    // Recreate ScenariosWithChildren because, when comparison type changes, new scenario data
    // might not have the same row/children data

    if (_.isEqual(childData, initialChildData)) {
      return
    }

    const clonedChildData = _.cloneDeep(childData)
    // reset hidden scenario tabs child data
    const newChildData = _.cloneDeep(initialChildData)
    // store displayed scenario tab child data
    newChildData[visibleScenarioTab] = _.cloneDeep(clonedChildData[visibleScenarioTab])

    if (!_.isEmpty(response.Result) && !_.isEmpty(response.Result.ScenarioData)) {
      const clonedResponse = _.cloneDeep(response)
      const ScenariosData = _.cloneDeep(clonedResponse.Result.ScenarioData)

      // Object.keys(newChildData[visibleScenarioTab]).forEach((id) => {
      ScenariosData.forEach(({ ScenarioId: id }) => {
        id = Number(id)

        const childId = ScenariosWithChildren.find((item) => item === id)
        const hasChild = typeof childId !== 'undefined'
        const currentData = ScenariosData.find((scenarioData) => scenarioData.ScenarioId === id)

        if (hasChild && currentData && !_.isEmpty(currentData.Children)) {
          clonedResponse.Result.ScenarioData = [currentData]
          newChildData[visibleScenarioTab][id] = {
            IsHidden: false,
            ..._.cloneDeep(clonedResponse) // important to clone deep
          }
          newScenariosWithChildren.push(id)
        } else {
          // Remove hidden scenario child
          newChildData[visibleScenarioTab] = _.omit(newChildData[visibleScenarioTab], [id])
        }
      })
    } else {
      // Reset child data if GetComparisonData has no result
      newChildData[visibleScenarioTab] = {}
    }

    this.setState({ childData: newChildData, ScenariosWithChildren: newScenariosWithChildren })
  }

  _fetchComparisonDataWithChild(
    scenarioId,
    ComparedScenarioId,
    loadingData = { fullscreenLoading: true, Guid: undefined }
  ) {
    const {
      scenarioComparisonMainConfiguration = {},
      defaultActionParams: { payload = {} }
    } = this.props

    if (loadingData?.fullscreenLoading) {
      this.toggleLoader(true, 'Loading')
    }

    const params = this.getComparisonDataCommonRequestParameters()

    const promise = makeRequest({
      payload: {
        ...payload,
        method: 'GetComparisonDataWithChild',
        requestMethod: 'post',
        body: {
          ...params,
          ComparedScenarioId,
          MfpScenarioId: scenarioId,
          ScenariosWithChildren: [scenarioId],
          Scenarios: scenarioComparisonMainConfiguration[this.state.visibleScenarioTab].Scenarios
        }
      }
    })

    promise.catch(() => this.toggleLoader(false, undefined, loadingData?.Guid))

    return promise
  }

  _fetchComparisonConfiguration() {
    const {
      defaultActionParams: { payload = {} },
      mfpSettings: { mfpId }
    } = this.props

    this.toggleLoader(true, MESSAGES.loading)

    const promise = this.props.fetchComparisonConfiguration({
      payload: {
        ...payload,
        method: 'GetComparisonConfiguration',
        requestMethod: 'post',
        body: {
          mfpId
        }
      }
    })

    promise.catch(() => this.toggleLoader(false))

    return promise
  }

  getMergedComparisonData() {
    const data = _.cloneDeep(this.props.GetComparisonData)
    const childData = _.cloneDeep(this.state.childData)
    const visibleScenarioChildData = _.cloneDeep(childData[this.state.visibleScenarioTab])

    if (_.isEmpty(data) || _.isEmpty(data.Result) || _.isEmpty(data.Result.ScenarioData)) {
      return {}
    }

    if (_.isEmpty(childData) || _.isEmpty(visibleScenarioChildData)) {
      data.Result.ScenarioData = this.calculate(data.Result.ScenarioData)
      return data
    }

    data.Result.ScenarioData = data.Result.ScenarioData.map((ScenarioData) => {
      if (ScenarioData.ScenarioId in visibleScenarioChildData) {
        const currentScenarioData = visibleScenarioChildData[ScenarioData.ScenarioId]

        // Set row data from GetComparisonWithChild if there is (hidden or visible) child data
        // This prevents displaying outdated data after user update/lock/unlock a field.
        const currentData = currentScenarioData.Result.ScenarioData
        const singleScenarioData = currentData.length ? currentData[0] : null
        if (singleScenarioData) {
          ScenarioData = singleScenarioData
        }

        if (currentScenarioData.IsHidden) {
          ScenarioData.Children = []
        }
      }

      return ScenarioData
    })

    data.Result.ScenarioData = this.calculate(data.Result.ScenarioData)

    return data
  }

  calculate($data) {
    const clonedData = _.cloneDeep($data)

    function getComparedScenario(ComparedScenarioId) {
      return (
        clonedData.find(
          ($scenario) => ComparedScenarioId !== null && $scenario.ScenarioId === ComparedScenarioId
        ) || {}
      )
    }

    return clonedData.map((scenario) => {
      const { ComparedScenarioId } = scenario

      const { Fields: targetFields, Summaries: targetSummaries } =
        getComparedScenario(ComparedScenarioId)

      if (targetFields) {
        scenario.Fields = _.cloneDeep(scenario.Fields).map((scenarioField, scenarioFieldIndex) => {
          scenarioField.Metrics = _.cloneDeep(scenarioField.Metrics).map((metric, metricIndex) => {
            const currentValue = metric.Value
            const targetValue = targetFields[scenarioFieldIndex]?.Metrics[metricIndex]?.Value
            const val = currentValue - targetValue
            metric.PercentageDiff =
              typeof targetValue === 'undefined' || val === 0 || targetValue === 0
                ? 0
                : (val / targetValue) * 100
            return metric
          })
          return scenarioField
        })

        Object.keys(scenario.Summaries).forEach((summaryItemKey) => {
          const currentValue = scenario.Summaries[summaryItemKey].Value
          const targetValue = targetSummaries[summaryItemKey]?.Value
          const val = currentValue - targetValue
          scenario.Summaries[summaryItemKey].PercentageDiff =
            typeof targetValue === 'undefined' || val === 0 || targetValue === 0
              ? 0
              : (val / targetValue) * 100
        })
      }

      scenario.Children = _.cloneDeep(scenario.Children).map((childScenario) => {
        childScenario.Fields = _.cloneDeep(childScenario.Fields).map(
          (childField, childFieldIndex) => {
            childField.Metrics = _.cloneDeep(childField.Metrics).map((metric, metricIndex) => {
              const { PercentageDiff: parentPercentageDiff, Value: parentValue } =
                scenario.Fields[childFieldIndex]?.Metrics[metricIndex]

              let metricPercentageDiff = (metric.Value / parentValue) * 100
              metricPercentageDiff = parentPercentageDiff * (metricPercentageDiff / 100)

              metric.PercentageDiff =
                parentPercentageDiff === 0 ||
                scenario.Children.length === 0 ||
                metric.Value === 0 ||
                parentValue === 0
                  ? 0
                  : metricPercentageDiff

              metric.RatioToParent =
                metric.Value === 0 || parentValue === 0 ? 0 : (metric.Value / parentValue) * 100
              return metric
            })
            return childField
          }
        )

        Object.keys(childScenario.Summaries).forEach((summaryItemKey) => {
          const { PercentageDiff: parentSummaryPercentageDiff, Value: parentSummaryValue } =
            scenario.Summaries[summaryItemKey]

          const currentSummaryValue = childScenario.Summaries[summaryItemKey].Value

          let metricPercentageDiff = (currentSummaryValue / parentSummaryValue) * 100
          metricPercentageDiff = parentSummaryPercentageDiff * (metricPercentageDiff / 100)

          childScenario.Summaries[summaryItemKey].PercentageDiff =
            parentSummaryPercentageDiff === 0 ||
            scenario.Children.length === 0 ||
            currentSummaryValue === 0 ||
            parentSummaryValue === 0
              ? 0
              : metricPercentageDiff

          childScenario.Summaries[summaryItemKey].RatioToParent =
            childScenario.Summaries[summaryItemKey]?.Value === 0 ||
            scenario.Summaries[summaryItemKey]?.Value === 0
              ? 0
              : (childScenario.Summaries[summaryItemKey]?.Value /
                  scenario.Summaries[summaryItemKey]?.Value) *
                100
        })

        return childScenario
      })

      return scenario
    })
  }

  updateRedux(cfg) {
    return this.props.saveScenarioComparisonMainConfiguration({
      payload: cfg
    })
  }

  onMarkAsGroup(scenarioId, scenarioItemIndex) {
    const ComparisonConfigurationData = _.cloneDeep(this.state.ComparisonConfigurationData)
    ComparisonConfigurationData.WithGrouped.SelectedIndex = Number(scenarioItemIndex)
    this.setState({
      ComparisonConfigurationData
    })
  }

  onMarkAsBaseline(scenarioId) {
    const ComparisonConfigurationData = _.cloneDeep(this.state.ComparisonConfigurationData)
    ComparisonConfigurationData.WithBase.BaselineScenarioId = scenarioId
    this.setState({
      ComparisonConfigurationData
    })
  }

  onActualizeChange(scenarioId) {
    const { visibleScenarioTab = '', ComparisonConfigurationData = {} } = this.state

    const clonedComparisonConfigurationData = _.cloneDeep(ComparisonConfigurationData)
    const { Scenarios } = clonedComparisonConfigurationData[visibleScenarioTab]

    clonedComparisonConfigurationData[visibleScenarioTab].Scenarios = Scenarios.map((scenario) => {
      if (scenarioId === scenario.Id) {
        scenario.IsActualized = !scenario.IsActualized
      }
      return scenario
    })

    this.setState({
      ComparisonConfigurationData: clonedComparisonConfigurationData
    })
  }

  onMoveScenario(scenarios) {
    const { visibleScenarioTab = '', ComparisonConfigurationData = {} } = this.state

    const clonedComparisonConfigurationData = _.cloneDeep(ComparisonConfigurationData)
    clonedComparisonConfigurationData[visibleScenarioTab].Scenarios = scenarios

    this.setState({
      ComparisonConfigurationData: clonedComparisonConfigurationData
    })
  }

  onRemoveScenario(item, itemIndex, event) {
    event.preventDefault(true)
    const { visibleScenarioTab = '', ComparisonConfigurationData = {} } = this.state

    const clonedComparisonConfigurationData = _.cloneDeep(ComparisonConfigurationData)
    const currentTab = clonedComparisonConfigurationData[visibleScenarioTab]
    currentTab.Scenarios = currentTab.Scenarios.filter((scItem) => item.Id !== scItem.Id)

    // TODO Check if is active baseline remove and select baseline first
    if (visibleScenarioTab === 'WithBase' && item.Id === currentTab.BaselineScenarioId) {
      if (currentTab.Scenarios.length) {
        currentTab.BaselineScenarioId = currentTab.Scenarios[0].Id
      } else {
        currentTab.BaselineScenarioId = null
      }
    }

    // TODO Check if is active baseline remove and select baseline first
    if (currentTab === 'WithGrouped' && itemIndex === currentTab.SelectedIndex) {
      if (currentTab.Scenarios.length) {
        currentTab.SelectedIndex = 0
      } else {
        currentTab.SelectedIndex = null
      }
    }

    clonedComparisonConfigurationData[visibleScenarioTab] = currentTab

    this.setState({
      ComparisonConfigurationData: clonedComparisonConfigurationData
    })
  }

  onAddScenario(selectedId, event) {
    event.preventDefault(true)

    const {
      visibleScenarioTab = '',
      ComparisonConfigurationData = {},
      activeScenarioFullList = []
    } = this.state

    const scenario = activeScenarioFullList.find((item) => item.Id === Number(selectedId))

    const clonedComparisonConfigurationData = _.cloneDeep(ComparisonConfigurationData)
    clonedComparisonConfigurationData[visibleScenarioTab].Scenarios.push(scenario)

    this.setState({
      ComparisonConfigurationData: clonedComparisonConfigurationData,
      isVisibleActiveScenarioFullList: false,
      query: ''
    })
  }

  onChangeScenarioTab(visibleScenarioTab, makeRequest, event) {
    event.preventDefault(true)

    const clonedComparisonConfigurationData = _.cloneDeep(this.state.ComparisonConfigurationData)
    clonedComparisonConfigurationData.ComparisonType =
      clonedComparisonConfigurationData[visibleScenarioTab].ComparisonType

    this.setState(
      {
        activeChildrenFilter: {},
        visibleScenarioTab,
        ComparisonConfigurationData: clonedComparisonConfigurationData
      },
      () => {
        if (makeRequest) {
          this.updateRedux(clonedComparisonConfigurationData).then(() => {
            this.toggleLoader(true, MESSAGES.loading)
            this._fetchComparisonData().then(() => {
              this.setState({ isVisibleScenarios: false })
              this.handleResponse()
            })
          })
        }
      }
    )
  }

  onToggleScenariosVisibility(isVisibleScenarios, event) {
    event.preventDefault(true)
    this.setState({ backupVisibleScenarioTab: this.state.visibleScenarioTab })
    this.setState({ isVisibleScenarios })
  }

  onCloseScenarios(event) {
    event.preventDefault(true)
    this.setState({
      ComparisonConfigurationData: _.cloneDeep(this.props.scenarioComparisonMainConfiguration),
      isVisibleScenarios: false,
      visibleScenarioTab: this.state.backupVisibleScenarioTab
    })
  }

  onToggleSplitVisibility(isVisibleSplitValue, event) {
    event.preventDefault(true)
    this.setState({ isVisibleSplitValue })
  }

  onToggleRatioVisibility(isVisibleRatioValue, event) {
    event.preventDefault(true)
    this.setState({ isVisibleRatioValue })
  }

  onToggleRatiosVisibility(areVisibleRatiosValues, event) {
    event.preventDefault(true)
    this.setState({ areVisibleRatiosValues })
  }

  onChangeView(view, event) {
    event.preventDefault(true)
    this.setState({ view })
  }

  onChangeMillionView() {
    this.setState({ isMillion: !this.state.isMillion })
  }

  onMetricChanged(item, index) {
    const {
      scenarioComparisonSettings: { selectedMetricFields = [] }
    } = this.props

    const clonedSelectedMetricFields = _.cloneDeep(selectedMetricFields)
    const { DisplayName, Name, IsUpdateAllowed, Format = '' } = item
    clonedSelectedMetricFields[index] = { DisplayName, Name, IsUpdateAllowed, Format }

    this.props
      .saveScenarioComparisonSettings({
        payload: {
          selectedMetricFields: clonedSelectedMetricFields
        }
      })
      .then(() => this._fetchComparisonData().then(() => this.handleResponse()))
  }

  getOrderedWithBaseScenarios(WithBase) {
    const oldScenarios = [...WithBase.Scenarios]
    const { BaselineScenarioId } = WithBase
    const selectedScenario = oldScenarios.find((scenario) => scenario.Id === BaselineScenarioId)
    const Scenarios = oldScenarios.filter((scenario) => scenario.Id !== BaselineScenarioId)
    if (selectedScenario) {
      Scenarios.unshift(selectedScenario)
    }
    return Scenarios
  }

  saveScenarioComparison($config) {
    const {
      id = '',
      catalogId = 0,
      pluginStates: $pluginStates = [],
      GetComparisonConfiguration: { Result = {} } = {}
    } = this.props

    const pluginStateDefaultConfig = _.pick(Result, [
      'ScenarioList',
      'WithBase',
      'WithGrouped',
      'WithPrevious'
    ])

    const { visibleScenarioTab = '', ComparisonConfigurationData = {} } = this.state

    const clonedComparisonConfigurationData = _.cloneDeep(ComparisonConfigurationData)
    const currentTab = clonedComparisonConfigurationData[visibleScenarioTab]

    if (visibleScenarioTab === 'WithGrouped') {
      if (currentTab.Scenarios.length < 2) {
        slvyToast.warning({
          message: MESSAGES.please_select_at_least_two_values,
          options: { containerId: 'dp' }
        })
        return
      }
    }

    if (visibleScenarioTab === 'WithBase') {
      if (
        !currentTab.Scenarios.length ||
        !currentTab.Scenarios.find((scenario) => scenario.Id === currentTab.BaselineScenarioId)
      ) {
        slvyToast.warning({
          message: MESSAGES.please_select_at_least_one_scenario_and_select_a_baseline,
          options: { containerId: 'dp' }
        })
        return
      }
    }

    const oldWithBaseScenarios = $config.WithBase
    $config.WithBase.Scenarios = this.getOrderedWithBaseScenarios($config.WithBase)

    const execute = () => {
      const scenarioComparisonPluginStates = $pluginStates.filter(
        (item) => item.name === 'ScenarioComparisonState'
      )

      const { config: { state: $$pluginState = {} } = {} } = !_.isEmpty(
        scenarioComparisonPluginStates
      )
        ? _.last(scenarioComparisonPluginStates)
        : {}

      const { config: pluginStateConfig = {} } = $$pluginState

      const pluginState = {
        name: 'ScenarioComparisonState',
        pluginId: id,
        catalogId,
        config: {
          state: {
            config: $config,
            defaultConfig: pluginStateDefaultConfig
          },
          stateId: '0000',
          createDate: new Date()
        }
      }

      const fetchData = () => {
        this.updateRedux(_.cloneDeep($config)).then(() => {
          this.toggleLoader(true, MESSAGES.loading)
          this._fetchComparisonData().then(() => {
            this.setState({ isVisibleScenarios: false })
            this.handleResponse()
            slvyToast.success({
              message: MESSAGES.your_changes_have_been_saved_successfully,
              options: { containerId: 'dp' }
            })
          })
        })
      }

      if (_.isEmpty(pluginStateConfig)) {
        this.props.createPluginState(`create_scenario_comparison_${id}`, id, pluginState)
        fetchData()
      } else {
        const $pluginState = _.last(scenarioComparisonPluginStates)

        // changed check
        const $clonedConfig = _.cloneDeep($config)
        // with care sorting
        $clonedConfig.WithBase.Scenarios = _.cloneDeep(oldWithBaseScenarios)

        if (!_.isEqual($clonedConfig, pluginStateConfig)) {
          this.props.updatePluginState(
            `update_scenario_comparison_${id}`,
            $pluginState.id,
            pluginState
          )
          fetchData()
        } else {
          slvyToast.warning({
            message: MESSAGES.you_must_have_changes_to_save,
            options: { containerId: 'dp' }
          })
        }
      }
    }

    this.setState({ ComparisonConfigurationData: _.cloneDeep($config) }, () => execute())
  }

  openScenarioDropdown(event) {
    event.preventDefault(true)

    const {
      ComparisonConfigurationData,
      ComparisonConfigurationData: { ScenarioList = [] } = {},
      visibleScenarioTab = ''
    } = this.state

    const activeScenarioList = _.cloneDeep(
      ComparisonConfigurationData[visibleScenarioTab].Scenarios
    )
    const activeScenarioFullList = _.pullAllBy([...ScenarioList], [...activeScenarioList], 'Id')

    this.setState({
      activeScenarioFullList: _.sortBy(activeScenarioFullList, (item) => item.Name.toLowerCase()),
      isVisibleActiveScenarioFullList: true
    })

    if (!activeScenarioFullList.length) {
      slvyToast.warning({
        message: `<strong>${MESSAGES.there_is_no_scenario_to_add}</strong>`,
        options: { containerId: 'dp' }
      })
    }
  }

  getActualizedScenarioListAsBoolean() {
    const ComparisonConfigurationData = _.cloneDeep(this.state.ComparisonConfigurationData)
    return ComparisonConfigurationData[this.state.visibleScenarioTab].Scenarios.map(
      (scenario) => scenario.IsActualized
    )
  }

  getActualizedScenarioById(ScenarioId) {
    const ComparisonConfigurationData = _.cloneDeep(this.state.ComparisonConfigurationData)
    return Boolean(
      ComparisonConfigurationData[this.state.visibleScenarioTab].Scenarios.find(
        (scenario) => scenario.IsActualized && scenario.Id === ScenarioId
      )
    )
  }

  onUpdate(params, isComparedScenario, ComparedScenarioId) {
    const { buttonFormat } = this.props

    const { Result: ComparisonData = {} } = this.getMergedComparisonData()

    const { Headers = [] } = ComparisonData
    const $params = {
      ActualScenarioList: this.getActualizedScenarioListAsBoolean(),
      BaselineScenarioId: params.BaselineScenarioId,
      BaselineValue: params.BaselineValue,
      LeftKeys: [...params.LeftFields],
      MetricField: params.MetricField,
      MfpScenarioId: params.MfpScenarioId,
      NewValue: params.NewValue,
      OldValue: params.OldValue,
      TopKey: Headers[params.fieldIndex],
      UpdateType: buttonFormat === 'percentage' ? 1 : 0,
      WithActual: this.getActualizedScenarioById(params.ScenarioId),
      Guid: params?.Guid
    }

    this.props.handleUpdate($params, (response) => {
      const { Errors = [] } = response
      if (!Errors.length) {
        this.reloadData(isComparedScenario, ComparedScenarioId, params.MfpScenarioId, {
          fullscreenLoading: false,
          Guid: params?.Guid
        })
      } else {
        this.toggleLoader(false, undefined, params?.Guid)
      }
    })
  }

  onNodeAction(params, isComparedScenario, ComparedScenarioId) {
    const { Result: ComparisonData = {} } = this.getMergedComparisonData()

    const LeftKeys = [...params.LeftFields]

    const { Headers = [] } = ComparisonData
    const $params = {
      // LeftKeys,
      BaselineScenarioId: params.BaselineScenarioId,
      MfpScenarioId: params.MfpScenarioId,
      endPoint: params.endPoint,
      fieldName: Headers[params.fieldIndex],
      name: params.name
    }

    this.props.handleNodeAction(LeftKeys, $params, (response) => {
      const { Errors = [] } = response

      this.toggleLoader()

      if (!Errors.length) {
        // Approve is an async method and so no need to reload page immediately
        if (params.endPoint === 'LockMfpNode' || params.endPoint === 'UnlockMfpNode') {
          this.reloadData(isComparedScenario, ComparedScenarioId, params.MfpScenarioId)
        }
      } else {
        slvyToast.warning({
          message: Errors[0],
          title: MESSAGES.warning,
          options: { containerId: 'dp' }
        })
      }
    })
  }

  reloadData(isComparedScenario, ComparedScenarioId, MfpScenarioId, loadingData) {
    const {
      state: { ScenariosWithChildren, childData, visibleScenarioTab }
    } = this

    if (!isComparedScenario) {
      const childId = ScenariosWithChildren.find((item) => item === MfpScenarioId)
      const hasChild = typeof childId !== 'undefined'
      if (hasChild) {
        const isVisibleChild = childData[visibleScenarioTab][childId].IsHidden === false
        if (isVisibleChild) {
          this.onRefreshScenariosWithChildren(Number(childId), ComparedScenarioId, loadingData)
          return
        }
      }
    }

    // fetch comparison when it's a hidden or not compared scenario
    this._fetchComparisonData(loadingData).then(() => this.handleResponse())
  }

  onQueryChange(event) {
    this.setState({ query: event.target.value })
  }

  getFilteredScenarios(rawScenarios) {
    let { query = '' } = this.state
    query = query.trim()

    if (query) {
      return rawScenarios.filter((item) => item.Name.toLowerCase().includes(query.toLowerCase()))
    }
    if (query === '') {
      return rawScenarios
    }
    return []
  }

  getIconClass(view) {
    switch (view) {
      case 'WithGrouped':
        return 'slvy-ui-icon-for-group'
      case 'WithPrevious':
        return 'slvy-ui-icon-tree-back'
      default:
        return 'slvy-ui-icon-baseline'
    }
  }

  rowClicked(LeftHierarchy, IsActualized, ScenarioTableName, event) {
    event.stopPropagation()

    const {
      scenarioComparisonSettings: { selectedMetricFields = [] }
    } = this.props

    const PivotFields = selectedMetricFields.map((item) => item.Name).filter((item) => item)

    this.props.rowClicked({
      IsActualScenario: IsActualized ? 1 : 0,
      LeftHierarchy,
      PivotField: PivotFields[0],
      PivotFields: PivotFields.toString(),
      ScenarioTableName
    })
  }

  onFilterScenariosChildren({ params: activeChildrenFilter, shouldFilter }) {
    if (shouldFilter) {
      const GetComparisonData = this.getMergedComparisonData()
      const { Result: ComparisonData = {} } = GetComparisonData || {}
      const ScenariosWithChildren =
        ComparisonData?.ScenarioData?.map(({ ScenarioId }) => ScenarioId) || []

      this.setState({ activeChildrenFilter })

      this.backupScenariosWithChildren = [...this.state.ScenariosWithChildren]

      if (_.isEqual(this.state.ScenariosWithChildren, ScenariosWithChildren)) {
        return
      }

      this.setState({ ScenariosWithChildren }, () => {
        this._fetchComparisonData().then(() => this.handleResponse())
      })
    } else {
      const { visibleScenarioTab, childData } = this.state

      const newChildData = _.cloneDeep(childData)
      Object.keys(newChildData[visibleScenarioTab]).forEach((id) => {
        id = Number(id)
        const childId = this.backupScenariosWithChildren.find((item) => item === id)
        newChildData[visibleScenarioTab][id].IsHidden = !childId
      })

      this.setState({
        childData: newChildData,
        activeChildrenFilter: {},
        ScenariosWithChildren: [...this.backupScenariosWithChildren]
      })
    }
  }

  render() {
    const {
      props: {
        buttonFormat,
        isRowClickedEventOn,
        isScenarioEditable,
        shouldShowConfirmApprovedCellUpdate,
        viewMode,
        missingRowLockMessage,
        scenarioComparisonSettings: { metricFields = [], selectedMetricFields = [] }
      } = {},
      state: {
        activeChildrenFilter,
        ComparisonConfigurationData,
        activeScenarioFullList,
        areVisibleRatiosValues,
        isMillion,
        isVisibleActiveScenarioFullList,
        isVisibleRatioValue,
        isVisibleScenarios,
        isVisibleSplitValue,
        view,
        visibleScenarioTab,
        ScenariosWithChildren,
        ComparisonConfigurationData: {
          WithBase: { BaselineScenarioId } = {},
          WithGrouped: { SelectedIndex } = {}
        } = {},
        query = ''
      },
      currenciesOptions
    } = this

    const isWithBaseVisible = visibleScenarioTab === 'WithBase'
    const activeScenarioList = !_.isEmpty(ComparisonConfigurationData)
      ? ComparisonConfigurationData[visibleScenarioTab].Scenarios
      : []

    const defaultMetricField = selectedMetricFields[0]
    const onScenarioClick = isWithBaseVisible
      ? this.onMarkAsBaseline.bind(this)
      : this.onMarkAsGroup.bind(this)

    const GetComparisonData = this.getMergedComparisonData()

    const { Result: ComparisonData = {} } = GetComparisonData || {}

    const { Headers = [], ScenarioData = [] } = ComparisonData || {}

    const [{ Fields: _Fields = [] } = {}] = ScenarioData
    const [{ Metrics: _Metrics = [] } = {}] = _Fields
    const cellMetricLength = _Metrics.length

    const isPercentage = view === 'percentage'
    const getActiveTabClass = ($val) => ($val === visibleScenarioTab ? '-active' : '')

    const isWithGroupedVisible = visibleScenarioTab === 'WithGrouped'
    const getActiveGroupClass = (scenarioItemIndex) => {
      return isWithGroupedVisible && SelectedIndex <= scenarioItemIndex ? '-stripped' : ''
    }

    const filteredActiveScenarioFullList = this.getFilteredScenarios(activeScenarioFullList)

    return (
      <div
        className={cx('-scenario-comparison-inner', { '-compact-view': areVisibleRatiosValues })}
      >
        <div className="actions-top">
          <MetricSelection
            metricFields={metricFields}
            selectedMetricFields={selectedMetricFields}
            onMetricChanged={this.onMetricChanged.bind(this)}
          />
          <div className="filter-group">
            <ViewOptions
              areVisibleRatiosValues={areVisibleRatiosValues}
              isMillion={isMillion}
              isPercentage={isPercentage}
              isVisibleRatioValue={isVisibleRatioValue}
              isVisibleSplitValue={isVisibleSplitValue}
              onChangeMillionView={this.onChangeMillionView.bind(this)}
              onChangeView={this.onChangeView.bind(this)}
              onToggleRatioVisibility={this.onToggleRatioVisibility.bind(this)}
              onToggleRatiosVisibility={this.onToggleRatiosVisibility.bind(this)}
              onToggleSplitVisibility={this.onToggleSplitVisibility.bind(this)}
            />
          </div>
        </div>
        <div className="actionsGroup">
          <div className="group-right">
            <span className="iconBaseline">
              <span className={`${this.getIconClass(visibleScenarioTab)} -icon -parent-icon`} />
              <div className="titleEdit">
                <div className="actionsGroup -top">
                  <div className="group-left">
                    <a
                      className="-icon"
                      onClick={this.onChangeScenarioTab.bind(this, 'WithBase', true)}
                    >
                      <span className="slvy-ui-icon-baseline" />
                    </a>
                    <a
                      className="-icon"
                      onClick={this.onChangeScenarioTab.bind(this, 'WithPrevious', true)}
                    >
                      <span className="slvy-ui-icon-tree-back" />
                    </a>
                    <a
                      className="-icon"
                      onClick={this.onChangeScenarioTab.bind(this, 'WithGrouped', true)}
                    >
                      <span className="slvy-ui-icon-for-group" />
                    </a>
                  </div>
                </div>
              </div>
            </span>
            <OverlayTrigger
              delay={{ show: 200, hide: 100 }}
              placeholder="right"
              tooltip="Open Scenario Editor"
            >
              <span
                className="slvy-ui-icon-cog-outline -icon"
                onClick={this.onToggleScenariosVisibility.bind(this, true)}
              />
            </OverlayTrigger>
          </div>
        </div>
        <div className="slvy-ui-table-v2">
          {isVisibleScenarios ? (
            <div className="scenarioEditor">
              <div className="actionsGroup -top">
                <div className="group-left">
                  <a
                    className="-icon"
                    onClick={this.onChangeScenarioTab.bind(this, 'WithBase', false)}
                  >
                    <span className={`slvy-ui-icon-baseline ${getActiveTabClass('WithBase')}`} />
                  </a>
                  <a
                    className="-icon"
                    onClick={this.onChangeScenarioTab.bind(this, 'WithPrevious', false)}
                  >
                    <span
                      className={`slvy-ui-icon-tree-back ${getActiveTabClass('WithPrevious')}`}
                    />
                  </a>
                  <a
                    className="-icon"
                    onClick={this.onChangeScenarioTab.bind(this, 'WithGrouped', false)}
                  >
                    <span
                      className={`slvy-ui-icon-for-group ${getActiveTabClass('WithGrouped')}`}
                    />
                  </a>
                </div>
                <div className="group-right">
                  <a className="-icon close" onClick={this.onCloseScenarios.bind(this)}>
                    <span className="slvy-ui-icon-times-extra-lt" />
                  </a>
                </div>
              </div>
              <ScenarioMove
                BaselineScenarioId={BaselineScenarioId}
                activeScenarioList={activeScenarioList}
                cellMetricLength={cellMetricLength}
                getActiveGroupClass={getActiveGroupClass}
                isWithBaseVisible={isWithBaseVisible}
                isWithGroupedVisible={isWithGroupedVisible}
                onActualizeChange={this.onActualizeChange.bind(this)}
                onMoveScenario={this.onMoveScenario.bind(this)}
                onRemoveScenario={this.onRemoveScenario.bind(this)}
                onScenarioClick={onScenarioClick.bind(this)}
              />
              <div className="actionsGroup -bottom">
                <div className="group-left">
                  <a className="-icon">
                    <span
                      className="slvy-ui-icon-plus-fill"
                      onClick={this.openScenarioDropdown.bind(this)}
                    />
                  </a>
                </div>
                <div className="group-right">
                  <a
                    className="save"
                    onClick={this.saveScenarioComparison.bind(this, ComparisonConfigurationData)}
                  >
                    Save
                  </a>
                </div>
                {isVisibleActiveScenarioFullList && activeScenarioFullList.length ? (
                  <div ref={this.scenariolistRef} className="addScenario-popup">
                    <div className="mb-2">
                      <FormControl
                        placeholder={MESSAGES.search}
                        size="sm"
                        type="search"
                        value={query}
                        onChange={this.onQueryChange.bind(this)}
                      />
                    </div>
                    <ul>
                      {filteredActiveScenarioFullList.length === 0 ? (
                        <li className="not-found">
                          <strong className="fw-bold">{query}</strong> is not found!
                        </li>
                      ) : (
                        filteredActiveScenarioFullList.map(
                          (filteredScenario, filteredScenarioIndex) => {
                            return (
                              <li
                                key={filteredScenarioIndex}
                                onClick={this.onAddScenario.bind(this, filteredScenario.Id)}
                              >
                                {filteredScenario.Name}
                              </li>
                            )
                          }
                        )
                      )}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          <SoftTimeline Headers={Headers} />
          <ScenarioTable
            BaselineScenarioId={BaselineScenarioId}
            GroupedSelectedIndex={SelectedIndex}
            ScenarioData={ScenarioData}
            ScenariosWithChildren={ScenariosWithChildren}
            activeChildrenFilter={activeChildrenFilter}
            activeScenarioList={activeScenarioList}
            areVisibleRatiosValues={areVisibleRatiosValues}
            buttonFormat={buttonFormat}
            currenciesOptions={currenciesOptions}
            defaultMetricField={defaultMetricField}
            isMillion={isMillion}
            isPercentage={isPercentage}
            isRowClickedEventOn={isRowClickedEventOn}
            isScenarioEditable={isScenarioEditable}
            isVisibleRatioValue={isVisibleRatioValue}
            isVisibleSplitValue={isVisibleSplitValue}
            missingRowLockMessage={missingRowLockMessage}
            selectedMetricFields={selectedMetricFields}
            shouldShowConfirmApprovedCellUpdate={shouldShowConfirmApprovedCellUpdate}
            viewMode={viewMode}
            visibleScenarioTab={visibleScenarioTab}
            onFilterScenariosChildren={this.onFilterScenariosChildren.bind(this)}
            onNodeAction={this.onNodeAction.bind(this)}
            onRowClicked={this.rowClicked}
            onToggleScenariosWithChildren={this.onToggleScenariosWithChildren.bind(this)}
            onUpdate={this.onUpdate.bind(this)}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scenarioComparisonSettings: state.scenarioComparisonSettings.scenarioComparisonSettings,
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    scenarioComparisonMainConfiguration:
      state.scenarioComparisonMainConfiguration.scenarioComparisonMainConfiguration,
    GetComparisonData: state.getComparisonData.GetComparisonData,
    GetComparisonConfiguration: state.getComparisonConfiguration.GetComparisonConfiguration,
    mfpSettings: state.mfpSettings.mfpSettings
  }
}

const mapDispatchToProps = {
  saveScenarioComparisonSettings,
  resetScenarioComparisonSettings,
  saveScenarioComparisonMainConfiguration,
  setActionLoader,
  fetchComparisonData,
  saveGetComparisonData,
  fetchComparisonConfiguration,
  saveGetComparisonConfiguration
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioComparison)
