import { emptyObject } from '../../constants'
import { RootGridState } from './useInitialState.types'

export const defaultState: RootGridState = {
  theme: 'balham',
  configurationState: emptyObject,
  gridState: emptyObject
} as const

export const maxExpandedRowGroupLimit = 100 as const
