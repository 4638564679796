Ext.define('Spui.view.tracking.TrackingController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.spui-view-tracking-trackingcontroller',
  /**
   * Called when the view is created
   */
  listen: {
    component: {
      'spui-view-tracking-leftside-dataview-statusreport': {
        loadStatusReport: 'onLoadStatusReport'
      },
      'spui-view-tracking-navigation-main': {
        showspot: 'onShowSpot'
      },
      'spui-view-tracking-navigation-spotgrid': {
        statuschanged: 'onRefrehTracking'
      }
    },
    controller: {
      'spui-view-tracking-tracking': {
        editWizard: 'onEditWizard'
      }
    }
  },
  init: function () {
    var me = this,
      view = me.getView(),
      spotRecord = view.spotRecord,
      viewModel = me.getViewModel()
    viewModel.set('isDistributionTab', false)
    viewModel.set('isContractTab', false)
    viewModel.set('spotRecord', spotRecord)
  },
  onSelectStatusReport: function (dataview, selected, eOpts) {
    var me = this,
      viewModel = me.getViewModel(),
      spotStore = viewModel.getStore('TrackingSpotStore'),
      grid = me.lookup('gridmain'),
      record = selected[0]
    grid.setGridType(record.get('ShortTitle'))
    grid.setLoading(tt('Yükleniyor'))
    spotStore.clearFilter()
    if (selected.length > 0) {
      var StatusId = selected[0].get('StatusId'),
        ProcessLevel = selected[0].get('StatusId')
      spotStore.filter('StatusId', StatusId)
      var columns = Spui.view.Tracking.SpotListColumns.getColumns(ProcessLevel)
      grid.reconfigure(spotStore, columns)
    }
    setTimeout(function () {
      grid.setLoading(false)
    }, 300)
  },
  onRefrehTracking: function () {
    var me = this,
      viewModel = me.getViewModel(),
      statusReport = me.lookup('statusreport').getStore(),
      SpotStore = viewModel.getStore('TrackingSpotStore')
    statusReport.load()
  },
  onEditWizard: function (cmp, rowIndex, colIndex, item, e, record, row) {
    var me = this,
      view = me.getView(),
      viewModel = me.getViewModel()

    view.fireEvent('editWizard', me, record)
  },
  onLoadStatusReport: function () {
    this.getViewModel().getStore('SpotListStore').load()
  },
  onShowSpot: function (grid, record) {
    var gridStore = grid.getStore(),
      datas = gridStore.getDatas()
    if (datas.length == 0) {
      this.getViewModel().set('spotRecord', null)
    } else {
      this.getViewModel().set('spotRecord', record)

      var MaterialRecord = Ext.create('Spui.data.model.Material', {})

      this.getViewModel().set('materialRecord', MaterialRecord)

      MaterialRecord.set('MaterialCode', record.get('MaterialCode'))
      MaterialRecord.load()
    }
  }
})
