import { Form } from 'react-bootstrap'
import { SlvyFormInput, SlvyFormSelect } from '../../../../../../components'
import { ILoadingProps } from './Loading.types'

const data = [
  { label: 'True', value: true },
  { label: 'False', value: false }
]

const Loading = ({
  onSave,
  params: { action = true, text = 'Please Wait...' } = {}
}: ILoadingProps) => {
  const handleTextChange = (newText: string) => {
    onSave({
      action,
      text: newText
    })
  }

  const handleActionChange = (newAction: string) => {
    onSave({
      text,
      action: JSON.parse(newAction)
    })
  }

  const selectValue = data.find((item) => item.value === action)?.value ?? null

  return (
    <Form aria-label="Overlay Settings" className="p-3">
      <SlvyFormSelect
        columns={{ label: 12, select: 12 }}
        data={data}
        label="Overlay Action"
        noRowMarginBottom={!action}
        placeholder="select"
        size="sm"
        value={selectValue}
        onSelect={(newOptionVal: string) => handleActionChange(newOptionVal)}
      />
      {action ? (
        <SlvyFormInput
          noRowMarginBottom
          columns={{ input: 12, label: 12 }}
          label="Text"
          maxLength="50"
          placeholder="Enter text"
          size="sm"
          value={text}
          onChange={handleTextChange}
        />
      ) : null}
    </Form>
  )
}

export default Loading
