import React, { Component } from 'react'
import _ from 'lodash'
import { getCoverStyle, getCoverKey, format, getCoverViolations } from '../utils'

export default class CoverBox extends Component {
  render() {
    const {
      data = {},
      coverSettings: {
        coverLabel = '',
        cover = '',
        minCover = '',
        maxCover = '',
        formatType = '0,0'
      } = {},
      className: styleName = '',
      coverViolationIndexes
    } = this.props

    let {
      [getCoverKey(cover, 'Cover')]: Cover = 0,
      [getCoverKey(minCover, 'MinCover')]: MinCover = 0,
      [getCoverKey(maxCover, 'MaxCover')]: MaxCover = 0,
      IssueValues
    } = data

    let coverViolations = [false, false]
    if (!_.isEmpty(IssueValues)) {
      coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
    }
    const coverStyle = getCoverStyle(Cover, MinCover, ...coverViolations)
    const isInfinity = Cover === 'Infinity' || Cover > 500

    // TODO: Make format return float instead of string
    // without breaking anything else...
    MinCover = parseFloat(format(MinCover, formatType))
    MaxCover = parseFloat(format(MaxCover, formatType))
    Cover = isInfinity ? MaxCover : parseFloat(format(Cover, formatType))

    const covers = _.sortBy([MinCover, Cover, MaxCover])
    const coverArr = _.range(_.first(covers), _.last(covers) + 1, 0.1)

    return (
      <div>
        <label>{coverLabel || 'Stock Cover (Day)'}</label>
        <div className="-cover-box">
          {isInfinity && <i className="-infinity">∞</i>}
          {
            <ul className={_.isEmpty(styleName) ? '-cover-list' : styleName}>
              {_.map(coverArr, (coverItem) => {
                coverItem = parseFloat(format(coverItem, formatType))
                const isMinCover = MinCover === coverItem
                const isCover = Cover === coverItem
                const isMaxCover = MaxCover === coverItem
                const currentClassName = isCover
                  ? '-cover'
                  : isMinCover
                  ? '-min-cover'
                  : isMaxCover
                  ? '-max-cover'
                  : ''
                const isActive = covers.indexOf(coverItem) > -1

                return (
                  <li key={coverItem} className={`${currentClassName}`}>
                    {isActive && <span style={isCover ? coverStyle : {}}>{coverItem}</span>}
                  </li>
                )
              })}
            </ul>
          }
        </div>
      </div>
    )
  }
}
