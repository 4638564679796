import _request from '../utils/_superagent'
import { API_URL } from '@/constants'

export const makeRequest = (action) => {
  const { pluginId = '', method = '', body = {}, requestMethod = 'get' } = action

  const url = `${API_URL}/data/${pluginId}/invoke/${method}`

  return new Promise((resolve, reject) => {
    let initialRequest = _request[requestMethod](url)
    if (requestMethod === 'post') {
      initialRequest = initialRequest.send(body)
    }

    initialRequest.then((response) => resolve(response)).catch((error) => reject(error))
  })
}
