Ext.define('tuiv2.cmp.DemandSummaryTmp', {
  extend: 'Ext.Component',
  xtype: 'tuiv2-DemandSummaryTmp',
  draggable: true,
  initComponent: function () {
    var me = this
    if (!me.data) {
      var map = me.rootView.down('mainmap').getMap()
      map.closePopup()
      Ext.toast({
        html: 'An error occured retrieving demand data',
        title: 'Error',
        width: 300,
        align: 'tr',
        iconCls: 'fa fa-check',
        closable: true,
        timeout: 4000
      })
      return
    }
    me.callParent()
  },
  listeners: {
    click: {
      fn: function (event, tile) {
        var me = this.component
        var view = me.rootView
        var viewModel = view.getViewModel()
        var controller = view.getController()
        var data = me.getData()
        var facilityKey = data.Id

        // Get loads
        var loadIds = []
        // Get loads from opened load cards
        for (var property in controller.popups) {
          if (controller.popups.hasOwnProperty(property) && property.startsWith('load')) {
            loadIds.push(property.replace('load', ''))
          }
        }
        // Get loads from context data
        data.Loads.forEach(function (load) {
          loadIds.push(load.Id)
        })
        loadIds = _.uniq(loadIds)

        var openedLoadCards = viewModel.get('openedLoadCards')
        if (controller.popups[me.popupId]) {
          controller.popups[me.popupId].bringToFront()
        }

        // Close demand card
        if (event.target.classList.contains('slvy-ui-icon-times-lt')) {
          var mainMap = me.rootView.down('mainmap')

          var map = mainMap.getMap()
          if (controller.popups[me.popupId]) {
            map.closePopup(controller.popups[me.popupId])
            delete controller.popups[me.popupId]
          } else {
            me.destroy()
          }
          if (me.demandToList) {
            controller.removeOptionCard(me.demandToList)
            me.demandToList.destroy()
            me.demandToList = undefined
          }
          return
        }

        // Change view between order view and pallet view
        if (event.target.classList.contains('changeView')) {
          data.viewIndex = data.viewIndex == 1 ? 0 : 1
          me.dataSet(data)
          return
        }

        // Remove all orders
        if (event.target.classList.contains('unloadDemandZone')) {
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to unserve demand zone?',
            function (reasonCode) {
              controller.onRemoveDemandZoneFromAllLoads(
                reasonCode,
                [facilityKey],
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.reloadDemandTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )

          return
        }

        // service contract

        if (
          event.target.classList.contains('serviceContractOptions') ||
          event.target.classList.contains('planPallet')
        ) {
          var facilityKey = event.target.getAttribute('FacilityKey')

          var ServiceContractId = event.target.getAttribute('ServiceContractId')

          var loadId = event.target.getAttribute('FromLoadId')

          var model = null

          var popups = document.getElementsByClassName('popup ')

          if (event.target.classList.contains('serviceContractOptions')) {
            if (!me.selectedServiceContractIds) {
              me.selectedServiceContractIds = []
            }
            if (!me.selectedLoadIds) {
              me.selectedLoadIds = []
            }
            let index = me.selectedServiceContractIds.indexOf(ServiceContractId)
            if (index > -1) {
              event.target.classList.remove('-selected')
              me.selectedServiceContractIds.splice(index, 1)
              me.selectedLoadIds.splice(ServiceContractId, 1)
            } else {
              me.selectedServiceContractIds.push(ServiceContractId)
              me.selectedLoadIds[ServiceContractId] = loadId
              event.target.classList.add('-selected')
            }
            var totalVolume = 0

            var totalWeight = 0

            var totalNumPallet = 0
            me.selectedServiceContractIds.forEach((key) => {
              var selectedDemand = data.OrderDetails.find(function (order) {
                return order.Id == key
              })
              selectedDemand.Items.forEach((item) => {
                totalVolume = totalVolume + item.Volume
                totalWeight = totalWeight + item.Weight
                totalNumPallet = totalNumPallet + item.NumPallet
              })
            })
            me.selectedVolume = totalVolume
            me.selectedWeight = totalWeight
            me.selectedPallet = totalNumPallet

            model = {
              class: 'dot',
              FacilityKey: facilityKey,
              ServiceContractId: ServiceContractId,
              ltlLoadId: loadId,
              SelectedVolume: me.formatNumber(me.selectedVolume),
              SelectedWeight: me.formatNumber(me.selectedWeight),
              SelectedPallet: me.formatNumber(me.selectedPallet),
              Loads: loadIds.map(function (id) {
                return {
                  Color: tuiv2.Colors.getColorByName(id),
                  Id: id
                }
              }),
              FromLoadId: loadId,
              Title: data.Title,
              UnloadClass: 'unloadServiceContract',
              ToLtlClass: 'loadServiceContractToLTL',
              CreateLoadClass: 'createNewLoad',
              LoadClass: 'loadServiceContract',
              CloseClass: 'closeDemandToLoad'
            }

            if (me.selectedServiceContractIds.length > 0 && !me.demandToList) {
              me.demandToList = Ext.create({
                xtype: 'tuiv2-demandToList',
                renderTo: event.target.parentNode.parentNode.parentNode.parentNode.children[0],
                data: model,
                rootView: me.rootView
              })

              openedLoadCards.push(me.demandToList)
              viewModel.set('openedLoadCards', openedLoadCards)
            } else if (me.selectedServiceContractIds.length == 0 && me.demandToList) {
              controller.removeOptionCard(me.demandToList)
              me.demandToList.destroy()
              me.demandToList = undefined
            }
            if (me.demandToList) {
              me.demandToList.setData(model)
            }
          } else {
            model = {
              class: 'square',
              FacilityKey: facilityKey,
              ServiceContractId: ServiceContractId,
              Loads: loadIds.map(function (id) {
                return {
                  Color: tuiv2.Colors.getColorByName(id),
                  Id: id
                }
              }),
              FromLoadId: loadId,
              UnloadClass: 'unloadPallet',
              Title: data.Title,
              ToLtlClass: 'loadPalletToLTL',
              CreateLoadClass: 'createNewLoad',
              LoadClass: 'loadPallet',
              CloseClass: 'closeDemandToLoad'
            }
          }
          return
        }

        // Remove order
        if (event.target.classList.contains('unloadServiceContract')) {
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to unload?',
            function (reasonCode) {
              var serviceContractId = me.selectedServiceContractIds
              controller.onRemoveServiceContractFromAllLoads(
                reasonCode,
                serviceContractId,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.reloadDemandTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )

          return
        }

        // Move order to another load
        if (event.target.classList.contains('loadServiceContract')) {
          var loadId = event.target.getAttribute('loadId')
          var serviceContractId = me.selectedServiceContractIds

          if (loadId) {
            loadIds = _.uniq(loadIds.concat(loadId))
          }
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to serve from ' + loadId + '?',
            function (reasonCode) {
              controller.onSwitchServiceContractToLoad(
                reasonCode,
                facilityKey,
                serviceContractId,
                loadId,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.reloadDemandTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('serviceContractDetail')) {
          var serviceContractId = event.target.getAttribute('Id')

          controller.onRetrieveOrderPopupData(serviceContractId, function (orderDetailData) {
            orderDetailData.Title = event.target.getAttribute('title')
            orderDetailData.SubTitle = data.Title

            Ext.create({
              xtype: 'tuiv2-demandDetailSummaryTmp',
              renderTo: me.el,
              data: orderDetailData,
              rootView: me.rootView
            })
          })

          return
        }

        // Move order to new load (To LTL)
        if (event.target.classList.contains('loadServiceContractToLTL')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var serviceContractId = me.selectedServiceContractIds
          var loadId = event.target.getAttribute('LoadId')
          if (loadId) {
            loadIds = _.uniq(loadIds.concat(loadId))
          }
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to convert LTL?',
            function (reasonCode) {
              controller.onLoadToLTL(
                reasonCode,
                [facilityKey],
                loadId,
                serviceContractId,
                null,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )

          return
        }

        // Move order to new load (to selected Transportation Mode)
        if (event.target.classList.contains('createNewLoad')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var serviceContractId = me.selectedServiceContractIds
          var loadId = me.selectedLoadIds

          viewModel.set('facilityKey', [facilityKey])
          viewModel.set('orderNumber', serviceContractId)

          if (loadId) {
            loadIds = _.uniq(loadIds.concat(loadId))
          }
          controller.createLoadMessageBox(
            'Confirm',
            'Are you sure you want to create load?',
            function (originLocation, transportationModeName, reasonCode) {
              controller.onCreateNewLoad(
                facilityKey,
                loadId,
                serviceContractId,
                originLocation,
                transportationModeName,
                reasonCode,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )
          return
        }

        // Change view between order view and load view
        if (event.target.parentElement.classList.contains('loadOptions')) {
          data.viewIndex = 2
          me.dataSet(data)
          return
        }

        // Change view between order view and load detail view
        if (event.target.parentElement.classList.contains('selectLoadOption')) {
          var currentElement = event.target.parentElement
          var currentId = currentElement.getAttribute('id')
          if (currentId == 'order') {
            data.viewIndex = 0
            data.viewType = 'order'
            data.LoadId = null
          } else {
            data.viewIndex = 3
            data.viewType = 'load'
            data.LoadId = currentId
          }

          me.dataSet(data)
          return
        }

        // Change view between TODO
        if (event.target.classList.contains('loadChangeView')) {
          data.viewIndex = data.viewIndex == 4 ? 3 : 4
          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('openLoadCard')) {
          var mainmap = view.down('mainmap')

          var loadId = event.target.getAttribute('loadId')

          var viewModel = view.getViewModel()

          var loadStore = viewModel.get('loadTreeStore')

          var references = view.getReferences()

          var tree = references['loadTree']

          var treeView = tree.getView()

          var drawRoute = viewModel.get('drawRoute')

          if (controller.popups['load' + loadId]) {
            if (drawRoute) {
              controller.openLoadPopup(null, loadId)
            } else {
              var latlng = viewModel.get('disabledMapLatlng')
              controller.openLoadPopup(latlng, loadId)
            }

            return
          }

          loadStore.load(function () {
            var node = loadStore.findNode('LoadName', loadId)

            treeView.lockedView.setChecked(node, true)
            // fires reset of demands.
            treeView.lockedView.ownerCt.ownerCt.fireEvent('itemclick', treeView, null, null, null, {
              getTarget: function () {
                return true
              }
            })
          })

          if (drawRoute) {
            controller.asyncCaller(
              function () {
                if (mainmap.loadStopDetails[loadId + '-0']) {
                  return true
                } else {
                  return false
                }
              },
              function () {
                controller.openLoadPopup(null, loadId)
              },
              400
            )
          } else {
            var latlng = viewModel.get('disabledMapLatlng')
            controller.openLoadPopup(latlng, loadId)
          }

          return
        }

        if (event.target.classList.contains('closePalletOptions')) {
          event.target.parentElement.parentElement.style.display = 'none'
          return
        }

        if (event.target.classList.contains('unloadPallet')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var fromLoadId = event.target.getAttribute('FromLoadId')
          var serviceContractId = event.target.getAttribute('ServiceContractId')

          if (fromLoadId) {
            loadIds = _.uniq(loadIds.concat(fromLoadId))
          }

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to load pallet?',
            function (reasonCode) {
              controller.onRemovePalletsFromLoad(
                reasonCode,
                facilityKey,
                serviceContractId,
                fromLoadId,
                1,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('loadPallet')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var loadId = event.target.getAttribute('LoadId')
          var fromLoadId = event.target.getAttribute('FromLoadId')
          var serviceContractId = event.target.getAttribute('ServiceContractId')

          if (loadId) {
            loadIds = _.uniq(loadIds.concat(loadId))
          }
          if (fromLoadId) {
            loadIds = _.uniq(loadIds.concat(fromLoadId))
          }

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to load pallet?',
            function (reasonCode) {
              controller.onServePalletOnLoad(
                reasonCode,
                facilityKey,
                serviceContractId,
                1,
                loadId,
                fromLoadId,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('loadPalletToLTL')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var loadId = event.target.getAttribute('FromLoadId')
          var serviceContractId = event.target.getAttribute('ServiceContractId')

          if (loadId) {
            loadIds = _.uniq(loadIds.concat(loadId))
          }

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to convert LTL?',
            function (reasonCode) {
              controller.onLoadToLTL(
                [facilityKey],
                loadId,
                serviceContractId,
                1,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('createNewLoad')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var loadId = event.target.getAttribute('FromLoadId')
          var serviceContractId = event.target.getAttribute('ServiceContractId')

          if (loadId) {
            loadIds = _.uniq(loadIds.concat(loadId))
          }

          controller.createLoadMessageBox(
            'Confirm',
            'Are you sure you want to create load?',
            function (originLocation, transportationModeName, reasonCode) {
              controller.onCreateNewLoad(
                [facilityKey],
                loadId,
                serviceContractId,
                originLocation,
                transportationModeName,
                reasonCode,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('planDemandZone')) {
          var facilityKey = data.Id

          var model = null

          // removes previus options
          if (popups && popups.length > 0) {
            for (let index = 0; index < popups.length; index++) {
              const element = popups[index]
              element.remove()
            }
          }

          model = {
            Id: facilityKey,
            Loads: loadIds.map(function (id) {
              return {
                Color: tuiv2.Colors.getColorByName(id),
                Id: id
              }
            }),
            UnloadClass: 'unloadPallet',
            ToLtlClass: 'loadPalletToLTL',
            CreateLoadClass: 'createNewLoad',
            LoadClass: 'switchDemandZoneToLoad',
            CloseClass: 'closePalletOptions'
          }

          var tpl = new Ext.XTemplate(
            '<ul class="popup" style="display:block;">' +
              '                 <li><span class="slvy-ui-icon-times-extra-lt {CloseClass}"  index={#}></span></li>' +
              '<tpl for="Loads">' +
              '                 <li  class="{parent.LoadClass}" loadId="{Id}" FacilityKey="{parent.Id}">' +
              '                   <div class="square" loadId="{Id}" FacilityKey="{parent.Id}" style="background:{Color}"></div>{Id}' +
              '                 </li>' +
              '</tpl>' +
              '</ul>'
          )
          var planDemandZoneTpl = tpl.apply(model)

          event.target.insertAdjacentHTML('beforeend', planDemandZoneTpl)

          return
        }
        if (event.target.classList.contains('switchDemandZoneToLoad')) {
          var loadId = event.target.getAttribute('loadId')
          var facilityKey = event.target.getAttribute('FacilityKey')

          if (loadId) {
            loadIds = _.uniq(loadIds.concat(loadId))
          }

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to serve demand zone from ' + loadId + '?',
            function (reasonCode) {
              controller.onSwitchDemandZoneToLoad(
                reasonCode,
                facilityKey,
                loadId,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              )
            },
            this
          )

          return
        }
        if (event.target.classList.contains('closeDemandToLoad')) {
          me.dataSet(data)
          return
        }
        if (event.target.classList.contains('slvy-ui-icon-select-all')) {
          var list = event.currentTarget.getElementsByClassName('serviceContractOptions')
          if (!event.currentTarget.classList.contains('checked')) {
            for (let i = 0; i < list.length; i++) {
              const element = list[i]
              if (!element.classList.contains('-selected')) {
                element.click()
              }
            }
            event.currentTarget.classList.add('checked')
          } else {
            for (let i = 0; i < list.length; i++) {
              const element = list[i]
              if (element.classList.contains('-selected')) {
                element.click()
              }
            }
            event.currentTarget.classList.remove('checked')
          }
        }
        if (event.target.classList.contains('slvy-ui-icon-circle-check')) {
          var list = event.currentTarget.getElementsByClassName('serviceContractOptions')
          var isChecked = event.currentTarget.classList.contains('checked')

          for (let i = 0; i < list.length; i++) {
            const option = list[i]
            const isNonPlannedBackground = option.attributes[0].value === 'background:#d9d9d9'

            if (isChecked) {
              if (option.classList.contains('-selected') && isNonPlannedBackground) {
                option.click()
              }
            } else {
              if (!option.classList.contains('-selected') && isNonPlannedBackground) {
                option.click()
              }
            }
          }

          event.currentTarget.classList.toggle('checked')
        }
      },
      element: 'el'
    }
  },
  tpl: new Ext.XTemplate(
    '<div class="card -demand">' + '<div class="demandToList"></div>',
    '<div class="titleBar ">' +
      '<p class="title">{Title}</p>' +
      '<div class="card-close"><span class="slvy-ui-icon-times-lt"></span></div>' +
      '</div>' +
      '    <div class="header -first">' +
      '      <div class="info">' +
      '         <div class="top">' +
      '           <ul class="demand-container">' +
      '             <li class="volume">{[this.formatNumber(values.TotalVolume)]}P</li>' +
      '             <li class="weight">{[this.formatNumber(values.TotalWeight)]} {[this.getUnit("BaseWeightUOM")]}</li>' +
      '             <li class="demand-pallet-count">{TotalPallet}P</li>' +
      '           </ul>' +
      '         </div>' +
      '         <div class="bottom">' +
      '         <div class="field1">{Hier2}</div>' +
      '         <div class="field2">{Att1}</div>' +
      '         <div class="field3">{Att3}</div>' +
      '         </div>' +
      '<tpl if="backButtonEnable">' +
      '        <div class="left">' +
      '           <ul class="header-actions">' +
      '             <li class="icon"><span class="slvy-ui-icon-arrow-left-extra-lt destroy"></span></li>' +
      '             <li>selected area</li>' +
      '           </ul>' +
      '        </div>' +
      '</tpl>' +
      '      </div>' +
      '    </div>' +
      // order toolbar
      '<tpl if="viewType == \'order\' || viewIndex == 2">' +
      '    <div class="header -second -overview">' +
      '            <div class="info">' +
      '                <div class="left">' +
      '                    <div class="id">Order List</div>' +
      '                </div>' +
      '               <tpl if="viewIndex == 2">' +
      '                 <div class="right loadOptions">' +
      '                    <div class="down-icon selectLoadOption" id="order"><span class="slvy-ui-icon-caret-top"></span></div>' +
      '                 </div>' +
      '               </tpl>' +
      '               <tpl if="viewIndex != 2">' +
      '                 <div class="right loadOptions">' +
      '                    <div class="down-icon loadOptions"><span class="slvy-ui-icon-caret-down"></span></div>' +
      '                 </div>' +
      '               </tpl>' +
      '            </div>' +
      '      <div class="action-container">' +
      '        <ul class="actions">' +
      '           <li><i class="slvy-ui-icon-select-all fs-4" data-qtip="Select All"></i></li>' +
      '           <li><i class="slvy-ui-icon-circle-check" data-qtip="Select Non-Planned"></i></li>' +
      '          <li><i class="slvy-ui-icon-info" data-qtip="Info"></i></li>' +
      '          <li><span class="slvy-ui-icon-scenario-change-extra-lt planDemandZone" data-qtip="Serve All Delivery"></span></li>' +
      '<tpl if="viewIndex == 1">' +
      '          <li><i class="slvy-ui-icon-list-extra-lt overview-list changeView" data-qtip="Pallet View"></i></li>' +
      '</tpl>' +
      '<tpl if="viewIndex == 0">' +
      '          <li><i class="slvy-ui-icon-grid-outline overview-grid changeView" data-qtip="Order View"></i></li>' +
      '</tpl>' +
      '          <li><span class="slvy-ui-icon-trash-can unloadDemandZone" data-qtip="Unload All Order"></span></li>' +
      '        </ul>' +
      '      </div>' +
      '    </div>' +
      '</tpl>' +
      // load toolbar
      '<tpl if="viewIndex == 3 || viewIndex == 4">' +
      '<tpl if="viewType == \'load\'">' +
      '   <div class="header -first" style="background:{[this.getColorCode(values.LoadId)]};">' +
      '      <div class="info" style="background:{[this.getColorCode(values.LoadId)]};">' +
      '         <div class="left">' +
      '           <div class="selectLoadOption" id="order">' +
      '            <span class="slvy-ui-icon-arrow-left-extra-lt"></span> ' +
      '           </div>' +
      '<tpl for="Loads">' +
      ' <tpl if="Id == parent.LoadId">' +
      '            <div class="id">{SubTitle1}</div>' +
      '            <div class="name">{SubTitle2}</div>' +
      '            <div class="distance">{[this.formatNumber(values.LoadedWeight)]} {[this.getUnit("BaseWeightUOM")]}</div>' +
      '            <div class="cost">{[this.formatNumber(values.Cost)]} {[this.getUnit("BaseCurrencyUOM")]}</div>' +
      ' </tpl>' +
      '</tpl>' +
      '         </div>' +
      '         <div class="right">' +
      '           <ul class="count-container">' +
      '<tpl for="Loads">' +
      ' <tpl if="Id == parent.LoadId">' +
      '           <li class="total-count">{[this.formatNumber(values.MaxPallet)]}P</li>' +
      '          <li class="palette-count">{[this.getLoadedPalletCount(parent.OrderDetails, parent.LoadId)]}P</li>' +
      '          <li class="empty-count">{[values.MaxPallet - values.LoadedPallet]}P</li>' +
      ' </tpl>' +
      '</tpl>' +
      '           </ul>' +
      '         </div>' +
      '      </div>' +
      '   </div>' +
      '</tpl>' +
      '   <div class="header -second" style="background:{[this.getColorCode(values.LoadId)]};">' +
      '      <div class="action-container">' +
      '        <ul class="actions">' +
      '          <li><i class="slvy-ui-icon-info" data-qtip="Info"></i></li>' +
      '          <li><i class="slvy-ui-icon-merge"></i></li>' +
      '          <li><i class="slvy-ui-icon-change"></i></li>' +
      '<tpl if="viewIndex == 3">' +
      '          <li><i class="slvy-ui-icon-grid ekol-1-grid loadChangeView"></i></li>' +
      '</tpl>' +
      '<tpl if="viewIndex == 4">' +
      '          <li><i class="slvy-ui-icon-list-regular ekol-1-list loadChangeView"></i></li>' +
      '</tpl>' +
      '        </ul>' +
      '      </div>' +
      '    </div>' +
      '</tpl>' +
      // demand view
      '<tpl if="viewIndex == 0">' +
      '     <div class="grid-container-tui">' +
      '<tpl for="OrderDetails">' +
      '         <div class="point">' +
      '             <ul class="sq">' +
      '                 <li style="background:{[this.getDemandColor(values.Items)]}" class="serviceContractOptions" ServiceContractId="{Id}" FacilityKey="{parent.Id}" FromLoadId="{[this.getLoadId(values.Items)]}" li>' +
      '             </ul>' +
      '             <p class="serviceContractDetail card-tooltip" id="{Id}"><span class="tooltiptext">Name: <b>{[this.isNull(values.SubTitle)]}</b> </br> Duedate: <b>{[this.isNull(values.Description)]}</b> </br> Priority: <b>{Priority}</b> </br> Cases: <b>{[this.getTooltip(values.Items,"Pieces")]}</b> </br> Pallet: <b>{[this.getTooltip(values.Items,"Volume")]}</b> </br> Weight: <b>{[this.getTooltip(values.Items,"Weight")]}</b> </br> </span>{Title} </p>' +
      '         </div>' +
      '         <ul class="card-grid">' +
      '<tpl exec="values.parent = parent;"></tpl>' +
      '<tpl for="this.getPalletArray(values.Items)">' +
      '             <li  class="{[values.class == "-unplanned" ? "" : values.class]}"><div  class="planPallet {[values.class == "-unplanned" ? "-unplanned" : ""]}" ServiceContractId="{parent.Id}" FacilityKey="{parent.parent.Id}" FromLoadId="{loadId}" style="width:{palletWidth}%;background:{color};"></div>' +
      '             </li>' +
      '</tpl>' +
      '         </ul>' +
      '</tpl>' +
      '     </div>' +
      '</tpl>' +
      // demand view as pallette
      '<tpl if="viewIndex == 1">' +
      '     <div class="grid-container-tui">' +
      '      <ul class="card-grid">' +
      '<tpl for="this.getPalletContainer(values.OrderDetails)">' +
      '         <li  class="{[values.class == "-unplanned" ? "" : values.class]}"><div class="{[values.class == "-unplanned" ? "-unplanned" : ""]}" style="width:{palletWidth}%;background:{color};"></div></li>' +
      '</tpl>' +
      '      </ul>' +
      '    </div>' +
      '</tpl>' +
      // load options view
      '<tpl if="viewIndex == 2">' +
      '   <div class="grid-container-tui dropdown-menu-tui">' +
      '    <div class="point link-overview selectLoadOption" id="order">' +
      '      <ul class="sq"><li class=""></li></ul>' +
      '      <p>Order List</p>' +
      '    </div>  ' +
      '<tpl for="Loads">' +
      '    <div class="point link-1 selectLoadOption" id="{Id}">' +
      '      <ul class="sq"><li class="" style="background:{[this.getColorCode(values.Id)]};"></li></ul>' +
      '      <p>{SubTitle1}<i class="slvy-ui-icon-export-box openLoadCard" style="float: right; padding-left: 5px;" loadId="{Id}""></i> </p>' +
      '      <p class="p-size">{MaxPallet}P</p>' +
      '    </div>  ' +
      '</tpl>' +
      '  </div>' +
      '</tpl>' +
      // load view
      '<tpl if="viewIndex == 3">' +
      '     <div class="grid-container-tui">' +
      '<tpl for="OrderDetails">' +
      '         <div class="point">' +
      '             <ul class="sq">' +
      '                 <li style="background:{[this.getDemandColor(values.Items)]}" class="serviceContractOptions" ServiceContractId="{Id}" FacilityKey="{parent.Id}" LoadId="{parent.LoadId}" ></li>' +
      '             </ul>' +
      '               <ul class="popup -rd">' +
      '                 <li><span class="slvy-ui-icon-times-extra-lt closePalletOptions"></span></li>' +
      '<tpl for="parent.LoadIds">' +
      '                 <li style="background:{[this.getColorCode(values.Id)]}" class="loadServiceContract" loadId="{Id}"  serviceContractId="{parent.Id}"></li>' +
      '</tpl>' +
      '                 <li><span class="slvy-ui-icon-trash-can unloadServiceContract" ServiceContractId="{Id}"></span></li>' +
      '                 <li><span class="slvy-ui-icon-truck loadServiceContractToLTL" ServiceContractId="{Id}" FacilityKey="{parent.Id}" LoadId="{parent.LoadId}"></span></li>' +
      '             </ul>' +
      '             <p class="serviceContractDetail" id="{Id}" title="{Title}" values="{Values}">{Title}</p>' +
      '         </div>' +
      '         <ul class="card-grid">' +
      '<tpl exec="values.parent = parent;"></tpl>' +
      '<tpl for="this.getPalletArray2(values.Items, parent.LoadId)">' +
      '             <li  class="{[values.class == "-unplanned" ? "" : values.class]}"><div class="planPallet {[values.class == "-unplanned" ? "-unplanned" : ""]}" FacilityKey="{parent.parent.Id}" ServiceContractId="{parent.Id}" FromLoadId="{loadId}" style="width:{palletWidth}%;background:{color};"></div>' +
      '             </li>' +
      '</tpl>' +
      '         </ul>' +
      '</tpl>' +
      '     </div>' +
      '</tpl>' +
      // load pallet view
      '<tpl if="viewIndex == 4">' +
      '     <div class="grid-container-tui">' +
      '         <ul class="card-grid">' +
      '<tpl for="this.getPalletForLoad(values.OrderDetails, values.Loads, values.LoadId)">' +
      '             <li  class="{[values.class == "-unplanned" ? "" : values.class]}"><div class="planPallet {[values.class == "-unplanned" ? "-unplanned" : ""]}" ServiceContractId="{parent.Id}" FacilityKey="{parent.parent.Id}" FromLoadId="{loadId}" style="width:{palletWidth}%;background:{color};"></div></li>' +
      '</tpl>' +
      '         </ul>' +
      '     </div>' +
      '</tpl>' +
      '  </div>',
    {
      getPalletContainer: function (orders) {
        var items = orders.reduce((a, b) => a.concat(b.Items), [])
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        var array = []
        for (let index = 0; index < items.length; index++) {
          const item = items[index]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned',
                loadId: item.LoadId
              })
            } else if (item.LoadId != null && item.LoadId.length > 0) {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg',
                loadId: item.LoadId
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9',
                loadId: item.LoadId
              })
            }
            palletCount = palletCount - 1
          }
        }
        return array
      },
      getPalletArray: function (items) {
        var array = []
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        items = items.sort(function (item) {
          return item.LoadId ? -1 : 1
        })
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned',
                loadId: null
              })
            } else if (item.LoadId != null && item.LoadId.length > 0) {
              array.push({
                palletWidth: 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg',
                loadId: item.LoadId
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9',
                loadId: null
              })
            }

            palletCount = palletCount - 1
          }
        }
        return array
      },
      getPalletArray2: function (items, loadId) {
        var array = []
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        items = items.sort(function (item) {
          return item.LoadId ? -1 : 1
        })
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned',
                loadId: item.LoadId
              })
            } else if (item.LoadId == loadId) {
              array.push({
                palletWidth: 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg',
                loadId: item.LoadId
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9',
                loadId: item.LoadId
              })
            }

            palletCount = palletCount - 1
          }
        }
        return array
      },

      getLoadedPalletCount: function (items, loadId) {
        var items = items.reduce((a, b) => a.concat(b.Items), [])
        var allLoadPallets = items.filter(function (item) {
          return item.LoadId == loadId
        })

        return allLoadPallets.reduce((a, b) => a + b.NumPallet, 0)
      },
      getPalletForLoad: function (items, loads, loadId) {
        var items = items.reduce((a, b) => a.concat(b.Items), [])
        var allLoadPallets = items.filter(function (item) {
          return item.LoadId == loadId
        })

        var sumOfLoadPallet = allLoadPallets.reduce((a, b) => a + b.NumPallet, 0)
        var currentLoad = loads.filter(function (item) {
          return item.Id == loadId
        })[0]
        var palletCount = currentLoad.MaxPallet
        var loadedPallet = currentLoad.LoadedPallet - sumOfLoadPallet

        var array = []
        while (palletCount > 0) {
          if (sumOfLoadPallet > 0) {
            array.push({
              palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
              color: tuiv2.Colors.getColorByName(loadId),
              class: '-percent-bg'
            })
            sumOfLoadPallet--
            palletCount--
          } else if (loadedPallet > 0) {
            array.push({
              palletWidth: 100,
              color: '#d9d9d9'
            })
            loadedPallet--
            palletCount--
          } else {
            array.push({
              palletWidth: 100,
              color: 'transparent',
              class: '-empty'
            })

            palletCount--
          }
        }
        return array
      },
      getDemandColor(items) {
        var loadIds = items.map(function (item) {
          return item.LoadId
        })

        loadIds = loadIds.filter(function (item) {
          if (item) return true

          return false
        })

        if (loadIds.length == 1) {
          return tuiv2.Colors.getColorByName(loadIds[0])
        } else {
          return '#d9d9d9'
        }
      },
      getLoadId(items) {
        var loadIds = items.map(function (item) {
          return item.LoadId
        })
        return loadIds
      },
      getColorCode: function (id) {
        return tuiv2.Colors.getColorByName(id)
      },
      getTooltip: function (data, value) {
        var stringTooltip = ''

        var numberTooltip = 0

        if (data.length == 1) {
          if (typeof data[0][value] === 'string') {
            stringTooltip += data[0][value] + ','
          } else {
            numberTooltip += data[0][value]
          }
        } else {
          data.forEach((item) => {
            if (typeof item[value] === 'string') {
              stringTooltip += item[value] + ','
            } else {
              numberTooltip += item[value]
            }
          })
        }

        if (stringTooltip != '' && stringTooltip.includes(',')) {
          return stringTooltip.slice(0, stringTooltip.length - 1)
        } else {
          return this.formatNumber(numberTooltip)
        }
      },
      formatNumber: function (value) {
        if (value == Number.NaN || value == Number.MAX_VALUE) value = 0
        if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

        return Ext.util.Format.number(value, '0,000.00')
      },
      isNull: function (value) {
        if (!value) {
          return '-'
        } else {
          return value
        }
      },
      getUnit: function (value) {
        var planInfo = tuiv2.Units.getPlanInfo()
        if (planInfo) {
          return planInfo[value]
        }
      }
    }
  ),

  toastResult: function (result) {
    if (result.IsSuccess) {
      Ext.toast({
        html: 'Operation is completed successfully.',
        title: 'Information',
        width: 300,
        align: 'tr',
        iconCls: 'fa fa-check',
        closable: true,
        timeout: 4000
      })
    } else {
      Ext.toast({
        html: 'Operation failed',
        title: 'Error',
        width: 300,
        align: 'tr',
        iconCls: 'fa fa-cross',
        closable: true,
        timeout: 4000
      })
    }
  },

  refresh: function () {
    var me = this

    var view = me.rootView

    var controller = view.getController()

    var data = me.getData()

    controller.onRetrieveDemandPopupData(data.Id, function (data) {
      data.viewIndex = 0
      data.viewType = 'order'
      me.dataSet(data)
    })
  },
  dataSet: function (data) {
    var me = this

    var view = me.rootView

    var controller = view.getController()
    if (me.demandToList) {
      controller.removeOptionCard(me.demandToList)
      me.demandToList.destroy()
      me.demandToList = undefined
    }

    me.setData(data)
    me.selectedServiceContractIds = []
    me.selectedLoadIds = []
  },
  formatNumber: function (value) {
    if (value == Number.NaN || value == Number.MAX_VALUE) value = 0
    if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

    return Ext.util.Format.number(value, '0,000.00')
  }
})
