export default `({
    title: tt('Resim'),
    xtype: 'panel',
    border: true,
    header: false,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b>' + tt('Campaign Picture') + '</b>'
        }]
    }],
    style: {
        "padding": "1px",
        background: '#fff',
    },
    padding: '0 0 0 0',
    viewModel: {},
    flex: 1,
    layout: 'center',
    items: [{
        xtype: 'image',
        autoEl: 'div',
        style: {
            padding: '10px',
            //   "background": "#e0e0e0"
        },
        listeners: {
            render: function () {
                var me = this;
                me.getEl().on({
                    loads: function () {
                        var owner = me.ownerCt;
                                                if (owner) {
                            owner.updateLayout();
                            if (owner.isFloating()) {
                                owner.center();
                            }
                        }
                        if (me.isFloating()) {
                            me.center();
                        }
                    }
                });
            },
            el: {
                dblclick: function (p, e) {
                    this.component.ownerCt.imageUploadWin()
                },
                scope: 'this'
            }
        },
        border: true,
        width: 778,
        height: 300,
        cls: 'center-image',
        bind: {
            src: '{spotRecord.ImgURL}'
        },
    }],
    imageUploadWin: function () {
        var me = this,
            viewModel = this.getViewModel();
        viewModel = viewModel.getParent();
        var spotRecord = viewModel.get('spotRecord');
        win = Ext.create('Ext.window.Window', {
            title: 'Image Cropper',
            spotRecord: spotRecord,
            width: 840,
            height: 680,
            autoShow: true,
            layout: 'border',
            modal: true,
            items: [{
                xtype: 'form',
                region: 'center',
                //layout: 'fit',
                items: [{
                    xtype: 'spui-util-imageupload-cropper',
                     resizerMaxWidth: 2000,
                     resizerMinWidth: 200,
                     resizerMaxHeight: 2000,
                     resizerMinHeight: 200,
                    // maxFileHeight: 2000,
                    // maxFileSize: 5000000,
                    cropperStyle: {
                        opacity: '0.5'
                    },
                    constrainToImage: false,
                    listeners: {
                        imageLoaded: function (imageCropper) {
                            imageCropper.up('window').down('displayfield[role=fileInfo]').setValue('File name: ' + imageCropper.getFileName() + '<br />' + 'File size: ' + imageCropper.getFileSize() + '<br />' + 'Measures: ' + imageCropper.getFileWidth() + 'x' + imageCropper.getFileHeight());
                        }
                    }
                }]
            }],
            listeners: {
                show: function (win) {
                    var cropper = win.down('spui-util-imageupload-cropper'),
                        uploadCfg = cropper.getFileUploadFieldConfig(cropper),
                        infoCfg = cropper.getInfoButtonConfig(cropper);
                    win.add({
                        xtype: 'panel',
                        layout: {
                            align: 'stretch',
                            type: 'vbox'
                        },
                        bodyPadding: 10,
                        region: 'east',
                        width: 250,
                        items: [
                            uploadCfg, {
                                xtype: 'displayfield',
                                role: 'fileInfo',
                                flex: 1
                            }, {
                                xtype: 'button',
                                text: tt('Upload'),
                                handler: function (button) {
                                    var form = button.up('window').down('form'),
                                        vals = form.getValues();
                                    if (form.isValid()) {
                                        Ext.Ajax.request({
                                            url: Spui.Global.getApiUrl() + 'upload/logo',
                                            method: 'POST',
                                            params: {
                                                "image": Ext.util.JSON.encode(vals)
                                            },
                                            success: function (conn, response, options, eOpts) {
                                                var result = Ext.JSON.decode(conn.responseText);
                                                if (result.success) {
                                                    if (win.spotRecord) {
                                                        win.spotRecord.set('ImgURL', result.filename);
                                                    }
                                                    win.fireEvent('upladed', result.filename);
                                                    win.close();
                                                } else {}
                                            },
                                            failure: function (conn, response, options, eOpts) {
                                                // TODO get the 'msg' from the json and display it
                                            }
                                        });
                                    }
                                }
                            }
                        ]
                    });
                }
            }
        });
        return win;
    },
})
`
