Ext.define('tuiv2.cmp.ChangeRunInfo', {
  extend: 'Ext.button.Button',
  alias: 'widget.tuiv2-changeruninfo',
  config: {
    menuPanel: [
      {
        xtype: 'tuiv2-treeCombo',
        fieldLabel: 'Scenario',
        queryMode: 'local',
        width: 350,
        labelWidth: 60,
        displayField: 'ScenarioName',
        labelAlign: 'left',
        emptyText: 'Scenario',
        valueField: 'ScenarioID',
        setStore: function (store) {
          var me = this,
            viewModel = me.up('tuiv2mainview').getViewModel()
          if (store != null) {
            store.on('load', function () {
              me.setValue(viewModel.get('PlanInfo.CurrentScenarioID'))
            })

            me.store = store
            store.load()
          }
        },
        bind: {
          store: '{scenarioTreeStore}'
        },
        beforeselect: function (node, record) {
          return record.get('RunInfoCount') > 0
        },
        listeners: {
          select: function () {
            var me = this,
              view = me.up('tuiv2mainview'),
              viewModel = view.getViewModel()
            viewModel.set('PlanInfo.CurrentScenarioID', me.getValue())
            viewModel.set('PlanInfo.CurrentRunInfoID', null)
          }
        }
      },
      {
        xtype: 'combo',
        anchor: '100%',
        fieldLabel: 'RunInfo',
        width: 350,
        labelWidth: 60,
        name: 'RunInfo',
        reference: 'runInfoCombo',
        emptyText: 'RunInfo',
        valueField: 'RunInfoID',
        displayField: 'RunInfoName',
        queryMode: 'local',
        bind: {
          store: '{runInfoStore}',
          value: '{PlanInfo.CurrentRunInfoID}'
        },
        listeners: {
          select: function () {
            var me = this,
              selectRunInfoID = this.getValue(),
              mainView = me.up('tuiv2mainview'),
              mainViewModel = mainView.getViewModel(),
              currentRunInfoID = mainViewModel.get('CurrentRunInfoID'),
              mainController = mainView.getController()

            if (selectRunInfoID !== 0 && selectRunInfoID !== currentRunInfoID) {
              mainViewModel.set('PlanInfo.CurrentRunInfoID', selectRunInfoID)
              mainController.onResetTable()
            }
          }
        }
      }
    ]
  },
  setUi: function (c) {
    this.setUI(c)
  },
  applyMenuPanel: function (items) {
    if (items) {
      this.menu = {
        xtype: 'menu',
        deltaY: 8,
        deltaX: -90,
        plain: true,
        shadow: true,
        closeAction: 'hide',
        items: [
          {
            xtype: 'panel',
            border: false,
            ui: 'tui-panel',
            minWidth: 300,
            header: false,
            defaults: {
              anchor: '100%'
            },
            items: items
          }
        ]
      }
    }
  }
})
