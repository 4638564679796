Ext.define('clustering.Model.ClusterSegmentModel', {
  extend: 'Ext.data.TreeModel',
  idProperty: 'Id',
  fields: [
    {
      name: 'leaf',
      defaultValue: true
    },
    {
      name: 'Color',
      convert: function (value, record) {
        return clustering.store.ClusterColors.colors[record.get('Id')]
      },
      depends: ['Id']
    },
    'Name',
    'Id',
    'IsExcluded'
  ],
  schema: {
    id: 'ClusterSegmentModelBase',
    namespace: 'clustering.model',
    urlPrefix: clustering.Global.getApiUrl(),

    proxy: {
      type: 'ajax',
      timeout: 600000,
      method: 'POST',
      useDefaultXhrHeader: false,
      cors: true,
      actionMethods: {
        create: 'POST',
        read: 'POST',
        update: 'POST',
        destroy: 'POST'
      },
      api: {
        create: '{prefix}{entityName:lowercase}/create',
        read: '{prefix}{entityName:lowercase}/read',
        update: '{prefix}{entityName:lowercase}/update',
        destroy: '{prefix}{entityName:lowercase}/destroy'
      },
      reader: {
        type: 'json',
        rootProperty: 'Children',
        totalProperty: 'total'
      },
      writer: {
        type: 'json',
        rootProperty: 'data',
        allowSingle: false,
        allDataOptions: {
          associated: false
        },
        writeAllFields: false
      },
      extraParams: {
        mainAppId: null
      }
    }
  },
  listeners: {
    beforeload: function (store) {
      var proxy = store.getProxy()
      proxy.setExtraParam('catalogId', clustering.Global.getCatalogId())
    }
  }
})
