import React, { Component } from 'react'
import _ from 'lodash'
import numeral from 'numeral'
import { SidebarCell } from './sidebar-cell'

export class SidebarSegments extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    const { removeCurrencySymbol = (a, b) => {}, current = '', segments = [] } = this.props

    return (
      <div>
        {segments.map((segmentItem, segmentIndex) => {
          const { Name, TotalSales, TotalRevenue, NumberOfStores, TotalCost, NumberOfOptions } =
            segmentItem
          const $TotalRevenue = numeral(TotalRevenue).format(current)
          const $TotalCost = numeral(TotalCost).format(current)
          const $TotalSales = removeCurrencySymbol(TotalSales, current)
          const $NumberOfStores = removeCurrencySymbol(NumberOfStores, current)

          return (
            <div key={segmentIndex} className="segment-top">
              <div className="cell-row -bgDim-05">
                <div className="cell">
                  <p>{Name}</p>
                </div>
              </div>
              <div className="cell-row -spaceBetween2">
                <SidebarCell text="Forecasted Revenue" value={$TotalRevenue} />
                <SidebarCell text="Forecasted Cost" value={$TotalCost} />
              </div>
              <div className="cell-row -spaceBetween3">
                <SidebarCell
                  iconTpl={<span className="slvy-ui-icon-box" />}
                  text="Forecasted Quantity"
                  value={$TotalSales}
                />
                <SidebarCell
                  iconTpl={<span className="slvy-ui-icon-shopping-bag-outline" />}
                  text="Number Of Stores"
                  value={$NumberOfStores}
                />
                <SidebarCell text="Number Of Options" value={NumberOfOptions} />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
