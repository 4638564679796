Ext.define('MDM.src.view.regionapp.Cmp.NewMaterial', {
  extend: 'Ext.window.Window',
  width: 1100,
  height: '70%',
  autoShow: true,
  bodyPadding: 4,
  statuful: true,
  stateId: 'DropDialog',
  modal: true,
  title: 'New Materials',
  closable: false,
  maximizable: false,
  layout: 'fit',
  userMegaRegion: '',
  defaultListenerScope: true,
  ui: 'window-inverse',
  iconCls: 'fa fa-trash-o',
  listeners: {},
  bbar: [
    '->',
    {
      text: 'Close',
      ui: 'btn-success',
      handler: function (btn) {
        btn.up('window').close()
      }
    }
  ],
  tbar: [
    {
      xtype: 'textfield',
      name: 'searchField',
      hideLabel: true,
      width: 500,
      emptyText: 'Enter search term',
      listeners: {
        specialkey: 'applySearchFilter',
        change: function (field, value) {
          field.getTrigger('clear')[value ? 'show' : 'hide']()
        }
      },
      triggers: {
        clear: {
          cls: 'x-form-clear-trigger',
          handler: 'onClearTriggerClick',
          hidden: true
        },
        search: {
          cls: 'x-form-search-trigger',
          weight: 1,
          handler: 'onSearchTriggerClick'
        }
      }
    },
    '->',
    {
      text: 'Export',
      iconCls: 'fa fa-download',
      ui: 'btn-success',
      handler: 'exportTo'
    }
  ],
  exportTo: function (btn) {
    var me = this
    var cfg = {
      type: 'excel07',
      ext: 'xlsx'
    }
    var newcfg = Ext.merge(
      {
        title: 'Material list',
        fileName: 'GridExport' + '.' + cfg.ext
      },
      btn.cfg
    )
    me.grid.saveDocumentAs(newcfg)
  },

  onBeforeDocumentSave: function (view) {
    this.timeStarted = Date.now()
    view.mask('Document is prepared for export. Please wait ...')
    Ext.log('export started')
  },

  onDocumentSave: function (view) {
    view.unmask()
    Ext.log('export finished; time passed = ' + (Date.now() - this.timeStarted))
  },

  onDataReady: function () {
    Ext.log('data ready; time passed = ' + (Date.now() - this.timeStarted))
  },
  onClearTriggerClick: function (field) {
    var me = this,
      store = me.store
    store.removeAll()
    field.setValue()
    field.getTrigger('clear').hide()
  },
  applySearchFilter: function (field, e) {
    // e.HOME, e.END, e.PAGE_UP, e.PAGE_DOWN,
    // e.TAB, e.ESC, arrow keys: e.LEFT, e.RIGHT, e.UP, e.DOWN
    var me = this,
      store = me.store

    if (e.getKey() == e.ENTER) {
      store.getProxy().setExtraParam('queryString', field.getValue())
      store.load()
    }
    if (e.getKey() == e.TAB) {
      store.getProxy().setExtraParam('queryString', field.getValue())
      store.load()
    }
  },
  onExtractDetails: function (grid, rowIndex, colIndex, item, e, record) {
    var win = Ext.create('MDM.src.view.regionapp.Cmp.ExtractDialog', {
      record: record
    })
    win.show()
  },
  onSearchTriggerClick: function (field) {
    var me = this,
      store = me.store
    store.getProxy().setExtraParam('queryString', field.getValue())
    store.load()
  },

  initComponent: function () {
    var me = this
    me.grid = Ext.create({
      xtype: 'regionapp-searckbox-grid',

      store: me.getStore()
    })
    me.items = [me.grid]
    me.callParent()
  },
  getStore: function (c) {
    this.store = Ext.create('Ext.data.Store', {
      model: 'MDM.src.view.regionapp.model.NoSupplySource',
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/RegionApp_SearchMaterialNew',
        extraParams: {},
        reader: {
          type: 'json'
        }
      },
      autoLoad: true
    })
    return this.store
  },
  onEditMaterial: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      store = me.store
    var newview = Ext.create('Ext.window.Window', {
      width: 1000,
      height: 600,
      y: 200,
      closable: false,
      layout: 'fit',
      ui: 'window-inverse',
      modal: true,
      header: false,
      iconCls: 'fa fa-pencil-square',
      title: 'Edit',
      items: [
        {
          xtype: 'materialeditmain',

          crec: record,
          listeners: {
            updated: function () {
              store.load()
            }
          }
        }
      ]
    })

    newview.show()
  },

  onDrop: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      mainGrid = grid,
      title = record.get('MaterialDescription')

    Ext.Msg.show({
      title: 'Suppress material',
      width: 500,
      msg:
        '<br> Are you sure you want to Suppress ' +
        title +
        ' from all lists and make it inactive ? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDrop(record, grid)
        }
      }
    })
  },
  doDrop: function (record, grid) {
    var me = this,
      mainGrid = grid,
      rec = record,
      store = me.store

    me.setLoading('tests')

    Ext.MessageBox.prompt('Suppress Reason', 'Reason:', function (btn, text) {
      Ext.Ajax.request({
        method: 'POST',
        url: MDM.Global.getApiUrl() + 'gillettev3/RegionApp_MDM_Material_Drop',
        params: {
          MaterialCode: record.get('MaterialCode'),
          Reason: text
        },
        success: function (response, opts) {
          me.setLoading(false)
          me.store.remove(record)
          me.fireEvent('dropped', me)
        },
        failure: function (response, opts) {
          me.setLoading(false)
          Ext.MessageBox.hide()
        }
      })
    })
  }
})
