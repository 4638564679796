import { Component } from 'react'
import _ from 'lodash'
import EditorSelector from './Editors/EditorSelector'

export default class JsonEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: {},
      fields: props.fields,
      showAdvanvedProperties: {}
    }

    this.onChange = this.onChange.bind(this)
    this.synchronizeDataDefinition = this.synchronizeDataDefinition.bind(this)

    this.getValueOfSchema = this.getValueOfSchema.bind(this)
    this.getWatchedValue = this.getWatchedValue.bind(this)

    this.visitSchema = this.visitSchema.bind(this)

    this.initialData = props.value || {}
  }

  getValueOfSchema(schema) {
    return this.visitSchema(schema, {})
  }

  getWatchedValue(schemaPath) {
    const { value } = this.state

    if (_.startsWith(schemaPath, 'root.')) {
      schemaPath = _.replace(schemaPath, 'root.', '')
    }

    let obj = { ...value }
    for (let i = 0, path = schemaPath.split('.'), len = path.length; i < len; i++) {
      obj = obj && obj[path[i]]
    }

    return obj
  }

  visitSchema(schema, value) {
    const newValue = { ...value }
    for (const key in schema.properties) {
      const subSchema = schema.properties[key]

      if (subSchema.type === 'object') {
        newValue[key] = this.visitSchema(subSchema, newValue[key] || subSchema.default || {})
      } else if (subSchema.type === 'array' && key === 'datafields') {
        const dataFields = []
        // we add an empty item, for selection to be cleared
        dataFields.push('')
        _.forEach(this.state.fields, function (value, key) {
          dataFields.push(value.fieldName)
        })

        newValue[key] = dataFields
      } else if (subSchema.type === 'array') {
        newValue[key] = newValue[key] || subSchema.default || []
      } else if (subSchema.oneOf) {
        if (_.isUndefined(newValue[key])) {
          newValue[key] = this.visitSchema(subSchema.oneOf[0], subSchema.oneOf[0].default || {})
        }
      } else if (subSchema.enum && subSchema.enum.length === 1) {
        if (_.isUndefined(newValue[key])) {
          newValue[key] = subSchema.enum[0]
        }
      } else if (this.props.diffMode !== true) {
        if (_.isUndefined(newValue[key]) && !_.isUndefined(subSchema.default)) {
          newValue[key] = subSchema.default
        }
      }
    }

    return newValue
  }

  UNSAFE_componentWillMount() {
    if (this.props.schema) {
      // We have to run plugin specific functions here
      // Or else intial value is not empty on plugin specific parts e.g. chart series
      let newValue = this.visitSchema(this.props.schema, _.cloneDeep(this.props.value))

      this.setState({ fields: this.props.fields })

      _.forEach(this.props.changedHandlers, (handler) => {
        try {
          newValue = handler(newValue, { ...this.initialData }, this.cachedValue, this)
        } catch (error) {
          console.error(error)
        }
      })

      const initialValue = this.addDataFieldsToInitialConfig()
      let mergedValue = _.merge({}, initialValue, this.props.value)
      if (this.props.initializer) {
        try {
          mergedValue = this.props.initializer(mergedValue)
        } catch (error) {
          console.error(error)
        }
      }

      this.setState({ value: mergedValue })
    }
  }

  onChange(value) {
    if (!_.isNil(value)) {
      let newValue = _.cloneDeep(value)
      _.forEach(this.props.changedHandlers, (handler) => {
        try {
          newValue = handler(newValue, this.getInitialData(), this.cachedValue, this)
        } catch (error) {
          console.error(error)
        }
      })

      this.cachedValue = _.cloneDeep(newValue)

      if (_.isFunction(this.props.onChanged)) {
        // console.log('JsonEditor -> onChange -> value', newValue)
        this.setState({ value: newValue }, this.props.onChanged(newValue))
      }
    }
  }

  synchronizeDataDefinition(schemaPath) {
    if (_.isFunction(this.props.onSynchronizeDataDefinition)) {
      const initialValue = this.state.value
      let newValue

      try {
        newValue = this.props.onSynchronizeDataDefinition(
          this.state.fields,
          initialValue,
          this,
          initialValue,
          schemaPath
        )
      } catch (error) {
        console.error(error)
        newValue = initialValue
      }

      const mergedValue = _.merge({}, initialValue, newValue)

      this.onChange(mergedValue)
    }
  }

  getInitialData() {
    return this.visitSchema(this.props.schema, this.props.value || {})
  }

  getObjectInObjectByKey(o, key) {
    for (const i in o) {
      if (o[i] !== null && typeof o[i] === 'object') {
        if (i === key) {
          return o[i]
        }
        const res = this.getObjectInObjectByKey(o[i], key)
        if (res) {
          return res
        }
      }
    }
  }

  addDataFieldsToInitialConfig() {
    return this.getInitialData()
  }

  render() {
    const { schema } = this.props
    const { value } = this.state

    // TODO : onSynchronizeDataDefinition => solve with redux ???

    return (
      <EditorSelector
        key="root"
        getWatchedValue={this.getWatchedValue}
        schema={schema}
        schemaPath="root"
        value={value}
        onChange={this.onChange}
        onSynchronizeDataDefinition={this.synchronizeDataDefinition}
      />
    )
  }
}
