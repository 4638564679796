import { fetchRecord, fetchOne, deleteRecord, updateOne } from '../crudoptV3'

const model = 'pagetemplate'

export function getTemplates(pageId) {
  return fetchOne(
    model,
    `/pagetemplate`,
    {},
    {
      key: pageId
    }
  )
}

export function getTemplateConnections(templateId) {
  return fetchOne(model, `/gettemplateconnectionlist/${templateId}`, {})
}

export function createTemplate(data) {
  return fetchRecord(model, `/pagetemplate`, {
    data
  })
}

export function updateTemplate(pageId, catalogId, templateId, templateName) {
  return fetchRecord(model, `/pagetemplate/update/${templateId}`, {
    data: {
      pageId,
      catalogId,
      templateName
    }
  })
}

export function applyTemplate(catalogId, pageId, templateId, connectionMapping) {
  return fetchRecord(
    model,
    `pagetemplate/catalog/${catalogId}/page/${pageId}/template/${templateId}/apply`,
    {
      data: {
        templateId,
        pageId,
        connectionMapping
      }
    }
  )
}

export function deleteTemplate(templateId) {
  return deleteRecord(model, `/pagetemplate/${templateId}`, {})
}

export function uploadHelpPdf(catalogId, menuId, pageId, helpPdf) {
  return updateOne(model, `catalog/${catalogId}/menu/${menuId}/page/${pageId}/uploadhelpdocument`, {
    data: helpPdf
  })
}

export function deleteHelpPdf(catalogId, menuId, pageId, helpUrl) {
  return updateOne(model, `catalog/${catalogId}/menu/${menuId}/page/${pageId}/deletehelpdocument`, {
    data: {
      helpUrl
    }
  })
}
