import React, { Component } from 'react'
import _ from 'lodash'
import Row from './row'

class Page extends Component {
  render() {
    const {
      imageBoxes,
      imageBoxNumberInRow = 1,
      imageHeight,
      imageFit,
      imageBackground
    } = this.props
    const rows = _.chunk(imageBoxes, imageBoxNumberInRow)

    let keepAspectRatioStyles = {}
    if (_.isEqual(imageHeight, 'Keep Aspect Ratio')) {
      keepAspectRatioStyles = {
        height: `calc((100% / ${rows.length}) - 33px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }
    }

    return (
      <div style={{ ...keepAspectRatioStyles }}>
        {_.map(rows, (rowImageBoxes, key) => (
          <Row
            key={key}
            handleImageClick={this.props.handleImageClick}
            imageBackground={imageBackground}
            imageBoxes={rowImageBoxes}
            imageFit={imageFit}
            imageHeight={imageHeight}
            imageNumberInRow={imageBoxNumberInRow}
          />
        ))}
      </div>
    )
  }
}
export default Page
