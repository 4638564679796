import { useMemo } from 'react'
import { CheckboxSelectionCallbackParams } from 'ag-grid-community'
import { CheckboxSelectionColDefProps } from './useCheckboxSelectionColDef.types'
import { AG_GRID_ENUMS } from '../../enums'
import { IRowData, SlvyCheckboxSelectionColDef, TrowDataValue } from '../../types'
import { CustomTooltipView } from '../../components/CustomTooltip'
import { getCellStyle } from '../../helpers'

const { CHECKBOX_SELECTION_COLUMN } = AG_GRID_ENUMS

function useCheckboxSelectionColDef(props: CheckboxSelectionColDefProps) {
  const { width, massUpdate, selectionType, rowDisplayRules } = props
  const isSelectionType = selectionType === 'Multiselect' || selectionType === 'Spreadsheet'

  return useMemo((): { isExist: boolean; col: null | SlvyCheckboxSelectionColDef } => {
    if (!massUpdate && !isSelectionType) {
      return { isExist: false, col: null }
    }

    return {
      isExist: true,
      col: {
        customInfo: {
          adding: {
            enabled: false
          },
          boolean: { trueIcon: '', falseIcon: '', trueColor: '', falseColor: '' },
          deletableCondition: '',
          columnType: 'simple',
          isExportable: false
        },
        checkboxSelection: (params: CheckboxSelectionCallbackParams<IRowData, TrowDataValue>) => {
          const {
            node: { footer, group }
          } = params
          return !(footer || group)
        },
        ...getCellStyle({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          customConfigs: {
            isRawFieldEmpty: false,
            rawField: CHECKBOX_SELECTION_COLUMN,
            cellStyle: {
              textAlign: 'center',
              backgroundColor: '',
              color: ''
            }
          },
          cellDisplayRules: [],
          rowDisplayRules,
          getCellColorsFromData: false,
          enableCellTextSelection: false
        }),
        headerCheckboxSelection: true,
        /*
         *  TODO: Find why is this cause page freeze!
         *  When using headerCheckboxSelectionFilteredOnly, page freezes
         *  We don't know why this happens,
         *  Workaround: So fix the issue, we get selected data with getSelectedRowsAfterFilterAndSort helper function.
         *  Scenario: After Selecting All and page freezes
         *  https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/8/menu/65af9bb0793b9e10e75d20f2/page/65f032ed74744548a108adc6
         *
         * */
        // headerCheckboxSelectionFilteredOnly: true,
        pinned: true,
        lockPinned: true,
        lockPosition: 'left',
        suppressMovable: true,
        suppressNavigable: true,
        cellDataType: true,
        headerComponent: () => null,
        headerName: 'SELECT ACTION',
        enableRowGroup: false,
        editable: () => false,
        resizable: false,
        width,
        flex: 0,
        sortable: false,
        field: CHECKBOX_SELECTION_COLUMN,
        colId: CHECKBOX_SELECTION_COLUMN,
        tooltipField: CHECKBOX_SELECTION_COLUMN,
        suppressColumnsToolPanel: true,
        tooltipComponent: CustomTooltipView,
        tooltipComponentParams: {
          tooltip: 'Select Row'
        }
      }
    }
  }, [isSelectionType, massUpdate, rowDisplayRules, width])
}
export default useCheckboxSelectionColDef
