export default {
  title: 'Daily Schedule',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'DailySchedule',
  description: '',
  iconClass: 'fa-calendar',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Views',
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: false
  }
}
