export default {
  tr: {
    removeTask: {
      title: 'Görev Silme',
      text: 'Bu görevi kalıcı olarak siliyorsunuz. Emin misiniz?',
      ok: 'Evet',
      cancel: 'İptal'
    },
    emp: {
      planned: 'Çalışan',
      need: 'İhtiyaç'
    },
    tooltip: {
      addTask: 'Görev ekle',
      overtime: 'Fazla mesai var',
      noOvertime: 'Fazla mesai yok'
    }
  },
  en: {
    removeTask: {
      title: 'Remove Task',
      text: 'You are removing this task permanently. Are you sure?',
      ok: 'Yes',
      cancel: 'Cancel'
    },
    emp: {
      planned: 'Planned',
      need: 'Need'
    },
    tooltip: {
      addTask: 'Add task',
      overtime: 'Overtime work',
      noOvertime: 'No overtime work'
    }
  }
}
