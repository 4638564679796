export default `({
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            html: '<b>'+ tt('Kampanya Parametreleri')+'  </b>'
        }]
    }],
    border: true,
    // header: false,
    bind:{
        title:tt('Spot Bilgilerii'),
    },
    margin: 2,
    defaults: {
        labelStyle: 'font-weight:bold;',
    },
    xtype: 'panel',
    header: false,
    bind: {
        data: {
            SalesPrice: '{spotRecord.SalesPrice}',
            PreStartDate: '{spotRecord.PreStartDate}',
            PreEndDate: '{spotRecord.PreEndDate}',
            UnitofMeasurement: '{spotRecord.UnitofMeasurement}',
            UnitsPerPackage: '{spotRecord.UnitsPerPackage}',
            MinQuantity: '{spotRecord.MinQuantity}',
            MaxQuantity: '{spotRecord.MaxQuantity}',
            MaterialDescription: '{spotRecord.MaterialDescription}',
        }
    },
    tpl: [
        '<div class="expander" style="text-align:center"> ' +
        '  <table class="table" style=" width: 98% ;min-width:100px:"> ' +
        '  <tr> ' +
        '<th colspan=4 style="text-align:center">{MaterialDescription} </td>',
        '  </tr>' +
        '    <tr> ' +
        '  <th class="r"> Sales Price </th> ' +
        '         <td class="r">{SalesPrice}</td> ' +
        '        <td class="r" colspan=2> </td>' +
        '     </tr> ' +
        '  </tr>' +
        '    <tr> ' +
        '          <th class="r">PreStartDate</th>' +
        '        <td class="r">{PreStartDate:date(tt("d-m-Y"))} </td>' +
        '         <th class="r">PreEndDate</th>' +
        '        <td class="r">{PreEndDate:date(tt("d-m-Y"))} </td>' +
        '     </tr> ' +
        '    <tr>' +
        '         <th class=r>UnitofMeasurement</th>' +
        '        <td class="r">{UnitofMeasurement}</td>' +
        '       <th class="r">UnitsPerPackage</th>' +
        '        <td class="r"> {UnitsPerPackage}</td>' +
        '     </tr>' +
        '  </tr>' +
        '    <tr>' +
        '        <th class="r"> MinQuantity </td>' +
        '        <td class="r">{MinQuantity} </td>' +
        '        <th class="r">MaxQuantity</td>' +
        '        <td class="r" >{MaxQuantity}</td>' +
        '     </tr>' +
        '  </table>' +
        '  </div>',
    ],
    itemso: [{
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            labelWidth: 100,
            layout: 'hbox',
            defaultType: 'displayfield',
            items: [{
                name: '<b>Urun Fiyati</b>',
                xtype: 'displayfield',
                flex: 1,
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                bind: {
                    value: '{spotRecord.SalesPrice}'
                },
                fieldLabel: '<b>'+tt('Satis Fiyatı')+'</b>',
                allowBlank: false,
            }, {
                flex: 1,
                xtype: 'container'
            }]
        },
        {
            xtype: 'displayfield',
            queryMode: 'local',
            displayField: 'name',
            valueField: 'abbr',
            anchor: '50%',
            bind: {
                value: '{spotRecord.UnitofMeasurement}'
            },
            fieldLabel: 'Dagitim Birimi',
            store: [
                {
                    abbr: 'KOL',
                    name: tt('Koli')
                }
            ]
        },
        {
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;',
            defaults: {
                labelStyle: 'font-weight:bold;',
                labelWidth: 150,
            },
            layout: 'hbox',
            defaultType: 'displayfield',
            items: [{
                flex: 1,
                name: 'middleInitial',
                fieldLabel: tt('Mag.Sevk Min.Miktar'),
                tooltip: tt('Magaza minimum siparis birim'),
                minValue: 0,
                step: 1,
                bind: {
                    value: '{spotRecord.MinQuantity}'
                },
            }, {
                flex: 1,
                // afterLabelTextTpl: [
                //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                // ],
                fieldLabel: 'Mag.Sevk Max.Miktar',
                margin: '0 0 0 5',
                minValue: 0,
                step: 1,
                bind: {
                    value: '{spotRecord.MaxQuantity}'
                },
            }]
        },
        {
            xtype: 'fieldcontainer',
            labelStyle: 'font-weight:bold;padding:0;',
            layout: 'hbox',
            defaultType: 'displayfield',
            defaults: {
                labelStyle: 'font-weight:bold;padding',
                labelWidth: 150,
            },
            items: [{
                flex: 1,
                name: 'middleInitial',
                fieldLabel: tt('Start Date'),
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                xtype: 'displayfield',
                format: tt('d-m-Y'),
                disabledDays: [0, 1, 2, 3, 5, 6, 7],
                minValue: new Date(),
                allowBlank: false,
                listenersd: {
                    change: function (field, value) {
                        var form = field.up('form');
                        var PreEndDateField = field.nextNode('datefield(true)'),
                            PreStartDateValue = Ext.Date.add(value, Ext.Date.DAY, 21);
                        PreEndDateField.setValue(PreStartDateValue);
                    }
                },
                bind: {
                    value: '{spotRecord.PreStartDate}'
                },
            }, {
                flex: 1,
                name: 'PreEndDate',
                afterLabelTextTpl: [
                    '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
                ],
                fieldLabel: tt('Bitis Tarihi'),
                format: tt('d-m-Y'),
                margin: '0 0 0 5',
                xtype: 'displayfield',
                disabledDays: [0, 1, 2, 3, 5, 6, 7],
                allowBlank: false,
                bind: {
                    value: '{spotRecord.PreEndDate}',
                    // minValue: Ext.Date.add('{spotRecord.PreStartDate}', Ext.Date.DAY, 21),
                },
            }]
        },
        // {
        //     xtype: 'fieldcontainer',
        //     labelStyle: 'font-weight:bold;padding:0;',
        //     layout: 'hbox',
        //     defaultType: 'textfield',
        //     items: [{
        //         flex: 1,
        //         name: 'middleInitial',
        //         fieldLabel: 'CreatedBy',
        //         disabled: true
        //     }, {
        //         flex: 1,
        //         name: 'lastName',
        //         // afterLabelTextTpl: [
        //         //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
        //         // ],
        //         fieldLabel: 'CreatedAt',
        //         allowBlank: false,
        //         disabled: true,
        //         margin: '0 0 0 5'
        //     }]
        // }
    ]
})
`
