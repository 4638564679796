import _ from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { slvyToast } from '../../../components'
import _request from './_superagent'
import { API_URL } from '@/constants'

const moment = extendMoment(Moment)

export function tMessage(title = 'Status', msg = 'Ok!', type = 'success') {
  slvyToast[_.isFunction(slvyToast[type]) ? type : 'success']({ message: msg, title })
}

export function makeRequest(action) {
  const { payload: { pluginId = '', method = '', body = {}, requestMethod = 'get' } = {} } = action

  const url = `${API_URL}/data/${pluginId}/invoke/${method}`

  return new Promise((resolve, reject) => {
    let initialRequest = _request[requestMethod](url)

    if (requestMethod === 'post') {
      initialRequest = initialRequest.send(body)
    }

    initialRequest.then((response) => resolve(response)).catch((error) => reject(error))
  })
}

/**
 * Check Filters/SeasonCode/SelectedIndex value in SeasonCode Filters
 * */
export function getIsActualSeason($filters, $values) {
  let isActualSeason = false
  const [seasonCodeFilter = null] = [...$filters].filter(({ Name = '' }) => Name === 'SeasonCode')
  if (seasonCodeFilter) {
    const { Values = [], SelectedIndices = [] } = seasonCodeFilter || {}
    const selectedSeason = _.filter(
      [...Values],
      (item, index) => _.indexOf(SelectedIndices, index) > -1
    )
    const isInValues = _.intersectionWith($values, selectedSeason, _.isEqual)
    isActualSeason = isInValues.length > 0
  }
  return isActualSeason
}

export function getIsMultipleByKey(defaultDependencyFilters, filterName) {
  let IsMultiple = false
  _.cloneDeep(defaultDependencyFilters).forEach((item) => {
    if (filterName === item.Key) {
      IsMultiple = item.IsMultiple
    }
  })
  return IsMultiple
}

export function getMultipleValues(params) {
  const { Values = [], SelectedIndices = [] } = params
  const filteredValues = _.isEmpty(Values)
    ? []
    : _.filter([...Values], (item, index) => _.indexOf(SelectedIndices, index) > -1)
  return filteredValues
}

export function getSelectedFilters(defaultDependencyFilters, Filters) {
  const DependencyFilters = []
  Filters.forEach((filter) => {
    DependencyFilters.push({
      Key: filter.Name,
      Value: getMultipleValues(filter)
    })
  })
  // concatenate with default
  return [...defaultDependencyFilters].map((dependency) => {
    const { Key = null } = dependency
    const currentFilter = [...DependencyFilters].filter((item) => item.Key === Key)
    if (currentFilter.length) {
      // keep default dependency
      dependency = { ...dependency, ...currentFilter[0] }
    }
    return dependency
  })
}

export function mapOptions(Values) {
  return Values.map((item) => ({ key: item, value: item }))
}

export function valuesToArray(obj) {
  return Object.keys(obj).map((key) => obj[key])
}

export function setLoader(
  setLoaderFn = () => {},
  actionType,
  isShown = true,
  messages = 'Loading!'
) {
  setLoaderFn({
    type: actionType,
    options: {
      isShown,
      messages
    }
  })
}

export function objectToArray(options) {
  return _.transform(options, (result, value) => result.push(value), [])
}

export function arrayToObject(options) {
  return _.transform(options, (result, value, key) => (result[key] = value), {})
}

export function imageLoaded({ target }) {
  if (
    target.src !== 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  ) {
    target.classList.remove('-not-found')
  }
  target.classList.remove('-image-loading')
}

export function imageError({ target }) {
  target.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  target.classList.add('-not-found')
  target.classList.remove('-image-loading')
}

export function searchOptions($data = [], query = '') {
  if (!_.isArray($data)) {
    return []
  }

  const minChars = 3

  if (!_.isEmpty(query) && query.length >= minChars) {
    $data = $data.filter((item) => item.Name.toLowerCase().includes(query.toLowerCase()))
  }

  return $data
}

export function filterOptions($data = [], settings = {}) {
  const {
    displayTestProducts = true,
    displayConfirmedProducts = true,
    displayAssignedProducts = true,
    displayUnassignedProducts = true
  } = settings
  const opt = {
    displayTestProducts,
    displayConfirmedProducts,
    displayAssignedProducts,
    displayUnassignedProducts
  }

  if (!_.isArray($data)) {
    return []
  }

  $data = $data.map((item) => {
    const {
      SegmentOptions = {
        0: {
          Enabled: false
        }
      }
    } = item || {}
    item.IsAssignedProduct =
      objectToArray(SegmentOptions).filter(({ Enabled = false }) => Enabled).length > 0
    return item
  })

  const optKeys = Object.keys(opt)
  const optKeysLength = optKeys.length
  const openFiltersLength = optKeys.filter(($key) => opt[$key]).length

  if (openFiltersLength === optKeysLength) {
    return $data
  }
  if (!openFiltersLength) {
    return []
  }

  const createFilter = ($filterBy, $arr, $field, $val) => {
    const displayFilterCount = [...$arr].filter((item) => item).length
    if (displayFilterCount === 1) {
      $filterBy[$field] = $val
    } else if (displayFilterCount === 0) {
      $filterBy[$field] = '##'
    }
    return $filterBy
  }

  let filterBy = createFilter(
    {},
    [displayTestProducts, displayConfirmedProducts],
    'IsTestProduct',
    displayTestProducts
  )
  filterBy = createFilter(
    filterBy,
    [displayAssignedProducts, displayUnassignedProducts],
    'IsAssignedProduct',
    displayAssignedProducts
  )

  return _.filter($data, filterBy)
}

export function sortByAdvanced(data = [], sortBy = 'aZ_Ascending') {
  if (!_.isArray(data)) {
    return []
  }
  switch (sortBy) {
    case 'aZ_Ascending':
      data = _.sortBy(data, ({ Name = '' }) => Name)
      break
    case 'aZ_Descending':
      data = _.sortBy(data, ({ Name = '' }) => -Name)
      break
    case 'price_lowToHigh':
      data = _.sortBy(data, ({ BasePrice = 0 }) => BasePrice)
      break
    case 'price_highToLow':
      data = _.sortBy(data, ({ BasePrice = 0 }) => -BasePrice)
      break
    default:
      return data
  }
  return data
}

export function getClosestFutureDate(dates, key, targetDate) {
  targetDate = new Date(targetDate)

  let closestDate = Infinity
  let closestIndex = null

  dates.forEach((item, index) => {
    const currentDate = new Date(item[key])

    if (targetDate >= currentDate) {
      closestDate = currentDate
      closestIndex = index
      if (targetDate > currentDate) {
        const isLast = index + 1 === dates.length
        closestIndex = isLast || key === 'StartTime' ? index : index + 1
        closestDate = new Date(dates[closestIndex][key])
      }
    } else {
      // Dates coming with own sort (ASC) and if first item is smaller than target? following items
      // can not be bigger, so select first as closest.
      if (index === 0) {
        closestDate = currentDate
        closestIndex = index
      }
    }
  })

  return {
    closestDate,
    closestIndex
  }
}

export function getDateDiff(startTime, endTime, timePeriod = 'asMonths') {
  const dictionary = {
    years: 'asYears',
    months: 'asMonths',
    weeks: 'asWeeks',
    days: 'asDays',
    hours: 'asHours'
  }
  const start = moment(startTime)
  const end = moment(endTime)
  const diff = moment.duration(end.diff(start))
  const fn = timePeriod in dictionary ? dictionary[timePeriod] : 'asMonths'
  return Math.ceil(diff[fn]())
}

export function getInvalidFields(clonedFilters) {
  return valuesToArray(clonedFilters).filter((currentFilter) => {
    const { IsRequired = false, IsMultiple = false, LimitedAt = 0, selected = null } = currentFilter
    if (IsRequired) {
      const isArraySelected = _.isArray(selected)
      if (IsMultiple && isArraySelected) {
        const $selected = selected.filter((item) => item !== '' && item !== null)
        if ($selected.length === 0 || $selected.length > LimitedAt) {
          return true
        }
      } else if (!selected) {
        return true
      }
    }
    return false
  })
}

export function removeCurrencySymbol(value, symbol) {
  return _.isString(value) ? value.replace(symbol, '') : value
}
