Ext.define('MDM.src.view.massimport.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.massimport-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {
    var me = this

    var vm = this.getViewModel()

    var owner = vm.get('owner')

    var isReadOnly = MDM.Global.getPermission(owner)

    vm.set('isReadOnly', isReadOnly)
  },
  onAfterRender: function () {
    var me = this

    var vm = me.getViewModel()

    var isReadOnly = vm.get('isReadOnly')

    if (!isReadOnly) {
      var win = Ext.create('MDM.src.view.massimport.FileDialog').show()
      win.on('applyxls', function (win, data, importType) {
        importType.value = importType.selection.data.abbr
        importType.rawValue =
          importType.selection.data.abbr + ' - ' + importType.selection.data.name
        me.getViewModel().set('importType', importType)
        me.loadGridStore(data, importType.value)
      })
    }
  },
  loadGridStore: function (data, importType) {
    var me = this

    var grid = me.lookupReference('grid')
    MDM.Btl.Column.setStartCol(data.startCol)
    MDM.Btl.Column.setColumnTitles(data.FiscealYears)
    grid.importType = importType
    grid.setReLoadData(data.data)
  },

  onShowDialog: function (grid, rowIndex, colIndex, item, e, record) {
    var win = Ext.create('MDM.src.view.massimport.FormDialog', {
      record: record
    })
    if (this.menu_grid) this.menu_grid.destroy()
    this.menu_grid = new Ext.menu.Menu({
      bodyPadding: 2,
      border: false,
      shadow: false,
      items: [win]
    })

    var position = e.getXY()
    ;(position[1] = position[1] + 20), e.stopEvent()
    this.menu_grid.showAt(position)
  },
  onClickApply: function () {
    var me = this

    var view = me.getView()

    var grid = me.lookup('grid')

    var data = []

    var selectedRecord = grid.getSelection()
    view.setLoading('Saving data please wait')

    if (selectedRecord.length > 0) {
      selectedRecord.forEach(function (record) {
        data.push(record.data)
      }, this)
      Ext.Ajax.request({
        method: 'POST',
        timeout: 360000,
        params: {
          IsMass: 1
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/MassImportFile_Apply',
        jsonData: data,
        success: function (response, opts) {
          view.setLoading(false)
          grid.store.remove(selectedRecord)
        },
        failure: function (response, opts) {
          Ext.MessageBox.alert('Error', 'Server Error')
          view.setLoading(false)
        }
      })
    }
  }
})
