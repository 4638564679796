import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import _ from 'lodash'
import flow from 'lodash/flow'

const defaultSettings = {
  isCheckEnabledLeftAxisDraggable: false, // this is not configurable
  isMoveEnabledLeftAxisDraggable: true,
  isCheckEnabledTopAxisDraggable: false, // this is not configurable
  isMoveEnabledTopAxisDraggable: false, // this is not configurable
  isCheckEnabledValuesDraggable: true,
  isMoveEnabledValuesDraggable: true,
  isCheckEnabledDefaultAxisDraggable: true, // this is not configurable
  isMoveEnabledDefaultAxisDraggable: true // this is not configurable
}

class Card extends Component {
  updateCurrentState(card, isSelect, event) {
    const { context } = this.props
    const _updateState = ($val, $card, $context) => {
      this.props.changeCurrentState({
        value: $val,
        card: $card,
        context: $context
      })
    }

    if (isSelect) {
      event.stopPropagation()
      _updateState(event.target.value, card, context)
    } else if (context !== 'LeftAxisDraggable' && context !== 'DefaultAxisDraggable') {
      _updateState('', card, context)
    } else {
      return false
    }
  }

  getCardConfig(pivotBuilderSettings, context) {
    const { isMoveEnabledLeftAxisDraggable = true } = pivotBuilderSettings

    const result = {
      isMoveEnabledDefaultAxisDraggable: isMoveEnabledLeftAxisDraggable
    }

    const newSettings = {
      ...defaultSettings,
      ...pivotBuilderSettings,
      ...result
    }

    let canCheck = false
    let canMove = false

    Object.keys(newSettings).forEach((item) => {
      const isCheckFieldName = `isCheckEnabled${context}`
      const isMoveFieldName = `isMoveEnabled${context}`
      if (item === isCheckFieldName) {
        canCheck = newSettings[isCheckFieldName]
      }
      if (item === isMoveFieldName) {
        canMove = newSettings[isMoveFieldName]
      }
    })

    return {
      canCheck,
      canMove
    }
  }

  render() {
    const {
      card,
      context,
      isDragging,
      connectDragSource,
      connectDropTarget,
      key,
      pivotBuilderSettings = {}
    } = this.props

    const {
      DisplayName = '',
      Name = '',
      Checked,
      ValuesDraggable,
      AggregationFunction,
      AggregationFunctions
    } = card

    const displayName = _.isEmpty(DisplayName) ? Name : DisplayName

    const { canCheck = true, canMove = true } =
      this.getCardConfig(pivotBuilderSettings, context) || {}

    const isDefaultAxisDraggable = context === 'DefaultAxisDraggable'
    const isLeftAxisDraggable = context === 'LeftAxisDraggable'
    const isTopAxisDraggable = context === 'TopAxisDraggable'
    const isNotCheckable = isDefaultAxisDraggable || isLeftAxisDraggable || isTopAxisDraggable

    const valuesDraggableTpl = ValuesDraggable && (
      <select
        value={AggregationFunction}
        onChange={canCheck ? this.updateCurrentState.bind(this, card, true) : () => {}}
      >
        {_.map(AggregationFunctions, (item, index) => (
          <option key={index}>{item}</option>
        ))}
      </select>
    )

    const draggableClass = canMove ? `draggable-item ${isDragging ? 'dragging' : ''}` : ''

    const checkedClass = isTopAxisDraggable || Checked ? '-on' : ''
    const canCheckDisabledClass = !isNotCheckable && !canCheck ? ' not-okay ' : '  '
    const cursorClass = !canCheck && !canMove ? ' is-default ' : ''

    const itemTpl = (
      <li
        key={key}
        className={`${draggableClass} ${cursorClass} ${checkedClass} ${canCheckDisabledClass}`}
        onClick={canCheck ? this.updateCurrentState.bind(this, card, false) : () => {}}
      >
        <div className="cell">
          <strong>{displayName}</strong>
          {valuesDraggableTpl}
        </div>
      </li>
    )

    return !canMove ? itemTpl : connectDragSource(connectDropTarget(itemTpl))
  }
}

const cardSource = {
  beginDrag(props) {
    return {
      index: props.index,
      listId: props.listId,
      card: props.card
    }
  },

  canDrag(e, v) {
    return true
  },

  endDrag(props, monitor) {
    const item = monitor.getItem()
    const dropResult = monitor.getDropResult()
    if (dropResult && dropResult.listId !== item.listId) {
      if (dropResult.listId !== undefined) {
        props.removeCard(item.index)
      }
    }
  }
}

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    const sourceListId = monitor.getItem().listId

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    if (props.listId === sourceListId) {
      props.moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex
    }
  }
}

export default flow(
  DropTarget('CARD', cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget()
  })),
  DragSource('CARD', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(Card)
