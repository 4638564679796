export default () => {
  return {
    title: 'ExecutiveDashboard',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          title: {
            type: 'string',
            title: 'Title'
          },
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          name: {
            type: 'string',
            title: 'Name'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          }
        }
      },
      dashboardSettings: {
        type: 'object',
        title: 'Settings',
        properties: {
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          isFlippable: {
            type: 'boolean',
            title: 'Is Flippable',
            default: true,
            required: true
          },
          singleFocus: {
            type: 'boolean',
            title: 'Is Single Focus',
            default: true,
            required: true
          },
          columnSize: {
            type: 'integer',
            title: 'Column Size',
            default: 4
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Field Settings',
        properties: {
          dataTitle: {
            type: 'string',
            title: 'Title'
          },
          dataSubTitle: {
            type: 'string',
            title: 'Sub Title'
          },
          dataTargetValue: {
            type: 'integer',
            title: 'Target Value'
          },
          dataActualValue: {
            type: 'integer',
            title: 'Actual Value'
          },
          dataValueFormat: {
            type: 'string',
            title: 'Value Format'
          },
          dataTrendValue: {
            type: 'integer',
            title: 'Trend Value'
          },
          dataTrendValueFormat: {
            type: 'string',
            title: 'Trend Value Format'
          },
          dataDetailsRowHeader: {
            type: 'string',
            title: 'Details Row Header'
          },
          dataDetailValue: {
            type: 'integer',
            title: 'Detail Value'
          },
          dataDetailValueFormat: {
            type: 'string',
            title: 'Detail Value Format'
          },
          dataStartPoint: {
            type: 'string',
            title: 'Start Point'
          },
          dataEndPoint: {
            type: 'string',
            title: 'End Point'
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          dataTopRowIndex: {
            type: 'string',
            title: 'Top Row Index',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataTopColumnIndex: {
            type: 'string',
            title: 'Top Column Index',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataTitle: {
            type: 'string',
            title: 'Title',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataSubTitle: {
            type: 'string',
            title: 'Sub Title',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataTargetValue: {
            type: 'string',
            required: true,
            title: 'Target Value',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataTargetMarginLeft: {
            type: 'string',
            required: true,
            title: 'Target Margin 1',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataTargetMarginRight: {
            type: 'string',
            required: true,
            title: 'Target Margin 2',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataActualValue: {
            type: 'string',
            required: true,
            title: 'Actual Value',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataValueFormat: {
            type: 'string',
            required: true,
            title: 'Value Format',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataTrendValue: {
            type: 'string',
            required: true,
            title: 'Trend Value',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataTrendValueFormat: {
            type: 'string',
            required: true,
            title: 'Trend Value Format',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataDetailRowIndex: {
            type: 'string',
            title: 'Details Row Index',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataDetailColumnIndex: {
            type: 'string',
            title: 'Details Column Index',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataDetailsRowHeader: {
            type: 'string',
            required: true,
            title: 'Details Row Header',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataDetailsColumnHeader: {
            type: 'string',
            required: true,
            title: 'Details Column Header',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataDetailValue: {
            type: 'string',
            required: true,
            title: 'Detail Value',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataDetailValueFormat: {
            type: 'string',
            required: true,
            title: 'Detail Value Format',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataStartPoint: {
            type: 'string',
            required: true,
            title: 'Start Point',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          dataEndPoint: {
            type: 'string',
            required: true,
            title: 'End Point',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          }
        }
      },
      colorPalette: {
        type: 'object',
        title: 'Color Palette',
        properties: {
          colorField: {
            type: 'string',
            required: true,
            title: 'Color Field',
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          colors: {
            type: 'array',
            title: 'Colors',
            items: {
              type: 'object',
              title: 'Color',
              properties: {
                firstColor: {
                  type: 'string',
                  format: 'Color',
                  title: 'First Color',
                  default: '#ed1d54'
                },
                secondColor: {
                  type: 'string',
                  format: 'Color',
                  title: 'Second Color',
                  default: '#67658b'
                },
                thirdColor: {
                  type: 'string',
                  format: 'Color',
                  title: 'Third Color',
                  default: '#00eba5'
                }
              }
            }
          }
        }
      }
    }
  }
}
