import { useCallback, useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { loadTreeTableSlice, tuiSlice, viewContainerSlice } from '../../../store/slices'

function useUpdateLoadTree({ loadTreeTableRef }) {
  const [{ registerMethod, setDataArguments }] = useReduxState(tuiSlice.selectSlice)
  const [viewContainer] = useReduxState(viewContainerSlice.selectSlice)
  const [, loadTreeDispatch] = useReduxState(undefined, loadTreeTableSlice.actions)

  const { currentView } = viewContainer

  const handleUpdateLoadTree = useCallback(() => {
    setDataArguments(null, true)

    if (currentView === 'Tree Table') {
      loadTreeDispatch.setLoadStops([])
    }

    loadTreeTableRef.current?.setState((prevState) => ({
      ...prevState,
      rowSelection: {},
      expanded: {}
    }))
  }, [currentView, loadTreeDispatch, loadTreeTableRef, setDataArguments])

  useEffect(() => {
    registerMethod({
      args: [{ name: 'refreshKey', type: PluginTypes.string }],
      key: 'updateLoadTree',
      fn: handleUpdateLoadTree
    })
  }, [handleUpdateLoadTree, registerMethod])
}

export default useUpdateLoadTree
