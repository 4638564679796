import { useCallback } from 'react'
import { IRowNode } from 'ag-grid-community'
import { AG_GRID_ENUMS } from '../../enums'
import { DirtyCell, GridRef, IRowData } from '../../types'

const { DIRTY_CELLS, CUSTOM_ROW_INFO } = AG_GRID_ENUMS

function useGetDirtyFields(gridRef: GridRef) {
  const getDirtyFields = useCallback(() => {
    const cells: DirtyCell[] = []

    gridRef?.current?.api?.forEachLeafNode?.(({ data }: IRowNode<IRowData>) => {
      if (data) {
        const { [DIRTY_CELLS]: currentRowDirtyCells = [] } = data[CUSTOM_ROW_INFO]
        // IMPORTANT NOTE
        // DO NOT USE SPREAD OPERATOR OR ANY OTHER METHOD TO MERGE diry cells arrays
        // Merging arrays with spread operator like below
        // cells = [...cells, ...currentRowDirtyCells]
        // makes ag-grid freeze on 100k row data.
        // DO NOT TOUCH PUSH METHOD UNTIL FACING A PERFORMANCE ISSUE.
        // Sample page: https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/1/menu/65e0930ff13b15ec30553d05/page/65e090f8f13b15ec30553ced
        cells.push(...currentRowDirtyCells)
      }
    })

    return cells
  }, [gridRef])

  return { getDirtyFields }
}

export default useGetDirtyFields
