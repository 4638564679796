import { useState, useEffect, useMemo, ChangeEvent, FormEvent } from 'react'
import _ from 'lodash'
import { FormControl, FormLabel, Form, Button, FormSelect, Row, Col, Badge } from 'react-bootstrap'
import { ColorResult, SketchPicker } from 'react-color'
import { slvyToast } from '@/components/SlvyToast'
import getAdminForm, { getBgColorCmpStyle } from '@/utils/admin-container.js'
import SlvyIconSelector from '@/components/SlvyIconSelector'
import { defaultColor } from '../constants'
import { modes, buttonStyles, placements, defaultForm, containerSettings } from './constants'
import { AdminContainerProps } from '@/elements/Container/Admin/Admin.types'
import {
  FormState,
  HandleChangeColorKey,
  HandleChangeFormKey,
  HandleChangeOverlaySizeKey
} from './Admin.types'

const AdminContainer = (props: AdminContainerProps) => {
  const { propertyEditorFields, settings, onChangeSettings } = props

  const getInitialForm = useMemo(
    () => getAdminForm({ ...propertyEditorFields, ...containerSettings }, settings, defaultForm),
    [propertyEditorFields, settings]
  )

  const [form, setForm] = useState<FormState>(getInitialForm)
  const [displayBackgroundPicker, setDisplayBackgroundPicker] = useState<boolean>(false)
  const [displayTextPicker, setDisplayTextPicker] = useState<boolean>(false)

  useEffect(() => {
    setForm(getInitialForm)
  }, [getInitialForm])

  const handleChangeHideMode = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked }
    } = event
    setForm((prevForm: FormState) => ({ ...prevForm, hideMode: checked }))
  }

  const handleChangeColor = (color: ColorResult, key: HandleChangeColorKey) => {
    const { rgb } = color
    setForm((prevForm: FormState) => ({ ...prevForm, [key]: rgb }))
  }

  const handleChangeForm = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    key: HandleChangeFormKey
  ) => {
    const {
      target: { value }
    } = event
    setForm((prevForm: FormState) => ({ ...prevForm, [key]: value }))
  }

  const handleChangeOverlaySize = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: HandleChangeOverlaySizeKey
  ) => {
    const {
      target: { value }
    } = event
    const size = parseInt(value, 10)
    setForm((prevForm: FormState) => ({ ...prevForm, [key]: size }))
  }

  const handleChangeRootClose = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked }
    } = event
    setForm((prevForm: FormState) => ({ ...prevForm, rootClose: checked }))
  }

  const getValidationState = () => {
    // TODO: add validation case
    return null
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!getValidationState()) {
      onChangeSettings({
        ...settings,
        ...form
      })
      slvyToast.success({ message: 'Saved successfully' })
    }
  }

  const {
    title,
    height,
    width,
    selectedMode,
    backgroundColor,
    textColor,
    textAlign = 'center',
    fontSize = '16',
    padding = 0,
    selectedBtnStyle,
    icon = 'fa slvy-ui-icon-check-extra-lt',
    iconPosition = 'left',
    placement = 'bottom',
    rootClose = true,
    hideMode = false
  } = form

  const background = backgroundColor || defaultColor
  const text = textColor || defaultColor
  const bgStyle = getBgColorCmpStyle(background)
  const textStyle = getBgColorCmpStyle(text)

  return (
    <Form noValidate className="containerForm overflow-hidden" onSubmit={handleSubmit}>
      <Form.Group className="col-12 pe-1 mb-2">
        <FormLabel className="fs-sm">Modes</FormLabel>
        <FormSelect
          placeholder="select mode"
          size="sm"
          value={selectedMode}
          onChange={(event) => handleChangeForm(event, 'selectedMode')}
        >
          {modes.map((mode, key) => {
            return (
              <option key={key} value={mode}>
                {mode}
              </option>
            )
          })}
        </FormSelect>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Title</FormLabel>
        <FormControl
          size="sm"
          type="text"
          value={title}
          onChange={(event) => handleChangeForm(event, 'title')}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Icon</FormLabel>
        <SlvyIconSelector
          value={icon}
          onChange={(value) => setForm((prevForm) => ({ ...prevForm, icon: value }))}
        />
      </Form.Group>
      <Row className="mb-2">
        <Col className="pe-1">
          <Form.Group>
            <FormLabel className="fs-sm">Icon Position</FormLabel>
            <FormSelect
              size="sm"
              value={iconPosition}
              onChange={(event) => handleChangeForm(event, 'iconPosition')}
            >
              <option value="left">Left</option>
              <option value="right">Right</option>
            </FormSelect>
          </Form.Group>
        </Col>
        <Col className="ps-1">
          <Form.Group>
            <FormLabel className="fs-sm d-block">Text Align</FormLabel>
            <FormSelect
              size="sm"
              value={textAlign}
              onChange={(event) => handleChangeForm(event, 'textAlign')}
            >
              <option value="start">Left</option>
              <option value="center">Center</option>
              <option value="between">Between</option>
              <option value="end">Right</option>
            </FormSelect>
          </Form.Group>
        </Col>
      </Row>
      {selectedMode === 'Label' ? (
        <>
          <Form.Group className="position-relative mb-2">
            <FormLabel className="fs-sm">Background Color</FormLabel>
            <br />
            {/* TODO: replace with SlvyColorPicker */}
            <div style={bgStyle.swatch} onClick={() => setDisplayBackgroundPicker(true)}>
              <div style={bgStyle.color} />
            </div>
            {displayBackgroundPicker ? (
              <div style={bgStyle.popover}>
                <div style={bgStyle.cover} onClick={() => setDisplayBackgroundPicker(false)} />
                <SketchPicker
                  color={background}
                  onChange={(color) => handleChangeColor(color, 'backgroundColor')}
                />
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="position-relative mb-2">
            <FormLabel className="fs-sm">Text Color</FormLabel>
            <br />
            {/* TODO: replace with SlvyColorPicker */}
            <div style={textStyle.swatch} onClick={() => setDisplayTextPicker(true)}>
              <div style={textStyle.color} />
            </div>
            {displayTextPicker ? (
              <div style={textStyle.popover}>
                <div style={textStyle.cover} onClick={() => setDisplayTextPicker(false)} />
                <SketchPicker
                  color={text}
                  onChange={(color) => handleChangeColor(color, 'textColor')}
                />
              </div>
            ) : null}
          </Form.Group>
        </>
      ) : null}
      {selectedMode === 'Button' ? (
        <Form.Group className="col-6 pe-1 mb-2">
          <FormLabel className="fs-sm">Style</FormLabel>
          <FormSelect
            placeholder="select style"
            size="sm"
            value={selectedBtnStyle}
            onChange={(event) => handleChangeForm(event, 'selectedBtnStyle')}
          >
            {buttonStyles.map((style, key) => {
              return (
                <option key={key} value={style}>
                  {style}
                </option>
              )
            })}
          </FormSelect>
        </Form.Group>
      ) : null}
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Text Size</FormLabel>
        <FormControl
          size="sm"
          type="number"
          value={fontSize}
          onChange={(event) => handleChangeForm(event, 'fontSize')}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Padding</FormLabel>
        <Row className="d-flex align-items-center">
          <Col sm={10}>
            <Form.Range
              max={50}
              min={0}
              step={1}
              value={padding}
              onChange={(event) => handleChangeOverlaySize(event, 'padding')}
            />
          </Col>
          <FormLabel column className="pt-0 fs-7" sm={2}>
            <Badge bg="info pt-1">{padding}</Badge>
          </FormLabel>
        </Row>
      </Form.Group>
      <Row className="mb-2">
        <Col className="pe-1">
          <Form.Group>
            <FormLabel className="fs-sm">Overlay Width</FormLabel>
            <FormSelect
              placeholder="width"
              size="sm"
              value={width}
              onChange={(event) => handleChangeOverlaySize(event, 'width')}
            >
              {_.times(10, (x) => {
                const val = (x + 1) * 100
                return (
                  <option key={x} value={val}>
                    {val}
                  </option>
                )
              })}
            </FormSelect>
          </Form.Group>
        </Col>
        <Col className="ps-1">
          <Form.Group>
            <FormLabel className="fs-sm d-block">Overlay Height</FormLabel>
            <FormSelect
              placeholder="height"
              size="sm"
              value={height}
              onChange={(event) => handleChangeOverlaySize(event, 'height')}
            >
              {_.times(10, (x) => {
                const val = (x + 1) * 100
                return (
                  <option key={x} value={val}>
                    {val}
                  </option>
                )
              })}
            </FormSelect>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="col-6 pe-1 mb-2">
        <FormLabel className="fs-sm">Overlay Placement</FormLabel>
        <FormSelect
          placeholder="select placement"
          size="sm"
          value={placement}
          onChange={(event) => handleChangeForm(event, 'placement')}
        >
          {placements.map((_placement, key) => {
            return (
              <option key={key} value={_placement}>
                {_placement}
              </option>
            )
          })}
        </FormSelect>
      </Form.Group>
      <Form.Group className="mb-2" controlId="hideOnClick">
        <Form.Check
          checked={rootClose}
          className="mb-0 form-check-sm"
          label="Close Overlay by Clicking Outside"
          type="checkbox"
          onChange={handleChangeRootClose}
        />
      </Form.Group>
      <Form.Group className="mb-2" controlId="hideMode">
        <Form.Check
          checked={hideMode}
          className="mb-0 me-2 form-check-sm"
          label={
            <>
              Hide Mode
              <small className="d-block text-muted fw-normal">
                Overlay will not be closed by clicking outside
              </small>
            </>
          }
          type="checkbox"
          onChange={handleChangeHideMode}
        />
      </Form.Group>
      <Button size="sm" type="submit" variant="success">
        Save
      </Button>
    </Form>
  )
}

export default AdminContainer
