Ext.define('tuiv2.cmp.SessionListWindow', {
  extend: 'Ext.window.Window',
  xtype: 'tuiv2-sessionlistwindow',
  modal: true,
  layout: 'border',
  height: 500,
  width: 1000,
  title: 'Sessions',
  closeAction: 'hide',
  ui: 'tui-window',
  referenceHolder: true,
  items: [
    {
      xtype: 'panel',
      region: 'center',
      layout: 'fit',
      margin: 10,
      overflowY: 'auto',
      items: [
        {
          xtype: 'tuiv2-sessiongrid',
          reference: 'sessionGrid',
          bind: {
            store: '{sessionListStore}'
          }
        }
      ]
    }
  ]
})
