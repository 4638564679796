import React, { useState } from 'react'
import _ from 'lodash'
import { Card } from 'react-bootstrap'
import { MESSAGES } from '../messages'
import { SlvySpinner } from '../../../components'
import '../index.scss'

const SearchList = ({
  onEdit = () => {},
  columnHeightValue,
  pendingSearch,
  searchResult,
  noResult
}) => {
  const [isEditBtnClickable, setIsEditBtnClickable] = useState(true)

  function handleEdit(event, code) {
    event.preventDefault()
    setIsEditBtnClickable(false)
    onEdit(code, () => setIsEditBtnClickable(true))
  }

  if (noResult) {
    return <h2 className="not-found-text text-center pt-3">{MESSAGES.no_colors_found}</h2>
  }

  return (
    <Card>
      <Card.Body>
        {!pendingSearch || !isEditBtnClickable ? <SlvySpinner /> : null}
        <ul className="p-list d-flex flex-wrap pantone">
          {_.map(searchResult, (searchItem, index) => {
            const { Red, Green, Blue, PantoneCode, Name, NameTR, ColorId, MasterCode } = searchItem
            return (
              <li key={index} className="search-result-item w-25">
                <div className="cb-ctn overflow-hidden position-relative rounded-2 m-1">
                  <div
                    className="color-box w-100 d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor:
                        _.isNull(PantoneCode) || _.isNull(Red) || _.isNull(Green) || _.isNull(Blue)
                          ? '#fff'
                          : `rgb(${Red},${Green},${Blue})`,
                      height: columnHeightValue - 15
                    }}
                  >
                    {_.isNull(PantoneCode) || _.isNull(Red) || _.isNull(Green) || _.isNull(Blue) ? (
                      <span className="no-color-info fw-semibold">{MESSAGES.no_color_info}</span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="p-1">
                    <b>{PantoneCode}</b>
                    <span>
                      {MasterCode} - {Name}
                      {NameTR ? `/${NameTR}` : ''}
                    </span>
                  </div>
                  {isEditBtnClickable ? (
                    <i
                      className="btn-edit d-flex justify-content-center position-absolute p-1 bg-white rounded-1 cp fa fa fa-plus-square"
                      onClick={(event) => handleEdit(event, ColorId)}
                    />
                  ) : null}
                </div>
              </li>
            )
          })}
        </ul>
      </Card.Body>
    </Card>
  )
}

export default SearchList
