Ext.define('Spui.view.common.similarity.tabpanel.SalesChart.TabPanel', {
  extend: 'Ext.tab.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-similarity-tabpanel-saleschart-tabpanel',

  defaultListenerScope: true,

  mixins: ['Spui.view.common.similarity.tabpanel.SalesChart.Mixin']
})
