import { isEmpty, isNil } from 'lodash'
import {
  TOGGLE_MOVE_MODE,
  TOGGLE_MOVE_ITEM,
  TOGGLE_MOVE_ITEM_GROUP,
  RESET_MOVE_MODE,
  RESET_MOVE_MODE_ITEMS
} from '../actions/actionTypes'
import { updateObject } from './utility'

const initialState = {
  isOn: false,
  items: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MOVE_MODE:
      return updateObject(state, {
        isOn: !state.isOn
      })
    case TOGGLE_MOVE_ITEM: {
      const actionItem = action.payload.item
      const stateItem = state.items[actionItem.key]
      const isExisting = !isEmpty(stateItem)

      if (isExisting) {
        actionItem.isSelected = !stateItem.isSelected
      } else {
        actionItem.isSelected = true
      }

      return updateObject(state, { items: { ...state.items, [actionItem.key]: { ...actionItem } } })
    }
    case TOGGLE_MOVE_ITEM_GROUP: {
      const items = action.payload.items.reduce(
        (items, item, index, array) => {
          const isFirstItem = index === 0
          if (isFirstItem) {
            const stateItem = state.items[item.key]
            const isExisting = !isEmpty(stateItem)
            if (isExisting) {
              items.selectState = !stateItem.isSelected
            } else {
              items.selectState = true
            }
          }

          item.isSelected = items.selectState
          items[item.key] = item

          const isLastItem = index === array.length - 1
          if (isLastItem) {
            delete items.selectState
          }

          return items
        },
        {
          selectState: false
        }
      )

      return updateObject(state, { items: { ...state.items, ...items } })
    }
    case RESET_MOVE_MODE:
      return updateObject(state, initialState)
    case RESET_MOVE_MODE_ITEMS:
      return updateObject(state, { items: {} })
    default:
      return state
  }
}
