import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cloneDeep, isEmpty, omit } from 'lodash'
import { slvyToast } from '../../../../components'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { updateAssortment } from '../../store/slices/assortment'
import { setCurrentScenario } from '../../store/slices/treeSelection'

class UpdateButton extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.isActualSeason !== this.props.isActualSeason
  }

  updateProducts(isActualSeason) {
    if (!isActualSeason) {
      return
    }

    const {
      pluginId,
      rootOptions: { AssortmentId = null } = {},
      setLoader = () => {},
      updateAssortment = () => {},
      setCurrentScenario = () => {},
      selectedScenarioTree: { currentScenario = {} } = {}
    } = this.props

    const clonedCurrentScenario = cloneDeep(currentScenario)
    clonedCurrentScenario.Status = 'EDITING'

    const payload = {
      pluginId,
      method: 'Update',
      requestMethod: 'post',
      body: {
        NodeStr: omit({ ...clonedCurrentScenario }, [
          'CreateTime',
          'Description',
          'Filters',
          'LastEditTime',
          'UserName'
        ]),
        AssortmentId
      }
    }

    setLoader({ isShown: true, messages: 'Updating' })

    updateAssortment({ ...payload }).then((response) => {
      const {
        UpdateAssortment: { Code = 0, Description = '' },
        errorUpdateAssortment,
        errorUpdateAssortment: { message: errorMessage = '' } = {}
      } = this.props

      if (!isEmpty(errorUpdateAssortment)) {
        slvyToast.error({ message: errorMessage, title: 'Update Assortment' })
      } else {
        const msgType = Code > 0 ? 'success' : 'error'
        slvyToast[msgType]({ message: Description, title: 'Update Assortment' })

        if (response?.payload?.Code > -1) {
          setCurrentScenario({ dataKey: 'tree', currentScenario: clonedCurrentScenario })
        }
      }
      setLoader({ isShown: false, messages: 'Updating' })
    })
  }

  render() {
    const {
      props: { isActualSeason = true }
    } = this

    return (
      <div
        className={`option-btn ${isActualSeason ? '' : '-disabled'}`}
        onClick={this.updateProducts.bind(this, isActualSeason)}
      >
        <i className="slvy-ui-icon-reset" />
        <span>Update</span>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    isLoadingRootOptions: state.options.isLoading,
    selectedScenarioTree: state.treeSelection.tree,
    UpdateAssortment: state.assortment.UpdateAssortment,
    errorUpdateAssortment: state.assortment.errorUpdate,
    isLoadingUpdateAssortment: state.assortment.isLoadingUpdate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAssortment: bindActionCreators(updateAssortment, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch),
    setCurrentScenario: bindActionCreators(setCurrentScenario, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateButton)
