import _ from 'lodash'
import { put, select } from 'redux-saga/effects'
import pubsub from '../../../subs'
import { SET_MENU_VARIABLES, SET_GLOBAL_VARIABLES, SET_STORE_RESET } from '../../relation'

export default function* runDrilldown(props = {}, result, action) {
  const { menuKey, pageId, addInPage = true, openNewTab = false } = props
  const {
    payload: {
      params: { storeIndex = 0, environment, catalogId },
      relations: { menus: { [menuKey]: { drilldown } = {} } = {} } = {}
    }
  } = action

  const { relationReducers = {} } = yield select()

  const { stores = [] } = relationReducers
  const store = stores[storeIndex] || {}
  const {
    global: { variables: globalVariables = {} } = {},
    page: { variables: pageVariables = {} } = {},
    menu: { variables: menuVariables = {} } = {}
  } = store || {}

  const variables = {
    ...pageVariables,
    ...menuVariables,
    ...globalVariables,
    ...result
  }

  if (menuKey) {
    const nextStoreIndex = parseInt(storeIndex, 10) + 1
    const menuReturn = _.transform(drilldown, (r, param, k) => {
      if (_.has(variables, param)) {
        ;(r || (r = {}))[k] = variables[param]
      }
    })

    yield put({
      type: SET_STORE_RESET,
      storeIndex: nextStoreIndex
    })
    yield put({
      type: SET_MENU_VARIABLES,
      result: menuReturn,
      storeIndex: nextStoreIndex
    })
    yield put({
      type: SET_GLOBAL_VARIABLES,
      result: globalVariables,
      storeIndex: nextStoreIndex
    })
    if (addInPage && pageId) {
      pubsub.publish('popup_drilldown', {
        menuKey,
        pageId,
        storeIndex: nextStoreIndex
      })
    } else if (!_.isNil(pageId) && pageId !== 0 && openNewTab) {
      window.open(
        `/${environment}/catalog/${catalogId}/store/${nextStoreIndex}/menu/${menuKey}/page/${pageId}`
      )
    } else if (!_.isNil(pageId) && pageId !== 0) {
      const $url = `/${environment}/catalog/${catalogId}/store/${nextStoreIndex}/menu/${menuKey}/page/${pageId}`
      window.SLVY_useNavigate($url)
    } else {
      const $url = `/${environment}/catalog/${catalogId}/store/${nextStoreIndex}/menu/${menuKey}`
      window.SLVY_useNavigate($url)
    }
  }
  return variables
}
