import { indexOf, trim, trimStart } from 'lodash'
import moment from 'moment'
import {
  CELL_CLASS_FOR_EXCEL_NUMBER_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_EXCEL_FORMAT,
  EXCEL_FORMAT_CONVERSION
} from '../../constants'
import { SlvyColDef, SlvyColGroupDef } from '../../types'
import { OneOfExcelFormatKeyType, OneOfExcelFormatValueType, PluginName } from './export.types'

const generateExportFileName = (pluginName: PluginName, defaultExportFileName: string) => {
  const currentDate = moment(new Date()).format(DEFAULT_DATE_FORMAT)
  return `${pluginName?.trim?.() || defaultExportFileName} ${currentDate}`
}

// USE NATIVE TRIM, INDEX OF FUNCTIONS
const formatToExcelConvention = (format: string) => {
  const charsToBeTrim = [' ', '$', '€', '₺', '%']
  const charsToBeTrimStart = ['Number', 'Percent']

  let excelFormat: string = format

  excelFormat = trim(excelFormat, ' ')

  charsToBeTrimStart.forEach((char) => {
    excelFormat = trimStart(excelFormat, char)
  })

  charsToBeTrim.forEach((char) => {
    excelFormat = trim(excelFormat, char)
  })

  let newExcelFormat: OneOfExcelFormatValueType | OneOfExcelFormatKeyType =
    excelFormat as OneOfExcelFormatKeyType

  if (newExcelFormat in EXCEL_FORMAT_CONVERSION) {
    newExcelFormat = EXCEL_FORMAT_CONVERSION[newExcelFormat as OneOfExcelFormatKeyType]
  } else {
    newExcelFormat = DEFAULT_EXCEL_FORMAT
  }

  const doesPercentageExist = indexOf(format, '%') > -1 || indexOf(format, 'Percent') > -1
  if (doesPercentageExist) {
    newExcelFormat += '%'
  }

  return newExcelFormat
}

const getExcelNumberFormatId = (field: SlvyColDef['field']) => {
  const cleanField = field?.replace?.(/[^a-zA-Z]/g, '') ?? ''
  return `${cleanField}${CELL_CLASS_FOR_EXCEL_NUMBER_FORMAT}`
}

const getExportableColumnDefs = (columnDefs: SlvyColGroupDef[] = []) => {
  const exportableColumnDefs: Array<SlvyColGroupDef> = []

  columnDefs.forEach((colDef) => {
    const { hide, children = [], customInfo } = colDef

    if (children.length) {
      const newChildren = children.filter((child) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { hide, customInfo } = child
        return Boolean(!hide && customInfo?.isExportable)
      })

      exportableColumnDefs.push(...(newChildren as Array<SlvyColGroupDef>))
    }

    if (!hide && customInfo?.isExportable) {
      exportableColumnDefs.push(colDef)
    }
  })

  return exportableColumnDefs
}

export {
  generateExportFileName,
  formatToExcelConvention,
  getExcelNumberFormatId,
  getExportableColumnDefs
}
