import Calendar from 'rc-calendar'
import { useTheme } from '../../../contexts/ThemeContext'
import { DateFilterProps } from './DateFilter.types'

export default function DateFilter(props: DateFilterProps) {
  const styles = useTheme()

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Calendar
        className={styles.dateFilter}
        onSelect={(value) =>
          props.onSelect({ ...props.getValue(), [props.range]: value, timeProp: props.range })
        }
        format={props.format}
      />
    </div>
  )
}
