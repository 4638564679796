Ext.define('MDM.view.regionapp.MassEdit.Main', {
  extend: 'Ext.window.Window',
  title: 'Material Mass  Edit',
  iconCls: 'fa fa-pencil-square-o',
  xtype: 'mdm-view-regionapp-massedit-main',
  viewModel: 'mdm-view-regionapp-massedit-mainviewmodel',
  controller: 'mdm-view-regionapp-massedit-maincontroller',
  maximized: true,
  height: '90%',
  width: '90%',
  layout: 'fit',
  bodyPadding: 10,
  loading: 'Loading.......',
  listeners: {
    boxready: 'onBoxReady'
  },
  items: [
    {
      xtype: 'mdm-view-regionapp-massedit-grid',
      border: true,
      bind: {
        store: '{MaterialStore}'
      }
    }
  ],
  dockedItems: [
    {
      xtype: 'toolbar',
      dock: 'bottom',
      items: [
        '->',
        {
          text: 'Cancel & Close',
          handler: 'onClose'
        },
        '-',
        {
          text: 'Reset',
          handler: 'onReLoad'
        },
        '-',
        {
          text: 'Save',
          handler: 'onSave'
        }
      ]
    }
  ]
})
