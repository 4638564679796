import { createElement } from 'react'
import { render as reactDOMRender } from 'react-dom'
import StyleSelector from './StyleSelector'

window.JSONEditor.defaults.editors.StylePicker = window.JSONEditor.AbstractEditor.extend({
  renderReact() {
    const self = this
    const element = createElement(StyleSelector, {
      value: this.value,
      onChange: (a) => {
        self.setValue(a)
        self.onChange(true)
      },
      title: this.schema.title ? this.schema.title : this.key,
      format: this.schema.format
    })
    reactDOMRender(element, this.container)
  },
  setValue(value, initial) {
    this.value = value
    this.renderReact()
    this.onChange()
  },
  build() {
    this.renderReact()
  }
})

export default {
  name: 'StylePicker',
  format: 'Style'
}
