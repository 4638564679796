import * as actionTypes from './actionTypes'

export const toggleResourceBasedProduction = () => {
  return {
    type: actionTypes.TOGGLE_RESOURCE_BASED_PRODUCTION
  }
}

export const onSort = (sortConfig) => ({
  type: actionTypes.ON_SORT,
  sortConfig
})

export const setConfigSettings = (configSettings) => ({
  type: actionTypes.SET_CONFIG_SETTINGS,
  configSettings
})

export const onPinRow = (pinnedRow) => ({
  type: actionTypes.ON_PIN_ROW,
  pinnedRow
})

export const setRunInfo = (runInfo) => ({
  type: actionTypes.SET_RUN_INFO,
  runInfo
})

export const setCoverViolationIndexes = (coverViolationIndexes) => ({
  type: actionTypes.SET_COVER_VIOLATION_INDEXES,
  payload: {
    coverViolationIndexes
  }
})

export const filterValues = (
  periodIndex,
  tableName,
  isResourceBased,
  operator,
  value,
  format,
  isChange
) => ({
  type: actionTypes.FILTER_VALUES,
  payload: {
    periodIndex,
    tableName,
    isResourceBased,
    operator,
    value,
    format,
    isChange
  }
})

export const resetFilterValues = () => {
  return {
    type: actionTypes.RESET_FILTER_VALUES
  }
}

export const weeklySort = (periodIndex, issueName, isAscending) => ({
  type: actionTypes.WEEKLY_SORT,
  payload: {
    periodIndex,
    issueName,
    isAscending
  }
})

// new production view
export const PRODUCTtoggleResourceBasedProduction = () => {
  return {
    type: actionTypes.PRODUCT_TOGGLE_RESOURCE_BASED_PRODUCTION
  }
}

export const PRODUCTonSort = (sortConfig) => ({
  type: actionTypes.PRODUCT_ON_SORT,
  sortConfig
})

export const PRODUCTsetConfigSettings = (configSettings) => ({
  type: actionTypes.PRODUCT_SET_CONFIG_SETTINGS,
  configSettings
})

export const PRODUCTonPinRow = (pinnedRow) => ({
  type: actionTypes.PRODUCT_ON_PIN_ROW,
  pinnedRow
})

export const PRODUCTsetRunInfo = (runInfo) => ({
  type: actionTypes.PRODUCT_SET_RUN_INFO,
  runInfo
})

export const PRODUCTsetCoverViolationIndexes = (coverViolationIndexes) => ({
  type: actionTypes.PRODUCT_SET_COVER_VIOLATION_INDEXES,
  payload: {
    coverViolationIndexes
  }
})

export const PRODUCTfilterValues = (
  periodIndex,
  tableName,
  isResourceBased,
  operator,
  value,
  format,
  isChange
) => ({
  type: actionTypes.PRODUCT_FILTER_VALUES,
  payload: {
    periodIndex,
    tableName,
    isResourceBased,
    operator,
    value,
    format,
    isChange
  }
})

export const PRODUCTweeklySort = (periodIndex, issueName, isAscending) => ({
  type: actionTypes.PRODUCT_WEEKLY_SORT,
  payload: {
    periodIndex,
    issueName,
    isAscending
  }
})

export const selectSession = (session) => ({
  type: actionTypes.SELECT_SESSION,
  payload: {
    session
  }
})

export const setSessions = (sessions) => ({
  type: actionTypes.SET_SESSIONS,
  payload: {
    sessions
  }
})

export const toggleGrandTotalPin = () => ({
  type: actionTypes.TOGGLE_GRAND_TOTAL_PIN
})
