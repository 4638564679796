import { Component } from 'react'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './style.scss'

class NumericFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      label: '',
      readOnly: false
    }
    this.setSelectionEnabled = this.setSelectionEnabled.bind(this)
    this.setSelectionDisabled = this.setSelectionDisabled.bind(this)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { registerEvent, registerMethod } = this.props

    this.fireHandleItemSelect = registerEvent({
      key: 'handleItemSelect',
      fn: this.fireHandleItemSelect.bind(this),
      returnTypes: { item: PluginTypes.int }
    })

    registerMethod({
      key: 'setValue',
      fn: this.setValue.bind(this),
      args: [{ name: 'value', type: PluginTypes.int }]
    })

    registerMethod({
      key: 'setLabel',
      fn: this.setLabel.bind(this),
      args: [{ name: 'label', type: PluginTypes.string }]
    })

    registerMethod({
      key: 'setSelectionEnabled',
      fn: this.setSelectionEnabled.bind(this),
      args: []
    })

    registerMethod({
      key: 'setSelectionDisabled',
      fn: this.setSelectionDisabled.bind(this),
      args: []
    })
  }

  componentDidMount() {
    const {
      settings: { config: { settings: { defaultValueFromData = '' } = {} } = {} } = {},
      pluginData = []
    } = this.props

    if (!defaultValueFromData) {
      this.applyDefaultValue()
      return
    }

    if (pluginData.length > 0) {
      this.getFieldData()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      settings: { config: { settings: { defaultValueFromData = '' } = {} } = {} } = {},
      pluginData = []
    } = this.props

    if (defaultValueFromData && prevProps.pluginData !== pluginData && pluginData.length > 0) {
      this.getFieldData()
    }
  }

  handleItemSelect(value) {
    const { settings: { config: { settings: { min = 0, max = 0 } = {} } = {} } = {} } = this.props

    if (min <= value && max >= value) {
      this.setState({ value })
      this.fireHandleItemSelect(value)
    }
  }

  setSelectionEnabled() {
    this.setState({ readOnly: false })
  }

  setSelectionDisabled() {
    this.setState({ readOnly: true })
  }

  setValue({ value }) {
    const { value: stateValue } = this.state
    if (value && stateValue !== value) {
      this.setState({ value })
    }
  }

  setLabel({ label }) {
    const { label: stateLabel } = this.state
    if (stateLabel !== label) {
      this.setState({ label })
    }
  }

  getFieldData() {
    const {
      settings: {
        query: { fields = [] },
        config: { settings: { defaultValueFromData = '' } } = {}
      } = {},
      pluginData = []
    } = this.props

    const fieldObj = fields.find((field) => field.fieldName === defaultValueFromData)

    if (fieldObj && pluginData.length > 0) {
      const data = pluginData.map((row) => row[defaultValueFromData])

      if (data.length > 0) {
        this.setState({ value: Number(data[0]) })
      }
    }
  }

  applyDefaultValue() {
    const { settings: { config: { settings: { defaultValue } = {} } = {} } = {} } = this.props
    if (defaultValue !== undefined) {
      this.setState({ value: defaultValue })
      this.handleItemSelect(defaultValue)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  fireHandleItemSelect(value) {
    return { item: value }
  }

  render() {
    const style = { width: '100%' }

    const {
      id,
      settings: {
        config: { settings: { placeholder, label: configLabel, step, min, max } = {} } = {}
      } = {}
    } = this.props

    const { label: stateLabel, readOnly = false, value } = this.state

    const label = stateLabel || configLabel

    return (
      <div className="numeric-filter p-1">
        {label ? <label htmlFor={id}>{label}:</label> : null}

        <input
          id={id}
          max={max}
          min={min}
          placeholder={placeholder}
          readOnly={readOnly}
          step={step}
          style={style}
          type="number"
          value={value || ''}
          onChange={(event) => this.handleItemSelect(_.toNumber(event.target.value))}
        />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  id: props.id
})

export default createPlugin(NumericFilter, selectConnectorProps)
