export default `({
    border: false,
    margin: '1 0 0 0',
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        style: {
            'border-bottom': '1px solid rgb(193, 193, 193,0.5) !important;',
            'border-top': '1px solid rgb(193, 193, 193,0.5) !important;'
        },
        items: [{
            xtype: 'tbtext',
            bind: {
                text: '<b> Birim  : {spotRecord.MaterialDescription} </b>  '
            }
        }]
    }],
    header: false,
    flex: 1,
    title: 'Olcu birimi',
    title: tt('UnitofMeasure'),
    columns: [{
            text: tt("MaterialDescription"),
            dataIndex: "MaterialDescription",
            flex:1
        },
        {
            text: tt("UnitofMeasure"),
            dataIndex: "UnitofMeasure",
            flex:1
        },
        {
            text: tt("BaseQuantity"),
            dataIndex: "BaseQuantity",
            align: 'right',
            flex:1
        }
    ]
})
`
