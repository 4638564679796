import React from 'react'
import _ from 'lodash'

class Body extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    const {
      props: { show = true }
    } = this

    return show && <div className="wizardBody">{this.props.children}</div>
  }
}

export default Body
