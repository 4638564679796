import { useCallback } from 'react'
import { GetMainMenuItemsParams, MenuItemDef } from 'ag-grid-community'
import { getHeaderMenuItems } from '../../helpers'
import { GetMainMenuItemsProps } from './useGetMainMenuItems.types'

function useGetMainMenuItems(props: GetMainMenuItemsProps) {
  const { gridRef, grouping, lockPinned, massUpdate, suppressRowGroupHidesColumns } = props
  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
      return getHeaderMenuItems({
        params,
        gridRef,
        grouping,
        lockPinned,
        massUpdate,
        suppressRowGroupHidesColumns
      })
    },
    [gridRef, grouping, lockPinned, massUpdate, suppressRowGroupHidesColumns]
  )

  return { getMainMenuItems }
}

export default useGetMainMenuItems
