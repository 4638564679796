Ext.define('MDM.src.view.model.Megaregion', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/RegionApp_MegaRegion',
  // idProperty:'SalesBomID',
  fields: [
    /*
            The fields for this model. This is an Array of Ext.data.field.Field definition objects or simply the field name.
            If just a name is given, the field type defaults to auto.  For example:
                { name: 'name',     type: 'string' },
                { name: 'age',      type: 'int' },
                { name: 'phone',    type: 'string' },
                { name: 'gender',   type: 'string' },
                { name: 'username', type: 'string' },
                { name: 'alive',    type: 'boolean', defaultValue: true }
            */
  ]
})
