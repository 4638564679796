Ext.define('tuiv2.gridview.loadTable', {
  extend: 'Ext.grid.Panel',
  xtype: 'tuiv2-loadTable',
  alias: 'widget.tuiv2-loadTable',
  reference: 'loadTable',
  ui: 'window-inverse',
  enableLocking: true,
  columnLines: true,
  lockedViewConfig: {
    syncRowHeight: true
  },
  viewConfig: {
    enableTextSelection: true
  },
  features: [
    {
      ftype: 'uxsummary',
      dock: 'top'
    }
  ],
  listeners: {
    select: 'onLoadTableRowSelect',
    viewready: function (grid) {
      var store = grid.getStore()

      store.on('load', function () {
        grid.getSelectionModel().select(0)
      })
    }
  },
  columns: []
})
