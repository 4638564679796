import { Component } from 'react'
import { connect } from 'react-redux'
import { fetchScenarioTree, setLoader as setActionLoader } from '../store/actions'
import { setLoader, _errorHandler } from '../utils'
import { MESSAGES } from '../messages'

class FetchScenarioTree extends Component {
  toggleLoader(isVisible = false, msg = MESSAGES.loading) {
    setLoader(this.props.setActionLoader, isVisible, msg)
  }

  _fetchScenarioTree(options) {
    const {
      defaultActionParams: { payload = {} },
      mfpSettings: { mfpId } = {}
    } = this.props

    const { isLoadingActive = true } = options || {}

    isLoadingActive && this.toggleLoader(true, MESSAGES.loading)

    const promise = this.props.fetchScenarioTree({
      payload: {
        ...payload,
        method: 'GetScenarioTree',
        requestMethod: 'post',
        body: {
          mfpId,
          accessMode: 0
        }
      }
    })

    promise
      .then((scenarioTreeResponse) => _errorHandler(scenarioTreeResponse, 'GetScenarioTree'))
      .catch((scenarioTreeError) => _errorHandler(scenarioTreeError, 'GetScenarioTree'))

    return promise
  }

  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return this.props.children({
      _fetchScenarioTree: this._fetchScenarioTree.bind(this),
      GetScenarioTree: this.props.GetScenarioTree
    })
  }
}

const mapStateToProps = (state) => {
  return {
    GetScenarioTree: state.scenarioTree.GetScenarioTree,
    GetScenarioTreeError: state.scenarioTree.error,
    mfpSettings: state.mfpSettings.mfpSettings,
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    Loader: state.loader.loader
  }
}

const mapDispatchToProps = {
  fetchScenarioTree,
  setActionLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchScenarioTree)
