import _ from 'lodash'
import fileList from './ConfigFiles/_settings'

let exportObj = _.map(fileList, (item) => {
  return { [item]: require(`./ConfigFiles/${item}.js`) }
})

exportObj = _.map(exportObj, (item) =>
  _.transform(
    item,
    (obj, { default: code = {} }, key) => {
      obj.type = key
      obj.code = code
      return obj
    },
    {}
  )
)

export default exportObj
