Ext.define('MDM.src.ux.Echarts.Echarts', {
  extend: 'MDM.src.ux.Component',
  xtype: 'viscopm-echarts',
  config: {
    defaultCls: {
      wrapper: Ext.baseCSSPrefix + 'echart-wrapper',
      scene: Ext.baseCSSPrefix + 'echart-scene',
      hidden: Ext.baseCSSPrefix + 'echart-hidden'
    },
    padding: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    isConfiguring: true,
    echarts: null
  },

  // constructor: function (config) {
  //     var me = this;
  //     // var defaultTile = Ext.state.Manager.get('defauleTile');
  //     // if (defaultTile) {
  //     //     config.defaultTile = defaultTile;
  //     // }

  //     this.callParent(arguments);
  // },
  getEcharts: function () {
    if (!this.innerEl || !this.innerEl.dom) return false

    if (!this.echarts) {
      this.isConfiguring = false
      this.echarts = echarts.init(this.innerEl.dom)

      this.fireEvent('chartready', this.map)
    }
    return this.echarts
  },

  resizeHandler: function (size) {
    var me = this,
      echarts = me.getEcharts(),
      isRtl = me.getInherited().rtl,
      width = size && size.width,
      height = size && size.height

    if (!me.isLoaded) {
      //    map.spin(true);
      me.isLoaded = true
    }

    if (!(width && height)) {
      return
    }
    me.innerEl.dom.style.width = width + 'px'
    me.innerEl.dom.style.height = height + 'px'
    if (this.getEcharts()) {
      this.getEcharts().resize()
      // this.performLayout()
    }
    if (echarts) {
      me.resizeHandler(echarts, size)
    }
    me.fireEvent('chartresize', me)
  },
  resizeMapHanler: function (echarts) {
    this.getEcharts().resize()
  },

  applyPadding: function (padding, oldPadding) {
    var result

    if (!Ext.isObject(padding)) {
      result = Ext.util.Format.parseBox(padding)
    } else if (!oldPadding) {
      result = padding
    } else {
      result = Ext.apply(oldPadding, padding)
    }

    return result
  },
  // TODO APPLY ONLY LINESTRING

  destroy: function () {
    var echarts
    if ((echarts = this.getEcharts())) {
      // echarts.off();
      // echarts.removeEventListener();
      // if (echarts.remove) echarts.remove();
    }
    this.callParent()
  }
})
