export default function ErrorView({ message }) {
  return (
    <div className="error-view">
      <p className="error-message">
        <span>
          <i className="fa-fw fa fa-warning" />
        </span>
        {message}
      </p>
    </div>
  )
}
