import { STORE_PIVOT_BUILDER_VIEW_SETTINGS } from '../actions/actionTypes'

const initialState = {
  pivotBuilderViewSettings: {
    isVisible: false,
    draftState: {}
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PIVOT_BUILDER_VIEW_SETTINGS:
      return {
        pivotBuilderViewSettings: {
          ...state.pivotBuilderViewSettings,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default reducer
