import { Modal, Button } from 'react-bootstrap'
import { ILogoutProps } from './Logout.types'

const Logout = ({ show, onHide, onLogout }: ILogoutProps) => {
  return (
    <Modal data-testid="logout-modal" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h4">Are you sure you want to log out?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <div className="d-grid gap-2 w-100">
          <Button size="sm" variant="danger" onClick={onLogout}>
            Log Out
          </Button>
          <Button size="sm" variant="outline-dark" onClick={onHide}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default Logout
