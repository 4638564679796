export default `({
    controller: {
        onCloneProduct: function (grid, rowIndex, colIndex, item, e, record) {
            var me = this,
                productsGrid = me.lookup('productsGrid'),
                store = productsGrid.store,
                data = record.data
            delete data['ProductCode'];
            delete data['id'];
            record = Ext.create('Spui.data.model.ManualMaterial');
                        record.set(data)
            store.insert(0, record);
            productsGrid.selModel.doSelect(record);
        },
        onAcceptProduct: function (grid, rowIndex, colIndex, item, e, record) {
            var view = this.getView();
            view.ownerCt.combo.fireEvent("select", view.ownerCt.combo, record)
            view.ownerCt.close();
        },
        onRemoveProduct: function (grid, rowIndex, colIndex, item, e, record) {
            var view = this.getView(),
                productsGrid = this.lookup('productsGrid'),
                store = productsGrid.store;
            store.remove(record)
            store.sync()
        },
        onSelectProduct: function (grid, record) {
            var me = this;
            c1 = me.lookup('H1N'),
                c2 = me.lookup('H2N'),
                c3 = me.lookup('H3N'),
                c2store = c2.store,
                c3store = c3.store;
            c2store.clearFilter();
            c2store.filter('h1c', record.get('H1C'));
            c3store.clearFilter();
            c3store.filter('h2c', record.get('H2C'));
        },
        onSaveMaterial: function () {
            var me = this,
                viewModel = me.getViewModel(),
                currentProduct = viewModel.get('currentProduct');
            currentProduct.save()
        },
        onSelectC1: function (combo, record) {
            var me = this,
                c2 = me.lookup('H2N'),
                c3 = me.lookup('H3N'),
                c2store = c2.store,
                viewModel = me.getViewModel(),
                currentProduct = viewModel.get('currentProduct');
            if (currentProduct.isModel) {
                currentProduct.set('H1N', record.get('name'));
            }
            c2.setValue(null);
            c3.setValue(null);
            c2store.clearFilter()
            c2store.filter('h1c', combo.getValue());
        },
        onSelectC2: function (combo, record) {
            var me = this,
                c2 = me.lookup('H2N'),
                c3 = me.lookup('H3N'),
                c3store = c3.store,
                viewModel = me.getViewModel(),
                currentProduct = viewModel.get('currentProduct');
            if (currentProduct.isModel) {
                currentProduct.set('H2N', record.get('name'));
            }
            c3store.clearFilter()
            c3store.filter('h2c', combo.getValue());
        },
        onSelectC3: function (combo, record) {
            var me = this,
                viewModel = me.getViewModel(),
                currentProduct = viewModel.get('currentProduct');
            if (currentProduct.isModel) {
                currentProduct.set('H3N', record.get('name'));
            }
            c3store.clearFilter()
        },
        onAddNewProduct: function () {
            var me = this,
                productsGrid = me.lookup('productsGrid'),
                store = productsGrid.store;
            record = Ext.create('Spui.data.model.ManualMaterial', {
                MaterialDescription: 'New Product',
                MaterialCode: Ext.id('s', '0000')
            });
            store.insert(0, record);
            productsGrid.selModel.doSelect(record);
        },
        onAddNewProductFromSap: function () {
            var win = Ext.create('Spui.uxWindow', {
                bodyPadding: 4,
                ui: 'sui-window',
                layout: 'fit',
                bodyStyle: {
                    background: '#EDEFF2',
                },
                maximized: true,
                height: '80%',
                width: 950,
                title: tt('MDM'),
                uxItemCfg: 'onAddNewProductFromSap',
            })
            win.show();
        },
        // onLoadStore: function (store) {
        //     var me = this,
        //         viewModel = me.getViewModel(),
        //         productsGrid = me.lookup('productsGrid'),
        //         record = store.first();
        //     productsGrid.selModel.doSelect(record);
        // }
    },
    viewModel: {
        store: {
            searchStore: {
                autoLoad: true,
                model: 'Spui.data.model.Product',
                listeners: {
                    //  load: 'onLoadStore'
                },
                proxy: {
                    type: 'ajax',
                    actionMethods: {
                        create: 'POST',
                        read: 'POST',
                        update: 'POST',
                        destroy: 'POST'
                    },
                    url: Spui.Global.getApiUrl() + 'spui/mdm',
                },
            }
        },
        formulas: {
            currentProduct: {
                bind: {
                    bindTo: "{productsGrid.selection}",
                    deep: true
                },
                get: function (b) {
                    return b
                },
                set: function (b) {
                    if (!b.isModel) {
                        b = this.get("customers").getById(b)
                    }
                    this.set("currentProduct", b)
                }
            }
        }
    },
    defaults: {
        collapsible: true,
        split: true,
        bodyPadding: 10
    },
    layout: 'border',
    items: [{
            title: tt('Product List'),
            region: 'center',
            xtype: 'grid',
            collapsible: false,
            border: true,
            reference: "productsGrid",
            tbar: ['->', {
                    text: tt('Add New Product'),
                    handler: 'onAddNewProduct',
                    iconCls: 'x-fa fa-plus-circle'
                },
                {
                    text: tt('Add new Product From library'),
                    //  handler: 'onAddNewProductFromSap',
                    menu: {
                        items: [{
                            xtype: 'uxPanel',
                            uxItemCfg: 'onAddNewProductFromSap',
                            width: 600,
                            height: 600
                        }]
                    },
                    iconCls: 'x-fa fa fa-clone'
                }
            ],
            store: {
                autoLoad: true,
                model: 'Spui.data.model.ManualMaterial',
                listeners: {
                    //  load: 'onLoadStore'
                },
                // proxy: {
                //     type: 'ajax',
                //     actionMethods: {
                //         create: 'POST',
                //         read: 'POST',
                //         update: 'POST',
                //         destroy: 'POST'
                //     },
                //     url: Spui.Global.getApiUrl() + 'spui/MDM_MaterialMaster',
                // },
            },
            listeners: {
                select: 'onSelectProduct'
            },
            columns: [{
                    menuDisabled: true,
                    sortable: false,
                    xtype: 'actioncolumn',
                    resizable: false,
                    width: 30,
                    align: 'center',
                    items: [{
                        iconCls: 'x-fa  fa-arrow-circle-o-left',
                        tooltip: tt('Apply'),
                        // isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                        //     return !rec.get('IsEditable');
                        // },
                        handler: 'onAcceptProduct'
                    }]
                },
                {
                    menuDisabled: true,
                    sortable: false,
                    xtype: 'actioncolumn',
                    resizable: false,
                    width: 30,
                    align: 'center',
                    items: [{
                        iconCls: 'x-fa   fa-trash',
                        tooltip: tt('Düzenle'),
                        isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                            return !rec.get('IsEditable');
                        },
                        handler: 'onRemoveProduct'
                    }]
                },
                {
                    text: tt('MaterialCode'),
                    dataIndex: 'MaterialCode',
                    width: 100
                },
                {
                    text: tt('MaterialDescription'),
                    dataIndex: 'MaterialDescription',
                    width: 200
                },
                {
                    text: tt('H1N'),
                    dataIndex: 'H1N',
                    width: 200
                },
                {
                    text: tt('H2N'),
                    dataIndex: 'H2N',
                    width: 200
                },
                {
                    text: tt('H3N'),
                    dataIndex: 'H3N',
                    width: 200
                },
                // {
                //     text: tt('BaseUnitofMeasure'),
                //     dataIndex: 'BaseUnitofMeasure',
                //     width: 100
                // },
                // {
                //     text: tt('WeightUnit'),
                //     dataIndex: 'WeightUnit',
                //     width: 100
                // },
                // {
                //     text: tt('NetWeight'),
                //     dataIndex: 'NetWeight',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: tt('GrossWeight'),
                //     dataIndex: 'GrossWeight',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: tt('PackageBaseUnitofMeasure'),
                //     dataIndex: 'PackageBaseUnitofMeasure',
                //     width: 100
                // },
                // {
                //     text: tt('UnitsPerPackage'),
                //     dataIndex: 'UnitsPerPackage',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: tt('PackageGrossWeight'),
                //     dataIndex: 'PackageGrossWeight',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: tt('PackageNetWeight'),
                //     dataIndex: 'PackageNetWeight',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: tt('PalletUnitofMeasure'),
                //     dataIndex: 'PalletUnitofMeasure',
                //     width: 100
                // },
                // {
                //     text: 'UnitsPerPallet',
                //     dataIndex: 'UnitsPerPallet',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: 'PackagePerPallet',
                //     dataIndex: 'PackagePerPallet',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: 'TABAN',
                //     dataIndex: 'TABAN',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: 'MaterialClass',
                //     dataIndex: 'MaterialClass',
                //     width: 100
                // },
                // {
                //     text: 'TotalShelfLife',
                //     dataIndex: 'TotalShelfLife',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: 'MinimumRemainingShelfLife',
                //     dataIndex: 'MinimumRemainingShelfLife',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: 'CrossPlantMaterialStatus',
                //     dataIndex: 'CrossPlantMaterialStatus',
                //     width: 100
                // },
                // {
                //     text: 'STATU',
                //     dataIndex: 'STATU',
                //     width: 100
                // },
                // {
                //     text: 'CIScenarioID',
                //     dataIndex: 'CIScenarioID',
                //     width: 100,
                //     format: '000.00',
                //     align: 'right',
                //     xtype: 'numbercolumn'
                // },
                // {
                //     text: 'Deleted',
                //     dataIndex: 'Deleted',
                //     width: 100
                // },
                // {
                //     text: 'ZZDIREKT',
                //     dataIndex: 'ZZDIREKT',
                //     width: 100
                // },
                // {
                //     text: 'ZZNITELIK',
                //     dataIndex: 'ZZNITELIK',
                //     width: 100
                // },
                // {
                //     text: 'MEIN3',
                //     dataIndex: 'MEIN3',
                //     width: 105
                // }
            ],
        },
        {
            title: tt('Product Edit'),
            //  floatable: false,
            width: 600,
            minWidth: 500,
            bind: {
                disabled: '{!currentProduct}'
            },
            xtype: 'form',
            region: 'east',
            fieldDefaults: {
                labelAlign: 'left',
                labelWidth: 130,
                msgTarget: 'side',
                labelStyle: 'font-weight:bold;',
                style: 'font-weight:bold; padding:1px',
            },
            cls: '',
            border: true,
            bodyPadding: 10,
            bbar: ['->', {
                    text: tt('Kaydet'),
                    handler: 'onSaveMaterial'
                },
                {
                    text: tt('SAP ile eslestir')
                },
                {
                    text: tt('SAP Aktar')
                }
            ],
            dockedItems: [{
                xtype: 'toolbar',
                docked: 'top',
                height: 44,
                border: true,
                items: [{
                    xtype: 'tbtext',
                    html: '<b>' + tt('Product parameters') + '</b>'
                }]
            }],
            items: [{
                    // Fieldset in Column 1 - collapsible via toggle button
                    xtype: 'fieldset',
                    columnWidth: 0.5,
                    title: 'Product Info',
                    collapsible: true,
                    defaultType: 'textfield',
                    defaults: {
                        anchor: '100%'
                    },
                    layout: 'anchor',
                    allowBlank: false,
                    items: [{
                        fieldLabel: tt('MaterialCode'),
                        disabled: true,
                        bind: "{currentProduct.MaterialCode}"
                    }, {
                        fieldLabel: tt('MaterialDescription'),
                        bind: "{currentProduct.MaterialDescription}"
                    }]
                },
                {
                    // Fieldset in Column 1 - collapsible via toggle button
                    xtype: 'fieldset',
                    columnWidth: 0.5,
                    title: 'Product Hierarcy',
                    collapsible: true,
                    defaultType: 'textfield',
                    defaults: {
                        anchor: '100%'
                    },
                    layout: 'anchor',
                    items: [{
                        fieldLabel: tt('H1N'),
                        xtype: 'combo',
                        reference: 'H1N',
                        allowBlank: false,
                        bind: "{currentProduct.H1C}",
                        listeners: {
                            select: 'onSelectC1'
                        },
                        store: {
                            fields: ['code', 'name'],
                            autoLoad: true,
                            proxy: {
                                type: 'ajax',
                                extraParams: {
                                    level: 1
                                },
                                actionMethods: {
                                    create: 'POST',
                                    read: 'POST',
                                    update: 'POST',
                                    destroy: 'POST'
                                },
                                url: Spui.Global.getApiUrl() + 'spui/mdm_Hie_Level',
                            },
                        },
                        valueField: 'code',
                        displayField: 'name',
                    }, {
                        fieldLabel: tt('H2N'),
                        xtype: 'combo',
                        reference: 'H2N',
                        bind: "{currentProduct.H2C}",
                        allowBlank: false,
                        listeners: {
                            select: 'onSelectC2'
                        },
                        store: {
                            fields: ['code', 'name'],
                            autoLoad: true,
                            proxy: {
                                type: 'ajax',
                                extraParams: {
                                    level: 2,
                                    H1C: 0,
                                },
                                actionMethods: {
                                    create: 'POST',
                                    read: 'POST',
                                    update: 'POST',
                                    destroy: 'POST'
                                },
                                url: Spui.Global.getApiUrl() + 'spui/mdm_Hie_Level',
                            },
                        },
                        valueField: 'code',
                        displayField: 'name',
                    }, {
                        fieldLabel: tt('H3N'),
                        xtype: 'combo',
                        reference: 'H3N',
                        bind: "{currentProduct.H3C}",
                        allowBlank: false,
                        listeners: {
                            select: 'onSelectC3'
                        },
                        store: {
                            fields: ['code', 'name'],
                            autoLoad: true,
                            proxy: {
                                type: 'ajax',
                                extraParams: {
                                    level: 3,
                                    H1C: 0,
                                },
                                actionMethods: {
                                    create: 'POST',
                                    read: 'POST',
                                    update: 'POST',
                                    destroy: 'POST'
                                },
                                url: Spui.Global.getApiUrl() + 'spui/mdm_Hie_Level',
                            },
                        },
                        valueField: 'code',
                        displayField: 'name',
                    }, ]
                },
                {
                    // Fieldset in Column 1 - collapsible via toggle button
                    xtype: 'fieldset',
                    columnWidth: 0.5,
                    hidden: true,
                    title: 'Product attri...',
                    collapsible: true,
                    defaultType: 'textfield',
                    allowBlank: false,
                    defaults: {
                        anchor: '100%'
                    },
                    layout: 'anchor',
                    items: [{
                            fieldLabel: tt('BaseUnitofMeasure'),
                            name: 'field1'
                        },
                        {
                            fieldLabel: tt('UnitsPerPackage'),
                            name: 'field1'
                        },
                        {
                            fieldLabel: tt('PackageGrossWeight'),
                            name: 'field1'
                        },
                        {
                            fieldLabel: tt('PackageNetWeight'),
                            name: 'field1'
                        },
                    ]
                },
            ]
        }
    ]
})
`
