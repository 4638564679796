Ext.define('MDM.src.view.globalapp.Panel.Geo.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.globalapp-geo-mainviewmodel',
  stores: {
    GeoTreeStore: {
      type: 'tree',
      model: 'MDM.src.view.globalapp.Panel.Geo.model.Tree',
      autoLoad: false,
      listeners: {
        load: 'onTreeLoad'
      },
      root: {
        expanded: true,
        Title: 'Georgraphy Hierarchy',
        iconCls: 'fa  fa-globe',
        id: '0',
        children: []
      }
    }
  }
})
