import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { slvyToast } from '../../../../components'
import AssortmentSelection from './AssortmentSelection/index'
import InformationForm from '../InformationForm'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { getOptions } from '../../store/slices/options'
import { saveAssortmentRequest } from '../../store/slices/assortment'
import {
  selectScenario,
  selectScenarioName,
  toggleScenarioSelection,
  resetScenarioSelection
} from '../../store/slices/treeSelection'
import { getTree } from '../../store/slices/tree'

class SaveAsAssortment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: props.show,
      information: {
        name: '',
        description: ''
      },
      isWizardCompleted: false,
      step: 1
    }
  }

  onScenarioSelected($params) {}

  onFormInformationChanged({ key, value }) {
    const { information = {} } = this.state

    this.setState({
      information: {
        ...information,
        [key]: value
      }
    })
  }

  goBack() {
    const { onSaveAsScenarioToggle = () => {} } = this.props
    onSaveAsScenarioToggle()
  }

  go(direction) {
    let { step } = this.state
    step = direction === 'prev' ? step - 1 : step + 1
    this.setState({ step })
  }

  handleSaveAsClicked() {
    const {
      information: { name: Name = '', description: Description = '' }
    } = this.state

    const {
      rootOptions: { AssortmentId = null } = {},
      selectedScenarioSaveTree: { currentScenario: { Id = null, ProjectId = null } = {} } = {}
    } = this.props

    this.onSaveAssortmentFunc({
      AssortmentId,
      NodeStr: {
        MotherId: Id,
        Name: Name.trim(),
        Description: Description.trim(),
        ProjectId
      }
    })
  }

  onSaveAssortmentFunc(params) {
    const {
      pluginId,
      saveAssortmentRequest = () => {},
      onSaveAsAssortment = () => {},
      resetScenarioSelection = () => {},
      setLoader = () => {}
    } = this.props

    const payload = {
      pluginId,
      method: 'Save',
      requestMethod: 'post',
      body: {
        ...params
      }
    }

    setLoader({ isShown: true, messages: 'Save As Assortment' })

    saveAssortmentRequest({ ...payload }).then(() => {
      const {
        SaveAssortment,
        SaveAssortment: { Code = null, Description = '' } = {},
        error,
        error: { message: errorMessage = '' } = {}
      } = this.props

      if (!_.isEmpty(error)) {
        slvyToast.error({ message: errorMessage, title: 'Save As Assortment' })
      } else if (!_.isEmpty(SaveAssortment)) {
        resetScenarioSelection({ dataKey: 'saveTree' })

        this.fetchTree(() => {
          const { tree: { Root: { Children = [] } = {} } = {} } = this.props

          slvyToast.success({ message: Description, title: 'Save As Assortment' })

          if (Code) {
            this.loadScenario(Code, () => {
              const current = this.findById(Children, Code)
              if (current) {
                this.selectScenario(current)
              }
              onSaveAsAssortment()
            })
          }

          setLoader({ isShown: false, messages: '' })
        })
      }
    })
  }

  findById(data, Id) {
    let result = null

    function findById(data, Id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].Id === Id) {
          result = { ...data[i] }
          break
        } else if (data[i].Children && data[i].Children.length) {
          findById(data[i].Children, Id)
        }
      }
      return result
    }

    return findById(data, Id)
  }

  loadScenario(nodeId, callback) {
    const { pluginId, fetchOptions = () => {}, setLoader = () => {} } = this.props

    const payload = {
      pluginId,
      method: 'Load',
      requestMethod: 'post',
      body: {
        nodeId
      }
    }
    setLoader({ isShown: true, messages: 'Load Options' })

    fetchOptions({ ...payload }).then(() => {
      const { rootOptions = {}, errorRootOptions: { message: errorMessage = '' } = {} } = this.props

      if (_.isEmpty(rootOptions)) {
        slvyToast.error({ message: errorMessage, title: 'Load Options' })
      } else {
        callback()
      }
      setLoader({ isShown: false, messages: 'Load Options' })
    })
  }

  selectScenario(item) {
    const { Status = '', Name = '' } = item
    const {
      selectScenario = () => {},
      selectScenarioName = () => {},
      toggleScenarioSelection = () => {},
      dataKey = 'tree'
    } = this.props

    selectScenario({ dataKey, currentScenario: item })

    selectScenarioName({ dataKey, scenarioName: Name })

    toggleScenarioSelection({
      dataKey,
      scenarioName: Name,
      currentScenario: item,
      isFolderSelected: Status === 'FOLDER'
    })
  }

  fetchTree($callback) {
    const { pluginId, fetchTree = () => {} } = this.props

    fetchTree({ pluginId, method: 'GetTree' }).then($callback)
  }

  isActive($currentStep) {
    const { step = 1 } = this.state
    return step === $currentStep
  }

  render() {
    const {
      props: {
        pluginId,
        selectedScenarioSaveTree: {
          currentScenario: { Id: selectedScenarioSaveTreeId = null } = {},
          scenarioName: selectedScenarioSaveTreeName = '',
          isFolderSelected = false
        } = {}
      },
      state: {
        step = 1,
        information: { name = '', description = '' }
      }
    } = this

    const isSaveAsScenarioEnabled = !!(
      selectedScenarioSaveTreeId &&
      selectedScenarioSaveTreeName &&
      isFolderSelected
    )

    const isDisableSelection = !isSaveAsScenarioEnabled
    const isDisableInformation = name.length === 0

    return (
      <div className="h-100">
        <div className="wizardHeader" style={{ float: 'none' }}>
          <Button
            className="-goBack"
            size="sm"
            type="button"
            variant="outline-dark"
            onClick={this.goBack.bind(this, false)}
          >
            <span className="slvy-ui-icon-times-lt" />
          </Button>
          <div className="pageTitle">Save As</div>
          <div className="wizardHeader-steps">
            <Button
              disabled={step === 1}
              size="sm"
              variant={this.isActive(1) ? 'success' : 'outline-dark'}
              onClick={this.go.bind(this, 'prev')}
            >
              1
            </Button>
            <Button
              className={this.isActive(2) ? 'success' : 'outline-dark'}
              disabled={isDisableSelection || step === 1}
              size="sm"
              onClick={this.go.bind(this, 'next')}
            >
              2
            </Button>
          </div>
        </div>
        {step === 1 ? (
          <AssortmentSelection
            pluginId={pluginId}
            onScenarioSelected={this.onScenarioSelected.bind(this)}
          />
        ) : null}
        {step === 2 ? (
          <InformationForm
            description={description}
            name={name}
            onFormInformationChanged={this.onFormInformationChanged.bind(this)}
          />
        ) : null}
        <div className="wizardFooter">
          {step === 1 ? (
            <Button
              className="btn"
              disabled={isDisableSelection}
              size="sm"
              variant="outline-dark"
              onClick={this.go.bind(this, 'next')}
            >
              Next
            </Button>
          ) : null}
          {step === 2 ? (
            <Button
              disabled={isDisableInformation}
              size="sm"
              variant="success"
              onClick={this.handleSaveAsClicked.bind(this)}
            >
              Apply
            </Button>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    SaveAssortment: state.assortment.SaveAssortment,
    isLoading: state.assortment.isLoadingSave,
    error: state.assortment.errorSave,
    selectedScenarioSaveTree: state.treeSelection.saveTree,
    tree: state.tree.tree
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    saveAssortmentRequest: bindActionCreators(saveAssortmentRequest, dispatch),
    selectScenario: bindActionCreators(selectScenario, dispatch),
    selectScenarioName: bindActionCreators(selectScenarioName, dispatch),
    toggleScenarioSelection: bindActionCreators(toggleScenarioSelection, dispatch),
    resetScenarioSelection: bindActionCreators(resetScenarioSelection, dispatch),
    fetchTree: bindActionCreators(getTree, dispatch),
    fetchOptions: bindActionCreators(getOptions, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveAsAssortment)
