import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom'
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'
import userManager from './utils/userManager'
import { ErrorBoundary, Renew, SlvyToastContainer, StateToProps } from './components'
import WithRouterDom from './WithRouterDom'
import {
  App,
  PluginSettings,
  Callback,
  Layout,
  Home,
  MenuIndex,
  Page,
  Menu,
  MenuAdd,
  MenuEdit,
  Catalog,
  CatalogAdd,
  CatalogEdit,
  Users,
  Profile,
  Notification,
  TransferObject,
  LayoutWithoutNav,
  Management
} from './containers'

const Root = ({ store }: { store: ToolkitStore }) => {
  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <Router>
          <Routes>
            <Route
              element={
                <ErrorBoundary>
                  <Suspense fallback="">
                    <WithRouterDom as={Callback} />
                  </Suspense>
                </ErrorBoundary>
              }
              path="/callback"
            />
            <Route
              element={
                <ErrorBoundary>
                  <Suspense fallback="">
                    <Renew />
                  </Suspense>
                </ErrorBoundary>
              }
              path="/silent_renew"
            />
            <Route element={<WithRouterDom as={App} />}>
              <Route element={<LayoutWithoutNav />}>
                <Route element={<Navigate to="/Live" />} path="/" />
                <Route element={<WithRouterDom as={Catalog} />} path="/" />
                <Route element={<WithRouterDom as={Catalog} />} path="/:environment" />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={CatalogAdd} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/add"
                />
              </Route>
              <Route element={<WithRouterDom as={Layout} />}>
                <Route
                  element={<WithRouterDom as={Home} />}
                  path="/:environment/catalog/:catalogId"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={CatalogEdit} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/edit"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={Users} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/users"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={Profile} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/profile"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={Notification} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/notifications"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={Menu} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/menu"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={MenuAdd} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/menu/add"
                />
                <Route
                  element={<WithRouterDom as={Page} />}
                  path="/:environment/catalog/:catalogId/menu/:menuId"
                />
                <Route
                  element={<MenuIndex />}
                  path="/:environment/catalog/:catalogId/store/:storeIndex/menu/:menuId"
                />
                <Route
                  element={<WithRouterDom as={Page} />}
                  path="/:environment/catalog/:catalogId/store/:storeIndex/menu/:menuId/page/:pageId"
                />
                <Route
                  element={<WithRouterDom as={Page} />}
                  path="/:environment/catalog/:catalogId/store/:storeIndex/menu/:menuId/page/:pageId/:comp"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={StateToProps} child={<PluginSettings />} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/menu/:menuId/page/:pageId/plugin/:pluginId"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={MenuEdit} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/menu/:menuId/edit"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={StateToProps} child={<TransferObject />} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/transferobject"
                />
                <Route
                  element={
                    <ErrorBoundary>
                      <Suspense fallback="">
                        <WithRouterDom as={Management} />
                      </Suspense>
                    </ErrorBoundary>
                  }
                  path="/:environment/catalog/:catalogId/management"
                />
              </Route>
            </Route>
          </Routes>
        </Router>
      </OidcProvider>
      <SlvyToastContainer />
    </Provider>
  )
}

export default Root
