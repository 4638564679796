import cx from 'classnames'
import { SlvySpinner, SlvyTable } from '@/components'
import { emptyDataMessage } from '../../../../constants'
import { DisplayType } from '../../../../LanguageModel.types'
import { MessageProps } from './Message.types'
import styles from './index.module.scss'

const Message = ({ data }: MessageProps) => {
  const { author, content } = data
  const { displayType } = content

  const isText = displayType === DisplayType.Text
  const isGrid = displayType === DisplayType.Grid

  if (isText) {
    return (
      <div>
        <h5 className="mb-2">{author}</h5>
        <p className="mb-1 fs-sm">{content.text}</p>
      </div>
    )
  }

  if (isGrid) {
    const { table, text } = content

    const firstRow = table[0]
    const columns = Object.keys(firstRow ?? {}).map((column) => {
      const isNumber = typeof firstRow[column] === 'number'
      const format = isNumber ? '0,0.0' : ''
      return {
        header: column,
        accessorKey: column,
        meta: { format }
      }
    })

    return (
      <div className={styles.gridContainer}>
        <h5 className="mb-2">{author}</h5>
        {text ? <p className="mb-2 fs-sm">{text}</p> : null}
        {columns.length ? (
          <SlvyTable
            suppressColumnMenu
            className={cx('text-dark', 'border-0', 'border-bottom', styles.grid)}
            columns={columns}
            data={table}
            getBodyClassName={cx('mb-0', styles.gridBody)}
            getCellClassName={styles.gridCell}
            getHeaderClassName={styles.gridCell}
            theme="sencha-grid"
          />
        ) : null}
        {!text && !columns.length ? <p className="mb-1 fs-sm">{emptyDataMessage}</p> : null}
      </div>
    )
  }

  return <SlvySpinner containerClass="position-relative" size="sm" />
}

export default Message
