Ext.define('po.cmp.RunButton', {
  extend: 'Ext.button.Split',
  xtype: 'poRunButton',
  iconCls: 'fa fa-bolt',
  itemId: 'OneriVar',
  initComponent: function () {
    this.menu = new Ext.menu.Menu({
      defaults: {
        handler: 'onRunReport'
      },
      items: [
        {
          itemId: 'SapHaric',
          iconCls: 'fa fa-bullseye',
          bind: {
            text: '{i18n.toolbar_RunButtonMenu_SapHaric}'
          }
        },
        {
          itemId: 'OneriVar',
          iconCls: 'fa fa-bullseye',
          bind: {
            text: '{i18n.toolbar_RunButtonMenu_onerivar}'
          }
        },
        {
          itemId: 'OneriYok',
          iconCls: 'fa fa-bullseye',
          bind: {
            text: '{i18n.toolbar_RunButtonMenu_oneriyok}'
          }
        },
        {
          itemId: 'Onaylanmis',
          iconCls: 'fa fa-bullseye',
          bind: {
            text: '{i18n.toolbar_RunButtonMenu_Onaylanmis}'
          }
        },
        {
          itemId: 'OnayBekleyen',
          iconCls: 'fa fa-bullseye',
          bind: {
            text: '{i18n.toolbar_RunButtonMenu_OnayBekleyen}'
          }
        },
        {
          itemId: 'All',
          iconCls: 'fa fa-bullseye',
          bind: {
            text: '{i18n.toolbar_RunButtonMenu_All}'
          }
        }
      ]
    })
    this.callParent()
  }
})
