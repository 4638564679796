// import { getMappedRowData } from '../../row'
// import { fieldConfigs, pluginData } from '../mocks'

describe('AgGrid:Helpers:Row:getMappedRowData', () => {
  it('should skip test', () => {})
  // it('should return JS date object if dataType is datetime', () => {
  // const pluginDataPart = pluginData.slice(0, 3)
  // const data = getMappedRowData(pluginDataPart, fieldConfigs)
  // const result = []
  // pluginDataPart.forEach(() => {})
  // fieldConfigs.forEach(({ dataType, fieldName }) => {
  //   if (dataType === 'datetime') {
  //     result[fieldName] = result[fieldName] ? new Date(result[fieldName]) : null
  //   }
  // })
  // expect(data).toEqual(result)
  // })
})
