Ext.define('MDM.Btl.model.MegaRegion', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/btl_mega_region',
  fields: [
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'value',
      type: 'int'
    }
  ]
})
