/* eslint-disable jest/no-export */
import { DEFAULT_CUSTOM_ROW_INFO } from '../../../../constants'
import { AG_GRID_ENUMS } from '../../../../enums'
import { TcustomConfigs } from '../../../../types'
import { MockCellRendererSelectorParams, MockEditableCallbackParams } from '../../cell.types'
import { HighchartOptions } from '../../../../components/CellRenderers/HighchartCellRenderer/HighchartCellRenderer.types'
import { SparklineOptions } from '../../../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'

const { CUSTOM_ROW_INFO, ROW_ID } = AG_GRID_ENUMS

export const cellRendererSelectorParams: MockCellRendererSelectorParams = {
  node: {
    footer: false
  },
  context: {
    groupDisplayType: 'groupRows'
  }
}

export const editableCallbackParams: MockEditableCallbackParams = {
  colDef: {
    field: 'col1',
    customInfo: {
      field: 'col1',
      fieldConfig: { fieldName: 'col1', dataType: 'string' },
      fieldLookupConfig: {
        isLookupQuery: false,
        substituteField: null,
        lookupQueryList: []
      },
      columnId: '',
      isExportable: false,
      deletableCondition: '',
      boolean: { trueIcon: '', falseIcon: '', trueColor: '', falseColor: '' },
      cellStyle: {
        textAlign: 'start',
        backgroundColor: '',
        color: ''
      },
      adding: {
        enabled: false
      },
      editing: {
        enabled: false,
        editableCondition: undefined,
        allowBlank: false,
        maxValue: null,
        minValue: null,
        step: null,
        maxValueField: '',
        minValueField: '',
        stepField: '',
        lookupDataField: '',
        warningThreshold: 0
      },
      cellDoubleClickEnabled: false,
      actionButton: {
        actionEnabled: false,
        actionName: '',
        actionEditableCondition: '',
        actionClickable: false
      },
      showInMaxMode: false,
      customAggregation: '',
      rowGroup: false,
      filtering: {
        showList: undefined,
        tagFilter: false
      },
      icon: {
        icon: undefined,
        displayOnlyIcon: false,
        iconPosition: 'left'
      },
      template: '',
      summaryTemplate: '',
      columnType: 'color',
      cellTooltip: undefined,
      formatField: undefined,
      aggFunc: '',
      hiddenCondition: '',
      width: 0,
      highchartOptions: undefined as unknown as HighchartOptions,
      sparklineOptions: undefined as unknown as SparklineOptions
    }
  },
  data: {
    col1_backColor: 'green',
    col1_textColor: 'yellow',
    col1: 'test',
    col2: 7,
    col3: 14,
    col4: '1',
    col5: 'vito',
    col6: '2020-04-09T21:00:00.000Z',
    col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme\n\nsave button test\n',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1,
    [CUSTOM_ROW_INFO]: {
      ...DEFAULT_CUSTOM_ROW_INFO,
      [ROW_ID]: '5'
    }
  }
}

export const cellRendererParamsForGetCellColors = {
  value: '3',
  colDef: {
    field: 'col1'
  },
  data: {
    col1_backColor: 'green',
    col1_textColor: 'yellow',
    col1: '3',
    col2: 7,
    col3: 14,
    col4: '1',
    col5: 'vito',
    col6: '2020-04-09T21:00:00.000Z',
    col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme\n\nsave button test\n',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1,
    [CUSTOM_ROW_INFO]: {
      ...DEFAULT_CUSTOM_ROW_INFO,
      [ROW_ID]: '8dc33deb-3df1-4f73-b371-0c8c5c73b179'
    }
  }
}

export const columnEditing: TcustomConfigs['editing'] = {
  enabled: true,
  editableCondition: '',
  allowBlank: true,
  maxValue: null,
  minValue: null,
  step: null,
  minValueField: '',
  maxValueField: '',
  stepField: '',
  lookupDataField: '',
  warningThreshold: 0
}

export const cellParamData = {
  col1: 14,
  col2: 7,
  col3: 14,
  col4: '1',
  col5: 'vito',
  col6: '2020-04-09T21:00:00.000Z',
  col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
  col8: null,
  col9: '-100,NULL,0,100,100',
  col10: false,
  col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
  col12: 3,
  col3_readonly: 1,
  col4_readonly: 1,
  col5_readonly: 1,
  [CUSTOM_ROW_INFO]: {
    ...DEFAULT_CUSTOM_ROW_INFO,
    [ROW_ID]: 'dc6a040e-d94c-40c6-b7b7-84f87748311b'
  }
}

export const validCellParamsData = {
  col1: 2,
  col2: 213234,
  col3: 2,
  col4: '99',
  col5: 'asdf',
  col6: '2023-11-10T21:00:00.000Z',
  col7: 'fredo',
  col8: null,
  col9: '0,0,0,NULL',
  col10: true,
  col11: '1,-1,1,-1,1,-1',
  col12: 3,
  col3_readonly: 1,
  col4_readonly: 1,
  col5_readonly: 1,
  [CUSTOM_ROW_INFO]: {
    ...DEFAULT_CUSTOM_ROW_INFO,
    [ROW_ID]: '69b8c252-17f1-4f5f-8c13-10328d57e8b7'
  }
}

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
