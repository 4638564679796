import { Component, createElement } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import _ from 'lodash'
import Box from './Box'

export default class Operations extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.state = {
      formula: '',
      args: {},
      ...props.value,
      title: ''
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { formula, args } = this.state
    if (this.props.onChange && !_.isEqual(nextProps.value, { formula, args })) {
      this.setState({
        ...this.state,
        ...nextProps.value
      })
    }
  }

  handleClick(key) {
    return (event) => {
      this.setState({ formula: key })
    }
  }

  handleDelete() {
    this.setState({ formula: '' })
    if (this.props.onChange) {
      this.props.onChange({
        formula: '',
        args: {}
      })
    }
  }

  handleChange(value) {
    if (this.props.onChange) {
      this.props.onChange({ formula: this.state.formula, args: value })
    }
  }

  createComponent(componentName, props, children) {
    const requireContext = require.context('./Formulas', true, /^\.\/.*\.js$/)
    const filteredKeys = _.find(requireContext.keys(), (x) => _.endsWith(x, `${componentName}.js`))
    if (filteredKeys) {
      const pluginContext = requireContext(filteredKeys)
      return createElement(pluginContext.default, props, children)
    }
    return null
  }

  render() {
    const { formula, args } = this.state
    if (_.isEmpty(formula)) {
      return (
        <Box>
          <ButtonGroup>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('If')}
            >
              If
            </Button>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('Add')}
            >
              Add
            </Button>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('Subtract')}
            >
              Subtract
            </Button>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('Multiply')}
            >
              Multiply
            </Button>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('Divide')}
            >
              Divide
            </Button>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('ColumnValue')}
            >
              ColumnValue
            </Button>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('SimpleValue')}
            >
              SimpleValue
            </Button>
            <Button
              className="mx-1"
              size="sm"
              type="button"
              variant="secondary"
              onClick={this.handleClick('Format')}
            >
              Format
            </Button>
          </ButtonGroup>
        </Box>
      )
    }
    let component = ''
    switch (formula) {
      case 'Add':
      case 'Subtract':
      case 'Multiply':
      case 'Divide':
        component = 'ArithmeticOperator'
        break
      default:
        component = formula
        break
    }

    return (
      <Box title={formula} onDelete={this.handleDelete}>
        {this.createComponent(component, {
          value: args,
          onChange: this.handleChange,
          columns: this.props.columns,
          dataTypes: this.props.dataTypes
        })}
      </Box>
    )
  }
}
