import { Component } from 'react'

export default class ColumnValue extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      name: '',
      ...props.value
    }
  }

  handleChange(event) {
    this.setState({ name: event.target.value })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.onChange && nextProps.value.name !== this.state.name) {
      this.setState({ name: nextProps.value.name })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.onChange && nextState.name !== this.state.name) {
      this.props.onChange({ name: nextState.name })
    }
  }

  render() {
    return (
      <div>
        <select
          className="form-select form-select-sm"
          value={this.state.name}
          onChange={this.handleChange}
        >
          <option>Select...</option>
          {this.props.columns.map((item) => (
            <option key={item.fieldName} value={item.fieldName}>
              {item.fieldName}
            </option>
          ))}
        </select>
      </div>
    )
  }
}
