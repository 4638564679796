import { Component } from 'react'
import { Row, Col, Form, ButtonGroup, Button, Nav, Tab } from 'react-bootstrap'
import _ from 'lodash'
import cx from 'classnames'

import { isVisible, isContainer } from '../helpers'
import OneOfEditor from './OneOfEditor'
import EditorSelector from '../EditorSelector'
import StyleEditor from '../StyleEditor'

export default class ObjectEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '',
      showAdvanced: false,
      value: {}
    }

    this.renderObjectTab = this.renderObjectTab.bind(this)
    this.renderTabTitle = this.renderTabTitle.bind(this)
    this.renderTabContent = this.renderTabContent.bind(this)
    this.renderProperties = this.renderProperties.bind(this)
    this.renderProperty = this.renderProperty.bind(this)

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { schema = {}, value } = this.props

    const val = value || schema.default || {}

    let firstProp = ''
    if (schema.format === 'objectTab') {
      for (const key in schema.properties) {
        if (isVisible(schema.properties[key])) {
          firstProp = key
          break
        }
      }
    }

    this.setState({ value: val, activeTab: firstProp })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value })
    }
  }

  handleChange() {
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(this.state.value)
    }
  }

  renderObjectTab() {
    const { schema } = this.props
    const { value } = this.state

    const name = value && value.general && value.general.name

    return (
      <Tab.Container className="panel-body" defaultActiveKey={this.state.activeTab}>
        <div className="subProperty">
          <Row>
            <Col>
              <h3 className="bg-solvoyo fs-5 fw-normal w-100 badge p-2 text-start">
                {schema.title + (name ? ` - ${name}` : '')}
              </h3>
            </Col>
          </Row>
          <div className="rows">
            <Row className="g-0">
              <Col sm={2}>
                <Nav as="ul" className="flex-column">
                  {_.map(schema.properties, (data, key) => this.renderTabTitle(data, key))}
                </Nav>
              </Col>
              <Tab.Content className="col-sm-10">
                {_.map(schema.properties, (data, key) => {
                  return (
                    <Tab.Pane key={key} eventKey={key}>
                      <div className="px-2">
                        <div className="subProperty">{this.renderTabContent()}</div>
                      </div>
                    </Tab.Pane>
                  )
                })}
              </Tab.Content>
            </Row>
          </div>
        </div>
      </Tab.Container>
    )
  }

  renderTabTitle(subschema, key) {
    if (!isVisible(subschema)) {
      return null
    }

    const isActive = this.state.activeTab === key

    return (
      <Nav.Item
        key={key}
        as="li"
        className={cx({ active: isActive })}
        id={`#tab${key}`}
        onClick={() => {
          if (!isActive) {
            this.setState({ activeTab: key })
          }
        }}
      >
        <Nav.Link eventKey={key}>{subschema.title}</Nav.Link>
      </Nav.Item>
    )
  }

  renderTabContent() {
    const { schema, onSynchronizeDataDefinition, getWatchedValue, schemaPath } = this.props
    const { activeTab, value } = this.state

    const subSchema = schema.properties[activeTab]
    const subValue = value[activeTab]

    return (
      <EditorSelector
        key={activeTab}
        getWatchedValue={getWatchedValue}
        schema={subSchema}
        schemaPath={`${schemaPath}.${activeTab}`}
        value={subValue}
        onChange={(val) => {
          this.setState(
            (prevState) => ({
              value: { ...prevState.value, [activeTab]: val }
            }),
            this.handleChange
          )
        }}
        onSynchronizeDataDefinition={onSynchronizeDataDefinition}
      />
    )
  }

  renderProperties() {
    const { schema = {} } = this.props
    const { properties = [], options: { splitProperties = false, sortProperties = '' } = {} } =
      schema
    const { showAdvanced = false } = this.state

    let subProperties = properties

    if (sortProperties) {
      subProperties = _(properties)
        .toPairs()
        .orderBy((p) => {
          return p[1].propertyOrder
        })
        .fromPairs()
        .value()
    }

    return (
      <div className="well well-sm">
        <div>
          <div className={splitProperties ? 'basicProperties' : ''}>
            {_.map(subProperties, (data, key) => {
              if (
                !splitProperties ||
                (splitProperties && data.options && data.options.basic === true)
              ) {
                return this.renderProperty(key, data)
              }
            })}
          </div>
          {splitProperties && (
            <div className="showDiv">
              <a
                className="showButton"
                onClick={() => this.setState({ showAdvanced: !showAdvanced })}
              >
                {showAdvanced ? 'Hide' : 'Show'} advanced options
              </a>
            </div>
          )}
          {showAdvanced && <div className="hr-line-dashed d-block" />}
          {showAdvanced && (
            <div className="advancedProperties d-block">
              {_.map(subProperties, (data, key) => {
                if (!(data.options && data.options.basic === true)) {
                  return this.renderProperty(key, data)
                }
              })}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderProperty(key, subSchema) {
    const { onSynchronizeDataDefinition, getWatchedValue, schemaPath, isMultipleColumnActive } =
      this.props
    const { value } = this.state

    if (!isVisible(subSchema) || (isMultipleColumnActive && !subSchema.isMultipleEditable)) {
      return null
    }

    const subValue = value && value[key]

    if (isContainer(subSchema)) {
      const { options: { collapsed = false, disable_collapse = false } = {} } = subSchema

      const visibilityStateKey = `show${key}`
      const { [visibilityStateKey]: stateCollapsed = collapsed } = this.state

      const iconClass = `fa fa-caret-square-o-${stateCollapsed ? 'right' : 'down'}`
      const buttonTitle = stateCollapsed ? 'Expand' : 'Collapse'

      return (
        <div key={`${key}div`} className="subProperty">
          <h3>
            <span>{(subSchema && subSchema.title) || key}</span>
            <ButtonGroup>
              {!disable_collapse && (
                <Button
                  className="json-editor-btn-collapse"
                  title={buttonTitle}
                  type="button"
                  variant="outline-dark"
                  onClick={() =>
                    this.setState({
                      [visibilityStateKey]: !stateCollapsed
                    })
                  }
                >
                  <i className={iconClass} />
                </Button>
              )}
            </ButtonGroup>
          </h3>
          <EditorSelector
            key={key}
            isMultipleColumnActive={isMultipleColumnActive}
            getWatchedValue={getWatchedValue}
            hidden={stateCollapsed}
            schema={subSchema}
            schemaPath={`${schemaPath}.${key}`}
            value={subValue}
            onChange={(val) => {
              if (!isMultipleColumnActive) {
                this.setState({ value: { ...value, [key]: val } }, this.handleChange)
                return
              }
              // If it is nested object
              if (schemaPath.charAt(schemaPath.length - 1) !== ']') {
                const multipleVal = { [val.multipleKey]: val.multipleVal }
                this.setState(
                  { value: { ...value, multipleKey: key, multipleVal } },
                  this.handleChange
                )
                return
              }
              const multipleEdit = {
                [key]: { [val.multipleKey]: val.multipleVal }
              }
              this.setState({ value: { ...value, multipleEdit } }, this.handleChange)
            }}
            onSynchronizeDataDefinition={onSynchronizeDataDefinition}
          />
        </div>
      )
    }
    return (
      <Row key={`${key}div`} className="clear-both">
        <Col sm={12}>
          <div className="form-group mb-3">
            <label className=" col-form-label">{(subSchema && subSchema.title) || key}</label>
            <EditorSelector
              key={key}
              getWatchedValue={getWatchedValue}
              schema={subSchema}
              schemaPath={`${schemaPath}.${key}`}
              value={subValue}
              onChange={(val) => {
                if (!isMultipleColumnActive) {
                  this.setState({ value: { ...value, [key]: val } }, this.handleChange)
                  return
                }
                this.setState(
                  { value: { ...value, multipleKey: key, multipleVal: val } },
                  this.handleChange
                )
              }}
              onSynchronizeDataDefinition={onSynchronizeDataDefinition}
            />
            {subSchema.description && (
              <Form.Text muted className="float-end">
                {subSchema.description}
              </Form.Text>
            )}
          </div>
          <div />
        </Col>
      </Row>
    )
  }

  render() {
    const { schema = {} } = this.props

    // console.log('ObjectEditor:::', this.props)

    // TODO : anyOf

    if (schema.format === 'objectTab') {
      return this.renderObjectTab()
    }
    if (schema.format === 'Style') {
      return <StyleEditor {...this.props} />
    }
    if (schema.oneOf) {
      return <OneOfEditor {...this.props} />
    }
    return this.renderProperties()
  }
}
