import React, { Component } from 'react'
import 'react-bootstrap'
import _ from 'lodash'

export default class InformationForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props)
  }

  handleInputChange(key, event) {
    const { onFormInformationChanged = () => {} } = this.props
    const { target: { value = '' } = {} } = event

    onFormInformationChanged({ key, value: this.regexControl(value) })
  }

  regexControl(value) {
    return value.replace(/\.|\*|\\|>|<|\+|%|\?|\/|&|:|#/g, '')
  }

  render() {
    const { title = '', name = '', description = '', displayHeader = true } = this.props

    return (
      <div className="wizardContainer">
        <div className="col-md-12">
          {displayHeader && title ? <div className="title">{title}</div> : null}
          <form className="form-horizontal simpleContainer mt-3">
            <div className="form-group mb-3 row">
              <div className="col-sm-3">
                <label className="form-label">Name</label>
              </div>
              <div className="col-sm-9">
                <input
                  className="form-control form-controls-m"
                  type="text"
                  value={name}
                  onChange={this.handleInputChange.bind(this, 'name')}
                />
              </div>
            </div>
            <div className="form-group mb-3 row">
              <div className="col-sm-3">
                <label className="form-label">Description</label>
              </div>
              <div className="col-sm-9">
                <input
                  className="form-control form-control-sm"
                  type="text"
                  value={description}
                  onChange={this.handleInputChange.bind(this, 'description')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
