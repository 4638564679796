export const getScenarioLoadedTypes = (PluginTypes) => {
  return {
    mfpId: PluginTypes.int,
    BaselineId: PluginTypes.int,
    BaselineName: PluginTypes.string,
    BaselineScenarioTableName: PluginTypes.string,
    PlannedId: PluginTypes.int,
    PlannedName: PluginTypes.string,
    PlannedScenarioTableName: PluginTypes.string
  }
}
