import { useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import ScheduleUpdateForm from './ScheduleUpdateForm'

export default function MovePopup({
  formProps,
  style,
  placement,
  onEnter,
  onEntered,
  onExited,
  onClick
}) {
  const [show, setShow] = useState(false)

  const handleClick = () => {
    onClick()
    setShow(true)
  }

  const handleClickMove = (e) => {
    setShow(false)
    formProps.moveHandler(e)
  }

  const getOverlay = () => (
    <Popover id="popover-positioned-gantt-form-update">
      <ScheduleUpdateForm
        connectApi={formProps.connectApi}
        coverViolationIndexes={formProps.coverViolationIndexes}
        data={formProps.data}
        getData={formProps.getData}
        isAllowed={formProps.isAllowed}
        lastRequest={formProps.lastRequest}
        settings={formProps.settings}
      />
      <div className="popoverFooter">
        <button
          className="-slvy-btn -small -edit -btn-move"
          disabled={!formProps.isUserAllowedToMoveProduction}
          type="button"
          onClick={handleClickMove}
        >
          MOVE
        </button>
      </div>
    </Popover>
  )

  return (
    <OverlayTrigger
      rootClose
      overlay={getOverlay()}
      placement={placement}
      rootCloseEvent="mousedown"
      show={show}
      trigger="click"
      onEnter={onEnter}
      onEntered={onEntered}
      onExited={onExited}
      onToggle={setShow}
    >
      <div className="-c-sub-item" style={style} onClick={handleClick} />
    </OverlayTrigger>
  )
}
