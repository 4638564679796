Ext.define('MDM.src.view.BomEdit.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.bomedit-mainviewmodel',
  stores: {
    searchStore: {
      model: 'MDM.src.view.BomEdit.model.searchModel',
      remoteSort: 'true', //For Remote Sorting
      autoLoad: true
    },
    BomStore: {
      model: 'MDM.src.view.BomEdit.model.BomModel',
      remoteSort: 'true', //For Remote Sorting
      bindTo: 'bindToBomListStore',
      autoLoad: false,
      autoSync: true,
      listeners: {
        write: 'onWrite'
      }
    }
  },
  formulas: {
    isStoreDirty: {
      bind: {
        bindTo: '{bindToBomListStore}',
        deep: true
      },
      get: function () {
        if (this.getStore('BomStore') && this.getStore('BomStore').isDirty) {
          return this.getStore('BomStore').isDirty()
        }
        return false
      }
    }
  },
  data: {}
})
