import { IDefaultOptions } from './SlvyCodeMirror.types'

export const defaultOptions: IDefaultOptions = {
  autocorrect: true,
  spellcheck: true,
  indentWithTabs: true,
  smartIndent: true,
  lineNumbers: true,
  mode: {
    name: 'text/x-sql'
  },
  extraKeys: {
    'Ctrl-Space': 'autocomplete'
  },
  lint: true,
  matchBrackets: true
}
