export default `({
    headerPosition: 'top',
    split: true,
    collapsible: true,
    collapsed: true,
    forceToLoadStore: true,
    forceToLoadStore: true,
    border: false,
    border: true,
    margin: 0,
    bodyStyle: {
        background: '#fff'
    },
    applySelectedChartPanel: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Selected Spot Sales Chart'),
                xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
                salesChartCfg: 'SpotSelected',
                salesChartStoreCfg: 'SpotSelectedStore',
                salesChartStore: {},
                salesChart: {},
                salesGridCfg: 'SelectedSpotSalesGrid',
                salesGrid: false,
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        b.on('activate', function (b) {
            b.forcetoLoad();
        })
        return b
    },
    updateSelectedChartPanel: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(0, f)
                } else {
                    d.splice(1, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    applySalesChartPanel: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Spot Sales chart'),
                xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        return b
    },
    updateSalesChartPanel: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(0, f)
                } else {
                    d.splice(2, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    applyNomalizedChart: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Normalized Chart'),
                xtype: 'spui-view-common-similarity-tabpanel-nomalizedchart-main',
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        b.getNormalizedChartStore().load()
        return b
    },
    updateNomalizedChart: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(1, f)
                } else {
                    d.splice(0, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    listeners: {
    },
    applySimilarSpotId: function (c) {
        this.getSalesChartPanel().setSimilarSpotId(c)
    },
})
`
