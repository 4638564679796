Ext.define('MDM.src.view.materialedit.panel.CarttRidge', {
  extend: 'Ext.grid.Panel',
  xtype: 'DPICarttRidge',

  columnLines: true,

  listeners: {
    edit: 'onEditCartridge'
  },
  viewConfig: {
    loadMask: false
  },
  title: '',
  tools: [
    {
      type: 'plus',
      tooltip: 'Add new DPI ',
      handler: 'onAddCartridge'
    }
  ],
  selModel: {},
  plugins: [
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],

  columns: [
    { xtype: 'rownumberer' },
    {
      text: 'Related Cartridge DPI',
      dataIndex: 'DPIFamilyID',

      flex: 1,
      sortable: true,
      renderer: function (value, metaData, record) {
        if (record.get('DPIFamilyID')) {
          return record.get('DPIFamily')
        }
        return 'Not selected'
      },
      editor: {
        xtype: 'combo',
        bind: {
          store: '{CartridgeListStore}'
        },
        queryMode: 'local',
        typeAhead: true,
        editable: true,
        triggerAction: 'all',
        displayField: 'DPIFamily',
        valueField: 'DPIFamilyID',
        listeners: {
          beforequery: function (record) {
            record.query = new RegExp(record.query, 'i')
            record.forceAll = true
          }
        }
      }
    },
    {
      text: 'RD Coefficient',
      dataIndex: 'Coefficient',
      type: 'number',
      format: '0.0',
      width: 70,
      align: 'right',
      sortable: true,
      editor: {
        xtype: 'numberfield',
        allowBlank: false,
        allowDecimals: true,
        decimalPrecision: 1,
        step: 0.1,
        minValue: 0,
        maxValue: 100000
      }
    },
    {
      xtype: 'actioncolumn',

      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'fa fa-chain-broken',
          tooltip: 'Remove the material from this DPI Family',
          handler: 'onCartridgeRemoveClick'
        }
      ]
    }
  ]
})
