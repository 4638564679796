const defaultForm = {
  tabs: [],
  ids: [],
  icons: [],
  tabsHidden: false,
  iconsHidden: false,
  iconPosition: 'Right'
} as const

export default defaultForm
