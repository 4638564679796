Ext.define('tuiv2.Colors', {
  extend: 'Ext.Base',
  singleton: true,
  config: {
    colors: [],
    colorIndex: 1,
    definedColors: []
  },
  constructor: function (config) {
    var me = this
    me.initConfig(config)
  },
  getColorByName: function (name, isLeaf) {
    var dc = this.getDefinedColors()

    var c = this.getColors()

    var ci = this.getColorIndex()
    if (dc[name] && dc[name] != 0) return dc[name]

    if (isLeaf === false) {
      dc[name] = '#eaeaea'
      this.setDefinedColors(dc)
    } else {
      dc[name] = c[ci]
      this.setDefinedColors(dc)
      ci = ci + 1
      if (ci > c.length) ci = 0
      this.setColorIndex(ci)
    }

    return dc[name]
  },
  setColorByName: function (name, color, controller) {
    var dc = this.getDefinedColors()
    dc[name] = color
    this.setDefinedColors(dc)
    if (controller.popups['load' + name]) {
      controller.popups['load' + name].tpl.refresh()
    }
    _.forEach(controller.popups, (value, key) => {
      if (key.length > 5 && key.substr(0, 6) === 'demand') {
        value.tpl.refresh()
      }
    })
  },
  reset: function () {
    this.setDefinedColors([])
  },

  getClassByColor: function (color) {
    return this.lightOrDark(color)
  },

  lightOrDark: function (color) {
    // Variables for red, green, blue values
    var r, g, b, hsp

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return '-darkText'
    } else {
      return '-lightText'
    }
  }
})
