Ext.define('Spui.view.wizard.items.Card', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-wizard-items-card',

  flex: 1,
  margin: '0',

  layout: 'card',
  reference: 'wiazarcardlayout',

  items: [
    { xtype: 'spui-view-wizard-pages-spotparams-main' },
    {
      xtype: 'spui-view-common-similarity-main',
      bind: {
        spotId: '{spotRecord.SpotId}'
      }
    },

    {
      xtype: 'spui-view-common-recom-main',
      //@TODO v2 CUI-1060
      id: 'spuiViewCommonRecomMain',
      bind: {
        spotId: '{spotRecord.SpotId}'
      }
    },

    {
      xtype: 'spui-view-wizard-pages-publish-main'
      // bind: {
      // 	spotId: '{spotRecord.SpotId}'
      // }
    }
  ]
})
