import { CellClassParams } from 'ag-grid-community'
import { omit } from 'lodash'
import { getCellColorsFromDataFn } from '../index'
import { cellRendererParamsForGetCellColors } from './mocks'

function getParams() {
  return { ...cellRendererParamsForGetCellColors } as CellClassParams
}

describe('AgGrid:Helpers:Cell:getCellColorsFromDataFn', () => {
  let params = getParams()

  beforeEach(() => {
    params = getParams()
  })

  it('should return null if getCellColorsFromData is false', () => {
    const getCellColorsFromData = false
    const result = getCellColorsFromDataFn({ params, getCellColorsFromData })
    expect(result).toEqual(null)
  })

  it('should return null if params.data is falsy', () => {
    const getCellColorsFromData = true
    params.data = undefined
    const result = getCellColorsFromDataFn({ params, getCellColorsFromData })
    expect(result).toEqual(null)
  })

  it('should return colors if getCellColorsFromData is true and params.data has `columnName_backColor` or columnName_textColor', () => {
    const getCellColorsFromData = true
    const colors = getCellColorsFromDataFn({ params, getCellColorsFromData })
    expect(colors).toEqual({
      backColor: params.data.col1_backColor,
      textColor: params.data.col1_textColor
    })
  })

  it('should return null if getCellColorsFromData is true but params.data does not have either `columnName_backColor` or columnName_textColor', () => {
    const getCellColorsFromData = true
    params.data = omit(params.data, ['col1_backColor', 'col1_textColor'])
    const colors = getCellColorsFromDataFn({ params, getCellColorsFromData })
    expect(colors).toEqual(null)
  })

  it('should return only columnName_backColor if getCellColorsFromData is true and params.data only has `columnName_backColor`', () => {
    const getCellColorsFromData = true
    params.data = omit(params.data, ['col1_textColor'])
    const colors = getCellColorsFromDataFn({ params, getCellColorsFromData })
    expect(colors).toEqual({ backColor: params.data.col1_backColor })
  })

  it('should return only columnName_textColor if getCellColorsFromData is true and params.data only has `columnName_textColor`', () => {
    const getCellColorsFromData = true
    params.data = omit(params.data, ['col1_backColor'])
    const colors = getCellColorsFromDataFn({ params, getCellColorsFromData })
    expect(colors).toEqual({ textColor: params.data.col1_textColor })
  })
})
