import { ICellRendererParams } from 'ag-grid-community'
import { cloneDeep } from 'lodash'
import { BooleanIconCellRenderer } from '../index'
import { defaultFieldConfig } from '../../../constants'
import {
  getCellRendererSelector,
  getExcelNumberFormatId,
  getFieldLookupConfig
} from '../../../helpers'
import { IRowData, SlvyColDef, TconfigColumn, TrowDataValue } from '../../../types/index'
import { GroupRowInnerRendererProps } from './GroupRowInnerRenderer.types'
import { defaultBooleanProps } from './constants'

const GroupColTitleCmp = ({ title }: { title: string | number | null | undefined }) => {
  const isTitleEmpty = title === '' || title === null || typeof title === 'undefined'
  return isTitleEmpty ? <>&nbsp;</> : <>{`${title}: `}</>
}

const GroupRowInnerRenderer = (props: GroupRowInnerRendererProps) => {
  const {
    api,
    node: {
      field,
      id,
      rowGroupColumn: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        colDef: { headerName }
      }
    },
    innerRendererParams: {
      configColumns,
      cellDisplayRules,
      fieldConfigs,
      handleActionBtnClick,
      editableFields
    }
  } = props

  if (typeof id === 'undefined' || (typeof field !== 'string' && typeof field !== 'number')) {
    return null
  }
  const allLeafChildren = api.getRowNode(id)?.allLeafChildren

  if (!allLeafChildren) {
    return null
  }

  const [{ data }] = allLeafChildren

  const { customConfigs } = configColumns.find((column) => column.field === field) as TconfigColumn
  // Clone custom configs to change the boolean icon below
  const clonedCustomConfigs = cloneDeep(customConfigs)

  const fieldConfig =
    fieldConfigs.find(({ fieldName }) => fieldName === field) ?? defaultFieldConfig

  const fieldLookupConfig = getFieldLookupConfig(field, editableFields)

  const {
    columnType,
    template,
    boolean,
    editing: { lookupDataField },
    isRawFieldEmpty
  } = clonedCustomConfigs

  const isBool = fieldConfig.dataType === 'bool'
  const isLookup = fieldLookupConfig?.substituteField || lookupDataField

  const isFieldString = typeof field === 'string'
  const isHeaderNameString = typeof headerName === 'string'

  // eslint-disable-next-line no-nested-ternary
  const trimmedField = isRawFieldEmpty ? '' : isFieldString ? field?.trim?.() : field
  const trimmedHeaderName = isHeaderNameString ? headerName?.trim?.() : ''

  const groupColTitle = trimmedHeaderName ?? trimmedField

  if (
    columnType === 'progress' ||
    columnType === 'sparkline' ||
    columnType === 'highchart' ||
    columnType === 'html' ||
    (template && !isLookup && !isBool)
  ) {
    return <GroupColTitleCmp title={groupColTitle} />
  }

  const { cellRendererSelector } = getCellRendererSelector({
    customConfigs: clonedCustomConfigs,
    fieldConfig,
    cellDisplayRules,
    handleActionBtnClick,
    fieldLookupConfig
  })

  const newParams: ICellRendererParams<IRowData, TrowDataValue> & { colDef: SlvyColDef } = {
    ...props,
    data,
    colDef: {
      field,
      customInfo: {
        ...clonedCustomConfigs,
        field,
        fieldConfig,
        fieldLookupConfig,
        deletableCondition: '',
        isExportable: true,
        columnId: getExcelNumberFormatId(`${field}Group`)
      }
    }
  }

  const { component: Component = <></>, params: selectorParams = {} } =
    cellRendererSelector(newParams) ?? {}

  const newProps = { ...newParams, ...selectorParams }

  if (isBool) {
    const { trueIcon, falseIcon } = boolean

    if (!trueIcon || !falseIcon) {
      newProps.colDef.customInfo.boolean = {
        ...newProps.colDef.customInfo.boolean,
        ...defaultBooleanProps
      }

      return (
        <div className="d-flex align-items-center p-1 m-1">
          <GroupColTitleCmp title={groupColTitle} />
          <BooleanIconCellRenderer {...newProps} agGroupClass="d-inline-block px-1" />
        </div>
      )
    }
  }

  // for datetime
  const optionalProps: Partial<{ valueFormatted: TrowDataValue }> = {}
  if (fieldConfig.dataType === 'datetime' && !('valueFormatted' in newProps)) {
    optionalProps.valueFormatted = newProps.value
  }

  if (
    isBool ||
    newProps?.colDef?.customInfo?.actionButton?.actionEnabled ||
    columnType === 'icon' ||
    newProps?.isDefaultCellRenderer
  ) {
    newProps.withText = true
  }

  return (
    <span className="d-flex align-items-center">
      <GroupColTitleCmp title={groupColTitle} />
      <Component {...newProps} {...optionalProps} />
    </span>
  )
}

export default GroupRowInnerRenderer
