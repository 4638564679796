import { useEffect, useMemo, useRef, useCallback } from 'react'
import { transform } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'
import { __RowIndex as SlvyRowIndex } from '@/store/slices/localData'
import { useGetSelectionCellParams } from '../../../index'
import {
  GetRowSelectedProps,
  GetRowSelectedReturnType,
  RowSelectedProps,
  RowSelectedEventRef
} from './useRegisterEventRowSelected.types'

function useRegisterEventRowSelected({
  fieldConfigs,
  registerEvent,
  formattedRowSelectedEventRef
}: RowSelectedProps) {
  const rowSelectedEventRef = useRef<RowSelectedEventRef>({
    handleRowSelected: (_args: never): never => ({} as never)
  })

  const getRowSelected = useCallback(
    ({ rowData, rowIndex }: GetRowSelectedProps) => {
      if (rowData) {
        formattedRowSelectedEventRef?.current?.handleFormattedRowSelected(rowData)
        return transform(
          fieldConfigs,
          (result: GetRowSelectedReturnType, { fieldName }) => {
            // eslint-disable-next-line no-param-reassign
            result[fieldName] = rowData[fieldName]
          },
          {
            rowSelected: true,
            rowDeselected: false,
            refreshKey: uuidv4(),
            [SlvyRowIndex]: rowIndex
          }
        )
      }
      // Deselect case
      return transform(
        fieldConfigs,
        (result: GetRowSelectedReturnType, { fieldName }) => {
          // eslint-disable-next-line no-param-reassign
          result[fieldName] = null
        },
        {
          rowSelected: false,
          rowDeselected: true,
          refreshKey: uuidv4(),
          [SlvyRowIndex]: null
        }
      )
    },
    [fieldConfigs, formattedRowSelectedEventRef]
  )

  const { cellParams } = useGetSelectionCellParams(fieldConfigs)

  const rowSelectedParams = useMemo(() => {
    return {
      ...cellParams,
      rowSelected: PluginTypes.boolean,
      rowDeselected: PluginTypes.boolean,
      refreshKey: PluginTypes.string,
      [SlvyRowIndex]: PluginTypes.int
    }
  }, [cellParams])

  useEffect(() => {
    rowSelectedEventRef.current.handleRowSelected = registerEvent({
      key: 'RowSelected',
      fn: (
        lastSelected: GetRowSelectedProps['rowData'],
        rowIndex: GetRowSelectedProps['rowIndex']
      ) => {
        return getRowSelected({
          rowData: lastSelected,
          rowIndex
        })
      },
      returnTypes: rowSelectedParams
    })
  }, [getRowSelected, formattedRowSelectedEventRef, rowSelectedParams, registerEvent])

  return { rowSelectedEventRef }
}

export default useRegisterEventRowSelected
