Ext.define('clustering.ClusterModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.cluster',
  stores: {},
  data: {
    currentConfigurationData: null,
    checkedMetricCount: 0,
    xDimension: null,
    yDimension: null,
    zDimension: null,
    currentStore: null,
    currentSegment: null,
    currentNode: null,
    dimensions: null,
    numberOfTestStores: 0
  },
  formulas: {
    isAssignTestStoresDisabled: {
      bind: {
        dimensions: '{dimensions}'
      },
      get: function (data) {
        if (!data.dimensions) return true
        return false
      }
    },
    setPercentageSelected: {
      bind: {
        bindTo: '{currentConfigurationData}',
        deep: true
      },
      get: function (data) {
        var currentConfigurationData = data

        if (!currentConfigurationData) return

        var clusteringAlgorithms = currentConfigurationData.ClusteringAlgorithms
        if (!clusteringAlgorithms) {
          clusteringAlgorithms = []
        }

        var checked = currentConfigurationData.IsManual

        this.set(
          'isPercentSelected',
          clusteringAlgorithms[currentConfigurationData.ClusteringAlgorithmIndex] == 'Percentage' &&
            !checked
        )

        if (currentConfigurationData.SplitPercentages) {
          var sumOfPercentages = currentConfigurationData.SplitPercentages.reduce(function (a, b) {
            return parseFloat(a != null ? a : 0) + parseFloat(b != null ? b : 0)
          }, 0)
          sumOfPercentages = Math.round(sumOfPercentages * 10) / 10
          this.set('SumOfPercentages', sumOfPercentages)

          if (sumOfPercentages != 100) {
            this.set('SumOfPercentagesMessage', 'Sum of percentages must be 100%')
          } else {
            this.set('SumOfPercentagesMessage', null)
          }
        }

        for (let index = 0; index < currentConfigurationData.Metrics.length; index++) {
          const metric = currentConfigurationData.Metrics[index]

          this.set(
            'isPercentSelected' + index,
            clusteringAlgorithms[metric.ClusteringAlgorithmIndex] == 'Percentage' && checked
          )

          if (metric.SplitPercentages) {
            var sumOfPercentages = metric.SplitPercentages.reduce(function (a, b) {
              return parseFloat(a != null ? a : 0) + parseFloat(b != null ? b : 0)
            }, 0)
            sumOfPercentages = Math.round(sumOfPercentages * 10) / 10
            this.set('SumOfPercentages' + index, sumOfPercentages)

            if (sumOfPercentages != 100) {
              this.set('SumOfPercentagesMessage' + index, 'Sum of percentages must be 100%')
            } else {
              this.set('SumOfPercentagesMessage' + index, null)
            }
          }
        }
      }
    }
  }
})
