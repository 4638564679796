Ext.define('MDM.src.view.globalapp.Panel.setup.FormDialog', {
  extend: 'Ext.window.Window',
  requires: [
    'Ext.layout.container.Fit',
    'Ext.layout.container.VBox',
    'Ext.layout.container.HBox',
    'Ext.form.Panel',
    'Ext.form.field.Text',
    'Ext.form.field.Date',
    'Ext.form.field.Time',
    'Ext.form.field.Checkbox',
    'Ext.calendar.form.CalendarPicker'
  ],
  layout: 'fit',
  modal: true,
  closable: false,
  defaultListenerScope: true,
  height: 300,
  width: 400,
  title: 'DPI Process Calendar',
  config: {
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     */
    calendarField: {
      xtype: 'calendar-calendar-picker',
      fieldLabel: 'Process Step',
      name: 'calendarId',
      forceSelection: true,
      editable: false,
      queryMode: 'local',
      displayField: 'title',
      valueField: 'id',
      readOnly: true,
      bind: {
        store: '{store}',
        value: '{record.OrderNumber}'
      }
    },

    /**
     * @cfg {Object} titleField
     * The config for the title field.
     */
    titleField: {
      xtype: 'textfield',
      fieldLabel: 'Title',
      name: 'title',
      allowBlank: false,
      hidden: true,
      bind: {
        value: '{record.Title}',
        readOnly: '{readOnlyMode}'
      }
    },

    /**
     * @cfg {Object} fromContainer
     * The config for the from container.
     */
    fromContainer: {
      xtype: 'fieldcontainer',
      fieldLabel: 'Start Date',
      layout: 'hbox'
    },

    /**
     * @cfg {Object} startDateField
     * The config for the start date field.
     */
    startDateField: {
      xtype: 'datefield',
      itemId: 'startDate',
      name: 'startDate',
      allowBlank: false,
      bind: {
        value: '{record.startDate}',
        maxValue: '{record.endDate}',
        readOnly: '{readOnlyMode}'
      }
    },

    /**
     * @cfg {Object} startTimeField
     * The config for the start time field.
     */
    startTimeField: {
      xtype: 'timefield',
      itemId: 'startTime',
      name: 'startTime',
      margin: '0 0 0 5',
      hidden: true,
      bind: {
        value: '{record.startDateTime}',

        readOnly: '{readOnlyMode}'
      }
    },

    /**
     * @cfg {Object} toContainer
     * The config for the to container.
     */
    toContainer: {
      xtype: 'fieldcontainer',
      fieldLabel: 'End Date',
      layout: 'hbox'
    },

    /**
     * @cfg {Object} endDateField
     * The config for the end date field.
     */
    endDateField: {
      xtype: 'datefield',
      itemId: 'endDate',
      name: 'endDate',
      allowBlank: false,
      bind: {
        value: '{record.endDate}',
        minValue: '{record.startDate}',
        readOnly: '{readOnlyMode}'
      }
    },

    /**
     * @cfg {Object} endTimeField
     * The config for the end time field.
     */
    endTimeField: {
      xtype: 'timefield',
      itemId: 'endTime',
      name: 'endTime',
      hidden: true,
      margin: '0 0 0 5',
      bind: {
        value: '{record.endDateTime}',
        readOnly: '{readOnlyMode}'
      }
    },

    /**
     * @cfg {Object} allDayField
     * The config for the all day field.
     */
    allDayField: {
      xtype: 'checkbox',
      itemId: 'allDay',
      name: 'allDay',
      boxLabel: 'All Day',
      readOnly: true,
      checked: true,
      hidden: true,
      hideEmptyLabel: false,
      handler: 'onAllDayChange'
    },

    /**
     * @cfg {Object} descriptionField
     * The config for the description field.
     */
    descriptionField: {
      xtype: 'textarea',
      fieldLabel: 'Description',
      name: 'description',
      flex: 1,
      bind: {
        value: '{record.description}',
        readOnly: '{readOnlyMode}'
      }
    },

    /**
     * @cfg {Object} dropButton
     * The config for the drop button. `null` to not show this button.
     */
    dropButton: {
      text: 'Delete',
      handler: 'onDropTap'
    },

    /**
     * @cfg {Object} saveButton
     * The config for the save button.
     */
    saveButton: {
      text: 'Save',
      handler: 'onSaveTap',
      bind: {
        hidden: '{readOnlyMode}'
      }
    },

    /**
     * @cfg {Object} cancelButton
     * The config for the cancel button.
     */
    cancelButton: {
      text: 'Close',
      handler: 'onCancelTap'
    }
  },

  initForm: function () {
    this.createItems()
  },
  initComponent: function () {
    var me = this

    me.initForm()
    me.fbar = me.generateButtons()

    me.callParent()
    me.form = me.items.first()
    me.checkFields()
    me.applyValues()
  },
  generateButtons: function () {
    var buttons = []
    buttons.push(
      {
        xtype: 'component',
        flex: 1
      },
      this.getSaveButton(),
      this.getCancelButton()
    )

    return buttons
  },

  applyValues: function () {},

  createItems: function () {
    var me = this,
      calField = me.getCalendarField(),
      fromCt = me.getFromContainer(),
      toCt = me.getToContainer()

    if (!calField.store) {
    }

    if (!fromCt.items) {
      fromCt.items = [me.getStartDateField(), me.getStartTimeField()]
    }

    if (!toCt.items) {
      toCt.items = [me.getEndDateField(), me.getEndTimeField()]
    }

    this.items = [
      {
        xtype: 'form',
        border: false,
        trackResetOnLoad: true,
        layout: {
          type: 'vbox',
          align: 'stretch'
        },
        bodyPadding: 10,
        items: [
          calField,
          me.getTitleField(),
          fromCt,
          toCt,
          me.getAllDayField(),
          me.getDescriptionField()
        ]
      }
    ]
  },

  privates: {
    checkFields: function () {
      var checked = this.down('#allDay').checked
      this.down('#startTime').setDisabled(checked)
      this.down('#endTime').setDisabled(checked)
    },

    onAllDayChange: function () {
      this.checkFields()
    },

    onCancelTap: function () {
      this.close()
    },

    onDropTap: function () {
      this.fireDrop()
    },

    onSaveTap: function () {
      this.fireEvent('save', this)
      this.close()
    }
  }
})
