import { defaultColor } from './constants'
import { Color, Value } from './SlvyColorPicker.types'

export const getInitialColor = (value: Value): Color => {
  const isValueUndefined = typeof value === 'undefined'
  if (isValueUndefined) {
    return defaultColor
  }

  const isValueString = typeof value === 'string'
  const isValueObject = typeof value === 'object' && value !== null

  if (isValueString) {
    return { bg: value, rgb: null }
  }

  if (isValueObject) {
    return { bg: null, rgb: value }
  }

  return defaultColor
}

export const getRgbAsString = (rgb: Color['rgb']) => {
  if (!rgb) {
    return defaultColor.bg
  }
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
}
