import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

import highchartsMore from 'highcharts/highcharts-more'
import highchartsSanKey from 'highcharts/modules/sankey'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsNodataDisplay from 'highcharts/modules/no-data-to-display'

highchartsMore(Highcharts)
highchartsSanKey(Highcharts)
highchartsExporting(Highcharts)
highchartsNodataDisplay(Highcharts)

class Sankey extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      subTitle: null
    }

    this.dataLabelsFormatter = this.dataLabelsFormatter.bind(this)
    this.linksHover = this.linksHover.bind(this)
    this.onPointClick = this.onPointClick.bind(this)
    this.tooltipFormatter = this.tooltipFormatter.bind(this)
  }

  dataLabelsFormatter(point, pluginConfig) {
    if (point && point.point) {
      const { point: { name = null, isNode = false, linksFrom = [] } = {}, key: pointKey } = point
      const {
        data: { dataFrom } = {},
        plotOptions: { sankey: { dataLabels: { enabledBasedOn } = {} } = {} } = {}
      } = pluginConfig

      // There are nodes and paths as point.
      if (isNode) {
        // We are checking only 'from' nodes for disable data labels.
        if (_.size(linksFrom) > 0 && enabledBasedOn) {
          if (dataFrom && pointKey) {
            const { props: { pluginData = [] } = {} } = this
            const showDataLabel = _.find(pluginData, (item) => {
              return item[dataFrom] === pointKey && item[enabledBasedOn]
            })
            return showDataLabel ? name || '' : null
          }
          return null
        }
        return name || ''
      }
      return null
    }
    return null
  }

  handlePointClick(value) {
    return value
  }

  linksHover(point, state) {
    if (point.isNode) {
      point.linksFrom.forEach(function (l) {
        l.setState(state)
      })
    }
  }

  onPointClick(event) {
    const { props: { settings: { config: pluginConfig = {} } = {} } = {} } = this
    const { point: { isNode = false, row: pointRow = {}, linksFrom = [], id } = {} } = event
    const { data: { dataFrom } = {} } = pluginConfig

    if (isNode) {
      if (_.size(linksFrom) > 0) {
        if (dataFrom && id) {
          const { props: { pluginData = [] } = {} } = this
          const nodeFirstRow = _.find(pluginData, (item) => {
            return item[dataFrom] === id
          })
          return nodeFirstRow
            ? this.handlePointClick({ nodeNameByChart: id, ...nodeFirstRow })
            : this.handlePointClick({ nodeNameByChart: id })
        }
        return false
      }
      return this.handlePointClick({ nodeNameByChart: id })
    }
    this.handlePointClick(pointRow)
  }

  removeEmptyConfig(config) {
    for (const prop in config) {
      if (!config.hasOwnProperty(prop)) continue

      if (config[prop] === '') {
        delete config[prop]
      } else if (typeof config[prop] === 'object') {
        this.removeEmptyConfig(config[prop])
      }
    }
  }

  tooltipFormatter(point) {
    const { props: { settings: { config: chartConfig = {} } = {}, getFormattedValue } = {} } = this
    const { data: { weight: weightDataField } = {} } = chartConfig
    const xFieldName = weightDataField
    let tooltip = `<span style="font-size: 120%">''</span><br/>`
    const { point: { sum, name, color, from, to, weight } = {} } = point
    let formattedWeight = weight
    if (xFieldName) {
      formattedWeight = getFormattedValue(xFieldName, weight)
    }
    if (_.isNil(point.key)) {
      tooltip = `<span style="font-weight:bold;color:${color}">${from} to ${to}, ${formattedWeight}</span><br/>`
    } else {
      tooltip = `<span style="font-weight:bold;font-size: 120%;color:${color}">${name}:${sum}</span><br/>`
    }

    return tooltip
  }

  componentDidMount() {
    this.handlePointClick = this.props.registerEvent({
      key: 'handlePointClick',
      fn: this.handlePointClick.bind(this),
      returnTypes: _.transform(
        this.props.settings.query.fields,
        (result, field = {}) => {
          const { dataType = '' } = field
          result[field.fieldName] = PluginTypes.fromString(dataType)
        },
        {}
      )
    })
  }

  render() {
    const {
      props: {
        pluginData = [],
        settings: {
          config: pluginConfig = {},
          config: {
            data: { name = '', dataFrom, dataTo, weight } = {},
            chart: { emptyText = 'No Data to Display', hoverOpacity = true } = {}
          } = {}
        } = {}
      } = {}
    } = this || {}
    const config = _.cloneDeep(pluginConfig)
    const defaults = {
      credits: {
        enabled: false,
        text: `Solvoyo © ${new Date().getFullYear()}`,
        href: '#',
        style: { fontSize: '12px' }
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      }
    }
    config.credits = defaults.credits
    const {
      chart: { showCredits = false } = {},
      exporting: { enabled: exportingEnabled = false } = {}
    } = pluginConfig
    config.credits.enabled = showCredits
    // display no data
    config.noData = defaults.noData
    config.lang = {
      noData: emptyText || ' '
    }
    // move export button to the top-left corner
    if (!config.exporting) {
      config.exporting = {}
    }
    config.exporting.enabled = exportingEnabled
    config.exporting.buttons = {
      contextButton: {
        align: 'left'
      }
    }

    if (!config.tooltip) {
      config.tooltip = {}
    }

    if (!hoverOpacity) _.set(config, 'plotOptions.sankey.states.inactive.enabled', false)

    if (_.isEmpty(config.plotOptions?.sankey?.dataLabels?.textPath)) {
      _.set(config, 'plotOptions.sankey.dataLabels.textPath', undefined)
    }

    const that = this
    config.tooltip.formatter = function () {
      return that.tooltipFormatter(this)
    }
    config.tooltip.followPointer = true
    config.tooltip.crosshairs = true

    config.series = [
      {
        type: 'sankey',
        keys: ['from', 'to', 'weight'],
        data: [],
        name,
        events: {}
      }
    ]

    _.forEach(pluginData, (row) => {
      if (weight && _.has(row, weight)) {
        if (row[dataFrom] && row[dataTo] && row[weight] > 0) {
          config.series[0].data.push({
            from: row[dataFrom],
            to: row[dataTo],
            weight: row[weight],
            row: { ...row }
          })
        }
      }
    })

    if (_.size(config.series[0].data) > 0) {
      config.noData = {
        style: {
          fontSize: '0px',
          color: 'rgba(255, 255, 255, 0)'
        }
      }
      config.lang = {
        noData: ''
      }
    }

    const pointClick = {
      events: {
        click: this.onPointClick
      }
    }

    config.series[0].point = pointClick

    if (!config.series[0].events) {
      config.series[0].events = {}
    }
    if (!config.plotOptions.sankey.point) {
      config.plotOptions.sankey.point = {}
    }
    if (!config.plotOptions.sankey.point.events) {
      config.plotOptions.sankey.point.events = {}
    }
    config.plotOptions.sankey.point.events.mouseOver = function () {
      that.linksHover(this, 'hover')
    }
    config.plotOptions.sankey.point.events.mouseOut = function () {
      that.linksHover(this, '')
    }

    if (config.plotOptions && config.plotOptions.sankey && config.plotOptions.sankey.dataLabels) {
      config.plotOptions.sankey.dataLabels.formatter = function () {
        return that.dataLabelsFormatter(this, config)
      }
    }

    // Delete empty ('') properties
    this.removeEmptyConfig(config)

    return (
      <div className="highcharts-container -sankey">
        <HighchartsReact highcharts={Highcharts} options={config} />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  settings: props.settings,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(Sankey, selectConnectorProps)
