import { useMemo } from 'react'
import { DefaultColDefReturnType } from './useDefaultColDef.types'

function useDefaultColDef() {
  const defaultColDef = useMemo((): DefaultColDefReturnType => {
    const defs: DefaultColDefReturnType = {}

    return defs
  }, [])

  return { defaultColDef }
}

export default useDefaultColDef
