import React, { forwardRef } from 'react'
import cx from 'classnames'
import { IGoToMainButtonProps } from './GoToMainButton.types'

const GoToMainButton = forwardRef(
  (
    { pluginId = '', notAllowed = false, reloadView, onViewChangedCallback }: IGoToMainButtonProps,
    ref: any
  ) => {
    const handleOnClick = () => {
      reloadView(() => {
        const $container = document.querySelector('.dp-main') as HTMLDivElement
        const dpStorage = JSON.parse(localStorage.getItem(`dp-Style-${pluginId}`) || '{}')
        $container.scrollTop = dpStorage?.scrollTop ?? 0
      })
      onViewChangedCallback({ View: 'PivotView' })
    }

    return (
      <i
        key="back-btn"
        ref={ref}
        className={cx('btn-close-pivot', 'slvy-ui-icon-arrow-left-extra-lt', {
          '-disabled': notAllowed
        })}
        onClick={notAllowed ? () => {} : handleOnClick}
      />
    )
  }
)

export default GoToMainButton
