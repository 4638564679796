Ext.define('MDM.src.view.globalapp.Panel.Dpi.model.DpiSegment', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Global_MDM_DPISegment',
  idProperty: 'ID',
  fields: [
    {
      name: 'DPISegmentID',
      type: 'int'
    },
    {
      name: 'DPISegment',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'ProductGroupCount',
      type: 'int'
    },
    {
      name: 'DPIFamilyCount',
      type: 'int'
    },
    {
      name: 'isEditable',
      type: 'boolean'
    }
  ]
})
