import { useCallback, useEffect, useMemo } from 'react'
import { objectKeys } from '@/ts/utils'
import { PluginTypes } from '@/BasePlugin'
import { getRegisterMethodFieldConfigsParams } from '../../../../helpers'
import { IRowDataGrid, ExtendedFilterModel, SlvyColDef } from '../../../../types'
import { FilterClientDataProps } from './useRegisterMethodFilterClientData.type'

function useRegisterMethodFilterClientData({
  fieldConfigs,
  gridRef,
  registerMethod
}: FilterClientDataProps) {
  const onFilterClientData = useCallback(
    async (params: IRowDataGrid) => {
      const gridApi = gridRef?.current?.api
      gridApi?.setFilterModel(null)
      const { clearFilter = 0, ...restFilterParams } = params
      if (clearFilter) {
        return
      }
      const keysOfRestFilterParams = objectKeys(restFilterParams)
      const filterModels: ExtendedFilterModel = {}
      const filterableColumns: Array<string | number | null> = []
      let counter = 0

      // eslint-disable-next-line no-restricted-syntax
      for await (const key of keysOfRestFilterParams) {
        // eslint-disable-next-line no-loop-func
        gridApi?.getColumnFilterInstance(key as string).then(() => {
          filterableColumns.push(key)
          counter += 1
        })
      }
      if (counter === filterableColumns.length) {
        const rootPromise: Promise<ExtendedFilterModel> = new Promise((resolve) => {
          keysOfRestFilterParams.forEach((key) => {
            const currentColDef = gridApi?.getColumnDef(key as string) as SlvyColDef
            const substituteField = currentColDef?.customInfo?.fieldLookupConfig?.substituteField

            const filterValue = restFilterParams[key]

            if (substituteField) {
              // eslint-disable-next-line no-param-reassign
              key = `_SLVY_${key}_SUBSTITUTE_FIELD_`
            }

            const filterInstance = gridApi?.getColumnFilterInstance(key as string)
            filterInstance
              ?.then((resolvedFilterInstance) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const filterType = resolvedFilterInstance?.getFilterType()

                const filterTypes = ['number', 'text', 'date', 'set']
                let filterModel: Partial<ExtendedFilterModel[string]> = {}

                if (filterTypes.includes(filterType)) {
                  filterModel.kind = filterType
                }

                if (filterModel.kind === 'number') {
                  const val = typeof filterValue === 'number' ? filterValue : null
                  filterModel = {
                    ...filterModel,
                    filter: val,
                    type: 'equals',
                    filterType: 'number'
                  }
                } else if (filterModel.kind === 'text') {
                  const val = typeof filterValue === 'string' ? filterValue : null
                  filterModel = {
                    ...filterModel,
                    filter: val,
                    type: 'contains',
                    filterType: 'text'
                  }
                } else if (filterModel.kind === 'date') {
                  const val = typeof filterValue === 'string' ? filterValue : null
                  filterModel = {
                    ...filterModel,
                    dateFrom: val,
                    dateTo: null,
                    type: 'equals',
                    filterType: 'date'
                  }
                } else if (filterModel.kind === 'set') {
                  // set filter: list filter, tag-filter, boolean filter
                  const val =
                    typeof filterValue === 'string' ||
                    typeof filterValue === 'number' ||
                    typeof filterValue === 'boolean'
                      ? filterValue.toString()
                      : null

                  filterModel = {
                    ...filterModel,
                    values: [val],
                    filterType: 'set'
                  }
                }

                filterModels[key] = filterModel as ExtendedFilterModel[string]
              })
              .finally(() => {
                if (
                  filterableColumns.length &&
                  filterableColumns.length === objectKeys(filterModels).length
                ) {
                  resolve(filterModels)
                }
              })
          })
        })

        rootPromise.then((resolvedFilterModels) => {
          if (objectKeys(resolvedFilterModels).length) {
            const newResolvedFilterModels: ExtendedFilterModel = {}
            // Clean empty filters from resolved filter models like below
            // { "Monthly Premium Auto": {} }
            // https://dragon.solvoyo.com/Configuration/catalog/60af598743b5790a546af522/store/0/menu/60af5d5443b579049021675e/page/66c5b94de05e3194e7cc557d
            objectKeys(resolvedFilterModels).forEach((key) => {
              if (objectKeys(resolvedFilterModels[key]).length > 0) {
                newResolvedFilterModels[key] = resolvedFilterModels[key]
              }
            })
            gridApi?.setFilterModel(newResolvedFilterModels)
          }
        })
      }
    },
    [gridRef]
  )

  const filterClientDataParams = useMemo(() => {
    return [
      ...getRegisterMethodFieldConfigsParams(fieldConfigs),
      {
        name: 'clearFilter',
        type: PluginTypes.int
      }
    ]
  }, [fieldConfigs])

  useEffect(() => {
    registerMethod({
      args: filterClientDataParams,
      key: 'filterClientData',
      fn: onFilterClientData
    })
  }, [filterClientDataParams, onFilterClientData, registerMethod])
}

export default useRegisterMethodFilterClientData
