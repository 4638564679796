import { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import BaseModal from '../../BaseModal'
import { SlvyTable } from '@/components'
import { headerSlice } from '../../../store/slices'
import { useReduxState } from '../../../hooks'
import {
  getSnapshotCreate,
  getSnapshot,
  getServiceStatus,
  getSnapshotDestroy
} from '../../../store/api/endpoints'
import IconButton from '../../IconButton'
import styles from './SnapshotModal.module.scss'

function SnapshotModal() {
  const snapshotResponse = getSnapshot.useQueryState()

  const [snapshotData, setSnapshotData] = useState(snapshotResponse.data)
  const [{ snapshotModal }, { setSnapshotModal }] = useReduxState(
    headerSlice.selectSlice,
    headerSlice.actions
  )

  const [triggerGetSnapshotCreate] = getSnapshotCreate.useLazyQuery()
  const [triggerGetSnapshotDestroy] = getSnapshotDestroy.useLazyQuery()
  const serviceStatus = getServiceStatus.useQueryState()

  useEffect(() => {
    setSnapshotData(snapshotResponse.data)
  }, [snapshotResponse.data])

  const handleRemoveSnaphot = useCallback(
    (snapshot) => {
      triggerGetSnapshotDestroy(snapshot)

      setSnapshotData((prev) => prev?.filter((current) => current.Id !== snapshot.Id))
    },
    [triggerGetSnapshotDestroy]
  )

  const diffCell = useCallback(
    ({ row }) => {
      return (
        <IconButton tooltipText="Delete Plant" onClick={() => handleRemoveSnaphot(row.original)}>
          <i className="fa fa-minus-circle fs-6 text-danger" />
        </IconButton>
      )
    },
    [handleRemoveSnaphot]
  )

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'Name'
      },
      {
        header: 'Description',
        accessorKey: 'Description'
      },
      {
        header: 'Created By',
        accessorKey: 'CreatedBy'
      },
      {
        header: 'Created At',
        accessorKey: 'CreatedAt',
        meta: {
          format: 'YYYY-MM-DD h:mm:ss',
          dataType: 'datetime'
        }
      },
      {
        header: '',
        id: 'actioncolumn',
        cell: diffCell,
        maxSize: 42
      }
    ],
    [diffCell]
  )

  function handleSnapshotModalCloseButton() {
    setSnapshotModal(false)
  }

  function handleCreateSnapshot() {
    const currentScenarioId = serviceStatus.data?.PlanInfo.CurrentScenarioID
    const currentRunInfoId = serviceStatus.data?.PlanInfo.CurrentRunInfoID

    const userName = serviceStatus.data?.Session.Username
    const currentId = `tuiv2/SnapShot-${snapshotData?.length + 1}`
    const currentDescription = `Scenario : ${currentScenarioId} - RunInfo : ${currentRunInfoId} - Date : ${moment(
      new Date()
    ).format('Y/m/d H:i:s')}`
    const currentName = `${currentScenarioId}-${currentRunInfoId}-${moment(new Date()).format(
      'Y/m/d H:i:s'
    )}`

    const newSnapshot = {
      CreatedAt: new Date(),
      CreatedBy: userName,
      Description: currentDescription,
      Id: currentId,
      Name: currentName
    }
    triggerGetSnapshotCreate(newSnapshot)
    setSnapshotData((prev) => [...prev, newSnapshot])
  }

  return (
    <BaseModal isOpen={snapshotModal} size="lg" onClose={handleSnapshotModalCloseButton}>
      <BaseModal.Header>
        <BaseModal.Title>Snapshot</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className={cx(styles.snapshotModal, 'fs-sm')}>
        <Button size="sm" variant="secondary" onClick={handleCreateSnapshot}>
          Create Snapshot
        </Button>
        <SlvyTable columns={columns} data={snapshotData} theme="sencha-grid" />
      </BaseModal.Body>
    </BaseModal>
  )
}

export default SnapshotModal
