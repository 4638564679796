import { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import Operations from '../Operations'

export default class ArithmeticOperator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first: {},
      second: {},
      ...props.value
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.onChange && nextProps.value !== this.state) {
      this.setState(nextProps.value)
    }
  }

  handleChange(index) {
    return (value) => {
      this.setState({ [index]: value })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      this.props.onChange &&
      (nextState.first !== this.state.first || nextState.second !== this.state.second)
    ) {
      this.props.onChange(nextState)
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col className="text-end" md={2}>
            First:
          </Col>
          <Col md={10}>
            <Operations
              columns={this.props.columns}
              dataTypes={this.props.dataTypes}
              value={this.state.first}
              onChange={this.handleChange('first')}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-end" md={2}>
            Second:
          </Col>
          <Col md={10}>
            <Operations
              columns={this.props.columns}
              dataTypes={this.props.dataTypes}
              value={this.state.second}
              onChange={this.handleChange('second')}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
