import { Component } from 'react'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import { connect } from 'react-redux'
import { slvyToast } from '../../../components'
import { setLoader as setActionLoader, saveMfpSettings } from '../store/actions'
import {
  setLoader,
  makeRequest,
  _getBaseNodeActionParameters,
  displayToastrMessages
} from '../utils'
import { MESSAGES } from '../messages'

class NodeActions extends Component {
  ApproveMfpNode({ ExecutionId, DataConnectionId }) {
    const { cellApproved = () => {}, mfpSettings: { mfpId = null } = {} } = this.props
    cellApproved({
      ExecutionId,
      DataConnectionId,
      MfpId: mfpId
    })
  }

  toggleLoader(isVisible = false, msg = MESSAGES.loading) {
    setLoader(this.props.setActionLoader, isVisible, msg)
  }

  alertConfirm(params, $callback = () => {}) {
    const { endPoint, name, description, cancelButton, confirmButton } = params

    confirmAlert({
      title: name,
      message: _.isEmpty(description)
        ? `Are you sure you want to ${name.toLowerCase()}?`
        : description,
      buttons: [
        {
          label: _.isEmpty(cancelButton) ? MESSAGES.cancel : cancelButton
        },
        {
          label: _.isEmpty(confirmButton) ? MESSAGES.confirm : confirmButton,
          onClick: () => {
            if (endPoint === 'LockMfpNode' || endPoint === 'UnlockMfpNode') {
              // add TopKey parameters to the HasPermanentLock request, if the request does not come from the main pivot screen
              if ('fieldName' in params) {
                params.TopKey = params.fieldName
              }
              const payload = this.getBaseNodeActionParameters(params, 'HasPermanentLock')

              this.toggleLoader(true, MESSAGES.loading)
              makeRequest({ payload }).then((response) => {
                const { data: { Result = false, Errors = [] } = {} } = response

                if (Errors.length) {
                  slvyToast.info({ message: Errors[0], options: { containerId: 'dp' } })
                  this.toggleLoader()
                } else if (!Result) {
                  $callback()
                } else {
                  slvyToast.info({
                    message: MESSAGES.permanent_lock_message,
                    options: { containerId: 'dp' }
                  })
                  this.toggleLoader()
                }
              })
            } else {
              $callback()
            }
          }
        }
      ]
    })
  }

  getBaseNodeActionParameters(params, method) {
    const body = this.props.getBaseRequestParameters()
    return _getBaseNodeActionParameters(this.props, body, params, method)
  }

  pivotNodeActionCallback(LeftKeys, params) {
    this.alertConfirm(
      params,
      function onConfirm() {
        this.executeNodeUpdate({ LeftKeys }, params)
      }.bind(this)
    )
  }

  pivotDetailNodeActionCallback(LeftKeys, params) {
    this.alertConfirm(
      params,
      function onConfirm() {
        this.executeNodeUpdate({ LeftKeys, TopKey: params.fieldName }, params)
      }.bind(this)
    )
  }

  pivotSingleDetailNodeActionCallback(LeftKeys, params) {
    this.alertConfirm(
      params,
      function onConfirm() {
        this.executeNodeUpdate({ LeftKeys, TopKey: params.fieldName }, params)
      }.bind(this)
    )
  }

  scenarioComparisonNodeActionCallback(LeftKeys, params, callback) {
    this.alertConfirm(
      params,
      function onConfirm() {
        const { MfpScenarioId = '', BaselineScenarioId = '' } = params
        this.executeNodeUpdate(
          {
            LeftKeys,
            TopKey: params.fieldName,
            ...(MfpScenarioId !== '' ? { MfpScenarioId: params.MfpScenarioId } : {}),
            ...(BaselineScenarioId !== '' ? { BaselineScenarioId: params.BaselineScenarioId } : {})
          },
          params,
          callback
        )
      }.bind(this)
    )
  }

  // execute methods calling as dynamic
  executeNodeUpdate(requestParams, params, fn) {
    const { refresh = () => {} } = this.props
    const { endPoint, SuccessMessage = '' } = params
    this[`execute${endPoint}`](requestParams, params).then(({ data: response = {} }) => {
      const { Errors = [], Result = {} } = response || {}
      this.props.setEditable(response, false)

      if (!Errors.length) {
        displayToastrMessages(endPoint, SuccessMessage)

        // event dispatch
        if (typeof this[endPoint] === 'function') {
          this[endPoint](Result)
        }

        if (typeof refresh === 'function') {
          if (endPoint === 'ApproveMfpNode') {
            this.toggleLoader()
          } else {
            refresh().then(() => this.toggleLoader())
          }
        }

        if (typeof fn === 'function') {
          fn(response)
        }
      } else {
        slvyToast.warning({ message: Errors[0], options: { containerId: 'dp' } })
        this.toggleLoader()
      }
    })
  }

  executeExecuteSpAction(req, params) {
    const payload = this.getBaseNodeActionParameters(
      _.omit(params, ['endPoint', 'name']),
      'ExecuteSpAction'
    )

    payload.body = {
      ...payload.body,
      ...req
    }

    this.toggleLoader(true, MESSAGES.loading)
    return makeRequest({ payload })
  }

  executeCopyHierarchy(req, params) {
    const { PlannedId, TargetId } = params
    const { stateHandler: { isReverse = false } = {} } = this.props

    const newParams = {}
    if (PlannedId && TargetId) {
      newParams.BaselineScenarioId = isReverse ? PlannedId : TargetId
      newParams.MfpScenarioId = isReverse ? TargetId : PlannedId
    }

    const payload = this.getBaseNodeActionParameters(
      _.omit(params, ['endPoint', 'name']),
      'CopyHierarchy'
    )
    payload.body = {
      ...payload.body,
      ...newParams,
      ...req
    }
    this.toggleLoader(true, MESSAGES.loading)
    return makeRequest({ payload })
  }

  executeApproveMfpNode(params) {
    const MoveToApprovedScenario = _.get(
      this.props,
      'settings.config.configuration.moveToApprovedScenario',
      false
    )
    const RccpServiceAddress = _.get(this.props, 'settings.config.rccp.serviceAddress', '')
    const RccpServiceIntegration = _.get(
      this.props,
      'settings.config.rccp.serviceIntegration',
      false
    )
    const RccpServiceRunInfoId = _.get(this.props, 'settings.config.rccp.runInfoID', 0)
    const RccpServiceScenarioId = _.get(this.props, 'settings.config.rccp.scenarioID', 0)

    const payload = this.getBaseNodeActionParameters(params, 'ApproveMfpNode')
    payload.body.MoveToApprovedScenario = MoveToApprovedScenario
    payload.body.RccpServiceAddress = RccpServiceAddress.trim()
    payload.body.RccpServiceIntegration = RccpServiceIntegration
    payload.body.RccpServiceRunInfoId = RccpServiceRunInfoId
    payload.body.RccpServiceScenarioId = RccpServiceScenarioId

    this.toggleLoader(true, MESSAGES.loading)
    return makeRequest({ payload })
  }

  executeLockMfpNode(params) {
    const payload = this.getBaseNodeActionParameters(params, 'LockMfpNode')
    this.toggleLoader(true, MESSAGES.loading)
    return makeRequest({ payload })
  }

  executeUnlockMfpNode(params) {
    const payload = this.getBaseNodeActionParameters(params, 'UnlockMfpNode')
    this.toggleLoader(true, MESSAGES.loading)
    return makeRequest({ payload })
  }

  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return this.props.children({
      pivotNodeActionCallback: this.pivotNodeActionCallback.bind(this),
      pivotDetailNodeActionCallback: this.pivotDetailNodeActionCallback.bind(this),
      pivotSingleDetailNodeActionCallback: this.pivotSingleDetailNodeActionCallback.bind(this),
      scenarioComparisonNodeActionCallback: this.scenarioComparisonNodeActionCallback.bind(this)
    })
  }
}

const mapStateToProps = (state) => {
  return {
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    mfpSettings: state.mfpSettings.mfpSettings
  }
}

const mapDispatchToProps = {
  setActionLoader,
  saveMfpSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeActions)
