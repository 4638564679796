export default `({
    columnLines: true,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            bind: {
                html: ' <b>{spotRecord.MaterialDescription} ' + tt('Inventory List')+'</b>'
            }
        }]
    }],
    header: false,
    flex: 1,
    columns: [{
            xtype: 'actioncolumn',
            width: 30,
            locked: true,
            items: [{
                iconCls: 'x-fa fa-dot-circle-o',
                tooltip: 'Show Vendor Analysis',
                align: 'center',
                handler: function (grid, rowIndex, colIndex) {
                    var win = Ext.create('Ext.window.Window', {
                        height: 500,
                        renderTo: Ext.getBody(),
                        width: 800,
                        autoShow: true,
                        modal: true,
                        layout: 'fit',
                        items: {
                            xtype: 'uxcontainer',
                            uxItemCfg: 'deneme',
                        }
                    })
                    // var rec = grid.getStore().getAt(rowIndex);
                    // grid.fireEvent('clickforDetail', rec);
                    // Ext.create('Spui.view.drilldown.Vendor.Main').show();
                }
            }]
        },
        // {
        //     xtype: 'actioncolumn',
        //     width: 30,
        //     locked: true,
        //     items: [{
        //         iconCls: 'x-fa fa-dot-circle-o',
        //         tooltip: 'Show Vendor Analysis',
        //         align: 'center',
        //         handler: function (grid, rowIndex, colIndex) {
        //             var win = Ext.create('Ext.window.Window', {
        //                 height: 500,
        //                 renderTo: Ext.getBody(),
        //                 width: 800,
        //                 autoShow: true,
        //                 title: 'test',
        //                 modal: true,
        //                 layout: 'fit',
        //                 items: {
        //                     xtype: 'uxcontainer',
        //                     uxItemCfg: 'deneme2',
        //                 }
        //             })
        //             // var rec = grid.getStore().getAt(rowIndex);
        //             // grid.fireEvent('clickforDetail', rec);
        //             // Ext.create('Spui.view.drilldown.Vendor.Main').show();
        //         }
        //     }]
        // },
        {
            text: 'DcCode',
            dataIndex: 'DcCode',
            width: 90,
            locked: true
        },
        {
            text: 'DcName',
            dataIndex: 'DcName',
            width: 150,
            locked: true
        },
        {
            text: 'Store',
            columns: [{
                    text: 'AcvtiveStore',
                    dataIndex: 'NumberOfOpenStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
                {
                    text: 'HasInventory',
                    dataIndex: 'NumberofStoreHasInventory',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
                {
                    text: 'TotaInventory',
                    dataIndex: 'TotalStoreInventory',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                }
            ]
        },
        {
            text: 'DcInventory',
            dataIndex: 'DcInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: 'TotalInventory',
            dataIndex: 'TotalInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        }
    ],
})
`
