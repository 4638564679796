import * as actionTypes from './actionTypes'

export const savePivotMainConfiguration = (options) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storePivotMainConfiguration(options))
      resolve()
    })
  }
}

const storePivotMainConfiguration = (options) => {
  return {
    type: actionTypes.STORE_PIVOT_MAIN_CONFIGURATION,
    payload: options.payload
  }
}
