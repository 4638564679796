import { Component } from 'react'

export default class ExtRoot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rendered: false
    }
  }

  componentDidMount() {
    window.addEventListener('reloadExtRoot', this.reloadExtRoot)
    window.addEventListener('hideExtRoot', this.hideExtRoot)
  }

  componentWillUnmount() {
    window.removeEventListener('reloadExtRoot', this.reloadExtRoot)
    window.removeEventListener('hideExtRoot', this.hideExtRoot)
  }

  hideExtRoot = (obj) => {
    const { pluginId } = this.props
    if (obj.detail.pluginId === pluginId) {
      this.setState({ rendered: false }, () => obj.detail.callback())
    }
  }

  reloadExtRoot = (obj) => {
    const { pluginId } = this.props
    if (obj.detail.pluginId === pluginId) {
      this.setState({ rendered: true }, () => obj.detail.callback())
    }
  }

  render() {
    const {
      state: { rendered },
      props: { children }
    } = this
    return rendered ? children : null
  }
}
