import { Nav, Tab } from 'react-bootstrap'
import { getModifiedItem } from '../helpers'
import ContainersMethod from './ContainersMethod'
import PluginsMethod from './PluginsMethod'
import { IRunMethodProps } from './RunMethod.types'
import { IContainerMethodKey, IMethodKey } from '../Jobs.types'

const RunMethod = (props: IRunMethodProps) => {
  const {
    onSave,
    params: { methodKeys = [], containerMethodKeys = [] } = {},
    params,
    plugins,
    containers
  } = props

  const handleAddItem = (listName: string, obj: IMethodKey | IContainerMethodKey) =>
    onSave(getModifiedItem(params, listName, obj))

  return (
    <div className="p-3" data-testid="run-method">
      <Tab.Container defaultActiveKey="plugins">
        <>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="plugins" role="button">
                Plugins
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="containers" role="button">
                Containers
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="border-0 pt-3">
            <Tab.Pane mountOnEnter eventKey="plugins" transition={false}>
              <PluginsMethod
                handleAddItem={handleAddItem}
                methodKeys={methodKeys}
                plugins={plugins}
              />
            </Tab.Pane>
            <Tab.Pane mountOnEnter eventKey="containers" transition={false}>
              <ContainersMethod
                containerMethodKeys={containerMethodKeys}
                containers={containers}
                handleAddItem={handleAddItem}
              />
            </Tab.Pane>
          </Tab.Content>
        </>
      </Tab.Container>
    </div>
  )
}

export default RunMethod
