import { STORE_SCENARIO_TREE, STORE_SCENARIO_TREE_FAILED } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetScenarioTree: {},
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SCENARIO_TREE:
      return updateObject(state, { GetScenarioTree: { ...action.payload }, error: {} })
    case STORE_SCENARIO_TREE_FAILED:
      return updateObject(state, {
        GetScenarioTree: {},
        error: { ...action.payload }
      })
    default:
      return state
  }
}

export default reducer
