import { Component, ErrorInfo } from 'react'
import Bowser from 'bowser'
import { get } from 'lodash'
import { IErrorBoundaryProps, IErrorBoundaryState } from './ErrorBoundary.types'
import Warning from './Warning/index'
import ApiClient from '@/crudoptV3/libs/apiClient'

const client = new ApiClient()
const clientUrl = '/log'

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  // eslint-disable-next-line react/state-in-constructor
  public state: IErrorBoundaryState = {
    hasError: false,
    showAlert: false
  }

  public static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true, showAlert: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { errorContext } = this.props
    const browser = Bowser.getParser(window.navigator.userAgent)
    const parsedResult = get(browser, 'parsedResult', {})

    const logRequest = {
      data: {
        Level: 'Information',
        Message: 'Dragon-UI-Error-Log',
        Fields: {
          Error: JSON.stringify(error.stack),
          ErrorInfo: errorInfo.toString(),
          ErrorContext: JSON.stringify(errorContext),
          Browser: parsedResult?.browser?.name || '-',
          BrowserVersion: parsedResult?.browser?.version || '-',
          BrowserEngine: parsedResult?.engine?.name || '-',
          OS: parsedResult?.os?.name || '-',
          OSVersion: parsedResult?.os?.version || '-',
          OSVersionName: parsedResult?.os?.versionName || '-',
          Platform: parsedResult?.platform?.type || '-',
          Language: window?.navigator?.language || '-'
        }
      }
    }

    /* @ts-ignore */
    client.post(clientUrl, logRequest)
  }

  public render() {
    const {
      state: { hasError, showAlert },
      props: { children }
    } = this

    if (hasError) {
      return (
        <div className="w-100 h-100 overflow-hidden" data-testid="error-boundary">
          <Warning show={showAlert} />
        </div>
      )
    }
    return children
  }
}

export default ErrorBoundary
