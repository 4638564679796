Ext.define('MDM.src.view.globalapp.Panel.Start', {
  extend: 'Ext.panel.Panel',
  xtype: 'globalapp-panel-start',
  ui: 'ligt',
  layout: {
    type: 'vbox',
    align: 'stretch'
  },
  height: 100,
  width: 400,
  border: true,
  items: [
    {
      xtype: 'container',
      flex: 1,
      html: '<h1> Copy all data from previos period </h1>'
    },
    {
      xtype: 'button',
      height: 40,
      text: 'Start To Copy'
    }
  ]
})
