import { Alert, Col, Row } from 'react-bootstrap'
import INFOS from './constants'
import { NotFoundTypes } from './NotFound.types'

const NotFound = ({ tags, query, leadText = null, isVisibleIcon = true }: NotFoundTypes) => {
  return (
    <div className="not-found-template">
      <p>
        {INFOS.you_searched_for}
        <strong>
          {' '}
          {query}
          {query && tags.length ? ', ' : ''}
          {tags.map((item, index) => (
            <span key={`${item}-${index}`} data-testid={`not-found-tag-${index}`}>
              {item}
              {index !== tags.length - 1 ? ', ' : ''}
            </span>
          ))}{' '}
        </strong>
        {INFOS.not_found}
      </p>
      <Alert variant="warning">
        <Row>
          <Col md={12}>
            <h5 className="mb-2">
              {isVisibleIcon ? (
                <i
                  aria-hidden="true"
                  className="fa fa-info-circle me-1"
                  data-testid="not-found-icon"
                />
              ) : null}
              {INFOS.this_might_be_because}
            </h5>
            <ul className="suggestion-list ps-3 mb-0">
              <li className="mb-2">
                {leadText || (
                  <>
                    {INFOS.default_lead_text} <strong>name</strong> e.g. Filter Calendar
                  </>
                )}
              </li>
              <li>{INFOS.input_mistakes}</li>
            </ul>
          </Col>
        </Row>
      </Alert>
    </div>
  )
}

export default NotFound
