export default () => {
  return {
    title: 'Grid',
    type: 'object',
    format: 'objectTab',

    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          export: {
            type: 'boolean',
            title: 'Exportable',
            default: true,
            required: true
          },
          exportAllColumns: {
            type: 'boolean',
            title: 'Export All Columns',
            default: false,
            required: true
          },
          exportWithoutHeaders: {
            type: 'boolean',
            title: 'Export Without Headers',
            default: false,
            required: true
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },
      grid: {
        type: 'object',
        title: 'Grid',
        properties: {
          summary: {
            type: 'string',
            title: 'Summary',
            enum: ['off', 'top', 'bottom'],
            default: 'off',
            required: true
          },
          columnLines: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Column Lines'
          },
          multiColumnSort: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Multi Column Sort'
          },
          lockable: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Lockable'
          },
          split: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Draggable Split Bar',
            description: 'Only enabled when lockable is active'
          },
          paging: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Paging'
          },
          grouping: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Grouping'
          },
          groupingSummary: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Grouping Summary',
            description: 'Only enabled when grouping is active'
          },
          initialGroupingCollapse: {
            type: 'boolean',
            default: false,
            required: false,
            title: 'Grouping Initially Collapse',
            description: 'Only enabled when grouping is active'
          },
          filtering: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Filtering'
          },
          keepListFilterState: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Keep List Filter State',
            description:
              'Default true, select false to remove list filter selections by refreshing the page'
          },
          listFilterCheckbox: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'List Filter Checkbox',
            description:
              'Select true to apply filters after Filters checkbox selected on the header menu'
          },
          bufferedRenderer: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Buffered Renderer',
            description: 'Deactivating this prop can reduce the scroll performance.'
          },
          exportable: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Client Side (WYSIWYG) Export'
          },
          exportButtonPlacement: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Show Export Button on Footer'
          },
          automaticConfiguration: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Automatic Configuration'
          },
          getCellColorsFromData: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Get Cell Colors from Data',
            description: 'Apply cell color using {columnName}_backColor or {columnName}_textColor'
          },
          textSelection: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Text Selection',
            options: {
              hidden: true // TODO: I moved this property, therefore it is hidden
            }
          },
          emptyText: {
            type: 'string',
            title: 'No Data Text',
            default: 'No Data to Display'
          },
          dismissDelay: {
            type: 'integer',
            title: 'Tooltip Delay',
            format: 'range',
            minimum: 1000,
            maximum: 20000,
            default: 5000
          },
          header: {
            type: 'object',
            title: 'Header',
            options: {
              collapsed: true
            },
            properties: {
              headersVisible: {
                type: 'boolean',
                default: true,
                required: true,
                title: 'Headers Visible'
              },
              autoTooltip: {
                type: 'boolean',
                default: true,
                required: true,
                title: 'Headers as Tooltips'
              },
              headerAlignment: {
                type: 'boolean',
                default: false,
                required: true,
                title: 'Header Alignment',
                description: 'Column headers` alignment will be independent from column alignment.'
              },
              headerAlign: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'center',
                required: false,
                description: 'Only enabled when Header Alignment is active',
                title: 'Align'
              },
              multiLineHeader: {
                type: 'boolean',
                default: false,
                required: true,
                title: 'Header Multiline'
              }
            }
          },
          selection: {
            type: 'object',
            title: 'Selection',
            options: {
              collapsed: true
            },
            properties: {
              type: {
                type: 'string',
                title: 'Selection Mode',
                enum: ['Row', 'Spreadsheet', 'Multiselect'],
                default: 'Row',
                required: true
              },
              textSelection: {
                type: 'boolean',
                default: true,
                required: true,
                title: 'Text Selection'
              },
              rowDeselection: {
                type: 'boolean',
                default: true,
                required: true,
                title: 'Row Deselection'
              },
              keepSelectedRows: {
                type: 'boolean',
                default: false,
                required: true,
                title: 'Keep Rows Selected After Data Retrieval'
              }
            }
          },
          adding: {
            type: 'object',
            title: 'Adding',
            options: {
              collapsed: true
            },
            properties: {
              enabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              addingType: {
                type: 'string',
                title: 'Adding Type',
                enum: ['Direct', 'Trigger'],
                default: 'Direct',
                required: true
              },
              addButtonSettings: {
                type: 'object',
                title: 'Add Button',
                options: {
                  collapsed: true
                },
                properties: {
                  addButtonText: {
                    type: 'string',
                    title: 'Add Button Text',
                    default: 'Add Record'
                  },
                  addButtonTooltip: {
                    type: 'string',
                    title: 'Add Button Tooltip',
                    default: 'Add New Record'
                  },
                  addButtonIcon: {
                    type: 'string',
                    title: 'Add Button Icon',
                    format: 'IconSelector',
                    default: 'fa fa-plus-square'
                  }
                }
              }
            }
          },
          editing: {
            type: 'object',
            title: 'Editing',
            options: {
              collapsed: true
            },
            properties: {
              enabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              saveButton: {
                type: 'boolean',
                title: 'Save Button',
                default: true,
                required: true,
                options: {
                  hidden: true
                }
              },
              editingType: {
                type: 'string',
                title: 'Editing Type',
                enum: ['SaveButton', 'Direct', 'Trigger', 'Queue'],
                default: 'SaveButton',
                required: true
              },
              clicksToEdit: {
                type: 'number',
                title: 'Clicks to Edit',
                default: 1,
                enum: [1, 2],
                required: true
              },
              editableColumnIndex: {
                type: 'string',
                title: 'Editable Column Index',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds',
                required: true
              },
              massUpdate: {
                type: 'boolean',
                title: 'Mass Update',
                default: false,
                required: true
              },
              confirmation: {
                type: 'boolean',
                title: 'Confirmation',
                default: false,
                required: true
              },
              confirmationMessage: {
                type: 'string',
                title: 'Confirmation Message',
                default: 'Are you sure you want to save this changes?'
              },
              lockGrid: {
                type: 'boolean',
                title: 'Lock Grid While Saving',
                default: true,
                required: true
              },
              keepChanges: {
                type: 'boolean',
                title: 'Keep Changes When Update Fails',
                default: false,
                required: true,
                description: 'Keeps Dirty State when IsSuccess returns false from the response'
              },
              saveButtonSettings: {
                type: 'object',
                title: 'Save Button',
                options: {
                  collapsed: true
                },
                properties: {
                  saveButton: {
                    type: 'boolean',
                    title: 'Save Button',
                    default: true,
                    required: true
                  },
                  saveButtonText: {
                    type: 'string',
                    title: 'Save Button Text',
                    default: 'Save'
                  },
                  saveButtonTooltip: {
                    type: 'string',
                    title: 'Save Button Tooltip',
                    default: 'Save Changes'
                  },
                  saveButtonIcon: {
                    type: 'string',
                    title: 'Save Button Icon',
                    format: 'IconSelector',
                    default: 'fa fa-floppy-o'
                  },
                  resetButton: {
                    type: 'boolean',
                    title: 'Reset Button',
                    default: true,
                    required: true
                  },
                  resetButtonText: {
                    type: 'string',
                    title: 'Reset Button Text',
                    default: 'Reset Changes'
                  },
                  resetButtonTooltip: {
                    type: 'string',
                    title: 'Reset Button Tooltip',
                    default: 'Reset Changes'
                  },
                  resetButtonIcon: {
                    type: 'string',
                    title: 'Reset Button Icon',
                    format: 'IconSelector'
                  }
                }
              }
            }
          },
          updateParameters: {
            type: 'array',
            title: 'Update Parameters',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Parameter',
              properties: {
                name: {
                  type: 'string',
                  title: 'Name',
                  required: true
                },
                type: {
                  type: 'string',
                  title: 'Type',
                  enum: ['string', 'int', 'boolean', 'datetime'],
                  default: 'string',
                  required: true
                }
              }
            }
          },
          deleting: {
            type: 'object',
            title: 'Deleting',
            options: {
              collapsed: true
            },
            properties: {
              enabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              deletableCondition: {
                type: 'string',
                title: 'Enabled based on',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds'
              },
              confirmation: {
                type: 'boolean',
                title: 'Confirmation',
                default: true,
                required: true
              },
              confirmationMessage: {
                type: 'string',
                title: 'Confirmation Message',
                default: 'Are you sure you want to delete this row?'
              },
              deleteButtonSettings: {
                type: 'object',
                title: 'Delete Button',
                options: {
                  collapsed: true
                },
                properties: {
                  deleteButtonTooltip: {
                    type: 'string',
                    title: 'Delete Button Tooltip',
                    default: 'Delete'
                  },
                  deleteButtonIcon: {
                    type: 'string',
                    title: 'Delete Button Icon',
                    format: 'IconSelector',
                    default: 'fa fa-minus-circle'
                  }
                }
              }
            }
          },
          dataDetails: {
            type: 'object',
            title: 'Data Details',
            options: {
              collapsed: true
            },
            properties: {
              enabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text',
                default:
                  'Displaying <b>{FilteredRecordCount}</b> of <b>{TotalRecordCount}</b> records'
              },
              unsavedChangesText: {
                type: 'string',
                title: 'Unsaved Changes Text',
                default: '',
                description: 'Shows a message on Toolbar until dirty state cleans'
              }
            }
          },
          footerButtons: {
            type: 'array',
            title: 'Footer Buttons',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Button',
              headerTemplate: 'Button {{ self.buttonText }}',
              options: {
                collapsed: true
              },
              properties: {
                buttonText: {
                  type: 'string',
                  title: 'Text',
                  default: ''
                },
                buttonTooltip: {
                  type: 'string',
                  title: 'Tooltip',
                  default: ''
                },
                buttonIcon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector',
                  default: 'fa fa-pie-chart'
                },
                enableFooterButton: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds'
                },
                confirmation: {
                  type: 'boolean',
                  title: 'Confirmation',
                  required: true,
                  default: false
                },
                confirmationText: {
                  type: 'string',
                  title: 'Confirmation Text',
                  default: 'Are you sure to continue?'
                },
                confirmationTitle: {
                  type: 'string',
                  title: 'Confirmation Title',
                  default: 'Confirmation'
                }
              }
            }
          }
        }
      },
      columnActions: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Column Actions',
        items: {
          type: 'string'
        },
        default: ['']
      },
      columns: {
        type: 'array',
        title: 'Columns',
        isSupportMultipleColumn: true,
        options: {
          syncButton: true
        },
        items: {
          type: 'object',
          title: 'Column',
          headerTemplate: '{{ self.header}}',
          options: {
            collapsed: true
          },
          properties: {
            generalOptions: {
              type: 'object',
              title: 'General',
              options: {
                collapsed: false
              },
              isMultipleEditable: true,
              properties: {
                fieldName: {
                  type: 'string',
                  title: 'Data Field',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: false
                },
                columnType: {
                  type: 'string',
                  title: 'Column Type',
                  enum: ['simple', 'progress', 'color', 'icon', 'sparkline', 'html'],
                  required: true,
                  default: 'simple',
                  isMultipleEditable: true
                },
                header: {
                  type: 'string',
                  title: 'Header',
                  default: '\xa0',
                  description: 'Apply templates using {columnName}',
                  isMultipleEditable: false
                },
                tooltip: {
                  type: 'string',
                  title: 'Tooltip',
                  description: 'Apply templates using {columnName}',
                  isMultipleEditable: false
                },
                cellTooltip: {
                  type: 'string',
                  format: 'Code',
                  title: 'Cell Tooltip',
                  description: 'Apply templates using {columnName}',
                  options: {
                    mode: 'htmlmixed',
                    lineNumbers: true,
                    readOnly: false
                  },
                  isMultipleEditable: false
                },
                groupHeader: {
                  type: 'string',
                  title: 'Group Header',
                  description: 'Apply templates using {columnName}',
                  isMultipleEditable: true
                },
                align: {
                  type: 'string',
                  enum: ['left', 'center', 'right'],
                  default: 'left',
                  required: true,
                  title: 'Align',
                  isMultipleEditable: true
                },
                sortable: {
                  type: 'boolean',
                  title: 'Sortable',
                  default: true,
                  required: true,
                  isMultipleEditable: true
                },
                cellStyle: {
                  type: 'string',
                  title: 'Color',
                  enum: ['', 'blue', 'red', 'green', 'orange'],
                  required: true,
                  default: '',
                  isMultipleEditable: true
                },
                backColor: {
                  type: 'string',
                  title: 'Back Color',
                  format: 'NullColor',
                  isMultipleEditable: true
                },
                fontColor: {
                  type: 'string',
                  title: 'Text Color',
                  format: 'NullColor',
                  isMultipleEditable: true
                },
                formatField: {
                  type: 'string',
                  title: 'Format Field',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: true
                },
                width: {
                  type: 'number',
                  title: 'Width',
                  default: 90,
                  isMultipleEditable: true
                },
                flex: {
                  type: 'number',
                  title: 'Flex',
                  enum: [0, 1],
                  default: 1,
                  isMultipleEditable: true
                },
                showInMaxMode: {
                  type: 'boolean',
                  title: 'Show only when Maximized',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                },
                locked: {
                  type: 'boolean',
                  title: 'Locked',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                },
                grouped: {
                  type: 'boolean',
                  title: 'Grouped',
                  default: false,
                  required: true,
                  isMultipleEditable: true,
                  description: 'Grid grouping property is a pre-condition.'
                },
                hidden: {
                  type: 'boolean',
                  title: 'Hidden',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                },
                hiddenCondition: {
                  type: 'string',
                  title: 'Hidden based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: true
                },
                template: {
                  type: 'string',
                  title: 'Template',
                  isMultipleEditable: true
                },
                summaryType: {
                  type: 'string',
                  title: 'Summary Type',
                  enum: ['', 'count', 'sum', 'min', 'max', 'average'],
                  required: true,
                  default: '',
                  isMultipleEditable: true
                },
                customAggregation: {
                  type: 'string',
                  format: 'Code',
                  title: 'Custom Aggregation',
                  options: {
                    mode: 'javascript',
                    lineNumbers: true,
                    readOnly: false,
                    base64: true,
                    basic: true
                  },
                  isMultipleEditable: true
                },
                summaryTemplate: {
                  type: 'string',
                  title: 'Summary Template',
                  isMultipleEditable: true
                },
                columnAction: {
                  type: 'string',
                  title: 'Column Action',
                  watch: {
                    ds: 'columnActions'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: true
                },
                hideMenu: {
                  type: 'boolean',
                  title: 'Hide Menu',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                }
              }
            },
            adding: {
              type: 'object',
              title: 'Adding',
              options: {
                collapsed: true
              },
              isMultipleEditable: true,
              properties: {
                enabled: {
                  type: 'boolean',
                  title: 'Enabled',
                  default: true,
                  required: true,
                  isMultipleEditable: true
                }
              }
            },
            editing: {
              type: 'object',
              title: 'Editing',
              options: {
                collapsed: true
              },
              isMultipleEditable: true,
              properties: {
                enabled: {
                  type: 'boolean',
                  title: 'Enabled',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                },
                editableCondition: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  required: true,
                  isMultipleEditable: true
                },
                allowBlank: {
                  type: 'boolean',
                  title: 'Allow Blank',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                },
                maxValue: {
                  type: 'NullableNumber',
                  title: 'Maximum Value',
                  default: null,
                  isMultipleEditable: true
                },
                minValue: {
                  type: 'NullableNumber',
                  title: 'Minimum Value',
                  default: null,
                  isMultipleEditable: true
                },
                step: {
                  type: 'NullableNumber',
                  title: 'Step',
                  default: null,
                  isMultipleEditable: true
                },
                maxValueField: {
                  type: 'string',
                  title: 'Maximum Value Field',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: true
                },
                minValueField: {
                  type: 'string',
                  title: 'Minimum Value Field',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: true
                },
                stepField: {
                  type: 'string',
                  title: 'Step Field',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: true
                },
                lookupDataField: {
                  type: 'string',
                  title: 'Lookup Data Field',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  isMultipleEditable: true
                },
                headerCheckbox: {
                  type: 'boolean',
                  title: 'Header Checkbox',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                },
                warningThreshhold: {
                  type: 'number',
                  title: 'Warning Threshhold',
                  description: 'As Percentage',
                  default: 0,
                  required: true,
                  isMultipleEditable: true
                }
              }
            },
            filtering: {
              type: 'object',
              title: 'Filtering',
              options: {
                collapsed: true
              },
              isMultipleEditable: true,
              properties: {
                showList: {
                  type: 'boolean',
                  title: 'Select from List',
                  default: false,
                  required: true,
                  description: 'Apply data filter using {columnName}_FilterValue',
                  isMultipleEditable: true
                },
                showListMaximum: {
                  type: 'number',
                  title: 'Maximum Items in the List',
                  default: 20
                },
                tagFilter: {
                  type: 'boolean',
                  title: 'Tag Filter',
                  default: false,
                  required: true,
                  description: `Activates multiple choices on 'like' filter while using Paging & {PLUGINFILTERS}`,
                  isMultipleEditable: true
                }
              }
            },
            sparkline: {
              type: 'object',
              title: 'Sparkline',
              options: {
                collapsed: true
              },
              isMultipleEditable: true,
              properties: {
                sparkLineProps: {
                  title: 'Type',
                  default: { type: 'sparklineline' },
                  options: {
                    disable_collapse: true
                  },
                  isMultipleEditable: true,
                  oneOf: [
                    {
                      title: 'Bar',
                      type: 'object',
                      required: ['type'],
                      additionalProperties: false,
                      isMultipleEditable: true,
                      properties: {
                        type: {
                          type: 'string',
                          enum: ['sparklinebar'],
                          options: {
                            hidden: true
                          },
                          isMultipleEditable: true
                        },
                        barSpacing: {
                          type: 'number',
                          title: 'Bar Spacing',
                          default: 1,
                          isMultipleEditable: true
                        },
                        barWidth: {
                          type: 'number',
                          title: 'Bar Width',
                          default: 4,
                          isMultipleEditable: true
                        },
                        barColor: {
                          type: 'string',
                          title: 'Bar Color',
                          format: 'Color',
                          default: '#3366cc',
                          isMultipleEditable: true
                        },
                        negBarColor: {
                          type: 'string',
                          title: 'Negative Bar Color',
                          format: 'Color',
                          default: '#f44',
                          isMultipleEditable: true
                        },
                        highlightColor: {
                          type: 'string',
                          title: 'Highlight Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        },
                        zeroColor: {
                          type: 'string',
                          title: 'Zero Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        }
                      }
                    },
                    {
                      title: 'Box',
                      type: 'object',
                      required: ['type'],
                      additionalProperties: false,
                      isMultipleEditable: true,
                      properties: {
                        type: {
                          type: 'string',
                          enum: ['sparklinebox'],
                          options: {
                            hidden: true
                          },
                          isMultipleEditable: true
                        },
                        boxFillColor: {
                          type: 'string',
                          title: 'Box Fill Color',
                          format: 'Color',
                          default: '#cdf',
                          isMultipleEditable: true
                        },
                        boxLineColor: {
                          type: 'string',
                          title: 'Box Line Color',
                          format: 'Color',
                          default: '#000',
                          isMultipleEditable: true
                        },
                        highlightColor: {
                          type: 'string',
                          title: 'Highlight Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        },
                        lineColor: {
                          type: 'string',
                          title: 'Line Color',
                          format: 'Color',
                          default: '#157fcc',
                          isMultipleEditable: true
                        },
                        medianColor: {
                          type: 'string',
                          title: 'Median Color',
                          format: 'Color',
                          default: '#f00',
                          isMultipleEditable: true
                        },
                        outlierFillColor: {
                          type: 'string',
                          title: 'Outlier Fill Color',
                          format: 'Color',
                          default: '#fff',
                          isMultipleEditable: true
                        },
                        outlierLineColor: {
                          type: 'string',
                          title: 'Outlier Line Color',
                          format: 'Color',
                          default: '#333',
                          isMultipleEditable: true
                        },
                        targetColor: {
                          type: 'string',
                          title: 'Target Color',
                          format: 'Color',
                          default: '#4a2',
                          isMultipleEditable: true
                        },
                        whiskerColor: {
                          type: 'string',
                          title: 'Whisker Color',
                          format: 'Color',
                          default: '#000',
                          isMultipleEditable: true
                        }
                      }
                    },
                    {
                      title: 'Bullet',
                      type: 'object',
                      required: ['type'],
                      additionalProperties: false,
                      isMultipleEditable: true,
                      properties: {
                        type: {
                          type: 'string',
                          enum: ['sparklinebullet'],
                          options: {
                            hidden: true
                          },
                          isMultipleEditable: true
                        },
                        highlightColor: {
                          type: 'string',
                          title: 'Highlight Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        },
                        performanceColor: {
                          type: 'string',
                          title: 'Performance Color',
                          format: 'Color',
                          default: '#33f',
                          isMultipleEditable: true
                        },
                        targetColor: {
                          type: 'string',
                          title: 'Target Color',
                          format: 'Color',
                          default: '#f33',
                          isMultipleEditable: true
                        }
                      }
                    },
                    {
                      title: 'Discrete',
                      type: 'object',
                      required: ['type'],
                      additionalProperties: false,
                      isMultipleEditable: true,
                      properties: {
                        type: {
                          type: 'string',
                          enum: ['sparklinediscrete'],
                          options: {
                            hidden: true
                          },
                          isMultipleEditable: true
                        },
                        highlightColor: {
                          type: 'string',
                          title: 'Highlight Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        },
                        lineColor: {
                          type: 'string',
                          title: 'Line Color',
                          format: 'Color',
                          default: '#157fcc',
                          isMultipleEditable: true
                        },
                        thresholdColor: {
                          type: 'string',
                          title: 'Threshold Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        }
                      }
                    },
                    {
                      title: 'Line',
                      type: 'object',
                      required: ['type'],
                      additionalProperties: false,
                      isMultipleEditable: true,
                      properties: {
                        type: {
                          type: 'string',
                          enum: ['sparklineline'],
                          options: {
                            hidden: true
                          },
                          isMultipleEditable: true
                        },
                        lineWidth: {
                          type: 'number',
                          title: 'Line Width',
                          default: 1,
                          isMultipleEditable: true
                        },
                        normalRangeMin: {
                          type: 'NullableNumber',
                          title: 'Normal Range Min',
                          default: '',
                          isMultipleEditable: true
                        },
                        normalRangeMax: {
                          type: 'NullableNumber',
                          title: 'Normal Range Max',
                          default: '',
                          isMultipleEditable: true
                        },
                        fillColor: {
                          type: 'string',
                          title: 'Fill Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        },
                        highlightColor: {
                          type: 'string',
                          title: 'Highlight Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        },
                        highlightLineColor: {
                          type: 'string',
                          title: 'Highlight Line Color',
                          format: 'Color',
                          default: '#f22',
                          isMultipleEditable: true
                        },
                        highlightSpotColor: {
                          type: 'string',
                          title: 'Highlight Spot Color',
                          format: 'Color',
                          default: '#5f5',
                          isMultipleEditable: true
                        },
                        lineColor: {
                          type: 'string',
                          title: 'Line Color',
                          format: 'Color',
                          default: '#157fcc',
                          isMultipleEditable: true
                        },
                        maxSpotColor: {
                          type: 'string',
                          title: 'Max Spot Color',
                          format: 'Color',
                          default: '#f80',
                          isMultipleEditable: true
                        },
                        minSpotColor: {
                          type: 'string',
                          title: 'Min Spot Color',
                          format: 'Color',
                          default: '#f80',
                          isMultipleEditable: true
                        },
                        spotColor: {
                          type: 'string',
                          title: 'Spot Color',
                          format: 'Color',
                          default: '#f80',
                          isMultipleEditable: true
                        },
                        normalRangeColor: {
                          type: 'string',
                          title: 'Normal Range Color',
                          format: 'Color',
                          default: '#ccc',
                          isMultipleEditable: true
                        }
                      }
                    },
                    {
                      title: 'Pie',
                      type: 'object',
                      required: ['type'],
                      additionalProperties: false,
                      isMultipleEditable: true,
                      properties: {
                        type: {
                          type: 'string',
                          enum: ['sparklinepie'],
                          options: {
                            hidden: true
                          },
                          isMultipleEditable: true
                        },
                        borderWidth: {
                          type: 'number',
                          title: 'Border Width',
                          default: 0,
                          maximum: 5,
                          isMultipleEditable: true
                        },
                        borderColor: {
                          type: 'string',
                          title: 'Border Color',
                          format: 'Color',
                          default: '#000',
                          isMultipleEditable: true
                        },
                        highlightColor: {
                          type: 'string',
                          title: 'Highlight Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        }
                      }
                    },
                    {
                      title: 'TriState',
                      type: 'object',
                      required: ['type'],
                      additionalProperties: false,
                      isMultipleEditable: true,
                      properties: {
                        type: {
                          type: 'string',
                          enum: ['sparklinetristate'],
                          options: {
                            hidden: true
                          },
                          isMultipleEditable: true
                        },
                        barSpacing: {
                          type: 'number',
                          title: 'Bar Spacing',
                          default: 1,
                          isMultipleEditable: true
                        },
                        barWidth: {
                          type: 'number',
                          title: 'Bar Width',
                          default: 4,
                          isMultipleEditable: true
                        },
                        highlightColor: {
                          type: 'string',
                          title: 'Highlight Color',
                          format: 'NullColor',
                          isMultipleEditable: true
                        },
                        posBarColor: {
                          type: 'string',
                          title: 'Positive Bar Color',
                          format: 'Color',
                          default: '#6f6',
                          isMultipleEditable: true
                        },
                        negBarColor: {
                          type: 'string',
                          title: 'Negative Bar Color',
                          format: 'Color',
                          default: '#f44',
                          isMultipleEditable: true
                        },
                        zeroBarColor: {
                          type: 'string',
                          title: 'Zero Bar Color',
                          format: 'Color',
                          default: '#999',
                          isMultipleEditable: true
                        }
                      }
                    }
                  ]
                }
              }
            },
            boolean: {
              type: 'object',
              title: 'Boolean',
              options: {
                collapsed: true
              },
              isMultipleEditable: true,
              properties: {
                trueText: {
                  type: 'string',
                  title: 'True Text',
                  options: {
                    hidden: true // TODO: This property is not implemented yet
                  },
                  isMultipleEditable: true
                },
                falseText: {
                  type: 'string',
                  title: 'False Text',
                  options: {
                    hidden: true // TODO: This property is not implemented yet
                  },
                  isMultipleEditable: true
                },
                trueIcon: {
                  type: 'string',
                  title: 'True Icon',
                  format: 'IconSelector',
                  isMultipleEditable: true
                },
                falseIcon: {
                  type: 'string',
                  title: 'False Icon',
                  format: 'IconSelector',
                  isMultipleEditable: true
                },
                trueColor: {
                  type: 'string',
                  title: 'True Color',
                  format: 'NullColor',
                  isMultipleEditable: true
                },
                falseColor: {
                  type: 'string',
                  title: 'False Color',
                  format: 'NullColor',
                  isMultipleEditable: true
                }
              }
            },
            action: {
              type: 'object',
              title: 'Action',
              options: {
                collapsed: true
              },
              isMultipleEditable: true,
              properties: {
                cellClickEnabled: {
                  type: 'boolean',
                  title: 'Cell Click',
                  default: false,
                  required: true,
                  isMultipleEditable: true
                }
              }
            },
            actionButton: {
              type: 'object',
              title: 'Action Button',
              options: {
                collapsed: true
              },
              isMultipleEditable: false,
              properties: {
                enabled: {
                  type: 'boolean',
                  title: 'Enabled',
                  default: false,
                  required: true,
                  isMultipleEditable: false,
                  description:
                    'You must select an icon from Icon menu to use action type column properly.'
                },
                name: {
                  type: 'string',
                  title: 'Name',
                  required: true,
                  description: 'Apply templates using {columnName}',
                  isMultipleEditable: false
                },
                editableCondition: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  required: true,
                  isMultipleEditable: false
                },
                clickable: {
                  type: 'boolean',
                  title: 'Clickable',
                  description: 'Icon still clickable when disabled',
                  default: false,
                  required: true,
                  isMultipleEditable: false
                }
              }
            },
            icon: {
              type: 'object',
              title: 'Icon',
              options: {
                collapsed: true
              },
              isMultipleEditable: true,
              properties: {
                icon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector',
                  isMultipleEditable: true
                },
                displayOnlyIcon: {
                  type: 'boolean',
                  title: 'Display Only Icon',
                  required: true,
                  default: false,
                  isMultipleEditable: true
                },
                iconPosition: {
                  type: 'string',
                  title: 'Icon Position',
                  enum: ['left', 'right'],
                  required: true,
                  default: 'right',
                  isMultipleEditable: true
                }
              }
            }
          }
        }
      },
      keys: {
        type: 'array',
        title: 'Keys',
        items: {
          type: 'object',
          title: 'Key',
          headerTemplate: '{{ self.fieldName}}',
          options: {
            collapsed: true
          },
          properties: {
            fieldName: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds'
            }
          }
        }
      },
      rowDisplayRules: {
        type: 'array',
        title: 'Row Display Rules',
        items: {
          type: 'object',
          title: 'Rule',
          properties: {
            ruleFieldName: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },
            operator: {
              type: 'string',
              title: 'Operator',
              enum: ['equal', 'not equal', 'smaller', 'larger'],
              required: true,
              default: 'equal'
            },
            value: {
              type: 'string',
              title: 'Value'
            },
            compareToField: {
              type: 'string',
              title: 'CompareToField',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true,
              description: 'If this field is used Value field above is ignored.'
            },
            backColor: {
              type: 'string',
              title: 'Back Color',
              format: 'NullColor',
              default: 'red'
            },
            textColor: {
              type: 'string',
              title: 'Text Color',
              format: 'NullColor',
              default: '#ffffff'
            },
            boldText: {
              type: 'boolean',
              title: 'Text Bold',
              required: true,
              default: false
            },
            italicText: {
              type: 'boolean',
              title: 'Text Italic',
              required: true,
              default: false
            },
            textDecoration: {
              type: 'string',
              title: 'Text Decoration',
              enum: ['none', 'underline', 'line-through'],
              required: true,
              default: 'none'
            },
            group: {
              type: 'string',
              title: 'Group'
            }
          }
        }
      },
      cellDisplayRules: {
        type: 'array',
        title: 'Cell Display Rules',
        items: {
          type: 'object',
          title: 'Rule',
          properties: {
            ruleFieldName: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },
            referenceFieldName: {
              type: 'string',
              title: 'Reference Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },
            operator: {
              type: 'string',
              title: 'Operator',
              enum: ['equal', 'not equal', 'smaller', 'larger'],
              required: true,
              default: 'equal'
            },
            value: {
              type: 'string',
              title: 'Value'
            },
            backColor: {
              type: 'string',
              title: 'Back Color',
              format: 'NullColor'
            },
            textColor: {
              type: 'string',
              title: 'Text Color',
              format: 'NullColor',
              default: '#ddd'
            },
            boldText: {
              type: 'boolean',
              title: 'Text Bold',
              required: true,
              default: false
            },
            italicText: {
              type: 'boolean',
              title: 'Text Italic',
              required: true,
              default: false
            },
            textDecoration: {
              type: 'string',
              title: 'Text Decoration',
              enum: ['none', 'underline', 'line-through'],
              required: true,
              default: 'none'
            },
            applyRuleSummary: {
              type: 'boolean',
              title: 'Apply to Summary',
              required: true,
              default: false,
              description: 'Apply rules styles to Summary cells'
            },
            icon: {
              type: 'string',
              title: 'Icon',
              format: 'IconSelector'
            },
            displayOnlyIcon: {
              type: 'boolean',
              title: 'Display Only Icon',
              required: true,
              default: false
            },
            cellTooltip: {
              type: 'string',
              format: 'Code',
              title: 'Cell Tooltip',
              description: 'Apply templates using {columnName}',
              options: {
                mode: 'htmlmixed',
                lineNumbers: true,
                readOnly: false
              }
            },
            iconPosition: {
              type: 'string',
              title: 'Icon Position',
              enum: ['left', 'right'],
              required: true,
              default: 'right'
            },
            group: {
              type: 'string',
              title: 'Group'
            }
          }
        }
      },
      actions: {
        type: 'object',
        title: 'Actions',
        options: {
          hidden: true
        },
        properties: {
          displayMode: {
            type: 'string',
            title: 'Display Mode',
            enum: ['Menu', 'Buttons'],
            description: 'Actions menu is obsolete. Use Columns-Action Button menu instead.',
            default: 'Menu',
            required: true
          },
          actionItems: {
            type: 'array',
            title: 'Action Items',
            items: {
              type: 'object',
              title: 'Item',
              headerTemplate: '{{ self.name }}',
              options: {
                disable_array_copy: true,
                disable_array_add: true
              },
              properties: {
                name: {
                  type: 'string',
                  title: 'Name'
                },
                title: {
                  type: 'string',
                  title: 'Title',
                  description: 'Apply templates using {columnName}'
                },
                index: {
                  type: 'NullableNumber',
                  title: 'Index',
                  default: ''
                },
                icon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector'
                },
                color: {
                  type: 'string',
                  title: 'Color',
                  format: 'Color',
                  default: '#404040'
                },
                groupHeader: {
                  type: 'string',
                  title: 'Group Header',
                  description: 'Apply templates using {columnName}'
                },
                enabledCondition: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'datafields'
                  },
                  enumSource: 'ds',
                  required: true
                }
              }
            }
          }
        }
      }
    }
  }
}
