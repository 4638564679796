import jwtDecode from 'jwt-decode'
import _ from 'lodash'

export const getUserToken = (user = {}) => {
  return jwtDecode(user.access_token)
}

export default (user = {}, currentEnvironment = '') => {
  /** Decode Token */
  const userObj = getUserToken(user)

  return permClass(userObj, currentEnvironment, true)
}

const permClass = function (userObj, userEnv, lastResult = true) {
  return {
    value: lastResult,
    hasPermType(permType) {
      const [group, action] = permType.split('.')

      const { [group]: permActions = [] } = userObj
      const userPerm = _.find(permActions, (pa) => _.isEqual(pa, action))
      return this.getReturnClass(userPerm !== undefined)
    },
    hasPermTypes(permTypes) {
      const perms = _.isArray(permTypes) ? permTypes : [permTypes]
      const newResult = _.reduce(
        perms,
        (result, permType) => {
          const [group, action] = permType.split('.')
          const { [group]: permActions = [] } = userObj
          const userPerm = _.find(permActions, (pa) => _.isEqual(pa, action))
          return result && userPerm !== undefined
        },
        true
      )
      return this.getReturnClass(newResult)
    },
    isEnv(env) {
      return this.getReturnClass(_.isEqual(env, userEnv))
    },
    getReturnClass(result) {
      return permClass(userObj, userEnv, lastResult && result)
    }
  }
}

export const getEnvironments = (user = {}) => {
  const decoded = typeof user === 'object' ? jwtDecode(user?.access_token) : {}
  return !_.isEmpty(decoded?.Mode) ? decoded.Mode : ['Live']
}

export const PermTypes = {
  MenuAdd: 'Menu.Add',
  MenuEdit: 'Menu.Edit',
  PluginEdit: 'Plugin.Edit',
  PluginAdd: 'Plugin.Add',
  PluginDelete: 'Plugin.Delete',
  PluginView: 'Plugin.View',
  UserView: 'User.View',
  UserEdit: 'User.Edit',
  UserAdd: 'User.Add',
  UserDelete: 'User.Delete',
  ContainerEdit: 'Container.Edit',
  CatalogEdit: 'Catalog.Edit',
  CatalogArchive: 'Catalog.Archive',
  PageTemplateCreate: 'PageTemplate.Create',
  PageTemplateDelete: 'PageTemplate.Delete',
  PageTemplateEdit: 'PageTemplate.Edit',
  CopyToEnv: 'Page.Copy',
  UserGroupCreate: 'UserGroup.Create',
  UserGroupView: ' UserGroup.View',
  UserGroupDelete: 'UserGroup.Delete',
  UserGroupUpdate: 'UserGroup.Update'
}
