import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'

export interface IClientLog {
  flag: string
  title: string
  message?: string
  date: Moment
}

export interface IState {
  clientLogs: IClientLog[]
}

export const initialState: IState = {
  clientLogs: []
}

export const logger = createSlice({
  name: 'logger',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Omit<IClientLog, 'date'>>) => {
      const { flag, title, message } = action.payload
      // TODO: message must be a string type, but we don't know how it passed as number or object etc.
      const optionalParams = typeof message === 'undefined' ? {} : { message }
      state.clientLogs.unshift({
        ...optionalParams,
        flag,
        title,
        date: moment(new Date())
      })
    },
    removeAllMessage: (state) => {
      state.clientLogs = []
    }
  }
})

export const { addMessage, removeAllMessage } = logger.actions

export default logger.reducer
