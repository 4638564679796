import { useCallback } from 'react'
import { IRowData } from '../../types'
import { ApplyUpdateProps } from './useApplyUpdate.types'

function useApplyUpdate(props: ApplyUpdateProps) {
  const { gridRef, setDirtyFields, doesDirectUpdate, triggerSave } = props
  const applyUpdate = useCallback(
    (data: IRowData[], callback: () => void = () => null) => {
      gridRef?.current?.api.applyTransactionAsync({ update: data }, () => {
        setDirtyFields()
        if (doesDirectUpdate) {
          triggerSave({ skipUnsavedChanges: true, skipConfirmation: false, updateParameters: [] })
        }
        callback()
      })
    },
    [gridRef, setDirtyFields, doesDirectUpdate, triggerSave]
  )

  return { applyUpdate }
}

export default useApplyUpdate
