export default `({
    //  fields: [{
    //        name: 'QTY',
    //        type: 'number'
    //    },
    //    {
    //        name: 'Price',
    //        type: 'number'
    //    },
    //    {
    //        name: 'NumberOfOpenStore',
    //        type: 'number'
    //    },
    //    {
    //        name: 'NumberofStoreHasInventory',
    //        type: 'number'
    //    },
    //    {
    //        name: 'TotalStoreInventory',
    //        type: 'number'
    //    },
    //    {
    //        name: 'Envanter',
    //        type: 'number'
    //    },
    //    {
    //        name: 'DcInventory',
    //        type: 'number'
    //    },
    //    {
    //        name: 'TotalInventory',
    //        type: 'number'
    //    },
    //],
    proxy: {
        type: 'ajax',
        actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
        },
        data: [{
            DateKey: 0
        }],
        url: Spui.Global.getApiUrl() + 'spui/GetInventoryListForMaterialCode',
    },
    autoLoad: false,
})
`
