Ext.define('MDM.src.view.massimport.Grid', {
  extend: 'Ext.grid.Panel',
  xtype: 'massimport-grid',
  columnLines: true,
  config: {
    importData: null,
    reLoadData: null
  },
  lockedGridConfig: {
    width: 600,
    split: true,
    collapsible: false
  },
  border: true,
  layout: 'border',
  features: [
    {
      ftype: 'summary'
    }
  ],
  viewConfig: {
    getRowClass: function (record) {
      if (record.get('criticalError')) return 'criticalError'
      if (!record.get('isValid')) return 'NotValid'
      return ''
    },
    loadMask: false,
    stripeRows: true
  },
  selModel: {
    type: 'checkboxmodel',
    checkOnly: true,
    listeners: {
      beforeselect: function (el, record) {
        if (!record.get('isValid')) {
          return false
        }
        return !record.get('criticalError')
      }
    }
  },

  masterColumns: [
    {
      menuDisabled: false,
      sortable: true,
      xtype: 'actioncolumn',
      locked: true,
      resizable: false,
      menuText: 'Action Apply',
      width: 40,
      align: 'center',
      text: '',
      summaryType: 'count',
      summaryRenderer: function () {
        return '-'
      },
      items: [
        {
          iconCls: 'fa fa-circle',
          isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
            return !!rec.get('criticalError')
          },
          getClass: function (v, meta, rec) {
            if (!rec.get('criticalError')) {
              return 'fa fa-pencil'
            }
            return ''
          },
          handler: 'onShowDialog'
        }
      ]
    },
    {
      xtype: 'checkcolumn',
      header: 'Apply',
      dataIndex: 'willApply',
      locked: true,
      headerCheckbox: true,
      summaryType: 'count',
      summaryRenderer: function () {
        return '-'
      },
      width: 50,
      stopSelection: true,
      toggleAll: function (e) {
        var me = this,
          view = me.getView(),
          store = view.getStore(),
          checked = !me.allChecked,
          position

        if (me.fireEvent('beforeheadercheckchange', me, checked, e) !== false) {
          // Only create and maintain a CellContext if there are consumers
          // in the form of event listeners. The event is a click on a
          // column header and will have no position property.
          if (me.hasListeners.checkchange || me.hasListeners.beforecheckchange) {
            position = e.position = new Ext.grid.CellContext(view)
          }
          store.each(function (record, recordIndex) {
            if (record.get('isValid') && !record.get('criticalError')) {
              me.setRecordCheck(record, recordIndex, checked, view.getCell(record, me))
            }
          })

          me.setHeaderStatus(checked, e)
          me.fireEvent('headercheckchange', me, checked, e)
        }
      },

      listeners: {
        beforecheckchange: function (col, rowIndex, checked, record, e, eOpts) {
          if (!record.get('isValid')) {
            return false
          }
          return !record.get('criticalError')
        }
      }
    },

    {
      text: 'BTL Type',
      dataIndex: 'BTLType',
      summaryType: 'count',
      locked: true,
      width: 50
    },
    {
      text: 'Reason',
      dataIndex: 'Reason',
      summaryType: 'count',
      locked: true,
      summaryRenderer: function () {
        return '<b>Totals:</b>'
      },
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (!record.get('Reason')) {
          metaData.tdCls = 'cell-yellow-warnig'
        }

        return value
      },
      width: 70
    },
    {
      text: 'Subregion',
      dataIndex: 'Region',
      summaryType: 'count',
      locked: true,
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (!record.get('RegionID')) {
          metaData.tdCls = 'cell-yellow-warnig'
        }

        return value
      },
      width: 70
    },

    {
      text: 'From Subregion',
      dataIndex: 'FromRegion',
      summaryType: 'count',
      locked: true,
      width: 70,
      renderer: function (value, metaData, record, rowIndex) {
        // style the cell differently for even / odd values
        if (record.get('BTLType') === 'X') {
          if (value && !record.get('FromRegionId')) {
            metaData.tdCls = 'cell-warnig'
          } else if (!record.get('FromRegionId') && !record.get('ToRegionId')) {
            metaData.tdCls = 'cell-yellow-warnig'
          }
        }
        return value
      }
    },
    {
      text: 'To Subregion',
      dataIndex: 'ToRegion',
      summaryType: 'count',
      locked: true,
      width: 70,
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (record.get('BTLType') === 'X') {
          if (value && !record.get('ToRegionId')) {
            metaData.tdCls = 'cell-warnig'
          } else if (!record.get('FromRegionId') && !record.get('ToRegionId')) {
            metaData.tdCls = 'cell-warnig'
          }
        }
        return value
      }
    },

    {
      text: 'DPI Family',
      dataIndex: 'DPIFamily',
      summaryType: 'count',
      locked: true,
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (!record.get('DPIFamilyID')) {
          metaData.tdCls = 'cell-yellow-warnig'
        }

        return value
      },
      width: 175
    },
    {
      text: 'Material Code',
      dataIndex: 'MaterialCode',
      summaryType: 'count',
      locked: true,
      width: 100,
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (value || record.get('MaterialDescription')) {
          if (!record.get('MaterialID')) {
            metaData.tdCls = 'cell-yellow-warnig'
          }
        }
        return value
      }
    },
    {
      text: 'Material Description',
      dataIndex: 'MaterialDescription',
      summaryType: 'count',
      locked: true,
      width: 200,
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (value || record.get('MaterialCode')) {
          if (!record.get('MaterialID')) {
            metaData.tdCls = 'cell-yellow-warnig'
          }
        }
        return value
      }
    },
    {
      text: 'Material Type',
      dataIndex: 'MaterialType',
      summaryType: 'count',
      locked: true,
      width: 100,
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (!record.get('MaterialTypeID')) metaData.tdCls = 'cell-yellow-warnig'
        return value
      }
    },
    {
      text: 'Supply Source',
      dataIndex: 'SupplySource',
      summaryType: 'count',
      locked: true,
      width: 100,
      renderer: function (value, metaData, record) {
        // style the cell differently for even / odd values
        if (record.get('BTLType') === 'P' && !record.get('SupplySourceID'))
          metaData.tdCls = 'cell-yellow-warnig'
        return value
      }
    },
    {
      text: 'Comment',
      dataIndex: 'Comment',
      summaryType: 'count',
      locked: true,
      width: 100
    }
  ],

  initComponent: function () {
    var me = this,
      importType = me.importType,
      columns = me.masterColumns.slice()
    me.store = Ext.create('Ext.data.Store', {
      model: 'MDM.src.view.massimport.Model',
      data: me.importData
    })
    me.columns = []

    if (importType === 'D' || importType === 'I') {
      columns.splice(6, 1)
      columns.splice(6, 1)
      columns.splice(8, 1)
    } else if (importType === 'P') {
      columns.splice(7, 1)
      columns.splice(7, 1)
    } else if (importType === 'X') {
      columns.splice(6, 1)
    }
    me.columns = MDM.Btl.Column.build(columns)
    me.callParent()
  },

  setReLoadData: function (data) {
    var me = this
    me.store.loadData(data)
    var columns = me.masterColumns.slice()
    me.columns = []
    var importType = (me.importType && me.importType.value) || ''

    if (importType === 'D' || importType === 'I') {
      columns.splice(4, 1)
      columns.splice(4, 1)
      columns.splice(8, 1)
    } else if (importType === 'P') {
      columns.splice(4, 1)
      columns.splice(4, 1)
    }
    var newcolumns = MDM.Btl.Column.build(columns)
    me.reconfigure(me.store, newcolumns)
  }
})
