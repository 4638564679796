export default {
  title: 'Timeline',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'Timeline',
  description: 'View tasks or processes in the timeline',
  iconClass: 'fa-calendar',
  color: 'lazur-bg',
  group: 'Views',
  support: {
    maximizable: true,
    editable: true
  },
  queryStatus: {
    isInsertActive: true,
    isUpdateActive: true,
    isDeleteActive: true
  }
}
