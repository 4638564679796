import { createApi } from '@reduxjs/toolkit/query/react'
import dynamicBaseQuery from './dynamicBaseQuery'

const api = createApi({
  reducerPath: 'api',
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({})
})

export default api
export const { endpoints, util } = api
