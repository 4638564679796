import { IRowData } from '../../../types'
import { getEnableInfo } from '../index'
import { DEFAULT_CUSTOM_ROW_INFO } from '../../../constants'

const commonRowData = {
  _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
    ...DEFAULT_CUSTOM_ROW_INFO,
    _SLVY_AG_GRID_ROW_ID_: 'ABC123'
  }
}

const getRowData = (): IRowData => {
  return {
    ...commonRowData,
    col1: 'value1',
    col2: 'value2'
  }
}

describe('AgGrid:Helpers:Common:getEnableInfo', () => {
  let rowData = getRowData()

  beforeEach(() => {
    rowData = getRowData()
  })

  it('should return true when rowData and dataField are truthy', () => {
    const dataField = 'col1'
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(true)
  })

  it('should return false when rowData is undefined', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rowData = undefined
    const dataField = 'col1'
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(false)
  })

  it('should return true when dataField is undefined', () => {
    const dataField = undefined
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(true)
  })

  it('should return false when rowData[dataField] is falsy', () => {
    rowData = { ...rowData, col1: null, col2: false, col3: 0 }
    const dataField = 'col1'
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(false)
  })

  it('should return true when rowData[dataField] is truthy', () => {
    rowData = { ...commonRowData, col1: 'value1', col2: true, col3: 42 }
    const dataField = 'col1'
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(true)
  })

  it('should return false when rowData[dataField] is an empty string', () => {
    rowData = { ...commonRowData, col1: '' }
    const dataField = 'col1'
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(false)
  })

  it('should return true when rowData[dataField] is an empty object or array', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rowData = { ...commonRowData, col1: {}, col2: [] }
    const dataField = 'col1'
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(true)
  })

  it('should return true when rowData[dataField] is a non-empty object or array', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rowData = { ...commonRowData, col1: { key: 'value' }, col2: [1, 2, 3] }
    const dataField = 'col1'
    const result = getEnableInfo(rowData, dataField)
    expect(result).toBe(true)
  })
})
