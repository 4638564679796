import { useState, useRef } from 'react'
import { Container, Tab, Nav } from 'react-bootstrap'
import cx from 'classnames'
import { getAllActionsDiff, getLastActionDiff } from '../../store/api/endpoints'
import { useReduxState } from '../../hooks'
import { sidebarSlice } from '../../store/slices'
import LoadTreeTable, { LoadTreeTableProps } from '../LoadTreeTable'
import DemandTreeTable, { DemandTreeTableProps } from '../DemandTreeTable'
import IconButton from '../IconButton'
import SettingsModal from '../SettingsModal'
import ViewAllDifferenceModal from '../ViewAllDifferenceModal'
import styles from './Sidebar.module.scss'

export default function Sidebar({ loadTreeTableRef, demandTreeTableRef }) {
  const [isHierarchyModalOpen, setIsHierarchyModal] = useState(false)
  const [isDiffModalOpen, setIsDiffModalOpen] = useState(false)
  const [sidebar, sidebarDispatch] = useReduxState(sidebarSlice.selectSlice, sidebarSlice.actions)
  const [triggerGetLastActionDiff] = getLastActionDiff.useLazyQuery()
  const [triggerGetAllActionsDiff] = getAllActionsDiff.useLazyQuery()
  const sidebarRef = useRef(null)

  function handleClickViewLastActionDiff() {
    triggerGetLastActionDiff()
  }

  function handleClickViewAllActionsDiff() {
    triggerGetAllActionsDiff()
    setIsDiffModalOpen(true)
  }

  function handleClearRowSelections() {
    loadTreeTableRef?.current?.setState((prevState) => ({ ...prevState, rowSelection: {} }))
    demandTreeTableRef?.current?.setState((prevState) => ({ ...prevState, rowSelection: {} }))
  }

  function handleClickTab(tab: 'load' | 'demand') {
    sidebarDispatch.setSelectedTab(tab)
  }

  return (
    <div ref={sidebarRef} className={cx(styles.sidebar, { [styles.isClosed]: !sidebar.isOpen })}>
      <Tab.Container activeKey={sidebar.selectedTab} defaultActiveKey="load" transition={false}>
        <Container className={styles.tabBar}>
          <Nav className={styles.tabs}>
            <Nav.Item
              className={cx(styles.tab, {
                [styles.isSelected]: sidebar.selectedTab === 'load'
              })}
              onClick={() => handleClickTab('load')}
            >
              <Nav.Link className={styles.tabLink} eventKey="load">
                Load
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              className={cx(styles.tab, {
                [styles.isSelected]: sidebar.selectedTab === 'demand'
              })}
              onClick={() => handleClickTab('demand')}
            >
              <Nav.Link className={styles.tabLink} eventKey="demand">
                Demand
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className={styles.tableControls}>
            <IconButton
              className={styles.hierarchySettingsButton}
              onClick={() => setIsHierarchyModal(true)}
            >
              <i className={cx(styles.tableControlIcon, 'fa fa-fw fa slvy-ui-icon-cog')} />
            </IconButton>
            <span className={styles.tableControlsGroup}>
              <IconButton
                className={styles.tableControl}
                tooltipText="View Last Difference"
                onClick={handleClickViewLastActionDiff}
              >
                <i className="slvy-ui-icon-diff" />
              </IconButton>
              <IconButton
                className={styles.tableControl}
                tooltipText="View All Difference"
                onClick={handleClickViewAllActionsDiff}
              >
                <i className={cx(styles.tableControlIcon, 'slvy-ui-icon-diff-all')} />
              </IconButton>
              <IconButton
                className={styles.tableControl}
                tooltipText="Filter From Map"
                onClick={() => ({})}
              >
                <i className="slvy-ui-icon-edit" />
              </IconButton>
              <IconButton
                className={styles.tableControl}
                tooltipText="Clear All Selections"
                onClick={handleClearRowSelections}
              >
                <i className="slvy-ui-icon-eraser" />
              </IconButton>
            </span>
          </div>
        </Container>
        <Tab.Content className={styles.tabContent}>
          <Tab.Pane className={styles.tabPane} eventKey="load">
            <LoadTreeTable tableRef={loadTreeTableRef} />
          </Tab.Pane>
          <Tab.Pane className={styles.tabPane} eventKey="demand">
            <DemandTreeTable tableRef={demandTreeTableRef} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SettingsModal
        isOpen={isHierarchyModalOpen}
        treeName={`${sidebar.selectedTab}Tree`}
        onClose={() => setIsHierarchyModal(false)}
      />
      <ViewAllDifferenceModal isOpen={isDiffModalOpen} onClose={() => setIsDiffModalOpen(false)} />
    </div>
  )
}
