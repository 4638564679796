import _ from 'lodash'

let totalSchema = {}

const pluginSchema = {
  title: 'Heatmap',
  type: 'object',
  format: 'objectTab',
  properties: {
    datafields: {
      type: 'array',
      format: 'HiddenTable',
      title: 'Data Fields',
      items: {
        type: 'string'
      }
    }
  }
}

const generalSchema = {
  general: {
    title: 'General',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      },
      header: {
        type: 'string',
        title: 'Header'
      },
      maximizable: {
        type: 'boolean',
        title: 'Maximizable',
        default: false,
        required: true
      },
      export: {
        type: 'boolean',
        title: 'Exportable',
        default: true,
        required: true
      },
      showNoDataToDisplay: {
        type: 'boolean',
        title: 'Show No Data To Display',
        default: true,
        required: true
      }
    }
  }
}

const titleSchema = {
  title: {
    title: 'Title',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      margin: {
        type: 'number',
        default: 15
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '18px' },
        options: {
          basic: true
        }
      },
      maxStyle: {
        type: 'object',
        title: 'Max Style',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '20px' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: 'Chart Title',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 25
      }
    }
  }
}

const subtitleSchema = {
  subtitle: {
    title: 'Subtitle',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(102,102,102,1)' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 1,
        description: 'Apply templates using {columnName}'
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: 50
      }
    }
  }
}

const exportingSchema = {
  exporting: {
    title: 'Exporting',
    type: 'object',
    properties: {
      allowHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: false
      },
      fallbackToExportServer: {
        type: 'boolean',
        required: true,
        default: true
      },
      filename: {
        type: 'string',
        default: 'chart'
      },
      libURL: {
        type: 'string',
        default: 'https://code.highcharts.com/{version}/lib'
      },
      printMaxWidth: {
        type: 'number',
        default: 780
      },
      scale: {
        type: 'number',
        default: 2
      },
      sourceHeight: {
        type: 'NullableNumber',
        default: ''
      },
      sourceWidth: {
        type: 'NullableNumber',
        default: ''
      },
      url: {
        type: 'string',
        default: 'https://export.highcharts.com'
      },
      width: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const dataSchema = {
  data: {
    title: 'Data',
    type: 'object',
    properties: {
      yAxis: {
        type: 'string',
        title: 'y Axis',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      },
      xAxis: {
        type: 'string',
        title: 'x Axis',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      },
      value: {
        type: 'string',
        title: 'Value',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      },
      labelValue: {
        type: 'string',
        title: 'Label Value',
        watch: {
          ds: 'datafields'
        },
        enumSource: 'ds'
      }
    }
  }
}

const tooltipSchema = {
  tooltip: {
    title: 'Tooltip',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      backgroundColor: {
        type: 'string',
        format: 'Color',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      borderRadius: {
        type: 'number',
        default: 3
      },
      borderWidth: {
        type: 'number',
        default: 1,
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      followPointer: {
        type: 'boolean',
        required: true,
        default: false
      },
      followTouchMove: {
        type: 'NullableBoolean',
        default: null
      },
      footerFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      headerFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      hideDelay: {
        type: 'number',
        default: 500
      },
      padding: {
        type: 'number',
        default: 8
      },
      pointFormat: {
        type: 'string',
        default: ''
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: true
      },
      style: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px'
        },
        options: {
          basic: true
        }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      }
    }
  }
}

const colorAxis = {
  colorAxis: {
    title: 'Color Axis',
    type: 'object',
    properties: {
      min: {
        title: 'Min',
        type: 'number',
        format: 'range',
        minimum: 0,
        maximum: 256,
        default: 0
      },
      minColor: {
        type: 'string',
        format: 'Color',
        title: 'Min Color',
        default: '#ffffff',
        options: {
          basic: true
        }
      },
      maxColor: {
        type: 'string',
        format: 'Color',
        title: 'Max Color',
        default: '#7eb6ec',
        options: {
          basic: true
        }
      }
    }
  }
}

const chartSchema = {
  chart: {
    title: 'Chart',
    type: 'object',
    properties: {
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: '#ffffff',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      showCredits: {
        type: 'boolean',
        title: 'Show credits?',
        default: false,
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 9
      },
      plotBackgroundColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      plotBorderWidth: {
        title: 'Plot Border Width',
        type: 'number',
        format: 'range',
        minimum: 1,
        maximum: 150,
        default: 1
      },
      marginTop: {
        title: 'Margin Top',
        type: 'number',
        format: 'range',
        minimum: 0,
        maximum: 150,
        default: 40
      },
      marginBottom: {
        title: 'Margin Bottom',
        type: 'number',
        format: 'range',
        minimum: 0,
        maximum: 150,
        default: 80
      },
      emptyText: {
        type: 'string',
        title: 'No Data Text',
        default: 'No Data to Display',
        options: {
          basic: true
        },
        propertyOrder: 7
      }
    }
  }
}

const legend = {
  legend: {
    title: 'Legend',
    type: 'object',
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'right',
        required: true
      },
      layout: {
        type: 'string',
        enum: ['vertical', 'horizontal'],
        default: 'vertical',
        required: true
      },
      margin: {
        minimum: 0,
        maximum: 150,
        title: 'Margin',
        type: 'number',
        format: 'range',
        default: 0
      },
      verticalAlign: {
        type: 'string',
        enum: ['top', 'middle', 'bottom'],
        default: 'top',
        required: true
      },
      y: {
        minimum: 0,
        maximum: 150,
        title: 'Y',
        type: 'number',
        format: 'range',
        default: 25
      },
      symbolHeight: {
        minimum: 0,
        maximum: 500,
        title: 'Symbol Height',
        type: 'number',
        format: 'range',
        default: 280
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      }
    }
  }
}

const xAxisSchema = {
  xAxis: {
    title: 'X Axis',
    type: 'array',
    default: [{ id: '0' }],
    items: {
      type: 'object',
      title: 'xAxis',
      options: {
        collapsed: true,
        sortProperties: 'key',
        splitProperties: true
      },
      properties: {
        endOnTick: {
          type: 'boolean',
          required: true,
          default: false
        },
        gridLineWidth: {
          type: 'number',
          default: 0
        },
        labels: {
          type: 'object',
          title: 'Labels',
          propertyOrder: 1001,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['', 'left', 'center', 'right'],
              default: '',
              required: true
            },
            autoRotation: {
              type: 'array',
              format: 'table',
              items: {
                title: 'angle',
                type: 'number'
              },
              default: [-45],
              options: {
                collapsed: true
              },
              propertyOrder: 1000
            },
            autoRotationLimit: {
              type: 'number',
              default: 80
            },
            enabled: {
              type: 'boolean',
              required: true,
              default: true
            },
            format: {
              type: 'string'
            },
            padding: {
              type: 'number',
              default: 5
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            staggerLines: {
              type: 'NullableNumber',
              default: ''
            },
            step: {
              type: 'NullableNumber',
              default: ''
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
            },
            useHTML: {
              type: 'boolean',
              required: true,
              default: false
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            },
            zIndex: {
              type: 'number',
              default: 7
            }
          }
        },
        lineWidth: {
          type: 'number',
          default: 1
        },
        maxPadding: {
          type: 'number',
          default: 0.01
        },
        minPadding: {
          type: 'number',
          default: 0.01
        },
        plotBands: {
          type: 'array',
          title: 'Plot Bands',
          propertyOrder: 1002,
          options: {
            collapsed: true
          },
          items: {
            type: 'object',
            title: 'Plot Band',
            properties: {
              bandField: {
                type: 'string',
                title: 'Band Field',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds'
              },
              bandFieldAsLabel: {
                title: 'Band Field as Label',
                type: 'boolean',
                required: true,
                default: false
              },
              borderColor: {
                type: 'string',
                format: 'NullColor'
              },
              borderWidth: {
                type: 'number',
                default: 0
              },
              color: {
                type: 'string',
                format: 'NullColor'
              },
              from: {
                type: 'NullableNumber',
                default: ''
              },
              id: {
                type: 'string',
                default: ''
              },
              label: {
                type: 'object',
                title: 'Label',
                propertyOrder: 1001,
                options: {
                  collapsed: true
                },
                properties: {
                  align: {
                    type: 'string',
                    enum: ['left', 'center', 'right'],
                    default: 'center',
                    required: true
                  },

                  rotation: {
                    type: 'number',
                    default: 0
                  },

                  style: {
                    type: 'object',
                    format: 'Style',
                    default: {}
                  },
                  text: {
                    type: 'string',
                    default: ''
                  },
                  textAlign: {
                    type: 'string',
                    enum: ['', 'left', 'center', 'right'],
                    default: '',
                    required: true
                  },
                  useHTML: {
                    type: 'boolean',
                    required: true,
                    default: false
                  },
                  verticalAlign: {
                    type: 'string',
                    enum: ['top', 'middle', 'bottom'],
                    default: 'top',
                    required: true
                  },
                  x: {
                    type: 'NullableNumber',
                    default: ''
                  },
                  y: {
                    type: 'NullableNumber',
                    default: ''
                  }
                }
              },
              to: {
                type: 'NullableNumber',
                default: ''
              },
              zIndex: {
                type: 'NullableNumber',
                default: ''
              }
            }
          }
        },
        plotLines: {
          type: 'array',
          title: 'Plot Lines',
          propertyOrder: 1003,
          options: {
            collapsed: true
          },
          items: {
            type: 'object',
            title: 'Plot Line',
            properties: {
              plotLineField: {
                type: 'string',
                title: 'Line Field',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds'
              },
              plotLineFieldAsLabel: {
                title: 'Line Field as Label',
                type: 'boolean',
                required: true,
                default: false
              },
              value: {
                title: 'Value',
                type: 'number',
                default: null
              },
              width: {
                title: 'Line Width',
                type: 'number',
                default: 1
              },
              color: {
                title: 'Line Color',
                type: 'string',
                format: 'Color',
                default: '#e6ebf5'
              },
              dashStyle: {
                title: 'Dash Style',
                type: 'string',
                enum: [
                  'Solid',
                  'ShortDash',
                  'ShortDot',
                  'ShortDashDot',
                  'ShortDashDotDot',
                  'Dot',
                  'Dash',
                  'LongDash',
                  'DashDot',
                  'LongDashDot',
                  'LongDashDotDot'
                ],
                default: 'Solid',
                required: true
              },
              label: {
                type: 'object',
                title: 'Label',
                propertyOrder: 1001,
                options: {
                  collapsed: true
                },
                properties: {
                  align: {
                    type: 'string',
                    enum: ['left', 'center', 'right'],
                    default: 'center',
                    required: true
                  },
                  rotation: {
                    type: 'number',
                    default: 90
                  },
                  style: {
                    type: 'object',
                    format: 'Style',
                    default: {}
                  },
                  text: {
                    type: 'string',
                    default: ''
                  },
                  textAlign: {
                    type: 'string',
                    enum: ['', 'left', 'center', 'right'],
                    default: '',
                    required: true
                  },
                  useHTML: {
                    type: 'boolean',
                    required: true,
                    default: false
                  },
                  verticalAlign: {
                    type: 'string',
                    enum: ['top', 'middle', 'bottom'],
                    default: 'top',
                    required: true
                  },
                  x: {
                    type: 'NullableNumber',
                    default: ''
                  },
                  y: {
                    type: 'NullableNumber',
                    default: ''
                  }
                }
              }
            }
          }
        },
        showLastLabel: {
          type: 'boolean',
          required: true,
          default: true
        },
        startOnTick: {
          type: 'boolean',
          required: true,
          default: false
        },
        tickWidth: {
          type: 'number',
          default: 1
        },
        title: {
          type: 'object',
          title: 'Title',
          propertyOrder: 1004,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['low', 'middle', 'high'],
              default: 'middle',
              required: true
            },
            margin: {
              type: 'NullableNumber',
              default: ''
            },
            offset: {
              type: 'NullableNumber',
              default: ''
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)' }
            },
            text: {
              type: 'string',
              default: '',
              description: 'Apply templates using {columnName}'
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            }
          }
        }
      }
    }
  }
}

const yAxisSchema = {
  yAxis: {
    title: 'Y Axis',
    type: 'array',
    default: [{ id: '0' }],
    items: {
      type: 'object',
      title: 'yAxis',
      options: {
        collapsed: true,
        sortProperties: 'key',
        splitProperties: true
      },
      properties: {
        angle: {
          type: 'number',
          default: 0
        },
        endOnTick: {
          type: 'boolean',
          required: true,
          default: true
        },
        gridLineInterpolation: {
          type: 'string',
          enum: ['', 'circle', 'polygon'],
          default: '',
          required: true
        },
        gridLineWidth: {
          type: 'number',
          default: 1
        },
        labels: {
          type: 'object',
          title: 'Labels',
          propertyOrder: 1001,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['left', 'center', 'right'],
              default: 'right',
              required: true
            },
            autoRotation: {
              type: 'array',
              format: 'table',
              items: {
                title: 'angle',
                type: 'number'
              },
              default: [-45],
              options: {
                collapsed: true
              },
              propertyOrder: 1000
            },
            autoRotationLimit: {
              type: 'number',
              default: 80
            },
            enabled: {
              type: 'boolean',
              required: true,
              default: true
            },
            format: {
              type: 'string'
            },
            padding: {
              type: 'number',
              default: 5
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 0
            },
            staggerLines: {
              type: 'NullableNumber',
              default: ''
            },
            step: {
              type: 'NullableNumber',
              default: ''
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)', fontSize: '11px' }
            },
            useHTML: {
              type: 'boolean',
              required: true,
              default: false
            },
            x: {
              type: 'NullableNumber',
              default: ''
            },
            y: {
              type: 'number',
              default: 3
            },
            zIndex: {
              type: 'number',
              default: 7
            }
          }
        },
        lineWidth: {
          type: 'number',
          default: 0
        },
        maxColor: {
          type: 'string',
          format: 'Color',
          default: '#003399'
        },
        maxPadding: {
          type: 'number',
          default: 0.05
        },
        minColor: {
          type: 'string',
          format: 'Color',
          default: '#e6ebf5'
        },
        minPadding: {
          type: 'number',
          default: 0.05
        },
        reversedStacks: {
          type: 'boolean',
          required: true,
          default: true
        },
        showLastLabel: {
          type: 'NullableBoolean',
          default: null
        },
        startOnTick: {
          type: 'boolean',
          required: true,
          default: true
        },
        tickWidth: {
          type: 'number',
          default: 0
        },
        title: {
          type: 'object',
          title: 'Title',
          propertyOrder: 1002,
          options: {
            collapsed: true
          },
          properties: {
            align: {
              type: 'string',
              enum: ['low', 'middle', 'high'],
              default: 'middle',
              required: true
            },
            margin: {
              type: 'number',
              default: 40
            },
            offset: {
              type: 'NullableNumber',
              default: ''
            },
            reserveSpace: {
              type: 'boolean',
              required: true,
              default: true
            },
            rotation: {
              type: 'number',
              default: 270
            },
            style: {
              type: 'object',
              format: 'Style',
              default: { color: 'rgba(102,102,102,1)' }
            },
            text: {
              type: 'string',
              default: 'Values',
              description: 'Apply templates using {columnName}'
            },
            x: {
              type: 'number',
              default: 0
            },
            y: {
              type: 'NullableNumber',
              default: ''
            }
          }
        },
        plotLines: {
          type: 'array',
          title: 'Plot Lines',
          propertyOrder: 1003,
          options: {
            collapsed: true
          },
          items: {
            type: 'object',
            title: 'Plot Line',
            properties: {
              plotLineField: {
                type: 'string',
                title: 'Line Field',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds'
              },
              value: {
                title: 'Value',
                type: 'number',
                default: null
              },
              width: {
                title: 'Line Width',
                type: 'number',
                default: 1
              },
              color: {
                title: 'Line Color',
                type: 'string',
                format: 'Color',
                default: '#e6ebf5'
              },
              dashStyle: {
                title: 'Dash Style',
                type: 'string',
                enum: [
                  'Solid',
                  'ShortDash',
                  'ShortDot',
                  'ShortDashDot',
                  'ShortDashDotDot',
                  'Dot',
                  'Dash',
                  'LongDash',
                  'DashDot',
                  'LongDashDot',
                  'LongDashDotDot'
                ],
                default: 'Solid',
                required: true
              },
              label: {
                type: 'object',
                title: 'Label',
                propertyOrder: 1001,
                options: {
                  collapsed: true
                },
                properties: {
                  align: {
                    type: 'string',
                    enum: ['left', 'center', 'right'],
                    default: 'center',
                    required: true
                  },
                  rotation: {
                    type: 'number',
                    default: 0
                  },
                  style: {
                    type: 'object',
                    format: 'Style',
                    default: {}
                  },
                  text: {
                    type: 'string',
                    default: ''
                  },
                  textAlign: {
                    type: 'string',
                    enum: ['', 'left', 'center', 'right'],
                    default: '',
                    required: true
                  },
                  useHTML: {
                    type: 'boolean',
                    required: true,
                    default: false
                  },
                  verticalAlign: {
                    type: 'string',
                    enum: ['top', 'middle', 'bottom'],
                    default: 'top',
                    required: true
                  },
                  x: {
                    type: 'NullableNumber',
                    default: ''
                  },
                  y: {
                    type: 'NullableNumber',
                    default: ''
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

const plotOptionsSchema = {
  plotOptions: {
    title: 'Plot Options',
    type: 'object',
    options: {
      splitProperties: false
    },
    properties: {
      heatmap: {
        type: 'object',
        options: {
          collapsed: true,
          splitProperties: false
        },
        properties: {
          dataLabels: {
            type: 'object',
            properties: {
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'center',
                required: true
              },
              animation: {
                type: 'object',
                properties: {
                  defer: {
                    type: 'number',
                    default: undefined
                  }
                }
              },
              backgroundColor: {
                type: 'string',
                format: 'NullColor'
              },
              borderColor: {
                type: 'string',
                format: 'NullColor'
              },
              borderRadius: {
                type: 'number',
                default: 0
              },
              borderWidth: {
                type: 'number',
                default: 0
              },
              className: {
                type: 'string',
                default: ''
              },
              color: {
                type: 'string',
                format: 'NullColor'
              },
              crop: {
                type: 'boolean',
                required: true,
                default: true
              },
              defer: {
                type: 'boolean',
                required: true,
                default: true
              },
              enabled: {
                type: 'boolean',
                required: true,
                default: true,
                options: {
                  basic: true
                },
                propertyOrder: 1
              },
              enabledBasedOn: {
                type: 'string',
                title: 'Enabled Based On',
                watch: {
                  ds: 'datafields'
                },
                enumSource: 'ds',
                options: {
                  basic: true
                },
                propertyOrder: 1
              },
              inside: {
                type: 'NullableBoolean',
                default: true
              },
              overflow: {
                type: 'string',
                enum: ['justify', 'allow'],
                default: 'justify',
                required: true,
                description:
                  'To display data labels outside the plot area, set crop to false and overflow to "allow"'
              },
              padding: {
                type: 'number',
                default: 5,
                description:
                  'When either the borderWidth or the backgroundColor is set, this is the padding within the box.'
              },
              position: {
                type: 'string',
                enum: ['center', 'right'],
                default: 'center'
              },
              rotation: {
                type: 'number',
                default: 0
              },
              shadow: {
                type: 'boolean',
                default: false,
                required: true
              },
              shape: {
                type: 'string',
                enum: ['square', 'callout', 'circle'],
                default: false
              },
              style: {
                type: 'string',
                default: undefined
              },
              textPath: {
                type: 'object',
                properties: {
                  attributes: {
                    type: 'string',
                    dafault: undefined
                  },
                  enabled: {
                    type: 'boolean',
                    dafault: undefined,
                    required: false
                  }
                }
              },
              useHTML: {
                type: 'boolean',
                required: true,
                default: false
              },
              verticalAlign: {
                type: 'string',
                enum: ['', 'top', 'middle', 'bottom'],
                default: '',
                required: true
              },
              x: {
                type: 'number',
                default: 0
              },
              y: {
                type: 'number',
                default: undefined
              },
              z: {
                type: 'number',
                default: 6
              }
            }
          }
        }
      }
    }
  }
}

export default () => {
  if (_.isEmpty(totalSchema)) {
    _.assign(pluginSchema.properties, generalSchema)
    _.assign(pluginSchema.properties, colorAxis)
    _.assign(pluginSchema.properties, chartSchema)
    _.assign(pluginSchema.properties, legend)
    _.assign(pluginSchema.properties, titleSchema)
    _.assign(pluginSchema.properties, subtitleSchema)
    _.assign(pluginSchema.properties, dataSchema)
    _.assign(pluginSchema.properties, tooltipSchema)
    _.assign(pluginSchema.properties, exportingSchema)
    _.assign(pluginSchema.properties, xAxisSchema)
    _.assign(pluginSchema.properties, yAxisSchema)
    _.assign(pluginSchema.properties, plotOptionsSchema)

    totalSchema = pluginSchema
  }
  return totalSchema
}
