export default {
  title: 'Matrix-Image',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'Matrix-Image',
  description: '',
  iconClass: 'fa-image',
  color: 'lazur-bg',
  group: 'Views'
}
