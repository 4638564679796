import { GetFieldConfigsValuesProps } from '../../../types'
import { getMultiRowSelectedResults } from '../selection'
import {
  resultObj,
  resultObjNoSelectedRows,
  selectionFieldConfigs,
  selectedRows as mockSelectedRows
} from './mocks/index'

const getMultiRowSelectedResultParams = (): Required<GetFieldConfigsValuesProps> => ({
  fieldConfigs: selectionFieldConfigs,
  rows: mockSelectedRows
})

describe('AgGrid:Helpers:Selection:getMultiRowSelectedResults', () => {
  let params = getMultiRowSelectedResultParams()

  beforeEach(() => {
    params = getMultiRowSelectedResultParams()
  })
  it('should return an object including fieldConfigs variables +1 number of parameters', () => {
    const result = getMultiRowSelectedResults(params)

    mockSelectedRows.forEach((row) => {
      expect(Object.keys(result).length).toEqual(Object.keys(row).length + 1)
    })
  })
  it('should return an object including fieldConfigs variables and selectedRowCount as keys of the object', () => {
    const result = getMultiRowSelectedResults(params)

    expect(result).toEqual(resultObj)
  })
  it('should return an object including fieldConfigs variables values as null and selectedRowCount as 0 if there is no selected rows', () => {
    params.rows = []
    const result = getMultiRowSelectedResults(params)
    expect(result).toMatchObject(resultObjNoSelectedRows)
  })
  it('should return selectedRowCount value equals to number of selected rows', () => {
    const result = getMultiRowSelectedResults(params)
    expect(result.selectedRowCount).toEqual(mockSelectedRows.length)
  })
  it('should only return selectedRowCount variable if fieldConfigs is an empty array', () => {
    params.fieldConfigs = []

    const result = getMultiRowSelectedResults(params)
    expect(result).toEqual({ selectedRowCount: mockSelectedRows.length })
  })
})
