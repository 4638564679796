Ext.define('MDM.src.view.globalapp.Panel.Dpi.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.globalapp-dpi-mainviewmodel',
  stores: {
    DPITreeStore: {
      type: 'tree',
      model: 'MDM.src.view.globalapp.Panel.Dpi.model.DPITree',
      autoLoad: false,
      root: {
        expanded: true,
        text: 'DPI Families',
        iconCls: 'fa fa-sitemap',
        id: '0',
        children: [],
        loaded: true
      },
      listeners: {}
    },
    DpiSegmentStore: {
      model: 'MDM.src.view.globalapp.Panel.Dpi.model.DpiSegment',
      autoLoad: false,
      autoSync: false,
      bindTo: 'bindTodfcStore',
      listeners: {
        load: 'onDpiSegmentStoreLoad',
        filterchange: 'onDpiSegmentStoreLoad'
      }
    },
    DpiProductGroupStore: {
      model: 'MDM.src.view.globalapp.Panel.Dpi.model.ProductGroup',
      autoLoad: false,
      bindTo: 'bindTodfcStore',
      autoSync: false,
      filters: [
        {
          property: 'DPISegmentID',
          value: '{DPISegmentID}',
          exactMatch: true
        }
      ],
      listeners: {
        filterchange: 'onDpiProductGrouptStoreFilterChange',
        load: 'onDpiProductGrouptStoreFilterChange'
      }
    },
    DPIFamilyStore: {
      model: 'MDM.src.view.globalapp.Panel.Dpi.model.DPIFamily',
      autoLoad: false,
      autoSync: false,
      bindTo: 'bindTodfcStore',
      filters: [
        {
          property: 'ProductGroupID',
          value: '{ProductGroupID}',
          exactMatch: true
        },
        {
          property: 'DPISegmentID',
          value: '{DPISegmentID}',
          exactMatch: true
        }
      ]
    }
  },
  formulas: {
    DPISegmentID: {
      bind: {
        bindTo: '{currentDPISegmentRecord}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('DPISegmentID') : ''
      }
    },
    DPISegment: {
      bind: {
        bindTo: '{currentDPISegmentRecord}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('DPISegment') : ''
      }
    },
    ProductGroupID: {
      bind: {
        bindTo: '{currentProductGroupRecord}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('ProductGroupID') : ''
      }
    },
    ProductGroup: {
      bind: {
        bindTo: '{currentProductGroupRecord}',
        deep: true
      },
      get: function (rec) {
        return rec ? rec.get('ProductGroup') : ''
      }
    }
  },
  data: {
    currentDPISegmentRecord: false,
    currentProductGroupRecord: false
  }
})
