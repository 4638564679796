Ext.define('MDM.Btl.edit.grid.Manuel', {
  extend: 'Ext.grid.Panel',
  xtype: 'btl-edit-grid-manuel',
  iconCls: 'fa fa-home',
  title: 'Manual',
  stateColumns: [],
  reference: 'manuelEdit',
  config: {
    cfgData: null,
    readOnly: true,
    hideReadOnlyCol: false,
    startCol: 0
  },
  layout: 'border',
  lockedGridConfig: {
    width: 400,
    split: true,
    listeners: {
      resize: 'onResizeLock'
    }
  },
  listeners: {
    beforeedit: 'onBeforeEdit',
    select: 'onSelectionChange'
  },
  viewConfig: {
    getRowClass: function (record, rowIndex, rowParams, store) {
      var css = ' '
      if (!record.get('hasValue')) {
        css = 'isZero '
      }
      if (record.get('IsApplied')) {
        return css + ' IsApplied'
      } else if (record.get('IsSimulated')) {
        return css + ' IsSimulated'
      }
      if (this.ownerGrid.getReadOnly()) {
        return css + ' isZero'
      }
      return css + ''
    }
  },
  selModel: {
    type: 'spreadsheet',
    columnSelect: false,
    checkboxSelect: true,
    showHeaderCheckbox: false,
    pruneRemoved: true,
    extensible: 'both',
    rowNumbererHeaderWidth: 1,
    listeners: {
      selectionchange: 'onSelectionChange',
      beforeselect: function (el, record) {
        if (record.get('IsApplied')) {
          return false
        }
        return record.get('hasValue')
      }
    }
  },
  plugins: [
    {
      ptype: 'clipboard',
      system: 'raw',
      formats: {
        raw: {
          put: 'putTextData'
        }
      },
      putTextData: function (data, format) {
        var view = this.getCmp().getView(),
          selModel = view.getSelectionModel(),
          selected = selModel.getSelected(),
          BTLType = selected.startCell.record.get('BTLType'),
          IsApplied = selected.startCell.record.get('IsApplied'),
          isEditable = selected.startCell.record.get('IsEditable'),
          startCol = this.cmp.getStartCol()
        if (this.cmp.getReadOnly()) {
          this.cmp.lockToast()
          return false
        }
        if (IsApplied) {
          this.cmp.lockToast()
          return false
        }
        if (!isEditable) {
          this.cmp.lockToast()
          return false
        }
        if (
          BTLType == 'X' ||
          BTLType == 'D' ||
          BTLType == 'P' ||
          BTLType == 'N' ||
          BTLType == 'R'
        ) {
          this.putCellData(data, format)
          return true
        }
        var v = parseInt(startCol) + 1
        if (BTLType == 'I' && selected.startCell.colIdx <= v) {
          this.putCellData(data, format)
          return true
        } else {
          this.cmp.lockToast()
          return false
        }
      }
    },
    'gridfilters',
    {
      ptype: 'cellediting',
      clicksToEdit: 2
    }
  ],
  initComponent: function () {
    this.columns = this.buildColumns()
    this.callParent()
  },
  lockToast: function () {
    var t = Ext.toast({
      html: 'This record is not editable.',
      title: 'Warning',
      width: 300,
      align: 'tr',
      iconCls: 'fa fa-check',
      closable: true
    })
  },
  features: [
    {
      ftype: 'groupingsummary',
      groupHeaderTpl: ['{columnName}: {name} ({[values.children.length]})'],
      hideGroupedHeader: false,
      startCollapsed: false
    },
    {
      ftype: 'summary',
      dock: 'bottom'
    }
  ],
  buildColumns: function () {
    var me = this,
      hideReadOnlyCol = me.getHideReadOnlyCol(),
      readOnly = me.getReadOnly()
    return MDM.Btl.Column.build(
      [
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          text: '',
          menuText: 'Action - Info',
          dataIndex: 'IsApplied',
          tooltip: 'View BTL Info',
          locked: true,
          resizable: false,
          width: 30,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-circle',
              tooltip: 'View BTL Info',
              isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                if (grid.ownerGrid.getReadOnly()) return true
                return !rec.get('IsEditable')
              },
              getClass: function (v, meta, rec) {
                if (rec.get('IsEditable')) {
                  return 'fa fa-info-circle'
                }
                return ''
              },
              handler: 'showEditPanel'
            }
          ]
        },
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          tooltip: 'Simulate BTL',
          text: '',
          menuText: 'Action - Refresh',
          dataIndex: 'IsApplied',
          locked: true,
          resizable: false,
          width: 30,
          align: 'center',
          tdCls: 'refresh',
          items: [
            {
              iconCls: 'fa fa-refresh',
              tooltip: 'Simulate BTL',
              handler: 'onEditPreCal',
              isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                if (grid.ownerGrid.getReadOnly()) return true
                if (!rec.get('IsEditable')) return true
                if (!rec.get('hasValue')) {
                  return true
                }
                return rec.get('IsApplied')
              }
            }
          ]
        },
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          text: '',
          menuText: 'Action - Apply',
          dataIndex: 'IsApplied',
          tooltip: 'Apply BTL',
          locked: true,
          resizable: false,
          width: 30,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-bolt',
              tooltip: 'Apply BTL',
              isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                if (grid.ownerGrid.getReadOnly()) return true
                if (!rec.get('IsEditable')) return true
                if (!rec.get('hasValue')) {
                  return true
                }
                return rec.get('IsApplied')
              },
              getClass: function (v, meta, rec) {
                if (rec.get('IsApplied')) {
                  return 'fa fa-check'
                }
                return 'fa fa-bolt'
              },
              handler: 'onApply'
            }
          ]
        },
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          text: '',
          menuText: 'Action - Delete',
          dataIndex: 'IsApplied',
          locked: true,
          resizable: false,
          width: 30,
          tooltip: 'Delete BTL',
          align: 'center',
          items: [
            {
              iconCls: 'cell-editing-delete-row',
              tooltip: 'Delete BTL',
              handler: 'onEditDelete',
              isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                if (grid.ownerGrid.getReadOnly()) return true
                return !rec.get('IsEditable')
              }
            }
          ]
        },
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          text: '',
          menuText: 'Action - Clone',
          dataIndex: 'IsApplied',
          locked: true,
          resizable: false,
          width: 30,
          tooltip: 'Clone BTL',
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-clone',
              tooltip: 'Clone BTL',
              handler: 'onCloneBTL',
              isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                if (grid.ownerGrid.getReadOnly()) return true
                return !rec.get('IsEditable')
              }
            }
          ]
        },
        {
          text: 'BTL Type',
          dataIndex: 'BTLType',
          tooltip: 'BTL Type',
          locked: true,
          width: 60,
          filter: 'list'
        },
        {
          text: 'BTL Entry ',
          dataIndex: 'Title',
          tooltip: 'BTL Entry',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'Reason',
          dataIndex: 'Reason',
          tooltip: 'Reason',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'Material Code',
          dataIndex: 'MaterialCode',
          tooltip: 'Material Code',
          locked: true,
          width: 200,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: 'Search for...'
            }
          }
        },
        {
          text: 'Material Description',
          dataIndex: 'MaterialDescription',
          tooltip: 'Material Description',
          locked: true,
          width: 200,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: 'Search for...'
            }
          }
        },
        {
          text: 'Material Type',
          tooltip: 'Material Type',
          dataIndex: 'MaterialType',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'From Subregion',
          tooltip: 'From Subregion',
          dataIndex: 'FromRegion',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'To Subregion',
          tooltip: 'To Subregion',
          dataIndex: 'ToRegion',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'Supply Source',
          tooltip: 'Supply Source',
          dataIndex: 'SupplySource',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'Comment',
          tooltip: 'Comment',
          dataIndex: 'Comment',
          locked: true,
          width: 200,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: 'Search for...'
            }
          }
        },
        {
          text: 'Created By',
          tooltip: 'Created By',
          dataIndex: 'CreatedBy',
          locked: true,
          width: 200,
          filter: 'list'
        },
        {
          text: 'Created At',
          tooltip: 'Created At',
          dataIndex: 'CreatedAt',
          xtype: 'datecolumn',
          format: 'F d, Y G:i:s',
          locked: true,
          width: 200
        },
        {
          text: 'Applied At',
          tooltip: 'Applied At',
          xtype: 'datecolumn',
          format: 'F d, Y G:i:s',
          dataIndex: 'AppliedAt',
          locked: true,
          width: 200
        },
        {
          menuDisabled: true,
          sortable: false,
          xtype: 'actioncolumn',
          locked: false,
          resizable: false,
          width: 1,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-pencil ',
              isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                return !rec.get('IsEditable')
              },
              getClassp: function (v, meta, rec) {
                if (rec.get('IsEditable')) {
                  return 'fa fa-pencil'
                }
                return ''
              },
              handler: 'onAddNew'
            }
          ]
        }
      ],
      hideReadOnlyCol
    )
  },
  updateHideReadOnlyCol: function (c) {
    var me = this
    if (me.isConfiguring) return c
    var column = this.buildColumns()
    this.hideReadOnlyCol = c
    me.reconfigure(me.store, column)
    return c
  },
  applyCfgData: function (c) {
    var me = this
    if (!c) return c
    var data = c ? c : []
    var columns = me.getColumnManager().getColumns()
    me.stateColumns = []
    columns.forEach(function (e) {
      me.stateColumns[e.dataIndex] = e.isHidden()
    })
    var column = me.buildColumns()
    me.store.loadData(data)
    me.reconfigure(me.store, column)
  }
})
