Ext.define('MDM.src.view.materialedit.MainControllor', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.materialedit-maincontrollor',
  updated: false,
  onAfterRender: function () {
    var me = this

    var v = me.getView()

    var vm = me.getViewModel()

    var isNew = v.isNew

    var store = vm.getStore('DPIRelationStore')

    var ResourceofProductStore = vm.getStore('ResourceofProductStore')

    var crec = v.crec

    var count
    if (!isNew) {
      count = crec.get('HasNumberOfBomElemenet')
      vm.set('crec', crec)
      vm.set('rec', crec)
      vm.set('editType', crec.get('DataSource'))
      v.setLoading('Loading Material Data Please Wait....')
      var currentRecord = Ext.create('MDM.src.view.materialedit.model.Material', {
        ID: crec.get('ID')
      })
      currentRecord.load({
        failure: function () {
          v.setLoading(false)
          Ext.defer(function () {
            v.close()
          }, 1000)
        },
        success: function (record) {
          me.loadForm(record)
          vm.set('record', record)
          var saveBtn = me.lookupReference('saveButton')
          store.getProxy().setExtraParam('MaterialID', crec.get('MaterialID'))
          ResourceofProductStore.load()
          ResourceofProductStore.getProxy().setExtraParam('MaterialID', crec.get('MaterialID'))
          store.load({
            callback: function () {
              v.setLoading(false)
            }
          })
          Ext.defer(function () {
            saveBtn.setDisabled(true)
          }, 50)
        }
      })
    } else {
      vm.set('editType', 'User')
      vm.set('isNew', true)
      var record = Ext.create('MDM.src.view.materialedit.model.Material', {
        isNew: true
      })
      me.loadForm(record)
      vm.set('record', record)
      var saveBtn = me.lookupReference('saveButton')
      Ext.defer(function () {
        saveBtn.setDisabled(true)
      }, 50)
    }
  },
  onDataChange: function () {
    var me = this

    var saveBtn = me.lookupReference('saveButton')
    saveBtn.setDisabled(false)
  },
  loadForm: function (record) {
    var me = this

    var formPanel = me.lookupReference('materialFrom')

    var form = formPanel.getForm()
    form.loadRecord(record)
  },
  onBeforeSync: function () {
    var me = this

    var view = me.getView()
  },
  onAddDPI: function () {
    var me = this

    var view = me.getView()

    var vm = me.getViewModel()

    var grid = me.lookupReference('dpilist')

    var crec = vm.get('crec')

    var store = vm.getStore('DPIRelationStore')
    var newRec = Ext.create('MDM.src.view.materialedit.model.Dpi', {
      MaterialID: crec.get('MaterialID'),
      GU: crec.get('GU'),
      DPIFamilyID: null
    })
    store.add(newRec)
    grid.findPlugin('cellediting').startEdit(newRec, 0)
  },
  onAddCartridge: function () {
    var me = this

    var view = me.getView()

    var vm = me.getViewModel()

    var grid = me.lookupReference('Cartridgelist')

    var crec = vm.get('crec')

    var DPISelectedRecord = vm.get('dpilist').selection
    DPISelectedRecord.dirty = true
    var store = grid.store

    var newRec = Ext.create('MDM.src.view.materialedit.model.Cartridge', {
      DPIFamilyID: null
    })
    DPISelectedRecord.cartridges().add(newRec)
    grid.findPlugin('cellediting').startEdit(newRec, 0)
  },
  onAddResource: function () {
    var me = this

    var view = me.getView()

    var vm = me.getViewModel()

    var grid = me.lookupReference('ResourceGrid')

    var crec = vm.get('crec')

    var store = vm.getStore('ResourceofProductStore')
    var newRec = Ext.create('MDM.src.view.materialedit.model.ResourceofProduct', {
      MaterialID: crec.get('MaterialID')
    })
    store.add(newRec)
    grid.findPlugin('cellediting').startEdit(newRec, 0)
  },
  onRemoveClick: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var viewModel = me.getViewModel()

    var store = viewModel.getStore('DPIRelationStore')
    store.remove(record)
    viewModel.set('isStoreDirty', true)
  },
  onRemoveResourceClick: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var viewModel = me.getViewModel()

    var store = viewModel.getStore('ResourceofProductStore')

    store.remove(record)
    viewModel.set('isStoreDirty', true)
  },

  onCartridgeRemoveClick: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this

    var viewModel = me.getViewModel()

    var DPISelectedRecord = viewModel.get('dpilist').selection
    DPISelectedRecord.dirty = true

    var store = grid.store
    store.remove(record)
    viewModel.set('isStoreDirty', true)
  },

  onFormDirty: function () {
    var saveButton = this.lookupReference('saveButton')
    saveButton.enable()
  },
  onBomList: function () {
    var me = this
    var record = this.getViewModel().get('record')
    var win = Ext.create('MDM.src.view.BomEdit.Main', {
      record: record,
      searchCollapsed: false,
      closable: false
    })
    win.on('updated', function (view, store) {})
    win.show()
  },
  onWrite: function () {
    var me = this

    var view = me.getView()

    var list = me.lookup('list')
    this.updated = true
  },
  onBeforeDestroy: function () {
    var me = this

    var viewModel = me.getViewModel()

    var view = me.getView()
    if (me.updated) view.fireEvent('updated', view)
  },
  onCommitChanges: function () {
    var me = this

    var viewModel = me.getViewModel()

    var view = me.getView()

    var store = viewModel.getStore('DPIRelationStore')
    var ResourceofProductStore = viewModel.getStore('ResourceofProductStore')
    var list = me.lookup('list')
    var Cartridgelist = me.lookup('Cartridgelist')
    var saveBtn = me.lookupReference('saveButton')
    view.setLoading('Saving DPI data please wait....!')
    ResourceofProductStore.sync()
    store.sync({
      callback: function () {
        view.setLoading(false)
      },
      success: function () {
        Cartridgelist.store.commitChanges()
        var t = Ext.toast({
          html: 'DPI data successfully saved',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        me.updated = true
        view.setLoading(false)
      },
      failure: function () {
        Ext.defer(function () {
          saveBtn.setDisabled(false)
        }, 50)
        list.setLoading(false)
      }
    })
  },
  onEditDPI: function (editor, e) {
    var v = e.value

    var record = e.record

    var field = e.field

    var dpiStore = this.getViewModel().getStore('DPIListStore')
    if (field == 'DPIFamilyID') {
      var index = dpiStore.findExact('DPIFamilyID', v)
      var dpiRec = dpiStore.getAt(index)
      if (dpiRec) {
        record.set('DPIFamily', dpiRec.get('DPIFamily'))
        record.set('IsRazor', dpiRec.get('IsRazor'))
        this.getViewModel().set('isChanged', Ext.id())
      }
    }
  },
  onEditCartridge: function (editor, e) {
    var v = e.value

    var me = this

    var view = me.getView()

    var vm = me.getViewModel()

    var record = e.record

    var field = e.field

    var dpiStore = this.getViewModel().getStore('CartridgeListStore')
    var DPISelectedRecord = vm.get('dpilist').selection
    DPISelectedRecord.dirty = true
    if (field == 'DPIFamilyID') {
      var index = dpiStore.findExact('DPIFamilyID', v)
      var dpiRec = dpiStore.getAt(index)
      if (dpiRec) {
        record.set('DPIFamily', dpiRec.get('DPIFamily'))
        this.getViewModel().set('isChanged', Ext.id())
      }
    }
  },
  onSourceEdit: function (editor, e) {
    var v = e.value

    var record = e.record

    var field = e.field

    var ResourceListStore = this.getViewModel().getStore('ResourceListStore')
    if (field == 'ResourceID') {
      var index = ResourceListStore.findExact('ResourceID', v)
      var sourceRec = ResourceListStore.getAt(index)
      if (sourceRec) {
        record.set('Resource', sourceRec.get('Resource'))
      }
    }
  },
  onSaveForm: function () {
    var me = this

    var view = me.getView()

    var saveBtn = me.lookupReference('saveButton')

    var formPanel = me.lookupReference('materialFrom')

    var form = formPanel.getForm()

    var vm = me.getViewModel()

    var record = form.getRecord()

    var store = me.getViewModel().getStore('DPIRelationStore')
    if (form.isValid()) {
      view.setLoading('Saving form data please wait....!')
      form.updateRecord()
      record.save({
        success: function (record) {
          var t = Ext.toast({
            html: 'Material data successfully saved',
            title: 'Information',
            width: 300,
            align: 'tr',
            iconCls: 'fa fa-check',
            closable: true
          })
          record.set('isNew', false)
          vm.set('crec', record)
          me.loadForm(record)
          me.updated = true
          me.onCommitChanges()
          view.setLoading(false)
        },
        failure: function () {
          view.setLoading(false)
          Ext.defer(function () {
            saveBtn.setDisabled(false)
          }, 50)
        }
      })
    }
  },

  onLoadRegionStore: function (store, records) {
    var me = this
    var combo = me.lookup('rcombo')
    if (records && _.size(records) > 0) {
      combo.select(records[0])
    }
  }
})
