import _ from 'lodash'
import svglist from '../../../assets/scripts/svglist'

export function getData(plugin) {
  const { props, state } = plugin
  const {
    settings: {
      config: {
        settings: {
          flowWidth: settingsFlowWidth = 2,
          flowCurve: settingsFlowCurve = 0.2,
          flowStyle: settingsFlowStyle = 'solid'
        } = {},
        data: {
          flowType,
          flowColor,
          flowWidth,
          flowCurve,
          flowStyle,

          source,
          sourceSymbol,
          sourceLat,
          sourceLon,
          destination,
          destinationSymbol,
          destinationLat,
          destinationLon,
          flow,
          legendColor
        } = {},
        tooltips: { rows = [] } = {}
      } = {}
    } = {},
    pluginData,
    getFormattedValue
  } = props
  const { value, selectedTooltipIndex } = state

  let filteredData = pluginData

  _.each(value, (filter, filterName) => {
    filteredData = _.filter(filteredData, (p) => _.find(filter, (value) => p[filterName] === value))
  })

  if (!_.isEmpty(filteredData)) {
    let minLon = 180
    let maxLon = -180
    let minLat = 90
    let maxLat = -90

    if (!sourceLat || !sourceLon) {
      const message = 'invalid configuration'
      throw message
    }

    const tooltipVolume = _.get(rows, [selectedTooltipIndex, 'volume'])
    const tooltipVolumeDisplay = _.get(rows, [selectedTooltipIndex, 'volumeDisplay'])

    let points = _.reduce(
      filteredData,
      (result, row) => {
        const srcLon = row[sourceLon]
        const srcLat = row[sourceLat]
        const dstLon = row[destinationLon]
        const dstLat = row[destinationLat]

        minLon = Math.min(minLon, srcLon, dstLon || srcLon)
        maxLon = Math.max(maxLon, srcLon, dstLon || srcLon)
        minLat = Math.min(minLat, srcLat, dstLat || srcLat)
        maxLat = Math.max(maxLat, srcLat, dstLat || srcLat)

        const src = [srcLon, srcLat]
        const dst = [dstLon, dstLat]
        const uniqSrc = `${row[source]}-${srcLon}-${srcLat}`
        const res =
          result[uniqSrc] ||
          (result[uniqSrc] = {
            coords: src,
            ...findSymbolFromKey({ key: row[sourceSymbol], props, state }),
            name: row[source],
            value: 0,
            valueIn: 0,
            valueOut: 0,
            flows: [],
            groupKey: row[sourceSymbol],
            tooltip: [],
            pointDisplay: ''
          })
        res.flows.push(row)

        if (tooltipVolume) {
          res.value = row[tooltipVolume]
          if (tooltipVolumeDisplay) {
            res.pointDisplay = getFormattedValue(tooltipVolumeDisplay, row[tooltipVolumeDisplay])
          } else {
            res.pointDisplay = getFormattedValue(tooltipVolume, row[tooltipVolume])
          }
        } else {
          res.value += row[flow]
          res.valueOut += row[flow]
        }

        res.tooltip = _.map(rows, ({ color, display, volume, columns = [] }) => {
          /// TODO
          return `<div class="ec-row" style="color:${color};">
                <i></i>
                <b>${display}</b>
                <span>${getFormattedValue(volume, row[volume])}</span>
                ${_.map(columns, (c) => {
                  return `<span>${getFormattedValue(c.column, row[c.column])}</span>`
                }).join('')}
            </div>`
        }).join('')

        const uniqDest = `${row[destination]}-${dstLon}-${dstLat}`
        if (!_.isEmpty(destination)) {
          const res2 =
            result[uniqDest] ||
            (result[uniqDest] = {
              coords: dst,
              ...findSymbolFromKey({ key: row[destinationSymbol], props, state }),
              name: row[destination],
              value: 0,
              valueIn: 0,
              valueOut: 0,
              flows: [],
              groupKey: row[destinationSymbol],
              pointDisplay: ''
            })
          res2.flows.push(row)

          if (tooltipVolume) {
            res2.value = row[tooltipVolume]
            if (tooltipVolumeDisplay) {
              res2.pointDisplay = getFormattedValue(tooltipVolumeDisplay, row[tooltipVolumeDisplay])
            } else {
              res2.pointDisplay = getFormattedValue(tooltipVolume, row[tooltipVolume])
            }
          } else {
            res2.value += row[flow]
            res2.valueIn += row[flow]
          }
        }
        return result
      },
      {}
    )

    points = _.orderBy(
      points,
      (x) => {
        return x.value
      },
      ['asc']
    )
    const colors = [
      '#F44336',
      '#FFC107',
      '#E91E63',
      '#8BC34A',
      '#3F51B5',
      '#FF5722',
      '#4CAF50',
      '#2196F3',
      '#FF9800'
    ]

    let data = []
    if (!_.isEmpty(destination)) {
      data = _.map(filteredData, (row) => {
        const srcLon = row[sourceLon]
        const srcLat = row[sourceLat]
        const dstLon = row[destinationLon]
        const dstLat = row[destinationLat]
        const legColor = !_.isNil(legendColor) ? row[legendColor] : null

        minLon = Math.min(minLon, srcLon, dstLon || srcLon)
        maxLon = Math.max(maxLon, srcLon, dstLon || srcLon)
        minLat = Math.min(minLat, srcLat, dstLat || srcLat)
        maxLat = Math.max(maxLat, srcLat, dstLat || srcLat)

        const src = [srcLon, srcLat]
        const dst = [dstLon, dstLat]

        const itemSettings = {
          flowColor: row[flowColor] || legColor,
          flowWidth: !_.isNil(row[flowWidth]) ? row[flowWidth] : settingsFlowWidth,
          flowCurve: !_.isNil(row[flowCurve]) ? row[flowCurve] : settingsFlowCurve,
          flowStyle: row[flowStyle] || settingsFlowStyle
        }

        return {
          fromName: row[source],
          toName: row[destination],
          coords: [src, dst],
          rowData: row,
          value: row[flow],
          type: row[flowType],
          color: legColor,
          itemSettings
        }
      })
    }

    const flows = _.groupBy(data, (row) => row.type)
    const settings = _.map(flows, (fl, idx) => {
      const { color = colors[idx], itemSettings = {} } = _.first(fl) || {}

      return {
        color,
        ...itemSettings
      }
    })

    var boundingCoords = [
      [maxLon + 1, minLat - 1],
      [minLon - 1, maxLat + 1]
    ]

    return {
      points: _.groupBy(points, (row) => row.groupKey),
      data: flows,
      settings,
      boundingCoords
    }
  }
  return { reduce: {}, data: [], boundingCoords }
}

export function findSymbolFromKey({ key, props, state, defaultSymbol = 'circle' }) {
  const {
    settings: { config: { tooltips: { rows = [] } = {}, data: { symbols = [] } = {} } = {} } = {},
    pluginData = []
  } = props
  const { selectedTooltipIndex } = state
  const result = {
    symbol: defaultSymbol,
    color: '#fff',
    symbolSize: 12,
    symbolMinSize: 12,
    symbolMaxSize: 22
  }

  if (_.isEmpty(key)) {
    return result
  }

  const symbolValue = _.find(symbols, (s) => s.key === key)
  const selectedTooltip = _.find(rows, (item, index) => index === selectedTooltipIndex)
  const colorData = _.get(symbolValue, 'colorData', null)
  if (_.isEmpty(symbolValue)) {
    return result
  }
  const selectedColor = !_.isEmpty(colorData)
    ? _.first(_.entries(_.groupBy(pluginData, colorData) || {}) || []) || {}
    : _.has(symbolValue, 'color')
    ? symbolValue.color
    : '#fff'
  const [symbolColor] = selectedColor

  if (selectedTooltip) {
    return {
      ...result,
      symbol: getSymbolFromSvgList(symbolValue.value),
      color: selectedTooltip.color,
      symbolMinSize: symbolValue.minSize,
      symbolMaxSize: symbolValue.maxSize,
      symbolSize: symbolValue.maxSize
    }
  }

  return {
    symbol: getSymbolFromSvgList(symbolValue.value),
    color: symbolColor,
    symbolMinSize: symbolValue.minSize,
    symbolMaxSize: symbolValue.maxSize,
    symbolSize: symbolValue.maxSize
  }
}

export function getSymbolFromSvgList(key, defaultSymbol = 'circle') {
  const modifiedKey = _.replace(key, 'fa ', '').replace('fa-', '')
  if (_.has(svglist, modifiedKey)) {
    return `path://${svglist[modifiedKey]}`
  }
  return defaultSymbol
}

export function getCenterPos({ props = {} }) {
  const {
    settings: {
      config: { data: { sourceLat, sourceLon, destinationLat, destinationLon } = {} } = {}
    } = {},
    pluginData
  } = props

  const flatPluginData = _.transform(
    pluginData,
    (arr, row) => {
      arr.push({
        latitude: row[destinationLat],
        longitude: row[destinationLon]
      })
      arr.push({
        latitude: row[sourceLat],
        longitude: row[sourceLon]
      })
      return arr
    },
    []
  )

  return averageGeolocation(flatPluginData)
}

export function averageGeolocation(coords) {
  if (coords.length === 1) {
    return coords[0]
  }

  let x = 0.0
  let y = 0.0
  let z = 0.0

  for (const coord of coords) {
    const latitude = (coord.latitude * Math.PI) / 180
    const longitude = (coord.longitude * Math.PI) / 180

    x += Math.cos(latitude) * Math.cos(longitude)
    y += Math.cos(latitude) * Math.sin(longitude)
    z += Math.sin(latitude)
  }

  const total = coords.length

  x /= total
  y /= total
  z /= total

  const centralLongitude = Math.atan2(y, x)
  const centralSquareRoot = Math.sqrt(x * x + y * y)
  const centralLatitude = Math.atan2(z, centralSquareRoot)

  const retLat = (centralLatitude * 180) / Math.PI
  const retLon = (centralLongitude * 180) / Math.PI

  return [_.isNaN(retLat) ? 0 : retLat, _.isNaN(retLon) ? 0 : retLon]
}
