import React, { Component } from 'react'
import Moment from 'moment'
import 'moment/min/locales'
import { v4 as uuidv4 } from 'uuid'
import { extendMoment } from 'moment-range'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
import TimePickerPanel from 'rc-time-picker/lib/Panel'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { isDisabledDate } from '@/helpers/datetime'
import 'rc-calendar/dist/rc-calendar.css'
import 'rc-time-picker/assets/index.css'
import './FilterCalendar.scss'

const moment = extendMoment(Moment)
const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function isEqualToCurrentTime(value) {
  if (value === null) return false
  return moment().format('HH:mm') === value.format('HH:mm')
}

class CalendarFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
      showOverlay: false,
      singleValue: null,
      intervalValue: [],
      hoverValue: [],
      selectedDay: null,
      label: '',
      dateFormat: null,
      minValue: '',
      maxValue: '',
      calendarLocale: null,
      visible: 'visible'
    }

    this.culture = ''
    this.locale = ''

    this.handleChange = this.handleChange.bind(this)
    this.disabledDate = this.disabledDate.bind(this)
    this.setSelectionEnabled = this.setSelectionEnabled.bind(this)
    this.handleClearSelectedDate = this.handleClearSelectedDate.bind(this)
    this.setSelectionDisabled = this.setSelectionDisabled.bind(this)
  }

  UNSAFE_componentWillMount() {
    const {
      props: { settings: { config: { settings: { calendarType = 'Single' } = {} } = {} } = {} } = {}
    } = this
    const isInterval = calendarType === 'Interval'
    this.handleItemSelect = this.props.registerEvent({
      key: 'selectedDate',
      fn: this.handleItemSelect.bind(this),
      returnTypes: isInterval
        ? { startDate: PluginTypes.string, endDate: PluginTypes.string }
        : { value: PluginTypes.string }
    })

    this.handleClearSelectedDate = this.props.registerEvent({
      key: 'clearSelectedDate',
      fn: this.handleClearSelectedDate.bind(this),
      returnTypes: isInterval
        ? {
            startDate: PluginTypes.string,
            endDate: PluginTypes.string,
            refreshKey: PluginTypes.string
          }
        : { value: PluginTypes.string, refreshKey: PluginTypes.string }
    })

    this.props.registerMethod({
      key: 'setFilterDate',
      fn: this.setFilterDate.bind(this),
      args: [
        { name: 'filterdate', type: PluginTypes.string },
        ...(isInterval ? [{ name: 'filterenddate', type: PluginTypes.string }] : [])
      ]
    })

    this.props.registerMethod({
      key: 'setSelectionEnabled',
      fn: this.setSelectionEnabled.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setSelectionDisabled',
      fn: this.setSelectionDisabled.bind(this),
      args: []
    })

    this.fillData(this.props)

    this.calendarLocaleImport(this.culture)
  }

  componentDidMount() {
    const {
      token,
      settings: { config: { settings: { firstDayOfWeek = 'Sunday' } = {} } = {} } = {}
    } = this.props

    const session = jwtDecode(token)
    this.locale = session.culture.substr(0, session.culture.indexOf('-'))
    this.culture = session.culture.replace('-', '_')

    const firstDayOfWeekNum = _.indexOf(weekDays, firstDayOfWeek)
    moment.updateLocale(this.locale, {
      week: {
        dow: firstDayOfWeekNum
      }
    })
  }

  componentWillUnmount() {
    moment.updateLocale(this.locale, {
      week: {
        dow: 0
      }
    })
  }

  onHoverChange = (hoverValue) => {
    this.setState({ hoverValue })
  }

  disabledDate(current) {
    const { settings: { config: { settings: { disableDays } = {} } = {} } = {} } = this.props
    const { minValue, maxValue } = this.state

    return isDisabledDate(current, {
      min: minValue,
      max: maxValue,
      disableDays
    })
  }

  async calendarLocaleImport(culture) {
    try {
      const module = await import(`rc-calendar/lib/locale/${culture}`)
      this.setState({ calendarLocale: module })
    } catch (error) {
      const module = await import('rc-calendar/lib/locale/en_US')
      this.setState({ calendarLocale: module })
    }
  }

  handleItemSelect({ value, dateformat, calendarType, configData = {}, resetToDefault }) {
    const {
      pluginData,
      settings: { config: { settings: { selectTime = false } = {} } = {} } = {}
    } = this.props

    const { value: dataValue, endDate, startDate } = configData
    const outputFormat = dateformat || `YYYY-MM-DD${selectTime ? ' HH:mm' : ''}`

    if (calendarType === 'Interval') {
      if (value === 'clear') {
        if (resetToDefault && endDate && startDate) {
          const dates = []
          const start = pluginData && pluginData[0][startDate]
          const end = pluginData && pluginData[0][endDate]
          dates.push(moment(start))
          dates.push(moment(end))
          return {
            startDate: moment(start).format(outputFormat),
            endDate: moment(end).format(outputFormat),
            intervalValue: dates
          }
        }
        return { startDate: null, endDate: null, intervalValue: null }
      }
      if (Array.isArray(value) && value.length > 0) {
        // Default (start, end) time will be (00:00, 23:59)
        // instead of current time
        if (isEqualToCurrentTime(value[0])) {
          value[0].set({ hour: 0, minute: 0 })
          value[1].set({ hour: 23, minute: 59 })
        }
        return {
          startDate: value[0].format(outputFormat),
          endDate: value[1].format(outputFormat)
        }
      }
    } else if (value) {
      if (value === 'clear') {
        if (dataValue) {
          const dateVal = pluginData && pluginData[0][dataValue]
          return { value: dateVal }
        }
        return { value: null }
      }
      if (typeof value === 'string') value = moment(value)
      if (isEqualToCurrentTime(value)) {
        value.set({ hour: 0, minute: 0 })
      }
      return {
        value: value.length === 2 ? value[0].format(outputFormat) : value.format(outputFormat)
      }
    }
  }

  handleClearSelectedDate() {
    const {
      props: {
        settings: {
          config: {
            settings: { dateformat = '', calendarType = 'Single', resetToDefault = false } = {},
            data: configData = {}
          } = {}
        } = {}
      } = {}
    } = this
    const returnTypes = this.handleItemSelect({
      value: 'clear',
      dateformat,
      calendarType,
      configData,
      resetToDefault
    })
    return { ...returnTypes, refreshKey: uuidv4() }
  }

  setFilterDate({ filterdate }) {
    if (this.state.selectedDay !== filterdate) {
      this.setState({ selectedDay: filterdate })
    }
  }

  setSelectionEnabled() {
    this.setState({ visible: 'visible' })
  }

  setSelectionDisabled() {
    this.setState({ visible: 'hidden' })
  }

  fillData(props) {
    if (props.pluginData && props.pluginData[0]) {
      const {
        settings: {
          config: {
            settings: { dateformat = '', calendarType = 'Single' } = {},
            data: configData = {},
            data: { value, endDate, startDate, minvalue, maxvalue, label } = {}
          } = {}
        } = {}
      } = props

      if (value) {
        const dateVal = props.pluginData[0][value]
        if (this.state.singleValue !== moment(dateVal)) {
          this.setState({
            singleValue: moment(dateVal)
          })
          this.handleItemSelect({ value: dateVal, dateformat, calendarType, configData })
        }
      }

      if (endDate && startDate) {
        if (calendarType === 'Interval') {
          const dates = []
          const start = props.pluginData[0][startDate]
          const end = props.pluginData[0][endDate]
          dates.push(moment(start))
          dates.push(moment(end))
          this.setState({
            startDate: moment(start),
            endDate: moment(end),
            intervalValue: dates
          })
          this.handleItemSelect({ value: dates, dateformat, calendarType, configData })
        }
      }
      if (minvalue) {
        const minVal = props.pluginData[0][minvalue]
        this.setState({
          minValue: moment(minVal)
        })
      }
      if (maxvalue) {
        const maxVal = props.pluginData[0][maxvalue]
        this.setState({
          maxValue: moment(maxVal)
        })
      }
      if (label) {
        const labelVal = props.pluginData[0][label]
        this.setState({
          label: labelVal
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.pluginData, nextProps.pluginData)) {
      this.fillData(nextProps)
    }
  }

  handleChange(date) {
    const {
      props: {
        settings: {
          config: {
            data: configData = {},
            settings: { dateformat = '', calendarType = 'Single', resetToDefault = false } = {}
          } = {}
        } = {}
      } = {}
    } = this
    calendarType === 'Interval'
      ? this.setState({
          intervalValue: date
        })
      : this.setState({
          singleValue: date
        })
    this.handleItemSelect({
      value: date || 'clear',
      dateformat,
      calendarType,
      configData,
      resetToDefault
    })
  }

  onIntervalClear() {
    const {
      props: {
        pluginData,
        settings: {
          config: {
            data: { endDate, startDate } = {},
            settings: { resetToDefault = false } = {}
          } = {}
        } = {}
      } = {}
    } = this
    if (resetToDefault && endDate && startDate) {
      const dates = []
      const start = pluginData && pluginData[0][startDate]
      const end = pluginData && pluginData[0][endDate]
      dates.push(moment(start))
      dates.push(moment(end))
      this.setState({
        startDate: moment(start),
        endDate: moment(end),
        intervalValue: dates
      })
    } else {
      this.setState({ intervalValue: [] })
    }
    this.handleClearSelectedDate()
  }

  onSingleClear() {
    const {
      props: {
        pluginData,
        settings: {
          config: { data: { value } = {}, settings: { resetToDefault = false } = {} } = {}
        } = {}
      } = {}
    } = this
    if (value && resetToDefault) {
      const dateVal = pluginData && pluginData[0][value]
      if (this.state.singleValue !== moment(dateVal)) {
        this.setState({
          singleValue: moment(dateVal)
        })
      }
    } else {
      this.setState({ singleValue: null })
    }

    this.handleClearSelectedDate()
  }

  render() {
    let {
      settings: {
        config: {
          settings: {
            calendarType = 'Single',
            label,
            displaydateformat = '',
            showClear = false,
            selectTime = false,
            placeholder = 'Please select'
          } = {}
        } = {}
      } = {}
    } = this.props

    const { intervalValue, singleValue, visible = 'visible' } = this.state
    const isInterval = calendarType === 'Interval'
    const disabled = visible === 'hidden'

    if (this.state.calendarType) {
      calendarType = this.state.calendarType
    }
    if (this.state.label) {
      label = this.state.label
    }
    displaydateformat = displaydateformat || `YYYY-MM-DD ${selectTime ? 'HH:mm' : ''}`
    const singleValueDateFormat = isEqualToCurrentTime(this.state.singleValue)
      ? `YYYY-MM-DD ${selectTime ? '00:00' : ''}`
      : displaydateformat
    const timePicker = selectTime ? <TimePickerPanel showSecond={false} /> : null
    const calendar = isInterval ? (
      <RangeCalendar
        dateInputPlaceholder={['start', 'end']}
        disabled={disabled}
        disabledDate={this.disabledDate}
        format={this.state.intervalValue.length ? displaydateformat : 'YYYY-MM-DD'}
        locale={this.state.calendarLocale}
        showClear={showClear}
        style={{ zIndex: 1000 }}
        timePicker={timePicker}
        onClear={(e) => this.onIntervalClear()}
        onSelect={(that) => this.handleChange(that)}
      />
    ) : (
      <Calendar
        dateInputPlaceholder=""
        defaultValue={singleValue || null}
        disabled={disabled}
        disabledDate={this.disabledDate}
        format={singleValueDateFormat}
        locale={this.state.calendarLocale}
        showClear={showClear}
        style={{ zIndex: 1000 }}
        timePicker={timePicker}
        onClear={(e) => this.onSingleClear()}
        onSelect={(that) => this.handleChange(that)}
      />
    )

    return (
      <div className="filter-calendar p-1" onMouseDown={this.handleContainerMouseDown}>
        {label ? <label className="filter-calendar-label">{label}</label> : null}
        <DatePicker
          animation="slide-up"
          calendar={calendar}
          disabled={disabled}
          value={calendarType === 'Interval' ? intervalValue : singleValue}
        >
          {({ value }) => {
            return (
              <span className="filter-calendar-span" tabIndex="0">
                <input
                  readOnly
                  className="filter-calendar-input ant-calendar-picker-input ant-input"
                  disabled={false}
                  placeholder={placeholder}
                  tabIndex="-1"
                  value={
                    calendarType === 'Interval'
                      ? value.length > 0
                        ? `${moment(value[0]).format(displaydateformat)} ~ ${moment(
                            value[1]
                          ).format(displaydateformat)}`
                        : ''
                      : value
                      ? moment(value).format(singleValueDateFormat)
                      : ''
                  }
                />
                {showClear && (
                  <i
                    className="rc-calendar-clear-btn -cc-clear"
                    title="Clear"
                    onClick={(event) => {
                      event.stopPropagation()
                      isInterval ? this.onIntervalClear() : this.onSingleClear()
                    }}
                  />
                )}
              </span>
            )
          }}
        </DatePicker>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  token: props.token
})

export default createPlugin(CalendarFilter, selectConnectorProps)
