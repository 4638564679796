export default `({
    listeners: {
        activate: function (p) {
                       var store = p.getSalesChartStore(),
                SpotId = p.getSpotId();
            store.getProxy().setExtraParam('SpotId', SpotId);
            if (store.forceToLoad) {
                store.load();
                store.forceToLoad = false
            }
        },
    },
    forcetoLoad: function () {
        var store = this.getSalesChartStore();
        if (this.isVisible()) {
            //  SpotId = this.getSpotId();
            //  store.getProxy().setExtraParam('SpotId', SpotId);
            store.load();
        } else {
            store.forcetoLoad = true;
        }
    },
    xAxis: {
        type: 'datetime',
        labels: {
            formatter: function () {
                var value=this.value
                if(this.value.name)
                    value=this.value.name
                    
                year = value.substr(0, 4);
                month =value.substr(4, 6);
                day = value.substr(6);
                dayValue = null;
                if (day) {
                    dayValue = parseInt(day);
                }
                monthValue = parseInt(month);
                yearValue = parseInt(year);
                if (dayValue) {
                    date = year + '-' + monthValue + '-' + dayValue;
                } else {
                    date = year + '-' + monthValue;
                }
                return date;
            },
            style: {
                fontSize: '13px'
            },
            rotation: 315,
            y: 35
        },
    },
    xField: 'DateKey',
    series: [{
            dataIndex: 'QTY',
            type: 'column',
            name: tt('Sales Quantity'),
            color: 'rgba(236,17,92,1)',
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    // panel = serie.chart;
                    // panel.fireEvent('clickChart', record);
                }
            }
        },
        // {
        //     dataIndex: 'Envanter',
        //     type: 'spline',
        //     name: 'Envanter',
        //     cursor: 'pointer',
        //     listeners: {
        //         pointclick: function (serie, point, record, event) {
        //             // panel = serie.chart;
        //             // panel.fireEvent('clickChart', record);
        //         }
        //     }
        // },
        {
            dataIndex: 'PriceNull',
            type: 'spline',
            "lineWidth": 0,
            "marker": {
                "enabled": "true",
                "states": {
                    "hover": {
                        "enabled": true
                    }
                },
                "radius": 5
            },
            "states": {
                "hover": {
                    "lineWidthPlus": 0
                }
            },
            name: tt('Satis Fiyatı'),
            yAxis: 1,
            cursor: 'pointer',
            tooltip: {
                valueDecimals: 2
            },
            listeners: {
                pointclick: function (serie, point, record, event) {
                    // panel = serie.chart;
                    // panel.fireEvent('clickChart', record);
                }
            }
        },
    ],
})
`
