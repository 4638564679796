import _ from 'lodash'
import { IRemoteActionCompletedKey } from './RemoteActionCompleted.types'

const hasInList = (
  list: IRemoteActionCompletedKey[],
  obj: { remoteActionKey: string; type: string }
) => {
  return !_.isNil(_.find(list, (item) => _.isEqual(_.omit(item, ['jobId', 'result']), obj)))
}

export default hasInList
