Ext.define('tuiv2.leaflet.map', {
  extend: 'tuiv2.Component',
  xtype: 'tuiv2-leaflet-map',
  config: {
    defaultCls: {
      wrapper: Ext.baseCSSPrefix + 'echart-wrapper',
      scene: Ext.baseCSSPrefix + 'echart-scene',
      hidden: Ext.baseCSSPrefix + 'echart-hidden'
    },
    padding: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    isConfiguring: true,
    map: null,
    mapOptions: {
      wheelPxPerZoomLevel: 100
    },
    smoothFactor: 1,
    weight: 10,
    opacity: 1,
    defaultTile: null,
    tiles: null
  },

  getMap: function () {
    if (!this.innerEl || !this.innerEl.dom) return false
    if (!this.map) {
      this.isConfiguring = false
      this.map = L.map(this.innerEl.dom, this.getMapOptions())
      this.map.ownerCt = this
      this.fireEvent('mapready', this.map)
    }

    return this.map
  },

  resizeHandler: function (size) {
    var me = this,
      map = me.getMap(),
      isRtl = me.getInherited().rtl,
      width = size && size.width,
      height = size && size.height

    if (!me.isLoaded) {
      me.isLoaded = true
    }
    if (!me.isTileLoaded) {
      me.isTileLoaded = true
      me.initTiles(map)
    }

    if (!(width && height)) {
      return
    }
    me.innerEl.dom.style.width = width + 'px'
    me.innerEl.dom.style.height = height + 'px'
    if (map) {
      me.resizeMapHanler(map, size)
    }
    me.fireEvent('mapresize', me)
  },

  resizeMapHanler: function (map) {
    map.invalidateSize()
  },

  initTiles: function (map) {
    var me = this

    var mainView = me.up('tuiv2mainview')
    var leafletOptions = mainView.leafletOptions
    var selectedTile = leafletOptions.selectedTile
    var selectedTheme = leafletOptions.selectedTheme
    this.setTiles(leafletOptions.tiles)

    var tiles = {},
      list = this.getTiles()

    for (var i in list) {
      var t = list[i],
        tile = L.tileLayer(t.url, t)
      tiles[t.title] = tile
      if (i == this.getDefaultTile()) {
        this.mainLayer = tile.addTo(map)
      }
    }
    me.tileLayers = tiles

    for (var i in tiles) {
      if (i == this.getDefaultTile()) {
        tiles[i].addTo(map)
      }
    }

    tiles[selectedTile].addTo(map)
    map.setView([39, 32], 3)
    me.setCls(selectedTheme)
    map.on('baselayerchange ', function (layer) {
      me.setCls(selectedTile)
    })
  },

  applyPadding: function (padding, oldPadding) {
    var result

    if (!Ext.isObject(padding)) {
      result = Ext.util.Format.parseBox(padding)
    } else if (!oldPadding) {
      result = padding
    } else {
      result = Ext.apply(oldPadding, padding)
    }

    return result
  },
  // TODO APPLY ONLY LINESTRING
  applySmoothFactor: function (n, o) {
    var map = this.getMap()
    if (map) {
      map.eachLayer(function (layer) {
        layer.options.smoothFactor = n
        if (layer._updatePath) layer._update()
      })
      map.invalidateSize()
    }
    return n
  },

  applyWeight: function (n, o) {
    var map = this.getMap()
    if (map) {
      map.eachLayer(function (layer) {
        layer.options.weight = n
        if (layer.setStyle && layer.feature && layer.feature.geometry.type == 'LineString') {
          layer.setStyle({ fillColor: 'blue', weight: n })
        }
      })
    }

    map.invalidateSize()
    return n
  },

  applyOpacity: function (n, o) {
    var map = this.getMap()
    if (map) {
      map.eachLayer(function (layer) {
        if (layer.setStyle && layer.feature && layer.feature.geometry.type == 'LineString') {
          layer.setStyle({ opacity: n })
        }
      })
    }

    map.invalidateSize()
    return n
  },

  destroy: function () {
    var map
    if ((map = this.getMap())) {
      try {
        map.off()
        map.removeEventListener()
        if (map.remove) map.remove()
      } catch (ex) {}
    }
    this.callParent()
  }
})
