import { IRowNode } from 'ag-grid-community'
import { getValidRowRuleForCell } from '../index'
import { cellRendererParams, validRowRule } from '../../__tests__/mocks'

function getParams() {
  return cellRendererParams
}

describe('AgGrid:Helpers:Cell:getValidRowRuleForCell', () => {
  let params = getParams()

  beforeEach(() => {
    params = getParams()
    params.value = validRowRule.value
    params.data.col1 = validRowRule.value
    params.node = {
      data: params.data,
      allLeafChildren: [{ data: params.data }],
      group: false
    } as IRowNode
  })

  it('should return null if there is no rules', () => {
    const result = getValidRowRuleForCell({ params, rowDisplayRules: [] })
    expect(result).toEqual(null)
  })

  it('should return valid row rule if current node is not a group', () => {
    const result = getValidRowRuleForCell({ params, rowDisplayRules: [validRowRule] })
    expect(result).toEqual(validRowRule)
  })

  it('should return valid row rule if current node is a group and have allLeftChildren prop', () => {
    const newParams = getParams()
    newParams.node.group = true
    const result = getValidRowRuleForCell({ params: newParams, rowDisplayRules: [validRowRule] })
    expect(result).toEqual(validRowRule)
  })
})
