window.JSONEditor.defaults.editors.SolvoyoNumberEditor =
  window.JSONEditor.defaults.editors.number.extend({
    sanitize(value) {
      return `${value}`.replace(/[^0-9.\-eE]/g, '')
    },
    getNumColumns() {
      return 2
    },
    getValue() {
      if (this.value === '') {
        return this.value
      }
      return this.value * 1
    },
    getDefault() {
      if ('default' in this.schema) {
        return this.schema.default
      }
      return 0
    }
  })

export default {
  name: 'SolvoyoNumberEditor',
  type: 'NullableNumber'
}
