import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from '../appAPI'

const initialState = {
  error: {},
  filters: {
    Name: '',
    Description: '',
    BasePrice: 0,
    Expenditure: 0,
    SizeRange: '',
    SizeRangeId: '60',
    SizeRangeTypeId: '60',
    TaxRate: 0.08,
    NumberOfOptionsId: 1,
    AttributeIds: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  },
  testOptionsData: {
    _default: {},
    selectProduct: {}
  },
  isLoading: true
}

export const getTestOptionData = createAsyncThunk('app/getTestOptionData', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload

    const responseObj = {
      ...JSON.parse(response.text),
      dataKey: action.dataKey
    }
    return responseObj
  } catch (err) {
    throw err
  }
})

const testOptionDataSlice = createSlice({
  name: 'testOptions',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestOptionData.pending, (state, action) => {
        state.error = {}
        state.isLoading = true
      })
      .addCase(getTestOptionData.fulfilled, (state, action) => {
        state.testOptionsData[action.payload.dataKey] = { ...action.payload }
        state.error = {}
        state.isLoading = false
      })
      .addCase(getTestOptionData.rejected, (state, action) => {
        state.testOptionsData = {
          _default: {},
          selectProduct: {}
        }
        state.error = { ...action.payload }
        state.isLoading = false
      })
  }
})

export const { setFilters } = testOptionDataSlice.actions

export const error = (state) => {
  state.error
}
export const filters = (state) => {
  state.filters
}
export const testOptionsData = (state) => {
  state.testOptionsData
}
export const isLoading = (state) => {
  state.isLoading
}

export default testOptionDataSlice.reducer
