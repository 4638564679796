import { Button } from 'react-bootstrap'
import { ExcelExportParams } from 'ag-grid-community'
import cx from 'classnames'
import { FooterButton, FooterButtonType } from './FooterButton'
import DataDetails from './DataDetails'
import { useConfirmationPopup } from '../../hooks'
import { getEnableInfo, generateExportFileName, getExportableColumnDefs } from '../../helpers'
import { FooterTypes } from './Footer.types'
import { LocaleText, SlvyColGroupDef } from '../../types'

const Footer = (props: FooterTypes) => {
  const {
    adding: {
      addingType,
      enabled: addingEnabled,
      addButtonSettings: { icon: addButtonIcon, text: addButtonText, tooltip: addButtonTooltip }
    },
    dataDetails,
    editing: {
      editingType,
      resetButtonSettings: {
        enabled: resetButtonEnabled,
        icon: resetButtonIcon,
        text: resetButtonText,
        tooltip: resetButtonTooltip
      },
      saveButtonSettings: {
        enabled: saveButtonEnabled,
        icon: saveButtonIcon,
        text: saveButtonText,
        tooltip: saveButtonTooltip
      }
    },
    exportable,
    filteredRowCount,
    firstRowData,
    footerButtons = [],
    footerButtonsRef,
    gridRef,
    gridEditableByDefault,
    gridEditableByState,
    handleAddRecord,
    handleReset,
    height,
    isThereUnsavedChanges,
    pluginName,
    noColorFooterButtons,
    theme,
    totalRowCount,
    triggerSave
  } = props

  const localeText = (gridRef?.current?.api?.getGridOption?.('localeText') as LocaleText) || {}

  const btnSize = theme === 'balham' ? 'xs' : 'sm'

  const { openConfirmationPopup } = useConfirmationPopup()

  const runFooterBtnClick = (buttonText: FooterButtonType['buttonText']) => {
    footerButtonsRef?.current?.[`handleFooterButtonClick_${buttonText}`]?.()
  }

  const handleBtnClick = ({
    buttonText,
    confirmation = false,
    confirmationText,
    confirmationTitle
  }: FooterTypes['footerButtons'][number]) => {
    if (confirmation) {
      openConfirmationPopup(confirmationText, confirmationTitle, () =>
        runFooterBtnClick(buttonText)
      )
    } else {
      runFooterBtnClick(buttonText)
    }
  }

  const handleSaveBtnClick = async () => {
    triggerSave({ skipUnsavedChanges: false, skipConfirmation: false, updateParameters: [] })
  }

  const handleAddRecordBtnClick = () => {
    handleAddRecord()
  }

  const handleExport = () => {
    const columns = (gridRef?.current?.api?.getColumnDefs?.() ?? []) as SlvyColGroupDef[]
    const exportColumns = getExportableColumnDefs(columns).map(({ field }) => field as string)

    const exportParams: ExcelExportParams = {
      columnKeys: exportColumns,
      fileName: generateExportFileName(pluginName, localeText.export)
    }
    gridRef?.current?.api?.exportDataAsExcel(exportParams)
  }

  const saveButtonVisible = editingType === 'SaveButton'
  const gridAddingEnabled = addingEnabled // true // this.isGridAddable()

  const displayButton = gridEditableByDefault && saveButtonVisible
  const displayAddButton = gridEditableByDefault && (saveButtonVisible || addingType === 'Direct')
  return (
    <div
      className={cx(`ag-theme-${theme}`, 'w-100 bg-white d-flex justify-content-between py-1 px-2')}
      style={{ height }}
    >
      <DataDetails
        dataDetails={dataDetails}
        filteredRowCount={filteredRowCount}
        isThereUnsavedChanges={isThereUnsavedChanges}
        localeText={localeText}
        totalRowCount={totalRowCount}
      />
      <div className="d-flex align-items-center gap-2">
        {displayAddButton && gridAddingEnabled ? (
          <FooterButton
            key="footer-tooltip-add"
            disabled={!gridEditableByState}
            handleBtnClick={handleAddRecordBtnClick}
            icon={addButtonIcon}
            size={btnSize}
            text={addButtonText}
            tooltip={addButtonTooltip}
            triggerKey="footer-tooltip-add"
            variant={noColorFooterButtons ? 'dark' : 'primary'}
          />
        ) : null}
        {displayButton && resetButtonEnabled ? (
          <FooterButton
            key="footer-tooltip-reset"
            disabled={!gridEditableByState}
            handleBtnClick={handleReset}
            icon={resetButtonIcon}
            size={btnSize}
            text={resetButtonText}
            tooltip={resetButtonTooltip}
            triggerKey="footer-tooltip-reset"
            variant={noColorFooterButtons ? 'dark' : 'warning'}
          />
        ) : null}
        {displayButton && saveButtonEnabled ? (
          <FooterButton
            key="footer-tooltip-save"
            disabled={!gridEditableByState}
            handleBtnClick={handleSaveBtnClick}
            icon={saveButtonIcon}
            size={btnSize}
            text={saveButtonText}
            tooltip={saveButtonTooltip}
            triggerKey="footer-tooltip-save"
            variant={noColorFooterButtons ? 'dark' : 'success'}
          />
        ) : null}
        {exportable ? (
          <Button
            size={btnSize as 'sm'}
            title={localeText.export}
            variant="dark"
            onClick={handleExport}
          >
            <i className="fa fa-download" /> {localeText.export}
          </Button>
        ) : null}
        {footerButtons.map((footerButton, key) => {
          const { buttonText, buttonTooltip, buttonIcon, enableFooterButton } = footerButton
          const enabled = getEnableInfo(firstRowData, enableFooterButton)
          return (
            <FooterButton
              key={`footer-tooltip-${key}`}
              disabled={!enabled}
              handleBtnClick={() => handleBtnClick(footerButton)}
              icon={buttonIcon}
              size={btnSize}
              text={buttonText}
              tooltip={buttonTooltip}
              triggerKey={`footer-tooltip-${key}`}
              variant={noColorFooterButtons ? 'dark' : 'primary'}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Footer
