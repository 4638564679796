Ext.define('clustering.Scatter3d', {
  extend: 'clustering.vc.Component',
  alias: ['widget.scatter3d'],
  forceFit: true,
  columnLines: false,
  cls: 'scatter3d',
  bodyStyle: { background: '#000' },
  html: 'test',
  config: {
    store: null,
    currentStore: null,
    currentSegment: null,
    segmentStore: null
  },
  bind: {
    store: '{clusterStore}',
    currentStore: '{currentStore}',
    currentSegment: '{currentSegment}',
    segmentStore: '{segmentStore}'
  },
  setCurrentStore: function (record) {
    this.highlightPoint(record)
  },
  setCurrentSegment: function (record) {
    this.highlightGroup(record)
  },
  setStore: function (store) {
    var me = this

    me.renderEmptyChart()

    if (store) {
      me.store = store
      store.on('datachanged', function (store) {
        me.renderChart(store)
      })
    }
  },

  configuration: {
    xAxis3D: 'X',
    yAxis3D: 'Y',
    zAxis3D: 'Z',
    color: 'ClusterID',
    symbolSize: 'TotalRevenue',
    name: 'Name',
    latitude: 'Latitude',
    longitude: 'Longitude',
    squareMeter: 'SquareMeter',
    totalSales: 'TotalSales',
    totalRevenue: 'TotalRevenue',
    clusterId: 'ClusterID',
    id: 'Id',
    segmentName: 'SegmentName'
  },
  renderChart: function (store) {
    var me = this,
      clusterViewModel = me.up().up().getViewModel(),
      yDimension = clusterViewModel.get('yDimension'),
      zDimension = clusterViewModel.get('zDimension')

    var segmentData = me.getSegmentStore().getDatas()

    var excludedClusterIds = $.map(
      $.grep(segmentData, function (segment) {
        return segment.IsExcluded
      }),
      function (item) {
        return item.Id
      }
    )

    var data = store.getDatas()

    data = $.grep(data, function (item) {
      return excludedClusterIds.indexOf(item.ClusterID) == -1
    })

    var myChart = me.getEcharts()

    function getMaxOnExtent(data) {
      var colorMax = -Infinity
      var symbolSizeMax = -Infinity
      for (var i = 0; i < data.length; i++) {
        var item = data[i]
        var colorVal = item[me.configuration.color]
        var symbolSizeVal = item[me.configuration.symbolSize]
        colorMax = Math.max(colorVal, colorMax)
        symbolSizeMax = Math.max(symbolSizeVal, symbolSizeMax)
      }
      return {
        color: colorMax,
        symbolSize: symbolSizeMax
      }
    }

    var convertedNames = {
      X: clusterViewModel.get('xDimension'),
      Y: clusterViewModel.get('yDimension'),
      Z: clusterViewModel.get('zDimension'),
      ClusterID: 'Cluster Id',
      SegmentName: 'Segment Name',
      TotalSales: 'Unit Sales',
      Name: 'Name',
      Latitude: 'Lattitude',
      Longitude: 'Longitude',
      SquareMeter: 'Square Meter',
      TotalRevenue: 'Total Revenue',
      Id: 'Id'
    }

    var alpha = 45
    var beta = 45
    if (yDimension == 'Undefined' || zDimension == 'Undefined') {
      alpha = 90
      beta = 0
    }

    var max = getMaxOnExtent(data)

    var distinctClusterIds = data.map(function (obj) {
      return obj.ClusterID
    })
    distinctClusterIds = distinctClusterIds.filter(function (v, i) {
      return distinctClusterIds.indexOf(v) == i
    })

    this.colors = clustering.store.ClusterColors.colors.slice()

    this.colors = this.colors.splice(0, Math.max.apply(null, distinctClusterIds) + 1)

    this.maxSymbolSize = max.symbolSize

    var defaultOptions = me.getDefaultOptions()

    var options = $.extend(true, {}, defaultOptions, {
      tooltip: {
        formatter: function (params) {
          var container = $('<div>')

          var storeRaw = params
          var item = {}
          item[me.configuration.xAxis3D] = storeRaw.data[0]
          item[me.configuration.yAxis3D] = storeRaw.data[1]
          item[me.configuration.zAxis3D] = storeRaw.data[2]
          item[me.configuration.color] = storeRaw.data[3]
          item[me.configuration.symbolSize] = storeRaw.data[4]
          item[me.configuration.name] = storeRaw.data[5]
          item[me.configuration.latitude] = storeRaw.data[6]
          item[me.configuration.longitude] = storeRaw.data[7]
          item[me.configuration.squareMeter] = storeRaw.data[8]
          item[me.configuration.totalRevenue] = storeRaw.data[9]
          item[me.configuration.id] = storeRaw.data[10]
          item[me.configuration.totalSales] = storeRaw.data[11]
          item[me.configuration.segmentName] = storeRaw.data[13]

          return $('<div>')
            .append(convertedNames.Name + ' : ' + item.Name)
            .append('<br>')
            .append(convertedNames.SegmentName + ' : ' + item.SegmentName)
            .append('<br>')
            .append(convertedNames.TotalSales + ' : ' + item.TotalSales)
            .append('<br>')
            .append(convertedNames.X + ' : ' + Ext.util.Format.number(item.X, '0,000'))
            .append('<br>')
            .append(convertedNames.Y + ' : ' + Ext.util.Format.number(item.Y, '0,000'))
            .append('<br>')
            .append(convertedNames.Z + ' : ' + Ext.util.Format.number(item.Z, '0,000'))
            .append('<br>')
            .html()
        }
      },
      visualMap: [
        {
          top: 10,
          calculable: true,
          dimension: 3,
          max: this.colors.length - 1,
          inRange: {
            color: this.colors
          },
          textStyle: {
            color: '#000'
          },
          itemHeight: 70
        },
        {
          bottom: 10,
          calculable: true,
          dimension: 4,
          max: this.maxSymbolSize,
          inRange: {
            symbolSize: [10, 40]
          },
          textStyle: {
            color: '#000'
          },
          itemHeight: 70
        }
      ],
      grid3D: {
        viewControl: {
          alpha: alpha,
          beta: beta,
          distance: 230
        },
        axisPointer: {
          label: {
            formatter: function (param) {
              var value = numeral(param).value()

              var res = value
              if (isNaN(value) || !isFinite(value)) {
                res = ''
              } else if (value === 0) {
                res = '0'
              } else if (value < 10) {
                res = value.toFixed(2)
              } else if (value < 100) {
                res = value.toFixed(1)
              } else {
                res = value.toFixed(0)
              }
              return res
            }
          }
        }
      },
      series: [
        {
          data: data.map(function (item) {
            return [
              item[me.configuration.xAxis3D],
              item[me.configuration.yAxis3D],
              item[me.configuration.zAxis3D],
              item[me.configuration.color],
              item[me.configuration.symbolSize],
              item[me.configuration.name],
              item[me.configuration.latitude],
              item[me.configuration.longitude],
              item[me.configuration.squareMeter],
              item[me.configuration.totalRevenue],
              item[me.configuration.id],
              item[me.configuration.totalSales],
              item[me.configuration.clusterId],
              item[me.configuration.segmentName]
            ]
          })
        }
      ]
    })

    if (!this.grid3DViewControl) {
      this.grid3DViewControl = options.grid3D.viewControl

      me.echarts.clear()
    } else {
      if (JSON.stringify(options.grid3D.viewControl) == JSON.stringify(this.grid3DViewControl)) {
        delete options.grid3D['viewControl']
      }
    }

    myChart.setOption(options)
  },

  renderEmptyChart: function () {
    var me = this

    if (me.echarts) {
      me.echarts.clear()
    }

    var myChart = me.getEcharts()

    var defaultOptions = me.getDefaultOptions()

    myChart.setOption(defaultOptions)
  },

  getDefaultOptions: function () {
    var me = this,
      clusterViewModel = me.up().up().getViewModel(),
      xDimension = clusterViewModel.get('xDimension'),
      yDimension = clusterViewModel.get('yDimension'),
      zDimension = clusterViewModel.get('zDimension')

    return {
      backgroundColor: '#f8f6f5',
      xAxis3D: {
        name: xDimension ? xDimension.split(' ').join('\n') : xDimension,
        type: 'value',
        nameTextStyle: {
          color: 'darkblue',
          fontSize: 13,
          fontWeight: 'normal'
        },
        nameGap: 25,
        scale: true,
        splitNumber: 3
      },
      yAxis3D: {
        name: yDimension ? yDimension.split(' ').join('\n') : yDimension,
        type: 'value',
        nameTextStyle: {
          color: 'darkblue',
          fontSize: 13,
          fontWeight: 'normal'
        },
        nameGap: 25,
        scale: true,
        splitNumber: 3
      },
      zAxis3D: {
        name: zDimension ? zDimension.split(' ').join('\n') : zDimension,
        type: 'value',
        nameTextStyle: {
          color: 'darkblue',
          fontSize: 13,
          fontWeight: 'normal'
        },
        nameGap: 25,
        scale: true,
        splitNumber: 3
      },
      grid3D: {
        axisLine: {
          lineStyle: {
            color: '#000'
          }
        },
        axisPointer: {
          lineStyle: {
            color: '#000'
          }
        },
        axisLabel: {
          textStyle: {
            color: '#000',
            fontSize: 8
          }
        },
        viewControl: {
          projection: 'orthographic',
          distance: 300
        }
      },
      series: [
        {
          type: 'scatter3D',
          data: [],
          symbolSize: 12,
          itemStyle: {
            borderWidth: 0.1,
            borderColor: '#000'
          },
          emphasis: {
            itemStyle: {
              color: '#fff',
              opacity: 0.5,
              borderWidth: 1,
              borderColor: '#000'
            }
          }
        }
      ]
    }
  },

  getEcharts: function () {
    if (!this.innerEl || !this.innerEl.dom) return false

    if (!this.echarts) {
      this.isConfiguring = false
      this.echarts = echarts.init(this.innerEl.dom)

      this.fireEvent('chartready', this.map)
    }
    return this.echarts
  },

  resizeHandler: function (size) {
    var me = this,
      echarts = me.getEcharts(),
      width = size && size.width,
      height = size && size.height

    if (!me.isLoaded) {
      me.isLoaded = true
    }

    if (!(width && height)) {
      return
    }
    me.innerEl.dom.style.width = width + 'px'
    me.innerEl.dom.style.height = height + 'px'
    if (this.getEcharts()) {
      this.getEcharts().resize()
    }
    if (echarts) {
      me.resizeHandler(echarts, size)
    }
    me.fireEvent('chartresize', me)
  },
  resizeMapHanler: function () {
    this.getEcharts().resize()
  },

  applyPadding: function (padding, oldPadding) {
    var result

    if (!Ext.isObject(padding)) {
      result = Ext.util.Format.parseBox(padding)
    } else if (!oldPadding) {
      result = padding
    } else {
      result = Ext.apply(oldPadding, padding)
    }

    return result
  },

  highlightPoint: function (record) {
    var me = this

    if (!me.store) return

    var myChart = me.getEcharts()

    me.resetDisplay()

    if (record) {
      myChart.dispatchAction({
        type: 'selectDataRange',
        visualMapIndex: 1,
        selected: [record.TotalRevenue, record.TotalRevenue]
      })
    }
  },

  highlightGroup: function (clusterId) {
    var me = this

    if (!me.store) return

    var myChart = me.getEcharts()

    me.resetDisplay()

    if (clusterId == null) return

    myChart.dispatchAction({
      type: 'selectDataRange',
      visualMapIndex: 0,
      selected: [clusterId, clusterId]
    })
  },

  resetDisplay: function () {
    var me = this

    var myChart = me.getEcharts()

    if (this.colors) {
      myChart.dispatchAction({
        type: 'selectDataRange',
        visualMapIndex: 0,
        selected: [0, this.colors.length - 1]
      })
    }

    if (this.maxSymbolSize) {
      myChart.dispatchAction({
        type: 'selectDataRange',
        visualMapIndex: 1,
        selected: [0, this.maxSymbolSize]
      })
    }
  }
})
