import { useState, useRef, useEffect, useCallback } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { SlvySelect, slvyToast } from '@/components'
import RequireFields from '../../RequiredFields'
import {
  getLookupField,
  initialLookupFieldItemState,
  MakeRequestActionForLookup,
  setLookupFieldList,
  SetLookupFieldListAction
} from '../../../store/slices/lookupField'
import { mapFilters, getLookupValuesFromString, omitCustomFields } from '../../../helpers'
import { RootState } from '../../../store'
import { useGenerateLookupHash } from '../../../hooks'
import { SelectStyles } from '../../../constants'
import { GridRef, IRowData, SlvyColDef, SlvyMasterColDef, Theme } from '../../../types'
import { LookupFieldCellEditorProps, Option } from './LookupFieldCellEditor.types'
import { AG_GRID_ENUMS } from '../../../enums'

const { LOOK_UP_FIELD_DATA, CUSTOM_ROW_INFO, ROW_ID } = AG_GRID_ENUMS
const menuPortalTarget = document.body as HTMLBodyElement

const LookupFieldCellEditor = (props: LookupFieldCellEditorProps) => {
  const {
    isMassUpdateModalVisible,
    shouldAutoStopEditing,
    lookupDataField,
    lookupQueryList,
    selectProps,
    api,
    context,
    stopEditing,
    onValueChange,
    value: pValue,
    node: { data },
    colDef: { field }
  } = props

  const hashParameters = {
    field,
    additionalArgs: context?.additionalArgs,
    actualFilters: context?.actualFilters,
    record: omitCustomFields(data as IRowData),
    lookupQueryList
  }

  const { getLookupHash } = useGenerateLookupHash(hashParameters)

  const [selected, setSelected] = useState<Option | null>(null)
  const [isSelectionCompleted, setIsSelectionCompleted] = useState(false)
  const refSelect = useRef<HTMLSelectElement | null>(null)

  const dispatch = useAppDispatch()
  const { lookupOptions, isRequested, isLoading, error } = useAppSelector((state: RootState) => {
    if (lookupDataField && field) {
      return (
        state.lookupField.fieldData?.[field]?.[data?.[CUSTOM_ROW_INFO]?.[ROW_ID] as string] ??
        initialLookupFieldItemState
      )
    }
    return state.lookupField.apiData?.[getLookupHash()] ?? initialLookupFieldItemState
  })

  useEffect(() => {
    if (!error?.length) {
      return
    }

    slvyToast.warning({
      component: <RequireFields messages={error as unknown as string[]} title="LookupField" />,
      options: { autoClose: 5000 }
    })
  }, [error])

  useEffect(() => {
    if (isRequested) {
      return
    }

    if (lookupDataField && data && field) {
      const newFieldLookupData = data[lookupDataField]
      const isNewFieldLookupDataDefined = typeof newFieldLookupData !== 'undefined'
      // eslint-disable-next-line no-nested-ternary
      const fieldData = data?.[CUSTOM_ROW_INFO]?.[LOOK_UP_FIELD_DATA]
        ? (newFieldLookupData as Option[])
        : isNewFieldLookupDataDefined
        ? getLookupValuesFromString((newFieldLookupData as string).toString()) // TODO: toString
        : [] // To cover a newly added row column editing scenario

      if (typeof fieldData === 'undefined') {
        return
      }

      const payload: SetLookupFieldListAction = {
        fieldData,
        field,
        rowId: data[CUSTOM_ROW_INFO][ROW_ID]
      }

      dispatch(setLookupFieldList(payload))
      return
    }

    const payload: MakeRequestActionForLookup = {
      hash: getLookupHash(),
      pluginId: context?.pluginId,
      method: `lookup/${field}`,
      requestMethod: 'post',
      body: {
        record: omitCustomFields(data as IRowData),
        updatehints: {},
        filters: {
          ...mapFilters({ current: { api } } as GridRef),
          ...context?.actualFilters
        }
      }
    }
    dispatch(getLookupField(payload))
  }, [
    isRequested,
    field,
    data,
    getLookupHash,
    lookupDataField,
    dispatch,
    context?.pluginId,
    api,
    context?.actualFilters
  ])

  const updateCustomInfo = useCallback(
    (newLookupFieldList: Option[]) => {
      // columnDefs reference is changing for each getColumnDefs call
      const columnDefs = api.getColumnDefs?.() as SlvyColDef[] | undefined

      const newColumnDefs: SlvyColDef[] = []
      columnDefs?.forEach((colDef: SlvyColDef) => {
        if (colDef?.children?.length) {
          const children: SlvyColDef[] = []
          colDef.children.forEach((child) => {
            if (field === child.field) {
              children.push({
                ...child,
                customInfo: { ...child.customInfo, lookupOptions: newLookupFieldList }
              })
            } else {
              children.push(child)
            }
          })
          newColumnDefs.push({ ...colDef, children })
        } else if (field === colDef.field) {
          newColumnDefs.push({
            ...colDef,
            customInfo: { ...colDef.customInfo, lookupOptions: newLookupFieldList }
          })
        } else {
          newColumnDefs.push(colDef)
        }
      })

      // IMPORTANT NOTE:
      // If we don't omit "sort" and "sortIndex", when we click column header to sort in pagination mode
      // onSortChanged event triggered with source "gridOptionsChanged", the options is sort and sortIndex changing below;
      // So, sort feature does not work with pagination, below fixes it
      // http://localhost:3000/Configuration/catalog/64a7bf816b6afd3cd177cba0/store/1/menu/657bff04b0158b87f58f9435/page/658546ae055781395efe6a2a

      // If we don't omit "row" and "rowGroupIndex", when we group a column that has `agSetFilterColumn`
      // then filter that column, then ungroup column, then Clear Filter for that column
      // In this scenario, column grouped automatically. So, below fixes it.
      // https://dragon-dev.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/1/menu/65c353047837bb86ff9e74f6/page/65c3531b7837bb86ff9e74f7
      const newColumnDefsWithoutSortAndGroup: SlvyMasterColDef[] = []
      newColumnDefs?.forEach((colDef: SlvyMasterColDef) => {
        if (colDef?.children?.length) {
          const children: SlvyColDef[] = []
          colDef.children.forEach((child) => {
            const { sort, sortIndex, rowGroup, rowGroupIndex, ...rest } = child
            children.push(rest)
          })
          const { sort, sortIndex, rowGroup, rowGroupIndex, ...rest } = colDef
          newColumnDefsWithoutSortAndGroup.push({ ...rest, children })
        } else {
          const { sort, sortIndex, rowGroup, rowGroupIndex, ...rest } = colDef
          newColumnDefsWithoutSortAndGroup.push(rest)
        }
      })

      api?.setGridOption('columnDefs', newColumnDefsWithoutSortAndGroup)
    },
    [api, field]
  )

  useEffect(() => {
    if (!lookupOptions.length) {
      return
    }

    updateCustomInfo(lookupOptions)

    // focus on the select
    refSelect?.current?.focus?.()
  }, [lookupOptions, updateCustomInfo, pValue, selected])

  useEffect(() => {
    if (shouldAutoStopEditing && isSelectionCompleted) {
      stopEditing()
    }
  }, [shouldAutoStopEditing, isSelectionCompleted, stopEditing])

  const onChange = (option: Option) => {
    setSelected(option)
    onValueChange(option?.id)
    setIsSelectionCompleted(true)
  }

  // TODO: check selected.value type
  const optionalProps: { placeholder?: string | number } = {}
  if (!shouldAutoStopEditing && selected?.value) {
    const selectedValueType = typeof selected?.value
    if (selectedValueType === 'number' || selectedValueType === 'string') {
      optionalProps.placeholder = selected?.value as string | number
    }
  }

  return (
    <div
      className={cx('w-100', { 'border border-2 border-light': isMassUpdateModalVisible })}
      style={{ minWidth: 200 }}
    >
      <SlvySelect
        {...selectProps}
        {...optionalProps}
        ref={refSelect}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getOptionLabel={(option: Option) => option.value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getOptionValue={(option: Option) => option.id}
        isLoading={isLoading}
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget}
        options={lookupOptions}
        styles={SelectStyles[context?.theme as Theme]}
        value={selected?.id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={onChange}
      />
    </div>
  )
}

export default LookupFieldCellEditor
