import { getGroupProps } from '../group'
import { GetGroupProps } from '../group.types'

const getGroupPropsParams = (): GetGroupProps => ({
  gridGrouping: true,
  rowGroup: true,
  cannotGroup: false
})

describe('AgGrid:Helpers:Group:getGroupProps', () => {
  let params = getGroupPropsParams()

  beforeEach(() => {
    params = getGroupPropsParams()
  })

  it('should return groupProps correctly for "gridGrouping" is true, "rowGroup" is true, and "cannotGroup" is false.', () => {
    const result = getGroupProps(params)
    expect(result).toEqual({ rowGroup: true, enableRowGroup: true })
  })

  it('should return groupProps correctly for "gridGrouping" is false', () => {
    params.gridGrouping = false
    const result = getGroupProps(params)
    expect(result).toEqual({ rowGroup: false, enableRowGroup: false })
  })

  it('should return groupProps correctly for "rowGroup" is false', () => {
    params.rowGroup = false
    const result = getGroupProps(params)
    expect(result).toEqual({ rowGroup: false, enableRowGroup: true })
  })

  it('should return groupProps correctly for "cannotGroup" is true', () => {
    params.cannotGroup = true
    const result = getGroupProps(params)
    expect(result).toEqual({ rowGroup: false, enableRowGroup: false })
  })
})
