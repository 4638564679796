export default `({
    bodyStyle: {
        background: '#ccc',
        //     padding: '2px'
    },
    bind: {
        spotId: '{spotRecord.SpotId}',
        statusCode:'{spotRecord.StatusCode}'
    },
    setSpotId: function (c) {
        if (c == null) {
            return
        }
        var store = this.getStore(),
            proxy = store.getProxy();
        if (!store.isLoading() && !this.ownerCt.hidden && store.proxy.extraParams.spotId!=c)
        {
            proxy.setExtraParam('spotId', c)
            store.load();
        }
    },
    setStatusCode:function(c)
    {
        this.StatusCode=c
    },
    reference: 'distributionGrid',
    margin: '0',
    header: false,
    plugins: {
        gridexporter: true
    },
    style: {
        background: 'rgb(209, 211, 214)'
    },
    dockedItems: [{
        xtype: 'toolbar',
        style: {
            'border-bottom': '1px solid rgb(193, 193, 193) !important;'
        },
        docked: 'top',
        height: 44,
        border: true,
        items: [{
                xtype: 'tbtext',
                html: '<b>'+tt('Spot Bölgesel Dağıtım')+'</b>',
            },
            {
                xtype: 'button',
                text: tt('Export to Excel'),
                handler: function (btn) {
                    var holder = btn.lookupReferenceHolder(),
                        grid = holder.lookupReference('distributionGrid')
                    grid.saveDocumentAs({
                        type: 'xlsx',
                        title: 'Report',
                        fileName: 'Report.xlsx',
                    });
                }
            },
            {
                showText: true,
                prependText: tt('View as '),
                xtype: 'cycle',
                margin: '0 50 0 0',
                hidden: Spui.Global.isMO(),
                menu: {
                    items: [{
                        text: tt('Own'),
                        iconCls: 'view-text',
                        checked: true,
                        itemId: 'Own'
                    }, {
                        text: tt('All'),
                        iconCls: 'view-html',
                        itemId: 'All'
                    }]
                },
                changeHandler: function (cycleBtn, activeItem) {
                    var store = cycleBtn.ownerCt.ownerCt.store,
                        proxy = store.getProxy();
                    proxy.setExtraParam('viewType', activeItem.itemId);
                    store.load();
                }
            }
        ]
    }],
    defaultListenerScope: true,
    calculateTotal: function () {
        var store = this.getStore();
        allRecords = store.snapshot || store.data,
            OrderRecommendation_DC = 0,
            OrderRecommendation_DC_UpdatedbyMO = 0,
            MaterialCode = '',
            MaterialDescription = '',
            UnitsPerPackage = 1,
            lastRecord = false,
            SalesPrice = 0,
            allRecords.each(function (record) {
                OrderRecommendation_DC = OrderRecommendation_DC + record.get('OrderRecommendation_DC');
                if (record.get('IsInclude')) {
                    OrderRecommendation_DC_UpdatedbyMO = OrderRecommendation_DC_UpdatedbyMO + record.get('OrderRecommendation_DC_UpdatedbyMO');
                }
                lastRecord = record
            });
        var viewModel = this.ownerCt.getViewModel();
        if (lastRecord) {
            MaterialCode = lastRecord.get('MaterialCode') * 1 + ' - ' + lastRecord.get('MaterialDescription');
            UnitsPerPackage = lastRecord.get('UnitsPerPackage')
            SalesPrice = lastRecord.get('SalesPrice')
        }
        sumRecord = Ext.create('Ext.data.Model', {
            MaterialCode: MaterialCode,
            SalesPrice: Ext.util.Format.number(SalesPrice, '0,000.00'),
            UnitsPerPackage: UnitsPerPackage,
            OrderRecommendation_DC: Ext.util.Format.number(OrderRecommendation_DC, '0,000') + ' ' + tt(' Koli'),
            OrderRecommendation_DCST: Ext.util.Format.number(OrderRecommendation_DC * UnitsPerPackage, '0,000') + ' ' + tt(' Adet'),
            OrderRecommendation_DC_UpdatedbyMO: OrderRecommendation_DC_UpdatedbyMO
        })
        viewModel.set('sumRecord', sumRecord)
    },
    features: [{
        ftype: 'summary',
        dock: 'top'
    }],
    onReCalcuulate: function (field, f, e, g) {
        var value = field.getValue();
        var menu = Ext.create('Ext.menu.Menu', {
            margin: '0 0 10 0',
            items: [{
                text: tt('Normal Dagitim '),
                handler: 'onClickdata',
                scope: this,
                calculateType: 0,
                sendValue: value
            }, {
                text: tt('Once Dusuk Ciro'),
                handler: 'onClickdata',
                scope: this,
                calculateType: 1,
                sendValue: value
            }, {
                text: tt('Once Yuksek Ciro'),
                handler: 'onClickdata',
                scope: this,
                calculateType: 2,
                sendValue: value
            }]
        });
        menu.showAt(e.getXY());
    },
    onClickdata: function (item) {
        var me = this,
            vieModel = me.getViewModel(),
            newValue = item.sendValue,
            calculateType = item.calculateType;
        me.onClickdataRequest(newValue, calculateType);
    },
    onClickdataRequest: function (newValue, calculateType) {
        var spotId = this.ownerCt.getSpotId()
        var me = this;
        me.setLoading(tt('Yükleniyor'));
        Ext.Ajax.request({
            url: SUI.Global.getApiUrl() + '/spui/recalculateoneri',
            method: 'POST',
            timeout: 600000,
            params: {
                SpotId: spotId,
                newValue: newValue,
                calculateType: calculateType
            },
            success: function (response, opts) {
                var obj = Ext.decode(response.responseText);
                me.setLoading(false);
                me.store.load();
                //me.reDrawChart(obj);
                // me.loadUserSettings();
            },
            failure: function (response, opts) {
                me.setLoading(false);
                console.log('server-side failure with status code ' + response.status);
            }
        });
    },
    title: tt('Oneri Listesi [Koli]'),
    header: {
        titlePosition: 0,
        // items: [{
        //     text: 'deneme',
        //     xtype: 'splitbutton',
        // }]
    },
    // header: false,
    margin: 2,
    header: false,
    columnLines: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            // rowParams.tstyle += 'height: 50px;';
            if (!record.get('IsInclude')) {
                //  rowParams.tstyle += "background-color:" + 'red' + ';';
                return 'row-blur';
            }
            return ''
            //      rowParams.tstyle = 'width:' + this.getTotalWidth() + ';';
            //  if (set_background) {
            //   rowParams.tstyle += "background-color:" + bgColor + ';';
            //  }
            //  if (set_foreground) {
            //   rowParams.tstyle += "color:" + fgColor + ';';
            //  }
        }
    },
    features: [{
        ftype: 'groupingsummary',
        groupHeaderTpl: ['{columnName}: {name} ({[values.children.length]})'],
        hideGroupedHeader: false,
        startCollapsed: false
    }, {
        ftype: 'summary',
        dock: 'bottom'
    }],
    columns: [
        // {
        //     xtype: 'checkcolumn',
        //     header: 'IsInclude?',
        //     dataIndex: 'IsInclude',
        //     headerCheckbox: false,
        //     width: 90,
        //     stopSelection: true,
        //     locked: true,
        //     summaryType: 'sum',
        //     summaryRenderer: function (value, summaryData, dataIndex, context) {
        //         sumObject = summaryData;
        //         context.innerCls = '';
        //         return '<b>' + Ext.String.format(tt('{0} DC{1}'), value, value !== 1 ? 's' : '') + '</b>';
        //     },
        //     listeners: {
        //         checkchange: 'oncheckchange',
        //     }
        // },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-list green',
                tooltip: tt('Bölgesel Dağıtım Detayı'),
                isActionDisabled: function(grid, rowIndex, colIndex, item, rec) {
                    return !grid.up().up().StatusCode.startsWith(2);
                },
                handler: function(grid, rowIndex, colIndex, item, e, record) {
                    var me = this;
                    var mainView = me.up('spuimainview');
                    var win = Ext.create('Spui.uxWindow', {
                        height: '80%',
                        width: 1150,
                        ui: 'sui-window',
                        record: record,
                        mainView: mainView,
                        title: tt('Bölgesel Dağıtım Detayı'),
                        uxItemCfg: 'regionalDistributionDetailsGridCfg',
                        uxItem: {
                            spotRecord: record,
                            viewModel: {
                                stores: {
                                    gridStore: {
                                        autoLoad: true,
                                        autoSync: false,
                                        fields: [{
                                            name: 'SpotID',
                                            type: 'int'
                                        }, ],
                                        proxy: {
                                            type: 'ajax',
                                            actionMethods: {
                                                read: 'POST',
                                            },
                                            api: {
                                                read: Spui.Global.getApiUrl() + 'spui/RegionalDistributionDetailsForDC',
                                            },
                                            writer: {
                                                rootProperty: 'data',
                                                allowSingle: false,
                                                successProperty: 'success',
                                                messageProperty: 'errors',
                                                allDataOptions: {
                                                    associated: false
                                                },
                                                writeAllFields: true
                                            },
                                            extraParams: {
                                                spotId: record.get('SpotID'),
                                                orgCode: record.get('Code')
                                            }
                                        },
                                    }
                                },
                                data: {
                                    SpotId: record.get('SpotId'),
                                    spotRecord: record
                                }
                            },
                        },
                        bodyPadding: 2,
                        modal: true,
                        dockedItems: [{
                            xtype: 'toolbar',
                            dock: 'bottom',
                            margin: 1,
                            items: ['->',
                            {
                                text: tt('Kapat'),
                                ui: 'btn-success',
                                handler: 'doClose'
                            }]
                        }],
                    });
                    win.show();
                }
            }]
        },
        {
            text: tt('Code'),
            dataIndex: 'Code',
            width: 100,
            // format: '000.00',
            hidden: true,
            align: 'center',
            locked: true
        },
        {
            text: tt('Name'),
            dataIndex: 'Name',
            width: 120,
            // format: '000.00',
            align: 'left',
            locked: true,
            summaryType: 'count',
            // summaryRenderer: function (value, summaryData, dataIndex, context) {
            //     sumObject = summaryData;
            //     if (!v || v === 0) return '<b>-</b>';
            //     return '<b> Total' + Ext.String.format(tt('{0} Distribution center{1}'), value, value !== 1 ? 's' : '') + '</b>';
            // }
        },
        {
            text: tt('StatusDescription'),
            dataIndex: 'StatusDescription',
            locked: true,
            width: 120
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            locked: true,
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            hidden: true,
        },
        {
            text: tt('NumberOfstore'),
            columns: [{
                    text: tt('All'),
                    dataIndex: 'NumberofStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                },
                {
                    text: tt('Recommended'),
                    dataIndex: 'NumberofRecommendedStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v, meta, record) {
                        // meta.tdCls = 'sas drilldown';
                        return v;
                    },
                    summaryType: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                }
            ]
        },
        {
            text: tt('Depo Envanter'),
            columns: [{
                text: tt('OnHand'),
                dataIndex: 'OnHandInventoryDC',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                // summaryType: function (records, property, root, begin, end) {
                //     var n = property.length,
                //         i, sum, v;
                //     sum = 0;
                //     for (i = 0; i < n; ++i) {
                //         v = property[i]
                //         if (records[i].get('IsInclude'))
                //             sum = sum + v;
                //     }
                //     return sum;
                // },
                //summaryType: function (records, property, root, begin, end) {
                //    var n = property.length,
                //        i, sum, v, sum2
                //    sum = 0;
                //    sum2 = 0
                //    for (i = 0; i < n; ++i) {
                //        v = property[i]
                //        sum = sum + v;
                //        if (records[i].get('IsInclude'))
                //            sum2 = sum2 + v;
                //    }
                //    return sum2 + " / " + sum;
                //},
                summaryType: 'sum',
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    sumObject = summaryData;;
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }, {
                text: tt('OpenOrder'),
                dataIndex: 'OpenOrderDC',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                summaryType: 'sum',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }]
        },
        {
            text: tt('Inventory_Store'),
            columns: [{
                    text: tt('OnHandInventory'),
                    dataIndex: 'OnHandInventoryStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryType: 'sum',
                    summaryRenderer: function (value, summaryData, dataIndex, context) {
                        if (!value || value === 0) return '<b>-</b>';
                        return '<b>' + Ext.util.Format.number(value, '0,000.00') + '</b>';
                    }
                },
                {
                    text: tt('OpenOrder'),
                    dataIndex: 'OpenOrderStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryRenderer: function (v, summaryData, dataIndex, context) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                }
            ]
        },
        {
            text: tt('OrderRecommendationParent'),
            dataIndex: 'OrderRecommendationParent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            summaryType: 'sum',
            summaryType: function (records, property, root, begin, end) {
                var n = property.length,
                    i, sum, v, sum2
                sum = 0;
                sum2 = 0
                for (i = 0; i < n; ++i) {
                    v = property[i]
                    sum = sum + v;
                    if (records[i].get('IsInclude'))
                        sum2 = sum2 + v;
                }
                return Ext.util.Format.number(sum2, '0,000.00') + " / " + Ext.util.Format.number(sum, '0,000.00');
            },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('OrderRecommendationCurrent'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: 'sum',
            summaryType: function (records, property, root, begin, end) {
                var n = property.length,
                    i, sum, v, sum2
                sum = 0;
                sum2 = 0
                for (i = 0; i < n; ++i) {
                    v = property[i]
                    sum = sum + v;
                    if (records[i].get('IsInclude'))
                        sum2 = sum2 + v;
                }
                return Ext.util.Format.number(sum2, '0,000.00') + " / " + Ext.util.Format.number(sum, '0,000.00');
            },
            // editor: {
            //     // completeOnEnter: false,
            //     // If the editor config contains a field property, then
            //     // the editor config is used to create the Ext.grid.CellEditor
            //     // and the field property is used to create the editing input field.
            //     field: {
            //         xtype: 'numberfield',
            //         allowBlank: false
            //     }
            // },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('OrderRecommendationChild'),
            dataIndex: 'OrderRecommendationChild',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: 'sum',
            summaryType: function (records, property, root, begin, end) {
                var n = property.length,
                    i, sum, v, sum2
                sum = 0;
                sum2 = 0
                for (i = 0; i < n; ++i) {
                    v = property[i]
                    sum = sum + v;
                    if (records[i].get('IsInclude'))
                        sum2 = sum2 + v;
                }
                return Ext.util.Format.number(sum2, '0,000.00') + " / " + Ext.util.Format.number(sum, '0,000.00');
            },
            // editor: {
            //     // completeOnEnter: false,
            //     // If the editor config contains a field property, then
            //     // the editor config is used to create the Ext.grid.CellEditor
            //     // and the field property is used to create the editing input field.
            //     field: {
            //         xtype: 'numberfield',
            //         allowBlank: false
            //     }
            // },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('Include'),
            dataIndex: 'IsInclude',
            align: 'center',
            width: 80
        },
        {
            text: tt('IsLocked'),
            dataIndex: 'IsLocked',
            align: 'center',
            width: 80
        },
        // {
        //     text: 'Comments',
        //     dataIndex: 'Comments',
        //     width: 200,
        // },
        // {
        //     text: 'LastModifiedBy',
        //     dataIndex: 'LastModifiedBy',
        //     width: 200
        // },
        // {
        //     text: 'LastModifiedAt',
        //     dataIndex: 'LastModifiedAt',
        //     width: 100
        // },
        // {
        //     text: 'CreatedAt',
        //     dataIndex: 'CreatedAt',
        //     width: 100
        // },
        // {
        //     text: 'IsInclude',
        //     dataIndex: 'IsInclude',
        //     width: 100
        // },
        //	{text:'StatusId',dataIndex:'StatusId',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
        //	{text:'StatusChangedAt',dataIndex:'StatusChangedAt',width:100},
        //	{text:'StatusChangedBy',dataIndex:'StatusChangedBy',width:200}
    ],
})
`
