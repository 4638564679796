import { Component } from 'react'

import _ from 'lodash'
import cx from 'classnames'
import { SketchPicker } from 'react-color'

import styles from './index.module.scss'
import presetColors from './constants'

export default class ColorPicker extends Component {
  constructor(props) {
    super(props)
    this.handleChangeColor = this.handleChangeColor.bind(this)
    this.handleResetColor = this.handleResetColor.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.state = {
      displayColorPicker: false
    }
  }

  UNSAFE_componentWillMount() {
    const { schema = {}, value } = this.props
    const val = !_.isUndefined(value) ? value : schema.default
    this.setState({ value: val })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value })
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleResetColor = () => {
    const { onChange } = this.props
    const defaultColorCode = 'rgba(255,255,255,0)'
    onChange(defaultColorCode)
  }

  handleChangeColor = (color) => {
    const colorCode = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`

    this.setState({ value: colorCode })
  }

  handleClear() {
    this.props.onChange('')
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
    this.props.onChange(this.state.value)
  }

  render() {
    const { value, displayColorPicker } = this.state
    const { value: background } = this.props

    return (
      <div className={cx('position-relative float-start pe-3 border', styles.picker)}>
        <div className={cx('w-100 cp', styles.colorBox)} onClick={this.handleClick}>
          <div className={cx('w-100 rounded-1', styles.color)} style={{ background }} />
        </div>
        <button
          className={cx('position-absolute p-0 border-0 bg-transparent', styles.clearBtn)}
          type="button"
          onClick={this.handleResetColor}
        >
          <i className="fa fa-times" />
        </button>
        {displayColorPicker ? (
          <div className={cx('position-absolute', styles.popover)}>
            <div
              className="position-fixed top-0 end-0 bottom-0 start-0"
              onClick={this.handleClose}
            />
            <SketchPicker
              color={value}
              presetColors={presetColors}
              onChange={this.handleChangeColor}
            />
          </div>
        ) : null}
      </div>
    )
  }
}
