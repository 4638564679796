Ext.define('Spui.view.common.productinfo.Main', {
  extend: 'Ext.panel.Panel',
  border: true,
  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-main',
  defaultListenerScope: true,
  publishes: ['materialRecord'],
  config: {
    searchBox: {},
    shortInfoCfg: 'MaterialShortInformation',
    shortInfo: {},
    tabpanelCfg: 'MaterialInfoTabPanel',
    tabpanel: {},
    materialCode: null,
    materialRecord: {}
  },
  header: false,
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },
  border: false,
  defaults: {
    frame: false
  },
  applyMaterialCode: function (c) {
    let me = this
    let wizardMain = me.up('spui-view-wizard-wizardmain')
    let wizardMainViewModel =
      typeof wizardMain.getViewModel === 'function' ? wizardMain.getViewModel() : null
    let wizardMainView =
      typeof wizardMainViewModel.getView === 'function' ? wizardMainViewModel.getView() : null

    if (wizardMainView) {
      let tabPanel = wizardMainView.lookupReference('tabpanel-info-mixin')
      if (tabPanel) {
        let vendorStore = tabPanel.getVendorStore()
        vendorStore.getProxy().setExtraParam('MaterialCode', c)
        vendorStore.load()
      }
    }

    var rec = Ext.create('Spui.data.model.Material', {
      MaterialCode: c
    })
    rec.load()
    this.setMaterialRecord(rec)
  },
  applySearchBox: function (b) {
    var main=this.up('spui-view-wizard-wizardmain')
    var searchDisabled=false
    if(main)
      searchDisabled=main.searchDisabled
    
      if (!b) return b
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        xtype: 'spui-view-common-productinfo-cmp-search-searchbox',
        disabled:searchDisabled
      })
      b = Ext.create(b)
    }
    return b
  },
  updateSearchBox: function (f, g) {
    if (!f) return f
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  applyShortInfoCfg: function (c) {
    return window.ss(c)
  },
  applyShortInfo: function (b) {
    var setRec = this.getShortInfoCfg()
    if (!setRec) {
      this.setDataView('test')
      setRec = this.getDataView()
    }
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'spui-view-common-productinfo-form-materialshortinfo',
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigureShortInfo'
              }
            ]
          }
        ]
      })
      b = Ext.create(b)
    }
    return b
  },
  updateShortInfo: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigureShortInfo: function () {
    var setRec = this.getShortInfoCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigureShortInfoSave, this)
  },
  onConfigureShortInfoSave: function (v, k) {
    var setRec = this.getShortInfoCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setShortInfo(res)
  },
  applyTabpanelCfg: function (c) {
    return window.ss(c)
  },
  applyTabpanel: function (b) {
    var setRec = this.getTabpanelCfg()
    if (!setRec) {
      this.setDataView('test')
      setRec = this.getDataView()
    }
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        xtype: 'spui-view-common-productinfo-cmp-tabpanel-main',
        flex: 1
      })
      b = Ext.create(b)
    }
    return b
  },
  updateTabpanel: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(2, f)
        } else {
          d.splice(2, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigureTabPanel: function () {
    var setRec = this.getTabpanelCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigureTabPanelSave, this)
  },
  onConfigureTabPanelSave: function (v, k) {
    var setRec = this.getTabpanelCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setTabpanel(res)
  }
})
