Ext.define('po.cmp.MassUpdateGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'massupdategrid',
  alias: 'widget.poMassUpdateGrid',
  header: false,
  bodyStyle: {
    background: '#fff'
  },
  bind: {
    i18n: '{i18n}'
  },
  setI18n: function (i18n) {
    if (i18n == null) return

    var me = this,
      gridfilters = me.getPlugin('gridfilters'),
      normalView = me.getView(),
      grouping = normalView.getFeature('grouping')
    grouping.expandTip = i18n.GridConfig_features_expandTip
    grouping.collapseTip = i18n.GridConfig_features_collapseTip
    gridfilters.menuFilterText = i18n.GridConfig_Plugins_menuFilterText
  },
  plugins: [
    {
      ptype: 'gridfilters',
      id: 'gridfilters'
    }
  ],
  features: [
    {
      ftype: 'grouping',
      id: 'grouping'
    }
  ],
  columns: [
    {
      dataIndex: 'urunKodu',
      text: 'Ürün Kodu',
      bind: {
        text: '{i18n.MassupdateGrid_Column_urunKodu}'
      },
      width: 115
    },
    {
      dataIndex: 'urunAciklamasi',
      text: 'Ürün',
      bind: {
        text: '{i18n.MassupdateGrid_Column_urunAciklamasi}'
      },
      width: 115
    },
    {
      xtype: 'numbercolumn',
      sortable: true,
      dataIndex: 'degisenOneriKoli',
      bind: {
        text: '{i18n.MassupdateGrid_Column_degisenOneriKoli}'
      },
      renderer: function (v) {
        return (
          "<span style='font-size:1em;color:#FF8600;font-weight:600'>" +
          Ext.util.Format.number(v, '0') +
          '</span>'
        )
      },
      width: 80,
      align: 'right'
    },
    {
      dataIndex: 'paletKatsayisi',
      text: 'Palet Katsayısı',
      bind: {
        text: '{i18n.MassupdateGrid_Column_paletKatsayisi}'
      },
      align: 'right',
      width: 125
    },
    {
      dataIndex: 'minSiparisMiktari',
      text: 'Minimum Siparis Miktari',
      tooltip: 'Minimum Siparis Miktari',
      bind: {
        text: '{i18n.MassupdateGrid_Column_minSiparisMiktari}',
        tooltip: '{i18n.MassupdateGrid_Column_minSiparisMiktari}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      width: 115,
      align: 'right'
    },
    {
      sortable: true,
      dataIndex: 'DepoSiparisliStokGun',
      width: 130,
      align: 'right',
      filter: {
        type: 'number'
      },
      renderer: function (v) {
        if (v == 0) return '-'
        return Ext.util.Format.number(v, '0.0')
      },
      bind: {
        text: '{i18n.MainGrid_Column_DepoSiparisliStokGun}',
        tooltip: '{i18n.MainGrid_Column_DepoSiparisliStokGun_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      sortable: true,
      dataIndex: 'DepoForecasteGoreStokGun',
      width: 130,
      align: 'right',
      filter: {
        type: 'number'
      },
      renderer: function (v) {
        if (v == 0) return '-'
        return Ext.util.Format.number(v, '0.0')
      },
      bind: {
        text: '{i18n.MainGrid_Column_DepoForecasteGoreStokGun}',
        tooltip: '{i18n.MainGrid_Column_DepoForecasteGoreStokGun_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    }
  ]
})
