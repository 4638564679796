Ext.define('Spui.view.common.Details.Main', {
  extend: 'Ext.window.Window',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-details-main',

  layout: 'fit',
  config: {
    SpotId: null,
    recomGridCfg: 'recomGridCfg',
    recomStoreCfg: 'recomStoreCfg',
    recomStore: {},
    recomGrid: {},
    applyRecomGridCfg: function (c) {
      return window.ss(c)
    },
    applyRecomStoreCfg: function (c) {
      return window.ss(c)
    },
    applyRecomStore: function (b) {
      var me = this
      var setRec = this.getRecomStoreCfg()

      var code = setRec.get('code')
      var settings = code ? code : '({})'
      var res = new Function('return ' + code)()

      if (b && !b.isStore) {
        Ext.applyIf(b, res)
        Ext.applyIf(b, {})

        b = Ext.create('Ext.data.Store', b)
      }
      return b
    },
    applyRecomGrid: function (b) {
      var setRec = this.getRecomGridCfg()

      var code = setRec.get('code')
      var settings = code ? code : '({})'
      var res = new Function('return ' + code)()

      if (b && !b.isComponent) {
        Ext.applyIf(b, res)

        Ext.applyIf(b, {
          region: 'center',
          xtype: 'grid',
          plugins: [
            {
              ptype: 'cellediting',
              clicksToEdit: 1,
              listeners: {
                edit: function (editor, context, eOpts) {
                  if (context.originalValue == context.value) return
                  var record = context.record,
                    grid = context.grid.ownerCt
                  grid.setLoading(tt('Yükleniyor'))
                  record.save({
                    failure: function (record, operation) {},
                    success: function (record, operation) {
                      grid.setLoading(false)
                      grid.store.load()
                    },
                    callback: function (record, operation, success) {
                      // do something whether the save succeeded or failed
                    }
                  })
                },
                scope: 'this'
              }
            },
            {
              ptype: 'cornerIcon',
              cfgButton: [
                '->',
                {
                  iconCls: 'x-fa fa-cog',

                  menu: [
                    {
                      text: 'Grid Config',
                      handler: 'onConfigRecomGrid'
                    },
                    {
                      text: 'store Config',
                      handler: 'onConfigRecomStore'
                    }
                  ]
                }
              ]
            }
          ],
          flex: 1,
          store: this.getRecomStore(),
          columns: [
            {
              text: 'ID',
              dataIndex: 'ID',
              width: 100,
              format: '000.00',
              align: 'right',
              xtype: 'numbercolumn'
            }
          ]
        })

        b = Ext.create(b)
      }

      return b
    },
    updateRecomGrid: function (f, g) {
      if (g) {
        g.destroy()
      }
      if (f) {
        var d = this.items
        if (d) {
          if (d.isMixedCollection) {
            this.insert(0, f)
          } else {
            d.splice(0, 0, f)
          }
        } else {
          this.items = [f]
        }
      }
    }
  },
  onConfigRecomGrid: function () {
    var setRec = this.getRecomGridCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        snippetPanel: {
          record: setRec
        },
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigRecomGridSave, this)
  },
  onConfigRecomGridSave: function (v, k) {
    var setRec = this.getRecomGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRecomGrid(res)
  },

  onConfigRecomGrid: function () {
    var setRec = this.getRecomGridCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigRecomGridSave, this)
  },

  onConfigRecomGridSave: function (v, k) {
    var setRec = this.getRecomGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setRecomGrid(res)
  }
})
