import { useCallback, useEffect } from 'react'
import { useGetColDefByField } from '../../../index'
import {
  getRegisterMethodFieldConfigsParams,
  getUpdateRowData,
  getSelectedRowsAfterFilterAndSort
} from '../../../../helpers'
import { IRowData } from '../../../../types'
import { SetMultiSelectRowValuesProps } from './useRegisterMethodSetMultiSelectRowValues.type'

// It should work if selection is not Spreadsheet
function useRegisterMethodSetMultiSelectRowValues({
  applyUpdate,
  fieldConfigs,
  gridRef,
  selectionType,
  multiRowsSetEventRef,
  registerMethod
}: SetMultiSelectRowValuesProps) {
  const isSpreadSheet = selectionType === 'Spreadsheet'
  const { getColDefByField } = useGetColDefByField(gridRef)

  const onSetMultiSelectRowValues = useCallback(
    (params: IRowData) => {
      if (isSpreadSheet) {
        return
      }
      const dataToUpdate: IRowData[] = []
      const selectedRows = getSelectedRowsAfterFilterAndSort(gridRef)
      selectedRows.forEach((selectedRow) => {
        // TODO: Need improvement for lookup fields,
        // Because we do not have lookupOptions yet,
        // We need to make an api call to take lookupOptions for each rows.
        const updatedRow = getUpdateRowData({
          fieldConfigs,
          getColDefByField,
          params,
          rowData: selectedRow
        })

        dataToUpdate.push(updatedRow)
      })
      if (dataToUpdate.length) {
        applyUpdate(dataToUpdate)
        multiRowsSetEventRef?.current?.handleMultiRowsSet()
      }
    },
    [applyUpdate, fieldConfigs, getColDefByField, gridRef, isSpreadSheet, multiRowsSetEventRef]
  )

  useEffect(() => {
    if (isSpreadSheet) {
      return
    }

    const setMultiSelectRowParams = getRegisterMethodFieldConfigsParams(fieldConfigs)

    registerMethod({
      args: setMultiSelectRowParams,
      key: 'setMultiSelectRowValues',
      fn: onSetMultiSelectRowValues
    })
  }, [onSetMultiSelectRowValues, registerMethod, fieldConfigs, isSpreadSheet])
}

export default useRegisterMethodSetMultiSelectRowValues
