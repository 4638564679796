export const MESSAGES = {
  an_unknown_error_occurred_when_getting_planning_colors:
    'An unknown error occurred when getting planning colors!',
  an_unknown_error_occurred: 'An unknown error occurred!',
  please_enter_a_pantone_code: 'Please enter a Pantone Code!',
  there_is_a_problem: 'There is a problem.',
  error: 'Error!',
  warning: 'Warning!',
  delete_pantone_color: 'Delete Pantone Color',
  are_you_sure_you_want_to_delete_this: 'Are you sure you want to delete this?',
  confirm_delete: 'Confirm Delete',
  cancel: 'Cancel',
  color: 'Color',
  main_colors: 'MAIN COLORS',
  loading: 'Loading',
  wait_for_design: 'Wait for design',
  deleting: 'Deleting',
  find: 'Find',
  planning_color: 'Planning Color',
  material_code_detail: 'Material Code / Detail',
  color_preview: 'Color Preview',
  custom_name: 'Custom Name',
  name: 'Name',
  rgb: 'RGB',
  hex_html: 'HEX/HTML',
  update: 'Update',
  no_colors_found: 'No colors found.',
  no_color_info: 'No Color Info',
  code_name: 'Code - Name',
  ref_code: 'Ref Code'
}
