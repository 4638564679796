import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import { RowProps } from './Row.types'

export default function Row({ children, className, isSelected, style, onClick }: RowProps) {
  const styles = useTheme()

  return (
    <div
      className={cx(styles.row, { [styles.isSelected]: isSelected }, className)}
      role="button"
      style={style}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={undefined}
    >
      {children}
    </div>
  )
}
