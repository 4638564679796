Ext.define('clustering.Plugin.ComboSelectAll', {
  extend: 'Ext.form.field.ComboBox',
  alias: 'plugin.selectAll',
  init: function (combo) {
    var fl = combo.getFieldLabel(),
      selectAllTpl =
        '<li id="' + combo.id + 'check" class="sel-all-chkbx">Select / Deselect All</li>',
      listTpl = '<li class="x-boundlist-item x-combo-list-item">{' + combo.displayField + '}</li>'

    combo.allSelected = combo.getStore().getDatas().length == combo.getValue().length

    Ext.apply(combo, {
      listConfig: {
        tpl: selectAllTpl + '<tpl for=".">' + listTpl + '</tpl>'
      }
    })

    combo.on({
      expand: function (combo) {
        var el = Ext.get(combo.id + 'check')

        el.on('click', function (el) {
          if (!combo.allSelected) {
            var data = combo.getStore().getDatas()
            var selectedItems = data.map(function (o) {
              return o.Key
            })
            combo.setValue(selectedItems)
            combo.allSelected = true
          } else {
            combo.setValue([])
            combo.allSelected = false
          }
        })
      }
    })

    combo.setValue(combo.getValue())
  }
})
