export const getRowClickedTypes = (PluginTypes) => {
  return {
    Filters: PluginTypes.string,
    LeftFields: PluginTypes.string,
    MfpId: PluginTypes.int,
    MfpScenarioId: PluginTypes.int,
    IsActualScenario: PluginTypes.int,
    ScenarioTableName: PluginTypes.string,
    PeriodAggregationLevel: PluginTypes.int,
    StartDate: PluginTypes.string,
    EndDate: PluginTypes.string,
    refreshKey: PluginTypes.string
  }
}
