require('../src/view/common/ux/Container')
require('../src/view/common/ux/TreeContainer')
require('../src/view/common/ux/uxChart')
require('../src/view/common/ux/uxPanel')
require('../src/view/common/ux/UxWindow')

require('../src/view/common/contract-recom/Mixin')
require('../src/view/common/contract-recom/Main')

require('../src/view/common/Details/Main')

require('../src/view/common/distribution-recom/Mixin')
require('../src/view/common/distribution-recom/Main')

require('../src/view/common/productinfo/cmp/search/SearchCombo')
require('../src/view/common/productinfo/cmp/search/SearchBox')
require('../src/view/common/productinfo/cmp/tabpanel/Info/Other')
require('../src/view/common/productinfo/cmp/tabpanel/Info/Mixin')
require('../src/view/common/productinfo/cmp/tabpanel/Info/Main')
require('../src/view/common/productinfo/cmp/tabpanel/Inventory/Mixin')
require('../src/view/common/productinfo/cmp/tabpanel/Inventory/Main')
require('../src/view/common/productinfo/cmp/tabpanel/SalesChart/SalesChartMixin')
require('../src/view/common/productinfo/cmp/tabpanel/SalesChart/Grid')
require('../src/view/common/productinfo/cmp/tabpanel/SalesChart/SalesChart')
require('../src/view/common/productinfo/cmp/tabpanel/SalesChart/SalesChartGrid')
require('../src/view/common/productinfo/cmp/tabpanel/SalesChart/Tabpanel')
require('../src/view/common/productinfo/cmp/tabpanel/SalesChart/Main')
require('../src/view/common/productinfo/cmp/tabpanel/Main')
require('../src/view/common/productinfo/cmp/ShortInfo')
require('../src/view/common/productinfo/Main')

require('../src/view/common/recom/Mixin')
require('../src/view/common/recom/Main')

require('../src/view/common/similarity/tabpanel/NomalizedChart/Mixin')
require('../src/view/common/similarity/tabpanel/NomalizedChart/TabPabel')
require('../src/view/common/similarity/tabpanel/NomalizedChart/Chart')
require('../src/view/common/similarity/tabpanel/NomalizedChart/Main')
require('../src/view/common/similarity/tabpanel/SalesChart/Mixin')
require('../src/view/common/similarity/tabpanel/SalesChart/TabPanel')
require('../src/view/common/similarity/tabpanel/SalesChart/Main')
require('../src/view/common/similarity/tabpanel/Mixin')
require('../src/view/common/similarity/tabpanel/Main')
require('../src/view/common/similarity/Mixin')
require('../src/view/common/similarity/Main')

require('../src/view/common/spotParameters/tabPanel/Mixin')
require('../src/view/common/spotParameters/tabPanel/Main')
require('../src/view/common/spotParameters/Form')
require('../src/view/common/spotParameters/Mixin')
require('../src/view/common/spotParameters/Main')
