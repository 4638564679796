import React from 'react'
import _ from 'lodash'
import { imageError, imageLoaded } from '../../utils'

export default class OptionImage extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    const {
      props: { source = '', alt = '', extraClass = '', isScrollEnd = true }
    } = this

    return isScrollEnd ? (
      <img
        alt={alt}
        className={`option-img img-fluid mx-auto -image-loading ${extraClass}`}
        src={source}
        onError={imageError}
        onLoad={imageLoaded}
      />
    ) : (
      <div className="option-img img-fluid mx-auto" />
    )
  }
}
