export const modes = ['Label', 'Button'] as const
export const buttonStyles = ['primary', 'success', 'info', 'warning', 'danger', 'link'] as const
export const placements = ['top', 'right', 'bottom', 'left'] as const
export const defaultForm = {
  title: '',
  height: 300,
  width: 300,
  selectedMode: 'Label'
} as const
export const containerSettings = {
  element: {}
} as const
