import React from 'react'
import Circle from './Circle'
import Horizontal from './Horizontal'

export default function Combined(props) {
  const {
    settings: {
      titleVal,
      titleSize,
      subTitleSize,
      subtitleVal,
      alignItems,
      flexDirection,
      padding
    } = {}
  } = props

  const progressContainerStyle = {
    height: `calc(100% - ${(titleVal ? titleSize : 0) + (subtitleVal ? subTitleSize : 0)}em)`
  }

  return (
    <div className="chart-wrapper -combined" style={{ alignItems, flexDirection }}>
      {titleVal && <h1 style={{ fontSize: `${titleSize}em` }}>{titleVal}</h1>}
      <div className="combined-progress" style={progressContainerStyle}>
        <Horizontal {...props} combined />
        <div style={{ width: `${padding * 2}em`, height: `${padding * 2}em` }} />
        <Circle {...props} combined />
      </div>
      {subtitleVal && <p style={{ fontSize: `${subTitleSize}em` }}>{subtitleVal}</p>}
    </div>
  )
}
