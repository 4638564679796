export default `({
    xtype: 'treepanel',
    margin: 1,
    border: true,
    // columns: [{
    //     xtype: 'treecolumn', //this is so we know which column will show the tree
    //     text: 'Task',
    //     width: 200,
    //     sortable: true,
    //     dataIndex: 'task',
    //     locked: true
    // }, {
    //     //we must use the templateheader component so we can use a custom tpl
    //     xtype: 'templatecolumn',
    //     text: 'Duration',
    //     width: 100,
    //     sortable: true,
    //     dataIndex: 'duration',
    //     align: 'center',
    //     //add in the custom tpl for the rows
    //     tpl: Ext.create('Ext.XTemplate', '{duration:this.formatHours}', {
    //         formatHours: function (v) {
    //             if (v < 1) {
    //                 return Math.round(v * 60) + ' mins';
    //             } else if (Math.floor(v) !== v) {
    //                 var min = v - Math.floor(v);
    //                 return Math.floor(v) + 'h ' + Math.round(min * 60) + 'm';
    //             } else {
    //                 return v + ' hour' + (v === 1 ? '' : 's');
    //             }
    //         }
    //     })
    // }, {
    //     text: 'Assigned To',
    //     flex: 1,
    //     dataIndex: 'user',
    //     sortable: true
    // }, {
    //     xtype: 'checkcolumn',
    //     header: 'Done',
    //     dataIndex: 'done',
    //     width: 70,
    //     stopSelection: false
    // }]
    rootVisible: true,
    columnLines: true,
    rowLines: true,
    columns: [{
            xtype: 'treecolumn', //this is so we know which column will show the tree
            text: tt('Title'),
            dataIndex: 'OrgTitle',
            flex: 2,
            sortable: true
        },
        {
            text: tt('NumberofStore'),
            dataIndex: 'NumberofStore',
            width: 150,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            renderer: function (v) {
                if (v === 0) {
                    return '-';
                }
                return Ext.util.Format.number(v, '0,000');
            }
        },
        {
            text: tt('Numberof RecommendedStore'),
            dataIndex: 'NumberofRecommendedStore',
            width: 150,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            renderer: function (v) {
                if (v === 0) {
                    return '-';
                }
                return Ext.util.Format.number(v, '0,000');
            }
        },
        {
            text: tt('Solvoyo Onerisi'),
            dataIndex: 'OrderRecommendationParent',
            width: 150,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            renderer: function (v) {
                if (v === 0) {
                    return '-';
                }
                return Ext.util.Format.number(v, '0,000');
            }
        },
        {
            text: tt('OrderRecommendation Updated'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 150,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            renderer: function (v) {
                if (v === 0) {
                    return '-';
                }
                return Ext.util.Format.number(v, '0,000');
            }
        },
        // ,{
        //     xtype: 'widgetcolumn',
        //     flex: 1,
        //     widget: {
        //         xtype: 'button',
        //         bind: '{record.task}',
        //         scope: this,
        //         handler: 'onActionClick'
        //     },
        // }
    ],
})
`
