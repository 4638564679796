Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.globalapp-geogrid-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {},
  onBoxReady: function () {
    var me = this
    var comp = this.getView().el
    if (!this.getViewModel().get('readOnlyMode')) {
      me.keyMap = new Ext.util.KeyMap({
        target: comp,
        ignoreInputFields: true,
        binding: [
          {
            ctrl: true,
            key: 'x',
            fn: me.onCopyRecord,
            scope: me
          },
          {
            ctrl: true,
            key: 'c',
            fn: me.onCopyRecord,
            scope: me
          },
          {
            ctrl: true,
            key: 'v',
            fn: me.onPasteRecord,
            scope: me
          }
        ]
      })
    }
  },
  onDPIActive: function () {
    var me = this,
      vm = me.getViewModel()
    if (!me.getView().isVisible()) return
    me.getView().setLoading('Loading data please wait.....')
    Ext.defer(function () {
      me.doLoadAll()
    }, 50)
  },
  doLoadAll: function () {
    var me = this,
      vm = me.getViewModel()
    if (!me.getView().isVisible()) return
    me.getView().setLoading('Loading data please wait.....')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_GEO_Hierarchies',
      timeout: 360000,
      success: function (response, opts) {
        var data = Ext.JSON.decode(response.responseText, true)
        if (data && data.MegaRegion) {
          vm.getStore('MegaRegionStore').loadRawData(data.MegaRegion)
        }
        if (data && data.Region) {
          vm.getStore('RegionStore').loadData(data.Region)
        }
        if (data && data.Country) {
          vm.getStore('CountryStore').loadData(data.Country)
        }
        if (data && data.SupplySource) {
          vm.getStore('SupplySourceStore').loadData(data.SupplySource)
        }
        var grid = me.lookupReference('megeregion')
        if (!grid.getSelection().length) grid.getSelectionModel().select(0)
        me.getView().setLoading(false)
      },
      failure: function (response, opts) {
        var icon = Ext.MessageBox['WARNING']
      }
    })
  },
  onbeforeDropToMagaregion: function (node, data, overModel, dropPosition, dropHandlers) {
    var grid = this.lookup('megaregion'),
      me = this
    // Defer the handling
    dropHandlers.wait = true
    // here you have the record from the treeview and can do anything you like with it.
    var record = data.records[0]
    Ext.MessageBox.confirm(
      'Move',
      'Your are about to move <span style="color:red">' +
        record.get('Region') +
        '</span> from <span style="color:red">' +
        record.get('MegaRegion') +
        '</span> to <span style="color:red">' +
        overModel.get('MegaRegion') +
        '</span>, Continue?',
      function (btn) {
        if (btn === 'yes') {
          record.set('MegaRegionID', overModel.get('MegaRegionID'))
          me.syncStore('RegionStore')
          dropHandlers.cancelDrop()
          grid.getSelectionModel().select(overModel)
        } else {
          // IMPORTANT - In this case, we want to cancel the drop as the records aren't compatible
          dropHandlers.cancelDrop()
        }
      }
    )
  },
  onbeforeDropToRegion: function (node, data, overModel, dropPosition, dropHandlers) {
    var me = this,
      vm = me.getViewModel()
    var cuttedFrom =
      data.view.grid.xtype == 'globalapp-geogrid-country' ? 'country' : 'supplysource'
    var cuttedRecord = data.records
    me.getViewModel().set('cuttedFrom', cuttedFrom)
    var RegionStore = vm.getStore('RegionStore')
    var targetRecord = overModel,
      targetName,
      targetId,
      fromName,
      fromId,
      targetRecord,
      fromRecord,
      listStr,
      fieldName
    fieldName = cuttedFrom == 'country' ? 'Country' : 'Description'
    if (!cuttedRecord) return
    fromId = cuttedRecord[0].get('RegionID')
    if (!targetRecord) return
    var targetId = targetRecord.get('RegionID')
    var recs = RegionStore.queryBy(function (rec) {
      return rec.get('RegionID') == fromId
    })
    if (!recs && recs.items.length < 1) {
      return
    }
    fromRecord = recs.items[0]
    targetName = targetRecord.get('Region')
    fromName = fromRecord.get('Region')
    listStr = ''
    cuttedRecord.forEach(function (record) {
      listStr += '<br>' + record.get(fieldName)
    })
    dropHandlers.cancelDrop()
    Ext.Msg.show({
      title:
        'Moving from <span style="color:yellow">' +
        fromName +
        '</span> <b> to </b> <span style="color:yellow"> ' +
        targetName,
      width: 500,
      msg: listStr,
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doPasteRecord(targetRecord, fromRecord, cuttedRecord)
        }
      }
    })
  },
  onMegaRegionStoreLoad: function () {
    var me = this,
      viewModel = me.getViewModel(),
      view = me.getView()
    var grid = me.lookupReference('megeregion')
  },
  onSelectMegaRegion: function (grid, record) {
    this.getViewModel().set('curMegaRegionRec', record)
  },
  onSelectRegion: function (grid, record) {
    this.getViewModel().set('curRegionRec', record)
  },
  onSelectCountry: function (grid, record) {
    this.getViewModel().set('curCountryRec', record)
  },
  onRegionStoreFilterChange: function () {
    var me = this,
      viewModel = me.getViewModel(),
      view = me.getView()
    var grid = me.lookupReference('region')
    grid.getSelectionModel().select(0)
  },
  onCountryStoreFilterChange: function () {
    var me = this,
      viewModel = me.getViewModel(),
      view = me.getView()
    var grid = me.lookupReference('country')
    grid.getSelectionModel().select(0)
  },
  onMegaRegionDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete ' + record.get('MegaRegion') + '',
      width: 500,
      closable: false,
      msg: 'This action will delete this entry as well as all associated entries in the hierarchical tree. Are you sure you want to continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDelete(record, 'MegaRegionStore')
        }
      }
    })
  },
  onRegionDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete ' + record.get('Region') + '',
      width: 500,
      msg: 'This action will delete this entry as well as all associated entries in the hierarchical tree. Are you sure you want to continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDelete(record, 'RegionStore')
        }
      }
    })
  },
  onCountryDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete ' + record.get('Country') + '?',
      width: 500,
      msg: 'This action will delete this entry as well as all associated entries in the hierarchical tree. Are you sure you want to continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDelete(record, 'CountryStore')
        }
      }
    })
  },
  onSupplySourceDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete ' + record.get('SupplySource') + '',
      width: 500,
      closable: false,
      msg: 'This action will delete this entry as well as all associated entries in the hierarchical tree. Are you sure you want to continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDelete(record, 'SupplySourceStore')
        }
      }
    })
  },
  doDelete: function (record, storename) {
    var me = this
    this.getViewModel().getStore(storename).remove(record)
    me.syncStore(storename)
  },
  onAddMegaRegion: function () {
    var newRec,
      grid = this.lookupReference('megeregion'),
      store = this.getViewModel().getStore('MegaRegionStore')
    newRec = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.model.MegaRegion', {})
    store.add(newRec)
  },
  onAddRegion: function () {
    var me = this,
      MegaRegionID = this.getViewModel().get('MegaRegionID'),
      record = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.model.Region', {
        MegaRegionID: MegaRegionID
      }),
      store = me.getViewModel().getStore('RegionStore'),
      view = this.getView()
    var win = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
      record: record,
      formType: 'Region',
      title: 'Region',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      if (!record.store) {
        store.add(record)
      }
      me.doSave(record)
    })
  },
  onAddCountry: function () {
    var newRec,
      me = this,
      MegaRegionID = this.getViewModel().get('MegaRegionID'),
      RegionID = this.getViewModel().get('RegionID'),
      grid = this.lookupReference('country'),
      store = this.getViewModel().getStore('CountryStore'),
      record = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.model.Country', {
        RegionID: RegionID,
        MegaRegionID: MegaRegionID
      })
    var win = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
      record: record,
      formType: 'Country',
      title: 'Country',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      if (!record.store) {
        store.add(record)
      }
      me.doSave(record)
    })
  },
  onAddSupplySource: function () {
    var newRec,
      me = this,
      MegaRegionID = this.getViewModel().get('MegaRegionID'),
      RegionID = this.getViewModel().get('RegionID'),
      grid = this.lookupReference('supplysource'),
      store = this.getViewModel().getStore('SupplySourceStore'),
      newRec = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.model.SupplySource', {
        RegionID: RegionID,
        MegaRegionID: MegaRegionID
      })
    var win = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
      record: newRec,
      formType: 'SupplySource',
      title: 'Supply Source',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      if (!this.record.store) {
        store.add(newRec)
      }
      me.doSave(newRec)
    })
  },
  onEditMegaRegion: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      view = this.getView()
    var win = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
      record: record,
      formType: 'MegaRegion',
      title: 'Mega Region',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      me.doSave(record)
    })
  },
  onEditRegion: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      view = this.getView()
    var win = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
      record: record,
      formType: 'Region',
      title: 'Region',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      me.doSave(record)
    })
  },
  onEditCountry: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      view = this.getView()
    var win = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
      record: record,
      formType: 'Country',
      title: 'Country',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      me.doSave(record)
    })
  },
  onEditSupplySource: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    var win = Ext.create('MDM.src.view.globalapp.Panel.GeoGrid.From.Main', {
      record: record,
      formType: 'SupplySource',
      title: 'Supply Source',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      me.doSave(record)
    })
  },
  doSave: function (record) {
    var me = this,
      view = this.getView()

    view.setLoading('Saving data please wait....!')
    record.save({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        view.setLoading(false)
        me.doLoadAll()
      },
      failure: function () {
        view.setLoading(false)
        console.log('server side error')
      }
    })
  },
  onCopyRecord: function (key, e) {
    if (e) {
      e.preventDefault()
    }
    var className = e.record.$className
    if (className == 'MDM.src.view.globalapp.Panel.GeoGrid.model.Country') {
      var grid = this.lookup('country')
      this.getViewModel().set('cuttedRecord', grid.getSelection())
      this.getViewModel().set('cuttedFrom', 'country')
    } else if (className == 'MDM.src.view.globalapp.Panel.GeoGrid.model.SupplySource') {
      var grid = this.lookup('supplysource')
      this.getViewModel().set('cuttedRecord', grid.getSelection())
      this.getViewModel().set('cuttedFrom', 'Region')
    } else this.getViewModel().set('copyFamilies')
  },
  onPasteRecord: function (grid, record, tr, rowIndex, e, eOpts) {
    var me = this,
      vm = me.getViewModel(),
      DPIFamilyStore = vm.getStore('DPIFamilyStore'),
      cuttedFrom = me.getViewModel().get('cuttedFrom'),
      RegionStore = vm.getStore('RegionStore'),
      cuttedRecord = this.getViewModel().get('cuttedRecord'),
      targetRecord = this.lookup('region').getSelection()
        ? this.lookup('region').getSelection()[0]
        : null,
      targetName,
      targetId,
      fromName,
      fromId,
      targetRecord,
      fromRecord,
      listStr,
      fieldName
    fieldName = cuttedFrom == 'country' ? 'Country' : 'Description'
    if (e) {
      e.preventDefault()
    }
    if (!cuttedRecord) return
    fromId = cuttedRecord[0].get('RegionID')
    if (!targetRecord) return
    targetId = targetRecord.get('RegionID')
    var recs = RegionStore.queryBy(function (rec) {
      return rec.get('RegionID') == fromId
    })
    if (!recs && recs.items.length < 1) {
      return
    }
    fromRecord = recs.items[0]
    targetName = targetRecord.get('Region')
    fromName = fromRecord.get('Region')
    listStr = ''
    cuttedRecord.forEach(function (record) {
      listStr += '<br>' + record.get(fieldName)
    })
    MDM.src.ux.MessageBox.show({
      title:
        'Moving from <span style="color:yellow">' +
        fromName +
        '</span> <b> to </b> <span style="color:yellow"> ' +
        targetName,
      width: 500,
      msg: listStr,
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doPasteRecord(targetRecord, fromRecord, cuttedRecord)
        }
      }
    })
  },
  doPasteRecord: function (targetRecord, fromRecord, pasteRecords) {
    var me = this,
      vm = me.getViewModel(),
      RegionStore = vm.getStore('RegionStore'),
      CountryStore = vm.getStore('CountryStore'),
      SupplySourceStore = vm.getStore('SupplySourceStore'),
      cuttedFrom = me.getViewModel().get('cuttedFrom'),
      store
    pasteRecords.forEach(function (record) {
      record.set('MegaRegionID', targetRecord.get('MegaRegionID'))
      record.set('RegionID', targetRecord.get('RegionID'))
    })
    var store = cuttedFrom == 'country' ? 'CountryStore' : 'SupplySourceStore'
    me.syncStore(store)
  },
  syncStore: function (storeName) {
    var store = this.getViewModel().getStore(storeName)
    var view = this.getView(),
      me = this
    view.setLoading('Saving data please wait....!')
    store.sync({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully deleted',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        view.setLoading(false)
        me.doLoadAll()
      },
      failure: function () {
        console.log('GroupDetailsStore onSave action server side error')
      }
    })
  }
})
