const AG_GRID_LOCALE_CUSTOM_EN = {
  massUpdate: 'Mass Update',
  refreshData: 'Refresh Data',
  clearFilters: 'Clear Filters',
  clearAllFilters: 'Clear All Filtering',
  clearAllSorting: 'Clear All Sorting',
  clearAllGrouping: 'Clear All Grouping',
  columnMenuSettings: 'Settings',
  requiredFields: 'Required Fields',
  invalidRows: 'Invalid Rows',
  value: 'Value',
  cancel: 'Cancel',
  save: 'Save',
  type: 'Type',
  valueAreNotAllowedToBeBlank: 'Value are not allowed to be blank!',
  overwrite: 'Overwrite',
  increment: 'Increment',
  percentage: 'Percentage',
  deleteAction: 'DELETE ACTION',
  youMustHaveChangesToSave: 'You must have changes to save!',
  anErrorOccurred: 'An Error Occurred',
  thresholdExceeded: 'Threshold Exceeded',
  invalidValue: 'Invalid Value',
  sheet: 'Sheet',
  valueCannotBeGreaterThan: (params: string | number) => `Value cannot be greater than ${params}`,
  valueCannotBeLowerThan: (params: string | number) => `Value cannot be lower than ${params}`,
  customAggregationFailed: (params: string | number) =>
    `Custom Aggregation Failed! Columns names: ${params}`,
  onlyNumericValuesAllowed: (params: string | number) =>
    `${params} is not numeric. Only numeric values are allowed!`,
  valueChangedMoreThanThreshold: (params: string | number) =>
    `The value has changed more than ${params} percent. Do you want to continue?`,
  fieldIsRequired: (params: string | number) => `${params} is required!`
}

export type AG_GRID_LOCALE_CUSTOM_EN_TYPES = typeof AG_GRID_LOCALE_CUSTOM_EN

export default AG_GRID_LOCALE_CUSTOM_EN
