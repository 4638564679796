Ext.define('MDM.src.view.Grouping.panel.Tree', {
  extend: 'Ext.tree.Panel',
  xtype: 'grouping-tree',
  rootVisible: true,
  checkPropagation: 'both',
  reference: 'tree',
  border: true,
  bind: {
    store: '{DPITreeStore}'
  },
  listeners: {
    activate: 'onActiveteTree'
  }
})
