Ext.define('tuiv2.cmp.SavePlanWindow', {
  extend: 'Ext.window.Window',
  xtype: 'tuiv2-savePlan',
  resize: false,
  iconCls: 'x-fa fa-pencil-square-o',
  modal: true,
  closable: false,
  ui: 'window-inverse',
  title: 'Save Plan',
  layout: 'fit',
  closeAction: 'hide',
  items: [
    {
      xtype: 'form',
      labelWidth: 100,
      defaults: {
        margin: '10px'
      },
      items: [
        {
          xtype: 'textfield',
          fieldLabel: 'Plan Name',
          name: 'planName',
          allowBlank: false
        },
        {
          xtype: 'textfield',
          fieldLabel: 'Plan Description',
          name: 'planDescription',
          allowBlank: false
        },
        {
          xtype: 'checkboxfield',
          fieldLabel: 'Resequence',
          name: 'reSequence'
        }
      ],
      buttons: [
        {
          text: 'OK',
          handler: 'onSavePlan'
        },
        {
          text: 'Cancel',
          handler: 'onCancelSavePlan'
        }
      ]
    }
  ]
})
