Ext.define('Assortment.cmp.SegmentGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'segment-grid',
  columnLines: true,
  rowLines: true,
  viewConfig: {
    enableTextSelection: true
  },
  plugins: [
    {
      ptype: 'cellediting',
      id: 'gridEditing',
      clicksToEdit: 1
    },
    {
      ptype: 'gridfilters',
      id: 'gridFilters'
    }
  ],  
  columns: [
    {
      text: 'Name',
      dataIndex: 'SegmentName',
      filter: {
        type: 'string'
      },
      flex: 1
    },
    {
      text: 'Start Date',
      align: 'center',
      dataIndex: 'StartDate',
      flex: 1,
      tdCls: 'blue',
      editor: {
        xtype: 'datefield',
        format: 'd/m/Y',
        allowBlank: false
      },
      listeners: {
        beforeRender: function () {
          var me = this.up('assortmentmainview')
          var vm = me.getViewModel()
          var isFutureOption = vm.get('IsFutureOption')
          if (!isFutureOption) this.editor = isFutureOption
        }
      },
      bind: {
        disabled: '{!IsFutureOption}'
      },
      renderer: function (value) {
        return Ext.util.Format.date(value, 'd/m/Y')
      },
      items: {
        xtype: 'datefield',
        reference: 'nameFilterField',
        flex: 1,
        enableKeyEvents: true,
        style: {
          width: '99%'
        },
        allowBlank: false,
        format: 'd/m/Y',
        bind: {
          value: '{StartDate}'
        },

        listeners: {
          change: function () {
            var window = this.up('window')

            var viewModel = window.getViewModel()

            var record = viewModel.get('Record')

            var segmentStore = viewModel.get('SegmentStore')

            var previousStartDate = viewModel.get('previousStartDate')

            if (
              previousStartDate == null &&
              record.get('StartDate').getTime() == this.value.getTime()
            ) {
              return
            }

            if (this.value.getTime() > viewModel.get('EndDate').getTime()) {
              this.setValue(previousStartDate != null ? previousStartDate : record.get('StartDate'))
              return
            }

            var value = this.value

            segmentStore.each(function (record) {
              record.set('StartDate', value)
            })

            viewModel.set('StartDate', value)
            viewModel.set('previousStartDate', value)
          },
          buffer: 500
        }
      }
    },
    {
      text: 'End Date',
      align: 'center',
      dataIndex: 'EndDate',
      flex: 1,
      tdCls: 'blue',
      editor: {
        xtype: 'datefield',
        format: 'd/m/Y',
        allowBlank: false
      },
      listeners: {
        beforeRender: function () {
          var me = this.up('assortmentmainview')
          var vm = me.getViewModel()
          var isFutureOption = vm.get('IsFutureOption')
          if (!isFutureOption) this.editor = isFutureOption
        }
      },
      bind: {
        disabled: '{!IsFutureOption}'
      },
      renderer: function (value) {
        return Ext.util.Format.date(value, 'd/m/Y')
      },
      items: {
        xtype: 'datefield',
        reference: 'nameFilterField',
        flex: 1,
        enableKeyEvents: true,
        style: {
          width: '99%'
        },
        allowBlank: false,
        format: 'd/m/Y',
        bind: {
          value: '{EndDate}'
        },
        listeners: {
          change: function () {
            var window = this.up('window')

            var viewModel = window.getViewModel()

            var record = viewModel.get('Record')

            var segmentStore = viewModel.get('SegmentStore')

            var previousEndDate = viewModel.get('previousEndDate')

            var value = this.value

            if (
              previousEndDate == null &&
              record.get('EndDate').getTime() == this.value.getTime()
            ) {
              return
            }

            if (this.value.getTime() <= viewModel.get('StartDate').getTime()) {
              this.setValue(previousEndDate != null ? previousEndDate : record.get('EndDate'))
              return
            }

            segmentStore.each(function (record) {
              record.set('EndDate', value)
            })

            viewModel.set('EndDate', value)
            viewModel.set('previousEndDate', value)
          },
          buffer: 500
        }
      }
    },
    {
      text: 'Active Store Count',
      dataIndex: 'NumberOfActiveStores',
      id: 'ActiveStore',
      align: 'right',
      flex: 1,
      summaryType: 'sum'
    },
    {
      text: 'Duration (Weeks)',
      dataIndex: 'AggPeriodCount',
      align: 'right',
      id: 'Duration',
      flex: 1,
      summaryType: 'average'
    },
    {
      text: 'Depth',
      dataIndex: 'Depth',
      align: 'right',
      flex: 1,
      summaryType: 'average',
      tdCls: 'blue',
      editor: {
        xtype: 'numberfield',
        allowBlank: false,
        minValue: 0
      },
      listeners: {
        beforeRender: function () {
          var me = this.up('assortmentmainview')
          var vm = me.getViewModel()
          var isFutureOption = vm.get('IsFutureOption')
          if (!isFutureOption) this.editor = isFutureOption
        }
      },
      bind: {
        disabled: '{!IsFutureOption}'
      },
      items: {
        xtype: 'numberfield',
        flex: 1,
        style: {
          width: '99%'
        },
        allowBlank: false,
        minValue: 0,
        listeners: {
          change: function () {
            var window = this.up('window')

            var viewModel = window.getViewModel()

            var record = viewModel.get('Record')

            var segmentStore = viewModel.get('SegmentStore')

            if (record.get('Depth') == this.value) {
              return
            }

            var value = this.value

            segmentStore.each(function (record) {
              record.set('Depth', value)
            })

            viewModel.set('Depth', value)
          },
          buffer: 500
        }
      }
    },
    {
      text: 'Adjustment Factor',
      dataIndex: 'AdjustmentFactor',
      align: 'right',
      flex: 1,
      summaryType: 'average',
      tdCls: 'blue',
      editor: {
        xtype: 'numberfield',
        allowBlank: false,
        step: 0.01,
        minValue: 0
      },
      listeners: {
        beforeRender: function () {
          var me = this.up('assortmentmainview')
          var vm = me.getViewModel()
          var isFutureOption = vm.get('IsFutureOption')
          if (!isFutureOption) this.editor = isFutureOption
        }
      },
      bind: {
        disabled: '{!IsFutureOption}'
      },
      items: {
        xtype: 'numberfield',
        flex: 1,
        style: {
          width: '99%'
        },
        allowBlank: false,
        step: 0.01,
        minValue: 0,
        listeners: {
          change: function () {
            var window = this.up('window')

            var viewModel = window.getViewModel()

            var record = viewModel.get('Record')

            var segmentStore = viewModel.get('SegmentStore')
            if (
              record.get('AdjustmentFactor') == this.value ||
              this.value < 0 ||
              isNaN(this.value * 1)
            ) {
              return
            }
            var value = this.value
            segmentStore.each(function (record) {
              record.set('AdjustmentFactor', value)
              record.set('PricingFactor', value)
            })
            viewModel.set('AdjustmentFactor', value)
            viewModel.set('PricingFactor', value)
          },
          buffer: 500
        }
      }
    },
    {
      text: 'Forecast',
      dataIndex: 'TotalSales',
      align: 'right',
      flex: 1,
      summaryType: 'sum',
      renderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      },
      summaryRenderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      }
    },
    {
      text: 'Edited Forecast',
      dataIndex: 'EditedForecast',
      align: 'right',
      flex: 1,
      id: 'EditedForecast',
      summaryType: 'sum',
      renderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      },
      summaryRenderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      }
    },
    {
      text: 'MinPresentation',
      dataIndex: 'MinPresentation',
      align: 'right',
      flex: 1,
      summaryType: 'sum',
      renderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      },
      summaryRenderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      }
    },
    {
      text: 'BuyQuantity',
      dataIndex: 'BuyQuantity',
      id: 'BuyQuantity',
      align: 'right',
      flex: 1,
      summaryType: 'sum',
      renderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      },
      summaryRenderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      }
    },
    {
      text: 'ROS',
      dataIndex: 'ROS',
      align: 'right',
      flex: 1,
      summaryRenderer: function (value, summaryData, dataIndex) {
        var result = 0
        var totalEditedForecast = summaryData['EditedForecast']
        var avgDuration = summaryData['Duration']
        var totalActiveStore = summaryData['ActiveStore']
        if(totalActiveStore > 0 && avgDuration > 0){
          result = totalEditedForecast / totalActiveStore / avgDuration
        }
        return Ext.util.Format.number(result, '0.00')
      }
    },
    {
      text: 'Final ROS',
      dataIndex: 'FinalROS',
      align: 'right',
      flex: 1,
      summaryRenderer: function (value, summaryData, dataIndex) {
        var result = 0
        var totalBuyQuantity = summaryData['BuyQuantity']
        var avgDuration = summaryData['Duration']
        var totalActiveStore = summaryData['ActiveStore']
        if(totalActiveStore > 0 && avgDuration > 0){
          result = totalBuyQuantity / totalActiveStore / avgDuration
        }
        return Ext.util.Format.number(result, '0.00')
      }
    },
    {
      text: 'Comment',
      dataIndex: 'Comment',
      align: 'right',
      editor: {
        xtype: 'textfield'
      },
      listeners: {
        beforeRender: function () {
          var me = this.up('assortmentmainview')
          var vm = me.getViewModel()
          var isFutureOption = vm.get('IsFutureOption')
          this.editor = isFutureOption
        }
      },
      flex: 1
    }
  ]
})
