Ext.define('MDM.src.view.regionapp.Grid.MaterialAssigned', {
  extend: 'Ext.grid.Panel',
  xtype: 'regionapp-grid-materialassigned',
  columnLines: true,
  canDragToTree: true,
  rowLines: false,
  layout: 'border',
  lockedGridConfig: {
    header: false,
    width: 600,
    split: true,
    forceFit: false,
    stateful: true,
    stateId: 'rmdmmaterialassignedlocked'
  },
  lockedViewConfig: {
    scroll: 'horizontal'
  },
  listeners: {
    // this event notifies us when the document was saved
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady',
    activate: 'onActivate',
    beforedrag: 'onBeforeDrag',
    enddrag: 'onEndDrag',
    dropped: 'onDropped'
  },
  emptyText: '<h3> No Record Found <h3>',
  plugins: [
    'gridfilters',
    'gridexporter',
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],

  selModel: {
    selType: 'checkboxmodel',
    checkOnly: true,
    showHeaderCheckbox: true,
    updateHeaderState: function () {
      // check to see if all records are selected
      var me = this

      var store = me.store

      var storeCount = store.getCount()

      var views = me.views

      var hdSelectStatus = false

      var selectedCount = 0

      var selected

      var len

      var i
      if (!store.isBufferedStore && storeCount > 0) {
        selected = me.selected
        hdSelectStatus = true
        for (i = 0, len = selected.getCount(); i < len; ++i) {
          if (store.indexOfId(selected.getAt(i).id) > -1) {
            ++selectedCount
          }
        }
        hdSelectStatus = selectedCount > 0
      }
      if (views && views.length) {
        me.column.setHeaderStatus(hdSelectStatus)
      }
    },
    listeners: {
      selectionchange: 'onSelectionChangeMainGrid',
      beforeselect: function (el, record, index, eOpts) {
        if (!record.get('isEditable')) {
          return false
        }
      }
    }
  },
  features: [
    {
      ftype: 'grouping',
      startCollapsed: false,
      groupHeaderTpl: '{name} ({rows.length} Material{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  viewConfig: {
    loadMask: {
      msg: 'Loading Assigned Materials Data Please wait...'
    },
    getRowClass: function (record) {
      return record.get('isEditable') ? 'editable-row' : 'noneditable-row'
    },
    stripeRows: true,

    plugins: {
      ptype: 'gridviewdragdrop',
      ddGroup: 'grid-to-form',
      enableDrop: false,
      dragZone: {
        onBeforeDrag: function (data, e) {
          if (data && data.records.length == 0) {
            return false
          }
        },
        onDrag: function (e) {
          var grid = this.view.grid.ownerGrid
          grid.fireEvent('beforedrag', e)
          return true
        },

        onEndDrag: function (data, e) {
          var grid = data.view.grid.ownerGrid
          grid.fireEvent('enddrag', e)
        }
      }
    },

    listeners: {
      beforedrop: function (node, data, overModel, dropPosition, dropHandlers) {
        dropHandlers.cancelDrop()
        var me = this
        setTimeout(function () {
          data.view.getStore().remove(data.records)
          me.getStore().add(data.records)
          me.setSelection(null)
        }, 0)
      },
      cellclick: function (view, cell, cellIndex, record) {
        var clickedDataIndex = view.panel.headerCt.getHeaderAtIndex(cellIndex).dataIndex
        var clickedColumnName = view.panel.headerCt.getHeaderAtIndex(cellIndex).text
        var clickedCellValue = record.get(clickedDataIndex)
        if (clickedDataIndex == 'numberInBOM') {
          var grid = Ext.create({
            xtype: 'ownerList'
          })

          var win = Ext.create('Ext.window.Window', {
            layout: 'fit',
            height: 300,
            ui: 'window-inverse',
            modal: true,
            iconCls: 'fa fa-pencil-square',
            width: 600,
            title: 'List',
            items: [grid]
          })
          win.show()
        }
      }
    }
  },
  columns: [
    {
      xtype: 'actioncolumn',
      dataIndex: 'MaterialDescription',
      menuDisabled: true,
      sortable: false,
      enableCellDrag: true,
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          enableCellDrag: true,
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },

          tooltip: 'Edit',
          handler: 'onEditMaterial'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'MaterialDescription',
      menuDisabled: true,
      sortable: false,
      enableCellDrag: true,
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-trash-o',
          enableCellDrag: true,
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          tooltip: 'Suppress from all lists and make this material inactive ',
          handler: 'onDropped'
        }
      ]
    },
    {
      text: 'Material',
      locked: true,
      flex: 1,
      sortable: true,
      width: 90,
      maxWidth: 120,
      dataIndex: 'MaterialCode',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      editor: {
        allowBlank: false,
        editable: false
      },
      exportStyle: [
        {
          // the type key means that this style will only be used by the html exporter
          // and for all others the default one, defined above, will be used
          alignment: {
            horizontal: 'Left'
          },
          width: 100,
          font: {
            bold: true,

            color: '#rrggbb'
          }
        }
      ]
    },
    {
      text: 'Description',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 200,
      locked: true,
      editor: {
        allowBlank: false,
        editable: false
      },

      dataIndex: 'MaterialDescription',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      exportStyle: [
        {
          // the type key means that this style will only be used by the html exporter
          // and for all others the default one, defined above, will be used

          alignment: {
            horizontal: 'Left'
          },
          width: 400,
          font: {
            bold: true,

            color: '#rrggbb'
          }
        }
      ]
    },

    {
      text: 'Appears On Extract',
      tooltip: 'Appears On Extract',
      flex: 1,
      sortable: true,
      minWidth: 70,
      dataIndex: 'AppearOnExtractYesOrNo',
      filter: 'list',
      locked: true
    },
    {
      text: 'Appears On Subregion',
      tooltip: 'Appears On Subregion',
      flex: 1,
      sortable: true,
      minWidth: 70,
      dataIndex: 'AppearsOnSubregion',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      locked: true
    },
    {
      text: 'Hierarchy Text',
      tooltip: 'Hierarchy Text',
      flex: 1,
      sortable: true,
      minWidth: 120,
      dataIndex: 'HierarchyText',
      hidden: true,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      locked: true
    },

    {
      xtype: 'actioncolumn',
      dataIndex: 'AppearOnExtractYesOrNo',
      menuDisabled: true,
      sortable: false,
      menuText: 'Extract Details',
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-external-link-square',

          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('AppearsOnExtract')
          },

          tooltip: 'Extraction details',
          handler: 'onExtractDetails'
        }
      ]
    },
    {
      text: 'DPI Family',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 120,
      locked: false,
      editor: {
        allowBlank: false,
        editable: false
      },
      dataIndex: 'DPIFamily',

      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Cartridge Family',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 120,
      locked: false,
      editor: {
        allowBlank: false,
        editable: false
      },
      dataIndex: 'CartridgeFamily',

      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'Number Of Cartridge',
      flex: 1,
      enableCellDrag: true,
      align: 'right',
      sortable: true,
      minWidth: 100,
      locked: false,
      editor: {
        allowBlank: false,
        editable: false
      },
      dataIndex: 'numofcartridge',
      filter: 'number'
    },
    {
      text: 'Material Type',
      width: 100,
      sortable: true,
      dataIndex: 'MaterialType',
      filter: 'list'
    },
    {
      text: 'BUOM',
      minWidth: 100,
      sortable: true,
      dataIndex: 'MaterialBUOM',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },

    {
      text: 'SU',
      width: 100,
      sortable: true,
      xtype: 'numbercolumn',
      format: '0.000000',
      align: 'right',
      dataIndex: 'SU',
      filter: 'number'
    },

    {
      text: 'GU Assigned',
      width: 120,
      // tdCls: 'red',
      align: 'right',
      sortable: true,
      format: '0',
      xtype: 'numbercolumn',
      dataIndex: 'GU_Default',
      filter: 'number'
    },
    {
      text: 'Excluded From Production',
      tooltip: 'Excluded from Production',
      sortable: true,
      minWidth: 70,
      dataIndex: 'ExcludedFromProductionYesorNo',
      filter: 'list',
      locked: false
    },
    {
      text: 'Excluded From Revenue',
      tooltip: 'Excluded from revenue',
      sortable: true,
      minWidth: 70,
      dataIndex: 'ExcludedFromRevenueYesorNo',
      filter: 'list',
      locked: false
    },

    {
      text: 'Region',
      width: 120,
      sortable: true,
      filter: 'list',

      dataIndex: 'MegaRegion'
    },

    {
      text: 'Brand',
      width: 120,
      sortable: true,

      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },

      dataIndex: 'Brand'
    },
    {
      text: 'Product Form Detail',
      width: 120,
      sortable: true,
      filter: 'list',

      dataIndex: 'ProductFormDetail'
    },
    {
      text: 'CSU Indicator',
      width: 120,
      sortable: true,
      filter: 'number',

      dataIndex: 'CSUIndicator'
    },
    {
      text: 'Modified BY',
      width: 120,
      sortable: true,
      filter: 'list',
      dataIndex: 'ModifiedBY'
    },
    {
      text: 'Modified Date',
      width: 120,
      sortable: true,
      filter: 'date',
      dataIndex: 'ModifiedDate'
    },
    {
      text: 'Supply Source',
      width: 120,
      sortable: true,
      filter: 'list',
      dataIndex: 'SupplySourceDescription'
    },

    {
      text: 'Data Source',
      width: 120,
      sortable: true,
      align: 'right',
      filter: 'list',
      dataIndex: 'DataSource'
    }
  ]
})
