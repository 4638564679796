import { forwardRef } from 'react'
import { Alert, Popover } from 'react-bootstrap'
import { useUniqueId } from '../../../hooks'
import { IMessagingServiceStatusProps } from './MessagingServiceStatus.types'

const MessagingServiceStatus = forwardRef(
  ({ signalrClientLog, title, ...rest }: IMessagingServiceStatusProps, ref) => {
    const [id] = useUniqueId()
    return (
      <Popover ref={ref} data-testid="messaging-service-status" id={id} {...rest}>
        <Popover.Header as="h3" className="mb-0 p-2">
          {title}
        </Popover.Header>
        <Popover.Body className="p-2">
          {signalrClientLog && signalrClientLog.length ? (
            signalrClientLog.map(
              ({ logLevelState = '', message = '', currentState = 'connecting' }, index) => {
                return logLevelState === 'Error' || logLevelState === 'Status' ? (
                  <div key={index} title={logLevelState}>
                    <i className={`-dot-info-icon fa fa-dot-circle -${currentState}`} />
                    {message}
                  </div>
                ) : null
              }
            )
          ) : (
            <Alert key="light" className="m-0 p-0" variant="light">
              <div className="text-center text-black">There are no logs!</div>
            </Alert>
          )}
        </Popover.Body>
      </Popover>
    )
  }
)

export default MessagingServiceStatus
