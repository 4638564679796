Ext.define('MDM.src.view.globalapp.Panel.Publish.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.globalapp-publish-mainviewmodel',
  stores: {
    MainStore: {
      model: 'MDM.src.view.globalapp.Panel.Publish.model.MainModel',
      listeners: {
        load: 'onMainStoreLoad'
      }
    },
    ChangeStore: {
      model: 'MDM.src.view.globalapp.Panel.Publish.model.Change',
      autoLoad: false
    }
  },
  data: {
    /* This object holds the arbitrary data that populates the ViewModel and is then available for binding. */
  }
})
