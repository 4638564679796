import { useEffect, ChangeEvent } from 'react'
import _ from 'lodash'
import { Form, Accordion } from 'react-bootstrap'
import { useAppSelector, useAppDispatch } from '@/hooks/store'
import ContainerHeader from '../../../Assignees/components/ContainerHeader'
import NoVariables from '../../../Assignees/components/NoVariables'
import { bodyHeight } from '../../../Assignees/constants'
import EventMethodButton from '../components/EventMethodButton'
import { IRunDrilldownProps, IPageData, Tcolumns, TmethodCol, TnameCol } from './RunDrilldown.types'

/* Crud */
import { selectCollection } from '@/crudoptV3'
import { getPages } from '@/actions/page'

const accordionContainerStyle = { maxHeight: bodyHeight - 15 }

const RunDrilldown = ({ catalogId, params, onSave, menuTree }: IRunDrilldownProps) => {
  const dispatch = useAppDispatch()

  const pages = useAppSelector((state) => {
    return selectCollection(getPages(catalogId, params.menuKey), state.model3)
  })

  useEffect(() => {
    if (pages.needFetch) {
      dispatch(pages.fetch)
    }
  }, [dispatch, pages])

  const handleChange = (pageId: string) => {
    const { pageId: prevPageId } = params
    onSave({ ...params, pageId: prevPageId === pageId ? null : pageId })
  }

  const handleMenuSelect = (menuKey: string) => {
    onSave({ ...params, menuKey, pageId: null })
  }

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    onSave({ ...params, addInPage: event.target.checked })
  }

  const handleCheckNewTab = (event: ChangeEvent<HTMLInputElement>) => {
    onSave({ ...params, openNewTab: event.target.checked })
  }

  const { menuKey = 0, pageId = null, addInPage = true, openNewTab = false } = params || {}
  const { data: pagesData = [] } = pages

  const columns: Tcolumns = [
    {
      text: 'Name',
      data: menuTree,
      countInfo: {
        id: 'name',
        active: menuTree?.filter?.((menu) => menu.id === menuKey)?.length ?? 0,
        total: _.size(menuTree)
      },
      title: <h6>Name</h6>
    },
    {
      text: 'Methods',
      data: pagesData,
      countInfo: {
        id: 'methods',
        active: pagesData?.filter?.((page: IPageData) => page.id === pageId)?.length ?? 0,
        total: _.size(pagesData)
      },
      title: <h6>Methods</h6>
    }
  ]
  const nameColumn = columns.find((column) => column.text === 'Name') as TnameCol
  const methodColumn = columns.find((column) => column.text === 'Methods') as TmethodCol

  return (
    <div className="table-responsive p-3" data-testid="run-drilldown-job">
      <div className="overflow-y-auto pe-1" style={accordionContainerStyle}>
        <Form.Check
          checked={addInPage}
          className="ms-1"
          data-testid="drilldown-add-in-page-checkbox"
          id="addIn"
          label="Add In"
          type="checkbox"
          onChange={handleCheck}
        />
        <Form.Check
          checked={openNewTab}
          className="ms-1 mb-3"
          data-testid="drilldown-open-in-new-tab-checkbox"
          id="openInNewTab"
          label="Open in new tab"
          type="checkbox"
          onChange={handleCheckNewTab}
        />
        {nameColumn.data.length > 0 || methodColumn.data.length > 0 ? (
          <Accordion alwaysOpen defaultActiveKey={columns.map((item) => item.countInfo.id)}>
            <Accordion.Item key={nameColumn.countInfo.id} eventKey={nameColumn.countInfo.id}>
              <Accordion.Header className="mb-0 pb-0">
                <ContainerHeader
                  active={nameColumn.countInfo.active}
                  title={nameColumn.title}
                  total={nameColumn.countInfo.total}
                  type=""
                />
              </Accordion.Header>
              <Accordion.Body className="p-3">
                {nameColumn.data.length ? (
                  <div className="d-flex flex-wrap gap-2" data-testid="drilldown-name-col">
                    {_.map(nameColumn.data, ({ id, name }) => {
                      return (
                        <EventMethodButton
                          key={id + name}
                          text={name}
                          variant={menuKey === id ? 'success' : 'outline-dark'}
                          onClick={() => handleMenuSelect(id)}
                        />
                      )
                    })}
                  </div>
                ) : (
                  <NoVariables text="No data found!" />
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item key={methodColumn.countInfo.id} eventKey={methodColumn.countInfo.id}>
              <Accordion.Header className="mb-0 pb-0">
                <ContainerHeader
                  active={methodColumn.countInfo.active}
                  title={methodColumn.title}
                  total={methodColumn.countInfo.total}
                  type=""
                />
              </Accordion.Header>
              <Accordion.Body className="p-3">
                {methodColumn.data.length ? (
                  <div className="d-flex flex-wrap gap-2" data-testid="drilldown-method-col">
                    {_.map(methodColumn.data, ({ id, name }) => {
                      return (
                        <EventMethodButton
                          key={id + name}
                          text={name}
                          variant={pageId === id ? 'success' : 'outline-dark'}
                          onClick={() => handleChange(id)}
                        />
                      )
                    })}
                  </div>
                ) : (
                  <NoVariables text="No data found!" />
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          <NoVariables text="No data found!" />
        )}
      </div>
    </div>
  )
}

export default RunDrilldown
