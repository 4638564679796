/**
 * Serie class for area range series type
 *
 * See {@link Chart.ux.Highcharts.RangeSerie} class for more info
 *
 */
Ext.define('Chart.ux.Highcharts.AreaRangeSerie', {
  extend: 'Chart.ux.Highcharts.RangeSerie',
  alternateClassName: ['highcharts.arearange'],
  type: 'arearange'
})
