// TODO: This is going to delete.

import { STORE_SCENARIO_PARAMETERS, STORE_SCENARIO_PARAMETERS_FAILED } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetScenarioParameters: {},
  error: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SCENARIO_PARAMETERS:
      return updateObject(state, { GetScenarioParameters: { ...action.payload }, error: {} })
    case STORE_SCENARIO_PARAMETERS_FAILED:
      return updateObject(state, {
        GetScenarioParameters: {},
        error: { ...action.payload }
      })
    default:
      return state
  }
}

export default reducer
