Ext.define('MDM.src.view.regionapp.Grid.Materialg11', {
  extend: 'Ext.grid.Panel',
  //xtype: 'regionapp-panel-unassignedGrid',
  xtype: 'regionapp-grid-materialg11',

  columnLines: true,
  canDragToTree: false,
  rowLines: true,
  layout: 'border',
  stateful: true,
  stateId: 'materialg11',
  lockedGridConfig: {
    header: false,
    width: 600,
    split: true,
    forceFit: false
  },
  lockedViewConfig: {
    scroll: 'horizontal'
  },
  listeners: {
    // this event notifies us when the document was saved
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady',
    activate: 'onActivate',
    beforedrag: 'onBeforeDrag',
    enddrag: 'onEndDrag',
    dropped: 'onDropped'
  },
  emptyText: '<h3> No Record Found <h3>',
  plugins: [
    'gridfilters',
    'gridexporter',
    {
      ptype: 'cellediting',
      clicksToEdit: 1
    }
  ],

  selModel: {
    selType: 'checkboxmodel',
    checkOnly: true,
    showHeaderCheckbox: true,
    disabled: true,
    renderer: function (value, metaData, record) {
      if (!record.get('inventory') + record.get('subInventory') < record.get('ShipQuantity')) {
        return '<div class="bloqued">&#160;</div>'
      }
      return '<div class="x-grid-checkcolumn">&#160;</div>'
    },
    updateHeaderState: function () {
      // check to see if all records are selected
      var me = this,
        store = me.store,
        storeCount = store.getCount(),
        views = me.views,
        hdSelectStatus = false,
        selectedCount = 0,
        selected,
        len,
        i
      if (!store.isBufferedStore && storeCount > 0) {
        selected = me.selected
        hdSelectStatus = true
        for (i = 0, len = selected.getCount(); i < len; ++i) {
          if (store.indexOfId(selected.getAt(i).id) > -1) {
            ++selectedCount
          }
        }
        hdSelectStatus = selectedCount > 0
      }
      if (views && views.length) {
        me.column.setHeaderStatus(hdSelectStatus)
      }
    },
    listeners: {
      selectionchange: 'onSelectionChangeMainGrid',
      beforeselect: function (el, record, index, eOpts) {
        if (!record.get('isEditable')) {
          return false
        }
      }
    }
  },

  viewConfig: {
    loadMask: {
      msg: 'Loading Materials Data Please wait...'
    },
    getRowClass: function (record) {
      return record.get('isEditable') ? 'editable-row' : 'noneditable-row'
    },
    stripeRows: true,
    plugins: {
      ptype: 'gridviewdragdrop',
      ddGroup: 'grid-to-form',
      enableDrop: false,
      dragZone: {
        onBeforeDrag: function (data, e) {
          data.fromType = 'new'
          if (data && data.records.length == 0) {
            return false
          }
        },
        onDrag: function (e) {
          var grid = this.view.grid.ownerGrid
          grid.fireEvent('beforedrag', e)
          return true
        },

        onEndDrag: function (data, e) {
          var grid = data.view.grid.ownerGrid
          grid.fireEvent('enddrag', e)
        }
      }
    },

    listeners: {
      beforedrop: function (node, data, overModel, dropPosition, dropHandlers) {
        dropHandlers.cancelDrop()
        var me = this
        setTimeout(function () {
          data.view.getStore().remove(data.records)
          me.getStore().add(data.records)
          me.setSelection(null)
        }, 0)
      },
      cellclick: function (view, cell, cellIndex, record) {
        var clickedDataIndex = view.panel.headerCt.getHeaderAtIndex(cellIndex).dataIndex
        var clickedColumnName = view.panel.headerCt.getHeaderAtIndex(cellIndex).text
        var clickedCellValue = record.get(clickedDataIndex)
        if (clickedDataIndex == 'numberInBOM') {
          var grid = Ext.create({
            xtype: 'ownerList'
          })

          var win = Ext.create('Ext.window.Window', {
            layout: 'fit',
            height: 300,
            ui: 'window-inverse',
            modal: true,
            iconCls: 'fa fa-pencil-square',
            width: 600,
            title: 'List',
            items: [grid]
          })
          win.show()
        }
      }
    }
  },
  columns: [
    //   {
    //     xtype: 'actioncolumn',
    //     dataIndex: 'MaterialDescription',
    //     menuDisabled: true,
    //     sortable: false,
    //     enableCellDrag: true,
    //     width: 40,
    //     align: 'center',
    //     locked: true,
    //     items: [
    //       {
    //         iconCls: 'fa fa-pencil-square-o',
    //         enableCellDrag: true,
    //         isActionDisabled: function (grid, rowIndex, colIndex, item, record) {

    //           return !record.get('isEditable')
    //         },

    //         tooltip: 'Edit',
    //         handler: 'onEditMaterialNew'
    //       }
    //     ]
    //   },
    //   {
    //     xtype: 'actioncolumn',
    //     dataIndex: 'MaterialDescription',
    //     menuDisabled: true,
    //     sortable: false,
    //     enableCellDrag: true,
    //     width: 40,
    //     align: 'center',
    //     locked: true,
    //     items: [
    //       {
    //         iconCls: 'fa fa-trash-o',
    //         enableCellDrag: true,
    //         isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
    //           return !record.get('isEditable')
    //         },
    //         tooltip: 'Suppress from all lists and make this material inactive ',
    //         handler: 'onDropped'
    //       }
    //     ]
    //   },
    {
      text: 'Material',
      locked: true,
      flex: 1,
      sortable: true,
      width: 90,
      maxWidth: 120,
      dataIndex: 'MaterialCode',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      exportStyle: [
        {
          // the type key means that this style will only be used by the html exporter
          // and for all others the default one, defined above, will be used
          type: 'xls',

          alignment: {
            horizontal: 'Left'
          },
          font: {
            bold: true,
            italic: true,
            color: 'red',
            width: 100
          }
        }
      ],
      editor: {
        allowBlank: false,
        editable: false
      },
      renderer: function (value, metaData, record, rowIdx, colIdx, store) {
        //   metaData.tdAttr = 'data-qtip="' + value + '"'
        return value
      }
    },
    {
      text: 'Description',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 200,
      locked: true,
      editor: {
        allowBlank: false,
        editable: false
      },
      dataIndex: 'MaterialDescription',

      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },
    {
      text: 'DPI Family',
      columns: [
        {
          text: 'Solvoyo',
          width: 100,
          sortable: true,
          dataIndex: 'DPIFamily',
          filter: 'list'
        },
        {
          text: 'G11',
          width: 100,
          sortable: true,
          dataIndex: 'DPIFamily_G11',
          filter: 'list'
        },
        {
          text: 'Diff',
          width: 100,
          sortable: true,
          dataIndex: 'DPIFamily_Diff',
          filter: 'list'
        }
      ]
    },
    {
      text: 'Material Type',
      columns: [
        {
          text: 'Solvoyo',
          width: 100,
          sortable: true,
          dataIndex: 'MaterialType',
          filter: 'list'
        },
        {
          text: 'G11',
          width: 100,
          sortable: true,
          dataIndex: 'MaterialType_G11',
          filter: 'list'
        },
        {
          text: 'Diff',
          width: 100,
          sortable: true,
          dataIndex: 'MaterialType_Diff',
          filter: 'list'
        }
      ]
    },
    {
      text: 'BUOM',
      columns: [
        {
          text: 'Solvoyo',
          minWidth: 100,
          sortable: true,
          dataIndex: 'MaterialBUOM',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: 'Search for...'
            }
          }
        },
        {
          text: 'G11',
          minWidth: 100,
          sortable: true,
          dataIndex: 'MaterialBUOM_G11',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: 'Search for...'
            }
          }
        },
        {
          text: 'Diff',
          minWidth: 100,
          sortable: true,
          dataIndex: 'MaterialBUOM_Diff',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: 'Search for...'
            }
          }
        }
      ]
    },
    {
      text: 'SU',
      columns: [
        {
          text: 'Solvoyo',
          width: 100,
          sortable: true,
          xtype: 'numbercolumn',
          format: '0.000000',
          align: 'right',
          dataIndex: 'SU',
          filter: 'number'
        },
        {
          text: 'G11',
          width: 100,
          sortable: true,
          xtype: 'numbercolumn',
          format: '0.000000',
          align: 'right',
          dataIndex: 'SU_G11',
          filter: 'number'
        },
        {
          text: 'Diff',
          width: 100,
          sortable: true,
          xtype: 'numbercolumn',
          format: '0.000000',
          align: 'right',
          dataIndex: 'SU_Diff',
          filter: 'number'
        }
      ]
    },
    {
      text: 'GU Assigned',
      columns: [
        {
          text: 'Solvoyo',
          width: 120,
          //tdCls: 'red',
          align: 'right',
          sortable: true,
          format: '0',
          xtype: 'numbercolumn',
          dataIndex: 'GU_Default',
          filter: 'number'
        },
        {
          text: 'G11',
          width: 120,
          //tdCls: 'red',
          align: 'right',
          sortable: true,
          format: '0',
          xtype: 'numbercolumn',
          dataIndex: 'GU_G11',
          filter: 'number'
        },
        {
          text: 'Diff',
          width: 120,
          //tdCls: 'red',
          align: 'right',
          sortable: true,
          format: '0',
          xtype: 'numbercolumn',
          dataIndex: 'GU_G11',
          filter: 'number'
        }
      ]
    },
    {
      text: 'Supply Source',
      columns: [
        {
          text: 'Solvoyo',
          width: 120,
          sortable: true,
          filter: 'list',
          dataIndex: 'SupplySourceDescription'
        },
        {
          text: 'G11',
          width: 120,
          sortable: true,
          filter: 'list',
          dataIndex: 'SupplySource_G11'
        },
        {
          text: 'Diff',
          width: 120,
          sortable: true,
          filter: 'list',
          dataIndex: 'SupplySource_Diff'
        }
      ]
    },
    {
      text: 'Total Diff',
      sortable: true,
      minWidth: 70,
      dataIndex: 'Total_Diff',
      filter: 'number',
      format: '0',
      xtype: 'numbercolumn',
      locked: false
    }
  ]
})
