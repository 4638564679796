Ext.define('tuiv2.cmp.demandToList', {
  extend: 'Ext.Component',
  xtype: 'tuiv2-demandToList',
  draggable: true,
  tpl: new Ext.XTemplate(
    '   <ul class="popup" style="display:block;">' +
      '      <div class="titleBar">' +
      '          <p class="title">{Title}</p>' +
      '          <div class="card-close"><span class="slvy-ui-icon-times-extra-lt {CloseClass}"  index={#} ></span></div>' +
      '      </div>' +
      '      <ul class="cumulative-container">' +
      '             <li class="volume">{SelectedVolume}m3</li>' +
      '             <li class="weight">{SelectedWeight}Kg</li>' +
      '             <li class="demand-pallet-count">{SelectedPallet}P</li>' +
      '      </ul>' +
      '       <ul>' +
      '      <tpl for="Loads">' +
      '         <li class="{parent.LoadClass}" loadId="{Id}" ServiceContractId="{parent.ServiceContractId}" FromLoadId="{parent.FromLoadId}" FacilityKey="{parent.FacilityKey}">' +
      '           <div class="loadRow">' +
      '             <div class="{parent.class} {parent.LoadClass}" loadId="{Id}" ServiceContractId="{parent.ServiceContractId}" FromLoadId="{parent.FromLoadId}" FacilityKey="{parent.FacilityKey}" style="background:{Color}"></div>' +
      '             <p class="{parent.LoadClass}" loadId="{Id}" ServiceContractId="{parent.ServiceContractId}" FromLoadId="{parent.FromLoadId}" FacilityKey="{parent.FacilityKey}">{Id}</p>' +
      '           </div>' +
      '         </li>' +
      '      </tpl>' +
      '       </ul>' +
      '      <div class="popupActions">' +
      '          <div class="popupAction {UnloadClass}" FacilityKey="{FacilityKey}"  ServiceContractId="{ServiceContractId}"><span class="slvy-ui-icon-trash-can {UnloadClass}" FacilityKey="{FacilityKey}"  ServiceContractId="{ServiceContractId}"" index={#}></span><p class="{UnloadClass}" FacilityKey="{FacilityKey}"  ServiceContractId="{ServiceContractId}" index={#}>Remove</p></div>' +
      '          <div class="popupAction {CreateLoadClass}" LoadId="{ltlLoadId}" FacilityKey="{FacilityKey}" ServiceContractId="{ServiceContractId}" FromLoadId="{FromLoadId}"><span class="slvy-ui-icon-truck-outline {CreateLoadClass}" LoadId="{ltlLoadId}" FacilityKey="{FacilityKey}" ServiceContractId="{ServiceContractId}" FromLoadId="{FromLoadId}"></span><p class="{CreateLoadClass}" LoadId="{ltlLoadId}" FacilityKey="{FacilityKey}" ServiceContractId="{ServiceContractId}" FromLoadId="{FromLoadId}" index={#}>Create new load</p></div>' +
      '      </div>' +
      '<tpl if="tplMode == true">' +
      '     <ul class="moveToList">' +
      '      <tpl for="toList">' +
      '       <li><p class ="sendToTruck">{text} {id}</p></li>' +
      '      </tpl>' +
      '     </ul>' +
      '</tpl>' +
      '   </ul>'
  )
})
