export const getViewChangedTypes = (PluginTypes) => {
  return {
    View: PluginTypes.string
  }
}
// PivotView
// PivotDetailView
// PivotSingleDetailView
// PivotBuilder
// ScenarioTree
// ScenarioComparison
// Subset
