import { isGraphColumn } from '..'
import { SlvyColDef, TcustomConfigs } from '../../../types'
import { customConfigs } from '../../__tests__/mocks'
import { fieldConfig } from '../../filter/__tests__/mocks'

const isGraphColumnParams = (columnType: TcustomConfigs['columnType'] = 'simple'): SlvyColDef => ({
  customInfo: {
    ...customConfigs,
    columnType,
    field: 'col1',
    fieldConfig,
    fieldLookupConfig: {
      lookupQueryList: [],
      isLookupQuery: false,
      substituteField: null
    },
    deletableCondition: ''
  }
})

describe('AgGrid:Helpers:Column:isGraphColumn', () => {
  let params = isGraphColumnParams()

  beforeEach(() => {
    params = isGraphColumnParams()
  })

  it('should return true for a column with sparkline columnType', () => {
    params = isGraphColumnParams('sparkline')

    const result = isGraphColumn(params)
    expect(result).toBe(true)
  })

  it('should return true for a column with highchart columnType', () => {
    params = isGraphColumnParams('highchart')

    const result = isGraphColumn(params)
    expect(result).toBe(true)
  })

  it('should return false for a column with columnType not equal to sparkline or highchart', () => {
    const result = isGraphColumn(params)
    expect(result).toBe(false)
  })
})
