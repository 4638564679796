import * as actionTypes from './actionTypes'

export const collapse = (options) => {
  return {
    type: actionTypes.COLLAPSE,
    payload: options.payload
  }
}

export const collapseAll = (options) => {
  return {
    type: actionTypes.COLLAPSE_ALL,
    payload: options.payload
  }
}

export const unCollapse = (options) => {
  return {
    type: actionTypes.UNCOLLAPSE,
    payload: options.payload
  }
}

export const unCollapseBulk = (options) => {
  return {
    type: actionTypes.UNCOLLAPSE_BULK,
    payload: options.payload
  }
}
