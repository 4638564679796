import React, { memo } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
import { saveHeaderSettings } from '../../store/actions'

interface IHeaderUpdateModes {
  updateMode: number
  modeButtons: { modeId: number; name: string; isVisible: boolean }[]
}

const HeaderUpdateModes = ({ updateMode, modeButtons }: IHeaderUpdateModes) => {
  const dispatch = useDispatch()

  function changeUpdateMode(updateMode: number) {
    /**
     * 0: Cell
     * 1: Row
     * 2: Popup
     * */
    dispatch(
      saveHeaderSettings({
        payload: { updateMode: Number(updateMode) }
      })
    )
  }

  return (
    <div className="-update-mode">
      {modeButtons
        .filter(({ isVisible }) => isVisible)
        .map(({ modeId, name }, index) => {
          return (
            <button
              key={index}
              className={cx({ 'is-active': updateMode === modeId })}
              onClick={() => changeUpdateMode(modeId)}
            >
              {name} Update
            </button>
          )
        })}
    </div>
  )
}

const MemoizedHeaderUpdateModes = memo(
  HeaderUpdateModes,
  (prevProps: IHeaderUpdateModes, nextProps: IHeaderUpdateModes) => {
    return _.isEqual(prevProps, nextProps)
  }
)

export default MemoizedHeaderUpdateModes
