import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { slvyToast } from '../../../../components'
import dependencyFilters from '../../utils/dependencyFilters'
import { getSelectedFilters } from '../../utils'
import { getOptions } from '../../store/slices/options'
import { setLoaderReducer } from '../../store/slices/appSlice'

const defaultDependencyFilters = [...dependencyFilters]

class ReloadButton extends React.Component {
  constructor(props) {
    super(props)
    this.firstAssortmentId = null
    this.lastAssortmentId = null
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.isActualSeason !== this.props.isActualSeason
  }

  reloadProducts(isActualSeason) {
    if (!isActualSeason) {
      return
    }

    const {
      pluginId,
      rootOptions: { AssortmentId = null, ClusterName = '', ClusterNodeId = '', Filters = [] } = {},
      fetchOptions = () => {},
      setLoader = () => {}
    } = this.props

    this.firstAssortmentId = AssortmentId

    let str = getSelectedFilters(defaultDependencyFilters, [...Filters])
    str = str.map((item) => {
      if (item.Value === null) {
        item.Value = []
      }
      return item
    })

    const payload = {
      pluginId,
      method: 'Load',
      requestMethod: 'post',
      body: {
        DependencyFilterStr: str,
        ClusterNodeId,
        ClusterName
      }
    }

    setLoader({ isShown: true, messages: 'Reloading' })

    fetchOptions({ ...payload }).then(() => {
      const {
        rootOptions,
        rootOptions: { AssortmentId: lastAssortmentId = null } = {},
        errorRootOptions: { message: errorMessage = '' } = {}
      } = this.props

      if (_.isEmpty(rootOptions)) {
        slvyToast.error({ message: errorMessage, title: 'Reload Products' })
      } else {
        this.lastAssortmentId = lastAssortmentId
        if (this.firstAssortmentId !== this.lastAssortmentId) {
          this.overrideAssortmentData()
        }
      }
    })
  }

  overrideAssortmentData() {
    const { pluginId, fetchOptions = () => {}, setLoader = () => {} } = this.props

    const payload = {
      pluginId,
      /**
       * This is because Load and OverrideAssortmentData response is same, to get options.
       * */
      method: 'OverrideAssortmentData',
      requestMethod: 'post',
      body: {
        SourceOptionId: this.lastAssortmentId,
        TargetOptionId: this.firstAssortmentId
      }
    }

    fetchOptions({ ...payload }).then(() => {
      const {
        rootOptions,
        rootOptions: { Description = '' } = {},
        errorRootOptions: { message: errorMessage = '' } = {}
      } = this.props

      if (_.isEmpty(rootOptions)) {
        slvyToast.error({ message: errorMessage, title: 'Reload Products' })
      } else {
        slvyToast.success({ message: Description, title: 'Reload Products' })
      }

      setLoader({ isShown: false, messages: 'Reloading' })
    })
  }

  render() {
    const {
      props: { isActualSeason = true }
    } = this

    return (
      <div
        className={`option-btn ${isActualSeason ? '' : '-disabled'}`}
        onClick={this.reloadProducts.bind(this, isActualSeason)}
      >
        <i className="slvy-ui-icon-recycle" />
        <span>Reload Products</span>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    isLoadingRootOptions: state.options.isLoading,
    errorRootOptions: state.options.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOptions: bindActionCreators(getOptions, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReloadButton)
