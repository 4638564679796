import * as actionTypes from './actionTypes'
import { makeRequest } from '../../utils'

export const fetchComparisonConfiguration = (action) => {
  return (dispatch) => {
    dispatch(startGetComparisonConfigurationStart())

    return new Promise((resolve, reject) => {
      makeRequest(action)
        .then((response) => {
          dispatch(
            saveGetComparisonConfiguration({
              payload: response.data
            })
          )
          resolve(response.data)
        })
        .catch((error) => {
          dispatch(
            saveGetComparisonConfigurationFailed({
              payload: error.data
            })
          )
          reject(error.data)
        })
    })
  }
}

export const startGetComparisonConfigurationStart = () => {
  return {
    type: actionTypes.FETCH_COMPARISON_CONFIGURATION_START
  }
}

export const saveGetComparisonConfiguration = (options) => {
  return {
    type: actionTypes.STORE_COMPARISON_CONFIGURATION,
    payload: options.payload
  }
}

export const saveGetComparisonConfigurationFailed = (options) => {
  return {
    type: actionTypes.STORE_COMPARISON_CONFIGURATION_FAILED,
    payload: options.payload
  }
}
