import React, { Component } from 'react'
import numeral from 'numeral'
import _ from 'lodash'
import { slvyToast } from '../../../components'
import FetchScenarioTree from './FetchScenarioTree'
import { _errorHandler, setLoader } from '../utils'
import { MESSAGES } from '../messages'

class AppInitialization extends Component {
  constructor(props) {
    super(props)
    this.currentLocaleSymbol = '$'
  }

  componentDidMount() {
    const {
      saveTimelineSettings = () => {},
      registerAuthorizations = () => {},
      saveMfpSettings = () => {},
      disableFilter = () => {},
      settings: {
        config: {
          localization: { isCurrencyModeActive = false } = {},
          configuration: { mfpId = 1, isWorkInSubsetEnabled = false } = {},
          gridDetail: { multiColumnEnabled = false } = {}
        } = {}
      } = {},
      id = ''
    } = this.props

    // AUTH
    registerAuthorizations(['CreateScenario', 'ApproveMfpNode', 'Edit Mode', 'MarkAsPublic'])

    saveMfpSettings({
      payload: {
        mfpSettings: {
          isCurrencyModeActive,
          mfpId: Number(mfpId)
        }
      }
    })

    this.setDefaultActionParams().then(() => {
      const AggregationLevelId = _.get(
        this.props,
        'settings.config.timeline.defaultAggregationLevelId'
      )
      saveTimelineSettings({
        payload: {
          AggregationLevelId
        }
      })

      const initializeMfp = () => {
        if (multiColumnEnabled) {
          const selectedMetricFields = JSON.parse(
            localStorage.getItem(`dp-SingleDetailMetricSelection-${id}`)
          )
          if (
            selectedMetricFields &&
            selectedMetricFields.length === selectedMetricFields.filter((item) => item).length
          ) {
            this.props._savePivotSingleDetailViewSettings({ selectedMetricFields })
          } else {
            localStorage.removeItem(`dp-SingleDetailMetricSelection-${id}`)
          }
        }

        this.adjustCurrency()
        if (isWorkInSubsetEnabled) {
          this._fetchUserScenario().then((userScenarioResponse) => {
            const { Result = {} } = userScenarioResponse

            const UserScenario = _.get(userScenarioResponse, 'Result', null)
            const UserScenarioId = _.get(userScenarioResponse, 'Result.Id', null)
            const isActiveSubset = !_.isNil(UserScenario) && !_.isNil(UserScenarioId)

            if (!isActiveSubset) {
              this.callScenarioTree()
              return
            }

            this._saveEditable(userScenarioResponse)

            // DISABLED FILTER
            disableFilter({ mfpId: Number(mfpId) })

            // @TODO: OnDiscardClick reset scenario selection!
            this.autoSelectSubsetSelection(Result)

            this.toggleSubsetConfirmation()
          })
        } else {
          this.callScenarioTree()
        }
      }

      if (isCurrencyModeActive) {
        this._fetchCurrencyRates().then(() => initializeMfp())
      } else {
        initializeMfp()
      }
    })
  }

  _saveEditable(userScenarioResponse) {
    const { Result: { PlannedScenario = {} } = {} } = userScenarioResponse

    // dont add default value for this line
    const defaultIsScenarioEditable = _.get(this.props, 'mfpSettings.isScenarioEditable')
    const IsEditable = _.get(PlannedScenario, 'IsEditable', defaultIsScenarioEditable)
    const ScenarioName = _.get(PlannedScenario, 'Name', 'This scenario')

    const errMsg = `${ScenarioName} is not editable!`

    this.props.setEditable({ Result: { IsEditable }, Errors: [errMsg] })
  }

  componentWillUnmount() {
    const { dispatch = () => {} } = this.props
    dispatch({ type: 'USER_EXIT' })
    numeral.localeData().currency.symbol = this.currentLocaleSymbol
  }

  toggleSubsetConfirmation() {
    const {
      saveSubsetViewSettings = () => {},
      onViewChangedCallback = () => {},
      subsetViewSettings: { isVisible = false } = {}
    } = this.props

    if (!isVisible) {
      onViewChangedCallback({ View: 'Subset' })
    }

    saveSubsetViewSettings({
      payload: {
        isVisible: !isVisible
      }
    })
  }

  autoSelectSubsetSelection(Result) {
    const { BaselineScenario = {}, PlannedScenario = {} } = Result
    const { selectScenario = () => {} } = this.props
    selectScenario({
      payload: {
        dataKey: 'scenarioTree',
        index: 0,
        scenarioType: 'Baseline',
        currentScenario: BaselineScenario
      }
    })

    selectScenario({
      payload: {
        dataKey: 'scenarioTree',
        index: 0,
        scenarioType: 'Planned',
        currentScenario: PlannedScenario
      }
    })
  }

  _fetchUserScenario() {
    const {
      defaultActionParams: { payload = {} },
      settings: { config: { configuration: { mfpId = 1 } = {} } = {} } = {},
      fetchUserScenario = () => {}
    } = this.props

    this.toggleLoader(true, MESSAGES.loading)

    const promise = fetchUserScenario({
      payload: {
        ...payload,
        requestMethod: 'post',
        method: 'GetUserScenario',
        body: {
          mfpId
        }
      }
    })

    promise.then((userScenarioResponse) => {
      const { Errors = [] } = userScenarioResponse || {}

      this.toggleLoader()

      if (Errors.length) {
        slvyToast.warning({
          message: Errors[0],
          title: MESSAGES.get_user_scenario,
          options: { containerId: 'dp' }
        })
      }
    })

    return promise
  }

  callScenarioTree() {
    const { saveScenarioTreeViewSettings = () => {}, onViewChangedCallback = () => {} } = this.props

    this._fetchScenarioTree()
      .then(() => {
        saveScenarioTreeViewSettings({
          payload: {
            isVisible: true
          }
        })

        onViewChangedCallback({ View: 'ScenarioTree' })
        this.toggleLoader()
      })
      .catch(() => {
        this.props.setActionLoader({
          payload: {
            isVisible: true,
            messages: MESSAGES.there_has_been_an_error,
            isAnimationStopped: true
          }
        })
      })
  }

  adjustCurrency() {
    const currency = _.get(this.props, 'settings.config.localization.currency', '')

    this.currentLocaleSymbol = numeral.localeData().currency.symbol

    numeral.localeData().currency.symbol = currency === '' ? '₺' : currency
  }

  toggleLoader(isVisible = false, msg = MESSAGES.loading, Guid = undefined) {
    const { setActionLoader = () => {} } = this.props
    setLoader(setActionLoader, isVisible, msg, Guid)
  }

  setDefaultActionParams() {
    const { id: pluginId = '', saveDefaultActionParams = () => {} } = this.props
    return saveDefaultActionParams({
      payload: {
        pluginId
      }
    })
  }

  _fetchCurrencyRates() {
    const {
      fetchCurrencyRates = () => {},
      saveGetCurrencyRates = () => {},
      currencyRatesSettings = {},
      defaultActionParams: { payload = {} },
      mfpSettings: { mfpId } = {}
    } = this.props

    this.toggleLoader(true, MESSAGES.loading)

    const promise = fetchCurrencyRates({
      payload: {
        ...payload,
        method: 'GetMfpCurrencyRates',
        requestMethod: 'post',
        body: {
          mfpId
        }
      }
    })

    promise
      .then((currencyRatesResponse) => {
        currencyRatesResponse.Result = [currencyRatesSettings, ...currencyRatesResponse.Result]

        // ADD DEFAULT
        saveGetCurrencyRates({
          payload: {
            ...currencyRatesResponse
          }
        })
      })
      .catch((currencyRatesError) => _errorHandler(currencyRatesError, 'GetMfpCurrencyRates'))

    return promise
  }

  render() {
    let {
      children = null,
      pluginClasses = '',
      pluginStyle = {},
      isTimelineCollapsed = false
    } = this.props

    pluginClasses += isTimelineCollapsed ? ' timeline-collapsed' : ''

    return (
      <FetchScenarioTree>
        {({ _fetchScenarioTree = () => {} }) => {
          this._fetchScenarioTree = _fetchScenarioTree
          return (
            <div className={pluginClasses} style={pluginStyle}>
              {children}
            </div>
          )
        }}
      </FetchScenarioTree>
    )
  }
}

export default AppInitialization
