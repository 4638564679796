import { Component } from 'react'
import { ObjectEditor, ArrayEditor, StringEditor, NumberEditor, BooleanEditor } from '..'
import TemplateRenderer from './TemplateRenderer'

export default class EditorSelector extends Component {
  render() {
    const { schema, value, hidden } = this.props

    if (hidden) return null

    if (schema.template) {
      return <TemplateRenderer {...this.props} />
    }

    switch (schema.type) {
      case 'object':
        return <ObjectEditor {...this.props} />

      case 'array':
        return <ArrayEditor {...this.props} />

      case 'string':
        return <StringEditor {...this.props} />

      case 'number':
      case 'integer':
      case 'NullableNumber':
        return <NumberEditor {...this.props} />

      case 'boolean':
      case 'NullableBoolean':
        return <BooleanEditor {...this.props} />

      default:
        // TODO : anyOf
        if (schema.oneOf) {
          return <ObjectEditor {...this.props} />
        }

        console.log('JsonEditor::: editor needed for type ', schema.type, schema)

        return (
          <pre data-format={schema.format} data-type={schema.type}>
            {JSON.stringify(value, null, 2)}
          </pre>
        )
    }
  }
}
