import { slvyToast } from '@/components'

export default function containerValidation(containers, id) {
  const container = containers.filter((item) => item.type === 'popupcontainer' && item.id === id)
  if (container.length === 0) {
    return true
  }
  slvyToast.error({ message: 'You can add popup containers only into the main container.' })
  return false
}
