import { objectKeys } from '@/ts/utils'
import {
  commonFilterParams,
  FILTER_OPERATORS,
  filterOptions,
  filterSelectorConfig,
  numberTypes,
  TAG_FILTER_KEY
} from '../../constants'
import { PluginFilter } from '@/BasePlugin/components/PluginProps.types'
import {
  EmptyObject,
  FilterModel,
  FilterType,
  GridRef,
  SlvyColDef,
  TcustomConfigs,
  TfieldConfigs
} from '../../types'
import { ColumnDefsConfigs } from '../column/column.types'
import {
  AddKindPropToFilterModelParams,
  GetColumnTagFilterReturnType,
  CustomConfigsCommonFilter,
  CustomConfigsWithShowListFilter,
  CustomConfigsWithTagFilter,
  GetColumnCommonFilterReturnType,
  GetColumnFiltersResultParams,
  GetColumnShowListFilterReturnType,
  CommonColumnFilterModel,
  PreparePluginFiltersParams,
  PreparePluginFiltersReturnType,
  TagFilterParams,
  TransformFilterValueParams
} from './filter.types'

function getColumnFilter(
  filtering: ColumnDefsConfigs['filtering'],
  customConfigs: TcustomConfigs,
  fieldConfig: TfieldConfigs[number],
  isPaginationEnabled: ColumnDefsConfigs['isPaginationEnabled'],
  listFilterCheckbox: ColumnDefsConfigs['listFilterCheckbox']
): EmptyObject
function getColumnFilter(
  filtering: true,
  customConfigs: CustomConfigsCommonFilter,
  fieldConfig: TfieldConfigs[number],
  isPaginationEnabled: ColumnDefsConfigs['isPaginationEnabled'],
  listFilterCheckbox: ColumnDefsConfigs['listFilterCheckbox']
): GetColumnCommonFilterReturnType
function getColumnFilter(
  filtering: true,
  customConfigs: CustomConfigsWithTagFilter,
  fieldConfig: TfieldConfigs[number],
  isPaginationEnabled: ColumnDefsConfigs['isPaginationEnabled'],
  listFilterCheckbox: ColumnDefsConfigs['listFilterCheckbox']
): GetColumnTagFilterReturnType
function getColumnFilter(
  filtering: true,
  customConfigs: CustomConfigsWithShowListFilter,
  fieldConfig: TfieldConfigs[number],
  isPaginationEnabled: ColumnDefsConfigs['isPaginationEnabled'],
  listFilterCheckbox: ColumnDefsConfigs['listFilterCheckbox']
): GetColumnShowListFilterReturnType
function getColumnFilter(
  filtering: ColumnDefsConfigs['filtering'],
  customConfigs:
    | TcustomConfigs
    | CustomConfigsWithShowListFilter
    | CustomConfigsCommonFilter
    | CustomConfigsWithTagFilter,
  fieldConfig: TfieldConfigs[number],
  isPaginationEnabled: ColumnDefsConfigs['isPaginationEnabled'],
  listFilterCheckbox: ColumnDefsConfigs['listFilterCheckbox']
) {
  const {
    filtering: { tagFilter = false, showList = false }
  } = customConfigs
  const { dataType } = fieldConfig

  if (!filtering) {
    return {}
  }

  const isTagFilterEnabled = tagFilter

  if (!isTagFilterEnabled && !showList) {
    let filterConfig = filterSelectorConfig[dataType as keyof typeof filterSelectorConfig]
    // Prevent unexpected data types on filtering
    if (typeof filterConfig === 'undefined') {
      return {}
    }
    if (isPaginationEnabled) {
      // Boolean columns can only be filtered on the client side
      // Because our backend system cannot support "boolean type", check there after it is fixed.
      if (dataType === 'bool') {
        return {
          filter: 'agSetColumnFilter',
          filterParams: {
            defaultToNothingSelected: true
          }
        }
      }
      const paginationFilterOptions = filterOptions[dataType as keyof typeof filterOptions]
      if (!paginationFilterOptions.length) {
        return {}
      }
      // Prevent input blur/focus problem when writing slowly
      // default debounceMs is 500
      if (
        filterConfig?.filter === 'agTextColumnFilter' ||
        filterConfig?.filter === 'agNumberColumnFilter'
      ) {
        filterConfig.filterParams.debounceMs = 1000
      }
      filterConfig = {
        ...filterConfig,
        filterParams: {
          ...filterConfig?.filterParams,
          maxNumConditions: 1,
          // TODO: Remove filterOptions limitations after all filters added to backend code for pagination.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          filterOptions: paginationFilterOptions
        }
      }
    }
    return filterConfig
  }

  if (isTagFilterEnabled) {
    // TODO: AG-3792 [Column Menu] Allow adding custom menu tabs to the column menu
    const filterParams: TagFilterParams = {
      ...commonFilterParams,
      maxNumConditions: 10,
      filterOptions: [
        {
          displayKey: TAG_FILTER_KEY,
          displayName: TAG_FILTER_KEY,
          predicate: ([filterValue], cellValue) => {
            return String(cellValue).toLowerCase().includes(filterValue.toLowerCase())
          }
        }
      ]
    }

    return {
      filter: 'agTextColumnFilter',
      filterParams
    }
  }

  if (showList) {
    return {
      filter: 'agSetColumnFilter',
      filterParams: {
        buttons: listFilterCheckbox ? ['apply'] : [],
        closeOnApply: true,
        defaultToNothingSelected: true
      }
    }
  }

  return {}
}

const transformFilterOperator = (agOperator: string) => {
  const id = FILTER_OPERATORS[agOperator as keyof typeof FILTER_OPERATORS] ?? ''
  return {
    id,
    text: agOperator
  }
}

const transformFilterValue = ({ columnFilterModel, dataType }: TransformFilterValueParams) => {
  if (columnFilterModel.kind === 'tagText') {
    return [columnFilterModel.filter]
  }
  if (columnFilterModel.kind === 'tagAdvanced') {
    return columnFilterModel.conditions.map((condition) => {
      return condition.filter
    })
  }
  if (columnFilterModel.kind === 'list') {
    const isNumber = numberTypes.some((numberType) => numberType === dataType)
    if (isNumber) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return columnFilterModel?.values?.map((value: PluginFilter['value']) => {
        if (value === null) {
          return null
        }
        return Number(value)
      })
    }
    return columnFilterModel.values
  }

  if (columnFilterModel.kind === 'date') {
    return columnFilterModel.dateFrom
  }
  if (columnFilterModel.kind === 'default') {
    return columnFilterModel.filter
  }
}

const addKindPropToFilterModel = ({
  columnFilterModel,
  isTagFilterEnabled
}: AddKindPropToFilterModelParams) => {
  const filterModel = columnFilterModel
  const { filterType, type } = filterModel
  const isDate = filterType === 'date' || filterType === 'datetime'

  if (isTagFilterEnabled) {
    if (type === TAG_FILTER_KEY) {
      filterModel.kind = 'tagText'
    }
    if ('conditions' in filterModel) {
      filterModel.kind = 'tagAdvanced'
    }
  } else if (filterModel.filterType === 'set') {
    filterModel.kind = 'list'
  } else if (isDate) {
    filterModel.kind = 'date'
  } else {
    filterModel.kind = 'default'
  }

  return filterModel
}

// TODO: Missing test
const getColumnFiltersResult = ({
  colId,
  columnConfig,
  fieldConfig,
  filterModel
}: GetColumnFiltersResultParams): PluginFilter | undefined => {
  const { dataType } = fieldConfig
  const {
    customConfigs: {
      filtering: { tagFilter: isTagFilterEnabled }
    }
  } = columnConfig
  let filterPropsResult

  const commonColumnFilterModel: CommonColumnFilterModel = addKindPropToFilterModel({
    columnFilterModel: filterModel[colId],
    isTagFilterEnabled
  })
  const { filterType, type: operator } = commonColumnFilterModel
  let agOperator = operator
  if (isTagFilterEnabled) {
    // It's for tag-filter
    agOperator = TAG_FILTER_KEY
  } else if (operator === undefined) {
    // It's for list filter
    agOperator = filterType
  }

  const { id: filterOperator } = transformFilterOperator(agOperator)
  const filterValue = transformFilterValue({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    columnFilterModel: commonColumnFilterModel,
    dataType
  })

  if (filterOperator && filterValue !== undefined) {
    const optionalProps: { tag?: 'like' } = {}
    if (isTagFilterEnabled) {
      optionalProps.tag = 'like'
    }

    filterPropsResult = {
      ...optionalProps,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      field: colId,
      value: filterValue,
      operator: filterOperator,
      dataType
    }
  }
  return filterPropsResult
}

// TODO: Missing test
const preparePluginFilters = ({
  configColumns,
  fieldConfigs,
  filterModel,
  gridRef
}: PreparePluginFiltersParams): PreparePluginFiltersReturnType => {
  const pluginFiltersResult: PreparePluginFiltersReturnType = []
  objectKeys(filterModel).forEach((colId) => {
    const columnConfig = configColumns.find(({ field }) => field === colId)
    if (columnConfig) {
      const columnSubstituteField =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gridRef?.current?.api?.getColumnDef(colId)?.customInfo?.fieldLookupConfig?.substituteField
      if (
        columnSubstituteField === null ||
        columnSubstituteField === undefined ||
        columnSubstituteField === ''
      ) {
        const fieldConfig: TfieldConfigs[number] | undefined = fieldConfigs.find(
          ({ fieldName }) => fieldName === columnConfig.field
        )
        // Our backend system cannot support boolean type & set filter together
        if (fieldConfig !== undefined && fieldConfig?.dataType !== 'bool') {
          const columnFilterResult: PluginFilter | undefined = getColumnFiltersResult({
            columnConfig,
            fieldConfig,
            filterModel,
            colId
          })
          if (columnFilterResult !== undefined) {
            if (Object.keys(columnFilterResult).length) {
              if (Array.isArray(columnFilterResult.value)) {
                if (columnFilterResult.value.length) {
                  pluginFiltersResult.push(columnFilterResult)
                }
              } else {
                pluginFiltersResult.push(columnFilterResult)
              }
            }
          }
        }
      }
    }
  })
  return pluginFiltersResult
}

// TODO: Missing test
const mapFilters = (gridRef: GridRef) => {
  const filterModel = gridRef?.current?.api?.getFilterModel() as FilterModel
  const filters: Partial<{ [key: string]: FilterType }> = {}

  objectKeys(filterModel).forEach((key) => {
    const currentColDef = gridRef?.current?.api?.getColumnDef(key as string) as SlvyColDef
    if (currentColDef?.customInfo) {
      const {
        customInfo: {
          filtering: { tagFilter: isTagFilterEnabled },
          fieldConfig: { dataType }
        }
      } = currentColDef

      // TODO: Fix type
      const filterModelItem = addKindPropToFilterModel({
        columnFilterModel: filterModel[key],
        isTagFilterEnabled
      })

      // TODO: Fix type
      filters[key] = transformFilterValue({ columnFilterModel: filterModelItem, dataType })
    }
  })
  return filters
}

export {
  addKindPropToFilterModel,
  getColumnFilter,
  preparePluginFilters,
  transformFilterOperator,
  transformFilterValue,
  mapFilters
}
