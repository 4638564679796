export default `({
    bodyStyle: {
        background: '#fff',
        padding: '1px'
    },
    style: {
        //background: 'rgb(209, 211, 214)'
        background: '#fff',
    },
    defaultListenerScope: true,
    calculateTotal: function () {
        var store = this.getStore();
        allRecords = store.snapshot || store.data,
            OrderRecommendation_DC = 0,
            OrderRecommendation_DC_UpdatedbyMO = 0,
            MaterialCode = '',
            MaterialDescription = '',
            UnitsPerPackage = 1,
            lastRecord = false,
            SalesPrice = 0,
            allRecords.each(function (record) {
                OrderRecommendation_DC = OrderRecommendation_DC + record.get('OrderRecommendationParent');
                if (record.get('IsInclude')) {
                    OrderRecommendation_DC_UpdatedbyMO = OrderRecommendation_DC_UpdatedbyMO + record.get('OrderRecommendationCurrent');
                }
                lastRecord = record
            });
        var viewModel = this.ownerCt.getViewModel();
        if (lastRecord) {
            MaterialCode = lastRecord.get('MaterialCode') * 1 + ' - ' + lastRecord.get('MaterialDescription');
            UnitsPerPackage = lastRecord.get('UnitsPerPackage')
            SalesPrice = lastRecord.get('SalesPrice')
        }
        sumRecord = Ext.create('Ext.data.Model', {
            MaterialCode: MaterialCode,
            SalesPrice: Ext.util.Format.number(SalesPrice, '0,000.00'),
            UnitsPerPackage: UnitsPerPackage,
            OrderRecommendation_DC: Ext.util.Format.number(OrderRecommendation_DC, '0,000')+' ' + tt(' Koli'),
            OrderRecommendation_DCST: Ext.util.Format.number(OrderRecommendation_DC * UnitsPerPackage, '0,000')+' ' + tt(' Adet'),
            OrderRecommendation_DC_UpdatedbyMO: OrderRecommendation_DC_UpdatedbyMO
        })
        viewModel.set('sumRecord', sumRecord)
    },
    features: [{
        ftype: 'summary',
        dock: 'top'
    }],
    dockedItems: [
        // {
        //     xtype: 'container',
        //     height: 50,
        //     margin: o,
        //     html: '<center><h2> domartes</h2></center>',
        //     style: {
        //         background: '#fff',
        //     },
        // },
        {
            xtype: 'toolbar',
            border: true,
            border: 1,
            margin: 1,
            dock: 'top',
            items: [{
                    xtype: 'label',
                    bind: {
                        html: '<h3>'+t('₺')+ '{sumRecord.MaterialCode} / {sumRecord.SalesPrice} </h3> '
                    },
                    margin: '0 10 0 10'
                },
                '-',
                {
                    xtype: 'button',
                    text: tt('Export to Excel'),
                    docked: 'right',
                    handler: function (btn) {
                        var grid = this.ownerCt.ownerCt
                        grid.saveDocumentAs({
                            type: 'xlsx',
                            title: tt('Distribution'),
                            fileName: tt('Distribution')+'.xlsx',
                        });
                    }
                },
                '->',
                {
                    xtype: 'label',
                    forId: 'myFieldId',
                    html: '<b>'+tt('Solvoyo Onerisi')+' :<b>',
                    margin: '0 0 0 10'
                },
                {
                    xtype: 'label',
                    bind: {
                        html: '{sumRecord.OrderRecommendation_DC}',
                    },
                    margin: '0 0 0 10'
                },
                {
                    xtype: 'label',
                    html: '-',
                    margin: '0 0 0 10'
                },
                {
                    xtype: 'label',
                    forId: 'myFieldId',
                    bind: {
                        html: '{sumRecord.OrderRecommendation_DCST}'
                    },
                    margin: '0 0 0 10'
                },
                {
                    xtype: 'label',
                    html: '<b>'+tt('Onaylanan Öneri')+'</b>'
                },
                {
                    width: 100,
                    xtype: 'textfield',
                    // hideTrigger: true,
                    listeners: {
                        // specialkey: 'onSpecialkey',
                    },
                    triggers: {
                        foo: {
                            cls: 'x-form-calculate-trigger',
                            weight: 1,
                            reference: 'approvedRecommendation',
                            handler: 'onClickdata',
                            tooltip: 'Yeniden Hesapla'
                        },
                    },
                    bind: {
                        value: '{sumRecord.OrderRecommendation_DC_UpdatedbyMO}',
                        // minValue: Ext.Date.add('{spotRecord.PreStartDate}', Ext.Date.DAY, 21),
                    },
                },
                {
                    xtype: 'label',
                    forId: 'myFieldId',
                    bind: {
                        html: tt(' Koli')
                    },
                    margin: '0 80 0 1'
                },
            ]
        },
        // {
        //     xtype: 'toolbar',
        //     dock: 'top',
        //     margin: 1,
        //     items: [{
        //             text: 'Docked to the top'
        //         },
        //         {
        //             width: 200,
        //             xtype: 'numberfield',
        //             fieldLabel: 'Koli ici adet',
        //             tooltip: 'Magaza minimum siparis birim',
        //             bind: {
        //                 value: '{sumRecord.OrderRecommendation_DC}',
        //                 // minValue: Ext.Date.add('{spotRecord.PreStartDate}', Ext.Date.DAY, 21),
        //             },
        //         }
        //     ]
        // }
    ],
    onReCalcuulate: function (field, f, e, g) {
        var value = field.getValue();
        var menu = Ext.create('Ext.menu.Menu', {
            margin: '0 0 10 0',
            items: [{
                    text: tt('Normal Dagitim '),
                    handler: 'onClickdata',
                    scope: this,
                    calculateType: 0,
                    sendValue: value
                }
                // , {
                //     text: tt('Once Dusuk Ciro'),
                //     handler: 'onClickdata',
                //     scope: this,
                //     calculateType: 1,
                //     sendValue: value
                // }, {
                //     text: tt('Once Yuksek Ciro'),
                //     handler: 'onClickdata',
                //     scope: this,
                //     calculateType: 2,
                //     sendValue: value
                // }
            ]
        });
        menu.showAt(e.getXY());
    },
    onClickdata: function (field) {
        var value = field.getValue();
        var me = this,
            vieModel = me.getViewModel(),
            newValue = value,
            calculateMethod = 0,
            store = this.getStore(),
            allRecords = store.snapshot || store.data,
            spotRecord = viewModel.get('spotRecord'),
            minQty = spotRecord.get('MinQuantity'),
            unitsPerPackage = spotRecord.get('UnitsPerPackage'),
            storeCount = 0,
            sumOfLockedRecommendationValues = 0
        allRecords.each(function (record) {
            if (record.get('IsLocked') == 0 && record.get('IsInclude') == 1) {
                storeCount = storeCount + record.get('NumberofStore')
            }
            if (record.get('IsLocked') == 1 && record.get('IsInclude') == 1) {
                sumOfLockedRecommendationValues = sumOfLockedRecommendationValues + record.get('OrderRecommendationCurrent')
            }
        })
        me.onClickdataRequest(newValue, calculateMethod);
    },
    onClickdataRequest: function (newValue, calculateMethod) {
        var spotId = this.ownerCt.getSpotId()
        var me = this;
        me.setLoading(tt('Oneri Hesaplaniyor Lutfen bekleyiniz.'));
        Ext.Ajax.request({
            url: Spui.Global.getApiUrl() + '/spui/recalculateoneri',
            method: 'POST',
            timeout: 600000,
            params: {
                SpotId: spotId,
                newValue: newValue,
                calculationMethod: calculateMethod
            },
            //callback: function (record, operation, success) {
            //    var response = operation.getResponse(),
            //        responseText = response.responseText,
            //        result = Ext.JSON.decode(responseText)
            //    if (!result.IsSuccess) {
            //        Ext.Msg.alert(
            //            'Durum',
            //            tt(result.Message)
            //        )
            //    }
            //    context.grid.ownerGrid.calculateTotal()
            //    grid.setLoading(false)
            //    // do something whether the save succeeded or failed
            //},
            success: function (response, opts) {
                var result = Ext.decode(response.responseText);
                
                if (!result.IsSuccess) {
                    Ext.Msg.alert(
                        tt('Durum'),
                        tt('Minimum Siparşi Miktarının Altında Bir değer Girdiniz. Minimum sipariş miktarı: ') + result.Message
                    )
                }
                me.setLoading(false);
                me.store.load();
                //me.reDrawChart(obj);
                // me.loadUserSettings();
            },
            failure: function (response, opts) {
                me.setLoading(false);
                Ext.Msg.alert(
                    tt('Durum'),
                    tt('İşlem Gerçekleştirilirken bir hata oluştu')
                )
            }
        });
    },
    plugins: [{
        ptype: 'cellediting',
        clicksToEdit: 1,
        listeners: {
            edit: function (editor, context, eOpts) {
                var me = this,
                    view = me.getCmp('#spui-view-common-recom-distribution-main'),
                    viewModel = view.ownerCt.getViewModel(),
                    spotRecord = viewModel.get('spotRecord'),
                    minQty = spotRecord.get('MinQuantity'),
                    numberOfStore = context.record.data.NumberofStore,
                    unitsPerPackage = spotRecord.get('UnitsPerPackage')
                    
                if (context.originalValue == context.value)
                    return;
                var record = context.record,
                    grid = context.grid.ownerCt
                grid.setLoading(tt('yükleniyor'));
                
                var oldValue = record.previousValues.OrderRecommendationCurrent
                
                record.save({
                    params: {
                        updateAction: 'SubValueChange'
                    },
                    //failure: function (record, operation) {
                    //    grid.setLoading(false)
                    //    Ext.Msg.alert(
                    //        'Durum',
                    //        tt('İşlem Gerçekleştirilirken bir hata oluştu')
                    //    )
                    //},
                    //success: function (record, operation) {
                    //    context.grid.ownerGrid.calculateTotal()
                    //    grid.setLoading(false)
                    //    //grid.store.load()
                    //},
                    callback: function (record, operation, success) {
                    // @TODO v2 response
                        var result = operation._response.responseJson
                        if (!result.IsSuccess) {
                            Ext.Msg.alert(
                                tt('Durum'),
                                tt('Minimum Siparşi Miktarının Altında Bir değer Girdiniz. Minimum sipariş miktarı: ') + result.Message
                            )
                            record.set('OrderRecommendationCurrent',oldValue)
                            record.commit()
                        }
                        
                        this.store.load()
                        context.grid.ownerGrid.calculateTotal()
                        grid.setLoading(false)
                        // do something whether the save succeeded or failed
                    }
                });
            },
            scope: 'this'
        }
    }, {
        ptype: 'cornerIcon',
        // offSet: {
        //     top: 0,
        //     right: 90
        // },
        extraButton: [
            '->',
            {
                iconCls: 'x-fa fa-cog',
                bind: {
                    hidden: '{!admin}'
                },
                menu: [{
                        text: 'Grid Config',
                        handler: 'onConfigRecomGrid',
                    },
                    {
                        text: 'store Config',
                        handler: 'onConfigRecomStore',
                    },
                ]
            }
        ]
    },
    {
        ptype: 'gridexporter'
    }],
    title: tt('Oneri Listesi [Koli]'),
    header: {
        titlePosition: 0,
        // items: [{
        //     text: 'deneme',
        //     xtype: 'splitbutton',
        // }]
    },
    header: false,
    // header: false,
    margin: 1,
    syncRowHeight: true,
    listeners: {
        activate: function (p) {
            //if (p.forceToLoadStore)
            p.getSimilarGridStore().load();
        },
        afterrender: function (p) {
            p.getStore().on('load', function (store) {
                this.calculateTotal();
                            }, p)
            p.getStore().on('write', function (store) {
                this.calculateTotal();
            }, p)
        },
        scope: 'this'
    },
    columnLines: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            if (!record.get('IsInclude')) {
                return 'row-height row-blur';
            }
            return 'RowHeightClass row-height'
        }
    },
    lockedViewConfig:{
        getRowClass: function (record, rowIndex, rowParams, store) {
            if (!record.get('IsInclude')) {
                return 'row-height row-blur';
            }
            return 'RowHeightClass row-height'
        }
    },
    oncheckchange: function (a, b, d, e) {
        var me = this;
        me.setLoading(tt('yükleniyor'));
        e.save({
            params: {
                updateAction: 'DCIncludeChange'
            },
            callback: function () {
                me.setLoading(false);
                me.calculateTotal();
            }
        })
    },
    onLockingChange: function (grid, rowIndex, colIndex, item, e, record) {
        var me = this;
        IsLocked = record.get('IsLocked')
        record.set('IsLocked', !IsLocked)
        record.commit()
        me.setLoading(tt('yükleniyor'));
        record.save({
            params: {
                updateAction: 'OnLockChange'
            },
            callback: function () {
                me.setLoading(false);
            }
        })
    },
    columns: [{
            xtype: 'actioncolumn',
            width: 30,
            locked: true,
            items: [{
                iconCls: 'x-fa fa-dot-circle-o',
                tooltip: 'Show store Details',
                align: 'center',
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var data = record.data;
                    if (!Spui.data.model.tree2) {
                        Ext.define('Spui.data.model.tree2', {
                            extend: 'Ext.data.TreeModel',
                            idProperty: 'OrgCode',
                            fields: [{
                                    type: 'int',
                                    name: 'NumberofStore'
                                },
                                {
                                    type: 'int',
                                    name: 'NumberofRecommendedStore'
                                },
                                {
                                    type: 'int',
                                    name: 'OrderRecommendationParent'
                                },
                                {
                                    type: 'int',
                                    name: 'OrderRecommendationCurrent'
                                },
                                {
                                    type: 'string',
                                    name: 'DCCode'
                                },
                            ]
                        });
                    }
                    var wind = Ext.create('Ext.window.Window', {
                        title: record.get('DCName'),
                        localeProperties: [],
                        height: '80%',
                        bodyPadding: 2,
                        bodyStyle: {
                            background: '#ccc'
                        },
                        width: '50%',
                        modal: true,
                        ui: 'spui-white',
                        viewModel: {},
                        layout: 'fit',
                        items: {
                            xtype: 'uxTreeContainer',
                            uxStoreCfg: 'StoretDetailsforWizardStore',
                            uxItemCfg: 'StoretDetailsforWizardi',
                            uxStore: {
                                model: 'Spui.data.model.tree2',
                                root: {
                                    OrgTitle: data['DCName'],
                                    text: data['DCName'],
                                    expanded: true,
                                    OrgCode: data['Code'],
                                    NumberofStore: data['NumberofStore'],
                                    NumberofRecommendedStore: data['NumberofRecommendedStore'],
                                    OrderRecommendationParent: data['OrderRecommendationParent'],
                                    OrderRecommendationCurrent: data['OrderRecommendationCurrent'],
                                    // children: [{
                                    //         text: "Child 1",
                                    //         leaf: true
                                    //     },
                                    //     {
                                    //         text: "Child 2",
                                    //         leaf: true
                                    //     }
                                    // ]
                                },
                                rootVisible: true,
                                proxy: {
                                    type: 'ajax',
                                    extraParams: {
                                        data: Ext.encode(data)
                                    },
                                    actionMethods: {
                                        create: 'POST',
                                        read: 'POST',
                                        update: 'POST',
                                        destroy: 'POST'
                                    },
                                    url: Spui.Global.getApiUrl() + '/spui/GetReport',
                                    reader: {
                                        type: 'json',
                                        rootProperty: 'children',
                                        keepRawData: true,
                                        successProperty: 'success',
                                        messageProperty: 'errors'
                                    },
                                }
                            },
                            ddParam: data,
                        }
                    })
                    wind.show();
                    // var rec = grid.getStore().getAt(rowIndex);
                    // grid.fireEvent('clickforDetail', rec);
                    // Ext.create('Spui.view.drilldown.Vendor.Main').show();
                }
            }]
        },
        {
            xtype: 'actioncolumn',
            width: 30,
            locked: true,
            dataIndex: 'IsLocked',
            items: [{
                iconCls: 'x-fa  fa-unlock',
                tooltip: tt('Locking'),
                align: 'center',
                getClass: function (value, meta, record) {
                    if (record) {
                        return value ? 'x-fa  fa-lock' : 'x-fa  fa-unlock';
                    }
                },
                handler: 'onLockingChange'
            }]
        },
        {
            xtype: 'checkcolumn',
            header: tt('Include'),
            dataIndex: 'IsInclude',
            headerCheckbox: false,
            width: 90,
            stopSelection: true,
            locked: true,
            summaryType: 'sum',
            summaryRenderer: function (value, summaryData, dataIndex, context) {
                sumObject = summaryData;
                context.innerCls = '';
                return '<b>' + Ext.String.format(tt('{0} DC{1}'), value, value !== 1 ? 's' : '') + '</b>';
            },
            listeners: {
                checkchange: 'oncheckchange',
            }
        },
        {
            text: tt('DcCode'),
            dataIndex: 'Code',
            width: 100,
            // format: '000.00',
            align: 'center',
            locked: true
        },
        {
            text: tt('DcName'),
            dataIndex: 'Name',
            width: 200,
            // format: '000.00',
            align: 'left',
            locked: true,
            summaryType: 'count',
            // summaryRenderer: function (value, summaryData, dataIndex, context) {
            //     sumObject = summaryData;
            //     if (!v || v === 0) return '<b>-</b>';
            //     return '<b> Total' + Ext.String.format(tt('{0} Distribution center{1}'), value, value !== 1 ? 's' : '') + '</b>';
            // }
        },
        {
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true
        },
        {
            text: tt('Inventory_DC'),
            columns: [{
                text: tt('OnHand'),
                dataIndex: 'OnHandInventoryDC',
                width: 130,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                // summaryType: function (records, property, root, begin, end) {
                //     var n = property.length,
                //         i, sum, v;
                //     sum = 0;
                //     for (i = 0; i < n; ++i) {
                //         v = property[i]
                //         if (records[i].get('IsInclude'))
                //             sum = sum + v;
                //     }
                //     return sum;
                // },
                summaryType: function (records, property, root, begin, end) {
                    var n = property.length,
                        i, sum, v, sum2
                    sum = 0;
                    sum2 = 0
                    for (i = 0; i < n; ++i) {
                        v = property[i]
                        sum = sum + v;
                        if (records[i].get('IsInclude'))
                            sum2 = sum2 + v;
                    }
                    return sum2 + " / " + sum;
                },
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    sumObject = summaryData;;
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }, {
                text: tt('OpenOrder'),
                dataIndex: 'OpenOrderDC',
                width: 120,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                summaryType: 'sum',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }]
        },
        {
            text: tt('Inventory_Store'),
            columns: [{
                    text: tt('OnHandInventory'),
                    dataIndex: 'OnHandInventoryStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryType: 'sum',
                    summaryRenderer: function (value, summaryData, dataIndex, context) {
                        if (!value || value === 0) return '<b>-</b>';
                        return '<b>' + Ext.util.Format.number(value, '0,000.00') + '</b>';
                    }
                },
                {
                    text: tt('OpenOrder'),
                    dataIndex: 'OpenOrderStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryRenderer: function (v, summaryData, dataIndex, context) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                }
            ]
        },
        // {
        //     text: 'log_OrjinalOneri',
        //     dataIndex: 'log_OrjinalOneri',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'log_OrtalamaSonrasiOneri',
        //     dataIndex: 'log_OrtalamaSonrasiOneri',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'log_DepoKirilimiSonrasiOneri',
        //     dataIndex: 'log_DepoKirilimiSonrasiOneri',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'log_MagazaKisitSonrasiOneri',
        //     dataIndex: 'log_MagazaKisitSonrasiOneri',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'log_MagazaEnvanterSonrasiOneri',
        //     dataIndex: 'log_MagazaEnvanterSonrasiOneri',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'log_DepoEnvanterSonrasiOneri',
        //     dataIndex: 'log_DepoEnvanterSonrasiOneri',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        {
            text: tt('OrderRecommendation'),
            dataIndex: 'OrderRecommendationParent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            summaryType: 'sum',
            // summadryType: function (records, property, root, begin, end) {
            //     var n = property.length,
            //         i, sum, v, sum2
            //     sum = 0;
            //     sum2 = 0
            //     for (i = 0; i < n; ++i) {
            //         v = property[i]
            //         sum = sum + v;
            //         if (records[i].get('IsInclude'))
            //             sum2 = sum2 + v;
            //     }
                        //     return Ext.util.Format.number(sum2, '0,000') + " / " + Ext.util.Format.number(sum, '0,000');
            // },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('OrderRecommedationCurrent'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: function (records, property, root, begin, end) {
                var sum = 0
                records.forEach(function(record){
                    if(record.get('IsInclude'))
                    {
                        sum=sum+record.get('OrderRecommendationCurrent')
                    }
                })
                return sum
                //return Ext.util.Format.number(sum2, '0,000') + " / " + Ext.util.Format.number(sum, '0,000');
            },
            //editor: {
            //    // completeOnEnter: false,
            //    // If the editor config contains a field property, then
            //    // the editor config is used to create the Ext.grid.CellEditor
            //    // and the field property is used to create the editing input field.
            //    field: {
            //        xtype: 'numberfield',
            //        allowBlank: false
            //    },
            //},
            getEditor: function (record) {
                if (record.get('IsLocked') == 1 || record.get('IsInclude') == 0) {
                    return null
                }
                return Ext.create('Ext.form.field.Number', {
                    allowBlank: false
                })
            },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('NumberOfstore'),
            columns: [{
                    text: tt('All'),
                    dataIndex: 'NumberofStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    summaryTyped: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                },
                {
                    text: tt('RecommendedSTore'),
                    dataIndex: 'NumberofRecommendedStore',
                    width: 200,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v, meta, record) {
                        // meta.tdCls = 'sas drilldown';
                        return v;
                    },
                    summaryType: 'sum',
                }
            ]
        },
        // {
        //     text: tt('Comments'),
        //     dataIndex: 'Comments',
        //     width: 200,
        // },
        // {
        //     text: 'LastModifiedBy',
        //     dataIndex: 'LastModifiedBy',
        //     width: 200
        // },
    ],
})
`
