import React, { Component } from 'react'
import _ from 'lodash'
import { CoverBox } from '../../../../components'
import {
  getMessage,
  getProductPeriod,
  onlyNumber,
  round,
  val,
  findUpdateValue
} from '../../../../utils'

export default class AddProduction extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
      selectedProduct: {},
      isButtonDisable: true,
      searchBoxValue: '',
      isSubmitted: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.highlightFind = this.highlightFind.bind(this)
    this.handleSearchBoxChange = this.handleSearchBoxChange.bind(this)
  }

  componentDidMount() {
    const {
      data: { Products }
    } = this.props
    this.setState({ selectedProduct: _.first(Products) })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.data.Products, nextProps.data.Products)) {
      this.setState({ selectedProduct: _.first(nextProps.data.Products) })
    }
  }

  handleChange(event) {
    const {
      target: { value = '' }
    } = event
    const { value: currentValue = '' } = this.state

    this.setState({
      value: onlyNumber(value),
      isButtonDisable: currentValue === value
    })
  }

  updateHandler(params, e) {
    const { keyCode } = e
    const { Id, resourceId, isForce, value } = params ?? {}
    let {
      connectApi,
      lastRequest: {
        GetRccpResourceActivityResponseWPF: {
          Request: ActivityRequest = {},
          Request: { periodId = 0 } = {}
        } = {},
        GetRccpResourcePeriodHierarchyResponseWPF: { Request: ResourceRequest = {} } = {}
      } = {},
      getData,
      settings: {
        productionVariants: {
          defaultProductionVariant = 'Production',
          userOptions: { isEnabled: isUserOptionsEnabled = false } = {}
        } = {},
        modificationCount = 0,
        stateHandler: {
          selectedParameter: { tableName = 'Production' },
          selectedProductVariant = ''
        }
      },
      data: { period: { Id: newPeriodId = 0 } = {} } = {}
    } = this.props
    const { isSubmitted } = this.state

    selectedProductVariant = !selectedProductVariant
      ? defaultProductionVariant
      : selectedProductVariant
    const productionVariant = isUserOptionsEnabled
      ? selectedProductVariant
      : defaultProductionVariant
    const requestFieldName = findUpdateValue(tableName, productionVariant)

    if (keyCode === 13 && Id && !isSubmitted) {
      this.setState({ isSubmitted: true })

      connectApi('UpdateRccpResourcePeriodProduct', {
        fieldName: requestFieldName,
        newValue: value,
        periodId: !_.isNull(getData) ? periodId : newPeriodId,
        productId: Id,
        resourceId,
        ...(isForce
          ? {
              rccpConfig: {
                ForceUpdate: isForce,
                ModificationCount: modificationCount
              }
            }
          : {})
      }).then((res) => {
        const { Description = 'Error', Code = -1 } = res

        // Request.rccpConfig.ModificationCount = 10000
        ResourceRequest.rccpConfig.ModificationCount = 10000

        getMessage(Code, Description).then((ret) => {
          if (ret.isOk) {
            document.body.click()

            connectApi('GetRccpResourcePeriodHierarchyResponseWPF', ResourceRequest).then(() => {
              if (!_.isNull(getData)) {
                ActivityRequest.rccpConfig.ModificationCount = 10000
                connectApi('GetRccpResourceActivityResponseWPF', ActivityRequest).then((res) => {
                  getData(res)
                })
              }
            })
          }
        })
      })
    }
  }

  productClickHandler(product, e) {
    e.stopPropagation()

    this.setState({
      selectedProduct: product
    })
  }

  handleSearchBoxChange(e) {
    this.setState({ searchBoxValue: e.target.value })
  }

  highlightFind(text, highlight) {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return parts.map((part) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span style={{ backgroundColor: 'rgb(255, 162, 0)' }}>{part}</span>
      ) : (
        part
      )
    )
  }

  render() {
    let {
      data,
      data: { Products = [], resourceId = 0, period = {} },
      getResourceHierarchyResponse,
      settings: {
        coverSettings = {},
        stateHandler: {
          selectedParameter: { Index: SelectedParamIndex = 0, name: Abbreviation = '' }
        } = {},
        productInfoSettings = {}
      },
      coverViolationIndexes
    } = this.props
    const {
      value,
      selectedProduct,
      selectedProduct: { Id, Values = {} } = {},
      searchBoxValue = ''
    } = this.state

    const productPeriod = getProductPeriod(getResourceHierarchyResponse, data, selectedProduct)
    const productionInfoCoverSettings = _.find(productInfoSettings, ({ tableName }) =>
      _.includes(tableName, 'Cover')
    )
    const { format: formatType = '0,0' } = productionInfoCoverSettings || {}
    coverSettings = { ...coverSettings, formatType }

    return !_.isEmpty(Products) ? (
      <div className="-schedule-update-form -add">
        <aside className="-sc-left">
          {/* <div className='-dflex'>
                <div className='searchContainer'>
                    <input placeholder="Search" className="searchInput"></input>
                </div>
              </div> */}
          <div className="-searchbox">
            <input
              autoFocus
              placeholder="Search..."
              type="text"
              onChange={this.handleSearchBoxChange}
            />
          </div>
          <ul>
            {_.map(Products, (productItem, productIndex) => {
              const { Id: productId, SKU = 0, Name = '' } = productItem ?? {}

              if (
                Name.toLowerCase().includes(searchBoxValue.toLowerCase()) ||
                SKU.includes(searchBoxValue)
              ) {
                return (
                  <li
                    key={productIndex}
                    className={productId === Id ? '-active' : ''}
                    onClick={this.productClickHandler.bind(this, productItem)}
                  >
                    {searchBoxValue !== '' ? this.highlightFind(Name, searchBoxValue) : Name}
                    <small>
                      ({searchBoxValue !== '' ? this.highlightFind(SKU, searchBoxValue) : SKU})
                    </small>
                  </li>
                )
              }
            })}
          </ul>
        </aside>
        <main className="-sc-main">
          <div className="-dflex">
            <div className="unit">{Abbreviation}</div>
            <div className="inputField">
              <input
                type="text"
                value={value}
                onChange={this.handleChange}
                onKeyDown={this.updateHandler.bind(this, {
                  isForce: true,
                  value,
                  Id,
                  resourceId,
                  period
                })}
              />
            </div>
          </div>
          {/* <td>
                <button
                    type='button'
                    disabled={isButtonDisable}
                    onClick={this.clickHandler.bind(this, { isForce: true, value, Id, resourceId, period })}
                >
                    ADD
                </button>
              </td> */}
          {!_.isEmpty(Values) && (
            <div>
              <div className="-dflex -seperator">
                <b>min {round(val(Values, 'MinProduction', SelectedParamIndex))}</b>
                <b>inc {round(val(Values, 'LotSize', SelectedParamIndex))}</b>
              </div>
              <div className="-dflex">
                <strong>net requirement</strong>
                <em>{round(val(Values, 'NetRequirement', SelectedParamIndex))}</em>
              </div>
              <div className="-dflex">
                <strong>min production</strong>
                <em>{round(val(Values, 'MinProduction', SelectedParamIndex))}</em>
              </div>
              <div className="-dflex">
                <strong>forecast</strong>
                <em>{round(val(Values, 'TotalDemand', SelectedParamIndex))}</em>
              </div>
              <div className="-dflex">
                <strong>ending inventory</strong>
                <em>{round(val(Values, 'EndingInventory', SelectedParamIndex))}</em>
              </div>
              <div className="-schedule-update-cover-box">
                <CoverBox
                  className="-common-cover-list"
                  coverSettings={coverSettings}
                  coverViolationIndexes={coverViolationIndexes}
                  data={productPeriod}
                />
              </div>
            </div>
          )}
        </main>
      </div>
    ) : (
      <div className="-schedule-update-form -loading">Loading...</div>
    )
  }
}
