import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from '@/hooks/store'
import { slvyToast } from '..'
import { handleAsyncAwait } from '../../helpers'
import permission, { PermTypes } from '../../helpers/Permission'
import { archiveCatalog } from '../../actions/catalog'
import ArchiveModal from './ArchiveModal'
import { ICatalogBoxProps } from './CatalogBox.types'
import styles from './index.module.scss'

const CatalogBox = ({
  archiveDate,
  customer,
  customerLogoUri,
  description,
  environment,
  id,
  name
}: ICatalogBoxProps) => {
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.oidc.user)

  const checkPermission = permission(user, environment)

  const handleArchiveCatalog = async (deleteCatalog: boolean) => {
    const [_, error] = await handleAsyncAwait(dispatch(archiveCatalog(id, deleteCatalog)))

    setShowArchiveModal(false)

    if (error) {
      slvyToast.error({
        message: 'Please, try this again later.',
        title: 'Failed to archive the catalog!'
      })
      return
    }

    if (deleteCatalog) {
      slvyToast.success({
        message: 'It may take few minutes until the archived catalog gets deleted.',
        title: 'Catalog archived!'
      })
    } else {
      slvyToast.success({ message: 'Catalog archived!' })
    }

    setTimeout(() => navigate(0), 1000)
  }

  const showArchiveBtn =
    checkPermission.hasPermType(PermTypes.CatalogArchive).isEnv('Configuration').value &&
    name !== undefined

  const hasNoId = id === undefined
  return (
    <div className={cx(styles.box, 'cp position-relative align-middle')} data-testid="catalog-box">
      {showArchiveBtn ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <span
          className={cx(styles.archiveBtn, 'cp d-block end-0 position-absolute')}
          data-testid="archive-modal-btn"
          onClick={() => setShowArchiveModal(true)}
        >
          <i className="fa-fw fa fa-archive" />
        </span>
      ) : null}
      <Link
        className="text-decoration-none text-reset"
        to={`/${environment}/catalog/${hasNoId ? 'add' : id}`}
      >
        {hasNoId ? (
          <Card className={cx(styles.wrapper, styles.add, 'text-center text-reset')}>
            <div
              className={cx(
                styles.logo,
                'bg-white align-items-center d-flex justify-content-center p-1 my-3 mx-auto'
              )}
            >
              <i className="fas fa-plus-circle fa-3x" />
            </div>
            <Card.Body>
              <Card.Title className={cx(styles.title, 'mb-3 text-truncate')}>
                Add Catalog
              </Card.Title>
              <Card.Title className={cx(styles.title, 'text-truncate')}>System</Card.Title>
              <Card.Text className="mt-5 mb-0 text-truncate" title="Click to create a catalog">
                Catalog Creation Form
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          <>
            <Card className={cx(styles.wrapper, 'text-center text-reset')}>
              <div
                className={cx(
                  styles.logo,
                  'bg-white align-items-center d-flex justify-content-center p-1 my-3 mx-auto'
                )}
              >
                <Card.Img
                  alt={customer}
                  className="img-thumbnail border-0"
                  src={customerLogoUri}
                  variant="top"
                />
              </div>
              <Card.Body>
                <Card.Title className={cx(styles.title, 'mb-3 text-truncate')}>{name}</Card.Title>
                <Card.Title className={cx(styles.title, 'text-truncate')}>{customer}</Card.Title>
                <Card.Text className="mt-5 mb-0 text-truncate" title={description}>
                  {description}
                </Card.Text>
              </Card.Body>
            </Card>
          </>
        )}
      </Link>
      {showArchiveModal ? (
        // @ts-ignore
        <ArchiveModal
          archiveDate={archiveDate}
          catalogName={name}
          show={showArchiveModal}
          onArchive={handleArchiveCatalog}
          onHide={() => setShowArchiveModal(false)}
        />
      ) : null}
    </div>
  )
}

export default CatalogBox
