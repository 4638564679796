import { Component } from 'react'
import _ from 'lodash'
import { createSelectorCreator, lruMemoize } from 'reselect'
import { bindActionCreators } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { slvyToast } from '@/components/SlvyToast'
import { PluginTypes } from '../..'
import PluginUpdateQueue from '@/helpers/PluginUpdateQueue'

/* Crud */
import { selectCollection, select, isSuccess, isFailed } from '@/crudoptV3'
import { runPluginQuery, clearPluginRecord } from '@/actions/query'
import { registerPluginOutput, updatePlugin } from '@/actions/plugin'
import {
  getPluginStates,
  createPluginState,
  updatePluginState,
  deletePluginState,
  deleteAllPluginState,
  clearPluginStateModel
} from '@/actions/pluginstate'
import { createLog } from '@/actions/serverLogger'

import { updateRow, deleteRow, addRowIndex, clearCaches } from '@/store/slices/localData'

import { addMessage } from '@/store/slices/logger'
import { triggerExportData } from '@/store/slices/exportData'
import { RootState } from '@/store'

import { RUN_EVENT, RUN_METHOD } from '@/libs/workflow'

import pubsub from '@/subs'

import ApiClientWithProgress from '@/crudoptV3/libs/apiClientWithProgress'
import ApiClient from '@/crudoptV3/libs/apiClient'
import {
  ActualFilters,
  NewData,
  NewQuery,
  NewStates,
  OwnProps,
  SettingsData,
  StatesData,
  WrapperProps,
  WrapperProvidedProps,
  WrapperStates
} from './Wrapper.types'
import {
  FetchPageDataParams,
  FetchPageDataReturnType,
  RegisterMethodArg
} from '../PluginProps.types'
import { Args, SetDataArgsKey, StatesToPropsStates } from '../StatesToProps/StatesToProps.types'
import { IValueFilter } from '@/containers/EventMethod/components/Common.types'

const Params = {
  get isRequired() {
    throw new Error('missing parameter')
  }
}
const clientWithProgress = new ApiClientWithProgress()
const client = new ApiClient()

const defaultData: SettingsData = {
  result: [],
  rowcount: undefined,
  schema: undefined,
  configurationhints: {},
  updatehints: {},
  additionaltables: []
}

class Wrapper extends Component<WrapperProps, WrapperStates> {
  constructor() {
    super()
    this.registerDataArguments = this.registerDataArguments.bind(this)
    this.setDataArguments = this.setDataArguments.bind(this)
    this.registerMethod = this.registerMethod.bind(this)
    this.reloadExtRoot = this.reloadExtRoot.bind(this)
    this.hideExtRoot = this.hideExtRoot.bind(this)
    this.setConnection = this.setConnection.bind(this)
    this.registerEvent = this.registerEvent.bind(this)
    this.fetchPageData = this.fetchPageData.bind(this)
    this.getTotalPage = this.getTotalPage.bind(this)
    this.getTotalRowCount = this.getTotalRowCount.bind(this)
    this.isPagedQuery = this.isPagedQuery.bind(this)
    this.getPageSize = this.getPageSize.bind(this)
    this.reloadPluginData = this.reloadPluginData.bind(this)
    this.updateRowInLocalData = this.updateRowInLocalData.bind(this)
    this.deleteRowInLocalData = this.deleteRowInLocalData.bind(this)
    this.addRowIndexToLocalData = this.addRowIndexToLocalData.bind(this)
    this.updateData = this.updateData.bind(this)
    this.clearCaches = this.clearCaches.bind(this)
    this.createLog = this.createLog.bind(this)
    this.registerAuthorizations = this.registerAuthorizations.bind(this)
    this.isAllowed = this.isAllowed.bind(this)
    this.afterDataLoad = this.afterDataLoad.bind(this)
    this.createPluginState = this.createPluginState.bind(this)
    this.updatePluginState = this.updatePluginState.bind(this)
    this.deletePluginState = this.deletePluginState.bind(this)
    this.deleteAllPluginState = this.deleteAllPluginState.bind(this)
    this.loadPluginStates = this.loadPluginStates.bind(this)
    this.getQueryArguments = this.getQueryArguments.bind(this)
    this.state = { data: {} }
    this.run = false
    this.additionalArgs = {}
  }

  exportDataArguments = (args) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { triggerExportData = () => {}, id: pluginId = '' } = this.props
    const { _CONNECTIONID } = this.additionalArgs

    triggerExportData({ pluginId, args, _CONNECTIONID })
  }

  setDataArguments(
    args: Args,
    force = false,
    suppressProgress = false,
    setDataArgsKey: SetDataArgsKey
  ) {
    this.suppressProgress = suppressProgress
    this.run = true
    this.props.statesToPropsData.setDataArguments(
      { ...args, ...this.additionalArgs },
      force,
      setDataArgsKey
    )
  }

  setConnection(params: Record<'connectionId', number>) {
    const { connectionId } = params
    this.additionalArgs._CONNECTIONID = connectionId
  }

  getData() {
    const { settings: { data: settingsData } = {}, query: { data = {} } = {} } = this.props
    const { result, rowcount = 0, schema } = settingsData || data
    return {
      result,
      rowcount,
      schema
    }
  }

  fetchPageData(params: FetchPageDataParams): FetchPageDataReturnType {
    const { pageNumber, pluginSorters, pluginFilters, forceLoad } = params
    const {
      isPreviewMode,
      statesToPropsData,
      settings: { query: { valueFilters = [] } } = {}
    } = this.props

    if (isPreviewMode || pageNumber < 0) {
      return null
    }

    const { defaultValue = 0 } =
      valueFilters.find(({ parameterName }) => parameterName === 'TAKE') || {}

    if (defaultValue === 0) {
      return null
    }

    const offset = (pageNumber - 1) * parseInt(defaultValue, 10)

    const args = {
      ...statesToPropsData.args,
      SKIP: offset,
      PLUGINFILTERS: pluginFilters
    }

    const ORDERBY = valueFilters.find(({ parameterName }) => parameterName === 'ORDERBY')
    if (ORDERBY) {
      args.ORDERBY = pluginSorters
    }

    this.additionalArgs.ORDERBY = pluginSorters
    this.additionalArgs.SKIP = offset
    this.additionalArgs.PLUGINFILTERS = pluginFilters

    if (forceLoad) {
      this.setDataArguments(args)
    }

    return offset
  }

  getTotalPage() {
    const { rowcount } = this.getData()
    const total = parseInt(rowcount, 10)
    if (rowcount > 0) {
      const { settings: { query: { valueFilters = [] } } = {} } = this.props
      const { defaultValue = 0 } = valueFilters.find((o) => o.parameterName === 'TAKE')
      if (defaultValue !== 0) {
        return Math.round(total / parseInt(defaultValue, 10))
      }
    }
    return null
  }

  getTotalRowCount() {
    const { rowcount = 0 } = this.getData()
    if (rowcount > 0) {
      return parseInt(rowcount, 10)
    }
    return null
  }

  isPagedQuery() {
    const { settings: { query: { valueFilters } } = {} } = this.props
    if (!valueFilters) {
      return false
    }

    const take: IValueFilter = valueFilters.find(({ parameterName }) => parameterName === 'TAKE')
    const skip: IValueFilter = valueFilters.find(({ parameterName }) => parameterName === 'SKIP')

    return take && skip
  }

  reloadPluginData() {
    const {
      id,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      clearPluginRecord,
      statesToPropsData: { args }
    } = this.props
    clearPluginRecord(id, {
      data: {
        id,
        arguments: args
      }
    })
  }

  updateData(updating: boolean) {
    // TODO: Currently this function only updates the state indicating that the plugin data is being updated.
    // Transfer all the data editing code here.
    this.setState({ pluginUpdating: updating })
  }

  clearCaches(suppressProgress = false) {
    this.suppressProgress = suppressProgress
    this.props.clearCaches(this.props.query.fetch.meta.key)
  }

  createLog(info: string) {
    this.props.createLog(this.props.id, this.props.id, info, this.props.statesToPropsData.args)
  }

  updateRowInLocalData(index: number, record: object) {
    this.props.updateRow(this.props.query.fetch.meta.key, index, record)
  }

  deleteRowInLocalData(index: number) {
    this.props.deleteRow(this.props.query.fetch.meta.key, index)
  }

  addRowIndexToLocalData() {
    this.props.addRowIndex(this.props.query.fetch.meta.key)
  }

  getPageSize() {
    const { settings: { query: { valueFilters = [] } } = {}, args: filterValues = {} } = this.props
    if (valueFilters && valueFilters.length > 0) {
      if (filterValues && _.has(filterValues, 'TAKE')) {
        return parseInt(filterValues.TAKE, 10)
      }
      const { defaultValue = 0 } =
        valueFilters.find(({ parameterName }) => parameterName === 'TAKE') || {}

      return parseInt(defaultValue, 10)
    }
    return undefined
  }

  afterDataLoad<T extends object>(row: T): T

  afterDataLoad(): Record<string, never>

  // eslint-disable-next-line class-methods-use-this
  afterDataLoad(row?: object): object | Record<string, never> {
    return row || {}
  }

  componentDidMount() {
    this.registerDataArguments()

    const {
      id,
      onMounted,
      query: { isSuccess, data = {} } = {},
      settings: { query: { fields = [] } = {} } = {},
      config: { general: { remoteMatrix = false } = {} } = {}
    } = this.props

    this.afterDataLoad = this.registerEvent({
      key: 'afterDataLoad',
      fn: this.afterDataLoad,
      returnTypes: _.transform(
        fields,
        (result, field) => {
          result[field.fieldName] = PluginTypes.fromString(field.dataType)
        },
        {}
      )
    })

    onMounted({ id })

    if (!remoteMatrix && isSuccess) {
      this.afterDataLoad(_.first(data?.result ?? []))
      this.run = false
      this.setState({ data })
    }
  }

  componentWillUnmount() {
    const { id } = this.props
    pubsub.unsubscribeWithFilter({ id })
    this.clearCaches()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      settings: { config: { general: { name = '', remoteMatrix = false } = {} } = {} } = {}
    } = this.props
    if (
      !remoteMatrix &&
      (isSuccess(this.props.query, nextProps.query) ||
        (nextProps.query.isSuccess &&
          !_.isEqual(this.props.query.fetchKey, nextProps.query.fetchKey)))
    ) {
      this.afterDataLoad(_.first(nextProps?.query?.data?.result ?? []))
    }
    if (
      isSuccess(this.props.query, nextProps.query) ||
      (nextProps.query.isSuccess && !_.isEqual(this.props.query.data, nextProps.query.data))
    ) {
      this.run = false
      const {
        query: { data = {} } = {},
        statesToPropsData: { setDataArgsKey }
      } = nextProps
      this.props.addMessage({
        flag: 'GET',
        title: `Query @ ${name}`,
        message: data?.debuginfo ?? ''
      })
      this.setState({ data, setDataArgsKey })
    }
    if (isFailed(this.props.query, nextProps.query)) {
      const { query: { error: { errorType, message, messageHeader, messageType } = {} } = {} } =
        nextProps
      if (errorType === 'TooMuchData') {
        this.displayToastrMessage(messageHeader, name + message, messageType)
      } else {
        this.displayToastrMessage('There is a problem', `Cannot get ${name} data`, 'warning')
      }
    }
    if (!remoteMatrix && this.run && nextProps.query.needFetch) {
      nextProps.dispatch(nextProps.query.fetch)
    } else if (!remoteMatrix && nextProps.query.needFetch && nextProps.statesToPropsData.force) {
      nextProps.dispatch(nextProps.query.fetch)
    }
  }

  displayToastrMessage(header, message, messageType = 'error') {
    if (messageType === 'warning') {
      slvyToast.warning({ message, title: header })
    } else {
      slvyToast.error({ message, title: header })
    }
  }

  loadPluginStates() {
    const { id } = this.props
    this.props.getPluginStates(id)
  }

  createPluginState(key: StatesData['name'], val: StatesData['config']) {
    const {
      id,
      params: { catalogId }
    } = this.props
    return this.props.createPluginState(`create_${id}`, id, {
      name: key,
      pluginId: id,
      config: val,
      catalogId
    })
  }

  updatePluginState(editedPluginState: StatesData) {
    const { id, updatePluginState } = this.props
    return updatePluginState(`update_${id}`, editedPluginState.id, editedPluginState)
  }

  deletePluginState(stateId: string) {
    const { id, deletePluginState } = this.props
    return deletePluginState(`delete_${id}`, id, stateId)
  }

  deleteAllPluginState() {
    const { id, deleteAllPluginState, clearPluginStateModel } = this.props

    return new Promise((resolve, reject) => {
      deleteAllPluginState(`delete_${id}`, id)
        .then(() => {
          clearPluginStateModel()
          resolve()
        })
        .catch(reject)
    })
  }

  registerAuthorizations(keys = Params.isRequired) {
    const {
      id,
      wrapperData,
      wrapperData: { pluginAuthorization = [] } = {},
      params: { catalogId, menuId, pageId },
      updatePlugin
    } = this.props
    const currentKeys = _.map(pluginAuthorization, (auth) => auth.key)
    const diff = _.xor(keys, currentKeys)
    if (_.size(diff) > 0) {
      const newObj = _.transform(
        keys,
        (result, item) => {
          result.push({
            key: item,
            userGroups:
              _.find(pluginAuthorization, { key: item }) &&
              _.find(pluginAuthorization, { key: item }).userGroups
                ? _.find(pluginAuthorization, { key: item }).userGroups
                : undefined
          })
        },
        []
      )
      updatePlugin(`update_plugin_${id}`, catalogId, menuId, pageId, id, {
        ...wrapperData,
        pluginAuthorization: newObj
      })
    }
  }

  // eslint-disable-next-line class-methods-use-this
  reloadExtRoot($pluginId: string, $callback = () => {}) {
    dispatchEvent(
      new CustomEvent('reloadExtRoot', { detail: { pluginId: $pluginId, callback: $callback } })
    )
  }

  // eslint-disable-next-line class-methods-use-this
  hideExtRoot($pluginId: string, $callback = () => {}) {
    dispatchEvent(
      new CustomEvent('hideExtRoot', { detail: { pluginId: $pluginId, callback: $callback } })
    )
  }

  registerMethod({ key = Params.isRequired, fn = Params.isRequired, args = Params.isRequired }) {
    const {
      id,
      wrapperData,
      params: { catalogId, menuId, pageId }
    } = this.props
    const mappedParams = _.transform(
      args,
      (result, value) => {
        if (value.type) {
          result[value.name] = { type: PluginTypes.toString(value.type) }
        }
      },
      {}
    )
    const { registers: { methods: { [key]: { params } = {} } = {} } = {} } = wrapperData
    if (!_.isEqual(params, mappedParams)) {
      // TODO prevent update for RemoteMatrix
      this.props.registerPluginOutput('update', catalogId, menuId, pageId, id, 'methods', {
        [key]: { params: mappedParams }
      })
    }
    pubsub.subscribe(
      key + id,
      (...p) => {
        fn(...p)
        this.props.dispatch({
          type: RUN_METHOD,
          payload: { pluginId: id, methodKey: key }
        })
      },
      { type: 'method', id }
    )
  }

  registerEvent({
    key = Params.isRequired,
    fn = Params.isRequired,
    returnTypes = Params.isRequired
  }) {
    const {
      id,
      wrapperData,
      params: { catalogId, menuId, pageId },
      registerPluginOutput,
      dispatch
    } = this.props

    const { registers: { events: { [key]: { params } = {} } = {} } = {} } = wrapperData
    const mappedParams = _.transform(
      returnTypes,
      (ret, value, key) => {
        ret[key] = { type: PluginTypes.toString(value) }
      },
      {}
    )

    if (!_.isEqual(params, mappedParams)) {
      registerPluginOutput('update', catalogId, menuId, pageId, id, 'events', {
        [key]: { params: mappedParams }
      })
    }
    const newFunc = (...params) => {
      /* We should delete localStorage items about googleAnalyticsTime that we've added before. */
      const localStorageArr = Object.keys(localStorage)
      const localStorageGoogleArr = localStorageArr.filter(
        (item) => item.indexOf('googleAnalyticsTime') > -1
      )
      if (localStorageGoogleArr.length) {
        localStorageGoogleArr.forEach((item) => localStorage.removeItem(item))
      }

      const result = fn(...params)
      dispatch({
        type: RUN_EVENT,
        payload: { result, pluginId: id, eventKey: key }
      })
      return result
    }
    pubsub.subscribe(key + id, newFunc, { type: 'event', id })
    return newFunc
  }

  getQueryArguments(): RegisterMethodArg[] {
    const {
      settings: {
        query: { conditionFilters = [], valueFilters = [], groupByFilters = [] } = {}
      } = {}
    } = this.props
    const newConditionFilters = conditionFilters.map((o) => ({
      name: o.argumentName,
      type: o.isMultiple
        ? PluginTypes.arrayOf(PluginTypes.fromString(o.dataType))
        : PluginTypes.fromString(o.dataType)
    }))
    const newValueFilters = valueFilters.map((o) => ({
      name: o.argumentName,
      type: o.isMultiple
        ? PluginTypes.arrayOf(PluginTypes.fromString(o.dataType))
        : PluginTypes.fromString(o.dataType)
    }))
    const newGroupByFilters = groupByFilters.map((o) => ({
      name: o.argumentName,
      type: PluginTypes.string
    }))
    return _.concat(newConditionFilters, newValueFilters, newGroupByFilters)
  }

  getActualFilters(): ActualFilters {
    const {
      settings: { query: { conditionFilters = [], valueFilters = [] } = {} } = {},
      statesToPropsData: { args: filterValues = {} }
    } = this.props
    const filterNames = [...conditionFilters, ...valueFilters].map((f) => {
      return f.argumentName
    })

    return _.reduce(
      filterNames,
      (result: ActualFilters, filterName) => {
        if (filterValues !== null && _.has(filterValues, filterName)) {
          // eslint-disable-next-line no-param-reassign
          result[filterName] = filterValues[filterName as keyof StatesToPropsStates['args']]
        } else {
          // eslint-disable-next-line no-param-reassign
          result[filterName] = null
        }
        return result
      },
      {}
    )
  }

  enqueueUpdateRequest(pluginId: string, request: object) {
    PluginUpdateQueue.enqueueUpdateRequest(pluginId, request)
  }

  dequeueUpdateRequest(pluginId: string) {
    return PluginUpdateQueue.dequeueUpdateRequest(pluginId)
  }

  getQueueSize(pluginId: string) {
    return PluginUpdateQueue.getQueueSize(pluginId)
  }

  registerDataArguments() {
    const { settings: { config: { general: { export: isExportable = false } = {} } = {} } = {} } =
      this.props

    this.registerMethod({
      key: 'setDataArguments',
      fn: this.setDataArguments,
      args: this.getQueryArguments()
    })

    this.registerMethod({
      key: 'setConnection',
      fn: this.setConnection,
      args: [{ name: 'connectionId', type: PluginTypes.int }]
    })

    if (isExportable) {
      this.registerMethod({
        key: 'exportData',
        fn: this.exportDataArguments,
        args: this.getQueryArguments()
      })
    }
  }

  isAllowed(key: string) {
    const { wrapperData: { pluginAuthorization = [] } = {} } = this.props
    const pluginAuthorizationItem = _.find(pluginAuthorization, (auth) => auth.key === key)
    // If the key we are asking does not exist at all, it is allowed
    return pluginAuthorizationItem ? pluginAuthorizationItem.isAllowed : true
  }

  render() {
    const {
      settings: {
        query: { query: queryString },
        data: settingsData, // for plugin preview
        status: { pending: statusPending, isSuccess: statusIsSuccess } = {}, // for plugin preview
        config: { general: { name = '', showNoDataToDisplay = false } = {} } = {}
      },
      // eslint-disable-next-line no-shadow
      query: { pending = statusPending, isSuccess = statusIsSuccess } = {},
      states: { data: statesData = {} } = {},
      children
    } = this.props
    const { data, pluginUpdating = false, setDataArgsKey } = this.state

    const settingsDataFinal = settingsData || (data ?? {})
    const {
      result = defaultData.result,
      rowcount,
      schema,
      configurationhints,
      updatehints,
      additionaltables
    }: SettingsData = { ...defaultData, ...settingsDataFinal }

    // TODO: Remove after hook conversion
    // START
    const newQuery: NewQuery = { isSuccess, pending }
    if (!_.isEqual(this?.MEMOIZED_QUERY, newQuery)) {
      this.MEMOIZED_QUERY = newQuery
    }

    const newData: NewData = {
      rowcount,
      schema,
      configurationhints,
      updatehints,
      additionaltables,
      setDataArgsKey
    }
    if (!_.isEqual(this?.MEMOIZED_DATA, newData)) {
      this.MEMOIZED_DATA = newData
    }

    const newStates: NewStates = { result: statesData }
    if (!_.isEqual(this?.MEMOIZED_STATES, newStates)) {
      this.MEMOIZED_STATES = newStates
    }

    const newActualFilters = this.getActualFilters()
    if (!_.isEqual(this?.MEMOIZED_ACTUAL_FILTERS, newActualFilters)) {
      this.MEMOIZED_ACTUAL_FILTERS = newActualFilters
    }
    // END

    const isLoading: boolean = (pending || pluginUpdating) && !this.suppressProgress

    const props: WrapperProvidedProps = {
      client,
      clientWithProgress,
      query: this.MEMOIZED_QUERY,
      // query: {
      //   // should memo
      //   isSuccess,
      //   pending
      // },
      data: this.MEMOIZED_DATA,
      // data: {
      //   // should memo
      //   rowcount,
      //   schema,
      //   configurationhints,
      //   updatehints,
      //   additionaltables,
      //   setDataArgsKey
      // },
      states: this.MEMOIZED_STATES,
      // states: {
      //   // should memo
      //   result: statesData
      // },
      pluginData: result,
      isPluginDataLoaded: Array.isArray(settingsDataFinal?.result) || result === null,
      // TODO: Workaround for: "Ag-Grid Cant load empty"
      isPluginDataLoading: isLoading,
      actualFilters: this.MEMOIZED_ACTUAL_FILTERS,
      // actualFilters: this.getActualFilters(), // should memo
      addRowIndexToLocalData: this.addRowIndexToLocalData,
      additionalArgs: this.additionalArgs,
      afterDataLoad: this.afterDataLoad,
      clearCaches: this.clearCaches,
      createLog: this.createLog,
      createPluginState: this.createPluginState,
      deleteAllPluginState: this.deleteAllPluginState,
      deletePluginState: this.deletePluginState,
      deleteRowInLocalData: this.deleteRowInLocalData,
      dequeueUpdateRequest: this.dequeueUpdateRequest,
      enqueueUpdateRequest: this.enqueueUpdateRequest,
      fetchPageData: this.fetchPageData,
      getPageSize: this.getPageSize,
      getQueryArguments: this.getQueryArguments,
      getQueueSize: this.getQueueSize,
      getTotalPage: this.getTotalPage,
      getTotalRowCount: this.getTotalRowCount,
      hideExtRoot: this.hideExtRoot,
      isAllowed: this.isAllowed,
      isPagedQuery: this.isPagedQuery,
      loadPluginStates: this.loadPluginStates,
      registerAuthorizations: this.registerAuthorizations,
      registerEvent: this.registerEvent,
      registerMethod: this.registerMethod,
      reloadExtRoot: this.reloadExtRoot,
      reloadPluginData: this.reloadPluginData,
      setDataArguments: this.setDataArguments,
      updateData: this.updateData,
      updatePluginState: this.updatePluginState,
      updateRowInLocalData: this.updateRowInLocalData
    }

    const displayNoData: boolean =
      showNoDataToDisplay && isSuccess && !_.isEmpty(queryString) && _.size(result) === 0

    return children({
      name,
      displayNoData,
      isLoading,
      wrapperProps: props
    })
  }
}

const getQueryState = (state: RootState, ownProps: OwnProps) => {
  return select(
    runPluginQuery(ownProps.id, {
      data: {
        id: ownProps.id,
        arguments: ownProps.statesToPropsData.args
      }
    }),
    state.model3
  )
}

const getPluginState = (state: RootState, ownProps: OwnProps) => {
  return selectCollection(getPluginStates(ownProps.id), state.model3)
}

const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual)

const getQuerySelector = () => {
  return createDeepEqualSelector([getQueryState], (plugins) => plugins)
}
const getStateSelector = () => {
  return createDeepEqualSelector([getPluginState], (states) => states)
}
const makeMapStateToProps = () => {
  const query = getQuerySelector()
  const states = getStateSelector()

  const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    return {
      query: query(state, ownProps),
      states: states(state, ownProps)
    }
  }
  return mapStateToProps
}

const connector = connect(makeMapStateToProps, (dispatch) => ({
  dispatch,
  addMessage: bindActionCreators(addMessage, dispatch),
  addRowIndex: bindActionCreators(addRowIndex, dispatch),
  clearCaches: bindActionCreators(clearCaches, dispatch),
  clearPluginRecord: bindActionCreators(clearPluginRecord, dispatch),
  clearPluginStateModel: bindActionCreators(clearPluginStateModel, dispatch),
  createLog: bindActionCreators(createLog, dispatch),
  createPluginState: bindActionCreators(createPluginState, dispatch),
  deleteAllPluginState: bindActionCreators(deleteAllPluginState, dispatch),
  deletePluginState: bindActionCreators(deletePluginState, dispatch),
  deleteRow: bindActionCreators(deleteRow, dispatch),
  getPluginStates: bindActionCreators(getPluginStates, dispatch),
  registerPluginOutput: bindActionCreators(registerPluginOutput, dispatch),
  runPluginQuery: bindActionCreators(runPluginQuery, dispatch),
  triggerExportData: bindActionCreators(triggerExportData, dispatch),
  updatePlugin: bindActionCreators(updatePlugin, dispatch),
  updatePluginState: bindActionCreators(updatePluginState, dispatch),
  updateRow: bindActionCreators(updateRow, dispatch)
}))

export type WrapperPropsFromRedux = ConnectedProps<typeof connector>

export default connector(Wrapper)
