export default {
  title: 'Image Box',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'Image-Box',
  description: 'to show an image in a box',
  iconClass: 'fa-picture-o',
  color: 'navy-bg',
  group: 'Views',
  support: {
    maximizable: true
  }
}
