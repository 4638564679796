import { useState, useEffect, useMemo, ChangeEvent, FormEvent } from 'react'
import { FormControl, FormLabel, FormCheck, Form, Row, Col, Button, Badge } from 'react-bootstrap'
import { slvyToast } from '@/components'
import defaultForm from './constants'
import { AdminContainerProps } from '@/elements/Container/Admin/Admin.types'
import { PanePosition } from '../SplitContainer.types'
import { FormState, PaneSize, Step } from './Admin.types'

const AdminContainer = (props: AdminContainerProps) => {
  const {
    settings,
    settings: { form: initialForm },
    onChangeSettings
  } = props

  const getForm = useMemo(() => {
    return {
      ...defaultForm,
      ...(initialForm || {})
    }
  }, [initialForm])

  const [form, setForm] = useState<FormState>(getForm)

  useEffect(() => {
    setForm(getForm)
  }, [getForm])

  const handleChangeIsCollapsedInitially = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    setForm((prevForm) => ({ ...prevForm, isCollapsedInitially: checked }))
  }

  const handleChangeIsSizeChangeable = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    setForm((prevForm) => ({ ...prevForm, isSizeChangeable: checked }))
  }

  const handleChangeSplitType = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    const isHorizontal = value === 'horizontal'
    const panePosition = isHorizontal ? 'top' : 'right'
    setForm((prevForm) => ({ ...prevForm, defaultPane: panePosition, horizontal: isHorizontal }))
  }

  const handleChangeDefaultPane = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    setForm((prevForm) => ({ ...prevForm, defaultPane: value as PanePosition }))
  }

  const handleChangePanesize = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    const panesize = parseInt(value, 10) as PaneSize
    setForm((prevForm) => ({ ...prevForm, panesize }))
  }

  const handleChangeStep = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    const step = parseInt(value, 10) as Step
    setForm((prevForm) => ({ ...prevForm, step }))
  }

  const handleChangeSplitOpenedTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    setForm((prevForm) => ({ ...prevForm, splitOpenedTitle: value }))
  }

  const handleChangeSplitCollapsedTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event

    setForm((prevForm) => ({ ...prevForm, splitCollapsedTitle: value }))
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onChangeSettings({
      ...settings,
      form: {
        ...form
      }
    })
    slvyToast.success({ message: 'Saved successfully' })
  }

  const {
    splitOpenedTitle,
    splitCollapsedTitle,
    horizontal,
    panesize,
    isCollapsedInitially,
    defaultPane,
    step,
    isSizeChangeable
  } = form

  return (
    <Form noValidate className="containerForm" onSubmit={handleSubmit}>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm mb-0">
          <Form.Check
            checked={isCollapsedInitially}
            className="mh-auto mb-0 form-check-sm"
            id="isCollapsedInitially"
            label="Collapsed Initially"
            type="checkbox"
            value="0"
            onChange={handleChangeIsCollapsedInitially}
          />
        </FormLabel>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block">Split Type</FormLabel>
        <Form.Check
          inline
          checked={!horizontal}
          className="mh-auto mb-0 form-check-sm"
          label="Vertical"
          name="splitgroup"
          type="radio"
          value="vertical"
          onChange={handleChangeSplitType}
        />
        <Form.Check
          inline
          checked={horizontal}
          className="mh-auto mb-0 form-check-sm"
          label="Horizontal"
          name="splitgroup"
          type="radio"
          value="horizontal"
          onChange={handleChangeSplitType}
        />
      </Form.Group>
      {horizontal ? (
        <Form.Group className="mb-2">
          <FormLabel className="fs-sm d-block">Default Pane</FormLabel>
          <FormCheck
            inline
            checked={defaultPane === 'top'}
            className="mh-auto mb-0 form-check-sm"
            label="Top"
            name="panegroup2"
            type="radio"
            value="top"
            onChange={handleChangeDefaultPane}
          />
          <FormCheck
            inline
            checked={defaultPane === 'bottom'}
            className="mh-auto mb-0 form-check-sm"
            label="Bottom"
            name="panegroup2"
            type="radio"
            value="bottom"
            onChange={handleChangeDefaultPane}
          />
        </Form.Group>
      ) : (
        <Form.Group className="mb-2">
          <FormLabel className="fs-sm d-block">Default Pane</FormLabel>
          <Form.Check
            inline
            checked={defaultPane === 'left'}
            className="mh-auto mb-0 form-check-sm"
            label="Left"
            name="panegroup"
            type="radio"
            value="left"
            onChange={handleChangeDefaultPane}
          />
          <Form.Check
            inline
            checked={defaultPane === 'right'}
            className="mh-auto mb-0 form-check-sm"
            label="Right"
            name="panegroup"
            type="radio"
            value="right"
            onChange={handleChangeDefaultPane}
          />
        </Form.Group>
      )}
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">First Pane Size (%)</FormLabel>
        <Row className="d-flex align-items-center">
          <Col sm={10}>
            <Form.Range
              max={100}
              min={0}
              step={5}
              value={panesize}
              onChange={handleChangePanesize}
            />
          </Col>
          <FormLabel column className="pt-0" sm={2}>
            <Badge bg="info">{panesize}</Badge>
          </FormLabel>
        </Row>
        <Form.Control.Feedback type="invalid">
          Value must be between 0 and 100!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Title Opened</FormLabel>
        <FormControl
          size="sm"
          type="text"
          value={splitOpenedTitle}
          onChange={handleChangeSplitOpenedTitle}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Title Collapsed</FormLabel>
        <FormControl
          size="sm"
          type="text"
          value={splitCollapsedTitle}
          onChange={handleChangeSplitCollapsedTitle}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm mb-0" htmlFor="isSizeChangeable">
          <FormCheck
            checked={isSizeChangeable}
            className="mh-auto mb-0 form-check-sm"
            id="isSizeChangeable"
            label=" Size Changeable"
            type="checkbox"
            onChange={handleChangeIsSizeChangeable}
          />
        </FormLabel>
      </Form.Group>
      {isSizeChangeable ? (
        <Form.Group className="mb-2">
          <FormLabel className="fs-sm">Step (%)</FormLabel>
          <Row className="d-flex align-items-center">
            <Col sm={10}>
              <Form.Range max={50} min={5} step={5} value={step} onChange={handleChangeStep} />
            </Col>
            <FormLabel column className="pt-0" sm={2}>
              <Badge bg="info">{step}</Badge>
            </FormLabel>
          </Row>
          <Form.Control.Feedback type="invalid">
            Value must be between 5 and 50!
          </Form.Control.Feedback>
        </Form.Group>
      ) : null}
      <Button size="sm" type="submit" variant="success">
        Save
      </Button>
    </Form>
  )
}

export default AdminContainer
