import { useCallback } from 'react'
import { pick } from 'lodash'
import { IRowNode } from 'ag-grid-community'
import { objectKeys } from '@/ts/utils'
import { AG_GRID_ENUMS } from '../../enums'
import { IRowData, IRowDataGrid, SlvyColDef } from '../../types'
import { formatDate, updateWithSubstituteNewValue, omitCustomFields } from '../../helpers'
import { ValidateAddingProps } from './useValidateAdding.types'

const { CUSTOM_ROW_INFO, DIRTY_CELLS, ADDED_ROWS } = AG_GRID_ENUMS

function useValidateAdding(props: ValidateAddingProps) {
  const { gridRef, adding, columnDefs } = props
  const validateAdding = useCallback(() => {
    const result: {
      isAddingValid: boolean
      invalidAddingFields: { field: string; headerName: string; rowIndex: number }[]
    } = {
      isAddingValid: true,
      invalidAddingFields: []
    }

    const { enabled: addingEnabled = false } = adding

    const addedRecords: { data: IRowDataGrid; rowIndex: number }[] = []

    gridRef?.current?.api.forEachNode(({ data, rowIndex }: IRowNode<IRowData>) => {
      // rowNode.data is undefined while grouping is active
      if (data) {
        const { [DIRTY_CELLS]: dirtyCells, [ADDED_ROWS]: addedRows } = pick(data[CUSTOM_ROW_INFO], [
          DIRTY_CELLS,
          ADDED_ROWS
        ])

        if (addedRows.length && typeof rowIndex === 'number') {
          // TODO: check order of omitCustomFields and updateWithSubstituteNewValue
          const restData = omitCustomFields(data)
          let updatedRowData = updateWithSubstituteNewValue({ data: restData, dirtyCells })
          updatedRowData = formatDate(updatedRowData)
          addedRecords.push({ data: updatedRowData, rowIndex })
        }
      }
    })

    addedRecords.forEach((record) => {
      objectKeys(record.data).forEach((colKey) => {
        const currentColDef = columnDefs.find((col) => col.field === colKey) as SlvyColDef
        const allowBlank = currentColDef?.customInfo?.editing?.allowBlank
        const addingEnabledForColumn = currentColDef?.customInfo?.adding?.enabled ?? false

        const value = record.data[colKey]
        const isValueInvalid = value === '' || value === null || value === undefined

        if (addingEnabled && addingEnabledForColumn && !allowBlank && isValueInvalid) {
          result.isAddingValid = false
          result.invalidAddingFields.push({
            field: colKey as string,
            headerName: currentColDef.headerName as string,
            rowIndex: record.rowIndex
          })
        }
      })
    })

    return result
  }, [adding, columnDefs, gridRef])

  return { validateAdding }
}

export default useValidateAdding
