export default `({
    columnLines: true,
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'top',
        height: 44,
        border: true,
        items: [{
            xtype: 'tbtext',
            bind: {
                html: ' <b>{spotRecord.MaterialDescription} ' + tt('Inventory List')+'</b>'
            }
        }]
    }],
    header: false,
    flex: 1,
    tbar: [
         '->',
        {
            xtype: 'button',
            text: tt('Export to Excel'),
            handler: function (btn) {
                var grid = this.ownerCt.ownerCt
                grid.saveDocumentAs({
                    type: 'xlsx',
                    title: tt('InventoryList'),
                    fileName: tt('InventoryList')+'.xlsx',
                });
            }
        }
    ],
    plugins: [{
        ptype: 'gridexporter'
    }],
    features: [{
        ftype: 'summary',
        dock: 'top'
    }],
    columns: [
        {
            text: tt('DcCode'),
            dataIndex: 'DcCode',
            width: 90,
            locked: true
        },
        {
            text: tt('DcName'),
            dataIndex: 'DcName',
            width: 150,
            locked: true
        },
        {
            text: tt('Store'),
            columns: [{
                    text: tt('AcvtiveStore'),
                    dataIndex: 'NumberOfOpenStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum'
                },
                {
                    text: tt('HasInventory'),
                    dataIndex: 'NumberofStoreHasInventory',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum'
                },
                {
                    text: tt('TotaInventory'),
                    dataIndex: 'TotalStoreInventory',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum'
                }
            ]
        },
        {
            text: tt('DcInventory'),
            dataIndex: 'DcInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'sum'
        },
        {
            text: tt('TotatInventory'),
            dataIndex: 'TotalInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'sum'
        }
    ],
})
`
