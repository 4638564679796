import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IState {
  max: string
}

export const initialState: IState = {
  max: ''
}

export const maximize = createSlice({
  name: 'maximize',
  initialState,
  reducers: {
    min: () => {
      return initialState
    },
    max: (state, action: PayloadAction<IState['max']>) => {
      state.max = action.payload
    }
  }
})

export const { min, max } = maximize.actions
export default maximize.reducer
