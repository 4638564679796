import { Button } from 'react-bootstrap'
import { IEventMethodButtonProps } from './EventMethodButton.types'

const EventMethodButton = ({ text, variant, onClick }: IEventMethodButtonProps) => {
  return (
    <Button className="text-nowrap" size="sm" variant={variant} onClick={onClick}>
      {text}
    </Button>
  )
}

export default EventMethodButton
