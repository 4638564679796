import {
  fetchRecord,
  fetchCollection,
  updateOne,
  fetchOne,
  deleteRecord,
  clearModel
} from '../crudoptV3'

const model = 'page'

export const createPage = (operationKey, catalogId, menuId, data) => {
  return fetchRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page`,
    {
      data
    },
    {
      operationKey,
      collectionKey: { path: `/catalog/${catalogId}/menu/${menuId}/page` }
    }
  )
}

export const clonePage = (operationKey, catalogId, menuId, pageId) => {
  return fetchRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/clone/${pageId}`,
    {
      data: { catalogId, menuId, pageId }
    },
    {
      operationKey,
      collectionKey: { path: `/catalog/${catalogId}/menu/${menuId}/page` }
    }
  )
}

export const getPage = (catalogId, menuId, pageId) => {
  return fetchOne(model, `/catalog/${catalogId}/menu/${menuId}/page/${pageId}`, {}, { key: pageId })
}

export const getPages = (catalogId, menuId) => {
  return fetchCollection(model, `/catalog/${catalogId}/menu/${menuId}/page`)
}

export const getLockedPages = (catalogId) => {
  return fetchCollection(model, `/catalog/LockedPages/${catalogId}`)
}

export const movePage = (catalogId, menuId, id, data = {}) => {
  return updateOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${id}/move`,
    { data },
    {
      key: id,
      mapResult: (result, currentResult) => ({
        sortOrder: data.sortOrder,
        ...currentResult
      })
    }
  )
}

export const movePageToMenu = (catalogId, pageId, menuId) => {
  return updateOne(model, `catalog/${catalogId}/movepage/${pageId}/to/${menuId}`, {
    key: pageId,
    mapResult: (_, currentResult) => ({
      ...currentResult
    })
  })
}

export const updatePage = (catalogId, menuId, pageId, data) => {
  return updateOne(
    model,
    `catalog/${catalogId}/menu/${menuId}/page/${pageId}`,
    {
      data
    },
    { key: pageId }
  )
}

export const deletePage = (catalogId, menuId, pageId) => {
  return deleteRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/Force`,
    {
      params: { catalogId, menuId }
    },
    {
      key: pageId,
      collectionKey: { path: `/catalog/${catalogId}/menu/${menuId}/page` },
      getRowKey: () => pageId
    }
  )
}

export const clearPageModel = () => {
  return clearModel(model)
}

// Copy to environment actions
export const getEnvironments = () => {
  return fetchOne(model, `migrator/environmets`, {})
}

export const getCatalogs = (env) => {
  return fetchOne(model, `migrator/catalogs/${env}`)
}

export const getMenus = (env, catalogId) => {
  return fetchOne(model, `migrator/menus/${env}/${catalogId}`)
}

export const getCatalogConnections = (env, catalogId) => {
  return fetchOne(model, `migrator/getcatalogconnections/${env}/${catalogId}`)
}

export const getPageConnections = (catalogId, pageId) => {
  return fetchOne(model, `migrator/getpageconnections/${catalogId}/${pageId}`)
}

export const copyPageToEnvironment = (
  env,
  sourcePageId,
  destinationCatalogId,
  destinationMenuId,
  connectionMapping
) => {
  return updateOne(
    model,
    `migrator/copy/${env}/${sourcePageId}/${destinationCatalogId}/${destinationMenuId}`,
    {
      data: { connectionMapping }
    },
    {
      method: 'post'
    }
  )
}
