Ext.define('tuiv2.model.DemandDetailDataModel', {
  extend: 'tuiv2.model.BaseTree',
  entityName: 'tuiv2/DemandDetailData',

  fields: [
    {
      name: 'leaf',
      mapping: 'Leaf'
    },
    {
      name: 'checked',
      type: 'boolean',
      defaultValue: false
    }
  ]
})
