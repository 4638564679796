export const iconPositions = {
  right: 'left',
  left: 'right',
  bottom: 'up',
  top: 'down'
} as const

export const iconCollapsedPositions = {
  right: 'right',
  left: 'left',
  bottom: 'down',
  top: 'up'
} as const
