import traverseTree from '@/utils/traverseTree'
import { rootValue } from './constants'
import { VisibleNode } from './Treeview.types'

export const getLeavesOfTree = (nodes: VisibleNode[]): string[] => {
  const leafValues: string[] = []

  traverseTree({ children: nodes }, (node: VisibleNode) => {
    if (node.children.length === 0) {
      leafValues.push(node.value)
    }
  })

  return leafValues
}

export const getCheckedNodes = (
  visibleNodes: VisibleNode[],
  checked: string[],
  prevFirstChecked?: string
): string[] => {
  const firstChecked = checked[0]

  const isFirstCheckedAll = firstChecked === rootValue
  const isPrevFirstCheckedAll = prevFirstChecked === rootValue

  const isSelectAll = isFirstCheckedAll && !isPrevFirstCheckedAll
  const isDeselectAll = !isFirstCheckedAll && isPrevFirstCheckedAll
  const isUncheckWhenAllSelected = isFirstCheckedAll && isPrevFirstCheckedAll

  if (isSelectAll) {
    return getLeavesOfTree(visibleNodes)
  }

  if (isDeselectAll) {
    return []
  }

  if (isUncheckWhenAllSelected) {
    return checked.slice(1)
  }

  return checked
}

export const prependItem = (
  item: Record<string, string | number>,
  displayField: string,
  valueField: string,
  levelField: string,
  parentField: string
): `${string}~${string}~${string}~${string}` => {
  const level = levelField && levelField in item ? item[levelField] : 0
  const parent = parentField && parentField in item ? item[parentField] : 0

  return `${item[displayField]}~${item[valueField]}~${level}~${parent}`
}
