import { forwardRef, MouseEvent, ForwardedRef } from 'react'
import cx from 'classnames'
import { Button } from 'react-bootstrap'
import Tooltip from '../Tooltip'
import { IconButtonProps } from './IconButton.types'
import styles from './IconButton.module.scss'

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, children, tooltipText, onClick }, ref: ForwardedRef<HTMLButtonElement>) => {
    function handleClick(event: MouseEvent<HTMLButtonElement>) {
      onClick(event)
    }

    const button = (
      <Button
        ref={ref}
        bsPrefix="iconButton"
        className={cx(styles.iconButton, className)}
        onClick={handleClick}
      >
        {children}
      </Button>
    )

    return tooltipText ? <Tooltip text={tooltipText}>{button}</Tooltip> : button
  }
)

export default IconButton
