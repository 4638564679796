import { compareOppositeVariables } from '../index'

describe('AgGrid:Helpers:Common:compareOppositeVariables', () => {
  it('should return true when "enabled" is true and "notEnabled" is false', () => {
    const result = compareOppositeVariables({ enabled: true, notEnabled: false })
    expect(result).toBe(true)
  })

  it('should return false when "enabled" is false and "notEnabled" is true', () => {
    const result = compareOppositeVariables({ enabled: false, notEnabled: true })
    expect(result).toBe(false)
  })

  it('should return true when only "enabled" is provided as true', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = compareOppositeVariables({ enabled: true })
    expect(result).toBe(true)
  })

  it('should return false when only "notEnabled" is provided as true', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = compareOppositeVariables({ notEnabled: true })
    expect(result).toBe(false)
  })

  it('should return null when both "enabled" and "notEnabled" are not provided', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = compareOppositeVariables({})
    expect(result).toBeNull()
  })

  it('should return null when both "enabled" and "notEnabled" are null', () => {
    const result = compareOppositeVariables({ enabled: null, notEnabled: null })
    expect(result).toBeNull()
  })

  it('should return null when both "enabled" and "notEnabled" are undefined', () => {
    const result = compareOppositeVariables({ enabled: undefined, notEnabled: undefined })
    expect(result).toBeNull()
  })
})
