import { put } from 'redux-saga/effects'
import _ from 'lodash'
import { SET_PAGE_VARIABLES } from '../../relation'

export default function* runDrilldown(props = {}, result, action) {
  const { variableValues = {} } = props

  const modifiedVariableValues = _.transform(
    variableValues,
    (res, value, key) => {
      if (value === 'null' || value === 'NULL') {
        res[key] = null
      } else {
        res[key] = value
      }
    },
    {}
  )

  const {
    payload: {
      params: { storeIndex }
    }
  } = action
  yield put({
    type: SET_PAGE_VARIABLES,
    result: modifiedVariableValues,
    storeIndex
  })
  return modifiedVariableValues
}
