import { useState, useEffect, useRef, ReactElement } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import Mousetrap from 'mousetrap'
import { useAppDispatch, useAppSelector } from '@/hooks/store'
import { removeAllMessage } from '@/store/slices/logger'
import Dock from './Dock'
import DockItem from './DockItem'
import { ForwardRef, HandleDoubleClick, OnCopyToClipboard } from './Dock.types'
import styles from './index.module.scss'

const LayoutDock = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [ctrlshiftk, setCtrlshiftk] = useState<boolean>(window.ctrlshiftk)
  const [selectedLog, setSelectedLog] = useState<string>('')
  const textAreas = useRef<HTMLTextAreaElement[]>([])

  const dispatch = useAppDispatch()
  const logs = useAppSelector((state) => state.logger.clientLogs)

  useEffect(() => {
    Mousetrap.bind('ctrl+shift+k', () => {
      setIsVisible((prevIsVisible: boolean) => !prevIsVisible)
    })
  }, [])

  const forwardRef: ForwardRef = (key, textArea) => {
    textAreas.current[key] = textArea
  }

  const hide = () => {
    setIsVisible(false)
  }

  const toggleCtrlShiftK = () => {
    window.ctrlshiftk = !window.ctrlshiftk
    setCtrlshiftk(window.ctrlshiftk)
  }

  const onCopyToClipboard: OnCopyToClipboard = (index = 0) => {
    /* Get the text field */
    const copyText = textAreas.current[index]

    /* Select the text field */
    copyText.select()
    copyText.setSelectionRange(0, 99999) /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand('copy')
  }

  const handleDoubleClick: HandleDoubleClick = (event) => {
    const { target: { textContent = '' } = {} } = event
    setSelectedLog((prevSelectedLog: string) => (prevSelectedLog ? '' : textContent))
  }

  return (
    <div>
      <Dock isVisible={isVisible}>
        <div className="table-responsive">
          <table className="table table-hover issue-tracker">
            <tbody>
              {isVisible &&
                _.reduce(
                  logs,
                  (dockItems: ReactElement[], { flag = 'Added', title, message, date }, key) => {
                    if (selectedLog !== '') {
                      return selectedLog === title
                        ? [
                            ...dockItems,
                            <DockItem
                              key={uuidv4()}
                              date={date}
                              flag={flag}
                              forwardRef={forwardRef}
                              message={message}
                              objKey={key}
                              title={title}
                              onCopyToClipboard={onCopyToClipboard}
                              onDoubleClick={handleDoubleClick}
                            />
                          ]
                        : dockItems
                    }
                    return [
                      ...dockItems,
                      <DockItem
                        key={uuidv4()}
                        date={date}
                        flag={flag}
                        forwardRef={forwardRef}
                        message={message}
                        objKey={key}
                        title={title}
                        onCopyToClipboard={onCopyToClipboard}
                        onDoubleClick={handleDoubleClick}
                      />
                    ]
                  },
                  []
                )}
            </tbody>
          </table>
        </div>
        <i
          className={cx('position-absolute', 'fs-6', 'cp', 'fa', styles.onOff, {
            'fa-pause': ctrlshiftk,
            'fa-play': !ctrlshiftk
          })}
          title="Start/stop watching all changes"
          onClick={toggleCtrlShiftK}
        />
        <i
          className={cx('position-absolute', 'fs-6', 'cp', 'fa', 'fa-trash', styles.cleaning)}
          onClick={() => dispatch(removeAllMessage())}
        />
        <i
          className={cx('position-absolute', 'fs-6', 'cp', 'fa', 'fa-times', styles.remove)}
          onClick={hide}
        />
      </Dock>
    </div>
  )
}

export default LayoutDock
