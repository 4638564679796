import React, { Component } from 'react'
import _ from 'lodash'
import ImageBox from './imageBox'

class Row extends Component {
  render() {
    const { imageBoxes = [], imageHeight, imageFit, imageNumberInRow, imageBackground } = this.props
    const imageWidth = 100 / imageNumberInRow
    return (
      <div
        style={{
          display: 'flex',
          height: _.isEqual(imageHeight, 'Keep Aspect Ratio') ? '100%' : ''
        }}
      >
        {_.map(imageBoxes, (item, key) => (
          <ImageBox
            key={key}
            handleImageClick={this.props.handleImageClick}
            imageBackground={imageBackground}
            imageFit={imageFit}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            item={item}
          />
        ))}
      </div>
    )
  }
}
export default Row
