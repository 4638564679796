Ext.define('MDM.Btl.center.MainGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'btl-center-grid',
  reference: 'centergrid',
  height: 600,
  split: false,
  listeners: {
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady',
    beforeedit: 'onBeforeEdit',
    celldblclick: 'onCelldblClick'
  },
  layout: 'border',
  config: {
    cfgData: null,
    readOnly: false,
    hideReadOnlyCol: false,
    totalRowColors: ['ebg0ebc', 'ebc0ebf', 'ebf0ej ']
  },
  cls: 'btlmain',
  lockedGridConfig: {
    width: 400,
    split: true,
    collapsible: false,
    listeners: {
      resize: 'onResizeLock'
    }
  },
  selModel: {
    type: 'spreadsheet',
    columnSelect: false,
    pruneRemoved: false,
    extensible: 'both',
    rowNumbererHeaderWidth: 1
  },
  plugins: [
    'gridexporter',
    {
      ptype: 'clipboard',
      system: 'raw',
      memory: true,
      putTextData: function (a, b) {
        return false
      }
    }
  ],

  viewConfig: {
    getRowClass: function (a) {
      return a.get('subdataType')
    }
  },
  features: [
    {
      ftype: 'grouping',
      groupHeaderTpl: '{name}',
      hideGroupedHeader: false,
      enableGroupingMenu: false
    },
    {
      ftype: 'rowbody',
      extraRowTpl: [
        '{%',
        'if(this.rowBody.bodyBefore) {',
        'values.view.renderColumnSizer(values, out);',
        '} else {',
        'this.nextTpl.applyOut(values, out, parent);',
        '}',
        'values.view.rowBodyFeature.setupRowData(values.record, values.recordIndex, values);',
        '%}',
        '<tpl if="rowBody">',
        '<tr class="' +
          Ext.baseCSSPrefix +
          'grid-rowbody-tr {rowBodyCls} {rowIdCls}" {ariaRowAttr}>',
        '<tpl if="addSpacerCell">',
        '<td class="{spacerCellCls}"></td>',
        '</tpl>',
        '<td class="' +
          Ext.baseCSSPrefix +
          'grid-td ' +
          Ext.baseCSSPrefix +
          'grid-cell-rowbody" colspan="{rowBodyColspan}" {ariaCellAttr}>',
        '<div class="' +
          Ext.baseCSSPrefix +
          'grid-rowbody {rowBodyDivCls}" {ariaCellInnerAttr}>{rowBody}</div>',
        '</td>',
        '</tr>',
        '</tpl>',
        '{%',
        'if(this.rowBody.bodyBefore) {',
        'this.nextTpl.applyOut(values, out, parent);',
        '}',
        '%}',
        {
          priority: 100,
          beginRowSync: function (a) {
            a.add('rowBody', this.owner.eventSelector)
          },
          syncContent: function (a, b, c) {
            var d = this.rowBody,
              e,
              f
            if (d.doSync) {
              e = Ext.fly(a).down(d.eventSelector, true)
              if (e && (f = Ext.fly(b).down(d.eventSelector, true))) Ext.fly(e).syncContent(f)
            }
          }
        }
      ],
      getAdditionalData: function (a, b, c, d) {
        var e = c.data.SubTitle
        var f = this.view.headerCt,
          g = f.getColumnCount()
        if (Ext.isEmpty(e))
          return {
            rowBody: '',
            rowBodyCls: 'yokhukmunde'
          }
        return {
          rowBody: '<div  class="subtitle">' + e + '</div>',
          rowBodyCls: 'my-body-class',
          addRow: true,
          rowBodyColspan: g
        }
      },
      bodyBefore: true
    }
  ],
  initComponent: function () {
    this.columns = this.buildColumns()
    this.callParent()
  },
  applyCfgData: function (a) {
    var b = this
    if (!a) return a
    var c = a && a.data ? a.data : false,
      d = a && a.FiscealYears ? a.FiscealYears : false
    MDM.Btl.Column.setColumnTitles(d)
    var e = b.buildColumns()
    b.store.loadData(c)
    var f = 0,
      g = 0,
      h = 0,
      i = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0
    b.store.each(function (a) {
      if (a.get('SumType')) {
        f += a.get('t1') * a.get('SumType')
        g += a.get('t2') * a.get('SumType')
        h += a.get('t3') * a.get('SumType')
        if ('FG' == a.get('MaterialType')) {
          i += a.get('t1') * a.get('SumType')
          j += a.get('t2') * a.get('SumType')
          k += a.get('t3') * a.get('SumType')
        }
        if ('FWIP' == a.get('MaterialType')) {
          l += a.get('t1') * a.get('SumType')
          m += a.get('t2') * a.get('SumType')
          n += a.get('t3') * a.get('SumType')
        }
      }
    })
    var newRec = Ext.create('MDM.Btl.model.MainModel', {
      Title: 'P=D',
      MaterialType: 'Summary',
      subdataType: 'SumPd',
      st1: f,
      st2: g,
      st3: h,
      isLast: true
    })
    b.store.add(newRec)
    newRec = Ext.create('MDM.Btl.model.MainModel', {
      Title: 'P=D',
      MaterialType: 'FG',
      subdataType: 'SumPd',
      st1: i,
      st2: j,
      st3: k,
      isLast: true
    })
    b.store.add(newRec)
    newRec = Ext.create('MDM.Btl.model.MainModel', {
      Title: 'P=D',
      MaterialType: 'FWIP',
      subdataType: 'SumPd',
      st1: l,
      st2: m,
      st3: n,
      isLast: true
    })
    b.store.add(newRec)
    b.reconfigure(b.store, e)
  },
  buildColumns: function () {
    var a = this,
      b = a.getHideReadOnlyCol()
    if (this.getReadOnly())
      return MDM.Btl.Column.build(
        [
          {
            text: 'Dimensions',
            dataIndex: 'Title',
            locked: true,
            resizable: false,
            flex: 1,
            renderer: function (a, b, c) {
              b.tdCls = c.get('subdataType')
              return a
            },
            summaryRenderer: function (a, b, c) {
              return 'Total: '
            }
          },
          {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: false,
            width: 1,
            align: 'center',
            items: [
              {
                iconCls: 'fa fa-pencil ',
                isActionDisabled: function (a, b, c, d, e) {
                  return !e.get('IsEditable')
                },
                getClassp: function (a, b, c) {
                  if (c.get('IsEditable')) return 'fa fa-pencil'
                  return ''
                },
                handler: 'addToEditPanel'
              }
            ]
          }
        ],
        b
      )
    else
      return MDM.Btl.Column.build(
        [
          {
            text: 'Dimensions',
            dataIndex: 'Title',
            locked: true,
            resizable: false,
            flex: 1,
            renderer: function (a, b, c) {
              b.tdCls = c.get('subdataType')
              return a
            },
            summaryRenderer: function (a, b, c) {
              return 'Total: '
            }
          },
          {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            locked: true,
            resizable: false,
            width: 50,
            align: 'center',
            items: [
              {
                iconCls: 'fa fa-pencil ',
                isActionDisabled: function (a, b, c, d, e) {
                  return !e.get('IsEditable')
                },
                getClass: function (a, b, c) {
                  if (c.get('IsEditable')) return 'fa fa-pencil'
                  return ''
                },
                handler: 'addToEditPanel'
              }
            ]
          },
          {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            locked: false,
            resizable: false,
            width: 1,
            align: 'center',
            items: [
              {
                iconCls: 'fa fa-pencil ',
                isActionDisabled: function (a, b, c, d, e) {
                  return !e.get('IsEditable')
                },
                getClassp: function (a, b, c) {
                  if (c.get('IsEditable')) return 'fa fa-pencil'
                  return ''
                },
                handler: 'addToEditPanel'
              }
            ]
          }
        ],
        b
      )
  },
  updateTotalRowColors(c) {
    MDM.Btl.Column.setTotalRowColors(c)
  },
  updateHideReadOnlyCol: function (a) {
    var b = this
    if (b.isConfiguring) return a
    var c = this.buildColumns()
    b.reconfigure(b.store, c)
    return a
  }
})
