Ext.define('po.Global', {
  singleton: true,
  config: {
    apiUrl: '###PO_API_URL###',
    pluginApiUrl: '###PO_PLUGIN_URL###',
    startToken: 'Home',
    userInfo: null,
    customerInfo: null,
    catalogInfo: null,
    catalogId: null,
    catologConfig: {},
    isStateful: false,
    NetToken: null,
    isDevelopment: false
  },
  constructor: function (config) {
    var me = this
    me.initConfig(config)
  },
  getCatalogId: function () {
    var me = this,
      catalogInfo = me.getCatalogInfo(),
      catalogId = catalogInfo != null && catalogInfo.catalogId ? catalogInfo.catalogId : null
    return catalogId
  },
  getMonthName: function (m) {
    var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    m = Math.abs(m)
    return month[m % 12]
  },

  mergeColumns: function (columns, defaultColumns) {
    for (let index = 0; index < columns.length; index++) {
      var column = columns[index]

      if (column.columnId) {
        columns[index] = this.mergeColumn(column, defaultColumns)
      } else if (column.columns) {
        for (let innerindex = 0; innerindex < column.columns.length; innerindex++) {
          var innercolumn = column.columns[innerindex]
          column.columns[innerindex] = this.mergeColumn(innercolumn, defaultColumns)
        }
      }
    }

    return columns
  },

  mergeColumn: function (column, defaultColumns) {
    if (column) {
      const defaultColumn = this.getColumnByColumnId(column.columnId, defaultColumns)

      if (defaultColumn) {
        defaultColumn.width = column.hasOwnProperty('width') ? column.width : defaultColumn.width
        defaultColumn.hidden = column.hasOwnProperty('hidden')
          ? column.hidden
          : defaultColumn.hidden
        defaultColumn.text = column.hasOwnProperty('text') ? column.text : defaultColumn.text
        defaultColumn.tooltip = column.hasOwnProperty('tooltip')
          ? column.tooltip
          : defaultColumn.tooltip
        defaultColumn.locked = column.hasOwnProperty('locked')
          ? column.locked
          : defaultColumn.locked
        defaultColumn.menuDisabled = column.hasOwnProperty('menuDisabled')
          ? column.menuDisabled
          : defaultColumn.menuDisabled
        defaultColumn.sortable = column.hasOwnProperty('sortable')
          ? column.sortable
          : defaultColumn.sortable
        defaultColumn.align = column.hasOwnProperty('align') ? column.align : defaultColumn.align
        defaultColumn.bind = column.hasOwnProperty('bind') ? column.bind : defaultColumn.bind
      }

      return defaultColumn
    }
  },

  getColumnByColumnId: function (columnId, defaultColumns) {
    if (!defaultColumns) return null

    for (let index = 0; index < defaultColumns.length; index++) {
      var defaultColumn = defaultColumns[index]

      if (defaultColumn.columnId && defaultColumn.columnId == columnId) {
        return Object.assign({}, defaultColumn)
      } else if (defaultColumn.columns) {
        var result = this.getColumnByColumnId(columnId, defaultColumn.columns)
        if (result) return Object.assign({}, result)
      }
    }

    return null
  }
})
