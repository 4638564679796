import { formatToExcelConvention } from '../export'
import { EXCEL_FORMAT_CONVERSION, DEFAULT_EXCEL_FORMAT } from '../../../constants'
import { OneOfExcelFormatKeyType } from '../export.types'

describe('AgGrid:Helpers:Export:formatToExcelConvention', () => {
  it('should trims spaces, prefixes, and special characters correctly then return corresponding format in EXCEL_FORMAT_CONVERSION', () => {
    Object.keys(EXCEL_FORMAT_CONVERSION).forEach((format) => {
      const inputFormat = ` Number ${format} `
      const result = formatToExcelConvention(inputFormat)
      const formatValue = EXCEL_FORMAT_CONVERSION[format as OneOfExcelFormatKeyType]
      expect(result).toBe(formatValue)
    })
  })

  it('should trims spaces, prefixes, and special characters correctly', () => {
    const inputFormat = `Number $ € ₺`
    const result = formatToExcelConvention(inputFormat)
    const splitInputFormat = inputFormat.split(' ').filter((str) => str)
    splitInputFormat.forEach((str) => {
      expect(result).not.toContain(str)
    })
  })

  it('should handles unknown formats with default value', () => {
    const inputFormat = 'UnknownFormat'

    const result = formatToExcelConvention(inputFormat)

    expect(result).toBe(DEFAULT_EXCEL_FORMAT)
  })

  it('should appends percentage sign if present in the input format', () => {
    const inputFormatWithPercentage = '0.00%'

    const result = formatToExcelConvention(inputFormatWithPercentage)

    expect(result).toBe('0.00%')
  })
})
