import {
  CellClassFunc,
  CellClassParams,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  INumberCellEditorParams,
  ValueGetterParams
} from 'ag-grid-community'
import { findLast, size, has } from 'lodash'
import { BooleanIconCellEditor, LookupFieldCellEditor } from '../../components/CellEditors'
import CellRenderer, {
  ActionButtonCellRenderer,
  BooleanIconCellRenderer,
  ColorCellRenderer,
  DateCellRenderer,
  HighchartCellRenderer,
  HtmlCellRenderer,
  IconCellRenderer,
  SummaryCellRenderer,
  TemplateCellRenderer
} from '../../components/CellRenderers/index'
import {
  cellEditedClasses,
  defaultBooleanCellColor,
  defaultBooleanCellIcon,
  numberTypes
} from '../../constants'
import { AG_GRID_ENUMS } from '../../enums'
import {
  CellColorsFromData,
  EmptyObject,
  GetCellClassRules,
  IRowData,
  SlvyColDef,
  StringOrNumber,
  TcellDisplayRules,
  TcustomConfigs,
  TrowDataValue
} from '../../types'
import {
  getColumnEditable,
  getEnableInfo,
  getIsProgressAndNotNumber,
  getSparklineOptions,
  getValidRowRule,
  validateRule
} from '../index'
import {
  CheckDisabledConditionProps,
  GetCalculatedNumberCellEditorParamsProps,
  GetCellColorsFromDataFnProps,
  GetCellEditableProps,
  GetCellEditableReturnType,
  GetCellEditorSelectorProps,
  GetCellEditorSelectorReturnType,
  GetCellIconProps,
  GetCellRendererSelectorProps,
  GetCellRendererSelectorReturnType,
  GetCellStyleProps,
  GetCellStyleReturnType,
  GetControlValueProps,
  GetMergedStyleProps,
  GetMergedStyleReturnType,
  GetNumberCellEditorParamsProps,
  GetNumberCellEditorParamsReturnType,
  GetValidCellRuleProps,
  GetValidRowRuleForCellProps,
  GetValueGetterProps,
  GetValueGetterReturnType,
  IsActionButtonDisabledProps
} from './cell.types'

const { CUSTOM_ROW_INFO, DIRTY_CELLS, ADDED_ROWS, ROW_ID, AUTO_GROUP_COLUMN } = AG_GRID_ENUMS

const getValidCellRule = ({
  params,
  cellDisplayRules
}: GetValidCellRuleProps): TcellDisplayRules[number] | null => {
  const currentCellDisplayRules = cellDisplayRules.filter(
    ({ ruleFieldName }: TcellDisplayRules[number]) => {
      return ruleFieldName === (params?.colDef as SlvyColDef)?.field
    }
  )

  const validCellRule = findLast(currentCellDisplayRules, (cellRule: TcellDisplayRules[number]) => {
    const { referenceFieldName, value, operator } = cellRule
    // TODO: params.data, params.value and value might be undefined for tooltip, check why it happens?
    // Header columns tooltips breaks the feature
    const doesReferenceFieldExist = referenceFieldName && typeof params.data !== 'undefined'
    const referenceCellValue = doesReferenceFieldExist ? params.data[referenceFieldName] : null

    return validateRule({
      conditionValue: value,
      value: doesReferenceFieldExist ? referenceCellValue : params.value,
      operator
    })
  })

  return validCellRule ?? null
}

function getCellIcon({
  params,
  cellDisplayRules,
  customConfigs
}: GetCellIconProps): TcustomConfigs['icon'] {
  // cell rule
  const validCellRule = getValidCellRule({ params, cellDisplayRules })
  if (validCellRule) {
    // TODO: @v2 Refactor
    return validCellRule ?? customConfigs.icon
  }

  // column
  return customConfigs.icon
}

function getCellEditorSelector({
  booleanIcons,
  isLookupQuery,
  lookupQueryList,
  lookupDataField,
  dataType,
  selectProps = { menuIsOpen: true }
}: GetCellEditorSelectorProps): GetCellEditorSelectorReturnType {
  const { trueIcon, falseIcon } = booleanIcons
  if (trueIcon && falseIcon) {
    return {
      cellEditorSelector: () => {
        return {
          component: BooleanIconCellEditor,
          params: {
            editorName: 'booleanIcon',
            shouldAutoStopEditing: true
          }
        }
      }
    }
  }
  if (lookupDataField || isLookupQuery) {
    return {
      cellEditorPopupPosition: 'under',
      cellEditorSelector: () => {
        return {
          component: LookupFieldCellEditor,
          popup: true,
          params: {
            isMassUpdateModalVisible: false,
            editorName: 'lookup',
            lookupQueryList,
            lookupDataField,
            selectProps,
            shouldAutoStopEditing: true
          }
        }
      }
    }
  }

  // TODO:
  // Issue: Null date, pagination and sorting problem
  // if there is a null date data in a grid with pagination, when grid is sorted data cells stops rendering
  // but still can be edited. After doing sorting few times user can edit date cells like text cells
  // Branch: fix/DateEditorAfterSort
  // https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/2/menu/65c353047837bb86ff9e74f6/page/65dc61689d213a5dc005d3d1
  // After review: Normally we do not pass cellEditorSelector unless it is boolean or look up cell.
  // When we pass cellEditorSelector like below, it fixes the issue.
  // Normally ag-grid can understand cell editor type based on the data we provide.
  // For example if cell data is 5 it opens number editor
  // I couldn't create the same problem on plunkr, when we have time check this out.
  // Plunkr: https://plnkr.co/edit/DvBIzUzyBXLdV9Cy?open=index.tsx
  // if (dataType === 'datetime') {
  //   return {
  //     cellEditorSelector: () => {
  //       return {
  //         component: 'agDateCellEditor'
  //       }
  //     }
  //   }
  // }

  return {}
}

const getCellRendererSelector = ({
  customConfigs,
  fieldConfig,
  cellDisplayRules,
  handleActionBtnClick,
  fieldLookupConfig
}: GetCellRendererSelectorProps): GetCellRendererSelectorReturnType => {
  return {
    cellRendererSelector: (params: ICellRendererParams<IRowData>) => {
      const {
        editing: { lookupDataField },
        columnType,
        summaryTemplate,
        template,
        actionButton,
        boolean,
        highchartOptions,
        sparklineOptions,
        progressOptions
      } = customConfigs
      const {
        api,
        column: { colId } = {},
        colDef: { customInfo = {} } = {},
        context: { groupDisplayType }
      } = params

      const icon = getCellIcon({ params, cellDisplayRules, customConfigs })
      const isFooter = Boolean(params.node.footer)
      const isGroup = Boolean(params.node.group)

      const isGroupTotalRowActive = api?.getGridOption?.('groupTotalRow')
      const hideGroupSummaryParams = isGroupTotalRowActive
        ? {}
        : { value: null, valueFormatted: null }

      const isBool = fieldConfig.dataType === 'bool'
      // TODO: Check if condition is correct
      const isLookup = Boolean(fieldLookupConfig?.substituteField || lookupDataField)

      // Added for group summary,
      // Check all the column and data type and figure out how to aggregate for example color column with sum
      if (isFooter) {
        return {
          component: SummaryCellRenderer,
          // if SummaryCellRenderer gets complicated with lots of different views, separate into new components
          params: { template: summaryTemplate }
        }
      }

      // Use different cellRenderer in certain scenarios
      if (template && !isLookup && !isBool) {
        return {
          component: TemplateCellRenderer,
          // Pass "summaryTemplate" for displaying summaryTemplate in group row for singleColumn
          params: { template, summaryTemplate }
        }
      }

      if (actionButton.actionEnabled && !isBool) {
        const actionButtonParams = actionButton
        return {
          component: ActionButtonCellRenderer,
          params: { actionButtonParams, icon, handleActionBtnClick, columnType }
        }
      }

      if (fieldConfig.dataType === 'bool') {
        const { trueIcon, falseIcon } = boolean
        if (trueIcon && falseIcon) {
          return { component: BooleanIconCellRenderer }
        }
        // for the singleColumn type but not autoColumn
        if (isGroup) {
          return {
            component: SummaryCellRenderer,
            params: hideGroupSummaryParams
          }
        }
        return { component: 'agCheckboxCellRenderer' }
      }

      if (fieldConfig.dataType === 'datetime') {
        return { component: DateCellRenderer }
      }

      if (columnType === 'color') {
        return { component: ColorCellRenderer }
      }

      if (columnType === 'icon') {
        return { component: IconCellRenderer }
      }

      if (columnType === 'html') {
        return { component: HtmlCellRenderer }
      }

      if (columnType === 'highchart') {
        // for the singleColumn type but not autoColumn
        if (isGroup) {
          return {
            component: SummaryCellRenderer,
            params: hideGroupSummaryParams
          }
        }
        return {
          component: HighchartCellRenderer,
          params: { highchartOptions }
        }
      }

      if (columnType === 'progress' || columnType === 'sparkline') {
        // for the singleColumn type but not autoColumn
        if (isGroup) {
          return {
            component: SummaryCellRenderer,
            params: hideGroupSummaryParams
          }
        }

        const cellStyle: SlvyColDef['customInfo']['cellStyle'] = customInfo?.cellStyle ?? {}

        return {
          component: 'agSparklineCellRenderer',
          params: {
            sparklineOptions: getSparklineOptions({
              columnType,
              sparklineOptions,
              progressOptions,
              cellStyle
            })
          }
        }
      }

      const isAutoColumn = colId === AUTO_GROUP_COLUMN

      // for the singleColumn type but not autoColumn
      if (isGroup && summaryTemplate && !isAutoColumn && groupDisplayType === 'singleColumn') {
        return {
          component: SummaryCellRenderer,
          params: { template: summaryTemplate, ...hideGroupSummaryParams }
        }
      }

      return { component: CellRenderer, params: { icon, isDefaultCellRenderer: true } }
    }
  }
}

const getValueGetter = ({
  columnType,
  fieldConfig
}: GetValueGetterProps): GetValueGetterReturnType => {
  const isSparkline = columnType === 'sparkline'
  const isProgress = columnType === 'progress'

  if (isSparkline || isProgress) {
    return {
      valueGetter: (params: ValueGetterParams<IRowData, TrowDataValue>) => {
        const fieldValue = params.data?.[fieldConfig?.fieldName]
        const isValueString = typeof fieldValue === 'string'
        const isValueNumber = typeof fieldValue === 'number'
        const isValueDefined = typeof fieldValue !== 'undefined'
        const isValueNull = fieldValue === null
        if (!isValueDefined || isValueNull) {
          return []
        }

        if (!isValueString && !isValueNumber) {
          return []
        }

        let newValue: StringOrNumber = fieldValue as StringOrNumber

        if (isProgress) {
          newValue = isValueString ? parseFloat(newValue as string) : newValue
          if (!Number.isNaN(newValue)) {
            return [(newValue as number) * 100]
          }
        }

        if (isSparkline) {
          if (isValueString) {
            return (newValue as string).split(',').map(Number)
          }
          return [newValue as number]
        }
        return []
      }
    }
  }

  return {}
}

const getCellEditable = ({
  customConfigs,
  gridEditableByDefault,
  fieldConfig
}: GetCellEditableProps): GetCellEditableReturnType => {
  return {
    editable: (params: EditableCallbackParams<IRowData, TrowDataValue>) => {
      const { data } = params

      if (!data) {
        return false
      }

      const { fieldName } = fieldConfig

      const {
        [fieldName]: value,
        [CUSTOM_ROW_INFO]: { [ADDED_ROWS]: addedRows, [ROW_ID]: rowId }
      } = data

      const {
        editing: { editableCondition },
        adding: { enabled: addingEnabled },
        columnType,
        isRawFieldEmpty
      } = customConfigs

      if (isRawFieldEmpty) {
        return false
      }

      const columnEditable = getColumnEditable(customConfigs, gridEditableByDefault)

      if (getIsProgressAndNotNumber({ columnType, value })) {
        return false
      }

      const isNewlyAddedRow = addedRows.find((addedRow) => rowId === addedRow)

      let isCellEditable = columnEditable && getEnableInfo(data, editableCondition)

      if (isNewlyAddedRow) {
        isCellEditable = addingEnabled
      }

      return isCellEditable
    }
  }
}

function getMergedStyle({
  rule,
  textAlign,
  cursor
}: GetMergedStyleProps): GetMergedStyleReturnType {
  const {
    backColor: backgroundColor = '',
    boldText,
    italicText,
    textColor: color,
    textDecoration
  } = rule
  const style: Partial<GetMergedStyleReturnType> = {}
  if (italicText) {
    style.fontStyle = 'italic'
  }
  if (boldText) {
    style.fontWeight = 'bold'
  }
  if (textAlign) {
    style.textAlign = textAlign
  }
  if (color) {
    style.color = color
  }
  if (backgroundColor) {
    style.backgroundColor = backgroundColor
  }
  if (textDecoration) {
    style.textDecoration = textDecoration
  }
  if (cursor) {
    style.cursor = cursor
  }
  return {
    ...style
  }
}

const getValidRowRuleForCell = ({ params, rowDisplayRules }: GetValidRowRuleForCellProps) => {
  const {
    node: { data }
  } = params

  if (!rowDisplayRules.length) {
    return null
  }

  // Note: Group check made in where this function gets called
  return getValidRowRule({ params: { data }, rowDisplayRules })
}

const getCellColorsFromDataFn = ({
  params,
  getCellColorsFromData
}: GetCellColorsFromDataFnProps): null | CellColorsFromData => {
  const {
    data,
    colDef: { field }
  } = params

  // Note: Group check made in where this function gets called

  if (!getCellColorsFromData || !data) {
    return null
  }

  const colors: Partial<CellColorsFromData> = {}
  const backColorFromData = data[`${field}_backColor`]
  const textColorFromData = data[`${field}_textColor`]

  const isBackColorValidString =
    typeof backColorFromData === 'string' && backColorFromData.trim().length > 0

  const isTextColorValidString =
    typeof textColorFromData === 'string' && textColorFromData.trim().length > 0

  if (isBackColorValidString || isTextColorValidString) {
    if (isBackColorValidString) {
      colors.backColor = backColorFromData
    }
    if (isTextColorValidString) {
      colors.textColor = textColorFromData
    }

    return colors
  }

  return null
}

function getCellStyle({
  customConfigs,
  cellDisplayRules,
  rowDisplayRules,
  getCellColorsFromData,
  enableCellTextSelection
}: GetCellStyleProps): GetCellStyleReturnType {
  return {
    cellStyle: (params: CellClassParams<IRowData, TrowDataValue>) => {
      const isFooter = params.node.footer
      const isGroup = params.node.group
      const { textAlign } = customConfigs.cellStyle
      const footerStyle = { textAlign }

      const cursor = enableCellTextSelection ? 'text' : ''

      if (isGroup) {
        return footerStyle
      }

      // if (isFooter && !cellDisplayRules.length) {
      if (isFooter) {
        return footerStyle
      }

      // cell rule
      const validCellRule = getValidCellRule({ params, cellDisplayRules })
      if (validCellRule) {
        if (isFooter && !validCellRule.applyRuleSummary) {
          return footerStyle
        }

        return getMergedStyle({ rule: validCellRule, textAlign, cursor })
      }
      if (isFooter && cellDisplayRules.length) {
        return footerStyle
      }

      // row rule
      const validRowRule = getValidRowRuleForCell({ params, rowDisplayRules })
      if (validRowRule) {
        return getMergedStyle({ rule: validRowRule, textAlign, cursor })
      }

      // getCellColorsFromData
      const validStyles = getCellColorsFromDataFn({ params, getCellColorsFromData })
      if (validStyles) {
        return getMergedStyle({ rule: validStyles, textAlign, cursor })
      }

      const cursorProp: Partial<{ cursor: 'text' }> = {}
      if (cursor) {
        cursorProp.cursor = cursor
      }

      // column style
      return { ...customConfigs.cellStyle, ...cursorProp }
    }
  }
}

function getCellClass(
  exportable: boolean
): EmptyObject | { cellClass: CellClassFunc<IRowData, TrowDataValue> } {
  if (!exportable) {
    return {}
  }
  return {
    cellClass: (params: CellClassParams<IRowData, TrowDataValue>) => {
      const $colDef = params?.colDef as SlvyColDef
      const dataType = $colDef?.customInfo?.fieldConfig?.dataType
      if (numberTypes.some((numberType) => numberType === dataType)) {
        return $colDef?.customInfo?.columnId
      }
      if (dataType === 'string' && typeof params?.value === 'string') {
        return 'stringType'
      }
      return ''
    }
  }
}

function getCellClassRules(): GetCellClassRules {
  return {
    cellClassRules: {
      [cellEditedClasses]: (params: CellClassParams<IRowData, TrowDataValue>) => {
        const $colDef = params.colDef as SlvyColDef
        const {
          node: { group, id },
          data
        } = params

        if (group || !data) {
          return false
        }

        const {
          [CUSTOM_ROW_INFO]: { [DIRTY_CELLS]: dirtyCells }
        } = data

        return dirtyCells.some((cell) => cell.field === $colDef.field && cell.rowId === id)
      }
    }
  }
}

function getNoOfDecimalPlaces(format: string) {
  const subStrings = format.split('.')
  return size(subStrings) >= 2 ? size(subStrings[1].match(new RegExp('0', 'g')) || []) : 0
}

function getControlValue({ value, valueField, cellData }: GetControlValueProps) {
  // Number() converts null to 0, so adding {} as fallback makes it returns NaN
  // SenchaGrid does not accept if cellData[valueField] is 0 (falsy) [backward-compatability]
  const isValueField = valueField && valueField in cellData && cellData[valueField]
  const result = isValueField ? cellData[valueField] : value
  // Check whether the result is empty string because the Number converts empty string to 0!
  const isEmptyString = result === ''
  if (!isEmptyString && (typeof result === 'string' || typeof result === 'number')) {
    return Number(result)
  }
  return NaN
}

function getCalculatedNumberCellEditorParams({
  editing,
  formatString,
  cellData
}: GetCalculatedNumberCellEditorParamsProps) {
  const { minValue, maxValue, step, stepField, minValueField, maxValueField } = editing

  const editorParams: Partial<INumberCellEditorParams<IRowData, TrowDataValue>> = {}

  let precision
  if (formatString) {
    // TODO: Display the raw value in cell editor
    // editorParams.precision = getNoOfDecimalPlaces(formatString)
    precision = getNoOfDecimalPlaces(formatString)
  }

  const calcMinValue = getControlValue({
    value: minValue,
    valueField: minValueField,
    cellData
  })
  const calcMaxValue = getControlValue({
    value: maxValue,
    valueField: maxValueField,
    cellData
  })
  const calcStepValue = getControlValue({
    value: step,
    valueField: stepField,
    cellData
  })

  // eslint-disable-next-line no-restricted-globals
  const isMinValueSet = !isNaN(calcMinValue)
  // eslint-disable-next-line no-restricted-globals
  const isMaxValueSet = !isNaN(calcMaxValue)
  // eslint-disable-next-line no-restricted-globals
  const isStepSet = !isNaN(calcStepValue)

  if (isMinValueSet && isMaxValueSet) {
    // Prevent passing bigger minValue than maxValue
    const isValidValues = calcMinValue <= calcMaxValue
    if (isValidValues) {
      editorParams.min = calcMinValue
      editorParams.max = calcMaxValue
    }
  } else {
    if (isMinValueSet) {
      editorParams.min = calcMinValue
    }
    if (isMaxValueSet) {
      editorParams.max = calcMaxValue
    }
  }

  if (isStepSet) {
    // TODO: Backward Compatability
    // user cannot enter values like 0.0666666666666667 from cellData[valueField] for step, min, max
    // so fixing it with precision is what SenchaGrid does
    // For example: 0.0666666666666667 to 0.067 if precision is 3
    editorParams.step = parseFloat(calcStepValue.toFixed(precision || 0))
  }

  return editorParams
}

function getNumberCellEditorParams({
  dataType,
  editing,
  formatString
}: GetNumberCellEditorParamsProps): GetNumberCellEditorParamsReturnType | EmptyObject {
  const isNumber = numberTypes.some((numberType) => numberType === dataType)

  if (!isNumber) {
    return {}
  }

  return {
    cellEditorParams: ({ data }: ICellEditorParams<IRowData, TrowDataValue>) =>
      getCalculatedNumberCellEditorParams({ editing, formatString, cellData: data })
  }
}

const getCalculatedBooleanCellProps = (
  value: TrowDataValue | undefined,
  boolean: TcustomConfigs['boolean']
) => {
  const { trueIcon, falseIcon, trueColor, falseColor } = boolean
  let icon: string = defaultBooleanCellIcon
  let color: string = defaultBooleanCellColor

  if (value === true || value === 'true') {
    icon = trueIcon
    color = trueColor
  } else if (value === false || value === 'false') {
    icon = falseIcon
    color = falseColor
  }

  return { icon, color }
}

const checkActionButtonDisabled = (props: CheckDisabledConditionProps) => {
  const { record, disabledCondition } = props
  let isActionDisabled = false
  if (disabledCondition) {
    if (has(record, disabledCondition) && !record?.[disabledCondition]) {
      isActionDisabled = true
    }
  }
  return isActionDisabled
}

const isActionButtonDisabled = (props: IsActionButtonDisabledProps) => {
  const { record, enabledCondition, clickableOnDisabled } = props
  if (clickableOnDisabled) {
    return false
  }
  return checkActionButtonDisabled({ record, disabledCondition: enabledCondition })
}

export {
  checkActionButtonDisabled,
  isActionButtonDisabled,
  getCalculatedBooleanCellProps,
  getCellColorsFromDataFn,
  getCellEditorSelector,
  getCellClass,
  getNoOfDecimalPlaces,
  getCalculatedNumberCellEditorParams,
  getNumberCellEditorParams,
  getValidRowRuleForCell,
  getCellRendererSelector,
  getCellStyle,
  getCellClassRules,
  getCellEditable,
  getMergedStyle,
  getValidCellRule,
  getCellIcon,
  getControlValue,
  getValueGetter
}
