export default `({
    model: Ext.define('net.gazsi.laszlo.extjs.GenericModel' + Ext.id(), {
        extend: 'Ext.data.Model',
        idProperty: 'SimilarityID',
        fields: [{
                name: 'SpotID',
                type: 'int'
            },
            {
                name: 'SimilarityID',
                type: 'int'
            },
            {
                name: 'ReferenceSpotID',
                type: 'int'
            },
            {
                name: 'MaterialCode',
                type: 'string'
            },
            {
                name: 'MaterialDescription',
                type: 'string'
            },
            {
                name: 'StartDate',
                type: 'date',
                //dateFormat: tt('Y-m-d H:i:s.u'),
                dateWriteFormat: tt('Y-m-d H:i:s.u')
            },
            {
                name: 'EndDate',
                type: 'date',
                //dateFormat: tt('Y-m-d H:i:s.u'),
                dateWriteFormat: tt('Y-m-d H:i:s.u')
            },
            {
                name: 'IsActive',
                type: 'int'
            },
            {
                name: 'IgnoreReason',
                type: 'string'
            },
            {
                name: 'IsOkey',
                type: 'boolean'
            },
            {
                name: 'DurationInWeek',
                type: 'int'
            },
            {
                name: 'DurationInDays',
                type: 'int'
            },
            {
                name: 'StartDateKey',
                type: 'string'
            },
            {
                name: 'EndDateKey',
                type: 'string'
            },
            {
                name: 'BackDateKey',
                type: 'string'
            },
            {
                name: 'TotalInvoicedQuantity',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrder',
                type: 'number'
            },
            {
                name: 'NumberOfDistinctDC',
                type: 'int'
            },
            {
                name: 'NumberOfDistinctStore',
                type: 'int'
            },
            {
                name: 'Price',
                type: 'number'
            },
            {
                name: 'TotalInvoicedQuantityDay1',
                type: 'number'
            },
            {
                name: 'TotalInvoicedQuantityDay2',
                type: 'number'
            },
            {
                name: 'TotalInvoicedQuantityDay3',
                type: 'number'
            },
            {
                name: 'TotalInvoicedQuantityDay4',
                type: 'number'
            },
            {
                name: 'TotalInvoicedQuantityDay5',
                type: 'number'
            },
            {
                name: 'TotalInvoicedQuantityDay6',
                type: 'number'
            },
            {
                name: 'TotalInvoicedQuantityDay7',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrderDay1',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrderDay2',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrderDay3',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrderDay4',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrderDay5',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrderDay6',
                type: 'number'
            },
            {
                name: 'TotalNetValueoftheSalesOrderDay7',
                type: 'number'
            },
            {
                name: 'Pareto80Day',
                type: 'number'
            },
            {
                name: 'ParetoNetValueoftheSalesOrder',
                type: 'number'
            },
            {
                name: 'ParetoTotalInvoicedQuantity',
                type: 'number'
            },
            {
                name: 'ParetoTotalInvoicedQuantityPercent',
                type: 'number'
            },
            {
                name: 'IsSimilar',
                type: 'boolean'
            },
            {
                name: 'IsSimilarTemp',
                type: 'int',
                convert: function (v) {
                    if (typeof v === 'boolean') {
                        return v ? 1 : 0;
                    } else {
                        return parseInt(v, 10);
                    }
                }
            },
            {
                name: 'MinQuantity',
                type: 'number'
            },
            {
                name: 'MaxQuantity',
                type: 'number'
            },
            {
                name: 'UnitsPerPackage',
                type: 'number'
            },
            {
                name: 'IsSame',
                type: 'int',
            },
            {
                name: 'CalMaterialDescription',
                type: 'string',
                calculate: function (data) {
                    if (data.IsSimilarTemp) {
                        return ' Secilmis ' + data.MaterialDescription
                    }
                    return data.MaterialDescription
                }
            }
        ],
    }),
    groupField: 'CalMaterialDescription'
})
`
