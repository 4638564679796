import React, { Component } from 'react'
import _ from 'lodash'
import { CoverBox } from '../../../components'
import {
  getMessage,
  onlyNumber,
  round,
  val,
  getAttachments,
  editReason,
  findUpdateValue
} from '../../../utils'

export default class ScheduleUpdateForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
      originalValue: 0,
      attachments: [],
      isButtonDisable: true,
      currentReason: {},
      isUserAllowedToChangeProductionQuantity: true,
      isSubmitted: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const {
      target: { value = '' }
    } = event
    const { value: currentValue = '' } = this.state

    this.setState({
      value: onlyNumber(value),
      isButtonDisable: currentValue === value
    })
  }

  componentDidMount() {
    const {
      data: {
        ProductPeriod: { Values = {} }
      },
      settings: {
        stateHandler: {
          selectedParameter: { Index = 0 }
        }
      },
      isAllowed = () => {}
    } = this.props

    const value = round(val(Values, 'Production', Index))
    const isUserAllowedToChangeProductionQuantity = isAllowed('Resource Schedule/Product Quantity')

    this.setState({
      value,
      originalValue: value,
      isUserAllowedToChangeProductionQuantity
    })
  }

  updateHandler(params, e) {
    const {
      keyCode,
      // target,
      target: { value }
    } = e
    const {
      ProductId,
      PeriodId,
      ResourceId,
      isForce = false,
      itemValue = value,
      clickUpdate = false
    } = params
    let {
      connectApi,
      lastRequest: {
        GetRccpResourceActivityResponseWPF: { Request: ActivityRequest = {} } = {},
        GetRccpProductPeriodResponse: { Request = {} } = {},
        GetRccpResourcePeriodResponse: { Request: ResourceRequest = {} } = {}
      },
      getData,
      settings: {
        productionVariants: {
          defaultProductionVariant = 'Production',
          userOptions: { isEnabled: isUserOptionsEnabled = false } = {}
        } = {},
        modificationCount = 0,
        stateHandler: {
          selectedParameter: { tableName = 'Production' },
          selectedProductVariant = ''
        },
        updateReasons = {}
      }
    } = this.props
    const { isSubmitted } = this.state

    selectedProductVariant = !selectedProductVariant
      ? defaultProductionVariant
      : selectedProductVariant
    const productionVariant = isUserOptionsEnabled
      ? selectedProductVariant
      : defaultProductionVariant
    const requestFieldName = findUpdateValue(tableName, productionVariant)

    if ((keyCode === 13 || clickUpdate) && !isSubmitted) {
      const set = {
        isopen: true,
        node: this.refs.editreasonstatic,
        isportal: true,
        reasondata: updateReasons
      }
      const update = () => {
        const {
          currentReason: { code: reasonCode, name: reasonName }
        } = this.state
        this.setState({ isSubmitted: true })

        connectApi('UpdateRccpResourcePeriodProduct', {
          fieldName: requestFieldName,
          newValue: itemValue,
          periodId: PeriodId,
          productId: ProductId,
          resourceId: ResourceId,
          rccpConfig: {
            ...(isForce ? { ForceUpdate: true } : {}),
            UpdateReasonCode: reasonCode,
            UpdateReasonDescription: reasonName,
            ModificationCount: modificationCount
          }
        }).then((res) => {
          const { Description = 'Error', Code = -1, Attachments = [] } = res

          if (!_.isEmpty(Attachments)) {
            this.setState({
              attachments: getAttachments(Attachments, itemValue),
              isSubmitted: false
            })
          }

          Request = {
            ...Request,
            rccpConfig: {
              ...Request.rccpConfig,
              ModificationCount: 10000
            }
          }
          ResourceRequest = {
            ...ResourceRequest,
            productId: ResourceRequest.productId || ActivityRequest.productId,
            rccpConfig: {
              ...ResourceRequest.rccpConfig,
              ModificationCount: 10000
            }
          }
          ActivityRequest = {
            ...ActivityRequest,
            rccpConfig: {
              ...ActivityRequest.rccpConfig,
              ModificationCount: 10000
            }
          }

          getMessage(Code, Description).then((ret) => {
            if (ret.isOk) {
              this.setState(
                {
                  attachments: []
                },
                () => {
                  document.body.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }))
                }
              )

              connectApi('GetRccpProductPeriodResponse', Request).then(() => {
                connectApi('GetRccpResourcePeriodResponse', ResourceRequest).then(() => {
                  connectApi('GetRccpResourceActivityResponseWPF', ActivityRequest).then((res) => {
                    getData(res)
                  })
                })
              })
            }
          })
        })
      }

      if (_.isEmpty(this.state.attachments)) {
        editReason(set, {
          onConfirm: (e, isok, reason) => {
            this.setState({ currentReason: reason }, () => update())
          }
        })
      } else if (clickUpdate) {
        update()
      }
    }
  }

  render() {
    let {
      settings: {
        coverSettings = {},
        stateHandler: {
          selectedParameter: { Index: SelectedParamIndex = 0, name: Abbreviation = '' }
        } = {},
        productInfoSettings = []
      },
      data: {
        ResourceId = 0,
        ProductPeriod,
        Period: { Id: PeriodId = 0 },
        ProductPeriod: { Values = {} },

        _OutputItems = []
      },
      coverViolationIndexes
    } = this.props
    const { Id: ProductId = 0, Values: ItemValues = {} } = _.first(_OutputItems) || {}
    const {
      value,
      attachments,
      isUserAllowedToChangeProductionQuantity = true
      // isButtonDisable,
      // originalValue
    } = this.state

    const productionInfoCoverSettings = _.find(productInfoSettings, ({ tableName }) =>
      _.includes(tableName, 'Cover')
    )
    const { format: formatType = '0,0' } = productionInfoCoverSettings || {}
    coverSettings = { ...coverSettings, formatType }

    return (
      <div className="-schedule-update-form">
        <div ref="editreasonstatic" />
        <div className="-dflex">
          <div className="unit">{Abbreviation}</div>
          <div className="inputField">
            <input
              disabled={!isUserAllowedToChangeProductionQuantity}
              type="text"
              value={value}
              onChange={this.handleChange}
              onKeyDown={this.updateHandler.bind(this, {
                isForce: false,
                itemValue: value,
                ProductId,
                PeriodId,
                ResourceId
              })}
            />
            {!_.isEmpty(attachments) && (
              <div className="attachment-content">
                <div className="attachment-list">
                  <ol>
                    {_.map(attachments, (aitem, aindex) => {
                      const isVisible = aitem === 0
                      const isForce = aindex === 1

                      return (
                        <li
                          key={aindex}
                          className={`${isForce ? '-force-update' : ''} ${
                            isVisible ? '-visible' : ''
                          }`}
                          title={isForce ? 'Force Update' : ''}
                          onClick={this.updateHandler.bind(this, {
                            isForce,
                            itemValue: aitem,
                            ProductId,
                            PeriodId,
                            ResourceId,
                            clickUpdate: true
                          })}
                        >
                          <span className="attachment">
                            {aitem}
                            <i className="slvy-ui-icon-check-extra-lt" />
                          </span>
                        </li>
                      )
                    })}
                  </ol>
                  <div
                    className="-overlay"
                    onClick={() => {
                      this.setState({ attachments: [] })
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <td>
                                <button
                                    type='button'
                                    disabled={isButtonDisable}
                                >
                                    UPDATE
                                </button>
                            </td> */}
        <div className="-dflex -seperator">
          <b>min {round(val(ItemValues, 'MinProduction', SelectedParamIndex))}</b>
          <b>inc {round(val(ItemValues, 'LotSize', SelectedParamIndex))}</b>
        </div>
        <div className="-dflex">
          <strong>net requirement</strong>
          <em>{round(val(Values, 'NetRequirement', SelectedParamIndex))}</em>
        </div>
        <div className="-dflex">
          <strong>min production</strong>
          <em>{round(val(ItemValues, 'MinProduction', SelectedParamIndex))}</em>
        </div>
        <div className="-dflex">
          <strong>forecast</strong>
          <em>{round(val(Values, 'TotalDemand', SelectedParamIndex))}</em>
        </div>
        <div className="-dflex">
          <strong>ending inventory</strong>
          <em>{round(val(Values, 'EndingInventory', SelectedParamIndex))}</em>
        </div>
        <div className="-schedule-update-cover-box">
          <CoverBox
            className="-common-cover-list"
            coverSettings={coverSettings}
            coverViolationIndexes={coverViolationIndexes}
            data={ProductPeriod}
          />
        </div>
      </div>
    )
  }
}
