import { useEffect, useRef } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { Dropdown, Badge, OverlayTrigger, Alert } from 'react-bootstrap'
import { parseTwitterDate } from '@/helpers/datetime'
import { slvyToast, SlvySpinner } from '../..'
import { INotification, INotificationBoxProps } from './NotificationBox.types'
import { useNotifications } from '../../../hooks'
import './NotificationBox.scss'
import playNotificationSound from '@/utils/notificationSound'

const params = {
  timeoutToResetNewNotificationState: 820
}

const NotificationBox = ({
  environment,
  catalogId,
  tooltip,
  isDisabled
}: INotificationBoxProps) => {
  const bell = useRef<HTMLElement | null>(null)
  const bellTimer = useRef<any>(null)

  const { handleReadNotifications, status, isThereAnyNewNotification, notifications } =
    useNotifications(params)

  useEffect(() => {
    if (status === 'error') {
      slvyToast.error({
        message: 'An error occurred when getting notifications!',
        title: 'Error'
      })
    }
  }, [status])

  useEffect(() => {
    if (!isDisabled && isThereAnyNewNotification) {
      playNotificationSound()
      bell?.current?.classList?.add('notification-bell-shake')
      bellTimer.current = setTimeout(() => {
        bell?.current?.classList?.remove('notification-bell-shake')
      }, params.timeoutToResetNewNotificationState)
    }
    return () => {
      clearTimeout(bellTimer.current)
    }
  }, [isDisabled, isThereAnyNewNotification])

  const noNotifications = notifications.length === 0

  const lastTenNotifications = notifications.slice(0, 10)
  const unreadNotificationsSize = lastTenNotifications.filter(
    ({ read }: INotification) => !read
  ).length

  const shouldNotifyUser = unreadNotificationsSize && !isDisabled

  return (
    <Dropdown
      align="end"
      className="notification-box px-xl-0 px-3 d-xl-block d-none"
      data-testid="notification-box"
      id="notificationBox"
      onClick={(event: any) => event.stopPropagation()}
    >
      <OverlayTrigger overlay={tooltip('Notification')} placement="bottom">
        <Dropdown.Toggle
          className={cx(
            {
              active: isDisabled,
              'opacity-100': isDisabled,
              'display-notification shadow bg-white bg-opacity-10 rounded': shouldNotifyUser
            },
            'navbar-link-item nav-link no-caret position-relative'
          )}
          data-testid="notification-box-toggle"
          disabled={isDisabled}
          variant="default"
        >
          <i
            ref={bell}
            className={cx(
              {
                'text-white': shouldNotifyUser
              },
              'fa fa-bell fa-lg'
            )}
          />
          {shouldNotifyUser ? (
            <Badge
              pill
              bg="danger"
              className="notification-box-badge position-absolute"
              data-testid="notification-box-badge"
            >
              {unreadNotificationsSize}
            </Badge>
          ) : null}
        </Dropdown.Toggle>
      </OverlayTrigger>
      <Dropdown.Menu
        className={cx('dropdown-alerts', 'notification-box-dropdown', {
          'd-none': isDisabled,
          'overflow-hidden': status === 'pending',
          'p-0': noNotifications
        })}
      >
        {noNotifications ? (
          <Alert key="light" className="m-0" variant="light">
            <div className="text-center text-black small">There are no notifications!</div>
          </Alert>
        ) : null}
        {status === 'pending' ? (
          <SlvySpinner containerClass="bg-opacity-75 bg-white" size="sm" />
        ) : null}
        {lastTenNotifications.map(
          ({ id, createdOn, read, message }: INotification, index: number) => {
            const bellClass = read ? 'fa-bell-o' : 'fa-bell'
            return (
              <Dropdown.Item
                key={id}
                as="span"
                data-testid={`notification-box-item-${index}`}
                onClick={() => !read && handleReadNotifications(id)}
              >
                <i className={cx('fa', 'fa-fw', bellClass)} />
                <div>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: message }}
                    className={cx({ 'fw-bold': !read })}
                  />
                  <div className="text-muted small">{parseTwitterDate(createdOn)}</div>
                </div>
              </Dropdown.Item>
            )
          }
        )}
        {noNotifications ? null : (
          <Dropdown.Item as="span" className="notification-box-footer">
            <Link
              className="dropdown-item"
              to={`/${environment}/catalog/${catalogId}/notifications`}
            >
              <strong>See All Notifications</strong> <i className="fa fa-angle-right m-0" />
            </Link>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NotificationBox
