Ext.define('Spui.view.tracking.navigation.SpotGrid', {
  extend: 'Ext.grid.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-tracking-navigation-spotgrid',
  flex: 1,
  listeners: {
    select: 'onSelectSpot',
    viewready: function (grid) {
      grid.selModel.doSelect(grid.getStore().first())
    }
  },

  initComponent: function () {
    var me = this
    var plugins = [
      {
        ptype: 'cornerIcon',
        extraButton: [
          '->',
          {
            iconCls: 'x-fa fa-arrows-alt',
            handler: this.onmaximize,
            scope: this
          }
        ],
        cfgButton: [
          {
            iconCls: 'x-fa fa-cog',
            cls: 'config-btn',
            handler: 'onConfigureSpotGrid'
          }
        ]
      }
    ]
    if (me.plugins) {
      me.plugins = me.plugins.concat(plugins)
    } else {
      me.plugins = plugins
    }
    me.callParent()
  }
})
