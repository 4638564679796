Ext.define('MDM.src.view.globalapp.Panel.GeoGrid.model.Region', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Global_MDM_Geo_Region',
  idProperty: 'ID',
  fields: [
    {
      name: 'Region',
      type: 'string',
      defaultValue: 'Enter Name'
    },
    {
      name: 'CountryCount',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'SupplysourceCount',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'Latitude',
      type: 'float',
      defaultValue: 0
    },
    {
      name: 'Longitude',
      type: 'float',
      defaultValue: 0
    },
    {
      name: 'isEditable',
      type: 'boolean'
    }
  ],
  get: function (fieldName) {
    if (fieldName == 'Title') {
      return this.data['Region']
    }
    return this.data[fieldName]
  }
})
