import { useEffect, useMemo, useRef, useState } from 'react'
import { SlvyTable } from '@/components'
import { useReduxState } from '../../hooks'
import { getDemandTree, getLoadTree } from '../../store/api/endpoints'
import { tuiSlice } from '../../store/slices'
import BaseModal from '../BaseModal'
import { getTreeQueryParams } from '../../utils'
import {
  getHierarchies,
  getCellStyle,
  toggleColorField,
  getInitialRightHierarchies
} from './helpers'
import { leftHiearchyColumns, rightHierarchyColumns } from './constants'
import { Hierarchy, SettingsModalProps } from './SettingsModal.types'

const SettingsModal = ({ isOpen, treeName, onClose }: SettingsModalProps) => {
  const tableRef = useRef(null)
  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)

  const TUI_HIERARCHY_STATE_KEY = `TUI_HIERARCHY_STATE_${treeName}_${auth.pluginId}`

  const { leftHierarchies, rightHierarchies: rightHierarchiesFromConfig } = useMemo(
    () => getHierarchies(config, treeName),
    [config, treeName]
  )

  const [rightHierarchies, setRightHierarchies] = useState(
    getInitialRightHierarchies(TUI_HIERARCHY_STATE_KEY, rightHierarchiesFromConfig)
  )

  useEffect(() => {
    setRightHierarchies(
      getInitialRightHierarchies(TUI_HIERARCHY_STATE_KEY, rightHierarchiesFromConfig)
    )
  }, [TUI_HIERARCHY_STATE_KEY, rightHierarchiesFromConfig])

  const [triggerGetLoadTree] = getLoadTree.useMutation({ fixedCacheKey: 'load-tree' })
  const [triggerGetDemandTree] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })

  const updateHierarchyState = (hierarchy: Hierarchy[]) => {
    localStorage.setItem(TUI_HIERARCHY_STATE_KEY, JSON.stringify(hierarchy))
    setRightHierarchies(hierarchy)
  }

  const handleLoad = () => {
    if (!treeName) {
      return
    }

    const hierarchy = tableRef.current?.getRowModel().rows.map(({ original }) => original)

    const payload = {
      ...getTreeQueryParams(config, treeName),
      hierarchy
    }

    if (treeName === 'loadTree') {
      triggerGetLoadTree(payload).then(() => updateHierarchyState(hierarchy))
    } else if (treeName === 'demandTree') {
      triggerGetDemandTree(payload).then(() => updateHierarchyState(hierarchy))
    }

    onClose()
  }

  return (
    <BaseModal isOpen={isOpen} size="lg" onClose={onClose}>
      <BaseModal.Header>
        <BaseModal.Title>Tree Structure</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className="d-flex gap-2">
        <SlvyTable suppressColumnMenu columns={leftHiearchyColumns} data={leftHierarchies} />
        <SlvyTable
          multiRowSelection
          rowDragDrop
          rowSelection
          suppressColumnMenu
          columns={rightHierarchyColumns}
          data={rightHierarchies}
          getCellStyle={getCellStyle}
          tableRef={tableRef}
          onSelectRow={toggleColorField}
        />
      </BaseModal.Body>
      <BaseModal.Footer>
        <BaseModal.ConfirmButton className="text-light" onClick={handleLoad}>
          Load
        </BaseModal.ConfirmButton>
        <BaseModal.CancelButton className="text-light" onClick={onClose}>
          Cancel
        </BaseModal.CancelButton>
      </BaseModal.Footer>
    </BaseModal>
  )
}

export default SettingsModal
