import { useCallback, useEffect, useMemo } from 'react'
import { transform } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import { __RowIndex as SlvyRowIndex } from '@/store/slices/localData'
import { useGetColDefByField } from '../../../index'
import { getUpdateRowData, getRowByIndex } from '../../../../helpers'
import { IRowData } from '../../../../types'
import { SetRowValuesProps } from './useRegisterMethodSetRowValues.type'
import { CommonRegisterMethodArg } from '../../registerEventsMethods.types'

function useRegisterMethodSetRowValues({
  applyUpdate,
  gridRef,
  registerMethod,
  fieldConfigs
}: SetRowValuesProps) {
  const { getColDefByField } = useGetColDefByField(gridRef)

  const onSetRowValues = useCallback(
    (params: IRowData) => {
      const dataToUpdate: IRowData[] = []

      const isUndefined = typeof params[SlvyRowIndex] === 'undefined'
      const isNull = params[SlvyRowIndex] === null
      const isString = typeof params[SlvyRowIndex] === 'string'
      const isEmptyString = isString && (params[SlvyRowIndex] as string).trim().length === 0

      if (isUndefined || isNull || isEmptyString) {
        return
      }

      const rowIndex = Number(params[SlvyRowIndex])

      const currentRow = getRowByIndex(gridRef, rowIndex)
      const pointedRows = currentRow ? [currentRow] : []

      pointedRows.forEach((pointedRow) => {
        // TODO: Need improvement for lookup fields,
        // Because we do not have lookupOptions yet,
        // We need to make an api call to take lookupOptions for each rows.
        const updatedRow = getUpdateRowData({
          fieldConfigs,
          getColDefByField,
          params,
          rowData: pointedRow
        })
        dataToUpdate.push(updatedRow)
      })

      if (dataToUpdate.length) {
        applyUpdate(dataToUpdate)
      }
    },
    [applyUpdate, fieldConfigs, getColDefByField, gridRef]
  )

  // Register setRowValues method
  const args = useMemo(() => {
    return transform(
      fieldConfigs,
      (result: CommonRegisterMethodArg[], field) => {
        const { dataType, fieldName } = field
        result.push({
          name: fieldName,
          type: PluginTypes.fromString(dataType)
        })
      },
      [{ name: SlvyRowIndex, type: PluginTypes.int }]
    )
  }, [fieldConfigs])

  useEffect(() => {
    registerMethod({
      args,
      key: 'setRowValues',
      fn: onSetRowValues
    })
  }, [args, onSetRowValues, registerMethod])
}

export default useRegisterMethodSetRowValues
