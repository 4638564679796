import _ from 'lodash'
import { updateObject } from './utility'
import * as actionTypes from '../actions/actionTypes.js'

const initialState = {
  toggledHierarchies: [],
  expandedHierarchies: [],
  allHierarchiesExpanded: false
}

const resourceRowItem = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_HIERARCHY:
      return !_.some(state.toggledHierarchies, action.resourceRow)
        ? updateObject(state, {
            toggledHierarchies: [...state.toggledHierarchies, action.resourceRow]
          })
        : updateObject(state, {
            toggledHierarchies: _.reject(state.toggledHierarchies, (resourceRow) =>
              _.isEqual(resourceRow, action.resourceRow)
            )
          })
    case actionTypes.EXPAND_HIERARCHY:
      return _.some(state.expandedHierarchies, action.resourceRow)
        ? updateObject(state, {
            expandedHierarchies: _.reject(state.expandedHierarchies, (resourceRow) =>
              _.isEqual(resourceRow, action.resourceRow)
            ),
            toggledHierarchies: _.reject(state.toggledHierarchies, (resourceRow) =>
              _.isEqual(resourceRow.resourceIndex, action.resourceRow.resourceIndex)
            )
          })
        : updateObject(state, {
            expandedHierarchies: [...state.expandedHierarchies, action.resourceRow],
            toggledHierarchies: _.reject(state.toggledHierarchies, (resourceRow) =>
              _.isEqual(resourceRow.resourceIndex, action.resourceRow.resourceIndex)
            )
          })
    case actionTypes.EXPAND_ALL_HIERARCHIES:
      return updateObject(state, { allHierarchiesExpanded: !state.allHierarchiesExpanded })
    case actionTypes.RESET_HIERARCHY:
      return updateObject(state, { toggledHierarchies: [], expandedHierarchies: [] })
    default:
      return state
  }
}

const PRODUCTresourceRowItem = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_TOGGLE_HIERARCHY:
      return !_.some(state.toggledHierarchies, action.resourceRow)
        ? updateObject(state, {
            toggledHierarchies: [...state.toggledHierarchies, action.resourceRow]
          })
        : updateObject(state, {
            toggledHierarchies: _.reject(state.toggledHierarchies, (resourceRow) =>
              _.isEqual(resourceRow, action.resourceRow)
            )
          })
    case actionTypes.PRODUCT_EXPAND_HIERARCHY:
      return _.some(state.expandedHierarchies, action.resourceRow)
        ? updateObject(state, {
            expandedHierarchies: _.reject(state.expandedHierarchies, (resourceRow) =>
              _.isEqual(resourceRow, action.resourceRow)
            ),
            toggledHierarchies: _.reject(state.toggledHierarchies, (resourceRow) =>
              _.isEqual(resourceRow.resourceIndex, action.resourceRow.resourceIndex)
            )
          })
        : updateObject(state, {
            expandedHierarchies: [...state.expandedHierarchies, action.resourceRow],
            toggledHierarchies: _.reject(state.toggledHierarchies, (resourceRow) =>
              _.isEqual(resourceRow.resourceIndex, action.resourceRow.resourceIndex)
            )
          })
    case actionTypes.PRODUCT_EXPAND_ALL_HIERARCHIES:
      return updateObject(state, { allHierarchiesExpanded: !state.allHierarchiesExpanded })
    case actionTypes.PRODUCT_RESET_HIERARCHY:
      return updateObject(state, { toggledHierarchies: [], expandedHierarchies: [] })
    default:
      return state
  }
}

export { resourceRowItem, PRODUCTresourceRowItem }
