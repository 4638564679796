export default `({
    columnLines: true,
    margin: 1,
    flex: 1,
    defaultListenerScope: true,
    viewModel: {
        data: {
            numberofselectedItems: '0',
        }
    },
    tbar: [{
        xtype: 'button',
        text: tt('Export to Excel'),
        handler: function (btn) {
            var grid = this.ownerCt.ownerCt
            grid.saveDocumentAs({
                type: 'xlsx',
                title: tt('Benzer Ürün'),
                fileName: tt('Benzer Ürün')+'.xlsx',
            });
        }
    },
    {
            iconCls: 'x-fa fa-filter',
            bind: {
                text: tt('Secili Urunler') + ' : ' + '{numberofselectedItems}'
            },
            handler: function () {
                if (this.ownerCt && this.ownerCt.ownerCt) {
                    var grid = this.ownerCt.ownerCt
                    if (!grid.store.isFiltered()) {
                        grid.store.filter('IsSimilarTemp', 1)
                    } else {
                        grid.store.clearFilter();
                    }
                }
            },
            scope: 'this'
        }, '->',
        {
            xtype: 'label',
            html: tt('Beklenti endeksi')
        },
        {
            width: 100,
            xtype: 'numberfield',
            minValue: 0.5,
            decimalSeparator: ',',
            listeners: {
                focusleave ( me, event, eOpts )
                {
                    if(me.value<0.5)
                    {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt("Beklenti endeksi 0.5'den küçük olamaz.")
                            )
                        me.setValue(0.5)
                    }
                }
            },
            bind: {
                value: '{spotRecord.ExpectedPerformanceIndex}',
            },
        },
        {
            iconCls: 'x-fa fa-cube',
            text: tt('Add New Spot'),
            handler: function () {
                var simmain = this.up('spui-view-common-similarity-main');
                spotId = this.up('spui-view-common-similarity-main').spotId
                if (!Spui.data.store.SpotMaster) {
                    Ext.define('Spui.data.store.SpotMaster', {
                        extend: 'Ext.data.Store',
                        alias: 'store.SpotMaster',
                        storeId: 'SpotMaster',
                        proxy: {
                            type: 'ajax',
                            url: Spui.Global.getApiUrl() + '/spui/SimilarSpotSearch',
                            reader: {
                                type: 'json',
                            },
                            params: {
                                spotId: spotId
                            }
                        },
                    });
                    var store = Ext.create('Spui.data.store.SpotMaster', {
                        autoSync: false
                    });
                    store.getProxy().setExtraParam('spotId', spotId);
                }
                var win = Ext.create('Spui.uxWindow', {
                    bodyPadding: 4,
                    ui: 'sui-window',
                    layout: 'fit',
                    bodyStyle: {
                        background: '#EDEFF2',
                    },
                    maximized: true,
                    height: '80%',
                    width: 950,
                    title: tt('Add New Spot'),
                    uxItemCfg: 'addNewSpot',
                    uxItem: {
                        spotId: spotId,
                        viewModel: {
                            stores: {
                                basketStore: {
                                    autoLoad: true,
                                    proxy: {
                                        type: 'ajax',
                                        url: Spui.Global.getApiUrl() + '/spui/getListofAddedSpotList',
                                        extraParams: {
                                            spotId: spotId
                                        }
                                    }
                                }
                            },
                            data: {
                                spotId: spotId
                            }
                        }
                    }
                })
                win.on('changed', function () {
                    simmain.fireEvent('activate', simmain)
                })
                win.show();
            },
            tooltip: tt('New Product')
        },
    ],
    listeners: {
        render: function (grid) {
            grid.store.on('load', grid.onSetCheckeds, grid)
        },
        select: function (grid, record) {
            var me = this,
                t = me.ownerCt.getTapPanel();
            t.setSimilarSpotId(record.get('SimilarityID'));
        },
    },
    features: [{
        ftype: 'grouping',
        startCollapsed: false,
        groupHeaderTpl: 'Ürün Açıklaması: {name} ({rows.length} {[values.rows.length > 1 ? "" : ""]})'
    }],
    plugins: [{
        ptype: 'gridexporter'
    }],
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            // rowParams.tstyle += 'height: 50px;';
            if (record.get('IsSame')) {
                //  rowParams.tstyle += "background-color:" + 'red' + ';';
                return 'row-red';
            }
            if (record.get('Source') == 'User') {
                // rowParams.tstyle += "background-color:" + 'blue' + ';';
                return 'row-red';
            }
            return ''
        }
    },
    columns: [
        {
            xtype: 'checkcolumn',
            header: tt('IsSmilar?'),
            dataIndex: 'IsSimilarTemp',
            sortable: true,
            headerCheckbox: false,
            width: 90,
            stopSelection: true,
            listeners: {
                checkchange: 'onCheckchange',
                beforecheckchange: 'onBeforeCheckChange'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('Date'),
            columns: [{
                    text: tt('StartDate'),
                    dataIndex: 'StartDate',
                    xtype: 'datecolumn',
                    format: tt('d-m-Y'),
                    width: '90'
                },
                {
                    text: tt('PreEndDate'),
                    dataIndex: 'EndDate',
                    xtype: 'datecolumn',
                    format: tt('d-m-Y'),
                    width: '90'
                },
            ]
        },
        {
            text: tt('Duration'),
            columns: [{
                    text: tt('Week'),
                    dataIndex: 'DurationInWeek',
                    width: 80,
                    format: '0',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('Days'),
                    dataIndex: 'DurationInDays',
                    width: 80,
                    format: '0',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
            ]
        },
        {
            text: tt('Performans'),
            columns: [{
                    text: tt('Quantity'),
                    dataIndex: 'TotalInvoicedQuantity',
                    width: 100,
                    format: '0,000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('Price'),
                    dataIndex: 'Price',
                    width: 100,
                    format: '0,000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        v = Ext.util.Format.number(v, '0,000');
                        if(t('₺')=='₺')
                        {
                            return v + ' ' + t('₺');
                        }
                        else{
                            return t('₺') +' '+ v;
                        }
                    }
                },
                {
                    text: tt('Revenue'),
                    dataIndex: 'TotalNetValueoftheSalesOrder',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        v = Ext.util.Format.number(v, '0,000');
                        if(t('₺')=='₺')
                        {
                            return v + ' ' + t('₺');
                        }
                        else{
                            return t('₺') +' '+ v;
                        }
                    }
                }
            ]
        },
        {
            text: tt('Day 1'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay1',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay1',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Day 4'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay4',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay4',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Day 8'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay8',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay8',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Day 11'),
            columns: [{
                text: tt('Quantity'),
                dataIndex: 'TotalInvoicedQuantityDay11',
                width: 100,
                format: '0,000',
                align: 'right',
                xtype: 'numbercolumn'
            }, {
                text: tt('Revenue'),
                dataIndex: 'TotalNetValueoftheSalesOrderDay11',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    v = Ext.util.Format.number(v, '0,000');
                    if(t('₺')=='₺')
                    {
                        return v + ' ' + t('₺');
                    }
                    else{
                        return t('₺') +' '+ v;
                    }
                }
            }]
        },
        {
            text: tt('Pareto 80% '),
            columns: [{
                    text: tt('Gün'),
                    dataIndex: 'Pareto80Day',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('ParetoTotalInvoicedQuantity'),
                    dataIndex: 'ParetoTotalInvoicedQuantity',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                },
                {
                    text: tt('ParetoTotalInvoicedQuantityPercent'),
                    dataIndex: 'ParetoTotalInvoicedQuantityPercent',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn'
                }
            ]
        },
    ],
    storeLoad: function (store) {
        store.sort('IsSimilarTemp', 'DESC')
        var rec = store.first();
        if (rec) {
            this.selModel.doSelect(rec);
        }
    },
    onSetCheckeds: function (p, rowIndex, checked, record, e, eOpts) {
        var store = this.store,
            viewModel = this.getViewModel();
        var allRecords = store.snapshot || store.data;
        var i = 0
        allRecords.each(function (record) {
            i = record.get('IsSimilarTemp') ? i + 1 : i;
        });
        viewModel.set('numberofselectedItems', i);
        viewModel.getParent().set('numberofselectedItems', i);
    },
    onBeforeCheckChange: function (p, rowIndex, checked, record, e, eOpts) {
        var store = this.store,
            viewModel = this.getViewModel();
        var allRecords = store.snapshot || store.data;
        var i = 0
        allRecords.each(function (record) {
            i = record.get('IsSimilarTemp') ? i + 1 : i;
        });
        if (i >= 5 && checked) {
            Ext.Msg.alert(
                tt('Durum'),
                tt('5 Adetten Fazla Benzer Ürün Seçemezsiniz.')
            )
            return false
        } else {
            return true
        }
    },
    onCheckchange: function (p, rowIndex, checked, record, e, eOpts) {
        var store = this.store,
            viewModel = this.getViewModel();
        ownerCt = this.ownerCt;
        ownerCt.setLoading(tt('yükleniyor'));
        store.sync({
            success: function () {
                store.commitChanges()
                ownerCt.setLoading(false);
            },
            failure: function () {
                ownerCt.setLoading(false);
                Ext.Msg.alert(
                    tt('Durum'),
                    tt('İşlem Gerçekleştirilirken bir hata oluştu')
                );
                store.rejectChanges()
            }
        })
        var allRecords = store.snapshot || store.data;
        var i = 0
        allRecords.each(function (record) {
            i = record.get('IsSimilarTemp') ? i + 1 : i;
        });
        viewModel.set('numberofselectedItems', i);
        viewModel.getParent().set('numberofselectedItems', i);
    },
    datachanged: function () {}
})
`
