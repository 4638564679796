import { TcustomConfigs } from '../../../types'

const defaultBooleanProps: TcustomConfigs['boolean'] = {
  trueIcon: 'ag-icon ag-icon-checkbox-checked',
  trueColor: 'gray',
  falseIcon: 'ag-icon ag-icon-checkbox-unchecked',
  falseColor: 'gray'
}

export { defaultBooleanProps }
