// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Column from './column'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import type {
  DropResult,
  DragStart,
  DraggableLocation,
  DroppableProvided
} from 'react-beautiful-dnd'
import type { TaskCardMap } from '../types'
import { reorderTaskCardMap } from '../reorder'
import _ from 'lodash'
import { slvyToast } from '../../../components'
import Popup from '../Popup/index'

const ParentContainer = styled.div`
  height: ${({ height }) => height};
  overflow-x: hidden;
  overflow-y: auto;
`

const Container = styled.div`
  min-height: 100vh;

  /* like display:flex but will allow bleeding over the window width */
  min-width: 100vw;
  display: inline-flex;
`

type Props = {|
  initial: TaskCardMap,
  containerHeight?: string
|}

type State = {|
  columns: TaskCardMap,
  ordered: string[],
  autoFocusTaskCardId: ?string
|}

export default class Board extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.sendTask = this.sendTask.bind(this)
    this.updateTaskStatus = this.updateTaskStatus.bind(this)
    this.getTask = this.getTask.bind(this)
    this.popupOk = this.popupOk.bind(this)
    this.popupCancel = this.popupCancel.bind(this)
  }
  getTask(taskId) {
    const { taskCards } = this.props
    return _.find(taskCards, { Id: taskId })
  }
  sendTask(taskCard) {
    this.props.taskOnClick(taskCard)
  }
  /* eslint-disable react/sort-comp */

  state: State = {
    columns: this.props.initial,
    ordered: Object.keys(this.props.initial),
    autoFocusTaskCardId: null,
    showTransportationPopup: false,
    srcStatusID: 0,
    draggableId: 0,
    desStatusID: 0,
    areThereReasons: false
  }

  boardRef: ?HTMLElement

  componentDidMount() {}

  onDragStart = (initial: DragStart) => {
    this.setState({
      autoFocusTaskCardId: null
    })
  }

  onDragEnd = (result: DropResult) => {
    const { assigneeChangeabilityDuringCardTransportation = false, uniqueUserName = '' } =
      this.props
    const { columns } = this.state
    if (!result.destination) {
      return
    }
    if (result.type !== 'Column') {
      const task = _.find(columns[result.source.droppableId], {
        Id: result.draggableId
      })

      const { ordered } = this.state
      const { tasksAvailableNavigations, statuses } = this.props
      const {
        destination: { droppableId: destinationIndex },
        draggableId,
        source: { droppableId: sourceIndex }
      } = result
      if (destinationIndex !== sourceIndex) {
        const srcStatusID =
          statuses[
            _.findIndex(ordered, function (o) {
              return o === sourceIndex
            })
          ].Id
        const desStatusID =
          statuses[
            _.findIndex(ordered, function (o) {
              return o === destinationIndex
            })
          ].Id
        const currentNavigation = _.find(tasksAvailableNavigations, {
          FromStatusId: srcStatusID,
          ToStatusId: desStatusID,
          TypeId: task.TypeId
        })
        if (
          currentNavigation &&
          (uniqueUserName === task.AssigneeUniqueName || uniqueUserName === task.ReporterUniqueName)
        ) {
          const source: DraggableLocation = result.source
          const destination: DraggableLocation = result.destination
          const data = reorderTaskCardMap({
            taskCardMap: this.state.columns,
            source,
            destination
          })

          if (assigneeChangeabilityDuringCardTransportation || currentNavigation.HasItReason) {
            this.setState({
              columns: data.taskCardMap,
              autoFocusTaskCardId: data.autoFocusTaskCardId,
              draggableId: draggableId,
              srcStatusID: srcStatusID,
              desStatusID: desStatusID,
              showTransportationPopup: true,
              areThereReasons: currentNavigation.HasItReason,
              draggableCard: task
            })

            this.props.localUpdateTaskStatus({
              taskID: draggableId,
              statusID: desStatusID
            })
            this.props.getPopupData(
              srcStatusID,
              desStatusID,
              assigneeChangeabilityDuringCardTransportation,
              currentNavigation.HasItReason,
              task.CategoryName
            )
          } else {
            this.setState({
              columns: data.taskCardMap,
              autoFocusTaskCardId: data.autoFocusTaskCardId,
              srcStatusID: srcStatusID
            })
            this.props.localUpdateTaskStatus({
              taskID: draggableId,
              statusID: desStatusID
            })
            this.updateTaskStatus(draggableId, desStatusID, '')
          }
        } else {
          slvyToast.error({
            message: ' It is not allowed to move task to this column  ',
            title: ' Unsuccessful '
          })
        }
      }
    }
  }

  popupOk(assignee, reason) {
    const { draggableId, desStatusID, draggableCard } = this.state
    if (assignee !== '') {
      this.props.updateTask({
        taskID: draggableCard.Id,
        categoryID: draggableCard.CategoryID,
        priorityID: draggableCard.PriorityID,
        subStatusId: draggableCard.SubStatusId,
        statusID: draggableCard.StatusID,
        dueDate: draggableCard.DueDate,
        assigneeName: assignee,
        subject: draggableCard.Subject,
        description: draggableCard.Description
      })
    }
    this.updateTaskStatus(draggableId, desStatusID, reason)
    this.setState({
      showTransportationPopup: false,
      srcStatusID: 0,
      draggableId: 0,
      desStatusID: 0,
      areThereReasons: false,
      draggableCard: undefined
    })
    this.props.emptyPopupData()
  }
  popupCancel() {
    const { draggableId, srcStatusID } = this.state
    this.props.localUpdateTaskStatus({
      taskID: draggableId,
      statusID: srcStatusID
    })
    this.setState({
      showTransportationPopup: false,
      srcStatusID: 0,
      draggableId: 0,
      desStatusID: 0,
      areThereReasons: false,
      draggableCard: undefined
    })
    this.props.emptyPopupData()
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      columns: nextProps.initial,
      ordered: Object.keys(nextProps.initial)
    })
  }
  updateTaskStatus(taskId, newStatusId, reason) {
    this.props.updateStatus({
      taskID: taskId,
      previousStatusID: this.state.srcStatusID,
      statusID: newStatusId,
      reason: reason
    })
  }

  render() {
    const columns: TaskCardMap = this.state.columns
    const ordered: string[] = this.state.ordered
    const {
      containerHeight,
      taskAppearanceProperties,
      localizationLanguage,
      users,
      reasons,
      tasksAvailableNavigations,
      format,
      openContent,
      uniqueUserName,
      assigneeChangeabilityDuringCardTransportation = false,
      popupReasonsSuccess = false,
      popupUsersSuccess = false
    } = this.props
    const { showTransportationPopup, areThereReasons = false } = this.state
    const board = (
      <Droppable
        droppableId="board"
        type="COLUMN"
        direction="horizontal"
        ignoreContainerClipping={Boolean(containerHeight)}
      >
        {(provided: DroppableProvided) => (
          <Container ref={provided.innerRef}>
            {ordered.map((key: string, index: number) => (
              <Column
                key={key}
                index={index}
                title={key}
                taskCards={columns[key]}
                autoFocusTaskCardId={this.state.autoFocusTaskCardId}
                onShow={this.sendTask}
                taskAppearanceProperties={taskAppearanceProperties}
                uniqueUserName={uniqueUserName}
                columnIndex={index}
                localizationLanguage={localizationLanguage}
                tasksAvailableNavigations={tasksAvailableNavigations}
                statuses={this.props.statuses}
                getTask={this.getTask}
                format={format}
                openContent={openContent}
              />
            ))}
          </Container>
        )}
      </Droppable>
    )
    return (
      <div>
        {showTransportationPopup && (
          <Popup
            onOk={this.popupOk}
            onCancel={this.popupCancel}
            users={users}
            reasons={reasons}
            title={'Confirmation'}
            areThereUsers={assigneeChangeabilityDuringCardTransportation}
            areThereReasons={areThereReasons}
            popupReasonsSuccess={popupReasonsSuccess}
            popupUsersSuccess={popupUsersSuccess}
          />
        )}
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          {this.props.containerHeight ? (
            <ParentContainer height={containerHeight}>{board}</ParentContainer>
          ) : (
            board
          )}
        </DragDropContext>
      </div>
    )
  }
}
