import { Col } from 'react-bootstrap'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { SlvyFormInput, SlvyFormSelect } from '@/components'
import styles from './index.module.scss'

const inputColumns = { label: 4, input: 6 }
const selectColumns = { label: 4, select: 3 }
const formGroupStyle = {
  marginLeft: 'calc(var(--bs-gutter-x) * -.5)',
  marginRight: 'calc(var(--bs-gutter-x) * -.5)',
  paddingTop: 6
}

const selectFromState = (state) => ({
  rootOptions: state.options.rootOptions,
  formattingSettings: state.plugin.formattingSettings,
  optionPriceEditable: state.plugin.optionPriceEditable
})

const Form = ({ data, isDisabled, setData }) => {
  const {
    BasePrice,
    Expenditure,
    Markdown,
    Sellthrough,
    PrePackSize,
    TotalPrePackQuantity,
    TotalBuyQuantity,
    SizeRangeId
  } = data
  const {
    rootOptions: { SizeRangeMapping = {} },
    formattingSettings: { currencySymbol = '$' },
    optionPriceEditable
  } = useSelector(selectFromState)

  const sizeRangeOptions = Object.entries(SizeRangeMapping).map(([value, label]) => ({
    label,
    value: Number(value)
  }))

  return (
    <>
      <Col className={styles.settingsForm}>
        <SlvyFormInput
          disabled
          columns={inputColumns}
          label="Markdown %:"
          labelClass="text-muted"
          size="sm"
          value={Markdown}
        />
        <SlvyFormInput
          columns={inputColumns}
          disabled={isDisabled}
          label={`Unit Cost (${currencySymbol}):`}
          size="sm"
          type="number"
          value={numeral(Expenditure).format('0.00')}
          onChange={(value) => setData((prev) => ({ ...prev, Expenditure: Number(value) }))}
        />
        <SlvyFormSelect
          columns={selectColumns}
          data={sizeRangeOptions}
          disabled={isDisabled}
          formGroupStyle={formGroupStyle}
          label="Size Range Type:"
          labelClass="ps-0 me-1 mb-1"
          size="sm"
          value={SizeRangeId}
          onSelect={(value) => setData((prev) => ({ ...prev, SizeRangeId: value }))}
        />
        <SlvyFormInput
          disabled
          columns={inputColumns}
          label="Total Buy Quantity:"
          labelClass="text-muted"
          size="sm"
          value={numeral(TotalBuyQuantity).format('0')}
        />
      </Col>
      <Col className={styles.settingsForm}>
        <SlvyFormInput
          columns={inputColumns}
          disabled={isDisabled}
          label="Sellthrough Target:"
          size="sm"
          type="number"
          value={Sellthrough}
          onChange={(value) => setData((prev) => ({ ...prev, Sellthrough: Number(value) }))}
        />
        <SlvyFormInput
          columns={inputColumns}
          disabled={isDisabled}
          label="Prepack per Option:"
          size="sm"
          type="number"
          value={PrePackSize}
          onChange={(value) => setData((prev) => ({ ...prev, PrePackSize: Number(value) }))}
        />
        <SlvyFormInput
          disabled
          columns={inputColumns}
          label="Total PrePack Quantity:"
          labelClass="text-muted"
          size="sm"
          value={TotalPrePackQuantity}
        />
        {optionPriceEditable ? (
          <SlvyFormInput
            columns={inputColumns}
            label="Price:"
            size="sm"
            type="number"
            value={numeral(BasePrice).format('0.00')}
            onChange={(value) => setData((prev) => ({ ...prev, BasePrice: Number(value) }))}
          />
        ) : null}
      </Col>
    </>
  )
}

export default Form
