Ext.define('Spui.view.common.productinfo.cmp.tabpanel.SalesChart.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-main',
  title: 'SalesChart',
  defaultListenerScope: true,
  layout: 'fit',
  forceToLoadStore: false,

  mixins: ['Spui.view.common.productinfo.cmp.tabpanel.SalesChart.SalesChartMixin'],
  listeners: {
    activate: function (p) {
      if (p.forceToLoadStore) p.getStore().load()
    }
  },
  initComponent: function () {
    var me = this
    ;(me.plugins = [
      {
        ptype: 'cornerIcon',
        extraButton: [
          '->',
          {
            iconCls: 'x-fa fa-arrows-alt',
            handler: me.onMaximize,

            scope: me
          }
        ],
        cfgButton: [
          {
            iconCls: 'x-fa fa-cog',
            cls: 'config-btn',
            handler: me.onConfigureChart,
            scope: me
          }
        ]
      }
    ]),
      me.callParent()
  },

  onConfigureChartSave: function (v, k) {
    var setRec = this.getChartCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()

    var res = new Function('return ' + k)()

    this.setChart(res)
  },
  onMaximize: function () {
    var me = this,
      store = me.getStore()

    var win = Ext.create('Ext.window.Window', {
      maximized: true,
      layout: 'fit'
    })
    win.show()
    win.setLoading(tt('Yükleniyor'))
    Ext.defer(function () {
      var item = Ext.create({
        xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-tabpanel',
        store: store,
        ownerCt: me.ownerCt.ownerCt,
        chart: {
          title: tt('Sales Chart'),

          plugins: [
            {
              ptype: 'cornerIcon',

              extraButton: [
                '->',
                {
                  iconCls: 'x-fa fa-cog',
                  bind: {
                    hidden: '{!isAdmin}'
                  },
                  handler: 'onConfigureChart'
                }
              ]
            }
          ]
        },
        grid: { title: tt('Sales Grid') }
      })
      win.add(item)
      win.setLoading(false)
    }, 10)
  }
})
