import { useEffect } from 'react'
import { isEmpty, isNil } from 'lodash'
import {
  getDemandTree,
  getLoadTree,
  getRunInfo,
  getServices,
  getServiceStatus,
  setService
} from '../../store/api/endpoints'
import { tuiSlice, serviceModalSlice } from '../../store/slices'
import { useReduxState } from '../../hooks'
import useScenarioSelected from '../../hooks/useRegisterEvents/useScenarioSelected'
import { getTreeQueryParams } from '../../utils'
import BaseModal from '../BaseModal'
import ServiceTable from '../ServiceTable'
import styles from './ServiceModal.module.scss'

export default function ServiceModal() {
  const { applyButtonRef } = useScenarioSelected()

  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)
  const [serviceModal, serviceModalDispatch] = useReduxState(
    serviceModalSlice.selectSlice,
    serviceModalSlice.actions
  )

  const [triggerGetServices, servicesResponse] = getServices.useLazyQuery()
  const [triggerGetLoadTree] = getLoadTree.useMutation({ fixedCacheKey: 'load-tree' })
  const [triggerGetDemandTree] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })
  const [triggerGetRunInfo] = getRunInfo.useLazyQuery()
  const serviceStatus = getServiceStatus.useQueryState()
  const [triggerSetService] = setService.useMutation()

  useEffect(() => {
    triggerGetServices()
  }, [])

  useEffect(updateServiceModalState, [servicesResponse.isSuccess])

  function handleCloseModal() {
    serviceModalDispatch.setIsOpen(false)
  }

  function handleSetService() {
    const { ServiceAddress, ServiceHttpPort, RunID } = serviceModal.selectedService!
    const serviceAddress = `http://${ServiceAddress}:${ServiceHttpPort}/LoadPlannerServiceMeta?wsdl`

    triggerSetService({ runParameter: RunID, serviceAddress })
    triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
    triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })
    serviceModalDispatch.setIsOpen(false)

    if (isNil(serviceStatus.data?.PlanInfo.CurrentScenarioID)) return
    triggerGetRunInfo(serviceStatus.data?.PlanInfo.CurrentScenarioID)
    applyButtonRef.current?.handleApplyButtonClick()
  }

  function updateServiceModalState() {
    if (servicesResponse.isSuccess) {
      serviceModalDispatch.setIsOpen(true)
    }
  }

  return (
    <BaseModal
      className={styles.serviceModal}
      isOpen={serviceModal.isOpen}
      size="xl"
      onClose={handleCloseModal}
    >
      <BaseModal.Header>
        <BaseModal.Title>Select a Service</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className={styles.serviceModalBody}>
        <ServiceTable />
      </BaseModal.Body>
      <BaseModal.Footer className={styles.serviceModalFooter}>
        <BaseModal.ConfirmButton
          className={styles.serviceModalConfirmButton}
          disabled={isEmpty(serviceModal.selectedService)}
          size="sm"
          variant="success"
          onClick={handleSetService}
        >
          Apply
        </BaseModal.ConfirmButton>
      </BaseModal.Footer>
    </BaseModal>
  )
}
