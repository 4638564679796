import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import numeral from 'numeral'
import { removeCurrencySymbol } from '../../utils'
import CopyFromPlanningSegments from '../CopyFromPlanningSegments'
import SegmentSettings from '../SegmentSettings'
import { getOptions } from '../../store/slices/options'

const title = 'Copy Planning Parameters From'

const selectFromState = (state) => ({
  formattingSettings: state.plugin.formattingSettings,
  rootOptions: state.options.rootOptions
})

function ContextMenu(props) {
  const dispatch = useDispatch()

  const {
    culture,
    isFutureOption,
    onUpdateOption,
    optionItem,
    setShowSettings,
    showCPP,
    pluginId,
    showSettings,
    setShowCPP
  } = props

  const { SegmentOptions = [] } = optionItem
  const { formattingSettings, rootOptions } = useSelector(selectFromState)

  const { currencySymbol = '$' } = formattingSettings
  const { AssortmentId, Options = [] } = rootOptions
  const [assortmentId, setAssortmentId] = useState(AssortmentId)
  const [assortmentOptions, setAssortmentOptions] = useState(Options)

  useEffect(() => {
    if (!_.isEmpty(rootOptions)) {
      setAssortmentOptions(rootOptions.Options)
      setAssortmentId(rootOptions.AssortmentId)
    }
  }, [rootOptions])

  useEffect(() => {}, [optionItem, SegmentOptions])

  const onCopyFromPlanningParameters = (selectedProduct) => {
    const { key: SourceProductId = null } = selectedProduct

    const params = {
      AssortmentId: assortmentId,
      SourceProductId,
      TargetProductId: optionItem.Id
    }

    const payload = {
      pluginId,
      method: 'CopyPlanningParameters',
      requestMethod: 'post',
      body: {
        ...params
      }
    }

    dispatch(getOptions({ ...payload }))
    setShowCPP(false)
  }

  return (
    <>
      {showSettings && (
        <SegmentSettings
          assortmentId={assortmentId}
          culture={culture}
          data={optionItem}
          handleCloseModal={() => {
            setShowSettings(false)
          }}
          isUpdateDisabled={isFutureOption === false}
          onUpdateOption={onUpdateOption}
          removeCurrencySymbol={(value, format) => {
            removeCurrencySymbol(numeral(value).format(format), currencySymbol)
          }}
          SegmentOptions={optionItem.SegmentOptions}
          show={showSettings}
        />
      )}
      <Modal
        centered
        contentClassName="w-auto"
        show={showCPP}
        size="sm"
        onHide={() => setShowCPP(false)}
      >
        <CopyFromPlanningSegments
          copyFromPlanningSegmentsHandler={onCopyFromPlanningParameters}
          isShown={showCPP}
          options={assortmentOptions}
          title={title}
        />
      </Modal>
    </>
  )
}

export default ContextMenu
