import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import _ from 'lodash'

class Number extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    const formattedValue = this.props.getFormatedValue(
      this.props.component.fieldName,
      this.props.component.value
    )
    this.state = {
      value: _.isNil(formattedValue) ? '' : formattedValue
    }
  }

  handleChange(e) {
    this.setState({
      value: e.target.value
    })
  }

  handleBlur(e) {
    const { component } = this.props
    const numberValue = e.target.value

    const unformattedValue = this.props.getUnformattedValue(component.fieldName, numberValue)
    this.props.onChange(component.sectionKey, component.fieldName, unformattedValue)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const formattedValue = nextProps.getFormatedValue(
      nextProps.component.fieldName,
      nextProps.component.value
    )
    this.setState({
      value: _.isNil(formattedValue) ? '' : formattedValue
    })
  }

  render() {
    const {
      isInvalid,
      validationCheckState,
      component: { readonly: compReadonly, placeholder = '' },
      readonly: formReadonly = false,
      compact = false,
      inputHeight = 25,
      inputFontSize = 11
    } = this.props
    const { value } = this.state
    const readonly = formReadonly || compReadonly

    const validationProps = {}
    if (validationCheckState) {
      validationProps.isInvalid = isInvalid
    }

    return (
      <FormControl
        {...validationProps}
        disabled={readonly ? 'disabled' : false}
        placeholder={placeholder}
        style={{
          height: `${compact ? `${inputHeight}px` : 'auto'}`,
          fontSize: `${compact ? `${inputFontSize}px` : '14px'}`
        }}
        type="text"
        value={value}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}
export default Number
