/**
 * Serie class for line series type
 *
 * See {@link Chart.ux.Highcharts.Serie} class for more info
 */
Ext.define('Chart.ux.Highcharts.LineSerie', {
  extend: 'Chart.ux.Highcharts.Serie',
  alternateClassName: ['highcharts.line'],
  type: 'line'
})
