import React from 'react'
import cx from 'classnames'
import { IProps } from './ViewOptions.types'

const viewSettings = [
  {
    name: 'Clean',
    ratio: false
  },
  {
    name: 'Compact',
    ratio: true
  }
]

const getViewClass = (val: boolean) =>
  val ? 'slvy-ui-icon-view-regular' : 'slvy-ui-icon-unview-regular'

export function ViewOptions({
  areVisibleRatiosValues,
  isMillion,
  isPercentage,
  isVisibleRatioValue,
  isVisibleSplitValue,
  onToggleSplitVisibility,
  onChangeView,
  onToggleRatioVisibility,
  onChangeMillionView,
  onToggleRatiosVisibility
}: IProps) {
  return (
    <div className="viewOptions">
      <div className="cell">
        <div className="split">
          <a
            className="show-hide"
            onClick={(event) => onToggleSplitVisibility(!isVisibleSplitValue, event)}
          >
            <span className={getViewClass(isVisibleSplitValue)} />
          </a>
          <div className="graph" />
          <div className="value">40%</div>
        </div>
        <div className="ratio-">
          <div className="unitToggle">
            <span className="slvy-ui-icon-symbol-absolute" />
            <span
              className={isPercentage ? 'slvy-ui-icon-toggle-on' : 'slvy-ui-icon-toggle-off'}
              onClick={(event) => onChangeView(isPercentage ? 'absolute' : 'percentage', event)}
            />
            <span className="slvy-ui-icon-symbol-percentage" />
          </div>
          <a
            className="show-hide"
            onClick={(event) => onToggleRatioVisibility(!isVisibleRatioValue, event)}
          >
            <span className={getViewClass(isVisibleRatioValue)} />
          </a>
          <div className="value">50%</div>
          <div className="icon -up">
            <span className="slvy-ui-icon-caret-top" />
          </div>
        </div>
        <div className="values" onClick={() => onChangeMillionView()}>
          <div className="value -main">{isMillion ? '1.000.000' : '1M'}</div>
        </div>
        <a className="show-hide-ratio">
          <i className="slvy-ui-icon-desktop" />
          <ul className="dd-root">
            {viewSettings.map(({ name, ratio }, key) => (
              <li
                key={key}
                className={cx('option', { '-active': ratio === areVisibleRatiosValues })}
                onClick={(event) => onToggleRatiosVisibility(ratio, event)}
              >
                {name}
              </li>
            ))}
          </ul>
        </a>
      </div>
    </div>
  )
}
