require('../src/view/wizard/WizardViewModel')

require('../src/view/wizard/Breadcrumbs/Breadcrumbs')
require('../src/view/wizard/items/Card')
require('../src/view/wizard/items/Main')
require('../src/view/wizard/pages/Publish/Mixin')
require('../src/view/wizard/pages/Publish/Main')
require('../src/view/wizard/pages/spotparams/Main')
require('../src/view/wizard/WizardController')
require('../src/view/wizard/WizardMain')
