import React, { Component } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import request from 'superagent'
import numeral from 'numeral'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import Mousetrap from 'mousetrap'
import { PluginTypes } from '@/BasePlugin'
import { ErrorBoundary, slvyToast } from '@/components'
import EventListenerManager from '@/utils/eventListenerManager'
import BoundedQueue from '@/utils/boundedQueue'
import { API_URL } from '@/constants'
import {
  onProductSearchInputValueChange,
  toggleProduct as onToggleProductionInfo,
  setConfigSettings,
  resetHierarchy,
  setRunInfo,
  setCoverViolationIndexes,
  toggleLogging
} from './actions'
import { selectSession, setSessions } from './actions/resourcePlan'

import {
  Production,
  ProductionV2,
  ProductionV3,
  Resource,
  ResourceSchedule,
  ShiftCalendar,
  Supply
} from './views'

import { Header } from './components'

import History from './utils/history'
import { getMessage } from './utils'
import { dataMap, shortcuts } from './utils/constants'
import CustomConsole from './utils/custom-console'
import './scss/rccp.scss'

const _h = new History()
const unfulfilledCoverViolation = 'UnfulfilledCoverViolation'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()

    this.isWeekly = true
    this.initialProductFilters = {}
    this.eventHandler = []
    this.errorContext = { pluginName: 'RCCP' }
    this.rccpRef = React.createRef()
    this.eventQueue = new BoundedQueue(5)
    this.requestErrorQueue = new BoundedQueue(5)
    this.connectionQueue = new BoundedQueue(5)

    this.connectApi = this.connectApi.bind(this)
    this.viewHandler = this.viewHandler.bind(this)
    this.stateHandler = this.stateHandler.bind(this)
    this.getLastRequest = this.getLastRequest.bind(this)
    this.handleDynamicGroupBy = this.handleDynamicGroupBy.bind(this)
    this.setGroupBy = this.setGroupBy.bind(this)
    this.restoreNumeralLocale = this.restoreNumeralLocale.bind(this)
    this.clearData = this.clearData.bind(this)
  }

  getInitialState = () => ({
    isLogActive: false,
    pending: true,
    isSuccess: true,
    status: null,
    lastRequest: {},
    periodDates: {},
    views: {
      productView: false,
      resourceView: false,
      scheduleResourceView: false,
      scheduleLineView: false,
      filteredProduct: false,
      newResourcePlanView: false
    },
    history: {
      state: [],
      componentState: {}
    },
    stateHandler: {
      shiftCalendar: {
        showWeeklyView: true
      },
      supplyPlan: {
        selectedGraphValue: null
      },
      resourceSchedule: {
        zoomLevel: null
      }
    },
    runInfo: {},
    filters: {},
    runId: 0,

    /// Response
    ModificationCount: 10000,
    MaxModificationCount: 10000,
    Metrics: [],

    GetRccpProductPeriodResponse: {},
    GetRccpResourcePeriodResponse: {},
    GetRccpResourceActivityResponseWPF: {},
    GetRccpResourceLineResponse: {},
    RccpFilteredProduct: {},
    GetRccpProductsResponse: {},
    GetRccpResourcePeriodHierarchyResponseWPF: {},
    GetRccpGroupBySetting: {},

    connectionInfo: {}
  })

  handleDynamicGroupBy() {
    const {
      id = 0,
      pluginStates: { data: pluginStates = [] } = [],
      params: { catalogId = '' } = {}
    } = this.props
    const { GetRccpGroupBySetting = {}, runInfo, runId } = this.state

    const groupBy =
      _.last(
        _.filter(
          pluginStates,
          (plugin) =>
            plugin.name === `GroupByState-${runInfo.scenarioId}-${runInfo.runInfoId}-${runId}`
        )
      ) || {}
    const productGroupBy =
      _.last(
        _.filter(
          pluginStates,
          (plugin) =>
            plugin.name ===
            `ProductGroupByState-${runInfo.scenarioId}-${runInfo.runInfoId}-${runId}`
        )
      ) || {}
    const { config: { state: { selectedHierarchies = [], hierarchies = [] } = {} } = {} } =
      groupBy ?? {}
    const {
      config: {
        state: {
          selectedHierarchies: selectedProductHierarchies = [],
          hierarchies: productHierarchies = []
        } = {}
      } = {}
    } = productGroupBy ?? {}
    let {
      Selected: defaultSelectedHierarchies = [],
      Hierarchies: defaultHierarchies = [],
      SelectedItemHierarchies: defaultSelectedProductHierarchies = [],
      ItemHierarchies: defaultProductHierarchies = []
    } = GetRccpGroupBySetting

    defaultSelectedHierarchies = _.map(
      defaultSelectedHierarchies,
      (defaultSelectedHierarchy) =>
        _.find(defaultHierarchies, ['Name', defaultSelectedHierarchy]) || {
          Name: defaultSelectedHierarchy,
          OverrideName: defaultSelectedHierarchy
        }
    )
    defaultHierarchies = _.reject(defaultHierarchies, (defaultHierarchy) =>
      _.some(defaultSelectedHierarchies, defaultHierarchy)
    )
    defaultSelectedProductHierarchies = _.map(
      defaultSelectedProductHierarchies,
      (defaultSelectedHierarchy) =>
        _.find(defaultProductHierarchies, ['Name', defaultSelectedHierarchy]) || {
          Name: defaultSelectedHierarchy,
          OverrideName: defaultSelectedHierarchy
        }
    )
    defaultProductHierarchies = _.reject(defaultProductHierarchies, (defaultHierarchy) =>
      _.some(defaultSelectedProductHierarchies, defaultHierarchy)
    )

    const groupByState = {
      name: `GroupByState-${runInfo.scenarioId}-${runInfo.runInfoId}-${runId}`,
      catalogId,
      pluginId: id,
      config: {
        state: {
          selectedHierarchies: defaultSelectedHierarchies,
          hierarchies: defaultHierarchies
        },
        createDate: new Date()
      }
    }

    const productGroupByState = {
      name: `ProductGroupByState-${runInfo.scenarioId}-${runInfo.runInfoId}-${runId}`,
      catalogId,
      pluginId: id,
      config: {
        state: {
          selectedHierarchies: defaultSelectedProductHierarchies,
          hierarchies: defaultProductHierarchies
        },
        createDate: new Date()
      }
    }

    this.setGroupBy({
      stateName: 'groupByState',
      groupBy,
      groupByState,
      defaultHierarchies,
      defaultSelectedHierarchies,
      hierarchies,
      selectedHierarchies
    })
    this.setGroupBy({
      stateName: 'productGroupByState',
      groupBy: productGroupBy,
      groupByState: productGroupByState,
      defaultHierarchies: defaultProductHierarchies,
      defaultSelectedHierarchies: defaultSelectedProductHierarchies,
      hierarchies: productHierarchies,
      selectedHierarchies: selectedProductHierarchies
    })
  }

  setGroupBy({
    stateName,
    groupBy,
    groupByState,
    defaultHierarchies,
    defaultSelectedHierarchies,
    hierarchies,
    selectedHierarchies
  }) {
    const { id = 0, createPluginState = () => {}, updatePluginState = () => {} } = this.props

    if (_.isEmpty(groupBy)) {
      createPluginState(`create_group_by_${id}`, id, groupByState)
      this.setState({
        [stateName]: groupByState.config.state
      })
    } else if (
      !_.isEqual(
        _.sortBy([...defaultHierarchies, ...defaultSelectedHierarchies], ['Name']),
        _.sortBy([...hierarchies, ...selectedHierarchies], ['Name'])
      )
    ) {
      const defaultPluginState = {
        ...groupBy,
        config: {
          ...groupBy.config,
          state: {
            ...groupBy.config.state,
            hierarchies: defaultHierarchies,
            selectedHierarchies: defaultSelectedHierarchies
          }
        }
      }
      this.setState({
        [stateName]: defaultPluginState.config.state
      })
      updatePluginState(`update_group_by_${id}`, groupBy.id, defaultPluginState)
    } else {
      this.setState({
        [stateName]: {
          hierarchies: defaultHierarchies,
          selectedHierarchies
        }
      })
    }
  }

  customEvent(obj) {
    return { ...obj }
  }

  handleOnline = () => {
    this.errorContext = {
      ...this.errorContext,
      connection: this.connectionQueue.enqueue({
        reconnectedAt: new Date().toString()
      }).items
    }
  }

  handleOffline = () => {
    this.errorContext = {
      ...this.errorContext,
      connection: this.connectionQueue.enqueue({
        offlineAt: new Date().toString()
      }).items
    }
  }

  componentDidMount() {
    const {
      registerEvent = () => {},
      registerMethod = () => {},
      registerAuthorizations = () => {},
      settings: { config: { topMenu = {} } = {} } = {},
      settings: { config = {} } = {},
      onToggleLogging
    } = this.props

    Mousetrap.bind(shortcuts.toggleLogging, onToggleLogging)

    const filteredTopMenu = _.filter(topMenu, (i) => i.enable)

    numeral.locale('en')
    this.props.setConfigSettings(config)

    this.rccpErrorContextListeners = new EventListenerManager(
      this.rccpRef.current,
      ['click', 'mousedown', 'keydown'],
      this.setEventInfo
    )
    window.addEventListener('online', this.handleOnline)
    window.addEventListener('offline', this.handleOffline)
    this.rccpErrorContextListeners.add()
    registerAuthorizations([
      'Production/Production Quantity',
      'Resource/Product Quantity',
      'Resource/Add Production',
      'Resource Schedule/Product Quantity',
      'Resource Schedule/Add Production',
      'Resource Schedule/Move Production',
      'Shift Calendar/Shift Pattern',
      'Resource and Production/Production Quantity',
      'Resource and Production/Resource Based Production Quantity',
      'Resource and Production/Add Production',
      'Top Menu Buttons'
    ])

    _.each(filteredTopMenu, (item) => {
      const { action } = item
      const eventName = `custom_${action}`

      let returnTypes = {}
      if (action === 'eventBtn') {
        returnTypes = {
          resourceName: PluginTypes.string,
          resourceId: PluginTypes.int,
          productName: PluginTypes.string,
          productId: PluginTypes.int,
          periodName: PluginTypes.string,
          periodId: PluginTypes.int,
          locationNames: PluginTypes.string
        }
      } else {
        returnTypes = {
          runInfo: PluginTypes.int
        }
      }

      this[eventName] = registerEvent({
        key: eventName,
        fn: this.customEvent,
        returnTypes
      })

      this.eventHandler[eventName] = this[eventName]
    })

    registerMethod({
      key: 'setFilter',
      fn: this.setFilter.bind(this),
      args: [
        { name: 'Res_ResourceGroup', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Res_Resource', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Res_MainGroup', type: PluginTypes.arrayOf(PluginTypes.string) },

        { name: 'Loc_Country', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Loc_Plant', type: PluginTypes.arrayOf(PluginTypes.string) },

        { name: 'Prod_Category', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Prod_SubCat', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Prod_Brand', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Prod_SubBrand', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Prod_FU', type: PluginTypes.arrayOf(PluginTypes.string) },
        { name: 'Prod_SKUCode', type: PluginTypes.arrayOf(PluginTypes.string) },

        { name: 'Scn_Scenario', type: PluginTypes.string },
        { name: 'Period_Start', type: PluginTypes.string },
        { name: 'Period_End', type: PluginTypes.string }
      ]
    })
    registerMethod({
      key: 'setRccpConnection',
      fn: this.setRccpConnection.bind(this),
      args: [{ name: 'DataConnectionId', type: PluginTypes.int }]
    })
  }

  componentDidUpdate(prevState) {
    const { settings: { config: { issueSettings = [] } = {} } = {} } = this.props

    const {
      GetRccpResourcePeriodHierarchyResponseWPF: prevGetRccpResourcePeriodHierarchyResponseWPF = {}
    } = prevState
    const {
      GetRccpResourcePeriodHierarchyResponseWPF = {},
      GetRccpResourcePeriodHierarchyResponseWPF: { ProductIssueNames = [] } = {}
    } = this.state

    if (
      _.isEmpty(prevGetRccpResourcePeriodHierarchyResponseWPF) &&
      !_.isEmpty(GetRccpResourcePeriodHierarchyResponseWPF)
    ) {
      const minCoverSettings = _.find(issueSettings, (issue) =>
        _.includes(issue.tableName, 'MinCover')
      )
      const { tableName = '' } = minCoverSettings || {}
      const maxCoverSettings = _.find(issueSettings, (issue) =>
        _.includes(issue.tableName, 'MaxCover')
      )
      const { tableName: maxCoverTablename = '' } = maxCoverSettings || {}

      const minCoverViolationIndex =
        _.findIndex(ProductIssueNames, (issue) => issue === tableName) || 0
      const maxCoverViolationIndex =
        _.findIndex(ProductIssueNames, (issue) => issue === maxCoverTablename) || 0
      const unfulfilledCoverViolationIndex =
        _.findIndex(ProductIssueNames, (issue) => issue === unfulfilledCoverViolation) || -1

      this.props.setCoverViolationIndexes([
        minCoverViolationIndex,
        maxCoverViolationIndex,
        unfulfilledCoverViolationIndex
      ])
    }
  }

  clearData() {
    const { isProductionInfoOpen = false, productSearchInputValue = '', dispatch } = this.props

    if (productSearchInputValue) this.props.onProductSearchInputValueChange('')
    if (isProductionInfoOpen) this.props.onToggleProductionInfo()
    dispatch({ type: 'USER_EXIT' })
  }

  componentWillUnmount() {
    const { onToggleLogging } = this.props

    this.restoreNumeralLocale()
    this.rccpErrorContextListeners.remove()
    this.clearData()
    window.removeEventListener('online', this.handleOnline)
    window.removeEventListener('offline', this.handleOffline)
    Mousetrap.unbind(shortcuts.toggleLogging, onToggleLogging)
  }

  restoreNumeralLocale() {
    const { user } = this.props
    const { access_token } = user

    const session = jwtDecode(access_token)
    const [language] = _.split(session.culture, '-')
    numeral.locale(language.toLowerCase())
  }

  getFilter(nodes) {
    const container = {
      Operator: 'OR',
      Children: []
    }

    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index]
      const parts = node.split('|||')

      const andObj = {
        Operator: 'AND',
        Children: []
      }

      for (let index = 0; index < parts.length; index++) {
        const part = parts[index]
        const splittedParts = part.split('||')
        andObj.Children.push({
          Name: splittedParts[0],
          Operator: '=',
          Values: [splittedParts[1]]
        })
      }

      container.Children.push(andObj)
    }

    return container
  }

  setFilter(obj) {
    const { onSelectSession, onSetSessions } = this.props
    const { ModificationCount, runInfo, history } = this.state

    let filters = {
      locationFilter: 'Loc_',
      resourceFilter: 'Res_',
      productFilter: 'Prod_'
    }

    filters = _.transform(
      filters,
      (filter, value, key) => {
        filter[key] = this.getFilter(
          _.transform(
            obj,
            (res, item, itemKey) => {
              if (_.startsWith(itemKey, value)) {
                res.push(...item)
              }
            },
            []
          )
        )

        return filter
      },
      {}
    )

    this.initialProductFilters = {
      ...filters
    }

    const request = {
      locationFilter: filters.locationFilter,
      resourceFilter: filters.resourceFilter,
      hierProductFilter: filters.productFilter
    }

    const { Scn_Scenario, Period_Start = '0001-01-01', Period_End = '0001-01-01' } = obj
    const [ScenarioId, RunInfoId, RunId] = _.split(Scn_Scenario, '|')

    if (_.isEmpty(obj) && !ScenarioId) {
      return
    }
    const scenarioChanged = runInfo.scenarioId !== ScenarioId && runInfo.runInfoId !== RunInfoId
    if (scenarioChanged) {
      onSelectSession({})
      onSetSessions([])
    }
    const nextRunInfo = {
      runId: 0,
      runInfoId: RunInfoId,
      scenarioId: ScenarioId
    }
    const periodDates = {
      ...(!_.isEmpty(Period_Start) && {
        StartDate: Period_Start,
        EndDate: Period_End
      })
    }
    this.errorContext = {
      ...this.errorContext,
      scenario: {
        runInfo: nextRunInfo,
        runId: RunId,
        filters,
        periodDates
      }
    }

    const view = history.state?.current?.constructorKey ?? 'newResourcePlanView'

    this.setState(
      {
        filters,
        runInfo: nextRunInfo,
        periodDates,
        runId: RunId
      },
      () => {
        this.props.setRunInfo({ scenarioId: ScenarioId, runId: RunId, runInfoId: RunInfoId })
        this.connectApi('GetRccpGroupBySetting', {
          rccpConfig: {
            EndDate: Period_End,
            StartDate: Period_Start,
            ModificationCount
          },
          runInfo: {
            runId: 0,
            runInfoId: RunInfoId,
            scenarioId: ScenarioId
          },
          runId: RunId
        }).then(() => {
          const apiKey = dataMap[view].getRequest
          this.connectApi(apiKey, {
            ...request,
            productId: -1
          }).then(() => {
            this.viewHandler(view, 'clear')
          })
        })
      }
    )
  }

  setRccpConnection(connection) {
    const {
      id,
      token,
      params: { environment },
      settings: { config: { general: { clearDataMessage } = {} } = {} } = {}
    } = this.props
    const { runId, connectionInfo } = this.state

    if (runId || connectionInfo?.runId) {
      this.clearData()
      this.setState({ ...this.getInitialState(), clearDataMessage })
    }

    request
      .post(`${API_URL}/data/${id}/invoke/SetServiceWithConnectionId`)
      .send(connection)
      .set({
        environment,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
      .then((res) => {
        if (res.body?.scenarioId) {
          this.setState({ connectionInfo: res.body })
        }
      })
      .catch(() => slvyToast.error({ message: 'Cannot update connection id.' }))
  }

  stateHandler(state, directlyInject = false, merge) {
    return new Promise((resolve) => {
      if (directlyInject) {
        this.setState(...state, () => {
          resolve()
        })
      } else if (merge) {
        this.setState({ ...state }, () => {
          resolve()
        })
      } else {
        const { stateHandler } = this.state
        const newState = { ...stateHandler, ...state }

        this.setState({ stateHandler: newState }, () => {
          resolve()
        })
      }
    })
  }

  viewHandler(stateName, historyFn) {
    const {
      settings: {
        config: { appSettings: { productionView: { gridView = 'Product' } } = {} } = {}
      } = {}
    } = this.props
    const { views = {}, history = {} } = this.state
    const newState = _.transform(
      views,
      (obj, value, key) => {
        obj[key] = _.isEqual(stateName, key)

        return obj
      },
      {}
    )

    historyFn = _h[historyFn]

    if (history && _.isFunction(historyFn)) {
      historyFn(stateName)
    }
    this.errorContext = {
      ...this.errorContext,
      view: {
        name: stateName,
        version: stateName === 'productView' ? gridView : ''
      }
    }

    const _history = _h.history()

    this.setState({
      views: { ...newState },
      history: {
        ...history,
        ...{ state: _history },
        componentState: _history.current
      }
    })
  }

  historyHandler(component, history) {
    this.setState({
      history: {
        state: _h.history(),
        componentState: _h.pushState(component, history)
      }
    })
  }

  getLastRequest() {
    const {
      lastRequest: {
        last: { method = '', request = {} }
      }
    } = this.state

    this.connectApi(method, request)
  }

  getModificationCount = (method, req, isHeaderCall) => {
    const { selectedSession } = this.props
    const { ModificationCount, MaxModificationCount, views } = this.state
    const { rccpConfig = {} } = req

    const isUndoRedo = rccpConfig.RequestType?.match(/Undo|Redo/)

    if (
      method === 'GetRccpResourcePeriodHierarchyResponseWPF' &&
      selectedSession.IsDebug &&
      isHeaderCall
    ) {
      return selectedSession.Id
    }

    if (
      (!_.isNil(rccpConfig.ModificationCount) &&
        !(ModificationCount > rccpConfig.ModificationCount) &&
        !(MaxModificationCount > ModificationCount)) ||
      isUndoRedo ||
      rccpConfig.RequestType === 'Debug'
    ) {
      return rccpConfig.ModificationCount
    }

    return ModificationCount
  }

  connectApi(method, requestObj, custom = '', isHeaderCall = false) {
    const {
      lastRequest = {},
      ModificationCount,
      MaxModificationCount,
      runInfo = {},
      periodDates,
      isGet = _.startsWith(method, 'Get'),
      runId,
      filters,
      isUpdate = _.startsWith(method, 'UpdateRccp'),
      GetRccpResourcePeriodHierarchyResponseWPF: {
        Periods = [],
        IssueNames,
        ProductIssueNames,
        MaxRccpResourcePeriod,
        MaxRccpResourcePeriodProduct,
        Resources,
        ResourcePeriods,
        ResourceProducts
      } = {},
      groupByState = {},
      productGroupByState = {},
      views,
      connectionInfo
    } = this.state
    const {
      sortConfig,
      settings: {
        config: {
          resourceAndProductPlanSettings: {
            grandTotal: { isEnabled: isGrandTotalEnabled = true } = {},
            moveMode: { isOverutilizationAllowed = false } = {}
          } = {},
          appSettings: {
            productionView: { gridView = 'Product' }
          },
          customSettings: { ignoreAuxiliary = false } = {}
        } = {}
      },
      selectedSession
    } = this.props

    const { runId: connectionRunId, ...connectionRunInfo } = connectionInfo

    const groupBy = method.includes('Hierarchy') ? groupByState : productGroupByState
    const selectedHierarchies = _.reduce(
      groupBy.selectedHierarchies,
      (acc, { Name }, index, array) => {
        if (
          index === array.length - 1 &&
          views.productView &&
          gridView === 'Product, Aggregation and Resource'
        ) {
          return acc.concat(Name, 'Resource')
        }

        return acc.concat(Name)
      },
      []
    )

    const { isQueue = false } = requestObj
    delete requestObj.isQueue

    const moveModeFilters = {
      hierLocationFilter: filters.locationFilter,
      hierResourceFilter: filters.resourceFilter
    }

    // if (requestObj.productId === -1) {
    //   delete filters['productFilter']
    // }

    if (requestObj.productId !== -1) {
      requestObj = {
        ...requestObj,
        initialProductId: requestObj.productId
      }
    }

    let {
      rccpConfig: {
        OverrideGroupByHierarchy,
        RequestType,
        StartDate: reqStartDate,
        EndDate: reqEndDate
      } = {}
    } = requestObj
    if (!RequestType) {
      if (selectedSession.IsDebug && method === 'GetRccpResourcePeriodHierarchyResponseWPF') {
        RequestType = 'Debug'
      } else {
        RequestType = isGet ? 'Get' : 'Update'
      }
    }
    if (views.productView && gridView === 'Product, Aggregation and Resource') {
      OverrideGroupByHierarchy?.push('Resource')
    }

    requestObj = {
      ...requestObj,
      ...{ runInfo },
      ...{
        rccpConfig: {
          ...requestObj.rccpConfig,
          ...(method === 'MoveResourcePeriodAndReturnHierarchyDiff'
            ? { AllowOverutilization: isOverutilizationAllowed }
            : {}),
          isGrandTotalEnabled,
          ignoreAuxiliary,
          AggFilters: {
            Period: 'Weekly'
          },
          RequestType,
          ModificationCount: this.getModificationCount(method, requestObj, isHeaderCall),
          ...(!reqStartDate || !reqEndDate
            ? periodDates
            : { StartDate: reqStartDate, EndDate: reqEndDate }),
          OverrideGroupByHierarchy: !_.isNil(OverrideGroupByHierarchy)
            ? OverrideGroupByHierarchy
            : selectedHierarchies
        }
      },
      runId: connectionRunId ?? runId,
      ...(_.includes(
        [
          'GetRccpProductPeriodResponse',
          'GetRccpResourcePeriodResponse',
          'GetRccpResourcePeriodHierarchyResponseWPF',
          'GetRccpResourceActivityResponseWPF',
          'GetRccpResourceLineResponse',
          'GetRccpResourcePeriodHierarchyResponseWPegtag',
          'MoveResourcePeriodAndReturnHierarchyDiff'
        ],
        method
      )
        ? { ...filters, hierProductFilter: filters.productFilter }
        : {}),
      ...(method === 'MoveResourcePeriodAndReturnHierarchyDiff' ? moveModeFilters : {})
    }

    if (
      !_.isEmpty(sortConfig) &&
      _.isEmpty(requestObj.rccpConfig.SortConfig) &&
      views.newResourcePlanView
    ) {
      requestObj.rccpConfig = { ...requestObj.rccpConfig, SortConfig: sortConfig }
    }

    if (requestObj?.runInfo) {
      requestObj.runInfo = { ...requestObj.runInfo, ...connectionRunInfo }
    }

    return new Promise((resolve, reject) => {
      this.setState({
        pending: true,
        isSuccess: isQueue,
        loadingType: method
      })

      this.setState({
        lastRequest: Object.assign(lastRequest, {
          [method]: { Request: requestObj },
          last: {
            method,
            request: requestObj
          }
        })
      })

      const {
        id,
        params: { environment },
        token
      } = this.props

      request
        .post(`${API_URL}/data/${id}/invoke/${method}`)
        .send(requestObj)
        .set({
          environment,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        })
        .then((res) => {
          res = JSON.parse(res.text)
          const {
            ModificationCount: ResponseModificationCount = 10000,
            MaxModificationCount: ResponseMaxModificationCount = 10000,
            Code,
            Description = ''
          } = res

          if (isGet && Code < 0) {
            getMessage(Code, Description)
          } else if (
            method === 'GetRccpProductPeriodResponse' &&
            gridView === 'Product and Aggregation'
          ) {
            this.setState({
              [method]: {
                ...res,
                IssueNames: _.cloneDeep(IssueNames),
                ProductIssueNames: _.cloneDeep(ProductIssueNames),
                MaxRccpResourcePeriod: _.isEmpty(res.MaxRccpResourcePeriod)
                  ? _.cloneDeep(MaxRccpResourcePeriod)
                  : res.MaxRccpResourcePeriod,
                MaxRccpResourcePeriodProduct: _.isEmpty(res.MaxRccpResourcePeriodProduct)
                  ? _.cloneDeep(MaxRccpResourcePeriodProduct)
                  : res.MaxRccpResourcePeriodProduct,
                Resources: _.isEmpty(res.Resources) ? _.cloneDeep(Resources) : res.Resources,
                ResourcePeriods: _.isEmpty(res.ResourcePeriods)
                  ? _.cloneDeep(ResourcePeriods)
                  : res.ResourcePeriods,
                ResourceProducts: _.isEmpty(res.ResourceProducts)
                  ? _.cloneDeep(ResourceProducts)
                  : res.ResourceProducts
              }
            })
          } else if (
            method === 'GetRccpProductPeriodResponse' &&
            gridView === 'Product, Aggregation and Resource'
          ) {
            this.setState({
              [method]: {
                ...res,
                IssueNames: _.cloneDeep(IssueNames),
                ProductIssueNames: _.cloneDeep(ProductIssueNames),
                ResourceProducts: _.isEmpty(res.ResourceProducts)
                  ? _.cloneDeep(ResourceProducts)
                  : res.ResourceProducts
              }
            })
          } else {
            this.setState({ [_.isEmpty(custom) ? method : custom]: res })
          }

          if (method === 'GetRccpGroupBySetting') this.handleDynamicGroupBy()

          this.setState({
            pending: false,
            isSuccess: true,
            ModificationCount: ResponseModificationCount,
            MaxModificationCount: ResponseMaxModificationCount
          })

          resolve(res)
        })
        .catch((err) => {
          this.setState({
            pending: false,
            isSuccess: true
          })

          this.errorContext = {
            ...this.errorContext,
            requestErrors: this.requestErrorQueue.enqueue(err?.response?.body).items
          }

          reject(err)
        })
    })
  }

  setEventInfo = (event) => {
    this.errorContext = {
      ...this.errorContext,
      events: this.eventQueue.enqueue({
        type: event.type,
        target: {
          className: event.target.className
        }
      }).items
    }
  }

  render() {
    const {
      GetRccpProductPeriodResponse,
      GetRccpResourcePeriodResponse = {},
      GetRccpResourceActivityResponseWPF = {},
      GetRccpResourceLineResponse = {},
      GetRccpProductsResponse = {},
      RccpFilteredProduct = {},
      GetRccpResourcePeriodHierarchyResponseWPF = {},
      GetRccpResourcePeriodHierarchyResponseWPF: { Metrics = [], Periods = [] },
      GetRccpGroupBySetting = {},
      ModificationCount,
      MaxModificationCount,
      isSuccess,
      lastRequest,
      views,
      periodDates,
      views: {
        productView,
        resourceView,
        scheduleResourceView,
        scheduleLineView,
        filteredProduct,
        newResourcePlanView
      },
      stateHandler,
      history,
      runInfo,
      filters,
      runId,
      productGroupByState,
      groupByState,
      clearDataMessage
    } = this.state

    const {
      size = {},
      settings: { config = {} },
      settings: {
        config: {
          appSettings: { productionView: { gridView = 'Product' } = {} },
          customSettings: { totalUtilization = {} } = {}
        }
      },
      clickedProductInfo,
      isAllowed = () => {},
      isMaximized
    } = this.props

    const settings = {
      ...config,
      size,
      stateHandler,
      history,
      modificationCount: ModificationCount,
      maxModificationCount: MaxModificationCount,
      metrics: Metrics,
      views,
      runInfo,
      filters,
      isMaximized,
      groupByState,
      productGroupByState,
      totalUtilization
    }
    this.errorContext = {
      ...this.errorContext,
      clickedProductInfo
    }

    CustomConsole.log({ clickedCell: clickedProductInfo })

    return (
      <ErrorBoundary errorContext={this.errorContext}>
        <div ref={this.rccpRef} className="rccp-plugin">
          {!isSuccess && (
            <div className="rccp-loading">
              {' '}
              <i className="slvy-ui-icon-cog-outline" /> Loading{' '}
            </div>
          )}

          {!_.isEmpty(Metrics) && (
            <Header
              {...this.props}
              GetRccpGroupBySetting={GetRccpGroupBySetting}
              connectApi={this.connectApi}
              eventHandler={this.eventHandler}
              firstPeriod={Periods[0]}
              initialProductFilters={this.initialProductFilters}
              lastRequest={lastRequest}
              maxModificationCount={MaxModificationCount}
              modificationCount={ModificationCount}
              periodDates={periodDates}
              settings={settings}
              stateHandler={this.stateHandler}
              viewHandler={this.viewHandler}
            />
          )}
          {productView && gridView === 'Product' && !_.isEmpty(GetRccpProductPeriodResponse) && (
            <Production
              connectApi={this.connectApi}
              getRccpProductPeriodResponse={GetRccpProductPeriodResponse}
              historyHandler={this.historyHandler.bind(this, 'TableProduct')}
              isAllowed={isAllowed}
              lastRequest={lastRequest}
              settings={settings}
              stateHandler={this.stateHandler}
              viewHandler={this.viewHandler}
            />
          )}

          {filteredProduct && gridView === 'Product' && !_.isEmpty(RccpFilteredProduct) && (
            <Production
              connectApi={this.connectApi}
              getRccpProductPeriodResponse={RccpFilteredProduct}
              historyHandler={this.historyHandler.bind(this, 'TableFilteredProduct')}
              isAllowed={isAllowed}
              lastRequest={lastRequest}
              settings={{ ...settings, ...{ isFiltered: true } }}
              viewHandler={this.viewHandler}
            />
          )}

          {productView &&
            gridView === 'Product and Aggregation' &&
            !_.isEmpty(GetRccpProductPeriodResponse) && (
              <ProductionV2
                GetRccpResourcePeriodHierarchyResponse={GetRccpProductPeriodResponse}
                connectApi={this.connectApi}
                getRccpProductPeriodResponse={GetRccpProductPeriodResponse}
                getRccpProductsResponse={GetRccpProductsResponse}
                historyHandler={this.historyHandler.bind(this, 'TableProduct')}
                initialFilters={this.initialProductFilters}
                isAllowed={isAllowed}
                lastRequest={lastRequest}
                periodDates={periodDates}
                settings={settings}
                stateHandler={this.stateHandler}
                viewHandler={this.viewHandler}
              />
            )}

          {productView &&
            gridView === 'Product, Aggregation and Resource' &&
            !_.isEmpty(GetRccpProductPeriodResponse) && (
              <ProductionV3
                GetRccpResourcePeriodHierarchyResponse={GetRccpProductPeriodResponse}
                connectApi={this.connectApi}
                getRccpProductPeriodResponse={GetRccpProductPeriodResponse}
                getRccpProductsResponse={GetRccpProductsResponse}
                historyHandler={this.historyHandler.bind(this, 'TableProduct')}
                initialFilters={this.initialProductFilters}
                isAllowed={isAllowed}
                lastRequest={lastRequest}
                periodDates={periodDates}
                settings={settings}
                stateHandler={this.stateHandler}
                viewHandler={this.viewHandler}
              />
            )}

          {resourceView && !_.isEmpty(GetRccpResourcePeriodResponse) && (
            <Resource
              connectApi={this.connectApi}
              getRccpProductsResponse={GetRccpProductsResponse}
              getRccpResourcePeriodResponse={GetRccpResourcePeriodResponse}
              historyHandler={this.historyHandler.bind(this, 'TableResource')}
              isAllowed={isAllowed}
              lastRequest={lastRequest}
              settings={settings}
              stateHandler={this.stateHandler}
              viewHandler={this.viewHandler}
            />
          )}

          {scheduleResourceView && !_.isEmpty(GetRccpResourceActivityResponseWPF) && (
            <ResourceSchedule
              connectApi={this.connectApi}
              getRccpProductsResponse={GetRccpProductsResponse}
              lastRequest={lastRequest}
              stateHandler={this.stateHandler}
              /// use size in settings
              size={size}
              viewHandler={this.viewHandler}
              historyHandler={this.historyHandler.bind(this, 'ScheduleResource')}
              settings={settings}
              GetRccpResourceActivityResponseWPF={GetRccpResourceActivityResponseWPF}
              isAllowed={isAllowed}
            />
          )}

          {scheduleLineView && !_.isEmpty(GetRccpResourceLineResponse) && (
            <ShiftCalendar
              connectApi={this.connectApi}
              getRccpResourceLineResponse={GetRccpResourceLineResponse}
              historyHandler={this.historyHandler.bind(this, 'ScheduleLine')}
              isAllowed={isAllowed}
              /// use size in settings
              isWeekly={this.isWeekly}
              lastRequest={lastRequest}
              periodsLength={Periods.length}
              stateHandler={this.stateHandler}
              settings={settings}
              size={size}
              viewHandler={this.viewHandler}
            />
          )}

          {newResourcePlanView && !_.isEmpty(GetRccpResourcePeriodHierarchyResponseWPF) && (
            <Supply
              GetRccpResourcePeriodHierarchyResponse={GetRccpResourcePeriodHierarchyResponseWPF}
              connectApi={this.connectApi}
              getRccpProductsResponse={GetRccpProductsResponse}
              historyHandler={this.historyHandler.bind(this, 'NewResourcePlanView')}
              initialFilters={this.initialProductFilters}
              isAllowed={isAllowed}
              lastRequest={lastRequest}
              periodDates={periodDates}
              settings={settings}
              stateHandler={this.stateHandler}
              viewHandler={this.viewHandler}
            />
          )}

          {_.isEmpty(GetRccpResourcePeriodHierarchyResponseWPF) && (
            <div className="no-data-info">
              <span>{clearDataMessage ?? 'No Data to Show'}</span>
            </div>
          )}
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sortConfig: state.resourcePlan.sortConfig,
    clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
    productSearchInputValue: state.clickedProductInfo.productSearchInputValue,
    isProductionInfoOpen: state.clickedProductInfo.isProductionInfoOpen,
    selectedSession: state.resourcePlan.selectedSession,
    isLoggingEnabled: state.logging.isLoggingEnabled
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      dispatch,
      onProductSearchInputValueChange,
      onToggleProductionInfo,
      setConfigSettings,
      resetHierarchy,
      setRunInfo,
      setCoverViolationIndexes,
      onSelectSession: selectSession,
      onSetSessions: setSessions,
      onToggleLogging: toggleLogging
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
