export default `({
    autoLoad: false,
    autoSync: false,
    sorters: [{
            property: 'EndDate',
            direction: 'DESC'
        },
        {
            property: 'StartDate',
            direction: 'DESC'
        }
    ],
    fields: [{
            name: 'MaterialCode',
            type: 'string'
        },
        {
            name: 'StartDate',
            type: 'date',
            dateFormat: Spui.Global.dateFormatLong
        },
        {
            name: 'EndDate',
            type: 'date',
            dateFormat: Spui.Global.dateFormatLong
        },
        {
            name: 'IsActive',
            type: 'int'
        },
        {
            name: 'DurationInWeek',
            type: 'int'
        },
        {
            name: 'DurationInDays',
            type: 'int'
        },
        {
            name: 'TotalInvoicedQuantity',
            type: 'number'
        },
        // {
        //     name: 'AverageSalesQuantity',
        //     type: 'number'
        // },
        {
            name: 'TotalNetValueoftheSalesOrder',
            type: 'number'
        },
        {
            name: 'NumberOfDistinctDC',
            type: 'int'
        },
        {
            name: 'NumberOfDistinctStore',
            type: 'int'
        },
        {
            name: 'Price',
            type: 'number'
        },
        {
            name: 'TotalInvoicedQuantityDay1',
            type: 'number'
        },
        {
            name: 'TotalInvoicedQuantityDay2',
            type: 'number'
        },
        {
            name: 'TotalInvoicedQuantityDay3',
            type: 'number'
        },
        {
            name: 'TotalInvoicedQuantityDay4',
            type: 'number'
        },
        {
            name: 'TotalInvoicedQuantityDay5',
            type: 'number'
        },
        {
            name: 'TotalInvoicedQuantityDay6',
            type: 'number'
        },
        {
            name: 'TotalInvoicedQuantityDay7',
            type: 'number'
        },
        {
            name: 'TotalNetValueoftheSalesOrderDay1',
            type: 'number'
        },
        {
            name: 'TotalNetValueoftheSalesOrderDay2',
            type: 'number'
        },
        {
            name: 'TotalNetValueoftheSalesOrderDay3',
            type: 'number'
        },
        {
            name: 'TotalNetValueoftheSalesOrderDay4',
            type: 'number'
        },
        {
            name: 'TotalNetValueoftheSalesOrderDay5',
            type: 'number'
        },
        {
            name: 'TotalNetValueoftheSalesOrderDay6',
            type: 'number'
        },
        {
            name: 'TotalNetValueoftheSalesOrderDay7',
            type: 'number'
        },
        {
            name: 'Pareto80Day',
            type: 'number'
        },
        {
            name: 'ParetoNetValueoftheSalesOrder',
            type: 'number'
        },
        {
            name: 'ParetoTotalInvoicedQuantity',
            type: 'number'
        },
        {
            name: 'ParetoTotalInvoicedQuantityPercent',
            type: 'number'
        }
    ],
    proxy: {
        type: 'ajax',
        actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
        },
        url: Spui.Global.getApiUrl() + 'spui/spotHistory',
    },
})
`
