import { fetchCollection, fetchOne, updateOne, deleteRecord, fetchRecord } from '../crudoptV3'

const model = 'catalog'

export const getCatalogs = () => {
  return fetchCollection(model, '/catalog')
}

export const getCatalog = (id) => {
  return fetchOne(model, `/catalog/${id}`, {}, { collectionKey: { path: '/catalog' }, key: id })
}

export const updateCatalog = (catalogId, data, operationKey) => {
  return updateOne(
    model,
    `catalog/${catalogId}`,
    {
      data
    },
    {
      key: catalogId,
      operationKey
    }
  )
}

export const createCatalog = (data = {}, operationKey) => {
  return fetchRecord(
    model,
    '/catalog',
    { data },
    { collectionKey: { path: '/catalog' }, operationKey }
  )
}

export const deleteCatalog = (id, operationKey) => {
  return deleteRecord(
    model,
    `/catalog/${id}`,
    {},
    {
      key: id,
      operationKey,
      collectionKey: { path: '/catalog' },
      getRowKey: () => id
    }
  )
}

export const forceDeleteCatalog = (id, operationKey) => {
  return deleteRecord(model, `/catalog/${id}/force`, {}, { key: id, operationKey })
}

export const archiveCatalog = (id, deleteCatalog) =>
  updateOne(model, `/catalog/${id}/archive`, { data: { deleteCatalog } })
