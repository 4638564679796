import _ from 'lodash'
import jQuery from 'jquery'

const $each = function (obj, callback) {
  if (!obj || typeof obj !== 'object') return
  let i
  if (
    Array.isArray(obj) ||
    (typeof obj.length === 'number' && obj.length > 0 && obj.length - 1 in obj)
  ) {
    for (i = 0; i < obj.length; i++) {
      if (callback(i, obj[i]) === false) return
    }
  } else if (Object.keys) {
    const keys = Object.keys(obj)
    for (i = 0; i < keys.length; i++) {
      if (callback(keys[i], obj[keys[i]]) === false) return
    }
  } else {
    for (i in obj) {
      if (!obj.hasOwnProperty(i)) continue
      if (callback(i, obj[i]) === false) return
    }
  }
}

window.JSONEditor.defaults.editors.SolvoyoObjectEditor =
  window.JSONEditor.defaults.editors.object.extend({
    postBuild() {
      const self = this
      this._super()

      if (self.schema.format === 'objectTab') {
        const uniqueKey = new Date().getUTCMilliseconds()

        // get content
        const content = this.container.getElementsByClassName('well')[0]

        // remove content
        this.container.removeChild(content)

        // new content
        const newContent = document.createElement('div')
        newContent.classList.add('rows')
        newContent.innerHTML =
          '<ul class="nav nav-pills nav-stacked col-md-3" role="tablist"></ul><div class="tab-content col-md-9"></div>'

        const tabHeader = newContent.getElementsByTagName('ul')[0]
        const tabContents = newContent.getElementsByTagName('div')[0]
        let i = 0
        for (const key in this.editors) {
          const childEditor = this.editors[key].container
          childEditor.parentElement.removeChild(childEditor)

          var childEditorTitleText

          const childEditorTitle = childEditor.getElementsByTagName('h3')[0]
          childEditorTitle.parentElement.removeChild(childEditorTitle)
          const spans = childEditorTitle.getElementsByTagName('span')
          if (
            (!childEditor.style.display || childEditor.style.display !== 'none') &&
            spans.length > 0
          ) {
            childEditorTitleText = spans[0].innerHTML

            // create tab
            const tab = document.createElement('li')
            tab.dataset.tabId = `tab${uniqueKey}${i}`
            tab.textContent = childEditorTitleText
            tabHeader.appendChild(tab)

            // create tab content
            const tabContent = document.createElement('div')
            tabContent.classList.add('tab-pane')
            tabContent.id = `tab${uniqueKey}${i}`
            tabContent.appendChild(childEditor)
            tabContents.appendChild(tabContent)

            if (i === 0) {
              tab.classList.add('active')
              tabContent.classList.add('active')
            }
            i++
          }
        }

        // remove toggle button from the top element
        this.toggle_button.parentElement.removeChild(this.toggle_button)

        this.container.appendChild(newContent)

        tabHeader.addEventListener(
          'click',
          ({
            target: {
              dataset: { tabId }
            }
          }) => {
            if (tabId) {
              Array.from(tabHeader.childNodes).forEach((childNode, index) => {
                if (childNode.dataset.tabId === tabId) {
                  childNode.classList.add('active')
                  tabContents.childNodes[index].classList.add('active')
                } else {
                  childNode.classList.remove('active')
                  tabContents.childNodes[index].classList.remove('active')
                }
              })
            }
          }
        )
      }

      // create an additional div with subProperty class,
      // it will be used while styling.
      // do not create the additional div when in the table structure
      if (this.container.nodeName !== 'TR') {
        const subProperty = document.createElement('div')
        subProperty.classList.add('subProperty')
        while (this.container.firstChild) {
          const child = this.container.firstChild
          this.container.removeChild(this.container.firstChild)
          subProperty.appendChild(child)
        }
        this.container.appendChild(subProperty)
      }
    },

    layoutEditors() {
      // Assign property orders to properties
      // If nothing is specified use the given property order
      // If the SortProperties is equal key, order by property name

      let editorNames = Object.keys(this.editors)
      if (this.options.sortProperties === 'key') {
        editorNames = editorNames.sort()
      }

      const editorsWithPropertyOrder = _.filter(this.editors, (editor) => {
        return editor.schema.propertyOrder
      })

      const occupiedPropertyOrders = _.map(editorsWithPropertyOrder, (editor) => {
        return editor.schema.propertyOrder
      })

      let propertyOrder = 0
      for (const key of editorNames) {
        propertyOrder = this.getNextPropertyOrder(occupiedPropertyOrders, propertyOrder)
        const { schema } = this.editors[key]
        if (!schema.propertyOrder) {
          schema.propertyOrder = propertyOrder
        }
      }

      this._super()

      // Separate properties into two sections: Basic & Advanced properties
      // If the container object has the option splitProperties equals true
      if (this.options.splitProperties && this.options.path) {
        const { path } = this.options
        let convertedPath
        if (path) {
          convertedPath = path.split('.').join('_')
        }

        const sortedEditors = _.sortBy(this.editors, (editor) => {
          return editor.schema.propertyOrder
        })

        const rowContainer = this.row_container.firstChild

        // Create basic property container
        const basicPropertyContainer = document.createElement('div')
        basicPropertyContainer.classList.add('basicProperties')

        // Create advanced property container
        const advancedPropertyContainer = document.createElement('div')
        advancedPropertyContainer.classList.add('advancedProperties')
        advancedPropertyContainer.classList.add(convertedPath)

        // Create show advanced link
        const showAdvancedLinkDiv = document.createElement('div')
        showAdvancedLinkDiv.className = 'showDiv'

        const showAdvancedLink = document.createElement('a')
        showAdvancedLink.text = 'Show advanced options'
        showAdvancedLink.classList.add('showButton')
        showAdvancedLink.classList.add(convertedPath)
        showAdvancedLinkDiv.appendChild(showAdvancedLink)

        showAdvancedLink.addEventListener('click', function (e) {
          window.JSONEditor.defaults.showHideAdvancedProps(true, path)
          jQuery(`.advancedProperties.${convertedPath}`).show('slow')
          jQuery(`.showButton.${convertedPath}`).hide()
          jQuery(`.hideButton.${convertedPath}`).show()
          jQuery(`.hr-line-dashed.${convertedPath}`).show()
        })

        // Create seperator
        const seperator = document.createElement('div')
        seperator.classList.add('hr-line-dashed')
        seperator.classList.add(convertedPath)

        // Create hide advanced link
        const hideAdvancedLinkDiv = document.createElement('div')
        hideAdvancedLinkDiv.className = 'hideDiv'

        const hideAdvancedLink = document.createElement('a')
        hideAdvancedLink.text = 'Hide advanced options'
        hideAdvancedLink.classList.add('hideButton')
        hideAdvancedLink.classList.add(convertedPath)
        hideAdvancedLinkDiv.appendChild(hideAdvancedLink)

        hideAdvancedLink.addEventListener('click', function (e) {
          window.JSONEditor.defaults.showHideAdvancedProps(false, path)
          jQuery(`.advancedProperties.${convertedPath}`).hide('slow')
          jQuery(`.showButton.${convertedPath}`).show()
          jQuery(`.hideButton.${convertedPath}`).hide()
          jQuery(`.hr-line-dashed.${convertedPath}`).hide()
        })

        if (window.JSONEditor.defaults.showAdvanvedProperties[path]) {
          advancedPropertyContainer.style.display = 'block'
          showAdvancedLink.style.display = 'none'
          hideAdvancedLink.style.display = 'block'
          seperator.style.display = 'block'
        } else {
          advancedPropertyContainer.style.display = 'none'
          showAdvancedLink.style.display = 'block'
          hideAdvancedLink.style.display = 'none'
          seperator.style.display = 'none'
        }

        editorNames = Object.keys(sortedEditors)
        for (let i = 0; i < sortedEditors.length; i++) {
          const editor = sortedEditors[i]

          const childRow = editor.container.parentElement

          rowContainer.removeChild(childRow)

          if (editor.schema && editor.schema.options && editor.schema.options.basic) {
            basicPropertyContainer.appendChild(childRow)
          } else {
            advancedPropertyContainer.appendChild(childRow)
          }
        }

        rowContainer.appendChild(basicPropertyContainer)
        rowContainer.appendChild(showAdvancedLinkDiv)
        rowContainer.appendChild(hideAdvancedLinkDiv)
        rowContainer.appendChild(seperator)
        rowContainer.appendChild(advancedPropertyContainer)
      }
    },

    getNextPropertyOrder(occupiedPropertyOrders, lastPropertyOrder) {
      lastPropertyOrder++
      if (_.indexOf(occupiedPropertyOrders, lastPropertyOrder) > -1) {
        return this.getNextPropertyOrder(occupiedPropertyOrders, lastPropertyOrder++)
      }
      return lastPropertyOrder
    },

    setValue(value, initial) {
      const self = this
      value = value || {}

      if (typeof value !== 'object' || Array.isArray(value)) value = {}

      // First, set the values for all of the defined properties
      $each(this.cached_editors, function (i, editor) {
        // Value explicitly set

        if (typeof value[i] !== 'undefined') {
          self.addObjectProperty(i)
          editor.setValue(value[i], initial)
        } else if (!initial && !self.isRequired(editor)) {
          // Otherwise, remove value unless this is the initial set or it's required
          self.removeObjectProperty(i)
        } else {
          // Otherwise, set the value to the default
          editor.setValue(editor.getDefault(), initial)
        }
      })

      $each(value, function (i, val) {
        if (!self.cached_editors[i]) {
          // eslint-disable-next-line eqeqeq
          if (typeof val == 'object') {
            return
          }

          self.addObjectProperty(i)
          if (self.editors[i]) self.editors[i].setValue(val, initial)
        }
      })

      this.refreshValue()
      if (!(self.schema.format === 'objectTab')) {
        this.layoutEditors()
      }
      this.onChange()
    }
  })

export default {
  name: 'SolvoyoObjectEditor',
  format: 'SolvoyoObjectEditor'
}
