import React, { Component } from 'react'
import _ from 'lodash'
import { FormControl, FormGroup, Form, Button, Row, Col } from 'react-bootstrap'
import { slvyToast, SlvyFormInput, SlvyFormSelect } from '../../../components'
import SearchList from './SearchList'
import { MESSAGES } from '../messages'
import '../index.scss'

const slvyFormInputColumns = { input: 10, label: 2 }

export default class PantoneForm extends Component {
  constructor(props) {
    super(props)
    this.handleSearchColor = this.handleSearchColor.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleChangeMaterialDetail = this.handleChangeMaterialDetail.bind(this)
    this.handleSearchEdit = this.handleSearchEdit.bind(this)
    this.handleChangePlanningColor = this.handleChangePlanningColor.bind(this)
    this.getSelectValue = this.getSelectValue.bind(this)

    this.state = {
      searchText: '',
      pending: false,
      pendingSearch: false,
      searchResult: [],
      noResult: false,
      planningColors: [],
      form: {
        MaterialDetail: '',
        PantoneCode: '',
        Name: '',
        NameTR: '',
        HEX: '',
        Red: '',
        Green: '',
        Blue: '',
        MasterCode: null,
        ColorId: null
      }
    }
  }

  componentDidMount() {
    const {
      props: { itemToEdit, modalShowColorDetails },
      state: { form }
    } = this

    const getPlanningColors = () => {
      if (modalShowColorDetails) {
        this.getPlanningColors()
      }
    }

    if (itemToEdit) {
      this.setState(
        {
          form: {
            ...form,
            ...itemToEdit
          }
        },
        () => {
          getPlanningColors()
        }
      )
    } else {
      getPlanningColors()
    }
  }

  getPlanningColors() {
    const {
      props: {
        pluginId,
        designId,
        isMainColor,
        materialTypeID = 0,
        selectedMainColorId: mainColorId,
        client
      },
      state: {
        form: { ColorId: colorId = null, MasterCode = null }
      }
    } = this

    const requestUrl = isMainColor ? 'GetColorsCodes' : 'GetColorsCodesForDesignAttribute'

    let requestData = {
      designId,
      colorId
    }

    if (!isMainColor) {
      // for design attribute
      requestData = {
        ...requestData,
        mainColorId,
        materialTypeID
      }
    }

    client
      .post(`/data/${pluginId}/Invoke/${requestUrl}`, {
        data: requestData
      })
      .then((result) => {
        const planningColors = !_.isEmpty(result) ? result : []

        if (MasterCode === null || MasterCode === '') {
          planningColors.unshift({
            MasterCode: null,
            MasterCodeDescription: null
          })
        }

        this.setState({
          planningColors
        })
      })
      .catch(() => {
        slvyToast.error({
          message: MESSAGES.an_unknown_error_occurred_when_getting_planning_colors,
          title: MESSAGES.error
        })
      })
  }

  handleChange(event) {
    const {
      target: { value: searchText = '' }
    } = event
    this.setState({ searchText }, () => {
      searchText.length > 2 && this.handleSearchColor()
    })
  }

  handleKeyPress(event) {
    event.key === 'Enter' && this.handleSearchColor()
  }

  handleSearchColor() {
    const {
      pluginId,
      designId,
      isMainColor,
      materialTypeID = 0,
      selectedMainColorId: mainColorId,
      client
    } = this.props
    const { searchText } = this.state

    if (!_.isEmpty(searchText)) {
      const requestUrl = isMainColor ? 'SearchColor' : 'SearchColorForDesignAttribute'

      let requestData = {
        designId,
        searchText
      }

      if (!isMainColor) {
        // for design attribute
        requestData = {
          ...requestData,
          mainColorId,
          materialTypeID
        }
      }

      this.setState({
        pending: true,
        pendingSearch: false,
        noResult: false
      })

      client
        .post(`/data/${pluginId}/Invoke/${requestUrl}`, {
          data: requestData
        })
        .then((result) => {
          if (!_.isEmpty(result)) {
            this.setState({
              searchResult: result,
              pendingSearch: true,
              noResult: false
            })
          } else {
            this.setState({
              noResult: true,
              searchResult: []
            })
          }
        })
        .catch(() =>
          slvyToast.error({
            message: MESSAGES.an_unknown_error_occurred,
            title: MESSAGES.error
          })
        )
    } else {
      slvyToast.warning({
        message: MESSAGES.please_enter_a_pantone_code,
        title: MESSAGES.warning
      })
    }
  }

  handleChangeMaterialDetail(MaterialDetail) {
    this.setState((state) => ({
      form: { ...state.form, MaterialDetail }
    }))
  }

  getSelectValue(selected) {
    const { planningColors } = this.state
    let value = null
    let label = null
    let Name = null
    let NameTR = null
    let ColorId = null
    if (selected && planningColors.length) {
      value = selected.value
      if (value) {
        const found = planningColors.find(({ MasterCode }) => MasterCode === value)
        const foundColor = found || { Name: null, NameTR: null, ColorId: null }
        Name = foundColor.Name
        NameTR = foundColor.NameTR
        ColorId = foundColor.ColorId
        label = this.getColorLabel({ MasterCode: value, Name, NameTR })
      }
    }

    return {
      value,
      label,
      Name,
      NameTR,
      ColorId
    }
  }

  handleChangePlanningColor(selected) {
    const {
      value: MasterCode = null,
      Name = '',
      NameTR = '',
      ColorId = null
    } = this.getSelectValue(selected)

    this.setState(
      (state) => ({
        form: {
          ...state.form,
          MasterCode,
          Name,
          NameTR,
          ColorId
        }
      }),
      () => {
        this.getColorById(ColorId)
      }
    )
  }

  getColorById() {
    const {
      props: { pluginId, client },
      state: {
        form: {
          MasterCode,
          Name,
          NameTR,
          ColorId: colorId = null,
          MainColorId = null,
          AttributeId = null,
          MaterialDetail = ''
        }
      }
    } = this
    client
      .post(`/data/${pluginId}/Invoke/GetColorById`, {
        data: {
          colorId
        }
      })
      .then((result) => {
        const activeForm = result
        if (activeForm) {
          this.setState({
            form: {
              ...activeForm,
              MasterCode,
              Name,
              NameTR,
              ColorId: colorId,
              MainColorId,
              AttributeId,
              MaterialDetail
            }
          })
        }
      })
      .catch(() =>
        slvyToast.warning({
          message: MESSAGES.an_unknown_error_occurred,
          title: MESSAGES.there_is_a_problem
        })
      )
  }

  updateDesignOption() {
    const {
      props: {
        pluginId,
        designId,
        handleAfterColorUpdate = () => {},
        onClose = () => {},
        client = () => {}
      },
      state: { form: { MainColorId: mainColorId = 0, ColorId: colorId = null } = {} }
    } = this

    client
      .post(`/data/${pluginId}/Invoke/UpdateDesignOption`, {
        data: {
          designId,
          mainColorId,
          colorId
        }
      })
      .then((result) => {
        handleAfterColorUpdate(result)
        onClose()
      })
      .catch(() =>
        slvyToast.warning({
          message: MESSAGES.an_unknown_error_occurred,
          title: MESSAGES.there_is_a_problem
        })
      )
  }

  updateDesignOptionAttribute() {
    const {
      props: {
        pluginId,
        designId,
        handleAfterColorUpdate = () => {},
        onClose = () => {},
        client = () => {}
      },
      state: {
        form: {
          AttributeId: attributeId,
          ColorId: colorId = null,
          MaterialDetail: materialDetail = ''
        } = {}
      }
    } = this

    client
      .post(`/data/${pluginId}/Invoke/UpdateDesignOptionAttribute`, {
        data: {
          designId,
          attributeId,
          colorId,
          materialDetail
        }
      })
      .then((result) => {
        handleAfterColorUpdate(result)
        onClose()
      })
      .catch(() =>
        slvyToast.warning({
          message: MESSAGES.an_unknown_error_occurred,
          title: MESSAGES.there_is_a_problem
        })
      )
  }

  addDesignOption(colorId, onAddCallback = () => {}) {
    const {
      pluginId,
      designId,
      handleAfterColorAdd = () => {},
      onClose = () => {},
      client = () => {}
    } = this.props

    client
      .post(`/data/${pluginId}/Invoke/AddDesignOption`, {
        data: { colorId, designId }
      })
      .then((result) => {
        handleAfterColorAdd(result, true)
        onAddCallback(true)
        onClose()
      })
      .catch(() => {
        slvyToast.warning({
          message: MESSAGES.an_unknown_error_occurred,
          title: MESSAGES.there_is_a_problem
        })
        onAddCallback(false)
      })
  }

  addColorToDesignOptionAttribute(colorId, onAddCallback = () => {}) {
    const {
      pluginId,
      designId,
      selectedMainColorId: mainColorId,
      materialTypeID = 0,
      handleAfterColorAdd = () => {},
      onClose = () => {},
      client = () => {}
    } = this.props

    client
      .post(`/data/${pluginId}/Invoke/AddColorToDesignOptionAttribute`, {
        data: {
          colorId,
          designId,
          mainColorId,
          materialTypeID
        }
      })
      .then((result) => {
        handleAfterColorAdd(result, false)
        onAddCallback(true)
        onClose()
      })
      .catch(() => {
        onAddCallback(false)
        slvyToast.warning({
          message: MESSAGES.an_unknown_error_occurred,
          title: MESSAGES.there_is_a_problem
        })
      })
  }

  handleSearchEdit(colorId, callback = () => {}) {
    const { isMainColor } = this.props
    if (isMainColor) {
      this.addDesignOption(colorId, callback)
    } else {
      this.addColorToDesignOptionAttribute(colorId, callback)
    }
  }

  getColorLabel({ MasterCode, Name, NameTR }) {
    const _Name = Name || ''
    const _NameTR = NameTR ? `/${NameTR}` : ''
    return `${MasterCode} - ${_Name}${_NameTR}`
  }

  render() {
    let {
      props: { modalShowColorDetails, modalShowColorSearch, isMainColor, columnHeightValue },
      state: {
        form: {
          MaterialDetail,
          PantoneCode,
          Name = '',
          NameTR = '',
          HEX,
          Red,
          Green,
          Blue,
          MasterCode
        } = {},
        form,
        pending,
        searchText,
        planningColors,
        searchResult,
        pendingSearch,
        noResult
      }
    } = this

    Name = Name || ''
    NameTR = NameTR || ''
    MaterialDetail = MaterialDetail || ''

    let planningColorsValueOption = {}
    let planningColorsMapped = []

    if (planningColors.length) {
      planningColorsValueOption = this.getSelectValue({ value: MasterCode })
      planningColorsMapped = _.map(planningColors, (color) => ({
        ...color,
        value: color.MasterCode,
        label: color.MasterCode === '' || color.MasterCode === null ? '' : this.getColorLabel(color)
      }))
    }

    const isNoColor = !!(
      _.isNull(PantoneCode) ||
      _.isNull(Red) ||
      _.isNull(Green) ||
      _.isNull(Blue)
    )

    return (
      <div className="pantone-form">
        {modalShowColorSearch && (
          <div className="row mb-3">
            <div className="col">
              <FormControl
                type="text"
                value={searchText}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
            </div>
            <div className="col-auto ps-0">
              <Button className="btn-block" variant="success" onClick={this.handleSearchColor}>
                {MESSAGES.find}
              </Button>
            </div>
          </div>
        )}

        {form && modalShowColorDetails && (
          <form className="pantone-from common-form top-0 bottom-0 start-0 end-0 bg-white overflow-x-auto px-3 pt-1">
            {planningColors.length ? (
              <SlvyFormSelect
                placeholder=""
                data={planningColorsMapped}
                label={MESSAGES.code_name}
                labelClass="justify-content-end"
                value={planningColorsValueOption?.value ?? null}
                onSelect={(val) => {
                  this.handleChangePlanningColor(
                    planningColorsMapped.find((item) => item.value === val)
                  )
                }}
              />
            ) : null}
            {!isMainColor && (
              <SlvyFormInput
                labelClass="justify-content-end"
                columns={slvyFormInputColumns}
                label={MESSAGES.material_code_detail}
                value={MaterialDetail}
                onChange={this.handleChangeMaterialDetail}
              />
            )}
            <FormGroup
              as={Row}
              className="form-group d-flex justify-content-between align-items-center mb-3"
            >
              <Form.Label column sm={2} className="d-flex align-items-center justify-content-end">
                {MESSAGES.color_preview}
              </Form.Label>
              <Col sm={10}>
                <FormControl
                  disabled
                  type="text"
                  value={isNoColor ? 'No Color Info' : ''}
                  style={
                    isNoColor
                      ? {}
                      : {
                          backgroundColor: `rgb(${Red},${Green},${Blue})`,
                          borderColor: `rgb(${Red},${Green},${Blue})`
                        }
                  }
                />
              </Col>
            </FormGroup>
            <SlvyFormInput
              disabled
              labelClass="justify-content-end"
              columns={slvyFormInputColumns}
              label={MESSAGES.ref_code}
              value={PantoneCode}
            />
            <FormGroup
              as={Row}
              className="form-group d-flex justify-content-between align-items-center mb-3"
            >
              <Form.Label column sm={2} className="d-flex align-items-center justify-content-end">
                {MESSAGES.name} (EN / TR)
              </Form.Label>
              <Col sm={10} className="d-flex gap-2">
                <FormControl disabled type="text" value={Name} />
                <FormControl disabled type="text" value={NameTR} />
              </Col>
            </FormGroup>
            <SlvyFormInput
              disabled
              labelClass="justify-content-end"
              columns={slvyFormInputColumns}
              label={MESSAGES.rgb}
              value={isNoColor ? '' : `${Red} ${Green} ${Blue}`}
            />
            <SlvyFormInput
              disabled
              labelClass="justify-content-end"
              columns={slvyFormInputColumns}
              label={MESSAGES.hex_html}
              value={HEX}
            />
            <Button
              className="ms-auto d-flex"
              disabled={!MasterCode}
              variant="success"
              onClick={
                isMainColor
                  ? this.updateDesignOption.bind(this)
                  : this.updateDesignOptionAttribute.bind(this)
              }
            >
              {MESSAGES.update}
            </Button>
          </form>
        )}

        {pending && (
          <SearchList
            columnHeightValue={columnHeightValue}
            noResult={noResult}
            pendingSearch={pendingSearch}
            searchResult={searchResult}
            onEdit={this.handleSearchEdit}
          />
        )}
      </div>
    )
  }
}
