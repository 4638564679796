Ext.define('MDM.Btl.dd.NegativeInventory.Dialog', {
  extend: 'Ext.window.Window',
  resize: false,
  modal: true,
  viewModel: {
    data: {
      record: null,
      readOnly: false
    }
  },
  iconCls: 'fa fa-pencil-square-o',
  layout: 'fit',
  bodyStyle: {
    backgroud: '#fff'
  },
  bodyPadding: 2,
  defaultListenerScope: true,
  height: '90%',
  width: '70%',
  initComponent: function () {
    var a = this
    a.buildStore()
    a.items = [
      {
        xtype: 'negInventory-grid',
        store: this.store,
        border: true
      }
    ]
    a.callParent()
  },
  buildStore: function () {
    this.store = Ext.create('Ext.data.Store', {
      model: 'MDM.Btl.model.EditModel',
      groupField: 'MaterialType',
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/GetNegativeInventory'
      },
      autoLoad: false
    })
  }
})
