import { useCallback, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { tuiSlice } from '../../../store/slices'

function useClickLoadEdit() {
  const editButtonRef = useRef<Record<string, (loadName: string) => void | undefined>>({})
  const [{ registerEvent }] = useReduxState(tuiSlice.selectSlice)

  const editButtonClick = useCallback((loadName) => {
    return {
      refreshKey: uuidv4(),
      LoadName: loadName
    }
  }, [])

  useEffect(() => {
    const fn = 'handleEditButtonClick'
    editButtonRef.current[fn] = registerEvent({
      key: 'ClickLoadEdit',
      fn: editButtonClick,
      returnTypes: { refreshKey: PluginTypes.string, LoadName: PluginTypes.string }
    })
  })

  return { editButtonRef }
}

export default useClickLoadEdit
