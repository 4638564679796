Ext.define('MDM.src.view.Grouping.model.DPITreeModel', {
  extend: 'MDM.model.BaseTree',
  entityName: 'gillettev3/CusHieTree',
  remoteFilter: true,
  fileds: [
    {
      name: 'checked',
      type: 'boolean'
    }
  ]
})
