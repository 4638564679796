/* global Ext */
import _ from 'lodash'

export default (pivot) => {
  return [
    {
      name: 'AlwaysFive',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        return 5
      }
    },
    {
      name: 'AlwaysTen',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {

        return 10
      }
    },
    {
      name: 'UniqueLeftCategories',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        const dimensions = _.map(matrix.leftAxis.dimensions.items, (item) => {
          return item.dataIndex
        })

        const groups2 = _.groupBy(records, (record) => {
          return _.reduce(
            dimensions,
            (res, dimension) => {
              return res + record.data[dimension]
            },
            ''
          )
        })

        return _.size(groups2)
      }
    },
    {
      name: 'Yorsan_PRODUCT_COSTSUM_Plus_PRODUCT_COSTAVG',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var length = records.length

        var total = 0

        var i
        for (i = 0; i < length; i++) {
          total += Ext.Number.from(records[i].get('PRODUCT_COST'), 0)
        }
        return total + total / length
      }
    },
    {
      name: 'Yorsan_PRODUCT_COSTSUM_Times_Two',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var length = records.length

        var total = 0

        var i
        for (i = 0; i < length; i++) {
          total += Ext.Number.from(records[i].get('PRODUCT_COST'), 0)
        }
        return total * 2
      }
    },
    {
      name: 'Defacto_IPO',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var length = records.length

        var total = 0

        var i
        for (i = 0; i < length; i++) {
          const dispose = records[i].data['Dispose']
          if (!dispose) {
            total += Ext.Number.from(records[i].get('Total'), 0)
          }
        }
        return total
      }
    },
    {
      name: 'Gillette_DemandByRegion_ChangeInDemand',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var length = records.length
        var currDemandSum = 0
        var prevDemandSum = 0
        var result
        for (var i = 0; i < length; i++) {
          currDemandSum += Ext.Number.from(records[i].get('ReportingPeriodDemand'), 0)
          prevDemandSum += Ext.Number.from(records[i].get('ComparisonPeriodDemand'), 0)
        }
        if (prevDemandSum !== 0) {
          result = (currDemandSum - prevDemandSum) / prevDemandSum
        } else if (prevDemandSum === 0 && currDemandSum === 0) {
          result = 0
        } else {
          result = 1
        }
        return result
      }
    },
    {
      name: 'Gillette_performanceComparison',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var length = records.length
        var currValueSum = 0
        var prevValueSum = 0
        var result
        for (var i = 0; i < length; i++) {
          currValueSum += Ext.Number.from(records[i].get('CurrentValue'), 0)
          prevValueSum += Ext.Number.from(records[i].get('PreviousValue'), 0)
        }
        if (prevValueSum !== 0) {
          result = (currValueSum - prevValueSum) / prevValueSum
        } else if (prevValueSum === 0 && currValueSum === 0) {
          result = 0
        } else {
          result = 1
        }
        return result
      }
    },
    {
      name: 'Gillette_productionComparison',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var length = records.length
        var currValueSum = 0
        var prevValueSum = 0
        var result
        for (var i = 0; i < length; i++) {
          currValueSum += Ext.Number.from(records[i].get('ReportingPeriodProduction'), 0)
          prevValueSum += Ext.Number.from(records[i].get('ComparisonPeriodProduction'), 0)
        }
        if (prevValueSum !== 0) {
          result = (currValueSum - prevValueSum) / prevValueSum
        } else if (prevValueSum === 0 && currValueSum === 0) {
          result = 0
        } else {
          result = 1
        }
        return result
      }
    },
    {
      name: 'Renault_WaitingTime',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var totalFlowSum = 0
        var totalWaitingTime = 0
        var result
        _.forEach(records, (record) => {
          totalFlowSum += Ext.Number.from(record.get('TotalFlow'), 0)
          totalWaitingTime +=
            Ext.Number.from(record.get('TotalWaitingTime'), 0) *
            Ext.Number.from(record.get('TotalFlow'), 0)
        })
        if (totalFlowSum !== 0) {
          result = totalWaitingTime / totalFlowSum
        } else {
          result = 0
        }
        return result
      }
    },
    {
      name: 'Renault_TransportLeadTime',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var totalFlowSum = 0
        var totalTransportLeadTimeSum = 0
        var result
        _.forEach(records, (record) => {
          totalFlowSum += Ext.Number.from(record.get('TotalFlow'), 0)
          totalTransportLeadTimeSum +=
            Ext.Number.from(record.get('TotalTransportLeadTime'), 0) *
            Ext.Number.from(record.get('TotalFlow'), 0)
        })
        if (totalFlowSum !== 0) {
          result = totalTransportLeadTimeSum / totalFlowSum
        } else {
          result = 0
        }
        return result
      }
    },
    {
      name: 'Renault_Totaltime',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var totalFlowSum = 0
        var totalWaitingTime = 0
        var totalTransportLeadTimeSum = 0
        var result
        _.forEach(records, (record) => {
          totalFlowSum += Ext.Number.from(record.get('TotalFlow'), 0)
          totalWaitingTime +=
            Ext.Number.from(record.get('TotalWaitingTime'), 0) *
            Ext.Number.from(record.get('TotalFlow'), 0)
          totalTransportLeadTimeSum +=
            Ext.Number.from(record.get('TotalTransportLeadTime'), 0) *
            Ext.Number.from(record.get('TotalFlow'), 0)
        })
        if (totalFlowSum !== 0) {
          result = (totalTransportLeadTimeSum + totalWaitingTime) / totalFlowSum
        } else {
          result = 0
        }
        return result
      }
    },
    {
      name: 'Yorsan_Bulunabilirlik',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var sumKARSILANABILEN = 0
        var sumSIPARISMIKTAR = 0
        var result = 0
        _.forEach(records, (record) => {
          sumKARSILANABILEN += Ext.Number.from(record.get('KARSILANABILEN'), 0)
          sumSIPARISMIKTAR += Ext.Number.from(record.get('SIPARISMIKTAR'), 0)
        })
        if (sumSIPARISMIKTAR !== 0) {
          result = sumKARSILANABILEN / sumSIPARISMIKTAR
        }
        return result
      }
    },
    {
      name: 'Yorsan_StokGun_GERCEKLESENEGORE',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var sumSATIS_MALIYET = 0
        var sumSTOK_MALIYET = 0
        var result = 0
        _.forEach(records, (record) => {
          sumSATIS_MALIYET += Ext.Number.from(record.get('SATIS_MALIYET'), 0)
          sumSTOK_MALIYET += Ext.Number.from(record.get('STOK_MALIYET'), 0)
        })
        if (sumSATIS_MALIYET !== 0) {
          result = sumSTOK_MALIYET / sumSATIS_MALIYET
        }
        return result
      }
    },
    {
      name: 'Yorsan_StokGun_SON30GUNEGORE',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var sumORTSATIS_MALIYET = 0
        var sumSTOK_MALIYET = 0
        var result = 0
        _.forEach(records, (record) => {
          sumORTSATIS_MALIYET += Ext.Number.from(record.get('ORTSATIS_MALIYET'), 0)
          sumSTOK_MALIYET += Ext.Number.from(record.get('STOK_MALIYET'), 0)
        })
        if (sumORTSATIS_MALIYET !== 0) {
          result = sumSTOK_MALIYET / sumORTSATIS_MALIYET
        }
        return result
      }
    },
    {
      name: 'Yorsan_StokGun_GELECEK1HAFTAYAGORE',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var sumTAHMIN_MALIYET = 0
        var sumSTOK_MALIYET = 0
        var result = 0
        _.forEach(records, (record) => {
          sumTAHMIN_MALIYET += Ext.Number.from(record.get('TAHMIN_MALIYET'), 0)
          sumSTOK_MALIYET += Ext.Number.from(record.get('STOK_MALIYET'), 0)
        })
        if (sumTAHMIN_MALIYET !== 0) {
          result = sumSTOK_MALIYET / sumTAHMIN_MALIYET
        }
        return result
      }
    },
    {
      name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_TONAJ',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var sumSTOK_KG = 0
        var maxGUNSAYISI = 0
        var result = 0
        _.forEach(records, (record) => {
          sumSTOK_KG += Ext.Number.from(record.get('STOK_KG'), 0)
          var gunSayisi = Ext.Number.from(record.get('gunsayisi'), 0)
          if (maxGUNSAYISI < gunSayisi) {
            maxGUNSAYISI = gunSayisi
          }
        })
        if (maxGUNSAYISI !== 0) {
          result = sumSTOK_KG / maxGUNSAYISI
        }
        return result
      }
    },
    {
      name: 'Yorsan_StokGun_GUNLUK_ORT_STOK_MALIYET ',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var sumSTOK_MALIYET = 0
        var maxGUNSAYISI = 0
        var result = 0
        _.forEach(records, (record) => {
          sumSTOK_MALIYET += Ext.Number.from(record.get('STOK_MALIYET'), 0)
          var gunSayisi = Ext.Number.from(record.get('gunsayisi'), 0)
          if (maxGUNSAYISI < gunSayisi) {
            maxGUNSAYISI = gunSayisi
          }
        })
        if (maxGUNSAYISI !== 0) {
          result = sumSTOK_MALIYET / maxGUNSAYISI
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_SapmaOrani',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Solvoyo_Sapma = 0
        var Toplam_Siparis = 0
        var result = 0
        _.forEach(records, (record) => {
          Solvoyo_Sapma += Ext.Number.from(record.get('Solvoyo_Sapma'), 0)
          Toplam_Siparis += Ext.Number.from(record.get('Toplam_Siparis'), 0)
        })
        if (Toplam_Siparis !== 0) {
          result = 1 - Solvoyo_Sapma / Toplam_Siparis
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_SapmaOrani_Takimi',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Solvoyo_Sapma_TK = 0
        var Toplam_Siparis_TK = 0
        var result = 0
        _.forEach(records, (record) => {
          Solvoyo_Sapma_TK += Ext.Number.from(record.get('Solvoyo_Sapma_TK'), 0)
          Toplam_Siparis_TK += Ext.Number.from(record.get('Toplam_Siparis_TK'), 0)
        })
        if (Toplam_Siparis_TK !== 0) {
          result = 1 - Solvoyo_Sapma_TK / Toplam_Siparis_TK
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_MüsteriSapmaOrani',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var DK_Sapma = 0
        var Toplam_Siparis = 0
        var result = 0
        _.forEach(records, (record) => {
          DK_Sapma += Ext.Number.from(record.get('DK_Sapma'), 0)
          Toplam_Siparis += Ext.Number.from(record.get('Toplam_Siparis'), 0)
        })
        if (Toplam_Siparis !== 0) {
          result = 1 - DK_Sapma / Toplam_Siparis
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var DK_Sapma_TK = 0
        var Toplam_Siparis_TK = 0
        var result = 0
        _.forEach(records, (record) => {
          DK_Sapma_TK += Ext.Number.from(record.get('DK_Sapma_TK'), 0)
          Toplam_Siparis_TK += Ext.Number.from(record.get('Toplam_Siparis_TK'), 0)
        })
        if (Toplam_Siparis_TK !== 0) {
          result = 1 - DK_Sapma_TK / Toplam_Siparis_TK
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_ButceKarsilastirma',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Hedefler = 0
        var Butceler = 0
        var result = 0
        _.forEach(records, (record) => {
          Hedefler += Ext.Number.from(record.get('Hedefler'), 0)
          Butceler += Ext.Number.from(record.get('Butceler'), 0)
        })
        if (Butceler !== 0) {
          result = Hedefler / Butceler
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_TahminFark',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Toplam_Siparis_TK = 0
        var DK_Tahmin_TK = 0
        var result = 0
        _.forEach(records, (record) => {
          Toplam_Siparis_TK += Ext.Number.from(record.get('Toplam_Siparis_TK'), 0)
          DK_Tahmin_TK += Ext.Number.from(record.get('DK_Tahmin_TK'), 0)
        })

        result = Math.abs(Toplam_Siparis_TK - DK_Tahmin_TK)

        return result
      }
    },
    {
      name: 'DogtasKelebek_MüsteriSapmaOrani_Takimi_2',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Toplam_Siparis_TK = 0
        var DK_Tahmin_TK = 0
        var result = 0
        _.forEach(records, (record) => {
          Toplam_Siparis_TK += Ext.Number.from(record.get('Toplam_Siparis_TK'), 0)
          DK_Tahmin_TK += Ext.Number.from(record.get('DK_Tahmin_TK'), 0)
        })

        if (Toplam_Siparis_TK !== 0) {
          result = 1 - Math.abs(Toplam_Siparis_TK - DK_Tahmin_TK) / Toplam_Siparis_TK
        }
        return result
      }
    },
    {
      name: 'Defacto_Difference',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var LASTWEEKVALUE = 0
        var PREVWEEKVALUE = 0
        _.forEach(records, (record) => {
          LASTWEEKVALUE += Ext.Number.from(record.get('LASTWEEKVALUE'), 0)
          PREVWEEKVALUE += Ext.Number.from(record.get('PREVWEEKVALUE'), 0)
        })
        return LASTWEEKVALUE - PREVWEEKVALUE
      }
    },
    {
      name: 'Defacto_DIFFERENCEpercent',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var LASTWEEKVALUE = 0
        var PREVWEEKVALUE = 0
        var result = 1
        _.forEach(records, (record) => {
          LASTWEEKVALUE += Ext.Number.from(record.get('LASTWEEKVALUE'), 0)
          PREVWEEKVALUE += Ext.Number.from(record.get('PREVWEEKVALUE'), 0)
        })
        if (PREVWEEKVALUE !== 0) {
          result = (LASTWEEKVALUE - PREVWEEKVALUE) / PREVWEEKVALUE
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_Unfull_Percent',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Unfullfilled = 0
        var TotalDemand = 0
        var result = 0
        _.forEach(records, (record) => {
          Unfullfilled += Ext.Number.from(record.get('Unfullfilled'), 0)
          TotalDemand += Ext.Number.from(record.get('TotalDemand'), 0)
        })
        if (TotalDemand !== 0) {
          result = Unfullfilled / TotalDemand
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_Unfull_Percent_Sol',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Unfullfilled_Sol = 0
        var TotalDemand = 0
        var result = 0
        _.forEach(records, (record) => {
          Unfullfilled_Sol += Ext.Number.from(record.get('Unfullfilled_Sol'), 0)
          TotalDemand += Ext.Number.from(record.get('TotalDemand'), 0)
        })
        if (TotalDemand !== 0) {
          result = Unfullfilled_Sol / TotalDemand
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_Start_StockDay',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Start_StockDay = 0
        var Urun_Fiyat = 0
        var result = 0
        _.forEach(records, (record) => {
          Start_StockDay +=
            Ext.Number.from(record.get('Start_StockDay'), 0) *
            Ext.Number.from(record.get('Urun_Fiyat'), 0)
          Urun_Fiyat += Ext.Number.from(record.get('Urun_Fiyat'), 0)
        })
        if (Urun_Fiyat !== 0) {
          result = Start_StockDay / Urun_Fiyat
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_End_StockDay',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var End_StockDay = 0
        var Urun_Fiyat = 0
        var result = 0
        _.forEach(records, (record) => {
          End_StockDay +=
            Ext.Number.from(record.get('End_StockDay'), 0) *
            Ext.Number.from(record.get('Urun_Fiyat'), 0)
          Urun_Fiyat += Ext.Number.from(record.get('Urun_Fiyat'), 0)
        })
        if (Urun_Fiyat !== 0) {
          result = End_StockDay / Urun_Fiyat
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_End_StockDay_Sol',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var End_StockDay_Sol = 0
        var Urun_Fiyat = 0
        var result = 0
        _.forEach(records, (record) => {
          End_StockDay_Sol +=
            Ext.Number.from(record.get('End_StockDay_Sol'), 0) *
            Ext.Number.from(record.get('Urun_Fiyat'), 0)
          Urun_Fiyat += Ext.Number.from(record.get('Urun_Fiyat'), 0)
        })
        if (Urun_Fiyat !== 0) {
          result = End_StockDay_Sol / Urun_Fiyat
        }
        return result
      }
    },
    {
      name: 'Penti_Store_Stock_Cover',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var ToplamMagazaStok = 0
        var SonHafta_Satis_Adet = 0
        var result = 1
        _.forEach(records, (record) => {
          ToplamMagazaStok += Ext.Number.from(record.get('ToplamMagazaStok'), 0)
          SonHafta_Satis_Adet += Ext.Number.from(record.get('SonHafta_Satis_Adet'), 0)
        })
        if (SonHafta_Satis_Adet !== 0) {
          result = ToplamMagazaStok / SonHafta_Satis_Adet
        }
        return result
      }
    },
    {
      name: 'Penti_Store_Stock_Cover_Prev',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var ToplamMagazaStok_Prev = 0
        var SonHafta_Satis_Adet_Prev = 0
        var result = 1
        _.forEach(records, (record) => {
          ToplamMagazaStok_Prev += Ext.Number.from(record.get('ToplamMagazaStok_Prev'), 0)
          SonHafta_Satis_Adet_Prev += Ext.Number.from(record.get('SonHafta_Satis_Adet_Prev'), 0)
        })
        if (SonHafta_Satis_Adet_Prev !== 0) {
          result = ToplamMagazaStok_Prev / SonHafta_Satis_Adet_Prev
        }
        return result
      }
    },
    {
      name: 'Penti_WH_Stock_Cover',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var DepoStok = 0
        var SonHafta_Satis_Adet_AllStores = 0
        var result = 1
        _.forEach(records, (record) => {
          DepoStok += Ext.Number.from(record.get('DepoStok'), 0)
          SonHafta_Satis_Adet_AllStores += Ext.Number.from(
            record.get('SonHafta_Satis_Adet_AllStores'),
            0
          )
        })
        if (SonHafta_Satis_Adet_AllStores !== 0) {
          result = DepoStok / SonHafta_Satis_Adet_AllStores
        }
        return result
      }
    },
    {
      name: 'Penti_WH_Stock_Cover_Prev',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var DepoStok_Prev = 0
        var SonHafta_Satis_Adet_AllStores_Prev = 0
        var result = 1
        _.forEach(records, (record) => {
          DepoStok_Prev += Ext.Number.from(record.get('DepoStok_Prev'), 0)
          SonHafta_Satis_Adet_AllStores_Prev += Ext.Number.from(
            record.get('SonHafta_Satis_Adet_AllStores_Prev'),
            0
          )
        })
        if (SonHafta_Satis_Adet_AllStores_Prev !== 0) {
          result = DepoStok_Prev / SonHafta_Satis_Adet_AllStores_Prev
        }
        return result
      }
    },
    {
      name: 'Penti_Availability',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Available_Flag_YolDahil = 0
        var AvailabilityNeed_Flag_DepoKisitli = 0
        var result = 1
        _.forEach(records, (record) => {
          Available_Flag_YolDahil += Ext.Number.from(record.get('Available_Flag_YolDahil'), 0)
          AvailabilityNeed_Flag_DepoKisitli += Ext.Number.from(
            record.get('AvailabilityNeed_Flag_DepoKisitli'),
            0
          )
        })
        if (AvailabilityNeed_Flag_DepoKisitli !== 0) {
          result = Available_Flag_YolDahil / AvailabilityNeed_Flag_DepoKisitli
        }
        return result
      }
    },
    {
      name: 'Penti_Availability_Kisitsiz',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Available_Flag_DepoKisitsiz_YolDahil = 0
        var AvailabilityNeed_Flag_DepoKisitsiz = 0
        var result = 1
        _.forEach(records, (record) => {
          Available_Flag_DepoKisitsiz_YolDahil += Ext.Number.from(
            record.get('Available_Flag_DepoKisitsiz_YolDahil'),
            0
          )
          AvailabilityNeed_Flag_DepoKisitsiz += Ext.Number.from(
            record.get('AvailabilityNeed_Flag_DepoKisitsiz'),
            0
          )
        })
        if (AvailabilityNeed_Flag_DepoKisitsiz !== 0) {
          result = Available_Flag_DepoKisitsiz_YolDahil / AvailabilityNeed_Flag_DepoKisitsiz
        }
        return result
      }
    },
    {
      name: 'Penti_Rep_Sum_StoreCover',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MagazaStok = 0
        var Satis = 0
        var result = 1
        _.forEach(records, (record) => {
          MagazaStok += Ext.Number.from(record.get('MagazaStok'), 0)
          Satis += Ext.Number.from(record.get('Satis'), 0)
        })
        if (Satis !== 0) {
          result = MagazaStok / Satis
        }
        return result
      }
    },
    {
      name: 'Penti_Rep_Sum_WHCover',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var DepoStok = 0
        var Satis_AllStores = 0
        var result = 1
        _.forEach(records, (record) => {
          DepoStok += Ext.Number.from(record.get('DepoStok'), 0)
          Satis_AllStores += Ext.Number.from(record.get('Satis_AllStores'), 0)
        })
        if (Satis_AllStores !== 0) {
          result = DepoStok / Satis_AllStores
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_UYSapmaOrani_Takim_2',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Toplam_Siparis_TK = 0
        var UY_Tahmin_TK = 0
        var result = 0
        _.forEach(records, (record) => {
          Toplam_Siparis_TK += Ext.Number.from(record.get('Toplam_Siparis_TK'), 0)
          UY_Tahmin_TK += Ext.Number.from(record.get('UY_Tahmin_TK'), 0)
        })

        if (Toplam_Siparis_TK !== 0) {
          result = 1 - Math.abs(Toplam_Siparis_TK - UY_Tahmin_TK) / Toplam_Siparis_TK
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_SatisSapmaOrani_Takim_2',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Toplam_Siparis_TK = 0
        var Satis_Tahmin_TK = 0
        var result = 0
        _.forEach(records, (record) => {
          Toplam_Siparis_TK += Ext.Number.from(record.get('Toplam_Siparis_TK'), 0)
          Satis_Tahmin_TK += Ext.Number.from(record.get('Satis_Tahmin_TK'), 0)
        })

        if (Toplam_Siparis_TK !== 0) {
          result = 1 - Math.abs(Toplam_Siparis_TK - Satis_Tahmin_TK) / Toplam_Siparis_TK
        }
        return result
      }
    },
    {
      name: 'DogtasKelebek_SolvoyoSapmaOrani_Takim_2',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Toplam_Siparis_TK = 0
        var Solvoyo_Tahmin_TK = 0
        var result = 0
        _.forEach(records, (record) => {
          Toplam_Siparis_TK += Ext.Number.from(record.get('Toplam_Siparis_TK'), 0)
          Solvoyo_Tahmin_TK += Ext.Number.from(record.get('Solvoyo_Tahmin_TK'), 0)
        })

        if (Toplam_Siparis_TK !== 0) {
          result = 1 - Math.abs(Toplam_Siparis_TK - Solvoyo_Tahmin_TK) / Toplam_Siparis_TK
        }
        return result
      }
    },
    {
      name: 'Penti_TotalStoreStock_Change',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var TotalStoreStock = 0
        var LastWeek_TotalStoreStock = 0
        var result = 0
        _.forEach(records, (record) => {
          TotalStoreStock += Ext.Number.from(record.get('TotalStoreStock'), 0)
          LastWeek_TotalStoreStock += Ext.Number.from(record.get('LastWeek_TotalStoreStock'), 0)
        })

        if (LastWeek_TotalStoreStock !== 0) {
          result = TotalStoreStock / LastWeek_TotalStoreStock - 1
        }
        return result
      }
    },
    {
      name: 'Penti_Kiriklik_Option_Per',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Option_Count = 0
        var Total_Option_Count = 0
        var result = 0
        _.forEach(records, (record) => {
          Option_Count += Ext.Number.from(record.get('Option_Count'), 0)
          Total_Option_Count += Ext.Number.from(record.get('Total_Option_Count'), 0)
        })

        if (Total_Option_Count !== 0) {
          result = Option_Count / Total_Option_Count
        }
        return result
      }
    },
    {
      name: 'Penti_Capacity_Usage',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Actual = 0
        var Kapasite = 0
        var result = 0
        _.forEach(records, (record) => {
          Actual += Ext.Number.from(record.get('Actual'), 0)
          Kapasite += Ext.Number.from(record.get('Kapasite'), 0)
        })

        if (Kapasite !== 0) {
          result = Actual / Kapasite
        }
        return result
      }
    },
    {
      name: 'Gillette_InventorybyRegion_Change',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var length = records.length
        var ReportingPeriodInventory = 0
        var ComparisonPeriodInventory = 0
        var result
        for (var i = 0; i < length; i++) {
          ReportingPeriodInventory += Ext.Number.from(records[i].get('ReportingPeriodInventory'), 0)
          ComparisonPeriodInventory += Ext.Number.from(
            records[i].get('ComparisonPeriodInventory'),
            0
          )
        }
        if (ComparisonPeriodInventory !== 0) {
          result =
            (ReportingPeriodInventory - ComparisonPeriodInventory) / ComparisonPeriodInventory
        } else if (ComparisonPeriodInventory === 0 && ReportingPeriodInventory === 0) {
          result = 0
        } else {
          result = 1
        }
        return result
      }
    },
    {
      name: 'HB_DefinedAttrPer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var NumberofDefinedProducts = 0
        var TotalNumberofProducts = 0
        var result = 0
        _.forEach(records, (record) => {
          NumberofDefinedProducts += Ext.Number.from(record.get('NumberofDefinedProducts'), 0)
          TotalNumberofProducts += Ext.Number.from(record.get('TotalNumberofProducts'), 0)
        })

        if (TotalNumberofProducts !== 0) {
          result = NumberofDefinedProducts / TotalNumberofProducts
        }
        return result
      }
    },
    {
      name: 'HB_AvgSalesPrice',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var REV = 0
        var QTY = 0
        var result = 0
        _.forEach(records, (record) => {
          REV += Ext.Number.from(record.get('REV'), 0)
          QTY += Ext.Number.from(record.get('QTY'), 0)
        })

        if (QTY !== 0) {
          result = REV / QTY
        }
        return result
      }
    },
    {
      name: 'HB_BudgetPerform',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var REV_Budget = 0
        var REV_Actual = 0
        var result = 0
        _.forEach(records, (record) => {
          REV_Budget += Ext.Number.from(record.get('REV_Budget'), 0)
          REV_Actual += Ext.Number.from(record.get('REV_Actual'), 0)
        })

        if (REV_Budget !== 0) {
          result = REV_Actual / REV_Budget - 1
        }
        return result
      }
    },
    {
      name: 'HB_StockDay',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var Avg_Revenue = 0
        var Avg_Stock_Value = 0
        var result = 360
        _.forEach(records, (record) => {
          Avg_Revenue += Ext.Number.from(record.get('Avg_Revenue'), 0)
          Avg_Stock_Value += Ext.Number.from(record.get('Avg_Stock_Value'), 0)
        })

        if (Avg_Revenue !== 0) {
          result = Avg_Stock_Value / Avg_Revenue
        }
        if (result > 360) {
          result = 360
        }
        return result
      }
    },

    {
      name: 'HB_AggTerm',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var result = 0

        const rows = []
        _.forEach(records, (record) => {
          const row = {
            Vade: Ext.Number.from(record.get('Vade'), 0),
            Avg_Aggr_Value: Ext.Number.from(record.get('Avg_Aggr_Value'), 0)
          }
          rows.push(row)
        })

        const vadeGroups = _.groupBy(rows, 'Vade')

        let Sum_Sum_Avg_Aggr_Value = 0
        let Prod_Sum_Avg_Aggr_Value = 0
        _.forEach(vadeGroups, (vadeGroup) => {
          const vadeGroupVade = vadeGroup[0].Vade
          let Sum_Avg_Aggr_Value = 0
          _.forEach(vadeGroup, (vadeGroupItem) => {
            Sum_Avg_Aggr_Value += vadeGroupItem.Avg_Aggr_Value
          })
          Prod_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value * vadeGroupVade
          Sum_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value
        })

        if (Sum_Sum_Avg_Aggr_Value !== 0) {
          result = Prod_Sum_Avg_Aggr_Value / Sum_Sum_Avg_Aggr_Value
        }
        return result
      }
    },
    {
      name: 'HB_FreeCashDay',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var result1 = 0

        const rows = []
        _.forEach(records, (record) => {
          const row = {
            Vade: Ext.Number.from(record.get('Vade'), 0),
            Avg_Aggr_Value: Ext.Number.from(record.get('Avg_Aggr_Value'), 0)
          }
          rows.push(row)
        })

        const vadeGroups = _.groupBy(rows, 'Vade')

        let Sum_Sum_Avg_Aggr_Value = 0
        let Prod_Sum_Avg_Aggr_Value = 0
        _.forEach(vadeGroups, (vadeGroup) => {
          const vadeGroupVade = vadeGroup[0].Vade
          let Sum_Avg_Aggr_Value = 0
          _.forEach(vadeGroup, (vadeGroupItem) => {
            Sum_Avg_Aggr_Value += vadeGroupItem.Avg_Aggr_Value
          })
          Prod_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value * vadeGroupVade
          Sum_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value
        })

        if (Sum_Sum_Avg_Aggr_Value !== 0) {
          result1 = Prod_Sum_Avg_Aggr_Value / Sum_Sum_Avg_Aggr_Value
        }

        /** ************************************* */
        var Avg_Revenue = 0
        var Avg_Stock_Value = 0
        var result2 = 360
        _.forEach(records, (record) => {
          Avg_Revenue += Ext.Number.from(record.get('Avg_Revenue'), 0)
          Avg_Stock_Value += Ext.Number.from(record.get('Avg_Stock_Value'), 0)
        })

        if (Avg_Revenue !== 0) {
          result2 = Avg_Stock_Value / Avg_Revenue
        }
        if (result2 > 360) {
          result2 = 360
        }

        return result1 - result2
      }
    },
    {
      name: 'HB_AggTermActual',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var result = 0

        const rows = []
        _.forEach(records, (record) => {
          const row = {
            Vade: Ext.Number.from(record.get('Vade_Gerceklesen'), 0),
            Avg_Aggr_Value: Ext.Number.from(record.get('Avg_Aggr_Value'), 0)
          }
          rows.push(row)
        })

        const vadeGroups = _.groupBy(rows, 'Vade')

        let Sum_Sum_Avg_Aggr_Value = 0
        let Prod_Sum_Avg_Aggr_Value = 0
        _.forEach(vadeGroups, (vadeGroup) => {
          const vadeGroupVade = vadeGroup[0].Vade
          let Sum_Avg_Aggr_Value = 0
          _.forEach(vadeGroup, (vadeGroupItem) => {
            Sum_Avg_Aggr_Value += vadeGroupItem.Avg_Aggr_Value
          })
          Prod_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value * vadeGroupVade
          Sum_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value
        })

        if (Sum_Sum_Avg_Aggr_Value !== 0) {
          result = Prod_Sum_Avg_Aggr_Value / Sum_Sum_Avg_Aggr_Value
        }
        return result
      }
    },
    {
      name: 'HB_FreeCashDayActual',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var result1 = 0

        const rows = []
        _.forEach(records, (record) => {
          const row = {
            Vade: Ext.Number.from(record.get('Vade_Gerceklesen'), 0),
            Avg_Aggr_Value: Ext.Number.from(record.get('Avg_Aggr_Value'), 0)
          }
          rows.push(row)
        })

        const vadeGroups = _.groupBy(rows, 'Vade')

        let Sum_Sum_Avg_Aggr_Value = 0
        let Prod_Sum_Avg_Aggr_Value = 0
        _.forEach(vadeGroups, (vadeGroup) => {
          const vadeGroupVade = vadeGroup[0].Vade
          let Sum_Avg_Aggr_Value = 0
          _.forEach(vadeGroup, (vadeGroupItem) => {
            Sum_Avg_Aggr_Value += vadeGroupItem.Avg_Aggr_Value
          })
          Prod_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value * vadeGroupVade
          Sum_Sum_Avg_Aggr_Value += Sum_Avg_Aggr_Value
        })

        if (Sum_Sum_Avg_Aggr_Value !== 0) {
          result1 = Prod_Sum_Avg_Aggr_Value / Sum_Sum_Avg_Aggr_Value
        }

        /** ************************************* */
        var Avg_Revenue = 0
        var Avg_Stock_Value = 0
        var result2 = 360
        _.forEach(records, (record) => {
          Avg_Revenue += Ext.Number.from(record.get('Avg_Revenue'), 0)
          Avg_Stock_Value += Ext.Number.from(record.get('Avg_Stock_Value'), 0)
        })

        if (Avg_Revenue !== 0) {
          result2 = Avg_Stock_Value / Avg_Revenue
        }
        if (result2 > 360) {
          result2 = 360
        }

        return result1 - result2
      }
    },
    {
      name: 'Unilever_Supply_CoverKG',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var ClosingStock = 0
        var result = 0
        _.forEach(records, (record) => {
          MonthlyForecast += Ext.Number.from(record.get('MonthlyForecast'), 0)
          ClosingStock += Ext.Number.from(record.get('ClosingStock'), 0)
        })

        if (MonthlyForecast !== 0) {
          result = (ClosingStock * 30) / MonthlyForecast
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_CoverValue',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var ClosingStock = 0
        var ProductValue = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        var s3 = 0
        _.forEach(records, (record) => {
          MonthlyForecast = Ext.Number.from(record.get('MonthlyForecast'), 0)
          ClosingStock = Ext.Number.from(record.get('ClosingStock'), 0)
          ProductValue = Ext.Number.from(record.get('ProductValue'), 0)

          s1 += MonthlyForecast * ProductValue
          s2 += (ClosingStock / 1000) * ProductValue
          s3 += (MonthlyForecast / 1000) * ProductValue
        })

        if (s1 !== 0) {
          result = (s2 * 30) / s3
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_MinCoverKG',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var MinCover = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MinCover = Ext.Number.from(record.get('MinCover'), 0)

          s1 += MonthlyForecast
          s2 += MonthlyForecast * MinCover
        })

        if (s1 !== 0) {
          result = s2 / s1
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_MinCoverValue',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var MinCover = 0
        var ProductValue = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MinCover = Ext.Number.from(record.get('MinCover'), 0)
          ProductValue = Ext.Number.from(record.get('ProductValue'), 0)

          s1 += MonthlyForecast * ProductValue
          s2 += MonthlyForecast * MinCover * ProductValue
        })

        if (s1 !== 0) {
          result = s2 / s1
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_MaxCoverKG',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var MaxCover = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MaxCover = Ext.Number.from(record.get('MaxCover'), 0)

          s1 += MonthlyForecast
          s2 += MonthlyForecast * MaxCover
        })

        if (s1 !== 0) {
          result = s2 / s1
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_MaxCoverValue',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var MaxCover = 0
        var ProductValue = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MaxCover = Ext.Number.from(record.get('MaxCover'), 0)
          ProductValue = Ext.Number.from(record.get('ProductValue'), 0)

          s1 += MonthlyForecast * ProductValue
          s2 += MonthlyForecast * MaxCover * ProductValue
        })

        if (s1 !== 0) {
          result = s2 / s1
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_MinCover2KG',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var MinCover = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MinCover = Ext.Number.from(record.get('MinCover'), 0)

          s1 += MonthlyForecast
          s2 += MonthlyForecast * MinCover
        })

        if (s1 !== 0) {
          result = s2 / (s1 * 2)
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_MinCover2Value',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast = 0
        var MinCover = 0
        var ProductValue = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MinCover = Ext.Number.from(record.get('MinCover'), 0)
          ProductValue = Ext.Number.from(record.get('ProductValue'), 0)

          s1 += MonthlyForecast * ProductValue
          s2 += MonthlyForecast * MinCover * ProductValue
        })

        if (s1 !== 0) {
          result = s2 / (s1 * 2)
        }
        return result
      }
    },
    {
      name: 'Unilever_Planners_CoverKG',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast = 0
        var OpeningStock = 0
        var result = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast += Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)
          OpeningStock += Ext.Number.from(record.get('OpeningStock'), 0)
        })

        if (MonthToGoDailyForecast !== 0) {
          result = OpeningStock / MonthToGoDailyForecast
        }
        return result
      }
    },
    {
      name: 'Unilever_Planners_CoverValue',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast = 0
        var ProductValue = 0
        var OpeningStock = 0
        var result = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthToGoDailyForecast = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)
          ProductValue = Ext.Number.from(record.get('ProductValue'), 0)
          OpeningStock = Ext.Number.from(record.get('OpeningStock'), 0)

          s1 += MonthToGoDailyForecast * ProductValue
          s2 += OpeningStock * ProductValue
        })

        if (s1 !== 0) {
          result = s2 / (s1 * 2)
        }
        return result
      }
    },
    {
      name: 'Unilever_Supply_MinCoverKG_Diff',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast1 = 0
        var ClosingStock = 0
        var result1 = 0
        _.forEach(records, (record) => {
          MonthlyForecast1 += Ext.Number.from(record.get('MonthlyForecast'), 0)
          ClosingStock += Ext.Number.from(record.get('ClosingStock'), 0)
        })

        if (MonthlyForecast1 !== 0) {
          result1 = (ClosingStock * 30) / MonthlyForecast1
        }

        var MonthlyForecast2 = 0
        var MinCover = 0
        var result2 = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast2 = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MinCover = Ext.Number.from(record.get('MinCover'), 0)

          s1 += MonthlyForecast2
          s2 += MonthlyForecast2 * MinCover
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },
    {
      name: 'Unilever_Supply_MinCover2KG_Diff',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast1 = 0
        var ClosingStock = 0
        var result1 = 0
        _.forEach(records, (record) => {
          MonthlyForecast1 += Ext.Number.from(record.get('MonthlyForecast'), 0)
          ClosingStock += Ext.Number.from(record.get('ClosingStock'), 0)
        })

        if (MonthlyForecast1 !== 0) {
          result1 = (ClosingStock * 30) / MonthlyForecast1
        }

        var MonthlyForecast2 = 0
        var MinCover2 = 0
        var result2 = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast2 = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MinCover2 = Ext.Number.from(record.get('MinCover2'), 0)

          s1 += MonthlyForecast2
          s2 += MonthlyForecast2 * MinCover2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },
    {
      name: 'Unilever_Supply_MaxCoverKG_Diff',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast1 = 0
        var ClosingStock = 0
        var result1 = 0
        _.forEach(records, (record) => {
          MonthlyForecast1 += Ext.Number.from(record.get('MonthlyForecast'), 0)
          ClosingStock += Ext.Number.from(record.get('ClosingStock'), 0)
        })

        if (MonthlyForecast1 !== 0) {
          result1 = (ClosingStock * 30) / MonthlyForecast1
        }

        var MonthlyForecast2 = 0
        var MaxCover = 0
        var result2 = 0

        var s1 = 0
        var s2 = 0
        _.forEach(records, (record) => {
          MonthlyForecast2 = Ext.Number.from(record.get('MonthlyForecast'), 0)
          MaxCover = Ext.Number.from(record.get('MaxCover'), 0)

          s1 += MonthlyForecast2
          s2 += MonthlyForecast2 * MaxCover
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },
    {
      name: 'Unilever_Supply_MinCoverValue_Diff',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast1 = 0

        var ClosingStock = 0

        var ProductValue1 = 0

        var result1 = 0

        var t1 = 0

        var t2 = 0

        _.forEach(records, (record) => {
          MonthlyForecast1 = Ext.Number.from(record.get('MonthlyForecast'), 0)

          ClosingStock = Ext.Number.from(record.get('ClosingStock'), 0)

          t1 += MonthlyForecast1 * ProductValue1

          t2 += ClosingStock * ProductValue1
        })

        if (MonthlyForecast1 !== 0) {
          result1 = (t2 * 30) / t1
        }

        var MonthlyForecast2 = 0

        var MinCover = 0

        var ProductValue2 = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthlyForecast2 = Ext.Number.from(record.get('MonthlyForecast'), 0)

          MinCover = Ext.Number.from(record.get('MinCover'), 0)

          s1 += MonthlyForecast2 * ProductValue2

          s2 += MonthlyForecast2 * MinCover * ProductValue2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },

    {
      name: 'Unilever_Supply_MinCover2Value_Diff',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast1 = 0

        var ClosingStock = 0

        var ProductValue1 = 0

        var result1 = 0

        var t1 = 0

        var t2 = 0

        _.forEach(records, (record) => {
          MonthlyForecast1 = Ext.Number.from(record.get('MonthlyForecast'), 0)

          ClosingStock = Ext.Number.from(record.get('ClosingStock'), 0)

          t1 += MonthlyForecast1 * ProductValue1

          t2 += ClosingStock * ProductValue1
        })

        if (MonthlyForecast1 !== 0) {
          result1 = (t2 * 30) / t1
        }

        var MonthlyForecast2 = 0

        var MinCover2 = 0

        var ProductValue2 = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthlyForecast2 = Ext.Number.from(record.get('MonthlyForecast'), 0)

          MinCover2 = Ext.Number.from(record.get('MinCover2'), 0)

          s1 += MonthlyForecast2 * ProductValue2

          s2 += MonthlyForecast2 * MinCover2 * ProductValue2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },
    {
      name: 'Unilever_Supply_MaxCoverValue_Diff',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthlyForecast1 = 0

        var ClosingStock = 0

        var ProductValue1 = 0

        var result1 = 0

        var t1 = 0

        var t2 = 0

        _.forEach(records, (record) => {
          MonthlyForecast1 = Ext.Number.from(record.get('MonthlyForecast'), 0)

          ClosingStock = Ext.Number.from(record.get('ClosingStock'), 0)

          t1 += MonthlyForecast1 * ProductValue1

          t2 += ClosingStock * ProductValue1
        })

        if (MonthlyForecast1 !== 0) {
          result1 = (t2 * 30) / t1
        }

        var MonthlyForecast2 = 0

        var MaxCover = 0

        var ProductValue2 = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthlyForecast2 = Ext.Number.from(record.get('MonthlyForecast'), 0)

          MaxCover = Ext.Number.from(record.get('MaxCover'), 0)

          s1 += MonthlyForecast2 * ProductValue2

          s2 += MonthlyForecast2 * MaxCover * ProductValue2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },
    {
      name: 'Unilever_Planners_MinCoverKG_Diff',

      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast1 = 0

        var OpeningStock = 0

        var result1 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast1 += Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          OpeningStock += Ext.Number.from(record.get('OpeningStock'), 0)
        })

        if (MonthToGoDailyForecast1 !== 0) {
          result1 = OpeningStock / MonthToGoDailyForecast1
        }

        var MonthToGoDailyForecast2 = 0

        var MinCover = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast2 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          MinCover = Ext.Number.from(record.get('MinCover'), 0)

          s1 += MonthToGoDailyForecast2

          s2 += MonthToGoDailyForecast2 * MinCover
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },

    {
      name: 'Unilever_Planners_MinCover2KG_Diff',

      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast1 = 0

        var OpeningStock = 0

        var result1 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast1 += Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          OpeningStock += Ext.Number.from(record.get('OpeningStock'), 0)
        })

        if (MonthToGoDailyForecast1 !== 0) {
          result1 = OpeningStock / MonthToGoDailyForecast1
        }

        var MonthToGoDailyForecast2 = 0

        var MinCover2 = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast2 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          MinCover2 = Ext.Number.from(record.get('MinCover2'), 0)

          s1 += MonthToGoDailyForecast2

          s2 += MonthToGoDailyForecast2 * MinCover2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },

    {
      name: 'Unilever_Planners_MaxCoverKG_Diff',

      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast1 = 0

        var OpeningStock = 0

        var result1 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast1 += Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          OpeningStock += Ext.Number.from(record.get('OpeningStock'), 0)
        })

        if (MonthToGoDailyForecast1 !== 0) {
          result1 = OpeningStock / MonthToGoDailyForecast1
        }

        var MonthToGoDailyForecast2 = 0

        var MaxCover = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast2 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          MaxCover = Ext.Number.from(record.get('MaxCover'), 0)

          s1 += MonthToGoDailyForecast2

          s2 += MonthToGoDailyForecast2 * MaxCover
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },

    {
      name: 'Unilever_Planners_MinCoverValue_Diff',

      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast1 = 0

        var OpeningStock = 0

        var ProductValue1 = 0

        var result1 = 0

        var t1 = 0

        var t2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast1 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          OpeningStock = Ext.Number.from(record.get('OpeningStock'), 0)

          t1 += MonthToGoDailyForecast1 * ProductValue1

          t2 += OpeningStock * ProductValue1
        })

        if (MonthToGoDailyForecast1 !== 0) {
          result1 = t2 / t1
        }

        var MonthToGoDailyForecast2 = 0

        var MinCover = 0

        var ProductValue2 = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast2 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          MinCover = Ext.Number.from(record.get('MinCover'), 0)

          s1 += MonthToGoDailyForecast2 * ProductValue2

          s2 += MonthToGoDailyForecast2 * MinCover * ProductValue2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },

    {
      name: 'Unilever_Planners_MinCover2Value_Diff',

      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast1 = 0

        var OpeningStock = 0

        var ProductValue1 = 0

        var result1 = 0

        var t1 = 0

        var t2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast1 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          OpeningStock = Ext.Number.from(record.get('OpeningStock'), 0)

          t1 += MonthToGoDailyForecast1 * ProductValue1

          t2 += OpeningStock * ProductValue1
        })

        if (MonthToGoDailyForecast1 !== 0) {
          result1 = t2 / t1
        }

        var MonthToGoDailyForecast2 = 0

        var MinCover2 = 0

        var ProductValue2 = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast2 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          MinCover2 = Ext.Number.from(record.get('MinCover2'), 0)

          s1 += MonthToGoDailyForecast2 * ProductValue2

          s2 += MonthToGoDailyForecast2 * MinCover2 * ProductValue2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },

    {
      name: 'Unilever_Planners_MaxCoverValue_Diff',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MonthToGoDailyForecast1 = 0

        var OpeningStock = 0

        var ProductValue1 = 0

        var result1 = 0

        var t1 = 0

        var t2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast1 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          OpeningStock = Ext.Number.from(record.get('OpeningStock'), 0)

          t1 += MonthToGoDailyForecast1 * ProductValue1

          t2 += OpeningStock * ProductValue1
        })

        if (MonthToGoDailyForecast1 !== 0) {
          result1 = t2 / t1
        }

        var MonthToGoDailyForecast2 = 0

        var MaxCover = 0

        var ProductValue2 = 0

        var result2 = 0

        var s1 = 0

        var s2 = 0

        _.forEach(records, (record) => {
          MonthToGoDailyForecast2 = Ext.Number.from(record.get('MonthToGoDailyForecast'), 0)

          MaxCover = Ext.Number.from(record.get('MaxCover'), 0)

          s1 += MonthToGoDailyForecast2 * ProductValue2

          s2 += MonthToGoDailyForecast2 * MaxCover * ProductValue2
        })

        if (s1 !== 0) {
          result2 = s2 / s1
        }

        return result1 - result2
      }
    },
    {
      name: 'yatsan_devirhizi_teshiregoreciro',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var CIRO_TOPLAM = 0
        var DENEME_STOK = 0
        var result = 0
        _.forEach(records, (record) => {
          CIRO_TOPLAM += Ext.Number.from(record.get('CIRO_TOPLAM'), 0)
          DENEME_STOK += Ext.Number.from(record.get('DENEME_STOK'), 0)
        })

        if (DENEME_STOK !== 0) {
          result = CIRO_TOPLAM / DENEME_STOK
        }
        return result
      }
    },
    {
      name: 'yatsan_devirhizi_toplamagoreciro',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var CIRO_TOPLAM = 0
        var TOTAL = 0
        var result = 0
        _.forEach(records, (record) => {
          CIRO_TOPLAM += Ext.Number.from(record.get('CIRO_TOPLAM'), 0)
          TOTAL += Ext.Number.from(record.get('TOTAL'), 0)
        })

        if (TOTAL !== 0) {
          result = CIRO_TOPLAM / TOTAL
        }
        return result
      }
    },
    {
      name: 'yatsan_devirhizi_teshiregoreadet',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MIKTAR_TOPLAM = 0
        var DENEME_STOK = 0
        var result = 0
        _.forEach(records, (record) => {
          MIKTAR_TOPLAM += Ext.Number.from(record.get('MIKTAR_TOPLAM'), 0)
          DENEME_STOK += Ext.Number.from(record.get('DENEME_STOK'), 0)
        })

        if (DENEME_STOK !== 0) {
          result = MIKTAR_TOPLAM / DENEME_STOK
        }
        return result
      }
    },
    {
      name: 'yatsan_devirhizi_toplamagoreadet',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var MIKTAR_TOPLAM = 0
        var TOTAL = 0
        var result = 0
        _.forEach(records, (record) => {
          MIKTAR_TOPLAM += Ext.Number.from(record.get('MIKTAR_TOPLAM'), 0)
          TOTAL += Ext.Number.from(record.get('TOTAL'), 0)
        })

        if (TOTAL !== 0) {
          result = MIKTAR_TOPLAM / TOTAL
        }
        return result
      }
    },
    {
      name: 'MD_DegisimStok',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiStok = 0
        var IndirimOncesiStok = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiStok += Ext.Number.from(record.get('IndirimSonrasiStok'), 0)
          IndirimOncesiStok += Ext.Number.from(record.get('IndirimOncesiStok'), 0)
        })

        if (IndirimOncesiStok !== 0) {
          result = -1 * ((IndirimSonrasiStok - IndirimOncesiStok) / IndirimOncesiStok)
        }
        return result
      }
    },
    {
      name: 'MD_DegisimSatisAdet',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiSatisAdet = 0
        var IndirimOncesiSatisAdet = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
        })

        if (IndirimOncesiSatisAdet !== 0) {
          result = (IndirimSonrasiSatisAdet - IndirimOncesiSatisAdet) / IndirimOncesiSatisAdet
        }
        return result
      }
    },
    {
      name: 'MD_DegisimSatisTutar',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiSatisTutar = 0
        var IndirimOncesiSatisTutar = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
        })

        if (IndirimOncesiSatisTutar !== 0) {
          result = (IndirimSonrasiSatisTutar - IndirimOncesiSatisTutar) / IndirimOncesiSatisTutar
        }
        return result
      }
    },
    {
      name: 'MD_IndirimOncesiCover1',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiStok = 0
        var IndirimOncesiSatisAdet = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiStok += Ext.Number.from(record.get('IndirimOncesiStok'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
        })

        if (IndirimOncesiSatisAdet !== 0) {
          result = IndirimOncesiStok / IndirimOncesiSatisAdet
        }
        return result
      }
    },
    {
      name: 'MD_IndirimSonrasiCover1',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiStok = 0
        var IndirimSonrasiSatisAdet = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiStok += Ext.Number.from(record.get('IndirimSonrasiStok'), 0)
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
        })

        if (IndirimSonrasiSatisAdet !== 0) {
          result = IndirimSonrasiStok / IndirimSonrasiSatisAdet
        }
        return result
      }
    },

    {
      name: 'MD_DegisimGrossProfit',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiGrossProfit = 0
        var IndirimOncesiGrossProfit = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiGrossProfit += Ext.Number.from(record.get('IndirimSonrasiGrossProfit'), 0)
          IndirimOncesiGrossProfit += Ext.Number.from(record.get('IndirimOncesiGrossProfit'), 0)
        })

        if (IndirimOncesiGrossProfit !== 0) {
          result = (IndirimSonrasiGrossProfit - IndirimOncesiGrossProfit) / IndirimOncesiGrossProfit
        }
        return result
      }
    },
    {
      name: 'MD_DegisimCOGS',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiCOGS = 0
        var IndirimOncesiCOGS = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
          IndirimOncesiCOGS += Ext.Number.from(record.get('IndirimOncesiCOGS'), 0)
        })

        if (IndirimOncesiCOGS !== 0) {
          result = (IndirimSonrasiCOGS - IndirimOncesiCOGS) / IndirimOncesiCOGS
        }
        return result
      }
    },
    {
      name: 'MD_DegisimCover',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiStok = 0
        var IndirimSonrasiSatisAdet = 0
        var IndirimOncesiStok = 0
        var IndirimOncesiSatisAdet = 0
        var IndirimSonrasiCover1 = 0
        var IndirimOncesiCover1 = 0

        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiStok += Ext.Number.from(record.get('IndirimSonrasiStok'), 0)
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
          IndirimOncesiStok += Ext.Number.from(record.get('IndirimOncesiStok'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
        })

        if (IndirimSonrasiSatisAdet !== 0) {
          IndirimSonrasiCover1 = IndirimSonrasiStok / IndirimSonrasiSatisAdet
        }

        if (IndirimOncesiSatisAdet !== 0) {
          IndirimOncesiCover1 = IndirimOncesiStok / IndirimOncesiSatisAdet
        }

        if (IndirimOncesiCover1 !== 0) {
          result = -1 * ((IndirimSonrasiCover1 - IndirimOncesiCover1) / IndirimOncesiCover1)
        }

        return result
      }
    },
    {
      name: 'IndirimOncesiGMROI1',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiGrossProfit = 0
        var Stokmaliyetionce = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiGrossProfit += Ext.Number.from(record.get('IndirimOncesiGrossProfit'), 0)
          Stokmaliyetionce += Ext.Number.from(record.get('Stokmaliyetionce'), 0)
        })

        if (Stokmaliyetionce !== 0) {
          result = IndirimOncesiGrossProfit / 4 / (Stokmaliyetionce * 365)
        }
        return result
      }
    },
    {
      name: 'IndirimSonrasiGMROI1',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiGrossProfit = 0
        var Stokmaliyetisonra = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiGrossProfit += Ext.Number.from(record.get('IndirimSonrasiGrossProfit'), 0)
          Stokmaliyetisonra += Ext.Number.from(record.get('Stokmaliyetisonra'), 0)
        })

        if (Stokmaliyetisonra !== 0) {
          result = IndirimSonrasiGrossProfit / 4 / (Stokmaliyetisonra * 365)
        }
        return result
      }
    },
    {
      name: 'DegisimGMROI',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiGrossProfit = 0
        var Stokmaliyetionce = 0
        var IndirimSonrasiGrossProfit = 0
        var Stokmaliyetisonra = 0
        var IndirimOncesiGMROI1 = 0
        var IndirimSonrasiGMROI1 = 0

        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiGrossProfit += Ext.Number.from(record.get('IndirimOncesiGrossProfit'), 0)
          Stokmaliyetionce += Ext.Number.from(record.get('Stokmaliyetionce'), 0)
          IndirimSonrasiGrossProfit += Ext.Number.from(record.get('IndirimSonrasiGrossProfit'), 0)
          Stokmaliyetisonra += Ext.Number.from(record.get('Stokmaliyetisonra'), 0)
        })

        if (Stokmaliyetionce !== 0) {
          IndirimOncesiGMROI1 = IndirimOncesiGrossProfit / 4 / (Stokmaliyetionce * 365)
        }

        if (Stokmaliyetisonra !== 0) {
          IndirimSonrasiGMROI1 = IndirimSonrasiGrossProfit / 4 / (Stokmaliyetisonra * 365)
        }

        if (IndirimOncesiGMROI1 !== 0) {
          result = (IndirimSonrasiGMROI1 - IndirimOncesiGMROI1) / IndirimOncesiGMROI1
        }

        return result
      }
    },
    {
      name: 'IndirimOncesiCOGSPer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiCOGS = 0
        var IndirimOncesiSatisTutar = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiCOGS += Ext.Number.from(record.get('IndirimOncesiCOGS'), 0)
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
        })

        if (IndirimOncesiSatisTutar !== 0) {
          result = IndirimOncesiCOGS / IndirimOncesiSatisTutar
        }
        return result
      }
    },
    {
      name: 'IndirimSonrasiCOGSPer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiCOGS = 0
        var IndirimSonrasiSatisTutar = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
        })

        if (IndirimSonrasiSatisTutar !== 0) {
          result = IndirimSonrasiCOGS / IndirimSonrasiSatisTutar
        }
        return result
      }
    },
    {
      name: 'COSGPerDegisim',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiCOGS = 0
        var IndirimOncesiSatisTutar = 0
        var IndirimSonrasiCOGS = 0
        var IndirimSonrasiSatisTutar = 0
        var IndirimOncesiCOGSPer = 0
        var IndirimSonrasiCOGSPer = 0

        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiCOGS += Ext.Number.from(record.get('IndirimOncesiCOGS'), 0)
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
        })

        if (IndirimOncesiSatisTutar !== 0) {
          IndirimOncesiCOGSPer = IndirimOncesiCOGS / IndirimOncesiSatisTutar
        }

        if (IndirimSonrasiSatisTutar !== 0) {
          IndirimSonrasiCOGSPer = IndirimSonrasiCOGS / IndirimSonrasiSatisTutar
        }

        result = IndirimSonrasiCOGSPer - IndirimOncesiCOGSPer

        return result
      }
    },
    {
      name: 'IndirimOncesiGrossMarginPer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiCOGS = 0
        var IndirimOncesiSatisTutar = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiCOGS += Ext.Number.from(record.get('IndirimOncesiCOGS'), 0)
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
        })

        if (IndirimOncesiSatisTutar !== 0) {
          result = 1 - IndirimOncesiCOGS / IndirimOncesiSatisTutar
        }
        return result
      }
    },
    {
      name: 'IndirimSonrasiGrossMarginPer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiCOGS = 0
        var IndirimSonrasiSatisTutar = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
        })

        if (IndirimSonrasiSatisTutar !== 0) {
          result = 1 - IndirimSonrasiCOGS / IndirimSonrasiSatisTutar
        }
        return result
      }
    },
    {
      name: 'DegisimGrossMarginPer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiCOGS = 0
        var IndirimOncesiSatisTutar = 0
        var IndirimSonrasiCOGS = 0
        var IndirimSonrasiSatisTutar = 0
        var IndirimOncesiGrossMarginPer = 0
        var IndirimSonrasiGrossMarginPer = 0

        var result = 0
        _.forEach(records, (record) => {
          IndirimOncesiCOGS += Ext.Number.from(record.get('IndirimOncesiCOGS'), 0)
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
        })

        if (IndirimOncesiSatisTutar !== 0) {
          IndirimOncesiGrossMarginPer = 1 - IndirimOncesiCOGS / IndirimOncesiSatisTutar
        }

        if (IndirimSonrasiSatisTutar !== 0) {
          IndirimSonrasiGrossMarginPer = 1 - IndirimSonrasiCOGS / IndirimSonrasiSatisTutar
        }

        result = IndirimSonrasiGrossMarginPer - IndirimOncesiGrossMarginPer

        return result
      }
    },
    {
      name: 'IndirimOncesiAvgPrice',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiSatisTutar = 0
        var IndirimOncesiSatisAdet = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
        })

        if (IndirimOncesiSatisAdet !== 0) {
          result = IndirimOncesiSatisTutar / IndirimOncesiSatisAdet
        }
        return result
      }
    },
    {
      name: 'IndirimSonrasiAvgPrice',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiSatisTutar = 0
        var IndirimSonrasiSatisAdet = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
        })

        if (IndirimSonrasiSatisAdet !== 0) {
          result = IndirimSonrasiSatisTutar / IndirimSonrasiSatisAdet
        }
        return result
      }
    },
    {
      name: 'DegisimPricePer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiSatisTutar = 0
        var IndirimOncesiSatisAdet = 0
        var IndirimSonrasiSatisTutar = 0
        var IndirimSonrasiSatisAdet = 0
        var IndirimOncesiAvgPrice = 0
        var IndirimSonrasiAvgPrice = 0

        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
        })

        if (IndirimOncesiSatisAdet !== 0) {
          IndirimOncesiAvgPrice = IndirimOncesiSatisTutar / IndirimOncesiSatisAdet
        }

        if (IndirimSonrasiSatisAdet !== 0) {
          IndirimSonrasiAvgPrice = IndirimSonrasiSatisTutar / IndirimSonrasiSatisAdet
        }

        if (IndirimOncesiAvgPrice !== 0) {
          result = (IndirimSonrasiAvgPrice - IndirimOncesiAvgPrice) / IndirimOncesiAvgPrice
        }

        return result
      }
    },
    {
      name: 'IndirimOncesiAvgCost',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiCOGS = 0
        var IndirimOncesiSatisAdet = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiCOGS += Ext.Number.from(record.get('IndirimOncesiCOGS'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
        })

        if (IndirimOncesiSatisAdet !== 0) {
          result = IndirimOncesiCOGS / IndirimOncesiSatisAdet
        }
        return result
      }
    },
    {
      name: 'IndirimSonrasiAvgCost',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiCOGS = 0
        var IndirimSonrasiSatisAdet = 0
        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
        })

        if (IndirimSonrasiSatisAdet !== 0) {
          result = IndirimSonrasiCOGS / IndirimSonrasiSatisAdet
        }
        return result
      }
    },
    {
      name: 'IndirimOncesiAvgMargin',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiSatisTutar = 0
        var IndirimOncesiSatisAdet = 0
        var IndirimoncesiCOGS = 0
        var IndirimOncesiAvgPrice = 0
        var IndirimOncesiAvgCost = 0

        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
          IndirimoncesiCOGS += Ext.Number.from(record.get('IndirimoncesiCOGS'), 0)
        })

        if (IndirimOncesiSatisAdet) {
          IndirimOncesiAvgPrice = IndirimOncesiSatisTutar / IndirimOncesiSatisAdet
        }
        if (IndirimOncesiSatisAdet) {
          IndirimOncesiAvgCost = IndirimoncesiCOGS / IndirimOncesiSatisAdet
        }

        result = IndirimOncesiAvgPrice - IndirimOncesiAvgCost

        return result
      }
    },
    {
      name: 'IndirimSonrasiAvgMargin',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimSonrasiSatisTutar = 0
        var IndirimSonrasiSatisAdet = 0
        var IndirimSonrasiCOGS = 0
        var IndirimSonrasiAvgPrice = 0
        var IndirimSonrasiAvgCost = 0

        var result = 9999
        _.forEach(records, (record) => {
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
        })

        if (IndirimSonrasiSatisAdet) {
          IndirimSonrasiAvgPrice = IndirimSonrasiSatisTutar / IndirimSonrasiSatisAdet
        }
        if (IndirimSonrasiSatisAdet) {
          IndirimSonrasiAvgCost = IndirimSonrasiCOGS / IndirimSonrasiSatisAdet
        }

        result = IndirimSonrasiAvgPrice - IndirimSonrasiAvgCost

        return result
      }
    },
    {
      name: 'DegisimMarginPer',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var IndirimOncesiSatisTutar = 0
        var IndirimOncesiSatisAdet = 0
        var IndirimoncesiCOGS = 0
        var IndirimSonrasiSatisTutar = 0
        var IndirimSonrasiSatisAdet = 0
        var IndirimSonrasiCOGS = 0

        var IndirimSonrasiAvgPrice = 0
        var IndirimSonrasiAvgCost = 0
        var IndirimOncesiAvgPrice = 0
        var IndirimOncesiAvgCost = 0

        var IndirimOncesiAvgMargin = 0
        var IndirimSonrasiAvgMargin = 0

        var result = 9999
        _.forEach(records, (record) => {
          IndirimOncesiSatisTutar += Ext.Number.from(record.get('IndirimOncesiSatisTutar'), 0)
          IndirimOncesiSatisAdet += Ext.Number.from(record.get('IndirimOncesiSatisAdet'), 0)
          IndirimoncesiCOGS += Ext.Number.from(record.get('IndirimoncesiCOGS'), 0)
          IndirimSonrasiSatisTutar += Ext.Number.from(record.get('IndirimSonrasiSatisTutar'), 0)
          IndirimSonrasiSatisAdet += Ext.Number.from(record.get('IndirimSonrasiSatisAdet'), 0)
          IndirimSonrasiCOGS += Ext.Number.from(record.get('IndirimSonrasiCOGS'), 0)
        })

        if (IndirimSonrasiSatisAdet) {
          IndirimSonrasiAvgPrice = IndirimSonrasiSatisTutar / IndirimSonrasiSatisAdet
        }

        if (IndirimSonrasiSatisAdet) {
          IndirimSonrasiAvgCost = IndirimSonrasiCOGS / IndirimSonrasiSatisAdet
        }

        if (IndirimOncesiSatisAdet) {
          IndirimOncesiAvgPrice = IndirimOncesiSatisTutar / IndirimOncesiSatisAdet
        }

        if (IndirimOncesiSatisAdet) {
          IndirimOncesiAvgCost = IndirimoncesiCOGS / IndirimOncesiSatisAdet
        }

        IndirimOncesiAvgMargin = IndirimOncesiAvgPrice - IndirimOncesiAvgCost
        IndirimSonrasiAvgMargin = IndirimSonrasiAvgPrice - IndirimSonrasiAvgCost

        result = (IndirimSonrasiAvgMargin - IndirimOncesiAvgMargin) / IndirimOncesiAvgMargin

        return result
      }
    },
    {
      name: 'SLVY_Dogruluk',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var meandemandSum = 0
        var OrderQuantitySum = 0

        _.forEach(records, (record) => {
          var orderQuantityMultiMAPE =
            Ext.Number.from(record.get('MAPE'), 0) * Ext.Number.from(record.get('OrderQuantity'), 0)
          meandemandSum += orderQuantityMultiMAPE
          OrderQuantitySum += Ext.Number.from(record.get('OrderQuantity'), 0)
        })
        if (OrderQuantitySum === 0) OrderQuantitySum = 1
        const error = meandemandSum / OrderQuantitySum
        if (1 - error < 0) return 0
        else return 1 - error
      }
    },
    {
      name: '1.SLVY_Dogruluk',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var meandemandSum = 0
        var OrderQuantitySum = 0

        _.forEach(records, (record) => {
          var orderQuantityMultiMAPE =
            Ext.Number.from(record.get('MAPE_1'), 0) *
            Ext.Number.from(record.get('OrderQuantity'), 0)
          meandemandSum += orderQuantityMultiMAPE
          OrderQuantitySum += Ext.Number.from(record.get('OrderQuantity'), 0)
        })
        if (OrderQuantitySum === 0) OrderQuantitySum = 1
        const error = meandemandSum / OrderQuantitySum
        if (1 - error < 0) return 0
        else return 1 - error
      }
    },
    {
      name: '2.SLVY_Dogruluk',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var meandemandSum = 0
        var OrderQuantitySum = 0

        _.forEach(records, (record) => {
          var orderQuantityMultiMAPE =
            Ext.Number.from(record.get('MAPE_2'), 0) *
            Ext.Number.from(record.get('OrderQuantity'), 0)
          meandemandSum += orderQuantityMultiMAPE
          OrderQuantitySum += Ext.Number.from(record.get('OrderQuantity'), 0)
        })
        if (OrderQuantitySum === 0) OrderQuantitySum = 1
        const error = meandemandSum / OrderQuantitySum
        if (1 - error < 0) return 0
        else return 1 - error
      }
    },
    {
      name: '3.SLVY_Dogruluk',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var meandemandSum = 0
        var OrderQuantitySum = 0

        _.forEach(records, (record) => {
          var orderQuantityMultiMAPE =
            Ext.Number.from(record.get('MAPE_3'), 0) *
            Ext.Number.from(record.get('OrderQuantity'), 0)
          meandemandSum += orderQuantityMultiMAPE
          OrderQuantitySum += Ext.Number.from(record.get('OrderQuantity'), 0)
        })
        if (OrderQuantitySum === 0) OrderQuantitySum = 1
        const error = meandemandSum / OrderQuantitySum
        if (1 - error < 0) return 0
        else return 1 - error
      }
    },
    {
      name: '4.SLVY_Dogruluk',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var meandemandSum = 0
        var OrderQuantitySum = 0

        _.forEach(records, (record) => {
          var orderQuantityMultiMAPE =
            Ext.Number.from(record.get('MAPE_4'), 0) *
            Ext.Number.from(record.get('OrderQuantity'), 0)
          meandemandSum += orderQuantityMultiMAPE
          OrderQuantitySum += Ext.Number.from(record.get('OrderQuantity'), 0)
        })
        if (OrderQuantitySum === 0) OrderQuantitySum = 1
        const error = meandemandSum / OrderQuantitySum
        if (1 - error < 0) return 0
        else return 1 - error
      }
    },
    {
      name: '5.SLVY_Dogruluk',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var meandemandSum = 0
        var OrderQuantitySum = 0

        _.forEach(records, (record) => {
          var orderQuantityMultiMAPE =
            Ext.Number.from(record.get('MAPE_5'), 0) *
            Ext.Number.from(record.get('OrderQuantity'), 0)
          meandemandSum += orderQuantityMultiMAPE
          OrderQuantitySum += Ext.Number.from(record.get('OrderQuantity'), 0)
        })
        if (OrderQuantitySum === 0) OrderQuantitySum = 1
        const error = meandemandSum / OrderQuantitySum
        if (1 - error < 0) return 0
        else return 1 - error
      }
    },
    {
      name: 'Stok/Satis',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var StokSum = 0
        var SatisSum = 0

        _.forEach(records, (record) => {
          StokSum += Ext.Number.from(record.get('Stok'), 0)
          SatisSum += Ext.Number.from(record.get('Satis'), 0)
        })
        if (SatisSum === 0) {
          SatisSum = 1
        }
        return StokSum / SatisSum
      }
    },
    {
      name: 'Gillette_UnconsVsCons',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var constrained = 0
        var unconstrained = 0
        var result = 0
        _.forEach(records, (record) => {
          constrained += Ext.Number.from(record.get('constrained'), 0)
          unconstrained += Ext.Number.from(record.get('unconstrained'), 0)
        })

        if (unconstrained !== 0) {
          result = constrained / unconstrained
        }
        return result
      }
    },
    {
      name: 'Penti_Store_Stock_Cover_Change',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        const Aggr_Penti_Store_Stock_Cover = _.find(pivot.customAggragators, {
          name: 'Penti_Store_Stock_Cover'
        })
        const Aggr_Penti_Store_Stock_Cover_Prev = _.find(pivot.customAggragators, {
          name: 'Penti_Store_Stock_Cover_Prev'
        })

        const Penti_Store_Stock_Cover = Aggr_Penti_Store_Stock_Cover.fn(records)
        const Penti_Store_Stock_Cover_Prev = Aggr_Penti_Store_Stock_Cover_Prev.fn(records)

        let result = 0
        if (Penti_Store_Stock_Cover_Prev !== 0) {
          result = Penti_Store_Stock_Cover / Penti_Store_Stock_Cover_Prev - 1
        }
        return result
      }
    },
    {
      name: 'Penti_WH_Stock_Cover_Change',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        const Aggr_Penti_WH_Stock_Cover = _.find(pivot.customAggragators, {
          name: 'Penti_WH_Stock_Cover'
        })
        const Aggr_Penti_WH_Stock_Cover_Prev = _.find(pivot.customAggragators, {
          name: 'Penti_WH_Stock_Cover_Prev'
        })

        const Penti_WH_Stock_Cover = Aggr_Penti_WH_Stock_Cover.fn(records)
        const Penti_WH_Stock_Cover_Prev = Aggr_Penti_WH_Stock_Cover_Prev.fn(records)

        let result = 0
        if (Penti_WH_Stock_Cover_Prev !== 0) {
          result = Penti_WH_Stock_Cover / Penti_WH_Stock_Cover_Prev - 1
        }
        return result
      }
    },
    {
      name: 'Gillette_Scenarios',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var sum = 0
        var mixedQueryTypes = false
        var lastQueryType = ''

        _.forEach(records, (record) => {
          var currQueryType = record.get('queryType')
          if (lastQueryType !== '' && lastQueryType !== currQueryType) {
            mixedQueryTypes = true
            return false
          }
          lastQueryType = currQueryType
        })

        _.forEach(records, (record) => {
          if (mixedQueryTypes) {
            var queryType = record.get('queryType')
            if (queryType === 'Diff') {
              sum += Ext.Number.from(record.get('queryValue'), 0)
            }
          } else {
            sum += Ext.Number.from(record.get('queryValue'), 0)
          }
        })

        return sum
      }
    },
    {
      name: 'GrandTotal_Ratio',
      fn: function (records, measure, matrix, rowGroupKey, colGroupKey) {
        var matrixRecords = matrix.results.get('grandtotal', colGroupKey).records
        var matrixValue
        var matrixGrandTotal = 0
      
        for (i = 0; i < matrixRecords.length; i++) {
          matrixValue = matrixRecords[i].get(measure)
          if (matrixValue !== null) {
            if (total === null) {
              total = 0
            }
  
            if (typeof matrixValue === 'number') {
              matrixGrandTotal += matrixValue
            }
          }
        }
      
        var length = records.length,
          total = matrix.calculateAsExcel ? null : 0,
          i,
          value
  
        for (i = 0; i < length; i++) {
          value = records[i].get(measure)
          if (value !== null) {
            if (total === null) {
              total = 0
            }
  
            if (typeof value === 'number') {
              total += value
            }
          }
        }
  
        return matrixGrandTotal === 0 ? 0 : total / matrixGrandTotal
      }
    }
  ]
}
