Ext.define('MDM.src.view.globalapp.Panel.setup.Grid', {
  extend: 'MDM.src.ux.GridPanel',
  xtype: 'globalapp-setup-grid',
  bodyPadding: 0,
  listeners: {
    beforeedit: 'onBeforeEdit'
  },
  columns: [
    {
      text: 'Process Steps',
      xtype: 'templatecolumn',
      flex: 1,
      tpl: '<div class="foo" style="background-color: {Color};"></div>{Title}'
    },
    {
      xtype: 'datecolumn',
      text: 'Start Date',
      dataIndex: 'startDate',
      format: 'M d, Y',
      width: 200
    },
    {
      xtype: 'datecolumn',
      text: 'End Date',
      dataIndex: 'endDate',
      format: 'M d, Y',
      width: 200
    },
    {
      text: 'Duration',
      dataIndex: 'diff',
      widt: 70,
      xtype: 'templatecolumn',
      tpl: '{diff} days'
    },
    {
      xtype: 'actioncolumn',
      width: 80,
      menuDisabled: true,
      align: 'center',
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          getClass: function (value, metadata, record) {
            if (record.get('isEditable')) {
              return 'fa fa-pencil-square-o'
            }
            if (record.get('description')) {
              return 'fa fa-eye bluecolor'
            }

            return 'fa fa-eye '
          },
          tooltip: 'View/Edit',
          handler: 'onEditCalendar'
        }
      ]
    }
  ]
})
