export default `({
    controller: {
        onLoadStore: function () {
            this.calculateTotal()
        },
        onChangeOverRecommendationConfirm: function (grid, rowIndex, colIndex, item, e, record) {
            var me = this
            Ext.Msg.show({
                title: tt('Onay'),
                width: 500,
                msg: tt('Are you sure you want to take over this record?'),
                buttonText: {
                    yes: tt('Evet'),
                    no: tt('Hayır')
                },
                buttons: Ext.Msg.YESNO,
                icon: Ext.MessageBox.QUESTION,
                fn: function (response) {
                    if (response === 'yes') {
                        me.onChangeOverRecommendation(record)
                    }
                }
            })
        },
        onChangeOverRecommendation: function (record) {
            var data = Ext.encode(record.data);
            var spotId = this.getViewModel().get('SpotId')
            var orderRecommendationCurrent = record.get('OrderRecommendationCurrent');
            me = this;
            var currentrecord = record
            me.getView().setLoading('Please wait while we are processing your request...');
            Ext.Ajax.request({
                url: Spui.Global.getApiUrl() + '/spui/changeOverRecommendation',
                method: 'POST',
                params: {
                    data: data,
                    newStatus: 'Sub Offers',
                },
                success: function (response, opts) {
                    record.set('OrderRecommendationCurrent',
                        record.get('OrderRecommendationChild'));
                    record.store.getProxy().setExtraParams({
                        updateAction: 'SubValueChange',
                        SpotGeneralStatus: 'InProgress',
                        SpotId:spotId
                    });
                    record.store.sync({
                        callback: function (records, operation) {
                            me.calculateTotal();
                            me.getView().setLoading(false);
                        }
                    });
                    me.getView().setLoading(false);
                },
                failure: function (response, opts) {
                    console.log('server-side failure with status code ' + response.status);
                    Ext.Msg.alert(
                        tt('Durum'),
                        tt('İşlem Gerçekleştirilirken bir hata oluştu')
                    )
                    me.getView().setLoading(false);
                }
            });
        },
        onClıckChangeOverRecommendationConfirm: function () {
            var me = this;
            Ext.Msg.show({
                title: tt('Onay'),
                width: 500,
                msg: tt('Are you sure you want to take over all record?'),
                buttonText: {
                    yes: tt('Evet'),
                    no: tt('Hayır')
                },
                buttons: Ext.Msg.YESNO,
                icon: Ext.MessageBox.QUESTION,
                fn: function (response) {
                    if (response === 'yes') {
                        me.onClıckChangeOverRecommendation()
                    }
                }
            })
        },
        onClıckChangeOverRecommendation: function () {
            var me = this;
            var SpotId = this.getViewModel().get('SpotId')
            var gridStore = this.getViewModel().getStore('gridStore');
            me.getView().setLoading('Please wait while we are processing your request...');
            Ext.Ajax.request({
                url: Spui.Global.getApiUrl() + '/spui/changeOverRecommendationAll',
                method: 'POST',
                params: {
                    SpotId: SpotId,
                },
                success: function (response, opts) {
                    me.getView().setLoading(false);
                    gridStore.proxy.setExtraParams({SpotId:SpotId})
                    gridStore.load();
                },
                failure: function (response, opts) {
                    Ext.Msg.alert(
                        tt('Durum'),
                        tt('İşlem Gerçekleştirilirken bir hata oluştu')
                    )
                    me.getView().setLoading(false);
                }
            });
        },
        onLockingChange: function (grid, rowIndex, colIndex, item, e, record) {
            var me = this;
            view = me.getView();
            IsLocked = record.get('IsLocked')
            record.set('IsLocked', !IsLocked)
            record.commit()
            view.setLoading('Saving data please wait.....');
            record.save({
                params: {
                    updateAction: 'OnLockChange'
                },
                callback: function () {
                    view.setLoading(false);
                }
            })
        },
        onApproveSpot: function (button) {
            var SpotId = this.getViewModel().get('SpotId')
            var me = this,
                v = me.getView(),
                store = me.getViewModel().getStore('gridStore');
            var win = button.up('window'),
                scope = win.bscope
            Ext.Msg.show({
                title: tt('Onay'),
                //height: 160,
                width: 500,
                msg: tt('Are you sure you want to publish this record?'),
                buttons: Ext.Msg.YESNO,
                icon: Ext.MessageBox.QUESTION,
                buttonText: {
                    yes: tt('Evet'),
                    no: tt('Hayır')
                },
                fn: function (response) {
                    if (response === 'yes') {
                        v.setLoading('Islem yapiliyor Lutfen bekleyiniz....');
                        store.each(function (record) {
                            if (record.get('IsLocked')) {
                                record.set('IsLocked', 0)
                            }
                        })
                        store.getProxy().setExtraParam('updateAction', 'UnlockAll');
                        store.sync()
                        Ext.Ajax.request({
                            url: Spui.Global.getApiUrl() + '/spui/approveSpot',
                            method: 'POST',
                            timeout: 600000,
                            params: {
                                SpotId: SpotId,
                                actionType: 'approve',
                            },
                            success: function (response, opts) {
                                var obj = Ext.decode(response.responseText);
                                v.setLoading(false);
                                me.getView().ownerCt.close();
                                scope.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            },
                            failure: function (response, opts) {
                                v.setLoading(false);
                                Ext.Msg.alert(
                                    tt('Durum'),
                                    tt('İşlem Gerçekleştirilirken bir hata oluştu')
                                )
                                console.log('server-side failure with status code ' + response.status);
                            }
                        });
                    }
                }
            });
        },
        onClickdata: function (field) {
            var me = this,
                vieModel = me.getViewModel(),
                newValue = field.getValue(),
                calculationMethod = 0;
            me.onClickdataRequest(newValue, calculationMethod);
        },
        onClickdataRequest: function (newValue, calculationMethod) {
            var spotId = this.getViewModel().get('SpotId')
            var me = this,
                v = me.getView(),
                store = me.getViewModel().getStore('gridStore'),
                win = v.ownerCt,
                spotRecord = win.spotRecord,
                minQty = spotRecord.get('MinQuantity'),
                unitsPerPackage = spotRecord.get('UnitsPerPackage'),
                storeCount = 0,
                sumOfLockedRecommendationValues = 0,
                allRecords = store.data;
            allRecords.each(function (record) {
                if (record.get('IsLocked') == 0 && record.get('IsInclude') == 1) {
                    storeCount = storeCount + record.get('NumberofStore')
                }
                if (record.get('IsLocked') == 1 && record.get('IsInclude') == 1) {
                    sumOfLockedRecommendationValues = sumOfLockedRecommendationValues + record.get('OrderRecommendationCurrent')
                }
            })
            
            v.setLoading(tt('Oneri Hesaplaniyor Lutfen bekleyiniz.'));
            Ext.Ajax.request({
                url: Spui.Global.getApiUrl() + '/spui/recalculateoneri',
                method: 'POST',
                timeout: 600000,
                params: {
                    SpotId: spotId,
                    newValue: newValue,
                    calculationMethod: calculationMethod
                },
                success: function (response, opts) {
                    // @TODO v2 response
                    var result = Ext.decode(response.responseText);
                    
                    if (!result.IsSuccess) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Minimum Siparşi Miktarının Altında Bir değer Girdiniz. Minimum sipariş miktarı: ') + result.Message
                        )
                    }
                    else{
                        store.load();
                    }
                    v.setLoading(false);
                },
                failure: function (response, opts) {
                    v.setLoading(false);
                    Ext.Msg.alert(
                        tt('Durum'),
                        tt('İşlem Gerçekleştirilirken bir hata oluştu')
                    )
                    console.log('server-side failure with status code ' + response.status);
                }
            });
        },
        oncheckchange: function (a, b, d, e) {
            var me = this,
                view = me.getView();
            view.setLoading('Saving data please wait.....');
            e.save({
                params: {
                    updateAction: 'DCIncludeChange'
                },
                callback: function () {
                    view.setLoading(false);
                    me.calculateTotal();
                }
            })
        },
        doClose: function () {
            this.getView().ownerCt.close();
        },
        calculateTotal: function () {
            var me = this,
                grid = me.lookup('grid'),
                store = me.getViewModel().getStore('gridStore');
            allRecords = store.snapshot || store.data,
                OrderRecommendation_DC = 0,
                OrderRecommendation_DC_UpdatedbyMO = 0,
                MaterialCode = '',
                MaterialDescription = '',
                UnitsPerPackage = 1,
                lastRecord = false,
                SalesPrice = 0,
                allRecords.each(function (record) {
                    OrderRecommendation_DC = OrderRecommendation_DC + record.get('OrderRecommendationParent');
                    if (record.get('IsInclude')) {
                        OrderRecommendation_DC_UpdatedbyMO = OrderRecommendation_DC_UpdatedbyMO + record.get('OrderRecommendationCurrent');
                    }
                    lastRecord = record
                });
            var viewModel = this.getViewModel();
            if (lastRecord) {
                MaterialCode = lastRecord.get('MaterialCode') * 1 + ' - ' + lastRecord.get('MaterialDescription');
                UnitsPerPackage = lastRecord.get('UnitsPerPackage')
                SalesPrice = lastRecord.get('SalesPrice')
            }
            sumRecord = Ext.create('Ext.data.Model', {
                MaterialCode: MaterialCode,
                SalesPrice: Ext.util.Format.number(SalesPrice, '0,000.00'),
                UnitsPerPackage: UnitsPerPackage,
                OrderRecommendation_DC: Ext.util.Format.number(OrderRecommendation_DC, '0,000')+' ' + tt(' Koli'),
                OrderRecommendation_DCST: Ext.util.Format.number(OrderRecommendation_DC * UnitsPerPackage, '0,000')+ ' ' + tt(' Adet'),
                OrderRecommendation_DC_UpdatedbyMO: OrderRecommendation_DC_UpdatedbyMO
            })
            viewModel.set('sumRecord', sumRecord)
        },
    },
    xtype: 'grid',
    reference: 'grid',
    layout: 'fit',
    bind: {
        store: '{gridStore}'
    },
    dockedItems: [
        {
            xtype: 'toolbar',
            border: true,
            border: 1,
            margin: 1,
            dock: 'top',
            items: [{
                    xtype: 'label',
                    bind: {
                        html: '<h3>' +t('₺') +'{sumRecord.MaterialCode} / {sumRecord.SalesPrice}</h3>'
                    },
                    margin: '0 10 0 10'
                },
                '-',
                {
                    xtype: 'container',
                    width: 100
                },
                '->',
                {
                    xtype: 'label',
                    forId: 'myFieldId',
                    html: '<b>'+tt('Solvoyo Onerisi')+ ' :<b>',
                    margin: '0 0 0 10'
                },
                {
                    xtype: 'label',
                    bind: {
                        html: '{sumRecord.OrderRecommendation_DC}',
                    },
                    margin: '0 0 0 10'
                },
                {
                    xtype: 'label',
                    html: '-',
                    margin: '0 0 0 10'
                },
                {
                    xtype: 'label',
                    forId: 'myFieldId',
                    bind: {
                        html: '{sumRecord.OrderRecommendation_DCST}'
                    },
                    margin: '0 0 0 10'
                },
                '-',
                {
                    xtype: 'container',
                    width: 100
                },
                {
                    xtype: 'label',
                    html: '<b>'+ tt('ApprovedRecommendation')+'</b>'
                },
                {
                    width: 100,
                    xtype: 'textfield',
                    listeners: {
                    },
                    triggers: {
                        foo: {
                            cls: 'x-form-calculate-trigger',
                            weight: 1,
                            handler: 'onClickdata',
                            tooltip: tt('Yeniden Hesapla')
                        },
                    },
                    bind: {
                        value: '{sumRecord.OrderRecommendation_DC_UpdatedbyMO}',
                    },
                },
                // {
                //     xtype: 'label',
                //     html: '<b>'+tt('SAS Öneri')+'</b>'
                // },
                // {
                //     width: 100,
                //     xtype: 'textfield',
                //     listeners: {
                //     },
                //     triggers: {
                //         foo: {
                //             cls: 'x-form-calculate-trigger',
                //             weight: 1,
                //             handler: 'onClickdata',
                //             tooltip: tt('Yeniden Hesapla')
                //         },
                //     },
                //     bind: {
                //         value: '{sumRecord.SAS}',
                //     },
                // },
                {
                    xtype: 'label',
                    forId: 'myFieldId',
                    bind: {
                        html: tt(' Koli')
                    },
                    margin: '0 10 0 1'
                },
                {
                    text: tt('Toplu Devral'),
                    iconCls: 'fa fa-stack-overflow',
                    handler: 'onClıckChangeOverRecommendationConfirm',
                    margin: '0 80 0 1'
                }
            ]
        },
        {
            xtype: 'toolbar',
            dock: 'bottom',
            margin: 1,
            items: ['->',
                {
                    text: tt('Afise Cik'),
                    ui: 'btn-success',
                    handler: 'onApproveSpot'
                },
                {
                    text: tt('Kapat'),
                    ui: 'btn-success',
                    handler: 'doClose'
                }
            ]
        }
    ],
    listeners: {
        render: 'onRender'
    },
    syncRowHeight:true,
    plugins: [{
        ptype: 'cellediting',
        clicksToEdit: 1,
        listeners: {
            edit: function (editor, context, eOpts) {
                if (context.originalValue == context.value)
                    return;
                var record = context.record,
                    grid = context.grid.ownerCt,
                    win = grid.up('window'),
                    spotRecord = win.spotRecord,
                    minQty = spotRecord.get('MinQuantity'),
                    numberOfStore = context.record.data.NumberofStore,
                    unitsPerPackage = spotRecord.get('UnitsPerPackage'),
                    oldValue=record.previousValues.OrderRecommendationCurrent
                    
                grid.setLoading('Saving');
                record.save({
                    params: {
                        updateAction: 'SubValueChange'
                    },
                    callback: function (record, operation, success) {
                        // @TODO v2 response
                        var result = operation._response.responseJson
                        if (!result.IsSuccess) {
                            Ext.Msg.alert(
                                tt('Durum'),
                                tt('Minimum Siparşi Miktarının Altında Bir değer Girdiniz. Minimum sipariş miktarı: ') + result.Message
                            )
                            record.set('OrderRecommendationCurrent',oldValue)
                            record.commit()
                        }
                        this.store.load()
                        grid.controller.calculateTotal()
                        grid.setLoading(false)
                        // do something whether the save succeeded or failed
                    }
                });
            },
            scope: 'this'
        }
    }],
    features: [{
        ftype: 'summary',
        dock: 'top'
    }],
    onLockingChange: function (grid, rowIndex, colIndex, item, e, record) {
        var me = this;
        IsLocked = record.get('IsLocked')
        record.set('IsLocked', !IsLocked)
        record.commit()
        me.setLoading('Saving data please wait.....');
        record.save({
            params: {
                updateAction: 'OnLockChange'
            },
            callback: function () {
                me.setLoading(false);
            }
        })
    },
    columns: [{
            xtype: 'actioncolumn',
            width: 30,
            locked: true,
            items: [{
                iconCls: 'x-fa fa-dot-circle-o',
                tooltip: 'Show store Details',
                align: 'center',
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var data = record.data;
                    if (!Spui.data.model.tree2) {
                        Ext.define('Spui.data.model.tree2', {
                            extend: 'Ext.data.TreeModel',
                            idProperty: 'OrgCode',
                            fields: [{
                                    type: 'int',
                                    name: 'NumberofStore'
                                },
                                {
                                    type: 'int',
                                    name: 'NumberofRecommendedStore'
                                },
                                {
                                    type: 'int',
                                    name: 'OrderRecommendationParent'
                                },
                                {
                                    type: 'int',
                                    name: 'OrderRecommendationCurrent'
                                },
                                {
                                    type: 'string',
                                    name: 'DCCode'
                                },
                            ]
                        });
                    }
                    var wind = Ext.create('Ext.window.Window', {
                        title: record.get('DCName'),
                        localeProperties: [],
                        height: '80%',
                        bodyPadding: 2,
                        bodyStyle: {
                            background: '#ccc'
                        },
                        width: '50%',
                        modal: true,
                        ui: 'spui-white',
                        viewModel: {},
                        layout: 'fit',
                        items: {
                            xtype: 'uxTreeContainer',
                            uxStoreCfg: 'StoretDetailsforWizardStore',
                            uxItemCfg: 'StoretDetailsforWizardi',
                            uxStore: {
                                model: 'Spui.data.model.tree2',
                                root: {
                                    OrgTitle: data['DCName'],
                                    text: data['DCName'],
                                    expanded: true,
                                    OrgCode: data['OrgCode'],
                                    NumberofStore: data['NumberofStore'],
                                    NumberofRecommendedStore: data['NumberofRecommendedStore'],
                                    OrderRecommendationParent: data['OrderRecommendationParent'],
                                    OrderRecommendationCurrent: data['OrderRecommendationCurrent'],
                                },
                                rootVisible: true,
                                proxy: {
                                    type: 'ajax',
                                    extraParams: {
                                        data: Ext.encode(data)
                                    },
                                    actionMethods: {
                                        create: 'POST',
                                        read: 'POST',
                                        update: 'POST',
                                        destroy: 'POST'
                                    },
                                    url: Spui.Global.getApiUrl() + '/spui/GetReport',
                                    reader: {
                                        type: 'json',
                                        rootProperty: 'children',
                                        keepRawData: true,
                                        successProperty: 'success',
                                        messageProperty: 'errors'
                                    },
                                }
                            },
                            ddParam: data,
                        }
                    })
                    wind.show();
                }
            }]
        },
        {
            xtype: 'actioncolumn',
            width: 30,
            locked: true,
            dataIndex: 'IsLocked',
            items: [{
                iconCls: 'x-fa  fa-unlock',
                tooltip: tt('Locking'),
                align: 'center',
                getClass: function (value, meta, record) {
                    if (record) {
                        return value ? 'x-fa  fa-lock' : 'x-fa  fa-unlock';
                    }
                },
                handler: 'onLockingChange'
            }]
        },
        {
            xtype: 'checkcolumn',
            header: tt('Include'),
            dataIndex: 'IsInclude',
            headerCheckbox: false,
            width: 90,
            stopSelection: true,
            locked: true,
            summaryType: 'sum',
            summaryRenderer: function (value, summaryData, dataIndex, context) {
                sumObject = summaryData;
                context.innerCls = '';
                return '<b>' + Ext.String.format(tt('{0} DC{1}'), value, value !== 1 ? 's' : '') + '</b>';
            },
            listeners: {
                checkchange: 'oncheckchange',
            }
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            // locked: true,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'fa fa-stack-overflow',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: 'onChangeOverRecommendationConfirm'
            }]
        },
        {
            text: tt('DcCode'),
            dataIndex: 'Code',
            width: 100,
            // format: '000.00',
            align: 'center',
            locked: true
        },
        {
            text: tt('DcName'),
            dataIndex: 'Name',
            width: 200,
            // format: '000.00',
            align: 'left',
            locked: true,
            summaryType: 'count',
        },
        {
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true
        },
        {
            text: tt('Inventory_DC'),
            columns: [{
                text: tt('OnHand'),
                dataIndex: 'OnHandInventoryDC',
                width: 100,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryType: function (records, property, root, begin, end) {
                    var n = property.length,
                        i, sum, v, sum2
                    sum = 0;
                    sum2 = 0
                    for (i = 0; i < n; ++i) {
                        v = property[i]
                        sum = sum + v;
                        if (records[i].get('IsInclude'))
                            sum2 = sum2 + v;
                    }
                    return sum2 + " / " + sum;
                },
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    sumObject = summaryData;;
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }, {
                text: tt('OpenOrder'),
                dataIndex: 'OpenOrderDC',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                summaryType: 'sum',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }]
        },
        {
            text: tt('Inventory_Store'),
            columns: [{
                    text: tt('OnHandInventory'),
                    dataIndex: 'OnHandInventoryStore',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryType: 'sum',
                    summaryRenderer: function (value, summaryData, dataIndex, context) {
                        if (!value || value === 0) return '<b>-</b>';
                        return '<b>' + Ext.util.Format.number(value, '0,000.00') + '</b>';
                    }
                },
                {
                    text: tt('OpenOrder'),
                    dataIndex: 'OpenOrderStore',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryRenderer: function (v, summaryData, dataIndex, context) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                }
            ]
        },
        {
            text: tt('OrderRecommendationParent'),
            dataIndex: 'OrderRecommendationParent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('OrderRecommedationCurrent'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: function (records, property, root, begin, end) {
                var sum = 0
                records.forEach(function(record){
                    if(record.get('IsInclude'))
                    {
                        sum=sum+record.get('OrderRecommendationCurrent')
                    }
                })
                return sum
            },
            getEditor: function (record) {
                if (record.get('IsLocked') == 1 || record.get('IsInclude') == 0) {
                    return null
                }
                return Ext.create('Ext.form.field.Number', {
                    allowBlank: false
                })
            },
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('OrderRecommendationChild'),
            dataIndex: 'OrderRecommendationChild',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('SAS Miktarı'),
            dataIndex: 'SAS',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return v
            },
        },
        {
            text: tt('NumberOfstore'),
            columns: [{
                    text: tt('All'),
                    dataIndex: 'NumberofStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    summaryTyped: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                },
                {
                    text: tt('RecommendedSTore'),
                    dataIndex: 'NumberofRecommendedStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v, meta, record) {
                        return v;
                    },
                    summaryType: 'sum',
                }
            ]
        },
        // {
        //     text: tt('Comments'),
        //     dataIndex: 'Comments',
        //     flex: 1,
        // },
    ],
})
`
