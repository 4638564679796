import { STORE_MFP_SETTINGS } from '../actions/actionTypes'

const initialState = {
  mfpSettings: {
    mfpId: 1,
    isSmallFontSize: false,
    isScenarioEditable: false,
    isCurrencyModeActive: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_MFP_SETTINGS:
      return {
        mfpSettings: {
          ...state.mfpSettings,
          ...action.payload.mfpSettings
        }
      }
    default:
      return state
  }
}

export default reducer
