/**
 * Serie class for column range series type
 *
 * See {@link Chart.ux.Highcharts.RangeSerie} class for more info
 */
Ext.define('Chart.ux.Highcharts.ColumnRangeSerie', {
  extend: 'Chart.ux.Highcharts.RangeSerie',
  alternateClassName: ['highcharts.columnrange'],
  type: 'columnrange'
})
