import React, { useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { slvyToast } from '../../../../../components'
import { IAction } from '../../Actions/Actions.types'
import Actions from '../../Actions/index'
import { makeRequest, getScenarioParameters, setLoaderWithDispatch } from '../../../utils'
import HeaderButton from '../HeaderButton'
import { IHeaderButtonGroupProps } from './HeaderButtonGroup.types'
import PivotBuilderDropdown from '../PivotBuilderDropdown'
import { MESSAGES } from '../../../messages'
import { saveScenarioTreeViewSettings, setLoader } from '../../../store/actions'

function HeaderButtonGroup(props: IHeaderButtonGroupProps) {
  const {
    actionLogsColumnName,
    isScenarioEditable,
    isVisibleActionsButton,
    isVisibleApplyChanges,
    isVisibleChangeScenarioButton,
    isVisibleDiscardChanges,
    isVisiblePivotBuilderButton,
    isWorkInSubsetEnabled,
    pluginId,
    pluginStates,
    preferredUsername,
    onApplySubsetChangesHandle,
    onDiscardHandle,
    onPivotStateChanged,
    onReloadViewClick,
    onViewChangedCallback
  } = props

  const [isVisibleActionList, setIsVisibleActionList] = useState(false)
  const [actions, setActions] = useState<IAction[]>([])
  const dispatch = useDispatch()

  // @ts-ignore
  const selectedScenarioTree = useSelector((state) => state.scenarioTreeSelection.scenarioTree)
  // @ts-ignore
  const defaultActionParams = useSelector((state) => state.defaultActionParams.defaultActionParams)
  // @ts-ignore
  const mfpSettings = useSelector((state) => state.mfpSettings.mfpSettings)

  const { payload = {} } = ({} = defaultActionParams) || {}
  const { mfpId } = mfpSettings || {}
  const { currentScenarios = [] } = selectedScenarioTree || {}

  function toggleLoader(isVisible = false, msg = MESSAGES.loading) {
    setLoaderWithDispatch(dispatch, setLoader, isVisible, msg)
  }

  function displayScenarioTree($state = true) {
    dispatch(
      saveScenarioTreeViewSettings({
        payload: {
          isVisible: $state
        }
      })
    )
    onViewChangedCallback({ View: 'ScenarioTree' })
  }

  function _getScenarioParameters() {
    return getScenarioParameters(currentScenarios, mfpId)
  }

  function getMfpActions() {
    const $params = {
      mfpId,
      scenarioId: _getScenarioParameters().params.PlannedId,
      workInSubsetEnabled: isWorkInSubsetEnabled
    }

    return makeRequest({
      payload: {
        ...payload,
        requestMethod: 'post',
        method: 'GetMfpActions',
        body: {
          ...$params
        }
      }
    })
  }

  function actionClickHandler() {
    if (!_.isEmpty(actions) && isVisibleActionList) {
      setIsVisibleActionList(false)
      setActions([])
      return false
    }

    const getErrors = (msg: string) => {
      slvyToast.warning({
        message: msg,
        title: MESSAGES.action_status,
        options: { containerId: 'dp' }
      })
      setIsVisibleActionList(false)
    }

    toggleLoader(true, MESSAGES.loading)

    setIsVisibleActionList(!isVisibleActionList)

    // if (!isVisibleActionList) {
    getMfpActions()
      .then((response: { data: { Result: []; Errors: string[] } }) => {
        const { data: { Result, Errors = [] } = {} } = response

        if (!_.isEmpty(Errors)) {
          getErrors(Errors.at(-1) || MESSAGES.there_has_been_an_error)
        }

        if (_.isEmpty(Result)) {
          getErrors(MESSAGES.there_are_no_actions)
        }

        if (Result) {
          setActions(Result)
        }

        toggleLoader()
      })
      .catch((response: { data: { Result: []; Errors: string[] } }) => {
        const { data: { Errors = [] } = {} } = response
        getErrors(Errors.at(-1) || MESSAGES.there_has_been_an_error)
        toggleLoader()
      })
    // }

    return true
  }

  function handleUndoAction(nodeActionId: number | string | null) {
    toggleLoader(true, MESSAGES.loading)

    makeRequest({
      payload: {
        ...payload,
        requestMethod: 'post',
        method: 'UndoAction',
        body: {
          mfpId,
          nodeActionId: Number(nodeActionId)
        }
      }
    }).then((response) => {
      const { data: { Errors = [] } = {} } = response

      toggleLoader()

      if (!Errors.length) {
        slvyToast.success({
          message: MESSAGES.undo_action_has_been_completed_successfully,
          title: MESSAGES.undo_action,
          options: { containerId: 'dp' }
        })
        onReloadViewClick()
      } else {
        slvyToast.warning({
          message: Errors[0],
          title: MESSAGES.undo_action,
          options: { containerId: 'dp' }
        })
      }
    })
  }

  return (
    <div className="mcp-col dp-btn-group headerSection" data-testid="header-button-group">
      <PivotBuilderDropdown
        isVisiblePivotBuilderButton={isVisiblePivotBuilderButton}
        pluginId={pluginId}
        pluginStates={pluginStates}
        onPivotStateChanged={(param: { stateId: any }) => onPivotStateChanged(param)}
        onViewChangedCallback={(param: { View: 'PivotBuilder' }) => onViewChangedCallback(param)}
      />
      <HeaderButton
        extraClass="-change-scenario-btn"
        icon="slvy-ui-icon-transfer"
        isVisible={isVisibleChangeScenarioButton}
        label={MESSAGES.change_scenario}
        onClickHandle={displayScenarioTree}
      />
      <HeaderButton
        icon="slvy-ui-icon-recycle"
        label={MESSAGES.reload}
        onClickHandle={onReloadViewClick}
      />
      <HeaderButton
        extraClass={isVisibleActionList ? '-active' : ''}
        icon={isVisibleActionList ? 'slvy-ui-icon-times-extra-lt' : 'slvy-ui-icon-history'}
        isVisible={isVisibleActionsButton}
        label={MESSAGES.actions}
        onClickHandle={actionClickHandler}
      >
        {!_.isEmpty(actions) ? (
          <Actions
            actionLogsColumnName={actionLogsColumnName}
            actions={actions}
            userName={preferredUsername}
            onUndoAction={handleUndoAction}
          />
        ) : null}
      </HeaderButton>
      {isWorkInSubsetEnabled ? (
        <section className="d-flex -ws-ctn">
          {isScenarioEditable && isVisibleApplyChanges ? (
            <HeaderButton
              icon="slvy-ui-icon-check-circle-regular"
              label={MESSAGES.apply_changes}
              onClickHandle={onApplySubsetChangesHandle}
            />
          ) : null}
          {isVisibleDiscardChanges ? (
            <HeaderButton
              icon="slvy-ui-icon-times-circle-regular -red"
              label={MESSAGES.discard_changes}
              onClickHandle={onDiscardHandle}
            />
          ) : null}
        </section>
      ) : null}
    </div>
  )
}

export default HeaderButtonGroup
