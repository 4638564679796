/* eslint-disable no-redeclare */
import { AnyAction } from 'redux'

type Matchable<AC extends () => AnyAction> = AC & {
  type: ReturnType<AC>['type']
  match(action: AnyAction): action is ReturnType<AC>
}

function withMatcher<AC extends () => AnyAction>(actionCreator: AC): Matchable<AC>
function withMatcher<AC extends (...args: any[]) => AnyAction & { type: string }>(
  actionCreator: AC
): Matchable<AC>
function withMatcher<AC extends (...args: any[]) => AnyAction>(
  actionCreator: AC,
  type: ReturnType<AC>['type']
): Matchable<AC>
function withMatcher(actionCreator: Function & { type?: string }, _type?: string) {
  const type = _type ?? actionCreator.type ?? actionCreator().type
  return Object.assign(actionCreator, {
    type,
    match(action: AnyAction) {
      return action.type === type
    }
  })
}

export default withMatcher
