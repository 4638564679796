import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getPluginStates } from '@/actions/pluginstate'
import { selectCollection } from '@/crudoptV3'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

import highchartsMore from 'highcharts/highcharts-more'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsNodataDisplay from 'highcharts/modules/no-data-to-display'

highchartsMore(Highcharts)
highchartsExporting(Highcharts)
highchartsNodataDisplay(Highcharts)

class NxMBubblechart extends Component {
  constructor(props) {
    super(props)
    this.highchartRef = {}
    this.defaults = {
      credits: {
        enabled: true,
        text: `Solvoyo © ${new Date().getFullYear()}`,
        href: 'http://www.solvoyo.com',
        style: { fontSize: '12px' }
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      }
    }

    this.state = {
      title: null,
      subtitle: null
    }

    this.handleInitializeSeries = this.handleInitializeSeries.bind(this)
    this.handleAxisChanged = this.handleAxisChanged.bind(this)
    this.tooltipFormatter = this.tooltipFormatter.bind(this)
    this.applySeriesDataLabelFormatters = this.applySeriesDataLabelFormatters.bind(this)
    this.onPointClick = this.onPointClick.bind(this)
    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
    this.onSeriesHide = this.onSeriesHide.bind(this)
    this.onSeriesShow = this.onSeriesShow.bind(this)
    this.applyAxisFormatters = this.applyAxisFormatters.bind(this)
    this.handleInitialization = this.handleInitialization.bind(this)
    this.handleDataSeriesTypeChanged = this.handleDataSeriesTypeChanged.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    // The component updates only by one of these cases
    // 1- grid configuration has changed
    // 2- pluginState has changed
    // 3- plugin size has changed
    // 4- plugin data  has changed

    let nextPluginState = {}
    let pluginStateOwner = ''
    if (nextProps.pluginStates.isSuccess) {
      const { pluginStates: { data = [] } = {} } = nextProps
      if (data.length > 0) {
        nextPluginState = data[0].config.state
        pluginStateOwner = data[0].id
      }
    }
    if (
      !_.isEqual(this.props.settings.config, nextProps.settings.config) ||
      (nextProps.pluginStates.isSuccess &&
        pluginStateOwner === this.props.id &&
        JSON.stringify(nextPluginState) !== JSON.stringify(this.pluginState)) ||
      nextProps.size.height !== this.props.size.height ||
      !_.isEqual(this.props.pluginData, nextProps.pluginData) ||
      this.state.title !== nextState.title ||
      this.state.subtitle !== nextState.subtitle
    ) {
      return true
    }

    return false
  }

  componentDidMount() {
    // Trigger reading plugin states
    if (this.props.pluginStates.needFetch) {
      this.props.dispatch(this.props.pluginStates.fetch)
    }

    this.handlePointClick = this.props.registerEvent({
      key: 'handlePointClick',
      fn: this.handlePointClick.bind(this),
      returnTypes: _.transform(
        this.props.settings.query.fields,
        (result, field = {}) => {
          const { dataType = '' } = field
          result[field.fieldName] = PluginTypes.fromString(dataType)
        },
        {}
      )
    })

    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setSubtitle',
      fn: this.setSubtitle.bind(this),
      args: [{ name: 'subtitle', type: PluginTypes.string }]
    })

    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [
          this.handleChartTypeChanged,
          this.handleDataSeriesChanged,
          this.handleAxisChanged,
          this.handleSeriesFieldNameChanged,
          this.handleDataSeriesTypeChanged
        ],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition,
        diffMode: true
      })
    }
  }

  handlePointClick(value) {
    return value
  }

  setTitle({ title }) {
    if (this.state.title !== title) {
      this.setState({ title })
    }
  }

  setSubtitle({ subtitle }) {
    if (this.state.subtitle !== subtitle) {
      this.setState({ subtitle })
    }
  }

  componentWillUnmount() {
    this.highchartRef = null
  }

  handleDataSeriesChanged(value, initialData) {
    initialData.series = []

    const { series = [] } = value || {}
    _.forEach(series, (series, index) => {
      if (!initialData.series[index]) {
        initialData.series[index] = {
          fieldName: '',
          seriesProps: {
            type: ''
          }
        }
        // New added series is different from its initial value
        series.__Temp = 'tmp'
      }
    })
    return value
  }

  handleAxisChanged(value, initialData, previousValue, schemaHelper) {
    if (!this.xAxisValue) {
      this.xAxisValue = schemaHelper.getValueOfSchema(this.props.schema.properties.xAxis.items)

      this.yAxisValue = schemaHelper.getValueOfSchema(this.props.schema.properties.yAxis.items)
    }

    if (value && _.size(value.xAxis) > 0) {
      initialData.xAxis = []
      _.forEach(value.xAxis, (xAxis, index) => {
        if (!initialData.xAxis[index]) {
          initialData.xAxis[index] = {}
          _.assign(initialData.xAxis[index], this.xAxisValue)
          value.xAxis[index].axisId = index.toString()
        }
      })

      initialData.yAxis = []
      _.forEach(value.yAxis, (yAxis, index) => {
        if (!initialData.yAxis[index]) {
          initialData.yAxis[index] = {}
          _.assign(initialData.yAxis[index], this.yAxisValue)
          value.yAxis[index].axisId = index.toString()
        }
      })
    }
    return value
  }

  handleInitializeSeries(value, initialData, previousValue, schemaHelper) {
    const typesSchema = this.props.schema.properties.series.items.properties.seriesProps.oneOf

    const { series = [] } = value || {}

    _.forEach(series, (series, index) => {
      let selectedSeriesType = ' '
      if (series.seriesProps && series.seriesProps.type) {
        selectedSeriesType = series.seriesProps.type
      }
      const initialSeries = initialData.series[index]

      _.forEach(typesSchema, function (typeSchema, index) {
        const seriesType = typeSchema.properties.type.enum[0]
        if (seriesType === selectedSeriesType) {
          const typeInitialVal = schemaHelper.getValueOfSchema(typeSchema)
          _.assign(initialSeries.seriesProps, typeInitialVal)
          initialSeries.seriesProps.type = ' '
          return false
        }
      })
    })
    return value
  }

  getDifferenceObject(initial, modified) {
    for (const prop in initial) {
      if (!initial.hasOwnProperty(prop)) continue
      if (!modified.hasOwnProperty(prop)) continue
      if (_.isEqual(initial[prop], modified[prop])) {
        delete initial[prop]
        delete modified[prop]
      } else if (typeof initial[prop] === 'object') {
        this.getDifferenceObject(initial[prop], modified[prop])
      }
    }
  }

  getDiffObject(initial, modified) {
    const initialClone = _.cloneDeep(initial)
    const modifiedClone = _.cloneDeep(modified)

    this.getDifferenceObject(initialClone, modifiedClone)
    return modifiedClone
  }

  assignDiffToObject(initial, diff) {
    for (const prop in initial) {
      if (!initial.hasOwnProperty(prop)) continue
      if (!diff.hasOwnProperty(prop)) continue
      if (!_.isEqual(initial[prop], diff[prop])) {
        if (typeof initial[prop] === 'object') {
          this.assignDiffToObject(initial[prop], diff[prop])
        } else {
          initial[prop] = diff[prop]
        }
      }
    }
  }

  handleDataSeriesTypeChanged(value, initialData, previousValue, schemaHelper) {
    // Type of s series changes.
    // Get all the modfied parameters of the old type
    // Apply them to the new type, if they exist on the new type

    const { series: { length: prevSeriescount = 0 } = {} } = previousValue || {}
    const { series: { length: actualSeriescount = 0 } = {} } = value || {}

    const seriesCountChanged = prevSeriescount !== actualSeriescount
    // Ignore when aseries count changed
    if (!seriesCountChanged) {
      const { series = [] } = value || {}

      _.forEach(series, (series, index) => {
        const { seriesProps: { type: actualSeriesType = ' ' } = {} } = series || {}
        const previousseries = previousValue.series[index]
        const { seriesProps: { type: prevSeriesType = ' ' } = {} } = previousseries || {}

        // Only when the type of a series has changed
        if (actualSeriesType !== prevSeriesType) {
          const typesSchema = this.props.schema.properties.series.items.properties.seriesProps.oneOf

          const actualTypeSchema = _.find(typesSchema, (typeSchema) => {
            return actualSeriesType === typeSchema.properties.type.enum[0]
          })

          const prevTypeSchema = _.find(typesSchema, (typeSchema) => {
            return prevSeriesType === typeSchema.properties.type.enum[0]
          })

          if (actualTypeSchema && prevTypeSchema) {
            const actualTypeInitialVal = schemaHelper.getValueOfSchema(actualTypeSchema)
            const prevTypeInitialVal = schemaHelper.getValueOfSchema(prevTypeSchema)

            const changedProperties = this.getDiffObject(prevTypeInitialVal, series.seriesProps)

            _.assign(series.seriesProps, actualTypeInitialVal)
            this.assignDiffToObject(series.seriesProps, changedProperties)
          }
        }
      })
    }
    return value
  }

  handleSeriesFieldNameChanged(value, initialData, previousValue) {
    if (
      !previousValue ||
      !value.series ||
      !previousValue.series ||
      value.series.length !== previousValue.series.length
    ) {
      return value
    }
    _.forEach(value.series, (series, index) => {
      if (
        series.fieldName &&
        previousValue.series[index].fieldName !== series.fieldName &&
        series.seriesProps.name === previousValue.series[index].fieldName
      ) {
        series.seriesProps.name = series.fieldName
      }
    })
    return value
  }

  handleChartTypeChanged(value, initialData, previousValue) {
    const { chart: { type: newType = null } = {} } = value || {}
    const { chart: { type: prevType = null } = {} } = previousValue || {}

    if (newType != null && prevType != null && newType !== prevType) {
      const { series = [] } = value
      _.forEach(series, (series) => {
        if (series.seriesProps.type === prevType) {
          series.seriesProps.type = newType
        }
      })
    }
    return value
  }

  handleInitialization(value) {
    this.removeExtraAttributes(value, this.props.schema)
    return value
  }

  handleSynchronizeDataDefinition(fields, value, schemaHelper, initialConfig) {
    const newValue = _.cloneDeep(value)

    if (!newValue.series) {
      newValue.series = []
    }

    _.remove(newValue.series, (series) => {
      return !series.fieldName || series.fieldName === ''
    })

    initialConfig.series = []

    _.forEach(fields, (field) => {
      // Check if the field is alraedy used as a column
      const existingSeries = _.find(newValue.series, (series) => {
        return series.fieldName === field.fieldName
      })

      // Create a series if its field does not exist
      if (
        !existingSeries &&
        (field.dataType === 'int' ||
          field.dataType === 'double' ||
          field.dataType === 'float' ||
          field.dataType === 'decimal' ||
          field.dataType === 'long' ||
          field.dataType === 'short')
      ) {
        newValue.series.push({
          fieldName: field.fieldName,
          seriesProps: {
            name: field.fieldName
          }
        })
      }
    })

    return newValue
  }

  executeCustomAggregation(customAggregation, data) {
    const aggFunc = `
      function table(i, col){
        return records[i][col]
      }`
    try {
      const funcBody = window.atob(customAggregation)
      // eslint-disable-next-line no-new-func
      const customAggregationFunction = new Function('records', 'rowCount', funcBody + aggFunc)
      return customAggregationFunction(data, _.size(data))
    } catch (err) {
      console.warn('Custom Aggregation Failed', err)
    }
  }

  calculateSummary(
    pluginData,
    fieldName,
    axisFieldName,
    categories,
    summaryType,
    customAggregation
  ) {
    const axisValues = _.transform(
      pluginData,
      (result, row) => {
        const xCategory = row[axisFieldName]
        result[xCategory].push(row)
      },
      _.transform(
        categories,
        (result, category) => {
          result[category] = []
        },
        {}
      )
    )

    return _.map(axisValues, (value) => {
      let summary = 0

      if (customAggregation) {
        summary = this.executeCustomAggregation(customAggregation, value)
      } else if (summaryType === 'sum') {
        summary = _.reduce(
          value,
          function (sum, row) {
            return sum + row[fieldName]
          },
          0
        )
      } else if (summaryType === 'avg') {
        if (_.size(value) > 0) {
          summary = _.reduce(
            value,
            function (sum, row) {
              return sum + row[fieldName]
            },
            0
          )
          summary /= _.size(value)
        }
      }
      return summary
    })
  }

  getSummary(
    pluginData,
    fieldName,
    xFieldName,
    yFieldName,
    xCategories,
    yCategories,
    summaryType,
    customAggregation
  ) {
    const xSummary = this.calculateSummary(
      pluginData,
      fieldName,
      xFieldName,
      xCategories,
      summaryType,
      customAggregation
    )
    const ySummary = this.calculateSummary(
      pluginData,
      fieldName,
      yFieldName,
      yCategories,
      summaryType,
      customAggregation
    )
    return { xSummary, ySummary }
  }

  getData(chartConfig) {
    const {
      chart: { ignoreSeriesWithNullData = true } = {},
      series = {},
      xField: { fieldName: xFieldName, sortIndex: xFieldSortIndex } = {},
      yField: { fieldName: yFieldName, sortIndex: yFieldSortIndex } = {}
    } = chartConfig || {}
    const { pluginData = [] } = this.props || {}

    // Delete unused series
    _.remove(series, function (series) {
      return !series
    })

    // Initialize series
    _.forEach(series, (series) => {
      series.data = []
      if (series.seriesProps) {
        _.assign(series, series.seriesProps)
      }
      delete series.seriesProps

      // Set showOnlyInTooltip
      if (series.showOnlyInTooltip) {
        series.visible = false
        series.showInLegend = false
      }
    })

    const xCategories = []
    const yCategories = []

    if (!chartConfig.xAxis) {
      chartConfig.xAxis = []
      chartConfig.xAxis.push()
      chartConfig.xAxis[0].categories = []
    }

    if (!chartConfig.xAxis[0].categories) {
      chartConfig.xAxis[0].categories = []
    }

    if (!chartConfig.yAxis) {
      chartConfig.yAxis = []
      chartConfig.yAxis.push()
      chartConfig.yAxis[0].categories = []
    }

    if (!chartConfig.yAxis[0].categories) {
      chartConfig.yAxis[0].categories = []
    }

    // Get axis categories
    _.forEach(pluginData, function (row) {
      // Set values to x axis
      if (xFieldName && xFieldName in row) {
        if (!_.find(xCategories, { value: row[xFieldName] })) {
          xCategories.push({
            value: row[xFieldName],
            sortIndex: row[xFieldSortIndex]
          })
        }

        if (!_.find(yCategories, { value: row[yFieldName] })) {
          yCategories.push({
            value: row[yFieldName],
            sortIndex: row[yFieldSortIndex]
          })
        }
      }
    })

    // Set axis categories after sorting
    chartConfig.xAxis[0].categories = _.map(_.orderBy(xCategories, 'sortIndex'), (category) => {
      return category.value
    })

    chartConfig.yAxis[0].categories = _.map(
      _.orderBy(yCategories, 'sortIndex', 'desc'),
      (category) => {
        return category.value
      }
    )

    // Get point data
    _.forEach(pluginData, function (row) {
      _.forEach(series, (series) => {
        const { fieldName = null } = series || {}
        if (_.has(row, fieldName)) {
          const xIndex = _.indexOf(chartConfig.xAxis[0].categories, row[xFieldName])
          const yIndex = _.indexOf(chartConfig.yAxis[0].categories, row[yFieldName])
          series.data.push([xIndex, yIndex, row[fieldName]])
        }
      })
    })

    const visibleSeries = _.find(series, (series) => {
      return !_.has(series, 'visible') || series.visible === true
    })

    if (visibleSeries) {
      const {
        fieldName: visibleSeriesFieldName,
        summaryType = 'sum',
        customAggregation
      } = visibleSeries || {}

      const { xSummary, ySummary } =
        this.getSummary(
          pluginData,
          visibleSeriesFieldName,
          xFieldName,
          yFieldName,
          chartConfig.xAxis[0].categories,
          chartConfig.yAxis[0].categories,
          summaryType,
          customAggregation
        ) || {}

      chartConfig.xAxis[1].categories = xSummary
      chartConfig.yAxis[1].categories = ySummary

      chartConfig.xAxis[0].min = 0
      chartConfig.yAxis[0].min = 0
      chartConfig.xAxis[0].max = _.size(xCategories) - 1
      chartConfig.yAxis[0].max = _.size(yCategories) - 1

      chartConfig.xAxis[1].min = 0
      chartConfig.yAxis[1].min = 0
      chartConfig.xAxis[1].max = _.size(xCategories) - 1
      chartConfig.yAxis[1].max = _.size(yCategories) - 1
    }

    // Remove series which have null data
    if (ignoreSeriesWithNullData) {
      _.remove(series, (series) => {
        const { fieldName } = series
        return _.reduce(
          pluginData,
          (result, row) => {
            if (_.has(row, fieldName) && row[fieldName] !== null) {
              result = false
            }
            return result
          },
          true
        )
      })
    }
  }

  getDynamicPlotBands(config) {
    _.forEach(config.xAxis, (xAxis) => {
      _.forEach(xAxis.plotBands, (plotBand) => {
        const { bandField = null, bandFieldAsLabel = false } = plotBand

        if (bandField) {
          let from = null
          let to = null
          let bandName
          _.forEach(this.props.pluginData, (row, index) => {
            const bandValue = row[plotBand.bandField]

            if (!bandName && bandValue) {
              bandName = bandValue
              from = index
            }

            if (bandName && bandValue === bandName) {
              to = index
            }
          })

          if (bandFieldAsLabel && bandName) {
            plotBand.label.text = bandName
          }

          if (from !== null && to !== null) {
            plotBand.from = _.toNumber(from)
            plotBand.to = _.toNumber(to)
            if (this.props.settings.config.xField) {
              plotBand.from -= 0.5
              plotBand.to += 0.5
            } else {
              plotBand.to += 1
            }
          }
        }
      })
    })
  }

  applyAxisFormatters(config) {
    const { getFormattedValue } = this.props
    const { xAxis: xAxisList = [], yAxis: yAxisList = [], series: seriesConfig = [] } = config
    const that = this

    const axisFormatter = function () {
      const { chart: { series = [] } = {} } = this
      const visibleSeries = _.find(series, (series) => {
        return !_.has(series, 'visible') || series.visible === true
      })
      // We cannot get the fieldName from the actual chart so get the field name from series config
      const visibleSeriesConfig = _.find(seriesConfig, {
        name: visibleSeries.name
      })
      return visibleSeriesConfig && visibleSeriesConfig.fieldName
        ? getFormattedValue(visibleSeriesConfig.fieldName, this.value)
        : this.value
    }

    // Apply x axis formats
    if (_.size(xAxisList) >= 2) {
      const { labels = {} } = xAxisList[1]
      labels.formatter = axisFormatter
      xAxisList[1].labels = labels
    }

    // Apply y axis formats
    if (_.size(yAxisList) >= 2) {
      const { labels = {} } = yAxisList[1]
      labels.formatter = axisFormatter
      yAxisList[1].labels = labels
    }
  }

  applySeriesDataLabelFormatters(config) {
    const { getFormattedValue } = this.props
    const { series: seriesConfigs = [] } = config
    const that = this

    const labelFormatter = function () {
      const { series: { name: seriesName } = {} } = this

      const seriesConfig = _.find(seriesConfigs, {
        name: seriesName
      })

      return seriesName ? getFormattedValue(seriesConfig.fieldName, this.point.z) : this.point.z
    }

    _.forEach(seriesConfigs, (series) => {
      const { dataLabels = {}, dataLabels: { enabled: dataLabelsEnabled = false } = {} } = series
      if (dataLabelsEnabled) {
        dataLabels.formatter = labelFormatter
      }
    })
  }

  applyYAxisMins(config) {
    const { series } = config
    _.map(series, (series) => {
      if (_.has(series, 'minField')) {
        const firstRow = this.getFirstDataRow()
        if (_.has(firstRow, series.minField)) {
          const { yAxis } = config
          _.forEach(yAxis, (yAxis) => {
            yAxis.min = firstRow[series.minField]
          })
        }
      }
    })
  }

  onPointClick(event) {
    let selectedRow = null
    const { config: { xField = '', yField = '' } = {} } = this.props.settings
    const category =
      typeof event.point.category === 'object' ? event.point.category.name : event.point.category
    if (xField && yField) {
      const { chart: { xAxis = [], yAxis = [] } = {} } = this.highchartRef
      const { chart } = this.highchartRef

      const { categories: xAxisCategories = [] } = xAxis[0]
      const { categories: yAxisCategories = [] } = yAxis[0]
      const xCategory =
        typeof xAxisCategories[event.point.x] === 'object'
          ? xAxisCategories[event.point.x].name
          : xAxisCategories[event.point.x]
      const yCategory =
        typeof yAxisCategories[event.point.y] === 'object'
          ? yAxisCategories[event.point.y].name
          : yAxisCategories[event.point.y]
      // Use the selected x value
      _.forEach(this.props.pluginData, (row) => {
        if (row[xField.fieldName] === xCategory && row[yField.fieldName] === yCategory) {
          selectedRow = row
          return false
        }
      })
      if (selectedRow) {
        this.handlePointClick(selectedRow)
      }
    } else {
      // There is no xfield use highchart enumeration
      if (this.props.pluginData.length >= category + 1) {
        this.handlePointClick(this.props.pluginData[category])
      }
    }
  }

  onSeriesHide(e) {
    if (this.selectingSeries) {
      this.selectingSeries = false
      return
    }
    const { chart: { series = [] } = {} } = this.highchartRef

    const seriesState = _.map(series, (series) => {
      return {
        name: series.name,
        visible: series.options.showOnlyInTooltip ? false : series.visible
      }
    })

    const newState = {
      series: seriesState
    }

    this.pluginState = newState
    this.savePluginstate(newState)
  }

  onSeriesShow(e) {
    const { target: { name: seriesName } = {} } = e || {}
    const { chart: { series = [], xAxis = [], yAxis = [] } = {} } = this.highchartRef

    const otherSeries = _.filter(series, (series) => {
      return series.name !== seriesName
    })

    this.selectingSeries = true

    // Hide other series
    _.forEach(otherSeries, (series) => {
      if (series.visible) {
        series.hide()
      }
    })

    // Update summaries for the selected series
    const {
      settings: {
        config: {
          series: seriesConfig = {},
          xField: { fieldName: xFieldName } = {},
          yField: { fieldName: yFieldName } = {}
        } = {}
      } = {},
      pluginData = []
    } = this.props

    const { categories: xAxisCategories = [] } = xAxis[0]
    const { categories: yAxisCategories = [] } = yAxis[0]

    const visibleSeriesConfig = _.find(seriesConfig, (seriesConfig) => {
      const { seriesProps: { name: seriesConfigName } = {} } = seriesConfig
      return seriesConfigName === seriesName
    })

    const {
      fieldName: visibleSeriesFieldName,
      seriesProps: { summaryType = 'sum', customAggregation } = {}
    } = visibleSeriesConfig

    const { xSummary, ySummary } =
      this.getSummary(
        pluginData,
        visibleSeriesFieldName,
        xFieldName,
        yFieldName,
        xAxisCategories,
        yAxisCategories,
        summaryType,
        customAggregation
      ) || {}

    xAxis[1].update({
      categories: xSummary
    })
    yAxis[1].update({
      categories: ySummary
    })

    // Save plugin state
    const seriesState = _.map(series, (series) => {
      return {
        name: series.name,
        visible: series.options.showOnlyInTooltip ? false : series.visible
      }
    })

    const newState = {
      series: seriesState
    }
    this.pluginState = newState
    this.savePluginstate(newState)
  }

  onLegendItemClick() {
    // Do not allow the user to display an empty chart with no series
    if (this.visible) {
      return false
    }
  }

  getSeriesName(series) {
    // Find series config through fieldName and not through series name
    // Since series name can be a template.
    const { userOptions: { fieldName: seriesFieldName = '' } = {} } = series
    return seriesFieldName || series.name
  }

  tooltipFormatter(point) {
    const {
      config: chartConfig = {},
      config: { xAxis: xAxisConfig = [], yAxis: yAxisConfig } = {}
    } = this.props.settings || {}

    const {
      point: { x, y } = {},
      series: { chart: { series: allSeries = [], xAxis = [], yAxis = [] } = {} } = {}
    } = point

    const { title: { text: xAxistitle = 'Top' } = {} } = xAxisConfig[0]
    const { title: { text: yAxistitle = 'Left' } = {} } = yAxisConfig[0]

    const xAxistitleUpdated = this.replaceTemplate(xAxistitle, this.getFirstDataRow())

    const yAxistitleUpdated = this.replaceTemplate(yAxistitle, this.getFirstDataRow())

    const { categories: xAxisCategories = [] } = xAxis[0]
    const xAxisCategory = xAxisCategories[x]
    const { categories: yAxisCategories = [] } = yAxis[0]
    const yAxisCategory = yAxisCategories[y]

    const category = `${xAxistitleUpdated}: ${xAxisCategory} - ${yAxistitleUpdated}: ${yAxisCategory}`

    let tooltip = `<span style="font-size: 120%">${category}</span><br/>`

    const points = _.transform(
      allSeries,
      (res, series) => {
        const { points = [] } = series
        const sharedPoint = _.find(points, { x, y })
        if (sharedPoint) {
          res.push(sharedPoint)
        }
      },
      []
    )

    _.each(points, (p) => {
      const seriesName = this.getSeriesName(p.series)

      tooltip += this.formatPointValue(seriesName, p.z, p.color, chartConfig)
    })
    return tooltip
  }

  formatPointValue(name, value, color, chartConfig) {
    const formattedValue = this.getFormattedSeriesValue(name, value, chartConfig)

    const series = _.find(chartConfig.series, (series) => series.fieldName === name)

    const { seriesProps: { tooltip: { pointFormat = null } = {} } = {} } = series || {}

    let tooltip = `<span style="color:${color}">\u25CF</span> ${name}: <b>${formattedValue}</b><br/> `
    if (pointFormat) {
      tooltip = this.unescapeUnicode(pointFormat)
    }
    tooltip = _.replace(tooltip, '{point.color}', color)
    tooltip = _.replace(tooltip, '{series.color}', color)
    tooltip = _.replace(tooltip, '{series.name}', name)
    tooltip = _.replace(tooltip, '{point.y}', formattedValue)

    return tooltip
  }

  unescapeUnicode(str) {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function (g, m1) {
      return String.fromCharCode(parseInt(m1, 16))
    })
  }

  getFormattedSeriesValue(name, value, chartConfig) {
    const { getFormattedValue } = this.props
    // Get field name from series name
    const series = _.find(chartConfig.series, (series) => series.fieldName === name)

    const { fieldName = null } = series || {}

    return fieldName ? getFormattedValue(series.fieldName, value) : value
  }

  removeEmptyConfig(config) {
    for (const prop in config) {
      if (!config.hasOwnProperty(prop)) continue

      if (config[prop] === '') {
        delete config[prop]
      } else if (typeof config[prop] === 'object') {
        this.removeEmptyConfig(config[prop])
      }
    }
  }

  savePluginstate(pluginState) {
    const { props: { client, id, pluginStates, params: { catalogId } = {} } = {} } = this
    const clientUrl = '/pluginstateupsert'
    const clientData = {
      name: `chart state ${id}`,
      pluginId: id,
      catalogId,
      config: {
        state: pluginState,
        stateId: 1
      }
    }
    return client.post(clientUrl, { data: clientData }).then(
      function () {
        this.props.dispatch(pluginStates.fetch)
      }.bind(this)
    )
  }

  getLoadedPluginStates() {
    if (this.props.pluginStates.isSuccess && this.props.pluginStates.data.length > 0) {
      return this.props.pluginStates.data[0].config.state
    }
  }

  replaceTemplate(text, data) {
    const regExp = /\{([^}]+)\}/g

    const matches = text.match(regExp)

    if (!matches) return text

    for (let i = 0; i < matches.length; i++) {
      const variableName = matches[i].substr(1, matches[i].length - 2)

      if (_.has(data, variableName)) {
        const value = data[variableName]

        if (value) {
          text = text.replace(matches[i], value)
        } else {
          text = text.replace(matches[i], '')
        }
      } else {
        text = text.replace(matches[i], '')
      }
    }

    return text
  }

  getFirstDataRow() {
    return this.props.pluginData && this.props.pluginData[0]
  }

  applyTitleTemplates(config) {
    // override title
    if (this.state.title) {
      if (!config.title) {
        config.title = {}
      }
      config.title.text = this.state.title
    }

    // apply templates to title
    if (config.title && config.title.text) {
      config.title.text = this.replaceTemplate(config.title.text, this.getFirstDataRow())
    }

    // if the plugin is maximized apply max style for title
    if (this.props.isMaximized && config.title && config.title.maxStyle) {
      config.title.style = config.title.maxStyle
    }
  }

  applySubtitleTemplates(config) {
    // override subtitle
    if (this.state.subtitle) {
      if (!config.subtitle) {
        config.subtitle = {}
      }
      config.subtitle.text = this.state.subtitle
    }

    // apply templates to title
    if (config.subtitle && config.subtitle.text) {
      config.subtitle.text = this.replaceTemplate(config.subtitle.text, this.getFirstDataRow())
    }
  }

  applyAxisTitleTemplates(config) {
    const { xAxis = [], yAxis = [] } = config

    _.forEach(xAxis, (xAxis) => {
      if (xAxis.title && xAxis.title.text) {
        xAxis.title.text = this.replaceTemplate(xAxis.title.text, this.getFirstDataRow())
      }
    })

    _.forEach(yAxis, (yAxis) => {
      if (yAxis.title && yAxis.title.text) {
        yAxis.title.text = this.replaceTemplate(yAxis.title.text, this.getFirstDataRow())
      }
    })
  }

  applySeriesNameTemplates(config) {
    _.forEach(config.series, (series) => {
      if (series.name) {
        series.name = this.replaceTemplate(series.name, this.getFirstDataRow())
      }
    })
  }

  applyNxMSettings(config) {
    const { xAxis = [], yAxis = [] } = config

    _.forEach(xAxis, (xAxis) => {
      xAxis.lineWidth = 1
      xAxis.gridLineWidth = 1
    })

    _.forEach(yAxis, (yAxis) => {
      yAxis.lineWidth = 1
      yAxis.gridLineWidth = 1
    })
  }

  apply3DMarkers(config) {
    _.forEach(config.series, (series, index) => {
      let { color } = series
      if (!color) {
        color = Highcharts.getOptions().colors[index]
      }
      series.marker = {
        fillColor: {
          radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
          stops: [
            [0, 'rgba(255,255,255,0.5)'],
            [1, Highcharts.Color(color).setOpacity(0.5).get('rgba')]
          ]
        }
      }
    })
  }

  createdHiddenSeries(config) {
    // We create these dummy series to make sure that all x and y axis are used
    if (_.size(config.xAxis) === 2 && _.size(config.yAxis) === 2) {
      if (!config.series) {
        config.series = []
      }
      config.series.push({
        name: 'hidden1',
        xAxis: 0,
        yAxis: 0,
        showInLegend: false
      })

      config.series.push({
        name: 'hidden2',
        xAxis: 1,
        yAxis: 1,
        showInLegend: false
      })
    }
  }

  removeExtraAttributes(config, schema) {
    const { series = [] } = config

    const {
      properties: {
        series: {
          items: { properties: { seriesProps: { oneOf: seriesSchemas = [] } = {} } = {} } = {}
        } = {}
      } = {}
    } = schema || {}

    _.forEach(series, (series) => {
      const { seriesProps = {} } = series
      const { dataLabels = {} } = seriesProps
      const seriesSchema = _.find(seriesSchemas, (seriesSchema) => {
        return seriesSchema.properties.type.enum[0] === seriesProps.type
      })
      const dataLabelsSchema = seriesSchema.properties.dataLabels

      seriesProps.dataLabels = _.pick(dataLabels, _.keys(dataLabelsSchema.properties))
      series.seriesProps = _.pick(seriesProps, _.keys(seriesSchema.properties))
    })
  }

  alignYAxisZeros(config) {
    const { yAxis: sourceAxis = [], series = [], chart: { alignYAxisZero = false } = {} } = config

    if (!alignYAxisZero) {
      return
    }

    const yAxisList = _.map(sourceAxis, (yAxis) => {
      return {
        axisId: yAxis.axisId,
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MIN_SAFE_INTEGER
      }
    })

    _.forEach(series, (series) => {
      const { yAxis = 0, data = [] } = series
      const yAxisOfSeries = _.find(yAxisList, { axisId: yAxis.toString() })
      if (yAxisOfSeries) {
        const maxSeriesValue = _.max(data)
        const minSeriesValue = _.min(data)

        if (minSeriesValue < yAxisOfSeries.min) {
          yAxisOfSeries.min = minSeriesValue
        }

        if (maxSeriesValue > yAxisOfSeries.max) {
          yAxisOfSeries.max = maxSeriesValue
        }
      }
    })

    _.forEach(yAxisList, (yAxis) => {
      const { scaledMin, scaledMax } = this.interpolateToMax(yAxis.min, yAxis.max)
      yAxis.scaledMin = scaledMin
      yAxis.scaledMax = scaledMax
    })

    const absScaledMin = _.minBy(yAxisList, (yAxis) => {
      return yAxis.scaledMin
    }).scaledMin

    const absScaledMax = _.maxBy(yAxisList, (yAxis) => {
      return yAxis.scaledMax
    }).scaledMax

    _.forEach(yAxisList, (yAxis) => {
      const unitChange = (yAxis.max - yAxis.min) / (yAxis.scaledMax - yAxis.scaledMin)
      yAxis.newMax = yAxis.max + (absScaledMax - yAxis.scaledMax) * unitChange
      yAxis.newMin = yAxis.min + (absScaledMin - yAxis.scaledMin) * unitChange
    })

    _.forEach(yAxisList, (yAxis) => {
      const sourceAxisItem = _.find(sourceAxis, (sourceAxis) => {
        return sourceAxis.axisId.toString() === yAxis.axisId
      })
      sourceAxisItem.min = yAxis.newMin
      sourceAxisItem.max = yAxis.newMax
    })
  }

  interpolateToMax(min, max) {
    const absMax = 100
    const absMin = -100

    const distanceMaxtoToZero = max - 0
    const distanceMintoToZero = 0 - min

    const interpolationFactor =
      distanceMaxtoToZero >= distanceMintoToZero ? absMax / max : absMin / min

    return {
      scaledMin: min * interpolationFactor,
      scaledMax: max * interpolationFactor
    }
  }

  updateAxisIds(config) {
    const updateKey = (axisDirection) => {
      _.forEach(axisDirection, (axisKey) => {
        if (axisKey.id) {
          axisKey.axisId = axisKey.id
          delete axisKey.id
        }
      })
    }

    // Ex: 'config.xAxis[0].id' will be 'config.xAxis[0].axisId'
    // usage of 'id' gives error on highchart version 10.1.0
    if (config.xAxis && config.xAxis.length) updateKey(config.xAxis)
    if (config.yAxis && config.yAxis.length) updateKey(config.yAxis)
    if (config.zAxis && config.zAxis.length) updateKey(config.zAxis)
  }

  render() {
    const { config: pluginConfig = {} } = this.props.settings || {}

    const config = _.cloneDeep(pluginConfig)

    // Apply loaded plugin states
    const pluginState = this.getLoadedPluginStates()

    if (pluginState && pluginState.series) {
      _.forEach(config.series, (series) => {
        const { seriesProps = {}, seriesProps: { name: seriesName } = {} } = series
        const seriesState = _.find(pluginState.series, { name: seriesName })
        if (seriesState) {
          seriesProps.visible = seriesState.visible
        }
      })
    }
    if (!_.isEmpty(pluginConfig)) {
      this.getData(config)
    }

    const pointClick = {
      events: {
        click: this.onPointClick
      }
    }

    _.forEach(config.series, (series) => {
      // Add click handlers
      series.point = pointClick
      series.events = {
        hide: this.onSeriesHide,
        show: this.onSeriesShow,
        legendItemClick: this.onLegendItemClick
      }
      // Remove default value from config
      if (series.zones === '[...]') delete series.zones
      if (series.tooltip === '[...]') delete series.tooltip
    })

    _.set(config, 'plotOptions.series.states.inactive.opacity', 1)

    // TODO remove somewhere else
    delete config.datafields
    delete config.xField
    delete config.yField

    // Display tooltip in a grid
    if (!config.tooltip) {
      config.tooltip = {}
    }

    const that = this

    config.tooltip.formatter = function () {
      return that.tooltipFormatter(this)
    }
    this.updateAxisIds(config)

    this.applyAxisFormatters(config)

    this.applySeriesDataLabelFormatters(config)

    this.getDynamicPlotBands(config)

    this.applySeriesNameTemplates(config)

    this.applyTitleTemplates(config)

    this.applySubtitleTemplates(config)

    this.alignYAxisZeros(config)

    this.applyYAxisMins(config)

    this.createdHiddenSeries(config)

    this.applyAxisTitleTemplates(config)

    this.applyNxMSettings(config)

    this.apply3DMarkers(config)

    // disable credits
    config.credits = this.defaults.credits
    const { chart: { showCredits = true } = {} } = pluginConfig
    config.credits.enabled = showCredits

    // display no data
    config.noData = this.defaults.noData

    // set empty text
    const { chart: { emptyText = 'No Data to Display' } = {} } = config
    config.lang = {
      noData: emptyText || ' '
    }

    // move export button to the top-left corner
    if (!config.exporting) {
      config.exporting = {}
    }

    config.exporting.buttons = {
      contextButton: {
        align: 'left'
      }
    }

    // Delete empty ('') properties
    this.removeEmptyConfig(config)

    return (
      <div className="highcharts-container">
        <HighchartsReact
          highcharts={Highcharts}
          ref={(el) => {
            this.highchartRef = el
          }}
          options={config}
          allowChartUpdate
          immutable
        />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  id: props.id,
  size: props.size,
  onReady: props.onReady,
  schema: props.schema,
  client: props.client,
  params: props.params,
  isMaximized: props.isMaximized,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(
  connect((state, ownProps) => {
    return {
      pluginStates: selectCollection(getPluginStates(ownProps.id), state.model3)
    }
  })(NxMBubblechart),
  selectConnectorProps
)

export const Self = NxMBubblechart
