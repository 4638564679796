import { has, isDate, isEmpty, isNil, isNumber, size, transform, omit, omitBy } from 'lodash'
import moment from 'moment'
import { CSSProperties } from 'react'
import { IRowNode } from 'ag-grid-community'
import { objectKeys } from '@/ts/utils'
import { PluginTypes } from '@/BasePlugin'
import { isSubstituteFieldMatch } from '../lookup'
import {
  CUSTOM_FIELDS,
  DEFAULT_DATE_FORMAT,
  footerBarHeight,
  paginationPanelHeight,
  ORANGE,
  RED,
  GREEN,
  BLUE
} from '../../constants'
import { AG_GRID_ENUMS } from '../../enums'
import {
  CommonRegisterMethodArg,
  GetFieldConfigsValuesResult
} from '../../hooks/registerEventsMethods/registerEventsMethods.types'
import {
  ColumnSchemaSettings,
  GetFieldConfigsValuesProps,
  GetRegisterEventFieldConfigsParams,
  GridRef,
  IRowData,
  IRowDataGrid,
  SlvyColDef,
  SlvyMasterColDef,
  TfieldConfigs,
  TrowDataValue
} from '../../types'
import {
  GetFormattedValueGridProps,
  GetGridEditableProps,
  GetIsProgressAndNotNumberProps,
  GetRegisterEventFieldConfigsResult,
  IsWarningThresholdExceededParams,
  OppositeVariables,
  ReplaceTemplateProps,
  GetBarsTotalHeightProps
} from './common.types'
import { EditableField } from '@/containers/PluginSettings/components/EditableFields/EditableFields.types'

const { CUSTOM_ROW_INFO, DELETE_COLUMN, CHECKBOX_SELECTION_COLUMN } = AG_GRID_ENUMS

const compareValuesCaseInsensitive = (prev?: string, next?: string) => {
  const upperCasePrev = prev?.toUpperCase?.() ?? ''
  const upperCaseNext = next?.toUpperCase?.() ?? ''

  if (upperCasePrev < upperCaseNext) {
    return -1
  }

  if (upperCasePrev > upperCaseNext) {
    return 1
  }

  return 0
}

const compareOppositeVariables = ({ enabled, notEnabled }: OppositeVariables) => {
  const isEnabledValid = enabled !== undefined && enabled !== null
  const isNotEnabledValid = notEnabled !== undefined && notEnabled !== null
  if (isNotEnabledValid || isEnabledValid) {
    let isEnabled = false
    if (isEnabledValid) {
      isEnabled = enabled
    }
    if (isNotEnabledValid) {
      isEnabled = !notEnabled
    }
    return isEnabled
  }
  return null
}

const getRegisterEventFieldConfigsParams = ({
  cellParams,
  getFieldType
}: GetRegisterEventFieldConfigsParams) => {
  return transform(cellParams, (result: GetRegisterEventFieldConfigsResult, _value, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = getFieldType(key, true)
  })
}

const getRegisterMethodFieldConfigsParams = (fieldConfigs: TfieldConfigs) => {
  return transform(
    fieldConfigs,
    (result: CommonRegisterMethodArg[], field) => {
      const { dataType, fieldName } = field
      result.push({
        name: fieldName,
        type: PluginTypes.fromString(dataType)
      })
    },
    []
  )
}

const getFieldConfigsValues = ({ fieldConfigs, rows }: GetFieldConfigsValuesProps) => {
  return transform(
    fieldConfigs,
    (result: GetFieldConfigsValuesResult, { fieldName }) => {
      const fieldValues: TrowDataValue[] | null = []
      rows?.forEach?.((row) => {
        if (fieldName in row) {
          fieldValues.push(row[fieldName])
        }
      })

      // eslint-disable-next-line no-param-reassign
      result[fieldName] = fieldValues.length > 0 ? fieldValues : null
    },
    {}
  )
}

const formatDate = (data: IRowDataGrid) => {
  const newData: Exclude<IRowDataGrid, Date> = {}
  objectKeys(data).forEach((key) => {
    const isDateValue = isDate(data[key])
    newData[key] = isDateValue ? moment(data[key] as Date).format(DEFAULT_DATE_FORMAT) : data[key]
  })
  return newData
}

const getEnableInfo = (rowData: IRowData | undefined, dataField: undefined | string) => {
  if (dataField) {
    return Boolean(rowData?.[dataField])
  }

  return true
}

function getFormattedValueGrid({
  value,
  columnConfig,
  record,
  fieldConfigs,
  getFormattedValue,
  formatValue,
  formattedFields,
  editableFields
}: GetFormattedValueGridProps) {
  const { substitudeField: substituteField = '' } =
    editableFields.find((editField: EditableField) => editField.field === columnConfig.fieldName) ||
    {}
  // eslint-disable-next-line no-param-reassign
  columnConfig.fieldName = substituteField || columnConfig.fieldName

  const { dataType = null } =
    fieldConfigs.find((item) => item.fieldName === columnConfig.fieldName) || {}
  const { columnType, fieldName = '', formatField = null } = columnConfig || {}

  const doesFormatFieldExist =
    formatField &&
    has(record.data, formatField) &&
    record.data[formatField] &&
    record.data[formatField] !== ''

  // Note: Progress formatted in sparkline.ts label formatter
  const isProgress = columnType === 'progress'
  if (isProgress) {
    return value
  }

  let formattedValue = value

  if (columnType !== 'html') {
    // Remove HTML tags from data. Only HTML column types are permitted to display HTML contents
    formattedValue =
      formattedValue && formattedValue.toString().replace(/</g, '&lt;').replace(/>/g, '&gt;')
  }

  if (isNumber(value)) {
    if (doesFormatFieldExist) {
      formattedValue = formatValue(record.data[formatField] as string, value)
    } else {
      formattedValue = getFormattedValue(fieldName, value, formattedFields)
    }

    return formattedValue
  }

  let isDateFormatted = false
  const isDatetime = dataType === 'datetime'

  if (isDatetime) {
    if (isNil(value) || size(value.toString()) === 0) {
      // Prevent invalid date
      formattedValue = ''
    } else if (doesFormatFieldExist) {
      isDateFormatted = true
      formattedValue = formatValue(record.data[formatField] as string, value)
    } else {
      const columnFormatField = formattedFields.find(
        (formattedField) => formattedField.columnName === fieldName
      )

      if (columnFormatField?.formatString) {
        isDateFormatted = true

        formattedValue = getFormattedValue(fieldName, value, formattedFields)
      }
    }

    // if there is no formatField or formatString,
    // this is the default format if there is no format
    if (isDate(value) && !isDateFormatted) {
      formattedValue = moment(value).format(DEFAULT_DATE_FORMAT)
    }

    return formattedValue
  }

  return formattedValue
}

const getGridEditable = ({ isAllowed, editingEnabledConfig }: GetGridEditableProps) => {
  const editableFromAutherization = isAllowed('Editing')
  const isEditable = editableFromAutherization && editingEnabledConfig

  return isEditable
}

function replaceTemplate({ text, data, getFormattedValue, formattedFields }: ReplaceTemplateProps) {
  const regExp = /\{([^}]+)\}/g

  if (isNil(text) || isEmpty(text)) {
    return '' // \xa0'
  }

  const matches = text.match(regExp)

  if (!matches) return text

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < matches.length; i++) {
    const variableName = matches[i].substr(1, matches[i].length - 2)

    let value: TrowDataValue = ''
    let formattedValue
    if (data && has(data, variableName) && !isNil(data[variableName])) {
      value = data[variableName]

      if (!isNil(value)) {
        formattedValue = getFormattedValue(variableName, value, formattedFields)
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    text = text.replace(matches[i], formattedValue || value)
  }

  return text
}

const isWarningThresholdExceeded = ({
  warningThreshold,
  oldValue,
  newValue
}: IsWarningThresholdExceededParams) => {
  if (typeof warningThreshold !== 'number') {
    return false
  }

  if (warningThreshold > 0 && isNumber(newValue) && isNumber(oldValue)) {
    return (oldValue * (100 + warningThreshold)) / 100 <= newValue
  }

  return false
}

const getUserColumnDefs = (columnDefs: SlvyMasterColDef[]) => {
  return columnDefs.filter(
    ({ field }) =>
      field !== CUSTOM_ROW_INFO && field !== DELETE_COLUMN && field !== CHECKBOX_SELECTION_COLUMN
  ) as SlvyColDef[]
}

const getColumnDefsAsFlat = (columnDefs: SlvyMasterColDef[]) => {
  const result: SlvyColDef[] = []
  columnDefs?.forEach?.((columnDef) => {
    if (columnDef?.children?.length) {
      columnDef?.children?.forEach((child) => {
        result.push(child)
      })
    } else {
      result.push(columnDef)
    }
  })

  return result
}

const getUserColumnDefsAsFlat = (columnDefs: SlvyMasterColDef[]) => {
  const userColumnDefs = getUserColumnDefs(columnDefs)

  return getColumnDefsAsFlat(userColumnDefs)
}

const getRowByIndex = (gridRef: GridRef, $index: number): IRowData | null => {
  let row = null
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  gridRef?.current?.api.forEachLeafNode?.(({ data }: IRowNode<IRowData>, index: number) => {
    if ($index === index && data) {
      row = data
    }
  })
  return row
}

const getColDefByField = (gridRef: GridRef, activeField: string) => {
  // const resultForNonGroupedColumns = gridRef?.current?.api
  //   ?.getColumnDefs()
  //   ?.find((column) => (column as SlvyColDef).field === activeField) as SlvyColDef

  const resultForGroupedColumns = gridRef?.current?.api?.getColumnDef?.(activeField)

  // const lastAlternativeResult = gridRef?.current?.api?.getColumn(activeField)?.colDef

  // const result = resultForNonGroupedColumns ?? resultForGroupedColumns ?? lastAlternativeResult

  return resultForGroupedColumns
}

const getIsProgressAndNotNumber = ({ columnType, value }: GetIsProgressAndNotNumberProps) => {
  const isProgress = columnType === 'progress'

  if (isProgress) {
    const isValueString = typeof value === 'string'
    const isValueNumber = typeof value === 'number'

    if (!isValueString && !isValueNumber) {
      return false
    }

    const newValue = isValueString ? parseFloat(value) : value
    const isNotNumber = Number.isNaN(newValue)

    if (isNotNumber) {
      return true
    }
  }

  return false
}

const getBarsTotalHeight = ({
  isFooterBarEnabled,
  isPaginationEnabled,
  theme
}: GetBarsTotalHeightProps): CSSProperties['height'] => {
  return `${
    (isFooterBarEnabled ? parseInt(footerBarHeight[theme] as string, 10) : 0) +
    (isPaginationEnabled ? parseInt(paginationPanelHeight[theme] as string, 10) : 0)
  }px`
}

const omitCustomFields = (data: IRowDataGrid) => {
  let restData: IRowDataGrid = omit(data, CUSTOM_FIELDS)
  restData = omitBy(restData, (_, value) => isSubstituteFieldMatch(value))
  return restData
}

const getDisplayedRowCount = (gridRef: GridRef) => {
  let count = 0
  gridRef?.current?.api?.forEachNode?.((node) => {
    if (!node?.group && node?.displayed) {
      count += 1
    }
  })
  return count
}

const getBackColorAndColor = (
  cellStyle: ColumnSchemaSettings['cellStyle'],
  textColor: ColumnSchemaSettings['fontColor']
): { backgroundColor: string | undefined; color: string } => {
  let backgroundColor: string | undefined
  let color: string = textColor
  if (cellStyle === 'red') {
    backgroundColor = RED
  } else if (cellStyle === 'blue') {
    backgroundColor = BLUE
    color = 'blue'
  } else if (cellStyle === 'green') {
    backgroundColor = GREEN
  } else if (cellStyle === 'orange') {
    backgroundColor = ORANGE
  }
  return { backgroundColor, color }
}

export {
  omitCustomFields,
  compareValuesCaseInsensitive,
  compareOppositeVariables,
  formatDate,
  getBarsTotalHeight,
  getIsProgressAndNotNumber,
  getColDefByField,
  getRowByIndex,
  getUserColumnDefs,
  getUserColumnDefsAsFlat,
  getColumnDefsAsFlat,
  getDisplayedRowCount,
  getBackColorAndColor,
  isWarningThresholdExceeded,
  getEnableInfo,
  getFormattedValueGrid,
  getGridEditable,
  getRegisterEventFieldConfigsParams,
  getRegisterMethodFieldConfigsParams,
  getFieldConfigsValues,
  replaceTemplate
}
