Ext.define('Spui.data.model.Base', {
  extend: 'Ext.data.Model',
  schema: {
    namespace: 'Spui.model',
    id: 'Spui',
    urlPrefix: Spui.Global.getApiUrl(),
    proxy: {
      config: {
        storeName: null
      },
      idParam: '{idProperty}',
      type: 'ajax',
      method: 'POST',
      timeout: 360000,
      storeNamew: 'storeName',
      useDefaultXhrHeader: false,
      cors: true,
      listeners: {
        // exception: function (proxy, response, operation) {
        //   Spui.util.ShowErrorBox.requestexception(proxy, response, operation)
        // }
      },
      actionMethods: {
        create: 'POST',
        read: 'POST',
        update: 'POST',
        destroy: 'POST'
      },
      api: {
        create: '{prefix}{entityName:lowercase}/create',
        read: '{prefix}{entityName:lowercase}/read',
        update: '{prefix}{entityName:lowercase}/update',
        destroy: '{prefix}{entityName:lowercase}/destroy'
      },
      reader: {
        type: 'json',
        rootProperty: 'data',
        totalProperty: 'totalCount',
        keepRawData: true,
        successProperty: 'success',
        messageProperty: 'errors'
      },
      writer: {
        rootProperty: 'data',
        allowSingle: false,
        successProperty: 'success',
        messageProperty: 'errors',
        writeRecordId: true,
        allDataOptions: {
          associated: false
        },
        writeAllFields: true
      },
      extraParams: {
        mainAppId: null
      }
    }
  }
})
