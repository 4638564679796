export default {
  title: 'Executive Dashboard',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'ExecutiveDashboard',
  description: 'Executive Dashboard Plugin',
  iconClass: 'fa-area-chart',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Application'
}
