/* global Ext, Assortment, store, $, _ */

Ext.define('Assortment.Main', {
  extend: 'Ext.panel.Panel',
  xtype: 'assortmentmainview',
  referenceHolder: true,
  reference: 'AssormentView',
  viewModel: 'assortment-mainviewmodel',
  controller: 'assortment-maincontroller',
  layout: 'fit',
  listeners: {
    beforedestroy: 'onBeforeDestroy'
  },
  initComponent: function () {
    var me = this
    var vm = me.getViewModel()

    vm.set('largeCurrencyFormat', me.config.largeCurrencyFormat)
    vm.set('smallCurrencyFormat', me.config.smallCurrencyFormat)
    vm.set('currency', me.config.currency)
    vm.set('similarItemExternalLink', me.config.similarItemExternalLink)
    vm.set('optionPriceEditable', me.config.optionPriceEditable)
    vm.set('delayUpdates', me.config.delayUpdates)
    vm.set('getPluginstate', me.config.getPluginstate)
    vm.set('savePluginstate', me.config.savePluginstate)

    vm.set('similarProductButtons', me.config.similarProductButtons)
    vm.set('segmentContextMenuItems', me.config.segmentContextMenuItems)

    Ext.Ajax.request({
      url: Assortment.Global.getApiUrl() + 'Assortment/GetMinPresentationData',
      timeout: 600000,
      success: function (response, opts) {
        var result = JSON.parse(response.responseText)

        vm.set('ProductAttributeNames', result.ProductAttributeNames)
        vm.set('NumberOfHistoryBackPeriods', result.NumberOfHistoryBackPeriods)
        vm.set('FutureSeasons', result.FutureSeasons)
        vm.set('SeasonGroup', result.SeasonGroup)
      }
    })

    me.config.getPluginstate({
      success: function (data) {
        var emptyPluginState = {
          recentAssortments: []
        }
        var pluginState = emptyPluginState
        if (_.size(data) > 0) {
          pluginState = data[0].config.state
        }
        vm.set('pluginState', pluginState)
      },
      complete: function (data) {
        me.openAssortmentLibrary()
      }
    })

    me.callParent()
  },
  openAssortmentLibrary: function () {
    var me = this

    Ext.create('Assortment.assortmentLibrary.AssortmentLibrary', {
      title: 'Select an Assortment',
      referencePanel: me,
      items: [
        {
          xtype: 'panel',
          region: 'center',
          layout: 'fit',
          margin: 10,
          overflowY: 'auto',
          items: [
            {
              xtype: 'assortmentTree',
              referencePanel: me,
              mode: 'Open'
            }
          ]
        }
      ],
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          layout: {
            type: 'hbox',
            pack: 'center'
          },
          defaults: {
            flex: 1
          },
          items: [
            {
              text: 'New Folder',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                if (!currentRow) return

                var assortmentLibrary = this.lookupReferenceHolder()
                var references = assortmentLibrary.getReferences()
                var assortmentTree = references['assortmentTree']

                if (!assortmentTree) return

                me.getEl().mask('Loading...')

                var nodeId = currentRow.get('Id')

                Ext.Ajax.request({
                  url: Assortment.Global.getApiUrl() + 'LibraryTreeCreateFolderNode',
                  params: {
                    MotherId: nodeId,
                    Name: 'New Folder',
                    Status: 'FOLDER',
                    ProjectId: 1
                  },
                  timeout: 600000,
                  success: function (response, opts) {
                    var result = Ext.JSON.decode(response.responseText)

                    if (result.Code > -1) {
                      Assortment.util.operationSuccessWithMessage(result.Description)
                      assortmentTree.store.reload()
                    } else {
                      Assortment.util.operationFailWithMessage(result.Description)
                    }

                    me.getEl().unmask()
                  },
                  failure: function (response, opts) {
                    me.getEl().unmask()
                    alert('Create folder node has failed.')
                  }
                })
              },
              bind: {
                disabled: '{!isFolderSelected || IsRecentAssortmentsSelected}'
              }
            },
            {
              text: 'Delete',
              iconCls: 'fa fa-folder-open-o',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                if (currentRow) {
                  var nodeId = currentRow.get('Id')

                  var assortmentLibrary = this.lookupReferenceHolder()
                  var references = assortmentLibrary.getReferences()
                  var assortmentTree = references['assortmentTree']

                  if (assortmentTree) {
                    Ext.Msg.confirm(
                      'Confirm Delete',
                      'Are you sure you want to delete?',
                      function (result) {
                        if (result == 'yes') {
                          Ext.Ajax.request({
                            url:
                              Assortment.Global.getApiUrl() +
                              'LibraryTreeDeleteNode' +
                              '?nodeId=' +
                              nodeId,
                            timeout: 600000,
                            success: function (response, opts) {
                              var result = Ext.JSON.decode(response.responseText)

                              if (result.Code > -1) {
                                Assortment.util.operationSuccessWithMessage(result.Description)
                                assortmentTree.store.reload()
                              } else {
                                Assortment.util.operationFailWithMessage(result.Description)
                              }
                            },
                            failure: function (response, opts) {
                              console.warn('Delete node has failed.')
                            }
                          })
                        }
                      }
                    )
                  }
                }
              },
              bind: {
                disabled: '{!currentRow || IsRecentAssortmentsSelected || IsReadonly}'
              }
            },
            {
              text: 'Load',
              iconCls: 'fa fa-folder-open-o',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                me.getEl().mask('Loading...')

                if (!currentRow) return
                var nodeId = currentRow.get('Id')
                Ext.Ajax.request({
                  url: Assortment.Global.getApiUrl() + 'assortment/Load' + '?nodeId=' + nodeId,
                  params: {
                    NodeId: nodeId
                  },
                  timeout: 600000,
                  success: function (response, opts) {
                    var viewModel = me.referencePanel.getViewModel()
                    var pluginState = viewModel.get('pluginState')

                    if (_.indexOf(pluginState.recentAssortments, nodeId) < 0) {
                      var savePluginstateFunc = viewModel.get('savePluginstate')
                      pluginState.recentAssortments.push(nodeId)
                      if (_.size(pluginState.recentAssortments) > 3) {
                        pluginState.recentAssortments.shift()
                      }
                      savePluginstateFunc(pluginState)
                      viewModel.set('pluginState', pluginState)
                    }

                    var result = JSON.parse(response.responseText)
                    var resultLoadFilters = result.Filters

                    if (result.Code > -1) {
                      Assortment.util.operationSuccessWithMessage(result.Description)

                      function getIsActualSeason(Filters, Values) {
                        let isActualSeason = false
                        let seasonCodes = Filters.filter(function (item) {
                          return item.Name === 'SeasonCode'
                        })
                        if (seasonCodes.length) {
                          let seasonCodeFilter = seasonCodes[0]

                          _.forEach(seasonCodeFilter.SelectedIndices, (selectedIndex) => {
                            if (_.indexOf(Values, seasonCodeFilter.Values[selectedIndex]) > -1) {
                              isActualSeason = true
                            }
                          })
                        }
                        return isActualSeason
                      }

                      var futureSeasons = viewModel.get('FutureSeasons')

                      var isActualSeason = getIsActualSeason(resultLoadFilters, futureSeasons)

                      viewModel.set('isActualSeason', isActualSeason)

                      viewModel.set('CurrentNode', currentRow.data)

                      const { data: { Name: assortmentName } = {} } = currentRow || {}

                      viewModel.set('selectedAssortmentName', assortmentName)

                      var controller = me.referencePanel.getController()

                      controller.initializeFilterSettings(result, me.referencePanel)

                      controller.initializeStores(result, me.referencePanel)
                    } else {
                      Assortment.util.operationFailWithMessage(result.Description)
                    }

                    me.getEl().unmask()
                    me.close()
                  },
                  failure: function (response, opts) {
                    me.getEl().unmask()
                    alert('Load assortment has failed.')
                  }
                })
              },
              bind: {
                disabled: '{!isLeafSelected}'
              }
            },
            {
              text: 'New Assortment',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var viewModel = me.referencePanel.getViewModel()
                viewModel.set('selectedAssortmentName', 'New Assortment')

                var controller = me.referencePanel.getController()

                controller.initializeFilterSettings(null, me.referencePanel)

                controller.initializeStores(null, me.referencePanel)

                me.close()
              }
            }
          ]
        }
      ]
    }).show()
  },
  dockedItems: [
    {
      xtype: 'assortment-toolbar',
      dock: 'top',
      overflowHandler: 'scroller'
    },
    {
      xtype: 'toolbar',
      dock: 'bottom',
      items: [
        '->',
        {
          xtype: 'tbtext',
          bind: {
            hidden: '{!MfpData}',
            html: '{assormentCompareText} - {mfpCompareText}'
          }
        }
      ]
    }
  ],
  items: [
    {
      xtype: 'assortment-grid',
      reference: 'assortmentGrid',
      bind: {
        store: '{gridStore}'
      }
    }
  ]
})
