import { getSparklineOptions } from '../sparkline'
import { SparklineOptions } from '../../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'
import {
  columnType as defaultColumnType,
  sparklineOptions as defaultSparklineOptions
} from './mocks'

function getColumnType() {
  return defaultColumnType
}

function getOptions() {
  return defaultSparklineOptions
}

describe('AgGrid:Helpers:Common:getBarOptions', () => {
  let sparklineOptions: SparklineOptions = getOptions()
  let columnType = getColumnType()
  beforeEach(() => {
    sparklineOptions = getOptions()
    columnType = getColumnType()
  })

  it('should return an object which includes type parameter equals to bar if columnType is progress', () => {
    columnType = 'progress'
    const result = getSparklineOptions({
      columnType,
      sparklineOptions
    })
    expect(result.type).toEqual('bar')
  })

  it('should return an object which includes type parameter equals to sparklineOptions.type if columnType is sparkline', () => {
    columnType = 'sparkline'
    const result = getSparklineOptions({ columnType, sparklineOptions })
    expect(result.type).toEqual('line')
  })

  it(`should return an object which includes type parameter equals to line and have result.marker.shape parameter which equals to 'square' if sparklineOptions.type is sparklinediscrete and columnType is sparkline`, () => {
    columnType = 'sparkline'
    sparklineOptions.type = 'sparklinediscrete'
    const result = getSparklineOptions({ columnType, sparklineOptions })
    expect(result.type).toEqual('line')
    expect(result).toHaveProperty('marker')
    expect(result.marker).toHaveProperty('shape')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.marker.shape).toEqual('square')
  })

  it('should return an object which includes type parameter equals to column if sparklineOptions.type is sparklinebar and columnType is sparkline', () => {
    columnType = 'sparkline'
    sparklineOptions.type = 'sparklinebar'
    const result = getSparklineOptions({ columnType, sparklineOptions })
    expect(result.type).toEqual('column')
  })

  it('should return undefined if sparklineOptions.type is not matching the options objects keys columnType is sparkline', () => {
    columnType = 'sparkline'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sparklineOptions.type = 'discrete'
    const result = getSparklineOptions({ columnType, sparklineOptions })
    expect(result).toBe(undefined)
  })
})
