Ext.define('MDM.src.ux.FullScreen.Button', {
  extend: 'Ext.button.Button',
  alias: 'widget.uxfullscreen',
  enableToggle: true,
  iconCls: 'fa fa-desktop',
  listeners: {
    toggle: {
      fn: function (button, pressed) {}
    },
    afterrender: function (sender) {
      // HACK: IE 11 requires the click source of the HTML DOM Element to be present at the top of the call stack (I think secha uses a timer and looses the stack)
      var me = this
      sender.getEl().dom.onclick = function () {
        try {
          MDM.src.ux.FullScreen.Util.toggleFullScreen(me)
        } catch (ex) {
          Ext.log('Error: ' + ex.message)
        }
      }
    }
  }
})
