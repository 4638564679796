import HighchartsWrapper from '../../HighchartsWrapper'
import { getBoxOptions, getBulletOptions, getPieOptions } from './helpers'
import { defaultClassNames } from './constants'
import { HighchartCellRendererProps } from './HighchartCellRenderer.types'

const HighchartCellRenderer = (props: HighchartCellRendererProps) => {
  const {
    node: { group = false },
    colDef: { field } = { field: undefined },
    highchartOptions,
    value,
    context: { theme }
  } = props

  // TODO: "count" agg func value displayed with SummaryCellRenderer,
  // check cell.ts cellRendererSelector
  if (group) {
    return null
  }

  const valuesArray = value?.split?.(',')?.map?.(Number) || []

  if (typeof field === 'undefined' || !valuesArray.length) {
    return null
  }

  if (highchartOptions.type === 'sparklinebox') {
    const boxOptions = getBoxOptions({ field, valuesArray, highchartOptions, theme })

    return <HighchartsWrapper className={defaultClassNames} options={boxOptions} />
  }
  if (highchartOptions.type === 'sparklinebullet') {
    const bulletOptions = getBulletOptions({ field, valuesArray, highchartOptions, theme })

    return <HighchartsWrapper className={defaultClassNames} options={bulletOptions} />
  }
  if (highchartOptions.type === 'sparklinepie') {
    const pieOptions = getPieOptions({ field, valuesArray })

    return <HighchartsWrapper className={defaultClassNames} options={pieOptions} />
  }
  return null
}

export default HighchartCellRenderer
