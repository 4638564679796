/* global Ext */
import React, { Component } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import hash from 'object-hash'
import { Treepanel } from '@sencha/ext-react-classic'
import { isSuccess } from '@/crudoptV3'
import { __RowIndex } from '@/store/slices/localData'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { slvyToast, ExtRoot } from '@/components'
import { API_URL } from '@/constants'
import './index.scss'

class SenchaTree extends Component {
  constructor(props) {
    super(props)

    this.clipBoard = []
    this.createdCssClasses = []
    this.lastEditor = 'TextBox'
    this.lookupData = {}
    this.lookupStore = {}

    const commonOption = {
      value: 0,
      align: 'right',
      type: 'numeric'
    }

    this.newColumnOptions = {
      int: commonOption,
      double: commonOption,
      float: commonOption,
      decimal: commonOption,
      long: commonOption,
      short: commonOption,
      datetime: {
        value: 0,
        align: 'right',
        type: 'date'
      },
      string: {
        value: '',
        align: 'left',
        type: 'string'
      },
      bool: {
        value: false,
        align: 'center',
        type: 'boolean'
      }
    }

    this.state = {
      enabledDragDrop: true
    }

    this.apiClient = this.apiClient.bind(this)
    this.getLookupStore = this.getLookupStore.bind(this)
    this.getMaxRowIndex = this.getMaxRowIndex.bind(this)
    this.getRowClass = this.getRowClass.bind(this)
    this.handleAddNode = this.handleAddNode.bind(this)
    this.handleBeforeCheckChange = this.handleBeforeCheckChange.bind(this)
    this.handleBeforeEdit = this.handleBeforeEdit.bind(this)
    this.handleBeforeNodeDrop = this.handleBeforeNodeDrop.bind(this)
    this.handleCellClick = this.handleCellClick.bind(this)
    this.handleCheckboxSelectedWrapper = this.handleCheckboxSelectedWrapper.bind(this)
    this.handleDataColumnChanged = this.handleDataColumnChanged.bind(this)
    this.handleDeleteButtonGetClass = this.handleDeleteButtonGetClass.bind(this)
    this.handleDeleteNode = this.handleDeleteNode.bind(this)
    this.handleItemKeydown = this.handleItemKeydown.bind(this)
    this.handleLookupBeforeInsertQuery = this.handleLookupBeforeInsertQuery.bind(this)
    this.handleLookupBeforeQuery = this.handleLookupBeforeQuery.bind(this)
    this.handleLookupDataLoaded = this.handleLookupDataLoaded.bind(this)
    this.handleNodeAdded = this.handleNodeAdded.bind(this)
    this.handleNodeDeselected = this.handleNodeDeselected.bind(this)
    this.handleNodeDrop = this.handleNodeDrop.bind(this)
    this.handleStoreUpdate = this.handleStoreUpdate.bind(this)
    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
    this.handleTreeAfterRender = this.handleTreeAfterRender.bind(this)
    this.handleTreeNodeExpandedCollapsed = this.handleTreeNodeExpandedCollapsed.bind(this)
    this.substitutionColumnRenderer = this.substitutionColumnRenderer.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    const pluginDataChanged = !_.isEqual(nextProps.pluginData, this.props.pluginData)
    const isMaximizeChanged = this.props.isMaximized !== nextProps.isMaximized

    return pluginDataChanged || isMaximizeChanged
  }

  UNSAFE_componentWillMount() {
    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [this.handleDataColumnChanged],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition
      })
    }

    this.store = Ext.create('Ext.data.TreeStore', {
      listeners: {
        update: this.handleStoreUpdate,
        nodeexpand: this.handleTreeNodeExpandedCollapsed,
        nodecollapse: this.handleTreeNodeExpandedCollapsed
      }
    })

    // Create extjs ViewModel
    this.viewModel = Ext.create('Ext.app.ViewModel', {})

    const {
      settings: {
        query: { fields = [] } = {},
        config: { columns: Columns = [], editing: { footerButtons = [] } = {} } = {}
      } = {}
    } = this.props

    const cellParams = _.transform(
      fields,
      (result, field = {}) => {
        const { dataType = '' } = field
        result[field.fieldName] = PluginTypes.fromString(dataType)
      },
      {}
    )
    const checkedCellParams = _.transform(
      fields,
      (result, field = {}) => {
        const { dataType = '' } = field
        result[field.fieldName] = PluginTypes.arrayOf(PluginTypes.fromString(dataType))
      },
      {}
    )

    // Register row selection event
    const nodeSelectedParams = {
      ...cellParams,
      nodeSelected: PluginTypes.boolean,
      nodeDeselected: PluginTypes.boolean,
      nodeAddible: PluginTypes.boolean,
      nodeNotAddible: PluginTypes.boolean,
      refreshKey: PluginTypes.string
    }

    this.handleDataUpdated = this.props.registerEvent({
      key: 'DataUpdated',
      fn: this.handleDataUpdated.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.handleNodeSelected = this.props.registerEvent({
      key: 'NodeSelected',
      fn: this.handleNodeSelected.bind(this),
      returnTypes: nodeSelectedParams
    })

    this.handleMultiNodeSelected = this.props.registerEvent({
      key: 'MultiNodeSelected',
      fn: this.handleMultiNodeSelected.bind(this),
      returnTypes: checkedCellParams
    })

    this.handleCheckboxSelected = this.props.registerEvent({
      key: 'NodeCheckboxSelected',
      fn: this.handleCheckboxSelected.bind(this),
      returnTypes: checkedCellParams
    })

    _.forEach(footerButtons, (footerButton) => {
      const { buttonText } = footerButton
      if (buttonText) {
        // Register footer button clicked event
        this['handleFooterButtonClick_' + buttonText] = function () {
          return this.handleFooterButtonClick()
        }

        this['handleFooterButtonClick_' + buttonText] = this.props.registerEvent({
          key: 'FooterButtonClick_' + buttonText,
          fn: this['handleFooterButtonClick_' + buttonText].bind(this),
          returnTypes: { refreshKey: PluginTypes.string }
        })
      }
    })

    this.props.registerMethod({
      key: 'AddNode',
      fn: this.handleTriggerAddNode.bind(this),
      args: [{ name: 'newValue', type: PluginTypes.int }]
    })

    // Register setEditable method
    this.props.registerMethod({
      key: 'setEditableState',
      fn: this.handleSetEditableState.bind(this),
      args: [
        { name: 'editable', type: PluginTypes.boolean },
        { name: 'notEditable', type: PluginTypes.boolean },
        { name: 'enableDragDrop', type: PluginTypes.boolean }
      ]
    })

    const fieldConfigs = this.getFieldConfigs()

    _.forEach(Columns, (column) => {
      if (column.actionButton && column.actionButton.enabled) {
        const { header: actionTitle, actionButton: { name: actionName } = {} } = column
        // Register actionButton clicked event
        this['handleActionClick_' + actionName] = function (
          grid,
          rowIndex,
          colIndex,
          item,
          e,
          record
        ) {
          return this.handleActionClick(record, actionTitle)
        }

        this['handleActionClick_' + actionName] = this.props.registerEvent({
          key: 'ActionClicked_' + actionName,
          fn: this['handleActionClick_' + actionName].bind(this),
          returnTypes: _.transform(
            fieldConfigs,
            (result, field = {}) => {
              var { dataType = '' } = field
              result[field.fieldName] = PluginTypes.fromString(dataType)
            },
            {
              _ActionTitle: PluginTypes.string,
              _RefreshKey: PluginTypes.string
            }
          )
        })
      }
    })

    const cellClickParams = {
      ...cellParams,
      columnField: PluginTypes.string
    }
    _.forEach(Columns, (columnConfig) => {
      const { action: { cellClickEnabled = false } = {} } = columnConfig || {}
      if (cellClickEnabled) {
        this.handleCellClick = this.props.registerEvent({
          key: 'CellClicked-' + columnConfig.fieldName,
          fn: this.handleCellClick.bind(this),
          returnTypes: cellClickParams
        })
      }
    })
  }

  componentDidMount() {
    this.props.reloadExtRoot(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isSuccess(this.props.query, nextProps.query)) {
      // Add row index to grid, we need it while editing
      this.props.addRowIndexToLocalData()
    }

    // If size of the grid changes do not render the grid
    const widthChanged = nextProps.size.width !== this.props.size.width
    const heightChanged = nextProps.size.height !== this.props.size.height

    if (this.extjsTree && this.extjsTree.cmp) {
      if (widthChanged) {
        this.extjsTree.cmp.setWidth(nextProps.size.width)
      }
      if (heightChanged) {
        this.extjsTree.cmp.setHeight(nextProps.size.height)
      }
    }
  }

  componentWillUnmount() {
    dispatchEvent(
      new CustomEvent('hideExtRoot', { detail: { pluginId: this.props.id, callback: () => {} } })
    )
  }

  handleSynchronizeDataDefinition(fields, value, schemaHelper) {
    const newValue = _.cloneDeep(value)

    // Create an  empty template for grid column
    const emptyColumn = schemaHelper.getValueOfSchema(this.props.schema.properties.columns.items)

    _.remove(newValue.columns, (column) => column.fieldName === '')

    _.forEach(fields, (field) => {
      // Check if the field is already used as a column
      const existingColumn = _.find(
        newValue.columns,
        (column) => column.fieldName === field.fieldName
      )

      // Create a column if its field does not exist
      if (!existingColumn) {
        const newColumn = _.cloneDeep(emptyColumn)
        newColumn.fieldName = field.fieldName
        newColumn.header = field.fieldName

        if (field.dataType in this.newColumnOptions) {
          newColumn.align = this.newColumnOptions[field.dataType].align
        }

        newValue.columns.push(newColumn)
      }
    })

    return newValue
  }

  getData(
    result,
    displayField,
    parentField,
    valueField,
    iconField,
    sortOrderField,
    maxLevel,
    expandedNodes
  ) {
    const treeDict = {}

    // Add node to tree dictionary
    _.forEach(result, (row) => {
      if (!treeDict[row[parentField]]) {
        treeDict[row[parentField]] = []
      }
      treeDict[row[parentField]].push(row)
    })

    return this.getNodes(
      treeDict,
      0,
      displayField,
      parentField,
      valueField,
      iconField,
      sortOrderField,
      0,
      maxLevel,
      expandedNodes
    )
  }

  getNodes(
    treeDict,
    parentId = 0,
    displayField,
    parentField,
    valueField,
    iconField,
    sortOrderField,
    currLevel = 0,
    maxLevel,
    expandedNodes
  ) {
    const children = (treeDict && treeDict[parentId]) || []

    const {
      settings: {
        config: { selection: { multiSelection = false, checkboxSelection = false } = {} } = {}
      } = {}
    } = this.props

    if (!children) return []

    return _.map(children, (child) => {
      const nextChildren = this.getNodes(
        treeDict,
        child[valueField],
        displayField,
        parentField,
        valueField,
        iconField,
        sortOrderField,
        currLevel + 1,
        maxLevel,
        expandedNodes
      )
      if (multiSelection && checkboxSelection) {
        child.checked = false
      }
      return {
        id: child[valueField],
        text: child[displayField],
        children: nextChildren,
        iconCls: child[iconField],
        sortOrder: child[sortOrderField],
        leaf: currLevel === maxLevel,
        dataRow: child,
        expanded: _.indexOf(expandedNodes, child[valueField]) >= 0,
        ...child
      }
    })
  }

  handleNodeSelected(tree, record) {
    const {
      settings: {
        config: { editing: { maxLevel = 10, levels = [] } = {} } = {},
        query: { fields = [] } = {}
      } = {}
    } = this.props

    const { data: { depth, dataRow = {} } = {} } = record || {}

    const nodeValues = _.transform(
      fields,
      (result, field = {}) => {
        result[field.fieldName] = dataRow[field.fieldName]
      },
      {}
    )

    let addingEnabled
    if (depth - 1 >= maxLevel) {
      addingEnabled = false
    } else {
      addingEnabled = this.canAddUnderNode(record, levels)
    }

    if (_.size(this.extjsTree.cmp.dockedItems.items) > 1) {
      const addNodeButton = _.find(this.extjsTree.cmp.dockedItems.items[1].items.items, (item) => {
        return item.itemId === 'addRecord'
      })
      if (!_.isNil(addNodeButton)) {
        addingEnabled ? addNodeButton.enable() : addNodeButton.disable()
      }
    }

    const parentNode = this.getTopParentNode(record)
    const { data: { index: parentNodeIndex } = {} } = parentNode || {}

    const nodeSelected = tree != null
    if (parentNodeIndex >= 0 && nodeSelected && this.newNodeAdded) {
      // Scroll to the selected node.
      // This is necessary while adding a new node.
      // The new node has to be in the visible area.
      this.newNodeAdded = false
      this.extjsTree.cmp.getView().focusRow(parentNodeIndex)
    }

    return {
      ...nodeValues,
      nodeSelected: nodeSelected,
      nodeDeselected: !nodeSelected,
      nodeAddible: addingEnabled,
      nodeNotAddible: !addingEnabled,
      refreshKey: uuidv4()
    }
  }

  handleCheckboxSelectedWrapper(self, record, item, index, eventObj, eOpts) {
    this.handleMultiNodeSelected(self, record, item, index, eventObj, eOpts)
    if (eventObj.getTarget('.x-tree-checkbox', 1, true)) {
      // eslint-disable-next-line eqeqeq
      if (eventObj.recordIndex == 1) {
        this.handleCheckboxSelected(self, record, item, index, eventObj, eOpts)
      }
    }
  }

  handleCheckboxSelected(self) {
    const {
      settings: { config: { data: { checkboxEnableColumn: checkEnabled } = {} } = {} } = {}
    } = this.props

    const nodes = self.getChecked()
    return _.transform(
      nodes,
      (res, node) => {
        if (!checkEnabled) {
          res.push(node.raw.dataRow)
        } else if (node.data.checkEnabled && node.data.checked) {
          res.push(node.raw.dataRow)
        }
      },
      []
    )
  }

  handleMultiNodeSelected() {
    const { settings: { query: { fields = [] } = {} } = {} } = this.props
    const nodes = this.extjsTree.cmp.getSelectionModel().getSelection()

    return _.transform(
      fields,
      (result, field = {}) => {
        result[field.fieldName] = _.map(nodes, (node) => {
          return node.data[field.fieldName]
        })
      },
      {}
    )
  }

  getTopParentNode(record) {
    const { parentNode } = record || {}
    if (parentNode === null) {
      return record
    } else {
      const { data: { depth: parentDepth = 0 } = {} } = parentNode || {}
      if (parentDepth === 0) {
        return record
      }
    }
    return this.getTopParentNode(parentNode)
  }

  handleNodeDeselected() {
    const tree = this.extjsTree.cmp
    const rootNode = tree.getRootNode()

    this.handleNodeSelected(null, rootNode)
  }

  handleLookupDataLoaded(field, isTreeColumn, store, records, successful) {
    const [record = {}] = records || []
    const { data: { data: { result = [] } = {} } = {} } = record
    const { props: { actualFilters = {}, additionalArgs = {} } = {} } = this
    const filters = { ...actualFilters, ...additionalArgs }

    const comboData = _.map(result, (row) => {
      return _.isNil(row.Id)
        ? { id: row.Value, value: row.Value }
        : { id: row.Id, value: row.Value }
    })
    // Save lookup data
    const { settings: { config: { data: { display } = {} } = {} } = {} } = this.props
    let displayField = isTreeColumn ? display : field
    const keyRecordsHash = this.getHashForLookup(
      displayField,
      this.lookupStore[displayField].proxy.extraParams.record,
      filters
    )

    if (!this.lookupData[displayField]) {
      this.lookupData[displayField] = {}
    }
    this.lookupData[displayField][keyRecordsHash] = comboData

    store.setData(comboData)
  }

  handleLookupBeforeQuery(queryEvent) {
    const record = queryEvent.combo.up('editor').context.record
    const { data: { dataRow = {} } = {} } = record || {}

    const {
      settings: { config: { data: { display: displayField } = {} } = {} } = {},
      actualFilters = {},
      additionalArgs = {}
    } = this.props
    const filters = { ...actualFilters, ...additionalArgs }

    const actualParamsHash = this.getHashForLookup(displayField, dataRow, filters)

    const storedLookupData =
      this.lookupData[displayField] && this.lookupData[displayField][actualParamsHash]

    if (!storedLookupData) {
      // We need update hints and current record data to load lookup data
      const lookupInputData = {
        record: { ...dataRow }
      }

      this.lookupStore[displayField].proxy.extraParams = lookupInputData
      this.lookupStore[displayField].load()
    } else {
      this.lookupStore[displayField].setData(storedLookupData)
    }
  }

  handleLookupBeforeInsertQuery() {
    const tree = this.extjsTree.cmp
    const parentNode = tree.selModel.getSelection()[0] || tree.getRootNode()
    const { data: { dataRow: parentRow = {} } = {} } = parentNode || {}

    const {
      settings: {
        config: {
          data: { display: displayField, parent: parentField, value: valueField } = {}
        } = {}
      } = {},
      params: { environment },
      actualFilters = {},
      additionalArgs = {}
    } = this.props
    const filters = { ...actualFilters, ...additionalArgs }
    const dataRow = this.getEmptyRecord()
    dataRow[parentField] = parentRow[valueField]

    const actualParamsHash = this.getHashForLookup(displayField, dataRow, filters)

    const storedLookupData =
      this.lookupData[displayField] && this.lookupData[displayField][actualParamsHash]

    if (!storedLookupData) {
      // We need update hints and current record data to load lookup data
      const lookupInputData = {
        record: { ...dataRow }
      }
      const lookupTreeStore = this.getLookupStore(displayField, environment, true)
      this.lookupStore[displayField] = lookupTreeStore

      this.lookupStore[displayField].proxy.extraParams = lookupInputData
      this.lookupStore[displayField].load()
    } else {
      this.lookupStore[displayField].setData(storedLookupData)
    }
  }

  handleBeforeEdit(editor, context) {
    const {
      record: { data: rowData, data: { depth = 100 } = {} } = {},
      column: { xtype: columnType = null, dataIndex = null } = {}
    } = context || {}
    const fieldConfigs = this.getFieldConfigs()
    const dateField = _.find(fieldConfigs, (field) => {
      return field.fieldName === dataIndex && field.dataType === 'datetime'
    })

    if (!_.isNil(dateField) && !(context.value instanceof Date)) {
      context.value = new Date(context.value)
    }

    let editingEnabled = true
    let isLookupEnabled = false
    if (columnType === 'treecolumn') {
      // TODO lastEditor check for AddNode
      const {
        props: {
          params: { environment },
          settings: {
            config: { data: { display: displayField } = {}, editing: { levels = [] } = {} } = {}
          } = {}
        } = {}
      } = this

      let that = this
      const sourceLevel = depth - 1
      const levelConstraint = _.find(levels, { level: sourceLevel })

      if (levelConstraint) {
        const { textEditingEnabled = false, lookupEnabled = false } = levelConstraint

        editingEnabled = textEditingEnabled
        isLookupEnabled = lookupEnabled
      }
      if (editingEnabled) {
        if (isLookupEnabled) {
          // TODO place is wrong for this.lookupStore[displayField]
          const lookupTreeStore = this.getLookupStore(displayField, environment, true)
          this.lookupStore[displayField] = lookupTreeStore
          context.column.setEditor(
            Ext.create('Ext.grid.plugin.CellEditing', {
              xtype: 'combo',
              queryMode: 'local',
              store: this.lookupStore[displayField],
              displayField: 'value',
              valueField: 'id',
              editable: false,
              listeners: {
                beforeQuery: that.handleLookupBeforeQuery
              }
            })
          )
        } else {
          context.column.setEditor(
            Ext.create('Ext.grid.plugin.CellEditing', {
              xtype: 'textfield'
            })
          )
        }
      }
    } else {
      editingEnabled = !!this.isCellEditable(dataIndex, rowData)
    }
    return editingEnabled
  }

  getLookupStore(field, environment, isTreeColumn) {
    const loookupStore = Ext.create('Ext.data.Store', {
      proxy: {
        type: 'ajax',
        url: API_URL + '/data/plugin/' + this.props.id + '/lookup/' + field,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.token,
          environment: environment
        },
        actionMethods: {
          read: 'POST'
        },
        pageParam: '',
        startParam: '',
        limitParam: '',
        paramsAsJson: true
      },
      listeners: {
        load: (store, records, successful) =>
          this.handleLookupDataLoaded(field, isTreeColumn, store, records, successful)
      },
      data: [{}]
    })
    return loookupStore
  }

  createDragDropPlugin(dragdropEnabled) {
    const plugins = {}
    if (dragdropEnabled !== 'Disabled') {
      plugins.treeviewdragdrop = {
        containerScroll: true
      }
    }
    return plugins
  }

  createTextEditingPlugin(textEditingEnabled) {
    const plugins = []
    if (textEditingEnabled) {
      plugins.push({
        ptype: 'cellediting',
        clicksToEdit: 2,
        listeners: {
          beforeedit: this.handleBeforeEdit
        }
      })
    }
    return plugins
  }

  createFooter(treeConfig) {
    const footerBar = {
      items: []
    }
    const {
      editing: {
        addingEnabled,
        addingType = 'SaveButton',
        addButtonSettings: {
          addButtonText = null,
          addButtonTooltip = null,
          addButtonIcon = null
        } = {},
        footerButtons = []
      } = {}
    } = treeConfig

    if (addingEnabled && addingType === 'SaveButton') {
      footerBar.items.push({
        xtype: 'button',
        text: addButtonText,
        itemId: 'addRecord',
        tooltip: addButtonTooltip,
        iconCls: addButtonIcon,
        handler: this.handleAddNode,
        bind: {
          disabled: '{editingDisabled}'
        }
      })
    }

    _.forEach(footerButtons, (footerButton) => {
      const { buttonText, buttonTooltip, buttonIcon } = footerButton
      footerBar.items.push({
        text: buttonText,
        tooltip: buttonTooltip,
        iconCls: buttonIcon,
        handler: this['handleFooterButtonClick_' + buttonText]
      })
    })

    return _.size(footerBar) > 0 ? footerBar : null
  }

  createDeleteColumn(deletingEnabled) {
    if (deletingEnabled) {
      const {
        settings: {
          config: {
            editing: {
              levels = [],
              deleting: {
                deleteButtonTooltip = 'Delete Record',
                deleteButtonIcon = 'fas fa-times'
              } = {}
            } = {}
          } = {}
        } = {}
      } = this.props

      return [
        {
          xtype: 'actioncolumn',
          width: 50,
          menuDisabled: true,
          items: [
            {
              tooltip: deleteButtonTooltip,
              ///TODO Ticket getClass
              getClass: this.handleDeleteButtonGetClass(levels, deleteButtonIcon),
              handler: this.handleDeleteNode
            }
          ]
        }
      ]
    }
    return []
  }

  isNodeDeletable(record, levels) {
    let canDeleteRecord = false
    if (record.get('id') !== 'root' && !record.hasChildNodes()) {
      const sourceLevel = record.data.depth - 1

      const levelConstraint = _.find(levels, { level: sourceLevel })
      if (levelConstraint) {
        const { deletingEnabled = false } = levelConstraint
        canDeleteRecord = deletingEnabled
      } else {
        canDeleteRecord = true
      }
    }
    return canDeleteRecord
  }

  handleDeleteButtonGetClass(levels, icon) {
    return (value, metaData, record) => {
      const canDeleteRecord = this.isNodeDeletable(record, levels)

      const className = canDeleteRecord ? 'agf-action fas ' + _.replace(icon, 'fa ', '') : ''
      return className
    }
  }

  handleDeleteNode(view, rowIndex, colIndex, item, e, record) {
    const {
      settings: {
        config: { editing: { levels = [], deleting: { deleteMessage = '' } = {} } = {} } = {}
      } = {}
    } = this.props

    const { data: { text: nodeText = '' } = {} } = record
    const message = this.replaceTemplate(deleteMessage, {
      NodeText: nodeText
    })

    const canDeleteRecord = this.isNodeDeletable(record, levels)
    if (canDeleteRecord) {
      Ext.MessageBox.confirm(
        'Confirmation',
        message,
        function (btn) {
          if (btn === 'yes') {
            this.handleDeleteNodeConfirmed(record)
          }
        }.bind(this)
      )
    }
  }

  handleDeleteNodeConfirmed(record) {
    const { data: { dataRow = {} } = {} } = record

    const deleteData = {
      type: 2, // 2 for deleting
      records: [dataRow]
    }
    this.deleteData(deleteData, record)
  }

  canAddUnderNode(record, levels) {
    const editingDisabledExternal = this.viewModel.get('editingDisabled')

    const { data: { leaf, depth: sourceLevel } = {} } = record || {}
    let ret = !leaf
    const levelConstraint = _.find(levels, { level: sourceLevel })
    if (levelConstraint) {
      const { addingEnabled = false } = levelConstraint
      ret = addingEnabled
    }

    return !editingDisabledExternal && ret
  }

  replaceTemplate(text, data) {
    var regExp = /\{([^}]+)\}/g

    if (!text) return null

    var matches = text.match(regExp)

    if (!matches) return text

    for (var i = 0; i < matches.length; i++) {
      var variableName = matches[i].substr(1, matches[i].length - 2)

      var value = ''
      if (data && data[variableName]) {
        value = data[variableName]
      }

      if (value) {
        text = text.replace(matches[i], value)
      } else {
        text = text.replace(matches[i], '')
      }
    }

    return text
  }

  getEmptyRecord() {
    const { data: { schema: fieldConfigs = [] } = {} } = this.props

    return _.transform(
      fieldConfigs,
      (result, fieldConfig) => {
        const { dataType, fieldName } = fieldConfig
        let fieldValue = ''

        if (dataType in this.newColumnOptions) {
          fieldValue = this.newColumnOptions[dataType].value
        }

        result[fieldName] = fieldValue
      },
      {}
    )
  }

  createNewRecordPopup(displayField, confirmCallback, lookupEnabled) {
    const window = Ext.create('Ext.window.Window', {
      height: 200,
      width: 300,
      title: 'Add Record',
      iconCls: 'fa fa-plus-square',
      cls: 'z-index-top',
      modal: true,
      layout: {
        type: 'vbox',
        pack: 'start',
        align: 'stretch'
      },
      bodyPadding: 8,
      items: [
        {
          fieldLabel: 'Enter Value',
          name: 'newValue',
          allowBlank: false,
          xtype: 'textfield',
          hidden: lookupEnabled
        },
        {
          xtype: 'combo',
          name: 'newValue',
          queryMode: 'local',
          store: this.lookupStore[displayField],
          displayField: 'value',
          valueField: 'id',
          hidden: !lookupEnabled,
          listeners: {
            beforeQuery: this.handleLookupBeforeInsertQuery
          }
        }
      ],

      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          items: [
            '->',
            {
              text: 'Cancel',
              handler: function () {
                window.close()
              }
            },
            {
              text: 'OK',
              handler: function () {
                const selector = lookupEnabled ? 'combo[name=newValue]' : 'textfield[name=newValue]'
                const newValue = window.down(selector).getValue()
                confirmCallback(newValue)
                window.close()
              }
            }
          ]
        }
      ]
    })
    return window
  }

  confirmNewNode(addData, displayField) {
    return (newValue) => {
      addData.records[0][displayField] = newValue
      this.addData(addData)
    }
  }

  handleAddNode() {
    const tree = this.extjsTree.cmp
    const parentNode = tree.selModel.getSelection()[0] || tree.getRootNode()
    const {
      settings: {
        config: {
          editing: { enterValueWhileAdding = false, levels = [] } = {},
          data: { parent: parentField, value: valueField, display: displayField } = {}
        } = {}
      } = {}
    } = this.props

    const addingEnabled = this.canAddUnderNode(parentNode, levels)
    if (addingEnabled) {
      const { data: { depth, dataRow: parentRow = {} } = {} } = parentNode

      const dataRow = this.getEmptyRecord()
      if (!_.isEmpty(parentRow)) {
        dataRow[parentField] = parentRow[valueField]
      }

      const addData = {
        type: 1, // 1 for inserting
        records: [{ ...dataRow }]
      }

      if (enterValueWhileAdding) {
        const level = depth
        const levelConstraint = _.find(levels, { level: level })
        const { lookupEnabled = false } = levelConstraint || {}
        const newRecordWindow = this.createNewRecordPopup(
          displayField,
          this.confirmNewNode(addData, displayField),
          lookupEnabled
        )
        newRecordWindow.show()
      } else {
        this.addData(addData)
      }
    }
  }

  handleTriggerAddNode(params) {
    const { newValue } = params

    // this.handleLookupBeforeInsertQuery()

    this.loadLookupSync()

    const tree = this.extjsTree.cmp
    const parentNode = tree.selModel.getSelection()[0] || tree.getRootNode()

    const {
      settings: {
        config: {
          editing: { levels = [] } = {},
          data: { parent: parentField, value: valueField, display: displayField } = {}
        } = {}
      } = {}
    } = this.props

    const addingEnabled = this.canAddUnderNode(parentNode, levels)
    if (addingEnabled) {
      const { data: { dataRow: parentRow = {} } = {} } = parentNode

      const dataRow = this.getEmptyRecord()
      if (!_.isEmpty(parentRow)) {
        dataRow[parentField] = parentRow[valueField]
      }

      dataRow[displayField] = newValue

      const addData = {
        type: 1, // 1 for inserting
        records: [{ ...dataRow }]
      }
      this.addData(addData)
    }
  }

  handleSetEditableState(params) {
    const { notEditable = null, editable = null, enableDragDrop = null } = params
    if (notEditable !== null || editable !== null) {
      let isReadonly = false
      if (notEditable !== null) {
        isReadonly = notEditable
      }
      if (editable !== null) {
        isReadonly = !editable
      }
      this.viewModel.set('editingDisabled', isReadonly)
    }
    if (enableDragDrop !== null) {
      this.setState({ enabledDragDrop: enableDragDrop })
    }
  }

  saveTreeStructure() {
    const {
      settings: {
        config: {
          data: {
            parent: parentField,
            value: valueField,
            display: displayField,
            sortOrder: sortOrderField
          } = {}
        } = {}
      } = {}
    } = this.props

    const { root: { childNodes = [] } = {} } = this.store || {}

    const resultingData = []
    const originalData = []

    this.getTabularData(
      childNodes,
      parentField,
      valueField,
      displayField,
      sortOrderField,
      resultingData,
      originalData
    )

    const updateData = {
      updateItems: []
    }

    _.forEach(resultingData, (row) => {
      const origNode = originalData[row[valueField]]
      // Add rows whose parent has changed
      if (origNode[parentField].toString() !== row[parentField].toString()) {
        updateData.updateItems.push({
          columnName: parentField,
          config: row.rawData,
          oldValue: origNode[parentField]
        })
        origNode[parentField] = row[parentField]
      }
      // Add rows whose sort order has changed
      if (sortOrderField && origNode[sortOrderField] !== row[sortOrderField]) {
        updateData.updateItems.push({
          columnName: sortOrderField,
          config: row.rawData,
          oldValue: origNode[sortOrderField]
        })
        origNode[sortOrderField] = row[sortOrderField]
      }
    })
    _.forEach(updateData.updateItems, (updateItem) => {
      const { config = {} } = updateItem
      this.props.updateRowInLocalData(config[__RowIndex], { ...config })
    })

    if (_.size(updateData) > 0) {
      this.updateData(updateData, true)
    }
  }

  handleTreeAfterRender(tree) {
    tree.on({
      drop: this.handleNodeDrop,
      beforeDrop: this.handleBeforeNodeDrop
    })
  }

  handleItemKeydown(tree, record, item, index, event) {
    if (event.ctrlKey && event.keyCode === 88) {
      // Ctrl + X
      const selectedRecords = tree.selModel.getSelection()
      if (_.size(selectedRecords) > 0) {
        this.clipBoard = []
        this.clipBoard.push(...selectedRecords)
      }
    } else if (event.ctrlKey && event.keyCode === 86) {
      // Ctrl + V
      if (_.size(this.clipBoard) > 0) {
        const {
          settings: {
            config: {
              data: { value: valueField } = {},
              editing: { dragdropEnabled = 'Disabled', levels = [], maxLevel = 10 } = {}
            } = {}
          } = {}
        } = this.props

        // Move is only possible when all individual moves are possible
        const movePossible = _.reduce(
          this.clipBoard,
          (result, node) => {
            if (result) {
              return this.movePossible(node, record, valueField, levels, dragdropEnabled, maxLevel)
            } else {
              return false
            }
          },
          true
        )

        if (movePossible) {
          _.forEach(this.clipBoard, (node) => this.moveNode(node, record))
          this.saveTreeStructure()
          this.clipBoard = []
          if (!record.isExpanded()) {
            record.expand(false)
          }
        }
      }
    }
  }

  getParentsOfNode(node, parents, valueField) {
    if (!node.data.root) {
      const { data: { dataRow = {} } = {} } = node

      parents.push(dataRow[valueField])
      this.getParentsOfNode(node.parentNode, parents, valueField)
    }
  }

  movePossible(node, targetNode, valueField, levels, dragdropEnabled, maxLevel) {
    const { data: { dataRow: movedNodeInfo = {} } = {} } = node
    const { data: { dataRow: targetNodeInfo = {} } = {} } = targetNode

    // check same node
    const sameNode = movedNodeInfo[valueField] === targetNodeInfo[valueField]

    // check ancestor node
    const parentNodes = []
    this.getParentsOfNode(targetNode, parentNodes, valueField)
    const ancestorNode = _.indexOf(parentNodes, movedNodeInfo[valueField]) >= 0

    // check levels
    const sourceLevel = node.data.depth - 1
    const targetLevel = targetNode.data.depth

    const dragToLevelAvailable = this.IsDragDropToLevelPossible(
      sourceLevel,
      targetLevel,
      dragdropEnabled,
      levels
    )

    const dragTreeDepth = this.getDepthOfTree(node)
    const dragMaxDepth = dragTreeDepth + targetLevel - 1
    const maxLevelExceeded = dragMaxDepth > maxLevel

    return !sameNode && !ancestorNode && !maxLevelExceeded && dragToLevelAvailable
  }

  moveNode(node, targetNode) {
    const { parentNode } = node
    parentNode.removeChild(node)
    targetNode.appendChild(node)
  }

  handleBeforeNodeDrop(node, data, overModel, dropPosition) {
    const {
      settings: {
        config: { editing: { dragdropEnabled = 'Disabled', levels = [], maxLevel = 10 } = {} } = {}
      } = {}
    } = this.props
    const { enabledDragDrop = true } = this.state
    if (!enabledDragDrop) {
      return false
    }

    const targetLevel =
      dropPosition === 'before' || dropPosition === 'after'
        ? overModel.data.depth - 1
        : overModel.data.depth

    const dragPossible = _.reduce(
      data.records,
      (result, record) => {
        if (!result) {
          return result
        }
        const sourceLevel = record.data.depth - 1

        const dragTreeDepth = this.getDepthOfTree(record)
        const dragMaxDepth = dragTreeDepth + targetLevel - 1

        const maxLevelExceeded = dragMaxDepth > maxLevel
        const dragToLevelAvailable = this.IsDragDropToLevelPossible(
          sourceLevel,
          targetLevel,
          dragdropEnabled,
          levels
        )
        return !maxLevelExceeded && dragToLevelAvailable
      },
      true
    )

    if (!dragPossible) {
      return false
    }

    this.setLeavesOfTree(data.records[0], targetLevel, maxLevel)
  }

  setLeavesOfTree(node, currLevel, maxLevel) {
    const { childNodes = [] } = node || {}
    node.data.leaf = currLevel >= maxLevel

    if (_.size(childNodes) > 0) {
      _.forEach(childNodes, (child) => {
        this.setLeavesOfTree(child, currLevel + 1, maxLevel)
      })
    }
  }

  getDepthOfTree(node) {
    const { childNodes = [] } = node || {}
    let maxChildDepth = 0
    if (_.size(childNodes) > 0) {
      _.forEach(childNodes, (child) => {
        let childDepth = this.getDepthOfTree(child)
        if (childDepth > maxChildDepth) {
          maxChildDepth = childDepth
        }
      })
    }
    return maxChildDepth + 1
  }

  IsDragDropToLevelPossible(sourceLevel, targetLevel, dragdropEnabled, levels) {
    if (dragdropEnabled === 'In the Same Level') {
      return sourceLevel === targetLevel
    } else if (dragdropEnabled === 'Upwards') {
      return sourceLevel > targetLevel
    } else if (dragdropEnabled === 'Upwards and Same Level') {
      return sourceLevel >= targetLevel
    } else if (dragdropEnabled === 'Downwards') {
      return sourceLevel < targetLevel
    } else if (dragdropEnabled === 'Downwards and Same Level') {
      return sourceLevel <= targetLevel
    } else if (dragdropEnabled === 'Enabled') {
      const levelConstraint = _.find(levels, { level: sourceLevel })
      if (levelConstraint) {
        const { dropTo = [] } = levelConstraint
        return _.indexOf(dropTo, targetLevel) > -1
      }
      return true
    }
    return false
  }

  handleNodeDrop() {
    this.saveTreeStructure()
  }

  getLookupValueOfId({ field, records, id }) {
    const { props: { actualFilters = {}, additionalArgs = {} } = {} } = this
    const filters = { ...actualFilters, ...additionalArgs }
    const keyRecordsHash = this.getHashForLookup(field, records, filters)
    const columnLookupData = this.lookupData[field] && this.lookupData[field][keyRecordsHash]
    if (columnLookupData) {
      const record = _.find(columnLookupData, (item) => {
        return item.id.toString() === id.toString()
      })
      if (record) {
        return record.value
      }
    }
  }

  getHashForLookup(field, records, filters) {
    const { lookupQueryList, isLookupQuery } = this.getLookupConfigOfField(field)
    if (isLookupQuery) {
      var keyRecords = []
      if (filters) {
        keyRecords.push({ filters })
      }

      _.forEach(lookupQueryList, (lookupQuery) => {
        keyRecords.push({
          [lookupQuery]: records[lookupQuery]
        })
      })

      return hash(keyRecords)
    }
  }

  getLookupConfigOfField(field) {
    const { settings: { query: { editableFields = [] } = {} } = {} } = this.props

    const editableFieldDef = _.find(editableFields, {
      field: field
    })

    const { lookupQueryList = [], isLookupQuery, substitudeField = null } = editableFieldDef || {}

    return {
      lookupQueryList,
      isLookupQuery,
      substitudeField
    }
  }

  updateData(updateData) {
    this.extjsTree.cmp.mask('Applying Changes...')

    this.apiClient({
      requestType: 'update',
      data: updateData,
      onSuccess: () => {},
      onError: this.handleUpdateError,
      onComplete: () => {
        this.extjsTree.cmp.unmask()
        this.handleDataUpdated()
      }
    })
  }

  deleteData(editData, record) {
    this.extjsTree.cmp.mask('Deleting...')

    this.apiClient({
      requestType: 'edit',
      data: editData,
      onSuccess: () => {
        const { parentNode, data: { dataRow = {} } = {} } = record
        const rowIndex = dataRow[__RowIndex]

        parentNode.removeChild(record)
        if (_.size(this.extjsTree.cmp.dockedItems.items) > 1) {
          const addNodeButton = this.extjsTree.cmp.dockedItems.items[1].items.items[0]
          addNodeButton.enable()
        }

        this.props.deleteRowInLocalData(rowIndex)
      },
      onError: this.handleUpdateError,
      onComplete: () => {
        this.extjsTree.cmp.unmask()
        this.handleDataUpdated()
      }
    })
  }

  addData(editData) {
    this.extjsTree.cmp.mask('Adding...')

    const { actualFilters = {} } = this.props

    this.apiClient({
      requestType: 'edit',
      data: {
        ...editData,
        filters: actualFilters
      },
      onSuccess: this.handleNodeAdded,
      onError: this.handleUpdateError,
      onComplete: () => {
        this.extjsTree.cmp.unmask()
        this.handleDataUpdated()
      }
    })
  }

  handleDataUpdated() {
    return { refreshKey: uuidv4() }
  }

  getMaxRowIndex(node) {
    const { childNodes = [], data: { dataRow = {} } = {} } = node || {}

    let nodeMaxRowIndex = 0
    if (_.has(dataRow, __RowIndex)) {
      nodeMaxRowIndex = dataRow[__RowIndex]
    }

    if (_.size(childNodes) > 0) {
      _.forEach(childNodes, (child) => {
        const childMaxRowIndex = this.getMaxRowIndex(child)
        if (childMaxRowIndex > nodeMaxRowIndex) {
          nodeMaxRowIndex = childMaxRowIndex
        }
      })
    }
    return nodeMaxRowIndex
  }

  handleNodeAdded(response) {
    const {
      settings: {
        config: {
          data: {
            display: displayField,
            parent: parentField,
            value: valueField,
            icon: iconField,
            sortOrder: sortOrderField
          } = {},
          editing: { maxLevel = 10, levels = [] } = {}
        } = {}
      } = {},
      data: { schema: fieldConfigs = [] } = {}
    } = this.props

    if (!_.isEmpty(response)) {
      const tree = this.extjsTree.cmp
      const selectedNode = tree.selModel.getSelection()[0] || tree.getRootNode()
      const { data: { depth: level } = {} } = selectedNode
      const table = _.get(response, 'dataset.table', [])

      const maxRowIndex = this.getMaxRowIndex(tree.getRootNode())

      let lookupEnabled = false

      const levelConstraint = _.find(levels, { level: level })
      if (levelConstraint) {
        const { lookupEnabled: levelLookupEnabled = false } = levelConstraint
        lookupEnabled = levelLookupEnabled
      }

      _.forEach(table, (row, index) => {
        const newRowIndex = maxRowIndex + 1 + index

        const treeRow = _.transform(
          row,
          (result, value, key) => {
            if (_.toLower(key) === _.toLower(displayField)) {
              let displayValue = value
              if (lookupEnabled) {
                //@TODO: check [displayField]
                // const lookupRow = _.find(this.lookupStore.data.items, (row) => {
                const lookupRow = _.find(this.lookupStore[displayField].data.items, (row) => {
                  return row.data.id.toString() === value.toString()
                })
                if (lookupRow) {
                  displayValue = lookupRow.data.value
                }
              }
              result[displayField] = displayValue
            } else if (_.toLower(key) === _.toLower(parentField)) {
              result[parentField] = value
            } else if (_.toLower(key) === _.toLower(valueField)) {
              result[valueField] = value
            } else if (_.toLower(key) === _.toLower(iconField)) {
              result[iconField] = value
            } else if (_.toLower(key) === _.toLower(sortOrderField)) {
              result[sortOrderField] = value
            } else {
              const field = _.find(fieldConfigs, (fieldConfig) => {
                return _.toLower(fieldConfig.fieldName) === _.toLower(key)
              })
              if (field) {
                result[field.fieldName] = value
              } else {
                result[key] = value
              }
            }
          },
          {}
        )

        treeRow[__RowIndex] = newRowIndex

        const sortOrder = treeRow[sortOrderField]

        const newNode = {
          sortOrder,
          children: [],
          leaf: level === maxLevel,
          id: treeRow[valueField],
          text: treeRow[displayField],
          iconCls: treeRow[iconField],
          dataRow: { ...treeRow }
        }

        this.props.updateRowInLocalData(newRowIndex, { ...treeRow })

        const { childNodes = [] } = selectedNode

        const treeIndex = _.findIndex(childNodes, (childNode) => {
          const { data: { dataRow = {} } = {} } = childNode

          return dataRow[sortOrderField] > sortOrder
        })
        const createdNode = selectedNode.insertChild(treeIndex, newNode)
        // Expand the parent node
        if (!selectedNode.isExpanded()) {
          selectedNode.expand(false)
        }
        this.newNodeAdded = true
        tree.selModel.select(createdNode)
      })
    }
  }

  handleUpdateError(response) {
    slvyToast.warning({
      message: _.get(response, 'responseJSON.message', 'An error occurred!')
    })
  }

  getTabularData(
    nodes,
    parentField,
    valueField,
    displayField,
    sortOrderField,
    tabularData,
    originalData
  ) {
    _.forEach(nodes, (node, index) => {
      const { childNodes = [], data: { dataRow = {} } = {}, parentNode } = node
      tabularData.push({
        [valueField]: dataRow[valueField],
        [parentField]: parentNode.data.dataRow ? parentNode.data.dataRow[valueField] : 0,
        [displayField]: dataRow[displayField],
        [sortOrderField]: index,
        [__RowIndex]: dataRow[__RowIndex],
        rawData: dataRow
      })

      originalData[dataRow[valueField]] = dataRow
      if (_.size(childNodes) > 0) {
        this.getTabularData(
          childNodes,
          parentField,
          valueField,
          displayField,
          sortOrderField,
          tabularData,
          originalData
        )
      }
    })
  }

  getLookupDataOfField(field, records) {
    const { props: { actualFilters = {}, additionalArgs = {} } = {} } = this
    const filters = { ...actualFilters, ...additionalArgs }
    const keyRecordsHash = this.getHashForLookup(field, records.data, filters)
    const lookupDataOfField = this.lookupData[field] && this.lookupData[field][keyRecordsHash]

    return lookupDataOfField
  }

  handleStoreUpdate(store, record, operation, modifiedFieldNames, details) {
    const { data: { dataRow = {} } = {} } = record || {}

    // Update node text
    if (_.indexOf(modifiedFieldNames, 'text') >= 0) {
      const {
        settings: {
          config: { editing: { levels = [] } = {}, data: { display: displayField } = {} } = {}
        } = {}
      } = this.props

      const { data: { text: newValue } = {} } = record || {}
      const oldValue = dataRow[displayField]

      const { data: { depth } = {} } = record || {}

      const level = depth - 1

      let lookupEnabled = false

      const levelConstraint = _.find(levels, { level: level })
      if (levelConstraint) {
        const { lookupEnabled: levelLookupEnabled = false } = levelConstraint
        lookupEnabled = levelLookupEnabled
      }

      // Update Cache
      let nodeText = newValue
      if (lookupEnabled) {
        const lookupRow = _.find(this.lookupStore[displayField].data.items, (row) => {
          return row.data.id.toString() === newValue
        })
        if (lookupRow) {
          // Update tree node text
          if (lookupRow.data.value === oldValue) {
            return
          }
          nodeText = lookupRow.data.value
        }
      }

      record.data.text = nodeText
      dataRow[displayField] = nodeText
      const localDataRow = { ...dataRow }
      this.props.updateRowInLocalData(localDataRow[__RowIndex], {
        ...localDataRow
      })

      // Update data
      const updateData = {
        updateItems: []
      }

      const row = { ...dataRow }
      row[displayField] = newValue

      updateData.updateItems.push({
        columnName: displayField,
        config: row,
        oldValue: oldValue
      })
      this.updateData(updateData, true)
    } else if (record.modified) {
      _.forEach(modifiedFieldNames, (modifiedFieldName) => {
        // 'parentId', 'index', 'isFirst' are internal columns of extjs tree ignore them
        if (_.indexOf(['parentId', 'index', 'isFirst', 'isLast'], modifiedFieldName) >= 0) {
          return false
        }

        // Get the actual values
        const columnValues = {}
        _.forEach(dataRow, (value, key) => {
          columnValues[key] = record.data[key]
        })

        const oldValue = record.modified && record.modified[modifiedFieldName]
        this.handleCellValueChanged(modifiedFieldName, record, columnValues, oldValue)
      })
    }
  }

  handleCellValueChanged(modifiedFieldName, record, columnValues, oldValue, delaySaving) {
    const fieldConfigs = this.getFieldConfigs()
    columnValues = _.transform(
      columnValues,
      (result, value, key) => {
        const dateField = _.find(fieldConfigs, (field) => {
          return field.fieldName === key && field.dataType === 'datetime'
        })
        if (dateField) {
          result[key] = moment(value).format('YYYY-MM-DD HH:mm:ss')
        } else {
          result[key] = value
        }
      },
      {}
    )

    // Save Button is not displayed.Directly apply the changes.
    if (_.has(columnValues, __RowIndex)) {
      const rowIndex = columnValues[__RowIndex]

      const updateData = this.prepareUpdateData([
        { changedColumn: modifiedFieldName, columnValues: columnValues }
      ])

      if (_.size(updateData.updateItems) > 0) {
        this.updateValue(updateData, false)
        const { substitudeField } = this.getLookupConfigOfField(modifiedFieldName)
        if (substitudeField) {
          // If the edited column has a substitude field
          // Update also the substitude value in redux data and store
          const lookupValue = this.getLookupValueOfId({
            field: modifiedFieldName,
            records: columnValues,
            id: columnValues[modifiedFieldName]
          })
          columnValues[substitudeField] = lookupValue
          record.data[substitudeField] = lookupValue
        }
        this.props.updateRowInLocalData(rowIndex, columnValues)
        record.commit()
      }
    }
  }

  updateValue(updateData, reloadGridData = false) {
    const {
      data: { updatehints = {} } = {},
      actualFilters = {},
      additionalArgs = {}
    } = this.props || {}

    this.extjsTree.cmp.mask('Applying Changes...')

    this.apiClient({
      requestType: 'update',
      data: {
        ...updateData,
        updatehints,
        filters: { ...actualFilters, ...additionalArgs }
      },
      onSuccess: () => {},
      onError: this.handleUpdateError,
      onComplete: () => {
        this.extjsTree.cmp.unmask()
        this.handleDataUpdated()
      }
    })
  }

  prepareUpdateData(updateDataList) {
    const fieldConfigs = this.getFieldConfigs()

    // If the same cell is edited multiple times
    // send only the last value to the server
    const groups = _.groupBy(updateDataList, (updateItem) => {
      const { changedColumn, columnValues: { __SLVYRowIndex } = {} } = updateItem
      return changedColumn + '-' + __SLVYRowIndex
    })
    let reducedUpdates = _.map(groups, (group) => {
      return { ..._.last(group), oldValue: _.first(group).oldValue }
    })

    // If the same cell is edited multiple times
    // and the cell retains its original value
    // ignore the cell
    reducedUpdates = _.filter(reducedUpdates, (updateItem) => {
      const { changedColumn, columnValues = {}, oldValue } = updateItem
      if (_.has(columnValues, changedColumn)) {
        return columnValues[changedColumn] !== oldValue
      }
    })

    const updateData = {
      updateItems: []
    }
    // const actualData = this.store.getData()
    // const { items: rows = [] } = actualData || {}
    _.forEach(reducedUpdates, (updateItem) => {
      // const row = _.find(rows, row => {
      //   return (
      //     row.get('__SLVYRowIndex') ===
      //     updateItem.columnValues['__SLVYRowIndex']
      //   )
      // })

      // const { data: { dataRow = {} } = {} } = row || {}

      const updateRecord = this.formatDatesForUpdate(updateItem.columnValues, fieldConfigs)

      // Copy the actual grid data to all update rows.
      // This is necessary since we want to feed the update statements of columns with
      // actual data
      if (updateRecord) {
        updateData.updateItems.push({
          columnName: updateItem.changedColumn,
          config: { ...updateRecord },
          oldValue: updateItem.oldValue
        })
      }
    })

    return updateData
  }

  createGridColumns(columnConfigs, fields) {
    // Create grid a column for each column config
    let columns = _.map(columnConfigs, (columnConfig, index) => {
      const field = _.find(fields, { fieldName: columnConfig.fieldName })
      return this.createColumn(columnConfig, field)
    })

    // Filter out empty columns
    columns = _.filter(columns, (column) => {
      return column
    })

    return columns
  }

  createColumn(columnConfig, field = {}) {
    const {
      fieldName: configFieldName = null,
      hidden: configHidden = false,
      flex: configFlex = 1,
      width: configWidth = 0,
      align = null,
      header: configHeader = null,
      groupHeader = null,
      summaryType = null,
      sortable = false,
      cellStyle = null,
      tooltip: configTooltip = null,
      locked = false
    } = columnConfig

    const flex = configFlex
    const width = configWidth
    const { isLookupQuery, substitudeField } = this.getLookupConfigOfField(configFieldName)
    // We changed column alignment options from start, center, end to
    // left, center, right
    let alignModified = align

    const firstDataRow = this.getFirstDataRow(this.props.pluginData)
    const header = this.replaceTemplate(configHeader, firstDataRow)
    const tooltip =
      _.size(configTooltip) > 0 ? this.replaceTemplate(configTooltip, firstDataRow) : header

    var editor = false
    var {
      editing: {
        enabled: columnEditingEnabled = false,
        allowBlank: editingAllowBlank = false
      } = {},
      adding: { enabled: columnAddingEnabled = false } = {},
      actionButton: { enabled: actionEnabled } = {}
    } = columnConfig
    var { dataType = '', fieldName } = field

    // Column is editable and has an update query
    // Bool columns have their own column type they dont need an editor
    if ((columnEditingEnabled || columnAddingEnabled) && dataType !== 'bool') {
      if (isLookupQuery) {
        editor = this.createComboEditor({
          field: configFieldName,
          columnConfig
        })
      } else if (this.isNumericType(dataType)) {
        const formattedFields = this.getFormattedFields()
        const formattedField = _.find(formattedFields, {
          columnName: field.fieldName
        })
        const { formatString = '' } = formattedField || {}
        editor = {
          xtype: 'numberfield',
          allowBlank: editingAllowBlank,
          decimalPrecision: this.getNoOfDecimalPlaces(formatString),
          listeners: {
            change: function (field, value) {
              if (value > field.maxValue) field.setValue(field.maxValue)
              else if (value < field.minValue) field.setValue(field.minValue)
            }
          }
        }
      } else if (dataType === 'datetime') {
        const formattedFields = this.getFormattedFields()
        const formattingInfo = _.find(formattedFields, {
          columnName: fieldName
        })
        editor = {
          xtype: 'datefield'
        }
        if (formattingInfo && formattingInfo.formatString) {
          editor.format = _.replace(formattingInfo.formatString, 'Date ', '')
        }
      } else {
        editor = { allowBlank: editingAllowBlank }
      }
    }

    const commonProperties = {
      locked,
      tooltip,
      sortable,
      groupHeader,
      summaryType,
      text: header,
      tdCls: cellStyle,
      ignoreExport: false,
      align: alignModified,
      hidden: configHidden,
      minWidth: configWidth,
      dataIndex: configFieldName,
      ...(flex && { flex })
    }

    // We cannot apply flex and width to the same column
    // It causes problems in sumnmary line
    if (!flex) {
      commonProperties.width = width
    }
    if (dataType === 'bool') {
      return {
        xtype: 'checkcolumn',
        ...commonProperties,
        listeners: {
          beforecheckchange: this.handleBeforeCheckChange
        }
      }
    }

    if (actionEnabled) {
      return this.createActionColumn(columnConfig)
    }

    return {
      ...commonProperties,
      editor,
      renderer: substitudeField
        ? this.substitutionColumnRenderer(substitudeField, columnConfig)
        : this.columnRenderer(columnConfig)
    }
  }

  createComboEditor({ field, columnConfig }) {
    const { editing: { allowBlank = false } = {} } = columnConfig || {}
    // Create extjs store
    const { environment } = this.props.params || {}
    const lookupColumnStore = this.getLookupStore(field, environment, false)
    this.lookupStore[field] = lookupColumnStore
    const editor = {
      xtype: 'combo',
      typeAhead: true,
      triggerAction: 'all',
      store: lookupColumnStore,
      queryMode: 'local',
      displayField: 'value',
      valueField: 'id',
      forceSelection: true,
      allowBlank: allowBlank,
      listeners: {
        focus: this.focusCombobox(field)
      }
    }

    return editor
  }

  focusCombobox(field) {
    return (record, event) => {
      const { props: { actualFilters = {}, additionalArgs = {} } = {} } = this
      const lookupQueryParams = record.up('editor') ? record.up('editor').context.record.data : {}

      const filters = { ...actualFilters, ...additionalArgs }
      const actualParamsHash = this.getHashForLookup(field, lookupQueryParams, filters)
      const storedLookupData = this.lookupData[field] && this.lookupData[field][actualParamsHash]
      if (!storedLookupData) {
        const { data: { updatehints = {} } = {} } = this.props || {}
        // We need update hints and current record data to load lookup data
        const lookupInputData = {
          record: lookupQueryParams,
          updatehints,
          filters
        }
        this.ignoreLookupLoad = false
        this.lookupStore[field].proxy.extraParams = lookupInputData
        this.lookupStore[field].load()
      } else {
        this.ignoreLookupLoad = true
        this.lookupStore[field].setData(storedLookupData)
      }
    }
  }

  createActionColumn(columnConfig) {
    const {
      flex = 1,
      width: configWidth = 0,
      header: configHeader = null,
      groupHeader = null,
      fontColor: color,
      icon: { icon: actionIcon } = {},
      actionButton: {
        editableCondition: enabledCondition,
        name: actionName,
        clickable: clickableOnDisabled
      } = {},
      tooltip: configTooltip = null
    } = columnConfig

    const firstDataRow = this.getFirstDataRow(this.props.pluginData)
    const header = this.replaceTemplate(configHeader, firstDataRow)

    const iconClass = this.getClassName(null, color)

    const tooltip =
      _.size(configTooltip) > 0 ? this.replaceTemplate(configTooltip, firstDataRow) : header

    //@TODO: iconCls does not work because of iconClass coming like 'a13609127'
    //const iconCls = iconClass

    return {
      xtype: 'actioncolumn',
      groupHeader,
      text: header,
      align: 'center',
      sortable: false,
      hideable: false,
      tooltip,
      menuDisabled: true,
      width: configWidth,
      renderer: this.columnRenderer(columnConfig),
      ...(flex && { flex }),
      items: [
        {
          getClass: () => iconClass + ' x-' + actionIcon,
          handler: this['handleActionClick_' + actionName],
          isActionDisabled: function (view, rowIndex, colIndex, item, record) {
            let isActionDisabled = false
            if (clickableOnDisabled) {
              return false
            }
            if (enabledCondition) {
              if (_.has(record.data, enabledCondition) && !record.data[enabledCondition]) {
                isActionDisabled = true
              }
            }
            return isActionDisabled
          }
        }
      ]
    }
  }

  getClassName = function (background, color) {
    let cssClassName = 'a' + new Date().valueOf()
    let rules = ''

    if (background) {
      const backgroundHexColor = this.rgb2hex(background)
      rules += 'background-color:' + background + '!important;'
      cssClassName += backgroundHexColor
    }

    if (color) {
      const backColor = this.rgb2hex(color)
      rules += 'color:' + color + '!important;'
      cssClassName += backColor
    }

    cssClassName = cssClassName.replace(new RegExp('#', 'g'), '')

    if (this.createdCssClasses.indexOf(cssClassName) < 0) {
      this.createClass('.' + cssClassName, rules)
      this.createdCssClasses.push(cssClassName)
    }

    return cssClassName
  }

  createClass = function (name, rules) {
    const style = document.createElement('style')
    style.type = 'text/css'

    document.getElementsByTagName('head')[0].appendChild(style)
    style.innerHTML = name + '{' + rules + '}'
  }

  rgb2hex = function (rgba) {
    let inputValue = rgba
    inputValue = inputValue.replace('rgba(', '')
    inputValue = inputValue.replace(')', '')
    const values = inputValue.split(',')

    return this.rgbToHex(
      parseInt(values[0], 10),
      parseInt(values[1], 10),
      parseInt(values[2], 10),
      parseFloat(values[3])
    )
  }

  rgbToHex(r, g, b, a) {
    return (
      '#' +
      this.componentToHex(r).toUpperCase() +
      this.componentToHex(g).toUpperCase() +
      this.componentToHex(b).toUpperCase() +
      parseInt(a * 100, 10)
    )
  }

  componentToHex(c) {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  formatDatesForUpdate(record, fields) {
    return _.transform(
      record,
      (result, value, key) => {
        const dateField = _.find(fields, (field) => {
          return field.fieldName === key && field.dataType === 'datetime'
        })
        if (dateField) {
          result[key] = moment(value).format('YYYY-MM-DD HH:mm:ss')
        } else {
          result[key] = value
        }
      },
      {}
    )
  }

  handleBeforeCheckChange(checkColumn, rowIndex, checked, record) {
    const { dataIndex: field } = checkColumn
    const { data: rowData } = record
    // Convert falsy values to boolean, since extjs work only with boolean
    return !!this.isCellEditable(field, rowData)
  }

  isCellEditable(field, rowData) {
    // Get column editability
    const columnConfigs = this.getColumnConfigs() || []
    const column = _.find(columnConfigs, { fieldName: field })
    const {
      editing: { enabled: editingEnabled, editableCondition: conditionField = null }
    } = column

    // Cell is editable when there is no conditionField or conditionField is true
    return editingEnabled && (!conditionField || (conditionField && rowData[conditionField]))
  }

  getColumnConfigs() {
    return _.get(this.props, 'settings.config.columns', [])
  }

  getNoOfDecimalPlaces(format) {
    const subStrings = format.split('.')
    return _.size(subStrings) >= 2 ? _.size(subStrings[1].match(new RegExp('0', 'g')) || []) : 0
  }

  columnRenderer(columnConfig) {
    const { getFormattedValue } = this.props
    return (value, metaData, record) => {
      const {
        backColor = null,
        fontColor = null,
        fieldName = null,
        cellTooltip = null,
        icon: { icon: selectedIcon = '', displayOnlyIcon = false, iconPosition = '' } = {},
        action: { cellClickEnabled = false } = {},
        actionButton: { editableCondition = null, clickable = false, enabled: actionEnabled } = {}
      } = columnConfig || {}

      const fieldConfigs = this.getFieldConfigs()

      const fieldConfig =
        _.find(fieldConfigs, (fieldConfig) => {
          return fieldConfig.fieldName === columnConfig.fieldName
        }) || {}

      const { dataType = null } = fieldConfig
      let formattedValue

      if (dataType === 'datetime' && (_.isNil(value) || _.size(value.toString()) === 0)) {
        // Prevent invalid date
        formattedValue = ''
      } else {
        const formattedFields = this.getFormattedFields()
        formattedValue = getFormattedValue(fieldName, value, formattedFields)
      }

      if (backColor) {
        metaData.style += `background-color: ${backColor};`
      }
      if (fontColor) {
        metaData.style += `color: ${fontColor};`
      }

      // This is related to iconCls, getIconCls issue. When we pass this <i> element, it would double the icon.
      // So we hid it by passing a hidden class.
      const hiddenClass = actionEnabled ? ' d-none ' : ''

      if (selectedIcon) {
        if (displayOnlyIcon) {
          formattedValue =
            '<i class="fa ' + selectedIcon + hiddenClass + '" aria-hidden="true"></i> '
        } else {
          if (iconPosition === 'left') {
            metaData.style += 'display: flex;align-items: center;justify-content: space-between;'
            formattedValue =
              '<i class="fa ' +
              selectedIcon +
              hiddenClass +
              '" aria-hidden="true"></i> <span>' +
              formattedValue +
              '</span>'
          } else {
            formattedValue =
              formattedValue +
              ' <i class="fa ' +
              selectedIcon +
              hiddenClass +
              '" aria-hidden="true"></i>'
          }
        }
      }
      if (cellTooltip) {
        const tooltip = this.replaceTemplate(cellTooltip, record.data.dataRow)
        metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
      }

      if (cellClickEnabled) {
        metaData.style += 'cursor: pointer;'
      }

      if (editableCondition) {
        if (_.has(record.data, editableCondition) && !record.data[editableCondition] && clickable) {
          metaData.style += 'opacity: 0.3'
        }
      }

      return formattedValue
    }
  }

  substitutionColumnRenderer(substitudeField, columnConfig) {
    const { getFormattedValue } = this.props
    return (value, metaData, record) => {
      const {
        backColor = null,
        fontColor = null,
        cellTooltip = null,
        icon: { icon: selectedIcon = '', displayOnlyIcon = false, iconPosition = '' } = {},
        action: { cellClickEnabled = false } = {},
        actionButton: { editableCondition = null, clickable = false } = {}
      } = columnConfig || {}

      var formattedValue = value
      if (substitudeField) {
        const fieldConfigs = this.getFieldConfigs()

        const fieldConfig =
          _.find(fieldConfigs, (fieldConfig) => {
            return fieldConfig.fieldName === substitudeField
          }) || {}

        const { dataType = null } = fieldConfig

        // Substitude value
        formattedValue = record.data[substitudeField]

        if (
          dataType === 'datetime' &&
          (_.isNil(formattedValue) || _.size(formattedValue.toString()) === 0)
        ) {
          // Prevent invalid date
          formattedValue = ''
        } else {
          const formattedFields = this.getFormattedFields()
          formattedValue = getFormattedValue(substitudeField, formattedValue, formattedFields)
        }
      }
      if (metaData) {
        // metaData is null when this renderer is used
        if (backColor) {
          metaData.style += `background-color: ${backColor};`
        }
        if (fontColor) {
          metaData.style += `color: ${fontColor};`
        }
        if (selectedIcon) {
          if (displayOnlyIcon) {
            formattedValue = '<i class="fa ' + selectedIcon + '" aria-hidden="true"></i> '
          } else {
            if (iconPosition === 'left') {
              metaData.style += `display: flex;align-items: center;justify-content: space-between;`
              formattedValue =
                '<i class="fa ' +
                selectedIcon +
                '" aria-hidden="true"></i> <span>' +
                formattedValue +
                '</span>'
            } else {
              formattedValue =
                formattedValue + ' <i class="fa ' + selectedIcon + '" aria-hidden="true"></i>'
            }
          }
        }
        if (cellTooltip) {
          const tooltip = this.replaceTemplate(cellTooltip, record.data.dataRow)
          metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
        }

        if (cellClickEnabled) {
          metaData.style += `cursor: pointer;`
        }

        if (editableCondition) {
          if (
            _.has(record.data, editableCondition) &&
            !record.data[editableCondition] &&
            clickable
          ) {
            metaData.style += `opacity: 0.3`
          }
        }
      }

      return formattedValue
    }
  }

  isNumericType(type) {
    if (type in this.newColumnOptions) {
      return this.newColumnOptions[type].type === 'numeric'
    }

    return false
  }

  getFormattedFields() {
    const {
      settings: { config: gridConfig = {}, query: { formattedFields = [] } = {} } = {},
      data: { configurationhints = {} } = {}
    } = this.props

    const { grid: { automaticConfiguration = false } = {} } = gridConfig

    return automaticConfiguration
      ? this.getAutoFormattedFields(configurationhints)
      : formattedFields
  }

  getAutoFormattedFields(configurationHints) {
    const { columns = {} } = configurationHints || {}
    return _.map(columns, (column) => {
      let newFormat = this.convertFormat(column.format)
      return {
        baseColumn: column.fieldName,
        columnName: column.fieldName,
        formatString: newFormat
      }
    })
  }

  getFirstDataRow(pluginData) {
    return pluginData && pluginData[0]
  }

  getFieldConfigs() {
    const {
      data: { schema, schema: fieldConfigsSchema = [] } = {},
      settings: { query: { fields: fieldConfigsQuery = [] } = {} } = {}
    } = this.props

    if (schema) {
      return fieldConfigsSchema
    }
    return fieldConfigsQuery
  }

  handleDataColumnChanged(value, initialData, previousValue) {
    if (
      !previousValue ||
      (value.columns && value.columns.length !== previousValue.columns.length)
    ) {
      return value
    }
    _.forEach(value.columns, (column, index) => {
      // Data field of the column changed
      if (column.fieldName && previousValue.columns[index].fieldName !== column.fieldName) {
        // Set Header
        if (column.header === previousValue.columns[index].fieldName) {
          column.header = column.fieldName
        }

        if (column.actionButton.name === previousValue.columns[index].fieldName) {
          column.actionButton.name = column.fieldName
        }

        const fieldConfigs = this.getFieldConfigs()
        const field = _.find(fieldConfigs, (field) => field.fieldName === column.fieldName)

        // Set column alignment
        if (field.dataType in this.newColumnOptions) {
          column.align = this.newColumnOptions[field.dataType].align
        }
      }
    })
    return value
  }

  handleFooterButtonClick() {
    return { refreshKey: uuidv4() }
  }

  handleActionClick(record, actionItemTitle) {
    const fieldConfigs = this.getFieldConfigs()

    return _.transform(
      fieldConfigs,
      (result, field) => {
        result[field.fieldName] = record.data[field.fieldName]
      },
      { _ActionTitle: actionItemTitle, _RefreshKey: uuidv4() }
    )
  }

  handleCellClick(grid, td, cellIndex, record) {
    const { config: { columns: columnConfigs = [] } = {} } = this.props.settings || {}

    const fieldConfigs = this.getFieldConfigs()

    const { grid: { columnManager: { columns: gridColumns = [] } = {} } = {} } = grid || {}

    // The column field of the clicked cell
    const dataIndex = gridColumns[cellIndex].dataIndex
    const columnConfig = _.find(columnConfigs, { fieldName: dataIndex })
    const { action: { cellClickEnabled = false } = {} } = columnConfig || {}

    if (cellClickEnabled) {
      return _.transform(
        fieldConfigs,
        (result, field) => {
          result[field.fieldName] = record.data[field.fieldName]
        },
        {
          columnField: dataIndex
        }
      )
    }
  }

  loadLookupSync() {
    const tree = this.extjsTree.cmp

    const parentNode = tree.selModel.getSelection()[0] || tree.getRootNode()
    const { data: { dataRow: parentRow = {} } = {} } = parentNode || {}

    const {
      settings: {
        config: {
          data: { display: displayField, parent: parentField, value: valueField } = {}
        } = {}
      } = {},
      actualFilters = {},
      additionalArgs = {}
    } = this.props

    const filters = { ...actualFilters, ...additionalArgs }
    const dataRow = this.getEmptyRecord()
    dataRow[parentField] = parentRow[valueField]

    const actualParamsHash = this.getHashForLookup(displayField, dataRow, filters)

    const storedLookupData =
      this.lookupData[displayField] && this.lookupData[displayField][actualParamsHash]

    if (!storedLookupData) {
      // We need update hints and current record data to load lookup data
      const lookupInputData = {
        record: { ...dataRow }
      }

      this.apiClient({
        requestType: `lookup/${displayField}`,
        data: lookupInputData,
        onSuccess: (data) => {
          const { data: { result = [] } = {} } = data

          const correctedResult = _.map(result, (row) => {
            return { id: row.Id, value: row.Value }
          })
          const keyRecordsHash = this.getHashForLookup(displayField, lookupInputData, filters)

          if (!this.lookupData[displayField]) {
            this.lookupData[displayField] = {}
          }
          this.lookupData[displayField][keyRecordsHash] = correctedResult

          this.lookupStore[displayField].setData(correctedResult)
        }
      })
    } else {
      this.lookupStore[displayField].setData(storedLookupData)
    }
  }

  getRowClass(record) {
    const {
      settings: {
        config: {
          data: { checkboxEnableColumn: checkEnabled } = {},
          selection: { multiSelection = false, checkboxSelection = false } = {}
        } = {}
      } = {}
    } = this.props

    if (multiSelection && checkboxSelection && !checkEnabled) {
      return 'yesCheckbox'
    } else {
      if (!multiSelection || (multiSelection && !checkboxSelection)) {
        return 'noCheckbox'
      }
      if (!record.get(checkEnabled)) {
        return 'noCheckbox'
      }
    }
  }

  handleTreeNodeExpandedCollapsed() {
    const {
      id: pluginId,
      settings: {
        config: { data: { value: valueField, rememberTreeState = false } = {} } = {}
      } = {}
    } = this.props

    const expandedNodes = []
    _.forEach(this.store.data.items, (item) => {
      this.getExpandedNodes(item, expandedNodes, valueField)
    })
    if (rememberTreeState) {
      localStorage.setItem(
        `Sencha-Tree-Plugin-ExpandedNodes${pluginId}`,
        JSON.stringify(expandedNodes)
      )
    }
  }

  getExpandedNodes(node, expandedNodes, valueField) {
    const { data: { expanded = false, dataRow = {}, children = [] } = {} } = node
    if (expanded) {
      expandedNodes.push(dataRow[valueField])
    }
    _.forEach(children, (child) => {
      this.getExpandedNodes(child, expandedNodes, valueField)
    })
  }

  apiClient({ requestType, data, onSuccess, onError, onComplete }) {
    const { id: pluginId, client } = this.props

    const clientUrl = `data/plugin/${pluginId}/${requestType}`

    client.post(clientUrl, { data }).then(onSuccess).catch(onError).finally(onComplete)
  }

  render() {
    const {
      id: pluginId,
      pluginData,
      isPreviewMode,
      size,
      size: { height },
      width,
      pluginData: result = [],
      settings: {
        config: treeConfig,
        config: {
          grid: { lockable = false } = {},
          data: {
            display: displayField,
            parent: parentField,
            value: valueField,
            icon: iconField,
            sortOrder: sortOrderField,
            treeColumnHeader,
            columnLines = false,
            width: treeColumnWidth = 90,
            flex = 1,
            rememberTreeState = false
          } = {},
          columns: columnConfigs = [],
          editing: {
            dragdropEnabled = 'Disabled',
            textEditingEnabled = false,
            deletingEnabled = false,
            maxLevel = 10
          } = {},
          selection: {
            nodeDeselection = true,
            multiSelection = true,
            checkboxSelection = false
          } = {}
        } = {}
      } = {}
    } = this.props

    if (this.store) {
      if (sortOrderField) {
        this.store.setSorters({ property: 'sortOrder' })
      }

      const expandedNodes = rememberTreeState
        ? JSON.parse(localStorage.getItem(`Sencha-Tree-Plugin-ExpandedNodes${pluginId}`)) || []
        : []

      const root = {
        expanded: true,
        children: this.getData(
          _.cloneDeep(result),
          displayField,
          parentField,
          valueField,
          iconField,
          sortOrderField,
          maxLevel,
          expandedNodes
        )
      }
      this.store.setRootNode(root)
      if (checkboxSelection) {
        this.store.model.addFields({
          name: 'checked',
          type: 'boolean',
          defaultValue: false
        })
      }
    }

    const fieldConfigs = this.getFieldConfigs()

    const dragdropPlugin = this.createDragDropPlugin(dragdropEnabled)

    const textEditingPlugin = this.createTextEditingPlugin(textEditingEnabled)

    const footerBar = this.createFooter(treeConfig)

    const gridColumns = this.createGridColumns(columnConfigs, fieldConfigs)

    const deleteColumn = this.createDeleteColumn(deletingEnabled)

    const firstDataRow = this.getFirstDataRow(pluginData)
    const treeColumnHeaderText = this.replaceTemplate(treeColumnHeader, firstDataRow)

    const { substitudeField } = this.getLookupConfigOfField(displayField)

    const treeColumn = {
      xtype: 'treecolumn',
      dataIndex: 'text',
      text: treeColumnHeaderText,
      sortable: false,
      flex: flex,
      minWidth: treeColumnWidth,
      locked: lockable,
      editor: {
        xtype: 'textfield',
        allowBlank: false,
        allowOnlyWhitespace: false,
        maxLength: 100
      },
      renderer: function (value, record) {
        if (substitudeField) {
          const substitudeValue =
            record &&
            record.record &&
            record.record.getData() &&
            record.record.getData().dataRow &&
            record.record.getData().dataRow[substitudeField]

          return substitudeValue || value
        } else {
          return value
        }
      }
    }

    const treeSettings = {
      ref: (ref) => (this.extjsTree = ref),
      cls: 'extjsTreeGridContainer',
      viewModel: this.viewModel,
      height: isPreviewMode ? '500px' : height,
      width: isPreviewMode ? '500px' : width,
      store: this.store,
      rootVisible: false,
      columnLines: columnLines,
      fbar: footerBar,
      lockable: lockable,
      plugins: textEditingPlugin,
      bufferedRenderer: false,
      allowDeselect: nodeDeselection,
      selModel: {
        mode: multiSelection ? 'MULTI' : 'SINGLE'
      },
      viewConfig: {
        plugins: dragdropPlugin,
        getRowClass: this.getRowClass
      },
      listeners: {
        select: this.handleNodeSelected,
        deselect: this.handleNodeDeselected,
        afterRender: this.handleTreeAfterRender,
        itemkeydown: this.handleItemKeydown,
        itemclick: this.handleCheckboxSelectedWrapper,
        cellclick: this.handleCellClick
      },
      columns: [treeColumn, ...gridColumns, ...deleteColumn]
    }

    const { width: pluginWidth = 0, height: pluginHeight = 0 } = size

    return (
      <div>
        <ExtRoot pluginId={pluginId}>
          {pluginWidth && pluginHeight ? <Treepanel {...treeSettings} /> : null}
        </ExtRoot>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  pluginData: props.pluginData,
  isMaximized: props.isMaximized,
  onReady: props.onReady,
  settings: props.settings,
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  reloadExtRoot: props.reloadExtRoot,
  id: props.id,
  query: props.query,
  addRowIndexToLocalData: props.addRowIndexToLocalData,
  size: props.size,
  schema: props.schema,
  actualFilters: props.actualFilters,
  additionalArgs: props.additionalArgs,
  params: props.params,
  token: props.token,
  updateRowInLocalData: props.updateRowInLocalData,
  deleteRowInLocalData: props.deleteRowInLocalData,
  data: props.data,
  client: props.client,
  isPreviewMode: props.isPreviewMode,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(SenchaTree, selectConnectorProps)
