import React, { ReactNode } from 'react'
import { OverlayTrigger } from '..'

interface IHeaderButtonProps {
  children?: ReactNode
  extraClass?: string
  icon?: string
  isVisible?: boolean
  label: ReactNode
  onClickHandle?(): void
}

const HeaderButton = ({
  children = null,
  extraClass = '',
  icon = '',
  isVisible = true,
  label,
  onClickHandle = () => {}
}: IHeaderButtonProps) => {
  return isVisible ? (
    <OverlayTrigger tooltip={label}>
      <button className={`button ${extraClass}`} type="button" onClick={onClickHandle}>
        <i className={icon} />
        {children}
      </button>
    </OverlayTrigger>
  ) : null
}

export default HeaderButton
