export default `({
    tbar: [{
        xtype: 'container',
        bind: {
            html: '<h3><span style="color:blue">{spotRecord.MaterialDescription}</span>' + tt('Inventory List')+'</h3>'
        }
    }],
    title: tt('Satış Grafiği'),
    series: [{
            dataIndex: 'QTY',
            type: 'column',
            name: tt('Sales Quantity'),
            color: 'rgba(236,17,92,1)',
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    panel = serie.chart;
                    panel.fireEvent('clickChart', record);
                }
            }
        },
        {
            dataIndex: 'PriceNull',
            type: 'spline',
            "lineWidth": 0,
            "marker": {
                "enabled": "true",
                "states": {
                    "hover": {
                        "enabled": true
                    }
                },
                "radius": 5
            },
            "states": {
                "hover": {
                    "lineWidthPlus": 0
                }
            },
            name: tt('Sales Price'),
            yAxis: 1,
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    panel = serie.chart;
                    panel.fireEvent('clickChart', record);
                }
            }
        },
        {
            dataIndex: 'NumberOfStoreNull',
            type: 'spline',
            "lineWidth": 0,
            "marker": {
                "enabled": "true",
                "states": {
                    "hover": {
                        "enabled": true
                    }
                },
                "radius": 3
            },
            "states": {
                "hover": {
                    "lineWidthPlus": 0
                }
            },
            visible: false,
            name: tt('Number Of Store'),
            yAxis: 2,
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    panel = serie.chart;
                    panel.fireEvent('clickChart', record);
                }
            }
            // listeners: {
            // 	pointclick: this.onClickPieChart
            // }
        },
    ],
    chartConfig: {
        chart: {
            type: 'column',
            alignTicks: true,
            marginRight: 150,
            marginBottom: 140,
            zoomType: 'x',
            dataLabels: {
                enabled: true
            }
        },
        credits: {
            text: 'SOLVOYO © 2021',
            href: 'http://www.solvoyo.com',
            position: {
                align: 'right',
                y: -5 // position of credits
            },
            style: {
                fontSize: '10pt' // you can style it!
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                turboThreshold: 3000,
            },
            series: {
                // cursor: 'ns-resize',
                turboThreshold: 3000,
                point: {
                    events: {
                        drop: function () {
                            a = 1;
                        }
                    }
                },
                stickyTracking: false
            }
        },
        navigation: {
            buttonOptions: {
                align: 'left'
            }
        },
        title: {
            text: tt('Sales History'),
            style: {
                fontSize: "14px",
                fontWeight: 'bold'
            }
        },
        tooltip: {
            formatter: function (d) {
                var value=this.x
                    if(this.x.name)
                        value=this.x.name
                        
                year = value.substr(0, 4);
                month = value.substr(4, 6);
                day = value.substr(6);
                dayValue = null;
                if (day) {
                    dayValue = parseInt(day);
                }
                monthValue = parseInt(month);
                yearValue = parseInt(year);
                if (dayValue) {
                    date = year + '-' + monthValue + '-' + dayValue;
                } else {
                    date = year + '-' + monthValue;
                }
                var format = date + "<br/>";
                if(this.series.name == tt('Sales Price')){
                    format += '<span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <b> ' + this.y.toFixed(2) + '</b><br/>';
                }else{
                    format += '<span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <b> ' + this.y + '</b><br/>';
                }
                
                return format;
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    var value=this.value
                    if(this.value.name)
                        value=this.value.name
                        
                    year = value.substr(0, 4);
                    month =value.substr(4, 6);
                    day = value.substr(6);
                    dayValue = null;
                    if (day) {
                        dayValue = parseInt(day);
                    }
                    monthValue = parseInt(month);
                    yearValue = parseInt(year);
                    if (dayValue) {
                        date = year + '-' + monthValue + '-' + dayValue;
                    } else {
                        date = year + '-' + monthValue;
                    }
                    return date;
                },
                style: {
                    fontSize: '13px'
                },
                rotation: 315,
                y: 35
            },
            // plotBands: [{ // visualize the weekend
            //     from: 13.5,
            //     to: 36,
            //     color: 'rgba(68, 170, 213, .2)',
            //     id: me.plotBandId
            // }]
        },
        yAxis: [{ // Primary yAxis
                title: {
                    text: tt('Satis Adedi')
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                }
            },
            { // Primary yAxis
                opposite: true,
                min: 0,
                title: {
                    text: tt('Satis Fiyatı')
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                },
            },
            { // Primary yAxis
                opposite: true,
                title: {
                    text: tt('Mağaza')
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                }
            }
        ]
    }
});
`
