Ext.define('MDM.src.view.globalapp.Panel.Dpi.model.DPIFamily', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Global_MDM_DPIFamily',
  idProperty: 'ID',
  fields: [
    {
      name: 'ReportingPeriodID',
      type: 'int'
    },
    {
      name: 'ProductGroupID',
      type: 'string'
    },
    {
      name: 'DPIFamilyID',
      type: 'string'
    },
    {
      name: 'DPIFamily',
      type: 'string',
      defaultValue: 'Enter Family Name'
    },
    {
      name: 'DPISegmentID',
      type: 'string'
    },
    {
      name: 'isEditable',
      type: 'boolean'
    },
    {
      name: 'SU',
      type: 'float',
      defaultValue: 1
    }
  ]
})
