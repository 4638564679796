export default {
  title: 'Transportation UI V2',
  subTitle: 'Solvoyo',
  version: '2.0',
  namespace: 'tuiv2',
  description: 'Transportation ui v2',
  iconClass: 'fa-table',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Application'
}
