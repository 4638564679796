import { useState } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import { SlvyAutosuggest, SuggestionsContainer } from '@/components'
import convertDataForSearch from './helpers'
import { initialPluginOptions, containerStyle } from './constants'
import { PluginOptions, SearchPluginsProps } from './SearchPlugins.types'

const renderSuggestionsContainer = (suggestionsContainerProps: object) => (
  <SuggestionsContainer {...suggestionsContainerProps} containerStyle={containerStyle} />
)

const SearchPlugins = ({
  pluginListGroupByGroup,
  containersList,
  handleSelectedPlugin
}: SearchPluginsProps) => {
  const [pluginOptions, setPluginOptions] = useState<PluginOptions>(initialPluginOptions)

  const handleSuggestionNotFound = ({ options }: { options: PluginOptions }) => {
    setPluginOptions((prevPluginOptions) => ({
      ...prevPluginOptions,
      query: options.query,
      notFound: true
    }))
  }

  const handleQueryChange = ({ options }: { options: PluginOptions }) => {
    setPluginOptions((prevPluginOptions) => ({
      ...prevPluginOptions,
      query: options.query,
      notFound: false
    }))
  }

  const handleSuggestionSelected = ({ options }: { options: PluginOptions }) => {
    setPluginOptions((prevPluginOptions) => ({
      ...prevPluginOptions,
      query: options.query,
      selectedSuggestion: options.selectedSuggestion
    }))

    if (options.query && options.selectedSuggestion.value && options.selectedSuggestion.version) {
      handleSelectedPlugin(options.selectedSuggestion.value, options.selectedSuggestion.version)
    }
  }

  return (
    <div>
      <SlvyAutosuggest
        data={convertDataForSearch(pluginListGroupByGroup, containersList)}
        field="pluginOptions"
        placeholder="Search"
        renderSuggestionsContainer={renderSuggestionsContainer}
        size="sm"
        onQueryChange={handleQueryChange}
        onSuggestionNotFound={handleSuggestionNotFound}
        onSuggestionSelected={handleSuggestionSelected}
      />
      {pluginOptions.query && pluginOptions.notFound && (
        <Alert variant="warning">
          <Row>
            <Col md={1}>
              <i aria-hidden="true" className="fa fa-3x fa-info-circle" />
            </Col>
            <Col md={11}>
              <h3 className="error-title">
                Sorry, <strong> {pluginOptions.query} </strong> does not exist in the{' '}
                <strong> New Element </strong> lists.
              </h3>
              <h5 className="error-description">
                If you are looking for a specific plugin please enter the plugin{' '}
                <strong>name</strong> e.g. Sencha Grid or Split
              </h5>
            </Col>
          </Row>
        </Alert>
      )}
    </div>
  )
}

export default SearchPlugins
