export default `({
    title: 'Accordion Layout',
    layout: 'accordion',
    defaults: {
        bodyPadding: 1
    },
    items: [{
        title: 'Store Clustering',
        flex: 3,
        border: true,
        listeners: {
            render: function (grid) {
                var me = this;
                //var spotId = grid.ownerCt.ownerCt.ownerCt.spotId
                var spotId = this.up('window').spotId,
                    store = grid.getStore(),
                    proxy = store.getProxy();
                // store.on('load', function (store) {
                //     OrderRecommendationCurrent = store.sum('OrderRecommendationCurrent');
                //     OrderRecommendationParent = store.sum('OrderRecommendationParent');
                //     viewModel = this.up('uxWindow').viewModel
                //     viewModel.set('OrderRecommendationCurrent', OrderRecommendationCurrent);
                //     viewModel.set('OrderRecommendationParent', OrderRecommendationParent)
                // }, me)
                proxy.setExtraParam('spotId', spotId);
                store.load();
            }
        },
        store: ({
            autoLoad: false,
            autoSync: true,
            fields: [{
                    name: 'ID',
                    type: 'int'
                },
                {
                    name: 'SpotID',
                    type: 'int'
                },
                {
                    name: 'OrgCode',
                    type: 'string'
                },
                {
                    name: 'Category',
                    type: 'string'
                },
                {
                    name: 'MinQuantity',
                    type: 'int'
                },
                {
                    name: 'MaxQuantity',
                    type: 'int'
                },
                {
                    name: 'FixQuantity',
                    type: 'int'
                },
                {
                    name: 'NumberofStores',
                    type: 'int'
                },
                {
                    name: 'OrderRecommendation',
                    type: 'number'
                }
            ],
            proxy: {
                type: 'ajax',
                actionMethods: {
                    create: 'POST',
                    read: 'POST',
                    update: 'POST',
                    destroy: 'POST'
                },
                api: {
                    create: Spui.Global.getApiUrl() + 'spui/ClusterDist/create',
                    read: Spui.Global.getApiUrl() + 'spui/ClusterDist/read',
                    update: Spui.Global.getApiUrl() + 'spui/ClusterDist/update',
                    destroy: Spui.Global.getApiUrl() + 'spui/ClusterDist/destroy',
                },
                writer: {
                    rootProperty: 'data',
                    allowSingle: false,
                    successProperty: 'success',
                    messageProperty: 'errors',
                    allDataOptions: {
                        associated: false
                    },
                    writeAllFields: true
                },
            },
            autoLoad: false,
        }),
        plugins: [{
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
                // beforeedit: function (editor, context) {
                //     rec = context.record;
                //     return rec.get('IsEditable');
                // },
                // edit: function (editor, context, eOpts) {
                //     var me = this;
                //     var record = context.record;
                //     if (context.originalValue * 1 == context.value * 1) {
                //         return;
                //     }
                //     grid = context.grid.ownerCt
                //     grid.setLoading('Saving');
                //     record.save({
                //         params: {
                //             updateAction: 'SubValueChange'
                //         },
                //         failure: function (record, operation) {},
                //         success: function (record, operation) {
                //             grid.setLoading(false)
                //             var c = grid.ownerCt.ownerCt.getController(),
                //                 store = grid.getStore();
                //             grid.setLoading(false);
                //             store.load();
                //         },
                //         callback: function (record, operation, success) {
                //             // do something whether the save succeeded or failed
                //         }
                //     });
                // },
                scope: 'this'
            }
        }],
        xtype: 'grid',
        columnLines: true,
        columns: [
            // {
            //     text: 'ID',
            //     dataIndex: 'ID',
            //     width: 100,
            //     format: '000.00',
            //     align: 'right',
            //     xtype: 'numbercolumn'
            // },
            // {
            //     text: 'SpotID',
            //     dataIndex: 'SpotID',
            //     width: 100,
            //     format: '000.00',
            //     align: 'right',
            //     xtype: 'numbercolumn'
            // },
            // {
            //     text: 'OrgCode',
            //     dataIndex: 'OrgCode',
            //     width: 200
            // },
            {
                text: tt('Category'),
                dataIndex: 'Category',
                flex: 1
            },
            {
                text: tt('FixQuantity'),
                dataIndex: 'FixQuantity',
                width: 150,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn',
                tdCls: 'blue',
                field: {
                    xtype: 'numberfield',
                }
            },
            {
                text: tt('MinQuantity'),
                dataIndex: 'MinQuantity',
                width: 150,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn',
                tdCls: 'blue',
                field: {
                    xtype: 'numberfield',
                }
            },
            {
                text: tt('MaxQuantity'),
                dataIndex: 'MaxQuantity',
                width: 150,
                format: '000.00',
                align: 'right',
                xtype: 'numbercolumn',
                tdCls: 'blue',
                field: {
                    xtype: 'numberfield',
                }
            },
            {
                text: tt('NumberofStores'),
                dataIndex: 'NumberofStores',
                width: 150,
                format: '000.00',
                align: 'right',
            },
            {
                text: tt('OrderRecommendation'),
                dataIndex: 'OrderRecommendation',
                width: 150,
                format: '000.00',
                align: 'right',
            }
        ],
    }, {
        title: tt('Change by clustered'),
        tools: [{
                iconCls: 'x-fa fa-thumb-tack'
            },
            {
                iconCls: 'x-fa fa-thumb-tack fa-rotate-90'
            },
            {
                iconCls: 'x-fa fa-gear'
            }
        ],
        html: 'Empty'
    }, {
        title: 'Accordion Item 3',
        tools: [{
                glyph: 'xf08d@FontAwesome'
            },
            {
                glyph: 'xf08d@FontAwesome',
                cls: 'fa-rotate-90'
            },
            {
                glyph: 'xf013@FontAwesome'
            }
        ],
        html: 'Empty'
    }, {
        title: 'Accordion Item 4',
        tools: [{
                iconCls: 'x-fa fa-thumb-tack'
            },
            {
                iconCls: 'x-fa fa-thumb-tack fa-rotate-90'
            },
            {
                iconCls: 'x-fa fa-gear'
            }
        ],
        html: 'Empty'
    }, {
        title: 'Accordion Item 5',
        tools: [{
                glyph: 'xf08d@FontAwesome'
            },
            {
                glyph: 'xf08d@FontAwesome',
                cls: 'fa-rotate-90'
            },
            {
                glyph: 'xf013@FontAwesome'
            }
        ],
        html: 'Empty'
    }]
})
`
