Ext.define('MDM.Btl.upload.Prompt', {
  extend: 'Ext.window.Window',
  width: 400,
  height: 200,
  bodyPadding: 5,
  viewModel: {},
  resize: false,
  layout: {
    type: 'vbox',
    align: 'stretch'
  },
  baseIconCls: Ext.baseCSSPrefix + 'message-box-icon',
  title: 'Select Sheet',
  defaultListenerScope: true,
  modal: true,
  closable: false,
  comboData: [],
  message: 'mesage',
  initComponent: function () {
    var me = this
    me.msg = new Ext.Component({
      cls: me.baseCls + '-text',
      html: me.message,
      margin: '5 5 20,5'
    })
    me.combo = Ext.create('Ext.form.field.ComboBox', {
      fieldLabel: 'Choose Sheet',
      store: me.comboData,
      queryMode: 'local',
      value: me.comboData[0],
      margin: '5 5 20,5'
    })
    me.items = [me.msg, me.combo]
    me.bottomTb = new Ext.toolbar.Toolbar({
      ui: 'footer',
      dock: 'bottom',
      focusableContainer: false,
      ariaRole: null,
      layout: {
        pack: 'center'
      },
      items: [
        {
          text: 'OK',
          width: 100,
          handler: 'onOKTap'
        },
        {
          text: 'Cancel',
          width: 100,
          handler: 'onCancelTap'
        }
      ]
    })
    me.dockedItems = [me.bottomTb]
    this.callParent()
  },
  onCancelTap: function () {
    this.close()
  },
  onOKTap: function () {
    this.fireEvent('select', this.combo.getValue())
    this.close()
  }
})
