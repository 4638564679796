import React, { Component, createRef } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import VirtualList from 'react-tiny-virtual-list'
import { slvyToast } from '../../../../components'
import GridSingleDetail from './grid-single-detail'
import { GridHeader } from '../GridCommon/index.ts'
import { MESSAGES } from '../../messages'
import './index.scss'
import './loading.scss'
import OutsideClickHandler from '../../OutsideClickHandler'

export default class GridSingleDetailContainer extends Component {
  constructor(props) {
    super(props)

    this.rowHeight = 50
    this.pivotInterval = null

    this.state = {
      data: {
        column: [],
        row: []
      },
      selectedRowId: {
        cell: 0,
        row: 0
      },
      SelectedHierarchy: '',
      pendingCount: props.pendingCount
    }

    this.uiGridCtnRef = createRef()

    this.click = this.click.bind(this)
    this.clickNode = this.clickNode.bind(this)
    this.setSelectedRowId = this.setSelectedRowId.bind(this)
    this.update = this.update.bind(this)
    this.rowClicked = this.rowClicked.bind(this)
  }

  componentDidMount() {
    const { updateMode = 0 } = this.props
    if (updateMode === 1) {
      // isSingle and isRowUpdate
      this.props.saveHeaderSettings({
        payload: { updateMode: 0 }
      })
      slvyToast.warning({
        message: MESSAGES.row_update_is_not_available_in_the_detail_and_the_single_detail_view,
        options: { containerId: 'dp' }
      })
      return
    }

    this.uiGridCtnRef.current.addEventListener('click', this.clickNode)

    this.pivotInterval = setInterval(() => this.setHeight(), 1500)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pendingCount !== this.props.pendingCount) {
      this.setState({ pendingCount: nextProps.pendingCount })
    }
  }

  componentWillUnmount() {
    clearInterval(this.pivotInterval)
    this.uiGridCtnRef.current.removeEventListener('click', this.clickNode)
  }

  setHeight() {
    const { pluginId } = this.props

    const virtualList = document.querySelector(`#dp-${pluginId} .single-detail-virtual-list`)
    const pivotModal = document.querySelector(`#dp-${pluginId} .pivot-single-detail-view`)
    const pivotHeader = document.querySelector(`#dp-${pluginId} .-pm-header.-single-detail-header`)

    if (virtualList && pivotModal && pivotHeader) {
      const height = pivotModal.clientHeight - pivotHeader.clientHeight
      virtualList.style.height = `${height}px`
    }
  }

  clickNode(event, isOutsideClicked = false) {
    const { updateMode = 0, viewMode = 0 } = this.props
    if (viewMode === 2 && (updateMode === 0 || updateMode === 1)) {
      if (event.target.tagName.toUpperCase() !== 'INPUT' || isOutsideClicked) {
        this.setSelectedRowId(0)
      }
    }
  }

  update(params) {
    const { updateExternalCallback = () => {}, updateCallback = () => {} } = this.props
    const { ExternalEdit = false } = params
    ExternalEdit ? updateExternalCallback(params) : updateCallback(params)
  }

  setSelectedRowId(cell, row) {
    this.setState({ selectedRowId: { cell, row } })
  }

  click(SelectedHierarchy, IsParent) {
    const {
      pivotCallback = () => {},
      pivotState: { LeftAxisDraggable: LeftFields = [] },
      leftHierarchy: LeftHierarchy = [],
      pivotField = ''
    } = this.props

    const isLastHierarchy = LeftFields.length - 1 === LeftHierarchy.length

    if (IsParent) {
      this.setState({ SelectedHierarchy })
      if (LeftHierarchy.length > 1) {
        LeftHierarchy.pop()
      }
    } else if (!isLastHierarchy) {
      LeftHierarchy.push(SelectedHierarchy)
    }

    if (!isLastHierarchy || IsParent) {
      pivotCallback({
        LeftKeys: LeftHierarchy,
        MetricName: pivotField
      })
    }
  }

  rowClicked(LeftHierarchy, event) {
    event.stopPropagation()

    const {
      pivotField: { Name: PivotField = null } = {},
      multiColumnEnabled = false,
      selectedMetricFields = []
    } = this.props

    const PivotFields = multiColumnEnabled
      ? selectedMetricFields
          .map((item) => item.Name)
          .filter((item) => item)
          .toString()
      : [PivotField].toString()

    this.props.rowClicked({ LeftHierarchy, PivotField, PivotFields })
  }

  render() {
    let {
      props: {
        buttonFormat,
        culture,
        isScenarioEditable = false,
        leftHierarchy = [],
        multiColumnEnabled = false,
        selectedMetricFields = [],
        setHierarchy,
        singleDetailBreadcrumbs = [],
        updateMode = 0,
        viewMode = 0,
        nodeActionCallback = () => {},
        pivotData: { Result: { Metrics = [], DetailList = [], Headers = [] } = {} } = {},
        statePicker: { allowedToUpdate = true } = {},
        pivotState: { ValuesDraggable = [] },
        settings = {},
        settings: {
          isRowClickedEventOn,
          size: { height },
          messages: { summary_text = '' } = {}
        }
      },
      state: { selectedRowId, SelectedHierarchy, pendingCount }
    } = this

    const newMetrics = _.cloneDeep(Metrics)
    const newDetailList = _.cloneDeep(DetailList)

    // this is needed/enabled only if multiColumnEnable is off
    const [{ Name: MetricField = null } = {}] = newMetrics || []

    // TODO: Because left hierarchy belong with current view
    SelectedHierarchy = _.isEmpty(SelectedHierarchy) ? _.last(leftHierarchy) : SelectedHierarchy

    const calculatedLeftHierarchy = !_.isEmpty(SelectedHierarchy)
      ? [..._.slice(_.cloneDeep(leftHierarchy), 0, leftHierarchy.length - 1), SelectedHierarchy]
      : leftHierarchy

    Headers = _.slice(Headers, 1, Headers.length)

    const gridData = newDetailList

    const isMultipleMetrics = newMetrics.length > 1

    const gridHeaderTpl = (
      <GridHeader
        hasSummary
        headers={Headers.map((item) => ({ Text: item }))}
        summaryText={summary_text}
      />
    )

    const gridDataLength = gridData.length

    return (
      <OutsideClickHandler onOutsideClick={(event) => this.clickNode(event, true)}>
        <div
          className={`grid-detail-common-container -single-mode has-virtual-list ${
            isMultipleMetrics ? '-cell-wide' : ''
          }`}
        >
          <div ref={this.uiGridCtnRef} className="slvy-ui-grid-ctn">
            <div className="slvy-ui-table">
              {gridData.length ? (
                <div className="slvy-ui -grid-body">
                  <div className="-angle-container">
                    <VirtualList
                      className={cx('single-detail-virtual-list', {
                        'loading-bg': gridDataLength > 10
                      })}
                      height={height}
                      itemCount={gridDataLength + 1}
                      itemSize={this.rowHeight}
                      renderItem={({ index, style }) => {
                        const commonStyle = { ...style, width: 'fit-content', minWidth: '100%' }

                        const isFirst = index === 0
                        if (isFirst) {
                          return (
                            <div key={index} style={{ ...commonStyle, zIndex: 9999 }}>
                              {gridHeaderTpl}
                            </div>
                          )
                        }

                        const item = gridData[index - 1]

                        return (
                          <GridSingleDetail
                            key={index}
                            allowedToUpdate={allowedToUpdate}
                            buttonFormat={buttonFormat}
                            click={this.click}
                            culture={culture}
                            data={item}
                            getLeftHierarchy={leftHierarchy}
                            index={index}
                            isRowClickedEventOn={isRowClickedEventOn}
                            isScenarioEditable={isScenarioEditable}
                            leftHierarchy={calculatedLeftHierarchy}
                            metricField={MetricField}
                            multiColumnEnabled={multiColumnEnabled}
                            newMetrics={newMetrics}
                            nodeActionCallback={nodeActionCallback}
                            pendingCount={pendingCount}
                            selectedMetricFields={selectedMetricFields}
                            selectedRowId={selectedRowId}
                            setHierarchy={setHierarchy}
                            setSelectedRowId={this.setSelectedRowId}
                            settings={settings}
                            singleDetailBreadcrumbs={singleDetailBreadcrumbs}
                            style={commonStyle}
                            topFields={Headers}
                            updateMode={updateMode}
                            values={ValuesDraggable}
                            viewMode={viewMode}
                            onRowClicked={this.rowClicked}
                            onUpdate={this.update}
                          />
                        )
                      }}
                      scrollDirection="vertical"
                      stickyIndices={[0]}
                      width="100%"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
}
