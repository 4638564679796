import { SlvySelectStyles } from '..'
import { ISlvyFormSelectProps } from './SlvyFormSelect.types'

const getSelectStyle = (size: ISlvyFormSelectProps['size']) => {
  switch (size) {
    case 'sm':
      return SlvySelectStyles.small
    case 'auto':
      return SlvySelectStyles.auto
    default:
      return {}
  }
}

export default getSelectStyle
