import { IRowNode } from 'ag-grid-community'
import { getCellStyle, getMergedStyle } from '../index'
import {
  cellDisplayRules as defaultCellDisplayRules,
  customConfigs as defaultCustomConfigs,
  cellRendererParams as defaultCellRendererParams,
  validCellRule,
  rowDisplayRules as defaultRowDisplayRules,
  validRowRule
} from '../../__tests__/mocks'
import { validCellParamsData } from './mocks'

function getCellDisplayRules() {
  return defaultCellDisplayRules
}

function getRowDisplayRules() {
  return defaultRowDisplayRules
}

function getCustomConfigs() {
  return defaultCustomConfigs
}

function getParams() {
  return defaultCellRendererParams
}

describe('AgGrid:Helpers:Cell:getCellStyle', () => {
  let rowDisplayRules = getRowDisplayRules()
  let cellDisplayRules = getCellDisplayRules()
  let customConfigs = getCustomConfigs()
  let params = getParams()

  beforeEach(() => {
    rowDisplayRules = getRowDisplayRules()
    cellDisplayRules = getCellDisplayRules()
    customConfigs = getCustomConfigs()
    params = getParams()
    params.node = { footer: false } as IRowNode
  })

  it('should return an object with a property named cellStyle', () => {
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules,
      rowDisplayRules,
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    expect(result).toHaveProperty('cellStyle')
  })
  it('should return an object that has cellStyle as function', () => {
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules,
      rowDisplayRules,
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    expect(typeof result.cellStyle).toBe('function')
  })
  it('should cellStyle function return customConfig.cellStyle if there are no valid cell or row rule', () => {
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: [],
      rowDisplayRules: [],
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    const style = result.cellStyle(params)
    expect(style).toEqual({ ...customConfigs.cellStyle, cursor: 'text' })
  })
  it('should cellStyle function return cellDisplayRules style with customConfigs.textAlign if there is a valid cell rule', () => {
    params.data = validCellParamsData
    params.value = validCellRule.value
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: [validCellRule],
      rowDisplayRules: [],
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    const style = result.cellStyle(params)
    expect(style).toEqual(
      getMergedStyle({
        rule: validCellRule,
        textAlign: customConfigs.cellStyle.textAlign,
        cursor: 'text'
      })
    )
  })
  it('should cellStyle function return rowDisplayRule style with customConfigs.textAlign if there is a valid row rule', () => {
    params.value = validRowRule.value
    params.data.col1 = validRowRule.value
    params.node = {
      footer: false,
      data: params.data,
      allLeafChildren: [{ data: params.data }],
      group: false
    } as IRowNode
    const newCellDisplayRules = cellDisplayRules.map((item) => {
      return {
        ...item,
        value: `${validRowRule.value}2`
      }
    })
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: newCellDisplayRules,
      rowDisplayRules: [validRowRule],
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    const style = result.cellStyle(params)
    expect(style).toEqual(
      getMergedStyle({
        rule: validRowRule,
        textAlign: customConfigs.cellStyle.textAlign,
        cursor: 'text'
      })
    )
  })
  it(
    'should cellStyle function return an object that has textAlign prop and has value as' +
      ' customConfigs.cellStyle.textAlign if current node is footer and given cellDisplayRules empty',
    () => {
      params.node.footer = true
      const result = getCellStyle({
        customConfigs,
        cellDisplayRules: [],
        rowDisplayRules: [],
        getCellColorsFromData: false,
        enableCellTextSelection: true
      })
      const style = result.cellStyle(params)
      expect(style).toEqual({ textAlign: customConfigs.cellStyle.textAlign })
    }
  )
  it(
    'should cellStyle function return an object that has textAlign prop and has value as' +
      ' customConfigs.cellStyle.textAlign if current node is footer and found validCellRule.applyRuleSummary falsy',
    () => {
      params.data.col1 = validCellRule.value
      params.value = validCellRule.value
      params.node.footer = true
      const result = getCellStyle({
        customConfigs,
        cellDisplayRules: [validCellRule],
        rowDisplayRules: [],
        getCellColorsFromData: false,
        enableCellTextSelection: true
      })
      const style = result.cellStyle(params)
      expect(style).toEqual({ textAlign: customConfigs.cellStyle.textAlign })
    }
  )
  it(
    'should cellStyle function return an object that has textAlign prop and has value as' +
      ' customConfigs.cellStyle.textAlign if current node is footer and given cellDisplayRules full' +
      ' but there is no validCellRule',
    () => {
      params.node.footer = true
      const newCellDisplayRules = cellDisplayRules.map((item) => {
        return {
          ...item,
          value: `${item.value}2`,
          ruleFieldName: `${Math.random()}`
        }
      })
      const result = getCellStyle({
        customConfigs,
        cellDisplayRules: newCellDisplayRules,
        rowDisplayRules: [],
        getCellColorsFromData: false,
        enableCellTextSelection: true
      })
      const style = result.cellStyle(params)
      expect(style).toEqual({ textAlign: customConfigs.cellStyle.textAlign })
    }
  )
  it('should cellStyle function return customConfig.cellStyle if there are no valid cell or row rule and the cursor is not set to "text"', () => {
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: [],
      rowDisplayRules: [],
      getCellColorsFromData: false,
      enableCellTextSelection: false
    })
    const style = result.cellStyle(params)
    expect(style).toEqual(customConfigs.cellStyle)
  })
  it('should cellStyle function return cellDisplayRules style with customConfigs.textAlign if there is a valid cell rule and the cursor is not set to "text"', () => {
    params.data = validCellParamsData
    params.value = validCellRule.value
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: [validCellRule],
      rowDisplayRules: [],
      getCellColorsFromData: false,
      enableCellTextSelection: false
    })
    const style = result.cellStyle(params)
    expect(style).toEqual(
      getMergedStyle({
        rule: validCellRule,
        textAlign: customConfigs.cellStyle.textAlign,
        cursor: ''
      })
    )
  })
  it('should cellStyle function return rowDisplayRule style with customConfigs.textAlign if there is a valid row rule and the cursor is not set to "text"', () => {
    params.value = validRowRule.value
    params.data.col1 = validRowRule.value
    params.node = {
      footer: false,
      data: params.data,
      allLeafChildren: [{ data: params.data }],
      group: false
    } as IRowNode
    const newCellDisplayRules = cellDisplayRules.map((item) => {
      return {
        ...item,
        value: `${validRowRule.value}2`
      }
    })
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: newCellDisplayRules,
      rowDisplayRules: [validRowRule],
      getCellColorsFromData: false,
      enableCellTextSelection: false
    })
    const style = result.cellStyle(params)
    expect(style).toEqual(
      getMergedStyle({
        rule: validRowRule,
        textAlign: customConfigs.cellStyle.textAlign,
        cursor: ''
      })
    )
  })
  it('should cellStyle function return customConfig.cellStyle if there are no valid cell or row rule and the cursor is set to "text"', () => {
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: [],
      rowDisplayRules: [],
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    const style = result.cellStyle(params)
    expect(style).toEqual({ ...customConfigs.cellStyle, cursor: 'text' })
  })
  it('should cellStyle function return cellDisplayRules style with customConfigs.textAlign if there is a valid cell rule and the cursor is set to "text"', () => {
    params.data = validCellParamsData
    params.value = validCellRule.value
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: [validCellRule],
      rowDisplayRules: [],
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    const style = result.cellStyle(params)
    expect(style).toEqual(
      getMergedStyle({
        rule: validCellRule,
        textAlign: customConfigs.cellStyle.textAlign,
        cursor: 'text'
      })
    )
  })
  it('should cellStyle function return rowDisplayRule style with customConfigs.textAlign if there is a valid row rule and the cursor is set to "text"', () => {
    params.value = validRowRule.value
    params.data.col1 = validRowRule.value
    params.node = {
      footer: false,
      data: params.data,
      allLeafChildren: [{ data: params.data }],
      group: false
    } as IRowNode
    const newCellDisplayRules = cellDisplayRules.map((item) => {
      return {
        ...item,
        value: `${validRowRule.value}2`
      }
    })
    const result = getCellStyle({
      customConfigs,
      cellDisplayRules: newCellDisplayRules,
      rowDisplayRules: [validRowRule],
      getCellColorsFromData: false,
      enableCellTextSelection: true
    })
    const style = result.cellStyle(params)
    expect(style).toEqual(
      getMergedStyle({
        rule: validRowRule,
        textAlign: customConfigs.cellStyle.textAlign,
        cursor: 'text'
      })
    )
  })
})
