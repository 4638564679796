import { useEffect } from 'react'
import { isArray } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { tuiSlice } from '../../../store/slices'

function useOpenLoadCard() {
  const [{ registerMethod }] = useReduxState(tuiSlice.selectSlice)

  const handleOpenLoadCard = (params) => {
    const { LoadNames: loadNames = [] } = params
    const loadNamesToOpen = isArray(loadNames) ? loadNames : [loadNames]

    // TODO: add openLoadCard function
    // The openLoadCard function allows us to open cards on the map according to the selected loadNames.
    // openLoadCard(loadNamesToOpen)
  }

  useEffect(() => {
    registerMethod({
      args: [{ name: 'LoadNames', type: PluginTypes.arrayOf(PluginTypes.string) }],
      key: 'openLoadCard',
      fn: handleOpenLoadCard
    })
  }, [registerMethod])
}

export default useOpenLoadCard
