import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import numeral from 'numeral'
import { objectToArray, getDateDiff } from '../../utils'
import settings from '../../utils/settings'
import { slvyToast } from '../../../../components'
import ActiveStores from '../ActiveStores'
import OldViewComponent from './OldViewComponent'
import SimilarProducts from '../SimilarProducts'
import { setLoaderReducer } from '../../store/slices/appSlice'
import {
  getOptions,
  setOptionUpdateList,
  setSelectType,
  setShowActiveStores,
  unSelectOption
} from '../../store/slices/options'
import { toggleSimilarOptions } from '../../store/slices/similarOptions'

const selectFromState = (state) => ({
  formattingSettings: state.plugin.formattingSettings,
  index: state.options.index,
  isLoading: state.options.isLoading,
  isShownSimilarProducts: state.similarOptions.isShown,
  points: state.options.points,
  rootOptions: state.options.rootOptions,
  selectedSegmentId: state.options.selectedSegmentId,
  selectType: state.options.selectType,
  show: state.options.show,
  showActiveStores: state.options.showActiveStores,
  optionUpdateList: state.options.optionUpdateList
})

function OldView(props) {
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const {
    formattingSettings,
    index,
    isLoading,
    isShownSimilarProducts,
    points,
    rootOptions,
    optionUpdateList,
    selectedSegmentId,
    selectType,
    show,
    showActiveStores
  } = useSelector(selectFromState)

  const {
    culture,
    environment,
    onTestProductsToggle = () => {},
    pluginId = '',
    similarProductsEventRef,
    similarProductsColumns = [],
    similarProductsHeaderButtons = [],
    token
  } = props

  const { AssortmentId, Options = [], Stores = [] } = rootOptions
  const { decimal = '0,0.00' } = formattingSettings
  const { DecimalFormat } = settings
  const [requestType, setRequestType] = useState('')
  const [optionItem, setOptionItem] = useState({})
  const [assortmentOptions, setAssortmentOptions] = useState(Options)
  const [optionsLoading, setOptionsLoading] = useState(false)

  useEffect(() => {
    // TODO check exceptions
    if (!_.isEmpty(rootOptions) && !optionsLoading) {
      setAssortmentOptions(rootOptions.Options)
    }
  }, [rootOptions])

  useEffect(() => {}, [points, selectType, showActiveStores])

  useEffect(() => {
    if (!_.isEmpty(rootOptions) && assortmentOptions && assortmentOptions.length && index > 0) {
      setOptionItem(assortmentOptions[index - 1])
    }
  }, [show, index])

  useEffect(() => {
    if (!_.isEmpty(optionItem)) {
      onSetSelectType(optionItem.SegmentOptions)
    }
  }, [optionItem])

  useEffect(() => {
    if (requestType === 'DeleteTestOption') {
      if (isLoading) {
        dispatch(setLoaderReducer({ isShown: true, messages: 'Delete Test Product' }))
      } else {
        if (isEmpty(rootOptions)) {
          slvyToast.error({ message: errorMessage, title: 'Delete Test Product' })
        } else {
          slvyToast.success({
            message: 'Test Product deleted successfully!',
            title: 'Delete Test Product'
          })

          dispatch(unSelectOption())
        }
        dispatch(setLoaderReducer({ isShown: false }))
        setRequestType('')
      }
    } else if (requestType === 'UpdateOption') {
      if (isLoading) {
        dispatch(setLoaderReducer({ isShown: true, messages: 'Update Option' }))
      } else {
        if (isEmpty(rootOptions)) {
          slvyToast.error({ message: 'There is an error!', title: 'Update Error!' })
        } else if (optionsLoading) {
          slvyToast.success({
            message: `${selectType} All Segments completed successfully!`,
            title: `${selectType} All Segments`
          })

          dispatch(unSelectOption())
          setOptionsLoading(false)
        }
        dispatch(setLoaderReducer({ isShown: false }))
        setRequestType('')
      }
    } else if (requestType === 'UpdateActiveStores') {
      if (isEmpty(rootOptions)) {
        slvyToast.error({ message: 'There is an error!', title: 'Update Error!' })
      } else if (optionsLoading) {
        slvyToast.success({
          message: `Active Stores updated successfully!`,
          title: `Active Stores`
        })

        dispatch(unSelectOption())
        setOptionsLoading(false)
      }
      dispatch(setLoaderReducer({ isShown: false }))
      setRequestType('')
    }
  }, [isLoading, rootOptions])

  const handleCloseActiveStoresModal = () => {
    dispatch(setShowActiveStores(false))
  }

  const handleApplyActiveStores = (OptionStr) => {
    optionUpdateList.forEach(({ SegmentId, Enabled }) => {
      OptionStr.SegmentOptions[SegmentId].Enabled = Enabled
    })

    setRequestType('UpdateActiveStores')
    onUpdateOption({ AssortmentId, OptionStr })

    const filteredOptionUpdateList = optionUpdateList.filter(
      ({ optionId }) => optionId !== OptionStr.Id
    )
    dispatch(setOptionUpdateList(filteredOptionUpdateList))

    handleCloseActiveStoresModal()
  }

  const handleTestProductsToggle = (isShowTestProducts) => {
    onTestProductsToggle(isShowTestProducts, 'Edit')
  }

  const getSegmentCount = (segmentOptions = {}) => {
    const _SegmentOptions = objectToArray(segmentOptions)
    const enabledSegmentOptions = [..._SegmentOptions].filter(({ Enabled = true }) => Enabled)
    return {
      totalCount: _SegmentOptions.length,
      enabledCount: enabledSegmentOptions.length,
      disabledCount: _SegmentOptions.length - enabledSegmentOptions.length
    }
  }

  const onSetSelectType = (segmentOptions) => {
    const { totalCount = 0, enabledCount = 0, disabledCount = 0 } = getSegmentCount(segmentOptions)
    const selection =
      (enabledCount >= disabledCount || disabledCount === totalCount) && enabledCount !== totalCount
        ? 'Select'
        : 'Unselect'
    dispatch(setSelectType(selection))
    return selection
  }

  const markSegmentOptions = (val, SegmentOptions) => {
    const newSegmentOptions = _.transform(
      _.cloneDeep(SegmentOptions),
      (result, segmentOption, key) => {
        if (val !== segmentOption.Enabled) {
          segmentOption.Enabled = val
          result[key] = segmentOption
        }
      },
      {}
    )

    return newSegmentOptions
  }

  const onUpdateOption = (params, $callback = () => {}) => {
    const {
      OptionStr,
      OptionStr: {
        BasePrice = null,
        EndDate = null,
        StartDate = null,
        Expenditure = null,
        PrePackSize = null,
        Sellthrough = null,
        SegmentOptions = {}
      } = {}
    } = params

    const BasePriceFormatted = `${numeral(BasePrice).format(decimal)}`
    const DaysDiff = getDateDiff(StartDate, EndDate, 'days')
    const _SegmentOptions = _.cloneDeep(SegmentOptions)
    const newSegmentOptions = _.transform(
      _SegmentOptions,
      (result, segmentOption, key) => {
        const { EndDate: sEndDate = null, StartDate: sStartDate = null } = segmentOption
        segmentOption.DaysDiff = getDateDiff(sStartDate, sEndDate, 'days')
        result[key] = segmentOption
      },
      {}
    )

    const newOptionStr = {
      ...OptionStr,
      BasePriceFormatted,
      DaysDiff,
      Expenditure: numeral(numeral(Expenditure).format(DecimalFormat)).value(),
      PrePackSize: numeral(numeral(PrePackSize).format(DecimalFormat)).value(),
      SegmentOptions: newSegmentOptions,
      Sellthrough: numeral(numeral(Sellthrough).format(DecimalFormat)).value()
    }

    const payload = {
      pluginId,
      method: 'UpdateOption',
      requestMethod: 'post',
      body: {
        ...params,
        OptionStr: newOptionStr
      }
    }
    setOptionsLoading(true)
    dispatch(getOptions({ ...payload }))
  }

  const onSelectAllSegments = (selectedProduct, selectType) => {
    const { SegmentOptions: _SegmentOptions = {} } = selectedProduct

    const filteredOptionUpdateList = optionUpdateList.filter(
      ({ optionId }) => selectedProduct.Id !== optionId
    )

    const SegmentOptions = markSegmentOptions(selectType === 'Select', _SegmentOptions)

    const selectedOptionUpdateList = Object.values(SegmentOptions).map(
      ({ OptionId, SegmentId, Enabled }) => ({ optionId: OptionId, SegmentId, Enabled })
    )

    const updatedOptionUpdateList = filteredOptionUpdateList.concat(selectedOptionUpdateList)

    dispatch(setOptionUpdateList(updatedOptionUpdateList))
  }

  const onDeleteTestOption = (selectedProduct) => {
    const { BasePrice = null, EndDate = null, StartDate = null } = selectedProduct
    let OptionStr = selectedProduct
    const BasePriceFormatted = `${numeral(BasePrice).format(decimal)}`
    const DaysDiff = getDateDiff(StartDate, EndDate, 'days')

    OptionStr = {
      ...OptionStr,
      BasePriceFormatted,
      DaysDiff
    }
    setRequestType('DeleteTestOption')
    const payload = {
      pluginId,
      method: 'DeleteTestOption',
      requestMethod: 'post',
      body: {
        AssortmentId,
        OptionStr
      }
    }

    dispatch(getOptions({ ...payload }))
  }

  const similarViewHandler = (isShown) => {
    dispatch(toggleSimilarOptions(isShown))
  }

  return (
    <div className="old-view overflow-auto h-100">
      {isShownSimilarProducts ? (
        <div className="similar-products-wrp">
          <SimilarProducts
            gridColumns={similarProductsColumns}
            pluginId={pluginId}
            similarProductsEventRef={similarProductsEventRef}
            similarProductsHeaderButtons={similarProductsHeaderButtons}
            similarViewHandler={similarViewHandler}
          />
        </div>
      ) : null}
      {showActiveStores ? (
        <ActiveStores
          handleCloseModal={handleCloseActiveStoresModal}
          handleOnSave={handleApplyActiveStores}
          option={Options[index - 1]}
          pluginId={pluginId}
          segmentIndex={selectedSegmentId}
          show={showActiveStores}
          stores={Stores}
        />
      ) : null}
      <OldViewComponent
        containerRef={containerRef}
        culture={culture}
        environment={environment}
        pluginId={pluginId}
        show={show}
        token={token}
        onDeleteTestOption={onDeleteTestOption}
        onSelectAllSegments={onSelectAllSegments}
        onSetSelectType={onSetSelectType}
        onTestProductsToggle={handleTestProductsToggle}
        onUpdateOption={onUpdateOption}
      />
    </div>
  )
}

export default OldView
