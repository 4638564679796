import { memo } from 'react'
import Icon from './SearchIconTooltip'
import { IVariableProps } from './Variable.types'
import './index.scss'

const Variable = ({
  count,
  variable,
  isSelected,
  onVariableRemove,
  onVariableAdd
}: IVariableProps) => {
  const icon = isSelected ? 'minus' : 'plus'
  const onClickHandler = isSelected ? onVariableRemove : onVariableAdd

  return (
    <li className="variable-item d-flex align-items-center gap-2 bg-solvoyo-widget border-0 rounded overflow-hidden mb-2">
      <Icon icon={icon} variable={variable} onClickHandler={onClickHandler} />
      <span className="text-white text-break text-truncate fs-sm" title={variable}>
        {variable}
      </span>
      <span className="variable-item-dark rounded-end text-white p-2 bg-solvoyo-widget-action fs-sm itemCount h-100 fw-bold ms-auto">
        {count}
      </span>
    </li>
  )
}

export default memo(Variable)
