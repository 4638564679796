import React, { Component } from 'react'
import _ from 'lodash'
import Checkbox from './checkbox'
import Textbox from './textbox'
import Dropdown from './dropdown'
import Label from './label'
import TextArea from './textArea'

class Section extends Component {
  constructor(props) {
    super(props)
    this.checkType = this.checkType.bind(this)
  }

  checkType(item) {
    const {
      sectionLeftColumn,
      sectionRightColumn,
      section: { backgroundColor },
      formBackgroundColor
    } = this.props
    switch (item.type) {
      case 'Checkbox': {
        return (
          <Checkbox
            backgroundColor={backgroundColor}
            formBackgroundColor={formBackgroundColor}
            getValidationState={this.props.getValidationState}
            item={item}
            sectionLeftColumn={sectionLeftColumn}
            sectionRightColumn={sectionRightColumn}
            onChange={this.props.onChange}
          />
        )
      }
      case 'String': {
        return (
          <Textbox
            backgroundColor={backgroundColor}
            formBackgroundColor={formBackgroundColor}
            getValidationState={this.props.getValidationState}
            item={item}
            sectionLeftColumn={sectionLeftColumn}
            sectionRightColumn={sectionRightColumn}
            onChange={this.props.onChange}
          />
        )
      }
      case 'TextArea': {
        return (
          <TextArea
            backgroundColor={backgroundColor}
            formBackgroundColor={formBackgroundColor}
            getValidationState={this.props.getValidationState}
            item={item}
            sectionLeftColumn={sectionLeftColumn}
            sectionRightColumn={sectionRightColumn}
            onChange={this.props.onChange}
          />
        )
      }
      case 'Label': {
        return (
          <Label
            backgroundColor={backgroundColor}
            formBackgroundColor={formBackgroundColor}
            item={item}
            sectionLeftColumn={sectionLeftColumn}
            sectionRightColumn={sectionRightColumn}
          />
        )
      }
      case 'Num': {
        return (
          <Textbox
            backgroundColor={backgroundColor}
            formBackgroundColor={formBackgroundColor}
            getValidationState={this.props.getValidationState}
            item={item}
            sectionLeftColumn={sectionLeftColumn}
            sectionRightColumn={sectionRightColumn}
            onChange={this.props.onChange}
          />
        )
      }
      case 'Dropdown-Num':
      case 'Dropdown-Str': {
        return (
          <Dropdown
            backgroundColor={backgroundColor}
            configData={this.props.configData}
            formBackgroundColor={formBackgroundColor}
            getValidationState={this.props.getValidationState}
            item={item}
            queryFields={this.props.queryFields}
            sectionLeftColumn={sectionLeftColumn}
            sectionRightColumn={sectionRightColumn}
            updateFieldLookUpData={this.props.updateFieldLookUpData}
            onChange={this.props.onChange}
            onNewOptionAdd={this.props.onNewOptionAdd}
          />
        )
      }
      default:
        return <div>Not convenient type</div>
    }
  }

  render() {
    const { section } = this.props
    const domainColor = section.backgroundColor.replace(/[^,]+(?=\))/, '0.15')
    const borderColor = section.backgroundColor.replace(/[^,]+(?=\))/, '0.2')
    return (
      <div
        className="scheduler-border"
        style={{
          backgroundColor: domainColor
        }}
      >
        {section.title && <h3 className="section-title">{section.title}</h3>}
        {_.map(section.items, (item, index) => {
          return (
            <div key={index} className="section-item" style={{ borderBottomColor: borderColor }}>
              {' '}
              {this.checkType(item)}{' '}
            </div>
          )
        })}
      </div>
    )
  }
}
export default Section
