import { updateOne } from '../crudoptV3'

const modelRun = 'runLongRunningAction'

export const runLongRunningAction = (catalogId, remoteActionKey, args) => {
  return updateOne(
    modelRun,
    // TODO RemoteActionId
    `/data/catalog/${catalogId}/longRunningTask/${remoteActionKey}`,
    { data: { arguments: args } },
    {
      method: 'post',
      operationKey: `runLongRunningAction_${catalogId}_${remoteActionKey}`,
      key: `${catalogId}_${remoteActionKey}`,
      getRowKey: () => `${catalogId}_${remoteActionKey}`
    }
  )
}
