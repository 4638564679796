import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import Select from 'react-select'
import styled from 'styled-components'

const menuPortalTarget = document.body
const zIndex = 999999

const MenuPortal = ({ controlElement, children }) => {
  const { bottom, left, width } = controlElement.getBoundingClientRect()
  return createPortal(
    <div
      className="position-fixed"
      style={{
        left,
        top: bottom,
        width,
        zIndex
      }}
    >
      {children}
    </div>,
    menuPortalTarget
  )
}

class Dropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.component.value,
      data: [],
      loading: false
    }

    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.mapOptions = this.mapOptions.bind(this)
    this.onChange = this.onChange.bind(this)
    this.loadLookUpdata = this.loadLookUpdata.bind(this)
    this.updateLookupData = this.updateLookupData.bind(this)
    this.onInputHandleChange = this.onInputHandleChange.bind(this)
    this.checkInitializationValue = this.checkInitializationValue.bind(this)
  }

  onChange(event) {
    const value = event && !_.isNil(event.value) ? event.value : null
    if (value !== this.state.value && !this.state.loading) {
      const { component, boundedComp = undefined } = this.props
      this.props.onChange(component.sectionKey, component.fieldName, value, () => {
        if (
          _.isObject(boundedComp) &&
          _.keys(boundedComp).length > 0 &&
          _.includes(boundedComp.boundedFields, '_searchText')
        ) {
          this.props.loadLookUpdataReadOnly(value)
        }
      })
    }
  }

  getOptions = (response) => {
    const { component: { fieldName = '' } = {} } = this.props
    const readOnlyFieldName = `${fieldName}_readonly`

    const result = _.get(response, 'data.result', [])
    return _.map(result, ({ Id, Value, [readOnlyFieldName]: readOnlyFieldValue }) => {
      const _id = _.isNil(Id) ? Value : Id
      return {
        id: _id,
        value: Value,
        ...(!_.isNil(readOnlyFieldValue) ? { [readOnlyFieldName]: readOnlyFieldValue } : {})
      }
    })
  }

  onInputHandleChange(value) {
    const { component: { element: { MinNoOfSearchChars = 3 } = {} } = {} } = this.props
    if (value.length >= MinNoOfSearchChars) {
      this.updateLookupData(value)
    }
  }

  onBlur() {
    const { component: { element: { searchEnabled = false } = {}, fieldName } = {} } = this.props
    const { value } = this.state
    if (_.isNil(value) && searchEnabled) {
      this.loadLookUpdata('', fieldName)
    }
  }

  onFocus() {
    const { boundedComp = undefined, component = {} } = this.props

    if (_.isObject(boundedComp) && boundedComp.mustBeUpdated === true) {
      this.loadLookUpdata('', component.fieldName)
      this.props.changeMustBeUpdated(component.fieldName, false)
    }
  }

  updateLookupData(searchText) {
    const { component } = this.props
    this.loadLookUpdata(searchText, component.fieldName)
  }

  loadLookUpdata(searchText, fieldName) {
    const { pluginId, prepareRecorDatawithChanges, actualFiters, component, clientWithProgress } =
      this.props
    const editableField = this.props.getLookupDataEditableField(fieldName)

    if (editableField) {
      this.setState({ loading: true, data: [] })
      const record = {
        ...prepareRecorDatawithChanges(),
        _searchText: searchText
      }

      clientWithProgress
        .post(`/data/plugin/${pluginId}/lookup/${editableField.field}`, {
          data: { record, actualFiters }
        })
        .then((response) => {
          const options = this.getOptions(response)

          this.setState({ data: options, loading: false }, () => {
            this.checkInitializationValue(component.value)
            this.props.updateFieldLookUpData(component.sectionKey, component.fieldName, options)
          })
        })
    }
  }

  checkInitializationValue(value) {
    const { data = [] } = this.state
    const { component } = this.props

    if (!_.isNil(value) && !_.find(data, { id: value })) {
      this.props.onChange(component.sectionKey, component.fieldName, null)
      this.props.setValidationState('error')
    }
  }

  mapOptions(list, idName, valueName) {
    return _.map(list, (item) => ({
      label: item[valueName],
      value: item[idName]
    }))
  }

  getReadOnlyValue = () => {
    const {
      props: {
        settingsComponents = [],
        component: { fieldName, readonly: compReadonly = false } = {},
        readonly: formReadonly = false
      },
      state: { data }
    } = this

    const lookupReadOnlyField = `${fieldName}_readonly`
    const { [lookupReadOnlyField]: lookupReadOnlyValue } = _.first(data) || {}
    const settingsComponent = _.find(
      settingsComponents,
      (component) => component.fieldName === fieldName
    )

    const componentReadOnly =
      settingsComponent &&
      (settingsComponent.readonly === 'true' || settingsComponent.readonly === 'false')
        ? compReadonly
        : !_.isNil(lookupReadOnlyValue)
        ? lookupReadOnlyValue
        : compReadonly

    const disabled = !!(formReadonly || componentReadOnly)
    return disabled
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  UNSAFE_componentWillMount() {
    const {
      component: { data, value }
    } = this.props

    if (!_.isEmpty(data) && data instanceof Promise) {
      this.setState({ loading: true })
      data.then((response) => {
        this.setState(
          {
            data: this.getOptions(response),
            loading: false
          },
          () => this.checkInitializationValue(value)
        )
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { component: { fieldName, required: compRequired } = {} } = this.props
    const { component: { data: nextLookupData, value: nextValue } = {} } = nextProps
    if (!_.isEmpty(nextLookupData) && nextLookupData instanceof Promise) {
      nextLookupData.then((response) => {
        const data = this.getOptions(response)

        if (!_.isEqual(this.state.data, data) && data.length > 0) {
          const newState = {
            data,
            value: nextValue
          }

          this.setState({ ...newState }, () => {
            const disabled = this.getReadOnlyValue()
            const required = disabled ? false : compRequired
            this.props.setRequiredState(fieldName, required)
            this.props.setValidationState(disabled ? null : 'error')
          })
        }
      })
    }

    if (!_.isEqual(nextProps, this.props)) {
      this.setState({
        value: nextProps.component.value
      })
    }
  }

  render() {
    const {
      props: {
        isInvalid,
        compact = false,
        inputHeight = 25,
        inputFontSize = 11,
        component: {
          placeholder = '',
          required: compRequired,
          value: propValue,
          element: { searchEnabled = false } = {}
        } = {},
        validationCheckState = false
      },
      state: { data, value, loading = false }
    } = this

    const disabled = this.getReadOnlyValue()
    const required = disabled ? false : compRequired
    const selectValue =
      _(data)
        .map(({ value: label, id: value }) => ({ label, value }))
        .find((item) => item.value === value) || null

    const noPropValue = _.isNil(propValue) || _.isEmpty(_.toString(propValue))
    const errorClassName = validationCheckState && required && noPropValue ? 'true' : 'null'
    let validationClass = ''
    if (validationCheckState) {
      validationClass = isInvalid ? 'is-invalid' : ''
    }

    const Wrapper = styled.section`
      .compactSelectbox {
        .filter {
          &__control {
            height: ${inputHeight}px;
            min-height: ${inputHeight}px;
          }
          &__indicators {
            height: ${inputHeight}px;
          }
          &__value-container {
            height: ${inputHeight}px;
          }
        }
      }
    `

    return (
      <Wrapper>
        <div
          className={`error-${errorClassName} ${validationClass} ${
            compact ? 'compactSelectbox' : ''
          }`}
        >
          <Select
            className={validationClass}
            classNamePrefix="filter"
            components={{ MenuPortal }}
            escapeClearsValue={false}
            isClearable={required}
            isDisabled={disabled}
            isLoading={loading}
            menuPortalTarget={menuPortalTarget}
            options={this.mapOptions(data, 'id', 'value')}
            placeholder={placeholder}
            style={{
              height: `${compact ? `${inputHeight}px` : 'auto'}`,
              fontSize: `${compact ? `${inputFontSize}px` : '14px'}`
            }}
            value={selectValue}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onInputChange={searchEnabled ? this.onInputHandleChange : () => {}}
          />
        </div>
      </Wrapper>
    )
  }
}

export default Dropdown
