import { AG_GRID_ENUMS } from '../../../enums'
import { DEFAULT_CUSTOM_ROW_INFO } from '../../../constants'
import { getControlValue } from '../index'

const { CUSTOM_ROW_INFO } = AG_GRID_ENUMS

function getDefaultCellData() {
  return {
    [CUSTOM_ROW_INFO]: DEFAULT_CUSTOM_ROW_INFO,
    col1: 5,
    col2: '12',
    col3: 'test',
    col4: '',
    col5: new Date()
  }
}

function getDefaultParams() {
  return {
    valueField: 'col1',
    cellData: getDefaultCellData(),
    value: 6
  }
}

describe('AgGrid:Helpers:Cell:getControlValue', () => {
  let params = getDefaultParams()

  beforeEach(() => {
    params = getDefaultParams()
  })

  it('should return NaN if value is empty', () => {
    params.valueField = 'col4'
    const result = getControlValue({
      value: '',
      valueField: params.valueField,
      cellData: params.cellData
    })
    expect(result).toEqual(NaN)
  })
  it('should return NaN if result type is not number or string', () => {
    params.valueField = 'col5'
    const result = getControlValue({
      value: params.value,
      valueField: params.valueField,
      cellData: params.cellData
    })
    expect(result).toEqual(NaN)
  })
  it('should return converted number of result if result type is string and result is not empty', () => {
    params.valueField = 'col2'
    const result = getControlValue({
      value: params.value,
      valueField: params.valueField,
      cellData: params.cellData
    })
    expect(result).toEqual(Number(result))
  })
})
