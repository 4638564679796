import mapOptions from './constants/mapOptions'

const tileNames = Object.values(mapOptions.tiles).map((tile) => tile.title)
const themeNames = Object.entries(mapOptions.themes).map(([key]) => key)

export default () => ({
  title: 'TUI v3',
  type: 'object',
  format: 'objectTab',
  properties: {
    general: {
      type: 'object',
      title: 'General',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          title: 'Name',
          default: ''
        },
        header: {
          type: 'string',
          title: 'Header',
          default: ''
        },
        maximizable: {
          type: 'boolean',
          title: 'Maximizable',
          default: true,
          required: true
        }
      }
    },
    settings: {
      type: 'object',
      title: 'Settings',
      properties: {
        title: {
          type: 'string',
          title: 'Title',
          default: ''
        },
        runParameters: {
          type: 'string',
          title: 'Run Parameters',
          default: ''
        },
        wsdl: {
          type: 'string',
          title: 'WSDL',
          default: ''
        },
        model: {
          type: 'string',
          title: 'Model Name',
          default: ''
        },
        errorMessage: {
          type: 'string',
          title: 'Error Message',
          default: 'Service is not running. Please contact your system admins.'
        },
        asyncActionStartMessage: {
          type: 'string',
          title: 'Save Plan Message',
          default: 'Operation will be completed soon.'
        },
        asyncActionSuccessMessage: {
          type: 'string',
          title: 'Success Plan Message',
          default: 'Operation completed successfully.'
        },
        asyncActionFailMessage: {
          type: 'string',
          title: 'Fail Plan Message',
          default: 'Operation has failed.'
        },
        isServiceSelectionEnabled: {
          type: 'boolean',
          title: 'Service Selection',
          default: true,
          required: true
        },
        defaultView: {
          type: 'string',
          title: 'Default View',
          enum: ['Map', 'Table', 'Tree Table'],
          default: 'Map',
          required: true
        },
        backgroundColor: {
          type: 'string',
          format: 'Color',
          title: 'Background Color',
          default: '#ffffff'
        },
        treeRefreshInterval: {
          title: 'Tree Refresh Interval',
          type: 'number',
          default: 0
        },
        isTreeFilteringEnabled: {
          type: 'boolean',
          title: 'Enable Load and Demand Tree Filtering',
          default: true,
          required: true
        },
        isServicePersistent: {
          type: 'boolean',
          title: 'Remember Last Selected Service',
          default: true,
          required: true
        },
        disabledMap: {
          type: 'object',
          title: 'Disabled Map',
          options: {
            collapsed: true
          },
          properties: {
            latValue: {
              title: 'Lat Value',
              type: 'number',
              default: 39.0405193724839
            },
            lngValue: {
              title: 'Lng Value',
              type: 'number',
              default: 35.29724098742009
            },
            zoomFactor: {
              title: 'Zoom Factor',
              type: 'number',
              default: 7
            }
          }
        }
      }
    },
    map: {
      type: 'object',
      title: 'Map',
      properties: {
        tile: {
          title: 'Tile',
          type: 'string',
          enum: tileNames,
          default: '',
          required: true
        },
        theme: {
          title: 'Theme',
          type: 'string',
          enum: themeNames,
          default: '',
          required: true
        },
        latValue: {
          title: 'Lat Value',
          type: 'number',
          default: 39.0405193724839
        },
        lngValue: {
          title: 'Lng Value',
          type: 'number',
          default: 35.29724098742009
        },
        zoomFactor: {
          title: 'Zoom Factor',
          type: 'number',
          default: 7
        }
      }
    },
    loadTree: {
      type: 'object',
      title: 'Load Tree',
      properties: {
        columns: {
          type: 'array',
          title: 'Load Tree Columns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataType: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              isTreeDistinctColorField: {
                type: 'boolean',
                title: 'Tree Distinct Color Column',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              backgroundColor: {
                type: 'string',
                title: 'Background Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              isPercentageType: {
                type: 'boolean',
                title: 'Percentage',
                default: false,
                required: true
              },
              isDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'string',
                title: 'Width'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              }
            }
          }
        },
        hierarchy: {
          type: 'array',
          title: 'Hierarchy',
          items: {
            type: 'object',
            title: 'Hierarchy',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.loadTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              }
            }
          }
        },
        filters: {
          type: 'array',
          title: 'Filters',
          items: {
            type: 'object',
            title: 'Filter',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.loadTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              },
              operator: {
                type: 'string',
                title: 'Operator',
                enum: ['=', '<', '>'],
                required: true,
                default: 'min'
              },
              value: {
                type: 'string',
                title: 'Value'
              }
            }
          }
        },
        footerButtons: {
          type: 'array',
          title: 'Footer Buttons',
          items: {
            type: 'object',
            title: 'Button',
            headerTemplate: 'Button {{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              text: {
                type: 'string',
                title: 'Text',
                default: ''
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip',
                default: ''
              },
              icon: {
                type: 'string',
                title: 'Icon',
                format: 'IconSelector',
                default: 'fa fa-pie-chart'
              },
              isConfirmationRequired: {
                type: 'boolean',
                title: 'Confirmation',
                required: true,
                default: false
              },
              confirmationText: {
                type: 'string',
                title: 'Confirmation Text',
                default: 'Are you sure to continue?'
              },
              confirmationTitle: {
                type: 'string',
                title: 'Confirmation Title',
                default: 'Confirmation'
              }
            }
          }
        }
      }
    },
    demandTree: {
      type: 'object',
      title: 'Demand Tree',
      properties: {
        columns: {
          type: 'array',
          title: 'Demand Tree Columns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataType: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: true,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum', 'count'],
                required: true,
                default: 'min'
              },
              backgroundColor: {
                type: 'string',
                title: 'Background Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              isDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'string',
                title: 'Width'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              }
            }
          }
        },
        hierarchy: {
          type: 'array',
          title: 'Hierarchy',
          items: {
            type: 'object',
            title: 'Hierarchy',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.demandTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              }
            }
          }
        },
        filters: {
          type: 'array',
          title: 'Filters',
          items: {
            type: 'object',
            title: 'Filter',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.demandTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              },
              operator: {
                type: 'string',
                title: 'Operator',
                enum: ['=', '<', '>'],
                required: true,
                default: 'min'
              },
              value: {
                type: 'string',
                title: 'Value'
              }
            }
          }
        },
        footerButtons: {
          type: 'array',
          title: 'Footer Buttons',
          items: {
            type: 'object',
            title: 'Button',
            headerTemplate: 'Button {{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              text: {
                type: 'string',
                title: 'Text',
                default: ''
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip',
                default: ''
              },
              icon: {
                type: 'string',
                title: 'Icon',
                format: 'IconSelector',
                default: 'fa fa-pie-chart'
              },
              isConfirmationRequired: {
                type: 'boolean',
                title: 'Confirmation',
                required: true,
                default: false
              },
              confirmationText: {
                type: 'string',
                title: 'Confirmation Text',
                default: 'Are you sure to continue?'
              },
              confirmationTitle: {
                type: 'string',
                title: 'Confirmation Title',
                default: 'Confirmation'
              }
            }
          }
        },
        totalFields: {
          type: 'array',
          title: 'Total Fields',
          items: {
            type: 'object',
            title: 'Total Fields',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.demandTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              }
            }
          }
        }
      }
    },
    location: {
      type: 'object',
      title: 'Locations',
      properties: {
        locations: {
          type: 'array',
          title: 'Locations',
          items: {
            type: 'object',
            title: 'Location',
            headerTemplate: '{{ self.name }}',
            options: {
              collapsed: true
            },
            properties: {
              facilityIndex: {
                type: 'string',
                title: 'Facility Index'
              },
              displayName: {
                type: 'string',
                title: 'Display Name'
              },
              facilityName: {
                type: 'string',
                title: 'Location Name'
              }
            }
          }
        }
      }
    },
    loadTable: {
      type: 'object',
      title: 'Load Table',
      properties: {
        isGroupingEnabled: {
          type: 'boolean',
          default: false,
          required: true,
          title: 'Grouping'
        },
        columns: {
          type: 'array',
          title: 'Load Table Columns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataType: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              columnType: {
                type: 'string',
                title: 'Column Type',
                enum: ['simple', 'progress'],
                required: true,
                default: 'simple'
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              backgroundColor: {
                type: 'string',
                title: 'Background Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              summaryType: {
                type: 'string',
                title: 'Summary Type',
                enum: ['', 'count', 'sum', 'min', 'max', 'average'],
                required: true,
                default: ''
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              isDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'string',
                title: 'Width'
              },
              isLocked: {
                type: 'boolean',
                title: 'Locked',
                default: false,
                required: true
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              }
            }
          }
        }
      }
    },
    loadStopTable: {
      type: 'object',
      title: 'Load Stop Table',
      properties: {
        header: {
          type: 'string',
          title: 'Header'
        },
        isGroupingEnabled: {
          type: 'boolean',
          default: false,
          required: true,
          title: 'Grouping'
        },
        columns: {
          type: 'array',
          title: 'TuiColumns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataType: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              backgroundColor: {
                type: 'string',
                title: 'Background Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              isDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'string',
                title: 'Width'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              }
            }
          }
        }
      }
    },
    loadStopEntryTable: {
      type: 'object',
      title: 'Load Stop Entry Table',
      properties: {
        header: {
          type: 'string',
          title: 'Header'
        },
        isGroupingEnabled: {
          type: 'boolean',
          default: false,
          required: true,
          title: 'Grouping'
        },
        columns: {
          type: 'array',
          title: 'TuiColumns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataType: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              backgroundColor: {
                type: 'string',
                title: 'Background Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              isDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'string',
                title: 'Width'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              }
            }
          }
        }
      }
    },
    units: {
      type: 'object',
      title: 'Units',
      properties: {
        BaseCurrencyUOM: {
          type: 'string',
          title: 'Currency'
        },
        BaseDistanceUOM: {
          type: 'string',
          title: 'Distance'
        },
        BaseTimeUOM: {
          type: 'string',
          title: 'Time'
        },
        BaseVolumeUOM: {
          type: 'string',
          title: 'Volume'
        },
        BaseWeightUOM: {
          type: 'string',
          title: 'Weight'
        },
        BaseLademeterUOM: {
          type: 'string',
          title: 'Ladameter'
        }
      }
    }
  }
})
