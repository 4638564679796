Ext.define('Spui.view.common.recom.Main', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-recom-distribution-main',
  mixins: ['Spui.view.common.recom.distribution.Mixin'],
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },
  viewModel: {},
  defaultListenerScope: true
})
