import { ReadOnlyProps } from './ReadOnly.types'

const ReadOnly = ({ title, text, author, fontSizes }: ReadOnlyProps) => {
  return (
    <>
      {title ? <h5 style={{ fontSize: fontSizes.title }}>{title}</h5> : null}

      {text ? (
        <p className="text-dark m-0" style={{ fontSize: fontSizes.text }}>
          {text}
        </p>
      ) : null}

      {author ? (
        <p className="align-self-end text-muted m-0" style={{ fontSize: fontSizes.author }}>
          {author}
        </p>
      ) : null}
    </>
  )
}

export default ReadOnly
