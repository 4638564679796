import {
  UPDATE_MFP_DATA_START,
  STORE_UPDATE_MFP_DATA,
  STORE_INCREASE_PENDING_UPDATES_COUNT,
  STORE_DECREASE_PENDING_UPDATES_COUNT,
  STORE_PENDING_UPDATES_COUNT,
  STORE_UPDATE_MFP_DATA_FAILED
} from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  UpdateMfpData: {},
  isLoading: false,
  error: {},
  pendingCount: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MFP_DATA_START:
      return updateObject(state, {
        isLoading: false
      })
    case STORE_INCREASE_PENDING_UPDATES_COUNT:
      return updateObject(state, {
        pendingCount: state.pendingCount + 1
      })
    case STORE_DECREASE_PENDING_UPDATES_COUNT:
      const count = state.pendingCount - 1
      return updateObject(state, {
        pendingCount: count < initialState.pendingCount ? initialState.pendingCount : count
      })
    case STORE_PENDING_UPDATES_COUNT:
      return updateObject(state, {
        pendingCount: action.payload.pendingCount
      })
    case STORE_UPDATE_MFP_DATA:
      return updateObject(state, {
        UpdateMfpData: { ...action.payload }
      })
    case STORE_UPDATE_MFP_DATA_FAILED:
      return updateObject(state, {
        UpdateMfpData: {},
        error: { ...action.payload },
        isLoading: false
      })
    default:
      return state
  }
}

export default reducer
