Ext.define('MDM.btlv2.upload.Edit.FormDialog', {
  extend: 'Ext.window.Window',
  requires: [
    'Ext.layout.container.Fit',
    'Ext.layout.container.VBox',
    'Ext.layout.container.HBox',
    'Ext.form.Panel',
    'Ext.form.field.Text',
    'Ext.form.field.Date',
    'Ext.form.field.Time',
    'Ext.form.field.Checkbox',
    'Ext.calendar.form.CalendarPicker'
  ],
  resize: false,
  viewModel: {
    data: {
      record: null,
      readOnly: false
    }
  },
  closable: false,
  iconCls: 'fa fa-pencil-square-o',
  layout: 'border',
  bodyStyle: {
    backgroud: '#fff'
  },
  modal: true,
  defaultListenerScope: true,
  height: 350,
  width: 500,
  record: null,
  bodyPadding: 5,
  config: {
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     *
     */
    formType: 'MegaRegion',
    megaRegionField: {
      xtype: 'textfield',
      fieldLabel: 'MegaRegion',
      flex: 1,
      name: 'MegaRegionSelection',
      itemId: 'megaregionselection',
      disabled: true,
      bind: {
        value: '{record.MegaRegion}'
      }
    },
    regionField: {
      xtype: 'textfield',
      fieldLabel: 'Subregion',
      name: 'Region',
      itemId: 'Region',
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.Region}'
      }
    },
    supplySourceField: {
      xtype: 'combo',
      fieldLabel: 'Plant',
      name: 'SupplySource',
      readOnly: false,
      displayField: 'name',
      valueField: 'name',
      flex: 1,
      listeners: {
        select: 'onChangePlant'
      },
      bind: {
        value: '{record.SupplySourceID}'
      }
    },
    countryField: {
      xtype: 'combo',
      fieldLabel: 'Country',
      name: 'Country',
      forceSelection: true,
      editable: false,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'name',
      allowBlank: false,
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      bind: {
        value: '{record.Country}'
      }
    },
    materialType: {
      xtype: 'combobox',
      fieldLabel: 'Material Type',
      flex: 1,
      name: 'MaterialType',
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      allowBlank: false,
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      bind: {
        value: '{record.MaterialTypeID}'
      }
    },
    /**
     * @cfg {Object} calendarField
     * The config for the calendar field.
     */
    fromRegionField: {
      xtype: 'combo',
      fieldLabel: 'From Subregion',
      name: 'fromregion',
      itemId: 'fromregion',
      forceSelection: true,
      editable: false,
      flex: 2,
      valueField: 'id',
      displayField: 'name',
      bind: {
        value: '{record.FromRegionID}',
        disabled: '{record.ToRegionID}'
      },
      listeners: {
        select: 'onChangeFromRegion'
      }
    },
    toRegionField: {
      xtype: 'combo',
      fieldLabel: 'To Subregion',
      name: 'toregion',
      itemId: 'toregion',
      flex: 2,
      valueField: 'id',
      displayField: 'name',
      allowBlank: false,
      bind: {
        value: '{record.ToRegionID}',
        disabled: '{record.FromRegionID}'
      },
      listeners: {
        select: 'onChangeToRegion'
      }
    },
    dpiFamilyField: {
      xtype: 'textfield',
      fieldLabel: 'DPIFamily',
      name: 'DPIFamily',
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.DPIFamily}',
        readOnly: '{readOnly}'
      }
    },
    dpiTypeDesc: {
      xtype: 'textfield',
      fieldLabel: 'BTL Type',
      flex: 1,
      name: 'BTLType',
      disabled: true,
      bind: {
        value: '{record.BTLType}',
        readOnly: '{readOnly}'
      }
    },
    createdByField: {
      xtype: 'textfield',
      fieldLabel: 'Created By',
      flex: 1,
      name: 'MaterialType',
      disabled: true,
      bind: {
        value: '{record.CreatedBy}',
        readOnly: '{readOnly}'
      }
    },
    creteatedAtField: {
      xtype: 'datefield',
      fieldLabel: 'Created At',
      name: 'endDate',
      disabled: true,
      flex: 1,
      bind: {
        value: '{record.CreatedAt}',
        readOnly: '{readOnlyMode}'
      }
    },
    appliedAtField: {
      xtype: 'datefield',
      fieldLabel: 'Applied At',
      name: 'appliedAt',
      disabled: true,
      flex: 1,
      bind: {
        value: '{record.AppliedAt}',
        readOnly: '{readOnly}'
      }
    },
    materialCode: {
      xtype: 'mygridpicker',
      fieldLabel: 'Material code',
      name: 'Material',
      bind: {
        value: '{record.MaterialCode}',
        readOnly: '{readOnly}'
      },
      allowBlank: true,
      width: 500,
      multiSelect: false,
      columns: [
        {
          text: 'Name',
          dataIndex: 'name',
          flex: 1
        }
      ],
      displayField: 'name',
      valueField: 'id',
      listeners: {
        select: 'onMaterialCodeChange'
      }
    },
    commentField: {
      xtype: 'textarea',
      fieldLabel: 'Comment',
      name: 'Comment',
      height: 100,
      bind: {
        value: '{record.Comment}',
        readOnly: '{readOnly}'
      }
    },
    latLongContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    dpiContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    geoContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    crossContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    userContainer: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    showHideButton: {
      text: 'Show/Hide Data',
      handler: 'onShowHide'
    },
    /**
     * @cfg {Object} saveButton
     * The config for the save button.
     */
    saveButton: {
      text: 'OK',
      handler: 'onSaveTap',
      bind: {}
    },
    /**
     * @cfg {Object} cancelButton
     * The config for the cancel button.
     */
    cancelButton: {
      text: 'Close',
      iconCls: 'fa fa-times',
      handler: 'onCancelTap'
    }
  },
  initForm: function () {
    this.createItems()
  },
  initComponent: function () {
    var me = this,
      BTLType = me.record.get('BTLType'),
      BTLTypeDesc = me.record.get('BTLTypeDesc')
    this.getViewModel().set('readOnly', this.readOnly)
    if (BTLType == 'P' || BTLType == 'D' || BTLType == 'I' || BTLType == 'X') {
      this.title = BTLType
      this.getViewModel().set('record', this.record)
      me.initForm()
      me.fbar = me.generateButtons()
      me.callParent()
      me.form = me.items.first()
      me.checkFields()
      me.applyValues()
    } else {
      me.height = 150
      me.width = 300
      me.title = 'Error ! <span style="color:yellow">' + BTLTypeDesc + '</span>'
      me.msg = new Ext.Component({
        cls: me.baseCls + '-text',
        html: BTLTypeDesc,
        margin: '5 5 20,5'
      })
      ;(me.iconCls = 'fa fa-warning'), (me.items = [me.msg])
      me.fbar = [me.getCancelButton()]
      me.callParent()
    }
  },
  generateButtons: function () {
    var buttons = [],
      showhide = this.getShowHideButton()
    buttons.push(
      {
        xtype: 'component',
        flex: 1
      },
      this.getSaveButton()
    )
    return buttons
  },
  applyValues: function () {},
  createItems: function () {
    var me = this,
      geoContainer = me.getGeoContainer(),
      dpiContainer = me.getDpiContainer(),
      userContainer = me.getUserContainer(),
      crossContainer = me.getCrossContainer()
    if (!dpiContainer.items) {
      dpiContainer.items = [me.getDpiFamilyField(), me.getDpiTypeDesc(), me.getMaterialType()]
    }
    if (!geoContainer.items) {
      geoContainer.items = [me.getMegaRegionField(), me.getRegionField(), me.getSupplySourceField()]
    }
    if (!userContainer.items) {
      userContainer.items = [
        me.getCreatedByField(),
        me.getCreteatedAtField(),
        me.getAppliedAtField()
      ]
    }
    if (!crossContainer.items) {
      crossContainer.items = [me.getFromRegionField(), me.getToRegionField()]
    }
    var items = [],
      BTLType = me.record.get('BTLType')
    if (BTLType === 'P') {
      items.push(me.getMaterialType())
      items.push(me.getMaterialCode())
      items.push(me.getSupplySourceField())
      items.push(me.getCommentField())
    } else if (BTLType === 'I') {
      items.push(me.getMaterialType())
      items.push(me.getMaterialCode())
      items.push(me.getCommentField())
    } else if (BTLType === 'X') {
      items.push(me.getMaterialType())
      items.push(me.getMaterialCode())
      items.push(me.getFromRegionField())
      items.push(me.getToRegionField())
      items.push(me.getSupplySourceField())
      items.push(me.getCommentField())
    } else if (BTLType === 'D') {
      items.push(me.getMaterialType())
      items.push(me.getMaterialCode())
      items.push(me.getCommentField())
    } else if (me.getFormType() === 'Country') {
      items.push(me.getMegaRegionSelectionField())
      items.push(me.getRegionSelectionField())
      items.push(me.getCountryField())
    } else if (me.getFormType() === 'SupplySource') {
      items.push(me.getMegaRegionSelectionField())
      items.push(me.getRegionSelectionField())
      items.push(me.getSupplySourceField())
      items.push(me.getCommentField())
    }
    me.grid = Ext.create({
      xtype: 'btl-dialog-grid',
      region: 'center',
      reference: 'map',
      border: true,
      bind: {
        record: '{record}'
      },
      listeners: {
        dragend: 'onDragend'
      }
    })
    this.items = [
      {
        xtype: 'form',
        border: false,
        trackResetOnLoad: true,
        region: 'center',
        height: '120',
        defaults: {
          anchor: '100%',
          labelWidth: 120
        },
        bodyPadding: 10,
        fieldDefaults: {
          labelAlign: 'right',
          labelWidth: 90,
          msgTarget: Ext.supports.Touch ? 'side' : 'qtip'
        },
        items: items
      }
    ]
  },
  privates: {
    checkFields: function () {},
    onAllDayChange: function () {
      this.checkFields()
    },
    onCancelTap: function () {
      this.close()
    },
    onDropTap: function () {
      this.fireDrop()
    },
    onSaveTap: function () {
      this.fireEvent('save', this, this.grid)
      this.close()
    },
    getMaterialCode: function () {
      var c = this.materialCode
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getMaterialType: function () {
      var c = this.materialType
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getSupplySourceField: function () {
      var c = this.supplySourceField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getFromRegionField: function () {
      var c = this.fromRegionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getToRegionField: function () {
      var c = this.toRegionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getStore: function (c) {
      return Ext.create('Ext.data.Store', {
        field: [
          {
            name: 'name'
          },
          {
            name: 'id'
          }
        ],
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: MDM.Global.getApiUrl() + 'gillettev3/BTL_ComboData',
          extraParams: {
            fieldType: c
          },
          reader: {
            type: 'json'
          }
        },
        autoLoad: true
      })
    }
  },
  onShowHide: function () {
    var dataPanel = this.down('#dataPanel'),
      height = dataPanel.isVisible() ? 400 : 650
    this.setHeight(height)
    dataPanel.setVisible(!dataPanel.isVisible())
  },
  onMaterialCodeChange: function (combo, value) {
    var me = this,
      rec = combo.selectedRecords[0]
    me.record.set('MaterialCode', rec.get('id'))
    me.record.set('MaterialDescription', rec.get('MaterialDescription'))
    me.record.set('MaterialID', rec.get('MaterialID'))
    me.record.commit()
  },
  onChangePlant: function (combo, rec) {
    var me = this
    me.record.set('SupplySource', rec.get('name'))
    me.record.commit()
  },
  onChangeFromRegion: function (combo, rec) {
    var me = this
    me.record.set('FromRegion', rec.get('name'))
    me.record.commit()
  },
  onChangeToRegion: function (combo, rec) {
    var me = this
    me.record.set('ToRegion', rec.get('name'))
    me.record.commit()
  }
})
