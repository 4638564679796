Ext.define('clustering.StoreGrid', {
  extend: 'Ext.grid.Panel',
  alias: 'widget.storeGrid',
  requires: ['Ext.grid.plugin.Exporter'],
  cls: 'clustering-details-grid',
  bufferedRenderer: false,
  filterStores: function (clusterId) {
    var store = this.getStore()
    store.clearFilter()
    if (clusterId >= 0) {
      store.filter({
        property: 'ClusterID',
        value: clusterId,
        exactMatch: true,
        caseSensitive: true
      })
    }
  },
  multiSelect: true,
  flex: 0.8,
  selModel: {
    allowDeselect: true
  },
  plugins: [
    {
      ptype: 'gridexporter'
    }
  ],
  lockedGridConfig: {
    width: 250,
    collapsible: false
  },
  viewConfig: {
    plugins: {
      ptype: 'gridviewdragdrop',
      dragGroup: 'SegmentDropGroup',
      dragText: 'Change Cluster'
    },
    listeners: {
      rowdblclick: function (container, b, c, rowIndex, e, eOpts) {
        var me = this.up('clusteringmainview')
        var vm = me.getViewModel()
        var record = b.data
        if (container.grid.getSelectionModel().selected.items.length == 0) {
          record = null
        } else if (container.grid.getSelectionModel().selected.items.length == 1) {
          record = container.grid.getSelectionModel().selected.items[0].data
        } else {
          record = b.data
        }

        vm.set('currentStore', record)
      },
      containerclick: function (container) {
        var me = this.up('clusteringmainview')
        var vm = me.getViewModel()
        vm.set('currentStore', null)
      }
    }
  },
  config: {
    store: null,
    currentStore: null,
    currentSegment: null,
    xDimension: null,
    yDimension: null,
    zDimension: null,
    dimensions: null
  },
  bind: {
    store: '{clusters}',
    currentStore: '{currentStore}',
    currentSegment: '{currentSegment}',
    xDimension: '{xDimension}',
    yDimension: '{yDimension}',
    zDimension: '{zDimension}',
    dimensions: '{dimensions}'
  },
  setColumnHeader: function (value, dataIndex) {
    var me = this

    me.columns.forEach(function (column) {
      if (column.dataIndex == dataIndex) {
        if (value == 'Undefined') {
          column.hide()
        } else {
          column.show()
          column.setText(value)
        }

        var formatter = '0,000'
        if (value == 'Average Price') formatter = '0,000.00'

        if (column.dataIndex === 'X') {
          column.renderer = function (value, meta) {
            meta.style = 'background-color: #fff6f5;'
            return Ext.util.Format.numberRenderer(formatter)(value)
          }
        }

        if (column.dataIndex === 'Y') {
          column.renderer = function (value, meta) {
            meta.style = 'background-color: #f7ffe8;'
            return Ext.util.Format.numberRenderer(formatter)(value)
          }
        }

        if (column.dataIndex === 'Z') {
          column.renderer = function (value, meta) {
            meta.style = 'background-color: #e9fcff;'
            return Ext.util.Format.numberRenderer(formatter)(value)
          }
        }
      } else if (column.dataIndex == dataIndex + 'Values') {
        column.setText(value)
      }
    }, this)
  },
  setCurrentStore: function (record) {
    if (record) {
      var index = this.store.find('Id', record.Id)
      var selectedRecord = this.getSelectionModel().getSelection()[0]
      var selectedIndex = this.store.indexOf(selectedRecord)
      if (selectedIndex !== index) {
        this.getSelectionModel().select(this.store.getAt(index))
      }
    }
  },
  setCurrentSegment: function (record) {},

  setDimensions: function (value) {
    var me = this,
      totalRevenueDisplayName = 'Total Revenue',
      totalRevenueDataIndex = 'TotalRevenue',
      isColumnHidden = value.indexOf(totalRevenueDisplayName) > -1

    me.setColumnHeader(value[0], 'X')
    me.setColumnHeader(value[1], 'Y')
    me.setColumnHeader(value[2], 'Z')

    var displayXValues = false
    var displayYValues = false
    var displayZValues = false
    let firstRow = null
    if (this.store && this.store.data && _.size(this.store.data.items) > 0) {
      firstRow = this.store.data.items[0]
      if (firstRow && firstRow.data) {
        if (_.size(firstRow.data.XValues) > 0) {
          displayXValues = true
        }
        if (_.size(firstRow.data.YValues) > 0) {
          displayYValues = true
        }
        if (_.size(firstRow.data.ZValues) > 0) {
          displayZValues = true
        }
      }
    }

    me.columns.forEach(function (column) {
      if (column.dataIndex == totalRevenueDataIndex) {
        if (isColumnHidden) {
          column.hide()
        } else {
          column.show()
        }
      }
      if (column.dataIndex === 'XValues') {
        if (displayXValues) {
          column.show()
        } else {
          column.hide()
        }
      }
      if (column.dataIndex === 'YValues') {
        if (displayYValues) {
          column.show()
        } else {
          column.hide()
        }
      }
      if (column.dataIndex === 'ZValues') {
        if (displayZValues) {
          column.hideable = true
          column.show()
        } else {
          column.hide()
        }
      }
    }, this)

    var grid = me.up('clusteringmainview')
    var vm = grid.getViewModel()
    var categoryNames = vm.get('categoryNames')

    // Create hidden columns for export
    if (categoryNames && categoryNames.length) {
      const firstRowData = firstRow.data
      if (displayXValues) {
        this.createDetailsColumns(me.normalGrid, firstRowData.XValues, 'xValue', categoryNames[0])
      }
      if (displayYValues) {
        this.createDetailsColumns(me.normalGrid, firstRowData.YValues, 'yValue', categoryNames[1])
      }
      if (displayZValues) {
        this.createDetailsColumns(me.normalGrid, firstRowData.ZValues, 'zValue', categoryNames[2])
      }
    }

    me.getView().refresh()
  },

  createDetailsColumns: function (grid, details, dataIndex, headers) {
    _.forEach(details, (detail, i) => {
      var column = Ext.create('Ext.grid.column.Column', {
        text: headers[i],
        dataIndex: dataIndex + i,
        hidden: true,
        hideable: false,
        ignoreExport: false,
        exportColumn: true,
        exportStyle: {
          width: 130,
          format: '0.00'
        }
      })
      grid.headerCt.insert(grid.columns.length + i, column)
    })
  },

  doExport: function () {
    var view = this.up('clusteringmainview'),
      viewModel = view.getViewModel(),
      currentConfigurationData = viewModel.get('currentConfigurationData')
    var filters = {}
    currentConfigurationData.Scopes.forEach(function (scope) {
      var levelName = ''
      scope.Levels.forEach(function (level) {
        var objValue = ''
        levelName = level.Name
        level.Values.forEach(function (value) {
          if (level.SelectedIndices) {
            for (let index = 0; index < level.SelectedIndices.length; index++) {
              if (value.Key == level.SelectedIndices[index]) {
                objValue += ', ' + value.Value
              }
            }
          }
        })
        filters[levelName] = objValue.substr(2)
      })
    })

    this.saveDocumentAs(
      {
        type: 'excel07',
        title: 'Cluster Export',
        fileName: 'Clusters.xlsx'
      },
      filters
    )
  },
  columns: [
    {
      text: 'Segment',
      dataIndex: 'SegmentName'
    },
    {
      text: 'Name',
      dataIndex: 'Name',
      locked: true,
      width: 250
    },
    {
      dataIndex: 'Color',
      text: 'Color',
      renderer: function (value) {
        return '<div style="width:100%;background-color:' + value + ';">&nbsp;</div>'
      },
      ignoreExport: true,
      width: 52
    },
    {
      text: 'm2',
      dataIndex: 'SquareMeter',
      renderer: Ext.util.Format.numberRenderer('0,000'),
      width: 50
    },
    {
      text: 'Total Revenue',
      dataIndex: 'TotalRevenue',
      renderer: Ext.util.Format.numberRenderer('0,000'),
      width: 127,
      align: 'right'
    },
    {
      text: 'Unit Sales',
      dataIndex: 'TotalSales',
      renderer: Ext.util.Format.numberRenderer('0,000'),
      width: 105,
      align: 'right'
    },
    {
      text: 'X',
      dataIndex: 'X',
      width: 130,
      align: 'right'
    },
    {
      text: 'X Chart',
      dataIndex: 'XValues',
      width: 130,
      xtype: 'widgetcolumn',
      hidden: true,
      hideable: false,
      ignoreExport: true,
      widget: {
        xtype: 'sparklineline',
        tooltipFormatter: function (sparkline, sparkline2, item) {
          var me = sparkline.up('clusteringmainview')
          var vm = me.getViewModel()
          var categoryNames = vm.get('categoryNames')

          if (_.size(categoryNames) > 0 && _.size(categoryNames[0]) > item.offset) {
            return (
              categoryNames[0][item.offset] +
              ': ' +
              Ext.util.Format.numberRenderer('0,000%')(item.y * 100)
            )
          } else {
            return Ext.util.Format.numberRenderer('0,000%')(item.y * 100)
          }
        }
      }
    },
    {
      text: 'Y',
      dataIndex: 'Y',
      renderer: function (value, meta) {
        meta.style = 'background-color: #f7ffe8;'
        return Ext.util.Format.numberRenderer('0,000')(value)
      },
      width: 130,
      align: 'right'
    },
    {
      text: 'Y Chart',
      dataIndex: 'YValues',
      width: 130,
      xtype: 'widgetcolumn',
      hidden: true,
      hideable: false,
      ignoreExport: true,
      widget: {
        xtype: 'sparklineline',
        tooltipFormatter: function (sparkline, sparkline2, item) {
          var me = sparkline.up('clusteringmainview')
          var vm = me.getViewModel()
          var categoryNames = vm.get('categoryNames')

          if (_.size(categoryNames) > 1 && _.size(categoryNames[1]) > item.offset) {
            return (
              categoryNames[1][item.offset] +
              ': ' +
              Ext.util.Format.numberRenderer('0,000%')(item.y * 100)
            )
          } else {
            return Ext.util.Format.numberRenderer('0,000%')(item.y * 100)
          }
        }
      }
    },
    {
      text: 'Z',
      dataIndex: 'Z',
      renderer: function (value, meta) {
        meta.style = 'background-color: #e9fcff;'
        return Ext.util.Format.numberRenderer('0,000')(value)
      },
      width: 130,
      align: 'right'
    },
    {
      text: 'Z Chart',
      dataIndex: 'ZValues',
      width: 130,
      xtype: 'widgetcolumn',
      hidden: true,
      hideable: false,
      ignoreExport: true,
      widget: {
        xtype: 'sparklineline',
        tooltipFormatter: function (sparkline, sparkline2, item) {
          var me = sparkline.up('clusteringmainview')
          var vm = me.getViewModel()
          var categoryNames = vm.get('categoryNames')

          if (_.size(categoryNames) > 2 && _.size(categoryNames[2]) > item.offset) {
            return (
              categoryNames[2][item.offset] +
              ': ' +
              Ext.util.Format.numberRenderer('0,000%')(item.y * 100)
            )
          } else {
            return Ext.util.Format.numberRenderer('0,000%')(item.y * 100)
          }
        }
      }
    },
    {
      xtype: 'checkcolumn',
      text: 'Test Store',
      dataIndex: 'IsTest',
      ignoreExport: true
    }
  ]
})
