Ext.define('MDM.btlv2.util.SubTotalColum', {
  extend: 'Ext.grid.column.Number',
  alias: ['widget.subtotalnumber'],
  requires: ['Ext.util.Format'],
  defaultFilterType: 'number',
  format: '0,000.000',
  align: 'right',
  width: 75,
  menuDisabled: true,
  sortable: false,
  draggable: false,
  defaultRenderer: function (value) {
    if (!value) return '.'
    if (value == 0) return '.'
    if (value < 0)
      return '<span style="color:red">' + Ext.util.Format.number(value, this.format) + '</span>'
    return Ext.util.Format.number(value, this.format)
  },
  summaryType: 'sum',
  summaryRenderer: function (value) {
    if (value == 0) return '.'
    return '<b>' + Ext.util.Format.number(value, this.format) + '</b>'
  }
})
