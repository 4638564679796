import { SlvySelectStyles } from '../../components'

const selectStyles = {
  ...SlvySelectStyles.auto,
  control: (provided) => ({
    ...provided,
    minHeight: 'auto',
    height: '100%',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    borderColor: '#fff',
    '&:hover': {
      borderColor: '#fff'
    },
    overflow: 'auto',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
    color: '#777',
    '&:hover': {
      color: '#444'
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    height: '100%',
    alignItems: 'center',
    padding: '0 2px 0 0',
    margin: '0 3px 0 0',
    borderRight: '1px solid #dfdfdf',
    borderImage: 'linear-gradient(180deg, #ffffff, #d3d3d3, #ffffff)',
    borderImageSlice: 1,
    cursor: 'pointer',
    color: '#a1a1a1',
    '&:hover': {
      color: '#777'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#333',
    fontSize: '13px',
    whiteSpace: 'nowrap'
  })
}

const menuPortalTarget = document.body

export { selectStyles, menuPortalTarget }
