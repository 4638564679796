import { useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import cx from 'classnames'
import { DemandCardProps } from './DemandCard.types'
import styles from './DemandCard.module.scss'

export default function DemandCard({ data, isOpen, target, onHide }: DemandCardProps) {
  const [isOrderListOpen, setIsOrderListOpen] = useState(false)

  const handleClickOrderList = () => {
    setIsOrderListOpen(!isOrderListOpen)
  }

  const renderOrderItem = (orderDetail) => {
    return (
      <li className="d-flex justify-content-between p-1" style={{ listStyle: 'none' }}>
        <div className="d-flex align-items-center">
          <span
            className="rounded-circle d-inline-block me-1"
            style={{ backgroundColor: '#89ab00', width: 18, height: 18 }}
          />
          <span className="me-1">{orderDetail.SubTitle1}</span>
          <span>
            <i className="slvy-ui-icon-export-box fs-6 fw-semibold" />
          </span>
        </div>
        <span className="text-end">{orderDetail.MaxPallet}P</span>
      </li>
    )
  }

  const renderPaletteItem = (orderDetail) => {
    return (
      <div className="p-1">
        <div>
          <span className="d-flex align-items-center pb-1">
            <span
              className="rounded-circle d-inline-block me-1"
              style={{ backgroundColor: '#89ab00', width: 18, height: 18 }}
            />
            <span>{orderDetail.Title}</span>
          </span>
        </div>
        <div>
          {Array(orderDetail.Items[0].NumPallet).fill(
            <span
              className="d-inline-block me-1"
              style={{ backgroundColor: '#89ab00', width: 18, height: 18 }}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div show={isOpen} target={target} placement="bottom">
      <div className={cx(styles.demandCard, 'rounded-0')} id="popover-basic">
        <div
          className={cx(
            styles.demandCardHeader,
            'd-flex align-items-center bg-white border-bottom border-light-subtle p-2 justify-content-between'
          )}
        >
          <span>{data?.Title}</span>
          <span onClick={onHide}>
            <i className="fa fa-fw fa fa-close" />
          </span>
        </div>
        <div className="p-0">
          <div className="d-flex">
            <div className="w-50 border-end text-center">
              <div className=" border-bottom">{data?.TotalVolume}P</div>
              <div>{data?.TotalWeight} kg</div>
            </div>
            <div className="d-flex justify-content-center align-items-center w-50">
              {data?.TotalPallet}P
            </div>
          </div>
          <div className={cx(styles.demandCardDetails, 'text-white p-1')}>
            <div>{data?.SubTitle}</div>
            <div>{data?.Att1}</div>
            <div>{data?.Att3}</div>
          </div>
          <div className="d-flex justify-content-between p-1">
            <span>Order List</span>
            <span onClick={handleClickOrderList}>
              <i
                className={isOrderListOpen ? 'slvy-ui-icon-caret-up' : 'slvy-ui-icon-caret-down'}
              />
            </span>
          </div>
          <div
            className={cx(
              styles.demandCardControls,
              'd-flex justify-content-between align-items-center px-1 fw-bold fs-5'
            )}
          >
            <span className=" fs-3">
              <i className="slvy-ui-icon-select-all" />
            </span>
            <span>
              <i className="slvy-ui-icon-circle-check fw-bold" />
            </span>
            <span>
              <i className="slvy-ui-icon-info" />
            </span>
            <span>
              <i className="slvy-ui-icon-scenario-change-extra-lt" />
            </span>
            <span>{isOrderListOpen ? null : <i className="slvy-ui-icon-grid-outline" />}</span>
            <span>
              <i className="slvy-ui-icon-trash-can" />
            </span>
          </div>
          <div className={styles.list}>
            {data?.OrderDetails?.map((orderDetail, index) =>
              isOrderListOpen
                ? renderOrderItem(data?.Loads?.[index])
                : renderPaletteItem(orderDetail)
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
