/* MODELS */
require('../src/view/materialedit/model/Base')
require('../src/view/materialedit/model/Cartridge')
require('../src/view/materialedit/model/Dpi')
require('../src/view/materialedit/model/DPIFamilyList')
require('../src/view/materialedit/model/Material')
require('../src/view/materialedit/model/MaterialBUOM')
require('../src/view/materialedit/model/ResourceList')
require('../src/view/materialedit/model/ResourceofProduct')
require('../src/view/materialedit/model/SuplySourceModel')

require('../src/view/materialedit/MainViewModel')

require('../src/view/materialedit/panel/CarttRidge')
require('../src/view/materialedit/panel/DPIGrid')
require('../src/view/materialedit/panel/MaterialEditForm')
require('../src/view/materialedit/panel/ResourceGrid')

require('../src/view/materialedit/MainControllor')
require('../src/view/materialedit/Main')
