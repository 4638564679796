Ext.define('po.cmp.SummaryTip', {
  extend: 'Ext.panel.Panel',
  xtype: 'poSummaryTip',
  alias: 'widget.poSummaryTip',
  scrollable: true,
  checkState: {
    grid_sm_trigger_0: '',
    grid_sm_trigger_1: '',
    grid_sm_trigger_2: ''
  },
  stateful: true,
  stateId: 'summaryTipState',
  getState: function () {
    var state = this.callParent()
    state.checkState = this.checkState
    return state
  },
  applyState: function (state) {
    this.checkState = state.checkState
  },
  applyData: function (data) {
    Ext.apply(data, this.checkState)
    this.update(data)
  },
  listeners: {
    clickToDrillDown: 'clickToDrillDown',
    click: {
      fn: function (event, tile) {
        var panel = this.component

        if (tile.nodeName == 'TD') {
          if (tile.getAttribute('dataindex')) {
            const dataindex = tile.getAttribute('dataindex')
            panel.fireEvent('clickToDrillDown', dataindex)
          }
          return
        }

        const id = tile.getAttribute('id')

        panel.checkState[id] = tile.checked ? 'checked' : ''
        const y = panel.getHeight()
        panel.setHeight(y + 10)
        panel.setHeight(y)
      },

      element: 'el',
      delegate: 'input,td'
    }
  },
  tpl: new Ext.XTemplate(
    '<div class="grid-tooltip-ctn">',
    '<div style="width:100%">',
    '<div class="grid-sum-table">',
    '<input type="checkbox" {grid_sm_trigger_0} id="grid_sm_trigger_0"/><label for="grid_sm_trigger_0"><i class="fa fa-plus-circle" aria-hidden="true"></i></label>',
    '<table class="tbl-tooltip">',
    '<thead>',
    '<tr>',
    '<th colspan="3">{SummaryTip_Header_stok}</th>',
    '</tr>',
    '</thead>',
    '<tbody>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_gunBasiEldekiStok}" ><div class="td-ellipsis" >{SummaryTip_gunBasiEldekiStok}</div></td>',
    '<td class="gr-tar">{[this.formatNumber(values.gunBasiEldekiStok)]}</td>',
    '<td class="gr-tar"></td>',
    '</tr>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_depoAcikSiparis}"> <div class="td-ellipsis">{SummaryTip_depoAcikSiparis}</div></td>',
    '<td class="gr-tar-left drilldowntip" dataindex="depoAcikSiparis"> <div unselectable="on" class="x-grid-cell-inner-tip " style="text-align:right;">{[this.formatNumber(values.depoAcikSiparis)]}</div></td>',
    '<td class="gr-tar" ></td>',
    '</tr>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_magazaBugunkuReplMiktari}"><div class="td-ellipsis">{SummaryTip_magazaBugunkuReplMiktari}</div></td>',
    '<td class="gr-tar"></td>',
    '<td class="gr-tar-left"><div unselectable="on" class="x-grid-cell-inner-tip " style="text-align:right;">{[this.formatNumber(values.magazaBugunkuReplMiktari)]}</div> </td>',
    '</tr>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_magazaAcikSiparis}"><div class="td-ellipsis">{SummaryTip_magazaAcikSiparis}</div></td>',
    '<td class="gr-tar"></td>',
    '<td class="gr-tar-left drilldowntip" dataindex="magazaAcikSiparis"> <div unselectable="on" class="x-grid-cell-inner-tip " style="text-align:right;">{[this.formatNumber(values.magazaAcikSiparis)]}</div></td>',
    '</tr>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_netDepoGirisCikis}"><div class="td-ellipsis">{SummaryTip_netDepoGirisCikis}</div></td>',
    '<td class="gr-tar">{[this.formatNumber(values.netDepoGiris)]}</td>',
    '<td class="gr-tar">{[this.formatNumber(values.netDepoCikis)]}</td>',
    '</tr>',
    '</tbody>',
    '<tfoot>',
    '<tr class="grid-ft">',
    '<td colspan="2" class="gr-title-right">{SummaryTip_netStok}</td>',
    '<td class="gr-tar">{[this.formatNumber(values.netStok)]}</td>',
    '</tr>',
    '</tfoot>',
    '</table>',
    '</div>',
    '<div class="grid-sum-table">',
    '<input type="checkbox" id="grid_sm_trigger_1" {grid_sm_trigger_1} /><label for="grid_sm_trigger_1"><i class="fa fa-plus-circle" aria-hidden="true"></i></label>',
    '<table class="tbl-tooltip">',
    '<thead>',
    '<tr>',
    '<th colspan="3">{SummaryTip_reviewPeriod} ({RevPeriod} {Gunluk}) </th>',
    '</tr>',
    '</thead>',
    '<tbody>',
    '<tr>',
    '<td class="gr-title" data-qtip="{MainGrid_Column_birSonrakiSipariseKadarkiMagazaTalebi_tooltip}" ><div class="td-ellipsis">{SummaryTip_birSonrakiSipariseKadarkiMagazaTalebi}</div></td>',
    '<td class="gr-tar"></td>',
    '<td class="gr-tar-left drilldowntip" dataindex="birSonrakiSipariseKadarkiMagazaTalebi"> <div unselectable="on" class="x-grid-cell-inner-tip " style="text-align:right;">{[this.formatNumber(values.birSonrakiSipariseKadarkiMagazaTalebi)]}</div></td>',
    '</tr>',
    '<tr>',
    '<td data-qtip="{SummaryTip_guvenlikStogu}"><div class="td-ellipsis">{SummaryTip_guvenlikStogu}</div></td>',
    '<td class="gr-tar"></td>',
    '<td class="gr-tar">{[this.formatNumber(values.guvenlikStogu)]}</td>',
    '</tr>',
    '</tbody>',
    '<tfoot>',
    '<tr class="grid-ft">',
    '<td colspan="2" class="gr-title-right">{SummaryTip_yeniTalep}</td>',
    '<td class="gr-tar">{[this.formatNumber(values.yeniTalep)]}</td>',
    '</tr>',
    '</tfoot>',
    '</table>',
    '</div>',
    '<div class="grid-sum-table">',
    '<input type="checkbox" id="grid_sm_trigger_2"  {grid_sm_trigger_2}/><label for="grid_sm_trigger_2"><i class="fa fa-plus-circle" aria-hidden="true"></i></label>',
    '<table class="tbl-tooltip">',
    '<thead>',
    '<tr>',
    '<th colspan="3">{SummaryTip_Header_siparis}</th>',
    '</tr>',
    '</thead>',
    '<tbody>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_siparisIhtiyaci}" colspan="2"><div class="td-ellipsis">{SummaryTip_siparisIhtiyaci}</div></td>',
    '<td class="gr-tar">{[this.formatNumber(values.siparisIhtiyaci)]}</td>',
    '</tr>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_siparisKatsayisi}" colspan="2"><div class="td-ellipsis">{SummaryTip_siparisKatsayisi}</div></td>',
    '<td class="gr-tar">{[this.formatNumber(values.siparisKatsayisi)]}</td>',
    '</tr>',
    '<tr>',
    '<td class="gr-title" data-qtip="{SummaryTip_minSiparisMiktari}" colspan="2"><div class="td-ellipsis">{SummaryTip_minSiparisMiktari}</div></td>',
    '<td class="gr-tar">{[this.formatNumber(values.minSiparisMiktari)]}</td>',
    '</tr>',
    '</tbody>',
    '<tfoot>',
    '<tr class="grid-ft">',
    '<td colspan="2" class="gr-title-right">{SummaryTip_depoSiparisKoli}</td>',
    '<td class="gr-tar">{[this.formatNumber(values.depoSiparisKoli)]}</td>',
    '</tr>',
    '</tfoot>',
    '</table>',
    '</div>',
    '</div>',
    '</div>',
    {
      formatNumber: function (value) {
        return Ext.util.Format.number(value, '0,000')
      },
      formatPercentage: function (value) {
        if (value) {
          return Ext.util.Format.number(value * 100, '0 %')
        }

        return '0 %'
      }
    }
  )
})
