import Calendar from 'rc-calendar'
import Picker from 'rc-calendar/lib/Picker'
import moment from 'moment'
import cx from 'classnames'
import AssortmentInput from '../../../Input'
import { DatePickerProps } from './DatePicker.types'
import styles from './index.module.scss'

const defaultFormat = 'DD/MM/YYYY'
const DatePicker = ({
  value,
  format = defaultFormat,
  inputClass,
  isDisabled,
  onSelect
}: DatePickerProps) => {
  const date = moment(value)
  const selectedDate = value ? date.format(format) : null

  return (
    <Picker
      animation="slide-up"
      calendar={<Calendar format={format} showDateInput={false} onSelect={onSelect} />}
      dropdownClassName={styles.picker}
      value={date}
    >
      {() => (
        <AssortmentInput
          readOnly
          className={cx('form-control-xs', 'full-width', 'text-end', inputClass)}
          isDisabled={isDisabled}
          value={selectedDate}
        />
      )}
    </Picker>
  )
}

export default DatePicker
