/* global Ext */
Ext.define('Ext.grid.filters.filter.DoubleFilter', {
  extend: 'Ext.grid.filters.filter.Base',

  menuItems: ['like', 'notlike'],

  constructor: function (config) {
    var me = this

    var stateful = false

    var filter = {}

    var filterLike

    var filterNotlike

    var value

    var operator

    me.callParent([config])
    value = me.value
    filterLike = me.getStoreFilter('like')
    filterNotlike = me.getStoreFilter('notlike')

    if (filterLike || filterNotlike) {
      stateful = me.active = true

      if (filterLike) {
        me.onStateRestore(filterLike)
      }
      if (filterNotlike) {
        me.onStateRestore(filterNotlike)
      }
    } else {
      if (me.grid.stateful && me.getGridStore().saveStatefulFilters) {
        value = undefined
      }
      me.active = me.getActiveState(config, value)
    }
    filter.like =
      filterLike ||
      me.createFilter(
        {
          operator: 'like',
          value:
            !stateful && value && Ext.isDefined(value.like) ? value.like : null
        },
        'like'
      )
    filter.notlike =
      filterNotlike ||
      me.createFilter(
        {
          operator: 'notlike',
          value:
            !stateful && value && Ext.isDefined(value.notlike)
              ? value.notlike
              : null
        },
        'notlike'
      )

    me.filter = filter

    if (me.active) {
      me.setColumnActive(true)
      if (!stateful) {
        for (operator in value) {
          me.addStoreFilter(me.filter[operator])
        }
      }
    }
  },
  activate: function (showingMenu) {
    var me = this

    var filters = me.filter

    var fields = me.fields

    var filter

    var field

    var operator

    var value

    var isRootMenuItem

    if (me.preventFilterRemoval) {
      return
    }

    for (operator in filters) {
      filter = filters[operator]
      field = fields[operator]
      value = filter.getValue()

      if (value || value === 0) {
        if (field.isComponent) {
          field.setValue(value)
          if (isRootMenuItem === undefined) {
            isRootMenuItem =
              me.owner.activeFilterMenuItem === field.up('menuitem')
          }

          if (!isRootMenuItem) {
            field.up('menuitem').setChecked(true, /* suppressEvents */ true)
          }
        } else {
          field.value = value
        }
        if (!showingMenu) {
          me.addStoreFilter(filter)
        }
      }
    }
  },
  deactivate: function () {
    var me = this

    var filters = me.filter

    var f

    var filter

    var value

    if (!me.countActiveFilters() || me.preventFilterRemoval) {
      return
    }

    me.preventFilterRemoval = true

    for (f in filters) {
      filter = filters[f]

      value = filter.getValue()
      if (value || value === 0) {
        me.removeStoreFilter(filter)
      }
    }

    me.preventFilterRemoval = false
  },

  countActiveFilters: function () {
    var filters = this.filter

    var filterCollection = this.getGridStore().getFilters()

    var prefix = this.getBaseIdPrefix()

    var i = 0

    var filter

    if (filterCollection.length) {
      for (filter in filters) {
        if (filterCollection.get(prefix + '-' + filter)) {
          i++
        }
      }
    }

    return i
  },

  onFilterRemove: function (operator) {
    var me = this

    var value
    if (!me.menu && me.countActiveFilters()) {
      me.active = false
    } else if (me.menu) {
      value = {}
      value[operator] = null
      me.setValue(value)
    }
  },

  onStateRestore: Ext.emptyFn,

  setValue: function (value) {
    var me = this

    var filters = me.filter

    var add = []

    var remove = []

    var active = false

    var filterCollection = me.getGridStore().getFilters()

    var filter

    var v

    var i

    var rLen

    var aLen
    if (me.preventFilterRemoval) {
      return
    }
    me.preventFilterRemoval = true
    if ('notlike' in value) {
      v = value.notlike
      if (v) {
        add.push(filters.notlike)
        filters.notlike.setValue(v)
      } else {
        remove.push(filters.notlike)
      }
    }

    if ('like' in value) {
      v = value.like
      if (v) {
        add.push(filters.like)
        filters.like.setValue(v)
      } else {
        remove.push(filters.like)
      }
    }
    rLen = remove.length
    aLen = add.length
    active = !!(me.countActiveFilters() + aLen - rLen)

    if (rLen || aLen || active !== me.active) {
      filterCollection.beginUpdate()
      if (rLen) {
        for (i = 0; i < rLen; i++) {
          filter = remove[i]

          me.fields[filter.getOperator()].setValue(null)
          filter.setValue(null)
          me.removeStoreFilter(filter)
        }
      }

      if (aLen) {
        for (i = 0; i < aLen; i++) {
          me.addStoreFilter(add[i])
        }
      }

      me.setActive(active)
      filterCollection.endUpdate()
    }

    me.preventFilterRemoval = false
  }
})
