import { useCallback, useEffect } from 'react'
import { isNil } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import { demandTreeTableSlice, loadTreeTableSlice, tuiSlice } from '../../../store/slices'
import useReduxState from '../../useReduxState'
import {
  getDemandTree,
  getLoadTree,
  getRunInfo,
  getServiceStatus,
  setServiceWithConnectionId
} from '../../../store/api/endpoints'
import { getTreeQueryParams } from '../../../utils'

function useSetTuiConnection({ demandTreeTableRef, loadTreeTableRef }) {
  const [{ config, auth, registerMethod }] = useReduxState(tuiSlice.selectSlice)
  const [, loadTreeDispatch] = useReduxState(undefined, loadTreeTableSlice.actions)
  const [, demandTreeTableDispatch] = useReduxState(undefined, demandTreeTableSlice.actions)
  const [triggerSetServiceWithConnectionId] = setServiceWithConnectionId.useMutation({
    fixedCacheKey: 'service-connection-id'
  })
  const [triggerGetLoadTree] = getLoadTree.useMutation({ fixedCacheKey: 'load-tree' })
  const [triggerGetDemandTree] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })
  const [triggerGetRunInfo] = getRunInfo.useLazyQuery()
  const serviceStatus = getServiceStatus.useQueryState()

  const handleSetConnection = useCallback(
    (params) => {
      const { DataConnectionId } = params || {}
      const data = {
        DataConnectionId
      }

      loadTreeDispatch.setLoadStops([])
      demandTreeTableDispatch.setFacilityNames([])

      loadTreeTableRef?.current?.setState((prevState) => ({ ...prevState, rowSelection: {} }))
      demandTreeTableRef?.current?.setState((prevState) => ({ ...prevState, rowSelection: {} }))

      triggerSetServiceWithConnectionId({ dataConnectionId: data.DataConnectionId })
      triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
      triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })

      if (isNil(serviceStatus.data?.PlanInfo.CurrentScenarioID)) return
      triggerGetRunInfo(serviceStatus.data?.PlanInfo.CurrentScenarioID)
    },
    [
      auth.pluginId,
      config,
      demandTreeTableDispatch,
      demandTreeTableRef,
      loadTreeDispatch,
      loadTreeTableRef,
      serviceStatus.data?.PlanInfo.CurrentScenarioID,
      triggerGetDemandTree,
      triggerGetLoadTree,
      triggerGetRunInfo,
      triggerSetServiceWithConnectionId
    ]
  )

  useEffect(() => {
    registerMethod({
      args: [{ name: 'DataConnectionId', type: PluginTypes.int }],
      key: 'setTuiConnection',
      fn: handleSetConnection
    })
  }, [handleSetConnection, registerMethod])
}

export default useSetTuiConnection
