Ext.define('Spui.data.model.SpotList', {
  extend: 'Spui.data.model.Base',
  entityName: 'Spui/SpotList',
  idProperty: 'SpotId',
  proxy: {
    writer: {
      allowSingle: true
    },
    reader: {
      root: 'SpotList',
      totalProperty: 'TotalRowCount'
    }
  },
  fields: [
    { name: 'SpotId', type: 'int' },
    { name: 'MaterialCode', type: 'string' },
    { name: 'MaterialDescription', type: 'string' },
    { name: 'MaterialGroupCode', type: 'string' },
    { name: 'MaterialGroupName', type: 'string' },
    { name: 'SalesPrice', type: 'number' },
    { name: 'UnitofMeasurement', type: 'string' },
    { name: 'UnitsPerPackage', type: 'number' },
    { name: 'MinQuantity', type: 'number' },
    { name: 'MaxQuantity', type: 'number' },
    { name: 'PreStartDate', type: 'date' },
    { name: 'PreEndDate', type: 'date' },
    { name: 'PageIndex', type: 'int' },
    { name: 'PageName', type: 'string' },
    { name: 'CreatedBy', type: 'string' },
    { name: 'CreatedAt', type: 'date' },
    { name: 'IsDeleted', type: 'boolean' },
    { name: 'DeletedBy', type: 'string' },
    { name: 'DeletedAt', type: 'date' },
    { name: 'CategoryManagerCode', type: 'string' },
    { name: 'PuplishedBy', type: 'string' },
    { name: 'PublishDate', type: 'date' },
    { name: 'DaysForApprove', type: 'int' },
    { name: 'DaysForTargetSales', type: 'int' },
    { name: 'StatusId', type: 'int' },
    { name: 'ImgURL', type: 'string' },
    { name: 'Notes', type: 'string' },
    { name: 'Total', type: 'int' },
    { name: 'Waiting', type: 'int' },
    { name: 'Delegated', type: 'int' },
    { name: 'OrderRecommendationParent', type: 'number' },
    { name: 'OrderRecommendationCurrent', type: 'number' },
    { name: 'OrderRecommendationChild', type: 'number' },
    { name: 'Approved', type: 'int' },
    { name: 'FinalOneri_DC_UpdatedbyDC', type: 'number' },
    { name: 'IsEditable', type: 'boolean' },
    { name: 'Returnable', type: 'boolean' }
  ]
})
