import {
  MouseEvent,
  SubmitEvent,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
  forwardRef
} from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

// eslint-disable-next-line
import 'ag-grid-enterprise'
// eslint-disable-next-line
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'

import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'
import { CellEditRequestEvent, GridOptions, ICellEditor } from 'ag-grid-community'
import { slvyToast, SlvyFormSelect } from '@/components'
import {
  getEditingDirtyCells,
  getEditingParams,
  getEnableInfo,
  getMappedRowData,
  isWarningThresholdExceeded,
  getIsLookupQuery,
  getIsSubstitute,
  getIsLookupDataField,
  getNumberCellEditorParams,
  getControlValue,
  getSelectedRowsAfterFilterAndSort
} from '../../../helpers'
import { getCalculatedNewValue, getFullLookupFieldData } from './helpers'
import { prepareUpdateData } from '../../../helpers/index'
import {
  useFirstRowDataAfterFilterAndSort,
  useGetColDefByField,
  useThresholdDidExceeded
} from '../../../hooks'
import RequiredFields from '../../RequiredFields'
import { useCreateColumnDefs } from './hooks'

import { constantGridProps, DATA_TYPES, numberTypes } from '../../../constants'
import {
  MassUpdateNumberTypeOptions,
  editableFields,
  constantMassUpdateGridProps,
  getMassUpdateNumberTypeOptionsByLocale
} from './constants'

import { IOptionLabelValue } from '@/components/SlvyFormSelect/SlvyFormSelect.types'
import { AG_GRID_ENUMS } from '../../../enums'
import {
  IRowData,
  TfieldConfigs,
  TrowDataValue,
  GridRef,
  ICustomRowInfo,
  SlvyColDef,
  TcustomConfigs,
  LocaleText
} from '../../../types'
import {
  InvalidCondition,
  MassUpdateModalProps,
  RequestParams,
  RequestParamsEvent,
  ValidateRowReturnType,
  ValidRowItem
} from './MassUpdateModal.types'
import { Option } from '../../CellEditors/LookupFieldCellEditor/LookupFieldCellEditor.types'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import styles from './MassUpdateModal.module.scss'
import { getLicenseKey } from '@/helpers'

const SLVY_AG_GRID_LICENSE_KEY: string = getLicenseKey('aggrid')

if (SLVY_AG_GRID_LICENSE_KEY) {
  LicenseManager.setLicenseKey(SLVY_AG_GRID_LICENSE_KEY)
} else {
  // eslint-disable-next-line no-console
  console.error('YOU MUST HAVE AG-GRID LICENSE!')
}

const { LOOK_UP_FIELD_DATA, CUSTOM_ROW_INFO, DIRTY_CELLS, ROW_ID } = AG_GRID_ENUMS

const MassUpdateModal = forwardRef((props: MassUpdateModalProps, ref) => {
  const {
    isVisible,
    activeField,
    dataTypeDefinitions,
    mainGridContext,
    mainGridFirstRowData,
    applyUpdate,
    onClose,
    onCancel
  } = props

  const mainGridRef = ref as GridRef

  const [isGridVisible, setIsGridVisible] = useState(true)

  const [numberTypeOptionValue, setNumberTypeOptionValue] = useState<IOptionLabelValue['value']>(
    MassUpdateNumberTypeOptions[0].value
  )

  const gridRef = useRef<AgGridReact<IRowData>>(null)

  const localeText = (mainGridRef?.current?.api?.getGridOption?.('localeText') as LocaleText) || {}
  const {
    invalidRows: invalidRowsLocale,
    valueAreNotAllowedToBeBlank: valueAreNotAllowedToBeBlankLocale,
    valueCannotBeGreaterThan: valueCannotBeGreaterThanLocale,
    valueCannotBeLowerThan: valueCannotBeLowerThanLocale,
    massUpdate: massUpdateLocale,
    type: typeLocale,
    value: valueLocale,
    cancel: cancelLocale,
    save: saveLocale
  } = localeText

  const { getColDefByField } = useGetColDefByField(gridRef)
  const { getColDefByField: getColDefByFieldForMainGrid } = useGetColDefByField(
    mainGridRef as GridRef
  )

  const { thresholdDidExceeded } = useThresholdDidExceeded(mainGridRef)

  const selectedRows = useMemo(() => {
    return getSelectedRowsAfterFilterAndSort(mainGridRef)
  }, [mainGridRef])

  const isPercentage = numberTypeOptionValue === 'percentage'

  // get the first column that matches with activeField
  const getInitialMainGridFirstColDef = useCallback((): SlvyColDef => {
    return getColDefByFieldForMainGrid(activeField)
  }, [activeField, getColDefByFieldForMainGrid])

  const mainGridFirstColDef = useMemo(() => {
    const initialMainGridFirstColDef = getInitialMainGridFirstColDef()

    const {
      field,
      fieldConfig: { dataType },
      editing,
      formatString = ''
    } = initialMainGridFirstColDef?.customInfo

    const doesLookupQueryExist = getIsLookupQuery(initialMainGridFirstColDef?.customInfo)
    const isLookupDataField = getIsLookupDataField(initialMainGridFirstColDef?.customInfo)
    const isNumber = numberTypes.some((numberType) => numberType === dataType)
    const isNotLookupNumber: boolean = !doesLookupQueryExist && !isLookupDataField && isNumber

    if (!isPercentage || !isNotLookupNumber) {
      // This is only for removing "step"!
      return {
        ...initialMainGridFirstColDef,
        customInfo: {
          ...initialMainGridFirstColDef.customInfo,
          editing: { ...editing, step: null, stepField: null }
        },
        ...getNumberCellEditorParams({
          dataType,
          editing: { ...editing, step: null, stepField: null },
          formatString
        })
      }
      // return initialMainGridFirstColDef
    }

    const numbers: number[] = selectedRows.map((selectedRow) => Number(selectedRow[field]))

    const lowestRowValue = Math.min(...numbers)
    const greatestRowValue = Math.max(...numbers)

    const calcMinValue = getControlValue({
      value: editing.minValue,
      valueField: editing?.minValueField,
      cellData: selectedRows.find((selectedRow) => Number(selectedRow[field]) === lowestRowValue)
    })

    const calcMaxValue = getControlValue({
      value: editing.maxValue,
      valueField: editing?.maxValueField,
      cellData: selectedRows.find((selectedRow) => Number(selectedRow[field]) === greatestRowValue)
    })

    // Clear "minValueField" and "maxValueField" from "editing"
    // Because we're calculating those fields above
    const newEditing: TcustomConfigs['editing'] = {
      ...editing,
      minValueField: '',
      maxValueField: '',
      minValue: calcMinValue,
      maxValue: calcMaxValue
    }

    // if its percentage
    if (isPercentage) {
      const lowestDiff = Math.abs(lowestRowValue / 100)
      const greatestDiff = Math.abs(greatestRowValue / 100)
      const minPercent =
        lowestDiff >= greatestDiff
          ? Math.ceil(calcMinValue / lowestDiff)
          : Math.ceil(calcMinValue / greatestDiff)
      const maxPercent =
        lowestDiff >= greatestDiff
          ? Math.floor(calcMaxValue / lowestDiff)
          : Math.floor(calcMaxValue / greatestDiff)
      newEditing.minValue = minPercent
      newEditing.maxValue = maxPercent
    }

    // // // if its increment
    // const maxIncrement = Math.floor(calcMaxValue - greatestRowValue)
    //
    // newEditing.minValue = 0.1
    // newEditing.maxValue = maxIncrement

    return {
      ...initialMainGridFirstColDef,
      customInfo: {
        ...initialMainGridFirstColDef.customInfo,
        editing: newEditing,
        rawEditing: {
          ...newEditing,
          minValue: calcMinValue,
          maxValue: calcMaxValue
        }
      },
      ...getNumberCellEditorParams({
        dataType,
        editing: newEditing,
        formatString
      })
    }
  }, [getInitialMainGridFirstColDef, selectedRows, isPercentage])

  const {
    fieldConfig: { dataType },
    editing: { allowBlank, warningThreshold, lookupDataField }
  } = mainGridFirstColDef?.customInfo

  const { columnDefs } = useCreateColumnDefs({ mainGridFirstColDef })

  const pluginData = useMemo(() => {
    const row = {
      [activeField]: dataType ? (DATA_TYPES[dataType] as never) : (null as never)
    }

    return [row]
  }, [activeField, dataType])

  const fieldConfigs = useMemo(
    (): TfieldConfigs => [{ dataType, fieldName: activeField }],
    [dataType, activeField]
  )

  const { getFirstRowDataAfterFilterAndSort } = useFirstRowDataAfterFilterAndSort({
    gridRef: mainGridRef,
    checkIsSelected: true
  })

  const selectedFirstRowData = useMemo(() => {
    // TODO: @v2 mainGridFirstRowData is probably unnecessary
    return getFirstRowDataAfterFilterAndSort() || mainGridFirstRowData
  }, [getFirstRowDataAfterFilterAndSort, mainGridFirstRowData])

  const rowData = useMemo((): IRowData[] => {
    return getMappedRowData({ pluginData, fieldConfigs, editableFields }).map((row) => {
      type OptionalFields = {
        [CUSTOM_ROW_INFO]: ICustomRowInfo
      } & { [key: string]: Option[] }

      const optionalFields: Partial<OptionalFields> = {}
      if (lookupDataField) {
        optionalFields[CUSTOM_ROW_INFO] = {
          ...selectedFirstRowData[CUSTOM_ROW_INFO],
          [LOOK_UP_FIELD_DATA]: Boolean(lookupDataField)
        }
        optionalFields[lookupDataField] = getFullLookupFieldData(selectedRows, lookupDataField)
      }

      const finalRow = {
        ...selectedFirstRowData,
        ...optionalFields,
        ...row
      }

      return {
        ...finalRow,
        [CUSTOM_ROW_INFO]: {
          ...finalRow[CUSTOM_ROW_INFO],
          [ROW_ID]: uuidv4()
        }
      }
    })
  }, [pluginData, fieldConfigs, lookupDataField, selectedFirstRowData, selectedRows])

  // if one of the cells is look up, all the columns must be "look up" field,
  // therefore, it is ok to use the first column
  const isSubstitute = getIsSubstitute(mainGridFirstColDef?.customInfo)
  const doesLookupQueryExist = getIsLookupQuery(mainGridFirstColDef?.customInfo)
  const isLookupDataField = getIsLookupDataField(mainGridFirstColDef?.customInfo)
  const isNumber = numberTypes.some((numberType) => numberType === dataType)
  const isLookUp = isSubstitute || isLookupDataField || doesLookupQueryExist

  const isLookupNumber: boolean = (doesLookupQueryExist || isLookupDataField) && isNumber

  const isNotLookupNumber: boolean = !doesLookupQueryExist && !isLookupDataField && isNumber

  const validateRow = useCallback(
    ({ row }: { row: ValidRowItem }) => {
      const result: ValidateRowReturnType = {
        isValid: true,
        invalidConditions: [],
        rowRequestParams: null
      }

      const newEvent: RequestParamsEvent = {
        node: { id: row.data[CUSTOM_ROW_INFO][ROW_ID] },
        data: row.data,
        colDef: row.colDef,
        newValue: row.newValue,
        oldValue: row.oldValue
      }

      const firstColDef = getColDefByField(activeField)

      // TODO: use firstColDef if its a lookup field
      const $colDef = doesLookupQueryExist ? firstColDef : newEvent.colDef

      const { customInfo } = $colDef
      const editingParams = getEditingParams({
        oldValue: newEvent.oldValue,
        newValue: newEvent.newValue,
        customInfo
      })
      const {
        newValueParams,
        newValueParams: { id: newValueId },
        oldValueParams,
        oldValueParams: { id: oldValueId }
      } = editingParams

      // check with data's default value with newValueId whether it is empty
      const isEmpty = DATA_TYPES[dataType] === newValueId || newValueId === null

      if (!allowBlank && isEmpty) {
        gridRef?.current?.api.stopEditing()
        result.isValid = false
        result.invalidConditions.push({
          type: 'allowBlank',
          msg: valueAreNotAllowedToBeBlankLocale
        })
        return result
      }

      const { currentDirtyCell, otherDirtyCells } = getEditingDirtyCells({
        field: activeField,
        id: newEvent.node.id as string,
        dirtyCells: newEvent.data[CUSTOM_ROW_INFO][DIRTY_CELLS]
      })

      const unTouchedValueParams = currentDirtyCell
        ? currentDirtyCell.unTouchedValueParams
        : oldValueParams

      const requestParams: RequestParams = {
        event: newEvent,
        otherDirtyCells,
        isSubstitute,
        newValueParams,
        oldValueParams,
        unTouchedValueParams
      }

      if (isNotLookupNumber) {
        const editorParams = columnDefs[0]?.cellEditorParams?.({ data: row.data })

        // TODO: For the Total Claim Amount column,
        // if you enter `1` as input, some of the colums are updated but some not.
        // if you enter `1` as input as single update (not mass) for the columns those are not updated with mass update will be updated!
        // https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/1/menu/663a2a68eb49afc7fad6e159/page/663e08660e0722dbf6316b5f
        // if (editorParams?.step && !isPercentage) {
        //   const precision = editorParams?.precision ?? 0
        //   const newValueMode = (newValueId as number) % editorParams.step
        //   const newValueModeFixed = newValueMode.toFixed(precision)
        //   const oldValueMode = (oldValueId as number) % editorParams.step
        //   const oldValueModeFixed = oldValueMode.toFixed(precision)
        //
        //   if (!(newValueMode === 0 || newValueModeFixed === oldValueModeFixed)) {
        //     result.isValid = false
        //     result.invalidConditions.push({
        //       type: 'step',
        //       msg: `Please enter a valid number. Step is: ${editorParams.step}`
        //     })
        //   }
        // }

        if (editorParams?.max) {
          const maxControlValue = isPercentage
            ? customInfo?.rawEditing?.maxValue ?? editorParams.max
            : editorParams.max

          if (!((newValueId as number) <= maxControlValue)) {
            result.isValid = false
            result.invalidConditions.push({
              type: 'max',
              msg: valueCannotBeGreaterThanLocale(editorParams.max)
            })
          }
        }

        if (editorParams?.min) {
          const minControlValue = isPercentage
            ? customInfo?.rawEditing?.minValue ?? editorParams.min
            : editorParams.min

          if (!((newValueId as number) >= minControlValue)) {
            result.isValid = false
            result.invalidConditions.push({
              type: 'min',
              msg: valueCannotBeLowerThanLocale(editorParams.min)
            })
          }
        }

        if (result.isValid) {
          // TODO: @v2 Check isThresholdExceeded is in while checking min, max, step
          const isThresholdExceeded = isWarningThresholdExceeded({
            warningThreshold,
            oldValue: (unTouchedValueParams as Option).id,
            newValue: newValueId as number
          })
          if (isThresholdExceeded) {
            result.isValid = false
            result.invalidConditions.push({
              type: 'threshold',
              rowRequestParams: requestParams
            })
          }
        }
      }

      if (result.isValid) {
        result.rowRequestParams = requestParams
      }

      return result
    },
    [
      getColDefByField,
      activeField,
      doesLookupQueryExist,
      dataType,
      allowBlank,
      isSubstitute,
      isNotLookupNumber,
      columnDefs,
      isPercentage,
      warningThreshold,
      valueAreNotAllowedToBeBlankLocale,
      valueCannotBeGreaterThanLocale,
      valueCannotBeLowerThanLocale
    ]
  )

  const getIsCellEditable = useCallback((row, colDef) => {
    return getEnableInfo(row, colDef?.customInfo?.editing?.editableCondition)
  }, [])

  const onCellEditRequest = useCallback(
    (event: CellEditRequestEvent<IRowData, TrowDataValue>) => {
      const newValue = event.newValue as TrowDataValue

      const invalidRows: { invalidConditions: InvalidCondition[] }[] = []

      const validDataToUpdate: IRowData[] = []

      const isCellEditable = getIsCellEditable(
        selectedRows?.[0] ?? null,
        getColDefByField(activeField)
      )

      if (!isCellEditable) {
        return
      }

      selectedRows.forEach((selectedRow: IRowData) => {
        const oldValue = selectedRow[activeField]
        const calculatedNewValue = !isNotLookupNumber
          ? newValue
          : getCalculatedNewValue({
              numberTypeOptionValue: numberTypeOptionValue as string,
              oldValue: oldValue as number,
              newValue: newValue as number
            })

        const validRowItem: ValidRowItem = {
          data: selectedRow,
          newValue: calculatedNewValue,
          oldValue,
          colDef: mainGridFirstColDef
        }

        const { isValid, invalidConditions, rowRequestParams } = validateRow({
          row: validRowItem
        })

        if (isValid) {
          validDataToUpdate.push(prepareUpdateData(rowRequestParams as RequestParams))
        } else {
          invalidRows.push({ invalidConditions })
        }
      })

      const invalidMessages: string[] = []
      const thresholdInvalidRows: InvalidCondition[] = []

      invalidRows.forEach((row) => {
        row.invalidConditions.forEach((condition) => {
          if (condition.type === 'threshold') {
            thresholdInvalidRows.push(condition)
          } else {
            invalidMessages.push(condition.msg as string)
          }
        })
      })

      if (invalidMessages.length) {
        const timeout = invalidMessages.length < 5 ? 5000 : 10000
        slvyToast.warning({
          component: <RequiredFields messages={invalidMessages} title={invalidRowsLocale} />,
          options: { autoClose: timeout }
        })
      }

      if (!invalidMessages.length && thresholdInvalidRows.length) {
        thresholdDidExceeded(warningThreshold, () => {
          const dataToUpdate: IRowData[] = []
          thresholdInvalidRows.forEach(({ rowRequestParams }) => {
            dataToUpdate.push(prepareUpdateData(rowRequestParams as RequestParams))
          })
          // run update for once
          if (dataToUpdate.length) {
            applyUpdate(dataToUpdate)
          }
        })
      }

      // run update for once
      if (validDataToUpdate.length) {
        applyUpdate(validDataToUpdate)
      }

      onClose()
    },
    [
      getIsCellEditable,
      selectedRows,
      getColDefByField,
      activeField,
      onClose,
      isNotLookupNumber,
      numberTypeOptionValue,
      mainGridFirstColDef,
      validateRow,
      invalidRowsLocale,
      thresholdDidExceeded,
      warningThreshold,
      applyUpdate
    ]
  )

  // Check each version update, this function is very sensitive, and easy to break
  const getEditorValue = () => {
    const instances = gridRef?.current?.api?.getCellEditorInstances?.() || []
    const instance = instances?.[0] as Partial<ICellEditor>

    if (doesLookupQueryExist || isLookupDataField) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return instance?.value
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (instance?.cellEditorInput) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return instance?.cellEditorInput?.getValue?.()
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (instance?.cellEditorParams?.editorName === 'booleanIcon') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return instance?.value
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (instance?.eCheckbox) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return instance?.eCheckbox?.selected
    }

    return null
  }

  const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    onCellEditRequest({ newValue: getEditorValue() } as CellEditRequestEvent<
      IRowData,
      TrowDataValue
    >)
  }

  const handleSubmit = (event: SubmitEvent) => {
    event.preventDefault()
    handleSave(event)
  }

  const onGridReady = useCallback(() => {
    gridRef?.current?.api?.startEditingCell?.({
      colKey: activeField,
      rowIndex: 0
    })
  }, [activeField])

  const newContext = useMemo(() => {
    return {
      ...mainGridContext,
      theme: 'alpine'
    }
  }, [mainGridContext])

  useEffect(() => {
    if (!isNotLookupNumber) {
      return
    }

    setIsGridVisible(false)

    const gridVisibleTimeout = setTimeout(() => {
      setIsGridVisible(true)
    })

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(gridVisibleTimeout)
  }, [isNotLookupNumber, numberTypeOptionValue])

  const gridProps: GridOptions<IRowData> & {
    ref: typeof gridRef
  } = {
    ...constantGridProps,
    ...constantMassUpdateGridProps,
    localeText,
    ref: gridRef,
    rowData,
    context: newContext,
    columnDefs,
    dataTypeDefinitions,
    onGridReady,
    onCellEditRequest
  }

  return (
    <Modal centered className={styles.slvyMassUpdateModal} show={isVisible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {massUpdateLocale}: {activeField}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={styles.slvyMassUpdateForm} role="form" onSubmit={handleSubmit}>
          {isNotLookupNumber ? (
            <SlvyFormSelect
              data={getMassUpdateNumberTypeOptionsByLocale(localeText)}
              label={typeLocale}
              labelClass="justify-content-end"
              name="typeId"
              value={numberTypeOptionValue}
              onSelect={setNumberTypeOptionValue as any}
            />
          ) : null}
          <Form.Group as={Row} className="form-group">
            {isLookupNumber ? null : (
              <Form.Label
                className="d-flex align-items-center justify-content-end"
                column={'md' as 'sm'}
                sm={2}
              >
                {valueLocale}
              </Form.Label>
            )}
            <Col sm={isLookupNumber ? 12 : 10} style={{ height: 42 }}>
              <div
                className={cx(
                  'w-100',
                  'ag-theme-alpine',
                  'position-relative',
                  styles.slvyMassUpdateGrid,
                  { [styles.slvyDisabledInteractionRows]: isLookUp }
                )}
                style={{ height: '100%' }}
              >
                {isGridVisible ? <AgGridReact<IRowData> {...gridProps} /> : null}
              </div>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={onCancel}>
          {cancelLocale}
        </Button>
        <Button type="submit" variant="success" onClick={handleSave}>
          {saveLocale}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default MassUpdateModal
