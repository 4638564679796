import React from 'react'
import { Popover, Button } from 'react-bootstrap'
import _ from 'lodash'
import AssortmentDropdown from '../Dropdown'

export default class CopyFromPlanningSegments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProduct: {
        key: '',
        value: ''
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  onFilterChanged(dropdownKey, { key: selectedKey = '', value: selectedValue = '' }) {
    this.setState({
      selectedProduct: {
        key: selectedKey,
        value: selectedValue
      }
    })
  }

  executeCopyFromPlanningSegments() {
    const {
      props: { copyFromPlanningSegmentsHandler = () => {} },
      state: { selectedProduct }
    } = this

    copyFromPlanningSegmentsHandler(selectedProduct)
  }

  render() {
    const {
      props: { isShown = false, options = [], title },
      state: { selectedProduct: { key: selectedKey = '', value: selectedValue = '' } = {} }
    } = this

    const _options = [...options].map(({ Id = null, Name = '' }) => ({ key: Id, value: Name }))

    return isShown ? (
      <Popover
        className="popover-copy-from-planning-segments"
        id="popover-copy-from-planning-segments"
        placement="bottom"
      >
        <Popover.Header as="h3" className="mb-0 pb-2">
          {title ?? 'Copy From Planning Segments'}
        </Popover.Header>
        <Popover.Body>
          <span className="form-inline d-flex">
            <div className="form-group mb-3 flex-grow-1">
              <AssortmentDropdown
                canAddEmpty
                options={_options}
                selected={selectedKey}
                onFilterChanged={this.onFilterChanged.bind(this)}
              />
            </div>
            <Button
              disabled={!selectedValue}
              size="sm"
              type="button"
              variant="outline-dark"
              onClick={this.executeCopyFromPlanningSegments.bind(this)}
            >
              <span className="slvy-ui-icon-check-circle-regular" />
            </Button>
          </span>
        </Popover.Body>
      </Popover>
    ) : null
  }
}
