import { Form } from 'react-bootstrap'
import { SlvyFormSelect } from '@/components'
import { getPluginVariables } from '@/helpers/mapper'
import { IGenerateKeyProps } from './GenerateKey.types'
import { IVariables } from '../../../Common.types'
import { getSortedVariables } from '../helpers'

export const mapVariables = (variables: IVariables) => {
  const newVariables = [{ label: 'Select', value: '' }]
  Object.keys(variables).forEach((key) => {
    const { type } = variables[key]
    if (type === 'string') {
      newVariables.push({ label: `${key} (${type})`, value: key })
    }
  })
  return newVariables
}

const GenerateKey = ({
  onSave,
  params: { variable } = {},
  customVariables,
  plugins,
  assignees
}: IGenerateKeyProps) => {
  const handleChange = (newVariable: string) => onSave({ variable: newVariable })

  const variables = mapVariables({
    ...getPluginVariables(plugins, assignees),
    ...customVariables
  })

  const sortedVariables = getSortedVariables(variables)

  const selectValue = variables.find((item) => item.value === variable)?.value ?? null

  return (
    <Form aria-label="Generate key" className="p-3">
      <SlvyFormSelect
        noRowMarginBottom
        columns={{ label: 12, select: 12 }}
        data={sortedVariables}
        label="Variable"
        placeholder="select"
        size="sm"
        value={selectValue}
        onSelect={(newOptionVal: string) => handleChange(newOptionVal)}
      />
    </Form>
  )
}

export default GenerateKey
