import { useCallback, useMemo, useState } from 'react'
import { SlvyTable } from '@/components'
import { slvyToast } from '@/components'
import BaseModal from '../../../BaseModal'
import { useReduxState } from '../../../../hooks'
import { sessionSlice } from '../../../../store/slices'
import { getSessions, getSnapshotDiff } from '../../../../store/api/endpoints'
import IconButton from '../../../IconButton'
import DiffScreenModal from '../DiffScreenModal'

function SessionListModal() {
  const [selectedId, setSelectedId] = useState(null)
  const [sessionState, { setIsDiffScreenModalOpen, setIsSessionListModalOpen }] = useReduxState(
    sessionSlice.selectSlice,
    sessionSlice.actions
  )
  const [triggerGetSnapshotDiff] = getSnapshotDiff.useLazyQuery()
  const sessionListResponse = getSessions.useQueryState()

  const handleCloseButton = () => {
    setIsSessionListModalOpen(false)
  }

  const handleDiffCellClick = useCallback(
    (id) => {
      triggerGetSnapshotDiff({ id })
        .then((response) => {
          const diffData = response?.data?.result?.DiffData
          if (diffData && diffData.length > 0) {
            setSelectedId(id)
            setIsDiffScreenModalOpen(true)
          }
        })
        .catch(() => {
          slvyToast.warning({
            title: 'Diff Screen',
            message: 'Snapshot diff data is not available.'
          })
        })
    },
    [setIsDiffScreenModalOpen, triggerGetSnapshotDiff]
  )

  const diffCell = useCallback(
    ({ row }) => {
      return (
        <IconButton tooltipText="Open Diff" onClick={() => handleDiffCellClick(row.original.Id)}>
          <i className="slvy-ui-icon-diff fs-6" />
        </IconButton>
      )
    },
    [handleDiffCellClick]
  )

  const columns = useMemo(
    () => [
      {
        header: '',
        id: 'actioncolumn',
        cell: diffCell,
        maxSize: 42
      },
      {
        header: 'Description',
        accessorKey: 'Description',
        minSize: 200,
        size: 200,
        maxSize: 100
      },
      {
        header: 'User Name',
        accessorKey: 'Username',
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Start Time',
        accessorKey: 'StartDate',
        maxSize: 150,
        meta: {
          format: 'YYYY-MM-DD',
          dataType: 'datetime'
        }
      },
      {
        header: 'Distance',
        accessorKey: 'Distance',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Duration',
        accessorKey: 'Duration',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'NumStops',
        accessorKey: 'NumStops',
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Pallet (Cumulative)',
        accessorKey: 'PalletCumulative',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Pallet (RoundUp)',
        accessorKey: 'PalletRoundUp',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Planned (Percentage)',
        accessorKey: 'PlannedPercentage',
        meta: {
          format: '0.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Planned Trucks',
        accessorKey: 'PlannedTrucks',
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Total Case',
        accessorKey: 'TotalCase',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Transport Cost',
        accessorKey: 'TransportCost',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Weight',
        accessorKey: 'Weight',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'CostPerPallet (Cumulative)',
        accessorKey: 'CostPerPalletCumulative',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'CostPerPallet (Gross)',
        accessorKey: 'CostPerPalletGross',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'CostPerPallet (RoundUp)',
        accessorKey: 'CostPerPalletRoundUp',
        meta: {
          format: '0,000.00'
        },
        minSize: 100,
        size: 100,
        maxSize: 200
      }
    ],
    [diffCell]
  )

  return (
    <>
      <BaseModal isOpen={sessionState.isSessionListModalOpen} size="xl" onClose={handleCloseButton}>
        <BaseModal.Header>
          <BaseModal.Title>Sessions</BaseModal.Title>
        </BaseModal.Header>
        <BaseModal.Body>
          <SlvyTable columns={columns} data={sessionListResponse?.data} theme="sencha-grid" />
        </BaseModal.Body>
      </BaseModal>

      <DiffScreenModal selectedId={selectedId} />
    </>
  )
}

export default SessionListModal
