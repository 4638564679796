import createSagaMiddleware from 'redux-saga'
import createOidcMiddleware, { loadUser, reducer as oidcReducer } from 'redux-oidc'
import { configureStore, combineReducers, PreloadedState } from '@reduxjs/toolkit'
import reduceReducers from 'reduce-reducers'
import mySaga from '../sagas'
import createMiddleware3 from '../crudoptV3/middleware/clientMiddleware'
import ApiClient from '../crudoptV3/libs/apiClient'

import userManager from '../utils/userManager'
import setting from './slices/setting'
import exportData from './slices/exportData'
import copy from './slices/copy'
import maximize from './slices/maximize'
import logger from './slices/logger'
import localData from './slices/localData'
import pageConfig from './slices/pageConfig'
import menus from './slices/menus'
import notifications from './slices/notifications'
import containerRelation from './slices/containerRelation'
import pluginlist from './slices/pluginlist'
import relationReducers from '../libs/relation/reducers'
import { CRUDOptV3 } from '../crudoptV3'

const client = new ApiClient()

// create the middleware with the userManager
const oidcMiddleware = createOidcMiddleware(userManager)

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// Create the root reducer independently to obtain the RootState type
export const rootReducer = combineReducers({
  oidc: oidcReducer,
  model3: reduceReducers(CRUDOptV3, localData),
  relationReducers,
  containerRelation,
  pluginlist,
  exportData,
  copy,
  maximize,
  setting,
  logger,
  pageConfig,
  menus,
  notifications
})

export const middleware = (getDefaultMiddleware) => {
  return getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  })
    .concat(createMiddleware3(client))
    .concat(oidcMiddleware)
    .concat(sagaMiddleware)
}

// eslint-disable-next-line no-use-before-define
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware
  })
}

const store = setupStore()

// load oidc user
loadUser(store, userManager)

window.dashboardStore = store
// then run the saga
sagaMiddleware.run(mySaga)

export default store
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
