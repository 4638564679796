import { useCallback, useRef } from 'react'
import { IRowNode, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community'
import {
  omitCustomFields,
  getSelectedRowsAfterFilterAndSort,
  updateWithSubstituteNewValue
} from '../../helpers'
import { GridRef, IRowData, IRowDataGrid, IRowDataGridOrNull } from '../../types'
import { RowSelectionProps, TNode } from './useRowSelection.types'

function useRowSelection(props: RowSelectionProps) {
  const selectedNodesRef = useRef<IRowNode[]>([])
  const { multiRowSelectedEventRef, rowSelectedEventRef, selectionType } = props

  const fireMultiRowSelected = useCallback(
    (selectedRows: IRowDataGrid[]) => {
      multiRowSelectedEventRef?.current?.handleMultiRowSelected({ selectedRows })
    },
    [multiRowSelectedEventRef]
  )

  const fireRowSelected = useCallback(
    (lastSelected: IRowDataGridOrNull, rowIndex: number | null) => {
      rowSelectedEventRef?.current?.handleRowSelected(lastSelected, rowIndex)
    },
    [rowSelectedEventRef]
  )

  const isSelectionTypeSingle = selectionType === 'Row'

  const executeFireRowSelected = useCallback(
    (node: TNode) => {
      if (node) {
        let data = updateWithSubstituteNewValue({ data: node.data, dirtyCells: [] })
        data = omitCustomFields(data)
        fireRowSelected(data, node.rowIndex)
      } else {
        fireRowSelected(null, null)
      }
    },
    [fireRowSelected]
  )

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      // After mass update and refresh data on 100k row data
      // row id is changing for each row
      // it triggers onRowSelected for `deselect` on 100k row data, Sencha-Grid does not dispatch onRowSelected after row data change
      // this makes ag-grid frozen
      // https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/9/menu/65e0930ff13b15ec30553d05/page/65e59d2a8f659a68690445b7
      // onSelectionChanged triggers for once not for each row selection/deselection.
      // We wanted to dispatch row selected event for once in case rowDataChanged

      // Case: click headerCheckbox while massUpdate is enabled and selectionType is Row
      if (event.source === 'uiSelectAll' || event.source === 'rowDataChanged') {
        executeFireRowSelected(event.api.getSelectedNodes().at(-1) ?? null)
      }

      if (isSelectionTypeSingle) {
        return
      }

      // Trigger MultiRowSelected
      const selectedRows: IRowDataGrid[] = []
      getSelectedRowsAfterFilterAndSort({ current: event } as GridRef).forEach((selectedRow) => {
        let data = updateWithSubstituteNewValue({ data: selectedRow, dirtyCells: [] })
        data = omitCustomFields(data)
        selectedRows.push(data)
      })
      fireMultiRowSelected(selectedRows)
    },
    [executeFireRowSelected, fireMultiRowSelected, isSelectionTypeSingle]
  )

  const onRowSelected = useCallback(
    (event: RowSelectedEvent<IRowData>) => {
      // After mass update and refresh data on 100k row data
      // row id is changing for each row
      // it triggers onRowSelected for `deselect` on 100k row data, Sencha-Grid does not dispatch onRowSelected after row data change
      // this makes ag-grid frozen
      // https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/9/menu/65e0930ff13b15ec30553d05/page/65e59d2a8f659a68690445b7
      if (event.source === 'uiSelectAll' || event.source === 'rowDataChanged') {
        return
      }

      if (event.node.isSelected()) {
        executeFireRowSelected(event.node)
      } else {
        const currentNode = selectedNodesRef.current.some((node) => node.id === event.node.id)
        if (currentNode) {
          executeFireRowSelected(null)
        }
      }

      selectedNodesRef.current = event.api.getSelectedNodes()
    },
    [executeFireRowSelected]
  )

  return { onRowSelected, onSelectionChanged }
}

export default useRowSelection
