import _ from 'lodash'
import { options } from '../../helpers/leafletOptions'

let { tiles = [], theme = [] } = options || {}

tiles = _.map(tiles, (item) => item.title)
theme = _.map(theme, (item) => item.title)

const [defaultTile = ''] = tiles
const [defaultTheme = ''] = theme

const generalSchema = {
  title: 'tuiv2',
  type: 'object',
  format: 'objectTab',
  properties: {
    general: {
      type: 'object',
      title: 'General',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          title: 'Name'
        },
        header: {
          type: 'string',
          title: 'Header'
        },
        maximizable: {
          type: 'boolean',
          title: 'Maximizable',
          default: true,
          required: true
        }
      }
    },
    settings: {
      type: 'object',
      title: 'Settings',
      properties: {
        title: {
          type: 'string',
          title: 'Title',
          default: ''
        },
        runParameters: {
          type: 'string',
          title: 'Run Parameters',
          default: ''
        },
        wsdl: {
          type: 'string',
          title: 'WSDL',
          default: ''
        },
        model: {
          type: 'string',
          title: 'Model Name',
          default: ''
        },
        errorMessage: {
          type: 'string',
          title: 'Error Message',
          default: 'Service is not running. Please contact your system admins.'
        },
        asyncActionStartMessage: {
          type: 'string',
          title: 'Save Plan Message',
          default: 'Operation will be completed soon.'
        },
        asyncActionSuccessMessage: {
          type: 'string',
          title: 'Success Plan Message',
          default: 'Operation completed successfully.'
        },
        asyncActionFailMessage: {
          type: 'string',
          title: 'Fail Plan Message',
          default: 'Operation has failed.'
        },
        serviceSelectionPopUp: {
          type: 'boolean',
          title: 'Service Selection',
          default: true,
          required: true
        },
        defaultViewTui: {
          type: 'string',
          title: 'Default View',
          enum: ['Map', 'Grid', 'Tree Grid'],
          default: 'Map',
          required: true
        },
        backgroundColor: {
          type: 'string',
          format: 'Color',
          title: 'Background Color',
          default: '#ffffff'
        },
        leftSideFilter: {
          type: 'boolean',
          title: 'Load and Demand Tree Filter',
          default: true,
          required: true
        },
        startingLastService: {
          type: 'boolean',
          title: 'Remember Last Selected Service',
          default: true,
          required: true
        },
        disabledMap: {
          type: 'object',
          title: 'Disabled Map',
          options: {
            collapsed: true
          },
          properties: {
            latValue: {
              title: 'Lat Value',
              type: 'number',
              default: 39.0405193724839
            },
            lngValue: {
              title: 'Lng Value',
              type: 'number',
              default: 35.29724098742009
            },
            zoomFactor: {
              title: 'Zoom Factor',
              type: 'number',
              default: 7
            }
          }
        }
      }
    },
    MapSettings: {
      type: 'object',
      title: 'Map Settings',
      properties: {
        tileSettings: {
          title: 'Tiles',
          type: 'string',
          enum: tiles,
          default: defaultTile,
          required: true
        },
        mapColor: {
          title: 'Map Theme',
          type: 'string',
          enum: theme,
          default: defaultTheme,
          required: true
        },
        latValue: {
          title: 'Lat Value',
          type: 'number',
          default: 39.0405193724839
        },
        lngValue: {
          title: 'Lng Value',
          type: 'number',
          default: 35.29724098742009
        },
        zoomFactor: {
          title: 'Zoom Factor',
          type: 'number',
          default: 7
        }
      }
    },
    LoadTree: {
      type: 'object',
      title: 'Load Tree',
      properties: {
        columns: {
          type: 'array',
          title: 'TuiColumns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataTypeName: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              isTreeDistinctColorField: {
                type: 'boolean',
                title: 'Tree Distinct Color Column',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              backColor: {
                type: 'string',
                title: 'Back Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              columnIsPercent: {
                type: 'boolean',
                title: 'Percent',
                default: false,
                required: true
              },
              columnIsDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'number',
                title: 'Width'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              }
            }
          }
        },
        Hierarchy: {
          type: 'array',
          title: 'Hierarchy',
          items: {
            type: 'object',
            title: 'Hierarchy',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.LoadTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              }
            }
          }
        },
        Filters: {
          type: 'array',
          title: 'Filters',
          items: {
            type: 'object',
            title: 'Filter',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.LoadTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              },
              operator: {
                type: 'string',
                title: 'Operator',
                enum: ['=', '<', '>'],
                required: true,
                default: 'min'
              },
              value: {
                type: 'string',
                title: 'Value'
              }
            }
          }
        },
        loadFooterButtons: {
          type: 'array',
          title: 'Footer Buttons',
          items: {
            type: 'object',
            title: 'Button',
            headerTemplate: 'Button {{ self.buttonText }}',
            options: {
              collapsed: true
            },
            properties: {
              buttonText: {
                type: 'string',
                title: 'Text',
                default: ''
              },
              buttonTooltip: {
                type: 'string',
                title: 'Tooltip',
                default: ''
              },
              buttonIcon: {
                type: 'string',
                title: 'Icon',
                format: 'IconSelector',
                default: 'fa fa-pie-chart'
              },
              confirmation: {
                type: 'boolean',
                title: 'Confirmation',
                required: true,
                default: false
              },
              confirmationText: {
                type: 'string',
                title: 'Confirmation Text',
                default: 'Are you sure to continue?'
              },
              confirmationTitle: {
                type: 'string',
                title: 'Confirmation Title',
                default: 'Confirmation'
              }
            }
          }
        }
      }
    },
    datafields2: {
      type: 'array',
      title: 'Data Fields',
      items: {
        type: 'string'
      },
      options: {
        hidden: true
      }
    },
    ServiceSetting: {
      type: 'object',
      title: 'Service Setting',
      options: {
        hidden: true
      }
    },
    DemandTree: {
      type: 'object',
      title: 'Demand Tree',
      properties: {
        columns: {
          type: 'array',
          title: 'TuiColumns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataTypeName: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: true,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum', 'count'],
                required: true,
                default: 'min'
              },
              backColor: {
                type: 'string',
                title: 'Back Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              columnIsDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'number',
                title: 'Width'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              }
            }
          }
        },
        Hierarchy: {
          type: 'array',
          title: 'Hierarchy',
          items: {
            type: 'object',
            title: 'Hierarchy',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.DemandTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              }
            }
          }
        },
        Filters: {
          type: 'array',
          title: 'Filters',
          items: {
            type: 'object',
            title: 'Filter',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.DemandTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              },
              operator: {
                type: 'string',
                title: 'Operator',
                enum: ['=', '<', '>'],
                required: true,
                default: 'min'
              },
              value: {
                type: 'string',
                title: 'Value'
              }
            }
          }
        },
        demandFooterButtons: {
          type: 'array',
          title: 'Footer Buttons',
          items: {
            type: 'object',
            title: 'Button',
            headerTemplate: 'Button {{ self.buttonText }}',
            options: {
              collapsed: true
            },
            properties: {
              buttonText: {
                type: 'string',
                title: 'Text',
                default: ''
              },
              buttonTooltip: {
                type: 'string',
                title: 'Tooltip',
                default: ''
              },
              buttonIcon: {
                type: 'string',
                title: 'Icon',
                format: 'IconSelector',
                default: 'fa fa-pie-chart'
              },
              confirmation: {
                type: 'boolean',
                title: 'Confirmation',
                required: true,
                default: false
              },
              confirmationText: {
                type: 'string',
                title: 'Confirmation Text',
                default: 'Are you sure to continue?'
              },
              confirmationTitle: {
                type: 'string',
                title: 'Confirmation Title',
                default: 'Confirmation'
              }
            }
          }
        },
        demandTotalFields: {
          type: 'array',
          title: 'Total Fields',
          items: {
            type: 'object',
            title: 'Total Fields',
            headerTemplate: '{{ self.fieldName }}',
            options: {
              collapsed: true
            },
            properties: {
              fieldName: {
                type: 'string',
                title: 'Data Field',
                watch: {
                  ds: 'root.DemandTree.columns'
                },
                enumSource: 'ds',
                enumValue: '{{item.dataIndex}}'
              }
            }
          }
        }
      }
    },
    Location: {
      type: 'object',
      title: 'Locations',
      properties: {
        locations: {
          type: 'array',
          title: 'Locations',
          items: {
            type: 'object',
            title: 'Location',
            headerTemplate: '{{ self.name }}',
            options: {
              collapsed: true
            },
            properties: {
              index: {
                type: 'string',
                title: 'Facility Index'
              },
              name: {
                type: 'string',
                title: 'Display Name'
              },
              facility: {
                type: 'string',
                title: 'Location Name'
              }
            }
          }
        }
      }
    },
    LoadTable: {
      type: 'object',
      title: 'Load Table',
      properties: {
        grouping: {
          type: 'boolean',
          default: false,
          required: true,
          title: 'Grouping'
        },
        columns: {
          type: 'array',
          title: 'TuiColumns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataTypeName: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              columnType: {
                type: 'string',
                title: 'Column Type',
                enum: ['simple', 'progress'],
                required: true,
                default: 'simple'
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              backColor: {
                type: 'string',
                title: 'Back Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              summaryType: {
                type: 'string',
                title: 'Summary Type',
                enum: ['', 'count', 'sum', 'min', 'max', 'average'],
                required: true,
                default: ''
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              columnIsDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'number',
                title: 'Width'
              },
              locked: {
                type: 'boolean',
                title: 'Locked',
                default: false,
                required: true
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              }
            }
          }
        }
      }
    },
    LoadStopTable: {
      type: 'object',
      title: 'Load Stop Table',
      properties: {
        header: {
          type: 'string',
          title: 'Header'
        },
        grouping: {
          type: 'boolean',
          default: false,
          required: true,
          title: 'Grouping'
        },
        columns: {
          type: 'array',
          title: 'TuiColumns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataTypeName: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              backColor: {
                type: 'string',
                title: 'Back Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              columnIsDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'number',
                title: 'Width'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              }
            }
          }
        }
      }
    },
    LoadStopEntryTable: {
      type: 'object',
      title: 'Load Stop Entry Table',
      properties: {
        header: {
          type: 'string',
          title: 'Header'
        },
        grouping: {
          type: 'boolean',
          default: false,
          required: true,
          title: 'Grouping'
        },
        columns: {
          type: 'array',
          title: 'TuiColumns',
          options: {
            syncButton: true
          },
          items: {
            type: 'object',
            title: 'Column',
            headerTemplate: '{{ self.text }}',
            options: {
              collapsed: true
            },
            properties: {
              dataIndex: {
                type: 'string',
                title: 'Data Index',
                readOnly: true
              },
              dataTypeName: {
                type: 'string',
                title: 'Data Type',
                readOnly: true
              },
              isHierarchy: {
                type: 'boolean',
                title: 'Hierarchy',
                default: false,
                required: true
              },
              text: {
                type: 'string',
                title: 'Text'
              },
              aggregationFunction: {
                type: 'string',
                title: 'Aggregation Function',
                enum: ['min', 'max', 'avg', 'sum'],
                required: true,
                default: 'min'
              },
              backColor: {
                type: 'string',
                title: 'Back Color',
                format: 'NullColor'
              },
              fontColor: {
                type: 'string',
                title: 'Text Color',
                format: 'NullColor'
              },
              unit: {
                type: 'string',
                title: 'Unit',
                enum: [
                  'qty',
                  'kg',
                  'lbs',
                  'km',
                  'm',
                  'mile',
                  'km3',
                  'dm3',
                  'liter',
                  'hour',
                  '$',
                  'percent'
                ]
              },
              tooltip: {
                type: 'string',
                title: 'Tooltip'
              },
              columnIsDisabled: {
                type: 'boolean',
                title: 'Disabled',
                default: true,
                required: true
              },
              width: {
                type: 'number',
                title: 'Width'
              },
              formatter: {
                title: 'Formatter',
                type: 'string'
              },
              align: {
                type: 'string',
                enum: ['left', 'center', 'right'],
                default: 'right',
                required: true,
                title: 'Align'
              }
            }
          }
        }
      }
    },
    Units: {
      type: 'object',
      title: 'Units',
      properties: {
        BaseCurrencyUOM: {
          type: 'string',
          title: 'Currency'
        },
        BaseDistanceUOM: {
          type: 'string',
          title: 'Distance'
        },
        BaseTimeUOM: {
          type: 'string',
          title: 'Time'
        },
        BaseVolumeUOM: {
          type: 'string',
          title: 'Volume'
        },
        BaseWeightUOM: {
          type: 'string',
          title: 'Weight'
        },
        BaseLademeterUOM: {
          type: 'string',
          title: 'Ladameter'
        }
      }
    }
  }
}

export default () => {
  return _.assign({}, generalSchema)
}
