export const panePosition = {
  horizontal: {
    top: {
      order: 1,
      status: false
    },
    bottom: {
      order: 2,
      status: false
    }
  },
  vertical: {
    left: {
      order: 1,
      status: false
    },
    right: {
      order: 2,
      status: false
    }
  }
} as const

export const initialPaneSize = 25 as const
export const initialSplitterSize = 24 as const
export const directions = {
  left: 100,
  top: 100,
  bottom: 0,
  right: 0
} as const
