import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormGroup, Button, Row, Col, Card, Form, FormLabel } from 'react-bootstrap'
import cx from 'classnames'
import _ from 'lodash'

import {
  SlvyProgress,
  SlvyFormCheckbox,
  SlvyFormInput,
  SlvyFormSelect,
  SlvyFormIconSelector,
  SlvySelect,
  ScrollPosition,
  slvyToast
} from '../../../../components'
import List from './List'

import { getDataConnections } from '../../../../actions/dataConnection'
import { getTemplates, getTemplateConnections } from '../../../../actions/pageTemplate'
import { createPage } from '../../../../actions/page'
import { getUserGroups } from '../../../../actions/userGroup'
import { getCatalog } from '../../../../actions/catalog'

import { selectCollection, selecOperationStatus, select } from '../../../../crudoptV3'
import { selectCustomerId } from '../../../../crudoptV3/selectors'

import { _defaultUserGroups } from '../../../containerSettings'
import { mapFromCrudList } from '../../../../helpers/mapper'
import { parseErrorMsg, camelize } from '../../../../helpers'

import './index.scss'

const viewTitles = ['Create Page', 'Select Template', 'Map Connections']

class PageAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: {
        userGroups: _defaultUserGroups,
        isHidden: true,
        useTemplate: false
      },
      views: this.generateViews(),
      keywordsWithOptions: [],
      viewIndex: 0
    }

    this.submit = false

    this.handleChangeKeywords = this.handleChangeKeywords.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getIsNextDisabled = this.getIsNextDisabled.bind(this)
    this.getViewTitle = this.getViewTitle.bind(this)
    this.handleChangeConnection = this.handleChangeConnection.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { catalog, userGroups, dispatch } = this.props

    if (catalog.needFetch) {
      dispatch(catalog.fetch)
    }

    if (userGroups.needFetch) {
      dispatch(userGroups.fetch)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props

    if (nextProps.catalog.needFetch) {
      dispatch(nextProps.catalog.fetch)
    }

    if (nextProps.userGroups.needFetch) {
      dispatch(nextProps.userGroups.fetch)
    }

    if (nextProps.insertStatus.isSuccess && this.submit) {
      const {
        params: { environment, catalogId, menuId, storeIndex },
        insertStatus: {
          data: { id }
        }
      } = nextProps

      this.props.navigate(
        `/${environment}/catalog/${catalogId}/store/${storeIndex}/menu/${menuId}/page/${id}`
      )
    }
  }

  generateViews() {
    return _.transform(
      viewTitles,
      (views, title, i) => {
        const name = camelize(title)

        views = _.assign(views, {
          [name]: {
            name,
            title,
            id: i,
            show: i === 0,
            data: [],
            selectedData: {},
            selectData: {},
            filterValue: ''
          }
        })
      },
      {}
    )
  }

  handleChange(label) {
    return (value) => {
      this.setState({
        page: {
          ...this.state.page,
          [label]: _.includes(['isHidden', 'useTemplate'], label) ? !this.state.page[label] : value
        }
      })
    }
  }

  handleKeyDown(event) {
    const isEnter = event.keyCode === 13
    if (isEnter) event.preventDefault()
  }

  handleChangeKeywords(value) {
    const nonEmptyValue = value.filter((elem) => elem.value.trim().length)
    const keywords = nonEmptyValue.map((elem) => elem.value)

    this.setState({
      keywordsWithOptions: nonEmptyValue,
      page: { ...this.state.page, keywords }
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.submit = true

    const {
      params: { catalogId, menuId },
      createPage
    } = this.props
    let {
      page,
      page: { useTemplate },
      views,
      views: {
        selectTemplate: {
          selectedData: { id: templateId }
        },
        mapConnections: { data, selectData }
      },
      viewIndex
    } = this.state

    if (useTemplate) {
      const connectionMapping = _.isEqual(data, ['No connections'])
        ? undefined
        : _.transform(
            data,
            (acc, curr, index) => {
              acc = _.assign(acc, { [curr]: selectData[index].value.value })
            },
            {}
          )
      createPage('create', catalogId, menuId, {
        ...page,
        templateId,
        connectionMapping
      })
        .then(() => {
          slvyToast.success({ message: 'Page created successfully!' })
          setTimeout(() => {
            window.location.reload()
          }, 300)
        })
        .catch((error) => {
          viewIndex = 0
          views = this.mapViews(views, viewIndex)

          this.setState({ views, viewIndex }, () => {
            slvyToast.error({ message: parseErrorMsg(error), title: 'Failed to create page!' })
          })
        })
    } else {
      createPage('create', catalogId, menuId, page).catch((error) => {
        slvyToast.error({ message: parseErrorMsg(error), title: 'Failed to create page!' })
      })
    }
  }

  handleMove(direction, event) {
    event.preventDefault()
    let {
      views,
      views: { selectTemplate, mapConnections },
      viewIndex
    } = this.state

    viewIndex = direction === 'back' ? viewIndex - 1 : viewIndex + 1
    views = this.mapViews(views, viewIndex)

    if (selectTemplate.show && _.isEmpty(selectTemplate.data)) {
      const {
        getTemplates,
        params: { pageId }
      } = this.props

      getTemplates(pageId)
        .then((templates) =>
          this.setState((prevState) => ({
            views: {
              ...prevState.views,
              selectTemplate: {
                ...prevState.views.selectTemplate,
                data: templates
              }
            }
          }))
        )
        .catch((error) => {
          slvyToast.error({ message: parseErrorMsg(error), title: 'Failed to fetch templates!' })
        })
    }

    if (mapConnections.show && _.isEmpty(mapConnections.data)) {
      const { customerId, getTemplateConnections, getDataConnections } = this.props
      const { selectedData } = selectTemplate

      getTemplateConnections(selectedData.id)
        .then((templateConnections) => {
          getDataConnections(customerId)
            .then((connections) => {
              const groupedConnections = _.groupBy(connections, 'type')
              const selectData = _.transform(
                templateConnections,
                (selectData, templateConnection, index) =>
                  (selectData = _.assign(selectData, {
                    [index]: {
                      options: { ...groupedConnections[templateConnection] }
                    }
                  })),
                {}
              )

              this.setState((prevState) => ({
                views: {
                  ...prevState.views,
                  mapConnections: {
                    ...prevState.views.mapConnections,
                    data: _.isEmpty(templateConnections) ? ['No connections'] : templateConnections,
                    selectData
                  }
                }
              }))
            })
            .catch((error) => {
              slvyToast.error({
                message: parseErrorMsg(error),
                title: 'Failed to fetch data connections!'
              })
            })
        })
        .catch((error) => {
          let { viewIndex, views } = this.state

          slvyToast.error({
            message: parseErrorMsg(error),
            title: 'Failed to fetch template connections!'
          })
          viewIndex -= 1
          views = this.mapViews(views, viewIndex)
          this.setState({ views, viewIndex })
        })
    }

    this.setState({ views, viewIndex })
  }

  handleSelectItem(viewName, selectedData) {
    const {
      views: { selectTemplate }
    } = this.state

    this.setState((prevState) => ({
      views: {
        ...prevState.views,
        [viewName]: {
          ...prevState.views[viewName],
          selectedData
        },
        ...(viewName === selectTemplate.name &&
          !_.isEqual(selectedData, prevState.views.selectTemplate.selectedData) && {
            mapConnections: {
              ...prevState.views.mapConnections,
              data: [],
              selectData: {}
            }
          })
      }
    }))
  }

  handleChangeFilterValue(viewName, filterValue) {
    this.setState((prevState) => ({
      views: {
        ...prevState.views,
        [viewName]: {
          ...prevState.views[viewName],
          filterValue
        }
      }
    }))
  }

  handleChangeConnection(viewName, selectValue, index) {
    this.setState((prevState) => ({
      views: {
        ...prevState.views,
        [viewName]: {
          ...prevState.views[viewName],
          selectData: {
            ...prevState.views[viewName].selectData,
            [index]: {
              ...prevState.views[viewName].selectData[index],
              value: selectValue
            }
          }
        }
      }
    }))
  }

  getIsNextDisabled() {
    const { views } = this.state
    const { selectTemplate, mapConnections } = views

    if (selectTemplate.show) {
      return _.isEmpty(selectTemplate.selectedData)
    }

    if (mapConnections.show) {
      return _.some(mapConnections.selectData, (selectData) => _.isEmpty(selectData.value))
    }

    return false
  }

  getViewTitle() {
    const { views, viewIndex } = this.state
    const view = _.find(views, { id: viewIndex }) || {}

    return view.title
  }

  mapViews(views, viewIndex) {
    return _.transform(views, (mappedViews, view) => {
      view.show = view.id === viewIndex
      views = _.assign(mappedViews, { [view.name]: view })
    })
  }

  render() {
    const {
      catalog,
      userGroups,
      insertStatus: { error = {}, pending }
    } = this.props
    const { keywordsWithOptions, views } = this.state
    const { name, isHidden, useTemplate, iconClass, userGroups: pageUserGroups } = this.state.page

    const title = this.getViewTitle()
    const { createPage, selectTemplate, mapConnections } = views

    const buttonType = useTemplate && !mapConnections.show ? 'button' : 'submit'
    const buttonText = useTemplate && !mapConnections.show ? 'Next' : 'Save'

    return (
      catalog.isSuccess && (
        <Row>
          <Col lg={12}>
            <Card className="pageAddPanel">
              <Card.Header>
                <Card.Title as="h5" className="mb-0">
                  {title}
                </Card.Title>
              </Card.Header>
              <Card.Body
                className={cx({
                  'template-wizard': !createPage.show
                })}
              >
                <SlvyProgress isLoading={pending}>
                  <Form onKeyDown={this.handleKeyDown} onSubmit={this.handleSubmit}>
                    {createPage.show && (
                      <div>
                        <SlvyFormInput
                          error={error}
                          errorModelLabel="page.Name"
                          label="Name"
                          labelClass="justify-content-end"
                          value={name}
                          onChange={this.handleChange('name')}
                        />
                        <div className="hr-line-dashed" />
                        <FormGroup as={Row} className="mb-3">
                          <FormLabel column className="text-end" sm={2}>
                            Keywords
                          </FormLabel>
                          <Col sm={10}>
                            <SlvySelect
                              isCreatable
                              isMulti
                              className="no-selection"
                              placeholder=""
                              value={keywordsWithOptions}
                              onChange={this.handleChangeKeywords}
                            />
                          </Col>
                        </FormGroup>
                        <SlvyFormCheckbox
                          error={error}
                          errorModelLabel="page.IsHidden"
                          isChecked={!isHidden}
                          label="Visible"
                          onChange={this.handleChange('isHidden')}
                        />
                        <div className="hr-line-dashed" />
                        <SlvyFormIconSelector
                          error={error}
                          errorModelLabel="page.IconClass"
                          label="Icon Class"
                          value={iconClass}
                          onChange={this.handleChange('iconClass')}
                        />
                        <div className="hr-line-dashed" />
                        <SlvyFormSelect
                          multiple
                          data={mapFromCrudList(userGroups.data)}
                          error={error}
                          errorModelLabel="page.UserGroups"
                          isLoading={userGroups.pending}
                          label="User Groups"
                          labelClass="justify-content-end"
                          value={pageUserGroups}
                          onSelect={this.handleChange('userGroups')}
                        />
                        <div className="hr-line-dashed" />
                        <SlvyFormCheckbox
                          isChecked={useTemplate}
                          label="Template"
                          onChange={this.handleChange('useTemplate')}
                        />
                        <div className="hr-line-dashed" />
                      </div>
                    )}
                    <ScrollPosition>
                      {(scrollTop, onScroll) =>
                        selectTemplate.show && (
                          <List
                            filterValue={selectTemplate.filterValue}
                            items={selectTemplate.data}
                            scrollTop={scrollTop}
                            selectedItem={selectTemplate.selectedData}
                            onChange={this.handleChangeFilterValue.bind(this, selectTemplate.name)}
                            onClickItem={this.handleSelectItem.bind(this, selectTemplate.name)}
                            onScroll={onScroll}
                          />
                        )
                      }
                    </ScrollPosition>
                    <ScrollPosition>
                      {(scrollTop, onScroll) =>
                        mapConnections.show && (
                          <List
                            withSelect
                            filterValue={mapConnections.filterValue}
                            items={_.map(mapConnections.data, (data) => ({
                              name: data
                            }))}
                            scrollTop={scrollTop}
                            selectItems={mapConnections.selectData}
                            selectedItem={mapConnections.selectedData}
                            onChange={this.handleChangeFilterValue.bind(this, mapConnections.name)}
                            onChangeConnection={this.handleChangeConnection.bind(
                              this,
                              mapConnections.name
                            )}
                            onClickItem={this.handleSelectItem.bind(this, mapConnections.name)}
                            onScroll={onScroll}
                          />
                        )
                      }
                    </ScrollPosition>
                    <FormGroup as={Row} controlId="userGroup">
                      <Col sm={{ span: 10, offset: 2 }}>
                        {!createPage.show && (
                          <Button
                            variant="outline-dark"
                            onClick={this.handleMove.bind(this, 'back')}
                          >
                            Back
                          </Button>
                        )}
                        <Button
                          type={buttonType}
                          variant="success"
                          {...(useTemplate &&
                            !mapConnections.show && {
                              onClick: this.handleMove.bind(this, 'forward')
                            })}
                          disabled={this.getIsNextDisabled()}
                        >
                          {buttonText}
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </SlvyProgress>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const catalog = select(getCatalog(ownProps.params.catalogId), state.model3)
  const customerId = selectCustomerId(state, ownProps.params.catalogId)
  const userGroups = customerId
    ? selectCollection(getUserGroups(customerId), state.model3)
    : { needFetch: false }
  const insertStatus = selecOperationStatus('page', 'create', state.model3)

  return {
    customerId,
    catalog,
    userGroups,
    insertStatus
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      createPage,
      getTemplates,
      getTemplateConnections,
      getDataConnections
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(PageAdd)
