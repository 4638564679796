import { getGridEditable } from '../../common/index'
import { GetGridEditableProps } from '../common.types'

const getGridEditableParams = (): GetGridEditableProps => ({
  isAllowed: (permission: string) => permission === 'Editing',
  editingEnabledConfig: true
})

describe('AgGrid:Helpers:Common:getGridEditable', () => {
  let params = getGridEditableParams()

  beforeEach(() => {
    params = getGridEditableParams()
  })

  it('should return true if isAllowed is Editing and editingEnableConfig is true', () => {
    const result = getGridEditable(params)
    expect(result).toBe(true)
  })
  it('should return false if isAllowed is not Editing', () => {
    params.isAllowed = (permission: string) => permission === 'Enabled'
    const result = getGridEditable(params)
    expect(result).toBe(false)
  })
  it('should return false if editingEnableConfig is false', () => {
    params.editingEnabledConfig = false
    const result = getGridEditable(params)
    expect(result).toBe(false)
  })
})
