import { useEffect, useMemo, useRef } from 'react'
import { transform } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import { BasicType } from '@/BasePlugin/PluginTypes'
import { IRowDataGridOrNull, TrowDataValue } from '../../../../types'
import {
  FormattedRowSelectedEventRef,
  GetFormattedRowSelectedProps,
  FormattedRowSelectedProps
} from './useRegisterEventFormattedRowSelected.types'

const getFormattedRowSelected = ({
  rowData,
  fieldConfigs,
  formattedFields,
  getFormattedValue
}: GetFormattedRowSelectedProps) => {
  if (rowData) {
    return transform(
      fieldConfigs,
      (result: Record<string, TrowDataValue>, { fieldName }) => {
        // eslint-disable-next-line no-param-reassign
        result[fieldName] = getFormattedValue(fieldName, rowData[fieldName], formattedFields)
      },
      {}
    )
  }
  // Deselect case
  return transform(
    fieldConfigs,
    (result: Record<string, ''>, { fieldName }) => {
      // eslint-disable-next-line no-param-reassign
      result[fieldName] = ''
    },
    {}
  )
}

function useRegisterEventFormattedRowSelected({
  fieldConfigs,
  formattedFields,
  getFormattedValue,
  registerEvent
}: FormattedRowSelectedProps) {
  const formattedRowSelectedEventRef = useRef<FormattedRowSelectedEventRef>({
    handleFormattedRowSelected: (_args: never): never => ({} as never)
  })
  const formattedCellParams = useMemo(() => {
    return transform(
      fieldConfigs,
      (result: Record<string, BasicType>, { fieldName }) => {
        // eslint-disable-next-line no-param-reassign
        result[fieldName] = PluginTypes.string
      },
      {}
    )
  }, [fieldConfigs])

  useEffect(() => {
    formattedRowSelectedEventRef.current.handleFormattedRowSelected = registerEvent({
      key: 'FormattedRowSelected',
      fn: (rowData: IRowDataGridOrNull) =>
        getFormattedRowSelected({ rowData, fieldConfigs, formattedFields, getFormattedValue }),
      returnTypes: formattedCellParams
    })
  }, [fieldConfigs, formattedCellParams, formattedFields, getFormattedValue, registerEvent])

  return { formattedRowSelectedEventRef }
}

export default useRegisterEventFormattedRowSelected
