Ext.define('MDM.src.view.regionapp.DroppedMaterial.MainModel', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/RMDM_Dropped_Material',
  fields: [
    {
      name: 'DroppedAt',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s.u',
      dateWriteFormat: 'c'
    },
    {
      name: 'AppearsOnExtract',
      type: 'boolean'
    },
    {
      name: 'isEditable',
      type: 'boolean'
    },
    {
      name: 'Demand',
      type: 'boolean'
    },
    {
      name: 'Production',
      type: 'boolean'
    },
    {
      name: 'Inventory',
      type: 'boolean'
    },
    {
      name: 'Xsupply',
      type: 'boolean'
    },
    {
      name: 'AppearOnExtractYesOrNo',
      convert: function (value, record) {
        if (record.get('AppearsOnExtract')) return 'Yes'
        return 'No'
      },
      depends: ['AppearsOnExtract']
    },
    {
      name: 'DemandYesorNo',
      convert: function (value, record) {
        if (record.get('Demand')) return 'Yes'
        return 'No'
      },
      depends: ['Demand']
    },
    {
      name: 'ProductionYesorNo',
      convert: function (value, record) {
        if (record.get('Production')) return 'Yes'
        return 'No'
      },
      depends: ['Production']
    },
    {
      name: 'InventoryYesorNo',
      convert: function (value, record) {
        if (record.get('Inventory')) return 'Yes'
        return 'No'
      },
      depends: ['Inventory']
    },
    {
      name: 'XsupplyYesorNo',
      convert: function (value, record) {
        if (record.get('Xsupply')) return 'Yes'
        return 'No'
      },
      depends: ['Xsupply']
    }
  ]
})
