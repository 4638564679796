/**
 * Serie class for solid gauge series type
 *
 * See {@link Chart.ux.Highcharts.Serie} class for more info
 *
 * SolidGauge series is a one dimensional series type, i.e only y-axis data
 */
Ext.define('Chart.ux.Highcharts.SolidGaugeSerie', {
  extend: 'Chart.ux.Highcharts.Serie',
  alternateClassName: ['highcharts.solidgauge'],
  type: 'solidgauge'

  /***
   * @cfg xField
   * @hide
   */
})
