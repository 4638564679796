Ext.define('po.EditorView', {
  extend: 'Ext.panel.Panel',
  alias: 'widget.poEditorView',
  scrollable: true,

  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },
  items: [
    {
      xtype: 'poSummaryTip',
      border: true,
      bodyPadding: 4,
      bind: {
        data: '{selectedRecordWithi18n}'
      },
      flex: 1
    },

    {
      xtype: 'form',
      height: 76,
      border: true,
      cls: 'editor-form',
      margin: '2 0 0 0',
      bodyPadding: 2,
      bodyStyle: {
        background: '#fff'
      },
      items: [
        {
          xtype: 'numberfield',
          anchor: '80%',
          name: 'miktar',
          editable: false,
          enableKeyEvents: true,
          bind: {
            value: '{editorMiktar}',
            step: '{selectedRecord.siparisKatsayisi}',
            fieldLabel: '{i18n.EditorView_Deger}',
            disabled: '{editorDisabled}'
          },
          listeners: {
            blur: function () {
              var me = this,
                mainView = me.up('pomainview'),
                viewModel = mainView.getViewModel(),
                record = viewModel.get('selectedRecord'),
                value = me.value

              if (value == 0) {
                record.set({
                  degisenOneriKoli: value
                })
              } else {
                record.set({
                  degisenOneriKoli:
                    value <= record.get('minSiparisMiktari')
                      ? record.get('minSiparisMiktari')
                      : value
                })
              }
            },
            focus: function (me) {
              var value = me.value
              var view = this.up('pomainview'),
                viewModel = view.getViewModel(),
                selectedRecord = viewModel.get('selectedRecord')

              me.setMinValue(
                selectedRecord.get('degisenOneriKoli') >= selectedRecord.get('minSiparisMiktari') ||
                  selectedRecord.get('degisenOneriKoli') == 0
                  ? 0
                  : selectedRecord.get('minSiparisMiktari')
              )
              if (value == 0) {
                me.setValue(selectedRecord.get('minSiparisMiktari'))
              }
            },
            focusleave: function (me) {
              var value = me.value,
                step = me.step,
                mod = value % step
              var view = this.up('pomainview'),
                viewModel = view.getViewModel(),
                selectedRecord = viewModel.get('selectedRecord')
              if (mod >= step / 2) {
                me.setValue(value + (step - mod))
              } else {
                me.setValue(value - mod)
              }
              if (me.getValue() != 0 && me.getValue() < selectedRecord.get('minSiparisMiktari')) {
                me.setValue(selectedRecord.get('minSiparisMiktari'))
              } else if (me.getValue() == 0) {
                me.setValue(0)
              }
            },
            keypress: function (field, e) {
              if (e.getKey() == e.ENTER) {
                field.blur()
              }
            }
          },
          setStep: function (value) {
            this.step = value
          }
        },
        {
          xtype: 'poComboWithClear',
          displayField: 'Reason',
          valueField: 'ReasonId',
          fieldLabel: 'Sebep Kodu',
          anchor: '100%',
          name: 'sebepKodu',
          bind: {
            store: '{reasonStore}',
            value: '{editorSebepKodu}',
            disabled: '{editorDisabled}',
            fieldLabel: '{i18n.EditorView_Sebep}'
          },
          listeners: {
            blur: function () {
              var me = this,
                mainView = me.up('pomainview'),
                viewModel = mainView.getViewModel(),
                record = viewModel.get('selectedRecord'),
                value = me.value

              record.set({
                sebepKodu: value
              })
            }
          }
        }
      ]
    }
  ]
})
