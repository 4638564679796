import { useMemo, useCallback } from 'react'
import { GridOptions, IsGroupOpenByDefaultParams } from 'ag-grid-community'
import { EmptyObject, IRowData } from '../../types'
import { UseIsGroupOpenByDefaultProps } from './useIsGroupOpenByDefaultProps.types'

function useIsGroupOpenByDefaultProps(props: UseIsGroupOpenByDefaultProps) {
  const { grouping, initialState } = props

  const isGroupOpenByDefault = useCallback(
    (params: IsGroupOpenByDefaultParams) => {
      const { field, key } = params
      const expandedRowGroupIds = initialState?.rowGroupExpansion?.expandedRowGroupIds || []
      const currentGroupId = `row-group-${field}-${key}`
      return (
        expandedRowGroupIds.length === 0 ||
        expandedRowGroupIds.some((groupId: string) => groupId === currentGroupId)
      )
    },
    [initialState]
  )

  const isGroupOpenByDefaultProps = useMemo(():
    | EmptyObject
    | {
        isGroupOpenByDefault: GridOptions<IRowData>['isGroupOpenByDefault']
      } => {
    if (!grouping) {
      return {}
    }

    return {
      isGroupOpenByDefault
    }
  }, [grouping, isGroupOpenByDefault])

  return { isGroupOpenByDefaultProps }
}

export default useIsGroupOpenByDefaultProps
