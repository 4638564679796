import _ from 'lodash'
import { take } from 'redux-saga/effects'
import { REMOTE_ACTION_COMPLETED } from '../actionTypes'
import signalr from '../../../helpers/SignalrClient'

export default function* remoteActionCompleted(props = {}, result, action) {
  while (true) {
    const {
      payload: { res: takeResult }
    } = yield take(REMOTE_ACTION_COMPLETED)

    const { connection = {} } = signalr.get()
    const { LRTS = [] } = takeResult
    const { remoteActionKeys } = props

    // /@TODO
    const filteredMessage = _.filter(
      LRTS,
      ({ messageBody: { remoteActionKey = '', taskType = '', taskResponse = '' } = {} }) =>
        !_.isEmpty(
          _.find(
            remoteActionKeys,
            ({ remoteActionKey: rKey = '', type = '', result = '' }) =>
              remoteActionKey === rKey && taskType === type && taskResponse === result
          )
        )
    )

    const [{ id = 0 } = {}] = filteredMessage
    const isOk = !_.isEmpty(filteredMessage)

    if (isOk) {
      connection.invoke('AcknowledgeMessage', id)
      return result
    }
  }
}

// actionName
// type job or pro
// success or failed
