const presetColors = [
  '#F8F8F8',
  '#FC5C65',
  '#45AAF2',
  '#26DE81',
  '#FD9644',
  '#FED330',
  '#4A90E2',
  '#2BCBBA',
  '#778CA3',
  '#EB3B5A',
  '#2D98DA',
  '#20BF6B',
  '#FA8231',
  '#F7B731',
  '#3867D6',
  '#4B6584'
] as const

export default presetColors
