import { isEmpty } from 'lodash'
import cx from 'classnames'
import { getServiceStatus } from '../../../store/api/endpoints'
import Tooltip from '../../Tooltip'
import styles from './UserInfo.module.scss'

export default function UserInfo() {
  const serviceStatusResponse = getServiceStatus.useQueryState()
  const hasUsers = !isEmpty(serviceStatusResponse.data?.Users)
  const userCount = hasUsers ? serviceStatusResponse.data?.Users.length : 0
  const userNames = (hasUsers ? serviceStatusResponse.data?.Users.join(', ') : 'No users') as string

  return (
    <Tooltip text={userNames}>
      <span>
        <i className={cx('fa fa-fw fa slvy-ui-icon-users-solid', styles.userInfoIcon)} />{' '}
        {userCount}
      </span>
    </Tooltip>
  )
}
