export default [
  {
    key: 'Date m/d/Y',
    value: 'MM/DD/Y'
  },
  {
    key: 'Date m/d/Y H:m:s',
    value: 'MM/DD/Y H:m:s'
  },
  {
    key: 'Date m/d/Y HH:mm:ss',
    value: 'MM/DD/Y HH:mm:ss'
  },
  {
    key: 'Date d/m/Y',
    value: 'DD/MM/Y'
  },
  {
    key: 'Date d/m/Y H:m:s',
    value: 'DD/MM/Y H:m:s'
  },
  {
    key: 'Date d/m/Y HH:mm:ss',
    value: 'DD/MM/Y HH:mm:ss'
  },
  {
    key: 'Date M d',
    value: 'MM DD'
  },
  {
    key: 'Date m-d-Y',
    value: 'MM-DD-Y'
  },
  {
    key: 'Date m-d-Y H:m:s',
    value: 'MM-DD-Y H:m:s'
  },
  {
    key: 'Date m-d-Y HH:mm:ss',
    value: 'MM-DD-Y HH:mm:ss'
  },
  {
    key: 'Date d-m-Y',
    value: 'DD-MM-Y'
  },
  {
    key: 'Date d-m-Y H:m:s',
    value: 'DD-MM-Y H:m:s'
  },
  {
    key: 'Date d-m-Y HH:mm:ss',
    value: 'DD-MM-Y HH:mm:ss'
  },
  {
    key: 'Date Y/m/d',
    value: 'Y/MM/DD'
  },
  {
    key: 'Date Y-m-d',
    value: 'Y-MM-DD'
  },
  {
    key: 'Date D. MMM',
    value: 'D. MMM'
  }
]
