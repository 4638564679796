Ext.define('Spui.util.imageupload.Cropper', {
  extend: 'Spui.util.imageupload.Upload',

  // Uncomment to give this component an xtype
  xtype: 'spui-util-imageupload-cropper',

  config: {
    imageResizer: null,
    exportFileType: 'image/png',
    cropperStyle: {},
    resizerMaxWidth: null,
    resizerMinWidth: 10,
    resizerMaxHeight: null,
    resizerMinHeight: 10,
    constrainToImage: false
  },
  initialImage:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCA' +
    'YAAAAeP4ixAAAFRElEQVRoQ+2ZeahtUxzHP+8lSZIkSZJMSXrJH+LJyxRC5jHkifd475Uh' +
    'UzIl6WXOFJIyliRzhkgyhUzJFJIkSZIkIaHP7bdP66671t77nO4996bzq1On9tprre9v+P' +
    '6+a+1FwL/8D2zRBMgCi+IkIgssIEwiMonIHHlgklpz5NiRp51EZGTX9X/xH2Bx3+FG5L1s' +
    'sC/vBKzTc5KfgO8AF87NOXYA1u0x17fAm8BnwI/Ar/HexsA2wK6xr/VKcwkkNwfeDizv4Z' +
    'GfgfOA+wtABOEcNwAbFtb5C/gKeB54CPgI+LsD8KbA4cApwI7ARs34EhCf7QPcA2zVMrER' +
    'cBNnRETyodsDdwPLCnP8ADwB3BUAStFsw7Q5cCRwGrCzA2tARLoWOL0lxX6LiR4tRMOong' +
    'VcAayf7egb4ErgKcCIjmqmqyVwK7BUIIbdfMzNnLwvcry02LPAEYApktsuwOPAltkDa+ko' +
    '4P0eadQXoJG/TiAHRYrk4bXo9dylhRn15HHAS4Vnesq0PKkA4mzgsR473CAKXSf9XiGSdJ' +
    'olAnkSOBEwVXIzFz8ALLLULG439UvhnQOjeGWbxtzM9Xquso7jJIelwL7AtpGSAjGKzwGv' +
    'VqLvu4sF8mewwMMVT10OXJbUijlugRuNPIoCt8AFk/aAV6Kevq6ssQlwUUTR+kwpViaT4p' +
    '8BrgGcYwY5NJ1d6jPfSwvpHWtFb+mhO4GLI+Tpvlx8dRR4SrdGYw1wbwWEdeQGj+7oXW5e' +
    'hzjX5/lcDZA/gGuDqfyfmjl/JnBVsMwxwLuFTckgArbQU9M5u0eTy1+T0c4HLgCsiy4TjA' +
    '4xI6b1nFRrfRrhf6sw29axSQG4aKlxmf/nFLz6IHByZYcyzgsd/Sp/VaI5DHg9fZCLRhe1' +
    'iEv8vn/Qpvmam1F4u5IaJwC1+pMRjfQw1kRlVVr8ORD7yak9KbJZXHa6Azi2sBtryrSyb+' +
    'QmGRiN/YZBEWONhkyrPpuykox30MGVJlnazPHATQWKdqzib4/QVKX6+GTItGrmsAx0+Dtt' +
    'QCz2S4BbenRfla3R2KviVQt979RzyTgj+SWQ9pu+wbEFrEgbcu1gJR0ryErs1Cwmm10YPa' +
    'Ym01W3NrhBCowbiLl9W0iUkg5zP0bDHM/1VOrV74E9K/1JuvXssUXfMCTjvghHD5ir7air' +
    '1JZxbEK5KSdMKdVxmyl7LPaPC4Ms9tei0Q6LxRZhsQ8aeNeZ3UPTjYVV1F5vhCbq2oRqty' +
    'YUlT8K02FM+lXrSb+D5j0qkM3Cm8qXLlMs2kRLtluI1lyUdkXZE+I054wDiD3E9CqdW9Rk' +
    'KoKVXd5Inr8cnX2aWh8HEEWjteaJsGRG1XO956K2Cw9lkbXmXFXRWHNIrUaGSS1z+pFQrb' +
    'Wj7ZJIPy8WSuJRZ3hsELA3LTO03jgiopNkwHMDUO2iQecoVw4ABOa5RAB6/2nAlLIfFd8f' +
    'FxDBWCvK77YmKyV7rjHFmoOZtSU71RzguKmjbtvH0NlIrTRt7UlqJCXGbJnnoLXjBuLmpc' +
    '2rgQ97XCp0gfWS7ma13nwAsVBVrxaul3TS6DAXdKaS9XNo1J0Rmbp8aEstz+ZqrtxsYC8C' +
    'nhxHNQHJRA/EbaO3Jeq62h2yPcfLDQ9xClqb6UCsdgFR05R0kmftQyp3usMCMyIyk+so62' +
    'U4AVngFr4AFJbbxX2vYjW/vVxwH0PdfPNrPisIxo233uh3RWRY787b+AmQeXN9ZeFJRCYR' +
    'mSMPNKkl1ak0S1eh0t4M3p6l/SgI/fl5wosKm6L7sHt7TWQDVBUr7aXh6lfe/wAGfUzmPL' +
    'SJqgAAAABJRU5ErkJggg==',
  initComponent: function () {
    var me = this

    me.callParent()

    me.on({
      imageLoaded: me.onImageLoaded,
      destroy: me.onDestroy,
      scope: me
    })
  },

  onDestroy: function () {
    var me = this,
      resizer = me.getImageResizer()

    if (resizer) {
      resizer.destroy()
    }

    me.callParent(arguments)
  },

  /**
   * Handles imageLoaded event of E2open.ux.image.Upload
   *
   * - Overrides getValues method of the next parent form
   * - Creates a resizable and draggable component
   */
  onImageLoaded: function () {
    var me = this,
      imageContainer = me.down('component[role=imageContainer]'),
      image = imageContainer.el.query('img')[0],
      imageEl = Ext.get(image),
      constrainToImage = me.getConstrainToImage(),
      cropperX = 0,
      cropperY = 0,
      form = me.up('form')

    if (me.getImageResizer()) {
      me.getImageResizer().destroy()
    }

    if (form) {
      /**
       * Overrides getValues method of form
       *
       * - Overwrites imageField value with png-base64-string of the cropped image
       *
       * @override
       * @param {boolean} asString
       * @param {boolean} dirtyOnly
       * @param {boolean} includeEmptyText
       * @param {boolean} useDataValues
       * @returns {*}
       */
      form.getValues = function (asString, dirtyOnly, includeEmptyText, useDataValues) {
        var values = form.getForm().getValues(asString, dirtyOnly, includeEmptyText, useDataValues)

        values[me.getImageName()] = me.getCroppedImage()

        return values
      }
    }

    /**
     * Creates image-resizer component
     */
    me.setImageResizer(
      Ext.create('Ext.Component', {
        constrainTo: constrainToImage ? image : imageContainer.el,
        renderTo: imageContainer.el,
        role: 'resizer',
        x: constrainToImage ? imageEl.getX() - imageContainer.getX() : cropperX,
        y: constrainToImage ? imageEl.getY() - imageContainer.getY() : cropperY,
        resizable: {
          constrain: true,
          constrainTo: constrainToImage ? image : imageContainer.el,
          transparent: true,
          handles: 'all'
        },
        draggable: {
          constrain: true,
          constrainTo: constrainToImage ? image : imageContainer.el
        },
        width: Math.min(me.getResizerMinWidth(), me.getResizerMaxWidth()),
        height: Math.min(me.getResizerMinHeight(), me.getResizerMaxHeight()),
        minHeight: me.getResizerMinHeight(),
        maxHeight: me.getResizerMaxHeight(),
        minWidth: me.getResizerMinWidth(),
        maxWidth: me.getResizerMaxWidth(),
        style: Ext.apply(
          {
            cursor: 'move',
            backgroundColor: 'lightblue',
            opacity: '0.3',
            position: 'absolute'
          },
          me.cropperStyle
        )
      })
    )
  },

  /**
   * Returns resizer position and size
   *
   * @returns {{x: number, y: number, w: number, h: number}}
   */
  getResizerPosition: function () {
    var me = this,
      imageContainer = me.down('component[role=imageContainer]'),
      image = imageContainer.el.query('img')[0],
      imageEl = Ext.get(image),
      imageResizer = me.getImageResizer()

    return {
      x: imageResizer.resizer.getEl().getX() - imageEl.getX(),
      y: imageResizer.resizer.getEl().getY() - imageEl.getY(),
      w: imageResizer.getWidth(),
      h: imageResizer.getHeight()
    }
  },

  /**
   * Returns cropped image as base64 string
   *
   * - Creates canvas element
   * - Crops canvas context image according to resizer position and size
   *
   * @returns {string|*}
   */
  getCroppedImage: function () {
    var me = this,
      pos = me.getResizerPosition(),
      form = me.up('form'),
      container = form ? form.down('component[role=imageContainer]') : false,
      image,
      imageField,
      canvas,
      imageObj,
      imageX,
      imageY,
      base64

    if (container) {
      image = container.el.query('img')[0]
      imageField = form.down('hidden[name=' + me.getImageName() + ']')
      canvas = document.createElement('canvas')
      imageObj = new Image()
      imageX = Math.round((pos.x / image.width) * me.initialImageWidth)
      imageY = Math.round((pos.y / image.height) * me.initialImageHeight)

      canvas.width = pos.w
      canvas.height = pos.h

      imageObj.src = imageField.getValue()

      canvas
        .getContext('2d')
        .drawImage(
          imageObj,
          Math.max(imageX, 0),
          Math.max(imageY, 0),
          me.initialImageWidth,
          me.initialImageHeight,
          pos.x < 0 ? Math.abs(pos.x) : 0,
          pos.y < 0 ? Math.abs(pos.y) : 0,
          image.width,
          image.height
        )

      base64 = canvas.toDataURL(me.getExportFileType())

      canvas.remove()
      imageObj.remove()
    }

    return base64
  }
})
