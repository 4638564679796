export default `({
    listeners: {
        activate: function (p) {
            var store = p.getNormalizedChartStore(),
                SpotId = p.getSpotId();
            store.getProxy().setExtraParam('SpotId', SpotId);
            if (store.forceToLoad) {
                store.load();
                store.forceToLoad = false
            }
        },
        afterChartRendered: function (p) {
            var me = this;
            Ext.defer(function () {
                if (me.chart && me.rendered) {
                    me.onloadStore(me.store);
                } else {
                    if (me.afterChartRendered) {
                        me.afterChartRendered()
                    }
                }
            }, 600);
        }
    },
    series: [{
            dataIndex: 'DaylyPercent',
            type: 'column',
            name: tt('DaylyPercent'),
            color: 'rgba(236,17,92,1)',
        },
        {
            dataIndex: 'ParetoPercent',
            type: 'line',
            name: tt('ParetoPercent'),
            color: 'rgba(236,17,92,1)',
        },
    ],
    xField: 'DayofSpot',
    colorDelta: 0.07,
    chartConfig: {
        chart: {
            type: 'column',
            alignTicks: true,
            marginRight: 20,
            marginBottom: 100,
            zoomType: 'x',
            dataLabels: {
                enabled: true
            },
            column: {
                stacking: 'normal'
            }
        },
        credits: {
            text: 'SOLVOYO © 2021',
            href: 'http://www.solvoyo.com',
            position: {
                align: 'right',
                y: -5 // position of credits
            },
            style: {
                fontSize: '11pt' // you can style it!
            }
        },
        tooltip: {
            formatter: function () {
                var s = '<b>' + this.x + '</b>';
                this.points.forEach(function (p) {
                    s += '<br/>' + p.series.name + ': ' +
                        Ext.util.Format.number(p.y * 100, '0.##%');
                });
                return s;
            },
            shared: true
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            },
            series: {
                point: {
                    events: {
                        drop: function () {
                            a = 1;
                        }
                    }
                },
                stickyTracking: false
            }
        },
        navigation: {
            buttonOptions: {
                align: 'left'
            }
        },
        title: {
            text: tt('Normalized Chart'),
            style: {
                fontSize: "18px",
                fontWeight: 'bold'
            }
        },
        xAxis: {
            labels: {
                // formatter: function () {
                //     monthValue = this.value
                //     monthValue = monthValue.slice(-2);
                //     monthValue = parseInt(monthValue) - 1
                //     return Ext.Date.shortMonthNames[monthValue];
                // },
                style: {
                    fontSize: '13px'
                },
                rotation: 315,
                y: 35,
            },
            plotBands: [{
                    color: '#FCFFC5',
                    from: 1,
                    to: 1,
                    id: 'plotband-1'
                },
                { // visualize the weekend
                    from: -1,
                    to: 6.5,
                    color: 'rgba(68, 170, 213, 0.07)',
                    id: 'w1',
                    label: {
                        text: 'W-1',
                        align: 'center',
                        style: {
                            color: 'blue',
                            fontWeight: 'bold',
                            fontSize: "15px"
                        }
                    },
                },
                { // visualize the weekend
                    from: 6.5,
                    to: 13.5,
                    color: 'rgba(255, 0, 0,  0.07)',
                    id: 'w2',
                    label: {
                        text: 'W-2',
                        align: 'center',
                        style: {
                            color: 'blue',
                            fontWeight: 'bold',
                            fontSize: "15px"
                        }
                    },
                },
                { // visualize the weekend
                    from: 13.5,
                    to: 20.5,
                    color: 'rgba(68, 170, 213, 0.07)',
                    id: 'w3',
                    label: {
                        text: 'W-3',
                        align: 'center',
                        style: {
                            color: 'blue',
                            fontWeight: 'bold',
                            fontSize: "15px"
                        }
                    },
                },
                { // visualize the weekend
                    from: 20.5,
                    to: 27.5,
                    color: 'rgba(255, 0, 0,  0.07)',
                    id: 'w4',
                    label: {
                        text: 'W-4',
                        align: 'center',
                        style: {
                            color: 'blue',
                            fontWeight: 'bold',
                            fontSize: "15px"
                        }
                    },
                },
                { // visualize the weekend
                    from: 27.5,
                    to: 35.5,
                    color: 'rgba(68, 170, 213, 0.07)',
                    id: 'w5',
                    label: {
                        text: 'W-5',
                        align: 'center',
                        style: {
                            color: 'blue',
                            fontWeight: 'bold',
                            fontSize: "15px"
                        }
                    },
                }
            ]
        },
        yAxis: {
            allowDecimals: false,
            min: '0',
            max: '1',
            //  tickInterval: 0.20,
            gridLineWidth: 1,
            //gridLineColor: '#ccc',
            plotLines: [{
                    value: 0.8,
                    color: 'rgba(255,0,0,0.4)',
                    width: 2,
                    label: {
                        text: '%80',
                        align: 'right',
                        style: {
                            color: 'blue'
                        }
                    },
                },
                {
                    value: 0.95,
                    color: 'rgba(255,0,0,0.4)',
                    width: 2,
                    label: {
                        text: '%95',
                        align: 'right',
                        style: {
                            color: 'blue'
                        }
                    },
                }
            ],
            title: {
                text: tt('PercentofSales')
            },
            labels: {
                formatter: function () {
                    // Use thousands separator for four-digit numbers too
                    return Ext.util.Format.number(this.value * 100, '0.##%');
                }
            }
        },
    },
    onloadStore: function (store) {
        // if (store.loadCount < 3 && store.count() < 3) {
        //     Ext.defer(function () {
        //         store.load()
        //     }, 10000);
        // }
        var rec = store.findRecord('ParetoDayNumber', 1, 0, false, true, true);
        var chart = this;
        var data = store.getDatas()
        if (rec) {
            this.plotBand = {
                color: 'rgba(255,255,0,0.2)',
                from: rec.get('DayofSpot') - 0.3,
                to: rec.get('DayofSpot') + 0.3,
                label: {
                    text: '80%',
                    align: 'center',
                },
                id: 'plotband-1'
            };
            if (chart.chart && this.rendered) {
                chart.chart.xAxis[0].removePlotBand('plotband-1');
                chart.chart.xAxis[0].addPlotBand(this.plotBand);
            }
        }
        if (data.length === 0) {
            var tabPanel = this.up().ownerCt.ownerCt.getTapPanel()
            // if (tabPanel) {
            //     tabPanel.setSimilarSpotId('')
            // }
            chart.removeAllSeries()
        }
        rec = store.findRecord('ParetoDayNumber', 2, 0, false, true, true);
        if (rec) {
            this.plotBand = {
                color: 'rgba(0,0,255,0.1)',
                from: rec.get('DayofSpot') - 0.3,
                to: rec.get('DayofSpot') + 0.3,
                label: {
                    text: '95%',
                    align: 'center',
                },
                id: 'plotband-2'
            };
            if (chart.chart && this.rendered) {
                chart.chart.xAxis[0].removePlotBand('plotband-2');
                chart.chart.xAxis[0].addPlotBand(this.plotBand);
            }
        }
    },
})
`
