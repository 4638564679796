import { setCustomAggregations } from '../custom-agg'
import { AG_GRID_LOCALE_CUSTOM_EN } from '../../../locale'
import { ISetCustomAggregations, SlvyColDef } from '../../../types'

const getDefaultCustomAggregation = (field?: string) =>
  window.btoa(`
  let avg = 0
  for (let i = 0; i < rowCount; i++) {
    avg += table(i, '${field}')
  }
  return avg / rowCount
`)

const getDefaultParams = (): ISetCustomAggregations => ({
  columnDefs: [
    {
      field: 'col1',
      customInfo: {
        customAggregation: getDefaultCustomAggregation('col1')
      } as SlvyColDef['customInfo']
    },
    {
      field: 'col2',
      customInfo: {
        customAggregation: getDefaultCustomAggregation('col2')
      } as SlvyColDef['customInfo']
    },
    {
      field: 'col3',
      customInfo: {} as SlvyColDef['customInfo']
    }
  ],
  data: [
    { col1: 1, col2: 2, col3: 3 },
    { col1: 5, col2: 5, col3: 6 },
    { col1: 7, col2: 8, col3: 9 }
  ],
  customAggregationFailedLocale: AG_GRID_LOCALE_CUSTOM_EN.customAggregationFailed
})

const defaultState = true
const defaultSetState = jest.fn()

describe('AgGrid:Helpers:CustomAgg:setCustomAggregations', () => {
  let params = getDefaultParams()

  beforeEach(() => {
    params = getDefaultParams()
  })

  it('should return custom aggregation functions', () => {
    const result = setCustomAggregations(params, defaultState, defaultSetState)
    expect(Object.keys(result)).toHaveLength(2)
  })

  it('should call setState if there are failed aggregations', () => {
    params.columnDefs[0].customInfo = {
      customAggregation: window.btoa("throw new Error('Test error')")
    } as SlvyColDef['customInfo']
    setCustomAggregations(params, defaultState, defaultSetState)
    expect(defaultSetState).toHaveBeenCalled()
  })

  it('should not call setState if state is false even there are failed aggregations', () => {
    params.columnDefs[0].customInfo = {
      customAggregation: window.btoa("throw new Error('Test error')")
    } as SlvyColDef['customInfo']
    setCustomAggregations(params, false, defaultSetState)
    expect(defaultSetState).not.toHaveBeenCalled()
  })

  it('should not call setState if there are no failed aggregations', () => {
    setCustomAggregations(params, defaultState, defaultSetState)
    expect(defaultSetState).not.toHaveBeenCalled()
  })
})
