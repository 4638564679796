import React, { useState } from 'react'
import _ from 'lodash'
import Pantone from './Pantone'
import { MESSAGES } from '../messages'

const PantoneListSection = ({
  colorList = [],
  pluginId,
  designId,
  columnHeightValue,
  sectionName,
  selectedMainColorId = undefined,
  isMainColor = false,
  readOnly = false,
  isMaterialList,
  onEdit = () => {},
  showModal = () => {},
  afterDelete = () => {},
  onColorClick = () => {},
  client = () => {}
}) => {
  const [showList, setShowList] = useState(sectionName === MESSAGES.main_colors)

  function getListStyle() {
    return {
      visibility: showList ? 'visible' : 'hidden',
      height: showList ? 'auto' : 0,
      opacity: showList ? 1 : 0
    }
  }

  const materialTypeID = _.size(colorList) > 0 ? colorList[0].MaterialTypeID : 0
  const isMaterialFull =
    _.size(colorList) > 0
      ? !isMainColor && !colorList[0].IsMaterialMultiple && !_.isNil(colorList[0].PantoneCode)
      : false

  return (
    <div>
      <div
        className="list-header mt-2 d-flex cp align-items-center justify-content-between flex-row"
        onClick={() => setShowList((prev) => !prev)}
      >
        <div className="section-name-container d-inline-flex flex-row justify-content-start align-items-center">
          <i
            className="section-arrow fs-md me-2 fa fa-caret-down"
            style={{ display: showList ? 'inline-block' : 'none' }}
          />
          <i
            className="section-arrow fs-md me-2 fa fa-caret-right"
            style={{ display: showList ? 'none' : 'inline-block' }}
          />
          <div className="section-name fs-sm fw-semibold">{sectionName}</div>
        </div>
        <div
          className="smallbox-container d-inline-flex flex-row justify-content-end"
          style={{ opacity: showList ? 0 : 1 }}
        >
          {_.map(colorList, (color, index) => {
            const { PantoneCode, Red, Green, Blue } = color
            return (
              <div
                key={index}
                className="smallcolor-box rounded-1 me-1"
                style={{
                  backgroundColor:
                    _.isNull(PantoneCode) || _.isNull(Red) || _.isNull(Green) || _.isNull(Blue)
                      ? `#fff`
                      : `rgb(${Red},${Green},${Blue})`
                }}
              />
            )
          })}
        </div>
      </div>
      <ul className="p-list d-flex flex-wrap pantone" style={getListStyle()}>
        {_.map(colorList, (color, index) => {
          if (color.ColorId) {
            return (
              <Pantone
                key={index}
                afterDelete={afterDelete}
                client={client}
                columnHeightValue={columnHeightValue}
                data={color}
                designId={designId}
                isMainColor={isMainColor}
                isMaterialList={isMaterialList}
                pluginId={pluginId}
                readOnly={readOnly}
                selectedMainColorId={selectedMainColorId}
                onColorClick={onColorClick}
                onEdit={onEdit}
              />
            )
          }
          return null
        })}
        {!readOnly && !isMaterialFull && (
          <Pantone
            isForAddColor
            client={client}
            isMaterialFull={isMaterialFull}
            isMaterialList={isMaterialList}
            materialTypeID={materialTypeID}
            showModal={showModal}
          />
        )}
      </ul>
    </div>
  )
}

export default PantoneListSection
