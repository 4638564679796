import _ from 'lodash'

import { defaultTemplate } from './defaultTemplate'

export { defaultTemplate }

export const isVisible = (schema) => {
  // TODO : refactor

  if (schema.type === 'array' && schema.format === 'HiddenTable') {
    return false
  }
  if (schema.options && schema.options.hidden) {
    return false
  }

  return true
}

export const isContainer = (schema) => {
  // TODO : refactor

  if (schema.type === 'object' && schema.format === 'Style') {
    return false
  }
  if (schema.type === 'object' || schema.type === 'array') {
    return true
  }
  if (schema.oneOf) {
    // TODO : return false and fix design
    return true
  }

  return false
}

export const visitSchema = (schema, value) => {
  const newValue = { ...value }
  for (const key in schema.properties) {
    const subSchema = schema.properties[key]

    if (subSchema.type === 'object') {
      newValue[key] = visitSchema(subSchema, newValue[key] || subSchema.default || {})
    } else if (subSchema.type === 'array') {
      newValue[key] = newValue[key] || subSchema.default || []
    } else if (subSchema.oneOf) {
      if (_.isUndefined(newValue[key])) {
        newValue[key] = visitSchema(subSchema.oneOf[0], subSchema.oneOf[0].default || {})
      }
    } else if (subSchema.enum && subSchema.enum.length === 1) {
      if (_.isUndefined(newValue[key])) {
        newValue[key] = subSchema.enum[0]
      }
    } else if (_.isUndefined(newValue[key]) && !_.isUndefined(subSchema.default)) {
      newValue[key] = subSchema.default
    }
  }

  return newValue
}
