import { Component } from 'react'

export default class Tab extends Component {
  constructor() {
    super()
    this.state = {
      isDragOver: false
    }

    this.handleDragOver = this.handleDragOver.bind(this)
    this.handleDragLeave = this.handleDragLeave.bind(this)
    this.handleDragStart = this.handleDragStart.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
  }

  handleDragOver() {
    const {
      moveData: { id },
      environment
    } = this.props

    const isItself = id === this.id
    if (isItself) return

    if (environment !== 'Configuration') return
    this.setState({ isDragOver: true })
  }

  handleDragLeave() {
    this.setState({ isDragOver: false })
  }

  handleDragStart(event) {
    const {
      moveData: { id, menuId, catalogId },
      onDragStart
    } = this.props

    this.id = id
    onDragStart(id, menuId, catalogId, event)
  }

  handleDrop(event) {
    const {
      moveData: { index, id },
      onDrop
    } = this.props

    onDrop(index, id, event)
  }

  render() {
    const { props: { children, className } = {}, state: { isDragOver } = {} } = this
    return (
      <div
        draggable
        className={`${className} ${isDragOver ? 'is-drag-overed' : ''}`}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDragStart={this.handleDragStart}
        onDrop={this.handleDrop}
      >
        {children}
      </div>
    )
  }
}
