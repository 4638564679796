/* eslint-disable jest/no-export */
import { MarkerFormatterParams } from 'ag-grid-community'
import { SparklineOptions } from '../../../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'

export const columnType = 'discrete'

export const sparklineOptions: SparklineOptions = {
  columnColor: '#ddd',
  highlightColor: '#f44',
  lineColor: '#157fcc',
  lineWidth: 1,
  maxSpotColor: '#f85',
  minSpotColor: '#f83',
  negBarColor: '#f44',
  spotColor: '#f81',
  thresholdColor: '#f82',
  type: 'sparklineline',
  zeroColor: '#f88'
}

export const sparklineParams: MarkerFormatterParams = {
  datum: {
    seriesDatum: {
      x: {},
      y: 60
    },
    point: {
      x: 13.25,
      y: 128
    }
  },
  xValue: {},
  yValue: 60,
  min: true,
  max: false,
  first: true,
  last: false,
  fill: 'rgb(124, 181, 236)',
  stroke: 'rgb(124, 181, 236)',
  strokeWidth: 1,
  size: 0,
  highlighted: false
}

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
