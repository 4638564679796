export default `({
    columnLines: true,
    multiColumnSort: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    plugins: [{
            ptype: 'rowexpander',
            rowBodyTpl: [
                '<div class="expander">',
                '<table class="table">',
                '<tr>',
                '</tr>',
                '  <tr>',
                '       <th class=r>'+tt('Merkez')+'</th>',
                '      <td class="r">{OrderRecommendationParent}</td>',
                '       <th class="r">'+tt('Bölge')+'</th>',
                '      <td class="r">{OrderRecommendationCurrent}</td>',
                '      <th class="r">'+tt('Satış Md.')+'</th>',
                '      <td class="r">{OrderRecommendationChild}</td>',
                '   </tr>',
                '</tr>',
                '</table>',
                '</div>',
            ]
        }, {
            ptype: 'cornerIcon',
            extraButton: [
                '->',
                {
                    iconCls: 'x-fa fa-arrows-alt',
                    handler: this.onmaximize,
                    scope: this
                }
            ],
            cfgButton: [{
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigureSpotGrid',
            }]
        },
        {
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
                beforeedit: function (editor, context) {
                    rec = context.record;
                    
                    if(rec.get('IsLocked'))
                        return false;

                    return rec.get('IsEditable');
                },
                edit: function (editor, context, eOpts) {
                    var me = this;
                    var record = context.record;
                    if (context.originalValue * 1 == context.value * 1) {
                        return;
                    }
                    
                    grid = context.grid.ownerCt;
                    tracking = context.grid.up('spui-view-tracking-navigation-main')
                    grid.setLoading(tt('yükleniyor'));

                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/DCChangeStatusControl',
                        method: 'POST',
                        timeout: 600000,
                        params: {
                            spotId: record.data.SpotId
                        },
                        success: function (response, opts) {                                
                            if(Ext.decode(response.responseText)==true)
                            {
                                record.save({
                                    params: {
                                        updateAction: 'DCValueChange'
                                    },
                                    callback: function (record, operation, success) {
                                        // do something whether the save succeeded or failed
                                        // @TODO v2 response
                                        var result = operation._response.responseJson
                                        if (!result.IsSuccess) {
                                            Ext.Msg.alert(
                                                tt('Durum'),
                                                tt('Minimum Siparşi Miktarının Altında Bir değer Girdiniz. Minimum sipariş miktarı: ') + result.Message
                                            )
                                            record.set('OrderRecommendationCurrent', record.previousValues.OrderRecommendationCurrent)
                                            record.commit()
                                        }
                                        
                                        if(result.Recommendation)
                                        {
                                            record.set('OrderRecommendationCurrent', result.Recommendation)
                                            record.commit()
                                        }
            
                                        var mainView = null,
                                            recommView = null,
                                            viewModel = null,
                                            store = null
                                        mainview = grid.up('spuimainview')
                                        if (mainview) {
                                            recommView = mainview.down('spui-view-common-recom-distribution-main')
                                            if (recommView) {
                                                viewModel = recommView.getViewModel()
                                                if (viewModel) {
                                                    store = viewModel.get('recomStore')
                                                }
                                            }
                                            else
                                            {
                                                recommView = mainview.down('spui-view-common-recom-main')
                                                if (recommView) {
                                                    store = recommView.getRecomStore()
                                                }
                                            }
                                            if (store) {
                                                store.load()
                                            }
                                        }
                                        grid.setLoading(false);
                            grid.onRefrehTracking()
                                    }
                                });
                            }
                            else
                            {
                                grid.setLoading(false);
                                Ext.Msg.alert(
                                    tt('Durum'),
                                    tt('Your changes could not be saved because the changes were made by the HQ Purchasing Team. Try again by refreshing the page.')
                                  )
                            }
                        },
                        failure: function (response, opts) {
                            console.log('server-side failure with status code ' + response.status);
                            
                            grid.setLoading(false);
                        }
                    });
                },
                scope: 'this'
            }
        },
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    columns: [
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            hideable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-thumbs-up blue',
                tooltip: tt('Accept'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeStatus(record, 'Approve')
                }
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            hideable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa  fa-thumbs-down red',
                tooltip: tt('Reject'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    me.onChangeStatus(record, 'Dismiss')
                },
            }]
        },
        {
            text: tt('RemainingDaysForApprove'),
            dataIndex: 'RemainingDaysForApprove',
            filter: {
                type: 'number'
            },
            width: 70,
            format: '000',
            align: 'right',
            renderer: function (value, metaData, record) {
                var DaysForApprove = record.get('DaysForApprove');
                return value + " / " + DaysForApprove
            }
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('Start Date'),
            dataIndex: 'PreStartDate',
            filter: {
                type: 'date'
            },
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90'
        },
        {
            text: tt('End Date'),
            dataIndex: 'PreEndDate',
            filter: {
                type: 'date'
            },
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90'
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '000.00',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('Merkez'),
            dataIndex: 'OrderRecommendationParent',
            
            filter: {
                type: 'number'
            },
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
        },
        {
            text: tt('Bölge'),
            dataIndex: 'OrderRecommendationCurrent',
            filter: {
                type: 'number'
            },
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            tdCls: 'blue',
            editor: {
                // completeOnEnter: false,
                // If the editor config contains a field property, then
                // the editor config is used to create the Ext.grid.CellEditor
                // and the field property is used to create the editing input field.
                field: {
                    xtype: 'numberfield',
                    allowBlank: false
                }
            },
        },
        // {
    ],
    onAccept: function () {},
    onDelegateStatus: function (record, newStatus, me) {
        var win = new Ext.Window({
            title: tt('Onay'),
            modal: true,
            bscope: me,
            layout: 'fit',
            items: [{
                xtype: 'form',
                itemId: 'myForm',
                autoheight: true,
                width: 350,
                margin: 1,
                padding: 5,
                border: false,
                //unstyled: true,
                items: [{
                    xtype: 'numberfield',
                    labelWidth: 140,
                    anchor: '100%',
                    name: 'bottles',
                    fieldLabel: tt('DaysForApprove'),
                    value: 1,
                    maxValue: record.get('RemainingDaysForApprove'),
                    step: 1,
                    minValue: 1
                }],
            }],
            bbar: [
                '->',
                {
                    text: tt('Okey'),
                    handler: function (a, b, c) {
                        var w = a.up('window');
                        me = w.bscope;
                        f = w.down('numberfield');
                        DaysForApprove = f.getValue();
                        w.close();
                        var data = Ext.encode(record.data);
                        me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));
                        Ext.Ajax.request({
                            url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                            method: 'POST',
                            timeout: 600000,
                            params: {
                                data: data,
                                newStatus: newStatus,
                            },
                            success: function (response, opts) {
                                me.up('spui-view-tracking-tracking').setLoading(false);
                                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            },
                            failure: function (response, opts) {
                                console.log('server-side failure with status code ' + response.status);
                                me.up('spui-view-tracking-tracking').setLoading(false);
                                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            }
                        });
                    }
                },
                {
                    text: tt('Cancel'),
                    handler: function (a, b, c) {
                        a.up('window').close();
                    }
                }
            ]
        });
        win.show();
    },
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        var msg = tt('Are you sure you want to dismiss this record? <br><br>')
        if (newStatus === 'Approve') {
            msg = tt('Are you sure you want to approve this record? <br><br>')
        }
        me = this;
        Ext.Msg.show({
            title: tt('Onay'),
            //height: 160,
            width: 500,
            msg: msg,
            icon: Ext.MessageBox.QUESTION,
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            buttons: Ext.Msg.YESNO,
            fn: function (response) {
                if (response === 'yes') {
                    me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));

                        Ext.Ajax.request({
                            url: Spui.Global.getApiUrl() + '/spui/DCChangeStatusControl',
                            method: 'POST',
                            timeout: 600000,
                            params: {
                                spotId: record.data.SpotId
                            },
                            success: function (response, opts) {                                
                                if(Ext.decode(response.responseText)==true)
                                {
                                    Ext.Ajax.request({
                                        url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                                        method: 'POST',
                                        timeout: 600000,
                                        params: {
                                            data: data,
                                            newStatus: newStatus
                                        },
                                        success: function (response, opts) {
                                            me.up('spui-view-tracking-tracking').setLoading(false);
                                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                        },
                                        failure: function (response, opts) {
                                            console.log('server-side failure with status code ' + response.status);
                                            me.up('spui-view-tracking-tracking').setLoading(false);
                                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                        }
                                    });
                                }
                                else
                                {
                                    me.up('spui-view-tracking-tracking').setLoading(false);
                                    Ext.Msg.alert(
                                        tt('Durum'),
                                        tt('Your changes could not be saved because the changes were made by the HQ Purchasing Team. Try again by refreshing the page.')
                                      )
                                }
                            },
                            failure: function (response, opts) {
                                console.log('server-side failure with status code ' + response.status);
                                me.up('spui-view-tracking-tracking').setLoading(false);
                                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                            }
                        });
                
                    
                    
                }
            }
        })
    }
})
`
