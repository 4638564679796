import { useCallback, useEffect } from 'react'
import { ClearAllFiltersProps } from './useRegisterMethodClearAllFilters.type'

function useRegisterMethodClearAllFilters({ gridRef, registerMethod }: ClearAllFiltersProps) {
  const onClearAllFilters = useCallback(
    () => gridRef?.current?.api?.setFilterModel?.(null),
    [gridRef]
  )

  useEffect(() => {
    registerMethod({
      args: [],
      key: 'clearAllFilters',
      fn: onClearAllFilters
    })
  }, [onClearAllFilters, registerMethod])

  return { onClearAllFilters }
}

export default useRegisterMethodClearAllFilters
