Ext.define('clustering.ClusterLibrary.ClusterLibrary', {
  extend: 'Ext.window.Window',
  alias: 'widget.clusterLibrary',
  modal: true,
  layout: 'border',
  height: 500,
  width: 800,
  title: 'Cluster Library',
  ui: 'window-inverse',
  referenceHolder: true,
  viewModel: {
    data: {
      currentRow: null
    },
    formulas: {
      isFolderSelected: function (get) {
        var currentRow = get('currentRow')
        if (currentRow) {
          return !currentRow.get('IsLeaf')
        }
        return false
      },
      isLeafSelected: function (get) {
        var currentRow = get('currentRow')
        if (currentRow) {
          return currentRow.get('IsLeaf')
        }
        return false
      }
    }
  },
  items: [
    {
      xtype: 'panel',
      region: 'center',
      layout: 'fit',
      margin: 10,
      overflowY: 'auto',
      items: [
        {
          xtype: 'clusterTree'
        }
      ]
    }
  ]
})
