import { useEffect, useRef } from 'react'
import { useBeforeUnload, useLocation } from 'react-router-dom'
import request from 'superagent'
import moment from 'moment'
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'
import { API_URL } from '@/constants'

const INITIAL_REQUEST_TIMEOUT = 15_000
const INITIAL_TIMER_TIMEOUT = 74_000
const storeRegex = /\/store\/\d+\//

const events = ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown']

export default function GAContainer({
  PageTitle,
  MenuName,
  Customer,
  PageName,
  CatalogName,
  Environment
}) {
  const isFirstRequest = useRef(true)
  const intervalRef = useRef(null)

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      // eslint-disable-next-line no-use-before-define
      sendUserReport()
    }, INITIAL_REQUEST_TIMEOUT)
  }

  const stopInterval = () => {
    clearInterval(intervalRef.current)
    intervalRef.current = null
  }

  const { isIdle, getActiveTime, reset } = useIdleTimer({
    timeout: INITIAL_TIMER_TIMEOUT,
    events,
    eventsThrottle: 500,
    onActive: () => {
      stopInterval()
      startInterval()
    }
  })

  const { pathname } = useLocation()

  const sendUserReport = (options = {}) => {
    const { url = window.location.href, activeTime = null } = options

    if (!activeTime && (isIdle() || !document.hasFocus())) {
      return
    }

    const date = moment().format('yyyy.MM.DD')
    const PageViews = isFirstRequest.current ? 1 : 0
    const urlWithoutStore = url.replace(storeRegex, '/')
    const TimeOnPage = activeTime ?? INITIAL_REQUEST_TIMEOUT

    request
      .post(`${API_URL}/userreport`)
      .send({
        HostName: window.location.hostname,
        Url: urlWithoutStore,
        PageTitle,
        Date: date,
        PageViews,
        TimeOnPage,
        Environment,
        CatalogName,
        MenuName,
        PageName,
        Company: null,
        Customer
      })
      .then(() => {
        isFirstRequest.current = false
      })
  }

  useEffect(() => {
    startInterval()

    const handleFocus = () => {
      if (!intervalRef.current) {
        reset()
        startInterval()
      }
    }

    const handleBlur = () => {
      sendUserReport({ activeTime: getActiveTime() % INITIAL_REQUEST_TIMEOUT })
      stopInterval()
    }

    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)
    return () => {
      stopInterval()
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useBeforeUnload(() => sendUserReport({ url: `${window.location.origin}${pathname}` }))

  return null
}
