import { replaceTemplate } from '../common'
import { AgGridProps, IRowData, SlvyColDef, SlvyColGroupDef, TformattedFields } from '../../types'
import { AG_GRID_ENUMS } from '../../enums'

export const getColumnDefsWithHeaderGroups = (
  columnDefsWithoutGroupHeaders: SlvyColDef[],
  firstAgGridRowData: IRowData,
  getFormattedValue: AgGridProps['getFormattedValue'],
  formattedFields: TformattedFields
): SlvyColGroupDef[] => {
  const newColDefWithGroupHeaders: Array<SlvyColGroupDef> = []

  columnDefsWithoutGroupHeaders.forEach((columnDef) => {
    const { customInfo: { groupHeader: columnDefGroupHeader = '' } = {} } = columnDef

    const finalHeaderGroupName = replaceTemplate({
      text: columnDefGroupHeader,
      data: firstAgGridRowData,
      getFormattedValue,
      formattedFields
    })

    const pinned = columnDef?.pinned

    if (!finalHeaderGroupName || pinned) {
      if (finalHeaderGroupName) {
        // eslint-disable-next-line no-param-reassign
        columnDef.customInfo.unGroupPinned = true
      }
      newColDefWithGroupHeaders.push(columnDef as SlvyColGroupDef)
    } else {
      const currentColDefGroupIndex = newColDefWithGroupHeaders.findIndex((colDef) => {
        const isGroupHeaderEqual = colDef?.headerName === finalHeaderGroupName

        const doesGroupHeaderExist =
          typeof colDef?.children !== 'undefined' ? true : colDef?.customInfo?.groupHeader

        const unGroupPinned = colDef?.customInfo?.unGroupPinned ?? false
        return Boolean(isGroupHeaderEqual && doesGroupHeaderExist) && !unGroupPinned
      })

      if (newColDefWithGroupHeaders.length > 0 && currentColDefGroupIndex !== -1) {
        newColDefWithGroupHeaders[currentColDefGroupIndex].children.push(columnDef)

        const isAllChildLeftPinned = newColDefWithGroupHeaders[
          currentColDefGroupIndex
        ].children.every((child) => {
          return (child as SlvyColDef)?.pinned === 'left'
        })

        const isAllChildRightPinned = newColDefWithGroupHeaders[
          currentColDefGroupIndex
        ].children.every((child) => {
          return (child as SlvyColDef)?.pinned === 'right'
        })

        newColDefWithGroupHeaders[currentColDefGroupIndex].children = newColDefWithGroupHeaders[
          currentColDefGroupIndex
        ].children.map((child) => {
          let $pinned: SlvyColDef['pinned'] = false
          if (isAllChildLeftPinned) {
            $pinned = 'left'
          } else if (isAllChildRightPinned) {
            $pinned = 'right'
          }

          return {
            ...child,
            pinned: $pinned, // TODO: This should be false, group column cannot be pinned
            // lockPinned: true,
            hasColumnGroup: true
          }
        })
      } else {
        newColDefWithGroupHeaders.push({
          colId: finalHeaderGroupName as AG_GRID_ENUMS.CUSTOM_ROW_INFO,
          headerName: finalHeaderGroupName,
          hide: false,
          customInfo: {},
          headerClass: 'justify-content-center',
          marryChildren: true,
          children: [
            {
              ...columnDef,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              customInfo: {
                ...columnDef?.customInfo,
                groupHeader: finalHeaderGroupName,
                hasColumnGroup: true
                // lockPinned: true
              }
            }
          ]
        })
      }
    }
  })

  return newColDefWithGroupHeaders
}

export default { getColumnDefsWithHeaderGroups }
