Ext.define('MDM.src.view.globalapp.Panel.Publish.model.GeoModel', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Global_MDM_Publish_Changesd',
  fields: [
    {
      name: 'Prev',
      type: 'int'
    },
    {
      name: 'Current',
      type: 'int'
    },
    {
      name: 'Next',
      type: 'int'
    },
    {
      name: 'TextChange',
      type: 'int'
    },
    {
      name: 'NodeChange',
      type: 'int'
    },
    {
      name: 'Change',
      convert: function (value, record) {
        return record.get('Next') - record.get('Current')
      },
      depends: ['Current', 'Next']
    }
  ]
})
