/**
 * Serie class for error bar series type
 *
 * See {@link Chart.ux.Highcharts.RangeSerie} class for more info
 */
Ext.define('Chart.ux.Highcharts.ErrorBarSerie', {
  extend: 'Chart.ux.Highcharts.RangeSerie',
  alternateClassName: ['highcharts.errorbar'],
  type: 'errorbar'
})
