import _ from 'lodash'
import { updateObject } from './utility'
import * as actionTypes from '../actions/actionTypes.js'

const defaultFilterValues = {
  periodIndex: null,
  tableName: null,
  isResourceBased: false,
  operator: ' ',
  value: 0,
  format: '0,0'
}

const initialState = {
  isProductionResourceBased: false,
  sortConfig: {},
  configSettings: {},
  pinnedRows: [],
  runInfo: {},
  coverViolationIndexes: [],
  filterValues: defaultFilterValues,
  weeklySort: {
    periodIndex: null,
    issueName: null,
    isAscending: null
  },
  sessions: [],
  selectedSession: {},
  isGrandTotalPinned: true
}

const resourcePlan = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_RESOURCE_BASED_PRODUCTION:
      return updateObject(state, {
        ...state,
        isProductionResourceBased: !state.isProductionResourceBased
      })
    case actionTypes.ON_SORT:
      return updateObject(state, { ...state, sortConfig: action.sortConfig })
    case actionTypes.SET_CONFIG_SETTINGS:
      return updateObject(state, {
        ...state,
        configSettings: { ...action.configSettings }
      })
    case actionTypes.ON_PIN_ROW:
      return _.some(state.pinnedRows, (item) => item.Index === action.pinnedRow.Index)
        ? updateObject(state, {
            ...state,
            pinnedRows: _.reject(state.pinnedRows, (item) => item.Index === action.pinnedRow.Index)
          })
        : updateObject(state, {
            ...state,
            pinnedRows: [...state.pinnedRows, action.pinnedRow]
          })
    case actionTypes.SET_RUN_INFO:
      return updateObject(state, { ...state, runInfo: action.runInfo })
    case actionTypes.SET_COVER_VIOLATION_INDEXES:
      return updateObject(state, {
        coverViolationIndexes: action.payload.coverViolationIndexes
      })

    case actionTypes.FILTER_VALUES:
      if (
        state.filterValues.periodIndex === action.payload.periodIndex &&
        state.filterValues.tableName === action.payload.tableName &&
        state.filterValues.operator === action.payload.operator &&
        state.filterValues.value === action.payload.value &&
        !action.payload.isChange
      ) {
        return updateObject(state, {
          ...state,
          filterValues: defaultFilterValues
        })
      }
      return updateObject(state, {
        ...state,
        filterValues: {
          periodIndex: action.payload.periodIndex,
          tableName: action.payload.tableName,
          isResourceBased: action.payload.isResourceBased,
          operator: action.payload.operator,
          value: action.payload.value,
          format: action.payload.format
        }
      })

    case actionTypes.RESET_FILTER_VALUES:
      return updateObject(state, {
        ...state,
        filterValues: defaultFilterValues
      })

    case actionTypes.WEEKLY_SORT:
      return updateObject(state, {
        weeklySort: {
          periodIndex: action.payload.periodIndex,
          issueName: action.payload.issueName,
          isAscending: action.payload.isAscending
        }
      })
    case actionTypes.SELECT_SESSION:
      return updateObject(state, { selectedSession: action.payload.session })
    case actionTypes.SET_SESSIONS:
      return updateObject(state, { sessions: action.payload.sessions })
    case actionTypes.TOGGLE_GRAND_TOTAL_PIN:
      return updateObject(state, { isGrandTotalPinned: !state.isGrandTotalPinned })
    default:
      return state
  }
}

const PRODUCTresourcePlan = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_TOGGLE_RESOURCE_BASED_PRODUCTION:
      return updateObject(state, {
        ...state,
        isProductionResourceBased: !state.isProductionResourceBased
      })
    case actionTypes.PRODUCT_ON_SORT:
      return updateObject(state, { ...state, sortConfig: action.sortConfig })
    case actionTypes.PRODUCT_SET_CONFIG_SETTINGS:
      return updateObject(state, {
        ...state,
        configSettings: { ...action.configSettings }
      })
    case actionTypes.PRODUCT_ON_PIN_ROW:
      return _.some(state.pinnedRows, (item) => item.Index === action.pinnedRow.Index)
        ? updateObject(state, {
            ...state,
            pinnedRows: _.reject(state.pinnedRows, (item) => item.Index === action.pinnedRow.Index)
          })
        : updateObject(state, {
            ...state,
            pinnedRows: [...state.pinnedRows, action.pinnedRow]
          })
    case actionTypes.PRODUCT_SET_RUN_INFO:
      return updateObject(state, { ...state, runInfo: action.runInfo })
    case actionTypes.PRODUCT_SET_COVER_VIOLATION_INDEXES:
      return updateObject(state, {
        coverViolationIndexes: action.payload.coverViolationIndexes
      })

    case actionTypes.PRODUCT_FILTER_VALUES:
      if (
        state.filterValues.periodIndex === action.payload.periodIndex &&
        state.filterValues.tableName === action.payload.tableName &&
        state.filterValues.operator === action.payload.operator &&
        state.filterValues.value === action.payload.value &&
        !action.payload.isChange
      ) {
        return updateObject(state, {
          ...state,
          filterValues: defaultFilterValues
        })
      }
      return updateObject(state, {
        ...state,
        filterValues: {
          periodIndex: action.payload.periodIndex,
          tableName: action.payload.tableName,
          isResourceBased: action.payload.isResourceBased,
          operator: action.payload.operator,
          value: action.payload.value,
          format: action.payload.format
        }
      })

    case actionTypes.PRODUCT_WEEKLY_SORT:
      return updateObject(state, {
        weeklySort: {
          periodIndex: action.payload.periodIndex,
          issueName: action.payload.issueName,
          isAscending: action.payload.isAscending
        }
      })
    default:
      return state
  }
}

export { resourcePlan, PRODUCTresourcePlan }
