import React, { Component } from 'react'
import createPlugin, { PluginTypes } from '../../BasePlugin'
import './Box.scss'
import _ from 'lodash'

class Box extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      subtitle: ''
    }
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.isNilOrEmpty = this.isNilOrEmpty.bind(this)
    this.getData = this.getData.bind(this)
  }

  getReturnTypes = (fields) =>
    _.transform(
      fields,
      (result, { dataType = '', fieldName = '' }) =>
        (result[fieldName] = PluginTypes.fromString(dataType)),
      {}
    )

  UNSAFE_componentWillMount() {
    const { settings: { query: { fields } = {} } = {} } = this.props

    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setSubTitle',
      fn: this.setSubTitle.bind(this),
      args: [{ name: 'subtitle', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setValue',
      fn: this.setValue.bind(this),
      args: [{ name: 'value', type: PluginTypes.int }]
    })

    this.props.registerMethod({
      key: 'setSubValue',
      fn: this.setSubTitle.bind(this),
      args: [{ name: 'subvalue', type: PluginTypes.int }]
    })

    const returnTypes = this.getReturnTypes(fields)

    this.handleDoubleClick = this.props.registerEvent({
      key: 'DoubleClick',
      fn: this.handleDoubleClick,
      returnTypes
    })

    this.handleClick = this.props.registerEvent({
      key: 'SingleClick',
      fn: this.handleClick,
      returnTypes
    })
  }

  handleDoubleClick() {
    const { pluginData, settings: { query: { fields } = {} } = {} } = this.props
    if (fields) {
      const data = _.first(pluginData)
      return _.transform(
        fields,
        (result, field) => {
          result[field.fieldName] = data[field.fieldName]
        },
        {}
      )
    }
    return {}
  }

  handleClick() {
    const { pluginData, settings: { query: { fields } = {} } = {} } = this.props
    if (fields) {
      const data = _.first(pluginData)
      return _.transform(
        fields,
        (result, field) => {
          result[field.fieldName] = data[field.fieldName]
        },
        {}
      )
    }
    return {}
  }

  isNilOrEmpty = (row, field, isFormat) => {
    if (!_.isNil(field) && row.hasOwnProperty(field) && !_.isNil(row[field])) {
      return !((isFormat && row[field] !== '') || !isFormat)
    }
    return true
  }

  valueFormatter = (row, value, valueFormatField) => {
    const { formatValue, getFormattedValue } = this.props
    if (!this.isNilOrEmpty(row, value, false)) {
      if (!this.isNilOrEmpty(row, valueFormatField, true)) {
        return formatValue(row[valueFormatField], row[value])
      }
      return getFormattedValue(value, row[value])
    }
  }

  getData() {
    const row = _.first(this?.props?.pluginData || [])

    const data = {
      value: '',
      subvalue: '',
      icon: '',
      title: '',
      subtitle: '',
      backgroundcolor: '',
      fontcolor: ''
    }

    if (row) {
      const {
        settings: {
          config: {
            data: {
              value: valueField,
              valueFormatField,
              subvalue: subValueField,
              subValueFormatField,
              icon,
              title,
              subtitle,
              backgroundcolor,
              fontcolor
            } = {}
          } = {}
        } = {},
        getFormattedValue
      } = this.props

      data.value = this.valueFormatter(row, valueField, valueFormatField)
      data.subvalue = this.valueFormatter(row, subValueField, subValueFormatField)
      data.icon = row[icon]
      data.title = title && title in row ? getFormattedValue(title, row[title]) : ''
      data.subtitle = subtitle && subtitle in row ? getFormattedValue(subtitle, row[subtitle]) : ''
      data.backgroundcolor = row[backgroundcolor]
      data.fontcolor = row[fontcolor]
    }

    return data
  }

  setTitle = ({ title }) => this.state.title !== title && this.setState({ title })

  setValue = ({ value }) => this.state.value !== value && this.setState({ value })

  setSubTitle = ({ subtitle }) => this.state.subtitle !== subtitle && this.setState({ subtitle })

  getBackgroundColor = (
    backgroundColor,
    { firstColor = 'transparent', secondColor = 'transparent' }
  ) => ({
    backgroundColor,
    backgroundImage: `linear-gradient(180deg, ${firstColor} 10%, ${secondColor} 100%)`
  })

  generateFontStyle = (fontSize) => ({ fontSize: `${fontSize}em` })

  getIcon = (icon, iconStyle) => icon && <i className={`fa ${icon}`} style={iconStyle} />

  render() {
    const {
      textAlign,
      itemsDistribution,
      paddingValue,
      paddingTarget,
      doubleClickEnabled = true,
      cursorClickPointer = false,
      backgroundGradientColors = {},
      iconStyle: {
        isIconEmbedded = false,
        iconSize = 1.4,
        embeddedIconSize = 50,
        embeddedIconPosition = 'left'
      } = {},
      textStyle: {
        titleTextSize = 2,
        valueTextSize = 3.2,
        valueTextFontWeight = 'bold',
        subTitleTextSize = 1.4
      } = {}
    } = this?.props?.settings?.config?.settings || {}
    const data = this.getData()

    if (this.props.settings && this.props.settings.config) {
      var { config: { settings: { title, subtitle, fontcolor, backgroundcolor } = {} } = {} } =
        this.props.settings || {}

      if (this.state.title) {
        title = this.state.title
      } else if (data.title !== undefined && data.title !== '') {
        title = data.title
      }

      if (this.state.subtitle) {
        subtitle = this.state.subtitle
      } else if (data.subtitle !== undefined && data.subtitle !== '') {
        subtitle = data.subtitle
      }

      if (data.backgroundcolor !== undefined && data.backgroundcolor !== '') {
        backgroundcolor = data.backgroundcolor
      }

      if (data.fontcolor !== undefined && data.fontcolor !== '') {
        fontcolor = data.fontcolor
      }

      const calculatedPadding = (paddingValue === undefined ? 8 : paddingValue) / 10
      var divStyle = {
        ...this.getBackgroundColor(backgroundcolor, backgroundGradientColors),
        color: fontcolor,
        padding: `${calculatedPadding}vw`
      }

      var textStyle =
        paddingTarget === 'Text Only' || paddingTarget === 'All'
          ? {
              paddingTop: `${calculatedPadding}vw`,
              paddingBottom: `${calculatedPadding}vw`
            }
          : {}
    }
    const { value = data.value } = this.state || {}
    const boxStyle = {
      fontSize: embeddedIconSize * 10,
      marginTop: -(embeddedIconSize / 2) * 10
    }
    boxStyle[embeddedIconPosition.toLowerCase()] = -(embeddedIconSize / 2) * 10

    return (
      <div
        className={`boxPlugin ${textAlign || 'Center'} ${isIconEmbedded ? '-embedded-icon' : ''} ${
          cursorClickPointer ? '-cursor-pointer' : ''
        }`}
        style={divStyle}
        onClick={!doubleClickEnabled ? this.handleClick : () => {}}
        onDoubleClick={doubleClickEnabled ? this.handleDoubleClick : () => {}}
      >
        <div
          className={`box-wrapper ${itemsDistribution || 'Distributed'} ${
            paddingTarget || 'Top Bottom'
          }`}
        >
          <h2
            dangerouslySetInnerHTML={{ __html: title }}
            className="subject"
            style={{ ...textStyle, ...this.generateFontStyle(titleTextSize) }}
          />

          {isIconEmbedded && iconSize > 0 && (
            <div className="-main-icon">
              {this.getIcon(data.icon, this.generateFontStyle(iconSize))}
            </div>
          )}

          <h1
            dangerouslySetInnerHTML={{ __html: value }}
            className="value"
            style={{
              ...textStyle,
              ...this.generateFontStyle(valueTextSize),
              fontWeight: valueTextFontWeight
            }}
          />

          <div
            className="subValue"
            style={{ ...textStyle, ...this.generateFontStyle(subTitleTextSize) }}
          >
            {!isIconEmbedded && this.getIcon(data.icon, {})}
            <span
              dangerouslySetInnerHTML={{
                __html: data.subvalue ? `${data.subvalue} ${subtitle}` : subtitle
              }}
            ></span>
          </div>
        </div>
        {isIconEmbedded && embeddedIconSize > 0 && (
          <div className="-big-icon">{this.getIcon(data.icon, boxStyle)}</div>
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  settings: props.settings,
  registerMethod: props.registerMethod,
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  getFormattedValue: props.getFormattedValue,
  formatValue: props.formatValue
})

export default createPlugin(Box, selectConnectorProps)
