import { Row } from 'react-bootstrap'
import { slvyToast } from '@/components'
import BaseModal from '../../BaseModal'
import { useReduxState } from '../../../hooks'
import { loadTreeTableSlice, tuiSlice } from '../../../store/slices'
import { getDemandTree, getLoadTree, removeLoad, removeLoads } from '../../../store/api/endpoints'
import { getLoadNames, getTreeQueryParams } from '../../../utils'
import styles from './RemoveLoadModal.module.scss'
import CommonModalFields from '../CommonModalFields'

function RemoveLoadModal({ selectedLoadRow, endpoint = 'loads' }) {
  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)
  const [
    { isRemoveLoadModalOpen, selectedRows, selectedReasonCodeId },
    { setIsRemoveLoadModalOpen }
  ] = useReduxState(loadTreeTableSlice.selectSlice, loadTreeTableSlice.actions)
  const [triggerRemoveLoad] = removeLoad.useLazyQuery()
  const [triggerRemoveLoads] = removeLoads.useLazyQuery()
  const [triggerGetDemandTree] = getDemandTree.useMutation({ fixedCacheKey: 'demand-tree' })
  const [triggerGetLoadTree] = getLoadTree.useMutation({
    fixedCacheKey: 'load-tree'
  })

  const uniqueLoadNames = getLoadNames(selectedLoadRow, selectedRows)?.join(', ')

  function handleClickCloseModal() {
    setIsRemoveLoadModalOpen(false)
  }

  function getBodyContent() {
    if (!uniqueLoadNames && endpoint === 'loads') {
      return <span className="fs-6">Please select the loads to be removed.</span>
    }

    return (
      <>
        <CommonModalFields />
        <Row>
          <p className={styles.removeLoadModalDescription}>
            Are you sure you want to remove load?{' '}
            {endpoint === 'loads' ? uniqueLoadNames : selectedLoadRow}
          </p>
        </Row>
      </>
    )
  }

  function handleClickConfirmButton() {
    const loadNames =
      endpoint === 'loads' ? getLoadNames(selectedLoadRow, selectedRows) : selectedLoadRow
    const reasonCodeId = Number(selectedReasonCodeId)

    if (endpoint === 'loads') {
      triggerRemoveLoads({ loadNames, reasonCodeId }).then((response) => {
        if (response?.data?.success) {
          triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
          triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })

          slvyToast.success({
            title: 'Information',
            message: 'Operation is completed successfully.'
          })
        } else if (!response?.data?.success) {
          slvyToast.warning({
            title: 'Error',
            message: 'Operation failed.'
          })
        }
      })
    } else {
      triggerRemoveLoad({ loadId: Number(selectedLoadRow), reasonCodeId }).then((response) => {
        if (response?.data?.success) {
          triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
          triggerGetDemandTree({ ...getTreeQueryParams(config, 'demandTree', auth.pluginId) })

          slvyToast.success({
            title: 'Information',
            message: 'Operation is completed successfully.'
          })
        } else if (!response?.data?.success) {
          slvyToast.warning({
            title: 'Error',
            message: 'Operation failed.'
          })
        }
      })
    }
    handleClickCloseModal()
  }

  return (
    <BaseModal isOpen={isRemoveLoadModalOpen} onClose={handleClickCloseModal}>
      <BaseModal.Header>
        <BaseModal.Title>Confirm: Remove Load</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className={styles.removeLoadModal}>{getBodyContent()}</BaseModal.Body>
      <BaseModal.Footer>
        <BaseModal.CancelButton onClick={handleClickCloseModal} />
        {uniqueLoadNames || (endpoint === 'load' && selectedLoadRow) ? (
          <BaseModal.ConfirmButton onClick={handleClickConfirmButton} />
        ) : null}
      </BaseModal.Footer>
    </BaseModal>
  )
}

export default RemoveLoadModal
