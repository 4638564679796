import { isNaN, isEmpty } from 'lodash'
import { scaleLinear, interpolateRgb, color, schemeCategory10 } from 'd3'

const accessToken =
  'pk.eyJ1IjoiZGV2LXNvbHZveW8iLCJhIjoiY2tndzhzZ3plMDd0ajMwb2VtMmdoeGd6cCJ9.D8h3DyVfy1OQC56-W8nXbw'

const presetColors = [
  '#FC5C65',
  '#45AAF2',
  '#26DE81',
  '#FD9644',
  '#FED330',
  '#4A90E2',
  '#2BCBBA',
  '#778CA3',
  '#EB3B5A',
  '#2D98DA',
  '#20BF6B',
  '#FA8231',
  '#F7B731',
  '#3867D6',
  '#4B6584'
]

const defaultColors = [...schemeCategory10.reverse(), ...randomColors(100)]

function averageGeolocation(coords) {
  if (coords.length === 1) {
    return coords[0]
  }

  let x = 0
  let y = 0
  let z = 0

  for (let i = 0; i < coords.length; i += 1) {
    let longitude = coords[i][0]
    let latitude = coords[i][1]

    latitude = (latitude * Math.PI) / 180
    longitude = (longitude * Math.PI) / 180

    x += Math.cos(latitude) * Math.cos(longitude)
    y += Math.cos(latitude) * Math.sin(longitude)
    z += Math.sin(latitude)
  }

  const total = coords.length

  x /= total
  y /= total
  z /= total

  const centralLongitude = Math.atan2(y, x)
  const centralSquareRoot = Math.sqrt(x * x + y * y)
  const centralLatitude = Math.atan2(z, centralSquareRoot)

  const retLat = (centralLatitude * 180) / Math.PI
  const retLon = (centralLongitude * 180) / Math.PI

  return [isNaN(retLon) ? 0 : retLon, isNaN(retLat) ? 0 : retLat]
}

function getConfig(props) {
  const {
    settings: {
      config: {
        settings: {
          zoom = 7,
          moveToUpdate = false,
          askUpdate = false,
          theme,
          isHover = false,
          isSync = false,
          isFastIcon = false,
          controlSettings = {}
        } = {},
        data: {
          id,
          level,
          geometry,
          latitude,
          longitude,
          isCenter,
          zoom: dataZoom,
          iconColor,
          iconSize,
          uom,
          modeOfTravel,
          tooltip,
          cssTooltip,
          type,
          types,
          layers,
          isDraggableData
        } = {}
      } = {}
    } = {}
  } = props

  const ready =
    !isEmpty(id) &&
    !isEmpty(level) &&
    !isEmpty(latitude) &&
    !isEmpty(longitude) &&
    !isEmpty(isCenter)

  return {
    id,
    level,
    geometry,
    latitude,
    longitude,
    isCenter,
    zoom,
    dataZoom,
    moveToUpdate,
    askUpdate,
    theme,
    iconColor,
    iconSize,
    uom,
    modeOfTravel,
    tooltip,
    cssTooltip,
    type,
    types,
    layers,
    ready,
    isHover,
    isSync,
    isFastIcon,
    controlSettings,
    isDraggableData
  }
}

function rgbToHex(r, g, b) {
  const toHex = (c) => {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${toHex(r) + toHex(g) + toHex(b)}`
}

function generateColorTones(cl, count) {
  const colorTones = []
  for (let i = 0; i < count; i += 1) {
    const scale = scaleLinear()
      .domain([0, count - 1])
      .range([0, 1])

    const t = scale(i)
    const rgb = color(interpolateRgb(cl, presetColors[i])(t))
    const hex = rgbToHex(rgb.r, rgb.g, rgb.b)
    colorTones.push(hex)
  }
  return colorTones
}

function randomColors(count = 10) {
  const customColorSeries = []
  const tonesPerColor = count / presetColors.length

  for (let i = 0; i < presetColors.length; i += 1) {
    const colorTones = generateColorTones(presetColors[i], tonesPerColor)
    customColorSeries.push(...colorTones)
  }

  return customColorSeries
}

export { accessToken, presetColors, defaultColors, averageGeolocation, getConfig, randomColors }
