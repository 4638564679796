import { AG_GRID_ENUMS } from '../enums'
import { DirtyCell } from './edit'
import { Option as LookupFieldOption } from '../components/CellEditors/LookupFieldCellEditor/LookupFieldCellEditor.types'

const {
  ROW_ID,
  CUSTOM_ROW_INFO,
  DIRTY_CELLS,
  ADDED_ROWS,
  DELETE_COLUMN,
  LOOK_UP_FIELD_DATA,
  CHECKBOX_SELECTION_COLUMN
} = AG_GRID_ENUMS

export interface ICustomRowInfo {
  [ROW_ID]: string
  [ADDED_ROWS]: string[]
  [CHECKBOX_SELECTION_COLUMN]: boolean
  [DELETE_COLUMN]: boolean
  [DIRTY_CELLS]: DirtyCell[]
  [LOOK_UP_FIELD_DATA]: boolean
}

export type TrowDataValue =
  | string
  | number
  | number[]
  | boolean
  | null
  | Date
  | ICustomRowInfo
  | LookupFieldOption[]

export type IRowData = Record<string | number, TrowDataValue> & {
  [CUSTOM_ROW_INFO]: ICustomRowInfo
}

export type IRowDataGrid = Omit<IRowData, AG_GRID_ENUMS.CUSTOM_ROW_INFO>

export type IRowDataGridOrNull = IRowDataGrid | null
