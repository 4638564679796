import { useRef, useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from './store'
import ApiClient from '../crudoptV3/libs/apiClient'
import { INotification } from '@/components/NavigationBar/NotificationBox/NotificationBox.types'
import signalr from '../helpers/SignalrClient'
import { markAsRead, storeNotifications } from '@/store/slices/notifications'
import { isCustomEvent } from '@/ts'

const client: any = new ApiClient()

interface IUseNotificationsProps {
  autoFetchStartTimeout?: number
  count?: number
  skip?: number
  timeoutToResetNewNotificationState?: number
}

type TStatus = 'idle' | 'pending' | 'success' | 'error'

function useNotifications(props?: IUseNotificationsProps) {
  const autoFetchStartTimeout = props?.autoFetchStartTimeout ?? 1000
  const count = props?.count ?? 10
  const skip = props?.skip ?? 0
  const timeoutToResetNewNotificationState = props?.timeoutToResetNewNotificationState ?? 1000

  const dispatch = useAppDispatch()
  const { notifications } = useAppSelector((state) => state.notifications)

  const [isThereAnyNewNotification, setIsThereAnyNewNotification] = useState(false)
  const [triggerId, setTriggerId] = useState('')
  const [status, setStatus] = useState<TStatus>('idle')

  const connection = useRef<any>(signalr.get())
  const fallbackCallTimer = useRef<any>(null)
  const isInitialCall = useRef(true)
  const newNotificationTimer = useRef<any>(null)

  useEffect(() => {
    function messagingReceiveMessage(event: Event) {
      if (!isCustomEvent(event)) {
        throw new Error('This is not a custom event!')
      }

      setTriggerId(uuidv4())
    }

    window.addEventListener('MessagingReceiveMessage', messagingReceiveMessage)
    return () => {
      window.removeEventListener('MessagingReceiveMessage', messagingReceiveMessage)
    }
  }, [])

  const resetIsThereAnyNewNotification = useCallback(() => {
    setIsThereAnyNewNotification(false)
  }, [])

  useEffect(() => {
    let isCancelled = false

    if (!triggerId) {
      return cleanUp
    }

    function clearNotifications() {
      dispatch(
        storeNotifications({
          notifications: []
        })
      )
    }

    function cleanUp() {
      isCancelled = true
      clearTimeout(newNotificationTimer.current)
      // TODO: refactor
      clearNotifications()
    }

    setStatus('pending')

    client
      .get(`notification?count=${count}&skip=${skip}`)
      .then((result: INotification[]) => {
        if (!isCancelled) {
          setStatus('success')
          const newResult = !_.isEmpty(result) ? result : []

          dispatch(
            storeNotifications({
              notifications: newResult
            })
          )

          // if unread size is bigger than the displayed notification count then do not shake the bell
          // user cannot see new notifications because of the limit (count) so it would be annoying to shake the bell
          const lastPartNotifications = newResult.slice(0, count)
          const unreadNotificationsSize = lastPartNotifications.filter(
            ({ read }: INotification) => !read
          ).length

          if (!isInitialCall.current && unreadNotificationsSize) {
            setIsThereAnyNewNotification(true)
            newNotificationTimer.current = setTimeout(() => {
              resetIsThereAnyNewNotification()
            }, timeoutToResetNewNotificationState)
          }

          isInitialCall.current = false
        }
      })
      .catch(() => {
        if (!isCancelled) {
          setStatus('error')
        }
      })

    return cleanUp
  }, [
    count,
    skip,
    dispatch,
    resetIsThereAnyNewNotification,
    timeoutToResetNewNotificationState,
    triggerId
  ])

  useEffect(() => {
    fallbackCallTimer.current = setTimeout(() => {
      if (!triggerId) {
        setTriggerId(uuidv4())
      }
    }, autoFetchStartTimeout)
    return () => {
      clearTimeout(fallbackCallTimer.current)
    }
  }, [autoFetchStartTimeout, triggerId])

  function handleReadNotifications(id: INotification['id']) {
    connection.current.connection.invoke('AcknowledgeMessage', id).then(() => {
      dispatch(markAsRead({ id }))
    })
  }

  return {
    handleReadNotifications,
    isThereAnyNewNotification,
    notifications,
    status
  }
}

export default useNotifications
