Ext.define('tuiv2.model.TaskModel', {
  extend: 'Ext.data.TreeModel',
  fields: [
    { name: 'Index', type: 'int' },
    { name: 'Name', type: 'string' },
    { name: 'Description', type: 'string' },
    { name: 'UserName', type: 'string' },
    { name: 'StartTime', type: 'date' },
    { name: 'EndTime', type: 'date' },
    { name: 'CurrentIteration', type: 'int' },
    { name: 'MaxIteration', type: 'int' },
    { name: 'InProgress', type: 'int' },
    {
      name: 'Progress',
      calculate: function (data) {
        var value = data.CurrentIteration / data.MaxIteration
        return value
      },
      depends: ['CurrentIteration', 'MaxIteration']
    }
  ]
})
