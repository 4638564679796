import React, { Component } from 'react'
import _ from 'lodash'
import { Row, Col } from 'react-bootstrap'
import request from 'superagent'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import Board from './Board/board'
import TaskAdd from './Add/index'
import TaskShow from './Edit/index'
import { SlvySelect, slvyToast } from '@/components'
import { filterOptionData, sortOptionData } from './constants'
import { API_URL } from '@/constants'

class TaskTracking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddTaskModal: false,
      taskDetailShow: false,
      value: '',
      show: false,
      priorities: [],
      categories: [],
      subStatuses: [],
      users: [],
      taskCards: [],
      taskList: [],
      BoardData: {},
      incomingTask: {},
      taskComments: [],
      taskActivities: [],
      taskAvailableNavigations: [],
      taskWatchers: [],
      tasksAvailableNavigations: [],
      taskAttachments: [],
      swimlaneUsers: [],
      selectedTasksActivities: [],
      reasons: [],
      transportationPopupUsers: [],
      predefinedFiterValue: 'Get specific tasks',
      sortFieldValue: 'Sort tasks By',
      sortType: 'asc',
      uniqueUserName: '',
      progressPercent: 0,
      commentsSuccess: false,
      oneTaskActivitiesSuccess: false,
      watchersSuccess: false,
      attachmentsSuccess: false,
      swimlaneUsersSuccess: false,
      categoriesSuccess: false,
      subStatusesSuccess: false,
      usersSuccess: false,
      statusesSuccess: false,
      navigationsSuccess: false,
      currentUserSuccess: false,
      localizationSuccess: false,
      tasksSuccess: false, // normal or filtered
      boardDataSuccess: false,
      activitiesSuccess: false,
      filters: {
        parentID: 0,
        categoryIds: [],
        priorityIds: [],
        statusIds: [],
        reporterNames: [],
        assigneeNames: [],
        searchText: '',
        dueDateFrom: '',
        dueDateTo: '',
        createdDateFrom: '',
        createdDateTo: '',
        isArchived: ''
      },
      activityShow: false,
      localizationLanguage: [],
      subjectPrefix: '',
      popupReasonsSuccess: false,
      popupUsersSuccess: false
    }
    this.showNewTaskCard = this.showNewTaskCard.bind(this)
    this.getTasks = this.getTasks.bind(this)
    this.getPriorities = this.getPriorities.bind(this)
    this.getSubStatuses = this.getSubStatuses.bind(this)
    this.makeBoardData = this.makeBoardData.bind(this)
    this.addNewTask = this.addNewTask.bind(this)
    this.mappingFromCrudList = this.mappingFromCrudList.bind(this)
    this.closeTaskDetail = this.closeTaskDetail.bind(this)
    this.showTaskDetail = this.showTaskDetail.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.insertComment = this.insertComment.bind(this)
    this.readComments = this.readComments.bind(this)
    this.updateTask = this.updateTask.bind(this)
    this.getTaskComments = this.getTaskComments.bind(this)
    this.getTaskActivities = this.getTaskActivities.bind(this)
    this.getWatchers = this.getWatchers.bind(this)
    this.getTaskAttachments = this.getTaskAttachments.bind(this)
    this.insertAttachment = this.insertAttachment.bind(this)
    this.updateTaskWatchers = this.updateTaskWatchers.bind(this)
    this.sortTasks = this.sortTasks.bind(this)
    this.tasksPredefinedFilter = this.tasksPredefinedFilter.bind(this)
    this.getTasksByMultipleValue = this.getTasksByMultipleValue.bind(this)
    this.handleSortTypeChange = this.handleSortTypeChange.bind(this)
    this.getCurrentUser = this.getCurrentUser.bind(this)
    this.getTasksAvailableNavigations = this.getTasksAvailableNavigations.bind(this)
    this.getTaskAvailableNavigations = this.getTaskAvailableNavigations.bind(this)
    this.updateTaskStatus = this.updateTaskStatus.bind(this)
    this.getFilteredTasksActivities = this.getFilteredTasksActivities.bind(this)
    this.deleteTaskAttachment = this.deleteTaskAttachment.bind(this)
    this.initializePlugin = this.initializePlugin.bind(this)
    this.checkFiltersIfEmpty = this.checkFiltersIfEmpty.bind(this)
    this.handleActivityShow = this.handleActivityShow.bind(this)
    this.getLocalizationLanguage = this.getLocalizationLanguage.bind(this)
    this.archiveTask = this.archiveTask.bind(this)
    this.restoreTask = this.restoreTask.bind(this)
    this.openContentById = this.openContentById.bind(this)
    this.afterTaskCreate = this.afterTaskCreate.bind(this)
    this.getPopupData = this.getPopupData.bind(this)
    this.localUpdateTaskStatus = this.localUpdateTaskStatus.bind(this)
    this.emptyPopupData = this.emptyPopupData.bind(this)
    this.resetUserLists = this.resetUserLists.bind(this)
    this.getSwimlaneCategoryUsers = this.getSwimlaneCategoryUsers.bind(this)
    this.resetTasks = this.resetTasks.bind(this)
    this.getMappedOptions = this.getMappedOptions.bind(this)
  }

  archiveTask(data) {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .post(`/data/${id}/invoke/ArchiveTask`, { data })
      .then(
        function (res) {
          this.resetTasks()
          slvyToast.success({
            message: localizationLanguage.TaskArchivedSucc,
            title: localizationLanguage.Successful
          })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.TaskArchivedUnsucc,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  restoreTask(data) {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .post(`/data/${id}/invoke/RestoreTask`, { data })
      .then(
        function (res) {
          this.resetTasks()
          slvyToast.success({
            message: localizationLanguage.TaskRestoredSucc,
            title: localizationLanguage.Successful
          })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.TaskRestoredUnsucc,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getLocalizationLanguage() {
    const { id, client } = this.props
    client
      .get(`/data/${id}/invoke/i18n_read`)
      .then(
        function (res) {
          const localizationLanguage = _.transform(
            res,
            (result, key) => {
              result[key.TextKey] = key.Text
            },
            {}
          )
          this.setState({
            localizationLanguage,
            localizationSuccess: true
          })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  handleActivityShow() {
    const { activityShow } = this.state
    if (activityShow) this.setState({ activityShow: false })
    else this.setState({ activityShow: true })
  }

  getTaskAvailableNavigations(taskTypeId) {
    const taskNavigations = _.reduce(
      this.state.tasksAvailableNavigations,
      (result, item) => {
        if (item.TypeId === taskTypeId) result.push(item)
        return result
      },
      []
    )
    this.setState({
      taskAvailableNavigations: taskNavigations
    })
  }

  handleSortTypeChange() {
    if (this.state.sortFieldValue !== 'Sort tasks By') {
      if (this.state.sortType === 'asc') {
        this.setState({ sortType: 'desc' }, () => {
          this.sortTasks({ value: this.state.sortFieldValue })
        })
      } else {
        this.setState({ sortType: 'asc' }, () => {
          this.sortTasks({ value: this.state.sortFieldValue })
        })
      }
    }
  }

  sortTasks(event) {
    let sortedTaskCards
    if (event.value) {
      switch (event.value) {
        case 'Category': {
          sortedTaskCards = _.orderBy(this.state.taskCards, ['CategoryName'], [this.state.sortType])
          break
        }
        case 'Priority': {
          sortedTaskCards = _.orderBy(
            this.state.taskCards,
            ['PriorityOrderNumber'],
            [this.state.sortType]
          )
          break
        }
        case 'Due Date': {
          sortedTaskCards = _.orderBy(this.state.taskCards, ['DueDate'], [this.state.sortType])
          break
        }
        case 'Created Date': {
          sortedTaskCards = _.orderBy(this.state.taskCards, ['CreatedDate'], [this.state.sortType])
          break
        }
        default: {
        }
      }
      this.setState(
        {
          taskCards: sortedTaskCards,
          sortFieldValue: event.value
        },
        () => this.makeBoardData()
      )
    }
  }

  tasksPredefinedFilter(event) {
    let fiteredTaskCards
    const { uniqueUserName } = this.state
    if (event.value) {
      switch (event.value) {
        case 'My tasks': {
          fiteredTaskCards = _.map(this.state.taskCards, function (item) {
            if (uniqueUserName !== item.AssigneeUniqueName) {
              item.IsShowed = false
            } else item.IsShowed = true
            return item
          })
          break
        }
        case 'Reported by me': {
          fiteredTaskCards = _.map(this.state.taskCards, function (item) {
            if (uniqueUserName !== item.ReporterUniqueName) {
              item.IsShowed = false
            } else item.IsShowed = true
            return item
          })
          break
        }
        default: {
          fiteredTaskCards = _.map(this.state.taskCards, function (item) {
            item.IsShowed = true
            return item
          })
        }
      }
      this.setState(
        {
          taskCards: fiteredTaskCards,
          predefinedFiterValue: event.value
        },
        () => this.makeBoardData()
      )
    }
  }

  mappingFromCrudList = (list, key, value) => {
    return _.map(list, function (item) {
      return { text: item[value], id: item[key] }
    })
  }

  showNewTaskCard() {
    /* const {
      settings: {
        config: { settings: { swimlaneBasedUsersEnabled = false } = {} } = {}
      } = {}
    } = this.props */
    this.setState({
      showAddTaskModal: true
    })
    /* const status = _.minBy(this.state.taskList, function (o) {
      return o.OrderNumber
    })
     if (status && swimlaneBasedUsersEnabled) {
      this.getSwimlaneUsers({ authorizationKey: status.Name }, 'NewCard')
    } */
  }

  showTaskDetail(taskCard) {
    this.setState({
      incomingTask: taskCard,
      taskDetailShow: true
    })
    const {
      settings: { config: { settings: { swimlaneBasedUsersEnabled = false } = {} } = {} } = {}
    } = this.props
    this.getTaskComments({ taskID: taskCard.Id })
    this.getTaskActivities({ taskID: taskCard.Id })
    this.getWatchers({ taskID: taskCard.Id })
    this.getTaskAttachments({ taskID: taskCard.Id })
    this.getTaskAvailableNavigations(taskCard.TypeId)
    this.getSubStatuses({ statusId: taskCard.StatusID })
    const statusName = _.find(this.state.taskList, {
      Id: taskCard.StatusID
    }).Name
    if (swimlaneBasedUsersEnabled) {
      this.getSwimlaneCategoryUsers(
        { authorizationKey: statusName, categoryName: taskCard.CategoryName },
        'CardDetails'
      )
    }
  }

  closeTaskDetail() {
    this.setState({
      taskDetailShow: false,
      taskComments: [],
      taskActivities: [],
      taskWatchers: [],
      taskAvailableNavigations: [],
      taskAttachments: [],
      commentsSuccess: false,
      oneTaskActivitiesSuccess: false,
      watchersSuccess: false,
      attachmentsSuccess: false,
      swimlaneUsersSuccess: false
    })
  }

  initializePlugin() {
    const { filters } = this.state

    this.getPriorities()
    this.getUsers()
    this.getTasksAvailableNavigations()
    this.getCurrentUser()
    this.getLocalizationLanguage()
    this.getStatusesAndCategories()
      .then(
        function () {
          if (filters && !this.checkFiltersIfEmpty(filters)) {
            this.getTasksByMultipleValue(filters)
          } else {
            this.getTasks()
          }
        }.bind(this)
      )
      .then(() => {
        // Create user lists authorizations config
        const authArray = []
        _.forEach(this.state.taskList, function (item) {
          authArray.push(item.Name)
        })
        _.forEach(this.state.categories, (item) => {
          authArray.push(item.Name)
        })
        authArray.unshift('UserListAuthorization')
        this.props.registerAuthorizations(authArray)
        this.setState({ progressPercent: 0 })
      })
  }

  getStatusesAndCategories() {
    const { id, client } = this.props
    return client
      .get(`/data/${id}/invoke/GetStatusesAndCategories`)
      .then(
        function (res) {
          this.setState({
            categories: res.Categories,
            taskList: _.sortBy(res.Statuses, [
              function (o) {
                return o.OrderNumber
              }
            ]),
            statusesSuccess: true,
            categoriesSuccess: true
          })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  resetTasks() {
    const { filters } = this.state
    if (filters && !this.checkFiltersIfEmpty(filters)) {
      this.getTasksByMultipleValue(filters)
    } else {
      this.getTasks()
    }
  }

  checkFiltersIfEmpty(filters) {
    const {
      parentID,
      categoryIds,
      priorityIds,
      statusIds,
      reporterNames,
      assigneeNames,
      searchText,
      dueDateFrom,
      dueDateTo,
      createdDateFrom,
      createdDateTo,
      isArchived
    } = filters
    if (
      parentID === 0 &&
      categoryIds.length === 0 &&
      priorityIds.length === 0 &&
      statusIds.length === 0 &&
      reporterNames.length === 0 &&
      assigneeNames.length === 0 &&
      searchText === '' &&
      dueDateFrom === '' &&
      dueDateTo === '' &&
      createdDateFrom === '' &&
      createdDateTo === '' &&
      _.lowerCase(isArchived) !== 'true'
    ) {
      return true
    }
    return false
  }

  componentWillMount() {
    this.setState({ progressPercent: 75 })
  }

  componentDidMount() {
    this.initializePlugin()
    this.props.registerMethod({
      key: 'showNewTaskCard',
      fn: this.showNewTaskCard.bind(this),
      args: []
    })
    this.props.registerMethod({
      key: 'getTasksByMultipleValue',
      fn: this.getTasksByMultipleValue.bind(this),
      args: [
        {
          name: 'parentID',
          type: PluginTypes.int
        },
        {
          name: 'categoryIds',
          type: PluginTypes.arrayOf(PluginTypes.int)
        },
        {
          name: 'priorityIds',
          type: PluginTypes.arrayOf(PluginTypes.int)
        },
        {
          name: 'statusIds',
          type: PluginTypes.arrayOf(PluginTypes.int)
        },
        {
          name: 'reporterNames',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'assigneeNames',
          type: PluginTypes.arrayOf(PluginTypes.string)
        },
        {
          name: 'searchText',
          type: PluginTypes.string
        },
        {
          name: 'dueDateFrom',
          type: PluginTypes.string
        },
        {
          name: 'dueDateTo',
          type: PluginTypes.string
        },
        {
          name: 'createdDateFrom',
          type: PluginTypes.string
        },
        {
          name: 'createdDateTo',
          type: PluginTypes.string
        },
        {
          name: 'isArchived',
          type: PluginTypes.string
        }
      ]
    })
    this.openContentById = this.props.registerEvent({
      key: 'handleTaskId',
      fn: this.openContentById.bind(this),
      returnTypes: {
        taskKey: PluginTypes.int,
        taskId: PluginTypes.int,
        maskedTaskId: PluginTypes.string
      }
    })
    this.afterTaskCreate = this.props.registerEvent({
      key: 'afterTaskCreate',
      fn: this.afterTaskCreate.bind(this),
      returnTypes: {
        taskKey: PluginTypes.int,
        taskId: PluginTypes.int,
        maskedTaskId: PluginTypes.string
      }
    })
    this.afterTaskStatusUpdate = this.props.registerEvent({
      key: 'afterTaskStatusUpdate',
      fn: this.afterTaskStatusUpdate.bind(this),
      returnTypes: {
        taskKey: PluginTypes.int,
        taskId: PluginTypes.int,
        maskedTaskId: PluginTypes.string,
        newStatusId: PluginTypes.int,
        previousStatusId: PluginTypes.int
      }
    })
  }

  openContentById(TaskId, MaskedTaskId) {
    return { taskKey: uuidv4(), taskId: TaskId, maskedTaskId: MaskedTaskId }
  }

  afterTaskCreate(TaskId, MaskedTaskId) {
    return { taskKey: uuidv4(), taskId: TaskId, maskedTaskId: MaskedTaskId }
  }

  afterTaskStatusUpdate(TaskId, MaskedTaskId, PreviousStatusId, NewStatusId) {
    return {
      taskKey: uuidv4(),
      taskId: TaskId,
      maskedTaskId: MaskedTaskId,
      newStatusId: NewStatusId,
      previousStatusId: PreviousStatusId
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.taskCards !== this.props.taskCards) {
      this.setState({
        taskCards: nextProps.taskCards
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      commentsSuccess,
      oneTaskActivitiesSuccess,
      watchersSuccess,
      attachmentsSuccess,
      swimlaneUsersSuccess,
      categoriesSuccess,
      subStatusesSuccess,
      usersSuccess,
      statusesSuccess,
      navigationsSuccess,
      currentUserSuccess,
      localizationSuccess,
      tasksSuccess,
      activitiesSuccess,
      boardDataSuccess,
      showAddTaskModal
    } = nextState
    const {
      settings: { config: { settings: { swimlaneBasedUsersEnabled = false } = {} } = {} }
    } = nextProps
    if (nextState.taskDetailShow) {
      /*  console.log(
        'should',
        commentsSuccess,
        subStatusesSuccess,
        oneTaskActivitiesSuccess,
        watchersSuccess,
        attachmentsSuccess,
        swimlaneUsersSuccess
      ) */
      if (
        commentsSuccess &&
        subStatusesSuccess &&
        oneTaskActivitiesSuccess &&
        watchersSuccess &&
        attachmentsSuccess &&
        (!swimlaneBasedUsersEnabled || swimlaneUsersSuccess)
      ) {
        return true
      }
      return false
    }
    if (showAddTaskModal) {
      /* console.log('should', showAddTaskModal) */
      return true
    }
    /* console.log(
        'should',
        categoriesSuccess,
        usersSuccess,
        statusesSuccess,
        navigationsSuccess,
        currentUserSuccess,
        localizationSuccess,
        tasksSuccess,
        activitiesSuccess,
        boardDataSuccess
      ) */

    if (
      categoriesSuccess &&
      usersSuccess &&
      statusesSuccess &&
      navigationsSuccess &&
      currentUserSuccess &&
      localizationSuccess &&
      tasksSuccess &&
      activitiesSuccess &&
      boardDataSuccess
    ) {
      return true
    }
    return false
  }

  digitNumber(number, size) {
    while (number.length < (size || 2)) {
      number = `0${number}`
    }
    return number
  }

  addNewTask(task) {
    const {
      token,
      params: { environment },
      id,
      settings: {
        config: { format: { prefix = '', idDigitNumber = 0, postfix = '' } = {} } = {}
      } = {}
    } = this.props
    const { localizationLanguage } = this.state
    const data = new FormData()
    for (let i = 0; i < task.files.length; i++) {
      data.append(`file${i}`, task.files[i])
    }
    for (const key in task) {
      if (task.hasOwnProperty(key) && !_.includes(key, 'file') && !_.includes(key, 'assignee')) {
        data.append(key, task[key])
      }
      if (_.includes(key, 'assignee')) {
        data.append(key, task[key])
      }
    }
    let lasttime = 0
    request
      .post(`${API_URL}/data/${id}/invoke/multipart/InsertTask`)
      .on(
        'progress',
        function (e) {
          if (e.percent >= lasttime + 10 || e.percent === 100) {
            this.setState({ progressPercent: e.percent })
            lasttime = e.percent
          }
        }.bind(this)
      )
      .send(data)
      .set({
        environment,
        Accept: 'application/json',
        'Content-Type': null,
        Authorization: `Bearer ${token}`
      })
      .then(
        function (res) {
          this.setState({ progressPercent: 0 })
          const formatedId = idDigitNumber
            ? this.digitNumber(_.toString(res.body), idDigitNumber)
            : res.body
          const showedTaskId = prefix + formatedId + postfix
          this.afterTaskCreate(res.body, showedTaskId)
          slvyToast.success({
            message: localizationLanguage.TaskAddedSucc,
            title: localizationLanguage.Successful
          })
          this.resetTasks()
        }.bind(this)
      )
      .catch(
        function (err) {
          this.setState({ progressPercent: 0 })
          slvyToast.error({
            message: localizationLanguage.TaskAddedUnsucc,
            title: localizationLanguage.Unsuccessful
          })
        }.bind(this)
      )
  }

  insertComment(comment) {
    const { id, client } = this.props
    client
      .post(`/data/${id}/invoke/InsertComment`, { data: comment })
      .then(
        function (res) {
          this.getTaskActivities({ taskID: comment.taskID })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  insertAttachment(Attachment) {
    const { localizationLanguage } = this.state
    const data = new FormData()
    for (let i = 0; i < Attachment.files.length; i++) {
      data.append(`file${i}`, Attachment.files[i])
    }
    for (const key in Attachment) {
      if (Attachment.hasOwnProperty(key) && !_.includes(key, 'file')) {
        data.append(key, Attachment[key])
      }
    }
    const that = this
    const {
      token,
      params: { environment },
      id
    } = that.props
    let lasttime = 0
    request
      .post(`${API_URL}/data/${id}/invoke/multipart/InsertTaskAttachment`)
      .on(
        'progress',
        function (e) {
          if (e.percent >= lasttime + 10 || e.percent === 100) {
            this.setState({ progressPercent: e.percent })
            lasttime = e.percent
          }
        }.bind(this)
      )
      .send(data)
      .set({
        environment,
        Accept: 'application/json',
        'Content-Type': null,
        Authorization: `Bearer ${token}`
      })
      .then(
        function (res) {
          const newAttachments = _.map(res.body, (x) =>
            _.transform(x, function (result, val, key) {
              result[_.upperFirst(key)] = val
            })
          )
          this.setState({
            taskAttachments: [...this.state.taskAttachments, ...newAttachments],
            progressPercent: 0
          })
          this.getTaskActivities({ taskID: Attachment.taskID })
          slvyToast.success({
            message: localizationLanguage.FilesAttachedSucc,
            title: localizationLanguage.Successful
          })
        }.bind(this)
      )
      .catch(function (err) {
        this.setState({ progressPercent: 0 })
        slvyToast.error({
          message: localizationLanguage.FilesAttachedUnsucc,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getUsers() {
    const { id, client } = this.props
    client
      .get(`/data/${id}/invoke/GetCompanyUsers`)
      .then(
        function (res) {
          this.setState({ users: res, usersSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  deleteTaskAttachment(data) {
    const { id, client } = this.props
    const { localizationLanguage, taskAttachments } = this.state
    client
      .post(`/data/${id}/invoke/DeleteTaskAttachment`, { data })
      .then(
        function (res) {
          const attachments = _.map(taskAttachments, _.clone)
          _.remove(attachments, { AttachmentId: data.attachmentId })
          this.setState({
            taskAttachments: attachments
          })
          this.getTaskActivities({ taskID: data.taskID })
          slvyToast.success({
            message: localizationLanguage.FileDeletedSucc,
            title: localizationLanguage.Successful
          })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.FilesDeletedUnsucc,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getTasks() {
    const { id, client } = this.props
    client
      .get(`/data/${id}/invoke/GetNearTasks`)
      .then(
        function (res) {
          this.setState({ taskCards: res, tasksSuccess: true }, () => this.makeBoardData())
          const tasksIds = _.map(res, (item) => {
            return item.Id
          })
          this.getFilteredTasksActivities({ tasksIds })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  getTaskComments(task) {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .post(`/data/${id}/invoke/GetTaskComments`, { data: task })
      .then(
        function (res) {
          this.setState({ taskComments: res, commentsSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.Unsuccessful,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getSwimlaneCategoryUsers(authorizationKeys, callerPlace) {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .post(`/data/${id}/invoke/GetSwimlaneCategoryUsers`, {
        data: authorizationKeys
      })
      .then(
        function (res) {
          if (callerPlace === 'CardDetails') {
            this.setState({ swimlaneUsers: res, swimlaneUsersSuccess: true })
          } else if (callerPlace === 'PopupData') {
            this.setState({
              transportationPopupUsers: res,
              popupUsersSuccess: true
            })
          }
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.Unsuccessful,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getWatchers(task) {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .post(`/data/${id}/invoke/GetTaskWatchers`, { data: task })
      .then(
        function (res) {
          this.setState({ taskWatchers: res, watchersSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.Unsuccessful,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getTaskActivities(task) {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .post(`/data/${id}/invoke/GetTaskActivities`, { data: task })
      .then(
        function (res) {
          this.setState({ taskActivities: res, oneTaskActivitiesSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.Unsuccessful,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getFilteredTasksActivities(tasks) {
    const { id, client } = this.props
    client
      .post(`/data/${id}/invoke/GetFilteredTasksActivities`, { data: tasks })
      .then(
        function (res) {
          this.setState({
            selectedTasksActivities: res,
            activitiesSuccess: true
          })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  getTaskAttachments(task) {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .post(`/data/${id}/invoke/GetTaskAttachments`, { data: task })
      .then(
        function (res) {
          this.setState({ taskAttachments: res, attachmentsSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.Unsuccessful,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getTasksByMultipleValue(tasksFilter) {
    const filter = {
      parentID: tasksFilter.parentID ? tasksFilter.parentID : 0,
      categoryIds: tasksFilter.categoryIds ? tasksFilter.categoryIds : [],
      priorityIds: tasksFilter.priorityIds ? tasksFilter.priorityIds : [],
      statusIds: tasksFilter.statusIds ? tasksFilter.statusIds : [],
      reporterNames: tasksFilter.reporterNames ? tasksFilter.reporterNames : [],
      assigneeNames: tasksFilter.assigneeNames ? tasksFilter.assigneeNames : [],
      searchText: tasksFilter.searchText ? tasksFilter.searchText : '',
      dueDateFrom: tasksFilter.dueDateFrom ? tasksFilter.dueDateFrom : '',
      dueDateTo: tasksFilter.dueDateTo ? tasksFilter.dueDateTo : '',
      createdDateFrom: tasksFilter.createdDateFrom ? tasksFilter.createdDateFrom : '',
      createdDateTo: tasksFilter.createdDateTo ? tasksFilter.createdDateTo : '',
      isArchived: _.lowerCase(tasksFilter.isArchived) === 'true'
    }
    if (!this.checkFiltersIfEmpty(filter)) {
      this.setState({
        filters: filter
      })
      const { id, client } = this.props
      client
        .post(`/data/${id}/invoke/GetMultipleFilteredTasks`, { data: filter })
        .then(
          function (res) {
            this.setState(
              {
                taskCards: res,
                predefinedFiterValue: 'Get specific tasks',
                tasksSuccess: true
              },
              () => this.makeBoardData()
            )
            const tasksIds = _.map(res, (item) => {
              return item.Id
            })
            this.getFilteredTasksActivities({ tasksIds })
          }.bind(this)
        )
        .catch(function (err) {})
    }
  }

  getTasksAvailableNavigations() {
    const { id, client } = this.props
    const { localizationLanguage } = this.state
    client
      .get(`/data/${id}/invoke/GetTasksAvailableNavigations`)
      .then(
        function (res) {
          this.setState({
            tasksAvailableNavigations: res,
            navigationsSuccess: true
          })
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.Unsuccessful,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  getPriorities() {
    const { id, client } = this.props
    client
      .get(`/data/${id}/invoke/GetPriorities`)
      .then(
        function (res) {
          this.setState({ priorities: res, prioritiesSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  getSubStatuses(statusId) {
    const { id, client } = this.props
    client
      .post(`/data/${id}/invoke/GetSubStatuses`, { data: statusId })
      .then(
        function (res) {
          this.setState({ subStatuses: res, subStatusesSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  getReasons(navigation) {
    const { id, client } = this.props
    client
      .post(`/data/${id}/invoke/GetReasons`, { data: navigation })
      .then(
        function (res) {
          this.setState({ reasons: res, popupReasonsSuccess: true })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  readComments(task) {
    const { id, client } = this.props
    client
      .post(`/data/${id}/invoke/ReadComment`, { data: task })
      .then(function (res) {})
      .catch(function (err) {})
  }

  localUpdateTaskStatus(task) {
    const { taskCards, incomingTask } = this.state
    const newTaskCards = _.map(taskCards, _.clone)
    const newTask = { ...incomingTask }
    const index = _.findIndex(newTaskCards, { Id: task.taskID })
    newTaskCards[index].StatusID = task.statusID
    newTask.StatusID = task.statusID
    this.setState({ taskCards: newTaskCards, incomingTask: newTask }, () => this.makeBoardData())
  }

  emptyPopupData() {
    this.setState({
      reasons: [],
      transportationPopupUsers: [],
      popupReasonsSuccess: false,
      popupUsersSuccess: false
    })
  }

  updateTaskStatus(task) {
    this.setState({ tasksSuccess: false, activitiesSuccess: false })
    const {
      id,
      client,
      settings: {
        config: { format: { prefix = '', idDigitNumber = 0, postfix = '' } = {} } = {}
      } = {}
    } = this.props
    const { localizationLanguage, taskDetailShow } = this.state
    client
      .post(`/data/${id}/invoke/UpdateTaskStatus`, { data: task })
      .then(
        function (res) {
          this.resetTasks()
          if (taskDetailShow) {
            this.getTaskActivities({ taskID: task.taskID })
          }
          slvyToast.success({
            message: localizationLanguage.TaskStatusUpdatedSucc,
            title: localizationLanguage.Successful
          })
          const formatedId = idDigitNumber
            ? this.digitNumber(_.toString(task.taskID), idDigitNumber)
            : task.taskID
          const showedTaskId = prefix + formatedId + postfix
          this.afterTaskStatusUpdate(
            task.taskID,
            showedTaskId,
            task.previousStatusID,
            task.statusID
          )
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.TaskStatusUpdatedUnsucc,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  updateTask(task) {
    this.setState({ tasksSuccess: false, activitiesSuccess: false })
    const { id, client } = this.props
    const { priorities, categories, users, localizationLanguage, taskDetailShow, taskCards } =
      this.state
    client
      .post(`/data/${id}/invoke/UpdateTask`, { data: task })
      .then(
        function (res) {
          const newCards = _.map(taskCards, _.clone)
          const index = _.findIndex(newCards, { Id: task.taskID })
          if (newCards[index].AssigneeUniqueName !== task.assigneeName) {
            const userObj = _.find(users, { UserName: task.assigneeName })
            newCards[index].AssigneeName = userObj.Name
            newCards[index].AssigneeSurname = userObj.Surname
            newCards[index].AssigneeUniqueName = userObj.UserName
            newCards[index].AssigneeID = userObj.UserId
          }
          if (newCards[index].CategoryID !== task.categoryID) {
            newCards[index].CategoryID = task.categoryID
            const categoryName = _.find(categories, {
              CategoryID: _.toNumber(task.categoryID)
            }).Name
            newCards[index].CategoryName = categoryName
          }
          newCards[index].Description = task.description
          newCards[index].DueDate = task.dueDate
          newCards[index].Subject = task.subject
          newCards[index].SubStatusId = task.subStatusId

          if (newCards[index].PriorityID !== task.priorityID) {
            newCards[index].PriorityID = task.priorityID
            const PriorityName = _.find(priorities, {
              PriorityID: _.toNumber(task.priorityID)
            }).Name
            newCards[index].PriorityName = PriorityName
          }
          const newTask = { ...newCards[index] }
          this.setState({ taskCards: newCards, incomingTask: newTask }, () => this.makeBoardData())
          slvyToast.success({
            message: localizationLanguage.TaskUpdatedSucc,
            title: localizationLanguage.Successful
          })
          this.resetTasks()
          if (taskDetailShow) {
            this.getTaskActivities({ taskID: task.taskID })
          }
        }.bind(this)
      )
      .catch(function (err) {
        slvyToast.error({
          message: localizationLanguage.TaskUpdatedUnsucc,
          title: localizationLanguage.Unsuccessful
        })
      })
  }

  updateTaskWatchers(data) {
    const { id, client } = this.props
    client
      .post(`/data/${id}/invoke/UpdateTaskWatchers`, { data })
      .then(function (res) {})
      .catch(function (err) {})
  }

  getCurrentUser() {
    const { id, client } = this.props
    client
      .get(`/data/${id}/invoke/GetCurrentUser`)
      .then(
        function (res) {
          this.setState({
            uniqueUserName: res,
            currentUserSuccess: true
          })
        }.bind(this)
      )
      .catch(function (err) {})
  }

  getPopupData(source, destination, areThereUsers, areThereReasons, categoryName) {
    const { taskList } = this.state
    if (areThereUsers) {
      const status = _.find(taskList, { Id: destination })
      if (status) {
        this.getSwimlaneCategoryUsers({ authorizationKey: status.Name, categoryName }, 'PopupData')
      }
    }
    if (areThereReasons) {
      this.getReasons({
        sourceStatusId: source,
        destinationStatusId: destination
      })
    }
  }

  resetUserLists() {
    const { id, client } = this.props
    client
      .get(`/data/${id}/invoke/clearCache`)
      .then()
      .catch(function (err) {})
  }

  makeBoardData() {
    const { taskList = [], taskCards = [] } = this.state
    if (taskList.length > 0 && taskCards.length > 0) {
      const boardData = _.transform(
        taskList,
        (result, key) => {
          result[key.Name] = []
        },
        {}
      )
      _.forEach(taskCards, (key) => {
        if (key.IsShowed) {
          if (taskCards) {
            boardData[_.find(taskList, { Id: key.StatusID }).Name].push(key)
          }
        }
      })
      this.setState({ BoardData: boardData, boardDataSuccess: true })
    } else {
      this.setState({ BoardData: { 'No thing to show': [] } })
    }
  }

  getMappedOptions(data) {
    const { localizationLanguage } = this.state

    return data.map(({ value, key }) => ({
      value,
      label: localizationLanguage[key]
    }))
  }

  render() {
    const {
      selectedTasksActivities,
      activityShow,
      localizationLanguage,
      showAddTaskModal,
      reasons,
      transportationPopupUsers,
      users,
      popupReasonsSuccess = false,
      popupUsersSuccess = false,
      sortFieldValue,
      predefinedFiterValue
    } = this.state
    const that = this
    const {
      settings: {
        config: {
          componentsAppearance: {
            card: cardProperties = {},
            adding: addingProperties = {},
            editing: editingProperties = {}
          } = {},
          format = {},
          settings: {
            assigneeChangeabilityDuringCardTransportation = false,
            swimlaneBasedUsersEnabled = false,
            makeAssigneeSameReporterInCreating = false
          } = {}
        } = {}
      },
      isPreviewMode,
      size: { height }
    } = that.props

    const sortOptions = this.getMappedOptions(sortOptionData)
    const selectedSort = sortOptions.find((option) => option.value === sortFieldValue)

    const filterOptions = this.getMappedOptions(filterOptionData)
    const selectedFilter = filterOptions.find((option) => option.value === predefinedFiterValue)

    return (
      <div className="task-tracking" style={{ minHeight: height }}>
        {showAddTaskModal && (
          <TaskAdd
            addNewTask={this.addNewTask}
            addingProperties={addingProperties}
            categories={this.state.categories}
            localizationLanguage={this.state.localizationLanguage}
            makeAssigneeSameReporterInCreating={makeAssigneeSameReporterInCreating}
            priorities={this.state.priorities}
            show={showAddTaskModal}
            swimlaneBasedUsersEnabled={swimlaneBasedUsersEnabled}
            uniqueUserName={this.state.uniqueUserName}
            users={users}
            onClose={() =>
              this.setState({
                showAddTaskModal: false
              })
            }
          />
        )}
        <div className={`right-side-row ${activityShow ? 'col-md-9' : 'col-md-12'}`}>
          <div className="tasks-department">
            <div className="task-predefined-filter-sort">
              <Row>
                <Col md={3} sm={3}>
                  <SlvySelect
                    options={filterOptions}
                    placeholder={localizationLanguage.GetSpecificTasks}
                    value={selectedFilter}
                    onChange={this.tasksPredefinedFilter}
                  />
                </Col>
                <Col md={3} sm={3}>
                  <SlvySelect
                    options={sortOptions}
                    placeholder={localizationLanguage.SortTasksBy}
                    value={selectedSort}
                    onChange={this.sortTasks}
                  />
                </Col>
                <Col md={3} sm={3}>
                  <div className="btn-group">
                    <button
                      className={`btn ${
                        this.state.sortType === 'asc' ? 'btn-success' : 'btn-white'
                      } `}
                      disabled={this.state.sortFieldValue === 'Sort tasks By' ? 'disabled' : false}
                      type="button"
                      onClick={this.handleSortTypeChange}
                    >
                      {localizationLanguage.Asc}
                    </button>
                    <button
                      className={`btn ${
                        this.state.sortType === 'desc' ? 'btn-success' : 'btn-white'
                      } `}
                      disabled={this.state.sortFieldValue === 'Sort tasks By' ? 'disabled' : false}
                      type="button"
                      onClick={this.handleSortTypeChange}
                    >
                      {localizationLanguage.Desc}
                    </button>
                  </div>
                </Col>

                <Col md={3} sm={3}>
                  {isPreviewMode && (
                    <button className="btn btn-success" onClick={this.resetUserLists}>
                      Refresh cashe memory
                    </button>
                  )}

                  <button
                    className={`btn ms-auto d-flex ${activityShow ? 'opened' : 'closed'}`}
                    type="button"
                    onClick={this.handleActivityShow}
                  />
                </Col>
              </Row>
            </div>
            {!this.state.taskDetailShow && (
              <div className="task-board">
                <Board
                  assigneeChangeabilityDuringCardTransportation={
                    assigneeChangeabilityDuringCardTransportation
                  }
                  containerHeight="100%"
                  emptyPopupData={this.emptyPopupData}
                  format={format}
                  getPopupData={this.getPopupData}
                  initial={this.state.BoardData}
                  localUpdateTaskStatus={this.localUpdateTaskStatus}
                  localizationLanguage={this.state.localizationLanguage}
                  openContent={this.openContentById}
                  popupReasonsSuccess={popupReasonsSuccess}
                  popupUsersSuccess={popupUsersSuccess}
                  reasons={reasons}
                  statuses={this.state.taskList}
                  taskAppearanceProperties={cardProperties}
                  taskCards={this.state.taskCards}
                  taskOnClick={this.showTaskDetail}
                  tasksAvailableNavigations={this.state.tasksAvailableNavigations}
                  uniqueUserName={this.state.uniqueUserName}
                  updateStatus={this.updateTaskStatus}
                  updateTask={this.updateTask}
                  users={transportationPopupUsers}
                />
              </div>
            )}
          </div>
        </div>
        {activityShow && (
          <div className="right-side-row col-md-3">
            <h1>{localizationLanguage.Activity}</h1>
            <div className="inspinia-timeline">
              {_.map(selectedTasksActivities, (item, index) => {
                return (
                  <div key={index} className="timeline-item">
                    <div className="row">
                      <div className="col-sm-4">
                        <i className="fa fa-bolt fa-fw" />
                        {index > 0
                          ? moment(item.ActionDate).format('DD-MM-YYYY') ===
                            moment(selectedTasksActivities[index - 1].ActionDate).format(
                              'DD-MM-YYYY'
                            )
                            ? ''
                            : moment(item.ActionDate).format('DD-MM-YYYY')
                          : moment(item.ActionDate).format('DD-MM-YYYY')}
                        <br />
                        <small className="text-navy">
                          {moment(item.ActionDate).format('HH.mm')}
                        </small>
                      </div>
                      <div className="col-sm-8 content no-top-border">
                        <p className="m-b-xs">
                          <strong className="text-ellipsis">{item.Subject}</strong>
                        </p>

                        <p>
                          {item.PerformedBy} {item.Activity}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {this.state.taskDetailShow && (
          <TaskShow
            archiveTask={this.archiveTask}
            assigneeChangeabilityDuringCardTransportation={
              assigneeChangeabilityDuringCardTransportation
            }
            categories={this.state.categories}
            deleteAttachment={this.deleteTaskAttachment}
            emptyPopupData={this.emptyPopupData}
            getPopupData={this.getPopupData}
            insertAttachment={this.insertAttachment}
            insertComment={this.insertComment}
            localUpdateTaskStatus={this.localUpdateTaskStatus}
            localizationLanguage={this.state.localizationLanguage}
            popupReasonsSuccess={popupReasonsSuccess}
            popupUsersSuccess={popupUsersSuccess}
            priorities={this.state.priorities}
            readComments={this.readComments}
            reasons={reasons}
            restoreTask={this.restoreTask}
            show={this.state.taskDetailShow}
            statuses={this.state.taskList}
            subStatuses={this.state.subStatuses}
            swimlaneBasedUsersEnabled={swimlaneBasedUsersEnabled}
            swimlaneUsers={this.state.swimlaneUsers}
            taskActivities={this.state.taskActivities}
            taskAppearanceProperties={editingProperties}
            taskAttachments={this.state.taskAttachments}
            taskAvailableNavigations={this.state.taskAvailableNavigations}
            taskCard={this.state.incomingTask}
            taskComments={this.state.taskComments}
            taskWatchers={this.state.taskWatchers}
            transportationPopupUsers={transportationPopupUsers}
            uniqueUserName={this.state.uniqueUserName}
            updateStatus={this.updateTaskStatus}
            updateTask={this.updateTask}
            updateTaskWatchers={this.updateTaskWatchers}
            username={this.props.userName}
            users={users}
            onChange={(value) => this.setState({ taskDetailShow: value })}
            onClose={this.closeTaskDetail}
          />
        )}
        {this.state.progressPercent !== 0 && (
          <div className={`task-tracking-loading `}>
            <i className="fa fa-circle-o-notch" />
            Loading
          </div>
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  registerAuthorizations: props.registerAuthorizations,
  settings: props.settings,
  id: props.id,
  client: props.client,
  token: props.token,
  params: props.params,
  isPreviewMode: props.isPreviewMode,
  size: props.size,
  userName: props.userName
})

export default createPlugin(TaskTracking, selectConnectorProps)
