import { useMemo } from 'react'
import { GridOptions } from 'ag-grid-community'
import { getGroupRowRendererParams } from '../../helpers'
import { EmptyObject, IRowData } from '../../types'
import { GroupRowRendererParamsProps } from './useGroupRowRendererParams.types'

function useGroupRowRendererParams(props: GroupRowRendererParamsProps) {
  const {
    groupDisplayType,
    grouping,
    suppressCount,
    cellDisplayRules,
    configColumns,
    fieldConfigs,
    handleActionBtnClick,
    editableFields
  } = props

  const groupRowRendererParams = useMemo(():
    | EmptyObject
    | {
        groupRowRendererParams: GridOptions<IRowData>['groupRowRendererParams']
      } => {
    return getGroupRowRendererParams({
      groupDisplayType,
      grouping,
      suppressCount,
      cellDisplayRules,
      configColumns,
      fieldConfigs,
      handleActionBtnClick,
      editableFields
    })
  }, [
    groupDisplayType,
    grouping,
    suppressCount,
    cellDisplayRules,
    configColumns,
    fieldConfigs,
    handleActionBtnClick,
    editableFields
  ])

  return { groupRowRendererParams }
}

export default useGroupRowRendererParams
