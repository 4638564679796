export enum LS_PREFIXES {
  PROJECT_NAME = 'DRAGON',
  APP_NAME = 'DASHBOARD',
  SIDEBAR_MENU_NAME = 'SIDEBAR_MENU',
  CONNECTION_NAME = 'CONNECTION',
  ENVIRONMENT_NAME = 'ENVIRONMENT',
  PLUGIN_NAME = 'PLUGIN',
  AG_GRID_NAME = 'AG_GRID',
  AG_PIVOT_NAME = 'AG_PIVOT',
  FILTER_STATE_NAME = 'FILTER_STATE',
  SORT_STATE_NAME = 'SORT_STATE',
  STATE_NAME = 'GRID_STATE'
}
