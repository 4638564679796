Ext.define('MDM.src.view.globalapp.Panel.Time.Panel.Tree', {
  extend: 'Ext.tree.Panel',

  xtype: 'globalapp-time-panel-tree',

  columnLines: true,
  rowLines: true,

  columns: [
    {
      xtype: 'treecolumn', //this is so we know which column will show the tree
      text: 'Time Hierarchy',
      dataIndex: 'Title',
      flex: 2,
      width: 300,
      sortable: true
    },
    {
      text: 'Year',
      dataIndex: 'Year',
      align: 'right',

      with: 100
    },
    {
      text: 'Quarter',
      dataIndex: 'Quarter',
      align: 'right',
      with: 100
    },
    {
      text: 'Month',
      dataIndex: 'Month',
      align: 'right',
      with: 100
    },
    {
      text: 'Begin Date',
      dataIndex: 'BeginDate',
      align: 'right',
      with: 100
    },
    {
      text: 'End Date',
      dataIndex: 'EndDate',
      align: 'right',
      with: 100
    },
    {
      text: 'NumberOfDaysIn',
      dataIndex: 'NumberOfDaysInMonth',
      align: 'right',

      with: 100
    }
  ],
  tbar: {
    reference: 'tbar',
    items: [
      {
        text: 'Expand All',
        target: 'dpitree',
        ui: 'btn-success',
        handler: 'onExpandAllClick'
      },
      {
        text: 'Collapse All',
        target: 'dpitree',
        ui: 'btn-success',
        handler: 'onCollapseAllClick'
      }
    ]
  }
})
