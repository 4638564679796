import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { onSort } from '../../../../actions'
import { dataMap } from '../../../../utils/constants'

class Sort extends Component {
  constructor(props) {
    super(props)

    this.state = {
      Aggregation: 'Sum',
      IsAscending: -1
    }

    this.createSortRequest = this.createSortRequest.bind(this)
    this.getCurrentPageRequest = this.getCurrentPageRequest.bind(this)
  }

  componentDidMount() {
    const { isAscending } = this.props

    this.setState({ IsAscending: isAscending })
  }

  componentDidUpdate(prevProps) {
    const { sortConfig = {} } = this.props
    const { sortConfig: prevSortConfig = {} } = prevProps
    const { IsAscending } = this.state

    const isSortResetted = IsAscending !== -1 && _.isEmpty(sortConfig) && !_.isEmpty(prevSortConfig)

    if (isSortResetted) this.setState({ IsAscending: -1 })
  }

  getCurrentPageRequest() {
    const {
      settings: { views = {} },
      lastRequest = {}
    } = this.props
    const { getRequest } = dataMap[_.findKey(views, (v) => v)]

    return {
      Method: getRequest,
      ...lastRequest[getRequest]
    }
  }

  createSortRequest() {
    const { Aggregation, IsAscending } = this.state
    const { issueList, currentIssueIndex = 0, periodIndex, weeklySort } = this.props

    let { tableName = '' } = issueList[currentIssueIndex < 0 ? 0 : currentIssueIndex] || {}
    tableName =
      weeklySort.periodIndex === _.first(periodIndex) &&
      weeklySort.issueName === tableName &&
      IsAscending === weeklySort.isAscending
        ? ''
        : tableName
    if (tableName === '') this.setState({ IsAscending: -1 })
    const SortConfig = {
      Aggregation,
      AvailableAggregations: ['Sum', 'Average', 'SinglePeriod'],
      AvailableIssueNames: _.map(issueList, (i) => i.tableName),
      IsAscending,
      IssueName: tableName,
      PeriodIndex: periodIndex
    }

    return {
      Request: {
        rccpConfig: {
          ...{ SortConfig }
        }
      }
    }
  }

  sort(params = {}, event) {
    event.stopPropagation()
    const { onClick } = this.props

    this.setState(params, () => {
      const { connectApi } = this.props

      const {
        Method = 'GetRccpResourcePeriodHierarchyResponseWPF',
        Request = {},
        Request: { rccpConfig: { SortConfig = {} } = {} } = {}
      } = this.createSortRequest()

      this.props.onSort(SortConfig)
      !_.isNil(onClick) && onClick(params)
      connectApi(Method, Request)
    })
  }

  render() {
    const { ascIcons, descIcons } = this.props
    const { IsAscending } = this.state

    const ascendingClass = IsAscending === 1 ? ascIcons[0] : ascIcons[1]
    const descendingClass = IsAscending === 0 ? descIcons[0] : descIcons[1]

    return (
      <div className="-sort">
        <i
          className={`-btn-up ${ascendingClass}`}
          title="ascending"
          onClick={this.sort.bind(this, { IsAscending: 1 })}
        />
        <i
          className={`-btn-down ${descendingClass}`}
          title="descending"
          onClick={this.sort.bind(this, { IsAscending: 0 })}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  sortConfig: state.resourcePlan.sortConfig,
  weeklySort: state.resourcePlan.weeklySort
})

const mapDispatchToProps = {
  onSort
}

export default connect(mapStateToProps, mapDispatchToProps)(Sort)
