Ext.define('po.model.OrderRecommendationGroup', {
  extend: 'po.model.Base',
  entityName: 'po/OrderRecommendationGroup',
  idProperty: 'ID',
  fields: [
    { name: 'Name', type: 'string' },

    { name: 'OnerilenKayit', type: 'int' },
    { name: 'OnaylananKayit', type: 'int' },
    { name: 'SAPKayit', type: 'int' },
    { name: 'ToplamKayit', type: 'int' },

    { name: 'OnerilenKoli', type: 'float' },
    { name: 'OnaylananKoli', type: 'float' },
    { name: 'SAPKoli', type: 'float' },
    { name: 'ToplamKoli', type: 'float' },

    { name: 'OnerilenTutar', type: 'float' },
    { name: 'OnaylananTutar', type: 'float' },
    { name: 'SAPTutar', type: 'float' },
    { name: 'ToplamTutar', type: 'float' },

    { name: 'OnerilenPalet', type: 'float' },
    { name: 'OnaylananPalet', type: 'float' },
    { name: 'SAPPalet', type: 'float' },
    { name: 'ToplamPalet', type: 'float' },
    { name: 'ToplamPaletFiltresiz', type: 'float' }
  ]
})
