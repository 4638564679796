import { OverlayTrigger, Popover } from 'react-bootstrap'
import {
  AlphaPicker,
  BlockPicker,
  ChromePicker,
  CirclePicker,
  CompactPicker,
  GithubPicker,
  HuePicker,
  MaterialPicker,
  PhotoshopPicker,
  SketchPicker,
  SliderPicker,
  SwatchesPicker,
  TwitterPicker
} from 'react-color'
import DefaultTrigger from './DefaultTrigger'
import { SlvyColorPickerProps } from './SlvyColorPicker.types'

export default function SlvyColorPicker({
  className,
  color,
  picker = 'sketch',
  styles,
  placement = 'bottom',
  trigger,
  triggerEvent = 'click',
  onChange,
  onChangeComplete
}: SlvyColorPickerProps) {
  // In future
  const getPickerProps = () => ({
    className,
    color,
    styles,
    onChange,
    onChangeComplete
  })

  const getPicker = () => {
    switch (picker) {
      case 'alpha':
        return <AlphaPicker {...getPickerProps()} />
      case 'block':
        return <BlockPicker {...getPickerProps()} />
      case 'chrome':
        return <ChromePicker {...getPickerProps()} />
      case 'circle':
        return <CirclePicker {...getPickerProps()} />
      case 'compact':
        return <CompactPicker {...getPickerProps()} />
      case 'github':
        return <GithubPicker {...getPickerProps()} />
      case 'hue':
        return <HuePicker {...getPickerProps()} />
      case 'material':
        return <MaterialPicker {...getPickerProps()} />
      case 'photoshop':
        return <PhotoshopPicker {...getPickerProps()} />
      case 'slider':
        return <SliderPicker {...getPickerProps()} />
      case 'swatches':
        return <SwatchesPicker {...getPickerProps()} />
      case 'twitter':
        return <TwitterPicker {...getPickerProps()} />
      default:
        return <SketchPicker {...getPickerProps()} />
    }
  }

  return (
    <OverlayTrigger
      rootClose
      overlay={<Popover>{getPicker()}</Popover>}
      placement={placement}
      trigger={triggerEvent}
    >
      {trigger || <DefaultTrigger />}
    </OverlayTrigger>
  )
}
