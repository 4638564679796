Ext.define('MDM.Btl.cmp.MaterialTypeBtn', {
  extend: 'Ext.button.Cycle',
  xtype: 'materialtype',
  reference: 'materialtype',
  showText: true,
  prependText: '',
  arrowVisible: false,
  hideMenu: true,
  width: 70,
  showMenu: false,
  bind: {
    activeItem: '{MaterialType}'
  },
  menu: {
    items: [
      {
        text: 'FG',
        itemId: 'FG'
      },
      {
        text: 'FWIP',
        itemId: 'FWIP'
      },
      {
        text: 'All',
        itemId: 'All'
      }
    ]
  },
  changeHandler: 'onChangeMaterialType'
})
