import React, { Component } from 'react'
import _ from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)
require('highcharts/modules/wordcloud')(Highcharts)

class WordCloud extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      subTitle: null
    }
    this.onWordClick = this.onWordClick.bind(this)
  }

  onWordClick(event) {
    let selected = null
    const { fieldName } = this.props.settings.config.data
    _.forEach(this.props.pluginData, (row) => {
      if (row[fieldName] === event.point.name) {
        selected = row
        return false
      }
    })

    if (selected) {
      this.wordClick(selected)
    } else {
      this.wordClick(null)
    }
  }

  componentDidMount() {
    this.wordClick = this.props.registerEvent({
      key: 'wordClick',
      fn: this.wordClick.bind(this),
      returnTypes: _.transform(
        this.props.settings.query.fields,
        (result, field = {}) => {
          const { dataType = '' } = field
          result[field.fieldName] = PluginTypes.fromString(dataType)
        },
        {}
      )
    })
    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setSubTitle',
      fn: this.setSubTitle.bind(this),
      args: [{ name: 'subTitle', type: PluginTypes.string }]
    })
  }

  setTitle({ title }) {
    if (this.state.title !== title) {
      this.setState({ title })
    }
  }

  setSubTitle({ subTitle }) {
    if (this.state.subTitle !== subTitle) {
      this.setState({ subTitle })
    }
  }

  wordClick(value) {
    return value
  }

  getColorArray(colorsObject) {
    const array = _.map(colorsObject, 'fieldColor')

    return array
  }

  render() {
    const { config: pluginConfig = {} } = this.props.settings || {}

    const config = _.cloneDeep(pluginConfig)

    const defaults = {
      credits: {
        enabled: true,
        text: `Solvoyo © ${new Date().getFullYear()}`,
        href: '#',
        style: { fontSize: '12px' }
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      }
    }

    // disable credits
    config.credits = defaults.credits
    const { chart: { showCredits = true } = {} } = pluginConfig
    config.credits.enabled = showCredits !== false

    // display no data
    config.noData = defaults.noData

    // set empty text
    const { chart: { emptyText = 'No Data to Display' } = {} } = config
    config.lang = {
      noData: emptyText || ' '
    }

    // move export button to the top-left corner
    if (!config.exporting) {
      config.exporting = {}
    }

    config.exporting.buttons = {
      contextButton: {
        align: 'left'
      }
    }

    const {
      settings: {
        config: {
          data: { fieldName: nameField, fieldWeight: weightField } = {},
          chart: { placementStrategy, spiral, colors } = {}
        } = {}
      } = {},
      pluginData = []
    } = this.props

    const wordClick = {
      events: {
        click: this.onWordClick
      }
    }

    const defaultColors = [
      '#7cb5ec',
      '#434348',
      '#90ed7d',
      '#f7a35c',
      '#8085e9',
      '#f15c80',
      '#e4d354',
      '#2b908f',
      '#f45b5b',
      '#91e8e1'
    ]

    if (_.size(pluginData) > 0) {
      config.series = [
        {
          type: 'wordcloud',
          data: [],
          name: 'Weight',
          events: {}
        }
      ]

      _.forEach(pluginData, (row) => {
        if (weightField && _.has(row, weightField)) {
          if (row[weightField] && row[nameField] && row[weightField] > 0) {
            config.series[0].data.push({ name: row[nameField], weight: row[weightField] })
          }
        }
      })

      config.series[0].point = wordClick
      config.series[0].placementStrategy = !placementStrategy ? 'center' : placementStrategy
      config.series[0].spiral = !spiral ? 'rectangular' : spiral
      config.series[0].colors =
        !colors || colors.length < 1 ? defaultColors : this.getColorArray(colors)
    }

    return (
      <div className="highcharts-container">
        <HighchartsReact highcharts={Highcharts} options={config} />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings
})

export default createPlugin(WordCloud, selectConnectorProps)
