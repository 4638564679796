Ext.define('Spui.view.common.spotParameters.Main', {
  extend: 'Ext.panel.Panel',

  xtype: 'spui-view-common-spotparameters-main',
  mixins: ['Spui.view.common.spotParameters.Mixin'],

  defaultListenerScope: true,
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },
  bodyPadding: 2,
  border: true,
  defaults: {
    frame: false,
    bodyPadding: 2
  }
})
