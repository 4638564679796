import { useCallback } from 'react'
import { CellClickedEvent } from 'ag-grid-community'
import { IRowData, TrowDataValue } from '../../types'

function useHandleBooleanIcon() {
  const handleBooleanIcon = useCallback((event: CellClickedEvent<IRowData, TrowDataValue>) => {
    const {
      node: { data },
      colDef: { field },
      event: innerEvent
    } = event

    const dataSlvyElementSelector: string | null | undefined = (
      innerEvent?.target as HTMLElement
    )?.getAttribute?.('data-slvy-element-selector')

    const isBooleanIconCellItem = dataSlvyElementSelector === 'booleanIconCellRenderer'

    if (!data || !field || !isBooleanIconCellItem) {
      return
    }

    const newValue = !data[field]
    event.node.setDataValue(field, newValue)
  }, [])

  return { handleBooleanIcon }
}

export default useHandleBooleanIcon
