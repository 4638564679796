Ext.define('Spui.view.tracking.navigation.Main', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-tracking-navigation-main',

  defaultListenerScope: true,
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },

  ui: 'spui-white',
  bodyPadding: 1,
  collapsable: false,
  tools: [
    {
      type: 'refresh',
      tooltip: t('Refresh'),
      handler: 'onRefrehTracking'
    }
  ],

  defaults: {
    frame: false
  },
  config: {
    statusReportStore: {},
    statusReportCfg: 'statusReport',
    statusReport: {},

    spotGridStore: {},
    spotGridCfg: 'SpotGrid',
    spotGrid: {}
  },
  applyStatusReportStore: function (b) {
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        fields: [
          { name: 'SpotId', type: 'int' },
          { name: 'MaterialCode', type: 'string' },
          { name: 'MaterialDescription', type: 'string' },
          { name: 'MaterialGroupCode', type: 'string' },
          { name: 'MaterialGroupName', type: 'string' },
          { name: 'SalesPrice', type: 'number' },
          { name: 'UnitofMeasurement', type: 'string' },
          { name: 'UnitsPerPackage', type: 'number' },
          { name: 'MinQuantity', type: 'number' },
          { name: 'MaxQuantity', type: 'number' },
          { name: 'PreStartDate', type: 'date', dateFormat: 'Y-m-d' },
          { name: 'PreEndDate', type: 'date', dateFormat: 'Y-m-d' },
          { name: 'PageIndex', type: 'int' },
          { name: 'PageName', type: 'string' },
          { name: 'CreatedBy', type: 'string' },
          { name: 'CreatedAt', type: 'date', dateFormat: 'Y-m-d H:i:s.u' },
          { name: 'IsDeleted', type: 'boolean' },
          { name: 'DeletedBy', type: 'string' },
          { name: 'DeletedAt', type: 'date', dateFormat: 'Y-m-d H:i:s.u' },
          { name: 'CategoryManagerCode', type: 'string' },
          { name: 'PuplishedBy', type: 'string' },
          { name: 'PublishDate', type: 'date', dateFormat: 'Y-m-d H:i:s.u' },
          { name: 'DaysForApprove', type: 'int' },
          { name: 'DaysForTargetSales', type: 'int' },
          { name: 'ImgURL', type: 'string' },
          { name: 'Notes', type: 'string' },
          { name: 'StatusCode', type: 'string' },
          { name: 'NumberOfRecommendedOrg', type: 'int' },
          { name: 'NumberOfWaitingOrg', type: 'int' },
          { name: 'NumberOfDelegatedOrg', type: 'int' },
          { name: 'NumberOfApprovedOrg', type: 'int' },
          { name: 'OriginalRecommendation', type: 'number' },
          { name: 'UpdatedRecommendation', type: 'number' },
          { name: 'ApprovedRecommendation', type: 'number' },
          { name: 'IsEditable', type: 'int' }
        ],
        proxy: {
          type: 'ajax',
          timeout: 120000,
          url: Spui.Global.getApiUrl() + '/spui/GetStatusReport',
          listeners: {
            exception: function (proxy, response, operation) {
              Spui.util.ShowErrorBox.requestexception(proxy, response, operation)
            }
          }
        },
        autoLoad: true
      })

      b = Ext.create('Ext.data.Store', b)
    }
    return b
  },
  applySpotGridStore: function (b) {
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        model: 'Spui.data.model.SpotList',
        autoLoad: false,
        remoteFilter: true,
        pageSize: 20,
        remoteSort: true,
        applyState: function () {},
        listeners: {
          exception: function (proxy, response, operation) {
            Spui.util.ShowErrorBox.requestexception(proxy, response, operation)
          }
        }
      })

      b = Ext.create('Ext.data.Store', b)
    }
    b.load()
    return b
  },
  updateStatusReportStore: function (f, g) {
    f.on('load', this.onLoadStatusReportStore, this)
  },
  onSelectGrid: function () {
    var store = this.getSpotGridStore()

    var grid = this.getSpotGrid()

    var selModel = grid.selModel

    var rec = store.first()

    var me = this

    if (rec) {
      Ext.Function.defer(function () {
        selModel.select(rec)
      }, 10)
    } else {
      var form = me.up('spuimainview')
      if (form) {
        var vm = form.getViewModel()
        var emptyModel = vm.get('emptyModel')
        selModel.select(emptyModel)
      }
    }
  },
  applyStatusReportCfg: function (c) {
    return window.ss(c)
  },
  applySpotGridCfg: function (c) {
    return window.ss(c)
  },
  applyStatusReport: function (b) {
    var setRec = this.getStatusReportCfg()

    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        ui: 'spui-white',
        xtype: 'spui-view-tracking-leftside-dataview-statusreport',
        store: this.getStatusReportStore(),
        title: ''
      })

      b = Ext.create(b)
    }
    return b
  },
  updateStatusReport: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  applySpotGrid: function (b) {
    var setRec = this.getSpotGridCfg()

    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        ui: 'spui-white-inner',
        xtype: 'spui-view-tracking-navigation-spotgrid',
        store: this.getSpotGridStore(),
        title: ''
      })

      b = Ext.create(b)
    }
    return b
  },
  updateSpotGrid: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onLoadStatusReportStore: function (store) {
    var lastStatusDesciption = this.up().getViewModel().get('lastStatusDescription')
    var record = store.findRecord('StatusDescription', lastStatusDesciption)
    if (!record) {
      record = store.findRecord('IsDefault', 1)
    }

    var report = this.getStatusReport()

    var selModel = report.selModel
    selModel.select(record)
    this.fireEvent('loadStatusReport', this)
  },

  onBeforeLoadStatusReportStore: function (store) {},
  onConfigureStatusReport: function () {
    var setRec = this.getStatusReportCfg()

    var code = setRec.get('code')

    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,

      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onStatusReportCfgSave, this)
  },

  onStatusReportCfgSave: function (v, k) {
    var setRec = this.getStatusReportCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()

    var res = new Function('return ' + k)()

    this.setStatusReport(res)
  },
  onSelectStatusReport: function (dataview, selected, eOpts) {
    if (selected.length == 0) return
    var me = this

    var record = selected[0]
    me.setSpotGridCfg('Spot-' + record.get('ShortTitle'))
    me.setSpotGrid({
      title: tt(record.get('StatusDescription'))
    })
    var that = this.up('spui-view-tracking-tracking')
    var vm = that.getViewModel()
    var statusDescription = record.get('StatusDescription')
    vm.set('lastStatusDescription', statusDescription)
    var isDistributionTab = statusDescription === 'Distribution'
    var isContractTab = statusDescription === 'Contract'
    vm.set('isDistributionTab', isDistributionTab)
    vm.set('isContractTab', isContractTab)

    var spotStore = this.getSpotGridStore()
    if (spotStore.currentPage > 1) spotStore.currentPage = 1
    spotStore.clearFilter()
    if (spotStore.sorters && spotStore.sorters.getCount() > 0) {
      spotStore.sorters.clear()
    }
    var proxy = spotStore.getProxy()
    proxy.setExtraParam('statusCode', selected[0].get('StatusCode'))
    spotStore.load()
    this.onSelectGrid()
  },

  onConfigureSpotGrid: function () {
    var setRec = this.getSpotGridCfg()

    var code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onSpotGridCfgSave, this)
  },

  onSpotGridCfgSave: function (v, k) {
    var setRec = this.getSpotGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()

    var res = new Function('return ' + k)()

    this.setSpotGrid(res)
  },

  onClickDeleteSpot: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Onay',
      width: 500,
      msg: tt('Are you sure you want to delete this record? <br><br>'),
      buttonText: {
        yes: 'Evet',
        no: 'Hayır'
      },
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDeleteNode(record)
          me.onRefrehTracking()
        }
      }
    })
  },
  doDeleteNode: function (record) {
    var store = record.store
    record.drop()
    store.sync()
  },
  onEditWizard: function (grid, rowIndex, colIndex, item, e, record) {
    this.fireEvent('editwizard', grid, record)
  },
  onRefrehTracking: function () {
    var p = this.up('spui-view-tracking-tracking')
    p.getViewModel().set('spotRecord', { SpotId: -1 })
    var statusReportStore = this.getStatusReportStore()
    statusReportStore.load()
    var spotGridStore = this.getSpotGridStore()
    spotGridStore.load()
  },

  onSelectSpot: function (grid, record) {
    var store = grid.getStore()
    var datas = store.getDatas()
    var param = datas.length > 0 ? record : null
    this.fireEvent('showspot', grid, param)
  }
})
