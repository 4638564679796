Ext.define('MDM.src.view.globalapp.Panel.Time.Panel.Grid', {
  extend: 'Ext.grid.Panel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-time-grid',
  features: [
    {
      ftype: 'grouping',
      startCollapsed: true,
      groupHeaderTpl:
        '{columnName}: {name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  columnLines: true,
  rowLines: true,
  columns: [
    {
      text: 'FiscalYear',
      dataIndex: 'FiscalYear',
      flex: 1
    },
    {
      text: 'Year',
      dataIndex: 'Year',

      flex: 1
    },
    {
      text: 'Quarter',
      dataIndex: 'Quarter',

      flex: 1
    },
    {
      text: 'Month',
      dataIndex: 'Month',

      flex: 1
    },
    {
      text: 'Month_Desc',
      dataIndex: 'Month_Desc',

      flex: 1
    },
    {
      text: 'BeginDate',
      dataIndex: 'BeginDate',

      flex: 1
    },
    {
      text: 'EndDate',
      dataIndex: 'EndDate',

      flex: 1
    },
    {
      text: 'NumberOfDaysInMonth',
      dataIndex: 'NumberOfDaysInMonth',
      align: 'right',
      flex: 1
    }
  ]
})
