Ext.define('Spui.util.imageupload.Upload', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-util-imageupload-upload',

  minWidth: 200,
  minHeight: 160,
  layout: {
    type: 'fit'
  },

  privates: {
    initialImageWidth: null,
    initialImageHeight: null
  },

  config: {
    maxFileSize: null,
    maxFileHeight: null,
    maxFileWidth: null,

    hideTbar: true,

    imageId: null,
    imageName: 'uploadedImage',
    initialImage: Ext.BLANK_IMAGE_URL,

    uploadButtonText: tt('Select image...'),
    // inserts height
    errorHeight: tt('Incorrect image height (more than {0} px)'),
    // inserts width
    errorWidth: tt('Incorrect image width (more than {0} px)'),
    errorInvalidFile: tt('Invalid file selected'),
    errorNoImage: tt('No image selected'),
    // inserts size
    errorFileSize: tt('File is too large (more than {0} Byte)'),
    errorFileMismatch: tt('File mismatch'),

    fileName: '',
    fileSize: '',
    fileHeight: '',
    fileWidth: '',

    fileInfo: null,
    infoEmptyText: tt('Please select an image file.'),
    infoTpl: tt('Filename: {name}, Filesize: {size} bytes, Height: {height} px, Width: {width} px'),
    backgroundImage:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAIkl' +
      'EQVQIW2NcvXp1PQMUhIaGNsLYjKRLwLSCaGTdjCRLAACWAxOH5+V5JAAAAABJRU5ErkJggg=='
  },

  initComponent: function () {
    var me = this

    me.on({
      afterrender: me.onAfterrender,
      scope: me
    })

    if (!me.getHideTbar()) {
      me.tbar = [me.getFileUploadFieldConfig(me), me.getInfoButtonConfig(me)]
    }

    me.callParent()
  },

  /**
   * Handler for afterrender event
   *
   * - Sets up image element
   * - Sets up Hidden-Field
   */
  onAfterrender: function () {
    var me = this,
      imageStyle

    Ext.defer(function () {
      if (me.rendered) {
        imageStyle =
          'min-width: 8px; max-width: ' +
          me.body.getWidth() +
          'px; width: auto; max-height: ' +
          me.body.getHeight() +
          'px; margin: auto;'

        me.setImageId(me.getId() + '-image')

        me.add([
          {
            xtype: 'component',
            role: 'imageContainer',
            flex: 1,
            style: {
              border: '1px solid #dddddd',
              background: '#ffffff url(' + me.getBackgroundImage() + ')',
              display: 'flex'
            },
            html:
              '<img alt="image" src="' +
              Ext.BLANK_IMAGE_URL +
              '" style="' +
              imageStyle +
              '" id="' +
              me.getImageId() +
              '"></img>'
          },
          {
            xtype: 'hidden',
            role: 'imageData',
            name: me.getImageName()
          }
        ])
      }
    }, 250)
  },

  getFileUploadFieldConfig: function (me) {
    return {
      xtype: 'filebutton',
      hideLabel: true,
      text: me.getUploadButtonText(),
      style: 'width: 100%',
      listeners: {
        afterrender: me.onFileFieldAfterRender,
        change: me.onFileFieldChange,
        scope: me
      }
    }
  },

  getInfoButtonConfig: function (me) {
    return {
      xtype: 'button',
      text: '?',
      handler: function () {
        Ext.MessageBox.alert('File Info', me.getFileInfo() || me.getInfoEmptyText())
      }
    }
  },

  /**
   * Handler for afterrender event of FileField
   *
   * - Sets up accept attribute for file-upload element to limit files
   *
   * @param {Ext.form.field.File} fileField
   */
  onFileFieldAfterRender: function (fileField) {
    var dom = fileField.el.dom,
      input = dom.getElementsByClassName('x-form-file-input')[0]

    if (input) {
      input.setAttribute('accept', 'image/*')
    }
  },

  /**
   * Handler for change event of FileField
   *
   * - Validates selected file
   * - Calls file handler
   */
  onFileFieldChange: function () {
    var me = this,
      files = Ext.EventObject.target.files,
      file = null,
      reader = new FileReader(),
      isValid,
      isImage,
      isSmall

    if (files.length === 1) {
      file = files[0]

      isValid = file.size > 0
      isImage = file.type.match('image.*')
      isSmall = me.getMaxFileSize() ? file.size < me.getMaxFileSize() : true

      if (isValid && isImage && isSmall) {
        reader.onload = (function (inFile) {
          return me.onReaderLoad(inFile)
        })(file)

        reader.readAsDataURL(file)
      } else {
        var messages = []
        if (!isValid) {
          messages.push(me.getErrorInvalidFile())
        }
        if (!isImage) {
          messages.push(me.getErrorNoImage())
        }
        if (!isSmall) {
          messages.push(Ext.String.format(me.getErrorFileSize(), me.getMaxFileSize()))
        }
        Ext.Msg.alert(me.getErrorFileMismatch(), messages.join('<br />'))
      }
    }
  },

  /**
   * Handler of reader load event
   *
   * - Sets up Image element, file info and private parameters
   * - Fires imageLoaded on E2open.ux.image.Upload if succeeded
   *
   * @param {File} file
   * @fires E2open.ux.image.Upload#imageLoaded
   */
  onReaderLoad: function (file) {
    var me = this,
      imageEl = Ext.get(me.getImageId()),
      hiddenField = me.down('hidden'),
      container = me.down('component[role=imageContainer]'),
      tmpImg,
      imgWidth,
      imgHeight

    return function (e) {
      var base64 = e.target.result,
        heightOk,
        widthOk

      container.setLoading(true)

      // create temporary image element to get size
      tmpImg = document.createElement('img')
      tmpImg.src = base64
      tmpImg.style.visibility = 'hidden'
      document.body.appendChild(tmpImg)

      // Use deferer to ensure a loaded image
      Ext.defer(function () {
        imgHeight = tmpImg.height
        imgWidth = tmpImg.width
        document.body.removeChild(tmpImg)
        tmpImg.remove()

        heightOk = !(me.getMaxFileHeight() && imgHeight > me.getMaxFileHeight())
        widthOk = !(me.getMaxFileWidth() && imgWidth > me.getMaxFileWidth())

        if (heightOk && widthOk) {
          // set base64 as value of hidden field
          hiddenField.setValue(base64)

          // set image source
          imageEl.el.dom.setAttribute('src', base64)

          me.setFileSize(file.size)
          me.setFileName(file.name)
          me.setFileHeight(imgHeight)
          me.setFileWidth(imgWidth)

          // set image tooltip
          me.setFileInfo(
            new Ext.XTemplate(me.getInfoTpl()).apply({
              name: me.getFileName(),
              size: me.getFileSize(),
              height: me.getFileHeight(),
              width: me.getFileWidth()
            })
          )

          if (imgWidth < 1 || imgHeight < 1) {
            Ext.Msg.alert(me.getErrorFileMismatch(), me.getErrorNoImage())
            return false
          }

          me.initialImageWidth = imgWidth
          me.initialImageHeight = imgHeight

          /**
           * Fires imageLoaded event on E2open.ux.image.Upload
           *
           * @event E2open.ux.image.Upload#imageLoaded
           * @type {object}
           * @property {E2open.ux.image.Upload} me
           */
          me.fireEvent('imageLoaded', me)
        } else {
          var messages = []
          if (!heightOk) {
            messages.push(Ext.String.format(me.getErrorHeight(), me.getMaxFileHeight()))
          }
          if (!widthOk) {
            messages.push(Ext.String.format(me.getErrorWidth(), me.getMaxFileWidth()))
          }
          Ext.Msg.alert(me.getErrorFileMismatch(), messages.join('<br />'))
        }
        container.setLoading(false)
      }, 250)
    }
  }
})
