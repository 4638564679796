export default `({
    //plain: true,
    // bind: {
    //     title: '{spotRecord.MaterialDescription}' + ' Urun Istatistikleri'
    // },
    header: false,
    tabPosition: 'top',
    stateEvents: 'tabchange',
    stateful: true,
    stateId: 'tabpanel-demo',
    margin: '2 0 0 0',
    // tabPosition: 'right',
    // initComponent: function () {
    //     var me = this;
    //     me.callParent();
    // },
    items: [{
            title: tt('MaterialInformation'),
            xtype: 'spui-view-common-productinfo-cmp-tabpanel-info-main',
            bind: {
                materialCode: '{spotRecord.MaterialCode}',
            }
        }, {
            xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-main',
            title: tt('Sales Chart'),
            bind: {
                recordData: '{spotRecord}',
                materialCode: '{spotRecord.MaterialCode}',
            },
        },
        {
            title: tt('InventoryList'),
            xtype: 'spui-view-common-productinfo-cmp-tabpanel-inventory-main',
            inventoryCfg: Spui.Global.getUserRole().userOrg + '-InventoryList',
            bind: {
                materialCode: '{spotRecord.MaterialCode}',
            },
        },
        {
            title: tt('Old Campaign'),
            xtype: 'uxcontainer',
            uxItemCfg: 'eeeeeee',
            uxStoreCfg: 'oldSpotInformations',
            uxItem: {
                bind: {
                    materialCode: '{spotRecord.MaterialCode}',
                },
            }
        }
    ]
})
`
