import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import { DraggableRowProps } from './DraggableRow.types'

const DraggableRow = ({
  children,
  index,
  className,
  isSelected,
  style,
  updateRowOrder,
  onClick
}: DraggableRowProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const styles = useTheme()

  const [{ borderTop }, drop] = useDrop({
    accept: 'row',
    collect: (monitor) => ({
      borderTop: cx({ 'border-top border-success': monitor.isOver() })
    }),
    drop(item: DraggableRowProps) {
      if (!ref.current) {
        return
      }
      const sourceIndex = item.index
      const destinationIndex = index

      // Don't replace items with themselves
      if (sourceIndex === destinationIndex) {
        return
      }

      updateRowOrder(sourceIndex, destinationIndex)
    }
  })

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'row',
      item: { index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 'opacity-50' : 'opacity-100'
      })
    }),
    []
  )

  drag(drop(ref))
  return (
    <div
      ref={ref}
      className={cx(styles.row, { [styles.isSelected]: isSelected }, className, opacity, borderTop)}
      role="button"
      style={style}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={undefined}
    >
      {children}
    </div>
  )
}

export default DraggableRow
