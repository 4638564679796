Ext.define('tuiv2.cmp.FacilityForm', {
  extend: 'Ext.window.Window',

  viewModel: {
    data: {
      record: null
    }
  },
  stateful: true,
  stateId: 'FacilityFormState',
  resize: false,
  iconCls: 'x-fa fa-pencil-square-o',
  layout: 'border',
  modal: true,
  closable: false,
  ui: 'window-inverse',
  title: 'Update Location',
  defaultListenerScope: true,
  height: 600,
  width: 600,
  config: {
    formType: 'Region',

    descriptionField: {
      xtype: 'textarea',
      fieldLabel: 'Description',
      name: 'description',
      flex: 1,
      bind: {
        value: '{record.Description}',
        readOnly: '{readOnlyMode}'
      }
    },

    titleField: {
      xtype: 'textfield',
      fieldLabel: 'Title',
      name: 'title',
      allowBlank: false,
      bind: {
        value: '{record.LoadStopDesc}',
        readOnly: '{readOnlyMode}'
      }
    },

    latLongContainer: {
      xtype: 'fieldcontainer',
      fieldLabel: 'Lat/Lng',

      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },

    latitudeField: {
      xtype: 'textfield',
      itemId: 'latitude',
      name: 'startTime',
      margin: '0 0 0 0',
      flex: 1,
      readOnly: true,
      bind: {
        value: '{record.Latitude}'
      }
    },
    longitudeField: {
      xtype: 'textfield',
      itemId: 'longitude',
      name: 'startTime',
      margin: '0 0 0 5',
      flex: 1,
      readOnly: true,
      bind: {
        value: '{record.Longitude}'
      }
    },
    polishizeField: {
      xtype: 'checkbox',
      itemId: 'allDay',
      name: 'polishize',
      boxLabel: 'Optimize',
      bind: {
        value: '{record.polishize}'
      },
      hideEmptyLabel: false
    },

    toContainer: {
      xtype: 'fieldcontainer',
      fieldLabel: 'Lat/Long',
      layout: 'hbox'
    },

    dropButton: {
      text: 'Delete',
      handler: 'onDropTap'
    },
    cloneButton: {
      text: 'Clone',
      handler: 'onCloneTap',
      iconCls: 'x-fa fa-clone'
    },
    saveButton: {
      text: 'Save',
      handler: 'onSaveTap',
      iconCls: 'x-fa fa-floppy-o',
      bind: {
        hidden: '{readOnlyMode}',
        disabled: '{!record.dirty}'
      }
    },
    cancelButton: {
      text: 'Cancel',
      iconCls: 'x-fa fa-times',
      handler: 'onCancelTap'
    }
  },

  initForm: function () {
    this.createItems()
  },
  initComponent: function () {
    var me = this
    this.getViewModel().set('record', this.record)
    me.initForm()
    me.fbar = me.generateButtons()

    me.callParent()
    me.form = me.items.first()
  },
  generateButtons: function () {
    return [
      this.getCloneButton(),
      {
        xtype: 'component',
        flex: 1
      },
      this.getSaveButton(),
      this.getCancelButton()
    ]
  },

  createItems: function () {
    var me = this,
      latLongCt = me.getLatLongContainer(),
      items = []

    items.push(me.getTitleField())

    if (!latLongCt.items) {
      latLongCt.items = [me.getLatitudeField(), me.getLongitudeField()]
    }
    items.push(latLongCt)

    items.push(me.getPolishizeField())
    me.items = [
      {
        xtype: 'form',
        border: false,
        trackResetOnLoad: true,
        region: 'north',
        height: '100',
        layout: {
          type: 'vbox',
          align: 'stretch'
        },
        bodyPadding: 10,
        items: items
      },
      {
        xtype: 'panel',
        region: 'center',
        layout: 'fit',
        bodyPadding: 10,
        border: true,
        margin: '5 0 0 0',

        items: {
          xtype: 'mainmap',
          region: 'center',
          reference: 'map',
          bind: {
            mapData: 1,
            record: '{record}',
            planInfo: '{PlanInfo}',
            maxDemandQuantity: '{maxDemandQuantity}'
          },
          listeners: {
            dragend: 'onDragend'
          }
        }
      }
    ]
  },

  privates: {
    onCancelTap: function () {
      this.close()
    },

    onSaveTap: function () {
      Ext.MessageBox.confirm(
        'Confirm',
        'Are you sure you want to save data?',
        this.applyResult,
        this
      )
    },
    onCloneTap: function () {
      Ext.MessageBox.confirm(
        'Confirm',
        'Are you sure you want to clone data?',
        this.applyResultClone,
        this
      )
    }
  },
  applyResult: function (btn, text) {
    if (btn == 'yes') {
      this.saveData(false)
    }
  },
  applyResultClone: function (btn, text) {
    if (btn == 'yes') {
      this.saveData(true)
    }
  },

  saveData: function (isNew) {
    var me = this,
      data = this.record.data
    me.vm.set('isOperationInProgress', true)

    data.Cloned = isNew ? 1 : 0
    me.setLoading('Saving Please wait....')

    Ext.Ajax.request({
      method: 'POST',
      url: tuiv2.Global.getApiUrl() + 'tuiv2/LocationUpdate',
      timeout: 600000,
      params: {
        data: JSON.stringify(data)
      },
      success: function (response, opts) {
        me.setLoading(false)
        me.vm.set('isOperationInProgress', false)
        me.close()
      },
      failure: function (response, opts) {
        me.setLoading(false)
        me.vm.set('isOperationInProgress', false)
        Ext.MessageBox.hide()
        me.close()
      }
    })
  }
})
