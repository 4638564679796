interface Itheme {
  id: string
  name: string
  slug: string
}
export const themes: Itheme[] = [
  {
    id: '0',
    name: 'Default Theme',
    slug: 'default-theme'
  },
  {
    id: '1',
    name: 'Sleek',
    slug: 'sleek-theme'
  }
]
