export default () => {
  return {
    title: 'List Filter',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          }
        }
      },
      fields: {
        type: 'array',
        title: 'Fields',
        items: {
          type: 'object',
          title: 'Field',
          headerTemplate: '{{ self.fieldName }}',
          options: {
            collapsed: true
          },
          properties: {
            fieldName: {
              type: 'string',
              title: 'Field Name',
              default: '',
              required: true
            },
            displayName: {
              type: 'string',
              title: 'Display Name',
              default: ''
            },
            dataType: {
              type: 'string',
              title: 'Data Type',
              enum: ['string', 'int', 'double', 'datetime', 'boolean'],
              default: 'string',
              required: true
            }
          }
        }
      },
      operators: {
        type: 'array',
        title: 'Operators',
        default: [
          { symbol: '=', display: '=' },
          { symbol: '!=', display: '≠' },
          { symbol: '>', display: '>' },
          { symbol: '>=', display: '≥' },
          { symbol: '<', display: '<' },
          { symbol: '<=', display: '≤' }
        ],
        items: {
          type: 'object',
          title: 'Operator',
          headerTemplate: '{{ self.symbol }}',
          options: {
            collapsed: true
          },
          properties: {
            symbol: {
              type: 'string',
              title: 'Symbol',
              default: ''
            },
            display: {
              type: 'string',
              title: 'Display',
              default: ''
            },
            default: {
              type: 'boolean',
              title: 'Default',
              default: false,
              required: true
            }
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          operatorPosition: {
            type: 'string',
            title: 'Operator Position',
            default: 'center',
            enum: ['left', 'center', 'right'],
            required: true
          }
        }
      },
      filterButtonSettings: {
        type: 'object',
        title: 'Filter Button',
        options: {
          hidden: true
        },
        properties: {
          filterButtonEnabled: {
            type: 'boolean',
            title: 'Enabled',
            default: true,
            required: true
          },
          filterButtonText: {
            type: 'string',
            title: 'Filter Button Text',
            default: 'Apply'
          },
          filterButtonTooltip: {
            type: 'string',
            title: 'Filter Button Tooltip',
            default: 'Apply'
          },
          filterButtonStyle: {
            type: 'string',
            title: 'Filter Button Style',
            default: 'success',
            enum: ['success', 'primary', 'info', 'warning', 'danger'],
            required: true
          },
          filterButtonIcon: {
            type: 'string',
            title: 'Filter Button Icon',
            format: 'IconSelector',
            default: 'fa fa-filter'
          }
        }
      }
    }
  }
}
