/* eslint-disable jest/no-export */

export const customInfo = {
  editing: { lookupDataField: '' },
  fieldLookupConfig: {
    isLookupQuery: true,
    substituteField: 'col2'
  }
}

export const data = {
  col1: 'New York',
  col2: 77,
  col3: 10
}

export const dirtyCells = [
  {
    field: 'col1',
    isSubstitute: false,
    newValueParams: {
      id: 'New York',
      value: 'New York'
    },
    oldValueParams: {
      id: 'Barcelona',
      value: 'Barcelona'
    }
  },
  {
    field: 'col2',
    isSubstitute: false,
    newValueParams: {
      id: 77,
      value: 77
    },
    oldValueParams: {
      id: 222,
      value: 222
    }
  }
]

export const lookupOptions = [
  { id: 3, value: 'Electronics' },
  { id: 4, value: 'Fashion' }
]

export const lookupValuesString = '1:Green~2:Blue'

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
