import { useEvent } from '@/hooks'
import { IRowData } from '../../types'
import { HandleResetProps } from './useHandleReset.types'
import { getInitialVersionOfDirtyRows, getNewAddedRows } from '../../helpers'

function useHandleReset(props: HandleResetProps) {
  const {
    deletedRows,
    gridRef,
    setFilteredRowCount,
    setTotalRowCount,
    resetCustomRowInfo,
    resetDeletedRows
  } = props

  const handleReset = useEvent(() => {
    const newRollbackRowNodesData: IRowData[] = getInitialVersionOfDirtyRows(gridRef)
    const newAddedNodesData: IRowData[] = getNewAddedRows(gridRef)

    // remove newly added but not saved rows
    if (newAddedNodesData.length) {
      gridRef?.current?.api.applyTransaction({ remove: newAddedNodesData })
    }

    // add newly removed but not saved rows
    deletedRows.forEach(({ data, rowIndex }) => {
      gridRef?.current?.api.applyTransaction({
        add: [data],
        addIndex: rowIndex
      })
    })

    if (newRollbackRowNodesData.length) {
      // rollback changes
      gridRef?.current?.api.applyTransaction({ update: newRollbackRowNodesData })
    }

    resetCustomRowInfo()

    resetDeletedRows()

    setTotalRowCount((prev) => prev + deletedRows.length - newAddedNodesData.length)
    setFilteredRowCount((prev) => prev + deletedRows.length - newAddedNodesData.length)
  })
  return { handleReset }
}

export default useHandleReset
