import { getFieldLookupConfig } from '../index'
import { editableFields } from '../../../helpers/__tests__/mocks'

describe('AgGrid:Helpers:Lookup:getFieldLookupConfig', () => {
  it('should return the correct FieldLookUpConfig for a valid field', () => {
    const result = getFieldLookupConfig('field1', editableFields)
    expect(result).toEqual({
      lookupQueryList: ['query1', 'query2'],
      isLookupQuery: true,
      substituteField: 'subField1'
    })
  })

  it('should return the correct FieldLookUpConfig for a different valid field', () => {
    const result = getFieldLookupConfig('field2', editableFields)
    expect(result).toEqual({
      lookupQueryList: ['query3', 'query4'],
      isLookupQuery: false,
      substituteField: 'subField2'
    })
  })

  it('should return an empty FieldLookUpConfig for an invalid field', () => {
    const result = getFieldLookupConfig('invalidField', editableFields)
    expect(result).toEqual({
      lookupQueryList: [],
      isLookupQuery: false,
      substituteField: null
    })
  })

  it('should return an empty FieldLookUpConfig when editableFields is empty', () => {
    const result = getFieldLookupConfig('field1', [])
    expect(result).toEqual({
      lookupQueryList: [],
      isLookupQuery: false,
      substituteField: null
    })
  })

  it('should return an empty FieldLookUpConfig when editableFieldDef is undefined', () => {
    const result = getFieldLookupConfig('field3', editableFields)
    expect(result).toEqual({
      lookupQueryList: [],
      isLookupQuery: false,
      substituteField: null
    })
  })
})
