export default `({
    plugins: [
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns:[
{text:'SpotId',dataIndex:'SpotId',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'MaterialCode',dataIndex:'MaterialCode',width:168},
{text:'MaterialDescription',dataIndex:'MaterialDescription',width:200},
{text:'MaterialGroupCode',dataIndex:'MaterialGroupCode',width:140},
{text:'MaterialGroupName',dataIndex:'MaterialGroupName',width:200},
{text:'SalesPrice',dataIndex:'SalesPrice',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'UnitofMeasurement',dataIndex:'UnitofMeasurement',width:168},
{text:'MinQuantity',dataIndex:'MinQuantity',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'MaxQuantity',dataIndex:'MaxQuantity',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'PreStartDate',dataIndex:'PreStartDate',width:100},
{text:'PreEndDate',dataIndex:'PreEndDate',width:100},
{text:'PageIndex',dataIndex:'PageIndex',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'PageName',dataIndex:'PageName',width:168},
{text:'CreatedBy',dataIndex:'CreatedBy',width:200},
{text:'CreatedAt',dataIndex:'CreatedAt',width:100},
{text:'IsDeleted',dataIndex:'IsDeleted',width:100},
{text:'DeletedBy',dataIndex:'DeletedBy',width:200},
{text:'DeletedAt',dataIndex:'DeletedAt',width:100},
{text:'CategoryManagerCode',dataIndex:'CategoryManagerCode',width:200},
{text:'PuplishedBy',dataIndex:'PuplishedBy',width:200},
{text:'PublishDate',dataIndex:'PublishDate',width:100},
{text:'DaysForApprove',dataIndex:'DaysForApprove',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'ImgURL',dataIndex:'ImgURL',width:200},
{text:'Notes',dataIndex:'Notes',width:200},
{text:'DCCode',dataIndex:'DCCode',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'DCName',dataIndex:'DCName',width:200},
{text:'OrderRecommendation_DC',dataIndex:'OrderRecommendation_DC',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'OrderRecommendation_DC_UpdatedbyMO',dataIndex:'OrderRecommendation_DC_UpdatedbyMO',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'OrderRecommendation_DC_UpdatedbyDC',dataIndex:'OrderRecommendation_DC_UpdatedbyDC',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'NumberofStores',dataIndex:'NumberofStores',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'NumberofRecommendedStores',dataIndex:'NumberofRecommendedStores',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'StatusCode',dataIndex:'StatusCode',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'OrderRecommendation_SO',dataIndex:'OrderRecommendation_SO',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'OrderRecommendation_SO_UpdatedbyDC',dataIndex:'OrderRecommendation_SO_UpdatedbyDC',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'OrderRecommendation_SO_UpdatedbySO',dataIndex:'OrderRecommendation_SO_UpdatedbySO',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'NumberOfWaitingStore',dataIndex:'NumberOfWaitingStore',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'NumberOfApprovedStore',dataIndex:'NumberOfApprovedStore',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'NumberOfDismissedStore',dataIndex:'NumberOfDismissedStore',width:100,format:'000.00',align:'right',xtype:'numbercolumn'},
{text:'IsEditable',dataIndex:'IsEditable',width:100,format:'000.00',align:'right',xtype:'numbercolumn'}],
{
    text: tt('UnitsPerPackage'),
    dataIndex: 'UnitsPerPackage',
    width: 100,
    format: '000.00',
    align: 'right',
    filter: {
        type: 'number'
    },
    xtype: 'numbercolumn'
}, 
})
`
