import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DemandTreeTableState = {
  demandIndexes: number[]
  facilityNames: string[]
  selectedRows: Record<string, unknown>[]
  isAutoServeModalOpen: boolean
  isOriginModalOpen: boolean
  selectedFacilityIndex: string | undefined
  disabledFooterButtons: Record<string, boolean>
}

const initialState: DemandTreeTableState = {
  demandIndexes: [],
  facilityNames: [],
  selectedRows: [],
  isAutoServeModalOpen: false,
  isOriginModalOpen: false,
  selectedFacilityIndex: undefined,
  disabledFooterButtons: {}
}

const demandTreeTableSlice = createSlice({
  name: 'demandTreeTable',
  initialState,
  reducers: {
    setSelectedRows(state, action: PayloadAction<DemandTreeTableState['selectedRows']>) {
      state.selectedRows = action.payload
    },
    setDemandIndexes(state, action: PayloadAction<DemandTreeTableState['demandIndexes']>) {
      state.demandIndexes = action.payload
    },
    setFacilityNames(state, action: PayloadAction<DemandTreeTableState['facilityNames']>) {
      state.facilityNames = action.payload
    },
    setSelectedFacilityIndex(
      state,
      action: PayloadAction<DemandTreeTableState['selectedFacilityIndex']>
    ) {
      state.selectedFacilityIndex = action.payload
    },
    setIsAutoServeModalOpen(state, action: PayloadAction<boolean>) {
      state.isAutoServeModalOpen = action.payload
    },
    setIsOriginModalOpen(state, action: PayloadAction<boolean>) {
      state.isOriginModalOpen = action.payload
    },
    setDisabledFooterButtons(state, action: PayloadAction<{ text: string; value: boolean }>) {
      const { text, value } = action.payload
      state.disabledFooterButtons[text] = value
    }
  }
})

export default demandTreeTableSlice
export type { DemandTreeTableState }
