import { SET_SCROLL_POS } from '../actions/actionTypes'
import { updateObject } from './utility'

function setInitialScrollPos(...scrollAreas) {
  return scrollAreas.reduce((scrollAreas, scrollArea) => {
    scrollAreas[scrollArea] =
      scrollArea === 'detailsTable'
        ? {}
        : {
            top: 0,
            left: 0
          }

    return scrollAreas
  }, {})
}

const initialState = {
  supplyPlan: setInitialScrollPos('main'),
  productionPlan: setInitialScrollPos('main', 'issueList', 'detailsTable'),
  productionPlanV2: setInitialScrollPos('main'),
  resourcePlan: setInitialScrollPos('main', 'issueList', 'detailsTable'),
  resourceSchedule: setInitialScrollPos('main'),
  shiftCalendar: setInitialScrollPos('main')
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SCROLL_POS:
      if (action.payload.scrollArea === 'detailsTable') {
        return updateObject(state, {
          [action.payload.view]: {
            ...state[action.payload.view],
            [action.payload.scrollArea]: {
              ...state[action.payload.view].detailsTable,
              ...action.payload.scrollPos
            }
          }
        })
      }
      return updateObject(state, {
        [action.payload.view]: {
          ...state[action.payload.view],
          [action.payload.scrollArea]: action.payload.scrollPos
        }
      })
    default:
      return state
  }
}
