export default [
  {
    Index: 0,
    FieldId: 'brandID',
    Key: 'Brand',
    Value: null,
    IsRequired: true,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 1,
    FieldId: 'divisionID',
    Key: 'Division',
    Value: null,
    IsRequired: true,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 2,
    FieldId: 'subDivisionID',
    Key: 'SubDivision',
    Value: null,
    IsRequired: true,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 3,
    FieldId: 'ageID',
    Key: 'Age',
    Value: null,
    IsRequired: true,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 4,
    FieldId: 'lifeStyleID',
    Key: 'LifeStyle',
    Value: null,
    IsRequired: true,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 5,
    FieldId: 'collectionGroupID',
    Key: 'CollectionGroup',
    Value: null,
    IsRequired: false,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 6,
    FieldId: 'buyerGroupID',
    Key: 'BuyerGroup',
    Value: null,
    IsRequired: false,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 7,
    FieldId: 'classID',
    Key: 'Class',
    Value: null,
    IsRequired: false,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 8,
    FieldId: 'rangeID',
    Key: 'Range',
    Value: null,
    IsRequired: false,
    IsMultiple: false,
    LimitedAt: 0,
    IsDisabled: false,
    IsGrouped: false,
    Info: null
  },
  {
    Index: 11,
    FieldId: 'seasonID',
    Key: 'SeasonCode',
    Value: null,
    IsRequired: true,
    IsMultiple: true,
    LimitedAt: 3,
    IsDisabled: false,
    IsGrouped: true,
    Info: {
      Header: 'Information',
      Body: [
        'Hold down the CTRL key to select multiple seasons.',
        'The seasons must be in the same season group.'
      ]
    }
  }
]
