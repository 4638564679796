Ext.define('MDM.src.view.globalapp.Panel.Time.Main', {
  extend: 'Ext.Container',
  controller: 'globalapp-time-maincontroller',
  viewModel: 'globalapp-time-mainviewmodel',
  xtype: 'globalapp-time-main',
  listeners: {
    activate: 'onActivateGeo'
  },
  layout: {
    type: 'hbox',
    pack: 'start',
    align: 'stretch'
  },
  items: [
    {
      xtype: 'globalapp-time-panel-tree',
      region: 'west',
      margin: '0 2  0 0',

      border: true,
      reference: 'tree',
      flex: 1,
      ui: 'panel-inverse',
      title: 'Time Hierarchy',
      bind: {
        store: '{TimeStore}'
      }
    }
  ]
})
