import { AxiosPromise } from 'axios'
import axiosWithDefaults from '../axiosWithDefaults'
import { handleAsyncAwait } from '@/helpers'
import { slvyToast } from '@/components'

export interface MakeRequestAction {
  pluginId: string
  method: string
  requestMethod?: 'get' | 'post'
  body?: { [prop: string | number]: any }
}

export interface RequestError {
  data: {
    result: Record<'errorCode' | 'isSuccess' | 'successCode', string | boolean | number>[]
  }
}

export const makeRequest = async (
  action: MakeRequestAction
): Promise<AxiosPromise<any> | RequestError> => {
  const { pluginId, method, body, requestMethod = 'get' } = action

  const url = `${process.env.API_URL}/data/plugin/${pluginId}/${method}`

  const request = axiosWithDefaults({
    url,
    method: requestMethod,
    ...(requestMethod === 'post' ? { data: body } : {})
  })

  const [response, error] = await handleAsyncAwait(request)

  if (!navigator.onLine) {
    throw {
      data: { result: [{ errorCode: 'No internet connection', isSuccess: false }] }
    }
  }

  if (error) {
    throw error
  }

  // success // TODO:
  slvyToast.dismiss()

  return response
}
