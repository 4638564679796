Ext.define('MDM.src.view.globalapp.Panel.setup.SetupDate', {
  extend: 'Ext.panel.Panel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-setupdate',
  bodyBorder: false,
  defaults: {
    collapsible: true,
    split: true,
    bodyPadding: 4
  },
  layout: 'border',
  items: [
    {
      xtype: 'globalapp-calendar',
      visibleWeeks: null,
      timezoneOffset: 0,
      gestureNavigation: false,
      bind: {
        value: '{CalendarDate}',
        store: '{calStore}'
      },
      region: 'center',
      floatable: false,
      margin: '3 0 0 0',
      collapsible: false
    },
    {
      header: false,
      width: 900,
      minWidth: 100,
      maxWidth: 800,
      collapsible: true,
      region: 'west',
      margin: '0 0 0 0',
      layout: 'fit',
      defaults: {
        collapsible: false,
        split: false,
        bodyPadding: 2
      },
      items: [
        {
          border: true,
          region: 'north',
          collapsible: false,
          height: 192,
          margin: '0 0 4 0',
          xtype: 'globalapp-setup-form'
        },
        {
          flex: 1,
          region: 'center',
          collapsible: false,
          bodyPadding: 0,
          border: true,
          xtype: 'globalapp-setup-grid',
          bind: {
            store: '{EditCalStore}',
            readOnlyMode: '{readOnlyMode}'
          }
        }
      ]
    }
  ]
})
