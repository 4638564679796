// @flow
import type { DraggableLocation } from 'react-beautiful-dnd'
import type { TaskCard, TaskCardMap } from './types'

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default reorder

type ReorderTaskCardMapArgs = {|
  taskCardMap: TaskCardMap,
  source: DraggableLocation,
  destination: DraggableLocation
|}

export type ReorderTaskCardMapResult = {|
  taskCardMap: TaskCardMap,
  autoFocusTaskCardId: ?string
|}

export const reorderTaskCardMap = ({
  taskCardMap,
  source,
  destination
}: ReorderTaskCardMapArgs): ReorderTaskCardMapResult => {
  const current: TaskCard[] = [...taskCardMap[source.droppableId]]
  const next: TaskCard[] = [...taskCardMap[destination.droppableId]]
  const target: TaskCard = current[source.index]

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered: TaskCard[] = reorder(current, source.index, destination.index)
    const result: TaskCardMap = {
      ...taskCardMap,
      [source.droppableId]: reordered
    }
    return {
      taskCardMap: result,
      // not auto focusing in own list
      autoFocusTaskCardId: null
    }
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1)
  // insert into next
  next.splice(destination.index, 0, target)

  const result: TaskCardMap = {
    ...taskCardMap,
    [source.droppableId]: current,
    [destination.droppableId]: next
  }

  return {
    taskCardMap: result,
    autoFocusTaskCardId: target.id
  }
}
