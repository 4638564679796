Ext.define('MDM.Btl.Column', {
  singleton: true,
  config: {
    columnTitles: [],
    startCol: 14,
    hidden: false,
    totalRowColors: []
  },
  constructor: function (config) {
    var me = this
    me.initConfig(config)
  },
  applyStartCol: function (c) {
    return c
  },
  isEditable: function (index) {
    return true
  },
  isHidden: function (index) {
    var me = this
    if (me.getHidden()) return index < me.getStartCol()
  },
  isLocked: function (index) {
    var me = this
    if (index <= me.getStartCol()) return 'islocked'
    return ''
  },

  build: function (definedColumns, hidden) {
    var me = this
    me.setHidden(hidden)
    var fiscealYear = MDM.Btl.Column.getColumnTitles()

    var f1 = fiscealYear && fiscealYear.length > 0 ? fiscealYear[0] : '18-19'

    var f2 = fiscealYear && fiscealYear.length > 1 ? fiscealYear[1] : '19-20'

    var f3 = fiscealYear && fiscealYear.length > 2 ? fiscealYear[2] : '20-21'

    var columns = []
    columns = [
      {
        text: f1,
        groupable: true,
        menuDisabled: true,
        columns: [
          {
            text: 'Jul',
            dataIndex: 'c01',
            xtype: 'btlnumber',
            editable: me.isEditable(1),
            hidden: me.isHidden(1),
            tdCls: me.isLocked(1),
            summaryType: 'sum'
          },
          {
            text: 'Aug',
            dataIndex: 'c02',
            xtype: 'btlnumber',
            editable: me.isEditable(2),
            hidden: me.isHidden(2),
            tdCls: me.isLocked(2),
            summaryType: 'sum'
          },
          {
            text: 'Sep',
            dataIndex: 'c03',
            xtype: 'btlnumber',
            editable: me.isEditable(3),
            hidden: me.isHidden(3),
            tdCls: me.isLocked(3),
            summaryType: 'sum'
          },
          {
            text: 'Oct',
            dataIndex: 'c04',
            xtype: 'btlnumber',
            editable: me.isEditable(4),
            hidden: me.isHidden(4),
            tdCls: me.isLocked(4),
            summaryType: 'sum'
          },
          {
            text: 'Nov',
            dataIndex: 'c05',
            xtype: 'btlnumber',
            editable: me.isEditable(5),
            hidden: me.isHidden(5),
            tdCls: me.isLocked(5),
            summaryType: 'sum'
          },
          {
            text: 'Dec',
            dataIndex: 'c06',
            xtype: 'btlnumber',
            editable: me.isEditable(6),
            hidden: me.isHidden(6),
            tdCls: me.isLocked(6),
            summaryType: 'sum'
          },
          {
            text: 'Jan',
            dataIndex: 'c07',
            xtype: 'btlnumber',
            editable: me.isEditable(7),
            hidden: me.isHidden(7),
            tdCls: me.isLocked(7),
            summaryType: 'sum'
          },
          {
            text: 'Feb',
            dataIndex: 'c08',
            xtype: 'btlnumber',
            editable: me.isEditable(8),
            hidden: me.isHidden(8),
            tdCls: me.isLocked(8),
            summaryType: 'sum'
          },
          {
            text: 'Mar',
            dataIndex: 'c09',
            xtype: 'btlnumber',
            editable: me.isEditable(9),
            hidden: me.isHidden(9),
            tdCls: me.isLocked(9),
            summaryType: 'sum'
          },
          {
            text: 'Apr',
            dataIndex: 'c10',
            xtype: 'btlnumber',
            editable: me.isEditable(10),
            hidden: me.isHidden(10),
            tdCls: me.isLocked(10),
            summaryType: 'sum'
          },
          {
            text: 'May',
            dataIndex: 'c11',
            xtype: 'btlnumber',
            editable: me.isEditable(11),
            hidden: me.isHidden(11),
            tdCls: me.isLocked(11),
            summaryType: 'sum'
          },
          {
            text: 'Jun',
            dataIndex: 'c12',
            xtype: 'btlnumber',
            editable: me.isEditable(12),
            hidden: me.isHidden(12),
            tdCls: me.isLocked(12),
            summaryType: 'sum'
          },
          {
            text: 'Ending',
            tdCls: 'subtotal',
            dataIndex: 't1',
            xtype: 'btlnumber',
            editable: false,
            hidden: me.isHidden(12),
            summaryType: 'sum'
          }
        ]
      },
      {
        text: f2,
        menuDisabled: true,
        columns: [
          {
            text: 'Jul',
            dataIndex: 'c13',
            xtype: 'btlnumber',
            menuDisabled: true,
            editable: me.isEditable(13),
            hidden: me.isHidden(13),
            tdCls: me.isLocked(13),
            summaryType: 'sum'
          },
          {
            text: 'Aug',
            dataIndex: 'c14',
            xtype: 'btlnumber',
            menuDisabled: true,
            editable: me.isEditable(14),
            hidden: me.isHidden(14),
            tdCls: me.isLocked(14),
            summaryType: 'sum'
          },
          {
            text: 'Sep',
            dataIndex: 'c15',
            xtype: 'btlnumber',
            editable: me.isEditable(15),
            hidden: me.isHidden(15),
            tdCls: me.isLocked(15),
            summaryType: 'sum'
          },
          {
            text: 'Oct',
            dataIndex: 'c16',
            xtype: 'btlnumber',
            editable: me.isEditable(16),
            hidden: me.isHidden(16),
            tdCls: me.isLocked(16),
            summaryType: 'sum'
          },
          {
            text: 'Nov',
            dataIndex: 'c17',
            xtype: 'btlnumber',
            editable: me.isEditable(17),
            hidden: me.isHidden(17),
            tdCls: me.isLocked(17),
            summaryType: 'sum'
          },
          {
            text: 'Dec',
            dataIndex: 'c18',
            xtype: 'btlnumber',
            editable: me.isEditable(18),
            hidden: me.isHidden(18),
            tdCls: me.isLocked(18),
            summaryType: 'sum'
          },
          {
            text: 'Jan',
            dataIndex: 'c19',
            xtype: 'btlnumber',
            editable: me.isEditable(19),
            hidden: me.isHidden(19),
            tdCls: me.isLocked(19),
            summaryType: 'sum'
          },
          {
            text: 'Feb',
            dataIndex: 'c20',
            xtype: 'btlnumber',
            editable: me.isEditable(20),
            hidden: me.isHidden(20),
            tdCls: me.isLocked(20),
            summaryType: 'sum'
          },
          {
            text: 'Mar',
            dataIndex: 'c21',
            xtype: 'btlnumber',
            editable: me.isEditable(21),
            hidden: me.isHidden(21),
            tdCls: me.isLocked(21),
            summaryType: 'sum'
          },
          {
            text: 'Apr',
            dataIndex: 'c22',
            xtype: 'btlnumber',
            editable: me.isEditable(22),
            hidden: me.isHidden(22),
            tdCls: me.isLocked(22),
            summaryType: 'sum'
          },
          {
            text: 'May',
            dataIndex: 'c23',
            xtype: 'btlnumber',
            editable: me.isEditable(23),
            hidden: me.isHidden(23),
            tdCls: me.isLocked(23),
            summaryType: 'sum'
          },
          {
            text: 'June',
            dataIndex: 'c24',
            xtype: 'btlnumber',
            editable: me.isEditable(24),
            hidden: me.isHidden(24),
            tdCls: me.isLocked(24),
            summaryType: 'sum'
          },
          {
            text: 'Ending',
            tdCls: 'subtotal',
            dataIndex: 't2',
            xtype: 'btlnumber',
            editable: false,
            summaryType: 'sum'
          }
        ]
      },
      {
        text: f3,
        menuDisabled: true,
        columns: [
          {
            text: 'Jul',
            dataIndex: 'c25',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Aug',
            dataIndex: 'c26',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Sep',
            dataIndex: 'c27',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Oct',
            dataIndex: 'c28',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Nov',
            dataIndex: 'c29',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Dec',
            dataIndex: 'c30',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Jan',
            dataIndex: 'c31',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Feb',
            dataIndex: 'c32',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Mar',
            dataIndex: 'c33',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Apr',
            dataIndex: 'c34',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'May',
            dataIndex: 'c35',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'June',
            dataIndex: 'c36',
            xtype: 'btlnumber',
            summaryType: 'sum'
          },
          {
            text: 'Ending',
            tdCls: 'subtotal',
            dataIndex: 't3',
            xtype: 'btlnumber',
            editable: false,
            summaryType: 'sum'
          }
        ]
      }
    ]
    return definedColumns.concat(columns)
  },
  updateTotalRowColors: function (colors) {
    var totalRowColors = ['-', '-', '-']
    this.setColumnTitles(totalRowColors)
  }
})
