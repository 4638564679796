import React from 'react'
import { IProps } from './SoftTimeline.types'

export function SoftTimeline({ Headers }: IProps) {
  return (
    <div className="slvy-ui-table-section -timeline">
      <div className="slvy-ui-row -header">
        <div className="cell -title -stick-to-left -cell-title" />
        {Headers.map((header) => (
          <div key={header} className="cell">
            {header}
          </div>
        ))}
        <div className="cell -end -stick-to-right pe-2">Total</div>
      </div>
    </div>
  )
}
