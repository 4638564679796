import { memo, useMemo } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import { emptyObject } from '../../../../../constants'
import { IAssigneeMenu, IAssigneeMenuDrilldown } from '../../../../Common.types'
import { getMenuRegisters, sortKeyInObject } from '../../../helpers'
import styles from '../../../index.module.scss'
import ContainerHeader from '../../ContainerHeader'
import Menus from '../../Menus'
import { IMenuRegisterTree, IMenusContainerProps } from './MenusContainer.types'

export const getActiveMenuMethodsCount = (
  menuRegistersTree: IMenuRegisterTree,
  assignees: IAssigneeMenu
) => {
  const count = {
    active: 0,
    total: 0
  }
  _.each(menuRegistersTree, (menu, menuKey) => {
    const drilldown = assignees?.[menuKey]?.drilldown ?? {}
    const selectedValues = Object.values(drilldown).filter((val) => val)

    const isParamsExist = Object.keys(menu.params).length
    count.active += isParamsExist && selectedValues.length ? 1 : 0
    count.total += isParamsExist ? 1 : 0
  })
  return count
}

const MenusContainer = ({
  assignees,
  variables,
  handleReturnVariableChange,
  menuTree,
  toggleActives
}: IMenusContainerProps) => {
  const menuRegistersTree = useMemo(() => {
    const dataMenuTree = getMenuRegisters(menuTree)
    return sortKeyInObject(dataMenuTree, 'params')
  }, [menuTree])
  const { active, total } = getActiveMenuMethodsCount(menuRegistersTree, assignees)
  const title = useMemo(() => <h6 className="p-0 m-0">Menu Methods (ALL)</h6>, [])

  if (toggleActives && !active) {
    return null
  }

  return (
    <Accordion defaultActiveKey={active ? '0' : ''}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="mb-0 pb-0">
          <ContainerHeader active={active} title={title} total={total} />
        </Accordion.Header>
        <Accordion.Body className={cx('p-3', styles.relationAccordionBody)}>
          {_.map(menuRegistersTree, (menu, menuKey) => {
            const {
              [menuKey]: {
                drilldown: menuAssignees = emptyObject
              } = emptyObject as IAssigneeMenuDrilldown
            } = assignees

            if (_.isEqual(menu.params, {})) {
              return null
            }

            return (
              <Menus
                key={menuKey}
                assignees={menuAssignees}
                id={menuKey}
                menuParams={menu.params}
                parentKey="drilldown"
                title={menu.title}
                toggleActives={toggleActives}
                variables={variables}
                onChange={handleReturnVariableChange}
              />
            )
          })}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default memo(MenusContainer)
