import { useState, useEffect, useMemo, ChangeEvent, FormEvent } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { confirmAlert } from 'react-confirm-alert'
import { Form, FormControl, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import cx from 'classnames'
import getAdminForm from '@/utils/admin-container'
import diacritics from '@/utils/diacritics'
import { SlvyFormIconSelector, slvyToast } from '@/components'
import defaultForm from './constants'
import { AdminContainerProps } from '@/elements/Container/Admin/Admin.types'
import { FormState } from './Admin.types'

const AdminContainer = (props: AdminContainerProps) => {
  const {
    propertyEditorFields,
    settings,
    settings: { overlays: initialOverlays = [], hideModeList: initialHideModeList = [] },
    onChangeSettings
  } = props

  const getInitialForm = useMemo(() => {
    return getAdminForm(propertyEditorFields, settings.form, defaultForm)
  }, [propertyEditorFields, settings])

  const [form, setForm] = useState<FormState>(getInitialForm)
  const [overlays, setOverlays] = useState<boolean[]>(initialOverlays)
  const [hideModeList, setHideModeList] = useState<boolean[]>(initialHideModeList)

  useEffect(() => {
    setForm(getInitialForm)
  }, [getInitialForm])

  const { tabs, ids, icons, tabsHidden, iconsHidden, iconPosition } = form

  const moveInput = (from: number, to: number) => {
    const tabsClone = [...tabs]
    const idsClone = [...ids]
    const iconsClone = [...icons]

    // Update tab order
    const tabsTemp = tabsClone[from]
    tabsClone[from] = tabsClone[to]
    tabsClone[to] = tabsTemp

    const idsTemp = idsClone[from]
    idsClone[from] = idsClone[to]
    idsClone[to] = idsTemp

    const iconsTemp = iconsClone[from] || ' '
    iconsClone[from] = iconsClone[to] || ' '
    iconsClone[to] = iconsTemp

    setForm((prevForm) => ({ ...prevForm, tabs: tabsClone, ids: idsClone, icons: iconsClone }))
  }

  const removeInput = (index: number) => {
    confirmAlert({
      title: 'Deleting Tab',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Cancel',
          onClick: () => null
        },
        {
          label: 'Confirm Delete',
          onClick: () => {
            let tabsClone = [...tabs]
            let idsClone = [...ids]
            let overlaysClone = [...overlays]
            let hideModeListClone = [...hideModeList]
            let iconsClone = [...icons]

            tabsClone = _.filter(tabsClone, (_t, i) => i !== index)
            idsClone = _.filter(idsClone, (_t, i) => i !== index)
            overlaysClone = _.filter(overlaysClone, (_t, i) => i !== index)
            hideModeListClone = _.filter(hideModeListClone, (_t, i) => i !== index)
            iconsClone = _.filter(iconsClone, (_t, i) => i !== index)

            setForm((prevForm) => ({
              ...prevForm,
              tabs: tabsClone,
              ids: idsClone,
              icons: iconsClone
            }))
            setOverlays(overlaysClone)
            setHideModeList(hideModeListClone)
          }
        }
      ]
    })
  }

  const handleChangeOverlay = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    initialOverlays[index] = checked
    setOverlays(initialOverlays)
  }

  const handleChangeHideMode = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    initialHideModeList[index] = checked
    setHideModeList(initialHideModeList)
  }

  const addInput = () => {
    // it gets 8 digit id
    const id = uuidv4().split('-')[0]

    const updatedTabs = [...tabs, '']
    const updatedIds = [...ids, id]
    const updatedOverlays = [...overlays, false]
    const updatedHideModeList = [...hideModeList, false]
    const updatedIcons = [...icons, '']

    setForm((prevForm) => ({
      ...prevForm,
      tabs: updatedTabs,
      ids: updatedIds,
      icons: updatedIcons
    }))
    setOverlays(updatedOverlays)
    setHideModeList(updatedHideModeList)
  }

  const handleChangeHideTabs = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    setForm((prevForm) => ({ ...prevForm, tabsHidden: checked }))
  }

  const handleChangeHideIcons = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    setForm((prevForm) => ({ ...prevForm, iconsHidden: checked }))
  }

  const handleChangeTabText = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      currentTarget: { value = '' }
    } = event

    const tabsClone = [...tabs]
    tabsClone[index] = value

    setForm((prevForm) => ({ ...prevForm, tabs: tabsClone }))
  }

  const handleChangeIconPosition = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked }
    } = event

    const iconPos = checked ? 'Left' : 'Right'

    setForm((prevForm) => ({ ...prevForm, iconPosition: iconPos }))
  }

  const handleChangeTabIcon = (index: number, value: string) => {
    const iconsClone = [...icons]
    iconsClone[index] = value

    setForm((prevForm) => ({ ...prevForm, icons: iconsClone }))
  }

  const getValidationState = (tabIndex?: number) => {
    const checkTabs = _.isUndefined(tabIndex) ? tabs : [tabs[tabIndex]]
    if (checkTabs.some((tab) => tab.trim().length === 0)) {
      return 'error'
    }
    return null
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!getValidationState()) {
      const updatedIds = ids.map((id, idIndex) => {
        // uuid generates 8 character id
        if (id.length === 8) {
          const text = diacritics.removeAndLowerCase(tabs[idIndex])
          // eslint-disable-next-line no-param-reassign
          id = `${text}_${id}`
        }
        return id
      })

      setForm((prevForm) => ({ ...prevForm, ids: updatedIds }))

      onChangeSettings({
        ...settings,
        form: {
          ...form,
          tabs,
          ids: updatedIds
        },
        hideModeList,
        overlays
      })
      slvyToast.success({ message: 'Saved successfully' })
    }
  }

  return (
    <div>
      <Form className="containerForm" onSubmit={handleSubmit}>
        <Form.Group className="form-group mb-2">
          <div className="inlinegroups">
            <Form.Group className="form-group mb-2 tab-container-group">
              <Form.Label className="fs-sm"> Tabs </Form.Label>
              <div className="checkbox-group">
                <input
                  checked={tabsHidden}
                  className="visibility-checkbox fs-sm"
                  id="tabs-visibility"
                  type="checkbox"
                  value="1"
                  onChange={handleChangeHideTabs}
                />
                <Form.Label className="hidden-label" htmlFor="tabs-visibility">
                  <i className="slvy-ui-icon-view-regular" />
                </Form.Label>
                <br />
                <div> Visibility </div>
              </div>
            </Form.Group>
            <Form.Group className="form-group mb-2 tab-container-group tab-container-icon-group">
              <Form.Label> Icons </Form.Label>
              <div className="icon-group">
                <div className="checkbox-group icon-settings">
                  <input
                    checked={iconsHidden}
                    className="visibility-checkbox fs-sm"
                    id="icon-visibility"
                    type="checkbox"
                    onChange={handleChangeHideIcons}
                  />
                  <Form.Label className="hidden-label" htmlFor="icon-visibility">
                    <i className="slvy-ui-icon-view-regular" />
                  </Form.Label>
                  <br />
                  <div> Visibility </div>
                </div>
                <div className="checkbox-group icon-settings">
                  <input
                    checked={iconPosition === 'Left'}
                    className="icon-position-checkbox"
                    id="icon-position"
                    type="checkbox"
                    onChange={handleChangeIconPosition}
                  />
                  <Form.Label className="hidden-label" htmlFor="icon-position">
                    <i className="slvy-ui-icon-square--half" />
                  </Form.Label>
                  <br />
                  <div> Position </div>
                </div>
              </div>
            </Form.Group>
          </div>
        </Form.Group>
        {_.map(tabs, (tab, index) => (
          <Form.Group key={index} className="form-group mb-2">
            <div className="inlinegroups tabcontaineradmin">
              <OverlayTrigger
                delay={250}
                overlay={<Tooltip id="tooltip-overlay">Overlay</Tooltip>}
                placement="top"
              >
                <Button className="tab-container-overlays border-0" size="sm" variant="light">
                  <input
                    className="overlays-checkbox fs-sm"
                    defaultChecked={overlays[index]}
                    id={`change-overlay_${index}`}
                    type="checkbox"
                    onChange={(event) => handleChangeOverlay(index, event)}
                  />
                  <Form.Label htmlFor={`change-overlay_${index}`}>
                    <i className="slvy-ui-icon-box_1" />
                  </Form.Label>
                </Button>
              </OverlayTrigger>
              <SlvyFormIconSelector
                inputClass="tab-item-icon-input border-0"
                value={icons[index]}
                onChange={(value: string) => handleChangeTabIcon(index, value)}
              />
              <OverlayTrigger
                delay={250}
                overlay={<Tooltip id="tooltip-hidemode">HideMode</Tooltip>}
                placement="top"
              >
                <Button
                  className="tab-container-overlays tab-container-hideModeTabs border-0"
                  size="sm"
                  variant="light"
                >
                  <input
                    className="overlays-checkbox fs-sm"
                    defaultChecked={hideModeList[index]}
                    id={`load-tab_${index}`}
                    type="checkbox"
                    onChange={(event) => handleChangeHideMode(index, event)}
                  />
                  <Form.Label htmlFor={`load-tab_${index}`}>
                    <i className="slvy-ui-icon-box_1" />
                  </Form.Label>
                </Button>
              </OverlayTrigger>
              <Form.Group className="form-group mb-2">
                <FormControl
                  required
                  className="tab-container-admin-name-input"
                  isInvalid={getValidationState(index) === 'error'}
                  maxLength={50}
                  size="sm"
                  value={tab}
                  onChange={(event) => handleChangeTabText(index, event)}
                />
              </Form.Group>
              <Button
                className="border-0"
                disabled={index === tabs.length - 1}
                size="sm"
                variant="outline-secondary"
                onClick={() => moveInput(index, index + 1)}
              >
                <i className="slvy-ui-icon-arrow-down" />
              </Button>
              <Button
                className="border-0"
                disabled={index === 0}
                size="sm"
                variant="outline-secondary"
                onClick={() => moveInput(index, index - 1)}
              >
                <i className="slvy-ui-icon-arrow-up" />
              </Button>
              <Button
                className="border-0"
                size="sm"
                variant="outline-secondary"
                onClick={() => removeInput(index)}
              >
                <i className="slvy-ui-icon-trash-can" />
              </Button>
            </div>
            <Form.Control.Feedback
              className={cx({
                'd-block': getValidationState(index) && getValidationState(index)
              })}
              type="invalid"
            >
              Name is required!
            </Form.Control.Feedback>
          </Form.Group>
        ))}
        <Form.Group className="form-group mb-2" controlId="formHorizontalEmail">
          <Button size="sm" variant="primary" onClick={addInput}>
            <i className="slvy-ui-icon-plus-lt" /> Add New
          </Button>
        </Form.Group>
        <Button size="sm" type="submit" variant="success">
          <i className="fa fa-save" /> Save
        </Button>
      </Form>
    </div>
  )
}

export default AdminContainer
