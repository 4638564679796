import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '@/constants'

const libraryTreeApi = createApi({
  reducerPath: 'libraryTreeApiReducer',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/data`,
    prepareHeaders: (headers) => {
      const {
        setting: { environment = '' },
        oidc: {
          user: { access_token = '' }
        }
      } = window.dashboardStore.getState()

      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')
      headers.set('environment', environment)
      headers.set('Authorization', `Bearer ${access_token}`)
      return headers
    }
  }),
  endpoints(builder) {
    return {
      createLibraryTreeFolderNode: builder.mutation({
        invalidatesTags: ['createLibrary'],
        query: (info) => {
          const { pluginId = '', method = '', body = {}, requestMethod = 'get' } = info
          return {
            url: `/${pluginId}/invoke/${method}`,
            body: body,
            method: requestMethod
          }
        }
      }),
      deleteLibraryTreeNode: builder.mutation({
        invalidatesTags: ['deleteLibrary'],
        query: (info) => {
          const { pluginId = '', method = '', body = {}, requestMethod = 'get' } = info
          return {
            url: `/${pluginId}/invoke/${method}`,
            body: body,
            method: requestMethod
          }
        }
      }),
      updateLibraryTreeNode: builder.mutation({
        invalidatesTags: ['updateLibrary'],
        query: (info) => {
          const { pluginId = '', method = '', body = {}, requestMethod = 'get' } = info
          return {
            url: `/${pluginId}/invoke/${method}`,
            body: body,
            method: requestMethod
          }
        }
      })
    }
  }
})

export const {
  useCreateLibraryTreeFolderNodeMutation,
  useDeleteLibraryTreeNodeMutation,
  useUpdateLibraryTreeNodeMutation
} = libraryTreeApi

export default libraryTreeApi
