import _ from 'lodash'

const mapboxSettings = {
  styleId: 'dev-solvoyo/ckgwdm1y3138o1ar56he8vsi9',
  access_token:
    'pk.eyJ1IjoiZGV2LXNvbHZveW8iLCJhIjoiY2tndzhzZ3plMDd0ajMwb2VtMmdoeGd6cCJ9.D8h3DyVfy1OQC56-W8nXbw'
}

const options = {
  theme: {
    lightgray: {
      title: 'lightgray-theme'
    },
    dark: {
      title: 'dark-theme'
    },
    default: {
      title: 'default'
    }
  },
  tiles: {
    OpenStreetMap: {
      title: 'Open Street Map',
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      options: {
        maxZoom: 20,
        opacity: 0.5,
        attribution:
          '&copy <a href="https://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
      }
    },
    EsriSatellite: {
      title: 'EsriSatellite',
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      options: {
        attribution: '&copy <a href="http://www.esri.com/" target="_blank">Esri</a> contributors'
      }
    },
    Topographic: {
      title: 'Topographic',
      url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
      options: {
        attribution:
          '&copy <a href="https://opentopomap.org/" target="_blank">Open Topomap</a> contributors'
      }
    },
    Mapbox: {
      title: 'Mapbox',
      url: `https://api.mapbox.com/styles/v1/${mapboxSettings.styleId}/tiles/256/{z}/{x}/{y}?access_token=${mapboxSettings.access_token}`,
      options: {
        maxZoom: 20,
        attribution: '&copy; GGL/OSM'
      }
    }
  }
}

const leafletOptions = _.reduce(
  options.tiles,
  (newOptions, value, key) => {
    newOptions[key] = value

    _.forEach(options.theme, (v, k) => {
      newOptions[`${key}.${k}`] = { ...value, ...{ themeOptions: v } }
      newOptions[`${key}.${k}`].title = `${key}.${k}`
    })

    return newOptions
  },
  {}
)

export { options, leafletOptions }

export default leafletOptions
