import React, { Component } from 'react'

export default class OutsideAlerter extends Component {
  componentDidMount() {
    global.addEventListener('mousedown', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    global.removeEventListener('mousedown', this.handleClickOutside, true)
  }

  setWrapperRef = (node) => (this.wrapperRef = node)

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClick()
    }
  }

  render = () => (
    <section ref={this.setWrapperRef} style={{ height: '100%' }}>
      {this.props.children}
    </section>
  )
}
