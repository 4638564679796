import { cellEditedClasses, DEFAULT_CUSTOM_ROW_INFO } from '../../../constants'
import { getCellClassRules } from '../index'
import { AG_GRID_ENUMS } from '../../../enums'

const { CUSTOM_ROW_INFO, DIRTY_CELLS } = AG_GRID_ENUMS

function getDefaultDirtyCell() {
  return {
    rowId: '1',
    field: 'col1',
    oldValue: 5,
    newValue: 10,
    unTouchedValueParams: { id: 5, value: 5 },
    isSubstitute: false
  }
}

function getDefaultParams() {
  return {
    node: { group: false, id: '1' },
    colDef: { field: 'col1' },
    data: {
      [CUSTOM_ROW_INFO]: {
        ...DEFAULT_CUSTOM_ROW_INFO,
        [DIRTY_CELLS]: [getDefaultDirtyCell()]
      }
    }
  }
}

describe('AgGrid:Helpers:Cell:getCellClassRules', () => {
  let params = getDefaultParams()

  beforeEach(() => {
    params = getDefaultParams()
  })

  it('should return an object with a property named cellClassRules', () => {
    const result = getCellClassRules()
    expect(result).toHaveProperty('cellClassRules')
  })
  it('should return an object that has `rule-classes` as string and Function as a value pair', () => {
    const result = getCellClassRules()
    expect(result.cellClassRules).toHaveProperty(cellEditedClasses)
    expect(typeof result.cellClassRules[cellEditedClasses]).toBe('function')
  })
  it('should return true if current cell field and id exist in dirtyCells', () => {
    const result = getCellClassRules()
    expect(result.cellClassRules[cellEditedClasses](params as any)).toBe(true)
  })
  it('should return false if current cell field and id does not exist in dirtyCells', () => {
    const result = getCellClassRules()
    params.node.id = '9'
    expect(result.cellClassRules[cellEditedClasses](params as any)).toBe(false)
  })
  it('should return false if current cell is a group', () => {
    const result = getCellClassRules()
    params.node.group = true
    expect(result.cellClassRules[cellEditedClasses](params as any)).toBe(false)
  })
})
