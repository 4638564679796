import { useState, useEffect } from 'react'
import CodeMirror from 'react-codemirror'
import merge from 'lodash/merge'
import { ISlvyCodeMirrorProps, ITheme } from './SlvyCodeMirror.types'
import { defaultOptions } from './defaultOptions.tsx'
import { themes } from './themes.tsx'
import { getDefaultTheme } from './utils.tsx'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/elegant.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/theme/paraiso-dark.css'
import 'codemirror/theme/solarized.css'
import 'codemirror/addon/hint/show-hint.css'

import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/sql-hint'
import 'codemirror/addon/hint/javascript-hint'
import 'codemirror/mode/sql/sql'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/htmlmixed/htmlmixed'

import SlvyFormSelect from '../SlvyFormSelect'
import './SlvyCodeMirror.scss'

const SlvyCodeMirror = ({
  className,
  value,
  onChange,
  onFocusChange = () => {},
  options = defaultOptions,
  isThemeSelectable
}: ISlvyCodeMirrorProps) => {
  const [theme, setTheme] = useState<ITheme>(() => getDefaultTheme())
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    const savedOptions: null | { theme: ITheme } = JSON.parse(
      localStorage.getItem('SlvyCodeMirror')!
    )
    setTheme(savedOptions?.theme ?? getDefaultTheme())
    return () => {
      document.documentElement.classList.remove('slvyCodeMirrorFullScreen')
    }
  }, [])

  useEffect(() => {
    document.documentElement.classList[isFullScreen ? 'add' : 'remove']('slvyCodeMirrorFullScreen')
  }, [isFullScreen])

  const changeScreen = () => {
    setIsFullScreen((prevState) => !prevState)
    return false
  }

  const getDefaultOptions = () => {
    return {
      ...defaultOptions,
      theme
    }
  }

  const handleChange = (newTheme: ITheme) => {
    setTheme(newTheme)
    localStorage.setItem('SlvyCodeMirror', JSON.stringify({ theme: newTheme }))
  }

  const mergedOptions = merge(getDefaultOptions(), options)

  return (
    <div className={`slvyCodeMirror ${isFullScreen}`} data-testid="slvy-code-mirror">
      {isThemeSelectable ? (
        <SlvyFormSelect
          columns={{ label: 12, select: 12 }}
          data={themes}
          label="Theme"
          value={theme}
          onSelect={handleChange}
        />
      ) : null}
      <CodeMirror
        className={className}
        options={mergedOptions}
        value={value}
        onChange={onChange}
        onFocusChange={onFocusChange}
      />
      <button
        className="btn-full-screen"
        data-testid="slvy-code-mirror-full-screen-btn"
        type="button"
        onClick={changeScreen}
      >
        <i className="fa fa-expand" />
      </button>
    </div>
  )
}

export default SlvyCodeMirror
