import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap'

const FormHeading = () => {
  return (
    <FormGroup as={Row} className="mb-2" data-testid="form-heading">
      <Col md={6}>
        <FormLabel className="mb-0">
          <span className="fs-sm fw-bold">Variables</span>
        </FormLabel>
      </Col>
      <Col md={6}>
        <FormLabel className="mb-0">
          <span className="fs-sm fw-bold">Page Variables</span>
        </FormLabel>
      </Col>
    </FormGroup>
  )
}

export default FormHeading
