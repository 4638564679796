export default `({
    plugins: [{
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
                beforeedit: function (editor, context) {
                    rec = context.record;
                    return rec.get('IsEditable');
                },
                edit: function (editor, context, eOpts) {
                    if (context.originalValue == context.value)
                        return;
                    var record = context.record,
                        grid = context.grid.ownerCt
                    //    grid.setLoading(tt('yükleniyor'));
                    record.save({
                        params: {
                            updateAction: 'DCValueChange'
                        },
                        failure: function (record, operation) {},
                        success: function (record, operation) {
                            grid.setLoading(false)
                            //  grid.store.load()
                        },
                        callback: function (record, operation, success) {
                            // do something whether the save succeeded or failed
                        }
                    });
                },
                scope: 'this'
            }
        },
        {
            ptype: 'rowexpander',
            rowBodyTpl: [
                '<div class="expander">',
                '<table class="table">',
                '<tr>',
                '</tr>',
                '  <tr>',
                '       <th class=r>'+tt('Merkez')+'</th>',
                '      <td class="r">{OrderRecommendationParent}</td>',
                '       <th class="r">'+tt('Bölge')+'</th>',
                '      <td class="r">{OrderRecommendationCurrent}</td>',
                '      <th class="r">Satis Md.</th>',
                '      <td class="r">{OrderRecommendationChild}</td>',
                '   </tr>',
                // '  <tr>',
                // '       <th class=r>NumberOf Org</th>',
                // '      <td class="r">{NumberOfRecommendedOrg}</td>',
                // '     <th class="r">Waiting Org</th>',
                // '      <td class="r">{NumberOfWaitingOrg}</td>',
                // '       <th class="r"> Delegated</th>',
                // '      <td class="r">{NumberOfDelegatedOrg}</td>',
                // '   </tr>',
                // '</tr>',
                // '  <tr>',
                // '      <th class="r"> Approved </td>',
                // '      <td class="r">{NumberOfApprovedOrg}</td>',
                // '      <th class="r">Dismissed</td>',
                // '      <td class="r" >{NumberOfDismissedOrg}</td>',
                // '   </tr>',
                '</table>',
                '</div>',
            ]
        },
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    multiColumnSort: true,
    columnLines: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('Spot Başlangıç Tarihi'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('Spot Bitiş Tarihi'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            hiden: true,
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        // {
        //     text: tt('SalesPrice'),
        //     dataIndex: 'SalesPrice',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: 'Material',
        //     columns: [{
        //             text: 'Code',
        //             dataIndex: 'MaterialCode',
        //             width: 100,
        //             //   locked: true,
        //             renderer: function (v) {
        //                 return v * 1;
        //             }
        //         },
        //         {
        //             text: 'Description',
        //             dataIndex: 'MaterialDescription',
        //             width: 200,
        //             //  locked: true,
        //         }
        //     ]
        // },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },
        // {
        //     text: tt('UnitofMeasurement'),
        //     dataIndex: 'UnitofMeasurement',
        //     width: 168
        // },
        // {
        //     text: tt('UnitsPerPackage'),
        //     dataIndex: 'UnitsPerPackage',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('NumberOfRecommendedOrg'),
        //     dataIndex: 'NumberOfRecommendedOrg',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('NumberOfWaitingOrg'),
        //     dataIndex: 'NumberOfWaitingOrg',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('NumberOfDelegatedOrg'),
        //     dataIndex: 'NumberOfDelegatedOrg',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('NumberOfApprovedOrg'),
        //     dataIndex: 'NumberOfApprovedOrg',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
        // {
        //     text: tt('OriginalRecommendation'),
        //     dataIndex: 'OriginalRecommendation',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn',
        //     // locked: true,
        // },
        {
            text: tt('UpdatedRecommendation'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 150,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn',
            //    locked: true,
            tdCls: 'blue',
            // editor: {
            //     field: {
            //         xtype: 'numberfield',
            //         allowBlank: false
            //     }
            // },
        },
        // {
        //     text: tt('ApprovedRecommendation'),
        //     dataIndex: 'ApprovedRecommendation',
        //     width: 100,
        //     format: '000.00',
        //     align: 'right',
        //     xtype: 'numbercolumn'
        // },
    ],
})
`
