export const multipleTexts = [
  'Multiple Values',
  'Multiple Values under Single Branch',
  'Multiple Values under Main Branch'
]

export const rootValue = '000~0~0~0'
export const filteredRootValue = '100~0~0~0'

export const selectAllNode = {
  value: rootValue,
  icon: '',
  search: '',
  label: 'Select All',
  children: []
}
