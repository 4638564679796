import { Button, Modal } from 'react-bootstrap'
import cx from 'classnames'
import {
  BaseModalProps,
  BaseModalHeaderProps,
  BaseModalTitleProps,
  BaseModalBodyProps,
  BaseModalFooterProps,
  BaseModalButtonProps
} from './BaseModal.types'
import styles from './BaseModal.module.scss'

function BaseModal({
  children,
  className,
  centered = true,
  isOpen,
  onClose,
  ...rest
}: BaseModalProps) {
  return (
    <Modal
      centered={centered}
      className={cx(styles.baseModal, 'rounded-0', className)}
      show={isOpen}
      onHide={onClose}
      {...rest}
    >
      {children}
    </Modal>
  )
}

function Header({ children, closeButton = true, className, ...rest }: BaseModalHeaderProps) {
  return (
    <Modal.Header
      className={cx(styles.baseModalHeader, 'py-2 px-3 bg-dark text-white rounded-0', className)}
      closeButton={closeButton}
      {...rest}
    >
      {children}
    </Modal.Header>
  )
}

function Title({ children, className, ...rest }: BaseModalTitleProps) {
  return (
    <Modal.Title className={cx('fs-6 fw-light', className)} {...rest}>
      {children}
    </Modal.Title>
  )
}

function Body({ children, className, ...rest }: BaseModalBodyProps) {
  return (
    <Modal.Body className={cx('bg-light border-0', className)} {...rest}>
      {children}
    </Modal.Body>
  )
}

function Footer({ children, className, ...rest }: BaseModalFooterProps) {
  return (
    <Modal.Footer
      className={cx('py-1 px-2 rounded-0 bg-secondary bg-opacity-25', className)}
      {...rest}
    >
      {children}
    </Modal.Footer>
  )
}

function ConfirmButton({
  children = 'Confirm',
  className,
  variant = 'primary',
  ...rest
}: BaseModalButtonProps) {
  return (
    <Button
      className={cx(
        'fs-6 py-1 px-2 border border-secondary border-opacity-25 rounded-0',
        className
      )}
      variant={variant}
      {...rest}
    >
      {children}
    </Button>
  )
}

function CancelButton({
  children = 'Cancel',
  className,
  variant = 'secondary',
  ...rest
}: BaseModalButtonProps) {
  return (
    <Button
      className={cx(
        'fs-6 py-1 px-2 border border-secondary border-opacity-25 rounded-0',
        className
      )}
      variant={variant}
      {...rest}
    >
      {children}
    </Button>
  )
}

BaseModal.Header = Header
BaseModal.Title = Title
BaseModal.Body = Body
BaseModal.Footer = Footer
BaseModal.ConfirmButton = ConfirmButton
BaseModal.CancelButton = CancelButton

export default BaseModal
