Ext.define('MDM.src.view.Grouping.Main', {
  extend: 'Ext.panel.Panel',
  // Uncomment to give this component an xtype
  xtype: 'groupingmain',
  controller: 'grouping-maincontroller',
  viewModel: 'grouping-mainviewmodel',
  bodyBorder: false,
  bodyPadding: 5,
  margin: 2,
  layout: 'border',
  title: 'Flexible Grouping',
  ui: 'panel-inverse',
  defaults: {
    collapsible: true,
    bodyPadding: 0
  },
  items: [
    {
      title: 'Groups',
      floatable: false,
      margin: '5 0 0 0',
      collapsible: false,
      reference: 'cushie',
      region: 'center',
      ui: 'panel-inverse',
      xtype: 'grouping-groupgrid',
      bind: {
        store: '{CusHieStore}',
        isReadOnly: '{isReadOnly}'
      },
      tools: [
        {
          type: 'plus',
          tooltip: 'Add new group',
          bind: {
            visible: '{!isReadOnly}'
          },
          handler: 'onAddNew'
        }
      ]
    },
    {
      title: 'List Of DPI Families',
      collapsible: true,
      ui: 'panel-inverse',
      region: 'east',
      split: true,
      width: 400,
      minWidth: 400,
      maxWidth: 800,
      margin: '5 0 0 0',
      xtype: 'grouping-dpifamily',
      reference: 'famgrid',
      bind: {
        store: '{CusHieRelStore}',
        title: 'DPI Families of ' + '<span style="color:yellow">' + '{descriptions}' + '</span>'
      }
    }
  ]
})
