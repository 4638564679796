import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type SidebarState = {
  isOpen: boolean
  selectedTab: 'load' | 'demand'
  isHidden: boolean
  isResizing: boolean
  resizedWidth: number | undefined
}

const initialState: SidebarState = {
  isOpen: true,
  selectedTab: 'load',
  isHidden: false,
  isResizing: false,
  resizedWidth: undefined
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleIsOpen(state) {
      state.isOpen = !state.isOpen
    },
    setIsHidden(state, action: PayloadAction<SidebarState>) {
      state.isHidden = action.payload.isHidden
    },
    setIsResizing(state, action: PayloadAction<SidebarState>) {
      state.isResizing = action.payload.isResizing
    },
    setWidth(state, action: PayloadAction<SidebarState>) {
      state.resizedWidth = action.payload.resizedWidth
    },
    setSelectedTab(state, action: PayloadAction<SidebarState['selectedTab']>) {
      state.selectedTab = action.payload
    }
  }
})

export default sidebarSlice
export const { toggleIsOpen, setIsHidden, setIsResizing, setWidth } = sidebarSlice.actions
