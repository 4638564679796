Ext.define('tui.ex.uxFeatureSummary', {
  extend: 'Ext.grid.feature.Summary',
  alias: 'feature.uxsummary',
  afterHeaderCtLayout: function (headerCt) {
    var me = this,
      view = me.view,
      columns = view.getVisibleColumnManager().getColumns(),
      column,
      len = columns.length,
      i,
      summaryEl,
      el,
      width,
      innerCt

    if (me.showSummaryRow && view.refreshCounter) {
      if (me.dock) {
        summaryEl = me.summaryBar.el
        width = headerCt.getTableWidth()
        innerCt = me.summaryBar.innerCt
        // if summary is docked item getAll available columns.
        columns = view.getColumnManager().getColumns()
        len = columns.length
        // Stretch the innerCt of the summary bar upon headerCt layout
        me.summaryBar.item.setWidth(width - 1)

        // headerCt's tooNarrow flag is set by its layout if the columns overflow.
        // Must not measure+set in after layout phase, this is a write phase.
        if (headerCt.tooNarrow) {
          width += Ext.getScrollbarSize().width
        }
        innerCt.setWidth(width - 1)
      } else {
        summaryEl = Ext.fly(Ext.fly(view.getNodeContainer()).down('.' + me.summaryItemCls, true))
      }
      // If the layout was in response to a clearView, there'll be no summary element
      if (summaryEl) {
        for (i = 0; i < len; i++) {
          column = columns[i]
          el = summaryEl.down(view.getCellSelector(column), true)
          var colWidth = column.getWidth(),
            display = column.hidden ? 'none' : 'table-cell'
          if (el) {
            Ext.fly(el).setWidth(
              colWidth || column.width || (column.lastBox ? column.lastBox.width : 100)
            )
          } else if (me.dock) {
            // if docked summary and cell does not exists in docked summaryBar then create new cell for that column
            var row = me.summaryBar.item.dom.rows[0]
            if (row) {
              row.insertCell(i)
              var newCell = row.cells[i]
              if (newCell) {
                // apply cell class and columnId attributes for newly added cells.
                var columnId = column.id
                newCell.setAttribute(
                  'class',
                  'x-grid-cell x-grid-td x-grid-cell-' + columnId + ' x-unselectable'
                )
                newCell.setAttribute('data-columnid', columnId)
                el = summaryEl.down(view.getCellSelector(column), true)
                if (el) {
                  // set the column width to cell.
                  Ext.fly(el).setWidth(
                    colWidth || column.width || (column.lastBox ? column.lastBox.width : 100)
                  )
                }
              }
            }
          }
          if (me.dock && el) {
            // show/hide summary cells based on column status.
            el.style.display = display
          }
        }
      }
    }
  }
})
