import React from 'react'
import createPlugin from '../../BasePlugin'
import App from './app'
import { store } from './store'
import './index.scss'
import { Provider } from 'react-redux'

export function AssortmentPlanning(props) {
  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  )
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  size: props.size,
  isPreviewMode: props.isPreviewMode,
  id: props.id,
  params: props.params,
  token: props.token,
  settings: props.settings,
  onReady: props.onReady
})

export default createPlugin(AssortmentPlanning, selectConnectorProps, true)
