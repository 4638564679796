import { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'
import { DataUpdatedEventRef, DataUpdatedProps } from './useRegisterEventDataUpdated.types'

function useRegisterEventDataUpdated({ registerEvent }: DataUpdatedProps) {
  const dataUpdatedEventRef = useRef<DataUpdatedEventRef>({
    handleDataUpdated: (): never => ({} as never)
  })

  useEffect(() => {
    dataUpdatedEventRef.current.handleDataUpdated = registerEvent({
      key: 'DataUpdated',
      fn: () => ({ refreshKey: uuidv4() }),
      returnTypes: { refreshKey: PluginTypes.string }
    })
  }, [registerEvent])

  return { dataUpdatedEventRef }
}

export default useRegisterEventDataUpdated
