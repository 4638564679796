import {
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
  Navbar,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { ISlvyTreeNavbarProps } from './SlvyTreeNavbar.types'

const SlvyTreeNavbar = (props: ISlvyTreeNavbarProps) => {
  const {
    title,
    icon,
    height = 'inherit',
    searchProps: {
      query,
      resultCount,
      placeholder = 'Search',
      onSelectPrevMatch,
      onSelectNextMatch,
      onQueryChange
    },
    onCollapse,
    onExpand,
    children
  } = props
  return (
    <Navbar
      className="border-bottom bg-card-header rounded-0"
      data-testid="slvy-tree-navbar"
      style={{ height }}
    >
      <Container fluid className="px-2">
        <Navbar.Brand as="span" className="p-0 ps-1">
          <i className={`text-white fa fa-${icon}`} />
          <span className="ps-1 text-white">{title}</span>
        </Navbar.Brand>
        <Navbar.Collapse>
          {children}
          <OverlayTrigger
            delay={100}
            overlay={<Tooltip id="tooltip-overlay">Expand All</Tooltip>}
            placement="top"
          >
            <Button
              aria-label="expand all button"
              className="me-2 cp"
              size="sm"
              variant="light"
              onClick={onExpand}
            >
              <i className="fa fa-arrow-down" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            delay={100}
            overlay={<Tooltip id="tooltip-overlay">Collapse All</Tooltip>}
            placement="top"
          >
            <Button
              aria-label="collapse all button"
              className="cp"
              size="sm"
              variant="light"
              onClick={onCollapse}
            >
              <i className="fa fa-arrow-up" />
            </Button>
          </OverlayTrigger>
          <Form
            aria-label="tree search form"
            className="d-flex ms-2"
            onSubmit={(event: any) => event.preventDefault()}
          >
            <InputGroup>
              <FormControl
                placeholder={placeholder}
                size="sm"
                type="search"
                value={query}
                onChange={(event: any) => onQueryChange(event.target.value)}
              />
              <OverlayTrigger
                delay={100}
                overlay={
                  <Tooltip id="tooltip-overlay">
                    {resultCount < 2 ? 'No result' : 'Select Prev Match'}
                  </Tooltip>
                }
                placement="top"
              >
                <Button
                  aria-label="select prev button"
                  disabled={resultCount < 2}
                  size="sm"
                  type="button"
                  variant="dark"
                  onClick={onSelectPrevMatch}
                >
                  {' '}
                  &lt;
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                delay={100}
                overlay={
                  <Tooltip id="tooltip-overlay">
                    {resultCount < 2 ? 'No result' : 'Select Next Match'}
                  </Tooltip>
                }
                placement="top"
              >
                <Button
                  aria-label="select next button"
                  disabled={resultCount < 2}
                  size="sm"
                  type="submit"
                  variant="dark"
                  onClick={onSelectNextMatch}
                >
                  {' '}
                  &gt;
                </Button>
              </OverlayTrigger>
            </InputGroup>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default SlvyTreeNavbar
