Ext.define('clustering.ClusterGrid', {
  extend: 'Ext.pivot.Grid',
  alias: 'widget.clusterGrid',
  cls: 'multiline-header-grid',
  requires: ['Ext.pivot.plugin.Exporter'],
  enableLocking: true,
  allowDeselect: true,
  config: {
    store: null,
    clusterStore: null,
    xDimension: null,
    yDimension: null,
    zDimension: null
  },
  bind: {
    store: '{pivotClusterStore}',
    clusterStore: '{clusterStore}',
    xDimension: '{xDimension}',
    yDimension: '{yDimension}',
    zDimension: '{zDimension}'
  },
  plugins: [
    {
      ptype: 'pivotexporter',
      pluginId: 'exporter'
    }
  ],
  setClusterStore: function (store) {
    var me = this
    if (store) {
      store.on('datachanged', function (store) {
        var pivotStore = me.getStore()
        pivotStore.loadData(store.getDatas())
      })

      store.on('update', function (store) {
        var pivotStore = me.getStore()
        pivotStore.loadData(store.getDatas())
      })
    }

    return store
  },
  listeners: {
    pivotdone: function () {
      var me = this

      if (me.store) {
        var setColumnHeaders = function () {
          me.setColumnHeaders()
        }
        setColumnHeaders()
      }
    },
    rowclick: function (container, b) {
      var segmentId = null
      var viewModel = this.ownerCt.lookupReferenceHolder().getViewModel()

      if (container.grid.getSelectionModel().selected.items.length > 0) {
        var uniqueId = container.ownerGrid.getStore().uniqueid
        var segmentStoreName = uniqueId + 'Segments'
        var segmentStore = Ext.StoreManager.lookup(segmentStoreName)

        var segmentName = container.ownerGrid.getLeftAxisItem(b).value

        var segment = segmentStore.findRecord('Name', segmentName)
        segmentId = segment.get('Id')
      }

      viewModel.set('currentSegment', segmentId)
    }
  },
  setColumnHeaders: function () {
    var me = this

    if (!me.getXDimension() || !me.getYDimension() || !me.getZDimension()) {
      return
    }

    var columns = me.headerCt.getGridColumns()

    for (var index = 0; index < columns.length; index++) {
      var column = columns[index]

      var columnName = column.config.dimension.header
      switch (column.config.dimension.dataIndex) {
        case 'X':
          columnName = column.config.dimension.templateText.replace('{X}', me.getXDimension())
          break
        case 'Y':
          columnName = column.config.dimension.templateText.replace('{Y}', me.getYDimension())
          break
        case 'Z':
          columnName = column.config.dimension.templateText.replace('{Z}', me.getZDimension())
          break

        default:
          break
      }

      if (columnName.indexOf('Undefined') > -1 || columnName === 'ClusterID') {
        column.hide()
      } else {
        column.show()
        if (
          me.matrix.columns[1].columns[index - 1] &&
          column.text == me.matrix.columns[1].columns[index - 1].text
        )
          me.matrix.columns[1].columns[index - 1].text = columnName

        column.setText(columnName)
      }
    }

    me.refreshView()
  },
  rowGrandTotalsPosition: 'none',
  aggregate: [
    {
      dataIndex: 'ClusterID',
      header: 'Store Count',
      aggregator: 'count',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'IsTest',
      header: 'Test Store Count',
      renderer: Ext.util.Format.numberRenderer('0'),
      aggregator: function (records) {
        var count = 0
        records.forEach(function (item) {
          if (item.data.IsTest) count++
        })

        return count
      }
    },
    {
      dataIndex: 'SquareMeter',
      header: 'Square Meter Avg',
      aggregator: 'avg',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'SquareMeter',
      header: 'Square Meter Min',
      aggregator: function (records, measure, matrix) {
        var length = records.length,
          min = null,
          i,
          item,
          compare
        for (i = 0; i < length; i++) {
          item = records[i].get(measure)
          compare = true

          if (matrix.calculateAsExcel) {
            if (item !== null) {
              if (typeof item !== 'number') {
                item = 0
                compare = false
              }

              if (min === null) {
                min = item
              }
            } else {
              compare = false
            }
          }

          if (compare && item < min) {
            min = item
          } else if (item && min == null) {
            min = item
          }
        }

        return min
      },
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'SquareMeter',
      header: 'Square Meter Max',
      aggregator: 'max',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'SquareMeter',
      header: 'Square Meter Sum',
      aggregator: 'sum',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalRevenue',
      header: 'Total Revenue Avg',
      aggregator: 'avg',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalRevenue',
      header: 'Total Revenue Min',
      aggregator: function (records, measure, matrix) {
        var length = records.length,
          min = null,
          i,
          item,
          compare
        for (i = 0; i < length; i++) {
          item = records[i].get(measure)
          compare = true

          if (matrix.calculateAsExcel) {
            if (item !== null) {
              if (typeof item !== 'number') {
                item = 0
                compare = false
              }

              if (min === null) {
                min = item
              }
            } else {
              compare = false
            }
          }

          if (compare && item < min) {
            min = item
          } else if (item && min == null) {
            min = item
          }
        }

        return min
      },
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalRevenue',
      header: 'Total Revenue Max',
      aggregator: 'max',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalRevenue',
      header: 'Total Revenue Sum',
      aggregator: 'sum',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalSales',
      header: 'Unit Sales Avg',
      aggregator: 'avg',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalSales',
      header: 'Unit Sales Min',
      aggregator: function (records, measure, matrix) {
        var length = records.length,
          min = null,
          i,
          item,
          compare
        for (i = 0; i < length; i++) {
          item = records[i].get(measure)
          compare = true

          if (matrix.calculateAsExcel) {
            if (item !== null) {
              if (typeof item !== 'number') {
                item = 0
                compare = false
              }

              if (min === null) {
                min = item
              }
            } else {
              compare = false
            }
          }

          if (compare && item < min) {
            min = item
          } else if (item && min == null) {
            min = item
          }
        }

        return min
      },
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalSales',
      header: 'Unit Sales Max',
      aggregator: 'max',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'TotalSales',
      header: 'Unit Sales Sum',
      aggregator: 'sum',
      renderer: Ext.util.Format.numberRenderer('0,000')
    },
    {
      dataIndex: 'X',
      header: 'X Avg',
      templateText: '{X} Avg',
      aggregator: 'avg',
      renderer: function (value, meta) {
        meta.style = 'background-color: #fff6f5;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'X',
      header: 'X Min',
      templateText: '{X} Min',
      aggregator: function (records, measure, matrix) {
        var length = records.length,
          min = null,
          i,
          item,
          compare
        for (i = 0; i < length; i++) {
          item = records[i].get(measure)
          compare = true

          if (matrix.calculateAsExcel) {
            if (item !== null) {
              if (typeof item !== 'number') {
                item = 0
                compare = false
              }

              if (min === null) {
                min = item
              }
            } else {
              compare = false
            }
          }

          if (compare && item < min) {
            min = item
          } else if (item && min == null) {
            min = item
          }
        }

        return min
      },
      renderer: function (value, meta) {
        meta.style = 'background-color: #fff6f5;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'X',
      header: 'X Max',
      templateText: '{X} Max',
      aggregator: 'max',
      renderer: function (value, meta) {
        meta.style = 'background-color: #fff6f5;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'X',
      header: 'X Sum',
      templateText: '{X} Sum',
      aggregator: 'sum',
      renderer: function (value, meta) {
        meta.style = 'background-color: #fff6f5;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Y',
      header: 'Y Avg',
      templateText: '{Y} Avg',
      aggregator: 'avg',
      renderer: function (value, meta) {
        meta.style = 'background-color: #f7ffe8;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Y',
      header: 'Y Min',
      templateText: '{Y} Min',
      aggregator: function (records, measure, matrix) {
        var length = records.length,
          min = null,
          i,
          item,
          compare
        for (i = 0; i < length; i++) {
          item = records[i].get(measure)
          compare = true

          if (matrix.calculateAsExcel) {
            if (item !== null) {
              if (typeof item !== 'number') {
                item = 0
                compare = false
              }

              if (min === null) {
                min = item
              }
            } else {
              compare = false
            }
          }

          if (compare && item < min) {
            min = item
          } else if (item && min == null) {
            min = item
          }
        }

        return min
      },
      renderer: function (value, meta) {
        meta.style = 'background-color: #f7ffe8;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Y',
      header: 'Y Max',
      templateText: '{Y} Max',
      aggregator: 'max',
      renderer: function (value, meta) {
        meta.style = 'background-color: #f7ffe8;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Y',
      header: 'Y Sum',
      templateText: '{Y} Sum',
      aggregator: 'sum',
      renderer: function (value, meta) {
        meta.style = 'background-color: #f7ffe8;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Z',
      header: 'Z Avg',
      templateText: '{Z} Avg',
      aggregator: 'avg',
      renderer: function (value, meta) {
        meta.style = 'background-color: #e9fcff;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Z',
      header: 'Z Min',
      templateText: '{Z} Min',
      aggregator: function (records, measure, matrix) {
        var length = records.length,
          min = null,
          i,
          item,
          compare
        for (i = 0; i < length; i++) {
          item = records[i].get(measure)
          compare = true

          if (matrix.calculateAsExcel) {
            if (item !== null) {
              if (typeof item !== 'number') {
                item = 0
                compare = false
              }

              if (min === null) {
                min = item
              }
            } else {
              compare = false
            }
          }

          if (compare && item < min) {
            min = item
          } else if (item && min == null) {
            min = item
          }
        }

        return min
      },
      renderer: function (value, meta) {
        meta.style = 'background-color: #e9fcff;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Z',
      header: 'Z Max',
      templateText: '{Z} Max',
      aggregator: 'max',
      renderer: function (value, meta) {
        meta.style = 'background-color: #e9fcff;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'Z',
      header: 'Z Sum',
      templateText: '{Z} Sum',
      aggregator: 'sum',
      renderer: function (value, meta) {
        meta.style = 'background-color: #e9fcff;'

        return Ext.util.Format.numberRenderer('0,000')(value)
      }
    },
    {
      dataIndex: 'ClusterID',
      header: 'ClusterID',
      aggregator: 'max',
      renderer: Ext.util.Format.numberRenderer('0,000')
    }
  ],
  leftAxis: [
    {
      dataIndex: 'SegmentName',
      header: 'Segment',
      width: 100,
      locked: true,
      sortIndex: 'SortId'
    }
  ],
  doExport: function () {
    var view = this.up('clusteringmainview'),
      viewModel = view.getViewModel(),
      currentConfigurationData = viewModel.get('currentConfigurationData')
    var filters = {}
    currentConfigurationData.Scopes.forEach(function (scope) {
      var levelName = ''
      scope.Levels.forEach(function (level) {
        var objValue = ''
        levelName = level.Name
        level.Values.forEach(function (value) {
          if (level.SelectedIndices) {
            for (let index = 0; index < level.SelectedIndices.length; index++) {
              if (value.Key == level.SelectedIndices[index]) {
                objValue += ', ' + value.Value
              }
            }
          }
        })
        filters[levelName] = objValue.substr(2)
      })
    })

    this.saveDocumentAs(
      {
        title: 'Segment Export',
        fileName: 'Segments.xls'
      },
      filters
    )
  }
})
