Ext.define('MDM.src.view.calendar.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.calendar-mainviewmodel',
  stores: {
    calStore: {
      type: 'calendar-calendars',
      autoLoad: true,
      autoSync: true,
      listeners: {
        updated: 'onWriteEvent'
      },
      eventStoreDefaults: {
        type: 'calendar-events',
        autoLoad: true,
        autoSync: true,
        listeners: {
          write: function (store) {
            var calendarStore = store.calendar.store
            calendarStore.fireEvent('updated', calendarStore)
          }
        },
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          api: {
            create: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/create',
            read: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/read',
            update: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/update',
            destroy: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/destroy'
          }
        }
      },
      proxy: {
        type: 'ajax',
        method: 'POST',
        url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Read',
        reader: {
          type: 'json'
        }
      }
    }
  },
  data: {}
})
