import { SlvySpinner } from '@/components'
import style from './progress.module.scss'

// Do not show loading when its self-managed plugin
const LOADING_SELF_MANAGED_PLUGINS = ['AgGrid', 'SenchaGrid', 'AgPivot']

const Progress = (props) => {
  const { isLoading, children, type } = props
  // Size can change depends on plugin size/type

  const displayLoading =
    isLoading && LOADING_SELF_MANAGED_PLUGINS.every((pluginType) => pluginType !== type)

  return (
    <div className={`${style.progressContainer} position-relative w-100 h-100`}>
      {displayLoading ? <SlvySpinner containerClass="bg-opacity-10 bg-dark" size="xs" /> : null}
      {children}
    </div>
  )
}

export default Progress
