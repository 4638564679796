import React from 'react'
import { connect } from 'react-redux'
import SubMenuItem from './SubMenuItem'
import { toggleGrandTotalPin } from '../../../../../actions'

class MenuItem extends React.Component {
  constructor() {
    super()
    this.state = {
      show: false,
      hoveredIndex: null
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleSubItemMouseEnter = this.handleSubItemMouseEnter.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleOnClick(filterName, isResourceBased, operator, value, format, isChange) {
    const { onClick } = this.props

    onClick(filterName, isResourceBased, operator, value, format, isChange)
  }

  handleMouseEnter() {
    const { onMouseEnter, i } = this.props
    onMouseEnter(i)
  }

  handleMouseLeave() {
    this.setState({ show: false })
  }

  handleSubItemMouseEnter(i) {
    this.setState({ hoveredIndex: i })
  }

  render() {
    const {
      clickedSubItemIndex,
      filterValues,
      hoveredIndex,
      i,
      issueIndex,
      name,
      periodIndex,
      tableName,
      isResourceBased,
      connectApi,
      issueList,
      format,
      weeklySort,
      isOverflow,
      onToggleGrandTotalPin
    } = this.props

    const subMenuStyle = isOverflow ? { right: '100%', left: 'unset' } : {}

    return (
      <li
        className={`menu-item ${i === hoveredIndex ? 'menu-item--is-hovered' : ''}`}
        title={name}
        onMouseEnter={this.handleMouseEnter}
        onClick={onToggleGrandTotalPin}
      >
        <span className="menu-item__name">{name}</span>
        {periodIndex === filterValues.periodIndex && tableName === filterValues.tableName && (
          <i className="fa-fw fa slvy-ui-icon-options active-filter-indicator" />
        )}
        {periodIndex === weeklySort.periodIndex && tableName === weeklySort.issueName && (
          <i
            className={`
              active-sort-indicator
              ${
                weeklySort.isAscending
                  ? 'fa-fw fa slvy-ui-icon-sort-ascending'
                  : 'fa-fw fa slvy-ui-icon-sort-descending'
              }`}
          />
        )}
        {i === hoveredIndex && !name.includes('Grand Total') && (
          <ul
            className="menu-item__submenu-wrapper"
            style={subMenuStyle}
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <SubMenuItem
              clickedSubItemIndex={clickedSubItemIndex}
              connectApi={connectApi}
              format={format}
              issueIndex={issueIndex}
              isResourceBased={isResourceBased}
              issueList={issueList}
              periodIndex={periodIndex}
              tableName={tableName}
              onClick={this.handleOnClick}
              onMouseEnter={this.handleSubItemMouseEnter}
            />
          </ul>
        )}
      </li>
    )
  }
}

export default connect(null, { onToggleGrandTotalPin: toggleGrandTotalPin })(MenuItem)
