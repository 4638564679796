import { useRef } from 'react'
import _ from 'lodash'
import { Responsive, WidthProvider } from 'react-grid-layout'
import withConnector from '@/BaseContainer'
import { useEvent } from '@/hooks'
import { View } from '..'
import { Permission } from '@/components'
import AddElement from '../components/AddElement'
import './style.scss'

const ResponsiveReactGridLayout = WidthProvider(Responsive)
const breakpoints = { lg: 1, md: 0, sm: 0, xs: 0, xxs: 0 }
const margin = [5, 5]
const cols = {
  lg: 12,
  md: 10,
  sm: 6,
  xs: 4,
  xxs: 2
}

const getFilteredNlchildren = (_nlchildren, _plugins, isHidden) => {
  if (_nlchildren.length !== _plugins.length) {
    _plugins = _plugins.filter((plugin) => {
      return _nlchildren.some((nlItem) => nlItem.i === plugin.id)
    })
  }

  return _nlchildren.filter((nlchildrenItem) => {
    const { i: _nhlchildrenId = null } = nlchildrenItem

    const currentPlugin = _plugins.filter((plugin) => plugin.id === _nhlchildrenId)

    if (currentPlugin.length) {
      const { config: { general: { hidden: _isHidden = false } = {} } = {} } =
        currentPlugin[0] || {}

      return _isHidden === isHidden
    }

    return true
  })
}

const Container = (props) => {
  const {
    onSave,
    onMounted,
    showAddPluginLayout,
    containers,
    plugins,
    first = false,
    disableSelection,
    isSelected,
    params,
    params: { environment, designMode },
    data: {
      version: dataVersion,
      id: containerId,
      settings,
      settings: {
        layout,
        version = 1,
        fit = true,
        background,
        withContainerPadding,
        Background = null,
        useCSSTransforms = true,
        WithContainerPadding = true
      } = {}
    } = {}
  } = props

  const countRef = useRef(0)

  const isConfiguration = environment === 'Configuration'

  const saveLayout = (_layout) => {
    onSave(
      {
        ...settings,
        version: 2,
        layout: _.uniqWith(_layout, ({ i: xi = 0 }, { i: yi = 0 }) => _.isEqual(xi, yi)),
        children: _.map(_layout, (l) => l.i)
      },
      _.map(_layout, (l) => l.i)
    )
  }

  const uniqLayoutItems = (_layout, isAll) => {
    if (isAll === false) {
      const modifiedLayout = _.filter(_layout, (l) => {
        const container = _.find(containers, (c) => c.id === l.i)
        const isPopupContainer = container && container.type === 'popupcontainer'
        if (!isPopupContainer) {
          return l
        }
      })

      return _.uniqWith(modifiedLayout, ({ i: xi = 0 }, { i: yi = 0 }) => _.isEqual(xi, yi))
    }
    return _.uniqWith(_layout, ({ i: xi = 0 }, { i: yi = 0 }) => _.isEqual(xi, yi))
  }

  const getLayout = (isAll) => {
    if (version === 1) {
      const { settings: { layout: oldLayout } = {} } = _.find(
        containers,
        (c) => c.id === containerId
      )
      return uniqLayoutItems(oldLayout, (o) => o.i, isAll)
    }

    return uniqLayoutItems(layout, isAll)
  }

  const afterAddPlugin = (id, type) => {
    const newLayout = getLayout(true)
    saveLayout([...newLayout, { x: 0, y: 0, w: 5, h: 5, i: id, type }])
  }

  const handlePluginMounted = () => {
    countRef.current += 1
    const newLayout = getLayout(true)
    if (_.size(newLayout) === countRef.current && onMounted) {
      onMounted()
    }
  }

  // Functions are re-created on every render, so 'BasePlugin/Connector'
  // cannot access the last created reference of functions due to memoization.
  // The function reference is kept stable with "useEvent".
  const handlePluginRemove = useEvent((id) => {
    const newLayout = getLayout(true)
    const filteredNewLayout = _.filter(newLayout, (l) => l.i !== id)
    saveLayout(filteredNewLayout)
  })

  const handleAddPlugin = () => showAddPluginLayout(afterAddPlugin)

  const popupLayoutItems = () => {
    const popupLayout = _.filter(layout, (l) => {
      const container = _.find(containers, (c) => c.id === l.i)
      const isPopupContainer = container && container.type === 'popupcontainer'
      if (isPopupContainer) {
        return {
          l
        }
      }
    })
    const modifiedLayout = _.map(popupLayout, (l) => {
      if (isConfiguration) {
        return { ...l, static: true }
      }
      return { ...l, x: 0, y: 0, h: 0, w: 0 }
    })
    return _.uniqWith(modifiedLayout, ({ i: xi = 0 }, { i: yi = 0 }) => _.isEqual(xi, yi))
  }

  const handleResizeDragStop = (_layout, oldItem, newItem) => {
    const settingsLayout = getLayout(true)

    const newLayout = settingsLayout.map((settingsLayoutElem) => {
      const filteredNewLayout = _layout.filter(
        (layoutItem) => layoutItem.i === settingsLayoutElem.i
      )

      if (filteredNewLayout.length) {
        const [currentLayout] = filteredNewLayout
        const { x, y, h, w, i } = currentLayout
        const { _x, _y, _h, _w } = settingsLayoutElem

        if (x !== _x || y !== _y || h !== _h || w !== _w) {
          const changedLayout = { ...settingsLayoutElem, ...currentLayout }
          const { i: newItemI } = newItem
          if (newItemI !== i) {
            const neededKeys = ['h', 'i', 'x', 'y', 'w', 'moved', 'static', 'type']
            return _.pick(changedLayout, neededKeys)
          }
          return { ...settingsLayoutElem, ...currentLayout }
        }
      }

      return { ...settingsLayoutElem }
    })

    window.dispatchEvent(new Event('resize'))

    saveLayout(newLayout)
  }

  const finalLayout = getLayout(false)
  const popuplayout = popupLayoutItems()

  const color = background || Background
  const nlchildren = _.sortBy(finalLayout, (l) => l.y)
  const pop = _.sortBy(popuplayout, (l) => l.y)

  const styles = {
    color: {
      height: '100%',
      minHeight: '100%',
      background: _.isObject(color) ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : color
    }
  }

  let invisibleNlchildrenPlugins = []

  let NLCHILDREN = _.cloneDeep(nlchildren)
  NLCHILDREN.map((item, index) => {
    return {
      ...item,
      itemIndex: index
    }
  })

  const POP = _.cloneDeep(pop)
  POP.map((item, index) => {
    return {
      ...item,
      itemIndex: index
    }
  })

  if (!isConfiguration && plugins.length && nlchildren.length) {
    const _nlchildren = _.cloneDeep(NLCHILDREN)

    const _nlchildrenPlugin = _nlchildren.filter((item) => item.type === 'plugin')
    const _nlchildrenContainers = _nlchildren.filter((item) => item.type !== 'plugin')

    const _plugins = _.cloneDeep(plugins)

    invisibleNlchildrenPlugins = getFilteredNlchildren(_nlchildrenPlugin, _plugins, true)
    const visibleNlChildrenPlugins = getFilteredNlchildren(_nlchildrenPlugin, _plugins, false)

    const finalVisibleNlChildren = _nlchildrenContainers.length
      ? [...visibleNlChildrenPlugins, ..._nlchildrenContainers]
      : [...visibleNlChildrenPlugins]

    NLCHILDREN = _.sortBy([...finalVisibleNlChildren], (l) => l.itemIndex)
    invisibleNlchildrenPlugins = _.sortBy(invisibleNlchildrenPlugins, (l) => l.itemIndex)
  }

  if (fit && first && !isConfiguration) {
    const size = _.size(document.getElementsByClassName('slvy-js-nav-item'))
    const maxRowCount = Math.ceil((window.innerHeight - (size >= 1 ? 104 : 65)) / 20)
    const max = _.maxBy(NLCHILDREN, (c) => c.h + c.y)
    if (max && max.h > 2 && maxRowCount > max.y + max.h) {
      NLCHILDREN = _.map(NLCHILDREN, (c) => {
        if (c.h + c.y === max.h + max.y) {
          return {
            ...c,
            h: maxRowCount - c.y - 2
          }
        }
        return c
      })
    }
  }

  if (dataVersion === '2.0') {
    cols.lg = 24
  }

  const containerPadding = (
    withContainerPadding === undefined ? WithContainerPadding : withContainerPadding
  )
    ? [5, 5]
    : [5, 0]

  return (
    <div style={styles.color}>
      {(isSelected || (_.size(NLCHILDREN) === 0 && _.size(POP) === 0)) && designMode ? (
        <Permission has={['Plugin.Add']}>
          <AddElement
            containerClass="position-absolute top-0 start-0 end-0 bottom-0 p-2 bg-black add-element-btn"
            handleAddElement={handleAddPlugin}
            handleContainerClick={disableSelection}
          />
        </Permission>
      ) : null}
      <ResponsiveReactGridLayout
        breakpoints={breakpoints}
        className="layout"
        cols={cols}
        containerPadding={containerPadding}
        draggableHandle={`.move${containerId}`}
        isDraggable={isConfiguration}
        isResizable={isConfiguration}
        layouts={{ lg: NLCHILDREN }}
        margin={margin}
        rowHeight={15}
        useCSSTransforms={useCSSTransforms}
        verticalCompact={false}
        onDragStop={handleResizeDragStop}
        onResizeStop={handleResizeDragStop}
      >
        {_.map(NLCHILDREN, ({ i, type }) => {
          return (
            <div key={i}>
              <View
                containers={containers}
                draggableHandle={`move${containerId}`}
                id={i}
                params={params}
                plugins={plugins}
                type={type}
                onMounted={handlePluginMounted}
                onRemovePlugin={handlePluginRemove}
              />
            </div>
          )
        })}
      </ResponsiveReactGridLayout>

      {POP.length ? (
        <div className="position-relative d-flex w-100">
          <div className="d-flex align-items-end flex-wrap w-100 p-1 popup-layout">
            {_.map(POP, ({ i, type }) => {
              return (
                <div key={i + type}>
                  <View
                    containers={containers}
                    draggableHandle="move-popup"
                    id={i}
                    params={params}
                    plugins={plugins}
                    type={type}
                    onMounted={handlePluginMounted}
                    onRemovePlugin={handlePluginRemove}
                  />
                </div>
              )
            })}
          </div>
        </div>
      ) : null}

      {invisibleNlchildrenPlugins.length ? (
        <div className="invisible-layout hidden">
          {_.map(invisibleNlchildrenPlugins, ({ i, type }) => {
            return (
              <div key={i + type}>
                <View
                  containers={containers}
                  draggableHandle={`move${containerId}`}
                  id={i}
                  params={params}
                  plugins={plugins}
                  type={type}
                  onMounted={handlePluginMounted}
                  onRemovePlugin={handlePluginRemove}
                />
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default withConnector(Container)
