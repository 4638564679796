Ext.define('MDM.src.view.globalapp.model.ConfigModel', {
  extend: 'MDM.src.model.BaseAutoSync',
  entityName: 'gillettev3/Global_MDM_Config_Data',
  idProperty: 'ReportingPeriodID',
  fields: [
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'WizardPage1',
      type: 'int'
    },
    {
      name: 'WizardPage2',
      type: 'int'
    },
    {
      name: 'WizardPage3',
      type: 'int'
    },
    {
      name: 'WizardPage4',
      type: 'int'
    },
    {
      name: 'alive',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'BeginDate',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s',
      dateWriteFormat: 'Y-m-d\\TH:i:s'
    },
    {
      name: 'EndDate',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s',
      dateWriteFormat: 'Y-m-d\\TH:i:s'
    }
  ]
})
