import { useReduxState } from '../../hooks'
import { viewContainerSlice } from '../../store/slices'
import MapView from '../MapView'
import TableView from '../TableView'
import TreeTableView from '../TreeTableView'
import styles from './ViewContainer.module.scss'

export default function ViewContainer({ demandTreeTableRef, loadTreeTableRef }) {
  const [{ currentView }] = useReduxState(viewContainerSlice.selectSlice)

  return (
    <div className={styles.viewContainer}>
      {currentView === 'Map' ? (
        // TODO: Create a useGlobalRefs hook and avoid prop drilling for these refs
        <MapView demandTreeTableRef={demandTreeTableRef} loadTreeTableRef={loadTreeTableRef} />
      ) : null}
      {currentView === 'Table' ? <TableView /> : null}
      {currentView === 'Tree Table' ? (
        <TreeTableView
          demandTreeTableRef={demandTreeTableRef}
          loadTreeTableRef={loadTreeTableRef}
        />
      ) : null}
    </div>
  )
}
