import { getActionButtonClassNames } from '..'
import { GetActionButtonClassNamesProps } from '../column.types'

const getActionButtonClassNamesParams = (): GetActionButtonClassNamesProps => ({
  selectedIcon: 'fa-check',
  isDisabled: false,
  isClickable: false
})

describe('AgGrid:Helpers:Column:getActionButtonClassNames', () => {
  let params = getActionButtonClassNamesParams()

  beforeEach(() => {
    params = getActionButtonClassNamesParams()
  })

  it('should return "fa" when selectedIcon is empty and and isDisabled is false and isClickable is false', () => {
    params.selectedIcon = ''
    const result = getActionButtonClassNames(params)
    expect(result).toEqual('fa')
  })

  it('should return "fa fa-check cp" when isDisabled is false and isClickable is true', () => {
    params.isClickable = true
    const result = getActionButtonClassNames(params)
    expect(result).toEqual('fa fa-check cp')
  })

  it('should return "fa fa-check" when isDisabled is false and isClickable is false', () => {
    const result = getActionButtonClassNames(params)
    expect(result).toEqual('fa fa-check')
  })

  it('should return "fa fa-check opacity-25 cp" when isDisabled is true and isClickable is true', () => {
    params.isDisabled = true
    params.isClickable = true
    const result = getActionButtonClassNames(params)
    expect(result).toEqual('fa fa-check opacity-25 cp')
  })

  it('should return "fa fa-check opacity-25" when isDisabled is true and isClickable is false', () => {
    params.isDisabled = true
    const result = getActionButtonClassNames(params)
    expect(result).toEqual('fa fa-check opacity-25')
  })
})
