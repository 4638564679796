import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LoadStopTableState = {
  selectedRow: string | undefined
}

const initialState: LoadStopTableState = {
  selectedRow: undefined
}

const loadStopTableSlice = createSlice({
  name: 'loadStopTable',
  initialState,
  reducers: {
    setSelectedRow(state, action: PayloadAction<LoadStopTableState['selectedRow']>) {
      state.selectedRow = action.payload
    }
  }
})

export default loadStopTableSlice
export type { LoadStopTableState }
