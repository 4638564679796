export default `({
    border: true,
    xtype: 'panel',
    header: false,
    bodyStyle: {
        background: 'rgba(204, 204, 204, 0.1)',
        padding: '2px',
        overflow:'auto'
    },
    cfg: {
        spotRecord: {},
    },
    width: '100%',
    height: '100%',
    setSpotRecord: function (c) {
        if (c.data) {
                        this.getViewModel().set('spotData', c.data)
            this.spotrec = c
        }
    },
    defaultListenerScope: true,
    viewModel: {
        data: {
            spotData: {},
            sumData: {}
        }
    },
    activated: function () {
        var me = this
        me.setSpotRecord(me.spotrec)
        if (me.spotrec.data) {
            Ext.defer(function () {
                me.items.items[0].setData(me.spotrec.data)
                me.getViewModel().set('spotData', null)
                me.getViewModel().set('spotData', me.spotrec.data)
                me.items.items[1].setData(me.spotrec.data)
                me.items.items[3].setData(me.spotrec.data)
                //    me.items.items[0].setData(me.spotrec.data)
            }, 100);
        }
    },
    items: [{
            xtype: 'component',
            tpl: ['<div>',
                '<div style="text-align:center;line-height: 60px;vertical-align:middle;color: #066277;margin: 10px auto; width:70%">',
                '<h1 style="letter-spacing: 2px;color: #0a0a0a;text-shadow: 1px 1px #676464"> {[this.formatCode(values.MaterialCode)]}    {MaterialDescription} </h1>',
                '<h3 style="line-height: 30px;margin-top: 0;">  {MaterialGroupName} </h3>',
                '</div></div>',
                {
                    formatCode: function (value) {
                        return value * 1
                    }
                },
            ],
            bind: {
                data: '{spotData}'
            }
        },
        {
            xtype: 'component',
            tpl: ['<div>',
                '<div style="text-align:center; vertical-align:middle;color: #066277;margin: 20px auto;  width:70%">',
                '<table class="responstable">',
                '  <tr>',
                '    <th> '+tt('Start Date')+'</th>',
                '    <th>  '+tt('Bitis Tarihi')+'</th>',
                '    <th>' + tt("Sure") + '</th>',
                '    <th>' + tt("Toplam Sipariş Ad.") + '</th>',
                '    <th>' + tt("Satis Fiyati") + '</th>',
                '    <th>' + tt("Kolici Adet") + '</th>',
                '  </tr>',
                '   <tr>',
                '    <td>{[this.formatDate(values.PreStartDate)]}</td>',
                '    <td>{[this.formatDate(values.PreEndDate)]}</td>',
                '    <td>{[this.dateDiff(values)]}</td>',
                '    <td>{[this.formatInt(values.TotalPackageSales)]} </td>',
                '    <td>{[this.formatNumber(values.SalesPrice)]} </td>',
                '    <td>{UnitsPerPackage}</td>',
                '  </tr>',
                '</table>',
                '</div></div>',
                {
                    formatNumber: function (value) {
                        return Ext.util.Format.number(value, '0,000.00')
                    }
                },
                {
                    formatInt: function (value) {
                        return Ext.util.Format.number(value, '0,000')
                    }
                },
                {
                    formatDate: function (value) {
                        return Ext.Date.format(value, tt('d-m-Y'));
                    }
                },
                {
                    dateDiff: function (value) {
                        return Ext.Date.diff(value.PreStartDate, value.PreEndDate, 'd') + ' ' + tt('gun')
                    }
                }
            ],
            bind: {
                data: '{spotData}',
            }
        },
        {
            xtype: 'component',
            bind: {
                data: '{sumData}'
            },
            listeners: {
                click: {
                    element: 'el',
                    delegate: 'div.box',
                    fn: function (e, t) {
                        if (Ext.get(t).hasCls('box')) {
                            Ext.get(t).toggleCls('selected');
                        }
                    }
                }
            },
            tpl: '   <div class="acontainer">  ' +
                '       <div class="box first">  ' +
                '       <span class="icon-cont"><i class="fa ">1</i></span>  ' +
                '       <h3 style="color: #fec115;text-shadow: 2px 2px #756b6b;font-weight: 600;">'+t('₺') +' {Day1Revenue:number("0,000")}</h3>  ' +
                '       <ul class="spot-hidden">  ' +
                '         <li class="percent">{Day1Ratio:percent("0,00")}</li>  ' +
                '         <li>{Day1PackageSales:number("0,000")} '+ tt(' Koli')+'</li>  ' +
                '         <li>{Day1Sales:number("0,000")} '+ tt('Adet')+ '</li> ' +
                '       </ul>  ' +
                '       <a class="expand"><span class="plus">+</span><span class="minus">-</span></a>  ' +
                '     </div>  ' +
                '     <div class="box second">  ' +
                '       <span class="icon-cont"><i class="fa ">4</i></span>  ' +
                '       <h3 style="color: #fec115;text-shadow: 2px 2px #756b6b;font-weight: 600;">'+t('₺') +' {Day4Revenue:number("0,000")}</h3> ' +
                '       <ul class="spot-hidden">  ' +
                '         <li class="percent">{Day4Ratio:percent("0,00")}</li>  ' +
                '         <li>{Day4PackageSales:number("0,000")} '+ tt(' Koli')+'</li>  ' +
                '         <li>{Day4Sales:number("0,000")} '+ tt('Adet')+ '</li>  ' +
                '       </ul>  ' +
                '       <a class="expand"><span class="plus">+</span><span class="minus">-</span></a>  ' +
                '     </div>  ' +
                '     <div class="box third">  ' +
                '       <span class="icon-cont"><i class="fa ">7</i></span>  ' +
                '       <h3 style="color: #fec115;text-shadow: 2px 2px #756b6b;font-weight: 600;">'+t('₺') +' {Day7Revenue:number("0,000")}</h3> ' +
                '       <ul class="spot-hidden">  ' +
                '         <li class="percent">{Day7Ratio:percent("0,00")}</li>  ' +
                '         <li>{Day7PackageSales:number("0,000")} '+ tt(' Koli')+'</li>  ' +
                '         <li>{Day7Sales:number("0,000")} '+ tt('Adet')+ '</li>  ' +
                '       </ul>  ' +
                '       <a class="expand"><span class="plus">+</span><span class="minus">-</span></a>  ' +
                '     </div>  ' +
                '     <div class="box fourth">  ' +
                '       <span class="icon-cont"><i class="fa">35</i></span>  ' +
                '       <h3 style="color: #fec115;text-shadow: 2px 2px #756b6b;font-weight: 600;">'+t('₺') +' {TotalRevenue:number("0,000")}</h3> ' +
                '       <ul class="spot-hidden">  ' +
                '         <li class="percent">{TotalRatio:percent("0,00")}</li>  ' +
                '         <li>{TotalPackageSales:number("0,000")} '+ tt(' Koli')+'</li>  ' +
                '         <li>{TotalSales:number("0,000")} '+ tt('Adet')+ '</li>   ' +
                '       </ul>  ' +
                '       <a class="expand"><span class="plus">+</span><span class="minus">-</span></a>  ' +
                '     </div>  ' +
                '  </div>  '
        },
        {
            xtype: 'component',
            tpl: ['<div>',
                '<div class="wrapper-note">',
                '<h2 class="title">'+ tt('Campaign Notes')+'</h2>',
                '<div class="note-box idea">',
                '<div class="note-text" style="width:100% color:#000">{Notes}</div>',
                '</div>',
                '</div>',
            ],
            bind: {
                data: '{spotData}'
            }
        },
        {
            xtype: 'container',
            layout: {
                type: 'hbox',
                align: 'center' // or 'right'
                    ,
                pack: 'center' // controls vertical align
                    ,
                defaultMargins: 5
            },
            height: 100,
            items: [{
                    xtype: 'numberfield',
                    width: 300,
                    // id: 'DaysForApprove',
                    reference: 'DaysForApprove',
                    labelWidth: 160,
                    fieldLabel: tt('Onay Bekleme Süresi (Gün)'),
                    value: 7,
                    minValue: 0,
                    allowBlank: false, // requires a non-empty value
                    // bind: {
                    // //     value: '{spotRecord.DaysForApprove}'
                    // // },
                    margin: '0 50 0 0'
                },
                {
                    text: tt('Yayınla'),
                    xtype: 'button',
                    width: 100,
                    handler: 'onPuslishSpotConfirm',
                    margin: '0 20 0 0'
                },
                {
                    text: tt('Afişe Çık'),
                    iconCls: 'fa fa-newspaper-o',
                    xtype: 'button',
                    width: 100,
                    handler: 'onApproveSpotConfirm'
                },
            ]
        }
    ],
    onPuslishSpotConfirm: function () {
        var me = this;
        Ext.Msg.show({
            title: tt('Onay'),
            // height: 160,
            width: 500,
            msg: tt('Are you sure you want to publish this record?'),
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            buttons: Ext.Msg.YESNO,
            icon: Ext.MessageBox.QUESTION,
            fn: function (response) {
                if (response === 'yes') {
                    me.onPublishSpot()
                }
            }
        })
    },
    onPublishSpot: function () {
        var me = this.up('spui-view-tracking-navigation-main');
        var viewModel = this.getViewModel(),
            spotRecord = viewModel.getParent().get('spotRecord'),
            view = this.up('spui-view-wizard-wizardmain')
        var daysForApprove = view.lookupReference('DaysForApprove').value
        // var daysForApprove = Ext.getCmp('DaysForApprove').getValue()
        spotRecord.set('DaysForApprove', daysForApprove)
        this.setLoading(tt('Yükleniyor'));
        spotRecord.save({
            params: {
                actionType: 'publish',
            },
            callback: function (record, operation, success) {
                var result = operation._response.responseJson
                var mainView = view.up('main-view')
                var trackingNavigationMain = view.ownerCt.ownerCt.down('spui-view-tracking-navigation-main')
                if (result.success) {
                    Ext.Msg.alert('Status', tt('İşlem başarıyla tamamlandı.'));
                    view.fireEvent('gohome', view)
                    trackingNavigationMain.onRefrehTracking()
                } else {
                    Ext.Msg.alert('Status', 'İşleminiz tamamlanamadı. Hata Mesajı :' + tt(result.message));
                }
            }
        });
        this.setLoading(false)
    },
    onApproveSpotConfirm: function () {
        var me = this;
        Ext.Msg.show({
            title: tt('Onay'),
            // height: 160,
            width: 500,
            msg: tt('Are you sure you want to post this record?'),
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            buttons: Ext.Msg.YESNO,
            icon: Ext.MessageBox.QUESTION,
            fn: function (response) {
                if (response === 'yes') {
                    me.onApproveSpot()
                }
            }
        })
    },
    onApproveSpot: function () {
        var me = this.up('spui-view-tracking-navigation-main');
        var viewModel = this.getViewModel(),
            spotRecord = viewModel.getParent().get('spotRecord'),
            view = this.up('spui-view-wizard-wizardmain');
                var daysForApprove = view.lookupReference('DaysForApprove').value
        // var daysForApprove = Ext.getCmp('DaysForApprove').getValue()
        spotRecord.set('DaysForApprove', daysForApprove)
        this.setLoading(tt('yükleniyor'));
        spotRecord.save({
            params: {
                actionType: 'approve',
            },
            callback: function (record, operation, success) {
                var result = operation._response.responseJson
                var mainView = view.up('main-view')
                var trackingNavigationMain = view.ownerCt.ownerCt.down('spui-view-tracking-navigation-main')
                if (result.success) {
                    Ext.Msg.alert('Status', 'İşlem başarıyla tamamlandı.');
                    view.fireEvent('gohome', view)
                    trackingNavigationMain.onRefrehTracking()
                } else {
                    Ext.Msg.alert('Status', 'İşleminiz tamamlanamadı. Hata Mesajı :' + tt(result.message));
                    
                }
            }
        });
        this.setLoading(false)
    },
    listeners: {
        render: function () {
            ownerCt = this.ownerCt;
            ownerCt.on('activate',
                function () {
                    var me = this;
                    me.setLoading(tt('yükleniyor'));
                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + "spui/SpotSummary",
                        params: {
                            SpotId: me.spotrec.data.SpotId
                        },
                        success: function (response, opts) {
                            var obj = Ext.decode(response.responseText);
                            spotData = me.viewModel.get('spotData')
                            Ext.apply(spotData, {
                                TotalPackageSales: obj[0].TotalPackageSales
                            })
                            me.activated();
                            me.viewModel.set('sumData', obj[0])
                            Ext.Function.defer(function () {
                                me.viewModel.set('spotData', null)
                                me.getViewModel().set('spotData', spotData)
                                me.activated();
                            }, 50);
                            me.setLoading(false);
                        },
                        failure: function (response, opts) {
                            me.setLoading(false);
                            console.log('server-side failure with status code ' + response.status);
                        }
                    });
                }, this)
        },
        beforeshow: function () {}
    },
})
`
