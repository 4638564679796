export default () => ({
  title: 'React DataGrid',
  type: 'object',
  format: 'objectTab',
  properties: {
    general: {
      type: 'object',
      title: 'General',
      properties: {
        name: {
          type: 'string',
          title: 'Name'
        },
        header: {
          type: 'string',
          title: 'Header'
        },
        maximizable: {
          type: 'boolean',
          title: 'Maximizable',
          default: true,
          required: true
        },
        export: {
          type: 'boolean',
          title: 'Exportable',
          default: true,
          required: true
        },
        exportAllColumns: {
          type: 'boolean',
          title: 'Export All Columns',
          default: false,
          required: true
        }
      }
    },
    settings: {
      type: 'object',
      title: 'Settings',
      properties: {
        text: {
          type: 'string',
          title: 'Label Text'
        },
        backgroundColor: {
          type: 'string',
          format: 'Color',
          title: 'Background Color'
        },
        textSelection: {
          type: 'boolean',
          default: true,
          required: true,
          title: 'Text Selection'
        },
        filtering: {
          type: 'boolean',
          default: true,
          required: true,
          title: 'Filtering'
        },
        noDataMessage: {
          type: 'string',
          default: 'No Data to Show',
          required: false,
          title: 'No Data Message'
        }
      }
    },
    actionButtons: {
      type: 'array',
      title: 'Action Buttons',
      items: {
        type: 'object',
        title: 'Button',
        headerTemplate: '{{self.name}}',
        properties: {
          isEnabled: {
            type: 'boolean',
            title: 'Enabled',
            default: true
          },
          name: {
            type: 'string',
            title: 'Name',
            default: '',
            required: true
          },
          returnParams: {
            type: 'array',
            title: 'Parameters',
            items: {
              type: 'object',
              title: 'Param',
              headerTemplate: '{{self.name}}',
              properties: {
                name: {
                  type: 'string',
                  title: 'Name',
                  required: true
                },
                type: {
                  type: 'string',
                  enum: ['boolean', 'datetime', 'dynamic', 'int', 'string'],
                  default: 'string',
                  required: true
                }
              }
            }
          }
        }
      }
    },
    events: {
      type: 'object',
      title: 'Events',
      properties: {
        rowClick: {
          type: 'object',
          title: 'Row Click',
          properties: {
            returnParams: {
              type: 'array',
              title: 'Parameters',
              items: {
                type: 'object',
                title: 'Param',
                headerTemplate: '{{self.name}}',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                    required: true
                  },
                  type: {
                    type: 'string',
                    enum: ['boolean', 'datetime', 'dynamic', 'int', 'string'],
                    default: 'string',
                    required: true
                  }
                }
              }
            }
          }
        },
        cellEdit: {
          type: 'object',
          title: 'Cell Edit',
          properties: {
            returnParams: {
              type: 'array',
              title: 'Parameters',
              items: {
                type: 'object',
                title: 'Param',
                headerTemplate: '{{self.name}}',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                    required: true
                  },
                  type: {
                    type: 'string',
                    enum: ['boolean', 'datetime', 'dynamic', 'int', 'string'],
                    default: 'string',
                    required: true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
})
