Ext.define('MDM.src.view.BomEdit.panels.Search', {
  extend: 'Ext.grid.Panel',
  xtype: 'bomedit-panels-search',
  dockedItems: [
    {
      xtype: 'toolbar',
      docked: 'top',
      items: [
        {
          xtype: 'textfield',
          name: 'field1',
          width: 400,
          flex: 1,
          emptyText: 'Search Material',
          listeners: {
            change: 'onSearch'
          }
        }
      ]
    }
  ],
  emptyText: 'norecord',
  viewConfig: {
    plugins: [
      {
        ptype: 'gridviewdragdrop',
        containerScroll: true,
        dragGroup: 'dd-grid-to-grid-group2',
        dropGroup: 'dd-grid-to-grid-group1',
        copy: true,
        // The right hand drop zone gets special styling
        // when dragging over it.
        dropZone: {
          overClass: 'dd-over-gridview'
        }
      }
    ],
    listeners: {
      //drop: 'onDropGrid2'
    }
  },
  columns: [
    {
      text: 'Material Code',
      dataIndex: 'MaterialCode',
      flex: 1,
      sortable: true
    },
    {
      text: 'Material Description',
      dataIndex: 'MaterialDescription',
      flex: 2,
      sortable: true
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'fas fa-plus-circle',
          tooltip: 'Delete DPI',
          handler: 'addToBom'
        }
      ]
    }
  ]
})
