Ext.define('Spui.util.Mask', {
  extend: 'Ext.Base',
  singleton: true,
  splashscreen: null,
  defaultMsg: 'Uygulama yukleniyor',
  plusMsg: '... , Lutfen bekleyiniz ...',
  mask: function (msg) {
    msg = msg || this.defaultMsg
    msg = msg + this.plusMsg
  },
  unMask: function () {
    Ext.getBody().unmask(false)
  }
})
