import React from 'react'
import { Modal as ModalBS } from 'react-bootstrap'
import PantoneForm from './PantoneForm'
import { MESSAGES } from '../messages'

const Modal = ({
  show,
  sectionNames,
  pluginId,
  designId,
  itemToEdit,
  modalShowColorDetails,
  modalShowColorSearch,
  isMainColor,
  selectedMainColorId,
  columnHeightValue,
  materialTypeID,
  onEdit = () => {},
  onClose = () => {},
  closeModal = () => {},
  afterSubmit = () => {},
  handleAfterColorAdd = () => {},
  handleAfterColorUpdate = () => {},
  getDesignOptionsAttributes = () => {},
  client = () => {}
}) => {
  return (
    <ModalBS
      className="color-palettes-plugin"
      enforceFocus={false}
      show={show}
      size="xl"
      onHide={closeModal}
    >
      <ModalBS.Header closeButton>
        <ModalBS.Title>{MESSAGES.color}</ModalBS.Title>
      </ModalBS.Header>
      <ModalBS.Body>
        <div className="c-form-ctn py-2 px-0">
          <PantoneForm
            afterSubmit={afterSubmit}
            client={client}
            columnHeightValue={columnHeightValue}
            designId={designId}
            getDesignOptionsAttributes={getDesignOptionsAttributes}
            handleAfterColorAdd={handleAfterColorAdd}
            handleAfterColorUpdate={handleAfterColorUpdate}
            isMainColor={isMainColor}
            itemToEdit={itemToEdit}
            materialTypeID={materialTypeID}
            modalShowColorDetails={modalShowColorDetails}
            modalShowColorSearch={modalShowColorSearch}
            pluginId={pluginId}
            sectionNames={sectionNames}
            selectedMainColorId={selectedMainColorId}
            onClose={onClose}
            onEdit={onEdit}
          />
        </div>
      </ModalBS.Body>
    </ModalBS>
  )
}

export default Modal
