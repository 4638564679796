import React, { Component } from 'react'
import { FormGroup, Col, FormControl, Row, Form } from 'react-bootstrap'
import _ from 'lodash'
import cx from 'classnames'

class Textbox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      value: _.isEmpty(props.item.value) ? '' : props.item.value
    }
  }

  handleChange(e) {
    const { item } = this.props
    this.props.onChange(item.sectionKey, item.key, e.target.value)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: _.isEmpty(nextProps.item.value) ? '' : nextProps.item.value
    })
  }

  render() {
    const { item, sectionLeftColumn, sectionRightColumn, backgroundColor } = this.props
    const { value } = this.state
    const lightColor = backgroundColor.replace(/[^,]+(?=\))/, '0.3')
    const darkColor = backgroundColor.replace(/[^,]+(?=\))/, '0.1')
    const validationState = this.props.getValidationState(item, value)
    return (
      <Row>
        <Col
          className="data-row-left"
          md={_.toNumber(sectionLeftColumn)}
          sm={_.toNumber(sectionLeftColumn)}
          style={{
            backgroundColor: lightColor
          }}
        >
          <h3>{item.title}</h3>
        </Col>
        <Col
          className="data-row-right"
          md={_.toNumber(sectionRightColumn)}
          sm={_.toNumber(sectionRightColumn)}
          style={{
            backgroundColor: darkColor
          }}
        >
          <FormGroup
            className="mb-3 form-group"
            // validationState={validationState === 'changed' ? null : validationState}
          >
            <div
              className={cx(validationState, {
                'is-invalid': validationState === 'error'
              })}
            >
              <FormControl
                className={cx({ 'is-invalid': validationState === 'error' })}
                disabled={item.isReadonly ? 'disabled' : false}
                placeholder="write something"
                size="sm"
                type="text"
                value={value}
                onChange={this.handleChange}
              />
            </div>
            <Form.Control.Feedback type="invalid">{item.validationMessage}</Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>
    )
  }
}
export default Textbox
