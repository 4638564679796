export default () => {
  return {
    title: 'Gauge',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: false,
            required: true
          },
          export: {
            type: 'boolean',
            title: 'Exportable',
            default: false,
            required: true
          }
        }
      },
      chart: {
        type: 'object',
        title: 'Chart',
        properties: {
          title: {
            type: 'string',
            title: 'Title'
          },
          titleColor: {
            type: 'string',
            format: 'Color',
            title: 'Title Color',
            default: '#666'
          },
          subTitle: {
            type: 'string',
            title: 'Subtitle'
          },
          subTitleColor: {
            type: 'string',
            format: 'Color',
            title: 'Subtitle Color',
            default: '#666'
          },
          backgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#FFFFFF'
          },
          outerBackgroundColor: {
            type: 'string',
            format: 'Color',
            title: 'Outer Background Color',
            default: '#FFFFFF'
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          seriesName: {
            type: 'string',
            title: 'Series Name',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          minValue: {
            type: 'string',
            title: 'Min Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          maxValue: {
            type: 'string',
            title: 'Max Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          color: {
            type: 'array',
            title: 'Color',
            items: {
              type: 'object',
              title: 'Color',
              properties: {
                from: {
                  type: 'string',
                  title: 'Start',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds'
                },
                to: {
                  type: 'string',
                  title: 'End',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds'
                },
                color: {
                  type: 'string',
                  format: 'Color',
                  title: 'Background Color',
                  default: '#FFFFFF'
                }
              }
            }
          }
        }
      },
      rangeAxis: {
        type: 'object',
        title: 'Range Axis',
        properties: {
          tickColor: {
            type: 'string',
            format: 'Color',
            title: 'Tick Color',
            default: '#666'
          },
          minorTickColor: {
            type: 'string',
            format: 'Color',
            title: 'Minor Tick Color',
            default: '#666'
          },
          minorTickWidth: {
            title: 'Minor Tick Width',
            type: 'integer',
            maximum: 2,
            minimum: 0,
            default: 1
          },
          dialColor: {
            type: 'string',
            title: 'Dial Color',
            format: 'Color',
            default: '#000000'
          }
        }
      },
      color: {
        type: 'array',
        title: 'Color',
        items: {
          type: 'object',
          title: 'Color',
          properties: {
            from: {
              type: 'integer',
              title: 'Start'
            },
            to: {
              type: 'integer',
              title: 'End'
            },
            color: {
              type: 'string',
              format: 'Color',
              title: 'Background Color',
              default: '#FFFFFF'
            }
          }
        }
      }
    }
  }
}
