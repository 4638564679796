Ext.define('Spui.view.common.similarity.tabpanel.Mixin', {
  extend: 'Ext.Mixin',
  // Uncomment to give this component an xtype
  config: {
    salesChartPanel: {},
    similarSpotId: null,
    nomalizedChart: {},
    selectedChartPanel: {}
  },
  forceToLoadStore: true,
  applySelectedChartPanel: function (b) {
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        title: tt('Spot Sales chart'),
        xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
        salesChartCfg: 'SpotSelected',
        salesChartStoreCfg: 'SpotSelectedStore',
        salesChartStore: {},
        salesChart: {},

        salesGridCfg: 'SelectedSpotSalesGrid',
        salesGrid: false,
        bind: {
          SpotId: '{spotRecord.SpotId}'
        }
      })
      b = Ext.create(b)
    }
    return b
  },
  updateSelectedChartPanel: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  applySalesChartPanel: function (b) {
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        title: tt('Spot Sales chartwww'),
        xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
        bind: {
          SpotId: '{spotRecord.SpotId}'
        }
      })

      b = Ext.create(b)
    }
    return b
  },
  updateSalesChartPanel: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  applyNomalizedChart: function (b) {
    if (b && !b.isComponent) {
      Ext.applyIf(b, {
        title: tt('Normalized Chart'),
        xtype: 'spui-view-common-similarity-tabpanel-nomalizedchart-main',
        bind: {
          SpotId: '{spotRecord.SpotId}'
        }
      })

      b = Ext.create(b)
    }
    return b
  },
  updateNomalizedChart: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  listeners: {},
  applySimilarSpotId: function (c) {
    this.getSalesChartPanel().setSimilarSpotId(c)
  }
})
