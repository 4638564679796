Ext.define('Spui.view.common.productinfo.cmp.tabpanel.SalesChart.SalesChart', {
  extend: 'Chart.ux.Highcharts',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-saleschart',
  border: true,
  margin: '10,10,10,10',
  defaultListenerScope: true,
  loadMaskMsg: 'Chart data is loading.....',
  bodyStyle: {
    background: '#ffc',
    border: '2px #ccc solid'
  },

  config: {
    record: null
  },

  initComponent: function () {
    this.callParent()
  },

  series: [
    {
      dataIndex: 'QTY',
      type: 'column',
      name: tt('sananse'),
      color: 'rgba(236,17,92,1)',
      cursor: 'pointer',
      listeners: {
        pointclick: function (serie, point, record, event) {
          var panel = serie.chart
          panel.fireEvent('clickChart', record)
        }
      }
    },
    {
      dataIndex: 'Envanter',
      type: 'spline',
      name: tt('Inventory'),
      cursor: 'pointer',
      listeners: {
        pointclick: function (serie, point, record, event) {
          var panel = serie.chart
          panel.fireEvent('clickChart', record)
        }
      }
    },
    {
      dataIndex: 'PriceNull',
      type: 'spline',
      lineWidth: 0,
      marker: {
        enabled: 'true',
        states: {
          hover: {
            enabled: true
          }
        },
        radius: 5
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      name: tt('Sales Price'),
      yAxis: 1,
      cursor: 'pointer',
      listeners: {
        pointclick: function (serie, point, record, event) {
          var panel = serie.chart
          panel.fireEvent('clickChart', record)
        }
      }
    },
    {
      dataIndex: 'NumberOfStoreNull',
      type: 'spline',
      lineWidth: 0,
      marker: {
        enabled: 'true',
        states: {
          hover: {
            enabled: true
          }
        },
        radius: 5
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      name: 'Number Of Store',
      yAxis: 2,
      cursor: 'pointer',
      listeners: {
        pointclick: function (serie, point, record, event) {
          var panel = serie.chart
          panel.fireEvent('clickChart', record)
        }
      }
    }
  ],

  chartConfig: {
    chart: {
      type: 'column',
      alignTicks: true,
      marginRight: 100,
      marginBottom: 140,
      zoomType: 'x',
      dataLabels: {
        enabled: true
      }
    },

    tooltip: {
      formatter: function () {
        var value = this.point.data
        var dateKey = value.DateKey
        var year = dateKey.substring(0, 4)
        var month = dateKey.substring(4, 6)
        var day = dateKey.substring(6, 8)
        var date = year + '-' + month + '-' + day
        var format = date + '<br/>'
        if (this.series.name == tt('Sales Price')) {
          format +=
            '<span style="color:' +
            this.point.color +
            '">\u25CF</span> ' +
            this.series.name +
            ': <b> ' +
            this.y.toFixed(2) +
            '</b><br/>'
        } else {
          format +=
            '<span style="color:' +
            this.point.color +
            '">\u25CF</span> ' +
            this.series.name +
            ': <b> ' +
            this.y.toFixed(2) +
            '</b><br/>'
        }
        return format
      }
    },

    credits: {
      text: 'SOLVOYO © 2021',
      href: 'http://www.solvoyo.com',
      position: {
        align: 'right',
        y: -5 // position of credits
      },
      style: {
        fontSize: '10pt' // you can style it!
      }
    },

    plotOptions: {
      column: {
        stacking: 'normal'
      },
      series: {
        point: {
          events: {
            drop: function () {}
          }
        },
        stickyTracking: false
      }
    },

    navigation: {
      buttonOptions: {
        align: 'left'
      }
    },

    title: {
      text: tt('Sales Chart'),
      style: {
        fontSize: '14px',
        fontWeight: 'bold'
      }
    },

    xAxis: {
      type: 'datetime',

      labels: {
        formatter: function () {
          var me = this
          var value = me.value
          if (value.name) {
            value = value.name
          }
          if (value && typeof value === 'string') {
            var year = value.substring(0, 4)
            var month = value.substring(4, 6)
            var day = value.substring(6, 8)
            return year + '-' + month + '-' + day
          }
        },
        style: {
          fontSize: '13px'
        },
        rotation: 315,
        y: 35
      }
    },
    yAxis: [
      {
        // Primary yAxis
        title: {
          text: tt('Number of Sales')
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        }
      },
      {
        // Primary yAxis
        opposite: true,
        title: {
          text: tt('Sales Price')
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        }
      },
      {
        // Primary yAxis
        opposite: true,
        title: {
          text: tt('Store')
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        }
      }
    ]
  },
  xField: 'DateKey',

  setIsMax: function (c) {
    var me = this
    if (c) {
      me.chartConfig.title.style.fontSize = '24px'
      me.chartConfig.xAxis.labels.style.fontSize = '16px'
      me.chartConfig.yAxis[0].labels.style.fontSize = '16px'
      me.chartConfig.legend = {
        itemStyle: {
          fontSize: '16px'
        }
      }
    } else {
      me.chartConfig.title.style.fontSize = '14px'
      me.chartConfig.xAxis.labels.style.fontSize = '13px'
      me.chartConfig.yAxis[0].labels.style.fontSize = '13px'
      me.chartConfig.legend = {
        itemStyle: {
          fontSize: '13px'
        }
      }
    }
    me.draw()
  },

  setStore: function (s) {
    this.bindStore(s)
  },

  afterChartRendered: function (d) {
    this.fireEvent('chartRender', this, d)
  },

  buildStore: function () {
    if (this.store) return this.store
    this.store = Ext.create('Ext.data.Store', {
      fields: [
        { name: 'QTY', type: 'number' },
        { name: 'Price', type: 'number' },
        { name: 'NumberOfStore', type: 'number' },
        { name: 'Envanter', type: 'number' },
        { name: 'DateKey', type: 'string' },
        {
          name: 'PriceNull',
          convert: function (value, record) {
            var v = record.get('Price')

            if (!v) return null
            return v
          },

          depends: ['Price']
        },
        {
          name: 'NumberOfStoreNull',
          convert: function (value, record) {
            var v = record.get('NumberOfStore')

            if (!v) return null
            return v
          },

          depends: ['NumberOfStore']
        }
      ],

      proxy: {
        type: 'ajax',
        timeout: 120000,
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        extraParams: {
          MaterialCode: this.MaterialCode
        },
        url: Spui.Global.getApiUrl() + 'spui/GetSalesData'
      },
      autoLoad: true
    })
    return this.store
  },

  buildDemo: function () {
    var me = this
    me.store = me.buildStore()

    Ext.apply(me.chartConfig, {})
  },

  onClickPieChart: function (serie, point, record, event) {
    var store = Ext.getStore('SpotModel'),
      win = Ext.create('Spui.view.spotanaliz.pricedetails.PriceDetails', {
        DateKey: record.get('DateKey'),
        MaterialCode: record.get('MaterialCode'),
        record: record,
        title:
          this.chart.MaterialCode +
          ' -' +
          record.get('MaterialDescription') +
          ' ' +
          record.get('DateKey') +
          ' ' +
          record.get('DayNameTR') +
          ' Gunu Satışları'
      })
    win.show()
    this.chart.fireEvent('clickChart', record, event)
  }
})
