import _ from 'lodash'
import { AnyAction } from 'redux'
import withMatcher from './helpers'
import { PluginPreviewData } from '@/containers/PluginSettings/PluginSettings.types'

interface Error {
  message: string
  datetime: string
  requestUri: string
  errorId: string
  errorType: string
  messageType: 'error'
}

interface Meta {
  method: 'get' | 'post'
  success: string
  failure: string
  model: string
  version: number
  path: string
  params: Record<string, never>
  data: Record<string, never>
  key: string
  getRowKey: (row?: object, index?: number) => string
  mapResult: (result: unknown, currentResult: never) => unknown
}

interface FetchDetails {
  type: string
  meta: Meta
  payload: Record<string, unknown>
}

interface CommonFetch {
  startFetch: number
  endFetch: number
  fetchTime: number
  error: Error | null
  needFetch: boolean
  isSuccess: boolean
  isFailed: boolean
  pending: boolean
  fetchKey: string
}

export interface FetchOne<Data = unknown> extends CommonFetch {
  data: Data
  fetch: FetchDetails
}

type ID = string | number
export interface FetchCollections extends CommonFetch {
  ids: Array<ID | Record<string, ID>>
}

interface State {
  [model: string]: {
    byId: Record<string, FetchOne>
    collections?: Record<string, FetchCollections>
  }
}

const LOCALDATA_UPDATEROW = 'LOCALDATA/UPDATEROW'
const LOCALDATA_DELETEROW = 'LOCALDATA/DELETEROW'
const LOCALDATA_ADDROWINDEX = 'LOCALDATA/ADDROWINDEX'
const LOCALDATA_CLEARCACHES = 'LOCALDATA/CLEARCACHES'

export const addRowIndex = withMatcher((id: string) => {
  return { type: LOCALDATA_ADDROWINDEX, id }
})

export const updateRow = withMatcher(
  (id: string, rowIndex: number, record: Record<string, any>) => {
    return { type: LOCALDATA_UPDATEROW, id, rowIndex, record }
  }
)

export const deleteRow = withMatcher((id: string, rowIndex: number) => {
  return { type: LOCALDATA_DELETEROW, id, rowIndex }
})

export const clearCaches = withMatcher((id: string) => {
  return { type: LOCALDATA_CLEARCACHES, id }
})

export const __RowIndex = '__SLVYRowIndex' as const

export default (state: State, action: AnyAction): State => {
  const { id: actionId, rowIndex = -1, record = {} } = action

  let newState: State

  if (addRowIndex.match(action)) {
    const {
      query: {
        byId: {
          [actionId]: { data: { result } = {} as PluginPreviewData, data } = {},
          [actionId]: item = {}
        } = {},
        byId = {}
      } = {},
      query = {}
    } = state
    newState = {
      ...state,
      query: {
        ...query,
        byId: {
          ...byId,
          [actionId]: {
            ...item,
            data: {
              ...data,
              result: {
                ...result
              }
            }
          }
        }
      }
    }

    // Add an additional field the data. It will be used by plugins to change rows of data
    _.forEach(newState.query.byId[actionId].data.result, (row, index) => {
      row[__RowIndex] = _.toNumber(index)
    })
    return newState
  }
  if (updateRow.match(action)) {
    newState = { ...state }

    if (rowIndex >= 0) {
      const pluginData = newState.query.byId[actionId].data.result

      // The record from plugins may contain fields which do not exits in the original data. (Due to extJS, Highcharts)
      // Delete non original fields.
      let safeRecord = _.transform(
        pluginData[rowIndex],
        (result, value, field) => {
          result[field] = record[field]
        },
        {}
      )

      // Add new row if the rowId does not exist in the data
      if (_.isEmpty(safeRecord)) {
        safeRecord = { ...record }
      }

      // Set the updated row to state
      pluginData[rowIndex] = safeRecord

      // Get plugin id
      const { data: { id: targetPluginId = '' } = {} } = JSON.parse(actionId)

      // Remove caches of the given plugin except from the given cache
      newState.query.byId = _.transform(
        newState.query.byId,
        (result, query, queryId) => {
          const { data: { id: pluginId = '' } = {} } = JSON.parse(queryId)
          if (pluginId !== targetPluginId || actionId === queryId) {
            result[queryId] = query
          }
        },
        {}
      )
    }

    return newState
  }
  if (deleteRow.match(action)) {
    newState = { ...state }

    if (rowIndex >= 0) {
      const pluginData = newState.query.byId[actionId].data.result

      // Remove the row by index
      delete pluginData[rowIndex]

      // Get plugin id
      const { data: { id: targetPluginId = '' } = {} } = JSON.parse(actionId)

      // Remove caches of the given plugin except from the given cache
      newState.query.byId = _.transform(
        newState.query.byId,
        (result, query, queryId) => {
          const { data: { id: pluginId = '' } = {} } = JSON.parse(queryId)
          if (pluginId !== targetPluginId || actionId === queryId) {
            result[queryId] = query
          }
        },
        {}
      )
    }

    return newState
  }
  if (clearCaches.match(action)) {
    newState = { query: { byId: {} }, ...state }

    // Get plugin id
    const { data: { id: targetPluginId = '' } = {} } = JSON.parse(actionId)

    // Remove caches of the given plugin
    newState.query.byId = _.transform(
      newState.query.byId,
      (result, query, queryId) => {
        const { data: { id: pluginId = '' } = {} } = JSON.parse(queryId)
        if (pluginId !== targetPluginId) {
          result[queryId] = query
        }
      },
      {}
    )

    return newState
  }
  return state
}
