Ext.define('MDM.src.view.materialedit.model.Cartridge', {
  extend: 'MDM.src.view.materialedit.model.Base',
  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'Coefficient',
      type: 'number'
    }
  ]
})
