import _ from 'lodash'

export const selectCollection = (crud, state, ready = true) => {
  if (!ready) return { needFetch: false }
  const {
    meta: { collectionKey, model }
  } = crud
  const { [model]: { byId, collections: { [collectionKey]: collection } = {} } = {} } = state

  if (collection === undefined) {
    return {
      needFetch: true,
      fetch: crud,
      isSuccess: false,
      pending: false,
      isFailed: false
    }
  }

  const { ids = [] } = collection

  const data = _.map(ids, (id) => byId[id].data)
  return {
    ...collection,
    fetch: crud,
    data
  }
}

export const select = (crud, state, ready = true) => {
  if (!ready) return { needFetch: false }
  const {
    meta: { key, model }
  } = crud

  if (!key) {
    throw new Error(model + crud.type, 'Selecting a record, but no ID was given')
  }

  const { [model]: { byId: { [key]: entity } = {} } = {} } = state

  if (entity === undefined) {
    return {
      needFetch: true,
      fetch: crud,
      isSuccess: false,
      pending: false,
      isFailed: false
    }
  }

  return {
    ...entity,
    fetch: crud
  }
}

export const selecOperationStatus = (model, operationKey, state) => {
  const { [model]: { operations: { [operationKey]: operationModel } = {} } = {} } = state

  if (operationModel) {
    return operationModel
  }
  return {
    pending: false,
    isSuccess: false,
    isFailed: false,
    data: null
  }
}

export const isSuccess = (props, nextProps) => {
  return !nextProps.pending && props.isSuccess !== nextProps.isSuccess && nextProps.isSuccess
}

export const isFailed = (props, nextProps) => {
  return (
    props.pending !== nextProps.pending &&
    !nextProps.pending &&
    !nextProps.isSuccess &&
    !_.isNil(nextProps.error)
  )
}

export const selectCustomerId = (state, catalogId) => {
  const { model3: { catalog: { byId: { [catalogId]: currentCatalog = {} } = {} } = {} } = {} } =
    state
  const { data: { customerId } = {} } = currentCatalog

  return customerId
}
