import { useEffect } from 'react'
import { Provider } from 'react-redux'
import createPlugin from '@/BasePlugin'
import { API_URL } from '@/constants'
import store from './store'
import { tuiSlice } from './store/slices'
import { selectPluginProps } from './utils'
import Tui from './Tui'
import { TuiContainerProps } from './Tui.types'
import styles from './Tui.module.scss'
import 'leaflet-third-party-libraries'
import 'leaflet/dist/leaflet.css'

function TuiContainer({ config, ...props }: TuiContainerProps) {
  const { tui } = store.getState()

  useEffect(init, [])

  function init() {
    const { oidc, setting } = (window as any).dashboardStore.getState()
    const { id } = props

    store.dispatch(
      tuiSlice.actions.initializeTui({
        auth: {
          accessToken: oidc.user.access_token,
          apiUrl: API_URL ?? '',
          environment: setting.environment,
          pluginId: id
        },
        config,
        ...props
      })
    )
  }

  return (
    <Provider store={store}>
      <div className={styles.tuiContainer}>
        {tui.isInitialized ? <Tui {...{ config, ...props }} /> : null}
      </div>
    </Provider>
  )
}

export default createPlugin(TuiContainer, selectPluginProps, true)
