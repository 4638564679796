import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import numeral from 'numeral'
import { v3 as uuidv3 } from 'uuid'
import cx from 'classnames'
import { confirmAlert } from 'react-confirm-alert'
import { slvyToast } from '../../../../components'
import { OverlayTrigger } from '../index'
import { getLockState, getApproveState, isEveryColumnInRange } from '../Util'
import { CURRENCIES_LOCALES } from '../../currencies-locales'
import GridBtnSection from './grid-btn-section'
import { MESSAGES } from '../../messages'
import GridCell from './grid-cell'

export default class Grid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: []
    }
    this.currenciesOptions = CURRENCIES_LOCALES[props.culture ? props.culture : 'en-US']
    this.onUpdated = this.onUpdated.bind(this)
  }

  componentDidMount() {
    const { stateHandler = () => {} } = this.props
    stateHandler({ isReverse: false })
  }

  componentDidUpdate() {
    const { updateMode = 0 } = this.props

    if (!_.isNil(this.elem)) {
      if (updateMode === 2) {
        this.elem.blur()
      } else {
        this.elem.focus()
      }
    }
  }

  rowClick(leftKeys, isLast, isActive) {
    const { onRowClick = () => {} } = this.props
    if (isActive) {
      leftKeys = [...leftKeys.slice(0, leftKeys.length - 1)]
    }
    if (!isLast) {
      onRowClick(leftKeys)
    }
  }

  rowClicked(LeftHierarchy, event) {
    event.stopPropagation()
    this.props.onRowClicked({ LeftHierarchy })
  }

  updateLocalStorage() {
    const { pluginId = '' } = this.props
    let styleStorage = localStorage.getItem(`dp-Style-${pluginId}`)
    styleStorage = styleStorage ? JSON.parse(styleStorage) : {}
    const style = { scrollTop: document.querySelector('.dp-main').scrollTop }
    const opts = JSON.stringify({ ...styleStorage, ...style })
    localStorage.setItem(`dp-Style-${pluginId}`, opts)
  }

  pivotClick(leftKeys, event) {
    event.stopPropagation()
    const { onPivotClick = () => {} } = this.props
    this.updateLocalStorage()
    onPivotClick(leftKeys)
  }

  cellClick(leftKeys, metricName, singleDetailMetricParams, event) {
    event.stopPropagation()
    const { onCellClick = () => {} } = this.props
    this.updateLocalStorage()
    onCellClick(leftKeys, metricName, singleDetailMetricParams)
  }

  isEveryColumnInRangeValid(event, params, newValue) {
    // CHECK VALIDITY
    event.classList.remove('alert')
    params.NewValue = newValue
    const updateDetails = 'UpdateDetails' in params ? params.UpdateDetails : [params]
    if (!isEveryColumnInRange(updateDetails, 'NewValue')) {
      slvyToast.warning({
        message: MESSAGES.please_enter_a_valid_value_between_min_and_max,
        options: { containerId: 'dp' }
      })
      event.classList.add('alert')
      return false
    }
    return true
  }

  updateCall(params, isPercent, isApproved, event) {
    const { target, target: { value = '' } = {}, keyCode } = event

    const { onUpdate, settings: { shouldShowConfirmApprovedCellUpdate = true } = {} } = this.props
    const { ExternalEdit = false } = params

    if ((onUpdate && keyCode === 13) || ExternalEdit) {
      const newValue = numeral(value).value()

      if (!ExternalEdit) {
        if (!this.isEveryColumnInRangeValid(target, params, newValue)) {
          return
        }
        params.NewValue = isPercent ? newValue / 100 : newValue
      }

      if (isApproved === 1) {
        if (shouldShowConfirmApprovedCellUpdate) {
          confirmAlert({
            title: MESSAGES.approve_confirmation,
            message: MESSAGES.are_you_sure_you_want_to_edit_the_already_approved_row,
            buttons: [
              {
                label: MESSAGES.cancel
              },
              {
                label: MESSAGES.confirm,
                onClick: () => this.onUpdated(params, target)
              }
            ]
          })
        } else {
          this.onUpdated(params, target)
        }
      } else {
        this.onUpdated(params, target)
      }
    }
  }

  onUpdated(params, target) {
    const { ExternalEdit = false } = params

    if (target && !ExternalEdit) {
      const parent = target.parentElement
      parent.classList.add('-mini-loading')
    }

    this.props.onUpdate(params)
    this.props.setSelectedRowId(0)
  }

  updateMultiple(params) {
    this.onUpdated(params)
  }

  actionCall(params, obj) {
    const { onAction = () => {} } = this.props
    onAction(params, obj)
  }

  onScenarioComparisonClick(params) {
    const { onScenarioComparisonClick = () => {} } = this.props
    onScenarioComparisonClick(params)
  }

  getIconMap(UpColor, DownColor) {
    return {
      equal: { style: { color: 'inherit' } },
      up: { style: { color: UpColor || '#8BC34A' } },
      down: { style: { color: DownColor || '#ff0000' } }
    }
  }

  getComparisonBtnTpl(comparisonParams) {
    return (
      <OverlayTrigger tooltip="Scenario Comparison">
        <i
          key={uuidv3(comparisonParams.toString(), uuidv3.DNS)}
          className="sc-btn slvy-ui-icon-merge-different"
          onClick={this.onScenarioComparisonClick.bind(this, comparisonParams)}
        />
      </OverlayTrigger>
    )
  }

  getCalculatedValue(isPercent, plannedValue, baselineValue) {
    return isPercent
      ? plannedValue - baselineValue
      : parseInt(baselineValue, 10) === 0
      ? 100
      : parseFloat(
          numeral(((plannedValue - baselineValue) / baselineValue) * 100)
            .format('0.0')
            .replace(',', '.'),
          10
        )
  }

  onCellDivClick($params, event) {
    const {
      ExternalEdit,
      IsApproved,
      IsLocked,
      LeftKeys,
      baselineValue,
      isEditable,
      isPercent,
      metricsField,
      plannedValue,
      uniqueID,
      valuesId
    } = $params

    const {
      updateMode = 0,
      viewMode = 0,
      selectedRowId: { cell = 0 } = {},
      setSelectedRowId = () => {}
    } = this.props

    if (IsLocked !== 1) {
      if (isEditable && updateMode === 2 && viewMode === 2 && ExternalEdit) {
        this.updateCall(
          {
            BaselineValue: baselineValue,
            ExternalEdit,
            Guid: uniqueID,
            LeftKeys,
            MetricField: metricsField.Name,
            OldValue: plannedValue
          },
          isPercent,
          IsApproved,
          event
        )
      } else {
        if (viewMode === 2) {
          setSelectedRowId(uniqueID, valuesId)
        }

        if (_.isEqual(uniqueID, cell)) {
          setSelectedRowId(0)
        }
      }
    }
  }

  getIconCondition(calculatedValue) {
    return calculatedValue >= 0 ? (calculatedValue === 0 ? 'equal' : 'up') : 'down'
  }

  render() {
    const {
      props: {
        isRowClickedEventOn,
        IsApproveAllowed,
        allowedToUpdate,
        buttonFormat,
        culture = '',
        data = [],
        isScenarioEditable,
        leftHierarchy = [],
        leftKeyLength = 0,
        metricsFields = [],
        pluginId,
        reqLeftFields = [],
        selectedRowId,
        setHierarchy,
        setSelectedRowId,
        settings = {},
        stateHandler,
        updateMode = 0,
        values = [],
        viewMode = 0,
        GetScenarioTree = () => {},
        onAction = () => {},
        onCellClick = () => {},
        onPivotClick = () => {},
        onRowClick = () => {},
        onRowClicked = () => {},
        onScenarioComparisonClick = () => {},
        onUpdate = () => {}
      },
      currenciesOptions: { decimalScale, thousandSeparator, decimalSeparator }
    } = this

    const { cell = 0, row = 0 } = selectedRowId
    const {
      detailViewForSingleColumn = true,
      isVisibleSCButton = true,
      lastLeftHierarchy,
      showAllDataInRows
    } = settings

    const showAllClass = showAllDataInRows || lastLeftHierarchy ? '-show-all' : ''

    return (
      <div className={`slvy-ui -grid-body ${showAllClass}`}>
        {_.map(data, (item, index) => {
          const {
            LeftKeys,
            Metrics,
            Children,
            IsApproved = 0,
            IsLocked = 0,
            MissingRow = false
          } = item
          const isActive = _.isEqual(leftHierarchy, LeftKeys) && leftHierarchy.length > 0
          const LeftKey = _.last(LeftKeys)
          const isLast = LeftKeys.length === leftKeyLength
          const isFirst = LeftKeys.length === 0
          const valuesId = uuidv3(`${LeftKey} ${Metrics.toString()} ${index}`, uuidv3.DNS)
          const LockKey = getLockState(IsLocked)
          const ApproveKey = getApproveState(IsApproved)

          const $metricsName = []
          Metrics.forEach((mItem, mIndex) => $metricsName.push(metricsFields[mIndex]))

          const _$metricsName = []
          Metrics.forEach((mItem, mIndex) => {
            const valuesDraggableIndex = values[mIndex] || {}
            const {
              DisplayName = '',
              Name = '',
              IsUpdateAllowed = false,
              Format: _Format = ''
            } = valuesDraggableIndex
            _$metricsName.push({ DisplayName, Name, IsUpdateAllowed, Format: _Format })
          })

          const activeGroupClass = isActive ? '-active-group' : ''
          const helperGroupClass = showAllDataInRows || lastLeftHierarchy ? '-helper-group' : ''
          const editableHoverClass =
            _.isEqual(row, valuesId) && viewMode === 2 ? '-editable-hover' : ''

          return (
            <div key={index} className={`${activeGroupClass} ${helperGroupClass}`}>
              <div
                key={index}
                className={cx(
                  'slvy-ui-row',
                  editableHoverClass,
                  { 'is-active': isActive },
                  { 'is-not-active': !isActive }
                )}
              >
                <div
                  className="-title"
                  onClick={this.rowClick.bind(this, LeftKeys, isLast, isActive)}
                >
                  <i />
                  <span>{LeftKey}</span>
                  {isRowClickedEventOn ? (
                    <>
                      <OverlayTrigger placement="right" tooltip="Row click">
                        <input
                          className="row-clicked-event-input"
                          name="grid-row"
                          type="radio"
                          onClick={this.rowClicked.bind(this, LeftKeys)}
                        />
                      </OverlayTrigger>
                      <span className="slvy-ui-icon-arrow-right-circle row-clicked-event-icon" />
                    </>
                  ) : null}
                </div>

                <div key={index} ref={`value${index}`} className="-values" data-id={valuesId}>
                  {_.map(Metrics, (subitem, subindex) => {
                    const [baselineValue, plannedValue] = subitem
                    const metricsField = metricsFields[subindex]
                    const isEditable =
                      isScenarioEditable && metricsField.IsUpdateAllowed && viewMode === 2
                    const valuesData = values[subindex] || {}
                    const uniqueID = uuidv3(
                      `${metricsField.DisplayName}
                        ${LeftKey}
                        ${index}
                        ${subindex}
                        ${JSON.stringify(LeftKeys)}`,
                      uuidv3.DNS
                    )

                    const {
                      Format,
                      ExternalEdit = false,
                      Arrow: { Visible = true, UpColor = '', DownColor = '' } = {},
                      Validation: {
                        MinAcceptableValue,
                        MaxAcceptableValue,
                        MinMaxCheckType = 'absolute'
                      } = {},
                      DisplayName: _displayName,
                      Name: _name,
                      IsUpdateAllowed: _isUpdateAllowed,
                      Format: _format = ''
                    } = valuesData

                    const iconMap = this.getIconMap(UpColor, DownColor)
                    const isPercent = !_.isNil(Format) ? Format.indexOf('%') > 0 : false

                    const calculatedValue = this.getCalculatedValue(
                      isPercent,
                      plannedValue,
                      baselineValue
                    )

                    const iconCondition = this.getIconCondition(calculatedValue)

                    const comparisonParams = {
                      LeftKeys,
                      Metrics: _$metricsName,
                      CurrentMetric: {
                        IsUpdateAllowed: _isUpdateAllowed,
                        Name: _name,
                        DisplayName: _displayName,
                        Format: _format
                      }
                    }

                    const singleDetailMetricParams = { ...comparisonParams }

                    const $scBtn = this.getComparisonBtnTpl(comparisonParams)

                    const editableActiveClass =
                      _.isEqual(uniqueID, cell) && isEditable && updateMode !== 2
                        ? '-editable-active'
                        : ''

                    const metricFieldName = { Name: _name, DisplayName: _displayName }
                    const placeholder = `${
                      buttonFormat === 'percentage' ? '100%' : numeral(plannedValue).format(Format)
                    }`
                    const keyDownObj = {
                      MinMaxCheckType,
                      MinAcceptableValue,
                      MaxAcceptableValue,
                      OldValue: plannedValue,
                      LeftKeys,
                      BaselineValue: baselineValue,
                      MetricField: metricsField.Name,
                      UpdateType: buttonFormat === 'percentage' ? 1 : 0,
                      Guid: uniqueID
                    }

                    return (
                      <div
                        key={`${index}-${subindex}`}
                        className={`-cell ${editableActiveClass}`}
                        data-id={uniqueID}
                        onClick={this.onCellDivClick.bind(this, {
                          ExternalEdit,
                          IsApproved,
                          IsLocked,
                          LeftKeys,
                          baselineValue,
                          isEditable,
                          isPercent,
                          metricsField,
                          plannedValue,
                          uniqueID,
                          valuesId
                        })}
                      >
                        <div className="-cellContent">
                          {Visible &&
                          calculatedValue !== 0 &&
                          !_.isEqual(plannedValue, baselineValue) ? (
                            <div className="-corner" style={iconMap[iconCondition].style}>
                              <i className={`diff-icon slvy-ui-icon-caret-${iconCondition}`} />
                              <div className="-percentage">
                                {isPercent
                                  ? numeral(calculatedValue).format('0.0%')
                                  : `${calculatedValue}%`}
                              </div>
                            </div>
                          ) : null}
                          <div key={`${index}-${subindex}-${0}`} className="-baseline">
                            {numeral(baselineValue).format(Format)}
                          </div>
                          {isEditable &&
                          (updateMode === 2 || updateMode === 0) &&
                          viewMode === 2 &&
                          IsLocked !== 1 &&
                          !MissingRow ? (
                            <div
                              key={`${index}-${subindex}-${1}`}
                              className={`-planned -editable ${ExternalEdit}`}
                            >
                              {numeral(plannedValue).format(Format)}
                              {detailViewForSingleColumn && LeftKeys.length > 0 && !isLast
                                ? [
                                    <OverlayTrigger key="icn-tooltip-1" tooltip="Single Detail">
                                      <i
                                        key="icn-1"
                                        className="slvy-ui-icon-export-box"
                                        onClick={this.cellClick.bind(
                                          this,
                                          LeftKeys,
                                          metricFieldName,
                                          singleDetailMetricParams
                                        )}
                                      />
                                    </OverlayTrigger>
                                  ]
                                : null}
                              {isVisibleSCButton && !isFirst ? $scBtn : null}
                              <GridCell
                                ref={(inst) => {
                                  if (uniqueID === cell) {
                                    this.elem = ReactDOM.findDOMNode(inst)
                                  }
                                }}
                                gridCellProps={{
                                  placeholder,
                                  plannedValue
                                }}
                                numberFormatProps={{
                                  decimalScale,
                                  decimalSeparator,
                                  fixedDecimalScale: true,
                                  placeholder,
                                  thousandSeparator,
                                  value: '',
                                  onKeyDown: this.updateCall.bind(
                                    this,
                                    { ...keyDownObj },
                                    isPercent,
                                    IsApproved
                                  )
                                }}
                              />
                            </div>
                          ) : !isEditable || IsLocked === 1 ? (
                            <div key={`${index}-${subindex}-${1}`} className="-planned">
                              {detailViewForSingleColumn && LeftKeys.length > 0 && !isLast
                                ? [
                                    <OverlayTrigger key="icn-tooltip-1" tooltip="Single Detail">
                                      <i
                                        key="icn-2"
                                        className="slvy-ui-icon-export-box"
                                        onClick={this.cellClick.bind(
                                          this,
                                          LeftKeys,
                                          metricFieldName,
                                          singleDetailMetricParams
                                        )}
                                      />
                                    </OverlayTrigger>
                                  ]
                                : null}
                              {isVisibleSCButton && !isFirst ? $scBtn : null}
                              {numeral(plannedValue).format(Format)}
                            </div>
                          ) : (
                            isEditable &&
                            updateMode === 1 &&
                            viewMode === 2 && (
                              <div
                                key={`${index}-${subindex}-${1}`}
                                className="-planned -editable -row-update"
                              >
                                {detailViewForSingleColumn && LeftKeys.length > 0 && !isLast
                                  ? [
                                      <OverlayTrigger
                                        key="icn-tooltip-1"
                                        placement="top"
                                        tooltip="Single Detail"
                                      >
                                        <i
                                          key="icn-3"
                                          className="slvy-ui-icon-export-box"
                                          onClick={this.cellClick.bind(
                                            this,
                                            LeftKeys,
                                            metricFieldName,
                                            singleDetailMetricParams
                                          )}
                                        />
                                      </OverlayTrigger>
                                    ]
                                  : null}
                                {isVisibleSCButton && !isFirst ? $scBtn : null}
                                {numeral(plannedValue).format(Format)}
                                <GridCell
                                  ref={(inst) => {
                                    if (uniqueID === cell) {
                                      this.elem = ReactDOM.findDOMNode(inst)
                                    }
                                  }}
                                  gridCellProps={{
                                    name: subindex,
                                    placeholder,
                                    plannedValue
                                  }}
                                  numberFormatProps={{
                                    decimalScale,
                                    decimalSeparator,
                                    fixedDecimalScale: true,
                                    placeholder,
                                    thousandSeparator,
                                    ref: (inst) => {
                                      if (uniqueID === cell) {
                                        this.elem = ReactDOM.findDOMNode(inst)
                                      }
                                    }
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>

                <GridBtnSection
                  ApproveKey={ApproveKey}
                  GetScenarioTree={GetScenarioTree}
                  IsApproveAllowed={IsApproveAllowed}
                  IsApproved={IsApproved}
                  LeftKeys={LeftKeys}
                  LockKey={LockKey}
                  Metrics={Metrics}
                  MissingRow={MissingRow}
                  actionCall={this.actionCall.bind(this)}
                  allowedToUpdate={allowedToUpdate}
                  buttonFormat={buttonFormat}
                  isEveryColumnInRangeValid={this.isEveryColumnInRangeValid.bind(this)}
                  isLast={isLast}
                  isScenarioEditable={isScenarioEditable}
                  metricsFields={metricsFields}
                  pivotClick={this.pivotClick.bind(this)}
                  reqLeftFields={reqLeftFields}
                  setHierarchy={setHierarchy}
                  settings={settings}
                  stateHandler={stateHandler}
                  updateMode={updateMode}
                  updateMultiple={this.updateMultiple.bind(this)}
                  values={values}
                  valuesId={valuesId}
                  viewMode={viewMode}
                  onAction={onAction}
                />
              </div>
              <Grid
                GetScenarioTree={GetScenarioTree}
                IsApproveAllowed={IsApproveAllowed}
                allowedToUpdate={allowedToUpdate}
                buttonFormat={buttonFormat}
                culture={culture}
                data={Children}
                isRowClickedEventOn={isRowClickedEventOn}
                isScenarioEditable={isScenarioEditable}
                leftHierarchy={leftHierarchy}
                leftKeyLength={leftKeyLength}
                metricsFields={metricsFields}
                pluginId={pluginId}
                reqLeftFields={reqLeftFields}
                selectedRowId={selectedRowId}
                setHierarchy={setHierarchy}
                setSelectedRowId={setSelectedRowId}
                settings={settings}
                stateHandler={stateHandler}
                updateMode={updateMode}
                values={values}
                viewMode={viewMode}
                onAction={onAction}
                onCellClick={onCellClick}
                onPivotClick={onPivotClick}
                onRowClick={onRowClick}
                onRowClicked={onRowClicked}
                onScenarioComparisonClick={onScenarioComparisonClick}
                onUpdate={onUpdate}
              />
            </div>
          )
        })}
      </div>
    )
  }
}
