import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import lockedOptions from '../../lockedOptions'
import approvedOptions from '../../approvedOptions'
import HeaderButton from './HeaderButton'
import { MESSAGES } from '../../messages'
import { saveSetFilterRemote } from '../../store/actions'

interface IHeaderSmallFilterGroup {
  onReloadViewClick: any
}

function HeaderSmallFilterGroup({ onReloadViewClick }: IHeaderSmallFilterGroup) {
  const dispatch = useDispatch()

  async function onLockChange(event: ChangeEvent<HTMLSelectElement>) {
    const Locked = event.target.value
    await dispatch(
      saveSetFilterRemote({
        payload: { Locked }
      })
    )
    onReloadViewClick()
  }

  async function onApproveChange(event: ChangeEvent<HTMLSelectElement>) {
    const Approved = event.target.value
    await dispatch(
      saveSetFilterRemote({
        payload: { Approved }
      })
    )
    onReloadViewClick()
  }

  const filters = [
    { onChange: onLockChange, options: lockedOptions },
    { onChange: onApproveChange, options: approvedOptions }
  ]

  return (
    <div className="selectable-button -s-hover">
      <HeaderButton icon="slvy-ui-icon-cog-outline" label={MESSAGES.settings} />
      <ul className="-small-filter">
        <li>
          {filters.map(({ onChange, options }, filterIndex) => {
            return (
              <select key={filterIndex} onChange={onChange}>
                {options.map(({ value, name }) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                ))}
              </select>
            )
          })}
        </li>
      </ul>
    </div>
  )
}

export default HeaderSmallFilterGroup
