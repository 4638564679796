import useCheckedLoad from './useCheckedLoad'
import useCheckedLoads from './useCheckedLoads'
import useDisabledFooterButtons from './useDisabledFooterButtons'
import useEnabledFooterButtons from './useEnabledFooterButtons'
import useOpenLoadCard from './useOpenLoadCard'
import useSetTuiConnection from './useSetTuiConnection'
import useUpdateDemandTree from './useUpdateDemandTree'
import useUpdateLoadTree from './useUpdateLoadTree'

function useRegisterMethods({ demandTreeTableRef, loadTreeTableRef }) {
  useDisabledFooterButtons()
  useEnabledFooterButtons()
  useOpenLoadCard()
  useSetTuiConnection({ demandTreeTableRef, loadTreeTableRef })
  useUpdateLoadTree({ loadTreeTableRef })
  useUpdateDemandTree({ demandTreeTableRef })
  useCheckedLoad({ loadTreeTableRef })
  useCheckedLoads({ loadTreeTableRef })
}

export default useRegisterMethods
