/* eslint eqeqeq: "off" */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { selectProduct as onProductSelect, saveResourceIndex } from '../../../../actions'
import { slvyToast } from '../../../../../../components'
import { ChartCell } from '../../../../components'
import {
  round,
  getMessage,
  getAttachments,
  val,
  editReason,
  format,
  parse,
  cellLoading,
  isStateCellTargetEmpty,
  patchDiff,
  displayOfflineErrorMsg
} from '../../../../utils'
import Queue from '../../../../utils/queue'
import CustomConsole from '../../../../utils/custom-console'

class ResourceChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelection: '0',
      productPeriod: {},
      attachments: [],
      updatedPeriodId: -1,
      currentReason: {},
      isUserAllowedToChangeProductionQuantity: true
    }

    this.cellTarget = null
    this.modificationCount = null
  }

  updateHandler(params, e, extras = {}) {
    if (_.isEmpty(extras) && _.isEmpty(e)) {
      this.setState({
        currentReason: {},
        attachments: []
      })
    }

    const {
      connectApi,
      settings: {
        modificationCount = 0,
        stateHandler: {
          selectedParameter: { tableName = 'Production' }
        },
        updateReasons = {},
        resourceAndProductPlanSettings: {
          grandTotal: { isEnabled: isGrandTotalEnabled = true } = {}
        } = {}
      },
      periodDates = {},
      resourcePeriodHierarchy = {},
      initialFilters: { locationFilter = {}, resourceFilter = {}, productFilter = {} },
      stateHandler = () => {}
    } = this.props
    let { cellTarget } = this

    const { resourceId } = params

    let { keyCode, target, target: { value = '' } = {} } = e || {}
    const { isForce = false, itemValue = value, clickUpdate = false } = extras

    value = parse(value)

    if (keyCode === 13 || clickUpdate) {
      const set = {
        isopen: true,
        node: target,
        reasondata: updateReasons
      }
      const update = () => {
        const {
          currentReason: { code: reasonCode, name: reasonName }
        } = this.state

        const updateRequest = () => ({
          hierLocationFilter: locationFilter,
          hierResourceFilter: resourceFilter,
          hierProductFilter: productFilter,
          hierProductId: -1,
          hierResourceId: resourceId,
          fieldName: tableName,
          ...params,
          newValue: clickUpdate ? itemValue : value,
          rccpConfig: {
            isGrandTotalEnabled,
            ...(isForce ? { ForceUpdate: true } : {}),
            ...periodDates,
            UpdateReasonCode: reasonCode,
            UpdateReasonDescription: reasonName,
            ModificationCount: this.modificationCount ? this.modificationCount : modificationCount
          },
          isQueue: true
        })

        // TODO: Refactor...
        if (target.parentNode.tagName === 'HEADER') cellTarget = target.parentNode
        cellLoading(target.parentNode, true)
        const updateApi = (req) => {
          if (tableName === 'Production') {
            req = { ...req, ...{ resourceId } }
          }
          return new Promise((resolve, reject) =>
            connectApi('UpdateRccpResourcePeriodProductAndReturnHierarchyDiffWPF', req)
              .then((res) => {
                let {
                  Description = 'Error',
                  Code = -1,
                  Json,
                  IsDiff = false,
                  ModificationCount
                } = res

                this.modificationCount = ModificationCount
                try {
                  Json = JSON.parse(Json)
                } catch (error) {
                  slvyToast.error({ message: 'Parsing Failed!' })
                  CustomConsole.error(`Error: ${error}`)
                }
                let Attachments = []
                if (Code < 1) {
                  const { Attachments: xAttachments = [] } = Json ?? {}
                  Attachments = xAttachments
                } else {
                  const { Attachments: xAttachments = [] } = res
                  Attachments = xAttachments
                }

                if (!_.isEmpty(Attachments)) {
                  this.setState({
                    attachments: getAttachments(Attachments, value),
                    updatedPeriodId: params.periodId
                  })
                }

                getMessage(Code, Description).then((ret) => {
                  if (ret.isOk) {
                    if (clickUpdate) {
                      this.setState({
                        attachments: [],
                        updatedPeriodId: -1
                      })
                    }

                    if (Code !== 0) {
                      patchDiff({
                        left: resourcePeriodHierarchy,
                        diffJson: Json,
                        isDiff: IsDiff,
                        stateName: 'GetRccpResourcePeriodHierarchyResponseWPF',
                        setState: stateHandler
                      })
                    }

                    isStateCellTargetEmpty(cellTarget)
                      ? cellLoading(target.parentNode, false)
                      : cellLoading(cellTarget, false)
                    resolve()
                  } else {
                    isStateCellTargetEmpty(cellTarget)
                      ? cellLoading(target.parentNode, false)
                      : cellLoading(cellTarget, false)
                    reject()
                  }
                })
              })
              .catch(() => {
                displayOfflineErrorMsg()

                isStateCellTargetEmpty(cellTarget)
                  ? cellLoading(target.parentNode, false)
                  : cellLoading(cellTarget, false)
                reject()
              })
          )
        }

        Queue.enqueue(updateApi, updateRequest).catch((error) => CustomConsole.error(error))
      }

      if (_.isEmpty(this.state.attachments)) {
        editReason(set, {
          onConfirm: (e, isok, reason) => {
            this.setState({ currentReason: reason }, () => update())
          },
          onReject: resolveHandler
        })
      } else {
        update()
      }
    }
    cellLoading(cellTarget, false)
  }

  clickHandler(pindex, periodId, period, e) {
    e.preventDefault()

    const { currentSelection } = this.state

    // productClickHandler({}, resourceIndex, period)
    // this.props.onProductSelect({ productIndex: null, periodIndex: pindex, cover: null })
    // this.props.onToggleProduction({ productIndex: null, periodIndex: pindex })

    this.props.onProductSelect({
      productIndex: 0,
      periodIndex: pindex,
      isResourceChart: true
    })

    if (currentSelection !== pindex) {
      // chartHandler(pindex)
      this.setState({
        currentSelection: pindex,
        updatedPeriodId: periodId,
        attachments: []
      })
    }
  }

  getData(resourcePeriod, products) {
    return _.reduce(
      _.cloneDeep(resourcePeriod),
      (obj, value, key) => {
        value.ResourcePeriodProducts = _.map(value.ResourcePeriodProducts, (_rpp, _rppindex) => {
          const { ProductIndex: newProductIndex = 0 } = _rpp

          return {
            ..._rpp,
            ...{ Product: products[newProductIndex] },
            ...{ ResourcePeriodProductItemIndex: _rppindex }
          }
        })

        obj[key] = value
        return obj
      },
      {}
    )
  }

  componentDidMount() {
    const {
      settings: {
        history: {
          state: {
            back: { pushState: { TableProduct: { productDictionary = {} } = {} } = {} } = {}
          } = {}
          // componentState: {
          //     pushState: {
          //         TableResource: {
          //             resourceDictionary = {}
          //         } = {}
          //     } = {}
          // } = {}
        } = {}
      } = {},
      productPeriods,
      resourcePeriod,
      products,
      productId = 0,
      clickedProductInfo: { periodIndex = 0 } = {},
      isAllowed = () => {}
    } = this.props

    const newResourcePeriod = this.getData(resourcePeriod, products)
    const { currentProductPeriod = 0 } =
      _.find(productDictionary, (_pd) => _pd.currentProductId === productId) || {}
    const { ResourcePeriodProducts: newResourcePeriodProducts = [] } =
      newResourcePeriod[currentProductPeriod] ?? {}
    const resourcePeriodProductItem =
      _.find(newResourcePeriodProducts, (_rp) => _rp.Product.Id === productId) || {}
    const { ProductIndex = 0 } = resourcePeriodProductItem ?? {}
    const productPeriod = productPeriods[ProductIndex][0] /// To be dynamic

    // productClickHandler(
    //     { ...resourcePeriodProductItem },
    //     resourceIndex,
    //     periods[currentProductPeriod]
    // )

    // debugger

    const isUserAllowedToChangeProductionQuantity = isAllowed(
      'Resource and Production/Resource Based Production Quantity'
    )

    this.setState({
      productPeriod,
      currentSelection: periodIndex.toString(),
      isUserAllowedToChangeProductionQuantity
    })
  }

  render() {
    const {
      products,
      productPeriods,
      resourceId,
      resourcePeriod: newResourcePeriod,
      periods,
      settings,
      settings: {
        stateHandler: { selectedParameter: { Index: SelectedParamIndex = 0 } = {} },
        productInfoSettings = []
      },
      clickedProductInfo: { productIndex = undefined, isResourceChart } = {}
    } = this.props
    const {
      productPeriod: { ProductIndex: stateProductIndex = 0 },
      currentSelection,
      attachments,
      updatedPeriodId,
      isUserAllowedToChangeProductionQuantity = true
    } = this.state

    const productionObj = _.find(productInfoSettings, { tableName: 'Production' }) || {}
    const { format: formatType } = productionObj || {}
    const resourcePeriod = this.getData(newResourcePeriod, products)
    // color =
    //     d3
    //         .scaleLinear()
    //         .domain([0, 400])
    //         .interpolate(d3.interpolateHcl)
    //         .range([d3.rgb('#dcdcdc'), d3.rgb('#999')])

    return (
      <aside className="product-resource-chart -chart">
        {_.map(resourcePeriod, (pitem, pindex) => {
          let {
            PeriodIndex = 0,
            MinorSetupPercentage = 0,
            MajorSetupPercentage = 0,
            ResourcePeriodProducts = []
          } = pitem
          const currentPeriod = periods[PeriodIndex]
          /// , Name: PeriodName
          const { Id: periodId = 0, Visible = false } = currentPeriod
          const selectedProduction =
            _.find(ResourcePeriodProducts, (rpItem) => rpItem.ProductIndex === stateProductIndex) ||
            {}
          const { Values = {} } = selectedProduction

          MinorSetupPercentage = 100 * MinorSetupPercentage
          MajorSetupPercentage = 100 * MajorSetupPercentage

          const calculatedMinorSetup = MinorSetupPercentage
          const calculatedMajorSetup = MajorSetupPercentage
          const totalSetup = round(calculatedMinorSetup + calculatedMajorSetup)
          const chartStyle = {
            height: `calc(100% - ${24}px)`
          }
          const selectedProductionValue = val(Values, 'Production', SelectedParamIndex)

          const { IsGrandTotal = true } = periods[pindex] || {}

          return (
            Visible && (
              <figure key={pindex} className={pindex === currentSelection ? '-active' : ''}>
                <section>
                  <ChartCell
                    attachments={{ attachments, isActive: updatedPeriodId === periodId }}
                    disabled={!isUserAllowedToChangeProductionQuantity || IsGrandTotal}
                    formatType={formatType}
                    settings={settings}
                    updateHandler={this.updateHandler.bind(this, {
                      resourceId,
                      productId: products[stateProductIndex].Id,
                      periodId
                    })}
                    value={format(selectedProductionValue, formatType)}
                  />
                </section>

                <main
                  className="-chart-wrp"
                  style={chartStyle}
                  onClick={this.clickHandler.bind(this, pindex, periodId, currentPeriod)}
                >
                  <footer style={{ height: `${totalSetup}%` }}>
                    <div
                      className="-setup"
                      style={{
                        height: `${(100 * calculatedMinorSetup) / totalSetup}%`,
                        backgroundColor: 'gray'
                      }}
                    />
                    <div
                      className="-setup"
                      style={{
                        height: `${(100 * calculatedMajorSetup) / totalSetup}%`,
                        backgroundColor: 'black'
                      }}
                    />
                  </footer>

                  {_.map(
                    ResourcePeriodProducts,
                    (resourcePeriodProductItem, resourcePeriodProductIndex) => {
                      const {
                        CapacityPercentage = 0,
                        ProductIndex = 0,
                        Product = {}
                      } = resourcePeriodProductItem
                      const productPeriod = productPeriods[ProductIndex][pindex]
                      const isSelected = productPeriod.ProductIndex == stateProductIndex
                      const { Name = '' } = Product
                      const productItemHeight = CapacityPercentage * 100

                      return (
                        <div
                          key={resourcePeriodProductIndex}
                          className={`-cbar ${isSelected ? '-active-product' : ''} ${
                            ProductIndex === productIndex && !isResourceChart ? '-green' : ''
                          } ${productItemHeight < 3.2 ? '-zoom-hover' : ''}`}
                          style={{ height: `${productItemHeight}%` }}
                          title={Name}
                          // style={{ height: `${productItemHeight}%`, backgroundColor: color((400 / ResourcePeriodProducts.length) * resourcePeriodProductIndex) }}
                          onClick={(e) => {
                            e.stopPropagation()

                            this.props.onProductSelect({
                              productIndex: ProductIndex,
                              periodIndex: pindex,
                              isResourceChart: true
                            })

                            this.setState({
                              productPeriod,
                              currentSelection: pindex,
                              attachments: []
                            })
                          }}
                        />
                      )
                    }
                  )}
                </main>
              </figure>
            )
          )
        })}
      </aside>
    )
  }
}

const mapStateToProps = (state) => ({
  resourcePeriodHierarchy: state.resourcePeriodHierarchy,
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
  periodDates: state.resourcePeriodHierarchy.periodDates,
  resourceIndex: state.clickedProductInfo.resourceIndex
})

const mapDispatchToProps = {
  onProductSelect,
  saveResourceIndex
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceChart)
