Ext.define('MDM.src.view.globalapp.MainViewmodel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.globalapp-mainviewmodel',
  stores: {
    calStore: {
      type: 'calendar-calendars',
      autoLoad: false,
      autoSync: true,
      listeners: {
        updated: 'onWriteEvent'
      },
      eventStoreDefaults: {
        // prefetchMode: 'week',
        type: 'calendar-events',

        autoLoad: true,
        autoSync: true,
        listeners: {
          write: function (store) {
            var calendarStore = store.calendar.store

            calendarStore.fireEvent('updated', calendarStore)
          }
        },
        proxy: {
          type: 'ajax',
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          api: {
            create: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/create',
            read: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/read',
            update: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/update',
            destroy: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Event/destroy'
          }
        }
      },
      proxy: {
        type: 'ajax',
        method: 'POST',
        url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Visiual_Calendar_Read',
        reader: {
          type: 'json'
        }
      }
    },
    EditCalStore: {
      model: 'MDM.src.view.globalapp.model.EditCal',
      autoLoad: false,
      bindTo: 'bindTodfcStore',
      autoSync: false,
      listeners: {
        load: 'OnLoad',
        update: 'onCalendarWrite'
      }
    },

    DPITreeStore: {
      type: 'tree',
      model: 'MDM.src.view.globalapp.Panel.Dpi.model.DPITree',

      autoLoad: false,
      root: {
        expanded: true,
        text: 'DPI Families',
        iconCls: 'fa fa-sitemap',
        id: '0',
        children: []
      },

      listeners: {}
    }
  },
  formulas: {
    isNameModified: {
      bind: {
        bindTo: '{reportingPeriod}',
        deep: true
      },
      get: function (reportingPeriod) {
        return record.get(WizardPage4)
      }
    },

    canPublish: {
      bind: {
        bindTo: '{record}',
        deep: true
      },
      get: function (record) {
        return record.get('WizardPage4') != '1'
      }
    },
    PublishText: {
      bind: {
        bindTo: '{record}',
        deep: true
      },
      get: function (record) {
        return record.get('IsPublished') != '1' ? 'Publish' : 'Published'
      }
    },
    CalendarDate: {
      bind: {
        bindTo: '{record}',
        deep: true
      },
      get: function (record) {
        if (record && record.get('BeginDate')) {
          return Ext.Date.getFirstDateOfMonth(record.get('BeginDate'))
        }
        return Ext.Date.getFirstDateOfMonth(new Date())
      }
    },

    reportingPeriod: {
      bind: {
        Year: '{Year}',
        Month: '{Month}'
      },
      get: function (reportingPeriod) {
        return 'FY-' + reportingPeriod.Year + '-' + reportingPeriod.Month
      }
    },

    processLine: {
      bind: {
        pageIndex: '{pageIndex}',
        record: '{record}'
      },
      get: function (data) {
        var pageIndex = data.pageIndex,
          record = data.record
        pageIndex = pageIndex - 1
        var pages = [
          {
            name: 'DPI Calendar',
            status: 'complete',
            Index: '0'
          },
          {
            name: 'DPI Hierarchies',
            status: 'in-progress',
            Index: '1'
          },
          {
            name: 'Geo Hierarchies',
            status: '',
            Index: '2'
          },
          {
            name: 'Time Hierarchies',
            status: '',
            Index: '3'
          },
          {
            name: 'Summary',
            status: '',
            Index: '4'
          }
        ]

        for (var index = 0; index < pages.length; index++) {
          var page = index + 1

          status = record.get('WizardPage' + page)
          if (pageIndex == index) {
            pages[index]['status'] = 'in-progress'
          } else {
            if (status == 1) pages[index]['status'] = 'complete'
            else pages[index]['status'] = ''
          }
        }

        return pages
      }
    }
  },
  data: {
    reportingPeriod: 999,
    pageIndex: 1,
    readOnlyMode: true,
    owner: 'Global'
  }
})
