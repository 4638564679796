Ext.define('MDM.src.view.massimport.FormDialog', {
  extend: 'Ext.panel.Panel',
  requires: [
    'Ext.layout.container.Fit',
    'Ext.layout.container.VBox',
    'Ext.layout.container.HBox',
    'Ext.form.Panel',
    'Ext.form.field.Text',
    'Ext.form.field.Date',
    'Ext.form.field.Time',
    'Ext.form.field.Checkbox',
    'Ext.calendar.form.CalendarPicker'
  ],
  modal: true,
  viewModel: {
    data: {
      record: null,
      readOnly: false
    },
    formulas: {
      DPIFamilyLabel: {
        bind: {
          bindTo: '{record.DPIFamilyID}',
          deep: true
        },
        get: function (c) {
          if (c) return ''
          return 'warning'
        }
      },
      MaterialTypeLabel: {
        bind: {
          bindTo: '{record.MaterialTypeID}',
          deep: true
        },
        get: function (c) {
          if (c) return ''
          return 'warning'
        }
      },
      SupplysurceLabel: {
        bind: {
          bindTo: '{record.SupplySourceID}',
          deep: true
        },
        get: function (c) {
          if (c) return ''
          return 'warning'
        }
      },
      SubregionLabel: {
        bind: {
          bindTo: '{record.RegionID}',
          deep: true
        },
        get: function (c) {
          if (c) return ''
          return 'warning'
        }
      },
      MaterialLabel: {
        bind: {
          bindTo: '{record.MaterialID}',
          deep: true
        },
        get: function (c) {
          var record = this.get('record')

          var MaterialCode = record.get('MaterialCode')
          if (MaterialCode === '0') MaterialCode = true

          if (c) {
            return ''
          } else if (MaterialCode) {
            if (!c) {
              return 'warning'
            }
            return ''
          }

          return ''
        }
      }
    }
  },
  closable: false,
  iconCls: 'fa fa-pencil-square-o',
  cls: 'menuform',
  defaultListenerScope: true,
  height: 350,
  width: 600,
  bodyStyle: {
    background: 'whitesmoke'
  },
  record: null,
  bodyPadding: 0,
  border: false,
  config: {
    dpiFamilyField: {
      xtype: 'combo',
      fieldLabel: 'DPI Family',
      cls: 'menuform',
      name: 'DPIFamily',
      forceSelection: true,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      typeAhead: true,
      flex: 1,
      valueField: 'id',
      allowBlank: false,
      defaultListConfig: {
        loadingHeight: 70,
        minWidth: 240,

        shadow: 'sides'
      },
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      listeners: {
        select: 'onChangeDpiFamily'
      },
      bind: {
        value: '{record.DPIFamilyID}',
        userCls: '{DPIFamilyLabel}',
        readOnly: '{!isMass}'
      }
    },
    materialTypeField: {
      xtype: 'combo',
      fieldLabel: 'Material Type',
      name: 'MaterialType',
      forceSelection: true,
      flex: 1,
      editable: false,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      allowBlank: false,

      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      listeners: {
        select: 'onChangeMaterialType'
      },
      bind: {
        value: '{record.MaterialTypeID}',
        userCls: '{MaterialTypeLabel}'
      }
    },
    dpiTypeDescField: {
      xtype: 'textfield',
      fieldLabel: 'BTL Type',
      flex: 1,
      name: 'BTLType',
      disabled: true,
      bind: {
        value: '{record.BTLType}',
        readOnly: '{readOnly}'
      }
    },
    materialCodeField: {
      xtype: 'mygridpicker',
      fieldLabel: 'Material Code',
      name: 'Material',
      queryMode: 'remote',

      bind: {
        value: '{record.MaterialCode}',
        disabled: '{!record.DPIFamilyID}',
        userCls: '{MaterialLabel}',
        emptyTextGrid:
          'No Material found in<br><div class="formlabel">DPIFamily : "{record.DPIFamily}" <br></div><div class="formlabel">MaterialType : "{record.MaterialType}"<br></div><div class="formlabel">Region : "{record.Region}"</div>'
      },
      allowBlank: true,
      flex: 1,
      multiSelect: false,
      listeners: {
        select: 'onChangeMaterialCode'
      },
      onExpand: function () {
        this.store.load()
      },
      columns: [
        {
          text: 'Code',
          dataIndex: 'id',
          width: 100
        },
        {
          text: 'Name',
          dataIndex: 'name',
          flex: 1
        }
      ],
      displayField: 'name',
      valueField: 'MaterialCode'
    },
    materialDescription: {
      xtype: 'textfield',
      fieldLabel: 'Description',
      name: 'MaterialDescription',
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.MaterialDescription}'
      }
    },
    regionDescField: {
      xtype: 'textfield',
      fieldLabel: 'Region',
      flex: 1,
      name: 'Region',
      disabled: true,
      readOnly: true,
      bind: {
        value: '{record.MegaRegion}'
      }
    },
    subRegionField: {
      xtype: 'combo',
      fieldLabel: 'Subregion',
      name: 'Subregion',
      forceSelection: true,
      flex: 1,
      editable: false,
      queryMode: 'local',
      displayField: 'name',
      valueField: 'id',
      allowBlank: false,
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      listeners: {
        select: 'onChangeSubRegion'
      },
      bind: {
        value: '{record.RegionID}',
        readOnly: '{!isMass}'
      }
    },
    fromRegionField: {
      xtype: 'comboboxwithclear',
      fieldLabel: 'From Subregion',
      name: 'fromregion',
      itemId: 'fromregion',
      forceSelection: true,
      editable: false,

      flex: 2,
      valueField: 'id',
      displayField: 'name',
      bind: {
        value: '{record.FromRegionID}'
      },
      listeners: {
        select: 'onChangeFromRegion'
      }
    },
    toRegionField: {
      xtype: 'comboboxwithclear',
      fieldLabel: 'To Subregion',
      name: 'toregion',
      itemId: 'toregion',
      flex: 2,
      valueField: 'id',
      displayField: 'name',
      allowBlank: false,
      bind: {
        value: '{record.ToRegionID}'
      },
      listeners: {
        select: 'onChangeToRegion'
      }
    },
    materialDescField: {
      xtype: 'textfield',
      fieldLabel: 'Description',
      name: 'MaterialDescription',
      flex: 1,
      disabled: true,
      bind: {
        value: '{record.MaterialDescription}'
      }
    },
    commentField: {
      xtype: 'textarea',
      fieldLabel: 'Comment',
      name: 'Comment',
      flex: 1,
      height: 100,
      bind: {
        value: '{record.Comment}',
        readOnly: '{readOnly}'
      }
    },
    dpiReasonField: {
      xtype: 'combo',
      fieldLabel: 'Reason',
      cls: 'menuform',
      name: 'Reason',
      forceSelection: true,
      editable: true,
      queryMode: 'local',
      displayField: 'name',
      typeAhead: true,
      flex: 1,
      valueField: 'id',
      allowBlank: false,
      defaultListConfig: {
        loadingHeight: 70,
        minWidth: 240,

        shadow: 'sides'
      },
      afterLabelTextTpl: ['<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'],
      listeners: {},
      bind: {
        value: '{record.Reason}',
        userCls: '{DPIFamilyLabel}',
        readOnly: '{readOnly}'
      }
    },
    supplySourceField: {
      xtype: 'combo',
      fieldLabel: 'Plant',
      name: 'SupplySource',
      readOnly: false,
      displayField: 'name',
      valueField: 'id',
      queryMode: 'remote',
      lastQuery: '',
      flex: 1,
      listeners: {
        select: 'onChangePlant',
        expand: function (field) {
          field.store.load()
        }
      },
      bind: {
        value: '{record.SupplySourceID}',
        userCls: '{SupplysurceLabel}'
      }
    },
    showHideButton: {
      text: 'Show/Hide Data',
      handler: 'onShowHide'
    },
    /**
     * @cfg {Object} saveButton
     * The config for the save button.
     */
    saveButton: {
      text: 'OK',
      handler: 'onSaveTap',
      bind: {}
    },
    r1Container: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    r2Container: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    r3Container: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    r4Container: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    r5Container: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    r6Container: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },
    r7Container: {
      xtype: 'fieldcontainer',
      layout: {
        type: 'hbox',
        align: 'stretch'
      }
    },

    /**
     * @cfg {Object} cancelButton
     * The config for the cancel button.
     */
    cancelButton: {
      text: 'Close',
      iconCls: 'fa fa-times',
      handler: 'onCancelTap'
    }
  },
  initComponent: function () {
    var me = this

    var BTLType = me.record.get('BTLType')

    var BTLTypeDesc = me.record.get('BTLTypeDesc')
    this.getViewModel().set('isMass', this.isMass)
    this.getViewModel().set('readOnly', this.readOnly)
    if (
      BTLType == 'P' ||
      BTLType == 'D' ||
      BTLType == 'I' ||
      BTLType == 'X' ||
      BTLType == 'R' ||
      BTLType == 'N'
    ) {
      this.title = BTLType
      this.getViewModel().set('record', this.record)
      me.initForm()
      me.fbar = me.generateButtons()
      me.callParent()
      me.form = me.items.first()
      me.checkFields()
      // me.applyValues()
    } else {
      me.height = 150
      me.width = 300
      me.title = 'Error ! <span style="color:yellow">' + BTLTypeDesc + '</span>'
      me.msg = new Ext.Component({
        cls: me.baseCls + '-text',
        html: BTLTypeDesc,
        margin: '5 5 20,5'
      })
      ;(me.iconCls = 'fa fa-warning'), (me.items = [me.msg])

      me.fbar = [me.getCancelButton()]
      me.callParent()
    }
  },
  initForm: function () {
    this.createItems()
  },
  generateButtons: function () {
    var buttons = []

    var showhide = this.getShowHideButton()

    buttons.push(
      {
        xtype: 'component',
        flex: 1
      },
      this.getSaveButton()
    )

    return buttons
  },
  createItems: function () {
    var me = this
    var r1Container = me.getR1Container()
    var r2Container = me.getR2Container()
    var r3Container = me.getR3Container()
    var r4Container = me.getR4Container()
    var r5Container = me.getR5Container()
    var r6Container = me.getR6Container()
    var r7Container = me.getR7Container()

    r1Container.items = [me.getDpiFamilyField(), me.getMaterialTypeField()]
    r2Container.items = [me.getRegionDescField(), me.getSubRegionField()]
    r3Container.items = [me.getMaterialCodeField(), me.getMaterialDescField()]
    r4Container.items = [me.getCommentField()]
    r5Container.items = [me.getSupplySourceField()]
    r6Container.items = [me.getToRegionField(), me.getFromRegionField()]
    r7Container.items = [me.getDpiReasonField()]

    var items = []

    var BTLType = me.record.get('BTLType')
    if (BTLType == 'D' || BTLType == 'I' || BTLType == 'N' || BTLType == 'R') {
      items.push(r1Container)
      items.push(r2Container)
      items.push(r3Container)
      items.push(r7Container)
      items.push(r4Container)
    }
    if (BTLType == 'P') {
      items.push(r1Container)
      items.push(r2Container)
      items.push(r3Container)
      items.push(r5Container)
      items.push(r7Container)
      items.push(r4Container)
    }
    if (BTLType == 'X') {
      items.push(r1Container)
      items.push(r2Container)
      items.push(r3Container)
      items.push(r6Container)
      items.push(r7Container)
      items.push(r4Container)
    }

    me.title =
      'BTL ' +
      '<span style="color:yellow">' +
      me.record.get('BTLType') +
      ' - ' +
      me.record.get('BTLTypeDesc') +
      '</span> edit'
    this.items = [
      {
        xtype: 'form',
        border: false,
        trackResetOnLoad: true,
        region: 'center',

        height: '120',
        defaults: {
          anchor: '100%',
          flex: 1,
          labelWidth: 140
        },
        bodyPadding: 2,
        bodyStyle: {
          background: 'whitesmoke'
        },
        fieldDefaults: {
          labelAlign: 'right',
          labelWidth: 100,
          msgTarget: Ext.supports.Touch ? 'side' : 'qtip'
        },
        items: items
      }
    ]
  },

  privates: {
    checkFields: function () {},

    onAllDayChange: function () {
      this.checkFields()
    },

    onCancelTap: function () {
      this.hide()
    },

    onDropTap: function () {
      this.fireDrop()
    },

    onSaveTap: function () {
      this.fireEvent('save', this, this.grid)

      this.ownerCt.hide()
    },

    getDpiFamilyField: function () {
      var c = this.dpiFamilyField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getMaterialTypeField: function () {
      var c = this.materialTypeField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getSubRegionField: function () {
      var c = this.subRegionField
      if (!c.store) c.store = this.getStore(c.name)
      return c
    },
    getMaterialCodeField: function () {
      var c = this.materialCodeField
      if (!c.store) c.store = this.getStore(c.name, false)

      return c
    },
    getMaterialType: function () {
      var c = this.materialType
      if (!c.store) c.store = this.getStore(c.name)

      return c
    },
    getSupplySourceField: function () {
      var c = this.supplySourceField
      if (!c.store) c.store = this.getStore(c.name)

      return c
    },

    getFromRegionField: function () {
      var c = this.fromRegionField
      if (!c.store) c.store = this.getStore(c.name)

      return c
    },
    getToRegionField: function () {
      var c = this.toRegionField
      if (!c.store) c.store = this.getStore(c.name)

      return c
    },
    getDpiReasonField: function () {
      var c = this.dpiReasonField
      if (!c.store) c.store = this.getStore(c.name)

      return c
    },

    getStore: function (c, autoLoad = true) {
      return Ext.create('Ext.data.Store', {
        field: [
          {
            name: 'name'
          },
          {
            name: 'id'
          }
        ],
        proxy: {
          type: 'ajax',
          timeout: 60000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          url: MDM.Global.getApiUrl() + 'gillettev3/Mass_Import_ComboData',

          extraParams: {
            fieldType: c
          },

          reader: {
            type: 'json'
          }
        },
        autoLoad: autoLoad,
        listeners: {
          beforeload: 'onStoreBeforeLoad',
          scope: this
        }
      })
    },
    onStoreBeforeLoad: function (store) {
      var record = this.record

      var data = record.data

      var dataJson = Ext.encode(data)
      store.getProxy().setExtraParam('dataJson', dataJson)
    },
    onChangeDpiFamily: function (combo, selectedrecord) {
      if (selectedrecord) {
        var record = this.record
        record.set('DPIFamily', selectedrecord.get('name'))
      }
    },
    onChangeMaterialType: function (combo, selectedrecord) {
      if (selectedrecord) {
        var record = this.record
        record.set('MaterialType', selectedrecord.get('name'))
      }
    },
    onChangeSubRegion: function (combo, selectedrecord) {
      if (selectedrecord) {
        var record = this.record
        record.set('Region', selectedrecord.get('name'))
      }
    },
    onChangeMaterialCode: function (combo, selectedrecord) {
      if (selectedrecord) {
        var record = this.record
        record.set('MaterialID', selectedrecord[0].get('MaterialID'))
        record.set('MaterialCode', selectedrecord[0].get('MaterialCode'))
        record.set('MaterialDescription', selectedrecord[0].get('MaterialDescription'))
      }
    }
  },
  onChangePlant: function (combo, rec) {
    var me = this
    me.record.set('SupplySource', rec.get('name'))
    me.record.commit()
  },
  onChangeFromRegion: function (combo, rec) {
    var me = this
    me.record.set('FromRegion', rec.get('name'))
    me.record.commit()
  },

  onChangeToRegion: function (combo, rec) {
    var me = this
    me.record.set('ToRegion', rec.get('name'))
    me.record.commit()
  }
})
