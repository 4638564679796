Ext.define('MDM.src.view.globalapp.Panel.Geo.Panel.Tree', {
  extend: 'Ext.tree.Panel',
  xtype: 'globalapp-Geo-panel-tree',
  columnLines: true,
  rowLines: true,
  listeners: {
    select: 'onTreeSelect',
    activate: function (tree) {
      tree.store.load()
    }
  },
  viewConfig: {},
  columns: [
    {
      xtype: 'treecolumn', // this is so we know which column will show the tree
      text: 'Title',
      dataIndex: 'Title',
      flex: 2,
      width: 300,
      sortable: true
    },
    {
      xtype: 'gridcolumn', // this is so we know which column will show the tree
      text: 'Country Cnt',
      dataIndex: '#SupplySource',
      align: 'right',
      width: 80,
      sortable: true,
      renderer: function (value, metaData, record) {
        var v = 0
        if (record.get('depth') > 2) return ''
        if (record.hasChildNodes()) {
          record.cascadeBy(function (node) {
            var c = node.get('depth') === 3 ? 1 : 0
            v = v + c
          })
        }
        if (v == 0) return ''
        return v
      }
    },
    {
      xtype: 'gridcolumn', // this is so we know which column will show the tree
      text: 'Supply Source Cnt',
      dataIndex: 'SupplySourceCount',
      align: 'right',
      width: 80,
      sortable: true,
      renderer: function (value, metaData, record) {
        var v = value
        if (typeof v === 'undefined') v = 0
        if (record.get('depth') == 0) {
          record.cascadeBy(function (node) {
            var c = node.get('depth') === 1 ? node.get('SupplySourceCount') : 0
            v = v + c
          })
        }
        if (v == 0) return ''
        return v
      }
    }
  ],
  tbar: {
    reference: 'tbar',
    items: [
      {
        text: 'Expand All',
        target: 'dpitree',
        ui: 'btn-success',
        isCollapsed: true,
        handler: 'onExpandAllClick'
      }
    ]
  }
})
