Ext.define('ARH.form.field.ComboWithClear', {
  extend: 'Ext.form.field.ComboBox',
  alias: 'widget.comboboxwithclear',
  triggers: {
    clear: {
      weight: 1,
      cls: Ext.baseCSSPrefix + 'form-clear-trigger',
      hidden: true,
      handler: function () {
        this.clearValue()
      }
    },
    picker: {
      weight: 2,
      handler: 'onTriggerClick'
    }
  },
  initComponent: function () {
    this.callParent(arguments)
    this.on(
      'change',
      function (combo, record) {
        this[record ? 'show' : 'hide']()
      },
      this.getTrigger('clear')
    )
  }
})
