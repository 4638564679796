Ext.define('Spui.view.common.spotParameters.Mixin', {
  extend: 'Ext.Mixin',

  // Uncomment to give this component an xtype

  config: {
    inputFormCfg: 'inputForm',
    inputForm: {},
    tapPanelCfg: 'spotParametersTab',
    tapPanel: {}
  },
  applyInputFormCfg: function (c) {
    return window.ss(c)
  },
  applyTapPanelCfg: function (c) {
    return window.ss(c)
  },

  applyInputForm: function (b) {
    var setRec = this.getInputFormCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'spui-view-common-spotparameters-form',
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',

                cls: 'config-btn',
                handler: 'onConfigInputForm'
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }
    return b
  },
  updateInputForm: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigInputForm: function () {
    var setRec = this.getInputFormCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigInputFormSave, this)
  },
  onConfigInputFormSave: function (v, k) {
    var setRec = this.getInputFormCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setInputForm(res)
  },

  applyTapPanel: function (b) {
    var setRec = this.getTapPanelCfg()
    var code = setRec.get('code')
    var settings = code || '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        region: 'south',
        flex: 1,
        xtype: 'spui-view-common-spotParameters-tabpanel-main',

        plugins: [
          {
            ptype: 'cornerIcon',
            // offSet: {
            //     top: 0,
            //     right: 90
            // },
            cfgButton: [
              '->',
              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigTapPanel'

                //	handler: 'onConfigSimilarGrid',
              }
            ]
          }
        ]
      })

      b = Ext.create(b)
    }

    return b
  },
  updateTapPanel: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  onConfigTapPanel: function () {
    var setRec = this.getTapPanelCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigTapPanelSave, this)
  }
})
