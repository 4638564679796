// @flow
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import TaskCardItem from '../Primatives/task-card-item'
import { grid } from '../constants'
import Title from '../Primatives/title'
import type { TaskCard } from '../types'
import type {
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd'
import _ from 'lodash'

const Wrapper = styled.div`
  background-color: ${({ isDraggingOver }) => (isDraggingOver ? '#ffffff' : '#F3F3F4')};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.1s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px;
`

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: 250px;
  /* not relying on the items for a margin-bottom
  as it will collapse when the list is empty */
  margin-bottom: ${grid}px;
`

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
`

const Container = styled.div``

type Props = {|
  listId: string,
  listType?: string,
  taskCards: TaskCard[],
  title?: string,
  internalScroll?: boolean,
  isDropDisabled?: boolean,
  style?: Object,
  // may not be provided - and might be null
  autoFocusTaskCardId?: ?string,
  ignoreContainerClipping?: boolean
|}

type TaskCardListProps = {|
  taskCards: TaskCard[],
  autoFocusTaskCardId: ?string
|}

class InnerTaskCardList extends Component<TaskCardListProps> {
  constructor(props) {
    super(props)
    this.state = {
      taskCards: this.props.taskCards
    }
    this.sendTask = this.sendTask.bind(this)
  }
  sendTask(taskCard) {
    this.props.onShow(taskCard)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      taskCards: nextProps.taskCards
    })
  }
  shouldComponentUpdate(nextProps: TaskCardListProps) {
    if (nextProps.taskCards !== this.props.taskCards) {
      return true
    }

    return false
  }

  render() {
    const { taskAppearanceProperties } = this.props
    const { taskCards } = this.state

    return (
      <div>
        {taskCards.map((taskCard: TaskCard, index: number) => (
          <Draggable key={taskCard.Id} draggableId={taskCard.Id} index={index}>
            {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
              <div>
                <TaskCardItem
                  key={taskCard.Id}
                  taskCard={taskCard}
                  isDragging={dragSnapshot.isDragging}
                  provided={dragProvided}
                  autoFocus={this.props.autoFocusTaskCardId === taskCard.Id}
                  onShow={this.sendTask}
                  taskAppearanceProperties={taskAppearanceProperties}
                  uniqueUserName={this.props.uniqueUserName}
                  format={this.props.format}
                  openContent={this.props.openContent}
                />
                {dragProvided.placeholder}
              </div>
            )}
          </Draggable>
        ))}
      </div>
    )
  }
}

type InnerListProps = {|
  dropProvided: DroppableProvided,
  taskCards: TaskCard[],
  title: ?string,
  autoFocusTaskCardId: ?string
|}

class InnerList extends Component<InnerListProps> {
  constructor(props) {
    super(props)
    this.state = {
      taskCards: this.props.taskCards
    }
    this.sendTask = this.sendTask.bind(this)
  }
  sendTask(taskCard) {
    this.props.onShow(taskCard)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      taskCards: nextProps.taskCards
    })
  }
  render() {
    const { dropProvided, autoFocusTaskCardId, taskAppearanceProperties } = this.props
    const { taskCards } = this.state
    const title = this.props.title ? <Title>{this.props.title}</Title> : null

    return (
      <Container>
        {title}
        <DropZone ref={dropProvided.innerRef}>
          <InnerTaskCardList
            taskCards={taskCards}
            autoFocusTaskCardId={autoFocusTaskCardId}
            onShow={this.sendTask}
            taskAppearanceProperties={taskAppearanceProperties}
            uniqueUserName={this.props.uniqueUserName}
            format={this.props.format}
            openContent={this.props.openContent}
          />
          {dropProvided.placeholder}
        </DropZone>
      </Container>
    )
  }
}

export default class TaskCardList extends Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      taskCards: this.props.taskCards
    }
    this.sendTask = this.sendTask.bind(this)
    this.checkIfColumnIsAvailable = this.checkIfColumnIsAvailable.bind(this)
  }
  checkIfColumnIsAvailable(taskId) {
    const { statuses, listId, tasksAvailableNavigations } = this.props
    if (listId) {
      const task = this.props.getTask(taskId)
      const statusIdToMove = _.find(statuses, { Name: listId }).Id
      const isAvailable = _.find(tasksAvailableNavigations, {
        FromStatusId: task.StatusID,
        ToStatusId: statusIdToMove,
        TypeId: task.TypeId
      })
      if (isAvailable) return true
      else return false
    }
    return false
  }
  sendTask(taskCard) {
    this.props.onShow(taskCard)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      taskCards: nextProps.taskCards
    })
  }
  render() {
    const {
      ignoreContainerClipping,
      internalScroll,
      isDropDisabled,
      listId,
      listType,
      style,
      autoFocusTaskCardId,
      title,
      taskAppearanceProperties
    } = this.props
    const { taskCards } = this.state
    return (
      <div className={this.props.className}>
        <Droppable
          droppableId={listId}
          type={listType}
          ignoreContainerClipping={ignoreContainerClipping}
          isDropDisabled={isDropDisabled}
        >
          {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
            <Wrapper
              style={Object.assign(style || {}, {
                backgroundColor: dropSnapshot.isDraggingOver
                  ? this.checkIfColumnIsAvailable(dropSnapshot.draggingOverWith)
                    ? '#b3ffb3'
                    : '#ff8080'
                  : '#F3F3F4'
              })}
              isDraggingOver={dropSnapshot.isDraggingOver}
              isDropDisabled={isDropDisabled}
            >
              {internalScroll ? (
                <ScrollContainer>
                  <InnerList
                    taskCards={taskCards}
                    title={title}
                    dropProvided={dropProvided}
                    autoFocusTaskCardId={autoFocusTaskCardId}
                    onShow={this.sendTask}
                    taskAppearanceProperties={taskAppearanceProperties}
                    uniqueUserName={this.props.uniqueUserName}
                    format={this.props.format}
                    openContent={this.props.openContent}
                  />
                </ScrollContainer>
              ) : (
                <InnerList
                  taskCards={taskCards}
                  title={title}
                  dropProvided={dropProvided}
                  autoFocusTaskCardId={autoFocusTaskCardId}
                  onShow={this.props.onShow}
                  taskAppearanceProperties={taskAppearanceProperties}
                  uniqueUserName={this.props.uniqueUserName}
                  format={this.props.format}
                  openContent={this.props.openContent}
                />
              )}
            </Wrapper>
          )}
        </Droppable>
      </div>
    )
  }
}
