Ext.define('Spui.view.common.similarity.Main', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-similarity-main',
  mixins: ['Spui.view.common.similarity.Mixin'],
  layout: {
    type: 'vbox',
    align: 'stretch'
  },
  defaultListenerScope: true
})
