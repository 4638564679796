import { useEffect, useRef } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { v4 as uuidv4 } from 'uuid'
import { transform } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import { __RowIndex as SlvyRowIndex } from '@/store/slices/localData'
import { IRowDataGrid } from '../../../../types'
import { ActionBtnRegisterEventKey } from '../../registerEventsMethods.types'
import {
  ActionButtonsProps,
  ActionButtonsRef,
  ActionButtonClickedData
} from './useRegisterEventActionButtons.types'

function useRegisterEventActionButtons({
  columnConfigs,
  fieldConfigs,
  registerEvent
}: ActionButtonsProps) {
  const actionButtonsRef = useRef<ActionButtonsRef>({})

  useEffect(() => {
    const filteredColumnConfigs = columnConfigs.filter((column) => {
      return !!column.customConfigs.actionButton?.actionEnabled
    })

    filteredColumnConfigs.forEach((column) => {
      const {
        headerName: actionTitle,
        customConfigs: {
          actionButton: { actionName }
        }
      } = column

      const fn: ActionBtnRegisterEventKey = `handleActionClick_${actionName}`

      actionButtonsRef.current[fn] = registerEvent({
        key: `ActionClicked_${actionName}`,
        fn: (data: IRowDataGrid, rowIndex: ICellRendererParams['node']['rowIndex']) => {
          return transform(
            fieldConfigs,
            (result: ActionButtonClickedData, field) => {
              // eslint-disable-next-line no-param-reassign
              result[field.fieldName] = data[field.fieldName]
            },
            {
              _ActionTitle: actionTitle,
              _RefreshKey: uuidv4(),
              [SlvyRowIndex]: rowIndex
            }
          )
        },
        returnTypes: transform(
          fieldConfigs,
          (result: Record<string, any>, field) => {
            const { fieldName, dataType } = field || {}
            // eslint-disable-next-line no-param-reassign
            result[fieldName] = PluginTypes.fromString(dataType)
          },
          {
            _ActionTitle: PluginTypes.string,
            _RefreshKey: PluginTypes.string,
            [SlvyRowIndex]: PluginTypes.int
          }
        )
      })
    })
  }, [columnConfigs, fieldConfigs, registerEvent])

  return { actionButtonsRef }
}

export default useRegisterEventActionButtons
