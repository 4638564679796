import { CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import randomcolor from 'randomcolor'
import { IProfileProps } from './Profile.types'

const Profile = ({ catalogId, environment, familyName, name, sub }: IProfileProps) => {
  const url = `/${environment}/catalog/${catalogId}/profile`

  const avatarStyle: CSSProperties = {
    backgroundColor: randomcolor({
      luminosity: 'light',
      seed: sub
    })
  }

  return (
    <div className="sidebar__profile-elements" data-testid="profile">
      <Link to={url}>
        <span className="avatar" style={avatarStyle}>
          <span>{name.charAt(0)}</span>
          <span>{familyName.charAt(0)}</span>
        </span>
      </Link>
      <div className="user-info">
        <span className="username">{name}</span>
        <Link className="link" to={url}>
          <span>My Profile</span>
        </Link>
      </div>
    </div>
  )
}

export default Profile
