Ext.define('MDM.src.view.regionapp.model.MaterialAssigned', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/RMDM_Material_Assigned',
  fields: [
    {
      name: 'ReportingPeriodID',
      type: 'int'
    },
    {
      name: 'SupplySourceID',
      type: 'int'
    },
    {
      name: 'MaterialID',
      type: 'int'
    },
    {
      name: 'MaterialCode',
      type: 'string'
    },
    {
      name: 'MaterialDescription',
      type: 'string'
    },
    {
      name: 'MaterialBUOMID',
      type: 'int'
    },
    {
      name: 'MaterialTypeID',
      type: 'int'
    },
    {
      name: 'GU',
      type: 'float'
    },
    {
      name: 'OwnerID',
      type: 'int'
    },
    {
      name: 'SU',
      type: 'float'
    },
    {
      name: 'Category',
      type: 'string'
    },
    {
      name: 'Brand',
      type: 'string'
    },
    {
      name: 'ProductFormDetail',
      type: 'string'
    },
    {
      name: 'CSUIndicator',
      type: 'string'
    },
    {
      name: 'isEditable',
      type: 'boolean'
    },
    {
      name: 'DPIFamily',
      type: 'string'
    },
    {
      name: 'MegaRegion',
      type: 'string'
    },
    {
      name: 'ExcludedFromProduction',
      type: 'boolean'
    },
    {
      name: 'AppearsOnExtract',
      type: 'boolean'
    },
    {
      name: 'ExcludedFromRevenue',
      type: 'boolean'
    },
    {
      name: 'AppearOnExtractYesOrNo',
      convert: function (d, c) {
        return c.get('AppearsOnExtract') ? 'Yes' : 'No'
      },
      depends: ['AppearsOnExtract']
    },
    {
      name: 'ExcludedFromProductionYesorNo',
      convert: function (d, c) {
        return c.get('ExcludedFromProduction') ? 'Yes' : 'No'
      },
      depends: ['ExcludedFromProduction']
    },
    {
      name: 'ExcludedFromRevenueYesorNo',
      convert: function (d, c) {
        return c.get('ExcludedFromRevenue') ? 'Yes' : 'No'
      },
      depends: ['ExcludedFromRevenue']
    },
    {
      name: 'HierarchyText',
      convert: function (d, c) {
        return c.get('DPIFamily') + ' \u2192 ' + c.get('MegaRegion')
      },
      depends: ['DPIFamily', 'MegaRegion']
    }
  ]
})
