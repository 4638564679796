Ext.define('MDM.src.view.materialedit.Panel.MaterialEditForm', {
  extend: 'Ext.form.Panel',
  xtype: 'MaterialEditForm',
  bodyPadding: 5,
  reference: 'materialFrom',
  title: 'Material Form',
  trackResetOnLoad: true,
  fieldDefaults: {
    labelAlign: 'right',
    labelWidth: 90,
    msgTarget: Ext.supports.Touch ? 'side' : 'qtip'
  },
  items: [
    {
      xtype: 'container',
      height: 240,
      layout: {
        type: 'hbox'
      },
      items: [
        {
          xtype: 'fieldset',
          title: 'Material Info',
          defaultType: 'textfield',
          layout: 'anchor',
          flex: 1,
          defaults: {
            anchor: '100%'
          },
          items: [
            {
              fieldLabel: 'Code',
              xtype: 'textfield',
              flex: 1,
              allowBlank: false,
              bind: {
                readOnly: '{!isNew}'
              },
              name: 'MaterialCode',
              emptyText: 'Material code',
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ]
            },
            {
              fieldLabel: 'Description',
              name: 'MaterialDescription',
              allowBlank: false,
              bind: {
                readOnly: '{!isNew}'
              },
              flex: 2,
              emptyText: 'Material Description',
              xtype: 'textfield',
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ]
            },
            {
              fieldLabel: 'Category',
              name: 'Category',
              bind: {
                readOnly: '{!isNew}'
              },
              xtype: 'textfield',
              flex: 1
            },
            {
              fieldLabel: 'Brand',
              xtype: 'textfield',
              name: 'brand',
              bind: {
                readOnly: '{!isNew}'
              },
              flex: 2
            }
          ]
        },
        {
          xtype: 'container',
          width: 150,
          height: 202,
          layout: 'center',
          border: true,
          margin: '20 5 20 5',
          style: 'border: 1px solid #bbb !important; background:#fff',
          items: [
            {
              xtype: 'image',
              itemId: 'image',
              shrinkWrap: true,
              minWidth: 114,
              maxWidth: 200,
              height: 198,
              cls: 'photo-profile',
              src: 'https://cdn.voyager.pgsitecore.com/tr-tr/-/media/Gillette_TR/Images/Products/Fusion%20Proshield%20Razor%20with%20Flexball/gillette-fusion-proshield-razor-with-flexball-pl.jpg?h=280&la=tr-TR&w=114&v=1-201610061200'
            }
          ]
        }
      ]
    },
    {
      xtype: 'fieldset',
      title: '<b>Attributes</b>',
      reference: 'Attributes',
      defaultType: 'textfield',
      layout: 'anchor',
      defaults: {
        anchor: '100%'
      },
      flex: 1,
      items: [
        {
          xtype: 'textfield',
          fieldLabel: 'Form Detail',
          name: 'firstName',
          bname: 'ProductFormDetail',
          flex: 2,
          readOnly: true,
          bind: {
            readOnly: '{!!isNew}'
          }
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',
          items: [
            {
              fieldLabel: 'BUOM',
              xtype: 'combo',
              width: 300,
              name: 'MaterialBUOMID',
              valueField: 'MaterialBUOMID',
              displayField: 'MaterialBUOM',
              queryMode: 'local',
              allowBlank: false,
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ],
              bind: {
                store: '{MaterialBUOMStore}'
              }
            },
            {
              xtype: 'numberfield',
              hideTrigger: true,
              decimalPrecision: 6,
              minValue: 0,
              fieldLabel: 'CSU Ind.',
              name: 'CSUIndicator',
              bind: {
                readOnly: '{!isNew}'
              },
              flex: 1
            }
          ]
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',
          items: [
            {
              xtype: 'textfield',
              fieldLabel: 'Modified By',
              readOnly: true,
              disabled: true,
              flex: 1,
              allowBlank: false
            },
            {
              xtype: 'textfield',
              fieldLabel: 'Date',
              flex: 1,
              readOnly: true,
              disabled: true,
              forceSelection: true,
              enforceMaxLength: true,
              valueField: 'abbr',
              displayField: 'abbr',
              queryMode: 'local',
              allowBlank: false,
              listConfig: {
                minWidth: null
              }
            }
          ]
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',
          items: [
            {
              xtype: 'numberfield',
              fieldLabel: 'SU',
              name: 'SU',
              hideTrigger: true,
              decimalPrecision: 10,
              // step: 0.1,
              min: 0,
              flex: 1,
              allowBlank: false,
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ]
            },
            {
              name: 'MaterialTypeID',
              fieldLabel: 'Material Type',
              xtype: 'combo',
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ],
              triggerAction: 'all',
              forceSelection: true,
              editable: false,
              allowBlank: false,
              store: [
                ['2', 'FWIP'],
                ['1', 'FG']
              ],
              flex: 1
            }
          ]
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',
          items: [
            {
              fieldLabel: 'Source Name',
              name: 'SupplySourceID',
              flex: 1,
              allowBlank: true,
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ],
              queryMode: 'local',
              forceSelection: true,
              editable: false,
              xtype: 'comboboxwithclear',
              triggerAction: 'all',
              displayField: 'Description',
              valueField: 'SupplySourceID',
              bind: {
                store: '{SuplySourceStore}'
              }
            }
          ]
        },
        {
          xtype: 'container',
          layout: 'hbox',
          margin: '0 0 5 0',
          items: [
            {
              name: 'ExcludedFromProduction',
              flex: 1,
              margin: '0 0 0 120',
              boxLabel: 'Excluded from Production',
              valueField: 'ExcludedFromProduction',
              xtype: 'checkboxfield'
            },
            {
              name: 'ExcludedFromRevenue',
              flex: 1,
              margin: '0 0 0 120',
              boxLabel: 'Excluded from Revenue',
              valueField: 'ExcludedFromRevenue',
              xtype: 'checkboxfield'
            }
          ]
        }
      ]
    }
  ],
  listeners: {
    dirtychange: 'onFormDirty'
  },
  bbarh: {
    xtype: 'toolbar',
    reference: 'basic-statusbar',
    defaultText: 'Ready',
    text: 'Ready',
    iconCls: 'x-status-valid',
    items: [
      '->',
      {
        text: 'Save',
        disabled: true,
        iconCls: 'fa fa-floppy-o',
        reference: 'saveButton',
        formBind: true,
        handler: 'onSaveForm'
      },
      {
        text: 'Close',
        iconCls: 'fa fa-ban',
        handler: function (btn) {
          btn.up('window').destroy()
        }
      }
    ]
  }
})
