export default {
  title: 'Pdf Export',
  subTitle: '',
  version: '1.0',
  namespace: 'PdfExport',
  description: '',
  iconClass: 'fa-file-pdf-o',
  color: 'lazur-bg',
  group: 'Views'
}
