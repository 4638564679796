import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type SessionTypes = {
  sessionName: string
  isSessionListModalOpen: boolean
  isDiffScreenModalOpen: boolean
}

const initialState: SessionTypes = {
  sessionName: '',
  isSessionListModalOpen: false,
  isDiffScreenModalOpen: false
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionName: (state, action) => {
      state.sessionName = action.payload
    },
    setIsSessionListModalOpen(state, action: PayloadAction<boolean>) {
      state.isSessionListModalOpen = action.payload
    },
    setIsDiffScreenModalOpen(state, action: PayloadAction<boolean>) {
      state.isDiffScreenModalOpen = action.payload
    }
  }
})

export default sessionSlice
