Ext.define('tuiv2.cmp.SelectedAreaSummaryTmp', {
  extend: 'Ext.Component',
  xtype: 'tuiv2-selectedAreaSummaryTmp',
  draggable: true,
  initComponent: function () {
    var me = this
    me.data.viewIndex = 0
    me.data.viewType = 'order'
    me.callParent()
  },
  listeners: {
    click: {
      fn: function (event, tile) {
        var me = this.component,
          view = me.rootView,
          viewModel = view.getViewModel(),
          controller = view.getController(),
          data = me.getData(),
          demandIds = data.DemandZones.map(function (item) {
            return item.Id
          }),
          openedLoadCards = viewModel.get('openedLoadCards'),
          drawRoute = viewModel.get('drawRoute')

        if (controller.popups[me.popupId]) {
          controller.popups[me.popupId].bringToFront()
        }

        if (event.target.classList.contains('slvy-ui-icon-times-lt')) {
          var mainMap = me.rootView.down('mainMap'),
            map = mainMap.getMap()
          if (controller.popups[me.popupId]) {
            map.closePopup(controller.popups[me.popupId])
            delete controller.popups[me.popupId]
          } else {
            me.destroy()
          }

          if (me.loadToList) {
            controller.removeOptionCard(me.loadToList)
            me.loadToList.destroy()
          }
          return
        }

        if (event.target.classList.contains('changeView')) {
          data.viewIndex = data.viewIndex == 1 ? 0 : 1
          me.dataSet(data)
          return
        }

        // demandzone

        if (
          event.target.classList.contains('planFacility') ||
          event.target.classList.contains('planPallet')
        ) {
          var facilityKey = event.target.getAttribute('FacilityKey'),
            loadId = event.target.getAttribute('Id'),
            fromLoadId = event.target.getAttribute('FromLoadId'),
            palletId = event.target.getAttribute('palletId'),
            model = null

          var loadIds = [],
            loadIdsInData = []
          for (var property in controller.popups) {
            if (controller.popups.hasOwnProperty(property) && property.startsWith('load')) {
              loadIds.push(property.replace('load', ''))
            }
          }
          data.Loads.forEach(function (load) {
            loadIdsInData.push(load.Id)
          })
          loadIds = loadIds.map(function (item) {
            return item
          })

          loadIds = loadIds.concat(loadIdsInData)

          loadIds = _.uniq(loadIds)
          data.LoadIds = loadIds.map(function (id) {
            return {
              Id: id
            }
          })

          if (event.target.classList.contains('planFacility')) {
            if (!me.selectedFacilityKeys) me.selectedFacilityKeys = []

            let index = me.selectedFacilityKeys.indexOf(facilityKey)
            if (index > -1) {
              event.target.classList.remove('-selected')
              me.selectedFacilityKeys.splice(index, 1)
            } else {
              me.selectedFacilityKeys.push(facilityKey)
              event.target.classList.add('-selected')
            }
            model = {
              class: 'dot',
              Id: facilityKey,
              ltlLoadId: loadId,
              Loads: data.LoadIds.map(function (load) {
                return {
                  Color: tuiv2.Colors.getColorByName(load.Id),
                  Id: load.Id
                }
              }),
              FromLoadId: fromLoadId,
              UnloadClass: 'unloadDemandZone',
              ToLtlClass: 'loadFacilityToLTL',
              LoadClass: 'loadDemandZone',
              CloseClass: 'closeLoadToList'
            }

            if (me.selectedFacilityKeys.length > 0 && !me.loadToList) {
              me.loadToList = Ext.create({
                xtype: 'tuiv2-selectedAreaListTmp',
                renderTo: event.target.parentNode.parentNode.parentNode.parentNode.children[0],
                data: model,
                rootView: me.rootView
              })

              openedLoadCards.push(me.loadToList)
              viewModel.set('openedLoadCards', openedLoadCards)
            } else if (me.selectedFacilityKeys.length == 0 && me.loadToList) {
              controller.removeOptionCard(me.loadToList)
              me.loadToList.destroy()
              me.loadToList = undefined
            }
          } else {
            model = {
              class: 'square',
              Id: facilityKey,
              Loads: data.LoadIds.map(function (load) {
                return {
                  Color: tuiv2.Colors.getColorByName(load.Id),
                  Id: load.Id
                }
              }),
              palletId: palletId,
              FromLoadId: fromLoadId,
              UnloadClass: 'unloadPallet',
              ToLtlClass: 'loadPalletToLTL',
              ltlLoadId: loadId,
              LoadClass: 'loadPallet',
              CloseClass: 'closeLoadToList'
            }
          }

          return
        }

        if (event.target.classList.contains('closeDemandZonePlan')) {
          event.target.parentElement.parentElement.remove()

          return
        }

        if (event.target.classList.contains('unloadDemandZone')) {
          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to unserve demand zone?',
            function (reasonCode) {
              var facilityKey = me.selectedFacilityKeys
              var loadIds = []
              controller.onRemoveDemandZoneFromAllLoads(reasonCode, facilityKey, function (result) {
                if (result.IsSuccess) {
                  loadIds = data.Loads.map(function (load) {
                    return load.Id
                  })

                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], loadIds)
                  }
                }
              })
            },
            this
          )

          return
        }

        if (event.target.classList.contains('loadDemandZone')) {
          var targetLoadId = event.target.getAttribute('loadId')
          var facilityKey = me.selectedFacilityKeys

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to serve demand zone from ' + targetLoadId + '?',
            function (reasonCode) {
              controller.onSwitchDemandZoneToLoad(
                reasonCode,
                facilityKey,
                targetLoadId,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    var mergedDemandIs = $.extend([], demandIds)
                    mergedDemandIs.push(facilityKey)
                    controller.refreshRelatedPopup(mergedDemandIs, [targetLoadId])
                  }
                }
              )
            },
            this
          )

          return
        }

        if (event.target.classList.contains('loadFacilityToLTL')) {
          var facilityKey = me.selectedFacilityKeys,
            loadId = event.target.getAttribute('loadId')

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to convert LTL?',
            function (reasonCode) {
              controller.onLoadToLTL(
                reasonCode,
                facilityKey,
                loadId,
                null,
                null,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], [loadId])
                  }
                }
              )
            },
            this
          )

          return
        }

        if (event.target.classList.contains('openDemandCard')) {
          var facilityKey = event.target.getAttribute('FacilityKey')

          if (controller.popups['demand' + facilityKey]) {
            if (drawRoute) {
              controller.onDemandlayerclick(null, { latlng: null }, facilityKey)
              return
            } else {
              var latlng = viewModel.get('disabledMapLatlng')
              controller.onDemandlayerclick(null, { latlng: latlng }, facilityKey)
              return
            }
          }

          viewModel.set('isOperationInProgress', true)
          controller.openDemandPopUp(facilityKey)
          return
        }

        // load

        if (event.target.parentElement.classList.contains('loadOptions')) {
          data.viewIndex = 2
          me.dataSet(data)
          return
        }

        if (event.target.parentElement.classList.contains('selectedLoadOption')) {
          var currentElement = event.target.parentElement
          var currentId = currentElement.getAttribute('id')
          if (currentId == 'order') {
            data.viewIndex = 0
            data.viewType = 'order'
            data.LoadId = null
          } else {
            data.viewIndex = 3
            data.viewType = 'load'
            data.LoadId = currentId
          }

          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('loadChangeView')) {
          data.viewIndex = data.viewIndex == 4 ? 3 : 4
          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('openLoadCard')) {
          var mainmap = view.down('mainmap'),
            loadId = event.target.getAttribute('loadId'),
            viewModel = view.getViewModel(),
            loadStore = viewModel.get('loadTreeStore'),
            references = view.getReferences(),
            tree = references['loadTree'],
            treeView = tree.getView()

          if (controller.popups['load' + loadId]) {
            if (drawRoute) {
              controller.openLoadPopup(null, loadId)
              return
            } else {
              var latlng = viewModel.get('disabledMapLatlng')
              controller.openLoadPopup(latlng, loadId)
              return
            }
          }

          loadStore.load(function () {
            var node = loadStore.findNode('LoadName', loadId)

            treeView.lockedView.setChecked(node, true)
            // fires reset of demands.
            treeView.lockedView.ownerCt.ownerCt.fireEvent('itemclick', treeView, null, null, null, {
              getTarget: function () {
                return true
              }
            })
          })
          if (drawRoute) {
            controller.asyncCaller(
              function () {
                if (mainmap.loadStopDetails[loadId + '-0']) {
                  return true
                } else {
                  return false
                }
              },
              function () {
                controller.openLoadPopup(null, loadId)
              },
              400
            )
            return
          } else {
            var latlng = viewModel.get('disabledMapLatlng')
            controller.openLoadPopup(latlng, loadId)
            return
          }
        }

        // pallet

        if (event.target.classList.contains('closeLoadToList')) {
          controller.removeOptionCard(me.loadToList)
          me.loadToList.destroy()
          me.loadToList = undefined
          me.selectedFacilityKeys = []
          me.dataSet(data)
          return
        }

        if (event.target.classList.contains('unloadPallet')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var fromLoadId = event.target.getAttribute('FromLoadId')

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to load pallet?',
            function (reasonCode) {
              controller.onRemovePalletsFromLoad(
                reasonCode,
                facilityKey,
                null,
                fromLoadId,
                1,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], [fromLoadId])
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('loadPallet')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var loadId = event.target.getAttribute('LoadId')
          var fromLoadId = event.target.getAttribute('FromLoadId')

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to load pallet?',
            function (reasonCode) {
              controller.onServePalletOnLoad(
                reasonCode,
                facilityKey,
                null,
                1,
                loadId,
                fromLoadId,
                function (result) {
                  me.toastResult(result)
                  if (result.IsSuccess) {
                    controller.reloadLoadTreeStore()
                    controller.refreshRelatedPopup([facilityKey], [loadId, fromLoadId])
                  }
                }
              )
            },
            this
          )
          return
        }

        if (event.target.classList.contains('loadPalletToLTL')) {
          var facilityKey = event.target.getAttribute('FacilityKey')
          var loadId = event.target.getAttribute('FromLoadId')

          controller.createMessageBox(
            'Confirm',
            'Are you sure you want to convert LTL?',
            function (reasonCode) {
              controller.onLoadToLTL(reasonCode, facilityKey, loadId, null, 1, function (result) {
                me.toastResult(result)
                if (result.IsSuccess) {
                  controller.reloadLoadTreeStore()
                  controller.refreshRelatedPopup([facilityKey], [loadId])
                }
              })
            },
            this
          )

          return
        }
        if (event.target.classList.contains('slvy-ui-icon-select-all')) {
          var list = event.currentTarget.getElementsByClassName('planFacility')
          if (!event.currentTarget.classList.contains('checked')) {
            for (let i = 0; i < list.length; i++) {
              const element = list[i]
              if (!element.classList.contains('-selected')) {
                element.click()
              }
            }
            event.currentTarget.classList.add('checked')
          } else {
            for (let i = 0; i < list.length; i++) {
              const element = list[i]
              if (element.classList.contains('-selected')) {
                element.click()
              }
            }
            event.currentTarget.classList.remove('checked')
          }

          return
        }
      },
      element: 'el'
    }
  },
  tpl: new Ext.XTemplate(
    '<div class="card -demand {[this.getClass(values)]}">' + '<div class="loadToList"></div>',
    '<div class="titleBar ">' +
      '<p class="title">{Title}</p>' +
      '<div class="card-close"><span class="slvy-ui-icon-times-lt"></span></div>' +
      '</div>' +
      '    <div class="header -first">' +
      '      <div class="info">' +
      '         <div class="top">' +
      '           <ul class="demand-container">' +
      '             <li class="volume">{[this.formatNumber(values.TotalVolume)]}P</li>' +
      '             <li class="weight">{[this.formatNumber(values.TotalWeight)]} {[this.getUnit("BaseWeightUOM")]}</li>' +
      '             <li class="demand-pallet-count">{[this.formatNumber(values.TotalPallet, 0)]}P</li>' +
      '           </ul>' +
      '         </div>' +
      '        <div class="right">' +
      '        </div>' +
      '      </div>' +
      '    </div>' +
      // order toolbar
      '<tpl if="viewType == \'order\'">' +
      '    <div class="header -second -overview">' +
      '      <div class="info">' +
      '        <div class="left">' +
      '          <div class="id">Order List</div>' +
      '        </div>' +
      '        <div class="right loadOptions">' +
      '          <div class="down-icon loadOptions"><span class="slvy-ui-icon-caret-down"></span></div>' +
      '        </div>' +
      '      </div>' +
      '      <div class="action-container">' +
      '        <ul class="actions">' +
      '            <li><i class="slvy-ui-icon-select-all" data-qtip="Select All"></i></li>' +
      '          <li><i class="slvy-ui-icon-info" data-qtip="Info"></i></li>' +
      '        </ul>' +
      '      </div>' +
      '    </div>' +
      '</tpl>' +
      // load toolbar
      '<tpl if="viewType == \'load\'">' +
      '   <div class="header {[this.getClass(values)]} -first" style="background:{[this.getColorCode(values.LoadId)]};">' +
      '      <div class="info" style="background:{[this.getColorCode(values.LoadId)]};">' +
      '        <div class="left">' +
      '<tpl for="Loads">' +
      ' <tpl if="Id == parent.LoadId">' +
      '            <div class="id">{SubTitle1}</div>' +
      '            <div class="name">{SubTitle2}</div>' +
      '            <div class="distance">{[this.formatNumber(values.LoadedWeight)]} {[this.getUnit("BaseWeightUOM")]}</div>' +
      '            <div class="cost">{[this.formatNumber(values.Cost)]} {[this.getUnit("BaseCurrencyUOM")]}</div>' +
      ' </tpl>' +
      ' </tpl>' +
      '        </div>' +
      '        <div class="right loadOptions">' +
      '           <ul class="count-container">' +
      '           <tpl for="Loads">' +
      '            <tpl if="Id == parent.LoadId">' +
      '                          <li class="total-count">{[this.formatNumber(values.MaxPallet)]}P</li>' +
      '                          <li class="palette-count">{LoadedPallet}</li>' +
      '                          <li class="empty-count">{[values.MaxPallet - values.LoadedPallet]}</li>' +
      '            </tpl>' +
      '           </tpl>' +
      '           </ul>' +
      '          <div class="down-icon loadOptions"><span class="slvy-ui-icon-caret-down"></span></div>' +
      '        </div>' +
      '      </div>' +
      '      <div class="action-container">' +
      '         <ul class="actions">' +
      '            <li><i class="slvy-ui-icon-info" data-qtip="Info"></i></li>' +
      '            <li><i class="slvy-ui-icon-merge mergeOptions" data-qtip="Merge Another Load"></i></li>' +
      '          <li><i class="slvy-ui-icon-change"></i></li>' +
      '<tpl if="viewIndex == 3">' +
      '          <li><i class="slvy-ui-icon-grid ekol-1-grid loadChangeView"></i></li>' +
      '</tpl>' +
      '<tpl if="viewIndex == 4">' +
      '          <li><i class="slvy-ui-icon-list-regular ekol-1-list loadChangeView"></i></li>' +
      '</tpl>' +
      '        </ul>' +
      '      </div>' +
      '    </div>' +
      '</tpl>' +
      // demand view
      '<tpl if="viewIndex == 0">' +
      '     <div class="grid-container-tui">' +
      '<tpl for="DemandZones">' +
      '         <div class="point">' +
      '             <ul class="sq">' +
      '                 <li style="background:{[this.getDemandColor(values.Items)]}" class="planFacility" FacilityKey="{Id}" Id="{[this.getLoadIdForDemandZone(values.Items)]}" index={#}></li>' +
      '             </ul>' +
      '             <p id="{Id}"class="card-tooltip"><span class="tooltiptext">Name: <b>{[this.isNull(values.SubTitle)]}</b> </br> Duedate: <b>{[this.isNull(values.Description)]}</b> </br> Priority: <b>{Priority}</b> </br> Cases: <b>{[this.getTooltip(values.Items,"Pieces")]}</b> </br> Volume: <b>{[this.getTooltip(values.Items,"Volume")]}</b> </br> Weight: <b>{[this.getTooltip(values.Items,"Weight")]}</b> </br> Lademeter: <b>{[this.getTooltip(values.Items,"Lademeter")]}</b> </br></span>{Title}</p>' +
      '<i class="slvy-ui-icon-export-box openDemandCard" style="float: right;font-size: 12px;right: 10px;position: absolute;top: 5px;" FacilityKey="{Id}"> </i>' +
      '         </div>' +
      '         <ul class="card-grid">' +
      '<tpl exec="values.parent = parent;"></tpl>' +
      '<tpl for="this.getPalletArray(values.Items, values.Id)">' +
      '             <li  class="{[values.class == "-unplanned" ? "" : values.class]}"><div class="planPallet {[values.class == "-unplanned" ? "-unplanned" : ""]}" FacilityKey="{parent.Id}" Id="{loadId}" FromLoadId="{loadId}" style="width:{palletWidth}%;background:{color};"></div>' +
      '             </li>' +
      '</tpl>' +
      '         </ul>' +
      '</tpl>' +
      '     </div>' +
      '</tpl>' +
      // demand view as pallette
      '<tpl if="viewIndex == 1">' +
      '     <div class="grid-container-tui">' +
      '      <ul class="card-grid">' +
      '<tpl for="this.getPalletContainer(values.DemandZones)">' +
      '         <li  class="{[values.class == "-unplanned" ? "" : values.class]}"></li>' +
      '</tpl>' +
      '      </ul>' +
      '    </div>' +
      '</tpl>' +
      // load options view
      '<tpl if="viewIndex == 2">' +
      '   <div class="grid-container-tui dropdown-menu-tui">' +
      '    <div class="point link-overview selectedLoadOption" id="order">' +
      '      <ul class="sq"><li class=""></li></ul>' +
      '      <p>Order List</p>' +
      '    </div>  ' +
      '<tpl for="Loads">' +
      '    <div class="point link-1 selectedLoadOption" id="{Id}">' +
      '      <ul class="sq"><li class="" style="background:{[this.getColorCode(values.Id)]};"></li></ul>' +
      '      <p>{SubTitle1} / {SubTitle2} <i class="slvy-ui-icon-export-box openLoadCard" style="float: right; padding-left: 5px;" loadId="{Id}""></i> </p>' +
      '      <p class="p-size">{[this.formatNumber(values.MaxPallet)]}P</p>' +
      '    </div>  ' +
      '</tpl>' +
      '  </div>' +
      '</tpl>' +
      // load view
      '<tpl if="viewIndex == 3">' +
      '     <div class="grid-container-tui">' +
      '<tpl for="DemandZones">' +
      '         <div class="point">' +
      '             <ul class="sq">' +
      '                 <li style="background:{[this.getDemandColor(values.Items)]}" class="planFacility" FacilityKey="{Id}" Id="{[this.getLoadIdForDemandZone(values.Items)]}" index={#}></li>' +
      '             </ul>' +
      '             <p class="card-tooltip"><span class="tooltiptext"> Name: <b>{[this.isNull(values.SubTitle)]}</b> </br> Duedate: <b>{[this.isNull(values.Description)]}</b> </br> Priority: <b>{Priority}</b> </br> Cases: <b>{[this.getTooltip(values.Items,"Pieces")]}</b> </br> Volume: <b>{[this.getTooltip(values.Items,"Volume")]}</b> </br> Weight: <b>{[this.getTooltip(values.Items,"Weight")]}</b> </br> Lademeter: <b>{[this.getTooltip(values.Items,"Lademeter")]}</b> </br></span>{Title}</p>' +
      '         </div>' +
      '         <ul class="card-grid">' +
      '<tpl exec="values.parent = parent;"></tpl>' +
      '<tpl for="this.getPalletArray2(values.Items, parent.LoadId, values.Id)">' +
      '             <li  class="{[values.class == "-unplanned" ? "" : values.class]}"><div class="planPallet {[values.class == "-unplanned" ? "-unplanned" : ""]}" FacilityKey="{parent.Id}" Id="{loadId}" FromLoadId="{loadId}" palletId="{palletId}" style="width:{palletWidth}%;background:{color};"></div>' +
      '               <ul class="popup -sq" style="display:{[this.isPalletVisible(values.palletId, parent.parent.VisiblePalletOptionId)]}">' +
      '                   <li><span class="slvy-ui-icon-times-extra-lt closePalletOptions"></span></li>' +
      '<tpl exec="values.parent = parent;"></tpl>' +
      '<tpl for="parent.parent.Loads">' +
      '                   <li style="background:{[this.getColorCode(values.Id)]}" class="loadPallet" LoadId="{Id}" FromLoadId="{parent.loadId}" FacilityKey="{parent.parent.parent.Id}"></li>' +
      '</tpl>' +
      '                   <li><span class="slvy-ui-icon-trash-can unloadPallet" FacilityKey="{parent.Id}" FromLoadId="{loadId}"></span></li>' +
      '                   <li><span class="slvy-ui-icon-truck loadPalletToLTL" FacilityKey="{parent.Id}" FromLoadId="{loadId}"></span></li>' +
      '               </ul>' +
      '             </li>' +
      '</tpl>' +
      '         </ul>' +
      '</tpl>' +
      '     </div>' +
      '</tpl>' +
      // load pallet view
      '<tpl if="viewIndex == 4">' +
      '     <div class="grid-container-tui">' +
      '         <ul class="card-grid">' +
      '<tpl for="this.getPalletForLoad(values.DemandZones, values.Loads, values.LoadId)">' +
      '             <li  class="{[values.class == "-unplanned" ? "" : values.class]}"></li>' +
      '</tpl>' +
      '         </ul>' +
      '     </div>' +
      '</tpl>' +
      '  </div>',
    {
      getPalletContainer: function (orders) {
        var items = orders.reduce((a, b) => a.concat(b.Items), [])
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        var array = []
        for (let index = 0; index < items.length; index++) {
          const item = items[index]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned',
                loadId: item.LoadId
              })
            } else if (item.LoadId != null && item.LoadId.length > 0) {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg',
                loadId: item.LoadId
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9',
                loadId: item.LoadId
              })
            }
            palletCount = palletCount - 1
          }
        }

        return array
      },
      getPalletArray: function (items, facilityKey) {
        var array = []
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        items = items.sort(function (item) {
          return item.LoadId ? -1 : 1
        })

        var loopCount = 0
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned',
                palletId: loopCount + '-' + facilityKey,
                loadId: item.LoadId
              })
            } else if (item.LoadId != null && item.LoadId.length > 0) {
              array.push({
                palletWidth: 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg',
                palletId: loopCount + '-' + facilityKey,
                loadId: item.LoadId
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9',
                palletId: loopCount + '-' + facilityKey,
                loadId: item.LoadId
              })
            }

            palletCount = palletCount - 1

            loopCount++
          }
        }
        return array
      },
      getPalletArray2: function (items, loadId, facilityKey) {
        var array = []
        items = items.sort(function (item) {
          return item.IsPlanned ? -1 : 1
        })
        items = items.sort(function (item) {
          return item.LoadId ? -1 : 1
        })
        var loopCount = 0
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          var palletCount = item.NumPallet
          while (palletCount > 0) {
            if (!item.IsPlanned) {
              array.push({
                palletWidth: 100,
                color: 'transparent',
                class: '-unplanned',
                palletId: loopCount + '-' + facilityKey,
                loadId: item.LoadId
              })
            } else if (item.LoadId == loadId) {
              array.push({
                palletWidth: 100,
                color: tuiv2.Colors.getColorByName(item.LoadId),
                class: '-percent-bg',
                palletId: loopCount + '-' + facilityKey,
                loadId: item.LoadId
              })
            } else {
              array.push({
                palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
                color: '#d9d9d9', // class: '-'
                palletId: loopCount + '-' + facilityKey,
                loadId: item.LoadId
              })
            }

            palletCount = palletCount - 1

            loopCount++
          }
        }

        return array
      },

      getLoadedPalletCount: function (items, loadId) {
        var items = items.reduce((a, b) => a.concat(b.Items), [])
        var allLoadPallets = items.filter(function (item) {
          return item.LoadId == loadId
        })

        return allLoadPallets.reduce((a, b) => a + b.NumPallet, 0)
      },
      getPalletForLoad: function (items, loads, loadId) {
        var items = items.reduce((a, b) => a.concat(b.Items), [])
        var allLoadPallets = items.filter(function (item) {
          return item.LoadId == loadId
        })

        var sumOfLoadPallet = allLoadPallets.reduce((a, b) => a + b.NumPallet, 0)
        var currentLoad = loads.filter(function (item) {
          return item.Id == loadId
        })[0]
        var palletCount = currentLoad.MaxPallet
        var loadedPallet = currentLoad.LoadedPallet - sumOfLoadPallet

        var array = []
        while (palletCount > 0) {
          if (sumOfLoadPallet > 0) {
            array.push({
              palletWidth: palletCount >= 1 ? 100 : palletCount * 100,
              color: tuiv2.Colors.getColorByName(loadId),
              class: '-percent-bg'
            })
            sumOfLoadPallet--
            palletCount--
          } else if (loadedPallet > 0) {
            array.push({
              palletWidth: 100,
              color: '#d9d9d9' // class: '-'
            })
            loadedPallet--
            palletCount--
          } else {
            array.push({
              palletWidth: 100,
              color: 'transparent',
              class: '-empty'
            })

            palletCount--
          }
        }
        return array
      },
      getDemandColor(items) {
        var loadIds = items.map(function (item) {
          return item.LoadId
        })

        loadIds = loadIds.filter(function (item) {
          if (item) return true

          return false
        })
        loadIds = _.uniq(loadIds)

        if (loadIds.length == 1) {
          return tuiv2.Colors.getColorByName(loadIds[0])
        } else {
          return '#d9d9d9'
        }
      },
      getColorCode: function (id) {
        return tuiv2.Colors.getColorByName(id)
      },
      formatNumber: function (value, digit) {
        if (value == Number.NaN || value == Number.MAX_VALUE) value = 0

        if (digit == 0) {
          return Ext.util.Format.number(value, '0,000')
        }

        if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

        return Ext.util.Format.number(value, '0,000.00')
      },
      isVisible: function (visible) {
        return visible ? 'block' : 'none'
      },
      isPalletVisible: function (palletId, visiblePalletId) {
        return palletId == visiblePalletId ? 'block' : 'none'
      },
      getLoadIdForDemandZone(items) {
        if (!items) return

        var loadIds = items.map(function (item) {
          return item.LoadId
        })

        loadIds = loadIds.filter(function (item) {
          if (item) return true

          return false
        })
        loadIds = _.uniq(loadIds)
        if (loadIds.length == 1) {
          return loadIds[0]
        } else {
          return null
        }
      },
      isNull: function (value) {
        if (!value) {
          return '-'
        } else {
          return value
        }
      },
      getTooltip: function (data, value) {
        var stringTooltip = '',
          numberTooltip = 0

        if (data.length == 1) {
          if (typeof data[0][value] == 'string') {
            stringTooltip += data[0][value] + ','
          } else {
            numberTooltip += data[0][value]
          }
        } else {
          data.forEach((item) => {
            if (typeof item[value] == 'string') {
              stringTooltip += item[value] + ','
            } else {
              numberTooltip += item[value]
            }
          })
        }

        if (stringTooltip != '' && stringTooltip.includes(',')) {
          return stringTooltip.slice(0, stringTooltip.length - 1)
        } else {
          return this.formatNumber(numberTooltip)
        }
      },
      getClass: function (values) {
        return tuiv2.Colors.getClassByColor(tuiv2.Colors.getColorByName(values.LoadId))
      },
      getUnit: function (value) {
        var planInfo = tuiv2.Units.getPlanInfo()
        if (planInfo) {
          return planInfo[value]
        }
      }
    }
  ),

  toastResult: function (result) {
    if (result.IsSuccess) {
      Ext.toast({
        html: 'Operation is completed successfully.',
        title: 'Information',
        width: 300,
        align: 'tr',
        iconCls: 'fa fa-check',
        closable: true,
        timeout: 4000
      })
    } else {
      Ext.toast({
        html: 'Operation failed',
        title: 'Error',
        width: 300,
        align: 'tr',
        iconCls: 'fa fa-cross',
        closable: true,
        timeout: 4000
      })
    }
  },
  refresh: function () {
    var me = this,
      view = me.rootView,
      controller = view.getController(),
      data = me.getData()

    controller.onRetrieveSelectedAreaDetails(me.coordinates, function (result) {
      data = result
      data.viewIndex = 0
      data.viewType = 'order'
      me.dataSet(data)
      return
    })
  },
  dataSet: function (data) {
    var me = this,
      view = me.rootView,
      controller = view.getController()
    if (me.loadToList) {
      controller.removeOptionCard(me.loadToList)
      me.loadToList.destroy()
      me.loadToList = undefined
    }

    me.setData(data)
    me.selectedFacilityKeys = []
  },
  formatNumber: function (value) {
    if (value == Number.NaN || value == Number.MAX_VALUE) value = 0
    if (value % 1 == 0) return Ext.util.Format.number(value, '0,000')

    return Ext.util.Format.number(value, '0,000.00')
  }
})
