import React, { useRef, useEffect } from 'react'
import cx from 'classnames'
import { OverlayTrigger } from '../../..'
import Title from './Title/index'
import { IProps } from './ScenarioName.types'
import { usePrevious } from '../../../../hooks/index.ts'

export function ScenarioName({
  extraClass,
  isChildrenFiltered,
  isFilterActive,
  isNested,
  isRowClickedEventOn,
  isWithBaseIconVisible,
  isWithGroupedIconVisible,
  name,
  onRowClick,
  onToggleScenariosWithChildren,
  onFilterScenariosChildren
}: IProps) {
  const pinnedScenario = useRef<null | HTMLSpanElement>(null)
  const prevIsChildrenFiltered = usePrevious(isChildrenFiltered)

  useEffect(() => {
    if (!isChildrenFiltered && prevIsChildrenFiltered) {
      pinnedScenario?.current?.scrollIntoView?.({ block: 'center' })
    }
  }, [isChildrenFiltered])

  const filterText = isChildrenFiltered ? 'Remove Filter' : `Filter by ${name}`

  return (
    <div
      className={cx(`cell -stick-to-left -cell-title ${extraClass}`, {
        'triple-column': isWithBaseIconVisible
      })}
      onClick={isNested ? () => {} : onToggleScenariosWithChildren}
    >
      <Title
        isWithBaseIconVisible={isWithBaseIconVisible}
        isWithGroupedIconVisible={isWithGroupedIconVisible}
        name={name}
        slotForChildren={isNested ? 'left' : 'right'}
      >
        {isNested && !(isFilterActive && !isChildrenFiltered) ? (
          <OverlayTrigger placement="right" tooltip={filterText}>
            <span
              ref={pinnedScenario}
              className={cx('filter-by-child-icon', 'fa-fw fa fa-map-pin', {
                'text-warning': isChildrenFiltered
              })}
              onClick={() => onFilterScenariosChildren(!isFilterActive)}
            />
          </OverlayTrigger>
        ) : null}
        {isRowClickedEventOn ? (
          <OverlayTrigger tooltip="Row Click">
            <span className="position-relative order-2">
              <input
                className="row-clicked-event-input"
                name="comparison-grid-row"
                type="radio"
                onClick={(event: any) => onRowClick(event)}
              />
              <span className="slvy-ui-icon-arrow-right-circle row-clicked-event-icon" />
            </span>
          </OverlayTrigger>
        ) : null}
      </Title>
    </div>
  )
}
