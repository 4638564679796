/**
 * Serie class for pyramid series type
 *
 * See {@link Chart.ux.Highcharts.FunnelSerie} class for more info
 */
Ext.define('Chart.ux.Highcharts.PyramidSerie', {
  extend: 'Chart.ux.Highcharts.FunnelSerie',
  alternateClassName: ['highcharts.pyramid'],
  type: 'pyramid'
})
