import {
  AlphaPicker,
  BlockPicker,
  ChromePicker,
  CirclePicker,
  CompactPicker,
  GithubPicker,
  HuePicker,
  MaterialPicker,
  PhotoshopPicker,
  SketchPicker,
  SliderPicker,
  SwatchesPicker,
  TwitterPicker
} from 'react-color'
export const defaultColor = {
  bg: 'rgba(255,255,255,0)',
  rgb: { r: 255, g: 255, b: 255, a: 0 }
} as const

export const presetColors = [
  '#F8F8F8',
  '#FC5C65',
  '#45AAF2',
  '#26DE81',
  '#FD9644',
  '#FED330',
  '#4A90E2',
  '#2BCBBA',
  '#778CA3',
  '#EB3B5A',
  '#2D98DA',
  '#20BF6B',
  '#FA8231',
  '#F7B731',
  '#3867D6',
  '#4B6584'
]

export const pickers = {
  alpha: AlphaPicker,
  block: BlockPicker,
  chrome: ChromePicker,
  circle: CirclePicker,
  compact: CompactPicker,
  github: GithubPicker,
  hue: HuePicker,
  material: MaterialPicker,
  photoshop: PhotoshopPicker,
  sketch: SketchPicker,
  slider: SliderPicker,
  swatches: SwatchesPicker,
  twitter: TwitterPicker
}
