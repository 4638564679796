export default {
  title: 'Sencha Grid',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'SenchaGrid',
  description: '***OBSOLETE*** (use Ag-Grid)',
  iconClass: 'fa-table',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Data Manipulation',
  queryStatus: {
    isInsertActive: true,
    isUpdateActive: true,
    isDeleteActive: true
  }
}
