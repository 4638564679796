Ext.define('MDM.src.view.globalapp.Panel.Dpi.PAnel.DPISegment', {
  extend: 'MDM.src.ux.GridPanel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-dpisegment',
  title: 'DPI Segments',
  iconCls: 'fa fa-sitemap',
  rowLines: true,
  columnLines: true,
  config: {
    readOnlyMode: false
  },
  cls: 'no-drop-indicator',
  border: true,
  listeners: {
    select: 'onSelectDPISegment'
  },
  tools: [
    {
      type: 'plus',
      tooltip: 'Add new DPISegment',
      // hidden:true,
      handler: 'onAddDPISegment',
      bind: {
        hidden: '{readOnlyMode}'
      }
    }
  ],
  plugins: [
    {
      ptype: 'uxcellediting',
      clicksToEdit: 2
    }
  ],
  viewConfig: {
    stripeRows: false,
    plugins: [
      {
        ptype: 'uxgridviewdragdrop',
        id: 'dd',
        containerScroll: true,
        enableDrag: false,
        dropGroup: 'dd-grid-to-grid-group1',
        dropZone: {
          overClass: 'dd-over-gridview'
        }
      }
    ],
    listeners: {
      beforedrop: 'onbeforeDropToSegment'
    }
  },
  features: [
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      text: 'DPI Segment',
      dataIndex: 'DPISegment',
      menuDisabled: true,
      editor: {
        allowBlank: false
      },
      flex: 2,
      summaryType: 'count',
      summaryRenderer: function (value) {
        return Ext.String.format('<b>{0} DPI Segment{1}</b>', value, value > 1 ? 's' : '')
      }
    },
    {
      text: 'Product Group Cnt',
      tooltip: 'Product Group Count',
      menuDisabled: true,
      dataIndex: 'ProductGroupCount',
      align: 'right',
      summaryType: 'sum',
      flex: 1,
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      text: 'DPI Family Cnt',
      tooltip: 'DPI Family Count',
      dataIndex: 'DPIFamilyCount',
      menuDisabled: true,
      align: 'right',
      summaryType: 'sum',
      flex: 1,
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      align: 'center',
      menuDisabled: true,
      bind: {
        hidden: '{readOnlyMode}'
      },
      items: [
        {
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete DPI Segment',
          handler: 'onDPISegmentDelete'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      align: 'center',
      bind: {
        hidden: '{readOnlyMode}'
      },
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          tooltip: 'Edit',
          handler: 'onEditDPISegment'
        }
      ]
    }
  ]
})
