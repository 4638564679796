import { PluginProps } from '@/BasePlugin/components/PluginProps.types'
import { selectGeneralConfigs, selectConfigColumns, selectGridConfigs } from './schema-mappings'
import { emptyArray, emptyObject } from './constants'

const selectConnectorProps = (props: PluginProps) => {
  const $columns = props.settings.config?.columns

  const settings = {
    ...props.settings,
    // Note: config is an empty object when the plugin is first added
    config: {
      ...props.settings.config,
      cellDisplayRules: props.settings.config?.cellDisplayRules ?? emptyArray,
      columns: $columns ? selectConfigColumns($columns) : undefined,
      general: selectGeneralConfigs(props.settings.config?.general ?? emptyObject),
      grid: selectGridConfigs(props.settings.config?.grid ?? emptyObject),
      rowDisplayRules: props.settings.config?.rowDisplayRules ?? emptyArray
    }
  }

  return {
    actualFilters: props.actualFilters,
    additionalArgs: props.additionalArgs,
    clearCaches: props.clearCaches,
    data: props.data,
    fetchPageData: props.fetchPageData,
    formatValue: props.formatValue,
    getFieldType: props.getFieldType,
    getFormattedValue: props.getFormattedValue,
    getPageSize: props.getPageSize,
    getTotalPage: props.getTotalPage,
    getTotalRowCount: props.getTotalRowCount,
    id: props.id,
    isAllowed: props.isAllowed,
    isMaximized: props.isMaximized,
    isPagedQuery: props.isPagedQuery,
    isPreviewMode: props.isPreviewMode,
    onReady: props.onReady,
    pluginData: props.pluginData,
    isPluginDataLoaded: props.isPluginDataLoaded,
    isPluginDataLoading: props.isPluginDataLoading,
    registerAuthorizations: props.registerAuthorizations,
    registerEvent: props.registerEvent,
    registerMethod: props.registerMethod,
    reportExportInfo: props.reportExportInfo,
    schema: props.schema,
    setDataArguments: props.setDataArguments,
    settings,
    token: props?.token ?? ''
  }
}

export default selectConnectorProps
