Ext.define('MDM.Btlv2.util.NumberCol', {
  extend: 'Ext.grid.column.Number',
  alias: ['widget.btlnumber'],
  requires: ['Ext.util.Format'],

  defaultFilterType: 'number',
  /**
   * @cfg {String} format
   * A formatting string as used by {@link Ext.util.Format#number} to format a numeric
   * value for this Column.
   * @locale
   */
  format: '0,000.000',
  align: 'right',
  editable: true,
  width: 110,
  menuDisabled: true,
  sortable: false,
  resizable: false,
  draggable: false,
  defaultRenderer: function (value, metaData, record) {
    var format = this.format
    if (record.get('Title') == 'DFC Target') format = '0'
    if (record.get('Title') == 'DFC Calculated') format = '0,000.0'
    if (!value) return ''
    if (value == 0) return ''
    if (value > 0 && value < 0.0000001) return ''
    if (value < 0 && value > -0.0000001) return ''
    if (record.get('MaterialType') == 'Summary') {
      return Ext.util.Format.number(value, format)
    }
    if (value < 0)
      return '<span style="color:red">' + Ext.util.Format.number(value, format) + '</span>'
    return Ext.util.Format.number(value, format)
  },
  summaryType: 'sum',
  summaryRenderer: function (value) {
    if (value == 0) return '.'
    return '<b>' + Ext.util.Format.number(value, this.format) + '</b>'
  },
  initComponent: function () {
    var me = this
    if (me.editable) {
      me.editor = {
        xtype: 'numberfield',
        hideTrigger: true,
        mouseWheelEnabled: false,
        decimalPrecision: 6
      }
    }
    ;(me.align = 'right'), me.callParent(arguments)
  }
})
