import { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'
import { FooterBtnRegisterEventKey } from '../../registerEventsMethods.types'
import {
  FooterButtonsRef,
  RegisterEventFooterButtonsProps
} from './useRegisterEventFooterButtons.types'

function useRegisterEventFooterButtons({
  footerButtons,
  registerEvent
}: RegisterEventFooterButtonsProps) {
  const footerButtonsRef = useRef<FooterButtonsRef>({})

  useEffect(() => {
    footerButtons
      .filter(({ buttonText }) => buttonText)
      .forEach(({ buttonText }) => {
        const fn: FooterBtnRegisterEventKey = `handleFooterButtonClick_${buttonText}`
        footerButtonsRef.current[fn] = registerEvent({
          key: `FooterButtonClick_${buttonText}`,
          fn: () => ({ refreshKey: uuidv4() }),
          returnTypes: { refreshKey: PluginTypes.string }
        })
      })
  }, [footerButtons, registerEvent])

  return { footerButtonsRef }
}

export default useRegisterEventFooterButtons
