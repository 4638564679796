export default `({
    controller: {
        onApproveSpot: function () {
            var me = this;
            console.log('Approve Click')
            var record = me.view.ownerCt.record;
            var mainView = me.view.ownerCt.mainView;
            var scope = me.view.ownerCt.scope;
            Ext.Msg.show({
                title: tt('Onay'),
                width: 500,
                msg: tt('Dağıtım Miktarlarını SAP ye Göndermek İstiyor Musunuz?'),
                buttonText: {
                    yes: tt('Evet'),
                    no: tt('Hayır')
                },
                buttons: Ext.Msg.YESNO,
                icon: Ext.MessageBox.QUESTION,
                fn: function (response) {
                    if (response === 'yes') {
                        scope.up('spui-view-tracking-tracking').setLoading(tt('Yükleniyor'));
                        Ext.Ajax.request({
                            url: Spui.Global.getApiUrl() + '/spui/StoreDistribution',
                            method: 'POST',
                            timeout: 600000,
                            params: {
                                spotId: record.get('SpotId'),
                            },
                            success: function (response, opts) {
                                var obj = Ext.decode(response.responseText);
                                scope.up('spui-view-tracking-tracking').setLoading(false);
                                scope.up('spui-view-tracking-navigation-main').onRefrehTracking()
                                mainView.fireEvent('distributionButtonClick')
                            },
                            failure: function (response, opts) {
                                console.log('server-side failure with status code ' + response.status);
                                scope.up('spui-view-tracking-tracking').setLoading(false);
                                Ext.MessageBox.show({
                                    title: 'Warning',
                                    message: '<br>Action failed<br>',
                                    buttons: Ext.Msg.OK,
                                    width: 300,
                                    icon: Ext.Msg.INFO,
                                    details: ''
                                })
                            }
                        })
                        me.doClose()
                    }
                }
            })
        },
        onReCalcuulate: function (field, f, e, g) {
            var me = this,
                recalculateField = me.lookupReference('recalculateValue'),
                recalculateValue = 0;
            if (recalculateField) {
                recalculateValue = recalculateField.getValue();
            }
        },
        doClose: function () {
            this.getView().ownerCt.close();
        },
    },
    xtype: 'grid',
    reference: 'grid',
    layout: 'fit',
    bind: {
        store: '{gridStore}'
    },
    dockedItems: [
        {
            xtype: 'toolbar',
            docked: 'top',
            height: 44,
            border: true,
            items: [
            {
                xtype: 'button',
                text: tt('Export to Excel'),
                docked: 'right',
                handler: function (btn) {
                    var grid = this.ownerCt.ownerCt
                    grid.saveDocumentAs({
                        type: 'xlsx',
                        title: tt('Distribution'),
                        fileName: tt('Distribution')+'.xlsx',
                    });
                },
            },
            {
                xtype: 'label',
                html: tt('DistributionCount: ')
            },
            
            {
                xtype: 'label',
                bind:{
                    text:'{DistributionCount}'
                }
            },
            '->',{
                xtype: 'checkboxfield',
                fieldLabel: tt('Use Mini Stores'),
                docked: 'right',
                bind:{
                    miniStores: '{useMiniStores}',
                    disabled:'{buttonsAreDisabled}'
                },
                setMiniStores:function(s)
                {
                    this.setRawValue(s)
                },
                onChange: function (newValue,oldValue) {
                    var me = this,
                    viewModel=me.ownerCt.ownerCt.getViewModel();

                    me.up('uxWindow').mask()

                    var settingsId = viewModel.get('SettingsId');

                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/UseMiniStoresUpdateForStoreDistributionSetting',
                        method: 'POST',
                        params: {
                            useMiniStores: newValue,
                            id:settingsId
                        },
                        suspendEvents:false,
                        success: function (response, opts) {
                            me.ownerCt.ownerCt.store.getProxy().setExtraParam('useMiniStores',newValue)  
                            me.ownerCt.ownerCt.store.reload()
                            me.ownerCt.ownerCt.up().unmask()
                        },
                        failure: function (response, opts) {
                            
                            Ext.Msg.alert(
                                tt('Durum'),
                                tt('İşlem Gerçekleştirilirken bir hata oluştu')
                            )
                            console.log('server-side failure with status code ' + response.status);

                            
                        },
                        async: true
                    });
                    
                    viewModel.set('useMiniStores',newValue)

                    
                },
            },
            {
                xtype:'label',
                style: {
                    color: 'red'
                },
                bind:
                {
                    text:'{labelText}'
                }
            },
            {
                xtype: 'checkboxfield',
                fieldLabel: tt('Use Store Stock'),
                docked: 'right',
                bind:{
                    storeStock: '{useStoreStock}',
                    disabled:'{buttonsAreDisabled}'
                },
                setStoreStock:function(s)
                {
                    this.setRawValue(s)
                },
                onChange: function (newValue,oldValue) {
                    var me = this,
                    viewModel=me.ownerCt.ownerCt.getViewModel();

                    me.up('uxWindow').mask()

                    var settingsId = viewModel.get('SettingsId');

                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/UseStoreStockUpdateForStoreDistributionSetting',
                        method: 'POST',
                        params: {
                            useStoreStock: newValue,
                            id:settingsId
                        },
                        suspendEvents:false,
                        success: function (response, opts) {
                            me.ownerCt.ownerCt.store.getProxy().setExtraParam('useStoreStock',newValue)  
                            me.ownerCt.ownerCt.store.reload()
                            me.ownerCt.ownerCt.up().unmask()
                        },
                        failure: function (response, opts) {
                            
                            Ext.Msg.alert(
                                tt('Durum'),
                                tt('İşlem Gerçekleştirilirken bir hata oluştu')
                            )
                            console.log('server-side failure with status code ' + response.status);

                            
                        },
                        async: true
                    });
                    
                    viewModel.set('useStoreStock',newValue)

                    
                },
            }
        ],
        },
        {
            xtype: 'toolbar',
            dock: 'bottom',
            margin: 1,
            items: ['->',
                {
                    text: tt('SAP'),
                    iconCls: 'x-fa fa-paper-plane',
                    ui: 'btn-success',
                    handler: 'onApproveSpot',
                    bind: {
                        visible: '{canUseSAP}',
                        disabled:'{buttonsAreDisabled}'
                    },
                },
                {
                    text: tt('Kapat'),
                    ui: 'btn-success',
                    handler: 'doClose'
                }
            ]
        }
    ],
    listeners: {
        render: 'onRender'
    },
    features: [{
        ftype: 'summary',
        dock: 'top'
    }],
    plugins: [{
        ptype: 'gridexporter'
    }],
    columns: [{
            text: tt('Name'),
            dataIndex: 'Name',
            width: 120,
            align: 'left',
            locked: true,
            summaryType: 'count',
        },
        {
            text: tt('StatusDescription'),
            dataIndex: 'StatusDescription',
            locked: true,
            width: 120
        },
        {
            text: tt('Store Code'),
            dataIndex: 'StoreCode',
            width: 100,
            align: 'center',
            locked: true
        },
        {
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            hidden: true,
        },
        {
            text: tt('NumberOfstore'),
            columns: [{
                    text: tt('All'),
                    dataIndex: 'NumberofStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            v = parseInt(v)
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                },
                {
                    text: tt('Recommended'),
                    dataIndex: 'NumberofRecommendedStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v, meta, record) {
                        // meta.tdCls = 'sas drilldown';
                        return v;
                    },
                    summaryType: function (records, property, root, begin, end) {
                        var n = property.length,
                            i, sum, v, sum2
                        sum = 0;
                        sum2 = 0
                        for (i = 0; i < n; ++i) {
                            v = property[i]
                            v = parseInt(v)
                            sum = sum + v;
                            if (records[i].get('IsInclude'))
                                sum2 = sum2 + v;
                        }
                        return sum2 + " / " + sum;
                    },
                }
            ]
        },
        {
            text: tt('Depo Envanter'),
            columns: [{
                text: tt('OnHand'),
                dataIndex: 'OnHandInventoryDC',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryType: 'sum',
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    sumObject = summaryData;;
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }, {
                text: tt('OpenOrder'),
                dataIndex: 'OpenOrderDC',
                width: 100,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                summaryType: 'sum',
                renderer: function (v) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '' + Ext.util.Format.number(v, '0,000') + '';
                },
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                }
            }]
        },
        {
            text: tt('Inventory_Store'),
            columns: [{
                    text: tt('OnHandInventory'),
                    dataIndex: 'OnHandInventoryStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryType: 'sum',
                    summaryRenderer: function (value, summaryData, dataIndex, context) {
                        if (!value || value === 0) return '<b>-</b>';
                        return '<b>' + Ext.util.Format.number(value, '0,000.00') + '</b>';
                    }
                },
                {
                    text: tt('OpenOrder'),
                    dataIndex: 'OpenOrderStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    renderer: function (v) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                    summaryRenderer: function (v, summaryData, dataIndex, context) {
                        if (!v || v === 0) return '<b>-</b>';
                        return '' + Ext.util.Format.number(v, '0,000.00') + '';
                    },
                }
            ]
        },
        {
            text: tt('OrderRecommendation_Distribution'),
            tooltip: tt('OrderRecommendation_Distribution'),
            dataIndex: 'OrderRecommendation_Distribution',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return Ext.util.Format.number(v, '0,000.00')
            },
        },
        {
            text: tt('OrderRecommendation_Distribution_Current'),
            tooltip: tt('OrderRecommendation_Distribution_Current'),
            dataIndex: 'OrderRecommendation_Distribution_Current',
            width: 120,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                if (!v || v === 0) return '<b>-</b>';
                return Ext.util.Format.number(v, '0,000.00')
            },
        }
        // {
        //     text: tt('OrderRecommendation_Updated'),
        //     tooltip: tt('OrderRecommendation_Updated'),
        //     dataIndex: 'OrderRecommendation_Updated',
        //     width: 120,
        //     format: '000',
        //     align: 'right',
        //     xtype: 'numbercolumn',
        //     locked: true,
        //     tdCls: 'blue',
        //     summaryType: function (records, property, root, begin, end) {
        //         var n = property.length,
        //             i, sum, v, sum2
        //         sum = 0;
        //         sum2 = 0
        //         for (i = 0; i < n; ++i) {
        //             v = property[i]
        //             v = parseInt(v)
        //             sum = sum + v;
        //             if (records[i].get('IsInclude'))
        //                 sum2 = sum2 + v;
        //         }
        //         return Ext.util.Format.number(sum2, '0,000') + " / " + Ext.util.Format.number(sum, '0,000');
        //     },
        //     summaryRenderer: function (v, summaryData, dataIndex, context) {
        //         if (!v || v === 0) return '<b>-</b>';
        //         return v
        //     },
        // }
    ],
})
`
