import { useCallback, useEffect, useRef } from 'react'
import { PluginTypes } from '@/BasePlugin'
import {
  DirtyStateChangedEventRef,
  DirtyStateChangedProps
} from './useRegisterEventDirtyStateChanged.types'

function useRegisterEventDirtyStateChanged({ registerEvent }: DirtyStateChangedProps) {
  const dirtyStateChangedEventRef = useRef<DirtyStateChangedEventRef>({
    handleDirtyStateChanged: (_args: never): never => ({} as never)
  })

  const dirtyStateChanged = useCallback((isDirty: boolean) => {
    return { dirty: isDirty, clean: !isDirty }
  }, [])

  useEffect(() => {
    const event = registerEvent({
      key: 'DirtyStateChanged',
      fn: dirtyStateChanged,
      returnTypes: { dirty: PluginTypes.boolean, clean: PluginTypes.boolean }
    })
    dirtyStateChangedEventRef.current.handleDirtyStateChanged = event
  }, [dirtyStateChanged, registerEvent])

  return { dirtyStateChangedEventRef }
}

export default useRegisterEventDirtyStateChanged
