export default {
  title: 'D3 Heatmap',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'D3Heatmap',
  description: '',
  iconClass: 'fa-h-square',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    needfulldataforpreview: true
  },
  group: 'Charts'
}
