// OVERRIDES
require('./../../../extjs/overrides/Calendar/Event')('MDM')
require('./../../../extjs/overrides/data/store.mdm')
require('./../../../extjs/overrides/data/store/reader')
require('./../../../extjs/overrides/clipboard')
require('./../../../extjs/overrides/ViewModel')('MDM')

// HIGHCHART
require('./../../../extjs/src/Chart/ux/require.js')

require('./src/ux/ErrorBox')

require('./src/Global')

require('./src/plugins/CornerIcon')
require('./src/plugins/PanelTitleEdit')

require('./src/data/model/Base')
require('./src/data/model/I18')
require('./src/data/model/Settings')
require('./src/data/model/MongoSettings')
require('./src/data/model/Material')
require('./src/data/model/SpotBase')
require('./src/data/model/SpotList')

require('./src/data/store/I18')
require('./src/data/store/MaterialSearch')
require('./src/data/store/MongoSettings')
require('./src/data/store/Settings')
