import { fetchOne, clearAll } from '../crudoptV3'

const model = 'query'

export const runPreviewQuery = (pluginId, data = {}) => {
  return fetchOne(
    'previewQuery',
    '/data/run/',
    {
      data: {
        ...data,
        withVersion: true
      }
    },
    { method: 'post', key: pluginId }
  )
}

export const runPluginQuery = (id, { params = {}, data = {} }) => {
  return fetchOne(
    model,
    `/data/${id}/`,
    {
      params,
      data
    },
    { key: JSON.stringify({ id, params, data }), method: 'post' }
  )
}

export const invalidatePluginCache = (pluginId, { params = {}, data = {} }) => {
  return fetchOne(
    'invalidatePluginCache',
    `data/plugin/${pluginId}/invalidate`,
    {
      params,
      data
    },
    { key: JSON.stringify({ pluginId, params, data }), method: 'post' }
  )
}

export const invalidateCatalogCache = (catalogId, { params = {}, data = {} }) => {
  return fetchOne(
    'invalidateCatalogCache',
    `data/catalog/${catalogId}/invalidate`,
    {
      params,
      data
    },
    { key: JSON.stringify({ catalogId, params, data }), method: 'post' }
  )
}

export const clearPluginRecord = (id, args) => {
  return clearAll()
}
