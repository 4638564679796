Ext.define('MDM.Btl.dd.MassApply.Other', {
  extend: 'Ext.grid.Panel',
  xtype: 'dd-btl-edit-grid-other',
  iconCls: 'fa fa-map-signs',
  title: 'Other Regions',
  reference: 'otherEdit',
  config: {
    cfgData: null,
    startCol: 0,
    readOnly: true,
    hideReadOnlyCol: false
  },
  layout: 'border',
  lockedGridConfig: {
    width: 400,
    split: true,
    listeners: {
      resize: 'onResizeLock'
    }
  },
  listeners: {
    beforeedit: 'onBeforeEdit'
  },
  viewConfig: {
    getRowClass: function (record, rowIndex, rowParams, store) {
      if (record.get('IsApplied')) {
        return 'IsApplied'
      }
      return ''
    }
  },
  plugins: ['gridfilters', 'gridexporter'],
  features: [
    {
      ftype: 'grouping',
      startCollapsed: false,
      groupHeaderTpl:
        '{columnName}: {name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  initComponent: function () {
    this.columns = this.buildColumns()
    this.callParent()
  },
  lockToast: function () {
    var t = Ext.toast({
      html: 'This record is not editable.',
      title: 'Warning',
      width: 300,
      align: 'tr',
      iconCls: 'fa fa-check',
      closable: true
    })
  },
  buildColumns: function () {
    var me = this,
      hideReadOnlyCol = me.getHideReadOnlyCol(),
      readOnly = me.getReadOnly()
    if (readOnly) {
      return MDM.Btl.Column.build(
        [
          {
            text: 'BTL Type',
            dataIndex: 'BTLType',
            locked: true,
            width: 70
          },
          {
            text: 'DPIFamily',
            dataIndex: 'DPIFamily',
            locked: true,
            minWidth: 150,
            flex: 1
          },
          {
            text: 'Reason',
            dataIndex: 'Reason',
            locked: true,
            minWidth: 150,
            flex: 1
          },
          {
            text: 'Comment',
            dataIndex: 'Comment',
            locked: true,
            minWidth: 150,
            hidden: me.stateColumns.hasOwnProperty('Comment') ? me.stateColumns['Comment'] : true,
            flex: 1
          },
          {
            text: 'Plant',
            dataIndex: 'Plant',
            locked: true,
            minWidth: 150,
            hidden: me.stateColumns.hasOwnProperty('Plant') ? me.stateColumns['Plant'] : true,
            flex: 1
          },
          {
            text: 'Material',
            dataIndex: 'Material',
            locked: true,
            minWidth: 150,
            hidden: me.stateColumns.hasOwnProperty('Material') ? me.stateColumns['Material'] : true,
            flex: 1
          },
          {
            text: 'Title',
            dataIndex: 'Title',
            flex: 1
          },
          {
            text: 'From Region',
            dataIndex: 'FromRegion',
            locked: true,
            hidden: me.stateColumns.hasOwnProperty('FromRegion')
              ? me.stateColumns['FromRegion']
              : true,
            flex: 1
          },
          {
            text: 'T0 Region',
            dataIndex: 'ToRegion',
            locked: true,
            hidden: me.stateColumns.hasOwnProperty('ToRegion') ? me.stateColumns['ToRegion'] : true,
            flex: 1
          },
          {
            text: 'Created By',
            dataIndex: 'CreatedBy',
            locked: true,
            hidden: me.stateColumns.hasOwnProperty('CreatedBy')
              ? me.stateColumns['CreatedBy']
              : true,
            flex: 1
          },
          {
            text: 'Created at',
            dataIndex: 'CreatedAt',
            locked: true,
            hidden: me.stateColumns.hasOwnProperty('CreatedAt')
              ? me.stateColumns['CreatedAt']
              : true,
            flex: 1
          },
          {
            text: 'Applied at',
            dataIndex: 'AppliedAt',
            locked: true,
            hidden: me.stateColumns.hasOwnProperty('AppliedAt')
              ? me.stateColumns['AppliedAt']
              : true,
            flex: 1
          },
          {
            menuDisabled: false,
            sortable: true,
            xtype: 'actioncolumn',
            locked: true,
            resizable: false,
            width: 50,
            align: 'center',
            items: [
              {
                iconCls: 'fa fa-circle',
                isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                  return !rec.get('IsEditable')
                },
                getClass: function (v, meta, rec) {
                  if (rec.get('IsEditable')) {
                    return 'fa fa-info-circle'
                  }
                  return ''
                },
                handler: 'showEditPanel'
              }
            ]
          },
          {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            locked: false,
            resizable: false,
            width: 1,
            align: 'center',
            items: [
              {
                iconCls: 'fa fa-pencil ',
                isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                  return !rec.get('IsEditable')
                },
                getClassp: function (v, meta, rec) {
                  if (rec.get('IsEditable')) {
                    return 'fa fa-pencil'
                  }
                  return ''
                },
                handler: 'onAddNew'
              }
            ]
          }
        ],
        hideReadOnlyCol
      )
    } else {
      return MDM.Btl.Column.build(
        [
          {
            menuDisabled: false,
            sortable: true,
            xtype: 'actioncolumn',
            text: '',
            menuText: 'Action - Info',
            dataIndex: 'IsApplied',
            tooltip: 'View BTL Info',
            locked: true,
            resizable: false,
            width: 30,
            align: 'center',
            items: [
              {
                iconCls: 'fa fa-circle',
                tooltip: 'View BTL Info',
                isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                  return !rec.get('IsEditable')
                },
                getClass: function (v, meta, rec) {
                  if (rec.get('IsEditable')) {
                    return 'fa fa-info-circle'
                  }
                  return ''
                },
                handler: 'showEditPanel'
              }
            ]
          },
          {
            text: 'BTL Entry ',
            dataIndex: 'Title',
            tooltip: 'BTL Entry',
            locked: true,
            minWidth: 150,
            filter: 'list',
            flex: 1
          },
          {
            text: 'DPIFamily',
            dataIndex: 'DPIFamily',
            locked: true,
            minWidth: 150,
            filter: {
              type: 'string',
              itemDefaults: {
                emptyText: 'Search for...'
              }
            },
            flex: 1
          },
          {
            text: 'Reason',
            dataIndex: 'Reason',
            locked: true,
            minWidth: 150,
            filter: {
              type: 'string',
              itemDefaults: {
                emptyText: 'Search for...'
              }
            },
            flex: 1
          },
          {
            text: 'Material Code',
            dataIndex: 'MaterialCode',
            tooltip: 'Material Code',
            locked: true,

            minWidth: 80,
            flex: 1,
            filter: {
              type: 'string',
              itemDefaults: {
                emptyText: 'Search for...'
              }
            }
          },
          {
            text: 'Material Description',
            dataIndex: 'MaterialDescription',
            tooltip: 'Material Description',

            locked: true,
            minWidth: 150,
            flex: 1,
            filter: {
              type: 'string',
              itemDefaults: {
                emptyText: 'Search for...'
              }
            }
          },
          {
            text: 'Material Type',
            tooltip: 'Material Type',
            dataIndex: 'MaterialType',
            locked: true,
            width: 100,
            filter: 'list'
          },
          {
            text: 'From Subregion',
            tooltip: 'From Subregion',
            dataIndex: 'FromRegion',
            locked: true,
            width: 100,
            filter: 'list'
          },
          {
            text: 'To Subregion',
            tooltip: 'To Subregion',
            dataIndex: 'ToRegion',
            locked: true,
            width: 100,
            filter: 'list'
          },
          {
            text: 'Supply Source',
            tooltip: 'Supply Source',
            dataIndex: 'SupplySource',
            locked: true,
            width: 150,
            filter: 'list'
          },
          {
            text: 'Comment',
            tooltip: 'Comment',
            dataIndex: 'Comment',
            locked: true,
            width: 200
          },
          {
            text: 'Created By',
            tooltip: 'Created By',
            dataIndex: 'CreatedBy',
            locked: true,
            width: 100
          },
          {
            text: 'Created At',
            tooltip: 'Created At',
            dataIndex: 'CreatedAt',
            xtype: 'datecolumn',
            format: 'F d, Y G:i:s',
            locked: true,
            width: 100
          },
          {
            text: 'Applied At',
            tooltip: 'Applied At',
            xtype: 'datecolumn',
            format: 'F d, Y G:i:s',
            dataIndex: 'AppliedAt',
            locked: true,
            width: 100
          },
          {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            locked: false,
            resizable: false,
            width: 1,
            align: 'center',
            items: [
              {
                iconCls: 'fa fa-pencil ',
                isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                  return !rec.get('IsEditable')
                },
                getClassp: function (v, meta, rec) {
                  if (rec.get('IsEditable')) {
                    return 'fa fa-pencil'
                  }
                  return ''
                },
                handler: 'onAddNew'
              }
            ]
          }
        ],
        hideReadOnlyCol
      )
    }
  },
  updateHideReadOnlyCol: function (c) {
    var me = this
    if (me.isConfiguring) return c
    var column = this.buildColumns()
    this.hideReadOnlyCol = c
    me.reconfigure(me.store, column)
    return c
  },
  stateColumns: [],
  applyCfgData: function (c) {
    var me = this
    if (!c) return c
    var data = c ? c : []
    var columns = me.getColumnManager().getColumns()
    me.stateColumns = []
    columns.forEach(function (e) {
      me.stateColumns[e.dataIndex] = e.isHidden()
    })
    var column = me.buildColumns()
    me.store.loadData(data)
    me.reconfigure(me.store, column)
  }
})
