import { IRowDataGrid } from '../../../types'
import { omitCustomFields } from '../common'
import { expectedOmitCustomFieldsData, omitCustomFieldsData } from './mocks'

const omitCustomFieldsParams = (): IRowDataGrid => omitCustomFieldsData

describe('AgGrid:Helpers:Common:omitCustomFields', () => {
  let params = omitCustomFieldsParams()

  beforeEach(() => {
    params = omitCustomFieldsParams()
  })
  it('should return data without custom fields and substitute fields', () => {
    const result = omitCustomFields(params)

    expect(result).toEqual(expectedOmitCustomFieldsData)
  })
})
