export default `({
    xtype: 'grid',
    margin: 4,
    border: true,
  //  title: 'Stok Listesi',
    columns: [{
            xtype: 'actioncolumn',
            width: 30,
            locked: true,
            items: [{
                iconCls: 'x-fa fa-dot-circle-o',
                tooltip: 'Show Vendor Analysis',
                align: 'center',
                handler: function (grid, rowIndex, colIndex) {
                    var win = Ext.create('Ext.window.Window', {
                        height: 300,
                        width: 300,
                        autoShow: true,
                        layout: 'fit',
                        items: {
                            xtype: 'uxcontainer',
                            uxItemCfg: 'deneme',
                        }
                    })
                    // var rec = grid.getStore().getAt(rowIndex);
                    // grid.fireEvent('clickforDetail', rec);
                    // Ext.create('Spui.view.drilldown.Vendor.Main').show();
                }
            }]
        },
        {
            text: 'DcCode',
            dataIndex: 'DcCode',
            width: 90,
            locked: true
        },
        {
            text: 'DcName',
            dataIndex: 'DcName',
            width: 150,
            locked: true
        },
        {
            text: 'Store',
            columns: [{
                    text: 'AcvtiveStore',
                    dataIndex: 'NumberOfOpenStore',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
                {
                    text: 'HasInventory',
                    dataIndex: 'NumberofStoreHasInventory',
                    width: 100,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                },
                {
                    text: 'TotaInventory',
                    dataIndex: 'TotalStoreInventory',
                    width: 100,
                    format: '000.00',
                    align: 'right',
                    xtype: 'numbercolumn',
                }
            ]
        },
        {
            text: 'DcInventory',
            dataIndex: 'DcInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: 'TotalInventory',
            dataIndex: 'TotalInventory',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        }
    ],
})
`
