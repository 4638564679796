export default function sortByKey(obj) {
  if (obj) {
    return Object.keys(obj)
      .sort((a, b) => a.localeCompare(b))
      .reduce((res, key) => {
        res[key] = obj[key]
        return res
      }, {})
  }
}
