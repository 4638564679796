import { CSSProperties } from 'react'

export default function resolveStyle(styleGetter?: any, ...params: any[]) {
  if (typeof styleGetter === 'object') {
    return styleGetter
  }

  if (typeof styleGetter === 'function') {
    if (params?.length) {
      return styleGetter(...params)
    }

    return styleGetter()
  }

  return {}
}
