export const saveFailed = {
  title: 'ChronoTimeline - Save Failed',
  message: 'Cannot save card'
} as const

export const saveSuccess = {
  title: 'ChronoTimeline - Save Successful',
  message: 'Card saved successfully'
} as const

export const deleteFailed = {
  title: 'ChronoTimeline - Delete Failed',
  message: 'Cannot delete card'
} as const

export const deleteSuccess = {
  title: 'ChronoTimeline - Delete Successful',
  message: 'Card deleted successfully'
} as const

export const queryType = {
  update: 0,
  add: 1,
  delete: 2
} as const

export const addCardItem = {
  title: '-',
  _cardTitle: '',
  _cardDetailedText: ''
} as const
