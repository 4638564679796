/* global Ext */
Ext.define('Ext.grid.filters.filter.LikelyhoodFilter', {
  extend: 'Ext.grid.filters.filter.DoubleFilter',
  alias: 'grid.filter.likelyhood',

  type: 'likelyhood',

  config: {
    fields: {
      like: {
        iconCls: Ext.baseCSSPrefix + 'grid-filters-find',
        margin: '0 0 3px 0'
      },
      notlike: {
        iconCls: Ext.baseCSSPrefix + 'grid-filters-notlike',
        margin: '0 0 3px 0'
      }
    }
  },

  emptyText: 'Enter Text...',

  itemDefaults: {
    xtype: 'textfield',
    enableKeyEvents: true,
    hideEmptyLabel: false,
    labelSeparator: '',
    labelWidth: 29,
    selectOnFocus: false
  },

  menuDefaults: {
    bodyPadding: 3,
    showSeparator: false
  },

  createMenu: function () {
    var me = this

    var listeners = {
      scope: me,
      keyup: me.onValueChange,
      spin: {
        fn: me.onInputSpin,
        buffer: 200
      },
      el: {
        click: me.stopFn
      }
    }

    var itemDefaults = me.getItemDefaults()

    var menuItems = me.menuItems

    var fields = me.getFields()

    var field

    var i

    var len

    var key

    var item

    var cfg

    me.callParent()

    me.fields = {}

    for (i = 0, len = menuItems.length; i < len; i++) {
      key = menuItems[i]
      if (key !== '-') {
        field = fields[key]

        cfg = {
          labelClsExtra:
            Ext.baseCSSPrefix + 'grid-filters-icon ' + field.iconCls
        }

        if (itemDefaults) {
          Ext.merge(cfg, itemDefaults)
        }

        Ext.merge(cfg, field)
        cfg.emptyText = cfg.emptyText || me.emptyText
        delete cfg.iconCls

        me.fields[key] = item = me.menu.add(cfg)

        item.filter = me.filter[key]
        item.filterKey = key
        item.on(listeners)
      } else {
        me.menu.add(key)
      }
    }
  },

  getValue: function (field) {
    var value = {}
    value[field.filterKey] = field.getValue()
    return value
  },

  onInputSpin: function (field, direction) {
    var value = {}

    value[field.filterKey] = field.getValue()

    this.setValue(value)
  },

  stopFn: function (e) {
    e.stopPropagation()
  }
})
