import React, { Component, useCallback, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import { useSelector, useDispatch } from 'react-redux'
import { OverlayTrigger, Popover, Button } from 'react-bootstrap'
import { slvyToast } from '../../../../components'
import { MESSAGES } from '../../utils/messages'
import ScenarioTreeList from './ScenarioTreeList'
import {
  useCreateLibraryTreeFolderNodeMutation,
  useDeleteLibraryTreeNodeMutation,
  useUpdateLibraryTreeNodeMutation
} from '../../store'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { getOptions } from '../../store/slices/options'
import {
  deleteTreeNode,
  updateTreeStatus,
  getTree,
  setSelectedStatus,
  setCurrentId
} from '../../store/slices/tree'
import {
  selectScenario,
  selectScenarioName,
  resetScenarioSelection,
  setCurrentScenario
} from '../../store/slices/treeSelection'

const recentAssortmentFolderId = -Number.MAX_VALUE

const selectFromState = (state) => ({
  currentId: state.tree.currentId,
  errorRootOptions: state.options.error,
  isLoading: state.options.isLoading,
  rootOptions: state.options.rootOptions,
  selectedScenarioTree: state.treeSelection.tree,
  selectedStatus: state.tree.selectedStatus
})

function ScenarioTree(props) {
  const [createLibraryFolderNode, createLibraryTreeResult] =
    useCreateLibraryTreeFolderNodeMutation()

  const [deleteLibraryTreeNode, deleteLibraryTreeResult] = useDeleteLibraryTreeNodeMutation()
  const [updateLibraryTreeNode, updateLibraryTreeResult] = useUpdateLibraryTreeNodeMutation()

  const {
    currentId,
    errorRootOptions,
    isLoading,
    rootOptions,
    selectedScenarioTree,
    selectedStatus
  } = useSelector(selectFromState)

  const dispatch = useDispatch()

  const [folderName, setFolderName] = useState('')

  const {
    changeScenario = () => {},
    data,
    data: { AvailableStatus = [], Root = {}, Root: { Id = null } = {} } = {},
    environment,
    isCreateScenarioEnabled = false,
    onCreateScenarioToggle = () => {},
    pluginId,
    token
  } = props

  const {
    currentScenario = {},
    currentScenario: { Id: selectedScenarioTreeId = null, IsLeaf = false } = {},
    isFolderSelected = false
  } = selectedScenarioTree

  const createScenarioEnabled =
    selectedScenarioTreeId !== recentAssortmentFolderId && isCreateScenarioEnabled
  const isDisableFolderCreation =
    selectedScenarioTreeId === recentAssortmentFolderId ||
    !selectedScenarioTreeId ||
    !isFolderSelected
  const deleteScenarioEnabled =
    _.isNumber(selectedScenarioTreeId) && selectedScenarioTreeId !== recentAssortmentFolderId
  const isVisible = !_.isEmpty(data)

  useEffect(() => {
    if (!isLoading) {
      dispatch(setLoaderReducer({ isShown: false }))
      setRecentAssortments(selectedScenarioTreeId)
    }
    if (!isLoading && errorRootOptions && errorRootOptions.message && _.isEmpty(rootOptions)) {
      slvyToast.error({ message: errorRootOptions.message, title: 'Load Options' })
    } else if (!isLoading && !_.isEmpty(rootOptions)) {
      changeScenario(false)
    }
  }, [rootOptions, errorRootOptions, isLoading])

  useEffect(() => {
    const { status, data: responseData } = createLibraryTreeResult
    if (status === 'pending') {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Create Tree Folder Node' }))
    } else if (status === 'fulfilled' && responseData) {
      if (responseData.Description) {
        dispatch(getTree({ pluginId, body: {}, environment, token, method: 'GetTree' })).then(
          () => {
            slvyToast.success({
              message: responseData.Description,
              title: 'Create Tree Folder Node'
            })
            resetCreateFolder()
          }
        )
      }
      dispatch(setLoaderReducer({ isShown: false, messages: 'Create Tree Folder Node' }))
    }
  }, [createLibraryTreeResult])

  useEffect(() => {
    const { status, data: responseData } = deleteLibraryTreeResult
    if (status === 'pending') {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Delete Tree Node' }))
    } else if (status === 'fulfilled' && responseData) {
      if (responseData.Description) {
        slvyToast.success({
          message: responseData.Description,
          title: 'Delete Tree Node'
        })
      }
      handleDeleteTreeNode(selectedScenarioTreeId)
      dispatch(setLoaderReducer({ isShown: false, messages: 'Delete Tree Node' }))
    }
  }, [deleteLibraryTreeResult])

  useEffect(() => {
    const { status, data: responseData } = updateLibraryTreeResult
    if (status === 'pending') {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Update Tree Node' }))
    } else if (status === 'fulfilled' && responseData) {
      if (responseData.Description) {
        const payload = {
          pluginId,
          body: {},
          environment,
          token
        }
        slvyToast.success({ message: responseData.Description, title: 'Update Tree Node' })
        handlerUpdateTreeStatus(selectedStatus, currentId, currentScenario)
        dispatch(getTree({ ...payload, method: 'GetTree' }))
      }
      dispatch(setLoaderReducer({ isShown: false, messages: 'Create Tree Folder Node' }))
    }
  }, [updateLibraryTreeResult])

  const runDelete = (nodeId) => {
    const payload = {
      pluginId,
      method: 'LibraryTreeDeleteNode',
      requestMethod: 'post',
      body: {
        nodeId
      }
    }
    deleteLibraryTreeNode({ ...payload })
  }

  const onLibraryTreeDeleteNode = (deleteScenarioEnabled) => {
    const { currentScenario: { Id: nodeId = null } = {} } = selectedScenarioTree
    if (!deleteScenarioEnabled) {
      slvyToast.info({
        message: 'Please choose a scenario or an assortment!',
        title: 'Delete Scenario/Assortment'
      })
      return
    }

    confirmAlert({
      title: MESSAGES.delete,
      message: MESSAGES.are_you_sure_you_want_to_delete,
      buttons: [
        {
          label: MESSAGES.cancel
        },
        {
          label: MESSAGES.delete,
          onClick: () => runDelete(nodeId)
        }
      ]
    })
  }

  const onChangeFolderName = (event) => {
    setFolderName(regexControl(event.target.value))
  }

  const findRecentAssortments = (recentAssortmentIds, roots, recentAssortments) => {
    _.forEach(roots, (node) => {
      if (node.IsLeaf && node.Id >= 0 && _.indexOf(recentAssortmentIds, node.Id) >= 0) {
        recentAssortments.push(node)
      } else if (node.Children) {
        findRecentAssortments(recentAssortmentIds, node.Children, recentAssortments)
      }
    })
    return recentAssortments
  }

  const setRecentAssortments = (nodeId) => {
    const recentAssortmentIds =
      JSON.parse(localStorage.getItem(`ap-RecentAssortments-${pluginId}`)) || []

    if (!_.includes(recentAssortmentIds, nodeId)) {
      recentAssortmentIds.push(nodeId)
      if (_.size(recentAssortmentIds) > 3) {
        recentAssortmentIds.shift()
      }
      localStorage.setItem(`ap-RecentAssortments-${pluginId}`, JSON.stringify(recentAssortmentIds))
    }
  }

  const getRecentAssortments = useCallback(
    (Root) => {
      let recentAssortmentsParentNode = null
      const recentAssortmentIds =
        JSON.parse(localStorage.getItem(`ap-RecentAssortments-${pluginId}`)) || []
      if (recentAssortmentIds.length > 0) {
        const recentAssortments = findRecentAssortments(
          recentAssortmentIds,
          Root.Children || [],
          []
        )

        deleteOutdatedRecentAssortmentsId(pluginId, recentAssortmentIds, recentAssortments)

        const recentAssortmentNodes = _.map(recentAssortments, (recentAssortment) => {
          const clonedNode = _.cloneDeep(recentAssortment)
          clonedNode.IsReadonly = true
          clonedNode.IsRecentAssortments = true
          return clonedNode
        })
        if (_.size(recentAssortmentNodes) > 0) {
          recentAssortmentsParentNode = {
            Name: 'Recent Assortments',
            Description: '',
            Id: recentAssortmentFolderId,
            UserName: '',
            IsLeaf: false,
            Status: 'FOLDER',
            CreateTime: null,
            IsReadonly: true,
            Children: recentAssortmentNodes,
            IsRecentAssortments: true
          }
        }
      }

      return recentAssortmentsParentNode
    },
    [pluginId, recentAssortmentFolderId]
  )

  const deleteOutdatedRecentAssortmentsId = (pluginId, recentAssortmentIds, recentAssortments) => {
    const assortmentIds = []
    _.forEach(recentAssortmentIds, (id) => {
      if (recentAssortments.find((item) => item.Id === id)) {
        assortmentIds.push(id)
      }
    })

    localStorage.setItem(`ap-RecentAssortments-${pluginId}`, JSON.stringify(assortmentIds))
  }

  const onLibraryTreeUpdateNodeHandler = (selectedStatus = '', currentScenario = {}, currentId) => {
    dispatch(setSelectedStatus(selectedStatus))
    dispatch(setCurrentScenario({ dataKey: 'tree', currentScenario: currentScenario }))
    dispatch(setCurrentId(currentId))

    const params = {
      ...currentScenario,
      Status: selectedStatus
    }

    let omittedParams = _.omit(params, 'Children')
    omittedParams = _.omit(omittedParams, 'Filters')

    const payload = {
      pluginId,
      method: 'LibraryTreeUpdateNode',
      requestMethod: 'post',
      body: {
        ...omittedParams
      }
    }

    updateLibraryTreeNode({ ...payload })
  }

  const createFolder = (isDisableFolderCreation) => {
    if (isDisableFolderCreation) {
      slvyToast.info({ message: 'Please choose a folder!', title: 'Create Folder' })
    }
  }

  const executeCreateFolder = (event) => {
    event.preventDefault()

    const { currentScenario: { Id = null, Status = null, ProjectId = null } = {} } =
      selectedScenarioTree

    const trimmedFolderName = folderName.trim()

    if (!trimmedFolderName.length) {
      slvyToast.info({ message: 'Please enter a valid folder name!', title: 'Create Folder' })
      return
    }

    const payload = {
      pluginId,
      method: 'LibraryTreeCreateFolderNode',
      requestMethod: 'post',
      body: { MotherId: Id, Status, ProjectId, Name: trimmedFolderName }
    }

    createLibraryFolderNode({ ...payload })
  }

  const resetCreateFolder = () => {
    setFolderName('')
  }

  const regexControl = (value = '') => {
    return value
      .replace(/  +/g, ' ') //  eslint-disable-line no-regex-spaces
      .replace(/\.|\*|\\|\>|\<|\+|\%|\?|\/|\&|\:|\#/g, '') // eslint-disable-line
  }

  const eventHandler = (event) => {
    event.stopPropagation()
  }

  const createScenarioToggle = (createScenarioEnabled) => {
    if (createScenarioEnabled) {
      onCreateScenarioToggle(createScenarioEnabled)
    } else {
      slvyToast.info({ message: 'Please choose a folder!', title: 'Create Assortment' })
    }
  }

  const handlerUpdateTreeStatus = (selectedStatus, currentId, currentScenario) => {
    dispatch(updateTreeStatus({ selectedStatus, currentId }))
    dispatch(
      selectScenario({
        dataKey: 'tree',
        currentScenario: {
          ...currentScenario,
          Status: selectedStatus
        }
      })
    )

    dispatch(setLoaderReducer({ isShown: false, messages: 'Update Tree Node' }))
  }

  const loadScenario = (IsLeaf) => {
    if (!IsLeaf) {
      slvyToast.info({ message: 'Please choose a scenario!', title: 'Load Scenario' })
      return
    }

    const { currentScenario: { Id: nodeId = null } = {} } = selectedScenarioTree

    const payload = {
      pluginId,
      method: 'Load',
      requestMethod: 'post',
      body: {
        nodeId
      }
    }
    dispatch(setLoaderReducer({ isShown: true, messages: 'Load Options' }))

    dispatch(getOptions({ ...payload }))
  }

  const handleDeleteTreeNode = (currentId) => {
    dispatch(deleteTreeNode({ currentId }))
    dispatch(resetScenarioSelection({ dataKey: 'tree' }))
  }

  const recentAssortments = getRecentAssortments(_.cloneDeep(Root))

  let expandedRows = {}

  if (!_.isEmpty(Root)) {
    if (!_.isNil(recentAssortments)) {
      expandedRows = {
        0: true,
        '0.0': true,
        0.1: true
      }
    } else {
      expandedRows = {
        0: true,
        '0.0': true
      }
    }
  }

  const popoverTop = (
    <Popover id="popover-create-new-folder" title="Please enter folder name!">
      <Popover.Header as="h3" className="mb-0 pb-2">
        Please enter folder name!
      </Popover.Header>
      <Popover.Body className="p-0">
        <form className="d-flex" onSubmit={executeCreateFolder}>
          <input
            className="form-control form-control-sm"
            placeholder="Name"
            type="text"
            value={folderName}
            onChange={onChangeFolderName}
          />
          <Button
            disabled={!folderName.trim().length}
            size="sm"
            type="submit"
            variant="outline-dark"
            onClick={eventHandler}
          >
            <span className="slvy-ui-icon-check-circle-regular" />
          </Button>
        </form>
      </Popover.Body>
    </Popover>
  )

  const createFolderButton = (
    <Button
      className="buttonFilter"
      disabled={isDisableFolderCreation}
      size="m"
      type="button"
      variant="success"
      onClick={() => createFolder(isDisableFolderCreation)}
    >
      <span className="btn-plugin-wrp">
        {<i className="slvy-ui-icon-folder-close-outline" />}
        {`  New Folder `}
      </span>
    </Button>
  )

  return (
    <div className={`assortment-scenario-tree ${isVisible ? '-visible' : ''}`}>
      <div className="scenario-lib-wrapper">
        <div className="a-wr">
          <nav>
            <ScenarioTreeList
              availableStatus={AvailableStatus}
              dataKey="tree"
              expandedRows={expandedRows}
              isStatusChangeable={true}
              onLibraryTreeUpdateNodeHandler={onLibraryTreeUpdateNodeHandler}
              recentAssortments={recentAssortments}
              root={Root}
              title={'Assortment Library'}
            />
            <div className="sc-btn-group">
              {isDisableFolderCreation ? (
                createFolderButton
              ) : (
                <OverlayTrigger
                  id="createFolderButton"
                  overlay={isDisableFolderCreation ? '' : popoverTop}
                  placement="top"
                  rootClose
                  trigger="click"
                >
                  {createFolderButton}
                </OverlayTrigger>
              )}
              <Button
                className="buttonFilter"
                disabled={!createScenarioEnabled}
                size="m"
                type="button"
                variant="success"
                onClick={() => createScenarioToggle(createScenarioEnabled)}
              >
                <span className="btn-plugin-wrp">
                  {<i className="slvy-ui-icon-plus-circle-regular" />}
                  {`  Create Assortment `}
                </span>
              </Button>
              <Button
                className="buttonFilter"
                disabled={!deleteScenarioEnabled}
                size="m"
                type="button"
                variant="danger"
                onClick={() => onLibraryTreeDeleteNode(deleteScenarioEnabled)}
              >
                <span className="btn-plugin-wrp">
                  {<i className="slvy-ui-icon-trash-can" />}
                  {`  Delete `}
                </span>
              </Button>
              <Button
                className="buttonFilter"
                disabled={!IsLeaf}
                size="m"
                type="button"
                variant="success"
                onClick={() => loadScenario(IsLeaf)}
              >
                <span className="btn-plugin-wrp">
                  {<i className="slvy-ui-icon-server-download" />}
                  {`  Load Assortment `}
                </span>
              </Button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default ScenarioTree
