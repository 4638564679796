import { useMemo, useState } from 'react'
import cx from 'classnames'
import { SlvyTable } from '@/components'
import { getServiceStatus, ServiceTask } from '../../../store/api/endpoints'
import BaseModal from '../../BaseModal'
import IconButton from '../../IconButton'
import styles from './ServiceTasks.module.scss'

export default function ServiceTasks() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const serviceStatusResponse = getServiceStatus.useQueryState()
  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'Name',
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Description',
        accessorKey: 'Description',
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'User Name',
        accessorKey: 'UserName',
        minSize: 100,
        size: 100,
        maxSize: 200
      },
      {
        header: 'Start Time',
        accessorKey: 'StartTime',
        maxSize: 150,
        meta: {
          format: 'YYYY-MM-DD h:mm:ss',
          dataType: 'datetime'
        }
      },
      {
        header: 'End Time',
        accessorKey: 'EndTime',
        maxSize: 150,
        meta: {
          format: 'YYYY-MM-DD h:mm:ss',
          dataType: 'datetime'
        }
      },
      {
        header: 'In Progress',
        accessorKey: 'InProgress',
        maxSize: 60
      },
      {
        header: 'Progress',
        accessorFn: getProgressPercentage,
        meta: {
          format: '%',
          type: 'progress'
        },
        maxSize: 85
      }
    ],
    []
  )

  function handleClickOpenModal() {
    setIsModalOpen(true)
  }

  function handleClickCloseModal() {
    setIsModalOpen(false)
  }

  function getProgressPercentage({ CurrentIteration, MaxIteration }: ServiceTask) {
    return CurrentIteration / MaxIteration
  }

  return (
    <div className={styles.serviceTasks}>
      <IconButton
        className={styles.serviceTasksTrigger}
        type="button"
        onClick={handleClickOpenModal}
      >
        <i
          className={cx(
            styles.serviceTasksStatusIcon,
            { [styles.isDanger]: serviceStatusResponse.data?.IsServiceRunning === 0 },
            { [styles.isSuccess]: serviceStatusResponse.data?.IsServiceRunning === 1 },
            { [styles.isWarning]: serviceStatusResponse.data?.IsServiceRunning === 2 }
          )}
        />
      </IconButton>
      <BaseModal isOpen={isModalOpen} size="lg" onClose={handleClickCloseModal}>
        <BaseModal.Header>
          <BaseModal.Title>Service Tasks</BaseModal.Title>
        </BaseModal.Header>
        <BaseModal.Body>
          <SlvyTable
            columns={columns}
            data={serviceStatusResponse.data?.Tasks}
            theme="sencha-grid"
          />
        </BaseModal.Body>
      </BaseModal>
    </div>
  )
}
