Ext.define('MDM.overrides.data.store.reader', {
  override: 'Ext.data.reader.Json',
  getResponseData: function (response) {
    var error
    if (typeof response.responseJson === 'object' || typeof response.responseJson === 'string') {
      return response.responseJson
    }
    if (typeof response.responseJson === 'boolean') {
      return JSON.stringify(response.responseJson)
    }
    try {
      return Ext.decode(response.responseText)
    } catch (ex) {
      error = this.createReadError(ex.message)
      Ext.Logger.warn('Unable to parse the JSON returned by the server')
      this.fireEvent('exception', this, response, error)
      return error
    }
  }
})
