import { useEffect } from 'react'
import { useParams, Outlet, Navigate } from 'react-router-dom'
import { selectCollection } from '@/crudoptV3'
import { getPages } from '@/actions/page'
import { useAppDispatch, useAppSelector } from '@/hooks/store'

const MenuIndex = () => {
  const { environment, catalogId, menuId, storeIndex } = useParams()
  const model3 = useAppSelector((state) => state.model3)
  const dispatch = useAppDispatch()

  const pages = selectCollection(getPages(catalogId, menuId), model3)

  useEffect(() => {
    if (pages.needFetch && pages.fetch) {
      dispatch(pages.fetch)
    }
  }, [dispatch, pages.fetch, pages.needFetch])

  if (!pages?.isSuccess) return <Outlet />

  const first = pages?.data?.find((p) => !p.isHidden)

  const path = `/${environment}/catalog/${catalogId}/store/${Number(
    storeIndex
  )}/menu/${menuId}/page/${first ? first.id : 'add'}`

  return <Navigate replace to={path} />
}

export default MenuIndex
