import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IState {
  selectedContainer: 0 | string
}

export const initialState: IState = { selectedContainer: 0 }

export const containerRelation = createSlice({
  name: 'containerRelation',
  initialState,
  reducers: {
    selectAContainer: (state, action: PayloadAction<IState['selectedContainer']>) => {
      state.selectedContainer = action.payload
    }
  }
})

export const { selectAContainer } = containerRelation.actions
export default containerRelation.reducer
