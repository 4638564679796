import { ReactElement, ReactNode } from 'react'
import { Tooltip } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { SIDEBAR_MENU } from './constants'

export const tooltip = (text: ReactNode): ReactElement => <Tooltip id={uuidv4()}>{text}</Tooltip>

export const getSidebarMenuCollapsed = (): { isCollapsed?: boolean } => {
  return JSON.parse(localStorage.getItem(SIDEBAR_MENU) || '{}')
}
