import * as actionTypes from './actionTypes'

export const saveResponseData = (responseData) => {
  return {
    type: actionTypes.SAVE_RESPONSE_DATA,
    responseData
  }
}

export const PRODUCTsaveResponseData = (responseData) => {
  return {
    type: actionTypes.PRODUCT_SAVE_RESPONSE_DATA,
    responseData
  }
}
