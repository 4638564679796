import { memo, useMemo } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import sortByKey from '../../../../../../../utils/sortByKey'
import Methods from '../../Methods/index'
import ContainerHeader from '../../ContainerHeader'
import { IRegisterEventMethod } from '../../../../Common.types'
import { IMethodsContainerProps } from './MethodsContainer.types'
import { emptyObject } from '../../../../../constants'
import { sortKeyInObject } from '../../../helpers'
import styles from '../../../index.module.scss'

const MethodsContainer = ({
  id,
  methods,
  methodsAssignees,
  name,
  type,
  variables,
  handleReturnVariableChange,
  countInfo,
  toggleActives
}: IMethodsContainerProps) => {
  let sortedMethods = sortByKey(methods) as IRegisterEventMethod
  sortedMethods = sortKeyInObject(sortedMethods, 'params')

  const { active, total } = countInfo

  const title = useMemo(() => <h6>{`${name} (${type})`}</h6>, [name, type])

  return (
    <>
      <Accordion.Header className="mb-0 pb-0">
        <ContainerHeader active={active} title={title} total={total} type={type} />
      </Accordion.Header>
      <Accordion.Body className={cx('p-3', styles.relationAccordionBody)}>
        {_.map(sortedMethods, (method, methodKey) => {
          const { [methodKey]: methodAssignees = emptyObject } = methodsAssignees
          return (
            <Methods
              key={id + methodKey}
              assignees={methodAssignees}
              id={id}
              methodParams={method.params}
              parentKey={methodKey}
              title={methodKey}
              toggleActives={toggleActives}
              variables={variables}
              onChange={handleReturnVariableChange}
            />
          )
        })}
      </Accordion.Body>
    </>
  )
}

export default memo(MethodsContainer)
