import { useMemo } from 'react'
import {
  EnableAdvancedFilterProps,
  EnableAdvancedFilterReturnType
} from './useEnableAdvancedFilter.types'

function useEnableAdvancedFilter(props: EnableAdvancedFilterProps) {
  const { filtering, isPaginationEnabled, enableAdvancedFilter } = props
  const enableAdvancedFilterProps = useMemo((): EnableAdvancedFilterReturnType => {
    if (!filtering || !enableAdvancedFilter || isPaginationEnabled) {
      return {}
    }

    return {
      enableAdvancedFilter: true
    }
  }, [enableAdvancedFilter, filtering, isPaginationEnabled])

  return { enableAdvancedFilterProps }
}

export default useEnableAdvancedFilter
