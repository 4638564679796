import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Service } from '../api/endpoints'

type ServiceModalState = {
  isOpen: boolean
  selectedService: Service | undefined
}

const initialState: ServiceModalState = {
  isOpen: false,
  selectedService: undefined
}

const serviceModal = createSlice({
  name: 'serviceModal',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<ServiceModalState['isOpen']>) => {
      state.isOpen = action.payload
    },
    setSelectedService: (state, action: PayloadAction<ServiceModalState['selectedService']>) => {
      state.selectedService = action.payload
    }
  }
})

export default serviceModal
export type { ServiceModalState }
