import numeral from 'numeral'
import { useTheme } from '../../contexts/ThemeContext'
import { ProgressBarProps } from './ProgressBar.types'

export default function ProgressBar({ format = '0.0%', progress }: ProgressBarProps) {
  const styles = useTheme()

  return (
    <div className={styles.progressBar}>
      <div className={styles.progressBarProgress} style={{ width: `${progress * 100}%` }} />
      <span className={styles.progressBarValue}>{numeral(progress).format(format)}</span>
    </div>
  )
}
