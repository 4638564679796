import { useEffect, useMemo, useRef } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { transform } from 'lodash'
import { PluginTypes } from '@/BasePlugin'
import { __RowIndex as SlvyRowIndex } from '@/store/slices/localData'
import { useGetSelectionCellParams } from '../../../index'
import { DoubleClickedRegisterEventKey } from '../../registerEventsMethods.types'
import {
  CellClickedData,
  OnCellDoubleClickedProps,
  OnCellDoubleClickedRef
} from './useRegisterEventOnCellDoubleClicked.types'

function useRegisterEventOnCellDoubleClicked({
  columnConfigs,
  fieldConfigs,
  registerEvent
}: OnCellDoubleClickedProps) {
  const onCellDoubleClickedRef = useRef<OnCellDoubleClickedRef>({})

  const { cellParams } = useGetSelectionCellParams(fieldConfigs)
  const cellClickParams = useMemo(() => {
    return {
      ...cellParams,
      [SlvyRowIndex]: PluginTypes.int,
      columnField: PluginTypes.string
    }
  }, [cellParams])

  useEffect(() => {
    const filteredColumns = columnConfigs?.filter(
      ({ customConfigs }) =>
        customConfigs.cellDoubleClickEnabled && customConfigs?.isRawFieldEmpty === false
    )
    filteredColumns?.forEach(({ customConfigs }) => {
      const { rawField } = customConfigs
      const fn: DoubleClickedRegisterEventKey = `handleCellClick_${rawField}`
      onCellDoubleClickedRef.current[fn] = registerEvent({
        key: `CellClicked-${rawField}`,
        fn: (data: CellClickedData, rowIndex: ICellRendererParams['node']['rowIndex']) => {
          return transform(
            fieldConfigs,
            (result: CellClickedData, { fieldName }) => {
              // eslint-disable-next-line no-param-reassign
              result[fieldName] = data[fieldName]
            },
            {
              columnField: rawField as string,
              [SlvyRowIndex]: rowIndex
            }
          )
        },
        returnTypes: cellClickParams
      })
    })
  }, [cellClickParams, columnConfigs, fieldConfigs, registerEvent])

  return { onCellDoubleClickedRef }
}

export default useRegisterEventOnCellDoubleClicked
