Ext.define('Assortment.cmp.Form', {
  extend: 'Ext.form.FormPanel',
  xtype: 'assortment-dateform',
  width: 300,
  header: false,
  bodyPadding: 10,
  title: 'Dates',
  items: [
    {
      xtype: 'datefield',
      format: 'd/m/Y',
      anchor: '100%',
      fieldLabel: 'StartDate',
      bind: {
        value: '{record.StartDate}'
      }
    },
    {
      xtype: 'datefield',
      format: 'd/m/Y',
      anchor: '100%',
      fieldLabel: 'EndDate',
      bind: {
        value: '{record.EndDate}'
      }
    }
  ],
  bbar: [
    '->',
    {
      text: 'Close',
      handler: function (btn) {
        btn.up('window').close()
      }
    }
  ]
})
