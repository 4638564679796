import * as actionTypes from './actionTypes'
import { makeRequest } from '../../utils'

export const fetchUserScenario = (action) => {
  return (dispatch) => {
    dispatch(startGetUserScenarioStart())

    return new Promise((resolve, reject) => {
      makeRequest(action)
        .then((response) => {
          dispatch(
            saveGetUserScenario({
              payload: response.data
            })
          )
          resolve(response.data)
        })
        .catch((error) => {
          dispatch(saveGetUserScenarioFailed({ payload: error.data }))
          reject(error.data)
        })
    })
  }
}

export const startGetUserScenarioStart = () => {
  return {
    type: actionTypes.FETCH_USER_SCENARIO_START
  }
}

export const saveGetUserScenario = (options) => {
  return {
    type: actionTypes.STORE_USER_SCENARIO,
    payload: options.payload
  }
}

export const saveGetUserScenarioFailed = (options) => {
  return {
    type: actionTypes.STORE_USER_SCENARIO_FAILED,
    payload: options.payload
  }
}
