import { getCellEditable } from '../cell'
import { AG_GRID_ENUMS } from '../../../enums'
import {
  customConfigs as defaultCustomConfigs,
  fieldConfigs as defaultFieldConfigs
} from '../../__tests__/mocks'
import { editableCallbackParams as defaultEditableCallbackParams } from '../__tests__/mocks'

const { CUSTOM_ROW_INFO, ADDED_ROWS } = AG_GRID_ENUMS

function getCustomConfigs() {
  return defaultCustomConfigs
}
function getFieldConfigs() {
  return defaultFieldConfigs[0]
}
function getGridEditableByDefault() {
  return true
}

function getEditableCallbackParams() {
  return defaultEditableCallbackParams
}

describe('AgGrid:Helpers:Cell:getCellEditable', () => {
  let customConfigs = getCustomConfigs()
  let fieldConfig = getFieldConfigs()
  let gridEditableByDefault = getGridEditableByDefault()
  let editableCallbackParams = getEditableCallbackParams()

  beforeEach(() => {
    customConfigs = getCustomConfigs()
    fieldConfig = getFieldConfigs()
    gridEditableByDefault = getGridEditableByDefault()
    editableCallbackParams = getEditableCallbackParams()
  })

  it('should return false if there is data and column type is not progress and column editable and getEditableInfo returns false', () => {
    customConfigs.columnType = 'simple'
    gridEditableByDefault = false
    const result = getCellEditable({ customConfigs, gridEditableByDefault, fieldConfig })

    expect(result).toHaveProperty('editable', expect.any(Function))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.editable(editableCallbackParams)).toBeFalsy()
  })

  it('should return true if there is data and column type is not progress and column editable and getEditableInfo returns true', () => {
    customConfigs.columnType = 'simple'
    customConfigs.editing.editableCondition = 'col10'
    customConfigs.editing.enabled = true
    gridEditableByDefault = true
    if (editableCallbackParams && editableCallbackParams.data) {
      editableCallbackParams.data.col10 = true
    }
    const result = getCellEditable({ customConfigs, gridEditableByDefault, fieldConfig })

    expect(result).toHaveProperty('editable', expect.any(Function))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.editable(editableCallbackParams)).toBeTruthy()
  })

  it('should return true if there is data and column type is not progress and row is new added and addingEnabled is true', () => {
    customConfigs.columnType = 'simple'
    if (editableCallbackParams && editableCallbackParams.data) {
      editableCallbackParams.data[CUSTOM_ROW_INFO][ADDED_ROWS] = ['5']
    }
    customConfigs.adding.enabled = true
    const result = getCellEditable({ customConfigs, gridEditableByDefault, fieldConfig })

    expect(result).toHaveProperty('editable', expect.any(Function))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.editable(editableCallbackParams)).toBeTruthy()
  })

  it('should return false if there is data and column type is not progress and row is new added and addingEnabled is false', () => {
    customConfigs.columnType = 'simple'
    if (editableCallbackParams && editableCallbackParams.data) {
      editableCallbackParams.data[CUSTOM_ROW_INFO][ADDED_ROWS] = ['5']
    }
    customConfigs.adding.enabled = false
    const result = getCellEditable({ customConfigs, gridEditableByDefault, fieldConfig })

    expect(result).toHaveProperty('editable', expect.any(Function))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.editable(editableCallbackParams)).toBeFalsy()
  })

  it('should return false if there is data but column type is progress and value is not a number', () => {
    customConfigs.columnType = 'progress'
    if (editableCallbackParams && editableCallbackParams.data) {
      editableCallbackParams.data.col1 = 'test'
    }
    const result = getCellEditable({ customConfigs, gridEditableByDefault, fieldConfig })
    expect(result).toHaveProperty('editable', expect.any(Function))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.editable(editableCallbackParams)).toBeFalsy()
  })

  it('should return false if there is no data', () => {
    editableCallbackParams.data = null
    const result = getCellEditable({ customConfigs, gridEditableByDefault, fieldConfig })
    expect(result).toHaveProperty('editable', expect.any(Function))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.editable(editableCallbackParams)).toBeFalsy()
  })
})
