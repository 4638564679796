export default () => {
  return {
    title: 'Html Template',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Box Settings',
        properties: {
          specificTemplate: {
            type: 'string',
            title: 'Style Templates',
            default: '',
            enum: ['', 'primary'],
            required: true
          },
          backgroundcolor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          bindContainerHeight: {
            type: 'boolean',
            title: 'Bind Container Height',
            default: false,
            required: true
          },
          styleAffectsWholePage: {
            type: 'boolean',
            title: 'Style Affects Whole Page',
            default: false
          },
          customStyleTemplate: {
            type: 'string',
            format: 'Code',
            title: 'Template Style',
            options: {
              mode: 'text/css'
            }
          },
          htmlTemplate: {
            type: 'string',
            format: 'Code',
            title: 'Template Html',
            options: {
              mode: 'text/html'
            }
          }
        }
      },
      setRecordMethodParams: {
        type: 'object',
        title: 'SetRecord Method Parameters',
        properties: {
          parameters: {
            type: 'array',
            title: 'Parameters',
            items: {
              type: 'string',
              title: 'Parameter'
            }
          }
        }
      },
      setEventMethodParams: {
        type: 'object',
        title: 'SetEvent Method Parameters',
        properties: {
          parameters: {
            type: 'array',
            title: 'Events',
            items: {
              type: 'string',
              title: 'Parameter'
            }
          }
        }
      }
    }
  }
}
