Ext.define('Assortment.cmp.LoadFiltersGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'loadFiltersGrid',
  columnLines: true,
  rowLines: true,
  viewConfig: {
    enableTextSelection: true,
    emptyText: '<h3>No Filters to Display<h3>',
    deferEmptyText: false
  },
  columns: [
    {
      text: 'Filter Name',
      dataIndex: 'filterName',
      width: 250,
      flex: 1,
      renderer: 'OnLoadFilterNameRender'
    },
    {
      xtype: 'actioncolumn',
      width: 25,
      menuDisabled: true,
      sortable: false,
      hideable: false,
      items: [
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete',
          handler: 'OnDeleteFilter'
        }
      ]
    }
  ],
  listeners: {
    rowdblclick: 'OnFilterSelected'
  }
})
