import React from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import _ from 'lodash'
import cx from 'classnames'
import {
  selectProduct as onProductSelect,
  saveResourceIndex,
  toggleHierarchy,
  expandHierarchy,
  onPinRow
} from '../../../../actions'
import {
  toggleMoveItem as onToggleMoveItem,
  toggleMoveItemGroup as onToggleMoveItemGroup,
  resetMoveModeItems
} from '../../../../actions/moveMode'
import ResourceRowItemInfo from '../resourceRowItemInfo'
import ResourceChart from '../resourceChart'
import AddProduction from '../addProduction'
import { format, calcTopValue, parse, getRowBgColor, getProductName } from '../../../../utils'
import traverseTree, { getPath, replaceNode } from '../../../../../../utils/traverseTree'

const operators = {
  ' ': () => {},
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '=': (a, b) => a === b,
  '!=': (a, b) => a !== b,
  '<=': (a, b) => a <= b,
  '<': (a, b) => a < b
}

const getResourceRowState = ({
  type,
  name,
  thisResourceIndex,
  ownResourceIndex,
  toggledResourceRows,
  expandedResourceRows,
  allHierarchiesExpanded
}) => {
  const isRowToggled = _.some(toggledResourceRows, {
    type,
    name,
    resourceIndex: type === 'Resource' ? thisResourceIndex : ownResourceIndex
  })
  const isRowExpanded = _.some(expandedResourceRows, {
    resourceIndex: type === 'Resource' ? thisResourceIndex : ownResourceIndex
  })

  return (
    allHierarchiesExpanded || (isRowToggled && !isRowExpanded) || (isRowExpanded && !isRowToggled)
  ) // Exclusive OR
}

class ResourceRowItem extends React.Component {
  constructor(props) {
    super(props)
    const {
      index = 0,
      type = '',
      name = '',
      indentLevel = 0,
      ownResourceIndex = 0,
      toggledResourceRows = [],
      expandedResourceRows = [],
      allHierarchiesExpanded
    } = props
    this.resourceIndex = index
    this[`level${indentLevel}`] = name
    this.locationNames = []

    const isResourceRowItemOpen = getResourceRowState({
      type,
      name,
      thisResourceIndex: this.resourceIndex,
      ownResourceIndex,
      toggledResourceRows,
      expandedResourceRows,
      allHierarchiesExpanded
    })
    const isExpanded =
      _.some(expandedResourceRows, {
        resourceIndex: type === 'Resource' ? this.resourceIndex : ownResourceIndex
      }) || allHierarchiesExpanded

    this.state = {
      isResourceRowItemOpen,
      isResourceChartOpen: false,
      isExpanded,
      addPopup: -30,
      isUserAllowedToAddProduction: true
    }
  }

  componentDidMount() {
    const { isAllowed = () => {} } = this.props

    const isUserAllowedToAddProduction = isAllowed('Resource and Production/Add Production')
    this.setState({ isUserAllowedToAddProduction })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isExpanded, type, allHierarchiesExpanded, clickedProductInfo, onResetMoveModeItems } =
      this.props

    if (nextProps.isExpanded !== this.props.isExpanded && this.props.type !== 'Resource') {
      this.setState({ isResourceRowItemOpen: nextProps.isExpanded })
    }

    if (nextProps.allHierarchiesExpanded !== this.props.allHierarchiesExpanded) {
      this.setState({
        isResourceRowItemOpen: nextProps.allHierarchiesExpanded,
        isExpanded: nextProps.allHierarchiesExpanded
      })
    }

    if (nextProps.clickedProductInfo.resourceId !== clickedProductInfo.resourceId) {
      onResetMoveModeItems()
    }
  }

  toggleResourceRowItem(event) {
    const {
      type,
      name,
      index,
      isPinned = false,
      ownResourceIndex,
      onProductSelect,
      clickedProductInfo: { periodIndex: clickedPeriodIndex = 0 },
      resourcePeriodHierarchy: { Resources: resources, Periods: periods, Products: products } = {}
    } = this.props

    event.stopPropagation()

    if (isPinned) return

    this.setState(
      (prevState) => ({
        isResourceRowItemOpen: !prevState.isResourceRowItemOpen
      }),
      () => {
        this.props.toggleHierarchy({
          type,
          name,
          resourceIndex: type === 'Resource' ? this.resourceIndex : ownResourceIndex
        })
      }
    )

    if (type === 'Resource') {
      this.resourceIndex = index
      // this.props.saveResourceIndex(index)
    }

    if (type === 'Product') {
      const {
        Id: periodId,
        DisplayName: periodDisplayName,
        Name: periodName
      } = periods[clickedPeriodIndex] ?? {}
      const { Id: productId, Name: productName } = products[index] ?? {}
      const { [ownResourceIndex]: { Id, Name } = [] } = resources ?? {}

      // onProductSelect({
      //   productIndex: index,
      //   periodIndex: clickedPeriodIndex,
      //   isResourceChart: false,

      //   resourceName: Name,
      //   resourceId: Id,

      //   periodName,
      //   periodDisplayName,
      //   periodId,

      //   productName,
      //   productId,

      //   locationNames
      // })

      this.props.saveResourceIndex(ownResourceIndex)
    }
  }

  toggleResourceChart(event) {
    event.stopPropagation()

    this.setState((prevState) => ({
      isResourceChartOpen: !prevState.isResourceChartOpen
    }))
  }

  handleExpandCollapse(event) {
    event.stopPropagation()
    const {
      type = '',
      name = '',
      ownResourceIndex = 0,
      allHierarchiesExpanded = false
    } = this.props

    this.setState(
      (prevState) => ({ isExpanded: !prevState.isExpanded }),
      () => {
        const { isExpanded } = this.state

        this.props.expandHierarchy({
          resourceIndex: type === 'Resource' ? this.resourceIndex : ownResourceIndex
        })

        if (!isExpanded || !allHierarchiesExpanded)
          this.props.toggleHierarchy({
            type,
            name,
            resourceIndex: type === 'Resource' ? this.resourceIndex : ownResourceIndex
          })
      }
    )
  }

  popoverClickRootClose(data) {
    const { connectApi, lastRequest, settings, resourcePeriodHierarchy, coverViolationIndexes } =
      this.props
    return (
      <Popover id="popover-positioned-gantt-form-add">
        <AddProduction
          connectApi={connectApi}
          coverViolationIndexes={coverViolationIndexes}
          data={data}
          getData={null}
          getResourceHierarchyResponse={resourcePeriodHierarchy}
          lastRequest={lastRequest}
          settings={settings}
        />
      </Popover>
    )
  }

  handleAddProduct(params, event) {
    event.stopPropagation()

    const { connectApi, period = params.week } = this.props

    params.week = period
    connectApi('GetRccpProductsResponse', params)
  }

  handlePinResource(event) {
    event.stopPropagation()
    const { name, type } = this.props

    this.props.onPinRow({
      Name: name,
      Type: type,
      Index: this.resourceIndex,
      Children: [],
      isPinned: true
    })
  }

  handleToggleMoveItem = (resourceIndex, event) => {
    event.stopPropagation()
    const {
      index,
      path,
      type,
      indentLevel,
      name,
      clickedProductInfo: { periodIndex },
      resourcePeriodHierarchy: { Products, ProductPeriods },
      onToggleMoveItem
    } = this.props

    const { Index, Id } = Products[index] ?? {}
    const resourceIds = Object.keys(ProductPeriods[index][periodIndex ?? 0].ResourceValues)
    const key = path
    const isProduct = type === 'Product'
    const item = {
      name,
      path,
      key,
      type,
      resourceIndex,
      ...(isProduct
        ? {
            index: Index,
            id: Id,
            resourceIds
          }
        : {})
    }

    onToggleMoveItem(item)
  }

  handleToggleMoveItemGroup = (resourceIndex, event) => {
    event.stopPropagation()
    const {
      index,
      path,
      type,
      indentLevel,
      name,
      clickedProductInfo: { periodIndex },
      resourcePeriodHierarchy: { Products, ProductPeriods, Root },
      onToggleMoveItemGroup
    } = this.props

    const groupItems = []
    traverseTree(
      Root,
      (node, context, accum) => {
        const isRoot = context.depth === 0
        const isProduct = node.Type === 'Product'
        let nodePath = ''

        if (isRoot) {
          node.path = 'root'
        } else {
          nodePath = getPath(context, node.Name)
          const newNode = {
            ...node,
            path: nodePath
          }

          context.parent.Children[index] = newNode
          context.replace(newNode)
        }

        const rootlessPath = nodePath.replace('root/', '')
        const isGroupItem = !isRoot && rootlessPath.replace('root/', '').includes(path)
        if (isGroupItem) {
          let productData = {}
          if (isProduct) {
            const { [node.Index]: { [periodIndex ?? 0]: { ResourceValues = {} } = {} } = {} } =
              ProductPeriods
            const resourceIds = Object.keys(ResourceValues)
            const productId = Products[node.Index]?.Id
            productData = {
              index: node.Index,
              id: productId,
              resourceIds
            }
          }

          const name = isProduct ? node.Name.substring(0, node.Name.indexOf(' ')) : node.Name
          const productKey = rootlessPath.substring(0, rootlessPath.lastIndexOf(' - '))
          const key = rootlessPath
          const item = {
            name: node.Name,
            path: rootlessPath,
            key,
            resourceIndex,
            type: node.Type,
            ...(isProduct ? productData : {})
          }
          groupItems.push(item)
        }
      },
      {
        // withAccum: true,
        // initAccumVal: [],
        getChildren: (node) => node.Children
      }
    )

    onToggleMoveItemGroup(groupItems)
  }

  render() {
    const {
      isResourceRowItemOpen = false,
      isResourceChartOpen = false,
      isUserAllowedToAddProduction = true
    } = this.state
    const {
      name = '',
      children = [],
      type = '',
      indentLevel,
      index,
      path,
      isExpanded,
      isPinned = false,
      settings,
      settings: {
        modificationCount,
        appSettings: {
          shiftCalendarView: {
            isEnabled: isShiftCalendarViewEnabled,
            title: shiftCalendarViewTitle = 'Shift Calendar'
          } = {},
          resourceScheduleView: {
            isEnabled: isResourceScheduleViewEnabled,
            title: resourceScheduleViewText = 'Resource Schedule'
          } = {}
        } = {},
        views
      },
      resourcePeriodHierarchy: {
        ResourcePeriods: resourcePeriods = {},
        ProductPeriods: productPeriods = {},
        Periods: periods = [],
        Resources: resources = {},
        Products: products = []
      } = {},
      clickedProductInfo: {
        periodIndex: clickedPeriodIndex = 0,
        productIndex: clickedProductIndex = 0,
        locationNames: clickedLocationNames = [],
        isProductPeriod,
        isSelectionOn
      },
      clickedProductInfo,
      isLastProduct,
      maxSelectedIssues = 1,
      selectedResourceIssueCount = 1,
      resourceIndex,
      initialFilters = {},
      ownResourceIndex,
      configSettings: {
        resourceAndProductPlanSettings: { shiftCalendarButtonFormat = '0,0' } = {}
      } = {},
      getRccpProductsResponse = {},
      pinnedRows = [],
      locations = [],
      connectApi = () => {},
      isAllowed = () => {},
      renderChildren = () => {},
      stateHandler = () => {},
      filterValues = () => {},
      moveMode,
      moveOrigin,
      selectedSession,
      lastRequest
    } = this.props

    const { resourceIndex: thisResourceIndex } = this

    const leftPadding = !indentLevel ? 5 : 15 + indentLevel * 15

    const pinnedRowsLength = pinnedRows.length
    const currentResourceChartPeriod = resourcePeriods[index] /// To be dynamic
    const periodBarStyle = {
      position: type === 'Resource' ? 'sticky' : 'relative',
      top:
        type === 'Resource'
          ? calcTopValue(pinnedRows.length, selectedResourceIssueCount, maxSelectedIssues)
          : 0,
      boxShadow:
        type === 'Resource' && isResourceRowItemOpen && !isPinned ? '0px 8px 16px -8px #c1c1c1' : ''

      // height: 48
      // marginBottom: isLastProduct ? 2 : ''
    }

    const { Id: resourceId = 0, Name: resourceName } = resources[this.resourceIndex] || {}
    const { Id: ownResourceId = 0, Name: ownResourceName } = resources[ownResourceIndex] || {}
    const period = periods[clickedPeriodIndex ?? 0] || []
    const { Name: periodName = '' } = period || {}

    const params = {
      week: periodName,
      period,
      resourceId,
      rccpConfig: {
        ModificationCount: modificationCount
      }
    }

    const resourcePeriod = resourcePeriods[index] || {}
    const { NumberOfEnabledShifts = 0, NumberOfAvailableShifts = 0 } =
      resourcePeriod[clickedPeriodIndex] || {}

    let foundProduct = {}
    if (type === 'Product' && filterValues.tableName) {
      if (filterValues.isResourceBased) {
        foundProduct = _.find(
          resourcePeriods[ownResourceIndex][filterValues.periodIndex || 0].ResourcePeriodProducts,
          { ProductIndex: index }
        )
      } else {
        foundProduct = productPeriods[index][filterValues.periodIndex]
      }
    }

    let { [filterValues.tableName]: Production = null } = foundProduct || {}
    Production = Production === null ? 0 : Production

    const moveItemsArray = Object.values(moveMode.items)
    const isUnderThisResource =
      resources[type === 'Resource' ? this.resourceIndex : ownResourceIndex]?.Id ===
      clickedProductInfo.resourceId
    const showMoveModeCheckbox = moveMode.isOn && isUnderThisResource
    const isMoveItemSelected = isUnderThisResource && moveMode.items[path]?.isSelected
    const resourceScheduleViewTitle = isUnderThisResource
      ? resourceScheduleViewText
      : 'Select Product'

    this.locationNames = _.keys(this.props).reduce((locationNames, prop) => {
      if (_.includes(prop, 'locationName')) {
        return [...locationNames, this.props[prop]]
      }
      return locationNames
    }, [])

    const locationsMatch = _.isEqual(this.locationNames, clickedLocationNames)

    return (
      <div
        className={`resource-row-item-container ${
          type === 'Resource' &&
          isResourceChartOpen &&
          isResourceRowItemOpen &&
          'resource-row-item-container_resource-chart-open'
        }`}
      >
        <div className="add-production-container" />
        <ul
          className={`resource-row-item-wrapper ${
            type === 'Product' &&
            filterValues.tableName &&
            filterValues.tableName !== null &&
            !operators[filterValues.operator](
              parse(format(Production, filterValues.format)),
              parse(format(filterValues.value, filterValues.format))
            )
              ? 'resource-row-item-wrapper--hidden'
              : ''
          }
          `}
          style={periodBarStyle}
          datahovertest={48}
        >
          <li
            className={`resource-row-item resource-row__resource-row-item left-sticky-column-border ${
              locationsMatch && 'resource-row-item_selected'
            }
              ${isSelectionOn && clickedPeriodIndex === 0 ? 'resource-row-item_border-right' : ''}
              ${isMoveItemSelected ? 'is-move-item' : ''}
              ${locationsMatch && _.isNil(clickedPeriodIndex) ? 'is-selected' : ''}
              ${type === 'Product' ? 'is-product' : ''}`}
            style={{
              paddingLeft: leftPadding,
              backgroundColor: getRowBgColor(this.props.globalIndex, type)
              // marginTop: type === 'Product' ? 1 : '',
            }}
            onClick={this.toggleResourceRowItem.bind(this)}
          >
            {type === 'Product' ? (
              <>
                <p className="resource-row-item__name text-truncate flex-grow-1">
                  {getProductName(name)}
                </p>
                {showMoveModeCheckbox && (
                  <span
                    onClick={(event) => {
                      this.handleToggleMoveItem(ownResourceIndex, event)
                    }}
                    style={{ position: 'relative', right: -2 }}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      {isMoveItemSelected ? (
                        <i
                          className="fa fa-fw fa fa-circle"
                          style={{
                            fontSize: 14,
                            color: isMoveItemSelected ? 'springgreen' : '#bbbbbb'
                          }}
                        />
                      ) : (
                        <i
                          className="fa fa-fw fa fa-dot-circle"
                          style={{ fontSize: 14, color: '#bbbbbb' }}
                        />
                      )}
                    </div>
                  </span>
                )}
              </>
            ) : (
              <div className="resource-row-item__name-wrapper flex-grow-1">
                {type === 'Resource' && (
                  <div
                    className={cx(
                      'pinned-row',
                      {
                        'pinned-row--on': _.some(
                          this.props.pinnedRows,
                          (item) => item.Index === thisResourceIndex
                        )
                      },
                      {
                        'pinned-row--off': !_.some(
                          this.props.pinnedRows,
                          (item) => item.Index === thisResourceIndex
                        )
                      },
                      { 'pinned-row--expanded': isPinned }
                    )}
                    style={{ background: moveMode.isOn ? 'transparent' : '' }}
                  >
                    <i
                      className="fa fa-fw fa fa-thumbtack"
                      onClick={this.handlePinResource.bind(this)}
                    />
                  </div>
                )}
                {!isPinned && (
                  <i
                    className={cx(
                      { 'slvy-ui-icon-caret-right_open': isResourceRowItemOpen },
                      'slvy-ui-icon-caret-right',
                      '-caretIndicator'
                    )}
                  />
                )}
                <p className="resource-row-item__name text-truncate flex-grow-1">{name}</p>
                {showMoveModeCheckbox && type !== 'Resource' && (
                  <span
                    className="d-flex"
                    onClick={(event) => {
                      this.handleToggleMoveItemGroup(ownResourceIndex, event)
                    }}
                    style={{ position: 'relative', right: -2 }}
                  >
                    {type !== 'Resource' && (
                      <strong className="pe-1">
                        {moveItemsArray.reduce(
                          (sum, item) =>
                            item.type === 'Product' && item.isSelected && item.path.includes(path)
                              ? sum + 1
                              : sum,
                          0
                        )}
                      </strong>
                    )}
                    {isMoveItemSelected ? (
                      <i
                        className="fa fa-fw fa fa-circle"
                        style={{
                          fontSize: 14,
                          color: isMoveItemSelected ? 'springgreen' : '#bbbbbb'
                        }}
                      />
                    ) : (
                      <i
                        className="fa fa-fw fa fa-dot-circle"
                        style={{ fontSize: 14, color: '#bbbbbb' }}
                      />
                    )}
                  </span>
                )}
              </div>
            )}

            {type === 'Resource' && !isPinned && (
              <div
                className={`resource-item-icon-container ${isResourceRowItemOpen ? 'is-open' : ''}`}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                {isResourceChartOpen && (
                  <OverlayTrigger
                    rootClose
                    container={document.body}
                    overlay={this.popoverClickRootClose(
                      {
                        ...getRccpProductsResponse,
                        ...params
                      },
                      false
                    )}
                    placement="right"
                    trigger="click"
                  >
                    <button
                      className="-slvy-btn -approve -xsmall"
                      disabled={
                        (selectedSession.IsDebug && views.newResourcePlanView) ||
                        !isUserAllowedToAddProduction
                      }
                      onClick={this.handleAddProduct.bind(this, params)}
                    >
                      <i className="slvy-ui-icon-plus-lt" />
                      Production
                    </button>
                  </OverlayTrigger>
                )}
                {!isResourceChartOpen && !moveMode.isOn && (
                  <div>
                    {isShiftCalendarViewEnabled && (
                      <button
                        key="shift-calendar"
                        className={cx('-slvy-btn -neutral -xsmall', {
                          'opacity-50': !isProductPeriod || !isUnderThisResource
                        })}
                        disabled={!isProductPeriod || !isUnderThisResource}
                        title={shiftCalendarViewTitle}
                        onClick={(e) => {
                          e.stopPropagation()
                          const req = {
                            periodId: periods[clickedPeriodIndex ?? 0]?.Id,
                            rccpConfig: {
                              ModificationCount: modificationCount
                            }
                          }
                          connectApi('GetRccpResourceLineResponse', req).then((res) => {
                            this.props.viewHandler('scheduleLineView', 'pushPage')
                          })
                        }}
                      >
                        <i className="slvy-ui-icon-export-box" />
                        {format(NumberOfEnabledShifts, shiftCalendarButtonFormat)} /{' '}
                        {format(NumberOfAvailableShifts, shiftCalendarButtonFormat)}
                      </button>
                    )}
                    {isResourceScheduleViewEnabled && (
                      <button
                        key="resource-schedule"
                        className={cx('-slvy-btn -xsmall get-resource-line', {
                          'opacity-50': !isProductPeriod || !isUnderThisResource
                        })}
                        disabled={!isUnderThisResource || !isProductPeriod}
                        title={resourceScheduleViewTitle}
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation()
                          const req = {
                            productId: products[clickedProductIndex]?.Id ?? -1,
                            periodId: periods[clickedPeriodIndex ?? 0]?.Id,
                            rccpConfig: {
                              StartDate: periods[clickedPeriodIndex]?.StartTime,
                              EndDate: periods[clickedPeriodIndex]?.EndTime,
                              ModificationCount: modificationCount
                            }
                          }

                          connectApi('GetRccpResourceActivityResponseWPF', req).then((res) => {
                            this.props.viewHandler('scheduleResourceView', 'pushPage')
                          })
                        }}
                      >
                        <i className="slvy-ui-icon-sorted" />
                        RS
                      </button>
                    )}
                  </div>
                )}
                {!moveMode.isOn && (
                  <>
                    <div>
                      <button
                        className={cx(
                          '-slvy-btn -xsmall get-resource-line',
                          {
                            'opacity-50': !isProductPeriod || !isUnderThisResource
                          },
                          {
                            'resource-item__chart-button--open': isResourceChartOpen
                          }
                        )}
                        disabled={!isProductPeriod || !isUnderThisResource}
                        title="Resource Chart"
                        onClick={this.toggleResourceChart.bind(this)}
                      >
                        <i
                          className={cx('slvy-ui-icon-chart-bar-regular resource-item__chart-icon')}
                        />
                      </button>
                    </div>
                    <i
                      className={`${
                        this.state.isExpanded
                          ? 'fa-fw fa slvy-ui-icon-minus-outline'
                          : 'fa-fw fa slvy-ui-icon-plus-outline'
                      } resource-item__expand-collapse-button`}
                      title="Expand/Collapse"
                      onClick={this.handleExpandCollapse.bind(this)}
                    />
                  </>
                )}
                {showMoveModeCheckbox && (
                  <span
                    className="d-flex resource-row-move-mode-checkbox"
                    onClick={(event) => {
                      this.handleToggleMoveItemGroup(thisResourceIndex, event)
                    }}
                    style={{ position: 'relative', right: -10 }}
                  >
                    <strong className="pe-1">
                      {moveItemsArray.reduce(
                        (sum, item) => (item.type === 'Product' && item.isSelected ? sum + 1 : sum),
                        0
                      )}
                    </strong>
                    {isMoveItemSelected ? (
                      <i
                        className="fa fa-fw fa fa-circle"
                        style={{
                          fontSize: 14,
                          color: isMoveItemSelected ? 'springgreen' : '#bbbbbb'
                        }}
                      />
                    ) : (
                      <i
                        className="fa fa-fw fa fa-dot-circle"
                        style={{ fontSize: 14, color: '#bbbbbb' }}
                      />
                    )}
                  </span>
                )}
              </div>
            )}
          </li>

          {!this.props.aIsResourceChartOpen && (
            <ResourceRowItemInfo
              paintOnClick
              connectApi={connectApi}
              index={index}
              path={path}
              initialFilters={initialFilters}
              isAllowed={isAllowed}
              globalIndex={this.props.globalIndex}
              locationNames={this.locationNames}
              locations={locations}
              settings={settings}
              stateHandler={stateHandler}
              lastRequest={lastRequest}
              testResourceIndex={ownResourceIndex}
              type={type}
            />
          )}
          {this.props.aIsResourceChartOpen && <div />}
        </ul>

        {type === 'Resource' && isResourceChartOpen && isResourceRowItemOpen && (
          <div className="rcc-wrp resource-row-item__resource-chart-wrapper">
            <div
              className="rccp-table resource-row-item__resource-chart-table"
              style={{
                top: calcTopValue(
                  pinnedRowsLength + 1,
                  selectedResourceIssueCount,
                  maxSelectedIssues
                )
              }}
            >
              <div className="resource-row-item__resource-chart" />
              <div ref="rccpbody" className="rccp-body" style={{ height: 347 }}>
                <div className="-row -active">
                  <div style={{ height: 347 }}>
                    <div style={{ height: '100%' }}>
                      <section className="-chart-box">
                        <ResourceChart
                          chartHandler={null}
                          connectApi={connectApi}
                          initialFilters={initialFilters}
                          isAllowed={isAllowed}
                          lastRequest={{}}
                          periods={periods}
                          productClickHandler={null}
                          productId={-1}
                          productPeriods={productPeriods}
                          products={products}
                          resourceId={resources[index]?.Id}
                          resourceIndex={index}
                          resourcePeriod={currentResourceChartPeriod}
                          settings={settings}
                          stateHandler={stateHandler}
                          viewHandler={null}
                        />
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isResourceRowItemOpen &&
          children.length > 0 &&
          renderChildren(
            children,
            type === 'Resource' ? isResourceChartOpen : this.props.aIsResourceChartOpen,
            type === 'Resource' ? this.resourceIndex : ownResourceIndex,
            type === 'Resource' ? this.state.isExpanded : isExpanded,
            this.locationNames,
            this.props.globalIndex
          )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isResourceChartOpen: state.clickedProductInfo.isResourceChartOpen,
  clickedProductInfo: state.clickedProductInfo.clickedProductInfo,
  resourcePeriodHierarchy: state.resourcePeriodHierarchy,
  maxSelectedIssues: state.legend.maxSelectedIssues,
  selectedResourceIssueCount: state.legend.selectedResourceIssueCount,
  resourceIndex: state.clickedProductInfo.resourceIndex,
  toggledResourceRows: state.resourceRowItem.toggledHierarchies,
  expandedResourceRows: state.resourceRowItem.expandedHierarchies,
  configSettings: state.resourcePlan.configSettings,
  allHierarchiesExpanded: state.resourceRowItem.allHierarchiesExpanded,
  pinnedRows: state.resourcePlan.pinnedRows,
  filterValues: state.resourcePlan.filterValues,
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes,
  moveMode: state.moveMode,
  moveOrigin: state.moveMode.moveOrigin,
  selectedSession: state.resourcePlan.selectedSession
})

const mapDispatchToProps = {
  onProductSelect,
  saveResourceIndex,
  toggleHierarchy,
  expandHierarchy,
  onPinRow,
  onToggleMoveItem,
  onToggleMoveItemGroup,
  onResetMoveModeItems: resetMoveModeItems
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceRowItem)
