/* This is an exception */
require('../src/view/materialedit/model/DPIFamilyList')
/**/

/* MODELS */
require('../src/view/Grouping/Edit/Model/DPITree')
require('../src/view/Grouping/Edit/Model/GroupDetails')
require('../src/view/Grouping/Edit/MainViewModel')
require('../src/view/Grouping/model/CusHie')
require('../src/view/Grouping/model/DPITreeModel')
require('../src/view/Grouping/model/Relations')
require('../src/view/Grouping/MainViewModel')

/* COMPONENTS */
require('../src/view/Grouping/Edit/GridPanel')
require('../src/view/Grouping/Edit/TreePanel')
require('../src/view/Grouping/Edit/MainController')
require('../src/view/Grouping/Edit/Main')

require('../src/view/Grouping/panel/DPIFamilies')
require('../src/view/Grouping/panel/GroupGrid')
require('../src/view/Grouping/panel/Tree')

require('../src/view/Grouping/FormDialog')
require('../src/view/Grouping/MainController')
require('../src/view/Grouping/Main')
