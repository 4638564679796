Ext.define('Spui.view.tracking.leftside.grid.Main', {
  extend: 'Ext.panel.Panel',
  config: {
    gridType: null
  },

  // Uncomment to give this component an xtype
  xtype: 'spui-view-tracking-leftside-grid-main',
  mixins: ['Ext.util.StoreHolder'],
  layout: 'fit',
  flex: 1,
  plugins: [
    {
      ptype: 'cornerIcon',
      extraButton: [
        {
          iconCls: 'x-fa fa-arrows-alt',
          handler: this.onmaximize,
          scope: this
        }
      ],
      cfgButton: [
        '-',
        {
          iconCls: 'x-fa fa-arrows-alt',
          cls: 'config-btn',
          handler: this.onmaximize2,
          scope: this
        }
      ]
    }
  ],
  updateGridType: function (c) {
    var newGrid = Ext.create('Ext.grid.Panel', {
      columnLines: true,
      columns: Spui.view.tracking.leftside.grid.Columns.getColumns(c),
      bind: {
        store: '{SpotListStore}'
      }
    })

    if (!this.isConfiguring) {
      var grid = this.down('grid')
      grid.destroy()
      this.add(newGrid)
    } else {
      this.items = [newGrid]
    }
  }
})
