import { useState, useRef, useEffect, MouseEvent } from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import { OverlayTrigger, ListGroup, Popover, Modal, Button } from 'react-bootstrap'
import { isEmpty } from 'lodash'
import { slvyToast } from '@/components'
import { selectSession, setSessions } from '../../../../actions/resourcePlan'
import { SessionsProps, Session } from './Sessions.types'
import { setScrollPos } from '../../../../actions'

const Sessions = ({
  settings,
  initialSessions,
  sessions,
  selectedSession,
  onSetScrollPos,
  onSelectSession,
  onSetSessions,
  scrollPos,
  moveMode,
  isSupplyPlan,
  onConnectApi
}: SessionsProps) => {
  const [sessionFilter, setSessionFilter] = useState('')
  const [visibleSessions, setVisibleSessions] = useState(sessions)
  const [show, setShow] = useState(false)
  const listRef = useRef<HTMLDivElement>(null)
  const listItemRef = useRef<HTMLDivElement>(null)
  const filterRef = useRef<HTMLInputElement>(null)
  const [listWidth, setListWidth] = useState(0)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [clickedSession, setClickedSession] = useState<Session>()

  useEffect(() => {
    if (show) {
      if (listRef.current) {
        listRef.current.scrollTop = scrollPos?.supplyPlan?.sessionList?.top
      }
      if (filterRef.current) {
        filterRef.current?.focus({ preventScroll: true })
      }
    }

    return () => {
      setSessionFilter('')
    }
  }, [show])

  const handleSelectSession = (session: Session) => {
    onSelectSession(session)

    const req = {
      rccpConfig: {
        ModificationCount: session.Id,
        RequestType: session.IsDebug ? 'Debug' : 'Get'
      }
    }

    setShow(false)
    onConnectApi('GetRccpResourcePeriodHierarchyResponseWPF', req)
      .then(() => {
        slvyToast.success({ message: 'Snapshot selected successfully!' })
      })
      .catch(() => slvyToast.error({ message: 'Something went wrong. Please try again later.' }))
  }

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    onSetScrollPos({
      view: 'supplyPlan',
      scrollArea: 'sessionList',
      scrollPos: {
        top: event.target.scrollTop,
        left: event.target.scrollLeft
      }
    })
  }

  const handleChangeSessionFilter = ({ target: { value } }: { target: { value: string } }) => {
    const nextVisibleSessions = sessions?.filter((session) =>
      session.Name.toLowerCase().includes(value)
    )

    setSessionFilter(value)
    setVisibleSessions(nextVisibleSessions)
  }

  const handleSetSnapshot = () => {
    setShow(false)
    setShowWarningModal(false)
    onConnectApi('SetSnapshot', { id: clickedSession?.Id })
      .then(({ Description, Code }: { Description: string; Code: number }) => {
        if (Code === 1) {
          slvyToast.success({ message: Description })
          onConnectApi('GetRccpResourcePeriodHierarchyResponseWPF', {
            rccpConfig: { ModificationCount: 10000 }
          })
          onSetSessions([])
          onSelectSession({ ...clickedSession, Name: 'Current' })
        } else {
          slvyToast.error({ message: Description })
        }
      })
      .catch(() => {
        slvyToast.error({
          message: 'Something went wrong. Please try again later.'
        })
      })
  }

  const handleAddSnapshot = () => {
    onConnectApi('AddSnapshot', { name: sessionFilter, isQueue: true })
      .then(
        ({
          Description,
          Sessions,
          Code
        }: {
          Description: string
          Sessions: Session[]
          Code: number
        }) => {
          setSessionFilter('')
          if (Code === 1) {
            setVisibleSessions(Sessions)
            slvyToast.success({ message: Description })
            onSetSessions(Sessions)
          } else {
            slvyToast.error({ message: Description })
          }
        }
      )
      .catch(() =>
        slvyToast.error({
          message: 'Something went wrong. Please try again later.'
        })
      )
  }

  const handleShowSessions = () => {
    if (!sessions?.length) {
      onSetSessions(initialSessions)
    }
    if (sessions?.length) {
      setVisibleSessions(sessions)
    } else {
      setVisibleSessions(initialSessions)
    }
    setShow(true)
  }

  const handleShowWarningModal = (event: MouseEvent<HTMLButtonElement>, session: Session) => {
    event.stopPropagation()
    setClickedSession(session)
    setShowWarningModal(true)
  }

  const handleHideWarningModal = (event: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    setShowWarningModal(false)
  }

  const renderSessionsPopup = () => (
    <Popover bsPrefix="sessions-popup popover" id="popover-basic">
      <Popover.Body
        style={{ minWidth: listWidth }}
        ref={listRef}
        className="sessions-list overflow-auto"
        onScroll={handleScroll}
      >
        <ListGroup variant="flush">
          {initialSessions ? (
            <>
              <input
                ref={filterRef}
                className="sessions-list-item sessions-list-filter"
                placeholder="Search snapshots..."
                type="text"
                value={sessionFilter}
                onChange={handleChangeSessionFilter}
              />
              {visibleSessions?.map((session, index) => {
                const isCurrentSession = session.Name.toLowerCase() === 'current'
                const currentSessionName = isCurrentSession
                  ? visibleSessions.find((visibleSession) => visibleSession.Id === session.Id)?.Name
                  : 'last'

                return (
                  <ListGroup.Item
                    key={`${session.Id}-${session.Name}`}
                    ref={listItemRef}
                    action
                    active={
                      session.Id === selectedSession?.Id && session.Name === selectedSession.Name
                    }
                    className="sessions-list-item is-debug"
                    variant="light"
                    onClick={() => handleSelectSession(session)}
                  >
                    <div>
                      {session.IsDebug ? <i title="debug" className="fa fa-fw fa fa-bug" /> : null}
                      {isCurrentSession ? `${session.Name} (${currentSessionName})` : session.Name}
                    </div>
                    <div style={{ width: 60, height: 20 }} />
                    {session.IsDebug ? (
                      <button
                        className="session-list-item-button"
                        type="button"
                        onClick={(event) => handleShowWarningModal(event, session)}
                      >
                        set
                      </button>
                    ) : null}
                  </ListGroup.Item>
                )
              })}
              {sessionFilter &&
                isEmpty(
                  visibleSessions?.filter((session) => session.Name.toLowerCase() === sessionFilter)
                ) && (
                  <ListGroup.Item
                    key="add-new-session"
                    action
                    className="sessions-list-item"
                    variant="light"
                  >
                    <strong>Add new snapshot &quot;{sessionFilter}&quot;</strong>
                    <button
                      className="session-list-item-button visible"
                      type="button"
                      onClick={handleAddSnapshot}
                    >
                      add
                    </button>
                  </ListGroup.Item>
                )}
            </>
          ) : (
            <ListGroup.Item className="text-center">No sessions</ListGroup.Item>
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  )

  return settings.isEnabled && isSupplyPlan ? (
    <>
      <OverlayTrigger
        rootClose
        overlay={renderSessionsPopup()}
        placement="bottom"
        show={show}
        trigger="click"
        onEntered={() => setListWidth(listRef.current?.offsetWidth)}
        onToggle={() => setShow(false)}
      >
        <button
          className={cx({ '-active': show || !isEmpty(selectedSession) })}
          disabled={moveMode.isOn}
          type="button"
          onClick={handleShowSessions}
        >
          <i className={cx('fw-normal', settings.buttonIcon)} />
          <span>{settings.buttonTitle}</span>
        </button>
      </OverlayTrigger>
      <Modal
        className="sessions-warning-modal"
        show={showWarningModal}
        onHide={handleHideWarningModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-6">
          Are you sure you want to set this snapshot? Please note that doing so will delete all
          snapshots that follow.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideWarningModal}>
            Close
          </Button>
          <Button variant="danger" onClick={handleSetSnapshot}>
            Set Snapshot
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : null
}

const mapStateToProps = (state: any) => ({
  initialSessions: state.resourcePeriodHierarchy.Sessions,
  sessions: state.resourcePlan.sessions,
  selectedSession: state.resourcePlan.selectedSession,
  scrollPos: state.scrollPositions,
  moveMode: state.moveMode
})
const mapDispatchToProps = {
  onSelectSession: selectSession,
  onSetSessions: setSessions,
  onSetScrollPos: setScrollPos
}

export default connect(mapStateToProps, mapDispatchToProps)(Sessions)
