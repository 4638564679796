Ext.define('MDM.Btlv2.upload.Dialog', {
  extend: 'Ext.window.Window',
  title: 'BTL Excel Upload Dialog',
  width: 1200,
  height: 600,
  maximized: false,
  closable: false,
  defaultListenerScope: true,
  ui: 'window-inverse',
  maximizable: false,
  resize: false,
  modal: true,
  viewModel: {},
  layout: 'border',
  importData: [],
  onShowDialog: function (grid, rowIndex, colIndex, item, e, record) {
    var win = Ext.create('MDM.src.view.massimport.FormDialog', {
      record: record,
      isMass: false
    })
    if (this.menu_grid) this.menu_grid.destroy()
    this.menu_grid = new Ext.menu.Menu({
      bodyPadding: 2,
      border: false,
      shadow: false,
      items: [win]
    })
    var position = e.getXY()
    ;(position[1] = position[1] + 20), e.stopEvent()
    this.menu_grid.showAt(position)
  },
  initComponent: function () {
    var me = this
    me.items = [
      {
        xtype: 'massimport-grid',
        importData: me.importData
      }
    ]
    me.callParent()
  },
  bbar: [
    '->',
    {
      text: 'Move To DPI',
      width: 110,
      handler: 'onClickApply',
      bind: {
        disabled: '{!processedgrid.selection}'
      }
    },
    {
      margin: '0 0 0 10',
      text: 'Close',
      width: 110,
      handler: function (btn) {
        btn.up('window').close()
      }
    }
  ],
  doRemoveTotalCls: function (cls) {
    var me = this,
      body = me.rendered ? me.body : me.getProtoBody()
    body.removeCls(cls)
    return me
  },
  onClickApply: function () {
    var me = this,
      grid = me.down('massimport-grid'),
      records = grid.getSelection()
    records.forEach(function (record) {
      var BTLType = record.get('BTLType'),
        Title = ''
      if (BTLType == 'I') Title = 'GU Inventory'
      else if (BTLType == 'D') Title = 'NA Fcst - Revenue'
      else if (BTLType == 'D') Title = record.get('SupplySource')
      record.set('Title', Title)
    }, this)
    me.fireEvent('applyxls', records)
    me.close()
  }
})
