import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { saveHeaderSettings } from '../../store/actions'
import { IUpdateFormProps } from './UpdateForm.types'

const UpdateForm = ({ buttonFormat }: IUpdateFormProps) => {
  const dispatch = useDispatch()

  function onUpdateButtonFormat(format: string) {
    dispatch(
      saveHeaderSettings({
        payload: { editMode: { format } }
      })
    )
  }

  const isPercentage = buttonFormat === 'percentage'

  return (
    <div className="unitToggle">
      <span className="slvy-ui-icon-symbol-absolute" />
      <span
        className={isPercentage ? 'slvy-ui-icon-toggle-on' : 'slvy-ui-icon-toggle-off'}
        onClick={() => onUpdateButtonFormat(isPercentage ? 'absolute' : 'percentage')}
      />
      <span className="slvy-ui-icon-symbol-percentage" />
    </div>
  )
}

const MemoizedUpdateForm = memo(
  UpdateForm,
  (prevProps: IUpdateFormProps, nextProps: IUpdateFormProps) => {
    return prevProps.buttonFormat === nextProps.buttonFormat
  }
)

export default MemoizedUpdateForm
