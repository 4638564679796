import React, { Component } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { dataMap } from '../utils/constants'
import { relocateScroll } from '../utils'

export default class Sort extends Component {
  constructor() {
    super()

    this.createSortRequest = this.createSortRequest.bind(this)
    this.getCurrentPageRequest = this.getCurrentPageRequest.bind(this)
    this.getIssueName = this.getIssueName.bind(this)
  }

  getCurrentPageRequest() {
    const {
      settings: { views = {} },
      lastRequest = {}
    } = this.props
    const { getRequest } = dataMap[_.findKey(views, (v) => v)]

    return {
      Method: getRequest,
      ...lastRequest[getRequest]
    }
  }

  createSortRequest() {
    const {
      issueList,
      period,
      settings: {
        modificationCount = 0,
        stateHandler: {
          sortState: { Aggregation, IsAscending }
        }
      }
    } = this.props

    const currentPageRequest = this.getCurrentPageRequest()
    const { Request: { rccpConfig = {} } = {} } = currentPageRequest
    const IssueName = this.getIssueName()

    const SortConfig = {
      Aggregation,
      AvailableAggregations: ['Sum', 'Average', 'SinglePeriod'],
      AvailableIssueNames: _.map(issueList, (i) => i.tableName),
      IsAscending,
      IssueName,
      PeriodIndex: _.map(
        _.filter(period, (item) => item.Visible),
        (p, i) => i
      )
    }

    currentPageRequest.Request.rccpConfig = {
      ...rccpConfig,
      ...{ ModificationCount: modificationCount },
      ...{ SortConfig }
    }

    return currentPageRequest
  }

  sort(params = {}) {
    this.props.stateHandler({ sortState: { ...params } }).then(() => {
      const {
        connectApi,
        updateProductDictionary,
        settings: { sessionEnvironments: { productId: targetId } = {} }
      } = this.props

      const { Method = '', Request = {} } = this.createSortRequest()

      connectApi(Method, Request).then((res) => {
        updateProductDictionary()
        relocateScroll(targetId)
      })
    })
  }

  getIssueName() {
    const {
      issueList,
      productInfoList,
      isProductInfo,
      currentIssueIndex,
      currentProductInfoIndex
    } = this.props

    if (isProductInfo) {
      return productInfoList?.[currentProductInfoIndex].tableName
    }
    return issueList?.[currentIssueIndex].tableName
  }

  render() {
    const {
      settings: {
        stateHandler: { sortState }
      }
    } = this.props
    const IssueName = this.getIssueName()

    return (
      <div className="-sort">
        <i
          className={cx('-btn-up', 'slvy-ui-icon-caret-top', {
            active: sortState?.IsAscending === 1 && sortState?.IssueName === IssueName
          })}
          onClick={this.sort.bind(this, { Aggregation: 'sum', IsAscending: 1, IssueName })}
        />
        <i
          className={cx('-btn-down', 'slvy-ui-icon-caret-down', {
            active: sortState?.IsAscending === 0 && sortState?.IssueName === IssueName
          })}
          onClick={this.sort.bind(this, { Aggregation: 'sum', IsAscending: 0, IssueName })}
        />
      </div>
    )
  }
}
