Ext.define('MDM.src.view.Grouping.Edit.GridPanel', {
  extend: 'Ext.grid.Panel',
  xtype: 'grouping-edit-gridpanel',
  iconCls: 'fa fa-list',
  columnLines: true,
  selModel: {
    type: 'cellmodel'
  },
  features: [
    {
      ftype: 'grouping',
      startCollapsed: true,
      groupHeaderTpl: '{name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  columns: [
    {
      text: 'DPI Families',
      dataIndex: 'DPIFamilyID',
      flex: 1,
      sortable: true,
      renderer: function (value, metaData, record) {
        if (record.get('DPIFamilyID')) {
          return record.get('DPIFamily')
        }
        return 'Not selected'
      }
    },
    {
      xtype: 'actioncolumn',

      width: 50,
      sortable: false,
      menuDisabled: true,
      align: 'center',
      items: [
        {
          iconCls: 'fa fa-chain-broken',
          tooltip: 'Remove DPI Famly',
          handler: 'onDPIBrokeChain'
        }
      ]
    }
  ]
})
