import { GridOptions } from 'ag-grid-community'
import { IOptionLabelValue } from '@/components/SlvyFormSelect/SlvyFormSelect.types'
import { EditableFields, IRowData, LocaleText } from '../../../types'

const MassUpdateNumberTypeOptions: { value: string }[] = [
  {
    // label: 'Overwrite'
    value: 'overwrite'
  },
  {
    // label: 'Increment'
    value: 'increment'
  },
  {
    // label: 'Percentage'
    value: 'percentage'
  }
]

const getMassUpdateNumberTypeOptionsByLocale = (localeText: LocaleText): IOptionLabelValue[] => {
  return MassUpdateNumberTypeOptions.map(({ value }) => {
    return {
      value,
      label: localeText[value]
    }
  })
}

const editableFields: EditableFields = []

const constantMassUpdateGridProps: {
  headerHeight: 0
  stopEditingWhenCellsLoseFocus: false
  getMainMenuItems: GridOptions<IRowData>['getMainMenuItems']
  getContextMenuItems: GridOptions<IRowData>['getContextMenuItems']
} = {
  headerHeight: 0,
  stopEditingWhenCellsLoseFocus: false,
  getMainMenuItems: () => [],
  getContextMenuItems: () => []
}

export {
  MassUpdateNumberTypeOptions,
  editableFields,
  constantMassUpdateGridProps,
  getMassUpdateNumberTypeOptionsByLocale
}
