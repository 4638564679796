Ext.define('clustering.cmp.ClusterField', {
  extend: 'Ext.form.field.Text',
  xtype: 'clusterfield',
  editable: false,
  containerViewModel: null,
  initComponent: function () {
    var me = this
    this.setTriggers({
      search: {
        cls: 'x-form-search-trigger',
        handler: me.onClickSearch
      }
    })

    var containerViewModel = me.containerViewModel

    this.callParent()
  },

  onClickSearch: function () {
    var me = this

    Ext.create('clustering.ClusterLibrary.ClusterLibrary', {
      dockedItems: [
        {
          xtype: 'toolbar',
          dock: 'bottom',
          layout: {
            type: 'hbox',
            pack: 'center'
          },
          defaults: {
            flex: 1
          },
          items: [
            {
              text: 'New Folder',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                if (!currentRow) return

                var clusterLibrary = this.lookupReferenceHolder()
                var references = clusterLibrary.getReferences()
                var clusterTree = references['clusterTree']

                if (!clusterTree) return

                me.getEl().mask('Loading...')

                var nodeId = currentRow.get('Id')

                Ext.Ajax.request({
                  url: clustering.Global.getApiUrl() + 'librarytree/CreateFolderNode',
                  params: {
                    MotherId: nodeId,
                    Name: 'New Folder',
                    Status: 'FOLDER',
                    ProjectId: 0
                  },
                  timeout: 600000,
                  success: function (response) {
                    var result = Ext.JSON.decode(response.responseText)

                    if (result.Code > -1) {
                      clustering.util.operationSuccessWithMessage(result.Description)
                      clusterTree.store.reload()
                    } else {
                      clustering.util.operationFailWithMessage(result.Description)
                    }

                    me.getEl().unmask()
                  },
                  failure: function () {
                    me.getEl().unmask()
                    alert('Create folder node has failed.')
                  }
                })
              },
              bind: {
                disabled: '{!isFolderSelected}'
              }
            },
            {
              text: 'Delete',
              iconCls: 'fa fa-folder-open-o',
              handler: function () {
                var me = this.lookupReferenceHolder()
                var currentRow = me.getViewModel().get('currentRow')

                if (currentRow) {
                  var nodeId = currentRow.get('Id')

                  var clusterLibrary = this.lookupReferenceHolder()
                  var references = clusterLibrary.getReferences()
                  var clusterTree = references['clusterTree']

                  if (clusterTree) {
                    Ext.Msg.confirm(
                      'Confirm Delete',
                      'Are you sure you want to delete?',
                      function (result) {
                        if (result === 'yes') {
                          Ext.Ajax.request({
                            url:
                              clustering.Global.getApiUrl() +
                              'librarytree/DeleteNode' +
                              '?nodeId=' +
                              nodeId,
                            timeout: 600000,
                            success: function (response) {
                              var result = Ext.JSON.decode(response.responseText)

                              if (result.Code > -1) {
                                clustering.util.operationSuccessWithMessage(result.Description)
                                clusterTree.store.reload()
                              } else {
                                clustering.util.operationFailWithMessage(result.Description)
                              }
                            },
                            failure: function () {}
                          })
                        }
                      }
                    )
                  }
                }
              },
              bind: {
                disabled: '{!currentRow}'
              }
            },
            {
              text: 'Select Folder',
              handler: function () {
                var clusterLibrary = this.lookupReferenceHolder()
                var currentRow = clusterLibrary.getViewModel().get('currentRow')

                if (!currentRow) return

                var references = clusterLibrary.getReferences()
                var clusterTree = references['clusterTree']

                if (!clusterTree) return

                var nodeId = currentRow.get('Id')
                me.setValue(currentRow.get('Name'))
                me.nodeId = nodeId
                clusterLibrary.close()
              },
              bind: {
                disabled: '{!isFolderSelected}'
              }
            }
          ]
        }
      ]
    }).show()
  }
})
