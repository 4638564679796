import React, { Component, createRef } from 'react'
import _ from 'lodash'
import { slvyToast } from '../../../../components'
import GridDetail from './grid-detail'
import { getLockState, getApproveState } from '../Util'
import { GridHeader, CoefButtons } from '../GridCommon/index.ts'
import { MESSAGES } from '../../messages'
import OutsideClickHandler from '../../OutsideClickHandler'
import cx from 'classnames'
import styles from './index.module.scss'

export default class GridDetailContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CurrentValue: '',
      SelectedHierarchy: '',
      focusMode: false,
      data: {
        row: [],
        column: []
      },
      selectedRowId: {
        cell: 0,
        row: 0
      }
    }

    this.uiGridCtnRef = createRef()

    this.click = this.click.bind(this)
    this.clickNode = this.clickNode.bind(this)
    this.setSelectedRowId = this.setSelectedRowId.bind(this)
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    const { updateMode = 0 } = this.props
    if (updateMode === 1) {
      // isSingle and isRowUpdate
      this.props.saveHeaderSettings({
        payload: { updateMode: 0 }
      })
      slvyToast.warning({
        message: MESSAGES.row_update_is_not_available_in_the_detail_and_the_single_detail_view,
        options: { containerId: 'dp' }
      })
      return
    }

    this.uiGridCtnRef.current.addEventListener('click', this.clickNode)
  }

  componentWillUnmount() {
    this.uiGridCtnRef.current.removeEventListener('click', this.clickNode)
  }

  clickNode(event, isOutsideClicked = false) {
    const { updateMode = 0, viewMode = 0 } = this.props
    if (viewMode === 2 && (updateMode === 0 || updateMode === 1)) {
      if (event.target.tagName.toUpperCase() !== 'INPUT' || isOutsideClicked) {
        this.setSelectedRowId(0)
      }
    }
  }

  update(params) {
    const { ExternalEdit = false } = params
    ExternalEdit ? this.props.updateExternalCallback(params) : this.props.updateCallback(params)
  }

  setSelectedRowId(cell, row) {
    this.setState({ selectedRowId: { cell, row } })
  }

  click(SelectedHierarchy, IsParent) {
    const {
      pivotState: { LeftAxisDraggable: LeftFields = [] },
      leftHierarchy: LeftHierarchy = [],
      pivotField: PivotField = ''
    } = this.props

    const isLastHierarchy = LeftFields.length - 1 === LeftHierarchy.length

    if (IsParent) {
      this.setState({ SelectedHierarchy })
      if (LeftHierarchy.length > 1) {
        LeftHierarchy.pop()
      }
    } else if (!isLastHierarchy) {
      LeftHierarchy.push(SelectedHierarchy)
    }

    if (!isLastHierarchy || IsParent) {
      this.props.pivotCallback({
        LeftKeys: LeftHierarchy,
        MetricName: PivotField
      })
    }
  }

  getHeaders(DetailList, Headers) {
    const { Metrics: TargetMetrics = [] } = DetailList.length ? _.first(DetailList) : {}

    return _.map(Headers, (item, index) => {
      const targetMetricValues = TargetMetrics[index] || {}
      const mergedMetricValues = {
        ...(!_.isEmpty(targetMetricValues) && {
          ...targetMetricValues,
          ...{
            ApproveKey: getApproveState(targetMetricValues.IsApproved),
            LockKey: getLockState(targetMetricValues.IsLocked)
          }
        })
      }

      return {
        Name: item,
        ...mergedMetricValues
      }
    })
  }

  _getMappedGridData(DetailList, SelectedHierarchy, foundIndex, Metrics, Headers) {
    const gridData = _.map(DetailList, (item, index) => {
      const { LeftKeys: Name = '', Metrics: ItemMetrics = [], IsLocked: _IsLocked } = item
      const LeftKey = _.first(Name)
      const isKeyInArray = _.isEqual(LeftKey, SelectedHierarchy)
      return {
        LeftKeys: { Name: LeftKey },
        Metrics: _.map(ItemMetrics, (metricItem) => metricItem.Metrics[foundIndex]),
        IsLocked: _IsLocked,
        Children: isKeyInArray
          ? _.map(Metrics, (metricItem, metricIndex) => {
              return {
                LeftKeys: metricItem,
                Metrics: _.map(Headers, (headerItem, headerIndex) => {
                  const cellItem = DetailList[index].Metrics[headerIndex]
                  const newItem = cellItem.Metrics[metricIndex]
                  newItem.map((item, index) => {
                    if (typeof item !== 'object' && index + 1 === newItem.length) {
                      newItem.push({ IsLocked: cellItem.IsLocked, IsApproved: cellItem.IsApproved })
                    }
                    return item
                  })
                  return newItem
                })
              }
            })
          : []
      }
    })

    _.reduce(
      gridData,
      (arr, item) => {
        const isNotEmpty = !_.isEmpty(item.Children) ? 'is-active' : 'is-not-active'
        item.ClassName = `-default ${isNotEmpty}`
        item.IsParent = true

        arr.push(item)

        if (isNotEmpty) {
          _.each(item.Children, (subItem) => {
            subItem.ClassName = '-opened'
            subItem.IsParent = false

            arr.push(subItem)
          })
        }
        return arr
      },
      []
    )

    return gridData
  }

  render() {
    let {
      props: {
        buttonFormat,
        culture,
        isScenarioEditable = false,
        leftHierarchy = [],
        nodeActionCallback,
        pivotData: { Result: { Metrics = [], DetailList = [], Headers = [] } = {} } = {},
        pivotState: { ValuesDraggable = [] },
        setHierarchy,
        settings,
        settings: { coef, coef: { buttons: coefSettingsButtons = [] } = {} } = {},
        statePicker: { allowedToUpdate = true } = {},
        updateMode = 0,
        viewMode = 0
      },
      state: { selectedRowId, SelectedHierarchy }
    } = this

    const isVisibleCoefSettingsButtons =
      isScenarioEditable && allowedToUpdate && coefSettingsButtons.length && DetailList.length === 1

    const leftKeys = isVisibleCoefSettingsButtons ? DetailList[0].LeftKeys : []

    let foundIndex = _.findIndex(Metrics, (metric) => metric.Name === '')

    // TODO: Because left hierarchy belong with current view
    foundIndex = foundIndex >= 0 ? foundIndex : 0

    ValuesDraggable = _.reduce(
      ValuesDraggable,
      (arr, e) => {
        const foundElement = _.find(Metrics, (b) => {
          return b.Name === e.Name
        })
        if (foundElement) {
          arr.push(e)
        }
        return arr
      },
      []
    )
    SelectedHierarchy = _.isEmpty(SelectedHierarchy) ? _.last(leftHierarchy) : SelectedHierarchy

    const calculatedLeftHierarchy = !_.isEmpty(SelectedHierarchy)
      ? [..._.slice(_.cloneDeep(leftHierarchy), 0, leftHierarchy.length - 1), SelectedHierarchy]
      : leftHierarchy

    const gridData = this._getMappedGridData(
      DetailList,
      SelectedHierarchy,
      foundIndex,
      Metrics,
      Headers
    )

    const {
      isApprove,
      isLock,
      messages: { summary_text = '' }
    } = settings

    Headers = this.getHeaders(DetailList, Headers)

    Headers = _.slice(Headers, 1, Headers.length)

    const GridHeaders = Headers.map((header) => ({ ...header, Text: header.Name }))

    const tableStyle = {
      width: Headers.length * 140 + 380
    }

    return (
      <div className="grid-detail-common-container">
        <OutsideClickHandler onOutsideClick={(event) => this.clickNode(event, true)}>
          <div ref={this.uiGridCtnRef} className="slvy-ui-grid-ctn">
            <div className="slvy-ui-table" style={tableStyle}>
              <div className={cx('position-absolute', 'm-3', styles.CoefWrapper)}>
                {isVisibleCoefSettingsButtons ? (
                  <CoefButtons coef={coef} leftKeys={leftKeys} actionCall={nodeActionCallback} />
                ) : null}
              </div>
              <GridHeader
                hasSummary
                actionButtonsVisibility={{ isLock, isApprove }}
                hasActionButtons={isScenarioEditable && allowedToUpdate}
                headers={GridHeaders}
                summaryText={summary_text}
                onApproveActionHandle={(ApproveKey) =>
                  nodeActionCallback(leftHierarchy, ApproveKey)
                }
                onLockActionHandle={(LockKey) => nodeActionCallback(leftHierarchy, LockKey)}
              />
              {gridData.length ? (
                <div className="slvy-ui -grid-body">
                  <div className="-angle-container">
                    {_.map(gridData, (item, index) => {
                      const { ClassName = '' } = item
                      return (
                        <GridDetail
                          key={index}
                          allowedToUpdate={allowedToUpdate}
                          buttonFormat={buttonFormat}
                          className={ClassName}
                          click={this.click}
                          culture={culture}
                          data={item}
                          getLeftHierarchy={leftHierarchy}
                          index={index}
                          isScenarioEditable={isScenarioEditable}
                          leftHierarchy={calculatedLeftHierarchy}
                          parent={gridData.length}
                          selectedRowId={selectedRowId}
                          setHierarchy={setHierarchy}
                          setSelectedRowId={this.setSelectedRowId}
                          settings={settings}
                          topFields={Headers}
                          updateMode={updateMode}
                          values={ValuesDraggable}
                          viewMode={viewMode}
                          onUpdate={this.update}
                        />
                      )
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  }
}
