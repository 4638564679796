import { useCallback, useState, useMemo } from 'react'
import { GridOptions } from 'ag-grid-community'
import { AG_GRID_ENUMS } from '../../enums'
import { emptyArray } from '../../constants'
import { EmptyArray, IRowData, PluginSorter, SortState } from '../../types'
import { HandleSortProps } from './useHandleSort.types'

const { AUTO_GROUP_COLUMN } = AG_GRID_ENUMS

function useHandleSort(props: HandleSortProps) {
  const { isPaginationEnabled, gridRef, setCurrentPage } = props

  // const refreshCellsTimeoutRef = useRef<any>(null)

  const [sortModel, setSortModel] = useState<SortState[]>([])

  const onSortChanged: GridOptions<IRowData>['onSortChanged'] = useCallback(() => {
    const colState = gridRef?.current?.api?.getColumnState?.() || emptyArray

    // console.log('colState', colState)

    const sortState = colState
      .filter(({ sort, colId }) => {
        return sort != null && sort !== undefined && colId !== AUTO_GROUP_COLUMN
      })
      .map(({ colId, sort }) => {
        return {
          colId,
          sort
        }
      }) as SortState[]

    if (isPaginationEnabled) {
      setCurrentPage(1)
    }

    setSortModel(sortState)

    // TODO: Workaround
    // After sort change, Date column could not render proper data. It display empty but there is a data.
    // https://ci-test.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/1/menu/65c4ac681d262e72b172a6e4/page/65e720818f659a686904468f
    // Timeout must be at least 500ms and force: true must be passed to the refreshCells
    // clearTimeout(refreshCellsTimeoutRef?.current)
    // refreshCellsTimeoutRef.current = setTimeout(() => {
    //  gridRef?.current?.api.refreshCells({ force: true })
    // }, 500)
  }, [gridRef, isPaginationEnabled, setCurrentPage])

  const pluginSorters: PluginSorter[] | EmptyArray = useMemo(() => {
    if (!isPaginationEnabled) {
      return emptyArray
    }

    return sortModel.map(({ colId, sort }) => ({ [colId]: sort }))
  }, [isPaginationEnabled, sortModel])

  return { onSortChanged, pluginSorters }
}

export default useHandleSort
