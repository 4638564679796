Ext.define('Spui.view.common.spotParameters.Form', {
  extend: 'Ext.form.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-spotparameters-form',

  // Uncomment to give this component an xtype

  reference: 'inputForm',
  modelValidation: true,

  items: [
    {
      title: tt('MaterialInformation'),
      //cls:'prodfuctInfo',
      xtype: 'fieldset',
      border: true,
      style: {
        //  padding: '0px 15px 10px 15px'
      },

      fieldDefaults: {
        labelAlign: 'left',
        labelWidth: 130,
        msgTarget: 'side'
      },
      items: [
        {
          xtype: 'fieldcontainer',
          labelStyle: 'font-weight:bold;padding:0;',
          layout: 'hbox',
          defaultType: 'numberfield',
          items: [
            {
              name: 'Ürün Fiyati',
              xtype: 'textfield',
              flex: 1,
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ],
              bind: {
                value: '{spotRecord.SalesPrice}'
              },
              fieldLabel: 'Satis Fiyati',
              allowBlank: false
            },
            {
              flex: 1,

              xtype: 'container'
            }
          ]
        },

        {
          xtype: 'combobox',

          queryMode: 'local',
          displayField: 'name',
          valueField: 'abbr',
          anchor: '50%',

          bind: {
            value: '{spotRecord.UnitofMeasurement}'
          },

          fieldLabel: 'Dagitim Birimi',
          store: [
            { abbr: 'KOL', name: 'Koli' }
          ]
        },
        {
          xtype: 'fieldcontainer',
          labelStyle: 'font-weight:bold;padding:0;',
          layout: 'hbox',
          defaultType: 'numberfield',
          items: [
            {
              flex: 1,
              name: 'middleInitial',
              fieldLabel: 'Mag.Sevk Min.Miktar',
              tooltip: 'Magaza minimum siparis birim',

              minValue: 0,
              step: 1,
              bind: {
                value: '{spotRecord.MinQuantity}'
              }
            },
            {
              flex: 1,

              // afterLabelTextTpl: [
              //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              // ],

              fieldLabel: 'Mag.Sevk Max.Miktar',

              margin: '0 0 0 5',
              minValue: 0,
              step: 1,
              bind: {
                value: '{spotRecord.MaxQuantity}'
              }
            }
          ]
        },
        {
          xtype: 'fieldcontainer',
          labelStyle: 'font-weight:bold;padding:0;',
          layout: 'hbox',
          defaultType: 'textfield',
          items: [
            {
              flex: 1,
              name: 'middleInitial',
              fieldLabel: 'Baslangic Tarihi',
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ],
              xtype: 'datefield',
              format: 'd-m-Y',
              disabledDays: [0, 1, 2, 3, 5, 6, 7],
              minValue: new Date(),
              allowBlank: false,
              listeners: {
                change: function (c, d) {}
              },
              bind: {
                value: '{spotRecord.PreStartDate}'
              }
            },
            {
              flex: 1,
              name: 'lastName',
              afterLabelTextTpl: [
                '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              ],
              fieldLabel: 'Bitis Tarihi',
              format: 'd-m-Y',
              margin: '0 0 0 5',
              xtype: 'datefield',
              disabledDays: [0, 1, 2, 3, 5, 6, 7],
              allowBlank: false,

              bind: {
                value: '{spotRecord.PreEndDate}'
                // minValue: Ext.Date.add('{spotRecord.PreStartDate}', Ext.Date.DAY, 21),
              }
            }
          ]
        },
        {
          xtype: 'fieldcontainer',

          labelStyle: 'font-weight:bold;padding:0;',
          layout: 'hbox',
          defaultType: 'textfield',

          items: [
            {
              flex: 1,
              name: 'middleInitial',
              fieldLabel: 'CreatedBy',

              disabled: true
            },
            {
              flex: 1,
              name: 'lastName',
              // afterLabelTextTpl: [
              //     '<span style="color:red;font-weight:bold" data-qtip="Required">*</span>'
              // ],
              fieldLabel: 'CreatedAt',
              allowBlank: false,
              disabled: true,
              margin: '0 0 0 5'
            }
          ]
        }
      ]
    }
  ]
})
