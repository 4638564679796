Ext.define('MDM.btlv2.upload.Model.Demand', {
  extend: 'Ext.data.Model',
  proxy: {
    type: 'memory'
  },
  fields: [
    {
      name: 'RegionID',
      type: 'number'
    },
    {
      name: 'RegionID',
      type: 'number'
    },
    {
      name: 'MaterialID',
      type: 'number'
    },
    {
      name: 'MaterialTypeID',
      type: 'number'
    },
    {
      name: 'BTLType',
      type: 'string'
    },
    {
      name: 'BTLTypeDesc',
      calculate: function (data) {
        if (data.BTLType == 'D') return 'Demand'
        if (data.BTLType == 'P') return 'Production'
        if (data.BTLType == 'I') return 'Inventory'
        if (data.BTLType == 'X') return 'Cross Supply'
        if (data.BTLType == 'R') return 'Razor Draw'
        if (data.BTLType == 'N') return 'Non-revenue'
        return data.BTLType
      }
    },
    {
      name: 'MaterialCode',
      type: 'string'
    },
    {
      name: 'DPIFamilyID',
      type: 'number'
    },
    {
      name: 'MaterialDescription',
      type: 'string'
    },
    {
      name: 'recType',
      type: 'string',
      defaultValue: 'Import'
    },
    {
      name: 'SupplySourceID',
      type: 'string'
    },
    {
      name: 'SupplySource',
      type: 'string'
    },
    {
      name: 'isValid',
      calculate: function (data) {
        if (!data.RegionID) return false
        if (!data.DPIFamilyID) return false
        if (!data.MaterialTypeID) return false
        if (data.MaterialCode || data.MaterialDescription) {
          if (!data.MaterialID) return false
        }
        if (data.BTLType == 'P') {
          if (!data.SupplySourceID) return false
        }
        return true
      }
    },
    {
      name: 'c01',
      type: 'number'
    },
    {
      name: 'c02',
      type: 'number'
    },
    {
      name: 'c03',
      type: 'number'
    },
    {
      name: 'c04',
      type: 'number'
    },
    {
      name: 'c05',
      type: 'number'
    },
    {
      name: 'c06',
      type: 'number'
    },
    {
      name: 'c07',
      type: 'number'
    },
    {
      name: 'c08',
      type: 'number'
    },
    {
      name: 'c09',
      type: 'number'
    },
    {
      name: 'c10',
      type: 'number'
    },
    {
      name: 'c11',
      type: 'number'
    },
    {
      name: 'c12',
      type: 'number'
    },
    {
      name: 't1',
      calculate: function (data) {
        return (
          data.c01 +
          data.c02 +
          data.c03 +
          data.c04 +
          data.c05 +
          data.c06 +
          data.c07 +
          data.c08 +
          data.c09 +
          data.c10 +
          data.c11 +
          data.c12
        )
      }
    },
    {
      name: 'c13',
      type: 'number'
    },
    {
      name: 'c14',
      type: 'number'
    },
    {
      name: 'c15',
      type: 'number'
    },
    {
      name: 'c16',
      type: 'number'
    },
    {
      name: 'c17',
      type: 'number'
    },
    {
      name: 'c18',
      type: 'number'
    },
    {
      name: 'c19',
      type: 'number'
    },
    {
      name: 'c20',
      type: 'number'
    },
    {
      name: 'c21',
      type: 'number'
    },
    {
      name: 'c22',
      type: 'number'
    },
    {
      name: 'c23',
      type: 'number'
    },
    {
      name: 'c24',
      type: 'number'
    },
    {
      name: 't2',
      calculate: function (data) {
        return (
          data.c13 +
          data.c14 +
          data.c15 +
          data.c16 +
          data.c17 +
          data.c18 +
          data.c19 +
          data.c20 +
          data.c21 +
          data.c22 +
          data.c23 +
          data.c24
        )
      }
    },
    {
      name: 'c25',
      type: 'number'
    },
    {
      name: 'c26',
      type: 'number'
    },
    {
      name: 'c27',
      type: 'number'
    },
    {
      name: 'c28',
      type: 'number'
    },
    {
      name: 'c29',
      type: 'number'
    },
    {
      name: 'c30',
      type: 'number'
    },
    {
      name: 'c31',
      type: 'number'
    },
    {
      name: 'c32',
      type: 'number'
    },
    {
      name: 'c33',
      type: 'number'
    },
    {
      name: 'c34',
      type: 'number'
    },
    {
      name: 'c35',
      type: 'number'
    },
    {
      name: 'c36',
      type: 'number'
    },
    {
      name: 't3',
      calculate: function (data) {
        return (
          data.c25 +
          data.c26 +
          data.c27 +
          data.c28 +
          data.c29 +
          data.c30 +
          data.c31 +
          data.c32 +
          data.c33 +
          data.c34 +
          data.c35 +
          data.c36
        )
      }
    }
  ]
})
