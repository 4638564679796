import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PRODUCTonProductSearchInputValueChange as onProductSearchInputValueChange } from '../../../../actions'

const getIconStatus = (inputValue, children) => {
  if (!inputValue) {
    return 'search__icon--inactive'
  }
  if (!_.isEmpty(children)) {
    return 'search__icon--active'
  }
  return 'search__icon--active-not-found'
}

class Search extends React.Component {
  constructor() {
    super()

    this.state = {
      isToggleOn: false
    }

    this.searchRef = null

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleToggleSearch = this.handleToggleSearch.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.searchRef && !this.searchRef.contains(event.target)) {
      this.setState({ isToggleOn: false })
    }
  }

  handleToggleSearch() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn
    }))
  }

  handleChange(event) {
    const { target: { value = '' } = {} } = event

    this.props.onProductSearchInputValueChange(value)
  }

  handleClick(event) {
    event.stopPropagation()
  }

  render() {
    const { isToggleOn } = this.state
    const {
      productSearchInputValue = '',
      resourcePeriodHierarchy: { FilteredRoot: { Children = [] } = {} } = {}
    } = this.props

    const toggleOnStyles = isToggleOn ? { width: 274, padding: 8 } : {}
    const iconStatus = getIconStatus(productSearchInputValue, Children)

    return (
      <section
        ref={(element) => {
          this.searchRef = element
        }}
        className="resource-plan__search search"
        onClick={this.handleToggleSearch}
      >
        <label className="search__label" htmlFor="product-search-input" title="Product Search...">
          <i className={`search__icon fa-fw fa slvy-ui-icon-search ${iconStatus}`} />
        </label>
        <input
          className="search__input"
          id="product-search-input"
          placeholder="Search products..."
          style={toggleOnStyles}
          type="text"
          value={productSearchInputValue}
          onChange={this.handleChange}
          onClick={this.handleClick}
        />
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  resourcePeriodHierarchy: state.PRODUCTresourcePeriodHierarchy,
  productSearchInputValue: state.PRODUCTclickedProductInfo.productSearchInputValue
})

const mapDispatchToProps = {
  onProductSearchInputValueChange
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
