export const WORKFLOW_INFO = {
  afterDataLoad: 'Triggers immediately after data is loaded.',
  ConditionedRowsResult: 'If triggered, it return rows that the condition holds true.',
  DataUpdated:
    'Triggers after the data is updated, can throw refreshkey. Direct update, throws immediately. Save button throws after save button clicked.',
  DirtyStateChanged:
    "When dirty state changes, it can throw 'dirty' or 'clean' boolean variables. Dirty state means updates are made to the plugin but not yet saved.",
  FormattedRowSelected:
    'When a single row is selected, it can throw the selected rows formatted values.',
  GetModifiedRowsResult: 'After data is changed, this returns only the modified values result.',
  MultiRowSelected:
    'When multiple rows are selected, it automatically throws an array of the selected column values.',
  MultiRowsSet: 'Triggers after a multi row set method setMultiSelectRowValues.',
  RowAdded: 'Fires after a new row is added.',
  RowSelected: "When a row is selected, it can throw the last selected row's column values.",
  TriggerMultiRowSelectedResult:
    'If TriggerMultiRowSelected is activated from workflow, this event will be triggered and return selected column values in an array.',
  addRecord: 'If this method is activated, it fires and adds a new empty record.',
  clearAllFilters: 'If enabled, it fires to clear all filters.',
  exportData: 'If enabled, it fires to export the data.',
  filterClientData: 'Data will be filtered according to the given parameter.',
  GetConditionedRows:
    "Once this method is called with the column name, such as 'IsSelected',It will trigger GetConditionedRowsResult and return rows that the condition holds true.",
  GetModifiedRows: 'Method to get only the modified values result.',
  resetChanges: 'If this method is activated, it fires and resets the modifications.',
  saveChanges: 'If this method is activated, it fires and saves the modifications.',
  setConnection: 'Used for seting dynamic connection for plugin.',
  setDataArguments: 'Used to set or load data arguments of the plugin.',
  setEditableState:
    'Takes boolean values. When the plugin is in non editable state, users can not do any editing.',
  setEnabledState:
    'Takes boolean values. When the plugin is in disabled state the users wont be able to select or update. Plugin becomes disabled to do any modifications.',
  setMultiSelectRowValues:
    'Used to set multi rows values from workflow. It can take all columns parameters from the relations tab.',
  setRowValues: 'Method to update/set the selected record with data from another plugin.',
  setRowValuesWithFilter:
    'Method to filter a column value and set it with a new selected variable.',
  TriggerMultiRowSelected:
    'If activated from workflow, it will trigger TriggerMultiRowSelectedResult event.'
}
