/* global Ext */
//require('./../../extjs/overrides/Exporter/File')()
import React, { Component } from 'react'
import _ from 'lodash'
import hash from 'object-hash'
import moment from 'moment'
import string_format from 'string-format'
import { v4 as uuidv4 } from 'uuid'
import { Grid } from '@sencha/ext-react-classic'
import { confirmAlert } from 'react-confirm-alert'
import { connect } from 'react-redux'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { slvyToast, ExtRoot, SlvySpinner } from '@/components'
import fontAwesomeUnicodeMap from '@/assets/scripts/font-awesome-unicode-map'
import gridOverrides from './extjsOverrides'
import slvyIconUnicodeMap from 'slvy-ui-icons/slvy-ui-icons-unicode-map'
import { __RowIndex } from '@/store/slices/localData'
import { getPluginStates } from '@/actions/pluginstate'
import { isSuccess, selectCollection } from '@/crudoptV3'
import { getExtContainerSize } from '@/helpers'
import { selectConnectorProps } from './selectConnectorProps'
import { API_URL } from '@/constants'
import './index.scss'

Ext.require(['Ext.grid.*'])
Ext.require(['Ext.sparkline.*'])

class SenchaGrid extends Component {
  constructor(props) {
    super(props)

    this.addList = []
    this.cellEditingHappened = false
    this.columnStates = []
    this.componentUpdating = false
    this.createdCssClasses = []
    this.deleteList = []
    this.editList = []
    this.executedUpdates = {}
    this.filterStores = {}
    this.ignoreLookupLoad = false
    this.ignoreLookupMassUpdateLoad = false
    this.lookupData = {}
    this.lookupStores = {}
    this.orange = 'rgba(255, 124, 30, 0.06)'
    this.pluginState = {}
    this.pluginStateLoaded = false
    this.filterState = true
    this.reloadAfterUpdateInProgress = false
    this.reloadAfterUpdateQueue = []
    this.rowChanging = false
    this.selectedRow = null
    this.selectedRows = null
    this.updateInProgress = false
    this.warningThresholdApproved = false
    this.tagFilters = []
    this.red = 'rgba(215, 44, 44, 0.06)'
    this.green = 'rgba(77, 204, 53, 0.06)'
    this.blue = 'rgba(0, 44, 237, 0.06)'

    // TODO check  0: '0',
    this.excelFormatConversion = {
      '': '0',
      0: '0',
      '0.0': '0.0',
      '0.00': '0.00',
      '0.000': '0.000',
      '0,000.0': '#,##0.0',
      '0,000.00': '#,##0.00',
      '0,000.000': '#,##0.000',
      '0,0.0': '#,##0.0',
      '0,0.00': '#,##0.00',
      '0,0.000': '#,##0.000'
    }

    this.pageGridState = {
      currentPage: 0,
      sortedColumns: [],
      sortedDataForced: false,
      sortedDataLoaded: false
    }

    this.containerRef = null
    this.resizeObserver = null

    this.bindMethods()
    gridOverrides(this)
  }

  bindMethods = () => {
    this.apiClientRequest = this.apiClientRequest.bind(this)
    this.clearAllFilters = this.clearAllFilters.bind(this)
    this.createReportExportInfo = this.createReportExportInfo.bind(this)
    this.dirtyStateChanged = this.dirtyStateChanged.bind(this)
    this.exportToXlsx = this.exportToXlsx.bind(this)
    this.fireFormattedRowSelected = this.fireFormattedRowSelected.bind(this)
    this.fireMultiRowSelected = this.fireMultiRowSelected.bind(this)
    this.fireRowSelected = this.fireRowSelected.bind(this)
    this.focusCombobox = this.focusCombobox.bind(this)
    this.focusComboboxForMassUpdate = this.focusComboboxForMassUpdate.bind(this)
    this.getActualFilters = this.getActualFilters.bind(this)
    this.getBackColorAndColor = this.getBackColorAndColor.bind(this)
    this.getFieldLookupConfig = this.getFieldLookupConfig.bind(this)
    this.getFormattedValue = this.getFormattedValue.bind(this)
    this.getLoadedPluginStates = this.getLoadedPluginStates.bind(this)
    this.getLookupStore = this.getLookupStore.bind(this)
    this.getRowClass = this.getRowClass.bind(this)
    this.handleAddRecord = this.handleAddRecord.bind(this)
    this.handleAfterRender = this.handleAfterRender.bind(this)
    this.handleBeforeCheckChange = this.handleBeforeCheckChange.bind(this)
    this.handleBeforeEdit = this.handleBeforeEdit.bind(this)
    this.handleBeforeRowSelected = this.handleBeforeRowSelected.bind(this)
    this.handleCellDoubleClick = this.handleCellDoubleClick.bind(this)
    this.handleClearAllFilters = this.handleClearAllFilters.bind(this)
    this.handleClearAllSorters = this.handleClearAllSorters.bind(this)
    this.handleCollapse = this.handleCollapse.bind(this)
    this.handleColumnChanged = this.handleColumnChanged.bind(this)
    this.handleDataColumnChanged = this.handleDataColumnChanged.bind(this)
    this.handleDeleteConfigChanged = this.handleDeleteConfigChanged.bind(this)
    this.handleDeleteRows = this.handleDeleteRows.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
    this.handleFilterChanged = this.handleFilterChanged.bind(this)
    this.handleHeaderMenuCreate = this.handleHeaderMenuCreate.bind(this)
    this.handleLookupDataLoaded = this.handleLookupDataLoaded.bind(this)
    this.handleMassUpdateExecute = this.handleMassUpdateExecute.bind(this)
    this.handlePageNumberChange = this.handlePageNumberChange.bind(this)
    this.handleResetChanges = this.handleResetChanges.bind(this)
    this.handleRowDeselected = this.handleRowDeselected.bind(this)
    this.handleSaveChanges = this.handleSaveChanges.bind(this)
    this.handleSelectionChange = this.handleSelectionChange.bind(this)
    this.handleSortChange = this.handleSortChange.bind(this)
    this.handleStoreAdd = this.handleStoreAdd.bind(this)
    this.handleStoreBeforeSort = this.handleStoreBeforeSort.bind(this)
    this.handleStoreBeginUpdate = this.handleStoreBeginUpdate.bind(this)
    this.handleStoreEndUpdate = this.handleStoreEndUpdate.bind(this)
    this.handleStoreUpdate = this.handleStoreUpdate.bind(this)
    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
    this.handleTriggerSaveChanges = this.handleTriggerSaveChanges.bind(this)
    this.handleUnlockColumn = this.handleUnlockColumn.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
    this.isGridAddable = this.isGridAddable.bind(this)
    this.isGridEditable = this.isGridEditable.bind(this)
    this.loadEditedData = this.loadEditedData.bind(this)
    this.lockedGridResize = this.lockedGridResize.bind(this)
    this.loadLookUpdata = this.loadLookUpdata.bind(this)
    this.prepareLookupData = this.prepareLookupData.bind(this)
    this.saveColumnState = this.saveColumnState.bind(this)
    this.sendWarningMessage = this.sendWarningMessage.bind(this)
    this.setFooterButtons = this.setFooterButtons.bind(this)
    this.standardColumnRenderer = this.standardColumnRenderer.bind(this)
    this.substitutionColumnRenderer = this.substitutionColumnRenderer.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.validateNewRows = this.validateNewRows.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    // The component updates only by one of these cases
    // 1- grid configuration has changed
    // 2- pluginState has changed
    // 3- plugin size has changed
    // 4- plugin data  has changed
    let nextPluginState = {}
    let pluginStateOwner = ''
    if (nextProps.pluginStates.isSuccess) {
      const { pluginStates: { data = [] } = {} } = nextProps
      if (data.length > 0) {
        nextPluginState = data[0].config.state
        pluginStateOwner = data[0].pluginId
      }
    }

    const containerId = `slvyExtContainer-${nextProps.id}`
    const loadingElement = document.querySelector(`#${containerId} .sencha-grid-loading`)

    if (loadingElement) {
      if (nextProps.isPluginDataLoading) {
        loadingElement.classList.remove('d-none')
      } else {
        if (!loadingElement.classList.contains('d-none')) {
          loadingElement.classList.add('d-none')
        }
      }
    }

    const settingsChanged = !_.isEqual(this.props.settings.config, nextProps.settings.config)

    const pluginStateChanged =
      !this.pluginStateLoaded &&
      nextProps.pluginStates.isSuccess &&
      pluginStateOwner === this.props.id &&
      JSON.stringify(nextPluginState) !== JSON.stringify(this.pluginState)

    // Check configuration hints
    const { data: { configurationhints = {} } = {} } = this.props

    const { data: { configurationhints: configurationhintsNext = {} } = {} } = nextProps || {}

    const configurationHintsChanged =
      !_.isEmpty(configurationhints) &&
      !_.isEmpty(configurationhintsNext) &&
      !_.isEqual(configurationhints, configurationhintsNext)

    const pluginDataReady =
      _.size(nextProps.pluginData) === 0 ||
      (_.size(nextProps.pluginData) > 0 && _.has(nextProps.pluginData[0], __RowIndex))

    const isMaximizeChanged = this.props.isMaximized !== nextProps.isMaximized

    if (
      pluginDataReady &&
      (settingsChanged || pluginStateChanged || isMaximizeChanged || configurationHintsChanged)
    ) {
      return true
    }
    this.componentUpdating = false
    return false
  }

  UNSAFE_componentWillMount() {
    // Call plugin onReady method
    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [this.handleDataColumnChanged, this.handleDeleteConfigChanged],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition
      })
    }
    // Create extjs store
    this.store = Ext.create('Ext.data.Store', {
      autoLoad: true,
      pageSize: 50,
      proxy: {
        type: 'memory',
        reader: {
          rootProperty: 'pageData',
          totalProperty: 'total',
          type: 'json'
        }
      },
      listeners: {
        add: this.handleStoreAdd,
        beforesort: this.handleStoreBeforeSort,
        beginupdate: this.handleStoreBeginUpdate,
        endupdate: this.handleStoreEndUpdate,
        update: this.handleStoreUpdate
      }
    })

    // Create extjs ViewModel
    this.viewModel = Ext.create('Ext.app.ViewModel', {})

    const columnConfigs = this.getColumnConfigs()

    //Trigger reading plugin states
    if (this.props.pluginStates.needFetch) {
      this.props.dispatch(this.props.pluginStates.fetch)
    }

    const { settings: { config: gridConfig = {} } = {}, id: pluginId, getFieldType } = this.props

    const fieldConfigs = this.getFieldConfigs()

    const {
      actions: { actionItems = [] } = {},
      grid: { updateParameters = [], footerButtons = [] } = {}
    } = gridConfig

    // Register row selection event
    const cellParams = _.transform(
      fieldConfigs,
      (result, field = {}) => {
        const { dataType = '' } = field
        result[field.fieldName] = PluginTypes.fromString(dataType)
      },
      {}
    )

    const rowSelectedParams = {
      ...cellParams,
      rowSelected: PluginTypes.boolean,
      rowDeselected: PluginTypes.boolean,
      refreshKey: PluginTypes.string,
      [__RowIndex]: PluginTypes.int
    }

    this.handleRowSelected = this.props.registerEvent({
      fn: this.handleRowSelected.bind(this),
      key: 'RowSelected',
      returnTypes: rowSelectedParams
    })

    // Register formatted row selection event
    const formattedCellParams = _.transform(
      fieldConfigs,
      (result, field = {}) => {
        result[field.fieldName] = PluginTypes.string
      },
      {}
    )

    this.handleFormattedRowSelected = this.props.registerEvent({
      fn: this.handleFormattedRowSelected.bind(this),
      key: 'FormattedRowSelected',
      returnTypes: formattedCellParams
    })

    const conditionedRowsParams = _.transform(
      cellParams,
      (result, value, key) => {
        result[key] = getFieldType(key, true)
      },
      {}
    )

    this.conditionedRowsResult = this.props.registerEvent({
      fn: this.conditionedRowsResult.bind(this),
      key: 'ConditionedRowsResult',
      returnTypes: conditionedRowsParams
    })

    // Register multi row selection event
    const multiRowSelectedParams = _.transform(
      cellParams,
      (result, value, key) => {
        result[key] = getFieldType(key, true)
      },
      { selectedRowCount: PluginTypes.int }
    )

    this.handleMultiRowSelected = this.props.registerEvent({
      fn: this.handleMultiRowSelected.bind(this),
      key: 'MultiRowSelected',
      returnTypes: multiRowSelectedParams
    })

    this.getModifiedRowsResult = this.props.registerEvent({
      fn: this.getModifiedRowsResult.bind(this),
      key: 'GetModifiedRowsResult',
      returnTypes: multiRowSelectedParams
    })

    this.triggerMultiRowSelectedResult = this.props.registerEvent({
      fn: this.triggerMultiRowSelectedResult.bind(this),
      key: 'TriggerMultiRowSelectedResult',
      returnTypes: multiRowSelectedParams
    })

    // Register cell click event
    const cellClickParams = {
      ...cellParams,
      [__RowIndex]: PluginTypes.int,
      columnField: PluginTypes.string
    }

    _.forEach(columnConfigs, (column) => {
      if (column.actionButton && column.actionButton.enabled) {
        const { header: actionTitle, actionButton: { name: actionName } = {} } = column
        // Register action clicked event
        this['handleActionClick_' + actionName] = function (
          grid,
          rowIndex,
          colIndex,
          item,
          e,
          record
        ) {
          return this.handleActionClick(record, actionTitle)
        }

        this['handleActionClick_' + actionName] = this.props.registerEvent({
          key: 'ActionClicked_' + actionName,
          fn: this['handleActionClick_' + actionName].bind(this),
          returnTypes: _.transform(
            fieldConfigs,
            (result, field = {}) => {
              const { dataType = '' } = field
              result[field.fieldName] = PluginTypes.fromString(dataType)
            },
            {
              _ActionTitle: PluginTypes.string,
              _RefreshKey: PluginTypes.string,
              [__RowIndex]: PluginTypes.int
            }
          )
        })
      }
    })

    _.forEach(columnConfigs, (columnConfig) => {
      const { action: { cellClickEnabled = false } = {} } = columnConfig || {}
      if (cellClickEnabled) {
        this['handleCellClick' + columnConfig.fieldName] = function (params) {
          return params
        }
      }
    })

    _.forEach(columnConfigs, (columnConfig) => {
      const { action: { cellClickEnabled = false } = {} } = columnConfig || {}
      if (cellClickEnabled) {
        this['handleCellClick' + columnConfig.fieldName] = this.props.registerEvent({
          key: 'CellClicked-' + columnConfig.fieldName,
          fn: this['handleCellClick' + columnConfig.fieldName].bind(this),
          returnTypes: cellClickParams
        })
      }
    })

    _.forEach(actionItems, (actionItem) => {
      const { name: actionName, title: actionTitle } = actionItem
      // Register action clicked event
      this['handleActionClick_' + actionName] = function (
        grid,
        rowIndex,
        colIndex,
        item,
        e,
        record
      ) {
        return this.handleActionClick(record, actionTitle)
      }

      this['handleActionClick_' + actionName] = this.props.registerEvent({
        key: 'ActionClicked_' + actionName,
        fn: this['handleActionClick_' + actionName].bind(this),
        returnTypes: _.transform(
          fieldConfigs,
          (result, field = {}) => {
            const { dataType = '' } = field
            result[field.fieldName] = PluginTypes.fromString(dataType)
          },
          { _ActionTitle: PluginTypes.string, _RefreshKey: PluginTypes.string }
        )
      })
    })

    // Register grid updated event
    this.handleDataUpdated = this.props.registerEvent({
      fn: this.handleDataUpdated.bind(this),
      key: 'DataUpdated',
      returnTypes: { refreshKey: PluginTypes.string }
    })

    // Register dirty state change event
    this.dirtyStateChanged = this.props.registerEvent({
      fn: this.dirtyStateChanged.bind(this),
      key: 'DirtyStateChanged',
      returnTypes: { dirty: PluginTypes.boolean, clean: PluginTypes.boolean }
    })

    // Register row added event
    this.handleRowAdded = this.props.registerEvent({
      fn: this.handleRowAdded.bind(this),
      key: 'RowAdded',
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.multiRowsSet = this.props.registerEvent({
      key: 'MultiRowsSet',
      fn: this.multiRowsSet.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    _.forEach(footerButtons, (footerButton) => {
      const { buttonText } = footerButton
      if (buttonText) {
        // Register footer button clicked event
        this['handleFooterButtonClick_' + buttonText] = function () {
          return this.handleFooterButtonClick()
        }

        this['handleFooterButtonClick_' + buttonText] = this.props.registerEvent({
          key: 'FooterButtonClick_' + buttonText,
          fn: this['handleFooterButtonClick_' + buttonText].bind(this),
          returnTypes: { refreshKey: PluginTypes.string }
        })
      }
    })

    const filteredUpdateParameters = _.filter(updateParameters, (updateParameter) => {
      return updateParameter.name !== ''
    })

    this.props.registerMethod({
      args: [{ name: 'conditionDataField', type: PluginTypes.string }],
      fn: this.getConditionedRows.bind(this),
      key: 'GetConditionedRows'
    })

    this.props.registerMethod({
      key: 'clearAllFilters',
      fn: this.clearAllFilters.bind(this),
      args: []
    })

    // Register save method
    this.props.registerMethod({
      key: 'saveChanges',
      fn: this.handleSaveChanges.bind(this),
      args: [
        { name: 'refreshKey', type: PluginTypes.string },
        ..._.map(filteredUpdateParameters, (updateParameter) => {
          const { name, type } = updateParameter
          return { name, type: PluginTypes.fromString(type) }
        })
      ]
    })

    this.props.registerMethod({
      args: [{ name: 'refreshKey', type: PluginTypes.string }],
      fn: this.handleResetChanges.bind(this),
      key: 'resetChanges'
    })

    this.props.registerMethod({
      args: [{ name: 'refreshKey', type: PluginTypes.string }],
      fn: this.handleAddRecord.bind(this),
      key: 'addRecord'
    })

    // Register setEditable method
    this.props.registerMethod({
      args: [
        { name: 'editable', type: PluginTypes.boolean },
        { name: 'notEditable', type: PluginTypes.boolean }
      ],
      key: 'setEditableState',
      fn: this.handleSetEditableState.bind(this)
    })

    // Register SetEnabledState method
    this.props.registerMethod({
      args: [
        { name: 'enabled', type: PluginTypes.boolean },
        { name: 'notEnabled', type: PluginTypes.boolean }
      ],
      key: 'setEnabledState',
      fn: this.handleSetEnabledState.bind(this)
    })

    // Register setRowValues method
    const setRowValuesParams = _.transform(
      fieldConfigs,
      (result, field = {}) => {
        const { dataType = '' } = field
        result.push({
          name: field.fieldName,
          type: PluginTypes.fromString(dataType)
        })
      },
      [{ name: __RowIndex, type: PluginTypes.int }]
    )

    this.props.registerMethod({
      key: 'setRowValues',
      fn: this.handleSetRowValues.bind(this),
      args: setRowValuesParams
    })

    // Register setRowValuesWithFilter method
    const setRowValuesWithFilterParams = _.orderBy(
      _.transform(
        fieldConfigs,
        (result, field = {}) => {
          const { dataType = '' } = field
          result.push({
            name: 'Filter_' + field.fieldName,
            type: PluginTypes.fromString(dataType)
          })
          result.push({
            name: 'Set_' + field.fieldName,
            type: PluginTypes.fromString(dataType)
          })
        },
        []
      ),
      'name'
    )

    const setMultiSelectRowParams = _.orderBy(
      _.transform(
        fieldConfigs,
        (result, field = {}) => {
          const { dataType = '' } = field

          result.push({
            name: field.fieldName,
            type: PluginTypes.fromString(dataType)
          })
        },
        []
      ),
      'name'
    )

    this.props.registerMethod({
      key: 'setRowValuesWithFilter',
      fn: this.handleSetRowValuesWithFilter.bind(this),
      args: setRowValuesWithFilterParams
    })

    // Register filterClientData method
    const filterClientDataParams = _.transform(
      fieldConfigs,
      (result, field = {}) => {
        const { dataType = '' } = field
        result.push({
          name: field.fieldName,
          type: PluginTypes.fromString(dataType)
        })
      },
      [
        {
          name: 'clearFilter',
          type: PluginTypes.int
        }
      ]
    )

    this.props.registerMethod({
      args: filterClientDataParams,
      fn: this.handleFilterClientData.bind(this),
      key: 'filterClientData'
    })

    this.props.registerMethod({
      args: [],
      fn: this.getModifiedRows.bind(this),
      key: 'GetModifiedRows'
    })

    this.props.registerMethod({
      args: [],
      fn: this.triggerMultiRowSelected.bind(this),
      key: 'TriggerMultiRowSelected'
    })

    this.props.registerMethod({
      args: setMultiSelectRowParams,
      fn: this.handleSetMultiSelectRowValues.bind(this),
      key: 'setMultiSelectRowValues'
    })

    this.createHeaderCheckboxStyles(gridConfig, pluginId)

    this.createFilterColumnStyle(pluginId)

    this.createCommonStyle(pluginId)

    this.pageGridState.currentPage = 1
  }

  componentDidMount() {
    // Register for authorizations
    this.props.registerAuthorizations(['Editing'])

    const pluginId = this.props.id

    this.props.reloadExtRoot(pluginId, () => this.observeResize(pluginId))
  }

  componentDidUpdate() {
    this.componentUpdating = false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Set this component as updating, it prevents unnecessary plugin states updates
    this.componentUpdating = true

    if (isSuccess(this.props.query, nextProps.query)) {
      // Add row index to grid, we need it while editing
      this.props.addRowIndexToLocalData()
    }

    const { settings: { config: gridConfig } = {} } = nextProps

    // Make sure setDataArguments method is called with the paging params
    if (this.pagingEnabled(nextProps)) {
      // When plugin states arrive after data force setDataArguments
      if (
        !this.pageGridState.sortedDataForced &&
        nextProps.pluginStates.isSuccess &&
        nextProps.query.isSuccess
      ) {
        this.pageGridState.sortedDataForced = true
        this.pageGridState.sortedDataLoaded = true
        // Get sort info
        const sorters = this.getSortingInfo(nextProps)
        this.loadPageData(this.store.currentPage, sorters, true)
      }

      // When plugin states arrive, add paging params to setDataArguments
      if (!this.pageGridState.sortedDataLoaded && nextProps.pluginStates.isSuccess) {
        this.pageGridState.sortedDataLoaded = true
        // Get sort info
        const sorters = this.getSortingInfo(nextProps)
        this.loadPageData(this.store.currentPage, sorters, false)
      }
    }

    //master
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //master

    const pluginDataChanged = !_.isEqual(nextProps.pluginData, this.props.pluginData)

    const pluginDataReady =
      _.size(nextProps.pluginData) === 0 ||
      (_.size(nextProps.pluginData) > 0 && _.has(nextProps.pluginData[0], __RowIndex))

    if (pluginDataReady) {
      if (pluginDataChanged) {
        const isColumnChanged = false
        this.createReportExportInfo(isColumnChanged)
        if (this.pagingEnabled(nextProps)) {
          const pagedGridData = this.getData(nextProps.pluginData, true)
          // Since we use paging, loading data has to be go over proxy

          const { actualFilters: { TAKE: takeFilterValue = 0 } = {} } = nextProps || {}

          if (takeFilterValue > 0 && this.store.pageSize !== takeFilterValue) {
            this.store.pageSize = takeFilterValue
          }

          // Since we use paging, loading data has to be go over proxy
          this.store.proxy.data = pagedGridData
          this.store.currentPage = this.pageGridState.currentPage
          this.store.load()
          if (this.extjsGrid && this.extjsGrid.cmp) {
            if (this.selectedRows !== null) {
              this.selectedRows.forEach((rowIndex) => {
                this.extjsGrid.cmp.getSelectionModel().select(rowIndex, true)
              })
            }

            if (this.selectedRow !== null) {
              this.extjsGrid.cmp.getSelectionModel().select(this.selectedRow)
            }
          }
        } else {
          const { data: { setDataArgsKey } = {}, pluginData } = nextProps || {}
          const gridData = this.getData(pluginData)
          if (setDataArgsKey) {
            if (this.executedUpdates[setDataArgsKey]) {
              let updatedRows = []
              if (_.size(this.reloadAfterUpdateQueue) > 0 || this.updateInProgress) {
                const { updateItems = [] } = this.executedUpdates[setDataArgsKey]
                updatedRows = _.map(updateItems, (updateItem) => {
                  const { config = {} } = updateItem
                  return config
                })
              }
              const { keys = [] } = gridConfig

              if (keys.length) {
                this.setDataToStore(gridData, keys, updatedRows)
              }
              delete this.executedUpdates[setDataArgsKey]
            }
            this.reloadAfterUpdateInProgress = false
            this.processReloadAfterUpdateRequest()
            if (this.extjsGrid && this.extjsGrid.cmp) {
              if (this.selectedRows !== null) {
                this.selectedRows.forEach((rowIndex) => {
                  this.extjsGrid.cmp.getSelectionModel().select(rowIndex, true)
                })
              }

              if (this.selectedRow !== null) {
                this.extjsGrid.cmp.getSelectionModel().select(this.selectedRow)
              }
            }
          } else {
            // TODO: After basePlugin refactor (refactor/createPlugin) BufferedRenderer started throw error
            // to fix it quickly we move loadData in a timeout
            setTimeout(() => {
              this.store.loadData(gridData)
              if (this.extjsGrid && this.extjsGrid.cmp) {
                if (this.selectedRows !== null) {
                  this.selectedRows.forEach((rowIndex) => {
                    this.extjsGrid.cmp.getSelectionModel().select(rowIndex, true)
                  })
                }

                if (this.selectedRow !== null) {
                  this.extjsGrid.cmp.getSelectionModel().select(this.selectedRow)
                }
              }
            }, 200)
          }
          // TODO: setTimeout added for catching the this.store.loadData(gridData)
          setTimeout(() => this.setDataDetails(), 200)
        }

        // Workaround: componentWillReceiveProps is called after the initial Render (WillMount > Render)
        // At this time the extjs grid is not created yet. Therefore call applyDataTemplates in a timeouted scope
        setTimeout(() => {
          if (this.extjsGrid && _.size(nextProps.pluginData) > 0) {
            this.applyDataTemplates(this.extjsGrid.cmp, gridConfig, nextProps.pluginData)
            const {
              props: { settings: { config: { grid: { footerButtons } = {} } = {} } = {} } = {}
            } = this
            if (_.size(footerButtons) > 0) {
              const pluginDataChanged = !_.isEqual(this.pluginData, nextProps.pluginData)
              if (pluginDataChanged) {
                this.setFooterButtons(nextProps.pluginData)
              }
            }
          }
        }, 0)

        if (this.extjsGrid && this.extjsGrid.cmp) {
          this.expandAndCollapse()
        }
      }
      if (!this.filterState) {
        this.filterState = true
      }
    }

    // If size of the grid changes do not render the grid
    let widthChanged = nextProps.size.width !== this.props.size.width
    //widthChanged = this.props.size.width === 0 && widthChanged
    let heightChanged = nextProps.size.height !== this.props.size.height
    //heightChanged = this.props.size.height === 0 && heightChanged
    // This is one-time operation
    if (widthChanged || heightChanged) {
      // this.setState({ forceReInit: new Date() }, () => {
      const pluginId = this.props.id
      this.props.reloadExtRoot(pluginId, () => this.observeResize(pluginId))
      //})
    }
  }

  componentWillUnmount() {
    this.removeStyle(this.props.id)

    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    this.selectedRow = null
    this.selectedRows = null
  }

  observeResize(pluginId) {
    if (_.isEmpty(this.containerRef)) {
      return
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      const size = _.isEmpty(entries) ? getExtContainerSize(pluginId) : entries[0].contentRect
      this.setSize(size)
    })

    this.resizeObserver.observe(this.containerRef)
  }

  setSize({ width, height }) {
    // If size of the grid changes do not render the grid
    if (this.extjsGrid && this.extjsGrid.cmp) {
      this.extjsGrid.cmp.setWidth(width)
      this.extjsGrid.cmp.setHeight(height)
    }
  }

  getLoadedPluginStates(props) {
    if (props.pluginStates.isSuccess && props.pluginStates.data.length > 0) {
      return props.pluginStates.data[0].config.state
    }
  }

  createHiddenColumn(field) {
    return {
      dataIndex: field.fieldName,
      groupHeader: '',
      hidden: true,
      hideable: false,
      text: field.fieldName
    }
  }

  replaceTemplate(text, data) {
    const { getFormattedValue } = this.props

    const regExp = /\{([^}]+)\}/g

    if (_.isNil(text) || _.isEmpty(text)) {
      return '\xa0'
    }

    const matches = text.match(regExp)

    if (!matches) return text

    for (let i = 0; i < matches.length; i++) {
      const variableName = matches[i].substr(1, matches[i].length - 2)

      let value = ''
      let formattedValue
      if (data && _.has(data, variableName) && !_.isNil(data[variableName])) {
        value = data[variableName]

        if (!_.isNil(value)) {
          formattedValue = getFormattedValue(variableName, value, this.getFormattedFields())
        }
      }
      text = text.replace(matches[i], formattedValue || value)
    }

    return text
  }

  handleClearAllSorters() {
    this.store.getSorters().clear()
    const groupingColumn = this.extjsGrid.cmp.store.getGroupField()
    if (groupingColumn) {
      this.store.group(groupingColumn, 'ASC')
    }
    this.handleSortChange()

    if (_.size(this.store.sorters) === 0) {
      // When last sorter is removed, the grid is not refreshed
      // Therefore add __SLVYRowIndex as a sorter
      this.store.setSorters([
        {
          property: __RowIndex,
          direction: 'ASC'
        }
      ])
    }
  }

  clearAllFilters() {
    this.filterState = false
    this.handleClearAllFilters()
  }

  handleClearAllFilters() {
    if (this.extjsGrid) {
      const grid = this.extjsGrid.cmp
      const columns = grid.getColumns()
      const headerItems = grid.headerCt.items
      _.forEach(columns, (element) => {
        const { filter: { column: { filter = null } = {} } = {} } = element || {}
        if (filter) {
          filter.setActive(false)
        }
      })
      _.forEach(headerItems.items, (item) => {
        item.removeCls('x-grid-tag-filters-filtered-column')
      })
    }

    if (this.store) {
      this.store.clearFilter()
    }
    this.tagFilters = []
    this.handleFilterChanged()
  }

  createActionMenuItems(gridConfig, data) {
    const { actions: { actionItems = [] } = {} } = gridConfig || {}

    if (_.size(actionItems) > 1) {
      return _.map(actionItems, (actionItem) => {
        return {
          text: this.replaceTemplate(actionItem.title, data),
          iconCls: actionItem.icon && 'fa ' + actionItem.icon,
          handler: (target) => {
            const record = target.up('button')
              ? target.up('button').getWidgetRecord()
              : target.getWidgetRecord()

            this['handleActionClick_' + actionItem.name](null, null, null, null, null, record)
          }
        }
      })
    }
  }

  createMenuActionColumns(gridConfig, configuredColumnCount, columnStates) {
    const { actions: { actionItems = [] } = {} } = gridConfig || {}

    const widgetIcon = actionItems.length > 1 ? 'fa-bars' : 'fa-circle'

    const configIndex = configuredColumnCount
    const columnState = _.find(columnStates, { configIndex })

    const actionIndex =
      _.size(actionItems) > 0 && actionItems[0].index !== ''
        ? actionItems[0].index - 1
        : configuredColumnCount
    const actionGroupHeader = _.size(actionItems) > 0 && actionItems[0].groupHeader

    const actionColumn = {
      columnOrder: columnState ? columnState.columnOrder : actionIndex,
      configIndex: configIndex,
      groupHeader: actionGroupHeader,
      hideable: false,
      index: actionItems.length > 0 && actionItems[0].index,
      maxWidth: 50,
      menuDisabled: true,
      sortable: false,
      text: actionItems.length === 1 ? actionItems[0].title : '',
      xtype: 'widgetcolumn',
      onWidgetAttach: (col, button, rec) => {
        const menuItems = this.createActionMenuItems(gridConfig, rec.data) || []
        button.setMenu({
          animateShadow: true,
          items: menuItems,
          shadow: 'frame',
          shadowOffset: 10
        })
      },
      widget: {
        arrowVisible: false,
        iconCls: 'fa ' + widgetIcon,
        xtype: 'button',
        handler:
          actionItems.length === 1 &&
          function (target) {
            const record = target.up('button')
              ? target.up('button').getWidgetRecord()
              : target.getWidgetRecord()
            this['handleActionClick_' + actionItems[0].name](null, null, null, null, null, record)
          }.bind(this),
        ui: 'none',
        style: {
          color: actionItems.length === 1 && actionItems[0].color
        }
      }
    }
    return [actionColumn]
  }

  createTextButtonAction(actionItem, configIndex, columnOrder) {
    const {
      name: actionName,
      title: actionTitle,
      groupHeader: actionGroupHeader,
      enabledCondition: actionEnabledCondition
    } = actionItem

    return {
      align: 'center',
      columnOrder: columnOrder,
      configIndex: configIndex,
      groupHeader: actionGroupHeader,
      hideable: false,
      index: actionItem.index,
      menuDisabled: true,
      sortable: false,
      width: 60,
      renderer: (value, metaData, record) => {
        const isActionDisabled = this.isDisabled(record, actionEnabledCondition)

        const className = isActionDisabled ? 'buttonActionTextDisabled' : ''
        return `<a style="text-decoration:underline" class="${className}">${actionTitle}</a>`
      },
      listeners: {
        click: (grid, rowIndex, colIndex, item, e, record) => {
          const isActionDisabled = this.isDisabled(record, actionEnabledCondition)

          if (!isActionDisabled) {
            this['handleActionClick_' + actionName](grid, rowIndex, colIndex, item, e, record)
          }
          return !isActionDisabled
        }
      }
    }
  }

  createIconButtonAction(actionItem, configIndex, columnOrder) {
    const {
      color: actionColor,
      enabledCondition: actionEnabledCondition,
      groupHeader: actionGroupHeader,
      icon: actionIcon,
      name: actionName,
      title: actionTitle
    } = actionItem

    const iconClass = this.getClassName({ color: actionColor })

    return {
      align: 'center',
      columnOrder: columnOrder,
      configIndex: configIndex,
      groupHeader: actionGroupHeader,
      hideable: false,
      index: actionItem.index,
      menuDisabled: true,
      sortable: false,
      text: actionTitle,
      width: 60,
      xtype: 'actioncolumn',
      items: [
        {
          getClass: () => {
            let result = `${iconClass} x-${actionIcon} fa`
            return result
          },
          handler: this['handleActionClick_' + actionName],
          isActionDisabled: (view, rowIndex, colIndex, item, record) => {
            return this.isDisabled(record, actionEnabledCondition)
          }
        }
      ]
    }
  }

  createButtonActionColumns(gridConfig, configuredColumnCount, columnStates) {
    const { actions: { actionItems = [] } = {} } = gridConfig || {}

    return _.map(actionItems, (actionItem, index) => {
      const configIndex = configuredColumnCount + index
      const columnState = _.find(columnStates, {
        configIndex: configIndex
      })
      const actionIndex =
        actionItem.index !== '' ? actionItem.index : configuredColumnCount + index + 1

      const columnOrder = columnState ? columnState.columnOrder : actionIndex - 1
      if (!actionItem.icon) {
        return this.createTextButtonAction(actionItem, configIndex, columnOrder)
      }

      return this.createIconButtonAction(actionItem, configIndex, columnOrder)
    })
  }

  createActionColumns(gridConfig, configuredColumnCount, columnStates) {
    const { actions = {} } = gridConfig || {}
    const { actionItems = [], displayMode = null } = actions

    if (actionItems.length !== 0) {
      if (displayMode === 'Menu') {
        return this.createMenuActionColumns(gridConfig, configuredColumnCount, columnStates)
      }
      return this.createButtonActionColumns(gridConfig, configuredColumnCount, columnStates)
    }
  }

  colorColumnRenderer(isAction, columnConfig) {
    const { cellTooltip = null } = columnConfig
    return (value, metaData, record) => {
      const that = this
      let tooltip = ''
      if (cellTooltip) {
        tooltip = that.replaceTemplate(cellTooltip, record.data)
      }
      if (metaData) {
        if (tooltip.length > 0) {
          metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
        }
      }
      if (value && typeof value === 'string') {
        const colorCodes = value.split(',')

        let structure = ''
        _.forEach(colorCodes, (colorCode) => {
          structure += isAction
            ? '<div class="box" style="background:' + colorCode + ';margin-right:-18px;" > </div>'
            : '<div class="box" style="background:' + colorCode + ';" > </div>'
        })
        return structure
      }
    }
  }

  iconColumnRenderer(columnConfig, icon) {
    return (value, metaData, record) => {
      const {
        backColor = null,
        fontColor = null,
        cellStyle = null,
        cellTooltip = null
      } = columnConfig || {}
      const that = this
      let tooltip = ''
      if (cellTooltip) {
        tooltip = that.replaceTemplate(cellTooltip, record.data)
        if (metaData) {
          if (tooltip.length > 0) {
            metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
          }
        }
      }

      metaData.style += 'font-size:16px;'
      if (backColor) {
        metaData.style += `background-color: ${backColor};`
      }
      if (fontColor) {
        metaData.style += `color: ${fontColor};`
      }
      const colors = this.getColors({ record, columnConfig })
      const newBackColor = colors.backgroundColor
      const newFontColor = colors.color
      if (newBackColor) {
        metaData.style += `background-color: ${newBackColor};`
      }
      if (newFontColor) {
        metaData.style += `color: ${newFontColor};`
      }
      const iconClass = icon || value
      if (iconClass) {
        return `<i class="fa ${iconClass}" aria-hidden="true"></i>`
      }
    }
  }

  booleanColumnRenderer(columnConfig) {
    const {
      cellTooltip = null,
      boolean: { trueIcon = null, falseIcon = null, trueColor = null, falseColor = null } = {}
    } = columnConfig
    return (value, metaData, record) => {
      const that = this
      let tooltip = ''
      if (cellTooltip) {
        tooltip = that.replaceTemplate(cellTooltip, record.data)
      }
      if (metaData) {
        if (tooltip.length > 0) {
          metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
        }
      }

      if (trueIcon && falseIcon) {
        const icon = value ? trueIcon : falseIcon
        const colorStyle = value ? `color: ${trueColor};` : `color: ${falseColor};`

        return `<i class="booleanColumn fa ${icon}" aria-hidden="true" style="${colorStyle}"></i>`
      } else {
        const icon = value ? '-checked' : ''
        return `<i class="x-grid-checkcolumn${icon}" role="button"  tabIndex="0"></i>`
      }
    }
  }

  substitutionColumnRenderer(substitudeField, columnConfig) {
    return (value, metaData, record) => {
      const {
        backColor = null,
        fontColor = null,
        cellStyle = null,
        cellTooltip = null,
        fieldName,
        editing: { lookupDataField = '' }
      } = columnConfig || {}
      if (metaData) {
        // metaData is null when this renderer is used
        if (backColor) {
          metaData.style += `background-color: ${backColor};`
        }
        if (fontColor) {
          metaData.style += `color: ${fontColor};`
        }
        if (cellStyle) {
          const colors = this.getColors({ record, columnConfig })
          const newBackColor = colors.backgroundColor
          const newFontColor = colors.color
          metaData.style += `background-color: ${newBackColor};`
          if (newFontColor === 'blue') {
            metaData.style += `color: blue;`
          }
        }
        if (cellTooltip) {
          const tooltip = this.replaceTemplate(cellTooltip, record.data)
          metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
        }
      }
      let formattedValue = value
      if (substitudeField) {
        if (record.dirty && _.has(record.previousValues, fieldName)) {
          if (lookupDataField) {
            formattedValue = this.getLookupValue({
              field: fieldName,
              id: value,
              lookupDataField,
              records: record.data
            })
          } else {
            // Dirty Value, search substitude value from lookup data
            formattedValue = this.getLookupValue({
              field: fieldName,
              id: value,
              records: record.data
            })
          }
        } else {
          // Substitute value
          formattedValue = record.data[substitudeField]
        }
      }

      return formattedValue
    }
  }

  getColors = ({ record, columnConfig }) => {
    const { data = [] } = record
    const {
      props: {
        settings: { config: { grid: { getCellColorsFromData = false } = {} } = {} } = {}
      } = {}
    } = this

    const { fieldName = '', backColor = null, fontColor = null, cellStyle = null } = columnConfig

    let colors = {}

    if (fontColor) {
      colors.color = fontColor
    }

    if (cellStyle) {
      colors = this.getBackColorAndColor(cellStyle, fontColor)
    } else if (backColor) {
      colors.backgroundColor = backColor
    }

    if (getCellColorsFromData) {
      const backColorFromData = data[`${fieldName}_backColor`]
      const textColorFromData = data[`${fieldName}_textColor`]
      if (backColorFromData) {
        colors.backgroundColor = backColorFromData
      }
      if (textColorFromData) {
        colors.color = textColorFromData
      }
    }

    return colors
  }

  getBackColorAndColor(cellStyle, textColor) {
    let backgroundColor
    let color = textColor
    if (cellStyle === 'red') {
      backgroundColor = this.red
    } else if (cellStyle === 'blue') {
      backgroundColor = this.blue
      color = 'blue'
    } else if (cellStyle === 'green') {
      backgroundColor = this.green
    } else if (cellStyle === 'orange') {
      backgroundColor = this.orange
    }
    return { backgroundColor, color }
  }

  standardColumnRenderer(columnConfig, cellRuleContainer) {
    const {
      fieldName,
      template = null,
      cellTooltip = null,
      icon: { icon: selectedIcon = '', displayOnlyIcon = false, iconPosition = '' } = {}
    } = columnConfig || {}
    const cellRuleGroups = cellRuleContainer && cellRuleContainer[fieldName]
    const rowRuleGroups = this.rowRuleContainer && this.rowRuleContainer.items

    return (value, metaData, record) => {
      const that = this
      const colors = this.getColors({ record, columnConfig })
      let backgroundColor = colors.backgroundColor
      let textColor = colors.color
      let tooltip = ''
      let formattedValue = that.getFormattedValue(value, columnConfig, record)
      let rowBackColor = null
      let rowColor = null
      let isColorNotApplied = true

      if (cellTooltip) {
        tooltip = that.replaceTemplate(cellTooltip, record.data)
      }
      if (rowRuleGroups && rowRuleGroups.length > 0) {
        _.forEach(rowRuleGroups, (rowRuleGroup) => {
          let rowRuleSucceeded = false
          _.forEach(rowRuleGroup.items, (rowRule) => {
            const clonedRule = { ...rowRule }
            const {
              backColor: rowRuleBackColor,
              compareToField = null,
              ruleFieldName: rowRuleFieldName,
              textColor: rowRuleTextColor
            } = clonedRule
            if (compareToField) {
              if (_.has(record.data, compareToField)) {
                clonedRule['value'] = record.data[compareToField]
              }
            }
            const result = that.executeRule(record.data[rowRuleFieldName], clonedRule)
            const { isValid = false } = result || {}
            if (isValid) {
              isColorNotApplied = false
              backgroundColor = rowRuleBackColor || backgroundColor
              textColor = rowRuleTextColor || textColor
              rowBackColor = rowRuleBackColor
              rowColor = rowRuleTextColor
              rowRuleSucceeded = true
              return false
            } else {
              isColorNotApplied = true
            }
          })
          if (rowRuleSucceeded) {
            return false
          }
        })
      }

      if (cellRuleGroups && cellRuleGroups.length > 0) {
        _.forEach(cellRuleGroups, (cellRuleGroup) => {
          let ruleGroupSucceeded = false
          _.forEach(cellRuleGroup.items, (cellRule) => {
            const {
              backColor: cellBackColor,
              referenceFieldName,
              textColor: cellTextColor
            } = cellRule
            const result = referenceFieldName
              ? that.executeRule(record.get(referenceFieldName), cellRule)
              : that.executeRule(value, cellRule)

            const {
              cellTooltip: conditionalCellTooltip,
              cssClass,
              displayOnlyIcon,
              icon,
              iconPosition,
              isValid = false
            } = result || {}
            if (isValid) {
              isColorNotApplied = false

              if (cellBackColor) {
                backgroundColor = cellBackColor
              }
              if (cellTextColor) {
                textColor = cellTextColor
              }
              if (metaData) {
                metaData.tdCls = cssClass
              }
              if (conditionalCellTooltip) {
                tooltip =
                  conditionalCellTooltip &&
                  that.replaceTemplate(conditionalCellTooltip, record.data)
              }
              if (icon) {
                formattedValue = that.getIconFormattedValue({
                  formattedValue,
                  icon,
                  displayOnlyIcon,
                  iconPosition
                }).formattedValue
                if (metaData) {
                  metaData.style += that.getIconFormattedValue({
                    formattedValue,
                    icon,
                    displayOnlyIcon,
                    iconPosition
                  }).metaDataStyle
                }
              } else {
                if (displayOnlyIcon) {
                  // Display blank since no icon is selected
                  formattedValue = ''
                }
              }
              ruleGroupSucceeded = true
              return false
            } else {
              backgroundColor = rowBackColor || backgroundColor
              textColor = rowColor || textColor
            }
          })
          if (ruleGroupSucceeded) {
            return false
          }
        })
      }
      // Apply Column Icon
      if (selectedIcon) {
        formattedValue = that.getIconFormattedValue({
          formattedValue,
          icon: selectedIcon,
          displayOnlyIcon,
          iconPosition
        }).formattedValue

        if (metaData) {
          metaData.style += that.getIconFormattedValue({
            formattedValue,
            icon: selectedIcon,
            displayOnlyIcon,
            iconPosition
          }).metaDataStyle
        }
      }
      if (metaData) {
        if (isColorNotApplied && backgroundColor) {
          metaData.style += `background-color: ${backgroundColor};`
        }
        if (isColorNotApplied && textColor) {
          metaData.style += `color: ${textColor};`
        }
        if (tooltip) {
          metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
        }
      }

      // Apply Template
      if (template) {
        formattedValue = string_format(template, formattedValue)
      }
      return formattedValue
    }
  }

  getIconFormattedValue({ formattedValue, icon, displayOnlyIcon, iconPosition }) {
    let newFormattedValue
    let metaDataStyle = ''
    if (displayOnlyIcon) {
      newFormattedValue = '<i class="fa ' + icon + '" aria-hidden="true"></i> '
    } else {
      if (iconPosition === 'left') {
        metaDataStyle = 'display: flex;align-items: center;justify-content: space-between;'

        newFormattedValue =
          '<i class="fa ' + icon + '" aria-hidden="true"></i> <span>' + formattedValue + '</span>'
      } else {
        newFormattedValue = formattedValue + ' <i class="fa ' + icon + '" aria-hidden="true"></i>'
      }
    }
    return { formattedValue: newFormattedValue, metaDataStyle }
  }

  executeCustomAggregation(customAggregation, data) {
    const aggFunc = `
                    function table(i, col){
                      return records[i][col]
                    }`
    try {
      const funcBody = window.atob(customAggregation)
      // eslint-disable-next-line no-new-func
      const customAggregationFunction = new Function('records', 'rowCount', funcBody + aggFunc)
      return customAggregationFunction(data, _.size(data))
    } catch (err) {
      console.warn('Custom Aggregation Failed', err)
    }
  }

  summaryRenderer(columnConfig) {
    const {
      props: { settings: { config: { cellDisplayRules = [] } = {} } = {}, getFormattedValue } = {}
    } = this
    return (value, summaryData, field, metaData) => {
      let that = this
      const {
        summaryType = null,
        customAggregation = null,
        summaryTemplate = null,
        fieldName = null,
        align = null
      } = columnConfig
      let ret = value
      if (summaryType || customAggregation) {
        if (customAggregation) {
          const { pluginData = {} } = this.props
          ret = this.executeCustomAggregation(customAggregation, pluginData)
        }

        if (fieldName && !_.isNil(ret)) {
          const formattedFields = this.getFormattedFields()
          ret = getFormattedValue(fieldName, ret, formattedFields)
        }

        if (summaryTemplate && !_.isNil(ret)) {
          ret = string_format(summaryTemplate, ret)
        }
        if (!_.isNil(ret) && metaData) {
          if (cellDisplayRules) {
            let rules = this.createCellRuleContainer(cellDisplayRules)
            if (fieldName in rules) {
              _.forEach(rules[fieldName], (rule) => {
                _.forEach(rule.items, (condition) => {
                  const { applyRuleSummary = false, backColor = null, textColor = null } = condition
                  if (applyRuleSummary) {
                    let result = that.executeRule(ret, condition)
                    const { isValid = false, cssClass } = result || {}

                    if (isValid) {
                      metaData.tdCls = cssClass
                      metaData.style += `color: ${textColor};`
                      metaData.style += `background-color: ${backColor};`
                    }
                  }
                })
              })
            }
          }
          metaData.style += `text-align: ${align};`
        }
      }

      return ret
    }
  }

  getRowClass(record) {
    let result
    for (let i = 0; i < this.rowRuleContainer.items.length; i++) {
      const rule = this.rowRuleContainer.items[i]

      for (let j = 0; j < rule.items.length; j++) {
        const condition = { ...rule.items[j] }

        if (condition.ruleFieldName) {
          if (condition.compareToField) {
            if (_.has(record.data, condition.compareToField)) {
              condition['value'] = record.data[condition.compareToField]
            }
          }

          const column = condition.ruleFieldName
          const value = record.get(column)
          result = this.executeRule(value, condition)
          if (!result.isValid) {
            break
          }
        }
      }
      if (result && result.isValid) {
        return result.cssClass
      }
    }
  }

  getColumnConfigs() {
    const { settings: { config: gridConfig = {} } = {}, data: { configurationhints = {} } = {} } =
      this.props

    const fieldConfigs = this.getFieldConfigs()

    const { grid: { automaticConfiguration = false } = {}, columns: columnConfigs = [] } =
      gridConfig

    const gridDeletingEnabled = this.isGridDeletable()

    return automaticConfiguration
      ? this.getAutoColumnConfigs(configurationhints, fieldConfigs, gridDeletingEnabled)
      : columnConfigs
  }

  isGridEditable() {
    const {
      settings: {
        config: {
          grid: {
            automaticConfiguration = false,
            editing: { enabled: editingEnabledConfig = false } = {}
          } = {}
        } = {}
      } = {},
      data: { configurationhints = {} } = {}
    } = this.props

    const editableFromAutherization = this.props.isAllowed('Editing')

    const editableFromSetEditableStateMethod = !this.viewModel.get('editingDisabled')

    const { view: { updatable: editingEnabledHint = false } = {} } = configurationhints || {}

    return (
      editableFromAutherization &&
      editableFromSetEditableStateMethod &&
      (automaticConfiguration ? editingEnabledHint : editingEnabledConfig)
    )
  }

  isGridAddable() {
    const {
      settings: {
        config: {
          grid: {
            automaticConfiguration = false,
            adding: { enabled: addingEnabledConfig = false } = {}
          } = {}
        } = {}
      } = {},
      data: { configurationhints = {} } = {}
    } = this.props

    const { view: { insertable: addingEnabledHint = false } = {} } = configurationhints || {}

    return automaticConfiguration ? addingEnabledHint : addingEnabledConfig
  }

  isGridDeletable() {
    const {
      settings: {
        config: {
          grid: {
            automaticConfiguration = false,
            deleting: { enabled: deletingEnabledConfig = false } = {}
          } = {}
        } = {}
      } = {},
      data: { configurationhints = {} } = {}
    } = this.props
    const { view: { deletable: deletingEnabledHint = false } = {} } = configurationhints || {}

    return automaticConfiguration ? deletingEnabledHint : deletingEnabledConfig
  }

  isWarningThresholdExceeded(selectedColumn, oldValue, newValue) {
    let warningThreshholdExceeded = false
    const {
      editing: { warningThreshhold = 0 }
    } = selectedColumn
    if (warningThreshhold > 0 && _.isNumber(newValue) && _.isNumber(oldValue)) {
      if ((oldValue * (100 + warningThreshhold)) / 100 < newValue) {
        warningThreshholdExceeded = true
        return true
      }
    }
    return warningThreshholdExceeded
  }

  getColumnDefaultValues() {
    const { settings: { config: gridConfig = {} } = {}, data: { configurationhints = {} } = {} } =
      this.props

    const { grid: { automaticConfiguration = false } = {} } = gridConfig
    if (!automaticConfiguration) {
      return {}
    } else {
      return _.transform(
        configurationhints.columns,
        (result, column) => {
          if (column.defaultValue !== null) {
            result[column.fieldName] = column.defaultValue
          }
        },
        {}
      )
    }
  }

  getAutoColumnConfigs(configurationHints, fieldConfigs, deletingEnabled) {
    const { columns: columnConfigs = [] } = configurationHints || {}

    const autoColumnConfigs = _.transform(
      columnConfigs,
      (result, columnConfig) => {
        const {
          backColor = null,
          fieldId = '',
          fieldName = '',
          header = '\xa0',
          isEditable = false,
          isInsertable = false,
          isNullable = false,
          isVisible = true,
          summaryType = '',
          warningThreshhold = null
        } = columnConfig

        // Skip column if it is not visible
        if (!isVisible) {
          return
        }

        const fieldConfig =
          _.find(fieldConfigs, (fieldConfig) => {
            return fieldConfig.fieldName === fieldName
          }) || {}

        const { dataType = null } = fieldConfig

        let columnAlign = 'left'
        if (
          dataType === 'int' ||
          dataType === 'double' ||
          dataType === 'float' ||
          dataType === 'decimal' ||
          dataType === 'long' ||
          dataType === 'short' ||
          dataType === 'datetime'
        ) {
          columnAlign = 'right'
        } else if (dataType === 'string') {
          columnAlign = 'left'
        } else if (dataType === 'bool') {
          columnAlign = 'center'
        }

        let backColorModified = backColor
        if (backColor && _.startsWith(backColor, '#')) {
          backColorModified += '0f'
        }
        result.push({
          align: columnAlign,
          backColor: backColorModified,
          columnType: 'simple',
          fieldId,
          fieldName,
          flex: 1,
          text: header,
          sortable: true,
          summaryType,
          width: 90,
          editing: {
            enabled: isEditable,
            allowBlank: isNullable,
            warningThreshhold:
              warningThreshhold && warningThreshhold !== null ? warningThreshhold : 0
          },
          adding: {
            enabled: isInsertable
          }
        })
      },
      []
    )

    // Add delete column
    if (deletingEnabled) {
      autoColumnConfigs.push({
        align: 'center',
        columnAction: 'Delete Button',
        flex: 0,
        fontColor: '#dd6550',
        text: '\xa0',
        sortable: false,
        tooltip: 'Delete',
        width: 40,
        adding: {
          enabled: false
        }
      })
    }
    return autoColumnConfigs
  }

  getFormattedFields() {
    const {
      settings: { config: gridConfig = {}, query: { formattedFields = [] } = {} } = {},
      data: { configurationhints = {} } = {}
    } = this.props

    const { grid: { automaticConfiguration = false } = {} } = gridConfig

    return automaticConfiguration
      ? this.getAutoFormattedFields(configurationhints)
      : formattedFields
  }

  getFormattedValue(value, columnConfig, record) {
    const { getFormattedValue, formatValue } = this.props
    const fieldConfigs = this.getFieldConfigs()
    const fieldConfig =
      _.find(fieldConfigs, (item) => {
        return item.fieldName === columnConfig.fieldName
      }) || {}
    const { dataType = null } = fieldConfig
    const { columnType, fieldName = null, formatField = null } = columnConfig || {}
    let formattedValue = value
    if (columnType !== 'html') {
      // Remove html tags from data. Only Html column types are permitted to display html contents
      formattedValue =
        formattedValue && formattedValue.toString().replace(/</g, '&lt;').replace(/>/g, '&gt;')
    }
    if (_.isNumber(value) || dataType === 'datetime') {
      if (dataType === 'datetime' && (_.isNil(value) || _.size(value.toString()) === 0)) {
        // Prevent invalid date
        formattedValue = ''
      } else if (
        formatField &&
        _.has(record.data, formatField) &&
        record.data[formatField] &&
        record.data[formatField] !== ''
      ) {
        formattedValue = formatValue(record.data[formatField], value)
      } else {
        const formattedFields = this.getFormattedFields()
        formattedValue = getFormattedValue(fieldName, value, formattedFields)
      }
    }

    return formattedValue
  }

  getAutoFormattedFields(configurationHints) {
    const { columns = {} } = configurationHints || {}
    return _.map(columns, (column) => {
      let newFormat = this.convertFormat(column.format)
      return {
        baseColumn: column.fieldName,
        columnName: column.fieldName,
        formatString: newFormat
      }
    })
  }

  convertFormat(viewDefFormat) {
    // Convert Viewdef format to dashboard format
    // e.g. N2 => 0.00
    // N => number
    // C => number
    // P => percentage

    if (
      _.startsWith(viewDefFormat, 'N') ||
      _.startsWith(viewDefFormat, 'C') ||
      _.startsWith(viewDefFormat, 'P')
    ) {
      const formatLengthString = viewDefFormat.substring(1, _.size(viewDefFormat))
      const formatLength = _.toInteger(formatLengthString)

      if (_.startsWith(viewDefFormat, 'N') || _.startsWith(viewDefFormat, 'C')) {
        return _.padEnd('0,0.', formatLength + 4, '0')
      } else if (_.startsWith(viewDefFormat, 'P')) {
        return _.padEnd('0,0.', formatLength + 4, '0') + '%'
      }
    }
  }

  createColumns(
    gridConfig,
    columnConfigs,
    cellRuleContainer,
    fields,
    pluginState,
    editableColumns
  ) {
    const columnStates = pluginState && pluginState.columnStates

    // Create grid a column for each column config
    let columns = _.map(columnConfigs, (columnConfig, index) => {
      const columnState =
        _.find(columnStates, {
          configIndex: index
        }) || {}

      const field = _.find(fields, { fieldName: columnConfig.fieldName })
      const column = this.createColumn(
        gridConfig,
        columnConfig,
        cellRuleContainer,
        columnState,
        field,
        editableColumns
      )

      column.configIndex = index
      column.columnOrder = !_.isEmpty(columnState) ? columnState.columnOrder : index

      return column
    })

    // Filter out empty columns
    columns = _.filter(columns, (column) => {
      return column
    })

    const configuredColumnCount = _.size(columns)

    // Create Menu or button columns
    const actionColumns = this.createActionColumns(gridConfig, configuredColumnCount, columnStates)
    _.forEach(actionColumns, (actionColumn) => {
      columns.push(actionColumn)
    })

    // Sort columns
    columns = _.orderBy(columns, ['columnOrder', 'id'], ['asc', 'desc'])

    // Create group headers
    columns = this.applyGroupHeaders(columns, columnStates)

    // Find out fields which are not configured as columns
    // And create hidden columns for them.
    // This is necessary since we send all fields at a row selected event
    const notUsedFields = _.differenceWith(fields, columnConfigs, (a, b) => {
      return a.fieldName === b.fieldName
    })

    notUsedFields.push({
      fieldName: __RowIndex,
      dataType: 'string'
    })

    _.forEach(notUsedFields, (field) => {
      columns.push(this.createHiddenColumn(field))
    })

    return columns
  }

  isNumericType(type) {
    return (
      type === 'int' ||
      type === 'double' ||
      type === 'float' ||
      type === 'decimal' ||
      type === 'long' ||
      type === 'short'
    )
  }

  getFirstDataRow(pluginData) {
    return pluginData && pluginData[0]
  }

  handleLookupDataLoaded(field, massUpdate) {
    return (store, records) => {
      if (this.ignoreLookupLoad) {
        return
      }
      const [record = {}] = records || []
      const { data: { data: { result = [] } = {} } = {} } = record
      const comboData = _.map(result, (row) => {
        return _.isNil(row.Id)
          ? { id: row.Value, value: row.Value }
          : { id: row.Id, value: row.Value }
      })

      if (massUpdate) {
        store.setData(comboData)
      } else {
        if (!this.lookupData[field]) {
          this.lookupData[field] = {}
        }
        const { props: { actualFilters = {}, additionalArgs = {} } = {} } = this
        const filters = { ...actualFilters, ...additionalArgs }
        const keyRecordsHash = this.getLookupHash({
          field,
          records: this.lookupStores[field].proxy.extraParams.record,
          filters
        })
        this.lookupData[field][keyRecordsHash] = comboData
        store.setData(comboData)
      }
    }
  }

  // prepareLookupData is used if there is a lookupDataField.
  prepareLookupData(lookupQueryParams, lookupDataField) {
    if (_.has(lookupQueryParams, lookupDataField)) {
      const lookupValuesString =
        (lookupQueryParams[lookupDataField] && lookupQueryParams[lookupDataField].toString()) || ''
      return (
        _.map(lookupValuesString.split('~'), (item) => {
          // item should be like 'id:value~id:value'
          const splitVal = item.split(':')
          if (splitVal.length === 2) {
            return { id: splitVal[0], value: splitVal[1] }
          } else {
            return { id: item, value: item }
          }
        }) || {}
      )
    }
  }

  focusCombobox(field, lookupDataField, massUpdate = false) {
    return (me) => {
      let that = this
      const { data: { updatehints = {} } = {} } = that.props || {}
      const { props: { actualFilters = {}, additionalArgs = {} } = {} } = that
      let lookupQueryParams = me.up('editor') ? me.up('editor').context.record.data : {}
      let lookupInputData
      const filters = { ...actualFilters, ...additionalArgs }
      const actualParamsHash = this.getLookupHash({
        field,
        records: lookupQueryParams,
        lookupDataField,
        filters
      })
      let storedLookupData = that.lookupData[field] && that.lookupData[field][actualParamsHash]

      if (massUpdate) {
        if (lookupDataField) {
          const lookupData = that.prepareLookupData(lookupQueryParams, lookupDataField)
          if (!that.lookupData[field]) {
            that.lookupData[field] = {}
          }
          that.lookupData[field][actualParamsHash] = lookupData

          that.lookupStores[field].setData(lookupData)
          that.ignoreLookupLoad = true
        } else {
          this.loadComboboxStore(field)
        }
      } else if (!storedLookupData) {
        // We need update hints and current record data to load lookup data
        lookupInputData = {
          record: lookupQueryParams,
          updatehints,
          filters
        }
        that.ignoreLookupLoad = false
        that.lookupStores[field].proxy.extraParams = lookupInputData
        that.lookupStores[field].load()
      } else {
        that.ignoreLookupLoad = true
        that.lookupStores[field].setData(storedLookupData)
      }
    }
  }

  focusComboboxForMassUpdate({ field, lookupDataField, selectedRows }) {
    return () => {
      const lookupDataForMassUpdate =
        this.getMassUpdateLookupData(lookupDataField, selectedRows) || []
      const keyMassRecordsHash = this.getLookupHash({
        field,
        lookupFromMassUpdate: true,
        lookupDataField: null
      })
      if (!this.lookupData[field]) {
        this.lookupData[field] = {}
      }
      this.lookupData[field][keyMassRecordsHash] = lookupDataForMassUpdate

      _.forEach(selectedRows, (item) => {
        // We should prepare row lookup data for data match
        const rowRecordsHash = this.getLookupHash({
          field,
          records: item.data,
          lookupFromMassUpdate: false,
          lookupDataField
        })
        this.lookupData[field][rowRecordsHash] = this.prepareLookupData(item.data, lookupDataField)
      })

      this.lookupStores[field].setData(lookupDataForMassUpdate)
      this.ignoreLookupLoad = true
    }
  }

  getMassUpdateLookupData(lookupDataField, selectedRows) {
    // TODO do code review
    let lookupValues = []

    _.forEach(selectedRows, (selectedRow) => {
      let lookupValuesString =
        (selectedRow && selectedRow.data && selectedRow.data[lookupDataField].toString()) || ''
      if (lookupValues.length) {
        const rowLookupData = _.map(lookupValuesString.split('~'), (item) => {
          const splitVal = item.split(':')
          if (splitVal.length === 2) {
            return { id: splitVal[0], value: splitVal[1] }
          } else {
            return { id: item, value: item }
          }
        })
        lookupValues = _.intersectionWith(lookupValues, rowLookupData, _.isEqual)
      } else {
        lookupValues = _.map(lookupValuesString.split('~'), (item) => {
          const splitVal = item.split(':')
          if (splitVal.length === 2) {
            return { id: splitVal[0], value: splitVal[1] }
          } else {
            return { id: item, value: item }
          }
        })
      }
    })

    return lookupValues
  }

  createMassUpdateEditor({ field, dataType, isLookup }) {
    let massUpdateValue = {
      fieldLabel: 'Value',
      name: 'massUpdateValue',
      padding: '4px 4px 4px 4px',
      xtype: 'numberfield'
    }
    if (isLookup) {
      const columnConfigs = this.getColumnConfigs()
      const columnConfig = _.find(columnConfigs, (item) => {
        return item.fieldName === field
      })
      const { editing: { allowBlank = false, lookupDataField = null } = {} } = columnConfig || {}

      const selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()
      if (lookupDataField) {
        const lookupData = selectedRows
          ? this.getMassUpdateLookupData(lookupDataField, selectedRows)
          : []

        const lookupStore = Ext.create('Ext.data.Store', {
          data: lookupData
        })

        massUpdateValue = {
          allowBlank,
          displayField: 'value',
          fieldLabel: 'Value',
          forceSelection: true,
          name: 'massUpdateValue',
          padding: '4px 4px 4px 4px',
          queryMode: 'remote',
          store: lookupStore,
          triggerAction: 'all',
          typeAhead: true,
          valueField: 'id',
          xtype: 'combo',
          listeners: {
            focus: this.focusComboboxForMassUpdate({
              field,
              lookupDataField,
              selectedRows
            }),
            change: () => {
              this.cellEditingHappened = true
            }
          }
        }
      } else {
        if (!this.ignoreLookupMassUpdateLoad) {
          this.getLookupStore(field, true)
          this.loadComboboxStore(field)
          this.ignoreLookupMassUpdateLoad = true
        }

        massUpdateValue = {
          xtype: 'combo',
          typeAhead: true,
          triggerAction: 'all',
          name: 'massUpdateValue',
          store: this.lookupStores[field],
          queryMode: 'local',
          displayField: 'value',
          valueField: 'id',
          forceSelection: true,
          allowBlank: allowBlank,
          listeners: {
            focus: this.focusCombobox(field, lookupDataField, true),
            change: function () {
              this.cellEditingHappened = true
            }.bind(this)
          }
        }
      }
    } else {
      if (dataType === 'bool') {
        massUpdateValue.xtype = 'combo'
        massUpdateValue.store = [
          [1, 'True'],
          [0, 'False']
        ]
        massUpdateValue.value = 1
      } else if (dataType === 'string') {
        massUpdateValue.xtype = 'textfield'
      } else if (dataType === 'datetime' || dataType === 'date') {
        massUpdateValue.xtype = 'datefield'
      }
    }

    return massUpdateValue
  }

  getLookupStore(field, massUpdate) {
    const {
      params: { environment },
      token,
      id
    } = this.props || {}

    if (_.isEmpty(this.lookupStores[field])) {
      this.lookupStores[field] = Ext.create('Ext.data.Store', {
        proxy: {
          type: 'ajax',
          url: API_URL + '/data/plugin/' + id + '/lookup/' + field,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            environment: environment
          },
          actionMethods: {
            read: 'POST'
          },
          limitParam: '',
          pageParam: '',
          paramsAsJson: true,
          startParam: ''
        },
        listeners: {
          load: this.handleLookupDataLoaded(field, massUpdate).bind(this)
        },
        data: [{}]
      })
    }

    return this.lookupStores[field]
  }

  loadComboboxStore = (field) => {
    const {
      actualFilters = {},
      additionalArgs = {},
      pluginData = [],
      data: { updatehints = {} } = {}
    } = this.props || {}

    const filters = { ...actualFilters, ...additionalArgs }

    this.lookupStores[field].proxy.extraParams = {
      record: this.getFirstDataRow(pluginData),
      updatehints,
      filters
    }
    //TODO
    //if (!this.ignoreLookupMassUpdateLoad) {}
    this.lookupStores[field].load()
  }

  createComboEditor({ field, columnConfig, massUpdate = false }) {
    const { editing: { allowBlank = false, lookupDataField = null } = {} } = columnConfig || {}
    this.getLookupStore(field, massUpdate)

    const editor = {
      xtype: 'combo',
      typeAhead: true,
      triggerAction: 'all',
      store: this.lookupStores[field],
      queryMode: 'local',
      displayField: 'value',
      valueField: 'id',
      forceSelection: true,
      selectOnFocus: true,
      allowBlank: allowBlank,
      listeners: {
        focus: this.focusCombobox(field, lookupDataField, false),
        change: function () {
          this.cellEditingHappened = true
        }.bind(this)
      }
    }

    return editor
  }

  getFieldLookupData(field, records) {
    let fieldLookupData
    const columnConfig = _.find(this.getColumnConfigs(), (column) => {
      return column.fieldName === field
    })

    const { editing: { lookupDataField = null } = {} } = columnConfig
    if (lookupDataField) {
      fieldLookupData = this.prepareLookupData(records.data, lookupDataField)
    } else {
      const { props: { actualFilters = {}, additionalArgs = {} } = {} } = this
      const filters = { ...actualFilters, ...additionalArgs }

      const keyRecordsHash = this.getLookupHash({
        field,
        records: records.data,
        filters
      })
      fieldLookupData = this.lookupData[field] && this.lookupData[field][keyRecordsHash]
    }
    return fieldLookupData
  }

  getLookupValue({ field, records, id, lookupDataField }) {
    const {
      props: {
        actualFilters = {},
        additionalArgs = {},
        settings: { config: { grid: { editing: { massUpdate = false } = {} } = {} } = {} } = {}
      } = {}
    } = this
    const filters = { ...actualFilters, ...additionalArgs }
    const { isLookupQuery } = this.getFieldLookupConfig(field)
    let columnLookupData

    if (isLookupQuery && massUpdate && !lookupDataField) {
      columnLookupData = this.lookupStores[field].getDatas()
    } else {
      let keyRecordsHash
      if (lookupDataField) {
        keyRecordsHash = this.getLookupHash({
          field,
          records,
          lookupDataField
        })
      } else {
        keyRecordsHash = this.getLookupHash({ field, records, filters })
      }

      columnLookupData = this.lookupData[field] && this.lookupData[field][keyRecordsHash]
    }

    if (columnLookupData) {
      const record = _.find(columnLookupData, (item) => {
        return item.id.toString() === id.toString()
      })
      if (record) {
        return record.value
      }
    }
  }

  getLookupHash({
    field,
    records,
    lookupFromMassUpdate = false,
    lookupDataField = null,
    filters = {}
  }) {
    const { lookupQueryList, isLookupQuery } = this.getFieldLookupConfig(field)
    if (isLookupQuery) {
      const keyRecords = []
      if (lookupDataField) {
        keyRecords.push({ field: field })
        keyRecords.push({ lookup: lookupDataField })
        if (records[lookupDataField]) {
          const data = _.trim(_.trim(records[lookupDataField], '~'), ':')
          keyRecords.push({ lookupDataField: data })
        } else {
          keyRecords.push({ lookupDataField: '' })
        }
      } else if (lookupFromMassUpdate) {
        // TODO add filters here
        keyRecords.push({ field: field })
        keyRecords.push({ lookupFromMassUpdate: lookupFromMassUpdate })
      } else {
        if (filters) {
          keyRecords.push({ filters })
        }

        _.forEach(lookupQueryList, (lookupQuery) => {
          keyRecords.push({
            [lookupQuery]: records[lookupQuery]
          })
        })
      }
      return hash(keyRecords)
    }
  }

  getFieldLookupConfig(field) {
    const { settings: { config: gridConfig = {} } = {}, data: { configurationhints = {} } = {} } =
      this.props || {}

    const { grid: { automaticConfiguration = false } = {} } = gridConfig

    if (automaticConfiguration) {
      if (configurationhints) {
        const columns = configurationhints.columns || []
        const configurationHint = _.find(columns, { fieldName: field })
        const { isLookup = false } = configurationHint || {}

        return {
          isLookupQuery: isLookup,
          substitudeField: isLookup && field
        }
      }
    } else {
      const { settings: { query: { editableFields = [] } = {} } = {} } = this.props
      const editableFieldDef = _.find(editableFields, { field })

      const {
        lookupQueryList = [],
        isLookupQuery = false,
        substitudeField = null
      } = editableFieldDef || {}

      return {
        lookupQueryList,
        isLookupQuery,
        substitudeField
      }
    }
  }

  handleSparklineAttach(col, widget, rec) {
    const chartNumberValues = _.map(_.split(rec.data[col.dataIndex], ','), (chartValue) => {
      return _.toNumber(chartValue)
    })
    widget.setValues(chartNumberValues)
  }

  getColumnFilter({ fieldName, columnConfig, dataType, columnState }) {
    const { keepListFilterState = true } = this.props?.settings?.config?.grid || {}
    const {
      columnType = null,
      fieldName: columnFieldName = null,
      filtering: { showList = false, showListMaximum = 20 } = {}
    } = columnConfig
    const fieldConfigs = this.getFieldConfigs()

    const fieldConfig = _.find(fieldConfigs, (fieldConfig) => {
      return fieldConfig.fieldName === columnFieldName
    })

    const { dataType: fieldDataType } = fieldConfig || {}

    // Sparkline column do not have filters
    if (columnType === 'sparkline') {
      return false
    }
    const { substitudeField } = this.getFieldLookupConfig(columnFieldName)

    const storeRecordCount = this.filterStores[fieldName] ? this.filterStores[fieldName].count() : 0

    // We use Yes/No editor when the column type is bool
    if (storeRecordCount <= showListMaximum && showList && fieldDataType !== 'bool') {
      const listFilterState =
        keepListFilterState &&
        columnState &&
        columnState.filter &&
        columnState.filter['in'] &&
        !_.isEmpty(columnState.filter['in'])
      return {
        type: 'list',
        ...(substitudeField && { labelIndex: substitudeField }),
        ...(listFilterState && { value: columnState.filter['in'] })
      }
    } else if (substitudeField) {
      // There is a substitution column
      // Set the dataIndex of the substitution column
      return {
        type: 'likelyhood',
        itemDefaults: {
          emptyText: 'Search for...'
        },
        dataIndex: substitudeField,
        ...(columnState && columnState.filter && { value: columnState.filter })
      }
    } else if (dataType === 'bool') {
      return {
        type: 'boolean'
      }
    } else if (this.isNumericType(dataType)) {
      return {
        type: 'number',
        ...(columnState && columnState.filter && { value: columnState.filter })
      }
    } else if (dataType === 'datetime') {
      return {
        type: 'date',
        ...(columnState &&
          columnState.filter && {
            value: this.getDateFilter(columnState.filter)
          })
      }
    } else {
      return {
        type: 'likelyhood',
        itemDefaults: {
          emptyText: 'Search for...'
        },
        ...(columnState && columnState.filter && { value: columnState.filter })
      }
    }
  }

  getDateFilter(columnStateFilter) {
    const filterKeys = Object.keys(columnStateFilter)

    return _.transform(
      filterKeys,
      (obj, item) => {
        obj[item] = new Date(this.getDateFormat(columnStateFilter[item]))
        return obj
      },
      {}
    )
  }

  getNoOfDecimalPlaces(format) {
    const subStrings = format.split('.')
    return _.size(subStrings) >= 2 ? _.size(subStrings[1].match(new RegExp('0', 'g')) || []) : 0
  }

  createColumn(
    gridConfig,
    columnConfig,
    cellRuleContainer,
    columnState,
    field = {},
    editableColumns
  ) {
    const {
      grid: {
        header: { autoTooltip = true } = {},
        lockable = false,
        split = false,
        filtering = false,
        deleting: {
          deletableCondition = null,
          deleteButtonSettings: { deleteButtonIcon = null, deleteButtonTooltip = null } = {}
        } = {},
        editing: { massUpdate = false } = {}
      } = {}
    } = gridConfig

    const gridEditingEnabled = this.isGridEditable()
    const gridDeletingEnabled = this.isGridDeletable()

    const {
      align = 'left',
      backColor = null,
      columnAction = null,
      columnType = 'simple',
      fieldName: configFieldName = null,
      flex: configFlex = 1,
      fontColor = null,
      groupHeader = null,
      header: configHeader = '\xa0',
      hidden: configHidden = false,
      hideMenu = false,
      icon: { icon: selectedIcon = '' } = {},
      locked: configLocked = false,
      showInMaxMode = false,
      sortable = true,
      summaryType = null,
      tooltip: configTooltip = null,
      width: configWidth = 90,
      actionButton: {
        clickable: clickableOnDisabled,
        editableCondition: enabledCondition,
        enabled: actionEnabled,
        name: actionName
      } = {}
    } = columnConfig

    let hidden
    let hideable = true
    if (columnState) {
      if (!_.isNil(columnState.hidden)) {
        hidden = columnState.hidden
      } else {
        hidden = configHidden
      }
    } else {
      hidden = configHidden
    }

    if (!hidden) {
      hidden = showInMaxMode && !this.props.isMaximized
    }

    if (showInMaxMode && !this.props.isMaximized) {
      hideable = false
    }

    // Locking configuration of the column
    // If locking is true or false in the plugin state, plugin state decides
    // If locking is null in the plugin state column config decides
    let lockColumn
    if ('locked' in columnState) {
      lockColumn = columnState.locked
    } else {
      lockColumn = configLocked
    }

    const locked = lockable && lockColumn

    // Width configuration of the column
    // If the column is locked, it cannot be flex since it crashes the grid
    const lockedSplit = locked ? split : !locked
    const flex = lockedSplit && (columnState && columnState.width ? 0 : configFlex)
    const width = columnState && columnState.width ? columnState.width : configWidth
    const { isLookupQuery, substitudeField } = this.getFieldLookupConfig(configFieldName)
    // Filter configuration of the column
    const { dataType = '', fieldName } = field
    const filter = filtering
      ? this.getColumnFilter({
          fieldName,
          columnConfig,
          dataType,
          columnState
        })
      : false
    // Editable configuration of the column
    let editor = false
    let {
      editing: {
        enabled: columnEditingEnabled = false,
        allowBlank: editingAllowBlank = false
      } = {},
      adding: { enabled: columnAddingEnabled = false } = {}
    } = columnConfig

    if (editableColumns) {
      columnEditingEnabled = _.indexOf(editableColumns, configFieldName) > 0
    }

    // Column is editable and has an update query
    // Bool columns have their own column type they dont need an editor
    if ((columnEditingEnabled || columnAddingEnabled) && dataType !== 'bool') {
      if (isLookupQuery) {
        editor = this.createComboEditor({
          field: configFieldName,
          columnConfig,
          massUpdate
        })
      } else if (this.isNumericType(dataType)) {
        // Editor will be created dynamically based on row content using the getEditor function
        editor = true
      } else if (dataType === 'datetime') {
        const formattedFields = this.getFormattedFields()
        const formattingInfo = _.find(formattedFields, {
          columnName: fieldName
        })
        editor = {
          xtype: 'datefield',
          selectOnFocus: true
        }
        if (formattingInfo) {
          editor.format = _.replace(formattingInfo.formatString, 'Date ', '')
        }
      } else {
        editor = { allowBlank: editingAllowBlank, selectOnFocus: true }
      }
    }

    // We changed column alignment options from start, center, end to
    // left, center, right
    let alignModified = align
    if (align === 'start') {
      alignModified = 'left'
    } else if (align === 'end') {
      alignModified = 'right'
    }

    const tooltip =
      _.size(configTooltip) > 0
        ? configTooltip.includes('{')
          ? null
          : configTooltip
        : autoTooltip
        ? configHeader
        : null

    const actualPluginState = this.getLoadedPluginStates(this.props)
    let componentCls = null
    if (actualPluginState && actualPluginState.columnStates) {
      this.tagFilters = this.getTagFilterStates(actualPluginState.columnStates)
      const foundedTagFilter = _.find(_.cloneDeep(this.tagFilters), (item) => {
        return item.field === configFieldName
      })

      if (foundedTagFilter) {
        componentCls = 'x-grid-tag-filters-filtered-column'
      }
    }

    const commonProperties = {
      align: alignModified,
      dataIndex: configFieldName,
      exportStyle: dataType === 'datetime' ? { format: 'DD/MM/Y HH:mm:ss' } : null,
      groupHeader,
      groupable: true,
      hidden,
      hideable,
      ignoreExport: false,
      locked,
      menuDisabled: hideMenu,
      minWidth: configWidth,
      sortable,
      summaryRenderer: this.summaryRenderer(columnConfig),
      summaryType,
      text: configHeader,
      componentCls,
      tooltip,
      ...(flex && { flex }),
      ...(filtering && filter && { filter }),
      ...(gridEditingEnabled && editor && { editor }),
      ...(isLookupQuery && { exportRenderer: true })
    }

    if (gridEditingEnabled && editor && this.isNumericType(dataType) && !isLookupQuery) {
      commonProperties.getEditor = this.getNumberFieldEditor(field, columnConfig)
    }

    // We cannot apply flex and width to the same column
    // It causes problems in summary line
    if (!flex) {
      commonProperties.width = width
    }

    if (columnType === 'progress') {
      return {
        xtype: 'widgetcolumn',
        ...commonProperties,
        widget: {
          xtype: 'progress',
          textTpl: ['{percent:number("0")}%']
        }
      }
    } else if (columnType === 'sparkline') {
      const { sparkline: { sparkLineProps = {} } = {} } = columnConfig
      const { type: sparklineType = null } = sparkLineProps

      return {
        dataIndex: configFieldName,
        groupHeader,
        hidden,
        hideable,
        locked,
        minWidth: configWidth,
        text: configHeader,
        tooltip,
        xtype: 'widgetcolumn',
        ...(flex && { flex }),
        widget: {
          xtype: sparklineType,
          ...sparkLineProps
        }
      }
    } else if (columnType === 'color') {
      if (actionEnabled) {
        return {
          xtype: 'actioncolumn',
          ...commonProperties,
          hideable: false,
          menuDisabled: true,
          renderer: this.colorColumnRenderer(true, columnConfig),
          width: configWidth,
          sortable: false,
          items: [
            {
              handler: this['handleActionClick_' + actionName],
              isActionDisabled: (view, rowIndex, colIndex, item, record) => {
                if (clickableOnDisabled) {
                  return false
                }
                return this.isDisabled(record, deletableCondition)
              }
            }
          ]
        }
      } else {
        return {
          ...commonProperties,
          renderer: this.colorColumnRenderer(false, columnConfig) //TODO
        }
      }
    } else if (columnType === 'icon') {
      return {
        ...commonProperties,
        renderer: this.iconColumnRenderer(columnConfig)
      }
    } else if (dataType === 'bool') {
      const {
        boolean: { trueIcon = null, falseIcon = null } = {},
        editing: { headerCheckbox = false } = {},
        cellTooltip = null
      } = columnConfig
      return {
        xtype: 'checkcolumn',
        ...commonProperties,
        headerCheckbox: gridEditingEnabled && columnEditingEnabled && headerCheckbox,
        listeners: {
          beforecheckchange: this.handleBeforeCheckChange
        },
        renderer:
          ((trueIcon && falseIcon) || cellTooltip) && this.booleanColumnRenderer(columnConfig)
      }
    } else if (columnAction === 'Delete Button' && gridDeletingEnabled) {
      const deleteButtonColorClass = this.getClassName({
        backgroundColor: backColor,
        color: fontColor
      })
      return {
        xtype: 'actioncolumn',
        ...commonProperties,
        hideable: false,
        menuDisabled: true,
        reference: 'deleteColumn',
        items: [
          {
            //@TODO: iconCls prop does not work a case of like below then changed with getClass fn
            //a1609323188796transparentdd6550initialnormalnone x-fa fa-minus-circle
            //iconCls: `${deleteButtonColorClass} x-${deleteButtonIcon}`,
            getClass: () => `${deleteButtonColorClass} x-${deleteButtonIcon} fa`,
            tooltip: deleteButtonTooltip,
            handler: this.handleDeleteRows,
            isActionDisabled: (view, rowIndex, colIndex, item, record) => {
              return this.isDisabled(record, deletableCondition)
            }
          }
        ]
      }
    } else if (actionEnabled) {
      const iconClass = this.getClassName({ color: fontColor })

      return {
        xtype: 'actioncolumn',
        ...commonProperties,
        hideable: false,
        menuDisabled: true,
        renderer: this.actionColumnRenderer(columnConfig),
        sortable: false,
        width: configWidth,
        items: [
          {
            getClass: () => iconClass + ' fa x-' + selectedIcon,
            handler: this['handleActionClick_' + actionName],
            isActionDisabled: (view, rowIndex, colIndex, item, record) => {
              if (clickableOnDisabled) {
                return false
              }
              return this.isDisabled(record, enabledCondition)
            }
          }
        ]
      }
    } else {
      return {
        ...commonProperties,

        renderer: substitudeField
          ? this.substitutionColumnRenderer(substitudeField, columnConfig)
          : this.standardColumnRenderer(columnConfig, cellRuleContainer),
        ...(substitudeField && {})
      }
    }
  }

  isDisabled(record, disabledCondition) {
    let isActionDisabled = false
    if (disabledCondition) {
      if (_.has(record.data, disabledCondition) && !record.data[disabledCondition]) {
        isActionDisabled = true
      }
    }
    return isActionDisabled
  }

  getNumberFieldEditor(field, columnConfig) {
    return (record) => {
      const formattedFields = this.getFormattedFields()
      const formattedField = _.find(formattedFields, {
        columnName: field.fieldName
      })
      const { formatString = '' } = formattedField || {}
      const {
        editing: {
          allowBlank: editingAllowBlank = false,
          maxValue: staticMaxValue,
          minValue: staticMinValue,
          step: staticStep,
          maxValueField,
          minValueField,
          stepField
        } = {}
      } = columnConfig

      const editorMaxValue =
        maxValueField && record.get(maxValueField) && !isNaN(_.toNumber(record.get(maxValueField)))
          ? _.toNumber(record.get(maxValueField))
          : staticMaxValue
      const editorMinValue =
        minValueField && record.get(minValueField) && !isNaN(_.toNumber(record.get(minValueField)))
          ? _.toNumber(record.get(minValueField))
          : staticMinValue
      const editorStep =
        (stepField && record.get(stepField) && !isNaN(_.toNumber(record.get(stepField)))
          ? _.toNumber(record.get(stepField))
          : staticStep) || 1

      return Ext.create('Ext.grid.CellEditor', {
        field: Ext.create('Ext.form.field.Number', {
          allowBlank: editingAllowBlank,
          decimalPrecision: this.getNoOfDecimalPlaces(formatString),
          maxValue: editorMaxValue,
          minValue: editorMinValue,
          step: editorStep,
          selectOnFocus: true,
          listeners: {
            change: (field, value) => {
              if (value > field.maxValue) field.setValue(field.maxValue)
              else if (value < field.minValue) {
                field.setValue(field.minValue)
              }
            }
          }
        })
      })
    }
  }

  actionColumnRenderer(columnConfig) {
    const { getFormattedValue, formatValue } = this.props
    return (value, metaData, record) => {
      const {
        actionButton: { editableCondition = null, clickable = false } = {},
        backColor = null,
        cellStyle = null,
        fieldName = null,
        fontColor = null,
        formatField = null,
        cellTooltip = null,
        icon: { icon: selectedIcon = '', displayOnlyIcon = false, iconPosition = '' } = {}
      } = columnConfig || {}
      const that = this
      let tooltip = ''
      if (cellTooltip) {
        tooltip = that.replaceTemplate(cellTooltip, record.data)
        if (metaData) {
          if (tooltip.length > 0) {
            metaData.tdAttr = 'data-qtip="' + Ext.String.htmlEncode(tooltip) + '"'
          }
        }
      }

      const fieldConfigs = this.getFieldConfigs()

      const fieldConfig =
        _.find(fieldConfigs, (fieldConfig) => {
          return fieldConfig.fieldName === columnConfig.fieldName
        }) || {}

      const { dataType = null } = fieldConfig
      let formattedValue

      if (dataType === 'datetime' && (_.isNil(value) || _.size(value.toString()) === 0)) {
        // Prevent invalid date
        formattedValue = ''
      } else if (
        formatField &&
        _.has(record.data, formatField) &&
        record.data[formatField] &&
        record.data[formatField] !== ''
      ) {
        formattedValue = formatValue(record.data[formatField], value)
      } else {
        const formattedFields = this.getFormattedFields()
        formattedValue = getFormattedValue(fieldName, value, formattedFields)
      }
      if (metaData) {
        if (backColor) {
          metaData.style += `background-color: ${backColor};`
        }
        if (fontColor) {
          metaData.style += `color: ${fontColor};`
        }
        if (cellStyle) {
          const colors = this.getColors({ record, columnConfig })
          const newBackColor = colors.backgroundColor
          const newFontColor = colors.color
          metaData.style += `background-color: ${newBackColor};`
          if (newFontColor === 'blue') {
            metaData.style += `color: blue;`
          }
        }
        if (editableCondition) {
          if (
            _.has(record.data, editableCondition) &&
            !record.data[editableCondition] &&
            clickable
          ) {
            metaData.style += `opacity: 0.3`
          }
        }
      }

      if (selectedIcon) {
        formattedValue = this.getIconFormattedValue({
          formattedValue,
          icon: selectedIcon,
          displayOnlyIcon,
          iconPosition
        }).formattedValue

        if (metaData) {
          metaData.style += this.getIconFormattedValue({
            formattedValue,
            icon: selectedIcon,
            displayOnlyIcon,
            iconPosition
          }).metaDataStyle
        }
      }

      return formattedValue
    }
  }

  getData(pluginData, isPaged) {
    // If the data does not contain row index do not display it
    const dataReady =
      _.size(pluginData) === 0 ||
      _.has(pluginData[_.keys(pluginData)[0]], __RowIndex) ||
      this.props.isPreviewMode

    let data = []
    if (dataReady) {
      data = _.transform(
        pluginData,
        (result, row) => {
          // Create new row object, since extjs manipulates row data
          // by adding id columns for internal use
          result.push({ ...row })
        },
        []
      )
    }

    if (isPaged) {
      return {
        pageData: data,
        success: true,
        total: this.props.getTotalRowCount()
      }
    }
    return data
  }

  applyGroupHeaders(columns, columnStates) {
    const groupHeaders = []
    for (let i = 0; i < columns.length; i++) {
      let isAdded = false
      const column = columns[i]

      if (columnStates) {
        const columnState = _.find(columnStates, {
          configIndex: column.configIndex
        })

        if (columnState) {
          if (columnState.groupHeader) {
            column.groupHeader = columnState.groupHeader
          } else {
            column.groupHeader = ''
          }
        }
      }

      if (!_.isEmpty(column.groupHeader)) {
        for (let j = 0; j < groupHeaders.length; j++) {
          if (groupHeaders[j].text === column.groupHeader) {
            if (groupHeaders[j].columns) {
              groupHeaders[j].columns.push(column)
              isAdded = true
              break
            }
          }
        }

        if (!isAdded) {
          groupHeaders.push({
            columns: [column],
            text: column.groupHeader
          })
          isAdded = true
        }
      }

      if (!isAdded) {
        groupHeaders.push(column)
      }
    }

    // If all the columns in the group header are locked
    // Set the group header also locked
    _.forEach(groupHeaders, (groupHeader) => {
      if (groupHeader.columns) {
        let allColumnsLocked = true
        _.forEach(groupHeader.columns, (column) => {
          if (!column.locked) {
            allColumnsLocked = false
          }
        })
        if (allColumnsLocked) {
          groupHeader.locked = true
        }
      }
    })
    _.forEach(groupHeaders, (groupHeader) => {
      if (groupHeader.columns) {
        groupHeader.slvyTemplate = groupHeader.text
        groupHeader.text = this.replaceTemplate(
          groupHeader.text,
          this.getFirstDataRow(this.props.pluginData)
        )
      }
    })
    return groupHeaders
  }

  createRowRuleContainer(rules) {
    const ruleContainer = {
      items: []
    }
    if (rules && rules.length > 0) {
      // Work on the clone since we change object
      const rulesClone = _.cloneDeep(rules)

      _.forEach(rulesClone, (rule, index) => {
        const group =
          rule.group && rule.group !== '' ? rule.group : new Date().valueOf().toString() + index

        rule.group = group

        let isGroupFound = false
        _.forEach(ruleContainer.items, (groupedRule) => {
          if (groupedRule.group === group) {
            groupedRule.items.push(rule)
            isGroupFound = true
            return false
          }
        })

        if (!isGroupFound) {
          ruleContainer.items.push({
            group: group,
            items: [rule]
          })
        }
      })
    }
    return ruleContainer
  }

  createCellRuleContainer(rules) {
    const ruleContainer = {}

    if (rules && rules.length > 0) {
      // Work on the clone since we change object
      const rulesClone = _.cloneDeep(rules)

      _.forEach(rulesClone, (rule, index) => {
        if (!ruleContainer[rule.ruleFieldName]) {
          ruleContainer[rule.ruleFieldName] = []
        }

        const group =
          rule.group && rule.group !== '' ? rule.group : new Date().valueOf().toString() + index

        rule.group = group

        const columnRules = ruleContainer[rule.ruleFieldName]
        let isGroupFound = false

        _.forEach(columnRules, (groupedRule) => {
          if (groupedRule.group === group) {
            groupedRule.items.push(rule)
            isGroupFound = true
            return false
          }
        })

        if (!isGroupFound) {
          columnRules.push({
            group: group,
            items: [rule]
          })
        }
      })
    }
    return ruleContainer
  }

  executeRule(value, condition) {
    const ret = {
      cssClass: null,
      isValid: false
    }
    if (condition) {
      const {
        backColor = null,
        boldText = false,
        cellTooltip,
        displayOnlyIcon,
        icon,
        iconPosition,
        italicText = false,
        operator,
        textColor = null,
        textDecoration = 'none',
        value: conditionValue
      } = condition
      ret.isValid = this.checkCondition(conditionValue, value, operator)
      if (ret.isValid) {
        ret.cssClass = this.getClassName({
          backgroundColor: backColor,
          color: textColor,
          fontStyle: italicText ? 'italic' : 'normal',
          fontWeight: boldText ? 'bold' : 'initial',
          textDecoration: textDecoration
        })
        ret.cellTooltip = cellTooltip
        ret.displayOnlyIcon = displayOnlyIcon
        ret.icon = icon
        ret.iconPosition = iconPosition
      }
    }
    return ret
  }

  checkCondition = function (expectedValue, value, operator) {
    let isValid = false
    if (_.isNil(value) || _.isNil(expectedValue)) {
      return isValid
    }
    switch (operator) {
      case 'equal':
        if (expectedValue.toString().toUpperCase() === value.toString().toUpperCase()) {
          isValid = true
        }
        break
      case 'not equal':
        if (expectedValue.toString().toUpperCase() !== value.toString().toUpperCase()) {
          isValid = true
        }
        break
      case 'larger':
        if (parseFloat(expectedValue) < parseFloat(value)) isValid = true
        break
      case 'smaller':
        if (parseFloat(expectedValue) > parseFloat(value)) isValid = true
        break
      default:
        isValid = false
        break
    }

    return isValid
  }

  rgb2hex = function (rgba) {
    if (rgba.indexOf('rgba') === -1) {
      return rgba
    }

    let inputValue = rgba
    inputValue = inputValue.replace('rgba(', '')
    inputValue = inputValue.replace(')', '')
    const values = inputValue.split(',')

    return this.rgbToHex(
      parseInt(values[0], 10),
      parseInt(values[1], 10),
      parseInt(values[2], 10),
      parseFloat(values[3])
    )
  }

  rgbToHex(r, g, b, a) {
    return (
      '#' +
      this.componentToHex(r).toUpperCase() +
      this.componentToHex(g).toUpperCase() +
      this.componentToHex(b).toUpperCase() +
      parseInt(a * 100, 10)
    )
  }

  componentToHex(c) {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  getClassName = function (properties = {}) {
    const {
      backgroundColor: pBackgroundColor,
      color: pColor,
      fontStyle: pFontStyle,
      fontWeight: pFontWeight,
      textDecoration: pTextDecoration
    } = properties

    const defaultProperties = {
      backgroundColor: 'transparent',
      color: 'inherit',
      fontStyle: 'normal',
      fontWeight: 'initial',
      textDecoration: 'none'
    }

    const finalProperties = {
      backgroundColor: pBackgroundColor || defaultProperties.backgroundColor,
      color: pColor || defaultProperties.color,
      fontStyle: pFontStyle || defaultProperties.fontStyle,
      fontWeight: pFontWeight || defaultProperties.fontWeight,
      textDecoration: pTextDecoration || defaultProperties.textDecoration
    }

    const settings = {
      backgroundColor: 'background-color',
      color: 'color',
      fontStyle: 'font-style',
      fontWeight: 'font-weight',
      textDecoration: 'text-decoration'
    }

    let cssClassName = 'a' + new Date().valueOf()
    let rules = ''
    Object.keys(finalProperties).forEach((item) => {
      let finalPropItem = finalProperties[item]
      rules += settings[item] + ': ' + finalPropItem + '!important;'
      cssClassName += this.rgb2hex(finalPropItem)
    })
    cssClassName = cssClassName.replace(new RegExp('#', 'g'), '').replace(/\(|\)|,/g, '')

    if (this.createdCssClasses.indexOf(cssClassName) < 0) {
      this.createClass('.' + cssClassName, rules)
      this.createdCssClasses.push(cssClassName)
    }
    return cssClassName
  }

  createClass = function (name, rules) {
    const style = document.createElement('style')
    style.type = 'text/css'

    document.getElementsByTagName('head')[0].appendChild(style)
    style.innerHTML = name + '{' + rules + '}'
  }

  handleDataColumnChanged(value, initialData, previousValue) {
    if (
      !previousValue ||
      (value.columns && value.columns.length !== previousValue.columns.length)
    ) {
      return value
    }
    _.forEach(value.columns, (column, index) => {
      // We changed column alignment options from start, center, end to
      // left, center, right
      if (column.align === 'start') {
        column.align = 'left'
      }

      if (column.align === 'end') {
        column.align = 'right'
      }

      // Data field of the column changed
      if (column.fieldName && previousValue.columns[index].fieldName !== column.fieldName) {
        // Set Header
        if (column.header === previousValue.columns[index].fieldName) {
          column.header = column.fieldName
        }

        if (
          column &&
          column.actionButton &&
          column.actionButton.name === previousValue.columns[index].fieldName
        ) {
          column.actionButton.name = column.fieldName
        }

        const fieldConfigs = this.getFieldConfigs()
        const field = _.find(fieldConfigs, (field) => {
          return field.fieldName === column.fieldName
        })

        // Set column alignment
        if (
          field.dataType === 'int' ||
          field.dataType === 'double' ||
          field.dataType === 'float' ||
          field.dataType === 'decimal' ||
          field.dataType === 'long' ||
          field.dataType === 'short' ||
          field.dataType === 'datetime'
        ) {
          column.align = 'right'
        } else if (field.dataType === 'string') {
          column.align = 'left'
        } else if (field.dataType === 'bool') {
          column.align = 'center'
        }
      }
    })
    return value
  }

  handleDeleteConfigChanged(value, initialData, previousValue, schemaHelper) {
    const { grid: { deleting: { enabled = false } = {} } = {}, columnActions = [] } = value || {}
    const { grid: { deleting: { enabled: enabledPrev = false } = {} } = {} } = previousValue || {}

    // Runs only if the Deleting config changed
    if (enabled !== enabledPrev) {
      if (enabled && _.indexOf(columnActions, 'Delete Button') < 0) {
        // Deleting is enabled

        // Add Delete Button to column actions
        value.columnActions.push('Delete Button')

        // Create a delete column
        const deleteColumn = schemaHelper.getValueOfSchema(
          this.props.schema.properties.columns.items
        )
        deleteColumn.adding.enabled = false
        deleteColumn.align = 'center'
        deleteColumn.columnAction = 'Delete Button'
        deleteColumn.flex = 0
        deleteColumn.fontColor = '#dd6550'
        deleteColumn.sortable = false
        deleteColumn.tooltip = 'Delete'
        deleteColumn.width = 40

        value.columns.push(deleteColumn)
      } else if (!enabled && _.indexOf(columnActions, 'Delete Button') >= 0) {
        // Deleting is enabled

        // Remove the delete botton from grid
        _.remove(value.columns, (column) => {
          return column.columnAction === 'Delete Button'
        })

        // Remove Delete Button from column actions
        _.remove(columnActions, (columnAction) => {
          return columnAction === 'Delete Button'
        })
      }
    }

    return value
  }

  handleSynchronizeDataDefinition(fields, value, schemaHelper) {
    const newValue = _.cloneDeep(value)

    // Create an  empty template for grid column
    const emptyColumn = schemaHelper.getValueOfSchema(this.props.schema.properties.columns.items)

    if (_.isNil(newValue.columns)) {
      newValue.columns = []
    }

    _.remove(newValue.columns, (column) => {
      return column.fieldName === ''
    })

    _.forEach(fields, (field) => {
      // Check if the field is alraedy used as a column
      const existingColumn = _.find(newValue.columns, (column) => {
        return column.fieldName === field.fieldName
      })

      // Create a column if its field does not exist
      if (!existingColumn) {
        const newcolumn = _.cloneDeep(emptyColumn)
        newcolumn.fieldName = field.fieldName
        newcolumn.header = field.fieldName

        if (
          field.dataType === 'datetime' ||
          field.dataType === 'decimal' ||
          field.dataType === 'double' ||
          field.dataType === 'float' ||
          field.dataType === 'int' ||
          field.dataType === 'long' ||
          field.dataType === 'short'
        ) {
          newcolumn.align = 'right'
        } else if (field.dataType === 'string') {
          newcolumn.align = 'left'
        } else if (field.dataType === 'bool') {
          newcolumn.align = 'center'
        }

        newValue.columns.push(newcolumn)
      }
    })

    return newValue
  }

  getModifiedRows() {
    this.getModifiedRowsResult()
  }

  getModifiedDirtyRecords() {
    const isUpdateQuery = this.props?.settings?.query?.dataEditing?.isUpdateQuery
    const modifiedRecords = _.cloneDeep(this.store.getModifiedRecords())
    const fieldConfigs = this.getFieldConfigs()

    return _.reduce(
      modifiedRecords,
      (arr, item) => {
        const { dirty, data } = item
        if (dirty) {
          _.forEach(data, (value, key) => {
            const isDate =
              _.size(
                _.filter(
                  fieldConfigs,
                  ({ fieldName, dataType }) => fieldName === key && dataType === 'datetime'
                )
              ) > 0
            data[key] = isDate ? this.getDateFormat(value) : value
          })
          if (!isUpdateQuery && item.modified) {
            _.forEach(item.modified, (val, index) => {
              this.editList.push({
                changedColumn: index,
                columnValues: data,
                oldValue: val
              })
            })
          }

          arr.push(data)
        }
        return arr
      },
      []
    )
  }

  getConditionedRows(args = {}) {
    const { conditionDataField } = args
    this.conditionedRowsResult(conditionDataField)
  }

  conditionedRowsResult(conditionDataField) {
    let result = {}

    if (this.store && this.store.getData() && this.store.getData().length) {
      const fieldConfigs = this.getFieldConfigs()

      const foundedField = conditionDataField
        ? _.find(fieldConfigs, (item) => {
            return item.fieldName === conditionDataField
          })
        : null

      if (foundedField) {
        const currentStoreData = _.cloneDeep(this.store.getData().items)
        const conditionalRows = _.filter(currentStoreData, (row) => {
          return row.data && row.data[conditionDataField]
        })
        result = _.transform(
          fieldConfigs,
          (result, field) => {
            const fieldValues = _.map(conditionalRows, (modifiedRow) => {
              return modifiedRow.data[field.fieldName]
            })
            result[field.fieldName] = _.size(fieldValues) > 0 ? fieldValues : null
          },
          {}
        )
      }
    }

    return result
  }

  getModifiedRowsResult() {
    const modifiedRecord = this.store.getModifiedRecords()
    const fieldConfigs = this.getFieldConfigs()
    const modifiedRows = []

    _.forEach(modifiedRecord, (row) => {
      if (row.dirty) {
        modifiedRows.push(row)
      }
    })

    return _.transform(
      fieldConfigs,
      (result, field) => {
        const fieldValues = _.map(modifiedRows, (modifiedRow) => {
          return modifiedRow.data[field.fieldName]
        })
        result[field.fieldName] = _.size(fieldValues) > 0 ? fieldValues : null
      },
      {}
    )
  }

  triggerMultiRowSelected() {
    this.triggerMultiRowSelectedResult()
  }

  triggerMultiRowSelectedResult() {
    const fieldConfigs = this.getFieldConfigs()
    const selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()

    const selectedRowCount = selectedRows.length
    const transformedParams = _.transform(
      fieldConfigs,
      (result, field) => {
        const fieldValues = _.map(selectedRows, (selectedRow) => {
          return selectedRow.data[field.fieldName]
        })
        result[field.fieldName] = _.size(fieldValues) > 0 ? fieldValues : null
      },
      { selectedRowCount }
    )

    return transformedParams
  }

  handleStoreBeginUpdate() {
    if (this.store) {
      this.store.suspendEvent('datachanged')
    }
  }

  handleStoreEndUpdate() {
    if (this.store) {
      this.store.resumeEvent('datachanged')
    }
  }

  handleStoreAdd(store, record) {
    const { removeTracking = false } = record

    // Ignore the record if it is deleted before and taken back now
    if (!removeTracking) {
      const columnValues = _.cloneDeep(record[0].data)
      if (!this.isGridDirty()) {
        this.dirtyStateChanged(true)
      }
      this.addList.push({
        columnValues
      })
    } else {
      delete record.removeTracking
    }
  }

  ///TODO
  isGridDirty() {
    const isUpdateQuery = this.props?.settings?.query?.dataEditing?.isUpdateQuery

    if (isUpdateQuery) {
      return (
        this.getModifiedDirtyRecords().length > 0 ||
        _.size(this.addList) > 0 ||
        _.size(this.deleteList) > 0
      )
    } else {
      return _.size(this.editList) > 0 || _.size(this.addList) > 0 || _.size(this.deleteList) > 0
    }
  }

  handleStoreUpdate(store, record, operation, modifiedFieldNames) {
    const {
      props: {
        settings: {
          config: { grid: { editing: { editingType = 'SaveButton' } = {} } = {} } = {},
          query: { dataEditing: { isUpdateQuery = '' } = {} } = {}
        } = {}
      } = {}
    } = this
    const delaySaving = editingType === 'SaveButton' || editingType === 'Trigger'
    const queueSaving = editingType === 'Queue'
    // We only care for editing changes
    if (operation !== 'edit') {
      return
    }

    // Cancel the editing if a column has lookup data and the new value is not in the lookup data.
    // This happens when spreadsheet is enabled in the grid and the user pastes data in the combo columns.
    if (modifiedFieldNames && modifiedFieldNames.length > 0) {
      let lookupDataViolation = false
      if (!this.cellEditingHappened) {
        _.forEach(modifiedFieldNames, (modifiedField) => {
          const { isLookupQuery } = this.getFieldLookupConfig(modifiedField)
          if (isLookupQuery) {
            let columnLookupData = this.getFieldLookupData(modifiedField, record)
            if (_.isNil(columnLookupData)) {
              this.loadLookUpdata(modifiedField, record.data)

              columnLookupData = this.getFieldLookupData(modifiedField, record)
            }
            // TODO check !massUpdate removed
            if (columnLookupData && modifiedField in record.data) {
              const columnConfig = _.find(this.getColumnConfigs(), (column) => {
                return column.fieldName === modifiedField
              })
              const { editing: { allowBlank = false } = {} } = columnConfig
              const lookupValue = _.find(columnLookupData, (row) => {
                return (
                  record.data[modifiedField] &&
                  row.value.toString() === record.data[modifiedField].toString()
                )
              })
              const valueIsNotPossible = _.isNil(lookupValue)

              const blankValueViolation = !allowBlank && record.data[modifiedField] === null

              if (valueIsNotPossible || blankValueViolation) {
                lookupDataViolation = true
                return false
              } else {
                record.data[modifiedField] = lookupValue.id
              }
            }
          }
        })
      }
      // TODO this.cellEditingHappened
      // this.cellEditingHappened = false

      if (lookupDataViolation) {
        record.reject()
        return
      }
      const columnConfigs = this.getColumnConfigs()

      _.forEach(modifiedFieldNames, (modifiedFieldName) => {
        const columnValues = _.cloneDeep(record.data)
        const oldValue = record.modified[modifiedFieldName]
        const newValue = columnValues[modifiedFieldName]

        // Display warning thresholds
        const modifiedColumn = _.find(columnConfigs, {
          fieldName: modifiedFieldName
        })
        const { editing: { warningThreshhold = 0 } = {} } = modifiedColumn || {}

        let warningThreshholdExceeded = false
        if (!this.warningThresholdApproved) {
          warningThreshholdExceeded = this.isWarningThresholdExceeded(
            modifiedColumn,
            oldValue,
            newValue
          )
        }

        if (warningThreshholdExceeded) {
          Ext.MessageBox.confirm(
            'Threshold Exceeded',
            `The value has changed more than ${warningThreshhold} percent. Do you want to continue?`,
            (btn) => {
              if (btn === 'yes') {
                this.handleCellValueChanged(
                  modifiedFieldName,
                  record,
                  columnValues,
                  oldValue,
                  delaySaving,
                  queueSaving,
                  isUpdateQuery
                )
              } else {
                record.reject()
              }
            }
          )
        } else {
          this.handleCellValueChanged(
            modifiedFieldName,
            record,
            columnValues,
            oldValue,
            delaySaving,
            queueSaving,
            isUpdateQuery
          )
        }
      })
    }
  }

  handleCellValueChanged(
    modifiedFieldName,
    record,
    columnValues,
    oldValue,
    delaySaving,
    queueSaving,
    isUpdateQuery = false
  ) {
    const fieldConfigs = this.getFieldConfigs()
    columnValues = this.formatDatesForUpdate(columnValues, fieldConfigs)

    if (!delaySaving) {
      // Save Button is not displayed.Directly apply the changes.
      if (_.has(columnValues, __RowIndex)) {
        const rowIndex = columnValues[__RowIndex]
        let updateData
        if (isUpdateQuery) {
          // records should be an array but includes 1 row for this case.
          let records = []
          records.push(columnValues)
          updateData = {
            type: 0,
            records: records
          }
          this.updateRow({ updateData, reloadGridData: false, queueSaving })
          if (record) {
            const { substitudeField } = this.getFieldLookupConfig(modifiedFieldName)
            if (substitudeField) {
              // If the edited column has a substitude field
              // Update also the substitude value in redux data and store
              const lookupValue = this.getLookupValue({
                field: modifiedFieldName,
                records: columnValues,
                id: columnValues[modifiedFieldName]
              })
              columnValues[substitudeField] = lookupValue
              record.data[substitudeField] = lookupValue
            }

            if (!queueSaving) {
              this.props.updateRowInLocalData(rowIndex, columnValues)
            }
            record.commit()
          }
        } else {
          updateData = this.prepareUpdateData([
            { changedColumn: modifiedFieldName, columnValues: columnValues }
          ])
          if (_.size(updateData.updateItems) > 0) {
            this.updateValue(updateData, false, {}, queueSaving)
            const { substitudeField } = this.getFieldLookupConfig(modifiedFieldName)
            if (substitudeField) {
              // If the edited column has a substitude field
              // Update also the substitude value in redux data and store
              const lookupValue = this.getLookupValue({
                field: modifiedFieldName,
                records: columnValues,
                id: columnValues[modifiedFieldName]
              })
              columnValues[substitudeField] = lookupValue
              record.data[substitudeField] = lookupValue
            }

            if (!queueSaving) {
              this.props.updateRowInLocalData(rowIndex, columnValues)
            }
            record.commit()
          }
        }
      }
    } else {
      // Save Button not displayed. Save the changes in a list. They will be applied when the save button is clicked
      if (!isUpdateQuery) {
        _.forEach(columnValues, (value, key) => {
          if (record.previousValues && key in record.previousValues && key !== modifiedFieldName) {
            columnValues[key] = record.previousValues[key]
          }
        })

        const addListItem = _.find(this.addList, (addListItem) => {
          return addListItem.columnValues.id === record.id
        })
        if (addListItem) {
          addListItem.columnValues = record.data
        } else {
          const firstEditedRec = _.find(this.editList, (editItem) => {
            return (
              editItem.changedColumn === modifiedFieldName &&
              editItem.columnValues[__RowIndex] === columnValues[__RowIndex]
            )
          })
          if (firstEditedRec && firstEditedRec.oldValue === columnValues[modifiedFieldName]) {
            // A cell is changed back to its original value
            // If the grid is not dirty after this change, fire not dirty
            _.remove(this.editList, (editItem) => {
              return (
                editItem.changedColumn === modifiedFieldName &&
                editItem.columnValues[__RowIndex] === columnValues[__RowIndex]
              )
            })
            if (!this.isGridDirty()) {
              this.dirtyStateChanged(false)
            }
          } else {
            // A cell is updated
            // if the grid is not dirty before this change fire dirty
            if (!this.isGridDirty()) {
              this.dirtyStateChanged(true)
            }
            this.editList.push({
              changedColumn: modifiedFieldName,
              columnValues,
              oldValue
            })
          }
        }
      } else {
        //TODO
        // this.dirtyStateChanged(this.isGridDirty())

        if (this.addList.length > 0) {
          const addListItem = _.find(this.addList, (addListItem) => {
            return addListItem.columnValues.id === record.id
          })

          if (addListItem) {
            addListItem.columnValues = record.data
          }
        }
      }
    }
  }

  dirtyStateChanged(dirty) {
    const {
      props: {
        settings: {
          config: { grid: { dataDetails: { unsavedChangesText = '' } = {} } = {} } = {}
        } = {}
      } = {}
    } = this
    if (unsavedChangesText.length > 0) {
      this.setUnsavedChangesText(unsavedChangesText, dirty)
    }
    return { dirty, clean: !dirty }
  }

  handleStoreBeforeSort(store, sorters) {
    _.forEach(sorters, (sorter, index) => {
      const { substitudeField } = this.getFieldLookupConfig(sorter._property)
      if (substitudeField) {
        sorters[index]._property = substitudeField
      }
    })
  }

  getTagFilterStates = (columnStates) => {
    const columnConfigs = this.getColumnConfigs()
    const tagFilterStates = _.transform(
      columnStates,
      (res, columnState) => {
        if (_.has(columnState, 'tagFilter')) {
          // Get the fieldName from columnConfig
          const columnConfig = _.nth(columnConfigs, columnState.configIndex)
          if (columnConfig) {
            res.push(columnState.tagFilter)
          }
        }
      },
      []
    )
    return _.cloneDeep(tagFilterStates)
  }

  getSortingInfo(props) {
    const { settings: { config: gridConfig = {} } = {} } = this.props

    const pluginState = this.getLoadedPluginStates(props) || {}

    const { configHash = '', version } = pluginState || {}
    if (configHash !== hash(gridConfig) || version !== 2) {
      return []
    }

    const { columnStates = [] } = pluginState

    const columnConfigs = this.getColumnConfigs()
    return _.transform(
      columnStates,
      (res, columnState) => {
        if (_.has(columnState, 'sortState')) {
          // Get the fieldName from columnConfig
          const columnConfig = _.nth(columnConfigs, columnState.configIndex)
          if (columnConfig && columnConfig.fieldName !== '__SLVYRowIndex') {
            res.push({ [columnConfig.fieldName]: columnState.sortState })
          }
        }
      },
      []
    )
  }

  setUnsavedChangesText(unsavedChangesText, dirty) {
    const toolbarItem =
      this.extjsGrid &&
      this.extjsGrid.cmp.getDockedItems('toolbar[dock="bottom"]').find((item) => {
        return item.xtype === 'toolbar'
      })
    if (!_.isNil(toolbarItem)) {
      const unsavedComp = toolbarItem.getComponent('unsavedChanges')
      if (dirty) {
        unsavedComp.setText(unsavedChangesText)
        unsavedComp.setStyle({ color: 'red' })
      } else {
        unsavedComp.setText('')
        unsavedComp.setStyle({ color: 'black' })
      }
    }
  }

  setFooterButtons(pluginData) {
    const dockedItems = this && this.extjsGrid.cmp && this.extjsGrid.cmp.dockedItems.items
    const footerBar = _.find(dockedItems, (item) => {
      return item.xtype === 'toolbar' && item.dock === 'bottom' && item.ui === 'footer'
    })
    const footerBarItems = footerBar && footerBar.items && footerBar.items.items
    if (_.size(footerBarItems) <= 0) {
      return
    }
    const {
      props: { settings: { config: { grid: { footerButtons = [] } = {} } = {} } = {} } = {}
    } = this
    const that = this

    _.forEach(footerButtons, (button) => {
      const { buttonText, enableFooterButton = null } = button
      const footerButton = _.find(footerBarItems, (footerItem) => footerItem.text === buttonText)
      if (footerButton) {
        if (!_.isNil(enableFooterButton)) {
          const record = that.getFirstDataRow(pluginData)
          if (record && !!record[enableFooterButton] === false) {
            footerButton.disable()
          } else {
            footerButton.enable()
          }
        } else {
          footerButton.enable()
        }
      }
    })
  }

  setDataToStore(nextData, keys, updatedRows) {
    const { items: prevData = [] } = this.store.getData() || {}

    let filteredNextData = nextData
    if (_.size(updatedRows) > 0) {
      filteredNextData = _.filter(nextData, (nextDataRow) => {
        return _.reduce(
          updatedRows,
          (rowMatch, updatedRow) => {
            return (
              rowMatch ||
              _.reduce(
                keys,
                function (keyMatch, key) {
                  const { fieldName } = key
                  return keyMatch && nextDataRow[fieldName] === updatedRow[fieldName]
                },
                true
              )
            )
          },
          false
        )
      })
    }
    // Update rows
    _.forEach(filteredNextData, (nextDataRow) => {
      // Find matching row
      const matchingPrevDataRow = _.find(prevData, (prevDataRow) => {
        return _.reduce(
          keys,
          function (keyMatch, key) {
            const { fieldName } = key
            return keyMatch && prevDataRow.get(fieldName) === nextDataRow[fieldName]
          },
          true
        )
      })

      if (matchingPrevDataRow) {
        // Set row
        _.forEach(nextDataRow, (value, fieldName) => {
          if (nextDataRow[fieldName] !== matchingPrevDataRow.get(fieldName)) {
            matchingPrevDataRow.set(fieldName, value, {
              commit: true
            })
          }
        })
      }
    })

    if (_.size(updatedRows) === 0) {
      // Remove rows
      const rowsToRemove = []
      _.forEach(prevData, (prevDataRow) => {
        const matchingNextDataRow = _.find(nextData, (nextDataRow) => {
          return _.reduce(
            keys,
            function (keyMatch, key) {
              const { fieldName } = key
              return keyMatch && prevDataRow.get(fieldName) === nextDataRow[fieldName]
            },
            true
          )
        })
        if (!matchingNextDataRow) {
          rowsToRemove.push(prevDataRow)
        }
      })
      if (_.size(rowsToRemove) > 0) {
        this.store.remove(rowsToRemove)
      }

      // Add rows
      _.forEach(nextData, (nextDataRow) => {
        // Find matching row
        const matchingPrevDataRow = _.find(prevData, (prevDataRow) => {
          return _.reduce(
            keys,
            function (keyMatch, key) {
              const { fieldName } = key
              return keyMatch && prevDataRow.get(fieldName) === nextDataRow[fieldName]
            },
            true
          )
        })

        if (!matchingPrevDataRow) {
          // Set row
          this.store.add(nextDataRow)
        }
      })
    }
  }

  createReportExportInfo(isChangedColumn) {
    const exportedColumn = []
    const formattedFields = this.getFormattedFields()

    const { props: gridProps = {} } = this

    const { settings: { config: gridConfig = {} } = {} } = gridProps

    const { general: { exportWithoutHeaders = false } = {} } = gridConfig
    if (!exportWithoutHeaders) {
      if (isChangedColumn) {
        const { columnStates = [] } = this.pluginState || {}
        const columnConfigs = this.getColumnConfigs()
        const sortedColumns = _.sortBy(columnStates, 'columnOrder')
        _.forEach(sortedColumns, (column) => {
          let { name, configIndex, columnOrder, groupHeader } = column
          const columnConfig = columnConfigs[configIndex]
          const { fieldName } = columnConfig || {}
          let formattedField = _.find(formattedFields, {
            columnName: fieldName
          })
          const { formatString } = formattedField || {}
          name = !_.isEmpty(name) ? name : fieldName
          if (columnOrder > -1 && !_.isEmpty(fieldName)) {
            exportedColumn.push({
              ColumnName: fieldName,
              DisplayName: name,
              FormatString: formatString || '',
              GroupHeader: groupHeader ? groupHeader + '|' + name : ''
            })
          }
        })
      } else {
        if (this.extjsGrid) {
          const gridColumns = this.extjsGrid.cmp.getColumns()
          if (!_.isEmpty(gridColumns)) {
            const columns = _.filter(gridColumns, (column) => column.isVisible())
            _.forEach(columns, (column) => {
              let { dataIndex, text = dataIndex, groupHeader } = column
              let formattedField = _.find(formattedFields, {
                columnName: dataIndex
              })
              const { formatString } = formattedField || {}
              if (!_.isEmpty(dataIndex)) {
                text = !_.isEmpty(text) ? text : dataIndex
                exportedColumn.push({
                  ColumnName: dataIndex,
                  DisplayName: text,
                  FormatString: formatString || '',
                  GroupHeader: groupHeader ? groupHeader + '|' + text : ''
                })
              }
            })
          }
        }
      }
    }
    this.props.reportExportInfo(exportedColumn)
  }

  applyDataTemplates(extjsGridCmp, gridConfig, pluginData) {
    const firstDataRow = this.getFirstDataRow(pluginData)

    const actualColumns = extjsGridCmp.getColumns()
    const { columns: columnConfigs = [] } = gridConfig
    _.forEach(actualColumns, (actualColumn) => {
      if (actualColumn && actualColumn.dataIndex) {
        const columnConfig = _.find(columnConfigs, {
          fieldName: actualColumn.dataIndex
        })
        if (columnConfig) {
          // Set column header
          if (_.indexOf(columnConfig.header, '{') >= 0) {
            const templatedHeader = this.replaceTemplate(columnConfig.header, firstDataRow)
            actualColumn.setText(templatedHeader)
          }

          if (columnConfig.hiddenCondition) {
            const hidden = !!firstDataRow[columnConfig.hiddenCondition]
            actualColumn.setHidden(hidden)
          }

          // Set column tooltip
          if (_.indexOf(columnConfig.tooltip, '{') >= 0 && actualColumn?.el?.dom) {
            const templatedTooltip = this.replaceTemplate(columnConfig.tooltip, firstDataRow)
            if (templatedTooltip) {
              actualColumn.el.dom.dataset.qtip = templatedTooltip
            }
          }

          // Set column group header
          if (
            actualColumn.isSubHeader &&
            actualColumn.ownerCt &&
            _.indexOf(columnConfig.groupHeader, '{') >= 0
          ) {
            const templatedGroupHeader = this.replaceTemplate(
              columnConfig.groupHeader,
              firstDataRow
            )
            actualColumn.ownerCt.setText(templatedGroupHeader)
          }
        }
      }
    })
  }

  pagingEnabled(props) {
    const { settings: { config: { grid: { paging: pagingEnabled = false } = {} } = {} } = {} } =
      props

    const isPagedQuery = props.isPagedQuery()

    return pagingEnabled && isPagedQuery
  }

  handleFooterButtonClick() {
    return { refreshKey: uuidv4() }
  }

  getColumnStates({ columnConfigs, reducedColumnsSettings, sorters, filters, gridState }) {
    const gridConfig = this.props.settings.config || {}

    const { grid: { selection: { type: selectionType = '' } = {}, lockable = false } = {} } =
      gridConfig
    // Prepare actual column states
    const columnStates = _.map(columnConfigs, (columnConfig, index) => {
      const {
        showInMaxMode = false,
        hidden: columnConfigHidden = false,
        fieldName = null
      } = columnConfig
      const columnSettings =
        _.find(reducedColumnsSettings, {
          configIndex: index
        }) || {}

      const columnOrder = _.findIndex(reducedColumnsSettings, {
        configIndex: index
      })
      const { text, width, hidden, hiddenAncestor, locked, dataIndex } = columnSettings

      const groupHeader = _.find(gridState.columns, (column) => {
        return column.columns && _.find(column.columns, { id: columnSettings.headerId })
      })
      // An extra column is auto generated by extJS when selection is Spreadsheet
      // Therefore - 1 for the right column order
      const columnState = {
        name: text,
        configIndex: index,
        columnOrder: selectionType === 'Spreadsheet' ? columnOrder - 1 : columnOrder
      }

      if (!showInMaxMode) {
        if (!_.isNil(width)) {
          columnState.width = width
        }

        if (hidden || hiddenAncestor) {
          columnState.hidden = true
        } else if (columnConfigHidden) {
          // Save the hidden = false state only if the column is is hidden in the config
          columnState.hidden = false
        }

        if (lockable) {
          if (_.has(groupHeader, 'locked')) {
            columnState.locked = groupHeader.locked
          } else {
            columnState.locked = locked
          }
        }

        const sortConfig = _.find(sorters, { id: dataIndex })

        if (sortConfig) {
          columnState.sortState = sortConfig.direction
          columnState.sortIndex = _.indexOf(sorters, sortConfig)
        }
        // If the column is filtered save the filters
        const columnFilters = _.transform(
          _.filter(filters, { fieldName }),
          (result, filter) => {
            _.assign(result, filter.condition)
          },
          {}
        )
        if (!_.isEmpty(columnFilters)) {
          columnState.filter = columnFilters
        }
        const tagFilter = _.find(this.tagFilters, (item) => {
          return item.field === fieldName
        })
        if (tagFilter) {
          columnState.tagFilter = tagFilter
        }

        if (columnSettings.isSubHeader && columnSettings.ownerCt) {
          columnState.groupHeader = columnSettings.ownerCt.slvyTemplate
        }
      }
      return columnState
    })
    return columnStates
  }

  getActionStates(columnStatesSize, reducedColumnsSettings) {
    const gridConfig = this.props.settings.config || {}
    let actionStates
    const {
      grid: { selection: { type: selectionType = '' } = {} } = {},
      actions: actionConfigs = {}
    } = gridConfig
    const { displayMode = null } = actionConfigs

    if (displayMode === 'Buttons') {
      actionStates = _.map(actionConfigs, (actionConfig, index) => {
        const configIndex = columnStatesSize + index
        const columnOrder = _.findIndex(reducedColumnsSettings, { configIndex })

        const columnSettings =
          _.find(reducedColumnsSettings, {
            configIndex
          }) || {}

        const actionState = {
          name: actionConfig.title,
          configIndex,
          columnOrder:
            selectionType === 'Spreadsheet' || selectionType === 'Multiselect'
              ? columnOrder - 1
              : columnOrder
        }

        if (columnSettings.isSubHeader && columnSettings.ownerCt) {
          actionState.groupHeader = columnSettings.ownerCt.slvyTemplate
        }

        return actionState
      })
    } else {
      const configIndex = columnStatesSize
      const columnOrder = _.findIndex(reducedColumnsSettings, {
        configIndex
      })

      const columnSettings =
        _.find(reducedColumnsSettings, {
          configIndex
        }) || {}

      const actionState = {
        name: 'actionMenu',
        configIndex,
        columnOrder:
          selectionType === 'Spreadsheet' || selectionType === 'Multiselect'
            ? columnOrder - 1
            : columnOrder
      }

      if (columnSettings.isSubHeader && columnSettings.ownerCt) {
        actionState.groupHeader = columnSettings.ownerCt.slvyTemplate
      }

      actionStates = [actionState]
    }
    return actionStates
  }

  saveColumnState(columnsSettings, groupingColumn, sorters, filters, gridState) {
    // Prevent the the grid to try save plugin state during grid is build
    if (!this.props.pluginStates.isSuccess || this.componentUpdating) {
      return
    }
    const gridConfig = this.props.settings.config || {}
    const columnConfigs = this.getColumnConfigs()
    let reducedColumnsSettings = columnsSettings
    if (columnsSettings[0].cls === 'x-selmodel-column') {
      reducedColumnsSettings = _.slice(columnsSettings, 1)
    }

    let columnStates = this.getColumnStates({
      columnConfigs,
      reducedColumnsSettings,
      sorters,
      filters,
      gridState
    })

    const actionStates = this.getActionStates(_.size(columnStates), reducedColumnsSettings)

    columnStates = columnStates.concat(actionStates)
    const pluginState = {
      columnStates,
      groupingColumn,
      configHash: hash(gridConfig),
      version: 2
    }

    const newPluginState = { ...this.pluginState, ...pluginState }

    if (!_.isEqual(newPluginState, this.pluginState)) {
      this.pluginState = newPluginState
      if (this.props.token) {
        const isChangedColumn = true
        this.createReportExportInfo(isChangedColumn)
        this.savePluginState(newPluginState)
      }
    }
  }

  savePluginState(pluginState) {
    const { props: { id: pluginId, params: { catalogId } = {} } = {} } = this
    const clientData = {
      name: 'grid state ' + pluginId,
      pluginId: pluginId,
      catalogId: catalogId,
      config: {
        state: pluginState,
        stateId: 1
      }
    }
    return this.apiClientRequest({
      requestType: 'pluginstateupsert',
      clientData
    })
  }

  sendWarningMessage(data) {
    if (data && 'result' in data) {
      const { result = [] } = data
      const errFiltered = _.filter(result, { isSuccess: false })
      if (errFiltered.length > 0) {
        const errGrouped = _.groupBy(errFiltered, 'errorCode')
        const errMessages = _.transform(
          errGrouped,
          (res, item, key) => {
            if (key === '') {
              res.push(`An error occured (${item.length})`)
            } else {
              res.push(`${key} (${item.length})`)
            }
            return res
          },
          []
        ).join('<br>')

        slvyToast.warning({ message: errMessages })
      }
    }
  }

  forceReloadAfterUpdate(updateKey) {
    // Force data fetching
    this.props.clearCaches(true)
    this.props.setDataArguments(null, true, true, updateKey)
  }

  enqueueReloadAfterUpdateRequest(updateKey) {
    if (_.size(this.reloadAfterUpdateQueue) === 0) {
      this.reloadAfterUpdateQueue.push(updateKey)
    }
  }

  processReloadAfterUpdateRequest() {
    if (!this.reloadAfterUpdateInProgress) {
      if (_.size(this.reloadAfterUpdateQueue) > 0) {
        const reloadRequest = this.reloadAfterUpdateQueue.shift()
        this.reloadAfterUpdateQueue = []
        this.reloadAfterUpdateInProgress = true
        this.forceReloadAfterUpdate(reloadRequest)
      }
    }
  }

  processUpdateQueue(pluginId, clientData) {
    if (!this.updateInProgress) {
      const nextRequest = this.props.dequeueUpdateRequest(pluginId)
      if (nextRequest) {
        this.executedUpdates[nextRequest.updateKey] = clientData
        this.updateInProgress = true
        return nextRequest
      }
    }
  }

  handleSelection() {
    const {
      settings: {
        config: {
          grid: { selection: { type: selectionType = 'Row', keepSelectedRows = false } = {} } = {}
        } = {}
      } = {}
    } = this.props

    if (this.extjsGrid && this.extjsGrid.cmp) {
      const selectedRecords =
        this.extjsGrid.cmp && this.extjsGrid.cmp.getSelectionModel().getSelection()
      if (keepSelectedRows && selectionType === 'Multiselect') {
        this.selectedRows = _.map(selectedRecords, (record) => {
          const selectedIndex = this.store.indexOf(record)
          return selectedIndex
        })
      }

      if (selectedRecords && selectedRecords[0] && selectionType === 'Row') {
        this.selectedRow = this.store.indexOf(selectedRecords[0])
      }
    }
  }

  updateValue(updateData, reloadGridData = false, updateParameters = {}, queueSaving = false) {
    const {
      id: pluginId,
      data: { updatehints = {} } = {},
      actualFilters = {},
      additionalArgs = {},
      settings: { config: { grid: { editing: { lockGrid = true } = {} } = {} } = {} } = {}
    } = this.props || {}

    const { extjsGrid } = this
    if (lockGrid && !queueSaving) {
      extjsGrid.cmp.mask('Applying Changes...')
      extjsGrid.cmp.viewModel.set('gridEnabled', false)
    }

    const clientData = {
      ...updateData,
      updatehints,
      filters: { ...actualFilters, ...updateParameters, ...additionalArgs }
    }

    const updateParams = { reloadGridData, extjsGridCmp: extjsGrid.cmp, lockGrid }
    const updateRequest = this.apiClientRequest({
      requestType: 'update',
      clientData,
      updateParams
    })

    if (queueSaving) {
      updateRequest.updateKey = uuidv4()
      this.props.enqueueUpdateRequest(pluginId, updateRequest)
      this.processUpdateQueue(pluginId, clientData)
    } else {
      return updateRequest
    }
  }

  apiClientRequest({ requestType = 'update', clientData, updateParams, editParams, lookupParams }) {
    const {
      props: {
        settings: {
          config: {
            grid: { editing: { keepChanges = false, editingType = 'SaveButton' } = {} } = {}
          } = {}
        } = {},
        id: pluginId,
        client
      } = {}
    } = this
    let updateRequest
    let clientUrl = `/data/plugin/${pluginId}/update`
    let failState = false
    let reloadData = false
    const queueSaving = editingType === 'Queue'
    if (requestType === 'update') {
      const { reloadGridData, extjsGridCmp, lockGrid } = updateParams
      updateRequest = client
        .post(clientUrl, { data: clientData })
        .then((res) => {
          this.sendWarningMessage(res)
          failState = !_.isNil(res) && 'result' in res && !!_.find(res.result, { isSuccess: false })
          if (failState) {
            reloadData = _.find(res.result, { successCode: -1 })
              ? true
              : _.find(res.result, { successCode: -2 })
              ? false
              : reloadGridData
          } else {
            reloadData = reloadGridData
          }

          if (reloadData) {
            this.editList = []
            // Force data fetching
            this.props.clearCaches()
            this.props.setDataArguments(null, true)
            this.dirtyStateChanged(false)
          }
          if (!failState) {
            this.handleDataUpdated()
          }
        })
        .catch(this.handleUpdateError)
        .finally(() => {
          if (!queueSaving) {
            if (lockGrid) {
              extjsGridCmp && extjsGridCmp.unmask()
              extjsGridCmp.viewModel.set('gridEnabled', true)
            }
          } else {
            this.enqueueReloadAfterUpdateRequest(updateRequest.updateKey)
            this.processReloadAfterUpdateRequest()

            this.updateInProgress = false
            this.processUpdateQueue(pluginId, clientData)
          }
        })
    } else if (requestType === 'lookup') {
      const { field, record, filters } = lookupParams
      clientUrl = '/data/plugin/' + pluginId + '/lookup/' + field
      updateRequest = client.post(clientUrl, { data: clientData }).then((res) => {
        if (!_.isNil(res)) {
          const comboData = _.map(res.data.result, (row) => {
            return _.isNil(row.Id)
              ? { id: row.Value, value: row.Value }
              : { id: row.Id, value: row.Value }
          })
          if (!this.lookupData[field]) {
            this.lookupData[field] = {}
          }
          const keyRecordsHash = this.getLookupHash({
            field,
            records: record,
            filters
          })

          this.lookupData[field][keyRecordsHash] = comboData
        }
      })
    } else if (requestType === 'edit') {
      clientUrl = '/data/plugin/' + pluginId + '/edit'
      const { extjsGridCmp, reloadGridData, updateDataType, rowIndex } = editParams
      updateRequest = client
        .post(clientUrl, { data: clientData })
        .then((res) => {
          this.sendWarningMessage(res)
          failState = !_.isNil(res) && 'result' in res && !!_.find(res.result, { isSuccess: false })
          if (failState) {
            reloadData = _.find(res.result, { successCode: -1 })
              ? true
              : _.find(res.result, { successCode: -2 })
              ? false
              : reloadGridData
          } else {
            reloadData = reloadGridData
          }

          if (!reloadData) {
            if (updateDataType === 2 && !_.isNil(rowIndex)) {
              this.props.deleteRowInLocalData(rowIndex)
            }
          } else {
            switch (updateDataType) {
              case 0:
                this.editList = []
                break
              case 1:
                this.addList = []
                break
              case 2:
                // TODO delete rowIndex updateParameters bilerek mi beraber yazılmıştı? check
                this.deleteList = []
                break
              default:
                break
            }
            this.props.clearCaches()
            // Force data fetching
            this.props.setDataArguments(null, true)
            this.dirtyStateChanged(false)
          }
          if (!failState) {
            this.handleDataUpdated()
          }
        })
        .catch(this.handleUpdateError)
        .finally(() => {
          try {
            if (!queueSaving) {
              extjsGridCmp && extjsGridCmp.unmask()
              extjsGridCmp.viewModel.set('gridEnabled', true)
            }
          } catch (error) {
            //TODO
          }
        })
    } else if (requestType === 'pluginstateupsert') {
      clientUrl = '/pluginstateupsert'
      updateRequest = client.post(clientUrl, { data: clientData }).then(() => {
        const { pluginStates } = this.props
        this.props.dispatch(pluginStates.fetch)
      })
    }

    return updateRequest
  }

  handleUpdateError(response) {
    const { responseJSON: { message = '' } = {} } = response || {}
    slvyToast.warning({ message: message })
  }

  prepareAddData(updateData = []) {
    // Eliminate columns which are not insertable
    const columnConfigs = this.getColumnConfigs()
    const fieldConfigs = this.getFieldConfigs()
    const recordsToBeAdded = []
    _.forEach(updateData, (row) => {
      let newRow = {}
      _.forEach(row, (value, key) => {
        const columnConfig = _.find(columnConfigs, { fieldName: key })
        const { adding: { enabled: addingEnabled } = {} } = columnConfig || {}
        if (addingEnabled) {
          newRow[key] = value
        }
      })

      newRow = this.formatDatesForUpdate(newRow, fieldConfigs)
      recordsToBeAdded.push(newRow)
    })
    return recordsToBeAdded
  }

  updateRow({
    updateData,
    reloadGridData = false,
    updateParameters = {},
    rowIndex = null,
    queueSaving = false
  }) {
    const {
      data: { updatehints = {} } = {},
      actualFilters = {},
      additionalArgs = {}
    } = this.props || {}
    const { extjsGrid } = this

    switch (updateData.type) {
      case 0: // update
        if (!queueSaving) {
          extjsGrid.cmp.mask('Applying Changes...')
          extjsGrid.cmp.viewModel.set('gridEnabled', false)
        }
        break
      case 1: // insert
        updateData.records = this.prepareAddData(updateData.records)
        if (!queueSaving) {
          extjsGrid.cmp.mask('Adding...')
          extjsGrid.cmp.viewModel.set('gridEnabled', false)
        }
        break
      case 2: // delete
        if (!queueSaving) {
          extjsGrid.cmp.mask('Deleting...')
          extjsGrid.cmp.viewModel.set('gridEnabled', false)
        }
        break
      default:
        break
    }
    const clientData = {
      ...updateData,
      updatehints,
      filters: { ...actualFilters, ...updateParameters, ...additionalArgs }
    }
    const editParams = {
      extjsGridCmp: extjsGrid.cmp,
      reloadGridData,
      rowIndex,
      updateDataType: updateData.type
    }

    return this.apiClientRequest({
      clientData,
      editParams,
      requestType: 'edit'
    })
  }

  loadLookUpdata(field, record) {
    const {
      data: { updatehints = {} } = {},
      actualFilters = {},
      additionalArgs = {}
    } = this.props || {}

    const filters = { ...actualFilters, ...additionalArgs }
    // We need update hints and current record data to load lookup data
    const lookupInputData = {
      filters,
      record,
      updatehints
    }
    const lookupParams = { field, record, filters }

    return this.apiClientRequest({
      clientData: lookupInputData,
      lookupParams,
      requestType: 'lookup'
    })
  }

  handleActionClick(record, actionItemTitle) {
    const fieldConfigs = this.getFieldConfigs()

    return _.transform(
      fieldConfigs,
      (result, field) => {
        result[field.fieldName] = record.data[field.fieldName]
      },
      {
        _ActionTitle: actionItemTitle,
        _RefreshKey: uuidv4(),
        [__RowIndex]: record.data[__RowIndex]
      }
    )
  }

  fireMultiRowSelected() {
    setTimeout(() => this.handleMultiRowSelected(), 0)
  }

  handleMultiRowSelected() {
    const fieldConfigs = this.getFieldConfigs()

    let selectedRows

    if (this.extjsGrid) {
      selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()
    }

    const selectedRowCount = selectedRows.length
    const transformedParams = _.transform(
      fieldConfigs,
      (result, field) => {
        const fieldValues = _.map(selectedRows, (selectedRow) => {
          return selectedRow.data[field.fieldName]
        })
        result[field.fieldName] = _.size(fieldValues) > 0 ? fieldValues : null
      },
      { selectedRowCount }
    )

    return transformedParams
  }

  fireFormattedRowSelected(record, fields) {
    setTimeout(() => this.handleFormattedRowSelected(record, fields), 0)
  }

  handleFormattedRowSelected(record, fields) {
    const { getFormattedValue } = this.props
    const formattedFields = this.getFormattedFields()

    if (record !== null) {
      return _.transform(
        fields,
        (result, field) => {
          result[field.fieldName] = getFormattedValue(
            field.fieldName,
            record.data[field.fieldName],
            formattedFields
          )
        },
        {}
      )
    } else {
      // Deselect case
      return _.transform(
        fields,
        (result, field) => {
          result[field.fieldName] = ''
        },
        {}
      )
    }
  }

  fireRowSelected(grid, record, index, eOpts) {
    setTimeout(() => this.handleRowSelected(grid, record, index, eOpts), 0)
  }

  handleRowSelected(grid, record) {
    const fieldConfigs = this.getFieldConfigs()

    this.rowChanging = false

    if (!_.isNil(record)) {
      this.fireFormattedRowSelected(record, fieldConfigs)
      return _.transform(
        fieldConfigs,
        (result, field) => {
          result[field.fieldName] = record.data[field.fieldName]
        },
        {
          rowSelected: true,
          rowDeselected: false,
          refreshKey: uuidv4(),
          [__RowIndex]: record.data[__RowIndex]
        }
      )
    } else {
      // Deselect case
      return _.transform(
        fieldConfigs,
        (result, field) => {
          result[field.fieldName] = null
        },
        {
          rowSelected: false,
          rowDeselected: true,
          refreshKey: uuidv4(),
          [__RowIndex]: null
        }
      )
    }
  }

  handleSelectionChange(grid, selected) {
    const {
      settings: {
        config: { grid: { selection: { type: selectionType = '' } = {} } = {} } = {}
      } = {}
    } = this.props

    if (_.size(selected) === 0) {
      this.fireRowSelected(grid, null, null, null)
    }

    if (_.size(selected) > 0) {
      this.fireRowSelected(grid, _.last(selected), null, null)
    }

    if (selectionType === 'Multiselect') {
      // Trigger MultiRowSelected
      this.fireMultiRowSelected()
    }
  }

  handleRowDeselected(grid, record, index, eOpts) {
    const {
      settings: {
        config: { grid: { selection: { type: selectionType = '' } = {} } = {} } = {}
      } = {}
    } = this.props

    // Ignore when multi selection is on
    if (selectionType !== 'Multiselect') {
      if (this.rowChanging) {
        // We have to differentiate between the cases when user selects another row
        // and when user deselect a row. We use the local variable rowChanging for this purpose.

        this.rowChanging = false
      } else {
        this.fireRowSelected(grid, null, null, eOpts)
      }
    }
    this.selectedRow = null
    _.pull(this.selectedRows, index)
  }

  handleBeforeRowSelected() {
    this.rowChanging = true
    return true
  }

  handleAfterRender(grid) {
    // We no longer set height of the grid in render
    // Initially height of the grid should be set here
    if (grid.height !== this.props.size.height) {
      grid.setHeight(this.props.size.height)
    }
    // width does not provide from props
    // if (grid.width !== this.props.width) {
    //   grid.setWidth(this.props.width)
    // }

    // We register on selectionchange in after render
    // to prevent this event is called multiple times
    grid.on({
      selectionchange: this.handleSelectionChange
    })
  }

  handleCellDoubleClick(grid, td, cellIndex, record) {
    const {
      config: {
        grid: { selection: { type: selectionType = '' } = {} } = {},
        columns: columnConfigs = []
      } = {}
    } = this.props.settings || {}

    const fieldConfigs = this.getFieldConfigs()

    if (selectionType === 'Spreadsheet') {
      const { grid: { columnManager: { columns: gridColumns = [] } = {} } = {} } = grid || {}

      // The column field of the clicked cell
      const dataIndex = gridColumns[cellIndex].dataIndex

      const columnConfig = _.find(columnConfigs, { fieldName: dataIndex })

      const { action: { cellClickEnabled = false } = {} } = columnConfig || {}
      if (cellClickEnabled) {
        const res = _.transform(
          fieldConfigs,
          (result, field) => {
            result[field.fieldName] = record.data[field.fieldName]
          },
          {
            [__RowIndex]: record.data[__RowIndex],
            columnField: dataIndex
          }
        )
        this['handleCellClick' + columnConfig.fieldName](res)
      }
    }
  }

  handleDataUpdated() {
    return { refreshKey: uuidv4() }
  }

  handleRowAdded() {
    return { refreshKey: uuidv4() }
  }

  multiRowsSet() {
    return { refreshKey: uuidv4() }
  }

  getActualFilters() {
    const columnConfigs = this.getColumnConfigs()
    const fieldConfigs = this.getFieldConfigs()
    let filters = this.store.getFilters()
    let conditions = []
    let fieldConfig
    _.forEach(filters.items, (filter) => {
      const property = filter.getProperty()
      const columnConfig = _.find(columnConfigs, {
        fieldName: property
      })
      if (columnConfig) {
        fieldConfig = _.find(fieldConfigs, {
          fieldName: columnConfig.fieldName
        })
      }
      if (
        filter.config.operator === 'in' &&
        !_.isNil(filter._filterValue) &&
        !_.isEqual(filter.getValue(), filter._filterValue)
      ) {
        filter.setValue(filter._filterValue)
      }
      const filterValue = filter.getValue()
      const actualFilter = {
        fieldName: filter.getProperty(),
        condition: {
          [filter.getOperator()]:
            fieldConfig && fieldConfig.dataType === 'datetime'
              ? filterValue instanceof Array
                ? _.map(filterValue, (item) => {
                    return this.getDateFormat(item)
                  })
                : this.getDateFormat(filterValue)
              : filterValue
        }
      }
      conditions.push(actualFilter)
    })

    return conditions
  }

  getDateFormat(value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
  }

  handleTagFilterChanged(dataIndex, newValue) {
    const foundTagFilter = _.find(this.tagFilters, (tagFilter) => {
      return tagFilter.field === dataIndex
    })
    if (foundTagFilter) {
      if (newValue.length) {
        foundTagFilter.value = newValue
      } else {
        _.remove(this.tagFilters, foundTagFilter)
      }
    } else {
      const field = _.find(this.getFieldConfigs(), {
        fieldName: dataIndex
      })
      const { dataType = '' } = field
      this.tagFilters.push({
        field: dataIndex,
        operator: 'in',
        tag: 'like',
        value: newValue,
        dataType
      })
    }

    this.handleFilterChanged()
  }

  expandAndCollapse() {
    const {
      props: {
        settings: {
          config: {
            grid: { lockable = false, grouping = false, groupingSummary = false } = {}
          } = {}
        } = {}
      } = {}
    } = this

    if (lockable && grouping && groupingSummary) {
      if (this.extjsGrid.cmp.lockedGrid && this.extjsGrid.cmp.lockedGrid.features) {
        const lockGridFeatures = _.find(this.extjsGrid.cmp.lockedGrid.features, {
          ftype: 'groupingsummary'
        })
        if (lockGridFeatures && lockGridFeatures.isAllCollapsed()) {
          lockGridFeatures.expandAll()
          lockGridFeatures.collapseAll()
        }
      }
    }
  }

  handleFilterActivate = () => {
    this.handleColumnChanged()

    if (this.extjsGrid && this.extjsGrid.cmp) {
      this.expandAndCollapse()
    }
  }

  handleFilterDeactivate = () => {
    this.handleColumnChanged()

    if (this.extjsGrid && this.extjsGrid.cmp) {
      this.expandAndCollapse()
    }
  }

  handleFilterChanged() {
    // Save plugin state
    this.handleColumnChanged()

    this.setDataDetails()

    if (this.pagingEnabled(this.props)) {
      const sorters = this.getSortingInfo(this.props)
      this.pageGridState.currentPage = 1
      this.selectedRow = null
      this.selectedRows = null
      this.loadPageData(1, sorters)
      this.handleResetChanges()
    }

    if (this.extjsGrid && this.extjsGrid.cmp) {
      this.expandAndCollapse()
    }
  }

  handleColumnChanged(unsortedColName) {
    // Prevent the the grid to try save plugin state during grid is build
    if (!this.props.pluginStates.isSuccess || this.componentUpdating) {
      return
    }
    if (this.extjsGrid) {
      const groupingColumn = this.extjsGrid.cmp.store.getGroupField()

      const columns = this.extjsGrid.cmp.getColumns()

      const state = this.extjsGrid.cmp.getState()
      let sorters
      if (state.storeState && state.storeState.sorters) {
        sorters = this.extjsGrid.cmp.getState().storeState.sorters
      }
      if (!_.isNil(unsortedColName) && _.isString(unsortedColName)) {
        _.remove(sorters, { id: unsortedColName })
      }
      const filters = this.getActualFilters()
      if (columns) {
        this.saveColumnState(columns, groupingColumn, sorters, filters, state)
      }
    }
  }

  lockedGridResize(pluginState, width) {
    if (!this.props.pluginStates.isSuccess || this.componentUpdating) {
      return
    }
    this.pluginState = { ...pluginState, lockGridWidth: width }
    this.savePluginState(this.pluginState)
  }

  handleSortChange(ct, column, direction) {
    if (this.extjsGrid && this.extjsGrid.cmp) {
      const groupingColumn = this.extjsGrid.cmp.store.getGroupField()
      if (groupingColumn) {
        if (column && column.dataIndex === groupingColumn) {
          this.store.group(groupingColumn, direction)
        } else {
          this.store.group(groupingColumn)
        }
      }
    }

    if (this.pagingEnabled(this.props) && !this.componentUpdating) {
      this.handlePagingSortingChange()
    }

    // Save plugin state
    this.handleColumnChanged()
  }

  handleUnlockColumn() {
    if (this.extjsGrid && this.extjsGrid.cmp) {
      // WorkAround:
      // Problem: User unlocks a column, sparkline column go blank
      // Solution: Refresh the grid view after unlocking
      this.extjsGrid.cmp.view.refresh()
    }
    // Save plugin state
    this.handleColumnChanged()
  }

  handlePagingSortingChange() {
    if (this.extjsGrid) {
      // get sorted columns info
      const gridState = this.extjsGrid.cmp.getState()
      const { storeState: { sorters: sortedColumns = [] } = {} } = gridState || {}

      const sorters = _.transform(
        sortedColumns,
        (result, sortedColumn) => {
          if (sortedColumn.property !== '__SLVYRowIndex') {
            result.push({ [sortedColumn.property]: sortedColumn.direction })
          }
        },
        []
      )

      // Check if sorted columns really changed
      if (!_.isEqual(this.pageGridState.sortedColumns, sorters)) {
        this.pageGridState.sortedColumns = sorters
        this.selectedRow = null
        this.selectedRows = null
        // trigger loading of data
        this.loadPageData(this.store.currentPage, sorters, true)
        this.handleResetChanges()
      }
    }
  }

  handleCollapse() {
    if (this.props.onCollapse) {
      this.props.onCollapse()
    }
  }

  handleExpand() {
    if (this.props.onExpand) {
      this.props.onExpand()
    }
  }

  handleResetChanges() {
    if (!this.extjsGrid) {
      // If the grid is not created yet, ignore the call
      return
    }

    const {
      props: { pluginData = [] }
    } = this

    if (this.store && this.store.getModifiedRecords().length > 0) {
      this.store.loadData(this.getData(pluginData))
      this.setDataDetails()
    }

    this.editList = []
    this.addList = []
    this.deleteList = []

    this.dirtyStateChanged(false)
  }

  validateNewRows() {
    // Validate added rows
    const columnConfigs = this.getColumnConfigs()

    const validationResult = {
      rowIndex: null,
      columnIndex: null
    }

    const gridColumns = this.extjsGrid.cmp.getColumns()

    _.forEach(this.addList, (row) => {
      _.forEach(gridColumns, (gridColumn) => {
        const key = gridColumn.dataIndex
        const value = row.columnValues[key]

        const columnConfig = _.find(columnConfigs, { fieldName: key })
        const {
          adding: { enabled: addingEnabled = false } = {},
          editing: { allowBlank = false } = {}
        } = columnConfig || {}

        // Column is mandatory and its value is empty
        if (
          addingEnabled &&
          !allowBlank &&
          (value === '' || value === null || value === undefined)
        ) {
          const allRows = this.store.getData()
          const rowIndex = _.findIndex(allRows.items, {
            id: row.columnValues.id
          })

          // Get the column indexfrom the actual grid
          const columnIndex = _.findIndex(gridColumns, {
            dataIndex: key
          })

          // Save the errorenous cell
          validationResult.rowIndex = rowIndex
          validationResult.columnIndex = columnIndex

          // Break at the first errorenous cell
          return false
        }
      })

      if (validationResult.rowIndex != null && validationResult.columnIndex != null) {
        // Break at the first errorenous row
        return false
      }
    })

    // Display validation error and return
    if (validationResult.rowIndex != null && validationResult.columnIndex != null) {
      const columnHeader = gridColumns[validationResult.columnIndex].text

      slvyToast.warning({ message: `${columnHeader} is required. ` })

      this.extjsGrid.cmp.findPlugin('cellediting').startEditByPosition({
        row: validationResult.rowIndex,
        column: validationResult.columnIndex
      })
      // Validation failed
      return false
    }
    // Validation succeeded
    return true
  }

  handleTriggerSaveChanges() {
    this.handleSaveChanges()
  }

  handleSaveChanges(updateParameters) {
    if (!this.extjsGrid) {
      // If the grid is not created yet, ignore the call
      return
    }
    const newRowsValidation = this.validateNewRows()
    if (!newRowsValidation) {
      return
    }
    // Handle deleted rows
    if (_.size(this.deleteList) > 0) {
      const records = _.map(this.deleteList, (deleteListItem) => {
        return deleteListItem.data
      })

      const deleteData = {
        type: 2, // 2 for deleting
        records: records
      }

      this.updateRow({
        reloadGridData: true,
        updateData: deleteData,
        updateParameters
      })
    }
    // Handle added rows
    if (_.size(this.addList) > 0) {
      const records = _.map(this.addList, (addListItem) => {
        return addListItem.columnValues
      })

      const addData = {
        type: 1, // 1 for inserting
        records: records
      }

      this.updateRow({
        reloadGridData: true,
        updateData: addData,
        updateParameters
      })
    }
    // Handle edited rows
    const isUpdateQuery = this.props?.settings?.query?.dataEditing?.isUpdateQuery
    if (isUpdateQuery) {
      const modifiedRecords = this.getModifiedDirtyRecords()
      if (modifiedRecords.length > 0) {
        let updateData = {
          records: modifiedRecords,
          type: 0
        }
        this.updateRow({ updateData, reloadGridData: true, updateParameters })
      }
    } else if (_.size(this.editList) > 0) {
      let updateData = this.prepareUpdateData(this.editList)
      if (_.size(updateData.updateItems) > 0) {
        this.updateValue(updateData, true, updateParameters, false)
      }
    }
  }

  handleSetEditableState(params) {
    const { notEditable = null, editable = null } = params
    if (notEditable !== null || editable !== null) {
      let isReadonly = false
      if (notEditable !== null) {
        isReadonly = notEditable
      }
      if (editable !== null) {
        isReadonly = !editable
      }
      let columns

      if (this.extjsGrid?.cmp) {
        columns = this.extjsGrid.cmp.getVisibleColumns()
      }
      const deleteColumn = _.find(columns, { reference: 'deleteColumn' })

      if (deleteColumn) {
        if (isReadonly) {
          deleteColumn.disableAction(0)
        } else {
          deleteColumn.enableAction(0)
        }
      }
      this.viewModel.set('editingDisabled', isReadonly)
    }
  }

  handleSetEnabledState(params) {
    const { enabled = null, notEnabled = null } = params
    if (notEnabled !== null || enabled !== null) {
      let isEnabled = false
      if (enabled !== null) {
        isEnabled = enabled
      }
      if (notEnabled !== null) {
        isEnabled = !notEnabled
      }

      this.viewModel.set('gridEnabled', isEnabled)
    }
  }

  handleSetRowValues(params) {
    const { [__RowIndex]: changedRecordRowIndex } = params || {}
    const that = this
    this.store.each(function (record) {
      const rowIndex = record.get(__RowIndex)
      if (rowIndex === changedRecordRowIndex) {
        _.forEach(params, (value, key) => {
          if (key !== __RowIndex && _.has(record.data, key)) {
            if (record.get(key) !== value) {
              const { isLookupQuery } = that.getFieldLookupConfig(key)
              if (isLookupQuery) {
                const columnLookupData = that.getFieldLookupData(key, record)
                if (_.isNil(columnLookupData)) {
                  that.loadLookUpdata(key, record.data)
                }
              }

              that.cellEditingHappened = true
              record.set(key, value)
            }
          }
        })
        return false
      }
    })
  }

  handleSetMultiSelectRowValues(params) {
    const setParams = { ...params }
    if (this.extjsGrid && this.extjsGrid.cmp) {
      const selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()
      _.forEach(selectedRows, (record) => {
        _.forEach(setParams, (value, key) => {
          if (_.has(record.data, key)) {
            if (record.get(key) !== value) {
              record.set(key, value)
            }
          }
        })
      })
    }

    this.multiRowsSet()
  }

  handleSetRowValuesWithFilter(params) {
    const filterParams = _.transform(
      params,
      (res, value, key) => {
        if (_.startsWith(key, 'Filter_')) {
          res[key.substring(7, _.size(key))] = value
        }
      },
      {}
    )

    const setParams = _.transform(
      params,
      (res, value, key) => {
        if (_.startsWith(key, 'Set_')) {
          res[key.substring(4, _.size(key))] = value
        }
      },
      {}
    )
    this.store.each(function (record) {
      const rowMatch = _.reduce(
        filterParams,
        (match, value, key) => {
          if (record.get(key) !== value) {
            match = match - 1
          }
          return match
        },
        1
      )

      if (rowMatch) {
        _.forEach(setParams, (value, key) => {
          if (_.has(record.data, key)) {
            if (record.get(key) !== value) {
              record.set(key, value)
            }
          }
        })
      }
    })
  }

  handleFilterClientData(params) {
    this.store.clearFilter()
    const { clearFilter = 0 } = params
    if (!clearFilter) {
      _.forEach(params, (value, key) => {
        if (key !== 'clearFilter') {
          this.store.filter(key, value)
        }
      })
    }
  }

  handleAddRecord() {
    const extjsGrid = this.extjsGrid && this.extjsGrid.cmp
    if (!extjsGrid) {
      // If the grid is not created yet, ignore the call
      return
    }

    const {
      settings: {
        config: { grid: { adding: { addingType = 'Direct' } = {}, grouping = false } = {} } = {}
      } = {}
    } = this.props

    // If adding type is Trigger, fire an event and return
    if (addingType === 'Trigger') {
      this.handleRowAdded()
      return
    }
    const fieldConfigs = this.getFieldConfigs()

    const columnConfigs = this.getColumnConfigs()

    const defaultValues = this.getColumnDefaultValues()

    const pagingEnabled = this.pagingEnabled(this.props)

    // Create an empty record
    const newRecord = _.transform(
      fieldConfigs,
      (result, fieldConfig) => {
        if (_.has(defaultValues, fieldConfig.fieldName)) {
          // Get the default value of the column if it has one
          result[fieldConfig.fieldName] = defaultValues[fieldConfig.fieldName]
        } else {
          result[fieldConfig.fieldName] = ''
        }
      },
      {}
    )

    const rows = this.store.getData()

    // Insert the empty record at the end of the store
    const rowCount = _.size((this.store.getData().getSource() || this.store.getData()).getRange())

    this.store.insert(rowCount, newRecord)
    if (grouping) {
      this.store.loadData(rows.items)
    }

    if (!pagingEnabled) {
      setTimeout(() => this.setDataDetails(), 200)
    }

    // Due to sorting in the grid new added record is not always at the end of grid
    // Find the actual position of the new record
    const newRecordIndex = _.findIndex(rows.items, { id: newRecord.id })
    // Find the first editable column, we will focus on it
    const firstEditableColumnIndex = _.findIndex(this.extjsGrid.cmp.getColumns(), (column) => {
      const columnConfig = _.find(columnConfigs, {
        fieldName: column.dataIndex
      })
      const { adding: { enabled: addingEnabled = false } = {} } = columnConfig || {}
      return addingEnabled
    })

    if (firstEditableColumnIndex < 0) {
      console.error('User added a new row but there is no editable column')
    }
    // Activate the editor on the new row
    if (newRecordIndex >= 0 && firstEditableColumnIndex >= 0) {
      this.extjsGrid.cmp.findPlugin('cellediting').startEditByPosition({
        row: newRecordIndex,
        column: firstEditableColumnIndex
      })
    }
  }

  handleDeleteRows(grid, rowIndex, colIndex, item, e, record) {
    const {
      grid: {
        deleting: {
          confirmation = false,
          confirmationMessage = 'Are you sure you want to delete this row?'
        } = {}
      } = {}
    } = this.props.settings.config

    if (confirmation) {
      Ext.MessageBox.addCls('z-index-top')
      Ext.MessageBox.confirm('Confirmation', confirmationMessage, (btn) => {
        if (btn === 'yes') {
          this.handleDeleteRowsConfirmed(record)
        }
      })
    } else {
      this.handleDeleteRowsConfirmed(record)
    }
  }

  handleDeleteRowsConfirmed(record) {
    const { grid: { editing: { editingType = 'SaveButton' } = {}, grouping = false } = {} } =
      this.props.settings.config

    const delaySaving = editingType === 'SaveButton' || editingType === 'Trigger'
    const queueSaving = editingType === 'Queue'
    const pagingEnabled = this.pagingEnabled(this.props)
    const gridEditingEnabled = this.isGridEditable()

    const { data = {}, previousValues = {} } = record

    const addListItem = _.find(this.addList, (addListItem) => {
      return addListItem.columnValues.id === record.id
    })

    this.store.remove(record)
    if (grouping) {
      const rows = this.store.getDataSource()
      this.store.loadData(rows.items)
    }

    if (addListItem) {
      _.remove(this.addList, (item) => {
        return item.columnValues.id === addListItem.columnValues.id
      })
    } else {
      const columnValues = _.cloneDeep(data)

      // There might be changed values in grid
      // We will ignore the changes, we want to delete the row with its original data
      _.forEach(previousValues, (value, key) => {
        columnValues[key] = value
      })
      if (gridEditingEnabled && delaySaving) {
        // Save button is enabled

        if (!this.isGridDirty()) {
          this.dirtyStateChanged(true)
        }
        this.deleteList.push(record)
      } else {
        // Instant deleting
        if (_.has(data, __RowIndex)) {
          const rowIndex = data[__RowIndex]

          const editData = {
            type: 2, // 2 for deleting
            records: [columnValues]
          }

          if (pagingEnabled) {
            // If paging is enabled we have to retrieve the data
            // Since the page content may have changed
            // this.deleteRow(editData, true)
            this.updateRow({ updateData: editData, reloadGridData: true, queueSaving })
          } else {
            this.setDataDetails()
            // this.deleteRow(editData, false, rowIndex)
            this.updateRow({
              updateData: editData,
              reloadGridData: false,
              rowIndex,
              queueSaving
            })
          }
        }
      }
    }
  }

  handleHeaderMenuCreate(grid, menu) {
    const { settings: { config: gridConfig = {} } = {} } = this.props
    const { grid: { filtering = null, listFilterCheckbox = false, paging = false } = {} } =
      gridConfig
    menu.on({
      click: function (menu, item) {
        const hideMenuAfterClick =
          filtering &&
          listFilterCheckbox &&
          item &&
          item.config &&
          item.config.itemId === 'filters' &&
          menu.activeHeader.filter.type === 'list'
        if (hideMenuAfterClick) {
          Ext.menu.Manager.hideAll()
        }
      },
      beforeshow: function (menu) {
        // Remove clearSorting menu item if it exists
        // We do this because the buildin "columns" menu item also registers at the second position
        const clearSortingMenuItem = _.find(menu.items.items, (item) => {
          return item.config.itemId === 'clearSorting'
        })
        if (clearSortingMenuItem) {
          menu.remove(clearSortingMenuItem)
        }

        const clearFilteringMenuItem = _.find(menu.items.items, (item) => {
          return item.config.itemId === 'clearFilters'
        })
        if (clearFilteringMenuItem) {
          menu.remove(clearFilteringMenuItem)
        }

        const clearSettingsMenuItem = _.find(menu.items.items, (item) => {
          return item.config.itemId === 'settings'
        })
        if (clearSettingsMenuItem) {
          menu.remove(clearSettingsMenuItem)
        }

        // We have to clear the menu items we add
        const massUpdateMenuItem = _.find(menu.items.items, (item) => {
          return item.config.itemId === 'massUpdate'
        })
        if (massUpdateMenuItem) {
          menu.remove(massUpdateMenuItem)
        }

        const massUpdateMenuseparatorMenuItem = _.find(menu.items.items, (item) => {
          return item.config.itemId === 'massUpdateMenuseparator'
        })
        if (massUpdateMenuseparatorMenuItem) {
          menu.remove(massUpdateMenuseparatorMenuItem)
        }

        const tagFilterItem = _.find(menu.items.items, (item) => {
          return item.config.itemId === 'tagfilter'
        })
        if (tagFilterItem) {
          menu.remove(tagFilterItem)
        }

        const columnTagFilter = _.find(this.tagFilters, (i) => {
          return i.field === menu.activeHeader.dataIndex
        })

        if (this.isMassUpdateEnabled(menu.activeHeader.dataIndex)) {
          const columnConfigs = this.getColumnConfigs()
          const columnConfig = _.find(columnConfigs, { fieldName: menu.activeHeader.dataIndex })
          const { editing: { lookupDataField = null } = {} } = columnConfig || {}

          const field = _.find(this.getFieldConfigs(), {
            fieldName: menu.activeHeader.dataIndex
          })
          const { dataType = '' } = field
          const that = this
          const { isLookupQuery } = that.getFieldLookupConfig(menu.activeHeader.dataIndex)
          const isLookup = isLookupQuery && true

          menu.insert(0, {
            itemId: 'massUpdate',
            text: 'Mass Update',
            iconCls: 'fa fa-pencil-square-o',
            handler: this.handleMassUpdate(
              menu.activeHeader.dataIndex,
              dataType,
              isLookup,
              lookupDataField
            )
          })

          menu.insert(1, {
            itemId: 'massUpdateMenuseparator',
            xtype: 'menuseparator'
          })

          // If no rows is selected, mass update is disabled
          const selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()
          if (_.size(selectedRows) > 0) {
            menu.items.items[0].enable()
          } else {
            menu.items.items[0].disable()
          }
        }

        // Insert the clearSorting menu item at the second index
        menu.insert(2, {
          itemId: 'clearSorting',
          text: 'Clear Sorting',
          iconCls: 'fa fa-minus',
          handler: function (item) {
            // Remove this column from the sort info
            const unsortedColName = item.ownerCt.activeHeader.dataIndex
            this.handleColumnChanged(unsortedColName)
            this.store.sorters.remove(item.ownerCt.activeHeader.dataIndex)
            const groupingColumn = this.extjsGrid.cmp.store.getGroupField()
            if (groupingColumn === item.ownerCt.activeHeader.dataIndex) {
              this.store.group(groupingColumn, 'ASC')
            }
            if (_.size(this.store.sorters) === 0) {
              // When last sorter is removed, the grid is not refreshed
              // Therefore add __SLVYRowIndex as a sorter
              this.store.setSorters([
                {
                  property: __RowIndex,
                  direction: 'ASC'
                }
              ])
            }
          }.bind(this)
        })

        // If the column is sorted its clearSorting menu item is enabled
        const sortedColumn = _.find(grid.store.sorters.items, (item) => {
          return item.config.property === menu.activeHeader.dataIndex
        })

        if (!sortedColumn) {
          menu.items.items[2].disable()
        } else {
          menu.items.items[2].enable()
        }

        if (filtering) {
          menu.insert(3, {
            itemId: 'clearFilters',
            text: 'Clear Filters',
            iconCls: 'fa fa-minus',
            handler: function (item) {
              const {
                ownerCt: {
                  activeHeader: { filter: { column: { filter: activeFilter = null } = {} } } = {}
                } = {}
              } = item
              if (activeFilter) {
                activeFilter.setActive(false)
              }

              if (!_.isNil(columnTagFilter)) {
                _.remove(this.tagFilters, columnTagFilter)
              }

              this.handleFilterChanged()
              if (_.size(this.store.filters) === 0) {
                this.store.setFilters([
                  {
                    property: item.ownerCt.activeHeader.dataIndex,
                    value: []
                  }
                ])
              }
              menu.ownerCmp.removeCls('x-grid-tag-filters-filtered-column')
            }.bind(this)
          })

          // If the column is filtered its clearFilters menu item is enabled
          const filteredColumn = _.find(grid.store.filters.items, (item) => {
            return item.config.property === menu.activeHeader.dataIndex
          })

          if (filteredColumn || !_.isNil(columnTagFilter)) {
            menu.items.items[3].enable()
          } else {
            menu.items.items[3].disable()
          }
        }

        menu.insert(4, {
          iconCls: 'fa fa-cog',
          itemId: 'settings',
          text: 'Settings',
          menu: {
            plain: true,
            xtype: 'menu',
            items: [
              {
                iconCls: 'fa fa-minus',
                itemId: 'clearAllSorting',
                text: 'Clear All Sorting',
                handler: () => {
                  this.handleClearAllSorters()
                }
              },
              {
                iconCls: 'fa fa-minus',
                itemId: 'clearAllFilters',
                text: 'Clear All Filters',
                handler: () => {
                  this.handleClearAllFilters()
                }
              }
            ]
          }
        })

        //* **Add enable-disable option to buttons */
        // // If the column is filtered its clearAllSorting menu item is enabled
        const allSortedColumn = _.find(grid.store.sorters.items, (item) => {
          if (item.config.property === __RowIndex) {
            return false
          }
          return true
        })

        this.settingsEnable = false

        if (!allSortedColumn) {
          menu.items.items[4].menu.items.items[0].disable()
        } else {
          menu.items.items[4].menu.items.items[0].enable()
          this.settingsEnable = true
        }

        if (filtering) {
          // // If the column is filtered its clearAllFilters menu item is enabled
          if (grid.store.filters.items.length || this.tagFilters.length) {
            menu.items.items[4].menu.items.items[1].enable()
            this.settingsEnable = true
          } else {
            menu.items.items[4].menu.items.items[1].disable()
          }
        }

        if (this.settingsEnable) {
          menu.items.items[4].enable()
        } else {
          menu.items.items[4].disable()
        }

        if (filtering && paging) {
          const tagFilterObj = _.find(this.tagFilters, (item) => {
            return item.field === menu.activeHeader.dataIndex
          })
          const columnConfigs = this.getColumnConfigs()
          const columnConfig = _.find(columnConfigs, { fieldName: menu.activeHeader.dataIndex })
          const { filtering: { tagFilter = false } = {} } = columnConfig || {}
          menu.insert(menu.items.items.length, {
            iconCls: 'fa fa-search',
            itemId: 'tagfilter',
            text: 'Tag Filter',
            disabled: !tagFilter,
            menu: {
              plain: true,
              xtype: 'menu',
              cls: ' tag-field-filter-box',
              items: [
                {
                  xtype: 'tagfield',
                  itemId: 'tagFilterItem',
                  filterPickList: false,
                  hideTrigger: true,
                  createNewOnEnter: true,
                  anyMatch: true,
                  value: (tagFilterObj && tagFilterObj.value) || [],
                  enableKeyEvents: false,
                  store: Ext.create('Ext.data.Store', {
                    data: []
                  }),
                  listeners: {
                    change: (e, newValue) => {
                      this.handleTagFilterChanged(menu.activeHeader.dataIndex, newValue)
                      if (newValue.length) {
                        menu.ownerCmp.addCls('x-grid-tag-filters-filtered-column')
                      } else {
                        menu.ownerCmp.removeCls('x-grid-tag-filters-filtered-column')
                      }
                    }
                  }
                }
              ]
            }
          })
        }

        menu.addCls('z-index-top')
      }.bind(this)
    })
  }

  formatDatesForUpdate(record, fields) {
    return _.transform(
      record,
      (result, value, key) => {
        const dateField = _.find(fields, (field) => {
          return field.fieldName === key && field.dataType === 'datetime'
        })
        if (dateField) {
          result[key] = this.getDateFormat(value)
        } else {
          result[key] = value
        }
      },
      {}
    )
  }

  prepareUpdateData(updateDataList) {
    const fieldConfigs = this.getFieldConfigs()

    // If the same cell is edited multiple times
    // send only the last value to the server
    const groups = _.groupBy(updateDataList, (updateItem) => {
      const { changedColumn, columnValues: { __SLVYRowIndex } = {} } = updateItem
      return changedColumn + '-' + __SLVYRowIndex
    })
    let reducedUpdates = _.map(groups, (group) => {
      return { ..._.last(group), oldValue: _.first(group).oldValue }
    })

    // If the same cell is edited multiple times
    // and the cell retains its original value
    // ignore the cell
    reducedUpdates = _.filter(reducedUpdates, (updateItem) => {
      const { changedColumn, columnValues = {}, oldValue } = updateItem
      if (_.has(columnValues, changedColumn)) {
        return columnValues[changedColumn] !== oldValue
      }
    })

    const updateData = {
      updateItems: []
    }
    const actualData = this.store.getData()
    const { items: rows = [] } = actualData || {}
    _.forEach(reducedUpdates, (updateItem) => {
      const row = _.find(rows, (item) => {
        return item.get('__SLVYRowIndex') === updateItem.columnValues['__SLVYRowIndex']
      })

      // Copy the actual grid data to all update rows.
      // This is necessary since we want to feed the update statements of columns with
      // actual data
      if (row) {
        const updateRecord = this.formatDatesForUpdate(row.data, fieldConfigs)

        updateData.updateItems.push({
          columnName: updateItem.changedColumn,
          config: { ...updateRecord },
          oldValue: updateItem.oldValue
        })
      }
    })

    return updateData
  }

  createSorters(columnConfigs, loadedColumnStates) {
    const sortedPluginStates = _.filter(loadedColumnStates, 'sortState')
    const sortedPluginStatesSorted = _.sortBy(sortedPluginStates, 'sortIndex')
    return _.transform(
      sortedPluginStatesSorted,
      (result, columnState) => {
        const columnConfig = _.nth(columnConfigs, columnState.configIndex)
        if (
          columnConfig &&
          columnConfig.header === columnState.name &&
          columnConfig.fieldName !== '__SLVYRowIndex'
        ) {
          result.push({
            property: columnConfig.fieldName,
            direction: columnState.sortState
          })
        }
        return result
      },
      []
    )
  }

  handleBeforeCheckChange(checkColumn, rowIndex, checked, record) {
    const { dataIndex: field } = checkColumn
    const { data: rowData } = record
    // Convert falsy values to boolean, since extjs work only with boolean
    return !!this.isCellEditable(field, rowData)
  }

  handleBeforeEdit(editor, context) {
    const {
      field,
      record: { data: rowData }
    } = context

    // Convert falsy values to boolean, since extjs work only with boolean
    return !!this.isCellEditable(field, rowData)
  }

  isCellEditable(field, rowData) {
    // Get grid editibility
    const gridEditingEnabled = this.isGridEditable()
    const gridAddingEnabled = this.isGridAddable()

    // Get column editibility
    const columnConfigs = this.getColumnConfigs() || []
    const column = _.find(columnConfigs, { fieldName: field }) || {}
    const {
      editing: { enabled: editingEnabled, editableCondition: conditionField = null } = {},
      adding: { enabled: addingEnabled = false } = {}
    } = column || {}

    // If adding is enabled and the update is made in a new row, allow it.
    const newRow = _.find(this.addList, (newRow) => {
      return newRow.columnValues.id === rowData.id
    })

    const editingInNewRow = newRow !== undefined

    if (editingInNewRow) {
      return gridAddingEnabled && addingEnabled
    } else if (gridEditingEnabled && editingEnabled) {
      // Cell is editable when there is no conditionField or conditionField is true
      return !conditionField || (conditionField && rowData[conditionField])
    }

    // In all other cases, do not allow it
    return false
  }

  createGridFeatures(gridConfig) {
    const {
      grid: {
        summary = null,
        grouping = false,
        groupingSummary = false,
        initialGroupingCollapse = false
      } = {}
    } = gridConfig || {}

    const gridFeatures = []

    const summaryVisible = summary === 'top' || summary === 'bottom'

    if (grouping) {
      if (groupingSummary) {
        gridFeatures.push({
          ftype: 'groupingsummary',
          startCollapsed: initialGroupingCollapse
        })
      } else {
        gridFeatures.push({
          ftype: 'grouping',
          startCollapsed: initialGroupingCollapse
        })
      }
    }
    if (summaryVisible) {
      //@TODO: summaryRenderer error
      gridFeatures.push({ ftype: 'summary', dock: summary })
    }

    return gridFeatures
  }

  createGridPlugins(gridConfig) {
    const {
      grid: {
        editing: { clicksToEdit: gridEditingClicksToEdit } = {},
        filtering = null,
        exportable = false,
        selection: { type: selectionType = '' } = {}
      } = {}
    } = gridConfig

    const gridEditingEnabled = this.isGridEditable()

    const gridPlugins = []

    // Filters plugin
    if (filtering) {
      gridPlugins.push('gridfilters')
    }

    // Editing plugin
    if (gridEditingEnabled) {
      const isUpdateQuery = this.props?.settings?.query?.dataEditing?.isUpdateQuery

      gridPlugins.push({
        clicksToEdit: gridEditingClicksToEdit,
        ptype: 'cellediting',
        listeners: {
          beforeedit: this.handleBeforeEdit,
          ...(isUpdateQuery
            ? {
                edit: () => this.dirtyStateChanged(this.getModifiedDirtyRecords().length > 0)
              }
            : {})
        }
      })
    }

    // Export plugin
    if (exportable) {
      gridPlugins.push({
        id: 'gridexport',
        ptype: 'gridexporter'
      })
    }

    // Spreadsheet selection plugin
    if (selectionType === 'Spreadsheet') {
      gridPlugins.push(Ext.create('Ext.grid.plugin.Clipboard', true))
    }

    return gridPlugins
  }

  createHeaderToolbar(exportEnabled) {
    const header = {
      itemPosition: 1,
      items: [],
      uiCls: ['pivotHeader']
    }

    if (exportEnabled) {
      header.items.push({
        handler: this.exportToXlsx,
        iconCls: 'fa fa-download',
        text: 'Export',
        xtype: 'button'
      })
    }

    return _.size(header.items) > 0 ? header : null
  }

  exportToXlsx() {
    const {
      settings: {
        query: { formattedFields = [] } = {},
        config: { general: { name = '' } = {} } = {}
      } = {}
    } = this.props

    const fieldConfigs = this.getFieldConfigs()

    // Add aggregate number formats for export
    // Normally we would add export formats in the configuration
    // But extjs loses this data, so we add it everytime we run export

    _.forEach(this.extjsGrid.cmp.getColumns(), (column) => {
      const formattedField = _.find(formattedFields, {
        columnName: column.dataIndex
      })

      if (formattedField && formattedField.formatString) {
        const fieldConfig = _.find(fieldConfigs, {
          fieldName: column.dataIndex
        })
        const { dataType = '' } = fieldConfig || {}
        const convertedFormat = this.converteExcelFormat(formattedField.formatString, dataType)
        if (!_.isNil(convertedFormat)) {
          column.exportStyle = {
            format: convertedFormat
          }
        }
      }
    })

    const date = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')

    this.extjsGrid.cmp
      .saveDocumentAs({
        type: 'excel07',
        title: 'Export',
        fileName: name + ' ' + date + '.xlsx'
      })
      .then(null, this.onError)
  }

  converteExcelFormat(format, dataType) {
    // Excel number formats are quite different than dashboard number format
    // This function basically converts the most common used dashboard formats to excel formats

    let excelFormat = format
    excelFormat = _.trim(excelFormat, ' ')
    excelFormat = _.trimStart(excelFormat, 'Number')
    excelFormat = _.trimStart(excelFormat, 'Percent')
    excelFormat = _.trim(excelFormat, ' ')
    excelFormat = _.trim(excelFormat, '$')
    excelFormat = _.trim(excelFormat, '€')
    excelFormat = _.trim(excelFormat, '₺')
    excelFormat = _.trim(excelFormat, '%')

    if (dataType === 'datetime') {
      excelFormat = null
    } else {
      if (_.has(this.excelFormatConversion, excelFormat)) {
        excelFormat = this.excelFormatConversion[excelFormat]
      } else {
        excelFormat = '#,##0.00'
      }
      const containsPercentage = _.indexOf(format, '%') > -1 || _.indexOf(format, 'Percent') > -1
      if (containsPercentage) {
        excelFormat += '%'
      }
    }
    return excelFormat
  }

  createFooter(gridConfig) {
    const {
      grid: {
        editing: {
          confirmation = false,
          confirmationMessage = 'Are you sure you want to save this changes?',
          editingType = 'SaveButton',
          saveButtonSettings: {
            resetButton: resetButtonEnabled = true,
            resetButtonIcon = '',
            resetButtonText = 'Reset Button',
            resetButtonTooltip = 'Reset Button',
            saveButton: saveButtonEnabled = true,
            saveButtonIcon = 'fa fa-floppy-o',
            saveButtonText = 'Save',
            saveButtonTooltip = 'Save Changes'
          } = {}
        } = {},
        adding: {
          addButtonSettings: {
            addButtonIcon = null,
            addButtonText = null,
            addButtonTooltip = null
          } = {}
        } = {},
        dataDetails: {
          enabled: dataDetailsEnabled = false,
          text: dataDetailsText = '',
          unsavedChangesText = ''
        } = {},
        exportable = false,
        exportButtonPlacement = false,
        footerButtons = []
      } = {}
    } = gridConfig

    const saveButtonVisible = editingType === 'SaveButton'

    const gridEditingEnabled = this.isGridEditable()
    const gridAddingEnabled = this.isGridAddable()

    const footerBar = {
      items: []
    }
    if (dataDetailsEnabled) {
      footerBar.items.push({
        xtype: 'tbtext',
        bind: {
          html: dataDetailsText
        }
      })
      footerBar.items.push('->')
    }

    if (unsavedChangesText.length > 0) {
      footerBar.items.push({
        html: '',
        itemId: 'unsavedChanges',
        xtype: 'tbtext'
      })
      footerBar.items.push('->')
    }

    if (exportable && exportButtonPlacement) {
      footerBar.items.push({
        xtype: 'button',
        text: 'Export',
        iconCls: 'fa fa-download',
        handler: this.exportToXlsx
      })
    }

    if (gridEditingEnabled && saveButtonVisible) {
      if (gridAddingEnabled) {
        footerBar.items.push({
          handler: this.handleAddRecord,
          iconCls: addButtonIcon,
          text: addButtonText,
          tooltip: addButtonTooltip,
          bind: {
            disabled: '{editingDisabled}'
          }
        })
      }
      if (resetButtonEnabled) {
        footerBar.items.push({
          handler: this.handleResetChanges,
          iconCls: resetButtonIcon,
          text: resetButtonText,
          tooltip: resetButtonTooltip,
          bind: {
            disabled: '{editingDisabled}'
          }
        })
      }
      if (saveButtonEnabled) {
        footerBar.items.push({
          iconCls: saveButtonIcon,
          text: saveButtonText,
          tooltip: saveButtonTooltip,
          handler: () => {
            if (confirmation) {
              Ext.MessageBox.addCls('z-index-top')
              Ext.MessageBox.confirm('Confirmation', confirmationMessage, (btn) => {
                if (btn === 'yes') {
                  this.handleTriggerSaveChanges()
                }
              })
            } else {
              this.handleTriggerSaveChanges()
            }
          },
          bind: {
            disabled: '{editingDisabled}'
          }
        })
      }
    }
    _.forEach(footerButtons, (footerButton) => {
      const {
        buttonIcon,
        buttonText,
        buttonTooltip,
        confirmation = false,
        confirmationText = 'Are you sure to continue?',
        confirmationTitle = 'Confirmation'
      } = footerButton
      footerBar.items.push({
        text: buttonText,
        tooltip: buttonTooltip,
        iconCls: buttonIcon,
        defaults: {
          disabled: false
        },
        handler: confirmation
          ? this.openFooterButtonConfirmationPopup(
              confirmationText,
              confirmationTitle,
              this['handleFooterButtonClick_' + buttonText]
            )
          : this['handleFooterButtonClick_' + buttonText]
      })
    })
    return _.size(footerBar.items) > 0 ? footerBar : null
  }

  openFooterButtonConfirmationPopup(confirmationText, confirmationTitle, handler) {
    return () => {
      confirmAlert({
        message: confirmationText,
        title: confirmationTitle,
        buttons: [
          {
            label: 'Cancel',
            onClick: () => false
          },
          {
            label: 'OK',
            onClick: () => handler()
          }
        ]
      })
    }
  }

  getTextSelectionEnabled(gridConfig) {
    // Default value of the textSelection is true
    // enableTextSelection is added to grid afterwards so consider also existing grids in projects
    // which do not have this property
    const { grid: { selection: { textSelection = true } = {} } = {} } = gridConfig || {}
    return textSelection
  }

  getSelectionModel(gridConfig) {
    let selectionModel = false

    // Selection model
    const {
      grid: {
        selection: { type: selectionType = '' } = {},
        editing: { massUpdate = false } = {}
      } = {}
    } = gridConfig || {}

    const gridEditingEnabled = this.isGridEditable()

    if (gridEditingEnabled && massUpdate) {
      selectionModel = {
        checkOnly: false,
        type: 'checkboxmodel'
      }
    } else if (selectionType === 'Spreadsheet') {
      selectionModel = {
        columnSelect: false,
        extensible: 'y',
        pruneRemoved: false,
        type: 'spreadsheet'
      }
    } else if (selectionType === 'Multiselect') {
      selectionModel = {
        checkOnly: false,
        type: 'checkboxmodel'
      }
    }

    return selectionModel
  }

  getEditableColumns(gridConfig) {
    const {
      grid: { editing: { editableColumnIndex: editableColumnIndexColumn = null } = {} } = {}
    } = gridConfig || {}

    let editableColumnIndex
    if (this.props.pluginData && this.props.pluginData.length > 0 && editableColumnIndexColumn) {
      editableColumnIndex = this.props.pluginData[0][editableColumnIndexColumn]
    }

    const fieldConfigs = this.getFieldConfigs()

    let editableColumns
    if (editableColumnIndex && fieldConfigs.length > 0) {
      const columnIndex = _.findIndex(fieldConfigs, (field) => {
        return field.fieldName === editableColumnIndex
      })

      editableColumns = _.cloneDeep(fieldConfigs).splice(columnIndex - 1, fieldConfigs.length)

      editableColumns = _.map(editableColumns, (column) => {
        return column.fieldName
      })
    }

    return editableColumns
  }

  convertToExtJsColumnType(typeLabel) {
    const maps = {
      bool: 'boolean',
      boolean: 'boolean',
      datetime: 'date',
      decimal: 'number',
      double: 'number',
      float: 'number',
      int: 'integer',
      long: 'integer',
      short: 'integer',
      string: 'string'
    }
    return _.has(maps, typeLabel) ? maps[typeLabel] : 'string'
  }

  handlePageNumberChange(pagingToolbar, page) {
    // Check if page number really changed
    if (page !== this.pageGridState.currentPage && page !== 0) {
      this.store.currentPage = page
      this.selectedRow = null
      this.selectedRows = null
      const gridState = this.extjsGrid.cmp.getState()
      const { storeState: { sorters: sortedColumns = [] } = {} } = gridState || {}

      const sorters = _.transform(
        sortedColumns,
        (result, sortedColumn) => {
          if (sortedColumn.property !== '__SLVYRowIndex') {
            result.push({ [sortedColumn.property]: sortedColumn.direction })
          }
        },
        []
      )

      this.pageGridState.currentPage = page
      this.loadPageData(page, sorters)
      this.handleResetChanges()
    }
    // The page change is handled by the plugin itself and extjs does nothing so prevent further events by returning false
    return false
  }

  translateOperator(operator) {
    let translatedOperator = operator
    switch (operator) {
      case 'eq':
        translatedOperator = '='
        break
      case 'gt':
        translatedOperator = '>'
        break
      case 'lt':
        translatedOperator = '<'
        break
      case 'like':
        translatedOperator = 'like'
        break
      case 'notlike':
        translatedOperator = 'not like'
        break
      case '==':
        translatedOperator = '='
        break
      default:
        translatedOperator = operator
    }
    return translatedOperator
  }

  loadPageData(pageNumber, sorters, loadData = true) {
    const forceLoadData = loadData && this.filterState
    const columnConfigs = this.getColumnConfigs()
    const fieldConfigs = this.getFieldConfigs()
    const appliedFilters = this.store.getFilters()
    let filters = _.transform(
      appliedFilters.items,
      (res, filter) => {
        const property = filter.getProperty()
        const operator = filter.getOperator()
        const filterValue = filter.getValue()
        const emptyList = operator === 'in' && _.isEmpty(filter.getValue())
        const columnConfig = _.find(columnConfigs, {
          fieldName: property
        })
        if (columnConfig) {
          const fieldConfig = _.find(fieldConfigs, {
            fieldName: columnConfig.fieldName
          })
          if (fieldConfig && operator && !emptyList) {
            const value =
              fieldConfig.dataType === 'datetime'
                ? filterValue instanceof Array
                  ? _.map(filterValue, (item) => {
                      return this.getDateFormat(item)
                    })
                  : this.getDateFormat(filterValue)
                : filterValue
            res.push({
              field: property,
              operator: this.translateOperator(operator),
              value,
              dataType: fieldConfig.dataType
            })
          }
        }
      },
      []
    )
    filters = _.concat(filters, this.tagFilters)
    let _sorters = _.size(sorters) > 0 ? sorters : []
    this.props.fetchPageData({
      pageNumber,
      pluginSorters: _sorters,
      pluginFilters: _.cloneDeep(filters),
      forceLoad: forceLoadData
    })
  }

  createDataFields(fieldConfigs, columnConfigs) {
    // Create fields with data types
    // We need this for sorting of null values
    return _.map(fieldConfigs, (field) => {
      const sparkLineCol = _.find(columnConfigs, {
        fieldName: field.fieldName,
        columnType: 'sparkline'
      })
      return {
        type: this.convertToExtJsColumnType(field.dataType),
        name: field.fieldName,
        allowNull: true,
        // Create a "calculate" property for sparkline columns
        // This function converts the csv text to number array
        ...(sparkLineCol && {
          calculate: (data) => {
            return _.map(_.split(data[field.fieldName], ','), (val) => {
              return _.toNumber(val)
            })
          }
        })
      }
    })
  }

  isAutoconfigDataReady() {
    const { settings: { config: gridConfig = {} } = {}, data: { configurationhints = {} } = {} } =
      this.props

    const { grid: { automaticConfiguration = false } = {} } = gridConfig

    return !automaticConfiguration || (automaticConfiguration && !_.isEmpty(configurationhints))
  }

  createCommonStyle(pluginId) {
    const style =
      '.x-menu { z-index:99999999999 !important; } .x-datepicker {z-index: 99999999999!important;} .x-message-box {z-index: 99999999999!important;}'
    this.createStyle(style, 'common', pluginId)
  }

  createFilterColumnStyle(pluginId) {
    const style = `.x-grid-filters-filtered-column {
      background-color: rgb(255, 152, 0);
      color: white;
      font-weight: 200;
      text-decoration: none;}`
    this.createStyle(style, 'filteringColumn', pluginId)
  }

  createHeaderCheckboxStyles(gridConfig, pluginId) {
    const { columns = [] } = gridConfig || {}
    const headerCheckboxIcon = _.find(columns, (columnConfig) => {
      const {
        editing: { headerCheckbox = false } = {},
        boolean: { trueIcon = null, falseIcon = null } = {}
      } = columnConfig

      return headerCheckbox && trueIcon && falseIcon
    })

    const headerCheckbox = _.find(columns, (columnConfig) => {
      const { editing: { headerCheckbox = false } = {} } = columnConfig
      return headerCheckbox
    })

    if (headerCheckboxIcon) {
      const { boolean: { trueIcon = null, falseIcon = null } = {} } = headerCheckboxIcon

      const trueIconStyle = this.getIconStyle(trueIcon)
      const falseIconStyle = this.getIconStyle(falseIcon)

      const style = `
            .extjsGrid${pluginId} div.x-column-header-checkbox{
              border-top:1px solid #d0d0d0;
            }
            .extjsGrid${pluginId} span.x-column-header-checkbox:after{
              font: normal normal normal 18px/1 FontAwesome !important;
            }
            .extjsGrid${pluginId} span.x-column-header-checkbox:after{
              ${falseIconStyle}
            }
            .extjsGrid${pluginId} span.x-column-header-checkbox:after{
              font-size: 18px !important;
            }
            .extjsGrid${pluginId} .x-grid-hd-checker-on span.x-column-header-checkbox:after{
              font: normal normal normal 18px/1 FontAwesome !important;
            }
            .extjsGrid${pluginId} .x-grid-hd-checker-on span.x-column-header-checkbox:after{
              ${trueIconStyle}
            }
            .extjsGrid${pluginId} .x-grid-hd-checker-on span.x-column-header-checkbox:after{
              font-size: 18px !important;
            }`
      this.createStyle(style, 'headerCheckbox', pluginId)
    }

    if (headerCheckbox) {
      const style = `
            .extjsGrid${pluginId} div.x-column-header-checkbox{
              border-top:1px solid #d0d0d0;
            }`
      this.createStyle(style, 'headerCheckbox', pluginId)
    }
  }

  createStyle = function (content, section, pluginId) {
    const element = document.getElementById('extjsGrid' + section + pluginId)

    if (!element) {
      const style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = content
      style.id = 'extjsGrid' + section + pluginId

      document.getElementsByTagName('head')[0].appendChild(style)
    }
  }

  removeStyle() {
    const gridStyles = document.querySelectorAll('[id^="extjsGrid"]')
    _.forEach(gridStyles, (style) => {
      style.parentNode.removeChild(style)
    })
  }

  getIconStyle(icon) {
    const isIconSlvy = _.includes(icon, 'slvy-ui-icon')
    const iconModified = isIconSlvy
      ? `\\${slvyIconUnicodeMap[icon.replace('fa slvy-ui-icon-', '')]}`
      : fontAwesomeUnicodeMap[icon.replace('fa fa-', '')]
    return `content: "${iconModified}" !important;
    font-family: ${isIconSlvy ? 'solvoyo' : '"Font Awesome 5 Free", FontAwesome'} !important;`
  }

  getFieldConfigs() {
    const {
      data: { schema, schema: fieldConfigsSchema = [] } = {},
      settings: { query: { fields: fieldConfigsQuery = [] } = {} } = {}
    } = this.props

    if (schema) {
      return fieldConfigsSchema
    }
    return fieldConfigsQuery
  }

  setDataDetails() {
    this.viewModel.set('FilteredRecordCount', this.store.getCount())
    this.viewModel.set(
      'TotalRecordCount',
      (this.store.getData().getSource() || this.store.getData()).getRange().length
    )
  }

  isMassUpdateEnabled(fieldName) {
    const { config: { grid: { editing: { massUpdate = false } = {} } = {} } = {} } =
      this.props.settings || {}

    const fieldConfigs = this.getFieldConfigs()

    const gridEditingEnabled = this.isGridEditable()

    const columns = this.getColumnConfigs()
    const columnConfig = _.find(columns, { fieldName })

    const { editing: { enabled: columnEditable = false } = {} } = columnConfig || {}

    // Check if the field is a number type
    // Mass update is only available at number types
    const field = _.find(fieldConfigs, (field) => {
      return (
        field.fieldName === fieldName &&
        (this.isNumericType(field.dataType) ||
          field.dataType === 'string' ||
          field.dataType === 'bool' ||
          field.dataType === 'datetime' ||
          field.dataType === 'date')
      )
    })

    return gridEditingEnabled && massUpdate && columnEditable && field
  }

  getMaxMinForMassUpdate = (columnName) => {
    const columnConfigs = this.getColumnConfigs()
    const columnConfig = _.find(columnConfigs, (item) => {
      return item.fieldName === columnName
    })
    const {
      editing: {
        maxValue: staticMaxValue,
        minValue: staticMinValue,
        maxValueField,
        minValueField
      } = {}
    } = columnConfig
    if (this.extjsGrid) {
      const selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()

      if (maxValueField) {
        const maxValues = _.map(selectedRows, (selectedRow) => {
          if (
            selectedRow.get(maxValueField) &&
            !isNaN(_.toNumber(selectedRow.get(maxValueField)))
          ) {
            return _.toNumber(selectedRow.get(maxValueField))
          }
        })
        var foundMax = _.min(maxValues)
      }
      if (minValueField) {
        const minValues = _.map(selectedRows, (selectedRow) => {
          if (
            selectedRow.get(minValueField) &&
            !isNaN(_.toNumber(selectedRow.get(minValueField)))
          ) {
            return _.toNumber(selectedRow.get(minValueField))
          }
        })
        var foundMin = _.max(minValues)
      }

      const editorMaxValue = maxValueField && foundMax ? foundMax : staticMaxValue
      const editorMinValue = minValueField && foundMin ? foundMin : staticMinValue
      return { min: editorMinValue, max: editorMaxValue }
    }
  }
  checkMaxMinValidity = ({ columnName, massUpdateType, massUpdateValue, minValue, maxValue }) => {
    if (this.extjsGrid) {
      const selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()
      let maxViolate = null
      let minVioalate = null

      switch (massUpdateType) {
        case 'overwrite': {
          return !(
            (minValue && massUpdateValue < minValue) ||
            (maxValue && massUpdateValue > maxValue)
          )
        }
        case 'increment': {
          if (maxValue) {
            maxViolate = _.find(selectedRows, (selectedRow) => {
              const originalValue = selectedRow.get(columnName)

              if (originalValue + _.toNumber(massUpdateValue) > maxValue) {
                return true
              }
            })
          }
          if (minValue) {
            minVioalate = _.find(selectedRows, (selectedRow) => {
              const originalValue = selectedRow.get(columnName)

              if (originalValue + _.toNumber(massUpdateValue) < minValue) {
                return true
              }
            })
          }
          if (maxViolate || minVioalate) return false
          else return true
        }
        case 'percentage': {
          if (maxValue) {
            maxViolate = _.find(selectedRows, (selectedRow) => {
              const originalValue = selectedRow.get(columnName)

              if ((originalValue * _.toNumber(massUpdateValue)) / 100 > maxValue) {
                return true
              }
            })
          }
          if (minValue) {
            minVioalate = _.find(selectedRows, (selectedRow) => {
              const originalValue = selectedRow.get(columnName)

              if ((originalValue * _.toNumber(massUpdateValue)) / 100 < minValue) {
                return true
              }
            })
          }
          if (maxViolate || minVioalate) return false
          else return true
        }
      }
    }
  }

  createMassUpdateWindow({ columnName, dataType, isLookup, lookupDataField }) {
    const massUpdateVal = this.createMassUpdateEditor({
      field: columnName,
      dataType,
      isLookup
    })

    const numericalDataType = this.isNumericType(dataType)
    const massUpdateTitle = 'Mass Update: '
    const updaters =
      dataType === 'bool'
        ? [['bool', 'Boolean']]
        : dataType === 'datetime' || dataType === 'date'
        ? [['datetime', 'Datetime']]
        : [
            ['overwrite', 'Overwrite'],
            ['increment', 'Increment'],
            ['percentage', 'Percentage']
          ]

    const massUpdateType = {
      fieldLabel: 'Type',
      xtype: 'combo',
      name: 'massUpdateType',
      queryMode: 'local',
      valueField: 'id',
      displayField: 'text',
      editable: false,
      store: updaters,
      value: !numericalDataType ? dataType : 'overwrite',
      hidden: !numericalDataType || isLookup,
      padding: '4px 4px 0px 4px'
    }

    const massUpdateWindow = new Ext.window.Window({
      title: massUpdateTitle + columnName,
      width: 300,
      height: 175,
      layout: 'fit',
      modal: true,
      closeAction: 'hide',
      cls: 'z-index-top',
      items: [
        {
          xtype: 'form',
          padding: 5,
          border: false,
          defaults: {
            anchor: '100%'
          },
          items: [massUpdateType, massUpdateVal]
        }
      ],
      buttons: [
        {
          text: 'OK',
          handler: () => {
            const { massUpdateType, massUpdateValue } = massUpdateWindow.down('form').getValues()
            const maxValue = this.getMaxMinForMassUpdate(columnName, massUpdateType).max
            const minValue = this.getMaxMinForMassUpdate(columnName, massUpdateType).min

            const isMaxMinValid = this.checkMaxMinValidity({
              columnName,
              massUpdateType,
              massUpdateValue,
              minValue,
              maxValue
            })
            const isNumberValid = !(
              massUpdateValue === '' ||
              _.isNaN(_.toNumber(massUpdateValue)) ||
              _.isNil(massUpdateValue) ||
              !_.isNumber(_.toNumber(massUpdateValue))
            )
            const isValidValue =
              _.isNil(lookupDataField) && numericalDataType
                ? isNumberValid && isMaxMinValid
                : !(massUpdateValue === '' || _.isNil(massUpdateValue))

            if (!isValidValue) {
              alert('Please enter a valid value')
            } else {
              // TODO check types
              if (!numericalDataType) {
                this.handleMassUpdateExecute(columnName, massUpdateType, massUpdateValue)
              } else {
                this.handleMassUpdateExecute(
                  columnName,
                  massUpdateType,
                  _.toNumber(massUpdateValue)
                )
              }
              massUpdateWindow.close()
              this.dirtyStateChanged(this.getModifiedDirtyRecords().length > 0)
            }
          }
        },
        {
          text: 'Cancel',
          handler: () => {
            massUpdateWindow.close()
          }
        }
      ]
    })
    return massUpdateWindow
  }

  handleMassUpdate(columnName, dataType, isLookup, lookupDataField) {
    return () => {
      const massUpdateWin = this.createMassUpdateWindow({
        columnName,
        dataType,
        isLookup,
        lookupDataField
      })
      massUpdateWin.show()
    }
  }

  handleMassUpdateExecute(columnName, massUpdateType, massUpdateValue) {
    const columnConfigs = this.getColumnConfigs()
    const selectedColumn = _.find(columnConfigs, { fieldName: columnName })
    const formattedFields = this.getFormattedFields()
    const formattedField = _.find(formattedFields, { columnName })
    const { formatString = '' } = formattedField || {}
    const {
      editing: { warningThreshhold = 0, editableCondition: conditionField = null }
    } = selectedColumn
    const selectedRows = this.extjsGrid.cmp.getSelectionModel().getSelection()
    const selectedEditableRows = _.isEmpty(conditionField)
      ? selectedRows
      : _.filter(selectedRows, (row) => {
          return row.data && row.data[conditionField]
        })
    const selectedRowIds = _.map(selectedEditableRows, (selectedRow) => {
      return selectedRow.id
    })
    const effectedRows = []

    const decimalPrecision = this.getNoOfDecimalPlaces(formatString)

    this.solvoyoMassUpdateExecute(
      selectedEditableRows,
      columnName,
      massUpdateType,
      massUpdateValue,
      decimalPrecision,
      warningThreshhold,
      selectedColumn
    )
  }

  solvoyoMassUpdateExecute = (
    selectedEditableRows,
    columnName,
    massUpdateType,
    massUpdateValue,
    decimalPrecision,
    warningThreshhold,
    selectedColumn
  ) => {
    const storeData = _.cloneDeep(this.store.getData())
    let warningThreshholdExceeded = false
    const selectedRows = _.map(selectedEditableRows, (item) => {
      if (warningThreshhold > 0) {
        if (!warningThreshholdExceeded) {
          warningThreshholdExceeded = this.isWarningThresholdExceeded(
            selectedColumn,
            item.originalValue,
            item.newValue
          )
        } else {
          return true
        }
      }
      const originalValue = item.get(columnName)
      const newValue = this.massUpdateValueTransform(
        massUpdateType,
        massUpdateValue,
        originalValue,
        decimalPrecision
      )
      if (originalValue !== newValue) {
        item.data[columnName] = newValue
        const modifiedCell = item.dirty && item.modified && !_.isNil(item.modified[columnName])
        if (!modifiedCell) {
          item.modified = { ...item.modified, [columnName]: originalValue }
        }
        item.dirty = true
        item.previousValues = { ...item.previousValues, [columnName]: originalValue }
      }

      return item
    })

    this.warningThresholdApproved = false

    if (warningThreshholdExceeded) {
      Ext.MessageBox.confirm(
        'Threshold Exceeded',
        `The value has changed more than ${warningThreshhold} percent. Do you want to continue?`,
        (btn) => {
          if (btn === 'yes') {
            this.warningThresholdApproved = true
            this.loadEditedData(storeData, selectedRows)
          }
        }
      )
    } else {
      this.loadEditedData(storeData, selectedRows)
    }
  }

  loadEditedData(storeData, selectedRows) {
    const {
      props: {
        settings: {
          config: { grid: { editing: { editingType = 'SaveButton' } = {} } = {} } = {},
          query: { dataEditing: { isUpdateQuery } = {} } = {}
        } = {}
      } = {}
    } = this
    const delaySaving = editingType === 'SaveButton' || editingType === 'Trigger'
    const queueSaving = editingType === 'Queue'
    const modifiedRecords = this.getModifiedDirtyRecords()
    let updateData = {
      type: 0,
      records: modifiedRecords
    }
    if (storeData) {
      storeData.items = _.sortBy(_.unionBy(selectedRows, storeData.items, 'id'), 'internalId')
      this.store.loadData(storeData.items)
      if (!delaySaving) {
        if (isUpdateQuery) {
          const updateObj = { updateData, reloadGridData: true, queueSaving }
          this.updateRow(updateObj)
        } else {
          updateData = this.prepareUpdateData(this.editList)
          this.updateValue(updateData, true, {})
        }
      }
      if (this.extjsGrid) {
        this.extjsGrid.cmp.getView().refresh()
      }
    }
  }

  massUpdateValueTransform(massUpdateType, massUpdateValue, originalValue, decimalPrecision) {
    let result = massUpdateValue
    if (massUpdateType === 'bool' || massUpdateType === 'string') {
      return massUpdateValue
    } else if (massUpdateType === 'datetime') {
      return this.getDateFormat(massUpdateValue)
    } else if (massUpdateType === 'overwrite') {
      result = massUpdateValue
    } else if (massUpdateType === 'increment') {
      result = originalValue + massUpdateValue
    } else if (massUpdateType === 'percentage') {
      result = (originalValue * massUpdateValue) / 100
    }
    return _.toNumber(Number.parseFloat(result).toFixed(decimalPrecision))
  }

  lockedGridWidthSum(columnConfigs, pluginState) {
    const columnStates = (pluginState && pluginState.columnStates) ?? []
    let sum = 0
    if (pluginState && !_.isNil(pluginState.lockGridWidth)) {
      sum = pluginState.lockGridWidth
    } else {
      _.map(columnConfigs, (columnConfig, index) => {
        if (columnConfig.locked == true) {
          const columnState =
            _.find(columnStates, {
              configIndex: index
            }) || {}
          sum += columnState.width ? columnState.width : columnConfig.width
        } else if (columnConfig.locked == false) {
          const columnState =
            _.find(columnStates, {
              name: columnConfig.header,
              locked: true
            }) || {}
          if (!_.isEmpty(columnState)) {
            sum += columnState.width ? columnState.width : columnConfig.width
          }
        }
      })
    }

    if (sum) {
      if (this.extjsGrid && this.extjsGrid.cmp) {
        this.extjsGrid.cmp.lockedGrid.setWidth(sum + 2)
      }
      return sum + 2
    }
  }

  render() {
    const {
      props: gridProps,
      props: {
        isPreviewMode,
        gridInCombo = false,
        id: pluginId,
        pluginData = [],
        size: { width = 0, height = 0 } = {},
        settings: {
          config: gridConfig,
          config: {
            rowDisplayRules = [],
            cellDisplayRules = [],
            general: { header: gridHeader = null } = {},
            grid: {
              bufferedRenderer = true,
              columnLines = false,
              emptyText = 'No Data to Display',
              exportable = false,
              exportButtonPlacement = false,
              grouping: generalGrouping = false,
              lockable = false,
              split = false,
              minWidth = null,
              multiColumnSort = false,
              selection: { rowDeselection = false } = {},
              header: {
                headersVisible = true,
                headerAlignment = false,
                headerAlign = null,
                multiLineHeader = false
              } = {}
            } = {}
          }
        } = {}
      }
    } = this

    const fieldConfigs = this.getFieldConfigs()

    this.rowRuleContainer = this.createRowRuleContainer(rowDisplayRules)

    const cellRuleContainer = this.createCellRuleContainer(cellDisplayRules)

    let actualPluginState = this.getLoadedPluginStates(this.props)

    if (!_.isEmpty(actualPluginState)) {
      this.pluginStateLoaded = true
    }

    if (!_.isEmpty(this.pluginState)) {
      actualPluginState = this.pluginState
    } else {
      this.pluginState = actualPluginState
    }

    const { configHash = '' } = actualPluginState || {}
    if (configHash !== hash(gridConfig) || actualPluginState.version !== 2) {
      actualPluginState = {}
    }

    const editableColumns = this.getEditableColumns(gridConfig)

    const dockedItems = []

    const columnConfigs = this.getColumnConfigs()

    if (this.store) {
      const dataFields = this.createDataFields(fieldConfigs, columnConfigs)
      // Set column descriptions e.g. column data type
      this.store.setFields(dataFields)

      if (this.pagingEnabled(this.props)) {
        // Paged grid
        this.store.pageSize = this.props.getPageSize()

        dockedItems.push({
          displayInfo: true,
          dock: 'bottom',
          store: this.store,
          xtype: 'pagingtoolbar',
          listeners: {
            beforechange: this.handlePageNumberChange
          }
        })

        if (_.size(pluginData) > 0) {
          const pagedGridData = this.getData(pluginData, true)

          // Since we use paging, loading data has to be go over proxy
          this.store.proxy.data = pagedGridData
          this.store.currentPage = this.pageGridState.currentPage

          this.store.load()
        }
      } else if (_.size(pluginData) > 0) {
        // Not paged case
        const gridData = this.getData(pluginData)
        this.store.loadData(gridData)
        this.setDataDetails()
      }
    }

    const columns = this.createColumns(
      gridConfig,
      columnConfigs,
      cellRuleContainer,
      fieldConfigs,
      actualPluginState,
      editableColumns
    )

    const columnSorters = this.createSorters(
      columnConfigs,
      actualPluginState && actualPluginState.columnStates
    )

    this.store.setSorters(columnSorters)

    if (actualPluginState && actualPluginState.columnStates) {
      this.filterState = _.isEmpty(
        _.filter(
          actualPluginState.columnStates,
          ({ filter }) => filter && !filter.hasOwnProperty('null')
        )
      )
      this.tagFilters = this.getTagFilterStates(actualPluginState.columnStates)
    }
    if (generalGrouping) {
      if (actualPluginState && _.has(actualPluginState, 'groupingColumn')) {
        // Set grouping column from plugin state
        this.store.setGroupField(actualPluginState.groupingColumn)
      } else {
        const groupingColumn = _.find(columnConfigs, 'grouped')
        if (groupingColumn) {
          // Set grouping column from plugin config
          this.store.setGroupField(groupingColumn.fieldName)
        }
      }
    }

    // Create extjs grid features, which are enabled by configuration
    const gridFeatures = this.createGridFeatures(gridConfig)

    // Create extjs grid plugins, which are enabled by configuration
    const gridPlugins = this.createGridPlugins(gridConfig)

    // Create Header
    const header = !exportButtonPlacement ? this.createHeaderToolbar(exportable) : null

    // Create footer bar
    const footerBar = this.createFooter(gridConfig)

    let comboProps = {
      collapseDirection: 'left',
      collapseMode: '',
      collapsed: false,
      collapsible: false,
      header,
      title: null,
      width: '100%'
    }
    if (gridInCombo) {
      comboProps = {
        collapseDirection: 'left',
        collapseMode: 'header',
        collapsed: true,
        collapsible: true,
        header,
        title: gridHeader
      }
      if (width) {
        comboProps.width = width
      }
    }
    const gridCls = headerAlignment
      ? multiLineHeader
        ? `extjsGridContainer extjsGrid multiline-header header-align-opt ${headerAlign} ${pluginId}`
        : `extjsGridContainer extjsGrid header-align-opt ${headerAlign} ${pluginId}`
      : multiLineHeader
      ? `extjsGridContainer extjsGrid multiline-header ${pluginId}`
      : `extjsGridContainer extjsGrid ${pluginId}`

    const gridSettings = {
      allowDeselect: rowDeselection,
      bufferedRenderer: bufferedRenderer,
      cls: gridCls,
      collapseDirection: comboProps.collapseDirection,
      collapseMode: comboProps.collapseMode,
      collapsed: comboProps.collapsed,
      collapsible: comboProps.collapsible,
      columnLines,
      columns,
      dockedItems,
      hideHeaders: !headersVisible,
      enableLocking: lockable,
      fbar: footerBar,
      features: gridFeatures,
      gridProps,
      header: comboProps.header,
      minWidth,
      multiColumnSort,
      plugins: gridPlugins,
      scrollable: true,
      selModel: this.getSelectionModel(gridConfig),
      store: this.store,
      title: comboProps.title,
      viewModel: this.viewModel,
      height: isPreviewMode ? '500px' : height,
      width: comboProps.width,
      listeners: {
        afterrender: this.handleAfterRender,
        beforeselect: this.handleBeforeRowSelected,
        select: this.handleSelection,
        celldblclick: this.handleCellDoubleClick,
        collapse: this.handleCollapse,
        columnhide: () => this.handleColumnChanged(),
        columnmove: () => this.handleColumnChanged(),
        columnresize: () => this.handleColumnChanged(),
        columnshow: () => this.handleColumnChanged(),
        deselect: this.handleRowDeselected,
        expand: this.handleExpand,
        filteractivate: this.handleFilterActivate,
        filterchange: this.handleFilterChanged,
        filterdeactivate: this.handleFilterDeactivate,
        groupchange: () => this.handleColumnChanged(),
        headermenucreate: this.handleHeaderMenuCreate,
        lockcolumn: () => this.handleColumnChanged(),
        sortchange: this.handleSortChange,
        unlockcolumn: this.handleUnlockColumn
      },
      viewConfig: {
        deferEmptyText: false,
        emptyText: `<h3> ${emptyText} <h3>`,
        enableTextSelection: this.getTextSelectionEnabled(gridConfig),
        getRowClass: this.getRowClass
      },
      bind: {
        disabled: '{!gridEnabled}'
      },
      ref: (ref) => {
        this.extjsGrid = ref
      },
      ...(lockable && split && { split }),
      ...(split && {
        lockedGridConfig: {
          flex: 0,
          width: this.lockedGridWidthSum(columnConfigs, actualPluginState),
          listeners: {
            resize: (comp, width) => {
              this.lockedGridResize(this.pluginState, width)
            }
          }
        }
      })
    }
    const containerProps = {
      id: `slvyExtContainer-${pluginId}`,
      className: 'slvy-ext-container w-100 h-100'
    }

    if (!this.isAutoconfigDataReady()) {
      return null
    }

    return (
      <ExtRoot pluginId={pluginId}>
        <div ref={(containerRef) => (this.containerRef = containerRef)} {...containerProps}>
          <Grid {...gridSettings} />
          <SlvySpinner
            containerClass="bg-opacity-10 bg-dark sencha-grid-loading d-none"
            size="sm"
          />
        </div>
      </ExtRoot>
    )
  }
}

export default createPlugin(
  connect((state, ownProps) => {
    return {
      pluginStates: selectCollection(getPluginStates(ownProps.id), state.model3)
    }
  })(SenchaGrid),
  selectConnectorProps
)

export const Self = SenchaGrid
