/* eslint-disable jest/no-export */
import { DEFAULT_CUSTOM_ROW_INFO } from '../../../../constants'
import { AG_GRID_ENUMS } from '../../../../enums'
import { IRowDataGrid } from '../../../../types'

const { CUSTOM_ROW_INFO } = AG_GRID_ENUMS

export const rows: IRowDataGrid[] = [
  {
    col1: 10,
    col2: 7,
    col3: 14,
    col4: '1',
    col5: 'vito',
    col6: '2020-04-09T00:00:00',
    col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
    col8: null,
    col9: '-100,NULL,0,100,100',
    col10: false,
    col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 20,
    col4: '99',
    col5: 'vito',
    col6: '2021-03-29T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: '0,0,0,NULL',
    col10: true,
    col11: '1,-1,1,-1,1,-1',
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sonny',
    col6: '2021-12-13T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: '-100,-100,0,0,100,-100',
    col10: false,
    col11: '1,2,4,5,7,3,3,6,10,2,4',
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 55,
    col2: 5,
    col3: 2,
    col4: '33',
    col5: 'vitoo',
    col6: '2021-12-13T00:00:00',
    col7: 'vito',
    col8: null,
    col9: '10,-200,50,100,500',
    col10: true,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 55,
    col2: 5,
    col3: 2,
    col4: '33',
    col5: 'vitoo',
    col6: '2021-12-13T00:00:00',
    col7: 'vito',
    col8: null,
    col9: null,
    col10: true,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sony',
    col6: '2023-06-02T00:00:00',
    col7: 'as',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 55,
    col2: 5,
    col3: 2,
    col4: '33',
    col5: 'vitoo',
    col6: '2021-08-08T00:00:00',
    col7: 'vito',
    col8: null,
    col9: null,
    col10: true,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sonny',
    col6: '2020-01-28T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 99,
    col2: 3,
    col3: 2,
    col4: '99',
    col5: 'sonny',
    col6: '2020-01-27T00:00:00',
    col7: 'fredo',
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: 3,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: 'sonny',
    col6: '2020-06-17T00:00:00',
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 32,
    col2: 10,
    col3: 20,
    col4: '1',
    col5: 'sonny',
    col6: '2020-06-02T00:00:00',
    col7: null,
    col8: null,
    col9: null,
    col10: null,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: null,
    col6: null,
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  },
  {
    col1: 600,
    col2: 1,
    col3: 20,
    col4: '1',
    col5: 'sonny',
    col6: '2020-06-09T00:00:00',
    col7: null,
    col8: null,
    col9: null,
    col10: false,
    col11: null,
    col12: null,
    col3_readonly: 1,
    col4_readonly: 1,
    col5_readonly: 1
  }
]

export const expectedResultForFieldConfigValues = {
  col1: [10, 600, 99, 99, 55, 55, 99, 55, 99, 99, 600, 600, 32, 600, 600, 600, 600, 600],
  col2: [7, 1, 3, 3, 5, 5, 3, 5, 3, 3, 1, 1, 10, 1, 1, 1, 1, 1],
  col3: [14, 20, 20, 2, 2, 2, 2, 2, 2, 2, 20, 20, 20, 20, 20, 20, 20, 20],
  col4: [
    '1',
    '1',
    '99',
    '99',
    '33',
    '33',
    '99',
    '33',
    '99',
    '99',
    '1',
    '1',
    '1',
    '1',
    '1',
    '1',
    '1',
    '1'
  ],
  col5: [
    'vito',
    null,
    'vito',
    'sonny',
    'vitoo',
    'vitoo',
    'sony',
    'vitoo',
    'sonny',
    'sonny',
    null,
    'sonny',
    'sonny',
    null,
    null,
    null,
    null,
    'sonny'
  ],
  col6: [
    '2020-04-09T00:00:00',
    null,
    '2021-03-29T00:00:00',
    '2021-12-13T00:00:00',
    '2021-12-13T00:00:00',
    '2021-12-13T00:00:00',
    '2023-06-02T00:00:00',
    '2021-08-08T00:00:00',
    '2020-01-28T00:00:00',
    '2020-01-27T00:00:00',
    null,
    '2020-06-17T00:00:00',
    '2020-06-02T00:00:00',
    null,
    null,
    null,
    null,
    '2020-06-09T00:00:00'
  ],
  col7: [
    '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
    null,
    'fredo',
    'fredo',
    'vito',
    'vito',
    'as',
    'vito',
    'fredo',
    'fredo',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  ],
  col8: [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  ],
  col9: [
    '-100,NULL,0,100,100',
    null,
    '0,0,0,NULL',
    '-100,-100,0,0,100,-100',
    '10,-200,50,100,500',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  ],
  col10: [
    false,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    false,
    false,
    false,
    null,
    false,
    false,
    false,
    false,
    false
  ],
  col11: [
    '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
    null,
    '1,-1,1,-1,1,-1',
    '1,2,4,5,7,3,3,6,10,2,4',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  ],
  col12: [3, null, 3, 3, 3, 3, null, 3, 3, 3, null, null, null, null, null, null, null, null],
  col3_readonly: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  col4_readonly: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  col5_readonly: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
}

export const expectedNullValuesForAllColumns = {
  col1: null,
  col2: null,
  col3: null,
  col4: null,
  col5: null,
  col6: null,
  col7: null,
  col8: null,
  col9: null,
  col10: null,
  col11: null,
  col12: null,
  col3_readonly: null,
  col4_readonly: null,
  col5_readonly: null
}

export const expectedGetRegisterMethodFieldConfigsParams = [
  {
    name: 'col1',
    type: {
      type: 'int',
      isMultiple: false
    }
  },
  {
    name: 'col2',
    type: {
      type: 'int',
      isMultiple: false
    }
  },
  {
    name: 'col3',
    type: {
      type: 'int',
      isMultiple: false
    }
  },
  {
    name: 'col4',
    type: {
      type: 'string',
      isMultiple: false
    }
  },
  {
    name: 'col5',
    type: {
      type: 'string',
      isMultiple: false
    }
  },
  {
    name: 'col6',
    type: {
      type: 'datetime',
      isMultiple: false
    }
  },
  {
    name: 'col7',
    type: {
      type: 'string',
      isMultiple: false
    }
  },
  {
    name: 'col8',
    type: {
      type: 'string',
      isMultiple: false
    }
  },
  {
    name: 'col9',
    type: {
      type: 'string',
      isMultiple: false
    }
  },
  {
    name: 'col10',
    type: {
      type: 'bool',
      isMultiple: false
    }
  },
  {
    name: 'col11',
    type: {
      type: 'string',
      isMultiple: false
    }
  },
  {
    name: 'col12',
    type: {
      type: 'int',
      isMultiple: false
    }
  },
  {
    name: 'col3_readonly',
    type: {
      type: 'int',
      isMultiple: false
    }
  },
  {
    name: 'col4_readonly',
    type: {
      type: 'int',
      isMultiple: false
    }
  },
  {
    name: 'col5_readonly',
    type: {
      type: 'int',
      isMultiple: false
    }
  }
]

export const omitCustomFieldsData: IRowDataGrid = {
  col1: 10,
  col2: 7,
  col3: 14,
  col4: '1',
  col5: 'vito',
  col6: '2020-04-09T00:00:00',
  col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
  col8: null,
  col9: '-100,NULL,0,100,100',
  col10: false,
  col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
  col12: 3,
  col3_readonly: 1,
  col4_readonly: 1,
  col5_readonly: 1,
  [CUSTOM_ROW_INFO]: DEFAULT_CUSTOM_ROW_INFO,
  _SLVY_col4_SUBSTITUTE_FIELD_: 'col4'
}

export const expectedOmitCustomFieldsData: IRowDataGrid = {
  col1: 10,
  col2: 7,
  col3: 14,
  col4: '1',
  col5: 'vito',
  col6: '2020-04-09T00:00:00',
  col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
  col8: null,
  col9: '-100,NULL,0,100,100',
  col10: false,
  col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
  col12: 3,
  col3_readonly: 1,
  col4_readonly: 1,
  col5_readonly: 1
}

export const formatDateData: IRowDataGrid = {
  col1: 10,
  col2: 7,
  col3: 14,
  col4: '1',
  col5: 'vito',
  col6: new Date('2022-06-12'),
  col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
  col8: null,
  col9: '-100,NULL,0,100,100',
  col10: false,
  col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
  col12: 3,
  col3_readonly: 1,
  col4_readonly: 1,
  col5_readonly: 1
}

export const expectedFormatDateData: IRowDataGrid = {
  col1: 10,
  col2: 7,
  col3: 14,
  col4: '1',
  col5: 'vito',
  col6: '2022-06-12 03:00:00',
  col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
  col8: null,
  col9: '-100,NULL,0,100,100',
  col10: false,
  col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
  col12: 3,
  col3_readonly: 1,
  col4_readonly: 1,
  col5_readonly: 1
}

export const formatWithoutDateData: IRowDataGrid = {
  col1: 10,
  col2: 7,
  col3: 14,
  col4: '1',
  col5: 'vito',
  col7: '> Dede\n\n* de\n* d\n\nOnur test 2\n\n**deneme**\n\n# test\n\n_deneme_\ndeneme',
  col8: null,
  col9: '-100,NULL,0,100,100',
  col10: false,
  col11: '1,2,4,-5,7,3,3,-6,10,2,-4,-5',
  col12: 3,
  col3_readonly: 1,
  col4_readonly: 1,
  col5_readonly: 1
}

describe('Mocks:', () => {
  it('should skip test', () => undefined)
})
