Ext.define('MDM.src.view.globalapp.Panel.Geo.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.globalapp-geo-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {},
  onTreeLoad: function (store) {
    var me = this,
      viewModel = me.getViewModel(),
      tree = me.lookupReference('tree')
    var node = store.findNode('id', 0)
    tree.getSelectionModel().select(node)
  },
  onActivateGeo: function () {
    var store = this.getViewModel().getStore('GeoTreeStore').load()
  },
  onDeleteNode: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete ' + record.get('Title') + '?',
      //height: 160,
      width: 500,
      msg: 'Are you sure you want to delete this record? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDeleteNode(record)
        }
      }
    })
  },
  doDeleteNode: function (record) {
    record.drop()
    this.getViewModel().getStore('GeoTreeStore').remove(record)
    this.getViewModel().getStore('GeoTreeStore').sync()
  },
  onExpandAllClick: function () {
    var tree = this.lookup('tree').expandAll()
  },
  onCollapseAllClick: function (btn) {
    this.lookup('tree').collapseAll()
    this.lookup('tree').getRootNode().expand()
  },
  onEditNode: function (grid, rowIndex, colIndex, item, e, record) {
    var depth = record.get('depth'),
      labels = ['Root', 'MegaRegion', 'Region', 'Country', 'Supply Source']
    this.getViewModel().set('currentRec', record)
    Ext.create('MDM.src.view.globalapp.Panel.Geo.Dilaog.Region.Window', {
      record: record,
      formType: labels[depth],
      title: labels[depth] + " <span style='color:yellow'>" + record.get('Title') + '</span>'
    }).show()
  },
  onTreeSelect: function (tree, record) {
    var data = []
    var cat = []
    record.cascadeBy(function (node) {
      if (node.get('depth') === 3) {
        data.push([node.get('Country'), node.parentNode.parentNode.data.MegaRegionID])
        cat[node.parentNode.parentNode.data.Title] = {
          min: node.parentNode.parentNode.data.MegaRegionID,
          max: node.parentNode.parentNode.data.MegaRegionID,
          label: node.parentNode.parentNode.data.Title
        }
      }
    })
    var pieces = []
    for (var prop in cat) {
      // skip loop if the property is from prototype
      if (!cat.hasOwnProperty(prop)) continue
      pieces.push(cat[prop])
    }
    var echartmap = this.lookup('echartmap')
    echartmap.setChartData({
      data: data,
      pieces: pieces
    })
  }
})
