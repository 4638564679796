import { useCallback, useEffect, useRef } from 'react'
import {
  useGetMultiRowSelectedParams,
  useGetMultiRowSelectedResultParams,
  useGetSelectionCellParams
} from '../../../index'
import {
  TriggerMultiRowSelectedResultEventRef,
  TriggerMultiRowSelectedResultProps
} from './useRegisterEventTriggerMultiRowSelectedResult.types'

function useRegisterEventTriggerMultiRowSelectedResult({
  fieldConfigs,
  registerEvent,
  getFieldType,
  gridRef
}: TriggerMultiRowSelectedResultProps) {
  const triggerMultiRowSelectedResultEventRef = useRef<TriggerMultiRowSelectedResultEventRef>({
    handleTriggerMultiRowSelectedResult: (_args: never): never => ({} as never)
  })

  const { cellParams } = useGetSelectionCellParams(fieldConfigs)
  const { multiRowSelectedParams } = useGetMultiRowSelectedParams({ cellParams, getFieldType })

  const { getParams } = useGetMultiRowSelectedResultParams(fieldConfigs)

  const triggerMultiRowSelectedResult = useCallback(() => {
    return getParams(gridRef)
  }, [getParams, gridRef])

  useEffect(() => {
    const event = registerEvent({
      key: 'TriggerMultiRowSelectedResult',
      fn: triggerMultiRowSelectedResult,
      returnTypes: multiRowSelectedParams
    })

    triggerMultiRowSelectedResultEventRef.current.handleTriggerMultiRowSelectedResult = event
  }, [multiRowSelectedParams, registerEvent, triggerMultiRowSelectedResult])

  return { triggerMultiRowSelectedResultEventRef }
}

export default useRegisterEventTriggerMultiRowSelectedResult
