import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import { BodyProps } from './Body.types'

export default function Body({
  children,
  className,
  contextMenuRef,
  rowDragDrop,
  onContextMenu,
  style
}: BodyProps) {
  const { body } = useTheme()

  return (
    <div
      ref={contextMenuRef}
      className={cx(body, className)}
      style={style}
      onContextMenu={onContextMenu}
    >
      {rowDragDrop ? <DndProvider backend={HTML5Backend}>{children}</DndProvider> : children}
    </div>
  )
}
