export default () => {
  return {
    title: 'Job Scheduler Status',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          jobId: {
            type: 'number',
            title: 'Job Id'
          },
          relationalJobId: {
            type: 'string',
            title: 'Relational Id'
          },
          requestWithVariables: {
            type: 'boolean',
            title: 'Send start request with parameters',
            required: true,
            default: false,
            description: 'Works with setRunButtonParameters by WF.'
          },
          showJobTitle: {
            type: 'boolean',
            title: 'Show Job Title',
            required: true,
            default: true
          },
          title: {
            type: 'string',
            title: 'Title'
          },
          showMachineName: {
            type: 'boolean',
            title: 'Show Job Machine Name',
            required: true,
            default: true
          },
          jobMachineName: {
            type: 'string',
            title: 'Job Machine Name'
          },

          refreshingTime: {
            type: 'number',
            title: 'Refresh Time (sec)',
            default: 30,
            enum: [5, 10, 20, 30, 40, 50, 60],
            required: true
          },
          backgroundcolor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          }
        }
      },
      fields: {
        type: 'object',
        title: 'Fields',
        properties: {
          enableRunButton: {
            type: 'boolean',
            title: 'Enable Run Button',
            required: true,
            default: true
          },
          enableDurationChart: {
            type: 'boolean',
            title: 'Enable Duration Chart',
            required: true,
            default: true
          },
          enableRunStatus: {
            type: 'boolean',
            title: 'Enable Run Status',
            required: true,
            default: true
          },
          enableStep: {
            type: 'boolean',
            title: 'Enable Steps',
            required: true,
            default: true
          },
          enableJobInfo: {
            type: 'boolean',
            title: 'Enable Job Informations',
            required: true,
            default: true
          },
          jobInfoProp: {
            type: 'object',
            title: 'Job Information Properties',
            properties: {
              jobInfoTaken: {
                type: 'number',
                title: 'Take',
                default: 10
              },
              jobInfoSkip: {
                type: 'number',
                title: 'Skip',
                default: 0
              }
            }
          }
        }
      }
    }
  }
}
