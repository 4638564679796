export default () => {
  return {
    title: 'Daily Schedule',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          backgroundcolor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#ffffff'
          },
          blockHours: {
            type: 'boolean',
            title: 'Block Hours',
            default: false,
            required: true
          },
          editingType: {
            type: 'string',
            title: 'Editing Type',
            enum: ['Direct', 'Trigger'],
            default: 'Direct',
            required: true
          }
        }
      },
      columns: {
        type: 'object',
        title: 'Data',
        properties: {
          date: {
            type: 'string',
            title: 'Date',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          name: {
            type: 'string',
            title: 'Row Title',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          value: {
            type: 'string',
            title: 'Hour Index',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          state: {
            type: 'string',
            title: 'Active',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          hourIndexStart: {
            type: 'string',
            title: 'Hour Index Start',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          hourIndexEnd: {
            type: 'string',
            title: 'Hour Index End',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
