import { MouseEvent } from 'react'
import cx from 'classnames'
import { useTheme } from '../../contexts/ThemeContext'
import SubmenuDropdown from './SubmenuDropdown'
import { MenuItemDef, MenuItemLeafDef } from './ContextMenu'

type MenuItemIcon = MenuItemLeafDef['icon']
type MenuItem = Exclude<MenuItemDef, 'separator'>
type MenuItemProps = {
  item: MenuItemDef & { index: number }
  onHide: () => void
}

const MenuItem = ({ item, onHide }: MenuItemProps) => {
  const theme = useTheme()

  const handleClickMenuItem = (menuItem: MenuItem, event: MouseEvent<HTMLElement>) => {
    if (menuItem.subMenu) return
    if (menuItem.onClick) menuItem.onClick(event)
    onHide()
  }

  const renderIcon = (icon: MenuItemIcon) => {
    if (!icon) return undefined

    return (
      <span className={theme.contextMenuItemIcon}>
        {typeof icon === 'string' ? <i className={icon} /> : icon}
      </span>
    )
  }

  const renderContextMenuItem = (menuItem: MenuItem) => {
    if (menuItem.subMenu) {
      return (
        <>
          {renderIcon(menuItem.icon)}
          <SubmenuDropdown items={menuItem.subMenu} toggleTitle={menuItem.name} onHide={onHide} />
        </>
      )
    }

    return (
      <>
        {renderIcon(menuItem.icon)}
        <span className={theme.contextMenuItemName}>{menuItem.name}</span>
      </>
    )
  }

  if (item === 'separator') {
    return <hr className="separator" />
  }

  return (
    <li
      aria-hidden="true"
      className={cx(theme.contextMenuItem, item.className, {
        [theme.contextMenuItemDisabled]: item.disabled
      })}
      hidden={item.hidden}
      tabIndex={item.index}
      onClick={(event) => handleClickMenuItem(item, event)}
    >
      {renderContextMenuItem(item)}
    </li>
  )
}

export default MenuItem
