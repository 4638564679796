import { isSubstituteFieldMatch } from '../lookup'

describe('AgGrid:Helpers:Lookup:isSubstituteFieldMatch', () => {
  it('should return true if param contains pattern', () => {
    const result = isSubstituteFieldMatch('_SLVY_Gender_SUBSTITUTE_FIELD_')
    expect(result).toBe(true)
  })
  it('should return false if param does not contains pattern', () => {
    const result = isSubstituteFieldMatch('Gender')
    expect(result).toBe(false)
  })
})
