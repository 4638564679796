import { IIconClasses } from './List/Highlight/Highlight.types'

export interface ISettings {
  minChars: number
  debounceDelay: number
  throttleDelay: number
  iconClasses: IIconClasses
}

export const settings: ISettings = {
  minChars: 3,
  debounceDelay: 500,
  throttleDelay: 500,
  iconClasses: {
    0: 'fa-link',
    1: 'fa-angle-double-right',
    2: 'fa-tags'
  }
}

export const placeholder: string = 'Search...'
