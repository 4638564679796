import { STORE_PIVOT_SINGLE_DETAIL_VIEW_SETTINGS } from '../actions/actionTypes'

const initialState = {
  pivotSingleDetailViewSettings: {
    isVisible: false,
    selectedLeftKeys: [],
    selectedBreadcrumbs: [],
    selectedMetricName: '',
    mergedLeftFieldsAndKeys: [],
    metricFields: [], // [{ Name: 'UnitSales', DisplayName: 'Unit Sales', AllowUpdate: false }}]
    selectedMetricFields: [{ Name: '' }, { Name: '' }, { Name: '' }] // [{ Name: 'Unit Sales', defaultSelected: true }],
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PIVOT_SINGLE_DETAIL_VIEW_SETTINGS:
      return {
        pivotSingleDetailViewSettings: {
          ...state.pivotSingleDetailViewSettings,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default reducer
