Ext.define('MDM.Btl.MainviewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.btl-mainviewmodel',
  stores: {
    rpStore: {
      autoLoad: true,
      fields: [
        {
          name: 'acPeriod',
          type: 'boolean'
        },
        {
          name: 'hasUnconstraint',
          type: 'boolean'
        }
      ],
      proxy: {
        type: 'ajax',
        actionMethods: {
          read: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/btl_reporting_period'
      },
      listeners: {
        load: 'onLoadRpStore'
      }
    },
    DPIStoreFWIP: {
      autoLoad: false,
      model: 'MDM.btl.model.DPIModel',
      proxy: {
        type: 'ajax',
        actionMethods: {
          read: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/btl_DPI_Data'
      }
    },
    DPIStoreFG: {
      autoLoad: false,
      model: 'MDM.btl.model.DPIModel',
      proxy: {
        type: 'ajax',
        actionMethods: {
          read: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/btl_DPI_Data'
      }
    },
    mrStore: {
      model: 'MDM.Btl.model.MegaRegion',
      autoLoad: true
    },
    rStore: {
      model: 'MDM.Btl.model.Region',
      autoLoad: true,
      listeners: {
        load: 'onLoadRegionStore'
      }
    },
    dhStore: {
      type: 'tree',
      model: 'MDM.Btl.model.DPIHierarcy',
      autoLoad: false,
      root: {
        expanded: false,
        text: 'DPI Families',
        iconCls: 'fa fa-sitemap',
        id: '0',
        children: []
      },
      listeners: {
        load: {
          fn: 'onDPITreeStoreLoad',
          delay: 200
        }
      }
    },
    mainStore: {
      model: 'MDM.Btl.model.MainModel',
      autoLoad: false,
      groupField: 'MaterialType',
      filters: [
        {
          property: 'IsHide',
          value: false
        }
      ]
    },
    discrepancyStore: {
      model: 'MDM.Btl.model.model.Discrepancy',
      autoLoad: false,
      groupField: 'MaterialType'
    },
    editStore: {
      model: 'MDM.Btl.model.EditModel',
      autoLoad: false,
      groupField: 'MaterialType'
    },
    editStoreManuel: {
      model: 'MDM.Btl.model.EditModel',
      //    groupField: 'BTLTypeDesc',
      autoSync: true,
      listeners: {
        beforesync: 'onBeforesyncEditStoreManuel',
        write: 'onWriteEditStoreManuel'
      }
      // filters: [{
      //     property: 'recType',
      //     value: 'Manuel'
      // }]
    },
    btlRuleDetailsStore: {
      model: 'MDM.Btl.model.EditModel',
      autoSync: true
    },
    importStore: {
      model: 'MDM.Btl.model.EditModel',
      autoSync: true,
      listeners: {
        beforesync: 'onBeforesyncImportStore',
        write: 'onWriteImportStore'
      }
    },
    lastPeriodStore: {
      model: 'MDM.Btl.model.EditModel',
      autoSync: true,
      listeners: {
        beforesync: 'onBeforesyncLastPeriodStore',
        write: 'onWriteLastPeriodStore'
      }
    },
    ruleBasedStore: {
      model: 'MDM.Btl.model.EditModel',
      autoSync: true,
      listeners: {
        beforesync: 'onBeforesyncRuleBasedStore',
        write: 'onWriteRuleBasedStore'
      }
    },
    otherStore: {
      model: 'MDM.Btl.model.EditModel',
      autoSync: true,
      listeners: {
        beforesync: 'onBeforesyncOtherStore',
        write: 'onWriteOtherStore'
      }
    },
    calculatedStore: {
      model: 'MDM.Btl.model.EditModel',
      autoSync: true,
      listeners: {
        beforesync: 'onBeforesyncOtherStore',
        write: 'onWriteOtherStore'
      }
    }
  },
  formulas: {
    isEditable: {
      bind: {
        a: '{DPIHierarcy}',
        b: '{RegionID}',
        deep: true
      },
      get: function (data) {
        var a = data.a
        var b = data.b
        return !!(a && a.get('depth') == 3 && b)
      }
    },
    hideReadOnlyColfn: {
      bind: {
        bindTo: '{hideReadOnlyCol}',
        deep: true
      },
      get: function (d) {
        return d != 'All'
      }
    },
    showMassimport: {
      bind: {
        a: '{actab}',
        b: '{onSelectionChange}',
        deep: true
      },
      get: function (d) {
        return d.a.getSelection().length > 0
      }
    },
    showhideimport: {
      bind: {
        b: '{isActivePeriod}',
        c: '{RegionID}',
        d: '{readOnly}',
        deep: true
      },
      get: function (o) {
        if (o.d) return true
        if (!o.b) return true
        return !o.c
      }
    },
    hasUnconstraint: {
      bind: {
        c: '{reportingPeriodID}'
      },
      get: function (c) {
        var reportingPeriod = this.get('rpcombo')
        if (reportingPeriod && reportingPeriod.selection) {
          return reportingPeriod.selection.data.hasUnconstraint
        }
        return false
      }
    },
    v1: {
      bind: {
        c: '{reportingPeriodID}'
      },
      get: function (c) {
        var reportingPeriod = this.get('rpcombo')
        if (reportingPeriod && reportingPeriod.selection) {
          if (reportingPeriod.selection.data.hasUnconstraint) {
            return 'Constrained'
          }
        }
        return 'Unconstrained'
      }
    },
    v2: {
      bind: {
        c: '{reportingPeriodID}'
      },
      get: function (c) {
        var reportingPeriod = this.get('rpcombo')
        if (reportingPeriod && reportingPeriod.selection) {
          if (reportingPeriod.selection.data.hasUnconstraint) {
            return 'Unconstrained'
          }
        }
        return 'Constrained'
      }
    }
  },
  data: {
    canShowImportBtn: false,
    onSelectionChange: null,
    DPIHierarcy: null,
    btncy: 'All',
    MaterialType: 'All',
    hideReadOnlyCol: 'All',
    readOnly: false,
    isActivePeriod: false,
    RegionID: null,
    MegaRegionID: null,
    ReportingPeriodID: null,
    syncSc: 'True',
    constarinView: 0,
    dpidefaulValue: null,
    owner: 'Region'
  }
})
