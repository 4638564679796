require('../src/view/btlv2/util/Column')
require('../src/view/btlv2/util/NumberCol')
require('../src/view/btlv2/util/SubTotalColum')

require('../src/view/btlv2/model/Discrepancy')
require('../src/view/btlv2/model/DPIHierarcy')
require('../src/view/btlv2/model/DPIModel')
require('../src/view/btlv2/model/EditModel')
require('../src/view/btlv2/model/ImportModel')
require('../src/view/btlv2/model/LastPeriodModel')
require('../src/view/btlv2/model/MainModel')
require('../src/view/btlv2/model/MegaRegion')
require('../src/view/btlv2/model/Region')

require('../src/view/btlv2/MainviewModel')

require('../src/view/btlv2/center/MainGrid')

require('../src/view/btlv2/edit/EditMixins')
require('../src/view/btlv2/cmp/HeaderBarMixin')

require('../src/view/btlv2/dd/Discrepancy/Grid')
require('../src/view/btlv2/dd/Discrepancy/Dialog')
require('../src/view/btlv2/dd/MassApply/Other')
require('../src/view/btlv2/dd/MassApply/Dialog')
require('../src/view/btlv2/dd/NegativeInventory/Grid')
require('../src/view/btlv2/dd/NegativeInventory/Dialog')
require('../src/view/btlv2/dd/RuleBased/Dialog')
require('../src/view/btlv2/dd/RuleBased/Other')

require('../src/view/btlv2/dialog/EditForm')
require('../src/view/btlv2/dialog/Grid')
require('../src/view/btlv2/dialog/MaterialDialog')
require('../src/view/btlv2/dialog/BtlRulesInfo')
require('../src/view/btlv2/dialog/BtlRulesInfoTemplate')
require('../src/view/btlv2/dialog/RuleBasedInfo')

require('../src/view/btlv2/Discrepancy/MainGrid')
require('../src/view/btlv2/edit/grid/LastPeriod')
require('../src/view/btlv2/edit/grid/RuleBased')
require('../src/view/btlv2/Discrepancy/DiscrepancyDialog')

require('../src/view/btlv2/DpiChart/DpiChart')
require('../src/view/btlv2/DpiChart/ChartDialog')

require('../src/view/btlv2/upload/Model/Demand')
require('../src/view/btlv2/upload/Edit/Dialog')
require('../src/view/btlv2/upload/Edit/Grid')
require('../src/view/btlv2/upload/Edit/FormDialog')
require('../src/view/btlv2/upload/Import')
require('../src/view/btlv2/upload/Prompt')
require('../src/view/btlv2/upload/MainController')

require('../src/view/btlv2/edit/grid/Calculated')
require('../src/view/btlv2/edit/grid/Imported')
require('../src/view/btlv2/edit/grid/Manuel')
require('../src/view/btlv2/edit/grid/Other')
require('../src/view/btlv2/edit/grid/RuleBasedDepent')
require('../src/view/btlv2/edit/TabPanel')

require('../src/view/btlv2/cmp/UxTreePicker')
require('../src/view/btlv2/cmp/DhCombo')
require('../src/view/btlv2/cmp/Headerbar')
require('../src/view/btlv2/cmp/MaterialTypeBtn')
require('../src/view/btlv2/cmp/MenuPanel')
require('../src/view/btlv2/cmp/MrCombo')
require('../src/view/btlv2/cmp/Region')
require('../src/view/btlv2/cmp/RpCombo')

require('../src/view/btlv2/MainController')
require('../src/view/btlv2/Main')
