export default () => {
  return {
    title: 'Production Plan',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          name: {
            type: 'string',
            title: 'Name'
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          clearDataMessage: {
            type: 'string',
            title: 'Clear data message'
          }
        }
      },

      settings: {
        type: 'object',
        title: 'Configurator',
        properties: {
          RunId: {
            type: 'number',
            title: 'RunId'
          },
          RunInfoId: {
            type: 'number',
            title: 'RunInfoId'
          },
          ScenarioId: {
            type: 'number',
            title: 'ScenarioId'
          },
          overrideServiceUrl: {
            type: 'string',
            title: 'Override Service Url(ip:port)'
          }
        }
      },

      customSettings: {
        type: 'object',
        title: 'Settings',
        properties: {
          totalUtilization: {
            type: 'object',
            title: 'Total Utilization Settings',
            properties: {
              minUtilization: {
                type: 'number',
                title: 'Min Utilization '
              },
              maxUtilization: {
                type: 'number',
                title: 'Max Utilization '
              }
            }
          },
          ignoreAuxiliary: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Ignore Auxiliary'
          }
        }
      },

      /// Product Plan
      issueSettings: {
        type: 'array',
        title: 'Product Issues',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Issue {{self.name}}',
          properties: {
            enable: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Enable'
            },
            tableName: {
              type: 'string',
              title: 'Fields',
              watch: {
                ds: 'general.datafields'
              },
              enumSource: 'ds'
            },
            name: {
              type: 'string',
              title: 'Issue Name'
            },
            shortName: {
              type: 'string',
              title: 'Short Name'
            },
            color: {
              type: 'string',
              format: 'Color',
              title: 'Background Color',
              default: 'orange'
            }
          }
        }
      },

      productInfoSettings: {
        type: 'array',
        title: 'Product Grid Values',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Product {{self.name}}',
          properties: {
            enable: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Enable'
            },
            tableName: {
              type: 'string',
              title: 'Fields',
              watch: {
                ds: 'general.datafields'
              },
              enumSource: 'ds'
            },
            name: {
              type: 'string',
              title: 'Name'
            },
            shortName: {
              type: 'string',
              title: 'Short Name'
            },
            matchFieldName: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Match Field Name'
            },
            isResourceBased: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Is Resource Based'
            },
            format: {
              type: 'string',
              default: '0,0',
              required: true,
              title: 'Format'
            },
            isEditable: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Is Editable'
            }
          }
        }
      },

      productPlanSettings: {
        type: 'array',
        title: 'Product Graph Values',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Product {{self.name}}',
          properties: {
            enable: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Enable'
            },
            checked: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Is first'
            },
            tableName: {
              type: 'string',
              title: 'Fields',
              watch: {
                ds: 'general.datafields'
              },
              enumSource: 'ds'
            },
            name: {
              type: 'string',
              title: 'Issue Name'
            }
          }
        }
      },

      customMOQSettings: {
        type: 'object',
        title: 'Custom MOQ Settings',
        properties: {
          overrideDefaults: {
            type: 'boolean',
            default: false,
            title: 'Override defaults'
          },
          customMOQValues: {
            type: 'array',
            title: 'Custom MOQ Values',
            items: {
              type: 'object',
              title: 'serie',
              headerTemplate: 'Product {{self.name}}',
              properties: {
                enable: {
                  type: 'boolean',
                  default: true,
                  required: true,
                  title: 'Enable'
                },
                tableName: {
                  type: 'string',
                  title: 'Fields',
                  watch: {
                    ds: 'general.datafields'
                  },
                  enumSource: 'ds'
                },
                name: {
                  type: 'string',
                  title: 'Issue Name'
                }
              }
            }
          }
        }
      },

      coverSettings: {
        type: 'object',
        title: 'Cover Settings',
        properties: {
          coverLabel: {
            type: 'string',
            default: 'Cover',
            required: true,
            title: 'Cover Label'
          },
          cover: {
            type: 'string',
            title: 'Cover',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          minCover: {
            type: 'string',
            title: 'Min Cover',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          maxCover: {
            type: 'string',
            title: 'Max Cover',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          }
        }
      },

      /// Resource
      resourceIssueSettings: {
        type: 'array',
        title: 'Resource Issues',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Issue {{self.name}}',
          properties: {
            enable: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Enable'
            },
            tableName: {
              type: 'string',
              title: 'Fields',
              watch: {
                ds: 'general.datafields'
              },
              enumSource: 'ds'
            },
            name: {
              type: 'string',
              title: 'Issue Name'
            },
            shortName: {
              type: 'string',
              title: 'Short Name'
            },
            color: {
              type: 'string',
              format: 'Color',
              title: 'Background Color',
              default: 'orange'
            }
          }
        }
      },

      resourceInfoSettings: {
        type: 'array',
        title: 'Resource Grid Values',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Resource {{self.name}}',
          properties: {
            enable: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Enable'
            },
            tableName: {
              type: 'string',
              title: 'Fields',
              watch: {
                ds: 'general.datafields'
              },
              enumSource: 'ds'
            },
            name: {
              type: 'string',
              title: 'Issue Name'
            },
            shortName: {
              type: 'string',
              title: 'Short Name'
            },
            format: {
              type: 'string',
              default: '0,0',
              required: true,
              title: 'Format'
            }
          }
        }
      },

      /// TODO Remove
      resourcePlanSettings: {
        type: 'array',
        title: 'Resource Graph Values',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Resource {{self.name}}',
          properties: {
            enable: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Enable'
            },
            tableName: {
              type: 'string',
              title: 'Fields',
              watch: {
                ds: 'general.datafields'
              },
              enumSource: 'ds'
            },
            name: {
              type: 'string',
              title: 'Issue Name'
            },
            matchFieldName: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Match Field Name'
            },
            format: {
              type: 'string',
              default: '0,0',
              required: true,
              title: 'Format'
            }
          }
        }
      },

      resourceAndProductPlanSettings: {
        type: 'object',
        title: 'Resource & Product Plan Settings',
        properties: {
          shiftCalendarButtonFormat: {
            type: 'string',
            title: 'Shift Calendar Button Format',
            default: '0,0'
          },
          metricSettings: {
            type: 'object',
            title: 'Metric Settings',
            properties: {
              metricLimit: {
                type: 'string',
                title: 'Selected Metric Limit'
              },
              isMetricOrderSorted: {
                type: 'boolean',
                default: false,
                title: 'Legend Issue Order (Sorted)'
              }
            }
          },
          moveMode: {
            type: 'object',
            title: 'Move Mode',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: false,
                title: 'Enable'
              },
              isOverutilizationAllowed: {
                type: 'boolean',
                default: false,
                title: 'Allow Overutilization'
              }
            }
          },
          groupBy: {
            type: 'object',
            title: 'GroupBy Settings',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: false,
                title: 'Enable'
              },
              buttonTitle: {
                type: 'string',
                default: 'group by',
                title: 'Button Title'
              },
              popupTitle: {
                type: 'string',
                default: 'Resource Hierarchy GroupBy',
                title: 'Popup Title'
              }
            }
          },
          grandTotal: {
            type: 'object',
            title: 'Grand Total Settings',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: true,
                title: 'Enable'
              }
            }
          },
          aggregatedValues: {
            type: 'object',
            title: 'Aggregated Values',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: true,
                title: 'Enable'
              }
            }
          },
          pegTagFilter: {
            type: 'object',
            title: 'PegTag Filter',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: false,
                title: 'Enable'
              },
              buttonTitle: {
                type: 'string',
                default: 'pegtag filter',
                title: 'Button Title'
              },
              buttonIcon: {
                type: 'string',
                default: 'fa fa-fw fa slvy-ui-icon-tag-extra-lt',
                title: 'Button Icon',
                format: 'IconSelector'
              }
            }
          },
          sessions: {
            type: 'object',
            title: 'Snapshots',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: false,
                title: 'Enable'
              },
              buttonTitle: {
                type: 'string',
                default: 'snapshots',
                title: 'Button Title'
              },
              buttonIcon: {
                type: 'string',
                default: 'fa fa-fw fa slvy-ui-icon-snapshot-regular',
                title: 'Button Icon',
                format: 'IconSelector'
              }
            }
          }
        }
      },

      topMenu: {
        type: 'array',
        title: 'Navigation Bar Settings',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Menu {{self.name}}',
          properties: {
            active: {
              type: 'boolean',
              default: false,
              required: true,
              title: 'Active'
            },
            enable: {
              type: 'boolean',
              default: true,
              required: true,
              title: 'Enable'
            },
            name: {
              type: 'string',
              title: 'Name'
            },
            action: {
              type: 'string',
              title: 'Action',
              enum: ['eventBtn', 'issues', 'undo', 'redo', 'save', 'saveas']
            },
            iconName: {
              type: 'string',
              title: 'Icon',
              format: 'IconSelector',
              default: 'fa-fw fa slvy-ui-icon-play'
            }
          }
        }
      },

      selectedParameterNames: {
        type: 'array',
        title: 'Unit of Measure',
        items: {
          type: 'object',
          title: 'Serie',
          headerTemplate: 'Name {{self.name}}',
          properties: {
            name: {
              type: 'string',
              title: 'Description'
            },
            tableName: {
              type: 'string',
              title: 'Parameter Name',
              watch: {
                ds: 'general.datafields'
              },
              enumSource: 'ds'
            }
          }
        }
      },

      periodSettings: {
        type: 'object',
        title: 'Period Settings',
        properties: {
          displayOptions: {
            type: 'object',
            title: 'Display Options',
            properties: {
              isYearVisible: {
                type: 'boolean',
                title: 'Display Year',
                default: true
              },
              isWeekVisible: {
                type: 'boolean',
                title: 'Display Week',
                default: true
              },
              isMonthVisible: {
                type: 'boolean',
                title: 'Display Month',
                default: true
              }
            }
          }
        }
      },

      productionVariants: {
        type: 'object',
        title: 'Production Variants',
        properties: {
          defaultProductionVariant: {
            type: 'string',
            title: 'Default production variant',
            required: true,
            watch: {
              ds: 'general.datafields'
            },
            enumSource: 'ds'
          },
          userOptions: {
            type: 'object',
            title: 'User Options',
            properties: {
              optionTitle: {
                type: 'string',
                title: 'Title',
                default: 'Production Variant',
                required: true
              },
              isEnabled: {
                type: 'boolean',
                title: 'Enabled',
                default: false,
                required: true
              },
              optionList: {
                type: 'array',
                title: 'Options List',
                items: {
                  type: 'object',
                  title: 'Serie',
                  headerTemplate: '{{self.name}}',
                  properties: {
                    name: {
                      type: 'string',
                      title: 'Name',
                      required: true
                    },
                    tableName: {
                      type: 'string',
                      title: 'Parameter Name',
                      required: true,
                      watch: {
                        ds: 'general.datafields'
                      },
                      enumSource: 'ds'
                    }
                  }
                }
              }
            }
          }
        }
      },

      updateReasons: {
        type: 'object',
        title: 'Update Reasons',

        properties: {
          isReasonActive: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Enable edit reasons'
          },
          reasons: {
            type: 'array',
            title: 'Update Reasons',
            items: {
              type: 'object',
              title: 'Serie',
              headerTemplate: 'Reason {{i}}',
              properties: {
                // id: {
                //     type: 'string',
                //     title: 'Reason Id'
                //     //default: crypto.getRandomValues(new Uint16Array(4)).join('-'),
                //     //readOnly: true
                // },
                name: {
                  type: 'string',
                  title: 'Reason'
                }
              }
            }
          }
        }
      },

      appSettings: {
        type: 'object',
        title: 'View Settings',
        properties: {
          productionView: {
            type: 'object',
            title: 'Production Plan',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: true,
                title: 'Enable'
              },
              title: {
                type: 'string',
                default: 'Production Plan',
                title: 'Title'
              },
              showGrandTotal: {
                type: 'boolean',
                default: false,
                title: 'Grand Total'
              },
              gridView: {
                type: 'string',
                title: 'Grid View',
                default: 'Product',
                enum: ['Product', 'Product and Aggregation', 'Product, Aggregation and Resource']
              }
            }
          },
          resourceView: {
            type: 'object',
            title: 'Resource Plan',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: true,
                title: 'Enable'
              },
              title: {
                type: 'string',
                default: 'Resource View',
                title: 'Title'
              },
              showGrandTotal: {
                type: 'boolean',
                default: false,
                title: 'Grand Total'
              }
            }
          },
          resourceScheduleView: {
            type: 'object',
            title: 'Resource Schedule',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: true,
                title: 'Enable'
              },
              title: {
                type: 'string',
                default: 'Resource Schedule',
                title: 'Title'
              }
            }
          },
          shiftCalendarView: {
            type: 'object',
            title: 'Shift Calendar',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: true,
                title: 'Enable'
              },
              title: {
                type: 'string',
                default: 'Shift Calendar',
                title: 'Title'
              }
            }
          },
          resourceAndProductionView: {
            type: 'object',
            title: 'Resource & Production Plan',
            properties: {
              isEnabled: {
                type: 'boolean',
                default: true,
                title: 'Enable'
              },
              title: {
                type: 'string',
                default: 'Resource & Production Plan',
                title: 'Title'
              },
              showGrandTotal: {
                type: 'boolean',
                default: false,
                title: 'Grand Total'
              }
            }
          },
          saveHistory: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Save History on Browser'
          }
        }
      }
    }
  }
}
