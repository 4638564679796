/**
 * Serie class for bar series type
 *
 * See {@link Chart.ux.Highcharts.Serie} class for more info
 */
Ext.define('Chart.ux.Highcharts.BarSerie', {
  extend: 'Chart.ux.Highcharts.Serie',
  alternateClassName: ['highcharts.bar'],
  type: 'bar'
})
