import { useRef, useLayoutEffect, useCallback } from 'react'

export default function useEvent(callback) {
  const callbackRef = useRef(null)

  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  return useCallback((...args) => {
    return callbackRef.current(...args)
  }, [])
}
