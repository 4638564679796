import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { shadeRGBColor } from '../util'

const Header = ({ calendarRef, settings, defaultDate, currentView, handleViewChange }) => {
  const [calendarRange, setCalendarRange] = useState()

  useEffect(() => {
    setCalendarRange(formatCalendarRange(defaultDate))
    calendarRef.current.calendarInst.setDate(defaultDate)
  }, [defaultDate])

  function formatCalendarRange(date, format = headerTitleFormat) {
    const currentDate = moment([date.getFullYear(), date.getMonth()])
    return currentDate.format(format)
  }

  function handleCalendarRange(direction) {
    if (!direction) return

    if (direction === 'prev') {
      calendarRef.current.calendarInst.prev()
    } else if (direction === 'today') {
      calendarRef.current.calendarInst.setDate(defaultDate)
    } else if (direction === 'next') {
      calendarRef.current.calendarInst.next()
    }

    const currentDate = calendarRef.current.calendarInst.getDate()
    setCalendarRange(formatCalendarRange(currentDate))
  }

  const { headerTitleFormat = 'MMMM/YYYY', themeColor = '#5ca9b8', showButtons = 'all' } = settings

  const themeStyle = { backgroundColor: themeColor }
  const buttonStyle = { backgroundColor: shadeRGBColor(themeColor, -0.14) }

  return (
    <div className="header" style={themeStyle}>
      <div className="left-header">
        <button
          className="btn btn-default btn-today"
          style={buttonStyle}
          type="button"
          onClick={() => handleCalendarRange('today')}
        >
          Today
        </button>
        <button
          className="calendar-range-icon fa fa-chevron-left text-white"
          style={buttonStyle}
          type="button"
          onClick={() => handleCalendarRange('prev')}
        />
        <button
          className="calendar-range-icon fa fa-chevron-right text-white"
          style={buttonStyle}
          type="button"
          onClick={() => handleCalendarRange('next')}
        />
      </div>
      <div className="center-header">{calendarRange}</div>
      <div className="right-header">
        <select
          className="select-view"
          style={buttonStyle}
          value={currentView}
          onChange={handleViewChange}
        >
          {(showButtons === 'all' || showButtons === 'month') && (
            <option value="month">Month</option>
          )}
          {(showButtons === 'all' || showButtons === 'week') && <option value="week">Week</option>}
          {(showButtons === 'all' || showButtons === 'day') && <option value="day">Day</option>}
        </select>
      </div>
    </div>
  )
}

export default Header
