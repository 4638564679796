import { memo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { IContainerHeaderProps } from './ContainerHeader.types'

const tooltip = (text: string) => <Tooltip id="tooltip-overlay">{text}</Tooltip>

const ContainerHeader = ({ total, active, title }: IContainerHeaderProps) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center w-100"
      data-testid="container-header"
    >
      {title}
      <div className="d-flex align-items-center">
        <OverlayTrigger delay={100} overlay={tooltip('Total / Active')} placement="top">
          <span className="fs-sm me-2">
            <strong>
              ({total}/{active})
            </strong>
          </span>
        </OverlayTrigger>
      </div>
    </div>
  )
}

export default memo(ContainerHeader)
