Ext.define('clustering.vc.leaflet.map', {
  extend: 'clustering.vc.Component',
  xtype: 'clustering-leaflet-map',
  config: {
    defaultCls: {
      wrapper: Ext.baseCSSPrefix + 'echart-wrapper',
      scene: Ext.baseCSSPrefix + 'echart-scene',
      hidden: Ext.baseCSSPrefix + 'echart-hidden'
    },
    padding: {
      top: 4,
      left: 4,
      right: 4,
      bottom: 4
    },
    style: {
      border: '2px solid #fff'
    },
    isConfiguring: true,
    map: null,
    mapOptions: {
      wheelPxPerZoomLevel: 100
    },
    smoothFactor: 1,
    weight: 10,
    opacity: 1,

    defaultTile: {
      title: 'Openstreet',
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      maxZoom: 20,
      attribution: 'OpenStreetmap'
    }, // Default Tile
    tiles: {
      Openstreet: {
        title: 'Openstreet',
        url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        maxZoom: 20,
        opacity: 0.5,
        attribution: 'OpenStreetmap'
      },
      Mapbox: {
        title: 'Mapbox',
        url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib3JoYW50IiwiYSI6ImNpc3NraDNoNDAwNmMydHBiMTdzbTc2bDUifQ.ZEmnaGxk69dOm8IvH_dRsg',
        maxZoom: 20,
        key: 'MAPBOX API KEY',

        attribution: '&copy; GGL/OSM'
      },
      'Mapbox-Street': {
        title: 'Mapbox Street',
        url: 'https://api.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoib3JoYW50IiwiYSI6ImNpc3NraDNoNDAwNmMydHBiMTdzbTc2bDUifQ.ZEmnaGxk69dOm8IvH_dRsg',
        maxZoom: 20,
        key: 'MAPBOX API KEY',
        opacity: 0.5,
        attribution: '&copy; GGL/OSM'
      },
      'Mapbox-Light': {
        title: 'Mapbox Light',
        url: 'https://api.mapbox.com/v4/mapbox.light/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoib3JoYW50IiwiYSI6ImNpc3NraDNoNDAwNmMydHBiMTdzbTc2bDUifQ.ZEmnaGxk69dOm8IvH_dRsg',
        maxZoom: 20,

        key: 'MAPBOX API KEY',
        attribution: '&copy; GGL/OSM'
      },
      'Mapbox Satellite': {
        title: 'Mapbox Satellite',
        url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib3JoYW50IiwiYSI6ImNpc3NraDNoNDAwNmMydHBiMTdzbTc2bDUifQ.ZEmnaGxk69dOm8IvH_dRsg',
        maxZoom: 24,
        key: 'MAPBOX API KEY',
        attribution: '&copy; GGL/OSM'
      }
    }
  },

  getMap: function () {
    if (!this.innerEl || !this.innerEl.dom) return false
    if (!this.map) {
      this.isConfiguring = false
      this.map = L.map(this.innerEl.dom, this.getMapOptions())
      this.map.ownerCt = this
      this.fireEvent('mapready', this.map)
    }

    return this.map
  },

  resizeHandler: function (size) {
    var me = this,
      map = me.getMap(),
      isRtl = me.getInherited().rtl,
      width = size && size.width,
      height = size && size.height

    if (!me.isLoaded) {
      me.isLoaded = true
    }
    if (!me.isTileLoaded) {
      me.isTileLoaded = true
      me.initTiles(map)
    }

    if (!(width && height)) {
      return
    }

    me.innerEl.dom.style.width = width + 'px'
    me.innerEl.dom.style.height = height + 'px'
    if (map) {
      me.resizeMapHandler(map, size)
    }
    me.fireEvent('mapresize', me)
  },
  resizeMapHandler: function (map) {
    map.invalidateSize()
  },
  initTiles: function (map) {
    var me = this

    var tiles = {},
      list = this.getTiles()
    for (var i in list) {
      var t = list[i],
        tile = L.tileLayer(t.url, t)
      tiles[t.title] = tile
      if (i == this.getDefaultTile()) {
        this.mainLayer = tile.addTo(map)
      }
    }

    me.tileLayers = tiles
    // Add Map Control
    var Roadmap = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'roadmap'
    })

    var Aerial = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'satellite'
    })

    var Terrain = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'terrain'
    })

    var Hybrid = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'hybrid'
    })

    var Styles = L.gridLayer.googleMutant({
      styles: [
        { elementType: 'labels', stylers: [{ visibility: 'off' }] },
        { featureType: 'water', stylers: [{ color: '#444444' }] },
        { featureType: 'landscape', stylers: [{ color: '#eeeeee' }] },
        { featureType: 'road', stylers: [{ visibility: 'off' }] },
        { featureType: 'poi', stylers: [{ visibility: 'off' }] },
        { featureType: 'transit', stylers: [{ visibility: 'off' }] },
        { featureType: 'administrative', stylers: [{ visibility: 'off' }] },
        { featureType: 'administrative.locality', stylers: [{ visibility: 'off' }] }
      ],
      maxZoom: 24,
      type: 'roadmap'
    })
    tiles = Ext.apply(tiles, {
      Roadmap: Roadmap,
      Aerial: Aerial,
      Terrain: Terrain,
      Hybrid: Hybrid,
      Styles: Styles
    })

    tiles['Openstreet'].addTo(map)

    var baseMaps = [
      {
        groupName: 'Google Base Maps',
        expanded: true,
        layers: {
          Satellite: Aerial,
          'Road Map': Roadmap,
          Terreno: Terrain
        }
      },
      {
        groupName: 'OSM Base Maps',
        layers: {
          OpenStreetMaps: Hybrid
        }
      }
    ]

    var options = {
      container_width: '300px',
      group_maxHeight: '80px',
      exclusive: true,
      position: 'bottomright'
    }
    var control,
      me = this
    setTimeout(function () {
      if (L.Control.styledLayerControl) {
        var control = L.Control.styledLayerControl(baseMaps, [], options)
        map.addControl(control)
        me.layerControl = control
      }
    }, 1000)
    this.isInitTiles = true
    map.setView([39, 32], 3)
  },
  applyPadding: function (padding, oldPadding) {
    var result

    if (!Ext.isObject(padding)) {
      result = Ext.util.Format.parseBox(padding)
    } else if (!oldPadding) {
      result = padding
    } else {
      result = Ext.apply(oldPadding, padding)
    }

    return result
  },
  // TODO APPLY ONLY LINESTRING
  applySmoothFactor: function (n, o) {
    var map = this.getMap()
    if (map) {
      map.eachLayer(function (layer) {
        layer.options.smoothFactor = n
        if (layer._updatePath) layer._update()
      })
      map.invalidateSize()
    }
    return n
  },
  applyWeight: function (n, o) {
    var map = this.getMap()
    if (map) {
      map.eachLayer(function (layer) {
        layer.options.weight = n
        if (layer.setStyle && layer.feature && layer.feature.geometry.type == 'LineString') {
          layer.setStyle({ fillColor: 'blue', weight: n })
        }
      })
    }

    map.invalidateSize()
    return n
  },
  applyOpacity: function (n, o) {
    var map = this.getMap()
    if (map) {
      map.eachLayer(function (layer) {
        if (layer.setStyle && layer.feature && layer.feature.geometry.type == 'LineString') {
          layer.setStyle({ opacity: n })
        }
      })
    }

    map.invalidateSize()
    return n
  },
  destroy: function () {
    var map
    if ((map = this.getMap())) {
      map.off()
      map.removeEventListener()
      if (map.remove) map.remove()
    }
    this.callParent()
  }
})
