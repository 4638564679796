export const padZero = (str, len) => {
  len = len || 2
  const zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export const invertColor = (hex, bw) => {
  if (hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1)
    }

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }

    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.')
    }

    let r = parseInt(hex.slice(0, 2), 16)
    let g = parseInt(hex.slice(2, 4), 16)
    let b = parseInt(hex.slice(4, 6), 16)

    if (bw) {
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
    }

    r = (255 - r).toString(16)
    g = (255 - g).toString(16)
    b = (255 - b).toString(16)

    return `#${padZero(r)}${padZero(g)}${padZero(b)}`
  }

  return null
}

export const colorShade = (color, percent) => {
  color = color.match(/\d+/g)

  let retval = color

  if (Array.isArray(color)) {
    let [R, G, B] = color

    R = parseInt((R * (100 + percent)) / 100, 10)
    G = parseInt((G * (100 + percent)) / 100, 10)
    B = parseInt((B * (100 + percent)) / 100, 10)

    R = R < 255 ? R : 255
    G = G < 255 ? G : 255
    B = B < 255 ? B : 255

    const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
    const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
    const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)

    retval = `#${RR}${GG}${BB}`
  }

  return retval
}

export const getColorStyle = (hex) => {
  const backgroundColor = colorShade(hex, 0)
  const borderColor = backgroundColor
  const color = invertColor(backgroundColor, true)

  return {
    color,
    backgroundColor,
    borderColor
  }
}

export const defaultColorScheme = {
  primary: {
    backgroundColor: '#1a7bb9'
  },
  success: {
    backgroundColor: '#1ab394'
  },
  info: {
    backgroundColor: '#21b9bb'
  },
  warning: {
    backgroundColor: '#f7a54a'
  },
  danger: {
    backgroundColor: '#ec4758'
  },
  link: {
    backgroundColor: '#1ab394'
  },
  custom: {}
}
