Ext.define('MDM.Btlv2.DpiChart.DpiChart', {
  extend: 'Chart.ux.Highcharts',
  xtype: 'btl-dpichart-dpichart',
  reference: 'DPIChart',
  margin: '10,10,10,10',
  mainpage: false,
  addButton: true,
  maximizable: true,
  loadMask: true,
  megaRegion: 'Asia',
  subtitle: 'Fg',
  loadMaskMsg: 'Chart data is looading.....',
  bodyStyle: {
    background: '#ffc',
    border: '2px #ccc solid'
  },
  initComponent: function () {
    var me = this
    this.buildDemo()
    this.plotBandId = Ext.id('', 'plotBand')
    if (this.animation == false) {
      this.initAnim = false
      this.updateAnim = false
      this.initAnimAfterLoad = false
    }
    this.callParent()
  },
  setIsMax: function (c) {
    var me = this
    if (c) {
      me.chartConfig.title.style.fontSize = '24px'
      me.chartConfig.xAxis.labels.style.fontSize = '16px'
      me.chartConfig.yAxis[0].labels.style.fontSize = '16px'
      me.chartConfig.legend = {
        itemStyle: {
          fontSize: '16px'
        }
      }
    } else {
      me.chartConfig.title.style.fontSize = '14px'
      //  me.chartConfig.subtitle.style.fontSize = "13px";
      me.chartConfig.xAxis.labels.style.fontSize = '13px'
      me.chartConfig.yAxis[0].labels.style.fontSize = '13px'
      me.chartConfig.legend = {
        itemStyle: {
          fontSize: '13px'
        }
      }
    }
    me.draw()
  },
  setStore: function (s) {
    this.bindStore(s)
  },
  afterChartRendered: function (d) {
    this.fireEvent('chartRender', this, d)
  },
  buildDemo: function () {
    var me = this
    //  me.store = me.buildStore();
    me.series = [
      {
        dataIndex: 'InventoryNull',
        type: 'spline',
        name: 'Inventory',
        color: 'rgba(236,17,92,1)',
        draggableY: true
      },
      {
        dataIndex: 'Demand',
        type: 'column',
        name: 'Demand',
        stack: 'Demand',
        color: 'rgba(255,152,0,1)',
        draggableY: true
      },
      {
        dataIndex: 'Export',
        type: 'column',
        name: 'Export ',
        stack: 'Demand',
        draggableY: true
      },
      {
        dataIndex: 'RazorDraw',
        type: 'column',
        name: 'RazorDraw ',
        color: 'rgba(65,117,5,1)',
        stack: 'Demand'
      },
      {
        dataIndex: 'Packaging',
        type: 'column',
        name: 'Packaging ',
        color: 'rgba(175,231,110,1)',
        stack: 'Demand',
        draggableY: true
      },
      {
        dataIndex: 'Production',
        type: 'column',
        name: 'Production',
        color: 'rgba(3,169,244,1)',
        stack: 'Production'
      },
      {
        dataIndex: 'Import',
        type: 'column',
        name: 'Import ',
        stack: 'Production',
        color: 'rgba(236,17,92,1)'
      }
    ]
    me.xField = 'Period'
    me.chartConfig = {
      chart: {
        type: 'column',
        alignTicks: true,
        marginRight: 20,
        marginBottom: 160,
        //  zoomType    : 'xy',
        dataLabels: {
          enabled: true
        }
      },
      credits: {
        text: 'SOLVOYO © 2016',
        href: 'http://www.solvoyo.com',
        position: {
          align: 'right',
          y: -5 // position of credits
        },
        style: {
          fontSize: '11pt' // you can style it!
        }
      },
      tooltip: {
        crosshairs: false,
        positioner: function (labelWidth, labelHeight, point) {
          var chart = this.chart
          var tooltipX, tooltipY
          if (point.plotX + labelWidth > chart.plotWidth) {
            tooltipX = point.plotX + chart.plotLeft - labelWidth - 30
          } else {
            tooltipX = point.plotX + chart.plotLeft + 30
          }
          tooltipY = point.plotY + chart.plotTop - 30
          return {
            x: tooltipX,
            y: tooltipY
          }
        },
        formatter: function () {
          return '<b>' + this.x + '</b>'
        },
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        },
        series: {
          cursor: 'ns-resize',
          point: {
            events: {
              drop: function () {}
            }
          },
          stickyTracking: false
        }
      },
      navigation: {
        buttonOptions: {
          align: 'left'
        }
      },
      title: {
        text: ' DPI REPORT',
        style: {
          fontSize: '18px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: this.subtitle,
        style: {
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        labels: {
          style: {
            fontSize: '13px'
          },
          rotation: 315,
          y: 35
        }
      },
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: 'Quantity in Millions'
          },
          labels: {
            style: {
              fontSize: '13px'
            }
          }
        }
      ]
    }
    Ext.apply(me.chartConfig, {})
  },
  onClickPieChart: function (serie, point, record, event) {
    this.chart.fireEvent('clickChart', record, event)
  }
})
