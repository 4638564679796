Ext.define('MDM.src.view.regionapp.Panel.UnassignedGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'regionapp-panel-unassignedGrid',
  columnLines: true,
  rowLines: true,
  layout: 'border',
  lockedGridConfig: {
    header: false,
    width: 750,
    split: true
  },
  lockedViewConfig: {
    scroll: 'horizontal'
  },
  listeners: {
    // this event notifies us when the document was saved
    documentsave: 'onDocumentSave',
    beforedocumentsave: 'onBeforeDocumentSave',
    dataready: 'onDataReady',
    activate: 'onActivate',
    beforedrag: 'onBeforeDrag',
    enddrag: 'onEndDrag',
    dropped: 'onDropped'
  },
  emptyText: '<h3> No Record Found <h3>',
  plugins: ['gridfilters', 'gridexporter'],
  selModel: {
    selType: 'checkboxmodel',
    checkOnly: true,
    showHeaderCheckbox: true,
    disabled: true,
    renderer: function (value, metaData, record) {
      if (!record.get('inventory') + record.get('subInventory') < record.get('ShipQuantity')) {
        return '<div class="bloqued">&#160;</div>'
      }
      return '<div class="x-grid-checkcolumn">&#160;</div>'
    },
    updateHeaderState: function () {
      // check to see if all records are selected
      var me = this,
        store = me.store,
        storeCount = store.getCount(),
        views = me.views,
        hdSelectStatus = false,
        selectedCount = 0,
        selected,
        len,
        i
      if (!store.isBufferedStore && storeCount > 0) {
        selected = me.selected
        hdSelectStatus = true
        for (i = 0, len = selected.getCount(); i < len; ++i) {
          if (store.indexOfId(selected.getAt(i).id) > -1) {
            ++selectedCount
          }
        }
        hdSelectStatus = selectedCount > 0
      }
      if (views && views.length) {
        me.column.setHeaderStatus(hdSelectStatus)
      }
    },
    listeners: {
      selectionchange: 'onSelectionChangeMainGrid',
      beforeselect: function (el, record, index, eOpts) {
        if (!record.get('isEditable')) {
          return false
        }
      }
    }
  },

  viewConfig: {
    getRowClass: function (record) {
      return record.get('isEditable') ? 'editable-row' : 'noneditable-row'
    },
    stripeRows: true,
    plugins: {
      ptype: 'gridviewdragdrop',
      ddGroup: 'grid-to-form',
      enableDrop: false,
      dragZone: {
        onDrag: function (e) {
          var grid = this.view.grid.ownerGrid
          grid.fireEvent('beforedrag', e)
          return true
        },
        onEndDrag: function (data, e) {
          var grid = data.view.grid.ownerGrid
          grid.fireEvent('enddrag', e)
        }
      }
    },

    listeners: {
      beforedrop: function (node, data, overModel, dropPosition, dropHandlers) {
        dropHandlers.cancelDrop()
        var me = this
        setTimeout(function () {
          data.view.getStore().remove(data.records)
          me.getStore().add(data.records)
          me.setSelection(null)
        }, 0)
      },
      cellclick: function (view, cell, cellIndex, record) {
        var clickedDataIndex = view.panel.headerCt.getHeaderAtIndex(cellIndex).dataIndex
        var clickedColumnName = view.panel.headerCt.getHeaderAtIndex(cellIndex).text
        var clickedCellValue = record.get(clickedDataIndex)
        if (clickedDataIndex == 'numberInBOM') {
          var grid = Ext.create({
            xtype: 'ownerList'
          })
          var win = Ext.create('Ext.window.Window', {
            layout: 'fit',
            height: 300,
            ui: 'window-inverse',
            modal: true,
            iconCls: 'fa fa-pencil-square',
            width: 600,
            title: 'List',
            items: [grid]
          })
          win.show()
        }
      }
    },
    focusRow: function (row, delay) {}
  },
  columns: [
    {
      xtype: 'actioncolumn',
      dataIndex: 'MaterialDescription',
      menuDisabled: true,
      sortable: false,
      enableCellDrag: true,
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          enableCellDrag: true,
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          tooltip: 'Edit',
          handler: 'onEditMaterial'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'MaterialDescription',
      menuDisabled: true,
      sortable: false,
      enableCellDrag: true,
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-trash-o',
          enableCellDrag: true,
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          tooltip: 'Suppress from all lists and make this material inactive ',
          handler: 'onDrop'
        }
      ]
    },
    {
      text: 'Material',
      locked: true,
      flex: 1,
      sortable: true,
      minWidth: 90,
      dataIndex: 'MaterialCode',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      renderer: function (value, metaData, record, rowIdx, colIdx, store) {
        return value
      }
    },
    {
      text: 'Description',
      flex: 1,
      enableCellDrag: true,
      sortable: true,
      minWidth: 200,
      locked: true,
      editor: {
        // defaults to textfield if no xtype is supplied
      },
      dataIndex: 'MaterialDescription',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      }
    },

    {
      text: 'Appears On Extract',
      tooltip: 'Appears On Extract',
      flex: 1,
      sortable: true,
      minWidth: 70,
      dataIndex: 'AppearOnExtractYesOrNo',
      filter: 'list',
      locked: true
    },
    {
      xtype: 'actioncolumn',
      dataIndex: 'AppearOnExtractYesOrNo',
      menuDisabled: true,
      sortable: false,
      menuText: 'Extract Details',
      width: 40,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-external-link-square',

          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('AppearsOnExtract')
          },

          tooltip: 'Extraction details',
          handler: 'onExtractDetails'
        }
      ]
    },
    {
      text: 'Material Type',
      width: 120,
      sortable: true,
      dataIndex: 'MaterialType',
      filter: 'list'
    },
    {
      text: 'BUOM',
      minWidth: 100,
      sortable: true,
      dataIndex: 'MaterialBUOM',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      editor: {}
    },

    {
      text: 'SU',
      width: 100,
      sortable: true,
      xtype: 'numbercolumn',
      format: '0.000000',
      align: 'right',
      dataIndex: 'SU',
      filter: 'number'
    },

    {
      text: 'GU Assigned',
      width: 120,
      //tdCls: 'red',
      align: 'right',
      sortable: true,
      format: '0',
      xtype: 'numbercolumn',
      dataIndex: 'GU_Default',
      filter: 'number'
    },
    {
      text: 'Excluded from production',
      tooltip: 'Excluded from Production',
      sortable: true,
      minWidth: 70,
      dataIndex: 'ExcludedFromProductionYesorNo',
      filter: 'list',
      locked: false
    },
    {
      text: 'Region',
      width: 120,
      sortable: true,
      filter: 'list',

      dataIndex: 'MegaRegion'
    },
    {
      text: 'Subregion',
      width: 120,
      sortable: true,
      filter: 'list',

      dataIndex: 'region'
    },
    {
      text: 'Brand',
      width: 120,
      sortable: true,
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: 'Search for...'
        }
      },
      dataIndex: 'Brand'
    },
    {
      text: 'Product Form Detail',
      width: 120,
      sortable: true,
      filter: 'list',
      dataIndex: 'ProductFormDetail'
    },
    {
      text: 'CSU Indicator',
      width: 120,
      sortable: true,
      filter: 'number',

      dataIndex: 'CSUIndicator'
    },
    {
      text: 'Modified BY',
      width: 120,
      sortable: true,
      filter: 'list',
      dataIndex: 'ModifiedBY'
    },
    {
      text: 'Modified Date',
      width: 120,
      sortable: true,
      filter: 'date',
      dataIndex: 'ModifiedDate'
    },
    {
      text: 'Supply Source',
      width: 120,
      sortable: true,
      filter: 'list',
      dataIndex: 'SupplySourceDescription'
    },

    {
      text: 'DataSource',
      width: 120,
      sortable: true,
      align: 'right',
      filter: 'list',

      dataIndex: 'DataSource'
    }
  ]
})
