import { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PluginTypes } from '@/BasePlugin'
import { AgGridProps } from '../../../../types'
import { MultiRowsSetEventRef } from './useRegisterEventMultiRowsSet.types'

function useRegisterEventMultiRowsSet(registerEvent: AgGridProps['registerEvent']) {
  const multiRowsSetEventRef = useRef<MultiRowsSetEventRef>({
    handleMultiRowsSet: (_args: never): never => ({} as never)
  })

  useEffect(() => {
    multiRowsSetEventRef.current.handleMultiRowsSet = registerEvent({
      key: 'MultiRowsSet',
      fn: () => ({ refreshKey: uuidv4() }),
      returnTypes: { refreshKey: PluginTypes.string }
    })
  }, [registerEvent])

  return { multiRowsSetEventRef }
}

export default useRegisterEventMultiRowsSet
