export default {
  title: 'ObjectViewer',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'ObjectViewer',
  description: '',
  iconClass: 'fa-align-center',
  color: 'lazur-bg',
  group: 'Views'
}
