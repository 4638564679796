Ext.define('clustering.vc.echarts.echarts', {
  extend: 'clustering.vc.Component',
  xtype: 'clustering-leaflet-echarts',
  config: {
    defaultCls: {
      wrapper: Ext.baseCSSPrefix + 'echart-wrapper',
      scene: Ext.baseCSSPrefix + 'echart-scene',
      hidden: Ext.baseCSSPrefix + 'echart-hidden'
    },
    padding: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    isConfiguring: true,
    echarts: null
  },
  getEcharts: function () {
    if (!this.innerEl || !this.innerEl.dom) return false

    if (!this.echarts) {
      this.isConfiguring = false
      this.echarts = echarts.init(this.innerEl.dom)

      this.fireEvent('chartready', this.map)
    }
    return this.echarts
  },

  resizeHandler: function (size) {
    var me = this,
      echarts = me.getEcharts(),
      isRtl = me.getInherited().rtl,
      width = size && size.width,
      height = size && size.height

    if (!me.isLoaded) {
      me.isLoaded = true
    }

    if (!(width && height)) {
      return
    }
    me.innerEl.dom.style.width = width + 'px'
    me.innerEl.dom.style.height = height + 'px'
    if (this.getEcharts()) {
      this.getEcharts().resize()
    }
    if (echarts) {
      me.resizeHandler(echarts, size)
    }
    me.fireEvent('chartresize', me)
  },
  resizeMapHanler: function (echarts) {
    this.getEcharts().resize()
  },

  applyPadding: function (padding, oldPadding) {
    var result

    if (!Ext.isObject(padding)) {
      result = Ext.util.Format.parseBox(padding)
    } else if (!oldPadding) {
      result = padding
    } else {
      result = Ext.apply(oldPadding, padding)
    }

    return result
  },
  // TODO APPLY ONLY LINESTRING
  destroy: function () {
    this.callParent()
  }
})
