import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import ScenarioTreeList from '../../ScenarioTree/ScenarioTreeList'
import './style.scss'
import { setLoaderReducer } from '../../../store/slices/appSlice'
import {
  selectScenario,
  selectScenarioName,
  toggleScenarioSelection
} from '../../../store/slices/treeSelection'
import { setClusterTree } from '../../../store/slices/assortment'
import { unCollapse } from '../../../store/slices/collapse'
import { useFetchClusterTreeMutation } from '../../../store'

const selectFromState = (state) => ({
  selectedScenarioClusterTree: state.treeSelection.clusterTree,
  clusterTree: state.assortment.clusterTree
})

function AssortmentSelection(props) {
  const [fetchClusterTree, fetchClusterTreeResult] = useFetchClusterTreeMutation()
  const {
    selectedScenarioClusterTree: { currentScenario = {} } = {},
    pluginId,
    onScenarioSelected = () => {}
  } = props

  const { clusterTree, selectedScenarioClusterTree } = useSelector(selectFromState)

  const dispatch = useDispatch()

  const { Root, Root: { Id = null } = {} } = clusterTree

  useEffect(() => {
    const payload = { pluginId, method: 'ClusterGetTree' }
    fetchClusterTree({ ...payload })
  }, [])

  useEffect(() => {}, [Root])

  useEffect(() => {
    // TODO check query mutation diff for status isLoading
    const { status, data: responseData } = fetchClusterTreeResult
    if (status === 'pending') {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Loading Cluster Library' }))
    } else if (status === 'fulfilled' && responseData) {
      const { Root, Root: { Id = null } = {} } = responseData

      if (!_.isEmpty(Root)) {
        dispatch(setClusterTree(responseData))
        dispatch(unCollapse({ Id, dataKey: 'clusterTree' }))
      }
      dispatch(setLoaderReducer({ isShown: false, messages: 'Loading Cluster Library' }))
    }

    // TODO check
  }, [fetchClusterTreeResult])

  useEffect(() => {
    const { isFolderSelected = true } = selectedScenarioClusterTree
    onScenarioSelected(isFolderSelected)
  }, [selectedScenarioClusterTree])

  return (
    <div className="assortment-scenario-tree -visible assortment-selection-container -free-height">
      <div className="scenario-lib-wrapper">
        <div className="a-wr">
          <nav>
            <ScenarioTreeList
              dataKey="clusterTree"
              isStatusChangeable={false}
              root={Root}
              expandedRows={{
                0: true,
                '0.0': true
              }}
              title={'Cluster Library'}
            />
          </nav>
        </div>
      </div>
    </div>
  )
}

export default AssortmentSelection
