import _ from 'lodash'
import { SET_PAGE_VARIABLES, SET_MENU_VARIABLES, SET_GLOBAL_VARIABLES, SET_STORE_RESET } from '.'

const initialStoreState = {
  page: {
    variables: {}
  },
  menu: {
    variables: {}
  },
  global: {
    variables: {}
  }
}

const initialState = {
  stores: [],
  page: {
    assignees: {}
  },
  menu: {
    assignees: {}
  },
  global: {
    assignees: {}
  }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_VARIABLES:
    case SET_MENU_VARIABLES:
    case SET_GLOBAL_VARIABLES:
    case SET_STORE_RESET:
      const { stores } = state
      return { ...state, stores: storesReducer(stores, action) }
    default:
      return state
  }
}

export const storesReducer = (state = initialStoreState, action) => {
  const { storeIndex } = action
  if (storeIndex === undefined) return state
  switch (action.type) {
    case SET_PAGE_VARIABLES:
    case SET_MENU_VARIABLES:
    case SET_GLOBAL_VARIABLES:
      state[storeIndex] = storeReducer(state[storeIndex], action)
      return state
    case SET_STORE_RESET:
      return _.filter(state, (s, i) => i !== storeIndex)
    default:
      return state
  }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
export const storeReducer = (state = initialStoreState, action) => {
  const { result } = action
  switch (action.type) {
    case SET_PAGE_VARIABLES:
      const {
        page: { variables: pageVariables },
        page
      } = state
      return {
        ...state,
        page: {
          ...page,
          variables: { ...pageVariables, ...result }
        }
      }
    case SET_MENU_VARIABLES:
      const {
        menu: { variables: menuVariables },
        menu
      } = state
      return {
        ...state,
        menu: {
          ...menu,
          variables: { ...menuVariables, ...result }
        }
      }
    case SET_GLOBAL_VARIABLES:
      const {
        global: { variables: globalVariables },
        global
      } = state
      return {
        ...state,
        global: {
          ...global,
          variables: { ...globalVariables, ...result }
        }
      }
    default:
      return state
  }
}
