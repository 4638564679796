import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import _ from 'lodash'

class Label extends Component {
  render() {
    const { item, sectionLeftColumn, sectionRightColumn, backgroundColor } = this.props
    const lightColor = backgroundColor.replace(/[^,]+(?=\))/, '0.3')
    const darkColor = backgroundColor.replace(/[^,]+(?=\))/, '0.1')
    return (
      <Row>
        <Col
          className="data-row-left"
          md={_.toNumber(sectionLeftColumn)}
          sm={_.toNumber(sectionLeftColumn)}
          style={{
            backgroundColor: lightColor
          }}
        >
          <h3>{item.title}</h3>
        </Col>
        <Col
          className="data-row-right"
          md={_.toNumber(sectionRightColumn)}
          sm={_.toNumber(sectionRightColumn)}
          style={{
            backgroundColor: darkColor
          }}
        >
          <h3>{item.value}</h3>
        </Col>
      </Row>
    )
  }
}
export default Label
