window.JSONEditor.defaults.editors.SolvoyoMultipleEditor =
  window.JSONEditor.defaults.editors.multiple.extend({
    build() {
      this._super()

      if (this.options.readonly || this.options.readOnly) {
        this.switcher.disabled = true
      }
    }
  })

export default {
  name: 'SolvoyoMultipleEditor',
  format: 'SolvoyoMultipleEditor'
}
