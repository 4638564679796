/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import { Component } from 'react'
import { first, isEqual, size, debounce } from 'lodash'
import { FormControl } from 'react-bootstrap'
import { SlvySelect } from '@/components'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

const DEFAULT_AC_LENGTH = 3

function ClearIndicator() {
  return <i className="fa fa-times" />
}
class TextField extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.eventChange = this.eventChange.bind(this)
    this.setText = this.setText.bind(this)
    this.state = { text: '', select: '', livePluginData: [], isLoading: false, isDisabled: false }
    this.queue = []
  }

  UNSAFE_componentWillMount() {
    this.eventChange = this.props.registerEvent({
      key: 'onChange',
      fn: this.eventChange,
      returnTypes: {
        text: PluginTypes.fromString('string'),
        id: PluginTypes.fromString('string'),
        valueEntered: PluginTypes.fromString('bool'),
        noValue: PluginTypes.fromString('bool')
      }
    })
    this.props.registerMethod({
      key: 'setText',
      fn: this.setText,
      args: [
        { name: 'text', type: PluginTypes.string },
        { name: 'id', type: PluginTypes.string }
      ]
    })
    this.props.registerMethod({
      key: 'setReadOnlyEnabled',
      fn: this.setReadOnlyEnabled.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setReadOnlyDisabled',
      fn: this.setReadOnlyDisabled.bind(this),
      args: []
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      pluginData = [],
      settings: { config: { settings: { text } = {}, data: { textColumn } = {} } = {} } = {}
    } = this.props

    if (textColumn) {
      const { pluginData: nextPluginData = [] } = nextProps
      if (!isEqual(pluginData, nextPluginData)) {
        const { [textColumn]: nextText = '' } = first(nextPluginData) || {}
        this.setState({
          text: nextText
        })
      }
    } else if (text) {
      const { settings: { config: { settings: { text: nextText } = {} } = {} } = {} } = nextProps
      if (!isEqual(text, nextText)) {
        this.setState({
          text: nextText
        })
      }
    }
  }

  setReadOnlyDisabled() {
    this.setState({
      isDisabled: true
    })
  }

  setReadOnlyEnabled() {
    this.setState({
      isDisabled: false
    })
  }

  setText({ text = '' }) {
    this.setState({
      text
    })
    this.eventChange({ text })
  }

  eventChange({ text = '', id } = {}) {
    const has = size(text) > 0
    return { text, valueEntered: has, noValue: !has, ...(id ? { id } : {}) }
  }

  interval = 0

  setEventChange = ({ text, id }) => {
    this.setState({
      text
    })
    clearTimeout(this.interval)
    this.interval = setTimeout(() => {
      this.eventChange({ text, id })
    }, 500)
  }

  handleChange = (event) => {
    const text = event.currentTarget.value
    this.setEventChange({ text })
  }

  fetchLiveData = debounce((text) => {
    this.queue.push(text)

    const settings = this.props.settings || {}
    const autocompleteColumn = settings?.config?.data?.autocompleteColumn || ''
    const argumentName = settings.config?.data?.argumentName || ''
    const valueFilters = settings.query.valueFilters || []
    const newArguments = {}

    this.setState({
      text,
      isLoading: true
    })

    if (argumentName && autocompleteColumn) {
      const foundArgument = valueFilters.filter((item) => {
        return item.argumentName === argumentName
      })

      if (foundArgument.length > 0) {
        newArguments[argumentName] = text
      }
    } else if (autocompleteColumn) {
      newArguments[autocompleteColumn] = text
    }

    const client = this.props?.client
    const pluginId = this.props.id
    const clientUrl = `/data/${pluginId}`

    client
      .post(clientUrl, {
        data: {
          id: pluginId,
          arguments: newArguments
        }
      })
      .then(({ result: livePluginData }) => {
        this.queue.splice(this.queue.indexOf(text), 1)
        this.setState({ livePluginData, isLoading: false })
      })
  }, 250)

  handleAutocompleteChange = (text) => {
    const autocompleteLength =
      this.props.settings?.config?.settings?.autocompleteLength || DEFAULT_AC_LENGTH

    if (text && text.length >= autocompleteLength) {
      this.fetchLiveData(text)
    } else {
      const processQueue = () => {
        if (this.queue.length > 0) {
          setTimeout(processQueue, 0)
        } else {
          this.setState({ livePluginData: [], text: '' })
        }
      }
      processQueue()
    }
  }

  acOnChange = (item) => {
    const select = item?.value
    const id = item?.id
    this.setState({ select, ...(item === null ? { livePluginData: [] } : {}) })
    this.setEventChange({ select, id })
  }

  render() {
    const { text = '', select = '', isLoading = false, isDisabled = false } = this.state
    const {
      settings: {
        config: {
          data: { autocompleteColumn, id: idColumn } = {},
          settings: { placeHolder = 'Enter text', autocompleteLength = DEFAULT_AC_LENGTH } = {}
        } = {}
      } = {}
    } = this.props

    const livePluginData = text.length < autocompleteLength ? [] : this.state?.livePluginData

    let autocompleteOptions = []
    if (autocompleteColumn && livePluginData.length > 0) {
      autocompleteOptions = livePluginData.reduce((arr, item) => {
        const value = item[autocompleteColumn]
        const id = item[idColumn]

        if (value) {
          arr.push({
            label: value,
            value,
            id
          })
        }

        return arr
      }, [])
    } else {
      autocompleteOptions = []
    }

    return (
      <div className="textfield-plugin p-1 position-relative">
        {!autocompleteColumn ? (
          <FormControl
            disabled={isDisabled}
            placeholder={placeHolder}
            type="text"
            value={text}
            onChange={this.handleChange}
          />
        ) : (
          <>
            {isDisabled ? (
              <div
                className="h-100 w-100 position-absolute"
                style={{ zIndex: 999999, backgroundColor: 'rgba(255,255,255, 0.1)' }}
              />
            ) : null}
            <SlvySelect
              isClearable
              className="autocomplete-field"
              components={ClearIndicator}
              isLoading={isLoading}
              menuPortalTarget={document.body}
              options={autocompleteOptions}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 999999999 }) }}
              value={select?.value}
              onChange={this.acOnChange}
              onInputChange={this.handleAutocompleteChange}
            />
          </>
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  data: props.data,
  client: props.client,
  id: props.id
})

export default createPlugin(TextField, selectConnectorProps)
