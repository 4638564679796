import { DragEvent, useEffect, useRef, useState, useMemo } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '@/hooks/store'
import { CHANGE_CONNECTION } from '@/libs/workflow'
import { Permission, SlvySelect, SlvySelectStyles } from '..'
import { movePageToMenu, clearPageModel } from '../../actions/page'
import { getFirstEnvironmentId, getSavedConnectionEnvironmentId } from './helpers'
import MenuItem from './MenuItem'
import Profile from './Profile'
import CatalogName from './CatalogName'
import FavoriteMenus from './FavoriteMenus'
import { CONNECTION_ENVIRONMENT } from './constants'
import { IMenu, INavigationProps } from './Navigation.types'

type DefaultEnvType = string | number

const Navigation = ({
  catalog,
  catalogId,
  customerEnvironments = [],
  environment: propsEnv,
  menuId,
  menus,
  storeIndex = '0',
  user,
  userSetting = [],
  addFavorite,
  setConnectionEnvironment
}: INavigationProps) => {
  const [{ favoriteMenuItems = [] } = {}] = userSetting

  const lsKey = `${CONNECTION_ENVIRONMENT}.${catalog?.customerId ?? ''}`

  const [currentMenuId, setCurrentMenuId] = useState<DefaultEnvType>(0)

  const lastSetEnvironment = useRef<DefaultEnvType>(0)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const connectionEnvironment = useAppSelector((state) => state.setting.connectionEnvironment)
  const isConnectionChangeActive = useAppSelector((state) => state.setting.isConnectionChangeActive)

  const isConfiguration = propsEnv === 'Configuration'

  const firstEnvironmentId = getFirstEnvironmentId(customerEnvironments)

  const currentConnectionEnvironmentId: number = useMemo(
    () => getSavedConnectionEnvironmentId(lsKey),
    [lsKey]
  )

  const defaultEnv: DefaultEnvType = currentConnectionEnvironmentId || firstEnvironmentId

  useEffect(() => {
    setConnectionEnvironment(currentConnectionEnvironmentId)
  }, [currentConnectionEnvironmentId, setConnectionEnvironment])

  useEffect(() => {
    if (lastSetEnvironment.current !== defaultEnv) {
      lastSetEnvironment.current = defaultEnv
      setConnectionEnvironment(defaultEnv)
    }
  }, [defaultEnv, setConnectionEnvironment])

  const handleFavoriteClick = (newMenuId: string) => {
    const filteredItemIndex = favoriteMenuItems.indexOf(newMenuId)

    if (filteredItemIndex > -1) {
      favoriteMenuItems.splice(filteredItemIndex, 1)
    } else {
      favoriteMenuItems.push(newMenuId)
    }

    addFavorite(catalogId, { catalogId, favoriteMenuItems })
  }

  const handleConnectionEnvironmentChange = (selected: {
    label: string
    value: DefaultEnvType
  }) => {
    localStorage.setItem(lsKey, String(selected.value))
    setConnectionEnvironment(selected.value)

    if (!isConnectionChangeActive) {
      navigate(0)
      return
    }

    dispatch({ type: CHANGE_CONNECTION })
  }

  const handleDragOver = (event: DragEvent<HTMLLIElement>) => event.preventDefault()

  const handleDrop = (newMenuId: string, event: DragEvent<HTMLLIElement>) => {
    event.preventDefault()

    const pageId = event.dataTransfer.getData('pageId')
    const pageMenuId = event.dataTransfer.getData('menuId')

    if (!isConfiguration || !pageId || pageMenuId === newMenuId) {
      return
    }

    dispatch(movePageToMenu(catalogId, pageId, newMenuId))

    setTimeout(() => dispatch(clearPageModel()), 1000)
  }

  const handleCurrentMenuClick = (id: string) => {
    setCurrentMenuId((prevCurrentMenuId: DefaultEnvType) => (prevCurrentMenuId === id ? 0 : id))
  }

  const nextStoreIndex = parseInt(storeIndex, 10) + 1

  let favoriteListMenu: IMenu[] = []

  favoriteMenuItems.forEach((item) => {
    menus.forEach((subItem: IMenu) => {
      if (subItem.item.id === item) {
        favoriteListMenu.push(subItem)
      }
      subItem.children.forEach((childItem: IMenu) => {
        if (childItem.item.id === item) {
          favoriteListMenu.push(childItem)
        }
      })
    })
  })

  favoriteListMenu = _.filter(favoriteListMenu, (i) => i.item.isVisible || isConfiguration)

  const isFavoriteListMenuExist = favoriteListMenu.length > 0

  const getFilteredAndSortedMenus = () => {
    return _.filter(
      _.sortBy(menus, (menu) => {
        return menu.item.sortOrder
      }),
      (o) => o.item.isVisible || isConfiguration
    )
  }

  const isCurrentChildren = (
    newMenuId: string | undefined,
    item: IMenu['item'],
    children: IMenu[]
  ) => {
    return (
      newMenuId === item.id ||
      currentMenuId === item.id ||
      !_.isEmpty(
        _.find(
          _.filter(children, (o) => o.item.isVisible || isConfiguration),
          (c) => c.item.id === newMenuId
        )
      )
    )
  }

  const getFilteredChildren = (children: IMenu[]) => {
    return _.filter(children, (o) => o.item.isVisible || isConfiguration)
  }

  const mappedCustomerEnvironments = _.map(
    _.sortBy(customerEnvironments, (env) => {
      return env?.environmentOrder ?? 0
    }),
    (item) => {
      const { id = 0, environment = '', displayName } = item
      return {
        value: id,
        label: displayName || environment
      }
    }
  )

  const getSelectedValue = () => {
    if (mappedCustomerEnvironments.length) {
      const selected = mappedCustomerEnvironments.find(
        (item) => item.value === connectionEnvironment
      )
      return selected ?? mappedCustomerEnvironments[0]
    }
    return null
  }

  const isMenuExist = menus && menus.length > 0
  const filteredAndSortedMenus = getFilteredAndSortedMenus()

  return (
    <div className="left-bar" data-testid="navigation">
      <div className="left-bar__sidebar default-scrollbar">
        <ListGroup className="list-group">
          <ListGroupItem>
            <Profile
              catalogId={catalogId}
              environment={propsEnv}
              familyName={user.profile.family_name}
              name={user.profile.name}
              sub={user.profile.sub}
            />
            {catalog ? (
              <CatalogName
                catalogId={catalogId}
                className={cx({ 'mb-0': !isMenuExist })}
                environment={propsEnv}
                name={catalog.name}
              />
            ) : null}
            {!_.isEmpty(customerEnvironments) ? (
              <div className="sidebar__select">
                {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                <form role="form">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="d-none" htmlFor="connectionEnvironment">
                    Connection Environment
                  </label>
                  <SlvySelect
                    aria-label="Connection Environment"
                    inputId="connectionEnvironment"
                    name="connectionEnvironment"
                    options={mappedCustomerEnvironments}
                    styles={SlvySelectStyles.small}
                    value={getSelectedValue()}
                    onChange={handleConnectionEnvironmentChange}
                  />
                </form>
              </div>
            ) : null}
          </ListGroupItem>

          {isFavoriteListMenuExist ? (
            <>
              <div>
                <span className="sidebar__section-title">FAVORITES</span>
                <FavoriteMenus
                  environment={propsEnv}
                  handleOnClick={(id: string) => setCurrentMenuId(id)}
                  menus={favoriteListMenu}
                  storeIndex={nextStoreIndex}
                />
              </div>
              <strong className="sidebar__section-title">MAIN MENU</strong>
            </>
          ) : null}

          {filteredAndSortedMenus.length > 0
            ? _.map(filteredAndSortedMenus, ({ item, children }, index) => {
                let className =
                  menuId === item.id ||
                  currentMenuId === item.id ||
                  !_.isEmpty(_.find(children, (child) => child.item.id === menuId))
                    ? 'active'
                    : ''

                if (!item.isVisible) {
                  className += ' text-decoration-line-through '
                }

                const isFavorite =
                  favoriteMenuItems.find((favoriteMenuItem) => favoriteMenuItem === item.id) !==
                  undefined

                const hasChildren = _.size(children) > 0
                const skipItem = hasChildren || menuId === item.id

                const arrowClasses = cx(
                  'arrow',
                  'fas',
                  `fa-chevron-${className === 'active' ? 'down' : 'left'}`
                )

                return (
                  <MenuItem
                    key={item.id}
                    classes={className}
                    environment={propsEnv}
                    skipItem={skipItem}
                    onDragOver={handleDragOver}
                    onDrop={(event: DragEvent<HTMLLIElement>) => handleDrop(item.id, event)}
                  >
                    {hasChildren ? (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <a
                          data-testid={`navigation-item-drilldown-btn-${index}`}
                          onClick={() => handleCurrentMenuClick(item.id)}
                        >
                          <i className={item.iconClass} />
                          <span className="nav-label">{item.name}</span>
                          <span className={arrowClasses} />
                        </a>
                      </>
                    ) : (
                      <div className="section-item-list">
                        <Link
                          to={`/${propsEnv}/catalog/${item.catalogId}/store/${nextStoreIndex}/menu/${item.id}`}
                          onClick={() => setCurrentMenuId(item.id)}
                        >
                          <i className={item.iconClass} />
                          <span className="nav-label">{item.name}</span>
                        </Link>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <span
                          className="fav-item"
                          data-testid={`navigation-mark-as-favorite-element-${index}`}
                          onClick={() => handleFavoriteClick(item.id)}
                        >
                          <i className={cx('fa', `fa fa-star${isFavorite ? '' : '-o'}`)} />
                        </span>
                      </div>
                    )}
                    {hasChildren && isCurrentChildren(menuId, item, children) ? (
                      <ListGroup
                        className="second-level-sidebar"
                        data-testid={`navigation-second-level-sidebar-${index}`}
                      >
                        {_.map(getFilteredChildren(children), ({ item: child }: IMenu) => {
                          const isChildrenFavorite =
                            favoriteMenuItems.find(
                              (favoriteMenuItem) => favoriteMenuItem === child.id
                            ) !== undefined

                          const classes = cx({
                            active: menuId === child.id,
                            'text-decoration-line-through': !child.isVisible
                          })

                          const favIconClasses = cx(
                            'fa',
                            `fa-star${!isChildrenFavorite ? '-o' : ''}`
                          )

                          return (
                            <MenuItem
                              key={child.id}
                              classes={classes}
                              environment={propsEnv}
                              onDragOver={handleDragOver}
                              onDrop={(event: DragEvent<HTMLLIElement>) =>
                                handleDrop(child.id, event)
                              }
                            >
                              <div className="section-item-list">
                                <Link
                                  to={`/${propsEnv}/catalog/${item.catalogId}/store/${nextStoreIndex}/menu/${child.id}`}
                                >
                                  <i className={child.iconClass} /> {child.name}
                                </Link>
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <span
                                  className="fav-item"
                                  onClick={() => handleFavoriteClick(child.id)}
                                >
                                  <i className={favIconClasses} />
                                </span>
                              </div>
                            </MenuItem>
                          )
                        })}
                      </ListGroup>
                    ) : null}
                  </MenuItem>
                )
              })
            : null}
          <Permission has={['Menu.Add']}>
            {!isMenuExist ? (
              <ListGroupItem>
                <Link to={`/${propsEnv}/catalog/${catalogId}/menu/add`}>
                  <span className="nav-label">Create MenuItem</span>
                </Link>
              </ListGroupItem>
            ) : null}
          </Permission>
        </ListGroup>
      </div>
    </div>
  )
}

export default Navigation
