export const CURRENCIES_LOCALES = {
  'tr-TR': {
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 2
  },
  'en-US': {
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2
  },
  'en-TR': {
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 2
  },
  'en-PL': {
    thousandSeparator: ' ',
    decimalSeparator: ',',
    decimalScale: 2
  },
  'en-SG': {
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2
  },
  'en-GB': {
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2
  },
  'en-AU': {
    thousandSeparator: ' ',
    decimalSeparator: '.',
    decimalScale: 2
  }
}
