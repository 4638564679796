import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from '../appAPI'

const initialState = {
  isShowCreateAssortment: false,
  isShowSaveAsAssortment: false,
  isShowTestProducts: false,
  loader: {
    isShown: false,
    messages: 'Loading!'
  },
  scenarioView: true,
  seasonValues: [],
  seasonValuesLoading: false,
  seasonValuesError: {},
  viewType: 'optionView'
}

export const getFilter = createAsyncThunk('app/getFilter', async (action) => {
  try {
    const response = await makeRequest(action)
    // The value we return becomes the `fulfilled` action payload
    return JSON.parse(response.text)
  } catch (err) {
    throw err
  }
})

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    onCreateScenarioToggle: (state, action) => {
      state.isShowCreateAssortment = action.payload
    },
    setLoaderReducer: (state, action) => {
      const { isShown = true, messages = '' } = action.payload
      state.loader.isShown = isShown
      state.loader.messages = messages
    },
    setViewType: (state, action) => {
      state.viewType = action.payload
    },
    setScenarioView: (state, action) => {
      state.scenarioView = action.payload
    },
    showSaveAsAssortment: (state, action) => {
      state.isShowSaveAsAssortment = action.payload
    },
    showTestProducts: (state, action) => {
      state.isShowTestProducts = action.payload
    },
    userExit: (state, action) => {
      state = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilter.pending, (state, action) => {
        state.seasonValues = []
        state.seasonValuesLoading = true
        state.seasonValuesError = {}
      })
      .addCase(getFilter.fulfilled, (state, action) => {
        state.seasonValues = action?.payload?.Values
        state.seasonValuesLoading = false
        state.seasonValuesError = {}
      })
      .addCase(getFilter.rejected, (state, action) => {
        state.seasonValues = []
        state.seasonValuesLoading = false
        state.seasonValuesError = { ...action.payload }
      })
  }
})

export const {
  onCreateScenarioToggle,
  setLoaderReducer,
  setViewType,
  setScenarioView,
  showSaveAsAssortment,
  showTestProducts,
  userExit
} = appSlice.actions

export const isShowCreateAssortment = (state) => {
  state.isShowCreateAssortment
}
export const isShowSaveAsAssortment = (state) => {
  state.isShowSaveAsAssortment
}
export const isShowTestProducts = (state) => {
  state.isShowTestProducts
}
export const loader = (state) => {
  state.loader
}
export const scenarioView = (state) => {
  state.scenarioView
}
export const seasonValues = (state) => {
  state.seasonValues
}
export const seasonValuesLoading = (state) => {
  state.seasonValuesLoading
}
export const seasonValuesError = (state) => {
  state.seasonValuesError
}
export const viewType = (state) => {
  state.viewType
}

export default appSlice.reducer
