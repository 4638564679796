export default () => ({
  title: 'Matrix Image',
  type: 'object',
  format: 'objectTab',
  properties: {
    general: {
      type: 'object',
      title: 'General',
      properties: {
        name: {
          type: 'string',
          title: 'Name'
        },
        header: {
          type: 'string',
          title: 'Header'
        },
        maximizable: {
          type: 'boolean',
          title: 'Maximizable',
          default: false,
          required: true
        }
      }
    },
    settings: {
      type: 'object',
      title: 'Settings',
      properties: {
        fieldsSectionHeader: {
          type: 'string',
          title: 'Fields Section Header'
        },
        imageBoxNumberInRow: {
          title: 'Image Number In Row',
          default: 3,
          type: 'number',
          required: true
        },
        exportButtonIsActive: {
          type: 'boolean',
          title: 'Pdf Export Is Active',
          default: true,
          required: true
        },
        paginationIsActive: {
          type: 'boolean',
          title: 'Pagination Is Active',
          default: true,
          required: true
        },
        rowNumberInPage: {
          type: 'integer',
          title: 'Row Number In Page',
          default: '1'
        },
        paginationMenuPlacement: {
          type: 'string',
          title: 'Pagination Menu Placement',
          default: 'Top',
          enum: ['Top', 'Bottom', 'Top & Bottom'],
          required: true
        },
        imageHeight: {
          type: 'string',
          title: 'Image Height * 100 px',
          enum: [1, 2, 3, 4, 5, 6, 'Keep Aspect Ratio'],
          default: 3,
          required: true
        },
        imageFit: {
          type: 'string',
          title: 'Image Fit',
          default: 'fill',
          enum: ['contain', 'cover', 'fill'],
          required: true
        },
        imageBackground: {
          type: 'string',
          title: 'Image Background',
          default: 'transparent',
          required: true,
          description: 'You can put transparent or blue, red,grey..etc'
        }
      }
    },
    datafields: {
      type: 'array',
      format: 'HiddenTable',
      title: 'Data Fields',
      items: {
        type: 'string'
      }
    },
    imageFields: {
      type: 'array',
      title: 'Image Fields',
      items: {
        title: 'Field',
        type: 'object',
        properties: {
          label: {
            type: 'string',
            title: 'Label'
          },
          dataField: {
            type: 'string',
            title: 'Data Field',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    },
    data: {
      type: 'object',
      title: 'Data',
      properties: {
        fieldsSectionHeader: {
          type: 'string',
          title: 'Fields Section Header',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        },
        imageBoxId: {
          type: 'string',
          title: 'Image Box Id',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        },
        imageURL: {
          type: 'string',
          title: 'Image URL',
          watch: {
            ds: 'datafields'
          },
          enumSource: 'ds'
        }
      }
    }
  }
})
