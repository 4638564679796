import { useCallback, useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { demandTreeTableSlice, tuiSlice } from '../../../store/slices'

function useUpdateDemandTree({ demandTreeTableRef }) {
  const [{ registerMethod, setDataArguments }] = useReduxState(tuiSlice.selectSlice)
  const [, demandTreeTableDispatch] = useReduxState(undefined, demandTreeTableSlice.actions)

  const handleUpdateLoadTree = useCallback(() => {
    setDataArguments(null, true)

    demandTreeTableDispatch.setFacilityNames([])
    demandTreeTableRef.current?.setState((prevState) => ({
      ...prevState,
      rowSelection: {},
      expanded: {}
    }))
  }, [demandTreeTableDispatch, demandTreeTableRef, setDataArguments])

  useEffect(() => {
    registerMethod({
      args: [{ name: 'refreshKey', type: PluginTypes.string }],
      key: 'updateDemandTree',
      fn: handleUpdateLoadTree
    })
  }, [handleUpdateLoadTree, registerMethod])
}

export default useUpdateDemandTree
