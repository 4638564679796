import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { useAppSelector } from '@/hooks/store'
import { environmentPermissions } from './constants'
import { PermissionProps, HasTypes, Node } from './Permission.types'

const Permission = ({ children, has, hasAny = [] }: PermissionProps) => {
  // eslint-disable-next-line camelcase
  const { access_token } = useAppSelector((state) => state.oidc.user)!
  const environment = useAppSelector((state) => state.setting.environment)

  const isConfiguration = environment === 'Configuration'

  const session: any = jwtDecode(access_token)

  if (environment !== 'Live' && !session.Mode) {
    return null
  }

  let node: Node = null
  if (has) {
    const invalidSize = has
      .map((item: HasTypes) => {
        const g = item.split('.')
        if (isConfiguration || _.includes(environmentPermissions[item], environment)) {
          const firstPart = session[g[0]]
          const hasRight = _.isNil(firstPart && firstPart.indexOf(g[1]) !== -1)
            ? false
            : firstPart && firstPart.indexOf(g[1]) !== -1

          return hasRight
        }

        return false
      })
      .filter((i) => i === false).length
    node = invalidSize === 0 ? children : null
  }
  if (node === null && hasAny) {
    // @ts-ignore
    // eslint-disable-next-line consistent-return
    _.forEach(hasAny, (item: HasTypes): boolean | undefined => {
      const [right, actionName] = item.split('.')
      if (isConfiguration || _.includes(environmentPermissions[item], environment)) {
        if (_.includes(session[right], actionName)) {
          node = children
          return false
        }
      }
    })
  }
  return node
}

export default Permission
