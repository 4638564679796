Ext.define('Spui.view.common.productinfo.cmp.tabpanel.Inventory.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-tabpanel-inventory-main',

  mixins: ['Spui.view.common.productinfo.cmp.tabpanel.Inventory.Mixin'],
  defaultListenerScope: true,
  layout: 'fit'
})
