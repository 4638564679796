Ext.define('Spui.view.wizard.pages.spotparams.Main', {
  extend: 'Ext.Container',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-wizard-pages-spotparams-main',

  layout: {
    type: 'hbox',
    pack: 'start',
    align: 'stretch'
  },
  style: {
    padding: '2px'
  },

  items: [
    {
      xtype: 'spui-view-common-productinfo-main',
      flex: 1,
      border: true,
      searchBox: {
        //  hidden:true,
      },
      bind: {
        materialRecord: '{materialRecord}',
        materialCode: '{spotRecord.MaterialCode}'
      },

      margin: '5 5 5 5'
    },
    {
      xtype: 'spui-view-common-spotparameters-main',
      flex: 1,
      margin: '5 5 5 0'
    }
  ]
})
