import cx from 'classnames'
import { useTheme } from '../../contexts/ThemeContext'
import { Dropdown } from 'react-bootstrap'
import ColumnMenu from './'

export default function ColumnMenuItem({
  name,
  icon,
  subMenu,
  isSeparator,
  columns,
  disabled,
  onClick
}) {
  const styles = useTheme()

  return isSeparator ? (
    <Dropdown.Divider />
  ) : (
    <Dropdown.Item
      as="li"
      className={cx(styles.columnMenuItem, { [styles.isDisabled]: disabled })}
      onClick={onClick}
    >
      {subMenu?.length > 0 ? (
        <>
          {typeof icon === 'string' ? <i className={icon} /> : icon}
          <ColumnMenu
            items={subMenu}
            toggler={name}
            trigger="hover"
            drop="end"
            isSubMenu={true}
            columns={columns}
          />
        </>
      ) : (
        <>
          {typeof icon === 'string' ? <i className={icon} /> : icon}
          {name}
        </>
      )}
    </Dropdown.Item>
  )
}
