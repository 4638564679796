import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import { ButtonGroup as BootstrapButtonGroup, Dropdown, Button } from 'react-bootstrap'
import '../index.scss'

const portalContainer = document.getElementsByTagName('body')[0]

export default class CustomMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rect: {},
      buttonGroupContainerWidth: 0,
      isOpen: false
    }
    this.buttonHandles = {}
    this.handleClick = this.handleClick.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this)
  }

  handleClick(buttonName) {
    return this.props.onClickButtonHandle(buttonName)
  }

  handleWindowResize() {
    this.setState({ isOpen: false })
  }

  openConfirmationPopup(text, confirmationTitle, confirmationText) {
    return (e) => {
      e.preventDefault()
      confirmAlert({
        title: confirmationTitle,
        message: confirmationText,
        buttons: [
          {
            label: 'Cancel',
            onClick: () => false
          },
          {
            label: 'OK',
            onClick: () => this.handleClick(text)
          }
        ]
      })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  onToggleHandler = (isOpen, target) => {
    const rect = target.getBoundingClientRect() || {}

    this.setState({ isOpen, rect }, () => {
      if (isOpen) {
        const { width: buttonGroupContainerWidth } =
          this.refs.buttonGroupContainer.getBoundingClientRect()
        this.setState({ buttonGroupContainerWidth })
      }
    })
  }

  getPortal(tmp) {
    return createPortal(tmp, portalContainer)
  }

  render() {
    const {
      groupKey,
      buttonState,
      groupConfig,
      buttonGroupConfig,
      splitButtonStyle,
      splitButtonTitle,
      splitButtonIcon,
      splitButtonIconPosition,
      splitConfirmation,
      splitConfirmationTitle,
      splitConfirmationText
    } = this.props

    const {
      textAlign: groupTitleTextAlign = 'center',
      style: groupTitleStyle = 'success',
      groupButtonsTextAlign = 'center'
    } = buttonGroupConfig

    const reducedGroupConfig = _.slice(groupConfig, 1)

    const {
      rect: { left = 0, top = 0, height = 0, width = 0 },
      buttonGroupContainerWidth,
      isOpen = false
    } = this.state

    const { innerWidth } = window
    const calculatedWidth = left + innerWidth - width
    const isOverflow = calculatedWidth > innerWidth

    const boxStyle = {
      left: isOverflow ? left - (buttonGroupContainerWidth - width) : left,
      top: top + height,
      minWidth: width - 3
    }

    if (splitButtonTitle != null) {
      const splitButtonDisabled = _.get(buttonState, [[splitButtonTitle], 'disabled']) || false

      return (
        <Dropdown
          ref="bsSplitButtonItem"
          as={BootstrapButtonGroup}
          className="btn-group bs-button-item button-group-split poly-group-ctn"
          onClick={
            splitConfirmation
              ? this.openConfirmationPopup(
                  splitButtonTitle,
                  splitConfirmationTitle,
                  splitConfirmationText
                )
              : () => this.handleClick(splitButtonTitle)
          }
          onToggle={(isOpen) => this.onToggleHandler(isOpen, this.refs.bsSplitButtonItem)}
        >
          <Button
            className={`buttonFilter button-split-ctn ${groupTitleTextAlign}`}
            disabled={splitButtonDisabled}
            id={`split-button-basic-${groupKey}`}
            variant={splitButtonStyle}
          >
            <span>
              {splitButtonIconPosition === 'Left' ? (
                <i className={`fa ${splitButtonIcon}`} />
              ) : null}
              {` ${splitButtonTitle} `}
              {splitButtonIconPosition === 'Right' ? (
                <i className={`fa ${splitButtonIcon}`} />
              ) : null}
            </span>

            {isOpen
              ? this.getPortal(
                  <div
                    ref="buttonGroupContainer"
                    className="button-group-plugin-portal-ctn"
                    style={boxStyle}
                  >
                    {_.map(reducedGroupConfig, (buttonConfig, index) => {
                      const {
                        text = '',
                        style = 'primary',
                        icon = 'fa-check',
                        iconPosition = 'Left',
                        seperator = 'none',
                        confirmation = false,
                        confirmationTitle = 'Confirmation',
                        confirmationText = 'Are you sure to continue?'
                      } = buttonConfig
                      const that = this
                      const disabledButton = _.get(buttonState, [[text], 'disabled']) || false
                      return (
                        <div key={`menuItem${index}`} className={`btn-text-style ${style}`}>
                          {seperator === 'top' ? <hr className="seperator-prop" /> : null}
                          <Button
                            key={groupKey}
                            className={`btnGroup ${groupButtonsTextAlign}`}
                            disabled={disabledButton}
                            id={`split-button-basic-${groupKey}`}
                            type="button"
                            variant={style}
                            onClick={
                              confirmation
                                ? that.openConfirmationPopup(
                                    text,
                                    confirmationTitle,
                                    confirmationText
                                  )
                                : () => that.handleClick(text)
                            }
                          >
                            {iconPosition === 'Left' ? <i className={`fa ${icon}`} /> : null}
                            {` ${text} `}
                            {iconPosition === 'Right' ? <i className={`fa ${icon}`} /> : null}
                          </Button>
                          {seperator === 'bottom' ? <hr className="seperator-prop" /> : null}
                        </div>
                      )
                    })}
                  </div>
                )
              : null}
          </Button>

          {/*
            If you do not put Dropdown.Menu to the DOM, outside close does not work.
            Because onToggle does not fire!
            DO NOT REMOVE Dropdown.Menu
          */}
          <Dropdown.Menu className="d-none" />

          <Dropdown.Toggle split disabled={splitButtonDisabled} variant={splitButtonStyle} />
        </Dropdown>
      )
    }
    return (
      <Dropdown
        ref="bsDropdownButtonItem"
        as={BootstrapButtonGroup}
        className="bs-button-item button-group-dropdown poly-group-ctn"
        onToggle={(isOpen) => this.onToggleHandler(isOpen, this.refs.bsDropdownButtonItem)}
      >
        {/*
            If you do not put Dropdown.Menu to the DOM, outside close does not work.
            Because onToggle does not fire!
            DO NOT REMOVE Dropdown.Menu
          */}
        <Dropdown.Menu className="d-none" />

        <Dropdown.Toggle
          className={`${groupTitleTextAlign} button-dropdown-ctn poly-drop-btn`}
          id={`dropdown-basic-${_.lowerCase(groupKey)}`}
          variant={groupTitleStyle}
        >
          {groupKey}
          {isOpen
            ? this.getPortal(
                <div
                  ref="buttonGroupContainer"
                  className="button-group-plugin-portal-ctn"
                  style={boxStyle}
                >
                  {_.map(groupConfig, (buttonConfig, index) => {
                    const {
                      style = 'primary',
                      text = '',
                      seperator = 'none',
                      icon = 'fa-check',
                      iconPosition = 'Left',
                      confirmation = false,
                      confirmationTitle = 'Confirmation',
                      confirmationText = 'Are you sure to continue?'
                    } = buttonConfig

                    const that = this
                    const disabledButton = _.get(buttonState, [[text], 'disabled']) || false

                    return (
                      <div key={`menuItem${index}`} className={`btn-text-style ${style}`}>
                        {seperator === 'top' ? <hr className="seperator-prop" /> : null}
                        <Button
                          className={`btnGroup ${groupButtonsTextAlign}`}
                          disabled={disabledButton}
                          type="button"
                          variant={style}
                          onClick={
                            confirmation
                              ? that.openConfirmationPopup(
                                  text,
                                  confirmationTitle,
                                  confirmationText
                                )
                              : () => that.handleClick(text)
                          }
                        >
                          {iconPosition === 'Left' ? <i className={`fa ${icon}`} /> : null}
                          {` ${text} `}
                          {iconPosition === 'Right' ? <i className={`fa ${icon}`} /> : null}
                        </Button>
                        {seperator === 'bottom' ? <hr className="seperator-prop" /> : null}
                      </div>
                    )
                  })}
                </div>
              )
            : null}
        </Dropdown.Toggle>
      </Dropdown>
    )
  }
}
