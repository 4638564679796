import { combineReducers } from 'redux'
import scenarioTree from './scenarioTree'
import scenarioParameters from './scenarioParameters'
import setFilterRemote from './setFilterRemote'
import setFilterTreeRemote from './setFilterTreeRemote'
import scenarioTreeSelection from './scenarioTreeSelection'
import getPivotConfiguration from './getPivotConfiguration'
import defaultActionParams from './defaultActionParams'
import pivotBuilderViewSettings from './pivotBuilderViewSettings'
import pivotViewSettings from './pivotViewSettings'
import pivotDetailViewSettings from './pivotDetailViewSettings'
import pivotSingleDetailViewSettings from './pivotSingleDetailViewSettings'
import scenarioTreeViewSettings from './scenarioTreeViewSettings'
import pivotMainConfiguration from './pivotMainConfiguration'
import scenarioComparisonMainConfiguration from './scenarioComparisonMainConfiguration'
import headerSettings from './headerSettings'
import getPeriods from './getPeriods'
import timelineSettings from './timelineSettings'
import getMfpData from './getMfpData'
import getMfpDetailData from './getMfpDetailData'
import getMfpDetailDataForSingleColumn from './getMfpDetailDataForSingleColumn'
import updateMfpData from './updateMfpData'
import loader from './loader'
import scenarioComparisonSettings from './scenarioComparisonSettings'
import getComparisonConfiguration from './getComparisonConfiguration'
import getComparisonData from './getComparisonData'
import collapse from './collapse'
import mfpSettings from './mfpSettings'
import getMfpCurrencyRates from './getMfpCurrencyRates'
import currencyRatesSettings from './currencyRatesSettings'
import setGenerateReportTree from './setGenerateReportTree'
import subsetViewSettings from './subsetViewSettings'
import getUserScenario from './getUserScenario'
import getQuickFilter from './getQuickFilter'
import quickFilterSettings from './quickFilterSettings'

const appReducer = combineReducers({
  loader,
  scenarioTree,
  scenarioParameters,
  setFilterRemote,
  setFilterTreeRemote,
  scenarioTreeSelection,
  getPivotConfiguration,
  defaultActionParams,
  pivotBuilderViewSettings,
  pivotViewSettings,
  pivotDetailViewSettings,
  pivotSingleDetailViewSettings,
  scenarioTreeViewSettings,
  pivotMainConfiguration,
  headerSettings,
  getPeriods,
  timelineSettings,
  getMfpData,
  getMfpDetailData,
  getMfpDetailDataForSingleColumn,
  updateMfpData,
  scenarioComparisonSettings,
  getComparisonConfiguration,
  getComparisonData,
  scenarioComparisonMainConfiguration,
  collapse,
  mfpSettings,
  getMfpCurrencyRates,
  currencyRatesSettings,
  setGenerateReportTree,
  subsetViewSettings,
  getUserScenario,
  getQuickFilter,
  quickFilterSettings
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_EXIT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
