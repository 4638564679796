export const selectConnectorProps = (props) => ({
  id: props.id,
  settings: props.settings,
  data: props.data,
  pluginData: props.pluginData,
  isMaximized: props.isMaximized,
  onReady: props.onReady,
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  registerAuthorizations: props.registerAuthorizations,
  reloadExtRoot: props.reloadExtRoot,
  query: props.query,
  addRowIndexToLocalData: props.addRowIndexToLocalData,
  actualFilters: props.actualFilters,
  size: props.size,
  isAllowed: props.isAllowed,
  additionalArgs: props.additionalArgs,
  params: props.params,
  token: props.token,
  isPreviewMode: props.isPreviewMode,
  getTotalRowCount: props.getTotalRowCount,
  schema: props.schema,
  updateRowInLocalData: props.updateRowInLocalData,
  reportExportInfo: props.reportExportInfo,
  isPagedQuery: props.isPagedQuery,
  clearCaches: props.clearCaches,
  setDataArguments: props.setDataArguments,
  dequeueUpdateRequest: props.dequeueUpdateRequest,
  enqueueUpdateRequest: props.enqueueUpdateRequest,
  client: props.client,
  deleteRowInLocalData: props.deleteRowInLocalData,
  fetchPageData: props.fetchPageData,
  getPageSize: props.getPageSize,
  getFieldType: props.getFieldType,
  getFormattedValue: props.getFormattedValue,
  formatValue: props.formatValue,
  isPluginDataLoading: props.isPluginDataLoading
})
