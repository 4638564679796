Ext.define('Spui.data.model.Material', {
  extend: 'Spui.data.model.Base',
  entityName: 'Spui/Material',
  idProperty: 'MaterialCode',
  fields: [
    { name: 'SpotId', type: 'int' },
    { name: 'MaterialCode', type: 'string' },
    { name: 'MaterialDescription', type: 'string' },
    { name: 'MaterialGroupCode', type: 'string' },
    { name: 'MaterialGroupName', type: 'string' },
    { name: 'SalesPrice', type: 'number' },
    { name: 'UnitofMeasurement', type: 'string' },
    { name: 'UnitsPerPackage', type: 'number' },
    { name: 'MinQuantity', type: 'number' },
    { name: 'MaxQuantity', type: 'number' },
    {
      name: 'PreStartDate',
      type: 'date',
      dateFormat: Spui.Global.dateFormatShort
    },
    {
      name: 'PreEndDate',
      type: 'date',
      dateFormat: Spui.Global.dateFormatShort
    },
    { name: 'PageIndex', type: 'int' },
    { name: 'PageName', type: 'string' },
    { name: 'CreatedBy', type: 'string' },
    { name: 'CreatedAt', type: 'date', dateFormat: Spui.Global.dateFormatLong },
    { name: 'IsDeleted', type: 'boolean' },
    { name: 'DeletedBy', type: 'string' },
    { name: 'DeletedAt', type: 'date', dateFormat: Spui.Global.dateFormatLong },
    { name: 'CategoryManagerCode', type: 'string' },
    { name: 'PuplishedBy', type: 'string' },
    {
      name: 'PublishDate',
      type: 'date',
      dateFormat: Spui.Global.dateFormatLong
    },
    { name: 'DaysForApprove', type: 'int' },
    { name: 'DaysForTargetSales', type: 'int' },
    { name: 'StatusId', type: 'int' },
    { name: 'ImgURL', type: 'string' },
    { name: 'Notes', type: 'string' },
    {
      name: 'imgURLC',
      convert: function (value, record) {
        var baseURL = Spui.Global.getApiUrl()
        var imgURL = record.get('ImgURL')
        // return "http://limingqian.com/SampleLarge.jpg";

        if (!imgURL) {
          return 'https://s3-eu-west-1.amazonaws.com/slvy-cdn/images/a101/noimage.png'
        } else {
          if (baseURL === '###SPUI_API_URL###') return imgURL

          return baseURL + '/' + imgURL
        }
      },

      depends: ['ImgURL']
    }
  ]
})
