import { transformFilterValue } from '../filter'
import {
  AdvancedDateFilterModel,
  AdvancedDefaultFilterModel,
  ListFilterModel,
  TagAdvancedFilterModel,
  TagTextFilterModel,
  TransformFilterValueParams
} from '../filter.types'

const transformFilterValueParams = (): TransformFilterValueParams => ({
  columnFilterModel: {
    kind: 'default'
  },
  dataType: 'string'
})

describe('AgGrid:Helpers:Filter:transformFilterValue', () => {
  let params = transformFilterValueParams()

  beforeEach(() => {
    params = transformFilterValueParams()
  })

  it('should return filter if kind is tagText', () => {
    params.columnFilterModel.kind = 'tagText'
    const tagTextModel = params.columnFilterModel as TagTextFilterModel
    tagTextModel.filter = 'Basic'
    const result = transformFilterValue(params)
    expect(result).toEqual([tagTextModel.filter])
  })

  it('should return filter if kind is tagAdvanced', () => {
    params.columnFilterModel.kind = 'tagAdvanced'
    const tagAdvancedModel = params.columnFilterModel as TagAdvancedFilterModel
    tagAdvancedModel.conditions = [
      {
        filterType: 'text',
        type: 'contains',
        colId: '2',
        filter: '41'
      },
      {
        filterType: 'text',
        type: 'contains',
        colId: '3',
        filter: 'DK'
      }
    ]
    const result = transformFilterValue(params)
    const expectedResult = tagAdvancedModel.conditions.map((condition) => condition.filter)
    expect(result).toEqual(expectedResult)
  })

  it('should return null if kind is list and dataType is number and value is null', () => {
    params.columnFilterModel.kind = 'list'
    params.dataType = 'int'
    const listModel = params.columnFilterModel as ListFilterModel
    listModel.values = [null]
    const result = transformFilterValue(params)
    expect(result).toEqual(listModel.values)
  })

  it('should return value if kind is list and dataType is number and value is not null', () => {
    params.columnFilterModel.kind = 'list'
    params.dataType = 'int'
    const listModel = params.columnFilterModel as ListFilterModel
    listModel.values = ['1000']

    const result = transformFilterValue(params)
    const expectedResult = Number(listModel.values)
    expect(result).toEqual([expectedResult])
  })

  it('should return value if kind is list and dataType is not number', () => {
    params.columnFilterModel.kind = 'list'
    const listModel = params.columnFilterModel as ListFilterModel
    listModel.values = ['abc']
    const result = transformFilterValue(params)
    expect(result).toEqual(listModel.values)
  })

  it('should return dateForm if kind is date', () => {
    params.columnFilterModel.kind = 'date'
    const dateModel = params.columnFilterModel as AdvancedDateFilterModel
    dateModel.dateFrom = '2024-03-11'
    const result = transformFilterValue(params)
    expect(result).toEqual(dateModel.dateFrom)
  })

  it('should return filter if kind is default', () => {
    const defaultModel = params.columnFilterModel as AdvancedDefaultFilterModel
    defaultModel.filter = 'Basic'
    const result = transformFilterValue(params)
    expect(result).toEqual(defaultModel.filter)
  })
})
