import { useCallback } from 'react'
import { CellClickedEvent } from 'ag-grid-community'
import { useConfirmationPopup } from '../index'
import { DeletedRow, IRowData, TrowDataValue } from '../../types'
import { HandleDeleteRowProps } from './useHandleDeleteRow.types'

function useHandleDeleteRow(props: HandleDeleteRowProps) {
  const {
    deleting,
    setDeletedRows,
    setTotalRowCount,
    setFilteredRowCount,
    doesDirectUpdate,
    triggerSave,
    gridRef
  } = props
  const { openConfirmationPopup } = useConfirmationPopup()

  const handleDeleteRow = useCallback(
    (event: CellClickedEvent<IRowData, TrowDataValue>) => {
      const {
        node: { data, group, rowIndex }
      } = event

      const isGroup = Boolean(group)
      if (isGroup || !data) {
        return
      }

      const { confirmation, confirmationMessage } = deleting

      const executeDelete = () => {
        // TODO: @v2 Keep rowIndex sync
        event.api.applyTransactionAsync({ remove: [data] }, () => {
          const deletedRow = { data, rowIndex }

          const context = gridRef?.current?.api?.getGridOption('context')
          const deletedRows: DeletedRow[] = [...(context?.deletedRows ?? []), deletedRow]
          context.deletedRows = deletedRows

          gridRef?.current?.api?.setGridOption('context', context)
          setDeletedRows(deletedRows)

          setTotalRowCount((prev) => prev - 1)
          setFilteredRowCount((prev) => prev - 1)

          if (doesDirectUpdate && deletedRows.length) {
            triggerSave({ skipUnsavedChanges: true, skipConfirmation: false, updateParameters: [] })
          }
        })
      }

      if (confirmation) {
        openConfirmationPopup('', confirmationMessage, executeDelete)
      } else {
        executeDelete()
      }
    },
    [
      deleting,
      setDeletedRows,
      setTotalRowCount,
      setFilteredRowCount,
      gridRef,
      doesDirectUpdate,
      triggerSave,
      openConfirmationPopup
    ]
  )

  return { handleDeleteRow }
}

export default useHandleDeleteRow
