Ext.define('MDM.src.view.globalapp.Panel.Geo.Dilaog.Region.Window', {
  extend: 'Ext.window.Window',
  modal: true,
  layout: 'border',
  height: 600,
  isTitleVisible: true,
  width: 600,
  bodyPadding: 4,
  controller: 'geo-dialog-controller',
  ui: 'window-inverse',
  title: 'MegaRegion',
  viewModel: {
    data: [
      {
        record: null
      }
    ]
  },
  bbar: {
    xtype: 'statusbar',
    reference: 'basic-statusbar',
    // defaults to use when the status is cleared:
    defaultText: 'Ready',
    // values to set initially:
    text: 'Ready',
    iconCls: 'x-status-valid',
    // any standard Toolbar items:
    items: [
      '-',
      {
        xtype: 'button',
        text: 'Save',
        ui: 'btn-success',
        iconCls: 'fa fa-floppy-o',
        handler: 'onSave',
        bind: {
          disabled: '{!isStoreDirty}'
        }
      },
      {
        xtype: 'button',
        text: 'Cancel',
        ui: 'btn-success',
        handler: 'onCancel',
        iconCls: 'fa fa-ban',
        bind: {
          disabled: '{!isStoreDirty}'
        }
      }
    ]
  },
  items: [
    {
      xtype: 'global-form-megargion',
      region: 'north',
      height: '100'
    },
    {
      xtype: 'panel',
      region: 'center',
      layout: 'fit',
      bodyPadding: 10,
      border: true,
      margin: '5 0 0 0',
      items: {
        xtype: 'globalapp-geo-map',
        region: 'center',
        reference: 'map',
        listeners: {
          dragend: 'onDragend'
        }
      }
    }
  ]
})
