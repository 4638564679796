Ext.define('Spui.view.common.productinfo.cmp.tabpanel.SalesChart.SalesChartMixin', {
  extend: 'Ext.Mixin',
  // Uncomment to give this component an xtype
  mixinId: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-saleschartmixin',
  config: {
    recordData: null,
    chartCfg: 'SalesChart',
    store: {},
    chart: {},
    gridCfg: 'SalesGrid',
    grid: false,
    materialCode: null
  },

  applyRecordData: function (c) {
    return c
  },

  applyChartCfg: function (c) {
    return window.ss(c)
  },

  applyGridCfg: function (c) {
    return window.ss(c)
  },

  applyStore: function (b) {
    var me = this
    if (b && !b.isStore) {
      Ext.applyIf(b, {
        fields: [
          { name: 'QTY', type: 'number' },
          { name: 'Price', type: 'number' },
          { name: 'NumberOfStore', type: 'number' },
          { name: 'Envanter', type: 'number' },
          { name: 'DateKey', type: 'string' },
          {
            name: 'PriceNull',
            convert: function (value, record) {
              var v = record.get('Price')
              if (!v) return null
              return v
            },
            depends: ['Price']
          },
          {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
              var v = record.get('NumberOfStore')

              if (!v) return null
              return v
            },
            depends: ['NumberOfStore']
          }
        ],
        listeners: {
          beforeload: function () {
            me.setLoading(tt('Yükleniyor'))
          },
          load: function () {
            me.setLoading(false)
            me.getChart().draw()
            me.forceToLoadStore = false
          }
        },
        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          data: [
            {
              DateKey: 0
            }
          ],
          url: Spui.Global.getApiUrl() + 'spui/GetSalesData'
        },
        autoLoad: false
      })
      b = Ext.create('Ext.data.Store', b)
    }
    return b
  },

  applyChart: function (b) {
    var setRec = this.getChartCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-saleschart',
        store: this.getStore(),
        record: { test: 'test' },
        listeners: {
          clickChart: 'onClickChart'
        }
      })
      b = Ext.create(b)
    }
    var store = b.store
    if (store.count == 0) store.loadData([{ dtakey: 0 }])
    return b
  },

  updateChart: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  applyGrid: function (b) {
    if (!b) return false
    var setRec = this.getGridCfg()
    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()
    if (b && !b.isComponent) {
      Ext.applyIf(b, res)
      var store = Ext.create('Ext.data.ChainedStore', {
        source: this.getStore()
      })
      Ext.applyIf(b, {
        xtype: 'spui-view-common-productinfo-cmp-tabpanel-saleschart-grid',
        store: store
      })
      b = Ext.create(b)
    }
    return b
  },
  updateGrid: function (f, g) {
    if (!f) return false
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(1, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },

  onConfigureChart: function () {
    var setRec = this.getChartCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigureChartSave, this)
  },

  onConfigureChartSave: function (v, k) {
    var setRec = this.getChartCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setChart(res)
  },

  onConfigureGrid: function () {
    var setRec = this.getGridCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'
    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigureGridSave, this)
  },

  onConfigureGridSave: function (v, k) {
    var setRec = this.getGridCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setGrid(res)
  },

  updateMaterialCode: function (c, d) {
    if (c == d) return
    var store = this.getStore()
    store.getProxy().setExtraParam('MaterialCode', c)
    if (this.isVisible()) {
      store.load()
      this.forceToLoadStore = false
    } else {
      this.forceToLoadStore = true
    }
  },
  onClickChart: function (record) {
    console.log(record)
  }
})
