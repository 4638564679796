import { useCallback, useEffect, useRef } from 'react'
import { PluginTypes } from '@/BasePlugin'
import useReduxState from '../../useReduxState'
import { serviceModalSlice, tuiSlice } from '../../../store/slices'

function useScenarioSelected() {
  const applyButtonRef = useRef<Record<string, () => void | undefined>>({})
  const [{ registerEvent }] = useReduxState(tuiSlice.selectSlice)
  const [serviceModal] = useReduxState(serviceModalSlice.selectSlice)

  const scenarioSelected = useCallback(() => {
    return {
      ScenarioID: serviceModal.selectedService?.ScenarioID,
      ScenarioName: serviceModal.selectedService?.ScenarioName,
      ConnectionID: serviceModal.selectedService?.ConnectionId,
      RunID: serviceModal.selectedService?.RunID,
      RunInfoID: serviceModal.selectedService?.RunInfoID
    }
  }, [
    serviceModal.selectedService?.ConnectionId,
    serviceModal.selectedService?.RunID,
    serviceModal.selectedService?.RunInfoID,
    serviceModal.selectedService?.ScenarioID,
    serviceModal.selectedService?.ScenarioName
  ])

  useEffect(() => {
    const fn = 'handleApplyButtonClick'
    applyButtonRef.current[fn] = registerEvent({
      key: 'scenarioSelected',
      fn: scenarioSelected,
      returnTypes: {
        ScenarioID: PluginTypes.string,
        ScenarioName: PluginTypes.string,
        ConnectionID: PluginTypes.int,
        RunID: PluginTypes.string,
        RunInfoID: PluginTypes.int
      }
    })
  }, [registerEvent, scenarioSelected])

  return { applyButtonRef }
}

export default useScenarioSelected
