export default `({
    listConfig: {
        loadingText: tt('Searching') + '..............',
        loadMask: true,
        emptyText: tt('No matching posts found.'),
        itemSelector: '.search-item',
        // Custom rendering template for each item
        itemTpl: [
            '<div class="search-item" style="font-size:12px" >',
            '<h3 ><span >{MaterialCode} <br /> -</span>{MaterialDescription}</h3>',
            '{H1N}-{H2N}-{H3N}',
            '</div>'
        ],
    },
    autoSelect: false,
    forceSelection: false,
    emptyText: tt('Search'),
    onSearchProduct: function () {},
    triggers: {
        foo: {
            cls: 'x-form-search-trigger',
            weight: 1,
            handler: function () {
                win = Ext.create('SUI.view.wizard.page.initial.Search.Main');
                win.show(a);
            },
            tooltip: tt('search Product')
        },
        //add: {
        //    cls: 'x-form-add-trigger',
        //    weight: 1,
        //    handler: function () {
        //        if (!Spui.data.store.Product) {
        //            Ext.define('Spui.data.store.Product', {
        //                extend: 'Ext.data.Store',
        //                alias: 'store.ProductStore',
        //                storeId: 'ProductStore',
        //                proxy: {
        //                    type: 'ajax',
        //                    url: Spui.Global.getApiUrl() + '/spui/MaterialSearch',
        //                    reader: {
        //                        type: 'json',
        //                    }
        //                }
        //            });
        //            var store = Ext.create('Spui.data.store.Product');
        //        }
        //        if (!Spui.data.model.Product) {
        //            Ext.define('Spui.data.model.Product', {
        //                extend: 'Ext.data.Model',
        //                idProperty: 'MaterialCode',
        //                fields: [{
        //                    type: 'string',
        //                    name: 'MaterialCode'
        //                }]
        //            });
        //        }
        //        if (!Spui.data.model.ManualMaterial) {
        //            Ext.define('Spui.data.model.ManualMaterial', {
        //                extend: 'Spui.data.model.Base',
        //                idProperty: 'MaterialCode',
        //                entityName: 'Spui/ManualAddedMaterial',
        //                fields: [{
        //                        type: 'string',
        //                        name: 'MaterialCode'
        //                    },
        //                    {
        //                        type: 'int',
        //                        name: 'IsEditable'
        //                    }
        //                ]
        //            });
        //        }
        //        var win = Ext.create('Spui.uxWindow', {
        //            bodyPadding: 4,
        //            ui: 'sui-window',
        //            layout: 'fit',
        //            bodyStyle: {
        //                background: '#EDEFF2',
        //            },
        //            maximized: true,
        //            combo: this,
        //            height: '80%',
        //            width: 950,
        //            title: tt('Material Manage'),
        //            uxItemCfg: 'newmaterialedit',
        //        })
        //        win.show();
        //    },
        //    tooltip: tt('New Product')
        //},
    },
})
`
