import { useState, useRef, useEffect, memo } from 'react'
import _ from 'lodash'
import { Tab, Nav } from 'react-bootstrap'
import cx from 'classnames'
import withConnector from '@/BaseContainer'
import { useEvent } from '@/hooks'
import AddElement from '../components/AddElement'
import { View } from '..'
import containerValidation from '@/utils/containerValidation'
import { ElementName, Position, RegisterEventRef } from './TabContainer.types'
import styles from './index.module.scss'

const TabContainer = withConnector((props) => {
  const {
    id,
    plugins,
    containers,
    container: {
      data: {
        settings,
        settings: {
          form: {
            ids = [],
            tabs = [],
            icons = [],
            tabsHidden = false,
            iconsHidden = false,
            iconPosition = 'Right'
          } = {},
          overlays = [],
          hideModeList = [],
          position = {}
        }
      }
    },
    params,
    params: { environment, designMode },
    registerEvent,
    registerMethod,
    showAddPluginLayout,
    onMounted,
    onSave
  } = props

  const [key, setKey] = useState<number>(0)
  const registerEventRef = useRef<RegisterEventRef>({})

  const isConfiguration = environment === 'Configuration'

  const handleTab = useEvent(() => {
    return {}
  })

  const handleSelect = useEvent((tabIndex: `${number}`) => {
    const newKey = +tabIndex
    setKey(newKey)

    setTimeout(() => {
      if (hideModeList[newKey] === true) {
        window.dispatchEvent(new Event('resize'))
      }

      const keyId = ids[newKey]
      const handleTabCallback = registerEventRef.current[`handleTab_${keyId}`]
      if (keyId && _.isFunction(handleTabCallback)) {
        handleTabCallback(keyId)
      }

      dispatchEvent(new CustomEvent('tabSelected'))
    }, 0)
  })

  useEffect(() => {
    ids.forEach((tabId: string, index: number) => {
      registerEventRef.current[`handleTab_${tabId}`] = registerEvent({
        key: `tab_${tabId}`,
        fn: handleTab,
        returnTypes: {}
      })

      registerMethod({
        key: `tab_${tabId}`,
        fn: () => handleSelect(index),
        args: []
      })
    })
  }, [ids.length])

  const savePosition = (newPosition: Position) => {
    onSave(
      {
        ...settings,
        position: newPosition
      },
      _.map(newPosition, (_id) => _id)
    )
  }

  const afterAddPlugin = (elementName: ElementName) => {
    return (_id: string) => {
      if (!containerValidation(containers, _id)) {
        return
      }
      const newPosition = _.transform(
        position,
        (result, itemId, key) => {
          if (itemId !== _id) {
            result[key] = itemId
          }
        },
        {}
      )
      savePosition({ ...newPosition, [elementName]: _id })
    }
  }

  const handleAddPlugin = (elementName: ElementName) => {
    showAddPluginLayout(afterAddPlugin(elementName))
  }

  const handlePluginRemove = (_id?: string) => {
    const newPosition = _.pickBy(position, (p) => p !== _id)
    savePosition(newPosition)
  }

  const findPlugin = (elementName: ElementName) => {
    const { [elementName]: elementId = '' } = position

    let type
    const plugin = _.find(plugins, (p) => p.id === elementId)
    if (plugin) {
      type = 'plugin'
    } else {
      const container = _.find(containers, (p) => p.id === elementId)
      if (container) type = 'container'
    }

    if (type === 'plugin' || type === 'container') {
      return (
        <View
          key={elementId}
          containers={containers}
          draggableHandle={`move${id}`}
          id={elementId}
          params={params}
          plugins={plugins}
          type={type}
          onMounted={onMounted}
          onRemovePlugin={handlePluginRemove}
        />
      )
    }

    if (isConfiguration && designMode) {
      return <AddElement handleAddElement={() => handleAddPlugin(elementName)} />
    }

    return null
  }

  const getIconTemplate = (icon?: string) => <i className={icon || ''} data-testid="tab-icon" />

  return (
    <div className="h-100 min-h-100" data-testid="tab-container">
      <Tab.Container activeKey={key} defaultActiveKey={0} id={`tab-${id}`} onSelect={handleSelect}>
        <div className="h-100">
          {!tabsHidden ? (
            <Nav variant="tabs">
              {_.map(tabs, (tab: string, index: number) => {
                const isLeftIconVisible = !iconsHidden && iconPosition === 'Left'
                const isRightIconVisible = !iconsHidden && iconPosition === 'Right'

                return (
                  <Nav.Item key={index} className="cp">
                    <Nav.Link eventKey={index}>
                      {isLeftIconVisible ? getIconTemplate(icons[index]) : null}
                      {`${tab} `}
                      {isRightIconVisible ? getIconTemplate(icons[index]) : null}
                    </Nav.Link>
                  </Nav.Item>
                )
              })}
            </Nav>
          ) : null}
          <div
            className={cx('position-relative', { 'h-100': tabsHidden }, styles.tabContentWrapper)}
          >
            <Tab.Content className="h-100">
              {_.map(tabs, (_tab: string, index: number) => {
                const isOverlayVisible = !isConfiguration && overlays[index]
                const isPluginVisible = key === index || hideModeList[index]

                return (
                  <Tab.Pane
                    key={index}
                    className="h-100"
                    eventKey={index}
                    onExiting={() => dispatchEvent(new CustomEvent('tabPaneExited'))}
                  >
                    {isOverlayVisible ? (
                      <div
                        className={cx(
                          'position-absolute',
                          'w-100',
                          'h-100',
                          'top-0',
                          'bottom-0',
                          'start-0',
                          'end-0',
                          'cp',
                          styles.tabContentOverlay
                        )}
                        data-testid="tab-overlay"
                      />
                    ) : null}
                    {isPluginVisible ? findPlugin(`tab${index}`) : null}
                  </Tab.Pane>
                )
              })}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  )
})

const MemoizedTabContainer = memo(TabContainer, (prevProps, nextProps) => {
  const checkIsPropertyEqual = (path: string) => {
    const oldProperty = _.get(prevProps, path, null)
    const newProperty = _.get(nextProps, path, [])
    return _.isEqual(oldProperty, newProperty)
  }

  const arePluginsEqual = checkIsPropertyEqual('plugins')
  const areElementsEqual = checkIsPropertyEqual('container.data.elementIds')
  const isFormEqual = checkIsPropertyEqual('container.data.settings.form')

  const arePropsEqual = arePluginsEqual && areElementsEqual && isFormEqual

  return arePropsEqual
})

export default MemoizedTabContainer
