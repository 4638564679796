import React from 'react'
import { connect } from 'react-redux'
import { FormControl, FormGroup, Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import { weeklySort as weeklySortFn } from '../../../../../actions'
import Sort from '../../sort'

class SubMenuItem extends React.Component {
  state = {
    selectedOperator: ' ',
    showOperators: false,
    filterValue: 0
  }
  operators = ['>', '>=', '=', '!=', '<=', '<']

  componentDidMount() {
    const { filterValues, periodIndex, tableName } = this.props

    if (filterValues.periodIndex !== periodIndex || filterValues.tableName !== tableName) return

    this.setState({ selectedOperator: filterValues.operator, filterValue: filterValues.value })
  }

  handleChangeFilterValue = (event) => {
    const { tableName, isResourceBased, format, onClick } = this.props
    const { selectedOperator } = this.state
    let {
      target: { value }
    } = event

    const isNotDigit = /\D/.test(value)
    value = value === '' ? 0 : isNotDigit ? this.state.value : parseFloat(value)
    this.setState({ filterValue: value })

    if (selectedOperator === ' ') return

    onClick(tableName, isResourceBased, selectedOperator, value, format, true)
  }

  handleSelectOperator = (operator) => {
    const { tableName, isResourceBased, format, onClick } = this.props

    onClick(tableName, isResourceBased, operator, operator, format)
    this.setState((prevState) => ({
      selectedOperator: prevState.selectedOperator === operator ? ' ' : operator
    }))
  }

  handleDeselectOperator = () => {
    this.setState({
      selectedOperator: ' '
    })
  }

  handleShowOperators = () => {
    this.setState((prevState) => ({ showOperators: !prevState.showOperators }))
  }

  handleApply = () => {
    const { tableName, isResourceBased, format, onClick } = this.props
    const { operator, filterValue } = this.state

    onClick(tableName, isResourceBased, operator, filterValue, format)
  }

  handleSort = (params) => {
    const { periodIndex, tableName, weeklySort } = this.props

    const isSortResetted =
      weeklySort.periodIndex === periodIndex &&
      weeklySort.issueName === tableName &&
      weeklySort.isAscending === params.IsAscending
    this.props.weeklySortFn(
      isSortResetted ? '' : periodIndex,
      isSortResetted ? '' : tableName,
      isSortResetted ? -1 : params.IsAscending
    )
  }

  render() {
    const { issueIndex, issueList, periodIndex, connectApi, weeklySort, tableName } = this.props
    const { selectedOperator, filterValue } = this.state

    return (
      <div className="submenu-item" title="">
        <div className="submenu-item__sort">
          <span>SORT</span>
          <Sort
            ascIcons={['fa-fw fa slvy-ui-icon-caret-outline-up', 'fa-fw fa slvy-ui-icon-caret-top']}
            bsClass="bsClassTest"
            connectApi={connectApi}
            currentIssueIndex={issueIndex}
            descIcons={[
              'fa-fw fa slvy-ui-icon-caret-outline-down',
              'fa-fw fa slvy-ui-icon-caret-down'
            ]}
            isAscending={
              weeklySort.periodIndex === periodIndex && weeklySort.issueName === tableName
                ? weeklySort.isAscending
                : null
            }
            bsClass={'bsClassTest'}
            onClick={this.handleSort}
            connectApi={connectApi}
            issueList={issueList}
            periodIndex={[periodIndex]}
            onClick={this.handleSort.bind(this)}
          />
        </div>
        <div className="submenu-item__filter">
          <div>
            <Dropdown className="submenu-item__dropdown">
              <Dropdown.Toggle
                className="submenu-item__dropdown-button"
                variant="light"
                id="dropdown-basic"
              >
                {selectedOperator}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {_.map(this.operators, (operator, i) => (
                  <Dropdown.Item
                    className={`submenu-item__dropdown-item ${
                      selectedOperator === operator ? 'selected-operator' : ''
                    }`}
                    key={operator}
                    eventKey={i}
                    onClick={() => this.handleSelectOperator(operator)}
                  >
                    {operator}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <FormGroup controlId="formBasicText">
              <FormControl
                type="text"
                onChange={this.handleChangeFilterValue}
                value={filterValue}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  filterValues: state.resourcePlan.filterValues,
  weeklySort: state.resourcePlan.weeklySort
})

const mapDispatchToProps = {
  weeklySortFn
}

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuItem)
