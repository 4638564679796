import { fetchRecord } from '../crudoptV3'

const model = 'serverLogs'

export const createLog = (operationKey, pluginId, info, data = {}) => {
  return fetchRecord(
    model,
    `/data/${pluginId}/log/${info}`,
    { data },
    { getRowKey: () => pluginId, operationKey }
  )
}
