import React from 'react'
import cx from 'classnames'
import { OverlayTrigger } from '../..'
import { ICardProps } from './ScenarioMove.types'

export default function Card({
  BaselineScenarioId,
  activeGroupClass,
  cellMetricLength,
  data,
  dataIndex,
  isWithBaseVisible,
  isWithGroupedVisible,
  onActualizeChange,
  onRemoveScenario,
  onScenarioClick
}: ICardProps) {
  return (
    <div className={`scenario-row ${activeGroupClass}`}>
      <div className="scenario-name">
        <a className="-icon" onClick={(event) => onRemoveScenario(data, dataIndex, event)}>
          <span className="slvy-ui-icon-minus-fill -icon" />
        </a>
        <div className="rowTitle">{data.Name}</div>
        <OverlayTrigger tooltip="Is Actual?">
          <input
            checked={data.IsActualized}
            className="dp-actual-scenario-input"
            type="checkbox"
            onChange={(event) => onActualizeChange(data.Id, event)}
          />
        </OverlayTrigger>
        {isWithGroupedVisible && dataIndex > 0 ? (
          <span
            className="slvy-ui-icon-group-down -icon -toGroup"
            onClick={(event) => onScenarioClick(data.Id, dataIndex, event)}
          />
        ) : null}
        {isWithBaseVisible ? (
          <div
            className={cx('baselineIndicator', {
              '--passive': BaselineScenarioId !== data.Id
            })}
            onClick={(event) => onScenarioClick(data.Id, dataIndex, event)}
          >
            B
          </div>
        ) : null}
        <div className="ghostValues">
          <div className="split">
            <div className="value">&nbsp;</div>
          </div>
          <div className="values">
            {Array(cellMetricLength)
              .fill('&nbsp;')
              .map((cell, cellIndex) => {
                return (
                  <div key={cellIndex} className="value">
                    {cell}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
