import { Row } from 'react-bootstrap'
import BaseModal from '../../BaseModal'
import { useReduxState } from '../../../hooks'
import { loadTreeTableSlice, tuiSlice } from '../../../store/slices'
import { getLoadTree, setChangeTransportationMode } from '../../../store/api/endpoints'
import { getTreeQueryParams } from '../../../utils'
import styles from './ChangeTransportationModal.module.scss'
import CommonModalFields from '../CommonModalFields'

function ChangeTransportationModal({
  changeTransportationLoadName,
  availableTransportationModeName
}) {
  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)
  const [
    { isChangeTransportationModalOpen, selectedReasonCodeId },
    { setIsChangeTransportationModalOpen }
  ] = useReduxState(loadTreeTableSlice.selectSlice, loadTreeTableSlice.actions)

  const [triggerSetChangeTransportationMode] = setChangeTransportationMode.useLazyQuery()
  const [triggerGetLoadTree] = getLoadTree.useMutation({
    fixedCacheKey: 'load-tree'
  })

  function handleClickCloseModal() {
    setIsChangeTransportationModalOpen(false)
  }

  function handleClickConfirmButton() {
    const transportationModeName = availableTransportationModeName
    const reasonCodeId = Number(selectedReasonCodeId)
    const id = changeTransportationLoadName
    triggerSetChangeTransportationMode({
      id,
      transportationModeName,
      reasonCodeId
    }).then(() => {
      triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
    })
    handleClickCloseModal()
  }

  return (
    <BaseModal isOpen={isChangeTransportationModalOpen} onClose={handleClickCloseModal}>
      <BaseModal.Header>
        <BaseModal.Title>Confirm: Change Transportation</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className={styles.changeTransportationModal}>
        <CommonModalFields />
        <Row>
          <p className={styles.changeTransportationModalDescription}>
            Are you sure you want to change transportation mode?
          </p>
        </Row>
      </BaseModal.Body>
      <BaseModal.Footer>
        <BaseModal.ConfirmButton onClick={handleClickConfirmButton} />
        <BaseModal.CancelButton onClick={handleClickCloseModal} />
      </BaseModal.Footer>
    </BaseModal>
  )
}

export default ChangeTransportationModal
