import { RefObject, ReactNode, MouseEvent } from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import { useTheme } from '../../contexts/ThemeContext'
import MenuList from './MenuList'

export type MenuItemLeafDef = {
  name: string
  icon?: JSX.Element | string
  disabled?: boolean
  hidden?: boolean
  className?: string
  onClick?: (event: MouseEvent<HTMLElement>) => void
}
export type MenuItemDef =
  | (MenuItemLeafDef & {
      subMenu?: MenuItemDef[]
    })
  | 'separator'
type ContextMenuProps = {
  container: RefObject<HTMLElement>
  items: MenuItemDef[]
  show: boolean
  target: ReactNode // Should it be react node?
  onHide: () => void
}

const ContextMenu = ({ container, items, show, target, onHide }: ContextMenuProps) => {
  const theme = useTheme()

  return (
    <Overlay
      flip
      rootClose
      container={container}
      placement="bottom-start"
      show={show}
      target={target}
      transition={false}
      onHide={onHide}
    >
      <Popover bsPrefix={theme.contextMenu} id="popover-container">
        <Popover.Body className="p-0">
          <MenuList items={items} onHide={onHide} />
        </Popover.Body>
      </Popover>
    </Overlay>
  )
}

export default ContextMenu
