import { getLoadTree, setApprovedLoads } from '../../../store/api/endpoints'
import { useReduxState } from '../../../hooks'
import { tuiSlice, loadTreeTableSlice } from '../../../store/slices'
import { getIsLoadApproved, getLoadNames, getTreeQueryParams } from '../../../utils'
import BaseModal from '../../BaseModal'
import { LoadApprovalModalProps } from './LoadApprovalModal.types'
import styles from './LoadApproveModal.module.scss'

export default function LoadApprovalModal() {
  const [{ config, auth }] = useReduxState(tuiSlice.selectSlice)
  const [{ selectedLoadApprovalRow, selectedRows, isLoadApprovalModalOpen }, loadTreeDispatch] =
    useReduxState(loadTreeTableSlice.selectSlice, loadTreeTableSlice.actions)

  const [triggerGetLoadTree] = getLoadTree.useMutation({
    fixedCacheKey: 'load-tree'
  })
  const [triggerSetApprovedLoads] = setApprovedLoads.useMutation()

  const originalData = selectedLoadApprovalRow?.original

  const isConfirmButtonVisible = originalData?.ID === 'root' && !selectedRows.length
  const isApproved = getIsLoadApproved(originalData || {})
  const titleText = isApproved ? 'Unapprove' : 'Approve'
  const loadNames = getLoadNames(selectedLoadApprovalRow, selectedRows)?.join(', ')

  function handleClickCloseModal() {
    loadTreeDispatch.setIsLoadApprovalModalOpen(false)
  }

  function getBodyContent() {
    if (originalData?.ID === 'root' && !selectedRows.length) {
      return <span className="fs-6">Load section was not made.</span>
    }

    return (
      <div>
        <span className="fs-6">
          {`Selected loads will be ${isApproved ? 'unapproved' : 'approved'}. Continue? `}
        </span>
        <span className="fs-6">Loads: {loadNames}</span>
      </div>
    )
  }

  function handleApproveLoads() {
    const approveLoads = getLoadNames(selectedLoadApprovalRow, selectedRows)

    triggerSetApprovedLoads(approveLoads).then(() => {
      triggerGetLoadTree({ ...getTreeQueryParams(config, 'loadTree', auth.pluginId) })
    })
    handleClickCloseModal()
  }

  return (
    <BaseModal isOpen={isLoadApprovalModalOpen} onClose={handleClickCloseModal}>
      <BaseModal.Header>
        <BaseModal.Title>{titleText}</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className={styles.modalBody}>{getBodyContent()}</BaseModal.Body>
      <BaseModal.Footer>
        {!isConfirmButtonVisible && <BaseModal.ConfirmButton onClick={handleApproveLoads} />}
        <BaseModal.CancelButton onClick={handleClickCloseModal} />
      </BaseModal.Footer>
    </BaseModal>
  )
}
