import { memo } from 'react'
import { ISelectedVariablesProps } from './SelectedVariables.types'

const SelectedVariables = ({ variables, handleRemove }: ISelectedVariablesProps) => {
  if (!variables.length) {
    return null
  }

  return (
    <div className="d-flex gap-1 flex-wrap form-control mb-3" data-testid="selected-variables">
      {variables.map((item, index) => {
        return (
          <span key={item + index}>
            <div className="badge ms-auto bg-secondary fs-sm fw-normal">
              <span className="text-white text-break">{item}</span>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                className="ms-2 cp"
                data-testid={`remove-selected-variable-${index}`}
                onClick={() => handleRemove(item)}
              >
                <i aria-hidden="true" className="fa fa-times" />
              </span>
            </div>
          </span>
        )
      })}
    </div>
  )
}

export default memo(SelectedVariables)
