import _ from 'lodash'
import { fetchCollection, fetchRecord, deleteRecord, fetchOne, updateOne } from '../crudoptV3'

const model = 'plugin'

export const getPlugins = (catalogId, menuId, pageId) => {
  return fetchCollection(model, `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin`)
}
export const getPlugin = (catalogId, menuId, pageId, pluginId) => {
  return fetchOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin/${pluginId}`,
    {},
    {
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin`
      },
      getRowKey: () => pluginId,
      key: pluginId
    }
  )
}

export const registerPluginOutput = (
  operationKey,
  catalogId,
  menuId,
  pageId,
  pluginId,
  registerType,
  data
) => {
  return updateOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin/${pluginId}/register/${registerType}`,
    {
      data,
      params: { catalogId, menuId, pageId }
    },
    {
      key: pluginId,
      operationKey,
      mapResult: (result, currentResult) => {
        const { registers: { [registerType]: register = {} } = {}, registers = {} } = currentResult
        return {
          ...currentResult,
          registers: {
            ...registers,
            [registerType]: {
              ...register,
              ...result
            }
          }
        }
      }
    }
  )
}

export const createPlugin = (operationKey, catalogId, menuId, pageId, data) => {
  return fetchRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin`,
    {
      data
    },
    {
      operationKey,
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin`
      }
    }
  )
}

export const updatePlugin = (operationKey, catalogId, menuId, pageId, pluginId, data) => {
  return updateOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin/${pluginId}`,
    {
      data
    },
    {
      key: pluginId,
      operationKey
    }
  )
}
export const removePlugin = (operationKey, catalogId, menuId, pageId, id) => {
  return deleteRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin/${id}`,
    {},
    {
      key: id,
      getRowKey: () => id,
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin`
      },
      operationKey
    }
  )
}

export const clonePlugin = (operationKey, catalogId, menuId, pageId, data) => {
  return fetchRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin/clone`,
    {
      data
    },
    {
      collectionKey: {
        path: `/catalog/${catalogId}/menu/${menuId}/page/${pageId}/plugin`
      },

      mapResult: (result) => {
        const id = _.first(_.keys(result))
        return {
          ...result[id]
        }
      },
      getRowKey: (result) => {
        const id = _.first(_.keys(result))
        return result[id].id
      },
      operationKey
    }
  )
}
