export const model3 = {
  menu: {
    collections: {
      '9dea3b30-0b31-5282-82be-70e90401d06e': {
        ids: ['606b0d2c43b5790dd816bce6', '606b0d5143b5790dd816bcfc', '60a37d9743b5790578d6915e'],
        needFetch: false,
        pending: false,
        isSuccess: true,
        startFetch: 1674639867967,
        fetchTime: 247,
        endFetch: 1674639868214,
        error: null,
        isFailed: false,
        fetchKey: '1eb49179-4886-430f-b268-62d9aeef259e'
      }
    },
    byId: {
      '606b0d2c43b5790dd816bce6': {
        startFetch: 1674639867967,
        endFetch: 1674639868214,
        fetchTime: 247,
        error: null,
        data: {
          item: {
            id: '606b0d2c43b5790dd816bce6',
            name: 'Demand Planning',
            iconClass: 'fa fa-bar-chart',
            isVisible: true,
            sortOrder: 0,
            transferObject: {},
            catalogId: '5f9866b143b5790fd42127d1',
            userGroups: [1, 2, 6, 13],
            pageCount: 1,
            keywords: []
          },
          children: []
        },
        isSuccess: true,
        pending: false,
        fetchKey: 'e85048b5-2e37-4b09-9104-a0df304ff32a'
      },
      '606b0d5143b5790dd816bcfc': {
        startFetch: 1674639867967,
        endFetch: 1674639868214,
        fetchTime: 247,
        error: null,
        data: {
          item: {
            id: '606b0d5143b5790dd816bcfc',
            name: 'Long Running',
            iconClass: 'fa fa-long-arrow-alt-right',
            isVisible: true,
            sortOrder: 1,
            transferObject: {},
            catalogId: '5f9866b143b5790fd42127d1',
            userGroups: [1, 6, 13],
            pageCount: 12,
            keywords: []
          },
          children: []
        },
        isSuccess: true,
        pending: false,
        fetchKey: '506213f5-5ba2-46c4-a08a-9d0b2b97787c'
      },
      '60a37d9743b5790578d6915e': {
        startFetch: 1674639867967,
        endFetch: 1674639868214,
        fetchTime: 247,
        error: null,
        data: {
          item: {
            id: '60a37d9743b5790578d6915e',
            name: 'IPO',
            iconClass: 'fa fa-shopping-basket',
            isVisible: true,
            sortOrder: 11,
            transferObject: {
              ProductId2Pass: 'int',
              NewProductSelected: 'string'
            },
            catalogId: '5f9866b143b5790fd42127d1',
            userGroups: [51, 52, 53, 54, 55, 56, 57, 58, 1, 2, 6, 13, 84, 21],
            pageCount: 14,
            keywords: []
          },
          children: []
        },
        isSuccess: true,
        pending: false,
        fetchKey: 'bd2993ac-283a-4417-83b5-908100a19cc8'
      }
    }
  },
  catalog: {
    byId: {
      '5f9866b143b5790fd42127d1': {
        startFetch: 1674639867972,
        endFetch: 1674639868245,
        fetchTime: 273,
        error: null,
        needFetch: false,
        isSuccess: true,
        isFailed: false,
        pending: false,
        fetchKey: '49e9beaf-da5d-4f7b-a6eb-967a1d5ba08c',
        data: {
          id: '5f9866b143b5790fd42127d1',
          name: 'Solvoyo Test Automation',
          description: 'test',
          customerId: 1,
          userGroups: [1, 13, 6],
          customerName: 'SOLVOYO',
          customerLogoUri: 'https://slvy-cdn.s3.amazonaws.com/logo/solvoyo-logo.png\n',
          aggregationFilterSettings: [],
          transferObject: {
            filteredint: {
              type: 'int',
              defaultValue: 'filteredint'
            },
            groupbyval: {
              type: 'string',
              defaultValue: 'groupbyval'
            },
            orderby: {
              type: 'string',
              defaultValue: 'orderby'
            },
            filterval: {
              type: 'string',
              defaultValue: 'filterval'
            },
            c: {
              type: 'string',
              defaultValue: 'c'
            },
            d: {
              type: 'string',
              defaultValue: 'd'
            },
            e: {
              type: 'string',
              defaultValue: 'e'
            },
            filtervalcol1: {
              type: 'int',
              defaultValue: 'filtervalcol1'
            },
            col7: {
              type: 'string',
              defaultValue: 'col7'
            },
            col8: {
              type: 'string',
              defaultValue: 'col8'
            },
            refreshKey: {
              type: 'string',
              defaultValue: 'refreshKey'
            },
            arrayvar: {
              type: 'array{int}',
              defaultValue: 'arrayvar'
            },
            datetimeval: {
              type: 'datetime',
              defaultValue: 'datetimeval'
            },
            floatcol: {
              type: 'float',
              defaultValue: 'floatcol'
            },
            boolvar: {
              type: 'bool',
              defaultValue: 'boolvar'
            },
            JobEndedRefreshKey: {
              type: 'string',
              defaultValue: 'JobEndedRefreshKey'
            },
            sp_cus_Unilever_CostImport: {
              type: 'string',
              defaultValue: 'sp_cus_Unilever_CostImport'
            },
            OrdinalNumber: {
              type: 'int',
              defaultValue: 'OrdinalNumber'
            },
            OptionGridNeedsUpdate: {
              type: 'string',
              defaultValue: 'OptionGridNeedsUpdate'
            },
            skucovertarget_drilldown: {
              type: 'string',
              defaultValue: 'skucovertarget_drilldown'
            },
            sp_cus_Whatif_SubmitButton_Iterative: {
              type: 'string',
              defaultValue: 'sp_cus_Whatif_SubmitButton_Iterative'
            }
          },
          remoteActions: [
            {
              name: 'SPTestAsliParam',
              type: 'Stored Procedure',
              connectionId: 3,
              jobId: 0,
              sp: 'SPTestAsliWithParam',
              parameters: [
                {
                  name: '@col7',
                  type: 'string'
                },
                {
                  name: '@col8',
                  type: 'string'
                },
                {
                  name: '@refreshKey',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'JobSch',
              type: 'Job',
              connectionId: 3,
              jobId: 539,
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'SPTestAsli',
              type: 'Stored Procedure',
              connectionId: 3,
              jobId: 0,
              sp: 'SPTestAsli',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'test3',
              type: 'Stored Procedure',
              connectionId: 3,
              jobId: 0,
              sp: 'cus_temp_Egitim2',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'bar',
              type: 'Job',
              connectionId: 3,
              jobId: 396430,
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'knn_test',
              type: 'Job',
              connectionId: 3,
              jobId: 293009,
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_sleep1',
              type: 'Stored Procedure',
              connectionId: 3,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_sleep1',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'RandomSP',
              type: 'Stored Procedure',
              connectionId: 4485,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_LongRunning_Random',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'FailSP',
              type: 'Stored Procedure',
              connectionId: 4485,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_LongRunning_Fail',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'SuccessSP',
              type: 'Stored Procedure',
              connectionId: 4485,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_LongRunning_Success',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'LongRunningTaskSuccessJob',
              type: 'Job',
              connectionId: 3,
              jobId: 754418,
              relationalJobId: '',
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ],
              isCloneJob: false
            },
            {
              name: 'LongRunningTaskFailJob',
              type: 'Job',
              connectionId: 3,
              jobId: 754462,
              relationalJobId: '',
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ],
              isCloneJob: false
            },
            {
              name: 'LongRunningTaskRandomJob',
              type: 'Job',
              connectionId: 3,
              jobId: 754454,
              relationalJobId: '',
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ],
              isCloneJob: false
            },
            {
              name: 'sp_cus_Unilever_Teslimat_Truncate_Import',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_Teslimat_Truncate_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Export_truncate',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_Export_truncate',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Code_Transition_Truncate',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_Code_Transition_Truncate',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_ChannelPriceInput_Truncate',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_ChannelPriceInput_Truncate',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_monthly_cost',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_monthly_cost',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_monthly_Forecast',
              type: 'Stored Procedure',
              connectionId: 72,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_monthly_Forecast',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_UFS_Tea_Import',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_UFS_Tea_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_UFS_Savoury_Dessert_Import',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_UFS_Savoury_Dessert_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_Retail_Savoury_Dessert',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_Retail_Savoury_Dessert_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_Retail_Tea_Import',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_Retail_Tea_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Customer_Code_Transition_Truncate',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_Customer_Code_Transition_Truncate',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_MFP_FlowToSAP Button',
              type: 'Job',
              connectionId: 64,
              jobId: 366867,
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_SetMFP_FlowToSAPRunning1',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_SetMFP_FlowToSAPRunning1',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_truncate_Unilever_IC_Flow_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_truncate_Unilever_IC_Flow_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_truncate_Unilever_IC_Shipment_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_truncate_Unilever_IC_Shipment_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_PC_SkinOral_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_PC_SkinOral_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_MFP_deleteActivity Besan',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_MFP_deleteActivity',
              parameters: [
                {
                  name: '@MFPScenarioFilter',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_PC_Deo_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_PC_Deo_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_PC_Hair_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_PC_Hair_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HC_FabSen_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_HC_FabSen_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HC_FabSol_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_HC_FabSol_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HC_HH_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_HC_HH_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_ApprovedFinancialReporting',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              sp: 'sp_cus_Unilever_ApprovedFinancialReporting',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_IC_Shipment_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_IC_Shipment_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_truncate_Unilever_IC_Shipment_Production_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_truncate_Unilever_IC_Shipment_Production_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Run Flow TO SAP Button HPC',
              type: 'Job',
              connectionId: 64,
              jobId: 394008,
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_SetMFP_FlowToSAPRunning HPC',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_Unilever_SetMFP_FlowToSAPRunning1',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_DP_addNewRecords HPC',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_Unilever_DP_addNewRecords',
              parameters: [
                {
                  name: '@PeriodFilter',
                  type: 'string'
                },
                {
                  name: '@IGAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@IGFilter',
                  type: 'string'
                },
                {
                  name: '@DZAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@DZFilter',
                  type: 'string'
                },
                {
                  name: '@Forecast',
                  type: 'string'
                },
                {
                  name: '@MFPScenarioIDFilter',
                  type: 'string'
                },
                {
                  name: '@isPreview',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_MFP_deleteActivity HPC',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_Unilever_MFP_deleteActivity',
              parameters: [
                {
                  name: '@MFPScenarioFilter',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_DP_addNewRecords Algida',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_Unilever_DP_addNewRecords',
              parameters: [
                {
                  name: '@PeriodFilter',
                  type: 'string'
                },
                {
                  name: '@IGAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@IGFilter',
                  type: 'string'
                },
                {
                  name: '@DZAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@DZFilter',
                  type: 'string'
                },
                {
                  name: '@Forecast',
                  type: 'string'
                },
                {
                  name: '@MFPScenarioIDFilter',
                  type: 'string'
                },
                {
                  name: '@isPreview',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_MFP_deleteActivity Algida',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_Unilever_MFP_deleteActivity',
              parameters: [
                {
                  name: '@MFPScenarioFilter',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HPC_ProductionPlan_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_HPC_ProductionPlan_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_truncate_Unilever_IC_Unica_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_truncate_Unilever_IC_Unica_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HPC_Unica_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              sp: 'sp_cus_unilever_truncate_HPC_Unica_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_ApprovedFinancialReporting Algida',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              sp: 'sp_cus_Unilever_ApprovedFinancialReporting',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_SetMFP_FlowToSAPRunning1 (Mult)',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_SetMFP_FlowToSAPRunning1',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Run Flow TO SAP Button (Mult)',
              type: 'Job',
              connectionId: 564,
              jobId: 394416,
              relationalJobId: 'Run Flow TO SAP Button',
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_ApprovedFinancialReporting (Mult)',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_ApprovedFinancialReporting',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_MFP_deleteActivity Multi',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_MFP_deleteActivity',
              parameters: [
                {
                  name: '@MFPScenarioFilter',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_HPC_ImportPlan_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_HPC_ImportPlan_Import',
              parameters: [
                {
                  name: '@tableName',
                  type: 'string'
                },
                {
                  name: '@isvalidation',
                  type: 'string'
                },
                {
                  name: '@modifiedBy',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_HPC_ImportPlan2_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_HPC_ImportPlan2_Import',
              parameters: [
                {
                  name: '@tableName',
                  type: 'string'
                },
                {
                  name: '@isvalidation',
                  type: 'string'
                },
                {
                  name: '@modifiedBy',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HPC_Katar_FC_Import',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_unilever_truncate_HPC_Unica_FC_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Fulfillment_Calendar',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_Fulfillment_Calendar',
              parameters: [
                {
                  name: '@date',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_NPD_onDemandFKMS',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_NPD_onDemandFKMS',
              parameters: [
                {
                  name: '@SKUCode',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: '@enddate_NPD',
                  type: 'string'
                },
                {
                  name: '@startdate_NPD',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_NPD_onDemandFKMS_agg',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_NPD_onDemandFKMS_agg',
              parameters: [
                {
                  name: '@SKUCode',
                  type: 'string'
                },
                {
                  name: '@startdate_NPD',
                  type: 'string'
                },
                {
                  name: '@enddate_NPD',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_NPD_summarize',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_unilever_NPD_summarize',
              parameters: [
                {
                  name: '@origSPU',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_NPD_ShowCalculatedFKMS',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_NPD_ShowCalculatedFKMS',
              parameters: [
                {
                  name: '@Skucode',
                  type: 'string'
                },
                {
                  name: '@startdate_NPD',
                  type: 'string'
                },
                {
                  name: '@enddate_NPD',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_DP_addNewRecords_20191105',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_DP_addNewRecords_20191105',
              parameters: [
                {
                  name: '@PeriodFilter',
                  type: 'string'
                },
                {
                  name: '@IGAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@IGFilter',
                  type: 'string'
                },
                {
                  name: '@DZAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@DZFilter',
                  type: 'string'
                },
                {
                  name: '@Forecast',
                  type: 'string'
                },
                {
                  name: '@MFPScenarioIDFilter',
                  type: 'string'
                },
                {
                  name: '@isPreview',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_ApprovedFCForNPD',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_ApprovedFCForNPD',
              parameters: [
                {
                  name: '@OriginalProduct',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_import_cus_Unilever_Cost_Import',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_import_cus_Unilever_Cost_Import',
              parameters: [
                {
                  name: '@tableName',
                  type: 'string'
                },
                {
                  name: '@isvalidation',
                  type: 'string'
                },
                {
                  name: '@modifiedBy',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_cost_table',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_unilever_truncate_cost_table',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_NPD_SendToDP',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_NPD_SendToDP',
              parameters: [
                {
                  name: '@OriginalProduct',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                },
                {
                  name: 'Scenario',
                  type: 'string'
                },
                {
                  name: 'UserName',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Fulfillment_Results_SAP_HC',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_Fulfillment_Results_SAP_HC',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Fulfillment_Results_SAP_PC',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_Fulfillment_Results_SAP_PC',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Fulfillment_Results_SAP_3001',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_Fulfillment_Results_SAP_3001',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Fulfillment_Results_SAP_3003',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_Fulfillment_Results_SAP_3003',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_FC_Disagg',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_FC_Disagg',
              parameters: [
                {
                  name: '@ScenarioID',
                  type: 'int'
                },
                {
                  name: '@MonthlyToolScenarioName',
                  type: 'string'
                },
                {
                  name: '@IsPipeLine',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_NPD_SimilarProductsForecast',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Unilever_NPD_SimilarProductsForecast',
              parameters: [
                {
                  name: '@OriginalProduct',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Whatif_ClearScenario_Button',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Whatif_ClearScenario_Button',
              parameters: [
                {
                  name: '@scenarioname',
                  type: 'string'
                },
                {
                  name: '@scenariocolumn',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_Transportation_Debug_Output',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_Transportation_Debug_Output',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_SolvoyoToFol_ProductionPlan_Prep_loopTest',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_SolvoyoToFol_ProductionPlan_Prep_loopTest',
              parameters: [
                {
                  name: 'RunInfoID',
                  type: 'string'
                },
                {
                  name: 'FirstWeek',
                  type: 'string'
                },
                {
                  name: 'LastWeek',
                  type: 'string'
                },
                {
                  name: 'ResourceName',
                  type: 'string'
                },
                {
                  name: 'UserName',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_IC_Flow_SPU_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_IC_Flow_SPU_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_UFS_Tea_FCImport',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_UFS_Tea_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_Export_FCImportExport_FCImport',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_Export_FCImportExport_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_UFS_Savoury_Dessert_FCImport',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_UFS_Savoury_Dessert_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_Export_FCImport',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_Export_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HPC_Katar_FCImport',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_HPC_Katar_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HPC_Unica_FC_Import_old',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_HPC_Unica_FC_Import_old',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_truncate_Unilever_IC_Shipment_Production_ForecastImport_old',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_truncate_Unilever_IC_Shipment_Production_ForecastImport_old',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_truncate_Unilever_IC_Unica_ForecastImport_old',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_truncate_Unilever_IC_Unica_ForecastImport_old',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_IC_Flow_SPU_ForecastImport_old',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_IC_Flow_SPU_ForecastImport_old',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'cus_Unilever_truncate_Algida_Shipment_ProductionPlan_Import',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_Algida_Shipment_ProductionPlan_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_Export_HPC_FCImport',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_Export_HPC_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_truncate_IC_Shipment_Production_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_truncate_IC_Shipment_Production_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_IC_Flow_ForecastImport',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_IC_Flow_ForecastImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'MFP sp_cus_Unilever_DP_addNewRecords_20191105',
              type: 'Stored Procedure',
              connectionId: 564,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_DP_addNewRecords_20191105',
              parameters: [
                {
                  name: '@PeriodFilter',
                  type: 'string'
                },
                {
                  name: '@IGAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@IGFilter',
                  type: 'string'
                },
                {
                  name: '@DZAggregationFilter',
                  type: 'string'
                },
                {
                  name: '@DZFilter',
                  type: 'string'
                },
                {
                  name: '@Forecast',
                  type: 'int'
                },
                {
                  name: '@MFPScenarioIDFilter',
                  type: 'int'
                },
                {
                  name: '@isPreview',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_IC_Flow_ForecastImport_After',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_IC_Flow_ForecastImport_After',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Whatif_SubmitButton_Iterative',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Whatif_Submit_TriggerIterative',
              parameters: [
                {
                  name: '@scenarioname',
                  type: 'string'
                },
                {
                  name: '@scenariocolumn',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Whatif_Submit_TriggerIterative',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Whatif_Submit_TriggerIterative',
              parameters: [
                {
                  name: '@scenarioname',
                  type: 'string'
                },
                {
                  name: '@scenariocolumn',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_Unilever_Fulfillment_UI_ServeLPD',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_Unilever_Fulfillment_UI_ServeLPD',
              parameters: [
                {
                  name: '@itemgroup',
                  type: 'string'
                },
                {
                  name: '@PlanDay',
                  type: 'string'
                },
                {
                  name: '@ScenarioName',
                  type: 'string'
                },
                {
                  name: '@DP',
                  type: 'string'
                },
                {
                  name: '@SalesOrg',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'HPC_sp_Unilever_Fulfillment_UI_ServeLPD',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_Unilever_Fulfillment_UI_ServeLPD',
              parameters: [
                {
                  name: '@itemgroup',
                  type: 'string'
                },
                {
                  name: '@PlanDay',
                  type: 'string'
                },
                {
                  name: '@ScenarioName',
                  type: 'string'
                },
                {
                  name: '@DP',
                  type: 'string'
                },
                {
                  name: '@SalesOrg',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_FC_Disagg_WhatifPipeline',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_FC_Disagg_WhatifPipeline',
              parameters: [
                {
                  name: '@ScenarioID',
                  type: 'int'
                },
                {
                  name: '@MonthlyToolScenarioName',
                  type: 'string'
                },
                {
                  name: '@IsPipeLine',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Teslimat_Import_Excel_Job',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_Teslimat_Import_Excel_Job',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_Teslimat_Import_SAP_Job',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_Teslimat_Import_SAP_Job',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Besan_sp_cus_Unilever_Teslimat_Import_Excel_Job',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_Teslimat_Import_Excel_Job',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Besan_sp_cus_Unilever_Teslimat_Import_SAP_Job',
              type: 'Stored Procedure',
              connectionId: 64,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_Teslimat_Import_SAP_Job',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_PODCoverTarget_Import_After',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_PODCoverTarget_Import_After',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_HC_FCImport',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_HC_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_unilever_truncate_PC_FCImport',
              type: 'Stored Procedure',
              connectionId: 458,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_unilever_truncate_PC_FCImport',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_IC_StokYaslilikRaporu_Import',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_IC_StokYaslilikRaporu_Import',
              parameters: [
                {
                  name: '@tableName',
                  type: 'string'
                },
                {
                  name: '@isvalidation',
                  type: 'string'
                },
                {
                  name: '@modifiedBy',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_truncate_IC_StokYaslilikRaporu_Import',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_truncate_IC_StokYaslilikRaporu_Import',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_IC_Unica_FCImport_After',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_IC_Unica_FCImport_After',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_IC_Kibris_FCImport_After',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_IC_Kibris_FCImport_After',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'sp_cus_Unilever_IC_Other_FCImport_After',
              type: 'Stored Procedure',
              connectionId: 457,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_Unilever_IC_Other_FCImport_After',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'MD_Export_Job',
              type: 'Job',
              connectionId: 20,
              jobId: 98938,
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'MarkdownExportTable',
              type: 'Job',
              connectionId: 20,
              jobId: 354417,
              relationalJobId: '',
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'OptimizePrepacks',
              type: 'Stored Procedure',
              connectionId: 75,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_IPO_Prepack_Selection',
              parameters: [
                {
                  name: '@ProductID',
                  type: 'int'
                },
                {
                  name: '@AssortmentID',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'GetPrepack',
              type: 'Stored Procedure',
              connectionId: 75,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_IPO_Prepack_Selection',
              parameters: [
                {
                  name: '@ProductID',
                  type: 'int'
                },
                {
                  name: '@AssortmentID',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Assortment Visual Report Update',
              type: 'Stored Procedure',
              connectionId: 62,
              jobId: 0,
              relationalJobId: '',
              sp: 'sp_cus_Defacto_Assortment_Visual_Report',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'MultiOptimization',
              type: 'Stored Procedure',
              connectionId: 75,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_IPO_Prepack_Multi_Optimization',
              parameters: [
                {
                  name: '@ProductIDD',
                  type: 'string'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Update Data - Clustering',
              type: 'Stored Procedure',
              connectionId: 62,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_ML_StoreClustering_Outputs',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Update Data - Forecasting',
              type: 'Stored Procedure',
              connectionId: 62,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_ML_Forecasting_Outputs',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Update Data - Forecasting Agg',
              type: 'Stored Procedure',
              connectionId: 62,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_ML_Forecasting_Outputs_Agg',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Assortment Approval Report1',
              type: 'Stored Procedure',
              connectionId: 62,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_AssortmentPlanApproval_4',
              parameters: [
                {
                  name: '@CategoryID',
                  type: 'int'
                },
                {
                  name: '@ClusterID',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'Create Prepack',
              type: 'Stored Procedure',
              connectionId: 62,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_DF_AddNewAssorty',
              parameters: [
                {
                  name: '@Assorty',
                  type: 'string'
                },
                {
                  name: '@ProductID',
                  type: 'int'
                },
                {
                  name: '@AssortyTypeID',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'AssortmentApprovalReport_Weekly',
              type: 'Stored Procedure',
              connectionId: 62,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.sp_cus_AssortmentPlanApproval_SendtoDF',
              parameters: [
                {
                  name: '@CategoryID',
                  type: 'int'
                },
                {
                  name: '@ClusterID',
                  type: 'int'
                },
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'UpdateSPDeneme',
              type: 'Stored Procedure',
              connectionId: 4485,
              jobId: 0,
              relationalJobId: '',
              sp: 'dbo.LongRunningUpdateSP',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ]
            },
            {
              name: 'unilever_hpc_test_sap_sakin_basma',
              type: 'Job',
              connectionId: 458,
              jobId: 394416,
              relationalJobId: '',
              sp: '',
              parameters: [
                {
                  name: 'refreshKey',
                  type: 'string'
                }
              ],
              isCloneJob: false
            }
          ]
        }
      }
    }
  },
  userSetting: {
    byId: {
      '5f9866b143b5790fd42127d1': {
        startFetch: 1674639867976,
        endFetch: 1674639868221,
        fetchTime: 245,
        error: null,
        needFetch: false,
        isSuccess: true,
        isFailed: false,
        pending: false,
        fetchKey: '9908c917-4fac-47a3-bcd8-f82615f56ea6',
        data: []
      }
    }
  },
  customerEnvironments: {
    collections: {
      '8786f9bb-090a-5276-a10d-34559a9f472f': {
        ids: [17],
        needFetch: false,
        pending: false,
        isSuccess: true,
        startFetch: 1674639868247,
        fetchTime: 370,
        endFetch: 1674639868617,
        error: null,
        isFailed: false,
        fetchKey: '6b5f1203-4c68-43ad-8f51-4f676342ea6a'
      }
    },
    byId: {
      '17': {
        startFetch: 1674639868247,
        endFetch: 1674639868617,
        fetchTime: 370,
        error: null,
        data: {
          id: 17,
          environment: 'a2',
          customerId: 1,
          customerName: 'SOLVOYO',
          environmentOrder: 2
        },
        isSuccess: true,
        pending: false,
        fetchKey: 'd0ceeb5e-1ef4-4137-9ccb-ccebd366f7e9'
      }
    }
  },
  culture: {
    collections: {
      'a1c141dd-73fd-54fe-801f-996764cd64b9': {
        ids: [1, 3, 4, 5, 6, 7, 8],
        needFetch: false,
        pending: false,
        isSuccess: true,
        startFetch: 1674639868924,
        fetchTime: 257,
        endFetch: 1674639869181,
        error: null,
        isFailed: false,
        fetchKey: '31e853ed-4c02-4de9-a2d0-7182462f8946'
      }
    },
    byId: {
      '1': {
        startFetch: 1674639868924,
        endFetch: 1674639869181,
        fetchTime: 257,
        error: null,
        data: {
          id: 1,
          languageCultureName: 'en-US',
          displayName: 'English (United States)'
        },
        isSuccess: true,
        pending: false,
        fetchKey: '574f9921-3a04-4c85-8cd2-9c5eb4d3d1fc'
      },
      '3': {
        startFetch: 1674639868924,
        endFetch: 1674639869181,
        fetchTime: 257,
        error: null,
        data: {
          id: 3,
          languageCultureName: 'tr-TR',
          displayName: 'Turkish (Turkey)'
        },
        isSuccess: true,
        pending: false,
        fetchKey: 'b8e49053-0acd-48f5-8f50-645a16d45e92'
      },
      '4': {
        startFetch: 1674639868924,
        endFetch: 1674639869181,
        fetchTime: 257,
        error: null,
        data: {
          id: 4,
          languageCultureName: 'en-TR',
          displayName: 'English (Turkey)'
        },
        isSuccess: true,
        pending: false,
        fetchKey: '34cea04c-30d4-4513-afba-0ace80437bd6'
      },
      '5': {
        startFetch: 1674639868924,
        endFetch: 1674639869181,
        fetchTime: 257,
        error: null,
        data: {
          id: 5,
          languageCultureName: 'en-PL',
          displayName: 'English (Poland)'
        },
        isSuccess: true,
        pending: false,
        fetchKey: '5248114a-4ba9-40ed-a148-6edf26462a94'
      },
      '6': {
        startFetch: 1674639868924,
        endFetch: 1674639869181,
        fetchTime: 257,
        error: null,
        data: {
          id: 6,
          languageCultureName: 'en-SG',
          displayName: 'English (Singapore)'
        },
        isSuccess: true,
        pending: false,
        fetchKey: '5391455b-3ba3-4481-8478-e26bf71da077'
      },
      '7': {
        startFetch: 1674639868924,
        endFetch: 1674639869181,
        fetchTime: 257,
        error: null,
        data: {
          id: 7,
          languageCultureName: 'en-GB',
          displayName: 'English (United Kingdom)'
        },
        isSuccess: true,
        pending: false,
        fetchKey: 'c3de1782-0e83-4d18-b1e4-02400b2481ea'
      },
      '8': {
        startFetch: 1674639868924,
        endFetch: 1674639869181,
        fetchTime: 257,
        error: null,
        data: {
          id: 8,
          languageCultureName: 'en-AU',
          displayName: 'English (Australia)'
        },
        isSuccess: true,
        pending: false,
        fetchKey: '86d44005-66b2-4d0f-9b76-fe3f05e6c94c'
      }
    }
  }
}
