import { useTheme } from '../../contexts/ThemeContext'
import MenuItem from './MenuItem'
import { MenuItemDef } from './ContextMenu'

type MenuListProps = {
  items: MenuItemDef[]
  onHide: () => void
}

const MenuList = ({ items, onHide }: MenuListProps) => {
  const theme = useTheme()

  return (
    <ul className={theme.contextMenuItems}>
      {items?.map((item, index) => (
        <MenuItem key={index} item={Object.assign(item, { index })} onHide={onHide} />
      ))}
    </ul>
  )
}
export default MenuList
