Ext.define('MDM.src.view.Grouping.Edit.Model.GroupDetails', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/Grouping_GroupDetails',
  idProperty: 'Id',
  fields: [
    {
      name: 'CusHieID',
      type: 'int',
      defaultValue: null
    },
    {
      name: 'ProductGroup',
      type: 'string'
    },
    {
      name: 'DPISegment',
      type: 'string'
    },
    {
      name: 'HierarchyText',
      convert: function (value, record) {
        return record.get('DPISegment') + ' → ' + record.get('ProductGroup')
      },
      depends: ['ProductGroup', 'DPISegment']
    }
  ]
})
