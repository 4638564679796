export default () => {
  return {
    title: 'Button',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: false,
            required: true
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          description: {
            type: 'string',
            title: 'Description'
          },
          backgroundColor: {
            type: 'string',
            format: 'NullColor',
            title: 'Background Color',
            default: '#ffffff'
          },
          scrollable: {
            type: 'boolean',
            title: 'Scrollable',
            default: true,
            required: true
          },
          compact: {
            type: 'boolean',
            title: 'Compact',
            default: false,
            required: false
          },
          inputHeight: {
            type: 'number',
            title: 'Input Height',
            default: 25
          },
          inputFontSize: {
            type: 'number',
            title: 'Font Size',
            default: 11
          }
        }
      },
      sections: {
        type: 'array',
        title: 'Sections',
        items: {
          title: 'Section',
          type: 'object',
          headerTemplate: '{{ self.key }}',
          options: {
            collapsed: true
          },
          properties: {
            key: {
              type: 'string',
              title: 'Key'
            },
            label: {
              type: 'string',
              title: 'Label'
            },
            colSize: {
              type: 'number',
              title: 'Column Size',
              enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              default: 6,
              required: true
            },
            backgroundColor: {
              type: 'string',
              format: 'NullColor',
              title: 'Background Color',
              default: '#ffffff'
            }
          }
        }
      },
      components: {
        type: 'array',
        title: 'Components',
        options: {
          syncButton: true
        },
        items: {
          title: 'Component',
          type: 'object',
          headerTemplate: '{{ self.label }}',
          options: {
            collapsed: true
          },
          properties: {
            element: {
              title: 'Type',
              oneOf: [text, textarea, number, datetime, dropdown, checkbox],
              options: {
                disable_collapse: true
              }
            },
            sectionKey: {
              type: 'string',
              title: 'Section Key',
              enumSource: [
                {
                  source: 'sections',
                  value: '{{item.key}}'
                }
              ],
              watch: {
                sections: 'sections'
              }
            },
            fieldName: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },
            min: {
              type: 'string',
              title: 'Min',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },
            max: {
              type: 'string',
              title: 'Max',
              watch: {
                ds: 'datafields'
              },
              enumSource: 'ds',
              required: true
            },
            colSize: {
              type: 'number',
              title: 'Column Size',
              enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              default: 6,
              required: true
            },
            disableDays: {
              type: 'number',
              title: 'Disable Days',
              isMulti: true,
              default: [],
              enum: [1, 2, 3, 4, 5, 6, 7]
            },
            placeholder: {
              type: 'string',
              default: '',
              title: 'Placeholder'
            },
            justifyContent: {
              type: 'string',
              title: 'Justify Content',
              default: 'start',
              enum: ['start', 'center', 'end'],
              required: true
            },
            label: {
              type: 'string',
              title: 'Label'
            },
            labelSize: {
              type: 'number',
              title: 'Label Size',
              enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              default: 3,
              required: true
            },
            justifyLabelContent: {
              type: 'string',
              title: 'Justify Label Content',
              default: 'start',
              enum: ['start', 'center', 'end'],
              required: true
            },
            labelPlacement: {
              type: 'string',
              default: 'left',
              required: true,
              title: 'Label Placement',
              enum: ['left', 'top']
            },
            required: {
              type: 'string',
              default: 'false',
              required: true,
              title: 'Required',
              enum: ['false', 'true', 'From Data as ColName_required']
              // todo colName as real column name from label
            },
            readonly: {
              type: 'string',
              default: 'false',
              required: true,
              title: 'Read Only',
              enum: ['false', 'true', 'From Data as ColName_readonly']
              // todo colName as real column name from label
            },
            validationMessage: {
              type: 'string',
              title: 'Validation Message',
              required: false
            },
            icon: {
              type: 'string',
              title: 'Icon',
              format: 'IconSelector'
            }
          }
        }
      },
      submit: {
        type: 'object',
        title: 'Submit Button',
        properties: {
          show: {
            type: 'boolean',
            title: 'Show',
            default: true
          },
          text: {
            type: 'string',
            title: 'Button Text'
          },
          style: {
            type: 'string',
            title: 'Style',
            default: 'success',
            enum: ['success', 'primary', 'info', 'warning', 'danger'],
            required: true
          },
          icon: {
            type: 'string',
            title: 'Icon',
            default: 'fa fa-check',
            format: 'IconSelector'
          },
          iconPosition: {
            type: 'string',
            title: 'Icon Position',
            default: 'Left',
            enum: ['Left', 'Right'],
            required: true
          },
          buttonPosition: {
            type: 'string',
            title: 'Button Position',
            default: 'Right',
            enum: ['Left', 'Center', 'Right'],
            required: true
          },
          refreshDataAfterSubmitting: {
            type: 'boolean',
            title: 'Refresh Data After Submitting',
            default: true,
            required: true
          },
          enabled: {
            type: 'string',
            title: 'Enabled',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          tooltip: {
            type: 'string',
            title: 'Tooltip',
            default: ''
          },
          includeDirtyStateOnSubmit: {
            type: 'boolean',
            title: 'Include Dirty State On Submit',
            default: true
          }
        }
      }
    }
  }
}
const text = {
  title: 'text',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['text'],
      options: {
        hidden: true
      }
    }
  },
  additionalProperties: false
}
const number = {
  title: 'number',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['number'],
      options: {
        hidden: true
      }
    }
  },
  additionalProperties: false
}
const datetime = {
  title: 'datetime',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['datetime'],
      options: {
        hidden: true
      }
    },
    selectTime: {
      type: 'boolean',
      title: 'Select Time',
      default: false,
      required: true
    }
  },
  additionalProperties: false
}
const dropdown = {
  title: 'dropdown',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['dropdown'],
      options: {
        hidden: true
      }
    },
    searchEnabled: {
      type: 'boolean',
      title: 'Search Enabled',
      default: false,
      required: true,
      description: `You can enable it if you want to use search text as a filter in lookup data query for big queries then you use dropdown's search text as {_searchText} in query`
    },
    MinNoOfSearchChars: {
      type: 'integer',
      title: 'Min No Of Search Characters',
      default: 3,
      required: true
    }
  },
  additionalProperties: false
}
const checkbox = {
  title: 'checkbox',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['checkbox'],
      options: {
        hidden: true
      }
    },
    returnNumber: {
      type: 'boolean',
      title: 'Return Number',
      default: false,
      description: 'the value will be 1/0 if this is selected, otherwise it will be true/false'
    }
  },
  additionalProperties: false
}
const textarea = {
  title: 'textarea',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['textarea'],
      options: {
        hidden: true
      }
    }
  },
  additionalProperties: false
}
