import { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { debounce, isNaN, isNil } from 'lodash'
import { useTheme } from '../../../contexts/ThemeContext'
import { NumberFilterProps } from './NumberFilter.types'

const getSafeValue = (value) => (!value || isNaN(value) ? undefined : Number(value))

export default function NumberFilter(props: NumberFilterProps) {
  const styles = useTheme()
  const ltInputRef = useRef()
  const gtInputRef = useRef()
  const eqInputRef = useRef()

  useEffect(() => {
    if (!isNil(ltInputRef.current)) {
      ltInputRef.current.value = props.getValue('lt') ?? ''
    }
  }, [props.getValue('lt')])

  useEffect(() => {
    if (!isNil(gtInputRef.current)) {
      gtInputRef.current.value = props.getValue('gt') ?? ''
    }
  }, [props.getValue('gt')])

  useEffect(() => {
    if (!isNil(eqInputRef.current)) {
      eqInputRef.current.value = props.getValue('eq') ?? ''
    }
  }, [props.getValue('eq')])

  const handleChange = debounce((value, type) => {
    const safeValue = getSafeValue(value)
    props.onChange({
      ...props.prevValue,
      [type]: safeValue,
      comparer: type,
      value: safeValue
    })
  }, 500)

  return (
    <div className={styles.numberFilter} onClick={(event) => event.stopPropagation()}>
      <span className={styles.gtFilter}>
        <i className="fa fa-fw fa fa-chevron-left" />
        <Form.Control
          ref={ltInputRef}
          type="number"
          defaultValue={props.getValue('lt') ?? ''}
          onChange={(event) => handleChange(event.target.value, 'lt')}
        />
      </span>
      <span className={styles.ltFilter}>
        <i className="fa fa-fw fa fa-chevron-right" />
        <Form.Control
          ref={gtInputRef}
          type="number"
          defaultValue={props.getValue('gt') ?? ''}
          onChange={(event) => handleChange(event.target.value, 'gt')}
        />
      </span>
      <hr className={styles.separator} />
      <span className={styles.eqFilter}>
        <span className={styles.eqIcon}>
          <i className="fa fa-fw fa fa-minus" />
          <i className="fa fa-fw fa fa-minus" />
        </span>
        <Form.Control
          ref={eqInputRef}
          type="number"
          defaultValue={props.getValue('eq') ?? ''}
          onChange={(event) => {
            handleChange(event.target.value, 'eq')
          }}
        />
      </span>
    </div>
  )
}
