Ext.define('MDM.src.view.globalapp.Panel.Publish.Panel.MainGrid', {
  extend: 'Ext.grid.Panel',

  // Uncomment to give this component an xtype
  xtype: 'globalapp-publish-maingrid',
  title: 'Summary Report',
  reference: 'maingrid',
  columnLines: true,
  rowLines: true,
  listeners: {
    select: 'onSelectMainGrid'
  },

  columns: [
    {
      text: 'Title',
      flex: 1,
      dataIndex: 'Title'
    },

    {
      text: 'Period',

      columns: [
        {
          text: 'Current',
          width: 120,
          xtype: 'uxnumbercolumn',
          format: '0',
          align: 'right',
          dataIndex: 'Current'
        },
        {
          text: 'New',
          width: 120,
          xtype: 'uxnumbercolumn',
          format: '0',
          align: 'right',
          dataIndex: 'Next'
        },
        {
          text: 'Change',
          width: 120,
          xtype: 'uxnumbercolumn',
          format: '0',
          align: 'right',
          dataIndex: 'Change'
        }
      ]
    },

    {
      text: 'Text Change',
      width: 120,
      xtype: 'uxnumbercolumn',
      format: '0',
      align: 'right',
      dataIndex: 'TextChange'
    },
    {
      text: 'Node Change',
      width: 120,
      xtype: 'uxnumbercolumn',
      format: '0',
      align: 'right',
      dataIndex: 'NodeChange'
    }
  ]
})
