import React, { Component } from 'react'
import _ from 'lodash'
import { Col } from 'react-bootstrap'
import Comp from './comp'

class Section extends Component {
  render() {
    const {
      pluginId,
      actualFilters,
      settingsComponents,
      boundedDropdowns,
      changeMustBeUpdated,
      updateFieldLookUpData,
      getLookupDataEditableField,
      prepareRecorDatawithChanges,
      readonly = false,
      compact = false,
      inputHeight = 25,
      inputFontSize = 11,
      validationCheckState = false,

      section: { label, colSize, components, backgroundColor = '#FFFFFF' } = {}
    } = this.props

    const colStyle = {
      backgroundColor
    }

    return (
      <Col className="bs-form-section-col h-100" md={colSize} sm={colSize} style={colStyle}>
        {label ? (
          <Col md={12} sm={12}>
            {' '}
            <h3 className="section-title">{label}</h3>
          </Col>
        ) : null}
        {_.map(components, (component, index) => {
          const boundedCmpTemp = _.cloneDeep(boundedDropdowns[component.fieldName]) || undefined

          return (
            <Comp
              key={index}
              actualFilters={actualFilters}
              boundedComp={boundedCmpTemp}
              changeMustBeUpdated={changeMustBeUpdated}
              clientWithProgress={this.props.clientWithProgress}
              compact={compact}
              component={component}
              getFormatedValue={this.props.getFormatedValue}
              getLookupDataEditableField={getLookupDataEditableField}
              getUnformattedValue={this.props.getUnformattedValue}
              getValueWithDateFormat={this.props.getValueWithDateFormat}
              handleDataByIconClick={this.props.handleDataByIconClick}
              inputFontSize={inputFontSize}
              inputHeight={inputHeight}
              loadLookUpdataReadOnly={this.props.loadLookUpdataReadOnly}
              pluginId={pluginId}
              prepareRecorDatawithChanges={prepareRecorDatawithChanges}
              readonly={readonly}
              setRequiredState={this.props.setRequiredState}
              settingsComponents={settingsComponents}
              updateFieldLookUpData={updateFieldLookUpData}
              validationCheckState={validationCheckState}
              onChange={this.props.onChange}
            />
          )
        })}
      </Col>
    )
  }
}
export default Section
