import { Component } from 'react'
import { FormControl, FormLabel } from 'react-bootstrap'

export default class AdminContainer extends Component {
  constructor(props) {
    super(props)
    this.setInput = this.setInput.bind(this)
  }

  setInput() {
    return (event) => {
      const { settings = {} } = this.props
      const { value } = event.currentTarget
      const t = {
        ...settings,
        size: value
      }
      this.props.onChangeSettings(t)
    }
  }

  render() {
    const { settings: { size } = {} } = this.props

    return (
      <table className="table">
        <tbody>
          <tr>
            <td>
              <FormLabel>Size</FormLabel>
            </td>
            <td>
              <FormControl
                placeholder="0"
                type="text"
                value={size}
                onChange={this.setInput('size')}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
