Ext.define('MDM.Btl.edit.EditMixins', {
  extend: 'Ext.Mixin',

  onActivateImported: function () {
    var me = this
    me.getViewModel().set('canShowImportBtn', true)
  },
  onDeactivateImported: function () {
    var me = this
    me.getViewModel().set('canShowImportBtn', false)
  }
})
