const keyMaps = {
  column: {
    backgroundColor: 'backColor',
    dataType: 'dataTypeName',
    isDisabled: 'columnIsDisabled',
    isLocked: 'locked',
    isPercentageType: 'columnIsPercent'
  }
}

export default keyMaps
