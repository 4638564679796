import { TfieldConfigs } from '../../../types'
import { fieldConfigs } from '../../__tests__/mocks'
import { getRegisterMethodFieldConfigsParams } from '../common'
import { expectedGetRegisterMethodFieldConfigsParams } from './mocks'

const mockGetRegisterMethodFieldConfigsParams = (): TfieldConfigs => fieldConfigs

describe('AgGrid:Helpers:Common:getRegisterMethodFieldConfigsParams', () => {
  let params = mockGetRegisterMethodFieldConfigsParams()

  beforeEach(() => {
    params = mockGetRegisterMethodFieldConfigsParams()
  })
  it('should return an array of objects with name and type properties for each field config', () => {
    const result = getRegisterMethodFieldConfigsParams(params)

    expect(result).toEqual(expectedGetRegisterMethodFieldConfigsParams)
  })
  it('should return an empty array when passed an empty fieldConfigs array', () => {
    const result = getRegisterMethodFieldConfigsParams([])

    expect(result).toEqual([])
  })
})
