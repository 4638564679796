import { TAG_FILTER_KEY } from '../../../constants'
import { addKindPropToFilterModel } from '../filter'
import { AddKindPropToFilterModelParams } from '../filter.types'

const addKindPropToFilterModelParams = (): AddKindPropToFilterModelParams => ({
  columnFilterModel: {
    filter: 'Basic',
    filterType: 'text',
    kind: 'default',
    type: 'contains'
  },
  isTagFilterEnabled: false
})

describe('AgGrid:Helpers:Filter:addKindPropToFilterModel', () => {
  let params = addKindPropToFilterModelParams()

  beforeEach(() => {
    params = addKindPropToFilterModelParams()
  })

  it('should return filterModel and set its kind to tagText, if isTagFilterEnabled is true and the type is TAG_FILTER_KEY', () => {
    params.isTagFilterEnabled = true
    params.columnFilterModel.type = TAG_FILTER_KEY

    const expectedFilterModel = {
      ...params.columnFilterModel,
      kind: 'tagText'
    }

    const result = addKindPropToFilterModel(params)
    expect(result).toEqual(expectedFilterModel)
  })

  it('should return filterModel and set its kind to tagAdvanced, if isTagFilterEnabled is true and conditions exist in the filterModel', () => {
    params.isTagFilterEnabled = true
    params.columnFilterModel.conditions = [
      {
        filterType: 'text',
        type: 'contains',
        colId: '2',
        filter: '41'
      },
      {
        filterType: 'text',
        type: 'contains',
        colId: '2',
        filter: 'AF'
      }
    ]

    const expectedFilterModel = {
      ...params.columnFilterModel,
      kind: 'tagAdvanced'
    }
    const result = addKindPropToFilterModel(params)
    expect(result).toEqual(expectedFilterModel)
  })

  it('should return filterModel and set its kind to list, if isTagFilterEnabled is false and the filterType is set', () => {
    params.columnFilterModel.filterType = 'set'

    const expectedFilterModel = {
      ...params.columnFilterModel,
      kind: 'list'
    }
    const result = addKindPropToFilterModel(params)
    expect(result).toEqual(expectedFilterModel)
  })

  it('should return filterModel and set its kind to date, if isTagFilterEnabled is false and the filterType is date', () => {
    params.columnFilterModel.filterType = 'date'

    const expectedFilterModel = {
      ...params.columnFilterModel,
      kind: 'date'
    }
    const result = addKindPropToFilterModel(params)
    expect(result).toEqual(expectedFilterModel)
  })

  it('should return filterModel and set its kind to date, if isTagFilterEnabled is false and the filterType is datetime', () => {
    params.columnFilterModel.filterType = 'datetime'

    const expectedFilterModel = {
      ...params.columnFilterModel,
      kind: 'date'
    }
    const result = addKindPropToFilterModel(params)
    expect(result).toEqual(expectedFilterModel)
  })

  it('should return filterModel and set its kind to default, if isTagFilterEnabled is false and the filterType is not set, date or datetime', () => {
    const result = addKindPropToFilterModel(params)
    expect(result).toEqual(params.columnFilterModel)
  })
})
