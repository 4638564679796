Ext.define('clustering.Model.ClusterStoreModel', {
  extend: 'Ext.data.Model',
  idProperty: 'Id',
  fields: [
    { name: 'ClusterID' },
    { name: 'Id' },
    { name: 'Latitude' },
    { name: 'Longitude' },
    { name: 'Name' },
    { name: 'SquareMeter' },
    { name: 'TotalRevenue' },
    { name: 'TotalSales' },
    { name: 'X' },
    { name: 'Y' },
    { name: 'Z' },

    { name: 'OpenDate' },
    { name: 'CloseDate' },
    {
      name: 'IsTest'
    },
    {
      name: 'Color',
      convert: function (value, record) {
        return clustering.store.ClusterColors.colors[record.get('ClusterID')]
      },
      depends: ['ClusterID']
    },
    {
      name: 'SegmentName',
      convert: function (value, record) {
        var segmentStoreId = record.get('uniqueid') + 'Segments'

        var segmentStore = Ext.data.StoreManager.lookup(segmentStoreId)

        if (segmentStore) {
          var clusterId = record.get('ClusterID')

          if (clusterId != null) {
            var segment = segmentStore.getById(clusterId)

            if (segment != null) {
              return segment.get('Name')
            } else {
              return clusterId
            }
          }
        }

        return 'NA'
      },
      depends: ['ClusterID']
    }
  ],
  listeners: {
    beforeload: function (store) {
      var proxy = store.getProxy()
      proxy.setExtraParam('catalogId', clustering.Global.getCatalogId())
    }
  }
})
