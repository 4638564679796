import * as actionTypes from './actionTypes'

export const savePivotDetailViewSettings = (options) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(storePivotDetailViewSettings(options))
      resolve()
    })
  }
}

const storePivotDetailViewSettings = (options) => {
  return {
    type: actionTypes.STORE_PIVOT_DETAIL_VIEW_SETTINGS,
    payload: options.payload
  }
}
