import {
  TOGGLE_MOVE_MODE,
  TOGGLE_MOVE_ITEM,
  TOGGLE_MOVE_ITEM_GROUP,
  RESET_MOVE_MODE,
  RESET_MOVE_MODE_ITEMS
} from './actionTypes'

export function toggleMoveMode() {
  return {
    type: TOGGLE_MOVE_MODE
  }
}

export function toggleMoveItem(item) {
  return {
    type: TOGGLE_MOVE_ITEM,
    payload: {
      item
    }
  }
}

export function toggleMoveItemGroup(items) {
  return {
    type: TOGGLE_MOVE_ITEM_GROUP,
    payload: {
      items
    }
  }
}

export function resetMoveMode() {
  return {
    type: RESET_MOVE_MODE
  }
}

export function resetMoveModeItems() {
  return {
    type: RESET_MOVE_MODE_ITEMS
  }
}
