export default `({
    title:'satuis grafigi',
    series: [{
            dataIndex: 'QTY',
            type: 'column',
            name: tt('Satis Adet'),
            color: 'rgba(236,17,92,1)',
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    panel = serie.chart;
                    panel.fireEvent('clickChart', record);
                }
            }
        },
        {
            dataIndex: 'Envanter',
            type: 'spline',
            name: 'Envanter',
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    panel = serie.chart;
                    panel.fireEvent('clickChart', record);
                }
            },
            visible: false
        },
        {
            dataIndex: 'PriceNull',
            type: 'spline',
            "lineWidth": 0,
            "marker": {
                "enabled": "true",
                "states": {
                    "hover": {
                        "enabled": true
                    }
                },
                "radius": 5
            },
            "states": {
                "hover": {
                    "lineWidthPlus": 0
                }
            },
            name: tt('Satis Fiyatı'),
            yAxis: 1,
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    panel = serie.chart;
                    panel.fireEvent('clickChart', record);
                }
            }
        },
        {
            dataIndex: 'NumberOfStoreNull',
            type: 'spline',
            "lineWidth": 0,
            "marker": {
                "enabled": "true",
                "states": {
                    "hover": {
                        "enabled": true
                    }
                },
                "radius": 3
            },
            "states": {
                "hover": {
                    "lineWidthPlus": 0
                }
            },
            visible: false,
            name: 'Number Of Store',
            yAxis: 2,
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    panel = serie.chart;
                    panel.fireEvent('clickChart', record);
                }
            }
            // listeners: {
            // 	pointclick: this.onClickPieChart
            // }
        },
    ],
    chartConfig: {
        chart: {
            type: 'column',
            alignTicks: true,
            marginRight: 100,
            marginBottom: 140,
            zoomType: 'x',
            dataLabels: {
                enabled: true
            }
        },
        credits: {
            text: 'SOLVOYO © 2021',
            href: 'http://www.solvoyo.com',
            position: {
                align: 'right',
                y: -5 // position of credits
            },
            style: {
                fontSize: '10pt' // you can style it!
            }
        },
        // tooltip: {
        //     crosshairs: false,
        //     positioner: function(labelWidth, labelHeight, point) {
        //         var chart = this.chart;
        //         var tooltipX, tooltipY;
        //         if (point.plotX + labelWidth > chart.plotWidth) {
        //             tooltipX = point.plotX + chart.plotLeft - labelWidth - 30;
        //         } else {
        //             tooltipX = point.plotX + chart.plotLeft + 30;
        //         }
        //         tooltipY = point.plotY + chart.plotTop - 30;
        //         return {
        //             x: tooltipX,
        //             y: tooltipY
        //         };
        //     },
        //     formatter: function() {
        //         var s = '<b>' + this.x + '</b>';
        //         // $.each(this.points, function () {
        //         //     s += '<br/>' + this.series.name + ': <b>' +
        //         //         Highcharts.numberFormat(this.y, 2) + '</b>';
        //         // });
        //         return s;
        //     },
        //     shared: true
        // },
        plotOptions: {
            column: {
                stacking: 'normal'
            },
            series: {
                // cursor: 'ns-resize',
                point: {
                    events: {
                        drop: function () {
                            a = 1;
                        }
                    }
                },
                stickyTracking: false
            }
        },
        navigation: {
            buttonOptions: {
                align: 'left'
            }
        },
        title: {
            text: tt('Satis {MaterialCode}'),
            style: {
                fontSize: "14px",
                fontWeight: 'bold'
            }
        },
        xAxis: {
            labels: {
                // formatter: function () {
                //     monthValue = this.value
                //     monthValue = monthValue.slice(-2);
                //     monthValue = parseInt(monthValue) - 1
                //     return Ext.Date.shortMonthNames[monthValue];
                // },
                style: {
                    fontSize: '13px'
                },
                rotation: 315,
                y: 35
            },
            // plotBands: [{ // visualize the weekend
            //     from: 13.5,
            //     to: 36,
            //     color: 'rgba(68, 170, 213, .2)',
            //     id: me.plotBandId
            // }]
        },
        yAxis: [{ // Primary yAxis
                title: {
                    text: tt('Satis Adedi')
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                }
            },
            { // Primary yAxis
                opposite: true,
                //  min: 0,
                title: {
                    text: tt('Satis Fiyatı')
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                }
            },
            { // Primary yAxis
                opposite: true,
                title: {
                    text: tt('Store')
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                }
            }
        ]
    }
})
`
