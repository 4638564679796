import { Component } from 'react'

import _ from 'lodash'

import SelectEditor from '../SelectEditor'

export default class NumberEditor extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.triggerChange = this.triggerChange.bind(this)
    this.getValue = this.getValue.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { schema = {}, value } = this.props

    let val = !_.isUndefined(value) ? value : schema.default
    val = !_.isNull(val) ? val : ''

    this.setState({ value: val })

    if (schema.format === 'range' && _.isUndefined(value) && !_.isNil(schema.default)) {
      this.props.onChange(schema.default)
    }

    this.timer = null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value })
    }
  }

  getValue(value) {
    const { schema: { type = '' } = {} } = this.props

    let regex = /[^0-9.\-eE]/g
    if (type === 'integer') {
      regex = /[^0-9]/g
    }

    // TODO: minimum - maximum

    const val = `${value}`.replace(regex, '')
    if (val === '' && type === 'NullableNumber') {
      return val
    }
    return val * 1
  }

  handleChange(e) {
    const val = this.getValue(e.target.value)

    this.setState({ value: val })

    if (this.props.value !== val) {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.triggerChange, 500)
    }
  }

  triggerChange() {
    this.props.onChange(this.state.value)
  }

  render() {
    const { schema } = this.props
    const { value = '' } = this.state

    // console.log('NumberEditor:::', this.props)

    if (schema.enumSource || schema.enum) {
      return <SelectEditor {...this.props} />
    }

    // TODO: add clear button (x) for NullableNumber

    if (schema.format === 'range') {
      const { minimum = 0, maximum = 100, step = 1 } = schema

      return (
        <div
          className="float-start d-flex align-items-center"
          style={{
            flexDirection: 'center',
            width: '55%'
          }}
        >
          <input
            className="w-25 p-1 rounded-2 me-2 text-center"
            style={{
              border: '1px solid #cccccc',
              height: 30
            }}
            value={value}
            onChange={this.handleChange}
          />
          <input
            className="form-control w-75 p-0"
            max={maximum}
            min={minimum}
            step={step}
            type="range"
            value={value}
            onChange={this.handleChange}
          />
        </div>
      )
    }

    return (
      <input
        className="form-control"
        type="number"
        value={value}
        onBlur={this.handleChange}
        onChange={(e) => {
          this.setState({ value: e.target.value })
        }}
      />
    )
  }
}
