Ext.define('MDM.src.view.globalapp.Panel.Geo.Dilaog.Region.Controller', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.geo-dialog-controller',
  /**
   * Called when the view is created
   */
  init: function () {
    var record = this.view.record
    this.getViewModel().set('record', this.view.record)
    this.getViewModel().set('formType', this.view.formType)
    this.getViewModel().set('fieldLabel', this.view.formType)
    this.getViewModel().set('isTitleVisible', this.view.isTitleVisible)
    record.set('formType', this.view.formType)
    var map = this.lookup('map')
    map.setMarker([record.get('Latitude'), record.get('Longitude')])
  },
  onCancel: function (btn) {
    const record = this.getViewModel().get('record')
    record.reject()
    btn.up('window').close()
  },
  onSave: function () {
    var me = this,
      record = this.getViewModel().get('record')
    if (me.getView().comesFrom == 'grid') {
      record.set(me.getView().formType, record.get('Title'))
    }
    record.save()
    this.getView().close()
  },
  onDragend: function (map, e) {
    var record = this.getViewModel().get('record')
    var win = map.up('window')
    var lat = win.query('[itemId=lat]')[0]
    record.set('Latitude', e.lat)
    var long = win.query('[itemId=long]')[0]
    record.set('Longitude', e.lng)
  }
})
