import { useCallback } from 'react'
import { useConfirmationPopup } from '../index'
import { GridRef, LocaleText, TWarningThreshold } from '../../types'

function useThresholdDidExceeded(gridRef: GridRef) {
  const { openConfirmationPopup } = useConfirmationPopup()

  const {
    thresholdExceeded: thresholdExceededLocale,
    valueChangedMoreThanThreshold: valueChangedMoreThanThresholdLocale
  } = (gridRef?.current?.api?.getGridOption?.('localeText') as LocaleText) || {}

  const thresholdDidExceeded = useCallback(
    (warningThreshold: TWarningThreshold, successCallback: () => void) => {
      const title = thresholdExceededLocale
      const message = valueChangedMoreThanThresholdLocale(warningThreshold)

      const onAccept = successCallback
      const onCancel = () => null

      openConfirmationPopup(message, title, onAccept, onCancel)
    },
    [thresholdExceededLocale, valueChangedMoreThanThresholdLocale, openConfirmationPopup]
  )

  return { thresholdDidExceeded }
}

export default useThresholdDidExceeded
