import React, { Component } from 'react'
import _ from 'lodash'
import { Button, ButtonGroup } from 'react-bootstrap'
import html2canvas from 'html2canvas'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import Page from './components/page'
import './index.scss'

class MatrixImage extends Component {
  constructor(props) {
    super(props)
    this.handleImageClick = this.handleImageClick.bind(this)
    this.state = {
      pageNumber: 1,
      imageBoxes: []
    }
  }

  componentDidMount() {
    const imageBoxFields = this.prepareImageBoxFieldsAsEventReturnTypes()
    this.handleImageClick = this.props.registerEvent({
      key: 'handleImageClick',
      fn: this.handleImageClick,
      returnTypes: imageBoxFields
    })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { pluginData = [] } = this.props
    const { pluginData: newPluginData = [] } = nextProps
    const { pageNumber } = this.state
    const { pageNumber: newPageNumber } = nextState

    if (
      (!_.isEqual(pluginData, newPluginData) && _.size(newPluginData) > 0) ||
      pageNumber !== newPageNumber
    ) {
      this.createPage(newPluginData, newPageNumber)
    }
  }

  prepareImageBoxFieldsAsEventReturnTypes() {
    const { settings: { query: { fields = [] } = {} } = {} } = this.props || {}
    let eventReturnTypes = _.transform(
      fields,
      (result, field, key) => {
        const fieldType = field.dataType
        const propName = field.fieldName

        if (!_.isEmpty(fieldType)) {
          switch (fieldType) {
            case 'datetime':
              result[propName] = PluginTypes.datetime
              break
            case 'string':
              result[propName] = PluginTypes.string
              break
            case 'int':
            case 'float':
            case 'double':
            case 'decimal':
              result[propName] = PluginTypes.int
              break
            case 'bool':
              result[propName] = PluginTypes.boolean
              break
            case 'dynamic':
              result[propName] = PluginTypes.dynamic
              break
            default: {
              /* continue regardless of error */
            }
          }
        }
      },
      {}
    )

    eventReturnTypes = { ...eventReturnTypes, refreshKey: PluginTypes.string }
    return eventReturnTypes
  }

  getImageBoxData(boxId) {
    const { pluginData = [], settings: { config: { data: { imageBoxId = '' } = {} } = {} } = {} } =
      this.props || {}
    let dataRecord = {}

    if (pluginData && pluginData.length !== 0) {
      const pluginDataRow = _.find(pluginData, { [imageBoxId]: boxId })
      dataRecord = _.transform(
        pluginDataRow,
        (result, value, key) => {
          result[key] = value
        },
        {}
      )
    }
    return dataRecord
  }

  getValueWithFormat(fieldName, value) {
    const { settings: { query: { formattedFields = [] } = {} } = {}, getFormattedValue } =
      this.props || {}
    const format = _.find(formattedFields, { columnName: fieldName })

    if (!_.isNil(format) && !_.isEmpty(format)) {
      return getFormattedValue(fieldName, value, formattedFields)
    }
    return value
  }

  createPage(pluginData, newPageNumber) {
    const {
      settings: {
        config: {
          data: {
            fieldsSectionHeader: fieldsSectionHeaderFromData = '',
            imageBoxId = '',
            imageURL = null
          } = {},
          imageFields = [],
          settings: {
            fieldsSectionHeader = '',
            imageBoxNumberInRow = 1,
            paginationIsActive = true,
            rowNumberInPage = 1
          } = {}
        } = {}
      } = {}
    } = this.props
    let pageArary
    if (_.isNull(imageBoxId) || _.isEmpty(imageBoxId)) return []
    if (paginationIsActive) {
      const { pageNumber } = this.state
      const currentPageNumber = _.isNil(newPageNumber) ? pageNumber : newPageNumber
      const maximumImageBoxNumberInPage = imageBoxNumberInRow * rowNumberInPage
      const arrayFirstIndex = (currentPageNumber - 1) * maximumImageBoxNumberInPage
      const arrayEndIndex = currentPageNumber * maximumImageBoxNumberInPage
      pageArary = _.slice(pluginData, arrayFirstIndex, arrayEndIndex)
    } else {
      pageArary = [...pluginData]
    }
    const imageBoxes = _.transform(
      pageArary,
      (result, item) => {
        const fields = _.transform(
          imageFields,
          (result, fieldItem) => {
            result.push({
              label: fieldItem.label,
              value: this.getValueWithFormat(fieldItem.dataField, item[fieldItem.dataField])
            })
          },
          []
        )
        result.push({
          imageBoxId: item[imageBoxId],
          imageFields: fields,
          imageURL: item[imageURL],
          fieldsSectionHeader:
            _.isNull(fieldsSectionHeaderFromData) || _.isEmpty(fieldsSectionHeaderFromData)
              ? fieldsSectionHeader
              : item[fieldsSectionHeaderFromData]
        })
      },
      []
    )
    this.setState({ imageBoxes })
    return imageBoxes
  }

  handleImageClick(boxId) {
    return this.getImageBoxData(boxId)
  }

  handlePdf() {
    let settings = {
      toolbar: 'no',
      location: 'no',
      directories: 'no',
      status: 'no',
      menubar: 'no',
      scrollbars: 'yes',
      resizable: 'yes',
      width: 595,
      height: 842,
      top: 0,
      left: 0
    }

    settings = _.map(Object.entries(settings), (item) => item.join('=')).join(',')

    const { settings: { config: { general: { name = '' } = {} } = {} } = {} } = this.props

    const { matrixImage = null } = this.refs
    const htmlToString = `<div class="-print-area -loading">${matrixImage.innerHTML.trim()}</div>`
    const importCss = `<style>
    .-print-area { font-family: Arial, Helvetica, sans-serif; font-size: 7px; text-align: left; color:#555; }
    .-print-area .btn-success, .-print-area .btn-group { display: none; visibility: hidden; }
    .-print-area img { width: 100%; object-fit: cover; height: 200px!important; }
    .-print-area .image-box { padding: 4px; }
    .-print-area.-loading:before {
        content:"Loading...";
        position: fixed;
        left:0; right:0; top:0; bottom:0;
        display:flex; align-items:center; justify-content:center; background:rgba(255, 255, 255, 0.95);
        font-size:14px;
      }
    </style>`

    const win = window.open('', name, settings)
    const doc = win.document

    html2canvas(matrixImage, { allowTaint: true, logging: true }).then(() => {
      doc.body.querySelector('.-print-area').classList.remove('-loading')
      win.print()
    })

    doc.body.innerHTML = htmlToString
    doc.head.innerHTML = importCss
    doc.title = `${name} PDF Export`

    doc.close()
    win.focus()
  }

  render() {
    const {
      settings: {
        config: {
          settings: {
            imageBoxNumberInRow = 1,
            paginationIsActive = true,
            paginationMenuPlacement = 'Top',
            rowNumberInPage = 1,
            imageHeight = 3,
            imageFit = 'cover',
            imageBackground = 'transparent',
            exportButtonIsActive = true
          } = {}
        } = {}
      } = {},
      pluginData = []
    } = this.props
    const { imageBoxes, pageNumber } = this.state
    const pluginDataLength = pluginData ? pluginData.length : 0
    if (pluginDataLength === 0) return null
    const maximumImageBoxNumberInPage = imageBoxNumberInRow * rowNumberInPage
    const arrayFirstIndex = (pageNumber - 1) * maximumImageBoxNumberInPage + 1
    const arrayEndIndex =
      pageNumber * maximumImageBoxNumberInPage < pluginDataLength
        ? pageNumber * maximumImageBoxNumberInPage
        : pluginDataLength
    const pageCount = pluginDataLength / maximumImageBoxNumberInPage

    return (
      <div ref="matrixImage" className="matrix-image-plugin">
        {exportButtonIsActive && (
          <Button size="sm" variant="success" onClick={this.handlePdf.bind(this)}>
            Pdf Export
          </Button>
        )}

        {paginationIsActive &&
          (paginationMenuPlacement === 'Top' || paginationMenuPlacement === 'Top & Bottom') && (
            <ButtonGroup>
              <Button
                disabled={pageNumber === 1}
                size="sm"
                variant="outline-dark"
                onClick={() => this.setState({ pageNumber: pageNumber - 1 })}
              >
                Prev
              </Button>
              <Button
                size="sm"
                variant="outline-dark"
              >{`${arrayFirstIndex} - ${arrayEndIndex} of ${pluginDataLength}`}</Button>
              <Button
                disabled={pageNumber >= pageCount}
                size="sm"
                variant="outline-dark"
                onClick={() => this.setState({ pageNumber: pageNumber + 1 })}
              >
                Next
              </Button>
            </ButtonGroup>
          )}

        <Page
          handleImageClick={this.handleImageClick}
          imageBackground={imageBackground}
          imageBoxNumberInRow={imageBoxNumberInRow}
          imageBoxes={imageBoxes}
          imageFit={imageFit}
          imageHeight={imageHeight}
        />
        {paginationIsActive &&
          (paginationMenuPlacement === 'Bottom' || paginationMenuPlacement === 'Top & Bottom') && (
            <ButtonGroup>
              <Button
                disabled={pageNumber === 1}
                size="sm"
                variant="outline-dark"
                onClick={() => this.setState({ pageNumber: pageNumber - 1 })}
              >
                Prev
              </Button>
              <Button
                size="sm"
                variant="outline-dark"
              >{`${arrayFirstIndex} - ${arrayEndIndex} of ${pluginDataLength}`}</Button>
              <Button
                disabled={pageNumber >= pageCount}
                size="sm"
                variant="outline-dark"
                onClick={() => this.setState({ pageNumber: pageNumber + 1 })}
              >
                Next
              </Button>
            </ButtonGroup>
          )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  pluginData: props.pluginData,
  settings: props.settings,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(MatrixImage, selectConnectorProps)
