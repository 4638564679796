import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  collapse as onRemoveUnCollapsedScenarios,
  unCollapse as onAddUnCollapsedScenarios
} from '../../store/actions/index'
import { OverlayTrigger } from '..'

class ScenarioTreeListTemplate extends Component {
  getIconFromStatus(IsScenario, IsCollapsed) {
    if (IsScenario === false) {
      if (IsCollapsed) {
        return '-folder-close-outline'
      }
      return '-folder-open-outline'
    }
    return '-document-outline'
  }

  getCollapseButton(Id, IsScenario, IsCollapsed) {
    const { dataKey = '', scenarioType = '' } = this.props

    if (IsScenario === false) {
      const payload = { Id, dataKey, scenarioType }
      if (IsCollapsed) {
        return (
          <i
            className="slvy-ui-icon-plus-fill dp-collapse-btn"
            onClick={this.props.onAddUnCollapsedScenarios.bind(this, {
              type: 'UNCOLLAPSE',
              payload
            })}
          />
        )
      }
      return (
        <i
          className="slvy-ui-icon-minus-fill dp-collapse-btn"
          onClick={this.props.onRemoveUnCollapsedScenarios.bind(this, {
            type: 'COLLAPSE',
            payload
          })}
        />
      )
    }
    return null
  }

  selectScenario(IsScenario, item, Name, event) {
    const {
      scenarioSelected = () => {},
      dataKey,
      listIndex = 0,
      scenarioType = '',
      actualScenarioList = []
    } = this.props

    event.stopPropagation()

    if ([...event.target.classList].includes('dp-collapse-btn')) {
      return
    }

    if ([...event.target.classList].includes('dp-actual-scenario-input')) {
      return
    }

    const hasSelectedActualizedScenario = actualScenarioList.find((actualScenario) => {
      return (
        actualScenario.scenarioType === scenarioType &&
        actualScenario.currentScenario.IsActualized === true
      )
    })

    if (IsScenario) {
      scenarioSelected({
        payload: { dataKey, index: listIndex, currentScenario: item, scenarioType }
      })

      // uncheck selected
      if (hasSelectedActualizedScenario) {
        hasSelectedActualizedScenario.currentScenario.IsActualized = false
        this.actualScenarioSelect(true, hasSelectedActualizedScenario.currentScenario)
      }
    }
  }

  actualScenarioSelect(IsScenario, item) {
    const {
      actualScenarioSelected = () => {},
      dataKey,
      listIndex = 0,
      scenarioType = ''
    } = this.props

    if (IsScenario) {
      actualScenarioSelected({
        payload: { dataKey, index: listIndex, currentScenario: item, scenarioType }
      })
    }
  }

  getActualTmp(IsScenario, _Item, Name, isActualized) {
    if (IsScenario) {
      // toggle actualized
      const item = _.cloneDeep(_Item)
      item.IsActualized = Boolean(isActualized) !== true
      return (
        <OverlayTrigger tooltip="Is Actual?">
          <input
            checked={Boolean(isActualized)}
            className="dp-actual-scenario-input cp"
            type="checkbox"
            onChange={this.actualScenarioSelect.bind(this, IsScenario, item, Name)}
          />
        </OverlayTrigger>
      )
    }
    return null
  }

  getDate = (date) => (_.isEmpty(date) ? '-' : moment(date).format('DD.MM.YYYY'))

  render() {
    const {
      root,
      dataKey = '',
      scenarioType = '',
      currentScenario,
      actualScenarioList = [],
      currentScenario: { currentScenario: { Id: currentId = 1 } = {} },
      unCollapsedScenarios = [],
      scenarioSelected = () => {},
      actualScenarioSelected = () => {}
    } = this.props

    const newRoot = !_.isEmpty(root) ? (!_.isArray(root) ? [root] : root) : []

    return (
      <table>
        <tbody>
          {_.map(newRoot, (item, index) => {
            let {
              Item: _Item = {},
              Item: {
                Description = '',
                Id = 0,
                Name = '',
                IsScenario = '',
                CreatedBy = '-',
                RevisionDate,
                CreatedAt
              } = {},
              Children = []
            } = item

            RevisionDate = this.getDate(RevisionDate)
            CreatedAt = this.getDate(CreatedAt)

            const IsCollapsed = !unCollapsedScenarios.some(
              ({ Id: _Id, scenarioType: _scenarioType }) =>
                Id === _Id && _scenarioType === scenarioType
            )

            const isActive = _.isEqual(currentId, Id) && IsScenario
            const isActiveClass = isActive ? '-active-selection' : ''
            const IsHighlightClass = 'IsHighlight' in _Item ? '-highlight' : ''

            const isActualized = actualScenarioList.find((actualScenario) => {
              return (
                actualScenario.currentScenario.IsActualized === true &&
                actualScenario.currentScenario.Id === Id &&
                actualScenario.scenarioType === scenarioType
              )
            })

            return [
              <tr
                key={index}
                className={`sc-item-ctn ${isActiveClass} ${IsHighlightClass}`}
                onClick={(event) => this.selectScenario(IsScenario, _Item, Name, event)}
              >
                <td>
                  <div className="sc-item-wrp">
                    {this.getCollapseButton(Id, IsScenario, IsCollapsed)}
                    <i
                      className={`slvy-ui-icon${this.getIconFromStatus(
                        IsScenario,
                        IsCollapsed
                      )} indent`}
                    />
                    <span>{Name}</span>
                  </div>
                </td>
                <td>
                  <span>{Description}</span>
                </td>
                <td>
                  <span>{CreatedBy}</span>
                </td>
                <td className="text-end -icon-view -ws-nw">
                  {isActive ? this.getActualTmp(IsScenario, _Item, Name, isActualized) : null}
                  <i
                    className="slvy-ui-icon-calendar"
                    style={{ marginRight: 7 }}
                    title={RevisionDate}
                  />{' '}
                  <i className="slvy-ui-icon-calendar-add" title={CreatedAt} />
                </td>
              </tr>,
              !IsCollapsed && (
                <tr key={`${index}collapsed`}>
                  <td className="indentation" colSpan="4">
                    <ConnectedScenarioTreeListTemplate
                      actualScenarioSelected={actualScenarioSelected}
                      currentScenario={currentScenario}
                      dataKey={dataKey}
                      root={Children}
                      scenarioSelected={scenarioSelected}
                      scenarioType={scenarioType}
                    />
                  </td>
                </tr>
              )
            ]
          })}
        </tbody>
      </table>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    unCollapsedScenarios: state.collapse[ownProps.dataKey].unCollapsedScenarios,
    actualScenarioList: state.scenarioTreeSelection[ownProps.dataKey].actualScenarioList
  }
}

const mapDispatchToProps = {
  onAddUnCollapsedScenarios,
  onRemoveUnCollapsedScenarios
}

const ConnectedScenarioTreeListTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioTreeListTemplate)

export default ConnectedScenarioTreeListTemplate
