import cx from 'classnames'
import { iconFontSizeClass } from '../../../constants'
import IconCellRendererProps from './IconCellRenderer.types'

const IconCellRenderer = (props: IconCellRendererProps) => {
  const {
    api,
    value,
    valueFormatted,
    colDef: {
      customInfo: { isAutoColumn }
    },
    node: { group = false },
    context: { groupDisplayType, theme }
  } = props

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    return <strong>{value?.value}</strong>
  }

  const classNames = cx('slvy-i-element', {
    [valueFormatted as string]: typeof valueFormatted !== 'undefined',
    [iconFontSizeClass[theme]]: valueFormatted || group
  })
  return <i aria-hidden="true" className={classNames} />
}

export default IconCellRenderer
