import { Component } from 'react'
import createPlugin from '@/BasePlugin'
import { JsonView, darkStyles, defaultStyles } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'

class ObjectViewer extends Component {
  render() {
    const { settings: { config: { settings = {} } = {} } = {} } = this.props
    const type = settings?.type || 'application/pdf'
    const theme = settings?.theme || 'light'
    const url = settings?.url || ''

    const isLight = theme === 'light'
    const isDocument = type === 'application/pdf'

    const background = isLight ? 'bg-white' : 'bg-black'
    const style = isLight ? defaultStyles : darkStyles

    let json = {}

    if (!isDocument) {
      try {
        json = JSON.parse(url)
      } catch (error) {
        json = {}
      }
    }

    return (
      <div
        className={`${background} w-100 h-100 d-flex justify-content-center align-content-center align-items-stretch flex-column p-0 overflow-auto`}
      >
        {isDocument ? (
          <object data={url} className="w-100 h-100" type={type}>
            <p>
              Alternative text - include a link
              <a href={url}>to the download!</a>
            </p>
          </object>
        ) : (
          <JsonView
            data={json}
            shouldInitiallyExpand={() => false}
            style={{
              ...style,
              container: 'h-100 fs-7 py-2'
            }}
          />
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  settings: props.settings
})

export default createPlugin(ObjectViewer, selectConnectorProps)
