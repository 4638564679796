Ext.define('MDM.src.view.globalapp.Panel.Dpi.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.globalapp-dpi-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {},
  onBoxReady: function () {
    const me = this
    const comp = this.getView().el
    if (!this.getViewModel().get('readOnlyMode')) {
      me.keyMap = new Ext.util.KeyMap({
        target: comp,
        ignoreInputFields: true,
        binding: [
          {
            ctrl: true,
            key: 'x',
            fn: me.onCopyRecord,
            scope: me
          },
          {
            ctrl: true,
            key: 'c',
            fn: me.onCopyRecord,
            scope: me
          },
          {
            ctrl: true,
            key: 'v',
            fn: me.onPasteRecord,
            scope: me
          }
        ]
      })
    }
  },
  onDPIActive: function () {
    const me = this
    if (!me.getView().isVisible()) {
      return
    }
    me.getView().setLoading('Loading data please wait.....')
    Ext.defer(function () {
      me.doLoadAll()
    }, 50)
  },
  doLoadAll: function () {
    var me = this,
      vm = me.getViewModel()
    me.getView().setLoading('Loading data please wait.....')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_DPI_Hierarchies',
      timeout: 360000,
      success: function (response) {
        var data = Ext.JSON.decode(response.responseText, true)
        if (data && data.DPISegment) {
          vm.getStore('DpiSegmentStore').loadData(data.DPISegment)
        }
        if (data && data.ProductGroup) {
          vm.getStore('DpiProductGroupStore').loadData(data.ProductGroup)
        }
        if (data && data.DPIFamily) {
          vm.getStore('DPIFamilyStore').loadData(data.DPIFamily)
        }
        const grid = me.lookupReference('dpisegment')
        if (!grid.getSelection().length) grid.getSelectionModel().select(0)
        me.onDpiProductGrouptStoreFilterChange()
        me.getView().setLoading(false)
      },
      failure: function () {}
    })
  },
  onDpiSegmentStoreLoad: function () {
    var me = this,
      viewModel = me.getViewModel(),
      view = me.getView()
    const grid = me.lookupReference('dpisegment')
    grid.getSelectionModel().select(0)
  },
  onDpiProductGrouptStoreFilterChange: function () {
    var me = this,
      viewModel = me.getViewModel(),
      view = me.getView(),
      rec,
      store,
      grid = me.lookupReference('productgroup')
    if (grid.getSelection().length) {
      store = grid.getSelection()[0].store
      rec = store.findRecord('ID', grid.getSelection()[0].get('ID'), 0, false, true, true)
    }
    if (!rec) grid.getSelectionModel().select(0)
  },
  onSelectDPISegment: function (grid, record) {
    this.getViewModel().set('currentDPISegmentRecord', record)
  },
  onProductGroupSelect: function (grid, record) {
    this.getViewModel().set('currentProductGroupRecord', record)
  },
  onCopyRecord: function (grid, record, tr, rowIndex, event) {
    if (event) {
      event.preventDefault()
    }
    grid = this.lookup('dpifamily')
    this.getViewModel().set('copyFamilies', grid.getSelection())
  },
  onPasteRecord: function () {},
  isActionDisabled: function (view) {
    return view.grid.getReadOnlyMode()
  },
  doPasteDPIFamily: function (targetRecord, fromRecord, pasteRecords) {
    var me = this
    pasteRecords.forEach(function (record) {
      record.set('ProductGroupID', targetRecord.get('ProductGroupID'))
      record.set('DPISegmentID', targetRecord.get('DPISegmentID'))
    })
    me.lookup('productgroup').getSelectionModel().select(targetRecord)
    me.syncStore('DPIFamilyStore')
  },
  onbeforeDropToSegment: function (node, data, overModel, dropPosition, dropHandlers) {
    var grid = this.lookup('dpisegment'),
      me = this
    // Defer the handling
    dropHandlers.wait = true
    // here you have the record from the treeview and can do anything you like with it.
    var record = data.records[0]
    if (record.get('DPISegmentID') == overModel.get('DPISegmentID')) {
      return
    }
    Ext.MessageBox.confirm(
      'Move',
      'Your are about to move <span style="color:red">' +
        record.get('ProductGroup') +
        '</span> from <span style="color:red">' +
        record.get('DPISegment') +
        '</span> to <span style="color:red">' +
        overModel.get('DPISegment') +
        '</span>, Continue?',
      function (btn) {
        if (btn === 'yes') {
          record.set('DPISegmentID', overModel.get('DPISegmentID'))
          me.getViewModel().set('currentDPISegmentRecord', record)
          me.doSave(record)
          dropHandlers.cancelDrop()
          grid.getSelectionModel().select(overModel)
        } else {
          dropHandlers.cancelDrop()
        }
      }
    )
  },
  onbeforeDropToProductGroup: function (node, data, overModel, dropPosition, dropHandlers) {
    var me = this,
      grid = this.lookup('productgroup'),
      cuttedRecord = data.records,
      fromName = cuttedRecord[0].get('ProductGroup'),
      targetName = overModel.get('ProductGroup')
    let listStr = ''
    // Defer the handling
    dropHandlers.wait = true
    cuttedRecord.forEach(function (record) {
      listStr += '<br>' + record.get('DPIFamily')
    })
    // here you have the record from the treeview and can do anything you like with it.
    var record = data.records[0]
    if (record.get('ProductGroupID') == overModel.get('ProductGroupID')) {
      return
    }
    Ext.Msg.show({
      title:
        'Moving from <span style="color:yellow">' +
        fromName +
        '</span> <b> to </b> <span style="color:yellow"> ' +
        targetName,
      width: 500,
      msg: listStr,
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doPasteDPIFamily(overModel, cuttedRecord[0], cuttedRecord)
        }
      }
    })
  },
  onEditDPISegment: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      view = this.getView()
    var win = Ext.create('MDM.src.view.globalapp.Panel.Dpi.Form.Main', {
      record: record,
      formType: 'DpiSegment',
      title: 'DPI Segment',
      height: 180,
      width: 400,
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      me.doSave(record)
    })
  },
  onAddDPISegment: function () {
    var me = this,
      record = Ext.create('MDM.src.view.globalapp.Panel.Dpi.model.DpiSegment', {}),
      store = me.getViewModel().getStore('DpiSegmentStore'),
      win = Ext.create('MDM.src.view.globalapp.Panel.Dpi.Form.Main', {
        record: record,
        formType: 'DpiSegment',
        height: 180,
        width: 400,
        formAction: 'create',
        title: 'DPI Segment',
        readOnlyMode: false
      }).show()
    win.on('save', function () {
      if (!record.store) {
        store.add(record)
      }
      me.doSave(record)
    })
  },
  onEditProductGroup: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      view = this.getView(),
      win = Ext.create('MDM.src.view.globalapp.Panel.Dpi.Form.Main', {
        record: record,
        formType: 'ProductGroup',
        title: 'Product Group',
        height: 220,
        width: 400,
        readOnlyMode: false
      }).show()
    win.on('save', function () {
      me.doSave(record)
    })
  },
  onAddProductGroup: function () {
    var me = this,
      store = me.getViewModel().getStore('DpiProductGroupStore'),
      DPISegmentID = this.getViewModel().get('DPISegmentID'),
      record = Ext.create('MDM.src.view.globalapp.Panel.Dpi.model.ProductGroup', {
        DPISegmentID: DPISegmentID
      }),
      view = this.getView(),
      win = Ext.create('MDM.src.view.globalapp.Panel.Dpi.Form.Main', {
        record: record,
        formType: 'ProductGroup',
        height: 220,
        width: 400,
        formAction: 'create',
        title: 'Product Group',
        readOnlyMode: false
      }).show()
    win.on('save', function () {
      if (!record.store) {
        store.add(record)
      }
      me.doSave(record)
    })
  },
  onDPIFamilyEdit: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this,
      win = Ext.create('MDM.src.view.globalapp.Panel.Dpi.Form.Main', {
        record: record,
        formType: 'DPIFamily',
        title: 'DPI Family',
        height: 320,
        width: 400,
        readOnlyMode: false
      }).show()
    win.on('save', function () {
      me.doSave(record)
    })
  },
  onAddDPIFamily: function () {
    var me = this,
      ProductGroupID = this.getViewModel().get('ProductGroupID'),
      store = me.getViewModel().getStore('DPIFamilyStore'),
      DPISegmentID = this.getViewModel().get('DPISegmentID'),
      record = Ext.create('MDM.src.view.globalapp.Panel.Dpi.model.DPIFamily', {
        DPISegmentID: DPISegmentID,
        ProductGroupID: ProductGroupID
      }),
      view = this.getView()
    var win = Ext.create('MDM.src.view.globalapp.Panel.Dpi.Form.Main', {
      record: record,
      formType: 'DPIFamily',
      height: 320,
      width: 400,
      formAction: 'create',
      title: 'DPI Family',
      readOnlyMode: false
    }).show()
    win.on('save', function () {
      if (!record.store) {
        store.add(record)
      }
      me.doSave(record)
    })
  },
  doSave: function (record) {
    var me = this,
      view = this.getView()
    view.setLoading('Saving data please wait....!')
    record.save({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        view.setLoading(false)
        me.doLoadAll()
      },
      failure: function () {
        view.setLoading(false)
        console.log('server side error')
      }
    })
  },
  onDPISegmentDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete <span style="color:yellow">' + record.get('DPISegment') + '</span> ',
      width: 500,
      msg: 'This action will delete this entry as well as all associated entries in the hierarchical tree. Are you sure you want to continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDelete(record, 'DpiSegmentStore')
        }
      }
    })
  },
  onProductGroupDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete <span style="color:yellow">' + record.get('ProductGroup') + '</span> ',
      width: 500,
      msg: 'This action will delete this entry as well as all associated entries in the hierarchical tree. Are you sure you want to continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDelete(record, 'DpiProductGroupStore')
        }
      }
    })
  },
  onDPIFamilyDelete: function (grid, rowIndex, colIndex, item, e, record) {
    var me = this
    Ext.Msg.show({
      title: 'Delete <span style="color:yellow">' + record.get('DPIFamily') + '</span> ',
      width: 500,
      msg: 'This action will delete this entry as well as all associated entries in the hierarchical tree. Are you sure you want to continue? <br><br>',
      buttons: Ext.Msg.YESNO,
      icon: Ext.MessageBox.QUESTION,
      fn: function (response) {
        if (response === 'yes') {
          me.doDelete(record, 'DPIFamilyStore')
        }
      }
    })
  },
  doDelete: function (record, storename) {
    var me = this
    this.getViewModel().getStore(storename).remove(record)
    me.syncStore(storename)
  },
  syncStore: function (storeName) {
    var store = this.getViewModel().getStore(storeName)
    var view = this.getView(),
      me = this
    view.setLoading('Processing data please wait....!')
    store.sync({
      success: function () {
        var t = Ext.toast({
          html: 'Operation successfully completed',
          title: 'Information',
          width: 300,
          align: 'tr',
          iconCls: 'fa fa-check',
          closable: true
        })
        view.setLoading(false)
        me.doLoadAll()
      },
      failure: function () {
        console.log('GroupDetailsStore onSave action server side error')
      }
    })
  }
})
