import { STORE_SET_GENERATE_REPORT_TREE } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetSetGenerateReportTree: {
    raw: {},
    created: [],
    groupBy: []
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SET_GENERATE_REPORT_TREE:
      return updateObject(state, {
        GetSetGenerateReportTree: {
          ...state.GetSetGenerateReportTree,
          ...action.payload
        }
      })
    default:
      return state
  }
}

export default reducer
