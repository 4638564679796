import { isEqual } from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelectorCreator, lruMemoize } from 'reselect'
import { GridOptions } from 'ag-grid-community'
import {
  EmptyObject,
  GroupingPrimitiveProps,
  EnableCellTextSelection,
  GridSettings,
  GridSchemaSettings,
  IRowData,
  SummaryProps,
  NoSelectionProps
} from '../types'
import { DEFAULT_THEME } from '../constants'

const selectRawGridConfig = (configGrid: GridSchemaSettings) => configGrid

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

interface MultiSortKeyObj {
  multiSortKey: GridOptions<IRowData>['multiSortKey']
}

interface HeaderHeightObj {
  headerHeight: GridOptions<IRowData>['headerHeight']
}

const selectGridConfigs = createDeepEqualSelector(selectRawGridConfig, (gridConfig) => {
  // eslint-disable-next-line no-console
  console.log('selectGridConfigs selector is running...')

  const {
    adding: {
      addButtonSettings: {
        addButtonText = 'Add Record',
        addButtonTooltip = 'Add New Record',
        addButtonIcon = 'fa fa-plus-square'
      } = {},
      addingType = 'Direct',
      enabled: addingEnabled = false
    } = {},
    dataDetails: {
      enabled: dataDetailsEnabled = false,
      isVisibleTotalAndFilteredRowCount = true,
      unsavedChangesText = ''
    } = {},
    deleting: {
      enabled: deletingEnabled = false,
      confirmation: deleteConfirmation = true,
      confirmationMessage: deleteConfirmationMsg = 'Are you sure you want to delete this row?',
      deletableCondition = '',
      deleteButtonSettings: {
        deleteButtonTooltip = 'Delete',
        deleteButtonIcon = 'fa fa-minus-circle'
      } = {}
    } = {},
    editing: {
      clicksToEdit = 1,
      confirmation: editingConfirmation = false,
      confirmationMessage: editingConfirmationMsg = 'Are you sure you want to save this changes?',
      enabled: editingEnabledByConfig = false,
      editingType = 'SaveButton',
      massUpdate = false,
      lockGrid = true,
      saveButtonSettings: {
        resetButton: resetButtonEnabled = true,
        resetButtonIcon = '',
        resetButtonText = 'Reset Changes',
        resetButtonTooltip = 'Reset Changes',
        saveButton: saveButtonEnabled = true,
        saveButtonIcon = 'fa fa-floppy-o',
        saveButtonText = 'Save',
        saveButtonTooltip = 'Save Changes'
      } = {}
    } = {},
    header: {
      autoTooltip = true,
      headerAlign = 'center',
      headerAlignment = false,
      headersVisible = true,
      multiLineHeader = false
    } = {},
    rowGrouping: {
      groupDisplayType = 'groupRows',
      suppressRowGroupHidesColumns = true,
      blankHeader = true
    } = {},
    exportable = false,
    filtering = true,
    enableAdvancedFilter = false,
    footerButtons = [],
    grouping = true,
    groupTotalRow = 'bottom',
    getCellColorsFromData = false,
    includeAggFuncInHeader = false,
    initialGroupingCollapse = false,
    listFilterCheckbox = false,
    lockable: pinnable = true,
    multiColumnSort = true,
    updateParameters = [],
    rowGroupPanelShow = 'onlyWhenGrouping',
    selection: { type: selectionType = 'Row', textSelection = true, rowDeselection = true } = {},
    suppressCount = true,
    summary = 'off',
    emptyText = 'No Data to Display',
    noColorFooterButtons = true,
    paging = false,
    theme = DEFAULT_THEME
  } = gridConfig

  let rowSelection: GridOptions<IRowData>['rowSelection']
  if (selectionType === 'Row') {
    rowSelection = 'single'
  } else if (selectionType === 'Multiselect') {
    rowSelection = 'multiple'
  }

  // Override rowSelection if massUpdate is enabled to allow user can select multiple rows
  // Use selectionType in the application not rowSelection!
  if (massUpdate) {
    rowSelection = 'multiple'
  }

  const groupingPrimitiveProps: Partial<GroupingPrimitiveProps> = grouping
    ? {
        groupDisplayType,
        suppressRowGroupHidesColumns,
        // suppressMakeColumnVisibleAfterUnGroup: true
        isGroupOpenByDefault: () => !initialGroupingCollapse
      }
    : {}

  if (grouping) {
    if (groupDisplayType === 'singleColumn') {
      // Note: This can work for multipleColumns
      groupingPrimitiveProps.groupSuppressBlankHeader = !blankHeader
    }
    if (groupTotalRow !== 'off') {
      groupingPrimitiveProps.groupTotalRow = groupTotalRow
    }
  }

  const summaryProps: Partial<SummaryProps> = {}
  if (summary === 'top' || summary === 'bottom') {
    summaryProps.grandTotalRow = summary
  }

  const multiSortKeyObj: MultiSortKeyObj | EmptyObject = multiColumnSort
    ? { multiSortKey: 'ctrl' }
    : {}

  const headerHeightObj: HeaderHeightObj | EmptyObject = headersVisible ? {} : { headerHeight: 0 }

  const isSpreadsheetSelection = selectionType === 'Spreadsheet'
  const isNoSelection = selectionType === 'No Selection'

  const enableCellTextSelection: EnableCellTextSelection =
    isSpreadsheetSelection || isNoSelection ? false : textSelection

  const noSelectionProps: Partial<NoSelectionProps> = isNoSelection
    ? {
        suppressRowClickSelection: true,
        suppressCellFocus: true,
        suppressRowHoverHighlight: true
      }
    : {}

  const gridSettings: GridSettings = {
    // map with defaults before here
    ...headerHeightObj,
    ...multiSortKeyObj,
    ...summaryProps,
    ...groupingPrimitiveProps,
    ...noSelectionProps,
    enableRangeSelection: isSpreadsheetSelection,
    rowGroupPanelShow,
    rowSelection,
    singleClickEdit: clicksToEdit === 1,
    suppressAggFuncInHeader: !includeAggFuncInHeader,
    rowMultiSelectWithClick: selectionType === 'Row' && rowDeselection,
    enableCellTextSelection,
    customConfigs: {
      initialGroupingCollapse,
      lockPinned: !pinnable,
      enableAdvancedFilter,
      pagination: paging,
      emptyText,
      getCellColorsFromData,
      exportable,
      filtering,
      footerButtons,
      grouping,
      listFilterCheckbox,
      suppressCount,
      theme,
      noColorFooterButtons,
      adding: {
        addingType,
        enabled: addingEnabled,
        addButtonSettings: {
          icon: addButtonIcon,
          text: addButtonText,
          tooltip: addButtonTooltip
        }
      },
      dataDetails: {
        enabled: dataDetailsEnabled,
        isVisibleTotalAndFilteredRowCount,
        unsavedChangesText
      },
      deleting: {
        confirmation: deleteConfirmation,
        confirmationMessage: deleteConfirmationMsg,
        deletableCondition,
        deleteButtonSettings: {
          icon: deleteButtonIcon,
          tooltip: deleteButtonTooltip
        },
        enabled: deletingEnabled
      },
      editing: {
        confirmation: editingConfirmation,
        confirmationMessage: editingConfirmationMsg,
        enabled: editingEnabledByConfig,
        editingType,
        massUpdate,
        lockGrid,
        resetButtonSettings: {
          enabled: resetButtonEnabled,
          icon: resetButtonIcon,
          text: resetButtonText,
          tooltip: resetButtonTooltip
        },
        saveButtonSettings: {
          enabled: saveButtonEnabled,
          icon: saveButtonIcon,
          text: saveButtonText,
          tooltip: saveButtonTooltip
        }
      },
      updateParameters,
      selection: {
        type: selectionType,
        textSelection,
        rowDeselection
      },
      header: {
        align: headerAlign,
        alignment: headerAlignment,
        autoTooltip,
        multiLineHeader
      }
    }
  }

  return gridSettings
})

export default selectGridConfigs
