Ext.define('Spui.Global', {
  singleton: true,
  dateFormatLong: 'Y-m-d\\TH:i:s',
  dateFormatShort: 'Y-m-d\\TH:i:s',
  isDotNet: true,
  config: {
    /**
     * URL of the custom API to use for the reporting system
     */
    apiUrl: '###SPUI_API_URL###',
    /**
     * User onject
     */
    userObj: {
      id: 0,
      username: '',
      isReadonly: true,
      isGlobal: false,
      isRegion: true,
      region: 'NA',
      culture: 'en-US',
      email: ''
    },
    menuId: null,
    mongoCollectionExportedData: null
  },
  constructor: function (config) {
    const me = this
    me.initConfig(config)
  },
  /**
   * @return User object
   */
  getUserRole: function () {
    var me = this,
      userRole,
      userPermissions,
      arr,
      userObj = me.getUserObj()
    if (Ext.isObject(userObj) && userObj.userRole && Ext.isObject(userObj.userRole)) {
      userRole = userObj.userRole
      if (!userRole.name || !userRole.code) return 'User Role Not defined'
      arr = userRole.name.split('_')
      if (Ext.isArray(arr) && arr.length == 2) {
        userRole = {
          userOrg: arr[0],
          userRole: arr[1],
          roleCode: userRole.code,
          userType: userObj.userType
        }
        return userRole
      }
    }
    return false
  },
  getUserCode: function () {
    var me = this,
      userRole,
      userPermissions,
      arr,
      userObj = me.getUserObj()
    if (Ext.isObject(userObj) && userObj.userRole && Ext.isObject(userObj.userRole)) {
      userRole = userObj.userRole
      if (!userRole.name || !userRole.code) return null
      return userRole.code
    }
    return null
  },
  getUserRoleName: function () {
    var me = this,
      userRole,
      userPermissions,
      arr,
      userObj = me.getUserObj()
    if (Ext.isObject(userObj) && userObj.userRole && Ext.isObject(userObj.userRole)) {
      userRole = userObj.userRole
      if (!userRole.name || !userRole.code) return null
      return userRole.name
    }
    return null
  },
  /**
   * @return User culture from user object
   */
  getCultere: function () {
    var me = this,
      cultere = 'en',
      userObj = me.getUserObj()
    if (Ext.isObject(userObj) && userObj.culture) {
      cultere = userObj.culture
    }
    return cultere
  },
  isAdmin: function () {
    var me = this,
      userRole = me.getUserRole()
    if (userRole && userRole.userType && userRole.userType.toLowerCase() == 'admin') {
      return true
    }
    return false
  },
  isMO: function () {
    var me = this,
      userRole = me.getUserRole()
    if (userRole && userRole.userOrg && userRole.userOrg.toLowerCase() == 'mo') {
      return true
    }
    return false
  },
  isDC: function () {
    var me = this,
      userRole = me.getUserRole()
    if (userRole && userRole.userOrg && userRole.userOrg.toLowerCase() == 'dc') {
      return true
    }
    return false
  },
  isSO: function () {
    var me = this,
      userRole = me.getUserRole()
    if (userRole && userRole.userOrg && userRole.userOrg.toLowerCase() == 'so') {
      return true
    }
    return false
  }
})
