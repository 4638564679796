import { useEffect, useRef } from 'react'
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex'
import { getScript } from 'jquery'
import { Header, ServiceModal, Sidebar, ViewContainer } from './components'
import { authorizations, queryOptions } from './constants'
import { useReduxState, useRegisterEvents, useRegisterMethods } from './hooks'
import { getReasonCodes, getServiceStatus, setServiceWithConnectionId } from './store/api/endpoints'
import { sidebarSlice, tuiSlice, viewContainerSlice } from './store/slices'
import { LoadTreeTableProps } from './components/LoadTreeTable'
import { DemandTreeTableProps } from './components/DemandTreeTable'
import styles from './Tui.module.scss'

export default function Tui() {
  const [{ config, registerAuthorizations }] = useReduxState(tuiSlice.selectSlice)
  const loadTreeTableRef: LoadTreeTableProps['tableRef'] = useRef(null)
  const demandTreeTableRef: DemandTreeTableProps['tableRef'] = useRef(null)

  const [{ currentView }] = useReduxState(viewContainerSlice.selectSlice)
  const [sidebar] = useReduxState(sidebarSlice.selectSlice)
  const [triggerGetReasonCodes] = getReasonCodes.useLazyQuery()
  const [triggerGetServiceStatus] = getServiceStatus.useLazyQuery(queryOptions.serviceStatus)
  const [, serviceConnectionData] = setServiceWithConnectionId.useMutation({
    fixedCacheKey: 'service-connection-id'
  })
  const isSidebarVisible = currentView === 'Map' || currentView === 'Table'

  useEffect(() => {
    registerAuthorizations(authorizations)
    fetchInitialResources()
    loadLegacyResources()
  }, [])

  useRegisterMethods({ demandTreeTableRef, loadTreeTableRef })
  useRegisterEvents()

  function fetchInitialResources() {
    triggerGetServiceStatus()
    triggerGetReasonCodes()
  }

  function loadLegacyResources() {
    getScript('/resources/turf.min.js')
  }

  if (!serviceConnectionData.isSuccess && !config.settings.isServiceSelectionEnabled) {
    return <div className="w-100 h-100 bg-light" />
  }

  return (
    <div className={styles.tui}>
      <Header />
      <div className={styles.tuiMain}>
        {isSidebarVisible ? (
          <ReflexContainer orientation="vertical">
            <ReflexElement className="left-pane" flex={sidebar.isOpen ? 0.33 : 0}>
              <Sidebar
                demandTreeTableRef={demandTreeTableRef}
                loadTreeTableRef={loadTreeTableRef}
              />
            </ReflexElement>
            <ReflexSplitter
              className={styles.tuiReflexSplitter}
              style={{ backgroundColor: config.settings.backgroundColor }}
            />
            <ReflexElement className="right-pane">
              <ViewContainer
                demandTreeTableRef={demandTreeTableRef}
                loadTreeTableRef={loadTreeTableRef}
              />
            </ReflexElement>
          </ReflexContainer>
        ) : (
          <ViewContainer
            demandTreeTableRef={demandTreeTableRef}
            loadTreeTableRef={loadTreeTableRef}
          />
        )}
      </div>
      {config.settings.isServiceSelectionEnabled ? <ServiceModal /> : null}
    </div>
  )
}
