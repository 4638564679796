import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { slvyToast } from '../../../../components'
import AssortmentWizard from './AssortmentWizard/index'
import AssortmentMainFilters from '../AssortmentMainFilters'
import AssortmentSelection from './AssortmentSelection/index'
import AssortmentOptions from './AssortmentOptions/index'
import InformationForm from '../InformationForm'
import { getInvalidFields } from '../../utils'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { getOptions, resetOptions } from '../../store/slices/options'
import {
  toggleScenarioSelection,
  selectScenario,
  selectScenarioName
} from '../../store/slices/treeSelection'
import { saveAssortmentRequest } from '../../store/slices/assortment'
import { getTree } from '../../store/slices/tree'

const selectFromState = (state) => ({
  rootOptions: state.options.rootOptions,
  errorRootOptions: state.options.error,
  optionsIsLoading: state.options.isLoading,
  SaveAssortment: state.assortment.SaveAssortment,
  isLoadingSave: state.assortment.isLoadingSave,
  errorSaveAssortment: state.assortment.errorSave,
  selectedScenarioTree: state.treeSelection.tree,
  selectedScenarioClusterTree: state.treeSelection.clusterTree,
  tree: state.tree.tree,
  treeLoading: state.tree.treeLoading,
  minPresentationData: state.minPresentationData.minPresentationData
})

function CreateAssortment(props) {
  const dispatch = useDispatch()

  const {
    rootOptions,
    errorRootOptions,
    optionsIsLoading,
    SaveAssortment,
    isLoadingSave,
    errorSaveAssortment,
    selectedScenarioTree,
    selectedScenarioClusterTree,
    tree,
    treeLoading,
    minPresentationData
  } = useSelector(selectFromState)

  const {
    pluginId,
    size = {},
    onSaveAssortment = () => {},
    Description = '',
    dataKey = 'tree',
    onCreateScenarioToggle = () => {}
  } = props

  const initialSteps = [
    {
      id: 1,
      isValid: false,
      isActive: true,
      title: 'Select Filters'
    },
    {
      id: 2,
      isValid: false,
      isActive: false,
      title: 'Select Cluster'
    },
    {
      id: 3,
      isValid: false,
      isActive: false,
      title: 'Preview'
    },
    {
      id: 4,
      isValid: false,
      isActive: false,
      title: 'Create Assortment'
    }
  ]

  const [show, setShow] = useState(props.show)
  const [steps, setSteps] = useState(initialSteps)
  const [AssortmentId, setAssortmentId] = useState(null)
  const [filters, setFilters] = useState({})
  const [information, setInformation] = useState({
    name: '',
    description: ''
  })
  const [isWizardCompleted, setIsWizardCompleted] = useState(false)
  const [result, setResult] = useState({})
  const [assortmentSaved, setAssortmentSaved] = useState(false)
  const { AssortmentId: rootAssortmentId = null } = rootOptions
  const { currentScenario: { Id: selectedScenarioTreeId = null, ProjectId = null } = {} } =
    selectedScenarioTree
  const { message: errorSaveMessage = '' } = errorSaveAssortment
  const { message: errorRootMessage = '' } = errorRootOptions
  // is a object list
  const { SeasonGroup: SeasonGroups = {} } = minPresentationData

  const _steps = [...steps]
  const { id: activeStepId = null, title: activeStepTitle = '' } = _steps.filter(
    (step) => step.isActive
  )[0]

  useEffect(() => {
    dispatch(resetOptions())
  }, [])

  useEffect(() => {
    if (isLoadingSave) {
    } else if (!isLoadingSave && errorSaveAssortment && errorSaveAssortment.message) {
      slvyToast.error({ message: errorSaveAssortment.message, title: 'Load Options' })
      dispatch(setLoaderReducer({ isShown: false, messages: 'Create Assortment' }))
    } else if (!isLoadingSave && !_.isEmpty(SaveAssortment)) {
      const { Description: saveDescription = '', Code = null } = SaveAssortment
      slvyToast.success({ message: saveDescription, title: 'Create Assortment' })
      setAssortmentSaved(true)
      refreshLoad(Code)
      dispatch(setLoaderReducer({ isShown: false, messages: 'Create Assortment' }))
    }
  }, [SaveAssortment, errorSaveAssortment, isLoadingSave])

  useEffect(() => {
    if (assortmentSaved) {
      if (optionsIsLoading) {
        dispatch(setLoaderReducer({ isShown: true, messages: 'Load Options' }))
      } else {
        if (errorRootOptions && errorRootOptions.message) {
          slvyToast.error({ message: errorRootOptions.message, title: 'Load Options' })
        } else if (_.isEmpty(errorRootOptions) && !_.isEmpty(rootOptions)) {
          slvyToast.success({ message: Description, title: 'Load Options' })
          dispatch(setLoaderReducer({ isShown: false, messages: 'Load Options' }))
          dispatch(getTree({ pluginId, method: 'GetTree' }))
        }
      }
    }
  }, [rootOptions, errorRootOptions, optionsIsLoading])

  useEffect(() => {
    if (assortmentSaved && !_.isEmpty(rootOptions)) {
      if (treeLoading) {
        dispatch(setLoaderReducer({ isShown: true, messages: 'Loading' }))
        setResult({})
      } else if (!_.isEmpty(tree)) {
        const {
          Root: { Children = [] }
        } = tree
        findById(Children, rootAssortmentId)
      }
    }
  }, [tree, treeLoading, rootOptions])

  useEffect(() => {
    if (!_.isEmpty(result)) {
      const newScenarioItem = result
      const { Status = '', Name = '' } = newScenarioItem || {}
      onSelectScenario(Status, newScenarioItem, Name)
      dispatch(setLoaderReducer({ isShown: false, messages: 'Loading' }))
      onSaveAssortment()
      setAssortmentSaved(false)
      onCreateScenarioToggle(false)
    }
  }, [result])

  const onScenarioSelected = (isFolderSelected) => {
    handleStepClicked(2, {
      id: 2,
      isActive: true,
      isValid: !isFolderSelected,
      title: 'Select Cluster'
    })
    dispatch(resetOptions())
  }

  const onSaveAssortmentId = (newAssortmentId) => {
    setAssortmentId(newAssortmentId)
    if (newAssortmentId) {
      handleStepClicked(3, {
        id: 3,
        isActive: true,
        isValid: true,
        title: 'Preview'
      })
    }
  }

  const onFormInformationChanged = ({ key, value }) => {
    setInformation({
      ...information,
      [key]: value
    })
    const { name = '' } = information
    if (name) {
      handleStepClicked(4, {
        id: 4,
        isActive: true,
        isValid: true,
        title: 'Create Assortment'
      })
    }
  }

  const onAnyFilterChanged = (filters) => {
    const clonedFilters = _.cloneDeep(filters)
    setFilters(clonedFilters)

    const invalidFields = getInvalidFields(clonedFilters)
    const isValid = invalidFields.length === 0

    handleStepClicked(1, {
      id: 1,
      isActive: true,
      title: 'Select Filters',
      isValid
    })
  }

  const handleStepClicked = (id, stepItem = null) => {
    const _steps = steps.map((item) => {
      if (stepItem && stepItem.id === item.id) {
        item = { ...stepItem }
      } else {
        item.isActive = item.id === id
      }
      return item
    })
    setSteps(_steps)
  }

  const handleApplyClicked = () => {
    const { name: Name = '', description: applyDescription = '' } = information
    setIsWizardCompleted(true)

    saveAssortment({
      AssortmentId: rootAssortmentId,
      NodeStr: {
        MotherId: selectedScenarioTreeId,
        Name: Name.trim(),
        Description: applyDescription.trim(),
        ProjectId
      }
    })
  }

  const saveAssortment = (params) => {
    const payload = {
      pluginId,
      method: 'Save',
      requestMethod: 'post',
      body: {
        ...params
      }
    }

    dispatch(setLoaderReducer({ isShown: true, messages: 'Create Assortment' }))

    dispatch(saveAssortmentRequest({ ...payload }))
  }

  const refreshLoad = (nodeId) => {
    const payload = {
      pluginId,
      method: 'Load',
      requestMethod: 'post',
      body: {
        nodeId
      }
    }
    dispatch(getOptions({ ...payload }))
    setAssortmentSaved(true)
  }

  const findById = (data, Id) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].Id === Id) {
        setResult({ ...data[i] })
        break
      } else if (data[i].Children && data[i].Children.length) {
        findById(data[i].Children, Id)
      }
    }
  }

  const onSelectScenario = (Status, item, Name) => {
    dispatch(selectScenario({ dataKey, currentScenario: item }))

    dispatch(selectScenarioName({ dataKey, scenarioName: Name }))

    dispatch(
      toggleScenarioSelection({
        dataKey,
        scenarioName: Name,
        currentScenario: item,
        isFolderSelected: Status === 'FOLDER'
      })
    )
  }

  const goBack = (isShow) => {
    dispatch(resetOptions())
    onCreateScenarioToggle(isShow)
  }

  const hasSelected = ($selected) => {
    if ((_.isArray($selected) && $selected.length) || (!_.isArray($selected) && $selected)) {
      return true
    }
    return false
  }

  const _dependencyFilters = []
  Object.keys(filters).forEach((filterItem) => {
    const $currentSelected = filters[filterItem].selected
    if (hasSelected($currentSelected)) {
      _dependencyFilters.push({
        Key: filterItem,
        Value: _.isArray($currentSelected) ? $currentSelected : [$currentSelected]
      })
    }
  })

  return (
    <AssortmentWizard show={!isWizardCompleted}>
      <div className="wizardHeader">
        <Button
          className="-goBack"
          size="sm"
          type="button"
          variant="outline-dark"
          onClick={() => goBack(false)}
        >
          <span className="slvy-ui-icon-times-lt" />
        </Button>
        <div className="pageTitle">{activeStepTitle}</div>
        <AssortmentWizard.Header show steps={steps} onStepClicked={handleStepClicked} />
      </div>
      <AssortmentWizard.Body show steps={steps} onStepClicked={handleStepClicked}>
        <div className={`${activeStepId === 1 ? 'scrollable-container' : 'd-none'}`}>
          <AssortmentMainFilters
            firstAutoLoad
            isAllRequired={false}
            isVisibleApplyButton={false}
            pluginId={pluginId}
            seasonGroups={SeasonGroups}
            onAnyFilterChanged={onAnyFilterChanged}
          />
        </div>
        {activeStepId === 2 && (
          <div className="-assortment-selection h-100">
            <AssortmentSelection pluginId={pluginId} onScenarioSelected={onScenarioSelected} />
          </div>
        )}
        {activeStepId === 3 && (
          <AssortmentOptions
            dependencyFilters={_dependencyFilters}
            pluginId={pluginId}
            size={size}
            onSaveAssortmentId={onSaveAssortmentId}
          />
        )}
        {activeStepId === 4 ? (
          <InformationForm
            description={information && information.description}
            displayHeader={false}
            name={information && information.name}
            title="Create - Assortment Form"
            onFormInformationChanged={onFormInformationChanged}
          />
        ) : null}
      </AssortmentWizard.Body>
      <AssortmentWizard.Footer
        show
        steps={_steps}
        onApplyClicked={handleApplyClicked}
        onStepClicked={handleStepClicked}
      />
    </AssortmentWizard>
  )
}

export default CreateAssortment
