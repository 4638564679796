import { useCallback } from 'react'
import { GridRef } from '../../types'

function useGetDeletedRows(gridRef: GridRef) {
  const getDeletedRows = useCallback(() => {
    const context = gridRef?.current?.api?.getGridOption?.('context')
    return context?.deletedRows ?? []
  }, [gridRef])

  return { getDeletedRows }
}

export default useGetDeletedRows
