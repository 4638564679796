import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import Jobs from './jobs'
import { jobsWithInitialValue } from './constants'
import { IMenu, IMenuTree, IWork } from '../Common.types'
import { CanDrop, SortableTreeNode, TreeData } from './Workflow.types'

const getMenuListFromTree = (menuTree: IMenuTree[] = [], prevResult: IMenu[] = []): IMenu[] => {
  return _.reduce(
    menuTree,
    (result, { item, children }) => {
      if (!_.isEqual(item.transferObject, {})) {
        result.push(item)
      }
      return getMenuListFromTree(children, result)
    },
    prevResult
  )
}

const getTreeFromTransition = (
  transitions: IWork['transitions'],
  from: IWork['init'],
  init: IWork['init'],
  workName: IWork['name']
): SortableTreeNode[] => {
  const filtered = _.filter(transitions, (t) => t.from === from)
  if (!filtered) return []
  return _.map(filtered, (transition) => ({
    title: transition.name,
    workName,
    item: transition,
    expanded: true,
    children:
      transition.to !== init
        ? getTreeFromTransition(transitions, transition.to, init, workName)
        : []
  }))
}

const getTreeFromTransitionV2 = (
  transitions: IWork['transitions'],
  parentId: string,
  workName: IWork['name']
): SortableTreeNode[] => {
  const filtered = _.filter(transitions, (t) => t.parentId === parentId)
  if (!filtered) return []
  return _.map(filtered, (transition) => ({
    title: transition.name,
    workName,
    item: transition,
    expanded: true,
    children: getTreeFromTransitionV2(transitions, transition.id, workName)
  }))
}

const getTreeFromWorks = (works: IWork[]) => {
  return _.map(works, (work) => {
    const { transitions } = work
    const v2WF = _.find(transitions, (transition) => {
      return _.has(transition, 'id')
    })
    return {
      title: work.name,
      expanded: true,
      children: v2WF
        ? getTreeFromTransitionV2(work.transitions, '0', work.name)
        : getTreeFromTransition(work.transitions, work.init, work.init, work.name)
    }
  })
}

const createTransition = (
  children: SortableTreeNode['children'],
  pos: number,
  parentId: string | number = 0,
  nodeInfo = { currPosition: 1 }
) => {
  return _.reduce(
    children,
    (result: IWork['transitions'], child) => {
      const size = _.size(child.children)
      const nextPos = size > 0 ? pos + 1 : 1
      const id = uuidv4()

      result.push({
        ...child.item,
        from: `A${pos}`,
        to: `A${nextPos}`,
        fromV2: `A${nodeInfo.currPosition}`,
        toV2Success: `A${nodeInfo.currPosition + 1}`,
        toV2Fail: `A1`,
        id,
        parentId
      })

      // eslint-disable-next-line no-param-reassign
      nodeInfo.currPosition += 1
      if (nextPos > 1) {
        // eslint-disable-next-line no-param-reassign
        result = _.concat(result, createTransition(child.children, nextPos, id, nodeInfo))
      }

      return result
    },
    []
  )
}

const updateConditions = (
  transitions: IWork['transitions'],
  parentId: string | number = 0,
  nextParentNode: SortableTreeNode['item']
) => {
  const levelItems = _.filter(transitions, (transition) => {
    return transition.parentId === parentId
  })

  _.forEach(levelItems, (levelItem, index) => {
    if (index > 0 && levelItems[index - 1].name === 'condition') {
      levelItems[index - 1].toV2Fail = levelItem.fromV2
    }
    if (levelItem.name === 'condition') {
      if (index === _.size(levelItems) - 1) {
        // eslint-disable-next-line no-param-reassign
        levelItem.toV2Fail = nextParentNode ? nextParentNode.fromV2 : 'A1'
      }
    }

    let nextParent = nextParentNode
    if (index < _.size(levelItems) - 1) {
      nextParent = levelItems[index + 1]
    }
    updateConditions(transitions, levelItem.id, nextParent)
  })
}

const createWorkflow = (treeData: TreeData): IWork[] => {
  return _.map(treeData, (w) => {
    const transitions = createTransition(w.children, 1)
    updateConditions(transitions)

    return {
      name: w.title,
      init: `A1`,
      transitions
    }
  })
}

const getJobsWithIds = <T extends object>(data: T) => {
  return Object.keys(data).map((item, index) => ({ key: item, id: String(index) }))
}

const getJobTitle = (key: SortableTreeNode['title']) => {
  if (key === 'pageReady') {
    return 'Define Variables'
  }
  const found = _.find(Jobs, (item) => item.config.key === key)
  return found?.config?.title ?? key
}

const customSearchMethod = ({
  node,
  searchQuery
}: {
  node: SortableTreeNode
  searchQuery: string
}) => {
  const query = searchQuery.trim().toLowerCase()
  if (!query.length) {
    return false
  }

  const titleByKey = getJobTitle(node.title)
  const isTitleIncluded = (title: SortableTreeNode['title']) => title.toLowerCase().includes(query)
  return searchQuery && (isTitleIncluded(node.title) || isTitleIncluded(titleByKey))
}

const canDrop: CanDrop = (params) => {
  const arr = ['waitEvent', 'remoteActionCompleted', 'pageReady'] as const

  // Prevent dropping works that are not in arr to the more than 1 level down of the tree
  if (params.nextPath.length === 2) {
    if (arr.every((item) => item !== params.node.title)) {
      return false
    }
  }

  // Prevent dropping each work listed in arr to more than 1 level down of the tree
  if (params.nextPath.length > 2) {
    if (arr.some((item) => item === params.node.title)) {
      return false
    }
  }

  // Prevent dropping sub-level node to the first level of the tree
  if (params.nextParent === null && params.prevPath.length > 1) {
    return false
  }

  const { prevPath, nextPath } = params
  if (_.size(prevPath) === 1) {
    return _.size(nextPath) === 1
  }
  return true
}

const checkIsNodeEmpty = (node?: SortableTreeNode['item']) => {
  const { name, params = {} } = node ?? {}

  if (Object.keys(params).length === 0 && !jobsWithInitialValue.some((job) => job === name)) {
    return true
  }

  if (name === 'condition' && !params?.query?.rules?.length) {
    return true
  }

  if (name === 'runMethod' && !params?.methodKeys?.length && !params?.containerMethodKeys?.length) {
    return true
  }

  if (name === 'waitEvent' && !params?.eventKeys?.length && !params?.containerEventKeys?.length) {
    return true
  }

  if (
    (name === 'runRemoteAction' || name === 'runLongRunningAction') &&
    !params?.remoteActionKeys?.length
  ) {
    return true
  }

  if (name === 'generateKey' && params?.variable === '') {
    return true
  }

  if (name === 'updateVariables' && Object.keys(params?.variableValues ?? {}).length === 0) {
    return true
  }

  if (name === 'pageReady' && Object.keys(params?.defineVariables ?? {}).length === 0) {
    return true
  }

  if (name === 'delay' && params?.time === '') {
    return true
  }

  return false
}

export {
  getMenuListFromTree,
  getTreeFromTransition,
  getTreeFromTransitionV2,
  getTreeFromWorks,
  checkIsNodeEmpty,
  createWorkflow,
  getJobsWithIds,
  getJobTitle,
  customSearchMethod,
  canDrop
}
