import React, { Component, createRef } from 'react'

class OutsideClickHandler extends Component {
  wrapperRef = createRef()

  static defaultProps = {
    onOutsideClick: () => {}
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.props.onOutsideClick(event)
    }
  }

  render() {
    const { children } = this.props

    return (
      <div ref={this.wrapperRef} className="w-100 h-100">
        {children}
      </div>
    )
  }
}

export default OutsideClickHandler
