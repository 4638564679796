Ext.define('MDM.btlv2.upload.Edit.Grid', {
  extend: 'Ext.grid.Panel',
  xtype: 'btl-upload-processedgrid',
  reference: 'processedgrid',
  // Uncomment to give this component an xtype
  header: false,
  lockedGridConfig: {
    width: 500,
    resizable: false
  },
  defaultListenerScope: true,
  viewConfig: {
    getRowClass: function (record) {
      if (record.get('criticalError')) return 'criticalError'
      if (!record.get('isValid')) return 'NotValid'
      return ''
    },
    loadMask: false,
    stripeRows: true
  },
  selModel: {
    type: 'checkboxmodel',
    checkOnly: true,
    listeners: {
      beforeselect: function (el, record) {
        return record.get('isValid')
      }
    }
  },
  onShowDialog: function (grid, rowIndex, colIndex, item, e, record) {
    console.log(record)
    var win = Ext.create('MDM.src.view.massimport.FormDialog', {
      record: record,
      isMass: false
    })
    if (this.menu_grid) this.menu_grid.destroy()
    this.menu_grid = new Ext.menu.Menu({
      bodyPadding: 2,
      border: false,
      shadow: false,
      items: [win]
    })
    var position = e.getXY()
    ;(position[1] = position[1] + 20), e.stopEvent()
    this.menu_grid.showAt(position)
  },
  initComponent: function () {
    var me = this,
      importType = me.importType
    me.columns = []
    if (importType == 'D' || importType == 'I') {
      me.columns = MDM.Btl.Column.build([
        {
          text: 'BTLTYPE',
          dataIndex: 'BTLType',
          locked: true,
          width: 50
        },
        {
          text: 'Reason',
          dataIndex: 'Reason',
          locked: true,
          renderer: function (value, metaData, record) {
            if (!record.get('Reason')) {
              metaData.tdCls = 'cell-yellow-warnig'
            }
            return value
          },
          width: 70
        },
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          locked: true,
          resizable: false,
          width: 50,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-circle',
              isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                return rec.get('criticalError')
              },
              getClass: function (v, meta, rec) {
                if (!rec.get('criticalError')) {
                  return 'fa fa-pencil'
                }
                return ''
              },
              handler: 'onShowDialog'
            }
          ]
        },
        {
          text: 'DPIFamily',
          dataIndex: 'DPIFamily',
          locked: true,
          width: 100
        },
        {
          text: 'MaterialCode',
          dataIndex: 'MaterialCode',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record) {
            // style the cell differently for even / odd values
            if (value || record.get('MaterialDescription')) {
              if (!record.get('MaterialID')) {
                metaData.tdCls = 'cell-yellow-warnig'
              }
            }
            return value
          }
        },
        {
          text: 'MaterialDescription',
          dataIndex: 'MaterialDescription',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record) {
            // style the cell differently for even / odd values
            if (value || record.get('MaterialCode')) {
              if (!record.get('MaterialID')) {
                metaData.tdCls = 'cell-yellow-warnig'
              }
            }
            return value
          }
        },
        {
          text: 'MaterialType',
          dataIndex: 'MaterialType',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record) {
            // style the cell differently for even / odd values
            if (!record.get('MaterialTypeID')) metaData.tdCls = 'cell-warnig'
            return value
          }
        },
        {
          text: 'Comment',
          dataIndex: 'Comment',
          locked: true,
          width: 100
        }
      ])
    } else if (importType == 'P') {
      me.columns = MDM.Btl.Column.build([
        {
          text: 'BTLTYPE',
          dataIndex: 'BTLType',
          locked: true,
          width: 50
        },
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          locked: true,
          resizable: false,
          width: 50,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-circle',
              isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                return rec.get('criticalError')
              },
              getClass: function (v, meta, rec) {
                if (!rec.get('criticalError')) {
                  return 'fa fa-pencil'
                }
                return ''
              },
              handler: 'onShowDialog'
            }
          ]
        },
        {
          text: 'DPIFamily',
          dataIndex: 'DPIFamily',
          locked: true,
          width: 100
        },
        {
          text: 'MaterialCode',
          dataIndex: 'MaterialCode',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record) {
            // style the cell differently for even / odd values
            if (value || record.get('MaterialDescription')) {
              if (!record.get('MaterialID')) {
                metaData.tdCls = 'cell-yellow-warnig'
              }
            }
            return value
          }
        },
        {
          text: 'MaterialDescription',
          dataIndex: 'MaterialDescription',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record) {
            // style the cell differently for even / odd values
            if (value || record.get('MaterialCode')) {
              if (!record.get('MaterialID')) {
                metaData.tdCls = 'cell-yellow-warnig'
              }
            }
            return value
          }
        },
        {
          text: 'SupplySource',
          dataIndex: 'SupplySource',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record) {
            // style the cell differently for even / odd values
            if (!record.get('SupplySourceID')) metaData.tdCls = 'cell-warnig'
            return value
          }
        },
        {
          text: 'MaterialType',
          dataIndex: 'MaterialType',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record) {
            // style the cell differently for even / odd values
            if (!record.get('MaterialTypeID')) metaData.tdCls = 'cell-warnig'
            return value
          }
        },
        {
          text: 'Comment',
          dataIndex: 'Comment',
          locked: true,
          width: 100
        }
      ])
    } else if (importType == 'X') {
      me.columns = MDM.Btl.Column.build([
        {
          text: 'BTLTYPE',
          dataIndex: 'BTLType',
          locked: true,
          width: 50
        },
        {
          menuDisabled: false,
          sortable: true,
          xtype: 'actioncolumn',
          locked: true,
          resizable: false,
          width: 50,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-circle',
              isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                return rec.get('criticalError')
              },
              getClass: function (v, meta, rec) {
                if (!rec.get('criticalError')) {
                  return 'fa fa-pencil'
                }
                return ''
              },
              handler: 'onShowDialog'
            }
          ]
        },
        {
          text: 'DPIFamily',
          dataIndex: 'DPIFamily',
          locked: true,
          width: 100
        },
        {
          text: 'From Region',
          dataIndex: 'FromRegion',
          locked: true,
          width: 100
        },
        {
          text: 'To Region',
          dataIndex: 'ToRegion',
          locked: true,
          width: 100
        },
        {
          text: 'MaterialCode',
          dataIndex: 'MaterialCode',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record, rowIndex) {
            // style the cell differently for even / odd values
            if (value || record.get('MaterialDescription')) {
              if (!record.get('MaterialID')) {
                metaData.tdCls = 'cell-yellow-warnig'
              }
            }
            return value
          }
        },
        {
          text: 'MaterialDescription',
          dataIndex: 'MaterialDescription',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record, rowIndex) {
            // style the cell differently for even / odd values
            if (value || record.get('MaterialCode')) {
              if (!record.get('MaterialID')) {
                metaData.tdCls = 'cell-yellow-warnig'
              }
            }
            return value
          }
        },
        {
          text: 'SupplySource',
          dataIndex: 'SupplySource',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record, rowIndex) {
            // style the cell differently for even / odd values
            if (!record.get('SupplySourceID')) metaData.tdCls = 'cell-warnig'
            return value
          }
        },
        {
          text: 'MaterialType',
          dataIndex: 'MaterialType',
          locked: true,
          width: 100,
          renderer: function (value, metaData, record, rowIndex) {
            // style the cell differently for even / odd values
            if (!record.get('MaterialTypeID')) metaData.tdCls = 'cell-warnig'
            return value
          }
        },
        {
          text: 'Comment',
          dataIndex: 'Comment',
          locked: true,
          width: 100
        }
      ])
    }
    me.callParent()
  }
})
