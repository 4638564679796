import { useMemo } from 'react'
import { getSelectionCellParams } from '../../helpers'
import { TfieldConfigs } from '../../types'

function useGetSelectionCellParams(fieldConfigs: TfieldConfigs) {
  const cellParams = useMemo(() => {
    return getSelectionCellParams(fieldConfigs)
  }, [fieldConfigs])

  return { cellParams }
}

export default useGetSelectionCellParams
