import notificationSound from '../assets/sounds/notification-bell.mp3'

let soundPermissionGranted = false

const requestNotificationPermission = async () => {
  if ('Notification' in window && Notification.permission !== 'granted') {
    const permission = await Notification.requestPermission()
    soundPermissionGranted = permission === 'granted'
  }
}

const playNotificationSound = () => {
  requestNotificationPermission()
  if ('Audio' in window && soundPermissionGranted) {
    const audio = new Audio(notificationSound)
    audio.play()
  }
}

window.playNotificationSound = playNotificationSound

export default playNotificationSound
