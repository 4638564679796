import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash'
import request from 'superagent'
import { v4 as uuidv4 } from 'uuid'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { API_URL } from '@/constants'
import './style.scss'

class DailySchedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      calendarData: {}
    }
    this.changeHandle = this.changeHandle.bind(this)
    this.firstClickHandle = this.firstClickHandle.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.handleDataUpdated = this.props.registerEvent({
      key: 'DataUpdated',
      fn: this.handleDataUpdated.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    this.props.registerMethod({
      key: 'saveChanges',
      fn: this.handleSaveChanges.bind(this),
      args: [{ name: 'refreshKey', type: PluginTypes.string }]
    })
  }

  handleSaveChanges() {
    const { calendarDate, calendarData } = this.state
    const {
      settings: {
        config: {
          settings: { blockHours = false } = {},
          columns: {
            date: dateField,
            name: nameField,
            hourIndexStart: hourIndexStartField,
            hourIndexEnd: hourIndexEndField
          } = {}
        } = {}
      } = {}
    } = this.props

    if (blockHours) {
      const updateItems = []
      _.forEach(calendarData, (calendarRow, rowTitle) => {
        const hourBlocks = this.getBlocks(calendarRow)
        const rowUpdateItems = _.map(hourBlocks, (hourBlock, index) => {
          return {
            columnName: hourIndexStartField,
            config: {
              [dateField]: moment(calendarDate).format('YYYY-MM-DD HH:mm:ss'),
              [nameField]: rowTitle,
              [hourIndexStartField]: _.first(hourBlock),
              [hourIndexEndField]: _.last(hourBlock) + 1,
              __BlockIndex: index
            },
            oldValue: 0
          }
        })
        if (_.size(rowUpdateItems) === 0) {
          rowUpdateItems.push({
            columnName: hourIndexStartField,
            config: {
              [dateField]: moment(calendarDate).format('YYYY-MM-DD HH:mm:ss'),
              [nameField]: rowTitle,
              [hourIndexStartField]: 0,
              [hourIndexEndField]: 0,
              __BlockIndex: 0
            },
            oldValue: 0
          })
        }
        updateItems.push(...rowUpdateItems)
      })

      const updateData = {
        updateItems
      }
      this.updateValue(updateData)
      this.handleDataUpdated()
    }
  }

  handleDataUpdated() {
    return { refreshKey: uuidv4() }
  }

  firstClickHandle(e, rowTitle) {
    const { calendarData } = this.state
    const { checked } = e.target

    calendarData[rowTitle] = []
    if (checked) {
      _.forEach(new Array(24), (item, index) => {
        calendarData[rowTitle].push(index)
      })
    }

    this.setState({ calendarData })

    const {
      settings: {
        config: {
          settings: { blockHours = false, editingType = 'Direct' } = {},
          columns: {
            date: dateField,
            name: nameField,
            value: valueField,
            state: stateField,
            hourIndexStart: hourIndexStartField,
            hourIndexEnd: hourIndexEndField
          } = {}
        } = {}
      } = {}
    } = this.props

    if (editingType === 'Direct') {
      const { calendarDate } = this.state

      if (!blockHours) {
        const updateItems = _.map(new Array(24), (item, index) => {
          return {
            columnName: stateField,
            config: {
              [dateField]: moment(calendarDate).format('YYYY-MM-DD HH:mm:ss'),
              [nameField]: rowTitle,
              [valueField]: index,
              [stateField]: checked ? 1 : 0
            },
            oldValue: checked ? 0 : 1
          }
        })

        const updateData = {
          updateItems
        }
        this.updateValue(updateData)
      } else {
        const updateData = {
          updateItems: [
            {
              columnName: hourIndexStartField,
              config: {
                [dateField]: moment(calendarDate).format('YYYY-MM-DD HH:mm:ss'),
                [nameField]: rowTitle,
                [hourIndexStartField]: 0,
                [hourIndexEndField]: checked ? 24 : 0,
                __BlockIndex: 0
              },
              oldValue: 0
            }
          ]
        }
        this.updateValue(updateData)
      }
    }
  }

  changeHandle(e, obj, update) {
    const { rowTitle, hourIndex: selectedHourIndex } = obj
    const { calendarData } = this.state
    const { checked } = e.target

    if (checked) {
      if (!_.has(calendarData, rowTitle)) {
        calendarData[rowTitle] = []
      }
      calendarData[rowTitle].push(selectedHourIndex)
    } else {
      _.remove(calendarData[rowTitle], (hourIndex) => {
        return hourIndex === selectedHourIndex
      })
    }

    this.setState({ calendarData })

    const {
      settings: {
        config: {
          settings: { blockHours = false, editingType = 'Direct' } = {},
          columns: {
            date: dateField,
            name: nameField,
            value: valueField,
            state: stateField,
            hourIndexStart: hourIndexStartField,
            hourIndexEnd: hourIndexEndField
          } = {}
        } = {}
      } = {}
    } = this.props

    if (editingType === 'Direct') {
      const { calendarDate } = this.state

      if (!blockHours) {
        const updateData = {
          updateItems: [
            {
              columnName: stateField,
              config: {
                [dateField]: moment(calendarDate).format('YYYY-MM-DD HH:mm:ss'),
                [nameField]: rowTitle,
                [valueField]: selectedHourIndex,
                [stateField]: checked ? 1 : 0
              },
              oldValue: checked ? 0 : 1
            }
          ]
        }

        this.updateValue(updateData)
      } else {
        const hourBlocks = this.getBlocks(calendarData[rowTitle])
        const updateItems = _.map(hourBlocks, (hourBlock, index) => {
          return {
            columnName: hourIndexStartField,
            config: {
              [dateField]: moment(calendarDate).format('YYYY-MM-DD HH:mm:ss'),
              [nameField]: rowTitle,
              [hourIndexStartField]: _.first(hourBlock),
              [hourIndexEndField]: _.last(hourBlock) + 1,
              __BlockIndex: index
            },
            oldValue: 0
          }
        })

        if (_.size(updateItems) === 0) {
          updateItems.push({
            columnName: hourIndexStartField,
            config: {
              [dateField]: moment(calendarDate).format('YYYY-MM-DD HH:mm:ss'),
              [nameField]: rowTitle,
              [hourIndexStartField]: 0,
              [hourIndexEndField]: 0,
              __BlockIndex: 0
            },
            oldValue: 0
          })
        }

        const updateData = {
          updateItems
        }
        this.updateValue(updateData)
      }
    }
  }

  getBlocks(hours) {
    const orderedHours = _.sortBy(hours)

    const hourBlocks = []
    let lastBlock = []

    _.forEach(orderedHours, (hour) => {
      if (_.size(lastBlock) === 0) {
        lastBlock.push(hour)
      } else if (_.indexOf(lastBlock, hour - 1) >= 0) {
        lastBlock.push(hour)
      } else {
        hourBlocks.push(lastBlock)
        lastBlock = []
        lastBlock.push(hour)
      }
    })

    if (_.size(lastBlock) > 0) {
      hourBlocks.push(lastBlock)
    }

    return hourBlocks
  }

  getDataInCellMode(data, nameField, valueField, stateField) {
    const calendarData = _.groupBy(
      _.map(data, (row) => {
        return {
          rowTitle: row[nameField],
          hourIndex: row[valueField],
          active: row[stateField]
        }
      }),
      (row) => {
        return row.rowTitle
      }
    )

    return _.transform(
      calendarData,
      (res, calendarRow, rowTitle) => {
        const calendarRowNew = _.transform(
          calendarRow,
          (res, calendarCell) => {
            if (calendarCell.active) {
              res.push(calendarCell.hourIndex)
            }
            return res
          },
          []
        )

        res[rowTitle] = calendarRowNew
      },
      {}
    )
  }

  getDataInBlockMode(data, nameField, hourIndexStartField, hourIndexEndField) {
    const calendarData = _.groupBy(
      _.map(data, (row) => {
        return {
          rowTitle: row[nameField],
          hourIndexStart: row[hourIndexStartField],
          hourIndexEnd: row[hourIndexEndField]
        }
      }),
      (row) => {
        return row.rowTitle
      }
    )

    const ret = _.transform(
      calendarData,
      (res, calendarRow, rowTitle) => {
        const hours = []

        _.forEach(calendarRow, (calendarCell) => {
          for (let i = calendarCell.hourIndexStart; i < calendarCell.hourIndexEnd; i++) {
            hours.push(i)
          }
        })
        res[rowTitle] = hours
      },
      {}
    )

    return ret
  }

  updateValue(updateData) {
    const {
      id,
      params: { environment },
      token,
      actualFilters = {}
    } = this.props

    const that = this

    request
      .post(`${API_URL}/data/plugin/${id}/update`)
      .send({
        ...updateData,
        filters: actualFilters
      })
      .set({
        environment,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
      .then((res) => {
        that.handleDataUpdated()
      })
      .catch((err) => {
        console.error(`Cannot update Daily Schedule data: ${err.message}`)
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      settings: {
        config: {
          settings: { blockHours = false } = {},
          columns: {
            date: dateField,
            name: nameField,
            value: valueField,
            state: stateField,
            hourIndexStart: hourIndexStartField,
            hourIndexEnd: hourIndexEndField
          } = {}
        } = {}
      } = {},
      pluginData = []
    } = nextProps

    let calendarData = {}
    if (blockHours) {
      calendarData = this.getDataInBlockMode(
        pluginData,
        nameField,
        hourIndexStartField,
        hourIndexEndField
      )
    } else {
      calendarData = this.getDataInCellMode(pluginData, nameField, valueField, stateField)
    }

    const calendarDate = _.size(pluginData) > 0 ? pluginData[0][dateField] : ''

    if (!_.isEqual(this.props.pluginData, nextProps.pluginData)) {
      this.setState({ calendarData, calendarDate })
    }
  }

  render() {
    const { settings: { config: { settings: { backgroundcolor = '#fff' } = {} } = {} } = {} } =
      this.props

    const { calendarData, calendarDate } = this.state

    const filteredDate = moment(calendarDate)

    const slicedHours = _.map(new Array(24), (item, index) => {
      return {
        view: moment(filteredDate).add(index, 'hours').format('HH:mm'),
        index
      }
    })

    return (
      <div className="ds-container">
        <div className="daily-schedule" style={{ backgroundColor: backgroundcolor }}>
          {_.size(calendarData) > 0 && (
            <div>
              <h1>{filteredDate.format('DD MMMM')}</h1>
              <div className="ds-row">
                <ul className="ds-timeline">
                  <li className="ds-first-item">&nbsp;</li>
                  {_.map(slicedHours, (item, index) => {
                    return <li key={index}>{item.view}</li>
                  })}
                </ul>
              </div>

              <div className="ds-row">
                {_.map(calendarData, (calendarRow, rowTitle) => {
                  const isFirstCheck = _.size(calendarRow) === 24

                  return (
                    <ul key={rowTitle} className="ds-timeline mx">
                      <li className="ds-first-item">
                        <input
                          checked={isFirstCheck}
                          id={`first_${rowTitle}`}
                          type="checkbox"
                          onChange={(that) => {
                            this.firstClickHandle(that, rowTitle)
                          }}
                        />
                        <label htmlFor={`first_${rowTitle}`}>{rowTitle}</label>
                      </li>
                      {_.map(slicedHours, (item, index) => {
                        const isCheck = _.indexOf(calendarRow, item.index) >= 0

                        return (
                          <li key={`chk_${rowTitle}_${index}`}>
                            <input
                              checked={isCheck}
                              id={`chk_${rowTitle}_${index}`}
                              type="checkbox"
                              onChange={(that) => {
                                this.changeHandle(
                                  that,
                                  {
                                    rowTitle,
                                    hourIndex: index
                                  },
                                  true
                                )
                              }}
                            />
                            <label htmlFor={`chk_${rowTitle}_${index}`} />
                          </li>
                        )
                      })}
                    </ul>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  settings: props.settings,
  pluginData: props.pluginData,
  id: props.id,
  params: props.params,
  token: props.token,
  actualFilters: props.actualFilters
})

export default createPlugin(DailySchedule, selectConnectorProps)
