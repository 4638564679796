import { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { Row } from 'react-bootstrap'
import toastr from 'toastr'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { Navigation, NavigationBar } from '../../components'
import LayoutDock from './components/Dock'

/* Crud */
import { AsyncConnect, select, selectCollection } from '../../crudoptV3'
import { getCatalog } from '@/actions/catalog'
import { getMenus } from '@/actions/menu'
import { getUserSetting, addFavorite } from '@/actions/userSetting'
import { getCustomerEnvironments } from '@/actions/customer'
import { setConnectionEnvironment } from '@/store/slices/setting'
import { setMenus } from '@/store/slices/menus'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.setPageTitle = this.setPageTitle.bind(this)
  }

  componentDidMount() {
    const {
      initialMenus: { data },
      setMenus
    } = this.props
    const opt = {
      closeMethod: 'fadeOut',
      closeDuration: 1000,
      timeOut: 4000,
      extendedTimeOut: 1000,
      closeButton: true
    }

    toastr.options = { ...toastr.options, ...opt }

    setMenus(data)
    this.setPageTitle()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      initialMenus: { fetchKey }
    } = this.props
    const {
      initialMenus: { data, fetchKey: nextFetchKey },
      setMenus
    } = nextProps

    if (fetchKey !== nextFetchKey) {
      setMenus(data)
    }
  }

  componentWillUnmount() {
    document.title = 'Solvoyo | Supply Chain Planning and Optimization, Cloud Based, SaaS'
  }

  componentDidUpdate(prevProps) {
    const { loading: { action: prevLoadingAction = false } = {} } = prevProps
    const { loading: { action: loadingAction = false } = {} } = this.props
    if (prevLoadingAction !== loadingAction) {
      this.setDocumentClass(loadingAction)
    }
  }

  setPageTitle() {
    const { catalog: { data: catalogData = [] } = {}, params: { menuId = '' } = {} } = this.props

    if (!menuId) {
      document.title = catalogData.name
    }
  }

  setDocumentClass = (action) =>
    global.document?.documentElement?.classList[action ? 'add' : 'remove']('layout-loading-overlay')

  render() {
    const {
      routerLocation,
      params: { catalogId, storeIndex, menuId, environment = '' },
      initialMenus: { data: initialMenus },
      menus,
      catalog: { data: catalogData },
      userSetting: { data: userSettingData },
      customerEnvironments: { data: customerEnvironmentsData },
      user,
      loading: { text: loadingText = '', action: loadingAction = false } = {}
    } = this.props

    const session = jwtDecode(this.props.user.access_token)
    const { customer, customerid } = session
    const isLayoutDockVisible = customerid == '1' || customer == '1'
    const currentMenus = menus.length ? menus : initialMenus

    if (environment !== 'Live') {
      if (!_.includes(session.Mode, environment)) {
        const link = window.location.pathname.replace(/^(\/.*?\/)/gm, '/Live/')
        this.props.navigate(link)
      }
    }

    const isNotificationsPageActive = Boolean(
      routerLocation?.pathname?.split('/')?.find((item) => item === 'notifications')
    )

    return (
      <div className={`${catalogData.theme}`}>
        <Navigation
          addFavorite={this.props.addFavorite}
          catalog={catalogData}
          catalogId={catalogId}
          customerEnvironments={customerEnvironmentsData}
          environment={environment}
          menuId={menuId}
          menus={currentMenus}
          setConnectionEnvironment={this.props.setConnectionEnvironment}
          storeIndex={storeIndex}
          user={user}
          userSetting={userSettingData}
        />
        <div className="gray-bg" id="page-wrapper">
          <Row>
            <NavigationBar
              catalog={catalogData}
              isNotificationsPageActive={isNotificationsPageActive}
            />
          </Row>
          <Outlet />
          {loadingAction && (
            <div className="layout-loading">
              <i />
              <div>{loadingText}</div>
            </div>
          )}
        </div>
        {isLayoutDockVisible && <LayoutDock />}
      </div>
    )
  }
}

export default AsyncConnect(
  {
    initialMenus: (state, ownProps) =>
      selectCollection(getMenus(ownProps.params.catalogId), state.model3),
    catalog: (state, ownProps) => select(getCatalog(ownProps.params.catalogId), state.model3),
    userSetting: (state, ownProps) =>
      select(getUserSetting(ownProps.params.catalogId), state.model3),
    customerEnvironments: () => {
      return { needFetch: false }
    }
  },
  (state, ownProps) => {
    const catalog = select(getCatalog(ownProps.params.catalogId), state.model3)
    const { data: { customerId = 0 } = {} } = catalog

    return {
      loading: state.setting.loading,
      customerEnvironments: selectCollection(
        getCustomerEnvironments(customerId),
        state.model3,
        customerId > 0
      )
    }
  }
)(
  connect(
    (state, ownProps) => {
      return {
        user: state.oidc.user,
        menus: state.menus
      }
    },
    (dispatch) => ({
      ...bindActionCreators({ addFavorite, setConnectionEnvironment, setMenus }, dispatch)
    })
  )(Layout)
)
