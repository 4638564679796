import { put, select } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import pubsub from '../../../subs'
import { addMessage } from '../../../store/slices/logger'
import { CHANGE_CONNECTION } from '../actionTypes'

export default function* runMethod(props = {}, result, action) {
  let { methodKeys = [], containerMethodKeys = [] } = props
  const {
    payload: {
      params: { storeIndex },
      relations = {},
      registers = {}
    }
  } = action
  const { type } = result

  const { relationReducers = {} } = yield select()

  const { stores = [] } = relationReducers
  const store = stores[storeIndex] || {}
  const {
    global: { variables: globalVariables = {} } = {},
    page: { variables: pageVariables = {} } = {},
    menu: { variables: menuVariables = {} } = {}
  } = store || {}

  const variables = {
    ...pageVariables,
    ...menuVariables,
    ...globalVariables,
    ...result
  }

  if (_.size(containerMethodKeys) > 0) {
    for (let i = 0, len = containerMethodKeys.length; i < len; i++) {
      const { id: containerId, methodKey } = containerMethodKeys[i]
      const { methods: { [containerId]: { [methodKey]: methodRelations } = {} } = {} } = relations
      const args = _.transform(
        methodRelations,
        (r, v, k) => {
          if (!_.isNil(variables[v])) {
            r[k] = variables[v]
          }
        },
        {}
      )
      try {
        yield pubsub.publish(`${methodKey}${containerId}`, args)
      } catch (err) {
        console.error(err)
      }
    }
  }

  if (_.size(containerMethodKeys) <= 0 && _.size(methodKeys) <= 0) {
    methodKeys = _.transform(
      registers,
      (r, { methods = {} } = {}, rpluginKey) => {
        _.each(methods, ({ params }, rmethodKey) => {
          if (
            (!_.isNil(relations.methods) &&
              !_.isNil(relations.methods[rpluginKey]) &&
              !_.isNil(relations.methods[rpluginKey][rmethodKey])) ||
            rmethodKey === 'setDataArguments'
          ) {
            return r.push({ pluginId: rpluginKey, methodKey: rmethodKey })
          }
        })
      },
      []
    )
  }

  const msg = []
  const sortedMethodKeys = _.sortBy(methodKeys, ({ methodKey }) => methodKey === 'exportData')
  _.each(sortedMethodKeys, ({ pluginId, methodKey }) => {
    const { methods: { [pluginId]: { [methodKey]: methodRelations } = {} } = {} } = relations
    const args = _.transform(
      methodRelations,
      (r, v, k) => {
        if (!_.isNil(variables[v])) {
          r[k] = variables[v]
        }
      },
      {}
    )

    if (type === CHANGE_CONNECTION) {
      args.refreshConnectionKey = uuidv4()
    }

    if (window.ctrlshiftk) {
      msg.push({
        title: `${methodKey} - ${pluginId}`,
        text: _.reduce(
          args,
          (r, v, k) => {
            return `${r}<br /><label>${k}</label>: ${v}</i>`
          },
          ''
        )
      })
    }

    try {
      pubsub.publish(`${methodKey}${pluginId}`, args)
    } catch (err) {
      console.error(err)
    }
  })
  if (window.ctrlshiftk) {
    for (let j = 0; j < msg.length; j++) {
      yield put(addMessage({ flag: 'Method', title: msg[j].title, message: msg[j].text }))
    }
  }
  return result
}
