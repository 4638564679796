export const general = {
  type: 'object',
  title: 'General',
  properties: {
    name: {
      type: 'string',
      title: 'Name'
    }
  }
} as const

export const layout = {
  type: 'object',
  title: 'Layout',
  properties: {
    mode: {
      title: 'Mode',
      type: 'string',
      enum: ['VERTICAL', 'VERTICAL_ALTERNATING', 'HORIZONTAL'],
      default: 'VERTICAL',
      required: true
    },
    flipLayout: {
      title: 'Flip',
      type: 'boolean',
      default: false,
      required: true
    },
    cardLess: {
      title: 'CardLess',
      type: 'boolean',
      default: false,
      required: true
    }
  }
} as const

const fontSize = {
  type: 'object',
  title: 'Font Size',
  properties: {
    timeline: {
      type: 'object',
      title: 'Timeline',
      properties: {
        title: {
          type: 'number',
          title: 'Title',
          default: 16
        },
        icon: {
          type: 'number',
          title: 'Icon',
          default: 16
        }
      }
    },
    card: {
      type: 'object',
      title: 'Card',
      properties: {
        title: {
          type: 'number',
          title: 'Title',
          default: 20
        },
        text: {
          type: 'number',
          title: 'Text',
          default: 14
        },
        author: {
          type: 'number',
          title: 'Author',
          default: 12
        }
      }
    }
  }
} as const

const color = {
  type: 'object',
  title: 'Color',
  properties: {
    timeline: {
      type: 'object',
      title: 'Timeline',
      properties: {
        title: {
          type: 'string',
          title: 'Title',
          format: 'Color',
          default: '#000'
        },
        activeTitle: {
          type: 'string',
          title: 'Active Title',
          format: 'Color',
          default: '#0f52ba'
        },
        background: {
          type: 'string',
          title: 'Background',
          format: 'Color',
          default: '#ffdf00'
        },
        line: {
          type: 'string',
          title: 'Line',
          format: 'Color',
          default: '#0f52ba'
        },
        icon: {
          type: 'string',
          title: 'Icon',
          format: 'Color',
          default: '#0f52ba'
        }
      }
    }
  }
} as const

export const styles = {
  type: 'object',
  title: 'Styles',
  properties: {
    fontSize,
    color
  }
} as const

const timelineData = {
  type: 'object',
  title: 'Timeline',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
      watch: {
        ds: 'data.datafields'
      },
      enumSource: 'ds',
      default: ''
    },
    icon: {
      type: 'string',
      title: 'Icon',
      watch: {
        ds: 'data.datafields'
      },
      enumSource: 'ds',
      default: ''
    }
  }
} as const

const cardData = {
  type: 'object',
  title: 'Card',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
      watch: {
        ds: 'data.datafields'
      },
      enumSource: 'ds',
      default: ''
    },
    text: {
      type: 'string',
      title: 'Text',
      watch: {
        ds: 'data.datafields'
      },
      enumSource: 'ds',
      default: ''
    },
    author: {
      type: 'string',
      title: 'Author',
      watch: {
        ds: 'data.datafields'
      },
      enumSource: 'ds',
      default: ''
    },
    isReadOnly: {
      type: 'string',
      title: 'Read Only',
      watch: {
        ds: 'data.datafields'
      },
      enumSource: 'ds',
      default: ''
    }
  }
} as const

export const data = {
  type: 'object',
  title: 'Data',
  properties: {
    datafields: {
      type: 'array',
      format: 'HiddenTable',
      title: 'Data Fields',
      items: {
        type: 'string',
        title: 'Field'
      }
    },
    timeline: timelineData,
    card: cardData
  }
} as const

const addButton = {
  type: 'object',
  title: 'Add Button',
  properties: {
    icon: {
      type: 'string',
      title: 'Icon',
      format: 'IconSelector',
      default: 'fa fa-plus-circle'
    },
    size: {
      type: 'number',
      title: 'Size',
      default: 32
    },
    color: {
      type: 'string',
      title: 'Color',
      format: 'Color',
      default: '#0f52ba'
    }
  }
} as const

export const add = {
  type: 'object',
  title: 'Add',
  properties: {
    enable: {
      type: 'boolean',
      title: 'Enable',
      default: false,
      required: true
    },
    button: addButton
  }
} as const

export default () => ({
  title: 'ChronoTimeline',
  type: 'object',
  format: 'objectTab',
  properties: {
    general,
    layout,
    styles,
    data,
    add
  }
})
