Ext.define('MDM.src.view.globalapp.Panel.Geo.Panel.EchartsMap', {
  extend: 'MDM.src.ux.Echarts.Echarts',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-EchartsMap',
  config: {
    chartData: {
      data: [],
      pieces: []
    }
  },
  applyChartData: function (data) {
    var myChart = this.getEcharts()
    var result = {
      data: data.data,
      errors: [],
      meta: {
        delimiter: ',',
        linebreak: '\n',
        aborted: false,
        truncated: false,
        cursor: 2394
      }
    }
    var nameMap = {
      'United States': 'United States of America'
    }
    myChart.setOption({
      roam: true,
      backgroundColor: '#fff',
      title: {
        text: 'Gillette',
        subtext: 'Mega Regions',
        left: 'center',
        top: 5,
        itemGap: 0,
        textStyle: {
          color: '#000'
        },
        z: 200
      },
      series: [
        {
          type: 'map',
          map: 'world',
          //silent: true,
          top: 10,
          bottom: 10,
          label: {
            normal: {
              show: false,
              textStyle: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontFamily: 'sans-serief',
                fontSize: 2
              }
            },
            emphasis: {
              show: true
            }
          },
          data: result.data.map(function (item) {
            return {
              name: nameMap[item[0]] || item[0],
              value: item[1]
            }
          })
        }
      ],
      visualMap: {
        type: 'piecewise',
        min: 0,
        max: 1,
        splitNumber: 3,
        left: 'left',
        top: 'bottom',
        color: ['orangered', 'yellow', 'lightskyblue'],
        textStyle: {
          color: '#000'
        },
        pieces: data.pieces
      }
    })
  }
})
