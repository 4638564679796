import crawl from 'tree-crawl'
import { produce } from 'immer'

export function getPath(context, nodeName, separator = '/') {
  const { path = '' } = context.parent ?? {}
  const pathArray = path.split(separator)

  pathArray[context.depth] = nodeName
  return pathArray.slice(0, context.depth + 1).join(separator)
}

export function replaceNode(node, context, index = context.index) {
  context.parent.children[index] = node
  context.replace(node)
}

export default function traverseTree(tree, callback, options = {}) {
  const { useImmer = true } = options

  if (useImmer) {
    return produce(tree, (draft) => {
      crawl(draft, callback, options)
    })
  }

  crawl(tree, callback, options)
}
