import { Modal, Button } from 'react-bootstrap'
import { useCountdown } from '@/hooks'
import { SessionModalProps } from './SessionModal.types'

const SessionModal = ({ expiresIn, onExtendSessionClick }: SessionModalProps) => {
  const { seconds, minutes } = useCountdown(expiresIn)

  const showSessionModal = minutes <= 2

  return (
    <Modal centered backdrop="static" show={showSessionModal}>
      <Modal.Header>
        <Modal.Title id="catalog-archive-modal-title">
          <i aria-hidden="true" className="fa fa-clock" /> Your session is about to expire.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fs-6">
          Your session will end in <span className="fw-bold font-monospace">{minutes}</span> minutes
          and&nbsp;
          <span className="fw-bold font-monospace">{seconds}</span> seconds.
        </p>

        <p className="fs-6">If your session is not extended, it will be closed.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="flex-fill" size="lg" onClick={onExtendSessionClick}>
          Continue Session
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SessionModal
