export default () => {
  return {
    title: 'Big Calendar',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string',
          title: 'Field'
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          localization: {
            type: 'string',
            title: 'Localization',
            enum: ['en', 'tr'],
            default: 'en',
            required: false
          },
          // weekStartsOn: {
          //   type: 'boolean',
          //   title: 'Week Starts Monday',
          //   default: false,
          //   required: true
          // },
          showButtons: {
            type: 'string',
            title: 'Show Buttons',
            enum: ['all', 'month', 'week', 'day'],
            default: 'all',
            required: true
          },
          headerTitleFormat: {
            type: 'string',
            default: 'MMMM/YYYY',
            title: 'Header Title Format'
          },
          themeColor: {
            type: 'string',
            format: 'Color',
            default: '#3ec1b6',
            title: 'Theme Color'
          },
          weekStartsMonday: {
            type: 'boolean',
            title: 'Week Starts on Monday',
            default: false,
            required: true
          },
          workweek: {
            type: 'boolean',
            title: 'Show Only Work Days',
            default: false,
            required: true
          },
          isReadOnly: {
            type: 'boolean',
            title: 'Read Only',
            default: false,
            required: true
          }
        }
      },
      days: {
        type: 'object',
        title: 'Days',
        properties: {
          today: {
            type: 'string',
            title: 'Today',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          }
          // highlightDays: {
          //   type: 'string',
          //   title: 'Highlight Days',
          //   watch: {
          //     ds: 'datafields'
          //   },
          //   enumSource: 'ds'
          // }
        }
      },
      events: {
        type: 'object',
        title: 'Events',
        properties: {
          id: {
            type: 'string',
            title: 'Event ID',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          eventName: {
            type: 'string',
            title: 'Event Name',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          startDate: {
            type: 'string',
            title: 'Start Date',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          endDate: {
            type: 'string',
            title: 'End Date',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          description: {
            type: 'string',
            title: 'Description',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          resizable: {
            type: 'string',
            title: 'Resizable',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          color: {
            type: 'string',
            title: 'Background Color',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
