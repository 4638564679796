import moment from 'moment'
import { generateExportFileName } from '../export'
import { DEFAULT_DATE_FORMAT } from '../../../constants'

const DEFAULT_EXPORT_FILE_NAME = 'Export'

describe('AgGrid:Helpers:Export:generateExportFileName', () => {
  it('should return string contains provided pluginName if its truthy after trim', () => {
    const pluginName = 'Grid Manual'
    const fileName = generateExportFileName(pluginName, DEFAULT_EXPORT_FILE_NAME)
    expect(fileName).toContain(pluginName)
  })

  it('should return string contains provided pluginName and trim must be applied', () => {
    const pluginName = '   Grid Manual   '
    const fileName = generateExportFileName(pluginName, DEFAULT_EXPORT_FILE_NAME)
    expect(fileName).toContain(pluginName.trim())
  })

  it('should return string does not contains if provided pluginName is falsy', () => {
    const pluginName = '  '
    const fileName = generateExportFileName(pluginName, DEFAULT_EXPORT_FILE_NAME)
    expect(fileName).not.toContain(pluginName)
  })

  it('should return string contains `Export` if provided pluginName is falsy', () => {
    const pluginName = ''
    const fileName = generateExportFileName(pluginName, DEFAULT_EXPORT_FILE_NAME)
    expect(fileName).toContain(DEFAULT_EXPORT_FILE_NAME)
  })

  it('should return a string with provided pluginName and a space and formatted current date if pluginName is truthy after trim', () => {
    const pluginName = 'Grid Manual'

    // Mock the Date constructor
    const mockDate = new Date('2023-01-01T12:00:00Z')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    global.Date = jest.fn(() => mockDate)

    const fileName = generateExportFileName(pluginName, DEFAULT_EXPORT_FILE_NAME)
    const currentDate = moment(mockDate).format(DEFAULT_DATE_FORMAT)

    // Restore the original Date object
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    global.Date = new Date()

    expect(fileName).toEqual(`${pluginName} ${currentDate}`)
  })
})
