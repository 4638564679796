import { TAG_FILTER_KEY } from '../../../constants'
import { transformFilterOperator } from '../filter'

const testCases = [
  { input: 'equals', expectedId: '=', expectedText: 'equals' },
  { input: 'greaterThan', expectedId: '>', expectedText: 'greaterThan' },
  { input: 'lessThan', expectedId: '<', expectedText: 'lessThan' },
  { input: 'contains', expectedId: 'like', expectedText: 'contains' },
  { input: 'notContains', expectedId: 'not like', expectedText: 'notContains' },
  { input: 'in', expectedId: 'in', expectedText: 'in' },
  { input: TAG_FILTER_KEY, expectedId: 'in', expectedText: TAG_FILTER_KEY },
  { input: 'set', expectedId: 'in', expectedText: 'set' }
]

describe('AgGrid:Helpers:Filter:transformFilterOperator', () => {
  it('should return the corresponding operator id and text for valid operators', () => {
    testCases.forEach(({ input, expectedId, expectedText }) => {
      const result = transformFilterOperator(input)
      expect(result.id).toBe(expectedId)
      expect(result.text).toBe(expectedText)
    })
  })

  it('should return empty string for id if operator is invalid', () => {
    const invalidOperator = 'invalidOperator'
    const result = transformFilterOperator(invalidOperator)
    expect(result.id).toBe('')
    expect(result.text).toBe(invalidOperator)
  })
})
