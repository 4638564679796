import React from 'react'
import { Button } from 'react-bootstrap'
import _ from 'lodash'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: props.show
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  render() {
    const {
      props: { steps = [], onStepClicked = () => {}, onApplyClicked = () => {} },
      state: { show = true }
    } = this

    const _steps = [...steps]

    const activeStep = _steps.filter((step) => step.isActive)[0]
    const activeStepId = activeStep.id

    const isLast = activeStepId === _steps.length
    const isCurrentValid = activeStep.isValid

    const isDisableNext = isLast || !isCurrentValid

    const isWizardValid = [..._steps].filter((step) => step.isValid).length === _steps.length

    return show ? (
      <div className="wizardFooter">
        {!isLast ? (
          <Button
            disabled={isDisableNext}
            size="sm"
            variant="outline-dark"
            onClick={onStepClicked.bind(this, activeStepId + 1)}
          >
            Next
          </Button>
        ) : (
          <Button
            disabled={!isWizardValid}
            size="sm"
            type="button"
            variant="success"
            onClick={onApplyClicked.bind(this)}
          >
            Apply
          </Button>
        )}
      </div>
    ) : null
  }
}

export default Footer
