Ext.define('Spui.util.test', {
  singleton: true,
  run: function (data) {
    this.data = data
    var str = 'Drafts [2]'
    var newstr = str.replace(/([\[(])(.+?)([\])])/g, this.replacer)
    alert(newstr)
  },
  replacer: function replacer(match, p1, p2, p3) {
    return p1 + (5 + parseInt(p2)) + p3
  },
  istebu: function tpl(str) {
    return str
  }
})
