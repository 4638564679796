import { ChangeEvent } from 'react'
import cx from 'classnames'
import { Form } from 'react-bootstrap'
import { useUniqueId } from '../../hooks'
import { ISlvyFormCheckProps } from './SlvyFormCheck.types'

const SlvyFormCheck = (props: ISlvyFormCheckProps) => {
  const {
    feedback,
    formCheckClass,
    labelClass,
    inputClass,
    onChange,
    checked,
    isValid = null,
    inputId,
    inputType = 'checkbox',
    type = 'check',
    label = '',
    size = '',
    variant = 'primary',
    reverse = false,
    disabled = false
  } = props
  const [uniqueId] = useUniqueId()
  const uniqueIdWithPrefix = inputId || `SlvyFormCheck-${uniqueId}`

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(event.currentTarget.checked)

  const formCheckClasses = cx('mh-auto', 'mb-0', 'align-items-center', formCheckClass, {
    'form-check': label,
    [`form-check-${size}`]: size && type === 'check',
    [`form-switch-${size}`]: size && type === 'switch',
    [`form-check-${variant}`]: variant && type === 'check',
    [`form-switch-${variant}`]: variant && type === 'switch',
    'form-check-reverse': reverse && type === 'check',
    'form-switch-reverse': reverse && type === 'switch'
  })

  const isValidType = isValid ? 'valid' : 'invalid'

  const labelTpl = label ? (
    <Form.Check.Label
      className={cx(labelClass, 'cp', { 'text-muted': disabled })}
      data-testid="slvy-form-check-label"
    >
      {label}
    </Form.Check.Label>
  ) : null

  const inputTpl = (
    <Form.Check.Input
      checked={checked}
      className={cx(inputClass, 'cp', 'mt-0')}
      data-testid="slvy-form-check-input"
      disabled={disabled}
      type={inputType}
      onChange={handleChange}
    />
  )

  const feedbackTpl = feedback ? (
    <Form.Control.Feedback type={isValidType}>{feedback}</Form.Control.Feedback>
  ) : null

  return (
    <Form.Check className={formCheckClasses} id={uniqueIdWithPrefix} reverse={reverse} type={type}>
      {reverse ? (
        <>
          {labelTpl}
          {inputTpl}
        </>
      ) : (
        <>
          {inputTpl}
          {labelTpl}
        </>
      )}
      {feedbackTpl}
    </Form.Check>
  )
}

export default SlvyFormCheck
