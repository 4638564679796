import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Picker from 'rc-calendar/lib/Picker'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import numeral from 'numeral'
import { CURRENCIES_LOCALES } from '../../utils/currencies'
import dependencyFilters from '../../utils/dependencyFilters'
import settings from '../../utils/settings'
import AssortmentDropdown from '../Dropdown'
import AssortmentInput from '../Input'
import SelectProductModal from '../SelectProduct'
import { setFilters } from '../../store/slices/testOptions'

const defaultDependencyFilters = [...dependencyFilters]
const selectFromState = (state) => ({
  filters: state.testOptions.filters,
  formattingSettings: state.plugin.formattingSettings,
  testOptionsDefault: state.testOptions.testOptionsData._default,
  testOptionsError: state.testOptions.error,
  testOptionsLoading: state.testOptions.isLoading
})

function TestProductFilters(props) {
  const dispatch = useDispatch()
  const isInitialCall = useRef(true)
  const { filters, formattingSettings, testOptionsDefault, testOptionsError, testOptionsLoading } =
    useSelector(selectFromState)
  const {
    colorCodes = [],
    createMode = false,
    culture,
    defaultSelectedFilters = {},
    mapRootOptionFiltersWithDefault = () => {},
    numberOfOptions = [],
    pluginId = '',
    priceLevels = [],
    prices = [],
    sizeRanges = [],
    taxRates
  } = props
  const { DateFormat = null, DecimalFormat, StateDateFormat = null } = settings
  const { decimal = '0,0.00' } = formattingSettings
  const {
    AvailableSizeRanges = [],
    AttributeDetails,
    AttributeDetails: { PriceLevel = {}, ColorCode = {} } = {},
    AttributeNames = [],
    Products = []
  } = testOptionsDefault
  const [newDefaultSelectedFilters, setNewDefaultSelectedFilters] = useState(defaultSelectedFilters)
  const [basePriceTextMode, setBasePriceTextMode] = useState(false)
  const [showSelectProductModal, setShowSelectProductModal] = useState(false)
  const [hoverValue, setHoverValue] = useState([])
  const [selectedDates, setSelectedDates] = useState([null, null])

  const currenciesLocales = { ...CURRENCIES_LOCALES }
  const { [culture]: { thousandSeparator, decimalSeparator, decimalScale } = {} } =
    currenciesLocales
  let {
    Name,
    Description,
    startDate,
    EndDate,
    BasePrice,
    Expenditure,
    SizeRangeId,
    TaxRate,
    NumberOfOptionsId,
    AttributeIds
  } = filters

  // TODO startDate
  startDate = moment(startDate)
  EndDate = moment(EndDate)

  const formattedStartDate = moment(_.first(selectedDates)).format(StateDateFormat)
  const formattedEndDate = moment(_.last(selectedDates)).format(StateDateFormat)

  const colorCodeID = _.isUndefined(AttributeIds[10]) ? 0 : AttributeIds[10]
  const priceLevelID = _.isUndefined(AttributeIds[16]) ? 0 : AttributeIds[16]

  useEffect(() => {}, [hoverValue])

  useEffect(() => {
    setNewDefaultSelectedFilters(defaultSelectedFilters)
  }, [filters, defaultSelectedFilters])

  const eventHandler = (event) => {
    event.stopPropagation()
  }

  const getTooltip = (text, id = Math.random()) => {
    return <Tooltip id={id}>{text}</Tooltip>
  }

  const handleCloseSelectProductModal = () => {
    toggleSelectProductModal(false)
  }

  const handleSelectProduct = (selectedProduct = {}) => {
    let _filters = { ...filters }
    const newAttributeIds = _.cloneDeep(AttributeIds)
    // TODO: use onFilterAttributesChanged and onHandleFieldChanged
    Object.keys(selectedProduct).forEach((productItem) => {
      if (selectedProduct[productItem].Index !== null) {
        const isMutualFilter =
          Object.keys(defaultSelectedFilters).filter((defaultSelected) => {
            return defaultSelected === productItem
          }).length > 0
        const shouldChangeOtherFields = productItem === 'ColorCode' || productItem === 'PriceLevel'

        // only update empty mutual filters and others // does not update default filters (which is selected assortment creation)
        if (
          (isMutualFilter && !defaultSelectedFilters[productItem].IsDefault) ||
          shouldChangeOtherFields
        ) {
          const result = _.findKey(
            AttributeDetails[productItem],
            (value) => value === selectedProduct[productItem].Value
          )
          newAttributeIds[selectedProduct[productItem].Index] = _.isNil(result) ? 0 : result
        }
      } else if (productItem !== 'Name') {
        // does not update name
        _filters = {
          ..._filters,
          [productItem]: selectedProduct[productItem].Value
        }
      }
    })
    dispatch(
      setFilters({
        ..._filters,
        AttributeIds: [...newAttributeIds]
      })
    )

    // TODO check updated AttributeNames,AttributeDetails,AttributeIds
    setFiltersForSelectProduct(AttributeNames, AttributeDetails, AttributeIds)
    toggleSelectProductModal(false)
  }

  const isValidRange = (value) => {
    return value && value instanceof Array && value.length === 2
  }

  const onChangeBasePriceFreeTextMode = () => {
    onHandleFieldChanged({ inputKey: 'BasePrice', value: '' })
    setBasePriceTextMode(!basePriceTextMode)
  }

  const onChangeBasePriceValues = (values) => {
    const { formattedValue = 0 } = values
    onHandleFieldChanged({ inputKey: 'BasePrice', value: formattedValue })
  }

  const onFilterAttributesChanged = (
    attrFieldName,
    dropdownKey,
    { key: selectedKey = '', value: selectedValue = '' }
  ) => {
    const result = _.findKey(AttributeDetails[attrFieldName], (value) => value === selectedValue)
    const newAttributeIds = _.cloneDeep(AttributeIds)
    newAttributeIds[dropdownKey] = _.isNil(result) ? 0 : result
    dispatch(
      setFilters({
        ...filters,
        AttributeIds: [...newAttributeIds]
      })
    )
  }

  const onFilterChanged = (dropdownKey, { key: selectedKey = '' }) => {
    dispatch(
      setFilters({
        ...filters,
        [dropdownKey]: selectedKey
      })
    )
  }

  const onFilterSizeRangeChanged = (
    dropdownKey,
    { key: selectedKey, value: selectedValue = '' }
  ) => {
    dispatch(
      setFilters({
        ...filters,
        SizeRange: selectedValue,
        SizeRangeId: selectedKey,
        SizeRangeTypeId: selectedKey
      })
    )
  }

  const onHandleDatesChanged = (values) => {
    setSelectedDates(_.cloneDeep(values))
    dispatch(
      setFilters({
        ...filters,
        startDate: moment(values[0]).format(StateDateFormat),
        EndDate: moment(values[1]).format(StateDateFormat)
      })
    )
  }

  const onHandleFieldChanged = ({ inputKey, value }) => {
    dispatch(
      setFilters({
        ...filters,
        [inputKey]: value
      })
    )
  }

  const rangeCalendar = (start, end) => {
    return (
      <RangeCalendar
        showClear
        dateInputPlaceholder={['start', 'end']}
        defaultValue={[start, end]}
        hoverValue={hoverValue}
        showOk={false}
        showWeekNumber={false}
        onHoverChange={(hoverValue) => setHoverValue({ hoverValue })}
      />
    )
  }

  // TODO: This function will be using for Select Product
  const setFiltersForSelectProduct = (AttributeNames, AttributeDetails, _AttributeIds) => {
    const _defaultSelectedFilters = {}
    AttributeNames.forEach((name, nameIndex) => {
      const isExist =
        [...defaultDependencyFilters].filter(({ Key = '', Index = '' }) => {
          return Key === name && Index === nameIndex
        }).length > 0
      if (isExist) {
        if (name === 'SeasonCode') {
          _defaultSelectedFilters[name] = newDefaultSelectedFilters.SeasonCode.Value
        } else {
          _defaultSelectedFilters[name] = AttributeDetails[name][_AttributeIds[nameIndex]]
        }
      }
    })

    Object.keys(_defaultSelectedFilters).forEach((item) => {
      const $defaultItem = _defaultSelectedFilters[item]
      _defaultSelectedFilters[item] = _.isArray($defaultItem) ? $defaultItem : [$defaultItem]
    })
    setNewDefaultSelectedFilters(mapRootOptionFiltersWithDefault(_defaultSelectedFilters))
  }

  const toggleSelectProductModal = ($status) => {
    setShowSelectProductModal($status)
  }

  //TODO filters check
  return (
    <div>
      {/* START - Name */}
      <div key="Name" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Name:</label>
          {createMode ? (
            <OverlayTrigger overlay={getTooltip('Select Similar Product')} placement="top">
              <i
                className="slvy-ui-icon-export-box -labelButton"
                onClick={(e) => {
                  toggleSelectProductModal(true)
                }}
              />
            </OverlayTrigger>
          ) : null}
          <SelectProductModal
            defaultSelectedFilters={newDefaultSelectedFilters}
            handleCloseModal={handleCloseSelectProductModal}
            handleOnSave={handleSelectProduct}
            pluginId={pluginId}
            show={showSelectProductModal}
          />
        </div>
        <div className="col-sm-6">
          <AssortmentInput inputKey="Name" value={Name} onChanged={onHandleFieldChanged} />
        </div>
      </div>
      {/* END - Name */}
      {/* START - Description */}
      <div key="Description" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Description:</label>
        </div>
        <div className="col-sm-6">
          <AssortmentInput
            inputKey="Description"
            value={Description}
            onChanged={onHandleFieldChanged}
          />
        </div>
      </div>
      {/* END - Description */}
      {/* START - Start Date - End Date */}
      <div key="Date" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Start - End Date:</label>
        </div>
        <div className="col-sm-6">
          <Picker
            animation="slide-up"
            calendar={rangeCalendar(startDate, EndDate)}
            defaultValue={[startDate, EndDate]}
            onChange={onHandleDatesChanged}
          >
            {({ value }) => {
              const [start, end] = value
              return (
                <AssortmentInput
                  readOnly
                  placeholder="Please select dates"
                  value={
                    isValidRange(value)
                      ? `${start.format(DateFormat)} - ${end.format(DateFormat)}`
                      : ''
                  }
                  onClick={eventHandler}
                />
              )
            }}
          </Picker>
          <AssortmentInput defaultValue={formattedStartDate} name="startDate" type="hidden" />
          <AssortmentInput defaultValue={formattedEndDate} name="EndDate" type="hidden" />
        </div>
      </div>
      {/* END - Start Date - End Date */}
      {/* START - Price */}
      <div key="BasePrice" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Price (₺):</label>
          <OverlayTrigger
            overlay={getTooltip(`${basePriceTextMode ? 'Back' : 'Add Price'}`)}
            placement="top"
          >
            <span
              className={`-labelButton slvy-ui-icon-${
                basePriceTextMode ? 'times-lt' : 'plus-outline'
              }`}
              onClick={onChangeBasePriceFreeTextMode}
            />
          </OverlayTrigger>
        </div>
        <div className="col-sm-6">
          {basePriceTextMode ? (
            <NumberFormat
              fixedDecimalScale
              className="form-control form-control-sm input"
              decimalScale={decimalScale}
              decimalSeparator={decimalSeparator}
              defaultValue={numeral(BasePrice).format(decimal)}
              thousandSeparator={thousandSeparator}
              onClick={eventHandler}
              onValueChange={(values) => onChangeBasePriceValues(values)}
            />
          ) : (
            <AssortmentDropdown
              isRequired
              canAddEmpty={false}
              dropdownKey="BasePrice"
              options={prices}
              selected={BasePrice}
              onFilterChanged={onFilterChanged}
            />
          )}
        </div>
      </div>
      {/* END - Price */}
      {/* START - Unit Cost */}
      <div key="Expenditure" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Unit Cost (₺):</label>
        </div>
        <div className="col-sm-6">
          <AssortmentInput
            inputKey="Expenditure"
            value={Expenditure}
            onChanged={onHandleFieldChanged}
          />
        </div>
      </div>
      {/* END - Unit Cost */}
      {/* START - Tax Rate */}
      <div key="TaxRate" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Tax Rate (₺):</label>
        </div>
        <div className="col-sm-6">
          <AssortmentDropdown
            isRequired
            canAddEmpty={false}
            dropdownKey="TaxRate"
            options={taxRates}
            selected={TaxRate}
            onFilterChanged={onFilterChanged}
          />
        </div>
      </div>
      {/* END - Tax Rate */}
      {/* START - Size Range */}
      <div key="SizeRange" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Size Range:</label>
        </div>
        <div className="col-sm-6">
          <AssortmentDropdown
            isRequired // SizeRangeId, SizeRangeTypeId is same
            canAddEmpty={false}
            dropdownKey="SizeRange"
            options={sizeRanges}
            selected={SizeRangeId}
            onFilterChanged={onFilterSizeRangeChanged}
          />
        </div>
      </div>
      {/* END - Size Range */}
      {/* START - Price Level */}
      <div key="PriceLevel" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Price Level:</label>
        </div>
        <div className="col-sm-6">
          <AssortmentDropdown
            isRequired // AttributeIds[16]
            canAddEmpty={false}
            dropdownKey={16}
            options={priceLevels}
            selected={priceLevelID}
            onFilterChanged={(attrId, selectedItem) => {
              onFilterAttributesChanged('PriceLevel', attrId, selectedItem)
            }}
          />
        </div>
      </div>
      {/* END - Price Level */}
      {/* START - Number of Options */}
      {createMode ? (
        <div key="NumberOfOptionsId" className="form-group mb-3 row">
          <div className="col-sm-6">
            <label className="form-label">Number of Options:</label>
          </div>
          <div className="col-sm-6">
            <AssortmentDropdown
              isRequired
              canAddEmpty={false}
              dropdownKey="NumberOfOptionsId"
              options={numberOfOptions}
              selected={NumberOfOptionsId}
              onFilterChanged={onFilterChanged}
            />
          </div>
        </div>
      ) : null}
      {/* END - Number of Options */}
      {/* START - Color */}
      <div key="Color" className="form-group mb-3 row">
        <div className="col-sm-6">
          <label className="form-label">Color:</label>
        </div>
        <div className="col-sm-6">
          <AssortmentDropdown
            isRequired // AttributeIds[10]
            canAddEmpty={false}
            dropdownKey={10}
            options={colorCodes}
            selected={colorCodeID}
            onFilterChanged={(attrId, selectedItem) => {
              onFilterAttributesChanged('ColorCode', attrId, selectedItem)
            }}
          />
        </div>
      </div>
      {/* END - Color */}
    </div>
  )
}

export default TestProductFilters
