import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Row, Col, Alert } from 'react-bootstrap'
import { unCollapseBulk as onAddUnCollapsedBulkScenarios } from '../../store/actions/index'
import ScenarioTreeListTemplate from './template'
import ScenarioSearch from '../ScenarioSearch/index'

class ScenarioTreeList extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState() {
    const { root = [] } = this.props
    return {
      notFound: false,
      query: '',
      rawData: root,
      tree: root
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.root, this.props.root)) {
      this.setState({
        rawData: nextProps.root,
        tree: nextProps.root
      })
    }
  }

  onSearchStart() {}

  onSearchEnd(items, tree, notFound) {
    const {
      props: { dataKey = '', scenarioType = '' }
    } = this

    const ids = items.map((item) => ({ Id: item.Id, dataKey, scenarioType }))

    setTimeout(() => {
      this.props.onAddUnCollapsedBulkScenarios({
        payload: {
          unCollapsedScenarios: ids,
          dataKey
        }
      })
    }, 0)

    this.setState({ tree, notFound })
  }

  onKeyDown(event) {}

  onChange({ query }) {
    this.setState({ query })
  }

  onClear = () => {
    this.setState({ ...this.getInitialState() }, () => this.scrollTo())
  }

  scrollTo() {
    const highlightedItems = document.querySelectorAll('.-active-selection')
    if (highlightedItems.length) {
      highlightedItems.forEach((selection) => {
        selection.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'center' })
      })
    }
  }

  getNotFoundTemplate() {
    const { query } = this.state
    return (
      <Alert className="scenarioNotFound" variant="warning">
        <Row>
          <Col md={1}>
            <i aria-hidden="true" className="fa fa-3x fa-info-circle" />
          </Col>
          <Col md={11}>
            <h4 className="mb-2 mt-1">
              Sorry, <strong className="fw-bold"> {query} </strong> does not exist in the{' '}
              <strong className="fw-bold"> Scenario </strong> lists.
            </h4>
            <h5>
              If you are looking for a specific scenario, please enter the scenario{' '}
              <strong className="fw-bold">name</strong> e.g. Baseline or Approved
            </h5>
          </Col>
        </Row>
      </Alert>
    )
  }

  render() {
    const {
      state: { notFound, tree, rawData },
      props: {
        dataKey = '',
        scenarioType = '',
        listIndex = '',
        headerTpl = null,
        currentScenario = {},
        selectScenario = () => {},
        selectActualScenario = () => {}
      }
    } = this

    return (
      <>
        <ScenarioSearch
          data={tree}
          minChars={2}
          rawData={rawData}
          onChange={this.onChange.bind(this)}
          onClear={this.onClear.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
          onSearchEnd={this.onSearchEnd.bind(this)}
          onSearchStart={this.onSearchStart.bind(this)}
        />
        {headerTpl}
        <div className="slvy-ui -grid-body">
          {notFound ? (
            this.getNotFoundTemplate()
          ) : (
            <ScenarioTreeListTemplate
              actualScenarioSelected={selectActualScenario}
              currentScenario={currentScenario}
              dataKey={dataKey}
              listIndex={listIndex}
              root={tree}
              scenarioSelected={selectScenario}
              scenarioType={scenarioType}
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = null

const mapDispatchToProps = {
  onAddUnCollapsedBulkScenarios
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioTreeList)
