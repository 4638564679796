import Jobs from '../../jobs'
import { WorkHeaderProps } from './WorkHeader.types'

const getHeaderContent = (workName: WorkHeaderProps['workName'], nodeName: string) => {
  if (workName && nodeName) {
    return (
      <>
        {workName}
        {' | '} <small>{nodeName}</small>
      </>
    )
  }

  if (!workName && nodeName) {
    return <>{nodeName}</>
  }

  if (workName && !nodeName) {
    return <>{workName}</>
  }

  return 'Work Details'
}

const WorkHeader = ({ workName, node }: WorkHeaderProps) => {
  let nodeName = ''
  // TODO: Set detail_title to the state onEdit btn clicks
  Object.keys(Jobs).forEach((jobKey) => {
    if (Jobs[jobKey].config.key === node?.item?.name) {
      nodeName = Jobs[jobKey].config.detail_title
    }
  })

  return (
    <span data-testid="work-header">
      <i className="fa fa-list text-white" />
      <span className="ps-1 text-white">{getHeaderContent(workName, nodeName)}</span>
    </span>
  )
}

export default WorkHeader
