import { Component } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

export default class Operations extends Component {
  render() {
    return (
      <ButtonGroup
        className="rounded w-100 d-block p-3 mb-3"
        style={{
          border: '1px solid #DCC896',
          background: 'rgba(250,240,210,.5)'
        }}
      >
        {this.props.title && (
          <div>
            <h3 className="pb-0">
              {this.props.title}
              {this.props.onDelete && (
                <Button
                  className="float-end"
                  size="sm"
                  type="button"
                  variant="danger"
                  onClick={this.props.onDelete}
                >
                  X
                </Button>
              )}
            </h3>
          </div>
        )}
        <div>{this.props.children}</div>
      </ButtonGroup>
    )
  }
}
