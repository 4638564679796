import {
  OverlayTrigger,
  Tooltip as BsTooltip,
  TooltipProps as BsTooltipProps
} from 'react-bootstrap'
import cx from 'classnames'
import { TooltipProps } from './Tooltip.types'
import styles from './Tooltip.module.scss'

export default function Tooltip({
  className,
  children,
  delay = 100,
  placement = 'bottom',
  text
}: TooltipProps) {
  function renderTooltip(props: BsTooltipProps) {
    return (
      <BsTooltip className={cx(styles.tooltip, className)} id="button-tooltip" {...props}>
        {text}
      </BsTooltip>
    )
  }

  return (
    <OverlayTrigger
      delay={delay}
      overlay={renderTooltip}
      placement={placement}
      trigger={['focus', 'hover']}
    >
      {children}
    </OverlayTrigger>
  )
}
