Ext.define('MDM.src.view.regionapp.DroppedMaterial.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.droppedmaterial-mainviewmodel',
  stores: {
    DroppedMaterialStore: {
      model: 'MDM.src.view.regionapp.DroppedMaterial.MainModel',
      remoteSort: false, //For Remote Sorting
      autoLoad: true,
      autoSync: false,

      listeners: {
        beforeload: 'onBeforeLoadDroppedMaterialStore'
      },
      remoteFilter: false //For Remote Filtering
    }
  },
  formulas: {
    ViewMode: {
      bind: {
        viewAs: '{viewAs}',
        userMegaRegion: '{userMegaRegion}'
      },
      get: function (d) {
        if (d.viewAs == 'owner') return d.userMegaRegion
        else {
          return 'All MegaRegion'
        }
      }
    }
  },
  data: {
    viewAs: 'owner',
    maingridSelected: [],
    isGlobal: false,
    userMegaRegion: ''
  }
})
