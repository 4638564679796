import { Alert } from 'react-bootstrap'
import { IWarningProps } from './Warning.types'

const Warning = ({ show }: IWarningProps) => {
  if (!show) {
    return null
  }

  return (
    <Alert className="m-0 w-100 p-2" variant="warning">
      <h3 className="m-0 p-0 fs-6">
        <strong>Oh snap!</strong> It looks like we&apos;re having issues.
      </h3>
    </Alert>
  )
}

export default Warning
