export const MESSAGES = {
  please_enter_a_valid_value_between_min_and_max:
    'Please, enter a valid value between min and max!',
  you_will_be_notified_once_the_approval_is_confirmed:
    'You will be notified once the approval is confirmed.',
  you_have_attempted_to_enter_the_same_value: 'You have attempted to enter the same value!',
  please_configure_the_pivot_builder: 'Please, configure the pivot builder!',
  your_report_will_be_create_soon: 'Your report will be created soon.',
  hierarchy_will_be_copied_soon: 'Hierarchy will be copied soon.',
  permanent_lock_message: 'This row could not be unlocked due to permanent lock filter.',
  you_must_have_changes_to_save: 'You must have changes to save!',
  row_update_is_not_available_in_the_detail_and_the_single_detail_view:
    'Row Update is not available in the detail and the single detail view!',
  this_scenario_is_not_editable: 'This scenario is not editable!',
  you_cannot_copy_the_same_scenario: 'You cannot copy the same scenario!',
  there_is_no_sub_level_to_go: 'There is no sub-level to go.',
  there_has_been_an_error: 'There has been an error!',
  please_select_at_least_one_values: 'Please select at least one values!',
  please_select_at_least_two_values: 'Please select at least two scenarios.',
  please_select_at_least_one_scenario_and_select_a_baseline:
    'Please select at least one scenario and select a baseline!',
  there_is_no_scenario_to_add: 'There is no scenario to add.',
  the_scenario_tree_could_not_be_reloaded: 'The scenario tree could not be reloaded!',
  the_scenario_tree_could_not_be_created: 'The scenario tree could not be created!',
  the_scenario_could_not_be_deleted: 'The scenario could not be deleted!',
  please_select_a_scenario: 'Please, select a scenario!',
  new_state_has_been_created: 'New state has been created!',
  your_changes_have_been_saved_successfully: 'Your changes have been saved successfully!',
  please_check_your_internet_connection: 'Please, check your internet connection!',
  name_is_required: 'Name is required!',
  this_name_was_taken_please_try_with_a_different_one:
    'This name was taken, please try with a different one!',
  could_not_find_a_proper_pivot_builder_state: 'Could not find a proper pivot builder state!',
  missing_pivot_state_columns: 'Missing Pivot State Columns',
  the_node_has_been_locked: 'The node has been locked.',
  the_node_has_been_unlocked: 'The node has been unlocked.',
  the_process_has_been_completed: 'The process has been completed!',
  undo_action_has_been_completed_successfully: 'Undo action has been completed successfully!',
  the_scenario_tree_has_been_reloaded_successfully:
    'The scenario tree has been reloaded successfully!',
  the_scenario_will_be_created_soon: 'The scenario will be created soon.',
  the_scenario_has_been_deleted_successfully: 'The scenario has been deleted successfully!',
  action_status: 'Action Status',
  undo_action: 'Undo Action',
  warning: 'Warning',
  reload_scenario: 'Reload Scenario',
  create_scenario: 'Create Scenario',
  delete_scenario: 'Delete Scenario',
  change_scenario: 'Change Scenario',
  reload: 'Reload',
  actions: 'Actions',
  apply_changes: 'Apply Changes',
  discard_changes: 'Discard Changes',
  get_user_scenario: 'Get User Scenario',
  no_pivot_configuration: 'No pivot configuration!',
  loading: 'Loading',
  settings: 'Settings',
  are_you_sure_you_want_to_delete_the_pivot_builder_state:
    'Are you sure you want to delete the pivot builder state?',
  some_columns_have_been_added_or_deleted: 'Some columns have been added or deleted.',
  information: 'Information',
  confirm: 'Confirm',
  cancel: 'Cancel',
  name: 'Name',
  back: 'Back',
  create: 'Create',
  discard: 'Discard',
  created_by: 'Created By',
  delete: 'Delete',
  revision: 'Revision',
  reload_timeline: 'Reload Timeline',
  continue: 'Continue',
  description: 'Description',
  create_scenario_from: 'Create Scenario From',
  the_scenario_comparison: 'The scenario comparison',
  parameters_ratio: "Parameter's Ratio",
  the_pivot_builder: 'The pivot builder',
  pivot_builder: 'Pivot Builder',
  your_changes_have_been_discarded_successfully: 'Your changes are discarded successfully!',
  your_changes_have_been_applied_successfully: 'Your changes are applied successfully!',
  approve_confirmation: 'Approve Confirmation',
  are_you_sure_you_want_to_edit_the_already_approved_row:
    'Are you sure you want to edit the already approved row?',
  there_are_no_actions: 'There are no actions.',
  you_cannot_change_this_pivot_builder_setting: 'You cannot change this pivot builder setting!',
  the_scenario_list_has_been_changed: 'The scenario list has been changed.',
  are_you_sure_you_want_to_delete_this_scenario: 'Are you sure you want to delete this scenario?',
  your_scenario_is_not_editable: 'Your scenario is not editable!',
  you_have_unsaved_changed_from_your_previous_session:
    'You have unsaved changes from your previous session.',
  would_you_like_to_continue_working_on_them: 'Would you like to continue working on them?',
  if_not_your_changes_will_be_discarded: 'If not, your changes will be discarded!',
  the_start_date_cannot_bigger_than_the_end_date: 'The start date cannot bigger than the end date!',
  please_reload_your_page: 'Please, reload your page!',
  approve_settings: 'Approve Settings',
  approved: 'Approved',
  not_approved: 'Not Approved',
  lock_settings: 'Lock Settings',
  locked: 'Locked',
  not_locked: 'Not Locked',
  view: 'View',
  edit: 'Edit',
  undo: 'Undo',
  save: 'Save',
  save_as: 'Save As',
  search: 'Search',
  apply: 'Apply',
  scenario_selection: 'Scenario Selection',
  copy_hierarchy_from_scenario: 'Copy Hierarchy From Scenario',
  enter_a_name: 'Enter a name',
  close: 'Close',
  adjust_font_size: 'Adjust Font Size',
  there_are_pending_updates: 'There are pending updates.',
  please_wait_for_them_to_be_completed: 'Please wait for them to be completed.',
  parent_update_is_disabled_due_to_its_drilldown_being_open:
    'Parent update is disabled due to its drilldown being open.',
  this_row_cannot_be_unlocked: 'This row cannot be unlocked.'
}
