export default {
  title: 'Data Import',
  subTitle: '',
  version: '1.1',
  namespace: 'DataImport',
  description: 'Import data from excel files',
  iconClass: 'fa-download',
  color: 'yellow-bg',
  group: 'Data Manipulation',
  support: {
    maximizable: false,
    editable: false
  }
}
