/* eslint array-callback-return: "off" */
export default function getMenu(menus, menuId) {
  return menus.reduce((acc, menu) => {
    if (acc) {
      return acc
    }
    if (menu.item.id === menuId) {
      return menu
    }
    if (menu.children.length) {
      return getMenu(menu.children, menuId)
    }
  }, null)
}
