import { countBy } from 'lodash'
import { Title } from '@/components'
import { RequiredFieldsTypes } from './RequiredFields.types'

const RequiredFields = ({ messages, title = 'Required Fields', children }: RequiredFieldsTypes) => {
  const uniqMsg = Object.entries(countBy(messages)).map(([msg, count]) => {
    const countMsg = count > 1 ? `(${count})` : ''
    const finalMsg = `${msg} ${countMsg}`
    return <li key={finalMsg}>{finalMsg}</li>
  })

  return (
    <>
      {title ? <Title>{title}</Title> : null}
      {children}
      <ul className="ms-3">{uniqMsg}</ul>
    </>
  )
}

export default RequiredFields
