import { useCallback } from 'react'
import { IRowNode } from 'ag-grid-community'
import { GridRef, IRowData } from '../../types'
import { AG_GRID_ENUMS } from '../../enums'

const { ADDED_ROWS, CUSTOM_ROW_INFO } = AG_GRID_ENUMS

function useGetAddedRows(gridRef: GridRef) {
  const getAddedRows = useCallback(() => {
    const rows: string[] = []

    gridRef?.current?.api?.forEachLeafNode?.(({ data }: IRowNode<IRowData>) => {
      if (data) {
        const { [ADDED_ROWS]: currentAddedRows = [] } = data[CUSTOM_ROW_INFO]
        // rows = [...rows, ...currentAddedRows]
        rows.push(...currentAddedRows)
      }
    })

    return rows
  }, [gridRef])

  return { getAddedRows }
}

export default useGetAddedRows
