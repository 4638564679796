Ext.define('MDM.Btl.dialog.Grid', {
  extend: 'Ext.grid.Panel',
  // Uncomment to give this component an xtype
  xtype: 'btl-dialog-grid',
  config: {
    record: null
  },
  columnLines: true,
  initComponent: function () {
    if (!this.store) {
      this.store = Ext.create('Ext.data.Store', {
        storeId: 'simpsonsStore',
        fields: ['name', 'email', 'phone'],
        data: [
          {
            FiscealYear: '16-17'
          },
          {
            FiscealYear: '17-18'
          },
          {
            FiscealYear: '18-19'
          }
        ]
      })
    }
    this.callParent()
  },
  applyRecord: function (c) {
    var record = c,
      rawData = [],
      f = ['16-17', '17-18', '18-19']
    if (c && c.isModel) {
      var data = record.data
      for (var i = 1; i < 4; i++) {
        var tempRow = {
          FiscealYear: f[i - 1]
        }
        for (var j = 1; j < 13; j++) {
          var rowIndex = j < 10 ? 'c0' + j : 'c' + j,
            t = (i - 1) * 12 + j,
            dataIndex = t < 10 ? 'c0' + t : 'c' + t
          tempRow[rowIndex] = data[dataIndex]
        }
        rawData.push(tempRow)
      }
      if (!this.store) {
        this.store = Ext.create('Ext.data.Store', {
          storeId: 'simpsonsStore',
          data: rawData
        })
      } else {
        this.store.loadData(rawData)
      }
    }
  },
  columns: [
    {
      text: 'FiscealYear',
      dataIndex: 'FiscealYear',
      locked: true
    },
    {
      text: 'Jul',
      dataIndex: 'c01',
      xtype: 'btlnumber'
    },
    {
      text: 'Aug',
      dataIndex: 'c02',
      xtype: 'btlnumber'
    },
    {
      text: 'Sep',
      dataIndex: 'c03',
      xtype: 'btlnumber'
    },
    {
      text: 'Oct',
      dataIndex: 'c04',
      xtype: 'btlnumber'
    },
    {
      text: 'Nov',
      dataIndex: 'c05',
      xtype: 'btlnumber'
    },
    {
      text: 'Dec',
      dataIndex: 'c06',
      xtype: 'btlnumber'
    },
    {
      text: 'Jan',
      dataIndex: 'c07',
      xtype: 'btlnumber'
    },
    {
      text: 'Feb',
      dataIndex: 'c08',
      xtype: 'btlnumber'
    },
    {
      text: 'Mar',
      dataIndex: 'c09',
      xtype: 'btlnumber'
    },
    {
      text: 'Apr',
      dataIndex: 'c10',
      xtype: 'btlnumber'
    },
    {
      text: 'May',
      dataIndex: 'c11',
      xtype: 'btlnumber'
    },
    {
      text: 'Jun',
      dataIndex: 'c12',
      xtype: 'btlnumber'
    },
    {
      text: 'Ending',
      tdCls: 'subtotal',
      dataIndex: 't1',
      xtype: 'btlnumber'
    }
  ]
})
