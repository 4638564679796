import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { getFormattedDefaultDate } from '../utils'
import { MESSAGES } from '../messages'

export function getLockState(IsLocked) {
  const LockMap = {
    between: {
      name: 'Lock',
      className: 'slvy-ui-icon-quantum-lock lock-between -between',
      endPoint: 'LockMfpNode'
    },
    lock: {
      name: 'Unlock',
      className: 'slvy-ui-icon-lock lock-lock -lock',
      endPoint: 'UnlockMfpNode'
    },
    unlock: {
      name: 'Lock',
      className: 'slvy-ui-icon-unlock lock-unlock -unlock',
      endPoint: 'LockMfpNode'
    }
  }
  return LockMap[IsLocked > 0 && IsLocked < 1 ? 'between' : IsLocked === 1 ? 'lock' : 'unlock']
}

export function findById(_data, _Id) {
  let result = {}

  const _findById = (data, Id) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].Item.Id === Id) {
        result = { ...data[i].Item }
        break
      } else if (data[i].Children && data[i].Children.length) {
        _findById(data[i].Children, Id)
      }
    }
  }

  _findById(_data, _Id)

  return result
}

export function getDefaultDatesFromSettings(periods, { defaultAggregationLevelId, settings = [] }) {
  let StartDate = null
  let EndDate = null
  const optional = {}

  if (defaultAggregationLevelId) {
    const { Values = [] } =
      _.find(periods, ({ AggregationLevel }) => AggregationLevel === defaultAggregationLevelId) ||
      {}
    const { max = 1000 } =
      _.find(
        settings,
        ({ aggregationLevelId }) => aggregationLevelId === defaultAggregationLevelId
      ) || {}
    const defaultValue = _.slice(Values, 0, max)

    StartDate = _.get(_.first(defaultValue), 'StartDate', null)
    EndDate = _.get(_.last(defaultValue), 'EndDate', null)

    const StartDisplayName = _.get(_.first(defaultValue), 'DisplayName', null)
    const EndDisplayName = _.get(_.last(defaultValue), 'DisplayName', null)
    if (StartDisplayName && EndDisplayName) {
      optional.StartDisplayName = StartDisplayName
      optional.EndDisplayName = EndDisplayName
    }
  }

  if (!StartDate && !EndDate) {
    StartDate = getFormattedDefaultDate(null, 'start')
    EndDate = getFormattedDefaultDate(null, 'end')
  }

  return {
    StartDate,
    EndDate,
    ...optional
  }
}

export function getApproveState(IsApproved) {
  const ApproveMap = {
    between: {
      name: 'Approve',
      className: 'slvy-ui-icon-check-circle-regular approve-between -between',
      endPoint: 'ApproveMfpNode',
      status: 'Between'
    },
    approve: {
      name: 'Approve',
      className: 'slvy-ui-icon-check-circle-regular approve-approve -approve',
      description: MESSAGES.are_you_sure_you_want_to_edit_the_already_approved_row,
      endPoint: 'ApproveMfpNode',
      status: 'Approve'
    },
    notapprove: {
      name: 'Approve',
      className: 'slvy-ui-icon-check-circle-regular approve-notapprove -notapprove',
      endPoint: 'ApproveMfpNode',
      status: 'Not Approve'
    }
  }

  return ApproveMap[
    IsApproved > 0 && IsApproved < 1 ? 'between' : IsApproved === 1 ? 'approve' : 'notapprove'
  ]
}

export function isInAcceptableRange(min, max, value) {
  return value >= min && value <= max
}

export function getMinValue($min) {
  return parseFloat(_.isEmpty($min) ? Number.MIN_SAFE_INTEGER : $min)
}

export function getMaxValue($max) {
  return parseFloat(_.isEmpty($max) ? Number.MAX_SAFE_INTEGER : $max)
}

export function getMinLimit(oldValue, minAcceptableValue) {
  return _.isEmpty(minAcceptableValue)
    ? getMinValue('')
    : (oldValue * getMinValue(minAcceptableValue)) / 100 - oldValue
}

export function getMaxLimit(oldValue, maxAcceptableValue) {
  return _.isEmpty(maxAcceptableValue)
    ? getMaxValue('')
    : (oldValue * getMaxValue(maxAcceptableValue)) / 100 + oldValue
}

export function isEveryColumnInRange(values, fieldName) {
  return values.every((item) => {
    if (item[fieldName] === null) {
      return false
    }

    const value = item[fieldName]
    const oldValue = item.OldValue
    let min = item.MinAcceptableValue
    let max = item.MaxAcceptableValue

    min = min === '' ? Number.MIN_SAFE_INTEGER : min
    max = max === '' ? Number.MAX_SAFE_INTEGER : max

    switch (item.MinMaxCheckType) {
      case 'absolute':
        return isInAcceptableRange(getMinValue(min), getMaxValue(max), value)
      case 'percentage':
        const minLimit = getMinLimit(oldValue, min)
        const maxLimit = getMaxLimit(oldValue, max)
        return isInAcceptableRange(minLimit, maxLimit, value)
      default:
        return isInAcceptableRange(getMinValue(min), getMaxValue(max), value)
    }
  })
}

export function isAnyColumnEqual(values) {
  return values.some((item) => item.OldValue === item.NewValue)
}

export function getItemByKey(array, key, value) {
  let found = {}
  array.some(function iteration(currentValue) {
    if (currentValue.Item[key] === value) {
      found = currentValue.Item
      return true
    }
    return Array.isArray(currentValue.Children) && currentValue.Children.some(iteration)
  })
  return found
}

export function createNodes(nodes) {
  const objArray = []
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index]
    let currentNode = objArray

    const nodeParts = node.split('|||')

    for (let index = 0; index < nodeParts.length; index++) {
      const nodePart = nodeParts[index]

      const nodeName = nodePart.split('||')[0]
      const nodeValue = nodePart.split('||')[1]

      let currentElement = getByValue(currentNode, nodeName, nodeValue)

      if (currentElement == null) {
        currentElement = {
          name: nodeName,
          value: nodeValue,
          children: []
        }

        currentNode.push(currentElement)
        currentNode = currentElement.children
        continue
      } else {
        currentNode = currentElement.children
        continue
      }
    }
  }
  const filter = createFilter(objArray, [])

  if (filter.length > 0 && filter[0].Filters && filter[0].Filters.length > 1) {
    const grouped = _.groupBy(filter[0].Filters, (f) => f.FieldName)
    const keys = _.keys(grouped)
    if (keys.length > 1) {
      const newFilter = {
        FilterType: 1,
        Filters: []
      }

      _.each(keys, (key) => {
        if (grouped[key].length === 1) {
          newFilter.Filters.push(grouped[key][0])
        } else {
          const childContainer = {
            FilterType: 2,
            Filters: grouped[key]
          }
          newFilter.Filters.push(childContainer)
        }
      })

      return [newFilter]
    }
  }

  return filter
}

export function createFilter(tree, result) {
  if (tree.length === 0) return result

  const currentContainer = {
    FilterType: tree.length > 1 ? 2 : 1,
    Filters: []
  }

  for (let index = 0; index < tree.length; index++) {
    const element = tree[index]

    const item = {
      FilterType: element.children.length > 0 ? 1 : 0,
      FieldName: element.name,
      Value: element.value,
      Filters: createFilter(element.children, [])
    }

    currentContainer.Filters.push(item)
  }

  result.push(currentContainer)

  return result
}

export function getByValue(array, name, value) {
  for (let index = 0; index < array.length; index++) {
    const element = array[index]

    if (element.name === name && element.value === value) return element
  }

  return null
}

/**
 * @params(pluginConfig, pluginState)
 * It merges both of them. Because plugin state need to get plugin config changes except Checked field,
 * only Checked field can change by user.
 * */
export function getMergedPluginState($pluginConfig, pluginStateConfig) {
  const copyPluginConfig = _.cloneDeep($pluginConfig)
  const copyStateConfig = _.cloneDeep(pluginStateConfig)
  Object.keys(copyStateConfig).forEach((itemKey) => {
    copyStateConfig[itemKey] = copyStateConfig[itemKey].map((fieldItem) => {
      const foundPluginAxis = copyPluginConfig[itemKey] || []
      const foundPluginConfig = foundPluginAxis.find((cpItem) => fieldItem.Name === cpItem.Name)
      if (foundPluginConfig) {
        return {
          ...fieldItem,
          ...foundPluginConfig,
          Checked: fieldItem.Checked
        }
      }
      return {
        ...fieldItem
      }
    })
  })
  return copyStateConfig
}

export function isExistTimelineSettings(Result = [], tml = {}) {
  let isExist = true
  const current = Result.find((item) => item.AggregationLevel === tml.AggregationLevelId)
  if (current) {
    Object.keys(tml.AggregationPeriods).forEach((idKey, index) => {
      const localCurrent = tml.AggregationPeriods[idKey]
      const _current = Result.find((item) => item.AggregationLevel === parseInt(idKey, 10))
      if (_current) {
        const { StartDate: _startDate = null, DisplayName: _startDisplayName = null } =
          _current.Values.find((value) => value.StartDate === localCurrent.StartDate) || {}

        const { EndDate: _endDate = null, DisplayName: _endDisplayName = null } =
          _current.Values.find((value) => value.EndDate === localCurrent.EndDate) || {}

        const isPeriodNameNotEqual = _current.Name !== localCurrent.PeriodName

        const isStartDateNotEqual = localCurrent.StartDate !== _startDate
        const isStartDisplayNameNotEqual = localCurrent.StartDisplayName !== _startDisplayName
        const isEndDateNotEqual = localCurrent.EndDate !== _endDate
        const isEndDisplayNameNotEqual = localCurrent.EndDisplayName !== _endDisplayName

        if (
          isStartDateNotEqual ||
          isStartDisplayNameNotEqual ||
          isEndDateNotEqual ||
          isEndDisplayNameNotEqual
        ) {
          isExist = false
        }
        if (isPeriodNameNotEqual) {
          isExist = false
        }
      } else {
        isExist = false
      }
    })
  } else {
    isExist = false
  }
  return isExist
}

export const getHierarchy = (params, Fields) => {
  const { List } = params

  const LeftFields = [...Fields]
    .filter((item) => {
      return item.LeftAxisDraggable && item.Name && !item.TopAxisDraggable
    })
    .map((item) => item.Name)

  // let result = []
  const result = {}
  const ListKeys = Object.keys(List)

  LeftFields.forEach((leftField) => {
    const $key = ListKeys.find((item) => item === leftField)
    // let item = { [leftField]: $key ? List[$key] : undefined }
    // result.push(item)
    result[leftField] = $key ? List[$key] : undefined
  })

  return result
}

export const getArgs = (fields, type, stringType) => {
  return _.transform(
    fields,
    (result, { LeftAxisDraggable = false, Name }) => {
      if (LeftAxisDraggable && Name) {
        result.push({
          name: Name,
          type
        })
      }
    },
    [
      { name: 'StartDate', type: stringType },
      { name: 'EndDate', type: stringType }
    ]
  )
}

export const getHierarchyTypes = (Fields, stringType) => {
  return _.transform(
    Fields,
    (result, { LeftAxisDraggable, TopAxisDraggable, Name }) => {
      if (LeftAxisDraggable && Name && !TopAxisDraggable) {
        result[Name] = stringType
      }
    },
    {}
  )
}

export const getModifiedConfig = ($config) => {
  // TODO: This check will remove
  if ('Main' in $config) {
    $config.DefaultAxisDraggable = $config.Main
    $config = _.omit($config, ['Main'])
  }
  return $config
}

export const getDefaultPluginState = ($props, $config) => {
  const {
    id = '',
    params: { catalogId = 0 },
    preferredUsername = new Date(),
    userName = preferredUsername
  } = $props
  const dynamicName = `${uuidv4()}`

  return {
    name: `PivotBuilderState_${dynamicName}`,
    pluginId: id,
    catalogId,
    filters: [],
    public: false,
    exportable: false,
    config: {
      state: $config,
      stateId: `${dynamicName}`,
      createDate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      stateSettings: {
        name: userName,
        isDefault: false
      }
    }
  }
}

export function isBefore(el1, el2) {
  let current
  if (el1 && el2) {
    if (el2.parentNode === el1.parentNode) {
      for (current = el1.previousSibling; current; current = current.previousSibling) {
        if (current === el2) return true
      }
    }
  }

  return false
}

export function getElementIndex(target, selector) {
  return Array.prototype.slice.call(document.querySelectorAll(selector)).indexOf(target)
}
