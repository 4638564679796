import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnvironmentTypes } from '../../ts'

export interface IState {
  environment: EnvironmentTypes | ''
  designMode: boolean
  connectionEnvironment: number
  isConnectionChangeActive: boolean
  loading: {
    action: boolean
    text: string
  }
}

export const initialState: IState = {
  environment: '',
  designMode: localStorage.getItem('designMode') === 'true',
  connectionEnvironment: 0,
  isConnectionChangeActive: false,
  loading: {
    action: false,
    text: ''
  }
}

export const setting = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setEnvironment: (state, action: PayloadAction<IState['environment']>) => {
      state.environment = action.payload ?? 'Live'
    },
    setDesignMode: (state, action) => {
      state.designMode = action.payload ?? false
    },
    setConnectionEnvironment: (state, action) => {
      state.connectionEnvironment = action.payload ?? 0
    },
    setIsConnectionChangeActive: (state, action) => {
      state.isConnectionChangeActive = action.payload ?? false
    },
    setLoadingStatus: (state, action) => {
      state.loading = action.payload ?? initialState.loading
    }
  }
})

export default setting.reducer

export const {
  setEnvironment,
  setDesignMode,
  setConnectionEnvironment,
  setIsConnectionChangeActive,
  setLoadingStatus
} = setting.actions
