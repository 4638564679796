Ext.define('Assortment.assortmentLibrary.AssortmentTree', {
  extend: 'Ext.tree.Panel',
  alias: 'widget.assortmentTree',
  requires: ['Assortment.model.AssortmentTreeModel'],
  width: 600,
  height: 800,
  reference: 'assortmentTree',
  rootVisible: false,
  columnLines: true,
  rowLines: true,
  publishes: ['currentRow'],
  config: {
    currentRow: null
  },
  bind: {
    currentRow: '{currentRow}'
  },
  features: [
    {
      ftype: 'treeGridFilterAssortment',
      reference: 'assortmentTree'
    }
  ],
  columns: [
    {
      xtype: 'treecolumn',
      dataIndex: 'Name',
      text: 'Assortment Tree',
      sortable: false,
      flex: 2,
      rootVisible: true,
      filter: {
        width: 150
      },
      editor: {
        xtype: 'textfield',
        allowBlank: true,
        allowOnlyWhitespace: false,
        maxLength: 100
      }
    },
    {
      text: 'Status',
      dataIndex: 'Status',
      minWidth: '100px',
      renderer: function (value, cell, record) {
        if (record.get('Status') == 'FOLDER') return null

        return value
      },
      getEditor: function (record) {
        var me = this
        if (!record.get('IsLeaf')) return false

        var assortmentLibrary = me.lookupReferenceHolder()
        var tree = assortmentLibrary.lookupReference('assortmentTree')

        var store = tree.store

        return Ext.create('Ext.grid.CellEditor', {
          field: {
            xtype: 'combo',
            forceSelection: true,
            store: store.AvailableStatuses,
            value: record.get('Status')
          }
        })
      }
    },
    {
      text: 'Description',
      dataIndex: 'Description',
      flex: 1,
      editor: {
        xtype: 'textfield',
        allowBlank: true,
        allowOnlyWhitespace: false,
        maxLength: 100
      }
    },
    {
      text: 'UserName',
      dataIndex: 'UserName',
      flex: 1
    },
    {
      text: 'CreateTime',
      dataIndex: 'CreateTime',
      flex: 1,
      renderer: function (value, cell, record) {
        if (_.startsWith(value, '000') || value == null) {
          return ''
        } else {
          var dt = new Date(value)
          return Ext.Date.format(dt, 'd/m/Y g:i A')
        }
      }
    }
  ],

  plugins: [
    {
      ptype: 'cellediting',
      clicksToEdit: 2
    }
  ],

  listeners: {
    beforeedit: function (editor, context) {
      // Do not update top nodes
      return !context.record.get('IsReadonly')
    },
    edit: function (editor, context, eOpts) {
      if (context.value === context.originalValue) {
        return
      }

      var me = this
      var currentRow = context.record
      currentRow.set(context.field, context.value)

      me.getEl().mask('Loading...')

      var node = $.extend(true, {}, currentRow.data)

      // Remove not needed arguments
      delete node['Children']
      delete node['Filters']
      delete node['id']

      Ext.Ajax.request({
        url: Assortment.Global.getApiUrl() + 'LibraryTreeUpdateNode',
        params: node,
        timeout: 600000,
        success: function (response, opts) {
          var result = Ext.JSON.decode(response.responseText)

          if (result.Code > -1) {
            Assortment.util.operationSuccessWithMessage(result.Description)
            me.store.reload()
          } else {
            Assortment.util.operationFailWithMessage(result.Description)
          }
          if (node['Status'] === 'APPROVED') {
            Ext.Ajax.request({
              url: Assortment.Global.getApiUrl() + 'assortment/Load' + '?nodeId=' + node['Id'],
              params: {
                NodeId: node['Id']
              },
              timeout: 600000,
              success: function (response, opts) {
                var assortmentData = JSON.parse(response.responseText)

                var eventParams = {
                  AssortmentId: node['Id'],
                  ClusterNodeId: assortmentData.ClusterNodeId
                }
                //PS: TODO //
                //me.referencePanel.onAssortmentApproved(eventParams)
                me.referencePanel.fireEvent('assortmentApproved', eventParams)
                me.getEl().unmask()
              },
              failure: function (response, opts) {
                me.getEl().unmask()
              }
            })
          } else {
            me.getEl().unmask()
          }
        },
        failure: function (response, opts) {
          me.getEl().unmask()
          alert('Node update failed.')
        }
      })
    },
    select: function (RowModel, record, index, eOpts) {
      var me = this
      me.currentRow = record
      me.lookupReferenceHolder().getViewModel().set('currentRow', record)
    },
    beforeitemexpand: function (cmp) {
      if (cmp?.data?.isLast) {
        this.scrollBy(10, 10, true)
        this.scrollTo(50, 50)
      }
    }
  },

  findRecentAssortments(recentAssortmentIds, roots, recentAssortments) {
    _.forEach(roots, (node) => {
      if (
        node.data.IsLeaf &&
        node.data.Id >= 0 &&
        _.indexOf(recentAssortmentIds, node.data.Id) >= 0
      ) {
        recentAssortments.push(node)
      } else if (node.childNodes) {
        this.findRecentAssortments(recentAssortmentIds, node.childNodes, recentAssortments)
      }
    })
  },

  initComponent: function () {
    var me = this

    var store = Ext.create('Ext.data.TreeStore', {
      autoLoad: true,
      model: 'Assortment.model.AssortmentTreeModel',
      proxy: {
        type: 'ajax',
        timeout: 600000,
        url: Assortment.Global.getApiUrl() + 'Assortment/GetTree',
        reader: {
          rootProperty: 'Root',
          metaProperty: 'AvailableStatus'
        }
      },

      root: {
        text: 'Root',
        id: '0',
        expanded: true,
        Children: []
      },
      listeners: {
        metachange: function (store, meta) {
          var availableStatuses = meta
          var removedStatuses = ['ASSORTMENT', 'FOLDER']

          availableStatuses = availableStatuses.filter(function (n) {
            return removedStatuses.indexOf(n) === -1
          })

          store.AvailableStatuses = availableStatuses
        },
        load: function () {
          var tree = me
          var assortmentLibrary = me.lookupReferenceHolder()
          var viewModel = assortmentLibrary.getViewModel()
          viewModel.set('currentRow', null)

          var treeStore = this
          // Rename Top Node
          treeStore.data.items[0]?.set('Name', 'All Assortments', {
            commit: true
          })
          treeStore.data.items[0]?.set('Description', '', {
            commit: true
          })
          treeStore.data.items[0]?.set('UserName', '', {
            commit: true
          })
          treeStore.data.items[0]?.set('IsReadonly', true, {
            commit: true
          })

          if (tree.mode === 'Open') {
            var pluginState = tree.referencePanel.getViewModel().get('pluginState')
            var recentAssortmentIds = pluginState.recentAssortments

            var recentAssortments = []
            me.findRecentAssortments(recentAssortmentIds, treeStore.data.items, recentAssortments)

            var recentAssortmentNodes = _.map(recentAssortments, (recentAssortment) => {
              var clonedNode = _.cloneDeep(recentAssortment.data)
              clonedNode.IsReadonly = true
              clonedNode.IsRecentAssortments = true
              return clonedNode
            })

            if (_.size(recentAssortmentNodes) > 0) {
              const recentAssortmentsParentNode = {
                Name: 'Recent Assortments',
                Description: '',
                UserName: '',
                Status: 'FOLDER',
                CreateTime: null,
                IsReadonly: true,
                Children: recentAssortmentNodes,
                IsRecentAssortments: true
              }
              this.data.items[0].parentNode.insertChild(0, recentAssortmentsParentNode)
            }
          }

          if (tree.getRootNode()) {
            if (tree.getRootNode().childNodes.length > 0) {
              tree.getRootNode().childNodes[0].expand()
            }
            if (tree.getRootNode().childNodes.length > 1) {
              tree.getRootNode().childNodes[1].expand()
            }
          }
        }
      }
    })

    me.store = store

    me.callParent()
  }
})
