import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { tMessage } from '../../utils'
import { slvyToast } from '../../../../components'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { getOptions } from '../../store/slices/options'

class OptionModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      segmentId: -1
    }

    this.lastSegmentId = 0
  }

  UNSAFE_componentWillMount() {
    const { segment } = this.props
    this.setState({
      segmentId: _.isArray(segment) ? -1 : segment
    })
  }

  onUpdateOptions(event) {
    // let {
    //   options,
    //   assortmentId = 0,
    //   pluginId = '',
    //   fetchOptions = () => {},
    //   setActionLoader = () => {}
    // } = this.props,
    //   { segmentId } = this.state
    //
    // event.preventDefault()
    //
    // let { target } = event,
    //   updatedOptionsMessages = [],
    //   selectedOptions = _.map(options, (option) => {
    //     let currentSegmentOption = {...option.SegmentOptions[segmentId]},
    //       isChecked = target[`item_${option.Id}`].checked,
    //       isChanged = currentSegmentOption.Enabled !== isChecked
    //
    //     if (isChanged) {
    //       updatedOptionsMessages.push(`${option.Name} has been ${isChecked ? 'added' : 'removed'}`)
    //
    //       currentSegmentOption.Enabled = isChecked
    //       option.SegmentOptions[segmentId] = {...currentSegmentOption}
    //     }
    //
    //     return option
    //   },
    //   []
    // )
    //
    // //setLoader(setActionLoader, SET_LOADER, true, 'Update Options')
    //
    // let payload = {
    //   pluginId,
    //   method: 'UpdateOptions',
    //   requestMethod: 'post',
    //   body: {
    //     OptionStrList: selectedOptions,
    //     AssortmentId: assortmentId
    //   }
    // }
    //
    // //toastr.info('This operation can not be performed!', 'Options Update')
    //
    // return
    //
    // // fetchOptions({
    // //   type: FETCH_OPTIONS, // because update affect/get options data
    // //   payload: {
    // //     ...payload
    // //   }
    // // })
    // //   .then(() => {
    // //     const {
    // //       rootOptions = {}
    // //     } = this.props
    // //
    // //     if (!_.isEmpty(rootOptions)) {
    // //       _.map(updatedOptionsMessages, updatedOptionMessage => tMessage('Success', updatedOptionMessage))
    // //       this.loadOptions(assortmentId)
    // //     } else {
    // //       setLoader(setActionLoader, SET_LOADER, false)
    // //     }
    // //   })
  }

  loadOptions(AssortmentId) {
    const {
      props: { pluginId = '', fetchOptions = () => {}, setLoader = () => {} }
    } = this

    const payload = {
      pluginId,
      method: 'Load',
      requestMethod: 'post',
      body: {
        nodeId: AssortmentId
      }
    }

    fetchOptions({ ...payload }).then(() => {
      const { rootOptions = {}, errorRootOptions = {} } = this.props

      if (!_.isEmpty(rootOptions)) {
        slvyToast.success({ message: 'Options updated successfully!', title: 'Options Update' })
        setLoader({ isShown: false })
      } else if (errorRootOptions && errorRootOptions.message) {
        tMessage('Warning', errorRootOptions.message, 'error')
      }
    })
  }

  render() {
    const { options, filteredOptions, segment = 0, stateHandler } = this.props
    const isSegmentArray = _.isArray(segment)

    return (
      <div className="ac-modal">
        <header>
          <div className="modal-form" />
          <div className="text-end">
            <span
              className="btn-close slvy-ui-icon-times-lt"
              onClick={() => {
                stateHandler('acmodal', false)
              }}
            />
          </div>
        </header>
        <main>
          <form onSubmit={this.onUpdateOptions.bind(this)}>
            {isSegmentArray && (
              <aside>
                {_.map(segment, (sitem, sindex) => {
                  const { FilteredOptions = [], Id } = sitem
                  return (
                    <button
                      key={sindex}
                      className="add-item btn-arrow -arrow-left segment-arrow"
                      type="submit"
                      onClick={() => {
                        this.setState({ segmentId: sitem.Id }, () => {
                          return true
                        })
                        return false
                      }}
                      onMouseOut={() => {
                        this.lastSegmentId = Id
                      }}
                      onMouseOver={() => {
                        if (!_.isEqual(this.lastSegmentId, Id)) {
                          _.map(this.refs, (refItem) => {
                            refItem.checked = false
                          })
                        }
                        _.map(FilteredOptions, (fitem) => {
                          this.refs[`item_${fitem.Id}`].checked = true
                        })
                      }}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  )
                })}
              </aside>
            )}

            <ul className="ac-prod-list clearfix">
              {_.map(options, (optionItem, optionIndex) => {
                const {
                  Name = '',
                  Id: OptionId = 0,
                  NumberOfActiveStores = 0,
                  SizeRange = '',
                  ImageUrl = '',
                  BasePrice = 0
                } = optionItem
                const isSelected = _.isObject(
                  _.find(filteredOptions, (item) => item.Id === OptionId)
                )

                return (
                  <li key={optionIndex}>
                    <input
                      ref={`item_${OptionId}`}
                      defaultChecked={isSelected}
                      id={`ac-item-${OptionId}`}
                      name={`item_${OptionId}`}
                      type="checkbox"
                    />
                    <label htmlFor={`ac-item-${OptionId}`}>
                      <figure>
                        {NumberOfActiveStores > 0 && (
                          <span className="count-box -indicator">
                            <i className="fa fa-tags" />
                            {NumberOfActiveStores}
                          </span>
                        )}

                        {!_.isEmpty(SizeRange) && (
                          <span className="count-box -size">{SizeRange}</span>
                        )}

                        <img alt="" src={ImageUrl} title="" />

                        <figcaption>
                          <span>{Name}</span>
                          <small>${BasePrice}</small>
                        </figcaption>
                      </figure>
                    </label>
                  </li>
                )
              })}
            </ul>

            {!isSegmentArray && (
              <button disabled className="add-item btn-arrow -arrow-top aas" type="submit">
                <i className="fa fa-plus" />
              </button>
            )}
          </form>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    errorRootOptions: state.options.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOptions: bindActionCreators(getOptions, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionModal)
