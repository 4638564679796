import { CSSProperties } from 'react'
import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import ContainerHeader from '../../../Assignees/components/ContainerHeader'
import NoVariables from '../../../Assignees/components/NoVariables'
import EventMethodButton from '../components/EventMethodButton'
import { bodyHeight } from '../../../Assignees/constants'
import { IRemoteAction, TcountInfo } from '../../../Common.types'
import { getClassName, getModifiedItem } from '../helpers'
import {
  IRemoteActionCompletedKey,
  IRemoteActionCompletedProps
} from './RemoteActionCompleted.types'
import { buttonList } from './constants'
import hasInList from './helpers'

const accordionContainerStyle = { maxHeight: bodyHeight - 15 }
const tableStyle: CSSProperties = { tableLayout: 'fixed' }

const getRemoteActionsCount = (
  remoteActionKeys: IRemoteActionCompletedKey[],
  remoteActions: IRemoteAction[]
) => {
  const count = { active: 0, total: _.size(remoteActions) }
  remoteActions.forEach(({ name: remoteActionKey, type }) => {
    const isActive = hasInList(remoteActionKeys, { remoteActionKey, type })
    count.active += isActive ? 1 : 0
  })
  return count
}

const RemoteActionCompleted = (props: IRemoteActionCompletedProps) => {
  const { onSave, params: { remoteActionKeys = [] } = {}, params, remoteActions } = props

  const handleAddItem = (listName: string, obj: IRemoteActionCompletedKey) =>
    onSave(getModifiedItem(params, listName, obj))

  const groupedRemoteActions = _.groupBy(remoteActions, 'type')

  const countInfos: TcountInfo[] = Object.keys(groupedRemoteActions).map((type) => {
    const { active, total } = getRemoteActionsCount(remoteActionKeys, groupedRemoteActions[type])
    return { active, total, id: type }
  })

  return (
    <div className="p-3" data-testid="remote-action-completed-job">
      <div className="overflow-y-auto pe-1" style={accordionContainerStyle}>
        <Accordion
          alwaysOpen
          data-testid="remote-action-completed-accordion"
          defaultActiveKey={countInfos.filter((item) => item.active).map((item) => item.id)}
        >
          {Object.keys(groupedRemoteActions).map((type) => {
            const groupedRemoteActionData = groupedRemoteActions[type]

            const { active, total } = countInfos.find((item) => item.id === type) || {}
            const title = <h6>{type}</h6>
            return (
              <Accordion.Item key={type} eventKey={type}>
                <Accordion.Header className="mb-0 pb-0">
                  <ContainerHeader active={active} title={title} total={total} type="" />
                </Accordion.Header>
                <Accordion.Body className="p-3">
                  {total ? null : <NoVariables text="No remote actions are found!" />}
                  <div className="table-responsive">
                    <table
                      className="table table-striped border align-middle fs-sm p-3 w-100 mb-0"
                      style={tableStyle}
                    >
                      <thead>
                        <tr>
                          <th colSpan={4}>Name</th>
                          <th className="text-end" colSpan={1}>
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedRemoteActionData.map((groupedRemoteActionItem) => {
                          const { name: remoteActionKey, jobId } = groupedRemoteActionItem
                          return (
                            <tr key={remoteActionKey + jobId}>
                              <td className="text-truncate" colSpan={4} title={remoteActionKey}>
                                {remoteActionKey}
                              </td>
                              <td colSpan={1}>
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                  {_.map(
                                    buttonList,
                                    ({ name: buttonName, action: result }, btnKey) => {
                                      const classParams = { type, remoteActionKey, jobId, result }
                                      return (
                                        <EventMethodButton
                                          key={`btn_${btnKey}`}
                                          text={buttonName}
                                          variant={getClassName(remoteActionKeys, classParams)}
                                          onClick={() => {
                                            handleAddItem('remoteActionKeys', classParams)
                                          }}
                                        />
                                      )
                                    }
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}

export default RemoteActionCompleted
