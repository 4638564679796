const hexToRgb = (hex) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))

const shadeRGBColor = (color, percent) => {
  color = color.indexOf('#') > -1 ? `rgb(${hexToRgb(color).join(',')})` : color
  color = color.replace('rgba', 'rgb')

  const f = color.split(',')
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent

  const R = parseInt(f[0].slice(4), 10)
  const G = parseInt(f[1], 10)
  const B = parseInt(f[2], 10)

  return `rgb(${Math.round((t - R) * p) + R},${Math.round((t - G) * p) + G}, ${
    Math.round((t - B) * p) + B
  })`
}

export { hexToRgb, shadeRGBColor }
