import React from 'react'
import _ from 'lodash'
import OverlayTrigger from '../../OverlayTrigger'
import { ICoefButtonsProps, ICoefButton } from './CoefButtons.types'

const defaultName = 'Coef'

const getCoefButtonTooltip = (SpName = '', explanation = '', index: number) => {
  if (explanation.trim() !== '') {
    return explanation
  }

  if (SpName.trim() !== '') {
    return SpName
  }

  return `${defaultName} ${index + 1}`
}

const CoefButtons = (props: ICoefButtonsProps) => {
  const {
    leftKeys,
    coef: {
      buttons: coefSettingsButtons = [],
      header: coefSettingsHeader,
      confirmationMessage: coefSettingsConfirmationMessage,
      cancelButton: coefSettingsCancelButton,
      confirmButton: coefSettingsConfirmButton
    } = {},
    actionCall
  } = props

  return (
    <span data-testid="coef-buttons" className="d-flex">
      {coefSettingsButtons.map((coefButton: ICoefButton, index: number) => {
        const { spName: SpName, explanation, icon, successMessage: SuccessMessage } = coefButton
        const params = {
          name: _.isEmpty(coefSettingsHeader) ? defaultName : coefSettingsHeader,
          SpName,
          SuccessMessage,
          description: coefSettingsConfirmationMessage,
          endPoint: 'ExecuteSpAction',
          cancelButton: coefSettingsCancelButton,
          confirmButton: coefSettingsConfirmButton
        }
        return (
          <OverlayTrigger
            key={`${defaultName}-${index}-overlay-trigger`}
            tooltip={
              <span data-testid={`coef-tooltip-${index}`}>
                {getCoefButtonTooltip(SpName, explanation, index)}
              </span>
            }
          >
            <i
              data-testid={`coef-button-${index}`}
              key={`${defaultName}-${index}-i`}
              className={`fa ${icon} cp`}
              onClick={() => actionCall(leftKeys, params)}
            />
          </OverlayTrigger>
        )
      })}
    </span>
  )
}

export default CoefButtons
