import { fetchCollection } from '../crudoptV3'

const model = 'customer'
const model2 = 'customerEnvironments'

export const getCustomers = (params = {}) => {
  return fetchCollection(model, '/customer', { params })
}

export const getCustomerEnvironments = (customerId) => {
  return fetchCollection(model2, `/customer/customerenvironments/${customerId}`)
}
