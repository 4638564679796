import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import Picker from 'rc-calendar/lib/Picker'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import numeral from 'numeral'
import { bindActionCreators } from 'redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import VirtualList from 'react-tiny-virtual-list'
import NumberFormat from 'react-number-format'
import OptionForm from './option-form'
import OptionFields from './option-fields'
import SimilarProducts from '../SimilarProducts'
import settings from '../../utils/settings'
import sortingOptions from '../../utils/sorting-options'
import { slvyToast } from '../../../../components'
import { confirmAlert } from 'react-confirm-alert'
import OptionImage from '../OptionImage'
import {
  arrayToObject,
  objectToArray,
  filterOptions,
  sortByAdvanced,
  getDateDiff,
  searchOptions,
  removeCurrencySymbol
} from '../../utils'
import OptionSidebar from './option-sidebar'
import ActiveStores from '../ActiveStores'
import { CURRENCIES_LOCALES } from '../../utils/currencies'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { selectOption, unSelectOption, getOptions } from '../../store/slices/options'
import { toggleSimilarOptions } from '../../store/slices/similarOptions'

export class TimePicker extends Component {
  return() {
    return <div />
  }
}

class OptionView extends Component {
  constructor(props) {
    super(props)
    this.sortingOptions = [...sortingOptions]
    this.settings = { ...settings }
    this.state = {
      value: [],
      hoverValue: [],
      show: false,
      selectedOptionIndex: null,
      selectedSegmentIndex: null,
      isScrollEnd: true,
      isCollapsed: false,
      optionUpdateList: [],
      scroll: {
        top: 0,
        left: 0
      }
    }
    this.isScrolling = null
    this.currenciesLocales = { ...CURRENCIES_LOCALES }
  }

  componentDidMount() {
    const { virtualList: { rootNode = null } = {}, leftSideCtn = null } = this.refs

    const { scroll: { top, left } = {} } = this.props

    if (rootNode && leftSideCtn) {
      rootNode.scrollTop = top
      rootNode.scrollLeft = left

      rootNode.onscroll = ({ target: { scrollTop = 0, scrollLeft = 0 } = {} }) => {
        leftSideCtn.scrollTop = scrollTop
        this.setState({ scroll: { top: scrollTop, left: scrollLeft } })
      }
    }
  }

  similarViewHandler(isShown) {
    const { toggleSimilarOptionsR = () => {} } = this.props

    toggleSimilarOptionsR(isShown)
  }

  eventHandler(event) {
    event.stopPropagation()
  }

  isValidRange(value) {
    return value && value[0] && value[1]
  }

  rangeCalendar(start, end, optionItem, segmentIndex) {
    const { hoverValue = [], value = [] } = this.state
    const onHoverChange = (hoverValue) => this.setState({ hoverValue })
    return (
      <RangeCalendar
        showClear
        showOk
        className="segment-range-calendar"
        dateInputPlaceholder={['start', 'end']}
        defaultValue={[start, end]}
        hoverValue={hoverValue}
        showWeekNumber={false}
        timePicker={<TimePicker />}
        onHoverChange={onHoverChange}
        onOk={this.pickerOnOk.bind(this, value, optionItem, segmentIndex)}
      />
    )
  }

  handleClick(optionItem, event) {
    event.stopPropagation()

    const {
      onSelectOption = () => {},
      // onUnSelectOption = () => {},
      SelectedOption: { Id: selectedOptionId = -1, SelectedSegmentIndex = null } = {}
    } = this.props

    optionItem = {
      ...optionItem,
      SelectedSegmentIndex // keep selected
    }

    if (optionItem.Id === selectedOptionId) {
      // TODO why is it commented?
      // onUnSelectOption()
    } else {
      onSelectOption(optionItem)
    }
  }

  onTestProductsToggle(isShowTestProducts) {
    const { onTestProductsToggle = () => {} } = this.props
    onTestProductsToggle(isShowTestProducts, 'Edit')
  }

  onDeleteTestOption(selectedProduct) {
    const {
      props: {
        pluginId = '',
        rootOptions: { AssortmentId = null },
        formattingSettings: { decimal = '0,0.00' } = {},
        fetchOptions = () => {},
        setLoader = () => {},
        onUnSelectOption = () => {}
      }
    } = this

    setLoader({ isShown: true, messages: 'Delete Test Product' })

    const { BasePrice = null, EndDate = null, StartDate = null } = selectedProduct

    let OptionStr = selectedProduct

    const BasePriceFormatted = `${numeral(BasePrice).format(decimal)}`
    const DaysDiff = getDateDiff(StartDate, EndDate, 'days')

    OptionStr = {
      ...OptionStr,
      BasePriceFormatted,
      DaysDiff
    }

    const payload = {
      pluginId,
      method: 'DeleteTestOption',
      requestMethod: 'post',
      body: {
        AssortmentId,
        OptionStr
      }
    }

    fetchOptions({ ...payload }).then(() => {
      const { rootOptions = {}, errorRootOptions: { message: errorMessage = '' } = {} } = this.props

      if (_.isEmpty(rootOptions)) {
        slvyToast.error({ message: errorMessage, title: 'Delete Test Product' })
      } else {
        slvyToast.success({
          message: 'Test Product deleted successfully!',
          title: 'Delete Test Product'
        })

        onUnSelectOption()
      }

      setLoader({ isShown: false })
    })
  }

  markSegmentOptions(val, SegmentOptions) {
    let _segmentOptions = objectToArray({ ...SegmentOptions })
    _segmentOptions = _segmentOptions.map((segmentOption) => {
      segmentOption.Enabled = val
      return segmentOption
    })
    return arrayToObject(_segmentOptions)
  }

  onSelectAllSegments(selectedProduct, $type = 'Select') {
    const {
      props: {
        rootOptions: { AssortmentId = null },
        onUnSelectOption = () => {}
      }
    } = this

    const { SegmentOptions: _SegmentOptions = {} } = selectedProduct

    const SegmentOptions = this.markSegmentOptions($type === 'Select', _SegmentOptions)

    const OptionStr = { ...selectedProduct, SegmentOptions }

    this.onUpdateOption({ OptionStr, AssortmentId }, (response = {}) => {
      if (!_.isEmpty(response)) {
        slvyToast.success({
          message: `${$type} All Segments completed successfully!`,
          title: `${$type} All Segments`
        })

        onUnSelectOption()
      }
    })
  }

  getTooltip(Description = '', Id = 0) {
    return <Tooltip id={Id + Description}>{Description}</Tooltip>
  }

  getDescriptionTemplate(Description, Id, Limit = 20) {
    Description = Description ? Description.trim() : null
    if (!Description) {
      return <div>-</div>
    }
    if (Description.length <= Limit) {
      return <div>{Description}</div>
    }
    const croppedDescription = `${Description.substring(0, Limit)}...`
    return (
      <OverlayTrigger overlay={this.getTooltip(Description, Id)} placement="top">
        <div>{croppedDescription}</div>
      </OverlayTrigger>
    )
  }

  getSimilarOptions(event, optionItem = {}, SegmentId, onSelectOption = () => {}) {
    event.stopPropagation()
    const selectedSegment = _.find(optionItem.SegmentOptions, (item) => {
      return item.SegmentId === SegmentId
    })

    if (!_.isNil(selectedSegment)) {
      if (selectedSegment.Enabled) {
        //TODO refactor for duplicate functions
        optionItem = {
          ...optionItem,
          SelectedSegmentIndex: SegmentId
        }

        onSelectOption(optionItem)

        this.similarViewHandler(true)
      } else {
        confirmAlert({
          title: 'Save Changes',
          message:
            'There are unsaved changes on this option. You have to save them before continue. Do you want to proceed?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => false
            },
            {
              label: 'Save',
              onClick: () => {
                this.handleClickUpdateOption(optionItem.Id)
                optionItem = {
                  ...optionItem,
                  SelectedSegmentIndex: SegmentId
                }

                onSelectOption(optionItem)

                this.similarViewHandler(true)
              }
            }
          ]
        })
      }
    }
  }

  setOptionUpdateList = (OptionStr, Enabled, segmentEnabled, SegmentId, event) => {
    const { Editable: isFutureOption = false, Id: optionId } = OptionStr
    if (!isFutureOption) {
      slvyToast.error({
        message: 'Option can not be assigned. It is not a future option!',
        title: 'Assign Option'
      })
      return
    }
    const optionUpdateList = _.cloneDeep(this.state.optionUpdateList)
    const selectedIndex = _.findIndex(optionUpdateList, {
      optionId,
      SegmentId
    })

    if (selectedIndex === -1) {
      optionUpdateList.push({ optionId, SegmentId, Enabled: !segmentEnabled })
    } else if (Enabled === segmentEnabled) {
      // TODO we do not expect that this case will happen
      optionUpdateList[selectedIndex].Enabled = !segmentEnabled
    } else {
      _.pullAt(optionUpdateList, selectedIndex)
    }
    this.setState({ optionUpdateList })
  }

  startUpdateOption(OptionStr, Enabled, segmentIndex, event) {
    event.stopPropagation()
    const {
      props: {
        rootOptions: { AssortmentId = '' }
      }
    } = this

    const { Editable: isFutureOption = false } = OptionStr

    if (!isFutureOption) {
      slvyToast.error({
        message: 'Option can not be assigned. It is not a future option!',
        title: 'Assign Option'
      })
      return
    }

    OptionStr.SegmentOptions[segmentIndex].Enabled = !Enabled

    this.onUpdateOption({ AssortmentId, OptionStr })
  }

  onUpdateOption(params, $callback = () => {}) {
    const {
      props: {
        pluginId = '',
        formattingSettings: { decimal = '0,0.00' } = {},
        fetchOptions = () => {},
        setLoader = () => {},
        scrollHandler = () => {}
      },
      settings: { DecimalFormat }
    } = this

    scrollHandler(this.state.scroll)

    setLoader({ isShown: true, messages: 'Update Option' })

    const {
      BasePrice = null,
      EndDate = null,
      StartDate = null,
      Expenditure = null,
      PrePackSize = null,
      Sellthrough = null
    } = params.OptionStr

    const { OptionStr } = params

    const BasePriceFormatted = `${numeral(BasePrice).format(decimal)}`
    const DaysDiff = getDateDiff(StartDate, EndDate, 'days')

    OptionStr.SegmentOptions = objectToArray(OptionStr.SegmentOptions).map((segmentOption) => {
      const { EndDate: sEndDate = null, StartDate: sStartDate = null } = segmentOption
      segmentOption.DaysDiff = getDateDiff(sStartDate, sEndDate, 'days')
      return segmentOption
    })

    OptionStr.SegmentOptions = arrayToObject(OptionStr.SegmentOptions)

    const payload = {
      pluginId,
      method: 'UpdateOption',
      requestMethod: 'post',
      body: {
        ...params,
        OptionStr: {
          ...OptionStr,
          BasePriceFormatted,
          DaysDiff,
          Expenditure: numeral(numeral(Expenditure).format(DecimalFormat)).value(),
          PrePackSize: numeral(numeral(PrePackSize).format(DecimalFormat)).value(),
          Sellthrough: numeral(numeral(Sellthrough).format(DecimalFormat)).value()
        }
      }
    }

    fetchOptions({ ...payload }).then(() => {
      const { rootOptions = {} } = this.props

      if (_.isEmpty(rootOptions)) {
        slvyToast.error({ message: 'There is an error!', title: 'Update Error!' })
      }
      setLoader({ isShown: false })

      $callback(rootOptions)
    })
  }

  pickerOnOk(value, optionItem, segmentIndex) {
    const {
      settings: { StateDateFormat },
      props: { rootOptions: { AssortmentId = '' } = {} }
    } = this

    this.setState({ value })

    const StartDate = moment(_.first(value)).format(StateDateFormat)
    const EndDate = moment(_.last(value)).format(StateDateFormat)

    optionItem.SegmentOptions[segmentIndex].StartDate = StartDate
    optionItem.SegmentOptions[segmentIndex].EndDate = EndDate

    this.onUpdateOption({
      AssortmentId,
      OptionStr: optionItem
    })
  }

  handleCloseActiveStoresModal() {
    this.displayActiveStoreModal(null, null)
  }

  handleApplyActiveStores(OptionStr) {
    const { rootOptions: { AssortmentId } = {} } = this.props
    this.onUpdateOption({ AssortmentId, OptionStr })
  }

  displayActiveStoreModal(selectedOptionIndex, selectedSegmentIndex) {
    this.setState({
      selectedOptionIndex,
      selectedSegmentIndex
    })
  }

  onScroll() {
    // TODO
    const images = document.querySelectorAll('img.option-img')
    images.forEach((img) => img.removeAttribute('src'))

    this.setState({ isScrollEnd: false }, () => {
      clearTimeout(this.isScrolling)
      this.isScrolling = setTimeout(() => this.setState({ isScrollEnd: true }), 500)
    })
  }

  removeCurrencySymbol(value, format) {
    const { formattingSettings: { currencySymbol = '$' } = {} } = this.props

    return removeCurrencySymbol(numeral(value).format(format), currencySymbol)
  }

  onChangeCollapse() {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    })
  }

  handleClickUpdateOption(optionId, event) {
    const { rootOptions: { AssortmentId = '', Options = [] } = {} } = this.props
    const selectedOptionUpdateList = _.filter(this.state.optionUpdateList, (item) => {
      return item.optionId === optionId
    })
    const OptionStr = _.find(_.cloneDeep(Options), { Id: optionId })
    const newOptionStr = { ...OptionStr }
    const { Editable: isFutureOption = false } = newOptionStr

    // TODO check error cases
    if (!isFutureOption) {
      slvyToast.error({
        message: 'Option can not be assigned. It is not a future option!',
        title: 'Assign Option'
      })
      return
    }

    _.forEach(selectedOptionUpdateList, (item) => {
      newOptionStr.SegmentOptions[item.SegmentId].Enabled = item.Enabled
    })

    this.onUpdateOption({ AssortmentId, OptionStr: newOptionStr })
    const removedOptionUpdateList = _.filter(this.state.optionUpdateList, (item) => {
      return item.optionId !== optionId
    })
    this.setState({ optionUpdateList: removedOptionUpdateList })
  }

  render() {
    let {
      settings: { DateFormat = null },
      props: {
        optionViewColumns,
        similarProductsColumns,
        similarProductsHeaderButtons = [],
        optionPriceEditable = false,
        onSelectOption = () => {},
        isShownSimilarProducts = false,
        rootOptions: {
          Options = [],
          Segments = {},
          AssortmentId,
          SizeRangeMapping = {},
          Stores = []
        } = {},
        Settings,
        Settings: { sortBy = 'aZ_Ascending', query = '' },
        formattingSettings,
        formattingSettings: { current = '0,0', decimal = '0,0.00', currencySymbol = '$' } = {},
        SelectedOption: { Id: selectedOptionId = -1 } = {},
        pluginId,
        size: { width = 0 } = {},
        culture,
        similarProductsEventRef
      },
      state: {
        isCollapsed = false,
        selectedOptionIndex = null,
        selectedSegmentIndex = null,
        optionUpdateList = []
      },
      currenciesLocales: {
        [culture]: { thousandSeparator, decimalSeparator, decimalScale } = {}
      } = {}
    } = this

    const isShownActiveStores = selectedOptionIndex !== null && selectedSegmentIndex !== null
    const [CurrentSortBy = {}] = [...this.sortingOptions].filter(({ key }) => key === sortBy) || []

    Options = searchOptions(Options, query)
    Options = filterOptions(_.cloneDeep(Options), Settings)
    Options = sortByAdvanced(Options, sortBy)

    const notFound = !Options.length

    return (
      <div className="option-view flex-grow-15 overflow-auto">
        {isShownSimilarProducts ? (
          <div className="similar-products-wrp">
            <SimilarProducts
              pluginId={pluginId}
              gridColumns={similarProductsColumns}
              similarProductsEventRef={similarProductsEventRef}
              similarProductsHeaderButtons={similarProductsHeaderButtons}
              similarViewHandler={this.similarViewHandler.bind(this)}
            />
          </div>
        ) : null}
        {isShownActiveStores ? (
          <ActiveStores
            handleCloseModal={this.handleCloseActiveStoresModal.bind(this)}
            handleOnSave={this.handleApplyActiveStores.bind(this)}
            option={Options[selectedOptionIndex]}
            pluginId={pluginId}
            segmentIndex={selectedSegmentIndex}
            show={isShownActiveStores}
            stores={Stores}
          />
        ) : null}

        <div className={`main-wrp h-100 ${isShownSimilarProducts ? 'blur' : ''}`}>
          <div ref="leftSideCtn" className="left-side-ctn">
            <OptionSidebar
              CurrentSortBy={CurrentSortBy}
              IsPreviewMode={false}
              Options={Options}
              Segments={Segments}
              isCollapsed={isCollapsed}
              optionViewColumns={optionViewColumns}
              formattingSettings={formattingSettings}
              onChangeCollapse={this.onChangeCollapse.bind(this)}
            />
          </div>
          {notFound ? (
            <div className="-not-found">
              <span className="slvy-ui-icon-exclamation-circle-solid" />
              <p>
                Sorry, <b>{query}</b> not found.
              </p>
            </div>
          ) : null}
          <VirtualList
            ref="virtualList"
            className="options-ctn"
            height="100%"
            itemCount={Options.length}
            itemSize={154}
            renderItem={({ index: optionIndex, style }) => {
              const optionItem = Options[optionIndex]
              const {
                IsTestProduct = false,
                ImageUrl = '',
                SegmentOptions = {},
                Name = '',
                Description = '',
                Id = -1,
                BasePrice,
                Editable: isFutureOption = false,
                TotalSales = 0,
                TotalBuyQuantity = 0
              } = optionItem

              const IsActive = !_.isEqual(selectedOptionId, Id)
              let imgClasses = IsTestProduct ? ' test-product ' : ''
              imgClasses += IsActive ? '' : ' shadow '
              const { isScrollEnd } = this.state

              const basePriceInputId = `BasePrice-Input-${Id}-${optionIndex}`

              const $extraFix =
                currencySymbol === '$' ? { prefix: currencySymbol } : { suffix: currencySymbol }
              const editedOption = _.find(optionUpdateList, { optionId: Id })
              return (
                <div
                  key={optionIndex}
                  className="option-w-segments"
                  style={style}
                  onClick={this.handleClick.bind(this, optionItem)}
                >
                  <div className={`option-card ${imgClasses}`}>
                    <div className="option-img-ctn">
                      <div className="option-img">
                        <OptionImage
                          alt={Description}
                          isScrollEnd={isScrollEnd}
                          source={ImageUrl}
                        />
                        {editedOption && (
                          <i
                            className="slvy-ui-icon-check-circle-regular opdate-opt-icon"
                            onClick={this.handleClickUpdateOption.bind(this, Id)}
                          />
                        )}
                      </div>
                      <div className="spinner" />
                      {this.getDescriptionTemplate(Description, Id)}
                      <div>{Name}</div>
                      {optionPriceEditable && !IsActive ? (
                        <ul className="card-text-ctn">
                          <li>
                            <NumberFormat
                              fixedDecimalScale
                              className="form-items"
                              decimalScale={decimalScale}
                              decimalSeparator={decimalSeparator}
                              defaultValue={numeral(BasePrice).format(decimal)}
                              id={basePriceInputId}
                              thousandSeparator={thousandSeparator}
                              onClick={this.eventHandler}
                              {...$extraFix}
                            />
                          </li>
                        </ul>
                      ) : (
                        <div data-price={numeral(BasePrice).format(decimal)} id={basePriceInputId}>
                          {numeral(BasePrice).format(decimal)}
                        </div>
                      )}
                      <div>{this.removeCurrencySymbol(TotalSales, current)}</div>
                      <div>{this.removeCurrencySymbol(TotalBuyQuantity, current)}</div>
                    </div>
                    {isCollapsed ? null : IsActive ? (
                      <OptionFields
                        SizeRangeMapping={SizeRangeMapping}
                        data={optionItem}
                        optionViewColumns={optionViewColumns}
                        formattingSettings={formattingSettings}
                        removeCurrencySymbol={this.removeCurrencySymbol.bind(this)}
                      />
                    ) : (
                      <OptionForm
                        assortmentId={AssortmentId}
                        basePriceInputId={basePriceInputId}
                        culture={culture}
                        data={optionItem}
                        optionViewColumns={optionViewColumns}
                        pluginId={pluginId}
                        similarViewHandler={this.similarViewHandler.bind(this)}
                        sizeRangeMapping={SizeRangeMapping}
                        onDeleteTestOption={this.onDeleteTestOption.bind(this)}
                        onSelectAllSegments={this.onSelectAllSegments.bind(this)}
                        onTestProductsToggle={this.onTestProductsToggle.bind(this)}
                        onUpdateOption={this.onUpdateOption.bind(this)}
                      />
                    )}
                    {_.map(SegmentOptions, (segmentItem, segmentIndex) => {
                      let {
                        Enabled,
                        BuyQuantity,
                        StartDate: SegmentStartDate,
                        EndDate: SegmentEndDate,
                        SegmentId
                      } = segmentItem

                      let segmentEnabled = Enabled
                      _.forEach(this.state.optionUpdateList, (item) => {
                        if (item.optionId === Id && item.SegmentId === SegmentId) {
                          segmentEnabled = item.Enabled
                        }
                      })
                      SegmentStartDate = moment(SegmentStartDate)
                      SegmentEndDate = moment(SegmentEndDate)
                      BuyQuantity = Math.round(BuyQuantity)

                      return (
                        <div key={segmentIndex} className="segment">
                          <div className="segmentActions">
                            <OverlayTrigger
                              overlay={this.getTooltip(
                                segmentEnabled ? 'Unassign Option' : 'Assign Option'
                              )}
                              placement="top"
                            >
                              <div
                                className={`enabled-segments ${segmentEnabled}`}
                                onClick={this.setOptionUpdateList.bind(
                                  this,
                                  optionItem,
                                  Enabled,
                                  segmentEnabled,
                                  SegmentId
                                )}
                              >
                                <span className="slvy-ui-icon-save" />
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={this.getTooltip('Active Stores')}
                              placement="top"
                            >
                              <button
                                className="similar assortment-similar-btn no-padding"
                                type="button"
                                onClick={this.displayActiveStoreModal.bind(
                                  this,
                                  optionIndex,
                                  segmentIndex
                                )}
                              >
                                <span className="slvy-ui-icon-shopping-bag-outline" />
                              </button>
                            </OverlayTrigger>
                            {segmentEnabled ? (
                              <OverlayTrigger
                                overlay={this.getTooltip('Similar Products')}
                                placement="top"
                              >
                                <button
                                  className="similar assortment-similar-btn no-padding"
                                  type="button"
                                  onClick={(e) => {
                                    this.getSimilarOptions(e, optionItem, SegmentId, onSelectOption)
                                  }}
                                >
                                  <span className="slvy-ui-icon-diff" />
                                </button>
                              </OverlayTrigger>
                            ) : null}
                            <OverlayTrigger
                              overlay={this.getTooltip('Buy Quantity')}
                              placement="top"
                            >
                              <small className="segment-field">{BuyQuantity}</small>
                            </OverlayTrigger>
                          </div>

                          {!IsActive && (
                            <Picker
                              animation="slide-up"
                              calendar={this.rangeCalendar(
                                SegmentStartDate,
                                SegmentEndDate,
                                optionItem,
                                segmentIndex
                              )}
                              defaultValue={[SegmentStartDate, SegmentEndDate]}
                              disabled={!isFutureOption}
                              onChange={(value) => {
                                this.setState({ value })
                              }}
                            >
                              {({ value }) => {
                                const [start, end] = value
                                return (
                                  <input
                                    readOnly
                                    className="segment-date"
                                    disabled={!isFutureOption}
                                    placeholder="Please select dates"
                                    type="text"
                                    value={
                                      this.isValidRange(value)
                                        ? `${start.format(DateFormat)} - ${end.format(DateFormat)}`
                                        : ''
                                    }
                                    onClick={this.eventHandler.bind(this)}
                                  />
                                )
                              }}
                            </Picker>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }}
            scrollDirection="horizontal"
            width={width - 154}
            onScroll={this.onScroll.bind(this)}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    errorRootOptions: state.options.error,
    SelectedOption: state.options.selected,
    Settings: state.optionSettings,
    isShownSimilarProducts: state.similarOptions.isShown,
    formattingSettings: state.plugin.formattingSettings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOptions: bindActionCreators(getOptions, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch),
    onSelectOption: bindActionCreators(selectOption, dispatch),
    onUnSelectOption: bindActionCreators(unSelectOption, dispatch),
    toggleSimilarOptionsR: bindActionCreators(toggleSimilarOptions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionView)
