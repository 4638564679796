import { Button } from 'react-bootstrap'
import cx from 'classnames'
import { newCard } from './constants'
import { AddCardProps } from './AddCard.types'

const AddCard = ({ config, setItems, user }: AddCardProps) => {
  const {
    button: { color, icon, size }
  } = config

  const handleAddCard = () => {
    const date = new Date().toUTCString()

    const card = {
      ...newCard,
      author: user,
      title: date
    }

    setItems((prev) => [...prev, card])
  }

  return (
    <Button className="w-100" size="sm" variant="base" onClick={handleAddCard}>
      <i className={cx('pt-1', icon)} style={{ color, fontSize: size }} />
    </Button>
  )
}

export default AddCard
