Ext.define('clustering.Extension.MultiCombo', {
  extend: 'Ext.form.ComboBox',
  alias: 'widget.multicombobox',
  collapse: function () {
    var me = this

    var elementId = me.getId() + '-toolbar-panel' + '-container'
    var clickedElement = Ext.get(elementId)

    if (clickedElement) {
      if (clickedElement.query('#' + Ext.get(document.activeElement).el.id).length > 0) {
        return false
      }
    }

    if (me.isExpanded && !me.destroyed && !me.destroying) {
      var openCls = me.openCls,
        picker = me.picker,
        aboveSfx = '-above'

      // hide the picker and set isExpanded flag
      picker.hide()
      me.isExpanded = false

      // remove the openCls
      me.bodyEl.removeCls([openCls, openCls + aboveSfx])
      picker.el.removeCls(picker.baseCls + aboveSfx)

      if (!me.ariaStaticRoles[me.ariaRole]) {
        me.ariaEl.dom.setAttribute('aria-expanded', false)
      }

      // remove event listeners
      me.touchListeners.destroy()
      me.scrollListeners.destroy()
      Ext.un('resize', me.alignPicker, me)
      me.fireEvent('collapse', me)
      me.onCollapse()
    }
  },
  initComponent: function () {
    var combo = this
    combo.multiSelect = true

    if (combo.multiSelect) {
      var allSelected = false
      var id = combo.getId() + '-toolbar-panel'

      Ext.apply(combo, {
        listConfig: {
          tpl: new Ext.XTemplate(
            '<div id="' +
              id +
              '"></div><tpl for="."><div class="x-boundlist-item fixedCombo">{' +
              combo.displayField +
              '}</div></tpl>'
          )
        }
      })

      var manageSelectAllCheckHandler = function (enable) {
        var selectAllCheck = toolbar.down('#selectAllCheck')
        // Do a handler backup
        var handlerBkp = selectAllCheck.handler
        // Disable handler
        selectAllCheck.handler = null
        // Set value
        selectAllCheck.setValue(enable)
        // Enable handler
        selectAllCheck.handler = handlerBkp
        // Change 'SelectAll' state
        allSelected = enable
        // Clean memory
        handlerBkp = null
      }

      var toolbar = Ext.create('Ext.toolbar.Toolbar', {
        items: [
          {
            xtype: 'checkbox',
            itemId: 'selectAllCheck',
            fieldLabel: 'All',
            labelWidth: 17,
            handler: function (me, evt) {
              if (!allSelected) {
                combo.select(combo.getStore().getRange())
                allSelected = true
              } else {
                combo.select([])
                allSelected = false
              }

              if (typeof evt !== 'boolean') {
                evt.stopEvent()
              }
            }
          },
          '-',
          {
            xtype: 'textfield',
            itemId: 'filterTextField',
            emptyText: 'Search',
            enableKeyEvents: true,
            width: '80px',
            listeners: {
              change: function (field, e) {
                combo.getStore().clearFilter()
                if (field.getValue()) {
                  combo.getStore().filter(combo.displayField, field.getValue())
                  manageSelectAllCheckHandler(false)
                  field.focus()
                }
              }
            }
          }
        ]
      })

      combo.on({
        select: function (me, records) {
          var len = records.length
          var store = me.getStore()
          if (len === store.getRange().length && !allSelected) {
            manageSelectAllCheckHandler(true)
          }
        },
        beforedeselect: function (me, record, index) {
          if (allSelected) {
            manageSelectAllCheckHandler(false)
          }
        },
        beforehide: function () {
          return !(combo.view && combo.view.isVisible())
        },
        expand: {
          fn: function () {
            var me = this

            var selectAllCheck = toolbar.down('#selectAllCheck')
            var filterTextField = toolbar.down('#filterTextField')
            filterTextField.setValue('')

            if (!this.toolbarRendered) {
              var dropdown = Ext.get(id).dom.parentElement
              var container = Ext.DomHelper.insertBefore(
                dropdown,
                '<div id="' + id + '-container"></div>',
                true
              )
              toolbar.render(container)
              this.toolbarRendered = true
            }
            var closeHandler = function (event) {
              var menuContainer = $('#' + toolbar.id)
                .parent()
                .parent()
              if (!$.contains(menuContainer[0], event.target)) {
                if (me.collapse() != false) {
                  $(window).unbind('click', closeHandler)
                }
              }
            }

            $(window).bind('click', closeHandler)

            if (combo.value && combo.getStore() && combo.getStore().getRange()) {
              selectAllCheck.setValue(combo.value.length === combo.getStore().getRange().length)
            }
            $(combo.picker.listEl.dom).scrollTop(-50)
          }
        }
      })
    }

    combo.callParent()
  }
})
