import { TOGGLE_LOGGING } from '../actions/actionTypes'
import { updateObject } from './utility'

const initialState = {
  isLoggingEnabled: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LOGGING: {
      const nextIsLoggingEnabled = !state.isLoggingEnabled
      console.log(`Logging ${nextIsLoggingEnabled ? 'enabled' : 'disabled'}`)

      return updateObject(state, { isLoggingEnabled: nextIsLoggingEnabled })
    }
    default:
      return state
  }
}
