import { getHeaderClass } from '../headerMenu'
import { GetHeaderClassProps } from '../headerMenu.type'
import { agRightAlignedHeader } from '../../../constants'
import styles from '../../../scss/_header.module.scss'

const getHeaderClassParams = (): GetHeaderClassProps => ({
  alignHeader: 'left'
})

describe('AgGrid:Helpers:headerMenu:getHeaderClass', () => {
  let params = getHeaderClassParams()

  beforeEach(() => {
    params = getHeaderClassParams()
  })
  it('should return an empty string if provided alignHeader does not match with center or right', () => {
    const { headerClass } = getHeaderClass(params)
    expect(headerClass).toEqual('')
  })
  it('should return hashed version of ag-center-aligned-header for center alignment', () => {
    params.alignHeader = 'center'
    const { headerClass } = getHeaderClass(params)
    expect(headerClass).toEqual(styles.agCenterAlignedHeader)
  })
  it('should return "ag-right-aligned-header" for right alignment', () => {
    params.alignHeader = 'right'
    const { headerClass } = getHeaderClass(params)
    expect(headerClass).toEqual(agRightAlignedHeader)
  })
})
