import { Provider } from 'react-redux'
import createPlugin from '@/BasePlugin'
import AgGrid from './AgGrid'
import { store } from './store'
import selectConnectorProps from './selectConnectorProps'

const AgGridWithRedux = (props) => {
  return (
    <Provider store={store}>
      <AgGrid {...props} />
    </Provider>
  )
}

export default createPlugin(AgGridWithRedux, selectConnectorProps, true)
