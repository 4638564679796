import * as actionTypes from './actionTypes'
import { makeRequest } from '../../utils'

export const fetchQuickFilter = (action) => {
  return (dispatch) => {
    dispatch(startGetQuickFilterStart())
    return new Promise((resolve, reject) => {
      makeRequest(action)
        .then((response) => {
          dispatch(
            saveGetQuickFilter({
              payload: response.data
            })
          )
          resolve(response.data)
        })
        .catch((error) => {
          dispatch(
            saveGetQuickFilterFailed({
              payload: error.data
            })
          )
          reject(error.data)
        })
    })
  }
}

export const startGetQuickFilterStart = () => {
  return {
    type: actionTypes.FETCH_QUICK_FILTER_START
  }
}

export const saveGetQuickFilter = (options) => {
  return {
    type: actionTypes.STORE_QUICK_FILTER,
    payload: options.payload
  }
}

export const saveGetQuickFilterFailed = (options) => {
  return {
    type: actionTypes.STORE_QUICK_FILTER_FAILED,
    payload: options.payload
  }
}
