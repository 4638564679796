export default () => {
  return {
    title: 'Purchase Order',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            default: ''
          },
          applicationType: {
            type: 'string',
            title: 'Application Type',
            enum: ['DC', 'Store'],
            required: true,
            default: 'DC'
          },
          language: {
            type: 'string',
            title: 'Language',
            enum: ['EN', 'TR'],
            required: true,
            default: 'TR'
          },
          isReasonCodeRequiredOnChange: {
            type: 'boolean',
            title: 'Reason Code Enabled?'
          },
          groupByField: {
            type: 'string',
            title: 'Group By Field'
          }
        }
      },
      mainGridHeaders: {
        type: 'array',
        title: 'Main Grid Headers',
        options: {
          disable_array_reorder: true
        },
        items: {
          type: 'object',
          title: 'Header',
          headerTemplate: '{{ self.text }}',
          options: {
            collapsed: true
          },
          properties: {
            text: {
              type: 'string'
            },
            locked: {
              type: 'boolean',
              required: true,
              default: false
            },
            sortable: {
              type: 'boolean',
              required: true,
              default: true
            },
            hideable: {
              type: 'boolean',
              required: true,
              default: true
            }
          }
        },
        default: mainGridHeaderDefault
      },
      mainGridColumns: {
        type: 'array',
        title: 'Main Grid Columns',
        options: {
          disable_array_add: true,
          disable_array_delete: true,
          syncButton: true
        },
        items: {
          type: 'object',
          title: 'Column',
          headerTemplate: '{{ self.columnId }}',
          options: {
            collapsed: true
          },
          properties: {
            columnType: {
              oneOf: [column_type_action, column_type_widget, column_type_data],
              options: {
                disable_collapse: true,
                readonly: true
              }
            },
            columnId: {
              type: 'string',
              title: 'Column',
              readonly: true
            },
            text: {
              type: 'string'
            },
            tooltip: {
              type: 'string'
            },
            groupHeader: {
              type: 'string',
              title: 'Group Header',
              required: false,
              default: '',
              watch: {
                headers: 'mainGridHeaders'
              },
              enumSource: [
                {
                  source: ['-']
                },
                {
                  source: 'headers',
                  title: '{{item.text}}',
                  value: '{{i}}'
                }
              ],
              options: {
                basic: true
              }
            },
            width: {
              title: 'Width',
              type: 'number'
            },
            locked: {
              type: 'boolean',
              required: true,
              default: false
            },
            hidden: {
              type: 'boolean',
              required: true,
              default: false
            },
            menuDisabled: {
              type: 'boolean',
              required: true,
              default: false
            },
            sortable: {
              type: 'boolean',
              required: true,
              default: false
            },
            align: {
              type: 'string',
              enum: ['left', 'center', 'right'],
              default: 'left',
              required: false
            }
          }
        },
        default: mainGridColumnDefault
      },
      panelVisibility: {
        type: 'object',
        title: 'Panel Visibility',
        properties: {
          productDetailVisible: {
            type: 'boolean',
            title: 'Product Detail Visible'
          },
          summaryEditorHidden: {
            type: 'boolean',
            title: 'Summary Editor Hidden',
            default: false
          },
          roundUpHidden: {
            type: 'boolean',
            title: 'Round Up Hidden',
            default: false
          },
          inventoryProfileVisible: {
            type: 'boolean',
            title: 'Inventory Profile Visible'
          },
          InventoryHidden: {
            type: 'boolean',
            title: 'Inventory Hidden',
            default: false
          },
          grupGridHidden: {
            type: 'boolean',
            title: 'Grup Grid Hidden',
            default: false
          },
          footerToolbarVisible: {
            type: 'boolean',
            title: 'Footer Toolbar Visible'
          },
          footerToolbarERPGroupVisible: {
            type: 'boolean',
            title: 'Footer ERP Group Visible'
          },
          resetColumnSequence: {
            type: 'boolean',
            title: 'Reset Column Sequence Visible',
            default: false
          }
        }
      },
      attributeFilters: {
        type: 'object',
        title: 'Attribute Filters',
        properties: {
          items: {
            type: 'array',
            format: 'table',
            title: 'Filters',
            items: {
              type: 'object',
              title: 'Filter',
              properties: {
                column: {
                  type: 'string',
                  title: 'Column'
                }
              }
            }
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },
      zeroInventoryGrid: {
        type: 'object',
        title: 'Zero Inventory Grid',
        properties: {
          magzaKoduVisible: {
            type: 'boolean',
            title: 'Magza Kodu',
            default: true
          },
          magzaAdiVisible: {
            type: 'boolean',
            title: 'Magza Adi ',
            default: true
          },
          envanterVisible: {
            type: 'boolean',
            title: 'Envanter ',
            default: true
          },
          statuVisible: {
            type: 'boolean',
            title: 'Statu ',
            default: true
          }
        }
      }
    }
  }
}

const column_type_action = {
  title: 'Action',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['action'],
      options: {
        hidden: true
      }
    }
  },
  additionalProperties: false
}

const column_type_widget = {
  title: 'Widget',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['widget'],
      options: {
        hidden: true
      }
    }
  },
  additionalProperties: false
}

const column_type_data = {
  title: 'Data',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      enum: ['data'],
      options: {
        hidden: true
      }
    }
  },
  additionalProperties: false
}

const mainGridHeaderDefault = [
  {
    text: 'Ürün',
    locked: true
  },
  {
    text: 'Sipariş Önerisi',
    locked: true
  },
  {
    text: 'Öneri Parametreleri'
  },
  {
    text: 'Tedarikçi Bilgileri'
  },
  {
    text: 'Mağaza'
  },
  {
    text: 'Ürün Bilgileri'
  },
  {
    text: 'Stok Bilgileri'
  }
]

const mainGridColumnDefault = [
  {
    columnId: 'selectCheckboxColumn',
    columnType: {
      type: 'action'
    },
    groupHeader: '0',
    menuDisabled: true,
    width: 40,
    locked: true,
    hidden: false
  },
  {
    columnId: 'drillDown',
    columnType: {
      type: 'action'
    },
    groupHeader: '0',
    menuDisabled: true,
    width: 40,
    locked: true,
    hidden: true,
    align: 'center'
  },
  {
    columnId: 'calendarEventColumn',
    columnType: {
      type: 'action'
    },
    groupHeader: '0',
    menuDisabled: true,
    width: 40,
    hidden: true,
    locked: true,
    align: 'center'
  },
  {
    columnId: 'drilldownAction',
    columnType: {
      type: 'action'
    },
    groupHeader: '0',
    menuDisabled: true,
    locked: true,
    hidden: false,
    width: 62
  },
  {
    columnId: 'depoAdi',
    columnType: {
      type: 'data'
    },
    groupHeader: '0',
    width: 100,
    locked: true
  },
  {
    columnId: 'depoKodu',
    columnType: {
      type: 'data'
    },
    groupHeader: '0',
    width: 80,
    locked: true
  },
  {
    columnId: 'urunKodu',
    columnType: {
      type: 'data'
    },
    groupHeader: '0',
    width: 90,
    locked: true
  },
  {
    columnId: 'urunAciklamasi',
    columnType: {
      type: 'data'
    },
    groupHeader: '0',
    sortable: true,
    width: 250,
    locked: true
  },
  {
    columnId: 'SiparisKalemNo',
    columnType: {
      type: 'data'
    },
    groupHeader: '0',
    sortable: true,
    width: 80,
    locked: true
  },
  {
    columnId: 'SiparisNo',
    columnType: {
      type: 'data'
    },
    groupHeader: '0',
    sortable: true,
    width: 80,
    locked: true
  },
  {
    columnId: 'PRODUCT_BRAND',
    columnType: {
      type: 'data'
    },
    groupHeader: '0',
    sortable: true,
    width: 80,
    locked: true,
    hidden: true
  },
  {
    columnId: 'muadil',
    columnType: {
      type: 'data'
    },
    groupHeader: '1',
    sortable: true,
    width: 40,
    locked: true,
    align: 'center'
  },
  {
    columnId: 'onceliklendirme',
    columnType: {
      type: 'data'
    },
    groupHeader: '1',
    sortable: true,
    width: 52,
    locked: true,
    align: 'right',
    menuDisabled: true
  },
  {
    columnId: 'YuvarlanmamisDepoSiparisKoli',
    columnType: {
      type: 'data'
    },
    groupHeader: '1',
    sortable: true,
    width: 75,
    locked: true,
    align: 'right'
  },
  {
    columnId: 'depoSiparisKoli',
    columnType: {
      type: 'data'
    },
    groupHeader: '1',
    sortable: true,
    width: 75,
    locked: true,
    align: 'right'
  },
  {
    columnId: 'degisenOneriKoli',
    columnType: {
      type: 'data'
    },
    groupHeader: '1',
    sortable: true,
    width: 78,
    locked: true,
    align: 'right'
  },
  {
    columnId: 'sebepKodu',
    columnType: {
      type: 'data'
    },
    groupHeader: '1',
    sortable: true,
    width: 150,
    locked: true,
    align: 'right'
  },
  {
    columnId: 'HesaplananPalet',
    columnType: {
      type: 'data'
    },
    groupHeader: '1',
    sortable: true,
    width: 90,
    locked: true,
    align: 'right',
    hidden: true
  },
  {
    columnId: 'onayAction',
    columnType: {
      type: 'action'
    },
    groupHeader: '1',
    sortable: true,
    align: 'center',
    width: 40,
    locked: true,
    menuDisabled: true
  },
  {
    columnId: 'SAPVendorApproval',
    columnType: {
      type: 'action'
    },
    groupHeader: '1',
    sortable: true,
    align: 'center',
    width: 40,
    locked: true,
    hidden: true,
    menuDisabled: true
  },
  {
    columnId: 'erpAction',
    columnType: {
      type: 'action'
    },
    groupHeader: '1',
    sortable: true,
    align: 'center',
    width: 40,
    locked: true,
    menuDisabled: true
  },
  {
    columnId: 'sparkline1',
    columnType: {
      type: 'widget'
    },
    width: 70
  },
  {
    columnId: 'sparkline2',
    columnType: {
      type: 'widget'
    },
    width: 70
  },
  {
    columnId: 'gunBasiEldekiStok',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 95,
    align: 'right'
  },
  {
    sortable: true,
    columnId: 'magazaAcikSiparis',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    width: 80,
    align: 'right'
  },
  {
    columnId: 'depoAcikSiparis',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 80,
    align: 'right'
  },
  {
    columnId: 'magazaBugunkuReplMiktari',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 140,
    align: 'right'
  },
  {
    columnId: 'gunSonuEldekiStok',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 100,
    align: 'right'
  },
  {
    columnId: 'birSonrakiSipariseKadarkiMagazaTalebi',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 160,
    align: 'right'
  },
  {
    columnId: 'minSiparisMiktari',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 150,
    align: 'right',
    hidden: true
  },
  {
    columnId: 'siparisKatsayisi',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 150,
    align: 'right',
    hidden: true
  },
  {
    columnId: 'guvenlikStogu',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 130,
    align: 'right'
  },
  {
    columnId: 'kacGunlukSiparis',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 100,
    align: 'right'
  },
  {
    columnId: 'depoTumAcikSiparisler',
    columnType: {
      type: 'data'
    },
    groupHeader: '2',
    sortable: true,
    width: 100,
    align: 'right'
  },
  {
    columnId: 'tedarikciKodu',
    columnType: {
      type: 'data'
    },
    groupHeader: '3',
    sortable: true,
    width: 90,
    align: 'left'
  },
  {
    columnId: 'tedarikciAdi',
    columnType: {
      type: 'data'
    },
    groupHeader: '3',
    sortable: true,
    width: 220,
    locked: false,
    align: 'left'
  },
  {
    columnId: 'devliveryDate',
    columnType: {
      type: 'data'
    },
    groupHeader: '3',
    sortable: true,
    width: 130,
    locked: true,
    align: 'right'
  },
  {
    columnId: 'leadTime',
    columnType: {
      type: 'data'
    },
    groupHeader: '3',
    sortable: true,
    width: 140,
    align: 'right'
  },
  {
    columnId: 'ortalamaTedarikSuresi',
    columnType: {
      type: 'data'
    },
    groupHeader: '3',
    sortable: true,
    width: 190,
    align: 'right'
  },
  {
    columnId: 'sonTedarikSuresi',
    columnType: {
      type: 'data'
    },
    groupHeader: '3',
    sortable: true,
    width: 150,
    align: 'right'
  },
  {
    columnId: 'tedarikci_onay_saati_bitis',
    columnType: {
      type: 'data'
    },
    groupHeader: '3',
    sortable: true,
    width: 150,
    align: 'right'
  },
  {
    columnId: 'magazaSayisi',
    columnType: {
      type: 'data'
    },
    groupHeader: '4',
    sortable: true,
    width: 90,
    align: 'right'
  },
  {
    columnId: 'magazaGunlukOrtSatisKoli',
    columnType: {
      type: 'data'
    },
    groupHeader: '4',
    sortable: true,
    width: 170,
    align: 'right'
  },
  {
    columnId: 'birStokGunKoli',
    columnType: {
      type: 'data'
    },
    groupHeader: '4',
    sortable: true,
    width: 170,
    align: 'right'
  },
  {
    columnId: 'malzemeAnaGrup',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 170
  },
  {
    columnId: 'malzemeAltGrup',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 170
  },
  {
    columnId: 'malzemeAile',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 170
  },
  {
    columnId: 'rafOmru',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 100,
    align: 'right'
  },
  {
    columnId: 'CiroPayi',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 90,
    align: 'right'
  },
  {
    columnId: 'koliIciMiktar',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 140,
    align: 'right'
  },
  {
    columnId: 'AdetliOneri',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 100,
    align: 'left'
  },
  {
    columnId: 'PromosyonDurumu',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 160,
    align: 'right'
  },
  {
    columnId: 'PromotionStartDate',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    hidden: true,
    width: 160,
    align: 'right'
  },
  {
    columnId: 'PromotionEndDate',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    hidden: true,
    width: 160,
    align: 'right'
  },
  {
    columnId: 'PromosyonAraligi',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 160,
    align: 'right'
  },
  {
    columnId: 'PromosyonAciklama',
    columnType: {
      type: 'data'
    },
    groupHeader: '5',
    sortable: true,
    width: 120,
    align: 'left'
  },
  {
    columnId: 'siparisGunu',
    columnType: {
      type: 'data'
    },
    groupHeader: '-',
    sortable: true,
    width: 120,
    align: 'right',
    hidden: true
  },
  {
    columnId: 'SifirStokluMagazaSayisi',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 120,
    align: 'right'
  },
  {
    columnId: 'BlokajliMagazaSayisi',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 120,
    align: 'right'
  },
  {
    columnId: 'Kayip_Ciro',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 120,
    align: 'right'
  },
  {
    columnId: 'Son7GunSatisKoli',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 90,
    align: 'right'
  },
  {
    columnId: 'Son7GunSatisKoliArindirilmis',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 90,
    align: 'right'
  },
  {
    columnId: 'Son7GunMagazaSiparis',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 120,
    align: 'right'
  },
  {
    columnId: 'Son7GunDepoCikis',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 105,
    align: 'right'
  },
  {
    columnId: 'OrtalamaMagazaBasiGorselStok',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 130,
    align: 'right'
  },
  {
    columnId: 'DepoStokGun',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 130,
    align: 'right'
  },
  {
    columnId: 'DepoBakiyeliStokGun',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 130,
    align: 'right'
  },
  {
    columnId: 'DepoSiparisliStokGun',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 130,
    align: 'right'
  },
  {
    columnId: 'DepoForecasteGoreStokGun',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 130,
    align: 'right'
  },
  {
    columnId: 'DepoSiparisliStokGunUpdated',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 130,
    align: 'right'
  },
  {
    sortable: true,
    columnId: 'MagazaStokGun',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    width: 120,
    align: 'right'
  },
  {
    columnId: 'ToplamStokGun',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 120,
    align: 'right'
  },
  {
    columnId: 'MagazaStokKoli',
    columnType: {
      type: 'data'
    },
    groupHeader: '6',
    sortable: true,
    width: 120,
    align: 'right'
  },
  {
    columnId: 'ErrorMessage',
    columnType: {
      type: 'data'
    },
    sortable: true,
    width: 140,
    align: 'left'
  },
  {
    columnId: 'Aciklamalar',
    columnType: {
      type: 'data'
    },
    sortable: true,
    width: 200,
    align: 'left'
  }
]
