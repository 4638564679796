import { createContext, useContext, ReactNode } from 'react'
import reactTableTheme from '../themes/react-table-theme.module.scss'
import senchaGridTheme from '../themes/sencha-grid-theme.module.scss'

export type ThemeProviderProps = {
  children: ReactNode
  theme: 'react-table' | 'bootstrap'
}

export const ThemeContext = createContext(undefined)
export const themes = {
  'react-table': reactTableTheme,
  'sencha-grid': senchaGridTheme
}

export default function ThemeProvider({ children, theme }: ThemeProviderProps) {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export function useTheme() {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return context
}
