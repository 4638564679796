import React from 'react'
import { connect } from 'react-redux'
import { slvyToast } from '../../../../components'
import FilterList from './filterList'
import { fetchQuickFilter, saveQuickFilterSettings } from '../../store/actions'
import { MESSAGES } from '../../messages'

class QuickFilter extends React.Component {
  componentDidMount() {
    const pluginId = this.props?.defaultActionParams?.payload?.pluginId ?? ''
    if (pluginId) {
      this._fetchQuickFilter()
      window.addEventListener('filterApplied', this.filterApplied)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('filterApplied', this.filterApplied)
  }

  filterApplied = () => {
    this.props._resetQuickFilterSettings()
    this._fetchQuickFilter()
  }

  _fetchQuickFilter() {
    const {
      defaultActionParams: { payload = {} },
      mfpSettings: { mfpId } = {},
      GetSetFilterTreeRemote: { created: FiltersMultiple = [] } = {}
    } = this.props

    const body = { FiltersMultiple, mfpId }

    const promise = this.props.fetchQuickFilter({
      payload: {
        ...payload,
        method: 'GetMfpQuickFilterList',
        requestMethod: 'post',
        body: {
          ...body
        }
      }
    })

    promise.then((response) => {
      const { Errors = [] } = response || { Errors: [MESSAGES.there_has_been_an_error] }
      if (Errors.length) {
        slvyToast.warning({
          message: Errors[0],
          title: 'GetMfpQuickFilterList',
          options: { containerId: 'dp' }
        })
      }
    })

    return promise
  }

  onSelect(Item) {
    const { getPivotBuilderStates = () => {}, onPivotStateChanged = () => {} } = this.props

    const all = getPivotBuilderStates()
    const targetState = all.find((item) => item.config.stateId === Item.LayoutId)

    if (!all.length || !targetState) {
      slvyToast.info({
        message: MESSAGES.could_not_find_a_proper_pivot_builder_state,
        options: { containerId: 'dp' }
      })
      return
    }

    this.props.saveQuickFilterSettings({
      payload: {
        selected: Item
      }
    })

    onPivotStateChanged({ stateId: targetState.config.stateId }, () => {})
  }

  render() {
    const { GetQuickFilter: { Result = [] } = {}, quickFilterSettings: { selected = {} } = {} } =
      this.props

    const hasResult = Result?.length

    if (!hasResult) {
      return null
    }

    return (
      <button className="button -quick-filter" tabIndex="-1">
        <i className="slvy-ui-icon-symbol-absolute" />
        <FilterList data={Result} selected={selected} onSelect={this.onSelect.bind(this)} />
      </button>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    mfpSettings: state.mfpSettings.mfpSettings,
    GetQuickFilter: state.getQuickFilter.GetQuickFilter,
    quickFilterSettings: state.quickFilterSettings.quickFilterSettings,
    GetSetFilterTreeRemote: state.setFilterTreeRemote.GetSetFilterTreeRemote
  }
}

const mapDispatchToProps = {
  fetchQuickFilter,
  saveQuickFilterSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickFilter)
