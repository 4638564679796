import { useState, useEffect, MouseEvent } from 'react'
import cx from 'classnames'
import { DockProps } from './Dock.types'
import styles from './index.module.scss'

const Dock = ({ children, isVisible }: DockProps) => {
  const [height, setHeight] = useState<number>(30)
  const [isResizing, setIsResizing] = useState<boolean>(false)

  const handleMouseDown = () => {
    setIsResizing(true)
  }

  const handleMouseUp = () => {
    setIsResizing(false)
  }

  const handleMouseMove = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isResizing) return

    const { innerHeight: fullHeight } = window
    const { clientY: y } = event

    if (y > 0) {
      const heightInPercentage = ((fullHeight - y) / fullHeight) * 100
      setHeight(heightInPercentage)
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isResizing])

  const dockStyle = {
    height: `${height}%`,
    top: `${100 - height}%`
  }

  return isVisible ? (
    <div
      className={cx('position-fixed bottom-0 start-0 w-100 bg-white user-select-none', styles.dock)}
      style={dockStyle}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={cx('position-absolute start-0 w-100 opacity-0', styles.resizer)}
        onMouseDown={handleMouseDown}
      />
      <div className="h-100 w-100 overflow-auto">{children}</div>
    </div>
  ) : null
}

export default Dock
