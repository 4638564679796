export default `({
    controller: {
        onLoadStore: function () {
            this.calculateTotal()
        },
        onChangeOverRecommendationConfirm: function (grid, rowIndex, colIndex, item, e, record) {
            var me = this
            Ext.Msg.show({
                title: tt('Onay'),
                width: 500,
                msg: tt('Are you sure you want to take over this record?'),
                buttonText: {
                    yes: tt('Evet'),
                    no: tt('Hayır')
                },
                buttons: Ext.Msg.YESNO,
                icon: Ext.MessageBox.QUESTION,
                fn: function (response) {
                    if (response === 'yes') {
                        me.onChangeOverRecommendation(record)
                    }
                }
            })
        },
        onChangeOverRecommendation: function (record) {
            var data = Ext.encode(record.data);
            var orderRecommendationCurrent = record.get('OrderRecommendationCurrent');
            me = this;
            var currentrecord = record
            me.getView().setLoading('Please wait while we are processing your request...');
            Ext.Ajax.request({
                url: Spui.Global.getApiUrl() + '/spui/changeOverRecommendation',
                method: 'POST',
                params: {
                    data: data,
                    newStatus: 'Sub Offers',
                },
                success: function (response, opts) {
                    me.getView().getStore().load()
                    me.getView().setLoading(false);
                    //me.up('spui-view-main-main').getController().changeView();
                },
                failure: function (response, opts) {
                    console.log('server-side failure with status code ' + response.status);
                    Ext.Msg.alert(
                        tt('Durum'),
                        tt('İşlem Gerçekleştirilirken bir hata oluştu')
                    )
                    me.getView().setLoading(false);
                }
            });
        },
        
    },
    xtype: 'grid',
    columnLines: true,
    features: [{
        ftype: 'summary',
        dock: 'top'
    }],
    plugins: [{
        ptype: 'cellediting',
        clicksToEdit: 1,
        listeners: {
            beforeedit: function (editor, context) {
                rec = context.record;
                return rec.get('IsEditable');
            },
            edit: function (editor, context, eOpts) {
                var me = this;
                var record = context.record;
                if (context.originalValue * 1 == context.value * 1) {
                    return;
                }
                grid = context.grid.ownerCt;
                grid.setLoading('Saving');
                record.save({
                    params: {
                        updateAction: 'SubValueChange'
                    },
                    failure: function (record, operation) {},
                    success: function (record, operation) {
                        grid.setLoading(false);
                        var c = grid.ownerCt.ownerCt.getController(),
                            store = grid.getStore();
                        grid.setLoading(false);
                        store.load();
                    },
                    callback: function (record, operation, success) {
                        // do something whether the save succeeded or failed
                    }
                });
            },
            scope: 'this'
        }
    }],
    columns: [{
            text: tt('OrgCode'),
            dataIndex: 'Code',
            width: 168,
            locked: true,
        },
        {
            text: tt('OrgName'),
            dataIndex: 'Name',
            width: 200,
            locked: true,
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            // locked: true,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'fa fa-stack-overflow',
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: 'onChangeOverRecommendationConfirm'
            }]
        },
        {
            text: tt('StatusDescription'),
            dataIndex: 'StatusDescription',
            locked: true,
            width: 120
        },
        {
            text: tt('Store Count'),
            columns: [{
                text: tt('NumberofStore'),
                dataIndex: 'NumberofStore',
                width: 150,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                summaryType: 'sum',
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    sumObject = summaryData;
                    if (!v || v === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000') + '</b>';
                },
            }, {
                text: tt('NumberofRecommendedStore'),
                dataIndex: 'NumberofRecommendedStore',
                width: 150,
                format: '000',
                align: 'right',
                xtype: 'numbercolumn',
                summaryType: 'sum',
                summaryRenderer: function (v, summaryData, dataIndex, context) {
                    sumObject = summaryData;
                    if (!v || v * 1 === 0) return '<b>-</b>';
                    return '<b>' + Ext.util.Format.number(v, '0,000') + '</b>';
                },
            }, ]
        },
        {
            text: tt('Inventory'),
            columns: [{
                    text: tt('OnHandInventoryDC'),
                    dataIndex: 'OnHandInventoryDC',
                    width: 150,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    summaryRenderer: function (v, summaryData, dataIndex, context) {
                        sumObject = summaryData;
                        if (!v || v * 1 === 0) return '<b>-</b>';
                        return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                    },
                },
                {
                    text: tt('OnHandInventoryStore'),
                    dataIndex: 'OnHandInventoryStore',
                    width: 150,
                    format: '000',
                    align: 'right',
                    xtype: 'numbercolumn',
                    summaryType: 'sum',
                    summaryRenderer: function (v, summaryData, dataIndex, context) {
                        sumObject = summaryData;
                        if (!v || v === 0) return '<b>-</b>';
                        return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
                    },
                }
            ]
        },
        {
            text: tt('OrderRecommendationParent'),
            dataIndex: 'OrderRecommendationParent',
            width: 170,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                sumObject = summaryData;
                if (!v || v === 0) return '<b>-</b>';
                return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
            },
            locked: true,
        },
        {
            text: tt('OrderRecommendationCurrent'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 170,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            locked: true,
            tdCls: 'blue',
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                sumObject = summaryData;
                if (!v || v === 0) return '<b>-</b>';
                return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
            },
            editor: {
                // completeOnEnter: false,
                // If the editor config contains a field property, then
                // the editor config is used to create the Ext.grid.CellEditor
                // and the field property is used to create the editing input field.
                field: {
                    xtype: 'numberfield',
                    allowBlank: false
                }
            },
        },
        {
            text: tt('OrderRecommendationChild'),
            dataIndex: 'OrderRecommendationChild',
            width: 170,
            locked: true,
            format: '000.00',
            summaryType: 'sum',
            summaryRenderer: function (v, summaryData, dataIndex, context) {
                sumObject = summaryData;
                if (!v || v === 0) return '<b>-</b>';
                return '<b>' + Ext.util.Format.number(v, '0,000.00') + '</b>';
            },
            align: 'right',
            xtype: 'numbercolumn'
        },
    ],
    listeners: {
        render: function (grid) {
            var me = this;
            var spotId = grid.ownerCt.ownerCt.ownerCt.spotId,
                store = grid.getStore(),
                proxy = store.getProxy();
            store.on('load', function (store) {
                OrderRecommendationCurrent = store.sum('OrderRecommendationCurrent');
                OrderRecommendationParent = store.sum('OrderRecommendationParent');
                viewModel = grid.ownerCt.ownerCt.ownerCt.viewModel;
                viewModel.set('OrderRecommendationCurrent', OrderRecommendationCurrent);
                viewModel.set('OrderRecommendationParent', OrderRecommendationParent);
            }, me);
            proxy.setExtraParam('spotId', spotId);
            store.load();
        }
    }
});
`
