const pluginAuthorizations = [
  'canApproveLoad',
  'canCancelTask',
  'canCreateSnapshot',
  'canRefix',
  'canReset',
  'canSavePlan',
  'canServe',
  'canSetOrigin',
  'canSetService',
  'canUpdateDemand',
  'canUpdateLocation'
]

export default pluginAuthorizations
