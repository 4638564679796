export default {
  title: 'Assortment',
  subTitle: 'Assortment Planning',
  version: '1.0',
  namespace: 'Assortment',
  description: '',
  iconClass: 'fa-tags ',
  color: 'yellow-bg',
  support: {
    maximizable: true
  },
  group: 'Application'
}
