import { getNoOfDecimalPlaces } from '../cell'

function getFormat() {
  return '0.00'
}
describe('AgGrid:Helpers:Cell:getNoOfDecimalPlaces', () => {
  let format = getFormat()

  beforeEach(() => {
    format = getFormat()
  })

  it('should return 0 if split format array is less than 2', () => {
    format = '0'
    const result = getNoOfDecimalPlaces(format)
    expect(result).toEqual(0)
  })

  it('should return number of decimal digits if split format array isequal to 2', () => {
    format = '0.00'
    const result = getNoOfDecimalPlaces(format)
    expect(result).toEqual(2)
  })

  it('should return number of first decimal digits if split format array is more than 2', () => {
    format = '0.000.00'
    const result = getNoOfDecimalPlaces(format)
    expect(result).toEqual(3)
  })
})
