Ext.define('Clustering.grid.plugin.Exporter', {
  override: 'Ext.grid.plugin.Exporter',

  saveDocumentAs: function (config, filters) {
    // ***************** Solvoyo Added Code *****************
    config = Object.assign(config, {
      userName: null,
      filters: filters
    })
    // *******************************************************
    var cmp = this.cmp

    var deferred = new Ext.Deferred()

    var exporter = this.getExporter(config)

    cmp.fireEvent('beforedocumentsave', cmp, {
      config: config,
      exporter: exporter
    })

    this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])

    return deferred.promise
  },

  getColumnHeader: function (config, column) {
    var dataIndexes = [],
      obj,
      result,
      style,
      width
    if (!column.ignoreExport) {
      style = this.getExportStyle(column.exportStyle, config)
      width = column.getWidth()
      if (style) {
        width = style.width || width
      }
      obj = { text: column.text, width: width, style: style }
      if (column.isGroupHeader) {
        result = this.getColumnHeaders(config, column.items.items)
        obj.columns = result.headers
        if (obj.columns.length === 0) {
          obj = null
        } else {
          Ext.Array.insert(dataIndexes, dataIndexes.length, result.dataIndexes)
        }
      } else {
        dataIndexes.push(column)
      }
    }
    if (obj) {
      return { header: obj, dataIndexes: dataIndexes }
    }
  }
})

Ext.define('Clustering.pivot.plugin.Exporter', {
  override: 'Ext.pivot.plugin.Exporter',

  saveDocumentAs: function (config, filters) {
    // ***************** Solvoyo Added Code *****************
    config = Object.assign(config, {
      userName: null,
      filters: filters
    })
    // *******************************************************
    var cmp = this.cmp,
      deferred = new Ext.Deferred(),
      exporter = this.getExporter(config)

    cmp.fireEvent('beforedocumentsave', cmp, {
      config: config,
      exporter: exporter
    })
    this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])
    return deferred.promise
  }
})
