import _ from 'lodash'
import { getCustomVariables, getPluginVariables } from '@/helpers/mapper'
import { emptyObject } from '../../constants'
import { defaultOption } from './constants'
import sortByKey from '../../../../utils/sortByKey'

const sortKeyInObject = (obj, sortBy) => {
  Object.keys(obj).forEach((key) => {
    obj[key] = {
      ...obj[key],
      [sortBy]: sortByKey(obj[key]?.[sortBy])
    }
  })
  return obj
}
const getActiveEventsCount = (sortedEvents, eventsAssignees) => {
  const count = {
    active: 0,
    total: _.size(sortedEvents)
  }
  _.each(sortedEvents, (_unused, eventKey) => {
    const { [eventKey]: eventAssignees = emptyObject } = eventsAssignees
    count.active += Object.keys(eventAssignees).length ? 1 : 0
  })
  return count
}

const getActiveMethodsCount = (sortedMethods, methodsAssignees) => {
  const count = {
    active: 0,
    total: _.size(sortedMethods)
  }
  _.each(sortedMethods, (_unused, methodKey) => {
    const { [methodKey]: methodAssignees = emptyObject } = methodsAssignees
    count.active += Object.keys(methodAssignees).length ? 1 : 0
  })
  return count
}

const findMenuVariables = (menuTree = [], menuId) => {
  return _.reduce(
    menuTree,
    (result, { item: { id }, item, children }) => {
      if (result) return result
      if (_.isEqual(id, menuId)) {
        return _.transform(
          item.transferObject,
          (r, t, k) => {
            r[k] = { type: t, group: 'menu', count: 0 }
          },
          {}
        )
      }
      return findMenuVariables(children, menuId)
    },
    false
  )
}

const findCatalogVariables = (transferObject) => {
  return _.transform(
    transferObject,
    (result, { type }, key) => {
      result[key] = { type, group: 'global', count: 0 }
    },
    {}
  )
}

const getMenuRegisters = (menuTree = [], prevResult = {}) => {
  return _.reduce(
    menuTree,
    (result, { item, children }) => {
      if (!_.isEqual(item.transferObject, {})) {
        result[item.id] = {
          title: item.name,
          params: _.transform(
            item.transferObject,
            (r, t, k) => {
              r[k] = { type: t }
            },
            {}
          )
        }
      }
      return getMenuRegisters(children, result)
    },
    prevResult
  )
}

const getSearchableText = (item) => {
  // container methods: item.settings.name
  // plugin methods: item.config.general.name
  let name = item.config?.general?.name ?? item.settings?.name ?? ''

  let value = ''

  let type = ''

  type = `(${item.type})`
  name = name ? `${name} ${type}` : type
  value = name

  return { type, name, value }
}

const convertDataForSearch = (data) => {
  const mappedData = data.map((item) => {
    const { name, value } = getSearchableText(item)
    return {
      name,
      value,
      title: item.type,
      type: item.type
    }
  })

  return mappedData.reduce((result, { name, type, value }) => {
    let index = result.findIndex((item) => item.title === type)

    // Create new group
    if (index === -1) {
      result.push({
        title: type,
        suggestions: [
          {
            type,
            name: type,
            value: type,
            prefix: '(ALL)'
          }
        ]
      })
    }

    // find new index
    index = result.findIndex((item) => item.title === type)

    // Append to group
    result[index].suggestions.push({
      type,
      name,
      value
    })
    return result
  }, [])
}

const getFilteredPluginsByQuery = (plugins, query) => {
  let queryExceptAll = ''
  if (!query) {
    return plugins
  }

  return plugins
    .filter((item) => {
      let { value } = getSearchableText(item)

      const newQuery = query.toLowerCase()
      value = value.toLowerCase()

      if (newQuery.includes('(all)')) {
        queryExceptAll = newQuery.replace(' (all)', '')
        return value.includes(queryExceptAll)
      }
      return value.includes(newQuery)
    })
    .map((item) => {
      const { value } = getSearchableText(item)
      return { ...item, searchText: value }
    })
}

const filterPluginEventsBySelectedVariables = (plugins, events, selectedVariables) => {
  const filteredPlugins = []

  _.each(plugins, (plugin) => {
    const { id: pluginKey, registers: { events: pluginEvents } = {} } = plugin
    let foundByVariable = false

    _.each(pluginEvents, ({ params }, eventKey) => {
      _.each(params, (unused, param) => {
        const { [pluginKey]: { [eventKey]: { [param]: val } = {} } = {} } = events
        if (val && !_.isNil(val)) {
          if (selectedVariables.indexOf(val) !== -1) {
            foundByVariable = true
          }
        }
      })
    })
    if (foundByVariable) {
      filteredPlugins.push(plugin)
    }
  })

  return filteredPlugins
}

const getFilteredVariables = (variables, searchOptions) => {
  const data = transformVariableGroups(variables)
  const { query, isSelected, notFound } = searchOptions
  const isTextSearch = query && isSelected && !notFound

  if (isTextSearch) {
    const filteredData = {}

    Object.keys(data).forEach((key) => {
      const item = data[key]
      filteredData[key] = {}

      Object.keys(item).forEach((itemKey) => {
        const newQuery = query.toLowerCase()
        const itemName = `${itemKey} (${key})`.toLowerCase()

        let isIncluded = itemName.includes(newQuery)

        if (newQuery.includes('(all)')) {
          const queryExceptAll = newQuery.replace(' (all)', '')
          isIncluded = itemName.includes(queryExceptAll)
        }

        if (isIncluded) {
          filteredData[key][itemKey] = item[itemKey]
          filteredData[key][itemKey].searchText = itemName
        }
      })
    })

    // sort by given text
    return sortData(filteredData, 'text')
  }

  return sortData(data, 'asc')
}

const getFilteredWaitEvents = (waitEvents, searchOptions) => {
  const { query, isSelected, notFound } = searchOptions
  const isTextSearch = query && isSelected && !notFound

  let result = waitEvents
  if (isTextSearch) {
    result = getFilteredPluginsByQuery(waitEvents, query)
  }

  if (isTextSearch && result.length) {
    // sort by given text
    result = _.sortBy(result, (item) => item?.searchText)
  } else {
    result = _.orderBy(result, ({ type = '', config: { general: { name = '' } = {} } = {} }) => [
      type,
      name
    ])
  }

  return result
}

const sortData = (filteredData, sortBy) => {
  const data = filteredData
  Object.keys(data).forEach((key) => {
    const current = data[key]
    if (sortBy === 'text') {
      data[key] = Object.fromEntries(
        _.sortBy(Object.entries(current), ([, val]) => val?.searchText)
      )
    } else if (sortBy === 'asc') {
      data[key] = Object.fromEntries(
        Object.entries(current).sort((prev, next) => prev[0] - next[0])
      )
    }
  })
  return data
}

const getFilteredEventPlugins = (
  events = {},
  plugins,
  searchOptions,
  selectedVariables,
  toggleActives
) => {
  return getFilteredPluginsOrContainers({
    pluginsOrContainers: plugins,
    searchOptions,
    selectedVariables,
    events,
    filterBy: 'events',
    toggleActives
  })
}

const filterPluginMethodsBySelectedVariables = (plugins, methods, selectedVariables) => {
  const filteredPlugins = []
  _.each(plugins, (plugin) => {
    const { id: pluginKey, registers: { methods: pluginMethods } = {} } = plugin
    let foundByVariable = false

    _.each(pluginMethods, ({ params }, methodKey) => {
      _.each(params, (unused, param) => {
        const { [pluginKey]: { [methodKey]: { [param]: val } = {} } = {} } = methods
        if (val && !_.isNil(val)) {
          if (selectedVariables.indexOf(val) !== -1) {
            foundByVariable = true
          }
        }
      })
    })
    if (foundByVariable) {
      filteredPlugins.push(plugin)
    }
  })
  return filteredPlugins
}

const getFilteredMethods = (
  methods = {},
  pluginsOrContainers,
  searchOptions,
  selectedVariables,
  toggleActives
) => {
  return getFilteredPluginsOrContainers({
    pluginsOrContainers,
    searchOptions,
    selectedVariables,
    methods,
    filterBy: 'methods',
    toggleActives
  })
}

const filterByActiveEvents = (pluginsOrContainers, assignees) => {
  return pluginsOrContainers.filter((pluginOrContainer) => {
    const { id, registers: { events = emptyObject } = emptyObject } = pluginOrContainer
    const { [id]: eventsAssignees = emptyObject } = assignees
    const { active } = getActiveEventsCount(events, eventsAssignees)
    return active
  })
}

const filterByActiveMethods = (pluginsOrContainers, assignees) => {
  return pluginsOrContainers.filter((pluginOrContainer) => {
    const { id, registers: { methods = emptyObject } = emptyObject } = pluginOrContainer
    const { [id]: methodsAssignees = emptyObject } = assignees
    const { active } = getActiveMethodsCount(methods, methodsAssignees)
    return active
  })
}

const getFilteredPluginsOrContainers = ({
  pluginsOrContainers,
  searchOptions,
  selectedVariables,
  events,
  methods,
  filterBy,
  toggleActives
}) => {
  const { query, isSelected, notFound } = searchOptions
  const isTextSearch = query && isSelected && !notFound

  let textSearchedPlugins = []
  if (isTextSearch) {
    textSearchedPlugins = getFilteredPluginsByQuery(pluginsOrContainers, query)
  }

  let variableSelectedPlugins = []
  if (selectedVariables.length) {
    variableSelectedPlugins =
      filterBy === 'events'
        ? filterPluginEventsBySelectedVariables(pluginsOrContainers, events, selectedVariables)
        : filterPluginMethodsBySelectedVariables(pluginsOrContainers, methods, selectedVariables)
  }

  if (toggleActives) {
    pluginsOrContainers =
      filterBy === 'events'
        ? filterByActiveEvents(pluginsOrContainers, events)
        : filterByActiveMethods(pluginsOrContainers, methods)
  }

  let result = []
  if (!isTextSearch && !selectedVariables.length) {
    result = pluginsOrContainers
  } else if (isTextSearch && selectedVariables.length) {
    result = _.intersectionBy(variableSelectedPlugins, textSearchedPlugins, 'id')
  } else if (isTextSearch) {
    result = textSearchedPlugins
  } else if (selectedVariables.length) {
    result = variableSelectedPlugins
  }

  if (isTextSearch && result.length) {
    // sort by given text
    result = _.sortBy(result, (item) => item?.searchText)
  } else {
    result = _.orderBy(result, ({ type = '', config: { general: { name = '' } = {} } = {} }) => [
      type,
      name
    ])
  }

  return result
}

const getMappedVariables = ({ assignees, plugins, catalog, menuId, menuTree, works }) => {
  const { transferObject = {} } = catalog

  const variables = {
    ...getPluginVariables(plugins, assignees),
    ...findMenuVariables(menuTree, menuId),
    ...findCatalogVariables(transferObject),
    ...getCustomVariables(works)
  }

  _.each(assignees.methods, (plugin) => {
    _.each(plugin, (params) => {
      _.each(params, (param) => {
        const index = _.find(variables, (o, k) => k === param)
        if (index) {
          variables[param].count += 1
        }
      })
    })
  })

  _.each(assignees.menus, (plugin) => {
    _.each(plugin, (params) => {
      _.each(params, (param) => {
        const index = _.find(variables, (o, k) => k === param)
        if (index) {
          variables[param].count += 1
        }
      })
    })
  })

  _.each(works, ({ transitions }) => {
    _.each(transitions, ({ name, params }) => {
      if (!_.isEmpty(params)) {
        if (name === 'condition') {
          _.each(params.query.rules, ({ field }) => {
            const index = _.find(variables, (o, k) => k === field)
            if (index) {
              variables[field].count += 1
            }
          })
        } else if (name === 'updateVariables') {
          _.each(params.variableValues, (key, value) => {
            const index = _.find(variables, (o, k) => k === value)
            if (index) {
              variables[value].count += 1
            }
          })
        } else if (name === 'generateKey') {
          const index = _.find(variables, (o, k) => k === params.variable)
          if (index) {
            variables[params.variable].count += 1
          }
        }
      }
    })
  })

  return variables
}

const transformVariableGroups = (variables) =>
  _.transform(
    variables,
    (r, v, k) => {
      // eslint-disable-next-line no-param-reassign
      r[v.group] = r[v.group] || {}
      // eslint-disable-next-line no-param-reassign
      r[v.group][k] = v
    },
    {}
  )

const getSelectedValue = (value, options) => {
  let result = value === null || value === '' ? defaultOption : { label: value, value }
  result = options.find((item) => item.value === result.value) || defaultOption
  return result
}

export {
  sortKeyInObject,
  getFilteredWaitEvents,
  getActiveEventsCount,
  getActiveMethodsCount,
  getSelectedValue,
  findMenuVariables,
  findCatalogVariables,
  getMenuRegisters,
  convertDataForSearch,
  getFilteredEventPlugins,
  getFilteredVariables,
  getFilteredMethods,
  getMappedVariables,
  transformVariableGroups
}
