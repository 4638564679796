import { Component } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import { View } from '..'
import withConnector from '../../BaseContainer'
import { Permission } from '../../components'
import 'react-reflex/styles.css'
import './style.scss'

class FlexboxContainer extends Component {
  constructor(props) {
    super(props)
    this.afterAddPlugin = this.afterAddPlugin.bind(this)
    this.handleAddPlugin = this.handleAddPlugin.bind(this)
    this.handleRemoveView = this.handleRemoveView.bind(this)
    this.handlePluginMounted = this.handlePluginMounted.bind(this)
    this.saveLayout = this.saveLayout.bind(this)
    this.findPlugin = this.findPlugin.bind(this)
  }

  saveLayout(layout) {
    const { data: { settings } = {} } = this.props
    this.props.onSave({
      ...settings,
      elements: layout
    })
  }

  afterAddPlugin(index) {
    return (id, type) => {
      const {
        data: { settings: { elements = [] } = {} }
      } = this.props
      const newLayout = _.clone(elements)
      newLayout[index] = id
      if (!containerValidation(this.props.containers, id)) {
        return
      }
      this.saveLayout(newLayout)
    }
  }

  count = 0

  handlePluginMounted() {
    this.count++
    const {
      data: { settings: { elements = {} } = {} }
    } = this.props
    if (_.size(elements) === this.count && this.props.onMounted) {
      this.props.onMounted()
    }
  }

  handleRemoveView(id) {
    const {
      data: { settings: { elements = [] } = {} }
    } = this.props
    const newLayout = _.filter(elements, (l) => l !== id)
    this.saveLayout(newLayout)
  }

  handleAddPlugin(index) {
    return () => {
      this.props.showAddPluginLayout(this.afterAddPlugin(index))
    }
  }

  findPlugin(elementId) {
    const {
      plugins,
      containers,
      data: { id: containerId },
      params
    } = this.props
    let type
    const plugin = _.find(plugins, (p) => p.id === elementId)
    if (plugin) {
      type = 'plugin'
    } else {
      const container = _.find(containers, (p) => p.id === elementId)
      if (container) type = 'container'
    }

    if (type) {
      return (
        <View
          key={elementId}
          containers={containers}
          draggableHandle={`move${containerId}`}
          id={elementId}
          params={params}
          plugins={plugins}
          type={type}
          onMounted={this.handlePluginMounted}
          onRemovePlugin={this.handleRemoveView}
        />
      )
    }
  }

  render() {
    const {
      data: { settings: { size = 0, elements = [] } = {} } = {},
      params: { designMode }
    } = this.props
    const sizeInt = parseInt(size, 10)
    const r = []
    _.times(
      sizeInt,
      (index) => {
        r.push(
          <ReflexElement key={index} className="reflex-element">
            {designMode && elements[index] ? (
              this.findPlugin(elements[index])
            ) : (
              <Permission has={['Plugin.Add']}>
                <div className="-text-center">
                  <Button size="sm" variant="success" onClick={this.handleAddPlugin(index)}>
                    Add Element
                  </Button>
                </div>
              </Permission>
            )}
          </ReflexElement>
        )
        if (sizeInt !== index + 1) {
          r.push(<ReflexSplitter key={`a${index}`} />)
        }
      },
      []
    )

    return (
      <div className="flex-container">
        <ReflexContainer orientation="horizontal">{r}</ReflexContainer>
      </div>
    )
  }
}

export default withConnector(FlexboxContainer)
