export default () => {
  return {
    title: 'Spot',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          menuId: {
            type: 'string',
            title: 'Menu Id',
            default: '5b0b2d85f4141'
          },
          spotDb: {
            type: 'string',
            title: 'Profile DB',
            default: ''
          },
          rteWSDL: {
            type: 'string',
            title: 'RTE WSDL',
            default: 'net.tcp://192.168.10.30:21211/ClusteringService'
          },
          spotUserClaimName: {
            type: 'string',
            title: 'Spot User Claim Name',
            default: 'a101spotuserid'
          }
        }
      }
    }
  }
}
