import { STORE_PIVOT_DETAIL_VIEW_SETTINGS } from '../actions/actionTypes'

const initialState = {
  pivotDetailViewSettings: {
    isVisible: false,
    selectedLeftKeys: [],
    selectedBreadcrumbs: [],
    mergedLeftFieldsAndKeys: []
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PIVOT_DETAIL_VIEW_SETTINGS:
      return {
        pivotDetailViewSettings: {
          ...state.pivotDetailViewSettings,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default reducer
