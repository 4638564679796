import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { slvyToast } from '../../../../components'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { updateTreeStatus } from '../../store/slices/tree'
import { selectScenario } from '../../store/slices/treeSelection'
import { useUpdateLibraryTreeNodeMutation } from '../../store'

const selectedStatus = 'APPROVED'

const selectFromState = (state) => ({
  rootOptions: state.options.rootOptions,
  selectedScenarioTree: state.treeSelection.tree
})

function ApproveButton(props) {
  const { canApprove = true, pluginId, assortmentApprovedEventRef } = props

  const [updateLibraryTreeNode, updateLibraryTreeResult] = useUpdateLibraryTreeNodeMutation()

  const { selectedScenarioTree, rootOptions: { AssortmentId, ClusterNodeId } = {} } =
    useSelector(selectFromState)
  const dispatch = useDispatch()

  useEffect(() => {
    const { status, data: responseData } = updateLibraryTreeResult
    if (status === 'pending') {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Approve Tree Node' }))
    } else if (status === 'fulfilled' && responseData) {
      const { currentScenario, currentScenario: { Id: currentId = null } = {} } =
        selectedScenarioTree
      if (responseData.Description) {
        slvyToast.success({ message: responseData.Description, title: 'Update Tree Node' })
        onUpdateTreeStatus(selectedStatus, currentId, currentScenario)
      }
      dispatch(setLoaderReducer({ isShown: false, messages: 'Approve Tree Node' }))
      assortmentApprovedEventRef.current?.assortmentApproved({ AssortmentId, ClusterNodeId })
    }
  }, [updateLibraryTreeResult])

  useEffect(() => {}, [selectedScenarioTree])

  const approveProducts = (canApprove) => {
    const { currentScenario } = selectedScenarioTree

    if (!canApprove) {
      return
    }

    const params = {
      ...currentScenario,
      Status: selectedStatus
    }

    let _params = _.omit(params, 'Children')
    _params = _.omit(_params, 'Filters')

    const payload = {
      pluginId,
      method: 'LibraryTreeUpdateNode',
      requestMethod: 'post',
      body: {
        ..._params
      }
    }

    updateLibraryTreeNode({ ...payload })
  }

  const onUpdateTreeStatus = (selectedStatus, currentId, currentScenario) => {
    dispatch(
      updateTreeStatus({
        selectedStatus,
        currentId
      })
    )

    dispatch(
      selectScenario({
        dataKey: 'tree',
        currentScenario: {
          ...currentScenario,
          Status: selectedStatus
        }
      })
    )
  }

  return (
    <div
      className={`option-btn ${canApprove ? '' : '-disabled'}`}
      onClick={() => approveProducts(canApprove)}
    >
      <i className="slvy-ui-icon-check-circle-regular" />
      <span>Approve</span>
    </div>
  )
}

export default ApproveButton
