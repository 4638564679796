import { updateObject } from '../utility'
import { SET_LOADER } from '../actions/actionTypes'

const initialState = {
  loader: {
    isVisible: true,
    isAnimationStopped: false,
    messages: 'Loading!'
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER:
      const { isVisible = true, isAnimationStopped = false, messages = '' } = action.payload
      return updateObject(state, {
        loader: {
          isVisible,
          isAnimationStopped,
          messages: messages || initialState.loader.messages
        }
      })
    default:
      return state
  }
}

export default reducer
