Ext.define('Spui.util.I18', {
  singleton: true,
  translate: function (val, data) {
    var store = Ext.getStore('I18n'),
      str,
      rec
    var locale = Ext.locale

    if (store && val && locale) {
      rec = store.findRecord('default', val, 0, false, true, true)
      if (!rec) {
        console.group(val + ' Lang record not found')
      }
      str = rec ? rec.get(locale) : null
      if (!rec) {
        rec = new Spui.data.model.I18({
          default: val,
          appId: Spui.Global.getMenuId()
        })
        console.info(val + ' Lang record  created')
        rec.save({
          success: function () {
            console.info(val + ' Lang record saved')
            console.groupEnd()
          }
        })
        store.add(rec)
      }
    }

    str = str || val
    if (data) {
      return Spui.util.I18.convertData(str, data)
    }
    return str
  },
  convertData: function tpl(str, data) {
    return Object.keys(data).length ? '' : str
  }
})

window.tt = Spui.util.I18.translate
window.t = Spui.util.I18.translate
