import { STORE_SET_FILTER_REMOTE } from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  GetSetFilterRemote: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SET_FILTER_REMOTE:
      return updateObject(state, {
        GetSetFilterRemote: {
          ...state.GetSetFilterRemote,
          ...action.payload
        }
      })
    default:
      return state
  }
}

export default reducer
