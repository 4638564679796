import { memo } from 'react'
import { replaceTemplate, getCellTooltip } from '../../helpers/index'
import { CustomTooltipProps } from './CustomTooltip.types'
import CustomTooltipView from './CustomTooltipView'

const CustomTooltip = (props: CustomTooltipProps) => {
  const {
    data,
    cellTooltip,
    cellDisplayRules,
    colDef = {},
    location,
    formatParams: { getFormattedValue, formattedFields }
  } = props
  let tooltip = ''

  if (location === 'header') {
    tooltip = colDef?.headerTooltip || ''
  } else {
    // cell rule
    const tooltipFromCellRule = getCellTooltip({ params: props, cellDisplayRules })

    if (tooltipFromCellRule || cellTooltip) {
      tooltip = replaceTemplate({
        text: tooltipFromCellRule || cellTooltip,
        data,
        getFormattedValue,
        formattedFields
      })
    }
  }

  if (!tooltip?.trim?.()?.length) {
    // return null does not hide tooltip, ag-grid expects html element
    return <div className="d-none" />
  }

  return <CustomTooltipView tooltip={tooltip} />
}

export default memo(CustomTooltip)
