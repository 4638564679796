import { DataDetailsProps } from './DataDetails.types'

const DataDetails = (props: DataDetailsProps) => {
  const {
    dataDetails: { enabled, isVisibleTotalAndFilteredRowCount, unsavedChangesText },
    filteredRowCount,
    isThereUnsavedChanges,
    totalRowCount,
    localeText
  } = props

  const text = (
    <>
      {localeText.totalAndFilteredRows}:{' '}
      <strong>
        {filteredRowCount} / {totalRowCount}
      </strong>
    </>
  )

  return (
    <>
      <div className="d-grid align-items-center fs-xs">
        {enabled && isVisibleTotalAndFilteredRowCount ? <span>{text}</span> : null}
      </div>
      <>
        {isThereUnsavedChanges && unsavedChangesText.length ? (
          <div className="d-grid align-items-center fs-xs">
            <span className="text-danger fw-bold">{unsavedChangesText}</span>
          </div>
        ) : null}
      </>
    </>
  )
}

export default DataDetails
