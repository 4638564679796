import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import numeral from 'numeral'
import { findCurrency } from '../../utils'
import { saveCurrencyRatesSettings } from '../../store/actions'
import { IProps, ICurrentCurrency } from './CurrencyRates.types'

const defaultCurrency: ICurrentCurrency = {
  CurrencySymbol: '?',
  CurrencyUnicode: '\\U20BA',
  Name: 'TRY'
}

const CurrencyRates = ({
  isVisible,
  CurrencyRates,
  currentCurrency = defaultCurrency,
  onReloadViewClick
}: IProps) => {
  const dispatch = useDispatch()

  const shouldDisplay = isVisible && Boolean(CurrencyRates.length)
  if (!shouldDisplay) {
    return null
  }

  const foundCurrency: ICurrentCurrency = findCurrency(currentCurrency.Name, CurrencyRates)
  const currentCurrencyProps: ICurrentCurrency =
    typeof foundCurrency !== 'undefined' ? foundCurrency : CurrencyRates[0]

  async function onChangeCurrencyRates(event: ChangeEvent<HTMLSelectElement>) {
    const currentCurrencyData: ICurrentCurrency = findCurrency(event.target.value, CurrencyRates)
    const { CurrencySymbol } = currentCurrencyData
    const isNoSymbol = CurrencySymbol === '?' || CurrencySymbol === null
    numeral.localeData().currency.symbol = isNoSymbol ? '₺' : CurrencySymbol

    await dispatch(
      saveCurrencyRatesSettings({
        payload: {
          currencyRatesSettings: {
            ...currentCurrencyData
          }
        }
      })
    )

    onReloadViewClick()
  }

  return (
    <select
      className="currency-option"
      defaultValue={currentCurrencyProps.Name}
      onChange={onChangeCurrencyRates}
    >
      {CurrencyRates.map(({ Name }, currencyRateIndex) => {
        return <option key={currencyRateIndex}>{Name}</option>
      })}
    </select>
  )
}

export default CurrencyRates
