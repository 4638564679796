Ext.define('Spui.view.wizard.WizardMainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.spui-view-wizard-wizardmainviewmodel',
  stores: {},
  formulas: {
    isPrevDisabled: {
      bind: {
        pageIndex: '{pageIndex}'
      },
      get: function (data) {
        var pageIndex = data.pageIndex
        if (pageIndex == 1) return true
        return false
      }
    },
    isNextDisabled: {
      bind: {
        pageIndex: '{pageIndex}'
      },
      get: function (data) {
        var pageIndex = data.pageIndex
        if (pageIndex == 4) return true
        return false
      }
    },
    bcDatafn: {
      bind: {
        pageIndex: '{pageIndex}'
      },

      get: function (data) {
        let ret = []
        var pageIndex = data.pageIndex - 1,
          d = [
            {
              Name: tt('Ürün Seç'),
              Status: '',
              PageIndex: 1
            },
            {
              Name: tt('Benzer Ürün'),
              Status: '',
              PageIndex: 2
            },
            {
              Name: tt('Dağıtım'),
              Status: '',
              PageIndex: 3
            },
            {
              Name: tt('Yayınla'),
              Status: '',
              PageIndex: 4
            }
          ]

        for (let index = 0; index < d.length; index++) {
          var item = d[index]
          if (index < pageIndex) {
            item['Status'] = 'done'
          } else if (index == pageIndex) {
            item['Status'] = 'active'
          } else {
            item['Status'] = ''
          }
          ret.push(item)
        }
        return ret
      }
    }
  },
  data: {
    pageIndex: 1,
    spotRecord: null,
    materialRecord: null
  }
})
