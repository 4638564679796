import {
  SELECT_LEGEND_PRODUCT_ISSUE,
  SELECT_LEGEND_RESOURCE_ISSUE,
  PRODUCT_SELECT_LEGEND_PRODUCT_ISSUE,
  PRODUCT_SELECT_LEGEND_RESOURCE_ISSUE
} from '../actions/actionTypes'
import { updateObject } from './utility'

const initialState = {
  productLegendState: {
    currentIssueIndex: 0,
    currentProductInfoIndex: [],
    isProductInfo: false
  },
  productLegendData: {
    issueList: [],
    resourceInfoList: []
  },
  resourceLegendState: {
    currentIssueIndex: 0,
    currentProductInfoIndex: [],
    isProductInfo: false
  },
  resourceLegendData: {
    issueList: [],
    resourceInfoList: []
  },
  maxSelectedIssues: 1,
  selectedResourceIssueCount: 1,
  selectedProductIssueCount: 1
}

const legend = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_LEGEND_PRODUCT_ISSUE:
      return updateObject(state, {
        ...state,
        productLegendState: action.legendState,
        productLegendData: { ...action.legendData },
        maxSelectedIssues: action.maxSelectedIssues,
        selectedProductIssueCount: action.selectedProductIssueCount
      })
    case SELECT_LEGEND_RESOURCE_ISSUE:
      return updateObject(state, {
        ...state,
        resourceLegendState: action.legendState,
        resourceLegendData: { ...action.legendData },
        maxSelectedIssues: action.maxSelectedIssues,
        selectedResourceIssueCount: action.selectedResourceIssueCount
      })
    default:
      return state
  }
}

const PRODUCTlegend = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_SELECT_LEGEND_PRODUCT_ISSUE:
      return updateObject(state, {
        ...state,
        productLegendState: action.legendState,
        productLegendData: { ...action.legendData },
        maxSelectedIssues: action.maxSelectedIssues,
        selectedProductIssueCount: action.selectedProductIssueCount
      })
    case PRODUCT_SELECT_LEGEND_RESOURCE_ISSUE:
      return updateObject(state, {
        ...state,
        resourceLegendState: action.legendState,
        resourceLegendData: { ...action.legendData },
        maxSelectedIssues: action.maxSelectedIssues,
        selectedResourceIssueCount: action.selectedResourceIssueCount
      })
    default:
      return state
  }
}

export { legend, PRODUCTlegend }
