Ext.define('MDM.src.view.Grouping.panel.GroupGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'grouping-groupgrid',
  config: {
    isReadOnly: false
  },
  columnLines: true,
  reference: 'liste',
  frame: false,
  iconCls: 'fa fa-sitemap',
  lockedGridConfig: {
    width: 340,
    resizable: true
  },
  features: [
    {
      ftype: 'grouping',
      startCollapsed: true,
      groupHeaderTpl: '{name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
    }
  ],
  split: false,
  listeners: {
    select: 'onCusHieSelect'
  },
  emptyText: '<h3> No Record Found <h3>',
  buttonAlign: 'center',
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      text: 'Name',
      dataIndex: 'Descriptions',
      flex: 1,
      sortable: true,
      locked: true
    },
    {
      text: 'Private',
      tooltip: 'is private',
      width: 50,
      align: 'center',
      sortable: true,
      dataIndex: 'isPrivate',
      renderer: function (value, metaData, record) {
        if (record.get('isPrivate'))
          return '<div tabindex="-1" style="cursor:auto !important;" role="button" class="x-action-col-icon x-action-col-2 fa fa-check-square-o" data-qtip="Private" data-tabindex-value="0" data-tabindex-counter="1"></div>'
        return ''
      }
    },
    {
      menuDisabled: true,
      sortable: false,
      xtype: 'actioncolumn',
      width: 80,
      align: 'center',
      locked: true,
      items: [
        {
          iconCls: 'fa fa-bars',
          tooltip: 'Select',
          margin: '0 5 0 0',
          handler: 'onShowTree',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            if (grid.ownerGrid.getIsReadOnly()) return true
            return !record.get('isEditable')
          }
        },
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete Record',
          margin: '0 5 0 0',
          handler: 'onDeleteCusHie',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            if (grid.ownerGrid.getIsReadOnly()) return true
            return !record.get('isEditable')
          }
        },
        {
          iconCls: 'fa fa-pencil',
          tooltip: 'View/Edit',
          margin: '0 5 0 0',
          handler: 'onEdit',
          isActionDisabled: function (grid) {
            return Boolean(grid.ownerGrid.getIsReadOnly())
          },
          getClass: function (value, metadata, record) {
            if (record.get('isEditable')) {
              return 'fa fa-pencil-square-o'
            } else return 'fa fa-eye'
          }
        }
      ]
    },
    {
      text: 'Region',
      tooltip: 'Name of Region',
      dataIndex: 'Region',
      hidden: false,
      width: 120,
      align: 'left',
      sortable: true
    },
    {
      text: 'Created By',
      tooltip: 'Created By',
      dataIndex: 'CreatedBy',
      width: 150,
      align: 'left',
      sortable: true
    },
    {
      text: 'Created At',
      tooltip: 'Created At',
      dataIndex: 'CreateDate',
      xtype: 'datecolumn',
      format: 'Y-m-d\\TH:i:s',
      width: 150,
      align: 'right',
      sortable: true
    },
    {
      text: 'Modified By',
      tooltip: 'Last Modified By',
      dataIndex: 'LastModifiedBy',
      width: 150
    },
    {
      text: 'Modified At',
      tooltip: 'Last Modified At',
      dataIndex: 'LastModifiedDate',
      xtype: 'datecolumn',
      format: 'Y-m-d\\TH:i:s',
      width: 150
    },
    {
      text: 'DPI Familiy Cnt',
      tooltip: 'Number of DPIFamily',
      dataIndex: 'NumberOfDPIFamily',
      hidden: false,
      width: 130,
      align: 'right',
      sortable: true
    }
  ]
})
