export default `({
    headerPosition: 'top',
    //titlePosition: 500,
    //tabPosition: 'left',
    split: true,
    forceToLoadStore: false,
    border: true,
    collapsible: true,
    collapsed: true,
    //height: '100px',
    //weight: '200px',
    //margin: 0,
    bodyStyle: {
        background: '#fff'
    },
    // tabBar: {
    //     layout: {
    //         pack: 'end'
    //     },
    //     items: [{
    //         xtype: 'tbfill'
    //     }, {
    //         xtype: 'button',
    //         text: 'Tum Bolgeler',
    //         style: {
    //             background: '#2574AB'
    //         }
    //     }]
    //},
    applySelectedChartPanel: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Selected Spot Sales Chart'),
                xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
                salesChartCfg: 'SpotSelected',
                salesChartStoreCfg: 'SpotSelectedStore',
                salesChartStore: {},
                salesChart: {},
                salesGridCfg: 'SelectedSpotSalesGrid',
                salesGrid: false,
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        //b.getStore().load();
        //store.fireEvent('load',store);
        b.on('activate', function (b) {
            b.forcetoLoad();
        })
        return b
    },
    updateSelectedChartPanel: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(0, f)
                } else {
                    d.splice(1, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    applySalesChartPanel: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Spot Sales chart'),
                xtype: 'spui-view-common-similarity-tabpanel-saleschart-main',
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        b.on('activate', function (b) {
            b.forcetoLoad();
        })
        //b.getStore().load();
        //store.fireEvent('load',store);
        return b
    },
    updateSalesChartPanel: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(0, f)
                } else {
                    d.splice(2, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    applyNomalizedChart: function (b) {
        if (b && !b.isComponent) {
            Ext.applyIf(b, {
                title: tt('Normalized Chart'),
                xtype: 'spui-view-common-similarity-tabpanel-nomalizedchart-main',
                bind: {
                    SpotId: '{spotRecord.SpotId}'
                }
            });
            b = Ext.create(b)
        }
        //b.getStore().load();
        //store.fireEvent('load',store);
        return b
    },
    updateNomalizedChart: function (f, g) {
        if (g) {
            g.destroy()
        }
        if (f) {
            var d = this.items;
            if (d) {
                if (d.isMixedCollection) {
                    this.insert(1, f)
                } else {
                    d.splice(0, 0, f)
                }
            } else {
                this.items = [f]
            }
        }
    },
    listeners: {
        // activate: function(p) {
        // 	//if (p.forceToLoadStore)
        //      p.getSimilarGridStore().load();
        //      p.forceToLoadStore=false;
        // }
    },
    listeners: {
        expand: function (c) {
            activeTab = c.getActiveTab()
            if (activeTab) {
                activeTab.onChangeSelectedSimilarSpot()
            }
        },
        afterRender: function (c) {
            c.getTabBar().insert(0, {
                xtype: 'component'
            });
        }
    },
    applySimilarSpotId: function (c) {
        this.getSalesChartPanel().setSimilarSpotId(c)
        this.getSelectedChartPanel().setSimilarSpotId(c)
    },
})
`
