export default `({
    columnLines: true,
    plugins: [
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    columns: [{
            xtype: 'rownumberer'
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-pencil',
                filterField: {
                    xtype: 'textfield'
                },
                handler: function ( grid, rowIndex, colIndex,item,e,record) {
                    Ext.create('Spui.view.statusdialogs.dc.Main',{
                    title:record.get('MaterialDescription')+' Onay Ekrani '
                    }
                    
                    
                    
                    ).show();
                }
            }]
        },
        // {
        //     menuDisabled: true,
        //     sortable: false,
        //     xtype: 'actioncolumn',
        //     resizable: false,
        //     locked: true,
        //     width: 30,
        //     align: 'center',
        //     items: [{
        //         iconCls: 'x-fa fa-pencil',
        //         filterField: {
        //             xtype: 'textfield'
        //         },
        //         handler: 'onEditWizard'
        //     }]
        // },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 168,
            locked: true,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            locked: true,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialGroupCode'),
            dataIndex: 'MaterialGroupCode',
            width: 140,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialGroupName'),
            dataIndex: 'MaterialGroupName',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },
        {
            text: tt('UnitofMeasurement'),
            dataIndex: 'UnitofMeasurement',
            width: 168,
            filter: {
                type: 'number'
            },
        },
        {
            text: tt('MinQuantity'),
            dataIndex: 'MinQuantity',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },
        {
            text: tt('MaxQuantity'),
            dataIndex: 'MaxQuantity',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },
        {
            text: tt('PreStartDate'),
            dataIndex: 'PreStartDate',
            width: 100,
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('PreEndDate'),
            dataIndex: 'PreEndDate',
            width: 100,
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('PageIndex'),
            dataIndex: 'PageIndex',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('PageName'),
            dataIndex: 'PageName',
            width: 168,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('CreatedBy'),
            dataIndex: 'CreatedBy',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('CreatedAt'),
            dataIndex: 'CreatedAt',
            width: 100,
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('IsDeleted'),
            dataIndex: 'IsDeleted',
            width: 100,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('DeletedBy'),
            dataIndex: 'DeletedBy',
            width: 200,
            filter: {
                type: 'string'
            },
        },
        {
            text: tt('DeletedAt'),
            dataIndex: 'DeletedAt',
            filter: {
                type: 'string'
            },
            width: 100
        },
        {
            text: tt('CategoryManagerCode'),
            dataIndex: 'CategoryManagerCode',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('PuplishedBy'),
            dataIndex: 'PuplishedBy',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('PublishDate'),
            dataIndex: 'PublishDate',
            filter: {
                type: 'date'
            },
            width: 100
        },
        {
            text: tt('DaysForApprove'),
            dataIndex: 'DaysForApprove',
            filter: {
                type: 'number'
            },
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn'
        },
        {
            text: tt('ImgURL'),
            dataIndex: 'ImgURL',
            width: 200
        },
        {
            text: tt('DCCode'),
            dataIndex: 'DCCode',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('DCName'),
            dataIndex: 'DCName',
            filter: {
                type: 'string'
            },
            width: 200
        },
        {
            text: tt('OrderRecommendation_DC'),
            dataIndex: 'OrderRecommendation_DC',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('OrderRecommendation_DC_UpdatedbyMO'),
            dataIndex: 'OrderRecommendation_DC_UpdatedbyMO',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('OrderRecommendation_DC_UpdatedbyDC'),
            dataIndex: 'OrderRecommendation_DC_UpdatedbyDC',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('NumberofStores'),
            dataIndex: 'NumberofStores',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('NumberofRecommendedStores'),
            dataIndex: 'NumberofRecommendedStores',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('StatusId'),
            dataIndex: 'StatusId',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        }
    ],
})
`
