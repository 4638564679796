import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import _ from 'lodash'
import { getClosestFutureDate, getDateDiff } from '../../utils'

const moment = extendMoment(Moment)
const dayOfPeriods = {
  Yearly: 365,
  Quarterly: 91.5, // average of months * 3
  Monthly: 30.5, // average of months
  Weekly: 7
}

class Revenue extends Component {
  componentDidMount() {
    this.adjustScrollLeft(this.props)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const isMapChanged = this.props.currentPeriod.map !== nextProps.currentPeriod.map
    const isScrollLeftChanged = nextProps.scrollLeft !== this.props.scrollLeft
    if (isScrollLeftChanged || isMapChanged) {
      this.adjustScrollLeft(nextProps)
    }
  }

  adjustScrollLeft(props) {
    const { scrollLeft = 0 } = props
    _.each(this.refs, (rItem) => (rItem.scrollLeft = scrollLeft))
  }

  getStyle(StartDate, EndDate) {
    const { currentPeriod: { map = '', periods = [] } = {} } = this.props
    if (!periods.length) {
      return {}
    }

    const { closestIndex, closestDate: _startClosestDate } = getClosestFutureDate(
      periods,
      'StartTime',
      StartDate
    )

    const { closestIndex: _endClosestIndex, closestDate: _endClosestDate } = getClosestFutureDate(
      periods,
      'EndTime',
      EndDate
    )

    if (new Date(_endClosestDate) < new Date(_startClosestDate)) {
      console.warn('Start date can not bigger than end date!')
    }

    const $left = periods[closestIndex]?.Left ?? 0
    const $endLeft = (periods[_endClosestIndex] || { Left: 0 }).Left
    const $endPWidth = (periods[_endClosestIndex] || { Width: 0 }).Width
    const $periodDayLength = dayOfPeriods[map]
    const $dayDiff = getDateDiff(EndDate, _endClosestDate, 'days')
    const $extraWidth = ($endPWidth / $periodDayLength) * ($periodDayLength - $dayDiff)
    const $borderWidth = 2
    const $width = $endLeft - $left + $extraWidth + $borderWidth
    return { width: $width, left: $left }
  }

  render() {
    const {
      filteredOptions,
      startUpdateOption = () => {},
      segmentIndex = '',
      currentPeriod: { periods = [] } = {}
    } = this.props

    const timelineContainerWidth =
      this.props?.currentPeriod?.timelineSize?.timelineContainerWidth ?? 0

    const momentFormat = 'DD.MM.YYYY'

    return (
      <div className="ac-body">
        {_.map(filteredOptions, (optionItem, optionIndex) => {
          const {
            Name: OptionName = '',
            NumberOfActiveStores = 0,
            ImageUrl: OptionImageUrl = '',
            StartDate,
            EndDate
          } = optionItem

          const _start = moment(StartDate).format(momentFormat)
          const _end = moment(EndDate).format(momentFormat)

          const $style = this.getStyle(StartDate, EndDate)

          return (
            <div key={optionIndex} className="slvy-ui-row">
              <div className="space-2 sub-title">
                <span
                  className="fa fa-times-circle"
                  onClick={startUpdateOption.bind(this, optionItem, true, segmentIndex)}
                />
                <span className="count-box -indicator">
                  <i className="fa fa-tags" />
                  {NumberOfActiveStores}
                </span>
                <div className="image-box">
                  <img alt="" src={OptionImageUrl} title="" />
                  <div className="img-tooltip">
                    <img alt="" src={OptionImageUrl} title="" />
                  </div>
                </div>
              </div>
              <div className="slvy-ui-section-ctn">
                <div className="slvy-ui-section">
                  <div ref={`gridSpacer_${optionIndex}`} className="grid-spacer">
                    <div style={{ width: timelineContainerWidth, height: 50 }}>
                      <div key={optionIndex} className="segment-grid-item" style={$style}>
                        <span>{OptionName}</span>
                        <section>
                          <div>Start Date: {_start}</div>
                          <div>End Date: {_end}</div>
                        </section>
                      </div>
                    </div>
                    <main style={{ width: timelineContainerWidth }}>
                      {_.map(periods, ({ Width: width, Left: left }, periodIndex) => {
                        return (
                          <div
                            key={periodIndex}
                            className="-timeline-item-width"
                            style={{ width, left }}
                          />
                        )
                      })}
                    </main>
                  </div>
                </div>
              </div>
              <div className="space-2" />
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scrollLeft: state.timelineSettings.scrollLeft
  }
}

export default connect(mapStateToProps, null)(Revenue)
