import { useEffect, useRef } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { BasicType } from '@/BasePlugin/PluginTypes'
import { UseRegisterEventIconClickParams } from './useRegisterEventIconClick.types'
import { Item } from '../../ChronoTimeline.types'

const useRegisterEventIconClick = ({
  registerEvent,
  dataConfig,
  fieldConfigs
}: UseRegisterEventIconClickParams) => {
  const handleIconClickRef = useRef((_item: Item) => ({}))

  useEffect(() => {
    const returnTypes = fieldConfigs.reduce<Record<string, BasicType>>((acc, fieldConfig) => {
      const { fieldName, dataType } = fieldConfig
      acc[fieldName] = PluginTypes.fromString(dataType)
      return acc
    }, {})

    handleIconClickRef.current = registerEvent({
      key: 'iconClick',
      fn: (item: Item) => ({
        [dataConfig.card.title]: item.cardTitle,
        [dataConfig.card.text]: item.cardDetailedText,
        [dataConfig.card.author]: item.author,
        [dataConfig.card.isReadOnly]: item.isReadOnly,
        [dataConfig.timeline.icon]: item.icon,
        [dataConfig.timeline.title]: item.title
      }),
      returnTypes
    })
  }, [dataConfig, fieldConfigs, registerEvent])

  return handleIconClickRef.current
}

export default useRegisterEventIconClick
