import React, { Component } from 'react'
import { Image as BSImage } from 'react-bootstrap'
import _ from 'lodash'

class Image extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageURL: props.src,
      imageLoaded: false,
      loadError: false
    }

    this.handleImageLoadError = this.handleImageLoadError.bind(this)
    this.handleImageLoaded = this.handleImageLoaded.bind(this)
  }

  handleImageLoadError() {
    this.setState({ loadError: true })
  }

  handleImageLoaded() {
    this.setState({ imageLoaded: true })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState({ imageLoaded: false, imageURL: nextProps.src })
    }
  }

  render() {
    const { src, imageHeight, imageFit, imageBackground, imageBoxId } = this.props
    const { imageLoaded, loadError, imageURL } = this.state

    let imageStyles = {}
    if (_.isEqual(imageHeight, 'Keep Aspect Ratio')) {
      imageStyles = {
        maxHeight: '100%',
        width: '100%',
        objectFit: imageFit
      }
    } else {
      imageStyles = {
        height: parseInt(imageHeight, 10) * 100,
        objectFit: imageFit
      }
    }

    const imgErrorClass = loadError ? 'image-parent-error' : ''

    return (
      <div
        className={imageLoaded ? '' : `image-parent ${imgErrorClass}`}
        style={{ maxHeight: _.isEqual(imageHeight, 'Keep Aspect Ratio') ? '100%' : '' }}
        onClick={() => this.props.handleImageClick(imageBoxId)}
      >
        {_.isNil(src) || _.isEmpty(src) || loadError ? (
          <div className="error-parent">
            <i className="fa fa-picture-o fa-picture-o-size" />
          </div>
        ) : (
          <div
            style={{
              background: imageBackground,
              height: _.isEqual(imageHeight, 'Keep Aspect Ratio') ? '100%' : ''
            }}
          >
            <BSImage
              src={imageURL}
              style={{ ...imageStyles }}
              onError={this.handleImageLoadError}
              onLoad={this.handleImageLoaded}
            />
          </div>
        )}
      </div>
    )
  }
}
export default Image
