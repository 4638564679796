import { isEqual } from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelectorCreator, lruMemoize } from 'reselect'
import { GeneralConfigs, GeneralSchemaSettings } from '../types'

const selectRawGeneralConfig = (generalConfig: GeneralSchemaSettings) => generalConfig

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

const selectGeneralConfigs = createDeepEqualSelector(selectRawGeneralConfig, (generalConfig) => {
  // eslint-disable-next-line no-console
  console.log('selectGeneralConfigs selector is running...')

  const {
    name = '',
    header = '',
    exportAllColumns = true,
    exportWithoutHeaders = false
  } = generalConfig

  const newGeneralConfig: GeneralConfigs = {
    name,
    header,
    exportAllColumns,
    exportWithoutHeaders
  }

  return newGeneralConfig
})

export default selectGeneralConfigs
