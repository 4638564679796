Ext.define('po.model.FutureOrderModel', {
  extend: 'po.model.Base',
  entityName: 'po/futureorder',
  fields: [
    { name: 'UrunKodu', type: 'string' },
    { name: 'MagazaKodu', type: 'string' },
    { name: 'MagazaAdi', type: 'string' },
    { name: 'SiparisMiktari', type: 'float' }
  ]
})
