import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsWrapperProps from './HighchartsWrapper.types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('highcharts/highcharts-more')(Highcharts)
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('highcharts/modules/bullet')(Highcharts)

const HighchartsWrapper = (props: HighchartsWrapperProps) => {
  const { className, options } = props
  return (
    <HighchartsReact containerProps={{ className }} highcharts={Highcharts} options={options} />
  )
}

export default HighchartsWrapper
