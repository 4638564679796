export default `({
    title: tt("Sales Chart"),
    listeners: {
        activate: function (p) {
                        //alert('activate')
            var store = p.getSalesChartPanel(),
                SpotId = p.getSpotId();
            store.getProxy().setExtraParam('SpotId', SpotId);
            if (store.forceToLoad) {
                store.load();
                store.forceToLoad = false
            }
        },
        afterChartRendered: function (p) {
            //alert('afterChartRendered')
            // var me = this;
            // Ext.defer(function () {
            //     if (me.chart && me.rendered) {
            //         me.onloadStore(me.store);
            //     } else {
            //         me.afterChartRendered()
            //     }
            // }, 600);
        }
    },
    series: [{
            dataIndex: 'QTY',
            type: 'column',
            name: tt('Quantity'),
            color: 'rgba(236,17,92,1)',
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    // panel = serie.chart;
                    // panel.fireEvent('clickChart', record);
                }
            }
        },
        // {
        //     dataIndex: 'Envanter',
        //     type: 'spline',
        //     name: tt('Inventory'),
        //     cursor: 'pointer',
        //     // visible: false,
        //     listeners: {
        //         pointclick: function (serie, point, record, event) {
        //             // panel = serie.chart;
        //             // panel.fireEvent('clickChart', record);
        //         }
        //     }
        // },
        {
            dataIndex: 'PriceNull',
            type: 'spline',
            "lineWidth": 0,
            "marker": {
                "enabled": "true",
                "states": {
                    "hover": {
                        "enabled": true
                    }
                },
                "radius": 5
            },
            "states": {
                "hover": {
                    "lineWidthPlus": 0
                }
            },
            name: tt('Salesprice'),
            yAxis: 1,
            cursor: 'pointer',
            tooltip:{
                 valueDecimals: 2
            },
            listeners: {
                pointclick: function (serie, point, record, event) {
                    // panel = serie.chart;
                    // panel.fireEvent('clickChart', record);
                }
            }
        },
        {
            dataIndex: 'NumberOfStoreNull',
            type: 'spline',
            "lineWidth": 0,
            "marker": {
                "enabled": "true",
                "states": {
                    "hover": {
                        "enabled": true
                    }
                },
                "radius": 5
            },
            "states": {
                "hover": {
                    "lineWidthPlus": 0
                }
            },
            name: tt('Number Of Store'),
            yAxis: 2,
            cursor: 'pointer',
            listeners: {
                pointclick: function (serie, point, record, event) {
                    // panel = serie.chart;
                    // panel.fireEvent('clickChart', record);
                }
            }
            // listeners: {
            // 	pointclick: this.onClickPieChart
            // }
        },
    ],
})
`
