import { useCallback, useEffect, useState } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { compareOppositeVariables } from '../../../../helpers'
import { HandleSetEnabledStateProps } from './useRegisterMethodSetEnabledState.types'
import { RegisterMethod } from '../../registerEventsMethods.types'

function useRegisterMethodSetEnabledState(registerMethod: RegisterMethod) {
  const [isGridReadOnly, setIsGridReadOnly] = useState(false)
  const handleSetEnabledState = useCallback(
    ({ enabled, notEnabled }: HandleSetEnabledStateProps) => {
      const isEnabled = compareOppositeVariables({ enabled, notEnabled })
      if (isEnabled !== null) {
        setIsGridReadOnly(!isEnabled)
      }
    },
    []
  )

  useEffect(() => {
    registerMethod({
      args: [
        { name: 'enabled', type: PluginTypes.boolean },
        { name: 'notEnabled', type: PluginTypes.boolean }
      ],
      key: 'setEnabledState',
      fn: handleSetEnabledState
    })
  }, [handleSetEnabledState, registerMethod])

  return { isGridReadOnly }
}

export default useRegisterMethodSetEnabledState
