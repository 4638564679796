import { useMemo } from 'react'
import { SlvySpinner } from '@/components'
import { GridSettings } from '../../types'

function useOverlayProps(emptyText: GridSettings['customConfigs']['emptyText']) {
  const overlayProps = useMemo(() => {
    return {
      overlayNoRowsTemplate: `<h6>${emptyText}</h6>`,
      loadingOverlayComponent: () => (
        <SlvySpinner containerClass="bg-opacity-10 bg-dark" size="sm" />
      )
    }
  }, [emptyText])

  return { overlayProps }
}

export default useOverlayProps
