Ext.define('MDM.src.view.globalapp.Panel.Dpi.Panel.DPIFamily', {
  extend: 'MDM.src.ux.GridPanel',
  // Uncomment to give this component an xtype
  // xtype : '-dpifamily',
  xtype: 'globalapp-dpifamily',
  border: true,
  title: 'DPI Family',
  iconCls: 'fa fa-cubes',
  multiSelect: true,
  listeners: {
    rowcontextmenu: 'onCopyRecord'
  },
  tools: [
    {
      type: 'plus',
      tooltip: 'Add new DPIFamily ',
      bind: {
        hidden: '{readOnlyMode}'
      },
      handler: 'onAddDPIFamily'
    }
  ],
  rowLines: true,
  columnLines: true,
  viewConfig: {
    stripeRows: false,
    plugins: [
      {
        ptype: 'uxgridviewdragdrop',
        id: 'dd',
        containerScroll: true,
        enableDrop: false,
        dragGroup: 'dd-grid-to-grid-group3',
        dropGroup: 'dd-grid-to-grid-group2',
        // The right hand drop zone gets special styling
        // when dragging over it.
        dropZone: {
          overClass: 'dd-over-gridview'
        }
      }
    ],
    listeners: {
      beforedrop: function (node, data, overModel, dropPosition, dropHandlers) {
        // Defer the handling
        dropHandlers.wait = true
        // here you have the record from the treeview and can do anything you like with it.
        var record = data.records[0]
        Ext.MessageBox.confirm(
          'Move',
          'Your are about to drop <span style="color:red">' +
            record.get('ProductGroup') +
            '</span> to <span style="color:red">' +
            overModel.get('DPISegment') +
            '</span>, Continue?',
          function (btn) {
            if (btn === 'yes') {
              dropHandlers.processDrop()
            } else {
              // IMPORTANT - In this case, we want to cancel the drop as the records aren't compatible
              dropHandlers.cancelDrop()
            }
          }
        )
      }
    }
  },
  features: [
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      text: 'DPI Family',
      dataIndex: 'DPIFamily',
      menuDisabled: true,
      flex: 2,
      summaryType: 'count',
      summaryRenderer: function (value) {
        return Ext.String.format('<b>{0} DPI {1} </b>', value, value > 1 ? 'Families' : 'Family')
      }
    },
    {
      text: 'SU',
      dataIndex: 'SU',
      align: 'right',
      flex: 1,
      menuDisabled: true
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      align: 'center',
      menuDisabled: true,
      bind: {
        hidden: '{readOnlyMode}'
      },
      items: [
        {
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete DPI Family',
          handler: 'onDPIFamilyDelete'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      align: 'center',
      bind: {
        hidden: '{readOnlyMode}'
      },
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          // isActionDisabled: 'isActionDisabled',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          tooltip: 'Edit',
          handler: 'onDPIFamilyEdit'
        }
      ]
    }
    // {
    //   xtype: 'actioncolumn',
    //   width: 30,
    //   align: 'center',
    //   menuDisabled: true,
    //   items: [{
    //     iconCls: 'cell-editing-delete-row',
    //     tooltip: 'Delete Record',
    //     isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
    //       return !record.get('isEditable')
    //     },
    //     handler: 'onDPIFamilyDelete'
    //   },
    //   {
    //     xtype: 'actioncolumn',
    //     width: 30,
    //     menuDisabled: true,
    //     align: 'center',
    //     bind: {
    //       hidden: '{readOnlyMode}',
    //     },
    //     items: [{
    //       iconCls: 'fa fa-pencil-square-o',
    //       // isActionDisabled: 'isActionDisabled',
    //       isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
    //         return !record.get('isEditable')
    //       },
    //       tooltip: 'Edit',
    //       handler: 'onEditDPIFamily'
    //     }]
    //   }]
    // }
  ]
})
