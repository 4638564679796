import React from 'react'

export default class AssortmentInput extends React.Component {
  onHandleKeyDown(event) {
    const { keyCode = '', target: { type = 'text', checked = false, value = '' } = {} } = event

    const { inputKey = '', onKeyDown = () => {} } = this.props

    const $isOptional = this.isOptional(type)

    onKeyDown({
      inputKey,
      [$isOptional ? 'checked' : 'value']: $isOptional ? checked : value,
      keyCode
    })
  }

  onChanged(event) {
    const { target: { type = 'text', checked = false, value = '' } = {} } = event

    const { inputKey = '', onChanged = () => {} } = this.props

    const $isOptional = this.isOptional(type)

    onChanged({
      inputKey,
      [$isOptional ? 'checked' : 'value']: $isOptional ? checked : value
    })
  }

  isOptional(type) {
    return type === 'checkbox' || type === 'radio'
  }

  getValidationClass(type) {
    const {
      props: { isTouched = false, isRequired = false, value = '' }
    } = this

    const $isNotOptional = this.isOptional(type) === false

    let validateClass = ''

    if (isTouched && $isNotOptional) {
      if (!isRequired || (isRequired && value)) {
        validateClass = 'success'
      } else if (isRequired && !value) {
        validateClass = 'error'
      }
    }

    return validateClass
  }

  render() {
    const {
      props: {
        id = Math.random(),
        isDisabled = false,
        name = '',
        value = null,
        type = 'text',
        readOnly = false,
        placeholder = '',
        className = '',
        defaultValue = '',
        isControlled = true,
        checked = false,
        noDefaultClass = false,
        onClick = () => {}
      }
    } = this

    const defaultClassNames = noDefaultClass ? '' : ' form-control form-control-sm input '
    const $isOptional = this.isOptional(type)

    // this is because null can not set input
    const _value = $isOptional ? {} : !value ? { value: '' } : { value }
    const _defaultValue = $isOptional ? {} : !defaultValue ? { defaultValue: '' } : { defaultValue }
    const $value = $isOptional ? {} : isControlled ? { ..._value } : { ..._defaultValue }
    const _checked = $isOptional ? { checked } : {}
    const _className = { className: defaultClassNames + className + this.getValidationClass(type) }

    const properties = {
      ...$value,
      ..._checked,
      ..._className
    }

    return (
      <input
        disabled={isDisabled}
        id={id}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        onChange={this.onChanged.bind(this)}
        onClick={onClick.bind(this)}
        onKeyDown={this.onHandleKeyDown.bind(this)}
        {...properties}
      />
    )
  }
}
