import { STORE_DEFAULT_ACTION_PARAMS } from '../actions/actionTypes'

const initialState = {
  defaultActionParams: {
    payload: {
      pluginId: '',
      requestMethod: 'get',
      body: {}
    }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_DEFAULT_ACTION_PARAMS:
      return {
        ...state,
        defaultActionParams: {
          payload: {
            ...state.defaultActionParams.payload,
            ...action.payload
          }
        }
      }
    default:
      return state
  }
}

export default reducer
