import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { slvyToast } from '../../../../components'
import { MESSAGES } from '../../messages'

const ScenarioSubList = (props) => {
  const onScenarioClick = function (Item) {
    if (Item.IsScenario) {
      props.onScenarioSelect(Item)
    } else {
      slvyToast.warning({
        message: MESSAGES.please_select_a_scenario,
        options: { containerId: 'dp' }
      })
    }
  }

  const getDate = (date) => (_.isEmpty(date) ? '-' : moment(date).format('DD.MM.YYYY'))

  const {
    selectedScenario,
    selectedScenario: { Id: selectedScenarioId } = {},
    data = [],
    mode = ''
  } = props

  return (
    <ul>
      {_.map(data, (item) => {
        let {
          Item,
          Item: {
            IsScenario = false,
            Id = -1,
            Name = '',
            CreatedBy = '-',
            RevisionDate = '',
            CreatedAt = '',
            Type = 0
          },
          Children = []
        } = item

        RevisionDate = getDate(RevisionDate)
        CreatedAt = getDate(CreatedAt)

        const isActive = selectedScenarioId === Id

        let isHidden = false
        if (mode === 'delete' && (Type === 1 || Type === 2)) {
          isHidden = true
        }

        return !isHidden ? (
          <li key={Id}>
            <div
              className={`sc-item-ctn ${isActive ? '-active-selection' : ''}`}
              onClick={onScenarioClick.bind(this, Item)}
            >
              <section>
                <i
                  className={`slvy-ui-icon-${!IsScenario ? 'plus-fill' : 'document-outline-alt'}`}
                />
                <span>{Name}</span>
              </section>
              <section />
              <section>{CreatedBy}</section>
              <section className="scenarioDate">{RevisionDate}</section>
              <section className="scenarioDate">{CreatedAt}</section>
            </div>
            <ScenarioSubList
              data={Children}
              mode={mode}
              selectedScenario={selectedScenario}
              onScenarioSelect={props.onScenarioSelect}
            />
          </li>
        ) : null
      })}
    </ul>
  )
}

export default ScenarioSubList
