import { useCallback, useEffect } from 'react'
import { GetModifiedRowsProps } from './useRegisterMethodGetModifiedRows.type'

function useRegisterMethodGetModifiedRows({
  getModifiedRowsResultEventRef,
  registerMethod
}: GetModifiedRowsProps) {
  const handleGetModifiedRows = useCallback(() => {
    getModifiedRowsResultEventRef?.current?.handleGetModifiedRowsResult()
  }, [getModifiedRowsResultEventRef])

  useEffect(() => {
    registerMethod({
      args: [],
      key: 'GetModifiedRows',
      fn: handleGetModifiedRows
    })
  }, [handleGetModifiedRows, registerMethod])
}

export default useRegisterMethodGetModifiedRows
