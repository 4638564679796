import { CellClassParams, ICellRendererParams, ITooltipParams } from 'ag-grid-community'
import { getValidCellRule } from '../index'
import {
  cellDisplayRules as defaultCellDisplayRules,
  cellRendererParams as defaultCellRendererParams,
  validCellRule as defaultValidCellRule
} from '../../__tests__/mocks'

function getCellDisplayRules() {
  return defaultCellDisplayRules
}

function getParams() {
  return defaultCellRendererParams as CellClassParams | ICellRendererParams | ITooltipParams
}

function getValidCellRuleData() {
  return defaultValidCellRule
}

describe('AgGrid:Helpers:Cell:getValidCellRule', () => {
  let cellDisplayRules = getCellDisplayRules()
  let validCellRule = getValidCellRuleData()
  let params = getParams()

  beforeEach(() => {
    cellDisplayRules = getCellDisplayRules()
    validCellRule = getValidCellRuleData()
    params = getParams()
  })

  it('should return null if there is no valid cell rule', () => {
    const result = getValidCellRule({ params, cellDisplayRules })
    expect(result).toEqual(null)
  })

  it('should return valid cell rule', () => {
    params.value = validCellRule.value
    params.data.col1 = validCellRule.value
    const result = getValidCellRule({ params, cellDisplayRules: [validCellRule] })
    expect(result).toEqual(validCellRule)
  })

  it('should return valid cell rule with referenceField', () => {
    validCellRule.value = '123'
    validCellRule.referenceFieldName = 'col4'
    params.data[validCellRule.referenceFieldName] = '123'
    const result = getValidCellRule({ params, cellDisplayRules: [validCellRule] })
    expect(result).toEqual(validCellRule)
  })
})
