import { Component } from 'react'

import { SlvySelect } from '../../../../../components'

export default class ConnectionMapping extends Component {
  componentDidMount() {
    const { onMount } = this.props

    onMount()
  }

  render() {
    const { data, isLoading, mapData, selectValue, title, onSelect } = this.props

    return (
      <div className="connection-mapping">
        {!isLoading.pageConnections ? <span className="title">{title}</span> : null}
        <section className="selection">
          {isLoading.pageConnections ? (
            <span className="loading-message">
              <i className="fa-fw fa fa-spinner" />
              Fetching connections...
            </span>
          ) : (
            Object.entries(data.pageConnections).map(([name], index, arr) => {
              const isLastTwo = index > arr.length - 3
              const menuPlacement = isLastTwo ? 'top' : 'bottom'

              return (
                <div key={index.toString()} className="group">
                  <label className="label" htmlFor="connection-select" title={name}>
                    {name}
                  </label>
                  <SlvySelect
                    id="connecton-select"
                    isLoading={isLoading.catalogConnections}
                    menuPlacement={menuPlacement}
                    options={mapData(data.catalogConnections)}
                    placeholder="Select..."
                    value={selectValue[index]}
                    onChange={(value) => onSelect(value, index)}
                  />
                </div>
              )
            })
          )}
        </section>
      </div>
    )
  }
}
