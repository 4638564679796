import * as actionTypes from './actionTypes'

export const selectLegendProductIssue = (
  legendState,
  legendData,
  maxSelectedIssues,
  selectedProductIssueCount
) => ({
  type: actionTypes.SELECT_LEGEND_PRODUCT_ISSUE,
  legendState: { ...legendState },
  legendData: {
    issueList: legendData.enabledProductIssues,
    resourceInfoList: legendData.enabledProductDetails
  },
  maxSelectedIssues,
  selectedProductIssueCount
})

export const selectLegendResourceIssue = (
  legendState,
  legendData,
  maxSelectedIssues,
  selectedResourceIssueCount
) => ({
  type: actionTypes.SELECT_LEGEND_RESOURCE_ISSUE,
  legendState: { ...legendState },
  legendData: {
    issueList: legendData.enabledResourceIssues,
    resourceInfoList: legendData.enabledResourceDetails
  },
  maxSelectedIssues,
  selectedResourceIssueCount
})

// new production view...
export const PRODUCTselectLegendProductIssue = (
  legendState,
  legendData,
  maxSelectedIssues,
  selectedProductIssueCount
) => ({
  type: actionTypes.PRODUCT_SELECT_LEGEND_PRODUCT_ISSUE,
  legendState: { ...legendState },
  legendData: {
    issueList: legendData.enabledProductIssues,
    resourceInfoList: legendData.enabledProductDetails
  },
  maxSelectedIssues,
  selectedProductIssueCount
})

export const PRODUCTselectLegendResourceIssue = (
  legendState,
  legendData,
  maxSelectedIssues,
  selectedResourceIssueCount
) => ({
  type: actionTypes.PRODUCT_SELECT_LEGEND_RESOURCE_ISSUE,
  legendState: { ...legendState },
  legendData: {
    issueList: legendData.enabledResourceIssues,
    resourceInfoList: legendData.enabledResourceDetails
  },
  maxSelectedIssues,
  selectedResourceIssueCount
})
