import React from 'react'

import Handlebars from 'handlebars'
import _ from 'lodash'

export default class Preview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      html: ''
    }
  }

  generateHtmlFromConfig(props) {
    const {
      settings: {
        config: {
          templateSettings: {
            specificTemplate = '',
            customStyleTemplate = '',
            customScriptTemplate = '',
            htmlTemplate = ''
          } = {}
        } = {}
      } = {},
      pluginData: result = []
    } = props

    try {
      let styleTemplate = ''

      switch (specificTemplate) {
        case 'primary':
          styleTemplate = ''
          break
        default:
          break
      }
      const template = Handlebars.compile(htmlTemplate)
      const newResult = _.map(
        result,
        (row) => {
          return _.transform(
            row,
            (r, col, colName) => {
              // r[colName] = this.getFormatedValueWithProps(props, colName, col)
            },
            {}
          )
        },
        {}
      )
      return `<style>${styleTemplate} ${customStyleTemplate}</style>${template({
        newResult
      })}${customScriptTemplate}`
    } catch (e) {
      return `TEMPLATE ERROR: ${e.message}`
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      html: this.generateHtmlFromConfig(this.props)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      this.setState({ html: this.generateHtmlFromConfig(nextProps) })
    }
  }

  render() {
    const { html } = this.state

    return (
      <div className="html-template-container">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    )
  }
}
