Ext.define('MDM.src.view.regionapp.Dilaog.OwnerList', {
  extend: 'Ext.grid.Panel',
  xtype: 'ownerList',
  columns: [
    {
      text: 'MaterialCode',
      dataIndex: 'MaterialCode',
      flex: 1,
      sortable: true
    },
    {
      text: 'MaterialDescription',
      dataIndex: 'MaterialDescription',
      flex: 2,
      sortable: true
    },
    {
      text: 'GU',
      dataIndex: 'GU',
      width: 120,
      sortable: true,
      align: 'right',
      editor: {
        xtype: 'numberfield',
        allowBlank: false,
        minValue: 0,
        maxValue: 100000,
        step: 0.1
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      sortable: false,
      menuDisabled: true,
      items: [
        {
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete DPI',
          handler: function (grid, rowIndex, colIndex, item, e, record) {
            var store = grid.store
            store.remove(record)
          }
        }
      ]
    }
  ]
})
