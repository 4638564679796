import { getFullLookupFieldData } from '../../index'
import { selectedRowsForLookupField } from '../mocks'

describe('AgGrid:MassUpdate:Helpers:Common:getFullLookupFieldData', () => {
  it('should return empty array if selectRows is empty', () => {
    const fullLookupFieldData = getFullLookupFieldData([], '')
    expect(fullLookupFieldData).toEqual([])
  })
  it('should return intersection of the selectRows lookupField data', () => {
    const fullLookupFieldData = getFullLookupFieldData(selectedRowsForLookupField, 'col9')
    expect(fullLookupFieldData).toEqual([
      {
        id: '2',
        value: 'Blue'
      },
      {
        id: '4',
        value: 'Orange'
      }
    ])
  })
})
