export default `({
    layout: 'fit',
    maximizable: true,
    items: [{
        title: tt('Store Clustering'),
        dockedItems: [{
            xtype: 'toolbar',
            dock: 'top',
            defaultButtonUI: 'default',
            items: ['->',
                {
                    xtype: 'button',
                    text: tt('Export to Excel'),
                    handler: function (btn) {
                        var w = btn.up('window'),
                            grid = w.down('grid')
                        grid.saveDocumentAs({
                            type: 'xlsx',
                            title: 'UserData',
                            fileName: 'export.xlsx',
                        });
                    }
                }
            ]
        }],
        border: true,
        listeners: {
            render: function (grid) {
                var me = this;
                var spotId = grid.ownerCt.ownerCt.spotId,
                    store = grid.getStore(),
                    proxy = store.getProxy();
                // store.on('load', function (store) {
                //     OrderRecommendationCurrent = store.sum('OrderRecommendationCurrent');
                //     OrderRecommendationParent = store.sum('OrderRecommendationParent');
                //     viewModel = this.up('uxWindow').viewModel
                //     viewModel.set('OrderRecommendationCurrent', OrderRecommendationCurrent);
                //     viewModel.set('OrderRecommendationParent', OrderRecommendationParent)
                // }, me)
                proxy.setExtraParam('spotId', spotId);
                store.load();
            }
        },
        store: ({
            groupField: 'Category',
            autoLoad: false,
            autoSync: true,
            fields: [{
                    name: 'SpotID',
                    type: 'int'
                },
                {
                    name: 'OrgCode',
                    type: 'string'
                },
                {
                    name: 'StoreCode',
                    type: 'string'
                },
                {
                    name: 'Category',
                    type: 'string'
                },
                {
                    name: 'MinQuantity',
                    type: 'int'
                },
                {
                    name: 'MaxQuantity',
                    type: 'int'
                },
                {
                    name: 'FixQuantity',
                    type: 'int'
                },
                {
                    name: 'OrgName',
                    type: 'string'
                },
                {
                    name: 'StoreName',
                    type: 'string'
                },
                {
                    name: 'StoreCity',
                    type: 'string'
                },
                {
                    name: 'StoreStreet',
                    type: 'string'
                },
                {
                    name: 'StoreOpenDate',
                    type: 'string'
                },
                {
                    name: 'StoreCloseDate',
                    type: 'string'
                },
                {
                    name: 'UrunGrubu',
                    type: 'string'
                }
            ],
            listeners: {
                //   load:this.onStoreLoad
            },
            proxy: {
                type: 'ajax',
                actionMethods: {
                    create: 'POST',
                    read: 'POST',
                    update: 'POST',
                    destroy: 'POST'
                },
                api: {
                    create: Spui.Global.getApiUrl() + 'spui/GetStoreCluster/create',
                    read: Spui.Global.getApiUrl() + 'spui/GetStoreCluster/read',
                    update: Spui.Global.getApiUrl() + 'spui/GetStoreCluster/update',
                    destroy: Spui.Global.getApiUrl() + 'spui/GetStoreCluster/destroy',
                },
                writer: {
                    rootProperty: 'data',
                    allowSingle: false,
                    successProperty: 'success',
                    messageProperty: 'errors',
                    allDataOptions: {
                        associated: false
                    },
                    writeAllFields: true
                },
            },
            autoLoad: false,
        }),
        plugins: [{
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
                // beforeedit: function (editor, context) {
                //     rec = context.record;
                //     return rec.get('IsEditable');
                // },
                // edit: function (editor, context, eOpts) {
                //     var me = this;
                //     var record = context.record;
                //     if (context.originalValue * 1 == context.value * 1) {
                //         return;
                //     }
                //     grid = context.grid.ownerCt
                //     grid.setLoading('Saving');
                //     record.save({
                //         params: {
                //             updateAction: 'SubValueChange'
                //         },
                //         failure: function (record, operation) {},
                //         success: function (record, operation) {
                //             grid.setLoading(false)
                //             var c = grid.ownerCt.ownerCt.getController(),
                //                 store = grid.getStore();
                //             grid.setLoading(false);
                //             store.load();
                //         },
                //         callback: function (record, operation, success) {
                //             // do something whether the save succeeded or failed
                //         }
                //     });
                // },
                scope: 'this'
            }
        }],
        xtype: 'grid',
        header: false,
        plugins: [{
            ptype: 'gridexporter'
        }],
        features: [{
            ftype: 'groupingsummary',
            groupHeaderTpl: ['{columnName}: {name} ({[values.children.length]})'],
            hideGroupedHeader: false,
            startCollapsed: false
        }],
        columnLines: true,
        columns: [{
                text: tt('SalesOrgCode'),
                dataIndex: 'OrgCode',
                width: 120
            },
            {
                text: tt('SalesOrgName'),
                dataIndex: 'OrgName',
                width: 200
            },
            {
                text: tt('Store Information '),
                columns: [{
                        text: tt('StoreCode'),
                        dataIndex: 'StoreCode',
                        width: 120
                    },
                    {
                        text: tt('StoreName'),
                        dataIndex: 'StoreName',
                        flex: 1,
                        width: 200
                    },
                    {
                        text: tt('StoreCity'),
                        dataIndex: 'StoreCity',
                        width: 200
                    },
                    {
                        text: tt('StoreStreet'),
                        dataIndex: 'StoreStreet',
                        width: 200
                    }
                ]
            },
            {
                text: tt('Category'),
                dataIndex: 'Category',
                width: 200
            },
            // {
            //     text: 'MinQuantity',
            //     dataIndex: 'MinQuantity',
            //     width: 100,
            //     format: '000.00',
            //     align: 'right',
            //     xtype: 'numbercolumn'
            // },
            // {
            //     text: 'MaxQuantity',
            //     dataIndex: 'MaxQuantity',
            //     width: 100,
            //     format: '000.00',
            //     align: 'right',
            //     xtype: 'numbercolumn'
            // },
            // {
            //     text: 'FixQuantity',
            //     dataIndex: 'FixQuantity',
            //     width: 100,
            //     format: '000.00',
            //     align: 'right',
            //     xtype: 'numbercolumn'
            // },
            {
                text: tt('StoreOpenDate'),
                dataIndex: 'StoreOpenDate',
                width: 100
            },
            {
                text: tt('StoreCloseDate'),
                dataIndex: 'StoreCloseDate',
                width: 100
            },
            // {
            //     text: 'UrunGrubu',
            //     dataIndex: 'UrunGrubu',
            //     width: 200
            // }
        ],
    }, ]
})
`
