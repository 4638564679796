import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import OptionModal from '../OptionModal'
import Body from './body'
import Revenue from './revenue'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { getOptions } from '../../store/slices/options'

class AcList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSegmentId: -1,
      stateHandler: {}
    }

    this.stateHandler = this.stateHandler.bind(this)
  }

  getData(props) {
    const { rootOptions } = props

    const segmentSum = {
      TotalRevenue: 0,
      TotalSales: 0,
      NumberOfStores: 0,
      MinPresentation: 0,
      BuyQuantity: 0
    }

    const clonedRootOptions = _.cloneDeep(rootOptions)
    const { Options = [], Segments = [] } = clonedRootOptions

    _.map(Segments, (SegmentItem) => {
      const { Id = 0 } = SegmentItem

      SegmentItem.FilteredOptions = _.reduce(
        Options,
        (OptionArr, OptionItem) => {
          const {
            NumberOfActiveStores = 0,
            MinPresentation = 0,
            BuyQuantity = 0,
            SegmentOptions = {}
          } = OptionItem
          const { Enabled } = SegmentOptions[Id]

          segmentSum.NumberOfStores += NumberOfActiveStores
          segmentSum.MinPresentation += MinPresentation
          segmentSum.BuyQuantity += BuyQuantity

          if (Enabled) {
            OptionArr.push(OptionItem)
          }

          return OptionArr
        },
        []
      )

      SegmentItem.Summary = segmentSum

      segmentSum.TotalRevenue += SegmentItem.TotalRevenue
      segmentSum.TotalSales += SegmentItem.TotalSales

      return SegmentItem
    })

    return clonedRootOptions
  }

  stateHandler(key, value) {
    const { stateHandler } = this.state
    stateHandler[key] = value
    this.setState({ stateHandler })
  }

  startUpdateOption(OptionStr, Enabled, segmentIndex, event) {
    const {
      props: {
        rootOptions: { AssortmentId = '' }
      }
    } = this
    event.stopPropagation()
    OptionStr.SegmentOptions[segmentIndex].Enabled = !Enabled
    this.onUpdateOption({ AssortmentId, OptionStr }, true)
  }

  onUpdateOption(params) {
    const {
      props: { pluginId = '', fetchOptions = () => {}, setLoader = () => {} }
    } = this

    setLoader({ isShown: true, messages: 'Update Option' })

    const payload = {
      pluginId,
      method: 'UpdateOption',
      requestMethod: 'post',
      body: {
        ...params
      }
    }

    fetchOptions({ ...payload }).then(() => {
      const { rootOptions = {} } = this.props

      if (!_.isEmpty(rootOptions)) {
        setLoader({ isShown: false })
      }
    })
  }

  render() {
    const { currentPeriod, scobj, pluginId = '' } = this.props
    const {
      currentSegmentId,
      stateHandler: { acmodal = false }
    } = this.state
    const { Options = [], Segments = [], AssortmentId = 0 } = this.getData(this.props)

    const {
      Summary: { TotalRevenue, TotalSales, NumberOfStores, MinPresentation, BuyQuantity } = {}
    } = Segments[0]

    const totalOptions = Options.length

    return (
      <div className={`ac-list-container modal-active-${acmodal}`}>
        <div className="slvy-ui-row total-revenue-box">
          <div className="space-2" />
          <nav className="slvy-ui-section -total-box">
            <Revenue
              buyQuantity={BuyQuantity}
              minPresentation={MinPresentation}
              numberOfStores={NumberOfStores}
              optionCount={totalOptions}
              totalRevenue={TotalRevenue}
              totalSales={TotalSales}
            />
          </nav>
          <div className="space-2" />
        </div>
        <div className="slvy-ui-row segment-selection">
          <div className="space-2" />
          <div className="slvy-ui-section">
            <a
              className="btn-arrow -arrow-left"
              onClick={() => {
                this.stateHandler('acmodal', true)
                this.setState({ currentSegmentId: -1 })
              }}
            >
              <i className="fa fa-plus" /> <span>Add to Segment</span>
            </a>
          </div>
          <div className="space-2" />
        </div>
        {acmodal && currentSegmentId < 0 && (
          <OptionModal
            assortmentId={AssortmentId}
            filteredOptions={[]}
            options={Options}
            segment={Segments}
            stateHandler={this.stateHandler}
          />
        )}

        <ul className="ac-list">
          {_.map(Segments, (segmentItem, segmentIndex) => {
            const {
              Name: SegmentName = '',
              Id: SegmentId = 0,
              NumberOfStores = 0,
              TotalRevenue = 0,
              TotalSales = 0,
              FilteredOptions = [],
              Summary: { TotalRevenue: BaseTotalRevenue = 0 }
            } = segmentItem
            const isActive = currentSegmentId === SegmentId
            const modalActive = acmodal && isActive

            const OptionCount = FilteredOptions.length

            const SegmentBuyQuantity = _.reduce(
              FilteredOptions,
              (totalCount, filteredItem) => {
                const { BuyQuantity = 0 } = filteredItem
                totalCount += BuyQuantity
                return totalCount
              },
              0
            )

            const SegmentMinPresentation = _.reduce(
              FilteredOptions,
              (totalCount, filteredItem) => {
                const { MinPresentation = 0 } = filteredItem
                totalCount += MinPresentation
                return totalCount
              },
              0
            )

            return (
              <li key={SegmentId} className={`selectedClass ${isActive ? 'active' : ''}`}>
                <div className="segment-modal-ctn">
                  {modalActive && (
                    <OptionModal
                      assortmentId={AssortmentId}
                      filteredOptions={FilteredOptions}
                      options={Options}
                      pluginId={pluginId}
                      segment={SegmentId}
                      stateHandler={this.stateHandler}
                    />
                  )}
                </div>
                <div className="slvy-ui-row">
                  <div className="space-2 ac-title">{SegmentName}</div>

                  <div
                    className="slvy-ui-section ac-header"
                    onClick={() => {
                      this.setState({
                        currentSegmentId: _.isEqual(currentSegmentId, SegmentId) ? -1 : SegmentId
                      })
                      this.stateHandler('acmodal', false)
                    }}
                  >
                    <div
                      className="total-rev-indicator"
                      style={{
                        width: `${(TotalRevenue / BaseTotalRevenue) * 100}%`
                      }}
                    />

                    <Revenue
                      buyQuantity={SegmentBuyQuantity}
                      minPresentation={SegmentMinPresentation}
                      numberOfStores={NumberOfStores}
                      optionCount={OptionCount}
                      totalRevenue={TotalRevenue}
                      totalSales={TotalSales}
                    />

                    <nav>
                      {!modalActive && (
                        <span
                          className="one-item -arrow-bottom"
                          onClick={(event) => {
                            event.stopPropagation()
                            this.setState({ currentSegmentId: SegmentId })
                            this.stateHandler('acmodal', !modalActive)
                          }}
                        >
                          <i className="fa fa-plus" />
                        </span>
                      )}

                      {!_.isEmpty(FilteredOptions) && (
                        <div>
                          {' '}
                          <i className="fa fa-caret-down icon-caret" />{' '}
                        </div>
                      )}
                    </nav>
                  </div>
                  <div className="space-2" />
                </div>
                {isActive && (
                  <Body
                    assortmentId={AssortmentId}
                    currentPeriod={currentPeriod}
                    filteredOptions={FilteredOptions}
                    pluginId={pluginId}
                    scobj={scobj}
                    segmentIndex={segmentIndex}
                    startUpdateOption={this.startUpdateOption.bind(this)}
                  />
                )}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOptions: bindActionCreators(getOptions, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcList)
