import React, { Component } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import PantoneListSection from './components/PantoneListSection'
import Modal from './components/modal'
import { slvyToast } from '@/components'
import { MESSAGES } from './messages'
import './index.scss'

class ColorPalettes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      designId: null,
      modalShow: false,
      itemToEdit: undefined,
      modalShowColorDetails: false,
      modalShowColorSearch: false,
      isMainColor: undefined,
      selectedMainColorId: undefined,
      designOptionsAttributes: [],
      selectedDesignColorAttributes: [],
      readOnly: false,
      materialTypeID: 0,
      pending: true,
      colorList: []
    }

    this.handleAfterDelete = this.handleAfterDelete.bind(this)
    this.setGroupKey = this.setGroupKey.bind(this)
    this.setReadOnly = this.setReadOnly.bind(this)
    this.handleOnEdit = this.handleOnEdit.bind(this)
    this.handleOnClose = this.handleOnClose.bind(this)
    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onColorClick = this.onColorClick.bind(this)
    this.handleAfterColorAdd = this.handleAfterColorAdd.bind(this)
    this.handleAfterColorUpdate = this.handleAfterColorUpdate.bind(this)
    this.getLoadingStateTemplate = this.getLoadingStateTemplate.bind(this)
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'setDesignID',
      fn: this.setGroupKey,
      args: [{ name: 'designId', type: PluginTypes.int }]
    })
    this.props.registerMethod({
      key: 'setReadOnly',
      fn: this.setReadOnly,
      args: [{ name: 'readOnly', type: PluginTypes.boolean }]
    })

    this.onColorAdd = this.props.registerEvent({
      key: 'onColorAdd',
      fn: this.onColorAdd,
      returnTypes: {
        refreshKey: PluginTypes.fromString('string')
      }
    })

    this.onColorDelete = this.props.registerEvent({
      key: 'onColorDelete',
      fn: this.onColorDelete,
      returnTypes: {
        refreshKey: PluginTypes.fromString('string')
      }
    })

    const {
      settings: { config: { settings: { designId = null, readOnly = false } = {} } = {} } = {}
    } = this.props

    this.setGroupKey({ designId })
    this.setReadOnly({ readOnly })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      settings: {
        config: {
          settings: { designId: userInputDesignId = null } = {},
          data: { designId: designIdColumn = null } = {}
        } = {}
      } = {}
    } = nextProps

    let designId = userInputDesignId

    if (designIdColumn && nextProps.pluginData && nextProps.pluginData[0]) {
      const row = nextProps.pluginData[0]
      designId = row[designIdColumn]
    }

    if (!_.isEqual(designId, this.state.designId)) {
      if (!_.isNil(designId)) {
        this.setGroupKey({ designId })
      }
    }
  }

  onColorAdd() {
    return { refreshKey: uuidv4() }
  }

  onColorDelete() {
    return { refreshKey: uuidv4() }
  }

  setReadOnly({ readOnly }) {
    this.setState({
      readOnly
    })
  }

  setGroupKey({ designId }) {
    if (!designId) {
      return
    }
    const { id, client } = this.props
    this.setState({ pending: true, designId })
    client
      .post(`/data/${id}/Invoke/GetDesignOptions`, { data: { designId } })
      .then((result) => {
        this.setState({ pending: false, colorList: result })
      })
      .catch(() =>
        slvyToast.error({ message: MESSAGES.an_unknown_error_occurred, title: MESSAGES.error })
      )

    this.getDesignOptionsAttributes(designId)
  }

  getDesignOptionsAttributes(designId) {
    return new Promise((resolve, reject) => {
      const { selectedMainColorId } = this.state
      const { id, client } = this.props
      client
        .post(`/data/${id}/Invoke/GetAttributesByDesignId`, {
          data: { designId }
        })
        .then((result) => {
          this.setState(
            {
              designOptionsAttributes: result
            },
            () => {
              if (!_.isNil(selectedMainColorId)) {
                this.onColorClick(selectedMainColorId)
              }
              resolve()
            }
          )
        })
        .catch(() => {
          slvyToast.error({
            message: MESSAGES.an_unknown_error_occurred,
            title: MESSAGES.error
          })
          reject()
        })
    })
  }

  handleAfterColorUpdate(result) {
    const { colorList = [], designId } = this.state
    const { AttributeId = null } = result

    let cloned = _.cloneDeep(colorList)
    cloned = cloned.map((item) => {
      if (!AttributeId) {
        // main
        if (item.MainColorId === result.MainColorId) {
          item = result
        }
      }
      return item
    })

    this.setState({ colorList: cloned }, () => {
      this.getDesignOptionsAttributes(designId)
    })
  }

  handleAfterColorAdd(result, isMainColor = false) {
    const { selectedMainColorId, designId, designOptionsAttributes } = this.state
    const { AttributeId = null } = result

    if (!AttributeId) {
      // main
      this.setState(
        (state) => ({
          colorList: _.uniqBy([result, ...state.colorList], (item) => item.MainColorId)
        }),
        () => this.getDesignOptionsAttributes(designId)
      )
    } else {
      const designOptionsAttributesTemp = _.cloneDeep(designOptionsAttributes)
      if (_.find(designOptionsAttributesTemp, (x) => x.AttributeId === AttributeId)) {
        _.remove(designOptionsAttributesTemp, (x) => x.AttributeId === AttributeId)
      }
      designOptionsAttributesTemp.push(result)

      this.setState(
        {
          designOptionsAttributes: designOptionsAttributesTemp
        },
        () => this.onColorClick(selectedMainColorId)
      )
    }
    if (isMainColor) {
      this.onColorAdd()
    }
  }

  handleAfterDelete(data, isMainColor = false) {
    const { AttributeId = null } = data
    const { designOptionsAttributes } = this.state
    const getFilteredData = (list) =>
      _.filter(list, (item) => item.MainColorId !== data.MainColorId)

    let _designOptionsAttributes = _.cloneDeep(designOptionsAttributes)

    if (!AttributeId) {
      // main
      this.setState(
        (state) => ({
          colorList: getFilteredData(state.colorList),
          designOptionsAttributes: getFilteredData(_designOptionsAttributes)
        }),
        () => {
          this.onColorClick(data.MainColorId)
        }
      )
    } else {
      if (
        _.filter(_designOptionsAttributes, (item) => {
          if (item.MainColorId === data.MainColorId && item.MaterialName === data.MaterialName) {
            return true
          }
        }).length === 1
      ) {
        _designOptionsAttributes = _.map(_designOptionsAttributes, (item) => {
          if (item.AttributeId === data.AttributeId) {
            item.Red = null
            item.Green = null
            item.Blue = null
            item.CMYK = null
            item.CustomName = null
            item.PantoneCode = null
            item.HEX = null
          }
          return item
        })
      } else {
        _designOptionsAttributes = _.filter(
          _designOptionsAttributes,
          (item) => item.AttributeId !== data.AttributeId
        )
      }

      this.setState(
        {
          designOptionsAttributes: _designOptionsAttributes
        },
        () => {
          this.onColorClick(data.MainColorId)
        }
      )
    }
    if (isMainColor) {
      this.onColorDelete()
    }
  }

  handleOnEdit(item, callback = () => {}) {
    const { AttributeId = null } = item
    const isMainColor = !AttributeId
    this.setState(
      {
        modalShow: true,
        itemToEdit: item,
        modalShowColorSearch: false,
        modalShowColorDetails: true,
        isMainColor
      },
      () => {
        callback && callback()
      }
    )
  }

  handleOnClose(callback = () => {}) {
    this.setState(
      {
        modalShow: false,
        itemToEdit: null
      },
      () => callback?.()
    )
  }

  showModal(isMainColor, attributeId, materialTypeId) {
    const { designOptionsAttributes } = this.state
    const item = attributeId
      ? _.find(designOptionsAttributes, (item) => item.AttributeId === attributeId)
      : undefined
    this.setState({
      modalShow: true,
      modalShowColorSearch: true,
      modalShowColorDetails: false,
      isMainColor,
      itemToEdit: item,
      materialTypeID: materialTypeId
    })
  }

  closeModal() {
    this.setState({
      modalShow: false,
      modalShowColorSearch: false,
      modalShowColorDetails: false,
      materialTypeID: 0
    })
  }

  onColorClick(mainColorId) {
    const { designOptionsAttributes } = this.state
    let designColorAttributes = _.filter(designOptionsAttributes, {
      MainColorId: mainColorId
    })

    designColorAttributes = _.groupBy(designColorAttributes, 'MaterialName')
    const sortedSelectedDesignColorAttributes = this.sortColorAttributes(designColorAttributes)
    this.setState({
      selectedMainColorId: mainColorId,
      selectedDesignColorAttributes: sortedSelectedDesignColorAttributes
    })
  }

  sortColorAttributes(selectedDesignColorAttributes) {
    const sortedSelectedDesignColorAttributes = {}
    Object.keys(selectedDesignColorAttributes)
      .sort()
      .forEach((key) => {
        sortedSelectedDesignColorAttributes[key] = selectedDesignColorAttributes[key]
      })
    return sortedSelectedDesignColorAttributes
  }

  getLoadingStateTemplate(backgroundColor, designId, pending) {
    let text = ''
    if (!designId) {
      text = MESSAGES.wait_for_design
    } else if (pending) {
      text = MESSAGES.loading
    }
    return (
      <div
        className="color-palettes-plugin default-scrollbar default-scrollbar-colored p-2 overflow-x-hidden overflow-y-auto fs-xs"
        style={{ backgroundColor }}
      >
        {text}
      </div>
    )
  }

  render() {
    const {
      props: {
        id: pluginId,
        client,
        settings: {
          config: {
            settings: { columnHeightValue = 55, title = '', backgroundColor = '#ffffff' } = {}
          } = {}
        } = {}
      },
      state: {
        designId = null,
        colorList = [],
        pending,
        modalShowColorDetails,
        modalShow,
        modalShowColorSearch,
        isMainColor,
        selectedMainColorId,
        selectedDesignColorAttributes,
        readOnly,
        materialTypeID,
        itemToEdit
      }
    } = this

    const mainColorindex = _.findIndex(colorList, {
      MainColorId: selectedMainColorId
    })
    const selectedColor = colorList[mainColorindex]
    const selectedMainColor = mainColorindex !== -1 ? selectedColor.HEX : '#ffffff'

    if (!designId || pending) {
      return this.getLoadingStateTemplate(backgroundColor, designId, pending)
    }

    return (
      <div
        className="color-palettes-plugin default-scrollbar default-scrollbar-colored p-2 overflow-x-hidden overflow-y-auto fs-xs"
        style={{ backgroundColor }}
      >
        {title && <h3>{title}</h3>}
        {modalShow && !readOnly && (
          <Modal
            client={client}
            closeModal={this.closeModal}
            columnHeightValue={columnHeightValue}
            designId={designId}
            getDesignOptionsAttributes={this.getDesignOptionsAttributes}
            handleAfterColorAdd={this.handleAfterColorAdd}
            handleAfterColorUpdate={this.handleAfterColorUpdate}
            isMainColor={isMainColor}
            itemToEdit={itemToEdit}
            materialTypeID={materialTypeID}
            modalShowColorDetails={modalShowColorDetails}
            modalShowColorSearch={modalShowColorSearch}
            pluginId={pluginId}
            selectedMainColorId={selectedMainColorId}
            show={modalShow}
            onClose={this.handleOnClose}
            onEdit={this.handleOnEdit}
          />
        )}

        <div className="list-group">
          <PantoneListSection
            key={MESSAGES.main_colors}
            isMainColor
            afterDelete={this.handleAfterDelete}
            client={client}
            colorList={colorList}
            columnHeightValue={columnHeightValue}
            designId={designId}
            isMaterialList={false}
            pluginId={pluginId}
            readOnly={readOnly}
            sectionName={MESSAGES.main_colors}
            selectedMainColorId={selectedMainColorId}
            showModal={this.showModal}
            onColorClick={this.onColorClick}
            onEdit={this.handleOnEdit}
          />
        </div>
        <div
          className="selected-design-color-attributes"
          style={{ borderLeft: `4px solid #${selectedMainColor}` }}
        >
          {_.map(selectedDesignColorAttributes, (item, key) => {
            return (
              <div key={key} className="list-group">
                <PantoneListSection
                  key={key}
                  isMaterialList
                  afterDelete={this.handleAfterDelete}
                  client={client}
                  colorList={item}
                  columnHeightValue={columnHeightValue}
                  designId={designId}
                  isMainColor={false}
                  pluginId={pluginId}
                  readOnly={readOnly}
                  sectionName={key}
                  selectedMainColorId={selectedMainColorId}
                  showModal={this.showModal}
                  onColorClick={this.onColorClick}
                  onEdit={this.handleOnEdit}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerMethod: props.registerMethod,
  registerEvent: props.registerEvent,
  id: props.id,
  pluginData: props.pluginData,
  client: props.client,
  settings: props.settings
})

export default createPlugin(ColorPalettes, selectConnectorProps)
