export default `({
    plugins: [{
            ptype: 'cornerIcon',
            extraButton: [
                '->',
                {
                    iconCls: 'x-fa fa-arrows-alt',
                    handler: this.onmaximize,
                    scope: this
                }
            ],
            cfgButton: [{
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                handler: 'onConfigureSpotGrid',
            }]
        },
        {
            ptype: 'cellediting',
            clicksToEdit: 1,
            listeners: {
                beforeedit: function (editor, context) {
                    rec = context.record;

                    if(rec.get('IsLocked'))
                        return false;

                    return rec.get('IsEditable');
                },
                edit: function (editor, context, eOpts) {
                    var me = this;
                    if (context.originalValue == context.value)
                        return;
                    var record = context.record,
                        grid = context.grid.ownerCt
                    grid.setLoading(tt('yükleniyor'));
                    record.save({
                        params: {
                            updateAction: 'DCValueChange'
                        },
                        callback: function (record, operation, success) {
                            // do something whether the save succeeded or failed
                            // @TODO v2 response
                            var result = operation._response.responseJson
                            if (!result.IsSuccess) {
                                Ext.Msg.alert(
                                    tt('Durum'),
                                    tt('Minimum Siparşi Miktarının Altında Bir değer Girdiniz. Minimum sipariş miktarı: ') + result.Message
                                )
                                record.set('OrderRecommendationCurrent', record.previousValues.OrderRecommendationCurrent)
                                record.commit()
                            }
                            
                            if(result.Recommendation)
                            {
                                record.set('OrderRecommendationCurrent', result.Recommendation)
                                record.commit()
                            }

                            var mainView = null,
                                recommView = null,
                                viewModel = null,
                                store = null
                            mainview = grid.up('spuimainview')
                            if (mainview) {
                                recommView = mainview.down('spui-view-common-recom-distribution-main')
                                if (recommView) {
                                    viewModel = recommView.getViewModel()
                                    if (viewModel) {
                                        store = viewModel.get('recomStore')
                                    }
                                }
                                else
                                {
                                    recommView = mainview.down('spui-view-common-recom-main')
                                    if (recommView) {
                                        store = recommView.getRecomStore()
                                    }
                                }
                                if (store) {
                                    store.load()
                                }
                            }
                            grid.setLoading(false);
                        }
                    });
                },
                scope: 'this'
            }
        },
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    multiColumnSort: true,
    viewConfig: {
        emptyText: tt('No record found'),
        getRowClass: function (record, rowIndex, rowParams, store) {
            
            if(record.get('AutoApproved'))
            {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    columns: [{
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa fa-thumbs-up blue',
                toottip: tt('accept'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    Ext.Msg.show({
                        title: tt('Onay'),
                        width: 500,
                        msg: tt('Are you sure you want to approve this record? <br><br>'),
                        buttonText: {
                            yes: tt('Evet'),
                            no: tt('Hayır')
                        },
                        buttons: Ext.Msg.YESNO,
                        icon: Ext.MessageBox.QUESTION,
                        fn: function (response) {
                            if (response === 'yes') {
                                me.onChangeStatus(record, 'Approve')
                            }
                        }
                    })
                },
            }]
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'x-fa  fa-thumbs-down red',
                toottip: tt('decline'),
                isActionDisabled: function (grid, rowIndex, colIndex, item, rec) {
                    return !rec.get('IsEditable');
                },
                handler: function (grid, rowIndex, colIndex, item, e, record) {
                    var me = grid.ownerCt;
                    Ext.Msg.show({
                        title: tt('Onay'),
                        width: 500,
                        msg: tt('Are you sure you want to cancel this record?'),
                        buttonText: {
                            yes: tt('Evet'),
                            no: tt('Hayır')
                        },
                        buttons: Ext.Msg.YESNO,
                        icon: Ext.MessageBox.QUESTION,
                        fn: function (response) {
                            if (response === 'yes') {
                                me.onChangeStatus(record, 'Dismiss')
                            }
                        }
                    })
                },
            }]
        },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: 100,
            filter: {
                type: 'string'
            },
            renderer: function (v) {
                return v * 1;
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: 200,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: 100,
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },
        {
            text: tt('PreStartDate'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: 100,
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('PreEndDate'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: 100,
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('Parent'),
            dataIndex: 'OrderRecommendationParent',
            width: 100,
            format: '000.00',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn',
        },
        {
            text: tt('Current'),
            dataIndex: 'OrderRecommendationCurrent',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn',
            tdCls: 'blue',
            editor: {
                // completeOnEnter: false,
                // If the editor config contains a field property, then
                // the editor config is used to create the Ext.grid.CellEditor
                // and the field property is used to create the editing input field.
                field: {
                    xtype: 'numberfield',
                    allowBlank: false
                }
            },
        }
    ],
    onAccept: function () {},
    onChangeStatus: function (record, newStatus) {
        var data = Ext.encode(record.data);
        var me = this;
        me.up('spui-view-tracking-tracking').setLoading(tt('yükleniyor'));
        Ext.Ajax.request({
            url: Spui.Global.getApiUrl() + '/spui/changeStatus',
            method: 'POST',
            timeout: 300000,
            params: {
                data: data,
                newStatus: newStatus
            },
            success: function (response, opts) {
                me.up('spui-view-tracking-tracking').setLoading(false);
                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
            },
            failure: function (response, opts) {
                console.log('server-side failure with status code ' + response.status);
                me.up('spui-view-tracking-tracking').setLoading(false);
                me.up('spui-view-tracking-navigation-main').onRefrehTracking();
            }
        });
    }
})
`
