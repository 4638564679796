Ext.define('MDM.src.ux.FullScreen.Util', {
  extend: 'Ext.Base',
  singleton: true,
  toggleFullScreen: function (btn) {
    // Test for each of the supported versions of full screen APIs and call
    // either requestFullscreen or cancelFullScreen (or exitFullScreen)
    //  Structure:
    //  Does the incoming target support requestFullscreen (or prefaced version)
    //  if (there is a fullscreen element)
    //      then cancel or exit
    //  else request full screen mode
    var divObj = document.documentElement
    if (divObj.requestFullscreen) {
      if (document.fullScreenElement) {
        document.cancelFullScreen()
      } else {
        divObj.requestFullscreen()
      }
    } else if (divObj.msRequestFullscreen) {
      if (document.msFullscreenElement) {
        document.msExitFullscreen()
      } else {
        divObj.msRequestFullscreen()
      }
    } else if (divObj.mozRequestFullScreen) {
      if (document.mozFullScreenElement) {
        document.mozCancelFullScreen()
      } else {
        divObj.mozRequestFullScreen()
      }
    } else if (divObj.webkitRequestFullscreen) {
      if (document.webkitFullscreenElement) {
        document.webkitCancelFullScreen()
      } else {
        divObj.webkitRequestFullscreen()
      }
    }
  },
  fullscreenEnabled: function () {
    return (
      document.fullscreenEnabled ||
      document.msFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitIsFullScreen ||
      document.webkitFullscreenEnabled
    )
  }
})
