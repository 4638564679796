import { useMemo } from 'react'
import { getCellStyle } from '../../helpers'
import { CustomTooltipView } from '../../components/CustomTooltip'
import { EmptyObject } from '../../types'
import { AutoGroupColumnCellInnerRenderer } from '../../components/CellRenderers'
import {
  AutoGroupColDef,
  AutoGroupColumnDefCellRendererParams,
  AutoGroupColumnDefProps
} from './useAutoGroupColumnDef.types'
import { AG_GRID_ENUMS } from '../../enums'

const { AUTO_GROUP_COLUMN } = AG_GRID_ENUMS

function getAutoGroupColumnDefCellRendererParams({
  suppressCount,
  configColumns,
  cellDisplayRules,
  fieldConfigs,
  handleActionBtnClick,
  editableFields
}: AutoGroupColumnDefCellRendererParams) {
  return {
    suppressCount,
    checkbox: false,
    innerRendererParams: {
      configColumns,
      cellDisplayRules,
      fieldConfigs,
      handleActionBtnClick,
      editableFields
    },
    innerRenderer: AutoGroupColumnCellInnerRenderer
  }
}

function useAutoGroupColumnDef(props: AutoGroupColumnDefProps) {
  const {
    gridRef,
    suppressCount,
    fieldConfigs,
    configColumns,
    cellDisplayRules,
    handleActionBtnClick,
    editableFields,
    rowDisplayRules,
    grouping,
    groupDisplayType
  } = props

  const { group: groupLocale } = gridRef?.current?.api?.getGridOption?.('localeText') || {}

  const autoGroupColumnDef = useMemo((): AutoGroupColDef | EmptyObject => {
    if (!grouping || groupDisplayType !== 'singleColumn') {
      return {}
    }

    return {
      pinned: true,
      lockPinned: true,
      // lockPosition: 'left', // do not use
      suppressMovable: true,
      cellDataType: true,
      enableRowGroup: false,
      rowGroup: false,
      editable: () => false,
      // resizable: false, // ?? Pin cannot resize
      field: AUTO_GROUP_COLUMN,
      colId: AUTO_GROUP_COLUMN,
      tooltipField: AUTO_GROUP_COLUMN,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      tooltipComponent: CustomTooltipView,
      tooltipComponentParams: {
        tooltip: groupLocale
      },
      headerValueGetter: (params) => {
        let headerValue = ''
        params.api.getRowGroupColumns().forEach((col, index) => {
          if (index > 0) {
            headerValue += ' > '
          }
          headerValue += col?.colDef?.headerName ?? '-'
        })
        return headerValue
      },
      customInfo: {
        deletableCondition: '',
        boolean: { trueIcon: '', falseIcon: '', trueColor: '', falseColor: '' },
        isExportable: true
      },
      ...getCellStyle({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customConfigs: {
          isRawFieldEmpty: false,
          rawField: AUTO_GROUP_COLUMN,
          cellStyle: {
            textAlign: 'center',
            backgroundColor: '',
            color: ''
          }
        },
        cellDisplayRules: [],
        rowDisplayRules,
        getCellColorsFromData: false,
        enableCellTextSelection: false
      }),
      cellRendererParams: getAutoGroupColumnDefCellRendererParams({
        suppressCount,
        fieldConfigs,
        configColumns,
        cellDisplayRules,
        handleActionBtnClick,
        editableFields
      })
    }
  }, [
    grouping,
    groupDisplayType,
    groupLocale,
    rowDisplayRules,
    suppressCount,
    fieldConfigs,
    configColumns,
    cellDisplayRules,
    handleActionBtnClick,
    editableFields
  ])

  return { autoGroupColumnDef }
}

export default useAutoGroupColumnDef
