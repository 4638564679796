import React from 'react'

export default function Circle(props) {
  const {
    settings: {
      flexDirection,
      alignItems,
      justifyContent,
      titleVal,
      titleSize,
      descriptinSize,
      textColor,
      progressBarColor,
      animationDuration,
      subTitleSize,
      subtitleVal,
      padding
    } = {},
    data: { circlePercentage, circleDescription } = {},
    combined = false
  } = props

  const style = {
    flexDirection,
    alignItems,
    justifyContent
  }

  const progressContainerStyle = {
    height: !combined
      ? `calc(100% - ${(titleVal ? titleSize : 0) + (subtitleVal ? subTitleSize : 0)}em)`
      : '100%',
    padding: `${padding}em`
  }

  return (
    <div className="chart-wrapper -circle " style={style}>
      {titleVal && !combined && <h1 style={{ fontSize: `${titleSize}em` }}>{titleVal}</h1>}
      <figure className="circle-progress m-0" style={progressContainerStyle}>
        <figcaption style={{ fontSize: `${descriptinSize}em` }}>
          {circlePercentage && <span>{circlePercentage}%</span>}
          {circleDescription && <small>{circleDescription}</small>}
        </figcaption>
        <svg viewBox="0 0 33.83098862 33.83098862">
          <circle
            cx="16.91549431"
            cy="16.91549431"
            r="15.91549431"
            style={{ stroke: progressBarColor }}
          />
          <circle
            cx="16.91549431"
            cy="16.91549431"
            r="15.91549431"
            strokeDasharray={`${circlePercentage},100`}
            style={{ animationDuration: `${animationDuration}ms`, stroke: textColor }}
          />
        </svg>
      </figure>
      {subtitleVal && !combined && <p style={{ fontSize: `${subTitleSize}em` }}>{subtitleVal}</p>}
    </div>
  )
}
