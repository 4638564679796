Ext.define('MDM.src.view.globalapp.Main', {
  extend: 'Ext.panel.Panel',
  requires: ['Ext.calendar.panel.Panel'],
  xtype: 'globalappmain',
  viewModel: 'globalapp-mainviewmodel',
  controller: 'globalapp-maincontroller',
  bodyBorder: true,
  cls: 'globalmdm',
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },
  bodyPadding: 1,
  margin: 4,
  border: true,
  defaults: {
    frame: false,
    bodyPadding: 2,
    border: false
  },
  listeners: {
    boxready: 'onBoxReady'
  },
  items: [
    {
      header: false,
      height: 100,
      margin: '0 0 0 0',
      xtype: 'panel',
      ui: 'light',
      bodyStyle: {
        background: '#e6e6e6'
      },
      layout: {
        type: 'hbox',
        pack: 'start',
        align: 'center'
      },
      items: [
        {
          xtype: 'button',
          text: 'Reset',
          iconCls: 'fa fa-refresh',
          width: 120,
          margin: '0 0 0 20',
          ui: 'btn-inverse',
          handler: 'onRestart',
          bind: {
            hidden: '{readOnlyMode}'
          }
        },
        {
          header: false,
          height: 100,
          flex: 1,
          margin: '0 0 0 0',
          xtype: 'globalapp-progress'
        },
        {
          xtype: 'button',
          iconCls: 'fa fa-rss',
          width: 120,
          margin: '0 20 0 0',
          ui: 'btn-inverse',
          handler: 'onPublish',
          bind: {
            text: '{PublishText}',
            disabled: '{canPublish}',
            hidden: '{readOnlyMode}'
          }
        }
      ]
    },
    {
      header: false,
      flex: 2,
      xtype: 'container',
      margin: '0 0 2 0',
      layout: 'card',
      bodyStyle: {
        background: 'transparent'
      },
      reference: 'wizard',
      tbar: [],
      items: [
        {
          xtype: 'globalapp-setupdate',
          flex: 2
        },
        {
          xtype: 'tabpanel',
          cls: 'rmdtabpanel',
          border: true,
          listeners: {
            activate: function (el) {
              var DPIMain = el.down('globalapp-dpi-main')
              DPIMain.fireEvent('activate', DPIMain)
              var DPITree = el.down('globalapp-dpi-tree')
              DPITree.fireEvent('activate', DPITree)
            }
          },
          items: [
            {
              xtype: 'globalapp-dpi-main',
              title: 'DPI Hierarchies',
              iconCls: 'fa fa-cubes'
            },
            {
              xtype: 'globalapp-dpi-tree',
              title: 'DPI Hierarchies Tree View',
              iconCls: 'fa fa-sitemap'
            }
          ]
        },
        {
          xtype: 'tabpanel',
          cls: 'rmdtabpanel',
          border: true,
          listeners: {
            activate: function (el) {
              var DPIMain = el.down('globalapp-geogrid-main')
              DPIMain.fireEvent('activate', DPIMain)
              var DPITree = el.down('globalapp-geo-main')
              DPITree.fireEvent('activate', DPITree)
            }
          },
          items: [
            {
              xtype: 'globalapp-geogrid-main',
              title: 'Geo Hierarchies',
              iconCls: 'fa fa-table'
            },
            {
              xtype: 'globalapp-geo-main',
              title: 'Geo Hierarchies Tree View',
              iconCls: 'fa fa-sitemap'
            }
          ]
        },
        {
          xtype: 'globalapp-time-main'
        },
        {
          xtype: 'globalapp-publish-main'
        }
      ]
    }
  ],
  bbar: [
    '->',
    {
      itemId: 'move-prev',
      text: '« Previous',
      handler: 'showPrevious',
      disabled: true
    },
    {
      itemId: 'move-next',
      text: 'Next »',
      handler: 'showNext'
    }
  ]
})
