import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'
import { createPortal } from 'react-dom'
import { slvyToast } from '../../../../components'
import Hierarchies from './Hierarchies'
import { makeRequest } from '../../utils'
import { getPageOffset } from '../../../../helpers/index'
import { MESSAGES } from '../../messages'
import OutsideClickHandler from '../../OutsideClickHandler'
import { OverlayTrigger } from '..'

const portalTarget = document.body
const breadCrumbText = 'Select Below'

class HierarchyTree extends Component {
  constructor(props) {
    super(props)
    const { pendingCount = 0 } = props
    this.state = {
      canGoToLastLevel: true,
      hierarchies: [],
      isLoadingHierarchies: false,
      rootClose: false,
      selectedBreadcrumbs: [],
      pendingCount
    }
    this.dpHierarchyTreePointerRef = createRef()
    this.noMoreChildHierarchy = false
  }
  
  componentDidMount() {
    this._selectedBreadcrumbsBackUp = _.cloneDeep(this.props.selectedBreadcrumbs)
    
    this.setState({
      canGoToLastLevel: this.props.canGoToLastLevel,
      selectedBreadcrumbs: this.props.selectedBreadcrumbs
    })
    
    window.addEventListener('filterApplied', this.filterApplied)
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props, nextProps)) {
      this.setState({
        canGoToLastLevel: nextProps.canGoToLastLevel,
        selectedBreadcrumbs: nextProps.selectedBreadcrumbs
      })
      if ('pendingCount' in nextProps && nextProps.pendingCount !== this.props.pendingCount) {
        this.setState({ pendingCount: nextProps.pendingCount })
      }
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('filterApplied', this.filterApplied)
    
    const { saveHierarchyTreeSettings = () => {} } = this.props
    saveHierarchyTreeSettings({ selectedKeys: [], selectedBreadcrumbs: [], isVisible: false })
  }
  
  filterApplied = () => {
    this.noMoreChildHierarchy = false
    this.setState({
      hierarchies: []
    })
  }
  
  onHierarchyChange(hierarchy, currentIndex, fromBreadcrumb, isChild, isGetPageData = false) {
    if (this.state.rootClose) {
      this.setState({ rootClose: false })
    }
    this.getHierarchyTree(hierarchy, currentIndex, fromBreadcrumb, isChild, isGetPageData)
  }
  
  getHierarchyTree(hierarchy, currentIndex, fromBreadcrumb, isChild, isGetPageData) {
    const {
      props: {
        defaultActionParams: { payload = {} },
        getBaseRequestParameters = () => {},
        mergeLeftFieldsAndKeys = () => {},
        onReloadViewClick = () => {},
        saveHierarchyTreeSettings = () => {},
        selectedBreadcrumbs = []
      }
    } = this
    
    this.setState({
      isLoadingHierarchies: false,
      hierarchies: []
    })
    
    const _selectedBreadcrumbs = _.cloneDeep(selectedBreadcrumbs)
    
    const shouldOmitList = [
      'BaselineScenarioId',
      'MetricFields',
      'MfpScenarioId',
      'WorkInSubsetEnabled'
    ]
    let body = _.omit(getBaseRequestParameters(), shouldOmitList)
    
    const isSiblingsRequest = !isChild
    
    if (isSiblingsRequest) {
      if (fromBreadcrumb) {
        _selectedBreadcrumbs.splice(currentIndex, _selectedBreadcrumbs.length)
      }
    } else if (hierarchy !== breadCrumbText) {
      _selectedBreadcrumbs.push(hierarchy)
    }
    
    const _settings = isGetPageData
      ? {
        selectedLeftKeys: _selectedBreadcrumbs,
        mergedLeftFieldsAndKeys: mergeLeftFieldsAndKeys(_selectedBreadcrumbs)
      }
      : {}
    
    if (isGetPageData) {
      this._selectedBreadcrumbsBackUp = _.cloneDeep(_selectedBreadcrumbs)
    }
    
    saveHierarchyTreeSettings(
      {
        ..._settings,
        selectedBreadcrumbs: _selectedBreadcrumbs
      },
      () => {
        this.noMoreChildHierarchy = body.LeftFields.length - _selectedBreadcrumbs.length < 2
        
        if (isGetPageData) {
          onReloadViewClick()
          return false
        }
        
        this.setState({
          isLoadingHierarchies: true
        })
        
        body = {
          ...body,
          LeftHierarchy: [..._selectedBreadcrumbs],
          RequestedLevel: body.LeftFields[_selectedBreadcrumbs.length]
        }
        
        makeRequest({
          payload: {
            ...payload,
            requestMethod: 'post',
            method: 'GetHierarchyTree',
            body: {
              ...body
            }
          }
        }).then((response) => {
          if (response.data) {
            const { data: { Result = [], Error = [] } = {} } = response
            if (!Error.length) {
              this.setState({
                hierarchies: Result,
                isLoadingHierarchies: false
              })
            }
          } else {
            this.setState({
              isLoadingHierarchies: false
            })
            slvyToast.warning({
              message: MESSAGES.there_has_been_an_error,
              options: { containerId: 'dp' }
            })
          }
        })
      }
    )
  }
  
  hideOverlay() {
    this.goBackHierarchies(() => {
      this.setState({ rootClose: true })
    })
  }
  
  goBackHierarchies($callback) {
    if (!_.isEqual(this.props.selectedBreadcrumbs, this._selectedBreadcrumbsBackUp)) {
      this.props.saveHierarchyTreeSettings(
        {
          selectedBreadcrumbs: this._selectedBreadcrumbsBackUp
        },
        () => {
          const body = this.props.getBaseRequestParameters()
          this.noMoreChildHierarchy =
            body.LeftFields.length - this._selectedBreadcrumbsBackUp.length < 2
          $callback()
        }
      )
    } else {
      $callback()
    }
  }
  
  getPointerStyle() {
    if (this.dpHierarchyTreePointerRef.current) {
      const {
        top = 0,
        left = 0,
        width = 0,
        height = 0
      } = this.dpHierarchyTreePointerRef.current.getBoundingClientRect()
      
      const pageOffset = getPageOffset()
      
      return { top: top + height + pageOffset.y, left: width ? left : left - 50 - pageOffset.x }
    }
    return {}
  }
  
  render() {
    const {
      state: {
        canGoToLastLevel,
        hierarchies,
        isLoadingHierarchies,
        rootClose,
        selectedBreadcrumbs = [],
        pendingCount = 0
      }
    } = this
    
    const breadCrumbList = [...selectedBreadcrumbs]
    
    const style = this.getPointerStyle()
    
    const getHierarchiesTpl = (pendingCount) => {
      return pendingCount
        ? null
        : createPortal(
            <div onClick={(e) => e.stopPropagation()}>
              <div className="slvy-dp-popover-overlay" />
              <OutsideClickHandler onOutsideClick={() => this.hideOverlay()}>
                <Hierarchies
                  breadCrumbList={breadCrumbList}
                  canGoToLastLevel={canGoToLastLevel}
                  hierarchies={hierarchies}
                  isLoading={isLoadingHierarchies}
                  noMoreChildHierarchy={this.noMoreChildHierarchy}
                  style={style}
                  onHierarchyChange={this.onHierarchyChange.bind(this)}
                />
              </OutsideClickHandler>
            </div>,
            portalTarget
          )
    }
    
    const getContentTmp = (_isEnabled) => (
      <ol
        className={`breadcrumb mb-0 p-0 rounded-0 bg-transparent ${
          _isEnabled ? '' : '-not-allowed -disabled'
        }`}
      >
        {[...breadCrumbList].map((breadCrumb, breadCrumbIndex) => {
          return (
            <li
              key={breadCrumbIndex}
              className="breadcrumb-item"
              onClick={
                _isEnabled
                  ? this.onHierarchyChange.bind(
                    this,
                    breadCrumb,
                    breadCrumbIndex,
                    true,
                    false,
                    false
                  )
                  : () => {}
              }
            >
              {breadCrumbIndex === breadCrumbList.length - 1 ? (
                <strong className="fw-bold">{breadCrumb}</strong>
              ) : (
                <span>{breadCrumb}</span>
              )}
            </li>
          )
        })}
        <li
          ref={this.dpHierarchyTreePointerRef}
          className={cx('breadcrumb-item', {
            'no-more-child-hierarchy': this.noMoreChildHierarchy
          })}
          onClick={
            _isEnabled
              ? this.onHierarchyChange.bind(
                this,
                breadCrumbText,
                breadCrumbList.length - 1,
                true,
                true,
                false
              )
              : () => {}
          }
        >
          <span>{breadCrumbText}</span>
          {(hierarchies.length > 0 || isLoadingHierarchies) && !rootClose
            ? getHierarchiesTpl()
            : null}
        </li>
      </ol>
    )
    
    const _msg = `${MESSAGES.there_are_pending_updates} ${MESSAGES.please_wait_for_them_to_be_completed}`
    
    return (
      <div className="dp-hierarchy-tree-wrapper">
        <div className="-breadcrumb-ctn">
          <OverlayTrigger shouldWrap={Boolean(pendingCount)} tooltip={_msg}>
            {getContentTmp(!pendingCount)}
          </OverlayTrigger>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    defaultActionParams: state.defaultActionParams.defaultActionParams,
    mfpSettings: state.mfpSettings.mfpSettings
  }
}

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(HierarchyTree)
