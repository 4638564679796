import CustomTooltip from '../../../components/CustomTooltip'
import { getTooltipComponent } from '../index'
import { TcellDisplayRules } from '../../../types'
import { cellDisplayRules as defaultCellDisplayRules } from '../../__tests__/mocks'

function getCellDisplayRules() {
  return defaultCellDisplayRules as TcellDisplayRules
}

describe('AgGrid:Helpers:Tooltip:getTooltipComponent', () => {
  let cellDisplayRules = getCellDisplayRules()
  const tooltipField = 'col1'
  const customConfig = { cellTooltip: 'col' }
  const formatParams = { getFormattedValue: () => '', formattedFields: [] }

  beforeEach(() => {
    cellDisplayRules = getCellDisplayRules()
  })

  it('should return an object that have tooltipField, tooltipComponent, tooltipComponentParams keys', () => {
    const result = getTooltipComponent(customConfig, tooltipField, formatParams, cellDisplayRules)
    expect(result).toHaveProperty('tooltipField')
    expect(result).toHaveProperty('tooltipComponent')
    expect(result).toHaveProperty('tooltipComponentParams')
  })

  it('should tooltipComponentParams have cellTooltip, formatParams, cellDisplayRules keys', () => {
    const result = getTooltipComponent(customConfig, tooltipField, formatParams, cellDisplayRules)
    expect(result.tooltipComponentParams).toHaveProperty('cellTooltip')
    expect(result.tooltipComponentParams).toHaveProperty('formatParams')
    expect(result.tooltipComponentParams).toHaveProperty('cellDisplayRules')
  })

  it('should return an object that must be created with the given parameters', () => {
    const result = getTooltipComponent(customConfig, tooltipField, formatParams, cellDisplayRules)
    expect(result).toEqual({
      tooltipField,
      tooltipComponent: CustomTooltip,
      tooltipComponentParams: {
        cellTooltip: customConfig.cellTooltip,
        formatParams,
        cellDisplayRules
      }
    })
  })
})
