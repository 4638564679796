import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { SlvyDeleteActionTypes } from './SlvyDeleteAction.types'

const SlvyDeleteAction = ({
  header,
  body,
  onDelete,
  onCancel,
  btnStyle
}: SlvyDeleteActionTypes) => {
  const overlay = (
    <Popover id="slvyDeleteActionPopover">
      <Popover.Header as="h6" className="mb-0">
        {header}
      </Popover.Header>
      <Popover.Body className="m-0 py-2">
        {body}
        <div className="d-flex gap-2 mt-2 justify-content-end">
          <Button size="sm" variant="danger" onClick={onDelete}>
            Delete
          </Button>
          <Button size="sm" variant="outline-dark" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  )
  return (
    <OverlayTrigger rootClose overlay={overlay} placement="top" trigger="click">
      <Button
        data-testid="slvy-delete-action"
        size="xs"
        style={btnStyle}
        title={header}
        variant="danger"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <i className="fa fa-trash-o" />
      </Button>
    </OverlayTrigger>
  )
}

export default SlvyDeleteAction
