export default () => {
  return {
    title: 'Image Box',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Image Box Settings',
        properties: {
          label: {
            type: 'string',
            title: 'Label'
          },
          imageurl: {
            type: 'string',
            title: 'Image Url'
          },
          keepratio: {
            type: 'string',
            title: 'Ratio',
            default: 'Keep-Ratio',
            enum: ['Stretch', 'Keep-Ratio', 'Cover'],
            required: true
          },
          padding: {
            type: 'integer',
            title: 'Padding',
            format: 'range',
            minimum: 0,
            maximum: 100,
            default: 0
          },
          paddingVal: {
            type: 'integer',
            title: ' ',
            template: '{{paddingVal}}',
            watch: {
              paddingVal: 'settings.padding'
            }
          },
          backgroundcolor: {
            type: 'string',
            format: 'Color',
            title: 'Background Color',
            default: '#FFFFFF'
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          imageurl: {
            type: 'string',
            title: 'Image Url',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
