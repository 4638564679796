import { getAggFunc } from '../agg'
import {
  customizedAggDateFuncs,
  customizedAggColumnTypeFuncs,
  customizedAggBooleanFuncs
} from '../../../constants'

import { IGetAggFuncProps } from '../agg.types'

const getAggFuncParams = (): IGetAggFuncProps => {
  return {
    dataType: 'string',
    columnType: 'simple',
    aggFunc: 'sum',
    customAggregation: ''
  }
}

describe('AgGrid:Helpers:Agg:getAggFunc', () => {
  let params = getAggFuncParams()

  beforeEach(() => {
    params = getAggFuncParams()
  })

  it('should return an object that contains aggFunc', () => {
    const result = getAggFunc(params)
    expect(result).toHaveProperty('aggFunc')
  })

  it('should return an null when aggFunc is not defined', () => {
    const { aggFunc, ...rest } = params
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getAggFunc(rest)
    expect(result.aggFunc).toBe(null)
  })

  it('should return custom aggregation function if customAggregation is provided', () => {
    params.customAggregation = 'custom agg func'
    const result = getAggFunc(params)
    expect(result.aggFunc).toBe('custom agg func')
  })

  it('should return customized aggregation function based on columnType', () => {
    params.columnType = 'progress'
    const result = getAggFunc(params)
    expect(result.aggFunc).toBe(customizedAggColumnTypeFuncs.sum)
  })

  it('should return customized aggregation function based on dataType for datetime', () => {
    params.aggFunc = 'min'
    params.dataType = 'datetime'
    const result = getAggFunc(params)
    expect(result.aggFunc).toBe(customizedAggDateFuncs.min)
  })

  it('should return customized aggregation function based on dataType for bool', () => {
    params.aggFunc = 'max'
    params.dataType = 'bool'
    const result = getAggFunc(params)
    expect(result.aggFunc).toBe(customizedAggBooleanFuncs.max)
  })

  it('should return given agg func as fallback scenario', () => {
    params.aggFunc = 'avg'
    params.dataType = 'int'
    const result = getAggFunc(params)
    expect(result.aggFunc).toBe(params.aggFunc)
  })
})
