import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import './index.scss'

import highchartsMore from 'highcharts/highcharts-more'
import highchartsSolid from 'highcharts/modules/solid-gauge'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsNodataDisplay from 'highcharts/modules/no-data-to-display'

highchartsMore(Highcharts)
highchartsSolid(Highcharts)
highchartsExporting(Highcharts)
highchartsNodataDisplay(Highcharts)

const config = {
  chart: {
    type: 'gauge',
    plotBackgroundColor: null,
    plotBackgroundImage: null,
    plotBorderWidth: 0,
    plotShadow: false,
    backgroundColor: null
  },

  credits: {
    enabled: false
  },

  title: {
    text: '',
    margin: 0,
    style: { color: '#000000' }
  },
  subTitle: {},
  pane: {
    startAngle: -150,
    endAngle: 150,
    background: [
      {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '109%'
      },
      {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '107%'
      },
      {
        // default background
      },
      {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }
    ]
  },

  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        color: '#000000'
      }
    },
    gauge: {
      dial: {
        backgroundColor: null
      },
      pivot: {
        borderColor: null,
        backgroundColor: null
      }
    }
  },
  exporting: {
    enabled: false,
    buttons: {
      contextButton: {
        symbolStroke: '#666666',
        theme: {
          fill: '#ffffff',
          padding: 5,
          stroke: 'none'
        }
      }
    }
  },
  // the value axis
  yAxis: {
    min: 0,
    max: 240,

    minorTickInterval: 'auto',
    minorTickWidth: 1,
    minorTickLength: 10,
    minorTickPosition: 'inside',
    minorTickColor: '',

    tickPixelInterval: 30,
    tickWidth: 2,
    tickPosition: 'inside',
    tickLength: 10,
    tickColor: '',
    labels: {
      step: 2,
      rotation: 'auto'
    },
    title: {
      text: '',
      style: { color: '#666666' }
    },
    plotBands: []
  },
  series: [
    {
      name: '',
      data: [],
      tooltip: {
        pointFormat: '',
        valueSuffix: ''
      },
      events: {}
    }
  ]
}

class HighchartGauge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      subTitle: null
    }
    this.handlePointClick = this.handlePointClick.bind(this)
  }

  componentDidMount() {
    this.handlePointClick = this.props.registerEvent({
      key: 'handlePointClick',
      fn: this.handlePointClick.bind(this),
      returnTypes: _.transform(
        this.props.settings.query.fields,
        (result, field = {}) => {
          const { dataType = '' } = field
          result[field.fieldName] = PluginTypes.fromString(dataType)
        },
        {}
      )
    })

    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setSubTitle',
      fn: this.setSubTitle.bind(this),
      args: [{ name: 'subTitle', type: PluginTypes.string }]
    })
  }

  setTitle({ title }) {
    if (this.state.title !== title) {
      this.setState({ title })
    }
  }

  setSubTitle({ subTitle }) {
    if (this.state.subTitle !== subTitle) {
      this.setState({ subTitle })
    }
  }

  handlePointClick() {
    return this.props.pluginData[0]
  }

  render() {
    const {
      settings: {
        config: {
          general: { export: exportable = false } = {},
          data: {
            seriesName: seriesNameField,
            value: valueField,
            minValue: minValueField,
            maxValue: maxValueField,
            color: colorConfigurationData = []
          } = {},
          color: colorConfiguration = [],
          chart: {
            title,
            titleColor,
            subTitle,
            subTitleColor,
            backgroundColor,
            outerBackgroundColor
          } = {},
          rangeAxis: {
            tickColor = '#666',
            minorTickColor = '#666',
            minorTickWidth = 1,
            dialColor = '#000000'
          } = {}
        } = {}
      } = {},
      pluginData = [],
      getFormattedValue
    } = this.props
    config.pane.background[3] = { backgroundColor }
    config.chart.backgroundColor = outerBackgroundColor
    const { title: titleFromState, subTitle: subTitleFromState } = this.state
    config.title.text = titleFromState || title
    config.title.style.color = titleColor
    config.yAxis.title.text = subTitleFromState || subTitle
    config.yAxis.title.style.color = subTitleColor
    config.yAxis.plotBands = colorConfiguration
    config.yAxis.tickColor = tickColor
    config.yAxis.minorTickColor = minorTickColor
    config.yAxis.minorTickWidth = minorTickWidth
    config.plotOptions.gauge.pivot.borderColor = dialColor
    config.plotOptions.gauge.pivot.backgroundColor = dialColor
    config.plotOptions.gauge.dial.backgroundColor = dialColor
    config.plotOptions.series.dataLabels.color = dialColor
    config.exporting.enabled = exportable
    config.exporting.buttons.contextButton.theme.fill = outerBackgroundColor
    config.exporting.buttons.contextButton.symbolStroke = titleColor

    if (_.size(pluginData) > 0) {
      const firstDataRow = pluginData[0]

      if (_.size(colorConfigurationData) > 0) {
        const colorConfigurationDataCloned = _.cloneDeep(colorConfigurationData)
        config.yAxis.plotBands = _.transform(
          colorConfigurationDataCloned,
          (res, colorConfig) => {
            const { from: fromField, to: toField, color } = colorConfig
            if (_.has(firstDataRow, fromField) && _.has(firstDataRow, toField)) {
              const fromValue = firstDataRow[fromField]
              const toValue = firstDataRow[toField]
              res.push({ from: fromValue, to: toValue, color })
            }
          },
          []
        )
      }

      if (minValueField && _.has(firstDataRow, minValueField)) {
        config.yAxis.min = firstDataRow[minValueField]
      }

      if (maxValueField && _.has(firstDataRow, maxValueField)) {
        config.yAxis.max = firstDataRow[maxValueField]
      }
    }

    config.series[0].data = []
    _.forEach(pluginData, (row) => {
      if (valueField && _.has(row, valueField)) {
        const formattedValue = getFormattedValue(valueField, row[valueField])
        config.series[0].name = row[seriesNameField]
        config.series[0].data.push(Number(formattedValue))
        config.series[0].tooltip = { pointFormat: '{series.name}: {point.y}' }
      }
    })
    config.series[0].events.click = this.handlePointClick

    return (
      <div className="highcharts-container">
        <HighchartsReact highcharts={Highcharts} options={config} updateArgs={[true]} />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(HighchartGauge, selectConnectorProps)
