import { useState, memo, useCallback } from 'react'
import { Card } from 'react-bootstrap'
import _ from 'lodash'
import { PluginTypes } from '../../../../../../BasePlugin'
import NoVariables from '../NoVariables'
import { sortOptions, transformOptions } from './helpers.ts'
import { useUniqueId } from '../../../../../../hooks'
import FormHeading from '../FormHeading'
import SlvyFormCheck from '../../../../../../components/SlvyFormCheck'
import MethodRow from './MethodRow'
import { IMethodsProps } from './Methods.types'
import { emptyObject } from '../../../../constants'
import WorkflowInformation from '../WorkflowInformation'

const Methods = ({
  assignees = emptyObject,
  id,
  title,
  parentKey,
  methodParams,
  variables,
  toggleActives,
  onChange
}: IMethodsProps) => {
  const [uniqueId] = useUniqueId()
  const checkboxId = `checkbox-${uniqueId}`

  const [isActive, setIsActive] = useState(() => !_.isEqual(assignees, emptyObject))

  const handleReturnVariableChange = useCallback(
    (newValue: string, key: string) => {
      onChange({ id, newValue, parentKey, key, type: 'methods' })
    },
    [id, onChange, parentKey]
  )

  const handleChangeStatus = () => {
    setIsActive((prev: boolean) => !prev)
    onChange({
      id,
      newValue: '',
      parentKey,
      key: '',
      type: 'methods',
      shouldRemoveAll: true
    })
  }

  const hasMethodParams = Object.keys(methodParams ?? {}).length > 0

  if (toggleActives && !isActive) {
    return null
  }

  return (
    <Card className="mb-2" data-testid="methods-card">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <SlvyFormCheck
          reverse
          checked={isActive}
          formCheckClass="w-100 d-flex justify-content-between"
          inputId={checkboxId}
          label={title}
          size="sm"
          type="switch"
          variant="success"
          onChange={handleChangeStatus}
        />
        <WorkflowInformation title={title} />
      </Card.Header>
      {isActive ? (
        <Card.Body>
          <div className="form-horizontal">
            {hasMethodParams ? (
              <>
                <FormHeading />
                {_.map(methodParams, (param, paramKey) => {
                  // @ts-ignore
                  const { oneOf: { type: secondType } = emptyObject } =
                    PluginTypes.fromString(param.type) || emptyObject
                  const options = sortOptions(transformOptions(variables, param, secondType))
                  const { [paramKey]: value = '' } = assignees
                  return (
                    <MethodRow
                      key={id + paramKey}
                      noRowMarginBottom={Object.keys(methodParams ?? {}).pop() === paramKey}
                      options={options}
                      paramKey={paramKey}
                      paramType={param.type}
                      value={value}
                      onChange={handleReturnVariableChange}
                    />
                  )
                })}
              </>
            ) : (
              <NoVariables />
            )}
          </div>
        </Card.Body>
      ) : null}
    </Card>
  )
}

export default memo(Methods)
