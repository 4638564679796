import React from 'react'

import { Button as BootstrapButton } from 'react-bootstrap'
import styled from 'styled-components'

const Wrp = styled.div`
  &:hover {
    color: ${({ iconHoverColor }) => iconHoverColor};
  }
`

export default class Button extends React.Component {
  render() {
    const {
      disabled = false,
      params: { environment = '' },
      settings: {
        config: {
          buttonSettings: {
            show = true,
            text = 'PDF',
            style = 'primary',
            icon = 'fa-file-pdf-o',
            iconPosition = 'Left',
            iconHoverColor = 'black'
          } = {}
        } = {}
      } = {}
    } = this.props

    if (!show && environment !== 'Configuration') return null

    const iconClassName = `fa ${icon}`

    const iconItem = <i className={iconClassName} />

    const iconHover = style !== 'link' ? 'inherit' : iconHoverColor

    const buttonClass = `buttonFilter${show ? '' : ' btn-hide'}`

    return (
      <div className="buttonPlugin">
        <BootstrapButton
          className={buttonClass}
          disabled={disabled}
          type="button"
          variant={style}
          onClick={this.props.onClick}
        >
          <Wrp className="btn-plugin-wrp" iconHoverColor={iconHover}>
            {iconPosition === 'Left' ? iconItem : null}
            {` ${text} `}
            {iconPosition === 'Right' ? iconItem : null}
          </Wrp>
        </BootstrapButton>
      </div>
    )
  }
}
