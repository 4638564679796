import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LoadTableState = {
  selectedRow: string | undefined
}

const initialState: LoadTableState = {
  selectedRow: undefined
}

const loadTableSlice = createSlice({
  name: 'loadTable',
  initialState,
  reducers: {
    setSelectedRow(state, action: PayloadAction<LoadTableState['selectedRow']>) {
      state.selectedRow = action.payload
    }
  }
})

export default loadTableSlice
export type { LoadTableState }
