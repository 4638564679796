import React, { useEffect } from 'react'
import { Form, Button, ButtonGroup } from 'react-bootstrap'
import cx from 'classnames'
import { getProductName } from '../../../../utils'
import { isEmpty } from 'lodash'
import styles from './index.module.scss'

export default function ProductionAdjustment({
  products,
  onMount,
  onChangeProduction,
  onClickAvailableValue,
  onClickRemoveProductBtn
}) {
  useEffect(onMount, [])

  function handleClickAvailableValue(value, productIndex, availableValueIndex) {
    onClickAvailableValue(value, productIndex, availableValueIndex)
  }

  function handleChangeProduction(event, productIndex) {
    const value = parseInt(event.target.value, 10)
    const isNumber = !isNaN(value)
    const production = isNumber ? value : 0

    onChangeProduction(production, productIndex)
  }

  function handleClickRemoveBtn(productIndex) {
    onClickRemoveProductBtn(productIndex)
  }

  const hasWarnings = products?.some(
    (product) => product.isSelected && product?.moveSummary?.status === 'warning'
  )
  const hasExceptions = products?.some(
    (product) =>
      product.isSelected &&
      (product?.moveSummary?.status === 'warning' || product?.moveSummary?.status === 'error')
  )

  return (
    <div className={styles.productionAdjustment}>
      <div className={styles.productionAdjustmentHeader}>Adjust Production (Optional)</div>
      <div className={styles.productionAdjustmentBody}>
        {products?.length > 0
          ? products
              .filter((product) => product.isSelected && product.type === 'Product')
              .map((product) => {
                return (
                  <div className={styles.productionAdjustmentRow}>
                    {product?.moveSummary?.status === 'warning' ? (
                      <>
                        <span>
                          <Form.Label className={cx('d-flex', styles.productionAdjustmentLabel)}>
                            {product?.moveSummary?.status && (
                              <i
                                title={product?.moveSummary?.message}
                                className={cx(
                                  styles.productionAdjustmentIcon,
                                  {
                                    'fa fa-fw fa fa-exclamation-triangle':
                                      product?.moveSummary?.status === 'warning'
                                  },
                                  {
                                    'fa fa-fw fa fa-times-circle':
                                      product?.moveSummary?.status === 'error'
                                  },
                                  {
                                    'fa fa-fw fa fa-check-circle':
                                      product?.moveSummary?.status === 'success'
                                  }
                                )}
                              />
                            )}
                            {getProductName(product.name)}
                          </Form.Label>
                        </span>
                        <div className="d-flex">
                          <ButtonGroup>
                            {product?.moveSummary?.AvailableValues.map((availableValue, index) => (
                              <Button
                                className={cx(styles.productionAdjustmentBtns, {
                                  [styles.isSelected]: product.selectedAvailableValueIndex === index
                                })}
                                variant="light"
                                onClick={() =>
                                  handleClickAvailableValue(availableValue, product.index, index)
                                }
                              >
                                {availableValue}
                              </Button>
                            ))}
                          </ButtonGroup>
                          {hasExceptions && (
                            <span onClick={() => {}}>
                              <i className="fa fa-fw fa fa-trash-o" />
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <Form.Label
                          className={cx('d-flex', styles.productionAdjustmentLabel, {
                            [styles.hasWarnings]: hasWarnings
                          })}
                        >
                          {product?.moveSummary?.status && (
                            <i
                              title={product?.moveSummary?.message}
                              className={cx(
                                styles.productionAdjustmentIcon,
                                {
                                  'fa fa-fw fa fa-exclamation-triangle':
                                    product?.moveSummary?.status === 'warning'
                                },
                                {
                                  'fa fa-fw fa fa-times-circle':
                                    product?.moveSummary?.status === 'error'
                                },
                                {
                                  'fa fa-fw fa fa-check-circle':
                                    product?.moveSummary?.status === 'success'
                                }
                              )}
                            />
                          )}
                          {getProductName(product.name)}
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Control
                            className={styles.productionAdjustmentInput}
                            value={product.production}
                            onChange={(event) => handleChangeProduction(event, product.index)}
                          />
                          {hasExceptions && (
                            <span onClick={() => handleClickRemoveBtn(product.index)}>
                              <i className="fa fa-fw fa fa-trash-o" title="Remove Product" />
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )
              })
          : null}
      </div>
    </div>
  )
}
