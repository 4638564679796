Ext.define('MDM.src.view.globalapp.Panel.Publish', {
  extend: 'Ext.panel.Panel',
  xtype: 'globalapp-panel-publish',
  ui: 'ligt',
  layout: {
    type: 'vbox',
    align: 'stretch'
  },
  height: 100,
  width: 400,
  border: true,
  items: [
    {
      xtype: 'container',
      flex: 1,
      html: '<h1> Publish </h1>'
    },
    {
      xtype: 'button',
      height: 40,
      text: 'Publish'
    }
  ]
})
