import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import _ from 'lodash'
import { ChartCell } from '../../../components'
import {
  getCoverKey,
  getCoverStyle,
  getMaxProductValue,
  round,
  getMessage,
  getAttachments,
  val,
  editReason,
  relocateScroll,
  findUpdateValue,
  format,
  parse,
  displayOfflineErrorMsg,
  getCoverViolations
} from '../../../utils'

class ChartProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelection: '2',
      attachments: [],
      updatedPeriodId: -1,
      currentReason: {},
      isUserAllowedToChangeProductionQuantity: true
    }
  }

  componentDidMount() {
    const {
      settings: {
        history: {
          componentState: { pushState: { TableProduct: { productDictionary = {} } = {} } = {} } = {}
        } = {}
      } = {},
      product: { Index: productIndex },
      productId = 0,
      isAllowed = () => {}
    } = this.props

    const { currentProductPeriod = 2 } = productDictionary[`${productId}${productIndex}`] || {}
    const isUserAllowedToChangeProductionQuantity = isAllowed('Production/Production Quantity')

    this.setState({
      currentSelection: currentProductPeriod,
      isUserAllowedToChangeProductionQuantity
    })
  }

  clickHandler(pindex, periodId, e) {
    e.preventDefault()

    const { chartHandler } = this.props
    const { currentSelection } = this.state

    if (currentSelection !== pindex) {
      chartHandler(round(pindex))
      this.setState({
        currentSelection: pindex,
        updatedPeriodId: periodId,
        attachments: [],
        currentReason: {}
      })
    }
  }

  handleToggle() {
    this.setState({ show: !this.state.show })
  }

  updateHandler(params, e, extras = {}) {
    return new Promise((resolveHandler) => {
      if (_.isEmpty(extras) && _.isEmpty(e)) {
        this.setState({
          currentReason: {},
          attachments: []
        })
      }

      let {
        connectApi,
        lastRequest: {
          GetRccpProductPeriodResponse: { Request }
        },
        settings: {
          modificationCount = 0,
          stateHandler: {
            selectedParameter: { tableName = 'Production' },
            selectedProductVariant = ''
          },
          productionVariants: {
            defaultProductionVariant = 'Production',
            userOptions: { isEnabled: isUserOptionsEnabled = false } = {}
          } = {},
          isFiltered = false,
          updateReasons = {}
        }
      } = this.props

      let { keyCode, target, target: { value = '' } = {} } = e
      const { isUpdate, productId, periodId } = params
      const { isForce = false, itemValue = value, clickUpdate = false } = extras

      value = parse(value)

      if ((keyCode === 13 && isUpdate) || (clickUpdate && isUpdate)) {
        const set = {
          isopen: true,
          node: target,
          reasondata: updateReasons
        }

        const update = () => {
          const { currentReason: { code: reasonCode, name: reasonName } = {} } = this.state

          selectedProductVariant = !selectedProductVariant
            ? defaultProductionVariant
            : selectedProductVariant
          const productionVariant = isUserOptionsEnabled
            ? selectedProductVariant
            : defaultProductionVariant
          const requestFieldName = findUpdateValue(tableName, productionVariant)

          const req = {
            fieldName: requestFieldName,
            productId,
            periodId,
            newValue: clickUpdate ? itemValue : value,
            rccpConfig: {
              ...(isForce ? { ForceUpdate: true } : {}),
              UpdateReasonCode: reasonCode,
              UpdateReasonDescription: reasonName,
              ModificationCount: modificationCount
            }
          }

          connectApi('UpdateRccpProductPeriod', req)
            .then((res) => {
              const { Description = 'Error', Code = -1, Attachments = [] } = res

              const isAttachments = !_.isEmpty(Attachments)

              if (isAttachments) {
                this.setState({
                  attachments: getAttachments(Attachments, value),
                  updatedPeriodId: periodId
                })
              }
              getMessage(Code, Description).then((ret) => {
                if (ret.isOk) {
                  Request.rccpConfig.ModificationCount = 10000

                  if (isFiltered) {
                    connectApi('GetRccpProductPeriodResponse', Request, 'RccpFilteredProduct').then(
                      () => {
                        target.blur()
                        relocateScroll(productId)
                      }
                    )
                  } else {
                    connectApi('GetRccpProductPeriodResponse', Request).then(() => {
                      target.blur()
                      relocateScroll(productId)
                    })
                  }

                  if (clickUpdate) {
                    this.setState({
                      attachments: [],
                      updatedPeriodId: -1
                    })
                  }
                }
              })
            })
            .catch(() => {
              displayOfflineErrorMsg()
            })
            .finally(resolveHandler)
        }

        if (_.isEmpty(this.state.attachments)) {
          editReason(set, {
            onConfirm: (e, isok, reason) => {
              this.setState({ currentReason: reason }, () => update())
            },
            onReject: resolveHandler
          })
        } else {
          update()
        }
      } else {
        resolveHandler()
      }
    })
  }

  popoverHoverFocus(cover = 0, min = 0, max = 0, coverLabel = '') {
    const { settings: { productInfoSettings = [] } = {} } = this.props
    const coverSettings = _.find(productInfoSettings, ({ tableName }) =>
      _.includes(tableName, 'Cover')
    )
    const { format: formatType = '0,0' } = coverSettings || {}

    cover = format(cover, formatType)
    min = format(min, formatType)
    max = format(max, formatType)

    return (
      <Popover id="popver-cover">
        <ul className="">
          <li>
            {coverLabel || 'Cover'}: {format(cover, formatType)}
          </li>
          <li>Min Cover: {format(min, formatType)}</li>
          <li>Max Cover: {format(max, formatType)}</li>
        </ul>
      </Popover>
    )
  }

  render() {
    let {
      productPeriod,
      product,
      product: { Id: productId = 0 } = product,
      periods = [],
      minCoverLimit = -12,
      maxCoverLimit = 112,
      tableName = 'EndingInventory',
      settings,
      settings: {
        coverSettings: { coverLabel = '', cover = '', minCover = '', maxCover = '' } = {},
        productionVariants: {
          defaultProductionVariant = 'Production',
          userOptions: { isEnabled: isUserOptionsEnabled = false } = {}
        } = {},
        productPlanSettings = [],
        stateHandler: {
          selectedParameter: { Index: SelectedParamIndex = 0 },
          selectedProductVariant = ''
        },
        productInfoSettings = []
      },
      maxProductPeriodValue = getMaxProductValue(tableName, productPeriod, SelectedParamIndex),
      // maxForecastValue = getMaxProductValue('IndependentDemand', productPeriod, SelectedParamIndex)
      coverViolationIndexes
    } = this.props

    const { isUserAllowedToChangeProductionQuantity = true } = this.state

    const productionObj = _.find(productInfoSettings, { tableName: 'Production' }) || {}
    const { format: formatType } = productionObj || {}

    const { currentSelection, attachments, updatedPeriodId } = this.state
    const { tableName: forecastTableName = 'IndependentDemand' } = productPlanSettings[0]

    return (
      <aside className="product-period-chart -chart">
        {_.map(productPeriod, (pitem, pindex) => {
          let {
            [getCoverKey(cover, 'Cover')]: Cover = 0,
            [getCoverKey(maxCover, 'MaxCover')]: MaxCover = 0,
            [getCoverKey(minCover, 'MinCover')]: MinCover = 0,
            PeriodIndex = 0,
            Values = {},
            IssueValues
          } = pitem

          MinCover = MinCover === 'NaN' ? 0 : MinCover
          MaxCover = MinCover === 'NaN' ? 0 : MaxCover

          selectedProductVariant = !selectedProductVariant
            ? defaultProductionVariant
            : selectedProductVariant
          const productionVariant = isUserOptionsEnabled
            ? selectedProductVariant
            : defaultProductionVariant

          const currentPlannedValue = val(Values, productionVariant, SelectedParamIndex)
          const ForecastPercentage = maxProductPeriodValue
            ? 100 -
              (val(Values, forecastTableName, SelectedParamIndex) * 100) / maxProductPeriodValue
            : 100

          const Percentage = maxProductPeriodValue
            ? (val(Values, tableName, SelectedParamIndex) / maxProductPeriodValue) * 100
            : 0
          const { Id: periodId = 0, Visible = false } = periods[PeriodIndex] ?? {}
          const wrapperHeight = (MaxCover - MinCover) * 4
          const dotCover = ((Cover - MinCover) * 100) / (MaxCover - MinCover)
          const dotCoverHeight =
            dotCover > maxCoverLimit
              ? maxCoverLimit
              : dotCover < minCoverLimit
              ? minCoverLimit
              : dotCover
          const isUpdate = true

          const coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
          const coverColor = getCoverStyle(Cover, MinCover, ...coverViolations)

          return (
            Visible && (
              <figure key={pindex} className={pindex === currentSelection ? '-active' : ''}>
                <ChartCell
                  attachments={{ attachments, isActive: updatedPeriodId === periodId }}
                  disabled={!isUserAllowedToChangeProductionQuantity}
                  formatType={formatType}
                  settings={settings}
                  updateHandler={this.updateHandler.bind(this, { isUpdate, productId, periodId })}
                  value={format(currentPlannedValue, formatType)}
                  onClick={this.clickHandler.bind(this, pindex, periodId)}
                  onFocus={() => this.setState({ currentReason: {}, attachments: [] })}
                />

                <section
                  className="-chart-wrp"
                  onClick={this.clickHandler.bind(this, pindex, periodId)}
                >
                  <b
                    className="-forecast"
                    style={{
                      top: `${
                        ForecastPercentage >= 100
                          ? 100
                          : ForecastPercentage < 0
                          ? 0
                          : ForecastPercentage
                      }%`
                    }}
                  />
                  <div className="-cbar" style={{ height: `${Percentage}%` }} />
                </section>

                <OverlayTrigger
                  overlay={this.popoverHoverFocus(Cover, MinCover, MaxCover, coverLabel)}
                  placement="bottom"
                  trigger={['hover', 'focus']}
                >
                  <footer onClick={this.clickHandler.bind(this, pindex, periodId)}>
                    <div
                      className="ft-wrp"
                      style={{ height: wrapperHeight < 10 ? 25 : wrapperHeight }}
                    >
                      <i className="-cover-line -top" />
                      <i
                        className="-cover-dot"
                        style={{
                          bottom: `${dotCoverHeight}%`,
                          backgroundColor: `${coverColor.backgroundColor}`
                        }}
                      />
                      <i className="-cover-line -bottom" />
                    </div>
                  </footer>
                </OverlayTrigger>
              </figure>
            )
          )
        })}
      </aside>
    )
  }
}

const mapStateToProps = (state) => ({
  coverViolationIndexes: state.resourcePlan.coverViolationIndexes
})

export default connect(mapStateToProps)(ChartProduct)
