import { useCallback } from 'react'
import { getCellEditable } from '../../helpers'
import { CUSTOM_FIELDS } from '../../constants'
import { SlvyColDef, SlvyMasterColDef } from '../../types'
import { SetColumnEditableProps } from './useSetColumnEditable.types'

function useSetColumnEditable(props: SetColumnEditableProps) {
  const { gridRef, columnDefs, gridEditableByDefault, gridEditableByState } = props

  const setColumnEditable = useCallback(() => {
    if (!gridRef?.current?.api) {
      return
    }

    const $columnDefs = gridRef.current.api?.getColumnDefs?.() as SlvyColDef[]
    if (!$columnDefs?.length || !columnDefs.length) {
      return
    }

    const newColumnDefs: SlvyColDef[] = []

    const getEditable = (colDef: SlvyColDef) => {
      const { field, customInfo } = colDef

      const disableEditing = {
        editable: () => false
      }

      if (customInfo?.isRawFieldEmpty) {
        return disableEditing
      }

      if (CUSTOM_FIELDS.some((customField) => customField === field)) {
        return disableEditing
      }

      if (!gridEditableByState) {
        return disableEditing
      }

      return getCellEditable({
        customConfigs: colDef.customInfo,
        gridEditableByDefault,
        fieldConfig: colDef.customInfo.fieldConfig
      })
    }

    $columnDefs.forEach((colDef) => {
      newColumnDefs.push({
        ...colDef,
        ...getEditable(colDef)
      })
    })

    // IMPORTANT NOTE:
    // If we don't omit "sort" and "sortIndex", when we click column header to sort in pagination mode
    // onSortChanged event triggered with source "gridOptionsChanged", the options is sort and sortIndex changing below;
    // So, sort feature does not work with pagination, below fixes it
    // http://localhost:3000/Configuration/catalog/64a7bf816b6afd3cd177cba0/store/1/menu/657bff04b0158b87f58f9435/page/658546ae055781395efe6a2a

    // If we don't omit "row" and "rowGroupIndex", when we group a column that has `agSetFilterColumn`
    // then filter that column, then ungroup column, then Clear Filter for that column
    // In this scenario, column grouped automatically. So, below fixes it.
    // https://dragon-dev.solvoyo.com/Configuration/catalog/644a5be02c0f079f38ff05b6/store/1/menu/65c353047837bb86ff9e74f6/page/65c3531b7837bb86ff9e74f7

    const newColumnDefsWithoutSort: SlvyMasterColDef[] = []
    newColumnDefs?.forEach((colDef: SlvyMasterColDef) => {
      if (colDef?.children?.length) {
        const children: SlvyColDef[] = []
        colDef.children.forEach((child) => {
          const { sort, sortIndex, rowGroup, rowGroupIndex, ...rest } = child
          children.push(rest)
        })
        const { sort, sortIndex, rowGroup, rowGroupIndex, ...rest } = colDef
        newColumnDefsWithoutSort.push({ ...rest, children })
      } else {
        const { sort, sortIndex, rowGroup, rowGroupIndex, ...rest } = colDef
        newColumnDefsWithoutSort.push(rest)
      }
    })

    gridRef.current.api.setGridOption('columnDefs', newColumnDefsWithoutSort)
  }, [columnDefs, gridRef, gridEditableByDefault, gridEditableByState])

  return {
    setColumnEditable
  }
}

export default useSetColumnEditable
