import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import { FooterProps } from './Footer.types'

export default function Footer({ children, className, ...props }: FooterProps) {
  const { footer } = useTheme()

  return (
    <div className={cx(footer, className)} {...props}>
      {children}
    </div>
  )
}
