import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { getExportableColumnDefs } from '../../helpers'
import { CreateReportExportInfoProps, ExportColumn } from './useCreateReportExportInfo.types'

function useCreateReportExportInfo(props: CreateReportExportInfoProps) {
  const { formattedFields, reportExportInfo, exportWithoutHeaders, columnDefs } = props

  const createReportExportInfo = useCallback(() => {
    if (exportWithoutHeaders) {
      return
    }

    // Note: Grouping does not work with black popup menu export for Sencha and Ag also
    const exportColumns: ExportColumn[] = getExportableColumnDefs(columnDefs).map(
      (exportableColumnDef) => {
        const {
          field,
          customInfo: { groupHeader },
          headerName = field
        } = exportableColumnDef
        const DisplayName = !isEmpty(headerName) ? headerName : field
        const { formatString } =
          formattedFields.find(({ columnName }) => columnName === field) || {}
        return {
          ColumnName: field as string,
          DisplayName: DisplayName as string,
          FormatString: formatString || '',
          GroupHeader: groupHeader ? `${groupHeader}|${DisplayName}` : ''
        }
      }
    )

    reportExportInfo(exportColumns)
  }, [exportWithoutHeaders, reportExportInfo, formattedFields, columnDefs])

  return { createReportExportInfo }
}

export default useCreateReportExportInfo
