import { updateOne, fetchOne, clearModel } from '../crudoptV3'

const model = 'remoteActions'
const modelRun = 'runRemoteAction'
const modelInfo = 'remoteActionInfo'

export const updateRemoteActions = (catalogId, data = {}) => {
  return updateOne(
    model,
    `/catalog/${catalogId}/remoteAction`,
    { data },
    { operationKey: `remoteaction_update_${catalogId}` }
  )
}

export const runRemoteAction = (catalogId, remoteActionKey, args) => {
  return updateOne(
    modelRun,
    `/data/catalog/${catalogId}/remoteAction/${remoteActionKey}`,
    { data: { arguments: args } },
    {
      method: 'post',
      operationKey: `runRemoteAction_${catalogId}_${remoteActionKey}`,
      key: `${catalogId}_${remoteActionKey}`,
      getRowKey: () => `${catalogId}_${remoteActionKey}`
    }
  )
}

export const getRemote = (catalogId) => {
  return fetchOne(
    modelInfo,
    `/catalog/${catalogId}/remoteactionsinfo`,
    {},
    { collectionKey: { path: '/remoteactionsinfo' }, key: catalogId }
  )
}

export const clearRemoteInfoModel = () => {
  return clearModel(modelInfo)
}
