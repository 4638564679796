require('../src/view/globalapp/model/ConfigModel')
require('../src/view/globalapp/model/EditCal')
require('../src/view/globalapp/model/Event')

/* This is an exception */
require('../src/view/regionapp/model/DPITree')
/**/

/* MODELS */
// DPI
require('../src/view/globalapp/Panel/Dpi/model/DPIFamily')
require('../src/view/globalapp/Panel/Dpi/model/DpiSegment')
require('../src/view/globalapp/Panel/Dpi/model/DPITree')
require('../src/view/globalapp/Panel/Dpi/model/ProductGroup')
require('../src/view/globalapp/Panel/Dpi/MainViewModel')

// GEO
require('../src/view/globalapp/Panel/Geo/model/Tree')
require('../src/view/globalapp/Panel/Geo/MainViewModel')

// GEOGRID
require('../src/view/globalapp/Panel/GeoGrid/model/Country')
require('../src/view/globalapp/Panel/GeoGrid/model/MegaRegion')
require('../src/view/globalapp/Panel/GeoGrid/model/Region')
require('../src/view/globalapp/Panel/GeoGrid/model/SupplySource')
require('../src/view/globalapp/Panel/GeoGrid/MainViewModel')

// PUBLISH
require('../src/view/globalapp/Panel/Publish/model/Change')
require('../src/view/globalapp/Panel/Publish/model/GeoModel')
require('../src/view/globalapp/Panel/Publish/model/MainModel')
require('../src/view/globalapp/Panel/Publish/MainViewModel')

// TIME
require('../src/view/globalapp/Panel/Time/model/TimeModel')
require('../src/view/globalapp/Panel/Time/MainViewModel')

// GENERAL
require('../src/view/globalapp/MainViewmodel')

/* COMPONENTS */
// DPI
require('../src/view/globalapp/Panel/Dpi/Panel/DPIFamily')
require('../src/view/globalapp/Panel/Dpi/Panel/DPISegment')
require('../src/view/globalapp/Panel/Dpi/Panel/ProductGroup')

require('../src/view/globalapp/Panel/Dpi/Form/Main')

require('../src/view/globalapp/Panel/Dpi/Tree')
require('../src/view/globalapp/Panel/Dpi/MainController')
require('../src/view/globalapp/Panel/Dpi/Main')

// GEO
require('../src/view/globalapp/Panel/Geo/Dilaog/Region/Controller')
require('../src/view/globalapp/Panel/Geo/Dilaog/Region/MegaRegion')
require('../src/view/globalapp/Panel/Geo/Dilaog/Region/Window')

require('../src/view/globalapp/Panel/Geo/Panel/EchartsMap')
require('../src/view/globalapp/Panel/Geo/Panel/Map')
require('../src/view/globalapp/Panel/Geo/Panel/MegaRegion')
require('../src/view/globalapp/Panel/Geo/Panel/Tree')

require('../src/view/globalapp/Panel/Geo/MainController')
require('../src/view/globalapp/Panel/Geo/Main')

// GEOGRID
require('../src/view/globalapp/Panel/GeoGrid/Columns/Columns')
require('../src/view/globalapp/Panel/GeoGrid/From/Main')

require('../src/view/globalapp/Panel/GeoGrid/Panel/Country')
require('../src/view/globalapp/Panel/GeoGrid/Panel/MegaRegion')
require('../src/view/globalapp/Panel/GeoGrid/Panel/Region')
require('../src/view/globalapp/Panel/GeoGrid/Panel/SupplySource')

require('../src/view/globalapp/Panel/GeoGrid/MainController')
require('../src/view/globalapp/Panel/GeoGrid/Main')

// PUBLISH
require('../src/view/globalapp/Panel/Publish/Panel/LogGrid')
require('../src/view/globalapp/Panel/Publish/Panel/MainGrid')

require('../src/view/globalapp/Panel/Publish/CalGrid')
require('../src/view/globalapp/Panel/Publish/numberColumn')

require('../src/view/globalapp/Panel/Publish/MainController')
require('../src/view/globalapp/Panel/Publish/Main')

// SETUP
require('../src/view/globalapp/Panel/setup/Calendar')
require('../src/view/globalapp/Panel/setup/Form')
require('../src/view/globalapp/Panel/setup/FormDialog')
require('../src/view/globalapp/Panel/setup/Grid')
require('../src/view/globalapp/Panel/setup/SetupDate')

// TIME
require('../src/view/globalapp/Panel/Time/Panel/Grid')
require('../src/view/globalapp/Panel/Time/Panel/Tree')

require('../src/view/globalapp/Panel/Time/MainController')
require('../src/view/globalapp/Panel/Time/Main')

// PANEL GENERAL
require('../src/view/globalapp/Panel/Progress')
require('../src/view/globalapp/Panel/Publish')
require('../src/view/globalapp/Panel/Start')

// GENERAL
require('../src/view/globalapp/CellEditing')

require('../src/view/globalapp/MainController')

require('../src/view/globalapp/Main')
