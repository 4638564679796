import cx from 'classnames'
import { useTheme } from '../contexts/ThemeContext'
import { FooterHeaderProps } from './FooterHeader.types'

export default function FooterHeader({ children, className, isPinned, style }: FooterHeaderProps) {
  const styles = useTheme()

  return (
    <div
      className={cx(styles.footerHeader, { [styles.isPinned]: isPinned }, className)}
      style={style}
    >
      {children}
    </div>
  )
}
