Ext.define('tuiv2.treeview.DemandTree', {
  extend: 'Ext.tree.Panel',
  xtype: 'demandTree',
  displayField: 'Text',
  reference: 'demandTree',
  columnLines: true,
  rowLines: true,
  cls: 'demandTree',
  syncRowHeight: true,
  checkPropagation: 'both',
  viewConfig: {
    enableTextSelection: true
  },
  config: {
    demandKeys: [],
    userManipulation: false
  },
  columns: [],
  stateful: true,
  getState: function () {
    var me = this,
      state = me.callParent(),
      storeState = me.store.getState()

    state = me.addPropertyToState(state, 'demandKeys', me.getDemandKeys())
    state = me.addPropertyToState(state, 'userManipulation', me.getUserManipulation())
    if (storeState) {
      state.storeState = storeState
    }
    return state
  },
  getStateId: function () {
    var me = this
    var view = me.up('tuiv2mainview')
    var viewModel = view.getViewModel()
    return 'demandHieararchyState-' + viewModel.data.demandHierarchyHash
  },
  applyState: function (state) {
    var me = this,
      demandKeys = state.demandKeys,
      userManipulation = state.userManipulation
    if (demandKeys && demandKeys.length > 0) {
      me.setDemandKeys(demandKeys)
    }
    me.setUserManipulation(userManipulation)
  },
  listeners: {
    itemclick: function (self, record, item, index, eventObj, eOpts) {
      var checkedDemands = [],
        checkedDemandsForEvent = [],
        view = this.up('tuiv2mainView'),
        viewModel = view.getViewModel(),
        demandTotalFields = viewModel.get('demandTotalFields'),
        demandTreeAllColumns = viewModel.get('DemandTreeAllColumns'),
        demandTreeColumns = viewModel.get('demandTreeColumns'),
        map = view.lookupReference('mainMap'),
        nodes

      if (eventObj.getTarget('.x-tree-checkbox', 1, true)) {
        map.getEl().mask('Processing')

        if (self.lockedGrid) {
          nodes = self.lockedGrid.getChecked()
        } else {
          nodes = self.getChecked()
        }

        var controlNodes = []

        var recursive = function (node) {
          if (controlNodes.indexOf(node) > -1) return
          controlNodes.push(node)

          if (node && node.childNodes && node.childNodes.length > 0) {
            recursive(node.childNodes[0])
          } else {
            checkedDemands.push(node.get('ID'))
            checkedDemandsForEvent.push(node.getData())
          }
        }

        nodes.forEach((element) => {
          if (controlNodes.indexOf(element) > -1) return
          controlNodes.push(element)

          var currentNode = element.parentNode

          while (currentNode && currentNode.parentNode) {
            currentNode.set('checked', element.get('checked'))

            currentNode = currentNode.parentNode
          }

          if (element.isLeaf()) {
            checkedDemands.push(element.get('ID'))
            checkedDemandsForEvent.push(element.getData())
          }

          if (element.childNodes && element.childNodes.length > 0) {
            recursive(element.childNodes[0])
          }
        })

        var firstSum = 0
        var secondSum = 0

        if (demandTotalFields.length > 0 && demandTreeColumns) {
          const fields = demandTotalFields.map((field) => field.fieldName)

          const dataIndexes = demandTreeAllColumns.reduce((acc, { text, dataIndex, formatter }) => {
            const index = fields.indexOf(text)
            if (index !== -1) {
              if (formatter && formatter !== '' && formatter.startsWith('number')) {
                formatter = formatter.slice(8, -2)
              }

              return { ...acc, [index]: { dataIndex, formatter } }
            }
            return acc
          }, {})

          nodes.forEach((element) => {
            if (element.data.Leaf === true) {
              firstSum += dataIndexes[0] ? element.data[dataIndexes[0].dataIndex] : null
              secondSum += dataIndexes[1] ? element.data[dataIndexes[1].dataIndex] : null
            }
          })

          var firstFormatTotal =
            dataIndexes[0] && dataIndexes[0].formatter
              ? Ext.util.Format.number(firstSum, dataIndexes[0].formatter)
              : firstSum.toFixed(1)

          var secondFormatTotal =
            dataIndexes[1] && dataIndexes[1].formatter
              ? Ext.util.Format.number(secondSum, dataIndexes[1].formatter)
              : secondSum.toFixed(1)

          viewModel.set({
            firstSum: firstFormatTotal,
            secondSum: secondFormatTotal
          })
        }

        viewModel.set('checkedDemands', checkedDemands)
        view.fireEvent('DemandRowSelected', checkedDemandsForEvent)
        view.onDemandRowSelected(checkedDemandsForEvent)
      }
    },
    staterestore: function () {
      tuiv2.Global.setDemandTreeStructure(this.getDemandKeys())
      tuiv2.Global.setDemandHierarchyIsEmpty(this.getUserManipulation())
    }
  }
})
