import React from 'react'
import _ from 'lodash'

interface IHeaderPageTitle {
  currentScenarios: any[]
  displayPivotState: boolean
  draftPivotState: object
  isVisibleScenarioComparison: boolean
  selectedPivotState: string
  selectedQuickFilterKey: string
  _resetQuickFilterSettings(needReload: boolean): void
}

// @TODO: It seems that all props values are required, so setting a default value for them should be needless
function HeaderPageTitle({
  currentScenarios = [],
  displayPivotState = false,
  draftPivotState = {},
  isVisibleScenarioComparison = false,
  selectedPivotState = '',
  selectedQuickFilterKey = '',
  _resetQuickFilterSettings
}: IHeaderPageTitle) {
  const hasDraftState = !_.isEmpty(draftPivotState)

  return (
    <div className="pageTitle">
      {isVisibleScenarioComparison ? null : (
        <ul>
          {currentScenarios.map(({ currentScenario = {} }, key) => (
            <li key={key}>
              <span>{currentScenario?.Name}</span>
            </li>
          ))}
        </ul>
      )}
      <div className="-title-tags">
        {displayPivotState ? (
          <span className="-pb-title">
            Pivot Configuration:{' '}
            <b>
              {selectedPivotState}
              {hasDraftState ? '-draft' : ''}
            </b>
          </span>
        ) : null}
        {selectedQuickFilterKey ? (
          <span className="-pb-title -with-icon">
            Quick Filter: <b>{selectedQuickFilterKey}</b>
            <i
              className="slvy-ui-icon-times-extra-lt"
              onClick={() => _resetQuickFilterSettings(true)}
            />
          </span>
        ) : null}
      </div>
    </div>
  )
}

export default HeaderPageTitle
