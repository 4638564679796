Ext.define('MDM.src.ux.CellContext', {
  override: 'Ext.grid.CellContext',

  // Uncomment to give this component an xtype
  // xtype : '-cellcontext',
  setColumn: function (col) {
    var me = this,
      colMgr = me.view.getVisibleColumnManager(),
      oldColumn = me.column
    // Maintainer:
    // We MUST NOT update the context view with the column's view because this context
    // may be for an Ext.locking.View which spans two grid views, and a column references
    // its local grid view.
    if (col !== undefined && col !== null) {
      if (typeof col === 'number') {
        me.colIdx = col
        me.column = colMgr.getHeaderAtIndex(col)
      } else if (col.isHeader) {
        me.column = col
        // Must use the Manager's indexOf because view may be a locking view
        // And Column#getVisibleIndex returns the index of the column within its own header.
        me.colIdx = colMgr.indexOf(col)
      }
    }
    if (me.column !== oldColumn) {
      me.generation++
    }
    return me
  }
})
