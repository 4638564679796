import { useCallback } from 'react'
import { objectKeys } from '@/ts/utils'
import { slvyToast } from '@/components'
import { useHandleEdit, useHandleUpdate, useValidateAdding } from '../index'
import { getDataUpdateRequestParams } from '../../helpers'
import { BodyRowRecord, ErrorMessage, LocaleText } from '../../types'
import { HandleSave, HandleSaveReturnType, HandleSaveProps } from './useHandleSave.types'

function useHandleSave(props: HandleSaveProps) {
  const {
    adding,
    additionalArgs,
    AG_GRID_URL,
    columnDefs,
    dataUpdatedEventRef,
    getDeletedRows,
    doesDirectUpdate,
    gridRef,
    isUpdateQuery,
    resetCustomRowInfo,
    resetDeletedRows
  } = props

  const { handleUpdate } = useHandleUpdate({
    additionalArgs,
    AG_GRID_URL,
    gridRef,
    resetCustomRowInfo
  })

  const { handleEdit } = useHandleEdit({
    additionalArgs,
    AG_GRID_URL,
    gridRef,
    resetCustomRowInfo,
    resetDeletedRows
  })

  const { validateAdding } = useValidateAdding({ gridRef, adding, columnDefs })

  const {
    anErrorOccurred: anErrorOccurredLocale,
    fieldIsRequired: fieldIsRequiredLocale,
    youMustHaveChangesToSave: youMustHaveChangesToSaveLocale
  } = (gridRef?.current?.api?.getGridOption?.('localeText') as LocaleText) || {}

  const handleSave = useCallback(
    async ({ updateParameters }: HandleSave): Promise<HandleSaveReturnType> => {
      const { isAddingValid, invalidAddingFields } = validateAdding()

      let addingMessages: string[] = []

      const errorResult: {
        name: 'add' | 'delete' | 'update' | 'edit' | 'general'
        error: ErrorMessage[]
      }[] = []

      const errorMessages: string[] = []

      if (!isAddingValid) {
        // Warn with required fields
        addingMessages = invalidAddingFields.map(({ headerName, field }) => {
          return fieldIsRequiredLocale(headerName ?? field)
        })

        // Start Editing first cell
        const [{ field, rowIndex }] = invalidAddingFields
        gridRef?.current?.api.startEditingCell({
          colKey: field,
          rowIndex
        })

        return { addingMessages, errorMessages, shouldRefresh: false }
      }

      const shouldIncludeUpdateRequest = isUpdateQuery

      const deletedRows = getDeletedRows()
      const recordParams = getDataUpdateRequestParams({
        gridRef,
        deletedRows,
        shouldIncludeUpdateRequest
      })

      const { dirtyColumns, dirtyRecords, addedRecords, deletedRecords } = recordParams

      const extras: BodyRowRecord[] = shouldIncludeUpdateRequest
        ? [{ records: dirtyRecords, type: 0, name: 'edit' }]
        : []

      let payloadBodies: BodyRowRecord[] = [
        { records: addedRecords, type: 1, name: 'add' },
        { records: deletedRecords, type: 2, name: 'delete' },
        ...extras
      ]
      payloadBodies = payloadBodies.filter(({ records }) => records.length)

      if (payloadBodies.length === 0 && !dirtyColumns.length) {
        slvyToast.warning({ message: youMustHaveChangesToSaveLocale })
        return {
          addingMessages: [],
          errorMessages: [],
          shouldRefresh: false
        }
      }

      const results = await handleEdit({
        shouldIncludeUpdateRequest,
        doesDirectUpdate,
        payloadBodies,
        updateParameters
      })
      let doesRefreshData = false
      if (typeof results !== 'undefined') {
        objectKeys(results).forEach((name: keyof typeof results) => {
          const { error, shouldRefresh } = results[name] ?? { error: [], shouldRefresh: true }
          if (!doesRefreshData && typeof shouldRefresh !== 'undefined') {
            doesRefreshData = shouldRefresh
          }
          if (error && error.length) {
            errorResult.push({ name, error })
          }
        })
      }

      if (!shouldIncludeUpdateRequest) {
        const result = await handleUpdate({ dirtyColumns, doesDirectUpdate, updateParameters })
        if (typeof result !== 'undefined') {
          const {
            update: { error: updateErrResult, shouldRefresh }
          } = result
          if (!doesRefreshData && typeof shouldRefresh !== 'undefined') {
            doesRefreshData = shouldRefresh
          }
          if (updateErrResult && updateErrResult.length) {
            errorResult.push({ name: 'update', error: updateErrResult })
          }
        }
      }

      if (errorResult && errorResult.length) {
        errorResult.forEach((resultItem) => {
          resultItem?.error?.forEach((errorItem) => {
            if (!errorItem?.isSuccess) {
              let msg = errorItem?.errorCode ?? anErrorOccurredLocale
              const name = resultItem?.name?.toUpperCase?.() ?? ''
              msg = name ? `${name}: ${msg}` : msg
              errorMessages.push(msg)
            }
          })
        })
      } else {
        // Success case
        dataUpdatedEventRef?.current?.handleDataUpdated()
      }

      return { addingMessages, errorMessages, shouldRefresh: doesRefreshData }
    },
    [
      dataUpdatedEventRef,
      doesDirectUpdate,
      getDeletedRows,
      gridRef,
      handleEdit,
      handleUpdate,
      isUpdateQuery,
      anErrorOccurredLocale,
      youMustHaveChangesToSaveLocale,
      fieldIsRequiredLocale,
      validateAdding
    ]
  )

  return { handleSave }
}

export default useHandleSave
