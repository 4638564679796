export default () => {
  return {
    title: 'Map',
    type: 'object',
    format: 'objectTab',

    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          showNoDataToDisplay: {
            type: 'boolean',
            title: 'Show No Data To Display',
            default: false,
            required: true
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title',
            default: '',
            required: true
          },
          zoom: {
            type: 'integer',
            title: 'Zoom Level',
            format: 'range',
            minimum: 1,
            maximum: 20,
            default: 1
          },
          moveToUpdate: {
            type: 'boolean',
            title: 'Draggable',
            default: false,
            required: true
          },
          askUpdate: {
            type: 'boolean',
            title: 'Ask on Update',
            default: false,
            required: true
          },
          isHover: {
            type: 'boolean',
            title: 'Trigger with Hover',
            default: false,
            required: true
          },
          isSync: {
            type: 'boolean',
            title: 'Synchronize',
            default: false,
            required: true
          },
          isFastIcon: {
            type: 'boolean',
            title: 'Fast Icons',
            default: false,
            required: true
          },
          theme: {
            type: 'string',
            title: 'Theme',
            default: 'Streets',
            enum: [
              'Streets',
              'OpenStreet',
              'Outdoors',
              'Light',
              'Dark',
              'Satellite',
              'NavigationDay',
              'NavigationNight'
            ],
            required: true
          },
          controlSettings: {
            type: 'object',
            title: 'Map Controls',
            options: {
              collapsed: true
            },
            properties: {
              showCompass: {
                type: 'boolean',
                title: 'Show Compass',
                default: true,
                required: true
              },
              showZoom: {
                type: 'boolean',
                title: 'Show Zoom',
                default: true,
                required: true
              },
              visualizePitch: {
                type: 'boolean',
                title: 'Visualize Pitch',
                default: true,
                required: true
              },
              showPolygonLevel: {
                type: 'boolean',
                title: 'Show Polygon Level',
                default: true,
                required: true
              }
            }
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string'
            }
          },
          id: {
            type: 'string',
            title: 'Id',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          level: {
            type: 'string',
            title: 'Level',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          geometry: {
            type: 'string',
            title: 'Geometry',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          type: {
            type: 'string',
            title: 'Type',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          latitude: {
            type: 'string',
            title: 'Latitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          longitude: {
            type: 'string',
            title: 'Longitude',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          isCenter: {
            type: 'string',
            title: 'Center',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          zoom: {
            type: 'string',
            title: 'Zoom',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          iconColor: {
            type: 'string',
            title: 'Icon Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          iconSize: {
            type: 'string',
            title: 'Icon Size',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          uom: {
            type: 'string',
            title: 'UOM (Unit of Measure)',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          modeOfTravel: {
            type: 'string',
            title: 'MOT (Mode of Travel)',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds',
            description: 'car, bus, walk, cycle, plane, train'
          },
          isDraggableData: {
            type: 'string',
            title: 'Draggable Data',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          tooltip: {
            type: 'string',
            format: 'Code',
            title: 'HTML Tooltip',
            options: {
              mode: 'htmlmixed',
              lineNumbers: true,
              readOnly: false
            }
          },
          cssTooltip: {
            type: 'string',
            format: 'Code',
            title: 'Style Tooltip',
            options: {
              mode: 'text/css'
            }
          },
          types: {
            type: 'array',
            title: 'Location Types',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Serie',
              properties: {
                value: {
                  type: 'string',
                  title: 'Value',
                  default: '',
                  required: true
                },
                icon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector',
                  default: 'fa fa-map-marker-alt'
                },
                iconColor: {
                  type: 'string',
                  format: 'NullColor',
                  title: 'Icon Color'
                },
                iconSize: {
                  type: 'integer',
                  title: 'Icon Size',
                  format: 'range',
                  minimum: 1,
                  maximum: 100,
                  default: 20
                },
                isDraggable: {
                  type: 'boolean',
                  title: 'Draggable',
                  default: false,
                  required: true
                }
              }
            }
          },
          layers: {
            type: 'array',
            title: 'Polygon Levels',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Serie',
              properties: {
                value: {
                  type: 'string',
                  title: 'Value',
                  default: '',
                  required: true
                },
                fillColor: {
                  type: 'string',
                  format: 'NullColor',
                  title: 'Fill Color'
                },
                fillOpacity: {
                  type: 'integer',
                  title: 'Fill Opacity',
                  format: 'range',
                  minimum: 1,
                  maximum: 100,
                  default: 50
                },
                lineColor: {
                  type: 'string',
                  format: 'NullColor',
                  title: 'Line Color'
                },
                lineWidth: {
                  type: 'integer',
                  title: 'Line Width',
                  format: 'range',
                  minimum: 1,
                  maximum: 10,
                  default: 1
                }
              }
            }
          }
        }
      }
    }
  }
}
