import { useEffect } from 'react'
import useReduxState from '../../useReduxState'
import { demandTreeTableSlice, loadTreeTableSlice, tuiSlice } from '../../../store/slices'

const staticLoadFooterButton = ['Polishize']
const staticDemandFooterButtons = ['Serve', 'Origin']

function useDisabledFooterButtons() {
  const [{ registerMethod }] = useReduxState(tuiSlice.selectSlice)
  const [{ config }] = useReduxState(tuiSlice.selectSlice)
  const [, { setDisabledFooterButtons: setLoadDisabledFooterButtons }] = useReduxState(
    undefined,
    loadTreeTableSlice.actions
  )
  const [, { setDisabledFooterButtons: setDemandDisabledFooterButtons }] = useReduxState(
    undefined,
    demandTreeTableSlice.actions
  )

  useEffect(() => {
    staticLoadFooterButton.forEach((staticFooterButton) => {
      registerMethod({
        args: [],
        key: `setDisable${staticFooterButton}`,
        fn: () => setLoadDisabledFooterButtons({ text: staticFooterButton, value: true })
      })
    })

    staticDemandFooterButtons.forEach((staticFooterButton) => {
      registerMethod({
        args: [],
        key: `setDisable${staticFooterButton}`,
        fn: () => setDemandDisabledFooterButtons({ text: staticFooterButton, value: true })
      })
    })

    config.loadTree.footerButtons.forEach((footerButton) => {
      const modifiedButtonText = footerButton.text.replace(/\s/g, '_')
      registerMethod({
        args: [],
        key: `setDisabledLoad_${modifiedButtonText}`,
        fn: () => setLoadDisabledFooterButtons({ text: modifiedButtonText, value: true })
      })
    })

    config.demandTree.footerButtons.forEach((footerButton) => {
      const modifiedButtonText = footerButton.text.replace(/\s/g, '_')
      registerMethod({
        args: [],
        key: `setDisabledDemand_${modifiedButtonText}`,
        fn: () => setDemandDisabledFooterButtons({ text: modifiedButtonText, value: true })
      })
    })
  }, [
    config.loadTree.footerButtons,
    config.demandTree.footerButtons,
    registerMethod,
    setLoadDisabledFooterButtons,
    setDemandDisabledFooterButtons
  ])
}

export default useDisabledFooterButtons
