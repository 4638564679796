Ext.define('clustering.store.SegmentStore', {
  extend: 'Ext.data.TreeStore',
  alias: 'store.segmentStore',
  storeId: 'SegmentStore',
  model: 'clustering.Model.ClusterSegmentModel',
  autoLoad: true,
  proxy: {
    type: 'memory',
    reader: {
      type: 'json'
    }
  },
  root: {
    text: 'Root',
    id: '0',
    expanded: true,
    children: []
  }
})
