import { getEditingDirtyCells } from '../edit'
import { dirtyCells as defaultDirtyCells } from './mocks/index'

const getDirtyCells = () => {
  return defaultDirtyCells
}
const getField = () => {
  return 'Customer'
}
const getId = () => {
  return '111'
}

describe('AgGrid:Helpers:Edit:getEditingDirtyCells', () => {
  let dirtyCells = getDirtyCells()
  let field = getField()
  let id = getId()

  beforeEach(() => {
    dirtyCells = getDirtyCells()
    field = getField()
    id = getId()
  })

  it(`should return an object includes otherDirtyCells which equals to dirtyCells array except an object matching its field and rowId with the field and the id`, () => {
    const result = getEditingDirtyCells({ dirtyCells, field, id })
    expect(result.otherDirtyCells).toEqual([
      {
        field: 'Income',
        isSubstitute: false,
        newValueParams: { id: 9, value: 9 },
        oldValueParams: { id: null, value: null },
        rowId: '222',
        unTouchedValueParams: { id: null, value: null }
      }
    ])
  })

  it(`should return an object includes otherDirtyCells which equals to empty array if dirtyCells is an empty array`, () => {
    dirtyCells = []
    const result = getEditingDirtyCells({ dirtyCells, field, id })
    expect(result.otherDirtyCells).toEqual([])
  })

  it(`should return an object includes currentDirtyCell which is an object that matching one of the dirtyCells array's object by field and rowId with the field and id`, () => {
    const result = getEditingDirtyCells({ dirtyCells, field, id })
    expect(result.currentDirtyCell).toEqual({
      field: 'Customer',
      isSubstitute: false,
      newValueParams: { id: 'yyy', value: 'yyy' },
      oldValueParams: { id: 'AA35519', value: 'AA35519' },
      rowId: '111',
      unTouchedValueParams: { id: 'AA35519', value: 'AA35519' }
    })
  })

  it(`should return an object includes currentDirtyCell which is undefined if dirtyCells is an empty array`, () => {
    dirtyCells = []
    const result = getEditingDirtyCells({ dirtyCells, field, id })
    expect(result.currentDirtyCell).toBeUndefined()
  })
})
