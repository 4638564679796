import _ from 'lodash'

const getAdminForm = (propertyEditorFields, settingsProperties, defaultForm) => {
  if (
    !_.isUndefined(settingsProperties) &&
    typeof settingsProperties === 'object' &&
    Object.keys(settingsProperties).length
  ) {
    const filteredKeys = Object.keys(settingsProperties).filter(
      (res) => !Object.keys(propertyEditorFields).includes(res)
    )
    const newForm = {}
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = filteredKeys.length; i < len; i++) {
      newForm[filteredKeys[i]] = settingsProperties[filteredKeys[i]]
    }

    return { ...defaultForm, ...newForm }
  }

  return defaultForm
}

const getBgColorCmpStyle = ({ r, g, b, a }) => {
  return {
    color: {
      background: `rgba(${r}, ${g}, ${b}, ${a})`,
      borderRadius: '2px',
      height: '14px',
      minWidth: '140px'
    },
    cover: {
      bottom: '0px',
      left: '0px',
      position: 'fixed',
      right: '0px',
      top: '0px'
    },
    popover: {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: '2'
    },
    swatch: {
      position: 'relative',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      cursor: 'pointer',
      display: 'inline-block',
      padding: '5px 18px 5px 5px',
      width: '100%',
      clear: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        icon: {
          padding: '5px'
        }
      }
    }
  }
}

export { getBgColorCmpStyle }

export default getAdminForm
