// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { borderRadius, colors, grid } from '../constants'
import type { TaskCard } from '../types'
import type { DraggableProvided } from 'react-beautiful-dnd'
import '../index.scss'
import _ from 'lodash'
import moment from 'moment'

type Props = {
  taskCard: TaskCard,
  isDragging: boolean,
  provided: DraggableProvided,
  autoFocus?: boolean
}

type HTMLElement = any

const Container = styled.a`
  border-radius: ${borderRadius}px;
  border: 1px solid grey;
  background-color: ${({ isDragging }) => (isDragging ? colors.green : colors.white)};

  box-shadow: ${({ isDragging }) => (isDragging ? `2px 2px 1px ${colors.shadow}` : 'none')};
  /* padding: ${grid}px; */
  min-height: 40px;
  margin-bottom: ${grid}px;
  user-select: none;
  transition: background-color 0.1s ease;
  width: 100%;
  /* anchor overrides */
  color: ${colors.black};

  &:hover {
    color: ${colors.black};
    text-decoration: none;
  }
  &:focus {
    outline: 2px solid ${colors.purple};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
  align-items: center;
`

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
export default class TaskCardItem extends React.PureComponent<Props> {
  constructor(props) {
    super(props)
    this.state = {
      showTaskModal: false,
      value: '',
      show: false,
      taskCard: this.props.taskCard
    }
    this.showTask = this.showTask.bind(this)
    this.getDateddmmyyyy = this.getDateddmmyyyy.bind(this)
    this.getHashCodeByHSL = this.getHashCodeByHSL.bind(this)
    this.openContent = this.openContent.bind(this)
    this.digitNumber = this.digitNumber.bind(this)
  }
  showTask() {
    this.props.onShow(this.props.taskCard)
  }
  getDateddmmyyyy(incomingDate) {
    const date = new Date(incomingDate)
    return (
      ('0' + date.getDate()).slice(-2) +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      date.getFullYear()
    )
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        taskCard: nextProps.taskCard
      })
    }
  }
  componentDidMount() {
    if (!this.props.autoFocus) {
      return
    }

    // eslint-disable-next-line react/no-find-dom-node
    const node: HTMLElement = ReactDOM.findDOMNode(this)
    node.focus()
  }
  getHashCodeByHSL(text) {
    text = text.split('').reverse().join('')
    var hash = 0
    let shortened = hash % 360
    if (text.length === 0) {
      return 'hsl(' + shortened + ',50%,75%)'
    }
    for (var i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash // Convert to 32bit integer
    }
    shortened = hash % 360
    return 'hsl(' + shortened + ',50%,75%)'
  }
  openContent(TaskId, MaskedTaskID) {
    this.props.openContent(TaskId, MaskedTaskID)
  }

  digitNumber(number, size) {
    while (number.length < (size || 2)) {
      number = '0' + number
    }
    return number
  }

  render() {
    const {
      isDragging,
      provided,
      taskAppearanceProperties,
      uniqueUserName,
      format = ''
    } = this.props
    const {
      taskCard: {
        AssigneeName,
        AssigneeSurname,
        AssigneeUniqueName,
        CategoryName,
        CommentsCount,
        CreatedBefore,
        CreatedDate,
        DaysLeft,
        DueDate,
        Id,
        IsAssignee,
        IsReporter,
        PriorityName,
        ReporterName,
        ReporterSurname,
        ReporterUniqueName,
        Subject,
        UnseenCommentsCount
      }
    } = this.state
    const priorityName = PriorityName || ''
    const isMine = IsAssignee || IsReporter
    const formatedId = format.idDigitNumber
      ? this.digitNumber(_.toString(Id), format.idDigitNumber)
      : Id
    const prefix = format.prefix ? format.prefix : ''
    const postfix = format.postfix ? format.postfix : ''
    const showedTaskId = prefix + formatedId + postfix
    return (
      <div>
        <Container
          className=" connectList agile-list "
          isDragging={isDragging}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <li
            onClick={this.showTask}
            className={`agile-list-item ui-sortable-handle taskli ${priorityName.toLowerCase()}`}
            id="task1"
            style={
              isMine
                ? {
                    boxShadow: `3px 0px 1px 0px ${this.getHashCodeByHSL(uniqueUserName)}`
                  }
                : {}
            }
          >
            <em
              style={{
                backgroundColor: this.getHashCodeByHSL(CategoryName)
              }}
              title={CategoryName}
            />
            <h1>
              <span
                className="text-ellipsis"
                title={Subject}
                onClick={(that) => {
                  that.stopPropagation()
                  this.openContent(Id, showedTaskId)
                }}
              >
                {priorityName && <i title={priorityName} className="fa fa-angle-double-up " />}
                {taskAppearanceProperties.idAsPrefixWithSubjectVisibility && showedTaskId} {Subject}
              </span>
              <span className="h-icon-box">
                <i className={`fa fa-comment-alt ${UnseenCommentsCount > 0 ? 'active' : ''}`} />{' '}
                {CommentsCount > 0 ? CommentsCount : ''}
              </span>
            </h1>
            <div className="agile-detail">
              <div className="agile-row">
                {taskAppearanceProperties && taskAppearanceProperties.reporterName && (
                  <div
                    className="user-profile-box"
                    style={{
                      backgroundColor: this.getHashCodeByHSL(ReporterUniqueName)
                    }}
                    title={`${ReporterName} ${ReporterSurname}`}
                  >
                    {_.first(ReporterName)}
                    {_.first(ReporterSurname)}
                  </div>
                )}
                {taskAppearanceProperties && taskAppearanceProperties.createdDate && (
                  <div title="Created Date">{this.getDateddmmyyyy(CreatedDate)}</div>
                )}
                {taskAppearanceProperties && taskAppearanceProperties.createdBefore && (
                  <div
                    className="user-profile-box"
                    title={`was created ${
                      CreatedBefore === 0
                        ? 'today'
                        : `${CreatedBefore} day${CreatedBefore > 1 ? 's' : ''} ago`
                    }`}
                  >
                    {CreatedBefore > 999 ? '+3Y' : CreatedBefore}
                  </div>
                )}
              </div>
              <div className="agile-row">
                {taskAppearanceProperties && taskAppearanceProperties.assigneeName && (
                  <div
                    className="user-profile-box"
                    style={{
                      backgroundColor: this.getHashCodeByHSL(AssigneeUniqueName)
                    }}
                    title={`${AssigneeName} ${AssigneeSurname}`}
                  >
                    {_.capitalize(_.first(AssigneeName))}
                    {_.capitalize(_.first(AssigneeSurname))}
                  </div>
                )}
                {taskAppearanceProperties && taskAppearanceProperties.dueDate && (
                  <div title="Due Date">
                    {moment(DueDate).format('DD-MM-YYYY') === 'Invalid date'
                      ? 'No Duedate'
                      : moment(DueDate).format('DD-MM-YYYY')}
                  </div>
                )}
                {taskAppearanceProperties && taskAppearanceProperties.daysLeft && (
                  <div
                    className="user-profile-box"
                    title={` ${
                      DaysLeft < 0
                        ? 'Duedate is expired'
                        : `${DaysLeft} day${DaysLeft > 1 ? 's' : ''} left to duedate`
                    }`}
                  >
                    {DaysLeft < 0 ? '-' : DaysLeft}
                  </div>
                )}
              </div>
            </div>
          </li>
        </Container>
      </div>
    )
  }
}
