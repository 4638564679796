export const {
  API_URL,
  AUTH_URL,
  MESSAGING_URL,
  PUBLIC_URL,
  NODE_ENV,
  PRODUCT_KEYS,
  APPLICATION_NAME,
  ENVIRONMENT_NAME,
  BUILD_ID
} = process.env
