export default () => {
  return {
    title: 'D3 Calendar Heatmap',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          export: {
            type: 'boolean',
            title: 'Exportable',
            default: true,
            required: true
          }
        }
      },
      datafields: {
        type: 'array',
        format: 'HiddenTable',
        title: 'Data Fields',
        items: {
          type: 'string'
        }
      },
      settings: {
        type: 'object',
        title: 'Calendar Settings',
        properties: {
          tooltipFormat: {
            title: 'Tooltip Format',
            type: 'string',
            format: 'Code',
            default: ''
          },
          tooltipStyle: {
            title: 'Tooltip Style',
            type: 'string',
            format: 'Code',
            default: '',
            options: {
              mode: 'text/css'
            }
          },
          disableWeekends: {
            title: 'Disable Weekends',
            type: 'boolean',
            default: false
          },
          weekendsColor: {
            type: 'string',
            title: 'Weekends Color',
            format: 'NullColor',
            options: {
              basic: true
            },
            default: 'gray'
          },
          firstDayOfWeek: {
            type: 'string',
            title: 'First Day of Week',
            default: 'Sunday',
            enum: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          date: {
            type: 'string',
            title: 'Date',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          endDate: {
            type: 'string',
            title: 'End Date',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          year: {
            type: 'string',
            title: 'Year',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          description: {
            type: 'string',
            title: 'Description',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          },
          colorField: {
            type: 'string',
            title: 'Color Field',
            watch: {
              ds: 'datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
