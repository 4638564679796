import _ from 'lodash'
import moment from 'moment'
import { getData, findSymbolFromKey, getCenterPos } from './data'

const tileStyle = {
  OpenStreetMap: {
    urlTemplate: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    options: {
      attribution:
        '&copy <a href="https://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
    }
  },
  Topographic: {
    urlTemplate: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    options: {
      attribution:
        '&copy <a href="https://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
    }
  },
  Stadia: {
    urlTemplate: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
    options: {
      attribution:
        '&copy <a href="https://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
    }
  },
  StadiaDark: {
    urlTemplate: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    options: {
      attribution:
        '&copy <a href="https://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
    }
  }
}

const arrowSelector = (showArrow) => {
  switch (showArrow) {
    case 'None':
      return ['none', 'none']
    case 'Start':
      return ['arrow', 'none']
    case 'End':
      return ['none', 'arrow']
    case 'Both':
      return ['arrow', 'arrow']
    // no default
  }
}

export function getConfig(plugin) {
  const { props, state } = plugin
  const that = plugin
  const {
    settings: {
      config: {
        data: { flow, showFlows, flowType, showArrow = 'None', symbols = [] } = {},
        settings: {
          title,
          subtitle,
          theme = 'Light',
          showAnimation = true,
          showVisualMap = false,
          leafletLayer = false,
          leafletTileStyle = 'OpenStreetMap',
          defaultLongitude = 0,
          defaultLatitude = 0,
          autoSymbolSize = true
        } = {}
      } = {}
    } = {},
    getFormattedValue
  } = props

  const data = getData(that)

  let min = 0
  let max = 100

  const series = []
  let idx = 0

  if (!_.isEmpty(data.points)) {
    _.map(data.points, (point, key) => {
      idx++
      max = _.max(_.map(point, (r) => r.value))
      min = _.min(_.map(point, (r) => r.value))
      const diffMaxMin = max - min

      const symbol = findSymbolFromKey({ key, props, state })
      const diffSymbolMaxMin = symbol.symbolMaxSize - symbol.symbolMinSize

      const serieData = {
        type: 'scatter',
        zlevel: 2,
        coordinateSystem: leafletLayer ? 'leaflet' : 'geo',
        data: _.map(point, (p) => {
          const colorData = _.get(_.find(symbols, (s) => s.key === key) || {}, 'colorData', null)
          const flowData = _.first(p.flows) || {}
          let cData = symbol.color

          if (colorData) {
            const { [colorData]: clr = symbol.color } = flowData
            cData = clr
          }

          return {
            name: p.name,
            type: key,
            value: [
              ...p.coords,
              p.value,
              p.valueIn,
              p.valueOut,
              p.tooltip,
              p.pointDisplay,
              p.value
            ],
            types: _.uniqBy(_.map(p.flows, (f) => f[flowType])),
            itemStyle: {
              normal: {
                color: cData
              },
              emphasis: {
                borderColor: '#fff',
                borderWidth: 1
              }
            },
            rowData: flowData
          }
        }),
        symbolKeepAspect: true,
        symbol: symbol.symbol,
        symbolSize: (val) => {
          return autoSymbolSize
            ? diffMaxMin === 0 || diffSymbolMaxMin <= 0
              ? symbol.symbolMaxSize
              : Math.round((val[2] * diffSymbolMaxMin) / diffMaxMin) + symbol.symbolMinSize
            : symbol.symbolMaxSize
        },
        label: {
          normal: {
            formatter(data) {
              const { data: { value = [] } = {} } = data
              return value[6]
            },
            position: 'inside',
            show: true
          },
          emphasis: {
            show: true
          }
        },
        itemStyle: {
          normal: {
            color: symbol.color
          },
          emphasis: {
            borderColor: '#fff',
            borderWidth: 1
          }
        }
      }
      if (!showFlows) {
        serieData.name = key
      }
      if (showAnimation) {
        serieData.rippleEffect = {
          brushType: 'stroke'
        }
      }
      series.push(serieData)
    })
  }

  idx = 0

  const { data: flowData = [], settings = [] } = data

  if (showFlows && !_.isEmpty(flowData)) {
    _.each(flowData, (d, key) => {
      const { flowColor, flowCurve, flowStyle, flowWidth } = settings[idx] || {}

      const data = _.map(d, (item) => {
        const { itemSettings } = item

        return {
          ...{
            ...item,
            lineStyle: {
              normal: {
                color: itemSettings.flowColor || flowColor,
                curveness: itemSettings.flowCurve || flowCurve,
                type: itemSettings.flowStyle || flowStyle,
                width: itemSettings.flowWidth || flowWidth,
                opacity: 0.6
              },
              emphasis: {
                width: 5,
                opacity: 0.8
              }
            }
          }
        }
      })

      const serieData = {
        coordinateSystem: leafletLayer ? 'leaflet' : 'geo',
        name: key,
        type: 'lines',
        zlevel: 1,
        large: true,
        symbol: showAnimation ? ['none', 'none'] : arrowSelector(showArrow),
        symbolSize: 10,
        lineStyle: {
          normal: {
            color: flowColor,
            curveness: flowCurve,
            type: flowStyle,
            width: flowWidth,
            opacity: 0.6
          },
          emphasis: {
            width: 5,
            opacity: 0.8
          }
        },
        data
      }
      if (showAnimation) {
        serieData.effect = {
          show: true,
          period: 6,
          trailLength: 0,
          ...findSymbolFromKey({ key, props, state, defaultSymbol: 'arrow' })
        }
      }
      series.push(serieData)
      idx++
    })
  }

  let themeStyle = {
    backgroundColor: '#525860', // backgroundColor,404a59
    title: {
      textStyle: '#f2f2f2'
    },
    geo: {
      itemStyle: {
        normal: {
          areaColor: '#414954', // 323c48
          borderColor: '#19212C' // #111
        },
        emphasis: {
          areaColor: '#262B32' // 2a333d
        }
      }
    }
  }
  if (theme === 'Light') {
    themeStyle = {
      backgroundColor: '#AFCFEE', // backgroundColor,#a3d7f6
      title: {
        textStyle: '#2d2d2d'
      },
      geo: {
        itemStyle: {
          normal: {
            areaColor: '#E2E5E8',
            borderColor: '#637B9B'
          },
          emphasis: {
            areaColor: '#9FADC0'
          }
        }
      }
    }
  } else if (theme === 'Light gray') {
    themeStyle = {
      backgroundColor: '#D3D7DA',
      title: {
        textStyle: '#3d3d3d'
      },
      geo: {
        itemStyle: {
          normal: {
            areaColor: '#9DA3A9',
            borderColor: '#A8B7C3'
          },
          emphasis: {
            areaColor: '#889DAE'
          }
        }
      }
    }
  }
  let legends = showFlows ? _.keys(data.data) : _.keys(data.points)
  legends = _.size(legends) > 1 ? legends : []

  const option = {
    ...themeStyle,
    title: {
      text: title,
      subtext: subtitle,
      left: 'center',
      textStyle: { color: themeStyle.title.textStyle },
      subtextStyle: { color: themeStyle.title.textStyle }
    },

    // theme: 'light',
    toolbox: {
      top: 'top',
      right: 30,
      orient: 'vertical',
      feature: {
        saveAsImage: {
          name: moment().format(),
          title: 'Export Image'
        }
      }
    },
    tooltip: {
      trigger: 'item',
      backgroundColor: 'white',
      ...(leafletLayer
        ? {
            position([top = 0, left = 0], params, el) {
              el.style.top = 'auto'
              el.style.left = 'auto'
              el.style.transform = `translate(${top + 10}px, ${left + 10}px)`

              return ['auto', 'auto']
            }
          }
        : {}),
      formatter(params) {
        const { data: { value = [] } = {} } = params
        const tooltip = value && _.isArray(value) && value.length >= 5 && value[5]
        let result = ''
        const dot = `<i style="color:${params.color}"></i>`
        if (params.data.name) {
          result = `<div class="ec-tooltip-data"><header class="ec-row">${dot} <b>${params.data.name}</b></header>`
          if (showFlows) {
            result += `<div class="ec-row">${dot} <b>Total In:</b> <span>${getFormattedValue(
              flow,
              params.data.value[3]
            )}</span></div>
                       <div class="ec-row">${dot} <b>Total Out:</b> <span>${getFormattedValue(
              flow,
              params.data.value[4]
            )}</span></div>`
          } else {
            result += `<div class="ec-row">${dot} <b>Volume:</b> <span>${getFormattedValue(
              flow,
              params.data.value[2]
            )}</span></div>`
          }
        } else {
          result = `<div class="ec-row">${dot} <span>${
            params.data.fromName
          } <i class="fa fa-long-arrow-right"></i> 
            ${params.data.toName}</span>
          </div>
          <div class="ec-row">${dot} <b>Value:</b> <span>${getFormattedValue(
            flow,
            params.data.value
          )}</span></div>`
        }
        result += '</div>'
        return `<div class="e-chart-tooltip-container">
            ${
              tooltip
                ? (params.data.name
                    ? `<header class="ec-row"><b>${params.data.name}</b></header>`
                    : '') + tooltip
                : result
            }
        </div>`
      }
    },
    legend: {
      orient: 'horizontal',
      top: 'top',
      zlevel: 3,
      left: 'left',
      data: legends,
      textStyle: {
        color: '#fff',
        fontWeight: 'bold'
      }
    },
    geo: {
      ...themeStyle.geo,
      boundingCoords: data.boundingCoords,
      map: 'world',
      label: {
        emphasis: {
          show: true
        }
      },
      silent: true,
      roam: true
    },
    series
  }

  if (leafletLayer) {
    let center = getCenterPos(that).reverse()

    if (_.isEqual(center, [0, 0])) {
      center = [defaultLongitude, defaultLatitude]
    }

    option.leaflet = {
      center,
      zoom: state.zoom,
      roam: true,
      tiles: [tileStyle[leafletTileStyle] || tileStyle.OpenStreetMap]
    }

    delete option.geo
  }

  if (showVisualMap && !_.isEmpty(data.points)) {
    option.visualMap = {
      min,
      max,
      calculable: true,
      inRange: {
        color: ['#50a3ba', '#eac736', '#d94e5d']
      },
      textStyle: {
        color: '#fff'
      }
    }
  }

  return option
}
