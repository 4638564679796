import { FocusEvent, useRef, useState } from 'react'
import { Editable, ReadOnly } from './components'
import { CardProps } from './Card.types'

const Card = ({ dataConfig, fontSizes, item, index, onSave, onDelete }: CardProps) => {
  const {
    cardTitle: initialCardTitle,
    cardDetailedText: initialCardDetailedText,
    author,
    title,
    isReadOnly = true,
    isNewCard = false
  } = item

  const [cardTitle, setCardTitle] = useState(initialCardTitle)
  const [cardDetailedText, setCardDetailedText] = useState(initialCardDetailedText)
  const [isFocused, setIsFocused] = useState(false)

  const cardRef = useRef(null)

  const handleSave = () => {
    const isCardContentEmpty = !cardTitle || !cardDetailedText
    const isCardUnmodified =
      cardTitle === initialCardTitle && cardDetailedText === initialCardDetailedText

    if (isCardUnmodified || isCardContentEmpty) {
      return
    }

    const updatedCard = {
      [dataConfig.timeline.title]: title,
      [dataConfig.card.author]: author,
      [dataConfig.card.title]: cardTitle,
      [dataConfig.card.text]: cardDetailedText
    }

    onSave(updatedCard, index, isNewCard)
  }

  const handleDelete = () => {
    const deletedCard = {
      [dataConfig.timeline.title]: title,
      [dataConfig.card.author]: author,
      [dataConfig.card.title]: initialCardTitle,
      [dataConfig.card.text]: initialCardDetailedText
    }

    onDelete(deletedCard, index, isNewCard)
  }

  const handleFocus = () => {
    if (isReadOnly || isNewCard) {
      return
    }

    setIsFocused(true)
  }

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    const hasFocus = cardRef?.current?.contains(event.relatedTarget)
    if (hasFocus || isNewCard) {
      return
    }

    setIsFocused(false)
    handleSave()
    setCardTitle(initialCardTitle)
    setCardDetailedText(initialCardDetailedText)
  }

  const isEditable = (!isReadOnly && isFocused) || isNewCard

  return (
    <div
      ref={cardRef}
      className="d-flex flex-column gap-2 w-100"
      tabIndex={index}
      onBlur={handleBlur}
      onFocus={handleFocus}
    >
      {isEditable ? (
        <Editable
          author={author}
          fontSizes={fontSizes}
          handleDelete={handleDelete}
          handleSave={handleSave}
          isNewCard={isNewCard}
          setText={setCardDetailedText}
          setTitle={setCardTitle}
          text={cardDetailedText}
          title={cardTitle}
        />
      ) : (
        <ReadOnly
          author={author}
          fontSizes={fontSizes}
          text={initialCardDetailedText}
          title={initialCardTitle}
        />
      )}
    </div>
  )
}

export default Card
