import { dataMap } from './constants'

export default function History() {
  let _state = []
  let _pushIndex = 0

  const _getActiveState = () => {
    return _.find(_state, (item) => item.active)
  }
  const _getBackState = (index) => {
    return _.find(_state, (item) => item.index === index - 1) || {}
  }
  const _getNextState = (index) => {
    return _.find(_state, (item) => item.index === index + 1) || {}
  }
  const _resetStateStatus = () => {
    return _.map(_state, (item) => {
      item.active = false
      return item
    })
  }

  this.getHistory = () => {
    const currentState = _getActiveState()
    const { index } = currentState

    return {
      current: currentState,
      back: _getBackState(index),
      next: _getNextState(index),
      state: _state
    }
  }

  this.pushState = (name, h) => {
    const { key, state } = h
    const findedState = _.find(_state, (_fs) => _fs.constructor === name) || {}
    const { pushState = {} } = findedState

    /// TODO
    findedState.pushState = {
      ...pushState,
      ...{ [key]: state }
    }

    return _getActiveState()
  }

  this.pushPage = (pageName) => {
    const { constructor } = dataMap[pageName]

    const activeState = _getActiveState()
    const { index = 0, constructorKey } = activeState || {}

    if (pageName === constructorKey) {
      return
    }

    const isNotLastState = index < _state.length
    if (isNotLastState) {
      _state = _.slice(_state, 0, index)
      _pushIndex = index
    }

    _resetStateStatus()
    _state.push({
      index: ++_pushIndex,
      active: true,
      constructorKey: pageName,
      constructor
    })
  }

  this.clearHistory = (pageName) => {
    _state = []

    this.pushPage(pageName)
  }

  this.replaceState = (pageName) => {
    _state.pop()

    this.pushPage(pageName)
  }

  this.back = () => {
    const { index } = _getActiveState()
    const targetObj = _getBackState(index) || _state[0]

    _resetStateStatus()
    targetObj.active = true
  }

  this.next = () => {
    const { index } = _getActiveState()
    const targetObj = _getNextState(index) || _state[0]

    _resetStateStatus()
    targetObj.active = true
  }

  this.go = (arg = 0) => {
    const newState =
      (_.isString(arg) ? _.find(_state, (item) => item.constructorKey === arg) : _state[arg]) ||
      _getActiveState()

    if (!_.isEmpty(newState)) {
      _resetStateStatus()

      newState.active = true
    }

    return newState
  }

  this.state = _.last(_state)

  return {
    pushPage: this.pushPage,
    pushState: this.pushState,
    back: this.back,
    next: this.next,
    go: this.go,
    clear: this.clearHistory,
    history: this.getHistory
  }
}
