Ext.define('Spui.view.wizard.items.Main', {
  extend: 'Ext.panel.Panel',
  // Uncomment to give this component an xtype
  xtype: 'spui-view-wizard-items-main',
  minHeight: 860,
  minWidth: 1600,
  reference: 'wizard',
  width: '85%',
  height: '96%',
  scrollable: true,
  layout: {
    type: 'vbox',
    pack: 'start',
    align: 'stretch'
  },
  border: true,
  items: [
    {
      xtype: 'spui-view-wizard-breadcrumbs-breadcrumbs',
      padding: '0 0 0 0',
      bind: {
        data: '{bcDatafn}'
      }
    },
    {
      xtype: 'spui-view-wizard-items-card',
      flex: 1
    }
  ],
  initComponent: function () {
    this.dockedItems = [
      {
        xtype: 'toolbar',
        height: 40,
        defaultButtonUI: 'spui-btn-wizard',
        style: {
          background: '#0090A2',
          'border-color': '#0090a2'
        },
        dock: 'bottom',
        items: [
          {
            text: tt('Close'),
            handler: 'onHome'
          },
          '->',
          {
            itemId: 'move-prev',
            bind: {
              disabled: '{isPrevDisabled}'
            },
            text: tt('« Previous'),
            handler: 'showPrevious'
          },
          {
            itemId: 'move-next',
            text: tt('Next »'),
            handler: 'showNext',
            bind: {
              hidden: '{isNextDisabled}'
            }
          }
        ]
      }
    ]
    this.callParent()
  }
})
