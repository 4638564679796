import { pick, isEqual } from 'lodash'
import { CellEditRequestEvent, IRowNode } from 'ag-grid-community'
import {
  formatDate,
  getIdFromLookupOptions,
  getIsLookupDataField,
  getIsLookupQuery,
  getIsSubstitute,
  getValueFromLookupOptions,
  omitCustomFields,
  updateWithSubstituteNewValue
} from '../index'
import { REFRESH_DATA_DICTIONARY } from '../../constants'
import { AG_GRID_ENUMS } from '../../enums'
import {
  DirtyColumn,
  DeletedRow,
  DirtyCell,
  IRowData,
  IRowDataGrid,
  GridRef,
  SlvyColDef,
  TrowDataValue
} from '../../types'
import { RequestParams } from '../../components/MassUpdate/MassUpdateModal/MassUpdateModal.types'
import {
  GetDirtyRowsProps,
  GetDataUpdateRequestParamsProps,
  GetDataUpdateRequestParamsReturnType,
  GetEditingDirtyCellsProps,
  GetEditingDirtyCellsReturnType,
  GetEditingParamsProps,
  GetEditingParamsReturnType,
  ShouldRefreshData,
  ShouldRefreshDataReturnType
} from './edit.types'

const { CUSTOM_ROW_INFO, DIRTY_CELLS, ADDED_ROWS } = AG_GRID_ENUMS

// TODO: Missing test
const getDeletedRecords = (deletedRows: DeletedRow[]): IRowDataGrid[] => {
  const deletedRecords: IRowDataGrid[] = []
  deletedRows.forEach(({ data }: DeletedRow) => {
    const { [DIRTY_CELLS]: dirtyCells } = pick(data[CUSTOM_ROW_INFO], [DIRTY_CELLS])

    const restData = updateWithSubstituteNewValue({ data, dirtyCells })
    const newRestData = omitCustomFields(restData)
    deletedRecords.push(formatDate(newRestData))
  })
  return deletedRecords
}

const getDataUpdateRequestParams = ({
  gridRef,
  deletedRows,
  shouldIncludeUpdateRequest
}: GetDataUpdateRequestParamsProps): GetDataUpdateRequestParamsReturnType => {
  const dirtyColumns: DirtyColumn[] = []
  const dirtyRecords: IRowDataGrid[] = []
  const addedRecords: IRowDataGrid[] = []
  const deletedRecords: IRowDataGrid[] = getDeletedRecords(deletedRows)

  gridRef?.current?.api?.forEachLeafNode?.(({ data }: IRowNode<IRowData>) => {
    if (data) {
      const { [DIRTY_CELLS]: dirtyCells, [ADDED_ROWS]: addedRows } = pick(data[CUSTOM_ROW_INFO], [
        DIRTY_CELLS,
        ADDED_ROWS
      ])

      if (addedRows.length) {
        const updatedRowData = updateWithSubstituteNewValue({ data, dirtyCells })
        const newUpdatedRowData = omitCustomFields(updatedRowData)
        addedRecords.push(formatDate(newUpdatedRowData))
      } else if (dirtyCells.length) {
        let newRestData = updateWithSubstituteNewValue({ data, dirtyCells })
        newRestData = omitCustomFields(newRestData)

        if (shouldIncludeUpdateRequest) {
          dirtyRecords.push(formatDate(newRestData))
        } else {
          dirtyCells.forEach((cell) => {
            dirtyColumns.push({ config: formatDate(newRestData), columnName: cell.field })
          })
        }
      }
    }
  })

  return {
    dirtyRecords,
    addedRecords,
    deletedRecords,
    dirtyColumns
  }
}

const getEditingParams = ({
  oldValue,
  newValue,
  customInfo
}: GetEditingParamsProps): GetEditingParamsReturnType => {
  const isLookupDataField = getIsLookupDataField(customInfo)
  const doesLookupQueryExist = getIsLookupQuery(customInfo)
  const isSubstitute = getIsSubstitute(customInfo)
  const { lookupOptions = [] } = customInfo

  let oldValueParams = { id: oldValue, value: oldValue }
  let newValueParams = { id: newValue, value: newValue }

  if (!isSubstitute && !doesLookupQueryExist && !isLookupDataField) {
    return { newValueParams, oldValueParams }
  }

  // newValue is id for all the lookup case

  if (isSubstitute) {
    // oldValue is value for isSubstitute

    let foundOldId = getIdFromLookupOptions({ lookupOptions, value: oldValue })

    // ?? oldValue is added as fallback when "lookupFieldList?.find" result is undefined (clearing the input)
    foundOldId = typeof foundOldId === 'undefined' ? oldValue : foundOldId

    let foundNewValue = getValueFromLookupOptions({ lookupOptions, id: newValue })

    // ?? newValue is added as fallback when "lookupFieldList?.find" result is undefined (clearing the input)
    foundNewValue = typeof foundNewValue === 'undefined' ? newValue : foundNewValue

    newValueParams = {
      id: newValue,
      value: foundNewValue
    }

    oldValueParams = {
      id: foundOldId,
      value: oldValue
    }
  } else if (doesLookupQueryExist || isLookupDataField) {
    // oldValue is id for doesLookupQueryExist or isLookupDataField
    const oldValueId = oldValue

    oldValueParams = {
      id: oldValueId,
      value: oldValueId
    }

    newValueParams = {
      id: newValue,
      value: newValue
    }
  }

  return { newValueParams, oldValueParams }
}

const getEditingDirtyCells = ({
  dirtyCells,
  field,
  id
}: GetEditingDirtyCellsProps): GetEditingDirtyCellsReturnType => {
  const copiedDirtyCells = dirtyCells
  const otherDirtyCells = copiedDirtyCells.filter((cell) => {
    return !(cell.field === field && cell.rowId === id)
  })
  const currentDirtyCell = copiedDirtyCells.find((cell) => {
    return cell.field === field && cell.rowId === id
  }) as DirtyCell
  return {
    otherDirtyCells,
    currentDirtyCell
  }
}

const getDirtyRows = ({ gridRef, shouldOmit }: GetDirtyRowsProps) => {
  const dirtyRows: (IRowDataGrid | IRowData)[] = []

  gridRef?.current?.api?.forEachLeafNode?.(({ data }: IRowNode<IRowData>) => {
    if (data) {
      const { [DIRTY_CELLS]: dirtyCells = [] } = data[CUSTOM_ROW_INFO]
      if (dirtyCells?.length) {
        let restData: IRowDataGrid | IRowData = data
        restData = updateWithSubstituteNewValue({ data, dirtyCells })
        if (shouldOmit) {
          restData = omitCustomFields(restData)
        }
        dirtyRows.push(restData)
      }
    }
  })

  return dirtyRows
}

const shouldRefreshData = ({
  name,
  doesDirectUpdate,
  error
}: ShouldRefreshData): ShouldRefreshDataReturnType => {
  const updateType = doesDirectUpdate ? 'direct' : 'saveButton'
  const { isSuccess, successCode: failCode } = error?.data?.result?.[0] || {
    isSuccess: true
  }

  const shouldRefreshInfo = REFRESH_DATA_DICTIONARY?.[name]?.[updateType]?.shouldRefresh(
    isSuccess,
    failCode
  ) ?? { shouldRefresh: true, shouldReset: true }

  return shouldRefreshInfo
}

// TODO: Missing test
const prepareUpdateData = (params: RequestParams): IRowData => {
  const {
    event: {
      node: { id },
      data: oldData,
      colDef: { field }
    },
    isSubstitute,
    newValueParams,
    oldValueParams,
    otherDirtyCells,
    unTouchedValueParams
  } = params

  if (typeof field === 'undefined') {
    return oldData
  }

  const newData: CellEditRequestEvent['data'] = {
    ...oldData,
    [field]: newValueParams.value
  }

  const newDirtyCells: DirtyCell[] = [
    ...otherDirtyCells,
    {
      isSubstitute,
      rowId: id,
      field,
      oldValueParams,
      newValueParams,
      unTouchedValueParams
    }
    // remove if new value is back to first version of value
  ].filter((cell) => !isEqual(cell.unTouchedValueParams.id, cell.newValueParams.id))

  newData[CUSTOM_ROW_INFO][DIRTY_CELLS] = [...newDirtyCells]

  return newData
}

const getInitialValue = (value: TrowDataValue, isProgress: boolean) => {
  if (isProgress) {
    if (value && Array.isArray(value) && value.length) {
      const firstItem = value[0] as number
      if (firstItem === 0) {
        return 0
      }
      return firstItem / 100
    }
    return null
  }

  return value
}

const getInitialVersionOfDirtyRows = (gridRef: GridRef) => {
  const newRollbackRowNodesData: IRowData[] = []

  gridRef?.current?.api?.forEachLeafNode?.(({ data }: IRowNode<IRowData>) => {
    if (data) {
      const { [DIRTY_CELLS]: dirtyCells } = pick(data[CUSTOM_ROW_INFO], [DIRTY_CELLS])

      if (dirtyCells.length) {
        const newRollbackRowNodesDataItem = { ...data }

        dirtyCells?.forEach((cell) => {
          const currentCell = gridRef?.current?.api?.getColumnDef(cell.field) as SlvyColDef
          const isProgress = currentCell?.customInfo?.columnType === 'progress'

          newRollbackRowNodesDataItem[cell.field] = cell.isSubstitute
            ? getInitialValue(cell.unTouchedValueParams.value, isProgress)
            : getInitialValue(cell.unTouchedValueParams.id, isProgress)
        })

        newRollbackRowNodesData.push(newRollbackRowNodesDataItem)
      }
    }
  })

  return newRollbackRowNodesData
}

const getNewAddedRows = (gridRef: GridRef) => {
  const newAddedNodesData: IRowData[] = []

  gridRef?.current?.api?.forEachLeafNode?.(({ data }: IRowNode<IRowData>) => {
    if (data) {
      const { [ADDED_ROWS]: addedRows } = pick(data[CUSTOM_ROW_INFO], [ADDED_ROWS])

      if (addedRows.length) {
        newAddedNodesData.push(data)
      }
    }
  })

  return newAddedNodesData
}

export {
  getNewAddedRows,
  getInitialVersionOfDirtyRows,
  getDataUpdateRequestParams,
  getEditingParams,
  getEditingDirtyCells,
  getDirtyRows,
  shouldRefreshData,
  prepareUpdateData
}
