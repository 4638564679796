import { useEffect } from 'react'
import { select } from '@/crudoptV3'
import { getCatalog } from '@/actions/catalog'
import { SlvyLogo } from '../../components'
import { useAppSelector, useAppDispatch } from '@/hooks/store'
import style from './Home.module.scss'
import { HomeTypes } from './Home.types'

const Home = ({ params: { catalogId } }: HomeTypes) => {
  const dispatch = useAppDispatch()

  const catalog = useAppSelector((state) => select(getCatalog(catalogId), state.model3))
  const { needFetch, fetch, data: { theme = 'Default' } = {} } = catalog

  useEffect(() => {
    if (needFetch) {
      dispatch(fetch)
    }
  }, [dispatch, fetch, needFetch])

  return (
    <div
      className={`row wrapper wrapper-content position-relative bg-size-cover ${style.backgroundImage} ${style[theme]}`}
      data-testid="home"
    >
      {theme !== 'Gillette' && (
        <div className={`col-lg-12 opacity-1 ${style.index}`}>
          <div className="text-center m-t-lg">
            <div className="mt-5 mb-4 mx-auto">
              <SlvyLogo size="lg" />
            </div>
            <h1 className={`text-white mx-auto my-0 fw-bold px-3 py-4 ${style.sloganDescription}`}>
              MAKES IT EASY
            </h1>
          </div>
        </div>
      )}
      <div
        className="bg-black position-absolute w-100 h-100 top-0 start-0 opacity-25"
        data-testid="home-gradient"
      />
    </div>
  )
}

export default Home
