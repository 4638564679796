import { isEqual } from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelectorCreator, lruMemoize } from 'reselect'
import { v3 as uuidv3 } from 'uuid'
import { ColumnSettings, ColumnSchemaSettings, AggFunc, ProgressOptions } from '../types'
import { HighchartOptions } from '../components/CellRenderers/HighchartCellRenderer/HighchartCellRenderer.types'
import { SparklineOptions } from '../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'
import { EDITABLE_COLUMN_TYPES, SORTABLE_COLUMN_TYPES } from '../constants'
import { getBackColorAndColor } from '../helpers'

const selectRawConfigColumns = (columns: ColumnSchemaSettings[]) => columns

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

const selectConfigColumns = createDeepEqualSelector(selectRawConfigColumns, (columns) => {
  // eslint-disable-next-line no-console
  console.log('selectConfigColumns selector is running...')

  // TODO: CRUCIAL: Remove columns whose Column Action is Delete Button on mongo.
  // In AgGrid you don’t need to add Column for Delete.
  // Grid > Deleting > Enabled is enough to create delete column.
  const newColumns = columns.filter((column) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const doesColumnActionExist = Boolean(column?.columnAction)
    return !doesColumnActionExist
  })

  return newColumns.map((column, columnIndex) => {
    const {
      align = 'left',
      backColor,
      cellStyle = '',
      cellTooltip = '',
      groupHeader = '',
      columnType = 'simple',
      fieldName = '', // TODO: Added default value
      filtering: { showList = false, tagFilter = false } = {},
      flex = 1,
      fontColor,
      formatField = '',
      grouped = false,
      header = '', // TODO: Check default '\xa0'
      showInMaxMode = false,
      hidden = false,
      hiddenCondition = '',
      hideMenu = false,
      icon: { displayOnlyIcon = false, icon: selectedIcon = '', iconPosition = 'right' } = {},
      locked = false,
      sortable = true,
      template = '',
      summaryTemplate = '',
      summaryType,
      customAggregation = '',
      tooltip = '', // TODO: Added default value
      width = 90,
      adding: { enabled: addingEnabled = true } = {},
      editing: {
        enabled = false,
        editableCondition,
        allowBlank = false,
        maxValue = null,
        minValue = null,
        step = null,
        maxValueField = '',
        minValueField = '',
        stepField = '',
        lookupDataField = '',
        warningThreshhold = 0
      } = {},
      action: { cellClickEnabled = false } = {},
      actionButton: {
        clickable: actionClickable = false,
        editableCondition: actionEditableCondition = '',
        enabled: actionEnabled = false,
        name: actionName = ''
      } = {},
      sparkline: {
        sparkLineProps: {
          barColor: columnColor = '#3366cc',
          boxFillColor = '#cdf',
          boxLineColor = '#000',
          highlightColor = '',
          lineColor = '#157fcc',
          lineWidth = 1,
          maxSpotColor = '#f80',
          medianColor = '#f00',
          minSpotColor = '#f80',
          negBarColor = '#f44',
          outlierFillColor = '#fff',
          outlierLineColor = '#333',
          performanceColor = '#33f',
          spotColor = '#f80',
          targetColor = '#f33',
          thresholdColor = '',
          type: sparklineType = 'sparklineline',
          whiskerColor = '#000',
          zeroColor = ''
        } = {}
      } = {},
      progress: {
        textColor = '#000',
        monochrome: {
          isEnabled: isMonochromeEnabled = false,
          backColor: monochromeBackColor = '#195196',
          brighten = 1
        } = {}
      } = {},
      boolean: { trueIcon = '', falseIcon = '', trueColor = '', falseColor = '' } = {}
    } = column

    const chartTypes =
      sparklineType === 'sparklinebox' ||
      sparklineType === 'sparklinebullet' ||
      sparklineType === 'sparklinepie'

    const newColumnType = columnType === 'sparkline' && chartTypes ? 'highchart' : columnType

    const isSortable =
      sortable &&
      SORTABLE_COLUMN_TYPES.some((sortableColumnType) => sortableColumnType === columnType)

    const isEnabled =
      enabled &&
      EDITABLE_COLUMN_TYPES.some((editableColumnType) => editableColumnType === columnType)

    const highchartType: HighchartOptions['type'] = chartTypes ? sparklineType : 'sparklinepie'
    const highchartOptions: HighchartOptions = {
      boxFillColor,
      boxLineColor,
      highlightColor,
      lineColor,
      medianColor,
      outlierFillColor,
      outlierLineColor,
      performanceColor,
      targetColor,
      type: highchartType,
      whiskerColor
    }

    const sparklineOptionsType: SparklineOptions['type'] = !chartTypes
      ? sparklineType
      : 'sparklineline'

    const sparklineOptions: SparklineOptions = {
      columnColor,
      highlightColor,
      lineColor,
      lineWidth,
      maxSpotColor,
      minSpotColor,
      negBarColor,
      spotColor,
      thresholdColor,
      type: sparklineOptionsType,
      zeroColor
    }

    const progressOptions: ProgressOptions = {
      textColor,
      monochrome: {
        isEnabled: isMonochromeEnabled,
        backColor: monochromeBackColor,
        brighten
      }
    }

    const aggFunc: AggFunc = summaryType === 'average' ? 'avg' : summaryType

    // TODO: cellStyle will be removed and getBackColorAndColor will be removed also.
    const { backgroundColor, color } = cellStyle
      ? getBackColorAndColor(cellStyle, fontColor)
      : { backgroundColor: backColor, color: fontColor }

    // Create field for empty "Data Field"
    // In Ag-Grid, "field" cannot be empty and repetitive
    // http://localhost:3000/Configuration/catalog/6385de7d43b57904ac09efe7/store/3/menu/638623cb43b5790aa8dd4ee1/page/665459eb8e671e448e4c49d5
    // Pinned column cannot pin if field is empty and repetitive
    const isRawFieldEmpty = fieldName?.trim?.()?.length < 1
    const field = isRawFieldEmpty ? `${uuidv3(String(columnIndex), uuidv3.DNS)}` : fieldName

    const newColumnProps: ColumnSettings = {
      customConfigs: {
        actionButton: {
          actionEnabled,
          actionName,
          actionEditableCondition,
          actionClickable
        },
        adding: {
          enabled: isRawFieldEmpty ? false : addingEnabled
        },
        boolean: {
          trueIcon,
          falseIcon,
          trueColor,
          falseColor
        },
        cellStyle: {
          textAlign: align,
          backgroundColor,
          color
        },
        editing: {
          enabled: isEnabled,
          editableCondition,
          allowBlank,
          maxValue,
          minValue,
          step,
          maxValueField,
          minValueField,
          stepField,
          lookupDataField,
          warningThreshold: warningThreshhold
        },
        filtering: {
          showList,
          tagFilter
        },
        icon: {
          displayOnlyIcon,
          icon: selectedIcon,
          iconPosition
        },
        cellDoubleClickEnabled: cellClickEnabled,
        showInMaxMode,
        customAggregation,
        rowGroup: grouped,
        aggFunc,
        template,
        summaryTemplate,
        columnType: newColumnType,
        // TODO: Add default value at schema.js and update mongo or create a function that puts the default value
        cellTooltip,
        // TODO: Add default value at schema.js and update mongo or create a function that puts the default value
        formatField,
        hiddenCondition,
        highchartOptions,
        sparklineOptions,
        progressOptions,
        width,
        groupHeader,
        headerName: header,
        hide: hidden,
        rawField: fieldName,
        isRawFieldEmpty
      },
      field,
      flex: locked ? 0 : flex,
      headerName: header,
      headerTooltip: tooltip,
      pinned: locked ? 'left' : false,
      sortable: isSortable,
      suppressHeaderMenuButton: hideMenu
    }

    return newColumnProps
  })
})

export type ConfigColumn = ReturnType<typeof selectConfigColumns>[number]
export default selectConfigColumns
