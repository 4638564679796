Ext.define('MDM.src.view.materialedit.model.Dpi', {
  extend: 'MDM.src.view.materialedit.model.Base',
  idProperty: 'id',
  entityName: 'gillettev3/RMDM_Material_Edit_DPIFamilyRelation',
  requires: ['MDM.src.view.materialedit.model.Cartridge'],
  fields: [
    {
      BOMID: 'name',
      type: 'int'
    },
    {
      name: 'DPIFamilyID',
      type: 'int'
    },
    {
      name: 'DPIFamily',
      type: 'string'
    },
    {
      name: 'MaterialID',
      type: 'int'
    },
    {
      name: 'GU',
      type: 'float'
    },
    {
      name: 'IsRazor',
      type: 'int'
    }
  ],
  hasMany: 'Cartridge'
})
