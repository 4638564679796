import { EmptyObject } from '../../types'
import { GroupRowInnerRenderer } from '../../components/CellRenderers'
import {
  GetGroupProps,
  GetGroupPropsReturnType,
  GetGroupRowRendererParams,
  GetGroupRowRendererParamsReturnType,
  GetGroupRowRendererParamsWithGrouping,
  GetGroupRowRendererParamsWithoutGrouping
} from './group.types'

function getGroupProps({
  gridGrouping,
  rowGroup,
  cannotGroup
}: GetGroupProps): GetGroupPropsReturnType {
  return {
    rowGroup: gridGrouping && rowGroup && !cannotGroup,
    enableRowGroup: gridGrouping && !cannotGroup
  }
}

function getGroupRowRendererParams({
  groupDisplayType,
  grouping,
  suppressCount,
  cellDisplayRules,
  configColumns,
  fieldConfigs,
  handleActionBtnClick,
  editableFields
}: GetGroupRowRendererParamsWithGrouping): GetGroupRowRendererParamsReturnType
function getGroupRowRendererParams({
  groupDisplayType,
  grouping,
  suppressCount,
  cellDisplayRules,
  configColumns,
  fieldConfigs,
  handleActionBtnClick,
  editableFields
}: GetGroupRowRendererParamsWithoutGrouping): EmptyObject
function getGroupRowRendererParams({
  groupDisplayType,
  grouping,
  suppressCount,
  cellDisplayRules,
  configColumns,
  fieldConfigs,
  handleActionBtnClick,
  editableFields
}: GetGroupRowRendererParams): EmptyObject
function getGroupRowRendererParams({
  groupDisplayType,
  grouping,
  suppressCount,
  cellDisplayRules,
  configColumns,
  fieldConfigs,
  handleActionBtnClick,
  editableFields
}:
  | GetGroupRowRendererParamsWithoutGrouping
  | GetGroupRowRendererParamsWithGrouping
  | GetGroupRowRendererParams) {
  if (!grouping || groupDisplayType !== 'groupRows') {
    return {}
  }

  return {
    groupRowRendererParams: {
      suppressCount,
      innerRendererParams: {
        cellDisplayRules,
        configColumns,
        fieldConfigs,
        handleActionBtnClick,
        editableFields
      },
      innerRenderer: GroupRowInnerRenderer
    }
  }
}

export { getGroupProps, getGroupRowRendererParams }
