import { SlvySpinner, SlvyTable } from '@/components'
import BaseModal from '../../../BaseModal'
import { useReduxState } from '../../../../hooks'
import { sessionSlice, tuiSlice } from '../../../../store/slices'
import { ServicePlanInfo, getServiceStatus, getSnapshotDiff } from '../../../../store/api/endpoints'
import { columns, valueFormat } from '../../../../constants/differenceModal'
import { calculateDifferences } from '../../../../utils'

function DiffScreenModal({ selectedId }) {
  const [{ config }] = useReduxState(tuiSlice.selectSlice)
  const [sessionState, sessionDispatch] = useReduxState(
    sessionSlice.selectSlice,
    sessionSlice.actions
  )
  const allSummary = getSnapshotDiff.useQueryState({
    id: selectedId
  }).currentData?.result?.DiffData[0]?.Summaries
  const planInfo: ServicePlanInfo =
    getServiceStatus.useQueryState()?.currentData?.PlanInfo ?? ({} as ServicePlanInfo)

  const allDifferences = calculateDifferences(allSummary, planInfo, config, valueFormat)

  const handleDiffScreenCloseButton = () => {
    sessionDispatch.setIsDiffScreenModalOpen(false)
  }

  return (
    <BaseModal
      isOpen={sessionState.isDiffScreenModalOpen}
      size="xl"
      onClose={handleDiffScreenCloseButton}
    >
      <BaseModal.Header>
        <BaseModal.Title>Diff Screen</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body>
        {allDifferences?.length ? (
          <SlvyTable suppressColumnMenu className="fs-sm" columns={columns} data={allDifferences} />
        ) : (
          <SlvySpinner size="sm" />
        )}
      </BaseModal.Body>
    </BaseModal>
  )
}

export default DiffScreenModal
