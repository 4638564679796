import { getProgressColumnOptions } from '../sparkline'

describe('AgGrid:Helpers:Common:getProgressColumnOptions', () => {
  it('should return correct object including type, label and formatter', () => {
    const result = getProgressColumnOptions()
    expect(result).toMatchObject({
      type: 'bar',
      label: {
        enabled: true,
        color: '#000',
        fontSize: 10,
        fontWeight: 'bold',
        formatter: expect.any(Function)
      },
      formatter: expect.any(Function)
    })
  })
})
