import cx from 'classnames'
import { withTextIconSize } from '../../../constants'
import DefaultCellRendererProps from './CellRenderer.types'
import { Theme } from '../../../types'

const displayOnlyIconSize: Record<Theme, 'fs-5' | 'fs-6'> = {
  balham: 'fs-6',
  alpine: 'fs-5',
  quartz: 'fs-5'
}

const IconRenderer = ({ className }: { className: string }) => <i className={className} />

const CellRenderer = (props: DefaultCellRendererProps) => {
  const {
    api,
    icon: { icon: selectedIcon = '', displayOnlyIcon, iconPosition },
    valueFormatted,
    context: { groupDisplayType, theme },
    colDef: {
      customInfo,
      customInfo: { isAutoColumn }
    },
    withText = false,
    node: { group }
  } = props

  let shouldWrapWithStrong = false

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    shouldWrapWithStrong = true
  }

  let iconClassName = cx('fa', selectedIcon)

  if (!selectedIcon) {
    return shouldWrapWithStrong ? <strong>{valueFormatted}</strong> : <>{valueFormatted}</>
  }

  if (displayOnlyIcon) {
    iconClassName += ` ${(withText ? withTextIconSize : displayOnlyIconSize)[theme]}`
    iconClassName += ' slvy-i-element'
    return (
      <>
        <IconRenderer className={iconClassName} />
      </>
    )
  }

  iconClassName += ` ${withTextIconSize[theme]}`

  // Sencha-Grid specific usage:
  // Case: Cell right aligned but icon left aligned
  // for backward compatability
  const isReversed =
    !withText && iconPosition === 'left' && customInfo?.cellStyle?.textAlign === 'right'

  const isIconLeftAligned = iconPosition === 'left'

  if (!isReversed) {
    return (
      <>
        {isIconLeftAligned ? (
          <>
            <IconRenderer className={iconClassName} /> {valueFormatted}
          </>
        ) : (
          <>
            {valueFormatted} <IconRenderer className={iconClassName} />
          </>
        )}
      </>
    )
  }

  return (
    <div className="d-flex justify-content-between">
      <span>
        <IconRenderer className={iconClassName} />
      </span>
      {shouldWrapWithStrong ? <strong>{valueFormatted}</strong> : <span>{valueFormatted}</span>}
    </div>
  )
}

export default CellRenderer
