const defaultForm = {
  splitOpenedTitle: '',
  splitCollapsedTitle: '',
  horizontal: true,
  panesize: 25,
  isCollapsedInitially: false,
  defaultPane: 'top',
  isSizeChangeable: false,
  step: 10
} as const

export default defaultForm
