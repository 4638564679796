import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import thunk from 'redux-thunk'
import app from './slices/appSlice'
import assortment from './slices/assortment'
import clusterTreeApi from './apis/clusterTreeApi'
import collapse from './slices/collapse'
import libraryTreeApi from './apis/libraryTreeApi'
import minPresentationData from './slices/minPresentationData'
import options from './slices/options'
import optionSettings from './slices/optionSettings'
import periodAggregations from './slices/periodAggregations'
import plugin from './slices/plugin'
import similarOptions from './slices/similarOptions'
import testOptions from './slices/testOptions'
import timelineSettings from './slices/timelineSettings'
import tree from './slices/tree'
import treeSelection from './slices/treeSelection'

export const store = configureStore({
  reducer: {
    app: app,
    assortment: assortment,
    clusterTreeApiReducer: clusterTreeApi.reducer,
    collapse: collapse,
    libraryTreeApiReducer: libraryTreeApi.reducer,
    minPresentationData: minPresentationData,
    options: options,
    optionSettings: optionSettings,
    periodAggregations: periodAggregations,
    plugin: plugin,
    similarOptions: similarOptions,
    testOptions: testOptions,
    timelineSettings: timelineSettings,
    tree: tree,
    treeSelection: treeSelection
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['similarOptions'],
        ignoredActions: ['similarOptions/setColumns']
      }
    })
      .concat(clusterTreeApi.middleware)
      .concat(libraryTreeApi.middleware)
  }
})

export {
  useCreateLibraryTreeFolderNodeMutation,
  useDeleteLibraryTreeNodeMutation,
  useUpdateLibraryTreeNodeMutation
} from './apis/libraryTreeApi'
export { useFetchClusterTreeMutation } from './apis/clusterTreeApi'
