import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import settings from '../../utils/settings'
import optionFieldsList from '../../utils/optionFieldsList'

export default class OptionFields extends Component {
  constructor(props) {
    super(props)
    this.settings = { ...settings }
    this._optionFieldsList = this.getOptionFieldsList()
    this._optionViewColumns = this.getOptionViewColumns()
  }

  getOptionViewColumns() {
    const { props: { optionViewColumns = [] } = {} } = this
    const startEndDate = this.getStartEndDate(optionViewColumns)

    const _optionViewColumns = _.filter(
      optionViewColumns,
      (column) => column.name !== 'StartDate' && column.name !== 'EndDate'
    )
    if (startEndDate) {
      _optionViewColumns.splice(startEndDate.index, 0, startEndDate)
    }
    return _optionViewColumns
  }

  getOptionFieldsList() {
    const { props: { optionViewColumns = [] } = {} } = this

    const _optionFieldsList = _.filter(
      optionFieldsList,
      (column) => column.name !== 'StartDate' && column.name !== 'EndDate'
    )
    const startEndDate = this.getStartEndDate(optionViewColumns)
    if (startEndDate) {
      startEndDate.format = function ({ startDate, endDate }, formatOptions) {
        const { DateFormat } = formatOptions
        const startVal = startEndDate.isStartDateVisible
          ? moment(startDate).format(DateFormat)
          : null
        const endVal = startEndDate.isEndDateVisible ? moment(endDate).format(DateFormat) : null
        return `${startVal || ''} - ${endVal || ''}`
      }
      _optionFieldsList.splice(startEndDate.index, 0, startEndDate)
    }
    return _optionFieldsList
  }

  getFieldValue(column, formatOptions) {
    const {
      props: { data = {}, SizeRangeMapping = {} }
    } = this
    if (column.isVisible) {
      let formattedValue
      const fieldInfo = _.find(this._optionFieldsList, (item) => item.name === column.name)
      let value = data[fieldInfo.name]

      if (fieldInfo.name === 'SizeRangeId') {
        const sizeRange = SizeRangeMapping[data.SizeRangeId]
        value = _.isUndefined(sizeRange) ? '' : sizeRange
      }

      if (fieldInfo.name === 'StartDate_EndDate') {
        formattedValue = fieldInfo.format(
          { startDate: data.StartDate, endDate: data.EndDate },
          formatOptions
        )
      } else {
        formattedValue = fieldInfo.format(value, formatOptions)
      }

      return formattedValue
    }
    return null
  }

  getStartEndDate(optionViewColumns) {
    const startDate = _.find(optionViewColumns, (column) => column.name === 'StartDate')

    const startDateIndex = _.findIndex(optionViewColumns, (column) => column.name === 'StartDate')

    const endDate = _.find(optionViewColumns, (column) => column.name === 'EndDate')
    const endDateIndex = _.findIndex(optionViewColumns, (column) => column.name === 'EndDate')

    let startEndDate = null
    if ((startDate && startDate.isVisible) || (endDate && endDate.isVisible)) {
      startEndDate = {
        name: 'StartDate_EndDate',
        isVisible: true,
        isStartDateVisible: startDate && startDate.isVisible,
        isEndDateVisible: endDate && endDate.isVisible,
        index: startDateIndex !== -1 ? startDateIndex : endDateIndex
      }
    }
    return startEndDate
  }

  getOptionFieldsValues() {
    const {
      props: {
        removeCurrencySymbol = () => {},
        pluginSettings: { formatting: { current = '0,0', decimal = '0,0.00' } = {} } = {}
      },
      settings: { DateFormat }
    } = this

    const formatOptions = {
      current,
      decimal,
      DateFormat,
      removeCurrencySymbol
    }

    const fieldValues = _.map(this._optionViewColumns, (column) => {
      const formattedValue = this.getFieldValue(_.cloneDeep(column), formatOptions)
      if (!_.isNil(formattedValue)) {
        return <li key={column.name}>{formattedValue}</li>
      }
      return null
    })

    return _.isNil(fieldValues) ? null : fieldValues
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    const {
      props: { IsPreviewMode = false }
    } = this

    return (
      <ul className="card-text-ctn">
        {IsPreviewMode ? null : <li> &nbsp; </li>}
        {this.getOptionFieldsValues()}
        <li className="lastCell"> &nbsp; </li>
      </ul>
    )
  }
}
