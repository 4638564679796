import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import Picker from 'rc-calendar/lib/Picker'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import SegmentSettings from '../SegmentSettings'
import settings from '../../utils/settings'
import { objectToArray, removeCurrencySymbol } from '../../utils'
import { slvyToast } from '../../../../components'
import CopyFromPlanningSegments from '../CopyFromPlanningSegments'
import { CURRENCIES_LOCALES } from '../../utils/currencies'
import { setLoaderReducer } from '../../store/slices/appSlice'
import { selectOption, getOptions } from '../../store/slices/options'

class OptionForm extends Component {
  constructor(props) {
    super(props)
    this.settings = { ...settings }
    this.state = {
      value: [],
      hoverValue: [],
      show: false,
      isShownCopyParameters: false
    }
    this.currenciesLocales = { ...CURRENCIES_LOCALES }
  }

  componentDidMount() {
    const {
      props: {
        data: { StartDate = '', EndDate = '' }
      }
    } = this

    this.setState({
      value: [moment(StartDate), moment(EndDate)]
    })
  }

  eventHandler(event) {
    event.stopPropagation()
  }

  isValidRange(value) {
    return value && value[0] && value[1]
  }

  rangeCalendar(start, end) {
    const { hoverValue = [] } = this.state
    const onHoverChange = (hoverValue) => this.setState({ hoverValue })
    return (
      <RangeCalendar
        showClear
        className="assortment-option-form-range-calendar"
        dateInputPlaceholder={['start', 'end']}
        defaultValue={[start, end]}
        hoverValue={hoverValue}
        showOk={false}
        showWeekNumber={false}
        onHoverChange={onHoverChange}
      />
    )
  }

  onUpdateOption(event) {
    const {
      basePriceInputId = '',
      data,
      data: { SegmentOptions = [], Editable: isFutureOption = false },
      assortmentId = '',
      onUpdateOption = () => {}
    } = this.props

    event.preventDefault()

    if (!isFutureOption) {
      slvyToast.error({ message: 'Options can not update!', title: 'Update Option' })
      return
    }

    const { target = {} } = event

    const targetData = _.transform(
      data,
      (result, value, key) => {
        const element = target[key]
        if (element) {
          result[key] = element.value
        }

        if (key === 'StartDate' || key === 'EndDate') {
          _.map(SegmentOptions, (item) => {
            item = item[key] = element.value
            return item
          })
        }
        return result
      },
      {}
    )

    const request = { ...data, ...targetData }

    let $basePriceVal
    const $basePriceElement = document.getElementById(basePriceInputId)
    if ($basePriceElement.hasAttribute('data-price')) {
      $basePriceVal = $basePriceElement.getAttribute('data-price')
    } else {
      $basePriceVal = $basePriceElement.value
    }

    request.BasePrice = numeral($basePriceVal).value()

    onUpdateOption({
      OptionStr: request,
      AssortmentId: assortmentId,
      cost: numeral(request.Expenditure).value(),
      price: request.BasePrice
    })
  }

  // TODO not using
  // getSimilarOptions() {
  //   const { similarViewHandler = () => {}, onSelectOption = () => {}, data = {} } = this.props

  //   onSelectOption(data)

  //   similarViewHandler(true)
  // }

  handleCloseSettingsModal() {
    this.setState({ show: false })
  }

  handleOpenModal(event) {
    this.eventHandler(event)
    this.setState({ show: true })
  }

  onEditTestProduct(event) {
    this.eventHandler(event)

    const { onTestProductsToggle = () => {}, onSelectOption = () => {}, data = {} } = this.props

    onSelectOption(data)

    onTestProductsToggle(true, 'Edit')
  }

  onDeleteTestProduct(event) {
    event.stopPropagation()

    const { data = {}, onDeleteTestOption = () => {} } = this.props

    onDeleteTestOption(data)
  }

  onSelectAllSegments(selectType, event) {
    this.eventHandler(event)
    const {
      data,
      data: { SizeRangeId = null, Editable: isFutureOption = false },
      onSelectAllSegments = () => {}
    } = this.props

    if (!SizeRangeId) {
      slvyToast.warning({
        message: 'Please enter a valid Size Range Type.',
        title: 'Missing Size Range Type'
      })
      return
    }

    if (!isFutureOption) {
      slvyToast.error({ message: 'Option can not select/unselect!' })
      return
    }

    onSelectAllSegments(data, selectType)
  }

  onCopyFromPlanningParameters(selectedProduct) {
    const {
      props: {
        pluginId = '',
        fetchOptions = () => {},
        setLoader = () => {},
        rootOptions: { AssortmentId } = {},
        data: { Id: TargetProductId = null } = {}
      }
    } = this

    const { key: SourceProductId = null } = selectedProduct

    const params = {
      AssortmentId,
      SourceProductId,
      TargetProductId
    }

    setLoader({ isShown: true, messages: 'Update Option' })

    const payload = {
      pluginId,
      method: 'CopyPlanningParameters',
      requestMethod: 'post',
      body: {
        ...params
      }
    }

    fetchOptions({ ...payload }).then(() => {
      const { rootOptions = {} } = this.props

      if (!_.isEmpty(rootOptions)) {
        setLoader({ isShown: false })
      }
    })
  }

  getSegmentCount(SegmentOptions = {}) {
    const _SegmentOptions = objectToArray(SegmentOptions)
    const enabledSegmentOptions = [..._SegmentOptions].filter(({ Enabled = true }) => Enabled)
    return {
      totalCount: _SegmentOptions.length,
      enabledCount: enabledSegmentOptions.length,
      disabledCount: _SegmentOptions.length - enabledSegmentOptions.length
    }
  }

  getSelectType(SegmentOptions) {
    const {
      totalCount = 0,
      enabledCount = 0,
      disabledCount = 0
    } = this.getSegmentCount(SegmentOptions)

    return (enabledCount >= disabledCount || disabledCount === totalCount) &&
      enabledCount !== totalCount
      ? 'Select'
      : 'Unselect'
  }

  getTooltip(text, id = Math.random()) {
    return <Tooltip id={id}>{text}</Tooltip>
  }

  toggleCopyFromPlanningParameters(isShownCopyParameters, event) {
    this.eventHandler(event)
    const { data: { Editable: isFutureOption = false } = {} } = this.props

    if (!isFutureOption) {
      slvyToast.error({ message: 'Option can not copy planning parameters!' })
      return
    }

    this.setState({ isShownCopyParameters })
  }

  removeCurrencySymbol(value, format) {
    const { formattingSettings: { currencySymbol = '$' } = {} } = this.props

    return removeCurrencySymbol(numeral(value).format(format), currencySymbol)
  }

  isFieldVisible(fieldName) {
    const { props: { optionViewColumns } = {} } = this

    return _.some(optionViewColumns, (column) => {
      return column.name === fieldName && column.isVisible
    })
  }

  render() {
    let {
      props: {
        formattingSettings,
        formattingSettings: { current = '0,0', decimal = '0,0.00', currencySymbol = '$' } = {},
        optionFieldsEditable = true,
        rootOptions: { Options: AssortmentOptions = [] } = {},
        data,
        data: {
          Id,
          Editable: isFutureOption = false,
          StartDate = '',
          EndDate = '',
          Expenditure = 0,
          IMU = 0,
          TotalRevenue = 0,
          TotalCost = 0,
          SizeRangeId = 0,
          TotalMinPresentation = 0,
          NumberOfActiveStores = 0,
          ROS = 0,
          Sellthrough = 0,
          Markdown = 0,
          PrePackSize = 0,
          TotalPrePackQuantity = 0,
          GlobalTotalStock = 0,
          SegmentOptions = [],
          IsTestProduct = false
        },
        assortmentId = '',
        sizeRangeMapping: SizeRangeMapping = {},
        onUpdateOption = () => {},
        culture
      },
      settings: { DateFormat, StateDateFormat },
      state: { show = true, isShownCopyParameters = false },
      currenciesLocales: {
        [culture]: { thousandSeparator, decimalSeparator, decimalScale } = {}
      } = {}
    } = this

    StartDate = moment(StartDate)
    EndDate = moment(EndDate)

    const selectType = this.getSelectType(SegmentOptions)
    const isUpdateDisabled = isFutureOption === false
    const optionsEditable = optionFieldsEditable && isFutureOption

    const $extraFix =
      currencySymbol === '$' ? { prefix: currencySymbol } : { suffix: currencySymbol }

    return (
      <form ref={Id} onSubmit={this.onUpdateOption.bind(this)}>
        <div className="overlayOptions">
          <OverlayTrigger overlay={this.getTooltip('Settings')} placement="top">
            <button
              className="similar assortment-setting-btn"
              type="button"
              onClick={this.handleOpenModal.bind(this)}
            >
              <span className="slvy-ui-icon-cog-outline" />
            </button>
          </OverlayTrigger>
          <OverlayTrigger overlay={this.getTooltip(`${selectType} All Segments`)} placement="top">
            <button
              className="similar assortment-setting-btn select-unselect-segments"
              type="button"
              onClick={this.onSelectAllSegments.bind(this, selectType)}
            >
              <span
                className={`slvy-ui-icon-circle-${selectType === 'Select' ? 'check' : 'outline'}`}
              />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={this.getTooltip('Copy From Planning Parameters')}
            placement="top"
          >
            <button
              className="similar assortment-setting-btn copy-from-planning-parameters"
              type="button"
              onClick={this.toggleCopyFromPlanningParameters.bind(this, true)}
            >
              <span className="slvy-ui-icon-copy" />
              <CopyFromPlanningSegments
                copyFromPlanningSegmentsHandler={this.onCopyFromPlanningParameters.bind(this)}
                isShown={isShownCopyParameters}
                options={AssortmentOptions}
              />
            </button>
          </OverlayTrigger>
          {IsTestProduct ? (
            <OverlayTrigger overlay={this.getTooltip('Edit Test Product')} placement="top">
              <button
                className="similar assortment-setting-btn edit-test-product"
                type="button"
                onClick={this.onEditTestProduct.bind(this)}
              >
                <span className="slvy-ui-icon-edit" />
              </button>
            </OverlayTrigger>
          ) : null}
          {IsTestProduct ? (
            <OverlayTrigger overlay={this.getTooltip('Delete Test Product')} placement="top">
              <button
                className="similar assortment-setting-btn delete-test-product"
                type="button"
                onClick={this.onDeleteTestProduct.bind(this)}
              >
                <span className="slvy-ui-icon-trash-can" />
              </button>
            </OverlayTrigger>
          ) : null}
        </div>
        <SegmentSettings
          culture={culture}
          SegmentOptions={SegmentOptions}
          assortmentId={assortmentId}
          data={data}
          handleCloseModal={this.handleCloseSettingsModal.bind(this)}
          isUpdateDisabled={isUpdateDisabled}
          removeCurrencySymbol={this.removeCurrencySymbol.bind(this)}
          show={show}
          onUpdateOption={onUpdateOption.bind(this)}
        />

        <ul className="card-text-ctn">
          <li> &nbsp; </li>
          {this.isFieldVisible('TotalRevenue') && <li>{numeral(TotalRevenue).format(current)}</li>}
          {this.isFieldVisible('TotalCost') && <li>{numeral(TotalCost).format(current)}</li>}
          {this.isFieldVisible('Expenditure') && (
            <li>
              <NumberFormat
                fixedDecimalScale
                className="form-items"
                decimalScale={decimalScale}
                decimalSeparator={decimalSeparator}
                defaultValue={numeral(Expenditure).format(decimal)}
                disabled={!optionsEditable}
                name="Expenditure"
                thousandSeparator={thousandSeparator}
                type="text"
                onClick={this.eventHandler}
                {...$extraFix}
              />
            </li>
          )}
          {this.isFieldVisible('IMU') && (
            <li>{isNaN(IMU) ? '' : this.removeCurrencySymbol(IMU, '')}</li>
          )}
          {(this.isFieldVisible('StartDate') || this.isFieldVisible('EndDate')) && (
            <li>
              <Picker
                animation="slide-up"
                calendar={this.rangeCalendar(StartDate, EndDate)}
                defaultValue={[StartDate, EndDate]}
                onChange={(value) => this.setState({ value })}
              >
                {({ value }) => {
                  const [start, end] = value
                  return (
                    <input
                      readOnly
                      className="form-items"
                      disabled={!optionsEditable}
                      placeholder="Please select dates"
                      type="text"
                      value={
                        this.isValidRange(value)
                          ? `${start.format(DateFormat)} - ${end.format(DateFormat)}`
                          : ''
                      }
                      onClick={this.eventHandler}
                    />
                  )
                }}
              </Picker>
              <input
                className="form-items"
                defaultValue={moment(_.first(this.state.value)).format(StateDateFormat)}
                disabled={!optionsEditable}
                name="StartDate"
                type="hidden"
              />
              <input
                className="form-items"
                defaultValue={moment(_.last(this.state.value)).format(StateDateFormat)}
                disabled={!optionsEditable}
                name="EndDate"
                type="hidden"
              />
            </li>
          )}
          {this.isFieldVisible('TotalMinPresentation') && (
            <li>{this.removeCurrencySymbol(TotalMinPresentation, current)}</li>
          )}
          {this.isFieldVisible('SizeRangeId') && (
            <li>
              <select
                className="form-items"
                defaultValue={SizeRangeId}
                disabled={!optionsEditable}
                name="SizeRangeId"
                onClick={this.eventHandler}
              >
                {_.map(SizeRangeMapping, (value, key) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </li>
          )}
          {this.isFieldVisible('Sellthrough') && (
            <li>
              <NumberFormat
                fixedDecimalScale
                className="form-items"
                decimalScale={decimalScale}
                decimalSeparator={decimalSeparator}
                defaultValue={numeral(Sellthrough).format(decimal)}
                disabled={!optionsEditable}
                name="Sellthrough"
                suffix="%"
                thousandSeparator={thousandSeparator}
                type="text"
                onClick={this.eventHandler}
              />
            </li>
          )}
          {this.isFieldVisible('NumberOfActiveStores') && <li>{NumberOfActiveStores}</li>}
          {this.isFieldVisible('ROS') && (
            <li>{isNaN(ROS) ? '' : this.removeCurrencySymbol(ROS, '')}</li>
          )}
          {this.isFieldVisible('Markdown') && <li>{Markdown} %</li>}
          {this.isFieldVisible('PrePackSize') && (
            <li>
              <NumberFormat
                className="form-items"
                defaultValue={numeral(PrePackSize).format('')}
                disabled={!optionsEditable}
                name="PrePackSize"
                onClick={this.eventHandler}
              />
            </li>
          )}
          {this.isFieldVisible('TotalPrePackQuantity') && <li>{TotalPrePackQuantity}</li>}
          {this.isFieldVisible('GlobalTotalStock') && <li>{GlobalTotalStock}</li>}
        </ul>
        {optionFieldsEditable ? (
          <button className="update-option" type="submit" onClick={this.eventHandler}>
            Update
          </button>
        ) : (
          <div className="update-option" />
        )}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootOptions: state.options.rootOptions,
    formattingSettings: state.plugin.formattingSettings,
    optionFieldsEditable: state.plugin.optionFieldsEditable
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectOption: bindActionCreators(selectOption, dispatch),
    fetchOptions: bindActionCreators(getOptions, dispatch),
    setLoader: bindActionCreators(setLoaderReducer, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionForm)
