export default class Queue {
  static queue = []

  static pendingPromise = false

  static stop = false

  static enqueue(promise, updateRequest) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        updateRequest,
        promise,
        resolve,
        reject
      })
      this.dequeue()
    })
  }

  static dequeue() {
    if (this.workingOnPromise) {
      return false
    }
    if (this.stop) {
      this.queue = []
      this.stop = false
      return
    }
    const item = this.queue.shift()
    if (!item) {
      return false
    }
    try {
      this.workingOnPromise = true

      const req = item.updateRequest()
      item
        .promise(req)
        .then((value) => {
          this.workingOnPromise = false
          item.resolve(value)
          this.dequeue()
        })
        .catch((err) => {
          this.workingOnPromise = false
          item.reject(err)
          this.dequeue()
        })
    } catch (err) {
      this.workingOnPromise = false
      item.reject(err)
      this.dequeue()
    }
    return true
  }
}
