import _ from 'lodash'

const PluginUpdateQueue = () => {
  const updateQueues = {}

  const pluginUpdateQueueInstance = {
    enqueueUpdateRequest(pluginId, request) {
      if (!updateQueues[pluginId]) {
        updateQueues[pluginId] = []
      }
      updateQueues[pluginId].push(request)
    },

    dequeueUpdateRequest(pluginId) {
      if (_.size(updateQueues[pluginId]) > 0) {
        return updateQueues[pluginId].shift()
      }
    },

    getQueueSize(pluginId) {
      let size = 0
      if (_.size(updateQueues[pluginId]) > 0) {
        size = _.size(updateQueues[pluginId])
      }
      return size
    },

    updateInProgress() {
      let updateInProgress = false
      _.forEach(updateQueues, (pluginQueue) => {
        if (_.size(pluginQueue) > 0) {
          updateInProgress = true
          return false
        }
      })
      return updateInProgress
    }
  }

  return pluginUpdateQueueInstance
}

export default PluginUpdateQueue()
