Ext.define('Spui.view.common.productinfo.cmp.tabpanel.Inventory.Mixin', {
  extend: 'Ext.Mixin',
  config: {
    inventoryCfg: 'otherCfg',
    inventory: {},
    inventoryStoreCfg: 'otherCfgInevtoryStore',
    inventoryStore: {},

    materialCode: false
  },
  listeners: {
    activate: function (p) {
      if (p.forceToLoadStore) p.getInventoryStore().load()
    }
  },
  applyInventoryCfg: function (c) {
    return window.ss(c)
  },
  applyInventoryStoreCfg: function (c) {
    return window.ss(c)
  },
  applyInventoryStore: function (b) {
    var me = this
    var setRec = this.getInventoryStoreCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, res)
      Ext.applyIf(b, {
        fields: [
          { name: 'QTY', type: 'number' },
          { name: 'Price', type: 'number' },
          { name: 'NumberOfStore', type: 'number' },
          { name: 'Envanter', type: 'number' },
          { name: 'DateKey', type: 'string' },
          {
            name: 'PriceNull',
            convert: function (value, record) {
              var v = record.get('Price')

              if (!v) return null
              return v
            },

            depends: ['Price']
          },
          {
            name: 'NumberOfStoreNull',
            convert: function (value, record) {
              var v = record.get('NumberOfStore')

              if (!v) return null
              return v
            },

            depends: ['NumberOfStore']
          }
        ],
        listeners: {
          beforeload: function () {
            me.getInventory().setLoading(tt('Yükleniyor'))
          },
          load: function () {
            me.getInventory().setLoading(false)

            this.forceToLoadStore = false
          }
        },
        proxy: {
          type: 'ajax',
          timeout: 120000,
          actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
          },
          data: [
            {
              DateKey: 0
            }
          ],
          url: Spui.Global.getApiUrl() + 'spui/GetInventoryListForMaterialCode'
        },
        autoLoad: false
      })

      b = Ext.create('Ext.data.Store', b)
    }
    return b
  },
  applyInventory: function (b) {
    var setRec = this.getInventoryCfg()

    var code = setRec.get('code')
    var settings = code ? code : '({})'
    var res = new Function('return ' + code)()

    if (b && !b.isComponent) {
      Ext.applyIf(b, res)

      Ext.applyIf(b, {
        xtype: 'grid',
        store: this.getInventoryStore(),
        plugins: [
          {
            ptype: 'cornerIcon',
            cfgButton: [
              '->',

              {
                iconCls: 'x-fa fa-cog',
                cls: 'config-btn',
                menu: [
                  {
                    text: 'GridConfig',
                    handler: 'onConfigureInventory'
                  },
                  {
                    text: 'Store Config',
                    handler: 'onConfigureInventoryStore'
                  }
                ]
              }
            ]
          }
        ],
        title: 'Vendor list',
        columns: [{ text: 'title' }]
      })

      b = Ext.create(b)
    }

    return b
  },
  updateInventory: function (f, g) {
    if (g) {
      g.destroy()
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(1, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  updateMaterialCode: function (c, d) {
    if (c == d) return
    if (!c) return
    var store = this.getInventoryStore()

    store.getProxy().setExtraParam('MaterialCode', c)

    if (this.isVisible()) {
      store.load()

      this.forceToLoadStore = false
    } else {
      this.forceToLoadStore = true
    }
  },
  onConfigureInventory: function () {
    var setRec = this.getInventoryCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        snippetPanel: {
          record: setRec
        },
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigureInventorySave, this)
  },
  onConfigureInventorySave: function (v, k) {
    var setRec = this.getInventoryCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()

    var res = new Function('return ' + k)()

    this.setInventory(res)
  },
  onConfigureInventoryStore: function () {
    var setRec = this.getInventoryStoreCfg(),
      code = setRec.get('code')
    var settings = code ? code : '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create(
      'Adm.editor.Panel',

      {
        width: 100,
        height: 100,
        maximized: true,
        value: settings,
        snippetPanel: {
          record: setRec
        },
        aceOptions: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          fontSize: '12pt'
        }
      }
    ).show()
    c.on('save', this.onConfigureInventoryStoreSave, this)
  },
  onConfigureInventoryStoreSave: function (v, k) {
    var setRec = this.getInventoryStoreCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()

    var res = new Function('return ' + k)()

    this.setInventory(res)
  }
})
