export default `({
    viewConfig: {
        getRowClass: function(record, rowIndex, rowParams, store) {
            if (record.get('ContractIsActive')) {
                return 'row-blur';
            }
            if (record.get('AutoApproved')) {
                return 'AutoApprovedSpot'
            }
            return ''
        }
    },
    plugins: [{
        ptype: 'rowexpander',
        rowBodyTpl: ['<div class="expander">', '<table class="table">', '<tr>', '</tr>', '  <tr>', '       <th class=r>Solvoyo</th>', '      <td class="r">{OrderRecommendationParent}</td>', '       <th class=r>' + tt('Merkez') + '</th>', '      <td class="r">{OrderRecommendationCurrent}</td>', '      <th class="r">' + tt('Bölge') + '</th>', '<tpl if="OrderRecommendationChild !== 0">', '      <td class="r">{OrderRecommendationChild}</td>', '<tpl else>', '      <td class="r">{OrderRecommendationCurrent}</td>', '</tpl>', // '   </tr>',
        // '  <tr>',
        // '       <th class=r>NumberOf Org</th>',
        // '      <td class="r">{NumberOfRecommendedOrg}</td>',
        // '     <th class="r">Waiting Org</th>',
        // '      <td class="r">{NumberOfWaitingOrg}</td>',
        // '       <th class="r"> Delegated</th>',
        // '      <td class="r">{NumberOfDelegatedOrg}</td>',
        // '   </tr>',
        // '</tr>',
        // '  <tr>',
        // '      <th class="r"> Approved </td>',
        // '      <td class="r">{NumberOfApprovedOrg}</td>',
        // '      <th class="r">Dismissed</td>',
        // '      <td class="r" >{NumberOfDismissedOrg}</td>',
        // '   </tr>',
        '</table>', '</div>', ]
    }, {
        ptype: 'gridfilters',
        id: 'gridfilters'
    }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    multiColumnSort: true,
    columnLines: true,
    columns: [{
        text: tt('MaterialCode'),
        dataIndex: 'MaterialCode',
        width: 100,
        renderer: function(v) {
            return v * 1;
        },
        filter: {
            type: 'string'
        }
    }, {
        text: tt('MaterialDescription'),
        dataIndex: 'MaterialDescription',
        width: 200,
        filter: {
            type: 'string'
        }
    }, {
        text: tt('Start Date'),
        dataIndex: 'PreStartDate',
        xtype: 'datecolumn',
        format: tt('d-m-Y'),
        width: '90',
        filter: {
            type: 'date'
        }
    }, {
        text: tt('End Date'),
        dataIndex: 'PreEndDate',
        xtype: 'datecolumn',
        format: tt('d-m-Y'),
        width: '90',
        filter: {
            type: 'date'
        }
    }, {
        text: tt('SalesPrice'),
        dataIndex: 'SalesPrice',
        width: 100,
        format: '0,000.00',
        align: 'right',
        xtype: 'numbercolumn',
        filter: {
            type: 'number'
        }
    }, {
        text: tt('UnitsPerPackage'),
        dataIndex: 'UnitsPerPackage',
        width: 100,
        format: '000.00',
        align: 'right',
        filter: {
            type: 'number'
        },
        xtype: 'numbercolumn'
    },
    // {
    //     text: 'SpotId',
    //     dataIndex: 'SpotId',
    //     width: 100,
    //     format: '000',
    //     align: 'right',
    //     xtype: 'numbercolumn'
    // },
    {
        text: tt('Status'),
        columns: [{
            text: tt('Total'),
            dataIndex: 'NumberOfRecommendedOrg',
            width: 80,
            format: '000',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        }, {
            text: tt('Waiting'),
            dataIndex: 'NumberOfWaitingOrg',
            width: 80,
            format: '000',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn',
            // renderer: function (value, metaData, record) {
            //     var t = record.get('NumberOfDelegatedOrg');
            //     r = parseInt(t) + parseInt(value);
            //     value = Ext.util.Format.number(r, '0');
            //     return value
            // }
        }, {
            text: tt('Delegated'),
            dataIndex: 'NumberOfDelegatedOrg',
            width: 80,
            format: '000',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn',
            // renderer: function (value, metaData, record) {
            //     var t = record.get('NumberOfRecommendedOrg');
            //     per = t === 0 ? 0 : value / t;
            //     per = Ext.util.Format.number(per * 100, '0%');
            //     value = Ext.util.Format.number(value, '0');
            //     return "<b>" + value + "</b> [" + per + "]"
            // }
        }, {
            text: tt('Approved'),
            dataIndex: 'NumberOfApprovedOrg',
            width: 80,
            format: '000',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn',
            // renderer: function (value, metaData, record) {
            //     var t = record.get('NumberOfRecommendedOrg');
            //     per = t === 0 ? 0 : value / t;
            //     per = Ext.util.Format.number(per * 100, '0%');
            //     value = Ext.util.Format.number(value, '0');
            //     return "<b>" + value + "</b> [" + per + "]"
            // }
        }, {
            text: tt('Dismissed'),
            dataIndex: 'NumberOfDismissedOrg',
            width: 120,
            format: '000',
            filter: {
                type: 'number'
            },
            align: 'right',
            xtype: 'numbercolumn',
            // renderer: function (value, metaData, record) {
            //     var t = record.get('NumberOfRecommendedOrg');
            //     per = t === 0 ? 0 : value / t;
            //     per = Ext.util.Format.number(per * 100, '0%');
            //     value = Ext.util.Format.number(value, '0');
            //     return "<b>" + value + "</b> [" + per + "]"
            // }
        }, ]
    }, {
        text: tt('Hata Mesajı'),
        dataIndex: 'HataMesaji',
        filter: {
            type: 'string'
        },
        width: '90'
    }, {
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        locked: true,
        width: 30,
        align: 'center',
        items: [{
            iconCls: 'x-fa  fa-undo',
            isActionDisabled: function(grid, rowIndex, colIndex, item, rec) {
                return rec.get('ContractIsActive');
            },
            tooltip: tt('Değerlendirmeye dön'),
            handler: function(grid, rowIndex, colIndex, item, e, record) {
                var me = grid.ownerCt.ownerCt;
                me.onChangeStatus(record, 'Update Contract', me);
            }
        }]
    }, 
    {
        text: tt('CreatedBy'),
        dataIndex: 'CreatedBy',
        filter: {
            type: 'string'
        },
        width: 140
    },{
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        locked: true,
        width: 30,
        align: 'center',
        items: [{
            iconCls: 'fa fa-pencil-square',
            tooltip: tt('Sözleşme Oluştur'),
            isActionDisabled: function(grid, rowIndex, colIndex, item, rec) {
                return rec.get('ContractIsActive') || rec.get('IsContractCreated');
            },
            handler: function(grid, rowIndex, colIndex, item, e, record) {
                var me = this;
                var mainView = me.up('spuimainview');
                var canUseSAP = mainView != undefined ? mainView.getCanUseSAP() : null;
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/IsContractCreated',
                    method: 'POST',
                    params: {
                        spotId: record.get('SpotId'),
                    },
                    success: function(response, opts) {
                        if (response.responseText == "true") {
                            Ext.Msg.alert(tt('Durum'), tt('Sözleşme daha önceden oluşturulmuş.'))
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                        } 
                        else 
                        {
                            var win = Ext.create('Spui.uxWindow', {
                                height: '80%',
                                width: 1150,
                                ui: 'sui-window',
                                record: record,
                                mainView: mainView,
                                title: tt('Sözleşme Oluştur'),
                                uxItemCfg: 'contractCfg',
                                uxItem: {
                                    spotRecord: record,
                                    viewModel: {
                                        stores: {
                                            gridStore: {
                                                autoLoad: true,
                                                autoSync: false,
                                                groupField: 'StatusDescription',
                                                fields: [{
                                                    name: 'SpotID',
                                                    type: 'int'
                                                }, ],
                                                proxy: {
                                                    type: 'ajax',
                                                    actionMethods: {
                                                        read: 'POST',
                                                    },
                                                    api: {
                                                        read: Spui.Global.getApiUrl() + 'spui/CreateContractPreview',
                                                    },
                                                    writer: {
                                                        rootProperty: 'data',
                                                        allowSingle: false,
                                                        successProperty: 'success',
                                                        messageProperty: 'errors',
                                                        allDataOptions: {
                                                            associated: false
                                                        },
                                                        writeAllFields: true
                                                    },
                                                    extraParams: {
                                                        spotId: record.get('SpotId')
                                                    },
                                                    url: Spui.Global.getApiUrl() + 'spui/SmilarSpot',
                                                },
                                            }
                                        },
                                        data: {
                                            SpotId: record.get('SpotId'),
                                            spotRecord: record,
                                            canUseSAP: canUseSAP
                                        }
                                    },
                                },
                                bodyPadding: 2,
                                modal: true,
                                dockedItems: [{
                                    xtype: 'toolbar',
                                    dock: 'bottom',
                                    margin: 1,
                                    items: ['->', {
                                        text: tt('Sözleşme Oluştur'),
                                        ui: 'btn-success',
                                        handler: function() {
                                            var window = this.ownerCt.ownerCt;
                                            var mainView = window.mainView;
                                            var storeDatas = window.uxItem.store.getDatas();
                                            
                                            Ext.Msg.show({
                                                title: tt('Onay'),
                                                width: 500,
                                                msg: tt('Sözleşme tarihini değiştirmek istiyor musunuz?'),
                                                buttonText: {
                                                    yes: tt('Evet'),
                                                    no: tt('Hayır')
                                                },
                                                buttons: Ext.Msg.YESNO,
                                                icon: Ext.MessageBox.QUESTION,
                                                fn: function(response) {
                                                    if (response === 'yes') {
                                                        var aform = Ext.create('Ext.form.Panel', {
                                                            title: tt('Contract Date'),
                                                            bodyPadding: 5,
                                                            floating: true,
                                                            closable : true,
                                                            modal: true,
                                                            width: 350,
                                                            items: [{
                                                                xtype: 'datefield',
                                                                anchor: '100%',
                                                                fieldLabel: tt('Contract Date'),
                                                                name: 'contractDate',
                                                                required: true,
                                                                allowBlank: false,
                                                                format:tt('d-m-Y'),
                                                                maxValue: new Date(storeDatas[0].VPER_END)
            
                                                            }],
            
                                                            buttons: [{
                                                                text: 'Submit',
                                                                handler: function() {
                                                                    var form = this.up('form').getForm();
                                                                    if (form.isValid()) {
                                                                        var contractDate = form.getValues().contractDate
                                                                        Ext.Msg.show({
                                                                            title: tt('Onay'),
                                                                            width: 500,
                                                                            msg: tt('Sözleşme oluşturmak istediğinizden emin misiniz? <br><br>'),
                                                                            buttonText: {
                                                                                yes: tt('Evet'),
                                                                                no: tt('Hayır')
                                                                            },
                                                                            buttons: Ext.Msg.YESNO,
                                                                            icon: Ext.MessageBox.QUESTION,
                                                                            fn: function(response) {
                                                                                if (response === 'yes') {
                                                                                    var spotId = record.get('SpotId')
                                                                                    var data = Ext.encode(record.data);
                                                                                    me.setLoading(tt('yükleniyor'));
                                                                                    Ext.Ajax.request({
                                                                                        url: Spui.Global.getApiUrl() + '/spui/CreateContract',
                                                                                        method: 'POST',
                                                                                        params: {
                                                                                            spotId: spotId,
                                                                                            contractDate: contractDate
                                                                                        },
                                                                                        success: function(response, opts) {
                                                                                            if (response.responseText == "false") {
                                                                                                Ext.Msg.alert(tt('Durum'), tt('İşleminiz gerçekleştirilirken bir hata oluştu.'))
                                                                                            } else {
                                                                                                mainView.fireEvent('createContract')
                                                                                            }
                                                                                            me.setLoading(false);
                                                                                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                                                                        },
                                                                                        failure: function(response, opts) {
                                                                                            console.log('server-side failure with status code ' + response.status);
                                                                                            me.setLoading(false);
                                                                                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                                                                        }
                                                                                    });
                                                                                    window.doClose()
                                                                                    aform.close()
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            }]
                                                        });
                                                        aform.show()
                                                    }
                                                    else {
            
                                                        Ext.Msg.show({
                                                            title: tt('Onay'),
                                                            width: 500,
                                                            msg: tt('Sözleşme oluşturmak istediğinizden emin misiniz? <br><br>'),
                                                            buttonText: {
                                                                yes: tt('Evet'),
                                                                no: tt('Hayır')
                                                            },
                                                            buttons: Ext.Msg.YESNO,
                                                            icon: Ext.MessageBox.QUESTION,
                                                            fn: function(response) {
                                                                if (response === 'yes') {
            
                                                                var spotId = record.get('SpotId')
                                                                var data = Ext.encode(record.data);
                                                                me.setLoading(tt('yükleniyor'));
                                                                Ext.Ajax.request({
                                                                    url: Spui.Global.getApiUrl() + '/spui/CreateContract',
                                                                    method: 'POST',
                                                                    params: {
                                                                        spotId: spotId,
                                                                        contractDate: null
                                                                    },
                                                                    success: function(response, opts) {
                                                                        if (response.responseText == "false") {
                                                                            Ext.Msg.alert(tt('Durum'), tt('İşleminiz gerçekleştirilirken bir hata oluştu.'))
                                                                        } else {
                                                                            mainView.fireEvent('createContract')
                                                                        }
                                                                        me.setLoading(false);
                                                                        me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                                                    },
                                                                    failure: function(response, opts) {
                                                                        console.log('server-side failure with status code ' + response.status);
                                                                        me.setLoading(false);
                                                                        me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                                                                    }
                                                                });
                                                                window.doClose()
                                                                }
                                                            }
                                                        })
                                                    }
                                                }
                                            })
            
                                        }
                                        
                                    }, 
                                    {
                                        text: tt('Kapat'),
                                        ui: 'btn-success',
                                        handler: 'doClose'
                                    }]
                                }],
                            });
                            win.show();
                        }
                    },
                    failure: function(response, opts) {
                        console.log('server-side failure with status code ' + response.status);
                        me.setLoading(false);
                        me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                    }
                });
                
            }
        }]
    }],
    onChangeStatus: function(record, newStatus, me) {
        Ext.Msg.show({
            title: tt('Onay'),
            width: 500,
            msg: tt('Değerlendirmeye yollamak istediğinizden emin misiniz?'),
            buttonText: {
                yes: tt('Evet'),
                no: tt('Hayır')
            },
            buttons: Ext.Msg.YESNO,
            icon: Ext.MessageBox.QUESTION,
            fn: function(response) {
                if (response === 'yes') {
                    var data = Ext.encode(record.data);
                    me.setLoading(tt('yükleniyor'));
                    Ext.Ajax.request({
                        url: Spui.Global.getApiUrl() + '/spui/changeStatus',
                        method: 'POST',
                        params: {
                            data: data,
                            newStatus: newStatus
                        },
                        success: function(response, opts) {
                            me.setLoading(false);
                            me.up('spui-view-tracking-navigation-main').onRefrehTracking();
                        },
                        failure: function(response, opts) {
                            console.log('server-side failure with status code ' + response.status);
                        }
                    });
                }
            }
        })
    },
})`