import { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  fetchMfpData,
  fetchMfpDetailData,
  fetchMfpDetailDataForSingleColumn,
  setLoader as setActionLoader
} from '../store/actions'
import { setLoader, _errorHandler, getActualScenarioIdsAsBoolean } from '../utils'
import { MESSAGES } from '../messages'

class FetchMfp extends Component {
  toggleLoader(isVisible = false, msg = MESSAGES.loading) {
    setLoader(this.props.setActionLoader, isVisible, msg)
  }

  _fetchMfpData(loader = true) {
    const {
      props: {
        actualScenarioList = [],
        defaultActionParams: { payload = {} } = {},
        getBaseRequestParameters = () => {},
        getSelectedCurrency = () => {},
        resetPivotLeftKeys = () => {},
        errorContext
      } = {}
    } = this

    this.toggleLoader(loader, MESSAGES.loading)

    const body = getBaseRequestParameters()
    body.SelectedCurrency = getSelectedCurrency()
    body.ActualScenarioList = getActualScenarioIdsAsBoolean(actualScenarioList)

    const fetchMfpDataPayload = {
      ...payload,
      method: 'GetMfpData',
      requestMethod: 'post',
      body: {
        ...body
      }
    }

    errorContext.lastRequest = {
      payload: fetchMfpDataPayload
    }

    const promise = this.props.fetchMfpData({
      payload: fetchMfpDataPayload
    })

    promise
      .then(({ data, status }) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          response: { data, status }
        }

        if (_.isEqual(data, {})) {
          resetPivotLeftKeys({ selectedLeftKeys: [] })
        }
        _errorHandler(data, 'GetMfpData')

        this.updateUserScenario(data)

        this.toggleLoader(false)
      })
      .catch(({ data, status }) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          error: { data, status }
        }

        // TODO: Check error response whether it has 'Errors' or not
        this.toggleLoader(false)
        _errorHandler(data, 'GetMfpData')
      })

    return promise
  }

  updateUserScenario(data) {
    const {
      saveGetUserScenario = () => {},
      settings: { config: { configuration: { isWorkInSubsetEnabled = false } = {} } = {} } = {}
    } = this.props

    if (isWorkInSubsetEnabled) {
      const { Result: { UserScenario = null } = {}, Errors = [] } = data || {
        Result: { UserScenario: null }
      }
      if (!Errors.length) {
        saveGetUserScenario({
          payload: {
            Result: UserScenario
          }
        })
      }
    }
  }

  _fetchMfpDetailData(loader = true) {
    const {
      props: {
        actualScenarioList = [],
        defaultActionParams: { payload = {} } = {},
        pivotDetailViewSettings: { selectedLeftKeys = [] },
        getBaseRequestParameters = () => {},
        getSelectedCurrency = () => {},
        errorContext
      } = {}
    } = this

    this.toggleLoader(loader, MESSAGES.loading)

    const body = getBaseRequestParameters()
    body.SelectedCurrency = getSelectedCurrency()
    body.LeftHierarchy = [...selectedLeftKeys]
    body.LeftFields = body.LeftFields.slice(0, body.LeftHierarchy.length)
    body.ActualScenarioList = getActualScenarioIdsAsBoolean(actualScenarioList)

    const fetchMfpDetailDataPayload = {
      ...payload,
      method: 'GetMfpDetailData',
      requestMethod: 'post',
      body: {
        ...body
      }
    }

    errorContext.lastRequest = {
      payload: fetchMfpDetailDataPayload
    }

    const promise = this.props.fetchMfpDetailData({
      payload: fetchMfpDetailDataPayload
    })

    promise
      .then(({ data, status }) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          response: { data, status }
        }
        this.updateUserScenario(data)
        _errorHandler(data, 'GetMfpDetailData')
        this.toggleLoader(false)
      })
      .catch(({ data, status }) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          error: { data, status }
        }
        _errorHandler(data, 'GetMfpDetailData')
        this.toggleLoader(false)
      })

    return promise
  }

  _fetchMfpDetailDataForSingleColumn(loader = true) {
    const {
      props: {
        pluginId = '',
        actualScenarioList = [],
        defaultActionParams: { payload = {} } = {},
        pivotSingleDetailViewSettings: {
          selectedLeftKeys = [],
          selectedMetricName: { Name: pivotField = '' } = {},
          selectedMetricFields = []
        },
        settings: {
          config: { gridDetail: { drilldownCount = 2, multiColumnEnabled = false } = {} } = {}
        } = {},
        getBaseRequestParameters = () => {},
        getSelectedCurrency = () => {},
        errorContext
      } = {}
    } = this

    this.toggleLoader(loader, MESSAGES.loading)

    const body = getBaseRequestParameters()
    body.ActualScenarioList = getActualScenarioIdsAsBoolean(actualScenarioList)
    const Drilldowncount = Math.min(drilldownCount, body.LeftFields.length)
    body.Drilldowncount = Math.max(Drilldowncount, 2)
    body.SelectedCurrency = getSelectedCurrency()
    body.LeftHierarchy = [...selectedLeftKeys]
    body.PivotField = pivotField
    body.MultiColumnEnabled = multiColumnEnabled

    body.PivotFields = multiColumnEnabled
      ? selectedMetricFields.map((item) => item.Name).filter((item) => item)
      : [pivotField]

    const fetchMfpDetailDataForSingleColumnPayload = {
      ...payload,
      method: 'GetMfpDetailDataForSingleColumn',
      requestMethod: 'post',
      body: {
        ...body
      }
    }

    errorContext.lastRequest = {
      payload: fetchMfpDetailDataForSingleColumnPayload
    }

    const promise = this.props.fetchMfpDetailDataForSingleColumn({
      payload: fetchMfpDetailDataForSingleColumnPayload
    })

    promise
      .then(({ data, status }) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          response: { data, status }
        }
        this.updateUserScenario(data)
        _errorHandler(data, 'GetMfpDetailDataForSingleColumn')

        // update metric selection by single column response
        if (multiColumnEnabled) {
          const ResultMetrics = _.get(data, 'Result.Metrics', [])
          let _smf = selectedMetricFields.filter((item) => {
            return !item.Name || ResultMetrics.find((resultItem) => resultItem.Name === item.Name)
          })

          // this is for fill with empty metric
          _smf = this.props.getFilledMetricSelection(_smf, _smf)
          this.props._savePivotSingleDetailViewSettings({ selectedMetricFields: _smf })
          localStorage.setItem(`dp-SingleDetailMetricSelection-${pluginId}`, JSON.stringify(_smf))
        }

        this.toggleLoader(false)
      })
      .catch(({ data, status }) => {
        errorContext.lastRequest = {
          ...errorContext?.lastRequest,
          error: { data, status }
        }
        _errorHandler(data, 'GetMfpDetailDataForSingleColumn')
        this.toggleLoader(false)
      })

    return promise
  }

  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return this.props.children({
      _fetchMfpData: this._fetchMfpData.bind(this),
      _fetchMfpDetailData: this._fetchMfpDetailData.bind(this),
      _fetchMfpDetailDataForSingleColumn: this._fetchMfpDetailDataForSingleColumn.bind(this)
    })
  }
}

const mapStateToProps = (state) => {
  return {
    actualScenarioList: state.scenarioTreeSelection.scenarioTree.actualScenarioList,
    GetUserScenario: state.getUserScenario.GetUserScenario,
    GetMfpData: state.getMfpData.GetMfpData,
    GetMfpDetailData: state.getMfpDetailData.GetMfpDetailData,
    GetMfpDetailDataForSingleColumn:
      state.getMfpDetailDataForSingleColumn.GetMfpDetailDataForSingleColumn,
    pivotDetailViewSettings: state.pivotDetailViewSettings.pivotDetailViewSettings,
    pivotSingleDetailViewSettings:
      state.pivotSingleDetailViewSettings.pivotSingleDetailViewSettings,
    Loader: state.loader.loader,
    defaultActionParams: state.defaultActionParams.defaultActionParams
  }
}

const mapDispatchToProps = {
  fetchMfpData,
  fetchMfpDetailData,
  fetchMfpDetailDataForSingleColumn,
  setActionLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchMfp)
