import React, { Component } from 'react'
import {
  Button as BootstrapButton,
  ButtonGroup as BootstrapButtonGroup,
  ButtonToolbar
} from 'react-bootstrap'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { confirmAlert } from 'react-confirm-alert'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import CustomMenu from './Components/customMenu'
import './index.scss'

class ButtonGroup extends Component {
  constructor(props) {
    super(props)
    this.i = 1
    this.authButtonState = {}

    this.buttonHandles = {}
    this.handleClick = this.handleClick.bind(this)
    this.setEnabled = this.setEnabled.bind(this)
    this.setDisabled = this.setDisabled.bind(this)
    this.setButtonState = this.setButtonState.bind(this)
    this.state = { buttonState: {} }
  }

  updateButtonState = (buttonName, disabled) => {
    const { disabled: authDisabled = disabled } = this.authButtonState[buttonName] || {}
    disabled = disabled || authDisabled

    return (previousState) => {
      return {
        buttonState: {
          ...previousState.buttonState,
          [buttonName]: { disabled }
        }
      }
    }
  }

  setEnabled(buttonName) {
    return () => {
      this.setState(this.updateButtonState(buttonName, false))
    }
  }

  setDisabled(buttonName) {
    return () => {
      this.setState(this.updateButtonState(buttonName, true))
    }
  }

  setButtonState(buttonName) {
    return ({ disabled = false }) => {
      this.setState(this.updateButtonState(buttonName, disabled))
    }
  }

  handleClick(buttonName) {
    const methodHandle = this.buttonHandles[buttonName]
    return methodHandle()
  }

  createSplitButton(
    groupKey,
    groupConfig,
    buttonGroupConfig,
    splitButtonStyle,
    splitButtonTitle,
    splitButtonIcon,
    splitButtonIconPosition,
    splitConfirmation,
    splitConfirmationTitle,
    splitConfirmationText
  ) {
    return (
      <CustomMenu
        key={groupKey}
        buttonGroupConfig={buttonGroupConfig}
        buttonState={this.state.buttonState || {}}
        groupConfig={groupConfig}
        groupKey={groupKey}
        splitButtonIcon={splitButtonIcon}
        splitButtonIconPosition={splitButtonIconPosition}
        splitButtonStyle={splitButtonStyle}
        splitButtonTitle={splitButtonTitle}
        splitConfirmation={splitConfirmation}
        splitConfirmationText={splitConfirmationText}
        splitConfirmationTitle={splitConfirmationTitle}
        onClickButtonHandle={this.handleClick}
      />
    )
  }

  createDropdownButton(groupKey, groupConfig, buttonGroupConfig) {
    return (
      <CustomMenu
        key={groupKey}
        buttonGroupConfig={buttonGroupConfig}
        buttonState={this.state.buttonState || {}}
        groupConfig={groupConfig}
        groupKey={groupKey}
        onClickButtonHandle={this.handleClick}
      />
    )
  }

  createButtonWithNewGroup(key, groupConfig) {
    return _.map(groupConfig, (buttonConfig, index) => {
      const {
        text = '',
        style = 'success',
        icon = 'fa-check',
        iconPosition = 'Left',
        seperator = 'none',
        confirmation = false,
        confirmationTitle = 'Confirmation',
        confirmationText = 'Are you sure to continue?'
      } = buttonConfig

      return (
        <BootstrapButtonGroup key={key + index} className="poly-group-ctn">
          {this.createButton(
            `button${index}${key}`,
            text,
            style,
            `fa ${icon}`,
            iconPosition,
            seperator,
            confirmation,
            confirmationTitle,
            confirmationText
          )}
        </BootstrapButtonGroup>
      )
    })
  }

  createButtonGroup(key, groupConfig) {
    return (
      <BootstrapButtonGroup key={key} className="poly-group-ctn">
        {_.map(groupConfig, (buttonConfig, index) => {
          const {
            text = '',
            style = 'primary',
            icon = 'fa-check',
            iconPosition = 'Left',
            seperator = 'none',
            confirmation = false,
            confirmationTitle = 'Confirmation',
            confirmationText = 'Are you sure to continue?'
          } = buttonConfig
          return this.createButton(
            `button${index}`,
            text,
            style,
            `fa ${icon}`,
            iconPosition,
            seperator,
            confirmation,
            confirmationTitle,
            confirmationText
          )
        })}
      </BootstrapButtonGroup>
    )
  }

  createButton(
    key,
    text,
    style,
    icon,
    iconPosition,
    seperator,
    confirmation = false,
    confirmationTitle = 'Confirmation',
    confirmationText = 'Are you sure to continue?'
  ) {
    const disabled = _.get(this.state.buttonState, [[text], 'disabled']) || false
    return (
      <div key={key} className="btn-group">
        {seperator === 'left' ? <hr className="seperator-vertical" /> : null}
        <BootstrapButton
          key={key}
          className="grouped-buttons poly-drop-btn"
          disabled={disabled}
          type="button"
          variant={style}
          onClick={
            confirmation
              ? this.openConfirmationPopup(text, confirmationTitle, confirmationText)
              : () => this.handleClick(text)
          }
        >
          {iconPosition === 'Left' ? <i className={icon} /> : null}
          {` ${text} `}
          {iconPosition === 'Right' ? <i className={icon} /> : null}
        </BootstrapButton>
        {seperator === 'right' ? <hr className="seperator-vertical" /> : null}
      </div>
    )
  }

  openConfirmationPopup(text, confirmationTitle, confirmationText) {
    return (e) => {
      e.preventDefault()
      confirmAlert({
        title: confirmationTitle,
        message: confirmationText,
        buttons: [
          {
            label: 'Cancel',
            onClick: () => false
          },
          {
            label: 'OK',
            onClick: () => this.handleClick(text)
          }
        ]
      })
    }
  }

  componentDidMount() {
    const {
      settings: { config: { buttons: buttonConfigs = [] } = {} } = {},
      registerEvent = () => {},
      registerMethod = () => {},
      registerAuthorizations = () => {},
      isAllowed = () => {}
    } = this.props

    const authorizationList = _.map(buttonConfigs, ({ text }) => `Enabled_${text}_Btn`)
    registerAuthorizations(authorizationList)

    _.forEach(buttonConfigs, (btn) => {
      const { text = '' } = btn
      const authKey = `Enabled_${text}_Btn`

      registerMethod({
        key: `setState_${text}`,
        fn: this.setButtonState(text),
        args: [{ name: 'disabled', type: PluginTypes.boolean }]
      })

      registerMethod({
        key: `setEnabled_${text}`,
        fn: this.setEnabled(text),
        args: []
      })

      registerMethod({
        key: `setDisabled_${text}`,
        fn: this.setDisabled(text),
        args: []
      })

      let btnHandle = () => {
        return { refreshKey: uuidv4() }
      }

      btnHandle = registerEvent({
        key: `handleClick_${text}`,
        fn: btnHandle.bind(this),
        returnTypes: { refreshKey: PluginTypes.string }
      })

      this.buttonHandles[text] = btnHandle

      this.authButtonState[text] = {
        disabled: !isAllowed(authKey)
      }
    })

    this.setState({ buttonState: this.authButtonState })
  }

  render() {
    const {
      settings: {
        config: { buttons: buttonConfigs = [], buttonGroups: buttonGroupConfigs = [] } = {}
      } = {}
    } = this.props

    const groupedButtonConfigs = _.groupBy(buttonConfigs, (b) => b.buttonGroup || uuidv4())

    const buttons = _.map(groupedButtonConfigs, (groupConfig, key) => {
      const buttonGroupConfig = _.find(buttonGroupConfigs, { name: key })
      if (buttonGroupConfig != null) {
        const { groupOptions = 'none' } = buttonGroupConfig
        if (groupOptions === 'dropdown') {
          return this.createDropdownButton(key, groupConfig, buttonGroupConfig)
        }
        if (groupOptions === 'split') {
          const splitButton = _.head(groupConfig)

          const {
            text: splitButtonText,
            style: splitButtonStyle,
            icon: splitButtonIcon,
            iconPosition: splitButtonIconPosition,
            confirmation: splitConfirmation = false,
            confirmationTitle: splitConfirmationTitle = 'Confirmation',
            confirmationText: splitConfirmationText = 'Are you sure to continue?'
          } = splitButton || {}

          return this.createSplitButton(
            key,
            groupConfig,
            buttonGroupConfig,
            splitButtonStyle,
            splitButtonText,
            `fa ${splitButtonIcon}`,
            splitButtonIconPosition,
            splitConfirmation,
            splitConfirmationTitle,
            splitConfirmationText
          )
        }
        if (groupOptions === 'none') {
          return this.createButtonGroup(key, groupConfig)
        }
      } else {
        this.i++
        return this.createButtonWithNewGroup(`btn${this.i}`, groupConfig)
      }
    })

    let classNamePoly = ''
    const singleButtonGroup = _.size(groupedButtonConfigs) === 1
    if (singleButtonGroup) {
      var className = 'button-group-plugin single-group p-1'
    } else {
      className = 'button-group-plugin p-1'
      classNamePoly = 'poly-groups'
    }

    return (
      <div className={className}>
        <ButtonToolbar className={classNamePoly}>{buttons}</ButtonToolbar>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  settings: props.settings,
  registerMethod: props.registerMethod,
  registerEvent: props.registerEvent,
  registerAuthorizations: props.registerAuthorizations,
  isAllowed: props.isAllowed
})

export default createPlugin(ButtonGroup, selectConnectorProps)
