import { ReactElement, PropsWithChildren } from 'react'
import { render } from '@testing-library/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import type { RenderOptions } from '@testing-library/react'
import type { PreloadedState } from '@reduxjs/toolkit'
import store, { RootState, AppStore, setupStore } from '../../store/index'
import { initialState as pageConfigInitialState } from '../../store/slices/pageConfig'
import ResizeObserver from './ResizeObserver'
import { NODE_ENV } from '@/constants'

let mocks = { oidc: {}, setting: {} }
if (NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  mocks = require('./__mocks__')
}

type mockStateKeysTypes = 'oidc' | 'setting' | 'pageConfig'

const defaultState = { ...store.getState() }

// This type interface extends the default options for render from RTL, as well
// as allows the user to specify other things such as initialState, store.
interface ExtendedRenderOptions extends Omit<RenderOptions, 'queries'> {
  state?: PreloadedState<RootState>
  store?: AppStore
}

const getMockState = () => {
  return {
    oidc: mocks.oidc,
    setting: mocks.setting,
    pageConfig: pageConfigInitialState
  }
}

const getStateWithMocksByKeys = (keys: mockStateKeysTypes[] = []) => {
  const state: any = { ...defaultState }
  const mockState = getMockState()
  keys.forEach((key) => {
    if (key in mockState) {
      state[key] = { ...mockState[key] }
    }
  })
  return { ...state }
}

const getState = () => ({ ...defaultState })

const stateWithMocks = getStateWithMocksByKeys(Object.keys(getMockState()) as mockStateKeysTypes[])

const renderWithProviders = (
  ui: ReactElement,
  {
    state = stateWithMocks,
    // Automatically create a store instance if no store was passed in
    // eslint-disable-next-line @typescript-eslint/no-shadow
    store = setupStore(state),
    ...renderOptions
  }: ExtendedRenderOptions = {}
) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function Wrapper({ children }: PropsWithChildren<{}>): JSX.Element {
    return (
      <Provider store={store}>
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    )
  }

  // Return an object with the store and all of RTL's query functions
  return { store, ...render(ui, { wrapper: Wrapper, ...renderOptions }) }
}

// re-export everything
export * from '@testing-library/react'

// override render method
export { renderWithProviders as render, getState, getMockState, ResizeObserver }
