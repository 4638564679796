import { FormEvent, KeyboardEvent, useEffect, useState } from 'react'
import cx from 'classnames'
import { paginationPanelHeight } from '../../constants'
import { PaginationCmpProps } from './Pagination.types'
import styles from './Pagination.module.scss'

const Pagination = (props: PaginationCmpProps) => {
  const {
    currentPage,
    doesDirectUpdate,
    pageSize,
    setCurrentPage,
    theme,
    totalRowCount: pTotalRowCount,
    totalPage: pWrongTotalPage,
    refreshData,
    gridRef
  } = props

  const [wrongTotalPage, setWrongTotalPage] = useState<number>(pWrongTotalPage)
  const [totalRowCount, seTotalRowCount] = useState<number>(pTotalRowCount)
  const [pageNumber, setPageNumber] = useState<string | number>(currentPage)

  const localeText = gridRef?.current?.api?.getGridOption?.('localeText') || {}

  useEffect(() => {
    setPageNumber(currentPage)
  }, [currentPage])

  // START:
  // The problem is,
  // when you click to "next page" or "prev page" icon, totalRowCount and totalPage number becomes 0 and
  // come back its real version for a short time.
  // This is a basePlugin problem and not related to AgGrid.
  // After fixing this in basePlugin remove below.
  useEffect(() => {
    seTotalRowCount((prev) => {
      return pTotalRowCount === 0 ? prev : pTotalRowCount
    })
  }, [pTotalRowCount])

  useEffect(() => {
    setWrongTotalPage((prev) => {
      return pWrongTotalPage === 0 ? prev : pWrongTotalPage
    })
  }, [pWrongTotalPage])
  // END:

  const totalPage = pageSize * wrongTotalPage < totalRowCount ? wrongTotalPage + 1 : wrongTotalPage

  const isFirstPage = currentPage === 1

  const isLastPage = currentPage === totalPage

  const isInvalidPageNumber =
    (typeof pageNumber === 'string' && pageNumber !== '') ||
    (typeof pageNumber === 'number' && pageNumber < 1)

  const toPage = isLastPage ? totalRowCount : Math.ceil(currentPage * pageSize)

  const difference = totalRowCount % pageSize

  const fromPage = isLastPage ? totalRowCount - difference : toPage - pageSize || 1

  const onKeyDownHandler = ({ key, target }: KeyboardEvent<HTMLInputElement>) => {
    if (key !== 'Enter') {
      return
    }

    const { value } = target as HTMLInputElement
    const numericValue = parseInt(value, 10)

    if (Number.isNaN(numericValue) || numericValue === 0) {
      return
    }

    if (numericValue >= totalPage) {
      setCurrentPage(totalPage)
      setPageNumber(totalPage)
    } else {
      setCurrentPage(numericValue)
    }
  }

  const onChangeHandler = ({ target }: FormEvent<HTMLInputElement>) => {
    let { value } = target as HTMLInputElement
    value = value.trim()

    if (value === '' || value === '-') {
      setPageNumber('')
      return
    }

    const numericValue = parseInt(value, 10)

    if (Number.isNaN(numericValue) || numericValue === 0) {
      return
    }

    setPageNumber(numericValue)
  }

  const goToNext = () => {
    if (!isLastPage) {
      setCurrentPage(() => currentPage + 1)
      setPageNumber(() => currentPage + 1)
    }
  }

  const goToPrev = () => {
    if (!isFirstPage) {
      setCurrentPage(() => currentPage - 1)
      setPageNumber(() => currentPage - 1)
    }
  }

  const goToLast = () => {
    if (!isLastPage) {
      setCurrentPage(totalPage)
      setPageNumber(totalPage)
    }
  }

  const goToFirst = () => {
    if (!isFirstPage) {
      setCurrentPage(1)
      setPageNumber(1)
    }
  }

  const isBalham = theme === 'balham'

  return (
    <div
      className={cx(`ag-theme-${theme}`, 'w-100 position-relative')}
      style={{ height: paginationPanelHeight[theme] }}
    >
      <div className="ag-root-wrapper border-top-0 border-start-0 border-end-0 ag-ltr ag-layout-normal">
        <div className="ag-paging-panel border-top-0 ag-unselectable ag-focus-managed">
          {doesDirectUpdate ? (
            <span className="ag-paging-page-summary-panel" role="presentation">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div
                aria-disabled="false"
                aria-label={localeText.refreshData}
                className="ag-button ag-paging-button"
                role="button"
                tabIndex={0}
                title={localeText.refreshData}
                onClick={refreshData}
              >
                <span
                  className={cx('fa fa-refresh', {
                    'fa-xs': isBalham,
                    'fa-sm': !isBalham
                  })}
                  role="presentation"
                />
              </div>
            </span>
          ) : null}
          <span className="ag-paging-row-summary-panel gap-1 d-flex" role="status">
            <span className="ag-paging-row-summary-panel-number">{fromPage}</span>
            <span>-</span>
            <span className="ag-paging-row-summary-panel-number">{toPage}</span>
            <span>/</span>
            <span className="ag-paging-row-summary-panel-number">{totalRowCount}</span>
          </span>
          <span className="ag-paging-page-summary-panel" role="presentation">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              aria-disabled="false"
              aria-label={localeText.firstPage}
              className={cx('ag-button ag-paging-button', { 'ag-disabled': isFirstPage })}
              role="button"
              tabIndex={0}
              onClick={goToFirst}
            >
              <span className="ag-icon ag-icon-first" role="presentation" />
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              aria-disabled="false"
              aria-label={localeText.previousPage}
              className={cx('ag-button ag-paging-button', { 'ag-disabled': isFirstPage })}
              role="button"
              tabIndex={0}
              onClick={goToPrev}
            >
              <span className="ag-icon ag-icon-previous" role="presentation" />
            </div>
            <span
              className="ag-paging-description gap-1 d-flex align-items-center justify-content-center"
              role="status"
            >
              <span>{localeText.page}</span>
              <input
                className={cx(styles.slvyPaginationInput, 'rounded-1 ms-1', {
                  [styles.slvyPaginationInvalidInput]: isInvalidPageNumber
                })}
                pattern="[1-9]{1,5}"
                placeholder={String(currentPage)}
                type="text"
                value={pageNumber}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
              />
              <span>/</span>
              <span className="ag-paging-number">{totalPage}</span>
            </span>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              aria-disabled="false"
              aria-label={localeText.nextPage}
              className={cx('ag-button ag-paging-button', { 'ag-disabled': isLastPage })}
              role="button"
              tabIndex={0}
              onClick={goToNext}
            >
              <span className="ag-icon ag-icon-next" role="presentation" />
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              aria-disabled="false"
              aria-label={localeText.lastPage}
              className={cx('ag-button ag-paging-button', { 'ag-disabled': isLastPage })}
              role="button"
              tabIndex={0}
              onClick={goToLast}
            >
              <span className="ag-icon ag-icon-last" role="presentation" />
            </div>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Pagination
