Ext.define('Spui.view.common.ux.Container', {
  extend: 'Ext.Container',
  // Uncomment to give this component an xtype
  xtype: 'uxcontainer',
  layout: 'fit',
  defaultListenerScope: true,
  config: {
    uxItemCfg: null,
    uxStoreCfg: null,
    uxStore: { xtype: 'store' },
    uxItem: {}
  },
  applyUxItemCfg: function (c) {
    if (!c) {
      return c
    }
    return window.ss(c)
  },
  applyUxStoreCfg: function (c) {
    if (!c) {
      return c
    }
    return window.ss(c)
  },
  applyUxStore: function (b) {
    var me = this
    var cfgRecord = this.getUxStoreCfg()
    if (!cfgRecord) {
      return false
    }

    var code = cfgRecord.get('code'),
      cfg = new Function('return ' + code)()

    if (b && !b.isStore) {
      Ext.applyIf(b, cfg)

      b = Ext.create('Ext.data.Store', b)
    }

    return b
  },
  applyUxItem: function (b) {
    var cfgRecord = this.getUxItemCfg()
    if (!cfgRecord) {
      return false
    }

    var code = cfgRecord.get('code'),
      settings = code || '({})'
    var cfg = new Function('return ' + settings)()
    if (this.getUxStore()) {
      Ext.applyIf(cfg, {
        store: this.getUxStore()
      })
    }

    Ext.applyIf(cfg, {
      xtype: 'container'
    })

    if (b && !b.isComponent) {
      Ext.applyIf(b, cfg)

      b = Ext.create(b)
    }

    return b
  },
  updateUxItem: function (f, g) {
    if (!f) {
      return
    }
    if (g) {
      this.remove(g)
      this.add(f)
      return
    }
    if (f) {
      var d = this.items
      if (d) {
        if (d.isMixedCollection) {
          this.insert(0, f)
        } else {
          d.splice(0, 0, f)
        }
      } else {
        this.items = [f]
      }
    }
  },
  initComponent: function () {
    var me = this
    ;(me.plugins = [
      {
        ptype: 'cornerIcon',
        cfgButton: [
          '->',
          {
            iconCls: 'x-fa fa-cog',
            cls: 'config-btn',
            menu: [
              {
                text: 'Item Config',
                handler: 'onConfigUxItem',
                scope: me
              },
              {
                text: 'Data Config',
                handler: 'onConfigUxStore',
                scope: me
              }
            ]
          }
        ]
      }
    ]),
      me.callParent()
  },

  onConfigUxItem: function () {
    var setRec = this.getUxItemCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigRecomUxItemSave, this)
  },
  onConfigRecomUxItemSave: function (v, k) {
    var setRec = this.getUxItemCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setUxItem(res)
  },

  onConfigUxStore: function () {
    var setRec = this.getUxStoreCfg(),
      code = setRec.get('code')
    var settings = code || '({ })'

    settings = Adm.util.Beautify['javascript'](settings)
    var c = Ext.create('Adm.editor.Panel', {
      width: 100,
      height: 100,
      maximized: true,
      value: settings,
      snippetPanel: {
        record: setRec
      },
      aceOptions: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        fontSize: '12pt'
      }
    }).show()
    c.on('save', this.onConfigUxStoreSave, this)
  },
  onConfigUxStoreSave: function (v, k) {
    var setRec = this.getUxStoreCfg()
    v.close()
    setRec.set('code', k)
    setRec.save()
    var res = new Function('return ' + k)()
    this.setUxStore(res)
  }
})
