Ext.define('po.grid.locking.Lockable', {
  override: 'Ext.grid.locking.Lockable',
  getMenuItems: function (getMenuItems, locked) {
    var me = this,
      unlockText = me.unlockText,
      lockText = me.lockText,
      unlockCls = Ext.baseCSSPrefix + 'hmenu-unlock',
      lockCls = Ext.baseCSSPrefix + 'hmenu-lock',
      unlockHandler = me.onUnlockMenuClick.bind(me),
      lockHandler = me.onLockMenuClick.bind(me)

    // runs in the scope of headerCt
    return function () {
      var grid = me.ownerGrid
      if (grid.lockText) lockText = grid.lockText

      if (grid.unlockText) unlockText = grid.unlockText

      // We cannot use the method from HeaderContainer's prototype here
      // because other plugins or features may already have injected an implementation
      var o = getMenuItems.call(this)
      o.push('-', {
        itemId: 'unlockItem',
        iconCls: unlockCls,
        text: unlockText,
        handler: unlockHandler,
        disabled: !locked
      })
      o.push({
        itemId: 'lockItem',
        iconCls: lockCls,
        text: lockText,
        handler: lockHandler,
        disabled: locked
      })
      return o
    }
  }
})
