﻿// eslint-disable-next-line import/no-webpack-loader-syntax, import/order
import mapboxgl from '!mapbox-gl'
import { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { parse } from 'wkt'
import {
  cloneDeep,
  compact,
  find,
  first,
  forEach,
  groupBy,
  has,
  indexOf,
  isEmpty,
  isEqual,
  isNull,
  isObject,
  map,
  max,
  sortBy,
  toInteger,
  toString,
  uniqBy,
  reduce,
  entries,
  last,
  upperCase
} from 'lodash'
import createPlugin from '@/BasePlugin'
import { accessToken, averageGeolocation, getConfig } from './util'
import { eventMethod, mapStyles, defaultColor, defaultColors, motIconList } from './settings'

import './style.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = accessToken

class Map extends Component {
  constructor(props) {
    super(props)

    this.state = {
      zoom: 7,
      style: mapStyles.Streets,
      filterLevel: {}
    }

    this.mapContainer = null
    this.map = null
    this.isRender = true
    this.mapDidMount = false
    this.disable = false
    this.center = null
    this.zoom = null
  }

  componentDidMount = () => {
    const { zoom, theme = 'Streets' } = getConfig(this.props)
    const style = mapStyles[theme] || mapStyles.Streets
    const { registerEvent, registerMethod } = this.props

    this.setState({ zoom, style })

    forEach(eventMethod.events, ({ key, returnTypes, refreshKey = {} }) => {
      this[`handle${key}`] = registerEvent({
        key,
        fn: (params = {}) => ({ ...params, ...refreshKey }),
        returnTypes
      })
    })

    forEach(eventMethod.methods, ({ key, args }) => {
      registerMethod({
        key,
        fn: this[key] || (() => undefined),
        args
      })
    })
  }

  setMove = (params) => {
    if (!isNull(this.map) && isObject(params) && !this.disable) {
      const { isSync } = getConfig(this.props)
      const { lat = 0, lng = 0, zoom = 0, pitch = 0, bearing = 0 } = params
      if (lat && lng && isSync) {
        this.map.setCenter({ lat, lng })
        this.map.setZoom(zoom)
        this.map.setPitch(pitch)
        this.map.setBearing(bearing)
      }
    }
  }

  setLevel = ({ levels = [] }) => {
    const legendSettings = this.getLegendSettings()
    const { state, props } = this

    if (!isEmpty(levels)) {
      const filterLevel = reduce(
        legendSettings,
        (obj, value, key) => {
          const newObj = obj
          newObj[key] = indexOf(levels, toInteger(key)) > -1
          return obj
        },
        {}
      )

      if (!isEqual(filterLevel, state.filterLevel)) {
        this.map.remove()
        this.isRender = true
        this.setState({ filterLevel }, () => this.filterPluginData(props.pluginData))
      }
    }
  }

  shouldComponentUpdate = (nextProps) => {
    const { size = {}, pluginData = [] } = this.props
    const { size: nexSize = {}, pluginData: nextPluginData = [] } = nextProps
    const windowDidChange = !isEqual(size.width, nexSize.width)
    const pluginDataReady = nextPluginData !== null && nextPluginData.length > 0
    const isRender = pluginDataReady && this.isRender

    if (isRender) {
      this.initMap(nextPluginData)
      this.isRender = false
    }

    const isDataChanged = !isEqual(pluginData, nextPluginData)
    if (!isRender && isDataChanged && this.map) {
      this.map.remove()
      this.initMap(nextPluginData)
    }

    if (windowDidChange && this.map) {
      this.map.resize()
    }

    if (isDataChanged) {
      return true
    }

    return isRender
  }

  filterPluginData = (pluginData = []) => {
    const { filterLevel } = this.state
    const { level } = getConfig(this.props)

    const foundData = pluginData.filter((pluginDataItem) => {
      const { [level]: polygonLevel } = pluginDataItem || {}
      const plStatus = filterLevel[toString(polygonLevel)]
      return plStatus !== false
    })

    return foundData
  }

  initMap = (pData) => {
    if (this.mapContainer && mapboxgl) {
      const { style, zoom } = this.state
      const {
        longitude,
        latitude,
        theme,
        ready,
        controlSettings: { showCompass = true, showZoom = true, visualizePitch = true } = {}
      } = getConfig(this.props)
      const mapStyle = mapStyles[theme] || mapStyles.Streets
      const navigationControlSettings = {
        showCompass,
        showZoom,
        visualizePitch
      }

      const pluginData = this.filterPluginData(pData)

      const center = ready
        ? averageGeolocation(
            map(pluginData, ({ [longitude]: Longitude = 0, [latitude]: Latitude = 0 }) => [
              Longitude,
              Latitude
            ])
          )
        : [0, 0]

      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        style: style !== mapStyle ? mapStyle : style,
        center: this.center ? this.center : center,
        zoom: this.zoom ? this.zoom : zoom,
        attributionControl: false
      })

      this.map.addControl(new mapboxgl.NavigationControl(navigationControlSettings))
      this.map.on('load', this.loadMap)
      this.map.on('move', this.mapMoveHandler)
    }
  }

  getCoordinates = () => {
    const { id, level, geometry, isCenter } = getConfig(this.props)
    let { pluginData = [] } = this.props
    pluginData = this.filterPluginData(pluginData)

    pluginData = pluginData.filter(
      ({ [isCenter]: IsCenter = false }) => !IsCenter || IsCenter === 0
    )

    if (geometry && level) {
      const geometryData = pluginData.filter((item) => {
        const { [level]: levelStr, [geometry]: geometryStr } = item
        return levelStr && geometryStr
      })

      const newGeoData = geometryData.map((item) => {
        return { ...item, ...{ [geometry]: parse(item[geometry]) } }
      })

      return newGeoData
    }

    if (level && !geometry) {
      const dataList = reduce(
        groupBy(pluginData, level),
        (arr, value) => {
          forEach(groupBy(value, id), (val) => {
            if (!isEmpty(val)) {
              arr.push(val)
            }
          })
          return arr
        },
        []
      ).reverse()

      return dataList
    }

    return []
  }

  getLegendSettings = () => {
    const { level, layers = [], uom, modeOfTravel } = getConfig(this.props)
    const { pluginData = [] } = this.props

    const polygonLevels = compact(
      sortBy(
        map(
          uniqBy(pluginData, level),
          ({ [level]: PolygonLevel, [uom]: UOM, [modeOfTravel]: MOT }) => {
            return {
              PolygonLevel,
              UOM,
              MOT
            }
          }
        )
      )
    )

    return reduce(
      polygonLevels,
      (obj, item, index) => {
        const newObj = obj
        const key = item.PolygonLevel

        const foundLayer =
          find(layers, ({ value = '' }) => value === toString(key)) || layers[index]

        const {
          fillColor = defaultColors[index],
          fillOpacity = 50,
          lineColor = defaultColor,
          lineWidth = 1
        } = foundLayer || {}

        if (key) {
          newObj[key] = {
            fillColor,
            fillOpacity,
            lineColor,
            lineWidth,
            UOM: item.UOM,
            MOT: item.MOT
          }
        }

        return obj
      },
      {}
    )
  }

  apiClientRequest = ({ endpoint, request }) => {
    const {
      id,
      client,
      data: { updatehints = {} } = {},
      actualFilters = {},
      additionalArgs = {}
    } = this.props

    const data = {
      ...request,
      filters: { ...actualFilters, ...additionalArgs },
      updatehints
    }

    const url = `/data/plugin/${id}/${endpoint}`
    client.post(url, { data }).then(() => this.handleDataUpdated())
  }

  onDragEnd = (config, { target, target: { _lngLat: { lat, lng } = {} } = {} }) => {
    const { longitude, latitude, askUpdate = false } = getConfig(this.props)
    const { [longitude]: Longitude = 0, [latitude]: Latitude = 0 } = config

    const request = {
      updateItems: [
        {
          columnName: longitude,
          config: {
            ...config,
            ...{
              [longitude]: lng
            }
          }
        },
        {
          columnName: latitude,
          config: {
            ...config,
            ...{
              [latitude]: lat
            }
          }
        }
      ]
    }

    this.handleCentroidMoved({
      longitude: lng,
      latitude: lat
    })

    if (askUpdate) {
      confirmAlert({
        title: 'Update Location ?',
        message: 'Are you sure you want to update location ?',
        confirmLabel: 'Confirm',
        cancelLabel: 'Cancel',
        onConfirm: () => this.apiClientRequest({ endpoint: 'update', request }),
        onCancel: () => target.setLngLat([Longitude, Latitude])
      })
    } else {
      this.apiClientRequest({ endpoint: 'update', request })
    }
  }

  markerHandler = (config) => {
    const { longitude, latitude, tooltip, cssTooltip } = getConfig(this.props)
    const { [longitude]: Longitude = 0, [latitude]: Latitude = 0 } = config
    const tooltipStr = tooltip.replace(/\{([^}]+)\}/g, (key, val) => config[val] || '')
    const html = `<div class="map-tooltip">${tooltipStr} <style>${cssTooltip}</style></div>`

    new mapboxgl.Popup({ offset: 15, closeOnClick: false })
      .setLngLat([Longitude, Latitude])
      .setHTML(html)
      .addTo(this.map)
  }

  removePopup = () => {
    const popupElement = document.querySelector('.mapboxgl-popup')
    if (popupElement) {
      popupElement.remove()
    }
  }

  loadMap = () => {
    const {
      ready = false,
      isCenter,
      longitude = 'Longitude',
      latitude = 'Latitude',
      level,
      geometry,
      moveToUpdate,
      iconSize,
      dataZoom,
      tooltip,
      type,
      iconColor,
      types,
      isHover,
      isFastIcon,
      isDraggableData
    } = getConfig(this.props)

    const { pluginData: pData = [] } = this.props

    const { zoom } = this.state
    const pluginData = this.filterPluginData(cloneDeep(pData))

    if (ready) {
      const [{ [dataZoom]: zoomValue = zoom } = {}] = pluginData

      if (zoom !== zoomValue) {
        this.map.zoomTo(zoomValue)
      }

      const legendSettings = this.getLegendSettings()
      const coordinates = this.getCoordinates()
      const filteredCentroids = pluginData.filter(({ [isCenter]: IsCenter = false }) => IsCenter)

      const centroids = uniqBy(
        filteredCentroids,
        ({ [longitude]: Longitude, [latitude]: Latitude, [type]: DataType }) => {
          return `${Latitude},${Longitude},${DataType}`
        }
      )

      forEach(centroids, (item) => {
        const {
          [longitude]: Longitude,
          [latitude]: Latitude,
          [type]: typeValue,
          [iconSize]: iconSizeValue,
          [iconColor]: iconColorValue,
          [isDraggableData]: draggableDataValue = false
        } = item

        const {
          icon = 'fa fa-map-marker-alt',
          iconColor: markerIconColor = defaultColor,
          iconSize: iconsize = 20,
          isDraggable = false
        } = find(types, ({ value }) => value === toString(typeValue)) || {}

        const iconMarker = document.createElement('div')
        if (!isFastIcon) {
          iconMarker.className = `map-marker ${icon}`
        } else {
          iconMarker.innerHTML = '•'
        }
        iconMarker.style.fontSize = `${iconSizeValue || iconsize}px`
        iconMarker.style.color = iconColorValue || markerIconColor

        let draggable = false

        if (moveToUpdate) {
          if (isDraggable) {
            if (typeof draggableDataValue === 'number') {
              draggable = draggableDataValue
            }
          } else {
            draggable = false
          }
        }

        try {
          const marker = new mapboxgl.Marker(iconMarker, {
            draggable
          })
            .setLngLat([Longitude, Latitude])
            .addTo(this.map)

          if (draggable) {
            marker.on('dragend', this.onDragEnd.bind(this, item))
          }

          if (tooltip) {
            // eslint-disable-next-line no-underscore-dangle
            const popupElement = marker._element
            popupElement[isHover ? 'onmouseenter' : 'onclick'] = this.markerHandler.bind(this, item)
            popupElement.onmouseout = isHover ? this.removePopup : () => undefined
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Coordinate Error:', [Longitude, Latitude])
        }
      })

      forEach(coordinates, (item, index) => {
        const source = `main${index}`
        let geoData = {}
        let itemData = {}

        if (geometry && level) {
          geoData = item[geometry]
          itemData = item
        }

        if (level && !geometry) {
          const coordinatesData = map(item, ({ [longitude]: Longitude, [latitude]: Latitude }) => [
            Longitude,
            Latitude
          ])

          itemData = first(item)

          geoData = {
            type: 'Polygon',
            coordinates: [coordinatesData]
          }
        }

        const { [level]: polygonLevel } = itemData
        const { [polygonLevel]: { fillColor, fillOpacity, lineColor, lineWidth } = {} } =
          legendSettings

        this.map.addSource(source, {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: geoData
          }
        })

        this.map.addLayer({
          id: source,
          type: 'fill',
          source,
          layout: {},
          paint: {
            'fill-color': fillColor || 'transparent',
            'fill-opacity': fillOpacity / 100
          }
        })

        this.map.addLayer({
          id: `outline${index}`,
          type: 'line',
          source,
          layout: {},
          paint: {
            'line-color': lineColor || 'transparent',
            'line-width': lineWidth
          }
        })
      })
    }
  }

  mapMoveHandler = ({ target }) => {
    const { isSync } = getConfig(this.props)
    if (!this.disable && isSync) {
      const { lat, lng } = target.getCenter()
      const zoom = target.getZoom()
      const pitch = target.getPitch()
      const bearing = target.getBearing()

      this.center = { lat, lng }
      this.zoom = zoom
      this.disable = true

      this.handleMoved({
        lat,
        lng,
        zoom,
        pitch,
        bearing
      })
      this.disable = false
    }
  }

  filterClickHandler = ({ key: selectedKey, legendSettings }, { target }) => {
    const { filterLevel } = this.state
    const { pluginData } = this.props

    forEach(legendSettings, (value, key) => {
      const checked = target.value !== 'on'

      if (selectedKey === key) {
        filterLevel[key] = checked
      }

      if (selectedKey !== key && !has(filterLevel, key)) {
        filterLevel[key] = true
      }
    })

    const maxValue = toInteger(
      max(
        reduce(
          filterLevel,
          (arr, value, key) => {
            if (value) {
              arr.push(key)
            }
            return arr
          },
          []
        )
      )
    )

    this.map.remove()
    this.isRender = true

    this.setState({ filterLevel }, () => {
      this.filterPluginData(pluginData)
      this.handleLevelClicked({ max: maxValue })
    })
  }

  render = () => {
    const { filterLevel } = this.state
    const { pluginData = [] } = this.props
    const { controlSettings: { showPolygonLevel = true } = {} } = getConfig(this.props)
    const legendSettings = pluginData.length > 0 ? this.getLegendSettings() : {}
    const firstLegend = first(entries(legendSettings))
    const { UOM = 'Min', MOT } = last(firstLegend) || {}
    const motIcon = motIconList[MOT]

    return (
      <div className="map-plugin bg-white w-100 position-relative">
        <div
          ref={(mapconainer) => {
            this.mapContainer = mapconainer
          }}
          className="map-container w-100 h-100"
        />

        {pluginData.length === 0 ? (
          <div className="d-flex position-absolute align-items-center justify-content-center left-0 top-0 right-0 bottom-0 w-100 h-100 fs-5">
            No data to display
          </div>
        ) : null}

        {pluginData.length > 0 && showPolygonLevel ? (
          <div className="d-flex position-absolute p-1 lh-1 left-0 bottom-0 ms-2 mb-2">
            {motIcon ? (
              <div
                className="bg-white shadow rounded text-muted px-2 lh-1 me-2 d-flex align-items-center"
                style={{ '--bs-bg-opacity': '.95', fontSize: 18 }}
                title={`Mode of travel: ${upperCase(MOT)}`}
              >
                <i className={motIcon} />
              </div>
            ) : null}

            <div
              className="bg-white shadow rounded p-1 overflow-auto"
              style={{ '--bs-bg-opacity': '.95', maxWidth: 350 }}
            >
              <div className="position-relative mb-1" style={{ fontSize: 10, left: 2, bottom: -1 }}>
                In {UOM}
              </div>
              <ul className="d-flex list-unstyled custom-checkbox">
                {map(legendSettings, ({ fillColor = 'Min' }, key) => {
                  const checked = has(filterLevel, key) ? filterLevel[key] : true
                  return (
                    <li key={key}>
                      <label>
                        <input
                          className="d-none"
                          defaultValue={checked ? 'on' : 'off'}
                          type="checkbox"
                          onClick={this.filterClickHandler.bind(this, {
                            key,
                            legendSettings
                          })}
                        />
                        <span className="d-flex align-items-center justify-content-center fw-bold">
                          <i
                            className="d-block rounded-circle shadow border border-light"
                            style={{ backgroundColor: fillColor }}
                          />
                          {key}
                        </span>
                      </label>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  size: props.size,
  id: props.id,
  client: props.client,
  data: props.data,
  actualFilters: props.actualFilters,
  additionalArgs: props.additionalArgs
})

export default createPlugin(Map, selectConnectorProps)
