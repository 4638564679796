import React, { Component } from 'react'
import _ from 'lodash'
import createPlugin from '@/BasePlugin'
import Charts from './charts'
import './ProgressBar.scss'

class ProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      percentage: 0,
      circlePercentage: 0
    }
  }

  isNilOrEmpty = (row, field, isFormat) => {
    if (!_.isNil(field) && row.hasOwnProperty(field) && !_.isNil(row[field])) {
      return !((isFormat && row[field] !== '') || !isFormat)
    }
    return true
  }

  valueFormatter = (row, value, valueFormatField) => {
    const { formatValue, getFormattedValue } = this.props
    valueFormatField = valueFormatField || '0.0'
    if (!this.isNilOrEmpty(row, value, false)) {
      if (!this.isNilOrEmpty(row, valueFormatField, true)) {
        return formatValue(row[valueFormatField], row[value])
      }
      return getFormattedValue(value, row[value])
    }
  }

  getData = () => {
    const { pluginData = [] } = this.props
    const data = {
      title: '',
      subtitle: '',
      icon: '',
      description: '',
      percentage: '',
      circleDescription: '',
      circlePercentage: ''
    }

    if (pluginData && pluginData.length > 0) {
      const [row = []] = pluginData
      const {
        settings: {
          config: {
            data: {
              title,
              subtitle,
              icon,
              description,
              percentage,
              percentageFormatField,
              circleDescription,
              circlePercentage,
              circlePercentageFormatField,
              textColor,
              progressBarColor
            } = {}
          } = {}
        } = {},
        getFormattedValue
      } = this.props

      data.title = _.trim(title && title in row ? getFormattedValue(title, row[title]) : '')
      data.subtitle = _.trim(
        subtitle && subtitle in row ? getFormattedValue(subtitle, row[subtitle]) : ''
      )
      data.icon = row[icon]
      data.description = _.trim(row[description])
      data.percentage = this.valueFormatter(row, percentage, percentageFormatField)
      data.circleDescription = _.trim(row[circleDescription])
      data.circlePercentage = this.valueFormatter(
        row,
        circlePercentage,
        circlePercentageFormatField
      )
      data.textColor = row[textColor]
      data.progressBarColor = row[progressBarColor]
    }

    return data
  }

  getSettings = (data) => {
    const {
      size: { height = 0 } = {},
      settings: {
        config: {
          settings: {
            flexDirection,
            alignItems = 'center',
            justifyContent = 'center',
            title = '',
            subtitle = '',
            theme = 'primary',
            themeIsInverse = false,
            chartType = 'circle',
            animationDuration = 0,
            textColor = '',
            backgroundColor = '',
            progressBarColor = '',
            padding = 10,
            titleSize = 20,
            subTitleSize = 16,
            descriptinSize = 22
          } = {}
        } = {}
      } = {}
    } = this.props

    const titleVal = data.title || title
    const subtitleVal = data.subtitle || subtitle
    const iconVal = data.icon || 'fa fa-line-chart'

    return {
      height,
      flexDirection: flexDirection || (chartType === 'boxProgress' ? 'row' : 'column'),
      alignItems,
      justifyContent,
      theme,
      themeIsInverse,
      chartType,
      animationDuration,
      textColor: data.textColor || textColor,
      progressBarColor: data.progressBarColor || progressBarColor,
      backgroundColor,
      padding: padding / 10,
      titleSize: titleSize / 10,
      subTitleSize: subTitleSize / 10,
      descriptinSize: descriptinSize / 10,
      titleVal,
      subtitleVal,
      iconVal
    }
  }

  render() {
    const data = this.getData()
    const settings = this.getSettings(data)
    const { theme, backgroundColor, textColor: color, themeIsInverse, padding } = settings

    return (
      <div
        className={`progress-bar-container ${theme} ${themeIsInverse ? 'inverse' : ''}`}
        style={{ backgroundColor, padding: `${padding}em`, color }}
      >
        <Charts data={data} settings={settings} />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  pluginData: props.pluginData,
  settings: props.settings,
  size: props.size,
  getFormattedValue: props.getFormattedValue,
  formatValue: props.formatValue
})

export default createPlugin(ProgressBar, selectConnectorProps)
