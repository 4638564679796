import { useRef, useEffect, useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import { OverlayTrigger, Navbar, Nav, Container } from 'react-bootstrap'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'

import { useAppDispatch, useAppSelector } from '@/hooks/store'
import { Permission, SlvyLogo } from '..'
import { useUpdateInProgress, useUrlLastSegment } from '@/hooks'
import userManager from '@/utils/userManager'
import { setLanguageModel, togglePageConfig } from '@/store/slices/pageConfig'
import { select } from '@/crudoptV3'
import { getPage } from '@/actions/page'

import Logout from './Logout'
import NotificationBox from './NotificationBox'
import QuickSearch from './QuickSearch'
import MessagingServiceStatus from './MessagingServiceStatus'
import { INavigationBarProps } from './NavigationBar.types'
import { indicatorClasses, indicatorVisibleStatuses, SIDEBAR_MENU } from './constants'
import { getSidebarMenuCollapsed, tooltip } from './helpers'
import './index.scss'

const NavigationBar = (props: INavigationBarProps) => {
  const { catalog = {}, isNotificationsPageActive } = props
  const { catalogId, menuId, pageId, environment } = useParams()

  const dispatch = useAppDispatch()

  const helpPdfUrl = useAppSelector((state) => state.pageConfig.helpPdfUrl)
  const showConfigButton = useAppSelector((state) => state.pageConfig.showConfigButton)
  const showPageConfig = useAppSelector((state) => state.pageConfig.show)
  const showLanguageModel = useAppSelector((state) => state.pageConfig.showLanguageModel)
  const model3 = useAppSelector((state) => state.model3)

  const indicatorRef = useRef(null)
  const miniNavbarRef = useRef(getSidebarMenuCollapsed()?.isCollapsed ?? false)
  const signalrClientLogCache = useRef([])
  const timerRef = useRef(null)
  const toggleRef = useRef(null)
  const updateQueueRef = useRef(null)

  const [isExpanded, setIsExpanded] = useState(false)
  const [isVisibleMessagingPopover, setIsVisibleMessagingPopover] = useState(false)
  const [showLogout, setShowLogout] = useState(false)
  const [signalrClientLog, setSignalrClientLog] = useState(signalrClientLogCache.current)

  const { urlLastSegment } = useUrlLastSegment()

  const hasParams = catalogId && menuId && pageId
  const page = hasParams && select(getPage(catalogId, menuId, pageId), model3)
  const { llmModel, llmServiceConnectionId, llmServiceSqlConnectionId } = page?.data ?? {}
  const hasLanguageModelConfig = llmModel && llmServiceConnectionId && llmServiceSqlConnectionId

  const toggleMiniNavbar = (isCollapsed: boolean) => {
    const fn = isCollapsed ? 'add' : 'remove'

    document.documentElement.classList[fn]('navbar-collapsed')
    toggleRef?.current?.classList?.[fn]?.('active')

    localStorage.setItem(SIDEBAR_MENU, JSON.stringify({ isCollapsed }))
  }

  const messagingLogLevelHandler = useCallback((event: any) => {
    const { detail: newSignalrClientLog = [] } = event
    const currentState = newSignalrClientLog?.at(-1)?.currentState ?? 'disconnected'

    indicatorRef?.current?.setAttribute?.('class', indicatorClasses)
    indicatorRef?.current?.classList?.add?.(`-${currentState}`)

    if (!indicatorVisibleStatuses.find((status: string) => status === currentState)) {
      setIsVisibleMessagingPopover(false)
    }

    if (!isEqual(signalrClientLogCache.current, newSignalrClientLog)) {
      signalrClientLogCache.current = newSignalrClientLog
      setSignalrClientLog(newSignalrClientLog)
    }
  }, [])

  useEffect(() => toggleMiniNavbar(miniNavbarRef?.current), [])

  useEffect(() => {
    window.addEventListener('MessagingLogLevel', messagingLogLevelHandler)
    return () => {
      window.removeEventListener('MessagingLogLevel', messagingLogLevelHandler)
    }
  }, [messagingLogLevelHandler])

  useUpdateInProgress((isUpdateInProgress: boolean) => {
    updateQueueRef?.current?.classList?.remove?.('active')
    if (isUpdateInProgress) {
      updateQueueRef?.current?.classList?.add?.('active')
    }
  }, 1000)

  const handleNavbarToggleClick = (event: any) => {
    event.preventDefault()

    miniNavbarRef.current = !miniNavbarRef.current

    toggleMiniNavbar(miniNavbarRef.current)

    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => window.dispatchEvent(new Event('resize')), 400)
  }

  const handleHideLogout = () => setShowLogout(false)

  const handleShowLogout = (event: any) => {
    event.preventDefault()
    setShowLogout(true)
  }

  const handleLogout = () => userManager.signoutRedirect()

  const handleShowPageConfig = (event: any) => {
    event.preventDefault()
    dispatch(togglePageConfig())
  }

  const setCollapsed = () => {
    setIsExpanded(false)
  }

  const onToggleMessagingPopover = () => {
    setIsVisibleMessagingPopover((prev: boolean) => !prev)
  }

  const isConfiguration = environment === 'Configuration'

  return (
    <>
      <Navbar
        className="bg-solvoyo border-0 navbar-common p-0"
        data-testid="navigation-bar"
        expand="xl"
        expanded={isExpanded}
        fixed="top"
        variant="dark"
      >
        <Container fluid className="p-xl-0 navigation-bar-container">
          <Navbar.Brand className="logoHeader me-xl-5 ps-xl-3 pe-xl-3 pe-0 ps-0">
            <Link to={`/${environment}`}>
              <SlvyLogo size="sm" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              toggleMiniNavbar(false)
              setIsExpanded((prevIsExpanded: boolean) => !prevIsExpanded)
            }}
          />
          <Navbar.Collapse
            className="d-xl-flex navigation-bar-nav-collapse"
            id="responsive-navbar-nav"
          >
            <Nav className="me-xl-auto order-2 order-xl-0 navigation-bar-toggle-search">
              <Nav.Item
                className="navbar-list-item px-3 py-2 cp"
                data-testid="navigation-bar-toggle-navbar-btn"
                onClick={handleNavbarToggleClick}
              >
                <Nav.Link ref={toggleRef} as="span" className="p-0">
                  <i className="fa fa-bars fa-lg" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-xl-3">
                <QuickSearch catalogId={catalogId} environment={environment} />
              </Nav.Item>
            </Nav>
            {catalog?.theme === 'test_theme' ? (
              <Nav.Item className="catalog-nav-title"> *** TEST SYSTEM ***</Nav.Item>
            ) : null}
            <Nav className="d-flex align-items-stretch align-items-xl-center">
              <OverlayTrigger overlay={tooltip('Updates in progress')} placement="bottom">
                <Nav.Item
                  ref={updateQueueRef}
                  className="navbar-list-item updateQueue px-3 py-2"
                  data-testid="navigation-bar-update-in-progress-btn"
                >
                  <i className="fa fa-pen fa-lg" />
                  <span className="ms-2 d-xl-none">Updates in progress</span>
                </Nav.Item>
              </OverlayTrigger>
              <OverlayTrigger
                rootClose
                overlay={(overlayProps) => (
                  <MessagingServiceStatus
                    {...overlayProps}
                    signalrClientLog={signalrClientLog}
                    title="Messaging Service Status"
                  />
                )}
                placement="bottom"
                show={isVisibleMessagingPopover}
                trigger="click"
                onToggle={onToggleMessagingPopover}
              >
                <Nav.Item
                  ref={indicatorRef}
                  className={indicatorClasses}
                  data-testid="navigation-bar-indicator-btn"
                  onClick={() => setSignalrClientLog(signalrClientLog)}
                >
                  <i className="fa fa-dot-circle fa-lg" />
                  <span className="ms-2 d-xl-none">Messaging Service Status</span>
                </Nav.Item>
              </OverlayTrigger>
              {helpPdfUrl ? (
                <OverlayTrigger overlay={tooltip('Help')} placement="bottom">
                  <Nav.Item
                    className="navbar-list-item px-2 py-2"
                    data-testid="navigation-bar-help-pdf-link"
                    role="button"
                    onClick={setCollapsed}
                  >
                    <Nav.Link className="p-0" href={helpPdfUrl} target="_blank">
                      <i className="fa fa-question-circle fa-lg" />
                      <span className="ms-2 d-xl-none">Help</span>
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              ) : null}
              {hasLanguageModelConfig ? (
                <OverlayTrigger overlay={tooltip('SolvoyoGPT')} placement="bottom">
                  <Nav.Item
                    className="navbar-list-item px-3 py-2 cp"
                    onClick={() => dispatch(setLanguageModel(!showLanguageModel))}
                  >
                    <Nav.Link as="span" className={cx('p-0', { active: showLanguageModel })}>
                      <i className="fa slvy-ui-icon-chat fa-lg" />
                      <span className="ms-2 d-xl-none">SolvoyoGPT</span>
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              ) : null}
              <Permission has={['Page.Edit']}>
                {showConfigButton ? (
                  <OverlayTrigger overlay={tooltip('Page Settings')} placement="bottom">
                    <Nav.Item
                      className="navbar-list-item px-3 py-2 cp"
                      data-testid="navigation-bar-show-config-btn"
                      onClick={(event: any) => {
                        handleShowPageConfig(event)
                        setCollapsed()
                        dispatch(setLanguageModel(false))
                      }}
                    >
                      <Nav.Link as="span" className={cx('p-0', { active: showPageConfig })}>
                        <i className="fa fa-cogs fa-lg" />
                        <span className="ms-2 d-xl-none">Page Settings</span>
                      </Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                ) : null}
              </Permission>
              {isConfiguration ? (
                <Permission has={['Page.Edit']}>
                  <OverlayTrigger overlay={tooltip('Management')} placement="bottom">
                    <Nav.Item
                      className={cx('navbar-list-item px-3 py-2', {
                        active: urlLastSegment === 'management'
                      })}
                      onClick={setCollapsed}
                    >
                      <Nav.Link
                        as={Link}
                        className="p-0"
                        data-testid="navigation-bar-management-link"
                        to={`/${environment}/catalog/${catalogId}/management`}
                      >
                        <i className="fa fa-tools fa-lg" />
                        <span className="ms-2 d-xl-none">Management</span>
                      </Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                </Permission>
              ) : null}
              <Permission has={['Catalog.Edit']}>
                {isConfiguration ? (
                  <OverlayTrigger overlay={tooltip('Transfer Object')} placement="bottom">
                    <Nav.Item
                      className={cx('navbar-list-item px-3 py-2', {
                        active: urlLastSegment === 'transferobject'
                      })}
                      onClick={setCollapsed}
                    >
                      <Nav.Link
                        as={Link}
                        className="p-0"
                        data-testid="navigation-bar-transfer-object-link"
                        to={`/${environment}/catalog/${catalogId}/transferobject`}
                      >
                        <i className="fa fa-random fa-lg" />
                        <span className="ms-2 d-xl-none">Transfer Object</span>
                      </Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                ) : null}
              </Permission>
              <Permission has={['Menu.Edit']}>
                <OverlayTrigger overlay={tooltip('Menu Settings')} placement="bottom">
                  <Nav.Item
                    className={cx('navbar-list-item px-3 py-2', {
                      active: urlLastSegment === 'menu'
                    })}
                    onClick={setCollapsed}
                  >
                    <Nav.Link
                      as={Link}
                      className="p-0"
                      data-testid="navigation-bar-menu-link"
                      to={`/${environment}/catalog/${catalogId}/menu`}
                    >
                      <i className="fa fa-list-ul fa-lg" />
                      <span className="ms-2 d-xl-none">Menu Settings</span>
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              </Permission>
              <Permission hasAny={['UserManagement.Read', 'UserGroupUserManagement.Read']}>
                <OverlayTrigger overlay={tooltip('User & User Group Settings')} placement="bottom">
                  <Nav.Item
                    className={cx('navbar-list-item px-3 py-2', {
                      active: urlLastSegment === 'users'
                    })}
                    onClick={setCollapsed}
                  >
                    <Nav.Link
                      as={Link}
                      className="p-0"
                      data-testid="navigation-bar-users-link"
                      to={`/${environment}/catalog/${catalogId}/users`}
                    >
                      <i className="fa fa-users fa-lg" />
                      <span className="ms-2 d-xl-none">User & User Group Settings</span>
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              </Permission>
              <OverlayTrigger overlay={tooltip('Notifications')} placement="bottom">
                <Nav.Item
                  className={cx('navbar-list-item px-3 py-2 d-xl-none', {
                    active: urlLastSegment === 'notifications'
                  })}
                  onClick={setCollapsed}
                >
                  <Nav.Link
                    as={Link}
                    className="p-0"
                    data-testid="navigation-bar-notifications-link"
                    to={`/${environment}/catalog/${catalogId}/notifications`}
                  >
                    <i className="fa fa-bell fa-lg" />
                    <span className="ms-2 d-xl-none">Notifications</span>
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
              <NotificationBox
                catalogId={catalogId}
                environment={environment}
                isDisabled={isNotificationsPageActive}
                tooltip={tooltip}
              />
              <OverlayTrigger overlay={tooltip('Log Out')} placement="bottom">
                <Nav.Item
                  className="navbar-list-item px-3 py-2 cp"
                  data-testid="navigation-bar-logout-btn"
                  onClick={handleShowLogout}
                >
                  <Nav.Link as="span" className={cx('p-0', { active: showLogout })}>
                    <i className="fa fa-sign-out fa-lg" />
                    <span className="ms-2 d-xl-none">Log Out</span>
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
              {catalog?.customerLogoUri ? (
                <OverlayTrigger overlay={tooltip('Go to Catalogs')} placement="bottom">
                  <Nav.Item className="customerLogo navbar-list-item py-0 align-items-center d-none d-xl-flex">
                    <Nav.Link as={Link} className="p-0" to={`/${environment}`}>
                      <img
                        alt="CUSTOMER LOGO"
                        className="catalogLogo"
                        height={44}
                        src={catalog.customerLogoUri}
                      />
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Logout show={showLogout} onHide={handleHideLogout} onLogout={handleLogout} />
    </>
  )
}

export default NavigationBar
