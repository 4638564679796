import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import numeral from 'numeral'
import { v3 as uuidv3 } from 'uuid'
import { confirmAlert } from 'react-confirm-alert'
import { getLockState, getApproveState, isEveryColumnInRange } from '../Util'
import { slvyToast } from '../../../../components'
import { MESSAGES } from '../../messages'
import { CURRENCIES_LOCALES } from '../../currencies-locales'
import { GridSingleDetailSummary } from './summary'
import GridCell from '../Grid/grid-cell'
import { OverlayTrigger } from '../index'

export default class GridSingleDetail extends Component {
  constructor(props) {
    super(props)
    this.currenciesOptions = CURRENCIES_LOCALES[props.culture ? props.culture : 'en-US']
    this.state = {
      pendingCount: props.pendingCount
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pendingCount !== this.props.pendingCount) {
      this.setState({ pendingCount: nextProps.pendingCount })
    }
  }

  componentDidUpdate() {
    const { updateMode = 0 } = this.props
    if (!_.isNil(this.elem)) {
      if (updateMode === 2) {
        this.elem.blur()
      } else {
        this.elem.focus()
      }
    }
  }

  getIconMap(UpColor = '#8BC34A', DownColor = '#ff0000') {
    return {
      down: { style: { color: DownColor } },
      equal: { style: { color: 'inherit' } },
      up: { style: { color: UpColor } }
    }
  }

  getIconCondition(calculatedValue) {
    return calculatedValue >= 0 ? (calculatedValue === 0 ? 'equal' : 'up') : 'down'
  }

  getCalculatedValue(isPercent, plannedValue, baselineValue) {
    return isPercent
      ? plannedValue - baselineValue
      : parseInt(baselineValue, 10) === 0
      ? 100
      : parseFloat(
          numeral(((plannedValue - baselineValue) / baselineValue) * 100)
            .format('0.0')
            .replace(',', '.'),
          10
        )
  }

  isEveryColumnInRangeValid(event, params, newValue) {
    event.classList.remove('alert')
    params.NewValue = newValue
    const updateDetails = 'UpdateDetails' in params ? params.UpdateDetails : [params]
    if (!isEveryColumnInRange(updateDetails, 'NewValue')) {
      slvyToast.warning({
        message: MESSAGES.please_enter_a_valid_value_between_min_and_max,
        options: { containerId: 'dp' }
      })
      event.classList.add('alert')
      return false
    }
    return true
  }

  updateCall(params, LeftKeys, isPercent, isApproved, event) {
    const { target, target: { value = '' } = {}, keyCode } = event

    const {
      settings: { shouldShowConfirmApprovedCellUpdate = true } = {},
      onUpdate = () => {},
      setSelectedRowId = () => {}
    } = this.props

    const { ExternalEdit = false } = params

    if (keyCode === 13 || ExternalEdit) {
      const newValue = numeral(value).value()

      if (!ExternalEdit) {
        if (!this.isEveryColumnInRangeValid(target, params, newValue)) {
          return
        }
        params.NewValue = isPercent ? newValue / 100 : newValue
      }

      const initUpdate = (_params) => {
        if (target && !_params.ExternalEdit) {
          const parent = target.parentElement
          parent.classList.add('-mini-loading')
        }

        params.LeftKeys = LeftKeys

        onUpdate(_params)
        setSelectedRowId(0)
      }

      if (isApproved === 1) {
        if (shouldShowConfirmApprovedCellUpdate) {
          confirmAlert({
            message: MESSAGES.are_you_sure_you_want_to_edit_the_already_approved_row,
            title: MESSAGES.approve_confirmation,
            buttons: [
              {
                label: MESSAGES.cancel
              },
              {
                label: MESSAGES.confirm,
                onClick: () => initUpdate(params)
              }
            ]
          })
        } else {
          initUpdate(params)
        }
      } else {
        initUpdate(params)
      }
    }
  }

  _nodeActionCallback(LeftKeys, DynamicKey, currentPeriod, event) {
    event.stopPropagation()
    this.props.nodeActionCallback(LeftKeys, { ...DynamicKey, ...{ fieldName: currentPeriod } })
  }

  getFinalMetrics = (Metrics) => {
    const {
      props: { selectedMetricFields = [], newMetrics = [] }
    } = this

    const finalMetrics = []
    selectedMetricFields.forEach((item) => {
      const foundMetricIndex = newMetrics.findIndex((elem) => elem.Name === item.Name)
      if (foundMetricIndex > -1) {
        finalMetrics.push(Metrics[foundMetricIndex])
      } else {
        finalMetrics.push([])
      }
    })
    return finalMetrics
  }

  getFinalMetricsWithProps = () => {
    const {
      props: {
        metricField = '',
        multiColumnEnabled = false,
        selectedMetricFields = [],
        values = []
      }
    } = this

    if (!multiColumnEnabled) {
      const result = values.find((elem) => elem.Name === metricField)
      return result ? [result] : [values[0]]
    }

    const finalMetrics = []
    selectedMetricFields.forEach((item) => {
      const foundMetric = values.find((elem) => elem.Name === item.Name)
      finalMetrics.push(foundMetric || [])
    })
    return finalMetrics
  }

  render() {
    let {
      props: {
        isRowClickedEventOn,
        allowedToUpdate,
        buttonFormat,
        index = 0,
        isScenarioEditable,
        leftHierarchy = [],
        multiColumnEnabled = false,
        selectedRowId,
        singleDetailBreadcrumbs = [],
        style = {},
        topFields = [],
        updateMode = 0,
        viewMode = 0,
        click = () => {},
        setSelectedRowId = () => {},
        data: { LeftKeys, Metrics = [], IsParent = false } = {},
        settings: { isLock: isVisibleLock = true, isApprove: isVisibleApprove = true } = {}
      },
      state: { pendingCount },
      currenciesOptions: { decimalScale, thousandSeparator, decimalSeparator }
    } = this

    const currentLeftKey = LeftKeys.length ? _.last(LeftKeys) : ''

    const valuesId = uuidv3(`${currentLeftKey} ${Metrics.toString()}`, uuidv3.DNS)

    const isDetailData = true
    let detailDataList = []
    if (isDetailData && Metrics.length) {
      const detailCell = _.first(Metrics) || { Metrics: [] }
      if (detailCell.Metrics.length && detailCell.Metrics[0].length > 1) {
        detailDataList = detailCell.Metrics

        if (multiColumnEnabled) {
          detailDataList = this.getFinalMetrics(detailCell.Metrics)
        }
      }

      Metrics = _.slice(Metrics, 1, Metrics.length)
    }

    const isOpened = _.isEqual(_.last(singleDetailBreadcrumbs), _.last(LeftKeys))
      ? 'is-active'
      : 'is-not-active'

    const _MetricsWithProps = this.getFinalMetricsWithProps()
    const [{ IsUpdateAllowed = false }] = _MetricsWithProps

    const isEditable = isScenarioEditable && IsUpdateAllowed && viewMode === 2
    const isEditableInputClass = isEditable ? ' -editable ' : ''

    const { cell = 0, row = 0 } = selectedRowId
    const isEditableHover = isEditable && _.isEqual(row, valuesId) ? '-editable-hover' : ''

    const level = LeftKeys.length

    const defaultPaddingLeft = 5
    const titleStyle = {
      paddingLeft: level < 2 ? defaultPaddingLeft : level * defaultPaddingLeft
    }

    const defaultWidth = 2.5
    const levelStyle = {
      width: (level - 1) * defaultWidth
    }

    const _msg = `${MESSAGES.there_are_pending_updates} ${MESSAGES.please_wait_for_them_to_be_completed}`

    const getTitleTmp = (isEnabled) => (
      <div
        className={`slvy-ui-section -title ${isEnabled ? '' : '-not-allowed'}`}
        style={titleStyle}
        onClick={() => (isEnabled ? click(currentLeftKey, IsParent) : () => {})}
      >
        <i className="slvy-ui-row-level" style={levelStyle} />
        <span className="slvy-ui-row-title-text" title={currentLeftKey}>
          {currentLeftKey}
        </span>
        {isRowClickedEventOn ? (
          <>
            <OverlayTrigger placement="right" tooltip="Row click">
              <input
                className="row-clicked-event-input"
                name="grid-single-detail-row"
                type="radio"
                onClick={this.props.onRowClicked.bind(this, LeftKeys)}
              />
            </OverlayTrigger>
            <span className="slvy-ui-icon-arrow-right-circle row-clicked-event-icon" />
          </>
        ) : null}
      </div>
    )

    return (
      <div style={style}>
        <div key={index} className={`slvy-ui-row ${isEditableHover} ${isOpened}`}>
          <OverlayTrigger shouldWrap={Boolean(pendingCount)} tooltip={_msg}>
            {getTitleTmp(!pendingCount)}
          </OverlayTrigger>
          <div className="slvy-ui-section -values -detail-values">
            {_.map(Metrics, (subitem, subindex) => {
              const currentPeriod = topFields[subindex]

              let {
                IsApproved: approveIndicator = 0,
                IsLocked: lockIndicator = 0,
                Metrics: _Metrics = []
              } = subitem

              if (multiColumnEnabled) {
                _Metrics = this.getFinalMetrics(_Metrics)
              }

              return (
                <div key={subindex + currentPeriod} className="-cell single-wrapper-cell">
                  {_Metrics.map((_MetricItem, _MetricItemIndex) => {
                    const valuesData = _MetricsWithProps[_MetricItemIndex] || {}

                    const {
                      Format,
                      ExternalEdit = false,
                      Arrow: { DownColor = '', UpColor = '', Visible = true } = {},
                      Validation: {
                        MaxAcceptableValue,
                        MinAcceptableValue,
                        MinMaxCheckType = 'absolute'
                      } = {},
                      Name: _metricField_ = ''
                    } = valuesData

                    const isPercent = !_.isNil(Format) ? Format.indexOf('%') > 0 : false
                    const iconMap = this.getIconMap(UpColor, DownColor)

                    const moreThanOne = _Metrics.length > 1

                    const isFirstMetric = _MetricItemIndex === 0

                    const [baselineValue, plannedValue] = _MetricItem

                    const LockKey = getLockState(lockIndicator)
                    const ApproveKey = getApproveState(approveIndicator)

                    const uniqueID = uuidv3(
                      `${currentPeriod}
                       ${currentLeftKey}
                       ${index}
                       ${subindex}
                       ${_MetricItemIndex}`,
                      uuidv3.DNS
                    )

                    const calculatedValue = this.getCalculatedValue(
                      isPercent,
                      plannedValue,
                      baselineValue
                    )

                    const iconCondition = this.getIconCondition(calculatedValue)

                    const isEditableActive =
                      isEditable && _.isEqual(uniqueID, cell) && updateMode !== 2

                    const isEditableClass = isEditableActive ? '-editable-active' : ''

                    const UpdateType = buttonFormat === 'percentage' ? 1 : 0

                    const $placeholder =
                      UpdateType === 1 ? '100%' : numeral(plannedValue).format(Format)

                    const firstMetricClass = isFirstMetric ? ' is-first-metric ' : ''
                    const lowerThanTwoClass = !moreThanOne ? ' full-width-cell ' : ''
                    const extra = isFirstMetric ? { 'data-id': uniqueID } : {}

                    return (
                      <div
                        className={`cell-metric-item -cell ${firstMetricClass} ${isEditableClass} ${lowerThanTwoClass}`}
                        {...extra}
                        key={`${index}-${subindex}-${_MetricItemIndex}`}
                        onClick={
                          isFirstMetric
                            ? lockIndicator !== 1
                              ? isEditable && ExternalEdit && updateMode === 2 && viewMode === 2
                                ? this.updateCall.bind(
                                    this,
                                    {
                                      BaselineValue: baselineValue,
                                      ExternalEdit,
                                      Guid: uniqueID,
                                      IsParent,
                                      LeftKeys: leftHierarchy,
                                      MetricField: _metricField_,
                                      OldValue: plannedValue,
                                      SubIndex: subindex,
                                      TopKey: topFields[subindex],
                                      UpdateType
                                    },
                                    LeftKeys,
                                    isPercent,
                                    approveIndicator
                                  )
                                : () => {
                                    setSelectedRowId(uniqueID, valuesId)
                                    if (_.isEqual(uniqueID, cell)) {
                                      setSelectedRowId(0)
                                    }
                                  }
                              : () => {}
                            : () => {}
                        }
                      >
                        <div className="-cellContent">
                          {isScenarioEditable &&
                          isFirstMetric &&
                          allowedToUpdate &&
                          (isVisibleLock || isVisibleApprove) ? (
                            <span className="-cell-btn-group -single-detail">
                              {isVisibleLock ? (
                                <OverlayTrigger tooltip={LockKey.name}>
                                  <i
                                    className={LockKey.className}
                                    onClick={this._nodeActionCallback.bind(
                                      this,
                                      LeftKeys,
                                      LockKey,
                                      currentPeriod
                                    )}
                                  />
                                </OverlayTrigger>
                              ) : null}
                              {isVisibleApprove ? (
                                <OverlayTrigger tooltip={ApproveKey.status}>
                                  <i
                                    className={ApproveKey.className}
                                    onClick={this._nodeActionCallback.bind(
                                      this,
                                      LeftKeys,
                                      ApproveKey,
                                      currentPeriod
                                    )}
                                  />
                                </OverlayTrigger>
                              ) : null}
                            </span>
                          ) : null}
                          {isFirstMetric && Visible && !_.isEqual(plannedValue, baselineValue) ? (
                            <div className="-corner" style={iconMap[iconCondition].style}>
                              <i className={`diff-icon fa fa-caret-${iconCondition}`} />
                              <div className="-percentage">
                                {isPercent
                                  ? numeral(calculatedValue).format('0.00%')
                                  : `${calculatedValue}%`}
                              </div>
                            </div>
                          ) : null}
                          <div
                            key={`${index}-${subindex}-${_MetricItemIndex}-${0}`}
                            className="-baseline"
                          >
                            {_MetricItem.length > 0 ? (
                              numeral(isFirstMetric ? baselineValue : plannedValue).format(Format)
                            ) : (
                              <strong className="fw-bold">&nbsp;</strong>
                            )}
                          </div>

                          {isFirstMetric ? (
                            isEditable && lockIndicator !== 1 ? (
                              // eslint-disable-next-line no-mixed-operators
                              updateMode === 0 || (updateMode === 1 && viewMode === 2) ? (
                                <div
                                  key={`${index}-${subindex}-${_MetricItemIndex}-${1}`}
                                  className={`-planned ${isEditableInputClass}`}
                                >
                                  {numeral(plannedValue).format(Format)}
                                  <GridCell
                                    ref={(inst) => {
                                      if (uniqueID === cell) {
                                        this.elem = ReactDOM.findDOMNode(inst)
                                      }
                                    }}
                                    gridCellProps={{
                                      name: subindex,
                                      placeholder: $placeholder,
                                      plannedValue,
                                      LeftKeys
                                    }}
                                    numberFormatProps={{
                                      decimalScale,
                                      decimalSeparator,
                                      fixedDecimalScale: true,
                                      placeholder: $placeholder,
                                      thousandSeparator,
                                      value: '',
                                      ref: (inst) => {
                                        if (uniqueID === cell) {
                                          this.elem = ReactDOM.findDOMNode(inst)
                                        }
                                      },
                                      onKeyDown: this.updateCall.bind(
                                        this,
                                        {
                                          BaselineValue: baselineValue,
                                          Guid: uniqueID,
                                          IsParent,
                                          LeftKeys: leftHierarchy,
                                          MaxAcceptableValue,
                                          MetricField: _metricField_,
                                          MinAcceptableValue,
                                          MinMaxCheckType,
                                          OldValue: plannedValue,
                                          TopKey: topFields[subindex],
                                          UpdateType
                                        },
                                        LeftKeys,
                                        isPercent,
                                        approveIndicator
                                      )
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  key={`${index}-${subindex}-${_MetricItemIndex}-${1}`}
                                  className={`-planned ${isEditableInputClass}`}
                                >
                                  {numeral(plannedValue).format(Format)}
                                  <input
                                    ref={(elem) => {
                                      if (uniqueID === cell) {
                                        this.elem = elem
                                      }
                                    }}
                                    placeholder={$placeholder}
                                    type="text"
                                    onClick={
                                      isEditable
                                        ? this.updateCall.bind(
                                            this,
                                            {
                                              BaselineValue: baselineValue,
                                              ExternalEdit,
                                              Guid: uniqueID,
                                              LeftKeys: leftHierarchy,
                                              MetricField: _metricField_,
                                              OldValue: plannedValue,
                                              TopKey: topFields[subindex],
                                              UpdateType
                                            },
                                            LeftKeys,
                                            isPercent,
                                            approveIndicator
                                          )
                                        : () => {}
                                    }
                                  />
                                </div>
                              )
                            ) : (
                              <div
                                key={`${index}-${subindex}-${_MetricItemIndex}-${1}`}
                                className="-planned"
                              >
                                {numeral(plannedValue).format(Format)}
                              </div>
                            )
                          ) : null}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>

          <GridSingleDetailSummary
            detailDataList={detailDataList}
            isDetailData={isDetailData}
            metricsWithProps={_MetricsWithProps}
          />
        </div>
      </div>
    )
  }
}
