import _ from 'lodash'
import { updateObject } from '../utility'
import { COLLAPSE, COLLAPSE_ALL, UNCOLLAPSE, UNCOLLAPSE_BULK } from '../actions/actionTypes'

const initialState = {
  scenarioTree: {
    unCollapsedScenarios: []
    // { Id: 1, dataKey: 'scenarioTree', scenarioType: 'baseline' }, { Id:1, dataKey: 'scenarioTree', scenarioType: 'planned' }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLAPSE:
      const currentUnCollapsedScenarios = _.cloneDeep(
        state[action.payload.dataKey].unCollapsedScenarios
      )
      const othersUnCollapsedScenarios = [...currentUnCollapsedScenarios].filter(
        ({ scenarioType }) => scenarioType !== action.payload.scenarioType
      )
      const nowUnCollapsedScenarios = [...currentUnCollapsedScenarios].filter(
        ({ Id, scenarioType }) => {
          return Id !== action.payload.Id && scenarioType === action.payload.scenarioType
        }
      )
      return updateObject(state, {
        [action.payload.dataKey]: {
          unCollapsedScenarios: [...othersUnCollapsedScenarios, ...nowUnCollapsedScenarios]
        }
      })
    case COLLAPSE_ALL:
      return updateObject(state, {
        [action.payload.dataKey]: {
          unCollapsedScenarios: [...action.payload.unCollapsedScenarios]
        }
      })
    case UNCOLLAPSE:
      return updateObject(state, {
        [action.payload.dataKey]: {
          unCollapsedScenarios: [
            ...state[action.payload.dataKey].unCollapsedScenarios,
            { ...action.payload }
          ]
        }
      })
    case UNCOLLAPSE_BULK:
      return updateObject(state, {
        [action.payload.dataKey]: {
          unCollapsedScenarios: _.uniqWith(
            [
              ...state[action.payload.dataKey].unCollapsedScenarios,
              ...action.payload.unCollapsedScenarios
            ],
            _.isEqual
          )
        }
      })
    default:
      return state
  }
}

export default reducer
