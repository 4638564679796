Ext.define('MDM.src.view.globalapp.Panel.Time.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.globalapp-time-maincontroller',
  /**
   * Called when the view is created
   */
  init: function () {},
  onActivateGeo: function () {
    var store = this.getViewModel().getStore('TimeStore').load()
  },
  onExpandAllClick: function (btn) {
    var tree = this.lookup('tree').expandAll()
  },
  onCollapseAllClick: function (btn) {
    this.lookup('tree').collapseAll()
    this.lookup('tree').getRootNode().expand()
  }
})
