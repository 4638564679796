Ext.define('Spui.view.common.productinfo.cmp.tabpanel.Info.Other', {
  extend: 'Ext.panel.Panel',

  // Uncomment to give this component an xtype
  xtype: 'spui-view-common-productinfo-cmp-tabpanel-info-other',

  plugins: [
    {
      ptype: 'cornerIcon',
      cfgButton: [
        '->',
        {
          iconCls: 'x-fa fa-cog',
          cls: 'config-btn',
          handler: 'onConfigureOtherInfo'
        }
      ]
    }
  ],

  layout: 'fit'
})
