Ext.define('tuiv2.gridview.loadStopEntryTable', {
  extend: 'Ext.grid.Panel',
  xtype: 'tuiv2-loadStopEntryTable',
  alias: 'widget.tuiv2-loadStopEntryTable',
  reference: 'loadStopEntryTable',
  columnLines: true,
  viewConfig: {
    enableTextSelection: true
  },
  columns: []
})
