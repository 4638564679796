import * as actionTypes from './actionTypes'
import { makeRequest } from '../../utils'

export const fetchMfpDetailData = (action) => {
  return (dispatch) => {
    dispatch(startGetMfpDetailDataStart())

    return new Promise((resolve, reject) => {
      makeRequest(action)
        .then((response) => {
          dispatch(
            saveGetMfpDetailData({
              payload: response.data
            })
          )
          resolve(response)
        })
        .catch((error) => {
          dispatch(
            saveGetMfpDetailDataFailed({
              payload: error.data
            })
          )
          reject(error)
        })
    })
  }
}

export const startGetMfpDetailDataStart = () => {
  return {
    type: actionTypes.FETCH_MFP_DETAIL_DATA_START
  }
}

export const saveGetMfpDetailData = (options) => {
  return {
    type: actionTypes.STORE_MFP_DETAIL_DATA,
    payload: options.payload
  }
}

export const saveGetMfpDetailDataFailed = (options) => {
  return {
    type: actionTypes.STORE_MFP_DETAIL_DATA_FAILED,
    payload: options.payload
  }
}
