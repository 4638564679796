import { useMemo } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { AG_GRID_ENUMS } from '../../enums'
import { IRowData, SlvyDeleteColDef } from '../../types'
import { DeleteColumnCellRenderer } from '../../components/CellRenderers/index'
import { CustomTooltipView } from '../../components/CustomTooltip'
import { DeleteColDefProps } from './useDeleteColDef.types'
import { getCellStyle } from '../../helpers'

const { DELETE_COLUMN } = AG_GRID_ENUMS

function useDeleteColDef(props: DeleteColDefProps) {
  const {
    width,
    deleting: {
      enabled,
      deletableCondition,
      deleteButtonSettings: { icon, tooltip }
    },
    rowDisplayRules,
    gridRef
  } = props

  const { deleteAction: deleteActionLocale } =
    gridRef?.current?.api?.getGridOption?.('localeText') || {}

  return useMemo((): { isExist: boolean; col: null | SlvyDeleteColDef } => {
    if (!enabled) {
      return { isExist: false, col: null }
    }

    return {
      isExist: true,
      col: {
        customInfo: {
          adding: {
            enabled: false
          },
          boolean: { trueIcon: '', falseIcon: '', trueColor: '', falseColor: '' },
          deletableCondition,
          columnType: 'icon',
          isExportable: false
        },
        ...getCellStyle({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          customConfigs: {
            isRawFieldEmpty: false,
            rawField: DELETE_COLUMN,
            cellStyle: {
              textAlign: 'center',
              backgroundColor: '',
              color: ''
            }
          },
          cellDisplayRules: [],
          rowDisplayRules,
          getCellColorsFromData: false,
          enableCellTextSelection: false
        }),
        cellDataType: true,
        headerComponent: () => null,
        headerName: deleteActionLocale,
        enableRowGroup: false,
        editable: () => false,
        resizable: false,
        width,
        flex: 0,
        sortable: false,
        field: DELETE_COLUMN,
        colId: DELETE_COLUMN,
        tooltipField: DELETE_COLUMN,
        tooltipComponent: CustomTooltipView,
        tooltipComponentParams: {
          tooltip
        },
        cellRendererSelector: (params: ICellRendererParams<IRowData>) => {
          const isFooter = params.node.footer

          let component = DeleteColumnCellRenderer

          if (isFooter) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component = null
          }

          return {
            component,
            params: {
              icon: {
                icon
              }
            }
          }
        }
      }
    }
  }, [deletableCondition, enabled, icon, deleteActionLocale, rowDisplayRules, tooltip, width])
}
export default useDeleteColDef
