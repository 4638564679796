require('./Highcharts.js')
require('./Highcharts/Serie')
require('./Highcharts/RangeSerie')
require('./Highcharts/AreaRangeSerie')
require('./Highcharts/AreaSerie')
require('./Highcharts/AreaSplineRangeSerie')
require('./Highcharts/AreaSplineSerie')
require('./Highcharts/BarSerie')
require('./Highcharts/BoxPlotSerie')
require('./Highcharts/BubbleSerie')
require('./Highcharts/ColumnRangeSerie')
require('./Highcharts/ColumnSerie')
require('./Highcharts/ColoredlineSerie')
require('./Highcharts/ErrorBarSerie')
require('./Highcharts/WaterfallSerie')
require('./Highcharts/FunnelSerie')
require('./Highcharts/GaugeSerie')
require('./Highcharts/SolidGaugeSerie')
require('./Highcharts/LineSerie')
require('./Highcharts/PieSerie')
require('./Highcharts/ScatterSerie')
require('./Highcharts/SplineSerie')
require('./Highcharts/PyramidSerie')
require('./Highcharts/HeatmapSerie')
require('./Highcharts/MapSerie')
require('./Highcharts/MapBubbleSerie')
require('./Highcharts/MapLineSerie')
require('./Highcharts/MapPointSerie')
require('./Highmaps.js')
