export default {
  title: 'Image Gallery',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'ImageGallery',
  description: 'to add and show multiple images',
  iconClass: 'fa-align-center',
  color: 'lazur-bg',
  group: 'Views'
}
