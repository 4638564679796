import { useEffect, useMemo } from 'react'
import { SlvyTable } from '@/components'
import { useReduxState } from '../../../hooks'
import { getLoadStopEntryTable } from '../../../store/api/endpoints'
import { loadTableSlice, loadStopTableSlice, tuiSlice } from '../../../store/slices'
import { getEnabledColumns, getTableQueryParams } from '../../../utils'

export default function LoadStopEntryTable() {
  const [{ config }] = useReduxState(tuiSlice.selectSlice)
  const [loadTable] = useReduxState(loadTableSlice.selectSlice)
  const [loadStopTable] = useReduxState(loadStopTableSlice.selectSlice)
  const [triggerGetLoadStopEntryTable, loadStopEntryTableResponse] =
    getLoadStopEntryTable.useLazyQuery()
  const columns = useMemo(() => [...getEnabledColumns(config.loadStopEntryTable.columns)], [])

  useEffect(() => {
    if (loadStopTable.selectedRow) {
      triggerGetLoadStopEntryTable({
        id: loadTable.selectedRow,
        loadStopSequence: '',
        ...getTableQueryParams(config, 'loadStopEntryTable')
      })
    }
  }, [])

  useEffect(() => {
    triggerGetLoadStopEntryTable({
      id: loadTable.selectedRow,
      loadStopSequence: '',
      ...getTableQueryParams(config, 'loadStopEntryTable')
    })
  }, [loadStopTable.selectedRow])

  function handleSelectRow({ original }) {}

  return (
    <div className="w-50 d-flex flex-column">
      <p className="p-0 m-0 fw-bold">{config.loadStopEntryTable.header}</p>
      <SlvyTable
        pagination
        rowSelection
        columns={columns}
        data={loadStopEntryTableResponse.data}
        grouping={config.loadStopEntryTable.isGroupingEnabled}
        isLoading={!loadStopEntryTableResponse.data?.length}
        paginationOpts={{
          pageSize: 1000
        }}
        theme="sencha-grid"
        onSelectRow={handleSelectRow}
      />
    </div>
  )
}
