import * as WaitEvent from './WaitEvent/index.ts'
import * as RunMethod from './RunMethod/index.ts'
import * as PageReady from './PageReady/index.ts'
import * as Delay from './Delay/index.ts'
import * as RunDrilldown from './RunDrilldown/index.ts'
import * as UpdateVariables from './UpdateVariables/index.ts'
import * as RunRemoteAction from './RunRemoteAction/index.ts'
import * as RunLongRunningAction from './RunLongRunningAction/index.ts'
import * as RemoteActionCompleted from './RemoteActionCompleted/index.ts'
import * as Loading from './Loading/index.ts'
import * as Condition from './Condition/index.ts'
import * as GenerateKey from './GenerateKey/index.ts'
import * as ChangeConnection from './ChangeConnection/index.ts'

export default {
  WaitEvent: {
    component: WaitEvent.default,
    config: WaitEvent.config,
    group: 'other'
  },
  ChangeConnection: {
    component: null,
    config: ChangeConnection.config,
    group: 'other'
  },
  RemoteActionCompleted: {
    component: RemoteActionCompleted.default,
    config: RemoteActionCompleted.config,
    group: 'other'
  },
  UpdateVariables: {
    component: UpdateVariables.default,
    config: UpdateVariables.config,
    group: 'run'
  },
  PageReady: {
    component: PageReady.default,
    config: PageReady.config,
    group: 'other'
  },
  Delay: {
    component: Delay.default,
    config: Delay.config,
    group: 'other'
  },
  Condition: {
    component: Condition.default,
    config: Condition.config,
    group: 'other'
  },
  RunMethod: {
    component: RunMethod.default,
    config: RunMethod.config,
    group: 'run'
  },
  RunDrilldown: {
    component: RunDrilldown.default,
    config: RunDrilldown.config,
    group: 'run'
  },
  RunRemoteAction: {
    component: RunRemoteAction.default,
    config: RunRemoteAction.config,
    group: 'run'
  },
  RunLongRunningAction: {
    component: RunLongRunningAction.default,
    config: RunLongRunningAction.config,
    group: 'run'
  },
  GenerateKey: {
    component: GenerateKey.default,
    config: GenerateKey.config,
    group: 'run'
  },
  Loading: {
    component: Loading.default,
    config: Loading.config,
    group: 'run'
  }
}
