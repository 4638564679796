import { useEffect, useRef } from 'react'
import Message from './components/Message'
import { ChatProps } from './Chat.types'

const Chat = ({ messages }: ChatProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div
      ref={containerRef}
      className="d-flex flex-column gap-4 overflow-x-auto pe-2 default-scrollbar"
    >
      {messages.map((message, index) => (
        <Message key={index} data={message} />
      ))}
    </div>
  )
}

export default Chat
