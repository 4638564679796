import { deleteRecord, fetchCollection, fetchRecord, updateOne } from '../crudoptV3'

const model = 'userGroup'

export const getUserGroups = (customerId) => {
  return fetchCollection(model, `/customer/${customerId}/usergroup`, {
    params: { customerId }
  })
}

export const getUserGroupsForUsersControlPanel = (catalogCustomerId) => {
  return fetchCollection(
    model,
    `/customer/${catalogCustomerId}/usergroupsForUsersControlPanel`,
    {
      params: { catalogCustomerId }
    },
    {
      collectionKey: { path: `/usergroup` },
      getRowKey: (row, index) => row.id
    }
  )
}

export const deleteUserGroup = (id, customerId) => {
  return deleteRecord(
    model,
    `/usergroup/${id}`,
    { data: { id } },
    {
      key: id,
      getRowKey: () => id,
      collectionKey: {
        path: `/customer/${customerId}/usergroupsForUsersControlPanel`,
        params: { catalogCustomerId: customerId }
      }
    }
  )
}

export const updateUserGroup = (id, name, customerId) => {
  return updateOne(
    model,
    `/usergroup`,
    {
      data: {
        id: id,
        Name: name,
        CustomerId: customerId
      }
    },
    {
      key: id,
      collectionKey: { path: `/usergroup` },
      getRowKey: (row, index) => row.id
    }
  )
}

export const createUserGroup = (customerId, name) => {
  return fetchRecord(
    model,
    `/usergroup`,
    {
      data: { CustomerId: customerId, Name: name }
    },
    {
      getRowKey: (row, index) => row.id,
      collectionKey: {
        path: `/customer/${customerId}/usergroupsForUsersControlPanel`,
        params: { catalogCustomerId: customerId }
      }
    }
  )
}
