import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_grouped from 'highcharts-grouped-categories'
import HC_patternFill from 'highcharts/modules/pattern-fill'
import HC_stock from 'highcharts/modules/stock'
import HC_scroll from './customModules/scroll'

import createPlugin, { PluginTypes } from '@/BasePlugin'
import { getFormatedValue } from '@/helpers/formats'
import { selectCollection } from '@/crudoptV3'
import { getPluginStates } from '@/actions/pluginstate'
import { slvyToast } from '@/components'

import './index.scss'

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)
HC_grouped(Highcharts)
HC_patternFill(Highcharts)
HC_stock(Highcharts)
HC_scroll(Highcharts)

class Highchart extends Component {
  constructor(props) {
    super(props)
    this.highchartRef = {}
    this.state = {
      title: null,
      subtitle: null,
      tooltipHtml: null
    }
    this.isToastrMessage = false

    this.handleInitializeSeries = this.handleInitializeSeries.bind(this)
    this.handleAxisChanged = this.handleAxisChanged.bind(this)
    this.tooltipFormatter = this.tooltipFormatter.bind(this)
    this.gridTooltipFormatter = this.gridTooltipFormatter.bind(this)
    this.onPointClick = this.onPointClick.bind(this)
    this.handleSynchronizeDataDefinition = this.handleSynchronizeDataDefinition.bind(this)
    this.onSeriesHide = this.onSeriesHide.bind(this)
    this.onSeriesShow = this.onSeriesShow.bind(this)
    this.applyAxisFormatters = this.applyAxisFormatters.bind(this)
    this.applyLabelFormatters = this.applyLabelFormatters.bind(this)
    this.handleInitialization = this.handleInitialization.bind(this)
    this.handleDataSeriesTypeChanged = this.handleDataSeriesTypeChanged.bind(this)
    this.getCategories = this.getCategories.bind(this)
    this.getSeriesData = this.getSeriesData.bind(this)
    this.getSeriesRowData = this.getSeriesRowData.bind(this)
    this.handleLegendColor = this.handleLegendColor.bind(this)
  }

  getRow = (position) => {
    const { pluginData = [] } = this.props
    return position && pluginData.length > 0 ? pluginData[position] || {} : {}
  }

  handlePointClick(value) {
    return value
  }

  setTitle({ title }) {
    if (this.state.title !== title) {
      this.setState({ title })
    }
  }

  setSubtitle({ subtitle }) {
    if (this.state.subtitle !== subtitle) {
      this.setState({ subtitle })
    }
  }

  setTooltipHtml({ tooltipHtml }) {
    if (this.state.tooltipHtml !== tooltipHtml) {
      this.setState({ tooltipHtml })
    }
  }

  handleDataSeriesChanged(value, initialData, previousValue) {
    initialData.series = []

    const { series = [] } = value || {}
    _.forEach(series, (series, index) => {
      if (!initialData.series[index]) {
        initialData.series[index] = {
          fieldName: '',
          seriesProps: {
            type: ''
          }
        }
        // New added series is different from its initial value
        series.__Temp = 'tmp'
      }
    })
    return value
  }

  handleAxisChanged(value, initialData, previousValue, schemaHelper) {
    if (!this.xAxisValue) {
      const {
        props: {
          schema: {
            properties: {
              xAxis: { items: xItems = {} } = {},
              yAxis: { items: yItems = {} } = {},
              zAxis: { items: zItems = {} } = {}
            } = {}
          } = {}
        } = {}
      } = this
      this.xAxisValue = schemaHelper.getValueOfSchema(xItems)
      this.yAxisValue = schemaHelper.getValueOfSchema(yItems)
      this.zAxisValue = schemaHelper.getValueOfSchema(zItems)
    }
    const { xAxis = [], yAxis = [], zAxis = [] } = value || {}
    let { xAxis: initXAxis = [], yAxis: initYAxis = [], zAxis: initZAxis = [] } = initialData || {}
    if (value && _.size(value.xAxis) > 0) {
      initXAxis = []
      _.forEach(xAxis, (axis, index) => {
        if (!initXAxis[index]) {
          initXAxis[index] = {}
          _.assign(initXAxis[index], this.xAxisValue)
          xAxis[index].axisId = index.toString()
        }
      })

      initYAxis = []
      _.forEach(yAxis, (axis, index) => {
        if (!initYAxis[index]) {
          initYAxis[index] = {}
          _.assign(initYAxis[index], this.yAxisValue)
          yAxis[index].axisId = index.toString()
        }
      })

      initZAxis = []
      _.forEach(zAxis, (axis, index) => {
        if (!initZAxis[index]) {
          initZAxis[index] = {}
          _.assign(initZAxis[index], this.zAxisValue)
          zAxis[index].axisId = index.toString()
        }
      })
    }
    return value
  }

  handleInitializeSeries(value, initialData, previousValue, schemaHelper) {
    const {
      props: {
        schema: {
          properties: {
            series: {
              items: { properties: { seriesProps: { oneOf: typesSchema = [] } = {} } = {} } = {}
            } = {}
          } = {}
        } = {}
      } = {}
    } = this

    const { series = [] } = value || {}

    _.forEach(series, (series, index) => {
      let selectedSeriesType = ' '
      if (series.seriesProps && series.seriesProps.type) {
        selectedSeriesType = series.seriesProps.type
      }
      const initialSeries = initialData.series[index]

      _.forEach(typesSchema, function (typeSchema, index) {
        const seriesType = typeSchema.properties.type.enum[0]
        if (seriesType === selectedSeriesType) {
          const typeInitialVal = schemaHelper.getValueOfSchema(typeSchema)
          _.assign(initialSeries.seriesProps, typeInitialVal)
          initialSeries.seriesProps.type = ' '
          return false
        }
      })
    })
    return value
  }

  getDifferenceObject(initial, modified) {
    for (const prop in initial) {
      if (!initial.hasOwnProperty(prop)) continue
      if (!modified.hasOwnProperty(prop)) continue
      if (_.isEqual(initial[prop], modified[prop])) {
        delete initial[prop]
        delete modified[prop]
      } else if (typeof initial[prop] === 'object') {
        this.getDifferenceObject(initial[prop], modified[prop])
      }
    }
  }

  getDiffObject(initial, modified) {
    const initialClone = _.cloneDeep(initial)
    const modifiedClone = _.cloneDeep(modified)

    this.getDifferenceObject(initialClone, modifiedClone)
    return modifiedClone
  }

  assignDiffToObject(initial, diff) {
    for (const prop in initial) {
      if (!initial.hasOwnProperty(prop)) continue
      if (!diff.hasOwnProperty(prop)) continue
      if (!_.isEqual(initial[prop], diff[prop])) {
        if (typeof initial[prop] === 'object') {
          this.assignDiffToObject(initial[prop], diff[prop])
        } else {
          initial[prop] = diff[prop]
        }
      }
    }
  }

  handleDataSeriesTypeChanged(value, initialData, previousValue, schemaHelper) {
    // Type of s series changes.
    // Get all the modfied parameters of the old type
    // Apply them to the new type, if they exist on the new type
    const { series: prevSeries = {}, series: { length: prevSeriescount = 0 } = {} } =
      previousValue || {}
    const { series: { length: actualSeriescount = 0 } = {} } = value || {}

    const seriesCountChanged = prevSeriescount !== actualSeriescount
    // Ignore when aseries count changed
    if (!seriesCountChanged) {
      const { series = [] } = value || {}
      _.forEach(series, (serie, index) => {
        const { seriesProps = {}, seriesProps: { type: actualSeriesType = ' ' } = {} } = serie || {}
        const previousseries = prevSeries[index]
        const { seriesProps: { type: prevSeriesType = ' ' } = {} } = previousseries || {}

        // Only when the type of a series has changed
        if (actualSeriesType !== prevSeriesType) {
          const {
            props: {
              schema: {
                properties: {
                  series: {
                    items: {
                      properties: { seriesProps: { oneOf: typesSchema = [] } = {} } = {}
                    } = {}
                  } = {}
                } = {}
              } = {}
            } = {}
          } = this
          const actualTypeSchema = _.find(typesSchema, (typeSchema) => {
            return actualSeriesType === typeSchema.properties.type.enum[0]
          })

          const prevTypeSchema = _.find(typesSchema, (typeSchema) => {
            return prevSeriesType === typeSchema.properties.type.enum[0]
          })

          if (actualTypeSchema && prevTypeSchema) {
            const actualTypeInitialVal = schemaHelper.getValueOfSchema(actualTypeSchema)
            const prevTypeInitialVal = schemaHelper.getValueOfSchema(prevTypeSchema)
            const changedProperties = this.getDiffObject(prevTypeInitialVal, seriesProps)

            _.assign(seriesProps, actualTypeInitialVal)
            this.assignDiffToObject(seriesProps, changedProperties)
          }
        }
      })
    }
    return value
  }

  handleSeriesFieldNameChanged(value, initialData, previousValue) {
    if (
      !previousValue ||
      !value.series ||
      !previousValue.series ||
      value.series.length !== previousValue.series.length
    ) {
      return value
    }
    _.forEach(value.series, (series, index) => {
      if (
        series.fieldName &&
        previousValue.series[index].fieldName !== series.fieldName &&
        series.seriesProps.name === previousValue.series[index].fieldName
      ) {
        series.seriesProps.name = series.fieldName
      }
    })
    return value
  }

  handleChartTypeChanged(value, initialData, previousValue) {
    const { chart: { type: newType = null } = {} } = value || {}
    const { chart: { type: prevType = null } = {} } = previousValue || {}

    if (newType != null && prevType != null && newType !== prevType) {
      const { series = [] } = value
      _.forEach(series, (series) => {
        if (series.seriesProps.type === prevType) {
          series.seriesProps.type = newType
        }
      })
    }
    return value
  }

  handleInitialization(value) {
    this.removeExtraAttributes(value, this.props.schema)
    return value
  }

  handleSynchronizeDataDefinition(fields, value, schemaHelper, initialConfig) {
    const newValue = _.cloneDeep(value)

    if (!newValue.series) {
      newValue.series = []
    }

    _.remove(newValue.series, (series) => {
      return !series.fieldName || series.fieldName === ''
    })

    initialConfig.series = []

    _.forEach(fields, (field) => {
      // Check if the field is alraedy used as a column
      const existingSeries = _.find(newValue.series, (series) => {
        return series.fieldName === field.fieldName
      })

      // Create a series if its field does not exist
      if (
        !existingSeries &&
        (field.dataType === 'int' ||
          field.dataType === 'double' ||
          field.dataType === 'float' ||
          field.dataType === 'decimal' ||
          field.dataType === 'long' ||
          field.dataType === 'short')
      ) {
        newValue.series.push({
          fieldName: field.fieldName,
          seriesProps: {
            name: field.fieldName
          }
        })
      }
    })

    return newValue
  }

  getCategories(chartConfig) {
    // Set values to x axis
    const { props: { pluginData = [], getFormattedValue } = {} } = this

    const { xAxis = [], xField: { fieldName: xFieldName = null, catGroupField = null } = {} } =
      chartConfig || {}

    if (xFieldName) {
      if (_.size(xAxis) <= 0) {
        xAxis.push({})
        xAxis[0].categories = []
      }

      const that = this

      _.forEach(xAxis, (axisItem) => {
        if (!('categories' in axisItem)) {
          axisItem.categories = []
        }

        axisItem.labels = {
          formatter() {
            let formatFieldName = xFieldName
            if (catGroupField) {
              if (this.parent) {
                formatFieldName = this.parent.groupFName
              } else if (typeof this.value === 'object') {
                if (catGroupField) {
                  if (this.value && this.value.parent && this.value.parent.tick) {
                    this.value.parent.tick.groupFName = catGroupField
                  }
                }
              }
            }
            const row = that.getRow(this.pos)
            if (typeof this.value === 'object' && !catGroupField) {
              return getFormattedValue(formatFieldName, this.value.name, null, row)
            }
            return getFormattedValue(formatFieldName, this.value, null, row)
          },
          ...(axisItem.labels ? axisItem.labels : {})
        }
        if (_.size(pluginData) > 0) {
          if (catGroupField) {
            const groupedPluginData = _.groupBy(pluginData, catGroupField)
            axisItem.categories = []
            _.forEach(groupedPluginData, (groupedCategories, categoryName) => {
              let groupedCategory = _.map(groupedCategories, (item, index) => {
                const categoryItem =
                  axisItem && axisItem.type === 'datetime'
                    ? new Date(item[xFieldName]).toString()
                    : _.isNil(item[xFieldName])
                    ? index.toString()
                    : typeof item[xFieldName] === 'string'
                    ? item[xFieldName]
                    : item[xFieldName].toString()
                return categoryItem
              })
              axisItem.categories.push({
                name: categoryName === 'null' ? '' : categoryName,
                categories: groupedCategory,
                groupFieldName: catGroupField
              })
              groupedCategory = []
            })
          } else {
            axisItem.categories = []

            _.forEach(pluginData, (rowOrig) => {
              const row = { ...rowOrig }
              if (!_.isNil(row[xFieldName])) {
                if (axisItem.type === 'datetime') {
                  const date = new Date(row[xFieldName])
                  axisItem.categories.push(date)
                } else {
                  axisItem.categories.push(row[xFieldName])
                }
              }
            })
          }
        }
      })
    }
  }

  handleLegendColor(chartConfig, series) {
    const { props: { pluginData = [] } = {} } = this
    const { xField: { fieldName: xFieldName = '' } = {} } = chartConfig || {}

    if (series.type === 'column' || series.type === 'bar') {
      const fieldName = series.colorField
      if (fieldName && pluginData.length > 0) {
        const color = pluginData[0][fieldName]
        if (color) {
          series.color = color
        }
      }
    } else if (series.type === 'waterfall') {
      const fieldName = series.pointColorField
      if (fieldName && pluginData.length > 0) {
        const color = pluginData[0][fieldName]
        if (color) {
          series.color = color
        }
      }
    } else if (series.type === 'scatter') {
      if (xFieldName.length != 0) {
        const fieldName = series.pointColorField
        if (fieldName && pluginData.length > 0) {
          const color = pluginData[0][fieldName]
          if (color) {
            series.color = color
          }
        }
      }
    }
  }
  isNumericType = (type) => {
    return (
      type === 'int' ||
      type === 'double' ||
      type === 'float' ||
      type === 'decimal' ||
      type === 'long' ||
      type === 'short'
    )
  }

  getFieldConfigs = () => {
    const {
      data: { schema, schema: fieldConfigsSchema = [] } = {},
      settings: { query: { fields: fieldConfigsQuery = [] } = {} } = {}
    } = this.props || {}

    if (schema) {
      return fieldConfigsSchema
    } else {
      return fieldConfigsQuery
    }
  }

  getMonochromeColors(chartConfig, color, brightenParameter) {
    if (color && color.length > 0) {
      chartConfig.colors = (function () {
        var colors = []

        var i

        for (i = 0; i < 10; i += 1) {
          colors.push(
            Highcharts.Color(color)
              .brighten((i - brightenParameter) / 9)
              .get()
          )
        }
        return colors
      })()
    }
  }

  applyColorSettings(chartConfig, series) {
    const {
      color = '',
      pattern = {},
      monochrome: { monochromeEnabled = false, brightenParameter = 1 } = {},
      colorField = ''
    } = series || {}
    const {
      patternEnabled = false,
      patternIndex = 1,
      backgroundColor = null,
      opacity = 1,
      strokeWidth = 2
    } = pattern || {}
    const { props: { pluginData = [] } = {} } = this
    if (series.type == 'pie' && monochromeEnabled) {
      this.getMonochromeColors(chartConfig, color, brightenParameter)
    } else {
      if (colorField && pluginData.length > 0) {
        const color = pluginData[0][colorField]
        if (color) {
          series.color = color
        }
      } else if (patternEnabled) {
        const defaultPattern = Highcharts.patterns[patternIndex - 1]
        const path = {
          d: defaultPattern.path,
          strokeWidth: strokeWidth
        }

        const pattern = { ...defaultPattern, path, opacity, color, backgroundColor }
        series.color = { pattern }
      } else {
        const serieColor = color === 'rgba(255,255,255,0)' ? '' : color
        series.color = serieColor
        if (monochromeEnabled) {
          this.getMonochromeColors(chartConfig, serieColor, brightenParameter)
        }
      }
    }
  }

  getData(chartConfig) {
    const { series = {}, xField = null } = chartConfig || {}

    if (xField) {
      this.getCategories(chartConfig)
    }
    this.getSeriesData(chartConfig, series)
  }

  getSeriesData(chartConfig, series) {
    const { props: { pluginData = [] } = {} } = this
    const {
      chart: { ignoreSeriesWithNullData = true } = {},
      xField: { catGroupField = null } = {}
    } = chartConfig || {}
    const that = this

    // Delete unused and non-numerical series
    const accumulatedMessage = []
    _.remove(series, function (series) {
      const { fieldName = null } = series || {}
      const yFieldConfig = _.find(that.getFieldConfigs(), { fieldName })
      const hasDifferentType = yFieldConfig && !that.isNumericType(yFieldConfig.dataType)
      if (hasDifferentType) {
        accumulatedMessage.push(fieldName)
      }
      return !series || hasDifferentType
    })
    if (accumulatedMessage.length > 0 && !this.isToastrMessage) {
      const message = accumulatedMessage.join(',') + ': please select numerical data field'
      slvyToast.warning({ message })
      this.isToastrMessage = true
    }
    // Initialize series
    _.forEach(series, (series) => {
      const { seriesProps: { yAxis = 0, xAxis = 0 } = {} } = series || {}
      series.data = []
      if (series.seriesProps) {
        _.assign(series, series.seriesProps)
      }
      delete series.seriesProps

      // Set showOnlyInTooltip
      if (series.showOnlyInTooltip) {
        series.visible = false
        series.showInLegend = false
      }

      if (typeof xAxis != 'number') {
        series.xAxis = _.toNumber(xAxis)
      }
      if (typeof yAxis != 'number') {
        series.yAxis = _.toNumber(yAxis)
      }

      this.applyColorSettings(chartConfig, series)
      this.handleLegendColor(chartConfig, series)
    })

    if (!_.isNil(catGroupField)) {
      const groupedPluginData = _.groupBy(pluginData, catGroupField)
      _.forEach(groupedPluginData, (groupedCategories) => {
        _.forEach(groupedCategories, (itemOrig) => {
          // We should get rows from the original one because it can effect the pluginData.
          const item = { ...itemOrig }
          that.getSeriesRowData(chartConfig, series, item)
        })
      })
    } else {
      _.forEach(pluginData, function (rowOrig) {
        // We should get rows from the original one because it can effect the pluginData.
        const row = { ...rowOrig }
        that.getSeriesRowData(chartConfig, series, row)
      })
    }

    // Remove series which have null data
    if (ignoreSeriesWithNullData) {
      _.remove(series, (serie) => {
        const { fieldName } = serie
        return _.reduce(
          pluginData,
          (result, row) => {
            if (_.has(row, fieldName) && row[fieldName] !== null) {
              result = false
            }
            return result
          },
          true
        )
      })
    }
  }

  getSeriesRowData(chartConfig, series, row) {
    const { xField: { fieldName: xFieldName = null } = {} } = chartConfig || {}
    // formatField gives the column name which includes specific format (e.g 0.0, %) for each rows in the series.
    // valueFormat keeps formats for each row.
    // If formatField column is exist but a row value is NULL,
    // it will take the base format
    // from the general Formatted Fields for this specific row.

    _.forEach(series, (series) => {
      const { type: seriesType = null, categoryField = null, fieldName = null } = series || {}
      if (seriesType === 'pie') {
        const { formatField, pattern: { patternEnabled, patternField = null } = {} } = series || {}

        if (_.has(row, fieldName)) {
          if (patternEnabled && patternField && _.has(row, patternField)) {
            const colorIndex = { patternIndex: row[patternField] }
            if (categoryField && _.has(row, categoryField)) {
              if (formatField) {
                series.data.push({
                  name: row[categoryField],
                  y: row[fieldName],
                  valueFormat: row[formatField],
                  row,
                  color: colorIndex
                })
              } else {
                series.data.push({
                  name: row[categoryField],
                  y: row[fieldName],
                  row,
                  color: colorIndex
                })
              }
            } else if (formatField) {
              series.data.push({
                y: row[fieldName],
                valueFormat: row[formatField],
                row,
                color: colorIndex
              })
            } else {
              series.data.push({ y: row[fieldName], row, color: colorIndex })
            }
          } else {
            if (categoryField && _.has(row, categoryField)) {
              if (formatField) {
                series.data.push({
                  name: row[categoryField],
                  y: row[fieldName],
                  valueFormat: row[formatField],
                  row
                })
              } else {
                series.data.push({
                  name: row[categoryField],
                  y: row[fieldName],
                  row
                })
              }
            } else if (formatField) {
              series.data.push({
                y: row[fieldName],
                valueFormat: row[formatField],
                row
              })
            } else {
              series.data.push({ y: row[fieldName], row })
            }
          }
        }
      } else if (seriesType === 'errorbar') {
        const { maxField: maxFieldName = null } = series || {}
        if (_.has(row, fieldName) && maxFieldName && _.has(row, maxFieldName)) {
          series.data.push([row[fieldName], row[maxFieldName]])
        }
      } else if (seriesType === 'scatter') {
        const { formatField } = series || {}
        const { pointColorField } = series || {}
        if (_.has(row, fieldName)) {
          if (xFieldName && _.has(row, xFieldName)) {
            if (formatField) {
              series.data.push({
                x: row[xFieldName],
                y: row[fieldName],
                valueFormat: row[formatField],
                row
              })
            } else if (pointColorField) {
              series.data.push({
                x: row[xFieldName],
                y: row[fieldName],
                color: row[pointColorField],
                row
              })
            } else {
              series.data.push({
                x: row[xFieldName],
                y: row[fieldName],
                row
              })
            }
          } else if (formatField) {
            series.data.push({
              y: row[fieldName],
              valueFormat: row[formatField],
              row
            })
          } else {
            // series.data.push(row[fieldName])
            series.data.push({ y: row[fieldName], row })
          }
        }

        let xAxis = 0
        if (_.has(series, 'xAxis')) {
          xAxis = series.xAxis
        }
        if (chartConfig.xAxis && chartConfig.xAxis[xAxis]) {
          chartConfig.xAxis[xAxis].categories = []
        }
      } else if (seriesType === 'waterfall') {
        const {
          sumField,
          intermediateSumField,
          fieldName,
          sumColor = '#434348',
          intermediateSumColor = '#434348',
          pointColorField,
          formatField
        } = series || {}

        if (sumField && _.has(row, sumField) && row[sumField]) {
          if (formatField) {
            series.data.push({
              isSum: true,
              color: sumColor,
              valueFormat: row[formatField],
              row
            })
          } else {
            series.data.push({ isSum: true, color: sumColor, row })
          }
        } else if (
          intermediateSumField &&
          _.has(row, intermediateSumField) &&
          row[intermediateSumField]
        ) {
          if (formatField) {
            series.data.push({
              isSum: true,
              color: intermediateSumColor,
              valueFormat: row[formatField],
              row
            })
          } else {
            series.data.push({ isSum: true, color: intermediateSumColor, row })
          }
        } else if (pointColorField && _.has(row, pointColorField) && row[pointColorField]) {
          if (formatField) {
            series.data.push({
              y: row[fieldName],
              color: row[pointColorField],
              valueFormat: row[formatField],
              row
            })
          } else {
            series.data.push({
              y: row[fieldName],
              color: row[pointColorField],
              row
            })
          }
        } else if (_.has(row, fieldName)) {
          if (formatField) {
            series.data.push({
              y: row[fieldName],
              valueFormat: row[formatField],
              row
            })
          } else {
            series.data.push({ y: row[fieldName], row })
          }
        }
      } else if (seriesType === 'column' || seriesType === 'bar') {
        const { fieldName, colorField, formatField } = series || {}
        if (colorField && _.has(row, colorField) && row[colorField]) {
          // colorField column should include color value
          // e.g. row[colorField]==='#0066ff'
          if (formatField) {
            series.data.push({
              y: row[fieldName],
              color: row[colorField],
              valueFormat: row[formatField],
              row
            })
          } else {
            series.data.push({
              y: row[fieldName],
              color: row[colorField],
              row
            })
          }
        } else if (_.has(row, fieldName)) {
          if (formatField) {
            series.data.push({
              y: row[fieldName],
              valueFormat: row[formatField],
              row
            })
          } else {
            series.data.push({ y: row[fieldName], row })
          }
        }
      } else if (seriesType === 'bubble') {
        const { formatField, zDataField = null } = series || {}

        if (_.has(row, fieldName)) {
          if (formatField) {
            if (zDataField) {
              series.data.push({
                y: row[fieldName],
                valueFormat: row[formatField],
                row,
                z: row[zDataField]
              })
            } else {
              series.data.push({
                y: row[fieldName],
                valueFormat: row[formatField],
                row
              })
            }
          } else if (zDataField) {
            series.data.push({
              y: row[fieldName],
              z: row[zDataField],
              row
            })
          } else {
            series.data.push({ y: row[fieldName], row })
          }
        } else {
          const { formatField } = series || {}
          if (_.has(row, fieldName)) {
            if (formatField) {
              if (zDataField) {
                series.data.push({
                  y: row[fieldName],
                  valueFormat: row[formatField],
                  row,
                  z: row[zDataField]
                })
              } else {
                series.data.push({
                  y: row[fieldName],
                  valueFormat: row[formatField],
                  row
                })
              }
            } else if (zDataField) {
              series.data.push({
                y: row[fieldName],
                z: row[zDataField],
                row
              })
            } else {
              series.data.push({ y: row[fieldName], row })
            }
          }
        }
      } else {
        const { formatField } = series || {}
        if (_.has(row, fieldName)) {
          if (formatField) {
            series.data.push({
              y: row[fieldName],
              valueFormat: row[formatField],
              row
            })
          } else {
            series.data.push({ y: row[fieldName], row })
          }
        }
      }
      // column series part is added for colorField option to change each rows' color seperately.
    })
  }

  getDynamicPlotBands(config) {
    _.forEach(config.xAxis, (xAxis) => {
      _.forEach(xAxis.plotBands, (plotBand) => {
        const { props: { pluginData = [] } = {} } = this
        const {
          bandField = null,
          bandFieldAsLabel = false,
          borderColor,
          borderWidth = 0,
          color,
          axisId,
          label = {},
          zIndex
        } = plotBand

        if (bandField) {
          let from = null
          let to = null
          let isBand = 0
          _.forEach(pluginData, (row, index) => {
            let drawPlotBand = false
            // bandValue should be true/false or 1/0
            const bandValue = Number(row[bandField])
            const bandValueChanged = isBand !== bandValue
            if (bandValue) {
              if (bandValueChanged) {
                // plotBand begin
                from = index - 0.5
                isBand = 1
              }
              to = index + 0.5
              if (pluginData.length - 1 === index) {
                // if last value is true, we should end plotBand
                drawPlotBand = true
              }
            } else if (bandValueChanged) {
              // plotBand end
              drawPlotBand = true
              isBand = 0
            }

            if (drawPlotBand && from !== null && to !== null) {
              xAxis.plotBands.push({
                borderColor,
                borderWidth,
                color,
                from,
                axisId,
                label: {
                  label,
                  ...(bandFieldAsLabel && { text: bandField })
                },
                to,
                zIndex
              })
            }
          })
          // first plotBand should be deleted created by configuration.
          plotBand.from = null
          plotBand.to = null
        }
      })
    })
  }

  getDynamicPlotLines(config) {
    _.forEach(config.xAxis, (xAxis) => {
      _.forEach(xAxis.plotLines, (plotLine) => {
        const {
          plotLineField = null,
          plotLineFieldAsLabel = false,
          width = 1,
          color = '#e6ebf5',
          dashStyle = 'Solid',
          label = {}
        } = plotLine
        plotLine.zIndex = 5
        if (plotLineField) {
          const {
            props: {
              settings: { config: { xField: { fieldName = '' } = {} } = {} } = {},
              pluginData = []
            } = {}
          } = this
          _.forEach(pluginData, (row, index) => {
            const lineValue = row[plotLineField]
            if (!_.isNil(lineValue)) {
              if (fieldName) {
                _.forEach(pluginData, (row, index) => {
                  if (row[fieldName] === lineValue) {
                    xAxis.plotLines.push({
                      color,
                      dashStyle,
                      value: index,
                      width,
                      label: {
                        label,
                        ...(plotLineFieldAsLabel && { text: lineValue })
                      }
                    })
                  }
                })
              } else {
                xAxis.plotLines.push({
                  color,
                  dashStyle,
                  value: lineValue,
                  width,
                  label: {
                    label,
                    ...(plotLineFieldAsLabel && { text: lineValue })
                  }
                })
              }
            }
          })
          plotLine.width = 0
        }
      })
    })

    _.forEach(config.yAxis, (yAxis) => {
      _.forEach(yAxis.plotLines, (plotLine) => {
        plotLine.zIndex = 5
        const { plotLineField = null } = plotLine
        if (plotLineField) {
          const firstRow = this.getFirstDataRow()
          if (_.has(firstRow, plotLineField)) {
            const lineValue = firstRow[plotLineField]
            if (!_.isNil(lineValue)) {
              plotLine.value = lineValue
            }
          }
        }
      })
    })
  }

  applyAxisFormatters(config) {
    const { formatValue } = this.props
    const { xAxis: xAxisList = [], yAxis: yAxisList = [], zAxis: zAxisList = [] } = config
    const that = this

    // Apply x axis formats
    _.forEach(xAxisList, (xAxis) => {
      const { labels = {}, labels: { style = {}, width } = {}, format = null } = xAxis
      if (labels && format) {
        labels.formatter = function () {
          return formatValue(format, this.value, that.getFirstDataRow())
        }
      }
      if (labels && width) {
        labels.style = { ...style, width, textOverflow: 'ellipsis' }
      }
      xAxis.labels = labels
    })

    // Apply y axis formats
    _.forEach(yAxisList, (yAxis) => {
      const { labels = {}, labels: { style = {}, width } = {}, format = null } = yAxis
      if (format) {
        labels.formatter = function () {
          return formatValue(format, this.value, that.getFirstDataRow())
        }
      }
      if (labels && width) {
        labels.style = { ...style, width, textOverflow: 'ellipsis' }
      }
      yAxis.labels = labels
    })

    // Apply z axis formats
    _.forEach(zAxisList, (zAxis) => {
      const { labels = {}, format = null } = zAxis
      if (format) {
        labels.formatter = function () {
          return formatValue(format, this.value, that.getFirstDataRow())
        }
        zAxis.labels = labels
      }
    })
  }
  dataLabelTemplate(text) {
    const regExp = /\{([^}]+)\}/g
    const matches = text.match(regExp)

    if (!matches) return { text }
    let variableName = ''
    for (let i = 0; i < matches.length; i++) {
      variableName = matches[i].substr(1, matches[i].length - 2)
    }

    return { variableName, matches }
  }

  applyLabelFormatters(config) {
    const { getFormattedValue } = this.props
    const { series: seriesList = [], xField: { fieldName: xFieldName = null } = {} } = config
    const that = this
    _.forEach(seriesList, (series) => {
      if (series.type !== 'pie') {
        const { dataLabels = {} } = series
        const { enabled = false, format: formatValue = '{y}' } = dataLabels
        if (enabled) {
          const dataLabelTemplate = that.dataLabelTemplate(formatValue)
          const { variableName, matches = [], text = null } = dataLabelTemplate
          if (matches.length == 0 && text) {
            return text
          }
          if (variableName != 'x' && variableName != 'y' && matches.length > 0) {
            return
          }
          delete dataLabels['format']
          dataLabels.formatter = function (value) {
            const { valueFormat = null } = this.point
            const row = this.point.row
            if (variableName === 'x') {
              let category = ''
              category = this.key
                ? typeof this.key === 'object'
                  ? this.key.name
                  : this.key
                : typeof this.x === 'object'
                ? this.x.name
                : this.x
              if (xFieldName) {
                category = getFormattedValue(xFieldName, category, null, row)
              }
              return formatValue.replace(matches[0], category)
            } else if (variableName === 'y') {
              if (valueFormat) {
                return getFormatedValue(valueFormat, this[variableName], row)
              }

              const formattedValue = getFormattedValue(
                series.fieldName,
                this[variableName],
                null,
                row
              )
              return formatValue.replace(matches[0], formattedValue)
            }
          }
          series.dataLabels = dataLabels
        }
      }
    })
  }

  applyYAxisMins(config) {
    const { series } = config
    _.map(series, (series) => {
      if (_.has(series, 'minField')) {
        const firstRow = this.getFirstDataRow()
        if (_.has(firstRow, series.minField)) {
          const { yAxis } = config
          _.forEach(yAxis, (yAxis) => {
            yAxis.min = firstRow[series.minField]
          })
        }
      }
    })
  }

  onPointClick(event) {
    const {
      props: { pluginData = [], settings: { config: { series = [], xField = {} } = {} } = {} } = {}
    } = this

    const {
      point: {
        series: {
          name: eventSeriesName = '',
          userOptions: { type: eventSeriesType = 'line' } = {}
        } = {},
        name: eventName,
        category: eventCategory,
        y: eventPointY = null,
        row: eventPointRow = {}
      } = {}
    } = event

    let referenceSeries
    let selectedRow = null
    const category = typeof eventCategory === 'object' ? eventCategory.name : eventCategory

    if (eventSeriesType === 'pie') {
      referenceSeries = _.find(series, (serie) => {
        return eventPointRow[serie.seriesProps.categoryField] === eventName
      })

      if (eventPointRow) {
        this.handlePointClick(eventPointRow)
      } else {
        // check pie chart
        // Pie chart use the selected pie category
        // Find the refrence series which the clientX belong to
        if (referenceSeries) {
          const categoryField = referenceSeries && referenceSeries.seriesProps.categoryField
          if (categoryField) {
            _.forEach(pluginData, (row) => {
              if (row[categoryField] === eventName) {
                selectedRow = row
                return false
              }
            })
            if (selectedRow) {
              this.handlePointClick(selectedRow)
            }
          }
        }
      }
    } else {
      referenceSeries = _.find(series, (serie) => {
        return serie.seriesProps.name === eventSeriesName
      })
      if (_.size(xField) > 0) {
        if (eventPointRow) {
          this.handlePointClick(eventPointRow)
        } else if (referenceSeries) {
          const categoryField = referenceSeries && referenceSeries.seriesProps.categoryField
          if (categoryField) {
            selectedRow = _.find(pluginData, (row) => {
              return row[categoryField] === eventPointY && row[xField.fieldName] === category
            })
            if (selectedRow) {
              this.handlePointClick(selectedRow)
            }
          }
        }
      } else {
        // TODO check y value
        // There is no xfield use highchart enumeration
        if (pluginData.length >= category + 1) {
          this.handlePointClick(pluginData[category])
        }
      }
    }
  }

  onSeriesHide(e) {
    const { chart: { series = [] } = {} } = this.highchartRef

    this.applyYAxisVisibility()

    const seriesState = _.map(series, (series) => {
      return {
        name: series.name,
        visible: series.options.showOnlyInTooltip ? false : series.visible
      }
    })

    const newState = {
      series: seriesState
    }

    this.pluginState = newState
    this.savePluginstate(newState)
  }

  onSeriesShow(e) {
    const { chart: { series = [] } = {} } = this.highchartRef

    this.applyYAxisVisibility()

    const seriesState = _.map(series, (series) => {
      return {
        name: series.name,
        visible: series.options.showOnlyInTooltip ? false : series.visible
      }
    })

    const newState = {
      series: seriesState
    }
    this.pluginState = newState
    this.savePluginstate(newState)
  }

  defaults = {
    credits: {
      enabled: false,
      text: `Solvoyo © ${new Date().getFullYear()}`,
      href: '#',
      style: { fontSize: '12px' }
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    }
  }

  getSeriesName(series) {
    // Find series config through fieldName and not through series name
    // Since series name can be a template.
    const { userOptions: { fieldName: seriesFieldName = '' } = {} } = series
    return seriesFieldName || series.name
  }

  gridTooltipFormatter(e) {
    const { props: { settings: { config: chartConfig = {} } = {}, getFormattedValue } = {} } = this
    const { series: { fieldName } = [] } = chartConfig
    const xFieldName = chartConfig.xField && fieldName
    let xValue = e.x
    if (xFieldName) {
      // TODO
      // console.log('gridTooltipFormatter', e)
      // const row = that.getRow(this.pos)
      xValue = getFormattedValue(xFieldName, e.x)
    }

    let tooltip = `<table class="tg"><tr><th class="tg-yw4l" colspan="2">${xValue}</th></tr>`

    let pointIndex = 0
    if (e.points) {
      // destructuring for e.points[0]
      const {
        points: [
          {
            series: { name: referenceSeriesName, chart: { series: chartSeries = [] } = {} } = {},
            point: { clientX } = {}
          } = {}
        ] = []
      } = e

      // Find the refrence series which the clientX belong to
      const referenceSeries = _.find(chartSeries, {
        name: referenceSeriesName
      })

      if (referenceSeries) {
        _.each(referenceSeries.points, (point, index) => {
          const { clientX: pointClientX } = point
          if (pointClientX === clientX) {
            pointIndex = index
            return false
          }
        })
      }
      _.each(chartSeries, (series) => {
        const {
          visible = true,
          options: { showOnlyInTooltip = false, showOnlyInChart = false } = {}
        } = series
        if ((visible || showOnlyInTooltip) && !showOnlyInChart) {
          const { points: seriesPoints } = series
          _.each(seriesPoints, (point, index) => {
            if (index === pointIndex) {
              const seriesName = this.getSeriesName(series)
              const { valueFormat = null, y } = point
              let yValue
              if (valueFormat) {
                yValue = getFormatedValue(valueFormat, y)
              } else {
                yValue = this.getFormattedSeriesValue(seriesName, y, chartConfig)
              }
              if (yValue !== null) {
                tooltip += `<tr><td class="tg-yw4l" style="min-width:50px">${series.name}: </td><td class="tg-yw42">${yValue}</td></tr>`
              }
              return true
            }
          })
        }
      })
    }
    // Single point
    else {
      const {
        point: {
          clientX,
          series: { name: refSeriesName, chart: { series: chartSeries = [] } = {} } = {}
        } = {}
      } = e
      const refSeries = _.find(chartSeries, {
        name: refSeriesName
      })

      if (refSeries) {
        const { points: refPoints = [] } = refSeries

        _.each(refPoints, (point, index) => {
          const { clientX: pointClientX } = point
          if (pointClientX === clientX) {
            pointIndex = index
            return false
          }
        })
      }

      _.each(chartSeries, (series) => {
        const {
          options: { showOnlyInTooltip, showOnlyInChart } = {},
          points = [],
          visible,
          name
        } = series
        if ((visible || showOnlyInTooltip) && !showOnlyInChart) {
          _.each(points, (point, index) => {
            if (index === pointIndex) {
              const seriesName = this.getSeriesName(series)
              const { valueFormat = null, y } = point
              let yValue
              if (valueFormat) {
                yValue = getFormatedValue(valueFormat, y)
              } else {
                yValue = this.getFormattedSeriesValue(seriesName, y, chartConfig)
              }
              if (yValue !== null) {
                tooltip += `<tr><td class="tg-yw4l" style="min-width:50px">${name}: </td><td class="tg-yw42">${yValue}</td></tr>`
              }
              return true
            }
          })
        }
      })
    }

    tooltip += '</table>'

    return tooltip
  }

  tooltipFormatter(point) {
    const { props: { settings: { config: chartConfig = {} } = {}, getFormattedValue } = {} } = this
    const { xField: { fieldName: xFieldName = null, catGroupField = null } = {} } = chartConfig
    let category = ''
    if (catGroupField && typeof point === 'object') {
      category = (point.key && point.key.name) || (point.x && point.x.name)
    } else {
      category = point.key
        ? typeof point.key === 'object'
          ? point.key.name
          : point.key
        : typeof point.x === 'object'
        ? point.x.name
        : point.x
    }

    if (xFieldName) {
      const { point: { row } = {} } = point || {}
      category = getFormattedValue(xFieldName, category, null, row)
    }

    let tooltip = `<span style="font-size: 120%">${category}</span><br/>`
    if (point.points) {
      // shared tooltip
      let pointIndex = 0
      // destructuring for point.points[0]
      const {
        points: [
          {
            series: { name: referenceSeriesName, chart: { series: chartSeries = [] } = {} } = {},
            point: { clientX } = {}
          } = {}
        ] = []
      } = point

      // Find the refrence series which the clientX belog to
      const referenceSeries = _.find(chartSeries, {
        name: referenceSeriesName
      })

      if (referenceSeries) {
        _.each(referenceSeries.points, (point, index) => {
          if (point.clientX === clientX) {
            pointIndex = index
            return false
          }
        })
      }

      let selectedRow
      _.each(chartSeries, (series) => {
        if (series.visible || series.options.showOnlyInTooltip) {
          _.each(series.points, (p, index) => {
            if (index === pointIndex) {
              selectedRow = p.row
              return false
            }
          })
        }
      })

      if (selectedRow) {
        tooltip = this.formatHeader(chartConfig, selectedRow, category)
      }
      _.each(chartSeries, (series) => {
        const {
          visible = true,
          options: { showOnlyInTooltip = false, showOnlyInChart = false } = {}
        } = series
        const seriesName = this.getSeriesName(series)
        if ((visible || showOnlyInTooltip) && !showOnlyInChart) {
          _.each(series.points, (p, index) => {
            if (index === pointIndex) {
              const seriesDescription = series.name
              if (_.has(p, 'low') && _.has(p, 'high')) {
                const { low, high, color, row } = p
                tooltip += this.formatErrorRangeText(seriesName, low, high, color, chartConfig, row)
              } else {
                const { point: { valueFormat = null } = {}, y, color, row = {}, z = null } = p
                if (y !== null) {
                  tooltip += this.formatPointValue({
                    name: seriesName,
                    description: seriesDescription,
                    value: y,
                    color,
                    chartConfig,
                    valueFormat,
                    row,
                    z
                  })
                }
              }
            }
          })
        }
      })
    } else {
      // Single point
      const {
        point: { valueFormat = null, row = {} } = {},
        series = [],
        y,
        color,
        z = null
      } = point
      const seriesName = this.getSeriesName(series)
      const seriesDescription = series.name
      const { options: { showOnlyInChart } = {} } = series
      if (!showOnlyInChart) {
        const tooltipHtml = this.state?.tooltipHtml || ''
        tooltip = tooltipHtml
          ? this.replaceTemplate(tooltipHtml, row)
          : this.formatHeader(chartConfig, row, category)
        if (y !== null) {
          tooltip += this.formatPointValue({
            name: seriesName,
            description: seriesDescription,
            value: y,
            color,
            chartConfig,
            valueFormat,
            row,
            z
          })
        }
      }
    }
    return tooltip
  }

  formatHeader(chartConfig, row, xValue) {
    const { getFormattedValue } = this.props
    const { tooltip: { headerFormat } = {} } = chartConfig
    let tooltip = `<span style="font-size: 120%">${xValue}</span><br/>`
    if (headerFormat) {
      tooltip = this.unescapeUnicode(headerFormat)
      if (row) {
        const formattedRow = _.transform(
          row,
          (res, value, key) => {
            res[key] = getFormattedValue(key, value, null, row)
          },
          {}
        )
        tooltip = this.replaceTemplate(tooltip, formattedRow)
      }
    }
    return tooltip
  }

  formatPointValue({ name, description, value, color, chartConfig, valueFormat, row = null, z }) {
    const { getFormattedValue } = this.props
    let formattedValue
    if (valueFormat) {
      formattedValue = getFormatedValue(valueFormat, value, row)
    } else {
      formattedValue = this.getFormattedSeriesValue(name, value, chartConfig, row)
    }
    const series = _.find(chartConfig.series, (series) => series.fieldName === name)
    const { seriesProps: { tooltip: { pointFormat = null } = {} } = {} } = series || {}
    let tooltip = `<span style="color:${color}">\u25CF</span> ${description}: <b>${formattedValue}</b> `
    tooltip += _.isNil(z) ? `<br/>` : `Size: <b>${z}</b><br/>`

    if (pointFormat) {
      tooltip = this.unescapeUnicode(pointFormat)
    }
    tooltip = _.replace(tooltip, '{point.color}', color)
    tooltip = _.replace(tooltip, '{series.color}', color)
    tooltip = _.replace(tooltip, '{series.name}', description)
    tooltip = _.replace(tooltip, '{point.y}', formattedValue)
    if (!_.isNil(z)) {
      tooltip = _.replace(tooltip, '{point.z}', z)
    }

    if (row) {
      const formattedRow = _.transform(
        row,
        (res, value, key) => {
          res[key] = getFormattedValue(key, value, null, row)
        },
        {}
      )

      tooltip = this.replaceTemplate(tooltip, formattedRow)
    }
    return tooltip
  }

  unescapeUnicode(str) {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function (g, m1) {
      return String.fromCharCode(parseInt(m1, 16))
    })
  }

  formatErrorRangeText(name, lowValue, highValue, color, chartConfig, row) {
    const lowFormattedValue = this.getFormattedSeriesValue(name, lowValue, chartConfig, row)
    const highFormattedValue = this.getFormattedSeriesValue(name, highValue, chartConfig, row)

    const series = _.find(chartConfig.series, (series) => series.fieldName === name)

    const { seriesProps: { tooltip: { pointFormat = null } = {} } = {} } = series || {}
    let tooltip = `<span style="color:{point.color}">●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b><br/>`

    if (pointFormat) {
      tooltip = this.unescapeUnicode(pointFormat)
    }

    tooltip = _.replace(tooltip, '{point.low}', lowFormattedValue)
    tooltip = _.replace(tooltip, '{point.high}', highFormattedValue)
    tooltip = _.replace(tooltip, '{point.color}', color)
    tooltip = _.replace(tooltip, '{series.name}', name)

    return tooltip
  }

  getFormattedSeriesValue(name, value, chartConfig, row) {
    const { getFormattedValue } = this.props
    // Get field name from series name
    const series = _.find(chartConfig.series, (series) => series.fieldName === name)

    const { fieldName = null } = series || {}
    return fieldName ? getFormattedValue(series.fieldName, value, null, row) : value
  }

  removeEmptyConfig(config) {
    for (const prop in config) {
      if (!config.hasOwnProperty(prop)) continue

      if (config[prop] === '') {
        delete config[prop]
      } else if (typeof config[prop] === 'object') {
        this.removeEmptyConfig(config[prop])
      }
    }
  }

  savePluginstate(pluginState) {
    const { props: { id = '', pluginStates, params: { catalogId } = {}, client } = {} } = this
    const clientUrl = '/pluginstateupsert'
    const clientData = {
      name: `chart state ${id}`,
      pluginId: id,
      catalogId,
      config: {
        state: pluginState,
        stateId: 1
      }
    }
    return client.post(clientUrl, { data: clientData }).then(
      function () {
        this.props.dispatch(pluginStates.fetch)
      }.bind(this)
    )
  }

  getAxisInfo() {
    const { chart: { yAxis = [] } = {} } = this.highchartRef

    return _.map(yAxis, (yAxis) => {
      const { series = [] } = yAxis

      const visibleSeriesCount = _.size(_.filter(series, (series) => series.visible))

      return {
        yAxis,
        visibleSeriesCount
      }
    })
  }

  applyYAxisVisibility() {
    const yAxisInfo = this.getAxisInfo()

    _.forEach(yAxisInfo, (yAxisInfo) => {
      yAxisInfo.visibleSeriesCount === 0
        ? yAxisInfo.yAxis.update({
            visible: false
          })
        : yAxisInfo.yAxis.update({
            visible: true
          })
    })
  }

  // Hide unused YAxis
  hideUnusedYAxis(config) {
    const usedYAxis = []

    // get used YAxis
    _.forEach(config.series, (series) => {
      const { visible = true, yAxis = 0 } = series
      if (visible) {
        usedYAxis.push(yAxis)
      }
    })

    const uniqUsedYAxis = _.uniq(usedYAxis)

    // filter unused YAxis
    const unUsedYAxis = _.filter(config.yAxis, (axis) => {
      return _.isNil(
        _.find(uniqUsedYAxis, (uniqUsedYAxis) => {
          return _.toString(uniqUsedYAxis) === _.toString(axis.axisId)
        })
      )
    })

    // Hide unsused YAxis
    _.forEach(unUsedYAxis, (unUsedYAxis) => {
      unUsedYAxis.visible = false
    })
  }

  getLoadedPluginStates() {
    const { props: { pluginStates: { isSuccess, data = [] } = {} } = {} } = this
    if (isSuccess && data.length > 0) {
      return data[0].config.state
    }
  }

  replaceTemplate(text, data) {
    const regExp = /\{([^}]+)\}/g

    const matches = text.match(regExp)

    if (!matches) return text

    for (let i = 0; i < matches.length; i++) {
      const variableName = matches[i].substr(1, matches[i].length - 2)

      if (_.has(data, variableName)) {
        const value = data[variableName]

        if (value) {
          text = text.replace(matches[i], value)
        } else {
          text = text.replace(matches[i], '')
        }
      } else {
        text = text.replace(matches[i], '')
      }
    }

    return text
  }

  getFirstDataRow() {
    return this.props.pluginData && this.props.pluginData[0]
  }

  applyTitleTemplates(config) {
    // override title
    if (this.state.title) {
      if (!config.title) {
        config.title = {}
      }
      config.title.text = this.state.title
    }

    // apply templates to title
    if (config.title && config.title.text) {
      config.title.text = this.replaceTemplate(config.title.text, this.getFirstDataRow())
    }

    const { xAxis = [], yAxis = [] } = config

    _.forEach(xAxis, (xAxis) => {
      if (xAxis.title && xAxis.title.text) {
        xAxis.title.text = this.replaceTemplate(xAxis.title.text, this.getFirstDataRow())
      }
    })

    _.forEach(yAxis, (yAxis) => {
      if (yAxis.title && yAxis.title.text) {
        yAxis.title.text = this.replaceTemplate(yAxis.title.text, this.getFirstDataRow())
      }
    })
    // if the plugin is maximized apply max style for title
    if (this.props.isMaximized && config.title && config.title.maxStyle) {
      config.title.style = config.title.maxStyle
    }
  }

  applySubtitleTemplates(config) {
    // override subtitle
    if (this.state.subtitle) {
      if (!config.subtitle) {
        config.subtitle = {}
      }
      config.subtitle.text = this.state.subtitle
    }

    // apply templates to title
    if (config.subtitle && config.subtitle.text) {
      config.subtitle.text = this.replaceTemplate(config.subtitle.text, this.getFirstDataRow())
    }
  }

  applySeriesNameTemplates(config) {
    _.forEach(config.series, (series) => {
      if (series.name) {
        series.name = this.replaceTemplate(series.name, this.getFirstDataRow())
      }
    })
  }

  removeExtraAttributes(config, schema) {
    const { series = [] } = config
    const {
      properties: {
        series: {
          items: { properties: { seriesProps: { oneOf: seriesSchemas = [] } = {} } = {} } = {}
        } = {}
      } = {}
    } = schema || {}
    _.forEach(series, (series) => {
      const { seriesProps = {} } = series
      const { dataLabels = {} } = seriesProps
      const seriesSchema = _.find(seriesSchemas, (seriesSchema) => {
        return seriesSchema.properties.type.enum[0] === seriesProps.type
      })
      const dataLabelsSchema = seriesSchema.properties.dataLabels

      seriesProps.dataLabels = _.pick(dataLabels, _.keys(dataLabelsSchema.properties))
      series.seriesProps = _.pick(seriesProps, _.keys(seriesSchema.properties))
    })
  }

  alignYAxisZeros(config) {
    const { yAxis: sourceAxis = [], series = [], chart: { alignYAxisZero = false } = {} } = config
    if (!alignYAxisZero) {
      return
    }

    const yAxisList = _.map(sourceAxis, (yAxis) => {
      // TODO delete yAxis min max from schema config and mongoDB to prevent default 0 values.
      return {
        axisId: yAxis.axisId,
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MIN_SAFE_INTEGER
      }
    })

    _.forEach(series, (serie) => {
      const { yAxis = 0, data = [], showOnlyInTooltip = false } = serie
      const yAxisOfSeries = _.find(yAxisList, { axisId: _.toString(yAxis) })
      if (yAxisOfSeries && !showOnlyInTooltip && _.has(_.first(data), 'y')) {
        const { y: maxSeriesValue } = _.maxBy(data, 'y')
        const { y: minSeriesValue } = _.minBy(data, 'y')

        if (minSeriesValue < yAxisOfSeries.min) {
          yAxisOfSeries.min = minSeriesValue
        }

        if (maxSeriesValue > yAxisOfSeries.max) {
          yAxisOfSeries.max = maxSeriesValue
        }
      }
    })

    _.forEach(yAxisList, (yAxis) => {
      const { scaledMin, scaledMax } = this.interpolateToMax(yAxis.min, yAxis.max)
      yAxis.scaledMin = scaledMin
      yAxis.scaledMax = scaledMax
    })

    const absScaledMin = _.minBy(yAxisList, (yAxis) => {
      return yAxis.scaledMin
    }).scaledMin

    const absScaledMax = _.maxBy(yAxisList, (yAxis) => {
      return yAxis.scaledMax
    }).scaledMax

    _.forEach(yAxisList, (yAxis) => {
      const unitChange =
        yAxis.scaledMax === yAxis.scaledMin
          ? 0
          : (yAxis.max - yAxis.min) / (yAxis.scaledMax - yAxis.scaledMin)
      // We should round the number or we get some negative numbers which are very close to zero
      // Causing negative steps in the y-axis
      yAxis.newMax = _.round(yAxis.max + (absScaledMax - yAxis.scaledMax) * unitChange, 4)
      yAxis.newMin = _.round(yAxis.min + (absScaledMin - yAxis.scaledMin) * unitChange, 4)
    })

    _.forEach(yAxisList, (yAxis) => {
      const sourceAxisItem = _.find(sourceAxis, (item) => {
        return _.toString(item.axisId) === yAxis.axisId
      })
      if (sourceAxisItem) {
        sourceAxisItem.min = yAxis.newMin
        sourceAxisItem.max = yAxis.newMax
      }
    })
  }

  interpolateToMax(min, max) {
    const absMax = 100
    const absMin = -100

    const distanceMaxtoToZero = max - 0
    const distanceMintoToZero = 0 - min

    const interpolationFactor =
      distanceMaxtoToZero >= distanceMintoToZero
        ? max === 0
          ? absMax
          : absMax / max
        : min === 0
        ? absMin
        : absMin / min

    return {
      scaledMin: min * interpolationFactor,
      scaledMax: max * interpolationFactor
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // The component updates only by one of these cases
    // 1- grid configuration has changed
    // 2- pluginState has changed
    // 3- plugin size has changed
    // 4- plugin data  has changed
    let nextPluginState = {}
    let pluginStateOwner = ''

    const {
      settings: { config: nextConfig } = {},
      size: { width: nextWidth, height: nextHeight } = {},
      pluginStates: { isSuccess } = {},
      pluginData: nextPluginData
    } = nextProps
    const {
      props: { settings: { config } = {}, size: { width, height } = {}, id, pluginData } = {}
    } = this
    const { title: nextTitle, subtitle: nextSubTitle } = nextState
    const { title, subtitle } = this.state

    if (isSuccess) {
      const { pluginStates: { data = [] } = {} } = nextProps
      if (data.length > 0) {
        nextPluginState = data[0].config.state
        pluginStateOwner = data[0].id
      }
    }
    const isConfigNotEqual = !_.isEqual(config, nextConfig)
    if (isConfigNotEqual) {
      this.isToastrMessage = false
    }
    if (
      isConfigNotEqual ||
      (isSuccess &&
        pluginStateOwner === id &&
        JSON.stringify(nextPluginState) !== JSON.stringify(this.pluginState)) ||
      nextWidth !== width ||
      nextHeight !== height ||
      !_.isEqual(pluginData, nextPluginData) ||
      title !== nextTitle ||
      subtitle !== nextSubTitle
    ) {
      return true
    }

    return false
  }

  componentDidMount() {
    // Trigger reading plugin states
    if (this.props.pluginStates.needFetch) {
      this.props.dispatch(this.props.pluginStates.fetch)
    }

    this.handlePointClick = this.props.registerEvent({
      key: 'handlePointClick',
      fn: this.handlePointClick.bind(this),
      returnTypes: _.transform(
        this.props.settings.query.fields,
        (result, field = {}) => {
          const { dataType = '' } = field
          result[field.fieldName] = PluginTypes.fromString(dataType)
        },
        {}
      )
    })

    this.props.registerMethod({
      key: 'setTitle',
      fn: this.setTitle.bind(this),
      args: [{ name: 'title', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setSubtitle',
      fn: this.setSubtitle.bind(this),
      args: [{ name: 'subtitle', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setTooltipHtml',
      fn: this.setTooltipHtml.bind(this),
      args: [{ name: 'tooltipHtml', type: PluginTypes.string }]
    })

    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [
          this.handleChartTypeChanged,
          this.handleDataSeriesChanged,
          this.handleAxisChanged,
          // this.handleInitializeSeries,
          this.handleSeriesFieldNameChanged,
          this.handleDataSeriesTypeChanged
        ],
        onSynchronizeDataDefinition: this.handleSynchronizeDataDefinition,
        diffMode: true
        // initializer: this.handleInitialization
      })
    }
  }

  applyXFieldImages = (chart) => {
    const {
      props: { id = '', pluginData = [], settings: { config: chartConfig = {} } = {} } = {}
    } = this

    const imageField = chartConfig.xField && chartConfig.xField.imageField
    if (imageField) {
      const {
        renderer = () => {},
        plotBox: { x: plotBoxX = 0, y: plotBoxY = 0, width: plotBoxWidth = 300 } = {},
        series = []
      } = chart || {}
      const pluginDataLength =
        chart &&
        chart.xAxis &&
        chart.xAxis[0] &&
        chart.xAxis[0].tickPositions &&
        chart.xAxis[0].tickPositions.length // _.isNil(pluginData) ? 0 : pluginData.length
      const isSerieVisible =
        _.find(series, (serie) => {
          return serie.options.showOnlyInTooltip ? false : serie.visible
        }) || 0
      if (chart && isSerieVisible && pluginDataLength > 0) {
        const imageElements = document.querySelectorAll(`image[id^=images_${id}_]`)

        if (imageElements.length > 0) {
          imageElements.forEach((e) => e.parentNode.removeChild(e))
        }

        if (chart.xAxis && chart.xAxis[0] && chart.xAxis[0].ticks) {
          _.forEach(chart.xAxis[0].ticks, (tick, index) => {
            if (index !== '-1' && !_.isNil(tick.pos)) {
              const xFieldUnit = plotBoxWidth / pluginDataLength
              const imgSize = xFieldUnit * 0.42 <= 30 ? xFieldUnit * 0.42 : 30
              const imgSpaceY = plotBoxY <= imgSize ? 5 : plotBoxY - imgSize
              const labelXPos = (tick.label && tick.label.xy && tick.label.xy.x) || plotBoxX
              const rowImgUrl = (pluginData[tick.pos] && pluginData[tick.pos][imageField]) || ''

              const image = renderer.image(
                rowImgUrl,
                labelXPos - imgSize / 2,
                imgSpaceY,
                imgSize,
                imgSize
              )

              image.attr('id', `images_${id}_${index}`)
              image.add()
            }
          })
        }
      }
    }
  }

  applyScrollbar = (event, pluginConfig) => {
    const { width, height, target } = event
    const { chart: { scrollbar = {} } = {} } = pluginConfig

    const isZoomActive = height || width
    const axisScrollbar = isZoomActive
      ? { enabled: true, showFull: false, ...scrollbar }
      : { enabled: false }

    target?.xAxis?.[0]?.update?.({ scrollbar: axisScrollbar })
    target?.yAxis?.[0]?.update?.({ scrollbar: axisScrollbar })
  }

  updateAxisIds(config) {
    const updateKey = (axisDirection) => {
      _.forEach(axisDirection, (axisKey) => {
        if (axisKey.id) {
          axisKey.axisId = axisKey.id
          delete axisKey.id
        }
      })
    }

    // Ex: 'config.xAxis[0].id' will be 'config.xAxis[0].axisId'
    // usage of 'id' gives error on highchart version 10.1.0
    if (config.xAxis && config.xAxis.length) updateKey(config.xAxis)
    if (config.yAxis && config.yAxis.length) updateKey(config.yAxis)
    if (config.zAxis && config.zAxis.length) updateKey(config.zAxis)
  }

  componentWillUnmount() {
    this.highchartRef = null
  }

  syncExtremes(e) {
    var thisChart = this.chart

    if (e.trigger !== 'syncExtremes') {
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart && chart !== thisChart) {
          _.forEach(chart.xAxis, (axis) => {
            if (axis && axis.setExtremes) {
              axis.setExtremes(e.min, e.max, undefined, false, {
                trigger: 'syncExtremes'
              })
            }
          })
        }
      })
    }
  }

  syncChartsConfig(config, item, isFirstChart) {
    const { tooltip = {}, xAxis = [], yAxis = [], title = {} } = config || {}
    const { backgroundColor = 'none', borderWidth = 0, shadow = false } = tooltip || {}

    const updatedXAxis = xAxis.map((axis) => {
      return {
        ...axis,
        ...(isFirstChart ? {} : { labels: { enabled: false } }),
        crosshair: { width: 1, color: '#cccccc' },
        events: {
          setExtremes: this.syncExtremes
        }
      }
    })

    const yAxisId = item.yAxis
    const yAxisList = _.cloneDeep(yAxis)

    const yAxisSettings = _.map(yAxisList, (yAxis) => {
      if (yAxis.axisId === _.toString(yAxisId)) {
        yAxis.visible = true
      } else {
        yAxis.visible = false
      }
      return yAxis
    })

    const syncChartsConfig = {
      ...config,
      title: {
        ...title,
        text: item.name
      },
      series: [item],
      tooltip: {
        ...tooltip,
        positioner: function () {
          return {
            x: this.chart.chartWidth - this.label.width,
            y: 10
          }
        },
        borderWidth,
        backgroundColor,
        shadow
      },
      xAxis: updatedXAxis,
      yAxis: yAxisSettings
    }

    return syncChartsConfig
  }

  handleMouseMove = (e) => {
    Highcharts.Pointer.prototype.reset = () => {}

    Highcharts.Point.prototype.highlight = function (event) {
      this.onMouseOver()
      this.series.chart.tooltip.refresh(this)
      this.series.chart.xAxis[0].drawCrosshair(event, this)
    }

    let point = null
    let event = null

    e.persist()
    Highcharts.charts.forEach((chart) => {
      if (!chart) return
      event = chart.pointer.normalize(e)
      point = chart.series[0].searchPoint(event, true)
      if (point) {
        point.highlight(e)
      }
    })
  }

  renderSyncCharts(config) {
    const { series = [] } = config || {}
    return (
      <div
        className="highcharts-container synchronized-chart"
        onMouseMove={this.handleMouseMove}
        onTouchMove={this.handleMouseMove}
      >
        {_.map(series, (item, index) => (
          <HighchartsReact
            ref={(el) => {
              this.highchartRef = el
            }}
            key={index}
            highcharts={Highcharts}
            options={this.syncChartsConfig(config, item, index === 0)}
            immutable={true}
          />
        ))}
      </div>
    )
  }

  render() {
    const { props: { settings: { config: pluginConfig = {} } = {} } = {} } = this || {}

    const config = _.cloneDeep(pluginConfig)

    if (!_.isNil(pluginConfig.chart?.hoverOpacity) && !pluginConfig.chart?.hoverOpacity)
      _.set(config, 'plotOptions.series.states.inactive.opacity', 1)

    if (this.props.settings.config) {
      this.getData(config)
    }
    // Apply loaded plugin states
    const pluginState = this.getLoadedPluginStates()
    if (pluginState && pluginState.series) {
      _.forEach(config.series, (series) => {
        const seriesState = _.find(pluginState.series, { name: series.name })
        if (seriesState) {
          series.visible = seriesState.visible
        }
      })
    }

    this.updateAxisIds(config)
    this.hideUnusedYAxis(config)

    const pointClick = {
      events: {
        click: this.onPointClick
      }
    }

    // Add click handlers
    _.map(config.series, (series) => {
      series.point = pointClick
      series.events = {
        hide: this.onSeriesHide,
        show: this.onSeriesShow
      }
      return series
    })

    this.applyLabelFormatters(config)

    // TODO remove somewhere else
    delete config.datafields
    delete config.xField

    // Display tooltip in a grid
    if (!config.tooltip) {
      config.tooltip = {}
    }

    const that = this
    if (config.tooltip.showInGrid) {
      config.tooltip.formatter = function () {
        return that.gridTooltipFormatter(this)
      }
      config.tooltip.shared = true
      config.tooltip.useHTML = true
      config.tooltip.crosshairs = true
    } else {
      config.tooltip.formatter = function () {
        return that.tooltipFormatter(this)
      }
    }

    this.applyAxisFormatters(config)

    this.getDynamicPlotBands(config)

    this.getDynamicPlotLines(config)

    this.applySeriesNameTemplates(config)

    this.applyTitleTemplates(config)

    this.applySubtitleTemplates(config)

    this.alignYAxisZeros(config)

    this.applyYAxisMins(config)

    const {
      chart: {
        showCredits = false,
        emptyText = 'No Data to Display',
        resetZoomButton: { theme: { zIndex = 7 } = {} } = {},
        synchronized = false
      } = {},
      title: { style: { color: titleColor } = {} } = {},
      general: { showNoDataToDisplay = false } = {},
      exporting: { enabled = false } = {}
    } = pluginConfig

    config.credits = this.defaults.credits
    config.credits.enabled = showCredits
    config.noData = this.defaults.noData

    if (titleColor) {
      config.noData.style.color = titleColor
    }
    // set empty text
    config.lang = {
      noData: showNoDataToDisplay ? emptyText : ' '
    }

    // move export button to the top-left corner
    if (!config.exporting) {
      config.exporting = {}
    }
    config.exporting.buttons = {
      contextButton: {
        align: 'left'
      }
    }
    config.exporting.enabled = enabled

    // Delete empty ('') properties
    this.removeEmptyConfig(config)

    const defaultValueConfig = {
      chart: {
        events: {
          render: ({ target: chart = {} }) => this.applyXFieldImages(chart),
          selection: (event) => this.applyScrollbar(event, pluginConfig)
        },
        resetZoomButton: {
          theme: {
            zIndex
          }
        }
      }
    }

    _.merge(config, defaultValueConfig)

    return (
      <div className="highcharts-container">
        {!synchronized ? (
          <HighchartsReact
            ref={(el) => {
              this.highchartRef = el
            }}
            highcharts={Highcharts}
            options={config}
            immutable={true}
          />
        ) : (
          this.renderSyncCharts(config)
        )}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  schema: props.schema,
  data: props.data,
  id: props.id,
  params: props.params,
  client: props.client,
  isMaximized: props.isMaximized,
  size: props.size,
  onReady: props.onReady,
  formatValue: props.formatValue,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(
  connect((state, ownProps) => {
    return {
      pluginStates: selectCollection(getPluginStates(ownProps.id), state.model3)
    }
  })(Highchart),
  selectConnectorProps
)

export const Self = Highchart
