import React, { Component } from 'react'
import update from 'immutability-helper'
import _ from 'lodash'
import { DropTarget } from 'react-dnd'
import Card from './card'

class Container extends Component {
  constructor(props) {
    super(props)
    this.state = { cards: props.list }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(this.props.list, nextProps.list) &&
      !_.isEqual(this.state.cards, nextProps.list)
    ) {
      this.setState({ cards: nextProps.list })
    }
  }

  pushCard(card) {
    const { context } = this.props

    this.setState(
      update(this.state, {
        cards: {
          $push: [card]
        }
      }),
      () => {
        this.props.getCurrentState({
          context,
          state: this.state.cards
        })
      }
    )
  }

  removeCard(index) {
    const { context } = this.props

    this.setState(
      update(this.state, {
        cards: {
          $splice: [[index, 1]]
        }
      }),
      () => {
        this.props.getCurrentState({
          context,
          state: this.state.cards
        })
      }
    )
  }

  moveCard(dragIndex, hoverIndex) {
    const { cards } = this.state
    const { context } = this.props
    const dragCard = cards[dragIndex]

    this.setState(
      update(this.state, {
        cards: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        }
      }),
      () => {
        this.props.getCurrentState({
          context,
          state: this.state.cards
        })
      }
    )
  }

  render() {
    const {
      props: {
        canDrop,
        isOver,
        connectDropTarget,
        context,
        id,
        changeCurrentState,
        leftAxisLimit,
        pivotBuilderSettings
      },
      state: { cards = [] }
    } = this
    const isActive = canDrop && isOver
    const bgClassName = isActive ? 'active' : ''
    const isLeftAxis = context === 'LeftAxisDraggable'

    return connectDropTarget(
      <div key={id} className={`container-item ${context} ${bgClassName}`}>
        {isLeftAxis ? (
          <ul className="holder-list">
            {_.map(new Array(leftAxisLimit), (item, index) => (
              <li key={`hl-${index}`} className="cell -slot" />
            ))}
          </ul>
        ) : null}
        <ul className="card-holder">
          {cards.map((card, i) => {
            return (
              <Card
                key={i}
                card={card}
                changeCurrentState={changeCurrentState}
                context={context}
                index={i}
                listId={id}
                moveCard={this.moveCard.bind(this)}
                pivotBuilderSettings={pivotBuilderSettings}
                removeCard={this.removeCard.bind(this)}
              />
            )
          })}
        </ul>
      </div>
    )
  }
}

const cardTarget = {
  drop(props, monitor, component) {
    const { id, axis } = props
    const sourceObj = monitor.getItem()
    if (
      (sourceObj.card[axis] &&
        id === 2 &&
        sourceObj.listId === 4 &&
        props.list.length < props.leftAxisLimit) ||
      (id === 4 && sourceObj.listId === 2)
    ) {
      if (id !== sourceObj.listId) component.pushCard(sourceObj.card)
      return {
        listId: id
      }
    }
  }
}

export default DropTarget('CARD', cardTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
}))(Container)
