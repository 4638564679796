Ext.define('Assortment.cmp.TemplateColumn', {
  extend: 'Ext.grid.column.Template',
  xtype: 'imgcolumn',
  width: 185,
  align: 'center',
  text: 'Style List',
  tpl: new Ext.XTemplate(
    `
  <div data-qtip="<ul style='list-style-type:none; padding-left:10px;margin-bottom:0px'><li><b>Product: </b>{[values.OverrideName]}</li> <li><b>Price: </b>{[values.BasePriceFormatted]}</li><li><b>Color: </b>{[values.ColorName]}</li></ul>">
  <tpl if="IsTestProduct == true">
    <tpl if="optionModified &gt; 0">
      <div class="optcontainer sumTest -dirty">
    <tpl else>
      <div class="optcontainer sumTest">
    </tpl>
  <tpl else>
    <tpl if="optionModified &gt; 0">
      <div class="optcontainer sum -dirty">
    <tpl else>
    <div class="optcontainer sum">
    </tpl>
  </tpl>
  
  <div class="text">{Name}</br> {[values.BasePriceFormatted]}</div>
  <div class="bottom"><img src="{ImageUrl}" /></div>
  </div></div>`,
    {
      formatNumber: function (value) {
        return Ext.util.Format.number(value, '0.00')
      }
    }
  ),
  tplp: new Ext.XTemplate('<tpl for=".">' + '<img src="{ImageURL}" width="310px" />' + '</tpl> ', {
    urlExists: function (vCode) {
      var url = vCode
      var http = new XMLHttpRequest()
      http.open('HEAD', url, false)
      http.send()
      return http.status != 404
    }
  })
})
