import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import _ from 'lodash'

import createPlugin, { PluginTypes } from '../../BasePlugin'

import styles from './index.module.scss'

export const Label = (props) => {
  const [isTextFromData, setIsTextFromData] = useState(true)
  const [isTextFromWorkflow, setIsTextFromWorkflow] = useState(false)
  const [textFromWorkflow, setTextFromWorkflowToState] = useState('')
  const pluginRef = useRef({})

  // Always gets the latest version of 'text'
  pluginRef.current.text = getText()

  useEffect(() => {
    props.registerMethod({
      key: 'setText',
      fn: setTextFromWorkflow,
      args: [{ name: 'text', type: PluginTypes.string }]
    })
    pluginRef.current.registerEvent = {
      handleClick: props.registerEvent({
        key: 'handleClick',
        fn: handleClick,
        returnTypes: { text: PluginTypes.string }
      })
    }
  }, [])

  function handleClick() {
    return { text: pluginRef.current.text }
  }

  function setTextFromWorkflow(data) {
    if (data.text) {
      setIsTextFromData(false)
      setIsTextFromWorkflow(true)
      setTextFromWorkflowToState(data.text)
    }
  }

  function getText() {
    if (isTextFromWorkflow) {
      return textFromWorkflow
    }

    const val = getValue()

    const textColumn = _.get(props, 'settings.config.data.textColumn')
    if (isTextFromData && !_.isUndefined(textColumn)) {
      const formattedValue = getValueWithFormat(textColumn, val)
      return _.isNil(formattedValue) ? '' : formattedValue
    }

    return val
  }

  function getValue() {
    const {
      pluginData = [],
      settings: {
        config: { settings: { text = '' } = {}, data: { textColumn = '' } = {} } = {}
      } = {}
    } = props

    if (text) {
      return text
    }
    if (pluginData) {
      const [{ [textColumn]: columnText } = {}] = pluginData

      return columnText
    }

    return text
  }

  function getValueWithFormat(fieldName, value) {
    const { settings: { query: { formattedFields = [] } = {} } = {}, getFormattedValue } = props

    const format = _.find(formattedFields, { columnName: fieldName })

    if (!_.isNil(format) && !_.isEmpty(format)) {
      return getFormattedValue(fieldName, value, formattedFields)
    }

    return value
  }

  function getStyle(settings = {}) {
    const {
      backgroundColor,
      fontStyle = 'Normal',
      fontWeight = 'Normal',
      fontColor: color,
      fontSize,
      textAlign
    } = settings

    const style = {
      fontStyle,
      fontWeight,
      textAlign: 'center'
    }

    if (backgroundColor) {
      style.backgroundColor = backgroundColor
    }
    if (color) {
      style.color = color
    }
    if (textAlign) {
      style.textAlign = textAlign
    }
    if (fontSize) {
      style.fontSize = fontSize
    }

    return style
  }

  function getBodyStyle(size, textDegree) {
    const commonStyle = {
      width: size.height,
      height: size.width
    }
    const styleMap = {
      '-90': {
        ...commonStyle,
        marginLeft: -size.height
      },
      90: {
        ...commonStyle,
        marginTop: -size.width
      }
    }

    return styleMap[textDegree] || {}
  }

  const { size = {}, settings: { config: { settings, settings: { textDegree } = {} } = {} } = {} } =
    props

  const style = getStyle(settings)
  const bodyStyle = getBodyStyle(size, textDegree)
  const text = getText()

  const onHandleClick = _.get(pluginRef, 'current.registerEvent.handleClick', null)

  return (
    <div
      className={styles.slvyLabel}
      data-testid="slvy-label"
      style={style}
      onClick={onHandleClick}
    >
      <div
        className={cx(styles.slvyLabelText, styles[`textDegree${textDegree}`])}
        style={bodyStyle}
      >
        {textDegree === 'Vertical'
          ? _.map(_.toString(text).split(''), (letter, index) => (
              <div
                key={index}
                className={cx(styles.slvyLabelLetter, { [styles.spacer]: letter === ' ' })}
                data-testid="slvy-label-letter"
              >
                {letter}
              </div>
            ))
          : text}
      </div>
    </div>
  )
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  size: props.size,
  getFormattedValue: props.getFormattedValue
})

export default createPlugin(Label, selectConnectorProps, true)
