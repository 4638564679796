Ext.define('TreeFilter', {
  extend: 'Ext.AbstractPlugin',
  alias: 'plugin.treefilter',
  collapseOnClear: true,
  allowParentFolders: false,
  init: function (tree) {
    var me = this
    me.tree = tree
    tree.filter = Ext.Function.bind(me.filter, me)
    tree.clearFilter = Ext.Function.bind(me.clearFilter, me)
  },
  filter: function (value, property, re) {
    var me = this,
      tree = me.tree,
      matches = [],
      root = tree.getRootNode(),
      property = property || 'text',
      re = re || new RegExp(value, 'ig'),
      visibleNodes = [],
      viewNode
    if (Ext.isEmpty(value)) {
      me.clearFilter()
      return
    }
    tree.expandAll()
    root.cascadeBy(function (node) {
      if (node.get(property).match(re)) {
        matches.push(node)
      }
    })
    if (me.allowParentFolders === false) {
      Ext.each(matches, function (match) {
        if (!match.isLeaf()) {
          Ext.Array.remove(matches, match)
        }
      })
    }
    Ext.each(matches, function (item, i, arr) {
      root.cascadeBy(function (node) {
        if (node.contains(item) == true) {
          visibleNodes.push(node)
        }
      })
      if (me.allowParentFolders === true && !item.isLeaf()) {
        item.cascadeBy(function (node) {
          visibleNodes.push(node)
        })
      }
      visibleNodes.push(item)
    })
    root.cascadeBy(function (node) {
      viewNode = Ext.fly(tree.getView().getNode(node))
      if (viewNode) {
        viewNode.setVisibilityMode(Ext.Element.DISPLAY)
        viewNode.setVisible(Ext.Array.contains(visibleNodes, node))
      }
    })
  },
  clearFilter: function () {
    var me = this,
      tree = this.tree,
      root = tree.getRootNode()
    if (me.collapseOnClear) {
      tree.collapseAll()
    }
    root.cascadeBy(function (node) {
      var viewNode = Ext.fly(tree.getView().getNode(node))
      if (viewNode) {
        viewNode.show()
      }
    })
  }
})
Ext.define('MDM.Btl.cmp.UxTreePicker', {
  extend: 'Ext.form.field.Picker',
  xtype: 'uxtreepicker',
  uses: ['Ext.tree.Panel'],
  triggerCls: 'x-form-search-trigger',
  config: {
    /**
     * @cfg {Ext.data.TreeStore} store
     * A tree store that the tree picker will be bound to
     */
    store: null,
    /**
     * @cfg {String} displayField
     * The field inside the model that will be used as the node's text.
     * Defaults to the default value of {@link Ext.tree.Panel}'s `displayField` configuration.
     */
    displayField: null,
    /**
     * @cfg {Array} columns
     * An optional array of columns for multi-column trees
     */
    columns: null,
    /**
     * @cfg {Boolean} selectOnTab
     * Whether the Tab key should select the currently highlighted item. Defaults to `true`.
     */
    selectOnTab: true,
    /**
     * @cfg {Number} maxPickerHeight
     * The maximum height of the tree dropdown. Defaults to 300.
     */
    maxPickerHeight: 300,
    /**
     * @cfg {Number} minPickerHeight
     * The minimum height of the tree dropdown. Defaults to 100.
     */
    minPickerHeight: 100
  },
  editable: false,
  /**
   * @event select
   * Fires when a tree node is selected
   * @param {Ext.ux.TreePicker} picker        This tree picker
   * @param {Ext.data.Model} record           The selected record
   */
  initComponent: function () {
    var me = this
    me.callParent(arguments)
  },
  /**
   * Creates and returns the tree panel to be used as this field's picker.
   */
  createPicker: function () {
    this.searchField = Ext.create({
      xtype: 'textfield',
      dock: 'top',
      lastFilterValue: '',
      emptyText: 'Searche',
      enableKeyEvents: true,
      triggers: {
        clear: {
          cls: 'x-form-clear-trigger',
          handler: 'onClearTriggerClick',
          hidden: true,
          scope: 'this'
        },
        search: {
          cls: 'x-form-search-trigger',
          weight: 1,
          handler: 'onSearchTriggerClick',
          scope: 'this'
        }
      },
      onClearTriggerClick: function () {
        this.setValue()
        me.store.clearFilter()
        me.filterStore('')
        this.getTrigger('clear').hide()
      },
      onSearchTriggerClick: function () {
        me.filterStore(this.getValue())
      },
      listeners: {
        change: {
          fn: function (field, newVal) {
            this.view.refresh()
            this.getPicker().filter(newVal)
            if (newVal == '') {
              field.getTrigger('clear').hide()
              field.lastFilterValue = newVal
            } else if (newVal && newVal !== field.lastFilterValue) {
              field.getTrigger('clear')[newVal.length > 0 ? 'show' : 'hide']()
              field.lastFilterValue = newVal
            }
          },
          buffer: 300
        },
        render: function (field) {
          this.searchField = field
        },
        scope: this
      }
    })
    var me = this,
      picker = new Ext.tree.Panel({
        border: true,
        shrinkWrapDock: 1,
        minWidth: 200,
        store: me.store,
        floating: true,
        displayField: me.displayField,
        minHeight: me.minPickerHeight,
        maxHeight: me.maxPickerHeight,
        manageHeight: true,
        rootVisible: false,
        shadow: true,
        plugins: [
          {
            ptype: 'treefilter',
            allowParentFolders: true,
            collapseOnClear: false
          }
        ],
        columns: [
          {
            xtype: 'treecolumn',
            flex: 1,
            dataIndex: me.displayField,
            scope: me,
            //PS: TODO: v2@ iconCls: 'fas fa-industry ',
            renderer: function (value) {
              var searchString = this.searchField.getValue()
              if (searchString.length > 0) {
                return this.strMarkRedPlus(searchString, value)
              }
              return value
            }
          }
        ],
        listeners: {
          scope: me,
          itemclick: me.onItemClick,
          itemkeydown: me.onPickerKeyDown
        },
        dockedItems: [this.searchField]
      })
    this.view = picker.getView()
    return picker
  },
  filterStore: function (value) {
    var me = this,
      store = me.store,
      searchString = value.toLowerCase(),
      filterFn = function (node) {
        var children = node.childNodes,
          len = children && children.length,
          visible = v.test(node.get('text')),
          i
        if (!visible) {
          for (i = 0; i < len; i++) {
            if (children[i].isLeaf()) {
              visible = children[i].get('visible')
            } else {
              visible = filterFn(children[i])
            }
            if (visible) {
              break
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            children[i].set('visible', true)
          }
        }
        return visible
      },
      v
    if (searchString.length < 1) {
      store.clearFilter()
    } else {
      v = new RegExp(searchString, 'i')
      store.getFilters().replaceAll({
        filterFn: filterFn
      })
    }
  },
  strMarkRedPlus: function (search, subject) {
    return subject.replace(
      new RegExp('(' + search + ')', 'gi'),
      "<span style='background:yellow; color:red ;'><b>$1</b></span>"
    )
  },
  repaintPickerView: function () {
    var style = this.picker.getView().getEl().dom.style
    // can't use Element.repaint because it contains a setTimeout, which results in a flicker effect
    style.display = style.display
  },
  /**
   * Handles a click even on a tree node
   * @private
   * @param {Ext.tree.View} view
   * @param {Ext.data.Model} record
   * @param {HTMLElement} node
   * @param {Number} rowIndex
   * @param {Ext.event.Event} e
   */
  onItemClick: function (view, record, node, rowIndex, e) {
    this.selectItem(record)
  },
  /**
   * Handles a keypress event on the picker element
   * @private
   * @param {Ext.event.Event} e
   * @param {HTMLElement} el
   */
  onPickerKeyDown: function (treeView, record, item, index, e) {
    var key = e.getKey()
    if (key === e.ENTER || (key === e.TAB && this.selectOnTab)) {
      this.selectItem(record)
    }
  },
  /**
   * Changes the selection to a given record and closes the picker
   * @private
   * @param {Ext.data.Model} record
   */
  selectItem: function (record) {
    var me = this
    me.setValue(record.getId())
    me.fireEvent('select', me, record)
    me.collapse()
  },
  /**
   * Runs when the picker is expanded.  Selects the appropriate tree node based on the value of the input element,
   * and focuses the picker so that keyboard navigation will work.
   * @private
   */
  onExpand: function () {
    var picker = this.picker,
      store = picker.store,
      value = this.value,
      node
    if (value) {
      node = store.getNodeById(value)
    }
    if (!node) {
      node = store.getRoot()
    }
    picker.ensureVisible(node, {
      select: true,
      focus: true
    })
  },
  /**
   * Sets the specified value into the field
   * @param {Mixed} value
   * @return {Ext.ux.TreePicker} this
   */
  setValue: function (value) {
    var me = this,
      record
    me.value = value
    if (me.store.loading) {
      // Called while the Store is loading. Ensure it is processed by the onLoad method.
      return me
    }
    // try to find a record in the store that matches the value
    record = value ? me.store.getNodeById(value) : me.store.getRoot()
    if (value === undefined) {
      record = me.store.getRoot()
      me.value = record.getId()
    } else {
      record = me.store.getNodeById(value)
    }
    // set the raw value to the record's display field if a record was found
    me.setRawValue(record ? record.get(me.displayField) : '')
    return me
  },
  changeRecord: function (fieldName, value) {
    var me = this,
      store = this.store,
      record = null
    record = store.findNode(fieldName, value)
    if (me.store.loading) {
      // Called while the Store is loading. Ensure it is processed by the onLoad method.
      return me
    }
    if (record) {
      me.fireEvent('select', me, record)
      record.expand()
      var picker = me.getPicker()
      picker.getSelectionModel().select(record, true)
      this.setValue(record.id)
    }
  },
  getSubmitValue: function () {
    return this.value
  },
  /**
   * Returns the current data value of the field (the idProperty of the record)
   * @return {Number}
   */
  getValue: function () {
    return this.value
  },
  /**
   * Handles the store's load event.
   * @private
   */
  onLoad: function () {
    var value = this.value
    if (value) {
      this.setValue(value)
    }
  },
  onUpdate: function (store, rec, type, modifiedFieldNames) {
    var display = this.displayField
    if (
      type === 'edit' &&
      modifiedFieldNames &&
      Ext.Array.contains(modifiedFieldNames, display) &&
      this.value === rec.getId()
    ) {
      this.setRawValue(rec.get(display))
    }
  }
})
