import {
  OverlayTrigger,
  Tooltip as BsTooltip,
  TooltipProps as BsTooltipProps
} from 'react-bootstrap'
import cx from 'classnames'
import { useTheme } from '../../contexts/ThemeContext'
import { TooltipProps } from './Tooltip.types'

export default function Tooltip({
  className,
  children,
  delay = 1000,
  placement = 'bottom',
  text
}: TooltipProps) {
  const styles = useTheme()

  function renderTooltip(props: BsTooltipProps) {
    return (
      <BsTooltip className={cx(styles.tooltip, className)} id="button-tooltip" {...props}>
        {text}
      </BsTooltip>
    )
  }

  return (
    <OverlayTrigger
      delay={delay}
      overlay={renderTooltip}
      placement={placement}
      trigger={['focus', 'hover']}
    >
      {children}
    </OverlayTrigger>
  )
}
