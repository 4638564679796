import { ReactNode } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { settings } from '../../constants.ts'
import { IHighlightProps, IIconClasses } from './Highlight.types'
import classes from './Highlight.module.scss'

const { iconClasses } = settings
const Highlight = ({ data, value }: IHighlightProps) => {
  const regexRule = new RegExp(`(${value})`, 'gi')

  return data.reduce((acc: ReactNode, item, index) => {
    const parts = item.split(regexRule)
    let tpl: ReactNode = acc

    const icon = iconClasses[index as keyof IIconClasses]

    if (!_.isEmpty(item)) {
      tpl = [
        ...(acc as ReactNode[]),
        <span
          key={index}
          className={cx(classes.highlightItem, 'd-block')}
          data-testid={`highlight-item-${index}`}
        >
          <i
            className={cx(classes.highlightIcon, 'fa', icon, {
              [classes.fs15]: icon === 'fa-angle-double-right'
            })}
          />
          {parts.map((part, i) => {
            const shouldHighlight = part.toLowerCase() === value.toLowerCase()
            return (
              <b
                key={`${index}-${i}`}
                className={cx(classes.highlightBold, { [classes.highlight]: shouldHighlight })}
                data-testid={`highlight-part-item-${i}`}
              >
                {part}
              </b>
            )
          })}
        </span>
      ]
    }
    return tpl
  }, [])
}

export default Highlight
