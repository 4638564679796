import React, { Component, createRef } from 'react'
import _ from 'lodash'
import Grid from './grid'
import { GridHeader } from '../GridCommon/index.ts'
import OutsideClickHandler from '../../OutsideClickHandler'

export default class Pivot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        row: [],
        column: []
      },
      selectedRowId: {
        cell: 0,
        row: 0
      }
    }

    this.uiGridCtnRef = createRef()

    this.cellClick = this.cellClick.bind(this)
    this.clickNode = this.clickNode.bind(this)
    this.nodeAction = this.nodeAction.bind(this)
    this.pivotClick = this.pivotClick.bind(this)
    this.rowClick = this.rowClick.bind(this)
    this.rowClicked = this.rowClicked.bind(this)
    this.setSelectedRowId = this.setSelectedRowId.bind(this)
    this.update = this.update.bind(this)
  }

  clickNode(event, isOutsideClicked = false) {
    const { updateMode = 0, viewMode = 0 } = this.props
    if (viewMode === 2 && (updateMode === 0 || updateMode === 1)) {
      if (event.target.tagName.toUpperCase() !== 'INPUT' || isOutsideClicked) {
        this.setSelectedRowId(0)
      }
    }
  }

  componentWillUnmount() {
    this.uiGridCtnRef.current.removeEventListener('click', this.clickNode)
  }

  componentDidMount() {
    this.uiGridCtnRef.current.addEventListener('click', this.clickNode)
  }

  update(params) {
    const { ExternalEdit = false } = params
    if (ExternalEdit) {
      this.props.updateExternalCallback(params)
    } else {
      this.props.updateCallback(params)
    }
  }

  nodeAction(params, obj) {
    this.props.nodeActionCallback(params, obj)
  }

  rowClick(LeftKeys) {
    this.pivotCallback(LeftKeys)
  }

  rowClicked(params) {
    this.props.rowClicked(params)
  }

  pivotClick(LeftKeys) {
    this.props.pivotDetailCallback({ LeftKeys })
    this.props.onViewChangedCallback({ View: 'PivotDetailView' })
  }

  cellClick(LeftKeys, MetricName, singleDetailMetricParams) {
    const { pivotSingleDetailCallback = () => {}, onViewChangedCallback = () => {} } = this.props
    pivotSingleDetailCallback({ LeftKeys, MetricName, singleDetailMetricParams })
    onViewChangedCallback({ View: 'PivotSingleDetailView' })
  }

  setSelectedRowId(cell, row) {
    this.setState({ selectedRowId: { cell, row } })
  }

  getValuesDraggable(ValuesDraggable, PivotConfigResult, Metrics) {
    ValuesDraggable = _.map(ValuesDraggable, (item) => {
      const { DisplayName = null } =
        _.find(PivotConfigResult, (subItem) => subItem.Name === item.Name) || {}
      return {
        ...item,
        ...(DisplayName !== null ? { DisplayName } : {})
      }
    })

    ValuesDraggable = _.reduce(
      ValuesDraggable,
      (arr, element) => {
        const isFoundElement = _.find(Metrics, (b) => b.Name === element.Name)
        if (isFoundElement) {
          arr.push(element)
        }
        return arr
      },
      []
    )

    return ValuesDraggable
  }

  pivotCallback(LeftKeys = []) {
    this.props._savePivotViewSettings(
      {
        selectedLeftKeys: LeftKeys,
        mergedLeftFieldsAndKeys: this.props.mergeLeftFieldsAndKeys(LeftKeys)
      },
      () => {
        this.props._fetchMfpData().then(() => this.props.toggleLoader())
      }
    )
  }

  render() {
    let {
      isScenarioEditable = false,
      pluginId = '',
      culture = '',
      pivotData,
      pivotData: { Errors = [] } = {},
      leftHierarchy = [],
      pivotState: { ValuesDraggable = [], LeftAxisDraggable = [] },
      viewMode = 0,
      updateMode = 0,
      settings: { isRowClickedEventOn },
      settings = {
        isRowClickedEventOn: false,
        isVisibleCopyHierarchyButton: true,
        isVisibleSCButton: true,
        coef: ({
          // eslint-disable-next-line
          buttons: []
        } = {}),
        shouldShowConfirmApprovedCellUpdate: true
      },
      buttonFormat,
      IsApproveAllowed,
      setHierarchy,
      stateHandler,
      onScenarioComparisonClick = () => {},
      onViewChangedCallback = () => {},
      GetScenarioTree = () => {},
      reqLeftFields = {},
      statePicker: { allowedToUpdate = true } = {},
      GetPivotConfiguration: { Result: { Fields: PivotConfigResult = [] } = {} } = {}
    } = this.props

    let DetailList = []
    let Metrics = []

    if (!Errors.length) {
      const { Result: { DetailList: _dl = [], Metrics: _mr = [] } = {} } = pivotData
      DetailList = [..._dl]
      Metrics = [..._mr]
    }

    const { selectedRowId } = this.state
    const leftKeyLength = LeftAxisDraggable.length

    ValuesDraggable = this.getValuesDraggable(ValuesDraggable, PivotConfigResult, Metrics)

    const pivotWith = Metrics.length * 140 + 305
    const pivotStyle = { width: pivotWith }
    const notAllowedClass = !allowedToUpdate ? '-not-allowed' : ''
    const rowUpdateClass = updateMode === 1 && viewMode === 2 ? '-ui-grid-row-update' : ''
    const inputCoverClass =
      (updateMode === 0 || updateMode === 1) && viewMode === 2 ? '-input-cover' : ''
    const fitClass = Metrics.length > 6 ? '-not-fit' : '-fit'

    const GridHeaders = Metrics.map(({ CurrentAggregationFunction = '' }, metricIndex) => {
      const { TooltipText = '', DisplayName: Text = '' } = ValuesDraggable[metricIndex] || {}
      return { TooltipText, Text, CurrentAggregationFunction }
    })

    return (
      <OutsideClickHandler onOutsideClick={(event) => this.clickNode(event, true)}>
        <div
          ref={this.uiGridCtnRef}
          className={`slvy-ui-grid-ctn ${notAllowedClass} ${rowUpdateClass} ${inputCoverClass} ${fitClass}`}
        >
          <div className="slvy-ui-grid-container -common">
            <div className="slvy-ui-table -main-pivot" style={pivotStyle}>
              <GridHeader customField="CurrentAggregationFunction" headers={GridHeaders} />
              <Grid
                GetScenarioTree={GetScenarioTree}
                IsApproveAllowed={IsApproveAllowed}
                allowedToUpdate={allowedToUpdate}
                buttonFormat={buttonFormat}
                culture={culture}
                data={DetailList}
                isRowClickedEventOn={isRowClickedEventOn}
                isScenarioEditable={isScenarioEditable}
                leftHierarchy={leftHierarchy}
                leftKeyLength={leftKeyLength}
                metricsFields={Metrics}
                pluginId={pluginId}
                reqLeftFields={reqLeftFields}
                selectedRowId={selectedRowId}
                setHierarchy={setHierarchy}
                setSelectedRowId={this.setSelectedRowId}
                settings={settings}
                stateHandler={stateHandler}
                submitHandler={this.submitHandler}
                updateMode={updateMode}
                values={ValuesDraggable}
                viewMode={viewMode}
                onAction={this.nodeAction}
                onCellClick={this.cellClick}
                onPivotClick={this.pivotClick}
                onRowClick={this.rowClick}
                onRowClicked={this.rowClicked}
                onScenarioComparisonClick={(params) => {
                  onScenarioComparisonClick(params)
                  onViewChangedCallback({
                    View: 'ScenarioComparison'
                  })
                }}
                onUpdate={this.update}
              />
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
}
