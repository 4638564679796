Ext.define('po.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.mainViewModel',
  data: {
    pluginTypeId: 1,
    isFiltered: false,
    storeChanged: null,
    recordChanged: null,
    selectedGroupByField: 'tedarikciAdi',
    groupActiveItem: 0,
    checkedSAPCount: 0,
    selectedRowCount: 0,
    isToolbarVisible: false,
    reasonDictionary: {},
    columnStates: [],
    reasonCodeEnabled: null,

    // for localization
    i18n: null,

    // editor fields for changes
    editorDegisenOneriKoli: null,
    editorSebepKodu: null,

    // inventoryStoreParams
    selectedRecord: null,

    // massupdate params
    checkedRecords: null,

    // filter
    applicationType: null,
    groupByField: null,
    selectedProductCode: null,
    selectedDC: null,
    selectedK1K: null,
    selectedK2K: null,
    selectedK3K: null,
    selectedDate: null,
    endDate: null,
    selectedStore: null,
    viewMode: null,
    checkedAll: false,

    mainGridColumns: null,
    panelVisibility: null,
    buttonsStatus: null,

    mainGridFilters: {},
    mainGridActiveFilters: [],

    massUpdateNumberFieldMinVal: null
  },
  stores: {
    mainStore: {
      type: 'OrderRecommendationStore',
      listeners: {
        update: 'onMainStoreUpdate',
        load: 'onMainStoreLoad',
        beforeLoad: 'onMainStoreBeforeLoad',
        write: 'onMainStoreWrite',
        filterchange: 'onMainStoreFilterChange'
      }
    },
    groupStore: {
      type: 'OrderRecommendationGroupStore',
      listeners: {
        beforeLoad: 'onGroupStoreBeforeLoad'
      }
    },
    productCodeStore: {
      type: 'ProductCodeStore',
      listeners: {
        beforeLoad: 'onProductCodeStoreBeforeLoad'
      }
    },
    dcStore: {
      type: 'DcStore',
      listeners: {
        beforeLoad: 'onDcStoreBeforeLoad'
      }
    },
    groupbyStore: {
      type: 'GroupByStore',
      listeners: {
        beforeLoad: 'onGroupByStoreBeforeLoad'
      }
    },
    k1kStore: {
      type: 'K1kStore',
      listeners: {
        beforeLoad: 'onK1kStoreBeforeLoad'
      }
    },
    k2kStore: {
      type: 'K2kStore'
    },
    k3kStore: {
      type: 'K3kStore'
    },
    inventoryStore: {
      type: 'InventoryStore',
      listeners: {
        beforeLoad: 'onInventoryStoreBeforeLoad'
      }
    },
    localizationStore: {
      type: 'LocalizationStore',
      listeners: {
        load: 'onLocalizationStoreLoad'
      }
    },
    reasonStore: {
      model: 'po.model.ReasonModel',
      autoLoad: true,
      listeners: {
        load: 'onReasonStoreLoad',
        beforeLoad: 'onReasonStoreBeforeLoad'
      }
    },
    storeDetailStore: {
      type: 'StoreDetailStore'
    },
    openSasStore: {
      type: 'OpenSasStore'
    },
    openAllSasStore: {
      type: 'OpenAllSasStore'
    },
    openOrderStore: {
      type: 'OpenOrderStore'
    },
    roundUpStore: {
      type: 'RoundUpStore',
      listeners: {
        beforeLoad: 'onRoundUpStoreBeforeLoad'
      }
    },
    substituteStore: {
      type: 'SubstituteStore',
      listeners: {
        update: 'onSubstituteStoreUpdate'
      }
    },
    checkedRecordsStore: {
      type: 'SubstituteStore'
    },
    mainDiffStore: {
      type: 'SubstituteStore'
    },
    zeroInventoryStore: {
      type: 'ZeroInventoryStore'
    },
    blockagedStore: {
      type: 'BlockagedStore'
    },
    futureOrderStore: {
      type: 'FutureOrderStore'
    },
    promotionStore: {
      type: 'PromotionDetailStore'
    }
  },
  formulas: {
    // this formula changing button status pls do not delete.
    buttonsStatusChange: {
      bind: {
        storeChanged: '{storeChanged}',
        recordChanged: '{recordChanged}'
      },
      get: function () {
        var me = this,
          view = me.getView(),
          viewModel = view.getViewModel(),
          buttonsStatus = viewModel.get('buttonsStatus'),
          selectedRowCount = me.get('selectedRowCount'),
          checkedSAPCount = me.get('checkedSAPCount')

        if (this.getStore('mainStore') && this.getStore('mainStore').isDirty()) {
          buttonsStatus.saveButtonEnable = true
          buttonsStatus.cancelButtonEnable = true
          viewModel.set('buttonsStatus', buttonsStatus)
          view.fireEvent('buttonsStatusChange', buttonsStatus)
        } else {
          if (checkedSAPCount == 0) buttonsStatus.sapButtonEnable = false
          if (selectedRowCount == 0) {
            buttonsStatus.massUpdateButtonEnable = false
          }
          buttonsStatus.saveButtonEnable = false
          buttonsStatus.cancelButtonEnable = false
          viewModel.set('buttonsStatus', buttonsStatus)
          view.fireEvent('buttonsStatusChange', buttonsStatus)
        }
      }
    },
    reloadGroupStore: {
      bind: {
        storeChanged: '{storeChanged}',
        selectedGroupByField: '{selectedGroupByField}'
      },
      get: function (data) {
        var me = this,
          mainStore = me.get('mainStore')
        var groupStore = me.get('groupStore')
        if (mainStore.isLoaded()) groupStore.load()
      }
    },
    reloadRoundUpStore: {
      bind: {
        storeChanged: '{storeChanged}'
      },
      get: function () {
        var me = this,
          mainStore = me.get('mainStore')
        var groupStore = me.get('roundUpStore')
        if (mainStore.isLoaded()) groupStore.load()
      }
    },
    allDataSummary: {
      bind: {
        storeChanged: '{storeChanged}'
      },
      get: function () {
        var mainstore = this.get('mainStore')
        var data = mainstore.getDatas()

        var sql =
          'SELECT Count(*) AS ToplamKayit, SUM(CASE WHEN onayFlag=true then 1 else 0 end) as OnaylananKayit, SUM(CASE WHEN SAPYollandi=true then 1 else 0 end) as SAPKayit, SUM(CASE WHEN depoSiparisKoli>0 then 1 else 0 end) as OnerilenKayit, SUM(degisenOneriKoli) AS ToplamKoli, SUM(case when onayFlag=true then degisenOneriKoli else 0 end) AS OnaylananKoli, SUM(case when SAPYollandi=true then degisenOneriKoli else 0 end) AS SAPKoli, SUM(CASE WHEN depoSiparisKoli>0 then depoSiparisKoli else 0 end) as OnerilenKoli, SUM(KoliSatisFiyati*degisenOneriKoli) AS ToplamTutar, SUM(case when onayFlag=true then KoliSatisFiyati*degisenOneriKoli else 0 end) AS OnaylananTutar, SUM(case when SAPYollandi=true then KoliSatisFiyati*degisenOneriKoli else 0 end) AS SAPTutar,SUM(CASE WHEN depoSiparisKoli>0 then KoliSatisFiyati*depoSiparisKoli  else 0 end) as OnerilenTutar FROM ?'

        var res = alasql(sql, [data])
        return res[0]
      }
    },
    isSapButtonActive: {
      bind: {
        checkedSAPCount: '{checkedSAPCount}'
      },
      get: function () {
        var checkedSAPCount = this.get('checkedSAPCount')
        return checkedSAPCount > 0
      }
    },
    isProductCodeDisabled: {
      bind: {
        selectedDC: '{selectedDC}'
      },
      get: function () {
        var selectedDC = this.get('selectedDC')
        var result = selectedDC == null || selectedDC.length == 0
        if (result) {
          this.set('selectedK1K', null)
        }
        return result
      }
    },
    isK1KDisabled: {
      bind: {
        selectedDC: '{selectedDC}'
      },
      get: function () {
        var selectedDC = this.get('selectedDC')
        var result = selectedDC == null || selectedDC.length == 0
        if (result) {
          this.set('selectedK1K', null)
        }
        return result
      }
    },
    isK2KDisabled: {
      bind: {
        selectedK1K: '{selectedK1K}'
      },
      get: function () {
        var selectedK1K = this.get('selectedK1K')
        var result = selectedK1K == null || selectedK1K.length == 0
        if (result) {
          this.set('selectedK2K', null)
        }
        return result
      }
    },
    isK3KDisabled: {
      bind: {
        selectedK2K: '{selectedK2K}'
      },
      get: function () {
        var selectedK2K = this.get('selectedK2K')
        var result = selectedK2K == null || selectedK2K.length == 0
        if (result) {
          this.set('selectedK3K', null)
        }
        return result
      }
    },
    statusText: {
      bind: {
        allDataSummary: '{allDataSummary}',
        i18n: '{i18n}',
        panelVisibility: '{panelVisibility}'
      },
      get: function () {
        var allDataSummary = this.get('allDataSummary')
        var i18n = this.get('i18n')
        var toolbarERPGroupVisible = this.get('panelVisibility.footerToolbarERPGroupVisible')
        var tpl = new Ext.XTemplate(
          '<div style="display:inline-flex;font-size: 14px !important;">',
          '<div style="display:inline-flex;">',
          '<b style="padding:10px 0 0 10px;">{StatusText_Record}</b>',
          '<div style="border:1px solid #32b927; padding:3px;margin:5px;background: #fff;">',
          '<span>{StatusText_OnerilenKayit}</span>:<b>{[this.formatNumber(values.OnerilenKayit)]}</b>   |  ',
          '<span>{StatusText_OnaylananKayit}</span>:<b>{[this.formatNumber(values.OnaylananKayit)]}</b>   |  ',
          toolbarERPGroupVisible
            ? '<span>{StatusText_SAPKayit}</span>:<b>{[this.formatNumber(values.SAPKayit)]}</b>   |  '
            : '',
          '<span>{StatusText_ToplamKayit}</span>:<b>{[this.formatNumber(values.ToplamKayit)]}</b> ',
          '</div>',
          '</div>',
          '<div style="display:inline-flex;">',
          '<b style="padding:10px 0 0 10px;">{StatusText_Quantity}</b>',
          '<div style="border:1px solid #32b927; padding:3px;margin:5px;background: #fff;">',
          '<span>{StatusText_OnerilenKoli}</span>:<b>{[this.formatNumber(values.OnerilenKoli)]}</b>   |  ',
          '<span>{StatusText_OnaylananKoli}</span>:<b>{[this.formatNumber(values.OnaylananKoli)]}</b>   |  ',
          toolbarERPGroupVisible
            ? '<span>{StatusText_SAPKoli}</span>:<b>{[this.formatNumber(values.SAPKoli)]}</b>   |  '
            : '',
          '<span>{StatusText_ToplamKoli}</span>:<b>{[this.formatNumber(values.ToplamKoli)]}</b> ',
          '</div>',
          '</div>',
          '<div style="display:inline-flex;">',
          '<b style="padding:10px 0 0 10px;">{StatusText_Amount}</b>',
          '<div style="border:1px solid #32b927; padding:3px;margin:5px;background: #fff;">',
          '<span>{StatusText_OnerilenTutar}</span>:<b>{[this.formatCurrency(values.OnerilenTutar)]}</b>   |  ',
          '<span>{StatusText_OnaylananTutar}</span>:<b>{[this.formatCurrency(values.OnaylananTutar)]}</b>   |  ',
          toolbarERPGroupVisible
            ? '<span>{StatusText_SAPTutar}</span>:<b>{[this.formatCurrency(values.SAPTutar)]}</b>   |  '
            : '',
          '<span>{StatusText_ToplamTutar}</span>:<b>{[this.formatCurrency(values.ToplamTutar)]}</b> ',
          '</div>',
          '</div>',
          '</div>',
          {
            formatCurrency: function (value) {
              return Ext.util.Format.number(value, '0,000.00 ₺')
            },
            formatNumber: function (value) {
              return Ext.util.Format.number(value, '0,000')
            }
          }
        )

        var allDataWithLocalization = Ext.apply({}, allDataSummary, i18n)
        return tpl.apply(allDataWithLocalization)
      }
    },
    groupActiveItemButtonText: {
      bind: {
        groupActiveItem: '{groupActiveItem}',
        i18n: '{i18n}'
      },
      get: function () {
        var groupActiveItem = this.get('groupActiveItem'),
          i18n = this.get('i18n'),
          tablo = '',
          chart = ''
        if (i18n) {
          ;(tablo = i18n.MainViewModel_groupActiveItemButtonTextTable),
            (chart = i18n.MainViewModel_groupActiveItemButtonTextChart)
        }
        return groupActiveItem == 1 ? tablo : chart
      }
    },
    selectedGroupByKeyField: {
      bind: {
        selectedGroupByField: '{selectedGroupByField}'
      },
      get: function () {
        var me = this,
          groupbyStore = me.getStore('groupbyStore'),
          selectedGroupByField = me.get('selectedGroupByField')

        var record = groupbyStore.findRecord('Field', selectedGroupByField)

        if (record) return record.get('KeyField')

        return null
      }
    },

    // editor formulas

    editorMiktar: {
      bind: {
        selectedRecord: '{selectedRecord}',
        storeChanged: '{storeChanged}'
      },
      get: function (data) {
        if (data.selectedRecord) {
          return data.selectedRecord.get('degisenOneriKoli')
        }
      }
    },
    selectedRecordWithi18n: {
      bind: {
        selectedRecord: '{selectedRecord}',
        i18n: '{i18n}'
      },
      get: function () {
        var selectedRecord = this.get('selectedRecord'),
          i18n = this.get('i18n'),
          selectedRecordData = null

        if (selectedRecord) {
          selectedRecordData = selectedRecord.data
        }
        return Ext.apply({}, selectedRecordData, i18n)
      }
    },
    mainGridColumnsMerged: {
      bind: {
        mainGridColumns: '{mainGridColumns}'
      },
      get: function (data) {
        var me = this
        var view = this.getView()
        var references = view.getReferences()

        if (data.mainGridColumns && references && references.MainGrid) {
          return po.Global.mergeColumns(data.mainGridColumns, po.Columns.mainGridColumns)
        } else if (po.Columns.mainGridColumns) {
          return po.Columns.mainGridColumns
        } else return []
      }
    },
    mainGridFiltersChanged: {
      bind: {
        mainGridFilters: '{mainGridFilters}'
      },
      get: function () {
        var me = this
        var mainGridFilters = me.get('mainGridFilters')
        var mainGridStore = me.getStore('mainStore')
        var mainGridActiveFilters = me.get('mainGridActiveFilters')

        if (mainGridStore.filters) {
          mainGridActiveFilters.forEach((filter) => {
            mainGridStore.removeFilter(filter)
          })

          mainGridActiveFilters = []

          for (const key in mainGridFilters) {
            if (mainGridFilters.hasOwnProperty(key)) {
              var value = mainGridFilters[key]

              mainGridStore.addFilter({
                id: key,
                property: key,
                value: value
              })

              mainGridActiveFilters.push(key)
            }
          }

          me.set('mainGridActiveFilters', mainGridActiveFilters)
        }
      }
    },
    selectRowColumnText: {
      bind: {
        selectedRowCount: '{selectedRowCount}',
        selectChange: '{selectChange}'
      },
      get: function () {
        var me = this,
          mainStore = me.getStore('mainStore'),
          datas = mainStore.getDatas(),
          length = datas.length,
          selectedCount = me.get('selectedRowCount')
        if (length == 0) {
          return '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
        } else if (selectedCount == length) {
          me.set('checkedAll', true)
          return '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn-checked" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
        } else {
          me.set('checkedAll', false)
          return '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>'
        }
      }
    },
    editorDisabled: {
      bind: {
        canEdit: '{canEdit}',
        selectedRecord: '{selectedRecord}'
      },
      get: function (data) {
        if (!data.canEdit) {
          return true
        } else if (!data.selectedRecord) {
          return true
        } else if (
          data.selectedRecord &&
          (!data.selectedRecord.data.IsEditable ||
            data.selectedRecord.data.SAPYollandi ||
            data.selectedRecord.data.isSendToSap)
        ) {
          return true
        } else if (data.selectedRecord && data.selectedRecord.editorMiktarDisabled) {
          return true
        }
        return false
      }
    }
  }
})
