export default `({
    autoLoad: false,
    autoSync: false,
    groupField: 'StatusDescription',
    fields: [{
            name: 'SpotID',
            type: 'int'
        },
        {
            name: 'DCCode',
            type: 'string'
        },
        {
            name: 'DCName',
            type: 'string'
        },
        {
            name: 'MaterialCode',
            type: 'string'
        },
        {
            name: 'LastModifiedAt',
            type: 'date',
            dateFormat: tt('Y-m-d H:i:s.u')
        },
        {
            name: 'LastModifiedBy',
            type: 'string'
        },
        {
            name: 'StatusCode',
            type: 'string'
        },
        {
            name: 'UnitsPerPackage',
            type: 'number'
        },
        {
            name: 'NumberofStore',
            type: 'int'
        },
        {
            name: 'NumberofRecommendedStore',
            type: 'int'
        },
        {
            name: 'OnHandInventoryStore',
            type: 'number'
        },
        {
            name: 'OnHandInventoryDC',
            type: 'number'
        },
        {
            name: 'OpenOrderStore',
            type: 'number'
        },
        {
            name: 'OpenOrderDC',
            type: 'number'
        },
        {
            name: 'OrderRecommendationParent',
            type: 'number'
        },
        {
            name: 'OrderRecommendationCurrent',
            type: 'number'
        },
        {
            name: 'OrderRecommendationChild',
            type: 'number'
        },
        {
            name: 'IsInclude',
            type: 'int',
            convert: function (v) {
                if (typeof v === 'boolean') {
                    return v ? 1 : 0;
                } else {
                    return parseInt(v, 10);
                }
            }
        }
    ],
    proxy: {
        type: 'ajax',
        actionMethods: {
            create: 'POST',
            read: 'POST',
            update: 'POST',
            destroy: 'POST'
        },
        api: {
            create: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/create',
            read: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/read',
            update: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/update',
            destroy: Spui.Global.getApiUrl() + 'spui/RecommendationForCenter/destroy',
        },
        writer: {
            rootProperty: 'data',
            allowSingle: false,
            successProperty: 'success',
            messageProperty: 'errors',
            allDataOptions: {
                associated: false
            },
            writeAllFields: true
        },
        // data: [{
        // 	spotId: this.getSpotId()
        // }],
        url: Spui.Global.getApiUrl() + 'spui/SmilarSpot',
    },
})
`
