import { Component, createRef, createElement, ChangeEvent } from 'react'
import _ from 'lodash'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  Col,
  Container,
  Navbar,
  FormControl,
  Row,
  Card,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll,
  getVisibleNodeCount
} from 'react-sortable-tree-patch-react-17'
import {
  categorizedJobs,
  reactVirtualizedListProps,
  externalNodeType,
  navbarHeight,
  btnStyle,
  jobsUsingCustomVariables
} from './constants'
import { SlvyDeleteAction, SlvyTreeNavbar } from '../../../../components'
import WorkSelect from './components/WorkSelect'
import WorkHeader from './components/WorkHeader'
import { getNodeKey } from './jobs/helpers'
import Jobs from './jobs'
import {
  canDrop,
  createWorkflow,
  customSearchMethod,
  checkIsNodeEmpty,
  getJobsWithIds,
  getJobTitle,
  getMenuListFromTree,
  getTreeFromWorks
} from './helpers'
import { getCustomVariables } from '@/helpers/mapper'
import { RowInfo, SortableTreeNode, TreeData, WorkFlowProps } from './Workflow.types'
import styles from './index.module.scss'

class WorkFlow extends Component {
  constructor(props: WorkFlowProps) {
    super(props)
    this.handleAddWork = this.handleAddWork.bind(this)
    this.handleSaveClose = this.handleSaveClose.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.onChangeWorkSelect = this.onChangeWorkSelect.bind(this)
    this.state = {
      active: {
        nodeId: null,
        treeIndex: null
      },
      searchString: '',
      searchFocusIndex: 0,
      searchFoundCount: null,
      treeData: getTreeFromWorks(props.works),
      treeWidth: reactVirtualizedListProps.width
    }
    this.treeRef = createRef()
    this.resizeObserver = null

    this.categorizedJobsWithIds = getJobsWithIds(categorizedJobs)
    this.jobsWithIds = getJobsWithIds(Jobs)
  }

  componentDidMount() {
    this.observeResize()
  }

  componentWillUnmount() {
    this.disconnect()
  }

  handleAddWork() {
    const { treeData } = this.state
    const name = 'New Work'
    const newTreeData = [...treeData].concat({
      title: name,
      // workName: name,
      item: { name },
      expanded: true,
      children: [
        {
          title: 'waitEvent',
          workName: name,
          item: {
            id: uuidv4(),
            name: 'waitEvent',
            params: {}
          }
        }
      ]
    })

    this.onTreeChange(newTreeData)
  }

  handleSave(params = {}) {
    const { treeData, currentNode, currentPath } = this.state
    const newNode = { ...currentNode, item: { ...currentNode.item, params } }
    const newTreeData = changeNodeAtPath({
      treeData,
      path: currentPath,
      getNodeKey,
      newNode
    })
    this.setState({
      treeData: newTreeData,
      currentNode: newNode
    })
    this.onTreeDataChange(newTreeData)
  }

  handleSaveClose(params = {}) {
    const { treeData, currentNode, currentPath } = this.state
    const newTreeData = changeNodeAtPath({
      treeData,
      path: currentPath,
      getNodeKey,
      newNode: { ...currentNode, item: { ...currentNode.item, params } }
    })
    this.setState({
      treeData: newTreeData,
      currentNode: null,
      currentPath: null
    })
    this.onTreeDataChange(newTreeData)
  }

  onEditWorkClick(rowInfo: RowInfo, node: RowInfo['node'], path: RowInfo['path']) {
    this.setState({
      active: {
        nodeId: rowInfo.node.item?.id ?? null,
        treeIndex: rowInfo.treeIndex
      },
      currentWorkName: node.workName,
      currentNode: node,
      currentPath: path
    })
  }

  onNodeChange(event: ChangeEvent<HTMLInputElement>, path: RowInfo['path'], node: RowInfo['node']) {
    const { treeData } = this.state
    const name = event.target.value
    const newTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: { ...node, title: name }
    })
    this.setState({ treeData: newTreeData })
    this.onTreeDataChange(newTreeData)
  }

  onChangeWorkSelect(configKey: SortableTreeNode['title'], path: RowInfo['path']) {
    const { treeData } = this.state

    const newNode = {
      title: configKey,
      // TODO: Find most parent work name
      // workName: this.state.treeData[path[0]].workName,
      item: {
        id: uuidv4(),
        name: configKey,
        params: {}
      }
    }
    const newTreeData = addNodeUnderParent({
      treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode,
      addAsFirstChild: true
    }).treeData

    this.setState({ treeData: newTreeData })

    this.onTreeDataChange(newTreeData)

    document.body.click()
  }

  onTreeDataChange(newTreeData: TreeData) {
    const { onChange } = this.props
    onChange(createWorkflow(newTreeData))
  }

  setSize({ width }: { width: number }) {
    if (width) {
      this.setState({ treeWidth: width })
    }
  }

  getRemoveWorkTpl(path: RowInfo['path'], node: RowInfo['node'], treeData: TreeData) {
    const body = (
      <>
        Are you sure you want to remove <strong>{getJobTitle(node.title)}</strong>?
      </>
    )
    return (
      <SlvyDeleteAction
        body={body}
        btnStyle={btnStyle}
        header="Remove Work"
        onCancel={() => document.body.click()}
        onDelete={() => this.removeWork(treeData, path)}
      />
    )
  }

  getSearchProps() {
    const { searchString, searchFoundCount } = this.state
    return {
      query: searchString,
      resultCount: searchFoundCount,
      onSelectNextMatch: this.onSelectNextMatch,
      onSelectPrevMatch: this.onSelectPrevMatch,
      onQueryChange: (newSearchString: string) => this.setState({ searchString: newSearchString })
    }
  }

  searchFinishCallback = (matches: Pick<RowInfo, 'node' | 'path' | 'treeIndex'>[]) => {
    const { searchFocusIndex } = this.state
    const searchFoundCount = matches.length
    this.setState({
      searchFoundCount,
      searchFocusIndex: searchFoundCount > 0 ? searchFocusIndex % searchFoundCount : 0
    })
  }

  toggleNodeExpansion = (expanded: boolean) => {
    const { treeData } = this.state
    const newTreeData = toggleExpandedForAll({
      treeData,
      expanded
    })
    this.setState({ treeData: newTreeData })
  }

  onTreeChange = (newTreeData: TreeData) => {
    this.setState({ treeData: newTreeData })
    this.onTreeDataChange(newTreeData)
  }

  onSelectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state
    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
    })
  }

  onSelectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state
    this.setState({
      searchFocusIndex: searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
    })
  }

  generateNodeProps = (rowInfo: RowInfo) => {
    const { treeData, active } = this.state

    const { node, lowerSiblingCounts, path } = rowInfo

    let isActive = false
    if (active.nodeId !== null) {
      isActive = active.nodeId === rowInfo.node?.item?.id
    } else if (active.treeIndex !== null) {
      isActive = active.treeIndex === rowInfo.treeIndex
    }

    const isFirstLevelPageReady = node.title === 'Page Ready'
    const isSecondLevelPageReady = node.title === 'pageReady'
    const isChangeConnection = node.title === 'changeConnection'

    if (_.size(lowerSiblingCounts) === 1) {
      return {
        title: (
          <FormControl
            disabled={isFirstLevelPageReady}
            size="xs"
            value={node.title}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              this.onNodeChange(event, path, node)
            }
          />
        ),
        buttons: [
          isFirstLevelPageReady ? null : (
            <div className="d-flex gap-2">
              <WorkSelect node={node} path={path} onChangeWorkSelect={this.onChangeWorkSelect} />
              {this.getRemoveWorkTpl(path, node, treeData)}
            </div>
          )
        ]
      }
    }

    const isNodeEmpty = checkIsNodeEmpty(node?.item)
    const jobTitle = getJobTitle(node.title)
    const title = isNodeEmpty ? (
      <span className={styles.emptyNode} title="Empty Item">
        <i className="fa fa-exclamation-triangle me-1" /> {jobTitle}
      </span>
    ) : (
      jobTitle
    )
    return {
      title,
      buttons: [
        <div className="d-flex gap-2">
          <WorkSelect path={path} onChangeWorkSelect={this.onChangeWorkSelect} />
          {isChangeConnection ? null : (
            <Button
              size="xs"
              style={btnStyle}
              title="Edit Work"
              variant={isActive ? 'outline-info' : 'info'}
              onClick={() => this.onEditWorkClick(rowInfo, node, path)}
            >
              <i className="fa fa-pen" />
            </Button>
          )}
          {isSecondLevelPageReady ? null : this.getRemoveWorkTpl(path, node, treeData)}
        </div>
      ]
    }
  }

  removeWork(treeData: TreeData, path: RowInfo['path']) {
    const newTreeData = removeNodeAtPath({
      treeData,
      path,
      getNodeKey
    })

    this.setState({
      active: {
        nodeId: null,
        treeIndex: null
      },
      currentWorkName: null,
      currentNode: null,
      currentPath: null,
      treeData: newTreeData
    })

    this.onTreeDataChange(newTreeData)

    document.body.click()
  }

  findJob(node: SortableTreeNode) {
    if (_.isNil(node)) return null
    const { currentPath = null } = this.state
    const {
      plugins,
      containers,
      menuTree,
      remoteActions,
      params: { catalogId },
      assignees,
      works
    } = this.props
    const {
      item: { name, params }
    } = node
    const job = _.find(Jobs, (_job, jobKey) => _.lowerCase(name) === _.lowerCase(jobKey))
    if (!job) return null

    const pluginsSorted = _.sortBy(plugins, (p) => {
      const { id, type, config: { general: { name = '' } = {} } = {} } = p
      return type + name + id
    })

    const containersSorted = _.sortBy(containers, (c) => {
      const { id, type, settings: { name } = {} } = c
      return type + name + id
    })

    const remoteActionsSorted = _.sortBy(remoteActions, (r) => {
      const { type, name } = r
      return type + name
    })

    const canUseCustomVariables = jobsUsingCustomVariables.some((jobName) => jobName === name)
    const customVariables = canUseCustomVariables ? getCustomVariables(works) : null

    return createElement(job.component, {
      key: (currentPath || [name]).toString(),
      assignees,
      catalogId,
      plugins: pluginsSorted,
      containers: containersSorted,
      menuTree: getMenuListFromTree(menuTree),
      remoteActions: remoteActionsSorted,
      params,
      customVariables,
      onSave: this.handleSave
    })
  }

  observeResize() {
    if (_.isEmpty(this.treeRef.current)) {
      return
    }

    this.disconnect()

    this.resizeObserver = new ResizeObserver((entries) => {
      const size = _.isEmpty(entries) ? reactVirtualizedListProps : entries[0].contentRect
      this.setSize(size)
    })

    this.resizeObserver.observe(this.treeRef.current)
  }

  disconnect() {
    this.resizeObserver?.disconnect?.()
  }

  render() {
    const { treeData, currentNode, searchString, searchFocusIndex, treeWidth, currentWorkName } =
      this.state

    reactVirtualizedListProps.width = treeWidth
    reactVirtualizedListProps.rowCount = getVisibleNodeCount({ treeData })

    return (
      <DndProvider backend={HTML5Backend}>
        <Container fluid className="p-0">
          <Row>
            <Col xs={6}>
              <Card className="m-0 border-solvoyo-widget rounded-0">
                <SlvyTreeNavbar
                  height={navbarHeight}
                  icon="code-fork"
                  searchProps={this.getSearchProps()}
                  title="Works"
                  onCollapse={() => this.toggleNodeExpansion(false)}
                  onExpand={() => this.toggleNodeExpansion(true)}
                >
                  <OverlayTrigger
                    delay={100}
                    overlay={<Tooltip id={currentNode?.item?.id}>Add Work</Tooltip>}
                    placement="top"
                  >
                    <Button
                      className="ms-auto me-2"
                      size="sm"
                      variant="success"
                      onClick={this.handleAddWork}
                    >
                      <i className="fa fa-plus" />
                    </Button>
                  </OverlayTrigger>
                </SlvyTreeNavbar>
                <Card.Body
                  ref={this.treeRef}
                  className="p-0 m-0 position-relative"
                  style={{ height: reactVirtualizedListProps.height }}
                >
                  <SortableTree
                    canDrop={canDrop}
                    dndType={externalNodeType}
                    generateNodeProps={this.generateNodeProps}
                    reactVirtualizedListProps={reactVirtualizedListProps}
                    searchFinishCallback={this.searchFinishCallback}
                    searchFocusOffset={searchFocusIndex}
                    searchMethod={customSearchMethod}
                    searchQuery={searchString}
                    treeData={treeData}
                    onChange={this.onTreeChange}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6}>
              <Card className="border-solvoyo-widget rounded-0">
                <Navbar
                  className="border-bottom bg-card-header text-white"
                  style={{ height: navbarHeight }}
                >
                  <Container fluid className="px-2">
                    <Navbar.Brand as="span" className="p-0">
                      <WorkHeader node={currentNode} workName={currentWorkName} />
                    </Navbar.Brand>
                  </Container>
                </Navbar>
                <Card.Body
                  className="m-0 overflow-auto p-0"
                  style={{ height: reactVirtualizedListProps.height }}
                >
                  {this.findJob(currentNode)}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </DndProvider>
    )
  }
}
export default WorkFlow
