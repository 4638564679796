import { STORE_HEADER_SETTINGS } from '../actions/actionTypes'

const initialState = {
  headerSettings: {
    updateMode: 0,
    mode: 0,
    editMode: {
      format: 'absolute'
    }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_HEADER_SETTINGS:
      return {
        headerSettings: {
          ...state.headerSettings,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default reducer
