import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cloneDeep, filter, find, forEach, isEmpty, isNil } from 'lodash'
import SlvyGridComponent from './SlvyGridComponent'
import { objectToArray } from '../../utils'
import { slvyToast } from '../../../../components'
import { confirmAlert } from 'react-confirm-alert'
import ContextMenu from './ContextMenu'
import { setLoaderReducer } from '../../store/slices/appSlice'
import {
  selectOption,
  setIndex,
  setOptionUpdateList,
  setPoints,
  setSelectedSegmentId,
  setShow,
  setShowActiveStores,
  setTarget
} from '../../store/slices/options'
import { toggleSimilarOptions } from '../../store/slices/similarOptions'

const selectFromState = (state) => ({
  errorRootOptions: state.options.error,
  index: state.options.index,
  isLoading: state.options.isLoading,
  optionUpdateList: state.options.optionUpdateList,
  rootOptions: state.options.rootOptions,
  selectType: state.options.selectType
})

function OldViewComponent(props) {
  const dispatch = useDispatch()

  const { errorRootOptions, index, isLoading, optionUpdateList, rootOptions, selectType } =
    useSelector(selectFromState)
  const { AssortmentId, Options = [] } = rootOptions

  const {
    culture,
    pluginId,
    containerRef,
    onSetSelectType = () => {},
    show,
    onTestProductsToggle = () => {},
    onUpdateOption = () => {},
    onDeleteTestOption = () => {},
    onSelectAllSegments = () => {},
    environment,
    token
  } = props

  const [showSettings, setShowSettings] = useState(false)
  const [showCPP, setShowCPP] = useState(false)
  const [optionItem, setOptionItem] = useState({})
  const [basePriceInputId, setBasePriceInputId] = useState('')
  const [isFutureOption, setIsFutureOption] = useState(false)
  const [assortmentOptions, setAssortmentOptions] = useState(Options)
  const [assortmentId, setAssortmentId] = useState(AssortmentId)
  // TODO check isTestProduct states not working
  // const [isTestProduct, setIsTestProduct] = useState(false)
  useEffect(() => {
    if (!isEmpty(rootOptions)) {
      setAssortmentOptions(rootOptions.Options)
      setAssortmentId(rootOptions, AssortmentId)
    }
  }, [rootOptions])

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoaderReducer({ isShown: true, messages: 'Update Option' }))
    } else {
      dispatch(setLoaderReducer({ isShown: false, messages: 'Update Option' }))
    }
  }, [isLoading])

  useEffect(() => {
    if (!isNil(optionItem) && index > 0) {
      setBasePriceInputId(`BasePrice-Input-${optionItem.Id}-${index}`)
    }
  }, [showSettings, index])

  useEffect(() => {
    setIsFutureOption(optionItem.Editable)
    // setIsTestProduct(optionItem.IsTestProduct)
  }, [optionItem])

  useEffect(() => {
    if (
      show &&
      !isEmpty(rootOptions) &&
      assortmentOptions &&
      assortmentOptions.length &&
      index > 0
    ) {
      setOptionItem(assortmentOptions[index - 1])
    }
  }, [show, index, selectType, assortmentOptions])

  const handleClickUpdateOption = (optionId, updateList) => {
    const selectedOptionUpdateList = filter(updateList, (item) => {
      return item.optionId === optionId
    })
    const OptionStr = find(cloneDeep(Options), { Id: optionId })
    const newOptionStr = { ...OptionStr }
    const { Editable = false } = newOptionStr // isFutureOption

    // TODO check error cases
    if (!Editable) {
      slvyToast.error({
        message: 'Option can not be assigned. It is not a future option!',
        title: 'Assign Option'
      })
      return
    }

    forEach(selectedOptionUpdateList, (item) => {
      newOptionStr.SegmentOptions[item.SegmentId].Enabled = item.Enabled
    })

    onUpdateOption({ AssortmentId, OptionStr: newOptionStr })
    const removedOptionUpdateList = filter(updateList, (item) => {
      return item.optionId !== optionId
    })
    dispatch(setOptionUpdateList(removedOptionUpdateList))
  }

  const handleSelectAllSegmentsClick = (rowData, selectType) => {
    if (!rowData.SizeRangeId) {
      slvyToast.warning({
        message: 'Please enter a valid Size Range Type.',
        title: 'Missing Size Range Type'
      })
      return
    }
    if (!rowData.Editable) {
      slvyToast.error({ message: 'Option can not select/unselect!' })
      return
    }

    onSelectAllSegments(rowData, selectType)
  }

  const getProductMenuItems = (row, selection) => {
    const { original: rowData } = row
    return [
      {
        name: 'Settings',
        icon: 'fa fa-fw fa slvy-ui-icon-cog',
        onClick: (event) => {
          setShowSettings(true)
        }
      },
      {
        name: 'Copy Planning Parameters',
        icon: 'fa fa-fw fa fa-files-o',
        onClick: (event) => {
          if (!rowData.Editable) {
            slvyToast.error({ message: 'Option can not copy planning parameters!' })
            return
          }
          setShowCPP(true)
        }
      },
      {
        name: `${selection} All Segments`,
        icon:
          selection === 'Select' ? 'fa fa-fw fa fa-check-square' : 'fa fa-fw fa fa-window-close-o',
        onClick: (event) => {
          handleSelectAllSegmentsClick(rowData, selection)
        }
      },
      {
        name: 'Edit Product',
        icon: 'fa fa-fw fa fa-pencil',
        hidden: !rowData.IsTestProduct,
        disabled: !rowData.IsTestProduct,
        onClick: (event) => {
          dispatch(selectOption(rowData))
          onTestProductsToggle(true, 'Edit')
        }
      },
      {
        name: 'Delete Product',
        icon: 'fa fa-fw fa fa-trash',
        hidden: !rowData.IsTestProduct,
        disabled: !rowData.IsTestProduct,
        onClick: (event) => {
          onDeleteTestOption(rowData)
        }
      }
    ]
  }

  const getSegmentColumnItems = (columnHeader, row, optionList, column) => {
    const {
      original: rowData,
      original: { Id, SegmentOptions }
    } = row
    const foundedSegmentRow = find(SegmentOptions, { SegmentName: columnHeader })
    let segmentEnabledCurrent = false

    if (foundedSegmentRow) {
      const { Enabled = false, SegmentId } = foundedSegmentRow
      segmentEnabledCurrent = Enabled
      forEach(optionUpdateList, (item) => {
        if (item.optionId === Id && item.SegmentId === SegmentId) {
          segmentEnabledCurrent = item.Enabled
        }
      })
      return [
        { name: columnHeader, className: 'old-view-grid-context-menu-item-header' },
        {
          name: 'Settings',
          icon: 'fa fa-fw fa slvy-ui-icon-cog',
          onClick: (event) => {
            setShowSettings(true)
          }
        },
        {
          name: 'Active Stores',
          icon: 'fa fa-fw fa fa-university',
          onClick: (event) => {
            dispatch(setShowActiveStores(true))
            dispatch(setSelectedSegmentId(SegmentId))
          }
        },
        {
          name: 'Similar Products',
          icon: 'fa fa-fw fa slvy-ui-icon-diff',
          hidden: !segmentEnabledCurrent,
          onClick: (event) => {
            dispatch(selectOption(rowData))
            dispatch(setSelectedSegmentId(SegmentId))
            getSimilarOptions(Id, Enabled, optionList)
          }
        }
      ]
    }
    return []
  }

  const getContextMenuItems = ({ column, row }, optionList) => {
    const rowIndex = row?.index || -1
    if (rowIndex <= 0) return

    const filteredOptionList = optionList.filter(({ optionId }) => row.original.Id === optionId)

    const isAllSelected =
      filteredOptionList.length === Object.keys(row.original.SegmentOptions).length
    const selection = onSetSelectType(
      isAllSelected ? filteredOptionList : row.original.SegmentOptions
    )
    setContextMenuRowIndex(rowIndex)
    const columnHeader = column?.columnDef?.header || ''
    if (columnHeader === 'Summary' || columnHeader === 'Product') {
      return getProductMenuItems(row, selection)
    }
    return getSegmentColumnItems(columnHeader, row, optionList, column)
  }

  const getSimilarOptions = (optionId, segmentEnabled, optionList) => {
    if (segmentEnabled) {
      dispatch(toggleSimilarOptions(true))
    } else {
      confirmAlert({
        title: 'Save Changes',
        message:
          'There are unsaved changes on this option. You have to save them before continue. Do you want to proceed?',
        buttons: [
          {
            label: 'Cancel',
            onClick: () => false
          },
          {
            label: 'Save',
            onClick: () => {
              handleClickUpdateOption(optionId, optionList)
              dispatch(toggleSimilarOptions(true))
            }
          }
        ]
      })
    }
  }

  const onSetShowSettings = (isShown) => {
    setShowSettings(isShown)
  }

  const setContextMenuRowIndex = (index) => {
    dispatch(setIndex(index))
    dispatch(setShow(true))
  }

  return (
    <>
      <ContextMenu
        assortmentId={assortmentId}
        assortmentOptions={assortmentOptions}
        culture={culture}
        isFutureOption={isFutureOption}
        optionItem={optionItem}
        pluginId={pluginId}
        setShowCPP={setShowCPP}
        setShowSettings={onSetShowSettings}
        showCPP={showCPP}
        showSettings={showSettings}
        onUpdateOption={onUpdateOption}
      />
      <SlvyGridComponent
        environment={environment}
        getContextMenuItems={(params) => getContextMenuItems(params, optionUpdateList)}
        handleClickUpdateOption={handleClickUpdateOption}
        pluginId={pluginId}
        token={token}
      />
    </>
  )
}

export default OldViewComponent
