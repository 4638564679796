import cx from 'classnames'
import { parseTwitterDate } from '@/helpers/datetime'
import { DockItemProps } from './DockItem.types'
import styles from './index.module.scss'

const DockItem = ({
  flag,
  title,
  message,
  objKey,
  date,
  onCopyToClipboard,
  onDoubleClick,
  forwardRef
}: DockItemProps) => (
  <tr className={styles.highlight}>
    <td>
      <span className="badge text-white bg-success">{flag}</span>
    </td>
    <td onDoubleClick={onDoubleClick}>{title}</td>
    <td className="w-50">
      <textarea
        ref={(textarea: HTMLTextAreaElement) => {
          forwardRef(objKey, textarea)
        }}
        className={cx(
          'position-absolute',
          'top-0',
          'opacity-0',
          'w-0',
          'h-0',
          styles.clipboardInput
        )}
        defaultValue={message}
      />
      <small
        dangerouslySetInnerHTML={{
          __html: message && _.isString(message) ? message.substring(0, 400) : ''
        }}
        onClick={() => onCopyToClipboard(objKey)}
      />
    </td>
    <td>
      <small>{parseTwitterDate(date.format())}</small>
    </td>
  </tr>
)

export default DockItem
