import { isEqual } from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelectorCreator, lruMemoize } from 'reselect'
import { layout as layoutSchema } from '../schema'
import { Config } from '../ChronoTimeline.types'

const selectRawLayoutConfig = (layoutConfig?: Config['layout']) => layoutConfig

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

const selectLayoutConfigs = createDeepEqualSelector(selectRawLayoutConfig, (layoutConfig) => {
  const mode = layoutConfig?.mode ?? layoutSchema.properties.mode.default
  const flipLayout = layoutConfig?.flipLayout ?? layoutSchema.properties.flipLayout.default
  const cardLess = layoutConfig?.cardLess ?? layoutSchema.properties.cardLess.default

  return {
    mode,
    flipLayout,
    cardLess
  } satisfies Config['layout']
})

export default selectLayoutConfigs
