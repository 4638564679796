import React, { Component } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Row, Col, Modal, Form, Button, FormGroup, Tooltip, OverlayTrigger } from 'react-bootstrap'
import cx from 'classnames'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { SlvyFormCheckbox, SlvySelect, SlvySpinner, slvyToast } from '@/components'
import './style.scss'

class DataImport extends Component {
  constructor(props) {
    super(props)

    this._initState = {
      show: false,
      hasHeaderRow: true,
      fileUploaded: false,
      fileName: null,
      fileUploadFailedMessage: null,
      sheets: [],
      selectedSheet: null,
      sheetColumns: [],
      mappedSheetColumns: {},
      tableName: '',
      data: [],
      progress: false,
      importableRowCount: 0,
      notImportableRowCount: 0,
      warnedRowsCount: 0,
      hideForm: false
    }

    this.state = {
      hideForm: false
    }

    this.state = this._initState

    this.onSheetSelected = this.onSheetSelected.bind(this)
    this.handleShow = this.handleShow.bind(this)
  }

  onResetState(callback = () => {}) {
    this.setState(this._initState, callback)
  }

  onShowModal() {
    this.onResetState(() => {
      this.setState({
        show: true,
        hideForm: false
      })
    })
  }

  handleShow() {
    this.setState({ show: true })
  }

  onFileSelected(e) {
    if (e.target.files[0]) {
      const me = this
      const { id, clientWithProgress } = this.props
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      clientWithProgress
        .post(`/import/plugin/${id}/UploadFile`, { data: formData })
        .progress(
          function (e) {
            this.setState({ progress: true })
          }.bind(this)
        )
        .then(function (res) {
          const response = res
          if (response.success) {
            me.setState({
              fileUploaded: true,
              fileName: response.fileName,
              sheets: response.sheets,
              fileUploadFailedMessage: null,
              progress: false
            })

            if (response.sheets.length === 1) {
              me.onSheetSelected({ value: response.sheets[0] })
            }
          } else {
            me.setState({
              fileUploadFailedMessage: response.message,
              progress: false
            })
          }
        })
        .catch(function (err) {
          console.log('error', err)
          slvyToast.warning({ message: 'Something went wrong', title: 'There is a problem' })
          me.setState({
            progress: false
          })
        })
    }
  }

  toggleHasHeaderRow() {
    this.setState({
      hasHeaderRow: !this.state.hasHeaderRow,
      selectedSheet: null,
      mappedSheetColumns: {}
    })
  }

  onSheetSelected(option = {}) {
    const selectedSheet = option?.value
    if (_.isEmpty(selectedSheet) || selectedSheet === this.state.selectedSheet) {
      return
    }

    this.setState(
      {
        selectedSheet,
        mappedSheetColumns: {}
      },
      this.getSheetColumns
    )
  }

  getSheetColumns() {
    const {
      id,
      clientWithProgress,
      settings: {
        config: { columns }
      }
    } = this.props
    const { selectedSheet, fileName, hasHeaderRow } = this.state

    clientWithProgress
      .post(`/import/plugin/${id}/GetSheetColumns`, {
        data: {
          SheetName: selectedSheet,
          FileName: fileName,
          HasHeaderRow: hasHeaderRow
        }
      })
      .progress(() => {
        this.setState({ progress: true })
      })
      .then((res) => {
        const response = res
        const { mappedSheetColumns } = this.state

        if (hasHeaderRow) {
          columns.forEach((columnContainer) => {
            const columnName = columnContainer.column
            response.columns.forEach((excelColumn) => {
              if (
                columnName.toLowerCase().replace(/[^\w]/gi, '') ===
                excelColumn.toLowerCase().replace(/[^\w]/gi, '')
              ) {
                mappedSheetColumns[columnName] = excelColumn
              }
            })
          })
        } else {
          columns.forEach((columnContainer, index) => {
            mappedSheetColumns[columnContainer.column] = response.columns[index]
          })
        }

        this.setState({
          sheetColumns: response.columns,
          mappedSheetColumns,
          progress: false
        })
      })
      .catch(() => {
        slvyToast.warning({ message: 'Something went wrong', title: 'There is a problem' })
        this.setState({
          progress: false
        })
      })
  }

  onTemporaryImport(e) {
    const me = this
    const {
      id,
      clientWithProgress,
      settings: { config: { columns = [], parameterDefinitions = [] } = {} } = {},
      actualFilters = {}
    } = me.props
    this.setState({
      hideForm: true,
      progress: true
    })

    const parameters = {}
    _.forEach(parameterDefinitions, (item) => {
      parameters[item.parameterName] = item.value
    })

    clientWithProgress
      .post(`/import/plugin/${id}/TemporaryImport`, {
        data: {
          SheetName: me.state.selectedSheet,
          FileName: me.state.fileName,
          HasHeaderRow: me.state.hasHeaderRow,
          ColumnMapping: me.state.mappedSheetColumns,
          Columns: columns,
          parameterDefinitions: parameters,
          filters: actualFilters
        }
      })
      .then(function (res) {
        const response = res

        me.setState({
          tableName: response.tablename,
          data: response.result,
          progress: false,
          importableRowCount: response.importablerowcount,
          notImportableRowCount: response.notimportablerowcount,
          warnedRowsCount: response.warnedrowscount
        })
      })
      .catch(function (err) {
        console.log('error', err)
        let message = 'Something went wrong'

        if (err && err.displayMessage) {
          message = err.message
        }
        slvyToast.warning({ message, title: 'There is a problem' })
        me.setState({
          progress: false
        })
      })
  }

  onCancelImport() {
    this.setState({
      show: false
    })
  }

  onGetNotEmportableDataExcel() {
    const me = this
    const { id, clientWithProgress } = me.props

    clientWithProgress
      .get(`/import/plugin/${id}/ExportNotimportableData/${me.state.tableName}`)
      .progress(
        function (e) {
          this.setState({ progress: true })
        }.bind(this)
      )
      .then(function (res) {
        const response = res
        me.setState({
          progress: false
        })

        const a = document.createElement('A')
        a.href = response
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        if (response) {
          slvyToast.success({
            message: `Download completed succesfully.`,
            title: `Operation Detail`
          })
        } else {
          alert('Download could not be completed.')
        }
      })
      .catch(function (err) {
        console.log('error', err)
        slvyToast.warning({ message: 'Something went wrong', title: 'There is a problem' })
        me.setState({
          progress: false
        })
      })
  }

  onApproveImport() {
    const me = this
    const {
      id,
      clientWithProgress,
      settings: { config: { parameterDefinitions = [] } = {} } = {},
      actualFilters = {}
    } = me.props

    const parameters = {}
    _.forEach(parameterDefinitions, (item) => {
      parameters[item.parameterName] = item.value
    })

    this.setState({ progress: true })
    clientWithProgress
      .post(`/import/plugin/${id}/Import`, {
        data: {
          tableName: me.state.tableName,
          parameterDefinitions: parameters,
          filters: actualFilters
        }
      })
      .then(
        function (res) {
          const response = res
          me.setState({
            progress: false
          })
          if (response) {
            this.importCompleted()
            slvyToast.success({
              message: `Import completed succesfully.`,
              title: `Operation Detail`
            })

            me.onResetState()
          } else {
            alert('Upload could not be completed.')
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log('error', err)
        slvyToast.warning({ message: 'Something went wrong', title: 'There is a problem' })
        me.setState({
          progress: false
        })
      })
  }

  onColumnMappingChanged(columnName, option) {
    const { mappedSheetColumns } = this.state
    const { value = '' } = option ?? {}

    if (!value || value === '') {
      if (mappedSheetColumns.hasOwnProperty(columnName)) {
        delete mappedSheetColumns[columnName]
      } else return
    } else {
      mappedSheetColumns[columnName] = value
    }

    this.setState({
      mappedSheetColumns
    })
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'openImportModal',
      fn: this.handleShow,
      args: {}
    })
    this.importCompleted = this.props.registerEvent({
      key: 'importCompleted',
      fn: this.importCompleted.bind(this),
      returnTypes: {
        refreshKey: PluginTypes.string,
        ImportCompleted: PluginTypes.boolean
      }
    })
  }

  importCompleted() {
    return { refreshKey: uuidv4(), ImportCompleted: true }
  }

  checkFields(columns, mappedSheetColumns) {
    let fieldsVerification = true
    _.forEach(columns, (item) => {
      if (item.isRequired) {
        if (!mappedSheetColumns[item.column]) {
          fieldsVerification = false
          return false
        }
      }
    })
    return fieldsVerification
  }

  render() {
    const me = this
    const {
      sheets,
      hasHeaderRow,
      selectedSheet,
      sheetColumns,
      mappedSheetColumns,
      tableName,
      data,
      fileUploadFailedMessage,
      progress,
      importableRowCount = 0,
      notImportableRowCount = 0,
      warnedRowsCount = 0,
      hideForm
    } = this.state
    const showSheets = sheets.length > 0

    const singleItem = data.length > 0 ? data[0] : {}

    const {
      settings: {
        config: {
          columns = [],
          general: { hidden = false } = {},
          importButton: {
            showButton = 'true',
            buttonText = '',
            style = 'primary',
            icon = 'fa-check',
            iconPosition = 'Left'
          } = {},
          finalImportButton: { buttonText: finalImportButtonText = '' } = {}
        } = {}
      } = {},
      params: { environment }
    } = this.props || {}

    const iconClassName = `fa ${icon}`

    const tableHeaders = (
      <tr>
        <th style={{ width: 20, paddingRight: 30 }} />
        {Object.keys(singleItem).reduce(
          (acc, k, innerIndex) =>
            k !== 'IsImportable' && k !== 'ErrorCode'
              ? [...acc, <th key={innerIndex}>{k}</th>]
              : acc,
          []
        )}
      </tr>
    )

    const tableWidth = singleItem.length * 100 + 50

    const tableRows = data.map(function (p, i) {
      const tooltip = (
        <Tooltip id="tooltip" style={{ zIndex: 9999999999 }}>
          {p.ErrorCode}
        </Tooltip>
      )
      const toolnot = <Tooltip id="tooltip" />
      return (
        <tr
          key={i}
          style={{
            backgroundColor: p.IsImportable ? 'white' : 'rgb(253, 228, 200)'
          }}
        >
          <td
            style={{
              width: 20,
              paddingRight: 30
            }}
          >
            <OverlayTrigger
              overlay={!p.IsImportable ? tooltip : p.ErrorCode ? tooltip : toolnot}
              placement="top"
            >
              <i
                className={
                  p.IsImportable
                    ? !p.ErrorCode
                      ? 'fa fa-check'
                      : 'fa fa-warning'
                    : 'fa fa-exclamation-circle'
                }
                style={p.IsImportable && p.ErrorCode ? { color: 'orange' } : {}}
              />
            </OverlayTrigger>
          </td>
          {Object.keys(p).reduce(
            (acc, k, innerIndex) =>
              k !== 'IsImportable' && k !== 'ErrorCode'
                ? [
                    ...acc,
                    <td
                      key={innerIndex}
                      className="text-overflow"
                      style={
                        p.ErrorCode && _.includes(p.ErrorCode, `'${k}'`)
                          ? { backgroundColor: '#ff000054' }
                          : {}
                      }
                    >
                      {p[k]}
                    </td>
                  ]
                : acc,
            []
          )}
        </tr>
      )
    })

    const sheetColumnsOptions = sheetColumns.map((sheetColumn) => ({
      label: sheetColumn,
      value: sheetColumn
    }))

    const mappedColumns = columns.map(function (columnContainer, index) {
      const value = mappedSheetColumns[columnContainer.column]
      const selectedColumn = value ? { label: value, value } : null
      return (
        <FormGroup
          key={index}
          as={Row}
          className={cx('form-group', 'mb-3', {
            'is-invalid text-danger':
              !mappedSheetColumns[columnContainer.column] && columnContainer.isRequired
          })}
        >
          <Form.Label column className="fw-bold d-flex justify-content-end" sm={2}>
            {columnContainer.column}
            {columnContainer.isRequired ? ' *' : ''}
          </Form.Label>
          <Col sm={10}>
            <SlvySelect
              isClearable
              options={sheetColumnsOptions}
              placeholder=""
              value={selectedColumn}
              onChange={me.onColumnMappingChanged.bind(me, columnContainer.column)}
            />
          </Col>
        </FormGroup>
      )
    })

    const sheetOptions = sheets.map((sheet) => ({ label: sheet, value: sheet }))
    const defaultOption = selectedSheet ? { label: selectedSheet, value: selectedSheet } : null
    return (
      <div className={cx('static-modal', 'data-import', { 'p-1': !hidden })}>
        {showButton ? (
          <Button
            className="buttonFilter"
            type="submit"
            variant={style}
            onClick={this.onShowModal.bind(this)}
          >
            {iconPosition === 'Left' ? <i className={iconClassName} /> : null}
            {` ${!_.isEmpty(buttonText) ? buttonText : ''}`}
            {iconPosition === 'Right' ? <i className={iconClassName} /> : null}{' '}
          </Button>
        ) : (
          environment === 'Configuration' && <i>{buttonText}</i>
        )}
        <Modal
          enforceFocus={false}
          show={this.state.show}
          size="lg"
          onHide={this.onCancelImport.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title" id="contained-modal-title-lg">
              Import Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
              <div className="form-horizontal form-component">
                {!hideForm && (
                  <FormGroup as={Row} className="form-group mb-3">
                    <Form.Label column className="fw-bold d-flex justify-content-end" sm={2}>
                      File
                    </Form.Label>
                    <Col sm={10}>
                      <input
                        accept=".xlsx"
                        className="form-control"
                        type="file"
                        onChange={this.onFileSelected.bind(this)}
                      />
                      <div
                        className="bg-danger"
                        style={{
                          display: fileUploadFailedMessage ? 'block' : 'none'
                        }}
                      >
                        {fileUploadFailedMessage}
                      </div>
                    </Col>
                  </FormGroup>
                )}

                {!hideForm && (
                  <div style={{ display: showSheets ? 'block' : 'none' }}>
                    <SlvyFormCheckbox
                      isChecked={hasHeaderRow}
                      label="Header Row"
                      onChange={this.toggleHasHeaderRow.bind(this)}
                    />
                    <FormGroup as={Row} className="form-group mb-3">
                      <Form.Label column className="fw-bold d-flex justify-content-end" sm={2}>
                        Sheet
                      </Form.Label>
                      <Col sm={10}>
                        <SlvySelect
                          isClearable
                          defaultValue={defaultOption}
                          options={sheetOptions}
                          placeholder=""
                          onChange={this.onSheetSelected}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                )}

                <div
                  style={{
                    display: sheetColumns.length > 0 && !data.length > 0 ? 'block' : 'none'
                  }}
                >
                  <FormGroup
                    as={Row}
                    className="form-group mb-3"
                    style={{
                      display:
                        Object.keys(mappedSheetColumns).length !== columns.length ? 'flex' : 'none'
                    }}
                  >
                    <Form.Label column sm={2} />
                    <Col sm={10}>
                      <div className="bg-info">Please select appropriate mapping below.</div>
                    </Col>
                  </FormGroup>

                  {mappedColumns}
                </div>

                <FormGroup
                  as={Row}
                  className="form-group mb-3"
                  style={{ display: data.length > 0 ? 'flex' : 'none' }}
                >
                  <Form.Label column className="fw-bold d-flex justify-content-end" sm={2}>
                    Preview
                  </Form.Label>
                  <Col className="data-import-plugin-table" sm={9}>
                    <table className="table table-striped" style={{ width: `${tableWidth}px` }}>
                      <thead>{tableHeaders}</thead>
                      <tbody>{tableRows}</tbody>
                    </table>
                  </Col>
                </FormGroup>

                {data.length > 0 && (
                  <FormGroup as={Row} className="form-group mb-3">
                    <Form.Label column className="fw-bold d-flex justify-content-end" sm={2}>
                      Summary
                    </Form.Label>
                    <Col sm={10}>
                      <Row>
                        <div className="m-t-sm summary-ctn">
                          <div>
                            <i className="fa fa-table" />
                            Total Rows in Excel:{' '}
                            <strong>{importableRowCount + notImportableRowCount}</strong>
                          </div>
                          <div>
                            <i className="fa fa-check" />
                            Success: <strong>{importableRowCount}</strong>
                          </div>
                          {warnedRowsCount > 0 && (
                            <div>
                              <i className="fa fa-warning" style={{ color: 'orange' }} />
                              Warning: <strong>{warnedRowsCount}</strong>
                            </div>
                          )}
                          <div>
                            <i className="fa fa-exclamation-circle" />
                            Failed:{' '}
                            <strong>
                              {notImportableRowCount}
                              {notImportableRowCount > 0 && (
                                <i
                                  className="fa fa-download "
                                  style={{
                                    color: '#5cb85c',
                                    paddingLeft: '10px',
                                    cursor: 'pointer'
                                  }}
                                  title="Download failed data"
                                  onClick={this.onGetNotEmportableDataExcel.bind(this)}
                                />
                              )}
                            </strong>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </FormGroup>
                )}
              </div>
            </form>
            {progress && <SlvySpinner />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.onCancelImport.bind(this)}>
              Cancel
            </Button>
            <Button
              className="btn"
              disabled={
                !selectedSheet || !this.checkFields(columns, mappedSheetColumns) || progress
              }
              variant={tableName ? 'success' : 'info'}
              onClick={
                tableName ? this.onApproveImport.bind(this) : this.onTemporaryImport.bind(this)
              }
            >
              {tableName
                ? !_.isEmpty(finalImportButtonText)
                  ? finalImportButtonText
                  : 'Import'
                : 'Preview'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  settings: props.settings,
  id: props.id,
  clientWithProgress: props.clientWithProgress,
  actualFilters: props.actualFilters,
  params: props.params
})

export default createPlugin(DataImport, selectConnectorProps)
