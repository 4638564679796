Ext.define('po.cmp.InventoryGrid', {
  extend: 'Ext.grid.Panel',
  alias: 'widget.poInventoryGrid',
  columnLines: true,
  header: false,
  bodyStyle: {
    background: '#fff'
  },
  columns: [
    {
      dataIndex: 'tarih',
      width: 95,
      sortable: false,
      bind: {
        text: '{i18n.InventoryGrid_Column_tarih}',
        tooltip: '{i18n.InventoryGrid_Column_tarih_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'Envanter',
      width: 80,
      sortable: false,
      align: 'right',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },
      bind: {
        text: '{i18n.InventoryGrid_Column_Envanter}',
        tooltip: '{i18n.InventoryGrid_Column_Envanter_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'NetEnvater',
      width: 80,
      sortable: false,
      align: 'right',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },
      bind: {
        text: '{i18n.InventoryGrid_Column_NetEnvater}',
        tooltip: '{i18n.InventoryGrid_Column_NetEnvater_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'Acilan_Miktar',
      width: 85,
      sortable: false,
      align: 'right',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },
      bind: {
        text: '{i18n.InventoryGrid_Column_Acilan_Miktar}',
        tooltip: '{i18n.InventoryGrid_Column_Acilan_Miktar_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'Kapanan_Miktar',
      width: 85,
      sortable: false,
      align: 'right',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },
      bind: {
        text: '{i18n.InventoryGrid_Column_Kapanan_Miktar}',
        tooltip: '{i18n.InventoryGrid_Column_Kapanan_Miktar_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'Magaza_Talep',
      width: 85,
      sortable: false,
      align: 'right',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },
      bind: {
        text: '{i18n.InventoryGrid_Column_Magaza_Talep}',
        tooltip: '{i18n.InventoryGrid_Column_Magaza_Talep_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'EnvanterSifir',
      width: 85,
      sortable: false,
      align: 'right',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },
      bind: {
        text: '{i18n.InventoryGrid_Column_EnvanterSifir}',
        tooltip: '{i18n.InventoryGrid_Column_EnvanterSifir_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'TeminSuresi',
      width: 80,
      sortable: false,
      align: 'right',
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },
      bind: {
        text: '{i18n.InventoryGrid_Column_TeminSuresi}',
        tooltip: '{i18n.InventoryGrid_Column_TeminSuresi_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      dataIndex: 'BirSonrakiTeslim',
      width: 85,
      align: 'right',
      sortable: false,
      renderer: function (value, summaryData, dataIndex) {
        return Ext.util.Format.number(value, '0,000')
      },

      bind: {
        text: '{i18n.InventoryGrid_Column_BirSonrakiTeslim}',
        tooltip: '{i18n.InventoryGrid_Column_BirSonrakiTeslim_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    }
  ]
})
