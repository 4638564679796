import { delay, put } from 'redux-saga/effects'
import { addMessage } from '../../../store/slices/logger'

export default function* pageReady(props, result) {
  const { time } = props
  yield delay(time)
  if (window.ctrlshiftk) {
    yield put(addMessage({ flag: 'Delay', title: `${time} ms` }))
  }
  return result
}
