import { fetchCollection, updateOne, deleteRecord, fetchOne, fetchRecord } from '../crudoptV3'

const model = 'menu'

export const getMenus = (catalogId) => {
  return fetchCollection(
    model,
    `/catalog/${catalogId}/menu`,
    {},
    {
      getRowKey: (row, index) => row.item.id
    }
  )
}

export const getMenu = (catalogId, menuId) => {
  return fetchOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}`,
    {},
    {
      key: menuId,
      mapResult: (result) => {
        return { item: result, children: [] }
      }
    }
  )
}

export const createMenu = (catalogId, data = {}) => {
  return fetchRecord(
    model,
    `/catalog/${catalogId}/menu`,
    { data },
    {
      mapResult: (result) => {
        return { item: result, children: [] }
      },
      collectionKey: { path: `/catalog/${catalogId}/menu` }
    }
  )
}

export const updateMenu = (catalogId, menuId, data = {}) => {
  return updateOne(
    model,
    `/catalog/${catalogId}/menu/${menuId}`,
    { data },
    {
      key: menuId,
      mapResult: (result) => {
        return { item: result, children: data.children }
      },
      collectionKey: { path: `/catalog/${catalogId}/menu` }
    }
  )
}

export const moveMenu = (catalogId, menuId, data) => {
  return updateOne(model, `/catalog/${catalogId}/menu/${menuId}/move`, { data })
}

export const deleteForceMenu = (catalogId, menuId) => {
  return deleteRecord(
    model,
    `/catalog/${catalogId}/menu/${menuId}/force`,
    {},
    {
      key: menuId,
      collectionKey: { path: `/catalog/${catalogId}/menu` },
      getRowKey: () => menuId
    }
  )
}
