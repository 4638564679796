export default {
  title: 'ButtonGroup',
  subTitle: 'ButtonGroup',
  version: '1.0',
  namespace: 'ButtonGroup',
  description: 'Group of buttons. Bootstrap buton group components are used.',
  iconClass: 'fa-qrcode',
  color: 'lazur-bg',
  group: 'Control'
}
