import _ from 'lodash'
import { STORE_PIVOT_MAIN_CONFIGURATION } from '../actions/actionTypes'

const initialState = {
  pivotMainConfiguration: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PIVOT_MAIN_CONFIGURATION:
      return {
        pivotMainConfiguration: {
          ...state.pivotMainConfiguration,
          ..._.cloneDeep(action.payload)
        }
      }
    default:
      return state
  }
}

export default reducer
