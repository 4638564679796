Ext.define('tuiv2.MainView', {
  extend: 'Ext.panel.Panel',
  alias: 'widget.tuiv2mainView',
  xtype: 'tuiv2mainview',
  cls: 'tuiv2mainview',
  viewModel: 'tuiv2MainViewModel',
  controller: 'tuiv2MainController',
  layout: 'border',
  requires: ['tuiv2.tuiv2MainController', 'tuiv2.MainViewModel'],
  config: {
    loadTreeColumns: null,
    loadTreeHierarchy: [],
    loadHierarchyHash: null,
    loadTreeFilters: [],
    loadFooterButtons: [],
    demandFooterButtons: [],
    demandTotalFields: [],
    DemandTreeAllColumns: [],
    loadStopTableHeader: null,
    loadStopTableGrouping: false,
    loadTableGrouping: false,
    loadStopEntryTableHeader: null,
    loadStopEntryTableGrouping: false,
    demandTreeHierarchyColumns: null,
    demandTreeColumns: null,
    demandTreeHierarchy: [],
    demandHierarchyHash: null,
    demandTreeFilters: [],
    locationColumns: [],
    loadTreeHierarchyColumns: [],
    loadTableColumns: [],
    loadTableRawColumns: [],
    loadStopTableColumns: [],
    loadStopEntryTableColumns: [],
    errorMessage: '',
    asyncActionStartMessage: '',
    asyncActionSuccessMessage: '',
    asyncActionFailMessage: '',
    CanReset: null,
    CanApproveLoad: null,
    CanRemoveLoad: null,
    CanAddDemandToLoad: null,
    CanMoveLoadEntryToLoad: null,
    CanRemoveLoadStop: null,
    CanChangeLoadStopSequence: null,
    CanUpdateLoadStopEntries: null,
    CanUpdateLocation: null,
    CanApplyDemandChanges: null,
    CanUpdateDemand: null,
    CanSavePlan: null,
    CanRefix: null,
    CanSimulate: null,
    CanServe: null,
    CanSetOrigin: null,
    CanCancelTask: null,
    CanCreateSnapshot: null,
    CanSetService: null,
    demandDetailDataColumns: [],
    demandDetailDataHierarchy: [],
    user: null,
    units: null,
    leftSideFilter: true,
    startingLastService: true,
    serviceSelectionPopUp: true,
    defaultViewTui: 'Map',
    backgroundColor: '#ffffff',
    demoMode: false,
    disabledMap: null
  },
  bind: {
    loadTreeColumns: 'loadTreeColumns',
    loadTreeHierarchy: 'loadTreeHierarchy',
    loadHierarchyHash: 'loadHierarchyHash',
    loadTreeFilters: 'loadTreeFilters',
    loadFooterButtons: 'loadFooterButtons',
    demandFooterButtons: 'demandFooterButtons',
    demandTotalFields: 'demandTotalFields',
    DemandTreeAllColumns: 'DemandTreeAllColumns',
    demandTreeHierarchyColumns: 'demandTreeHierarchyColumns',
    demandTreeColumns: 'demandTreeColumns',
    demandTreeHierarchy: 'demandTreeHierarchy',
    demandHierarchyHash: 'demandHierarchyHash',
    demandTreeFilters: 'demandTreeFilters',
    locationColumns: 'locationColumns',
    errorMessage: 'errorMessage',
    asyncActionStartMessage: 'asyncActionStartMessage',
    asyncActionSuccessMessage: 'asyncActionSuccessMessage',
    asyncActionFailMessage: 'asyncActionFailMessage',
    loadTreeHierarchyColumns: 'loadTreeHierarchyColumns',
    loadStopTableColumns: 'loadStopTableColumns',
    loadStopTableHeader: 'loadStopTableHeader',
    loadStopTableGrouping: 'loadStopTableGrouping',
    loadTableGrouping: 'loadTableGrouping',
    loadStopEntryTableHeader: 'loadStopEntryTableHeader',
    loadStopEntryTableGrouping: 'loadStopEntryTableGrouping',
    loadStopEntryTableColumns: 'loadStopEntryTableColumns',
    loadTableColumns: 'loadTableColumns',
    loadTableRawColumns: 'loadTableRawColumns',

    demandDetailDataColumns: 'demandDetailDataColumns',
    demandDetailDataHierarchy: 'demandDetailDataHierarchy',

    CanReset: 'CanReset',
    CanCancelTask: 'CanCancelTask',
    CanSetService: 'CanSetService',

    CanApproveLoad: 'CanApproveLoad',
    CanRemoveLoad: 'CanRemoveLoad',
    CanAddDemandToLoad: 'CanAddDemandToLoad',
    CanMoveLoadEntryToLoad: 'CanMoveLoadEntryToLoad',

    CanRemoveLoadStop: 'CanRemoveLoadStop',
    CanChangeLoadStopSequence: 'CanChangeLoadStopSequence',
    CanUpdateLoadStopEntries: 'CanUpdateLoadStopEntries',

    CanUpdateLocation: 'CanUpdateLocation',
    CanApplyDemandChanges: 'CanApplyDemandChanges',
    CanUpdateDemand: 'CanUpdateDemand',

    CanSavePlan: 'CanSavePlan',
    CanRefix: 'CanRefix',

    CanSimulate: 'CanSimulate',
    CanServe: 'CanServe',
    CanCreateSnapshot: 'CanCreateSnapshot',
    CanSetOrigin: 'CanSetOrigin',
    user: 'user',
    units: 'units',
    leftSideFilter: 'leftSideFilter',
    startingLastService: 'startingLastService',
    serviceSelectionPopUp: 'serviceSelectionPopUp',
    defaultViewTui: 'defaultViewTui',
    backgroundColor: 'backgroundColor',
    disabledMap: 'disabledMap',
    demoMode: 'demoMode'
  },
  publishes: [
    'loadTreeColumns',
    'loadTreeHierarchy',
    'loadHierarchyHash',
    'loadTreeFilters',
    'loadFooterButtons',
    'demandFooterButtons',
    'demandTotalFields',
    'DemandTreeAllColumns',
    'demandTreeColumns',
    'loadTreeHierarchyColumns',
    'demandTreeHierarchy',
    'demandHierarchyHash',
    'demandTreeFilters',
    'locationColumns',
    'errorMessage',
    'asyncActionStartMessage',
    'asyncActionSuccessMessage',
    'asyncActionFailMessage',
    'demandTreeHierarchyColumns',
    'loadTableColumns',
    'loadTableRawColumns',
    'loadStopTableColumns',
    'loadStopTableHeader',
    'loadStopTableGrouping',
    'loadTableGrouping',
    'loadStopEntryTableHeader',
    'loadStopEntryTableGrouping',
    'loadStopEntryTableColumns',
    'demandDetailDataColumns',
    'demandDetailDataHierarchy',
    'CanReset',
    'CanApproveLoad',
    'CanRemoveLoadStop',
    'CanSavePlan',
    'CanRefix',
    'CanUpdateDemand',
    'CanApplyDemandChanges', // sağ ekran aşağı sağ button
    'CanRemoveLoad', // sağ ekranda aşağıda sol buton
    'CanAddDemandToLoad', // sol alta toolbar button auto serve, simulate
    'CanSimulate',
    'CanServe',
    'CanSetOrigin',
    'CanCancelTask',
    'CanSetService',
    'CanCreateSnapshot',
    'CanChangeLoadStopSequence', // drag drop istiyoz ve sequence seçtir
    // auth
    'CanUpdateLoadStopEntries',
    'CanUpdateLocation', // ters click ama pointe
    'user',
    'units',
    'leftSideFilter',
    'startingLastService',
    'disabledMap',
    'serviceSelectionPopUp',
    'defaultViewTui',
    'backgroundColor',
    'demoMode'
  ],
  initComponent: function () {
    var me = this
    var viewModel = me.getViewModel()

    viewModel.set({
      loadTreeHierarchy: me.config.loadTreeHierarchy,
      loadHierarchyHash: me.config.loadHierarchyHash,
      loadTreeColumns: me.config.loadTreeColumns,
      loadTreeHierarchyColumns: me.config.loadTreeHierarchyColumns,
      loadTreeFilters: me.config.loadTreeFilters,
      loadFooterButtons: me.config.loadFooterButtons,
      demandFooterButtons: me.config.demandFooterButtons,
      demandTotalFields: me.config.demandTotalFields,
      DemandTreeAllColumns: me.config.DemandTreeAllColumns,
      demandTreeHierarchy: me.config.demandTreeHierarchy,
      demandHierarchyHash: me.config.demandHierarchyHash,
      demandTreeColumns: me.config.demandTreeColumns,
      demandTreeHierarchyColumns: me.config.demandTreeHierarchyColumns,
      demandTreeFilters: me.config.demandTreeFilters,
      locationColumns: me.config.locationColumns,
      errorMessage: me.config.errorMessage,
      asyncActionStartMessage: me.config.asyncActionStartMessage,
      asyncActionSuccessMessage: me.config.asyncActionSuccessMessage,
      asyncActionFailMessage: me.config.asyncActionFailMessage,
      loadTableColumns: me.config.loadTableColumns,
      loadTableRawColumns: me.config.loadTableRawColumns,
      loadStopTableColumns: me.config.loadStopTableColumns,
      loadStopTableHeader: me.config.loadStopTableHeader,
      loadStopTableGrouping: me.config.loadStopTableGrouping,
      loadTableGrouping: me.config.loadTableGrouping,
      loadStopEntryTableHeader: me.config.loadStopEntryTableHeader,
      loadStopEntryTableGrouping: me.config.loadStopEntryTableGrouping,
      loadStopEntryTableColumns: me.config.loadStopEntryTableColumns,
      CanReset: me.config.CanReset,
      CanApproveLoad: me.config.CanApproveLoad,
      CanRemoveLoad: me.config.CanRemoveLoad,
      CanAddDemandToLoad: me.config.CanAddDemandToLoad,
      CanMoveLoadEntryToLoad: me.config.CanMoveLoadEntryToLoad,
      CanRemoveLoadStop: me.config.CanRemoveLoadStop,
      CanChangeLoadStopSequence: me.config.CanChangeLoadStopSequence,
      CanUpdateLoadStopEntries: me.config.CanUpdateLoadStopEntries,
      CanUpdateLocation: me.config.CanUpdateLocation,
      CanApplyDemandChanges: me.config.CanApplyDemandChanges,
      CanUpdateDemand: me.config.CanUpdateDemand,
      CanSavePlan: me.config.CanSavePlan,
      CanRefix: me.config.CanRefix,
      CanSimulate: me.config.CanSimulate,
      CanServe: me.config.CanServe,
      CanSetOrigin: me.config.CanSetOrigin,
      CanCancelTask: me.config.CanCancelTask,
      CanSetService: me.config.CanSetService,
      CanCreateSnapshot: me.config.CanCreateSnapshot,
      demandDetailDataColumns: me.config.demandDetailDataColumns,
      demandDetailDataHierarchy: me.config.demandDetailDataHierarchy,
      user: me.config.user,
      units: me.config.units,
      leftSideFilter: me.config.leftSideFilter,
      startingLastService: me.config.startingLastService,
      disabledMapLatlng: {
        lat: me.config.disabledMap ? me.config.disabledMap.latValue : null,
        lng: me.config.disabledMap ? me.config.disabledMap.lngValue : null
      },
      disabledZoomFactor: me.config.disabledMap ? me.config.disabledMap.zoomFactor : null,
      demoMode: me.config.demoMode,
      defaultViewTui: me.config.defaultViewTui,
      backgroundColor: me.config.backgroundColor
    })
    var loadTreeColumns = _.clone(me.config.loadTreeColumns)
    loadTreeColumns.unshift(
      {
        xtype: 'actioncolumn',
        menuDisabled: true,
        width: 60,
        bind: {
          hidden: '{hiddenTuiSettingsByMap}'
        },
        renderer: function (val, metadata, record) {},
        items: [
          {
            margin: '0 0 0 10',
            width: 25,

            iconCls: 'fa fa-circle',
            cls: '',
            getClass: function (v, meta, record) {
              var key = record.get('ID')

              var color = '#fffff'

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }

              color = tuiv2.Colors.getColorByName(key, isLeafParent)
              meta.style = 'color:' + color + ';'
              return 'slvy-ui-icon-circle-fill colorstyle'
            },
            handler: 'onChangeColor'
          },
          {
            margin: '0 0 0 10',
            width: 25,
            getClass: function (v, meta, record) {
              var key = record.get('ID')

              var color = '#fffff'

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }
              if (isLeafParent) {
                return 'slvy-ui-icon-card'
              }
            },
            isActionDisabled: function (view, rowIndex, colIndex, item, record) {
              var key = record.get('ID')

              var color = '#fffff'

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }
              if (!isLeafParent) {
                return true
              }
            },
            handler: 'onOpenLoadCard',
            tooltip: 'Open Card'
          }
        ],
        locked: true,
        lockable: false
      },
      {
        xtype: 'actioncolumn',
        menuDisabled: true,
        width: 80,
        bind: {
          hidden: '{noneModeTreeview}'
        },
        renderer: function (val, metadata, record) {},
        items: [
          {
            width: 25,
            getClass: function (v, meta, record) {
              var key = record.get('ID')

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }
              if (isLeafParent) {
                return 'slvy-ui-icon-edit'
              }
            },
            isActionDisabled: function (view, rowIndex, colIndex, item, record) {
              var key = record.get('ID')

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }
              if (!isLeafParent) {
                return true
              }
            },
            handler: 'onEditLoadCard',
            tooltip: 'Edit'
          },
          {
            width: 25,
            getClass: function (v, meta, record) {
              var key = record.get('ID')

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }
              if (isLeafParent) {
                return 'slvy-ui-icon-scenario-change-extra-lt mx-1'
              }
            },
            isActionDisabled: function (view, rowIndex, colIndex, item, record) {
              var key = record.get('ID')

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }
              if (!isLeafParent) {
                return true
              }
            },
            handler: 'onChangeTransportationCard',
            tooltip: 'Change TransportationMode'
          },
          {
            width: 25,
            getClass: function (v, meta, record) {
              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (record && record.childNodes && record.childNodes.length > 0) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }

              if (isLeafParent) {
                return 'slvy-ui-icon-scenario-delete-extra-lt'
              }
            },
            isActionDisabled: function (view, rowIndex, colIndex, item, record) {
              var key = record.get('ID')

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (record && record.childNodes && record.childNodes.length > 0) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              }
              if (!isLeafParent) {
                return true
              }
            },
            handler: 'onRemoveLoadsHandler',
            tooltip: 'Remove Load'
          },
          {
            width: 25,
            getClass: function (v, meta, record) {
              var isLeaf = false

              if (record.isLeaf()) {
                isLeaf = true
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
              }

              if (isLeaf) {
                return 'slvy-ui-icon-scenario-delete-extra-lt'
              }
            },
            isActionDisabled: function (view, rowIndex, colIndex, item, record) {
              if (record.isLeaf()) {
                return false
              }

              if (record.isRoot() || record.parentNode) {
                return true
              }
            },
            handler: 'onRemoveLoadStop',
            tooltip: 'Remove Load Stop'
          }
        ],
        locked: true,
        lockable: false
      },
      {
        xtype: 'actioncolumn',
        width: 30,
        menuDisabled: true,
        renderer: function (val, metadata, record) {},
        items: [
          {
            margin: '0 0 0 10',
            width: 25,
            getClass: function (v, meta, record) {
              if (record.data.root === true) {
                const anySelected = record.childNodes.some(
                  (item) => item.data.Approved === 'Approved'
                )

                if (anySelected) {
                  const allApproved = record.childNodes.every(
                    (item) => item.data.Approved === 'Approved'
                  )

                  return allApproved
                    ? 'slvy-ui-icon-circle-check approved-icon'
                    : 'slvy-ui-icon-circle-check'
                } else {
                  return 'slvy-ui-icon-circle-extra-lt'
                }
              }
              if (record.isLeaf()) return ''
              if (record.get('Approved') === 'Approved') {
                return 'slvy-ui-icon-circle-check approved-icon'
              } else if (record.get('Approved') === 'Unapproved') {
                return 'slvy-ui-icon-circle-extra-lt'
              }
            },
            tooltip: 'Approve',
            handler: 'onApproveLoad',
            hidden: !me.config.CanApproveLoad
          }
        ],
        locked: true,
        lockable: false
      },
      {
        xtype: 'treecolumn', // this is so we know which column will show the tree
        text: 'Name',
        menuDisabled: true,
        dataIndex: 'Text',
        width: 240,
        stopSelection: true,
        locked: true,
        lockable: false,
        sortable: false
      }
    )
    viewModel.set('loadTreeColumns', loadTreeColumns)

    var demandTreeColumns = _.clone(me.config.demandTreeColumns)

    demandTreeColumns.unshift(
      {
        xtype: 'actioncolumn',
        menuDisabled: true,
        width: 42,
        renderer: function (val, metadata, record) {
          // if (record.isLeaf()) return "";
          // return val;
        },
        bind: {
          hidden: '{hiddenTuiSettingsByMap}'
        },
        items: [
          {
            margin: '0 0 0 10',
            getClass: function (v, meta, record) {
              var key = record.get('ID')

              var color = '#fffff'

              var isLeafParent = false
              meta.style = 'margin: 0px 0px 0px 10px'
              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].childNodes &&
                record.childNodes[0].childNodes.length > 0 &&
                record.childNodes[0].childNodes[0].isLeaf()
              ) {
                isLeafParent = true
              }
              if (isLeafParent) {
                return 'slvy-ui-icon-card'
              }
            },
            isActionDisabled: function (view, rowIndex, colIndex, item, record) {
              var key = record.get('ID')

              var color = '#fffff'

              var isLeafParent = false

              if (record.isLeaf()) {
                key = record.get('ID')
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].isLeaf()
              ) {
                key = record.childNodes[0].get('ID')
                isLeafParent = true
              } else if (
                record.childNodes &&
                record.childNodes.length > 0 &&
                record.childNodes[0].childNodes &&
                record.childNodes[0].childNodes.length > 0 &&
                record.childNodes[0].childNodes[0].isLeaf()
              ) {
                isLeafParent = true
              }
              if (!isLeafParent) {
                return true
              }
            },
            handler: 'onOpenDemandCard',
            tooltip: 'Open Card'
          }
        ],
        locked: true,
        lockable: false
      },
      {
        xtype: 'treecolumn', // this is so we know which column will show the tree
        text: 'Name',
        dataIndex: 'Text',
        menuDisabled: true,
        width: 240,
        locked: true,
        lockable: false,
        sortable: false
      }
    )

    var demandDetailColumns = _.clone(me.config.demandDetailDataColumns)

    demandDetailColumns.unshift({
      xtype: 'treecolumn', // this is so we know which column will show the tree
      text: 'Name',
      dataIndex: 'Text',
      width: 240,
      sortable: true
    })

    viewModel.set('demandDetailColumns', demandDetailColumns)

    var loadTableColumns = _.clone(me.config.loadTableColumns)

    loadTableColumns.unshift({
      xtype: 'actioncolumn',
      width: 40,
      sortable: false,
      menuDisabled: true,
      locked: true,
      summaryRenderer: false,
      align: 'center',
      items: [
        {
          iconCls: 'icon-map', // Use a URL in the icon config
          tooltip: 'Show on Map',
          margin: '0 10 0 0',
          handler: 'onLoadTableShowMap'
        }
      ]
    })

    me.items = [
      {
        xtype: 'panel',
        region: 'center',
        header: false,
        layout: 'card',
        hideCollapseTool: true,
        bind: {
          activeItem: '{mainActiveItem}'
        },
        items: [
          {
            xtype: 'panel',
            reference: 'ServiceIsNotReadyPanel'
          },
          {
            xtype: 'panel',
            layout: 'border',
            items: [
              {
                xtype: 'component',
                height: 200,
                width: 200,
                html: '<div class="popupIndicator"> <p>Saving...</p><span class="fa fa-spin spinIndicator"></span></div>',
                bind: {
                  hidden: '{savingHidden}'
                }
              },
              {
                title: 'north',
                xtype: 'toolbar',
                ui: 'tui-toolbar',
                border: '0 0 1 0',
                overflowHandler: 'scroller',
                height: 50,
                items: [
                  {
                    xtype: 'box',
                    reference: 'headerBarTitle',
                    width: 400,
                    html: 'Plan Analysis Report'
                  },
                  {
                    cls: 'navigation-btn',
                    ui: 'tui-tab-button',
                    scale: 'medium',
                    iconCls: 'slvy-ui-icon-burger-lt',
                    handler: 'onToggleTreeView',
                    bind: {
                      hidden: '{mapAndTableModeTreeview}'
                    }
                  },
                  {
                    xtype: 'container',
                    margin: '0 10 0 10',

                    bind: {
                      html: '{isServiceRunningIcon}'
                    },
                    listeners: {
                      click: {
                        fn: 'serviceIconClick',
                        element: 'el',
                        delegate: 'i'
                      }
                    }
                  },
                  {
                    xtype: 'container',
                    margin: '0 10 0 10',

                    bind: {
                      html: '{userInfo}'
                    }
                  },
                  '->',
                  {
                    cls: 'tuiv2-comboArea',
                    xtype: 'combo',
                    fieldLabel: 'Choose Reason',
                    editable: false,
                    displayField: 'Description',
                    valueField: 'Id',
                    bind: {
                      store: '{reasonCodeStore}',
                      value: '{reasonCode}'
                    },
                    listeners: {
                      change: function (me, newValue, oldValue, eOpts) {
                        var me = this

                        var view = me.up('tuiv2mainview')

                        var viewModel = view.getViewModel()

                        viewModel.set('selectedDefaultReason', newValue)
                        viewModel.set('selectedDefaultReasonDescription', me.getDisplayValue())
                      }
                    }
                  },
                  {
                    cls: 'tuiv2-inputArea',
                    xtype: 'textfield',
                    fieldLabel: 'Session Name',
                    displayField: 'Description',
                    reference: 'sessionNameTextField',
                    bind: {
                      value: '{sessionNameText}'
                    }
                  },
                  {
                    cls: 'tuiv2-navigation-btn',
                    iconCls: 'slvy-ui-icon-caret-right',
                    tooltip: 'Start Session',
                    handler: function () {
                      var me = this

                      var view = me.up('tuiv2mainview')

                      var referenceHolder = this.lookupReferenceHolder()

                      var textField = referenceHolder.lookupReference('sessionNameTextField')

                      var sessionName = textField.getValue()

                      var controller = view.getController()

                      var viewModel = view.getViewModel()

                      var selectedDefaultReason = viewModel.get('selectedDefaultReason')

                      if (sessionName == null || sessionName == '') {
                        sessionName = Ext.util.Format.date(new Date(), 'Y-m-d H:i:s') + ' Session'
                      }

                      controller.onSetSessionStart(
                        sessionName,
                        selectedDefaultReason,
                        function (data) {}
                      )
                    }
                  },
                  {
                    cls: 'tuiv2-navigation-btn',
                    iconCls: 'slvy-ui-icon-history',
                    handler: 'openSessionListWindow',
                    tooltip: 'Session list'
                  },
                  {
                    xtype: 'button',
                    ui: 'tui-tab-button',
                    scale: 'medium',
                    iconCls: 'slvy-ui-icon-bolt',
                    hidden:
                      !me.config.CanReset &&
                      !me.config.CanRefix &&
                      !me.config.CanUpdateDemand &&
                      !me.config.CanSavePlan,
                    menu: [
                      {
                        iconCls: 'slvy-ui-icon-reset',
                        text: 'Reset',
                        reference: 'resetTableButton',
                        overCls: Ext.baseCSSPrefix + 'btn-over' + ' myoverCls',
                        handler: 'onResetTable',
                        hidden: !me.config.CanReset
                      },
                      {
                        iconCls: 'slvy-ui-icon-save',
                        text: 'Save Plan',
                        overCls: Ext.baseCSSPrefix + 'btn-over' + ' myoverCls',
                        handler: 'onOpenSavePlan',
                        hidden: !me.config.CanSavePlan
                      },
                      {
                        text: 'Snapshot',
                        overCls: Ext.baseCSSPrefix + 'btn-over' + ' myoverCls',
                        iconCls: 'slvy-ui-icon-snapshot-regular',
                        tooltip: 'Take snapshot',
                        handler: 'onSaveCurrentState',
                        hidden: !me.config.CanCreateSnapshot
                      }
                    ]
                  },
                  {
                    xtype: 'cycle',
                    ui: 'tui-tab-button',
                    scale: 'medium',
                    changeHandler: 'onChangeView',
                    bind: {
                      iconCls: '{viewModeBtnIconCls}'
                    },
                    menu: {
                      items: [
                        {
                          text: 'Map',
                          viewId: 0
                        },
                        {
                          text: 'Table',
                          viewId: 1,
                          checked: me.config.defaultViewTui === 'Grid'
                        },
                        {
                          text: 'Tree Grid',
                          viewId: 2,
                          checked: me.config.defaultViewTui === 'Tree Grid'
                        }
                      ]
                    }
                  },

                  {
                    xtype: 'tuiv2-changeruninfo',
                    ui: 'tui-tab-button',
                    scale: 'medium',
                    iconCls: 'slvy-ui-icon-snapshot-regular',
                    hidden: me.config.serviceSelectionPopUp ? false : true
                  },
                  {
                    xtype: 'tuiv2-settings',
                    ui: 'tui-tab-button',
                    scale: 'medium',
                    iconCls: 'slvy-ui-icon-tools',
                    bind: {
                      disabled: '{hiddenTuiSettingsByMap}'
                    }
                  }
                ],
                region: 'north',
                header: false
              },
              {
                region: 'west',
                xtype: 'tabpanel',
                ui: 'tui-tab',
                split: {
                  size: 10,
                  style: {
                    backgroundColor: me.config.backgroundColor
                  }
                },
                width: '50%',
                layout: 'fit',
                bind: {
                  hidden: '{noneModeTreeview}'
                },
                tabBar: {
                  items: [
                    {
                      xtype: 'button',
                      iconCls: 'slvy-ui-icon-cog',
                      handler: 'onShowSettingsLoad',
                      ui: 'tui-tab-button',
                      scale: 'medium',
                      style: {
                        left: '120px !important;'
                      }
                    }
                  ]
                },
                items: [
                  {
                    title: 'Load',
                    xtype: 'loadtree',
                    reference: 'loadTree',
                    ui: 'tui-grid',
                    cls: 'tui-custom-grid-B',
                    columns: loadTreeColumns,
                    bind: {
                      store: '{loadTreeStore}'
                    },
                    bbar: [
                      {
                        iconCls: 'fa fa-superpowers',
                        tooltip: 'Polishize',
                        handler: 'onPolishize',
                        style: {
                          backgroung: '#d8dce3'
                        },
                        bind: {
                          disabled: '{Polishize}'
                        },
                        hidden: !me.config.CanRefix
                      },
                      ...me.config.loadFooterButtons,
                      '->',
                      {
                        xtype: 'tbtext',
                        bind: {
                          html: 'Load Count : <b>{loadTreeLoadCount}</b> | Load Stop Count : <b>{loadTreeLoadStopCount}</b>'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                region: 'west',
                xtype: 'tabpanel',
                ui: 'tui-tab',
                split: {
                  size: 10,
                  bind: {
                    style: {
                      backgroundColor: '{backgroundColorByTable}'
                    }
                  }
                },
                reference: 'treeView',
                width: 400,
                bind: {
                  hidden: !me.config.leftSideFilter || '{mapAndTableModeTreeview}'
                },
                tabBar: {
                  items: [
                    {
                      xtype: 'button',
                      iconCls: 'slvy-ui-icon-cog',
                      handler: 'onShowSettings',
                      ui: 'tui-tab-button',
                      scale: 'medium',
                      style: {
                        left: '120px !important;'
                      }
                    },
                    {
                      xtype: 'component',
                      flex: 1
                    },
                    {
                      xtype: 'button',
                      iconCls: 'slvy-ui-icon-diff',
                      handler: 'openDiffWindow',
                      tooltip: 'View Last Difference',
                      ui: 'tui-tab-button',
                      scale: 'medium',
                      style: {}
                    },
                    {
                      xtype: 'button',
                      iconCls: 'slvy-ui-icon-diff-all',
                      handler: 'openAllDiffWindow',
                      tooltip: 'View All Difference',
                      // margin: '3 0 3 0',
                      ui: 'tui-tab-button',
                      scale: 'medium',
                      style: {}
                    },
                    {
                      xtype: 'button',
                      iconCls: 'slvy-ui-icon-edit',
                      handler: 'onMapEdit',
                      tooltip: 'Filter from map',
                      ui: 'tui-tab-button',
                      scale: 'medium',
                      style: {},
                      bind: {
                        disabled: '{hiddenTuiSettingsByMap}',
                        freeDrawMode: '{freeDrawMode}'
                      },
                      setFreeDrawMode: function (freeDrawMode) {
                        if (freeDrawMode == 0 && this.pressed) {
                          this.setPressed(false)
                        }
                      },
                      enableToggle: true
                    },
                    {
                      xtype: 'button',
                      iconCls: 'slvy-ui-icon-eraser',
                      reference: 'topeyebutton',
                      ui: 'tui-tab-button',
                      scale: 'medium',
                      handler: 'onErase',
                      tooltip: 'Clear All selections',
                      style: {}
                    }
                  ]
                },
                items: [
                  {
                    title: 'Load',
                    xtype: 'loadtree',
                    reference: 'loadTree',
                    ui: 'tui-grid',
                    cls: 'tui-custom-grid-B',
                    columns: loadTreeColumns,
                    bind: {
                      store: '{loadTreeStore}'
                    },
                    bbar: [
                      {
                        iconCls: 'fa fa-superpowers',
                        tooltip: 'Polishize',
                        handler: 'onPolishize',
                        bind: {
                          disabled: '{Polishize}'
                        },
                        style: {
                          backgroung: '#d8dce3'
                        },
                        hidden: !me.config.CanRefix
                      },
                      ...me.config.loadFooterButtons,
                      ,
                      '->',
                      {
                        xtype: 'tbtext',
                        bind: {
                          html: 'Load Count : <b>{loadTreeLoadCount}</b> | Load Stop Count : <b>{loadTreeLoadStopCount}</b>'
                        }
                      }
                    ]
                  },
                  {
                    xtype: 'demandTree',
                    columns: demandTreeColumns,
                    title: 'Demand',
                    ui: 'tui-grid',
                    cls: 'tui-custom-grid-B',
                    bind: {
                      store: '{demandTreeStore}'
                    },
                    tabConfig: {
                      listeners: {
                        click: function () {
                          var me = this

                          var view = me.up('tuiv2mainview')

                          var tree = view.lookupReference('demandTree')

                          var root = tree.getRootNode()

                          if (root && !root.isExpanded()) {
                            root.expand()
                          }
                        }
                      }
                    },
                    bbar: [
                      {
                        xtype: 'button',
                        tooltip: 'Serve',
                        iconCls: 'fa fa-gavel',
                        handler: 'onServe',
                        bind: {
                          hidden: '{!CanServe}',
                          disabled: '{Serve}'
                        }
                      },
                      {
                        xtype: 'button',
                        tooltip: 'Set Origin',
                        iconCls: 'fa fa-arrows',
                        handler: 'onSetOrigin',
                        bind: {
                          hidden: '{!CanSetOrigin}',
                          disabled: '{Origin}'
                        }
                      },
                      ...me.config.demandFooterButtons
                    ]
                  }
                ]
              },

              {
                xtype: 'panel',
                region: 'center',
                header: false,
                layout: 'card',
                hideCollapseTool: true,
                bind: {
                  activeItem: '{viewMode}'
                },
                items: [
                  {
                    xtype: 'panel',
                    region: 'center',
                    header: false,
                    layout: 'border',
                    reference: 'mapView',
                    items: [
                      {
                        xtype: 'panel',
                        region: 'center',
                        layout: 'border',
                        items: [
                          {
                            xtype: 'mainmap',
                            region: 'center',
                            bind: {
                              mapData: '{mapData}',
                              loadStopMarkerId: '{loadStopMarkerId}',
                              smoothFactor: '{smoothFactor}',
                              weight: '{weight}',
                              opacity: '{opacity}',
                              zoomOnSelect: '{zoomOnSelect}',
                              planInfo: '{PlanInfo}',
                              maxDemandQuantity: '{maxDemandQuantity}',
                              freeDrawMode: '{freeDrawMode}',
                              demoEnabled: '{demoEnabled}'
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    layout: 'border',
                    xtype: 'panel',
                    region: 'center',
                    items: [
                      {
                        region: 'center',
                        xtype: 'tuiv2-loadTable',
                        features: me.config.loadTableGrouping ? { ftype: 'grouping' } : undefined,
                        columns: loadTableColumns,
                        bind: {
                          store: '{loadTableStore}'
                        },
                        flex: 1
                      },
                      {
                        xtype: 'container',
                        layout: {
                          type: 'hbox',
                          pack: 'start',
                          align: 'stretch'
                        },
                        bodyPadding: 4,
                        region: 'south',
                        flex: 1,
                        border: true,
                        bind: {
                          margin:
                            me.config.loadStopTableHeader || me.config.loadStopEntryTableHeader
                              ? '5 0 0 0'
                              : '0'
                        },
                        items: [
                          {
                            xtype: 'tuiv2-loadStopTable',
                            margin: '0 10 0 0',
                            columns: me.config.loadStopTableColumns,
                            features: me.config.loadStopTableGrouping
                              ? { ftype: 'grouping' }
                              : undefined,
                            title: {
                              text: me.config.loadStopTableHeader,
                              style: {
                                fontWeight: 'bold'
                              }
                            },
                            header: {
                              style: {
                                backgroundColor: 'transparent',
                                margin: '0',
                                padding: '0'
                              }
                            },
                            bind: {
                              store: '{loadStopTableStore}'
                            },
                            flex: 1
                          },
                          {
                            xtype: 'tuiv2-loadStopEntryTable',
                            columns: me.config.loadStopEntryTableColumns,
                            features: me.config.loadStopEntryTableGrouping
                              ? { ftype: 'grouping' }
                              : undefined,
                            title: {
                              text: me.config.loadStopEntryTableHeader,
                              style: {
                                fontWeight: 'bold'
                              }
                            },
                            header: {
                              style: {
                                backgroundColor: 'transparent',
                                margin: '0',
                                padding: '0'
                              }
                            },
                            bind: {
                              store: '{loadStopEntryTableStore}'
                            },
                            flex: 1
                          }
                        ]
                      }
                    ]
                  },
                  {
                    region: 'west',
                    xtype: 'tabpanel',
                    ui: 'tui-tab',
                    width: '50%',
                    layout: 'fit',
                    bind: {
                      hidden: '{noneModeTreeview}'
                    },
                    tabBar: {
                      items: [
                        {
                          xtype: 'button',
                          iconCls: 'slvy-ui-icon-cog',
                          handler: 'onShowSettingsDemand',
                          ui: 'tui-tab-button',
                          scale: 'medium',
                          style: {
                            left: '120px !important;'
                          }
                        }
                      ]
                    },
                    items: [
                      {
                        xtype: 'demandTree',
                        columns: demandTreeColumns,
                        title: 'Demand',
                        ui: 'tui-grid',
                        cls: 'tui-custom-grid-B',
                        bind: {
                          store: '{demandTreeStore}'
                        },
                        tabConfig: {
                          listeners: {
                            click: function () {
                              var me = this

                              var view = me.up('tuiv2mainview')

                              var tree = view.lookupReference('demandTree')

                              var root = tree.getRootNode()

                              if (root && !root.isExpanded()) {
                                root.expand()
                              }
                            }
                          }
                        },
                        bbar: [
                          {
                            xtype: 'button',
                            tooltip: 'Serve',
                            iconCls: 'fa fa-gavel',
                            handler: 'onServe',
                            bind: {
                              hidden: '{!CanServe}',
                              disabled: '{Serve}'
                            }
                          },
                          {
                            xtype: 'button',
                            tooltip: 'Set Origin',
                            iconCls: 'fa fa-arrows',
                            handler: 'onSetOrigin',
                            bind: {
                              hidden: '{!CanSetOrigin}',
                              disabled: '{Origin}'
                            }
                          },
                          ...me.config.demandFooterButtons,
                          '->',
                          {
                            xtype: 'tbtext',
                            bind: {
                              html:
                                me.config.demandTotalFields.length === 1
                                  ? me.config.demandTotalFields[0].fieldName +
                                    ' Sum : <b>{firstSum}</b>'
                                  : me.config.demandTotalFields.length > 1
                                  ? me.config.demandTotalFields[0].fieldName +
                                    ' Sum : <b>{firstSum}</b> | ' +
                                    me.config.demandTotalFields[1].fieldName +
                                    ' Sum : <b>{secondSum}</b>'
                                  : null
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]

    if (me.config.CanSetService && me.config.serviceSelectionPopUp) {
      var serviceDialog = Ext.create('tuiv2.cmp.ServiceDialog', {
        mainController: this.getController()
      }).show()

      serviceDialog.on(
        'select',
        function (data) {
          const { ScenarioID, ScenarioName, ConnectionId: ConnectionID, RunID, RunInfoID } = data
          var me = this

          var controller = me.getController()

          controller.onSelectService(data)
          me.onScenarioSelected({ ScenarioID, ScenarioName, ConnectionID, RunID, RunInfoID })
          serviceDialog.close()
        },
        this
      )
    }

    me.callParent()
  },
  listeners: {
    afterrender: 'onAfterRender',
    setFilters: 'onSetFilters',
    setCheckedLoads: 'onSetCheckedLoads',
    setFooterButtonsView: 'setFooterButtonsView',
    setCheckedDemands: 'onSetCheckedDemands',
    openLoadCard: 'onOpenLoadCardExternal',
    openDemandCard: 'openDemandCardExternal',
    setTuiConnection: 'setTuiConnection'
  }
})
