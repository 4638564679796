import { getIsProgressAndNotNumber } from '../../common/index'
import { GetIsProgressAndNotNumberProps } from '../common.types'

const getIsProgressAndNotNumberParams = (): GetIsProgressAndNotNumberProps => ({
  columnType: 'simple',
  value: 'vito'
})

describe('AgGrid:Helpers:Common:getIsProgressAndNotNumber', () => {
  let params = getIsProgressAndNotNumberParams()

  beforeEach(() => {
    params = getIsProgressAndNotNumberParams()
  })

  it('should return false if columnType is not progress', () => {
    const result = getIsProgressAndNotNumber(params)
    expect(result).toBe(false)
  })
  it('should return false if typeof value is not string and number', () => {
    params.columnType = 'progress'
    params.value = [1, 2, 3]
    const result = getIsProgressAndNotNumber(params)
    expect(result).toBe(false)
  })
  it('should return false if typeof value is number', () => {
    params.columnType = 'progress'
    params.value = 25
    const result = getIsProgressAndNotNumber(params)
    expect(result).toBe(false)
  })
  it('should try to parse newValue if its a string and return true if it can not parse value to a number', () => {
    params.columnType = 'progress'
    const result = getIsProgressAndNotNumber(params)
    expect(result).toBe(true)
  })
  it('should try to parse newValue if its a string and return false if it can parse value to a number', () => {
    params.columnType = 'progress'
    params.value = '25'
    const result = getIsProgressAndNotNumber(params)
    expect(result).toBe(false)
  })
})
