import { useMemo } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { getRegisterEventFieldConfigsParams } from '../../helpers/common'
import { GetRegisterEventFieldConfigsParams } from '../../types'

function useGetMultiRowSelectedParams({
  cellParams,
  getFieldType
}: GetRegisterEventFieldConfigsParams) {
  const multiRowSelectedParams = useMemo(() => {
    return {
      ...getRegisterEventFieldConfigsParams({ cellParams, getFieldType }),
      selectedRowCount: PluginTypes.int
    }
  }, [cellParams, getFieldType])
  return { multiRowSelectedParams }
}

export default useGetMultiRowSelectedParams
