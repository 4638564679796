import { createRef, Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import cx from 'classnames'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import TreeViewContent from './TreeViewContent'
import { prependItem } from './helpers'
import { filteredRootValue, multipleTexts, rootValue, selectAllNode } from './constants'
import './style.scss'

class FilterTreeview extends Component {
  constructor() {
    super()
    this.state = {
      show: false,
      checked: [],
      size: { top: 0, bottom: 0, left: 0, right: 0, height: 0, width: 0 },
      visible: 'visible'
    }

    this.lastOperation = createRef()

    this.handleClick = this.handleClick.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleItemSelect = this.handleItemSelect.bind(this)
    this.handleClearAllSelections = this.handleClearAllSelections.bind(this)
    this.handleSelectPrevNext = this.handleSelectPrevNext.bind(this)
    this.bodyClick = this.bodyClick.bind(this)
    this.selectionStateChanged = this.selectionStateChanged.bind(this)
    this.setSelectionEnabled = this.setSelectionEnabled.bind(this)
    this.setSelectionDisabled = this.setSelectionDisabled.bind(this)
  }

  componentDidMount() {
    const {
      settings: {
        config: {
          settings: { multiple = 'Single Value', dropdown = true } = {},
          data: { display, value, levels } = {}
        } = {}
      } = {},
      getFieldType
    } = this.props

    const fields = this.getFieldConfigs()

    if (!_.isEmpty(display) && !_.isEmpty(value)) {
      if (!_.isEmpty(levels) && _.size(levels) > 0) {
        const returnTypes = _.transform(
          levels,
          (result, level) => {
            result[`${level.variable}_${value}`] = getFieldType(
              value,
              multiple === 'Multiple Values' || multiple === 'Multiple Values under Single Branch'
            )
            result[`${level.variable}_${display}`] = getFieldType(
              display,
              multiple === 'Multiple Values' ||
                multiple === 'Multiple Values under Single Branch' ||
                multiple === 'Multiple Values under Main Branch'
            )
          },

          _.transform(
            levels,
            (result, level) => {
              _.forEach(fields, (fieldConfig) => {
                result[`${level.variable}_Column_${fieldConfig.fieldName}`] = getFieldType(
                  fieldConfig.fieldName,
                  multiple === 'Multiple Values' ||
                    multiple === 'Multiple Values under Single Branch'
                )
              })
            },
            {
              nodesSelected: PluginTypes.boolean,
              nodesNotSelected: PluginTypes.boolean
            }
          )
        )
        this.handleItemSelect = this.props.registerEvent({
          key: 'handleItemSelect',
          fn: this.handleItemSelect,
          returnTypes
        })
      } else {
        this.handleItemSelect = this.props.registerEvent({
          key: 'handleItemSelect',
          fn: this.handleItemSelect,
          returnTypes: _.transform(
            fields,
            (result, fieldConfig) => {
              result[`Column_${fieldConfig.fieldName}`] = getFieldType(
                fieldConfig.fieldName,
                multiple === 'Multiple Values' || multiple === 'Multiple Values under Single Branch'
              )
            },
            {
              value: getFieldType(
                value,
                multiple === 'Multiple Values' || multiple === 'Multiple Values under Single Branch'
              ),
              display: getFieldType(
                display,
                multiple === 'Multiple Values' ||
                  multiple === 'Multiple Values under Single Branch' ||
                  multiple === 'Multiple Values under Main Branch'
              ),
              nodesSelected: PluginTypes.boolean,
              nodesNotSelected: PluginTypes.boolean
            }
          )
        })
      }
    }

    // Register save method
    this.props.registerMethod({
      key: 'clearValue',
      fn: this.clearValue.bind(this),
      args: []
    })

    this.selectionStateChanged = this.props.registerEvent({
      key: 'selectionStateChanged',
      fn: this.selectionStateChanged,
      returnTypes: {
        selectedValues:
          multiple === 'Single Value' ? PluginTypes.string : PluginTypes.arrayOf(PluginTypes.string)
      }
    })

    this.props.registerMethod({
      key: 'setSelectionState',
      fn: this.setSelectionState.bind(this),
      args: [
        {
          name: 'selectedValues',
          type:
            multiple === 'Single Value'
              ? PluginTypes.string
              : PluginTypes.arrayOf(PluginTypes.string)
        }
      ]
    })

    this.props.registerMethod({
      key: 'setSelectionEnabled',
      fn: this.setSelectionEnabled.bind(this),
      args: []
    })

    this.props.registerMethod({
      key: 'setSelectionDisabled',
      fn: this.setSelectionDisabled.bind(this),
      args: []
    })

    this.popup = document.createElement('div')
    document.body.appendChild(this.popup)
    if (dropdown) {
      this._renderLayer()
    }
    window.addEventListener('click', this.bodyClick)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {
      settings: {
        config: { settings: { sendDataWhenDropdownClosed = false, dropdown = true } = {} } = {}
      } = {}
    } = this.props
    const { checked, show } = this.state

    if (this.lastOperation.current === 'clear') {
      this.handleItemSelect([], nextProps)
      this.lastOperation.current = null
      return
    }

    const isCheckedChanged =
      checked.length !== nextState.checked && !_.isEqual(checked, nextState.checked)
    const isDropdownClosed = show && !nextState.show
    const shouldSendDataWhenDropdownClosed = sendDataWhenDropdownClosed && dropdown

    // To prevent 'handleItemSelect' from being executed when the dropdown is
    // opened and closed without making any selection, 'lastOperation' checks
    // whether a selection has been made after the dropdown is opened.
    if (show && isCheckedChanged) {
      this.lastOperation.current = 'check'
    }

    // When 'sendDataWhenDropdownClosed' is active, 'handleItemSelect' should not be
    // triggered event if a selection is made while the dropdown is open. It should
    // only be triggered when the dropdown is closed.
    const shouldHandleItemSelectWithDropdownClosed =
      shouldSendDataWhenDropdownClosed && isDropdownClosed && this.lastOperation.current === 'check'

    const shouldHandleItemSelectWithCheckedChanged =
      !shouldSendDataWhenDropdownClosed && isCheckedChanged

    if (shouldHandleItemSelectWithCheckedChanged || shouldHandleItemSelectWithDropdownClosed) {
      this.handleItemSelect(nextState.checked, nextProps)
    }

    if (isDropdownClosed) {
      this.lastOperation.current = null
    }
  }

  componentDidUpdate() {
    const {
      settings: {
        config: { settings: { dropdown = true } = {} }
      }
    } = this.props
    if (dropdown) {
      this._renderLayer()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isPluginDataLoading: prevIsPluginDataLoading } = this.props

    const {
      settings: {
        config: {
          data: {
            display: displayField,
            value: valueField,
            level: levelField,
            default: defaultField,
            parent: parentField
          } = {}
        } = {}
      } = {},
      pluginData: result,
      isPluginDataLoading
    } = nextProps

    if (prevIsPluginDataLoading && !isPluginDataLoading && !_.isEmpty(defaultField)) {
      const checked = _.map(
        _.filter(result, (r) => r[defaultField]),
        (r) => prependItem(r, displayField, valueField, levelField, parentField)
      )
      this.setState({ checked })

      this.handleItemSelect(checked, nextProps)
    }

    const domElement = ReactDOM.findDOMNode(this.refs.filter_content)
    if (domElement) {
      const size = domElement.getBoundingClientRect()
      if (!_.isEqual(size, this.state.size)) {
        this.setState({ size })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.bodyClick)
    ReactDOM.unmountComponentAtNode(this.popup)
    document.body.removeChild(this.popup)
  }

  handleClick(event) {
    let { size } = this.state
    event.preventDefault()
    const domElement = ReactDOM.findDOMNode(this.refs.filter_content)
    if (domElement) {
      size = domElement.getBoundingClientRect()
    }
    const { show } = this.state
    this.setState({ show: !show, size })
  }

  handleCheck(checked) {
    const {
      settings: {
        config: {
          settings: { multiple = 'Single Value' } = {},
          data: {
            display: displayField,
            value: valueField,
            level: levelField,
            parent: parentField
          } = {}
        } = {}
      } = {},
      pluginData: result
    } = this.props
    const checkedLength = _.size(checked)
    const stateCheckedLength = _.size(this.state.checked)
    const diff =
      checkedLength > stateCheckedLength
        ? _.difference(checked, this.state.checked)
        : _.difference(this.state.checked, checked)

    let filter = checked
    switch (multiple) {
      case 'Multiple Values':
        this.setState({
          checked
        })
        break
      case 'Multiple Values under Single Branch':
        if (!_.isEmpty(diff)) {
          const el = _.split(_.first(diff), '~')
          filter = _.filter(checked, (c) => {
            if (el[3] === _.split(c, '~')[3]) {
              return true
            }
            return false
          })
        }
        this.setState({
          checked: filter
        })
        break
      case 'Multiple Values under Main Branch':
        if (!_.isEmpty(diff)) {
          const el = _.split(_.first(diff), '~')
          const main = _.split(
            this.findMainBranch(el[1], result, displayField, valueField, levelField, parentField),
            '~'
          )
          filter = _.filter(checked, (c) => {
            const h = _.split(c, '~')
            const mainItem = _.split(
              this.findMainBranch(h[1], result, displayField, valueField, levelField, parentField),
              '~'
            )
            if (main[1] === mainItem[1]) {
              return true
            }
            return false
          })

          this.setState({
            checked: filter
          })
        }
        break
      default:
        const oldChecked = [...this.state.checked]
        const newChecked = [...checked]
        if (!_.isEqual(oldChecked, newChecked)) {
          this.setState({
            checked: _.difference(checked, this.state.checked)
          })
        } else {
          this.setState({
            checked: [...this.state.checked]
          })
        }
        break
    }
  }

  handleItemSelect(checked, nextProps) {
    const {
      settings: {
        config: {
          settings: {
            multiple = 'Single Value',
            omitChildrenWhenAllChildrenSelected = false,
            sendNull = false,
            disableSendParentWhenPartiallySelected = false
          } = {},
          data: {
            display: displayField,
            value: valueField,
            level: levelField,
            parent: parentField,
            levels
          } = {}
        } = {}
      } = {},

      pluginData: result,
      isFieldTypeEqual
    } = nextProps || this.props

    const fields = this.getFieldConfigs()

    if (!_.isEqual(this.state.checked, checked)) {
      this.selectionStateChanged(checked)
    }

    const isThereSelectedNode = Boolean(checked.length)

    const isSendNull =
      sendNull &&
      multiple === 'Multiple Values' &&
      checked[0] === rootValue &&
      checked[checked.length - 1] !== filteredRootValue

    if (isSendNull) {
      checked = []
    }

    const isString = isFieldTypeEqual(parentField, 'string')
    const isValueString = isFieldTypeEqual(valueField, 'string')
    const checkedObj = _.map(checked, (c) => {
      const [, sValue, Slevel, Sparent] = _.split(c, '~')
      return {
        value: isValueString ? sValue : parseInt(sValue, 10),
        level: parseInt(Slevel, 10),
        parent: isString ? Sparent : parseInt(Sparent, 10)
      }
    })

    let maxLevel = 0
    const selectedValues = _.filter(result, (r) => {
      const value = r[valueField]
      const level = levelField && _.has(r, levelField) ? r[levelField] : 0
      maxLevel = Math.max(maxLevel, level)
      return (
        _.find(checkedObj, (c) => c.level === level * 1 && `${c.value}` === `${value}`) !==
        undefined
      )
    })

    if (_.isNaN(maxLevel)) return null

    const isMultiple = multipleTexts.some((text) => text === multiple)

    let values
    if (isMultiple && omitChildrenWhenAllChildrenSelected && maxLevel > 0) {
      values = this.getNodesWithAllChildren(
        result,
        selectedValues,
        maxLevel + 1,
        valueField,
        parentField,
        levelField,
        multiple
      )
      if (!disableSendParentWhenPartiallySelected) {
        this.fillWithParents(result, values, valueField, levelField, parentField, maxLevel - 1)
      }
      values = _.uniqBy(values, (obj) => obj[valueField])
    } else {
      values = this.fillWithParents(
        result,
        selectedValues,
        valueField,
        levelField,
        parentField,
        maxLevel - 1
      )
    }

    const nodesLevels = _.keyBy(levels, (l) => l.level)
    const nodes = values?.map(({ halfChecked, ...node }) => node)

    const ret = _.transform(
      nodes,
      (checkedWithParentsResult, selectedValue) => {
        const nodeLevel = nodesLevels?.[selectedValue[levelField] ?? 0]
        const selectedDisplayField = selectedValue[displayField]
        const selectedValueField = selectedValue[valueField]

        if (nodeLevel && Object.keys(nodeLevel).length > 0) {
          const variableDisplayField = `${nodeLevel.variable}_${displayField}`
          const variableDisplayFieldResult = checkedWithParentsResult[variableDisplayField]

          const variableValueField = `${nodeLevel.variable}_${valueField}`
          const variableValueFieldResult = checkedWithParentsResult[variableValueField]

          if (isMultiple) {
            const isValueFieldEmpty = !variableValueFieldResult || variableValueFieldResult === null
            const isDisplayFieldEmpty =
              !variableDisplayFieldResult || variableDisplayFieldResult === null

            if (isDisplayFieldEmpty) {
              checkedWithParentsResult[variableDisplayField] = []
            }

            if (isValueFieldEmpty) {
              checkedWithParentsResult[variableValueField] = []
            }

            if (isDisplayFieldEmpty || isValueFieldEmpty) {
              _.forEach(selectedValue, (_value, key) => {
                const variableColumnKey = `${nodeLevel.variable}_Column_${key}`
                checkedWithParentsResult[variableColumnKey] = []
              })
            }

            checkedWithParentsResult[variableDisplayField].push(selectedDisplayField)
            checkedWithParentsResult[variableValueField].push(selectedValueField)

            _.forEach(selectedValue, (value, key) => {
              const variableColumnKey = `${nodeLevel.variable}_Column_${key}`
              checkedWithParentsResult[variableColumnKey].push(value)
            })
          } else {
            checkedWithParentsResult[variableDisplayField] = selectedDisplayField
            checkedWithParentsResult[variableValueField] = selectedValueField

            _.forEach(selectedValue, (value, key) => {
              const variableColumnKey = `${nodeLevel.variable}_Column_${key}`
              checkedWithParentsResult[variableColumnKey] = value
            })
          }
        } else if (isMultiple) {
          const isResultEmpty = !checkedWithParentsResult.value || !checkedWithParentsResult.display
          if (isResultEmpty) {
            checkedWithParentsResult.value = []
            checkedWithParentsResult.display = []

            _.forEach(selectedValue, (_value, key) => {
              const columnKey = `Column_${key}`
              checkedWithParentsResult[columnKey] = []
            })
          }

          checkedWithParentsResult.value.push(selectedValueField)
          checkedWithParentsResult.display.push(selectedDisplayField)

          _.forEach(selectedValue, (value, key) => {
            const columnKey = `Column_${key}`
            checkedWithParentsResult[columnKey].push(value)
          })
        } else {
          checkedWithParentsResult.value = checkedWithParentsResult.value || selectedValueField
          checkedWithParentsResult.display =
            checkedWithParentsResult.display || selectedDisplayField

          _.forEach(selectedValue, (value, key) => {
            const columnKey = `Column_${key}`
            checkedWithParentsResult[columnKey] = value
          })
        }
      },
      _.transform(
        nodesLevels,
        (result, { variable }) => {
          result[`${variable}_${displayField}`] = null
          result[`${variable}_${valueField}`] = null
          _.forEach(fields, (fieldConfig) => {
            result[`${variable}_Column_${fieldConfig.fieldName}`] = null
          })
        },
        _.transform(
          fields,
          (result, fieldConfig) => {
            result[`Column_${fieldConfig.fieldName}`] = null
          },
          {
            value: null,
            display: null
          }
        )
      )
    )

    ret.nodesSelected = isThereSelectedNode
    ret.nodesNotSelected = !isThereSelectedNode

    return ret
  }

  handleClearAllSelections(event) {
    event.stopPropagation()

    this.lastOperation.current = 'clear'
    this.setState({ checked: [] })
  }

  handleSelectPrevNext(walk) {
    return (e) => {
      const {
        settings: {
          config: {
            data: {
              display: displayField,
              parent: parentField = 0,
              value: valueField,
              level: levelField
            } = {}
          } = {}
        } = {},
        pluginData: result
      } = this.props
      const { checked = [] } = this.state
      if (displayField && result) {
        const firstCheck = _.first(checked)
        const values = _.map(result, (r) =>
          prependItem(r, displayField, valueField, levelField, parentField)
        )
        let index = _.findIndex(values, (v) => _.isEqual(v, firstCheck)) + walk

        if (index >= values.length) index = 0
        if (index < 0) index = values.length - 1

        this.setState({
          checked: [values[index]]
        })
      }
    }
  }

  bodyClick(event) {
    const { show } = this.state

    const isDescendant = this.isDomDescendant(this.refs.filter_container, event.target)

    if (show && !isDescendant) {
      this.setState({ show: false })
    }
  }

  selectionStateChanged(values) {
    const { settings: { config: { settings: { multiple = 'Single Value' } = {} } = {} } = {} } =
      this.props
    if (multiple === 'Single Value') return { selectedValues: values[0] }
    return { selectedValues: values }
  }

  isDomDescendant(parent, child) {
    if (parent === child) {
      return true
    }
    let node = child.parentNode
    while (node != null) {
      if (node === parent) {
        return true
      }
      node = node.parentNode
    }
    return false
  }

  setSelectionEnabled() {
    this.setState({ visible: 'visible' })
  }

  setSelectionDisabled() {
    this.setState({ visible: 'hidden' })
  }

  setSelectionState(values) {
    const { settings: { config: { settings: { multiple = 'Single Value' } = {} } = {} } = {} } =
      this.props

    if (multiple === 'Single Value') {
      const valuesArray = []
      valuesArray.push(values.selectedValues)
      this.setState({ checked: valuesArray })
    } else if (_.isNil(values.selectedValues)) {
      this.setState({ checked: [] })
    } else {
      this.setState({ checked: values.selectedValues })
    }
  }

  fillWithParents(result, values, valueField, levelField, parentField, currLevel) {
    const { settings: { config: { settings: { multiple = 'Single Value' } = {} } = {} } = {} } =
      this.props

    const newValue = _.transform(
      result,
      (newResult, r) => {
        const value = r[valueField]
        const level = levelField && _.has(r, levelField) ? r[levelField] : 0
        if (level === currLevel) {
          const f = _.find(
            values,
            (c) => _.isEqual(c[levelField] - 1, level) && _.isEqual(`${c[parentField]}`, `${value}`)
          )

          if (multiple === 'Single Value') {
            r.halfChecked = Boolean(f)
          }

          if (f) {
            newResult.push(r)
          }
        }
      },
      values
    )

    if (currLevel <= 0) return newValue
    return this.fillWithParents(
      result,
      newValue,
      valueField,
      levelField,
      parentField,
      currLevel - 1
    )
  }

  getNodesWithAllChildren = (
    allNodes,
    selectedNodes,
    levelCount,
    valueField,
    parentField,
    levelField,
    multiple
  ) => {
    // Precondition: We assume the nodes in selectedNodes are all max level nodes
    const { settings: { config: { settings: { sendNull = false } = {} } = {} } = {} } = this.props
    const siblingDict = {}
    const parentDict = {}

    const finalNodes = []

    const getSiblings = (parentId) => {
      if (!_.has(siblingDict, parentId)) {
        siblingDict[parentId] = _.filter(allNodes, {
          [parentField]: parentId
        })
      }
      return siblingDict[parentId]
    }

    const getParent = (parentId) => {
      if (!_.has(parentDict, parentId)) {
        parentDict[parentId] = _.find(allNodes, { [valueField]: parentId })
      }
      return parentDict[parentId]
    }

    const levelNodes = selectedNodes?.reduce((acc, node) => {
      const level = levelCount - node[levelField] - 1
      if (!acc[level]) {
        acc[level] = []
      }

      acc[level].push(node)
      return acc
    }, [])

    // Iterate in all levels of the tree
    for (var i = 0; i < levelCount; i++) {
      if (!levelNodes[i + 1]) {
        levelNodes[i + 1] = []
      }
      // eslint-disable-next-line no-loop-func
      _.forEach(levelNodes[i], (node) => {
        if (node) {
          // Get the siblings (and self) of the node
          const siblingNodes = getSiblings(node[parentField])

          // Compare siblings to the selected nodes
          const diffNodes = _.differenceBy(siblingNodes, levelNodes[i], valueField)
          const equalNodes =
            _.size(siblingNodes) === 1 &&
            _.size(levelNodes[i]) === 1 &&
            _.isEqual(siblingNodes, levelNodes[i]) &&
            !sendNull

          if (_.size(diffNodes) === 0) {
            // If all children of the parent are selected, add the parent node
            // to the upper level and ignore this node
            if (
              !_.find(levelNodes[i + 1], {
                [valueField]: node[parentField]
              })
            ) {
              const parentNode = getParent(node[parentField])
              if (parentNode) {
                levelNodes[i + 1].push(parentNode)
              }
            }

            if (equalNodes && !sendNull) {
              finalNodes.push(node)
            }
          } else {
            // If some children of the parent of this node are not selected,
            // this node is included
            finalNodes.push(node)
          }
        }
      })
    }

    if (finalNodes.length === 0 && !sendNull && multiple === 'Multiple Values') {
      return levelNodes[levelNodes.length - 2]
    }

    return finalNodes
  }

  clearValue() {
    this.setState({ checked: [] })
  }

  getFieldConfigs() {
    const {
      data: { schema, schema: fieldConfigsSchema = [] } = {},
      settings: { query: { fields: fieldConfigsQuery = [] } = {} } = {}
    } = this.props || {}

    if (schema) {
      return fieldConfigsSchema
    }
    return fieldConfigsQuery
  }

  getNodes(
    treeDict,
    parentId = 0,
    { displayField, parentField, valueField, levelField, iconField, iconColorField },
    currLevel = 0,
    nodesLevels = {},
    initiallyExpandedNodes
  ) {
    const {
      settings: { config: { settings: { initiallyExpandAllNodes = false } = {} } = {} } = {}
    } = this.props

    const nodeLevel = nodesLevels[currLevel] || {}
    const { enabled: levelEnabled = true } = nodeLevel || {}

    const children = (treeDict && treeDict[currLevel] && treeDict[currLevel][parentId]) || []

    if (!children) return []
    return _.map(children, (child) => {
      const {
        [displayField]: childDisplay,
        [valueField]: childValue,
        [iconField]: childIcon,
        [iconColorField]: iconColor,
        halfChecked
      } = child

      let icon = null
      if (childIcon || nodeLevel.icon) {
        icon = (
          <i
            aria-hidden="true"
            className={`fa ${childIcon || nodeLevel.icon}`}
            {...(iconColor && { style: { color: iconColor } })}
          />
        )
      }

      const value = prependItem(child, displayField, valueField, levelField, parentField)
      const children = this.getNodes(
        treeDict,
        childValue,
        {
          displayField,
          parentField,
          valueField,
          levelField,
          iconField,
          iconColorField
        },
        currLevel + 1,
        nodesLevels,
        initiallyExpandedNodes
      )

      if (initiallyExpandAllNodes && children.length > 0) {
        initiallyExpandedNodes.push(value)
      }

      return {
        value,
        icon,
        search: _.toLower(childDisplay),
        label: String(childDisplay),
        className: cx({
          'react-checkbox-tree-node-enabled': levelEnabled,
          'react-checkbox-tree-node-disabled': !levelEnabled,
          halfChecked
        }),
        children
      }
    })
  }

  getTreeViewProps() {
    const {
      settings: {
        config: {
          general: { name = null } = {},
          settings: {
            multiple = 'Single Value',
            showAllChildrenOnSearch = false,
            omitChildrenWhenAllChildrenSelected = false,
            maximumNumberOfSelectableItem,
            placeholder = ''
          } = {},
          data: {
            display: displayField,
            parent: parentField = 0,
            value: valueField,
            level: levelField,
            icon: iconField,
            iconColor: iconColorField,
            levels = {}
          } = {}
        } = {}
      } = {},
      pluginData: result,
      isPreviewMode
    } = this.props

    if (result) {
      const treeDict = {}

      // Build helper dictionary to speed up the tree building
      _.forEach(result, (row) => {
        const level = levelField && _.has(row, levelField) ? row[levelField] : 0
        const parent = parentField && _.has(row, parentField) ? row[parentField] : 0
        if (!treeDict[level]) {
          treeDict[level] = {}
        }
        if (!treeDict[level][parent]) {
          treeDict[level][parent] = []
        }
        treeDict[level][parent].push(row)
      })

      const initiallyExpandedNodes = []
      const nodes = this.getNodes(
        treeDict,
        0,
        {
          displayField,
          parentField,
          valueField,
          levelField,
          iconField,
          iconColorField
        },
        0,
        _.keyBy(levels, (l) => l.level),
        initiallyExpandedNodes
      )

      if (multiple === 'Multiple Values') {
        nodes.unshift(selectAllNode)
      }
      const { checked } = this.state
      return {
        showAllChildrenOnSearch,
        omitChildrenWhenAllChildrenSelected,
        multiple,
        maximumNumberOfSelectableItem,
        nodes,
        initiallyExpandedNodes,
        checked,
        onCheck: this.handleCheck,
        levels,
        placeholder,
        pluginName: name,
        isPreviewMode
      }
    }
  }

  _renderLayer() {
    const {
      show,
      size: { left, bottom, width, height = 0 }
    } = this.state

    const treeViewOverflow = document.body.offsetHeight - (bottom + 320)

    ReactDOM.render(
      <div
        className={treeViewOverflow < 0 ? 'filter-treeview-static-height' : ''}
        style={{
          visibility: show ? 'visible' : 'hidden',
          position: 'fixed',
          width: width < 300 ? 300 : width,
          top:
            treeViewOverflow < 0
              ? bottom - (height - document.scrollingElement.scrollTop + 230)
              : bottom,
          left,
          zIndex: 999999
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <TreeViewContent {...this.getTreeViewProps()} />
      </div>,
      this.popup
    )
  }

  findMainBranch(v, result, displayField, valueField, levelField, parentField) {
    const item = _.find(result, (r) => r[valueField] === v)
    if (item) {
      const parentValue = item[parentField]
      const g = this.findMainBranch(
        parentValue,
        result,
        displayField,
        valueField,
        levelField,
        parentField
      )
      if (g) {
        return g
      }
      return prependItem(item, displayField, valueField, levelField, parentField)
    }
    return false
  }

  render() {
    const { checked = [], visible = 'visible' } = this.state
    const {
      settings: {
        config: {
          settings: {
            easySelect = false,
            label = '',
            placeholder = '',
            dropdown = true,
            propertyNameOfSelector = 'items',
            showClearAllSelectionsBtn = true
          } = {}
        }
      }
    } = this.props
    const checkedWithoutAll = _.filter(checked, (c) => c !== rootValue && c !== filteredRootValue)
    const v =
      checked.length < 3
        ? _.join(
            _.map(checkedWithoutAll, (c) => _.split(c, '~')[0]),
            ', '
          ) || placeholder
        : `${checkedWithoutAll.length} ${propertyNameOfSelector} selected`
    if (dropdown) {
      return (
        <div ref="filter_container">
          <div className={`filter-treeview-wrapper p-1 -${visible}`}>
            <div className="filter-treeview-container">
              {!_.isEmpty(label) && <label>{label}:</label>}

              {easySelect && (
                <i
                  className="fa fa-chevron-left fa-md fw-common -left"
                  onClick={this.handleSelectPrevNext(-1)}
                />
              )}

              <div
                ref="filter_content"
                className="filter-treeview"
                onClick={visible === 'visible' ? this.handleClick : () => {}}
              >
                <div className="filter-treeview-content" title={v}>
                  <span className="selected-node-names">{v}</span>
                </div>

                <div className="filter-right-nav">
                  {showClearAllSelectionsBtn && checked.length > 0 ? (
                    <i
                      className="fa fa-times clear-all-selections-btn"
                      title="Clear all selections"
                      onClick={this.handleClearAllSelections}
                    />
                  ) : null}
                  <i className="fa fa-chevron-down fa-md chevron-down" />
                </div>
              </div>

              {easySelect && (
                <i
                  className="fa  fa-chevron-right fa-md fw-common -right"
                  onClick={this.handleSelectPrevNext(1)}
                />
              )}
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="filter-treeview-static">
        <TreeViewContent {...this.getTreeViewProps()} />
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  data: props.data,
  getFieldType: props.getFieldType,
  isFieldTypeEqual: props.isFieldTypeEqual,
  isPluginDataLoading: props.isPluginDataLoading,
  isPreviewMode: props.isPreviewMode
})

export default createPlugin(FilterTreeview, selectConnectorProps)
