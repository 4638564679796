import React from 'react'
import { CalendarControlsProps } from './types/CalendarControls.types'

const CalendarControls = ({
  year,
  onPrevYear = () => {},
  onNextYear = () => {},
  goToToday = () => {},
  showTodayButton = false
}: CalendarControlsProps) => {
  let todayButton
  if (showTodayButton) {
    todayButton = (
      <div className="control today" onClick={() => goToToday()}>
        Today
      </div>
    )
  }

  return (
    <div className="calendar-controls">
      <div className="control" onClick={() => onPrevYear()}>
        &laquo;
      </div>
      <div className="current-year">{year}</div>
      <div className="control" onClick={() => onNextYear()}>
        &raquo;
      </div>
      {todayButton}
    </div>
  )
}

export default CalendarControls
