import { v4 as uuidv4 } from 'uuid'
import { getUserColumnDefsAsFlat } from '../../helpers'
import { DATA_TYPES, DEFAULT_CUSTOM_ROW_INFO } from '../../constants'
import { IRowData } from '../../types'
import { AG_GRID_ENUMS } from '../../enums'
import { HandleAddRecordProps } from './useHandleAddRecord.types'

const { CUSTOM_ROW_INFO, ADDED_ROWS, ROW_ID } = AG_GRID_ENUMS

function useHandleAddRecord(props: HandleAddRecordProps) {
  const { adding, rowAddedEventRef, columnDefs, gridRef, setTotalRowCount, totalRowCount } = props

  const handleAddRecord = () => {
    const { addingType = 'Direct' } = adding
    if (addingType === 'Trigger') {
      rowAddedEventRef?.current?.handleRowAdded()
      return
    }

    const rowId = uuidv4()

    const newRowData: IRowData = {
      [CUSTOM_ROW_INFO]: {
        ...DEFAULT_CUSTOM_ROW_INFO,
        [ROW_ID]: rowId,
        [ADDED_ROWS]: [rowId]
      }
    }

    const userColumnDefsAsFlat = getUserColumnDefsAsFlat(columnDefs)

    userColumnDefsAsFlat.forEach((colDef) => {
      const {
        field,
        customInfo: {
          fieldConfig: { dataType }
        }
      } = colDef

      if (typeof field !== 'undefined') {
        newRowData[field] = dataType ? DATA_TYPES[dataType] : null
      }
    })

    gridRef?.current?.api.applyTransactionAsync(
      { add: [newRowData], addIndex: totalRowCount },
      () => {
        const firstEditableCol = userColumnDefsAsFlat.find(
          ({ editable, customInfo }) => editable && customInfo.adding.enabled
        )?.field as string

        gridRef?.current?.api.startEditingCell({
          rowIndex: totalRowCount,
          colKey: firstEditableCol
        })

        setTotalRowCount((prev) => prev + 1)
      }
    )
  }

  return { handleAddRecord }
}

export default useHandleAddRecord
