import React, { ReactElement, PropsWithChildren } from 'react'
import { render } from '@testing-library/react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import rootReducer from '../../store/reducers'

type mockStateKeysTypes = any

const defaultState = { ...rootReducer(undefined, {}) }

const getMockState = () => {
  return {}
}

const getStateWithMocksByKeys = (keys: mockStateKeysTypes[] = []) => {
  const state: any = { ...defaultState }
  const mockState = getMockState()
  keys.forEach((key) => {
    if (key in mockState) {
      // @ts-ignore
      state[key] = { ...mockState[key] }
    }
  })
  return { ...state }
}

const getState = () => ({ ...defaultState })

const stateWithMocks = getStateWithMocksByKeys(Object.keys(getMockState()) as mockStateKeysTypes[])

const renderWithProviders = (
  ui: ReactElement,
  { state = stateWithMocks, store = createStore(rootReducer, state), ...renderOptions }: any = {}
) => {
  // eslint-disable-next-line no-undef
  const Wrapper = ({ children }: PropsWithChildren<{}>): JSX.Element => {
    return (
      // @ts-ignore
      // eslint-disable-next-line react/jsx-filename-extension
      <Provider store={store}>
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    )
  }
  return render(ui, { wrapper: Wrapper, ...renderOptions })
}

// re-export everything
export * from '@testing-library/react'

// override render method
export { renderWithProviders as render, getState, getMockState }
