Ext.define('po.Columns', {
  singleton: true,

  mainGridColumns: [
    {
      locked: true,
      bind: {
        text: '{i18n.MainGrid_Column_Urun_Header}'
      },
      sortable: false,
      columns: [
        {
          align: 'center',
          xtype: 'checkcolumn',
          columnId: 'selectCheckboxColumn',
          //@PS related to https://jira.solvoyo.com/browse/CUI-1331
          // bind: {
          //   text: '{selectRowColumnText}'
          // },
          //text: '<div tabindex="-1" role="button" class="x-action-col-icon slvy-text x-action-col-0 x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>',
          width: 40,
          tdCls: 'x-grid-selectbox',
          locked: true,
          cls: 'text-center',
          headerCheckbox: true,
          //@PS related to https://jira.solvoyo.com/browse/CUI-1331
          // menuText:
          //   '1. <div tabindex="-1" role="button" class="x-action-col-icon slvy-menu-text x-action-col-0 x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>',
          dataIndex: 'selected',
          isActionDisabled: function (view, rowIndex, colIndex, item, record) {
            return (
              record.get('IsEditable') == 0 ||
              record.get('isSendToSap') ||
              record.get('SAPYollandi')
            )
          },
          getClass: function (v, meta, record) {
            if (record.get('selected')) {
              return 'x-grid-checkcolumn x-grid-checkcolumn-checked '
            } else if (!record.get('isSendToSap')) {
              return 'x-grid-checkcolumn'
            }
          },
          handler: 'onMainSelectChange',
          listeners: {
            headerClick: 'onselectCheckboxColumnHeaderClick'
          },
          renderer: function (value, meta, record) {
            var isDisabled = record.get('degisenOneriKoli') === 0
            if (isDisabled) {
              if (record?.modified) {
                delete record.modified.selected
              }
              meta.tdCls += ' ' + this.disabledCls
            }
            return new Ext.grid.column.CheckColumn().renderer(value)
          }
        },
        {
          xtype: 'actioncolumn',
          columnId: 'drillDown',
          width: 40,
          hidden: false,
          locked: true,
          align: 'center',
          menuText: '<i class="x-fa fa-circle drilldowncircle"></i>',
          setTooltip: function (value) {
            this.items[0].tooltip = value
          },
          bind: {
            tooltip: '{i18n.MainGrid_actioncolumn_DrillDown}'
          },
          items: [
            {
              iconCls: 'x-fa fa-circle drilldowncircle',
              handler: 'onMainGridDrillDown'
            }
          ]
        },
        {
          xtype: 'actioncolumn',
          columnId: 'calendarEventColumn',
          width: 40,
          hidden: false,
          locked: true,
          align: 'center',
          menuText: '<i class="x-fa fa-calendar blueIcon"></i>',
          setTooltip: function (value) {
            this.items[0].tooltip = value
          },
          bind: {
            tooltip: '{i18n.MainGrid_actioncolumn_CalendarEventColumn}'
          },
          items: [
            {
              iconCls: 'x-fa fa-calendar blueIcon',
              handler: 'onMainGridCalendarEvent'
            }
          ]
        },
        {
          xtype: 'actioncolumn',
          columnId: 'drilldownAction',
          width: 40,
          menuText: '<i class="a fa fa-cart-arrow-down blueIcon"></i>',
          locked: true,
          setTooltip1: function (value) {
            this.items[0].tooltip = value
          },
          setTooltip2: function (value) {
            this.items[1].tooltip = value
          },
          bind: {
            tooltip1: '{i18n.MainGrid_actioncolumn_Istatistik}',
            tooltip2: '{i18n.MainGrid_actioncolumn_TedarikciPerformans}'
          },
          items: [
            {
              iconCls: 'fa fa-cart-arrow-down blueIcon',
              handler: 'onMainGridDrilldownStoreDetail'
            },
            {
              iconCls: 'x-fa fa-truck greenIcon',
              hidden: true
            }
          ]
        },
        {
          sortable: true,
          xtype: 'datecolumn',
          dataIndex: 'devliveryDate',
          columnId: 'devliveryDate',
          width: 100,
          locked: true,
          align: 'center',
          format: 'd-m-Y',
          editor: {
            xtype: 'datefield',
            format: 'd-m-y',
            minValue: new Date(),
            startDay: 1
          },
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          exportRenderer: true,
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'depoAdi',
          columnId: 'depoAdi',
          width: 100,
          locked: true,
          align: 'center',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'depoKodu',
          columnId: 'depoKodu',
          width: 80,
          locked: true,
          align: 'center',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          tdCls: 'blue',
          sortable: true,
          dataIndex: 'urunKodu',
          columnId: 'urunKodu',
          width: 85,
          locked: true,
          align: 'right',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          renderer: function (v, meta, record) {
            if (record.get('IsStarred')) {
              meta.tdCls = 'star'
              return v
            }

            return v
          },
          bind: {
            text: '{i18n.MainGrid_Column_urunkodu}',
            tooltip: '{i18n.MainGrid_Column_urunkodu_tooltip}'
          }
        },
        {
          tdCls: 'blue',
          sortable: true,
          dataIndex: 'urunAciklamasi',
          columnId: 'urunAciklamasi',
          width: 250,
          locked: true,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'SiparisNo',
          columnId: 'SiparisNo',
          width: 90,
          locked: true,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'SiparisKalemNo',
          columnId: 'SiparisKalemNo',
          width: 90,
          locked: true,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          tdCls: 'blue',
          sortable: true,
          dataIndex: 'PRODUCT_BRAND',
          columnId: 'PRODUCT_BRAND',
          width: 80,
          locked: true,
          hidden: true,
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.MainGrid_Column_Siparis_Header}'
      },
      hideable: false,
      locked: true,
      columns: [
        {
          text: '<i class="fa fa-exchange" aria-hidden="true"></i>',
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          bind: {
            tooltip: '{i18n.MainGrid_Column_substitute_tooltip}'
          },
          sortable: true,
          columnId: 'muadil',
          width: 40,
          locked: true,
          dataIndex: 'IsSubstitute',
          xtype: 'actioncolumn',
          align: 'center',
          filter: {
            type: 'boolean'
          },
          getClass: function (v, meta, record) {
            var isEditable = record.get('IsEditable'),
              subStitute = record.get('IsSubstitute'),
              degisenOneriKoli = record.get('degisenOneriKoli')
            if (isEditable == 1 && subStitute == 1 && degisenOneriKoli != 0) {
              return 'array-grid-substitute-col'
            }
            return 'array-grid-substitute-col-none'
          },
          handler: 'onSubstituteClick'
        },
        {
          text: '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>',
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          sortable: true,
          dataIndex: 'onceliklendirme',
          columnId: 'onceliklendirme',
          width: 52,
          align: 'right',
          locked: true,
          menuDisabled: true,
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          }
        },
        {
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          tdCls: 'tooltip-cls',
          sortable: true,
          dataIndex: 'YuvarlanmamisDepoSiparisKoli',
          columnId: 'YuvarlanmamisDepoSiparisKoli',
          flex: 1,
          locked: true,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return "<span style='font-size:1em;font-weight:600'>" + v + '</span>'
          },
          summaryType: 'sum',
          summaryFormatter: 'number("0")',
          filter: {
            type: 'number'
          }
        },
        {
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          tdCls: 'tooltip-cls',
          sortable: true,
          dataIndex: 'depoSiparisKoli',
          columnId: 'depoSiparisKoli',
          flex: 1,
          locked: true,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return "<span style='font-size:1em;font-weight:600'>" + v + '</span>'
          },
          summaryType: 'sum',
          summaryFormatter: 'number("0")',
          filter: {
            type: 'number'
          }
        },
        {
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          tdCls: 'red',
          sortable: true,
          dataIndex: 'degisenOneriKoli',
          columnId: 'degisenOneriKoli',
          flex: 1,
          locked: true,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return (
              "<span style='font-size:1em;color:blue;;font-weight:600'>" +
              Ext.util.Format.number(v, '0') +
              '</span>'
            )
          },
          summaryType: 'sum',
          summaryFormatter: 'number("0")',
          filter: {
            type: 'number'
          },
          getEditor: function (record) {
            if (record.get('IsEditable') == 0) {
              return null
            }

            if (!record.get('SAPYollandi') && !record.get('isSendToSap')) {
              return Ext.create('Ext.grid.CellEditor', {
                field: Ext.create('Ext.form.field.Number', {
                  editable: true,
                  value: record.get('degisenOneriKoli'),
                  minValue:
                    record.get('degisenOneriKoli') >= record.get('minSiparisMiktari') ||
                    record.get('degisenOneriKoli') == 0
                      ? 0
                      : record.get('minSiparisMiktari'),
                  step: record.get('siparisKatsayisi'),
                  listeners: {
                    focus: function (me, event, eOpts) {
                      var value = me.value
                      if (value == 0) {
                        me.setValue(record.get('minSiparisMiktari'))
                      }
                    },
                    focusleave: function (me, event, eOpts) {
                      var value = me.value,
                        step = me.step,
                        mod = value % step
                      if (mod >= step / 2) {
                        me.setValue(value + (step - mod))
                      } else {
                        me.setValue(value - mod)
                      }
                      if (me.getValue() != 0 && me.getValue() < record.get('minSiparisMiktari')) {
                        me.setValue(record.get('minSiparisMiktari'))
                      } else if (me.getValue() == 0) {
                        me.setValue(0)
                      }
                    }
                  }
                })
              })
            }
            return null
          }
        },
        {
          bind: {
            text: '{i18n.EditorView_Sebep}',
            tooltip: '{i18n.EditorView_Sebep}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          tdCls: 'red',
          sortable: true,
          dataIndex: 'sebepKodu',
          columnId: 'sebepKodu',
          exportRenderer: true,
          width: 150,
          locked: true,
          align: 'left',
          renderer: function (v) {
            if (v == 0) return '-'
            var pomainView = this.up('pomainview')
            var viewModel = pomainView.getViewModel()
            var reasonDictionary = viewModel.get('reasonDictionary')

            return '<span style="font-weight:bold">' + reasonDictionary[v] + '</span>'
          },
          getEditor: function (record) {
            if (!record.get('SAPYollandi') && !record.get('isSendToSap')) {
              return Ext.create('po.cmp.ComboWithClear', {
                displayField: 'Reason',
                valueField: 'ReasonId',
                name: 'sebepKodu',
                width: '99%',
                editable: false,
                border: 1,
                style: {
                  borderColor: 'red',
                  borderStyle: 'solid'
                },
                bind: {
                  store: '{reasonStore}',
                  value: '{editorSebepKodu}'
                }
              })
            }
            return null
          }
        },
        {
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          sortable: true,
          dataIndex: 'HesaplananPalet',
          columnId: 'HesaplananPalet',
          width: 90,
          locked: true,
          align: 'right',
          renderer: function (v) {
            var c = Ext.util.Format.number(v, '0.0')
            if (v == 0) return '-'

            return "<span style='font-size:1em;color:#FF8600;font-weight:600'>" + c + '</span>'
          },

          summaryType: 'sum',
          summaryFormatter: 'number("0")',
          filter: {
            type: 'number'
          },
          hidden: true
        },
        {
          sortable: true,
          align: 'center',
          xtype: 'actioncolumn',
          //@PS related to https://jira.solvoyo.com/browse/CUI-1331
          //  menuText:
          //  '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0 array-grid-accept-col" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>',
          columnId: 'onayAction',
          width: 40,
          locked: true,
          //  text: '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0 array-grid-accept-col" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>',
          menuDisabled: true,
          isActionDisabled: function (view, rowIndex, colIndex, item, record) {
            if (record.get('IsEditable') == 0) return true
            if (record.get('SAPYollandi')) return true
            if (record.get('degisenOneriKoli') < 1) return true
            return false
          },
          getClass: function (v, meta, record) {
            if (record.get('degisenOneriKoli') < 1) {
              return ''
            }
            if (record.get('SAPYollandi')) {
              return 'array-grid-sap-icon'
            }
            if (record.get('onayFlag') == 1) {
              return 'array-grid-wait-col'
            } else {
              return 'array-grid-accept-col'
            }
          },
          handler: 'onMainGridApproveChange',
          listeners: {
            headerClick: 'onOnayActionHeaderClick',
            added: function (headerColumn) {
              headerColumn.addCls('custom-checkbox-with-icon-approve-action array-grid-accept-col')
            }
          }
        },
        {
          align: 'center',
          xtype: 'actioncolumn',
          dataIndex: 'SAPVendorApproval',
          columnId: 'SAPVendorApproval',
          width: 40,
          locked: true,
          tdCls: 'x-grid-selectbox',
          bind: {
            text: '{i18n.MainGrid_Column_SAPVendorApproval}',
            tooltip: '{i18n.MainGrid_Column_SAPVendorApproval_tooltip}'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          },
          getClass: function (v, meta, record) {
            if (record.get('SAPVendorApproval')) {
              return 'x-grid-checkcolumn x-grid-checkcolumn-checked '
            } else if (!record.get('isSendToSap')) {
              return 'x-grid-checkcolumn'
            }
          },
          handler: 'onMainSAPVendorApprovalChange'
        }
      ]
    },
    {
      locked: true,
      text: 'ERP',
      sortable: false,
      columns: [
        {
          sortable: true,
          align: 'center',
          xtype: 'actioncolumn',
          //@PS related to https://jira.solvoyo.com/browse/CUI-1331
          // text: '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>',
          // menuText:
          //   '<div tabindex="-1" role="button" class="x-action-col-icon x-action-col-0  x-grid-checkcolumn" style="" data-tabindex-value="0" data-tabindex-counter="1"></div>',
          //headerCheckbox: true,
          columnId: 'erpAction',
          width: 48,
          menuDisabled: true,
          getClass: function (v, meta, record) {
            if (record.get('isSendToSap')) {
              return 'x-grid-checkcolumn x-grid-checkcolumn-checked'
            } else if (!record.get('isSendToSap')) {
              return 'x-grid-checkcolumn'
            }
          },
          handler: 'onMainGridSAPChange',
          locked: true,
          listeners: {
            headerClick: 'onErpActionHeaderClick',
            added: function (headerColumn) {
              headerColumn.addCls(
                'custom-grid-checkcolumn x-action-col-icon x-action-col-0 x-grid-checkcolumn'
              )
            }
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.MainGrid_Column_widgetcolumnSatis}',
        tooltip: '{i18n.MainGrid_Column_widgetcolumnSatis_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      width: 70,
      resizable: false,
      draggable: false,
      xtype: 'widgetcolumn',
      columnId: 'sparkline1',
      widget: {
        xtype: 'sparklinebar',
        width: '100',
        tooltipFormatter: function (me, me2, field) {
          return Ext.util.Format.number(field[0].value, '0.00')
        },
        bind: '{record.MonthlySalesRevLast10}'
      }
    },
    {
      width: 70,
      xtype: 'widgetcolumn',
      columnId: 'sparkline2',
      draggable: false,
      resizable: false,
      widget: {
        xtype: 'sparklinebar',
        width: '100',
        tooltipFormatter: function (me, me2, field) {
          return Ext.util.Format.number(field[0].value, '0.00')
        },
        bind: '{record.Son30GunDepoCikisSparklineLast10}'
      },
      bind: {
        text: '{i18n.MainGrid_Column_widgetcolumnCikis}',
        tooltip: '{i18n.MainGrid_Column_widgetcolumnCikis_tooltip}'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      bind: {
        text: '{i18n.MainGrid_Column_Oneri_header}'
      },
      columns: [
        {
          sortable: true,
          dataIndex: 'gunBasiEldekiStok',
          columnId: 'gunBasiEldekiStok',
          width: 75,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'magazaAcikSiparis',
          columnId: 'magazaAcikSiparis',
          width: 75,
          align: 'right',
          renderer: function (v, meta, record) {
            meta.tdCls = 'openorderstore drilldown'
            if (v == 0) {
              meta.tdCls = 'openorderstore'
              return '-'
            }
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'depoAcikSiparis',
          columnId: 'depoAcikSiparis',
          width: 75,
          align: 'right',
          renderer: function (v, meta, record) {
            meta.tdCls = 'sas drilldown'

            if (v == 0) {
              meta.tdCls = 'sas'
              return '-'
            }
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'depoTumAcikSiparisler',
          columnId: 'depoTumAcikSiparisler',
          width: 75,
          align: 'right',
          renderer: function (v, meta, record) {
            meta.tdCls = 'sas drilldown'

            if (v == 0) {
              meta.tdCls = 'sas'
              return '-'
            }
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'magazaBugunkuReplMiktari',
          columnId: 'magazaBugunkuReplMiktari',
          width: 75,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'gunSonuEldekiStok',
          columnId: 'gunSonuEldekiStok',
          width: 80,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'birSonrakiSipariseKadarkiMagazaTalebi',
          columnId: 'birSonrakiSipariseKadarkiMagazaTalebi',
          width: 75,
          align: 'right',
          renderer: function (v, meta, record) {
            meta.tdCls = 'openorderstore drilldown'
            if (v == 0 || v == null) {
              meta.tdCls = 'openorderstore'
              return '-'
            }
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'minSiparisMiktari',
          columnId: 'minSiparisMiktari',
          width: 70,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          hidden: true,
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'siparisKatsayisi',
          columnId: 'siparisKatsayisi',
          width: 150,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          hidden: true,
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'guvenlikStogu',
          columnId: 'guvenlikStogu',
          width: 75,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'

            return v
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'RevPeriod',
          columnId: 'kacGunlukSiparis',
          flex: 1,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'

            return v
          },
          filter: {
            type: 'number'
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.MainGrid_Column_Tedarikci_header}'
      },
      tdCls: 'custom-column',
      locked: false,
      columns: [
        {
          tdCls: 'blue',
          sortable: true,
          dataIndex: 'tedarikciKodu',
          columnId: 'tedarikciKodu',
          width: 90,
          align: 'center',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'tedarikciAdi',
          columnId: 'tedarikciAdi',
          tdCls: 'blue',
          width: 220,
          locked: false,
          align: 'left',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'leadTime',
          columnId: 'leadTime',
          width: 75,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'ortalamaTedarikSuresi',
          columnId: 'ortalamaTedarikSuresi',
          width: 75,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'sonTedarikSuresi',
          columnId: 'sonTedarikSuresi',
          width: 75,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          xtype: 'datecolumn',
          dataIndex: 'tedarikci_onay_saati_bitis',
          columnId: 'tedarikci_onay_saati_bitis',
          width: 100,
          align: 'right',
          format: 'H:i:s',
          editor: {
            xtype: 'timefield',
            format: 'H:i:s',
            minValue: new Date(),
            startDay: 1
          },
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          exportRenderer: true,
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },
    {
      bind: {
        text: '{i18n.MainGrid_Column_magaza_header}'
      },
      columns: [
        {
          sortable: true,
          dataIndex: 'magazaSayisi',
          columnId: 'magazaSayisi',
          flex: 1,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'magazaGunlukOrtSatisKoli',
          columnId: 'magazaGunlukOrtSatisKoli',
          width: 80,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'birStokGunKoli',
          columnId: 'birStokGunKoli',
          width: 80,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },

    {
      bind: {
        text: '{i18n.MainGrid_Column_urunbilgileri_header}'
      },
      columns: [
        {
          sortable: true,
          dataIndex: 'malzemeAnaGrup',
          columnId: 'malzemeAnaGrup',
          width: 170,
          filter: {
            type: 'list'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'malzemeAltGrup',
          columnId: 'malzemeAltGrup',
          width: 170,
          filter: {
            type: 'list'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'malzemeAile',
          columnId: 'malzemeAile',
          width: 170,
          filter: {
            type: 'list'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'rafOmru',
          columnId: 'rafOmru',
          width: 80,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'CiroPayi',
          columnId: 'CiroPayi',
          width: 90,
          align: 'right',
          renderer: function (v) {
            if (v == 0 || v == null) return '-'
            return Ext.util.Format.number(v, '0.00%')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'koliIciMiktar',
          columnId: 'koliIciMiktar',
          width: 80,
          align: 'right',
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          filter: {
            type: 'number'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'AdetliOneri',
          columnId: 'AdetliOneri',
          width: 100,
          locked: false,
          align: 'left',
          filter: {
            type: 'string',
            itemDefaults: {
              emptyText: ' '
            }
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'PromosyonDurumu',
          columnId: 'PromosyonDurumu',
          width: 160,
          align: 'right',
          filter: {
            type: 'list'
          },
          renderer: function (v, meta, record) {
            meta.tdCls = 'openorderstore drilldown'
            if (v == 'YOK') {
              meta.tdCls = 'openorderstore'
              return ''
            }
            return v
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },

        {
          sortable: true,
          dataIndex: 'PromotionStartDate',
          columnId: 'PromotionStartDate',
          width: 160,
          align: 'right',
          filter: {
            type: 'list'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'PromotionEndDate',
          columnId: 'PromotionEndDate',
          width: 160,
          align: 'right',
          filter: {
            type: 'list'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'PromosyonAraligi',
          columnId: 'PromosyonAraligi',
          width: 160,
          align: 'right',
          filter: {
            type: 'list'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'PromosyonAciklama',
          columnId: 'PromosyonAciklama',
          width: 160,
          align: 'left',
          filter: {
            type: 'list'
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },
    {
      sortable: true,
      dataIndex: 'siparisGunu',
      columnId: 'siparisGunu',
      width: 120,
      align: 'right',
      hidden: true,
      filter: {
        type: 'list'
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      bind: {
        text: '{i18n.MainGrid_Column_stokBilgileri_header}'
      },
      columns: [
        {
          sortable: true,
          dataIndex: 'SifirStokluMagazaSayisi',
          columnId: 'SifirStokluMagazaSayisi',
          width: 77,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v, meta, record) {
            meta.tdCls = 'openorderstore drilldown'
            if (v == 0 || v == null) {
              meta.tdCls = 'openorderstore'
              return '-'
            }
            return Ext.util.Format.number(v, '0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'BlokajliMagazaSayisi',
          columnId: 'BlokajliMagazaSayisi',
          width: 77,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v, meta, record) {
            meta.tdCls = 'openorderstore drilldown'
            if (v == 0 || v == null) {
              meta.tdCls = 'openorderstore'
              return '-'
            }
            return Ext.util.Format.number(v, '0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'Kayip_Ciro',
          columnId: 'Kayip_Ciro',
          width: 100,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0 || v == null) return '-'
            return Ext.util.Format.number(v, '0.0 ₺')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'Son7GunSatisKoli',
          columnId: 'Son7GunSatisKoli',
          width: 90,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'Son7GunSatisKoliArindirilmis',
          columnId: 'Son7GunSatisKoliArindirilmis',
          width: 90,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'Son7GunMagazaSiparis',
          columnId: 'Son7GunMagazaSiparis',
          width: 130,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.00')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'Son7GunDepoCikis',
          columnId: 'Son7GunDepoCikis',
          width: 105,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.00')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'OrtalamaMagazaBasiGorselStok',
          columnId: 'OrtalamaMagazaBasiGorselStok',
          width: 130,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'DepoStokGun',
          columnId: 'DepoStokGun',
          width: 130,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'DepoBakiyeliStokGun',
          columnId: 'DepoBakiyeliStokGun',
          width: 130,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'DepoSiparisliStokGun',
          columnId: 'DepoSiparisliStokGun',
          width: 130,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'DepoForecasteGoreStokGun',
          columnId: 'DepoForecasteGoreStokGun',
          width: 130,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'DepoSiparisliStokGunUpdated',
          columnId: 'DepoSiparisliStokGunUpdated',
          width: 130,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'MagazaStokGun',
          columnId: 'MagazaStokGun',
          width: 140,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        },
        {
          sortable: true,
          dataIndex: 'MagazaStokKoli',
          columnId: 'MagazaStokKoli',
          width: 140,
          align: 'right',
          filter: {
            type: 'number'
          },
          renderer: function (v) {
            if (v == 0) return '-'
            return Ext.util.Format.number(v, '0.0')
          },
          setTooltip: function (value) {
            var element = this.el.getFirstChild()
            element.dom.setAttribute('data-qtip', value)
          }
        }
      ]
    },
    {
      columnId: 'ErrorMessage',
      dataIndex: 'ErrorMessage',
      width: 140,
      align: 'left',
      filter: {
        type: 'string',
        itemDefaults: {
          emptyText: ' '
        }
      },
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      }
    },
    {
      columnId: 'Aciklamalar',
      dataIndex: 'Aciklamalar',
      setTooltip: function (value) {
        var element = this.el.getFirstChild()
        element.dom.setAttribute('data-qtip', value)
      },
      editor: {
        xtype: 'textarea',
        height: 250,
        width: 200,
        maxLength: 255
      },
      width: 200
    }
  ]
})
