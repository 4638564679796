import _ from 'lodash'
import path from 'path'

export const GetPluginSchema = (type) => {
  try {
    const requireContext = require.context(
      './../../plugins/',
      true,
      /^\.\/.*(schema|config).(js|ts)$/
    )
    const configFiles = _.groupBy(requireContext.keys(), (c) => {
      return path.dirname(c)
    })
    if (configFiles) {
      const pluginFiles = _.find(configFiles, (files) => {
        if (files.length < 2) return false
        const {
          default: { namespace = '' }
        } = requireContext(files[0])
        return namespace === type
      })
      if (pluginFiles) {
        const { default: defaultFn } = requireContext(pluginFiles[1])
        return defaultFn()
      }
    }
    return null
  } catch (ex) {
    return null
  }
}

export const GetPluginConfig = (type) => {
  const requireContext = require.context('./../../plugins/', true, /^\.\/.*\.js$/)
  let config = null
  _.each(requireContext.keys(), (r) => {
    if (_.endsWith(r, 'config.js')) {
      const context = requireContext(r)
      if (context.default.namespace === type) {
        config = context.default
      }
    }
  })
  return config
}

export const GetPlugins = () => {
  const requireContext = require.context('./../../plugins/', true, /^\.\/.*\.js$/)
  const filteredKeys = _.filter(requireContext.keys(), (x) => _.endsWith(x, 'config.js'))

  return _.map(filteredKeys, (r) => {
    const c = _.replace(r, 'config.js', 'index.js')
    const pluginContext = requireContext(c)
    const context = requireContext(r)

    const a = { pluginContext, ...context.default }
    return a
  })
}

export function GetPlugin(pluginKey) {
  const g = _.find(GetPlugins(), (value, key) => {
    return pluginKey === value.namespace
  })
  return g && g.pluginContext && g.pluginContext.default
}

const helpers = {
  GetPluginSchema,
  GetPluginConfig,
  GetPlugins,
  GetPlugin
}

export default helpers
