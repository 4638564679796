import { useState } from 'react'
import cx from 'classnames'
import { GlobalFilterProps } from './GlobalFilter.types'

export default function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  className,
  style
}: GlobalFilterProps) {
  const [value, setValue] = useState(globalFilter)

  const handleChangeGlobalFilter = (event) => {
    setValue(event.target.value)
    setGlobalFilter(event.target.value)
  }

  return (
    <input
      className={cx('fs-5', 'text-muted', 'light', 'dark', className)}
      placeholder="Search..."
      style={style}
      value={value}
      onChange={handleChangeGlobalFilter}
    />
  )
}
