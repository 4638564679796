import { fetchCollection, fetchRecord, updateOne, deleteRecord, clearModel } from '../crudoptV3'

const model = 'pluginstate'

export const getPluginStates = (pluginId) => {
  return fetchCollection(model, `/pluginstate/${pluginId}`)
}

export const createPluginState = (key, pluginId, data = {}) => {
  return fetchRecord(
    model,
    '/pluginstate',
    { data },
    {
      collectionKey: {
        path: `/pluginstate/${pluginId}`
      },
      operationKey: key
    }
  )
}

export const updatePluginState = (key, stateId, data = {}) => {
  return updateOne(
    model,
    `/pluginstate/${stateId}`,
    { data },
    {
      key: stateId,
      getRowKey: () => stateId,
      operationKey: key
    }
  )
}

export const deletePluginState = (key, pluginId, stateId) => {
  return deleteRecord(
    model,
    `/pluginstate/${stateId}`,
    {},
    {
      key: stateId,
      getRowKey: () => stateId,
      collectionKey: {
        path: `/pluginstate/${pluginId}`
      },
      operationKey: key
    }
  )
}

export const deleteAllPluginState = (key, pluginId) => {
  return deleteRecord(
    model,
    `/pluginstate/plugin/${pluginId}/deleteAll`,
    {},
    {
      collectionKey: {
        path: `/pluginstate/${pluginId}`
      },
      operationKey: key
    }
  )
}

export const clearPluginStateModel = () => {
  return clearModel(model)
}
