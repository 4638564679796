export default {
  title: 'ChronoTimeline',
  subtitle: 'Solvoyo',
  version: '1.0',
  namespace: 'ChronoTimeline',
  description: 'allows users to track the progression of events, milestones, and changes',
  iconClass: 'fa-history',
  group: 'Views',
  support: {
    editable: true
  },
  queryStatus: {
    isInsertActive: true,
    isUpdateActive: true,
    isDeleteActive: true
  }
}
