import { shouldRefreshData } from '../index'
import { ShouldRefreshData } from '../edit.types'

const getShouldRefreshDataParams = (): ShouldRefreshData => ({
  name: 'update',
  doesDirectUpdate: false,
  // successCode equals -1: refresh, -2: not refresh
  error: { data: { result: [{ isSuccess: true, successCode: -1 }] } }
})

describe('AgGrid:Helpers:Edit:shouldRefreshData', () => {
  let params = getShouldRefreshDataParams()
  beforeEach(() => {
    params = getShouldRefreshDataParams()
  })

  describe('given params.name is update', () => {
    describe('error.data.result[0].isSuccess is true', () => {
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false', () => {
        // successCode cannot affect to the result for this test case
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:true when directUpdate is true', () => {
        params.doesDirectUpdate = true
        // successCode cannot affect to the result for this test case
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(true)
      })
    })
    describe('error.data.result[0].isSuccess is false', () => {
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false, successCode is -1', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is false, successCode is -2', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is false, successCode is undefined', () => {
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is -1', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is true, successCode is -2', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is undefined', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(true)
      })
    })
  })
  describe('given params.name is edit', () => {
    beforeEach(() => {
      params.name = 'edit'
    })
    describe('error.data.result[0].isSuccess is true', () => {
      beforeEach(() => {
        params.name = 'edit'
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false', () => {
        // successCode cannot affect to the result for this test case
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:true when directUpdate is true', () => {
        params.doesDirectUpdate = true
        // successCode cannot affect to the result for this test case
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(true)
      })
    })
    describe('error.data.result[0].isSuccess is false', () => {
      beforeEach(() => {
        params.name = 'edit'
      })

      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false, successCode is -1', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is false, successCode is -2', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false, successCode is undefined', () => {
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is -1', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is true, successCode is -2', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is undefined', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
    })
  })
  describe('given params.name is add', () => {
    beforeEach(() => {
      params.name = 'add'
    })
    describe('error.data.result[0].isSuccess is true', () => {
      beforeEach(() => {
        params.name = 'add'
      })

      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false', () => {
        // successCode cannot affect to the result for this test case
        // params.name = 'add'

        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true', () => {
        params.doesDirectUpdate = true
        // successCode cannot affect to the result for this test case
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
    })
    describe('error.data.result[0].isSuccess is false', () => {
      beforeEach(() => {
        params.name = 'add'
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false, successCode is -1', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is false, successCode is -2', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false, successCode is undefined', () => {
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is -1', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is true, successCode is -2', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is undefined', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
    })
  })
  describe('given params.name is delete', () => {
    beforeEach(() => {
      params.name = 'delete'
    })
    describe('error.data.result[0].isSuccess is true', () => {
      beforeEach(() => {
        params.name = 'delete'
      })

      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false', () => {
        // successCode cannot affect to the result for this test case
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true', () => {
        params.doesDirectUpdate = true
        // successCode cannot affect to the result for this test case
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
    })
    describe('error.data.result[0].isSuccess is false', () => {
      beforeEach(() => {
        params.name = 'delete'
      })

      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false, successCode is -1', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is false, successCode is -2', () => {
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is false, successCode is undefined', () => {
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is -1', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -1 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
      it('should return an object that contains shouldRefresh:false and shouldReset:false when directUpdate is true, successCode is -2', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false, successCode: -2 }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(false)
        expect(result.shouldReset).toBe(false)
      })
      it('should return an object that contains shouldRefresh:true and shouldReset:true when directUpdate is true, successCode is undefined', () => {
        params.doesDirectUpdate = true
        params.error = { data: { result: [{ isSuccess: false }] } }
        const result = shouldRefreshData(params)
        expect(result.shouldRefresh).toBe(true)
        expect(result.shouldReset).toBe(true)
      })
    })
  })

  it('should return an object that contains shouldRefresh:true and shouldReset:true when given params.name is a random text', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    params.name = 'general'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = shouldRefreshData(params)
    expect(result.shouldRefresh).toBe(true)
    expect(result.shouldReset).toBe(true)
  })
})
