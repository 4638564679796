import cx from 'classnames'
import { FormGroup, FormLabel, Col, Row } from 'react-bootstrap'
import { SlvyFormCheck } from '../index'
import { ISlvyFormCheckboxProps } from './SlvyFormCheckbox.types'
import { getFormErrorMsg } from '@/helpers'
import { useUniqueId } from '../../hooks'

export default function SlvyFormCheckbox({
  formCheckProps = {},
  columns = { label: 2, check: 10 },
  inputId,
  noMarginBottom = false,
  onChange,
  label = '',
  isChecked,
  error,
  errorModelLabel = ''
}: ISlvyFormCheckboxProps) {
  const [uniqueId] = useUniqueId()
  const uniqueIdWithPrefix = inputId || `SlvyFormCheckbox-${uniqueId}`

  const handleChange = (checked: boolean) => onChange(checked)

  const errorMsg = getFormErrorMsg(error, errorModelLabel)

  const optionalProps = label ? { controlId: uniqueIdWithPrefix } : {}

  return (
    <FormGroup
      as={Row}
      className={cx({ 'mb-3': !noMarginBottom }, { 'is-invalid': errorMsg })}
      data-testid="slvy-form-checkbox"
      {...optionalProps}
    >
      {label ? (
        <FormLabel
          column
          className="d-flex align-items-center justify-content-end cp fs-6"
          md={columns?.label ?? 12 - columns.check}
        >
          {label}
        </FormLabel>
      ) : null}
      <Col className="d-flex align-items-center" md={columns.check}>
        <SlvyFormCheck
          checked={isChecked}
          feedback={errorMsg}
          formCheckClass="d-flex"
          inputClass={cx({ 'is-invalid': errorMsg })}
          inputId={uniqueIdWithPrefix}
          isValid={!errorMsg}
          size="md"
          onChange={handleChange}
          {...formCheckProps}
        />
      </Col>
    </FormGroup>
  )
}
