Ext.define('tuiv2.model.ScenarioTreeModel', {
  extend: 'tuiv2.model.BaseTree',
  entityName: 'tuiv2/GetScenarioTree',
  idProperty: 'ScenarioID',
  fields: [
    'ScenarioID',
    {
      name: 'expanded',
      type: 'boolean',
      defaultValue: true
    }
  ]
})
