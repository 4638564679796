import { v4 as uuidv4 } from 'uuid'
import { IQuery } from './Condition.types'

export const getEmptyQuery = (): IQuery => {
  return {
    id: uuidv4(),
    rules: [],
    combinator: 'and'
  }
}
