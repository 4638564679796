import { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { useOnClickOutside } from '@/hooks'
import { useTheme } from '../../contexts/ThemeContext'

export default function EditableCell({ cell, onEditCell, className }) {
  const [value, setValue] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef(null)
  const themeStyles = useTheme()

  useEffect(() => {
    setValue(cell.getValue())
  }, [cell.getValue()])

  useOnClickOutside(inputRef, () => {
    setIsEditing(false)
    onEditCell?.(cell, value)
  })

  function handleChangeCellValue(event) {
    setValue(event.target.value)
  }

  function handleEnterValue({ key }) {
    if (key !== 'Enter' && key !== 'Escape') return

    setIsEditing(false)
    onEditCell?.(cell, value)
  }

  function handleToggleCellEditing() {
    if (isEditing) return
    setIsEditing(true)
  }

  return (
    <div className={themeStyles.editableCell} onDoubleClick={handleToggleCellEditing}>
      {isEditing ? (
        <input
          ref={inputRef}
          className={cx(themeStyles.input, className)}
          value={value}
          onChange={handleChangeCellValue}
          onKeyDown={handleEnterValue}
          autoFocus
          onFocus={(event) => event.target.select()}
        />
      ) : (
        <span className={themeStyles.value}>{value}</span>
      )}
    </div>
  )
}
