import { findLast, reduce } from 'lodash'
import { IRowNode } from 'ag-grid-community'
import { objectKeys } from '@/ts/utils'
import {
  getEditingDirtyCells,
  getEditingParams,
  getIsSubstitute,
  prepareUpdateData,
  validateRule
} from '../index'
import { AG_GRID_ENUMS } from '../../enums'
import {
  RequestParams,
  RequestParamsEvent
} from '../../components/MassUpdate/MassUpdateModal/MassUpdateModal.types'
import { GridRefApi, IRowData, IRowDataGrid, TrowDataValue, TrowDisplayRules } from '../../types'
import { RegisterMethodFunctionFieldConfigArg } from '../../hooks/registerEventsMethods/registerEventsMethods.types'
import { GetMappedRowDataProps, GetUpdateRowDataProps, GetValidRowRuleProps } from './row.types'

const { CUSTOM_ROW_INFO, DIRTY_CELLS, ROW_ID } = AG_GRID_ENUMS

// TODO: Missing test
const getUpdateRowData = ({
  fieldConfigs,
  getColDefByField,
  params,
  rowData
}: GetUpdateRowDataProps) => {
  let dirtyRow = { ...rowData }
  objectKeys(params).forEach((field) => {
    if (field in rowData) {
      const oldValue = rowData[field]

      let newValue = params[field] as TrowDataValue
      const { dataType = '' } = fieldConfigs.find(({ fieldName }) => fieldName === field) || {}

      if (dataType === 'datetime') {
        newValue = (params[field] as keyof IRowDataGrid)
          ? new Date(params[field] as keyof IRowDataGrid)
          : null
      }

      // https://dragon.solvoyo.com/Configuration/catalog/62e0f3ec43b5790340b52a97/store/1/menu/62e0f51843b5790eb803db32/page/667f0986352fc23af5085c58
      // Normally 'params' that comes from setRowValues expected to a grid column def.
      // But like in the above link, params field might not be grid column def
      const firstColDef = getColDefByField(field)

      if (firstColDef) {
        const newEvent: RequestParamsEvent = {
          node: { id: rowData[CUSTOM_ROW_INFO][ROW_ID] },
          data: { ...rowData, ...dirtyRow },
          colDef: firstColDef,
          newValue,
          oldValue
        }

        const isSubstitute = getIsSubstitute(firstColDef?.customInfo)

        // use firstColDef if its lookup field
        const $colDef = isSubstitute ? firstColDef : newEvent.colDef

        const { customInfo } = $colDef
        const editingParams = getEditingParams({
          oldValue: newEvent.oldValue,
          newValue: newEvent.newValue,
          customInfo
        })
        const { newValueParams, oldValueParams } = editingParams

        const { currentDirtyCell, otherDirtyCells } = getEditingDirtyCells({
          field,
          id: newEvent.node.id as string,
          dirtyCells: newEvent.data[CUSTOM_ROW_INFO][DIRTY_CELLS]
        })

        const unTouchedValueParams = currentDirtyCell
          ? currentDirtyCell.unTouchedValueParams
          : oldValueParams

        const requestParams: RequestParams = {
          event: newEvent,
          otherDirtyCells,
          isSubstitute,
          newValueParams,
          oldValueParams,
          unTouchedValueParams
        }

        const preparedData = prepareUpdateData(requestParams as RequestParams)
        dirtyRow = { ...dirtyRow, ...preparedData }
      } else {
        // Update row data with income setRowValues params for fields that not in grid column defs.
        dirtyRow[field] = newValue
      }
    }
  })

  return dirtyRow
}

const getGridRowData = (gridApi: GridRefApi | undefined) => {
  const rowData: IRowData[] = []
  gridApi?.forEachNode?.(({ data }: IRowNode<IRowData>) => {
    if (data) {
      rowData.push(data)
    }
  })
  return rowData
}

function getMappedRowData({
  pluginData,
  fieldConfigs,
  editableFields
}: GetMappedRowDataProps): IRowDataGrid[] {
  return pluginData.map((row: IRowDataGrid) => {
    const dateConvertedRowFields: Record<string | number, Date | null> = {}
    const substituteRowFields: Record<string, TrowDataValue> = {}
    const extraSubstituteColumn: Record<string, TrowDataValue> = {}
    objectKeys(row).forEach((fieldKey) => {
      // Use substitudeField data as current column
      const { substitudeField: substituteField } =
        editableFields.find((editField) => editField.field === fieldKey) || {}

      if (substituteField) {
        extraSubstituteColumn[`_SLVY_${fieldKey}_SUBSTITUTE_FIELD_`] = row[fieldKey]

        substituteRowFields[fieldKey] = row[substituteField]

        // Use substituteField as current column fieldKey
        // eslint-disable-next-line no-param-reassign
        fieldKey = substituteField
      }

      const { dataType = '' } = fieldConfigs.find(({ fieldName }) => fieldName === fieldKey) || {}

      if (dataType === 'datetime') {
        dateConvertedRowFields[fieldKey] = row[fieldKey]
          ? new Date(row[fieldKey] as string | number | Date)
          : null
      }
    })
    return {
      ...row,
      ...dateConvertedRowFields,
      ...substituteRowFields,
      ...extraSubstituteColumn
    }
  })
}

// TODO: Fix compareToField test
const getValidRowRule = ({
  params,
  rowDisplayRules
}: GetValidRowRuleProps): TrowDisplayRules[number] | null => {
  const validRule = findLast(rowDisplayRules, (rowRule) => {
    // eslint-disable-next-line prefer-const
    let { compareToField, value, ruleFieldName, operator } = rowRule
    const dataValue = params?.data?.[ruleFieldName as keyof IRowData]

    if (compareToField && compareToField in params?.data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value = params?.data?.[compareToField as keyof IRowData]
    }

    return validateRule({ conditionValue: value, value: dataValue, operator })
  })

  return validRule ?? null
}

// TODO: Missing test
const isRowDataChanged = (data: IRowData, filterParams: RegisterMethodFunctionFieldConfigArg) => {
  return reduce(
    filterParams,
    (match, value, key) => {
      if (key in data && data[key] !== value) {
        // eslint-disable-next-line no-param-reassign
        match = false
      }
      return match
    },
    true
  )
}

export { getUpdateRowData, getGridRowData, getMappedRowData, getValidRowRule, isRowDataChanged }
