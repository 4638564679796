import { useEffect } from 'react'
import { PluginTypes } from '@/BasePlugin'
import { ResetChangesProps } from './useRegisterMethodResetChanges.type'

function useRegisterMethodResetChanges({ registerMethod, handleReset }: ResetChangesProps) {
  useEffect(() => {
    registerMethod({
      args: [{ name: 'refreshKey', type: PluginTypes.string }],
      key: 'resetChanges',
      fn: handleReset
    })
  }, [registerMethod, handleReset])
}

export default useRegisterMethodResetChanges
