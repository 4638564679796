Ext.define('tuiv2.model.TransportationModeModel', {
  extend: 'tuiv2.model.Base',
  fields: [
    'Name',
    'Utilization',
    'Cost',
    'SubTitle',
    {
      name: 'DisplayName',
      calculate: function (data) {
        var value =
          Ext.isEmpty(data.SubTitle) === false ? data.SubTitle + '-' + data.Name : data.Name
        return value
      },
      depends: ['Name', 'SubTitle']
    }
  ],
  entityName: 'tuiv2/GetAvailableTransportationModesForDemands',
  schema: {
    id: 'tuiv2TransportationModeModel',
    urlPrefix: tuiv2.Global.getApiUrl(),
    proxy: {
      type: 'ajax',
      timeout: 600000,
      method: 'POST',
      useDefaultXhrHeader: false,
      cors: true,
      actionMethods: {
        read: 'POST'
      },
      api: {
        read: '{prefix}{entityName}'
      },
      reader: {
        type: 'json',
        rootProperty: 'data',
        totalProperty: 'totalCount',
        keepRawData: true,
        successProperty: 'success',
        messageProperty: 'errors'
      },
      extraParams: {
        facilityKey: null,
        orderNumber: null,
        productCode: null
      }
    }
  }
})
