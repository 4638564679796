Ext.define('MDM.Btl.Discrepancy.MainGrid', {
  extend: 'Ext.grid.Panel',
  // Uncomment to give this component an xtype
  xtype: 'Discrepancy-maingrid',
  config: {
    cfgData: null,
    readOnly: false,
    hideReadOnlyCol: false
  },
  initComponent: function () {
    this.columns = this.buildColumns()
    this.callParent()
  },
  applyCfgData: function (c) {
    var me = this
    if (!c) return c
    var data = c
    var column = me.buildColumns()
    me.store.loadData(data)
    me.reconfigure(me.store, column)
  },
  layout: 'border',
  cls: 'btlmain',
  lockedGridConfig: {
    width: 400,
    split: true,
    collapsible: false,
    listeners: {
      resize: 'onResizeLock'
    }
  },
  features: [
    {
      ftype: 'grouping',
      groupHeaderTpl: '{name}',
      hideGroupedHeader: false,
      enableGroupingMenu: false
    }
  ],
  viewConfig: {
    emptyText: 'No discrepancy found',
    getRowClass: function (record, rowIndex, rowParams, store) {
      return record.get('subdataType')
    }
  },
  emptyText: 'No discrepancy found',
  buildColumns: function () {
    var me = this
    var hideReadOnlyCol = me.getHideReadOnlyCol()
    return MDM.Btl.Column.build(
      [
        {
          text: 'Dimensions',
          dataIndex: 'Title',
          locked: true,
          resizable: false,
          flex: 1,
          renderer: function (value, metaData, record) {
            metaData.tdCls = 'Production'
            return value
          },
          summaryRenderer: function (value, summaryData, dataIndex) {
            return 'Total: '
          }
        },
        {
          menuDisabled: true,
          sortable: false,
          xtype: 'actioncolumn',
          locked: true,
          resizable: false,
          width: 50,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-pencil ',
              isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                return !rec.get('IsEditable')
              },
              getClass: function (v, meta, rec) {
                if (rec.get('IsEditable')) {
                  return 'fa fa-pencil'
                }
                return ''
              },
              handler: 'addToEditPanelDisc'
            }
          ]
        },
        {
          menuDisabled: true,
          sortable: false,
          xtype: 'actioncolumn',
          locked: false,
          resizable: false,
          width: 1,
          align: 'center',
          items: [
            {
              iconCls: 'fa fa-pencil ',
              isActionDisabled: function (view, rowIndex, colIndex, item, rec) {
                return !rec.get('IsEditable')
              },
              getClassp: function (v, meta, rec) {
                if (rec.get('IsEditable')) {
                  return 'fa fa-pencil'
                }
                return ''
              },
              handler: 'addToEditPanel'
            }
          ]
        }
      ],
      hideReadOnlyCol
    )
  },
  updateHideReadOnlyCol: function (c) {
    var me = this
    if (me.isConfiguring) return c
    var column = this.buildColumns()
    this.hideReadOnlyCol = c
    me.reconfigure(me.store, column)
    return c
  }
})
