Ext.define('MDM.btlv2.DpiChart.ChartDialog', {
  extend: 'Ext.window.Window',
  // closable:false,
  layout: 'fit',
  width: '70%',
  height: '70%',
  title: 'DPI Charts',
  items: [
    {
      xtype: 'btl-dpichart-dpichart'
    }
  ]
})
