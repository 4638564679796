export const SELECT_PRODUCT = 'SELECT_PRODUCT'
export const TOGGLE_PRODUCTION_INFO = 'TOGGLE_PRODUCTION_INFO'
export const SET_PLUGIN_CONFIGURATION = 'SET_PLUGIN_CONFIGURATION'
export const RESET_PLUGIN_CONFIGURATION = 'RESET_PLUGIN_CONFIGURATION'
export const SELECT_PRODUCT_GRAPH_VALUE = 'SELECT_PRODUCT_GRAPH_VALUE'
export const TOGGLE_RESOURCE_CHART = 'TOGGLE_RESOURCE_CHART'
export const SELECT_LEGEND_OPTION = 'SELECT_LEGEND_OPTION'
export const SAVE_RESPONSE_DATA = 'SAVE_RESPONSE_DATA'
export const SELECT_LEGEND_PRODUCT_ISSUE = 'SELECT_LEGEND_PRODUCT_ISSUE'
export const SELECT_LEGEND_RESOURCE_ISSUE = 'SELECT_LEGEND_RESOURCE_ISSUE'
export const SAVE_RESOURCE_INDEX = 'SAVE_RESOURCE_INDEX'
export const TOGGLE_RESOURCE_BASED_PRODUCTION = 'TOGGLE_RESOURCE_BASED_PRODUCTION'
export const ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE = 'ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE'
export const ON_SORT = 'ON_SORT'
export const TOGGLE_HIERARCHY = 'TOGGLE_HIERARCHY'
export const EXPAND_HIERARCHY = 'EXPAND_HIERARCHY'
export const RESET_HIERARCHY = 'RESET_HIERARCHY'
export const EXPAND_ALL_HIERARCHIES = 'EXPAND_ALL_HIERARCHIES'
export const SET_CONFIG_SETTINGS = 'SET_CONFIG_SETTINGS'
export const ON_PIN_ROW = 'ON_PIN_ROW'
export const SET_RUN_INFO = 'SET_RUN_INFO'
export const SET_COVER_VIOLATION_INDEXES = 'SET_COVER_VIOLATION_INDEXES'
export const FILTER_VALUES = 'FILTER_VALUES'
export const RESET_FILTER_VALUES = 'RESET_FILTER_VALUES'
export const WEEKLY_SORT = 'WEEKLY_SORT'

export const PRODUCT_SELECT_PRODUCT = 'PRODUCT_SELECT_PRODUCT'
export const PRODUCT_TOGGLE_PRODUCTION_INFO = 'PRODUCT_TOGGLE_PRODUCTION_INFO'
export const PRODUCT_SET_PLUGIN_CONFIGURATION = 'PRODUCT_SET_PLUGIN_CONFIGURATION'
export const PRODUCT_RESET_PLUGIN_CONFIGURATION = 'PRODUCT_RESET_PLUGIN_CONFIGURATION'
export const PRODUCT_SELECT_PRODUCT_GRAPH_VALUE = 'PRODUCT_SELECT_PRODUCT_GRAPH_VALUE'
export const PRODUCT_TOGGLE_RESOURCE_CHART = 'PRODUCT_TOGGLE_RESOURCE_CHART'
export const PRODUCT_SELECT_LEGEND_OPTION = 'PRODUCT_SELECT_LEGEND_OPTION'
export const PRODUCT_SAVE_RESPONSE_DATA = 'PRODUCT_SAVE_RESPONSE_DATA'
export const PRODUCT_SELECT_LEGEND_PRODUCT_ISSUE = 'PRODUCT_SELECT_LEGEND_PRODUCT_ISSUE'
export const PRODUCT_SELECT_LEGEND_RESOURCE_ISSUE = 'PRODUCT_SELECT_LEGEND_RESOURCE_ISSUE'
export const PRODUCT_SAVE_RESOURCE_INDEX = 'PRODUCT_SAVE_RESOURCE_INDEX'
export const PRODUCT_TOGGLE_RESOURCE_BASED_PRODUCTION = 'PRODUCT_TOGGLE_RESOURCE_BASED_PRODUCTION'
export const PRODUCT_ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE =
  'PRODUCT_ON_PRODUCT_SEARCH_INPUT_VALUE_CHANGE'
export const PRODUCT_ON_SORT = 'PRODUCT_ON_SORT'
export const PRODUCT_TOGGLE_HIERARCHY = 'PRODUCT_TOGGLE_HIERARCHY'
export const PRODUCT_EXPAND_HIERARCHY = 'PRODUCT_EXPAND_HIERARCHY'
export const PRODUCT_RESET_HIERARCHY = 'PRODUCT_RESET_HIERARCHY'
export const PRODUCT_EXPAND_ALL_HIERARCHIES = 'PRODUCT_EXPAND_ALL_HIERARCHIES'
export const PRODUCT_SET_CONFIG_SETTINGS = 'PRODUCT_SET_CONFIG_SETTINGS'
export const PRODUCT_ON_PIN_ROW = 'PRODUCT_ON_PIN_ROW'
export const PRODUCT_SET_RUN_INFO = 'PRODUCT_SET_RUN_INFO'
export const PRODUCT_SET_COVER_VIOLATION_INDEXES = 'PRODUCT_SET_COVER_VIOLATION_INDEXES'
export const PRODUCT_FILTER_VALUES = 'PRODUCT_FILTER_VALUES'
export const PRODUCT_WEEKLY_SORT = 'PRODUCT_WEEKLY_SORT'
export const SELECT_SESSION = 'SELECT_SESSION'
export const SET_SESSIONS = 'SET_SESSIONS'

export const SET_SCROLL_POS = 'SET_SCROLL_POS'

// Move mode...
export const TOGGLE_MOVE_MODE = 'TOGGLE_MOVE_MODE'
export const TOGGLE_MOVE_ITEM = 'TOGGLE_MOVE_ITEM'
export const TOGGLE_MOVE_ITEM_GROUP = 'TOGGLE_MOVE_ITEM_GROUP'
export const RESET_MOVE_MODE = 'RESET_MOVE_MODE'
export const RESET_MOVE_MODE_ITEMS = 'RESET_MOVE_MODE_ITEMS'

export const TOGGLE_GRAND_TOTAL_PIN = 'TOGGLE_GRAND_TOTAL_PIN'

export const TOGGLE_LOGGING = 'TOGGLE_LOGGING'
