Ext.define('Spui.view.main.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.spuiMainController',
  /**
   * Called when the view is created
   */
  clicked: false,
  hiddenCfg: true,
  listen: {
    component: {
      'spui-view-main-toolbar-changelang': {
        culterChange: 'init'
      },
      'spui-view-tracking-navigation-main': {
        editwizard: 'onEditWizard'
      },
      'spui-view-wizard-wizardmain': {
        gohome: 'onGoHome'
      },
      'spui-view-main-toolbar-changeuser': {
        userChange: 'onUserChange'
      }
    }
  },
  setAdmin: function () {
    var me = this,
      view = me.getView()
    if (this.hiddenCfg) {
      view.removeCls('main-hide')
      this.hiddenCfg = false
    } else {
      view.addCls('main-hide')
      this.hiddenCfg = true
    }
  },
  init: function () {
    var me = this
    Spui.util.Mask.mask('Dil dosyaları yükleniyor.')
    var map = new Ext.util.KeyMap({
      target: Ext.getBody(),
      binding: [
        {
          key: 'q',
          ctrl: true,
          handler: function () {
            me.setAdmin()
          }
        }
      ]
    })
    Ext.Ajax.on('beforerequest', function (con) {
      var extraParams = con.getExtraParams()
      extraParams = Ext.apply(extraParams, {
        menuId: Spui.Global.getMenuId()
      })
      con.setExtraParams(extraParams)
    })

    Spui.util.Mask.mask('Dil dosyaları yükleniyor.')
    var I18nStore = me.getViewModel().getStore('I18n')
    Spui.util.Mask.unMask('Dil dosyaları yükleniyor.')
    me.mon(I18nStore, {
      scope: me,
      load: me.loadUserProfile,
      single: true
    })
    I18nStore.load()

    var emptyModel = Ext.create('Ext.data.Model', {
      SpotId: -1,
      MaterialCode: -1
    })
    me.getViewModel().set('emptyModel', emptyModel)
  },
  loadUserProfile: function (stora, records, successful) {
    var me = this
    if (!successful) {
      Spui.util.Mask.unMask()
      //@TODO
      me.showErrorPage()
      return
    }
    Spui.util.Mask.mask('Kullanıcı blgileri yükleniyor.')
    Ext.Ajax.request({
      url: Spui.Global.getApiUrl() + '/spui/userPofileRead',
      success: function (response) {
        var obj = JSON.parse(response.responseText),
          userCultere = 'tr'
        if (obj === null) {
          Ext.Msg.show({
            title: tt('Durum'),
            msg: tt('A101 Profiliniz bulunamadı. Lütfen yöneticiniz ile iletişime geçin.'),
            buttons: Ext.Msg.OK,
            animEl: 'elId'
          })
          return
        }
        Spui.Global.setUserObj(obj)
        me.getViewModel().set('userObj', obj)
        if (!Spui.Global.isAdmin()) var Adm = {}
        userCultere = Spui.Global.getCultere()
        Ext.locale = userCultere
        me.loadUserSettings()
      },

      failure: function (response) {
        Spui.util.Mask.unMask()
        me.showErrorPage()
        console.log('server-side failure with status code ' + response.status)
      }
    })
  },
  loadUserSettings: function () {
    var me = this
    Spui.util.Mask.mask('Uygulama ayarları yükleniyor.')
    Spui.util.Mask.mask('Dil dosyaları yükleniyor.')
    var SettingsStore = me.getViewModel().getStore('Settings')
    Spui.util.Mask.unMask('Dil dosyaları yükleniyor.')
    me.mon(SettingsStore, {
      scope: me,
      load: me.startApp,
      single: true
    })
    SettingsStore.load()
  },
  startApp: function () {
    if (!this.clicked) {
      this.clicked = true
    }
    this.changeView()
  },
  changeView: function () {
    var me = this,
      item,
      newView
    item = me.getView().items.items[0]
    if (item) {
      me.getView().remove(item)
    }
    newView = Ext.create('Spui.view.main.CardLayout')
    me.getView().add(newView)
  },
  onUserChange: function () {
    this.init()
  },
  onEditWizard: function (grid, record) {
    var me = this,
      view = me.getView(),
      cardLayout = me.lookup('maincardlayout'),
      newWizard,
      viewmodel = view.getViewModel()

      
    viewmodel.set('searchDisabled',true)
    view.setLoading(tt('Yükleniyor'))
    var model = Ext.create('Spui.data.model.SpotBase', {
      SpotId: record.get('SpotId')
    })
    model.load({
      success: function (record) {
        newWizard = Ext.create('Spui.view.wizard.WizardMain', {
          spotRecord: model,
          searchDisabled:true
        })
        cardLayout.add(newWizard)
        cardLayout.setActiveItem(newWizard)
        view.setLoading(false)
      }
    })
  },
  onGoHome: function (wizard) {
    var me = this,
      view = me.getView(),
      cardLayout = me.lookup('maincardlayout')
    cardLayout.setActiveItem(0)
    wizard.destroy()
    var cycleBtn = this.lookup('addnewspot')
    cycleBtn.setActiveItem(0)
  },
  onClickNewSpot: function () {},
  onChangeView: function (cycleBtn, activeItem) {
    var wizard = this.lookupReference('spotWizard'),
      me = this,
      cardLayout = me.lookup('maincardlayout'),
      view=this.getView(),
      viewmodel=view.getViewModel()
    
    viewmodel.set('searchDisabled',false)
    
    if (activeItem.itemId === 'new') {
      if (cardLayout) var record = Ext.create('Spui.data.model.SpotBase', {})
      const newWizard = Ext.create('Spui.view.wizard.WizardMain', {
        spotRecord: record,
        searchDisabled:false
      })
      cardLayout.add(newWizard)
      cardLayout.setActiveItem(newWizard)
    } else {
      cardLayout.setActiveItem(0)
    }
  }
})
