import { getEditingParams } from '../index'
import { TrowDataValue } from '../../../types'

const getOldValue = () => {
  return 5
}
const getNewValue = () => {
  return 8
}
const getCustomInfo = () => {
  return {
    editing: { lookupDataField: '' },
    fieldLookupConfig: { isLookupQuery: false, substituteField: '' }
  }
}

describe('AgGrid:Helpers:Edit:getEditingParams', () => {
  let oldValue: TrowDataValue = getOldValue()
  let newValue: TrowDataValue = getNewValue()
  let customInfo = getCustomInfo()

  beforeEach(() => {
    oldValue = getOldValue()
    newValue = getNewValue()
    customInfo = getCustomInfo()
  })

  it(`should return an object inclues newValueParams which has id and value as newValue and oldValueParams which has id and value as oldValue 
  if customInfo.fieldLookupConfig.substituteField, customInfo.fieldLookupConfig.isLookupQuery and customInfo.editing.lookupDataField are falsy`, () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getEditingParams({ oldValue, newValue, customInfo })
    expect(result.newValueParams.id).toEqual(8)
    expect(result.newValueParams.value).toEqual(8)
    expect(result.oldValueParams.id).toEqual(5)
    expect(result.oldValueParams.value).toEqual(5)
  })

  it(`should return an object inclues newValueParams and oldValueParams, newValueParams which has id as newValue and value as foundNewValue matching by customInfo.lookupOptions and oldValueParams which has id as foundOldId matching by customInfo.lookupOptions and value as oldValue 
  if customInfo.fieldLookupConfig.substituteField is truthy`, () => {
    customInfo.fieldLookupConfig.substituteField = 'textCol'
    customInfo.fieldLookupConfig.isLookupQuery = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customInfo.lookupOptions = [
      { id: 8, value: 'Yellow' },
      { id: 5, value: 'Green' }
    ]
    oldValue = 'Green'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getEditingParams({ oldValue, newValue, customInfo })

    expect(result.newValueParams.id).toEqual(8)
    expect(result.newValueParams.value).toEqual('Yellow')
    expect(result.oldValueParams.id).toEqual(5)
    expect(result.oldValueParams.value).toEqual('Green')
  })

  it(`should return an object inclues newValueParams which has id and value as newValue and oldValueParams which has id and value as oldValue 
  if customInfo.fieldLookupConfig.substituteField is falsy 
  and one of customInfo.fieldLookupConfig.isLookupQuery or customInfo.editing.lookupDataField is truthy`, () => {
    customInfo.fieldLookupConfig.isLookupQuery = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customInfo.lookupOptions = [
      { id: 8, value: 'Yellow' },
      { id: 5, value: 'Green' }
    ]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getEditingParams({ oldValue, newValue, customInfo })
    expect(result.newValueParams.id).toEqual(8)
    expect(result.newValueParams.value).toEqual(8)
    expect(result.oldValueParams.id).toEqual(5)
    expect(result.oldValueParams.value).toEqual(5)
  })

  it(`should return an object inclues newValueParams and oldValueParams, newValueParams which has id as newValue and value as foundNewValue matching by customInfo.lookupOptions and oldValueParams which has id as oldValue and value as oldValue 
  if customInfo.fieldLookupConfig.substituteField is truthy and oldValue cannot match with one of customInfo.lookupOptions objects`, () => {
    customInfo.fieldLookupConfig.substituteField = 'textCol'
    customInfo.fieldLookupConfig.isLookupQuery = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customInfo.lookupOptions = [
      { id: 1, value: 'Yellow' },
      { id: 2, value: 'Green' }
    ]
    newValue = 1
    oldValue = 'Black'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getEditingParams({ oldValue, newValue, customInfo })

    expect(result.newValueParams.id).toEqual(1)
    expect(result.newValueParams.value).toEqual('Yellow')
    expect(result.oldValueParams.id).toEqual('Black')
    expect(result.oldValueParams.value).toEqual('Black')
  })

  it(`should return an object inclues newValueParams and oldValueParams, newValueParams which has id as newValue and value as newValue and oldValueParams which has id as foundOldId matching by customInfo.lookupOptions and value as oldValue 
  if customInfo.fieldLookupConfig.substituteField is truthy and newValue cannot match with one of customInfo.lookupOptions objects`, () => {
    customInfo.fieldLookupConfig.substituteField = 'textCol'
    customInfo.fieldLookupConfig.isLookupQuery = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customInfo.lookupOptions = [
      { id: 1, value: 'Yellow' },
      { id: 2, value: 'Green' }
    ]
    oldValue = 'Green'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = getEditingParams({ oldValue, newValue, customInfo })

    expect(result.newValueParams.id).toEqual(8)
    expect(result.newValueParams.value).toEqual(8)
    expect(result.oldValueParams.id).toEqual(2)
    expect(result.oldValueParams.value).toEqual('Green')
  })
})
