export default {
  title: 'Numeric',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'FilterNumeric',
  description: 'Numeric filter',
  iconClass: 'fa-filter',
  color: 'navy-bg',
  group: 'Filters'
}
