/* eslint eqeqeq: "off" */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { PRODUCTselectProduct as onProductSelect } from '../../../../actions'
import { slvyToast } from '../../../../../../components'
import { ChartCell } from '../../../../components'
import {
  getCoverKey,
  getCoverStyle,
  getMaxProductValue,
  getMessage,
  getAttachments,
  val,
  editReason,
  format,
  parse,
  cellLoading,
  isStateCellTargetEmpty,
  patchDiff,
  displayOfflineErrorMsg,
  getCoverViolations,
  dropGrandTotal
} from '../../../../utils'
import Queue from '../../../../utils/queue'
import CustomConsole from '../../../../utils/custom-console'

class ProductionChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelection: '2',
      attachments: [],
      updatedPeriodId: -1,
      currentReason: {},
      isUserAllowedToChangeProductionQuanitity: true,
      isUserAllowedToChangeResourceBasedProductionQuantity: true
    }

    this.cellTarget = null
    this.modificationCount = null
  }

  componentDidMount() {
    const {
      settings: {
        history: {
          componentState: { pushState: { TableProduct: { productDictionary = {} } = {} } = {} } = {}
        } = {}
      } = {},
      product: { Index: productIndex },
      productId = 0,
      isAllowed = () => {}
    } = this.props

    const { currentProductPeriod = 0 } = productDictionary[`${productId}${productIndex}`] || {}
    const isUserAllowedToChangeProductionQuanitity = isAllowed(
      'Resource and Production/Production Quantity'
    )
    const isUserAllowedToChangeResourceBasedProductionQuantity = isAllowed(
      'Resource and Production/Resource Based Production Quantity'
    )
    this.setState({
      currentSelection: currentProductPeriod.toString(),
      isUserAllowedToChangeProductionQuanitity,
      isUserAllowedToChangeResourceBasedProductionQuantity
    })
  }

  clickHandler(pindex, periodId, e) {
    e.preventDefault()

    const {
      product: { ProductIndex },
      isConsumedBy,
      onPeriodChange
    } = this.props
    const { currentSelection } = this.state

    if (currentSelection !== pindex) {
      // chartHandler(round(pindex))
      if (!isConsumedBy) {
        this.props.onProductSelect({
          productIndex: ProductIndex,
          periodIndex: parseInt(pindex, 10),
          isResourceChart: false
        })
      }

      onPeriodChange(pindex)

      this.setState({
        currentSelection: pindex,
        updatedPeriodId: periodId,
        attachments: [],
        currentReason: {}
      })
    }
  }

  handleToggle() {
    this.setState({ show: !this.state.show })
  }

  updateHandler(params, e, extras = {}) {
    return new Promise((resolveHandler) => {
      if (_.isEmpty(extras) && _.isEmpty(e)) {
        this.setState({
          currentReason: {},
          attachments: []
        })
      }

      const {
        settings: {
          modificationCount = 0,
          stateHandler: { selectedParameter: { tableName = 'Production' } = {} } = {},
          updateReasons = {},
          resourceAndProductPlanSettings: {
            grandTotal: { isEnabled: isGrandTotalEnabled = true } = {}
          } = {}
        },
        initialFilters: { locationFilter = {}, resourceFilter = {}, productFilter = {} } = {},
        periodDates = {},
        isProductionResourceBased = false,
        resourcePeriodHierarchy = {},
        connectApi = () => {},
        stateHandler = () => {}
      } = this.props
      let { cellTarget } = this

      let { keyCode, target, target: { value = '' } = {} } = e
      const { isUpdate, productId, periodId, resourceId } = params
      const { isForce = false, itemValue = value, clickUpdate = false } = extras

      value = parse(value)

      if ((keyCode === 13 && isUpdate) || (clickUpdate && isUpdate)) {
        const set = {
          isopen: true,
          node: target,
          reasondata: updateReasons
        }

        const update = () => {
          const { currentReason: { code: reasonCode, name: reasonName } = {} } = this.state

          const updateRequest = () => ({
            hierLocationFilter: locationFilter,
            hierResourceFilter: resourceFilter,
            hierProductFilter: productFilter,
            hierProductId: -1,
            fieldName: tableName,
            productId,
            periodId,
            newValue: clickUpdate ? itemValue : value,
            rccpConfig: {
              isGrandTotalEnabled,
              ...(isForce ? { ForceUpdate: true } : {}),
              UpdateReasonCode: reasonCode,
              UpdateReasonDescription: reasonName,
              ModificationCount: this.modificationCount
                ? this.modificationCount
                : modificationCount,
              ...periodDates
            }
          })

          // TODO: Refactor...
          if (target.parentNode.tagName === 'HEADER') cellTarget = target.parentNode
          cellLoading(target.parentNode, true)
          const updateApi = (req) => {
            if (tableName === 'Production') {
              req = { ...req, ...{ resourceId } }
            }
            return new Promise((resolve, reject) =>
              connectApi(
                isProductionResourceBased
                  ? 'UpdateRccpResourcePeriodProductAndReturnHierarchyDiffWPF'
                  : 'UpdateRccpProductPeriodAndReturnHierarchyDiffWPF',
                { ...req, isQueue: true }
              )
                .then((res) => {
                  let {
                    Description = 'Error',
                    Code = -1,
                    Json,
                    IsDiff = false,
                    ModificationCount
                  } = res

                  this.modificationCount = ModificationCount
                  try {
                    Json = JSON.parse(Json)
                  } catch (error) {
                    slvyToast.error({ message: 'Parsing Failed!' })
                    CustomConsole.error(`Error: ${error}`)
                  }
                  let Attachments = []
                  if (Code < 1) {
                    const { Attachments: xAttachments = [] } = Json ?? {}
                    Attachments = xAttachments
                  } else {
                    const { Attachments: xAttachments = [] } = res
                    Attachments = xAttachments
                  }

                  const isAttachments = !_.isEmpty(Attachments)

                  if (isAttachments) {
                    this.setState({
                      attachments: getAttachments(Attachments, value),
                      updatedPeriodId: periodId
                    })
                  }

                  getMessage(Code, Description).then((ret) => {
                    if (ret.isOk) {
                      if (clickUpdate) {
                        this.setState({
                          attachments: [],
                          updatedPeriodId: -1
                        })
                      }

                      if (Code !== 0) {
                        patchDiff({
                          left: resourcePeriodHierarchy,
                          diffJson: Json,
                          isDiff: IsDiff,
                          stateName: 'GetRccpResourcePeriodHierarchyResponseWPF',
                          setState: stateHandler
                        })
                      }

                      isStateCellTargetEmpty(cellTarget)
                        ? cellLoading(target.parentNode, false)
                        : cellLoading(cellTarget, false)
                      resolve()
                    } else {
                      isStateCellTargetEmpty(cellTarget)
                        ? cellLoading(target.parentNode, false)
                        : cellLoading(cellTarget, false)
                      reject()
                    }
                  })
                })
                .catch(() => {
                  displayOfflineErrorMsg()

                  isStateCellTargetEmpty(cellTarget)
                    ? cellLoading(target.parentNode, false)
                    : cellLoading(cellTarget, false)
                  reject()
                })
            )
          }

          Queue.enqueue(updateApi, updateRequest)
            .catch((error) => CustomConsole.error(error))
            .finally(resolveHandler)
        }

        if (_.isEmpty(this.state.attachments)) {
          editReason(set, {
            onConfirm: (e, isok, reason) => {
              this.setState({ currentReason: reason }, () => update())
            },
            onReject: resolveHandler
          })
        } else {
          update()
        }
      } else {
        resolveHandler()
      }
      cellLoading(cellTarget, false)
    })
  }

  popoverHoverFocus(cover = 0, min = 0, max = 0, coverLabel = '') {
    const { settings: { productInfoSettings = [] } = {} } = this.props
    const coverSettings = _.find(productInfoSettings, ({ tableName }) =>
      _.includes(tableName, 'Cover')
    )
    const { format: formatType = '0,0' } = coverSettings || {}

    cover = format(cover, formatType)
    min = format(min, formatType)
    max = format(max, formatType)

    return (
      <Popover id="popver-cover">
        <ul className="">
          <li>
            {coverLabel || 'Cover'}: {cover}
          </li>
          <li>Min Cover: {min}</li>
          <li>Max Cover: {max}</li>
        </ul>
      </Popover>
    )
  }

  render() {
    const {
      resourcePeriodHierarchy: {
        ResourcePeriods: resourcePeriods = {},
        Resources: resources = {}
      } = {},
      resourceIndex = 0,
      isProductionResourceBased = false,
      productPeriod,
      productId,
      periods = [],
      minCoverLimit = -12,
      maxCoverLimit = 112,
      tableName = 'EndingInventory',
      settings,
      settings: {
        coverSettings: { coverLabel = '', cover = '', minCover = '', maxCover = '' } = {},
        productPlanSettings = [],
        stateHandler: { selectedParameter: { Index: SelectedParamIndex = 0 } = {} } = {},
        productInfoSettings = [],
        resourceAndProductPlanSettings: {
          grandTotal: { isEnabled: isGrandTotalEnabled = true } = {}
        } = {},
        views
      },
      // maxForecastValue = getMaxProductValue('IndependentDemand', productPeriod, SelectedParamIndex)
      coverViolationIndexes,
      selectedSession
    } = this.props
    const {
      isUserAllowedToChangeProductionQuanitity = true,
      isUserAllowedToChangeResourceBasedProductionQuantity = true
    } = this.state

    let maxProductPeriodValue
    if (isGrandTotalEnabled) {
      maxProductPeriodValue = getMaxProductValue(
        tableName,
        dropGrandTotal(productPeriod),
        SelectedParamIndex
      )
    } else {
      maxProductPeriodValue = getMaxProductValue(tableName, productPeriod, SelectedParamIndex)
    }

    const productionObj = _.find(productInfoSettings, { tableName: 'Production' }) || {}
    const { format: formatType } = productionObj

    const { attachments, updatedPeriodId } = this.state
    const { tableName: forecastTableName = 'IndependentDemand' } = productPlanSettings[0] /// To be dynamic

    return (
      <aside className="product-period-chart -chart">
        {_.map(productPeriod, (pitem, pindex) => {
          const resourceBasedProduct =
            _.find(resourcePeriods[resourceIndex][pindex].ResourcePeriodProducts, {
              ProductIndex: pitem.ProductIndex
            }) || {}
          let {
            [getCoverKey(cover, 'Cover')]: Cover = 0,
            [getCoverKey(maxCover, 'MaxCover')]: MaxCover = 0,
            [getCoverKey(minCover, 'MinCover')]: MinCover = 0,
            PeriodIndex = 0,
            Values = {},
            IssueValues
          } = pitem

          const { selectedProductPeriod } = this.props

          MinCover = MinCover === 'NaN' ? 0 : MinCover
          MaxCover = MinCover === 'NaN' ? 0 : MaxCover

          const currentPlannedValue = val(Values, 'Production', SelectedParamIndex)
          const ForecastPercentage = maxProductPeriodValue
            ? 100 -
              (val(Values, forecastTableName, SelectedParamIndex) * 100) / maxProductPeriodValue
            : 100

          const Percentage = maxProductPeriodValue
            ? (val(Values, tableName, SelectedParamIndex) / maxProductPeriodValue) * 100
            : 0
          const { Id: periodId = 0, Visible = false } = periods[PeriodIndex] ?? {}
          const wrapperHeight = (MaxCover - MinCover) * 4
          const dotCover = ((Cover - MinCover) * 100) / (MaxCover - MinCover)
          const dotCoverHeight =
            dotCover > maxCoverLimit
              ? maxCoverLimit
              : dotCover < minCoverLimit
              ? minCoverLimit
              : dotCover
          const isUpdate = true
          const coverViolations = getCoverViolations(IssueValues, coverViolationIndexes)
          const coverColor = getCoverStyle(Cover, MinCover, ...coverViolations)

          const { Production: resourceBasedProduction = 0 } = resourceBasedProduct
          const cellValue = isProductionResourceBased
            ? resourceBasedProduction
            : currentPlannedValue

          const isChartCellDisabled = isProductionResourceBased
            ? !isUserAllowedToChangeResourceBasedProductionQuantity
            : !isUserAllowedToChangeProductionQuanitity

          const { IsGrandTotal = true } = periods[pindex] || {}

          return (
            Visible && (
              <figure key={pindex} className={pindex == selectedProductPeriod ? '-active' : ''}>
                <ChartCell
                  attachments={{
                    attachments,
                    isActive: updatedPeriodId === periodId
                  }}
                  disabled={
                    (selectedSession.IsDebug && views.newResourcePlanView) ||
                    isChartCellDisabled ||
                    IsGrandTotal
                  }
                  formatType={formatType}
                  settings={settings}
                  updateHandler={this.updateHandler.bind(this, {
                    isUpdate,
                    productId,
                    periodId,
                    resourceId: resources[resourceIndex]?.Id
                  })}
                  value={format(cellValue, formatType)}
                  onClick={this.clickHandler.bind(this, pindex, periodId)}
                  onFocus={() => this.setState({ currentReason: {}, attachments: [] })}
                />

                <section
                  className="-chart-wrp"
                  onClick={this.clickHandler.bind(this, pindex, periodId)}
                >
                  <b
                    className="-forecast"
                    style={{
                      top: `${
                        ForecastPercentage >= 100
                          ? 100
                          : ForecastPercentage < 0
                          ? 0
                          : ForecastPercentage
                      }%`
                    }}
                  />
                  <div className="-cbar" style={{ height: `${Percentage}%` }} />
                </section>

                <OverlayTrigger
                  overlay={this.popoverHoverFocus(Cover, MinCover, MaxCover, coverLabel)}
                  placement="top"
                  trigger={['hover', 'focus']}
                >
                  <footer onClick={this.clickHandler.bind(this, pindex, periodId)}>
                    <div
                      className="ft-wrp"
                      style={{
                        height: wrapperHeight < 10 ? 25 : wrapperHeight
                      }}
                    >
                      <i className="-cover-line -top" />
                      <i
                        className="-cover-dot"
                        style={{
                          bottom: `${dotCoverHeight}%`,
                          backgroundColor: `${coverColor.backgroundColor}`
                        }}
                      />
                      <i className="-cover-line -bottom" />
                    </div>
                  </footer>
                </OverlayTrigger>
              </figure>
            )
          )
        })}
      </aside>
    )
  }
}

const mapStateToProps = (state) => ({
  periodDates: state.PRODUCTresourcePeriodHierarchy.periodDates,
  resourcePeriodHierarchy: state.PRODUCTresourcePeriodHierarchy,
  resourceIndex: state.PRODUCTclickedProductInfo.resourceIndex,
  isProductionResourceBased: state.PRODUCTresourcePlan.isProductionResourceBased,
  coverViolationIndexes: state.PRODUCTresourcePlan.coverViolationIndexes,
  selectedSession: state.PRODUCTresourcePlan.selectedSession
})

const mapDispatchToProps = {
  onProductSelect
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionChart)
