export const defaultForm = {
  title: '',
  sizeType: 'px',
  height: 200,
  sizeTypeWidth: 'px',
  width: 900,
  fullPage: false,
  hideMode: 'close',
  confirmationMode: false
} as const

export const containerSettings = {
  element: {}
} as const
