import enhancedApi from './endpoints'
import {
  ApprovedLoad,
  CreateLoad,
  DemandDetail,
  DemandLoad,
  DemandTree,
  LastActionDiff,
  LastActionsDiff,
  LoadDetail,
  LoadStop,
  LoadStopEntryTable,
  LoadStopItem,
  LoadStopTable,
  LoadTable,
  LoadTree,
  MapElements,
  MapElementsData,
  MapObjects,
  OrderDetail,
  OrderItem,
  ReasonCodes,
  Reset,
  RunInfo,
  SavedPlan,
  Service,
  ServicePlanInfo,
  ServiceStatus,
  ServiceTask,
  Session,
  SnapshotDiff
} from './endpoint.types'

export const {
  createLoad,
  getAllActionsDiff,
  getAvailableTransportationModes,
  getDemandDetailById,
  getDemandTransportationModes,
  getDemandTree,
  getLastActionDiff,
  getLoadDetailById,
  getLoadStopEntryTable,
  getLoadStopTable,
  getLoadTable,
  getLoadTree,
  getMapElements,
  getReasonCodes,
  getRemoveLoadStop,
  getRunInfo,
  getServices,
  getServiceStatus,
  getSessions,
  getSnapshot,
  getSnapshotCreate,
  getSnapshotDestroy,
  getSnapshotDiff,
  removeLoad,
  removeLoads,
  setApprovedLoads,
  setAutoServe,
  setChangeTransportationMode,
  setRefix,
  setResetPlan,
  setSavedPlan,
  setServeFrom,
  setService,
  setServiceWithConnectionId,
  setSessionStart
} = enhancedApi.endpoints
export type {
  ApprovedLoad,
  CreateLoad,
  DemandDetail,
  DemandLoad,
  DemandTree,
  LastActionDiff,
  LastActionsDiff,
  LoadDetail,
  LoadStop,
  LoadStopEntryTable,
  LoadStopItem,
  LoadStopTable,
  LoadTable,
  LoadTree,
  MapElements,
  MapElementsData,
  MapObjects,
  OrderDetail,
  OrderItem,
  ReasonCodes,
  Reset,
  RunInfo,
  SavedPlan,
  Service,
  ServicePlanInfo,
  ServiceStatus,
  ServiceTask,
  Session,
  SnapshotDiff
}
