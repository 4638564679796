import { useCallback } from 'react'
import {
  getMultiRowSelectedResults,
  getSelectedRowsAfterFilterAndSort,
  omitCustomFields,
  updateWithSubstituteNewValue
} from '../../helpers/index'
import { TfieldConfigs } from '../../types'

function useGetMultiRowSelectedResultParams(fieldConfigs: TfieldConfigs) {
  const getParams = useCallback(
    (gridRef) => {
      return getMultiRowSelectedResults({
        fieldConfigs,
        rows: getSelectedRowsAfterFilterAndSort(gridRef).map((selectedRow) => {
          let data = updateWithSubstituteNewValue({ data: selectedRow, dirtyCells: [] })
          data = omitCustomFields(data)
          return data
        })
      })
    },
    [fieldConfigs]
  )
  return { getParams }
}

export default useGetMultiRowSelectedResultParams
