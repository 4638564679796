import { useState } from 'react'
import _ from 'lodash'
import { Accordion } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import SlvyAutosuggest, {
  SuggestionsContainer
} from '../../../../../../../components/SlvyAutosuggest'
import sortByKey from '../../../../../../../utils/sortByKey'
import { ISearchCallbackParams } from '../../../../Assignees/Assignees.types'
import ContainerHeader from '../../../../Assignees/components/ContainerHeader'
import NotFound from '../../../../Assignees/components/NotFound'
import NoVariables from '../../../../Assignees/components/NoVariables'
import EventMethodButton from '../../components/EventMethodButton'
import { bodyContentHeight, searchOption } from '../../../../Assignees/constants'
import { convertDataForSearch, getFilteredWaitEvents } from '../../../../Assignees/helpers'
import { IPlugin, IRegisterEventMethod, TcountInfo } from '../../../../Common.types'
import { getClassName, getEventsCount } from '../../helpers'
import { IPluginsEventProps } from './PluginsEvent.types'

const PluginsEvent = ({ plugins, eventKeys, handleAddItem }: IPluginsEventProps) => {
  const [searchOptions, setSearchOptions] = useState({ ...searchOption })

  const handleSuggestionSelected = ({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: true
    })
  }

  const handleSuggestionNotFound = ({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: true,
      isSelected: true
    })
  }

  const handleQueryChange = ({ options }: ISearchCallbackParams) => {
    setSearchOptions({
      query: options.query,
      notFound: false,
      isSelected: false
    })
  }

  const mPlugins: IPlugin[] = plugins.filter((plugin) => plugin?.registers?.events)
  const filteredPlugins: IPlugin[] = getFilteredWaitEvents(mPlugins, searchOptions)

  const searchData = convertDataForSearch(filteredPlugins)

  const isNotFound = searchOptions.query && searchOptions.notFound

  const renderSuggestionsContainer = (suggestionsContainerProps: any) => (
    <SuggestionsContainer
      {...suggestionsContainerProps}
      containerStyle={{ overflowY: 'auto', maxHeight: bodyContentHeight - 50 }}
    />
  )

  const countInfos: TcountInfo[] = filteredPlugins.map((plugin) => {
    const { id, registers: { events = {} } = {} } = plugin
    const sortedEvents = sortByKey(events) as IRegisterEventMethod
    const { active, total } = getEventsCount(eventKeys, sortedEvents, id, 'plugin')
    return { active, total, id }
  })

  return (
    <>
      <SlvyAutosuggest
        data={searchData}
        placeholder="Search"
        renderSuggestionsContainer={renderSuggestionsContainer}
        size="sm"
        onQueryChange={handleQueryChange}
        onSuggestionNotFound={handleSuggestionNotFound}
        onSuggestionSelected={handleSuggestionSelected}
      />
      {isNotFound ? (
        <NotFound query={searchOptions.query} tags={[]} />
      ) : (
        <div className="overflow-y-auto pe-1" style={{ height: bodyContentHeight - 65 }}>
          <Accordion
            alwaysOpen
            data-testid="plugins-event"
            defaultActiveKey={countInfos.filter((item) => item.active).map((item) => item.id)}
          >
            {_.map(filteredPlugins, (plugin) => {
              const {
                id,
                type,
                config: { general: { name = '' } = {} } = {},
                registers: { events = {} } = {}
              } = plugin

              const sortedEvents = sortByKey(events)
              const { active, total } = countInfos.find((item) => item.id === id) || {}
              const title = <h6>{`${name}@${type}`}</h6>

              return (
                <Accordion.Item key={uuidv4()} eventKey={id}>
                  <Accordion.Header className="mb-0 pb-0">
                    <ContainerHeader active={active} title={title} total={total} type="" />
                  </Accordion.Header>
                  <Accordion.Body className="p-3">
                    {total ? null : <NoVariables text="No events are found!" />}
                    <div className="d-flex flex-wrap gap-2">
                      {_.map(sortedEvents, (_event, key) => {
                        const classParams = {
                          pluginId: id,
                          eventKey: key
                        }
                        const variant = getClassName(eventKeys, classParams)
                        return (
                          <EventMethodButton
                            key={id + key}
                            text={key}
                            variant={variant}
                            onClick={() => handleAddItem('eventKeys', classParams)}
                          />
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        </div>
      )}
    </>
  )
}

export default PluginsEvent
