import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { PluginTypes } from '../..'
import {
  getFormatedValue as getFormattedValueUtil,
  getValidFormat,
  getValueFilterFormats
} from '../../../helpers/formats'
import {
  BasePluginComponentProps,
  BasePluginComponentProvidedProps
} from './BasePluginComponent.types'

// TODO:
export type EmptyArray = readonly never[]

const fieldsFromSchemaDefault: EmptyArray = []
const fieldsFromQueryDefault: EmptyArray = []
const formattedFieldsFromQueryDefault: EmptyArray = []
const valueFiltersFromQueryDefault: EmptyArray = []

const BasePluginComponent = (props: BasePluginComponentProps) => {
  const {
    data: { schema: fieldsFromSchema = fieldsFromSchemaDefault } = {},
    settings: {
      query: {
        fields: fieldsFromQuery = fieldsFromQueryDefault,
        formattedFields: formattedFieldsFromQuery = formattedFieldsFromQueryDefault,
        valueFilters: valueFiltersFromQuery = valueFiltersFromQueryDefault
      } = {}
    } = {},
    args,
    children
  } = props

  const valueFilterFormats = useMemo(
    () => getValueFilterFormats(valueFiltersFromQuery, args),
    [args, valueFiltersFromQuery]
  )

  const getFieldType: BasePluginComponentProvidedProps['getFieldType'] = useCallback(
    (fieldName, isMultiple = false) => {
      const field = _.find(fieldsFromQuery, (fieldFromQuery) =>
        _.isEqual(fieldFromQuery.fieldName, fieldName)
      )
      const type = PluginTypes.fromString(field ? field.dataType : 'string')
      return isMultiple ? PluginTypes.arrayOf(type) : type
    },
    [fieldsFromQuery]
  )

  const isFieldTypeEqual: BasePluginComponentProvidedProps['isFieldTypeEqual'] = useCallback(
    (fieldName, type) => {
      const field = _.find(fieldsFromQuery, (fieldFromQuery) =>
        _.isEqual(fieldFromQuery.fieldName, fieldName)
      )
      return field?.dataType === type
    },
    [fieldsFromQuery]
  )

  const getFormattedValue: BasePluginComponentProvidedProps['getFormattedValue'] = useCallback(
    (columnName, value, formattedFields, row) => {
      // "Id" fields or formattedFields parameter is null to get it from configuration
      const fieldsActual = _.size(fieldsFromSchema) === 0 ? fieldsFromQuery : fieldsFromSchema
      const formattedFieldsActual = formattedFields || formattedFieldsFromQuery

      const field = _.find(fieldsActual, (f) => f.fieldName === columnName)

      if (field && getValidFormat(field.dataType)) {
        const foundFormattedField = _.find(
          formattedFieldsActual,
          (f) => f.columnName === columnName || f.columnName === `${columnName}.formatted`
        )

        if (foundFormattedField) {
          return getFormattedValueUtil(foundFormattedField.formatString, value, {
            ...valueFilterFormats,
            ...row
          })
        }
      }
      return value
    },
    [fieldsFromSchema, fieldsFromQuery, formattedFieldsFromQuery, valueFilterFormats]
  )

  const memoizedProps: BasePluginComponentProvidedProps = useMemo(() => {
    return {
      getFieldType,
      isFieldTypeEqual,
      getFormattedValue,
      formatValue: getFormattedValueUtil
    }
  }, [getFieldType, isFieldTypeEqual, getFormattedValue])

  return children(memoizedProps)
}

export default BasePluginComponent
