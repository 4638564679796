import React, { Component, createRef } from 'react'
import _ from 'lodash'
import ScenarioSelect from './scenarioSelect'
import { MESSAGES } from '../../messages'

export default class DeleteScenarioForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedScenario: {}
    }
    this.scenarioIdRef = createRef()
  }

  scenarioCheck(event) {
    event.target.setCustomValidity(
      _.isEmpty(event.target.value) ? MESSAGES.please_select_a_scenario : ''
    )
  }

  onFormClick(event) {
    event.stopPropagation()
  }

  onFormSubmit(event) {
    event.preventDefault()

    const {
      props: { mfpId, onDeleteFormSubmit = () => {} },
      state: { selectedScenario }
    } = this

    const scenarioId = selectedScenario.Id

    onDeleteFormSubmit({ mfpId, scenarioId: Number(scenarioId) })
  }

  onCloseClick() {
    const { onClose = () => {} } = this.props
    onClose(false)
  }

  onScenarioSelect(selectedScenario) {
    const { Id = '' } = selectedScenario

    this.setState({
      selectedScenario
    })

    this.scenarioIdRef.current.value = Id
  }

  render() {
    const {
      props: { data = [] },
      state: { selectedScenario }
    } = this

    return (
      <div className="scenario-container">
        <div className="sc-wrapper -w100">
          <h1>{MESSAGES.delete_scenario}</h1>
          <form
            className="scenario-create-form"
            onClick={this.onFormClick.bind(this)}
            onSubmit={this.onFormSubmit.bind(this)}
          >
            <ScenarioSelect
              data={data}
              mode="delete"
              selectedScenario={selectedScenario}
              onScenarioSelect={this.onScenarioSelect.bind(this)}
            />
            <input
              ref={this.scenarioIdRef}
              required
              className="scenarioId"
              type="number"
              onInvalid={this.scenarioCheck}
            />
            <div className="sc-btn-group">
              <span className="cell-btn -close" onClick={this.onCloseClick.bind(this)}>
                <i className="slvy-ui-icon-angle-left" /> {MESSAGES.back}
              </span>
              <button className="cell-btn -delete" type="submit">
                <i className="slvy-ui-icon-trash-can" /> {MESSAGES.delete}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
