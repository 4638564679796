import { getColumnWidth } from '../index'
import { GetColumnWithParams } from '../column.types'

const mockParamsWithFlex: GetColumnWithParams = {
  flex: 1,
  width: 100,
  theme: 'balham'
}

const mockParamsWithoutFlex: GetColumnWithParams = {
  flex: 0,
  width: 100,
  theme: 'balham'
}

const mockParamsWithZeroWidth: GetColumnWithParams = {
  flex: 0,
  width: 0,
  theme: 'balham'
}

const mockParamsWithAlpineTheme: GetColumnWithParams = {
  flex: 0,
  width: 100,
  theme: 'alpine'
}

const mockParamsWithQuartzTheme: GetColumnWithParams = {
  flex: 0,
  width: 100,
  theme: 'quartz'
}

describe('AgGrid:Helpers:Column:getColumnWidth', () => {
  it('should return minWidth when flex is truthy`', () => {
    const result = getColumnWidth(mockParamsWithFlex)
    expect(result).toEqual({ minWidth: 100 })
  })

  it('should return width when flex is falsy and width is not 0', () => {
    const result = getColumnWidth(mockParamsWithoutFlex)
    expect(result).toEqual({ width: 100 })
  })

  it('should return minWidth, maxWidth, and width set to 0 when flex is falsy and width is 0', () => {
    const result = getColumnWidth(mockParamsWithZeroWidth)
    expect(result).toEqual({ width: 0, minWidth: 0, maxWidth: 0 })
  })

  it('should handle alpine themes and calculate width accordingly', () => {
    const result = getColumnWidth(mockParamsWithAlpineTheme)
    expect(result).toEqual({ width: 135.2 })
  })

  it('should handle quartz themes and calculate width accordingly', () => {
    const result = getColumnWidth(mockParamsWithQuartzTheme)
    expect(result).toEqual({ width: 132.8 })
  })
})
