import { useState, useEffect } from 'react'
import cx from 'classnames'
import { BooleanIconCellEditorProps } from './BooleanIconCellEditor.types'
import { getCalculatedBooleanCellProps } from '../../../helpers'
import { agIndeterminateIconClass, iconFontSizeClass } from '../../../constants'
import styles from './BooleanIconCellEditor.module.scss'

const BooleanIconCellEditor = (props: BooleanIconCellEditorProps) => {
  const {
    colDef: {
      customInfo: { boolean }
    },
    shouldAutoStopEditing,
    value,
    stopEditing,
    onValueChange,
    context
  } = props

  const [selected, setSelected] = useState<boolean>(value)
  const [isSelectionCompleted, setIsSelectionCompleted] = useState(false)

  const { icon, color } = getCalculatedBooleanCellProps(selected, boolean)

  useEffect(() => {
    if (shouldAutoStopEditing && isSelectionCompleted) {
      stopEditing()
    }
  }, [shouldAutoStopEditing, isSelectionCompleted, stopEditing])

  const onChange = (val: typeof selected) => {
    setSelected(val)
    onValueChange(val)
    setIsSelectionCompleted(true)
  }

  const isIndeterminateIcon = icon.includes(agIndeterminateIconClass)

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <i
      className={cx(
        styles.editorBooleanIcon,
        { [styles.slvyIndeterminateIcon]: isIndeterminateIcon },
        'ag-cell-value', // TODO: fix align center does not work
        'cp',
        'slvy-i-element',
        iconFontSizeClass[context.theme],
        icon
      )}
      style={{ color }}
      onClick={() => onChange(!selected)}
    />
  )
}

export default BooleanIconCellEditor
