import { combineReducers } from 'redux'
import { pluginConfiguration } from './pluginConfiguration'
import { resourcePeriodHierarchy, PRODUCTresourcePeriodHierarchy } from './resourcePeriodHierarchy'
import { clickedProductInfo, PRODUCTclickedProductInfo } from './clickedProductInfo'
import { productGraphValues, PRODUCTproductGraphValues } from './productGraphValues'
import { legend, PRODUCTlegend } from './legend'
import { resourceRowItem, PRODUCTresourceRowItem } from './resourceRowItem'
import { resourcePlan, PRODUCTresourcePlan } from './resourcePlan'
import scrollPositions from './scrollPositions'
import moveMode from './moveMode'
import logging from './logging'

const appReducer = combineReducers({
  pluginConfiguration,
  resourcePeriodHierarchy,
  PRODUCTresourcePeriodHierarchy,
  clickedProductInfo,
  PRODUCTclickedProductInfo,
  productGraphValues,
  PRODUCTproductGraphValues,
  legend,
  PRODUCTlegend,
  resourceRowItem,
  PRODUCTresourceRowItem,
  resourcePlan,
  PRODUCTresourcePlan,
  scrollPositions,
  moveMode,
  logging
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_EXIT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
