import { CSSProperties } from 'react'
import { DirtyCell } from '../../types'

const style: CSSProperties = {
  display: 'none', // block
  background: 'lightblue',
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: '1rem'
}

const DirtyStates = ({ data }: { data: Array<DirtyCell> }) => {
  // if (!data.length) {
  return null
  // }

  return (
    <div style={style}>
      <h3 className="mb-2 pb-1">Dirty States</h3>
      <ol className="list-group list-group-numbered">
        {data.map((item, index) => (
          <li key={index} className="list-group-item">
            <pre>{JSON.stringify(item, null, 2)}</pre>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default DirtyStates
