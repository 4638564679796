Ext.define('tuiv2.MainViewModel', {
  extend: 'Ext.app.ViewModel',
  alias: 'viewmodel.tuiv2MainViewModel',
  data: {
    defaultViewTui: 'Map',
    backgroundColor: '#ffffff',
    loadTreeColumns: null,
    loadTreeHierarchy: [],
    loadTreeFilters: [],
    loadFooterButtons: [],
    demandFooterButtons: [],
    demandTotalFields: [],
    DemandTreeAllColumns: [],
    demandTreeColumns: null,
    demandTreeHierarchy: [],
    demandTreeFilters: [],
    locationColumns: [],
    checkedLoads: [],
    checkedDemands: [],
    loadStopSequence: null,
    selectedLoadId: null,
    viewMode: 0,
    mapData: null,
    loadTreeLoadCount: 0,
    loadTreeLoadStopCount: 0,
    firstSum: 0,
    secondSum: 0,
    smoothFactor: 2,
    weight: 3,
    opacity: 0.8,
    zoomOnSelect: true,
    PlanInfo: null,
    IsServiceRunning: false,
    freeDrawMode: 0,
    ReloadRunInfo: 123,
    user: null,
    // authorization.
    CanReset: false,
    CanReload: false,

    CanLockLoad: false,
    CanApproveLoad: false,
    CanRemoveLoad: false,
    CanAddDemandToLoad: false,
    CanMoveLoadEntryToLoad: false,

    CanRemoveLoadStop: false,
    CanChangeLoadStopSequence: false,
    CanUpdateLoadStopEntries: false,

    CanUpdateLocation: false,
    CanApplyDemandChanges: false,

    CanSavePlan: false,
    CanRefix: false,
    CanSetService: false,
    asyncActions: [],
    openedLoadCards: [],
    selectedDefaultReason: null,
    sessionName: null,
    sessionNameText: '',
    savingHidden: true,
    drawRoute: true,
    isServiceSelected: false,
    intervalId: null,
    demoEnabled: false,
    simulationSliderValue: 0,
    demoMode: false,
    currentReloadDemandPromise: '',
    treeStoreError: false
  },
  stores: {
    loadTreeStore: {
      type: 'tuiv2TreeStore',
      model: 'tuiv2.model.LoadTreeModel',
      autoLoad: false,
      listeners: {
        beforeload: 'onLoadStoreBeforeLoad',
        load: 'onLoadTreeStoreLoad'
      },
      defaultRootText: 'Loads'
    },
    demandTreeStore: {
      type: 'tuiv2TreeStore',
      model: 'tuiv2.model.LoadTreeModel',
      autoLoad: false,
      listeners: {
        beforeload: 'onDemandTreeStoreBeforeLoad',
        load: 'onDemandTreeStoreLoad'
      },
      defaultRootText: 'Demands'
    },
    loadTableStore: {
      type: 'tuiv2GridStore',
      model: 'tuiv2.model.LoadTableModel',
      autoLoad: false
    },
    loadStopTableStore: {
      type: 'tuiv2GridStore',
      model: 'tuiv2.model.LoadStopTableModel',
      autoLoad: false
    },
    loadStopEntryTableStore: {
      type: 'tuiv2GridStore',
      model: 'tuiv2.model.LoadStopEntryTableModel',
      autoLoad: false
    },
    scenarioTreeStore: {
      type: 'tuiv2TreeStore',
      model: 'tuiv2.model.ScenarioTreeModel',
      autoLoad: false,
      defaultRootText: 'Children'
    },
    runInfoStore: {
      type: 'tuiv2GridStore',
      model: 'tuiv2.model.RunInfoModel',
      autoLoad: false
    },
    taskStore: {
      type: 'tuiv2GridStore',
      model: 'tuiv2.model.TaskModel',
      autoLoad: false
    },
    demandDetailDataStore: {
      type: 'tuiv2TreeStore',
      model: 'tuiv2.model.DemandDetailDataModel',
      autoLoad: false,
      listeners: {
        beforeload: 'onDemandDetailDataStoreBeforeLoad'
      },
      defaultRootText: 'Demands'
    },
    reasonCodeStore: {
      type: 'tuiv2ReasonCodeStore',
      model: 'tuiv2.model.ReasonCodeModel',
      autoLoad: true
    },
    transportationModeStore: {
      type: 'tuiv2TransportationModeStore',
      model: 'tuiv2.model.TransportationModeModel',
      autoLoad: false,
      listeners: {
        beforeload: 'onTransportationModeStoreBeforeLoad'
      }
    },
    sessionListStore: {
      type: 'tuiv2GridStore',
      model: 'tuiv2.model.SessionListModel',
      autoLoad: false
    }
  },

  formulas: {
    mask: {
      bind: {
        isOperationInProgress: '{isOperationInProgress}'
      },
      get: function (data) {
        if (data.isOperationInProgress) {
          Ext.getBody().mask('Loading...', 'splashscreen')
        } else {
          Ext.getBody().unmask()
        }
      }
    },
    userInfo: {
      bind: {
        Users: '{Users}'
      },
      get: function (data) {
        if (data.Users) {
          var userCount = data.Users.length
          var userList = data.Users.join(',')
          return (
            '<span class="d-flex align-items-center justify-content-center" title="' +
            userList +
            '">' +
            ' <i class="fa fa-users"></i> ' +
            userCount +
            '</span>'
          )
        }
      }
    },
    changeDrawRoute: {
      bind: {
        drawRoute: '{drawRoute}'
      },
      get: function (data) {
        var view = this.getView()

        var mainMap = view.lookupReference('mainMap')

        var map = mainMap.map

        var viewModel = view.getViewModel()

        var latlng = viewModel.get('disabledMapLatlng')

        var zoomFactor = viewModel.get('disabledZoomFactor')

        if (!data.drawRoute) map.setView(latlng, zoomFactor)
      }
    },
    requestMapData: {
      bind: {
        checkedLoads: '{checkedLoads}',
        checkedDemands: '{checkedDemands}',
        viewMode: '{viewMode}'
      },
      get: function (data) {
        var view = this.getView()

        var map = view.lookupReference('mainMap')

        var viewmodel = view.getViewModel()

        var controller = view.getController()

        var drawRoute = viewmodel.get('drawRoute')

        var mapLoadSummary = viewmodel.get('mapLoadSummary')

        if (
          (data.checkedLoads.length === 0 && data.checkedDemands.length === 0) ||
          data.viewMode == 1
        ) {
          viewmodel.set({
            summaryData: [],
            mapData: []
          })
          var request = viewmodel.get('mapRequest')
          if (request) Ext.Ajax.abort(request)
          return
        }
        if (data.viewMode !== 2) {
          if (!drawRoute) {
            var mapData = viewmodel.get('mapData')
            controller.onManagePopUp(mapData)
            map.getEl().unmask()
            return
          }

          map.getEl().mask('Processing')
          controller.ajaxCallForMapData(data.checkedLoads, data.checkedDemands, mapLoadSummary)
        }
      }
    },
    viewModeBtnText: {
      bind: {
        viewMode: '{viewMode}'
      },
      get: function (data) {
        if (data.viewMode == 0) {
          return 'View as Table'
        } else if (data.viewMode == 1) {
          return 'View as Map'
        } else {
          return 'View as Tree Grid'
        }
      }
    },
    viewModeBtnIconCls: {
      bind: {
        viewMode: '{viewMode}'
      },
      get: function (data) {
        if (data.viewMode == 0) {
          return 'slvy-ui-icon-map-route'
        } else if (data.viewMode == 1) {
          return 'slvy-ui-icon-table'
        } else {
          return 'slvy-ui-icon-minus-outline'
        }
      }
    },
    hiddenTuiSettingsByMap: {
      bind: {
        viewMode: '{viewMode}'
      },
      get: function (data) {
        return data.viewMode !== 0
      }
    },
    mapAndTableModeTreeview: {
      bind: {
        viewMode: '{viewMode}'
      },
      get: function (data) {
        return data.viewMode === 2
      }
    },
    backgroundColorByTable: {
      bind: {
        viewMode: '{viewMode}',
        backgroundColor: '{backgroundColor}'
      },
      get: function (data) {
        return data.viewMode === 1 ? data.backgroundColor : ''
      }
    },
    noneModeTreeview: {
      bind: {
        viewMode: '{viewMode}'
      },
      get: function (data) {
        return data.viewMode === 0 || data.viewMode === 1
      }
    },

    checkChange: {
      bind: {
        checkedLoads: '{checkedLoads}',
        viewMode: '{viewMode}'
      },
      get: function (data) {
        var view = this.getView()

        var viewmodel = view.getViewModel()

        var loadTableStore = viewmodel.get('loadTableStore')

        var loadStopTableStore = viewmodel.get('loadStopTableStore')

        var loadStopEntryTableStore = viewmodel.get('loadStopEntryTableStore')

        var columns = viewmodel.get('loadTableRawColumns')

        if (data.viewMode == 1 && data.checkedLoads.length > 0) {
          var proxy = loadTableStore.getProxy()

          proxy.setExtraParam('LoadIds', JSON.stringify(data.checkedLoads))
          proxy.setExtraParam('Columns', JSON.stringify(columns))
          loadTableStore.load()
        } else if (data.checkedLoads.length === 0) {
          loadTableStore.removeAll()
          loadTableStore.getProxy().abort()
          loadStopTableStore.removeAll()
          loadStopTableStore.getProxy().abort()
          loadStopEntryTableStore.removeAll()
          loadStopEntryTableStore.getProxy().abort()
        }
      }
    },
    loadStopMarkerId: {
      bind: {
        loadStopSequence: '{loadStopSequence}',
        selectedLoadId: '{selectedLoadId}'
      },
      get: function (data) {
        return data.selectedLoadId + '-' + data.loadStopSequence
      }
    },
    triggerRunInfoLoad: {
      bind: {
        CurrentScenarioID: '{PlanInfo.CurrentScenarioID}',
        ReloadRunInfo: '{ReloadRunInfo}'
      },
      get: function (data) {
        if (data.CurrentScenarioID == 0) {
          return
        }

        var viewModel = this
        var runInfoStore = this.get('runInfoStore')
        var proxy = runInfoStore.getProxy()

        proxy.setExtraParam('CurrentScenarioID', data.CurrentScenarioID)
        runInfoStore.load(function () {
          var view = viewModel.getView()

          if (view) {
            view.getReferences().runInfoCombo.setValue(viewModel.get('PlanInfo.CurrentRunInfoID'))
          }
        })
      }
    },
    selectedService: {
      bind: {
        isServiceSelected: '{isServiceSelected}'
      },
      get: function (data) {
        var me = this
        var view = me.getView()
        var controller = view.getController()
        if (data.isServiceSelected) {
          controller.checkServiceStatus()
        }
      }
    },
    triggerLoadTree: {
      bind: {
        IsServiceRunning: '{IsServiceRunning}'
      },
      get: function (data) {
        var viewModel = this
        var store = viewModel.getStore('loadTreeStore')
        var demandStore = viewModel.getStore('demandTreeStore')

        if (!data.IsServiceRunning || store.getDatas().length != 1) return
        if (!data.IsServiceRunning || demandStore.getDatas().length != 1) return

        demandStore.load()
        store.load()
      }
    },
    triggerTreeErrorMessage: {
      bind: {
        treeStoreError: '{treeStoreError}'
      },
      get: function (data) {
        var viewModel = this
        var errorMessage = viewModel.get('errorMessage')
        if (data.treeStoreError === true) {
          Ext.create('Ext.window.Window', {
            width: 230,
            modal: true,
            autoShow: true,
            ui: 'tui-window',
            cls: 'tuiv2-confirmationWindow',
            title: 'Action Failed',
            scrollable: 'y',
            items: [
              {
                xtype: 'label',
                text: errorMessage
              }
            ],
            bbar: [
              '->',
              {
                xtype: 'button',
                text: 'OK',
                handler: function () {
                  var button = this

                  var window = button.up('window')
                  window.destroy()
                }
              },
              '->'
            ],
            bodyStyle: 'padding:20px;word-wrap: break-word'
          }).show()
        }
      }
    },
    mainActiveItem: {
      bind: {
        IsServiceRunning: '{IsServiceRunning}'
      },
      get: function (data) {
        return data.IsServiceRunning != 1 ? 0 : 1
      }
    },
    manageButtonIsDisabled: {
      bind: {
        checkedDemands: '{checkedDemands}'
      },
      get: function (data) {
        return !data.checkedDemands.length > 0
      }
    },

    isServiceRunningIcon: {
      bind: {
        IsServiceRunning: '{IsServiceRunning}',
        demoEnabled: '{demoEnabled}',
        PlanInfo: '{PlanInfo}'
      },
      get: function (data) {
        var currentTime = ''

        if (data.demoEnabled) {
          var date = Ext.Date.parse(data.PlanInfo.CurrentDateTime, 'Y-m-d\\TH:i:s')

          currentTime = ' <b>Current Time</b> : ' + Ext.Date.format(date, 'Y-m-d H:i:s')
        }

        if (data.IsServiceRunning == 0) {
          return (
            '<i class="fa fa-times-circle" aria-hidden="true" style="color:red;cursor:pointer;"></i>' +
            currentTime
          )
        } else if (data.IsServiceRunning == 1) {
          return (
            '<i class="fa fa-circle" aria-hidden="true" style="color:green;cursor:pointer;"></i>' +
            currentTime
          )
        } else if (data.IsServiceRunning == 2) {
          return (
            '<i class="fa fa-circle" aria-hidden="true" style="color:yellow;cursor:pointer;"></i>' +
            currentTime
          )
        }
      }
    },
    loadTitle: {
      bind: {
        selectedLoad: '{selectedLoad}'
      },
      get: function (data) {
        var li = data.selectedLoad

        if (!li || !li.LoadName || !li.TransportationModeName) {
          return 'Load Name'
        }

        return li.LoadName + ' - ' + li.TransportationModeName
      }
    },
    FacilityDescription: {
      bind: {
        selectedLoad: '{selectedLoad}'
      },
      get: function (data) {
        var li = data.selectedLoad

        return li ? li.FacilityDescription : ''
      }
    },
    setUnits: {
      bind: {
        PlanInfo: '{PlanInfo}'
      },
      get: function (data) {
        var units = this.get('units')

        if (data.PlanInfo) {
          var PlanInfoKeys = Object.keys(data.PlanInfo)
          PlanInfoKeys.forEach((element) => {
            if (
              units &&
              units[element] != '' &&
              units[element] != undefined &&
              units[element] != null
            ) {
              data.PlanInfo[element] = units[element]
            }
          })
        }
        tuiv2.Units.setPlanInfo(data.PlanInfo)
      }
    },
    sessionNameText: {
      bind: { sessionName: '{sessionName}' },
      get: function (data) {
        var sessionNameText = this.get('sessionNameText')
        if (sessionNameText == null || sessionNameText.length == 0) {
          return data.sessionName
        } else {
          return sessionNameText
        }
      }
    },
    simulationSliderMaxValue: {
      bind: {
        planInfo: '{PlanInfo}'
      },
      get: function (data) {
        if (!data.planInfo || !data.planInfo.StartDateTime) return 0

        var startDate = Ext.Date.parse(data.planInfo.StartDateTime, 'Y-m-d\\TH:i:s')
        var endDate = Ext.Date.parse(data.planInfo.EndDateTime, 'Y-m-d\\TH:i:s')

        if (startDate == null || endDate == null) return 100

        return endDate - startDate
      }
    },
    simulationDate: {
      bind: {
        simulationSliderValue: '{simulationSliderValue}',
        PlanInfo: '{PlanInfo}'
      },
      get: function (data) {
        if (!data.PlanInfo || !data.PlanInfo.StartDateTime) return null

        var startDate = Ext.Date.parse(data.PlanInfo.StartDateTime, 'Y-m-d\\TH:i:s')
        return new Date(startDate.getTime() + data.simulationSliderValue)
      }
    },
    simulationDate: {
      bind: {
        simulationSliderValue: '{simulationSliderValue}',
        PlanInfo: '{PlanInfo}'
      },
      get: function (data) {
        if (!data.PlanInfo || !data.PlanInfo.StartDateTime) return null

        var startDate = Ext.Date.parse(data.PlanInfo.StartDateTime, 'Y-m-d\\TH:i:s')
        return new Date(startDate.getTime() + data.simulationSliderValue)
      }
    },
    simulationDateStr: {
      bind: {
        simulationDate: '{simulationDate}'
      },
      get: function (data) {
        if (!data.simulationDate) return ''

        return Ext.util.Format.date(data.simulationDate, 'Y/m/d H:i:s')
      }
    }
  }
})
