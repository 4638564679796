Ext.define('po.column.Column', {
  override: 'Ext.grid.column.Column',
  privates: {
    onUnlock: function (header) {
      if (this.items.items) {
        var items = this.items.items,
          len = items.length,
          i,
          item
        for (i = 0; i < len; ++i) {
          item = items[i]
          if (item.isColumn) {
            item.onUnlock(header)
          }
        }
      }
    },
    onLock: function (header) {
      if (this.items.items) {
        var items = this.items.items,
          len = items.length,
          i,
          item
        for (i = 0; i < len; ++i) {
          item = items[i]
          if (item.isColumn) {
            item.onLock(header)
          }
        }
      }
    }
  }
})
Ext.define('po.dom.Element', {
  override: 'Ext.dom.Element',
  setStyle: function (prop, val) {
    return this.dom ? this.callParent([prop, val]) : this
  }
})

Ext.define('Overrides.view.AbstractView', {
  override: 'Ext.view.AbstractView',
  /**
   * Override to check if el is destroyed
   */
  clearViewEl: function () {
    var me = this,
      targetEl = me.getTargetEl(),
      all = me.all,
      store = me.getStore(),
      i,
      removedItems,
      removedRecs,
      nodeContainerIsTarget = me.getNodeContainer() === targetEl
    removedItems = all.slice()
    removedRecs = []
    for (i = all.startIndex; i <= all.endIndex; i++) {
      if (!all.item(i, true)) continue
      removedRecs.push(store.getByInternalId(all.item(i, true).getAttribute('data-recordId')))
    }
    removedItems = removedItems.filter((p) => p)
    me.fireItemMutationEvent('itemremove', removedRecs, all.startIndex || 0, removedItems, me)
    me.clearEmptyEl()
    me.all.clear(!nodeContainerIsTarget)
    targetEl = nodeContainerIsTarget ? targetEl.dom : me.getNodeContainer()
    if (targetEl) {
      targetEl.innerHTML = ''
    }
  }
})
Ext.define('Overrides.view.Table', {
  override: 'Ext.view.Table',
  /**
   * Override to check if el is destroyed
   */
  syncRowHeightBegin: function () {
    var me = this,
      itemEls = me.all,
      ln = itemEls.count,
      synchronizer = [],
      RowSynchronizer = Ext.grid.locking.RowSynchronizer,
      i,
      j,
      rowSync
    var count = 0
    for (i = 0, j = itemEls.startIndex; i < ln; i++, j++) {
      if (!itemEls.elements[j]) continue
      synchronizer[count] = rowSync = new RowSynchronizer(me, itemEls.elements[j])
      rowSync.reset()
      count += 1
    }
    return synchronizer
  },
  // syncRowHeightMeasure: function (synchronizer) {
  //   var ln = synchronizer.length, i;
  //   for (i = 0; i < ln; i++) {
  //     if (synchronizer[i])
  //       synchronizer[i].measure();
  //   }
  // }
  privates: {
    activateCell: function (position) {
      var me = this,
        lockingPartner = position.view !== me ? me.lockingPartner : null,
        actionables = me.grid.actionables,
        len = actionables.length,
        navModel = me.getNavigationModel(),
        focusTarget = position.target,
        record,
        prevRow,
        focusRow,
        focusCell,
        i,
        isActionable,
        tabbableChildren
      position = position.clone()
      record = position.record
      position.view.grid.ensureVisible(record, { column: position.column })
      focusRow = me.all.item(position.rowIdx, true)
      if (!focusRow) return
      if (me.actionPosition) {
        prevRow = me.all.item(me.actionPosition.rowIdx, true)
        if (prevRow && focusRow !== prevRow) {
          Ext.fly(prevRow).saveTabbableState({
            skipSelf: true,
            includeSaved: false
          })
        }
      }
      me.activating = true
      if (!lockingPartner) {
        focusCell = Ext.fly(position.getCell(true))
        me.actionPosition = position
        for (i = 0; i < len; i++) {
          isActionable = isActionable || actionables[i].activateCell(position, null, true)
        }
      }
      if (
        lockingPartner ||
        (focusCell &&
          focusCell.restoreTabbableState({ skipSelf: true }).length |
            (tabbableChildren = focusCell.findTabbableElements()).length) ||
        isActionable
      ) {
        for (i = 0; i < len; i++) {
          if (actionables[i].activateRow) {
            actionables[i].activateRow(focusRow)
          }
        }
        if (lockingPartner || tabbableChildren.length) {
          Ext.fly(focusRow).restoreTabbableState({ skipSelf: true })
          if (lockingPartner) {
            me.actionableMode = true
            me.actionPosition = null
            me.activating = false
            return true
          }
          if (tabbableChildren) {
            me.actionRow = me.actionRowFly.attach(focusRow)
            me.actionableMode = me.ownerGrid.actionableMode = true
            navModel.setPosition()
            navModel.actionPosition = me.actionPosition = position
            if (focusTarget && Ext.Array.contains(tabbableChildren, focusTarget)) {
              Ext.fly(focusTarget).focus()
            } else {
              Ext.fly(tabbableChildren[0]).focus()
            }
            me.activating = false
            return true
          }
        }
      }
      me.activating = false
    }
  }
})
Ext.define('Overrides.grid.plugin.BufferedRenderer', {
  override: 'Ext.grid.plugin.BufferedRenderer',
  /**
   * Override to check if el is destroyed
   */
  getScrollHeight: function () {
    var me = this,
      view = me.view,
      rows = view.all,
      store = me.store,
      recCount = store.getCount(),
      rowCount = rows.getCount(),
      row,
      rowHeight,
      borderWidth,
      scrollHeight
    if (Object.keys(rows.elements).length > 0) {
      var result = this.getMaxAndMin(rows)
      rows.startIndex = result.startIndex
      rows.endIndex = result.endIndex
    }
    if (!recCount) {
      return 0
    }
    if (!me.hasOwnProperty('rowHeight')) {
      if (rowCount) {
        if (me.variableRowHeight) {
          me.rowHeight = Math.floor(me.bodyHeight / rowCount)
        } else {
          row = rows.first()
          rowHeight = row.getHeight()
          if (Ext.isIE8) {
            borderWidth = row.getBorderWidth('b')
            if (borderWidth > 0) {
              rowHeight -= borderWidth
            }
          }
          me.rowHeight = rowHeight
        }
      } else {
        delete me.rowHeight
      }
    }
    if (me.variableRowHeight) {
      if (rows.endIndex === recCount - 1) {
        scrollHeight = me.bodyTop + me.bodyHeight - 1
      } else {
        scrollHeight = Math.floor((recCount - rowCount) * me.rowHeight) + me.bodyHeight
        scrollHeight += me.bodyTop - rows.startIndex * me.rowHeight
      }
    } else {
      scrollHeight = Math.floor(recCount * me.rowHeight)
    }
    return (me.scrollHeight = scrollHeight)
  },
  getMaxAndMin(all) {
    var min = 999999
    var max = 0
    Object.keys(all.elements).forEach(function (key, index) {
      min = Math.min(min, key)
      max = Math.max(max, key)
    })
    return {
      startIndex: min,
      endIndex: max
    }
  }
})
Ext.define('Overrides.grid.locking.RowSynchronizer', {
  override: 'Ext.grid.locking.RowSynchronizer',
  /**
   * Override to check if el is destroyed
   */
  finish: function (other) {
    if (!other) return
    var me = this,
      els = me.els,
      otherEls = other.els,
      otherEl,
      growth = 0,
      otherGrowth = 0,
      delta,
      name,
      otherHeight
    for (name in els) {
      otherEl = otherEls[name]
      otherHeight = otherEl ? otherEl.height : 0
      delta = otherHeight - els[name].height
      if (delta > 0) {
        growth += delta
        Ext.fly(els[name].el).setHeight(otherHeight)
      } else {
        otherGrowth -= delta
      }
    }
    otherHeight = other.rowHeight + otherGrowth
    if (Ext.isIE9 && me.view.ownerGrid.rowLines) {
      otherHeight--
    }
    if (me.rowHeight + growth < otherHeight) {
      Ext.fly(me.rowEl).setHeight(otherHeight)
    }
  }
})
