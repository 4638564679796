import React from 'react'
import _ from 'lodash'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'

function AssortmentWizard(props) {
  const { show = true } = props

  return show && <div className="h-100">{props.children}</div>
}

AssortmentWizard.Header = Header
AssortmentWizard.Body = Body
AssortmentWizard.Footer = Footer

export default AssortmentWizard
