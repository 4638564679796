const ToastrTypes = {
  true: {
    type: 'success',
    updateMsg: 'is successfully updated',
    addMsg: 'is successfully added',
    removeMsg: 'is successfully removed',
    deleteMsg: 'is successfully deleted'
  },
  false: {
    type: 'error',
    updateMsg: "isn't successfully updated",
    addMsg: "isn't successfully added",
    removeMsg: "isn't successfully removed",
    deleteMsg: "isn't successfully deleted"
  }
}

export default ToastrTypes
