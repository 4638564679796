Ext.define('MDM.src.view.globalapp.CellEditing', {
  alias: 'plugin.uxcellediting',
  extend: 'Ext.grid.plugin.CellEditing',
  config: {
    readOnly: false
  },
  activateCell: function (position, skipBeforeCheck, doFocus) {
    if (this.getCmp().getReadOnlyMode()) return
    this.callParent(arguments)
  }
})
