import React from 'react'

function AssortmentLoading(props) {
  const { messages = 'Loading', isShown = false } = props

  return isShown ? (
    <div className="assortment-loading">
      <div className="d-grid">
        <div className="spinner-border mx-auto mb-2 border-2" />
        {messages}
      </div>
    </div>
  ) : null
}

export default AssortmentLoading
