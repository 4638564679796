export const defaultColor = {
  r: '255',
  g: '255',
  b: '255',
  a: '100'
} as const

export const containerSettings = {
  version: '',
  layout: [],
  children: []
} as const

export const defaultForm = {
  fit: true,
  background: '',
  withContainerPadding: false,
  useCSSTransforms: true,
  Background: '',
  WithContainerPadding: true
} as const
