import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'
import MultiIssueList from './MultiIssueList'
import Sort from '../sort'
import { selectLegendResourceIssue, selectLegendProductIssue } from '../../../../actions'

class MultiSelectList extends Component {
  constructor() {
    super()
    this.state = {
      resourceLegendData: {
        enabledResourceIssues: [],
        enabledResourceDetails: []
      },
      productLegendData: {
        enabledProductIssues: [],
        enabledProductDetails: []
      },
      resourceLegendState: {
        currentIssueIndex: 0,
        currentProductInfoIndex: [],
        isProductInfo: false
      },
      productLegendState: {
        currentIssueIndex: 0,
        currentProductInfoIndex: [],
        isProductInfo: false
      },
      multiSelectBox: false
    }

    this.handleScrollMsbItemContainer = this.handleScrollMsbItemContainer.bind(this)
  }

  setRef = (node) => (this.getRef = node)

  handleClickOutside = ({ target }) => {
    if (this.getRef && !this.getRef.contains(target)) {
      this.setState({ multiSelectBox: false })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('scroll', this.handleScrollMsbItemContainer)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)

    const {
      resourcePeriodHierarchy: { IssueNames: resourceIssueNames = [], ProductIssueNames = [] } = {},
      settings: {
        resourceInfoSettings = {},
        resourceIssueSettings = [],
        productInfoSettings = [],
        issueSettings: productIssueSettings = []
      } = {},
      resourceLegendState = {},
      productLegendState = {}
    } = this.props

    /// @TODO to be dynamic
    const productIssueNames = ProductIssueNames || [
      'SafetyStockViolation',
      'MinCoverViolation',
      'MaxCoverViolation',
      'UnfulfilledDemand',
      'StockOut',
      'Backordered',
      'MinCoverCwkViolation',
      'MaxCoverCwkViolation'
    ]

    const enabledResourceIssues = _.reduce(
      resourceIssueNames,
      (enabledResourceIssues, issueName, index) => {
        const foundElement =
          _.find(resourceIssueSettings, (issue) => issue.tableName === issueName) || {}
        const { enable = true } = foundElement

        if (enable && !_.isEmpty(foundElement)) {
          foundElement.issueIndex = index
          enabledResourceIssues.push(foundElement)
        }

        return enabledResourceIssues
      },
      []
    )

    const enabledProductIssues = _.reduce(
      productIssueNames,
      (enabledProductIssues, issueName, index) => {
        const foundElement =
          _.find(productIssueSettings, (issue) => issue.tableName === issueName) || {}
        const { enable = true } = foundElement

        if (enable && !_.isEmpty(foundElement)) {
          foundElement.issueIndex = index
          enabledProductIssues.push(foundElement)
        }

        return enabledProductIssues
      },
      []
    )

    const enabledResourceDetails = _.filter(
      resourceInfoSettings,
      (productInfo) => productInfo.enable
    )
    const enabledProductDetails = _.filter(productInfoSettings, (productInfo) => productInfo.enable)

    const localStorageResourceState =
      JSON.parse(localStorage.getItem('resourceLegendState')) || resourceLegendState
    const localStorageProductState =
      JSON.parse(localStorage.getItem('productLegendState')) || productLegendState

    // Handle enabled issue lists not having elements with selected issue indexes
    localStorageResourceState.currentProductInfoIndex = _.reject(
      localStorageResourceState.currentProductInfoIndex,
      (index) => _.isEmpty(enabledResourceDetails[index])
    )
    localStorageProductState.currentProductInfoIndex = _.reject(
      localStorageProductState.currentProductInfoIndex,
      (index) => _.isEmpty(enabledProductDetails[index])
    )
    localStorageResourceState.currentIssueIndex =
      localStorageResourceState.currentIssueIndex > enabledResourceIssues.length
        ? 0
        : localStorageResourceState.currentIssueIndex
    localStorageProductState.currentIssueIndex =
      localStorageProductState.currentIssueIndex > enabledProductIssues.length
        ? 0
        : localStorageProductState.currentIssueIndex

    const maxSelectedIssues =
      localStorageProductState.currentProductInfoIndex.length >
      localStorageResourceState.currentProductInfoIndex.length
        ? localStorageProductState.currentProductInfoIndex.length
        : localStorageResourceState.currentProductInfoIndex.length

    this.setState(
      {
        resourceLegendData: {
          enabledResourceIssues,
          enabledResourceDetails
        },
        productLegendData: {
          enabledProductIssues,
          enabledProductDetails
        },
        resourceLegendState: localStorageResourceState || resourceLegendState,
        productLegendState: localStorageProductState || productLegendState
      },
      () => {
        this.props.selectLegendResourceIssue(
          this.state.resourceLegendState,
          this.state.resourceLegendData,
          maxSelectedIssues,
          localStorageResourceState.currentProductInfoIndex.length
        )
        this.props.selectLegendProductIssue(
          this.state.productLegendState,
          this.state.productLegendData,
          maxSelectedIssues,
          localStorageProductState.currentProductInfoIndex.length
        )
      }
    )

    const msbItemContainer = document.querySelector('.msb-item-container')
    msbItemContainer.addEventListener('scroll', this.handleScrollMsbItemContainer)
  }

  resourceLegendHandler = (newState) => {
    let { resourceLegendState, productLegendState, resourceLegendData } = this.state
    resourceLegendState = { ...resourceLegendState, ...newState }

    const maxSelectedIssues =
      productLegendState.currentProductInfoIndex.length >
      resourceLegendState.currentProductInfoIndex.length
        ? productLegendState.currentProductInfoIndex.length
        : resourceLegendState.currentProductInfoIndex.length

    this.setState({ resourceLegendState }, () => {
      this.props.selectLegendResourceIssue(
        newState,
        resourceLegendData,
        maxSelectedIssues,
        resourceLegendState.currentProductInfoIndex.length
      )
      localStorage.setItem('resourceLegendState', JSON.stringify(newState))
    })
  }

  productionLegendHandler = (newState) => {
    let { productLegendState, productLegendData, resourceLegendState } = this.state
    productLegendState = { ...this.state.productLegendState, ...newState }

    const maxSelectedIssues =
      productLegendState.currentProductInfoIndex.length >
      resourceLegendState.currentProductInfoIndex.length
        ? productLegendState.currentProductInfoIndex.length
        : resourceLegendState.currentProductInfoIndex.length

    this.setState({ productLegendState }, () => {
      this.props.selectLegendProductIssue(
        newState,
        productLegendData,
        maxSelectedIssues,
        productLegendState.currentProductInfoIndex.length
      )
      localStorage.setItem('productLegendState', JSON.stringify(newState))
    })
  }

  handleScrollMsbItemContainer(event) {
    const { target: { scrollTop } = {} } = event
    this.setState({ msbContainerScrollTop: scrollTop })
  }

  render() {
    const {
      resourcePeriodHierarchy: { Periods: periods = {} } = {},
      settings = {},
      settings: {
        resourceAndProductPlanSettings: {
          metricSettings: { isMetricOrderSorted = false } = {}
        } = {}
      } = {},
      historyHandler = () => {}
    } = this.props
    const {
      resourceLegendData: { enabledResourceIssues = {}, enabledResourceDetails = {} } = {},
      productLegendData: { enabledProductIssues = {}, enabledProductDetails = {} } = {},
      resourceLegendState = {},
      productLegendState = {},
      multiSelectBox,
      msbContainerScrollTop
    } = this.state

    const selectedResourceIssue = enabledResourceIssues[resourceLegendState.currentIssueIndex] || []
    const selectedResourceDetails =
      _.map(
        isMetricOrderSorted
          ? _.sortBy(resourceLegendState.currentProductInfoIndex)
          : resourceLegendState.currentProductInfoIndex,
        (index) => enabledResourceDetails[index]
      ) || []
    const selectedResources = !_.isEmpty(selectedResourceIssue)
      ? [selectedResourceIssue]
      : selectedResourceDetails

    const selectedProductionIssue = enabledProductIssues[productLegendState.currentIssueIndex] || []
    const selectedProductionDetails =
      _.map(
        isMetricOrderSorted
          ? _.sortBy(productLegendState.currentProductInfoIndex)
          : productLegendState.currentProductInfoIndex,
        (index) => enabledProductDetails[index]
      ) || []
    const selectedProductions = !_.isEmpty(selectedProductionIssue)
      ? [selectedProductionIssue]
      : selectedProductionDetails

    const enabledResourceIssuesLength = _.size(enabledResourceIssues)
    const enabledProductIssuesLength = _.size(enabledProductIssues)
    const issueHeight = 24
    const issueContainerHeight =
      enabledResourceIssuesLength > enabledProductIssuesLength
        ? enabledResourceIssuesLength * issueHeight
        : enabledProductIssuesLength * issueHeight

    return (
      <div
        ref={this.setRef}
        className={`multi-select-box ${multiSelectBox ? '-focus' : ''}`}
        onClick={() => this.setState({ multiSelectBox: !multiSelectBox })}
      >
        <div className="msb-result">
          <nav>
            <ul>
              {_.map(selectedResources, (selectedResource, selectedResourceIndex) => {
                const { name = '', shortName = '' } = selectedResource || {}

                return (
                  <li key={selectedResourceIndex} title={name}>
                    {!_.isEmpty(selectedResource) && (
                      <Sort
                        ascIcons={['fa-fw fa fa-arrow-circle-o-up', 'fa-fw fa fa-arrow-circle-up']}
                        connectApi={this.props.connectApi}
                        currentIssueIndex={selectedResourceIndex}
                        descIcons={[
                          'fa-fw fa fa-arrow-circle-o-down',
                          'fa-fw fa fa-arrow-circle-down'
                        ]}
                        historyHandler={historyHandler}
                        issueList={selectedResources}
                        lastRequest={this.props.lastRequest}
                        period={periods}
                        settings={settings}
                      />
                    )}
                    <span>{shortName || name}</span>
                  </li>
                )
              })}
            </ul>

            <ul>
              {_.map(selectedProductions, (selectedProduction, selectedProductionIndex) => {
                const { name = '', shortName = '' } = selectedProduction || {}

                return (
                  <li key={selectedProductionIndex} title={name}>
                    {!_.isEmpty(selectedProduction) && (
                      <Sort
                        ascIcons={['fa-fw fa fa-arrow-circle-o-up', 'fa-fw fa fa-arrow-circle-up']}
                        connectApi={this.props.connectApi}
                        currentIssueIndex={selectedProductionIndex}
                        descIcons={[
                          'fa-fw fa fa-arrow-circle-o-down',
                          'fa-fw fa fa-arrow-circle-down'
                        ]}
                        historyHandler={historyHandler}
                        issueList={selectedProductions}
                        lastRequest={this.props.lastRequest}
                        period={periods}
                        settings={settings}
                      />
                    )}
                    <span>{shortName || name}</span>
                  </li>
                )
              })}
            </ul>
          </nav>
          <i className="-icon-trigger slvy-ui-icon-caret-down" />
        </div>

        <div className="msb-items">
          <header
            className={cx('msb-header-container', {
              scrolling: msbContainerScrollTop > 0
            })}
          >
            <div className="msb-s msb-left">
              <div className="msb-header">
                <i className="slvy-ui-icon-server-download" />
                {msbContainerScrollTop > 0 && <span>Resource</span>}
              </div>
            </div>
            <div className="msb-s msb-right">
              <div className="msb-header">
                {msbContainerScrollTop > 0 && <span>Product</span>}
                <i className="slvy-ui-icon-box" />
              </div>
            </div>
          </header>
          <div className="msb-item-container">
            <div>
              <div className="msb-s msb-left">
                <MultiIssueList
                  checkboxName="ResourceIssues"
                  height={issueContainerHeight}
                  historyHandler={historyHandler}
                  infoList={enabledResourceDetails}
                  issueList={enabledResourceIssues}
                  legendHandler={this.resourceLegendHandler}
                  legendState={resourceLegendState}
                  settings={settings}
                />
              </div>
              <div className="msb-s msb-right">
                <MultiIssueList
                  checkboxName="ProductIssues"
                  height={issueContainerHeight}
                  historyHandler={historyHandler}
                  infoList={enabledProductDetails}
                  issueList={enabledProductIssues}
                  legendHandler={this.productionLegendHandler}
                  legendState={productLegendState}
                  settings={settings}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  resourcePeriodHierarchy: state.resourcePeriodHierarchy,
  resourceLegendState: state.legend.resourceLegendState,
  productLegendState: state.legend.productLegendState
})

const mapDispatchToProps = {
  selectLegendResourceIssue,
  selectLegendProductIssue
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectList)

// _.map(new Array(4), (item, key) => {
//     return (
//         <label className="msb-custom-radio" key={key}>
//             <input type="radio" name="same-l" />
//             <i />
//             <span>
//                 Violation item {key}
//             </span>
//         </label>
//     )
// })
