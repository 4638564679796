Ext.define('Spui.plugin.CornerIcon', {
  extend: 'Ext.plugin.Abstract',

  alias: 'plugin.cornerIcon',
  extraButton: [],
  cfgButton: [],
  offSet: false,
  mixins: {
    observable: 'Ext.util.Observable'
  },
  constructor: function (config) {
    this.callParent([config])
    this.mixins.observable.constructor.call(this, config)
  },

  init: function (cnt) {
    this.cnt = cnt
  },

  onAfterLayout: function () {
    var me = this,
      el = me.cnt.getEl(),
      style = ''

    if (me.cnt.xtype == 'fieldset') {
      style = {
        top: '25px !important',
        right: '10px !important'
      }
    }

    if (this.offSet) {
      style = {
        top: this.offSet['top'] + 'px !important',
        right: this.offSet['right'] + 'px !important',
        width: 100
      }
    }

    var items = this.extraButton.concat(this.cfgButton)

    this.buttonGroup = Ext.create('Ext.toolbar.Toolbar', {
      renderTo: this.cnt.getEl(),
      hidden: true,
      frame: false,
      style: style,
      minWidth: 100,
      ownerCt: this.cnt.ownerCt,

      cls: 'App-x-btn-group',
      items: items
    })

    this.mon(el, {
      scope: this,
      mouseover: this.onMouseover,
      delegate: '*'
    })
    this.mon(el, {
      scope: this,
      mouseleave: this.onMouseleave,

      delay: 1000
    })
  },
  onMouseleave: function (e, el) {
    if (
      e.parentEvent.relatedTarget &&
      e.parentEvent.relatedTarget.className.includes &&
      e.parentEvent.relatedTarget.className.includes('x-menu')
    ) {
      return
    }

    var me = this
    if (me.buttonGroup) {
      me.buttonGroup.setHidden(true)
    }
  },
  onMouseover: function () {
    var me = this

    if (me.buttonGroup) {
      me.buttonGroup.setHidden(false)
    }
  }
})
