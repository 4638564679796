import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Cell,
  Header,
  HeaderGroup,
  Row
} from '@tanstack/react-table'
import { Form } from 'react-bootstrap'
import cx from 'classnames'
import { isEmpty, isNil } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import { resolveClassName, resolveStyle } from './helpers'
import { getFormatedValue } from '@/helpers/formats'
import { defaultPageSize, defaultPageSizeOptions } from './constants'
import ThemeProvider, { themes } from './contexts/ThemeContext'
import Table from './core'
import {
  AggregationRow,
  ColumnMenu,
  ColumnResizer,
  ContextMenu,
  EditableCell,
  FilterRow,
  GlobalFilter,
  IndeterminateCheckbox,
  Pagination,
  Placeholder,
  ProgressBar,
  Tooltip
} from './components'
import { SlvyTableProps } from './SlvyTable.types'

// TODO: Refactor filter functions
function datetimeFilter(row, columnId, value) {
  if (value.timeProp === 'before') {
    if (value.after) {
      return (
        moment(row.getValue(columnId)).isBefore(value.before, 'day') &&
        moment(row.getValue(columnId)).isAfter(value.after, 'day')
      )
    }
    return moment(row.getValue(columnId)).isBefore(value.before, 'day')
  }

  if (value.timeProp === 'after') {
    if (value.before) {
      return (
        moment(row.getValue(columnId)).isAfter(value.after, 'day') &&
        moment(row.getValue(columnId)).isBefore(value.before, 'day')
      )
    }
    return moment(row.getValue(columnId)).isAfter(value.after, 'day')
  }

  return value.on.isSame(moment(row.getValue(columnId)), 'day')
}

function numberFilter(row, columnId, value, _, table) {
  const {
    columnDef: { meta: { format = '' } = {} }
  } = table.getColumn(columnId)
  if (value.value === '') return true
  const rowValue = parseFloat(
    numeral(row.getValue(columnId))
      .format(format)
      .replace(/[^0-9.-]/g, '')
  )

  if (value.comparer === 'gt' || value.comparer === 'lt') {
    if (value.comparer === 'gt') {
      if (!isNil(value.gt) && !isNil(value.lt)) {
        return value.gt < rowValue && value.lt > rowValue
      }

      if (!isNil(value.gt) && isNil(value.lt)) {
        return value.gt < rowValue
      }

      if (isNil(value.gt) && !isNil(value.lt)) {
        return value.lt > rowValue
      }

      return true
    }

    if (value.comparer === 'lt') {
      if (!isNil(value.lt) && !isNil(value.gt)) {
        return value.gt < rowValue && value.lt > rowValue
      }

      if (!isNil(value.lt) && isNil(value.gt)) {
        return value.lt > rowValue
      }

      if (isNil(value.lt) && !isNil(value.gt)) {
        return value.gt < rowValue
      }

      return true
    }
  }

  return isNil(value.eq) ? true : value.eq === rowValue
}

function SlvyTable({
  aggregation = false,
  groupAggregation = false,
  className,
  columns = [],
  checkboxSelection,
  multiRowSelection,
  customExpander,
  data = [],
  defaultColumn = {},
  enableGlobalFilter,
  enableSubRowSelection = true,
  footer,
  getBodyClassName,
  getBodyStyle,
  getCellClassName,
  getCellStyle,
  getColumnMenuItems,
  getContextMenuItems,
  getExpanderColumn,
  getFooterClassName,
  getFooterStyle,
  getFooterHeaderClassName,
  getFooterHeaderStyle,
  getHeadClassName,
  getHeaderClassName,
  getHeaderStyle,
  getHeadStyle,
  getRowClassName,
  getRowId,
  getRowStyle,
  getSubRows = (row) => row.children,
  globalFilterStyle,
  initialState,
  style,
  tableRef,
  suppressColumnMenu,
  remotePaging,
  rowSelection,
  rowDragDrop = false,
  pagingProps,
  pagination,
  paginationOpts,
  grouping = false,
  textWrap = true,
  theme = 'react-table',
  themeOpts,
  density = 'default',
  onEditCell,
  onSelectRow
}: SlvyTableProps<TData, TValue>) {
  const [localData, setLocalData] = useState(data)
  const [filterRow, setFilterRow] = useState<Row<any>>({})
  const [contextMenuTarget, setContextMenuTarget] = useState(undefined)
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [contextMenuItems, setContextMenuItems] = useState([])
  const [selectedCell, setSelectedCell] = useState({})
  const contextMenuContainerRef = useRef(null)
  const showFilterRow = columns.some((column) => column.meta?.filterable)
  const memoizedDefaultColumn = useMemo(() => ({ ...defaultColumn }), [])

  useEffect(() => {
    if (!rowDragDrop) {
      return
    }

    setLocalData(data)
  }, [rowDragDrop, data])

  const themeStyles = themes[theme]
  const isUngroupedHeader = columns?.every((column) => isEmpty(column.columns))

  const handleContextMenu = (params, event) => {
    event.stopPropagation()
    const menus = getContextMenuItems?.(params)
    if (!menus) {
      if (showContextMenu) setShowContextMenu(false)
      return
    }

    event.preventDefault()
    setShowContextMenu(true)
    event.target.getBoundingClientRect = () => new DOMRect(event.clientX, event.clientY)
    setContextMenuTarget(event.target)
    setContextMenuItems(menus)
  }

  const handleHideContextMenu = () => {
    setShowContextMenu(false)
  }

  const handleSelectRow = (row, event) => {
    if (row.isFilterRow) return

    if (rowSelection && onSelectRow) {
      onSelectRow(row, event, table)
    }

    row.toggleSelected(!row.getIsSelected())
  }

  function handleExpand(cell, event) {
    event.stopPropagation()
    const expandHandler = cell.row.getToggleExpandedHandler()
    expandHandler(event)
  }

  const table = useReactTable({
    columns,
    data: rowDragDrop ? localData : data,
    defaultColumn: memoizedDefaultColumn,
    initialState: {
      ...initialState,
      pageIndex: 0,
      pagination: {
        pageSize: paginationOpts?.pageSize ?? defaultPageSize
      }
    },
    filterFns: {
      datetime: (...params) => datetimeFilter(...params, table),
      number: (...params) => numberFilter(...params, table)
    },
    filterFromLeafRows: true,
    autoResetExpanded: false,
    enableMultiRowSelection: Boolean(multiRowSelection),
    enableSubRowSelection,
    getSubRows,
    ...(getRowId ? { getRowId } : {}),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // onRowSelectionChange: (x, ...y) => {
    //   console.log(x)
    // },
    ...(grouping ? { getGroupedRowModel: getGroupedRowModel() } : {}),
    ...(pagination ? { getPaginationRowModel: getPaginationRowModel() } : {}),
    manualPagination: remotePaging,
    manualSorting: remotePaging,
    manualFiltering: remotePaging
  })
  const { rows } = table.getRowModel()
  const { flatRows: filteredFlatRows } = table.getFilteredRowModel()

  useEffect(() => {
    if (isEmpty(filterRow) && rows.length > 0) {
      setFilterRow(rows[0])
    }

    if (tableRef) tableRef.current = table
  })

  const getTableProps = () => ({
    className,
    style,
    theme,
    themeOpts,
    density
  })
  const getTableHeadProps = () => ({
    className: resolveClassName(getHeadClassName),
    style: resolveStyle(getHeadStyle)
  })
  const getTableHeadRowProps = (headerGroup: HeaderGroup<any>) => ({
    key: headerGroup.id
  })
  const getTableHeaderProps = (
    header: Header<any, any>,
    headerGroup: HeaderGroup<any>,
    index: number
  ) => ({
    key: header.id,
    className: cx(
      memoizedDefaultColumn.meta?.className,
      // header.className,
      resolveClassName(getHeaderClassName, { ...header, headers: headerGroup.headers }, index)
    ),
    isSorted: remotePaging
      ? pagingProps.getIsColumnSorted(header.column.columnDef.accessorKey)
      : header.column.getIsSorted(),
    isPinned: header.column.getIsPinned(),
    style: {
      width: header.getSize(),
      minWidth: header.column.columnDef.minSize,
      maxWidth: header.column.columnDef.maxSize,
      justifyContent: header.column.columnDef.meta?.alignHeader,
      textAlign: header.column.columnDef.meta?.alignHeader,
      ...header.style,
      ...resolveStyle(getHeaderStyle, { ...header, headers: headerGroup.headers }, index)
    },
    theme,
    onClick: remotePaging
      ? () => pagingProps.sort(header.column.columnDef.accessorKey)
      : header.column.getToggleSortingHandler()
  })
  const getTableBodyProps = () => ({
    contextMenuRef: contextMenuContainerRef,
    rowDragDrop,
    className: resolveClassName(getBodyClassName),
    style: resolveStyle(getBodyStyle),
    onContextMenu: (event) => handleContextMenu({}, event)
  })
  const getTableBodyRowProps = (row: Row<any>) => ({
    key: row.id,
    className: resolveClassName(getRowClassName, row),
    style: resolveStyle(getRowStyle, row),
    isSelected: row.getIsSelected(),
    onClick: (event) => {
      if (checkboxSelection) return

      handleSelectRow(row, event)
    }
  })
  const getTableBodyCellProps = (cell: Cell<any, any>, index: number) => ({
    key: cell.id,
    className: cx(
      memoizedDefaultColumn.className,
      resolveClassName(getCellClassName, cell, index),
      {
        [themeStyles.selectedCell]:
          selectedCell.cellId === cell.column.id && selectedCell.rowId === cell.row.id
      }
    ),
    style: (ref) => ({
      ...resolveStyle(cell.column.columnDef.meta?.style),
      ...resolveStyle(getCellStyle, { node: ref.current, ...cell }, index),
      width: cell.column.getSize(),
      minWidth: cell.column.columnDef.minSize,
      maxWidth: cell.column.columnDef.maxSize
    }),
    isSelected: true,
    isPinned: cell.column.getIsPinned(),
    isProgressCell: cell.column.columnDef.meta?.type === 'progress',
    isEditable: cell.column.columnDef.meta?.editable,
    textWrap,
    onContextMenu: (event) => handleContextMenu(cell, event),
    onClick: () => {
      setSelectedCell({
        cellId: cell.column.id,
        rowId: cell.row.id
      })
    }
  })
  const getTableFooterProps = () => ({
    className: resolveClassName(getFooterClassName),
    style: resolveStyle(getFooterStyle)
  })
  const getTableFooterRowProps = (footerGroup: HeaderGroup<any>) => ({
    key: footerGroup.id
  })
  const getTableFooterHeaderProps = (footerHeader: any, index: number) => ({
    key: footerHeader.id,
    className: resolveClassName(
      getFooterHeaderClassName,
      { ...footerHeader, headers: table.getFooterGroups().headers },
      index
    ),
    isPinned: footerHeader.column.getIsPinned(),
    style: {
      width: footerHeader.column.columnDef.size,
      minWidth: footerHeader.column.columnDef.minSize,
      maxWidth: footerHeader.column.columnDef.maxSize,
      ...resolveStyle(
        getFooterHeaderStyle,
        { ...footerHeader, headers: table.getFooterGroups().headers },
        index
      )
    }
  })

  const renderCell = (cell: any, index: number) => {
    const {
      column: {
        columnDef,
        columnDef: { meta: { editable, format, dataType, type } = {} }
      },
      getValue
    } = cell

    if (grouping && cell.getIsAggregated()) {
      return null
    }

    if (cell.getIsGrouped()) {
      return (
        <span className="text-secondary fs-sm cp" onClick={(event) => handleExpand(cell, event)}>
          <i
            className={
              cell.row.getIsExpanded()
                ? 'fa fa-fw fa fa-minus-square'
                : 'fa fa-fw fa fa-plus-square'
            }
          />
          <span className="ms-1">{flexRender(columnDef.cell, cell.getContext())}</span>
        </span>
      )
    }

    const firstColumn = table.getAllColumns().at(0)?.id
    const expanderColumn = getExpanderColumn ?? firstColumn
    if (cell.row.getCanExpand() && cell.column.id === expanderColumn && !customExpander) {
      return (
        <div style={{ marginLeft: cell.row.depth * 24 }}>
          <span
            className="cp"
            style={{ fontSize: 14, color: '#4a4a4a', margin: '0 4px 0 -2px', fontWeight: 100 }}
            onClick={(event) => handleExpand(cell, event)}
          >
            <i
              className={
                cell.row.getIsExpanded()
                  ? 'fa fa-fw fa fa-minus-square'
                  : 'fa fa-fw fa fa-plus-square'
              }
            />
          </span>
          {checkboxSelection ? (
            <IndeterminateCheckbox
              checked={cell.row.getIsSelected()}
              indeterminate={cell.row.getIsSomeSelected()}
              onChange={(event) => handleSelectRow(cell.row, event)}
            />
          ) : null}
          {flexRender(columnDef.cell, cell.getContext())}
        </div>
      )
    }

    if (type === 'progress') {
      return <ProgressBar format={format} progress={getValue()} />
    }

    if (editable) {
      return <EditableCell cell={cell} onEditCell={onEditCell} />
    }
    if (format?.trim()) {
      if (cell.column.columnDef.meta?.dataType === 'datetime') {
        return moment(getValue()).format(format)
      }
      return cell.column.columnDef.meta?.type === 'status' ? (
        <div>
          <span
            className={cx(
              themeStyles.cellStatus,
              {
                [themeStyles.success]:
                  cell.row.original[`${cell.column.columnDef.accessorKey}Color`] === '#20BF6B'
              },
              {
                [themeStyles.warning]:
                  cell.row.original[`${cell.column.columnDef.accessorKey}Color`] === '#FFBF00'
              },
              {
                [themeStyles.danger]:
                  cell.row.original[`${cell.column.columnDef.accessorKey}Color`] === '#EB3B5A'
              }
            )}
            // style={{
            //   backgroundColor: cell.row.original[`${cell.column.columnDef.accessorKey}Color`]
            // }}
          ></span>
          <span style={{ zIndex: 5, position: 'relative' }}>
            {/* {numeral(getValue()).format(format)} */}
            {getFormatedValue(format, getValue())}
          </span>
        </div>
      ) : (
        // numeral(getValue()).format(format)
        getFormatedValue(format, getValue())
      )
    }

    return (
      <div
        style={{
          marginLeft:
            !isNil(cell.row.parentId) && cell.column.id === expanderColumn
              ? cell.row.depth * 24 + 20
              : 0
        }}
      >
        {checkboxSelection && cell.column.id === expanderColumn ? (
          <IndeterminateCheckbox
            checked={cell.row.getIsSelected()}
            indeterminate={cell.row.getIsSomeSelected()}
            onChange={(event) => handleSelectRow(cell.row, event)}
          />
        ) : null}
        {flexRender(columnDef.cell, cell.getContext())}
      </div>
    )
  }

  const renderHeader = (header) => {
    const isColumnHeader = header.subHeaders === 0
    const hideColumnMenu = header.column.columnDef?.meta?.hideColumnMenu
    const visibleColumnMenuOptions = header.column.columnDef?.meta?.visibleColumnMenuOptions
    const isColumnResizable = header.column.getCanResize()

    return (
      <>
        <Tooltip
          text={
            header.column.columnDef.tooltipText ||
            header.column.columnDef.header?.trim?.() ||
            header.column.columnDef.id
          }
        >
          <span
            className={cx(
              { [themeStyles.headerText]: textWrap },
              {
                [themeStyles.isFiltered]: remotePaging
                  ? pagingProps.getIsColumnFiltered(header.column.columnDef.accessorKey)
                  : header.column.getIsFiltered()
              }
            )}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}{' '}
          </span>
        </Tooltip>
        {!suppressColumnMenu && !hideColumnMenu && (
          <ColumnMenu
            column={header.column}
            columns={header}
            grouping={grouping}
            isColumnHeader={isColumnHeader}
            items={getColumnMenuItems?.({ column: header.column, table })}
            pagingProps={pagingProps}
            remotePaging={remotePaging}
            table={table}
            visibleColumnMenuOptions={visibleColumnMenuOptions}
          />
        )}
        {isColumnResizable ? (
          <ColumnResizer
            columnSizingInfo={table.getState().columnSizingInfo}
            isResizing={header.column.getIsResizing()}
            onResetSize={header.column.resetSize}
            onResizeColumn={header.getResizeHandler()}
          />
        ) : null}
      </>
    )
  }

  function getPaginationProps() {
    const pageIndex = remotePaging
      ? pagingProps.pageIndex
      : table.getState().pagination.pageIndex + 1
    return {
      canNextPage: remotePaging ? pagingProps.canNextPage : table.getCanNextPage(),
      canPreviousPage: remotePaging ? pagingProps.canPreviousPage : table.getCanPreviousPage(),
      gotoPage: remotePaging ? pagingProps.gotoPage : table.setPageIndex,
      nextPage: remotePaging ? pagingProps.nextPage : table.nextPage,
      pageCount: remotePaging ? pagingProps.pageCount : table.getPageCount(),
      pageIndex,
      pageSize: remotePaging ? pagingProps.pageSize : table.getState().pagination.pageSize,
      previousPage: remotePaging ? pagingProps.previousPage : table.previousPage,
      setPageSize: table.setPageSize,
      totalRows: remotePaging ? pagingProps.totalRowCount : filteredFlatRows.length,
      firstPage: remotePaging ? pagingProps.firstPage : () => table.setPageIndex(0),
      lastPage: remotePaging
        ? pagingProps.lastPage
        : () => table.setPageIndex(table.getPageCount() - 1),
      showPageSizeOptions: !remotePaging && paginationOpts?.showPageSizeOptions,
      pageSizeOptions:
        !remotePaging && !isEmpty(paginationOpts?.pageSizeOptions)
          ? paginationOpts?.pageSizeOptions
          : defaultPageSizeOptions
    }
  }

  const updateRowOrder = (sourceIndex: number, destinationIndex: number) => {
    const orderedData = [...localData]
    const draggedRow = orderedData.splice(sourceIndex, 1)[0]
    orderedData.splice(destinationIndex, 0, draggedRow)
    setLocalData(orderedData)
  }

  return (
    <ThemeProvider theme={themeStyles}>
      <div className={themeStyles?.slvyTableContainer}>
        <Table {...getTableProps()}>
          <Table.Head {...getTableHeadProps()}>
            {enableGlobalFilter ? (
              <GlobalFilter
                globalFilter={table.getState().globalFilter}
                setGlobalFilter={table.setGlobalFilter}
                globalFilterStyle={globalFilterStyle}
              />
            ) : null}
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row {...getTableHeadRowProps(headerGroup)}>
                {headerGroup.headers.map((header, index) => (
                  <Table.Header {...getTableHeaderProps(header, headerGroup, index)}>
                    {renderHeader(header)}
                  </Table.Header>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body {...getTableBodyProps()}>
            {showFilterRow ? (
              <FilterRow
                row={filterRow}
                getTableBodyRowProps={getTableBodyRowProps}
                getTableBodyCellProps={getTableBodyCellProps}
              />
            ) : null}
            {rows.map((row) => {
              const { id, index, subRows, getCanExpand, getIsExpanded, getParentRow } = row

              const isRowExpandable = getCanExpand()
              const isRowExpanded = getIsExpanded()
              const childRows = getParentRow()?.subRows ?? []
              const isLastRow = index + 1 === childRows.length

              const displayAggregationOnParent = isRowExpandable && !isRowExpanded
              const displayAggregationOnChild = !isRowExpandable && isLastRow
              const groupAggregationRows = displayAggregationOnParent ? subRows : childRows
              const isGroupAggregationVisible =
                groupAggregation && (displayAggregationOnParent || displayAggregationOnChild)

              const visibleCells = row
                .getVisibleCells()
                .map((cell, cellIndex) => (
                  <Table.Cell {...getTableBodyCellProps(cell, cellIndex)}>
                    {renderCell(cell, cellIndex)}
                  </Table.Cell>
                ))

              return (
                <Fragment key={id}>
                  {rowDragDrop ? (
                    <Table.DraggableRow
                      {...getTableBodyRowProps(row)}
                      index={index}
                      updateRowOrder={updateRowOrder}
                    >
                      {visibleCells}
                    </Table.DraggableRow>
                  ) : (
                    <Table.Row {...getTableBodyRowProps(row)}>{visibleCells}</Table.Row>
                  )}
                  {isGroupAggregationVisible ? (
                    <AggregationRow rows={groupAggregationRows} columns={columns} />
                  ) : null}
                </Fragment>
              )
            })}
          </Table.Body>
          {aggregation && !remotePaging ? (
            <AggregationRow rows={filteredFlatRows} columns={columns} />
          ) : null}
          {footer ? (
            <Table.Footer {...getTableFooterProps()}>
              {table.getFooterGroups().map((footerGroup) => (
                <Table.Row {...getTableFooterRowProps(footerGroup)}>
                  {footerGroup.headers.map((footerHeader, index) => (
                    <Table.FooterHeader {...getTableFooterHeaderProps(footerHeader, index)}>
                      {flexRender(footerHeader.column.columnDef.footer, footerHeader.getContext())}
                    </Table.FooterHeader>
                  ))}
                </Table.Row>
              ))}
            </Table.Footer>
          ) : null}
        </Table>
        {pagination ? <Pagination {...getPaginationProps()} /> : null}
        <ContextMenu
          container={contextMenuContainerRef}
          items={contextMenuItems}
          show={showContextMenu}
          target={contextMenuTarget}
          onHide={handleHideContextMenu}
        />
      </div>
    </ThemeProvider>
  )
}

export default function Init(props: SlvyTableProps) {
  return props.isLoading ? <Placeholder text={props.noDataMessage} /> : <SlvyTable {...props} />
}
