import { CSSProperties } from 'react'
import { ExcelExportParams, GridOptions } from 'ag-grid-community'
import { LS_PREFIXES } from '@/ts'
import { SlvySelectStyles } from '@/components'
import { AG_GRID_ENUMS } from './enums'
import { IField } from '@/containers/EventMethod/components/Common.types'
import {
  CellEditedClasses,
  CommonFilterParams,
  ICustomRowInfo,
  ConstantGridProps,
  EmptyArray,
  EmptyObject,
  AgIndeterminateIconClass,
  AgRightAlignedHeader,
  Theme,
  IRowData
} from './types'

import * as rowStyles from './scss/_row.module.scss'

export const cellEditedClasses: CellEditedClasses = 'text-danger border border-danger'

export const agRightAlignedHeader: AgRightAlignedHeader = 'ag-right-aligned-header'

export const agIndeterminateIconClass: AgIndeterminateIconClass = 'ag-icon-checkbox-indeterminate'

const {
  ROW_ID,
  DIRTY_CELLS,
  ADDED_ROWS,
  DELETE_COLUMN,
  LOOK_UP_FIELD_DATA,
  CUSTOM_ROW_INFO,
  CHECKBOX_SELECTION_COLUMN,
  AUTO_GROUP_COLUMN
} = AG_GRID_ENUMS

export const CUSTOM_FIELDS: [
  typeof CUSTOM_ROW_INFO,
  typeof DELETE_COLUMN,
  typeof CHECKBOX_SELECTION_COLUMN,
  typeof AUTO_GROUP_COLUMN
] = [CUSTOM_ROW_INFO, DELETE_COLUMN, CHECKBOX_SELECTION_COLUMN, AUTO_GROUP_COLUMN]

export const FIXED_WIDTH_CUSTOM_FIELDS: [
  typeof DELETE_COLUMN,
  typeof CHECKBOX_SELECTION_COLUMN,
  typeof AUTO_GROUP_COLUMN
] = [DELETE_COLUMN, CHECKBOX_SELECTION_COLUMN, AUTO_GROUP_COLUMN]

export const DEFAULT_THEME = 'balham'

// Temporary fix
// Add 4px (depending on theme) to have the same column as SenchaGrid.
// SenchaGrid columns have 10px padding-left and padding-right
// AgGrid columns have balham: 12px, alpine: 18px, quartz: 16px padding-left and padding-right

export const columnHeaderPaddingDifferences: Record<Theme, number> = {
  balham: 0, // fixed with CSS
  alpine: 16 * 1.2,
  quartz: 12 * 1.4
}

export const columnHeaderMenuWidthObj: Record<Theme, number> = {
  balham: 0,
  alpine: 0,
  quartz: 0
}

export const columnHeaderFontDifferences: Record<Theme, number> = {
  // font-family, font-weight etc.
  balham: 0,
  alpine: 16,
  quartz: 16
}

export const paginationPanelHeight: Record<Theme, CSSProperties['height']> = {
  balham: '32px',
  alpine: '49px',
  quartz: '49px'
}
export const footerBarHeight: Record<Theme, CSSProperties['height']> = {
  balham: '40px',
  alpine: '47px',
  quartz: '47px'
}

export const customCheckboxColumnWidth: Record<Theme, number> = {
  balham: 40,
  alpine: 52,
  quartz: 48
}

export const customDeleteColumnWidth: Record<Theme, number> = {
  balham: 40,
  alpine: 56,
  quartz: 52
}

export const iconFontSizeClass: Record<Theme, 'fs-5' | 'fs-6'> = {
  balham: 'fs-6',
  alpine: 'fs-5',
  quartz: 'fs-5'
}

export const withTextIconSize: Record<Theme, '' | 'fs-5' | 'fs-6'> = {
  balham: '', // makes it 12px
  alpine: 'fs-6',
  quartz: 'fs-6'
}

export const themeStyles: Record<Theme, string> = {
  balham: rowStyles.slvyRowFooterBgForBalham,
  alpine: rowStyles.slvyRowFooterBgForAlpine,
  quartz: rowStyles.slvyRowFooterBgForQuartz
}

export const SelectStyles = {
  balham: SlvySelectStyles.smallWithPortal,
  alpine: SlvySelectStyles.portalMenuStyles,
  quartz: SlvySelectStyles.portalMenuStyles
} as const

export const FILTER_DISALLOWED_COLUMN_TYPES = ['icon', 'color'] as const

export const SORTABLE_COLUMN_TYPES = ['simple', 'progress'] as const
export const EDITABLE_COLUMN_TYPES = ['simple', 'progress'] as const

export const defaultBooleanCellIcon = 'ag-icon ag-icon-checkbox-indeterminate' as const
export const defaultBooleanCellColor = 'gray' as const

export const emptyObject: EmptyObject = {}
export const emptyArray: EmptyArray = []

export const DATA_TYPES = {
  bool: null,
  boolean: null,
  datetime: '', // 'Invalid date',
  float: null,
  int: null,
  double: null,
  decimal: null,
  long: null,
  short: null,
  guid: '',
  string: '',
  'array{bool}': [],
  'array{datetime}': [],
  'array{float}': [],
  'array{int}': [],
  'array{string}': [],
  object: null
} as const

export const defaultFieldConfig: IField = {
  dataType: 'string',
  fieldName: ''
}

const { PROJECT_NAME, PLUGIN_NAME, AG_GRID_NAME, STATE_NAME } = LS_PREFIXES
export const AG_GRID_PATH = `${PROJECT_NAME}.${PLUGIN_NAME}.${AG_GRID_NAME}`
export const AG_GRID_STATE = `${AG_GRID_PATH}.${STATE_NAME}`

export const DEFAULT_CUSTOM_ROW_INFO: ICustomRowInfo = {
  [ROW_ID]: 'dc6a040e-d94c-40c6-b7b7-84f87748311b',
  [ADDED_ROWS]: [],
  [CHECKBOX_SELECTION_COLUMN]: false,
  [DIRTY_CELLS]: [],
  [DELETE_COLUMN]: false,
  [LOOK_UP_FIELD_DATA]: false
}

export const commonFilterParams: CommonFilterParams = {
  defaultJoinOperator: 'OR',
  trimInput: true
}

export const constantGridProps: ConstantGridProps = {
  animateRows: false,
  readOnlyEdit: true,
  stopEditingWhenCellsLoseFocus: true,
  suppressDragLeaveHidesColumns: true,
  tooltipShowDelay: 500,
  maintainColumnOrder: true,
  columnMenu: 'legacy'
}

// TODO: Remove filterOptions limitations after all filters added to backend code for pagination.
const agTextColumnFilterOptions = ['contains', 'notContains']
const agNumberColumnFilterOptions = ['equals', 'greaterThan', 'lessThan']
const agDateColumnFilterOptions = ['equals', 'greaterThan', 'lessThan']

export const filterOptions = {
  string: agTextColumnFilterOptions,
  number: agNumberColumnFilterOptions,
  int: agNumberColumnFilterOptions,
  double: agNumberColumnFilterOptions,
  datetime: agDateColumnFilterOptions,
  bool: [], // @v2, bool type causes server error
  decimal: agNumberColumnFilterOptions,
  long: agNumberColumnFilterOptions,
  short: agNumberColumnFilterOptions,
  boolean: [], // @v2, boolean type causes server error
  timespan: agNumberColumnFilterOptions,
  byte: [], // @v2, byte type causes server error
  guid: [], // @v2, guid type causes server error
  'Byte[]': [] // @v2, Byte[] type causes server error
} as const

export const filterSelectorConfig = {
  string: {
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams
  },
  number: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  int: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  double: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  datetime: {
    filter: 'agDateColumnFilter',
    filterParams: commonFilterParams
  },
  bool: {
    filter: true,
    filterParams: commonFilterParams
  },
  decimal: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  long: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  short: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  guid: {
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams
  },
  boolean: {
    filter: true,
    filterParams: commonFilterParams
  },
  timespan: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  byte: {
    filter: 'agNumberColumnFilter',
    filterParams: commonFilterParams
  },
  'Byte[]': {
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams
  }
} as const

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const TAG_FILTER_KEY = 'slvy_tag_filter_text_contains'

export const FILTER_OPERATORS = {
  equals: '=',
  greaterThan: '>',
  lessThan: '<',
  contains: 'like',
  notContains: 'not like',
  in: 'in',
  [TAG_FILTER_KEY]: 'in',
  set: 'in'
}

export const customizedAggDateFuncs = {
  max: 'date max',
  min: 'date min',
  avg: 'date avg',
  sum: 'date sum'
} as const

export const customizedAggBooleanFuncs = {
  max: 'bool max',
  min: 'bool min',
  avg: 'bool avg',
  sum: 'bool sum'
} as const

export const customizedAggColumnTypeFuncs: Record<string, string> = {
  max: 'agg max',
  min: 'agg min',
  avg: 'agg avg',
  sum: 'agg sum',
  first: 'agg first',
  last: 'agg last'
}

export const numberTypes = ['int', 'float', 'double', 'decimal', 'long', 'short']

// TODO check  0: '0',
export const EXCEL_FORMAT_CONVERSION = {
  '': '0',
  0: '0',
  '0.0': '0.0',
  '0.00': '0.00',
  '0.000': '0.000',
  '0,000.0': '#,##0.0',
  '0,000.00': '#,##0.00',
  '0,000.000': '#,##0.000',
  '0,0.0': '#,##0.0',
  '0,0.00': '#,##0.00',
  '0,0.000': '#,##0.000'
} as const

export const CELL_CLASS_FOR_EXCEL_NUMBER_FORMAT = 'ExcelNumberFormatType' as const

export const DEFAULT_EXCEL_COLUMN_WIDTH = 75 as const

export const DEFAULT_EXCEL_FORMAT = '#,##0.00'

type FailCodes = '-1' | '-2'

type FailCodesDictionary = {
  '-1': true
  '-2': false
}

const failCodesDictionary: FailCodesDictionary = {
  '-1': true,
  '-2': false
}

type ResponseDictionary = {
  true: boolean
  false: boolean
}

const commonRefreshDataActions = {
  direct: {
    shouldRefresh: (isSuccess: keyof ResponseDictionary, failCode: FailCodes) => {
      const responseDictionary: ResponseDictionary = {
        true: true,
        false: failCodesDictionary[failCode] ?? true
      }
      const shouldRefresh = responseDictionary[isSuccess]
      return {
        shouldRefresh,
        shouldReset: Boolean(isSuccess || shouldRefresh)
      }
    }
  },
  saveButton: {
    shouldRefresh: (isSuccess: keyof ResponseDictionary, failCode: FailCodes) => {
      const responseDictionary: ResponseDictionary = {
        true: true,
        false: failCodesDictionary[failCode] ?? true
      }
      const shouldRefresh = responseDictionary[isSuccess]
      return {
        shouldRefresh,
        shouldReset: Boolean(isSuccess || shouldRefresh)
      }
    }
  }
}

export const REFRESH_DATA_DICTIONARY = {
  add: commonRefreshDataActions,
  delete: commonRefreshDataActions,
  edit: {
    direct: {
      shouldRefresh: (isSuccess: keyof ResponseDictionary, failCode: FailCodes) => {
        const responseDictionary: ResponseDictionary = {
          true: false,
          false: failCodesDictionary[failCode] ?? false
        }
        const shouldRefresh = responseDictionary[isSuccess]
        return {
          shouldRefresh,
          shouldReset: Boolean(isSuccess || shouldRefresh)
        }
      }
    },
    saveButton: {
      shouldRefresh: (isSuccess: keyof ResponseDictionary, failCode: FailCodes) => {
        const responseDictionary: ResponseDictionary = {
          true: true,
          false: failCodesDictionary[failCode] ?? true
        }
        const shouldRefresh = responseDictionary[isSuccess]
        return {
          shouldRefresh,
          shouldReset: Boolean(isSuccess || shouldRefresh)
        }
      }
    }
  },
  update: {
    direct: {
      shouldRefresh: (isSuccess: keyof ResponseDictionary, failCode: FailCodes) => {
        const responseDictionary: ResponseDictionary = {
          true: false,
          false: failCodesDictionary[failCode] ?? false
        }
        const shouldRefresh = responseDictionary[isSuccess]
        // P.S: Special Case for Direct Update Fails and No failCode
        const shouldReset = isSuccess ? true : failCodesDictionary[failCode] ?? true

        return {
          shouldRefresh,
          shouldReset
        }
      }
    },
    saveButton: {
      shouldRefresh: (isSuccess: keyof ResponseDictionary, failCode: FailCodes) => {
        const responseDictionary: ResponseDictionary = {
          true: true,
          false: failCodesDictionary[failCode] ?? true
        }
        const shouldRefresh = responseDictionary[isSuccess]
        return {
          shouldRefresh,
          shouldReset: Boolean(isSuccess || shouldRefresh)
        }
      }
    }
  }
} as const

export const DEFAULT_CONTEXT: GridOptions<IRowData>['context'] = {
  theme: '',
  pluginId: '',
  additionalArgs: {},
  actualFilters: {}
} as const

export const PROGRESS_LABEL_PLACEMENT: {
  left: 'insideBase'
  right: 'insideEnd'
  center: 'center'
} = {
  left: 'insideBase',
  right: 'insideEnd',
  center: 'center'
}

export const ORANGE = 'rgba(255, 124, 30, 0.06)'
export const RED = 'rgba(215, 44, 44, 0.06)'
export const GREEN = 'rgba(77, 204, 53, 0.06)'
export const BLUE = 'rgba(0, 44, 237, 0.06)'
