Ext.define('Spui.data.store.MaterialSearch', {
  extend: 'Ext.data.Store',
  storeId: 'material-search',
  alias: 'store.material-search',
  proxy: {
    type: 'ajax',
    timeout: 120000,
    url: Spui.Global.getApiUrl() + '/spui/MaterialSearch',
    reader: {
      type: 'json'
    }
  }
})
