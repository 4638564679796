import { Button } from 'react-bootstrap'
import cx from 'classnames'
import { MESSAGES } from '@/elements/messages'
import { AddElementProps } from './AddElement.types'

const AddElement = ({
  containerClass,
  handleContainerClick,
  handleAddElement
}: AddElementProps) => {
  return (
    <div
      className={cx(
        'd-flex align-items-center justify-content-center h-100 bg-opacity-50',
        containerClass
      )}
      role="presentation"
      onClick={handleContainerClick}
    >
      <Button className="fs-xs" size="sm" variant="success" onClick={handleAddElement}>
        {MESSAGES.add_element}
      </Button>
    </div>
  )
}

export default AddElement
