import { ChronoTimelineProps, Item } from './ChronoTimeline.types'

const getInitialItems = (
  pluginData: ChronoTimelineProps['pluginData'],
  dataConfig: ChronoTimelineProps['settings']['config']['data']
) =>
  pluginData?.map(
    (data) =>
      ({
        title: data[dataConfig.timeline.title],
        icon: data[dataConfig.timeline.icon],
        cardTitle: data[dataConfig.card.title],
        cardDetailedText: data[dataConfig.card.text],
        author: data[dataConfig.card.author],
        isReadOnly: data[dataConfig.card.isReadOnly]
      } as Item)
  ) ?? []

export default getInitialItems
