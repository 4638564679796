import superagent from 'superagent'
import _ from 'lodash'
import { API_URL } from '@/constants'

const methods = ['get', 'post', 'put', 'patch', 'del']

function formatUrl(path) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path
  // Prepend host and port of the API server to the path.
  return API_URL + adjustedPath
}

class PromiseWithProgress extends Promise {
  constructor(fn) {
    let self
    super(function (resolve, reject) {
      fn(resolve, reject, (e) => {
        if (self.progressCallback && typeof self.progressCallback === 'function') {
          self.progressCallback(e)
        }
      })
    })
    self = this
  }

  progress = (progress) => {
    this.progressCallback = progress
    return this
  }
}

export default class ApiClientWithProgress {
  constructor() {
    methods.forEach(
      (method) =>
        (this[method] = (path, { params, data, attach } = {}, requestHeader = {}) => {
          return new PromiseWithProgress((resolve, reject, progress) => {
            const request = superagent[method](formatUrl(path))

            if (params) {
              request.query(params)
            }

            if (attach) {
              _.each(attach, (file) => {
                request.attach(...file)
              })
            }

            _.each(requestHeader, (value, key) => {
              request.set(key, value)
            })

            if (progress !== undefined && typeof progress === 'function') {
              request.on('progress', progress)
            }

            if (data) {
              request.send(data)
            }

            request.end((err, { body } = {}) => (err ? reject(body || err) : resolve(body)))
          })
        })
    )
  }
}
