export default {
  title: 'Gillette MDM',
  subTitle: 'Master Data Management',
  version: '1.1',
  namespace: 'GilletteMDM',
  description: '',
  iconClass: 'fa-circle',
  color: 'yellow-bg',
  support: {
    maximizable: true
  },
  group: 'Application'
}
