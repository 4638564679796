Ext.define('MDM.Btl.upload.Import', {
  extend: 'Ext.window.Window',
  defaultListenerScope: true,
  width: 400,
  height: 230,
  bodyPadding: 10,
  closable: false,
  title: 'Import BTLs',
  viewModel: {
    data: {
      importType: 'p'
    }
  },
  resize: false,
  modal: true,
  config: {
    xlsType: {
      fieldLabel: 'Type',
      xtype: 'combo',
      name: 'importType',
      width: 230,
      labelWidth: 50,
      value: 'P',
      margin: '0 0 0 10',
      store: [
        {
          abbr: 'D',
          name: 'Demand'
        },
        {
          abbr: 'P',
          name: 'Production'
        },
        {
          abbr: 'I',
          name: 'Inventory'
        },
        {
          abbr: 'X',
          name: 'Cross Supply'
        },
        {
          abbr: 'R',
          name: 'Razor Draw'
        },
        {
          abbr: 'N',
          name: 'Non-revenue'
        }
      ],
      queryMode: 'local',
      valueField: 'abbr',
      reference: 'importType',
      editable: false,
      forceSelection: true,
      bind: {},
      tpl: Ext.create(
        'Ext.XTemplate',
        '<ul class="x-list-plain"><tpl for=".">',
        '<li role="option" class="x-boundlist-item">{abbr} - {name}</li>',
        '</tpl></ul>'
      ),
      displayTpl: Ext.create('Ext.XTemplate', '<tpl for=".">', '{abbr} - {name}', '</tpl>')
    },
    fileButton: {
      xtype: 'fileuploadfield',
      margin: '0 0 0 10',
      reference: 'basicFile',
      buttonOnly: true,
      hideLabel: true,
      bind: {
        value: '{filePath}',
        disabled: '{!uploadType}'
      },
      ui: 'btn-inverse',
      name: 'fileToUpload',
      buttonConfig: {
        text: 'Browse'
      },
      listeners: {
        change: 'onUploadForm'
      }
    },
    cancelButton: {
      text: 'Cancel',
      handler: 'onCancelTap'
    }
  },
  initComponent: function () {
    var me = this,
      items = []
    items.push(this.getXlsType())
    items.push(this.getFileButton())
    me.fbar = me.generateButtons()
    me.items = [
      {
        xtype: 'form',
        reference: 'uploadForm',
        itemId: 'form',
        flex: '1',
        items: [
          {
            xtype: 'fieldset',
            title: 'Please select import type and file ',
            padding: '0 0 30 0',
            defaultType: 'textfield',
            layout: {
              type: 'hbox',
              pack: 'start',
              align: 'stretch'
            },
            items: items
          }
        ]
      }
    ]
    this.callParent()
  },
  generateButtons: function () {
    var buttons = []
    buttons.push(
      {
        xtype: 'component',
        flex: 1
      },
      this.getCancelButton()
    )
    return buttons
  },
  onUploadForm: function (s) {
    var me = this
    var form = this.down('#form').getForm()
    var file = s.fileInputEl.dom.files[0]
    var data = new FormData()
    data.append('file', file)
    data.append('importType', form.getValues().importType)
    for (var key in me.filterParams) {
      if (me.filterParams.hasOwnProperty(key)) {
        data.append(key, me.filterParams[key])
      }
    }
    var headers = {
      'Content-Type': null // to use content type of FormData
    }
    var postUrl = MDM.Global.getApiUrl() + 'gillettev3/ImportFile'
    Ext.Ajax.request({
      url: postUrl,
      timeout: 360000,
      method: 'POST',
      rawData: data,
      headers: headers,
      success: function (response) {
        var r = Ext.decode(response.responseText, true)
        if (r.success) {
          if (r.sheets) {
            me.promptSheet(r)
          }
        } else {
          Ext.Msg.alert('Failed', r.message)
        }
      },
      failure: function (form, action) {
        Ext.Msg.alert('Failed', action.result ? action.result.message : 'No response')
      }
    })
  },
  onCancelTap: function () {
    this.close()
  },
  promptSheet: function (data) {
    var me = this
    var win = Ext.create('MDM.Btl.upload.Prompt', {
      message: data.message,
      comboData: data.sheets
    }).show()
    win.on('select', function (v) {
      me.reloadData(v, data)
    })
  },
  reloadData: function (v, data) {
    var me = this
    data['selected'] = v
    var importType = me.getViewModel().get('importType').selection.data.abbr
    this.setLoading('loading')
    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/ImportFile_repossess',
      timeout: 360000,
      jsonData: data,
      success: function (response, opts) {
        Ext.getBody().unmask()
        if (me.setLoading) me.setLoading(false)
        var data = Ext.JSON.decode(response.responseText),
          message,
          str
        if (data.success == 0) {
          str = ' '
          message = data['message']
          message.forEach(function (item) {
            str = str + item['message'] + '<br>'
          }, this)
          Ext.Msg.alert('Failed', str)
          return
        }
        var win = Ext.create('MDM.Btlv2.upload.Dialog', {
          importData: data.data,
          importType: importType
        }).show()
        win.on('applyxls', function (record) {
          me.fireEvent('applyxls', record)
          me.close()
        })
      },
      failure: function (response, opts) {
        Ext.getBody().unmask()
        Ext.MessageBox.hide()
        me.setLoading(false)
      }
    })
  }
})
