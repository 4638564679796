import runMethod from './runMethod'
import waitEvent from './waitEvent'
import waitChangeVariables from './waitChangeVariables'
import pageReady from './pageReady'
import delay from './delay'
import loading from './loading'
import runDrilldown from './runDrilldown'
import updateVariables from './updateVariables'
import runRemoteAction from './runRemoteAction'
import runLongRunningAction from './runLongRunningAction'
import condition from './condition'
import remoteActionCompleted from './remoteActionCompleted'
import generateKey from './generateKey'
import changeConnection from './changeConnection'

export default {
  delay,
  loading,
  pageReady,
  waitChangeVariables,
  waitEvent,
  runMethod,
  runDrilldown,
  updateVariables,
  runRemoteAction,
  runLongRunningAction,
  condition,
  remoteActionCompleted,
  generateKey,
  changeConnection
}
