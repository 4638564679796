Ext.define('Assortment.cmp.SimilarOptionsGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'similarOptionsGrid',
  columnLines: true,
  rowLines: true,
  id: 'similarOptionsGrid',
  listeners: {
    select: 'onSimilarOptionClicked'
  },
  viewConfig: {
    enableTextSelection: true,
    getRowClass: function (record) {
      const desc = record.get('similarityDesc')
      const rowClass = desc === 'Forecast' ? 'forecast' : ''
      return rowClass
    }
  },
  columns: [
    {
      xtype: 'actioncolumn',
      items: [
        {
          iconCls: 'fa fa-external-link blueIcon',
          handler: 'similarProductDrilldown'
        }
      ],
      align: 'center',
      width: 40,
      bind: {
        hidden: '{!similarItemExternalLink}'
      }
    },
    {
      xtype: 'checkcolumn',
      header: 'Active',
      tooltip: 'Active',
      headerCheckbox: true,
      dataIndex: 'isEnabled',
      width: 60,
      stopSelection: false,
      listeners: {
        checkchange: 'onSimilarOptionActivityChanged',
        afterRender: function () {
          var me = this.up('assortmentmainview')
          var vm = me.getViewModel()
          var isFutureOption = vm.get('IsFutureOption')
          this.setDisabled(!isFutureOption)
        },
        beforecheckchange: 'beforeSimilarOptionActivityChanged',
        headercheckchange: function (ownerCt, value) {
          var me = this
          var view = me.up('assortmentmainview')
          var viewModel = view.getViewModel()
          var store = viewModel.getStore('similarOptionStore')
          var similarProductId = []

          var datas = store.getDatas()

          datas.forEach((element) => {
            similarProductId.push(element.id)
          })

          var productId = viewModel.get('SelectedOption')
          Ext.Ajax.request({
            url: Assortment.Global.getApiUrl() + 'Assortment/SetSimilarProductStatusMassUpdate',
            method: 'POST',
            params: {
              ProductId: productId,
              SimilarProductId: similarProductId,
              IsEnabled: value
            },
            success: function (response, opts) {
              store.load()
            },
            timeout: 600000
          })
        }
      }
    },
    {
      text: 'Name',
      tooltip: 'Name',
      dataIndex: 'name',
      flex: 1
    },
    {
      text: 'Product Description',
      tooltip: 'Description',
      dataIndex: 'description',
      flex: 1
    },
    {
      text: 'Base Price',
      tooltip: 'Base Price',
      dataIndex: 'basePrice',
      flex: 1,
      align: 'right'
    },
    {
      text: 'Overlapping Ratio',
      tooltip: 'Overlapping Ratio',
      dataIndex: 'overlappingRatio',
      flex: 1,
      renderer: function (rate) {
        return Ext.util.Format.number(rate * 100, '0.##%')
      },
      align: 'right'
    },
    {
      text: 'Start Date',
      tooltip: 'Start Date',
      dataIndex: 'startTime',
      flex: 1,
      renderer: function (value) {
        return Ext.util.Format.date(value, 'd/m/Y')
      },
      align: 'right'
    },
    {
      text: 'End Date',
      tooltip: 'End Date',
      dataIndex: 'endTime',
      flex: 1,
      renderer: function (value) {
        return Ext.util.Format.date(value, 'd/m/Y')
      },
      align: 'right'
    },
    {
      text: 'Total Sales',
      tooltip: 'Total Sales',
      dataIndex: 'totalSales',
      flex: 1,
      align: 'right',
      renderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      }
    },
    {
      text: 'Planned P. Sales',
      tooltip: 'Planned Period Sales',
      dataIndex: 'overlappingTotalSales',
      flex: 1,
      align: 'right',
      renderer: function (value) {
        return Ext.util.Format.number(value, '0,000')
      }
    },
    {
      text: 'Stock',
      tooltip: 'Stock',
      dataIndex: 'stock',
      flex: 1,
      align: 'right'
    },
    {
      text: 'Store Count',
      tooltip: 'Store Count',
      dataIndex: 'storeCount',
      flex: 1,
      align: 'right'
    },
    {
      text: 'Description',
      tooltip: 'Description',
      dataIndex: 'similarityDesc',
      flex: 1
    },
    {
      text: 'Details',
      tooltip: 'Details',
      flex: 2,
      xtype: 'widgetcolumn',
      widget: {
        xtype: 'sparklinetristate',
        posBarColor: '#14CC33',
        bind: {
          values: '{record.similarityDetails}'
        },
        tooltipFormatter: function (sparkline, sparkline2, item) {
          var similarOptionsGrid = Ext.getCmp('similarOptionsGrid')
          var vm = similarOptionsGrid.getViewModel()
          var productAttributeNames = vm && vm.get('ProductAttributeNames')
          if (productAttributeNames) {
            return productAttributeNames[item.offset]
          }
        }
      }
    },
    {
      text: 'Fit',
      tooltip: 'Fit',
      dataIndex: 'fit',
      flex: 1
    },
    {
      text: 'Size Range',
      tooltip: 'Size Range',
      dataIndex: 'sizeRange',
      flex: 1,
      align: 'right',
      renderer: function (value, metaData, record) {
        var similarOptionsGrid = Ext.getCmp('similarOptionsGrid')
        var vm = similarOptionsGrid.getViewModel()
        var selectedOptionSizeRangeTypeId = vm && vm.get('SelectedOptionSizeRangeTypeId')
        var similarProductSizeRangeTypeID = record.get('sizeRangeTypeID')

        if (selectedOptionSizeRangeTypeId !== similarProductSizeRangeTypeID) {
          metaData.tdStyle = 'color:#F5A623'
          metaData.tdAttr =
            'data-qtip="<div>This similar product does not have the same <b>Size Range Type</b> as the selected product.</div>"'
          return (
            '<span>' +
            value +
            '</span> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>'
          )
        } else {
          return value
        }
      }
    }
  ]
})
