export default `({
    title: tt('Store List'),
    listeners: {
        select: function (view, record) {
            var main = this.ownerCt.ownerCt
            store = main.getSalesChartStore(),
                recordData = main.getRecordData(),
                proxy = store.getProxy();
            proxy.setExtraParam('StoreCode', record.get('Storecode'));
            proxy.setExtraParam('MaterialCode', recordData['MaterialCode']);
            store.load();
                    }
    },
    columnLines: true,
    features: [
        // {
        //     ftype: 'summary'
        // },
        {
            ftype: 'grouping',
            startCollapsed: true,
            groupHeaderTpl: '{columnName}: {name} ({rows.length} Item{[values.rows.length > 1 ? "s" : ""]})'
        }
    ],
    // features: [{
    //     groupHeaderTpl: 'Subject: {name}',
    //     ftype: 'groupingsummary'
    // }],
    columns: [{
            text: tt('DcCode'),
            dataIndex: 'DcCode',
            hidden: true,
            summaryType: 'count',
        },
        {
            text: tt('DcName'),
            dataIndex: 'DcName',
            flex: 1,
            hidden: true,
            summaryType: 'count',
        },
        {
            text: tt('StoreCode'),
            dataIndex: 'Storecode',
            summaryType: 'count',
        },
        {
            text: tt('StoreName'),
            dataIndex: 'StoreName',
            flex: 1,
            summaryType: 'count',
        },
        {
            text: tt('Inventory'),
            dataIndex: 'Inventory',
            summaryType: 'sum',
        },
        {
            text: tt('InvoicedQuantity'),
            dataIndex: 'InvoicedQuantity',
            summaryType: 'sum'
        },
    ]
})
`
