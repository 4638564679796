Ext.define('Assortment.cmp.AddSimilarOptionGrid', {
  extend: 'Ext.grid.Panel',
  xtype: 'addSimilarOptionGrid',
  columnLines: true,
  rowLines: true,
  viewConfig: {
    enableTextSelection: true
  },
  columns: [
    {
      text: 'Id',
      dataIndex: 'id',
      hidden: true,
      hideable: false
    },
    {
      text: 'Name',
      dataIndex: 'display',
      width: 250,
      flex: 1
    },
    {
      xtype: 'actioncolumn',
      width: 25,
      menuDisabled: true,
      sortable: false,
      hideable: false,
      items: [
        {
          iconCls: 'fa fa-plus-square similarOptionGreen icon-margin',
          handler: 'OnAddSimilarProductToCart'
        }
      ]
    }
  ]
})
