import * as actionTypes from './actionTypes'

export const selectScenario = (options) => {
  return {
    type: actionTypes.SELECT_SCENARIO,
    payload: options.payload
  }
}

export const resetScenarioSelection = (options) => {
  return {
    type: actionTypes.RESET_SCENARIO,
    payload: options.payload
  }
}

export const selectActualScenario = (options) => {
  return {
    type: actionTypes.SELECT_ACTUAL_SCENARIO,
    payload: options.payload
  }
}

export const resetActualScenarioSelection = (options) => {
  return {
    type: actionTypes.RESET_ACTUAL_SCENARIO,
    payload: options.payload
  }
}
