import { MouseEvent } from 'react'
import cx from 'classnames'
import { useTheme } from '../../contexts/ThemeContext'
import { ColumnResizerProps } from './ColumnResizer.types'

export default function ColumnResizer({
  columnSizingInfo,
  isResizing,
  onResetSize,
  onResizeColumn
}: ColumnResizerProps) {
  const styles = useTheme()

  const handleResizeColumn = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onResizeColumn(event)
  }

  return (
    <div
      className={cx(styles.resizer, { [styles.isResizing]: isResizing })}
      style={{
        transform: `translateX(${1 * (columnSizingInfo.deltaOffset ?? 0)}px)`
      }}
      onDoubleClick={onResetSize}
      onMouseDown={handleResizeColumn}
      onClick={(event) => event.stopPropagation()}
    />
  )
}
