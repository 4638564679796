export default `({
    border: true,
    tpl: new Ext.XTemplate(
        '<div class="grid-container" style="min-width:800px">',
        '<tpl for=".">',
        '<div class="status-item">',
        '<div class="toptext">  {[this.setLang(values.StatusDescription)]} </div>',
        '<div class="topNumber"> {NumberOfRecord} </div>',
        '</div>',
        '</tpl>',
        '</div>', {
            setLang: function (value) {
               
                return tt(value)
            }
        }
    ),
})
`
