export default {
  title: 'Rccp',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'Rccp',
  description: 'Rough-Cut Capacitated Planning',
  iconClass: 'fa-table',
  color: 'yellow-bg',
  support: {
    maximizable: true,
    editable: true
  },
  group: 'Application'
}
