import React, { useState, useEffect } from 'react'
import { connect, Provider } from 'react-redux'
import { thunk } from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { slvyToast } from '../../components'
import createPlugin from '../../BasePlugin'
import { selectCollection } from '../../crudoptV3'
import rootReducer from './store/reducers'
import App from './containers/app'
import { useNetwork } from './hooks/index.ts'
import { NetworkInformation } from './components'
import {
  createPluginState,
  deletePluginState,
  getPluginStates,
  updatePluginState,
  deleteAllPluginState
} from '../../actions/pluginstate'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

window.__SOLVOYO__DP__REQUEST__POOL = []

export function DemandPlanning(props) {
  const initialAutoLoad = props?.settings?.config?.configuration?.isAutoLoadActive ?? true
  const [isAutoLoadActive, setIsAutoLoadActive] = useState(initialAutoLoad)
  const [isVisible, setIsVisible] = useState(true)
  const { isOnline = true } = useNetwork(handleNetworkChange)

  useEffect(() => {
    const { pluginStates, dispatch = () => {} } = props
    pluginStates.needFetch && dispatch(pluginStates.fetch)
  }, [])

  useEffect(() => {
    if (!isVisible) {
      setIsVisible(true)
    }
  }, [isVisible])

  function handleNetworkChange(information) {
    if (information.isOnline) {
      slvyToast.info({ message: 'Connected to the network!', options: { containerId: 'dp' } })
      window.__SOLVOYO__DP__REQUEST__POOL = []
    } else {
      window.__SOLVOYO__DP__REQUEST__POOL.forEach((dpRequest) => dpRequest.abort())
    }
  }

  function reload() {
    setIsVisible(false)
    setIsAutoLoadActive(true)
  }

  return (
    <>
      {isOnline ? null : (
        <div className="w-100 h-100">
          <NetworkInformation show />
        </div>
      )}
      {isVisible ? (
        <Provider store={store}>
          <App {...props} isAutoLoadActive={isAutoLoadActive} isOnline={isOnline} reload={reload} />
        </Provider>
      ) : null}
    </>
  )
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  registerAuthorizations: props.registerAuthorizations,
  settings: props.settings,
  id: props.id,
  params: props.params,
  userName: props.userName,
  preferredUsername: props.preferredUsername,
  token: props.token,
  isAllowed: props.isAllowed,
  size: props.size
})

export default createPlugin(
  connect(
    (state, ownProps) => {
      return {
        pluginStates: selectCollection(getPluginStates(ownProps.id), state.model3)
      }
    },
    (dispatch) => ({
      createPluginState: (key, pluginId, data) => dispatch(createPluginState(key, pluginId, data)),
      updatePluginState: (key, stateId, data) => dispatch(updatePluginState(key, stateId, data)),
      deletePluginState: (key, pluginId, stateId) =>
        dispatch(deletePluginState(key, pluginId, stateId)),
      deleteAllPluginState: (key, pluginId) => dispatch(deleteAllPluginState(key, pluginId)),
      dispatch
    })
  )(DemandPlanning),
  selectConnectorProps,
  true
)
