export default `({
    xtype: treepanel
    columns: [{
        xtype: 'treecolumn', //this is so we know which column will show the tree
        text: tt('Task'),
        width: 200,
        sortable: true,
        dataIndex: 'task',
        locked: true
    }, {
        //we must use the templateheader component so we can use a custom tpl
        xtype: 'templatecolumn',
        text: tt('Duration'),
        width: 100,
        sortable: true,
        dataIndex: 'duration',
        align: 'center',
        //add in the custom tpl for the rows
        tpl: Ext.create('Ext.XTemplate', '{duration:this.formatHours}', {
            formatHours: function (v) {
                if (v < 1) {
                    return Math.round(v * 60) + ' mins';
                } else if (Math.floor(v) !== v) {
                    var min = v - Math.floor(v);
                    return Math.floor(v) + 'h ' + Math.round(min * 60) + 'm';
                } else {
                    return v + ' hour' + (v === 1 ? '' : 's');
                }
            }
        })
    }, {
        text: tt('Assigned To'),
        flex: 1,
        dataIndex: 'user',
        sortable: true
    }, {
        xtype: 'checkcolumn',
        header: 'Done',
        dataIndex: 'done',
        width: 70,
        stopSelection: false
    }]
})
`
