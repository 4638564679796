import { Form } from 'react-bootstrap'
import { IDelayProps } from './Delay.types'
import { SlvyFormInput } from '../../../../../../components'

const Delay = ({ onSave, params: { time = '500' } = {} }: IDelayProps) => {
  const handleChange = (newTime: string) => onSave({ time: newTime })

  return (
    <Form className="p-3" data-testid="delay-job">
      <SlvyFormInput
        autoFocus
        noRowMarginBottom
        columns={{ input: 12, label: 12 }}
        label="Time (ms)"
        placeholder="Enter text"
        size="sm"
        value={time}
        onChange={handleChange}
      />
    </Form>
  )
}

export default Delay
