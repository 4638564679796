export default {
  title: 'Treeview',
  subTitle: 'Solvoyo',
  version: '1.1',
  namespace: 'FilterTreeview',
  description: '',
  iconClass: 'fa-filter',
  color: 'navy-bg',
  group: 'Filters'
}
