export default () => {
  return {
    title: 'Button',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Settings',
        properties: {
          text: {
            type: 'string',
            title: 'Button Text'
          },
          style: {
            type: 'string',
            title: 'Style',
            default: 'primary',
            enum: ['success', 'primary', 'info', 'warning', 'danger', 'link'],
            required: true
          },
          icon: {
            type: 'string',
            title: 'Icon',
            default: 'fa-check',
            format: 'IconSelector'
          },
          iconPosition: {
            type: 'string',
            title: 'Icon Position',
            default: 'Left',
            enum: ['Left', 'Right'],
            required: true
          },
          iconHoverColor: {
            type: 'string',
            format: 'Color',
            default: 'black',
            title: 'Icon Hover Color'
          },
          disabledDefault: {
            type: 'boolean',
            title: 'Disabled by Default',
            required: true,
            default: false
          },
          showVisitedLink: {
            type: 'boolean',
            title: 'Show Visited Link',
            required: true,
            default: true
          },
          confirmation: {
            type: 'boolean',
            title: 'Confirmation',
            required: true,
            default: false
          },
          confirmationText: {
            type: 'string',
            title: 'Confirmation Text',
            default: 'Are you sure to continue?'
          },
          confirmationTitle: {
            type: 'string',
            title: 'Confirmation Title',
            default: 'Confirmation'
          }
        }
      },

      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          text: {
            type: 'string',
            title: 'Button Text',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          enabled: {
            type: 'string',
            title: 'Data Field',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
