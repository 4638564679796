import stringFormat from 'string-format'
import TemplateCellRendererProps from './TemplateCellRenderer.types'

const getValue = (valueFormatted: any, template: string) => {
  const newValueFormatted = valueFormatted ? `${valueFormatted}` : ''
  return stringFormat(template, newValueFormatted)
}

const TemplateCellRenderer = (props: TemplateCellRendererProps) => {
  const {
    api,
    valueFormatted,
    template,
    summaryTemplate,
    colDef: {
      customInfo: { isAutoColumn }
    },
    node: { group },
    context: { groupDisplayType }
  } = props

  if (group && !isAutoColumn && groupDisplayType === 'singleColumn') {
    if (!api?.getGridOption?.('groupTotalRow')) {
      return null
    }
    return <strong>{getValue(valueFormatted, summaryTemplate ?? '')}</strong>
  }

  const formattedValue = getValue(valueFormatted, template)

  if (group && groupDisplayType === 'groupRows') {
    return <strong>{formattedValue}</strong>
  }

  return <>{formattedValue}</>
}

// TODO: This might need to wrap with memo
export default TemplateCellRenderer
