export default () => {
  return {
    title: 'Tree',
    type: 'object',
    format: 'objectTab',

    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: true,
            required: true
          },
          export: {
            type: 'boolean',
            title: 'Exportable',
            default: true,
            required: true
          },
          exportAllColumns: {
            type: 'boolean',
            title: 'Export All Columns',
            default: false,
            required: true
          }
        }
      },
      grid: {
        type: 'object',
        title: 'Grid',
        properties: {
          lockable: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Lockable'
          }
        }
      },
      data: {
        type: 'object',
        title: 'Tree Column',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          display: {
            type: 'string',
            title: 'Display',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          icon: {
            type: 'string',
            title: 'Icon',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          parent: {
            type: 'string',
            title: 'Parent',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          sortOrder: {
            type: 'string',
            title: 'Sort Order',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          checkboxEnableColumn: {
            type: 'string',
            title: 'Checkbox',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          treeColumnHeader: {
            type: 'string',
            title: 'Header',
            description: 'Apply templates using {columnName}'
          },
          width: {
            type: 'number',
            title: 'Width',
            default: 90
          },
          flex: {
            type: 'number',
            title: 'Flex',
            default: 1
          },
          columnLines: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Column Lines'
          },
          rememberTreeState: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Remember Tree State'
          }
        }
      },
      columns: {
        type: 'array',
        title: 'Grid Columns',
        options: {
          syncButton: true
        },
        items: {
          type: 'object',
          title: 'Column',
          headerTemplate: '{{ self.header }}',
          options: {
            collapsed: true
          },
          properties: {
            fieldName: {
              type: 'string',
              title: 'Data Field',
              watch: {
                ds: 'data.datafields'
              },
              enumSource: 'ds'
            },
            header: {
              type: 'string',
              title: 'Header',
              description: 'Apply templates using {columnName}'
            },
            tooltip: {
              type: 'string',
              title: 'Tooltip',
              description: 'Apply templates using {columnName}'
            },
            cellTooltip: {
              type: 'string',
              format: 'Code',
              title: 'Cell Tooltip',
              options: {
                mode: 'htmlmixed',
                lineNumbers: true,
                readOnly: false
              }
            },
            align: {
              type: 'string',
              enum: ['left', 'center', 'right'],
              default: 'end',
              required: true,
              title: 'Align'
            },
            backColor: {
              type: 'string',
              title: 'Back Color',
              format: 'NullColor'
            },
            fontColor: {
              type: 'string',
              title: 'Text Color',
              format: 'NullColor'
            },
            width: {
              type: 'number',
              title: 'Width',
              default: 90
            },
            flex: {
              type: 'number',
              title: 'Flex',
              default: 1
            },
            hidden: {
              type: 'boolean',
              title: 'Hidden',
              default: false,
              required: true
            },
            locked: {
              type: 'boolean',
              title: 'Locked',
              default: false,
              required: true
            },
            editing: {
              type: 'object',
              title: 'Editing',
              options: {
                collapsed: true
              },
              properties: {
                enabled: {
                  type: 'boolean',
                  title: 'Enabled',
                  default: false,
                  required: true
                },
                editableCondition: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds',
                  required: true
                },
                allowBlank: {
                  type: 'boolean',
                  title: 'Allow Blank',
                  default: false,
                  required: true
                }
              }
            },
            action: {
              type: 'object',
              title: 'Action',
              options: {
                collapsed: true
              },
              properties: {
                cellClickEnabled: {
                  type: 'boolean',
                  title: 'Cell Click',
                  default: false,
                  required: true
                }
              }
            },
            actionButton: {
              type: 'object',
              title: 'Action Button',
              options: {
                collapsed: true
              },
              properties: {
                enabled: {
                  type: 'boolean',
                  title: 'Enabled',
                  default: false,
                  required: true
                },
                name: {
                  type: 'string',
                  title: 'Name',
                  required: true,
                  description: 'Apply templates using {columnName}'
                },
                editableCondition: {
                  type: 'string',
                  title: 'Enabled based on',
                  watch: {
                    ds: 'data.datafields'
                  },
                  enumSource: 'ds',
                  required: true
                },
                clickable: {
                  type: 'boolean',
                  title: 'Clickable',
                  description: 'Icon still clickable when disabled',
                  default: false,
                  required: true
                }
              }
            },
            icon: {
              type: 'object',
              title: 'Icon',
              options: {
                collapsed: true
              },
              properties: {
                icon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector'
                },
                displayOnlyIcon: {
                  type: 'boolean',
                  title: 'Display Only Icon',
                  required: true,
                  default: false
                },
                iconPosition: {
                  type: 'string',
                  title: 'Icon Position',
                  enum: ['left', 'right'],
                  required: true,
                  default: 'right'
                }
              }
            }
          }
        }
      },
      editing: {
        type: 'object',
        title: 'Editing',
        properties: {
          dragdropEnabled: {
            type: 'string',
            title: 'Drag&Drop',
            default: 'Disabled',
            required: true,
            enum: [
              'Enabled',
              'Disabled',
              'In the Same Level',
              'Upwards',
              'Downwards',
              'Upwards and Same Level',
              'Downwards and Same Level'
            ]
          },
          textEditingEnabled: {
            type: 'boolean',
            title: 'Text Editing',
            default: false,
            required: true
          },
          addingEnabled: {
            type: 'boolean',
            title: 'Adding',
            default: false,
            required: true
          },
          addingType: {
            type: 'string',
            title: 'Adding Type',
            enum: ['SaveButton', 'Trigger'],
            default: 'SaveButton',
            required: true
          },
          deletingEnabled: {
            type: 'boolean',
            title: 'Deleting',
            default: false,
            required: true
          },
          maxLevel: {
            title: 'Max Level',
            type: 'number',
            default: 10,
            required: true
          },
          enterValueWhileAdding: {
            type: 'boolean',
            title: 'Enter Value While Adding',
            default: false,
            required: true
          },
          addButtonSettings: {
            type: 'object',
            title: 'Add Button',
            options: {
              collapsed: true
            },
            properties: {
              addButtonText: {
                type: 'string',
                title: 'Text',
                default: 'Add Record'
              },
              addButtonTooltip: {
                type: 'string',
                title: 'Tooltip',
                default: 'Add New Record'
              },
              addButtonIcon: {
                type: 'string',
                title: 'Icon',
                format: 'IconSelector',
                default: 'fa fa-plus-square'
              }
            }
          },
          deleting: {
            type: 'object',
            title: 'Deleting',
            options: {
              collapsed: true
            },
            properties: {
              deleteButtonTooltip: {
                type: 'string',
                title: 'Tooltip',
                default: 'Delete Record'
              },
              deleteButtonIcon: {
                type: 'string',
                title: 'Icon',
                format: 'IconSelector',
                default: 'fa fa-times'
              },
              deleteMessage: {
                type: 'string',
                title: 'Delete Message',
                default: 'Are you sure you want to delete {NodeText}?',
                description: 'Apply templates using {NodeText}'
              }
            }
          },
          levels: {
            type: 'array',
            title: 'Levels',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Level',
              headerTemplate: 'Level {{ self.level }}',
              properties: {
                level: {
                  title: 'Level',
                  type: 'number'
                },
                textEditingEnabled: {
                  type: 'boolean',
                  title: 'Text Editing',
                  default: false,
                  required: true
                },
                addingEnabled: {
                  type: 'boolean',
                  title: 'Adding',
                  default: false,
                  required: true
                },
                deletingEnabled: {
                  type: 'boolean',
                  title: 'Deleting',
                  default: false,
                  required: true
                },
                lookupEnabled: {
                  type: 'boolean',
                  title: 'Lookup',
                  default: false,
                  required: true
                },
                dropTo: {
                  type: 'array',
                  title: 'Drop Available To',
                  items: {
                    title: 'level',
                    headerTemplate: 'level {{ self }}',
                    type: 'number'
                  }
                }
              }
            }
          },
          footerButtons: {
            type: 'array',
            title: 'Footer Buttons',
            options: {
              collapsed: true
            },
            items: {
              type: 'object',
              title: 'Button',
              headerTemplate: 'Button {{ self.buttonText }}',
              options: {
                collapsed: true
              },
              properties: {
                buttonText: {
                  type: 'string',
                  title: 'Text',
                  default: ''
                },
                buttonTooltip: {
                  type: 'string',
                  title: 'Tooltip',
                  default: ''
                },
                buttonIcon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector',
                  default: 'fa fa-pie-chart'
                }
              }
            }
          }
        }
      },
      selection: {
        type: 'object',
        title: 'Selection',
        properties: {
          multiSelection: {
            type: 'boolean',
            default: true,
            required: true,
            title: 'Multi Selection'
          },
          nodeDeselection: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Node Deselection',
            description: 'Only works when multiselection is not active'
          },
          checkboxSelection: {
            type: 'boolean',
            default: false,
            required: true,
            title: 'Checkbox Selection',
            description: 'Only works when multiselection is active'
          }
        }
      }
    }
  }
}
