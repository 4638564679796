const Menu = ({ canMove, canDelete, draggableHandleClassName, id, onClickRemove }) => {
  const handleClickContainerEdit = (id) => {
    dispatchEvent(
      new window.CustomEvent('editContainer', {
        detail: id
      })
    )
  }

  const menuShow = canDelete || canMove

  if (!menuShow) {
    return null
  }

  return (
    <div className="container-menu-wrapper">
      <div className="container-menu">
        {canDelete ? (
          <span className="container-menu-link" onClick={onClickRemove}>
            <i aria-hidden="true" className="fa fa-trash" />
          </span>
        ) : null}
        {canMove ? (
          <span className={`container-menu-link ${draggableHandleClassName}`}>
            <i aria-hidden="true" className="fa fa-arrows" />
          </span>
        ) : null}
        <span className="container-menu-link" id={id} onClick={() => handleClickContainerEdit(id)}>
          Settings
        </span>
      </div>
    </div>
  )
}

export default Menu
