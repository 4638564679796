Ext.define('MDM.src.view.regionapp.Panel.HeaderBar', {
  extend: 'Ext.toolbar.Toolbar',
  xtype: 'regionapp-panel-headerbar',
  border: false,
  height: 44,
  margin: 0,
  style: {},
  items: [
    {
      xtype: 'box',
      margin: '4 0 0 0',
      bind: {
        html: '<h3 style="margin:0px">BTL Edit :{currentDPIFamily}</h3>'
      },
      reference: 'headerBarTitle',
      style: {
        'font-size': '1.2em',
        'font-weight': 'bold'
      },
      width: 400
    },
    {
      width: 400,
      fieldLabel: 'Search',
      labelWidth: 50,
      xtype: 'textfield'
    },
    {
      xtype: 'fullscreen',
      icon: '',
      iconCls: 'fa fa-arrows-alt'
    },
    '-',
    {
      margin: '0 0 0 2',
      cls: 'navigation-btn',
      iconCls: 'fa fa-times',
      reference: 'topbaricontabe',
      overCls: Ext.baseCSSPrefix + 'btn-over' + ' myoverCls',
      handler: 'onCloseApp'
    }
  ],

  bbar: {
    xtype: 'statusbar',
    defaultText: 'Default status text',
    reference: 'basic-statusbar',
    // defaults to use when the status is cleared:
    // values to set initially:
    text: 'Ready',
    iconCls: 'x-status-valid',
    // any standard Toolbar items:
    items: [
      {
        xtype: 'tbtext',
        bind: {
          text: 'Total Distance :<b>' + '{summaryData.statusbar.sumTotalDistance}' + ' Km</b>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: { text: 'Total Cost($) : <b>' + '{summaryData.statusbar.sumTransportCost}' + ' </b>' }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Cost By Volume :<b>' + '{summaryData.statusbar.costByVolume}' + ' $/dm3</b>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: { text: 'Cost By Weight :<b>' + '{summaryData.statusbar.costByWeight}' + ' $/Kg</b>' }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Cost By Ladameter :<b>' + '{summaryData.statusbar.costByLadameter}' + ' $/M</b>'
        }
      },
      '-',
      {
        xtype: 'tbtext',
        bind: {
          text: 'Number Of loads :<b>' + '{summaryData.statusbar.numberOfLoad}' + ' Loads</b>'
        }
      },
      '-',
      {
        reference: 'clock',
        xtype: 'tbtext',
        text: Ext.Date.format(new Date(), 'g:i:s A')
      },
      ' '
    ]
  }
})
