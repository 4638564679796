import React from 'react'
import FilterItem from './filterItem'

export default function FilterList(props) {
  const { data = [], selected = {}, onSelect = () => {} } = props

  return data.length ? (
    <ul>
      {data.map(({ Item, Children }, index) => {
        return (
          <FilterItem key={index} Item={Item} selected={selected} onSelect={onSelect.bind(this)}>
            {Children ? (
              <FilterList data={Children} selected={selected} onSelect={onSelect.bind(this)} />
            ) : null}
          </FilterItem>
        )
      })}
    </ul>
  ) : null
}
