import { configureStore } from '@reduxjs/toolkit'
import lookupField from './slices/lookupField'

export const setupStore = () => {
  return configureStore({
    reducer: {
      lookupField
    }
  })
}

export const store = setupStore()

export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
