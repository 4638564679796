import { useState, useEffect } from 'react'

interface CountdownProps {
  remainingTime: number
  minutes: number
  seconds: number
}

const MILLISECONDS_IN_MINUTE = 60000
const MILLISECONDS_IN_SECOND = 1000

const useCountdown = (
  durationInSeconds: number,
  interval: number = MILLISECONDS_IN_SECOND
): CountdownProps => {
  const [remainingTime, setRemainingTime] = useState(durationInSeconds * MILLISECONDS_IN_SECOND)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (remainingTime > 0) {
      timeoutId = setTimeout(() => {
        setRemainingTime(remainingTime - interval)
      }, interval)
    }

    return () => clearTimeout(timeoutId)
  }, [remainingTime, interval])

  useEffect(() => {
    setRemainingTime(durationInSeconds * MILLISECONDS_IN_SECOND)
  }, [durationInSeconds])

  const minutes: number = Math.floor(remainingTime / MILLISECONDS_IN_MINUTE)
  const seconds: number = Math.floor(
    (remainingTime % MILLISECONDS_IN_MINUTE) / MILLISECONDS_IN_SECOND
  )

  return {
    remainingTime,
    minutes,
    seconds
  }
}

export default useCountdown
