import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // TODO check timelineSettings
  currentPeriod: {},
  scrollLeft: 0
}

const timelineSettingsSlice = createSlice({
  name: 'timelineSettings',
  initialState,
  reducers: {
    setCurrentPeriod: (state, action) => {
      state.currentPeriod = action.payload
    },
    setScrollLeft: (state, action) => {
      state.scrollLeft = action.payload
    }
  }
})

export const { setCurrentPeriod, setScrollLeft } = timelineSettingsSlice.actions

export const currentPeriod = (state) => {
  state.currentPeriod
}
export const timelineSettings = (state) => {
  state.timelineSettings
}

export default timelineSettingsSlice.reducer
