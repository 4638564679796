import { lazy } from 'react'
import App from './App'
import Home from './Home'
import Catalog from './Catalog'
import Layout from './Layout'
import LayoutWithoutNav from './LayoutWithoutNav'
import MenuIndex from './Menu'
// eslint-disable-next-line import/no-cycle
import Page from './Page'

const Callback = lazy(() => import(/* webpackChunkName: "Callback" */ './Callback'))
const CatalogAdd = lazy(() => import(/* webpackChunkName: "CatalogAdd" */ './Catalog/Add/index'))
const CatalogEdit = lazy(() => import(/* webpackChunkName: "CatalogEdit" */ './Catalog/Edit'))
const Management = lazy(() => import(/* webpackChunkName: "Management" */ './Management'))
const Menu = lazy(() => import(/* webpackChunkName: "Menu" */ './Menu/List'))
const MenuAdd = lazy(() => import(/* webpackChunkName: "MenuAdd" */ './Menu/Add'))
const MenuEdit = lazy(() => import(/* webpackChunkName: "MenuEdit" */ './Menu/Edit'))
const Notification = lazy(() => import(/* webpackChunkName: "Notification" */ './Notification'))
const PluginSettings = lazy(() =>
  import(/* webpackChunkName: "PluginSettings" */ './PluginSettings')
)
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ './Profile/index'))
const TransferObject = lazy(() =>
  import(/* webpackChunkName: "TransferObject" */ './TransferObject')
)
const Users = lazy(() => import(/* webpackChunkName: "Users" */ './Users'))

export {
  App,
  Callback,
  Catalog,
  CatalogAdd,
  CatalogEdit,
  Home,
  Layout,
  LayoutWithoutNav,
  Management,
  Menu,
  MenuAdd,
  MenuEdit,
  MenuIndex,
  Notification,
  Page,
  PluginSettings,
  Profile,
  TransferObject,
  Users
}
