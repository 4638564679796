import { getUserColumnDefs } from '..'
import { SlvyColDef } from '../../../types'
import { AG_GRID_ENUMS } from '../../../enums'

const { DELETE_COLUMN, CUSTOM_ROW_INFO, CHECKBOX_SELECTION_COLUMN } = AG_GRID_ENUMS

const emptyColumnDefs = [
  {
    field: CHECKBOX_SELECTION_COLUMN
  },
  {
    field: DELETE_COLUMN
  },
  {
    field: CUSTOM_ROW_INFO
  }
] as SlvyColDef[]

const filteredColumnDef = { field: 'col1' }
const filteredColumnDefs = [...emptyColumnDefs, filteredColumnDef] as SlvyColDef[]

const getColumnDefs = (param = ''): SlvyColDef[] => {
  let columns: SlvyColDef[] = []
  if (param === 'isEmpty') {
    columns = emptyColumnDefs
  }

  if (param === 'isFiltered') {
    columns = filteredColumnDefs
  }

  return columns
}

describe('AgGrid:Helpers:Common:getUserColumnDefs', () => {
  let params = getColumnDefs()

  beforeEach(() => {
    params = getColumnDefs()
  })

  it('should filter column that field is DELETE_COLUMN or CUSTOM_ROW_INFO or CHECKBOX_SELECTION_COLUMN', () => {
    params = getColumnDefs('isEmpty')
    const result = getUserColumnDefs(params)
    expect(result).toEqual([])
  })

  it('should filter columns and return one single column which is not DELETE_COLUMN or CUSTOM_ROW_INFO or CHECKBOX_SELECTION_COLUMN', () => {
    params = getColumnDefs('isFiltered')
    const result = getUserColumnDefs(params)
    expect(result).toEqual([filteredColumnDef])
  })
})
