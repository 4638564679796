import _ from 'lodash'

let totalSchema = {}

const pluginSchema = {
  title: 'Chart',
  type: 'object',
  format: 'objectTab',
  properties: {
    general: {
      type: 'object',
      title: 'General',
      properties: {
        name: {
          type: 'string',
          title: 'Name'
        },
        header: {
          type: 'string',
          title: 'Header'
        },
        maximizable: {
          type: 'boolean',
          title: 'Maximizable',
          default: false,
          required: true
        },
        export: {
          type: 'boolean',
          title: 'Exportable',
          default: false,
          required: true
        }
      }
    },
    data: {
      type: 'object',
      title: 'Data',
      properties: {
        datafields: {
          type: 'array',
          format: 'HiddenTable',
          title: 'Data Fields',
          items: {
            type: 'string',
            title: 'Field'
          }
        },
        id: {
          type: 'string',
          title: 'Id',
          watch: {
            ds: 'data.datafields'
          },
          enumSource: 'ds'
        },
        name: {
          type: 'string',
          title: 'Name',
          watch: {
            ds: 'data.datafields'
          },
          enumSource: 'ds'
        },
        value: {
          type: 'string',
          title: 'Value',
          watch: {
            ds: 'data.datafields'
          },
          enumSource: 'ds'
        },
        color: {
          type: 'string',
          title: 'Color',
          watch: {
            ds: 'data.datafields'
          },
          enumSource: 'ds'
        },
        parent: {
          type: 'string',
          title: 'Parent',
          watch: {
            ds: 'data.datafields'
          },
          enumSource: 'ds'
        },
        level: {
          type: 'string',
          title: 'Level',
          watch: {
            ds: 'data.datafields'
          },
          enumSource: 'ds'
        }
      }
    }
  }
}

const chartSchema = {
  chart: {
    title: 'Chart',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      alignTicks: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      animation: {
        type: 'boolean',
        required: true,
        default: true
      },
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: '#ffffff',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'Color',
        default: '#335cad',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      showCredits: {
        type: 'boolean',
        title: 'Show credits?',
        default: false,
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 8
      },
      borderRadius: {
        type: 'number',
        default: 0
      },
      borderWidth: {
        type: 'number',
        default: 0
      },
      className: {
        type: 'string',
        default: ''
      },
      colorCount: {
        type: 'number',
        default: 10
      },
      description: {
        type: 'string',
        default: ''
      },
      height: {
        type: 'string',
        default: ''
      },
      ignoreHiddenSeries: {
        type: 'boolean',
        required: true,
        default: true
      },
      inverted: {
        type: 'boolean',
        required: true,
        default: false
      },
      panKey: {
        type: 'string',
        default: ''
      },
      panning: {
        type: 'boolean',
        required: true,
        default: false
      },
      pinchType: {
        type: 'string',
        default: ''
      },
      plotBackgroundColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      plotBackgroundImage: {
        type: 'string',
        default: ''
      },
      plotBorderColor: {
        type: 'string',
        format: 'Color',
        default: '#cccccc'
      },
      plotBorderWidth: {
        type: 'number',
        default: 0
      },
      plotShadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      polar: {
        type: 'boolean',
        required: true,
        default: false
      },
      reflow: {
        type: 'boolean',
        required: true,
        default: false
      },
      selectionMarkerFill: {
        type: 'string',
        format: 'Color',
        default: 'rgba(51,92,173,0.25)'
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      showAxes: {
        type: 'boolean',
        required: true,
        default: false
      },
      spacingBottom: {
        type: 'number',
        default: 15
      },
      spacingLeft: {
        type: 'number',
        default: 10
      },
      spacingRight: {
        type: 'number',
        default: 10
      },
      spacingTop: {
        type: 'number',
        default: 15
      },

      typeDescription: {
        type: 'string',
        default: ''
      },
      width: {
        type: 'string',
        default: '',
        description: 'In pixels'
      },
      zoomType: {
        options: {
          input_width: '500px',
          basic: true
        },
        type: 'string',
        enum: ['', 'x', 'y', 'xy'],
        default: '',
        required: true,
        propertyOrder: 2
      },
      emptyText: {
        type: 'string',
        title: 'No Data Text',
        default: 'No Data to Display',
        options: {
          basic: true
        },
        propertyOrder: 7
      }
    }
  }
}

const titleSchema = {
  title: {
    title: 'Title',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      margin: {
        type: 'number',
        default: 15
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '18px' },
        options: {
          basic: true
        }
      },

      maxStyle: {
        type: 'object',
        title: 'Max Style',
        format: 'Style',
        default: { color: 'rgba(51,51,51,1)', fontSize: '20px' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: 'Chart Title',
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const subtitleSchema = {
  subtitle: {
    title: 'Subtitle',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      align: {
        type: 'string',
        enum: ['left', 'center', 'right'],
        default: 'center',
        required: true,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      floating: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 3
      },
      style: {
        type: 'object',
        format: 'Style',
        default: { color: 'rgba(102,102,102,1)' },
        options: {
          basic: true
        }
      },
      text: {
        type: 'string',
        default: '',
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      verticalAlign: {
        type: 'string',
        enum: ['', 'top', 'middle', 'bottom'],
        default: '',
        required: true
      },
      widthAdjust: {
        type: 'number',
        default: -44
      },
      x: {
        type: 'number',
        default: 0
      },
      y: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const tooltipSchema = {
  tooltip: {
    title: 'Tooltip',
    type: 'object',
    options: {
      splitProperties: true
    },
    properties: {
      animation: {
        type: 'boolean',
        required: true,
        default: true
      },
      backgroundColor: {
        type: 'string',
        format: 'Color',
        default: 'rgba(247,247,247,0.85)',
        options: {
          basic: true
        },
        propertyOrder: 4
      },
      borderColor: {
        type: 'string',
        format: 'NullColor',
        options: {
          basic: true
        },
        propertyOrder: 5
      },
      borderRadius: {
        type: 'number',
        default: 3
      },
      borderWidth: {
        type: 'number',
        default: 1,
        options: {
          basic: true
        },
        propertyOrder: 6
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: true,
        options: {
          basic: true
        },
        propertyOrder: 1
      },
      followPointer: {
        type: 'boolean',
        required: true,
        default: false
      },
      followTouchMove: {
        type: 'NullableBoolean',
        default: null
      },

      tooltipFormat: {
        type: 'string',
        format: 'Code',
        default: ''
      },
      hideDelay: {
        type: 'number',
        default: 500
      },
      padding: {
        type: 'number',
        default: 8
      },
      pointFormat: {
        type: 'string',
        default: ''
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: true
      },
      shared: {
        type: 'boolean',
        required: true,
        default: false,
        options: {
          basic: true
        },
        propertyOrder: 2
      },
      snap: {
        type: 'NullableNumber',
        default: ''
      },
      split: {
        type: 'boolean',
        required: true,
        default: false
      },
      style: {
        type: 'object',
        format: 'Style',
        default: {
          color: '#333333',
          fontSize: '12px'
        },
        options: {
          basic: true
        }
      },
      useHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      valueDecimals: {
        type: 'NullableNumber',
        default: ''
      },
      valuePrefix: {
        type: 'string',
        default: ''
      },
      valueSuffix: {
        type: 'string',
        default: ''
      },
      xDateFormat: {
        type: 'string',
        default: ''
      }
    }
  }
}

const exportingSchema = {
  exporting: {
    title: 'Exporting',
    type: 'object',
    properties: {
      allowHTML: {
        type: 'boolean',
        required: true,
        default: false
      },
      enabled: {
        type: 'boolean',
        required: true,
        default: false
      },
      fallbackToExportServer: {
        type: 'boolean',
        required: true,
        default: true
      },
      filename: {
        type: 'string',
        default: 'chart'
      },
      libURL: {
        type: 'string',
        default: 'https://code.highcharts.com/{version}/lib'
      },
      printMaxWidth: {
        type: 'number',
        default: 780
      },
      scale: {
        type: 'number',
        default: 2
      },
      sourceHeight: {
        type: 'NullableNumber',
        default: ''
      },
      sourceWidth: {
        type: 'NullableNumber',
        default: ''
      },
      url: {
        type: 'string',
        default: 'https://export.highcharts.com'
      },
      width: {
        type: 'NullableNumber',
        default: ''
      }
    }
  }
}

const treemapSeriesSchema = {
  series: {
    title: 'Series',
    type: 'object',

    properties: {
      name: {
        title: 'Name',
        default: 'Series 1',
        type: 'string',
        description: 'Apply templates using {columnName}'
      },
      allowDrillToNode: {
        type: 'boolean',
        required: true,
        default: false
      },
      allowPointSelect: {
        type: 'boolean',
        required: true,
        default: false
      },
      alternateStartingDirection: {
        type: 'boolean',
        required: true,
        default: false
      },
      animation: {
        type: 'boolean',
        required: true,
        default: true
      },
      animationLimit: {
        type: 'NullableNumber',
        default: ''
      },
      borderColor: {
        type: 'string',
        format: 'Color',
        default: '#e6e6e6'
      },
      borderWidth: {
        type: 'number',
        default: 1
      },
      className: {
        type: 'string',
        default: ''
      },
      color: {
        type: 'string',
        format: 'NullColor'
      },
      colorByPoint: {
        type: 'boolean',
        required: true,
        default: false
      },
      colorIndex: {
        type: 'NullableNumber',
        default: ''
      },
      crisp: {
        type: 'boolean',
        required: true,
        default: true
      },
      cropThreshold: {
        type: 'number',
        default: 300
      },
      cursor: {
        type: 'string',
        default: ''
      },
      dataLabels: {
        type: 'object',
        title: 'Data Labels',
        options: {
          collapsed: true
        },
        propertyOrder: 1001,
        properties: {
          align: {
            type: 'string',
            enum: ['left', 'center', 'right'],
            default: 'center',
            required: true
          },
          allowOverlap: {
            type: 'boolean',
            required: true,
            default: false
          },
          backgroundColor: {
            type: 'string',
            format: 'NullColor'
          },
          borderColor: {
            type: 'string',
            format: 'NullColor'
          },
          borderRadius: {
            type: 'number',
            default: 0
          },
          borderWidth: {
            type: 'number',
            default: 0
          },
          className: {
            type: 'string',
            default: ''
          },
          color: {
            type: 'string',
            format: 'NullColor'
          },
          crop: {
            type: 'boolean',
            required: true,
            default: true
          },
          defer: {
            type: 'boolean',
            required: true,
            default: false
          },
          enabled: {
            type: 'boolean',
            required: true,
            default: true
          },
          format: {
            type: 'string',
            default: '{y}'
          },
          inside: {
            type: 'boolean',
            required: true,
            default: true
          },
          overflow: {
            type: 'string',
            enum: ['justify', 'none'],
            default: 'justify',
            required: true
          },
          padding: {
            type: 'number',
            default: 5
          },
          rotation: {
            type: 'number',
            default: 0
          },
          shadow: {
            type: 'boolean',
            required: true,
            default: false
          },
          shape: {
            type: 'string',
            enum: ['square', 'callout', 'circle'],
            default: 'square',
            required: true
          },
          style: {
            type: 'object',
            format: 'Style',
            default: { fontSize: '11px', fontWeight: 'bold' }
          },
          useHTML: {
            type: 'boolean',
            required: true,
            default: false
          },
          verticalAlign: {
            type: 'string',
            enum: ['top', 'middle', 'bottom'],
            default: 'middle',
            required: true
          },
          x: {
            type: 'number',
            default: 0
          },
          y: {
            type: 'number',
            default: -6
          },
          zIndex: {
            type: 'number',
            default: 6
          }
        }
      },
      description: {
        type: 'string',
        default: ''
      },
      enableMouseTracking: {
        type: 'boolean',
        required: true,
        default: true
      },
      findNearestPointBy: {
        type: 'string',
        default: ''
      },
      getExtremesFromAll: {
        type: 'boolean',
        required: true,
        default: false
      },
      id: {
        type: 'string',
        default: ''
      },
      ignoreHiddenPoint: {
        type: 'boolean',
        required: true,
        default: true
      },
      index: {
        type: 'NullableNumber',
        default: ''
      },
      interactByLeaf: {
        type: 'NullableBoolean',
        default: null
      },
      layoutAlgorithm: {
        type: 'string',
        enum: ['sliceAndDice', 'stripes', 'squarified', 'strip'],
        default: 'sliceAndDice',
        required: true
      },
      layoutStartingDirection: {
        type: 'string',
        enum: ['vertical', 'horizontal'],
        default: 'vertical',
        required: true
      },
      legendIndex: {
        type: 'NullableNumber',
        default: ''
      },
      levelIsConstant: {
        type: 'boolean',
        required: true,
        default: true
      },
      levels: {
        type: 'array',
        title: 'Levels',
        propertyOrder: 1002,
        options: {
          collapsed: true
        },
        items: {
          type: 'object',
          title: 'Level',
          options: {
            collapsed: true
          },
          properties: {
            __Temp: {
              type: 'string',
              options: {
                hidden: true
              }
            },
            borderColor: {
              type: 'string',
              format: 'NullColor'
            },
            borderDashStyle: {
              type: 'string',
              enum: [
                '',
                'Solid',
                'ShortDash',
                'ShortDot',
                'ShortDashDot',
                'ShortDashDotDot',
                'Dot',
                'Dash',
                'LongDash',
                'DashDot',
                'LongDashDot',
                'LongDashDotDot'
              ],
              default: '',
              required: true
            },
            borderWidth: {
              type: 'NullableNumber',
              default: ''
            },
            color: {
              type: 'string',
              format: 'NullColor'
            },
            dataLabels: {
              type: 'object',
              title: 'Data Labels',
              options: {
                collapsed: true
              },
              propertyOrder: 1001,
              properties: {
                align: {
                  type: 'string',
                  enum: ['left', 'center', 'right'],
                  default: 'center',
                  required: true
                },
                allowOverlap: {
                  type: 'boolean',
                  required: true,
                  default: false
                },
                backgroundColor: {
                  type: 'string',
                  format: 'NullColor'
                },
                borderColor: {
                  type: 'string',
                  format: 'NullColor'
                },
                borderRadius: {
                  type: 'number',
                  default: 0
                },
                borderWidth: {
                  type: 'number',
                  default: 0
                },
                className: {
                  type: 'string',
                  default: ''
                },
                color: {
                  type: 'string',
                  format: 'NullColor'
                },
                crop: {
                  type: 'boolean',
                  required: true,
                  default: true
                },
                defer: {
                  type: 'boolean',
                  required: true,
                  default: false
                },
                enabled: {
                  type: 'boolean',
                  required: true,
                  default: false
                },
                format: {
                  type: 'string',
                  default: '{y}'
                },
                inside: {
                  type: 'boolean',
                  required: true,
                  default: true
                },
                overflow: {
                  type: 'string',
                  enum: ['justify', 'none'],
                  default: 'justify',
                  required: true
                },
                padding: {
                  type: 'number',
                  default: 5
                },
                rotation: {
                  type: 'number',
                  default: 0
                },
                shadow: {
                  type: 'boolean',
                  required: true,
                  default: false
                },
                shape: {
                  type: 'string',
                  enum: ['square', 'callout', 'circle'],
                  default: 'square',
                  required: true
                },
                style: {
                  type: 'object',
                  format: 'Style',
                  default: { fontSize: '11px', fontWeight: 'bold' }
                },
                useHTML: {
                  type: 'boolean',
                  required: true,
                  default: false
                },
                verticalAlign: {
                  type: 'string',
                  enum: ['top', 'middle', 'bottom'],
                  default: 'middle',
                  required: true
                },
                x: {
                  type: 'number',
                  default: 0
                },
                y: {
                  type: 'number',
                  default: -6
                },
                zIndex: {
                  type: 'number',
                  default: 6
                }
              }
            },
            layoutAlgorithm: {
              type: 'string',
              enum: ['', 'sliceAndDice', 'stripes', 'squarified', 'strip'],
              default: '',
              required: true
            },
            layoutStartingDirection: {
              type: 'string',
              enum: ['vertical', 'horizontal'],
              default: 'vertical',
              required: true
            },
            level: {
              type: 'NullableNumber',
              default: ''
            }
          }
        }
      },
      maxPointWidth: {
        type: 'NullableNumber',
        default: ''
      },
      opacity: {
        type: 'number',
        default: 0.15
      },
      selected: {
        type: 'boolean',
        required: true,
        default: false
      },
      shadow: {
        type: 'boolean',
        required: true,
        default: false
      },
      showCheckbox: {
        type: 'boolean',
        required: true,
        default: false
      },
      showInLegend: {
        type: 'boolean',
        required: true,
        default: false
      },
      sortIndex: {
        type: 'NullableNumber',
        default: ''
      },
      stickyTracking: {
        type: 'boolean',
        required: true,
        default: true
      },
      tooltip: {
        title: 'Tooltip',
        type: 'object',
        options: {
          collapsed: true
        },
        propertyOrder: 1002,
        properties: {
          followPointer: {
            type: 'boolean',
            required: true,
            default: false
          },
          followTouchMove: {
            type: 'NullableBoolean',
            default: null
          },
          footerFormat: {
            type: 'string',
            default: ''
          },
          headerFormat: {
            type: 'string',
            default: '""'
          },
          hideDelay: {
            type: 'number',
            default: 500
          },
          padding: {
            type: 'number',
            default: 8
          },
          pointFormat: {
            type: 'string',
            default: ''
          },
          split: {
            type: 'boolean',
            required: true,
            default: false
          },
          valueDecimals: {
            type: 'NullableNumber',
            default: ''
          },
          valuePrefix: {
            type: 'string',
            default: ''
          },
          valueSuffix: {
            type: 'string',
            default: ''
          },
          xDateFormat: {
            type: 'string',
            default: ''
          }
        }
      },
      visible: {
        type: 'boolean',
        required: true,
        default: true
      },
      zIndex: {
        type: 'NullableNumber',
        default: ''
      }
    },
    additionalProperties: false
  }
}

export default () => {
  if (_.isEmpty(totalSchema)) {
    _.assign(pluginSchema.properties, treemapSeriesSchema)
    _.assign(pluginSchema.properties, chartSchema)
    _.assign(pluginSchema.properties, titleSchema)
    _.assign(pluginSchema.properties, subtitleSchema)
    _.assign(pluginSchema.properties, tooltipSchema)
    _.assign(pluginSchema.properties, exportingSchema)

    totalSchema = pluginSchema
  }

  return totalSchema
}
