import { getValueGetter } from '../index'
import {
  cellRendererParams as defaultValueGetterParams,
  customConfigs as defaultCustomConfigs,
  fieldConfigs as defaultFieldConfigs
} from '../../__tests__/mocks'

function getColumnType() {
  return defaultCustomConfigs.columnType
}

function getFieldConfig() {
  return defaultFieldConfigs[0]
}

function getValueGetterParams() {
  return defaultValueGetterParams
}

describe('AgGrid:Helpers:Cell:getValueGetter', () => {
  let columnType = getColumnType()
  let fieldConfig = getFieldConfig()
  let valueGetterParams = getValueGetterParams()

  beforeEach(() => {
    columnType = getColumnType()
    fieldConfig = getFieldConfig()
    valueGetterParams = getValueGetterParams()
  })

  it('should return empty object if column type is not sparkline or progress', () => {
    columnType = 'simple'
    const result = getValueGetter({ columnType, fieldConfig })
    expect(result).toEqual({})
  })

  it('should return an object includes a function named if column type is sparkline or progress', () => {
    columnType = 'sparkline'
    const result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
  })

  it('should return an object includes valueGetter function returning empty array if the value is not defined or null', () => {
    columnType = 'sparkline'
    valueGetterParams.data.col1 = undefined
    let result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([])

    valueGetterParams.data.col1 = null
    result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([])
  })

  it('should return an object includes valueGetter function returning empty array if the value is not a string or a number', () => {
    columnType = 'sparkline'
    fieldConfig.fieldName = 'col6'
    fieldConfig.dataType = 'datetime'
    valueGetterParams.data.col6 = new Date()
    let result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([])

    fieldConfig.fieldName = 'col10'
    fieldConfig.dataType = 'bool'
    result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([])

    fieldConfig.fieldName = 'col4'
    fieldConfig.dataType = 'string'
    valueGetterParams.data.col4 = {}
    result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([])
  })

  it('should return an object includes valueGetter function returning an array includes 100 times the value if columnType is progress and the value is a number', () => {
    columnType = 'progress'
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    valueGetterParams.data.col1 = 2
    const result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([200])
  })

  it('should return an object includes valueGetter function returning an array includes 100 times the parsed value if columnType is progress and the value is a string', () => {
    columnType = 'progress'
    fieldConfig.fieldName = 'col5'
    fieldConfig.dataType = 'string'
    valueGetterParams.data.col5 = '3'
    const result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([300])
  })

  it('should return an object includes valueGetter function returning an empty array if columnType is progress and the value cannot be parsed to a number', () => {
    columnType = 'progress'
    fieldConfig.fieldName = 'col5'
    fieldConfig.dataType = 'string'
    valueGetterParams.data.col5 = 'test'
    const result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([])
  })

  it('should return an object includes valueGetter function returning an array includes the value if columnType is sparkline and the value is a number', () => {
    columnType = 'sparkline'
    fieldConfig.fieldName = 'col1'
    fieldConfig.dataType = 'int'
    valueGetterParams.data.col1 = 2
    const result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([2])
  })

  it('should return an object includes valueGetter function returning an array includes the splitted values if columnType is sparkline and the main value is a string', () => {
    columnType = 'sparkline'
    fieldConfig.fieldName = 'col5'
    fieldConfig.dataType = 'string'
    valueGetterParams.data.col5 = '2,3,5'
    const result = getValueGetter({ columnType, fieldConfig })
    expect(result).toHaveProperty('valueGetter')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expect(result.valueGetter(valueGetterParams)).toStrictEqual([2, 3, 5])
  })
})
