import { fetchCollection, fetchRecord, deleteRecord } from '../crudoptV3'

const model = 'template'

export const getTemplate = () => {
  return fetchCollection(model, `/template`)
}

export const createTemplate = (containerId, title) => {
  return fetchRecord(
    model,
    `/template`,
    { data: { containerId, title } },
    { collectionKey: { path: `/template` } }
  )
}

export const restoreTemplate = (catalogId, menuId, pageId, templateId, connectionMap) => {
  return fetchRecord(
    model,
    `catalog/${catalogId}/menu/${menuId}/page/${pageId}/template/${templateId}/restore`,
    { data: { connectionMap } },
    { mapResult: () => {} }
  )
}

export const deleteTemplate = (templateId) => {
  return deleteRecord(
    model,
    `/template/${templateId}`,
    {},
    {
      collectionKey: { path: `/template` },
      key: templateId,
      getRowKey: () => templateId
    }
  )
}
