Ext.define('Clustering.cmp.TreeGridFilter', {
  extend: 'Ext.grid.feature.Feature',
  alias: 'feature.treeGridFilter',
  collapseOnClear: true, // collapse all nodes when clearing/resetting the filter
  allowParentFolders: false, // allow nodes not designated as 'leaf' (and their child items) to  be matched by the filter
  treeGrid: null,
  filterPropertyNames: [],
  filterPropertyValues: [],
  filterColumnRenderers: [],
  init: function (tree) {
    var me = this
    me.tree = tree.ownerGrid
    var treeGrid = tree.ownerGrid
    var view = me.view
    var headerCt = view.headerCt

    // // Listen for header menu being created
    headerCt.on('menucreate', me.onMenuCreate, me)

    treeGrid.filter = Ext.Function.bind(me.filter, me)
    treeGrid.clearFilter = Ext.Function.bind(me.clearFilter, me)
  },

  filter: function (value, property, re, columnRenderer) {
    var me = this,
      tree = me.tree,
      property = property || 'text', // property is optional - will be set to
      // the 'text' propert of the treeStore
      // record by default

      re = re || new RegExp(value, 'ig'), // the regExp could be modified
      // to allow for case-sensitive,
      // starts with, etc.
      filters = tree.store.getFilters()
    // if the search field is empty
    if (Ext.isEmpty(value)) {
      me.clearFilter()
      me.updateValueForName(property, value, columnRenderer)
      return
    }
    me.updateValueForName(property, value, columnRenderer)

    Ext.suspendLayouts()
    tree.suspendLayouts()
    treeGrid.suspendLayouts()
    if (value) {
      this.nameFilter = filters.add({
        filterFn: function (node) {
          var children = node.childNodes,
            len = children && children.length,
            // Visibility of leaf nodes is whether they pass the test.
            // Visibility of branch nodes depends on them having visible children.
            visible = re.test(node.get(property)),
            parent = node.parentNode,
            i,
            matches = [],
            parentMatches = []

          // visible if any parent matches
          while (parent) {
            if (re.test(parent.get(property))) {
              parentMatches.push(parent)
            }

            parent = parent.parentNode
          }
          // iterate over childe nodes in the tree in order to evalute them against
          // the search criteria
          if (value.includes('>')) {
            node.cascadeBy(function (child) {
              // if the child node matches the search criteria
              if (child.get(property) > value.replace('>', '')) {
                // add the node to the matches array
                matches.push(child)
              }
            })
          } else if (value.includes('<')) {
            node.cascadeBy(function (child) {
              // if the child node matches the search criteria
              if (child.get(property) < value.replace('<', '')) {
                // add the node to the matches array
                matches.push(child)
              }
            })
          } else if (value.includes('=')) {
            node.cascadeBy(function (child) {
              // if the child node matches the search criteria
              if (child.get(property) == value.replace('=', '')) {
                // add the node to the matches array
                matches.push(child)
              }
            })
          } else {
            node.cascadeBy(function (child) {
              // if the child node matches the search criteria
              if (re.test(child.get(property))) {
                // add the node to the matches array
                matches.push(child)
              }
            })
          }

          return visible || parentMatches.length || matches.length
        },
        id: 'nameFilter'
      })
    } else if (this.nameFilter) {
      me.clearFilter()
    }
    var view = treeGrid.up('window'),
      viewModel = view.getViewModel()
    viewModel.set('columnFilter', true)
    viewModel.set('isFiltered', true)
    Ext.resumeLayouts()
    tree.resumeLayouts()
    treeGrid.resumeLayouts()
  },

  clearFilter: function () {
    var me = this,
      tree = this.tree,
      root = tree.getRootNode(),
      view = treeGrid.up('window'),
      viewModel = view.getViewModel()

    if (me.collapseOnClear) {
      viewModel.set('columnFilter', false)
      viewModel.set('isFiltered', false)
    }

    if (this.nameFilter) {
      tree.store.filters.remove(this.nameFilter)
      viewModel.set('columnFilter', false)
      viewModel.set('isFiltered', false)
      this.nameFilter = null
    }
  },

  onMenuCreate: function (headerCt, menu) {
    var me = this
    menu.on('beforeshow', me.onMenuBeforeShow, me)
  },

  onMenuBeforeShow: function (menu) {
    var me = this
    var currentHeaderFilter = menu.activeHeader.filter
    var reference = me.reference
    if (currentHeaderFilter == null) {
      if (me.menuItem == null) {
        return
      }
      me.menuItem.hide()
      me.menuSeparator.hide()
    } else if (me.menuItem != null) {
      me.menuItem.show()
      me.menuSeparator.show()
    }
    if (me.menuItem) {
      var perviousFilterValue = me.getValueForName(menu.activeHeader.dataIndex)
      if (perviousFilterValue == null || perviousFilterValue == '') {
        me.menuItem.menu.items.items[0].setRawValue('')
      } else {
        me.menuItem.menu.items.items[0].setRawValue(perviousFilterValue)
      }
    } else {
      me.menuSeparator = menu.add('-')

      var filterTextFiels = new Ext.form.TextField({
        itemId: 'filterTextBox',
        text: 'filter',
        listeners: {
          change: this.onFilterTextChange,
          focusleave: function () {
            var me = this,
              view = me.up('window'),
              viewModel = view.getViewModel(),
              isFiltered = viewModel.get('columnFilter'),
              mainGrid = view.lookupReference(reference),
              column = me.up('gridcolumn')

            mainGrid.suspendLayouts()
            Ext.suspendLayouts()
            if (isFiltered) {
              mainGrid.getRootNode().collapse()
              mainGrid.expandAll()
              column.addCls('x-grid-filters-filtered-column')
            } else {
              column.removeCls('x-grid-filters-filtered-column')
            }
            mainGrid.resumeLayouts()
            Ext.resumeLayouts()
          }
        }
      })
      me.menuItem = menu.add({
        text: 'Filter',
        iconCls: 'fa fa-search',
        menu: {
          items: [filterTextFiels]
        }
      })
    }
    me.menuItem.menu.items.items[0].activeDataIndex = menu.activeHeader.dataIndex
    me.menuItem.menu.items.items[0].activeRenderer = menu.activeHeader.renderer
    me.menuItem.width =
      currentHeaderFilter == null || currentHeaderFilter.width == null
        ? 150
        : currentHeaderFilter.width
  },

  onFilterTextChange: function (searchMenuItem, value) {
    treeGrid.filter(value, searchMenuItem.activeDataIndex, null, searchMenuItem.activeRenderer)
  },

  updateValueForName: function (property, value, columnRenderer) {
    var propertyIndex = -1
    for (var index = 0; index < this.filterPropertyNames.length; index++) {
      if (property == this.filterPropertyNames[index]) {
        propertyIndex = index
        break
      }
    }
    if (propertyIndex >= 0) {
      if (value == null || value == '') {
        this.filterPropertyNames.splice(propertyIndex, 1)
        this.filterPropertyValues.splice(propertyIndex, 1)
        this.filterColumnRenderers.splice(propertyIndex, 1)
      } else {
        this.filterPropertyValues[propertyIndex] = value
      }
    } else {
      propertyIndex = this.filterPropertyNames.length
      this.filterPropertyNames[propertyIndex] = property
      this.filterPropertyValues[propertyIndex] = value
      this.filterColumnRenderers[propertyIndex] = columnRenderer
    }
  },

  getValueForName: function (property) {
    var propertyIndex = -1
    for (var index = 0; index < this.filterPropertyNames.length; index++) {
      if (property == this.filterPropertyNames[index]) {
        propertyIndex = index
        break
      }
    }
    if (propertyIndex >= 0) {
      return this.filterPropertyValues[propertyIndex]
    } else {
      return null
    }
  }
})
