import _ from 'lodash'
import { PluginTypes } from '../../../../../../BasePlugin'
import { IMethodsProps, ObjectWithAny } from './Methods.types'

const sortOptions = (options: ObjectWithAny) => {
  const sortedOptions: ObjectWithAny = {}
  const clonedOptions: ObjectWithAny = { ...options }
  Object.keys(clonedOptions)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    .forEach((variable) => {
      sortedOptions[variable] = clonedOptions[variable]
    })
  return sortedOptions
}

const transformOptions = (variables: IMethodsProps['variables'], param: any, secondType: any) => {
  return _.transform(
    variables,
    (result, { type }, variable) => {
      const { type: firstType } = PluginTypes.fromString(type)
      if (_.isEqual(type, param.type) || _.isEqual(firstType, secondType)) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        result[variable] = { type }
      }
    },
    {}
  )
}

// eslint-disable-next-line import/prefer-default-export
export { sortOptions, transformOptions }
