Ext.define('clustering.Map', {
  extend: 'clustering.vc.leaflet.map',
  alias: ['widget.clusterMap'],
  forceFit: true,
  config: {
    store: null,
    currentStore: null,
    currentSegment: null,
    segmentStore: null,
    dotSize: 30
  },
  bind: {
    store: '{clusterStore}',
    currentStore: '{currentStore}',
    currentSegment: '{currentSegment}',
    segmentStore: '{segmentStore}'
  },
  setCurrentStore: function (record) {
    this.highlightPoint(record)
  },
  setCurrentSegment: function (record) {
    this.highlightGroup(record)
  },
  setStore: function (store) {
    var me = this

    me.createSlider()

    if (store) {
      me.store = store
      store.on('datachanged', function () {
        me.renderMap(store)
      })
    }
  },

  renderMap: function (store) {
    var me = this

    var pointData = store.getDatas()

    var maxTotalSales = Math.max.apply(
      Math,
      pointData.map(function (o) {
        return o.TotalSales
      })
    )

    var map = me.getMap()

    if (me.markers) {
      map.removeLayer(me.markers)
    }

    var markers = []
    for (var i = 0; i < pointData.length; i++) {
      var dotSize = this.getDotSize()

      var markerConfiguration = {
        radius: (pointData[i].TotalSales * dotSize) / maxTotalSales,
        color: pointData[i].Color,
        fill: true,
        weight: 1,
        opacity: 0.1,
        fillColor: pointData[i].Color,
        fillOpacity: 0.4,
        id: pointData[i].Id,
        clusterId: pointData[i].ClusterID,
        record: pointData[i],
        isHighlighted: false,
        isAnimating: true
      }
      // to return original options.
      markerConfiguration.originalOptions = markerConfiguration

      var marker = L.circleMarker(
        [pointData[i].Latitude, pointData[i].Longitude],
        markerConfiguration
      )
      markers.push(marker)
    }

    if (markers.length > 0) {
      me.markers = new L.featureGroup(markers)

      me.markers.addTo(map)

      map.fitBounds(me.markers.getBounds(), { zoom: 7 })
    }

    if (me.markers) me.markers.bringToFront()
  },

  createSlider: function () {
    var me = this
    Ext.create({
      xtype: 'sliderfield',
      cls: 'clustering-x-slider-map',
      increment: 1,
      minValue: 10,
      maxValue: 50,
      width: 200,
      value: 30, // bind from context. and set to context,
      renderTo: this.getEl(),
      label: 'Buble Size',
      listeners: {
        change: function (slider, value) {
          // should resize markers, not re render

          var originalDotSize = me.getDotSize()

          me.setDotSize(value)

          if (!me.markers) return

          var layers = me.markers.getLayers()

          for (var index = 0; index < layers.length; index++) {
            var marker = layers[index]
            marker.options.radius = (marker.options.radius * value) / originalDotSize
            if (marker.options.originalOptions) {
              marker.options.originalOptions.radius =
                (marker.options.originalOptions.radius * value) / originalDotSize
            }
            marker.setStyle(marker.options)
          }
        }
      }
    })
  },

  highlightPoint: function (record) {
    var me = this

    if (!me.markers) return

    me.resetDisplay()

    if (!record) return

    var markerHighlight = {
      weight: 2,
      opacity: 0.5,
      fillOpacity: 0.3,
      color: '#eaeaea',
      isHighlighted: true,
      radius: me.getDotSize() / 2,
      isAnimating: true,
      originalOptions: null
    }

    var markerDownPlay = {
      weight: 1,
      opacity: 0.5,
      fillOpacity: 0.1,
      isHighlighted: true,
      radius: me.getDotSize() / 20,
      isAnimating: true,
      originalOptions: null
    }

    var layers = me.markers.getLayers()

    // TODO : should work with id.
    for (var index = 0; index < layers.length; index++) {
      var marker = layers[index]
      if (marker.options.id == record.Id) {
        markerHighlight.originalOptions = $.extend({}, marker.options.originalOptions)

        marker.setStyle(markerHighlight)
      } else {
        markerDownPlay.originalOptions = $.extend({}, marker.options.originalOptions)

        marker.setStyle(markerDownPlay)
      }
    }
  },

  highlightGroup: function (clusterId) {
    var me = this

    if (!me.markers) return

    me.resetDisplay()

    if (clusterId == null) return

    var markerHighlight = {
      weight: 2,
      opacity: 0.5,
      fillOpacity: 0.3,
      color: '#eaeaea',
      isHighlighted: true,
      radius: me.getDotSize() / 2,
      isAnimating: true,
      originalOptions: null
    }

    var markerDownPlay = {
      weight: 0.5,
      opacity: 0.5,
      fillOpacity: 0.0,
      isHighlighted: true,
      radius: me.getDotSize() / 1000,
      isAnimating: true,
      originalOptions: null
    }

    var layers = me.markers.getLayers()

    for (var index = 0; index < layers.length; index++) {
      var marker = layers[index]
      if (marker.options.clusterId != clusterId) {
        markerDownPlay.originalOptions = $.extend({}, marker.options.originalOptions)

        marker.setStyle(markerDownPlay)
      } else {
        markerHighlight.originalOptions = $.extend({}, marker.options.originalOptions)

        marker.setStyle(markerHighlight)
      }
    }
  },

  resetDisplay: function () {
    var me = this

    if (me.markers) {
      var layers = me.markers.getLayers()

      layers.forEach(function (marker) {
        marker.setStyle(marker.options.originalOptions)
      }, this)
    }
  }
})
