export default () => {
  return {
    title: 'Progress Bar',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            title: 'Name'
          },
          maximizable: {
            type: 'boolean',
            title: 'Maximizable',
            default: false,
            required: false
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Progress Bar Settings',
        properties: {
          title: {
            type: 'string',
            title: 'Title'
          },
          subtitle: {
            type: 'string',
            title: 'Sub Title'
          },
          titleSize: {
            type: 'number',
            title: 'Title Size',
            default: 20,
            minimum: 0
          },
          subTitleSize: {
            type: 'number',
            title: 'Sub Title Size',
            default: 16,
            minimum: 0
          },
          descriptinSize: {
            type: 'number',
            title: 'Description Size',
            default: 22,
            minimum: 0
          },
          chartType: {
            type: 'string',
            title: 'Chart Type',
            enum: ['horizontal', 'circle', 'combined', 'boxProgress'],
            required: true
          },
          theme: {
            type: 'string',
            title: 'Theme',
            enum: ['custom', 'primary', 'success', 'warning', 'danger'],
            default: 'primary',
            required: true
          },
          themeIsInverse: {
            type: 'boolean',
            title: 'Theme Inverse',
            default: false,
            required: true
          },
          textColor: {
            type: 'string',
            format: 'NullColor',
            title: 'Progress Bar & Text Color'
          },
          progressBarColor: {
            type: 'string',
            format: 'NullColor',
            title: 'Progress Bar Bg Color'
          },
          backgroundColor: {
            type: 'string',
            format: 'NullColor',
            title: 'Background Color'
          },
          padding: {
            type: 'integer',
            title: 'Padding',
            format: 'range',
            minimum: 0,
            maximum: 50,
            default: 10
          },
          flexDirection: {
            type: 'string',
            title: 'Direction',
            default: 'column',
            enum: ['column', 'row', 'column-reverse', 'row-reverse'],
            required: true
          },
          alignItems: {
            type: 'string',
            title: 'Align Items',
            default: 'center',
            enum: ['center', 'flex-start', 'flex-end', 'baseline'],
            required: true
          },
          justifyContent: {
            type: 'string',
            title: 'Justify Content',
            default: 'center',
            enum: ['center', 'flex-start', 'flex-end', 'space-between'],
            required: true
          },
          animationDuration: {
            type: 'integer',
            title: 'Animation Duration (ms)',
            format: 'range',
            minimum: 0,
            maximum: 3000,
            default: 1000
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          title: {
            type: 'string',
            title: 'Title',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          subtitle: {
            type: 'string',
            title: 'Sub Title',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          description: {
            type: 'string',
            title: 'Description',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          icon: {
            type: 'string',
            title: 'Icon Name',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          percentage: {
            type: 'string',
            title: 'Percentage',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          percentageFormatField: {
            type: 'string',
            title: 'Percentage Format',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          circlePercentage: {
            type: 'string',
            title: 'Circle Percentage',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          circlePercentageFormatField: {
            type: 'string',
            title: 'Circle Percentage Format',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          circleDescription: {
            type: 'string',
            title: 'Circle Description',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          textColor: {
            type: 'string',
            title: 'Progress Bar & Text Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          progressBarColor: {
            type: 'string',
            title: 'Progress Bar Bg Color',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
