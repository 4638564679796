import React from 'react'

export default function FilterItem(props) {
  const {
    Item: currentItem,
    Item: { IsFilter = false, Id: currentItemId = '', Key = '', IsSelectable = false } = {},
    selected: { Id: selectedItemId = 0 } = {},
    onSelect = () => {}
  } = props

  function _onSelect() {
    if (!IsFilter) {
      return
    }
    onSelect(currentItem)
  }

  const noSelected = selectedItemId === 0
  const active = !noSelected && currentItemId === selectedItemId
  const disabled = !IsFilter

  const disabledClass = disabled ? ' disabled ' : ''
  const activeClass = active ? ' active ' : ''

  return (
    <li>
      <span className={`${disabledClass} ${activeClass}`} onClick={_onSelect.bind(this)}>
        {IsSelectable ? (
          <i className="slvy-ui-icon-angle-right" />
        ) : (
          <i className="slvy-ui-icon-minus-outline" />
        )}
        {Key}
      </span>
      {props.children}
    </li>
  )
}
