/* global Ext */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import hash from 'object-hash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { Pivotgrid } from '@sencha/ext-react-classic'
import { confirmAlert } from 'react-confirm-alert'
import { selecOperationStatus, selectCollection, isSuccess } from '@/crudoptV3'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { slvyToast, ExtRoot } from '@/components'
import { getFormatedValue } from '@/helpers/formats'
import { __RowIndex } from '@/store/slices/localData'
import pivotOverrides from './extjsOverrides'
import customAggs from './customAggs'
import { getExtContainerSize, hasClass } from '@/helpers'
import selectConnectorProps from './selectConnectorProps'
import { API_URL } from '@/constants'
import {
  getPluginStates,
  createPluginState,
  deletePluginState,
  updatePluginState
} from '@/actions/pluginstate'
import './index.scss'

Ext.require(['Ext.pivot.*', 'Ext.pivot.plugin.*', 'Ext.pivot.plugin.CellEditing'])

function getControlValue(value) {
  // Number() converts null to 0, so adding {} as fallback makes it returns NaN
  // Check whether the value is empty string because the Number converts empty string to 0!
  const isEmptyString = value === ''
  if (!isEmptyString && (typeof value === 'string' || typeof value === 'number')) {
    return Number(value)
  }
  return NaN
}

class SenchaPivot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      forceReInit: Date.now(),
      args: {}
    }

    this.customAggragators = customAggs(this)
    this.editList = []
    this.executedUpdates = {}
    this.expanded = false
    this.excelFormatConversion = {
      '': '0',
      0: '0',
      '0.0': '0.0',
      '0.00': '0.00',
      '0.000': '0.000',
      '0,000.0': '#,##0.0',
      '0,000.00': '#,##0.00',
      '0,000.000': '#,##0.000',
      '0,0.0': '#,##0.0',
      '0,0.00': '#,##0.00',
      '0,0.000': '#,##0.000'
    }
    this.matrixProcessingState = 0 // 0: Done, 1:Working
    this.pivotStateBeforeUpdate = null
    this.reloadAfterUpdateInProgress = false
    this.reloadAfterUpdateQueue = []
    this.rendered = false
    this.updateInProgress = false

    this.containerRef = null
    this.resizeObserver = null

    this.bindMethods()
    pivotOverrides(this)
  }

  bindMethods() {
    this.beforeconfigchange = this.beforeconfigchange.bind(this)
    this.beforestaterestore = this.beforestaterestore.bind(this)
    this.beforestatesave = this.beforestatesave.bind(this)
    this.cellRenderer = this.cellRenderer.bind(this)
    this.collapse = this.collapse.bind(this)
    this.customAggsFunc = this.customAggsFunc.bind(this)
    this.expand = this.expand.bind(this)
    this.exportAllToXlsx = this.exportAllToXlsx.bind(this)
    this.exportVisibleToXlsx = this.exportVisibleToXlsx.bind(this)
    this.formatTopAndLeftAxisItems = this.formatTopAndLeftAxisItems.bind(this)
    this.handleAfterLayout = this.handleAfterLayout.bind(this)
    this.handleAfterRender = this.handleAfterRender.bind(this)
    this.handleBeforeEdit = this.handleBeforeEdit.bind(this)
    this.handleCellClick = this.handleCellClick.bind(this)
    this.handleCellEdit = this.handleCellEdit.bind(this)
    this.handleCellKeydown = this.handleCellKeydown.bind(this)
    this.handleEditorSpecialkey = this.handleEditorSpecialkey.bind(this)
    this.handleExpandCollapse = this.handleExpandCollapse.bind(this)
    this.handleMatrixAfterUpdate = this.handleMatrixAfterUpdate.bind(this)
    this.handleMatrixProcessingDone = this.handleMatrixProcessingDone.bind(this)
    this.handleMatrixProcessingStart = this.handleMatrixProcessingStart.bind(this)
    this.handleNumericKeyPressed = this.handleNumericKeyPressed.bind(this)
    this.handlePivotDone = this.handlePivotDone.bind(this)
    this.handleResetChanges = this.handleResetChanges.bind(this)
    this.handleRowSelected = this.handleRowSelected.bind(this)
    this.handleSaveChanges = this.handleSaveChanges.bind(this)
    this.handleStoreUpdate = this.handleStoreUpdate.bind(this)
    this.handleTriggerSaveChanges = this.handleTriggerSaveChanges.bind(this)
    this.handleValidateEdit = this.handleValidateEdit.bind(this)
    this.onAddState = this.onAddState.bind(this)
    this.onbookmarkclick = this.onbookmarkclick.bind(this)
    this.saveState = this.saveState.bind(this)
    this.setFooterButtons = this.setFooterButtons.bind(this)
    this.updateClientData = this.updateClientData.bind(this)
    this.updateRemoteValue = this.updateRemoteValue.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.pivotgrid || this.state.forceReInit !== nextState.forceReInit) {
      return true
    }
    if (!_.isEqual(this.props.pluginStates, nextProps.pluginStates)) {
      // Rerender if a plugin state is added or removed
      return true
    }
    if (
      _.isEqual(this.props.settings.config, nextProps.settings.config) &&
      _.isEqual(this.props.pluginData, nextProps.pluginData)
    ) {
      return false
    }
    const {
      pluginData: nextPluginData = [],
      settings: {
        general: { remoteMatrix = false } = {},
        config: {
          leftAxis: nextLeftAxis = {},
          topAxis: nextTopAxis = {},
          aggregate: nextAggregate = {},
          keys = [],
          matrix: nextMatrix,
          matrix: {
            rowGrandTotalsPosition: nextRowGrandTotalsPosition = remoteMatrix ? 'first' : 'none',
            textGrandTotalTpl: cfgTextGrandTotalTpl,
            textRowLabels: cfgTextRowLabelsConfig,
            viewLayoutType: nextViewLayoutType = {},
            colGrandTotalsPosition: nextColGrandTotalsPosition,
            colSubTotalsPosition: nextColSubTotalsPosition,
            rowSubTotalsPosition: nextRowSubTotalsPosition
          } = {}
        } = {}
      } = {}
    } = nextProps

    const {
      props: {
        pluginData,
        settings: { config: { aggregate, leftAxis, matrix, topAxis } = {} } = {}
      } = {}
    } = this

    // WORKAROUND: If plugin data is we want to show the empty text
    // rowGrandTotalsPosition prevents empty text
    const rowGrandTotalsPosition =
      nextPluginData === null || nextPluginData.length === 0
        ? remoteMatrix
          ? 'first'
          : 'none'
        : nextRowGrandTotalsPosition

    let ret = true

    const textGrandTotalTplConfig = cfgTextGrandTotalTpl || 'Grand total'
    const prevFirstDataRow = this.getFirstDataRow(pluginData)
    const prevTextGrandTotalTpl = this.replaceTemplate(textGrandTotalTplConfig, prevFirstDataRow)
    const nextFirstDataRow = this.getFirstDataRow(nextPluginData)
    const nextTextGrandTotalTpl = this.replaceTemplate(textGrandTotalTplConfig, nextFirstDataRow)

    const textRowLabels = cfgTextRowLabelsConfig || 'Grand total'
    const prevTextRowLabels = this.replaceTemplate(textRowLabels, prevFirstDataRow)
    const nextTextRowLabels = this.replaceTemplate(textRowLabels, nextFirstDataRow)
    const prevVisibleByValues = _.map(leftAxis, (leftAxisItem) => {
      const { visibleBy = '' } = leftAxisItem
      return visibleBy && prevFirstDataRow && prevFirstDataRow[visibleBy]
    })

    const nextVisibleByValues = _.map(nextLeftAxis, (leftAxisItem) => {
      const { visibleBy = '' } = leftAxisItem
      return visibleBy && nextFirstDataRow && nextFirstDataRow[visibleBy]
    })
    // If the plugin configuration changes, do not rerender the plugin
    // But just reconfigure the pivot
    if (
      !_.isEqual(matrix, nextMatrix) ||
      !_.isEqual(aggregate, nextAggregate) ||
      !_.isEqual(leftAxis, nextLeftAxis) ||
      !_.isEqual(topAxis, nextTopAxis) ||
      !_.isEqual(this.pivotgrid.cmp.matrix.rowGrandTotalsPosition, rowGrandTotalsPosition) ||
      !_.isEqual(prevTextGrandTotalTpl, nextTextGrandTotalTpl) ||
      !_.isEqual(prevTextRowLabels, nextTextRowLabels) ||
      !_.isEqual(prevVisibleByValues, nextVisibleByValues)
    ) {
      var aggregates = _.cloneDeep(nextAggregate)
      _.remove(aggregates, (field) => !field.dataIndex)
      var leftAxisItems = _.cloneDeep(nextLeftAxis)
      _.remove(leftAxisItems, (field) => !field.dataIndex)
      var topAxisItems = _.cloneDeep(nextTopAxis)
      _.remove(topAxisItems, (field) => !field.dataIndex)

      // Remove items which are not visible by default
      aggregates = _.remove(aggregates, (field) => {
        return !('visibleByDefault' in field) || field.visibleByDefault
      })

      leftAxisItems = _.remove(leftAxisItems, (field) => {
        return !('visibleByDefault' in field) || field.visibleByDefault
      })

      topAxisItems = _.remove(topAxisItems, (field) => {
        return !('visibleByDefault' in field) || field.visibleByDefault
      })

      _.forEach(aggregates, (aggregate) => {
        aggregate.renderer = this.cellRenderer(aggregate)
        aggregate.editor = 'numberfield'

        if (aggregate.customAggregator) {
          const customAggregator = _.find(this.customAggragators, {
            name: aggregate.customAggregator
          })
          if (customAggregator) {
            aggregate.aggregator = customAggregator.fn
            aggregate.customAggregator = customAggregator.name
          } else {
            const wantedAggregate = this.customAggsFunc(aggregate.customAggregator)
            if (wantedAggregate.enabled) {
              aggregate.aggregator = wantedAggregate.func
              aggregate.customAggregator = aggregate.customAggregator
            }
          }
        }
      })

      _.forEach(leftAxisItems, (item) => {
        item.header = this.replaceTemplate(item.header, nextFirstDataRow)
      })

      _.forEach(topAxisItems, (item) => {
        item.header = this.replaceTemplate(item.header, nextFirstDataRow)
      })

      leftAxisItems = _.filter(leftAxisItems, (leftAxisItem) => {
        const { visibleBy = '' } = leftAxisItem
        return !visibleBy || (visibleBy && nextFirstDataRow && nextFirstDataRow[visibleBy])
      })

      this.pivotgrid.cmp.matrix.textGrandTotalTpl = nextTextGrandTotalTpl
      this.pivotgrid.cmp.matrix.textRowLabels = nextTextRowLabels
      this.pivotgrid.cmp.matrix.reconfigure({
        viewLayoutType: nextViewLayoutType.viewLayoutType,
        compactViewColumnWidth: nextViewLayoutType.compactViewColumnWidth,
        rowGrandTotalsPosition: rowGrandTotalsPosition,
        colGrandTotalsPosition: nextColGrandTotalsPosition,
        colSubTotalsPosition: nextColSubTotalsPosition,
        rowSubTotalsPosition: nextRowSubTotalsPosition,
        aggregate: aggregates,
        leftAxis: leftAxisItems,
        topAxis: topAxisItems
      })
      ret = false
    }
    // If the plugin data changes, do not rerender the plugin
    // But just update its store
    if (nextPluginData && !_.isEqual(pluginData, nextPluginData)) {
      let pluginDataReady = false
      if (_.size(nextPluginData) > 0 && _.has(nextPluginData[0], __RowIndex)) {
        pluginDataReady = true
      } else if (_.size(nextPluginData) === 0 && _.isObject(nextPluginData)) {
        // If there are no rows in the plugindata we can check if is an object
        // Because while adding the Solvoyo Row Index we change the plugin data from array to object
        pluginDataReady = true
      }

      if (this.matrixProcessingState === 0 && pluginDataReady) {
        let pivotData = _.cloneDeep(nextPluginData)
        // Remove this data mapping after rowindex adding is fixed
        pivotData = _.map(pivotData, (row) => {
          return row
        })

        pivotData = this.formatTopAndLeftAxisItems(pivotData, leftAxis, topAxis, aggregate)

        const { data: { setDataArgsKey } = {} } = nextProps
        if (setDataArgsKey) {
          // TODO setDataArgsKey not added for remoteMatrix
          if (this.executedUpdates[setDataArgsKey]) {
            const { data: updateRequestString = '' } = this.executedUpdates[setDataArgsKey]
            let updatedRows = []
            if (_.size(this.reloadAfterUpdateQueue) > 0 || this.updateInProgress) {
              const updateRequestData = JSON.parse(updateRequestString)
              const { updateItems = [] } = updateRequestData
              updatedRows = _.map(updateItems, (updateItem) => {
                const { config = {} } = updateItem
                return config
              })
            }
            this.setDataToStore(pivotData, keys, updatedRows)
            delete this.executedUpdates[setDataArgsKey]
          }
          this.reloadAfterUpdateInProgress = false
          this.processReloadAfterUpdateRequest()
        } else {
          this.store.setData(pivotData)
        }

        // The pivot might be updating because of a update operation
        // In this case we have to restore pre-update state of the pivot
        // Since it is not done automatically
        if (this.pivotStateBeforeUpdate) {
          this.applyStateToPivot(this.pivotStateBeforeUpdate)
          // Reset the saved state after using. When axis config changes after nonupdate requests,
          // we should not remember and set the old state.
          this.pivotStateBeforeUpdate = null
        }
      }

      ret = false
    }
    return ret
  }

  UNSAFE_componentWillMount() {
    const pivotConfig = this.props.settings.config

    if (this.props.onReady) {
      this.props.onReady({
        changedHandlers: [this.handleDataColumnChanged]
      })
    }

    this.store = Ext.create('Ext.data.Store', {
      listeners: {
        update: this.handleStoreUpdate
      }
    })

    const { pluginStates } = this.props

    if (pluginStates.needFetch) {
      const pluginId = this.props.id
      this.props
        .dispatch(pluginStates.fetch)
        .then(() => this.props.reloadExtRoot(pluginId, () => this.observeResize(pluginId)))
    }

    // Register drill down event
    const gridSettings = this.props.settings

    if (gridSettings.query && gridSettings.query.fields) {
      this.handleCellDoubleClick = this.props.registerEvent({
        key: 'Drilldown',
        fn: this.handleCellDoubleClick.bind(this),
        returnTypes: _.transform(
          gridSettings.query.fields,
          (result, field) => {
            result[field.fieldName] = PluginTypes.fromString(field.dataType)
          },
          {}
        )
      })

      this.handleRowSelected = this.props.registerEvent({
        key: 'RowSelect',
        fn: this.handleRowSelected.bind(this),
        returnTypes: _.transform(
          gridSettings.query.fields,
          (result, field) => {
            result[field.fieldName] = PluginTypes.fromString(field.dataType)
          },
          {}
        )
      })
    }

    // Register pivot updated event
    this.handleDataUpdated = this.props.registerEvent({
      key: 'DataUpdated',
      fn: this.handleDataUpdated.bind(this),
      returnTypes: { refreshKey: PluginTypes.string }
    })

    const { pivot: { updateParameters = [], footerButtons = [] } = {} } = pivotConfig || {}

    const filteredUpdateParameters = _.filter(updateParameters, (updateParameter) => {
      return updateParameter.name !== ''
    })

    // Register save method
    this.props.registerMethod({
      key: 'saveChanges',
      fn: this.handleSaveChanges.bind(this),
      args: [
        { name: 'refreshKey', type: PluginTypes.string },
        ..._.map(filteredUpdateParameters, (updateParameter) => {
          const { name, type } = updateParameter
          return { name, type: PluginTypes.fromString(type) }
        })
      ]
    })

    this.props.registerMethod({
      key: 'setDataArgumentsRemote',
      fn: this.handleRemoteArguments.bind(this),
      args: this.props.getQueryArguments()
    })

    // Register footer button events
    _.forEach(footerButtons, (footerButton) => {
      const { buttonText } = footerButton
      if (buttonText) {
        // Register footer button clicked event
        this['handleFooterButtonClick_' + buttonText] = function () {
          return this.handleFooterButtonClick()
        }

        this['handleFooterButtonClick_' + buttonText] = this.props.registerEvent({
          key: 'FooterButtonClick_' + buttonText,
          fn: this['handleFooterButtonClick_' + buttonText].bind(this),
          returnTypes: { refreshKey: PluginTypes.string }
        })
      }
    })

    this.createPivotStyles(this.props.id)
  }

  componentDidMount() {
    // Register for authorizations
    this.props.registerAuthorizations([
      'PublicStateAuthorization',
      'ExportStateAuthorization',
      'Editing'
    ])

    if (!this.props.pluginStates.needFetch) {
      const pluginId = this.props.id
      this.props.reloadExtRoot(pluginId, () => this.observeResize(pluginId))
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isSuccess(this.props.query, nextProps.query)) {
      // Add row index to grid, we need it while editing
      this.props.addRowIndexToLocalData()
    }
    if (
      isSuccess(this.props.pluginStateStatus, nextProps.pluginStateStatus) ||
      isSuccess(this.props.pluginStateDeleteStatus, nextProps.pluginStateDeleteStatus) ||
      isSuccess(this.props.pluginStateUpdateStatus, nextProps.pluginStateUpdateStatus) ||
      !_.isEqual(
        this.props.settings.config.pivotconfigurator,
        nextProps.settings.config.pivotconfigurator
      ) ||
      !_.isEqual(
        this.props.settings.config.pivotexporter,
        nextProps.settings.config.pivotexporter
      ) ||
      !_.isEqual(this.props.settings.config.pivot, nextProps.settings.config.pivot) ||
      !_.isEqual(this.props.pluginData, nextProps.pluginData)
    ) {
      this.setState({ forceReInit: Date.now() })
    }

    setTimeout(() => {
      if (this.pivotgrid && this.pivotgrid.cmp && _.size(nextProps.pluginData) > 0) {
        const {
          props: { settings: { config: { pivot: { footerButtons } = {} } = {} } = {} } = {}
        } = this
        if (_.size(footerButtons) > 0) {
          const pluginDataChanged = !_.isEqual(this.pluginData, nextProps.pluginData)
          if (pluginDataChanged) {
            this.setFooterButtons(nextProps.pluginData)
          }
        }
      }
    }, 0)

    //TODO: START: Related to childNodes null problem
    // If size of the grid changes do not render the grid
    let widthChanged = nextProps.size.width !== this.props.size.width
    widthChanged = this.props.size.width === 0 && widthChanged
    let heightChanged = nextProps.size.height !== this.props.size.height
    heightChanged = this.props.size.height === 0 && heightChanged

    // This is one-time operation
    if (!this.pivotgrid && (widthChanged || heightChanged)) {
      this.setState({ forceReInit: Date.now() }, () => {
        const pluginId = this.props.id
        this.props.reloadExtRoot(pluginId, () => this.observeResize(pluginId))
      })
    }
    // TODO: END
  }

  componentWillUnmount() {
    this.removeStyle(this.props.id)

    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }

    this.props.hideExtRoot(this.props.id)
  }

  observeResize(pluginId) {
    if (_.isEmpty(this.containerRef)) {
      return
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      const size = _.isEmpty(entries) ? getExtContainerSize(pluginId) : entries[0].contentRect
      this.setSize(size)
    })

    this.resizeObserver.observe(this.containerRef)
  }

  setSize({ width, height }) {
    // If size of the grid changes do not render the grid
    //TODO: START: Related to childNodes null problem
    if (this.pivotgrid && this.pivotgrid.cmp && width && height) {
      // TODO: END
      //if (this.pivotgrid && this.pivotgrid.cmp) {
      this.pivotgrid.cmp.setWidth(width)
      this.pivotgrid.cmp.setHeight(height)
    }
  }

  handleDataUpdated() {
    return { refreshKey: uuidv4() }
  }

  handleDataColumnChanged(value, initialData, previousValue) {
    if (
      previousValue &&
      value.aggregate &&
      value.aggregate.length === previousValue.aggregate.length
    ) {
      _.forEach(value.aggregate, (aggregate, index) => {
        const { aggregate: prevAggregates = [] } = previousValue
        const prevAggregate = prevAggregates[index]
        if (aggregate.dataIndex && prevAggregate.dataIndex !== aggregate.dataIndex) {
          if (aggregate.header === prevAggregate.dataIndex) {
            aggregate.header = aggregate.dataIndex
          }
        }
      })
    }

    if (
      previousValue &&
      value.leftAxis &&
      value.leftAxis.length === previousValue.leftAxis.length
    ) {
      _.forEach(value.leftAxis, (leftAxis, index) => {
        if (leftAxis.dataIndex && previousValue.leftAxis[index].dataIndex !== leftAxis.dataIndex) {
          leftAxis.header = leftAxis.dataIndex
        }
      })
    }

    if (previousValue && value.topAxis && value.topAxis.length === previousValue.topAxis.length) {
      _.forEach(value.topAxis, (topAxis, index) => {
        if (topAxis.dataIndex && previousValue.topAxis[index].dataIndex !== topAxis.dataIndex) {
          topAxis.header = topAxis.dataIndex
        }
      })
    }

    return value
  }

  handleExpandCollapse(target) {
    const settings = {
      collapse: {
        mask: 'Collapsing...',
        text: 'Expand',
        fn: 'collapse'
      },
      expand: {
        mask: 'Expanding...',
        text: 'Collapse',
        fn: 'expand'
      }
    }

    const current = this.expanded ? settings.collapse : settings.expand

    this.expanded = !this.expanded

    this.maskCmp(current.mask)

    target.setText(current.text)

    this.clearStateById(this.pivotgrid.cmp.stateId)

    this[current.fn]()

    this.unmaskCmp()
  }

  clearStateById(stateId) {
    Ext.state.Manager.clear(stateId)
  }

  getCmpId() {
    return this.pivotgrid.cmp.getId()
  }

  maskCmp(text) {
    Ext.getCmp(this.getCmpId()).getEl().mask(text)
  }

  unmaskCmp() {
    Ext.getCmp(this.getCmpId()).getEl().unmask()
  }

  expand() {
    this.pivotgrid.cmp.expandAll()
  }

  collapse() {
    this.pivotgrid.cmp.collapseAll()
  }

  exportAllToXlsx() {
    const {
      settings: {
        query: { formattedFields = [] },
        config: { general: { name = '' } = {} } = {}
      } = {}
    } = this.props

    // Add aggregate number formats for export
    // Normally we would add export formats in the configuration
    // But extjs loses this data, so we add it everytime we run export
    _.forEach(this.pivotgrid.cmp.matrix.aggregate.items, (aggregate) => {
      const formattedField = _.find(formattedFields, {
        columnName: aggregate.dataIndex
      })
      if (formattedField && formattedField.formatString) {
        aggregate.exportStyle = {
          format: this.converteExcelFormat(formattedField.formatString)
        }
      }
    })

    this.pivotgrid.cmp
      .saveDocumentAs({
        type: 'excel07',
        title: 'ExportAll',
        fileName: name + ' ExportAll.xlsx'
      })
      .then(null, this.onError)
  }

  converteExcelFormat(format) {
    // Excel number formats are quite different than dashboard number format
    // This function basically converts the most common used dashboard formats to Excel formats

    let excelFormat = format
    excelFormat = _.trim(excelFormat, ' ')
    excelFormat = _.trimStart(excelFormat, 'Number')
    excelFormat = _.trimStart(excelFormat, 'Percent')
    excelFormat = _.trim(excelFormat, ' ')
    excelFormat = _.trim(excelFormat, '$')
    excelFormat = _.trim(excelFormat, '€')
    excelFormat = _.trim(excelFormat, '₺')
    excelFormat = _.trim(excelFormat, '%')

    if (_.has(this.excelFormatConversion, excelFormat)) {
      excelFormat = this.excelFormatConversion[excelFormat]
    } else {
      excelFormat = '#,##0.00'
    }
    const containsPercentage = _.indexOf(format, '%') > -1 || _.indexOf(format, 'Percent') > -1
    if (containsPercentage) {
      excelFormat += '%'
    }

    return excelFormat
  }

  exportVisibleToXlsx() {
    const { settings: { config: { general: { name = '' } = {} } = {} } = {} } = this.props

    this.pivotgrid.cmp
      .saveDocumentAs({
        type: 'excel07',
        title: 'ExportVisible',
        fileName: name + ' ExportVisible.xlsx',
        onlyExpandedNodes: true
      })
      .then(null, this.onError)
  }

  onbookmarkclick(item, event) {
    const { bookmark = {}, bookmark: { id: bookmarkId } = {} } = item

    // Ignore readonly bookmarks
    if (hasClass(event.target, 'readonly')) {
      return
    } else if (hasClass(event.target, 'fa-remove')) {
      Ext.MessageBox.confirm('Delete', 'Are you sure to delete saved bookmark?', (btn) => {
        if (btn === 'yes') {
          this.props.deletePluginState('delete_' + this.props.id, this.props.id, bookmarkId)
        }
      })
      return
    } else if (hasClass(event.target, 'fa-pencil-square-o')) {
      this.onUpdateState(bookmark)
      return
    }

    const stateValid = this.isPluginStateValid(bookmark.config.state)

    if (stateValid) {
      _.forEach(bookmark.config.state.matrix.aggregate, (aggregate) => {
        // Assign the cell renderer
        aggregate.renderer = this.cellRenderer(aggregate)
        // TODO Added editor here bcs editor was removing after data updates.
        //aggregate.editor = 'numberfield'

        // Assign custom renderer
        const aggregateProps = _.find(this.props.settings.config.aggregate, {
          header: aggregate.header
        })

        if (aggregateProps && aggregateProps.customAggregator) {
          const customAggregator = _.find(this.customAggragators, {
            name: aggregateProps.customAggregator
          })
          if (customAggregator) {
            aggregate.aggregator = customAggregator.fn
            aggregate.customAggregator = customAggregator.name
          } else {
            const wantedAggregate = this.customAggsFunc(aggregateProps.customAggregator)
            if (wantedAggregate.enabled) {
              aggregate.aggregator = wantedAggregate.func
              aggregate.customAggregator = aggregateProps.customAggregator
            }
          }
        }
      })
      // showZeroAsBlank is always active
      // bookmark.config.state.matrix.showZeroAsBlank = false
      this.pivotgrid.cmp.applyState(bookmark.config.state)
    } else {
      // Remove invalid plugin state

      Ext.MessageBox.show({
        title: 'Invalid Bookmark',
        msg: 'The selected bookmark is no longer consistent with the data configuration of the pivot table. Please contact Solvoyo support for help.',
        buttons: Ext.MessageBox.OK,
        scope: this,
        icon: Ext.MessageBox.INFO
      })
    }
  }

  onSearchChanged(textField, newValue) {
    const stateMenu = textField.up('menu')
    _.forEach(stateMenu.items.items, (menuItem) => {
      if (_.has(menuItem, 'bookmark')) {
        const { bookmark: { name } = {} } = menuItem
        if (newValue !== '' && _.lowerCase(name).indexOf(_.lowerCase(newValue)) < 0) {
          menuItem.hide()
        } else {
          menuItem.show()
        }
      }
    })
  }

  onAddState() {
    const publicStateAuthorizationAllowed = this.props.isAllowed('PublicStateAuthorization')
    const exportStateAuthorizationAllowed = this.props.isAllowed('ExportStateAuthorization')

    const {
      settings: { config: { pivot: { publicState = false, exportState = false } = {} } = {} } = {}
    } = this.props

    const windowConfig = {
      title: 'Add Bookmark',
      width: 300,
      height: 130,
      layout: 'fit',
      modal: true,
      items: [
        {
          xtype: 'form',
          padding: 5,
          border: false,
          defaults: {
            anchor: '100%'
          },
          items: [
            {
              fieldLabel: 'Name',
              xtype: 'textfield',
              name: 'stateName',
              padding: '4px 4px 4px 4px',
              allowBlank: false
            },
            {
              xtype: 'container',
              layout: 'hbox',
              margin: '0 0 0 5',
              items: [
                {
                  xtype: 'radiogroup',
                  flex: 1,
                  simpleValue: true,
                  columns: 1,
                  value: 1,
                  name: 'visibility',
                  listeners: {
                    change: function (me, newValue) {
                      if (newValue === 2) {
                        me.up().items.items[1].enable()
                      } else {
                        me.up().items.items[1].disable()
                        me.up().items.items[1].setValue(false)
                      }
                    }
                  },
                  items: [
                    { fieldLabel: 'Private', inputValue: 1, hidden: true },
                    {
                      fieldLabel: 'Public',
                      inputValue: 2,
                      hidden: true
                    }
                  ]
                },
                {
                  xtype: 'checkbox',
                  fieldLabel: 'Exportable',
                  name: 'exportable',
                  margin: '32px 0px 0px 0px',
                  flex: 1,
                  disabled: true,
                  hidden: true
                }
              ]
            }
          ]
        }
      ],
      buttons: [
        {
          text: 'OK',
          handler: () => {
            const { stateName, visibility, exportable } = bookmarkPropsWin.down('form').getValues()
            if (_.size(stateName) > 0) {
              const { actualFilters } = this.props

              const stateFilters = _.transform(
                actualFilters,
                (result, filterValue, filterKey) => {
                  if (filterValue != null) {
                    result.push({
                      name: filterKey,
                      value: filterValue
                    })
                  }
                },
                []
              )
              this.saveState(stateName, visibility > 1, !!exportable, stateFilters)
              bookmarkPropsWin.close()
            }
          }
        },
        {
          text: 'Cancel',
          handler: () => {
            bookmarkPropsWin.close()
          }
        }
      ]
    }

    if (publicState && publicStateAuthorizationAllowed) {
      windowConfig.height = 200
      windowConfig.items[0].items[1].items[0].items[0].hidden = false
      windowConfig.items[0].items[1].items[0].items[1].hidden = false
    }

    if (publicState && exportState && exportStateAuthorizationAllowed) {
      windowConfig.items[0].items[1].items[1].hidden = false
    }

    const bookmarkPropsWin = new Ext.window.Window(windowConfig)

    bookmarkPropsWin.show()
  }

  onUpdateState(bookmark) {
    const publicStateAuthorizationAllowed = this.props.isAllowed('PublicStateAuthorization')
    const exportStateAuthorizationAllowed = this.props.isAllowed('ExportStateAuthorization')

    const {
      settings: { config: { pivot: { publicState = false, exportState = false } = {} } = {} } = {}
    } = this.props

    const { name, public: publicBookmark, exportable } = bookmark || {}
    const visibility = !publicBookmark ? 1 : 2

    const windowConfig = {
      title: 'Bookmark',
      width: 300,
      height: 130,
      layout: 'fit',
      modal: true,
      items: [
        {
          xtype: 'form',
          padding: 5,
          border: false,
          defaults: {
            anchor: '100%'
          },
          items: [
            {
              fieldLabel: 'Name',
              xtype: 'textfield',
              name: 'stateName',
              padding: '4px 4px 4px 4px',
              allowBlank: false,
              value: name
            },
            {
              xtype: 'container',
              layout: 'hbox',
              margin: '0 0 0 5',
              items: [
                {
                  xtype: 'radiogroup',
                  flex: 1,
                  simpleValue: true,
                  columns: 1,
                  name: 'visibility',
                  value: visibility,
                  listeners: {
                    change: function (me, newValue) {
                      if (newValue === 2) {
                        me.up().items.items[1].enable()
                      } else {
                        me.up().items.items[1].disable()
                        me.up().items.items[1].setValue(false)
                      }
                    }
                  },
                  items: [
                    { fieldLabel: 'Private', inputValue: 1, hidden: true },
                    {
                      fieldLabel: 'Public',
                      inputValue: 2,
                      hidden: true
                    }
                  ]
                },
                {
                  xtype: 'checkbox',
                  fieldLabel: 'Exportable',
                  name: 'exportable',
                  checked: !!exportable,
                  disabled: visibility === 1,
                  margin: '32px 0px 0px 0px',
                  flex: 1,
                  hidden: true
                }
              ]
            }
          ]
        }
      ],
      buttons: [
        {
          text: 'OK',
          handler: () => {
            const { stateName, visibility, exportable } = bookmarkPropsWin.down('form').getValues()
            if (_.size(stateName) > 0) {
              const { actualFilters } = this.props
              const stateFilters = _.transform(
                actualFilters,
                (result, filterValue, filterKey) => {
                  if (filterValue != null) {
                    result.push({
                      name: filterKey,
                      value: filterValue
                    })
                  }
                },
                []
              )
              this.updateState(bookmark, stateName, visibility > 1, !!exportable, stateFilters)
              bookmarkPropsWin.close()
            }
          }
        },
        {
          text: 'Cancel',
          handler: function () {
            bookmarkPropsWin.close()
          }
        }
      ]
    }

    if (publicState && publicStateAuthorizationAllowed) {
      windowConfig.height = 200
      windowConfig.items[0].items[1].items[0].items[0].hidden = false
      windowConfig.items[0].items[1].items[0].items[1].hidden = false
    }

    if (publicState && exportState && exportStateAuthorizationAllowed) {
      windowConfig.items[0].items[1].items[1].hidden = false
    }

    const bookmarkPropsWin = new Ext.window.Window(windowConfig)

    bookmarkPropsWin.show()
  }

  setFooterButtons(pluginData) {
    let dockedItems = this.pivotgrid && this.pivotgrid.cmp && this.pivotgrid.cmp.dockedItems.items
    let footerBar = _.find(dockedItems, (item) => {
      return item.xtype === 'toolbar' && item.dock === 'bottom' && item.ui === 'footer'
    })
    const footerBarItems = footerBar && footerBar.items && footerBar.items.items
    if (_.size(footerBarItems) <= 0) {
      return
    }
    const {
      props: { settings: { config: { pivot: { footerButtons = [] } = {} } = {} } = {} } = {}
    } = this
    _.forEach(footerButtons, (button) => {
      const { buttonText, footerButtonCondition = null } = button
      const footerButton = _.find(footerBarItems, (footerItem) => footerItem.text === buttonText)
      if (footerButton) {
        if (!_.isNil(footerButtonCondition)) {
          const record = this.getFirstDataRow(pluginData)
          if (record && !!record[footerButtonCondition] === false) {
            footerButton.disable()
          } else {
            footerButton.enable()
          }
        } else {
          footerButton.enable()
        }
      }
    })
  }

  saveState(name, isPublic, isExportable, actualFilters) {
    const pivotGrid = this.pivotgrid.cmp
    const { pluginStates: { data: states = [] } = {} } = this.props
    const pluginState = {
      catalogId: this.props.params.catalogId,
      exportable: isExportable,
      filters: actualFilters,
      name: name,
      pluginId: this.props.id,
      public: isPublic,
      config: {
        createDate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        state: pivotGrid.getState(),
        stateId: pivotGrid.stateId
      }
    }

    if (_.find(states, (state) => state.name === name)) {
      slvyToast.error({ message: 'bookmark with this name already exist!', title: 'Unsuccessful' })
    } else {
      this.props
        .createPluginState('create_' + this.props.id, this.props.id, pluginState)
        .then(() =>
          slvyToast.success({ message: 'bookmark has been created successfully', title: 'Success' })
        )
        .catch(() => slvyToast.error({ message: 'Cannot create bookmark', title: 'Unsuccessful' }))
    }
  }

  updateState(pluginState, name, isPublic, isExportable, actualFilters) {
    const pivotGrid = this.pivotgrid.cmp
    const { pluginStates: { data: states = [] } = {} } = this.props
    const editedPluginState = {
      ...pluginState,
      exportable: isExportable,
      filters: actualFilters,
      name,
      public: isPublic,
      config: {
        ...pluginState.config,
        state: pivotGrid.getState()
      }
    }

    if (
      _.find(states, (state) => {
        return state.name === name && state.id !== pluginState.id
      })
    ) {
      slvyToast.error({ message: 'Bookmark with this name already exist!', title: 'Unsuccessful' })
    } else {
      this.props
        .updatePluginState('update_' + this.props.id, pluginState.id, editedPluginState)
        .then(() =>
          slvyToast.success({ message: 'Bookmark has been updated successfully', title: 'Success' })
        )
        .catch(() => slvyToast.error({ message: 'Cannot update bookmark', title: 'Unsuccessful' }))
    }
  }

  findTooltip(aggregate, leftAxis, topAxis, dataIndex) {
    const allConfigs = [].concat(aggregate, leftAxis, topAxis)

    const result = [...allConfigs]
      .reverse()
      .find((element) => element.dataIndex && element.dataIndex === dataIndex && element.tooltip)

    return _.get(result, 'tooltip', null)
  }

  handleAfterLayout(pivot) {
    // Set tooltips of columns
    const columns = (pivot && pivot.getColumns()) || []
    const {
      config: {
        aggregate: configAggregate = [],
        leftAxis: configLeftAxis = [],
        topAxis: configTopAxis = [],
        pivot: { autoTooltip: autoTooltip = null } = {}
      } = {}
    } = this.props.settings

    _.forEach(columns, (column) => {
      const { el = {} } = column || {}

      const element = el.getFirstChild ? el.getFirstChild() : {}

      const { dom: columnDOM = {} } = element || {}

      const {
        config: { dimension: { dataIndex: columnDataIndex = '', header: columnHeader } = {} } = {}
      } = column || {}

      const tooltip = this.findTooltip(
        configAggregate,
        configLeftAxis,
        configTopAxis,
        columnDataIndex
      )

      if (columnDOM.setAttribute) {
        if (tooltip) {
          columnDOM.setAttribute('data-qtip', tooltip)
        } else if (autoTooltip === true) {
          columnDOM.setAttribute('data-qtip', columnHeader)
        }
      }
    })
  }

  formatTopAndLeftAxisItems(pivotData, leftAxis, topAxis, aggregate) {
    const { getFormattedValue } = this.props
    if ((leftAxis || topAxis) && !aggregate) {
      const filteredFormattedFields = _.filter(
        this.props.settings.query.formattedFields,
        (field) => {
          return field.formatString
        }
      )

      let formattedLeftAxisItems = _.map(
        _.filter(leftAxis, (leftAxisItem) => {
          let findedIndex = _.findIndex(
            filteredFormattedFields,
            (filteredFormattedField) => leftAxisItem.dataIndex === filteredFormattedField.columnName
          )

          return findedIndex > -1
        }),
        'dataIndex'
      )

      formattedLeftAxisItems = _.reduce(
        formattedLeftAxisItems,
        (res, item) => {
          let findedIndex = _.find(aggregate, (i) => i.dataIndex === item)
          if (_.isEmpty(findedIndex)) {
            res.push(item)
          }
          return res
        },
        []
      )

      let formattedTopAxisItems = _.map(
        _.filter(topAxis, (topAxisItem) => {
          let findedIndex = _.findIndex(
            filteredFormattedFields,
            (filteredFormattedField) => topAxisItem.dataIndex === filteredFormattedField.columnName
          )

          return findedIndex > -1
        }),
        'dataIndex'
      )

      formattedTopAxisItems = _.reduce(
        formattedTopAxisItems,
        (res, item) => {
          let findedIndex = _.find(aggregate, (i) => i.dataIndex === item)
          if (_.isEmpty(findedIndex)) {
            res.push(item)
          }
          return res
        },
        []
      )

      _.forEach(formattedLeftAxisItems, (item) => {
        _.forEach(pivotData, (data) => {
          data[item] = getFormattedValue(item, data[item])
        })
      })

      _.forEach(formattedTopAxisItems, (item) => {
        _.forEach(pivotData, (data) => {
          data[item] = getFormattedValue(item, data[item])
        })
      })
    }

    return pivotData
  }

  setDataToStore(nextData, keys, updatedRows) {
    const { items: prevData = [] } = this.store.getData() || {}

    let filteredNextData = nextData
    if (_.size(updatedRows) > 0) {
      filteredNextData = _.filter(nextData, (nextDataRow) => {
        return _.reduce(
          updatedRows,
          (rowMatch, updatedRow) => {
            return (
              rowMatch ||
              _.reduce(
                keys,
                function (keyMatch, key) {
                  const { fieldName } = key
                  return keyMatch && nextDataRow[fieldName] === updatedRow[fieldName]
                },
                true
              )
            )
          },
          false
        )
      })
    }
    // Update rows
    _.forEach(filteredNextData, (nextDataRow) => {
      // Find matching row
      const matchingPrevDataRow = _.find(prevData, (prevDataRow) => {
        return _.reduce(
          keys,
          function (keyMatch, key) {
            const { fieldName } = key
            return keyMatch && prevDataRow.get(fieldName) === nextDataRow[fieldName]
          },
          true
        )
      })

      if (matchingPrevDataRow) {
        // Set row
        _.forEach(nextDataRow, (value, fieldName) => {
          if (nextDataRow[fieldName] !== matchingPrevDataRow.get(fieldName)) {
            matchingPrevDataRow.set(fieldName, value, {
              commit: true
            })
          }
        })
      }
    })

    if (_.size(updatedRows) === 0) {
      // Remove rows
      const rowsToRemove = []
      _.forEach(prevData, (prevDataRow) => {
        const matchingNextDataRow = _.find(nextData, (nextDataRow) => {
          return _.reduce(
            keys,
            function (keyMatch, key) {
              const { fieldName } = key
              return keyMatch && prevDataRow.get(fieldName) === nextDataRow[fieldName]
            },
            true
          )
        })
        if (!matchingNextDataRow) {
          rowsToRemove.push(prevDataRow)
        }
      })
      if (_.size(rowsToRemove) > 0) {
        this.store.remove(rowsToRemove)
      }

      // Add rows
      _.forEach(nextData, (nextDataRow) => {
        // Find matching row
        const matchingPrevDataRow = _.find(prevData, (prevDataRow) => {
          return _.reduce(
            keys,
            function (keyMatch, key) {
              const { fieldName } = key
              return keyMatch && prevDataRow.get(fieldName) === nextDataRow[fieldName]
            },
            true
          )
        })

        if (!matchingPrevDataRow) {
          // Set row
          this.store.add(nextDataRow)
        }
      })
    }
  }

  applyStateToPivot(state) {
    const pivotGrid = this.pivotgrid.cmp
    _.forEach(state.matrix.aggregate, (aggregate) => {
      // Assign the cell renderer
      aggregate.renderer = this.cellRenderer(aggregate)
      // TODO Added editor here bcs editor was removing after data updates.
      //aggregate.editor = 'numberfield'

      // Assign custom renderer
      var aggregateProps = _.find(this.props.settings.config.aggregate, {
        header: aggregate.header
      })

      if (aggregateProps.customAggregator) {
        const customAggregator = _.find(this.customAggragators, {
          name: aggregateProps.customAggregator
        })
        if (customAggregator) {
          aggregate.aggregator = customAggregator.fn
          aggregate.customAggregator = customAggregator.name
        } else {
          const wantedAggregate = this.customAggsFunc(aggregateProps.customAggregator)
          if (wantedAggregate.enabled) {
            aggregate.aggregator = wantedAggregate.func
            aggregate.customAggregator = aggregateProps.customAggregator
          }
        }
      }
    })

    pivotGrid.applyState(state)
  }

  beforestatesave(pivot, state) {
    // Add config Hash to state. We will ignore the plugin state if config has changed
    const { config: pivotConfig = {} } = this.props.settings
    state.configHash = hash(pivotConfig)

    _.forEach(state.matrix.aggregate, (aggregate) => {
      // Assign the cell renderer
      aggregate.renderer = this.cellRenderer(aggregate)
      // TODO Added editor here bcs editor was removing after data updates.
      //aggregate.editor = 'numberfield'

      // Assign custom renderer
      const aggregateProps = _.find(this.props.settings.config.aggregate, {
        header: aggregate.header
      })

      if (aggregateProps && aggregateProps.customAggregator) {
        const customAggregator = _.find(this.customAggragators, {
          name: aggregateProps.customAggregator
        })
        if (customAggregator) {
          aggregate.aggregator = customAggregator.fn
          aggregate.customAggregator = customAggregator.name
        } else {
          const wantedAggregate = this.customAggsFunc(aggregateProps.customAggregator)
          if (wantedAggregate.enabled) {
            aggregate.aggregator = wantedAggregate.func
            aggregate.customAggregator = aggregateProps.customAggregator
          }
        }
      }
    })

    // change the text of the expand/collapse button
    const { expandedItems: { cols = [], rows = [] } = {} } = state
    const expandButton = this.pivotgrid.cmp.lookupReference('ExpandButton')
    if (expandButton) {
      if (_.size(cols) > 0 || _.size(rows) > 0) {
        this.expanded = true
        expandButton.setText('Collapse')
      } else {
        this.expanded = false
        expandButton.setText('Expand')
      }
    }
  }

  isPluginStateValid(pluginState) {
    // Check if the data indexes in the plugin state exist in plugin configuration.
    // If not it is not possible to use the plugin state.
    const {
      config: {
        aggregate: configAggregate = [],
        leftAxis: configLeftAxis = [],
        topAxis: configTopAxis = []
      } = {}
    } = this.props.settings

    const {
      matrix: {
        aggregate: stateAggregate = [],
        leftAxis: stateLeftAxis = [],
        topAxis: stateTopAxis = []
      } = {}
    } = pluginState || {}

    const missingDimensions = []

    // Aggregates
    _.forEach(stateAggregate, (stateAggregateItem) => {
      const configAggregateItem = _.find(configAggregate, (configAggregateItem) => {
        return configAggregateItem.dataIndex === stateAggregateItem.dataIndex
      })

      if (!configAggregateItem) {
        missingDimensions.push({
          aggregator: stateAggregateItem.aggregator,
          dataIndex: stateAggregateItem.dataIndex,
          location: 'Aggregation'
        })
      }
    })

    // Left Axis
    _.forEach(stateLeftAxis, (stateLeftAxisItem) => {
      const configLeftAxisItem = _.find(configLeftAxis, (configLeftAxisItem) => {
        return configLeftAxisItem.dataIndex === stateLeftAxisItem.dataIndex
      })

      if (!configLeftAxisItem) {
        missingDimensions.push({
          aggregator: stateLeftAxisItem.aggregator,
          dataIndex: stateLeftAxisItem.dataIndex,
          location: 'Left Axis'
        })
      }
    })

    // Top Axis
    _.forEach(stateTopAxis, (stateTopAxisItem) => {
      const configTopAxisItem = _.find(configTopAxis, (configTopAxisItem) => {
        return configTopAxisItem.dataIndex === stateTopAxisItem.dataIndex
      })

      if (!configTopAxisItem) {
        missingDimensions.push({
          aggregator: stateTopAxisItem.aggregator,
          dataIndex: stateTopAxisItem.dataIndex,
          location: 'Top Axis'
        })
      }
    })

    if (_.size(missingDimensions) > 0) {
      console.warn('Warning: Missing dimensions in pivot state', missingDimensions)
    }

    return _.size(missingDimensions) === 0
  }

  removeFiltersFromPluginState(pluginState) {
    const {
      matrix: {
        aggregate: stateAggregate = [],
        leftAxis: stateLeftAxis = [],
        topAxis: stateTopAxis = []
      } = {}
    } = pluginState || {}

    // Aggregates
    _.forEach(stateAggregate, (stateAggregateItem) => {
      delete stateAggregateItem.filter
    })

    // Left Axis
    _.forEach(stateLeftAxis, (stateLeftAxisItem) => {
      delete stateLeftAxisItem.filter
    })

    // Top Axis
    _.forEach(stateTopAxis, () => {
      delete stateTopAxis.filter
    })
  }

  beforestaterestore(pivot, state) {
    // showZeroAsBlank is always active
    // state.matrix.showZeroAsBlank = false
    const stateValid = this.isPluginStateValid(state)

    // Ignore the plugin state, if the plugin config has changed.
    const { config: pivotConfig = {} } = this.props.settings
    const { configHash: existingConfigHash } = state || {}

    const stateActual = !existingConfigHash || hash(pivotConfig) === existingConfigHash

    if (stateValid && stateActual) {
      this.removeFiltersFromPluginState(state)
      // functions cannot be stored in the state, so set aggregator and renderer functions
      _.forEach(state.matrix.aggregate, (aggregate) => {
        // Assign the cell renderer
        aggregate.renderer = this.cellRenderer(aggregate)
        aggregate.editor = 'numberfield'

        // Assign custom renderer
        const aggregateProps = _.find(this.props.settings.config.aggregate, {
          header: aggregate.header
        })

        if (aggregateProps && aggregateProps.customAggregator) {
          const customAggregator = _.find(this.customAggragators, {
            name: aggregateProps.customAggregator
          })
          if (customAggregator) {
            aggregate.aggregator = customAggregator.fn
            aggregate.customAggregator = customAggregator.name
          } else {
            const wantedAggregate = this.customAggsFunc(aggregateProps.customAggregator)
            if (wantedAggregate.enabled) {
              aggregate.aggregator = wantedAggregate.func
              aggregate.customAggregator = aggregateProps.customAggregator
            }
          }
        }
      })
    }

    return stateValid && stateActual
  }

  beforeconfigchange(panel, config) {
    _.forEach(config.aggregate, (aggregate) => {
      // Assign the cell renderer
      aggregate.renderer = this.cellRenderer(aggregate)
      // TODO Added editor here bcs editor was removing after data updates.
      //aggregate.editor = 'numberfield'

      // Assign custom renderer
      var aggregateProps = _.find(this.props.settings.config.aggregate, {
        header: aggregate.header
      })

      if (aggregateProps && aggregateProps.customAggregator) {
        const customAggregator = _.find(this.customAggragators, {
          name: aggregateProps.customAggregator
        })
        if (customAggregator) {
          aggregate.aggregator = customAggregator.fn
          aggregate.customAggregator = customAggregator.name
        } else {
          const wantedAggregate = this.customAggsFunc(aggregateProps.customAggregator)
          if (wantedAggregate.enabled) {
            aggregate.aggregator = wantedAggregate.func
            aggregate.customAggregator = aggregateProps.customAggregator
          }
        }
      }
    })
  }

  handleRowSelected(pivot, record) {
    const leftKeys = this.getLeftKeys(this.pivotgrid.cmp, record)
    const { query: { fields = [] } = {} } = this.props.settings
    _.forEach(fields, (field) => {
      if (!_.has(leftKeys, field.fieldName)) {
        leftKeys[field.fieldName] = null
      }
    })
    return leftKeys
  }

  handleCellDoubleClick(pivot, td, cellIndex, record, tr, rowIndex, event) {
    const keys = {
      ...this.getLeftKeys(this.pivotgrid.cmp, record),
      ...this.getTopKeys(this.pivotgrid.cmp, event.position.column)
    }

    const { query: { fields = [] } = {} } = this.props.settings

    _.forEach(fields, (field) => {
      if (!_.has(keys, field.fieldName)) {
        keys[field.fieldName] = null
      }
    })

    return keys
  }

  handleCellClick(pivot, td, cellIndex, record) {
    const { grid: { columnManager: { columns = [] } = {} } = {} } = pivot || {}
    const column = columns[cellIndex] || {}
    const { dataIndex: pivotColumnName, dimension: { dataIndex: fieldName = '' } = {} } = column

    // Get raw data rows
    const relatedRows = this.getRelatedRows(record, column)

    const { settings: { config: { aggregate: aggregates = [] } = {} } = {} } = this.props

    const aggregateConfig = _.find(aggregates, { dataIndex: fieldName })

    const {
      editing: { enabled: editingEnabled = false, editableCondition = '', checkboxField = '' } = {}
    } = aggregateConfig || {}

    // This cell is editable if the aggregate is editable and editing condition matches
    let cellEditable = editingEnabled
    if (editingEnabled && editableCondition) {
      // Get the max aggregation of the condition field
      const maxEditableConditionValue = _.reduce(
        relatedRows,
        (max, row) => {
          return row.data[editableCondition] > max ? row.data[editableCondition] : max
        },
        0
      )
      cellEditable = maxEditableConditionValue > 0
    }

    if (cellEditable) {
      // If this cell is to be configured as checkbox cell
      // A single click changes the state of the checkbox
      const checkBoxConditionValue = _.reduce(
        relatedRows,
        (sum, row) => {
          return sum + row.data[checkboxField]
        },
        0
      )

      if (checkBoxConditionValue) {
        const value = record.get(pivotColumnName)
        // Set value to all underlying records
        _.forEach(relatedRows, (relatedRow) => {
          relatedRow.set(fieldName, value ? 0 : 1)
        })
      }
    }
  }

  handlePivotDone() {
    if (this.pivotgrid) {
      const pivotConfigurator = _.find(this.pivotgrid.cmp.plugins, {
        ptype: 'pivotconfigurator'
      })
      if (pivotConfigurator && !pivotConfigurator.configPanel.collapsed && !this.rendered) {
        this.rendered = true
        pivotConfigurator.configPanel.collapse()
      }
    }
  }

  cellRenderer(aggregate) {
    const {
      settings: { config: { aggregate: aggregates = [], cellDisplayRules = [] } = {} } = {},
      getFormattedValue
    } = this.props
    return (value, metaData, record) => {
      if (value === null) {
        return value
      }
      const {
        column: { config: { dimension: { dataIndex = '', aggregator = '' } = {} } = {} } = {}
      } = metaData || {}

      let formattedValue = getFormattedValue(dataIndex, value)

      // Display check box if this is a checkbox cell
      const aggregateConfig = _.find(aggregates, { dataIndex: dataIndex })

      const {
        formatField = '',
        displayZeroAsBlank = false,
        editing: {
          enabled: editingEnabled = false,
          checkboxField = '',
          checkboxFieldIconOn = '',
          checkboxFieldIconOff = ''
        } = {}
      } = aggregateConfig || {}

      var relatedRows = null

      if (editingEnabled && record.dirty) {
        relatedRows = this.getRelatedRows(record, metaData.column)
        const dirty = !_.isNil(
          _.find(relatedRows, (relatedRow) => {
            return _.has(relatedRow.modified, dataIndex)
          })
        )

        if (dirty) {
          metaData.tdCls = metaData.tdCls + ' x-grid-dirty-cell-solvoyo'
        }
      }

      if (formatField) {
        // Get raw data rows
        if (!relatedRows) {
          relatedRows = this.getRelatedRows(record, metaData.column)

          if (_.size(relatedRows) > 0) {
            const format = relatedRows[0].get(formatField)
            formattedValue = getFormatedValue(format, value)
          }
        }
      }

      if (checkboxField) {
        // Get raw data rows
        if (!relatedRows) {
          relatedRows = this.getRelatedRows(record, metaData.column)
        }

        const checkBoxConditionValue = _.reduce(
          relatedRows,
          (sum, row) => {
            return sum + row.data[checkboxField]
          },
          0
        )

        if (checkBoxConditionValue && checkboxFieldIconOn && checkboxFieldIconOff) {
          const checkBoxIcon = value ? checkboxFieldIconOn : checkboxFieldIconOff
          formattedValue =
            '<i class="booleanColumn fa ' + checkBoxIcon + '" aria-hidden="true"></i> '
          metaData.align = 'center'
        }
      }

      // Apply rules
      const matcingRules = _.filter(cellDisplayRules, (rule) => {
        if (rule.ruleFieldName && rule.ruleFieldName === dataIndex) {
          if (rule.aggregator) {
            if (rule.aggregator === aggregator) {
              return true
            }
          } else {
            return true
          }
        } else if (
          aggregate.customAggregator &&
          aggregate.customAggregator === rule.customAggregator
        ) {
          return true
        }
      })

      if (matcingRules.length > 0) {
        if (!relatedRows) {
          relatedRows = this.getRelatedRows(record, metaData.column)
        }
        _.forEach(matcingRules, (rule) => {
          let ruleValue = value
          if (rule.referenceFieldName && rule.ruleFieldName !== rule.referenceFieldName) {
            // Get the sum aggregation of the condition field
            // ToDo support other aggregations
            ruleValue = _.reduce(
              relatedRows,
              (sum, row) => {
                return sum + row.data[rule.referenceFieldName]
              },
              0
            )
          } else if (rule.referenceCustomAggregator) {
            // Run the reference custom aggregation function to get the rule value
            let referenceCustomAggregator = _.find(this.customAggragators, {
              name: rule.referenceCustomAggregator
            })
            if (!referenceCustomAggregator) {
              referenceCustomAggregator = this.customAggsFunc(rule.referenceCustomAggregator)
              referenceCustomAggregator.fn = referenceCustomAggregator.func
            }
            ruleValue = referenceCustomAggregator.fn(relatedRows)
          }
          const result = this.executeRule(ruleValue, rule)
          if (result.isValid) {
            metaData.style = result.cssClass

            if (result.icon) {
              if (result.displayOnlyIcon) {
                formattedValue = '<i class="fa ' + result.icon + '" aria-hidden="true"></i> '
              } else {
                if (result.iconPosition === 'left') {
                  formattedValue =
                    '<i class="fa ' + result.icon + '" aria-hidden="true"></i> ' + formattedValue
                } else {
                  formattedValue =
                    formattedValue + ' <i class="fa ' + result.icon + '" aria-hidden="true"></i>'
                }
              }
            }
            return false
          }
        })
      }
      // In tabular view left axis cells are also rendered
      // Display undefined values as empty
      if (formattedValue === undefined) {
        formattedValue = ''
      }

      // cellRenderer breaks displayZeroAsBlank so we add below lines.
      if (displayZeroAsBlank && Number(formattedValue) === 0 && getControlValue(value) === 0) {
        return null
      }

      return formattedValue
    }
  }

  executeRule(value, condition) {
    const ret = {
      isValid: false,
      cssClass: null
    }

    if (condition) {
      ret.isValid = this.checkCondition(condition.value, value, condition.operator)

      if (ret.isValid) {
        ret.cssClass = this.getClassName(condition.backColor, condition.textColor)
        ret.icon = condition.icon
        ret.displayOnlyIcon = condition.displayOnlyIcon
        ret.iconPosition = condition.iconPosition
      }
    }

    return ret
  }

  checkCondition = function (expectedValue, value, operator) {
    var isValid = false

    switch (operator) {
      case 'equal':
        if (
          !_.isNil(value) &&
          expectedValue.toString().toUpperCase() === value.toString().toUpperCase()
        ) {
          isValid = true
        }
        break
      case 'not equal':
        if (
          !_.isNil(value) &&
          expectedValue.toString().toUpperCase() !== value.toString().toUpperCase()
        ) {
          isValid = true
        }
        break
      case 'larger':
        if (!_.isNil(value) && parseFloat(expectedValue) < parseFloat(value)) {
          isValid = true
        }
        break
      case 'smaller':
        if (!_.isNil(value) && parseFloat(expectedValue) > parseFloat(value)) {
          isValid = true
        }
        break
      default:
        isValid = false
        break
    }

    return isValid
  }

  getClassName = function (background, color) {
    let rules = ''

    if (background) {
      rules += 'background-color:' + background + '!important;'
    }

    if (color) {
      rules += 'color:' + color + '!important;'
    }

    return rules
  }
  handleStoreUpdate(store, record, operation, modifiedFieldNames) {
    // We only care for editing changes
    if (operation !== 'edit') {
      return
    }

    const {
      settings: {
        config: { pivot: { editing: { editingType = '' } = {} } = {} } = {},
        query: { dataEditing: { isUpdateQuery } = {} } = {}
      } = {}
    } = this.props
    const delaySaving = editingType === 'SaveButton' || editingType === 'Trigger'
    const queueSaving = editingType === 'Queue'

    if (modifiedFieldNames && modifiedFieldNames.length > 0) {
      _.forEach(modifiedFieldNames, (modifiedFieldName) => {
        const columnValues = _.cloneDeep(record.data)
        const oldValue = record.modified[modifiedFieldName]
        if (!delaySaving) {
          if (!isUpdateQuery) {
            this.editList.push({
              changedColumn: modifiedFieldName,
              columnValues: columnValues,
              oldValue: oldValue
            })

            if (_.has(columnValues, __RowIndex)) {
              const rowIndex = columnValues[__RowIndex]

              if (record) {
                if (!queueSaving) {
                  this.props.updateRowInLocalData(rowIndex, columnValues)
                }
                record.commit()
              }
            }
          }
        }
      })
    }
  }

  handleMatrixAfterUpdate() {
    const {
      settings: {
        config: {
          pivot: { editing: { editingType = '', lockGridDisaggregation = true } = {} } = {}
        } = {},
        query: { dataEditing: { isUpdateQuery } = {} } = {}
      } = {}
    } = this.props
    const delaySaving = editingType === 'SaveButton' || editingType === 'Trigger'
    const queueSaving = editingType === 'Queue'
    let updateData
    const modifiedRecords = this.getModifiedDirtyRecords()
    if (delaySaving && lockGridDisaggregation) {
      // Display progress while an update is being executed in the pivot
      this.unmaskCmp()
    }
    if (!delaySaving) {
      if (isUpdateQuery) {
        if (modifiedRecords.length > 0) {
          updateData = {
            records: modifiedRecords,
            type: 0
          }
          this.updateValue({ updateData, reloadData: true })
        }
      } else {
        updateData = this.prepareUpdateData(this.editList)
        this.updateValue({
          updateData,
          reloadData: false,
          updateParameters: {},
          queueRequests: queueSaving
        })
      }
      this.editList = []
    }
  }

  handleMatrixProcessingDone() {
    // Matrix processing finished
    this.matrixProcessingState = 0
  }

  handleMatrixProcessingStart() {
    // Matrix processing started
    this.matrixProcessingState = 1
  }

  getTextSelectionEnabled(pivotConfig) {
    // Default value of the textSelection is true
    // enableTextSelection is added to grid afterwards so consider also existing grids in projects
    // which do not have this property
    const { pivot: { selection: { textSelection = true } = {} } = {} } = pivotConfig || {}
    return textSelection
  }

  getSelectionModel(pivotConfig) {
    // Selection model
    const { pivot: { selection: { type: selectionType = '' } = {} } = {} } = pivotConfig || {}

    let selectionModel = false
    if (selectionType === 'Spreadsheet') {
      selectionModel = {
        columnSelect: false,
        extensible: 'y',
        pruneRemoved: false,
        type: 'spreadsheet'
      }
    }

    return selectionModel
  }

  forceReloadAfterUpdate(updateKey) {
    const { settings: { config: { general: { remoteMatrix = false } = {} } = {} } = {} } =
      this.props
    // TODO
    this.props.clearCaches(true)
    if (remoteMatrix) {
      const args = this.state.args
      this.handleRemoteArguments(args)
    } else {
      // Force data fetching
      this.props.setDataArguments(null, true, true, updateKey)
    }
  }

  enqueueReloadAfterUpdateRequest(updateKey) {
    if (_.size(this.reloadAfterUpdateQueue) === 0) {
      this.reloadAfterUpdateQueue.push(updateKey)
    }
  }

  processReloadAfterUpdateRequest() {
    if (!this.reloadAfterUpdateInProgress) {
      if (_.size(this.reloadAfterUpdateQueue) > 0) {
        const reloadRequest = this.reloadAfterUpdateQueue.shift()
        this.reloadAfterUpdateQueue = []
        this.reloadAfterUpdateInProgress = true
        this.forceReloadAfterUpdate(reloadRequest)
      }
    }
  }

  processUpdateQueue(pluginId) {
    if (!this.updateInProgress) {
      const nextRequest = this.props.dequeueUpdateRequest(pluginId)
      if (nextRequest) {
        this.executedUpdates[nextRequest.updateKey] = nextRequest
        this.updateInProgress = true
        return nextRequest
      }
    }
  }

  sendWarningMessage(data) {
    if (data && 'result' in data) {
      const { result = [] } = data
      const errFiltered = _.filter(result, { isSuccess: false })
      if (errFiltered.length > 0) {
        const errGrouped = _.groupBy(errFiltered, 'errorCode')
        const errMessages = _.transform(
          errGrouped,
          (res, item, key) => {
            if (key === '') {
              res.push(`An error occurred (${item.length})`)
            } else {
              res.push(`${key} (${item.length})`)
            }
            return res
          },
          []
        ).join('<br>')

        slvyToast.warning({ message: errMessages })
      }
    }
  }

  updateRemoteValue(queueRequests, editList, lockGridDisaggregation) {
    const { props: { id: pluginId } = {} } = this
    const me = this.pivotgrid.cmp.matrix
    const matrix = me.serialize()

    const remoteData = {
      leftAxis: _.map(matrix.leftAxis, (item) => item.dataIndex),
      topAxis: _.map(matrix.topAxis, (item) => item.dataIndex),
      keySeperator: me.keysSeparator,
      grandTotalKey: me.grandTotalKey,
      updateDetails: editList
    }

    const updateRequest = this.updateClientData({
      updateType: 'remote',
      queueRequests,
      clientData: remoteData,
      reloadData: null,
      lockGrid: lockGridDisaggregation
    })

    if (queueRequests) {
      updateRequest.updateKey = uuidv4()
      this.props.enqueueUpdateRequest(pluginId, updateRequest)
      this.processUpdateQueue(pluginId)
    } else {
      return updateRequest
    }
  }

  updateValue({ updateData, reloadData = false, updateParameters = {}, queueRequests = false }) {
    const {
      id: pluginId,
      actualFilters = {},
      additionalArgs: { _CONNECTIONID = null } = {},
      settings: { config: { pivot: { editing: { lockGrid = true } = {} } = {} } = {} } = {}
    } = this.props
    const clientData = {
      ...updateData,
      filters: { ...actualFilters, ...updateParameters, ...(_CONNECTIONID && { _CONNECTIONID }) }
    }
    const updateRequest = this.updateClientData({
      queueRequests,
      clientData,
      reloadData,
      lockGrid
    })

    if (queueRequests) {
      updateRequest.updateKey = uuidv4()
      this.props.enqueueUpdateRequest(pluginId, updateRequest)
      this.processUpdateQueue(pluginId)
    } else {
      return updateRequest
    }
  }

  updateClientData({ updateType, queueRequests, clientData, reloadData, lockGrid }) {
    const {
      props: {
        settings: { query: { dataEditing: { isUpdateQuery } = {} } = {} } = {},
        id: pluginId,
        client
      } = {}
    } = this
    const postData = { data: clientData }
    let updateRequest
    let clientUrl

    if (updateType === 'remote') {
      clientUrl = `/data/UpdateRemotePivotData/${pluginId}`
      updateRequest = client
        .post(clientUrl, postData)
        .then((response) => {
          this.handleDataUpdated()
          if (response === true) {
            slvyToast.success({
              message: 'Your changes are applied successfully.',
              title: 'Success'
            })
          }
          const args = this.state.args
          this.handleRemoteArguments(args)
        })
        .finally(() => this.handleUpdateFinally(queueRequests, lockGrid, updateRequest, pluginId))
        .catch(this.handleUpdateCatch)

      return updateRequest
    } else if (isUpdateQuery) {
      clientUrl = '/data/plugin/' + pluginId + '/edit'
      updateRequest = client
        .post(clientUrl, postData)
        .then((res) => {
          this.sendWarningMessage(res)

          if (reloadData) {
            // Store the current state of the pivot. It should be restored after updating.
            this.pivotStateBeforeUpdate = this.pivotgrid.cmp.getState()
            this.editList = []
            // Force data fetching
            this.props.clearCaches()
            this.props.setDataArguments(null, true)
          }
          this.handleDataUpdated()
        })
        .finally(() => this.handleUpdateFinally(queueRequests, lockGrid, updateRequest, pluginId))
        .catch(this.handleUpdateCatch)

      return updateRequest
    } else {
      clientUrl = `/data/plugin/${pluginId}/update`

      updateRequest = client
        .post(clientUrl, postData)
        .then((response) => {
          this.sendWarningMessage(response)

          if (reloadData) {
            // Store the current state of the pivot. It should be restored after updating.
            this.pivotStateBeforeUpdate = this.pivotgrid.cmp.getState()
            this.editList = []
            // Force data fetching
            this.props.clearCaches()
            this.props.setDataArguments(null, true)
          }
          this.handleDataUpdated()
        })
        .finally(() => this.handleUpdateFinally(queueRequests, lockGrid, updateRequest, pluginId))
        .catch((error) => this.handleUpdateCatch(error))

      return updateRequest
    }
  }

  handleUpdateFinally(queueRequests, lockGrid, updateRequest, pluginId) {
    if (lockGrid) {
      this.unmaskCmp()
    }

    if (queueRequests) {
      this.enqueueReloadAfterUpdateRequest(updateRequest.updateKey)
      this.processReloadAfterUpdateRequest()

      this.updateInProgress = false
      this.processUpdateQueue(pluginId)
    }
  }

  handleUpdateCatch(error) {
    slvyToast.warning({ message: error.message, title: error.errorType })
  }

  handleResetChanges() {
    _.forEach(this.store.getModifiedRecords(), (record) => {
      if (record.dirty) {
        record.reject()
      }
    })
  }

  handleTriggerSaveChanges() {
    this.handleSaveChanges()
  }

  handleSaveChanges(updateParameters) {
    const {
      settings: {
        config: { general: { remoteMatrix = false } = {} } = {},
        query: { dataEditing: { isUpdateQuery } = {} } = {}
      } = {}
    } = this.props

    let updateData
    const modifiedRecords = this.getModifiedDirtyRecords()

    if (_.size(this.editList) > 0 || modifiedRecords.length > 0) {
      const list = _.cloneDeep(this.editList)
      if (remoteMatrix) {
        // TODO
        this.updateRemoteValue(false, list)
      } else if (isUpdateQuery) {
        if (modifiedRecords.length > 0) {
          updateData = {
            records: modifiedRecords,
            type: 0
          }
          this.updateValue({ updateData, reloadData: true, updateParameters })
        }
      } else {
        updateData = this.prepareUpdateData(list)
        this.updateValue({ updateData, reloadData: true, updateParameters })
      }
      this.editList = []
    }
  }

  getFieldConfigs() {
    const {
      data: { schema, schema: fieldConfigsSchema = [] } = {},
      settings: { query: { fields: fieldConfigsQuery = [] } = {} } = {}
    } = this.props

    if (schema) {
      return fieldConfigsSchema
    }
    return fieldConfigsQuery
  }

  getModifiedDirtyRecords() {
    const isUpdateQuery = this.props?.settings?.query?.dataEditing?.isUpdateQuery
    const modifiedRecords = _.cloneDeep(this.store.getModifiedRecords())
    const fieldConfigs = this.getFieldConfigs()

    return _.reduce(
      modifiedRecords,
      (arr, item) => {
        const { dirty, data } = item
        if (dirty) {
          _.forEach(data, (value, key) => {
            const isDate =
              _.size(
                _.filter(
                  fieldConfigs,
                  ({ fieldName, dataType }) => fieldName === key && dataType === 'datetime'
                )
              ) > 0
            data[key] = isDate ? this.getDateFormat(value) : value
          })
          if (!isUpdateQuery && item.modified) {
            _.forEach(item.modified, (val, index) => {
              this.editList.push({
                changedColumn: index,
                columnValues: data,
                oldValue: val
              })
            })
          }

          arr.push(data)
        }
        return arr
      },
      []
    )
  }

  getDateFormat(value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
  }

  handleRemoteArguments(params) {
    // TODO try with multiple pivots
    if (this.pivotgrid && this.pivotgrid.cmp && this.pivotgrid.cmp.matrix.type === 'remote') {
      this.pivotgrid.cmp.matrix.reload(params)
      this.setState({ args: params })
      this.props.afterDataLoad()
    }
  }

  handleFooterButtonClick() {
    return { refreshKey: uuidv4() }
  }

  createFooter(pivotConfig) {
    const footerBar = []
    const {
      pivot: {
        editing: {
          editingType = '',
          saveButtonSettings: {
            resetButton: resetButtonEnabled = true,
            resetButtonIcon = '',
            resetButtonText = 'Reset Button',
            resetButtonTooltip = 'Reset Button',
            saveButtonIcon = 'fa fa-floppy-o',
            saveButtonText = 'Save',
            saveButtonTooltip = 'Save Changes'
          } = {}
        } = {},
        footerButtons = []
      } = {}
    } = pivotConfig || {}

    const editingEnabled = this.isPivotEditable()

    if (editingEnabled && editingType === 'SaveButton') {
      if (resetButtonEnabled) {
        footerBar.push({
          handler: this.handleResetChanges,
          iconCls: resetButtonIcon,
          text: resetButtonText,
          tooltip: resetButtonTooltip
        })
      }
      footerBar.push({
        handler: this.handleTriggerSaveChanges,
        iconCls: saveButtonIcon,
        text: saveButtonText,
        tooltip: saveButtonTooltip
      })
    }

    _.forEach(footerButtons, (footerButton) => {
      const {
        buttonIcon,
        buttonText,
        buttonTooltip,
        confirmation = false,
        confirmationText = 'Are you sure to continue?',
        confirmationTitle = 'Confirmation'
      } = footerButton
      footerBar.push({
        text: buttonText,
        tooltip: buttonTooltip,
        iconCls: buttonIcon,
        handler: confirmation
          ? this.openFooterButtonConfirmationPopup(
              confirmationText,
              confirmationTitle,
              this['handleFooterButtonClick_' + buttonText]
            )
          : this['handleFooterButtonClick_' + buttonText]
      })
    })

    if (_.size(footerBar) === 0) {
      return null
    }
    return footerBar
  }

  openFooterButtonConfirmationPopup(message, title, handler) {
    return () => {
      confirmAlert({
        title,
        message,
        buttons: [
          {
            label: 'Cancel',
            onClick: () => false
          },
          {
            label: 'OK',
            onClick: () => handler()
          }
        ]
      })
    }
  }

  prepareUpdateData(updateDataList) {
    const updateData = {
      updateItems: []
    }
    _.forEach(updateDataList, (value) => {
      updateData.updateItems.push({
        columnName: value.changedColumn,
        config: value.columnValues,
        oldValue: value.oldValue
      })
    })

    return updateData
  }

  prepareUpdateData(updateDataList) {
    const fieldConfigs = this.getFieldConfigs()

    // If the same cell is edited multiple times
    // send only the last value to the server
    const groups = _.groupBy(updateDataList, (updateItem) => {
      const { changedColumn, columnValues: { __SLVYRowIndex } = {} } = updateItem
      return changedColumn + '-' + __SLVYRowIndex
    })
    let reducedUpdates = _.map(groups, (group) => {
      return { ..._.last(group), oldValue: _.first(group).oldValue }
    })

    // If the same cell is edited multiple times
    // and the cell retains its original value
    // ignore the cell
    reducedUpdates = _.filter(reducedUpdates, (updateItem) => {
      const { changedColumn, columnValues = {}, oldValue } = updateItem
      if (_.has(columnValues, changedColumn)) {
        return columnValues[changedColumn] !== oldValue
      }
    })

    const updateData = {
      updateItems: []
    }
    const actualData = this.store.getData()
    const { items: rows = [] } = actualData || {}
    _.forEach(reducedUpdates, (updateItem) => {
      const row = _.find(rows, (item) => {
        return item.get('__SLVYRowIndex') === updateItem.columnValues['__SLVYRowIndex']
      })

      // Copy the actual grid data to all update rows.
      // This is necessary since we want to feed the update statements of columns with
      // actual data
      if (row) {
        const updateRecord = this.formatDatesForUpdate(row.data, fieldConfigs)
        updateData.updateItems.push({
          columnName: updateItem.changedColumn,
          config: { ...updateRecord },
          oldValue: updateItem.oldValue
        })
      }
    })

    return updateData
  }

  formatDatesForUpdate(record, fields) {
    return _.transform(
      record,
      (result, value, key) => {
        const dateField = _.find(fields, (field) => {
          return field.fieldName === key && field.dataType === 'datetime'
        })
        if (dateField) {
          result[key] = this.getDateFormat(value)
        } else {
          result[key] = value
        }
      },
      {}
    )
  }

  getTopKeys(pivot, column) {
    const keys = {}
    const topAxisItem = pivot.getTopAxisItem(column)

    if (topAxisItem) {
      let i = 0
      for (let key in topAxisItem.data) {
        keys[topAxisItem.axis.dimensions.items[i].dataIndex] = topAxisItem.data[key]
        i++
      }
    }
    return keys
  }

  getLeftKeys(pivot, record) {
    const keys = {}
    const leftAxisItem = pivot.getLeftAxisItem(record)

    if (leftAxisItem) {
      let i = 0
      const leftAxisData = leftAxisItem.data

      for (let key in leftAxisData) {
        keys[leftAxisItem.axis.dimensions.items[i].dataIndex] = leftAxisData[key]
        i++
      }
    }

    return keys
  }

  getRelatedRows(record, column) {
    // Get top and left keys
    const keys = {
      ...this.getLeftKeys(this.pivotgrid.cmp, record),
      ...this.getTopKeys(this.pivotgrid.cmp, column)
    }

    const rawData = this.store.getData()
    const { items: rawDataRows = [] } = rawData || {}

    // Get the raw data rows which are related with the selected cell
    return _.filter(rawDataRows, (rawDataRow) => {
      let match = true
      _.forEach(keys, (value, key) => {
        const { data: { [key]: rowKeyValue = null } = {} } = rawDataRow || {}
        if (rowKeyValue !== value) {
          match = false
          return false
        }
      })
      return match
    })
  }

  handleCellEdit(editor) {
    const {
      settings: {
        config: {
          pivot: { editing: { editingType = '', lockGridDisaggregation = true } = {} } = {},
          general: { remoteMatrix = false } = {}
        } = {}
      } = {}
    } = this.props
    if (!remoteMatrix) {
      return
    }
    const delaySaving = editingType === 'SaveButton' || editingType === 'Trigger'
    const queueSaving = editingType === 'Queue'
    const cellUpdaterConfig = editor.updater.config
    // TODO check the same value edited or invalid edit
    const editObj = {
      dataIndex: cellUpdaterConfig.dataIndex,
      leftKey: cellUpdaterConfig.leftKey,
      topKey: cellUpdaterConfig.topKey,
      updateType: cellUpdaterConfig.type,
      value: cellUpdaterConfig.value
    }

    if (!delaySaving) {
      const singleEditList = []
      singleEditList.push(editObj)
      this.updateRemoteValue(queueSaving, singleEditList, lockGridDisaggregation)
    } else {
      // TODO check Trigger type
      // TODO operation edit check like saveButtonUpdate
      this.editList.push(editObj)
      if (lockGridDisaggregation) {
        this.unmaskCmp()
      }
    }
  }

  handleValidateEdit(editor, context) {
    const { value, originalValue } = context

    // If a cell is clicked, the editor is displayed but the value has not changed
    // Do not disaggregate the value to raw data, ignore the event

    // We have to format the values before we compare them.
    // We have chosen the 0.00 format for this, since the numberfield editor has this format.
    const formattedOriginalValue = getFormatedValue('0.000', originalValue)
    const formattedNewValue = getFormatedValue('0.000', value)

    if (formattedNewValue === formattedOriginalValue) {
      context.cancel = true
    } else {
      // Display progress while an update is being executed in the pivot
      const varPath = 'settings.config.pivot.editing.lockGridDisaggregation'
      const lockGridDisaggregation = _.get(this.props, varPath, true)

      if (lockGridDisaggregation) {
        this.maskCmp('Applying Changes...')
      }
    }
  }

  handleBeforeEdit(editor, context) {
    const { record, column } = context || {}
    const { dimension: { dataIndex = '' } = {} } = column || {}

    const { settings: { config: { aggregate: aggregates = [] } = {} } = {} } = this.props

    const aggregateConfig = _.find(aggregates, { dataIndex: dataIndex })
    let {
      editing: {
        checkboxField = '',
        defaultUpdater = 'overwrite',
        editableCondition = '',
        enabled: editingEnabled = false
      } = {},
      aggregator = 'sum'
    } = aggregateConfig || {}

    const cellEditingPlugin = _.find(this.pivotgrid.cmp.plugins, {
      ref: 'cellEditing'
    })
    if (cellEditingPlugin) {
      if (aggregateConfig.customAggregator) {
        const customAggregator = _.find(this.customAggragators, {
          name: aggregateConfig.customAggregator
        })
        if (customAggregator) {
          aggregator = customAggregator.fn
          aggregateConfig.customAggregator = customAggregator.name
        } else {
          const wantedAggregate = this.customAggsFunc(aggregateConfig.customAggregator)
          if (wantedAggregate.enabled) {
            aggregator = wantedAggregate.func
          }
        }
      }

      cellEditingPlugin.updater = Ext.Factory.pivotupdate({
        type: defaultUpdater,
        aggregator: aggregator
      })
    }

    // Get raw data rows
    const relatedRows = this.getRelatedRows(record, column)

    // Is this cell a checkbox cell
    let checkBoxCell = false
    if (checkboxField) {
      const checkBoxConditionValue = _.reduce(
        relatedRows,
        (sum, row) => {
          return sum + row.data[checkboxField]
        },
        0
      )
      checkBoxCell = checkBoxConditionValue > 0
    }

    if (!editingEnabled) {
      return false
    }

    // The editability of this aggregation is based on a field
    if (editableCondition) {
      // Get the max aggregation of the condition field
      const maxEditableConditionValue = _.reduce(
        relatedRows,
        (max, row) => {
          return row.data[editableCondition] > max ? row.data[editableCondition] : max
        },
        0
      )

      // The selected cell is editable if the max aggregation of the condition field is larger than zero
      // Do not display the editor if the cell is checkbox cell
      return !checkBoxCell && maxEditableConditionValue > 0
    }

    // There no conditional editing. Do not display the editor if the cell is checkbox cell
    return !checkBoxCell
  }

  handleShowEangeEditor() {
    return false
  }

  createPivotStyles(pluginId) {
    const style = `.x-message-box {
      z-index: 9999999999 !important
    }
    .x-window[role=dialog] {
      z-index: 9999999999 !important
    }
    .x-boundlist {
      z-index: 9999999999 !important
    }
    .x-dd-drop-ok {
      z-index: 9999999999 !important
    }
    .x-dd-drop-nodrop {
      z-index: 9999999999 !important
    }
    .x-pivot-grid-config-column-text {
      color: white;
    }
    .x-pivot-grid-config-container-header {
      background-color: #06307F
    }

    .pivotgrid${pluginId} .x-grid-dirty-cell > .x-grid-cell-inner:after {
      color: rgba(255, 255, 255, 0.0);
    }
    .pivotgrid${pluginId} .x-grid-dirty-cell-solvoyo > .x-grid-cell-inner:after {
      content: "\\e602";
      font: 14px/1 ExtJS;
      color: #cf4c35 !important;
      position: absolute;
      top: 0;
      left: 0;
    }
    
    /* The aggregate items in the configurator have a Field Settings dialog
               In the Field Settings dialog there is a "Format as" field
               Since we have our own formatting system, hide the "Format as" field */
    .x-panel-body .x-field:nth-child(4){
      display:none
    }
    `

    this.createStyle(style, pluginId)
  }

  createStyle = function (content, pluginId) {
    const element = document.getElementById('extjsPivot' + pluginId)

    if (!element) {
      const style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = content
      style.id = 'extjsPivot' + pluginId

      document.getElementsByTagName('head')[0].appendChild(style)
    }
  }

  removeStyle(pluginId) {
    const element = document.getElementById('extjsPivot' + pluginId)
    if (element) {
      element.parentNode.removeChild(element)
    }
  }

  createHeaderToolbar(collapseButtonEnabled, exportEnabled, bookmarkButtonEnabled) {
    const header = {
      itemPosition: 1,
      items: [],
      uiCls: ['pivotHeader']
    }

    if (collapseButtonEnabled) {
      header.items.push({
        xtype: 'button',
        cls: 'btn-primary',
        handler: this.handleExpandCollapse,
        reference: 'ExpandButton',
        text: 'Expand'
      })
    }

    if (exportEnabled) {
      header.items.push({
        xtype: 'button',
        cls: 'btn-primary',
        iconCls: 'fa fa-download',
        text: 'Export',
        menu: {
          cls: 'z-index-top',
          items: [
            {
              text: 'All Items',
              handler: this.exportAllToXlsx
            },
            {
              text: 'Only Visible Items',
              handler: this.exportVisibleToXlsx
            }
          ]
        }
      })
    }

    if (bookmarkButtonEnabled) {
      const { preferredUsername } = this.props
      const bookmarks = this.props.pluginStates.data.map((item) => {
        const {
          name,
          public: publicBookmark,
          user,
          config: { createDate: createDateUTC } = {}
        } = item

        // Convert to local time
        let createDateLocal = moment(moment.utc(createDateUTC).toDate())
          .local()
          .format('YYYY-MM-DD HH:mm:ss')

        if (createDateLocal === 'Invalid date') {
          createDateLocal = createDateUTC
        }

        // Bookmark is editable if it is created by the current user
        const readonlyClass = user !== preferredUsername ? 'readonly' : ''

        return {
          text: `<div class="bookmarkMenuContainer">${name}<span class="bookmarkMenuIconContainer"><i class="fa fa-pencil-square-o bookmarkMenuIcon ${readonlyClass}"></i><i class="fa fa-remove bookmarkMenuIcon ${readonlyClass}"></i></span></div>`,
          handler: this.onbookmarkclick,
          iconCls: publicBookmark && 'x-fa fa-star',
          tooltip: `<div" style="list-style-type:none"><li><b>Created By: </b>${user}</li><li><b>Created At: </b>${createDateLocal}</li></div>`,
          bookmark: item
        }
      })

      header.items.push({
        xtype: 'button',
        cls: 'btn-success',
        iconCls: 'fa fa-bookmark',
        name: 'bookmarkMenu',
        menu: {
          width: 260,
          cls: 'bookmarkMenu',
          items: [
            {
              handler: this.onAddState,
              iconCls: 'x-fa fa-plus',
              text: 'Add Bookmark'
            },
            '-',
            {
              xtype: 'textfield',
              emptyText: 'Search',
              fieldCls: 'searchField',
              listeners: {
                change: this.onSearchChanged
              }
            },
            '-',
            ...bookmarks
          ]
        }
      })
    }

    return _.size(header.items) > 0 ? header : null
  }

  customAggsFunc(customAggregator) {
    let aggFunc = `
      function table(i, aggName){
       if(_.size(records) >= i && _.has(records[i].data, aggName)){
        var value =  records[i].get(aggName)
        var numberValue = _.toNumber(value)
        if(!_.isNaN(numberValue)){
          value = numberValue
        }
        return value
       }
      }
      const rowCount=records.length
      `

    const { settings: { config: { dynamicAggregators = {} } = {} } = {} } = this.props
    if (!customAggregator || _.isEmpty(customAggregator.trim())) {
      return { enabled: false }
    }
    const wantedAggregatorFunc = _.find(dynamicAggregators, {
      name: customAggregator
    })
    if (wantedAggregatorFunc && !_.isEmpty(wantedAggregatorFunc.dynamicAggregatorFunc.trim())) {
      try {
        let funcBody = wantedAggregatorFunc.dynamicAggregatorFunc
        funcBody = window.atob(funcBody)
        aggFunc = aggFunc + funcBody
        // eslint-disable-next-line no-new-func
        let func = new Function('records', aggFunc)
        return { enabled: true, func }
      } catch (err) {
        console.log(err)
      }
      return { enabled: false }
    } else return { enabled: false }
  }

  isPivotEditable() {
    const {
      settings: {
        config: { pivot: { editing: { enabled: editableFromConfig = false } = {} } = {} } = {}
      } = {}
    } = this.props

    const editableFromAuthorization = this.props.isAllowed('Editing')

    return editableFromAuthorization && editableFromConfig
  }

  replaceTemplate(text = '', data) {
    const regExp = /\{([^}]+)\}/g

    const matches = text.match(regExp)

    if (!matches) return text

    for (let i = 0; i < matches.length; i++) {
      const variableName = matches[i].substr(1, matches[i].length - 2)

      let value = ''
      if (data && data[variableName]) {
        value = data[variableName]
      }

      text = text.replace(matches[i], value ? value : '')
    }

    return text
  }

  getFirstDataRow(pluginData) {
    return pluginData && pluginData[0]
  }

  moveToNextCell(direction) {
    const pivot = this.pivotgrid.cmp

    const selectionModel = pivot.getSelectionModel()
    const currCell = selectionModel.navigationModel.lastFocused
    const nextCell = currCell.view.walkCells(currCell, direction)
    if (nextCell) {
      currCell.view.focusCell(nextCell)

      if (selectionModel.type === 'spreadsheet') {
        let lockedColumnCount = 0
        const { column: { locked: lockedColumn = false } = {} } = nextCell
        if (!lockedColumn) {
          lockedColumnCount = 1
          const columns = pivot.getColumns()
          lockedColumnCount = _.reduce(
            columns,
            (count, column) => {
              const { locked } = column
              if (locked) {
                count += 1
              }
              return count
            },
            0
          )
        }

        selectionModel.selectCells(
          [nextCell.colIdx + lockedColumnCount, nextCell.rowIdx],
          [nextCell.colIdx + lockedColumnCount, nextCell.rowIdx],
          false
        )
      } else if (selectionModel.type === 'rowmodel') {
        selectionModel.select(nextCell.record, false)
      }
    }
  }

  handleCellKeydown(cell, td, cellIndex, record, tr, rowIndex, event) {
    if (event.keyCode === 13) {
      let direction = ''
      if (event.shiftKey) {
        direction = 'up'
      } else {
        direction = 'down'
      }
      setTimeout(() => {
        this.moveToNextCell(direction)
      }, 0)
      return false
    }
  }

  handleEditorSpecialkey(field, event) {
    let direction = ''
    if (event.keyCode === 37) {
      direction = 'left'
    } else if (event.keyCode === 38 || (event.keyCode === 13 && event.shiftKey)) {
      direction = 'up'
    } else if (event.keyCode === 39) {
      direction = 'right'
    } else if (event.keyCode === 40 || (event.keyCode === 13 && !event.shiftKey)) {
      direction = 'down'
    }
    if (direction) {
      this.moveToNextCell(direction)
    }

    return true
  }

  handleAfterRender(pivot) {
    this.createAlphaNumericKeyMaps(pivot)
  }

  createAlphaNumericKeyMaps(grid) {
    const numericKeys = []
    for (let i = 48; i < 58; i++) {
      numericKeys.push(i)
    } // 0-9
    new Ext.util.KeyMap({
      target: grid.el,
      binding: [
        {
          handler: this.handleNumericKeyPressed,
          key: numericKeys
        }
      ]
    })
  }

  handleNumericKeyPressed() {
    const pivot = this.pivotgrid.cmp
    const lastCell = pivot.getSelectionModel().navigationModel.lastFocused

    const cellEditing = _.find(pivot.plugins, {
      ref: 'cellEditing'
    })

    if (cellEditing) {
      let lockedColumnCount = 0
      const { column: { locked: lockedColumn = false } = {} } = lastCell
      if (!lockedColumn) {
        lockedColumnCount = 1
        const columns = pivot.getColumns()
        lockedColumnCount = _.reduce(
          columns,
          (count, column) => {
            const { locked } = column
            if (locked) {
              count += 1
            }
            return count
          },
          0
        )
      }

      cellEditing.startEditByPosition({
        column: lastCell.colIdx + lockedColumnCount,
        row: lastCell.rowIdx
      })
    }
  }

  setPivotConfiguratorField(fieldGroup) {
    // Create allowed places for fields
    var allowedPlaces = []

    _.forEach(fieldGroup, (field) => {
      allowedPlaces.push(field.place)
    })

    // Create fixed places for fields
    var fixedPlaces = []

    _.forEach(fieldGroup, (field) => {
      if (field.fixed) {
        fixedPlaces.push(field.place)
      }
    })

    // Create available aggregators
    var availableAggregators = []
    _.forEach(fieldGroup, (field) => {
      if (field.aggregators && field.aggregators.length > 0) {
        _.forEach(field.aggregators, (aggregator) => {
          if (!availableAggregators.includes(aggregator)) {
            availableAggregators.push(aggregator)
          }
        })

        if (field.aggregator && !availableAggregators.includes(field.aggregator)) {
          availableAggregators.push(field.aggregator)
        }
      }
    })

    // Create default aggregator
    var aggregator = null
    _.forEach(fieldGroup, (field) => {
      if (field.aggregator) {
        aggregator = field.aggregator
        return false
      }
    })

    // Create pivot configurator fields
    const field = {
      dataIndex: fieldGroup[0].dataIndex,
      header: fieldGroup[0].header,
      settings: {
        allowed: allowedPlaces,
        fixed: fixedPlaces
      }
    }

    _.forEach(fieldGroup, (fieldObj) => {
      // Aggregates are always aligned to right since they are numbers
      // Ignore Left and top axis items
      if (fieldObj.align) {
        field.align = fieldObj.align
      }

      // If there is a sort index, apply it also to the configurator field
      if (_.has(fieldObj, 'sortIndex')) {
        field.sortIndex = fieldObj.sortIndex
      }
    })

    if (availableAggregators.length > 0) {
      field.settings.aggregators = availableAggregators
    }

    if (aggregator) {
      field.aggregator = aggregator
    }

    return field
  }

  render() {
    const {
      props: pivotProps,
      props: {
        pluginData = [],
        id: pluginId = '',
        isPreviewMode,
        pluginStates: { isSuccess: pluginStatesSuccess = false } = {},
        query: { isSuccess = false } = {},
        size: { width = 0, height = 0 } = {},
        settings: {
          config: pivotConfig = {},
          config: {
            pivot: {
              autoRememberState = true,
              collapseButtonEnabled = false,
              emptyText = '',
              enableLocking = false,
              startColGroupsCollapsed = true,
              startRowGroupsCollapsed = true,
              stateButtonEnabled = false,
              editing: {
                clicksToEdit: pivotEditingClicksToEdit,
                defaultUpdater = 'overwrite',
                editingMethod = 'CellEditing'
              } = {}
            } = {},
            pivotexporter: {
              plugin: exportPluginConfig = {},
              plugin: { enabled: exportEnabled = false } = {}
            } = {},
            general: { remoteMatrix = false } = {},
            matrix = {},
            matrix: {
              rowGrandTotalsPosition: configRowGrandTotalsPosition = remoteMatrix
                ? 'first'
                : 'none',
              collapsibleColumns = true,
              collapsibleRows = true,
              viewLayoutType = {},
              textGrandTotalTpl: configTextGrandTotalTpl = 'Grand total',
              textRowLabels: configTextRowLabels = '',
              calculateAsExcel = false
            } = {},
            aggregate = [],
            leftAxis = [],
            topAxis = []
          }
        } = {}
      },
      state: { forceReInit }
    } = this

    //TODO: START: Related to childNodes null problem
    if (!width) {
      return null
    }
    //TODO: END

    const pluginDataReady = _.size(pluginData) > 0 && _.has(pluginData[0], __RowIndex)
    if (!pluginStatesSuccess) return null

    const header = this.createHeaderToolbar(
      collapseButtonEnabled,
      exportEnabled,
      stateButtonEnabled
    )

    /** ************************************************* */
    // Add cell renderers and custom aggregators to aggregates, leftAxis and topAxis
    let aggregates = _.cloneDeep(aggregate)
    let leftAxisItems = _.cloneDeep(leftAxis)
    let topAxisItems = _.cloneDeep(topAxis)

    if (pluginDataReady) {
      let pivotData = _.cloneDeep(pluginData)
      // Remove this data mapping after rowindex adding is fixed
      pivotData = _.map(pivotData, (row) => {
        return row
      })

      pivotData = this.formatTopAndLeftAxisItems(pivotData, leftAxisItems, topAxisItems, aggregates)
      this.store.setData(pivotData)
    } else if (isSuccess && _.size(pluginData) === 0) {
      this.store.setData([])
    }

    _.remove(aggregates, (field) => {
      return !field.dataIndex
    })
    _.remove(leftAxisItems, (field) => {
      return !field.dataIndex
    })
    _.remove(topAxisItems, (field) => {
      return !field.dataIndex
    })

    const firstDataRow = this.getFirstDataRow(this.props.pluginData)
    let fieldGroups = {}

    _.forEach(aggregates, (field) => {
      if (!fieldGroups[field.dataIndex]) {
        fieldGroups[field.dataIndex] = []
      }
      field.place = 'aggregate'
      field.align = 'right'
      fieldGroups[field.dataIndex].push(field)
    })

    _.forEach(leftAxisItems, (field) => {
      if (!fieldGroups[field.dataIndex]) {
        fieldGroups[field.dataIndex] = []
      }
      field.place = 'leftAxis'
      fieldGroups[field.dataIndex].push(field)
      field.header = this.replaceTemplate(field.header, firstDataRow)
    })

    _.forEach(topAxisItems, (field) => {
      if (!fieldGroups[field.dataIndex]) {
        fieldGroups[field.dataIndex] = []
      }
      field.place = 'topAxis'
      fieldGroups[field.dataIndex].push(field)
      field.header = this.replaceTemplate(field.header, firstDataRow)
    })

    leftAxisItems = _.filter(leftAxisItems, (leftAxisItem) => {
      const { visibleBy = '' } = leftAxisItem
      return !visibleBy || (visibleBy && firstDataRow && firstDataRow[visibleBy])
    })

    // Remove items which are not visible by default
    aggregates = _.remove(aggregates, (field) => {
      return !('visibleByDefault' in field) || field.visibleByDefault
    })

    leftAxisItems = _.remove(leftAxisItems, (field) => {
      return !('visibleByDefault' in field) || field.visibleByDefault
    })

    topAxisItems = _.remove(topAxisItems, (field) => {
      return !('visibleByDefault' in field) || field.visibleByDefault
    })

    _.forEach(aggregates, (aggregate) => {
      aggregate.renderer = this.cellRenderer(aggregate)
      aggregate.editor = 'numberfield'

      if (aggregate.customAggregator) {
        const customAggregator = _.find(this.customAggragators, {
          name: aggregate.customAggregator
        })
        if (customAggregator) {
          aggregate.aggregator = customAggregator.fn
          aggregate.customAggregator = customAggregator.name
        } else {
          const wantedAggregate = this.customAggsFunc(aggregate.customAggregator)
          if (wantedAggregate.enabled) {
            aggregate.aggregator = wantedAggregate.func
          }
        }
      }
    })

    const plugins = []

    const pivotconfigurator = _.cloneDeep(pivotConfig.pivotconfigurator)
    if (pivotconfigurator && pivotconfigurator.plugin.enabled) {
      pivotconfigurator.plugin.fields = []
      _.forEach(fieldGroups, (fieldGroup) => {
        const field = this.setPivotConfiguratorField(fieldGroup)
        pivotconfigurator.plugin.fields.push(field)
      })
      plugins.push(Ext.create('Ext.pivot.plugin.Configurator', pivotconfigurator.plugin))
    }

    if (exportEnabled) {
      plugins.push(Ext.create('Ext.pivot.plugin.Exporter', exportPluginConfig))
    }

    const selectionModel = this.getSelectionModel(pivotConfig)
    if (selectionModel) {
      plugins.push(Ext.create('Ext.grid.plugin.Clipboard', true))
    }

    const editingEnabled = this.isPivotEditable()
    if (editingEnabled) {
      if (editingMethod === 'CellEditing') {
        plugins.push(
          Ext.create('Ext.pivot.plugin.CellEditing', {
            clicksToEdit: pivotEditingClicksToEdit,
            defaultUpdater,
            ref: 'cellEditing',
            listeners: {
              beforeedit: this.handleBeforeEdit,
              edit: this.handleCellEdit,
              validateedit: this.handleValidateEdit
            }
          })
        )
      } else {
        plugins.push(
          Ext.create('Ext.pivot.plugin.RangeEditor', {
            defaultUpdater,
            textButtonOk: 'OK',
            listeners: {
              showrangeeditorpanel: this.handleShowEangeEditor
            }
          })
        )
      }
    }

    // WORKAROUND: If plugin data is we want to show the empty text
    // rowGrandTotalsPosition prevents empty text
    const rowGrandTotalsPosition =
      pluginData === null || pluginData.length === 0
        ? remoteMatrix
          ? 'first'
          : 'none'
        : configRowGrandTotalsPosition

    const textGrandTotalTpl = this.replaceTemplate(configTextGrandTotalTpl, firstDataRow)

    const textRowLabels = configTextRowLabels
      ? this.replaceTemplate(configTextRowLabels, firstDataRow)
      : null

    // Create footer bar
    const footerBar = this.createFooter(pivotConfig)

    const listeners = {
      afterlayout: this.handleAfterLayout,
      afterrender: this.handleAfterRender,
      beforeconfigchange: this.beforeconfigchange,
      beforestaterestore: this.beforestaterestore,
      beforestatesave: this.beforestatesave,
      cellclick: this.handleCellClick,
      celldblclick: this.handleCellDoubleClick,
      cellkeydown: this.handleCellKeydown,
      pivotdone: this.handlePivotDone,
      select: this.handleRowSelected
    }

    const viewConfig = {
      deferEmptyText: true,
      emptyText: `<h3> ${emptyText} <h3>`,
      enableTextSelection: this.getTextSelectionEnabled(pivotConfig)
    }

    const compactViewColumnWidth =
      viewLayoutType &&
      viewLayoutType.viewLayoutType === 'compact' &&
      viewLayoutType.compactViewColumnWidth

    const $matrix = {
      aggregate: aggregates,
      colGrandTotalsPosition: matrix && matrix.colGrandTotalsPosition,
      colSubTotalsPosition: matrix && matrix.colSubTotalsPosition,
      collapsibleColumns,
      collapsibleRows,
      compactViewColumnWidth,
      id: 'pivotgridmatrix' + pluginId,
      leftAxis: leftAxisItems,
      rowGrandTotalsPosition: rowGrandTotalsPosition,
      rowSubTotalsPosition: matrix && matrix.rowSubTotalsPosition,
      calculateAsExcel,
      store: this.store,
      textGrandTotalTpl,
      textRowLabels,
      topAxis: topAxisItems,
      type: remoteMatrix ? 'remote' : 'local',
      viewLayoutType: viewLayoutType && viewLayoutType.viewLayoutType,
      listeners: {
        afterupdate: this.handleMatrixAfterUpdate,
        done: this.handleMatrixProcessingDone,
        start: this.handleMatrixProcessingStart
      },
      ...(remoteMatrix && {
        url: `${API_URL}/data/GetRemotePivotData/${pluginId}`
      })
    }

    const containerProps = {
      id: `slvyExtContainer-${pluginId}`,
      className: 'slvy-ext-container w-100 h-100 pivotContainer'
    }

    const pivotGridSettings = {
      cls: 'pivotgrid' + pluginId,
      clsGrandTotal: 'clsGrandTotal',
      clsGroupTotal: 'clsGroupTotal',
      enableLocking,
      fbar: footerBar,
      handleEditorSpecialkey: this.handleEditorSpecialkey,
      header,
      height: isPreviewMode ? '500px' : height ? height : '100%',
      id: 'pivotgrid' + pluginId,
      key: forceReInit,
      listeners,
      matrix: $matrix,
      pivotProps,
      plugins,
      ref: (ref) => (this.pivotgrid = ref),
      referenceHolder: true,
      selModel: selectionModel,
      startColGroupsCollapsed,
      startRowGroupsCollapsed,
      stateId: 'pivotgrid' + pluginId,
      stateful: autoRememberState,
      viewConfig,
      width: width ? width : '100%'
    }

    return (
      <ExtRoot pluginId={pluginId}>
        <div ref={(containerRef) => (this.containerRef = containerRef)} {...containerProps}>
          <Pivotgrid {...pivotGridSettings} />
        </div>
      </ExtRoot>
    )
  }
}

export default createPlugin(
  connect(
    (state, ownProps) => {
      return {
        pluginStates: selectCollection(getPluginStates(ownProps.id), state.model3),
        pluginStateStatus: selecOperationStatus(
          'pluginstate',
          'create_' + ownProps.id,
          state.model3
        ),
        pluginStateDeleteStatus: selecOperationStatus(
          'pluginstate',
          'delete_' + ownProps.id,
          state.model3
        ),
        pluginStateUpdateStatus: selecOperationStatus(
          'pluginstate',
          'update_' + ownProps.id,
          state.model3
        )
      }
    },
    (dispatch) => ({
      dispatch,
      createPluginState: bindActionCreators(createPluginState, dispatch),
      deletePluginState: bindActionCreators(deletePluginState, dispatch),
      updatePluginState: bindActionCreators(updatePluginState, dispatch)
    })
  )(SenchaPivot),
  selectConnectorProps
)
