import { useEffect, useMemo } from 'react'
import { SlvyTable } from '@/components'
import { useReduxState } from '../../../hooks'
import { getLoadStopTable } from '../../../store/api/endpoints'
import { tuiSlice, loadTableSlice, loadStopTableSlice } from '../../../store/slices'
import { getEnabledColumns, getTableQueryParams } from '../../../utils'

export default function LoadStopTable() {
  const [{ config }] = useReduxState(tuiSlice.selectSlice)
  const [loadTable, loadTableDispatch] = useReduxState(
    loadTableSlice.selectSlice,
    loadTableSlice.actions
  )
  const [loadStopTable, loadStopTableDispatch] = useReduxState(
    loadStopTableSlice.selectSlice,
    loadStopTableSlice.actions
  )
  const [triggerGetLoadStopTable, loadTableResponse] = getLoadStopTable.useLazyQuery()
  const columns = useMemo(() => [...getEnabledColumns(config.loadStopTable.columns)], [])

  useEffect(() => {
    if (loadTable.selectedRow) {
      triggerGetLoadStopTable({
        id: loadTable.selectedRow,
        ...getTableQueryParams(config, 'loadStopTable')
      })
    }
  }, [])

  useEffect(() => {
    triggerGetLoadStopTable({
      id: loadTable.selectedRow ?? '',
      ...getTableQueryParams(config, 'loadStopTable')
    })
  }, [loadTable.selectedRow])

  function handleSelectRow({ original }) {
    loadStopTableDispatch.setSelectedRow(original.ID)
  }

  return (
    <div className="w-50 d-flex flex-column">
      <p className="p-0 m-0 fw-bold">{config.loadStopTable.header}</p>
      <SlvyTable
        rowSelection
        columns={columns}
        data={loadTableResponse.data}
        grouping={config.loadStopTable.isGroupingEnabled}
        isLoading={!loadTableResponse.data?.length}
        theme="sencha-grid"
        onSelectRow={handleSelectRow}
      />
    </div>
  )
}
