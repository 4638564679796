export default () => {
  return {
    title: 'Calendar',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Filter Settings',
        properties: {
          label: {
            type: 'string',
            title: 'Label'
          },
          placeholder: {
            type: 'string',
            title: 'Placeholder',
            default: 'Please select'
          },
          calendarType: {
            type: 'string',
            title: 'Calendar Type',
            enum: ['Single', 'Interval'],
            required: true,
            default: 'Single'
          },
          firstDayOfWeek: {
            type: 'string',
            title: 'First Day of Week',
            default: 'Sunday',
            enum: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
          },
          disableDays: {
            type: 'number',
            title: 'Disable Days',
            isMulti: true,
            default: [],
            enum: [1, 2, 3, 4, 5, 6, 7]
          },
          showClear: {
            type: 'boolean',
            title: 'Show Clear',
            required: true,
            default: false
          },
          resetToDefault: {
            type: 'boolean',
            title: 'Reset to Default',
            required: true,
            default: false,
            description:
              'Selected dates turn back to default values in case of clearing the selection.'
          },
          dateformat: {
            type: 'string',
            title: 'Output Date Format'
          },
          displaydateformat: {
            type: 'string',
            title: 'Display Date Format'
          },
          selectTime: {
            type: 'boolean',
            title: 'Select Time',
            required: true,
            default: false
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          label: {
            type: 'string',
            title: 'Label',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },

          startDate: {
            type: 'string',
            title: 'Start Date',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },

          endDate: {
            type: 'string',
            title: 'End Date',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          minvalue: {
            type: 'string',
            title: 'MinValue',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          maxvalue: {
            type: 'string',
            title: 'MaxValue',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          }
        }
      }
    }
  }
}
