export default {
  title: 'Markdown Editor',
  subTitle: 'Solvoyo',
  version: '1.0',
  namespace: 'MarkdownEditor',
  description: '',
  iconClass: 'fa-edit',
  color: 'lazur-bg',
  group: 'Views',
  support: {
    maximizable: true,
    editable: true
  },
  queryStatus: {
    isInsertActive: false,
    isUpdateActive: true,
    isDeleteActive: false
  }
}
