Ext.define('MDM.src.view.BomEdit.Main', {
  extend: 'Ext.window.Window',
  closeAction: 'destroy',
  height: 600,
  stateful: true,
  stateId: 'bomedit',
  searchCollapsed: false,
  width: 1200,
  y: 150,
  closable: false,
  modal: true,
  bodyStyle: {
    background: '#fff'
  },
  controller: 'boomedit-maincontroller',
  viewModel: 'bomedit-mainviewmodel',
  iconCls: 'fa fa-list',
  bodyBorder: false,
  layout: 'border',
  bodyPadding: 4,
  defaults: {
    collapsible: true,
    split: true
  },
  items: [
    {
      title: 'Component List ',
      region: 'west',
      xtype: 'bomedit-panels-search',
      floatable: false,
      reference: 'search',
      margin: '5 0 0 0',
      width: 440,
      iconCls: 'fa fa-search-plus',
      minWidth: 100,
      maxWidth: 600,
      ui: 'panel-inverse',
      border: true,
      bind: {
        store: '{searchStore}'
      }
    },
    {
      title: 'Bom List',
      collapsible: false,
      border: true,
      ui: 'panel-inverse',
      xtype: 'bomedit-list',
      iconCls: 'fa fa-list',
      region: 'center',
      margin: '5 0 0 0',
      bind: {
        store: '{BomStore}'
      }
    }
  ],
  listeners: {
    beforeclose: 'onbeforeClose'
  },
  bbar: {
    xtype: 'toolbar',
    reference: 'basic-statusbar',
    // defaults to use when the status is cleared:
    defaultText: 'Ready',
    //defaultIconCls: 'default-icon',
    // values to set initially:
    text: 'Ready',
    iconCls: 'x-status-valid',
    // any standard Toolbar items:
    items: [
      '->',
      {
        text: 'Close',
        iconCls: 'fa fa-ban',
        ui: 'btn-primary',
        handler: function (btn) {
          btn.up('window').close()
        }
      }
    ]
  }
})
