import React, { Component } from 'react'
import _ from 'lodash'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import SectionList from './components/sectionList'
import './style.scss'

class KeyValueList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      keyValueListSchema: {},
      saveDisabled: true
    }
    this.prepareSectionList = this.prepareSectionList.bind(this)
    this.createSectionsSchema = this.createSectionsSchema.bind(this)
    this.loadLookUpdata = this.loadLookUpdata.bind(this)
    this.runUpdateQuery = this.runUpdateQuery.bind(this)
    this.prepareUpdatedData = this.prepareUpdatedData.bind(this)
    this.onChangeValue = this.onChangeValue.bind(this)
    this.getValidationState = this.getValidationState.bind(this)
    this.changeSaveStatus = this.changeSaveStatus.bind(this)
    this.updateFieldLookUpData = this.updateFieldLookUpData.bind(this)
  }

  getLookupDataEditableField() {
    const { settings: { query: { editableFields = [] } = {} } = {}, pluginData = [] } =
      this.props || {}
    if (pluginData) {
      const result = _.find(editableFields, function (o) {
        return o.isLookupQuery
      })
      return result
    }
    return false
  }

  prepareUpdatedData() {
    const { pluginData } = this.props || {}
    const {
      keyValueListSchema: { sections }
    } = this.state || {}
    if (pluginData && pluginData.length !== 0) {
      const data = _.transform(
        pluginData,
        function (result, item, index) {
          const currentSection = _.find(sections, function (o) {
            return o.key === item.SectionKey
          })
          if (currentSection) {
            const currentSectionItem = _.find(currentSection.items, function (x) {
              return x.key === item.Key
            })
            if (item.Value !== currentSectionItem.value && !item.IsReadonly) {
              const newItem = { ...item } // Not deep clone because no children
              newItem.Value = currentSectionItem.value
              result.push({
                columnName: 'Value',
                config: newItem,
                oldValue: item.Value
              })
            }
          }
        },
        []
      )
      return data
    }
  }

  updateValue(updateData) {
    const { id, clientWithProgress } = this.props || {}
    clientWithProgress
      .post(`/data/plugin/${id}/update/`, { data: updateData })
      .then(
        function (res) {
          this.props.clearCaches()
          this.props.setDataArguments(null, true)
        }.bind(this)
      )
      .catch(function (err) {})
  }

  runUpdateQuery() {
    const { saveDisabled } = this.state
    if (!saveDisabled) {
      const updateItems = this.prepareUpdatedData()
      if (!_.isEmpty(updateItems)) {
        const data = {
          filters: {},
          updateItems,
          updatehints: {}
        }
        this.updateValue(data)
      }
    }
  }

  updateFieldLookUpData(updatedItem, $callback = () => {}) {
    const { pluginData = [] } = this.props
    const { keyValueListSchema } = this.state

    const clonedKeyValueListSchema = _.cloneDeep(keyValueListSchema)
    setTimeout(() => {
      clonedKeyValueListSchema.sections[updatedItem.sectionKey].items[updatedItem.key].data =
        this.loadLookUpdata(pluginData, updatedItem.key)
      this.setState(
        {
          keyValueListSchema: clonedKeyValueListSchema
        },
        () => {
          $callback(this.loadLookUpdata(pluginData, updatedItem.key))
        }
      )
    }, 1000)
  }

  loadLookUpdata(pluginData, key) {
    const {
      data: { updatehints = {} } = {},
      actualFilters = {},
      id,
      clientWithProgress,
      settings: { config: { data: dataFields = {} } = {} }
    } = this.props || {}
    const editableField = this.getLookupDataEditableField()
    const keyName = dataFields.key

    if (editableField) {
      const record = _.find(pluginData, { [keyName]: key })
      return clientWithProgress.post(`/data/plugin/${id}/lookup/${editableField.field}`, {
        data: { filters: actualFilters, record, updatehints }
      })
    }
    return null
  }

  onChangeValue(sectionKey, key, value) {
    const { keyValueListSchema = {} } = this.state

    const modifiedSchema = {
      ...keyValueListSchema,
      sections: {
        ...keyValueListSchema.sections,
        [sectionKey]: {
          ...keyValueListSchema.sections[sectionKey],
          items: {
            ...keyValueListSchema.sections[sectionKey].items,
            [key]: {
              ...keyValueListSchema.sections[sectionKey].items[key],
              value
            }
          }
        }
      }
    }
    this.setState({ keyValueListSchema: modifiedSchema }, () => {
      this.checkFormValidationState()
    })
  }

  prepareSectionList(pluginData, settings, data, sections) {
    const {
      backgroundColor = 'rgba(255, 255, 255,0.2)',
      title = '',
      sectionLeftColumn = 6,
      sectionRightColumn = 6
    } = settings || {}
    const keyValueListSchema = {
      title,
      backgroundColor,
      sectionLeftColumn,
      sectionRightColumn,
      sections: this.createSectionsSchema(pluginData, data, sections)
    }
    this.setState({ keyValueListSchema })
  }

  createSectionsSchema(pluginData, data, sections) {
    const items = _.transform(
      pluginData,
      (result, index) => {
        result[index[data.key]] = {
          key: index[data.key],
          value: index[data.value],
          isRequired: index[data.isRequired],
          isReadonly: index[data.isReadonly],
          type: index[data.type],
          validationMessage: index[data.validationMessage],
          sectionKey: index[data.sectionKey],
          title: index[data.title],
          addButton: index[data.addButton],
          data: _.startsWith(index[data.type], 'Dropdown')
            ? this.loadLookUpdata(pluginData, index[data.key])
            : {}
        }
      },
      {}
    )

    // grouping by section
    const uniqueSecsArr = _.uniq(_.map(items, 'sectionKey'))
    const formatedSections = _.transform(
      uniqueSecsArr,
      (res, item) => {
        const currentSection = _.find(sections, function (o) {
          return o.key === item
        })
        const currentSectionOrder = currentSection ? _.findIndex(sections, { key: item }) : 999
        res[item] = {
          items: {},
          title: currentSection
            ? currentSection.label
              ? currentSection.label
              : undefined
            : undefined,
          key: item,
          backgroundColor: currentSection
            ? currentSection.backgroundColor
              ? currentSection.backgroundColor
              : '#ffffff'
            : '#ffffff',
          order: currentSectionOrder
        }
      },
      {}
    )
    _.forEach(items, (compItem, index) => {
      formatedSections[compItem.sectionKey].items[compItem.key] = compItem
    })

    return formatedSections
  }

  getValidationState(item, value) {
    const { pluginData = [], settings: { config: { data = {} } = {} } = {} } = this.props
    const dataItem = _.find(pluginData, function (o) {
      return o[data.key] === item.key
    })
      ? _.find(pluginData, function (o) {
          return o[data.key] === item.key
        })
      : undefined
    const dataValue = dataItem ? (dataItem[data.value] ? dataItem[data.value] : '') : ''
    const returnedClass = value === dataValue ? null : 'changed'

    if (item.isRequired && _.isEmpty(value)) return 'error'
    if (!item.isRequired && _.isEmpty(value)) return returnedClass
    switch (item.type) {
      case 'Num':
      case 'Dropdown-Num': {
        if (_.isNumber(_.toNumber(value))) return returnedClass
        return 'error'
      }
      default:
        return returnedClass
    }
  }

  checkFormValidationState() {
    const {
      keyValueListSchema: { sections }
    } = this.state
    let validationState = true
    _.forEach(sections, (sec) => {
      _.forEach(sec.items, (item) => {
        if (this.getValidationState(item, item.value) === 'error') {
          validationState = false
          return false
        }
      })
      if (!validationState) return false
    })

    this.setState(
      {
        saveDisabled: !validationState
      },
      () => this.changeSaveStatus()
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { settings: { config: nextConfig = {} } = {}, pluginData: nextPluginData = [] } =
      nextProps
    const { settings: { config: oldConfig = {} } = {}, pluginData: oldPluginData = [] } = this.props
    const { keyValueListSchema: oldKeyValueListSchema = [], saveDisabled: oldSaveDisabled } =
      this.state
    const { keyValueListSchema: nextKeyValueListSchema = [], saveDisabled: nextSaveDisabled } =
      nextState

    const pluginDataChanged = !_.isEqual(nextPluginData, oldPluginData)
    const pluginConfiguration = !_.isEqual(nextConfig, oldConfig)
    const keyValueListChanged = !_.isEqual(nextKeyValueListSchema, oldKeyValueListSchema)
    const saveDisabledChanged = !_.isEqual(nextSaveDisabled, oldSaveDisabled)

    return pluginDataChanged || pluginConfiguration || keyValueListChanged || saveDisabledChanged
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      pluginData: newPlginData = [],
      settings: { config: { data = {}, sections = [], settings = {} } = {} } = {}
    } = nextProps
    const { pluginData: oldPlginData } = this.props

    if (_.size(newPlginData) > 0 && !_.isEqual(newPlginData, oldPlginData)) {
      this.prepareSectionList(newPlginData, settings, data, sections)
    }
  }

  changeSaveStatus() {
    const { saveDisabled } = this.state
    return { saveDisabled }
  }

  onNewOptionAdd(opts) {
    return opts
  }

  getReturnTypes() {
    const { settings: { query: { fields = [] } = {} } = {} } = this.props

    const returnTypes = {}

    const _fields = [...fields]

    for (let i = 0, len = _fields.length; i < len; i++) {
      returnTypes[_fields[i].fieldName] = PluginTypes.fromString(_fields[i].dataType)
    }

    return returnTypes
  }

  componentDidMount() {
    this.props.registerMethod({
      key: 'save',
      fn: this.runUpdateQuery.bind(this),
      args: []
    })
    this.changeSaveStatus = this.props.registerEvent({
      key: 'saveStatusChanged',
      fn: this.changeSaveStatus.bind(this),
      returnTypes: { saveDisabled: PluginTypes.boolean }
    })

    this.onNewOptionAdd = this.props.registerEvent({
      key: 'onNewOptionAdd',
      fn: this.onNewOptionAdd,
      returnTypes: this.getReturnTypes()
    })
  }

  render() {
    const { settings: { config: { data = {} } = {}, query: { fields = [] } = {} } = {} } =
      this.props
    const { keyValueListSchema } = this.state

    return (
      <SectionList
        configData={data}
        getValidationState={this.getValidationState}
        queryFields={fields}
        schema={keyValueListSchema}
        updateFieldLookUpData={this.updateFieldLookUpData}
        onChange={this.onChangeValue}
        onNewOptionAdd={this.onNewOptionAdd}
      />
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  id: props.id,
  clientWithProgress: props.clientWithProgress,
  clearCaches: props.clearCaches,
  setDataArguments: props.setDataArguments,
  data: props.data,
  actualFilters: props.actualFilters
})

export default createPlugin(KeyValueList, selectConnectorProps)
