export default `({
    //stateful: true,
    columnlines: true,
    stateId: 'lockedTrees',
    lockedGridConfig: {
        header: false,
        resizable: 'x',
        width: 300,
        //width: 100,
        forceFit: false,
        // split: true,
        //stateful: true,
        // stateId: 'bananeffff',
    },
    normalGridConfig: {
        header: false,
        //split: true,
        //stateful: true,
        //  stateId: 'bananewfff',
        //    forceFit: false,
    },
    multiColumnSort: true,
    width: 900,
    plugins: [
        {
          ptype: 'gridfilters',
          id: 'gridfilters'
        }],
    bbar: {
        xtype: 'pagingtoolbar',
        displayInfo: true
    },
    dockedItems: [{
        xtype: 'toolbar',
        docked: 'bottom',
        height: 44,
        border: true,
        items: [{ 
            xtype: 'button',
            text: tt('Export to Excel') ,
            handler : function() {
                var me=this
                var grid = this.ownerCt.ownerCt;
                 
                var columns=[];
                grid.columns.forEach((x)=>
                    {
                        if(x.dataIndex && x.hidden == false) 
                        {
                            columns.push({
                                    column:x.dataIndex,
                                    columnName:x.text,
                                    columnType:x.xtype,
                                    format:x.format
                                })
                        }
                        
                    })
                
                var filters=[];
                grid.store.filters.items.forEach(
                    (x)=>{
                        filters.push({property:x._property,value:x._value,operator:x._operator})
                    })
                var sorters=[];
                grid.store.sorters.items.forEach(
                    (x)=>{
                        sorters.push({direction:x._direction,property:x._property})
                    })
    
                var statusCode = grid.store.proxy.extraParams.statusCode    
                Ext.Ajax.request({
                    url: Spui.Global.getApiUrl() + '/spui/ExcelExport',
                    method: 'POST',
                    params: {
                        filter:JSON.stringify(filters),
                        sort:JSON.stringify(sorters),
                        statusCode:statusCode,
                        columns:  JSON.stringify(columns)
                    },
                    success: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export isteğiniz alınmıştır.')
                          )
                    },
                    failure: function(response, opts) {
                        Ext.Msg.alert(
                            tt('Durum'),
                            tt('Export sırasında bir problem oluştu.')
                          )
                    }
                });
    
            }
        }]
    }],
    columns: [{
            xtype: 'rownumberer'
        },
        {
            menuDisabled: true,
            sortable: false,
            xtype: 'actioncolumn',
            resizable: false,
            locked: true,
            width: 30,
            align: 'center',
            items: [{
                iconCls: 'fa fa-pencil',
               disabled: true,
                filterField: {
                    xtype: 'textfield'
                },
                handler: function(){
                    Ext.create('Spui.view.statusdialogs.dc.Main').show();
                }
            }]
        },
        {
            text: tt('id'),
            dataIndex: 'SpotId',
            width: 50,
            hidden: true
        },
        // {
        //     menuDisabled: true,
        //     sortable: false,
        //     xtype: 'actioncolumn',
        //     resizable: false,
        //     locked: false,
        //     width: 30,
        //     align: 'center',
        //     items: [{
        //         iconCls: 'x-fa fa-pencil',
        //         filterField: {
        //             xtype: 'textfield'
        //         },
        //         handler: 'onEditWizard'
        //     }]
        // },
        // {
        //     menuDisabled: true,
        //     sortable: false,
        //     xtype: 'actioncolumn',
        //     resizable: false,
        //     locked: false,
        //     width: 30,
        //     align: 'center',
        //     items: [{
        //         iconCls: 'cell-editing-delete-row',
        //         filterField: {
        //             xtype: 'textfield'
        //         },
        //         handler: 'onClickDeleteSpot'
        //     }]
        // },
        {
            text: tt('MaterialCode'),
            dataIndex: 'MaterialCode',
            width: '100',
            locked: true,
            renderer: function (v) {
                return v * 1;
            },
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialDescription'),
            dataIndex: 'MaterialDescription',
            width: '200',
            flex: 1,
            locked: true,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('MaterialGroupName'),
            dataIndex: 'MaterialGroupName',
            width: '200',
            hidden: true,
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('SalesPrice'),
            dataIndex: 'SalesPrice',
            width: '100',
            format: '000.00',
            align: 'right',
            xtype: 'numbercolumn',
            filter: {
                type: 'number'
            }
        },{
            text: tt('UnitsPerPackage'),
            dataIndex: 'UnitsPerPackage',
            width: 100,
            format: '000.00',
            align: 'right',
            filter: {
                type: 'number'
            },
            xtype: 'numbercolumn'
        },
        {
            text: tt('PreStartDate'),
            dataIndex: 'PreStartDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('PreEndDate'),
            dataIndex: 'PreEndDate',
            xtype: 'datecolumn',
            format: tt('d-m-Y'),
            width: '90',
            filter: {
                type: 'date'
            }
        },
        {
            text: tt('CreatedBy'),
            dataIndex: 'CreatedBy',
            width: '200',
            filter: {
                type: 'string'
            }
        },
        {
            text: tt('CreatedAt'),
            dataIndex: 'CreatedAt',
            width: '100',
            filter: {
                type: 'date'
            }
        },
    ]
})
`
