Ext.define('MDM.src.view.globalapp.Panel.Publish.MainController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.globalapp-publish-controller',
  /**
   * Called when the view is created
   */
  init: function () {},
  onActive: function () {
    var me = this
    this.getViewModel().getStore('MainStore').load()
  },
  onMainStoreLoad: function () {
    var grid = this.lookupReference('maingrid')
    grid.getSelectionModel().select(0)
  },

  onSelectMainGrid: function (grid, record) {
    var title = record.get('Title'),
      store = this.getViewModel().getStore('ChangeStore')

    store.getProxy().setExtraParam('title', title)
    store.load()
  },

  onCellclickDPI: function (view, cell, cellIndex, record, row, rowIndex, e) {
    var clickedDataIndex = view.panel.headerCt.getHeaderAtIndex(cellIndex).dataIndex
    var clickedColumnName = view.panel.headerCt.getHeaderAtIndex(cellIndex).text
    var clickedCellValue = record.get(clickedDataIndex)
    if (
      clickedColumnName == 'Prev' ||
      clickedColumnName == 'Current' ||
      clickedColumnName == 'Nex'
    ) {
      return
    }
    if (clickedCellValue < 1) return
    var title = record.get('Title')
    // this.getViewModel().set('record', record)
    var store = Ext.create('Ext.data.Store', {
      fields: ['ONAME', 'NNAME']
    })

    var win = Ext.create('Ext.window.Window', {
      height: 300,
      width: 300,
      modal: true,
      title: title + '-' + clickedColumnName,
      model: true,
      layout: 'fit',
      bodyPadding: 4,
      items: [
        {
          xtype: 'grid',
          border: true,
          store: store,
          columns: [
            {
              text: 'OLD',
              dataIndex: 'Old',
              flex: 1
            },
            {
              text: 'NEW',
              dataIndex: 'New',
              flex: 1
            }
          ]
        }
      ]
    })

    win.show()

    Ext.Ajax.request({
      method: 'POST',
      url: MDM.Global.getApiUrl() + 'gillettev3/Global_MDM_Publish_Change',
      timeout: 360000,
      params: {
        Title: title,
        DataIndex: clickedDataIndex
      },
      success: function (response, opts) {
        var data = Ext.JSON.decode(response.responseText)

        store.loadData(data)
        win.setLoading(false)
      },
      failure: function (response, opts) {
        Ext.MessageBox.hide()
      }
    })
  }
})
