import { DEFAULT_CUSTOM_ROW_INFO } from '../../../constants'
import { GetColumnHidden } from '../column.types'
import { getColumnHidden } from '../index'

const getColumnHiddenParams = (): GetColumnHidden => {
  return {
    firstRowData: {
      col1: false,
      _SLVY_AG_GRID_CUSTOM_ROW_INFO_: {
        ...DEFAULT_CUSTOM_ROW_INFO,
        _SLVY_AG_GRID_ROW_ID_: 'ABC123'
      }
    },
    hideByColumnSettings: false,
    hiddenCondition: '',
    isMaximized: false,
    showInMaxMode: false
  }
}

describe('AgGrid:Helpers:Column:getColumnHidden', () => {
  let params = getColumnHiddenParams()

  beforeEach(() => {
    params = getColumnHiddenParams()
  })

  it('should return false when hideByColumnSettings is false and no hiddenCondition and showInMaxMode is true and isMaximized is false', () => {
    const result = getColumnHidden(params)
    expect(result).toBe(false)
  })

  it('should return true when hideByColumnSettings is true and no hiddenCondition', () => {
    params.hideByColumnSettings = true
    const result = getColumnHidden(params)
    expect(result).toBe(true)
  })

  it('should return false when hideByColumnSettings is false and hiddenCondition is false and showInMaxMode is true and isMaximized is false', () => {
    params.hiddenCondition = 'col1'
    const result = getColumnHidden(params)
    expect(result).toBe(false)
  })

  it('should return true when hiddenCondition is true and showInMaxMode is true and isMaximized is false', () => {
    params.hiddenCondition = 'col1'
    params.firstRowData.col1 = true
    const result = getColumnHidden(params)
    expect(result).toBe(true)
  })

  it('should return false when hideByColumnSettings is false and hiddenCondition is defined but firstRowData is falsy and showInMaxMode is true and isMaximized is false', () => {
    params.hiddenCondition = 'col1'
    const { firstRowData, ...rest } = params
    const result = getColumnHidden(rest as GetColumnHidden)
    expect(result).toBe(false)
  })

  it('should return true when hiddenCondition is true and firstRowData is truthy and hiddenCondition exist in firstRowData and showInMaxMode is true and isMaximized is false', () => {
    params.hiddenCondition = 'col1'
    params.firstRowData.col1 = true
    const result = getColumnHidden(params)
    expect(result).toBe(true)
  })

  it('should return false when hideByColumnSettings is false and hiddenCondition is false and showInMaxMode is false and isMaximized is false', () => {
    params.hiddenCondition = 'col1'
    const result = getColumnHidden(params)
    expect(result).toBe(false)
  })

  it('should return false when hideByColumnSettings is false and hiddenCondition is false and showInMaxMode is true and isMaximized is true', () => {
    params.hiddenCondition = 'col1'
    params.showInMaxMode = true
    params.isMaximized = true
    const result = getColumnHidden(params)
    expect(result).toBe(false)
  })

  it('should return false when hideByColumnSettings is false and hiddenCondition is false and showInMaxMode is false and isMaximized is true', () => {
    params.hiddenCondition = 'col1'
    params.showInMaxMode = false
    params.isMaximized = true
    const result = getColumnHidden(params)
    expect(result).toBe(false)
  })
})
