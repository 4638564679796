import { useCallback } from 'react'
import { DeletedRow } from '../../types'
import { ResetDeletedRowsProps } from './useResetDeletedRows.types'

function useResetDeletedRows(props: ResetDeletedRowsProps) {
  const { gridRef, setDeletedRows } = props
  const resetDeletedRows = useCallback(() => {
    const deletedRows: DeletedRow[] = []
    const context = gridRef?.current?.api?.getGridOption('context')
    context.deletedRows = deletedRows
    gridRef?.current?.api?.setGridOption('context', context)
    setDeletedRows(deletedRows)
  }, [gridRef, setDeletedRows])
  return { resetDeletedRows }
}

export default useResetDeletedRows
