import React, { Component } from 'react'

export default class CustomPopover extends Component {
  render() {
    const { children, style = {}, className = '' } = this.props

    return (
      <div className={`-custom-popover ${className}`} style={style}>
        {children}
      </div>
    )
  }
}
