import { useState, useEffect, useMemo, ChangeEvent, FormEvent } from 'react'
import _ from 'lodash'
import {
  FormControl,
  FormLabel,
  FormCheck,
  Form,
  Button,
  FormGroup,
  FormSelect,
  Row,
  Col
} from 'react-bootstrap'
import { slvyToast } from '@/components'
import getAdminForm from '@/utils/admin-container'
import { defaultForm, containerSettings } from './constants'
import { AdminContainerProps } from '@/elements/Container/Admin/Admin.types'
import { FormState, HandleChangeCheckKey, HandleChangeFormKey } from './Admin.types'

const AdminContainer = (props: AdminContainerProps) => {
  const { propertyEditorFields, settings, onChangeSettings } = props

  const getInitialForm = useMemo(() => {
    return getAdminForm({ ...propertyEditorFields, ...containerSettings }, settings, defaultForm)
  }, [propertyEditorFields, settings])

  const [form, setForm] = useState<FormState>(getInitialForm)

  useEffect(() => {
    setForm(getInitialForm)
  }, [getInitialForm])

  const handleChangeForm = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    key: HandleChangeFormKey
  ) => {
    const {
      currentTarget: { value }
    } = event

    setForm((prevForm) => ({ ...prevForm, [key]: value }))
  }

  const handleCheck = (event: ChangeEvent<HTMLInputElement>, key: HandleChangeCheckKey) => {
    const {
      currentTarget: { checked = false }
    } = event

    setForm((prevForm) => ({ ...prevForm, [key]: checked }))
  }

  const getValidationState = () => {
    // TODO: add validation case
    return null
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!getValidationState()) {
      onChangeSettings({
        ...settings,
        ...form
      })
      slvyToast.success({ message: 'Saved successfully' })
    }
  }

  const { title, sizeType, height, sizeTypeWidth, width, fullPage, hideMode, confirmationMode } =
    form

  return (
    <Form noValidate className="containerForm" onSubmit={handleSubmit}>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm">Title</FormLabel>
        <FormControl
          size="sm"
          type="text"
          value={title}
          onChange={(event) => handleChangeForm(event, 'title')}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm d-block mb-1">Hide Mode</FormLabel>
        <FormCheck
          inline
          checked={hideMode === 'close'}
          className="mh-auto mb-0 form-check-sm"
          label="Close"
          name="hidemode"
          type="radio"
          value="close"
          onChange={(event) => handleChangeForm(event, 'hideMode')}
        />
        <FormCheck
          inline
          checked={hideMode === 'hide'}
          className="mh-auto mb-0 form-check-sm"
          label="Hide"
          name="hidemode"
          type="radio"
          value="hide"
          onChange={(event) => handleChangeForm(event, 'hideMode')}
        />
      </Form.Group>
      <Form.Group className="mb-2" controlId="hideOnClick">
        <Form.Check
          checked={confirmationMode}
          className="mb-0 form-check-sm"
          label={
            <>
              Confirmation Mode
              <small className="d-block text-muted fw-normal">
                If checked, popup will not close by clicking outside
              </small>
            </>
          }
          type="checkbox"
          onChange={(event) => handleCheck(event, 'confirmationMode')}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <FormLabel className="fs-sm mb-0">
          <FormCheck
            checked={fullPage === true}
            className="mh-auto mb-0 form-check-sm"
            id="fullPage"
            label="Full Page"
            type="checkbox"
            onChange={(event) => handleCheck(event, 'fullPage')}
          />
        </FormLabel>
      </Form.Group>
      {fullPage === false && (
        <>
          <Form.Group className="mb-2">
            <FormLabel className="fs-sm">Width</FormLabel>
            <FormGroup as={Row} className="g-2">
              <Col>
                <FormSelect
                  placeholder="select"
                  size="sm"
                  value={width}
                  onChange={(event) => handleChangeForm(event, 'width')}
                >
                  {_.times(10, (x) => {
                    const val = (x + 1) * (sizeTypeWidth === '%' ? 10 : 100)
                    return (
                      <option key={x} value={val}>
                        {val}
                      </option>
                    )
                  })}
                </FormSelect>
              </Col>
              <Col>
                <FormSelect
                  placeholder="select"
                  size="sm"
                  value={sizeTypeWidth}
                  onChange={(event) => handleChangeForm(event, 'sizeTypeWidth')}
                >
                  <option value="px">px</option>
                  <option value="%">%</option>
                </FormSelect>
              </Col>
            </FormGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <FormLabel className="fs-sm d-block">Height</FormLabel>
            <Form.Group as={Row} className="g-2">
              <Col>
                <FormSelect
                  placeholder="select"
                  size="sm"
                  value={height}
                  onChange={(event) => handleChangeForm(event, 'height')}
                >
                  {_.times(10, (x) => {
                    const val = (x + 1) * (sizeType === '%' ? 10 : 100)
                    return (
                      <option key={x} value={val}>
                        {val}
                      </option>
                    )
                  })}
                </FormSelect>
              </Col>
              <Col>
                <FormSelect
                  placeholder="select"
                  size="sm"
                  value={sizeType}
                  onChange={(event) => handleChangeForm(event, 'sizeType')}
                >
                  <option value="px">px</option>
                  <option value="%">%</option>
                </FormSelect>
              </Col>
            </Form.Group>
          </Form.Group>
        </>
      )}
      <Button size="sm" type="submit" variant="success">
        Save
      </Button>
    </Form>
  )
}

export default AdminContainer
