import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import cx from 'classnames'
import { getClosestFutureDate } from '../../utils'
import { setCurrentPeriod, setScrollLeft } from '../../store/slices/timelineSettings'

const selectFromState = (state) => ({
  currentPeriod: state.timelineSettings.currentPeriod,
  scrollLeft: state.timelineSettings.scrollLeft
})

function Timeline(props) {
  const dispatch = useDispatch()
  const timelineListRef = useRef(null)
  const { currentPeriod } = useSelector(selectFromState)
  const {
    data: { PeriodAggregations = {} },
    minimumStartDate = null,
    size: { width = 0 },
    timelineHandler = () => {},
    timelineSettings
  } = props

  let scrollObj = {
    xPos: 0,
    scrollLeft: 0,
    draggable: false
  }

  const [periodData, setPeriodData] = useState({})

  const { aggregationLevelId = 0, maxCount = 1, periods = [] } = currentPeriod

  useEffect(() => {
    let newCurrentPeriod = {}
    const newPeriodData = _.map(timelineSettings, (titem) => {
      const { map, isDefault, maxCount } = titem

      const periodItem = PeriodAggregations[map]

      const { StartTime: rootStartTime } = _.first(periodItem)

      const { StartTime: rootEndTime } = _.last(periodItem)

      const itemWidth = (width - 216) / maxCount

      const containerWidth = itemWidth * periodItem.length

      const retItem = {
        ...titem,
        periods: _.reduce(
          periodItem,
          (pArr, pItem, pIndex) => {
            /// Todo
            const isLast = pIndex === periodItem.length - 1

            const nextIndex = isLast ? 0 : pIndex + 1

            const { StartTime: EndTime } = periodItem[nextIndex]

            const { StartTime } = pItem

            const left = calculateX(
              moment(rootStartTime),
              moment(rootEndTime),
              moment(StartTime),
              containerWidth
            )

            const width =
              calculateX(
                moment(rootStartTime),
                moment(rootEndTime),
                moment(EndTime),
                containerWidth
              ) - left

            pItem = {
              ...pItem,
              Left: left,
              Width: isLast ? 2 : width
            }

            pArr.push(pItem)
            return pArr
          },
          []
        ),
        timelineSize: {
          timelineItemWidth: itemWidth,
          timelineContainerWidth: containerWidth
        }
      }

      if (isDefault) {
        newCurrentPeriod = retItem
      }
      return retItem
    })

    dispatch(setCurrentPeriod(newCurrentPeriod))
    setPeriodData(newPeriodData)
  }, [])

  useEffect(() => {
    timelineHandler(currentPeriod, () => {
      adjustScrollLeft()
    })
  }, [currentPeriod, periodData])

  const adjustScrollLeft = () => {
    if (periods.length) {
      const { closestIndex = null } = getClosestFutureDate(periods, 'StartTime', minimumStartDate)

      if (typeof closestIndex === 'number') {
        timelineListRef.current.scrollLeft = periods[closestIndex].Left

        dispatch(setScrollLeft(timelineListRef.current.scrollLeft))
      }
    }
  }

  const diff = (startTime, endTime) => {
    return moment(endTime).diff(moment(startTime), 'seconds')
  }

  const calculateX = (startTime, endTime, calculatedTime, endX) => {
    startTime = moment(startTime)
    endTime = moment(endTime)

    const startX = 0

    const totalSecond = diff(startTime, endTime)

    const calculatedSecond = diff(startTime, calculatedTime)

    return (calculatedSecond * (endX - startX)) / totalSecond + startX
  }

  const periodClick = (periodItem) => {
    dispatch(setCurrentPeriod(periodItem))
  }

  const timeSliderHandler = (e, type) => {
    e.preventDefault()
    const { clientX } = e

    switch (type) {
      case 'up':
      case 'leave':
        timelineListRef.current.scrollLeft = timelineListRef.current.scrollLeft
        scrollObj.draggable = false
        timelineListRef.current.classList.remove('on-drag')
        break

      case 'down':
        scrollObj.scrollLeft = timelineListRef.current.scrollLeft
        scrollObj.draggable = true
        scrollObj.xPos = clientX
        timelineListRef.current.classList.add('on-drag')
        break

      case 'move':
        if (scrollObj.draggable) {
          timelineListRef.current.scrollLeft = scrollObj.scrollLeft + scrollObj.xPos - clientX
          timelineListRef.current.classList.add('on-drag')
        }
        break
      default:
    }

    dispatch(setScrollLeft(timelineListRef.scrollLeft))
  }

  const timelineContainerWidth = ((width - 216) / maxCount) * periods.length
  return (
    <div className="time-container">
      <header className="timeline-header">
        <ul>
          {_.map(periodData, (periodItem, periodIndex) => {
            const { aggregationLevelId: agLevelId, map } = periodItem

            const isActive = agLevelId === aggregationLevelId

            return (
              <li
                key={periodIndex}
                className={cx({ active: isActive })}
                onClick={() => periodClick(periodItem)}
              >
                {map}
              </li>
            )
          })}
        </ul>
      </header>

      <div className="timeline-period-container">
        <main
          ref={timelineListRef}
          className="timeline-period-list"
          onMouseDown={(e) => {
            timeSliderHandler(e, 'down')
          }}
          onMouseLeave={(e) => {
            timeSliderHandler(e, 'leave')
          }}
          onMouseMove={(e) => {
            timeSliderHandler(e, 'move')
          }}
          onMouseUp={(e) => {
            timeSliderHandler(e, 'up')
          }}
        >
          <ul style={{ minWidth: timelineContainerWidth }}>
            {_.map(periods, (periodItem, periodIndex) => {
              const { Left, Name, Width } = periodItem

              return (
                <li
                  key={periodIndex}
                  className="-timeline-item-width"
                  style={{ left: Left, width: Width }}
                >
                  {Name}
                </li>
              )
            })}
          </ul>
        </main>
      </div>
    </div>
  )
}

export default Timeline
