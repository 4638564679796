/* global Ext */
import _ from 'lodash'
import { Mutex } from 'async-mutex'

export default (pivot) => {
  Ext.define(null, {
    override: 'Ext.pivot.matrix.Remote',
    extend: 'Ext.pivot.matrix.Base',
    delayedProcess: function (arg) {
      var me = this,
        matrix = me.serialize(),
        ret,
        params

      params = {
        keysSeparator: me.keysSeparator,
        grandTotalKey: me.grandTotalKey,
        /** Solvoyo Added Code */
        arguments: arg,
        /** ************************************************* */
        leftAxis: matrix.leftAxis,
        topAxis: matrix.topAxis,
        aggregate: matrix.aggregate
      }

      ret = me.fireEvent('beforerequest', me, params)

      if (ret !== false) {
        if (Ext.isFunction(me.onBeforeRequest)) {
          ret = me.onBeforeRequest(params)
        }
      }

      if (ret === false) {
        me.endProcess()
      } else {
        // do an Ajax call to the configured URL and fetch the results
        Ext.Ajax.request({
          url: me.url,
          /** Solvoyo Added Code prevented timeout */
          // timeout: me.timeout,
          /** ************************************************* */
          jsonData: params,
          callback: me.processRemoteResults,
          scope: me
        })
      }
    },
    processRemoteResults: function (options, success, response) {
      var me = this,
        exception = !success,
        data = Ext.JSON.decode(response.responseText, true),
        items,
        item,
        len,
        i
      /** Solvoyo Added Code       */
      // TODO check the cases if you cannot reload the data
      me.clearData()
      /** ************************************************* */

      if (success) {
        exception = !data || !data['success']
      }

      if (exception) {
        // handle exception
        me.fireEvent('requestexception', me, response)

        if (Ext.isFunction(me.onRequestException)) {
          me.onRequestException(response)
        }

        me.endProcess()
        return
      }

      items = Ext.Array.from(data.leftAxis || [])
      len = items.length
      for (i = 0; i < len; i++) {
        item = items[i]
        if (Ext.isObject(item)) {
          me.leftAxis.addItem(item)
        }
      }

      items = Ext.Array.from(data.topAxis || [])
      len = items.length
      for (i = 0; i < len; i++) {
        item = items[i]
        if (Ext.isObject(item)) {
          me.topAxis.addItem(item)
        }
      }

      items = Ext.Array.from(data.results || [])
      len = items.length
      for (i = 0; i < len; i++) {
        item = items[i]
        if (Ext.isObject(item)) {
          var result = me.results.add(item.leftKey || '', item.topKey || '')
          Ext.Object.each(item.values || {}, result.addValue, result)
        }
      }

      me.endProcess()
    },
    /** Solvoyo Added Code */
    reload: function (arg) {
      var me = this
      me.delayedProcess(arg)
    }
    /** ************************************************* */
  })

  Ext.define(null, {
    override: 'Ext.pivot.plugin.CellEditing',
    addColumnEditors: function (matrix, columns) {
      var len = columns.length

      var col

      var i

      for (i = 0; i < len; i++) {
        col = columns[i]

        // ***************** Solvoyo Added Code *****************
        const { pivot: { pivotProps = {}, handleEditorSpecialkey } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        if (col && col.dimension) {
          const aggregate = _.find(aggregates, {
            dataIndex: col.dimension.dataIndex
          })
          const {
            editing: {
              enabled: editingEnabled = false,
              minValue: editorMinValue,
              maxValue: editorMaxValue,
              editingStep = 1
            } = {}
          } = aggregate || {}
          if (editingEnabled) {
            const hideTrigger = editingStep === 1
            col.dimension.editor = {
              xtype: 'numberfield',
              decimalPrecision: 3,
              maxValue: editorMaxValue,
              minValue: editorMinValue,
              step: editingStep,
              selectOnFocus: true,
              keyNavEnabled: false,
              hideTrigger: hideTrigger,
              listeners: {
                change: function (field, value) {
                  if (value > field.maxValue) field.setValue(field.maxValue)
                  else if (value < field.minValue) {
                    field.setValue(field.minValue)
                  }
                },
                specialkey: function (field, e) {
                  handleEditorSpecialkey(field, e)
                  return true
                }
              }
            }
          }
        }
        // *******************************************************

        if (col.dimension && col.dimension.editor) {
          // it has an aggregate dimension assigned
          col.editor = Ext.clone(col.dimension.editor)
        }

        if (col.columns) {
          this.addColumnEditors(matrix, col.columns)
        }
      }
    }
  })

  Ext.define(null, {
    override: 'Ext.layout.container.Editor',
    calculate: function (ownerContext) {
      var me = this

      var owner = me.owner

      var autoSize = owner.autoSize

      var fieldWidth

      var fieldHeight

      if (autoSize === true) {
        autoSize = me.autoSizeDefault
      }

      // Calculate size of both Editor, and its owned Field
      if (autoSize) {
        fieldWidth = me.getDimension(owner, autoSize.width, 'getWidth', owner.width)
        fieldHeight = me.getDimension(owner, autoSize.height, 'getHeight', owner.height)
      }

      // ***************** Solvoyo Added Code *****************
      // Added null check
      if (ownerContext.childItems[0]) {
        // *******************************************************

        // Set Field size
        ownerContext.childItems[0].setSize(fieldWidth, fieldHeight)

        // Bypass validity checking. Container layouts should not usually set their owner's size.
        ownerContext.setWidth(fieldWidth)
        ownerContext.setHeight(fieldHeight)

        // This is a Container layout, so publish content size
        ownerContext.setContentSize(
          fieldWidth || owner.field.getWidth(),
          fieldHeight || owner.field.getHeight()
        )
      }
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.Grid',

    onMatrixAfterUpdate: function (matrix, changed) {
      var me = this
      // Solvoyo: Added mutex
      if (!me.mutex) {
        me.mutex = new Mutex()
      }
      me.mutex.acquire().then(function (release) {
        if (changed) {
          // if the structure of the left/top axis changed
          // then we need to reconfigure the grid
          me.refreshMatrixData(matrix, true)
        } else {
          me.refreshView()
        }
        release()
      })
    },

    getLeftAxisItem: function (record) {
      var dataSource = this.getFeatureDataSource()

      var info

      if (!record || !dataSource) {
        return null
      }

      // ***************** Solvoyo Added Code *****************
      if (this.matrix.viewLayoutType === 'tabular') {
        return this.getMatrix().leftAxis.items.getByKey(record.data.leftAxisKey)
      }
      // *******************************************************
      info = dataSource.storeInfo[record.internalId]

      return info ? this.getMatrix().leftAxis.items.getByKey(info.leftKey) : null
    }
  })

  Ext.define('Ext.pivot.update.RatioInteger', {
    extend: 'Ext.pivot.update.Base',

    alias: 'pivotupdate.ratioInteger',

    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }

      var dataIndex = this.getDataIndex()
      var records = result.records

      var value = parseFloat(this.getValue())

      if (isNaN(value)) {
        value = null
      }

      if (records) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}
        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })
        const { editing: { lockingCondition = '' } = {} } = aggregate || []
        const unlockedSum = _.reduce(
          records,
          (sum, record) => {
            sum += !lockingCondition || record.get(lockingCondition) > 0 ? record.get(dataIndex) : 0
            return sum
          },
          0
        )

        const unlockedCount = _.reduce(
          records,
          (sum, record) => {
            sum += !lockingCondition || record.get(lockingCondition) > 0 ? 1 : 0
            return sum
          },
          0
        )

        const lockedSum = _.reduce(
          records,
          (sum, record) => {
            sum += lockingCondition && record.get(lockingCondition) <= 0 ? record.get(dataIndex) : 0
            return sum
          },
          0
        )
        const count = records.length

        if (unlockedSum > 0 || unlockedCount > 0) {
          let distributedSum = 0
          if (aggregate.aggregator && aggregate.aggregator === 'avg') {
            _.forEach(records, (record) => {
              if (!lockingCondition || record.get(lockingCondition) > 0) {
                const recordValue = record.get(dataIndex)
                let recordNewValue = 0
                let distributedValue = 0
                if (unlockedSum === 0) {
                  distributedValue = Math.floor((value * count) / unlockedCount)
                  recordNewValue = distributedValue
                } else {
                  distributedValue = Math.floor(
                    (recordValue * (count * value - lockedSum)) / unlockedSum
                  )
                  recordNewValue = distributedValue
                }
                distributedSum += distributedValue

                record.set(dataIndex, recordNewValue)
              }
            })
          } else {
            _.forEach(records, (record) => {
              if (!lockingCondition || record.get(lockingCondition) > 0) {
                const recordValue = record.get(dataIndex)
                let recordNewValue = 0
                let distributedValue = 0
                if (unlockedSum === 0) {
                  distributedValue = Math.floor(value / unlockedCount)
                  recordNewValue = distributedValue
                } else {
                  distributedValue = Math.floor((recordValue * value) / unlockedSum)
                  recordNewValue = distributedValue
                }
                distributedSum += distributedValue

                record.set(dataIndex, recordNewValue)
              }
            })
          }

          let remaining = Math.floor(value - (distributedSum + lockedSum))

          _.forEach(records, (record) => {
            if (!lockingCondition || record.get(lockingCondition) > 0) {
              const recordValue = record.get(dataIndex)

              if (remaining > 0) {
                record.set(dataIndex, recordValue + 1)
                remaining--
              }
            }
          })
        }
      }
      this.fireEvent('update', this)
    }
  })

  Ext.define('Ext.pivot.update.Ratio', {
    extend: 'Ext.pivot.update.Base',

    alias: 'pivotupdate.ratio',

    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }
      var dataIndex = this.getDataIndex()
      var records = result.records

      var value = parseFloat(this.getValue())

      if (isNaN(value)) {
        value = null
      }
      if (records) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })

        const {
          editing: { lockingCondition = '' } = {},
          aggregator = '',
          customAggregator = ''
        } = aggregate || {}
        const unlockedSum = _.reduce(
          records,
          (sum, record) => {
            sum += !lockingCondition || record.get(lockingCondition) > 0 ? record.get(dataIndex) : 0
            return sum
          },
          0
        )
        const unlockedCount = _.reduce(
          records,
          (sum, record) => {
            sum += !lockingCondition || record.get(lockingCondition) > 0 ? 1 : 0
            return sum
          },
          0
        )
        const lockedSum = _.reduce(
          records,
          (sum, record) => {
            sum += lockingCondition && record.get(lockingCondition) <= 0 ? record.get(dataIndex) : 0
            return sum
          },
          0
        )
        const count = records.length
        if (unlockedSum > 0 || unlockedCount > 0) {
          if (_.size(customAggregator) > 0 && this.aggregator && unlockedCount > 0) {
            let aggValue = this.aggregator(records)
            let ratioNumber = aggValue === 0 ? 0 : value / aggValue
            if (ratioNumber !== 1) {
              _.forEach(records, (record) => {
                if (!lockingCondition || record.get(lockingCondition) > 0) {
                  const recordValue = record.get(dataIndex)
                  let recordNewValue = recordValue * ratioNumber
                  record.set(dataIndex, recordNewValue)
                }
              })
            }
          } else if (aggregator === 'avg') {
            _.forEach(records, (record) => {
              if (!lockingCondition || record.get(lockingCondition) > 0) {
                const recordValue = record.get(dataIndex)
                let recordNewValue = 0
                let distributedValue = 0
                if (unlockedSum === 0) {
                  distributedValue = (value * count) / unlockedCount
                  recordNewValue = distributedValue
                } else {
                  distributedValue = (recordValue * (count * value - lockedSum)) / unlockedSum
                  recordNewValue = distributedValue
                }
                record.set(dataIndex, recordNewValue)
              }
            })
          } else {
            _.forEach(records, (record) => {
              if (!lockingCondition || record.get(lockingCondition) > 0) {
                const recordValue = record.get(dataIndex)
                let recordNewValue = 0
                let distributedValue = 0
                if (unlockedSum === 0) {
                  distributedValue = value / unlockedCount
                  recordNewValue = distributedValue
                } else {
                  distributedValue = (recordValue * (value - lockedSum)) / unlockedSum

                  recordNewValue = distributedValue
                }
                record.set(dataIndex, recordNewValue)
              }
            })
          }
        }
      }

      this.fireEvent('update', this)
    }
  })

  Ext.define('Ext.pivot.update.IncreasePercentage', {
    extend: 'Ext.pivot.update.Base',

    alias: 'pivotupdate.increasePercentage',

    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }

      var dataIndex = this.getDataIndex()

      var value = parseFloat(this.getValue())

      var records = result.records

      var len

      var i

      var rec

      if (isNaN(value)) {
        value = null
      }

      if (records) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })
        const { editing: { lockingCondition = '' } = {} } = aggregate || {}
        len = records.length

        if (lockingCondition) {
          for (i = 0; i < len; i++) {
            rec = records[i]
            if (rec.get(lockingCondition) > 0) {
              rec.set(
                dataIndex,
                value === null ? null : rec.get(dataIndex) + (rec.get(dataIndex) * value) / 100
              )
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            rec = records[i]

            rec.set(
              dataIndex,
              value === null ? null : rec.get(dataIndex) + (rec.get(dataIndex) * value) / 100
            )
          }
        }
      }

      this.fireEvent('update', this)
    }
  })

  Ext.define('Ext.pivot.update.DecreasePercentage', {
    extend: 'Ext.pivot.update.Base',

    alias: 'pivotupdate.decreasePercentage',

    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }

      var dataIndex = this.getDataIndex()

      var value = parseFloat(this.getValue())

      var records = result.records

      var len

      var i

      var rec

      if (isNaN(value)) {
        value = null
      }

      if (records) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })
        const { editing: { lockingCondition = '' } = {} } = aggregate || {}
        len = records.length

        if (lockingCondition) {
          for (i = 0; i < len; i++) {
            rec = records[i]
            if (rec.get(lockingCondition) > 0) {
              rec.set(
                dataIndex,
                value === null ? null : rec.get(dataIndex) - (rec.get(dataIndex) * value) / 100
              )
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            rec = records[i]

            rec.set(
              dataIndex,
              value === null ? null : rec.get(dataIndex) - (rec.get(dataIndex) * value) / 100
            )
          }
        }
      }

      this.fireEvent('update', this)
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.update.Uniform',
    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }
      var dataIndex = this.getDataIndex()

      var records = result.records

      var value = parseFloat(this.getValue())

      var len

      var i

      var avg

      if (isNaN(value)) {
        value = null
      }

      if (records) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })
        const { editing: { lockingCondition = '' } = {} } = aggregate || {}

        if (lockingCondition) {
          const totalRecordCount = records.length
          // Get number of rows whose locking condition hold. We will use it to calculate the average
          len = _.reduce(
            records,
            (sum, record) => {
              sum += record.get(lockingCondition) > 0 ? 1 : 0
              return sum
            },
            0
          )
          if (len > 0) {
            avg = value === null ? null : value / len
            // Traverse all rows and set only those rows whose locking condition hold
            for (i = 0; i < totalRecordCount; i++) {
              if (records[i].get(lockingCondition) > 0) {
                records[i].set(dataIndex, avg)
              }
            }
          }
        } else {
          len = records.length
          if (len > 0) {
            avg = value === null ? null : value / len

            for (i = 0; i < len; i++) {
              records[i].set(dataIndex, avg)
            }
          }
        }
      }
      this.fireEvent('update', this)
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.update.Overwrite',
    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }

      var dataIndex = this.getDataIndex()

      var value = parseFloat(this.getValue())

      var records = result.records

      var len

      var i

      if (isNaN(value)) {
        value = null
      }

      if (records) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })
        const { editing: { lockingCondition = '' } = {} } = aggregate || {}

        len = records.length

        if (lockingCondition) {
          for (i = 0; i < len; i++) {
            if (records[i].get(lockingCondition) > 0) {
              records[i].set(dataIndex, value)
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            records[i].set(dataIndex, value)
          }
        }
      }

      this.fireEvent('update', this)
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.update.Increment',
    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }

      var dataIndex = this.getDataIndex()

      var value = parseFloat(this.getValue())

      var records = result.records

      var len

      var i

      var rec

      if (isNaN(value)) {
        value = null
      }

      if (records && value) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })
        const { editing: { lockingCondition = '' } = {} } = aggregate || {}

        len = records.length

        if (lockingCondition) {
          for (i = 0; i < len; i++) {
            rec = records[i]
            if (rec.get(lockingCondition) > 0) {
              rec.set(dataIndex, rec.get(dataIndex) + value)
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            rec = records[i]
            rec.set(dataIndex, rec.get(dataIndex) + value)
          }
        }
      }

      this.fireEvent('update', this)
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.update.Percentage',

    onUpdate: function (result, resolve, reject) {
      // This could fire asynchronously after we've been destroyed
      if (this.destroyed) {
        return
      }

      var dataIndex = this.getDataIndex()

      var value = parseFloat(this.getValue())

      var records = result.records

      var len

      var i

      var rec

      if (isNaN(value)) {
        value = null
      }

      if (records) {
        const { config: { matrix: { cmp: { pivotProps = {} } = {} } = {} } = {} } = this

        const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

        const aggregate = _.find(aggregates, {
          dataIndex: dataIndex
        })
        const { editing: { lockingCondition = '' } = {} } = aggregate || {}
        len = records.length

        if (lockingCondition) {
          for (i = 0; i < len; i++) {
            rec = records[i]
            if (rec.get(lockingCondition) > 0) {
              rec.set(
                dataIndex,
                value === null ? null : Math.floor((rec.get(dataIndex) * value) / 100)
              )
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            rec = records[i]
            rec.set(
              dataIndex,
              value === null ? null : Math.floor((rec.get(dataIndex) * value) / 100)
            )
          }
        }
      }

      this.fireEvent('update', this)
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.plugin.Exporter',

    saveDocumentAs: function (config) {
      // ***************** Solvoyo Added Code *****************
      const {
        cmp: {
          pivotProps: {
            userName = null,
            actualFilters = {},
            createLog,
            settings: { config: { general: { name = '' } = {} } = {} } = {}
          } = {}
        } = {}
      } = this

      const matrix = this.cmp.getMatrix()

      const {
        rowGrandTotalsPosition = 'none',
        rowSubTotalsPosition = 'none',
        colGrandTotalsPosition = 'none',
        colSubTotalsPosition = 'none',
        viewLayoutType = 'outline'
      } = matrix

      config = {
        ...config,
        userName: userName,
        pluginName: name,
        filters: actualFilters,
        createLog: createLog,
        rowGrandTotalsPosition,
        rowSubTotalsPosition,
        colGrandTotalsPosition,
        colSubTotalsPosition,
        viewLayoutType
      }
      // *******************************************************
      var cmp = this.cmp

      var deferred = new Ext.Deferred()

      var exporter = this.getExporter(config)

      cmp.fireEvent('beforedocumentsave', cmp, {
        config: config,
        exporter: exporter
      })

      this.delayedSaveTimer = Ext.asap(this.delayedSave, this, [exporter, config, deferred])
      return deferred.promise
    },

    prepareData: function (config) {
      var me = this

      var matrix

      var group

      var columns

      var headers

      var total

      var i

      var j

      var dLen

      var tLen

      var dataIndexes

      var row

      var value

      me.matrix = matrix = me.cmp.getMatrix()
      me.onlyExpandedNodes = config && config.onlyExpandedNodes

      if (!me.onlyExpandedNodes) {
        me.setColumnsExpanded(matrix.topAxis.getTree(), true)
      }

      columns = Ext.clone(matrix.getColumnHeaders())

      // ***************** Solvoyo Added Code *****************
      // EXTJS Bug Fix: Column sub totals are set as none, in the exported excel file
      // they are still visible.
      const { colSubTotalsPosition = 'none' } = matrix
      if (!me.onlyExpandedNodes && colSubTotalsPosition === 'none') {
        this.removeColSubtotals(columns)
      }
      // *******************************************************

      headers = me.getColumnHeaders(columns, config)
      dataIndexes = me.getDataIndexColumns(columns)

      if (!me.onlyExpandedNodes) {
        me.setColumnsExpanded(matrix.topAxis.getTree())
      }

      group = {
        columns: headers,
        groups: []
      }
      me.extractGroups(group, matrix.leftAxis.getTree(), dataIndexes)

      tLen = matrix.totals.length
      dLen = dataIndexes.length

      if (tLen) {
        group.summaries = []
        for (i = 0; i < tLen; i++) {
          total = matrix.totals[i]

          row = {
            cells: [
              {
                value: total.title
              }
            ]
          }

          for (j = 1; j < dLen; j++) {
            value = total.record.data[dataIndexes[j].dataIndex]
            row.cells.push({
              value: (value == null || value === 0) && matrix.showZeroAsBlank ? '' : value
            })
          }
          group.summaries.push(row)
        }
      }

      me.matrix = me.onlyExpandedNodes = null

      return new Ext.exporter.data.Table(group)
    },

    extractGroups: function (group, items, columns) {
      var i, j, iLen, cLen, doExtract, item, row, subGroup, record, value, cells
      iLen = items.length
      for (i = 0; i < iLen; i++) {
        item = items[i]
        if (item.record) {
          group.rows = group.rows || []
          cells = []
          row = {
            cells: cells
          }
          for (j = 0; j < columns.length; j++) {
            value = item.record.data[columns[j].dataIndex]
            cells.push({
              value: (value == null || value === 0) && this.matrix.showZeroAsBlank ? '' : value
            })
          }
          group.rows.push(row)
        } else if (item.children) {
          group.groups = group.groups || []
          subGroup = {
            text: item.name
          }
          doExtract = this.onlyExpandedNodes ? item.expanded : true
          if (doExtract) {
            this.extractGroups(subGroup, item.children, columns)
          }
          subGroup.summaries = []
          cells = [
            {
              value: doExtract ? item.getTextTotal() : item.value
            }
          ]
          row = {
            cells: cells
          }

          // ***************** Solvoyo Added Code *****************
          // Commented out the first line and adden the secod line instead of it.
          // If a row is expanded, records.expanded returns empty summary line data

          record = item.records.collapsed
          // *******************************************************

          cLen = columns.length
          for (j = 1; j < cLen; j++) {
            value = record.data[columns[j].dataIndex]
            cells.push({
              value: (value == null || value === 0) && this.matrix.showZeroAsBlank ? '' : value
            })
          }
          subGroup.summaries.push(row)
          group.groups.push(subGroup)
        }
      }
    },

    removeColSubtotals: function (columns) {
      _.remove(columns, (column) => {
        const { subTotal = null } = column
        return subTotal
      })

      _.forEach(columns, (column) => {
        if (column.columns) {
          this.removeColSubtotals(column.columns)
        }
      })
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.plugin.RangeEditor',

    showPanel: function (params, e, eOpts) {
      var me = this

      var grid = me.getGrid()

      var matrix = grid.getMatrix()

      var dataIndex

      var result

      var col

      var view

      // do nothing if the plugin is disabled
      if (me.disabled) {
        return
      }

      result = matrix.results.get(params.leftKey, params.topKey)

      if (!result) {
        return
      }

      // to keep compatibility with prior versions of this plugin
      me.currentCol = col = params.column
      me.currentRecords = result.records || []
      dataIndex = col.dimension.getId()
      me.currentValue = result.getValue(dataIndex)

      // ***************** Solvoyo Added Code *****************
      const { grid: { pivotProps = {} } = {} } = params
      const { query: { formattedFields = [] } = {}, config: { aggregate: aggregates = [] } = {} } =
        pivotProps.settings || {}
      const aggregate = _.find(aggregates, {
        dataIndex: params.column.dimension.dataIndex
      })

      const { editing: { enabled: editingEnabled = false, editableCondition = '' } = {} } =
        aggregate || {}

      const formattedField = _.find(formattedFields, {
        columnName: aggregate.dataIndex
      })

      const { formatString } = formattedField || {}
      // create the window that will show the records
      view = me.createPanel()

      // If aggregate is not editable do not display the range editor
      if (!editingEnabled) {
        return false
      }

      // The editability of this aggregation is based on a field
      if (editableCondition) {
        result = matrix.results.get(params.leftKey, params.topKey)

        // Get the max aggregation of the condition field
        const maxEditableConditionValue = _.reduce(
          result.records,
          (max, row) => {
            return row.data[editableCondition] > max ? row.data[editableCondition] : max
          },
          0
        )
        // The seleted cell is editable  if the max aggregation of the condition field is larger than zero
        // Do not display the editor if the cell is checkbox cell
        if (maxEditableConditionValue === 0) {
          return false
        }
      }

      // to change default value of disagg functions
      let defaultValue = result.getValue(dataIndex)
      if (me.defaultUpdater === 'percentage') {
        defaultValue = 100
      } else if (
        me.defaultUpdater === 'increment' ||
        me.defaultUpdater === 'increasePercentage' ||
        me.defaultUpdater === 'decreasePercentage'
      ) {
        defaultValue = 0
      } else {
        if (_.indexOf(formatString, '%') > -1) {
          defaultValue = defaultValue * 100
        }
      }

      // *******************************************************
      view
        .down('form')
        .getForm()
        .setValues({
          leftKey: params.leftKey,
          topKey: params.topKey,
          dataIndex: col.dimension.dataIndex,
          field: col.dimension.header || col.text || col.dimension.dataIndex,
          value: defaultValue,
          type: me.defaultUpdater
        })
      view.show()
      me.setView(view)
      grid.fireEvent('showrangeeditorpanel', view)
    },

    updateGrid: function (grid) {
      Ext.destroy(this.gridListeners)
      // ***************** Solvoyo Added Code *****************

      const {
        pivotProps: {
          settings: {
            config: {
              pivot: { editing: { clicksToEdit: pivotEditingClicksToEdit } = {} } = {}
            } = {}
          } = {}
        } = {}
      } = grid || {}

      if (grid) {
        if (pivotEditingClicksToEdit === 1) {
          this.gridListeners = grid.on({
            pivotitemcellclick: 'showPanel',
            pivotgroupcellclick: 'showPanel',
            pivottotalcellclick: 'showPanel',
            scope: this,
            destroyable: true
          })
        } else if (pivotEditingClicksToEdit === 2) {
          // *******************************************************
          this.gridListeners = grid.on({
            pivotitemcelldblclick: 'showPanel',
            pivotgroupcelldblclick: 'showPanel',
            pivottotalcelldblclick: 'showPanel',
            scope: this,
            destroyable: true
          })
        }
      }
    },
    createPanel: function () {
      var me = this

      var col

      col = me.currentCol

      const updaters = []

      // ***************** Solvoyo Added Code *****************
      const { grid: { pivotProps = {} } = {} } = me
      const { config: { aggregate: aggregates = [] } = {} } = pivotProps.settings || {}

      const aggregate = _.find(aggregates, {
        dataIndex: col.dimension.dataIndex
      })
      const { editing: { disaggregation = [], defaultUpdater = 'overwrite' } = {} } =
        aggregate || {}

      if (defaultUpdater === 'increasePercentage') {
        updaters.push([defaultUpdater, 'Percentage (Increase)'])
      } else if (defaultUpdater === 'decreasePercentage') {
        updaters.push([defaultUpdater, 'Percentage (Decrease)'])
      } else {
        updaters.push([
          defaultUpdater,
          defaultUpdater.charAt(0).toUpperCase() + defaultUpdater.slice(1)
        ])
      }

      me.defaultUpdater = defaultUpdater

      // updaters array will be appended on the 'store' field into Ext.window.Window below.

      _.map(disaggregation, (disagg) => {
        if (disagg !== defaultUpdater) {
          if (disagg === 'increasePercentage') {
            updaters.push([disagg, 'Percentage (Increase)'])
          } else if (disagg === 'decreasePercentage') {
            updaters.push([disagg, 'Percentage (Decrease)'])
          } else {
            updaters.push([disagg, disagg.charAt(0).toUpperCase() + disagg.slice(1)])
          }
        }
      })

      const newUpdaters = _.uniq(updaters)
      // *******************************************************

      return new Ext.window.Window({
        title: me.textWindowTitle,
        width: me.width,
        height: me.height,
        layout: 'fit',
        modal: true,
        closeAction: 'hide',
        items: [
          {
            xtype: 'form',
            padding: 5,
            border: false,
            defaults: {
              anchor: '100%'
            },
            items: [
              {
                fieldLabel: me.textFieldEdit,
                xtype: 'displayfield',
                name: 'field'
              },
              {
                fieldLabel: me.textFieldType,
                xtype: 'combo',
                name: 'type',
                queryMode: 'local',
                valueField: 'id',
                displayField: 'text',
                editable: false,
                store: newUpdaters
              },
              {
                fieldLabel: me.textFieldValue,
                xtype: 'numberfield',
                name: 'value'
              },
              {
                xtype: 'hidden',
                name: 'leftKey'
              },
              {
                xtype: 'hidden',
                name: 'topKey'
              },
              {
                xtype: 'hidden',
                name: 'dataIndex'
              }
            ]
          }
        ],
        buttons: [
          {
            text: me.textButtonOk,
            handler: me.updateRecords,
            scope: me
          },
          {
            text: me.textButtonCancel,
            handler: me.closePanel,
            scope: me
          }
        ],

        listeners: {
          close: 'onClosePanel',
          scope: me
        }
      })
    },
    updateRecords: function () {
      var me = this

      var view = me.getView()

      var values = view.down('form').getValues()

      var fn = Ext.bind(me.cleanUp, me)

      var updater

      // ***************** Solvoyo Added Code *****************
      const { grid: { pivotProps = {} } = {} } = me

      const { query: { formattedFields = [] } = {} } = pivotProps.settings || {}

      const formattedField = _.find(formattedFields, {
        columnName: values.dataIndex
      })

      const { formatString } = formattedField || {}
      if (_.indexOf(formatString, '%') > -1 && values) {
        values.value = values.value / 100
      }
      // *******************************************************

      values.matrix = me.getGrid().getMatrix()
      updater = Ext.Factory.pivotupdate(values)
      updater.on({
        beforeupdate: me.onBeforeUpdate,
        update: me.onUpdate,
        scope: me
      })
      updater.update().then(fn, fn)
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.Aggregators',

    min: function (records, measure, matrix, rowGroupKey, colGroupKey) {
      // Changed the start value of the min variable from null to Number.MAX_SAFE_INTEGER
      var length = records.length

      var min = Number.MAX_SAFE_INTEGER

      var i

      var item

      var compare

      // Excel works like this:
      // - if all values are null then min is null
      // - if all values are null except one that is a number then min is that number
      // - if all values are null except one that is a string then min is 0

      for (i = 0; i < length; i++) {
        item = records[i].get(measure)
        compare = true

        if (matrix.calculateAsExcel) {
          if (item !== null) {
            if (typeof item !== 'number') {
              item = 0
              compare = false
            }

            if (min === null) {
              min = item
            }
          } else {
            compare = false
          }
        }
        if (compare && item < min) {
          min = item
        }
      }
      return min
    }
  })

  Ext.define(null, {
    override: 'Ext.pivot.axis.Local',

    removeRecordsByItem: function (item) {
      var me = this

      var keys

      var i

      var results

      var toRemove

      // ***************** Solvoyo Added Code *****************
      // We commented out the lines below to increase filtering speed
      // if (item.children) {
      //   me.removeRecordsFromResults(item.children);
      // }
      // *******************************************************
      if (me.isLeftAxis) {
        toRemove = me.matrix.results.get(item.key, me.matrix.grandTotalKey)
        results = me.matrix.results.getByLeftKey(me.matrix.grandTotalKey)
      } else {
        toRemove = me.matrix.results.get(me.matrix.grandTotalKey, item.key)
        results = me.matrix.results.getByTopKey(me.matrix.grandTotalKey)
      }
      if (!toRemove) {
        return
      }

      // remove records from grand totals
      for (i = 0; i < results.length; i++) {
        me.removeItemsFromArray(results[i].records, toRemove.records)
      }

      keys = item.key.split(me.matrix.keysSeparator)
      keys.length = keys.length - 1

      while (keys.length > 0) {
        // remove records from parent groups
        if (me.isLeftAxis) {
          results = me.matrix.results.getByLeftKey(keys.join(me.matrix.keysSeparator))
        } else {
          results = me.matrix.results.getByTopKey(keys.join(me.matrix.keysSeparator))
        }

        for (i = 0; i < results.length; i++) {
          me.removeItemsFromArray(results[i].records, toRemove.records)
        }

        keys.length = keys.length - 1
      }
    }
  })
}
