import { Component } from 'react'
import * as _methods from '../methods'
import * as _events from '../events'

export default class RegisterEventMethod extends Component {
  UNSAFE_componentWillMount() {
    this.registerMethods()
    this.registerEvents()
  }

  registerMethods() {
    const { PluginTypes } = this.props
    const methods = [
      {
        key: 'updateExternal',
        fn: this.props.updateExternal.bind(this),
        args: [..._methods.getUpdateExternalArgs(PluginTypes)]
      },
      {
        key: 'reloadMFP',
        fn: this.props.onReloadViewClick.bind(this),
        args: []
      },
      {
        key: 'initial load',
        fn: this.props.resetPage.bind(this),
        args: []
      }
    ]
    methods.forEach(this.props.registerMethod)
  }

  registerEvents() {
    const { PluginTypes } = this.props
    const events = [
      {
        key: 'disableFilter',
        fn: this.disableFilter.bind(this),
        returnTypes: { ..._events.getDisableFilterTypes(PluginTypes) }
      },
      {
        key: 'scenarioLoaded',
        fn: this.scenarioLoaded.bind(this),
        returnTypes: { ..._events.getScenarioLoadedTypes(PluginTypes) }
      },
      {
        key: 'generateReport',
        fn: this.generateReport.bind(this),
        returnTypes: { ..._events.getGenerateReportTypes(PluginTypes) }
      },
      {
        key: 'externalUpdate',
        fn: this.externalUpdate.bind(this),
        returnTypes: { ..._events.getExternalUpdateTypes(PluginTypes) }
      },
      {
        key: 'engineUpdated',
        fn: this.props.engineUpdated.bind(this),
        returnTypes: { ..._events.getEngineUpdatedTypes(PluginTypes) }
      },
      {
        key: 'cellUpdated',
        fn: this.cellUpdated.bind(this),
        returnTypes: { ..._events.getCellUpdatedTypes(PluginTypes) }
      },
      {
        key: 'cellApproved',
        fn: this.cellApproved.bind(this),
        returnTypes: { ..._events.getCellApprovedTypes(PluginTypes) }
      },
      {
        key: 'rowClicked',
        fn: this.rowClicked.bind(this),
        returnTypes: { ..._events.getRowClickedTypes(PluginTypes) }
      },
      {
        key: 'periodUpdated',
        fn: this.periodUpdated.bind(this),
        returnTypes: { ..._events.getPeriodUpdatedTypes(PluginTypes) }
      },
      {
        key: 'viewChanged',
        fn: this.viewChanged.bind(this),
        returnTypes: { ..._events.getViewChangedTypes(PluginTypes) }
      },
      {
        key: 'discardClicked',
        fn: this.discardClicked.bind(this),
        returnTypes: { ..._events.getDiscardClicked(PluginTypes) }
      }
    ]
    events.forEach((event) => (this[event.key] = this.props.registerEvent(event)))
  }

  disableFilter(obj) {
    return obj
  }

  scenarioLoaded(obj) {
    return obj
  }

  generateReport(obj) {
    return obj
  }

  externalUpdate(obj) {
    return obj
  }

  cellUpdated({ LeftHierarchy = [], Status = false, MfpId = null }) {
    return {
      LeftHierarchy,
      Status,
      MfpId
    }
  }

  cellApproved({ ExecutionId = null, DataConnectionId = null, MfpId = null }) {
    return {
      ExecutionId,
      DataConnectionId,
      MfpId
    }
  }

  rowClicked(params) {
    return params
  }

  periodUpdated({ PeriodAggregationLevel = null }) {
    return {
      PeriodAggregationLevel
    }
  }

  viewChanged(params) {
    return params
  }

  discardClicked(obj) {
    return obj
  }

  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return this.props.children({
      cellApproved: this.cellApproved.bind(this),
      cellUpdated: this.cellUpdated.bind(this),
      disableFilter: this.disableFilter.bind(this),
      externalUpdate: this.externalUpdate.bind(this),
      generateReport: this.generateReport.bind(this),
      periodUpdated: this.periodUpdated.bind(this),
      rowClicked: this.rowClicked.bind(this),
      scenarioLoaded: this.scenarioLoaded.bind(this),
      viewChanged: this.viewChanged.bind(this),
      discardClicked: this.discardClicked.bind(this)
    })
  }
}
