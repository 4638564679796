export default `({
    xtype: 'tabpanel',
    tbar: [{
            xtype: 'button',
            bind: {
                text: '{record.WeekNumber}' + ' Haftayı Seç',
                hidden: '{record}'
            },
            itemId: 'SelectedWeekButton',
            handler: function (b) {
                var w = b.up('window'),
                    record = w.getViewModel().get('record')
                w.fireEvent('select', record);
                w.close();
            },
            setHidden: function (record) {
                if (!record.data) {
                    this.up().child('#SelectedWeekButton').hide()
                } else {
                    this.up().child('#SelectedWeekButton').show()
                }
            }
        },
        '->',
        {
            text: tt('Excel e Aktar'),
            handler: function (b) {
                                var grid = this.up('window').down('grid');
                grid.saveDocumentAs({
                    type: 'xlsx',
                    title: grid.title,
                    fileName: 'Spot Bilgileri' + '.xlsx'
                });
            }
        }
    ],
    items: [{
        flex: 1,
        title: tt('Spot Listesi'),
        xtype: 'uxcontainer',
        uxStoreCfg: 'chart113gridstore2',
        uxItemCfg: 'chart113grid2',
    }, {
        flex: 1,
        title: tt('Spot Grafigi'),
        xtype: 'uxcontainer',
        uxStoreCfg: 'chart113gridstore23',
        uxItemCfg: 'chart113grid23',
    }, ]
})
`
