import { SET_SCROLL_POS } from './actionTypes'

export function setScrollPos({ view, scrollArea, scrollPos }) {
  return {
    type: SET_SCROLL_POS,
    payload: {
      view,
      scrollArea,
      scrollPos
    }
  }
}
