Ext.define('Spui.view.tracking.leftside.grid.Columns', {
  singleton: true,
  config: {},
  columns: [
    { text: 'SpotId', dataIndex: 'SpotId', width: '100' },
    { text: 'MaterialCode', dataIndex: 'MaterialCode', width: '168' },
    { text: 'MaterialDescription', dataIndex: 'MaterialDescription', width: '200' },
    { text: 'MaterialGroupCode', dataIndex: 'MaterialGroupCode', width: '140' },
    { text: 'MaterialGroupName', dataIndex: 'MaterialGroupName', width: '200' },
    {
      text: 'SalesPrice',
      dataIndex: 'SalesPrice',
      width: '100',
      format: '000.00',
      align: 'right',
      xtype: 'numbercolumn'
    },
    { text: 'UnitofMeasurement', dataIndex: 'UnitofMeasurement', width: '168' },
    {
      text: 'UnitsPerPackage',
      dataIndex: 'UnitsPerPackage',
      width: '100',
      format: '000.00',
      align: 'right',
      xtype: 'numbercolumn'
    },
    {
      text: 'MinQuantity',
      dataIndex: 'MinQuantity',
      width: '100',
      format: '000.00',
      align: 'right',
      xtype: 'numbercolumn'
    },
    {
      text: 'MaxQuantity',
      dataIndex: 'MaxQuantity',
      width: '100',
      format: '000.00',
      align: 'right',
      xtype: 'numbercolumn'
    },
    { text: 'PreStartDate', dataIndex: 'PreStartDate', width: '100' },
    { text: 'PreEndDate', dataIndex: 'PreEndDate', width: '100' },
    { text: 'PageIndex', dataIndex: 'PageIndex', width: '100' },
    { text: 'PageName', dataIndex: 'PageName', width: '168' },
    { text: 'CreatedBy', dataIndex: 'CreatedBy', width: '200' },
    { text: 'CreatedAt', dataIndex: 'CreatedAt', width: '100' },
    { text: 'IsDeleted', dataIndex: 'IsDeleted', width: '100' },
    { text: 'DeletedBy', dataIndex: 'DeletedBy', width: '200' },
    { text: 'DeletedAt', dataIndex: 'DeletedAt', width: '100' },
    { text: 'CategoryManagerCode', dataIndex: 'CategoryManagerCode', width: '200' },
    { text: 'PuplishedBy', dataIndex: 'PuplishedBy', width: '200' },
    { text: 'PublishDate', dataIndex: 'PublishDate', width: '100' },
    { text: 'DaysForApprove', dataIndex: 'DaysForApprove', width: '100' },
    { text: 'DaysForTargetSales', dataIndex: 'DaysForTargetSales', width: '100' },
    { text: 'StatusId', dataIndex: 'StatusId', width: '100' },
    { text: 'ImgURL', dataIndex: 'ImgURL', width: '200' },
    { text: 'Notes', dataIndex: 'Notes', width: '200' },
    { text: 'Total', dataIndex: 'Total', width: '100' },
    { text: 'Waiting', dataIndex: 'Waiting', width: '100' },
    { text: 'Delegated', dataIndex: 'Delegated', width: '100' },
    { text: 'Approved', dataIndex: 'Approved', width: '100' },
    {
      text: 'FinalOneri_DC_UpdatedbyDC',
      dataIndex: 'FinalOneri_DC_UpdatedbyDC',
      width: '100',
      format: '000.00',
      align: 'right',
      xtype: 'numbercolumn'
    }
  ],
  constructor: function (config) {
    var me = this

    me.initConfig(config)
  },

  getColumns: function (gridType) {
    switch (gridType) {
      case 'MO_NOT_PUBLISHED':
        return this.get_Col_For_Mo_Not_Published()
        break

      default:
        break
    }
  },
  get_Col_For_Mo_Not_Published: function () {
    var definedColumns = [
      {
        xtype: 'rownumberer'
      },
      {
        text: 'id',
        dataIndex: 'SpotId',
        width: 50
      },

      {
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        locked: false,
        width: 30,
        align: 'center',
        items: [
          {
            iconCls: 'cell-editing-delete-row',
            filterField: {
              xtype: 'textfield'
            },
            handler: 'onClickDeleteSpot'
          }
        ]
      },
      {
        menuDisabled: true,
        sortable: false,
        xtype: 'actioncolumn',
        resizable: false,
        locked: false,
        width: 30,
        align: 'center',
        items: [
          {
            iconCls: 'fa fa-pencil',
            filterField: {
              xtype: 'textfield'
            },
            handler: 'onEditWizard'
          }
        ]
      }
    ]

    var columns = [
      { text: 'MaterialCode', dataIndex: 'MaterialCode', width: '168' },
      { text: 'MaterialDescription', dataIndex: 'MaterialDescription', width: '200' },
      { text: 'MaterialGroupCode', dataIndex: 'MaterialGroupCode', width: '140' },
      { text: 'MaterialGroupName', dataIndex: 'MaterialGroupName', width: '200' },
      {
        text: 'SalesPrice',
        dataIndex: 'SalesPrice',
        width: '100',
        format: '000.00',
        align: 'right',
        xtype: 'numbercolumn'
      },

      { text: 'PreStartDate', dataIndex: 'PreStartDate', width: '100' },
      { text: 'PreEndDate', dataIndex: 'PreEndDate', width: '100' },

      { text: 'CreatedBy', dataIndex: 'CreatedBy', width: '200' },
      { text: 'CreatedAt', dataIndex: 'CreatedAt', width: '100' }
    ]

    return definedColumns.concat(columns)
  }
})
