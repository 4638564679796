import _ from 'lodash'

const distinctColors = {
  1: ['#00a336'],
  2: ['#00a336', '#ee006a'],
  3: ['#ebe857', '#f2006b', '#005435'],
  4: ['#f0e812', '#009ad4', '#f30053', '#005230'],
  5: ['#c5a900', '#0093d5', '#f30053', '#00cebb', '#004a25'],
  6: ['#b8a200', '#cba0ff', '#0291c5', '#e30034', '#01cbb4', '#004a25'],
  7: ['#f2e392', '#c9a0ff', '#735e00', '#004e2b', '#f20047', '#01c3b0', '#0192c6'],
  8: ['#e9e5ad', '#e3004e', '#00bfae', '#004f2d', '#0192c6', '#e3ad00', '#c8a0ff', '#6d5a00'],
  9: [
    '#fadeb7',
    '#e0014f',
    '#1f7b00',
    '#c7a0ff',
    '#01c0b4',
    '#ebaf00',
    '#0192c8',
    '#004e31',
    '#6f5900'
  ],
  10: [
    '#f2e1b4',
    '#510f00',
    '#01c0b4',
    '#ff6676',
    '#197d00',
    '#c6a1ff',
    '#eeb300',
    '#0293c9',
    '#705a00',
    '#005032'
  ],
  11: [
    '#ebe3b3',
    '#ff4226',
    '#01bfb3',
    '#0194c8',
    '#117d00',
    '#c5a1ff',
    '#eab700',
    '#ff7092',
    '#005032',
    '#6f5b00',
    '#510f00'
  ],
  12: [
    '#cdecaf',
    '#d90072',
    '#107900',
    '#c3a0ff',
    '#ff341c',
    '#01bbaf',
    '#0093c7',
    '#d8aa00',
    '#005032',
    '#6f5b00',
    '#510f00',
    '#ffb79a'
  ],
  13: [
    '#c2efb2',
    '#f00013',
    '#00baae',
    '#0293c7',
    '#027700',
    '#ba006f',
    '#c2a0ff',
    '#c8a500',
    '#005032',
    '#6d5a00',
    '#ff9a5b',
    '#510f00',
    '#ffba9f'
  ],
  14: [
    '#cbebc4',
    '#f10015',
    '#01baaf',
    '#0293c7',
    '#007124',
    '#bc006f',
    '#5f8800',
    '#c2a0ff',
    '#d0a700',
    '#005033',
    '#6e5900',
    '#ff995b',
    '#510f00',
    '#ffb99f'
  ],
  15: [
    '#d0ebb9',
    '#f10013',
    '#00bfb1',
    '#01a4e3',
    '#007021',
    '#006b85',
    '#c3006b',
    '#004f31',
    '#5f8700',
    '#c4a0ff',
    '#cfa600',
    '#ff995b',
    '#6e5900',
    '#510f00',
    '#ffb99e'
  ],
  16: [
    '#d0ebb6',
    '#00168b',
    '#cfa500',
    '#5f8700',
    '#007120',
    '#f30011',
    '#01c0b1',
    '#00a5e3',
    '#ff995b',
    '#016c85',
    '#ca006a',
    '#6e5900',
    '#005031',
    '#c5a2ff',
    '#ffb99e',
    '#510f00'
  ],
  17: [
    '#a4f5af',
    '#00168b',
    '#caa000',
    '#5f8700',
    '#006e1c',
    '#f00011',
    '#00baab',
    '#00a2e0',
    '#ff9757',
    '#006c86',
    '#6e5900',
    '#c00060',
    '#004f30',
    '#f3e0bc',
    '#c5a1ff',
    '#ffb69c',
    '#510f00'
  ],
  18: [
    '#a5f5aa',
    '#00168b',
    '#c9a000',
    '#5f8700',
    '#f30008',
    '#01a2df',
    '#00baab',
    '#006e1c',
    '#006d86',
    '#ff9857',
    '#a10068',
    '#dc0056',
    '#005030',
    '#6e5a00',
    '#f3e0bc',
    '#c3a3ff',
    '#ffb69c',
    '#510f00'
  ],
  19: [
    '#abf4a2',
    '#00168b',
    '#c9a000',
    '#5f8700',
    '#f50b05',
    '#00a2dd',
    '#01baaa',
    '#006e1b',
    '#00044b',
    '#017189',
    '#ff9857',
    '#a30067',
    '#005030',
    '#d9004c',
    '#6e5a00',
    '#f3e0bc',
    '#c4a4ff',
    '#ffb69b',
    '#510f00'
  ],
  20: [
    '#aaf5a0',
    '#00168b',
    '#c8a000',
    '#5f8700',
    '#f82600',
    '#01a2dc',
    '#00baaa',
    '#006e1c',
    '#002571',
    '#017289',
    '#ff9a57',
    '#a2006c',
    '#6f5a00',
    '#005031',
    '#f3e0bc',
    '#c3a4ff',
    '#a8003e',
    '#ffb79b',
    '#ff708a',
    '#510f00'
  ],
  21: [
    '#acf599',
    '#00168b',
    '#c7a000',
    '#5e8700',
    '#ff2c06',
    '#00a1db',
    '#00baa9',
    '#006e1c',
    '#002a7a',
    '#00738b',
    '#ff9b58',
    '#a1006c',
    '#ff84e1',
    '#005131',
    '#6f5b00',
    '#b11d00',
    '#f3e1bc',
    '#c3a4ff',
    '#a8003e',
    '#ffb79a',
    '#510f00'
  ],
  22: [
    '#adf597',
    '#c251ff',
    '#5e8700',
    '#006e1b',
    '#c7a000',
    '#00168b',
    '#ff2d05',
    '#00a1d9',
    '#00b9a9',
    '#6f5b00',
    '#005131',
    '#00256e',
    '#01738b',
    '#ff9b58',
    '#a10069',
    '#b12400',
    '#ff8adb',
    '#c3a6ff',
    '#f3e1bc',
    '#a7003d',
    '#ffb79a',
    '#510f00'
  ],
  23: [
    '#00f69d',
    '#c151ff',
    '#5e8600',
    '#00168b',
    '#006b18',
    '#c29b00',
    '#fd2a04',
    '#a00069',
    '#dee997',
    '#ff80e1',
    '#a7003d',
    '#00a0d9',
    '#01b7a7',
    '#6f5b00',
    '#002570',
    '#b01e00',
    '#005131',
    '#00738b',
    '#ff9a57',
    '#510f00',
    '#c3a5ff',
    '#f3e0bd',
    '#ffb69a'
  ],
  24: [
    '#11ffa0',
    '#c252ff',
    '#00168b',
    '#5e8600',
    '#006c18',
    '#fe2c05',
    '#c39b00',
    '#a2006a',
    '#a9003e',
    '#013a95',
    '#dfe997',
    '#00a2da',
    '#b32000',
    '#01b8a8',
    '#6f5b00',
    '#005231',
    '#ff8fd4',
    '#00758d',
    '#ff9a57',
    '#510f00',
    '#00021c',
    '#c3a6ff',
    '#f3e0bd',
    '#ffb69a'
  ],
  25: [
    '#2dff9c',
    '#c353ff',
    '#00168b',
    '#5e8600',
    '#006d18',
    '#c39b00',
    '#ff2e05',
    '#a5006b',
    '#005edb',
    '#ab003e',
    '#dfe997',
    '#26002d',
    '#01a2db',
    '#01b8a8',
    '#b42600',
    '#6f5c00',
    '#005231',
    '#01778e',
    '#ff9a57',
    '#ff97d0',
    '#c3a6ff',
    '#510f00',
    '#00081c',
    '#f3e0bd',
    '#ffb69a'
  ],
  26: [
    '#21ff9b',
    '#c154ff',
    '#00178b',
    '#5e8600',
    '#006d18',
    '#a3006c',
    '#c39b00',
    '#dd0047',
    '#015bd1',
    '#fa3f00',
    '#a0003b',
    '#24002d',
    '#dfe998',
    '#6f5c00',
    '#00a2da',
    '#005231',
    '#00b8a8',
    '#ac3600',
    '#ff9b58',
    '#c3a6ff',
    '#510f00',
    '#00778e',
    '#00081c',
    '#ffa5dd',
    '#f3e0bd',
    '#ffb79a'
  ],
  27: [
    '#2dff99',
    '#c055ff',
    '#00178b',
    '#5e8600',
    '#006e19',
    '#a4006f',
    '#c39c00',
    '#de0047',
    '#005dd3',
    '#ff4200',
    '#aa0044',
    '#21002f',
    '#dfe998',
    '#00a2da',
    '#6f5c00',
    '#00b8a8',
    '#005332',
    '#5a0002',
    '#c03c00',
    '#ff9c58',
    '#01778f',
    '#c3a6ff',
    '#510f00',
    '#00091d',
    '#f3e0bd',
    '#ffb3e7',
    '#ffb79a'
  ],
  28: [
    '#22ff8b',
    '#c054ff',
    '#00178b',
    '#5e8600',
    '#a4006e',
    '#006c17',
    '#005bd4',
    '#de0047',
    '#c39c00',
    '#fe4200',
    '#aa0044',
    '#22002f',
    '#e0e996',
    '#6f5c00',
    '#009dd4',
    '#5a0002',
    '#c3a5ff',
    '#bf3d00',
    '#005231',
    '#00b4a2',
    '#ffa2db',
    '#510f00',
    '#ff9c58',
    '#00091d',
    '#00768e',
    '#adebff',
    '#f3e0bd',
    '#ffb799'
  ],
  29: [
    '#6bff83',
    '#c054ff',
    '#00178b',
    '#5e8600',
    '#015ede',
    '#006f19',
    '#c49c00',
    '#a4006e',
    '#ff4200',
    '#de0047',
    '#220030',
    '#ab0044',
    '#e0e897',
    '#715e00',
    '#009dd5',
    '#005432',
    '#01b4a3',
    '#c83b00',
    '#c3a5ff',
    '#5a0002',
    '#ff9c58',
    '#01778f',
    '#00081d',
    '#adebff',
    '#212300',
    '#ffa2d6',
    '#510f00',
    '#f3e0bc',
    '#ffb799'
  ],
  30: [
    '#6eff80',
    '#bf55ff',
    '#00178b',
    '#5e8600',
    '#007019',
    '#ac0076',
    '#0160d7',
    '#c49d00',
    '#de0047',
    '#ff4501',
    '#1d0031',
    '#b80047',
    '#78003f',
    '#e0e998',
    '#715e00',
    '#009dd4',
    '#005432',
    '#00b5a3',
    '#c3a5ff',
    '#c64300',
    '#590002',
    '#ff9c58',
    '#007890',
    '#00091d',
    '#212400',
    '#adebff',
    '#510f00',
    '#f3e0bc',
    '#ffaedc',
    '#ffb799'
  ],
  31: [
    '#00df81',
    '#bf55ff',
    '#c5f17d',
    '#5d8500',
    '#00178b',
    '#006c16',
    '#c29900',
    '#ab0076',
    '#de0047',
    '#ff4300',
    '#015fd7',
    '#b80046',
    '#715d00',
    '#019dd4',
    '#ebe59f',
    '#78003f',
    '#00b3a3',
    '#005432',
    '#1d0031',
    '#c64000',
    '#ff9b58',
    '#017790',
    '#adebff',
    '#212300',
    '#590002',
    '#c2a5ff',
    '#00091d',
    '#f4e0bd',
    '#510f00',
    '#ffa9db',
    '#ffb699'
  ],
  32: [
    '#01dc81',
    '#be55ff',
    '#c4f17e',
    '#5d8500',
    '#00178b',
    '#006c17',
    '#c19900',
    '#ab0076',
    '#de0048',
    '#005fd5',
    '#b70048',
    '#e13200',
    '#705d00',
    '#eae5a0',
    '#005432',
    '#77003f',
    '#1c0031',
    '#02b3a2',
    '#ff6435',
    '#009dd4',
    '#ff9d59',
    '#a93e00',
    '#202300',
    '#590002',
    '#c2a5ff',
    '#adebff',
    '#017890',
    '#f3e0bd',
    '#510f00',
    '#00091d',
    '#ffade2',
    '#ffb699'
  ],
  33: [
    '#49ffba',
    '#be55ff',
    '#01c968',
    '#c9f07e',
    '#5d8400',
    '#006710',
    '#00178b',
    '#c19900',
    '#ab0076',
    '#de0048',
    '#015fd7',
    '#e13200',
    '#b70048',
    '#715d00',
    '#ece59f',
    '#005332',
    '#77003f',
    '#ff6435',
    '#1d0031',
    '#009dd4',
    '#00b2a3',
    '#ff9d59',
    '#a93c00',
    '#590002',
    '#202200',
    '#c2a5ff',
    '#adebff',
    '#017790',
    '#f4e0bd',
    '#510f00',
    '#ffabe0',
    '#00091d',
    '#ffb699'
  ],
  34: [
    '#6fea1f',
    '#be55ff',
    '#00178b',
    '#0161e1',
    '#01ca68',
    '#ab0076',
    '#5e8400',
    '#caf080',
    '#00680e',
    '#1e0031',
    '#c29900',
    '#de0048',
    '#c2a5ff',
    '#b70047',
    '#78003f',
    '#e13200',
    '#00081d',
    '#ffa6d9',
    '#715d00',
    '#590002',
    '#005331',
    '#ece49f',
    '#ff6335',
    '#009ed6',
    '#01b4a5',
    '#ff9c59',
    '#510f00',
    '#aa3800',
    '#2a2400',
    '#017892',
    '#adebff',
    '#f4e0bd',
    '#01605c',
    '#ffb699'
  ],
  35: [
    '#a4d200',
    '#be55ff',
    '#00178b',
    '#00cb68',
    '#0163e8',
    '#5e8500',
    '#006b10',
    '#caef81',
    '#c39900',
    '#ab0076',
    '#1e0032',
    '#e13200',
    '#de0048',
    '#c2a5ff',
    '#725e00',
    '#b80048',
    '#ff6335',
    '#005633',
    '#ede5a0',
    '#790040',
    '#019ed6',
    '#00b5a6',
    '#112300',
    '#ff9c5a',
    '#ab3300',
    '#00071d',
    '#342400',
    '#007993',
    '#adebff',
    '#ffa5d7',
    '#f4e0bd',
    '#00706b',
    '#590002',
    '#ffb699',
    '#510f00'
  ],
  36: [
    '#b5c500',
    '#be54ff',
    '#00cb68',
    '#00178b',
    '#0063ee',
    '#006b0d',
    '#5e8500',
    '#caf081',
    '#c39900',
    '#ab0076',
    '#e13200',
    '#de0048',
    '#725e00',
    '#ff6335',
    '#b70047',
    '#1f0032',
    '#005633',
    '#eee49f',
    '#c3a5ff',
    '#ab3100',
    '#7a0040',
    '#ff9c59',
    '#00b4a5',
    '#009ed6',
    '#81f5ed',
    '#122300',
    '#00071d',
    '#372500',
    '#017994',
    '#adebff',
    '#f4e0bd',
    '#ff9ecf',
    '#590002',
    '#00514f',
    '#ffb699',
    '#510f00'
  ],
  37: [
    '#baca00',
    '#be54ff',
    '#00cb68',
    '#0167f9',
    '#00178c',
    '#006c0e',
    '#5e8500',
    '#cbf081',
    '#c49a00',
    '#e13200',
    '#ab0076',
    '#de0048',
    '#ff6235',
    '#200034',
    '#735f00',
    '#b80048',
    '#005633',
    '#eee49f',
    '#aa1c00',
    '#ff9c5a',
    '#c3a5ff',
    '#7c0040',
    '#009ed7',
    '#02b5a6',
    '#112400',
    '#8af4e9',
    '#453100',
    '#00061e',
    '#007a95',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#00605a',
    '#ff9ecd',
    '#030600',
    '#ffb699',
    '#510f00'
  ],
  38: [
    '#b9ca00',
    '#ba56ff',
    '#00cb68',
    '#00178b',
    '#0164eb',
    '#006d0e',
    '#5e8500',
    '#caf081',
    '#ff209b',
    '#c49a00',
    '#a50073',
    '#e13200',
    '#dd0047',
    '#1f0034',
    '#735f00',
    '#ff6433',
    '#b40043',
    '#005633',
    '#ede49f',
    '#c2a5ff',
    '#7a003f',
    '#019ed6',
    '#af3500',
    '#01b5a6',
    '#112400',
    '#ff9c59',
    '#8af4e9',
    '#453100',
    '#00061e',
    '#007a95',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#00605a',
    '#ffa3d0',
    '#010600',
    '#ffb699',
    '#510f00'
  ],
  39: [
    '#b5cc00',
    '#b956ff',
    '#00cb68',
    '#00178b',
    '#0164e8',
    '#006d0f',
    '#5e8500',
    '#caf081',
    '#ff209b',
    '#c39b00',
    '#a50073',
    '#e13200',
    '#dd0047',
    '#dc5900',
    '#1f0034',
    '#725f00',
    '#b30044',
    '#c2a5ff',
    '#ede59f',
    '#005633',
    '#79003f',
    '#009ed6',
    '#01b5a6',
    '#112400',
    '#ff9e5c',
    '#85f5eb',
    '#ff5d43',
    '#9e2500',
    '#00061e',
    '#443100',
    '#007a95',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#01615a',
    '#ffa6d7',
    '#000802',
    '#ffb699',
    '#510f00'
  ],
  40: [
    '#b0ce00',
    '#b856ff',
    '#00178b',
    '#01cb68',
    '#0162e3',
    '#006d0f',
    '#5e8500',
    '#caf081',
    '#ff1f9b',
    '#c29b00',
    '#a40073',
    '#1e0034',
    '#db0047',
    '#e13200',
    '#dc5900',
    '#725f00',
    '#c2a5ff',
    '#ac0041',
    '#ece59f',
    '#005633',
    '#009ed6',
    '#77003f',
    '#ff593c',
    '#80f5ed',
    '#ffa05b',
    '#112400',
    '#00b5a6',
    '#a14800',
    '#00061e',
    '#423000',
    '#ff7070',
    '#007a96',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#00615a',
    '#ffaee4',
    '#000b06',
    '#ffb79a',
    '#510f00'
  ],
  41: [
    '#b4cd00',
    '#b956ff',
    '#0066f9',
    '#00cb68',
    '#00178c',
    '#006d0d',
    '#5e8500',
    '#ff1e9b',
    '#caf081',
    '#c39b00',
    '#e13200',
    '#dc5900',
    '#a40072',
    '#db0047',
    '#200033',
    '#735f00',
    '#ff593c',
    '#ac0041',
    '#005632',
    '#c2a5ff',
    '#ece59f',
    '#ff9f5b',
    '#a24900',
    '#00a0d9',
    '#78003f',
    '#00b6a6',
    '#122400',
    '#92f4e5',
    '#00051d',
    '#453200',
    '#007062',
    '#007e9a',
    '#ff7070',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#004d68',
    '#ffabdd',
    '#100900',
    '#ffb799',
    '#510f00'
  ],
  42: [
    '#a1ae00',
    '#b955ff',
    '#00c866',
    '#d2ef5e',
    '#00178c',
    '#0166f9',
    '#006b0b',
    '#5d8400',
    '#ff1e9b',
    '#c19900',
    '#caee97',
    '#e13200',
    '#dc5900',
    '#a40072',
    '#db0047',
    '#725f00',
    '#210033',
    '#ff583b',
    '#005632',
    '#ff9f5b',
    '#ac0041',
    '#a14400',
    '#f0e39f',
    '#019fd9',
    '#01b4a5',
    '#122400',
    '#78003f',
    '#86f5ec',
    '#443100',
    '#00051d',
    '#c2a5ff',
    '#007e9a',
    '#016b5e',
    '#ff6f70',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#ffa9de',
    '#004c68',
    '#100900',
    '#ffb799',
    '#510f00'
  ],
  43: [
    '#a1ab00',
    '#b955ff',
    '#00c764',
    '#d4ee5e',
    '#0165fc',
    '#00178b',
    '#006907',
    '#5e8400',
    '#ff1e9b',
    '#c19800',
    '#e13200',
    '#caee97',
    '#dc5900',
    '#a40072',
    '#db0047',
    '#00d9c1',
    '#725e00',
    '#210032',
    '#ff583b',
    '#ff9e5b',
    '#a14300',
    '#005632',
    '#ac0041',
    '#f2e39e',
    '#009fd9',
    '#79003f',
    '#122400',
    '#00ada0',
    '#453100',
    '#00051d',
    '#c2a5ff',
    '#9df1e9',
    '#007e9a',
    '#ff6f70',
    '#016459',
    '#adebff',
    '#f4e0bd',
    '#ffa6db',
    '#590002',
    '#004b67',
    '#ffb699',
    '#120800',
    '#510f00'
  ],
  44: [
    '#a0ad00',
    '#b855ff',
    '#01c764',
    '#d3ef5e',
    '#00178c',
    '#0065f8',
    '#006908',
    '#5e8400',
    '#ff1f9c',
    '#c19800',
    '#caee97',
    '#e13200',
    '#dc5900',
    '#a30073',
    '#dd004b',
    '#00d9c1',
    '#725f00',
    '#200032',
    '#ff653d',
    '#c90028',
    '#005632',
    '#ffa05c',
    '#ab0043',
    '#a04a00',
    '#f1e39f',
    '#009fd8',
    '#76003f',
    '#122400',
    '#00aea0',
    '#443100',
    '#00051d',
    '#c2a5ff',
    '#99f2ea',
    '#007e9a',
    '#adebff',
    '#006459',
    '#ff7171',
    '#f4e0bd',
    '#590002',
    '#ffaae0',
    '#004c67',
    '#100800',
    '#ffb799',
    '#510f00'
  ],
  45: [
    '#acb100',
    '#b855ff',
    '#00c864',
    '#d4ee60',
    '#0065fc',
    '#00178c',
    '#006b03',
    '#5e8400',
    '#ff1f9c',
    '#c29900',
    '#019048',
    '#e13200',
    '#caee97',
    '#dc5900',
    '#a30073',
    '#dd004b',
    '#00d9c1',
    '#735f00',
    '#ff643d',
    '#00532a',
    '#210033',
    '#c90028',
    '#ff9f5c',
    '#a14800',
    '#ab0043',
    '#f1e39e',
    '#019fda',
    '#77003f',
    '#132400',
    '#01b0a3',
    '#94f2ee',
    '#c2a5ff',
    '#00614f',
    '#00051d',
    '#463000',
    '#007e9b',
    '#ff7171',
    '#adebff',
    '#f4e0bd',
    '#ffa8de',
    '#590002',
    '#004b6a',
    '#ffb799',
    '#120700',
    '#510f00'
  ],
  46: [
    '#aab000',
    '#b856ff',
    '#01c864',
    '#d4ee60',
    '#00178c',
    '#ff66f9',
    '#006b02',
    '#5e8400',
    '#ff209c',
    '#0163e6',
    '#c29900',
    '#00934e',
    '#caee97',
    '#a50074',
    '#dd004b',
    '#e13200',
    '#dc5900',
    '#00d9c1',
    '#735f00',
    '#1e0033',
    '#00532a',
    '#ca0028',
    '#ad0040',
    '#f1e39f',
    '#ff653c',
    '#009fda',
    '#ffa05c',
    '#a44e00',
    '#132400',
    '#710036',
    '#02b1a3',
    '#95f2ee',
    '#453000',
    '#c2a5ff',
    '#00051d',
    '#00614f',
    '#007e9c',
    '#ff7171',
    '#adebff',
    '#f4e0bd',
    '#590001',
    '#004c6a',
    '#100800',
    '#ffb799',
    '#ffb3df',
    '#510f00'
  ],
  47: [
    '#a7b000',
    '#b756ff',
    '#00c864',
    '#f03df0',
    '#d4ef60',
    '#006c03',
    '#00178c',
    '#5e8400',
    '#00924d',
    '#ff1e9c',
    '#0162e1',
    '#c19900',
    '#caee97',
    '#00d9c1',
    '#a40074',
    '#00532a',
    '#dc004a',
    '#e13200',
    '#dc5900',
    '#735f00',
    '#1e0032',
    '#c90028',
    '#02b1a3',
    '#a9003e',
    '#132400',
    '#f0e39f',
    '#ff6439',
    '#00614f',
    '#019fd9',
    '#ffa15a',
    '#a15100',
    '#8ff3f0',
    '#710036',
    '#c1a5ff',
    '#443000',
    '#00051d',
    '#ff6a69',
    '#007e9c',
    '#adebff',
    '#f4e0bd',
    '#0f0800',
    '#590001',
    '#004c6a',
    '#ff8284',
    '#ffb899',
    '#ffbef3',
    '#510f00'
  ],
  48: [
    '#a9b200',
    '#b755ff',
    '#01c964',
    '#f441f3',
    '#d5ee61',
    '#116a00',
    '#00178c',
    '#5e8400',
    '#ff1e9c',
    '#0262e4',
    '#c19900',
    '#caee97',
    '#019c73',
    '#00d9c1',
    '#a40074',
    '#2b4600',
    '#005129',
    '#dc004a',
    '#e13200',
    '#dc5900',
    '#735f00',
    '#c90028',
    '#1e0032',
    '#00b6af',
    '#a9003e',
    '#132400',
    '#f1e39e',
    '#ff6439',
    '#00604e',
    '#019fd9',
    '#ffa05a',
    '#a35000',
    '#710036',
    '#c2a5ff',
    '#aaefe7',
    '#463000',
    '#00051d',
    '#ff6a69',
    '#017e9c',
    '#adebff',
    '#f4e0bd',
    '#590001',
    '#110700',
    '#004b6a',
    '#ff8284',
    '#ffbbef',
    '#ffb799',
    '#510f00'
  ],
  49: [
    '#95a400',
    '#b755ff',
    '#00c964',
    '#f03cef',
    '#c9f162',
    '#00178c',
    '#0b6800',
    '#5d8400',
    '#ff1e9c',
    '#0162e6',
    '#ffdc59',
    '#bb9200',
    '#caee97',
    '#01d9c1',
    '#009c73',
    '#a40074',
    '#e13200',
    '#005129',
    '#dc004a',
    '#dc5900',
    '#1e0032',
    '#725e00',
    '#c90028',
    '#00b6af',
    '#a9003e',
    '#ff6238',
    '#132400',
    '#ece4a5',
    '#00604e',
    '#009fd9',
    '#ff9f5a',
    '#a04c00',
    '#710036',
    '#8cf3f4',
    '#003328',
    '#00051d',
    '#c1a4ff',
    '#452f00',
    '#ff6a69',
    '#007e9c',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#120500',
    '#004b6b',
    '#ff8284',
    '#ffb9ee',
    '#ffb79a',
    '#510f00'
  ],
  50: [
    '#96a300',
    '#b552ff',
    '#00c864',
    '#d412d7',
    '#c8f162',
    '#00178b',
    '#0c6900',
    '#5d8400',
    '#ffdc58',
    '#ff1c9c',
    '#0154ca',
    '#bb9200',
    '#caee97',
    '#01d9c1',
    '#009c73',
    '#a40072',
    '#dc004a',
    '#005129',
    '#203b00',
    '#725e00',
    '#1f0031',
    '#dc5900',
    '#e13200',
    '#c90028',
    '#01b5ae',
    '#ece4a4',
    '#a9003d',
    '#009ed7',
    '#132400',
    '#a04c00',
    '#93f2f0',
    '#ff9f5a',
    '#00604e',
    '#ff6238',
    '#710036',
    '#bea3ff',
    '#452f00',
    '#00051d',
    '#ff6a69',
    '#007e9b',
    '#eeb5ff',
    '#adebff',
    '#f4e0bd',
    '#590002',
    '#004b69',
    '#ff8284',
    '#110600',
    '#ffb799',
    '#510f00',
    '#ffb6e0'
  ],
  51: [
    '#8f9600',
    '#b551ff',
    '#c6f25f',
    '#01c863',
    '#c500cb',
    '#00178b',
    '#0f6900',
    '#ffdd54',
    '#5d8400',
    '#ff1c9b',
    '#0152c8',
    '#bb9100',
    '#caee97',
    '#00d9c1',
    '#a40072',
    '#019b72',
    '#dc004a',
    '#1c3900',
    '#725e00',
    '#1f0031',
    '#005129',
    '#dc5900',
    '#e13200',
    '#00d8fb',
    '#c90028',
    '#eee4a1',
    '#a9003d',
    '#00b4ac',
    '#132400',
    '#019dd6',
    '#a04b00',
    '#ff9e59',
    '#ff6238',
    '#710036',
    '#bea3ff',
    '#005f4e',
    '#00051d',
    '#452e00',
    '#ff6a69',
    '#007d9b',
    '#efb3ff',
    '#adebff',
    '#f4e0bc',
    '#590002',
    '#ffa7d9',
    '#004a67',
    '#120600',
    '#ff8284',
    '#ffb698',
    '#510f00',
    '#dbe4e2'
  ],
  52: [
    '#8f9700',
    '#af54ff',
    '#c6f25f',
    '#01c863',
    '#ff02c5',
    '#a000bc',
    '#00178b',
    '#ffdd53',
    '#0e6900',
    '#5d8400',
    '#ff1c9a',
    '#0151c2',
    '#bb9200',
    '#caef97',
    '#00d9c1',
    '#a20071',
    '#019b72',
    '#dc004a',
    '#1e3a00',
    '#1e0031',
    '#725e00',
    '#005129',
    '#dc5900',
    '#c90028',
    '#e13200',
    '#00d8fa',
    '#00b4ac',
    '#eee4a1',
    '#a8003c',
    '#a04c00',
    '#019dd5',
    '#ff9e59',
    '#132400',
    '#005f4e',
    '#ff6237',
    '#710036',
    '#bea3ff',
    '#00051d',
    '#452f00',
    '#ff6a69',
    '#007d9b',
    '#edb4ff',
    '#adebff',
    '#f4e0bc',
    '#590001',
    '#004a67',
    '#ffaad9',
    '#ff8284',
    '#120700',
    '#ffb698',
    '#510f00',
    '#dae4e2'
  ],
  53: [
    '#919500',
    '#af54ff',
    '#009d13',
    '#a300be',
    '#ff02c5',
    '#01ca67',
    '#cef065',
    '#00178b',
    '#ffdb56',
    '#5f8200',
    '#ff1c9a',
    '#206000',
    '#bc9200',
    '#0051c5',
    '#caee97',
    '#00d9c1',
    '#a20071',
    '#019a74',
    '#dc004a',
    '#725e00',
    '#005129',
    '#dc5900',
    '#c90028',
    '#e13200',
    '#01dafe',
    '#1f0031',
    '#02b4ad',
    '#efe4a1',
    '#bea3ff',
    '#a8003c',
    '#009dd5',
    '#a04a00',
    '#ff9e59',
    '#edb4ff',
    '#132400',
    '#005f4e',
    '#ff6137',
    '#710036',
    '#452e00',
    '#003229',
    '#ff6a69',
    '#00051d',
    '#ffa8d8',
    '#017d9b',
    '#adebff',
    '#f4e0bc',
    '#590001',
    '#004a68',
    '#ff8284',
    '#ffb698',
    '#140600',
    '#510f00',
    '#dbe4e2'
  ],
  54: [
    '#929500',
    '#303ee6',
    '#009d13',
    '#cef065',
    '#b300c1',
    '#bf5aff',
    '#ff02c5',
    '#01ca67',
    '#ffdb56',
    '#00178b',
    '#5f8200',
    '#bc9200',
    '#ff1d99',
    '#caee97',
    '#206000',
    '#dc5900',
    '#e13200',
    '#0151bd',
    '#00d9c1',
    '#a3006f',
    '#725e00',
    '#efe4a1',
    '#ff9e59',
    '#009b74',
    '#ff6137',
    '#dc004a',
    '#a14b00',
    '#01dbfe',
    '#005129',
    '#c90028',
    '#01b4ad',
    '#1f002f',
    '#bfa4ff',
    '#a8003c',
    '#009dd5',
    '#f4e0bc',
    '#005f4e',
    '#edb7ff',
    '#710036',
    '#132400',
    '#452e00',
    '#ffb698',
    '#003227',
    '#ff6a69',
    '#00051d',
    '#007d9b',
    '#ffa9d7',
    '#adebff',
    '#590001',
    '#dbe4e2',
    '#510f00',
    '#004a67',
    '#ff8284',
    '#140700'
  ],
  55: [
    '#929800',
    '#2e3fe7',
    '#009d14',
    '#cdf065',
    '#be5aff',
    '#ff02c5',
    '#01ca67',
    '#ffdc56',
    '#9500ad',
    '#00178b',
    '#5f8200',
    '#bc9200',
    '#ff1d9a',
    '#caee97',
    '#1f6000',
    '#dc5900',
    '#e13200',
    '#00d9c1',
    '#0152be',
    '#a20070',
    '#725e00',
    '#eee4a1',
    '#ffa05b',
    '#009b74',
    '#ff6e3e',
    '#dc004a',
    '#9e4f00',
    '#b21800',
    '#01dbfe',
    '#005129',
    '#cb002c',
    '#1f0030',
    '#01b4ad',
    '#a8003e',
    '#bfa4ff',
    '#019dd5',
    '#f4e0bc',
    '#005f4e',
    '#710036',
    '#ecb7ff',
    '#132400',
    '#442e00',
    '#ffb799',
    '#003327',
    '#00051d',
    '#ff6b6a',
    '#007d9b',
    '#ffaad9',
    '#adebff',
    '#590001',
    '#dae5e2',
    '#510f00',
    '#004b67',
    '#ff8284',
    '#130700'
  ],
  56: [
    '#8f9200',
    '#2f3ee6',
    '#009d13',
    '#cdf065',
    '#be59ff',
    '#ff02c5',
    '#00ca67',
    '#ffdd54',
    '#00178b',
    '#5f8200',
    '#bc9200',
    '#800095',
    '#ff1c9a',
    '#caef97',
    '#206100',
    '#dc5900',
    '#e13200',
    '#0051bc',
    '#00d9c1',
    '#a3006f',
    '#725e00',
    '#eee4a0',
    '#ff9f5a',
    '#019a74',
    '#ff6e3d',
    '#dc004a',
    '#9f4f00',
    '#b21800',
    '#00512a',
    '#cb002c',
    '#02cdea',
    '#1e002f',
    '#00b4ac',
    '#ff8ed1',
    '#bfa4ff',
    '#a8003e',
    '#f4e0bc',
    '#019dd4',
    '#005f4e',
    '#ecb5ff',
    '#710036',
    '#132400',
    '#432e00',
    '#003325',
    '#ffb698',
    '#00051d',
    '#ff6b6a',
    '#d2e7dc',
    '#017d9b',
    '#adebff',
    '#590001',
    '#510f00',
    '#004a67',
    '#ff8284',
    '#120600',
    '#f2dafe'
  ],
  57: [
    '#969800',
    '#313ee6',
    '#00a60f',
    '#be59ff',
    '#ff02c5',
    '#cfef67',
    '#01cb67',
    '#ffdc57',
    '#830098',
    '#00178b',
    '#608300',
    '#bd9300',
    '#ff1c9a',
    '#006700',
    '#caee97',
    '#dc5900',
    '#0151be',
    '#e13200',
    '#a30070',
    '#00d9c1',
    '#735e00',
    '#eee4a1',
    '#324c00',
    '#ff9f5a',
    '#009b75',
    '#ff6d3d',
    '#dc004a',
    '#a04f00',
    '#b21800',
    '#cb002c',
    '#bfa4ff',
    '#00522c',
    '#ff8ed0',
    '#01d2f1',
    '#01b5ad',
    '#1f0030',
    '#a8003e',
    '#ecb5ff',
    '#009dd5',
    '#f4e0bc',
    '#710036',
    '#00604e',
    '#132300',
    '#432c00',
    '#ffb698',
    '#ff6b6a',
    '#00041d',
    '#017e9b',
    '#d3e7dc',
    '#adebff',
    '#003532',
    '#590001',
    '#ff8284',
    '#004b68',
    '#510f00',
    '#f4d9fd',
    '#130600'
  ],
  58: [
    '#959700',
    '#323ce4',
    '#01a50f',
    '#bd58ff',
    '#ff02c5',
    '#cfef67',
    '#01ca67',
    '#ffdc56',
    '#00178b',
    '#608300',
    '#bd9300',
    '#ff1b99',
    '#006700',
    '#caee97',
    '#74007c',
    '#ff75da',
    '#dc5900',
    '#e13200',
    '#a2006e',
    '#0147ab',
    '#00d9c1',
    '#eee4a0',
    '#735e00',
    '#324c00',
    '#ff9f5a',
    '#009b75',
    '#ff6d3d',
    '#dc004a',
    '#a15000',
    '#b31800',
    '#cb002c',
    '#bfa4ff',
    '#00522c',
    '#00d1ed',
    '#02b5ad',
    '#1f002f',
    '#a8003d',
    '#ddb4ff',
    '#f4e0bc',
    '#009cd3',
    '#ffabe7',
    '#710036',
    '#00604e',
    '#132300',
    '#432c00',
    '#ffb698',
    '#ff6a6a',
    '#d1e8db',
    '#00051d',
    '#017d9b',
    '#003530',
    '#adebff',
    '#590001',
    '#ff8284',
    '#004b67',
    '#510f00',
    '#130600',
    '#f2dbf9'
  ],
  59: [
    '#a7c100',
    '#333ce3',
    '#00a515',
    '#bd58ff',
    '#ff02c5',
    '#d0ef6b',
    '#01ca68',
    '#ffdb5c',
    '#01178b',
    '#c39600',
    '#5f8300',
    '#ff1b99',
    '#006806',
    '#caee97',
    '#730078',
    '#7c7400',
    '#ff74d9',
    '#dc5900',
    '#0147ac',
    '#e13200',
    '#a2006e',
    '#00d9c1',
    '#eee4a1',
    '#304b00',
    '#725a00',
    '#009b75',
    '#ff9f5b',
    '#dc004a',
    '#ff6c3d',
    '#a24d00',
    '#b31700',
    '#cb002c',
    '#00522c',
    '#bfa4ff',
    '#00ceeb',
    '#00b5ad',
    '#a8003d',
    '#1f002f',
    '#f4e0bc',
    '#009cd3',
    '#ddb4ff',
    '#ffabe7',
    '#00604e',
    '#710036',
    '#122300',
    '#412a00',
    '#ffb698',
    '#ff6a6a',
    '#d2e8db',
    '#017e9b',
    '#00041d',
    '#adebff',
    '#003633',
    '#590001',
    '#ff8284',
    '#004b68',
    '#510f00',
    '#120500',
    '#f0dbfc'
  ],
  60: [
    '#a6c100',
    '#003fdd',
    '#00a515',
    '#ae53ff',
    '#ff02c5',
    '#eb6aff',
    '#00cb68',
    '#d0ef6b',
    '#ffdb5b',
    '#00178b',
    '#c39600',
    '#ff1b99',
    '#006806',
    '#5f8300',
    '#720076',
    '#caee97',
    '#dc5900',
    '#7c7400',
    '#ff76d2',
    '#e13200',
    '#0144a5',
    '#a1006c',
    '#00d9c1',
    '#304b00',
    '#009b75',
    '#dc004a',
    '#eee4a1',
    '#ff9f5b',
    '#ff6d3d',
    '#725a00',
    '#a24e00',
    '#b31700',
    '#cb002c',
    '#00522c',
    '#01cfeb',
    '#bfa4ff',
    '#02b5ad',
    '#a7003c',
    '#1f002e',
    '#019cd3',
    '#122300',
    '#00604e',
    '#710035',
    '#ffade8',
    '#d9b6ff',
    '#f4e0bc',
    '#ff6a6a',
    '#412a00',
    '#ffb698',
    '#007e9b',
    '#00051d',
    '#003632',
    '#adebff',
    '#d1e8db',
    '#590001',
    '#ff8284',
    '#004b67',
    '#120600',
    '#510f00',
    '#f0dbf9'
  ],
  61: [
    '#a9c000',
    '#013bdd',
    '#01a514',
    '#af53ff',
    '#ff02c5',
    '#d0ef6b',
    '#ec69ff',
    '#01cb67',
    '#ffdb5b',
    '#00178b',
    '#c39600',
    '#5f8300',
    '#ff1b99',
    '#81008a',
    '#006805',
    '#caee97',
    '#013da5',
    '#7c7400',
    '#dc5900',
    '#e13200',
    '#a2006c',
    '#00d9c1',
    '#eee4a1',
    '#ff78c7',
    '#304b00',
    '#ff9f5a',
    '#019b75',
    '#ff6c3d',
    '#725a00',
    '#dc004a',
    '#a34f00',
    '#b31700',
    '#02ddf9',
    '#cb002c',
    '#00522c',
    '#bfa5ff',
    '#00b5ad',
    '#a7003c',
    '#20002d',
    '#019fd5',
    '#f4e0bc',
    '#132300',
    '#ddb5ff',
    '#00604e',
    '#710036',
    '#ffade6',
    '#ffb698',
    '#422b00',
    '#ff6a6a',
    '#01619a',
    '#d1e8da',
    '#00342d',
    '#00809c',
    '#00041c',
    '#adebff',
    '#590001',
    '#ff8284',
    '#004961',
    '#510f00',
    '#140700',
    '#f4daf6'
  ],
  62: [
    '#a7bf00',
    '#013add',
    '#00a514',
    '#ae53ff',
    '#ff01c5',
    '#cfef6a',
    '#ec67ff',
    '#01ca67',
    '#ffdb59',
    '#00168b',
    '#c29500',
    '#5f8300',
    '#ff1a99',
    '#006805',
    '#caee97',
    '#ff70db',
    '#003ca4',
    '#7c7400',
    '#dc5900',
    '#e13200',
    '#00d9c1',
    '#a1006c',
    '#eee4a0',
    '#ff9e59',
    '#304b00',
    '#009b75',
    '#ff6c3c',
    '#725a00',
    '#dc004a',
    '#a24e00',
    '#840056',
    '#b31700',
    '#cb002c',
    '#00522c',
    '#02d1ea',
    '#bfa4ff',
    '#01b4ac',
    '#a7003b',
    '#20002d',
    '#ffa7e6',
    '#f4e0bc',
    '#019ed4',
    '#132300',
    '#dcb5ff',
    '#005f4e',
    '#710035',
    '#ffb696',
    '#caead9',
    '#422b00',
    '#ff6a6a',
    '#00332b',
    '#00609a',
    '#007f9c',
    '#00041c',
    '#adebff',
    '#590001',
    '#ff8284',
    '#004961',
    '#510f00',
    '#ffd1ce',
    '#140700',
    '#c9d0ff'
  ],
  63: [
    '#a8be00',
    '#0038dc',
    '#00a514',
    '#ae53ff',
    '#b700c0',
    '#ff01c5',
    '#cfef69',
    '#ec64ff',
    '#00ca67',
    '#ffdc58',
    '#00168b',
    '#c29500',
    '#5f8300',
    '#ff1a99',
    '#006804',
    '#caee97',
    '#7c7400',
    '#013094',
    '#dc5900',
    '#e13200',
    '#a2006c',
    '#00d9c1',
    '#eee49f',
    '#ff9e59',
    '#304b00',
    '#ff6c3c',
    '#019b74',
    '#725b00',
    '#dc004a',
    '#a34e00',
    '#9d0057',
    '#b31700',
    '#00def5',
    '#cb002c',
    '#00522c',
    '#bfa4ff',
    '#00b4ab',
    '#a7003b',
    '#20002c',
    '#ffa3e1',
    '#e1b3ff',
    '#f4e0bc',
    '#019dd2',
    '#132300',
    '#005f4e',
    '#710035',
    '#c9ead7',
    '#ffb596',
    '#422b00',
    '#89b8ff',
    '#003228',
    '#ff6a69',
    '#016098',
    '#00041c',
    '#017f9b',
    '#adebff',
    '#590001',
    '#ff8284',
    '#510f00',
    '#00475e',
    '#ffd1ce',
    '#150800',
    '#e4d7ff'
  ],
  64: [
    '#a9bd00',
    '#0036db',
    '#00a412',
    '#af53ff',
    '#b200be',
    '#ff01c5',
    '#cfef69',
    '#ec62ff',
    '#00ca67',
    '#ffdc57',
    '#00168b',
    '#c29500',
    '#5f8300',
    '#ff1999',
    '#006803',
    '#caee97',
    '#7c7400',
    '#dc5900',
    '#002b8c',
    '#e13200',
    '#00d9c1',
    '#a1006b',
    '#eee49f',
    '#ff9e58',
    '#304b00',
    '#ff6b3c',
    '#019a74',
    '#725b00',
    '#dc004a',
    '#a34e00',
    '#9d0057',
    '#b31700',
    '#cb002c',
    '#00522c',
    '#00d8e9',
    '#bfa4ff',
    '#01b3a9',
    '#a7003b',
    '#e3b1ff',
    '#ffa1e0',
    '#f4e0bc',
    '#21002c',
    '#132300',
    '#019acf',
    '#005f4e',
    '#caead4',
    '#710035',
    '#ffb596',
    '#7fb0ff',
    '#422b00',
    '#003227',
    '#93ddff',
    '#ff6a69',
    '#005f97',
    '#00041c',
    '#017e9a',
    '#afecff',
    '#590001',
    '#ff8284',
    '#510f00',
    '#ffd0cd',
    '#00465c',
    '#f0d5ff',
    '#150800'
  ],
  65: [
    '#a9bd00',
    '#0150ed',
    '#00a412',
    '#b456ff',
    '#ff01c5',
    '#ef69ff',
    '#00ca67',
    '#cff069',
    '#ff5de3',
    '#ffdc57',
    '#01178b',
    '#570082',
    '#ff1a98',
    '#006803',
    '#c29500',
    '#5f8300',
    '#dc5900',
    '#caee97',
    '#e13200',
    '#00308d',
    '#7c7400',
    '#00d9c1',
    '#a2006b',
    '#009a74',
    '#304c00',
    '#dc004a',
    '#ff6c3c',
    '#ff9e58',
    '#02e8f7',
    '#a00057',
    '#eee49f',
    '#a44f00',
    '#725b00',
    '#b31800',
    '#cb002c',
    '#00522c',
    '#bfa4ff',
    '#01b3a9',
    '#a7003a',
    '#e3b2ff',
    '#20002b',
    '#ffa2df',
    '#019bcf',
    '#005f4e',
    '#132300',
    '#710035',
    '#82b2ff',
    '#003226',
    '#93ddff',
    '#f4e0bb',
    '#ff6a69',
    '#005f97',
    '#422c00',
    '#00041c',
    '#017e9a',
    '#afecff',
    '#ffb596',
    '#caead4',
    '#590001',
    '#ff8284',
    '#00475c',
    '#f0d6ff',
    '#510f00',
    '#150900',
    '#ffd0cd'
  ],
  66: [
    '#a9bd00',
    '#0150ee',
    '#00a512',
    '#b456ff',
    '#ff02c4',
    '#ef69ff',
    '#01ca67',
    '#cfef69',
    '#ffdc57',
    '#01178b',
    '#570082',
    '#ff1b98',
    '#006903',
    '#c29500',
    '#5f8300',
    '#003ba1',
    '#caee97',
    '#dc5900',
    '#e13200',
    '#7c7400',
    '#a3006c',
    '#00d9c1',
    '#019b74',
    '#304c00',
    '#dc004a',
    '#ff73ba',
    '#ff6b3b',
    '#ff9e58',
    '#a40058',
    '#3bf7ff',
    '#a65100',
    '#eee49f',
    '#725b00',
    '#b41800',
    '#cb002c',
    '#00522c',
    '#c0a4ff',
    '#02b3a9',
    '#a8003a',
    '#e2b2ff',
    '#1f002d',
    '#ffa3e1',
    '#019bcf',
    '#005f4e',
    '#710035',
    '#132400',
    '#003426',
    '#85b3ff',
    '#f4e0bb',
    '#93ddff',
    '#ff6a69',
    '#442f00',
    '#005f98',
    '#007f9a',
    '#00041c',
    '#ffb596',
    '#2f0018',
    '#afecff',
    '#caead4',
    '#590001',
    '#ff8284',
    '#100c00',
    '#00495e',
    '#510f00',
    '#ecd7ff',
    '#ffd0cd'
  ],
  67: [
    '#a7b900',
    '#0150ed',
    '#c5f25e',
    '#00a214',
    '#b456ff',
    '#ff01c5',
    '#ef68ff',
    '#00ca66',
    '#ffdb4f',
    '#01178b',
    '#570082',
    '#ff1a98',
    '#006803',
    '#c19400',
    '#5e8300',
    '#003aa0',
    '#caef97',
    '#dc5900',
    '#e13200',
    '#7c7400',
    '#01d8c1',
    '#a3006c',
    '#e3e899',
    '#019a74',
    '#304c00',
    '#dc004a',
    '#ff71b9',
    '#ff6b3b',
    '#ff9d57',
    '#a54f00',
    '#725b00',
    '#a20057',
    '#b41700',
    '#1f002c',
    '#00522c',
    '#cb002c',
    '#01b3a9',
    '#bfa4ff',
    '#f4e1a8',
    '#a8003b',
    '#00bccc',
    '#e2b2ff',
    '#ffa2e1',
    '#009bcf',
    '#005f4e',
    '#132400',
    '#710035',
    '#00041c',
    '#300018',
    '#003426',
    '#82b2ff',
    '#93ddff',
    '#f4e0bd',
    '#432e00',
    '#ff6a69',
    '#005f98',
    '#007e9a',
    '#ffb595',
    '#afecff',
    '#c7ead8',
    '#590001',
    '#ff8284',
    '#100c00',
    '#00495e',
    '#510f00',
    '#ecd4ff',
    '#ffd0cd'
  ],
  68: [
    '#a7b900',
    '#605cff',
    '#00a213',
    '#ba57ff',
    '#ff02c4',
    '#c5f25e',
    '#01ca66',
    '#f46bff',
    '#00168a',
    '#ffdb4f',
    '#003ab7',
    '#590081',
    '#ff1a98',
    '#006802',
    '#5e8300',
    '#c19400',
    '#00348f',
    '#caef97',
    '#7c7400',
    '#dc5900',
    '#01d8c1',
    '#a3006b',
    '#e13200',
    '#009a74',
    '#304c00',
    '#dc004a',
    '#e3e899',
    '#ff71b7',
    '#a30057',
    '#725b00',
    '#ff6b3b',
    '#ff9d57',
    '#a54f00',
    '#cb002c',
    '#00522c',
    '#1f002c',
    '#b41700',
    '#01b3a9',
    '#c0a4ff',
    '#a8003a',
    '#02c1d1',
    '#f4e1a8',
    '#e3b3ff',
    '#ffa3e0',
    '#009bcf',
    '#005f4e',
    '#132400',
    '#710035',
    '#00041c',
    '#003425',
    '#2f0016',
    '#84b3ff',
    '#93deff',
    '#432e00',
    '#ff6a69',
    '#006098',
    '#007f9a',
    '#f4e0bd',
    '#afecff',
    '#c7ead7',
    '#590001',
    '#ffb595',
    '#ff8284',
    '#100d00',
    '#00495e',
    '#510f00',
    '#edd5ff',
    '#ffd0cd'
  ],
  69: [
    '#a6b800',
    '#a14fff',
    '#00a213',
    '#c5f25e',
    '#ff03c4',
    '#c95dff',
    '#01ca66',
    '#5362ff',
    '#fe6fff',
    '#00168a',
    '#ffdb4e',
    '#0139b5',
    '#006802',
    '#5e8300',
    '#590081',
    '#ff1b98',
    '#c19400',
    '#caef97',
    '#7c7400',
    '#003089',
    '#01d8c1',
    '#dc5900',
    '#304c00',
    '#e13200',
    '#a3006b',
    '#e3e899',
    '#009a73',
    '#dc004a',
    '#725b00',
    '#ff72b5',
    '#a30056',
    '#00522c',
    '#cb002c',
    '#ff6b3b',
    '#1f002c',
    '#a65000',
    '#ff9d57',
    '#01b3a9',
    '#b41800',
    '#c0a5ff',
    '#a8003a',
    '#f4e1a8',
    '#01c4d5',
    '#132400',
    '#e2b3ff',
    '#ffa3e0',
    '#019bce',
    '#005f4e',
    '#710035',
    '#432e00',
    '#003425',
    '#00041c',
    '#2f0016',
    '#84b4ff',
    '#93deff',
    '#ff6a69',
    '#f4e0bd',
    '#006097',
    '#c7ebd7',
    '#007f9a',
    '#afecff',
    '#590001',
    '#110d00',
    '#ff8284',
    '#ffb595',
    '#00495d',
    '#510f00',
    '#ecd6ff',
    '#ffd0cd'
  ],
  70: [
    '#9abb00',
    '#a14fff',
    '#00a215',
    '#c6f25f',
    '#ff03c4',
    '#c85dff',
    '#01c966',
    '#5261ff',
    '#fe6eff',
    '#ffdf4c',
    '#00168a',
    '#0139b5',
    '#006803',
    '#5e8300',
    '#590081',
    '#ff1a98',
    '#be9400',
    '#caef97',
    '#7c7400',
    '#003189',
    '#01d8c1',
    '#dc5900',
    '#e13200',
    '#304c00',
    '#a3006b',
    '#e0e89c',
    '#019a74',
    '#ff70bc',
    '#dc004a',
    '#ffa250',
    '#715b00',
    '#a10056',
    '#00522c',
    '#ff6739',
    '#cb002c',
    '#1f002c',
    '#b31600',
    '#00b3a8',
    '#c0a5ff',
    '#9c4700',
    '#f4e1a8',
    '#a8003a',
    '#ff975e',
    '#132400',
    '#00b7c6',
    '#e2b3ff',
    '#ffa3e1',
    '#009bce',
    '#005f4e',
    '#710035',
    '#432e00',
    '#003525',
    '#00041c',
    '#81b3ff',
    '#2f0016',
    '#93deff',
    '#ff6a69',
    '#006097',
    '#f4e0bd',
    '#c6ebd8',
    '#007f9a',
    '#afecff',
    '#590001',
    '#100d00',
    '#ff8284',
    '#ffb596',
    '#00495e',
    '#510f00',
    '#e8d6ff',
    '#ffd0ce'
  ],
  71: [
    '#9bbb00',
    '#a14fff',
    '#00a215',
    '#c6f260',
    '#ff03c4',
    '#c85dff',
    '#00ca66',
    '#5261ff',
    '#fd6fff',
    '#ffdf4c',
    '#00168a',
    '#0139b5',
    '#006903',
    '#5e8300',
    '#590081',
    '#ff1b98',
    '#be9400',
    '#caef97',
    '#7c7400',
    '#003c9a',
    '#01d8c1',
    '#dc5900',
    '#e13200',
    '#304c00',
    '#a3006c',
    '#e0e89c',
    '#009a74',
    '#dc004a',
    '#ffa250',
    '#725b00',
    '#a60058',
    '#ff76b7',
    '#00522c',
    '#ff6739',
    '#1d002e',
    '#cb002c',
    '#b41600',
    '#01b3a9',
    '#c0a5ff',
    '#9e4900',
    '#aa003b',
    '#f4e1a8',
    '#00c2d2',
    '#ff985e',
    '#310025',
    '#122400',
    '#e2b3ff',
    '#ffa4e1',
    '#019bce',
    '#005f4e',
    '#720036',
    '#433000',
    '#003625',
    '#00051d',
    '#83b4ff',
    '#93deff',
    '#ff6a6a',
    '#006098',
    '#f4e0bd',
    '#c6ebd7',
    '#007f9a',
    '#afecff',
    '#590001',
    '#080d00',
    '#340200',
    '#ff8284',
    '#ffb596',
    '#004a5f',
    '#510f00',
    '#e8d7ff',
    '#ffd0ce'
  ],
  72: [
    '#a5b900',
    '#a14fff',
    '#00a513',
    '#c85dff',
    '#ff03c4',
    '#c6f261',
    '#5160ff',
    '#00ca67',
    '#fd6fff',
    '#ffde4e',
    '#00168a',
    '#0b6a00',
    '#0039b5',
    '#5f8300',
    '#590081',
    '#ff1b98',
    '#bf9400',
    '#caef97',
    '#7c7400',
    '#003e9e',
    '#a3006c',
    '#dc5900',
    '#00d9c1',
    '#e13200',
    '#334d00',
    '#e1e89b',
    '#009d77',
    '#dc004a',
    '#ffa250',
    '#a60058',
    '#725b00',
    '#005e34',
    '#ff76b5',
    '#01e2f1',
    '#ff6739',
    '#cb002c',
    '#1d002e',
    '#b41600',
    '#c0a5ff',
    '#01b4aa',
    '#004624',
    '#9f4900',
    '#aa003b',
    '#f4e1a8',
    '#ff985e',
    '#e2b3ff',
    '#340028',
    '#ffa4e1',
    '#132400',
    '#019bcf',
    '#720036',
    '#00604f',
    '#443000',
    '#00051d',
    '#86b5ff',
    '#93deff',
    '#ff6a6a',
    '#006098',
    '#f4e0bd',
    '#017f9b',
    '#c8ead7',
    '#00332f',
    '#afecff',
    '#590001',
    '#ff8284',
    '#0c0c00',
    '#340200',
    '#ffb596',
    '#004a60',
    '#510f00',
    '#ebd7ff',
    '#ffd0cd'
  ],
  73: [
    '#a5b900',
    '#a150ff',
    '#00a513',
    '#ff03c4',
    '#c95fff',
    '#c6f261',
    '#00ca67',
    '#fe72ff',
    '#5d6aff',
    '#ffdf4e',
    '#5e008d',
    '#00168a',
    '#0b6a00',
    '#0039b5',
    '#5f8300',
    '#ff1c98',
    '#bf9400',
    '#4f006b',
    '#caef97',
    '#7c7400',
    '#a4006c',
    '#00d9c1',
    '#0049ab',
    '#334d00',
    '#e1e89b',
    '#019d77',
    '#e13200',
    '#dc004a',
    '#dc5900',
    '#01eefb',
    '#a70058',
    '#725b00',
    '#005e34',
    '#ffa250',
    '#cb002c',
    '#ff78b4',
    '#1d002d',
    '#c0a5ff',
    '#01b4aa',
    '#004624',
    '#b41600',
    '#aa003b',
    '#ff6739',
    '#a04a00',
    '#f4e1a8',
    '#e2b4ff',
    '#132400',
    '#ffa5e1',
    '#330025',
    '#019bcf',
    '#720036',
    '#ff985e',
    '#00604f',
    '#453000',
    '#00051d',
    '#88b6ff',
    '#93deff',
    '#ff6a69',
    '#006098',
    '#f4e0bd',
    '#017f9b',
    '#c8ead7',
    '#00332e',
    '#afecff',
    '#590001',
    '#ff8284',
    '#0d0d00',
    '#340200',
    '#004a60',
    '#ffb596',
    '#510f00',
    '#ebd8ff',
    '#ffd0cd'
  ],
  74: [
    '#a7b800',
    '#a150ff',
    '#00a410',
    '#ff03c4',
    '#c95eff',
    '#c6f260',
    '#01ca66',
    '#fe71ff',
    '#5d6aff',
    '#ffde4d',
    '#00168a',
    '#5e008d',
    '#0039b5',
    '#0f6a00',
    '#5f8300',
    '#ff1b98',
    '#bf9400',
    '#4f006b',
    '#caef97',
    '#7c7400',
    '#0147a9',
    '#a4006b',
    '#01d8c1',
    '#334d00',
    '#e3e899',
    '#009c74',
    '#e13200',
    '#dc004a',
    '#dc5900',
    '#725b00',
    '#a60057',
    '#005e34',
    '#ffa250',
    '#ff76b2',
    '#cb002c',
    '#5af5ff',
    '#1d002d',
    '#c0a5ff',
    '#02b1a5',
    '#004624',
    '#ff6739',
    '#aa003b',
    '#02c7d6',
    '#a04900',
    '#f4e1a8',
    '#b41600',
    '#e3b3ff',
    '#ffa4e0',
    '#330025',
    '#132400',
    '#029ace',
    '#720036',
    '#ff975e',
    '#00604f',
    '#453000',
    '#00051d',
    '#87b4ff',
    '#94deff',
    '#ff6a69',
    '#016098',
    '#f4e0bd',
    '#017e9a',
    '#00312b',
    '#caead6',
    '#afecff',
    '#590001',
    '#ff8284',
    '#0d0d00',
    '#340200',
    '#ffb596',
    '#004a5f',
    '#f1d7ff',
    '#510f00',
    '#ffd0cd'
  ],
  75: [
    '#a5b800',
    '#a14fff',
    '#01a410',
    '#ff03c4',
    '#c95eff',
    '#c5f25f',
    '#01ca66',
    '#fe6dff',
    '#5a68ff',
    '#ffdf4b',
    '#00168a',
    '#5d008d',
    '#0039b5',
    '#0f6a00',
    '#5f8300',
    '#ff1a98',
    '#bf9400',
    '#4f006b',
    '#caef97',
    '#7c7400',
    '#01409d',
    '#a4006b',
    '#01d8c1',
    '#334d00',
    '#e4e898',
    '#009c74',
    '#e13200',
    '#dc5900',
    '#dc0049',
    '#725b00',
    '#005e34',
    '#a30055',
    '#ffa250',
    '#ff6fac',
    '#cb002c',
    '#58f5ff',
    '#1d002c',
    '#ff97dd',
    '#bfa4ff',
    '#01b0a4',
    '#004624',
    '#ff6739',
    '#e2afff',
    '#aa003a',
    '#a04a00',
    '#b41600',
    '#02c5d3',
    '#f4e1a8',
    '#132400',
    '#310023',
    '#009acd',
    '#ff975d',
    '#720036',
    '#00604f',
    '#453000',
    '#7bb2ff',
    '#00051d',
    '#94deff',
    '#ff6a69',
    '#016097',
    '#c8ead5',
    '#f4e0bd',
    '#00312a',
    '#017e9a',
    '#afecff',
    '#590001',
    '#ffc8f3',
    '#0d0d00',
    '#ff8284',
    '#350200',
    '#ffb596',
    '#00495f',
    '#510f00',
    '#cfd0ff',
    '#ffd0cd'
  ],
  76: [
    '#a1b900',
    '#a14fff',
    '#00a411',
    '#ff03c4',
    '#c95eff',
    '#c5f260',
    '#00ca66',
    '#fe6dff',
    '#5968ff',
    '#ffe04b',
    '#00168a',
    '#5d008d',
    '#0039b5',
    '#0e6a00',
    '#5f8300',
    '#ff1a98',
    '#be9400',
    '#4f006b',
    '#caef97',
    '#7c7400',
    '#01409d',
    '#a4006b',
    '#01d8c1',
    '#334d00',
    '#e3e899',
    '#ff6d22',
    '#019c74',
    '#e13200',
    '#d95800',
    '#dc004a',
    '#725b00',
    '#ff6faf',
    '#005e34',
    '#a30055',
    '#ffa251',
    '#cb002c',
    '#58f4ff',
    '#1d002c',
    '#ff98de',
    '#bfa4ff',
    '#01b0a4',
    '#004624',
    '#e1afff',
    '#a9003b',
    '#f4e1a8',
    '#b21300',
    '#9c4800',
    '#00bfce',
    '#ff643e',
    '#132400',
    '#310023',
    '#009acd',
    '#ff995f',
    '#00604f',
    '#720036',
    '#443000',
    '#7ab1ff',
    '#00051d',
    '#94deff',
    '#ff6a6a',
    '#016097',
    '#c8ead5',
    '#f4e0bd',
    '#00312b',
    '#017e9a',
    '#afecff',
    '#590001',
    '#ffc9f3',
    '#0d0d00',
    '#ff8284',
    '#340200',
    '#ffb596',
    '#00495f',
    '#510f00',
    '#d1d3ff',
    '#ffd0cd'
  ],
  77: [
    '#a1b800',
    '#a04fff',
    '#00a411',
    '#d51ddc',
    '#ff01c4',
    '#be5dff',
    '#c5f260',
    '#00ca66',
    '#ffe04a',
    '#ff71fd',
    '#496aff',
    '#00168a',
    '#5d008c',
    '#0139b4',
    '#0e6a00',
    '#5f8300',
    '#ff1897',
    '#be9400',
    '#4f006b',
    '#caef97',
    '#7c7400',
    '#01d8c1',
    '#a3006a',
    '#013d97',
    '#334d00',
    '#019c74',
    '#ff6d22',
    '#e3e898',
    '#e13200',
    '#d95800',
    '#dc0049',
    '#005e34',
    '#725b00',
    '#ffa250',
    '#ff70ae',
    '#a20054',
    '#cb002c',
    '#004624',
    '#58f4ff',
    '#1d002c',
    '#bfa5ff',
    '#01b0a4',
    '#ff99de',
    '#b21300',
    '#a9003a',
    '#9c4900',
    '#f4e1a8',
    '#ff643e',
    '#03c3d1',
    '#dfb1ff',
    '#132400',
    '#300021',
    '#00604f',
    '#ff995f',
    '#0199cd',
    '#720036',
    '#443000',
    '#79b2ff',
    '#00051d',
    '#c8ebd5',
    '#94deff',
    '#ff6a6a',
    '#016097',
    '#f4e0bd',
    '#00312a',
    '#017e9a',
    '#afecff',
    '#590001',
    '#ffc8f3',
    '#0d0d00',
    '#ff8284',
    '#340200',
    '#ffb596',
    '#00495f',
    '#510f00',
    '#d3d5ff',
    '#ffd0cd'
  ],
  78: [
    '#a1b800',
    '#3235dd',
    '#00a411',
    '#a150ff',
    '#d60cd5',
    '#c5f260',
    '#ffe04a',
    '#ff01c4',
    '#c45eff',
    '#00ca66',
    '#ff71fc',
    '#00168a',
    '#5d0088',
    '#5f8300',
    '#be9400',
    '#0e6a00',
    '#0137af',
    '#4374ff',
    '#ff1997',
    '#caef97',
    '#7c7400',
    '#4f006a',
    '#ff6d22',
    '#e3e898',
    '#d95800',
    '#ffa250',
    '#e13200',
    '#01d8c1',
    '#009c74',
    '#a3006a',
    '#334d00',
    '#003a90',
    '#725b00',
    '#dc0049',
    '#005e34',
    '#f4e1a8',
    '#ff643e',
    '#ff995f',
    '#9c4a00',
    '#ff70ad',
    '#a20053',
    '#b21300',
    '#cb002c',
    '#004624',
    '#58f5ff',
    '#01b0a4',
    '#1d002c',
    '#bfa5ff',
    '#ff99dd',
    '#00ced9',
    '#a9003a',
    '#dfb2ff',
    '#f4e0bd',
    '#132400',
    '#00604f',
    '#2f0020',
    '#c7ebd5',
    '#009acd',
    '#720036',
    '#443000',
    '#7ab3ff',
    '#00051c',
    '#ffb596',
    '#94deff',
    '#ff6a6a',
    '#006097',
    '#003129',
    '#017e9a',
    '#afecff',
    '#590001',
    '#ffc9f3',
    '#0d0e00',
    '#ff8284',
    '#340200',
    '#510f00',
    '#004a5e',
    '#ffd0cd',
    '#d5d6ff'
  ],
  79: [
    '#a7b800',
    '#3335dc',
    '#00a70f',
    '#a250ff',
    '#d70dd6',
    '#c6f261',
    '#ff01c4',
    '#c45eff',
    '#ffe04c',
    '#01ca66',
    '#ff71fc',
    '#00168a',
    '#5d0088',
    '#0a6c00',
    '#5f8400',
    '#4674ff',
    '#be9400',
    '#0137b0',
    '#ff1997',
    '#caee97',
    '#4f006a',
    '#7c7400',
    '#ff6d22',
    '#e4e898',
    '#d95800',
    '#ffa251',
    '#e13200',
    '#01d8c1',
    '#a3006a',
    '#009d75',
    '#375000',
    '#013c94',
    '#725b00',
    '#dc0049',
    '#005e34',
    '#71f7e8',
    '#0c3500',
    '#f4e1a8',
    '#a20053',
    '#ff633e',
    '#ff71ad',
    '#9d4900',
    '#ff995f',
    '#b21300',
    '#cb002c',
    '#bfa5ff',
    '#59f5ff',
    '#01b1a5',
    '#ff9add',
    '#004b2b',
    '#1d002c',
    '#a9003a',
    '#dfb2ff',
    '#f4e0bd',
    '#720036',
    '#006050',
    '#320023',
    '#142200',
    '#029acd',
    '#c8ead5',
    '#463000',
    '#7cb3ff',
    '#ffb596',
    '#00051d',
    '#ff6a6a',
    '#94deff',
    '#006097',
    '#027e9a',
    '#afecff',
    '#003432',
    '#ffc9f3',
    '#590001',
    '#ff8284',
    '#0d0b00',
    '#350200',
    '#004a60',
    '#510f00',
    '#ffd0cd',
    '#d5d6ff'
  ],
  80: [
    '#a6b700',
    '#3235dc',
    '#00a70f',
    '#a150ff',
    '#d814d8',
    '#c6f261',
    '#ff01c4',
    '#c45eff',
    '#ffe04b',
    '#00ca66',
    '#ff71fd',
    '#00168a',
    '#5d0088',
    '#bc76ff',
    '#0a6c00',
    '#5f8400',
    '#4573ff',
    '#be9400',
    '#0137af',
    '#ff1997',
    '#caef97',
    '#4f006a',
    '#7c7400',
    '#ff6d22',
    '#e4e898',
    '#d95800',
    '#ffa251',
    '#e13200',
    '#01d8c1',
    '#a30069',
    '#009d75',
    '#375100',
    '#725c00',
    '#003382',
    '#7ff7df',
    '#dc0049',
    '#005e34',
    '#0c3500',
    '#f4e1a8',
    '#ff633e',
    '#9f4b00',
    '#ff995f',
    '#9f0050',
    '#ff6fa4',
    '#b31300',
    '#cb002c',
    '#bfa5ff',
    '#59f5ff',
    '#01b1a5',
    '#004b2b',
    '#1d002b',
    '#aa0039',
    '#deb2ff',
    '#ffa1de',
    '#f4e0bd',
    '#720036',
    '#006050',
    '#142200',
    '#c8ebd5',
    '#009acd',
    '#2e001e',
    '#463000',
    '#80b8ff',
    '#ffb596',
    '#00051c',
    '#ff6a6a',
    '#94deff',
    '#006097',
    '#007e9a',
    '#afecff',
    '#003330',
    '#ffc9f3',
    '#590001',
    '#ff8284',
    '#0d0c00',
    '#350200',
    '#ffd1cd',
    '#510f00',
    '#004a5f',
    '#d4d8ff'
  ],
  81: [
    '#a4b600',
    '#2f35dc',
    '#70ff62',
    '#a150ff',
    '#ff03c4',
    '#c25dff',
    '#00a710',
    '#00168a',
    '#c6f261',
    '#5c0088',
    '#856dff',
    '#ffe04a',
    '#0137af',
    '#ff73fe',
    '#d7009c',
    '#00ca66',
    '#2f72ff',
    '#dd79ff',
    '#4e006a',
    '#ff1e96',
    '#0a6c00',
    '#5f8400',
    '#be9400',
    '#a10067',
    '#caef97',
    '#003280',
    '#dc0049',
    '#7c7400',
    '#ff6d22',
    '#9e004e',
    '#e4e898',
    '#1d002b',
    '#d95800',
    '#ffa250',
    '#e13200',
    '#cb002c',
    '#a90038',
    '#720035',
    '#01d8c1',
    '#375100',
    '#2d001d',
    '#009d75',
    '#725c00',
    '#ff75a7',
    '#bfa5ff',
    '#0c3500',
    '#f4e1a8',
    '#ff633e',
    '#9f4b00',
    '#005e34',
    '#ff995f',
    '#76f7e7',
    '#b31300',
    '#00051c',
    '#ffa3de',
    '#dcb3ff',
    '#59f5ff',
    '#01b1a5',
    '#350300',
    '#590001',
    '#004b2b',
    '#ff6a69',
    '#f4e0bd',
    '#016097',
    '#510f00',
    '#142200',
    '#019acd',
    '#c8ead5',
    '#006050',
    '#463000',
    '#ff8284',
    '#ffb596',
    '#81b9ff',
    '#0c0c00',
    '#94deff',
    '#ffc9f3',
    '#007e9a',
    '#afecff',
    '#003330',
    '#004a5f',
    '#ffd1cd'
  ],
  82: [
    '#9bb900',
    '#2f35dc',
    '#00a611',
    '#a150ff',
    '#c5f262',
    '#fde343',
    '#c25dff',
    '#ff03c4',
    '#00ca66',
    '#ff73ff',
    '#846eff',
    '#00168a',
    '#5c0088',
    '#dc78ff',
    '#d7019c',
    '#086c00',
    '#5f8400',
    '#2d72ff',
    '#bd9400',
    '#0137af',
    '#ff1d96',
    '#caef97',
    '#4f006a',
    '#7c7400',
    '#ff6d22',
    '#e1e89b',
    '#d95800',
    '#ffa250',
    '#e13200',
    '#01d8c1',
    '#a10067',
    '#375100',
    '#002f7d',
    '#009d75',
    '#725b00',
    '#ff8c4d',
    '#dc0049',
    '#0c3500',
    '#f4e1a8',
    '#ffb575',
    '#78f7e3',
    '#ff603c',
    '#005e34',
    '#994600',
    '#b21200',
    '#9d004e',
    '#ff70a6',
    '#cb002c',
    '#58f5ff',
    '#bfa5ff',
    '#01b1a5',
    '#1d002b',
    '#004b2b',
    '#a90038',
    '#ffa2df',
    '#dcb3ff',
    '#f4e0bd',
    '#c6ebd4',
    '#142200',
    '#720035',
    '#0199cd',
    '#006050',
    '#00051c',
    '#453000',
    '#2d001d',
    '#79b7ff',
    '#016097',
    '#ffb698',
    '#ff6969',
    '#94deff',
    '#007e9a',
    '#003430',
    '#afecff',
    '#ffc9f3',
    '#590001',
    '#ff8284',
    '#0c0c00',
    '#350200',
    '#ffd1cd',
    '#510f00',
    '#004a5f',
    '#dddfff'
  ],
  83: [
    '#9db900',
    '#3034db',
    '#00a710',
    '#a150ff',
    '#c6f263',
    '#fde344',
    '#c25dff',
    '#ff03c4',
    '#01ca66',
    '#ff73ff',
    '#00168a',
    '#5c0088',
    '#dc78ff',
    '#d7009c',
    '#086d00',
    '#8b74ff',
    '#3472ff',
    '#5f8400',
    '#bd9400',
    '#0137af',
    '#ff1d96',
    '#caef97',
    '#4f006a',
    '#7c7400',
    '#ff6d22',
    '#e1e89b',
    '#d95800',
    '#ffa250',
    '#e13200',
    '#a10067',
    '#375100',
    '#01d8c1',
    '#013180',
    '#009d76',
    '#725c00',
    '#ff8c4d',
    '#dc0049',
    '#0c3500',
    '#f4e1a8',
    '#7ef7dd',
    '#ffb475',
    '#ff603c',
    '#005e34',
    '#9e004e',
    '#9a4700',
    '#b31200',
    '#cb002c',
    '#bfa5ff',
    '#59f5ff',
    '#ff73a7',
    '#00b1a5',
    '#1d002c',
    '#004c2c',
    '#a90039',
    '#ffa2df',
    '#dcb2ff',
    '#f4e0bd',
    '#142400',
    '#720036',
    '#c7ebd4',
    '#009acd',
    '#350024',
    '#006150',
    '#00051d',
    '#483200',
    '#7cb8ff',
    '#006198',
    '#ff6a6a',
    '#ffb698',
    '#94deff',
    '#007f9a',
    '#afecff',
    '#003836',
    '#001104',
    '#ffc9f3',
    '#590001',
    '#ff8284',
    '#350200',
    '#004c61',
    '#ffd1cd',
    '#510f00',
    '#1f0e00',
    '#dfe0fe'
  ],
  84: [
    '#9ab800',
    '#2f34db',
    '#00a711',
    '#a150ff',
    '#fce342',
    '#c5f263',
    '#c25dff',
    '#ff03c4',
    '#00ca66',
    '#ff73ff',
    '#00168a',
    '#dc77ff',
    '#5c0088',
    '#d7009c',
    '#3271ff',
    '#086d00',
    '#5f8400',
    '#0137af',
    '#bd9400',
    '#ff1c96',
    '#caef97',
    '#4f006a',
    '#6482ff',
    '#7c7400',
    '#ff6d22',
    '#e1e89b',
    '#da5800',
    '#ffa250',
    '#e13200',
    '#012b79',
    '#a00067',
    '#375100',
    '#01d8c1',
    '#ff8b4a',
    '#009d76',
    '#ff64a7',
    '#725c00',
    '#db0049',
    '#0c3500',
    '#ffb574',
    '#7cf7de',
    '#f4e1a8',
    '#ff5f3b',
    '#005e34',
    '#9a4800',
    '#9d004e',
    '#b31200',
    '#cb002c',
    '#58f5ff',
    '#bfa5ff',
    '#1d002c',
    '#02b1a5',
    '#004c2c',
    '#ffa1e1',
    '#a80038',
    '#dbb2ff',
    '#f4e0bd',
    '#c6ebd3',
    '#142400',
    '#720036',
    '#00051d',
    '#019acd',
    '#330022',
    '#006150',
    '#473200',
    '#7ab8ff',
    '#016198',
    '#ffb798',
    '#ff6969',
    '#94deff',
    '#007f9a',
    '#afecff',
    '#003835',
    '#001105',
    '#ffc9f4',
    '#590001',
    '#ff8284',
    '#350200',
    '#ffd2ce',
    '#004c61',
    '#510f00',
    '#1f0e00',
    '#ff9ea2',
    '#dce1fa'
  ],
  85: [
    '#9bb700',
    '#2f34db',
    '#00a60e',
    '#a150ff',
    '#fde340',
    '#c5f262',
    '#c25dff',
    '#ff03c4',
    '#00ca66',
    '#ff73ff',
    '#00168a',
    '#db75ff',
    '#5c0088',
    '#3271ff',
    '#8973ff',
    '#d7009c',
    '#0b6d00',
    '#0137af',
    '#5f8400',
    '#bd9400',
    '#ff1c96',
    '#caef96',
    '#4f006a',
    '#7c7400',
    '#ff6d22',
    '#e2e899',
    '#7af9ca',
    '#da5800',
    '#ffa250',
    '#e13200',
    '#012f7d',
    '#375100',
    '#a00067',
    '#01d8c0',
    '#ff8b49',
    '#009c74',
    '#725c00',
    '#ff5f9f',
    '#db0049',
    '#0c3500',
    '#ffb573',
    '#f4e1a8',
    '#ff5f3b',
    '#01e5ff',
    '#005e34',
    '#9a4800',
    '#9c004d',
    '#b31200',
    '#cb002c',
    '#bfa5ff',
    '#1d002c',
    '#00afa2',
    '#004c2c',
    '#ffa1e2',
    '#a90038',
    '#dbb2ff',
    '#83f4f1',
    '#f4e0bd',
    '#142400',
    '#c8ebd2',
    '#720036',
    '#00041d',
    '#330022',
    '#0199cc',
    '#006050',
    '#473200',
    '#79b6ff',
    '#006097',
    '#ffb698',
    '#ff6869',
    '#95deff',
    '#017e9a',
    '#003633',
    '#afecff',
    '#001104',
    '#ffc9f4',
    '#590001',
    '#ff8284',
    '#350200',
    '#ffd2ce',
    '#004b60',
    '#1f0f00',
    '#510f00',
    '#ff9ea2',
    '#dcdfff'
  ],
  86: [
    '#9bb700',
    '#3239e0',
    '#00a60e',
    '#a250ff',
    '#fde340',
    '#c5f262',
    '#ff03c4',
    '#c35eff',
    '#00ca66',
    '#ff73ff',
    '#00178b',
    '#60008b',
    '#df79ff',
    '#d8009c',
    '#0c6d00',
    '#0137b1',
    '#5f8400',
    '#4873ff',
    '#bd9400',
    '#9377ff',
    '#ff1d96',
    '#2c0068',
    '#caef96',
    '#74fac4',
    '#56006b',
    '#7c7400',
    '#ff6d22',
    '#e2e899',
    '#da5800',
    '#e13200',
    '#ffa250',
    '#01d8c0',
    '#a10067',
    '#375100',
    '#003481',
    '#ff8b49',
    '#019c74',
    '#725c00',
    '#ff5d9a',
    '#db0049',
    '#0c3500',
    '#ffb573',
    '#ff5f3b',
    '#f4e1a8',
    '#02e5ff',
    '#005e34',
    '#9b4800',
    '#b31300',
    '#9d004d',
    '#cb002c',
    '#bfa5ff',
    '#00b0a2',
    '#1e002b',
    '#004c2c',
    '#ffa2e2',
    '#a90038',
    '#83f5f1',
    '#dbb2ff',
    '#f4e0bd',
    '#142400',
    '#720036',
    '#c8ebd2',
    '#00041d',
    '#0099cc',
    '#483200',
    '#006050',
    '#320021',
    '#016197',
    '#7bb7ff',
    '#ffb798',
    '#ff6968',
    '#95deff',
    '#017e9a',
    '#003632',
    '#afecff',
    '#ffc9f4',
    '#001105',
    '#590001',
    '#ff8284',
    '#360200',
    '#510f00',
    '#201000',
    '#004c60',
    '#ffd1cd',
    '#ff9ea2',
    '#dde1fe'
  ],
  87: [
    '#98b800',
    '#3139e0',
    '#00a610',
    '#a250ff',
    '#fbe441',
    '#c5f262',
    '#ff03c4',
    '#c25eff',
    '#01ca66',
    '#ff73ff',
    '#00178b',
    '#60008b',
    '#de79ff',
    '#d8009c',
    '#0a6d00',
    '#0137b1',
    '#5f8400',
    '#bc9400',
    '#4873ff',
    '#ff1d96',
    '#2c0068',
    '#caef96',
    '#887eff',
    '#56006b',
    '#dc6a00',
    '#75fac5',
    '#7c7400',
    '#ff6d22',
    '#e1e89a',
    '#d95500',
    '#e13200',
    '#ffa251',
    '#01d8c0',
    '#375100',
    '#a10067',
    '#00327e',
    '#009c74',
    '#725c00',
    '#ff65a8',
    '#db0049',
    '#0c3500',
    '#ff8e52',
    '#ffb674',
    '#f4e1a8',
    '#02e5ff',
    '#005e34',
    '#ff5e3e',
    '#b10d00',
    '#9c004d',
    '#954400',
    '#cb002c',
    '#bfa5ff',
    '#00b0a2',
    '#1d002b',
    '#004c2c',
    '#ffa2e2',
    '#a80039',
    '#83f5f1',
    '#dbb2ff',
    '#f4e0bd',
    '#142400',
    '#c7ebd2',
    '#720035',
    '#00041d',
    '#0099cc',
    '#473200',
    '#006050',
    '#320021',
    '#79b7ff',
    '#016197',
    '#ff6969',
    '#ffb798',
    '#95deff',
    '#017e9a',
    '#003733',
    '#afecff',
    '#001105',
    '#ffc9f4',
    '#590001',
    '#ff8284',
    '#360200',
    '#510f00',
    '#004c61',
    '#1f0f00',
    '#ffd2ce',
    '#ff9ea2',
    '#dde1fc'
  ],
  88: [
    '#96b300',
    '#3139e0',
    '#6bff61',
    '#a150ff',
    '#ff03c4',
    '#c25dff',
    '#00a510',
    '#00178b',
    '#fbe43e',
    '#60008b',
    '#c4f262',
    '#ff73ff',
    '#0137b1',
    '#d8009c',
    '#2c0068',
    '#00ca66',
    '#de77ff',
    '#9574ff',
    '#4873ff',
    '#ff1c96',
    '#56006b',
    '#0b6d00',
    '#5f8400',
    '#bc9400',
    '#a10067',
    '#caef96',
    '#003583',
    '#db0049',
    '#dc6a00',
    '#9c004d',
    '#7c7400',
    '#ff64a6',
    '#ff6d22',
    '#e3e898',
    '#1d002b',
    '#d95500',
    '#e13200',
    '#ffa251',
    '#cb002c',
    '#a80039',
    '#720035',
    '#320021',
    '#00d8c0',
    '#375100',
    '#bfa5ff',
    '#009c74',
    '#725c00',
    '#ff8e51',
    '#0c3500',
    '#ffb574',
    '#b20d00',
    '#ffa2e2',
    '#f4e1a8',
    '#00e4fe',
    '#005e34',
    '#ff5d3e',
    '#dcb1ff',
    '#00041d',
    '#954300',
    '#360200',
    '#590001',
    '#02afa1',
    '#ff6869',
    '#004c2c',
    '#c0edcd',
    '#7bf6f3',
    '#016097',
    '#f4e0bc',
    '#510f00',
    '#142400',
    '#75b4ff',
    '#0098cb',
    '#00604f',
    '#473200',
    '#ff8284',
    '#019ea3',
    '#1f0f00',
    '#ffb698',
    '#95deff',
    '#ffc9f4',
    '#001105',
    '#017e99',
    '#003632',
    '#afecff',
    '#ff9ea2',
    '#004b60',
    '#d7e5e9',
    '#ffd1cd'
  ],
  89: [
    '#95b100',
    '#3037de',
    '#6eff59',
    '#8d00c7',
    '#a14fff',
    '#dc1edc',
    '#ca51ff',
    '#ff03c4',
    '#00a510',
    '#00178b',
    '#60008b',
    '#fbe43e',
    '#c4f262',
    '#0137b0',
    '#ff71fd',
    '#2c0068',
    '#d7009b',
    '#00ca65',
    '#006df8',
    '#ff1c96',
    '#56006b',
    '#b985ff',
    '#0b6d00',
    '#5f8400',
    '#bc9400',
    '#a10066',
    '#caef96',
    '#003481',
    '#db0049',
    '#9b004c',
    '#ff5f9e',
    '#dc6a00',
    '#1e002b',
    '#7c7400',
    '#ff6d22',
    '#e3e897',
    '#d95500',
    '#e13200',
    '#720035',
    '#cb002c',
    '#a80038',
    '#ffa250',
    '#310020',
    '#00d8c0',
    '#375100',
    '#bfa5ff',
    '#019c74',
    '#725c00',
    '#005e34',
    '#ffa2e2',
    '#0c3500',
    '#ff8e51',
    '#ffb574',
    '#b20e00',
    '#00041d',
    '#f4e1a8',
    '#dcb3ff',
    '#00e4fe',
    '#ff5d3d',
    '#360200',
    '#964400',
    '#590001',
    '#004c2c',
    '#00afa1',
    '#ff6969',
    '#7af6f2',
    '#c0edcd',
    '#016097',
    '#74b4ff',
    '#510f00',
    '#f4e0bc',
    '#142400',
    '#00604f',
    '#0098cb',
    '#ff8284',
    '#473200',
    '#1f1000',
    '#009ea3',
    '#ffc9f3',
    '#ffb698',
    '#001105',
    '#95deff',
    '#003631',
    '#017e99',
    '#ff9ea2',
    '#afecff',
    '#004b60',
    '#d7e5e9',
    '#ffd1cc'
  ],
  90: [
    '#82b900',
    '#3037de',
    '#00a317',
    '#a14fff',
    '#dc1fdc',
    '#9800c5',
    '#c951ff',
    '#afbd00',
    '#c4f269',
    '#ffe14e',
    '#00c966',
    '#ff02c4',
    '#ff71fd',
    '#00178b',
    '#066c00',
    '#5d8300',
    '#60008b',
    '#006df8',
    '#bc9300',
    '#0137b0',
    '#d7009b',
    '#ff1c96',
    '#caef97',
    '#2c0068',
    '#dc6a00',
    '#7c7400',
    '#b985ff',
    '#ff6d22',
    '#56006b',
    '#e2e89a',
    '#d95500',
    '#e13200',
    '#ffa251',
    '#00d8c0',
    '#365100',
    '#009c74',
    '#a10066',
    '#725b00',
    '#ff4f8b',
    '#005e34',
    '#0b3500',
    '#db0049',
    '#ff8e51',
    '#ffb574',
    '#f4e1a8',
    '#003380',
    '#00e4fe',
    '#ff5d3d',
    '#b20d00',
    '#964400',
    '#cb002c',
    '#004c2c',
    '#01afa1',
    '#c0edcc',
    '#9b004c',
    '#7af6f3',
    '#bfa5ff',
    '#1e002b',
    '#a80038',
    '#ffa1e2',
    '#f4e0bc',
    '#142400',
    '#00604f',
    '#dcb3ff',
    '#0098cb',
    '#6fb3ff',
    '#463200',
    '#720035',
    '#009898',
    '#310020',
    '#00041d',
    '#ffb698',
    '#ff6869',
    '#95deff',
    '#016097',
    '#003631',
    '#017e99',
    '#001104',
    '#afecff',
    '#590001',
    '#aac3ff',
    '#ffc9f3',
    '#ff8284',
    '#d7e5e9',
    '#1f1000',
    '#510f00',
    '#360200',
    '#004b60',
    '#ffd1cd',
    '#ff9ea2'
  ],
  91: [
    '#86ad00',
    '#3036dd',
    '#6eff60',
    '#a14fff',
    '#df25e0',
    '#c952ff',
    '#ff03c4',
    '#00a317',
    '#00178b',
    '#60008b',
    '#afbe00',
    '#c4f269',
    '#ff71fd',
    '#0037b0',
    '#ffe14e',
    '#2c0068',
    '#016efd',
    '#d8009b',
    '#00c966',
    '#ff1c96',
    '#55006c',
    '#740067',
    '#056c00',
    '#b985ff',
    '#5d8300',
    '#bc9300',
    '#a20066',
    '#caef97',
    '#028aff',
    '#db0049',
    '#dc6a00',
    '#003983',
    '#7c7400',
    '#ff6d22',
    '#ff62a1',
    '#990047',
    '#e2e89a',
    '#1d002a',
    '#d95500',
    '#e13200',
    '#ffa251',
    '#cb002c',
    '#aa0038',
    '#720035',
    '#00d8c0',
    '#365100',
    '#009c74',
    '#2f001d',
    '#bfa6ff',
    '#725c00',
    '#005e34',
    '#0b3500',
    '#ff8e51',
    '#ffb574',
    '#f4e1a8',
    '#b20e00',
    '#ffa0df',
    '#01e4fe',
    '#ff5d3d',
    '#00051d',
    '#dcb3ff',
    '#964300',
    '#004c2c',
    '#00afa1',
    '#360300',
    '#590001',
    '#ff6969',
    '#7af6f3',
    '#c1edce',
    '#f4e0bc',
    '#006197',
    '#142400',
    '#510f00',
    '#00604f',
    '#0298cb',
    '#463200',
    '#ff8284',
    '#79baff',
    '#019ca0',
    '#ffb698',
    '#201100',
    '#95deff',
    '#ffc9f3',
    '#001105',
    '#003630',
    '#017e99',
    '#afecff',
    '#ff9ea2',
    '#004c5f',
    '#d7e5e9',
    '#ffd1cc'
  ],
  92: [
    '#86ac00',
    '#2f37de',
    '#6dff6e',
    '#a14fff',
    '#e02be3',
    '#c952ff',
    '#ff03c4',
    '#00a317',
    '#afbe00',
    '#c4f26a',
    '#00178b',
    '#5f008b',
    '#ffe14e',
    '#00c966',
    '#ff71fd',
    '#0037b0',
    '#d8019c',
    '#2b0068',
    '#006efd',
    '#ff1d96',
    '#55006c',
    '#056c00',
    '#5d8300',
    '#bc9300',
    '#730068',
    '#b985ff',
    '#caef97',
    '#a10067',
    '#dc6a00',
    '#7c7400',
    '#c80056',
    '#ff6d22',
    '#dd0049',
    '#e2e89a',
    '#d95500',
    '#018dff',
    '#e13200',
    '#ffa251',
    '#95004a',
    '#013984',
    '#ff6fb4',
    '#1d002a',
    '#00d8c0',
    '#365100',
    '#cd002c',
    '#009c74',
    '#710035',
    '#725c00',
    '#a50030',
    '#005e34',
    '#0b3500',
    '#2d001c',
    '#ff8e52',
    '#ffb574',
    '#f4e1a8',
    '#01e4fe',
    '#ff5f3d',
    '#bfa6ff',
    '#b31200',
    '#964600',
    '#004c2c',
    '#00afa1',
    '#ffa1e0',
    '#00051d',
    '#7af6f3',
    '#c1edce',
    '#590001',
    '#360300',
    '#dcb3ff',
    '#ff6a69',
    '#f4e0bc',
    '#142400',
    '#00604f',
    '#0198cb',
    '#463200',
    '#510f00',
    '#79bcff',
    '#029c9e',
    '#016197',
    '#ffb798',
    '#95deff',
    '#ff8284',
    '#003630',
    '#017e99',
    '#201200',
    '#001105',
    '#afecff',
    '#ffc9f3',
    '#d7e5e9',
    '#ff9ea2',
    '#ffd1cd',
    '#004c5f'
  ],
  93: [
    '#88aa00',
    '#3036dd',
    '#6fff69',
    '#a14fff',
    '#e12be4',
    '#c952ff',
    '#ff03c4',
    '#00a317',
    '#afbe00',
    '#00178b',
    '#60008b',
    '#c4f26a',
    '#ffe14e',
    '#01c966',
    '#ff71fd',
    '#0037b0',
    '#d8009c',
    '#186fff',
    '#2c0068',
    '#ff1d96',
    '#56006c',
    '#056c00',
    '#5d8300',
    '#76006b',
    '#bc9300',
    '#b985ff',
    '#a20067',
    '#caef97',
    '#c90057',
    '#dc6a00',
    '#7c7400',
    '#ff6d22',
    '#dd0049',
    '#003f91',
    '#3d8dff',
    '#e2e89a',
    '#da5500',
    '#95004a',
    '#e13200',
    '#ffa251',
    '#200029',
    '#ff6dac',
    '#00d8c0',
    '#365100',
    '#cd002c',
    '#009c74',
    '#710035',
    '#a50030',
    '#725c00',
    '#2e001b',
    '#005e34',
    '#0b3500',
    '#ff8f52',
    '#ffb574',
    '#f4e1a8',
    '#c0a6ff',
    '#00e4ff',
    '#ff5f3d',
    '#b31200',
    '#974600',
    '#004c2c',
    '#ffa1e0',
    '#01afa2',
    '#00031c',
    '#dcb3ff',
    '#590001',
    '#360300',
    '#7af5f2',
    '#c2edce',
    '#003157',
    '#ff6a69',
    '#142400',
    '#f4e0bc',
    '#00604f',
    '#473300',
    '#0299cb',
    '#510f00',
    '#01639a',
    '#02abb4',
    '#7cbcff',
    '#ffb798',
    '#ff8284',
    '#00342c',
    '#95deff',
    '#007f9a',
    '#001104',
    '#afecff',
    '#221500',
    '#ffc9f3',
    '#ff9ea2',
    '#d7e5e9',
    '#ffd1cc',
    '#014f61'
  ],
  94: [
    '#89a900',
    '#3137de',
    '#6fff68',
    '#a14fff',
    '#e32fe6',
    '#c952ff',
    '#ff03c4',
    '#00a417',
    '#afbe00',
    '#01178b',
    '#60008c',
    '#c4f26a',
    '#ffe14e',
    '#00ca66',
    '#ff71fd',
    '#0137b1',
    '#d9009d',
    '#2a6fff',
    '#2c0068',
    '#ff1d96',
    '#56006d',
    '#830078',
    '#066c00',
    '#5e8300',
    '#bc9300',
    '#b985ff',
    '#a20067',
    '#caef97',
    '#250035',
    '#cb0058',
    '#dc6a00',
    '#7c7400',
    '#dd0049',
    '#ff6d22',
    '#01469a',
    '#e2e899',
    '#960049',
    '#4a91ff',
    '#da5500',
    '#e13200',
    '#ffa251',
    '#ff6ca8',
    '#cd002c',
    '#00d8c0',
    '#365100',
    '#009c74',
    '#1f0024',
    '#720035',
    '#a50030',
    '#725c00',
    '#0b3500',
    '#005e34',
    '#310019',
    '#ff8f52',
    '#c0a6ff',
    '#ffb574',
    '#f4e1a8',
    '#01e5ff',
    '#ff5f3d',
    '#b31200',
    '#984700',
    '#004c2c',
    '#ffa2e0',
    '#01afa2',
    '#00041c',
    '#dcb3ff',
    '#590001',
    '#01cdd2',
    '#360300',
    '#7af5f2',
    '#c2edce',
    '#003257',
    '#ff6a69',
    '#142400',
    '#f4e0bc',
    '#00604f',
    '#483400',
    '#0099cc',
    '#510f00',
    '#01649b',
    '#7cbdff',
    '#ffb798',
    '#ff8284',
    '#00332a',
    '#95deff',
    '#001104',
    '#007f9a',
    '#ffc9f3',
    '#afecff',
    '#231600',
    '#ff9ea2',
    '#d7e5e9',
    '#004f61',
    '#ffd1cc'
  ],
  95: [
    '#8aa800',
    '#3532d9',
    '#6bff58',
    '#a14fff',
    '#e328e2',
    '#cb4fff',
    '#ff03c4',
    '#01178b',
    '#0166fc',
    '#60008b',
    '#00a417',
    '#afbe00',
    '#0037b0',
    '#ff71fd',
    '#2c0068',
    '#d9009c',
    '#c4f26a',
    '#ffe24e',
    '#00ca66',
    '#56006d',
    '#ff1d96',
    '#7f0072',
    '#ba85ff',
    '#066c00',
    '#5e8300',
    '#a20067',
    '#bc9300',
    '#7f8aff',
    '#250035',
    '#cc0058',
    '#caef97',
    '#014294',
    '#6bfbc7',
    '#dd0049',
    '#960049',
    '#0291fc',
    '#dc6a00',
    '#7c7400',
    '#ff6d22',
    '#ff669e',
    '#e2e899',
    '#cd002c',
    '#1f0024',
    '#720035',
    '#da5500',
    '#ffa251',
    '#e13200',
    '#a50030',
    '#00d8c0',
    '#375100',
    '#c1a6ff',
    '#019c74',
    '#300019',
    '#725c00',
    '#0b3500',
    '#005e34',
    '#ffa1de',
    '#ff8f51',
    '#b31200',
    '#ffb574',
    '#f4e1a8',
    '#00041c',
    '#deb4ff',
    '#02e5ff',
    '#ff5f3d',
    '#590001',
    '#994800',
    '#360300',
    '#003257',
    '#004c2c',
    '#01afa1',
    '#ff6a69',
    '#7af6f2',
    '#c2ecce',
    '#00649a',
    '#510f00',
    '#142400',
    '#f4e0bc',
    '#00604f',
    '#ff8284',
    '#483400',
    '#0099cb',
    '#7cbeff',
    '#ffc9f3',
    '#ffb798',
    '#001104',
    '#003228',
    '#95deff',
    '#231700',
    '#007f9a',
    '#ff9ea2',
    '#afecff',
    '#004f61',
    '#d7e5e9',
    '#ffd1cc'
  ],
  96: [
    '#8ea700',
    '#3431d9',
    '#70ff71',
    '#a14fff',
    '#e126e0',
    '#cb4fff',
    '#00a417',
    '#ff03c4',
    '#afbe00',
    '#c4f26a',
    '#ffe24e',
    '#01178b',
    '#00ca66',
    '#60008b',
    '#0267fe',
    '#ff71fc',
    '#0037b0',
    '#d9009c',
    '#2c0068',
    '#ff1d96',
    '#56006d',
    '#076d00',
    '#5e8300',
    '#bc9300',
    '#7b006d',
    '#caef97',
    '#ba85ff',
    '#a20067',
    '#6bfbc8',
    '#dc6a00',
    '#7c7400',
    '#0148a5',
    '#7f8aff',
    '#cb0058',
    '#ff6d22',
    '#e3e899',
    '#250035',
    '#dd0049',
    '#da5500',
    '#ffa251',
    '#ff96f5',
    '#e13200',
    '#00d8c0',
    '#365100',
    '#950047',
    '#019c74',
    '#cd002c',
    '#725c00',
    '#720035',
    '#0b3500',
    '#1f0024',
    '#005e34',
    '#a50030',
    '#ff8f51',
    '#ffb574',
    '#f4e1a8',
    '#03e5ff',
    '#014d95',
    '#9c4c00',
    '#ff5f3d',
    '#2e0017',
    '#323c00',
    '#b41200',
    '#004c2c',
    '#c1a6ff',
    '#02afa1',
    '#7af6f2',
    '#c3ecce',
    '#00041b',
    '#590001',
    '#142400',
    '#370300',
    '#f4e0bc',
    '#dbb4ff',
    '#00604f',
    '#ff6a69',
    '#831400',
    '#483400',
    '#003257',
    '#0199cb',
    '#510f00',
    '#81beff',
    '#ffb797',
    '#95deff',
    '#01649a',
    '#ffa8d0',
    '#ff8284',
    '#001103',
    '#007f9a',
    '#afecff',
    '#231700',
    '#ffc9f3',
    '#d7e5e9',
    '#ffd1cc',
    '#005060',
    '#ff9ea2'
  ],
  97: [
    '#8ba900',
    '#3432d9',
    '#00a417',
    '#a14fff',
    '#e128e1',
    '#cb4fff',
    '#75fe8a',
    '#afbe00',
    '#c4f26a',
    '#ffe24f',
    '#ff03c4',
    '#00ca66',
    '#0166fe',
    '#ff71fd',
    '#01178b',
    '#60008b',
    '#056d00',
    '#5d8300',
    '#bd9300',
    '#d9019c',
    '#0037b0',
    '#2c0068',
    '#ff1d96',
    '#caef97',
    '#56006d',
    '#dd6b00',
    '#ba85ff',
    '#7c7400',
    '#ff6d22',
    '#7b006d',
    '#e2e899',
    '#da5500',
    '#ffa251',
    '#e13200',
    '#a20067',
    '#00d8c0',
    '#365100',
    '#7f8aff',
    '#019c74',
    '#ff97f6',
    '#ca0058',
    '#dd0049',
    '#0045a1',
    '#725c00',
    '#250035',
    '#0b3500',
    '#005e34',
    '#9d5500',
    '#ffb674',
    '#f4e1a8',
    '#ff9154',
    '#00e5ff',
    '#ce002d',
    '#950048',
    '#b70500',
    '#ff623f',
    '#ff5e94',
    '#303b00',
    '#004c2c',
    '#02afa1',
    '#a50030',
    '#720035',
    '#993300',
    '#1f0024',
    '#7af6f3',
    '#00ccd1',
    '#c1a6ff',
    '#c3ecce',
    '#005caa',
    '#142400',
    '#f4e0bc',
    '#2d0017',
    '#00604f',
    '#473400',
    '#0199cb',
    '#dbb4ff',
    '#590001',
    '#81beff',
    '#ffb797',
    '#00041c',
    '#360300',
    '#95deff',
    '#003257',
    '#ff6a6a',
    '#00649a',
    '#001104',
    '#007f9a',
    '#510f00',
    '#afecff',
    '#ffa9d0',
    '#221700',
    '#ffc9f3',
    '#ff8284',
    '#d7e5e9',
    '#ffd1cc',
    '#005060',
    '#ff9ea2'
  ],
  98: [
    '#88ad00',
    '#3431d9',
    '#00a418',
    '#a14fff',
    '#e129e2',
    '#ca4fff',
    '#afbe00',
    '#c4f26b',
    '#72fd96',
    '#fee250',
    '#ff03c4',
    '#00ca66',
    '#0166ff',
    '#ff71fd',
    '#036d00',
    '#60008b',
    '#5d8300',
    '#01178b',
    '#bd9400',
    '#d9009d',
    '#0037b0',
    '#ff1d96',
    '#caef97',
    '#2c0068',
    '#dd6b00',
    '#ba85ff',
    '#7c7400',
    '#56006d',
    '#ff6d22',
    '#e2e89a',
    '#da5600',
    '#e13200',
    '#ffa251',
    '#7a006e',
    '#365100',
    '#00d8c0',
    '#7f8aff',
    '#009c74',
    '#ff98f9',
    '#ae6100',
    '#a20067',
    '#715c00',
    '#00429c',
    '#0a3500',
    '#005e34',
    '#dd0049',
    '#c80056',
    '#ffb675',
    '#f4e1a8',
    '#ff9256',
    '#ff6cae',
    '#03e5ff',
    '#b80200',
    '#250035',
    '#ce002d',
    '#ff633f',
    '#2d3900',
    '#004d2c',
    '#95004a',
    '#00afa2',
    '#016fc6',
    '#9a3400',
    '#7af6f3',
    '#c1a6ff',
    '#a60031',
    '#c4ecce',
    '#720035',
    '#132400',
    '#f4e0bc',
    '#7b3700',
    '#1f0024',
    '#00614f',
    '#443300',
    '#0199cb',
    '#dbb4ff',
    '#2d0017',
    '#00a2a7',
    '#ffb798',
    '#82bfff',
    '#00041c',
    '#590001',
    '#95deff',
    '#ff6a6a',
    '#003257',
    '#360300',
    '#00649a',
    '#001104',
    '#007f9a',
    '#ffa9d1',
    '#afecff',
    '#211600',
    '#510f00',
    '#ffc9f3',
    '#ff8284',
    '#d7e5e9',
    '#ffd1cc',
    '#005060',
    '#ff9ea2'
  ],
  99: [
    '#89a800',
    '#3431d8',
    '#71ff7d',
    '#a14fff',
    '#e128e2',
    '#ca4fff',
    '#00a215',
    '#afbe00',
    '#c5f269',
    '#ff03c4',
    '#ffe24d',
    '#01c964',
    '#01178b',
    '#60008b',
    '#0466ff',
    '#ff71fd',
    '#d9009c',
    '#0037b0',
    '#2c0068',
    '#086c00',
    '#5d8300',
    '#ff1d96',
    '#bd9400',
    '#56006d',
    '#caef96',
    '#7a006e',
    '#dd6b00',
    '#ba85ff',
    '#7c7400',
    '#a20067',
    '#ff6d22',
    '#e4e798',
    '#da5500',
    '#e13200',
    '#ffa251',
    '#c70056',
    '#00429d',
    '#dd0049',
    '#250035',
    '#365100',
    '#02d8c0',
    '#7f8aff',
    '#ff97f8',
    '#009b73',
    '#ae6100',
    '#92f6ce',
    '#950049',
    '#715c00',
    '#ce002d',
    '#0a3500',
    '#005e34',
    '#ff6bac',
    '#f4e1a8',
    '#ffb675',
    '#ff9255',
    '#02e3fe',
    '#b80200',
    '#720035',
    '#a60031',
    '#1f0024',
    '#ff623f',
    '#004c2c',
    '#01aea0',
    '#016ac0',
    '#2e0017',
    '#9a3300',
    '#c1a6ff',
    '#75f6f6',
    '#c7ebcf',
    '#142400',
    '#f4e0bc',
    '#7b3600',
    '#00604f',
    '#590001',
    '#00041c',
    '#443300',
    '#360300',
    '#dbb4ff',
    '#0099cb',
    '#019796',
    '#ff6a6a',
    '#003226',
    '#ffb798',
    '#82beff',
    '#003257',
    '#510f00',
    '#95deff',
    '#01649a',
    '#007f9a',
    '#001104',
    '#ffa9d1',
    '#aeebff',
    '#ff8284',
    '#211600',
    '#ffc9f3',
    '#d7e5e9',
    '#ffd1cc',
    '#004f60',
    '#ff9ea2'
  ],
  100: [
    '#89ab00',
    '#3431d8',
    '#00a315',
    '#a14fff',
    '#71fe89',
    '#e129e2',
    '#ca4fff',
    '#afbe00',
    '#c5f26a',
    '#ffe24e',
    '#ff03c4',
    '#01c965',
    '#0d66ff',
    '#ff71fd',
    '#01178b',
    '#60008b',
    '#066d00',
    '#5d8300',
    '#d9009d',
    '#bd9400',
    '#0037b0',
    '#2c0068',
    '#ff1d96',
    '#caef96',
    '#56006d',
    '#dd6b00',
    '#ba85ff',
    '#7b006f',
    '#7c7400',
    '#ff6d22',
    '#e4e898',
    '#da5500',
    '#e13200',
    '#ffa251',
    '#a20067',
    '#b76500',
    '#355100',
    '#02d8c0',
    '#7f8aff',
    '#ff98fa',
    '#009b73',
    '#dd0049',
    '#92f6ce',
    '#c50055',
    '#715d00',
    '#250035',
    '#014098',
    '#0a3500',
    '#005e34',
    '#f4e1a8',
    '#ffb675',
    '#ce002d',
    '#95004a',
    '#ff9356',
    '#ff71b5',
    '#b90000',
    '#03e4fe',
    '#ff623f',
    '#004d2c',
    '#a60031',
    '#01aea0',
    '#720035',
    '#0076d0',
    '#1e0024',
    '#9b3400',
    '#c1a6ff',
    '#75f6f6',
    '#c8ebcf',
    '#2e0018',
    '#823800',
    '#132400',
    '#f4e0bc',
    '#00604f',
    '#403300',
    '#dbb4ff',
    '#0099cb',
    '#623100',
    '#009898',
    '#590001',
    '#360300',
    '#00041c',
    '#003429',
    '#ffb798',
    '#82bfff',
    '#ff6a6a',
    '#95deff',
    '#003257',
    '#01649a',
    '#001104',
    '#007f9a',
    '#510f00',
    '#ffa9d3',
    '#aeebff',
    '#201500',
    '#ffc9f3',
    '#ff8284',
    '#d7e5e9',
    '#ffd1cc',
    '#005061',
    '#ff9ea2'
  ]
}
export default function GetDistinctColors(numberOfColors) {
  numberOfColors = numberOfColors > 0 ? numberOfColors : 1
  const numberOfDistinctColor = _.size(distinctColors)
  const result = []

  for (let i = numberOfDistinctColor; i <= numberOfColors; i += numberOfDistinctColor) {
    result.push(...distinctColors[numberOfDistinctColor])
  }
  if (numberOfColors % numberOfDistinctColor !== 0) {
    result.push(...distinctColors[numberOfColors % numberOfDistinctColor])
  }

  return result
}
