Ext.define('MDM.src.view.globalapp.Panel.Dpi.Panel.ProductGroup', {
  extend: 'MDM.src.ux.GridPanel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-productgroup',
  // ui: 'panel-inverse',
  border: true,
  title: 'Product Group',
  iconCls: 'fa fa-cubes',
  rowLines: true,
  columnLines: true,
  tools: [
    {
      type: 'plus',
      tooltip: 'Add new ProductGroup',
      bind: {
        hidden: '{readOnlyMode}'
      },
      handler: 'onAddProductGroup'
    }
  ],
  listeners: {
    select: 'onProductGroupSelect',
    rowcontextmenu: 'onMoveRecord'
  },
  viewConfig: {
    stripeRows: false,
    plugins: [
      {
        ptype: 'uxgridviewdragdrop',
        id: 'dd',
        containerScroll: true,
        dragGroup: 'dd-grid-to-grid-group1',
        dropGroup: 'dd-grid-to-grid-group3'
      }
    ],
    listeners: {
      beforedrop: 'onbeforeDropToProductGroup'
    }
  },
  features: [
    {
      ftype: 'summary',
      dock: 'top'
    }
  ],
  columns: [
    {
      xtype: 'rownumberer'
    },
    {
      text: 'Product Group',
      dataIndex: 'ProductGroup',
      flex: 2,
      menuDisabled: true,
      summaryType: 'count',
      summaryRenderer: function (value) {
        return Ext.String.format('<b>{0} Product Group{1}</b>', value, value > 1 ? 's' : '')
      }
    },
    {
      text: 'DPI Family Cnt',
      tooltip: 'DPI Family Count',
      dataIndex: 'DPIFamilyCount',
      menuDisabled: true,
      align: 'right',
      summaryType: 'sum',
      flex: 1,
      summaryRenderer: function (value) {
        return '<b>' + value + '<b>'
      }
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      align: 'center',
      menuDisabled: true,
      bind: {
        hidden: '{readOnlyMode}'
      },
      items: [
        {
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          iconCls: 'cell-editing-delete-row',
          tooltip: 'Delete DPI Product Group',
          handler: 'onProductGroupDelete'
        }
      ]
    },
    {
      xtype: 'actioncolumn',
      width: 30,
      menuDisabled: true,
      align: 'center',
      bind: {
        hidden: '{readOnlyMode}'
      },
      items: [
        {
          iconCls: 'fa fa-pencil-square-o',
          isActionDisabled: function (grid, rowIndex, colIndex, item, record) {
            return !record.get('isEditable')
          },
          tooltip: 'Edit',
          handler: 'onEditProductGroup'
        }
      ]
    }
  ]
})
