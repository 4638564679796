import { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { StatesToPropsExtra, StatesToPropsProps, SetDataArgsKey } from './StatesToProps.types'

const defaultArgs = {}
const StatesToProps = ({ onChange, children }: StatesToPropsProps) => {
  const [args, setArgs] = useState(null)
  const [setDataArgsKey, saveSetDataArgsKey] = useState<SetDataArgsKey>()
  const [force, setForce] = useState(false)

  const setDataArguments = useCallback(
    (newArgs, newForce = false, newSetDataArgsKey) => {
      if (!newForce && !_.isEqual(newArgs, args)) {
        setArgs(newArgs)
        saveSetDataArgsKey(newSetDataArgsKey)
        onChange({ args: newArgs })
      } else if (newForce) {
        // If the plugin is forced to fetch data, use the last filters
        setArgs(args)
        setForce(newForce)
        saveSetDataArgsKey(newSetDataArgsKey)
        onChange({ args, force: newForce })
      }
    },
    [args, onChange]
  )

  const memoizedProps: StatesToPropsExtra = useMemo(() => {
    return {
      force,
      setDataArgsKey,
      setDataArguments,
      // TODO: Improve args check
      args: _.isNil(args) ? defaultArgs : args
    }
  }, [force, setDataArgsKey, setDataArguments, args])

  return children(memoizedProps)
}

export default StatesToProps
