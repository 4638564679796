import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import styles from './index.module.scss'

export default function Success({ isLoading, onMount }) {
  useEffect(onMount, [])

  return (
    <div className={styles.successView}>
      {isLoading ? (
        <>
          <Spinner animation="border" role="status" variant="dark" />
          <span>Moving productions, please wait...</span>
        </>
      ) : (
        <p>
          <i className={`fa-fw fa slvy-ui-icon-check-circle-regular ${styles.successViewIcon}`} />
          <span>Productions moved successfully!</span>
        </p>
      )}
    </div>
  )
}
