Ext.define('MDM.src.view.regionapp.model.Summary', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/RMDM_Material_Summary',
  fields: [
    {
      name: 'Assigned',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'UnAssigned',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'NoSupplysource',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'TotalNew',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'UnprocessedNew',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'Dropped',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'CurrentRegion',
      type: 'string',
      defaultValue: ' '
    }
  ]
})
