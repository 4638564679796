import React, { Component } from 'react'

export class SidebarCellOptions extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false
  }

  render() {
    return (
      <div>
        <div className="cell-option">Description</div>
        <div className="cell-option">Name</div>
        <div className="cell-option">Price</div>
        <div className="cell-option">Forecasted Quantity</div>
        <div className="cell-option">Buy Quantity Final</div>
      </div>
    )
  }
}
