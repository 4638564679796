import { getBarOptions } from '../sparkline'
import { SparklineOptions } from '../../../components/CellRenderers/SparklineColumnCellRenderer/SparklineColumnCellRenderer.types'
import { sparklineOptions as defaultSparklineOptions } from './mocks'

function getSparklineOptions() {
  return { ...defaultSparklineOptions }
}

describe('AgGrid:Helpers:Common:getBarOptions', () => {
  let sparklineOptions: SparklineOptions = getSparklineOptions()
  beforeEach(() => {
    sparklineOptions = getSparklineOptions()
  })

  it('should return an object includes fill parameter equals to columnColor', () => {
    sparklineOptions.columnColor = '#f80'
    const result = getBarOptions(sparklineOptions)
    expect(result.fill).toEqual('#f80')
    expect(result).toMatchObject({
      fill: '#f80',
      formatter: expect.any(Function),
      highlightStyle: {
        fill: '#f44'
      },
      paddingInner: 0.1,
      paddingOuter: 0.1,
      stroke: '#91cc75',
      type: 'column'
    })
  })

  it('should return an object includes highlightStyle.fill which equals to highlightColor', () => {
    sparklineOptions.highlightColor = '#fff'
    const result = getBarOptions(sparklineOptions)
    expect(result.highlightStyle.fill).toEqual('#fff')
    expect(result).toMatchObject({
      fill: '#ddd',
      formatter: expect.any(Function),
      highlightStyle: {
        fill: '#fff'
      },
      paddingInner: 0.1,
      paddingOuter: 0.1,
      stroke: '#91cc75',
      type: 'column'
    })
  })
})
