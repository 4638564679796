Ext.define('MDM.view.btlv2.dialog.MaterialDialog', {
  extend: 'Ext.window.Window',
  height: 500,
  width: 650,
  layout: 'fit',
  // modal:true,
  ui: 'window-inverse',
  xtype: 'materialdialog',
  closeAction: 'destroy',
  bodyPadding: 5,
  tools: [
    {
      iconCls: 'fa fa-clone',
      tooltip: 'Close all',
      // hidden:true,
      handler: 'onCloseAllWin'
    }
  ],
  initComponent: function () {
    var me = this
    me.buildStore()
    this.grid = this.buildGrid()
    this.items = [this.grid]
    me.callParent()
  },
  buildGrid: function () {
    var columns = [
      {
        text: 'Data Source',
        dataIndex: 'DataSource',
        width: 100,
        summaryType: 'count',
        summaryRenderer: function (value, summaryData, dataIndex) {
          return ''
        },
        filter: {
          type: 'string'
        }
      },

      {
        text: 'Function',
        dataIndex: 'Function',
        width: 100,
        summaryType: 'count',
        summaryRenderer: function (value, summaryData, dataIndex) {
          return ''
        }
      },
      {
        text: '',
        dataIndex: 'ecolvalue',
        width: 100,
        format: '000.000',
        align: 'right',

        summaryType: 'count',

        filter: {
          type: 'string'
        }
      },

      {
        text: 'Material',
        dataIndex: 'Material',
        width: 100,
        summaryType: 'count',
        summaryRenderer: function (value, summaryData, dataIndex) {
          return Ext.String.format('{0} Material{1}', value, value !== 1 ? 's' : '')
        },
        filter: {
          type: 'string'
        }
      },
      {
        text: 'MaterialDescription',
        dataIndex: 'MaterialDescription',
        flex: 1,
        summaryType: 'count',
        summaryRenderer: function (value, summaryData, dataIndex) {},
        filter: {
          type: 'string'
        }
      },
      {
        text: 'Value',
        dataIndex: 'Value',
        width: 100,
        format: '000.000',
        align: 'right',
        xtype: 'numbercolumn',
        summaryType: 'sum',
        summaryRenderer: function (value, summaryData, dataIndex) {
          return Ext.util.Format.number(value, '0,000.000')
        },
        filter: {
          type: 'number'
        }
      }
    ]
    return Ext.create('Ext.grid.Panel', {
      tbar: [
        '->',
        {
          xtype: 'button',
          ui: 'btn-success',
          text: 'Export',
          iconCls: 'fa fa-download',
          handler: function () {
            this.up('grid').saveDocumentAs({
              type: 'xlsx',
              fileName: 'Export.xlsx'
            })
          }
        }
      ],
      viewConfig: {
        enableTextSelection: true
      },
      columns: columns,
      plugins: [
        {
          ptype: 'gridfilters'
        },
        {
          ptype: 'clipboard',
          system: 'raw',
          memory: true,
          putTextData: function (data, format) {
            return false
          }
        },
        'gridexporter'
        //   {
        //     ptype: 'cellediting',
        //     clicksToEdit: 2
        // },
      ],
      features: [
        {
          ftype: 'summary',
          dock: 'top'
        }
      ],
      border: true,
      columnLines: true,
      deferEmptyText: false,
      emptyText: 'No record found',
      minHeight: 200,
      store: this.store
    })
  },
  buildStore: function () {
    var me = this
    this.store = Ext.create('Ext.data.Store', {
      fields: [
        {
          name: 'Material',
          type: 'string'
        },
        {
          name: 'MaterialDescription',
          type: 'string'
        },
        {
          name: 'Value',
          type: 'number'
        }
      ],
      proxy: {
        type: 'ajax',
        actionMethods: {
          create: 'POST',
          read: 'POST',
          update: 'POST',
          destroy: 'POST'
        },
        url: MDM.Global.getApiUrl() + 'gillettev3/getBTLMaterialList'
      },
      autoLoad: false
    })

    this.store.on('load', me.changeColumnName, me)
  },
  changeColumnName: function () {
    var grid = this.down('grid')

    var col = grid.getColumns()[2]

    var rec = this.store.first()

    var text = rec ? rec.get('ecolname') : 'Undefined'
    col.setText(text)
  }
})
