/* global Ext, clustering */
import React, { Component } from 'react'
import numeral from 'numeral'
import { ExtContainer } from '@sencha/ext-react-classic'

import L from 'leaflet'
import 'leaflet-third-party-libraries'
import 'leaflet/dist/leaflet.css'

/* 3rd-party imports */
import jquery from 'jquery'
import echarts from 'echarts'
import echartsgl from 'echarts-gl'
import excelReader from 'read-excel-file'

import createPlugin from '@/BasePlugin'
import { ExtRoot } from '@/components'
import { getExtContainerSize, getPluginSize } from '@/helpers'
import { API_URL } from '@/constants'

// EXT_PACKAGE
import './ext-package/sass/etc/all.scss'
require('./ext-package')

class ClusteringCmp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterArgs: {},
      attributeFilterArgs: [],
      size: getPluginSize(props.size, props.isPreviewMode)
    }

    Ext.define('clustering.ExtGlobal', {
      singleton: true,
      config: {
        dragonApiUrl: `${API_URL}/data/${props.id}/invoke/`,
        authToken: props.token,
        pluginId: props.id
      },
      constructor: function (config) {
        var me = this
        me.initConfig(config)
      }
    })

    /* HACK : 3rd-party imports */
    window.$ = jquery
    window.echarts = echarts
    window.echartsgl = echartsgl
    window.numeral = numeral

    Ext.require('Ext.button.*')
    Ext.require('Ext.calendar.*')
    Ext.require('Ext.exporter.*')
    Ext.require('Ext.form.*')
    Ext.require('Ext.grid.*')
    Ext.require('Ext.panel.*')
    Ext.require('Ext.ux.*')
    Ext.require('Ext.window.Toast')

    Ext.require('clustering.*')

    var defaultHeaders = Ext.Ajax.getDefaultHeaders() || {}

    if (!defaultHeaders['Authorization']) {
      defaultHeaders['environment'] = props.params.environment
      defaultHeaders['Authorization'] = 'Bearer ' + props.token
      Ext.Ajax.setDefaultHeaders(defaultHeaders)
    }

    Ext.Ajax.addListener('beforerequest', function (conn, options, eOpts) {
      options.url = options.url.replace(
        '###CLUSTERING_API_URL###',
        `${clustering.ExtGlobal.getDragonApiUrl()}`
      )
      options.url = options.url.replace('/cluster/', '/')
      options.url = options.url.replace('/Cluster/', '/')
      options.url = options.url.replace('/librarytree/', '/')
    })
  }

  componentDidMount() {
    this.props.reloadExtRoot(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If size of the grid changes do not render the grid
    const widthChanged = nextProps.size.width !== this.props.size.width
    const heightChanged = nextProps.size.height !== this.props.size.height

    if (widthChanged || heightChanged) {
      setTimeout(() => this.setState({ size: getExtContainerSize(this.props.id) }), 100)
    }
  }

  render() {
    const {
      settings: {
        config: { settings: { applicationType = 'DC', language = 'TR' } = {} } = {}
      } = {},
      id = 'id',
      isPreviewMode
    } = this.props

    const packageProps = {
      xtype: 'clusteringmainview',
      isToolbarVisible: false,
      width: '100%',
      height: '100%',

      language,
      excelReader,
      applicationType,
      pluginInstanceId: id,
      mainGridFilters: this.state.attributeFilterArgs,
      ...this.state.filterArgs
    }

    const extContainerProps = {
      ...getPluginSize(this.state.size, isPreviewMode),
      id: `slvyExtContainer-${id}`,
      cls: 'slvy-ext-container',
      layout: 'fit',
      autoSize: true,
      items: [packageProps]
    }

    return (
      <ExtRoot pluginId={id}>
        <ExtContainer {...extContainerProps} />
      </ExtRoot>
    )
  }
}

const selectConnectorProps = (props) => ({
  size: props.size,
  isPreviewMode: props.isPreviewMode,
  id: props.id,
  token: props.token,
  params: props.params,
  reloadExtRoot: props.reloadExtRoot,
  settings: props.settings
})

export default createPlugin(ClusteringCmp, selectConnectorProps)
