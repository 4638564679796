import { Component, createRef } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import { FormControl, FormGroup, Button } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { v4 as uuidv4 } from 'uuid'
import createPlugin, { PluginTypes } from '@/BasePlugin'
import { SlvyProgress, slvyToast } from '@/components'
import pluginLoadingIcon from './pluginLoading2.gif'
import './style.scss'

class ImageGallery extends Component {
  constructor(props) {
    super(props)
    this.handleDrop = this.handleDrop.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleDefault = this.handleDefault.bind(this)
    this.eventOnClick = this.eventOnClick.bind(this)
    this.handleDragOver = this.handleDragOver.bind(this)
    this.handleDragLeave = this.handleDragLeave.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleEditSubmit = this.handleEditSubmit.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.setGroupKey = this.setGroupKey.bind(this)
    this.setReadOnly = this.setReadOnly.bind(this)
    this.findLastImageId = this.findLastImageId.bind(this)
    this.state = {
      showDrag: false,
      imageList: [],
      pendingList: [],
      activeIndex: -1,
      editMode: false
    }
    this.containerRef = createRef(null)
    this.figureListRef = createRef(null)
  }

  componentDidMount() {
    this.eventOnClick = this.props.registerEvent({
      key: 'onClick',
      fn: this.eventOnClick,
      returnTypes: {
        id: PluginTypes.fromString('string'),
        url: PluginTypes.fromString('string')
      }
    })

    this.eventFileUploadDone = this.props.registerEvent({
      key: 'fileUploadDone',
      fn: this.eventFileUploadDone,
      returnTypes: {
        urls: PluginTypes.arrayOf(PluginTypes.fromString('string'))
      }
    })

    this.onCommentUpdated = this.props.registerEvent({
      key: 'onCommentUpdated',
      fn: this.onCommentUpdated,
      returnTypes: {
        refreshKey: PluginTypes.fromString('string')
      }
    })

    this.onDefaultChanged = this.props.registerEvent({
      key: 'onDefaultChanged',
      fn: this.onDefaultChanged,
      returnTypes: {
        refreshKey: PluginTypes.fromString('string')
      }
    })

    this.onFileDeleted = this.props.registerEvent({
      key: 'onFileDeleted',
      fn: this.onFileDeleted,
      returnTypes: {
        refreshKey: PluginTypes.fromString('string')
      }
    })

    this.props.registerMethod({
      key: 'setGroupKey',
      fn: this.setGroupKey,
      args: [{ name: 'groupKey', type: PluginTypes.string }]
    })

    this.props.registerMethod({
      key: 'setReadOnly',
      fn: this.setReadOnly,
      args: [{ name: 'readOnly', type: PluginTypes.boolean }]
    })

    const {
      settings: { config: { settings: { groupKey = '', readOnly = false } = {} } = {} } = {}
    } = this.props

    this.setGroupKey({ groupKey })
    this.setReadOnly({ readOnly })

    setTimeout(() => {
      this.containerRef?.current?.scrollTo({ top: 0 })
    }, 1500)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      settings: {
        config: {
          settings: { groupKey: userInputGroupKey = 0 } = {},
          data: { groupKey: groupKeyColumn = '' } = {}
        } = {}
      } = {}
    } = nextProps

    let groupKey = userInputGroupKey

    if (groupKeyColumn && nextProps.pluginData && nextProps.pluginData[0]) {
      const row = nextProps.pluginData[0]
      groupKey = row[groupKeyColumn]
    }

    if (!_.isEqual(groupKey, this.state.groupKey)) {
      if (!_.isEmpty(groupKey)) {
        this.setGroupKey({ groupKey })
      }
    }
  }

  onCommentUpdated() {
    return { refreshKey: uuidv4() }
  }

  onDefaultChanged() {
    return { refreshKey: uuidv4() }
  }

  onFileDeleted() {
    return { refreshKey: uuidv4() }
  }

  setReadOnly({ readOnly }) {
    this.setState({
      readOnly
    })
  }

  setGroupKey({ groupKey }) {
    if (!groupKey) return
    const { id, client } = this.props
    this.setState({ pending: true, groupKey })
    client
      .post(`/data/${id}/Invoke/GetAll`, { data: { groupKey } })
      .then((result) => {
        this.setState({ pending: false, imageList: result })
        const { imageList = [] } = this.state
        if (!_.isEmpty(imageList)) {
          this.findLastImageId()
        } else {
          this.setState({
            showDrag: true
          })
        }
      })
      .catch(function (err) {
        console.log(err)
        slvyToast.error({
          message: 'An unknown error occurred!..',
          title: 'Error'
        })
      })
  }

  findLastImageId() {
    const { imageList = [] } = this.state
    const { Id } = imageList[0]
    const defaultImageId = _.findIndex(imageList, 'IsDefault')

    if (defaultImageId !== -1) {
      this.setState({
        activeIndex: imageList[defaultImageId].Id,
        showDrag: false
      })
    } else {
      this.setState({
        activeIndex: Id,
        showDrag: false
      })
    }
  }

  eventOnClick(id, url) {
    const { imageList = [] } = this.state
    const image = _.find(imageList, ({ Id }) => Id === id)
    if (image) {
      const { PreviewPath } = image
      return { id, url: PreviewPath }
    }
    return { id: undefined, url: undefined }
  }

  eventFileUploadDone(urls) {
    return { urls: _.map(urls) }
  }

  handleDrop(acceptedFiles) {
    const { id, client } = this.props
    const { groupKey } = this.state
    this.setState({ newPending: true, showDrag: false })
    client
      .post(`/data/${id}/Invoke/ImportFile`, {
        attach: {
          files: [..._.map(acceptedFiles, (file) => [file.name, file])],
          fields: [{ name: 'groupKey', value: groupKey }]
        }
      })
      .then((result) => {
        result = _.map(result, (r) => _.mapKeys(r, (v, k) => _.upperFirst(k)))
        const lastItem = _.last(result)
        this.handleDefault(lastItem.Id, lastItem.GroupKey)
        this.eventFileUploadDone(_.map(result, (r) => r.PreviewPath))
        this.setState({
          newPending: false,
          imageList: result
        })
      })
      .catch(() => {
        this.setState({ newPending: false })
      })
  }

  handleRemove(itemId) {
    return (e) => {
      e.stopPropagation()
      e.preventDefault()
      confirmAlert({
        title: 'Deleting Image?',
        message: 'Are you sure you want to delete this?',
        buttons: [
          {
            label: 'Cancel',
            onClick: () => {
              this.setState((state) => ({
                pendingList: _.filter(state.pendingList, (id) => id !== itemId)
              }))
            }
          },
          {
            label: 'Confirm Delete',
            onClick: () => {
              const { id, client } = this.props
              this.setState((state) => ({
                pendingList: [...state.pendingList, itemId]
              }))
              client
                .post(`/data/${id}/Invoke/RemoveFile`, {
                  data: { itemId }
                })
                .then((result) => {
                  this.setState((state) => ({
                    imageList: _.filter(state.imageList, ({ Id }) => Id !== itemId),
                    pendingList: _.filter(state.pendingList, (id) => id !== itemId)
                  }))
                  this.onFileDeleted()
                })
                .catch(() => {})
            }
          }
        ]
      })

      return false
    }
  }

  handleSelect(event, id) {
    event.stopPropagation()
    this.eventOnClick(id)
    this.setState({ activeIndex: id })
  }

  handleDefault(itemId, groupKey) {
    const { id, client } = this.props
    client
      .post(`/data/${id}/Invoke/SetAsDefault`, {
        data: { groupKey, itemId }
      })
      .then(() => {
        this.setState({
          activeIndex: itemId
        })
        this.setGroupKey({ groupKey })
        this.onDefaultChanged()
      })
      .catch(() =>
        slvyToast.error({
          message: 'An unknown error occurred!..',
          title: 'Error'
        })
      )
  }

  handleEdit(id) {
    return (e) => {
      e.stopPropagation()
      this.eventOnClick(id)
      const { imageList } = this.state
      const itemToEdit = _.find(imageList, (item) => item.Id === id)
      this.setState({
        editMode: true,
        itemToEdit
      })
    }
  }

  handleEditSubmit(e) {
    e.preventDefault()
    const { id, client } = this.props
    const {
      itemToEdit: { Description, Id },
      groupKey
    } = this.state
    client
      .post(`/data/${id}/Invoke/SetDescription`, {
        data: {
          itemId: Id,
          description: Description
        }
      })
      .then(() => {
        this.setState(() => ({
          editMode: false,
          itemToEdit: undefined
        }))
        this.setGroupKey({ groupKey })
        this.onCommentUpdated()
      })
  }

  handleChangeDescription(e) {
    const { value } = e.target
    this.setState((state) => ({
      itemToEdit: { ...state.itemToEdit, Description: value }
    }))
  }

  handleDragOver(e) {
    this.setState({ showDrag: true })
  }

  handleDragLeave(e) {
    this.setState({ showDrag: false })
  }

  handleHorizontalScroll = (event) => {
    const scrollAmount = event.deltaY
    const currentScroll = this.figureListRef.current.scrollLeft

    this.figureListRef.current.scrollTo({
      left: currentScroll + scrollAmount
    })
  }

  render() {
    const {
      size: { width = 0 },
      settings: {
        config: {
          settings: {
            multiple = true,
            mode = 'Gallery',
            showBrowser = true,
            showInfo = true,
            listType = 'Thumbnails',
            columnSize = 4,
            ratioType = 'cover',
            groupKey: userInputGroupKey = 0,
            containerBackground = '#fff',
            detailViewBackground = '#000000',
            thumbnailsDirection = 'right'
          } = {}
        } = {}
      } = {}
    } = this.props
    const columSize = width / columnSize
    const {
      imageList = [],
      pendingList = [],
      newPending = false,
      pending,
      editMode,
      itemToEdit,
      readOnly
    } = this.state

    const isThumbnail = listType === 'Thumbnails'
    const isList = listType === 'List'

    const isThumbnailwithBrowser = isThumbnail && showBrowser
    const isThumbnailLeft = isThumbnailwithBrowser && thumbnailsDirection === 'left'
    const isThumbnailBottom = isThumbnailwithBrowser && thumbnailsDirection === 'bottom'
    const isThumbnailTop = isThumbnailwithBrowser && thumbnailsDirection === 'top'
    const isThumbnailVertical = isThumbnailTop || isThumbnailBottom

    const containerHeight = this.containerRef?.current?.clientHeight

    const cellStyle = isThumbnail
      ? {
          width: columSize,
          height: columSize,
          maxWidth: `calc(${100 / columnSize}% - ${(columnSize - 1) * 4}px)`
        }
      : {
          height: isList ? containerHeight : 'inherit'
        }

    const imgStyle = isList ? { objectFit: ratioType } : {}

    const columnCount = listType === 'Masonry' ? { columnCount: columnSize } : {}

    const showDrag = imageList.length === 0 || this.state.showDrag

    const { groupKey = userInputGroupKey } = this.state

    if (_.isNil(groupKey)) {
      return <div>Wait for groupKey</div>
    }

    if (pending) {
      return <div className="colorpalettes-plugin">Loading</div>
    }

    let { activeIndex } = this.state

    if (imageList.length > 0) {
      if (mode === 'Single Image' && activeIndex === -1) {
        const defaultPinnedItem = _.find(imageList, (data) => data.IsDefault)

        if (defaultPinnedItem == null) activeIndex = imageList[0].Id
        else activeIndex = defaultPinnedItem.Id
      }
    }

    let preview = _.find(imageList, (data) => data.Id === activeIndex) || {}
    if (Object.keys(preview).length === 0 && isThumbnailwithBrowser) {
      preview = imageList?.[0]
    }

    const {
      Id: lookId,
      Name: lookName = '',
      PreviewPath: lookPreviewPath = '',
      CreatedDate: lookCreatedData = '',
      Description: lookDescription = ''
    } = preview ?? {}

    const isGallery = !_.isEmpty(preview) || isThumbnailwithBrowser

    return (
      <div
        ref={this.containerRef}
        className={cx('image-gallery-container', listType.toLowerCase(), {
          '-gallery': isGallery && !isList,
          '-ondrag': showDrag,
          '-show-info': showInfo
        })}
        style={{ backgroundColor: containerBackground }}
      >
        {editMode && (
          <div className="edit">
            <figure className="edit-preview">
              <img alt="" className="mw-100" src={itemToEdit.PreviewPath} />
            </figure>
            <span
              className="btn-close fa fa-close"
              onClick={() => {
                this.setState(() => ({
                  editMode: false
                }))
              }}
            />
            <form className="comment-container" onSubmit={this.handleEditSubmit}>
              <FormGroup className="mb-3">
                <FormControl
                  componentClass="textarea"
                  placeholder="Comment..."
                  size="sm"
                  value={itemToEdit.Description}
                  onChange={this.handleChangeDescription}
                />
                <Button className="btn-submit" size="sm" type="submit" variant="success">
                  Submit
                </Button>
              </FormGroup>
            </form>
          </div>
        )}
        {mode === 'Gallery' ? (
          <figure
            className={cx('image-gallery-preview', 'mb-0', {
              'flex-column-reverse': isThumbnailVertical
            })}
            style={{ backgroundColor: detailViewBackground }}
          >
            <div
              className={cx('position-relative', 'ig-img-container', {
                'w-75': showBrowser,
                'w-100': !showBrowser,
                'h-75 w-100': isThumbnailVertical,
                'h-100': !isThumbnailVertical,
                'order-1': isThumbnailLeft || isThumbnailBottom
              })}
            >
              {showInfo ? (
                <div
                  className={cx(
                    'position-absolute',
                    'start-0',
                    'end-0',
                    'lh-1',
                    'text-white',
                    'fs-sm',
                    'm-0',
                    'bg-dark',
                    'bg-opacity-50',
                    'p-3',
                    'fw-bold',
                    'opacity-0',
                    'info'
                  )}
                >
                  <span>
                    {lookDescription}
                    {!readOnly ? (
                      <span
                        className={cx('fa', 'fa-pencil', 'cp', { 'ms-2': lookDescription })}
                        onClick={this.handleEdit(lookId)}
                      />
                    ) : null}
                    <small className="d-block mt-2 fs-xs">
                      Added: {moment(lookCreatedData).format('MMM MM, YYYY')}
                    </small>
                  </span>
                </div>
              ) : null}
              {mode === 'Gallery' ? (
                <>
                  {isThumbnailwithBrowser ? (
                    <i
                      className="btn-trash fa fa-trash fs-6 m-3 end-0"
                      onClick={this.handleRemove(lookId)}
                    />
                  ) : null}
                  {!isThumbnailwithBrowser ? (
                    <span
                      className="btn-close fa fa-close pe-0"
                      onClick={(event) => this.handleSelect(event, -1)}
                    />
                  ) : null}
                </>
              ) : (
                <i className="btn-close fa fa-trash" onClick={this.handleRemove(activeIndex)} />
              )}
              {lookPreviewPath ? (
                <img
                  alt={lookName}
                  className={cx({ 'h-100': isThumbnailVertical })}
                  src={lookPreviewPath}
                  style={{ objectFit: ratioType }}
                />
              ) : (
                <div className="file-upload-item">No image to display</div>
              )}
            </div>
            <div
              ref={this.figureListRef}
              className={cx('w-25', 'fs-6', 'text-white', 'figure-list', {
                'd-none': !showBrowser,
                thumbnailLeft: isThumbnailLeft,
                thumbnailVertical: isThumbnailVertical,
                'w-100 h-25': isThumbnailVertical,
                'mb-1': isThumbnailTop,
                'mt-2': isThumbnailBottom
              })}
              onWheel={this.handleHorizontalScroll}
            >
              {showBrowser ? (
                <ul
                  className={cx('clearfix', {
                    'd-flex gap-2 h-100 p-0': isThumbnailVertical
                  })}
                >
                  {!readOnly && isThumbnailwithBrowser ? (
                    <SlvyProgress isLoading={newPending}>
                      <Dropzone
                        className={cx(
                          'd-flex justify-content-center align-items-center w-100 mb-2 cp bg-white bg-opacity-75 rounded-1 thumbnail-dropzone',
                          { top: isThumbnailVertical }
                        )}
                        disabled={readOnly}
                        multiple={multiple}
                        onDragLeave={this.handleDragLeave}
                        onDragOver={this.handleDragOver}
                        onDrop={this.handleDrop}
                      >
                        {readOnly ? null : (
                          <div className="text-black-50 fs-4 image-add-icon">
                            <i aria-hidden="true" className="fa fa-plus-circle" />
                          </div>
                        )}
                      </Dropzone>
                    </SlvyProgress>
                  ) : null}
                  {_.map(imageList, ({ Id, Name, PreviewPath, GroupKey, IsDefault }) => {
                    return (
                      <li
                        key={Id}
                        className="position-relative d-inline-block mb-1 overflow-hidden cp"
                      >
                        <img
                          alt={Name}
                          className={cx('w-100 rounded-1 pe-auto', {
                            'h-100': isThumbnailVertical
                          })}
                          src={PreviewPath}
                          onClick={(event) => this.handleSelect(event, Id)}
                        />
                        {isThumbnailwithBrowser && !readOnly ? (
                          <div className="position-absolute top-0 end-0 d-flex gap-1 mt-1 me-1 editButtons">
                            <i
                              className="btn-edit fa fa-pencil float-end fs-xs"
                              onClick={this.handleEdit(Id)}
                            />
                            <i
                              className={`btn-pin fa fa-thumb-tack float-end ${IsDefault} fs-xs`}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.handleDefault(Id, GroupKey)
                              }}
                            />
                            <i
                              className="btn-trash fa fa-trash float-end fs-xs"
                              onClick={this.handleRemove(Id)}
                            />
                          </div>
                        ) : null}
                      </li>
                    )
                  })}
                </ul>
              ) : null}
            </div>
          </figure>
        ) : null}

        {!isThumbnailwithBrowser ? (
          <SlvyProgress isLoading={newPending}>
            <Dropzone
              className="image-gallery-dropzone"
              disabled={readOnly}
              multiple={multiple}
              onDragLeave={this.handleDragLeave}
              onDragOver={this.handleDragOver}
              onDrop={this.handleDrop}
            >
              {showDrag ? (
                <div className="file-upload-item">
                  <i className="fa fa-cloud-upload" />
                  Drop files or click here to upload
                  <small>Only JPEG and PNG images will be accepted</small>
                </div>
              ) : (
                <div>
                  {(!isGallery && !_.isEmpty(imageList) && mode === 'Gallery') || isList ? (
                    <ul
                      className={cx('gallery-list', {
                        'gap-2': isThumbnail
                      })}
                      style={columnCount}
                    >
                      {_.map(
                        imageList,
                        ({
                          Id,
                          Name,
                          Description,
                          PreviewPath,
                          CreatedData,
                          GroupKey,
                          IsDefault
                        }) => {
                          const isLoading = _.find(pendingList, (pendingId) => pendingId === Id)

                          return (
                            <li
                              key={Id}
                              className={cx('bg-white', { 'order-first': IsDefault })}
                              style={cellStyle}
                            >
                              <figure className={cx('image', 'm-0', { 'h-100': isList })}>
                                <div
                                  className={cx('b-image-box', {
                                    'w-100': !showInfo && isList
                                  })}
                                  onClick={(event) =>
                                    isList ? event.stopPropagation() : this.handleSelect(event, Id)
                                  }
                                >
                                  <img
                                    alt={Name}
                                    className="img-fluid"
                                    src={isLoading ? pluginLoadingIcon : PreviewPath}
                                    style={imgStyle}
                                  />
                                  {!readOnly ? (
                                    <>
                                      <i
                                        className="btn-edit fa fa-pencil float-end"
                                        onClick={this.handleEdit(Id)}
                                      />
                                      <i
                                        className={`btn-pin fa fa-thumb-tack float-end ${IsDefault}`}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          this.handleDefault(Id, GroupKey)

                                          if (isList) {
                                            this.containerRef.current.scrollTo({ top: 0 })
                                          }
                                        }}
                                      />
                                      <i
                                        className="btn-trash fa fa-trash float-end"
                                        onClick={this.handleRemove(Id)}
                                      />
                                    </>
                                  ) : null}
                                </div>

                                <figcaption
                                  className="file-name"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {Description} <br />
                                  <small>Added: {moment(CreatedData).format('MMM MM, YYYY')}</small>
                                </figcaption>
                              </figure>
                            </li>
                          )
                        }
                      )}
                      {readOnly ? null : (
                        <div className="image-add-icon" style={cellStyle}>
                          <i aria-hidden="true" className="fa fa-plus-circle" />
                        </div>
                      )}
                    </ul>
                  ) : (
                    <div className="single-image-container">
                      <figure className="image mb-0">
                        <div className="single-image-box">
                          <img alt={lookName} className="img-fluid" src={lookPreviewPath} />

                          {!readOnly && (
                            <i
                              className="btn-trash fa fa-trash float-end"
                              onClick={this.handleRemove(lookId)}
                            />
                          )}
                        </div>

                        <figcaption className="file-name" onClick={(e) => e.stopPropagation()}>
                          <span style={{ fontSize: 14, paddingBottom: 8 }}>{lookDescription}</span>
                          {!readOnly && (
                            <span
                              className="fa fa-pencil"
                              style={{ paddingLeft: 10, cursor: 'pointer' }}
                              onClick={this.handleEdit(lookId)}
                            />
                          )}{' '}
                          <br />
                          <small>Added: {moment(lookCreatedData).format('MMM MM, YYYY')}</small>
                        </figcaption>
                      </figure>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </SlvyProgress>
        ) : null}
      </div>
    )
  }
}

const selectConnectorProps = (props) => ({
  registerEvent: props.registerEvent,
  registerMethod: props.registerMethod,
  pluginData: props.pluginData,
  settings: props.settings,
  id: props.id,
  client: props.client,
  size: props.size
})

export default createPlugin(ImageGallery, selectConnectorProps)
