Ext.define('MDM.src.view.Grouping.model.CusHie', {
  extend: 'MDM.src.model.Base',
  entityName: 'gillettev3/CusHie',
  idProperty: 'Id',
  fields: [
    {
      name: 'ItemCount',
      type: 'int',
      defaultValue: 0
    },
    {
      name: 'MegaRegion',
      type: 'string',
      defaultValue: 0
    },
    {
      name: 'isEditable',
      type: 'boolean',
      defaultValue: 0
    },
    {
      name: 'isPrivate',
      type: 'boolean',
      defaultValue: 0
    },

    {
      name: 'CreateDate',
      type: 'date',
      dateReadFormat: 'Y-m-d\\TH:i:s',
      dateWriteFormat: 'Y-m-d\\TH:i:s'
    }
  ]
})
