import { fork, take, call, cancel, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import Jobs from './jobs'

function* runWork(work, action) {
  const { init, transitions } = work
  if (_.size(transitions) <= 0) return
  let from = init
  const fn = (item) => {
    const { from: transitionfrom, fromV2 } = item
    const fromRes = fromV2 || transitionfrom
    return fromRes === from
  }
  let args = {}
  while (true) {
    let item = _.find(transitions, fn)
    if (!item) {
      from = 'A1'
      item = _.find(transitions, fn)
    }

    if (item) {
      const { name, params, to, toV2Success, toV2Fail } = item

      if (_.isFunction(Jobs[name])) {
        args = yield call([Jobs, name], params, args, action)
      }

      if (args && args.cancel === true) {
        from = toV2Fail || 'A1'
        args.cancel = false
      } else {
        from = toV2Success || to
      }
    }
  }
}

function* startWorkflow(action) {
  const { workflow } = action
  if (_.isArray(workflow)) {
    const f = []
    for (let i = 0; i < workflow.length; i += 1) {
      f[i] = yield fork(runWork, workflow[i], action)
    }
  }
}

function* watchRunWorkflow(action) {
  const {
    payload: { pageId }
  } = action
  const bgWorkflow = yield fork(startWorkflow, action)
  while (true) {
    // wait for the new action
    const {
      payload: { pageId: unMountPageId }
    } = yield take('START_WORKFLOW_SYNC_NOT')
    if (unMountPageId === pageId) {
      yield cancel(bgWorkflow)
    }
  }
}

const mySaga = [takeEvery('START_WORKFLOW_SYNC', watchRunWorkflow)]

export default mySaga
