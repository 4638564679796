import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Overlay, Popover } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import OptionSettings from '../OptionSettings'

export class SidebarFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShownSettings: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  handleToggle(isShownSettings) {
    this.setState({ isShownSettings })
  }

  render() {
    const {
      props: { activeSortBy = '' },
      state: { isShownSettings },
      target = null
    } = this

    return (
      <div className="filterContainer">
        <Button
          ref={(button) => (this.target = button)}
          size="sm"
          variant="outline-dark"
          onClick={this.handleToggle.bind(this, !isShownSettings)}
        >
          <small>{activeSortBy}</small>
          <span className="slvy-ui-icon-funnel" />
        </Button>
        <Overlay
          rootClose
          placement="bottom"
          show={isShownSettings}
          target={() => ReactDOM.findDOMNode(target)}
          onHide={this.handleToggle.bind(this, false)}
        >
          <Popover className="popover-product-filters" id="popover-product-filters">
            <OptionSettings />
          </Popover>
        </Overlay>
      </div>
    )
  }
}
