import { FILTER_DISALLOWED_COLUMN_TYPES } from '../../../constants'
import { getMenuTabs } from '../../headerMenu'
import { GetMenuTabsProps } from '../../headerMenu/headerMenu.type'

const getMenuTabsParams = (): GetMenuTabsProps => ({
  columnType: 'simple',
  grouping: false
})

describe('AgGrid:Helpers:headerMenu:getMenuTabs', () => {
  let params = getMenuTabsParams()

  beforeEach(() => {
    params = getMenuTabsParams()
  })

  it('should return empty object when columnType is simple', () => {
    const result = getMenuTabs(params)
    expect(result).toEqual({})
  })
  it('should return empty object when columnType is progress', () => {
    params.columnType = 'progress'
    const result = getMenuTabs(params)
    expect(result).toEqual({})
  })
  it('should return empty object when columnType is html', () => {
    params.columnType = 'html'
    const result = getMenuTabs(params)
    expect(result).toEqual({})
  })
  it('should return "columnsMenuTab" when columnType is sparkline', () => {
    params.columnType = 'sparkline'
    const result = getMenuTabs(params)
    expect(result).toEqual({ menuTabs: ['columnsMenuTab'] })
  })
  it('should return "columnsMenuTab" when columnType is highchart', () => {
    params.columnType = 'highchart'
    const result = getMenuTabs(params)
    expect(result).toEqual({ menuTabs: ['columnsMenuTab'] })
  })

  FILTER_DISALLOWED_COLUMN_TYPES.forEach((columnType) => {
    it(`should return menuTabs with "columnsMenuTab" when columnType is ${columnType} and grouping is false`, () => {
      params.columnType = columnType
      const result = getMenuTabs(params)
      expect(result).toEqual({ menuTabs: ['columnsMenuTab'] })
    })
    it(`should return menuTabs with "generalMenuTab" and "columnsMenuTab" when columnType is ${columnType} and grouping is true`, () => {
      params.columnType = columnType
      params.grouping = true
      const result = getMenuTabs(params)
      expect(result).toEqual({ menuTabs: ['generalMenuTab', 'columnsMenuTab'] })
    })
  })
})
