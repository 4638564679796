import { isEqual } from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelectorCreator, lruMemoize } from 'reselect'
import { data as dataSchema } from '../schema'
import { Config } from '../ChronoTimeline.types'

const { timeline: timelineSchema, card: cardSchema } = dataSchema.properties

const selectRawDataConfig = (dataConfig?: Config['data']) => dataConfig

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

const selectDataConfigs = createDeepEqualSelector(selectRawDataConfig, (dataConfig) => {
  const timeline: Config['data']['timeline'] = {
    title: dataConfig?.timeline?.title ?? timelineSchema.properties.title.default,
    icon: dataConfig?.timeline?.icon ?? timelineSchema.properties.icon.default
  }

  const card: Config['data']['card'] = {
    title: dataConfig?.card?.title ?? cardSchema.properties.title.default,
    text: dataConfig?.card?.text ?? cardSchema.properties.text.default,
    author: dataConfig?.card?.author ?? cardSchema.properties.author.default,
    isReadOnly: dataConfig?.card?.isReadOnly ?? cardSchema.properties.isReadOnly.default
  }

  return {
    timeline,
    card
  } satisfies Config['data']
})

export default selectDataConfigs
