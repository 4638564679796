Ext.define('MDM.src.view.globalapp.Panel.Dpi.Main', {
  extend: 'Ext.panel.Panel',
  // Uncomment to give this component an xtype
  xtype: 'globalapp-dpi-main',
  controller: 'globalapp-dpi-maincontroller',
  viewModel: 'globalapp-dpi-mainviewmodel',
  header: false,
  bodyPadding: 4,
  layout: {
    type: 'hbox',
    pack: 'start',
    align: 'stretch'
  },
  listeners: {
    activate: 'onDPIActive',
    boxready: 'onBoxReady'
  },
  items: [
    {
      xtype: 'globalapp-dpisegment',
      ui: 'panel-inverse',
      flex: 1,
      margin: '0 2 0 0',
      reference: 'dpisegment',
      bodyStyle: {
        background: '#fcfcfc'
      },
      bind: {
        store: '{DpiSegmentStore}',
        readOnlyMode: '{readOnlyMode}'
      }
    },
    {
      xtype: 'globalapp-productgroup',
      flex: 1,
      ui: 'panel-inverse',
      margin: '0 2 0 0',
      reference: 'productgroup',
      bodyStyle: {
        background: '#fcfcfc'
      },
      bind: {
        store: '{DpiProductGroupStore}',
        readOnlyMode: '{readOnlyMode}',
        title: 'Product Groups of <span style="color:yellow">' + '{DPISegment}' + '</span>'
      }
    },
    {
      xtype: 'globalapp-dpifamily',
      flex: 1,
      ui: 'panel-inverse',
      margin: '0 2 0 0',
      reference: 'dpifamily',
      bodyStyle: {
        background: '#fcfcfc'
      },
      bind: {
        store: '{DPIFamilyStore}',
        readOnlyMode: '{readOnlyMode}',
        title: 'DPI Families of <span style="color:yellow">' + '{ProductGroup}' + '</span>'
      }
    }
  ]
})
