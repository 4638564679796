Ext.define('Assortment.reader.ProductReader', {
  extend: 'Ext.data.reader.Json',
  alias: 'reader.productReader',
  getResponseData: function (response) {
    var result = response.responseJson
    return result.map(function (product) {
      return { display: product.name, id: product.id }
    })
  }
})
