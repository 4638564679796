Ext.define('MDM.src.ux.DragDrop', {
  extend: 'Ext.grid.plugin.DragDrop',
  alias: 'plugin.uxgridviewdragdrop',
  enable: function () {
    var me = this
    if (me.dropZone && me.dropZone.unlock != undefined) {
      me.dropZone.unlock()
    }
  },

  disable: function () {
    var me = this
    if (me.dropZone && me.dropZone.lock != undefined) {
      me.dropZone.lock()
    }
  }
})
