import { useState, useEffect, memo, CSSProperties } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { useEvent } from '@/hooks'
import containerValidation from '@/utils/containerValidation'
import withConnector from '@/BaseContainer'
import AddElement from '../components/AddElement'
import { View } from '..'
import Splitter from './Splitter'
import { panePosition, initialPaneSize, initialSplitterSize, directions } from './constants'
import { Direction, Order, Position, PositionId, PanePosition } from './SplitContainer.types'
import styles from './index.module.scss'

const SplitContainer = withConnector((props) => {
  const {
    plugins,
    containers,
    container: {
      data: {
        id: containerId,
        settings,
        settings: {
          form: {
            isCollapsedInitially = false,
            isSizeChangeable = false,
            panesize = initialPaneSize,
            horizontal: isHorizontal = true,
            step = 10,
            defaultPane = isHorizontal ? 'top' : 'right',
            splitOpenedTitle = '',
            splitCollapsedTitle = ''
          } = {},
          position = {}
        }
      }
    },
    customState,
    params,
    params: { environment, designMode },
    registerMethod,
    createState,
    showAddPluginLayout,
    onMounted,
    onSave
  } = props

  const defaultPaneSize: number = isCollapsedInitially
    ? directions[defaultPane as PanePosition]
    : panesize

  const [isLocked, setIsLocked] = useState<boolean>(false)
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedInitially)
  const [paneSize, setPaneSize] = useState<number>(defaultPaneSize)

  const hasEditPermission = environment !== 'Configuration'

  const handleCollapse = useEvent((status?: boolean) => {
    const collapsed = typeof status === 'boolean' ? status : !isCollapsed

    const newPaneSize: number = collapsed ? directions[defaultPane as PanePosition] : panesize

    setIsCollapsed(collapsed)
    setPaneSize(newPaneSize)
  })

  useEffect(() => {
    const workflowMethods = [
      {
        key: 'toggle',
        fn: handleCollapse
      },
      {
        key: 'lockSplit',
        fn: () => setIsLocked(true)
      },
      {
        key: 'unlockSplit',
        fn: () => setIsLocked(false)
      },
      {
        key: 'hideSplit',
        fn: () => setIsHidden(true)
      },
      {
        key: 'showSplit',
        fn: () => setIsHidden(false)
      },
      {
        key: 'openSplit',
        fn: () => handleCollapse(false)
      },
      {
        key: 'closeSplit',
        fn: () => handleCollapse(true)
      }
    ]

    workflowMethods.forEach(({ key, fn }) => {
      registerMethod({ key, fn, args: [] })
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 0)
  }, [isLocked, isHidden, isCollapsed, paneSize])

  const savePosition = (newPosition: Position) => {
    if (hasEditPermission) {
      createState('layout', { layout: newPosition })
    } else {
      onSave(
        {
          ...settings,
          position: newPosition
        },
        _.map(newPosition, (id) => id)
      )
    }
  }

  const getPosition = () => {
    if (hasEditPermission) {
      const userState = _.find(customState, (c) => c.name === 'layout')
      if (userState) {
        const { config: { layout: userLayout } = {} } = userState
        if (userLayout) return userLayout
      }
    }
    return position
  }

  const afterAddPlugin = (elementName: PositionId) => {
    return (id: string) => {
      const currentPosition = getPosition()
      const newPosition = _.transform(
        currentPosition,
        (result, itemId, key) => {
          if (itemId !== id) {
            result[key] = itemId
          }
        },
        {}
      )
      if (!containerValidation(containers, id)) {
        return
      }
      savePosition({ ...newPosition, [elementName]: id })
    }
  }

  const handleRemoveView = (id?: string) => {
    const currentPosition = getPosition()
    savePosition(_.pickBy(currentPosition, (pid) => pid !== id))
  }

  const handleAddPlugin = (elementName: PositionId) => {
    // for test uncomment following
    // this.props.addPlugin(this.afterAddPlugin(elementName))
    // for prod uncomment following
    showAddPluginLayout(afterAddPlugin(elementName))
  }

  const findPlugin = (elementName: PositionId) => {
    const { [elementName]: elementId = '' } = getPosition()

    let type
    const plugin = _.find(plugins, (p) => p.id === elementId)
    if (plugin) {
      type = 'plugin'
    } else {
      const container = _.find(containers, (p) => p.id === elementId)
      if (container) type = 'container'
    }

    if (type === 'plugin' || type === 'container') {
      return (
        <View
          key={elementId}
          containers={containers}
          draggableHandle={`move${containerId}`}
          id={elementId}
          params={params}
          plugins={plugins}
          showDeleteIcon={hasEditPermission}
          type={type}
          onMounted={onMounted}
          onRemovePlugin={handleRemoveView}
        />
      )
    }

    const isConfiguration = environment === 'Configuration'
    if (hasEditPermission || (isConfiguration && designMode)) {
      return <AddElement handleAddElement={() => handleAddPlugin(elementName)} />
    }

    return null
  }

  const createSplitPane = (prefix: 'panel1' | 'panel2') => {
    return findPlugin(`${prefix}_1`)
  }

  const getSplitColumnClass = (order: Order) => {
    const text = isHorizontal ? 'horizontal' : 'vertical'

    let classNames = 'overflow-hidden'

    const { order: _order = 1 } = panePosition[text][defaultPane] || {}

    if (isCollapsed) {
      if (_order !== order) {
        classNames = ' w-0 h-0 d-none'
      } else {
        classNames += ' flex-1'
      }
    }

    return classNames
  }

  const getSplitColumnStyle = (order: Order) => {
    const fullSize = 100

    const totalSize = 1
    let style: CSSProperties = {}

    if (isCollapsed) {
      return style
    }

    if (order === 1) {
      style.flex = paneSize * 0.01
    } else {
      style.flex = totalSize - paneSize * 0.01
    }

    const splitterSize = isHidden ? 0 : initialSplitterSize

    let halfWidth = splitterSize / 2
    if (style.flex >= 0.9) {
      halfWidth = splitterSize - (totalSize - style.flex) * fullSize
    } else if (style.flex <= 0.1) {
      halfWidth = style.flex * fullSize
    }

    const cssProp = `calc(${style.flex * fullSize}% - ${halfWidth}px)`

    if (isHorizontal) {
      style = { ...style, minHeight: cssProp, maxHeight: cssProp }
    } else {
      style = { ...style, minWidth: cssProp, maxWidth: cssProp }
    }

    return style
  }

  const handleChangePaneSize = (direction: Direction) => {
    const fullSize = 100
    const isSecondarySide = defaultPane === 'left' || defaultPane === 'top'
    const remainingPaneSize = isSecondarySide ? fullSize - panesize : panesize
    let newPaneSize = paneSize

    if (direction === '-') {
      newPaneSize -= (remainingPaneSize / fullSize) * step
    } else {
      newPaneSize += (remainingPaneSize / fullSize) * step
    }

    // it cannot be lower than initialPaneSize if it is secondary
    if (isSecondarySide && newPaneSize < panesize) {
      newPaneSize = panesize
    }

    if (newPaneSize > fullSize) {
      newPaneSize = fullSize
    } else if (newPaneSize < 0) {
      newPaneSize = 0
    }

    const collapsed = newPaneSize === directions[defaultPane as PanePosition]

    setIsCollapsed(collapsed)
    setPaneSize(newPaneSize)
  }

  const splitterTitle = isCollapsed ? splitCollapsedTitle : splitOpenedTitle

  return (
    <div
      className={cx(
        'bg-white',
        'd-flex',
        'h-100',
        'overflow-hidden',
        {
          'flex-column': isHorizontal,
          'flex-row': !isHorizontal,
          [styles.horizontal]: isHorizontal,
          [styles.vertical]: !isHorizontal
        },
        styles.splitContainerWrapper
      )}
      data-testid="split-container"
    >
      <div className={getSplitColumnClass(1)} data-testid="panel1" style={getSplitColumnStyle(1)}>
        {createSplitPane('panel1')}
      </div>

      {isHidden ? null : (
        <Splitter
          defaultPane={defaultPane}
          initialPaneSize={panesize}
          isCollapsed={isCollapsed}
          isHorizontal={isHorizontal}
          isLocked={isLocked}
          isSizeChangeable={isSizeChangeable}
          panesize={paneSize}
          title={splitterTitle}
          onChangePaneSize={handleChangePaneSize}
          onCollapse={handleCollapse}
        />
      )}

      <div className={getSplitColumnClass(2)} style={getSplitColumnStyle(2)}>
        {createSplitPane('panel2')}
      </div>
    </div>
  )
})

const MemoizedSplitContainer = memo(SplitContainer, (prevProps, nextProps) => {
  const checkIsPropertyEqual = (
    path: string,
    prevDefaultValue: any = null,
    nextDefaultValue: any = []
  ) => {
    const oldProperty = _.get(prevProps, path, prevDefaultValue)
    const newProperty = _.get(nextProps, path, nextDefaultValue)
    return _.isEqual(oldProperty, newProperty)
  }

  const areElementIdsEqual = checkIsPropertyEqual('container.data.elementIds')
  const arePluginsEqual = checkIsPropertyEqual('plugins')
  const areContainersEqual = checkIsPropertyEqual('containers')
  const isFormEqual = checkIsPropertyEqual('container.data.settings.form', null, {})
  const isDesignModeEqual = checkIsPropertyEqual('params.designMode', false, true)

  const arePropsEqual =
    areElementIdsEqual && arePluginsEqual && areContainersEqual && isFormEqual && isDesignModeEqual

  return arePropsEqual
})

export default MemoizedSplitContainer
