import { useCallback } from 'react'
import { confirmAlert } from 'react-confirm-alert'

function useConfirmationPopup() {
  const openConfirmationPopup = useCallback(
    (
      message: string,
      title: string,
      onAccept: () => (null | void) | Promise<void> = () => null,
      onCancel: () => (null | void) | Promise<void> = () => null
    ) => {
      confirmAlert({
        message,
        title,
        buttons: [
          {
            label: 'Cancel',
            onClick: () => onCancel()
          },
          {
            label: 'OK',
            onClick: () => onAccept()
          }
        ]
      })
    },
    []
  )

  return { openConfirmationPopup }
}

export default useConfirmationPopup
