import React from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { FormControl, Button, FormGroup, Form } from 'react-bootstrap'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import OverlayTrigger from './overlayTrigger'
import '../index.scss'

const portalTarget = document.body

export default class CustomMenu extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.checkboxId = uuidv4()

    this.state = {
      value: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.setState({ value: e.target.value })
  }

  focusNext() {
    const input = ReactDOM.findDOMNode(this.input)

    if (input) {
      input.focus()
    }
  }

  render() {
    const {
      children,
      isOpen,
      targetRect: { left = 0, top = 0, height = 0, width = 0 },
      reset: { show: resetShow = true, text: resetText = 'Reset' } = {},
      newState: { text: newStateText = 'New state', placement: newStatePlacement = 'left' } = {}
    } = this.props
    const { value } = this.state
    const boxStyle = {
      left: width < 230 ? left - (230 - width) : left,
      top: top + height
    }

    const filteredChildren = !_.isEmpty(value) ? _.map(children, _.clone) : children

    if (!_.isEmpty(value) && filteredChildren[0].props && filteredChildren[0].props.items) {
      let filteredItems = _.map(filteredChildren[0].props.items, _.clone)
      filteredItems = filteredItems.filter((x) => _.includes(_.toLower(x.name), _.toLower(value)))
      filteredChildren[0].props = {}
      filteredChildren[0].props.items = filteredItems
      filteredChildren[0].props.loadState = children[0].props.loadState
    }

    return (
      <div className="dropdown-menu invisible">
        {isOpen &&
          createPortal(
            <div className="StateSaverDiv state-save-dropdown-portal bg-white" style={boxStyle}>
              <ul className="list-unstyled p-2">
                <div className="state-button-ctn d-flex gap-1 mb-2">
                  <OverlayTrigger
                    newStatePlacement={newStatePlacement}
                    newStateText={newStateText}
                    resetShow={resetShow}
                    saveState={this.props.saveState}
                  />
                  {resetShow && (
                    <Button
                      className="btn-min-w w-100"
                      size="sm"
                      variant="danger"
                      onClick={this.props.handleResetClick}
                    >
                      {' '}
                      {_.isEmpty(resetText) ? 'Reset' : resetText}
                    </Button>
                  )}
                </div>
                <div className="state-filter-wrp mb-2">
                  {this.props.enableAutoLoad && (
                    <FormGroup className="mb-3">
                      <Form.Check
                        inline
                        defaultChecked={this.props.autoLoad}
                        id={this.checkboxId}
                        label="Auto load last used filter"
                        type="checkbox"
                        onChange={this.props.autoLoadValueChanged}
                      />
                    </FormGroup>
                  )}
                  <FormControl
                    ref={(c) => {
                      this.input = c
                    }}
                    placeholder="Type to search..."
                    size="sm"
                    type="text"
                    value={value}
                    onChange={this.handleChange}
                  />
                </div>
                {React.Children.toArray(filteredChildren)}
              </ul>
            </div>,
            portalTarget
          )}
      </div>
    )
  }
}
