import { Col, Form, Row } from 'react-bootstrap'
import { getReasonCodes, getServiceStatus } from '../../../store/api/endpoints'
import { loadTreeTableSlice } from '../../../store/slices'
import { useReduxState } from '../../../hooks'

function CommonModalFields() {
  const serviceStatusResponse = getServiceStatus.useQueryState()
  const [triggerGetReasonCodes, reasonCodesResponse] = getReasonCodes.useLazyQuery()
  const [, { setSelectedReasonCodeId }] = useReduxState(undefined, loadTreeTableSlice.actions)

  const sessionName = serviceStatusResponse.data?.Session?.Description

  function handleChangeReasonCode(event) {
    const selectedId = event.target.selectedOptions[0].getAttribute('data-id')
    setSelectedReasonCodeId(selectedId)
  }

  return (
    <>
      <Row>
        <Col sm="4">
          <Form.Label>Session Name:</Form.Label>
        </Col>
        <Col sm="8">
          <Form.Control defaultValue={sessionName} type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Form.Label>Choose Reason:</Form.Label>
        </Col>
        <Col sm="8">
          <Form.Select onChange={handleChangeReasonCode} onClick={() => triggerGetReasonCodes()}>
            {reasonCodesResponse.data?.map((reason) => (
              <option key={reason.Id} data-id={reason.Id} value={reason.Description}>
                {reason.Description}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
    </>
  )
}

export default CommonModalFields
