export default () => {
  return {
    title: 'Filter',
    type: 'object',
    format: 'objectTab',
    properties: {
      general: {
        type: 'object',
        title: 'General',
        properties: {
          name: {
            type: 'string',
            title: 'Name'
          },
          header: {
            type: 'string',
            title: 'Header'
          }
        }
      },
      settings: {
        type: 'object',
        title: 'Filter Settings',
        properties: {
          label: {
            type: 'string',
            title: 'Label'
          },
          placeholder: {
            type: 'string',
            default: 'Search',
            title: 'Placeholder'
          },
          easySelect: {
            type: 'boolean',
            title: 'Easy Select',
            default: false,
            required: true
          },
          multiple: {
            type: 'string',
            title: 'Selection Type',
            enum: [
              'Single Value',
              'Multiple Values under Main Branch',
              'Multiple Values under Single Branch',
              'Multiple Values'
            ],
            required: true
          },
          propertyNameOfSelector: {
            type: 'string',
            title: 'Property Name Of Selector',
            description: `Changes N "items" selected text.`,
            default: 'items',
            required: false
          },
          maximumNumberOfSelectableItem: {
            type: 'NullableNumber',
            title: 'Maximum selectable item',
            description: 'Limits the maximum number of items that can be selected.',
            default: ''
          },
          dropdown: {
            type: 'boolean',
            title: 'Dropdown',
            default: true,
            required: true
          },
          sendDataWhenDropdownClosed: {
            type: 'boolean',
            title: 'Send Data When Dropdown Closed',
            default: false,
            required: true
          },
          omitChildrenWhenAllChildrenSelected: {
            type: 'boolean',
            title: 'Omit Children When All Children Selected',
            default: false,
            required: true
          },
          sendNull: {
            type: 'boolean',
            title: 'Send Null',
            default: false,
            required: true
          },
          disableSendParentWhenPartiallySelected: {
            type: 'boolean',
            title: 'Disable Send Parent When Partially Selected',
            default: false,
            required: true
          },
          showAllChildrenOnSearch: {
            type: 'boolean',
            title: 'Show all children on search',
            default: false,
            required: false
          },
          showClearAllSelectionsBtn: {
            type: 'boolean',
            title: 'Show clear all selections button',
            default: true,
            required: false
          },
          initiallyExpandAllNodes: {
            type: 'boolean',
            title: 'Initially Expand All Nodes',
            default: false,
            required: false
          }
        }
      },
      data: {
        type: 'object',
        title: 'Data',
        properties: {
          datafields: {
            type: 'array',
            format: 'HiddenTable',
            title: 'Data Fields',
            items: {
              type: 'string',
              title: 'Field'
            }
          },
          value: {
            type: 'string',
            title: 'Value',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          display: {
            type: 'string',
            title: 'Display',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          icon: {
            type: 'string',
            title: 'Icon',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          iconColor: {
            type: 'string',
            title: 'Icon Color',
            default: '',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          parent: {
            type: 'string',
            title: 'Parent',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          level: {
            type: 'string',
            title: 'Level',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          default: {
            type: 'string',
            title: 'Default',
            watch: {
              ds: 'data.datafields'
            },
            enumSource: 'ds'
          },
          levels: {
            type: 'array',
            title: 'Levels',
            uniqueItems: true,
            items: {
              type: 'object',
              title: 'Level',
              headerTemplate: '{{ self.level }}',
              properties: {
                level: {
                  type: 'number',
                  enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                },
                variable: {
                  type: 'string',
                  title: 'Variable'
                },
                icon: {
                  type: 'string',
                  title: 'Icon',
                  format: 'IconSelector'
                },
                limit: {
                  title: 'Number of Visible Items',
                  type: 'NullableNumber',
                  default: ''
                },
                enabled: {
                  type: 'boolean',
                  title: 'Enabled',
                  default: true,
                  required: true
                }
              }
            }
          }
        }
      }
    }
  }
}
